import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Tooltip } from '@mui/material';
import { MiniBoldFont, SmallFont } from '../../FontStyle';

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    height: '38px',
    padding: '10px 12px',
    boxSizing: 'border-box',
    backgroundColor: '#F1F3F5',
    ...MiniBoldFont,
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
    color: '#596A7C',
  },
  [`&.${tableCellClasses.body}`]: {
    height: '36px',
    padding: '8px 12px',
    boxSizing: 'border-box',
    ...SmallFont,
    color: '#262E35',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

const StyledJournalTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    height: '38px',
    padding: '10px 12px',
    boxSizing: 'border-box',
    backgroundColor: '#F1F3F5',
    ...MiniBoldFont,
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
    color: '#596A7C',
  },
  [`&.${tableCellClasses.body}`]: {
    height: 'auto',
    padding: '8px 12px',
    boxSizing: 'border-box',
    ...SmallFont,
    color: '#262E35',
    wordWrap: 'normal',
  },
});

const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
});

export function JournalTable(props) {
  const { journalList } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell sx={{ minWidth: '261px', maxWidth: '100%' }}>
            JOURNAL
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {journalList.sort().map((item, index) => (
          <StyledTableRow key={index}>
            <StyledJournalTableCell
              component='th'
              scope='row'
              sx={{ minWidth: '261px', maxWidth: '100%' }}
            >
              {item}
            </StyledJournalTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export function SITable(props) {
  const { SIList } = props;
  return (
    <Box
      sx={{
        Width: '684px',
        border: '1px solid #DFE4E8',
        borderRadius: '8px',
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ minWidth: '126px', maxWidth: '126px' }}>
              Journal Code
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: '292px', maxWidth: '292px' }}>
              SI Code w. "Special Issue Detail" URL{' '}
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: '158px', maxWidth: '158px' }}>
              Special Issue Title
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: '111px', maxWidth: '111px' }}>
              Edit by
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {SIList.map((item, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell sx={{ minWidth: '126px', maxWidth: '126px' }}>
                {item.journalCode}
              </StyledTableCell>
              <StyledTableCell sx={{ minWidth: '292px', maxWidth: '292px' }}>
                <a
                  href={
                    item.siUrl.indexOf('http') !== -1
                      ? item.siUrl
                      : 'https://' + item.siUrl
                  }
                  style={{
                    letterSpacing: '0.01em',
                    color: '#154AB6',
                    fontWeight: '600',
                  }}
                  target='_blank'
                  rel='noreferrer'
                >
                  {item.siUrl}
                </a>
              </StyledTableCell>
              <Tooltip title={item.siTitle}>
                <StyledTableCell sx={{ minWidth: '158px', maxWidth: '158px' }}>
                  {item.siTitle}
                </StyledTableCell>
              </Tooltip>
              <Tooltip title={item.editBy}>
                <StyledTableCell sx={{ minWidth: '111px', maxWidth: '111px' }}>
                  {item.editBy}
                </StyledTableCell>
              </Tooltip>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export function ProblematicGETable(props) {
  const { list } = props;
  return (
    <Box
      sx={{
        Width: '684px',
        border: '1px solid #DFE4E8',
        borderRadius: '8px',
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ minWidth: '126px', maxWidth: '126px' }}>
              GE Name
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: '292px', maxWidth: '292px' }}>
              GE Details Page URL
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: '158px', maxWidth: '158px' }}>
              Flag Name
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: '111px', maxWidth: '111px' }}>
              Flagged by
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell sx={{ minWidth: '126px', maxWidth: '126px' }}>
                {item['GE NAME']}
              </StyledTableCell>
              <StyledTableCell sx={{ minWidth: '292px', maxWidth: '292px' }}>
                <a
                  href={
                    (item['GE Details Page URL'].indexOf('http') !== -1
                      ? item.siUrl
                      : 'https://' + item.siUrl)??(item['GE Details Page URL'].indexOf('http') !== -1
                      ? item['GE Details Page URL']
                      : 'https://' + item['GE Details Page URL'])
                  }
                  style={{
                    letterSpacing: '0.01em',
                    color: '#154AB6',
                    fontWeight: '600',
                  }}
                  target='_blank'
                  rel='noreferrer'
                >
                  {item['GE Details Page URL']}
                </a>
              </StyledTableCell>
              <Tooltip title={item.siTitle}>
                <StyledTableCell sx={{ minWidth: '158px', maxWidth: '158px' }}>
                  {item['Flag Name']}
                </StyledTableCell>
              </Tooltip>
              <Tooltip title={item.editBy}>
                <StyledTableCell sx={{ minWidth: '111px', maxWidth: '111px' }}>
                  {item['Flagged by']}
                </StyledTableCell>
              </Tooltip>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

import { TableFooter2 } from '@/components/DataTable';
import { StyledTableCell } from '@/components/DataTable';
import NoDataMessage from '@/componentsphase2/NoDataMessage';
import {
  Box,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import ShowDataTable from '../../compontents/showDataTable';
import moment from 'moment';
export default function publishedSI(props) {
  const { tableData } = props;
  const titleList = [
    // ['title','width','title text-align','info text-align,]

    ['SI CODE', '151px', '', '', 'url'],
    ['SI TITLE', '254px'],
    ['Volume', '103px'],
    ['ISSUE', '85px'],
    ['Actual Publication Date', '231px'],
    ['OA Articles', '133px'],
    ['OO Articles', '135px'],
    ['Issue Publication Link', '264px', '', '', 'nativeURL'],
  ];
  // const tableData = [
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  // ];
  const formedTableData = tableData.map(item => {
    return [
      { code: item['siCode'], type: item['alertGeFlagType'] },
      item['siTitle'],
      item['volume'],
      item['issue'],
      moment(item['actualPublicationDate']).utc().format('YYYY-MM-DD'),
      item['numberOfOaArticles'],
      item['numberOfOoArticles'],
      item['issuePublicationLink'],
    ];
  });
  return (
    <ShowDataTable
      titleList={titleList}
      tableData={formedTableData}
      tableWidth='1356px'
    />
  );
}

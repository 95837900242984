import {
  Stack,
  Typography,
  styled,
  Box,
  Button,
  TextField,
  createTheme,
  ThemeProvider,
  Dialog,
  IconButton,
  DialogContent,
  DialogActions,
  InputAdornment
} from '@mui/material';
import { useIntl } from 'react-intl';
import { SIModal } from '@/modules/Modal';
import { useState } from 'react';
import GEDetailCloseIcon from '@/assets/GEDetailCloseIcon.svg';
import { H4Font, SmallFont } from '../../../../components/FontStyle';
import { OnlyTextWithX } from '@/componentsphase2/OnlyText';
import { CancelBtnCss, exportBtnCss } from './DecisionUnfold';
import CloseIconButton from '@/componentsphase2/Button/CloseIconButton';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import { StateButton } from '@/componentsphase2/StateButton';
import React from 'react';
const Cross = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.47206 1.47157C9.73241 1.21122 9.73241 0.789108 9.47206 0.528758C9.21171 0.268409 8.7896 0.268409 8.52925 0.528758L5.00065 4.05735L1.47206 0.528758C1.21171 0.268409 0.789596 0.268409 0.529247 0.528758C0.268897 0.789108 0.268897 1.21122 0.529247 1.47157L4.05784 5.00016L0.529247 8.52876C0.268897 8.78911 0.268897 9.21122 0.529247 9.47157C0.789596 9.73192 1.21171 9.73192 1.47206 9.47157L5.00065 5.94297L8.52925 9.47157C8.7896 9.73192 9.21171 9.73192 9.47206 9.47157C9.73241 9.21122 9.73241 8.78911 9.47206 8.52876L5.94346 5.00016L9.47206 1.47157Z" fill="#596A7C" />
  </svg>
);

const TopText = styled(props => (
  <Typography
    {...props}
    data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-PropsTypography'
  />
))({
  '&.MuiTypography-root': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '125%',
    color: '#596A7C',
  },
});

const getBorderColor = (hover, focus, hasValue) => {
  if (focus) {
    return "2px solid #0052CC";
  }
  if (hover) {
    return "1px solid #596A7C";
  }
  if (hasValue) {
    return "1px solid #98A7B6";
  }
  return "1px solid #DFE4E8";
};

export const AssigneeInput = styled(TextField)(props => ({

  '& .MuiOutlinedInput-root': {
    height: '40px',
    width: '358px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17.5px',
    padding: '11px 12px',
    boxSizing: 'border-box',
  },
  '& .MuiOutlinedInput-input': {
    padding: '0px 0px',
  },
  '& fieldset': {
    border: getBorderColor(props.hover, props.focus, props.hasValue)
  }
}));

function AssigneeInputField(props) {
  const { value, setInputValue, ...others } = props;
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  return (
    <AssigneeInput
      onMouseEnter={() => { setHover(true); }}
      onMouseLeave={() => { setHover(false); }}
      onFocus={() => {
        props.onInputFocus && props.onInputFocus();
        setFocus(true);
      }}
      onBlur={() => {
        props.onInputBlur && props.onInputBlur();
        setTimeout(() => setFocus(false), 100);
      }}
      value={value}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={setInputValue}
              edge="end"
              disableFocusRipple
              disableRipple
              disabled={!(value && value !== "" && (focus || hover))}
              sx={{ mr: "-8px" }}
            >
              {(value && value !== "" && focus) ? <Cross /> : <></>}
            </IconButton>
          </InputAdornment>

      }}

      hover={hover}
      focus={focus}
      hasValue={value && value !== ""}
      {...others}
    />
  );
}

const ItemBlock = props => {
  const { title } = props;
  return (
    <Stack
      spacing={0}
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-ItemBlock-Stack'
    >
      <Stack
        sx={{
          width: '160px',
          float: "left"
        }}
        spacing={0}
        direction='row'
        data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-ItemBlock-InnerStack'
      >
        <Box
          data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-ItemBlock-Box1'
          sx={{
            height: '20px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            color: '#596A7C',
          }}
        >
          {title}
        </Box>
        <Box
          data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-ItemBlock-Box2'
          style={{
            width: '8px',
            height: '18px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '18px',
            letterSpacing: '0.08em',
            color: '#C40000',
            paddingLeft: '2px',
          }}
        >
          *
        </Box>
      </Stack>
      <Box>{props.children}</Box>
    </Stack>
  );
};

const ErrBox = props => {
  return (
    <Box
      data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-Err-Box'
      sx={{
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
        color: '#EE5350',
      }}
    >
      {props.children}
    </Box>
  );
};

const theme = createTheme({
  palette: {
    error: {
      main: '#EE5350',
    },
  },
});

export default function AssigneeAddPopUp(props) {
  const intl = useIntl();
  const {
    open = true,
    handleClose = () => { },
    handleSave = () => { },
    assignee = {
      firstname: '',
      lastname: '',
      email: '',
    },
    setAssignee = () => { },
    assignees = [],
    index = -1,
  } = props;

  // const savetBtnCss = {
  //   width: '69px',
  //   height: '32px',
  //   backgroundColor: '#154AB6',
  //   borderRadius: '3px',
  //   fontFamily: 'Open Sans',
  //   fontStyle: 'normal',
  //   fontWeight: 600,
  //   fontSize: '14px',
  //   lineHeight: '20px',
  //   color: '#FFFFFF',
  //   letterSpacing: '1px',
  //   textTransform: 'capitalize',
  // };

  // const CancelBtnCss = {
  //   fontFamily: 'Open Sans',
  //   fontStyle: 'normal',
  //   fontWeight: 400,
  //   fontSize: '14px',
  //   lineHeight: '20px',
  //   color: '#154AB6',
  //   textTransform: 'capitalize',
  // };

  const [isClick, setIsClick] = useState(false);

  const emailPattern =  /^\w+((-\w+)|(\.\w+)|(_\w+))*@[A-Za-z0-9]+(([.\-])[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

  const [emailFormatErr, setEmailFormatErr] = useState(false);

  const checkEmailRepeat = () => {
    if (index >= 0 && assignees[index].email === assignee.email) {
      return false;
    }
    for (let i = 0; i < assignees.length; i++) {
      if (assignees[i].email === assignee.email) {
        return true;
      }
    }
    return false;
  };
  React.useEffect(()=>{
    setEmailFormatErr(!emailPattern.test(assignee.email));
  },[isClick,assignee]);
  return (
    <Dialog
      data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-Dialog'
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          boxShadow: 'none',
        },
      }}
      sx={{
        marginTop: '48px',
        '& .MuiDialog-paper': {
          minWidth: '600px',
          minHeight: '282px',
          margin: 0,
          padding: '24px 32px 0px 32px',
          boxSizing: 'border-box',
        },

        '& .MuiModal-backdrop': {
          background: 'rgba(17, 61, 149, 0.5)',
          backdropFilter: 'blur(4px)'
        }

      }}
    >
      <Box
        sx={{ height: '23px', marginBottom: '16px' }}
        data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-Box'
      >
        <Box
          data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-InnerBox'
          sx={{
            ...H4Font,
            height: '23px',
            float: 'left',
            color: '#262E35',
          }}
        >
          {index == -1
            ? intl.messages['sipDetail.addNewAssignee']
            : intl.messages['sipDetail.editAssignee']}
        </Box>
        {/* <IconButton
          
        >
          <GEDetailCloseIcon data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-GEDCloseIcon' />
        </IconButton> */}
        <CloseIconButton
          data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-IconButton'
          id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-IconButton'
          sx={{ float: 'right', cursor: 'pointer', padding: '0px' }}
          onClick={handleClose}
        />
      </Box>
      <DialogContent
        sx={{ padding: '0px' }}
        data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-DialogContent'
      >
        <Box
          sx={{ height: '1px' }}
          data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-DialogContent-Box'
        ></Box>
        <Stack
          direction='column'
          spacing={1.25}
          data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-DialogContent-Stack'
        >
          <ItemBlock title={intl.messages['sipDetail.firstName']}>
            <ThemeProvider
              theme={theme}
              data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-DialogContent-ThemeProvider1'
            >
              <MyOnlyText
                id='firstNameInput'
                data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-DialogContent-FirstNameInput'
                variant='outlined'
                value={assignee.firstName}
                onChange={e => {
                  if (e.target.value.length <= 40) {
                    setAssignee({
                      ...assignee,
                      firstName: e.target.value,
                    });
                  }
                }}
                setValue={(value) => {
                  setAssignee({
                    ...assignee,
                    firstName: value,
                  });
                }}
                handleIconButtonClick={(value) => {
                  setAssignee({
                    ...assignee,
                    firstName: '',
                  });
                }
                }
                error={isClick && assignee.firstName === ''}
                needEndAdornment={true}
                width='358px'
                height='40px'
              />
            </ThemeProvider>
            {isClick && assignee.firstName === '' ? (
              <ErrBox data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-DialogContent-ErrBox1'>
                This field is required.
              </ErrBox>
            ) : (
              <></>
            )}
          </ItemBlock>

          <ItemBlock title={intl.messages['sipDetail.lastName']}>
            <ThemeProvider
              theme={theme}
              data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-DialogContent-ThemeProvider2'
            >
              <MyOnlyText
                id='lastNameInput'
                data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-DialogContent-LastNameInput'
                variant='outlined'
                value={assignee.lastName}
                onChange={e => {
                  if (e.target.value.length <= 40) {
                    setAssignee({
                      ...assignee,
                      lastName: e.target.value,
                    });
                  }
                }}
                setValue={value => {
                  setAssignee({
                    ...assignee,
                    lastName: value,
                  });
                }}
                handleIconButtonClick={() => {
                  setAssignee({
                    ...assignee,
                    lastName: '',
                  });
                }}
                error={isClick && assignee.lastName === ''}
                needEndAdornment={true}
                width='358px'
                height='40px'
              />
            </ThemeProvider>
            {isClick && assignee.lastName === '' ? (
              <ErrBox data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-DialogContent-ErrBox2'>
                This field is required.
              </ErrBox>
            ) : (
              <></>
            )}
          </ItemBlock>

          <ItemBlock title={intl.messages['sipDetail.emailAddress']}>
            <ThemeProvider
              theme={theme}
              data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-DialogContent-ThemeProvider3'
            >
              <MyOnlyText
                id='emailAddressInput'
                data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-DialogContent-EmailAddressInput'
                variant='outlined'
                value={assignee.email}
                onChange={e => {
                  if (e.target.value.length <= 100) {
                    setAssignee({
                      ...assignee,
                      email: e.target.value,
                    });
                  }
                }}
                setValue={
                  value=>{
                    setAssignee({
                      ...assignee,
                      email: value,
                    });
                  }
                }
                handleIconButtonClick={() => {
                  setAssignee({
                    ...assignee,
                    email: '',
                  });
                }}
                onInputBlur={() => {
                  if (assignee.email !== '') {
                    setEmailFormatErr(!emailPattern.test(assignee.email));
                  }
                }}
                onInputFocus={() => {
                  setEmailFormatErr(false);
                }}
                error={
                  (isClick && (assignee.email === '' || checkEmailRepeat())) ||
                  (emailFormatErr && isClick)
                }
                needEndAdornment={true}
                width='358px'
                height='40px'
              />
            </ThemeProvider>
            {isClick && (assignee.email === '' || checkEmailRepeat()) ? (
              <ErrBox data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-DialogContent-CheckEmialRepeatErrBox'>
                {checkEmailRepeat()
                  ? 'Mailbox already exists'
                  : 'This field is required.'}{' '}
              </ErrBox>
            ) : isClick && emailFormatErr ? (
              <ErrBox data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-DialogContent-ErrBox3'>
                email Format Error
              </ErrBox>
            ) : (
              <></>
            )}
          </ItemBlock>
        </Stack>
      </DialogContent>
      <DialogActions
        data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-DialogActions'
        sx={{
          padding: '0px',
          height: '68px',
        }}
      >
        <Stack
          direction='row'
          justifyContent='flex-end'
          spacing={1.125}
          data-selenium-id='SI P_DetailPage_DecisionSection-AssigneeAddPopUp-DialogActions-Stack'
        >
          <Button
            id='exportAllSIPageCancelButton'
            data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-DialogActions-ExportAllSIPageCancelButton'
            sx={CancelBtnCss}
            onClick={() => {
              setIsClick(false);
              handleClose();
            }}
          >
            Cancel
          </Button>
          <StateButton
            id='editSaveButton'
            data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-DialogActions-EditSaveButton'
            variant='contained'
            // 直接绑定到Assign下面那个数组
            onClick={() => {
              setIsClick(true);
              if (
                assignee.firstName !== '' &&
                assignee.lastName !== '' &&
                assignee.email !== '' &&
                !checkEmailRepeat() &&
                !emailFormatErr
              ) {
                handleSave();
                setIsClick(false);
              }
            }}
            titleName= {index == -1 ? 'Create' : 'Save'}
            sx={{width: '70px'}}
          >
            
          </StateButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

import PropTypes from 'prop-types';
import { Tabs, tabClasses, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NewDesignedMain } from '../../../components/Main';
import { switchJobsTab } from '../../../actions/SIMT-Report/ReportJobsSubmission/ReportJobsAction';
import NewSubmission from './NewSubmission';
import WaitingForApproval from './WaitingForApproval/WaitingForApprovalTable';
import HistoryRequestTable from './HistoryRequest/HistoryRequestTable';

const ReportJobsTab = styled(Tab)(({ _theme }) => ({
  [`&.${tabClasses.root}`]: {
    padding: '9px 16px 9px 16px',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 400,
    color: '#596A7C',
    textTransform: 'none',
    lineHeight: '20px',
    fontFamily: 'Open Sans',
  },
  [`&.${tabClasses.selected}`]: {
    padding: '9px 16px 9px 16px',
    fontFamily: 'Open Sans',
    letterSpacing: '0.01em',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#262E35 !important',
    textTransform: 'none',
  },
  // [`&.${tabClasses.}`]: {
  //
  // }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`reportTabpanel${index}`}
      aria-labelledby={`reportTab${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `systemAdminReportJobsTab${index}`,
    'aria-controls': `reportTabpanel${index}`,
  };
}

function ReportJobs() {
  const dispatch = useDispatch();
  const open = useSelector(state => state.UserManage.siderOpen);
  const { currentReportTab } = useSelector(state => {
    return {
      currentReportTab: state.Report.currentReportTab,
    };
  });
  const handleChange = (_event, newValue) => {
    dispatch(switchJobsTab(newValue));
  };

  return (
    <Box p={'4px 24px 26px 24px'} data-selenium-id='SystemAdmin_ReportJobs-Box'>
      <NewDesignedMain
        id='report-jobs-overview-paper'
        data-selenium-id='SystemAdmin_ReportJobs-Box-NewDesignedMain'
        open={open}
        sx={{ backgroundColor: 'transparent' }}
      >
        <Box
          sx={{ width: '100%' }}
          data-selenium-id='SystemAdmin_ReportJobs-Box-NewDesignedMain-Box'
        >
          <Box
            data-selenium-id='SystemAdmin_ReportJobs-Box-NewDesignedMain-Box-Box1'
            sx={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '22px',
              lineHeight: '125%',
              color: '#262E35',
              paddingTop: '8px',
              marginLeft: '8px',
              marginBottom: '15px',
            }}
          >
            <FormattedMessage id='reportJobs.reportJobs' />
          </Box>
          <Box
            data-selenium-id='SystemAdmin_ReportJobs-Box-NewDesignedMain-Box-Box2'
            sx={{
              borderBottom: '2px solid #DFE4E8',
              width: 'fit-content',
              marginLeft: '8px',
            }}
          >
            <Tabs
              disableRipple
              value={currentReportTab}
              onChange={handleChange}
              aria-label='basic tabs example'
              sx={{
                marginBottom: '-2px', // 这个属性是为了让底部的蓝色线条跟外部的灰白色线条平齐
              }}
            >
              <ReportJobsTab
                disableRipple
                id='reportJobsFirst'
                label='New Submission'
                {...a11yProps(0)}
              />
              <ReportJobsTab
                disableRipple
                id='reportJobsSecond'
                label='Waiting for Approval'
                {...a11yProps(1)}
              />
              <ReportJobsTab
                disableRipple
                id='reportJobsThird'
                label='History Requests'
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>

          <TabPanel
            value={currentReportTab}
            index={0}
            data-selenium-id='SystemAdmin_ReportJobs-Box-NewDesignedMain-Box-TabPanel1'
          >
            <NewSubmission data-selenium-id='SystemAdmin_ReportJobs-Box-NewDesignedMain-Box-TabPanel1-NewSubmission' />
          </TabPanel>
          <TabPanel
            value={currentReportTab}
            index={1}
            data-selenium-id='SystemAdmin_ReportJobs-Box-NewDesignedMain-Box-TabPanel2'
          >
            <Box
              style={{ marginTop: '10px' }}
              data-selenium-id='SystemAdmin_ReportJobs-Box-NewDesignedMain-Box-TabPanel2-Box'
            >
              <WaitingForApproval />
            </Box>
          </TabPanel>
          <TabPanel
            value={currentReportTab}
            index={2}
            data-selenium-id='SystemAdmin_ReportJobs-Box-NewDesignedMain-Box-TabPanel3'
          >
            <Box
              style={{ marginTop: '10px' }}
              data-selenium-id='SystemAdmin_ReportJobs-Box-NewDesignedMain-Box-TabPanel3-Box'
            >
              <HistoryRequestTable data-selenium-id='SystemAdmin_ReportJobs-Box-NewDesignedMain-Box-TabPanel3-Box-HistoryRequestTable' />
            </Box>
          </TabPanel>
        </Box>
        {/*</Box>*/}
      </NewDesignedMain>
    </Box>
  );
}

export default ReportJobs;

import React from 'react';
import SimpleIconButton from '../SimpleIconButton';
import IconActive from '../icon/ContainerDownloadIconActive.svg';
import IconDefault from '../icon/ContainerDownloadIconDefault.svg';
import IconDisable from '../icon//ContainerDownloadIconDisable.svg';
export default function DownloadContainerButton(props) {
  return (
    <SimpleIconButton
      DefaultIcon={IconDefault}
      HoverIcon={IconActive}
      ActiveIcon={IconActive}
      DisabledIcon={IconDisable}
      {...props}
    ></SimpleIconButton>
  );
};

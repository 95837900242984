import ooAcqLineIcon from '@/assets/ReportDiagrams/A&A/FYTrends/lineIcon/ooAcq.png';
import oaAcqLineIcon from '@/assets/ReportDiagrams/A&A/FYTrends/lineIcon/oaAcq.png';
import ooActLineIcon from '@/assets/ReportDiagrams/A&A/FYTrends/lineIcon/ooAct.png';
import oaActLineIcon from '@/assets/ReportDiagrams/A&A/FYTrends/lineIcon/oaAct.png';
import ooAcqLegendIcon from '@/assets/ReportDiagrams/A&A/FYTrends/legendIcon/ooAcq.png';
import oaAcqLegendIcon from '@/assets/ReportDiagrams/A&A/FYTrends/legendIcon/oaAcq.png';
import ooActLegendIcon from '@/assets/ReportDiagrams/A&A/FYTrends/legendIcon/ooAct.png';
import oaActLegendIcon from '@/assets/ReportDiagrams/A&A/FYTrends/legendIcon/oaAct.png';
export const FY_TRENDS_OF_SI_ACQUISITION = 'FY Trends of SI Acquisition';
export const FY_TRENDS_OF_ACTIVE_SI = 'FY Trends of Active SI';
export const SUBJECT_GROUP = 'Subject Group';
export const BUSINESS_DIVISION = 'Business Division';
export const ACQUIRED = 'Acquired';
export const ACTIVE = 'Active';

export const SG_CHART_COLOR = {
  [FY_TRENDS_OF_SI_ACQUISITION]: {
    oo: {
      barColor: '#DFE4E8',
      borderColor: '#98A7B6',
      lineColor: '#596A7C',
      lineIcon: ooAcqLineIcon,
      legendIcon: ooAcqLegendIcon,
    },
    oa: {
      barColor: '#F0F9FF',
      borderColor: '#4DBBFF',
      lineColor: '#4DBBFF',
      lineIcon: oaAcqLineIcon,
      legendIcon: oaAcqLegendIcon,
    },
  },
  [FY_TRENDS_OF_ACTIVE_SI]: {
    oo: {
      barColor: '#DFE4E8',
      borderColor: '#98A7B6',
      lineColor: '#596A7C',
      lineIcon: ooActLineIcon,
      legendIcon: ooActLegendIcon,
    },
    oa: {
      barColor: '#FFDDDD',
      borderColor: '#FF8180',
      lineColor: '#FF8180',
      lineIcon: oaActLineIcon,
      legendIcon: oaActLegendIcon,
    },
  },
};

export const BD_CHART_COLOR = {
  [FY_TRENDS_OF_SI_ACQUISITION]: {
    oo: {
      barColor: SG_CHART_COLOR[FY_TRENDS_OF_SI_ACQUISITION].oo.borderColor,
    },
    oa: {
      barColor: SG_CHART_COLOR[FY_TRENDS_OF_SI_ACQUISITION].oa.borderColor,
    },
  },
  [FY_TRENDS_OF_ACTIVE_SI]: {
    oo: {
      barColor: SG_CHART_COLOR[FY_TRENDS_OF_ACTIVE_SI].oo.borderColor,
    },
    oa: {
      barColor: SG_CHART_COLOR[FY_TRENDS_OF_ACTIVE_SI].oa.borderColor,
    },
  },
};

export const EXCEL_HEADER = {
  [ACQUIRED]: {
    exportHeader: [
      '@{acq}MAY',
      '@{acq}JUN',
      '@{acq}JUL',
      '@{acq}AUG',
      '@{acq}SEP',
      '@{acq}OCT',
      '@{acq}NOV',
      '@{acq}DEC',
      '@{acq}JAN',
      '@{acq}FEB',
      '@{acq}MAR',
      '@{acq}APR',
      '@{acq}Total',
      '@{acq}MAY@OA',
      '@{acq}JUN@OA',
      '@{acq}JUL@OA',
      '@{acq}AUG@OA',
      '@{acq}SEP@OA',
      '@{acq}OCT@OA',
      '@{acq}NOV@OA',
      '@{acq}DEC@OA',
      '@{acq}JAN@OA',
      '@{acq}FEB@OA',
      '@{acq}MAR@OA',
      '@{acq}APR@OA',
      '@{acq}Total@OA',
    ],
    partHeader: ['Acquired SI (All Journals)', 'Acquired SI (OA Journals)'],
  },
  [ACTIVE]: {
    exportHeader: [
      '@{act}MAY',
      '@{act}JUN',
      '@{act}JUL',
      '@{act}AUG',
      '@{act}SEP',
      '@{act}OCT',
      '@{act}NOV',
      '@{act}DEC',
      '@{act}JAN',
      '@{act}FEB',
      '@{act}MAR',
      '@{act}APR',
      '@{act}MAY@OA',
      '@{act}JUN@OA',
      '@{act}JUL@OA',
      '@{act}AUG@OA',
      '@{act}SEP@OA',
      '@{act}OCT@OA',
      '@{act}NOV@OA',
      '@{act}DEC@OA',
      '@{act}JAN@OA',
      '@{act}FEB@OA',
      '@{act}MAR@OA',
      '@{act}APR@OA',
    ],
    partHeader: ['Active SI (All Journals)', 'Active SI (OA Journals)'],
  },
};

import { useState } from "react";
import InfoList from "../compontents/InfoList";
import SectionFolder from "../compontents/SectionFolder";
import { useDispatch, useSelector } from "react-redux";
import EditIconButton from "@/componentsphase2/Button/EditIconButton";
import { Box, Popover, Stack, Typography } from "@mui/material";
import EmailIconButton from "@/componentsphase2/Button/EmailIconButton";
import MenuIcon from "@/componentsphase2/Dropdown";
import ArrowIcon from '@/assets/Arrow.svg';
import JemailActiveIcon from '@/assets/JemailActiveIcon.svg';
import IconDefault from '@/componentsphase2/Button/icon/EmailIconDefault.svg';
import EmailDefaultIcon from '@/assets/EmailDefaultIcon.svg';
import EmailHoverIcon from '@/assets/EmailHoverIcon.svg';
import React from "react";
import { ShowForPermission } from "@/components/PermissionControl/ShowForPermission";
import {PERMISSIONS} from "@/constant/permission";

const StyledTypography = props => {
  const { journalCode, type } = props;
  return (
    <a
      href={`/simt/auth/siRelated/emailSend?journalCode=${journalCode}&type=${type}`}
      style={{
        textDecoration: 'none',

      }}
      target='_blank'
      data-selenium-id='SIDetail_GeneralInfoSection-StyledTypographyLink'
    >
      <Typography
        sx={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '20px',
          cursor: 'pointer',
          color: '#596A7C',
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          gap: '6px',
          '&:hover': {
            color: '#113D95',
            '& path': {
              stroke: '#113D95'
            }
          }
        }}
        data-selenium-id='SIDetail_GeneralInfoSection-StyledTypography'
      >
        {props.children}
        <ArrowIcon
          id='SIDetail_GeneralInfoSection-StyledTypography-ArrowIcon'
          data-selenium-id='SIDetail_GeneralInfoSection-StyledTypography-ArrowIcon'
        />
      </Typography>
    </a>
  );
};

export default function StakeholdersSection(props) {
  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      CATJournalDetail: state.CATJournalDetail
    };
  };
  const { open, CATJournalDetail } = useSelector(selector);
  // const CATJournalDetail = {
  //   "journalCode": "string",
  //   "journalName": "string",
  //   "subjectGroup": "string",
  //   "ownershipStatus": "string",
  //   "revenueModel": "string",
  //   "primaryURL": "string",
  //   "authorGuideline": "string",
  //   "vchCode": "string",
  //   "businessDivision": "string",
  //   "society": "string",
  //   "peerReviewModel": "string",
  //   "eeoSystemLink": "string",
  //   "submissionUrl": "string",
  //   "onlineISSN": "string",
  //   "journalPublishingManager": "string",
  //   "editorInChief": "string",
  //   "associateManagingEditor": null,
  //   "productionEditor": "string",
  //   "marketingManager": "string",
  //   "editorialDirector": "string",
  //   "eOContact": null,
  //   "managingEditor": null,
  //   "productionManager": "string",
  //   "handlingCe": null,
  //   "catJournalActiveSiList": [
  //     {
  //       "siCode": "string",
  //       "siPageLink": "string",
  //       "siTitle": "string",
  //       "siStage": "string",
  //       "numberOfSubmittedArticles": 0,
  //       "numberOfAcceptedArticles": 0,
  //       "numberOfRejectedArticles": 0,
  //       "numberOfInProgressArticles": 0
  //     }
  //   ],
  //   "catJournalPublishedSiList": [
  //     {
  //       "id": 0,
  //       "siCode": "string",
  //       "siPageLink": "string",
  //       "siTitle": "string",
  //       "volume": "string",
  //       "issue": "string",
  //       "actualPublicationDate": "string",
  //       "numberOfOaArticles": 0,
  //       "numberOfOoArticles": 0
  //     }
  //   ],
  //   "catJournalShelvedSiList": [
  //     {
  //       "siCode": "string",
  //       "siPageLink": "string",
  //       "siTitle": "string",
  //       "shelvedReason": "string",
  //       "shelvedDate": "string"
  //     }
  //   ]
  // };
  const list = [
    ['Editor-in-Chief', CATJournalDetail.editorInChief, 'text'],
    ["Publishing Development Lead", CATJournalDetail.publishingDevelopmentLead,'text'],
    ["Publishing Development Manager", CATJournalDetail.publishingDevelopmentManager,'text'],
    ["Publishing Development Director", CATJournalDetail.publishingDevelopmentDirector,'text'],
    ["Partner Publishing Lead", CATJournalDetail.partnerPublishingLead,'text'],
    ["Partner Publishing Manager", CATJournalDetail.partnerPublishingManager,'text'],
    ["Partner Publishing Director", CATJournalDetail.partnerPublishingDirector,'text'],
    ['EO Contact', CATJournalDetail.eOContact, 'text'],
    ["Peer Review Performance Lead", CATJournalDetail.peerReviewPerformanceLead,'text'],
    ["Peer Review Performance Manager", CATJournalDetail.peerReviewPerformanceManager,'text'],
    ["Peer Review Performance Director", CATJournalDetail.peerReviewPerformanceDirector,'text'],
    ["Comprehensive Editorial Evaluation Team Lead", CATJournalDetail.comprehensiveEditorialEvaluationTeamLead,'text'],
    ["Publishing VP", CATJournalDetail.publishingVP,'text'],
    ['Production Editor (PE)', CATJournalDetail.productionEditor, 'text'],
    ['Production Manager (PM)', CATJournalDetail.productionManager, 'text'],
    ['Marketing Manager (MM)', CATJournalDetail.marketingManager, 'text'],
    ['Handling CE', CATJournalDetail.handlingCe, 'text'],
  ];
  
  // const [popOpen, setPopOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const popOpen = Boolean(anchorEl);
  return (
    <SectionFolder title='Stakeholders'
      titleEndElement={(
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            top: '-3px',
            alignItems: 'end',
            gap: '8px',
            padding: '0 8px',
          }}
        >
          <ShowForPermission permission={`${PERMISSIONS.SI_WRITE} ${PERMISSIONS.JOURNAL_CAT_WRITE} ${PERMISSIONS.JOURNAL_CAT_READ}`}>{/* https://zqh0ovipcne.feishu.cn/record/IiElraX4ae6EnhcWKwgcdPwinFb */}
            <EmailIconButton
              sx={{ padding: '0px',paddingBottom:'1.5px' }}
              id='emailIconButton'
              DefaultIcon={anchorEl===null?EmailDefaultIcon:JemailActiveIcon}
              HoverIcon={EmailHoverIcon}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              // setPopOpen(!popOpen);
              }} />

          </ShowForPermission>
          
          
          <Popover
            id='pop'
            anchorEl={anchorEl}
            open={popOpen}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}

            PaperProps={{
              style: {
                width: '422px',
                borderRadius: '5px', //modify

                borderLeft: 'solid', //modify
                borderLeftWidth: '8px',
                borderLeftColor: '#154AB6', //modify
                
                marginTop: 6,
                boxShadow: 'none',
                filter: 'drop-shadow(0px 6px 12px rgba(38, 46, 53, 0.12))',
                '&>div>div': {
                  boxShadow: 'none',
                },
              },
            }}

          // className='dropdown-popover'
          >
            <Box
              sx={{
                width: '414px',
                height: '70px',
                padding: '0px 0px 12px 0px',
                'border-radius': '0px 5px 5px 0px',

              }}>
              <Box
                sx={{
                  ' font-family': 'Open Sans',
                  'font-size': '16px',
                  'font-weight': '700',
                  'line-height': '12px',
                  'letter-spacing': '0em',
                  'text-align': 'left',
                  padding: '12px 12px 10px 12px',
                  height: '33px',
                  color: '#596A7C',
                }}
              >Select Email Template</Box>
              <Stack
                spacing={1.25}
                sx={{ pt: '6px', pb: '6px', pl: '12px', pr: '12px' }}
                data-selenium-id='SIDetail_GeneralInfoSection-ContainerStack3'
              >
                <StyledTypography
                  journalCode={CATJournalDetail.journalCode}
                  type={12}
                >
                  Journal level SI status sharing
                </StyledTypography>
              </Stack>
            </Box>
          </Popover>
        </Box>
      )}
    >
      <InfoList
        sx= {{
          display:'flex',
          flexDirection:'column',
          height:'303px'
        }}
        list={list} />
    </SectionFolder>
  );
}
// @ts-check
import * as React from 'react';
import Box from '@mui/material/Box';
import { StyledTab, StyledTabs } from './CssTabs';

/**
 *
 * @param {import("./NotificationTabs").NotificationTabsProps} props
 * @returns
 */
export default function NotificationTabs(props) {
  const {
    id = 'test', //用于给Tabs上面的两个按钮添加id
    currentTab = 0,
    setCurrentTab = currentTab => {}, //获取当前标签页的index，All 为0，unread 为1，
    children,
    scrollTopDistance,
  } = props;
  const [value, setValue] = React.useState(currentTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentTab(newValue);
  };

  /** @type {React.MutableRefObject<HTMLDivElement |null>} */
  const notificationTabsBodyRef = React.useRef(null);

  React.useEffect(() => {
    setValue(currentTab);
  }, [currentTab]);

  React.useEffect(() => {
    if (notificationTabsBodyRef?.current && scrollTopDistance)
      notificationTabsBodyRef.current.scrollTop = scrollTopDistance;
  }, [scrollTopDistance]);

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'auto',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
      }}
      className='SIMT-NotificationTabs-root'
    >
      <Box sx={{ bgcolor: '#fff' }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label='simt notification tab'
        >
          <StyledTab id={`${id}_all`} label='All' />
          <StyledTab id={`${id}_unread`} label='Unread' />
        </StyledTabs>
        <Box
          className='SIMT-NotificationTabs-body'
          ref={notificationTabsBodyRef}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}

import MyOnlyText from '@/componentsphase2/MyOnlyText';
import { Box, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import NecessaryIcon from './NecessaryIcon';
import { formatNumber } from '../utils';

export default function OnlyTextBlock(props) {
  const {
    id = 'checklist',
    title = 'Comments:',
    value = '',
    setValue = () => {},
    multiline = true,
    maxRows = 2,
    minRows = 2,
    sx,
    editing = true,
    required = false,
    isError = false,
    errorMessage = '',
    radioValue = null,
    ...other
  } = props;

  const [statusFocus, setStatusFocus] = React.useState(false);

  function getErrorMessage(){
    if(value?.length > 100000){
      return 'Input exceeds maximum character limit.';
    }else if(isError){
      return errorMessage;
    }else{
      return '';
    }
  }

  return (!editing && !value) || (editing && !radioValue) ? null : (
    <Stack id={`${id}-OnlyTextBlock`} direction='column' spacing={0.5}>
      <Stack direction='row' spacing={1}>
        <Box
          sx={{
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            latterSpacing: '1%',
            color: '#596A7C',
          }}
        >
          {title}
        </Box>
        {required && <NecessaryIcon />}
      </Stack>

      {editing ? (
        <Box sx={{ position: 'relative', height: '71px' }}>
          <MyOnlyText
            multiline={multiline}
            value={value}
            setValue={setValue}
            maxRows={maxRows}
            minRows={minRows}
            height='53px'
            placeholder='Add comments'
            onFocus={() => {
              setStatusFocus(true);
            }}
            onBlur={() => {
              setStatusFocus(false);
            }}
            isError={!statusFocus && (isError || value?.length > 100000)}
            errorMessage={getErrorMessage()}
            {...other}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              fontFamily: 'Open Sans',
              fontSize: '12px',
              fontWeight: '400',
              lineHeight: '16px',
              letterSpacing: '0px',
              textAlign: 'left',
              color: '#596A7C',
              height: '16px',
            }}
          >{`${formatNumber(value)}/100,000`}</Box>
        </Box>
      ) : (
        <Box
          sx={{
            fontSize: '14px',
            fontWeight: '400px',
            letterSpacing: '-1.1%',
            lineHeight: '16.6px',
            color: '#262E35',
            wordBreak:'break-all'
          }}
        >
          {value}
        </Box>
      )}
    </Stack>
  );
}

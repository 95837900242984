import {
  LOADING,
  ERROR,
  VALID,
  NO_DATA,
  NO_SELECTION_FROM_DROPDOWN_MENU,
  NO_DISPLAYED_DATA,
  NO_DISPLAYED_DATA_FOR_SELECTED_STAGE,
} from '@/pages/Report/chartUtils/constants.js';

export default function useGetDiagramStatusHook(
  chartData,
  filteredChartData,
  selectedCatagories,
  selectedLegendObject,
  hasCategoryFilter,
  alertLabel,
  loading,
  error
) {
  let valid = VALID;
  let info = null;

  // console.log('==chartData=', chartData);
  if (loading) {
    valid = LOADING;
  } else if (error) {
    valid = ERROR;
  } else if (Array.isArray(chartData) && chartData.length === 0) {
    valid = NO_DATA;
    info = alertLabel;
  } else if (
    hasCategoryFilter &&
    Array.isArray(selectedCatagories) &&
    selectedCatagories.length === 0
  ) {
    valid = NO_SELECTION_FROM_DROPDOWN_MENU;
    info = alertLabel;
  } else if (!validateLegend(selectedLegendObject)) {
    valid = NO_SELECTION_FROM_DROPDOWN_MENU;
    info = 'stage';
  } else if (
    !validateFilteredData(filteredChartData, selectedLegendObject, true)
  ) {
    valid = NO_DISPLAYED_DATA;
    info = alertLabel;
  } else if (!validateFilteredData(filteredChartData, selectedLegendObject)) {
    valid = NO_DISPLAYED_DATA_FOR_SELECTED_STAGE;
    info = alertLabel;
  }

  return {
    valid,
    info,
  };
}

/**
 * check if there is any stage are selected
 * @param {*} selectedLegend
 * @returns
 */
const validateLegend = selectedLegend => {
  let hasData = false;

  for (let legend in selectedLegend) {
    hasData ||= selectedLegend[legend];
  }

  return hasData;
};

/**
 * check if there is any data for selected certeria
 * @param {chartData, selectedLegend, checkAllLegend}
 * @returns boolean
 */
const validateFilteredData = (
  chartData,
  selectedLegend,
  checkAllLegend = false
) => {
  let isValid = false;

  for (let category of chartData) {
    for (let legend in selectedLegend) {
      const checked = selectedLegend[legend] || checkAllLegend;
      if (checked) {
        // console.log('===category[legend]===', category[legend]);
        isValid ||= category[legend] !== 0;
        // console.log('===isValid===', isValid);
      }
    }
  }

  // console.log('===isValid===', isValid);
  return isValid;
};

import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, TableCell, Tooltip } from '@mui/material';
import {
  ReportNoData,
  TableBox,
  TableBox2,
  TwoColorTableRowGreen,
  TwoColorTableRowPurple,
} from '../../ReportSpecificComponents/SpecialColorTableHeader';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '../../../../components/DataTable';
import {
  CommonContentCells,
  CommonHeaderFirstRow,
  CommonHeaderSecondRow,
  TotalCommonContentCells,
} from './FYTrendsPageHead';
import TableSkeleton from '../../../../components/TableSkeleton';
import { getFyYearFromDate } from '../../../../utils/date_string';
import monthIds from '../../monthIds';
import {
  findLongestLineSubstring,
  measureWidth,
} from '../../../../utils/StringWidthMeasure';
import { SimpleTooltip } from '../../../../componentsphase2/CustomTooltip';
import {getFYSubjectData} from "@/actions/SIMT-Report/ReportArticle";

export function SubjectRow(props) {
  const { row, isTotal } = props;
  return (
    <>
      {/*第一个cell不是数字*/}

      <StyledTableCell
        align={isTotal ? 'right' : 'left'}
        className={isTotal ? 'RightBorder ReportTotalTextSize' : 'RightBorder'}
        sx={{
          position: 'sticky',
          left: 0,
          zIndex: 6,
          backgroundColor: '#F1F3F5',
        }}
      >
        {isTotal ? (
          <Box>{row.subjectGroup}</Box>
        ) : (
          <SimpleTooltip title={row.subjectGroup}>
            {row.sgAbbr ?? row.subjectGroup}
          </SimpleTooltip>
        )}
      </StyledTableCell>
      {isTotal ? (
        <TotalCommonContentCells row={row} />
      ) : (
        <CommonContentCells row={row} />
      )}
    </>
  );
}

export default function FYSubjectGroupTable() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    subjectData,
    // fiscalYear,
    isOA,
  } = useSelector(state => {
    return {
      subjectData: state.Report.fyTrendsArticle.subjectGroupTableData,
      // fiscalYear: state.Report.fyTrendsArticle.fiscalYear,
      isOA: !state.Report.fyTrendsArticle.subjectGroupShowAll, // showAll 和isOA是反过来的
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};

    for (let item of monthIds) {
      let monthString = intl.messages[`month.${item.id}`];
      let len = measureWidth(monthString, '18px Open Sans');
      widthMap['sub' + item.post] = len;
      widthMap['acc' + item.post] = len;
      widthMap['pub' + item.post] = len;
    }

    const totalWidth = measureWidth('Total', '18px Open Sans');
    widthMap['subTotal'] = totalWidth;
    widthMap['accTotal'] = totalWidth;
    widthMap['pubTotal'] = totalWidth;

    widthMap['subjectGroup'] = measureWidth(
      findLongestLineSubstring('Subject Group'),
      '18px Open Sans'
    );

    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (subjectData && subjectData.length > 0) {
      const maxMap = {};
      for (let dataObj of subjectData) {
        dataObj['subjectGroup'] = dataObj.sgAbbr ?? dataObj.subjectGroup;
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength) {
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [subjectData]);

  const selectMonthDate = useSelector(state => state.Report.selectDate);
  const [fiscalYear, setSelectFY__] = useState(
    getFyYearFromDate(selectMonthDate)
  );
  useEffect(() => {
    if (selectMonthDate != null) {
      const fyYear = getFyYearFromDate(selectMonthDate);
      setSelectFY__(fyYear);
    }
  }, [selectMonthDate]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fiscalYear !== null) {
      setLoading(true);
      async function fetchData() {
        await dispatch(getFYSubjectData(fiscalYear, isOA));
      }
      fetchData().then(_r => {
        setLoading(false);
      });
    }
  }, [dispatch, fiscalYear, isOA]);

  return (
    <TableBox2
      id='BoxTable2'
      data-selenium-id='Report_Articles-FYTrends-FYSubjectGruopTable-BoxTable'
      dataList={[subjectData]}
      tableKey={'Report_Articles-FYTrends-SGTable'}
      footerHeight={0}
    >
      <TableContainer
        id='reportArticlesFYTrendsSGTable'
        data-selenium-id='Report_Articles-FYTrends-FYSubjectGruopTableContanier'
        sx={{
          maxHeight: '550px',
          maxWidth: '100%',
          minHeight: '240px',
        }}
      >
        <Table
          stickyHeader
          data-selenium-id='Report_Articles-FYTrends-FYSubjectGruopTable'
          sx={{
            tableLayout: 'fixed',
            width: 'max-content',
            maxWidth: 'none',
          }}
        >
          <TableHead data-selenium-id='Report_Articles-FYTrends-FYSubjectGruopTableHead'>
            {/*表头的几个部分*/}
            <CommonHeaderFirstRow
              tableName='SubjectGroupTable'
              // 第一个格子是特殊的
              left={[
                {
                  id: 'subjectGroup',
                  label: intl.messages['report.fyTrendsTable.subjectGroup'],
                  minWidth: '180px',
                  maxWidth: '340px',
                  isOrder: false,
                  align: 'left',
                  show: true,
                },
              ]}
              isOA={isOA}
              columnWidthMap={columnWidthMap}
              columnMinWidthMap={columnMinWidthMap}
            />
            <CommonHeaderSecondRow
              isOA={isOA}
              columnWidthMap={columnWidthMap}
              columnMinWidthMap={columnMinWidthMap}
            />
          </TableHead>
          {loading ? (
            <TableBody data-selenium-id='Report_Articles-FYTrends-FYSubjectGruopTableBody'>
              <TableSkeleton
                num={8}
                colSpan={37}
                data-selenium-id='Report_Articles-FYTrends-FYSubjectGruopTableSkeleton'
              />
            </TableBody>
          ) : (
            <TableBody>
              {subjectData != null &&
              subjectData != undefined &&
              subjectData.length != 0 ? (
                subjectData.map((item, rowIndex) => {
                  if (isOA) {
                    return (
                      <TwoColorTableRowGreen key={rowIndex}>
                        <SubjectRow
                          row={item}
                          isTotal={item.subjectGroup === 'Total'}
                        />
                      </TwoColorTableRowGreen>
                    );
                  } else {
                    return (
                      <TwoColorTableRowPurple key={rowIndex}>
                        <SubjectRow
                          row={item}
                          isTotal={item.subjectGroup === 'Total'}
                        />
                      </TwoColorTableRowPurple>
                    );
                  }
                })
              ) : (
                <ReportNoData id='AcquiredAndActiveSiAnnualTrendsBDNoResultTableCell' />
              )}
              <TableRow></TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </TableBox2>
  );
}

import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

export const sipTitleComponents = {
  accept: (
    <FormattedMessage
      id='sipDetail.steps.header.accept'
      data-selenium-id='SIPPage_Components-Step-Title-DetailHeaderAccept'
    />
  ),
  editorReview: (
    <FormattedMessage
      id='sipDetail.steps.header.editorReview'
      data-selenium-id='SIPPage_Components-Step-Title-DetailHeaderEditorReview'
    />
  ),
  initialReview: (
    <FormattedMessage
      id='sipDetail.steps.header.initialReview'
      data-selenium-id='SIPPage_Components-Step-Title-DetailHeaderInitialReview'
    />
  ),
  submit: (
    <FormattedMessage
      id='sipDetail.steps.header.submit'
      data-selenium-id='SIPPage_Components-Step-Title-DetailHeaderSubmit'
    />
  ),
  revision: (
    <FormattedMessage
      id='sipDetail.steps.header.revision'
      data-selenium-id='SIPPage_Components-Step-Title-DetailRevision'
    />
  ),
  completeAccept: (
    <FormattedMessage
      id='sipDetail.steps.header.completeAccept'
      data-selenium-id='SIPPage_Components-Step-Title-DetailHeaderCompleteAccept'
    />
  ),
  completeReject: (
    <FormattedMessage
      id='sipDetail.steps.header.completeReject'
      data-selenium-id='SIPPage_Components-Step-Title-DetailHeaderCompleteReject'
    />
  ),
  shelved: (
    <FormattedMessage
      id='sipDetail.steps.header.shelved'
      data-selenium-id='SIPPage_Components-Step-Title-DetailHeaderShelved'
    />
  ),
  publisherInquiry: (
    <FormattedMessage
      id='sipDetail.steps.header.publisherInquiry'
      data-selenium-id='SIPPage_Components-Step-Title-DetailHeaderPublisherInquiry'
    />
  ),
  start: (
    <FormattedMessage
      id='sipDetail.steps.header.start'
      data-selenium-id='SIPPage_Components-Step-Title-DetailHeaderStart'
    />
  ),
  ideation: (
    <FormattedMessage
      id='sipDetail.steps.header.ideation'
      data-selenium-id='SIPPage_Components-Step-Title-DetailHeaderIdeation'
    />
  ),
};

function SIPStepTitle(props) {
  const { step, color, ...other } = props;
  return (
    <Typography
      data-selenium-id='SIPPage_Components-Step-Title-Typography'
      fontFamily='Open Sans'
      fontSize='14px'
      fontStyle='normal'
      fontWeight={600}
      lineHeight='20px'
      letterSpacing='0.01em'
      color={color ?? 'Primary100.main'}
      textAlign='center'
      {...other}
    >
      {sipTitleComponents[step] ?? step}
    </Typography>
  );
}

SIPStepTitle.propTypes = {
  step: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default SIPStepTitle;

import { useParams } from 'react-router-dom';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';

import {
  Autocomplete,
  Box,
  CardActions,
  CardContent,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  Link,
  ListItem,
  Stack,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { clearCreateGEResult } from '@/actions/SIMT-SI/GuestEditor/GEAction';
import { styled } from '@mui/system';
import { permissionIsAdmin, permissionSiWrite } from '@/utils/authTokenHandler';
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import CreateGEPage from '@/pages/GEPage/Overview/CreateGE';
import {
  formatDateString,
  handleGEName,
  SIDateChangeFunction2 as SIDateChangeFunction,
  SISubmissionDeadlineDateChangeFunction2 as SISubmissionDeadlineDateChangeFunction,
  transferSubmissionUTCDate
} from '@/utils/commonUtils';

import OctagonWarningIcon from '@/assets/OctagonWarningIcon.svg';
import DeadlineChangedIcon from '@/assets/DeadlineChangedIcon.svg';
import { BootstrapSwitch } from '@/components/CreateAndUpdate/MyOperationModule';
import ViewMoreDownArrow from '@/assets/ViewMoreDownArrow.svg';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';

import Popper from '@mui/material/Popper';
import ArrowIcon from '@/assets/Arrow.svg';
import SelectEmailTemplate from '@/componentsphase2/SIDetail/SelectEmailTemplate.jsx';
import ErrorIcon from '@/assets/Error.svg';
import MyTooltip from '@/componentsphase2/SIDetail/MyTooltip.jsx';
import ErrorFlag from '@/assets/SIDetailGEFlagError.svg';
import WarningFlag from '@/assets/SIDetailGEFlagWarning.svg';
import TextFieldErrorIcon from '@/assets/TextFieldErrorIcon.svg';
import { AlertBox } from '@/components/Alert/AlertBox';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import SimpleTooltip from '@/componentsphase2/SimpleTooltip';
import { beforeDays } from '@/utils/date_string';
import { getCE } from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueSystemAdmin";
import { getJournalListWithCodeOptIn, getJournalListWithNameOptIn } from "@/actions/SIMT-SI/SpecialIssue/Journal";
import { getGE } from "@/actions/SIMT-SI/SpecialIssue/GuestEditor";
import { getNewSiMarketinfo, getSIDetail } from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueDetail";
import { get_SIHistory } from "@/actions/SIMT-SI/SpecialIssue/SiEditHistory";
import { get_COGEHistory, get_LeadGEHistory } from "@/actions/SIMT-SI/SpecialIssue/GeEditDetail";
import { EditSIGeneralInfoAdmin, EditSIGeneralInfoT1 } from "@/actions/SIMT-SI/SpecialIssue/SiEditDetail";
import { judgeSubmissionPass } from "@/actions/SIMT-SI/SpecialIssue";
import { getSepcialIssueSource, getSIRevenueModel, getWorkFlowList } from "@/actions/SIMT-SI/SpecialIssue/Config";

import './GeneralInfoSection.css';
import styleModule from './GeneralInfoSection.module.scss';
import SaveAndCancelButton from './Table/SaveAndCancelButton';
import GESelectBox from './GeneralInfoSection/CommonComponents/GESelectBox';
import CustomDatePicker from '../CreateAndUpdateSI/CGTComponents/CustomDatePicker';
import ViewMoreDialog from './ViewMoreDialog';
import GeMenuIcon from '../SpecialIssueManagement/GeMenuIcon';
import { PERMISSIONS } from "@/constant/permission";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    background: '#596A7C',
    fontWeight: 400,
    fontSize: '14px',
    marginBottom: '3px !important',
    borderRadius: '5px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      width: '6px',
      height: '3px',
      backgroundColor: '#596A7C',
    },
  },
});


const STAGE_OF_Idea = 0;
const STAGE_OF_Acquired = 1;
const STAGE_OF_PaperCommission = 2;
const STAGE_OF_Production = 4;
const SingleAutocompleteForSIDetailPage = props => {
  const config = {
    outlinedInputProps: {
      outlinedInputSX: {
        height: '33px',
        padding: '8px  12px',
      },
    },
    endAttchmentProps: {
      endAttchmentSX: {
        top: '8.5px',
      },
    },
    dropListProps: {
      listBoxUlSX: {
        li: {
          color: 'var(--gray-500, #596A7C)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px' /* 166.667% */,
        },
        liActive: {
          color: 'var(--gray-600, #262E35)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px' /* 166.667% */,
        },
        liHover: {
          color: 'var(--gray-600, #262E35)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px' /* 166.667% */,
        },
      },
    },
  };
  return (
    <SinpleAutocomplete {...props} config={{ ...props.config, ...config }} />
  );
};

const JournalSingleAutocompleteForSIDetailPage = props => {
  const config = {
    outlinedInputProps: {
      outlinedInputSX: {
        height: '33px',
        padding: '8px  12px',
      },
    },
    popupIcon: null,
    disableClearable: false,
    endAttchmentProps: {
      endAttchmentSX: {
        top: '8.5px',
      },
    },
    dropListProps: {
      listBoxUlSX: {
        li: {
          color: 'var(--gray-500, #596A7C)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px' /* 166.667% */,
        },
        liActive: {
          color: 'var(--gray-600, #262E35)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px' /* 166.667% */,
        },
        liHover: {
          color: 'var(--gray-600, #262E35)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px' /* 166.667% */,
        },
      },
    },
  };
  return (
    <SinpleAutocomplete {...props} config={{ ...props.config, ...config }} />
  );
};

const GIExample = [
  {
    acquiredDate: '',
    ame: '',
    closedForSubmission: '',
    eoContact: '',
    deadlinePassed: false,
    expectedAcceptanceDate: '',
    expectedPublicationDate: '',
    editorInChief: '',
    editorialDirector: '',
    groupOrTitle: '',
    guestEditors: [],
    handlingCE: '',
    handlingCeUid: 0,
    ideaDate: '',
    journalCode: '',
    journalName: '',
    jpm: '',
    managingEditor: '',
    openForSubmissionDate: '',
    onlineISSN: '',
    paperCommissionMethod: '',
    pe: '',
    previousHandlingCE: [],
    projectCode: '',
    reactiveReason: '',
    remarks: '',
    shelvedReason: '',
    siSource: '',
    specialIssueTitle: '',
    specialIssueType: '',
    stage: '',
    subjectGroup: '',
    submissionDeadline: '',
    workFlow: '',
    siRevenueModel: '',
  },
];

const JournalDetailsInfo = [
  {
    ame: 'string',
    ameEmail: 'string',
    authorGuidelines: 'string',
    businessDivision: 'string',
    editorInChief: 'string',
    editorInChiefEmail: 'string',
    editorialDirector: 'string',
    editorialDirectorEmail: 'string',
    eeoSystemLink: 'string',
    eoContact: 'string',
    jpm: 'string',
    jpmEmail: 'string',
    managingEditor: 'string',
    managingEditorEmail: 'string',
    marketingManager: 'string',
    marketingManagerEmail: 'string',
    ownershipStatus: 'string',
    pe: 'string',
    peEmail: 'string',
    peerReviewModel: 'string',
    pm: 'string',
    pmEmail: 'string',
    primaryUrl: 'string',
    revenueModel: 'string',
    society: 'string',
    subjectGroup: 'string',
    submissionURL: 'string',
    siRevenueModel: 'string',
    pdp: "string",
    pdpEmail: "string",
    pdm: "string",
    pdmEmail: "string",
    pdd: "string",
    pddEmail: "string",
    ppp: "string",
    pppEmail: "string",
    ppm: "string",
    ppmEmail: "string",
    ppd: "string",
    ppdEmail: "string",
    prpp: "string",
    prppEmail: "string",
    prpm: "string",
    prpmEmail: "string",
    prpd: "string",
    prpdEmail: "string",
    ceetp: "string",
    ceetpEmail: "string",
    pvp: "string",
    pvpEmail: "string",
  },
];

//GeneralInfoSection 下拉框选项
const SITypeList = [
  { Id: 1, Name: 'Regular Issue Replacement' },
  { Id: 2, Name: 'Special Section' },
  { Id: 3, Name: 'Supplement' },
  { Id: 4, Name: 'Virtual Issue' },
];

const SISourceList = [
  { Id: 1, Name: 'Attending Conference Or From Research Community' },
  { Id: 2, Name: 'CE-Desk Research' },
  { Id: 3, Name: 'EBMs' },
  { Id: 4, Name: 'EiC' },
  { Id: 5, Name: 'SI Proposal Platform' },
  { Id: 6, Name: 'Others' },
];

const PaperCommissionMethodList = [
  { Id: 1, Name: 'CE Invitation' },
  { Id: 2, Name: 'GE Invitation' },
  { Id: 3, Name: 'Open Call' },
  { Id: 4, Name: 'Hybrid' },
];

const WorkflowList = [
  { Id: 1, Name: 'GE Has no Decision Role in Peer Review' },
  { Id: 2, Name: 'GE Makes Final Decision' },
  { Id: 3, Name: 'GE Works as Temporary AE' },
];

const SIRevenueModelList = [
  { Id: 1, Name: 'Free' },
  { Id: 2, Name: 'OA' },
  { Id: 3, Name: 'Subscription Only' },
  { Id: 4, Name: 'Hybrid Model' },
];

// General Information View
//GeneralInfoSection 左边
const leftSIDetails = [
  {
    key: 'SI Code',
    value: '',
    properity: 'projectCode',
    isEdit: false,
    isTooltip: false,
  },
  {
    key: 'SI Source',
    value: '',
    properity: 'siSource',
    isEdit: true,
    type: 'SELECT',
    SelectData: SISourceList,
    isTooltip: true,
  },
  {
    key: 'Paper Commissioning Method',
    value: '',
    properity: 'paperCommissionMethod',
    isEdit: false, //true,
    type: 'SELECT',
    SelectData: PaperCommissionMethodList,
    isTooltip: true,
  },
  {
    key: 'Workflow',
    value: '',
    properity: 'workFlow',
    isEdit: false, //true,
    type: 'SELECT',
    SelectData: WorkflowList,
    isTooltip: true,
  },
  {
    key: 'SI Publication Format',
    value: '',
    properity: 'specialIssueType',
    isEdit: true,
    type: 'SELECT',
    SelectData: SITypeList,
    isTooltip: true,
  },
];
//GeneralInfoSection 右边
const rightSIDetails = [
  {
    key: 'Journal Name',
    value: '',
    properity: 'journalName',
    isEdit: true,
    isTooltip: true,
  },
  {
    key: 'Journal Code',
    value: '',
    properity: 'journalCode',
    isEdit: true,
    type: 'Jour',
    isTooltip: false,
  },
  {
    key: 'VCH Code',
    value: '',
    properity: 'vchCode', // TODO
    isEdit: false,
    isTooltip: false,
  },
  {
    key: 'Online ISSN',
    value: '',
    properity: 'onlineISSN',
    isEdit: true,
    type: 'onlineISSN',
    isTooltip: false,
  },
  {
    key: 'Group/Title',
    value: '',
    properity: 'groupOrTitle',
    isEdit: false,
    isTooltip: true,
  },
];
//Stockholders  左边
const leftStakeholder = [
  {
    key: 'Lead GE',
    value: '',
    properity: 'leadGuestEditors',
    isEdit: false, //true
    type: 'GE',
    isLead: true,
    isTooltip: false,
  },
  {
    key: 'Co-GE',
    value: '',
    properity: 'coGuestEditors',
    isEdit: true,
    type: 'GE',
    isLead: false,
    isTooltip: false,
  },
  {
    key: 'Editor-in-Chief',
    value: '',
    properity: 'editorInChief',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Publishing Development Lead',
    value: '',
    properity: 'pdp',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Publishing Development Manager',
    value: '',
    properity: 'pdm',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Publishing Development Director',
    value: '',
    properity: 'pdd',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Partner Publishing Lead',
    value: '',
    properity: 'ppp',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Partner Publishing Manager',
    value: '',
    properity: 'ppm',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Partner Publishing Director',
    value: '',
    properity: 'ppd',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'EO Contact',
    value: '',
    properity: 'eoContact',
    isEdit: false,
    type: 'GE',
    isLead: false,
    isTooltip: true,
  },
  {
    key: 'Peer Review Performance Lead',
    value: '',
    properity: 'prpp',
    isEdit: false,
    isTooltip: true,
  },

];
//Stockholders  右边
const rightStakeholder = [
  {
    key: 'Peer Review Performance Manager',
    value: '',
    properity: 'prpm',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Peer Review Performance Director',
    value: '',
    properity: 'prpd',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Comprehensive Editorial Evaluation Team Lead',
    value: '',
    properity: 'ceetp',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Publishing VP',
    value: '',
    properity: 'pvp',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Production Editor (PE)',
    value: '',
    properity: 'pe',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Production Manager (PM)',
    value: '',
    properity: 'pm',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Marketing Manager (MM)',
    value: '',
    properity: 'marketingManager',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Commissioning Editor',
    value: '',
    properity: 'handlingCeFullName',
    isEdit: true,
    type: 'CE',
    isTooltip: true,
  },
  {
    key: 'Previous Commissioning Editor',
    value: '',
    properity: 'previousHandlingCe',
    isEdit: false,
    isTooltip: false,
  },
  {
    key: 'Operations Lead',
    value: '',
    properity: 'operationsLeadFullName',
    isEdit: false,
    isTooltip: false,
  },
  {
    key: 'Screener',
    value: '',
    properity: 'screenerFullName',
    isEdit: false,
    isTooltip: false,
  },
];
//Dates 左边
const rightDates = [
  //modified
  {
    key: 'Submission Deadline',
    value: '',
    properity: 'submissionDeadline',
    isEdit: false, //true,
    type: 'DATE',
    isTooltip: false,
  },
  {
    key: 'Expected Acceptance Date',
    value: '',
    properity: 'expectedAcceptanceDate',
    isEdit: false, //true,
    type: 'DATE',
    isTooltip: false,
  },
  {
    key: 'Expected Publication Date',
    value: '',
    properity: 'expectedPublicationDate',
    isEdit: false, //true,
    type: 'DATE',
    isTooltip: false,
  },
  {
    key: 'Deadline Passed',
    value: '',
    properity: 'deadlinePassed',
    isEdit: false,
    isTooltip: false,
  },
  {
    key: 'Closed for Submission',
    value: '',
    properity: 'closedForSubmission',
    isEdit: false, //true,
    type: 'CHECKBOX',
    isTooltip: false,
  },
];
//Dates 右边
const leftDates = [
  //modified
  {
    key: 'Idea Date',
    value: '',
    properity: 'ideaDate',
    isEdit: true,
    type: 'DATE',
    isTooltip: false,
  },
  {
    key: 'Acquired Date',
    value: '',
    properity: 'acquiredDate',
    isEdit: false, //"Admin",
    type: 'DATE',
    isTooltip: false,
  },
  {
    //2022/9/18 新字段 后台字段openForSubmissionDate
    key: 'Open for Submission Date',
    value: '',
    properity: 'openForSubmissionDate',
    isEdit: false, //"Admin",
    type: 'DATE',
    isTooltip: false,
  },
];
// notes 的三项
const notesItem = [
  {
    key: 'Remarks',
    value: '',
    properity: 'remarks',
    isEdit: true,
    type: 'TEXTAREA',
    isTooltip: false,
  },
  {
    key: 'Shelved Reason',
    value: '',
    properity: 'shelvedReason',
    isEdit: true,
    type: 'TEXTAREA',
    isTooltip: false,
  },
  {
    key: 'Reactive Reason',
    value: '',
    properity: 'reactiveReason',
    isEdit: true,
    type: 'TEXTAREA',
    isTooltip: false,
  },
];

// General Information Edit
// GeneralInfoSection 左边
const leftSIDetailEditItem = [
  {
    key: 'SI Code',
    value: '',
    properity: 'projectCode',
    isEdit: false,
    isTooltip: false,
  },
  {
    key: 'SI Source',
    value: '',
    properity: 'siSource',
    isEdit: true,
    type: 'SELECT',
    SelectData: SISourceList,
    isTooltip: true,
  },
  {
    key: 'SI Source Other',
    value: '',
    properity: 'siSource',
    isEdit: true,
    type: 'OTHER',
    isTooltip: true,
  },
  {
    key: 'Paper Commissioning Method',
    value: '',
    properity: 'paperCommissionMethod',
    isEdit: false, //true,
    type: 'SELECT',
    SelectData: PaperCommissionMethodList,
    isTooltip: true,
  },
  {
    key: 'Workflow',
    value: '',
    properity: 'workFlow',
    isEdit: true, //true,
    type: 'SELECT',
    SelectData: WorkflowList,
    isTooltip: true,
  },
  {
    key: 'SI Publication Format',
    value: '',
    properity: 'specialIssueType',
    isEdit: true,
    type: 'SELECT',
    SelectData: SITypeList,
    isTooltip: true,
  },
  {
    key: 'SI Revenue Model',
    value: '',
    properity: 'siRevenueModel',
    isEdit: true,
    type: 'SELECT',
    SelectData: SIRevenueModelList,
    isTooltip: false,
  },
];
// GeneralInfoSection 右边
const rightSIDetailEditItem = [
  {
    key: 'Journal Name',
    value: '',
    properity: 'journalName',
    isEdit: true,
    isTooltip: true,
  },
  {
    key: 'Journal Code',
    value: '',
    properity: 'journalCode',
    isEdit: true,
    type: 'Jour',
    isTooltip: false,
  },
  {
    key: 'VCH Code',
    value: '',
    properity: 'vchCode',
    isEdit: false,
    isTooltip: false,
  },
  {
    key: 'Online ISSN',
    value: '',
    properity: 'onlineISSN',
    isEdit: true,
    isTooltip: true,
  },
  {
    key: 'Group/Title',
    value: '',
    properity: 'groupOrTitle',
    isEdit: false,
    isTooltip: true,
  },
];
// Stakeholders 左边
const leftStakeholdersEditItem = [
  {
    key: 'Lead GE',
    value: '',
    properity: 'leadGuestEditors',
    isEdit: false, //true
    type: 'GE',
    isLead: true,
    isTooltip: false,
  },
  {
    key: 'Co-GE',
    value: '',
    properity: 'coGuestEditors',
    isEdit: true,
    type: 'GE',
    isLead: false,
    isTooltip: false,
  },
  {
    key: 'Editor-in-Chief',
    value: '',
    properity: 'editorInChief',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Publishing Development Lead',
    value: '',
    properity: 'pdp',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Publishing Development Manager',
    value: '',
    properity: 'pdm',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Publishing Development Director',
    value: '',
    properity: 'pdd',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Partner Publishing Lead',
    value: '',
    properity: 'ppp',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Partner Publishing Manager',
    value: '',
    properity: 'ppm',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Partner Publishing Director',
    value: '',
    properity: 'pdd',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'EO Contact',
    value: '',
    properity: 'eoContact',
    isEdit: false,
    type: 'GE',
    isLead: false,
    isTooltip: true,
  },
  {
    key: 'Peer Review Performance Lead',
    value: '',
    properity: 'prpp',
    isEdit: false,
    isTooltip: true,
  },
];
// Stakeholders 右边
const rightStakeholdersEditItem = [
  {
    key: 'Peer Review Performance Manager',
    value: '',
    properity: 'prpm',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Peer Review Performance Director',
    value: '',
    properity: 'prpd',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Comprehensive Editorial Evaluation Team Lead',
    value: '',
    properity: 'ceetp',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Publishing VP',
    value: '',
    properity: 'pvp',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Production Editor (PE)',
    value: '',
    properity: 'pe',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Production Manager (PM)',
    value: '',
    properity: 'jpm',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Marketing Manager (MM)',
    value: '',
    properity: 'marketingManager',
    isEdit: false,
    isTooltip: true,
  },
  {
    key: 'Commissioning Editor',
    value: '',
    properity: 'handlingCeFullName',
    isEdit: true,
    type: 'CE',
    isTooltip: true,
  },
  {
    key: 'Previous Commissioning Editor',
    value: '',
    properity: 'previousHandlingCe',
    isEdit: false,
    isTooltip: false,
  },
  {
    key: 'Operations Lead',
    value: '',
    properity: 'operationsLeadFullName',
    isEdit: false,
    isTooltip: false,
  },
  {
    key: 'Screener',
    value: '',
    properity: 'screenerFullName',
    isEdit: false,
    isTooltip: false,
  },
];
// Dates 左侧
const leftDatesEditItem = [
  {
    key: 'Submission Deadline',
    value: '',
    properity: 'submissionDeadline',
    isEdit: false,
    type: 'DATE',
    isTooltip: false,
  },
  {
    key: 'Expected Acceptance Date',
    value: '',
    properity: 'expectedAcceptanceDate',
    isEdit: false,
    type: 'DATE',
    isTooltip: false,
  },
  {
    key: 'Expected Publication Date',
    value: '',
    properity: 'expectedPublicationDate',
    isEdit: false,
    type: 'DATE',
    isTooltip: false,
  },
  {
    key: 'Deadline Passed',
    value: '',
    properity: 'deadlinePassed',
    isEdit: false,
    isTooltip: false,
  },
  {
    key: 'Closed for Submission',
    value: '',
    properity: 'closedForSubmission',
    isEdit: false,
    type: 'CHECKBOX',
    isTooltip: false,
  },
];
// Dates 右侧
const rightDatesEditItem = [
  {
    key: 'Idea Date',
    value: '',
    properity: 'ideaDate',
    isEdit: true,
    type: 'DATE',
    isTooltip: false,
  },
  {
    key: 'Acquired Date',
    value: '',
    properity: 'acquiredDate',
    isEdit: false, //"Admin",
    type: 'DATE',
    isTooltip: false,
  },
  {
    key: 'Open for Submission Date',
    value: '',
    properity: 'openForSubmissionDate',
    isEdit: true, //true,
    type: 'DATE',
    isTooltip: false,
  },
];
// notes 的三项
const notesEditItem = [
  {
    key: 'Remarks',
    value: '',
    properity: 'remarks',
    disabled: false,
    maxLength: 5000,
    isEdit: true,
    type: 'TEXTAREA',
    isTooltip: false,
  },
  {
    key: 'Shelved Reason',
    value: '',
    properity: 'shelvedReason',
    disabled: true,
    maxLength: 10000,
    isEdit: true,
    type: 'TEXTAREA',
    isTooltip: false,
  },
  {
    key: 'Reactive Reason',
    value: '',
    properity: 'reactiveReason',
    disabled: true,
    maxLength: 10000,
    isEdit: true,
    type: 'TEXTAREA',
    isTooltip: false,
  },
];

//处理输入信息的格式
const handlingValueFormat = value => {
  if (value === null) {
    value = '';
  } else if (
    typeof value === 'string' &&
    value.indexOf('-') !== -1 &&
    value.indexOf('T') !== -1 &&
    value.indexOf(':') !== -1 &&
    new Date(value)?.toDateString() !== 'Invalid Date'
  ) {
    // value = getUTCToDate(value);
    value = new Date(value);
    value = formatDateString(value);
  } else if (typeof value === 'object') {
    if (Array.isArray(value)) {
      let tmpstr = '';
      for (let i = 0; i < value.length; i++) {
        tmpstr = tmpstr + value[i] + ';';
      }
      value = tmpstr;
    } else {
      value = value.toString();
    }
  } else if (typeof value === 'boolean') {
    value ? (value = 'Yes') : (value = 'No');
  }
  return value;
};

//格式自定义AutoComplete
const CssAutoComplete = styled(Autocomplete)({
  '& .MuiAutocomplete-clearIndicator:hover': {
    background: 'none',
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
  '& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiInputBase-root': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17.5px',
    color: '#262E35',
    padding: '0px 6px',
    width: '286px',
  },
  '& .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall, & .MuiOutlinedInput-input.MuiInputBase-input':
  {
    padding: '0px 41px 0px 6px',
    height: '33px',
  },
  '& .MuiButtonBase-root.MuiIconButton-root path': {
    fill: '#596A7C',
  },
  '& .MuiAutocomplete-popper.MuiPopper-root': {
    marginTop: '6px',
    boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
  },
});

const emptyErrorPrompt = 'Please enter this field.';//错误信息提示

//判断value是否为空值
const isEmptyValue = value => {
  return (
    value === null ||
    value === '' ||
    value === undefined ||
    value.length === 0
  );
};

//所有带有搜索的的文本框
function AutocompleteTextField(props) {
  //getOptionLabel：处理Option获得标签
  //TextFieldLabel：默认的值
  //excuteFunc：获取数据的函数
  //data：数据项
  //setValue：向上级传递选择的数据
  //setGroup：仅用于Handling CE与Group的联动
  const {
    id,
    getOptionLabel,
    TextFieldLabel,
    excuteFunc,
    data,
    setValue,
    setGroup,
    firstTimeClick,
  } = props;
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  //根据输入的数据进行更新数据
  useEffect(() => {

    const timer = setTimeout(() => {
      setLoading(true);
      (async () => {
        setOptions([]);
        await dispatch(excuteFunc(inputValue)); // For demo purposes.
        setLoading(false);
      })();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [inputValue]);

  //当点击打开获取数据
  useEffect(() => {
    if (!open) {
      setOptions([]);
    } else {
      setLoading(true);
      (async () => {
        await dispatch(excuteFunc(inputValue)); // For demo purposes.
        setLoading(false);
      })();
    }
  }, [open]);

  useEffect(() => {
    setOptions([...data]);
  }, [data]);

  return (
    <Box>
      <CssAutoComplete
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border:
              isEmptyValue(inputValue) && firstTimeClick
                ? '2px solid #EE5350'
                : null,
          },
        }}
        clearText=''
        id={id}
        data-selenium-id={id}
        ListboxProps={{
          style: {
            maxHeight: '208px',
            fontFamily: 'Open Sans',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0em',
          },
          sx: {
            'li:last-child': {
              marginBottom: '0 !important',
            },
            '::-webkit-scrollbar': {
              width: '12px',
            },
            '::-webkit-scrollbar-thumb': {
              border: '3px solid #fff',
            },
          },
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={options}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option, state) => (
          <li
            {...props}
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '4px',
              height: '30px',
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '12px',
              fontSize: '12px',
              lineHeight: '9px',
              marginBottom: '4px',
            }}
          >
            <div>{option.username}</div>
            <div>{option.email}</div>
          </li>
        )}
        defaultValue={TextFieldLabel}
        autoComplete
        includeInputInList
        filterSelectedOptions
        loading={loading}
        filterOptions={x => x}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue?.email);
          if (typeof setGroup === 'function') {
            setGroup(newValue?.group);
          }
        }}
        onInputChange={(e, newInputValue) => {
          setInputValue(newInputValue);
        }}
        popupIcon={null}
        renderInput={params => <TextField {...params} />}
        className={styleModule.autocompleteTextField}
        PopperComponent={props => (
          <Popper
            {...props}
            sx={{
              '&.MuiAutocomplete-popper': {
                inset: '6px auto !important',
              },
              '&>.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAutocomplete-paper':
              {
                boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
              },
            }}
          />
        )}
      />
      {isEmptyValue(inputValue) && firstTimeClick ? (
        <Stack
          direction='row'
          spacing='2px'
          alignItems='flex-Start'
          justifyContent='flex-start'
        >
          <Stack
            alignItems='center'
            justifyContent='center'
            sx={{
              marginTop: '2px',
            }}
          >
            <TextFieldErrorIcon />
          </Stack>
          <Box
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '16px',
              color: '#EE5350',
              letterSpacing: '-0.084px',
            }}
          >
            {emptyErrorPrompt}
          </Box>
        </Stack>
      ) : null}
    </Box>
  );
}

function BasicSelect(props) {
  //list：下拉框选项
  const { id, list, value, setValue, ...others } = props;

  return (
    <Box
      id={id}
      data-selenium-id={id}
      sx={{ width: '286px' }} //modified
    >
      <FormControl
        sx={{
          width: '286px',
        }}
        fullWidth
        data-selenium-id='SIDetail_GeneralInfoSection-BasicSelect-FormControls'
      >
        <SingleAutocompleteForSIDetailPage
          value={value}
          onChange={(e, value) => {
            setValue(value);
          }}
          options={list.map((value, index) =>
            (isEmptyValue(value.Name) ? value.value : value.Name).toString()
          )}
          needKeyboardDelete={false}
          defaultQuery={true}
          needClearInputValue={true}
          readOnly={true}
          {...others}
        />
      </FormControl>
    </Box>
  );
}

//自定义的文本框
const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '120%',
    letterSpacing: '-0.011em',
    color: '#505050',
    maxHeight: 33,
  },
});

function BasicTextField(props) {
  const { id, value, setValue } = props;

  const handleChange = e => {
    if (e.target.value.length <= 400) {
      setValue(e.target.value);
    }
  };

  return (
    <CssTextField
      id={id}
      data-selenium-id={id}
      value={value}
      variant='outlined'
      onChange={handleChange}
    />
  );
}

//GE的选择框，与AutoComplete类似的用法
function GETextFiled(props) {
  const {
    id,
    getOptionLabel,
    TextFieldLabel,
    excuteFunc,
    selectlist,
    data,
    setValue,
    handleClick, //modified
    type,
    firstTimeClick,
    stageId
  } = props;
  const [selectValue, setSelectValue] = useState([]);
  const selector = state => {
    return {
      configInfo: state.SystemConfig.systemConfig,
    };
  };
  const { configInfo } = useSelector(selector);

  const maxGeNumber =
    isEmptyValue(configInfo) ? 20 : configInfo.eachSiGuestEditorMaxNumber;

  useEffect(() => {
    setSelectValue(data);
  }, [data]);

  return type === 'LeadGE' || type === 'COGE' ? (
    <Box>
      <GESelectBox
        emptyErr={
          stageId !== 0 && type === 'LeadGE' && isEmptyValue(selectValue) && firstTimeClick
        }
        type={type}
        id={id}
        options={selectlist}
        getOptionLabel={getOptionLabel}
        defaultValue={TextFieldLabel}
        excuteFunc={excuteFunc}
        onChange={(event, newValue) => {
          //判断GE是否有重复(使用邮箱比对进行判断，如果重复不能进入 handleGeSelect 进行填充)
          function judgeRepetition() {
            if (newValue.length === 0 || newValue.length === 1) {
              return true;
            } else {
              for (let i = 1; i < newValue.length; i++) {
                if (
                  newValue[newValue.length - 1].primaryEmail ===
                  newValue[i - 1].primaryEmail
                ) {
                  return false;
                }
              }
              return true;
            }
          }
          // GE数量小于20，以及不重复时记录填充给操作
          if (newValue.length < maxGeNumber + 1 && judgeRepetition()) {
            setValue(newValue);
          }
        }}
        Value={selectValue}
        handleAddNewClick={handleClick}
      />

      {type === 'LeadGE' && isEmptyValue(selectValue) && firstTimeClick && stageId !== 0 ? (
        <Stack
          direction='row'
          spacing='2px'
          alignItems='flex-Start'
          justifyContent='flex-start'
        >
          <Stack
            alignItems='center'
            justifyContent='center'
            sx={{
              marginTop: '2px',
            }}
          >
            <TextFieldErrorIcon />
          </Stack>
          <Box
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '16px',
              color: '#EE5350',
              letterSpacing: '-0.084px',
            }}
          >
            {emptyErrorPrompt}
          </Box>
        </Stack>
      ) : null}
    </Box>
  ) : null;
}

function JournalNameTextField(props) {
  const { TextFieldLabel, journalSelect, handleJounalSearch, isEmpty } = props;
  const selector = state => {
    return {
      journalList: state.SI.JourList,
    };
  };
  const { journalList } = useSelector(selector);
  const [options, setOptions] = useState([]);

  const loadOptions = newText => {
    return getJournalListWithNameOptIn(newText, 1);
  };

  useEffect(() => {
    setOptions([...journalList]);
  }, [journalList]);

  return (
    <FormControl
      sx={{
        width: '286px',
      }}
      fullWidth
      data-selenium-id='SIDetail_GeneralInfoSection-BasicSelect-JournalName-FormControls'
    >
      <JournalSingleAutocompleteForSIDetailPage
        clearText=''
        id='siDetailPageGISJournalNameInput'
        data-selenium-id='SIDetail_GeneralInfoSection-JournalNameInput'
        options={
          isEmptyValue(options) ?
            [{ journalCode: 'Temporary', journalName: 'Temporary' }] : options
        }
        defaultQuery={false}
        loadOptionAction={loadOptions}
        getOptionLabel={option => {
          return option.journalName;
        }}
        defaultValue={TextFieldLabel}
        value={journalSelect} //modified
        isErr={isEmpty}
        isEmpty={isEmpty}
        onChange={(event, newValue) => {
          handleJounalSearch(newValue);
        }}
      />

      {isEmpty ? (
        <Stack
          direction='row'
          spacing='2px'
          alignItems='flex-Start'
          justifyContent='flex-start'
        >
          <Stack
            alignItems='center'
            justifyContent='center'
            sx={{
              marginTop: '2px',
            }}
          >
            <TextFieldErrorIcon />
          </Stack>
          <Box
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '16px',
              color: '#EE5350',
              letterSpacing: '-0.084px',
            }}
          >
            {emptyErrorPrompt}
          </Box>
        </Stack>
      ) : null}
    </FormControl>
  );
}

function JournalCodeTextField(props) {
  const { TextFieldLabel, journalSelect, handleJounalSearch, isEmpty } = props;
  const selector = state => {
    return {
      journalList: state.SI.JourList,
    };
  };

  const { journalList } = useSelector(selector);
  const [options, setOptions] = useState([]);
  const loadOptions = (newText) => {
    return getJournalListWithCodeOptIn(newText, 1);
  };

  useEffect(() => {
    setOptions([...journalList]);
  }, [journalList]);

  return (
    <FormControl
      sx={{
        width: '286px',
      }}
      fullWidth
      data-selenium-id='SIDetail_GeneralInfoSection-BasicSelect-JournalCode-FormControls'
    >
      <JournalSingleAutocompleteForSIDetailPage
        clearText=''
        id='siDetailPageGISJournalCodeInput'
        data-selenium-id='SIDetail_GeneralInfoSection-JournalCodeInput'
        clearOnBlur
        options={isEmptyValue(options) ?
          [{ journalCode: 'Temporary', journalName: 'Temporary' }] : options
        }
        defaultQuery={false}
        loadOptionAction={loadOptions}
        getOptionLabel={option => {
          return option.journalCode;
        }}
        defaultValue={TextFieldLabel}
        value={journalSelect} //modified
        isErr={isEmpty}
        isEmpty={isEmpty}
        onChange={(event, newValue) => {
          handleJounalSearch(newValue);
        }}
      />
      {isEmpty ? (
        <Stack
          direction='row'
          spacing='2px'
          alignItems='flex-Start'
          justifyContent='flex-start'
        >
          <Stack
            alignItems='center'
            justifyContent='center'
            sx={{
              marginTop: '2px',
            }}
          >
            <TextFieldErrorIcon />
          </Stack>
          <Box
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '16px',
              color: '#EE5350',
              letterSpacing: '-0.084px',
            }}
          >
            {emptyErrorPrompt}
          </Box>
        </Stack>
      ) : null}
    </FormControl>
  );
}

//备注文本框
const CssNotesTextField = styled(TextField)({
  '&': {
    width: '100%',
  },
  '& .MuiOutlinedInput-root': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#505050',
    paddingTop: '9px',
    paddingBottom: '9px',
  },
  '& .MuiFormHelperText-root': {
    textAlign: 'right',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    maxWidth: '100%',
    textAlign: 'left',
  },
});

//在千分位添加逗号，用于RemarkText的helperText
function format(num) {
  var reg = /\d{1,3}(?=(\d{3})+$)/g;
  return (num + '').replace(reg, '$&,');
}

//用千分位分隔符格式化数字
const formatNumberWithMillennials = length => {
  let millennials = 1000;
  if (length < millennials) {
    return length;
  }
  let Millimeter = parseInt(length / millennials);
  let unit = length % millennials;
  return Millimeter + ',' + (Array(3).join(0) + unit).slice(-3);
};

const RSRMaxLength = 50; //Reactive Reason && Shelved Reason && Remarks的最大长度


export function NotesTextField(props) {
  const {
    id,
    itemKey,
    value,
    setValue,
    setErr,
    maxLength,
    InputSX,
    minRows = 2.5,
    maxRows = 50,
    placeholder = '',
  } = props;
  const [tooLongError, setTooLongError] = useState(false);

  const handleChange = event => {
    setValue(event.target.value);
  };

  const helpTextBuilder = () => {
    let length = isEmptyValue(value) ? 0 : value.length;
    return `${format(length)}/${formatNumberWithMillennials(maxLength)}`;
  };

  const handleBlur = () => {
    let length = isEmptyValue(value) ? 0 : value.length;
    if (length > maxLength) {
      setErr(true);
      setTooLongError(true);
    } else {
      setErr(false);
      setTooLongError(false);
    }
  };

  return (
    <CssNotesTextField
      id={id}
      data-selenium-id={id}
      multiline
      placeholder={placeholder}
      minRows={minRows} //modified
      maxRows={maxRows}
      disabled={props.disabled}
      helperText={
        tooLongError
          ? `[${itemKey}] should not exceed ${formatNumberWithMillennials(
            maxLength
          )} characters in length!`
          : helpTextBuilder()
      }
      sx={{
        '& .MuiFormHelperText-root': {
          fontSize: '10px',
        },
        ...InputSX,
      }}
      value={value}
      onChange={handleChange}
      error={tooLongError}
      onBlur={handleBlur}
    />
  );
}
// 小标题
const CustomPartTitle = props => {
  const { children } = props;
  return (
    <Typography
      component='h4'
      marginBottom={2}
      sx={{
        fontColor: '#262E35',
        fontSize: '16px',
        fontWeight: '600',
      }}
      data-selenium-id='SIDetail_GeneralInfoSection-CustomPartTitle'
    >
      {children}
    </Typography>
  );
};

const StyledTypography = props => {
  const { siCode } = useParams();
  return (
    <a
      href={`/simt/auth/siRelated/emailSend?siCode=${siCode}&type=${props.type}`}
      style={{ textDecoration: 'none' }}
      target='_blank'
      data-selenium-id='SIDetail_GeneralInfoSection-StyledTypographyLink'
    >
      <Typography
        sx={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '20px',
          cursor: 'pointer',
          color: '#596A7C',
          textDecoration: 'none',
        }}
        data-selenium-id='SIDetail_GeneralInfoSection-StyledTypography'
      >
        {props.children}
        <ArrowIcon
          id='SIDetail_GeneralInfoSection-StyledTypography-ArrowIcon'
          data-selenium-id='SIDetail_GeneralInfoSection-StyledTypography-ArrowIcon'
        />
      </Typography>
    </a>
  );
};


const otherMaxLength = 200;//other输入框可输入的最大字符数
//针对不同的表项，返回不同的编辑框
const EditBox = ({ item, value, editEnvs }) => {
  const {
    infoObj,
    stageId,
    CEResult,
    setHandleCeEmail,
    setGroup,
    firstTimeClick,
    selectTypeMap,
    setFun,
    valueFun,
    SISourceOther,
    getMinDate,
    getMaxDate,
    checkDateIfError,
    getDateErrorMessage,
    setCustomDatePickerValue,
    codeDateValue,
    handleGEStringToObject,
    GEResult,
    LeadGE,
    COGE,
    setLeadGE,
    setCOGE,
    handleCreateOpen,
    handleCheckedChange,
    closeForSub,
    remarkErr,
    setRemarkErr,
  } = editEnvs;
  switch (item.type) {
    case 'CE':
      return (
        <AutocompleteTextField
          id={`siDetailPageGIS${item.key !== undefined
            ? item.key.replace(new RegExp(' ', 'g'), '')
            : item.key
            }Input`}
          data-selenium-id={`SIDetail_GIS-${item.key !== undefined
            ? item.key.replace(new RegExp(' ', 'g'), '')
            : item.key
            }Input`}
          TextFieldLabel={{
            username: value,
            email: infoObj[0]['handlingCeEmail'],
          }}
          excuteFunc={getCE}
          data={CEResult}
          getOptionLabel={option => {
            return option.username + '(' + option.email + ')';
          }}
          setValue={setHandleCeEmail}
          setGroup={setGroup}
          firstTimeClick={firstTimeClick}
        />
      );
    case 'SELECT':
      return (
        <BasicSelect
          id={`siDetailPageGIS${item.key !== undefined
            ? item.key.replace(new RegExp(' ', 'g'), '')
            : item.key
            }SelectInput`}
          data-selenium-id={`SIDetail_GIS-${item.key !== undefined
            ? item.key.replace(new RegExp(' ', 'g'), '')
            : item.key
            }SelectInput`}
          list={selectTypeMap[item.key]}
          setValue={setFun(item.key)}
          value={valueFun(item.key)}
          needKeyboardDelete={
            item.key === 'Paper Commissioning Method' &&
            infoObj[0]['stage'] === 'Idea/Proposal'
          }
          deleteValue={() => {
            if (item.key === 'Paper Commissioning Method') {
              setFun(item.key)('');
            }
          }}
          isEmpty={isEmptyValue(valueFun(item.key))}
        />
      );
    case 'TEXT':
      return (
        <BasicTextField
          id={`siDetailPageGIS${item.key !== undefined
            ? item.key.replace(new RegExp(' ', 'g'), '')
            : item.key
            }TextInput`}
          data-selenium-id={`SIDetail_GIS-${item.key !== undefined
            ? item.key.replace(new RegExp(' ', 'g'), '')
            : item.key
            }TextInput`}
          value={valueFun(item.key)}
          setValue={setFun(item.key)}
        />
      );
    case 'OTHER':
      return (
        <Stack spacing='2px'>
          <MyOnlyText
            value={valueFun(item.key)}
            setValue={value => {
              if ((value?.length ?? 0) > otherMaxLength) {
                setFun(item.key)(value.substring(0, otherMaxLength));
              } else {
                setFun(item.key)(value);
              }
            }}
            width={'286px'}
            handleIconButtonClick={() => {
              setFun(item.key)('');
            }}
            multiline={true}
            maxRows={10}
            needEndAdornment={false}
            placeholder={'Please specify.'}
            isError={
              (firstTimeClick &&
                isEmptyValue(SISourceOther)) ||
              valueFun(item.key)?.length > otherMaxLength
            }
            errorMessage={
              valueFun(item.key)?.length > otherMaxLength ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '274px',
                    height: '16px',
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: '#ee5350',
                  }}
                >
                  <div>Should no more than {otherMaxLength}</div>
                  <div>{valueFun(item.key)?.length || 0}/{otherMaxLength}</div>
                </div>
              ) : emptyErrorPrompt
            }
          />
          {valueFun(item.key)?.length > otherMaxLength || (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                height: '16px',
                fontSize: '12px',
                lineHeight: '16px',
              }}
            >
              {valueFun(item.key)?.length || 0}/{otherMaxLength}
            </div>
          )}
        </Stack>
      );
    case 'DATE':
      const hasDateError =
        firstTimeClick && checkDateIfError(item.key, stageId);
      return (
        <Stack
          direction='column'
          alignItems='flex-start'
          justifyContent='center'
          spacing='2px'
        >
          <CustomDatePicker
            id={`${item.key ?? item.properity ?? ''}-datepicker`}
            onChange={(Date, UTCDate) => {
              setFun(item.key)(UTCDate);
            }}
            // 在此加日期限制
            minDateStr={handlingValueFormat(getMinDate(item.key))}
            maxDateStr={handlingValueFormat(getMaxDate(item.key))}
            value={codeDateValue(setCustomDatePickerValue(item.key))}
            error={hasDateError}
            handleFocus={() => {
            }}
            handleBlur={() => {
            }}
            mergeIntoDocumentFlow={false}
            needClear={true}
            height={'33px'}
          ></CustomDatePicker>
          {hasDateError ? (
            <Stack
              direction='row'
              spacing='2px'
              alignItems='flex-Start'
              justifyContent='flex-start'
              sx={{
                maxWidth: '100%',
              }}
            >
              <Stack
                alignItems='center'
                justifyContent='center'
                sx={{
                  marginTop: '2px',
                }}
              >
                <TextFieldErrorIcon />
              </Stack>
              <Box
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '16px',
                  color: '#EE5350',
                  letterSpacing: '-0.084px',
                }}
              >
                {getDateErrorMessage(item.key, stageId)}
              </Box>
            </Stack>
          ) : null}
        </Stack>
      );
    case 'GE':
      return (
        <Box width='286px'>
          <GETextFiled
            id={`siDetailPageGIS${item.key !== undefined
              ? item.key.replace(new RegExp(' ', 'g'), '')
              : item.key
              }TextInput`}
            data-selenium-id={`SIDetail_GIS-${item.key !== undefined
              ? item.key.replace(new RegExp(' ', 'g'), '')
              : item.key
              }TextInput`}
            TextFieldLabel={handleGEStringToObject(value)}
            excuteFunc={getGE}
            selectlist={GEResult}
            data={item.isLead ? LeadGE : COGE}
            getOptionLabel={option => {
              return option.lastName + ',' + option.firstName;
            }}
            setValue={item.isLead ? setLeadGE : setCOGE}
            handleClick={() => {
              handleCreateOpen(item.isLead ? LeadGE : COGE, item.isLead);
            }}
            type={item.isLead ? 'LeadGE' : 'COGE'}
            firstTimeClick={firstTimeClick}
            stageId={stageId}
          />
        </Box>
      );

    case 'CHECKBOX':
      return (
        <BootstrapSwitch
          onClick={handleCheckedChange}
          checked={closeForSub === 'Yes'}
          sx={{
            '&.MuiSwitch-root .MuiSwitch-track:before':
              closeForSub === 'Yes'
                ? {}
                : {
                  width: '100%',
                  right: '6px',
                  textAlign: 'right',
                  content: '"No"',
                },
          }}
          data-selenium-id='SIDetail_GeneralInfoSection-BootstrapSwitch'
        />
      );
    case 'TEXTAREA':
      return (
        <NotesTextField
          id={`siDetailPageGIS${item.key !== undefined
            ? item.key.replace(new RegExp(' ', 'g'), '')
            : item.key
            }RemarkTextInput`}
          data-selenium-id={`SIDetail_GIS-${item.key !== undefined
            ? item.key.replace(new RegExp(' ', 'g'), '')
            : item.key
            }RemarkTextInput`}
          itemKey={item.key}
          value={valueFun(item.key)}
          setValue={setFun(item.key)}
          disabled={item.disabled}
          maxLength={item.maxLength}
          error={remarkErr}
          setErr={setRemarkErr}
        />
      );
    default:
      return null;
  }
};

//对于各表项值的返回
const EditPart = ({ items, editEnvs }) => {
  const {
    infoObj,
    firstTimeClick,
    deadlinePassed,
    subDeadline,
    checkDateValue,
    group,
    JPM,
    SubjectGroup,
    vchCode,
    handleViewMore,
    journalCodeSelect,
    journalNameSelect,
    journalSelect,
    handleJounalSearch,
    onlineISSN,
    createGEOpen,
    handleCreateClose,
    createGeIsLead,
    setJournalSelect,
  } = editEnvs;

  // 当表项不可编辑或者可编辑的权限为Admin
  const boxStyle = {
    width: '286px', //modified
    fontWeight: 400,
    minHeight: '28px',
    display: 'flex',
    alignItems: 'center',
    color: '#596A7C',
  };

  if (items.isEdit === false || items.isEdit === 'Admin') {
    // 当表项为AcquiredDate并且权限为Admin
    if (
      items.key === 'Acquired Date' &&
      (isEmptyValue(infoObj[0][items.properity]) || permissionIsAdmin())
    ) {
      return (
        <Box
          sx={boxStyle}
          data-selenium-id='SIDetail_GeneralInfoSection-ConatinerBox'
        >
          <EditBox
            item={items}
            value={handlingValueFormat(infoObj[0][items.properity])}
            editEnvs={editEnvs}
          />
        </Box>
      );
    }
    else if (items.key === 'Deadline Passed') {
      //当表项为Deadline Passed根据不同的值有不同的颜色
      if (deadlinePassed) {
        return (
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            id='siDetailPageGISDeadlinePassed'
            data-selenium-id='SIDetail_GeneralInfoSection-DeadlinePassed'
            height='28px'
          >
            <span style={{ color: '#596A7C', fontWeight: '400' }}>
              {handlingValueFormat(deadlinePassed)}
            </span>
            <OctagonWarningIcon data-selenium-id='SIDetail_GeneralInfoSection-OctagonWarningIcon' />
          </Stack>
        );
      } else if (
        infoObj[0]['submissionDeadline'] != null ||
        checkDateValue(subDeadline)
      ) {
        return (
          <Box
            id='siDetailPageGISDeadlinePassed'
            data-selenium-id='SIDetail_GeneralInfoSection-DeadlinePassed'
            style={{ 'font-size': '14px' }}
            sx={boxStyle}
          >
            {handlingValueFormat(deadlinePassed)}
          </Box>
        );
      } else {
        return (
          <Box
            id='siDetailPageGISDeadlinePassed'
            data-selenium-id='SIDetail_GeneralInfoSection-DeadlinePassedBox'
          ></Box>
        );
      }
    }
    else if (items.key === 'Group/Title') {
      return (
        <Box
          id='siDetailPageGISGroupTitle'
          data-selenium-id='SIDetail_GeneralInfoSection-GroupTitle'
          sx={boxStyle}
        >
          {group}
        </Box>
      );
    }
    else if (items.key === 'JPM') {
      //当表项为JPM，值从变量中读取，不从infoObj读取
      return (
        <Box
          id='siDetailPageGISJPM'
          data-selenium-id='SIDetail_GeneralInfoSection-JPM'
          sx={boxStyle}
        >
          {JPM}
        </Box>
      );
    }
    else if (items.key === 'Subject Group') {
      //当表项为SubjectGroup，值从变量中读取，不从infoObj读取
      return (
        <Box
          id='siDetailPageGISSubjectGroup'
          data-selenium-id='SIDetail_GeneralInfoSection-SubjectGroup'
          sx={boxStyle}
        >
          {SubjectGroup}
        </Box>
      );
    }
    else if (items.key === 'VCH Code') {
      //当表项为VCH Code，值从useState中读取，不从infoObj读取
      return (
        <Box
          id='siDetailPageGISJPM'
          data-selenium-id='SIDetail_GeneralInfoSection-JPM'
          sx={boxStyle}
        >
          {vchCode}
        </Box>
      );
    }
    else {
      //其余不可编辑表项正常返回值
      return (
        <Box
          id={`siDetailPageGIS${items.key?.replace(
            new RegExp(' ', 'g'),
            ''
          )}Text`}
          data-selenium-id={`SIDetail_GIS-${items.key?.replace(
            new RegExp(' ', 'g'),
            ''
          )}Text`}
          sx={
            items.key === 'Reactive Reason' ||
              items.key === 'Shelved Reason' ||
              items.key === 'Remarks'
              ? {
                ...boxStyle,
                flexDirection: 'column',
                alignItems: 'start',
                maxWidth: '792px',
                flexGrow: 1,
                gap: '4px',
                '& > .MuiBox-root:first-child': {
                  // height: '42px',  //modified
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  lineHeight: '20px',
                },
              }
              : boxStyle
          }
        >
          <Box
            sx={{
              width: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox1'
          >
            {handlingValueFormat(infoObj[0][items.properity])}
          </Box>
          {/*只在Reactive和shelved两个reason底下显示view more，且他们的长度需要超过20字符*/}
          {(items.key === 'Reactive Reason' ||
            items.key === 'Shelved Reason' ||
            items.key === 'Remarks') &&
            infoObj[0][items.properity]?.length > RSRMaxLength && (
              <Box
                fontSize={12}
                data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox'
              >
                <Link
                  component='button'
                  onClick={handleViewMore.bind(
                    this,
                    items.key,
                    items.properity
                  )}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#154AB6',
                    textDecoration: 'none',
                    gap: '4px',
                  }}
                  data-selenium-id='SIDetail_GeneralInfoSection-Link2'
                >
                  <Box data-selenium-id='SIDetail_GeneralInfoSection-ViewAllBox'>
                    View All
                  </Box>
                  <ViewMoreDownArrow data-selenium-id='SIDetail_GeneralInfoSection-ViewMoreDownArrow' />
                </Link>
              </Box>
            )}
        </Box>
      );
    }
  } else {
    //当可编辑表项为Journal Name
    if (items.key === 'Journal Name') {
      return (
        <Box
          sx={boxStyle}
          data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox'
        >
          <JournalNameTextField
            TextFieldLabel={{
              journalName:
                isEmptyValue(handlingValueFormat(infoObj[0][items.properity]))
                  ? ''
                  : handlingValueFormat(infoObj[0][items.properity]),
            }}
            isEmpty={
              firstTimeClick &&
              isEmptyValue(journalNameSelect)
            }
            journalNameSelect={journalNameSelect}
            journalCodeSelect={journalCodeSelect}
            journalSelect={journalSelect}
            handleJounalSearch={handleJounalSearch}
            setJournalSelect={setJournalSelect}
            data-selenium-id='SIDetail_GeneralInfoSection-JournalNameTextField'
          />
        </Box>
      );
      //当可编辑表项为Journal Code
    }
    else if (items.key === 'Journal Code') {
      return (
        <Box
          sx={boxStyle}
          data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox'
        >
          <JournalCodeTextField
            TextFieldLabel={{
              journalName: handlingValueFormat(infoObj[0][items.properity]),
            }}
            isEmpty={
              firstTimeClick &&
              isEmptyValue(journalCodeSelect)
            }
            journalNameSelect={journalNameSelect}
            journalCodeSelect={journalCodeSelect}
            journalSelect={journalSelect}
            handleJounalSearch={handleJounalSearch}
            setJournalSelect={setJournalSelect}
            data-selenium-id='SIDetail_GeneralInfoSection-JournalCodeTextField'
          />
        </Box>
      );
    }
    else if (items.key === 'Online ISSN') {
      return (
        <Box
          sx={boxStyle}
          data-selenium-id='SIDetail_GeneralInfoSection-OnlineISSN'
        >
          {onlineISSN}
        </Box>
      );
    }
    else if (items.key === 'Co-GE') {
      //当为其余可编辑表项时，按照EditBox中的返回
      return (
        <Box
          id='siDetailPageGISCEGEInput'
          data-selenium-id='SIDetail_GeneralInfoSection-CEGEInput'
          sx={boxStyle}
        >
          <EditBox
            item={items}
            value={handlingValueFormat(infoObj[0][items.properity])}
            editEnvs={editEnvs}
          />
          {/* GE Pop-up */}
          {createGEOpen && (
            <CreateGEPage
              open={createGEOpen}
              handleCreateGEPageClose={handleCreateClose}
              isLead={createGeIsLead}
              data-selenium-id='SIDetail_GeneralInfoSection-CreateGEPage'
            />
          )}
        </Box>
      );
    }
    else if (
      items.key === 'Remarks' ||
      items.key === 'Reactive Reason' ||
      items.key === 'Shelved Reason'
    ) {
      return (
        <Box
          sx={{
            ...boxStyle,
            maxWidth: 'min(calc(50% + 286px),860px,calc(100vw - 400px))',
            flexGrow: 1,
          }} //modified
          data-selenium-id='SIDetail_GeneralInfoSection-EditBox'
        >
          <EditBox
            item={items}
            value={handlingValueFormat(infoObj[0][items.properity])}
            editEnvs={editEnvs}
          />
        </Box>
      );
    }
    else {
      return (
        <Box
          sx={boxStyle}
          data-selenium-id={
            handlingValueFormat(infoObj[0][items.properity])
              ? 'SIDetail_GeneralInfoSection-EditBoxOther'
              : 'SIDetail_GeneralInfoSection-EditBoxEmpty'
          }
        >
          <EditBox
            item={items}
            value={handlingValueFormat(infoObj[0][items.properity])}
            editEnvs={editEnvs}
          />
        </Box>
      );
    }
  }
};

// General Section 主体部分 在展示和编辑进行切换
export default function GeneralInfoSection(props) {
  const {
    siId,
    stageId,
    title,
    loadingData,
    setAddMarketSection,
    setFirstOpenMarketSection,
    sideMenuOpen,
    needNotes = true,
  } = props;
  const [isFold, setIsFold] = useState(true);
  const [editType, setEditType] = useState(false);
  const [infoObj, setInfoObj] = useState(GIExample);
  const [journalDetails, setJournalDetails] = useState(JournalDetailsInfo);
  const dispatch = useDispatch();
  const [firstTimeClick, setFirstTimeClick] = useState(false);
  const [submitIsClicked, setSubmitIsClicked] = useState(false);

  const selector = state => {
    return {
      detailInfo: state.SI.detailInfo,
    };
  };
  const { detailInfo } = useSelector(selector);
  const handleEditClick = () => {
    setEditType(true);
  };
  const childRef = useRef();
  //出错标记
  const [remarkErr, setRemarkErr] = useState(false);
  const [error, setError] = useState(false);
  const [SIDetailIsSaving, setSIDetailIsSaving] = useState(false);

  useEffect(() => {
    if (detailInfo.generalInformation !== undefined) {
      if (detailInfo.journalDetails !== undefined) {
        const newInfoObj = [];
        newInfoObj[0] = {
          ...detailInfo.journalDetails[0],
          ...detailInfo.generalInformation[0],
        };
        setInfoObj(newInfoObj);
      } else {
        setInfoObj(detailInfo.generalInformation);
      }
      if (!isEmptyValue(detailInfo.generalInformation[0].stage)) {
        leftSIDetailEditItem[2]['isEdit'] = true; // Paper Commissioning Method
        leftSIDetailEditItem[3]['isEdit'] = true; // Workflow
        leftStakeholdersEditItem[0]['isEdit'] = true; // Lead GE
        leftDatesEditItem[0]['isEdit'] = true; // Submission Deadline
        leftDatesEditItem[1]['isEdit'] = true; // Expected Acceptance Date
        leftDatesEditItem[2]['isEdit'] = true; // Expected Publication Date
        leftDatesEditItem[4]['isEdit'] = true; // Closed for Submission
        rightDatesEditItem[1]['isEdit'] = 'Admin'; // Acquired Date
      }
    }
  }, [detailInfo]);

  const matches1168 = useMediaQuery('(min-width:1168px)');
  const matches1368 = useMediaQuery('(min-width:1368px)');
  const [listInOneColumn, setListInOneColumn] = useState(false);

  useEffect(() => {
    if ((!sideMenuOpen && matches1168) || (sideMenuOpen && matches1368))
      setListInOneColumn(true);
    else setListInOneColumn(false);
  }, [sideMenuOpen, matches1368, matches1168]);

  const EditButton = (
    //编辑按钮
    <Box
      sx={{
        height: '32px',
        transition: 'all 0.5s',
        opacity: isFold ? '1' : '0',
        visibility: isFold ? 'visible' : 'hidden',
      }}
      data-selenium-id='SIDetail_GeneralInfoSection-EditBtnBox'
    >
      {!editType
        ? infoObj[0] !== undefined &&
        infoObj[0]['stage'] !== 'Shelved' && (
          <ShowForPermission
            permission={`${PERMISSIONS.SI_WRITE}`}
            data-selenium-id='SIDetail_GeneralInfoSection-ShowForPermission'
          >
            <MyTooltip title='Edit' placement='top' arrow>
              <Box
                id='siDetailPageGISEditButton'
                data-selenium-id='SIDetail_GeneralInfoSection-EditBtn'
                onClick={handleEditClick}
              >
                <Box
                  data-selenium-id='SIDetail_GeneralInfoSection-EditBtnIcon'
                  className={styleModule.editIcon}
                />
              </Box>
            </MyTooltip>
          </ShowForPermission>
        )
        : null}
    </Box>
  );

  return (
    <Box mt={0.5} data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox'>
      <Box>
        {/* 标题部分 */}
        <Stack
          sx={{ padding: '30px 0px 0px 0px', cursor: 'pointer' }}
          direction='row'
          alignItems='center'
          spacing={1}
          data-selenium-id='SIDetail_GeneralInfoSection-ContainerStack1'
        >
          <Box
            class='titleFoldButton'
            onClick={() => {
              async function fetchData() {
                await dispatch(get_SIHistory(siId, 1, 20));
                await dispatch(get_LeadGEHistory(siId, 1, 20));
                await dispatch(get_COGEHistory(siId, 1, 20));
              }
              if (title === 'Editing History') {
                fetchData();
              }
              setIsFold(!isFold);
            }}
            data-selenium-id='SIDetail_GeneralInfoSection-TitleFoldBtn'
          >
            {isFold ? <MarketChevronUp /> : <SIDetailArrowDown />}
          </Box>
          <Box
            id='titleBoxLeft'
            data-selenium-id='SIDetail_GeneralInfoSection-TitleBoxLeft'
            sx={{ cursor: 'default' }}
          >
            {title}
          </Box>
          <CardActions
            sx={{ padding: 0 }}
            data-selenium-id='SIDetail_GeneralInfoSection-CardActions'
          >
            {!loadingData && EditButton}
          </CardActions>
        </Stack>

        {/* 主体部分 */}
        <Box data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox'>
          <Collapse
            in={isFold}
            timeout='auto'
            unmountOnExit
            data-selenium-id='SIDetail_GeneralInfoSection-Collapse'
          >
            {loadingData ? (
              <Box
                sx={{ display: 'flex', justifyContent: 'center' }}
                data-selenium-id='SIDetail_GeneralInfoSection-CircularProgressBox'
              >
                <CircularProgress data-selenium-id='SIDetail_GeneralInfoSection-CircularProgress' />
              </Box>
            ) : editType ? (
              <GeneralInfoEdit
                infoObj={infoObj}
                setEditType={setEditType}
                siId={siId}
                stageId={stageId}
                firstTimeClick={firstTimeClick}
                setFirstTimeClick={setFirstTimeClick}
                submitIsClicked={submitIsClicked}
                setSubmitIsClicked={setSubmitIsClicked}
                error={error}
                setError={setError}
                remarkErr={remarkErr}
                setRemarkErr={setRemarkErr}
                ref={childRef}
                SIDetailIsSaving={SIDetailIsSaving}
                setSIDetailIsSaving={setSIDetailIsSaving}
                detailInfo={detailInfo}
                listInOneColumn={listInOneColumn}
                data-selenium-id='SIDetail_GeneralInfoSection-GeneralInfoEdit'
                handleSubmit={() => childRef.current.handleSubmit()}
                handleCancel={() => childRef.current.handleCancel()}
              />
            ) : (
              <GeneralInfoShow
                infoObj={infoObj}
                setEditType={setEditType}
                journalDetails={journalDetails}
                setAddMarketSection={setAddMarketSection}
                addMarketSectionClicked={props.addMarketSectionClicked}
                needNotes={needNotes}
                SIDetailIsSaving={SIDetailIsSaving}
                setSIDetailIsSaving={setSIDetailIsSaving}
                listInOneColumn={listInOneColumn}
                stageId={stageId}
                detailInfo={detailInfo}
                setFirstOpenMarketSection={setFirstOpenMarketSection}
                data-selenium-id='SIDetail_GeneralInfoSection-GeneralInfoShow'
              />
            )}
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
}


/**
 * @author SZn
 * @date 2022/5/24
 * @param {string} value 需要解析的字符串
 * @returns 返回解析好的ge格式的对象
 */
const handleGEStringToObject = value => {
  let newGE = [];
  let newValue = value;
  if (newValue !== undefined) {
    if (typeof newValue === 'string') {
      newValue = newValue.split(';');
    }
    newValue.forEach(element => {
      if (!isEmptyValue(element)) {
        let geValue = handleGEName(element);
        newGE.push(geValue);
      }
    });
  }
  return newGE;
};

//用于展示信息
export function GeneralInfoShow(props) {
  const {
    needNotes,
    infoObj,
    setAddMarketSection,
    stageId,
    detailInfo,
    setFirstOpenMarketSection,
  } = props;
  const [emailAnchorEl, setEmailAnchorEl] = useState(null);
  const emailPopoverOpen = Boolean(emailAnchorEl);
  const handleClickSendEmailIcon = event => {
    setEmailAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setEmailAnchorEl(null);
  };
  const [viewMoreOpen, setViewMoreOpen] = useState(false);
  const [viewMoreContent, setViewMoreContent] = useState(['', '', '']);
  const dispatch = useDispatch();

  useEffect(() => {
    rightSIDetailEditItem[0]['isEdit'] =
      stageId !== 5 && rightSIDetailEditItem[0]['isEdit'];
    rightSIDetailEditItem[1]['isEdit'] =
      stageId !== 5 && rightSIDetailEditItem[1]['isEdit'];
  }, []);

  const handleCEStringToObject = value => {
    let newGE = [];
    let newValue = value;
    if (newValue !== undefined) {
      if (typeof newValue === 'string') {
        newValue = newValue.split(';');
      }
      newValue.forEach(element => {
        if (!isEmptyValue(element)) {
          let firstName = element.split(', ')[1].split('(')[0];
          let lastName = element.split(', ')[0];
          let fullName = lastName + ', ' + firstName;
          let primaryEmail = element
            .split('(')[1]
            .replace('(', '')
            .replace(')', '');
          newGE.push({
            fullName: fullName,
            email: primaryEmail,
          });
        }
      });
    }
    return newGE;
  };
  // 处理特殊字符
  const handleSpecialValueFormat = (key, value) => {
    if (key === 'Journal Code' && handlingValueFormat(value) === 'Temporary') {
      value = '';
    }
    if (key === 'Lead GE' || key === 'Co-GE') {
      let geList = handleGEStringToObject(value);
      value = '';
      geList.map(ge => {
        if (value.length === 0) {
          value += ge.fullName;
        } else {
          value += '; ' + ge.fullName;
        }
      });
    }
    if (key === 'Previous Handling CE') {
      let ceList = handleCEStringToObject(value);
      value = '';
      value = value + ceList[0].fullName;
    }
    if (key === 'SI Source') {
      value = infoObj[0].siSourceOthersExtraValue
        ? value + '-' + infoObj[0].siSourceOthersExtraValue
        : value === 'Others'
          ? 'Others-N/A'
          : value;
    }
    return value;
  };

  const handleSepcialValueTop = (key, value) => { // to show the email of role.
    switch (key) {
      case 'Handling CE':
        return infoObj[0]['handlingCeEmail'];
      case 'Publishing Development Lead':
        return infoObj[0]['pdpEmail'];
      case 'Publishing Development Manager':
        return infoObj[0]['pdmEmail'];
      case 'Publishing Development Director':
        return infoObj[0]['pddEmail'];
      case 'Partner Publishing Lead':
        return infoObj[0]['pppEmail'];
      case 'Partner Publishing Manager':
        return infoObj[0]['ppmEmail'];
      case 'Partner Publishing Director':
        return infoObj[0]['ppdEmail'];
      case 'Peer Review Performance Lead':
        return infoObj[0]['prppEmail'];
      case 'Peer Review Performance Manager':
        return infoObj[0]['prpmEmail'];
      case 'Peer Review Performance Director':
        return infoObj[0]['prpdEmail'];
      case 'Comprehensive Editorial Evaluation Team Lead':
        return infoObj[0]['ceetpEmail'];
      case 'Publishing VP':
        return infoObj[0]['pvpEmail'];
      case 'Production Editor (PE)':
        return infoObj[0]['peEmail'];
      case 'Marketing Manager (MM)':
        return infoObj[0]['marketingManagerEmail'];
      case 'Production Manager (PM)':
        return infoObj[0]['pmEmail'];
      case 'Managing Editor':
        return infoObj[0]['managingEditorEmail'];
      case 'Editor-in-Chief':
        return infoObj[0]['editorInChiefEmail'];
      case 'Associate Managing Editor (AME)':
        return infoObj[0]['ameEmail'];
      case 'Previous Handling CE':
        return (
          handleGEStringToObject(infoObj[0]['previousHandlingCe'])[0]?.email || ''
        );
      case 'Lead GE':
        return (
          handleGEStringToObject(infoObj[0]['leadGuestEditors'])[0]?.email || ''
        );
      case 'Co-GE':
        return (
          handleGEStringToObject(infoObj[0]['coGuestEditors'])[0]?.email || ''
        );
      default:
        return handleSpecialValueFormat(
          key,
          handlingValueFormat(infoObj[0][value])
        );
    }
  };

  const SIDetailsShowFilter = (value) =>
    !(
      ((value.key === 'Reactive Reason' || value.key === 'Shelved Reason') &&
        isEmptyValue(infoObj[0][value.properity])) ||
      (value.key === 'Previous Handling CE' &&
        isEmptyValue(infoObj[0]['previousHandlingCe']))
    );

  const SIDetailItem = (items, index) => {
    const [isShowMore, setIsShowMore] = useState(false);
    return (
      /* 如果不是这三个字段或者是这三个字段而且有内容 那么就会正常显示 否则就不显示 这里要用infoObj[0][items.properity] 如果用items.value 加载该js文件中定义的列表 逻辑有错误*/

      (items.key !== 'Reactive Reason' &&
        items.key !== 'Shelved Reason' &&
        items.key !== 'Previous Handling CE') ||
        infoObj[0][items.properity] !== null ? (
        items.key !== 'SI Revenue Model' ||
          (items.key === 'SI Revenue Model' &&
            infoObj[0]['revenueModel'] !== 'OA') ? (
          <ListItem
            id={`siDetailPageGISLeft${index}`}
            data-selenium-id={`SIDetail_GIS-Left-${index}`}
            alignItems='baseline'
            key={index}
            sx={{
              fontSize: '16px',
              lineHeight: '20px', //modified
              alignItems: 'baseline',
              padding: 0,
            }}
          >
            {/* item key */}
            <Box
              width='224px'
              textAlign='left'
              marginRight={1}
              sx={{ color: '#596A7C', fontSize: '14px', fontWeight: '400' }}
              data-selenium-id='SIDetail_GeneralInfoSection-ItemKeyBox'
            >
              {items.key === 'Lead GE' ? (
                <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                  Lead GE:
                  {handleGEStringToObject(infoObj[0]['leadGuestEditors'])
                    .map(item => item.alertGeFlagType)
                    .reduce(
                      (prev, cur) =>
                        prev === 'Flags-Severe Issue'
                          ? 'Flags-Severe Issue'
                          : prev === 'Flags-Mild Issue'
                            ? cur === 'Flags-Severe Issue'
                              ? 'Flags-Severe Issue'
                              : 'Flags-Mild Issue'
                            : cur,
                      null
                    ) === 'Flags-Severe Issue' ? (
                    <StyledTooltip
                      title='Check GE list for ethical issue'
                      arrow
                      placement='top'
                    >
                      <ErrorFlag />
                    </StyledTooltip>
                  ) : handleGEStringToObject(infoObj[0]['leadGuestEditors'])
                    .map(item => item.alertGeFlagType)
                    .reduce(
                      (prev, cur) =>
                        prev === 'Flags-Severe Issue'
                          ? 'Flags-Severe Issue'
                          : prev === 'Flags-Mild Issue'
                            ? cur === 'Flags-Severe Issue'
                              ? 'Flags-Severe Issue'
                              : 'Flags-Mild Issue'
                            : cur,
                      null
                    ) === 'Flags-Mild Issue' ? (
                    <StyledTooltip
                      title='Expend to view the GE with mild issue'
                      arrow
                      placement='top'
                    >
                      <WarningFlag />
                    </StyledTooltip>
                  ) : null}
                </Box>
              ) : items.key === 'Co-GE' ? (
                <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                  Co-GE:
                  {handleGEStringToObject(infoObj[0]['coGuestEditors'])
                    .map(item => item.alertGeFlagType)
                    .reduce(
                      (prev, cur) =>
                        prev === 'Flags-Severe Issue'
                          ? 'Flags-Severe Issue'
                          : prev === 'Flags-Mild Issue'
                            ? cur === 'Flags-Severe Issue'
                              ? 'Flags-Severe Issue'
                              : 'Flags-Mild Issue'
                            : cur,
                      null
                    ) === 'Flags-Severe Issue' ? (
                    <StyledTooltip
                      title='Check GE list for ethical issue'
                      arrow
                      placement='top'
                    >
                      <ErrorFlag />
                    </StyledTooltip>
                  ) : handleGEStringToObject(infoObj[0]['coGuestEditors'])
                    .map(item => item.alertGeFlagType)
                    .reduce(
                      (prev, cur) =>
                        prev === 'Flags-Severe Issue'
                          ? 'Flags-Severe Issue'
                          : prev === 'Flags-Mild Issue'
                            ? cur === 'Flags-Severe Issue'
                              ? 'Flags-Severe Issue'
                              : 'Flags-Mild Issue'
                            : cur,
                      null
                    ) === 'Flags-Mild Issue' ? (
                    <StyledTooltip
                      title='Expend to view the GE with mild issue'
                      arrow
                      placement='top'
                    >
                      <WarningFlag />
                    </StyledTooltip>
                  ) : null}
                </Box>
              ) : (
                items.key + ':'
              )}
              {/* {items.key}: */}
            </Box>
            {/* item value */}
            {items.key === 'Deadline Passed' ? (
              //注销calDeadlinePassed的功能
              infoObj[0]['deadlinePassed'] ? (
                <Stack
                  direction='row'
                  spacing={1}
                  alignItems='center'
                  id='siDetailPageGISDeadlinePassed'
                  data-selenium-id='SIDetail_GeneralInfoSection-DeadlinePasssed'
                >
                  <span
                    style={{ color: '#262E35', fontWeight: '400' }}
                    data-selenium-id='SIDetail_GeneralInfoSection-LogoffCalDeadLinePassed'
                    className={
                      styleModule[
                      'SIDetail_GeneralInfoSection-LogoffCalDeadLinePassed'
                      ]
                    }
                  >
                    {handlingValueFormat(
                      //注销calDeadlinePassed的功能
                      infoObj[0]['deadlinePassed']
                    )}
                  </span>
                  <OctagonWarningIcon data-selenium-id='SIDetail_GeneralInfoSection-OctagonWarningIcon' />
                </Stack>
              ) : infoObj[0]['submissionDeadline'] === null ? (
                //当submission Date没有值时留空
                <Box
                  maxWidth='200px'
                  data-selenium-id='SIDetail_GeneralInfoSection-NoSubmissionDate'
                >
                  {''}
                </Box>
              ) : (
                <Box
                  maxWidth='200px'
                  style={{ fontSize: '14px' }}
                  data-selenium-id='SIDetail_GeneralInfoSection-SubmissionDate'
                >
                  {handlingValueFormat(infoObj[0][items.properity])}
                </Box>
              )
            ) : items.key === 'Submission Deadline' ? (
              (infoObj[0]['submissionDeadlineExtendedNumber'] ?? 0) > 0 ? (
                <Stack
                  direction='row'
                  spacing={'10px'}
                  alignItems='center'
                  id='siDetailPageGISSubmissionDeadLine'
                  data-selenium-id='SIDetail_GeneralInfoSection-SubmissionDeadLine'
                >
                  <span
                    style={{
                      color: '#262E35',
                      fontWeight: '400',
                      fontSize: '14px',
                    }}
                    data-selenium-id='SIDetail_GeneralInfoSection-LogoffCalSubmissionDeadLine'
                  >
                    {handlingValueFormat(infoObj[0]['submissionDeadline'])}
                  </span>
                  <SimpleTooltip
                    sx={{
                      '& .MuiTooltip-tooltip': {
                        maxWidth: '400px',
                      },
                    }}
                    placement='top'
                    title={`The deadline has been extended ${infoObj[0]['submissionDeadlineExtendedNumber']} time(s).`}
                  >
                    <DeadlineChangedIcon data-selenium-id='SIDetail_GeneralInfoSection-DeadlineChangedIcon' />
                  </SimpleTooltip>
                </Stack>
              ) : (
                <Box
                  maxWidth='200px'
                  style={{ fontWeight: '400', fontSize: '14px' }}
                  data-selenium-id='SIDetail_GeneralInfoSection-SubmissionDate'
                >
                  {handlingValueFormat(infoObj[0][items.properity])}
                </Box>
              )
            ) : // 判断剩余字段是否需要提示 ？isTooltip
              items.isTooltip ? (
                // 如果需要提示 则按以下渲染
                <Stack
                  direction='row'
                  alignItems='flex-start'
                  width='286px'
                  sx={{
                    color: '#262E35',
                    fontSize: '14px',
                  }}
                  data-selenium-id='SIDetail_GeneralInfoSection-TooltipStack'
                >
                  <MyTooltip
                    title={handleSepcialValueTop(items.key, items.properity)}
                    data-selenium-id='SIDetail_GeneralInfoSection-Tooltip'
                    placement='top'
                    arrow //modified
                  >
                    <Box
                      sx={{
                        color:
                          infoObj[0]['stage'] === 'Shelved'
                            ? // ? '#828282'
                            '#262E35'
                            : '#262E35',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox'
                    >
                      {handleSpecialValueFormat(
                        items.key,
                        handlingValueFormat(infoObj[0][items.properity])
                      )}
                    </Box>
                  </MyTooltip>

                  {infoObj[0].stage !== 'Shelved' &&
                    (permissionIsAdmin() || permissionSiWrite()) ? (
                    items.key === 'Paper Commissioning Method' &&
                      (infoObj[0][items.properity] === 'Open Call' ||
                        infoObj[0][items.properity] === 'Hybrid') ? (
                      <a
                        style={{
                          fontFamily: 'Open Sans',
                          fontStyle: 'normal',
                          fontWeight: '400', //modified
                          fontSize: '12px',
                          lineHeight: '18px',
                          color: '#154AB6',
                          display: props.addMarketSectionClicked
                            ? 'none'
                            : 'flex', // tag todo
                          alignItems: 'center',
                          textAlign: 'right',
                          textDecoration: 'none',
                          cursor: 'pointer',
                          marginLeft: 'auto',
                          '&:hover': {
                            fontWeight: '600',
                            color: '#113D95',
                          },
                        }}
                        href='#MarketSection'
                        onClick={() => {
                          setFirstOpenMarketSection(true);
                          dispatch(
                            getNewSiMarketinfo(
                              infoObj[0].projectCode,
                              detailInfo,
                              setAddMarketSection
                            )
                          );
                        }}
                        data-selenium-id='SIDetail_GeneralInfoSection-AddMarketingSectionLink'
                        className={styleModule.AddMarketingSectionButton}
                      >
                        <span style={{ fontSize: '18px' }}>+&nbsp;</span> ADD
                        MARKETING SECTION
                      </a>
                    ) : null
                  ) : null}
                </Stack>
              ) : (
                // 如果不需要提示 则按以下渲染
                <Box
                  width='286px'
                  sx={
                    items.key === 'Reactive Reason' ||
                      items.key === 'Shelved Reason' ||
                      items.key === 'Remarks'
                      ? {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        maxWidth: '851px', //modified
                        flexGrow: 1,
                        gap: '4px',
                        fontSize: '14px',
                        fontWeight: '400',
                        '& > .MuiBox-root:first-child': {
                          // height: '42px', //modified
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          ...(!isShowMore && { display: '-webkit-box' }),
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          lineHeight: '20px',
                          marginTop: '-26px', //modified
                          transition: 'all 0.5s ease-in-out',
                        },
                      }
                      : {
                        color:
                          infoObj[0]['stage'] === 'Shelved'
                            ? // ? '#828282'
                            '#262E35'
                            : '#262E35',
                        'overflow-x': 'hidden',
                        'overflow-y': 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        'flex-flow':
                          items.key === 'Reactive Reason' ||
                            items.key === 'Shelved Reason' ||
                            items.key === 'Remarks'
                            ? 'column'
                            : 'row',
                        fontSize: '14px',
                        fontWeight: '400',
                      }
                  }
                  data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox2'
                >
                  <Box
                    sx={{
                      maxWidth:
                        items.key === 'Lead GE' ||
                          items.key === 'Previous Handling CE' ||
                          items.key === 'Co-GE'
                          ? '300px'
                          : '100%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                    data-selenium-id='SIDetail_GeneralInfoSection-ContainerBOx'
                  >
                    {handleSpecialValueFormat(
                      items.key,
                      items.key === 'Lead GE' || items.key === 'Co-GE'
                        ? infoObj[0][items.properity]
                        : handlingValueFormat(infoObj[0][items.properity])
                    )}
                  </Box>
                  {items.key === 'Lead GE' ? (
                    <GeMenuIcon
                      id={items.key}
                      data-selenium-id={items.key}
                      geList={handleGEStringToObject(
                        infoObj[0]['leadGuestEditors']
                      )}
                      blueIsLeft={true}
                    />
                  ) : null}
                  {items.key === 'Previous Handling CE' ? (
                    <GeMenuIcon
                      id={items.key}
                      data-selenium-id={items.key}
                      geList={handleCEStringToObject(
                        infoObj[0]['previousHandlingCe']
                      )}
                      blueIsLeft={true}
                    // NoHref
                    />
                  ) : null}
                  {items.key === 'Co-GE' ? (
                    <GeMenuIcon
                      id={items.key}
                      data-selenium-id={items.key}
                      geList={handleGEStringToObject(
                        infoObj[0]['coGuestEditors']
                      )}
                      blueIsLeft={false}
                    />
                  ) : null}
                  {/*只在Reactive和shelved两个reason底下显示view more，且他们的长度需要超过20字符*/}
                  {(items.key === 'Reactive Reason' ||
                    items.key === 'Shelved Reason' ||
                    items.key === 'Remarks') &&
                    infoObj[0][items.properity]?.length > RSRMaxLength && (
                      <Box
                        fontSize={12}
                        data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox'
                      >
                        <Link
                          component='button'
                          onClick={() => setIsShowMore(!isShowMore)}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#154AB6',
                            textDecoration: 'none',
                            gap: '4px',
                          }}
                          className={styleModule.ViewAllBox}
                          data-selenium-id='SIDetail_GeneralInfoSection-Link1'
                        >
                          <Box>{isShowMore ? 'Hide' : 'View All'}</Box>
                          <ViewMoreDownArrow
                            {...(isShowMore && {
                              className: styleModule.ViewMoreDownArrow,
                            })}
                          />
                        </Link>
                      </Box>
                    )}
                </Box>
              )}
          </ListItem>
        ) : null
      ) : null
    );
  };

  return (
    <Box data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox'>
      <CardContent
        sx={{
          maxWidth: '100%',
          width: '1092px',
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: '0px !important',
        }}
        data-selenium-id='SIDetail_GeneralInfoSection-CardContent'
      >
        <Stack
          spacing={3}
          data-selenium-id='SIDetail_GeneralInfoSection-ContainerStack2'
        >
          {/* SI Details 部分  */}
          <Box data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox'>
            <CustomPartTitle data-selenium-id='SIDetail_GeneralInfoSection-CustomPartTitle'>
              SI Details
            </CustomPartTitle>
            <Box data-selenium-id='SIDetail_GeneralInfoSection-GridContainer'>
              <Stack
                direction={'row'}
                spacing={7}
                data-selenium-id='SIDetail_GeneralInfoSection-GridItem1'
                className={styleModule.ParentEditDiv}
              >
                <Stack
                  spacing={1}
                  data-selenium-id='SIDetail_GeneralInfoSection-Stack1'
                  className={styleModule.LeftEditDiv}
                >
                  {leftSIDetails.filter(SIDetailsShowFilter).map(SIDetailItem)}
                </Stack>
                <Stack
                  spacing={1}
                  data-selenium-id='SIDetail_GeneralInfoSection-Stack2'
                  className={styleModule.RightEditDiv}
                >
                  {rightSIDetails.filter(SIDetailsShowFilter).map(SIDetailItem)}
                </Stack>
              </Stack>
            </Box>
          </Box>
          {/* Stakeholder部分  */}
          <Box data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox'>
            <CustomPartTitle data-selenium-id='SIDetail_GeneralInfoSection-CustomPartTitle'>
              Stakeholders
              <SelectEmailTemplate
                stageId={stageId}
                siDetail={infoObj[0]}
                handleClickSendEmailIcon={handleClickSendEmailIcon}
                emailPopoverOpen={emailPopoverOpen}
                emailAnchorEl={emailAnchorEl}
                handleClose={handleClose}
                StyledTypography={StyledTypography}
              />
            </CustomPartTitle>
            <Box data-selenium-id='SIDetail_GeneralInfoSection-GridContainer'>
              <Stack
                spacing={7}
                direction={'row'}
                data-selenium-id='SIDetail_GeneralInfoSection-GridItem1'
                className={styleModule.ParentEditDiv}
              >
                <Stack
                  spacing={1}
                  data-selenium-id='SIDetail_GeneralInfoSection-Stack'
                  className={styleModule.LeftEditDiv}
                >
                  {leftStakeholder
                    .filter(SIDetailsShowFilter)
                    .map(SIDetailItem)}
                </Stack>
                <Stack
                  spacing={1}
                  data-selenium-id='SIDetail_GeneralInfoSection-Stack'
                  className={styleModule.RightEditDiv}
                >
                  {rightStakeholder
                    .filter(SIDetailsShowFilter)
                    .map(SIDetailItem)}
                </Stack>
              </Stack>
            </Box>
          </Box>
          {/* Dates Details部分  */}
          <Box data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox'>
            <CustomPartTitle data-selenium-id='SIDetail_GeneralInfoSection-CustomPartTitle'>
              Dates
            </CustomPartTitle>
            <Box data-selenium-id='SIDetail_GeneralInfoSection-GridContainer'>
              <Stack
                direction={'row'}
                spacing={7}
                data-selenium-id='SIDetail_GeneralInfoSection-GridItem1'
                className={styleModule.ParentEditDiv}
              >
                <Stack
                  spacing={1}
                  data-selenium-id='SIDetail_GeneralInfoSection-Stack'
                  className={styleModule.LeftEditDiv}
                >
                  {leftDates.filter(SIDetailsShowFilter).map(SIDetailItem)}
                </Stack>
                <Stack
                  spacing={1}
                  data-selenium-id='SIDetail_GeneralInfoSection-Stack'
                  className={styleModule.RightEditDiv}
                >
                  {rightDates.filter(SIDetailsShowFilter).map(SIDetailItem)}
                </Stack>
              </Stack>
            </Box>
          </Box>
          {/* show-Notes 部分  */}
          <Box
            data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox'
            style={{ display: needNotes ? 'block' : 'none' }}
          >
            <CustomPartTitle data-selenium-id='SIDetail_GeneralInfoSection-CustomPartTitle'>
              Notes
            </CustomPartTitle>
            <Grid
              container
              data-selenium-id='SIDetail_GeneralInfoSection-GridContainer'
            >
              <Grid
                item
                xs={12}
                data-selenium-id='SIDetail_GeneralInfoSection-GridItem'
              >
                <Stack
                  spacing={1}
                  data-selenium-id='SIDetail_GeneralInfoSection-Stack'
                >
                  {notesItem.map(SIDetailItem)}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </CardContent>
      <ViewMoreDialog
        content={viewMoreContent[1]}
        title={viewMoreContent[0]}
        date={viewMoreContent[2]}
        open={viewMoreOpen}
        setOpen={setViewMoreOpen}
        data-selenium-id='SIDetail_GeneralInfoSection-ViewMoreDialog'
      />
    </Box>
  );
}

const judgeValueEffect = (value, list) => {
  if (value === null) {
    return value;
  } else if (value === 'Y') {
    return 'Yes';
  } else if (value === 'N') {
    return 'No';
  } else if (list.indexOf(value) > -1) {
    return value;
  } else {
    return null;
  }
};

//检查date数据是否正确
const checkDateValue = value => {
  return value != null && value !== '' && value !== 'Invalid Date';
};

const handleStringtoList = value => {
  return value.split(',');
};

//用于判断COGE和LeadGE是否有重复的项
const isGERepeatErr = (array1, array2) => {
  for (let i = 0; i < array1.length; i++) {
    for (let j = 0; j < array2.length; j++) {
      if (array1[i].primaryEmail === array2[j].primaryEmail) {
        return true;
      }
    }
  }
  return false;
};

const MandatoryJudge = (value, list) => {
  return list.indexOf(value) > -1;
};

const handleGEObjectToString = value => {
  let newValue = [];
  if (value.length > 0) {
    value.forEach(element => {
      newValue.push(element.primaryEmail);
    });
  }
  return newValue;
};

//格式化时间
function codeDateValue(input) {
  if (input === null || input === undefined) {
    return '';
  }

  const date = new Date(input);

  if (isNaN(date.valueOf())) {
    return '';
  }
  return moment(date).utc().format('YYYY-MM-DD');
}

const handleAsteriskError = (attribute, stageId) => {
  switch (attribute) {
    case 'Handling CE':
    case 'SI Publication Format':
    case 'Special Issue Title':
    case 'Journal Code':
    case 'Journal Name':
    case 'SI Source':
      return (
        <>
          <span>{attribute}</span>
          <span style={{ color: '#C40000' }}> * </span>
        </>
      );
    case 'SI Source Other':
      return '';
    case 'Lead GE':
    case 'Acquired Date':
    case 'Submission Deadline':
    case 'Expected Acceptance Date':
    case 'Expected Publication Date':
    case 'Paper Commissioning Method':
    case 'Workflow':
      return stageId > 0 ? (
        <>
          <span>{attribute}</span>
          <span style={{ color: '#C40000' }}> * </span>
        </>
      ) : (
        attribute
      );
    case 'SI Revenue Model':
      return stageId === 5 ? (
        <>
          <span>{attribute}</span>
          <span style={{ color: '#C40000' }}> * </span>
        </>
      ) : (
        attribute
      );
    case 'Idea Date':
      return stageId < 1 ? (
        <>
          <span>{attribute}</span>
          <span style={{ color: '#C40000' }}> * </span>
        </>
      ) : (
        attribute
      );
    case 'Open for Submission Date':
      return stageId === 2 || stageId === 3 ? (
        <>
          <span>{attribute}</span>
          <span style={{ color: '#C40000' }}> * </span>
        </>
      ) : (
        attribute
      );
    case 'Closed for Submission':
      return stageId > 2 ? (
        <>
          <span>{attribute}</span>
          <span style={{ color: '#C40000' }}> * </span>
        </>
      ) : (
        attribute
      );
    case 'Remarks':
    case 'Shelved Reason':
    case 'Reactive Reason':
      return `${attribute}:`;
    default:
      return attribute;
  }
};

export const GeneralInfoEdit = forwardRef((props, ref) => {
  const [errorOpen, setErrorOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const [emailAnchorEl, setEmailAnchorEl] = useState(null);
  const emailPopoverOpen = Boolean(emailAnchorEl);
  const handleClickSendEmailIcon = event => {
    setEmailAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setEmailAnchorEl(null);
  };
  const dispatch = useDispatch();
  //redux取出数据
  const selector = state => {
    return {
      CEResult: state.SI.CE,
      GEResult: state.SI.GE,
      SITypeList: state.SystemConfig.SITypeList,
      PapaerComfigList: state.SystemConfig.PapaerComfigList,
      configInfo: state.SystemConfig.systemConfig,
      CreateGEResult: state.SI.createGEResult, //whether
      submissionJudgeValue: state.SI.submissionJudgeValue,
      workFlowList: state.SI.workFlowList,
      siRevenueModelList: state.SI.siRevenueModelList,
      siSourceList: state.SI.siSourceList,
    };
  };

  const {
    CEResult,
    GEResult,
    SITypeList,
    PapaerComfigList,
    configInfo,
    CreateGEResult,
    submissionJudgeValue,
    workFlowList,
    siRevenueModelList,
    siSourceList,
  } = useSelector(selector);
  // 改为从后台拿数据 20221112
  const [viewMoreOpen, setViewMoreOpen] = useState(false);
  const [viewMoreContent, setViewMoreContent] = useState(['', '']);

  useEffect(() => {
    dispatch(getWorkFlowList());
    dispatch(getSIRevenueModel());
    dispatch(getSepcialIssueSource());
  }, []);

  const [SITypeList2, setSITypeList] = useState([]);
  const [PaperCommissionMethodList2, setPaperCommissionMethodList] = useState(
    []
  );
  const [WorkflowList2, setWorkFlowList] = useState([]);

  useEffect(() => {
    setWorkFlowList(
      workFlowList !== []
        ? workFlowList.map(item => {
          return Object.assign({}, { Id: item.id, Name: item.value });
        })
        : []
    );
  }, [workFlowList]);

  const [SIRevenueModelList2, setSIRevenueModelList2] = useState([]);

  useEffect(() => {
    setSIRevenueModelList2(
      siRevenueModelList !== []
        ? siRevenueModelList.map(item => {
          return Object.assign({}, { Id: item.id, Name: item.value });
        })
        : []
    );
  }, [siRevenueModelList]);

  const [SISourceList2, setSISourceList] = useState([]);

  useEffect(() => {
    setSISourceList(
      siSourceList !== []
        ? siSourceList.map(item => {
          return Object.assign({}, { Id: item.id, Name: item.value });
        })
        : []
    );
  }, [siSourceList]);

  const selectTypeMap = {
    'SI Publication Format': SITypeList2,
    'SI Source': SISourceList2,
    'Paper Commissioning Method': PaperCommissionMethodList2,
    Workflow: WorkflowList2,
    'SI Revenue Model': SIRevenueModelList2,
  };

  /**
   * 展示弹出窗口
   * @param title
   * @param property
   */
  const handleViewMore = (title, property) => {
    setViewMoreContent([title, infoObj[0][property]]);
    setViewMoreOpen(true);
  };

  const {
    infoObj,
    setEditType,
    siId,
    stageId,
    firstTimeClick,
    submitIsClicked,
    remarkErr,
    setRemarkErr,
    error,
    setError,
    setFirstTimeClick,
    setSubmitIsClicked,
    SIDetailIsSaving,
    setSIDetailIsSaving,
    detailInfo,
  } = props;

  const [SIRevenueModel, setSIRevenueModel] = useState(
    infoObj[0]['siRevenueModel']
  );

  const [handleCeEmail, setHandleCeEmail] = useState(
    infoObj[0]['handlingCeEmail']
  ); //传email
  const [group, setGroup] = useState(infoObj[0]['groupOrTitle']);
  const [specialIssueType, setSpecialIssueType] = useState(
    infoObj[0]['specialIssueType']
  );
  const [specialIssueTitle, setSpecialIssueTitle] = useState(
    infoObj[0]['specialIssueTitle']
  );

  const [journalSelect, setJournalSelect] = useState(
    infoObj[0]['journalCode'] === 'Temporary' ||
      infoObj[0]['journalCode'] == null
      ? {
        journalCode: '',
        journalName: '',
        onlineISSN: '',
        revenueModel: '',
        vchCode: '',
        jpm: '',
        subjectGroup: '',
      }
      : {
        journalCode: infoObj[0]['journalCode'],
        journalName: infoObj[0]['journalName'],
        onlineISSN: infoObj[0]['onlineISSN'],
        revenueModel: infoObj[0]['revenueModel'],
        vchCode: infoObj[0]['vchCode'],
        jpm: infoObj[0]['jpm'],
        subjectGroup: infoObj[0]['subjectGroup'],
      }
  );
  const journalCodeSelect = journalSelect?.journalCode || '';
  const journalNameSelect = journalSelect?.journalName || '';
  const onlineISSN = journalSelect?.onlineISSN || '';
  const vchCode = journalSelect?.vchCode || '';
  const JPM = journalSelect?.jpm || '';
  const SubjectGroup = journalSelect?.subjectGroup || '';

  const [LeadGE, setLeadGE] = useState(
    handleGEStringToObject(infoObj[0]['leadGuestEditors'])
  );
  const [COGE, setCOGE] = useState(
    handleGEStringToObject(infoObj[0]['coGuestEditors'])
  );
  const [ideaDate, setIdeaDate] = useState(
    // getUTCToDate(moment(infoObj[0]['ideaDate']))
    moment(infoObj[0]['ideaDate'])
  );
  const [SISource, setSISource] = useState(infoObj[0]['siSource']);
  const [SISourceOther, setSISourceOther] = useState(
    infoObj[0]['siSourceOthersExtraValue']
  );
  const [acquiredDate, setAcquiredDate] = useState(
    (infoObj[0]['acquiredDate'] == null ||
      `${new Date(infoObj[0]['acquiredDate'])}` === 'Invalid Date') ? null
      : new Date(infoObj[0]['acquiredDate'])
  );
  const [subDeadline, setSubDeadline] = useState(
    // getUTCToDate(moment(infoObj[0]['submissionDeadline']))
    (moment(infoObj[0]['submissionDeadline']))
  );
  const [expectedAcceptanceDate, setExpectedAcceptanceDate] = useState(
    // getUTCToDate(moment(infoObj[0]['expectedAcceptanceDate']))
    (moment(infoObj[0]['expectedAcceptanceDate']))
  );
  const [expectedPublicationDate, setExpectedPublicationDate] = useState(
    // getUTCToDate(moment(infoObj[0]['expectedPublicationDate']))
    (moment(infoObj[0]['expectedPublicationDate']))
  );
  const [openForSubmissionDate, setOpenForSubmissionDate] = useState(
    // getUTCToDate(moment(infoObj[0]['openForSubmissionDate']))
    (moment(infoObj[0]['openForSubmissionDate']))
  );
  const [deadlinePassed, setDeadlinePassed] = useState(
    infoObj[0]['deadlinePassed']
  );
  const [closeForSub, setCloseForSub] = useState(
    judgeValueEffect(infoObj[0]['closedForSubmission'], ['Yes', 'No'])
  );
  const [paperCommissionMethod, setPaperCommissionMethod] = useState(
    infoObj[0]['paperCommissionMethod']
  );
  const [workFlow, setWorkFlow] = useState(infoObj[0]['workFlow']);
  const [remarks, setRemarks] = useState(infoObj[0]['remarks']);
  const [shelvedReason, setShelvedReason] = useState(
    infoObj[0]['shelvedReason']
  );
  const [reactiveReason, setReactiveReason] = useState(
    infoObj[0]['reactiveReason']
  );

  //出错标记位
  const [GERepeatErr, setGERepeatErr] = useState(false);

  const maxGeNumber =
    configInfo === null ? 20 : configInfo.eachSiGuestEditorMaxNumber;
  const [createGEOpen, setCreateOpen] = useState(false);
  const [createGeIsLead, setCreateGeIsLead] = useState(false);

  const handleCreateOpen = (value, ifLead) => {
    if (ifLead) {
      setCreateGeIsLead(true);
    } else {
      setCreateGeIsLead(false);
    }
    if (value.length < maxGeNumber) {
      setCreateOpen(true);
    }
  };
  const handleCreateClose = () => {
    setCreateOpen(false);
  };

  useEffect(() => {
    let result1 = CreateGEResult;
    if (CreateGEResult !== 'error' && CreateGEResult !== '') {
      if (CreateGEResult !== 'EmailExit') {
        setCreateOpen(false);
        let GES = [result1];
        if (result1.isLead) {
          GES = LeadGE.concat(GES);
          setLeadGE(GES);
        } else {
          GES = COGE.concat(GES);
          setCOGE(GES);
        }
        dispatch(clearCreateGEResult());
        dispatch(getGE('')); //dropdown更新
      } else {
        setCreateOpen(true);
      }
    }
  }, [CreateGEResult]);

  useEffect(() => {
    setSITypeList(SITypeList);
  }, [SITypeList]);

  useEffect(() => {
    if (
      detailInfo !== null &&
      detailInfo !== undefined &&
      detailInfo.siMarketing !== null &&
      detailInfo.siMarketing !== undefined &&
      detailInfo.siMarketing.length > 0 &&
      detailInfo.siMarketing[0].readyForPromotion === 'Yes'
    ) {
      setPaperCommissionMethodList([
        { Id: 1, value: 'Open Call' },
        { Id: 2, value: 'Hybrid' },
      ]);
    } else {
      setPaperCommissionMethodList(PapaerComfigList);
    }
  }, [PapaerComfigList, detailInfo]);

  useEffect(() => {
    let date = transferSubmissionUTCDate(new Date(subDeadline));
    dispatch(judgeSubmissionPass(date));
  }, [subDeadline]);

  useEffect(() => {
    setDeadlinePassed(submissionJudgeValue);
  }, [submissionJudgeValue]);

  const IdeaProposalMandatoryList = handleStringtoList(
    localStorage.getItem('IdeaProposalMandatoryList')
  );
  const AcquiredMandatoryList = handleStringtoList(
    localStorage.getItem('AcquiredMandatoryList')
  );
  const PaperCommissionMandatoryList = handleStringtoList(
    localStorage.getItem('PaperCommissionMandatoryList')
  );
  const SubmissionAndReviewMandatoryList = handleStringtoList(
    localStorage.getItem('SubmissionAndReviewMandatoryList')
  );
  const ProductionMandatoryList = handleStringtoList(
    localStorage.getItem('ProductionMandatoryList')
  );
  const PublishedMandatoryList = handleStringtoList(
    localStorage.getItem('PublishedMandatoryList')
  );


  const isEmptyForIdea = () => {
    return (
      (MandatoryJudge('Handling CE', IdeaProposalMandatoryList) &&
        isEmptyValue(handleCeEmail)) ||
      (MandatoryJudge('Journal Name', IdeaProposalMandatoryList) &&
        isEmptyValue(journalNameSelect)) ||
      (MandatoryJudge('Journal Code', IdeaProposalMandatoryList) &&
        isEmptyValue(journalCodeSelect)) ||
      (MandatoryJudge('SI Publication Format', IdeaProposalMandatoryList) &&
        isEmptyValue(specialIssueType)) ||
      (MandatoryJudge('Special Issue Title', IdeaProposalMandatoryList) &&
        isEmptyValue(specialIssueTitle)) ||
      (MandatoryJudge('SI Source', IdeaProposalMandatoryList) &&
        isEmptyValue(SISource)) ||
      (MandatoryJudge('Idea Date', IdeaProposalMandatoryList) &&
        !checkDateValue(ideaDate))
    );
  };

  const isEmptyForAcquired = () => {
    return (
      (MandatoryJudge('Handling CE', AcquiredMandatoryList) &&
        isEmptyValue(handleCeEmail)) ||
      (MandatoryJudge('Journal Name', AcquiredMandatoryList) &&
        isEmptyValue(journalNameSelect)) ||
      (MandatoryJudge('Journal Code', AcquiredMandatoryList) &&
        isEmptyValue(journalCodeSelect)) ||
      (MandatoryJudge('SI Publication Format', AcquiredMandatoryList) &&
        isEmptyValue(specialIssueType)) ||
      (MandatoryJudge('Special Issue Title', AcquiredMandatoryList) &&
        isEmptyValue(specialIssueTitle)) ||
      (MandatoryJudge('SI Source', AcquiredMandatoryList) &&
        isEmptyValue(SISource)) ||
      (MandatoryJudge('Lead-GE', AcquiredMandatoryList) &&
        isEmptyValue(LeadGE)) ||
      (MandatoryJudge('Co-GE', AcquiredMandatoryList) && isEmptyValue(COGE)) ||
      (MandatoryJudge('Paper Commissioning Method', AcquiredMandatoryList) &&
        isEmptyValue(paperCommissionMethod)) ||
      (MandatoryJudge('Workflow', AcquiredMandatoryList) &&
        isEmptyValue(workFlow)) ||
      (MandatoryJudge('Acquired Date', AcquiredMandatoryList) &&
        !checkDateValue(acquiredDate)) ||
      (MandatoryJudge('Open For Submission Date', AcquiredMandatoryList) &&
        !checkDateValue(openForSubmissionDate)) ||
      (MandatoryJudge('Submission Deadline', AcquiredMandatoryList) &&
        !checkDateValue(subDeadline)) ||
      (MandatoryJudge('Expected Acceptance Date', AcquiredMandatoryList) &&
        !checkDateValue(expectedAcceptanceDate)) ||
      (MandatoryJudge('Expected Publication Date', AcquiredMandatoryList) &&
        !checkDateValue(expectedPublicationDate))
    );
  };

  const isEmptyForPaperCommission = () => {
    return (
      (MandatoryJudge('Handling CE', PaperCommissionMandatoryList) &&
        isEmptyValue(handleCeEmail)) ||
      (MandatoryJudge('Journal Name', PaperCommissionMandatoryList) &&
        isEmptyValue(journalNameSelect)) ||
      (MandatoryJudge('Journal Code', PaperCommissionMandatoryList) &&
        isEmptyValue(journalCodeSelect)) ||
      (MandatoryJudge('SI Publication Format', PaperCommissionMandatoryList) &&
        isEmptyValue(specialIssueType)) ||
      (MandatoryJudge('Special Issue Title', PaperCommissionMandatoryList) &&
        isEmptyValue(specialIssueTitle)) ||
      (MandatoryJudge('SI Source', PaperCommissionMandatoryList) &&
        isEmptyValue(SISource)) ||
      (MandatoryJudge('Lead-GE', PaperCommissionMandatoryList) &&
        isEmptyValue(LeadGE)) ||
      (MandatoryJudge('Co-GE', PaperCommissionMandatoryList) &&
        isEmptyValue(COGE)) ||
      (MandatoryJudge(
        'Paper Commissioning Method',
        PaperCommissionMandatoryList
      ) &&
        isEmptyValue(paperCommissionMethod)) ||
      (MandatoryJudge('Workflow', PaperCommissionMandatoryList) &&
        isEmptyValue(workFlow)) ||
      (MandatoryJudge('Acquired Date', PaperCommissionMandatoryList) &&
        !checkDateValue(acquiredDate)) ||
      (MandatoryJudge(
        'Open For Submission Date',
        PaperCommissionMandatoryList
      ) &&
        !checkDateValue(openForSubmissionDate)) ||
      (MandatoryJudge('Submission Deadline', PaperCommissionMandatoryList) &&
        !checkDateValue(subDeadline)) ||
      (MandatoryJudge(
        'Expected Acceptance Date',
        PaperCommissionMandatoryList
      ) &&
        !checkDateValue(expectedAcceptanceDate)) ||
      (MandatoryJudge(
        'Expected Publication Date',
        PaperCommissionMandatoryList
      ) &&
        !checkDateValue(expectedPublicationDate)) ||
      (MandatoryJudge('Closed for submission?', PaperCommissionMandatoryList) &&
        isEmptyValue(closeForSub))
    );
  };

  const isEmptyForSubmission = () => {
    return (
      (MandatoryJudge('Handling CE', SubmissionAndReviewMandatoryList) &&
        isEmptyValue(handleCeEmail)) ||
      (MandatoryJudge('Journal Name', SubmissionAndReviewMandatoryList) &&
        isEmptyValue(journalNameSelect)) ||
      (MandatoryJudge('Journal Code', SubmissionAndReviewMandatoryList) &&
        isEmptyValue(journalCodeSelect)) ||
      (MandatoryJudge(
        'SI Publication Format',
        SubmissionAndReviewMandatoryList
      ) &&
        isEmptyValue(specialIssueType)) ||
      (MandatoryJudge(
        'Special Issue Title',
        SubmissionAndReviewMandatoryList
      ) &&
        isEmptyValue(specialIssueTitle)) ||
      (MandatoryJudge('SI Source', SubmissionAndReviewMandatoryList) &&
        isEmptyValue(SISource)) ||
      (MandatoryJudge('Lead-GE', SubmissionAndReviewMandatoryList) &&
        isEmptyValue(LeadGE)) ||
      (MandatoryJudge('Co-GE', SubmissionAndReviewMandatoryList) &&
        isEmptyValue(COGE)) ||
      (MandatoryJudge(
        'Paper Commissioning Method',
        SubmissionAndReviewMandatoryList
      ) &&
        isEmptyValue(paperCommissionMethod)) ||
      (MandatoryJudge('Workflow', SubmissionAndReviewMandatoryList) &&
        isEmptyValue(workFlow)) ||
      (MandatoryJudge('Acquired Date', SubmissionAndReviewMandatoryList) &&
        !checkDateValue(acquiredDate)) ||
      (MandatoryJudge(
        'Open For Submission Date',
        SubmissionAndReviewMandatoryList
      ) &&
        !checkDateValue(openForSubmissionDate)) ||
      (MandatoryJudge(
        'Submission Deadline',
        SubmissionAndReviewMandatoryList
      ) &&
        !checkDateValue(subDeadline)) ||
      (MandatoryJudge(
        'Expected Acceptance Date',
        SubmissionAndReviewMandatoryList
      ) &&
        !checkDateValue(expectedAcceptanceDate)) ||
      (MandatoryJudge(
        'Expected Publication Date',
        SubmissionAndReviewMandatoryList
      ) &&
        !checkDateValue(expectedPublicationDate)) ||
      (MandatoryJudge(
        'Closed for submission?',
        SubmissionAndReviewMandatoryList
      ) &&
        isEmptyValue(closeForSub))
    );
  };

  const isEmptyForProduction = () => {
    return (
      (MandatoryJudge('Handling CE', ProductionMandatoryList) &&
        isEmptyValue(handleCeEmail)) ||
      (MandatoryJudge('Journal Name', ProductionMandatoryList) &&
        isEmptyValue(journalNameSelect)) ||
      (MandatoryJudge('Journal Code', ProductionMandatoryList) &&
        isEmptyValue(journalCodeSelect)) ||
      (MandatoryJudge('SI Publication Format', ProductionMandatoryList) &&
        isEmptyValue(specialIssueType)) ||
      (MandatoryJudge('Special Issue Title', ProductionMandatoryList) &&
        isEmptyValue(specialIssueTitle)) ||
      (MandatoryJudge('SI Source', ProductionMandatoryList) &&
        isEmptyValue(SISource)) ||
      (MandatoryJudge('Lead-GE', ProductionMandatoryList) &&
        isEmptyValue(LeadGE)) ||
      (MandatoryJudge('Co-GE', ProductionMandatoryList) &&
        isEmptyValue(COGE)) ||
      (MandatoryJudge('Paper Commissioning Method', ProductionMandatoryList) &&
        isEmptyValue(paperCommissionMethod)) ||
      (MandatoryJudge('Workflow', ProductionMandatoryList) &&
        isEmptyValue(workFlow)) ||
      (MandatoryJudge('Acquired Date', ProductionMandatoryList) &&
        !checkDateValue(acquiredDate)) ||
      (MandatoryJudge('Open For Submission Date', ProductionMandatoryList) &&
        !checkDateValue(openForSubmissionDate)) ||
      (MandatoryJudge('Submission Deadline', ProductionMandatoryList) &&
        !checkDateValue(subDeadline)) ||
      (MandatoryJudge('Expected Acceptance Date', ProductionMandatoryList) &&
        !checkDateValue(expectedAcceptanceDate)) ||
      (MandatoryJudge('Expected Publication Date', ProductionMandatoryList) &&
        !checkDateValue(expectedPublicationDate)) ||
      (MandatoryJudge('Closed for submission?', ProductionMandatoryList) &&
        isEmptyValue(closeForSub))
    );
  };

  const isEmptyForPublication = () => {
    return (MandatoryJudge('Handling CE', PublishedMandatoryList) &&
      isEmptyValue(handleCeEmail)) ||
      (MandatoryJudge('SI Publication Format', PublishedMandatoryList) &&
        isEmptyValue(specialIssueType)) ||
      (MandatoryJudge('SI Revenue Model', PublishedMandatoryList) &&
        isEmptyValue(specialIssueType)) ||
      (MandatoryJudge('Special Issue Title', PublishedMandatoryList) &&
        isEmptyValue(specialIssueTitle)) ||
      (MandatoryJudge('SI Source', PublishedMandatoryList) &&
        isEmptyValue(SISource)) ||
      (MandatoryJudge('Lead-GE', PublishedMandatoryList) &&
        isEmptyValue(LeadGE)) ||
      (MandatoryJudge('Co-GE', PublishedMandatoryList) && isEmptyValue(COGE)) ||
      (MandatoryJudge('Paper Commissioning Method', PublishedMandatoryList) &&
        isEmptyValue(paperCommissionMethod)) ||
      (MandatoryJudge('Workflow', PublishedMandatoryList) &&
        isEmptyValue(workFlow)) ||
      (MandatoryJudge('Acquired Date', PublishedMandatoryList) &&
        !checkDateValue(acquiredDate)) ||
      (MandatoryJudge('Open For Submission Date', PublishedMandatoryList) &&
        !checkDateValue(openForSubmissionDate)) ||
      (MandatoryJudge('Submission Deadline', PublishedMandatoryList) &&
        !checkDateValue(subDeadline)) ||
      (MandatoryJudge('Expected Acceptance Date', PublishedMandatoryList) &&
        !checkDateValue(expectedAcceptanceDate)) ||
      (MandatoryJudge('Expected Publication Date', PublishedMandatoryList) &&
        !checkDateValue(expectedPublicationDate)) ||
      (MandatoryJudge('Closed for submission?', PublishedMandatoryList) &&
        isEmptyValue(closeForSub));
  };

  const getStageEmptyJudge = () => {
    switch (stageId) {
      case 0:
        return isEmptyForIdea();
      case 1:
        return isEmptyForAcquired();
      case 2:
        return isEmptyForPaperCommission();
      case 3:
        return isEmptyForSubmission();
      case 4:
        return isEmptyForProduction();
      case 5:
        return isEmptyForPublication();
    }
  };

  useEffect(() => {
    let ifEmpty = getStageEmptyJudge();
    let checkError =
      handleCeEmail?.length === 0 ||
      specialIssueTitle?.length === 0 ||
      specialIssueType?.length === 0 ||
      SISource === null ||
      SISource?.length === 0;

    checkError =
      checkError ||
      (infoObj[0]['stage'] === 'Idea/Proposal' && !checkDateValue(ideaDate));

    checkError =
      checkError ||
      (infoObj[0]['stage'] === 'Paper Commission' &&
        !checkDateValue(openForSubmissionDate));

    checkError =
      checkError ||
      (infoObj[0]['stage'] === 'Submission and Review' &&
        !checkDateValue(openForSubmissionDate));

    checkError =
      checkError ||
      (infoObj[0]['stage'] !== 'Idea/Proposal' &&
        (LeadGE?.length === 0 ||
          workFlow === null ||
          workFlow?.length === 0 ||
          paperCommissionMethod?.length === 0 ||
          !checkDateValue(acquiredDate) ||
          !checkDateValue(subDeadline) ||
          !checkDateValue(expectedAcceptanceDate) ||
          !checkDateValue(expectedPublicationDate)));

    checkError =
      checkError ||
      (stageId > 2 && closeForSub !== 'Yes' && closeForSub !== 'No');

    checkError =
      checkError || (ideaDate && acquiredDate && ideaDate > acquiredDate);

    checkError =
      checkError ||
      (openForSubmissionDate &&
        subDeadline &&
        openForSubmissionDate >= subDeadline);

    checkError =
      checkError ||
      (subDeadline &&
        (expectedAcceptanceDate && subDeadline >= expectedAcceptanceDate
        ));

    checkError =
      checkError ||
      (expectedAcceptanceDate &&
        expectedPublicationDate &&
        expectedAcceptanceDate >= expectedPublicationDate);

    setError(ifEmpty || checkError);
    setFirstTimeClick(false);
  }, [
    handleCeEmail,
    specialIssueTitle,
    specialIssueType,
    SISource,
    SISourceOther,
    journalCodeSelect,
    journalNameSelect,
    LeadGE,
    COGE,
    ideaDate,
    workFlow,
    paperCommissionMethod,
    closeForSub,
    acquiredDate,
    subDeadline,
    expectedAcceptanceDate,
    expectedPublicationDate,
    openForSubmissionDate,
    SIRevenueModel,
  ]);

  useEffect(() => {
    if (isGERepeatErr(LeadGE, COGE)) {
      setGERepeatErr(true);
    } else {
      setGERepeatErr(false);
    }
  }, [LeadGE, COGE]);

  //同步journal相关信息
  const handleJounalSearch = value => {
    setJournalSelect(value);
  };

  //处理复选框点击事件
  const handleCheckedChange = event => {
    setCloseForSub(current => (current === 'Yes' ? 'No' : 'Yes'));
  };

  //针对不同的编辑框，传入不同的变量存储数据
  const valueFun = key => {
    switch (key) {
      case 'SI Publication Format':
        return specialIssueType;
      case 'SI Source':
        return SISource;
      case 'SI Source Other':
        return SISourceOther;
      case 'Special Issue Title':
        return specialIssueTitle;
      case 'Paper Commissioning Method':
        return paperCommissionMethod;
      case 'Workflow':
        return workFlow;
      case 'Remarks':
        return remarks;
      case 'Shelved Reason':
        return shelvedReason;
      case 'Reactive Reason':
        return reactiveReason;
      case 'SI Revenue Model':
        return SIRevenueModel;
      default:
        return null;
    }
  };

  const handleSubmit = async () => {
    if (GERepeatErr) {
      setErrorOpen(true);
      setErrorMessage(intl.messages['siPage.geRepeatErr']);
      setSeverity('error');
      setAlertTitle('Error');
      setFirstTimeClick(true);
      return;
    }
    setFirstTimeClick(true);
    setSubmitIsClicked(true);

    if (remarkErr || error) {
      return;
    } else if (
      SISource === 'Others' &&
      (SISourceOther === '' || SISourceOther === null)
    ) {
      return;
    } else if (
      SISource === 'Others' &&
      valueFun('SI Source Other')?.length > otherMaxLength
    ) {
      return;
    }

    setSIDetailIsSaving(true);
    if (permissionIsAdmin()) {
      await adminSave();
    } else if (permissionSiWrite()) {
      await SIWriteSave();
    }
    setSIDetailIsSaving(false);
    setFirstTimeClick(false);
  };

  //针对不同的编辑框，传入不同的函数操作存储数据
  const setFun = key => {
    switch (key) {
      case 'SI Publication Format':
        return setSpecialIssueType;
      case 'Idea Date':
        return setIdeaDate;
      case 'SI Source':
        return setSISource;
      case 'SI Source Other':
        return setSISourceOther;
      case 'Acquired Date':
        return setAcquiredDate;
      case 'Submission Deadline':
        return setSubDeadline;
      case 'Expected Acceptance Date':
        return setExpectedAcceptanceDate;
      case 'Expected Publication Date':
        return setExpectedPublicationDate;
      case 'Paper Commissioning Method':
        return setPaperCommissionMethod;
      case 'Workflow':
        return setWorkFlow;
      case 'SI Revenue Model':
        return setSIRevenueModel;
      case 'Special Issue Title':
        return setSpecialIssueTitle;
      case 'Remarks':
        return setRemarks;
      case 'Open for Submission Date':
        return setOpenForSubmissionDate;
      case 'Shelved Reason':
        return setShelvedReason;
      case 'Reactive Reason':
        return setReactiveReason;
      default:
        return () => { };
    }
  };

  const setCustomDatePickerValue = key => {
    switch (key) {
      case 'Idea Date':
        return ideaDate;
      case 'Acquired Date':
        return acquiredDate;
      case 'Expected Acceptance Date':
        return expectedAcceptanceDate;
      case 'Expected Publication Date':
        return expectedPublicationDate;
      case 'Open for Submission Date':
        return openForSubmissionDate;
      case 'Submission Deadline':
        return subDeadline;
      default:
        return () => { };
    }
  };

  const getMinDate = DateType => {
    const acquiredDateMin = beforeDays(ideaDate);
    const submissionDeadlineDateMin = openForSubmissionDate;
    const expectedAcceptanceDateMin = subDeadline;
    const expectedPublicationDateMin = expectedAcceptanceDate;
    if (DateType === 'Idea Date') return '';
    else if (DateType === 'Acquired Date') return acquiredDateMin;
    else if (DateType === 'Open for Submission Date') return '';
    else if (DateType === 'Submission Deadline')
      return submissionDeadlineDateMin;
    else if (DateType === 'Expected Acceptance Date')
      return expectedAcceptanceDateMin;
    else if (DateType === 'Expected Publication Date')
      return expectedPublicationDateMin;
    else return '';
  };

  const getMaxDate = DateType => {
    if (DateType === 'Idea Date') return acquiredDate;
    return '';
  };

  const checkDateIfError = (DateType, stageId) => {
    switch (DateType) {
      case 'Idea Date':
        return stageId === STAGE_OF_Idea && !checkDateValue(ideaDate);
      case 'Acquired Date':
        return (
          (stageId >= STAGE_OF_Acquired && !checkDateValue(acquiredDate)) ||
          (acquiredDate && ideaDate && ideaDate > acquiredDate)
        );
      case 'Open for Submission Date':
        return (
          stageId >= STAGE_OF_PaperCommission &&
          stageId < STAGE_OF_Production &&
          !checkDateValue(openForSubmissionDate)
        );
      case 'Submission Deadline':
        return (
          (stageId >= STAGE_OF_Acquired && !checkDateValue(subDeadline)) ||
          (subDeadline &&
            ((openForSubmissionDate && openForSubmissionDate >= subDeadline)
            ))
        );
      case 'Expected Acceptance Date':
        return (
          (stageId >= STAGE_OF_Acquired &&
            !checkDateValue(expectedAcceptanceDate)) ||
          (expectedAcceptanceDate &&
            subDeadline &&
            subDeadline >= expectedAcceptanceDate)
        );
      case 'Expected Publication Date':
        return (
          (stageId >= STAGE_OF_Acquired &&
            !checkDateValue(expectedPublicationDate)) ||
          (expectedPublicationDate &&
            expectedAcceptanceDate &&
            expectedAcceptanceDate >= expectedPublicationDate)
        );
      default:
        return false;
    }
  };

  const fieldRequiredError = emptyErrorPrompt;
  const needAfterError = name => `This date should be after ${name}.`;

  const getDateErrorMessage = (DateType, stageId) => {
    switch (DateType) {
      case 'Idea Date':
        return fieldRequiredError;
      case 'Acquired Date':
        if (stageId >= STAGE_OF_Acquired && !checkDateValue(acquiredDate)) {
          return fieldRequiredError;
        }
        return needAfterError('Idea Date');
      case 'Open for Submission Date':
        return fieldRequiredError;
      case 'Submission Deadline':
        if (stageId >= STAGE_OF_Acquired && !checkDateValue(subDeadline)) {
          return fieldRequiredError;
        }
        // if(openForSubmissionDate && openForSubmissionDate >= subDeadline){
        return needAfterError('Open for Submission Date');
      // }
      // return needAfterError('Acquired Date');
      case 'Expected Acceptance Date':
        if (
          stageId >= STAGE_OF_Acquired &&
          !checkDateValue(expectedAcceptanceDate)
        ) {
          return fieldRequiredError;
        }
        return needAfterError('Submission Deadline');
      case 'Expected Publication Date':
        if (
          stageId >= STAGE_OF_Acquired &&
          !checkDateValue(expectedPublicationDate)
        ) {
          return fieldRequiredError;
        }
        if (expectedPublicationDate) {
          return needAfterError('Expected Acceptance Date');
        }
        return '';
      default:
        return '';
    }
  };

  //点击取消的函数
  const handleCancel = () => {
    setEditType(false);
    setError(false);
    setRemarkErr(false);
  };

  useImperativeHandle(ref, () => ({
    handleCancel: () => {
      handleCancel();
    },
    handleSubmit: () => {
      handleSubmit();
    },
  }));

  const adminSave = async () => {
    let params = {
      projectCode: infoObj[0]['projectCode'],
      handlingCE: handleCeEmail,
      specialIssueType: specialIssueType,
      specialIssueTitle: specialIssueTitle,
      journalCode: journalCodeSelect,
      leadGuestEditors: handleGEObjectToString(LeadGE),
      coGuestEditors: handleGEObjectToString(COGE),
      ideaDate: SIDateChangeFunction(ideaDate),
      siSource: SISource,
      siSourceOthersExtraValue: SISourceOther,
      acquiredDate: SIDateChangeFunction(acquiredDate),
      submissionDeadline: SISubmissionDeadlineDateChangeFunction(subDeadline),
      // submissionDeadline: SIDateChangeFunction(subDeadline),
      expectedAcceptanceDate: SIDateChangeFunction(expectedAcceptanceDate),
      expectedPublicationDate: SIDateChangeFunction(expectedPublicationDate),
      closedForSubmission: closeForSub,
      paperCommissionMethod: paperCommissionMethod,
      workFlow: workFlow,
      siRevenueModel: SIRevenueModel,
      remarks: remarks === null || remarks === undefined ? '' : remarks.trim(),
      openForSubmissionDate: SIDateChangeFunction(openForSubmissionDate),
      shelvedReason: shelvedReason,
      reactiveReason: reactiveReason,
    };
    const success = await dispatch(EditSIGeneralInfoAdmin(params));
    if (success) {
      await dispatch(getSIDetail(siId));
    }
    setEditType(false);
  };

  const SIWriteSave = async () => {
    let params = {
      projectCode: infoObj[0]['projectCode'],
      handlingCE: handleCeEmail,
      specialIssueType: specialIssueType,
      specialIssueTitle: specialIssueTitle,
      journalCode: journalCodeSelect,
      leadGuestEditors: handleGEObjectToString(LeadGE),
      coGuestEditors: handleGEObjectToString(COGE),
      ideaDate: SIDateChangeFunction(ideaDate),
      siSource: SISource,
      siSourceOthersExtraValue: SISourceOther,
      acquiredDate: acquiredDate,
      submissionDeadline: SISubmissionDeadlineDateChangeFunction(subDeadline),
      expectedAcceptanceDate: SIDateChangeFunction(expectedAcceptanceDate),
      expectedPublicationDate: SIDateChangeFunction(expectedPublicationDate),
      closedForSubmission: closeForSub,
      paperCommissionMethod: paperCommissionMethod,
      workFlow: workFlow,
      siRevenueModel: SIRevenueModel,
      remarks: remarks === null || remarks === undefined ? '' : remarks.trim(),
      openForSubmissionDate: SIDateChangeFunction(openForSubmissionDate),
      shelvedReason: shelvedReason,
      reactiveReason: reactiveReason,
    };
    const success = await dispatch(EditSIGeneralInfoT1(params));
    if (success) {
      await dispatch(getSIDetail(siId));
    }
    setEditType(false);
  };

  const SIDetailsEditItemFilter = (value, index) =>
    !(
      (
        (value.key === 'Deadline Passed' &&
          isEmptyValue(infoObj[0]['deadlinePassed'])) ||
        ((value.key === 'Reactive Reason' || value.key === 'Shelved Reason') &&
          isEmptyValue(infoObj[0][value.properity])) ||
        (value.key === 'Previous Handling CE' &&
          isEmptyValue(infoObj[0]['previousHandlingCe']))
      )
    );
  const editEnvs = {
    infoObj,
    stageId,
    CEResult,
    setHandleCeEmail,
    setGroup,
    firstTimeClick,
    submitIsClicked,
    selectTypeMap,
    setFun,
    valueFun,
    SISourceOther,
    getMinDate,
    getMaxDate,
    checkDateIfError,
    getDateErrorMessage,
    setCustomDatePickerValue,
    codeDateValue,
    handleGEStringToObject,
    GEResult,
    LeadGE,
    COGE,
    setLeadGE,
    setCOGE,
    handleCreateOpen,
    handleCheckedChange,
    closeForSub,
    remarkErr,
    setRemarkErr,
    deadlinePassed,
    subDeadline,
    checkDateValue,
    group,
    JPM,
    SubjectGroup,
    vchCode,
    handleViewMore,
    journalCodeSelect,
    journalNameSelect,
    handleJounalSearch,
    onlineISSN,
    createGEOpen,
    handleCreateClose,
    createGeIsLead,
    setJournalSelect,
    journalSelect,
    codeDateValue,
  };

  const SIDetailsEditItem = (value, index) => {
    if (
      (value.key !== 'Reactive Reason' &&
        value.key !== 'Shelved Reason' &&
        value.key !== 'Previous Handling CE') ||
      infoObj[0][value.properity] != null
    ) {
      if (value.key === 'SI Source Other' && SISource !== 'Others') {
        return null;
      } else {
        return (
          <ListItem
            key={index}
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              alignItems:
                value.key === 'Remarks' ||
                  value.key === 'Reactive Reason' ||
                  value.key === 'Shelved Reason' ||
                  value.key === 'Lead GE' ||
                  value.key === 'Co-GE'
                  ? 'start'
                  : 'center',
              padding: 0,
            }}
            //在edit进行没有内容就隐藏
            data-selenium-id='SIDetail_GeneralInfoSection-ListItem'
          >
            {value.key === 'SI Revenue Model' ? null : (
              <>
                <Stack
                  width='224px'
                  textAlign='left'
                  marginRight={1}
                  direction='row'
                  spacing='10px'
                  sx={{ color: '#596A7C', fontSize: '14px', fontWeight: '400' }}
                  data-selenium-id='SIDetail_GeneralInfoSection-Stack'
                >
                  {handleAsteriskError(value.key, stageId)}
                </Stack>
                <EditPart items={value} editEnvs={editEnvs} />
                <Box
                  id='SIDetail_GeneralInfoSection-ErrorTip'
                  data-selenium-id='SIDetail_GeneralInfoSection-ErrorTip'
                  style={{ display: 'none' }}
                >
                  <ErrorIcon
                    id='SIDetail_GeneralInfoSection-ErrorIcon'
                    data-selenium-id='SIDetail_GeneralInfoSection-ErrorIcon'
                  />
                  <span
                    id='SIDetail_GeneralInfoSection-ErrorText'
                    data-selenium-id='SIDetail_GeneralInfoSection-ErrorText'
                  >
                    Please enter this field.
                  </span>
                </Box>
              </>
            )}
          </ListItem>
        );
      }
    } else {
      return null;
    }
  };

  const intl = useIntl(); // 为国际化提供的API支持

  return (
    <Box
      data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox'
      sx={{
        width: '1092px',
        maxWidth: '100%',
      }}
    >
      <ViewMoreDialog
        content={viewMoreContent[1]}
        title={viewMoreContent[0]}
        open={viewMoreOpen}
        setOpen={setViewMoreOpen}
        data-selenium-id='SIDetail_GeneralInfoSection-ViewMoreDialog'
      />
      <CardContent
        sx={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 0,
        }}
        data-selenium-id='SIDetail_GeneralInfoSection-CardContent'
      >
        <Stack
          spacing={3}
          data-selenium-id='SIDetail_GeneralInfoSection-Stack'
          className={styleModule.GeneralInfoSectionStack}
        >
          {/* SI Details 编辑部分  */}
          <Box data-selenium-id='SIDetail_GeneralInfoSection-Box'>
            <CustomPartTitle data-selenium-id='SIDetail_GeneralInfoSection-PartTitle'>
              SI Details
            </CustomPartTitle>
            <Box data-selenium-id='SIDetail_GeneralInfoSection-GridContainer'>
              <Stack direction={'row'} spacing={7} className={styleModule.ParentEditDiv}>
                <Stack spacing={1} className={styleModule.LeftEditDiv}>
                  {leftSIDetailEditItem.map(SIDetailsEditItem)}
                </Stack>
                <Stack spacing={1} className={styleModule.RightEditDiv}>
                  {rightSIDetailEditItem
                    .filter(SIDetailsEditItemFilter)
                    .map(SIDetailsEditItem)}
                </Stack>
              </Stack>
            </Box>
          </Box>
          {/* stakeholders */}
          <Box data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox'>
            <CustomPartTitle data-selenium-id='SIDetail_GeneralInfoSection-CustomPartTitle'>
              Stakeholders
              <SelectEmailTemplate
                stageId={stageId}
                siDetail={infoObj[0]}
                handleClickSendEmailIcon={handleClickSendEmailIcon}
                emailPopoverOpen={emailPopoverOpen}
                emailAnchorEl={emailAnchorEl}
                handleClose={handleClose}
                StyledTypography={StyledTypography}
              />
            </CustomPartTitle>
            <Box data-selenium-id='SIDetail_GeneralInfoSection-GridContainer'>
              <Stack
                direction={'row'}
                spacing={7}
                data-selenium-id='SIDetail_GeneralInfoSection-GridItem1'
                className={styleModule.ParentEditDiv}
              >
                <Stack spacing={1} className={styleModule.LeftEditDiv}>
                  {leftStakeholdersEditItem
                    .filter(SIDetailsEditItemFilter)
                    .map(SIDetailsEditItem)}
                </Stack>
                <Stack spacing={1} className={styleModule.RightEditDiv}>
                  {rightStakeholdersEditItem
                    .filter(SIDetailsEditItemFilter)
                    .map(SIDetailsEditItem)}
                </Stack>
              </Stack>
            </Box>
          </Box>
          {/* dates */}
          <Box data-selenium-id='SIDetail_GeneralInfoSection-ContainerBox'>
            <CustomPartTitle data-selenium-id='SIDetail_GeneralInfoSection-CustomPartTitle'>
              Dates
            </CustomPartTitle>
            <Box data-selenium-id='SIDetail_GeneralInfoSection-GridContainer'>
              <Stack
                direction={'row'}
                spacing={7}
                data-selenium-id='SIDetail_GeneralInfoSection-GridItem1'
                className={styleModule.ParentEditDiv}
              >
                <Stack spacing={1} className={styleModule.LeftEditDiv}>
                  {rightDatesEditItem.map(SIDetailsEditItem)}
                </Stack>
                <Stack spacing={1} className={styleModule.RightEditDiv}>
                  {leftDatesEditItem
                    .filter(SIDetailsEditItemFilter)
                    .map(SIDetailsEditItem)}
                </Stack>
              </Stack>
            </Box>
          </Box>
          {/* Notes 部分  */}
          <Box data-selenium-id='SIDetail_GeneralInfoSection-NotesBox'>
            <CustomPartTitle data-selenium-id='SIDetail_GeneralInfoSection-CustomPartTitle'>
              Notes
            </CustomPartTitle>
            <Grid
              container
              data-selenium-id='SIDetail_GeneralInfoSection-GridContainer'
            >
              <Grid
                item
                xs={12}
                data-selenium-id='SIDetail_GeneralInfoSection-GridItem'
              >
                <Stack
                  spacing={2}
                  data-selenium-id='SIDetail_GeneralInfoSection-Stack'
                >
                  {notesEditItem.map(SIDetailsEditItem)}
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <SaveAndCancelButton
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            isSaving={SIDetailIsSaving}
            isDisabled={
              firstTimeClick &&
              (remarkErr ||
                error ||
                GERepeatErr ||
                (SISource === 'Others' &&
                  (SISourceOther === '' || SISourceOther === null)) ||
                (valueFun('SI Source Other')?.length ?? 0) > otherMaxLength)
            } //modified
            id='SIDetail_GeneralInfoSection-SaveAndCancelButton'
            data-selenium-id='SIDetail_GeneralInfoSection-SaveAndCancelButton'
          />
        </Stack>
      </CardContent>
      <AlertBox
        title={alertTitle}
        open={errorOpen}
        handleClose={() => {
          setErrorOpen(false);
        }}
        message={errorMessage}
        autoHideDuration={4000}
        severity={severity}
      />
    </Box>
  );
});

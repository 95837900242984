import React, { useState } from 'react';
import Icon1 from './VectorImage/ItemCollapsedButton/2/1.svg';
import Icon2 from './VectorImage/ItemCollapsedButton/2/2.svg';
import Icon3 from './VectorImage/ItemCollapsedButton/2/3.svg';
import Icon4 from './VectorImage/ItemCollapsedButton/2/4.svg';
import Icon5 from './VectorImage/ItemExpandedButton/2/1.svg';
import Icon6 from './VectorImage/ItemExpandedButton/2/2.svg';
import Icon7 from './VectorImage/ItemExpandedButton/2/3.svg';
import Icon8 from './VectorImage/ItemExpandedButton/2/4.svg';

export default function ItemFolderButtonSI(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const LeftRight = props.LeftRight;//true expand，false collapse

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const checkHoveredState = () => {
    setIsHovered(document.getElementById('ItemFolderButton_SI').contains(document.activeElement));
  };

  const handleClick = () => {
    setIsClicked(!isClicked);
    props.ItemButtonClick();
    checkHoveredState();
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      id="ItemFolderButton_SI" 
      data-selenium-id="ItemFolderButton_SI"
      LeftRight={props.LeftRight}
      style={{cursor:'pointer'}}
    ><div>
        {!LeftRight ? (
          props.isClicked ? (isHovered ? <Icon4 /> : <Icon2 />) : (isHovered ? <Icon3 /> : <Icon1 />)
        ) : (
          props.isClicked ? (isHovered ? <Icon8 /> : <Icon6 />) : (isHovered ? <Icon7 /> : <Icon5 />)
        )}</div>
    </div>
  );
  
}

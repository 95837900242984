// 'checked' | 'error | 'default'默认值
export function calcStatus(required, radioValue, contentValue,sipStage) {
  if (
    radioValue === 'Yes' ||
    (radioValue === 'No' && (!required || contentValue||[].includes(sipStage)))||
    (radioValue instanceof Array && radioValue[0]!==null&& radioValue[1]!==null)
  ) {
    return 'checked';
  }
  return 'default';
}

export function formatNumber(value) {
  if (!value) return 0;
  let number = value.length;
  // 将数字转换为字符串
  let str = number.toString();

  // 使用正则表达式在数字之间插入逗号
  str = str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return str;
}

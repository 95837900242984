import { Skeleton, Stack } from '@mui/material';
import * as React from 'react';

export function DropdownSkeleton() {
  const height = '36px';
  return (
    <Stack
      data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownSkeleton-Stack'
      spacing={1}
      sx={{
        padding: '0 4px',
        maxWidth: '600px',
      }}
    >
      <Skeleton
        variant='rectangular'
        animation='wave'
        height={height}
        data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownSkeleton-Stack-Skeleton1'
      />
      <Skeleton
        variant='rectangular'
        animation='wave'
        height={height}
        data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownSkeleton-Stack-Skeleton2'
      />
      <Skeleton
        variant='rectangular'
        animation='wave'
        height={height}
        data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownSkeleton-Stack-Skeleton3'
      />
      <Skeleton
        variant='rectangular'
        animation='wave'
        height={height}
        data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownSkeleton-Stack-Skeleton4'
      />
      <Skeleton
        variant='rectangular'
        animation='wave'
        height={height}
        data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownSkeleton-Stack-Skeleton5'
      />
    </Stack>
  );
}

import styles from './index.module.scss';
interface Props {
  className?: string;
  style?: React.CSSProperties;
  size?: number;
  text?: string;
  color?: string;
}
function Loading({ size = 60, text, color = '#3498db', className = '', style = {} }: Props) {
  return (
    <>
      <div className={[className, styles.container, styles[size]].join(' ')} style={style}>
        <div className={[styles['loading']].join(' ')} style={{borderTopColor: color, width: `${size}px`, height: `${size}px`}}></div>
        {text !== '' && <div className={styles.text}>{text}</div>}
      </div>
    </>
  );
}
export default Loading;

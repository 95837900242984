/*
 * @Author: jojo 903497174@qq.com
 * @Date: 2023-07-21 16:14:02
 * @LastEditors: LiuJingQian 1250366059@qq.com
 * @LastEditTime: 2023-07-23 16:00:06
 * @FilePath: \simt_front\src\componentsphase2\Button\SimpleIconButton.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState } from 'react';
import {debounce, IconButton} from '@mui/material';

export default function SimpleIconButton(props) {
  const {
    DefaultIcon,
    HoverIcon = DefaultIcon,
    ActiveIcon = DefaultIcon,
    DisabledIcon = DefaultIcon,
    isDisabled = false,
    id,
    // onClick = () => { },
    ...others
  } = props;
  const randomId = 'IconButton-' + useState(Math.random())[0];
  const [isHover, setIsHover] = useState(false);
  const [isActive, setIsActive] = useState(false);
  // const [isReady, setIsReady] = useState(true); //事件节流
  // const handleEnter = () => {
  //   setIsHover(true);
  // };
  // const handleLeave = (...other) => {
  //   setIsHover(false);
  //   setIsActive(false);
  // };
  // const handleDown = (...other) => {
  //   setIsHover(true);
  //   setIsActive(true);
  // };
  // const handleUp = (...other) => {
  //   //事件节流
  //   if (isReady) {
  //     setIsReady(false);
  //     setTimeout(() => {
  //       setIsReady(true);
  //     }, 100);
  //     onClick(...other);
  //     setIsActive(false);
  //   } else {
  //     // setTimeout(() => {
  //     //   setIsReady(true);
  //     // }, 100);
  //   }
  // };
  // React.useEffect(() => {
  //   //AddListenerh
  //   setTimeout(() => {
  //     try {
  //       const btnElement = document.querySelector(`[randomid="${randomId}"]`);
  //       btnElement.addEventListener('mouseenter', handleEnter, true);
  //       btnElement.addEventListener('mouseleave', handleLeave, true);
  //       btnElement.addEventListener('mousedown', handleDown, true);
  //       btnElement.addEventListener('mouseup', handleUp, true);
  //       // btnElement.addEventListener('click', onClick,true);
  //     } catch (e) { }
  //   }, 1000);
  //   return (() => {
  //     //Remove Listener
  //     try {
  //       const btnElement = document.querySelector(`[randomid="${randomId}"]`);
  //       btnElement.removeEventListener('mouseenter', handleEnter);
  //       btnElement.removeEventListener('mouseleave', handleLeave);
  //       btnElement.removeEventListener('mousedown', handleDown);
  //       btnElement.removeEventListener('mouseup', handleUp);
  //       // btnElement.removeEventListener('click', onClick);
  //     } catch (e) { }
  //   });
  // },[]);
  const handleMouseEnter = debounce(() => {
    setIsHover(true);
  }, 50);

  const handleMouseLeave = debounce(() => {
    setIsHover(false);
  }, 50);
  return (
    <IconButton
      id={id}
      randomid={randomId}
      disabled={isDisabled}
      disableFocusRipple
      disableRipple
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...others}
    >
      {
        isDisabled ?
          <DisabledIcon /> :
          isActive ?
            <ActiveIcon /> :
            isHover ?
              <HoverIcon /> :
              <DefaultIcon />
      }
    </IconButton>
  );
}
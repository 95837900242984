import { Box } from '@mui/material';
import React from 'react';
import SectionTitle from './compontents/SectionTitle';
import BoxWithShadow from './compontents/BoxWithShadow';
import ChecklistItemBlock from './compontents/ChecklistItemBlock';
import RadioBlock from './compontents/RadioBlock';
import OnlyTextBlock from './compontents/OnlyTextBlock';
import { useDispatch, useSelector } from 'react-redux';
import { calcStatus } from './utils';
import { setItemValue } from '@/actions/SIMT-SIP/SIPChecklistSubmitted/SIPChecklistAction';
import { GEChecklistItem } from './ChecklistForGESection';
import { useParams } from 'react-router-dom';
const initialSIPChecklist = [
  new GEChecklistItem(
    'sipTopicAndAssignedPortfolioMatching',
    'SI Topic and Assigned Portfolio Matching',
    'To check if the SI topic fits in the scope of your handling portfolio.',
    true
  ),
  new GEChecklistItem(
    'nonOverlappingSiTopicWithinWiley',
    'Non-overlapping SI Topic within Wiley',
    ['To check whether the SI topic is overlapping with the currently ongoing special issues within Wiley. If so, refer to Overlapping SI Guideline and try to avoid competition. If the SI has similar topic and similar timeline, then one should be careful. ', ' If the SI has same topic and same GEs, please confirm whether the SI is being used to attract paper mill submissions.'],
    true
  ),
  new GEChecklistItem(
    'nonOverlappingSiTopicOutsideWiley',
    'Non-overlapping SI Topic outside Wiley',
    ['To check whether the SI topic is overlapping with any special issues outside Wiley by Google search. ', ' If the SI has similar topic and similar timeline, then one should be careful. If the SI has same topic and same GEs, please confirm whether the SI is being used to attract paper mill submissions.'],
    true
  ),
];
const submittedSIPChecklist = [
  new GEChecklistItem(
    'validSipDetails',
    'Valid SIP Details',
    'To check whether the detailed information on SIP is complete and valid, including SIP Title, Aims and Scope as well as Topics.',
    true
  ),
];
const SSIPinitialSIPChecklist = [
  new GEChecklistItem(
    'nonOverlappingSiTopicWithinWiley',
    'Non-overlapping SI Topic within Wiley',
    ['To check whether the SI topic is overlapping with the currently ongoing special issues within Wiley. If so, refer to Overlapping SI Guideline and try to avoid competition. If the SI has similar topic and similar timeline, then one should be careful. ', ' If the SI has same topic and same GEs, please confirm whether the SI is being used to attract paper mill submissions.'],
    true
  ),
  new GEChecklistItem(
    'nonOverlappingSiTopicOutsideWiley',
    'Non-overlapping SI Topic outside Wiley',
    ['To check whether the SI topic is overlapping with any special issues outside Wiley by Google search. ', ' If the SI has similar topic and similar timeline, then one should be careful. If the SI has same topic and same GEs, please confirm whether the SI is being used to attract paper mill submissions.'],
    true
  ),
];

export function getSIPCheckListItems(sipStage,sipCode) {
  switch (sipStage) {
    case 'initial':
      return sipCode.includes('SSIP')?SSIPinitialSIPChecklist:initialSIPChecklist;
    case 'submitted':
      return submittedSIPChecklist;
    default:
      return [];
  }
}

export function ChecklistForSIPSection(props) {
  const { mustTransferBack = false } = props;
  // const { sipStage=props.stage,sipCode=props.sipCode } = useParams();
  const { sipCode: paramSipCode, sipStage: paramSipStage } = useParams();
  const { sipCode = paramSipCode, stage: sipStage = paramSipStage, noNeedQueryData = false, outerData = {} } = props;

  const dispatch = useDispatch();
  const selector = state => {
    return {
      proceeding: state.SIPChecklist.proceeding,
    };
  };
  let SIPChecklistDetail = outerData;
  if (! noNeedQueryData){
    SIPChecklistDetail = useSelector(state => state.SIPChecklist.SIPChecklistDetail);
  }

  const {  proceeding } = useSelector(selector);
  const { status, validSipDetails, validSipDetailsComments, immediateDecision, locked, ...other } =
    SIPChecklistDetail;
  // function handleValidSipDetailsChange(newValue) {
  //   dispatch(setItemValue('validSipDetails', newValue));
  // }
  // function handleValidSipDetailsCommentsChange(newValue) {
  //   dispatch(setItemValue('validSipDetailsComments', newValue));
  // }

  function calcCommentsError(required, radioValue, comments) {
    if (!(required && radioValue === 'No')) return false;
    return proceeding && !comments;
  }

  function calcItemBlockStatus(required, radioValue, comments, sipStage) {
    let status = calcStatus(required, radioValue, comments, sipStage);
    if (required && proceeding && status === 'default') {
      status = 'error';
    }
    if (comments?.length > 100000) {
      return 'error';
    }
    return status;
  }
  const getIsBlockRequired = (item) => {
    switch (sipStage) {
      case 'initial':
        return item.required && !immediateDecision && (SIPChecklistDetail['sipTopicAndAssignedPortfolioMatching'] === 'Yes' || item.key === 'sipTopicAndAssignedPortfolioMatching' || sipCode.includes('SSIP'));
      default:
        return item.required && !immediateDecision;
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '17px',
      }}
    >
      <SectionTitle>Checklist for SIP</SectionTitle>
      <BoxWithShadow mustTransferBack={mustTransferBack}>
        {getSIPCheckListItems(sipStage,sipCode).map((item, index) => (
          <ChecklistItemBlock
            id={'ChecklistForSIPSection-ItemBlock-' + index} //注意：修改这个id要慎重！！！！！！
            title={item.title}
            necessary={getIsBlockRequired(item)}
            info={item.info}
            status={mustTransferBack===true?'default':(calcItemBlockStatus(getIsBlockRequired(item), SIPChecklistDetail[item.key], SIPChecklistDetail[item.key + 'Comments'], sipStage))}
            needClear={status === 'Editing' && !locked && !getIsBlockRequired(item)}
            handleClear={() => {
              dispatch(setItemValue(item.key, null));
              dispatch(setItemValue(item.key + 'Comments', null));
            }}
            mustTransferBack={mustTransferBack}
          >
            <RadioBlock
              id='checklistForSIPRadioBlock'
              title='Qualified:'
              editing={status === 'Editing' && !locked}
              value={SIPChecklistDetail[item.key]}
              setValue={(v) => { dispatch(setItemValue(item.key, v)); }}
              radioGropList={['Yes', 'No']}
              error={proceeding && !SIPChecklistDetail[item.key] && getIsBlockRequired(item)}
              disabled={mustTransferBack}
            // disabled={immediateDecision}
            />
                <OnlyTextBlock
                  id='checklistForSIP'
                  title={status === 'Editing' && !locked ? 'Comments' : 'Comments:'}
                  required={getIsBlockRequired(item) && SIPChecklistDetail[item.key] === 'No'}
                  value={SIPChecklistDetail[item.key + 'Comments']}
                  setValue={(v) => { dispatch(setItemValue(item.key + 'Comments', v)); }}
                  editing={status === 'Editing' && !locked}
                  isError={calcCommentsError(getIsBlockRequired(item), SIPChecklistDetail[item.key], SIPChecklistDetail[item.key + 'Comments'])}
                  errorMessage=' Please provide the required information.'
                  radioValue={SIPChecklistDetail[item.key]}
                // disabled={immediateDecision}
                />
          </ChecklistItemBlock>
        ))}
      </BoxWithShadow>
    </Box>
  );
}

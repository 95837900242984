import React from 'react';
import Header from '@/components/Header';

export const CustomHeader = props => {
  const { sx, ...others } = props;
  return (
    <Header
      data-selenium-id='SIP_AcceptPage_Submodule-Custom-Header'
      sx={{
        '& .MuiToolbar-root': {
          paddingX: '26px',
        },
        ...sx,
      }}
      {...others}
    />
  );
};

import { Box, Stack } from '@mui/material';
import CreateAndUpdateNumber from '../CommonComponents/CreateAndUpdateNumber';
import {
  CreateAndUpdateLittleTitle,
  CreateAndUpdateTitle,
} from '../CommonComponents/CreateAndUpdateTitle';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useUpdateEffect } from 'ahooks';
function SubmissionOverviewPage(props) {
  const {
    value,
    setValue,
    step3NextMonitor,
    setErr,
    clickedButton,
    setHasError=e=>{},
    clickTime,
  } = props;
  const [recruitedArticles, setRecuitedArticles] = useState(
    value.numberOfArticlesRecruited
  );
  const [submittedArticles, setSubmittedArticles] = useState(
    value.numberOfArticlesSubmitted
  );
  const [acceptArticles, setAcceptArticles] = useState(
    value.numberOfArticlesAccepted
  );
  const [rejectedArticles, setRejectedArticles] = useState(
    value.numberOfArticlesRejected
  );
  const [recruitedOutRangeErr, setRecruitedOutRangeErr] = useState(false);
  const [submittedOutRangeErr, setSubmittedOutRangeErr] = useState(false);
  const [acceptOutRangeErr, setAcceptOutRangeErr] = useState(false);
  const [acceptMinErr, setAcceptMinErr] = useState(false);
  const [acceptHelperText, setAcceptHelperText] = useState('');
  const [rejectedOutRangeErr, setRejectedOutRangeErr] = useState(false);

  const [relationErr, setRelationErr] = useState(false);

  const [isClick, setIsClick] = useState(false);
  //获取当前页面的url
  const url = window.location.pathname;
  //判断当前页面的操作是否为update,如果是 返回 true， 否则返回false
  const isUpdate = () => {
    return url.indexOf('update') !== -1 ? true : false;
  };
  //获得 stage
  const getStage = () => {
    let SIArr = url.split('/');
    let stage = SIArr[SIArr.length - 1];
    switch (stage) {
      case 'Acquired':
      case 'Production':
      case 'Published':
        return stage;
      case 'PaperCommission':
        return 'Paper Commission';
      case 'SubmissionAndReview':
        return 'Submission and Review';
      default:
        return '';
    }
  };
  //根据stage和操作（update/create） 动态获取大标题
  const getTitle = () => {
    return isUpdate()
      ? `Update Stage to ${getStage()}`
      : 'Create New Special Issue';
  };

  const selector = state => {
    return {
      configInfo: state.SystemConfig.systemConfig,
    };
  };

  const { configInfo } = useSelector(selector);

  const maxNumber =
    configInfo === null ? 1000 : configInfo.eachSiArticlesMaxNumber;

  const isEmpty = value => {
    return (
      value === undefined ||
      value === null ||
      value === '' ||
      value === [] ||
      value === {}
    );
  };

  const isError = () => {
    return (
      (getStage() !== 'Paper Commission' &&
        (isEmpty(acceptArticles) ||
          isEmpty(submittedArticles) ||
          isEmpty(rejectedArticles))) ||
      relationErr ||
      acceptMinErr ||
      recruitedOutRangeErr ||
      acceptOutRangeErr ||
      submittedOutRangeErr ||
      rejectedOutRangeErr
    );
  };

  const pageHasError = isError();
  useEffect(() => {
    if (!clickedButton) return;
    setHasError(pageHasError);
  },[pageHasError, clickedButton]);

  const parseInt = value => {
    return isEmpty(value) ? 0 : Number(value);
  };

  useEffect(() => {
    setValue({
      numberOfArticlesRecruited:
        recruitedArticles === null ? null : parseInt(recruitedArticles),
      numberOfArticlesRejected:
        rejectedArticles === null ? null : parseInt(rejectedArticles),
      numberOfArticlesAccepted:
        acceptArticles === null ? null : parseInt(acceptArticles),
      numberOfArticlesSubmitted:
        submittedArticles === null ? null : parseInt(submittedArticles),
    });
    if (isUpdate() && getStage() === 'Published') {
      if (parseInt(acceptArticles) < 1) {
        setAcceptMinErr(true);
        setAcceptHelperText(
          'Number of Accepted Articles should equal to or more than 1'
        );
      } else {
        setAcceptMinErr(false);
        setAcceptHelperText('');
      }
    }

    // 只要相加小于 就会报错
    // 报错的逻辑 首先判断是否为0 不为0 就可以不用
    let tmpSubmittedArticles =
      submittedArticles === null ? 0 : submittedArticles;
    let tmpAcceptArticles = acceptArticles === null ? 0 : acceptArticles;
    let tmpRejectedArticles =
      rejectedArticles === null ? 0 : rejectedArticles;

    setRelationErr(
      parseInt(tmpSubmittedArticles) <
        parseInt(tmpAcceptArticles) + parseInt(tmpRejectedArticles)
    );

  }, [recruitedArticles, submittedArticles, acceptArticles, rejectedArticles]);

  useEffect(() => {
    setIsClick(true);
  }, [step3NextMonitor]);

  useEffect(() => {
    setErr(isError());
    if (isClick && !isError()) setIsClick(false);
  });

  useEffect(() => {
    setRecruitedOutRangeErr(
      recruitedArticles < 0 || recruitedArticles > maxNumber
    );
    setSubmittedOutRangeErr(
      submittedArticles < 0 || submittedArticles > maxNumber
    );
    setAcceptOutRangeErr(acceptArticles < 0 || acceptArticles > maxNumber);
    setRejectedOutRangeErr(
      rejectedArticles < 0 || rejectedArticles > maxNumber
    );
  }, []);

  useEffect(() => {
    if (submittedOutRangeErr || acceptOutRangeErr || rejectedOutRangeErr) {
      setRelationErr(false);
    }
  }, [submittedOutRangeErr, acceptOutRangeErr, rejectedOutRangeErr]);

  // 记录错误时候的id
  const recoredError = () => {
    if (
      getStage() !== 'Paper Commission' &&
      (isEmpty(submittedArticles) || submittedOutRangeErr || relationErr)
    ) {
      return 'NumberOfSubmittedArticlesInput';
    } else if (
      getStage() !== 'Paper Commission' &&
      (acceptOutRangeErr || acceptMinErr || isEmpty(acceptArticles))
    ) {
      return 'NumberOfAcceptedArticlesInput';
    } else if (
      getStage() !== 'Paper Commission' &&
      (isEmpty(rejectedArticles) || rejectedOutRangeErr || relationErr)
    ) {
      return 'NumberOfRejectedArticlesInput';
    }
  };
  // useUpdateEffect 初次进入页面不会渲染 ， 出错的话再进行定位，先定位到顶部，在定位到屏幕中间
  useUpdateEffect(() => {
    if (isError()) {
      let currentDOM = document.getElementById('' + recoredError());
      if (currentDOM) {
        setTimeout(() => {
          window.scrollTo({ left: 0, top: 0, behavior: 'instant' });
          window.scroll({
            left: 0,
            top:
              currentDOM.getBoundingClientRect().top -
              window.screen.availHeight / 2 +
              100,
            behavior: 'instant',
          });
        }, 200);
      }
    }
  }, [clickTime]);

  const [errorNumberOfRecruitedArticles, setErrorNumberOfRecruitedArticles] = useState(false);
  const [errorNumberOfSubmittedArticles, setErrorNumberOfSubmittedArticles] = useState(false);
  const [errorNumberOfAcceptedArticles, setErrorNumberOfAcceptedArticles] = useState(false);
  const [errorNumberOfRejectedArticles, setErrorNumberOfRejectedArticles] = useState(false);

  useEffect (() => {
    setHasError(errorNumberOfRecruitedArticles || errorNumberOfSubmittedArticles || errorNumberOfAcceptedArticles || errorNumberOfRejectedArticles);
  },[errorNumberOfRecruitedArticles, errorNumberOfSubmittedArticles, errorNumberOfAcceptedArticles, errorNumberOfRejectedArticles]);

  return (
    <Box data-selenium-id='SI_CreateAndUpdate_SubmissionOverviewPage-Box'>
      <CreateAndUpdateTitle
        data-selenium-id='SI_CreateAndUpdate_SubmissionOverviewPage-Title'
        title={getTitle()}
      />
      <CreateAndUpdateLittleTitle
        data-selenium-id='SI_CreateAndUpdate_SubmissionOverviewPage-LittleTitle'
        littleTitle='Submission Overview'
      />
      <Stack spacing={2} direction='column'>
        <CreateAndUpdateNumber
          maxNumber={maxNumber}
          minNumber={0}
          exSpecialChar
          value={recruitedArticles}
          setValue={setRecuitedArticles}
          id='NumberOfRecruitedArticles'
          titleSXTextTransform='none'
          data-selenium-id='SI_CreateAndUpdate_SubmissionOverviewPage-NumberOfRecruitedArticles'
          title='Number of Recruited Articles'
          required={false}
          setOutRangeErr={setRecruitedOutRangeErr}
          outRangeErr={recruitedOutRangeErr}
          setHasError={setErrorNumberOfRecruitedArticles}
        />
        {getStage() === 'Paper Commission' ? (
          <></>
        ) : (
          <>
            <CreateAndUpdateNumber
              maxNumber={maxNumber}
              minNumber={0}
              exSpecialChar
              value={submittedArticles}
              setValue={setSubmittedArticles}
              id='NumberOfSubmittedArticles'
              data-selenium-id='SI_CreateAndUpdate_SubmissionOverviewPage-NumberOfSubmittedArticles'
              title='Number of Submitted Articles'
              titleSXTextTransform='none'
              required={true}
              setOutRangeErr={setSubmittedOutRangeErr}
              error={relationErr}
              helperText=''
              emptyErr={clickedButton && isEmpty(submittedArticles)}
              outRangeErr={submittedOutRangeErr}
              setHasError={setErrorNumberOfSubmittedArticles}
            />
            <CreateAndUpdateNumber
              maxNumber={maxNumber}
              minNumber={(getStage() === 'Production' || getStage() === 'Published')? 1 : 0}
              exSpecialChar
              value={acceptArticles}
              setValue={setAcceptArticles}
              id='NumberOfAcceptedArticles'
              data-selenium-id='SI_CreateAndUpdate_SubmissionOverviewPage-NumberOfAcceptedArticles'
              title='Number of Accepted Articles'
              titleSXTextTransform='none'
              required={true}
              setOutRangeErr={setAcceptOutRangeErr}
              // error={(acceptMinErr || relationErr)}
              error={acceptMinErr}
              helperText={acceptHelperText}
              emptyErr={clickedButton && isEmpty(acceptArticles)}
              outRangeErr={acceptOutRangeErr}
              setHasError={setErrorNumberOfAcceptedArticles}
            />
            <CreateAndUpdateNumber
              maxNumber={maxNumber}
              minNumber={0}
              exSpecialChar
              value={rejectedArticles}
              setValue={setRejectedArticles}
              id='NumberOfRejectedArticles'
              data-selenium-id='SI_CreateAndUpdate_SubmissionOverviewPage-NumberOfRejectedArticles'
              title='Number of Rejected Articles'
              titleSXTextTransform='none'
              required={true}
              setOutRangeErr={setRejectedOutRangeErr}
              error={relationErr}
              helperText='Please note that the number of [accepted articles] plus number of [rejected articles] should not be larger than number of [submitted articles]'
              emptyErr={clickedButton && isEmpty(rejectedArticles)}
              outRangeErr={rejectedOutRangeErr}
              setHasError={setErrorNumberOfRejectedArticles}
            />
          </>
        )}
      </Stack>
    </Box>
  );
}
export default SubmissionOverviewPage;

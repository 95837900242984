/**
 * 2022/12/15
 * 在这个文件里存放后端传回来的key与前端的key的映射
 * 后端格式暂时未知
 * 前端格式为首字母小写的驼峰式
 * 注：更改下面的key值即可
 */

const backendMapping = {
  Submitted: 'submit', // 因为之前编写的时候使用的是"submit"，所以这里要映射为submit而非submitted
  Ideation:'ideation',
  'Initial Review': 'initialReview',
  'Editor Review': 'editorReview',
  Revision: 'revision',
  Accept: 'accept',
  'Complete - Accept': 'completeAccept',
  'Complete - Reject': 'completeReject',
  Shelved: 'shelved',
  'Publisher Inquiry': 'publisherInquiry', // replace "JPM" with "Publisher"
};

function stageMapping(key) {
  if (backendMapping.hasOwnProperty(key)) {
    return backendMapping[key];
  } else {
    return key;
  }
}

export default stageMapping;

import { Button, IconButton } from '@mui/material';
import Framework from './component/Framework';
import FrameworkHeader from './component/Framework/Header';
import { IDContext } from './Context';
import NumberIcon from './component/CommonComponets/NumberIcon';
import FrameworkMain from './component/Framework/Main';
import FirstSection from './component/FunctionalComponents/FirstSection';
import SecondSection from './component/FunctionalComponents/SecondSection';
import ThirdSection from './component/FunctionalComponents/ThirdSection';
import FourthSection from './component/FunctionalComponents/FourthSection';
import React, { useContext } from "react";
import TextCancelButton from '@/componentsphase2/Button/TextCancelButton';
import TextButton from './component/CommonComponets/TextButton';
import {
  resetFilter,
  setAdaptSIFilterSelected,
  setFavSIFilterSelected,
  setFilters,
} from '@/actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import { UntitledAlertBox } from '@/components/Alert/AlertBox';
import { useDispatch, useSelector } from 'react-redux';
import ResetButton from './component/CommonComponets/ResetButton';
import AdaptFilterButton from './component/CommonComponets/AdaptFilterButton';
import { handlePageChangeAction, setOpen } from '@/actions/SIMT-SI/SpecialIssue/SIAction';
import { cancelSelectAll } from '@/actions/SeleteAction';
import CloseHoverIcon from '@/assets/SIOverviewColumnCloseHoverFigma.svg';
import FilterCloseIcon from '@/assets/FilterCloseIcon.svg';
import { handleFileterJournalName } from '@/utils/commonUtils';
import { compareFilter, getSum, initSIFilterSelected, prepareFilters, useSingleSIDateRangePicker } from './utils';
import { unstable_batchedUpdates } from 'react-dom';
import { isCNmarketingContext } from "../../../../Route";
import {loadFavourite, saveFilters} from "@/actions/SIMT-SI/SpecialIssueFilter";

export default function FilterPage(props) {
  const { id = 'filter', show = false, handleClose } = props;
  const dispatch = useDispatch();
  const isCNmarketing = useContext(isCNmarketingContext) ?? false;

  const [hover, setHover] = React.useState(false);
  const [openLoadedFilterOptions, setOpenLoadedFilterOptions] =
    React.useState(false);

  const [openSaveSuccess, setOpenSaveSuccess] = React.useState(false);

  const {
    siFilterSelected,
    adaptSIFilterSelected,
    favSIFilterSelected,
    hasFilter,
    userId,
  } = useSelector(state => {
    return {
      siFilterSelected: state.Filter.siFilterSelected,
      adaptSIFilterSelected: state.Filter.adaptSIFilterSelected,
      favSIFilterSelected: state.Filter.favSIFilterSelected,
      hasFilter: state.Filter.hasFilter,
      userId: state.User.userId,
    };
  });

  const handleLoadFavouriteFilter = async () => {
    const isSuccess = await dispatch(loadFavourite(false, true, isCNmarketing,userId));
    if (isSuccess) {
      setOpenLoadedFilterOptions(true);
    }
  };

  const handleSaveFilter = async () => {
    // 从selected构造
    // 它不需要保留CeName，所以第二个参数直接传false
    const favFilters = prepareFilters(siFilterSelected, true,true);
    await dispatch(saveFilters(userId,favFilters));
    setOpenSaveSuccess(true);
    dispatch(setFavSIFilterSelected(favFilters));
  };

  const handleReset = () => {
    dispatch(resetFilter());
    let favFilters = prepareFilters(initSIFilterSelected, true,true);
    dispatch(saveFilters(userId,favFilters));
    dispatch(setFavSIFilterSelected(favFilters));
    dispatch(setAdaptSIFilterSelected(favFilters));
    // dispatch(setFilters(prepareFilters(initSIFilterSelected, true,false)));
    setResetSignal(!resetSignal);
  };

  const handleApply = async () => {
    const filters = prepareFilters(siFilterSelected,true,false);
    await dispatch(setFilters(filters));
    dispatch(setAdaptSIFilterSelected(prepareFilters(siFilterSelected, true,true)));
    // sessionStorage.setItem('ChinaGE', siFilterSelected?.ChinaGESelected?.[0]);
    unstable_batchedUpdates(() => {
      dispatch(handlePageChangeAction(1)); // set page to 1 after changing the filters
      // dispatch(setOpen("filter", false));
      // dispatch(setOpen('newFilter', false));
      dispatch(cancelSelectAll()); // clear all selected items
    });
  };

  //  Inorder to translate local signal to firstSection and secondSection to reset their state, need some signal
  const [resetSignal, setResetSignal] = React.useState(false);

  return (
    <IDContext.Provider value={id}>
      <Framework show={show}>
        <FrameworkHeader>
          <ResetButton disabled={getSum(prepareFilters(siFilterSelected, true,true)) === 0} handleClick={handleReset} />
          <AdaptFilterButton
            disabled={
              (getSum(prepareFilters(siFilterSelected, true,true)) !== 0 &&
              compareFilter(
                prepareFilters(siFilterSelected, true,true),
                adaptSIFilterSelected
              )) || (
                useSingleSIDateRangePicker(siFilterSelected)
              ) 
              // &&siFilterSelected.ChinaGESelected===adaptSIFilterSelected.ChinaGE
              // &&sessionStorage.getItem('ChinaGE')===(siFilterSelected?.ChinaGESelected?.[0]??'undefined')
            }
            handleClick={handleApply}
          />
          <TextButton
            id='btnResetToMyFilter'
            dataSeleniumId={
              'SIPage_Overview-Filter-Overview-btnResetToMyFilter'
            }
            label='Reset to my filter'
            handleClick={handleLoadFavouriteFilter}
            disabled={
              getSum(favSIFilterSelected) ===0 ||
              compareFilter(
                prepareFilters(siFilterSelected, true,true),
                favSIFilterSelected
              )
            }
          />
          <TextButton
            id='btnSaveAsMyFilter'
            dataSeleniumId={'SIPage_Overview-Filter-Overview-btnSaveAsMyFilter'}
            label='Save as my filter'
            handleClick={handleSaveFilter}
            disabled={
              compareFilter(
                prepareFilters(siFilterSelected, true,true),
                favSIFilterSelected
              ) || (
                useSingleSIDateRangePicker(siFilterSelected)
              ) 
            }
          />
          <IconButton
            disableRipple
            id='btnCloseSIFilter'
            data-selenium-id={
              'SIPage_Overview-Filter-Overview-btnCloseSIFilter'
            }
            onClick={() => {
              handleClose();
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            sx={{
              padding: '0px',
            }}
          >
            {hover ? <CloseHoverIcon /> : <FilterCloseIcon />}
          </IconButton>
        </FrameworkHeader>
        <FrameworkMain>
          <FirstSection resetSignal={resetSignal} />
          <SecondSection />
          <ThirdSection />
          <FourthSection />
        </FrameworkMain>
      </Framework>
      <UntitledAlertBox
        style={{ top: '123px', left: '103px',right:'aotu' }}
        id='siPageFilterDrawerSaveFilterSuccessAlert'
        data-selenium-id={
          'SIPage_Overview-anotherNewFilterDrawer-Overview-Save-Filter-Success-Alert'
        }
        open={openSaveSuccess}
        severity='success'
        message='Successfully saved your filter.'
        autoHideDuration={3000}
        handleClose={() => {
          setOpenSaveSuccess(false);
        }}
      />
      <UntitledAlertBox
        style={{ top: '123px', left: '103px',right:'auto' }}
        id='siPageFilterDrawerLoadFilterSuccessAlert'
        data-selenium-id={
          'SIPage_Overview-anotherNewFilterDrawer-Overview-Load-Filter-Success-Alert'
        }
        open={openLoadedFilterOptions}
        severity='info'
        message='Your saved filter options have been loaded.'
        autoHideDuration={3000}
        handleClose={() => {
          setOpenLoadedFilterOptions(false);
        }}
      />
    </IDContext.Provider>
  );
}

import { axios_instance } from '@/utils/axios_instance';

export const LOAD_DATA = 'LOAD_DATA';
export const SET_ACQUIRE_FILTER_SELECT = 'SET_ACQUIRE_FILTER_SELECT';
export const SET_ACQUIRE_OVERVIEW_FILTE = 'SET_ACQUIRE_OVERVIEW_FILTE';
export const ACQUIRE_OVERVIEW_FILTER_RESET = 'ACQUIRE_OVERVIEW_FILTER_RESET';

export const SET_ARTICLE_FILTER_SELECT = 'SET_ARTICLE_FILTER_SELECT';
export const SET_ARTICLE_OVERVIEW_FILTE = 'SET_ARTICLE_OVERVIEW_FILTE';
export const ARTICLE_OVERVIEW_FILTER_RESET = 'ARTICLE_OVERVIEW_FILTER_RESET';

export const SET_ARTICLE_OAWAIVERUSE_FILTER_SELECT = 'SET_ARTICLE_OAWAIVERUSE_FILTER_SELECT';
export const SET_ARTICLE_OAWAIVERUSE_FILTE = 'SET_ARTICLE_OAWAIVERUSE_FILTE';
export const ARTICLE_OAWAIVERUSE_FILTER_RESET = 'ARTICLE_OAWAIVERUSE_FILTER_RESET';

export const SET_ARTICLE_OAP_FILTER_SELECT = 'SET_ARTICLE_OAP_FILTER_SELECT';
export const SET_ARTICLE_OAP_FILTE = 'SET_ARTICLE_OAP_FILTE';
export const ARTICLE_OAP_FILTER_RESET = 'ARTICLE_OAP_FILTER_RESET';



export function clearReportFilter() {
  return dispatch => {
    dispatch({ type: ACQUIRE_OVERVIEW_FILTER_RESET });
  };
}

export function setSelect(which, data) {
  return dispatch => {
    dispatch({
      type: SET_ACQUIRE_FILTER_SELECT,
      data: { which: which, data: data },
    });
  };
}

export function setReportAcquireOverviewFilters(filters) {
  return dispatch => {
    dispatch({ type: SET_ACQUIRE_OVERVIEW_FILTE, data: filters });
  };
}

export function clearArticleFilter() {
  return dispatch => {
    dispatch({ type: ARTICLE_OVERVIEW_FILTER_RESET });
  };
}

export function clearArticleOAWFilter() {
  return dispatch => {
    dispatch({ type: ARTICLE_OAWAIVERUSE_FILTER_RESET });
  };
}

export function clearArticleOAPFilter() {
  return dispatch => {
    dispatch({ type: ARTICLE_OAP_FILTER_RESET });
  };
}

export function setArticleFilterSelect(which, data) {
  return dispatch => {
    dispatch({
      type: SET_ARTICLE_FILTER_SELECT,
      data: { which: which, data: data },
    });
  };
}

export function setArticleOAWFilterSelect(which, data) {
  return dispatch => {
    dispatch({
      type: SET_ARTICLE_OAWAIVERUSE_FILTER_SELECT,
      data: { which: which, data: data },
    });
  };
}

export function setArticleOAPFilterSelect(which, data) {
  return dispatch => {
    dispatch({
      type: SET_ARTICLE_OAP_FILTER_SELECT,
      data: { which: which, data: data },
    });
  };
}

export function setArticleOverviewFilters(filters) {
  return dispatch => {
    dispatch({ type: SET_ARTICLE_OVERVIEW_FILTE, data: filters });
  };
}

export function setArticleOAWaiverUseFilters(filters) {
  return dispatch => {
    dispatch({ type: SET_ARTICLE_OAWAIVERUSE_FILTE, data: filters });
  };
}

export function setArticleOAPFilters(filters) {
  return dispatch => {
    dispatch({ type: SET_ARTICLE_OAP_FILTE, data: filters });
  };
}
/**
 * 
 * @param {string} condition { operate: "equal", value: "Others" }
 * @param {array | string} value 
 * @returns 
 */
export function judgeCondition(condition, value) {
  if (!condition) {
    return true;
  }
  switch (condition.operate) {
    case 'includes':
      return value?.includes(condition.value);
    case 'notIncludes':
      return !value?.includes(condition.value);
    case 'equal':
      return value === condition.value;
    case 'notEqual':
      return value !== condition.value;
  }
  return false;
}

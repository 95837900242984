import { paramsEncode } from "@/utils/commonUtils";
import { axios_instance } from "@/utils/axios_instance";
import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";
import { LOAD_GE, LOAD_GE_CODES } from "@/actions/SIMT-SI/GuestEditor/GEAction";
import getAuth from "@/utils/getAuth";
const {fromEntries,entries}=Object;

/**
 * 请求GE列表
 * @param {number} page
 * @param {number} pageSize
 * @param {'desc'|'asc'} order
 * @param {string} orderBy
 * @param {string} searchContent
 * @param geFilterMap
 * @returns {(function(*): Promise<void>)|*}
 */
export function loadGEData(
    page,
    pageSize,
    order,
    orderBy,
    searchContent,
    geFilterMap = {}
) {
    /**
     * @param {import("redux").Dispatch} dispatch
     */
    return async dispatch => {
        try {
            const geKeywords=(()=>{
              if((Object.prototype.toString.call(searchContent) === '[object String]' && searchContent?.constructor===String && typeof searchContent === 'string')){
                return searchContent.split(',').filter(i=>Boolean(i));
              }else return [];
            })();
            let res = await axios_instance.post('/api/v1/si-service/guest-editors/overview',
                {
                    pageNum: page,
                    pageSize,
                    orderField: orderBy,
                    orderType: order,
                    ...(geKeywords.length&&{geKeywords}),
                    geFilterMap:fromEntries(entries(geFilterMap).filter(i=>{
                      const v=i[1];
                      return Array.isArray(v)?v.length:Boolean(v);})),
                },
                {
                    headers: {
                        authorization: getAuth()??'',
                    },
                });
            const { code, data } = res.data;
            if (code === 200) {
                const returnData = {
                    result: [],
                    count: data.count,
                };
                for (const resultElement of data.result) {
                    const temp = {
                        ...resultElement,
                    };
                    temp['id'] = temp.geCode;
                    returnData.result.push(temp);
                }
                // 修改return data的一些属性
                dispatch({ type: LOAD_GE, data: returnData });
            } else {
                dispatch({ type: LOAD_GE, data: { result: [], count: 0 } });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'common.failToLoad',
                        { value: 'common.ges' },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({ type: LOAD_GE, data: { result: [], count: 0 } });
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToLoad',
                    { value: 'common.ges' },
                    SEVERITIES.error
                )
            );
        }
    };
}

export function loadGECodes(geFilterMap = {}) {
    return async dispatch => {
        try {
            let res = await axios_instance.post(
                '/guestEditor/getFilterGeCode',
                geFilterMap,
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            const { code, data } = res.data;
            if (code === 200) {
                dispatch({ type: LOAD_GE_CODES, data: data });
            } else {
                dispatch({ type: LOAD_GE_CODES, data: [] });
            }
        } catch (err) {
            dispatch({ type: LOAD_GE_CODES, data: [] });
        }
    };
}
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Box } from '@mui/material';
import { ChartGroups } from '@/components/Chart/ChartGroups';
import { ChartList } from '@/components/Chart/ChartList';
import styles from './index.module.scss';
import { changeSiderOpen } from '@/actions/SIMT-User/User/UserManageAction';

import subjectGroupAcqImg from '@/assets/ReportDiagrams/A&A/OAvsTotal/Acq/SubjectGroup.png';
import businessDivisionAcqImg from '@/assets/ReportDiagrams/A&A/OAvsTotal/Acq/BusinessDivision.png';

import subjectGroupYTDImg from '@/assets/ReportDiagrams/A&A/OAvsTotal/Ytd/SubjectGroup.png';
import businessDivisionYTDImg from '@/assets/ReportDiagrams/A&A/OAvsTotal/Ytd/BusinessDivision.png';

import subjectGroupActiveImg from '@/assets/ReportDiagrams/A&A/OAvsTotal/Active/SubjectGroup.png';
import businessDivisionActiveImg from '@/assets/ReportDiagrams/A&A/OAvsTotal/Active/BusinessDivision.png';

import { MONTH } from './Constants/index.js';
import AAndAOAvsTotalChart from './Diagrams';

import {
  OA_AND_OO_SI_ACQUISITION,
  OA_AND_OO_SI_ACQUISITION_YTD,
  OA_AND_OO_ACTIVE_SI,
  SUBJECT_GROUP,
  BUSINESS_DIVISION,
  SI_ACQUISITION,
  SI_ACQUISITION_YTD,
  ACTIVE_SI,
} from './Constants/index.js';

const VALUE_ATTRIBUTE_ALL_NAMES = [
  'acquiredSiOa',
  'acquiredSi',
  'acquiredYtdOa',
  'acquiredYtd',
  'activeSiOa',
  'activeSi',
];
const BAR_SERIES_LIST = [
  'Acquired SI OA-[month]',
  'Acquired SI OO-[month]',
  'Acquired SI OA-YTD',
  'Acquired SI OO-YTD',
  'Active SI OA',
  'Active SI OO',
];

const baseDataURL = '/reports/acquired-active/oa-vs-total/';
const baseExcelTableURL = '/reports/export/acquired-active/oa-vs-total/export';

const DIAGRAM_SETTINGS = [
  // 1st group
  {
    groupName: OA_AND_OO_SI_ACQUISITION,
    chartName: SUBJECT_GROUP,
    hasAdditionalFilter: true,
    hasSelectAllOption: true,
    catagoryAttributeName: 'sgAbbr',
    url: `${baseDataURL}sg/`,
    downloadUrl: `${baseExcelTableURL}/sg`,
    tableDownloadTitle: SI_ACQUISITION,
  },
  {
    groupName: OA_AND_OO_SI_ACQUISITION,
    chartName: BUSINESS_DIVISION,
    hasAdditionalFilter: true,
    hasSelectAllOption: false,
    catagoryAttributeName: 'businessDivision',
    url: `${baseDataURL}bd/`,
    downloadUrl: `${baseExcelTableURL}/bd`,
    tableDownloadTitle: SI_ACQUISITION,
  },
  // 2nd group
  {
    groupName: OA_AND_OO_SI_ACQUISITION_YTD,
    chartName: SUBJECT_GROUP,
    hasAdditionalFilter: true,
    hasSelectAllOption: true,
    catagoryAttributeName: 'sgAbbr',
    url: `${baseDataURL}sg/`,
    downloadUrl: `${baseExcelTableURL}/sg`,
    tableDownloadTitle: SI_ACQUISITION_YTD,
  },
  {
    groupName: OA_AND_OO_SI_ACQUISITION_YTD,
    chartName: BUSINESS_DIVISION,
    hasAdditionalFilter: true,
    hasSelectAllOption: false,
    catagoryAttributeName: 'businessDivision',
    url: `${baseDataURL}bd/`,
    downloadUrl: `${baseExcelTableURL}/bd`,
    tableDownloadTitle: SI_ACQUISITION_YTD,
  },
  // 3rd group
  {
    groupName: OA_AND_OO_ACTIVE_SI,
    chartName: SUBJECT_GROUP,
    hasAdditionalFilter: true,
    hasSelectAllOption: true,
    catagoryAttributeName: 'sgAbbr',
    url: `${baseDataURL}sg/`,
    downloadUrl: `${baseExcelTableURL}/sg`,
    tableDownloadTitle: ACTIVE_SI,
  },
  {
    groupName: OA_AND_OO_ACTIVE_SI,
    chartName: BUSINESS_DIVISION,
    hasAdditionalFilter: true,
    hasSelectAllOption: false,
    catagoryAttributeName: 'businessDivision',
    url: `${baseDataURL}bd/`,
    downloadUrl: `${baseExcelTableURL}/bd`,
    tableDownloadTitle: ACTIVE_SI,
  },
];

const CHART_GROUP_TITLES = [
  {
    groupTitle: OA_AND_OO_SI_ACQUISITION,
    levels: [
      {
        chartIndex: 0,
        title: SUBJECT_GROUP,
        img: subjectGroupAcqImg,
      },
      {
        chartIndex: 1,
        title: BUSINESS_DIVISION,
        img: businessDivisionAcqImg,
      },
    ],
  },
  {
    groupTitle: OA_AND_OO_SI_ACQUISITION_YTD,
    levels: [
      {
        chartIndex: 2,
        title: SUBJECT_GROUP,
        img: subjectGroupYTDImg,
      },
      {
        chartIndex: 3,
        title: BUSINESS_DIVISION,
        img: businessDivisionYTDImg,
      },
    ],
  },
  {
    groupTitle: OA_AND_OO_ACTIVE_SI,
    levels: [
      {
        chartIndex: 4,
        title: SUBJECT_GROUP,
        img: subjectGroupActiveImg,
      },
      {
        chartIndex: 5,
        title: BUSINESS_DIVISION,
        img: businessDivisionActiveImg,
      },
    ],
  },
];

const DiagramContent = () => {
  // get selectedChartIndex from URL
  let [searchParams, setSearchParams] = useSearchParams();
  let selectedChartIndex = searchParams.get('selectedChartIndex');
  if (selectedChartIndex == null) {
    selectedChartIndex = -1;
  } else {
    selectedChartIndex = parseInt(selectedChartIndex);
  }

  const setShowTableNav = chartIndex => {
    setSearchParams({ showTable: false, selectedChartIndex: chartIndex });
  };

  const siderOpen = useSelector(state => state.UserManage.siderOpen);
  const dispatch = useDispatch();

  const { selectDate } = useSelector(state => {
    return {
      // selectDate: state.Report.OAvsTotalSelectDate,
      selectDate: state.Report.selectDate,
    };
  });

  const [formatdate, formatMonth] = useMemo(() => {
    let date = selectDate;
    // default is today
    if (date === null) {
      date = moment().utc().format('YYYY-M');
    }
    let [year, month] = date.split('-');
    month = MONTH[month];
    return [month + ' ' + year, month];
  }, [selectDate]);

  // update group titles with selected month year
  const formattedChartGroupTitles = CHART_GROUP_TITLES.map(chart => ({
    ...chart,
    groupTitle: chart.groupTitle + ' ' + formatdate,
  }));

  const {
    groupName,
    chartName,
    hasAdditionalFilter,
    hasSelectAllOption,
    catagoryAttributeName,
    url,
    downloadUrl,
    tableDownloadTitle,
  } = useMemo(() => {
    if (
      selectedChartIndex >= 0 &&
      selectedChartIndex < DIAGRAM_SETTINGS.length
    ) {
      return DIAGRAM_SETTINGS[selectedChartIndex];
    } else {
      return {
        groupName: '',
        chartName: '',
        hasAdditionalFilter: false,
        hasSelectAllOption: false,
        catagoryAttributeName: null,
        url: null,
        downloadUrl: null,
        tableDownloadTitle: null,
      };
    }
  }, [selectedChartIndex]);

  // diagram legend and ..
  const { barSeriesValue, valueAttributeNames } = useMemo(() => {
    console.log('=selectedChartIndex=', selectedChartIndex);
    if (selectedChartIndex >= 0 && selectedChartIndex <= 1) {
      let barSeriesValue = BAR_SERIES_LIST.slice(0, 2);
      barSeriesValue[0] = barSeriesValue[0].substring(0, 15) + formatMonth;
      barSeriesValue[1] = barSeriesValue[1].substring(0, 15) + formatMonth;
      let valueAttributeNames = VALUE_ATTRIBUTE_ALL_NAMES.slice(0, 2);
      return {
        barSeriesValue: barSeriesValue,
        valueAttributeNames: valueAttributeNames,
      };
    } else if (selectedChartIndex >= 2 && selectedChartIndex <= 3) {
      let barSeriesValue = BAR_SERIES_LIST.slice(2, 4);
      let valueAttributeNames = VALUE_ATTRIBUTE_ALL_NAMES.slice(2, 4);
      return {
        barSeriesValue: barSeriesValue,
        valueAttributeNames: valueAttributeNames,
      };
    } else if (selectedChartIndex >= 4 && selectedChartIndex <= 5) {
      let barSeriesValue = BAR_SERIES_LIST.slice(4, 6);
      let valueAttributeNames = VALUE_ATTRIBUTE_ALL_NAMES.slice(4, 6);
      return {
        barSeriesValue: barSeriesValue,
        valueAttributeNames: valueAttributeNames,
      };
    } else {
      return {
        barSeriesValue: ['', ''],
        valueAttributeNames: ['', ''],
      };
    }
  }, [selectedChartIndex, selectDate]);

  const onSelectChartIndex = index => {
    setShowTableNav(index);

    // if a chart is selected and the side menu is open, close the side menu
    if (index >= 0 && index < DIAGRAM_SETTINGS.length && siderOpen) {
      dispatch(changeSiderOpen(false));
    }
  };

  const cancelSelectedChart = () => onSelectChartIndex(-1);

  return (
    <Box sx={{ marginTop: '32px', paddingBottom: '62px' }}>
      {selectedChartIndex >= 0 ? (
        <Box className={styles['card-content-container']}>
          <div
            className={styles['card-content-paper']}
            disableGutters
            maxWidth={false}
          >
            <AAndAOAvsTotalChart
              header={groupName + ' ' + formatdate}
              subHeader={chartName}
              selectedDate={selectDate}
              formatdate={formatdate}
              cancelSelectedChart={cancelSelectedChart}
              showSubjectGroupsSelector={false}
              valueAttributeNames={valueAttributeNames}
              hasAdditionalFilter={hasAdditionalFilter}
              hasSelectAllOption={hasSelectAllOption}
              catagoryAttributeName={catagoryAttributeName}
              barSeriesValue={barSeriesValue}
              url={url}
              downloadUrl={downloadUrl}
              tableDownloadTitle={tableDownloadTitle}
            />
          </div>
          <ChartList
            chartGroupTitles={formattedChartGroupTitles}
            onSelectChartIndex={onSelectChartIndex}
            selectedChartIndex={selectedChartIndex}
          />
        </Box>
      ) : (
        <ChartGroups
          chartGroupTitles={formattedChartGroupTitles}
          onSelectChartIndex={onSelectChartIndex}
          selectedChartIndex={selectedChartIndex}
        />
      )}
    </Box>
  );
};

export default DiagramContent;

import React from 'react';
import {
  IconButton
} from '@mui/material';

import DrawerCloseIcon from '../assets/DrawerCloseIcon.svg';

export default function DrawerCloseButton(props){
  const { onClick, sx} = props;
  return(
    <IconButton
      id='closePageButton'
      disableRipple
      onClick={onClick}
      sx={{
        padding: '0px',
        '&:hover': {
          'svg path': {
            fill: '#596A7C',
          }
        },
        ...sx
      }}
    >
      <DrawerCloseIcon />
    </IconButton>
  );
};
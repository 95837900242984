import WiderMain from '@/components/WiderMain';
import { Box, Stack } from '@mui/material';
import MyBreadCrums from './components/MyBreadCrums';
import Header from './Header';
import { useSelector } from 'react-redux';
import Body from './Body';

let id_prefix = 'SIPPage_CreateSIPPage-Index-WiderMain';

export default function CreateSIPPage() {
  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
    };
  };
  const { open } = useSelector(selector);
  return (
    <WiderMain
      data-selenium-id={`${id_prefix}`}
      sx={{
        paddingX: '24px',
        paddingY: 0,
        margin: 0,
        marginLeft: open ? '240px' : '83px',
        boxSizing: 'border-box',
        backgroundColor: 'transparent',
        width: 'auto',
        '& *': {
          boxSizing: 'border-box',
        },
        minHeight:'unset'
      }}
      marginTop='4px'
    >
      <MyBreadCrums data-selenium-id={`${id_prefix}-MyBreadCrums`} />
      <Stack spacing={0.5} direction='column'>
        <Header data-selenium-id={`${id_prefix}-Header`} />
        <Body data-selenium-id={`${id_prefix}-Body`} />
      </Stack>
    </WiderMain>
  );
}

export const commonButtonContainerStyle = {
  height: '60px',
  background: '#fafafa',
  // boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
  position: 'relative',
  padding: '12px 0', //'12px 32px'
  marginRight: '-112px', //174-32=142
};
export const commonPopContainerStyle = {
  minHeight: '152px',
  background: '#fafafa',
  boxShadow: '0px -6px 12px 0 #262E351F',
  position: 'relative',
  padding: '0px 136px',
  alignItems: 'right',
  justifyContent: 'right',
};
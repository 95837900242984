import { useEffect } from 'react';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';

function ActiveExpiredPage() {
  const navigator = useNavigate();

  const handleToLogin = () => {
    navigator('/simt/login');
  };

  return (
    <div data-selenium-id='Login_ActiveEmailPage-ActiveExpired-Div'>
      <Header data-selenium-id='Login_ActiveEmailPage-ActiveExpired-Header' />
      <Box
        display='flex'
        width='100%'
        alignItems='flex-start'
        justifyContent='center'
        mt={25}
        mb={35}
        data-selenium-id='Login_ActiveEmailPage-ActiveExpired-FirstBox'
      >
        <Box
          data-selenium-id='Login_ActiveEmailPage-ActiveExpired-FirstBox-FistBox'
          sx={{
            width: '630px',
            background: '#ffffff',
            border: '1px solid #dddddd',
            boxSizing: 'border-box',
            borderRadius: '4px',
            p: '46px 40px',
          }}
        >
          <Typography
            fontSize='34px'
            lineHeight='46px'
            color='#484848'
            data-selenium-id='Login_ActiveEmailPage-ActiveExpired-FirstTypography'
          >
            Activation Code Expired
          </Typography>
          <Typography
            data-selenium-id='Login_ActiveEmailPage-ActiveExpired-SecondTypography'
            fontSize='16px'
            lineHeight='24px'
            color='#484848'
            mt={'16px'}
          >
            Account activation code has expired. Please contact System Admin or
            Super Admin to resend the activitation link.
          </Typography>
          <Button
            id='activeExpiredLoginButton'
            data-selenium-id='Login_ActiveEmailPage-ActiveExpired-ActiveExpiredLoginButton'
            variant='contained'
            onClick={handleToLogin}
            sx={{
              mt: '16px',
              width: '73px',
              height: '36px',
              background: '#007eb6',
              fontWeight: 600,
            }}
          >
            HOME
          </Button>
        </Box>
      </Box>
      <Footer />
    </div>
  );
}

export default ActiveExpiredPage;

/**
 * This file includes basic eChart options for barchart & piechart diagram type
 * The 1st part has some common constants
 * The 2nd defined an Options class
 */

/**
 * padding left and right
 */
const CHART_PADDING_LEFT = 72;
const CHART_PADDING_RIGHT = 72;

/**
 * title & legend
 */
const TITLE_HEIGHT = 70;
const TITLE_TOP_PADDING = 30;

/**
 * bar chart
 */
const BARCHART_TOP = 40;
const BARCHART_LEFT = 312.5;
const BARCHART_RIGHT = 20;
const BARCHART_HEIGHT = 373;

/**
 * title & legend
 */
/**
 * legend height change
 */
const LEGEND_HEIGHT = 95;
/**
 * legend height change
 */
const LEGEND_PADDING_BOTTOM = 45;

/**
 * zoom controller
 */
const ZOOM_CONTROL_PADDING_LEFT = 10;
const ZOOM_CONTROL_RIGHT_OFFSET = 30;

/**
 * Pie chart
 */
const PIE_CHART_PADDING_LEFT = 40;
const PIECHART_RADIUS = 100;

const MAX_LABEL_SIZE = 7;
/**
 * dataZoom
 */
const DATA_ZOOM_INSIDE = {
  type: 'inside',
  yAxisIndex: 0,
  start: 0,
  end: 100,
};

const DATA_ZOOM_SLIDER = {
  type: 'slider',
  yAxisIndex: 0,
  // right
  show: true,
  showDetail: true,
  showDataShadow: false,
  fillerColor: '#4C81EB',
  backgroundColor: '#D9D9D9B3',
  borderRadius: 15,
  width: 4,
  moveHandleSize: 0,
  handleIcon: 'circle',
  handleSize: 12,
  handleStyle: {
    borderColor: '#FFFFFF',
    borderWidth: 3,
    color: '#4C81EB',
  },
  textStyle: {
    fontSize: 8,
    color: '#113D95',
    backgroundColor: '#F1F3F5',
    padding: 5,
    height: 18,
    lineHeight: 18,
    borderRadius: 3,
  },
  filterMode: 'empty',
};

const LINE_STYLE = {
  Color: '#DCDCDC',
  Type: 'solid',
  width: 0.4,
  opacity: 1,
};

const X_AXIS_LABLE_STYLE = {
  color: '#6F6F6F',
  fontStyle: 'normal',
  fontWeight: 400,
  fontFamily: 'Open Sans',
  fontSize: 10,
  lineHeight: 10,
};

const X_AXIS = {
  type: 'value',
  gridIndex: 0,
  nameTextStyle: {
    fontSize: '50px',
    align: 'left',
  },
  splitNumber: 10,
  /**
   * modified start
   *
   * min is integer
   */
  minInterval: 1,
  /**
   * modified end
   *
   * min is integer
   */
  axisLabel: {
    show: true,
    interval: 'auto',
    inside: false,
    rotate: 0,
    margin: 8,
    formatter: function (value, index) {
      return value;
    },
    showMinLabel: null,
    showMaxLabel: null,
    textStyle: X_AXIS_LABLE_STYLE,
  },
  splitLine: {
    show: true,
    interval: 'auto',
    lineStyle: LINE_STYLE,
  },
};

const Y_AXIS_LABLE_STYLE = {
  color: '#6F6F6F',
  fontStyle: 'normal',
  fontWeight: 400,
  fontFamily: 'Open Sans',
  fontSize: 10,
  lineHeight: 8.5,
};

const Y_AXIS = {
  type: 'category',
  inverse: true,
  axisTick: {
    show: false,
  },
  axisLabel: {
    show: true,
    interval: 'auto',
    inside: false,
    rotate: 0,
    margin: 8,
    formatter: function (value, index) {
      return value;
    },
    showMinLabel: null,
    showMaxLabel: null,
    textStyle: Y_AXIS_LABLE_STYLE,
    // width: 75,
    overflow: 'break',
    verticalAlign: 'middle',
  },
  axisLine: {
    show: true,
    onZero: true,
    lineStyle: {
      color: '#979797',
      width: 0.4,
    },
  },
};

const BAR_SERIE_LABEL_STYLE = {
  datasetIndex: 0,
  type: 'bar',
  // stack: "total",
  label: {
    show: true,
  },
  emphasis: {
    disabled: true,
  },
  itemStyle: {
    normal: {
      label: {
        show: true,
        position: 'inside',
        textStyle: {
          // color: "#FFFFFF",
          fontSize: 7,
        },
      },
    },
    borderWidth: 1,
    // borderColor: "#FF8180"
  },
};

// "OA" "ALL" labels on the left side of legend
const LEGEND_LABEL_STYLE = {
  icon: 'none',
  selectedMode: false,
  itemGap: 20,
  width: '50px',
  textStyle: {
    fontFamily: 'Open Sans',
    fontSize: '10px',
    fontWeight: '700',
    color: '#596A7C',
  },
  // bottom: "12px",
  bottom: LEGEND_PADDING_BOTTOM + 2,
  right: '86%',
  formatter: name => {
    // use the last two legends to show the labels, and hide or others
    // temp solution to get last two legends
    const allIndex = name.indexOf('Production OA');
    const oaIndex = name.indexOf('Published in selected FY OA');
    // console.log(oaIndex);
    if (oaIndex >= 0) {
      return 'OA';
    } else if (allIndex >= 0) {
      return 'All';
    }
  },
};

// main part of legends
const LEGEND_STYLE = {
  icon: 'circle',
  itemGap: 16,
  left: '25%',
  width: '750px',
  selectedMode: false,
  inactiveColor: '#BCC5CF',
  inactiveBorderColor: '#BCC5CF',
  inactiveBorderWidth: 1,
  textStyle: {
    fontFamily: 'Open Sans',
    fontSize: '10px',
    fontWeight: '400',
    color: '#596A7C',
  },
  // bottom: "10px",
  bottom: LEGEND_PADDING_BOTTOM,
  formatter: name => {
    const oaIndex = name.indexOf(' OA');
    if (oaIndex > 0) {
      return name.substr(0, oaIndex);
    } else {
      return name;
    }
  },
};

const TOOLTIP_STYLE = {
  showContent: true,
  trigger: 'item',
  padding: [6, 8, 6, 8], // top, right, bottom, left
  position: function (point, params, dom, rect, size) {
    const boxWidth = size.contentSize[0];
    const boxHeight = size.contentSize[1];

    const x = rect.x + 0.5 * rect.width - 0.5 * boxWidth;
    const y = rect.y - boxHeight - 4; // 4px above the item

    return [x, y];
  },
  // alwaysShowContent: true,
  formatter: params => {
    // console.log(params)
    /**
     * modified start
     *
     * fix tooltip mismatch
     */
    // const stageName = params.dimensionNames[params.seriesIndex + 1]
    let dataStr = `<div style="padding: 0; margin: 0;">
    <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 0 0 2.5px 0;">${
      params.name
    }</p>
    <p style="font-size: 10px; font-weight: 400;color: #848484; line-height: 14px; margin: 0;">${
      params.seriesName
    }&nbsp;:&nbsp;${params.data[params.seriesName]}</p>
    </div>`;
    // let dataStr = `${params.name}`
    // let dataStr = `${params.seriesName}`
    // let dataStr = `${params.data[params.seriesName]}`
    return dataStr;
    /**
     * modified end
     *
     * fix tooltip mismatch
     */
  },
};

const TITLE = {
  textStyle: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 700,
    color: '#113D95',
    lineHeight: 17.5,
  },
  subtextStyle: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 400,
    color: '#113D95',
    lineHeight: 17.5,
  },
  left: 'center',
};

const GRID = {
  containLabel: true,
};

const PIE_COLORS = [
  '#71C8FF',
  '#4C81EB',
  '#9098E2',
  '#FFB152',
  '#42C1B3',
  '#FF8180',
];

export class OABarPieChartOptions {
  constructor() {
    this.hasPie = true;
    this.chartPaddingLeft = CHART_PADDING_LEFT;
    this.chartPaddingRight = CHART_PADDING_RIGHT;
    this.barchartTop = BARCHART_TOP;
    this.barchartLeft = BARCHART_LEFT;
    this.barchartRight = BARCHART_RIGHT;
    this.titleHeight = TITLE_HEIGHT;
    this.titleTopPadding = TITLE_TOP_PADDING;
    this.dataZoomInside = Object.assign({}, DATA_ZOOM_INSIDE);
    this.dataZoomSlider = Object.assign({}, DATA_ZOOM_SLIDER);
    this.zoomControlPaddingLeft = ZOOM_CONTROL_PADDING_LEFT;
    this.zoomControlRightOffset = ZOOM_CONTROL_RIGHT_OFFSET;
    this.pieChartPaddingLeft = PIE_CHART_PADDING_LEFT;
    this.pieRadius = PIECHART_RADIUS;
    this.pieColors = this.hasPie ? [...PIE_COLORS] : undefined;
    this.hasTitle = false;
    this.title = '';
    this.subTitle = '';
    this.hasZoom = false;
    this.selectedLegend = [];
    this.dataForBar = null;
    this.dataForPie = null;
    this.width = 0;
  }

  getOption() {
    return {
      grid: this.getGrid(),
      legend: this.getSelectedLegend(),
      xAxis: X_AXIS,
      yAxis: Y_AXIS,
      tooltip: TOOLTIP_STYLE,
      series: this.getSeries(),
      color: this.pieColors,
      title: this.getByTitle(),
      dataZoom: this.getZoom(),
      dataset: this.getDataset(),
    };
  }

  getHeightWithTitle() {
    let totalHeight =
      this.titleHeight +
      this.getBarchartHeight() +
      (this.hasTitle && this.titleHeight) +
      LEGEND_HEIGHT;

    // console.log("this.hasTitle, ", this.hasTitle)
    // console.log("totalHeight, ", totalHeight)
    return totalHeight;
  }

  getBarchartHeight() {
    return BARCHART_HEIGHT;
  }

  getGrid() {
    const grid = {
      ...GRID,
      left: this.chartPaddingLeft + (this.hasPie ? this.barchartLeft : 0),
      right:
        this.barchartRight +
        this.chartPaddingRight +
        (this.hasZoom && this.zoomControlPaddingLeft),
      top: !this.hasTitle
        ? this.barchartTop
        : this.barchartTop + this.titleHeight,
      height: this.getBarchartHeight(),
    };
    return grid;
  }

  getByTitle() {
    if (this.hasTitle) {
      return {
        ...TITLE,
        top: this.titleTopPadding,
        text: this.title,
        subtext: '(' + this.subTitle + ')',
      };
    }
    return undefined;
  }

  setHasPie(hasPie) {
    this.hasPie = hasPie;
    return this;
  }

  // set Title if hasTitle
  setTitle(hasTitle, text, subtext) {
    this.hasTitle = hasTitle;
    this.title = text;
    this.subTitle = subtext;
    return this;
  }

  getZoom() {
    if (this.hasZoom) {
      let slider = {
        ...this.dataZoomSlider,
        right: this.chartPaddingRight,
      };
      return [this.dataZoomInside, slider];
    }
    return undefined;
  }

  setZoom(hasZoom) {
    this.hasZoom = hasZoom;
    return this;
  }

  getLegendStyle() {
    return { ...LEGEND_STYLE };
  }

  getLegendLabelStyle() {
    return { ...LEGEND_LABEL_STYLE };
  }

  getSelectedLegend() {
    return [
      {
        ...this.getLegendStyle(),
        selected: this.selectedLegend,
      },
      this.getLegendLabelStyle(),
    ];
  }

  setSelectedLegend(selectedLegend) {
    this.selectedLegend = selectedLegend;
    return this;
  }

  getDataset() {
    if (this.hasPie) {
      return [
        {
          source: this.dataForBar,
        },
        {
          source: this.dataForPie,
        },
      ];
    } else {
      return [
        {
          source: this.dataForBar,
        },
      ];
    }
  }

  setDataSource(dataForBar, dataForPie = null) {
    this.dataForBar = dataForBar;
    if (this.hasPie) {
      this.dataForPie = dataForPie;
    }
    return this;
  }

  setBarSerieOption({
    datasetIndex = 0,
    attributeName,
    name,
    stackName,
    color,
    borderColor,
    textColor,
    borderRadius,
  }) {
    // console.log('borderRadius', borderRadius);
    // console.log('name || attributeName', name || attributeName);
    return {
      datasetIndex,
      name: name || attributeName,
      encode: { x: attributeName },
      stack: stackName,
      type: 'bar',
      label: {
        show: true,
      },
      barMinHeight: 3,
      barMaxWidth: 150,
      emphasis: {
        disabled: true,
      },
      color,
      itemStyle: {
        normal: {
          borderRadius,
          label: {
            show: true,
            position: 'inside',
            color: textColor,
            // fontSize: 7,
            fontSize: this.getLabelMinFontSize(),
            verticalAlign: 'left',
            lineHeight: 1,

            formatter: params => {
              // console.log('===params===', params);
              // console.log('===params===', params.data[name || attributeName]);
              const labelValue = params.data[name || attributeName];
              if (labelValue === 0) {
                return '';
              } else {
                return labelValue;
              }
            },
          },
          borderWidth: 1,
          borderColor: borderColor,
        },
      },
    };
  }

  // create series of pie and bar charts
  getSeries() {
    const barSeries = this.getBarSeries();

    if (this.hasPie) {
      const pieSerie = this.getPieSerie();
      return [...barSeries, pieSerie];
    } else {
      return barSeries;
    }
  }

  getPieSerie() {
    const centerX =
      this.pieRadius + this.chartPaddingLeft + this.pieChartPaddingLeft;

    const centerY =
      this.barchartTop +
      this.getBarchartHeight() / 2 +
      (this.hasTitle && this.titleHeight);

    return {
      type: 'pie',
      id: 'pie',
      radius: this.pieRadius,
      center: [centerX, centerY],
      label: {
        formatter: params => {
          return params.data[2];
        },
        fontSize: 10,
        fontWeight: 500,
        color: '#596A7C',
      },
      labelLine: {
        show: false,
        length: 5,
        length2: 5,
      },
      emphasis: {
        disabled: false,
        scaleSize: 5,
      },
      tooltip: {
        show: false,
      },
      datasetIndex: 1,
      encode: {
        itemName: 0,
        value: 2,
      },
    };
  }

  getBarSeries() {
    return [];
  }

  getLabelMinFontSize() {
    return 7;
  }

  getLabelFontSize() {
    return [this.getLabelMinFontSize(), MAX_LABEL_SIZE];
  }

  setWidth(width) {
    this.width = width;
    return this;
  }
}

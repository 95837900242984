import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage } from 'react-intl';

export function Note(props) {
  const { top } = props;
  return (
    <Box
      sx={{ marginTop: top, display: 'block' }}
      data-selenium-id='Report_Articles-Note-Box'
    >
      <Box
        data-selenium-id='Report_Articles-Note-Box-Box'
        sx={{
          marginRight: '6px',
          display: 'inline',
          fontFamily: 'Open Sans',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '24px',
          color: '#828282',
        }}
      >
        Note:
      </Box>
      <Typography
        data-selenium-id='Report_Articles-Note-Typography'
        variant='caption'
        color='#828282'
        fontSize={16}
        lineHeight='24px'
        fontFamily='Open Sans'
      >
        <FormattedMessage
          id='report.tipTwo'
          data-selenium-id='Report_Articles-Note-TipTwo'
        />
      </Typography>
    </Box>
  );
}

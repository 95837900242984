import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fySetSubjectShowAll } from "../../../actions/SIMT-Report/Report/ReportAction";
import React from "react";
import { FormattedMessage } from "react-intl";
import Typography from "@mui/material/Typography";

/**
 * yueying
 * 紫色的switch
 */
export const StyledSwitchPurple = styled(Switch)(({ _theme }) => ({
  width: 50,
  height: 25,
  padding: 5,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(4px)',
    '&.Mui-checked': {
      transform: 'translateX(23px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#CCD4FF',
      },
      '& + .MuiSwitch-track': {
        backgroundColor: '#d6ddff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be',
    borderRadius: 20 / 2,
  },
}));

export const StyledSwitchGreen = styled(Switch)(({ _theme }) => ({
  width: 50,
  height: 25,
  padding: 5,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(4px)',
    '&.Mui-checked': {
      transform: 'translateX(23px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#96DCA9',
      },
      '& + .MuiSwitch-track': {
        backgroundColor: 'rgb(217, 238, 222)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: 'rgb(217, 238, 222)',
    borderRadius: 20 / 2,
  },
}));

export const StyledSwitchTwoColorSwitch = styled(Switch)(({ }) => ({
  width: 50,
  height: 25,
  padding: 5,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(23px)',
    '&.Mui-checked': {
      transform: 'translateX(4px)',
      '& .MuiSwitch-thumb': {

        backgroundColor: '#CCD4FF',
      },
      '& + .MuiSwitch-track': {

        backgroundColor: '#d6ddff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 22,
    height: 22,
    backgroundColor: '#96DCA9',
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    borderRadius: 20 / 2,
    backgroundColor: 'rgb(217, 238, 222)',
  },
}));

export const  StyledSwitchTwoColor = props => {
  const {id, checked, onChange} = props;
  const handleChange = (event) => {
    // const modifiedEvent = {
    //   ...event,
    //   target: {
    //     ...event.target,
    //     checked: !event.target.checked
    //   }
    // };
    // onChange(modifiedEvent);
    onChange(event);
  };
  return <>
    <Typography variant="caption" sx={{
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '125%',
      color: checked? '#262E35':'#98A7B6',
    }}>
      <FormattedMessage id='report.all'  />
    </Typography>
    <StyledSwitchTwoColorSwitch id={id} checked={checked} onChange={handleChange}/>
    <Typography variant="caption" sx={{
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '125%',
      color: !checked? '#262E35':'#98A7B6',
    }}>
      <FormattedMessage id='report.oa'  />
    </Typography>
  </>;
};



// @ts-check
import React from 'react';
import {
  Box,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  IconButton,
} from '@mui/material';
import { MyTableHeader } from '../../../../components/DataTable';
import {
  StyledTableCell,
  TextInput,
  CommonInput,
  EmailInput,
} from '../../SIPDetailPage/Submodule/WriteTable';
// @ts-ignore
import SIPAuthorDelete from '@/assets/SIPAuthorDelete.svg';
import {
  PoliticalAuthorsTableAffiliationText,
  PoliticalAuthorsTableEmailText,
  PoliticalAuthorsTableNameText,
  PoliticalAuthorsTableTopicText,
} from './CustomTypography';

/**
 *
 * @param {import("./CustomTable").PotentialAuthorsTableProps} props
 * @returns
 */
export const PotentialAuthorsTable = props => {
  const { tableRowList, mode, emptyTip = true,isSSIP=false } = props;

  /** @type {import("./CustomTable").HandlePotentialAuthorsTableChangeFunction} */
  let handleTableRowItemChange = () => {};
  /** @type {((rowIndex: number) => void)} */
  let handleDeleteRow = () => {};

  if (mode === 'edit') {
    handleTableRowItemChange = props.handleTableRowItemChange;
    handleDeleteRow = props.handleDeleteRow;
  }

  /**
   * @typedef {import("../../SIPDetailPage/Submodule/WriteTable").HeaderId} HeaderId
   */

  /**
   * @type {import("./CustomTable").CreateePotentialAuthorsHeaderItemFunction}
   */
  const createHeaderItem = (
    id,
    label,
    minWidth = 'none',
    maxWidth = 'none',
    isOrder = false,
    align = 'left'
  ) => {
    return { id, label, minWidth, maxWidth, isOrder, align };
  };

  /**
   * @type {import("./CustomTable").CreatePotentialAuthorsTableRowItemFunction}
   */
  const createEditRowItem = (
    id,
    authorName,
    affiliation,
    email,
    articleTopic,
    rowIndex
  ) => {
    return {
      id: id,
      name: (
        <CommonInput
          data-selenium-id='SIP_RevisePage_CustomComponents-Table-CreateEditRowItem-NameInput'
          value={authorName}
          onChange={e =>
            handleTableRowItemChange(rowIndex, 'name', e.target.value)
          }
        />
      ),
      affiliation: (
        <TextInput
          data-selenium-id='SIP_RevisePage_CustomComponents-Table-CreateEditRowItem-AffiliationInput'
          value={affiliation}
          onChange={e =>
            handleTableRowItemChange(rowIndex, 'affiliation', e.target.value)
          }
        />
      ),
      email: (
        <EmailInput
          data-selenium-id='SIP_RevisePage_CustomComponents-Table-CreateEditRowItem-EmailInput'
          value={email}
          onChange={e =>
            handleTableRowItemChange(rowIndex, 'email', e.target.value)
          }
        />
      ),
      topic: (
        <TextInput
          data-selenium-id='SIP_RevisePage_CustomComponents-Table-CreateEditRowItem-TopicInput'
          value={articleTopic}
          onChange={e =>
            handleTableRowItemChange(rowIndex, 'topic', e.target.value)
          }
        />
      ),
      actions: (
        <IconButton
          onClick={() => handleDeleteRow(rowIndex)}
          data-selenium-id='SIP_RevisePage_CustomComponents-Table-CreateEditRowItem-ActionsIconButton'
        >
          <SIPAuthorDelete data-selenium-id='SIP_RevisePage_CustomComponents-Table-CreateEditRowItem-AuthorDelete' />
        </IconButton>
      ),
    };
  };

  /**
   * @type {import("./CustomTable").CreatePotentialAuthorsTableRowItemFunction}
   */
  const createShowRowItem = (
    id,
    authorName,
    affiliation,
    email,
    articleTopic,
    rowIndex
  ) => {
    return {
      id: id,
      name: (
        <PoliticalAuthorsTableNameText data-selenium-id='SIP_RevisePage_CustomComponents-Table-CreateShowRowItem-NameText'>
          {authorName}
        </PoliticalAuthorsTableNameText>
      ),
      affiliation: (
        <PoliticalAuthorsTableAffiliationText data-selenium-id='SIP_RevisePage_CustomComponents-Table-CreateShowRowItem-AffiliationText'>
          {affiliation}
        </PoliticalAuthorsTableAffiliationText>
      ),
      email: (
        <PoliticalAuthorsTableEmailText data-selenium-id='SIP_RevisePage_CustomComponents-Table-CreateShowRowItem-EmailText'>
          {email}
        </PoliticalAuthorsTableEmailText>
      ),
      topic: (
        <PoliticalAuthorsTableTopicText data-selenium-id='SIP_RevisePage_CustomComponents-Table-CreateShowRowItem-TopicText'>
          {articleTopic}
        </PoliticalAuthorsTableTopicText>
      ),
      actions: '',
    };
  };

  /**
   * @type {import("./CustomTable").CreateTableCellStyle}
   */
  const createTableCellWidthStyle = (minWidth, maxWidth) => ({
    minWidth: minWidth,
    maxWidth: maxWidth,
  });

  let tableHeads = [
    createHeaderItem('name', 'AUTHOR NAME'),
    createHeaderItem('affiliation', 'AFFILIATION'),
    createHeaderItem('email', 'EMAIL'),
    createHeaderItem('topic', 'ARTICLE TOPIC'),
  ];

  mode === 'edit' &&
    (tableHeads = [...tableHeads, createHeaderItem('actions', 'ACTIONS')]);

  /**
   * 配置单元格宽度
   * @type {import("./CustomTable").HeaderIdToTableCellStyle<HeaderId>}
   */
  const headerIdTableCellWidthStyle = {
    name: createTableCellWidthStyle('196px', '287px'),
    affiliation: createTableCellWidthStyle('196px', '287px'),
    email: createTableCellWidthStyle('196px', '287px'),
    topic: createTableCellWidthStyle('196px', '287px'),
    actions: createTableCellWidthStyle('94px', 'none'),
  };

  // 生成形如 ".MuiTableCell-root.TableCell-affiliation" 的 CSS 选择器，定义单元格宽度
  /** @type {(keyof import("./CustomTable").HeaderIdToTableCellStyle<HeaderId>)[]} */
  const headerIdTableCellWidthStyleKeys = Object.keys(
    headerIdTableCellWidthStyle
  );
  const tableCellStyleHook = headerIdTableCellWidthStyleKeys.reduce(
    (acc, key) => {
      const keyValue = `& .MuiTableCell-root.TableCell-${key}`;
      return {
        ...acc,
        [keyValue]: headerIdTableCellWidthStyle[key],
      };
    },
    /** @type {import("@mui/material").SxProps} */ ({})
  );

  const rowList = tableRowList.map((rowItem, index) => {
    return mode === 'edit'
      ? createEditRowItem(
          rowItem.id,
          rowItem.name || '',
          rowItem.affiliation || '',
          rowItem.email || '',
          rowItem.topic || '',
          index
        )
      : createShowRowItem(
          rowItem.id,
          rowItem.name || '',
          rowItem.affiliation || '',
          rowItem.email || '',
          rowItem.topic || '',
          index
        );
  });

  /**
   * @type {import("react").CSSProperties}
   */
  const emptyStyle = {
    height: '20px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.01em',
    color: '#BCC5CF',
    flex: 'none',
  };

  return (
    <Box
      sx={{ width: '100%' }}
      data-selenium-id='SIP_RevisePage_CustomComponents-Table-EmptyStyle-Box'
    >
      <TableContainer
        // id={id}
        // data-selenium-id={`SIP_RevisePage_CustomComponents-Table-EmptyStyle-${id}`}
        sx={{
          borderRadius: '8px 8px 8px 8px',
          borderWidth: '1px 1px 0px 1px',
          borderStyle: 'solid',
          borderColor: '#DFE4E8',
          boxShadow: 'none',
        }}
        component={Paper}
      >
        <Table
          data-selenium-id='SIP_RevisePage_CustomComponents-Table-EmptyStyle-Table'
          stickyHeader
          aria-label='potential authors table'
          sx={{
            minWidth: 500,
            letterSpacing: '0.01em',
            color: '#262E35',
            '& .MuiTableCell-root': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              '& .MuiFormControl-root': {
                // border: "1px solid #DFE4E8",
                borderRadius: '4px',
                padding: 0,
                '& .MuiInputBase-root': {
                  padding: '2px 12px',
                  '&.Mui-focused': {
                    '& fieldset': {
                      borderColor: '#1e48b4',
                      borderWidth: '2px',
                    },
                  },
                  '& fieldset': {
                    border: '1px solid #DFE4E8',
                  },
                  '& input': {
                    padding: 0,
                    lineHeight: '20px',
                  },
                },
              },
            },
            ...tableCellStyleHook,
          }}
        >
          <MyTableHeader
            data-selenium-id='SIP_RevisePage_CustomComponents-Table-EmptyStyle-Header'
            items={tableHeads}
            backgroundColor='#F1F3F5'
            color='#596A7C'
          />
          <TableBody data-selenium-id='SIP_RevisePage_CustomComponents-Table-EmptyStyle-Body'>
            {rowList.map((row, index) => (
              <TableRow
                key={row.id !== -1 ? row.id : -index}
                data-selenium-id='SIP_RevisePage_CustomComponents-Table-EmptyStyle-Row'
              >
                <StyledTableCell
                  className='TableCell-name'
                  data-selenium-id='SIP_RevisePage_CustomComponents-Table-EmptyStyle-NameCell'
                >
                  {row.name}
                </StyledTableCell>
                <StyledTableCell
                  className='TableCell-affiliation'
                  data-selenium-id='SIP_RevisePage_CustomComponents-Table-EmptyStyle-AffiliationCell'
                >
                  {row.affiliation}
                </StyledTableCell>
                <StyledTableCell
                  className='TableCell-email'
                  data-selenium-id='SIP_RevisePage_CustomComponents-Table-EmptyStyle-EmailCell'
                >
                  {row.email}
                </StyledTableCell>
                <StyledTableCell
                  className='TableCell-topic'
                  data-selenium-id='SIP_RevisePage_CustomComponents-Table-EmptyStyle-TopicCell'
                >
                  {row.topic}
                </StyledTableCell>
                {mode === 'edit' && (
                  <StyledTableCell
                    className='TableCell-actions'
                    data-selenium-id='SIP_RevisePage_CustomComponents-Table-EmptyStyle-ActionsCell'
                  >
                    {row.actions}
                  </StyledTableCell>
                )}
              </TableRow>
            ))}
            {rowList.length <= 0 && emptyTip && (
              <TableRow
                id='potentialAuthorNoDataText'
                data-selenium-id='SIP_RevisePage_CustomComponents-Table-EmptyStyle-PotentialAuthorNoDataText'
                style={{ height: '44px' }}
              >
                <StyledTableCell
                  data-selenium-id='SIP_RevisePage_CustomComponents-Table-EmptyStyle-Cell'
                  colSpan={tableHeads.length}
                  sx={{
                    textAlign: 'center',
                    fontSize: '20px',
                    '&.MuiTableCell-root': emptyStyle,
                  }}
                >
                  {isSSIP?'Add Potential Authors Information':'No authors were recommended'}
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

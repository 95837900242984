import {
  TableCell,
  TableRow,
  tableCellClasses,
  TextField,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SubmitIcon from '../../../../assets/submitIcon2.svg';
import Calender from '../../../../assets/Calender.svg';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { CommonDialog } from '@/modules/AlertDialog';
import { unstable_batchedUpdates } from 'react-dom';
import {
  resetNewSubmission,

} from '../../../../actions/SIMT-Report/ReportJobsSubmission/ReportJobsAction';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitErrorAlert, SubmitSuccessAlert } from './AlertDialog';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import {AlertBox, UntitledAlertBox} from '@/components/Alert/AlertBox';
import {submitNewSubmission} from "@/actions/SIMT-Report/ReportJobsSubmission";
function ReportTableRow(props) {
  const {
    index,
    reportName,
    submittedBy = '-',
    submittedTime = '-',
    showName,
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [selectMonth, setSelectMonth] = useState(new Date());
  const [submissionConfirmOpen, setSubmissionConfirmOpen] = useState(false);
  const [cannotSubmit, setCannotSubmit] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const handleSuccessClose=(flag)=>{setSuccessOpen(!flag);};
  const handleErrorClose=(flag)=>{setErrorOpen(!flag);};

  const selector = state => {
    return {
      submitResult: state.ReportJobs.submitResult,
    };
  };
  const { submitResult } = useSelector(selector);

  useEffect(() => {
    if (selectMonth === null || selectMonth > new Date()) {
      setCannotSubmit(true);
    } else {
      let year = selectMonth.getFullYear();
      let month = selectMonth.getMonth();
      if (year !== year || month !== month) {
        setCannotSubmit(true);
      } else {
        setCannotSubmit(false);
      }
    }
  }, [selectMonth]);

  useEffect(() => {
    if (submitResult.flag === index) {
      if (submitResult.result === 'OK') {
        setSuccessOpen(true);
      }
      if (submitResult.result === 'ERROR') {
        setErrorOpen(true);
      }
      dispatch(resetNewSubmission());
    }
  }, [submitResult]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      ...tableRowCss,
    },
  }));

  const tableRowCss = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#262E35',
    alignItems: 'center',
    letterSpacing: '0.01em',
    padding: '6px 24px',
  };

  const handleSubmitClick = () => {
    async function submit() {
      unstable_batchedUpdates(() => {
        dispatch(submitNewSubmission(showName, selectMonth, index));
      });
    }
    submit();
    setSubmissionConfirmOpen(false);
  };
  return (
    <TableRow
      key={index}
      data-selenium-id='SystemAdmin_ReportJobs-NewSubmission-ReportTableRow-TableRow'
    >
      <StyledTableCell
        id={`submission-tablerow-reportname-${index}`}
        align='left'
        sx={{ ...tableRowCss }}
      >
        <FormattedMessage id={`reportJobs.${reportName}`} />
      </StyledTableCell>

      <StyledTableCell
        id={`submission-tablerow-selectedmonth-${index}`}
        align='left'
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            views={['year', 'month']}
            openTo='month'
            inputFormat='yyyy-MM'
            maxDate={new Date()}
            value={selectMonth}
            onChange={newDate => {
              setSelectMonth(newDate);
            }}
            id='catjournalAddOptdateSelect'
            data-selenium-id='CatjournalAddOptdateSelect'
            mask='____-__'
            sx={{
              height: '33px',
              lineHeight: '120%',
            }}
            components={{
              OpenPickerIcon: Calender,
            }}
            renderInput={params => (
              <TextField
                id={`systemAdminReportJobsSubmissionTablerowSelectedmonthSelect${index}`}
                data-selenium-id={`SystemAdmin-ReportJobsSubmissionTablerowSelectedmonthSelect-${index}`}
                onKeyDown={e => {
                  e.preventDefault();
                }}
                {...params}
                sx={{
                  lineHeight: '120%',
                  '& .MuiInputBase-input': {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    width: '183px',
                    height: '33px',
                  },
                }}
                placeholder='yyyy-mm'
              />
            )}
          />
        </LocalizationProvider>
      </StyledTableCell>
      <StyledTableCell
        id={`submission-tablerow-submittedby-${index}`}
        align='left'
        sx={{ ...tableRowCss, padding: '6px 24px' }}
      >
        {submittedBy}
      </StyledTableCell>
      <StyledTableCell
        id={`submission-tablerow-submittedtime-${index}`}
        align='left'
        sx={{ ...tableRowCss }}
      >
        {submittedTime}
      </StyledTableCell>
      <StyledTableCell id={`submission-tablerow-actions-${index}`} align='left'>
        <IconButton
          data-selenium-id='SystemAdmin_ReportJobs-NewSubmission-ReportTableRow-TableRow-StyledTableCell1-IconButton'
          onClick={() => {
            setSubmissionConfirmOpen(true);
          }}
          sx={{
            alignItems: 'flex-start',
            padding: 0,
            justifyContent: 'flex-start',
          }}
        >
          <SubmitIcon
            data-selenium-id='SystemAdmin_ReportJobs-NewSubmission-ReportTableRow-TableRow-StyledTableCell1-IconButton-SubmitIcon'
            sx={{
              padding: 0,
            }}
          />
        </IconButton>
      </StyledTableCell>

      <CommonDialog
        title={`Submit Request`}
        id={`submit-request-confirm-${index}`}
        content='Are you sure to submit the report job ?'
        handleClose={() => {
          setSubmissionConfirmOpen(false);
        }}
        handleConfirm={handleSubmitClick}
        alertOpen={submissionConfirmOpen}
      />
      <UntitledAlertBox
        id={`submit-success-alert-${index}`}
        open={successOpen}

        handleClose={handleSuccessClose}
        severity='success'
        message={'The report job request has been submitted.'}
        // message={<FormattedMessage id='reportJobs.waitingApproved' />}
      />
      {/* <SubmitSuccessAlert
        id={`submit-success-alert-${index}`}
        open={successOpen}
        setOpen={setSuccessOpen}
        tipText='The report job request has been submitted.'
      /> */}
      <UntitledAlertBox
        id={`submit-error-alert-${index}`}
        open={errorOpen}

        handleClose={handleErrorClose}
        severity='error'
        message={`There is unapproved request for the Job of ${
          intl.messages[`reportJobs.${reportName}`]
        } in the list of waiting for approval, please have a check and ask the other System Admin to Approve or Reject.`}
      />
      {/* <SubmitErrorAlert
        id={`submit-error-alert-${index}`}
        open={errorOpen}
        setOpen={setErrorOpen}
        tipText={`There is unapproved request for the Job of ${
          intl.messages[`reportJobs.${reportName}`]
        } in the list of waiting for approval, please have a check and ask the other System Admin to Approve or Reject.`}
      /> */}
    </TableRow>
  );
}

export default ReportTableRow;

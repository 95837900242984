/*
 * @Author: Huang Tao
 * @Date:2022/1/19
 * @Description:errorAlert For NewAdmin props
 */

import './index.css';
import ErrorOutline from '@/assets/error_outline.svg';
import { Box, Icon, Stack } from '@mui/material';

function ErrorAlert() {
  return (
    <Stack
      data-selenium-id='SuperAdminManage_errorAlert-Stack'
      className='CAerror_alert'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      p={1}
      spacing={1}
    >
      <Icon
        id='error_outline'
        data-selenium-id='SuperAdminManage_errorAlert-Icon'
      >
        <ErrorOutline data-selenium-id='SuperAdminManage_errorAlert-error_outline' />
      </Icon>
      <div
        id='CAerr_msg'
        data-selenium-id='SuperAdminManage_errorAlert-CAerr_msg'
      >
        An account already exists with this email address
      </div>
    </Stack>
  );
}

export default ErrorAlert;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateSIDisabledBlock from '../../../CommonComponents/CreateSIDisabledBlock';
import CreateSISelectBlock from '../../../CommonComponents/CreateSISelectBlock';
import {
  CreateAndUpdateTitle,
  CreateAndUpdateLittleTitle,
} from '../../../CommonComponents/CreateAndUpdateTitle';
import CreateSIInputBlock from '../../../CommonComponents/CreateSIInputBlock';
import CreateAndUpdateAutoComplete from '../../../CommonComponents/CreateAndUpdateAutoComplete';
import { HandlingCEAutocomplete } from '../../../CommonComponents/FuntionalComponents/HandlingCEAutocomplete';
import { Box, Stack } from '@mui/material';
import { scrollToTop } from '../../../../../../utils/commonUtils';
import { useLocation } from 'react-router-dom';
import { useUpdateEffect } from 'ahooks';
import {getUserInfo} from "@/actions/SIMT-User/User";
import {loadPaperConfig, loadSITypes} from "@/actions/SIMT-User/Config";
import {getCE} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueSystemAdmin";
import {getJournalListWithCodeOptIn, getJournalListWithNameOptIn} from "@/actions/SIMT-SI/SpecialIssue/Journal";
import {getSepcialIssueSource, getSIRevenueModel, getWorkFlowList} from "@/actions/SIMT-SI/SpecialIssue/Config";

function GeneralInformationForm(props) {
  const {
    MandatoryList,
    clickedButton,
    GeneralInformation,
    setGeneralInformation,
    setJump,
    clickTime,
    setHasError = e => {},
  } = props;
  const [handleCe, setHandleCe] = useState();
  const [CEValue, setCEValue] = useState(
    GeneralInformation.HandlingCeSelectValue
  );
  const [CeId, setCeId] = useState(GeneralInformation.HandlingCE);
  const [group, setGroup] = useState(GeneralInformation.GroupOrTitle); //CE的group/title
  const [loading, setLoadeing] = useState(true);
  const dispatch = useDispatch();
  const [JournalData, setJournalData1] = useState([]);
  const [SIType, setSIType] = useState(GeneralInformation.SIPublicationFormat);
  const [longTitle, setLongTitle] = useState(false); //标题过长报错
  const [SITitle, setSITitle] = useState(GeneralInformation.SpecialIssueTitle);
  const [reTitle, setReTitle] = useState(false); //标题的重复检查
  const [SISource, setSISource] = useState(
    GeneralInformation.SpecialIssueSource
  );
  const [paperCommition, setPaperCommition] = useState(
    GeneralInformation.PaperCommissionMethod
  );
  const [workflow, setWorkflow] = useState(GeneralInformation.WorkFlow);
  const [siRevenueModel, setSIRevenueModel] = useState(
    GeneralInformation.siRevenueModel
  );

  const location = useLocation(); // 使用useLocation跳过来的值
  const { state } = location;
  // console.log("currentSTage:", location)
  let SIData2 = {
    specialIssueTitle: '', //创建完成的title的提示框
  };
  const [CEData, setCEData1] = useState([
    {
      Id: 1,
      username: '',
      email: '',
    },
  ]);

  const [SITypeData, setSITypeData1] = useState([]);

  const [PaperData, setPaperData1] = useState([]);

  const [getrevenueModel, setGetRevenueModel] = useState(
    // GeneralInformation.revenueModel
    'OA'
  );

  const [journalSelect, setJournalSelect] = useState(null);
  const [journalNameSelect, setJournalNameSelect] = useState('');
  const [journalCodeSelect, setJournalCodeSelect] = useState('');

  const [journaleName, setJournalName] = useState('');
  const [journaleCode, setJournalCode] = useState('');

  const [currentJournalPageC, setCurrentJournalPageC] = useState(1); //当前选中journlCode
  const [currentJournalPageN, setCurrentJournalPageN] = useState(1); //当前选中journalName

  // 判断HandlingCE是不是第一次加载，当CE还没有加载到值的时候，不进行空值报错
  const [isCEFirstLoad, setIsCEFirstLoad] = useState(true);

  const selector = state => {
    return {
      CEResult: state.SI.CE, //CE下拉框数据
      JourListResult: state.SI.JourList, //journal下拉框数据
      UserFirstNameInfo: state.User.firstName, //登录的用户信息
      UserIDInfo: state.User.userId,
      UserLastNameInfo: state.User.lastName,
      UserEmailInfo: state.User.email,
      UserGroupInfo: state.User.group,
      moreJourListResult: state.SI.moreJourList, //用于加载更多journal （可能还会用，暂时没用）
      checkTitleResult: state.SI.checkTitle, //title不可重复
      SITypeList: state.SystemConfig.SITypeList,
      PapaerComfigList: state.SystemConfig.PapaerComfigList,
      workFlowList: state.SI.workFlowList,
      siRevenueModelList: state.SI.siRevenueModelList,
      siSourceList: state.SI.siSourceList,
    };
  };

  const {
    CEResult,
    JourListResult,
    UserFirstNameInfo,
    UserIDInfo,
    UserLastNameInfo,
    UserEmailInfo,
    UserGroupInfo,
    moreJourListResult,
    checkTitleResult,
    SITypeList,
    PapaerComfigList,
    workFlowList,
    siRevenueModelList,
    siSourceList,
  } = useSelector(selector);

  useEffect(() => {
    dispatch(getJournalListWithNameOptIn('', 1));
    dispatch(loadSITypes()); // 获取SI Publication Format下拉列表的后台数据
    dispatch(loadPaperConfig());
    dispatch(getWorkFlowList());
    dispatch(getSIRevenueModel());
    dispatch(getSepcialIssueSource());
    scrollToTop();
  }, []);

  useEffect(() => {
    if (UserFirstNameInfo !== '' && CEValue === '') {
      setCEValue(
        UserLastNameInfo + ', ' + UserFirstNameInfo + ' (' + UserEmailInfo + ')'
      );

      setGeneralInformation({
        ...GeneralInformation,
        HandlingCeSelectValue:
          UserLastNameInfo +
          ', ' +
          UserFirstNameInfo +
          '(' +
          UserEmailInfo +
          ')',
      });
      setIsCEFirstLoad(false); //此时CE已经获取到初始值，不再是第一次加载的状态了
    }
    if (group === '') {
      setGroup(UserGroupInfo);
      setCeId(UserIDInfo);
    }
  }, [UserFirstNameInfo]);

  const MandatoryJudge = (value, list) => {
    return list.indexOf(value) > -1;
  };

  const handleCeSelect = value => {
    setCEValue(value);
    //选中CE时的变化
    let tmpId = -1;
    let tmpName = '';
    if (value) {
      let tmpEmail = value.split('(')[1];
      tmpEmail = tmpEmail.split(')')[0];
      tmpName = value.split('(')[0];
      for (const element of CEData) {
        if (element.email == tmpEmail) {
          tmpId = element.userId;
          setGroup(element.groupName);
          break;
        }
      }
      if (tmpId > 0) {
        setCeId(tmpId);
      }
    }

    setGeneralInformation({
      ...GeneralInformation,
      HandlingCE: tmpId === -1 ? UserIDInfo : tmpId,
      HandlingCeSelectValue: value,
      HandlingCEReviewValue: tmpName,
    });
  };

  useEffect(() => {
    // console.log("GroupOrTitle的值改变了: " + group)
    // 将修改之后的HandlingCE Name赋值给HandlingCEReviewValue
    let tmpName = CEValue?.split('(')[0] ?? '';
    setGeneralInformation({
      ...GeneralInformation,
      GroupOrTitle: group,
      HandlingCE: CeId === -1 ? UserIDInfo : CeId,
      HandlingCeSelectValue: CEValue,
      HandlingCEReviewValue: tmpName,
    });
  }, [group]);

  const handleSITypeChange = (event, newValue) => {
    setSIType(newValue);
    setGeneralInformation({
      ...GeneralInformation,
      SIPublicationFormat: event.target.value,
    });
  };

  const handleSITitleChange = value => {
    var regu = '^[ ]+$';
    if (new RegExp(regu).test(value)) {
      value = '';
    }
    if (value.length > 400) {
      setLongTitle(true);
    } else {
      setLongTitle(false);
      setSITitle(value);
      SIData2.specialIssueTitle = value;
      setGeneralInformation({
        ...GeneralInformation,
        SpecialIssueTitle: value,
      });
    }
  };

  const handleSISourceChange = (event, newValue) => {
    setSISource(newValue);
    setGeneralInformation({
      ...GeneralInformation,
      SpecialIssueSource: event.target.value,
    });
  };

  const handlePaperCommitionChange = (event, newValue) => {
    setPaperCommition(newValue);
    setGeneralInformation({
      ...GeneralInformation,
      PaperCommissionMethod: event.target.value,
    });
  };

  const handleWorkflowChange = (event, newValue) => {
    setWorkflow(newValue);
    setGeneralInformation({
      ...GeneralInformation,
      WorkFlow: event.target.value,
    });
  };

  const getCEFromSearch = async value => {
    if (value !== undefined) {
      await dispatch(getCE(value));
    }
  };

  const getJournalFromName = async value => {
    await dispatch(getJournalListWithNameOptIn(value, 1));
    setCurrentJournalPageN(1);
  };

  const getJournalFromCode = async value => {
    await dispatch(getJournalListWithCodeOptIn(value, 1));
    setCurrentJournalPageC(1);
  };

  const getMoreJournalInfoFromName = async () => {
    if (currentJournalPageC > 1) {
      await getJournalFromName(journaleName);
      setCurrentJournalPageC(1);
    }
    await dispatch(
      getJournalListWithNameOptIn(journaleName, currentJournalPageN + 1)
    );
    setCurrentJournalPageN(currentJournalPageN + 1);
  };

  const getMoreJournalInfoFromCode = async () => {
    if (currentJournalPageN > 1) {
      await getJournalFromCode(journaleCode);
      setCurrentJournalPageN(1);
    }
    await dispatch(
      getJournalListWithCodeOptIn(journaleCode, currentJournalPageC + 1)
    );
    setCurrentJournalPageC(currentJournalPageC + 1);
  };

  const handleJounalSearchWithName = value => {
    if (value !== null) {
      setGetRevenueModel(value.revenueModel);
      setJournalSelect(value);
      setJournalNameSelect(value.journalName);
      setJournalCodeSelect(value.journalCode);
      setJournalName(value.journalName);
      setSIRevenueModel(value.revenueModel);
    } else {
      setGetRevenueModel(null);
      setJournalSelect(null);
      setJournalNameSelect(null);
      setJournalCodeSelect(null);
      setSIRevenueModel(null);
      setGeneralInformation({
        ...GeneralInformation,
        JournalCode: null,
        JournalName: null,
        siRevenueModel: null,
        revenueModel: null,
      });
    }
  };

  const handleJounalSearchWithCode = value => {
    if (value !== null) {
      setGetRevenueModel(value.revenueModel);
      setJournalSelect(value);
      setJournalNameSelect(value.journalName);
      setJournalCodeSelect(value.journalCode);
      setSIRevenueModel(value.revenueModel);
    } else {
      setGetRevenueModel(null);
      setJournalSelect(null);
      setJournalNameSelect(null);
      setJournalCodeSelect(null);
      setSIRevenueModel(null);
      setGeneralInformation({
        ...GeneralInformation,
        JournalCode: null,
        JournalName: null,
        siRevenueModel: null,
        revenueModel: null,
      });
    }
  };

  const handleJournalNameChange = event => {
    if (event !== null && event._reactName !== 'onClick') {
      setJournalName(event.target.value);
    }
  };

  const handleJournalCodeChange = event => {
    if (event !== null && event._reactName !== 'onClick') {
      setJournalCode(event.target.value);
    }
  };

  function sleep(delay = 0) {
    return new Promise(resolve => {
      setTimeout(resolve, delay);
    });
  }

  //获取SI Publication Format的下拉列表中的数据
  useEffect(() => {
    setSITypeData1(SITypeList);
  }, [SITypeList]);

  // PaerComissioningMethod的下拉列表
  useEffect(() => {
    setPaperData1(PapaerComfigList);
  }, [PapaerComfigList]);

  //check title
  useEffect(() => {
    let result = checkTitleResult;
    // console.log(result)
    if (result == 'false') {
      setReTitle(true);
    } else {
      setReTitle(false);
    }
  }, [checkTitleResult]);

  function loadCEData(active) {
    return async dispatch => {
      setLoadeing(false);
      await sleep(1e3); // For demo purposes.

      if (active) {
        dispatch(getCEFromSearch(handleCe));
      }
      setLoadeing(true);
    };
  }

  //延迟获取CE值
  useEffect(() => {
    let active = true;

    if (UserFirstNameInfo === '') {
      dispatch(getUserInfo());
    }

    loadCEData(active);

    return () => {
      active = false;
    };
  }, [handleCe]);

  useEffect(() => {
    let result = CEResult;
    if (result != null && result !== undefined && result !== 'error') {
      setCEData1(result);
    }
  }, [CEResult]);

  useEffect(() => {
    let result = JourListResult;

    if (result != null && result !== undefined && result !== 'error') {
      setJournalData1(result);
    }
  }, [JourListResult]);

  useEffect(() => {
    let result = moreJourListResult;
    if (result.length != 0) {
      setJournalData1(JournalData.concat(result));
    }
  }, [moreJourListResult]);

  //延迟获取JournalName值
  useEffect(() => {
    let active = true;
    (async () => {
      await sleep(3e2); // For demo purposes.
      if (active) {
        if (!journaleName) getJournalFromName('');
        else getJournalFromName(journaleName);
      }
    })();

    return () => {
      active = false;
    };
  }, [journaleName]);

  //延迟获取JournalCode值
  useEffect(() => {
    let active = true;
    (async () => {
      await sleep(3e2); // For demo purposes.

      if (active) {
        if (!journaleCode) getJournalFromCode('');
        else getJournalFromCode(journaleCode);
      }
    })();

    return () => {
      active = false;
    };
  }, [journaleCode]);

  //判断一个值是否为空或者空串
  const isEmptyValue = value => {
    return (
      value === null ||
      value === '' ||
      value === undefined ||
      value.length === 0
    );
  };

  //判断所有必填项是否已填
  const isEmpty = () => {
    return (
      (MandatoryJudge('Handling CE', MandatoryList) && isEmptyValue(CEValue)) ||
      (MandatoryJudge('Journal Code', MandatoryList) &&
        isEmptyValue(GeneralInformation.JournalCode)) ||
      (MandatoryJudge('Journal Name', MandatoryList) &&
        isEmptyValue(GeneralInformation.JournalName)) ||
      (MandatoryJudge('SI Publication Format', MandatoryList) &&
        isEmptyValue(GeneralInformation.SIPublicationFormat)) ||
      (MandatoryJudge('SI Source', MandatoryList) && isEmptyValue(SISource)) ||
      (MandatoryJudge('Special Issue Title', MandatoryList) &&
        isEmptyValue(SITitle)) ||
      (MandatoryJudge('Paper Commissioning Method', MandatoryList) &&
        isEmptyValue(paperCommition)) ||
      (MandatoryJudge('Workflow', MandatoryList) && isEmptyValue(workflow))
    );
  };

  const pageHasError = isEmpty();
  useEffect(() => {
    if (!clickedButton) {
      return;
    }
    setHasError(pageHasError);
  }, [pageHasError, clickedButton]);

  //如果有必填项未填则不能跳转
  useEffect(() => {
    setJump(!isEmpty());
  });

  const [WorkFlowData, setWorkFlowList] = useState([]);

  useEffect(() => {
    setWorkFlowList(
      workFlowList != []
        ? workFlowList.map(item => {
            return Object.assign({}, { Id: item.id, Name: item.value });
          })
        : []
    );
  }, [workFlowList]);

  const [SIRevenueModelList, setSIRevenueModelList] = useState([]);

  useEffect(() => {
    setSIRevenueModelList(
      siRevenueModelList != []
        ? siRevenueModelList.map(item => {
            return Object.assign({}, { Id: item.id, Name: item.value });
          })
        : []
    );
  }, [siRevenueModelList]);

  const [SISourceData, setSISourceList] = useState([]);

  useEffect(() => {
    setSISourceList(
      siSourceList != []
        ? siSourceList.map(item => {
            return Object.assign({}, { Id: item.id, Name: item.value });
          })
        : []
    );
  }, [siSourceList]);

  const recoredError = () => {
    if (MandatoryJudge('Handling CE', MandatoryList) && isEmptyValue(CEValue)) {
      return 'handlingCEAutocompleteHandlingCE';
    } else if (
      MandatoryJudge('Journal Code', MandatoryList) &&
      isEmptyValue(GeneralInformation.JournalCode)
    ) {
      return 'createAndUpdateAutoCompleteJournalCode';
    } else if (
      MandatoryJudge('Journal Name', MandatoryList) &&
      (isEmptyValue(GeneralInformation.JournalName) ||
        journalNameSelect === 'Temporary')
    ) {
      return 'createAndUpdateAutoCompleteJournalName';
    } else if (
      MandatoryJudge('SI Publication Format', MandatoryList) &&
      isEmptyValue(GeneralInformation.SIPublicationFormat)
    ) {
      return 'createSISelectBlockSIPublicationFormat';
    } else if (
      MandatoryJudge('Special Issue Title', MandatoryList) &&
      isEmptyValue(SITitle)
    ) {
      return 'createSIInputBlockSpecialIssueTitle';
    } else if (
      MandatoryJudge('SI Source', MandatoryList) &&
      isEmptyValue(SISource)
    ) {
      return 'createSIInputBlockSpecialIssueSource';
    } else if (
      MandatoryJudge('Paper Commissioning Method', MandatoryList) &&
      isEmptyValue(GeneralInformation.PaperCommissionMethod)
    ) {
      return 'createSIInputBlockPaperCommissionMethod';
    } else if (
      MandatoryJudge('Workflow', MandatoryList) &&
      isEmptyValue(workflow)
    ) {
      return 'createSIInputBlockWorkflow';
    }
  };

  // useUpdateEffect 初次进入页面不会渲染 ， 出错的话再进行定位，先定位到顶部，在定位到屏幕中间
  useUpdateEffect(() => {
    if (isEmpty()) {
      let currentDOM = document.getElementById('' + recoredError());
      if (currentDOM) {
        setTimeout(() => {
          window.scrollTo({ left: 0, top: 0, behavior: 'instant' });
          window.scroll({
            left: 0,
            top:
              currentDOM.getBoundingClientRect().top -
              window.screen.availHeight / 2 +
              100,
            behavior: 'instant',
          });
        }, 200);
      }
    }
  }, [clickTime]);

  return (
    <Box
      data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage-GeneralInfomationForm-Box'
      sx={{ width: '100%' }}
    >
      <CreateAndUpdateTitle
        title='Create New Special Issue'
        id='createSIGeneralInfotitle'
        data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage-GeneralInfomationForm-GeneralInfotitle'
      ></CreateAndUpdateTitle>
      <CreateAndUpdateLittleTitle
        littleTitle='General Information'
        id='createSIGeneralInfolittletitle'
        data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage-GeneralInfomationForm-GeneralInfolittletitle'
      ></CreateAndUpdateLittleTitle>
      <Stack spacing={2} direction='column'>
        {/* Stage */}
        <CreateSIDisabledBlock
          title='Stage'
          defaultValue={state}
          id='createSIProductionStageDisabledBlock'
          data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage-GeneralInfomationForm-ProductionStageDisabledBlock'
        ></CreateSIDisabledBlock>

        {/* Handling CE */}
        <HandlingCEAutocomplete
          id='handlingCEAutocompleteHandlingCE'
          required={MandatoryJudge('Handling CE', MandatoryList)}
          value={CEValue}
          placeholder='Select Handling CE'
          onChange={(event, newValue) => {
            handleCeSelect(newValue);
          }}
          emptyErr={clickedButton && !isCEFirstLoad && isEmptyValue(CEValue)}
        ></HandlingCEAutocomplete>

        {/* Journal Code */}
        <CreateAndUpdateAutoComplete
          id='createAndUpdateAutoCompleteJournalCode'
          data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage-GeneralInfomationForm-createSIProductionJournalCodeSelect'
          title='Journal Code'
          required={MandatoryJudge('Journal Code', MandatoryList)}
          defaultValue='Journal Code'
          value={
            GeneralInformation.JournalCode ? GeneralInformation : journalSelect
          }
          placeholder='Select Journal Code'
          options={
            JournalData.length == 0
              ? JournalData.concat({
                  journalName: 'Temporary',
                  journalCode: 'Temporary',
                })
              : JournalData
          }
          getOptionLabel={option =>
            option.JournalCode || option.journalCode || ''
          }
          onChange={(event, newValue) => {
            //触发在onInputChange之后触发
            handleJounalSearchWithCode(newValue);
            if (newValue !== null) {
              setGeneralInformation({
                ...GeneralInformation,
                JournalCode: newValue.journalCode,
                JournalName: newValue.journalName,
                revenueModel: newValue.revenueModel,
              });
            } else {
              setGeneralInformation({
                ...GeneralInformation,
                JournalCode: null,
                JournalName: null,
                revenueModel: null,
              });
            }
          }}
          onInputChange={handleJournalCodeChange} //输入框改变时候 触发在前
          getOptionDisabled={option => option.journalCode === journalCodeSelect}
          renderOption={(props, option) => {
            return !option.showMore ? (
              <li {...props}>{option.journalCode}</li>
            ) : (
              <li {...props} onClick={getMoreJournalInfoFromCode}>
                {option.journalCode}
              </li>
            );
          }}
          empty={isEmptyValue(journalSelect?.journalCode)}
          isErr={
            (clickedButton && isEmptyValue(journalSelect?.journalCode)
              && isEmptyValue(GeneralInformation?.JournalCode)) ||
            journalCodeSelect === 'Temporary'
          }
          errorText={
            journalCodeSelect === 'Temporary'
              ? 'Please contact your JPM to add the journal information into Product Data Management system (STIBO) as soon as possible!'
              : 'This field is required.'
          }
        ></CreateAndUpdateAutoComplete>

        {/* Journal Name */}
        <CreateAndUpdateAutoComplete
          id='createAndUpdateAutoCompleteJournalName'
          data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage-GeneralInfomationForm-createSIProductionJournalNameSelect'
          title='Journal Name'
          required={MandatoryJudge('Journal Name', MandatoryList)}
          value={
            GeneralInformation.JournalName ? GeneralInformation : journalSelect
          }
          defaultValue={null}
          placeholder='Select Journal Name'
          options={
            JournalData.length == 0
              ? JournalData.concat({
                  journalName: 'Temporary',
                  journalCode: 'Temporary',
                })
              : JournalData
          }
          getOptionLabel={option =>
            option.JournalName || option.journalName || ''
          }
          onChange={(event, newValue) => {
            handleJounalSearchWithName(newValue);
            if (newValue !== null) {
              setGeneralInformation({
                ...GeneralInformation,
                JournalCode: newValue.journalCode,
                JournalName: newValue.journalName,
                revenueModel: newValue.revenueModel,
              });
            } else {
              setGeneralInformation({
                ...GeneralInformation,
                JournalCode: null,
                JournalName: null,
                revenueModel: null,
              });
            }
          }}
          onInputChange={handleJournalNameChange}
          getOptionDisabled={option => option.journalName === journalNameSelect}
          renderOption={(props, option) => {
            return !option.showMore ? (
              <li {...props}>{option.journalName}</li>
            ) : (
              <li {...props} onClick={getMoreJournalInfoFromName}>
                {option.journalName}
              </li>
            );
          }}
          empty={isEmptyValue(journalSelect?.journalName)}
          isErr={
            (clickedButton && isEmptyValue(journalSelect?.journalName)
              && isEmptyValue(GeneralInformation?.JournalName)) ||
            journalCodeSelect === 'Temporary'
          }
          errorText={
            journalCodeSelect === 'Temporary'
              ? 'Please contact your JPM to add the journal information into Product Data Management system (STIBO) as soon as possible!'
              : 'This field is required.'
          }
        ></CreateAndUpdateAutoComplete>

        {/* SI Publication Format */}
        <CreateSISelectBlock
          title='SI Publication Format'
          required={MandatoryJudge('SI Publication Format', MandatoryList)}
          placeholder='Select SI Publication Format'
          id='createSISelectBlockSIPublicationFormat'
          data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage-GeneralInfomationForm-createSIProductionTypeSelect'
          list={SITypeData}
          value={SIType}
          attribute='value'
          onChange={handleSITypeChange}
          emptyErr={
            clickedButton &&
            isEmptyValue(GeneralInformation.SIPublicationFormat)
          }
        />

        {/* "SI Revenue Model" */}
        {/* {getrevenueModel === 'OA' ? null : (
          <CreateSISelectBlock
            id='createSIRevenueModel'
            data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage-GeneralInfomationForm-createSIRevenueModel'
            title='SI Revenue Model'
            required={false}
            list={SIRevenueModelList} // TODO
            placeholder='Select SI Revenue Model'
            value={siRevenueModel}
            attribute='Name'
            onChange={(event, newValue) => {
              setSIRevenueModel(newValue);
              setGeneralInformation({
                ...GeneralInformation,
                siRevenueModel: event.target.value,
              });
            }}
          />
        )} */}
        {/* SI title */}
        <CreateSIInputBlock
          title='Special Issue Title'
          required={MandatoryJudge('Special Issue Title', MandatoryList)}
          id='createSIInputBlockSpecialIssueTitle'
          data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage-GeneralInfomationForm-createSIProductiontitleinput'
          placeholder='Type SI Title'
          limitLength={401}
          defaultValue={GeneralInformation.SpecialIssueTitle}
          setValue={handleSITitleChange}
          value={SITitle}
          emptyErr={clickedButton && isEmptyValue(SITitle)}
          HandleChange={value => {
            setGeneralInformation({
              ...GeneralInformation,
              SpecialIssueTitle: value,
            });
          }}
        ></CreateSIInputBlock>

        {/* SI Source */}
        <CreateSISelectBlock
          title='Special Issue Source'
          required={MandatoryJudge('SI Source', MandatoryList)}
          placeholder='Select SI Source'
          emptyErr={clickedButton && isEmptyValue(SISource)}
          id='createSIInputBlockSpecialIssueSource'
          data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage-GeneralInfomationForm-SIProductionSourceSelect'
          value={SISource}
          list={SISourceData}
          attribute='Name'
          onChange={handleSISourceChange}
        />

        {/* Paper Commissioning Method */}
        <CreateSISelectBlock
          title='Paper Commissioning Method'
          required={MandatoryJudge('Paper Commissioning Method', MandatoryList)}
          placeholder='Select Paper Commissioning Method'
          emptyErr={
            clickedButton &&
            isEmptyValue(GeneralInformation.PaperCommissionMethod)
          }
          id='createSIInputBlockPaperCommissionMethod'
          data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage-GeneralInfomationForm-createSIProductionPaperCommissionMethodSelect'
          value={paperCommition}
          list={PaperData}
          attribute='value'
          onChange={handlePaperCommitionChange}
        />

        {/* Workflow */}
        <CreateSISelectBlock
          title='Workflow'
          required={MandatoryJudge('Workflow', MandatoryList)}
          placeholder='Select Workflow'
          emptyErr={clickedButton && isEmptyValue(workflow)}
          id='createSIInputBlockWorkflow'
          data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage-GeneralInfomationForm-createSIProductionWorkflowSelect'
          list={WorkFlowData}
          value={workflow}
          attribute='Name'
          onChange={handleWorkflowChange}
          mergeIntoDocumentFlow={true}
        />
      </Stack>
    </Box>
  );
}

export default GeneralInformationForm;

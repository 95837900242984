import { useState } from 'react';
import { SelectorAccordion } from './Accordion/index.js';
import { SelectorMenus } from '../SelectorMenus';

export const GroupedSelect = ({
  label,
  options = [], // groupedCEFullList
  selectedItems = [],
  setSelectedItems,
  disabled,
}) => {
  const [open, setOpen] = useState(false);

  // get all options
  let flattenedOptions = [];
  options.forEach(group => {
    // console.log(group.options);
    flattenedOptions = [...flattenedOptions, ...group.options];
  });

  // check if all options are selected
  const isAllSelected = flattenedOptions?.length === selectedItems?.length;

  // debug
  // console.group('===GroupedSelect===');
  // console.log('===options===', options);
  // console.log('===flattenedOptions===', flattenedOptions);
  // console.log('===selectedItems===', selectedItems);
  // console.log('===isAllSelected===', isAllSelected);
  // console.groupEnd();

  return (
    <div>
      <SelectorMenus
        label={label}
        disabled={disabled}
        highlight={!isAllSelected}
        style={{
          // padding: '12px',
          '& > .MuiPaper-root': { padding: '0 12px' },
        }}
        open={open}
        setOpen={setOpen}
      >
        {options.map(group => {
          const { groupName, options } = group;
          return (
            <SelectorAccordion
              key={groupName}
              groupName={groupName}
              options={options}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          );
        })}
      </SelectorMenus>
    </div>
  );
};

import styles from "@/pages/SIPForm/index.module.scss";
import Header from "@/pages/SIPForm/components/Header";
import Footer from "@/pages/SIPForm/components/Footer";
import {Box} from "@mui/material";
import React from "react";
import {ThankYou} from "@/pages/SIPForm/subpages/ThankYou";

function ThankYouPage() {
  return (
      <Box className={styles['wire-frame']}>
          <Header />
          <ThankYou />
          <Footer />
      </Box>
  );
}

export default ThankYouPage;
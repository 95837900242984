import {
  CardActions,
  Divider,
  IconButton,
  Card,
  CircularProgress,
  Stack,
} from '@mui/material';
import { useEffect, useState, useRef, useImperativeHandle, } from 'react';
import { Box } from '@mui/system';
import BasicInforBlock from './BasicInforBlock';
import AssociatedSIBlock from './AssociatedSIBlock';
import FlagBlock from './FlagBlock';
import FlagBlockAdd from './FlagBlockAdd';
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import EditSIDetailnewIcon from '@/assets/EditSIDetailnewIcon.svg';
import EditSIDetailnewHover from '@/assets/EditSIDetailnewHover.svg';
import AddSIDetailIcon from '@/assets/AddSIDetailIcon.svg';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import EditingHistoryBlock from './EditingHistoryBlock';
import { useDispatch } from 'react-redux';
// import MarketChevronUp from '@/assets/GEDetailArrowUp.svg';
// import SIDetailArrowDown from '@/assets/GEDetailArroeDown.svg';

import { forwardRef } from 'react';
import { Flag } from '@mui/icons-material';
import {get_GEBasicHistory} from "@/actions/SIMT-SI/GuestEditor";
import {get_FlagHistory} from "@/actions/SIMT-SI/GuestEditor/GEFlag";
import {PERMISSIONS} from "@/constant/permission";

const ShowBlockBox = forwardRef((props, ref) => {
  const { title, geId, GEBasicInformation, FlagData } = props;
  var stickWidth = 0;
  title === 'Basic Information'
    ? (stickWidth = '218px')
    : (stickWidth = '276px');

  // const [isFold, setIsFold] = useState(title === 'Basic Information');
  const [isFold, setIsFold] = useState(true);
  const [editType, setEditType] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isflagEdit, setIsflagEdit] = useState(false);
  const [saveClick, setSaveClick] = useState(false);
  const childRef = useRef();
  const [remarkErr, setRemarkErr] = useState(false);
  const [error, setError] = useState(props.outSaveDisabled);
  const [SIDetailIsSaving, setSIDetailIsSaving] = useState(false);
  const [firstTimeClick, setFirstTimeClick] = useState(true);
  const [flagEditState, setFlagEditState] = useState([]);
  const [editAdd, setEditAdd] = useState(false);
  useEffect(() => {
    if (typeof FlagData !== 'undefined') {
      let flagStates = [];
      for (let i = 0; i < FlagData.length; i++) {
        flagStates.push(false);
      }
      setFlagEditState(flagStates);
    }
  }, [FlagData]);
  useEffect(() => {}, [title]);
  useEffect(() => {
    if (title === 'Basic Information') {
      if (props.setIfEdit !== undefined) {
        props.setIfEdit(editType);
      }
    }
  }, [editType]);

  useEffect(() => {
    if (props.setSaveDisabled) {
      props.setSaveDisabled(error);
    }
  }, [error]);
  useEffect(()=>{
    async function fetchData() {
      await dispatch(get_GEBasicHistory(geId, 1, 20));
      await dispatch(get_FlagHistory(geId, 1, 20));
    }
    if(geId) fetchData();
  },[geId]);
  const handleEditClick = () => {
    setEditType(true);
  };

  const handleCancelClick = () => {
    setEditType(false);
    childRef.current.handleCancel();
  };

  const handleSaveClick = () => {
    childRef.current.handleSubmit();
  };

  const handleAddClick = () => {
    setIsAdd(true);
    setIsflagEdit(false);
    setEditType(false);
  };
  const handleAddClose = () => {
    setIsAdd(false);
  };

  useImperativeHandle(ref, () => ({
    handleCancel: () => {
      handleCancelClick();
    },
    handleSave: () => {
      handleSaveClick();
    },
  }));


  const EditButton = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [isPressed, setIsPressed] = useState(false);
    //编辑按钮
    return (
      <Box
        sx={{
          height: '32px',
          transition: 'all 0.5s',
          opacity: isFold ? '1' : '0',
          visibility:
            isFold && title === 'Basic Information' ? 'visible' : 'hidden',
        }}
        data-selenium-id='GEPage_GEDetailPage-ShowBlockBox-EditButton'
      >
        {!editType ? (
          <ShowForPermission permission={`${PERMISSIONS.GE_WRITE}`}>
            <IconButton
              id='siDetailPageGISEditButton'
              onClick={handleEditClick}
              disableRipple
              sx={{
                boxShadow: 'none',
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onMouseDown={() => setIsPressed(true)}
              onMouseUp={() => setIsPressed(false)}
            >
              {isHovered || isPressed ? (
                <EditSIDetailnewHover sx={{ width: '16px', height: '16px' }} />
              ) : (
                <EditSIDetailnewIcon sx={{ width: '16px', height: '16px' }} />
              )}
            </IconButton>
          </ShowForPermission>
        ) : (
          <></>
        )}
      </Box>
    );
  };
  const FlagButton = () => {
    const [isHovered, setIsHovered] = useState(false);
    return (
      <Box
        sx={{
          height: '32px',
          transition: 'all 0.5s',
          opacity: isFold ? '1' : '0',
          visibility: isFold && title === 'Flag' ? 'visible' : 'hidden',
        }}
        data-selenium-id='GEPage_GEDetailPage-ShowBlockBox-AditButton'
      >
        {FlagData.length === 0 || editAdd === true ? (
          <ShowForPermission permission={`${PERMISSIONS.GE_WRITE}`}>
            <IconButton
              id='siDetailPageGISFlagButton'
              onClick={handleAddClick}
              disableRipple
              sx={{
                boxShadow: 'none',
                paddingTop: '4px',
                paddingBottom: '10px',
                paddingRight: '5px',
              }}
            >
              <AddSIDetailIcon sx={{ width: '16px', height: '16px' }} />
            </IconButton>
          </ShowForPermission>
        ) : (
          <ShowForPermission permission={`${PERMISSIONS.GE_WRITE}`}>
            <IconButton
              id='siDetailPageGISFlagButton'
              onClick={() => {
                setIsflagEdit(true);
                setEditAdd(true);
              }}
              disableRipple
              sx={{
                boxShadow: 'none',
                paddingTop: '9px',
                paddingBottom: '10px',
                paddingRight: '5px',
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {isHovered ? (
                <EditSIDetailnewHover
                  sx={{ width: '16px', height: '16px', marginTop: '8px' }}
                />
              ) : (
                <EditSIDetailnewIcon sx={{ width: '16px', height: '16px' }} />
              )}
            </IconButton>
          </ShowForPermission>
        )}
      </Box>
    );
  };
  const renderFlagBlock = () => {
    // console.log("renderFlagBlock()->flagEditState:",flagEditState);
    const childFlagBlock = [];
    if (FlagData.length > 0) {
      childFlagBlock.push(
        <FlagBlock
          geId={geId}
          GEBasicInformation={GEBasicInformation}
          FlagData={FlagData}
          isEdit={isflagEdit}
          setIsEdit={setIsflagEdit}
          setEditType={setEditType}
          setAdd={setEditAdd}
          // flagNum={i}
          data-selenium-id='GEPage_GEDetailPage-ShowBlockBox-FlagBlock'
        ></FlagBlock>
      );
    }
    if (isAdd)
      childFlagBlock.push(
        <FlagBlockAdd
          geId={geId}
          FlagData={FlagData}
          isEdit={editType}
          setAdd={setEditAdd}
          flagNum={0}
          closeAdd={handleAddClose}
          setIsEdit={setIsflagEdit}
          setEditAdd={setEditAdd}
          data-selenium-id='GEPage_GEDetailPage-ShowBlockBox-FlagBlockAdd'
        ></FlagBlockAdd>
      );
    return childFlagBlock;
  };
  const dispatch = useDispatch();
  return (
    <Box mt={4} data-selenium-id='GEPage_GEDetailPage-ShowBlockBox'>
      <Card variant='' data-selenium-id='GEPage_GEDetailPage-ShowBlockBox-Card'>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          data-selenium-id='GEPage_GEDetailPage-ShowBlockBox-Card-Stack'
          sx={{ paddingBottom: !isFold ? '32px' : '0px',cursor:'pointer' }}
        >
          <Box
            id='InfoFoldButton'
            data-selenium-id='GEPage_GEDetailPage-ShowBlockBox-Card-Stack-InfoFoldButton'
            class='titleFoldButton'
            onClick={() => {
              setIsFold(!isFold);
              async function fetchData() {
                await dispatch(get_GEBasicHistory(geId, 1, 20));
                await dispatch(get_FlagHistory(geId, 1, 20));
              }
              fetchData();
            }}
          >
            {isFold ? <MarketChevronUp /> : <SIDetailArrowDown />}
          </Box>
          <Box
            id='titleBoxLeft'
            data-selenium-id='GEPage_GEDetailPage-ShowBlockBox-Card-Stack-titleBoxLeft'
            sx={{cursor:'default'}}
          >
            {title}
          </Box>
          <CardActions sx={{ padding: 0 }}>
            {title === 'Basic Information' ? <EditButton /> : <></>}
            {title === 'Flag' ? <FlagButton /> : <></>}
          </CardActions>
        </Stack>
        {!isFold ? (
          <Box></Box>
        ) : (
          <Box sx={{ overflowX: 'auto', }}>
            {title === 'Basic Information' ? (
              <BasicInforBlock
                geId={geId}
                GEBasicInformation={GEBasicInformation}
                isEdit={editType}
                setIsEdit={setEditType}
                error={error}
                setError={setError}
                saveClick={saveClick}
                ref={childRef}
                data-selenium-id='GEPage_GEDetailPage-ShowBlockBox-BasicInfoBlock'
              />
            ) : (
              <></>
            )}
            {title === 'Flag' ? <>{renderFlagBlock()}</> : <></>}
            {title === 'Associated Special Issues' ? (
              <AssociatedSIBlock
                geId={geId}
                GEBasicInformation={GEBasicInformation}
                isEdit={editType}
                setIsEdit={setEditType}
                error={error}
                setError={setError}
                saveClick={saveClick}
                ref={childRef}
                data-selenium-id='GEPage_GEDetailPage-ShowBlockBox-BasicInfoBlock'
              />
            ) : (
              <></>
            )}
            {title === 'Editing History' ?
               <EditingHistoryBlock
               geCode={geId}
               isFold={isFold}
               data-selenium-id='GEPage_GEDetailPage-ShowBlockBox-EditingHistoryBlock'
               geFullName={GEBasicInformation?.fullName}
             />
            :<></>}
          </Box>
        )}
      </Card>
    </Box>
  );
});

export default ShowBlockBox;

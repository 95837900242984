import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import DialogIcon from '@/assets/DialogCancelIcon.svg';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, buttonClasses } from '@mui/material';
import { Box } from '@mui/system';

export default function AttachmentDialog(props) {
  const { open, setOpen, setIsSendClicked } = props;

  const [sending, setSending] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSend = () => {
    setSending(true);
    setIsSendClicked(true);
    setOpen(false);
  };
  const SendDialogTitleStyle = {
    width: '100%',
    height: '32px',
    display: 'flex',
    fontFamily: 'Open Sans',
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '125%',
    color: '#262E35',
  };
  const SendDialogContentStyle = {
    width: '100%',
    height: '20px',
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '28px',
    color: '#262E35',
    padding: '17px 0px 33px 0px',
  };
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
      padding: '32px 35px 0px',
      borderRadius: '0px',
      margin: '0px',
      width: '530px',
      height: '158px',
    },
  }));

  const SendDialogButton = styled(Button)(() => ({
    [`&.${buttonClasses.root}`]: {
      boxShadow: 'none',
      borderRadius: '5px',
      fontWeight: 600,
      padding: '8px 18px',
      textTransform: 'none',
      fontSize: '14px',
      marginLeft: '10px',
      color: '#F13F5',
      height: '36px',
    },
  }));

  return (
    <div>
      <BootstrapDialog
        maxWidth={false}
        open={open}
        onClose={handleClose}
        data-selenium-id='Email_Send-CommonComponents-CustomDialog-BootstrapDialog'
      >
        <Box
          style={SendDialogTitleStyle}
          id='customized-dialog-title'
          data-selenium-id='Email_Send-CommonComponents-CustomDialog-TitleBox'
          onClose={handleClose}
        >
          Warning
          <IconButton
            id='sendCancel'
            data-selenium-id='Email_Send-CommonComponents-CustomDialog-SendCancelIconButton'
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 35,
              top: 32,
              padding: '0px',
            }}
          >
            <DialogIcon />
          </IconButton>
        </Box>
        <Box
          style={SendDialogContentStyle}
          id='SendDialogContent'
          data-selenium-id='Email_Send-CommonComponents-CustomDialog-SendDialogContentBox'
        >
          <DialogContentText
            sx={{
              width: '100%',
              height: '20px',
              fontFamily: 'Open Sans',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '20px',
              color: '#262E35',
            }}
          >
            Do you want to send this message without an attachment file?
          </DialogContentText>
        </Box>
        <DialogActions sx={{ padding: '0px' }}>
          <SendDialogButton
            id="Don't send"
            data-selenium-id="Email_Send-CommonComponents-CustomDialog-Don'tSendDialogButton"
            onClick={handleClose}
            variant='text'
          >
            Don't send
          </SendDialogButton>
          <SendDialogButton
            // loading={sending.toString()}
            // loadingIndicator="Sending…"
            id='sendAnyway'
            data-selenium-id='Email_Send-CommonComponents-CustomDialog-SendAnywayDialogButton'
            variant='contained'
            onClick={handleSend}
            // disabled={sending}
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              color: '#F13F5',
              background: '#154AB6',
              marginLeft: '10px',
            }}
          >
            Send anyway
          </SendDialogButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

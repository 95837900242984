import SnackbarInfoIcon from '@/assets/SnackbarInfoIcon.svg';
import { useFormContext, userFormState } from "@/components/Form/form.d";
import AlertActions from "@/pages/SIPPage/SIPCheckListPage/compontents/Snackbar/AlertActions";
import AlertBody from "@/pages/SIPPage/SIPCheckListPage/compontents/Snackbar/AlertBody";
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import React from 'react';
import { useLocation } from "react-router-dom";
import SnackbarSuccessIcon from '../assets/SnackbarSuccessIcon.svg';
import SnackbarWarningIcon from '../assets/SnackbarWarningIcon.svg';
import { getMessageInfo } from "./actions";
import { SNACKBAR_ID, SNACKBAR_SHOW } from "./constants";
import {useNavigate} from "react-router";

export const ThemeContext = React.createContext();

export default function SIPChecklistSnackbar(props) {
    const form = useFormContext();

    // const dispatch = form.dispatch;
    const [SnackbarShow, setSnackbarShow] = userFormState(form, SNACKBAR_SHOW);
    const SnackbarId = form.get(SNACKBAR_ID);
    const SnackbarMessage = form.get('_snackbarMessage');

    const sipCode = form.get('sipCode');

    const navigate = useNavigate();
    const messageInfo = getMessageInfo(SnackbarId, () => {
        navigate('/simt/auth/sipRelated/sip/detail/'+sipCode);
    }, SnackbarMessage?.title, SnackbarMessage?.message) || {};
    const { type, title, message, actions, needRefresh = false } = messageInfo;

    function handleClose(event, reason) { //https://mui.com/material-ui/react-snackbar
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarShow(false);
        if(needRefresh) location.reload();
    }

    function getTheme(bgcolor, borderColor, titleColor, actionColor) {
        return {
            bgcolor: bgcolor,
            borderColor: borderColor,
            titleColor: titleColor,
            actionColor: actionColor,
        };
    }

    function setTheme() {
        switch (type) {
            case 'warning':
                return getTheme('#FFFBEB', '#FFCA7B', '#D97706', '#D97706');
            case 'error':
                return {};
            case 'info':
                return getTheme('#E8EDFB', '#4C81EB', '#154AB6', '#154AB6');
            case 'success':
                return getTheme('#ECFDF5', '#42C1B3', '#0D6A61', null);
            default:
                return {};
        }
    }
    const theme = setTheme();

    return (
        <ThemeContext.Provider value={theme}>
            <Snackbar
                open={SnackbarShow}
                autoHideDuration={needRefresh?null:3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{
                    top: '76px !important',
                    right:'36px !important'
                }}
            >
                <Alert
                    onClose={handleClose}
                    severity={type}
                    action=''
                    sx={{
                        width: '546px',
                        bgcolor: theme.bgcolor,
                        p: '16px 32px 16px 24px',
                        border: `1px solid ${theme.borderColor}`,
                        boxShadow: '0px 12px 24px rgba(38,46,53,0.12)',
                        borderRadius: '5px',
                        boxSizing: 'border-box',
                        '& .MuiAlert-icon': {
                            marginRight: '12px',
                            p: 0,
                        },
                        '& .MuiAlert-message': {
                            p: 0,
                            overflow:'hidden'
                        },
                        '& .MuiAlertTitle-root': {
                            height: '17px',
                            fontFamily: 'Open Sans',
                            fontSize: '14px',
                            fontWeight: '700',
                            lineHeight: '17px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            margin:'0px',
                            color: theme.titleColor,
                        },
                    }}
                    iconMapping={{
                        warning: <SnackbarWarningIcon />,
                        success: <SnackbarSuccessIcon />,
                        info:<SnackbarInfoIcon/>,
                    }}
                >
                    <AlertTitle>{title}</AlertTitle>
                    <AlertBody>{message}</AlertBody>
                    <AlertActions actions={actions} />
                </Alert>
            </Snackbar>
        </ThemeContext.Provider>
    );
}

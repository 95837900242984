// @ts-check
import * as React from 'react';
import { Box, ButtonBase, IconButton } from '@mui/material';
// @ts-ignore
import NotificationItemDelete from '../../assets/NotificationItemDelete.svg';
import { MiniFont, SmallBoldFont, SmallFont } from '../FontStyle';
import { formatDateString } from '../../utils/commonUtils';
import { useState } from 'react';
import RedAlertCircleSmallIcon from '@/assets/RedAlertCircleSmallIcon.svg';
/** @typedef {import("react").CSSProperties} CSSProperties*/

/** @type {CSSProperties} */
const CssSubject = {
  width: 'calc(100% - 20px)',
  height: '20px',
  ...SmallBoldFont,
  letterSpacing: ' 0.01em',
  color: '#000000',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

/** @type {CSSProperties} */
const readAlertSubjectCss = {
  width: 'calc(100% - 20px)',
  height: '20px',
  ...SmallFont,
  letterSpacing: ' 0.01em',
  color: '#596A7C',
  cursor: 'pointer',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

/** @type {CSSProperties} */
const CssContent = {
  width: 'calc(100% - 20px)',
  minHeight: '20px',
  height: 'fit-content',
  ...MiniFont,
  color: 'rgba(0, 0, 0, 0.8)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  marginBottom: '12px',
};

/** @type {CSSProperties} */
const readAlertContentCss = {
  width: 'calc(100% - 20px)',
  height: 'fit-content',
  minHeight: '20px',
  ...MiniFont,
  letterSpacing: ' 0.01em',
  color: '#596A7C',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  marginBottom: '12px',
};

/** @type {CSSProperties} */
const CssDate = {
  height: '18px',
  ...MiniFont,
  display: 'flex',
  alignItems: ' center',
  color: '#98A7B6',
};

/**
 *
 * @param {import("./NotificationItem").NotificationItemProps} props
 * @returns
 */
export default function NotificationItem(props) {
  const [deleting, setDeleting] = useState(false);
  const {
    id = 'test', //用于自动化测试
    active = false, //布尔值 true/false true:背景色为深蓝色,false:背景色为白色
    state = 'Unread', // Unread, Read
    subject = '', //顾名思义
    handleDelete = () => {}, //用于处理 X 的点击事件
    handleSubjectClick = () => {}, // 用于处理subject 的点击事件
    DaysOfDifference = 0, // 数字, 表示天数差,比如0 对标的就是today 1就是1 day ago
    sx, // 最外层 Box 的样式
    children,
    ...other //添加给Item最外层的那个box，比如说你觉得默认给这个box设置width或者padding等等不合适，你可以自己设定
  } = props;
  /** @type {import("@mui/material").SxProps} */
  const CssContainer = {
    cursor: 'pointer',
    position: 'relative',
    opacity: deleting ? 0 : 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    // height: '98px',
    minHeight: '98px',
    height: 'fit-content',
    transition: 'opacity 1s, background 0.5s, box-shadow 0.3s, transform 0.3s',
    overflow: 'hidden',
    background: active
      ? 'rgba(206, 221, 249, 0.5)'
      : '#FFFFFF',
      // : state === 'Unread'
      //   ? '#FFFFFF'
      //   : '#F1F3F5',
    borderBottom: '1px solid #BCC5CF',
    borderBottomWidth: '1px',
    zIndex: 0,
    '&:hover': {
      // boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.20)',
      backgroundColor: '#E8EDFB',
      // transform: "scale(1.03)",
      zIndex: 1,
    },
    paddingLeft: '12px',
  };

  return (
    <Box id={id} sx={{ ...CssContainer, ...sx }} {...other}>
      <ButtonBase
        sx={{
          width: '100%',
          paddingRight: '8px',
          paddingLeft: '18px',
          paddingY: '10px',
          height: '100%',
          display: 'block',
          position: 'relative',
          textAlign: 'start',
        }}
        onClick={handleSubjectClick}
      >
        <Box
          sx={{
            marginBottom: '8px',
            display: 'flex',
            justifyContent: 'flex-start',
            height: '20px',
            position: 'relative',
          }}
        >
          {state!=='Read'?(<RedAlertCircleSmallIcon style={{
            position: 'absolute',
            top: 'calc(50% - 7px)',
            left: '-20px',
          }}/>):null}

          <Box
            id={`${id}_subject`}
            sx={{
              ...(state === 'Read' ? readAlertSubjectCss : CssSubject),
              fontWeight: 600,
              color: '#000',
            }}
          >
            {subject}
          </Box>
        </Box>
        <Box sx={state === 'Read' ? readAlertContentCss : CssContent}>
          {children}
        </Box>
        <Box sx={CssDate}>{formatDateString(DaysOfDifference)}</Box>
      </ButtonBase>
      <IconButton
        sx={{
          padding: '0px',
          width: '20px',
          height: '20px',
          position: 'absolute',
          top: '12px',
          right: '12px',
        }}
        id={`${id}_itemDelete`}
        // disableRipple
        onClick={() => {
          setDeleting(true);
          handleDelete();
        }}
      >
        <NotificationItemDelete />
      </IconButton>
    </Box>
  );
}

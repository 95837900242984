import React, {JSXElementConstructor, ReactElement, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
    ChecklistStatus,
    FormAction, FormComponent,
    FormComponentProps,
    FormComponentType,
    FormProps,
    FormState,
    MapState,
    RelativePathFormProps,
    RootFormProps, SubFormGroupProps,
    SubFormProps
} from "@/components/Form/form.d";
import {string} from "prop-types";
import {deepMerge, deepMergeIf} from "@/reducers/SIPChecklistReducer";
import {CHECKLIST_EDITED} from "@/pages/SIPPage/SIPCheckListPage/configable/constants";

export function pureData(data: MapState): MapState{
    const result: MapState = {};
    for (const key in data){
        if (data.hasOwnProperty(key) && key[0] !== '_'){
            let datum = data[key];
            if (datum !== null && datum !== undefined){
                if(datum instanceof Array){
                    result[key] = datum.map((item) => {
                        if (typeof item === 'object'){
                            return pureData(item);
                        } else {
                            return item;
                        }
                    });
                } else if (typeof datum === 'object'){
                    result[key] = pureData(datum);
                } else {
                    result[key] = datum;
                }
            }
        }
    }
    return result;
}

interface ComputeMethods {
    expression: string;
    // for the bindings, it should start with 'v:', such as 'v:prop_name'
    [key: string]: (string|number)[] | string;
}

// TypeScript utility type to enforce that all keys except "expression" must be (string | number)[]
type EnforceArrayProperties<T> = {
    [K in keyof T]: K extends 'expression' ? T[K] : (string | number)[];
};

type ValidComputeMethods = EnforceArrayProperties<ComputeMethods>;

export function ComputedComponent<T>(component: FormComponent<T>): FormComponent<T>{
    return function (props: FormComponentProps & T): ReactElement {
        const form = useFormContext();
        const checklist = form.root();
        const newProps = { ...props };

        Object.keys(props).forEach((key) => {
            if (key.startsWith('@')) {
                const computedKey = key.slice(1);
                // @ts-ignore
                const computeMethods: ValidComputeMethods = props[key] as ValidComputeMethods;
                const { expression, ...bindings } = computeMethods;

                const boundValues = Object.entries(bindings)
                    .filter(([bindingKey]) => bindingKey.startsWith('v:'))
                    .reduce((acc, [bindingKey, value]) => {
                        const pathKey = bindingKey.slice(2);
                        acc[pathKey] = getInPath(checklist, value);
                        return acc;
                    }, {} as { [key: string]: any });

                const evalValue = new Function('v', `return ${expression};`)(boundValues);
                // @ts-ignore
                newProps[computedKey] = evalValue;
            }
        });

        return component(newProps);
    };
}

export function extractHtmlId(props: FormComponentProps): string|undefined {
    if (props['html-id']){
        const parentId = props["parent-html-id"];
        const index = props["html-index"];
        const env: {
            parentId?: string;
            index?: number;
            [key: string]: any;
        } = {
            parentId,
            index,
        };

        const template = props['html-id'];
        return template.replaceAll(/\$\{([^}]+)\}/g, (match, group1) => `${env[group1] ?? ''}`);
    }
    return undefined;
}

class EmptyFormState implements FormState{
    basePath: (string | number)[] = [];

    data(): MapState {
        return {};
    }

    dispatch(value: FormAction): void {
    }

    get(prop_name: string): any {
    }

    regist(key: string, defaultValue: any, initStates: MapState | undefined, statesPath?: (string|number)[]): any {
    }

    root(): MapState {
        return {};
    }

    set(prop_name: string, value: any): void {
    }

    state: MapState = {};
}

const EMPTY_FORM_STATE = new EmptyFormState();

const FormContext = React.createContext<FormState>(EMPTY_FORM_STATE);
const FormContextProvider = FormContext.Provider;
export const useFormContext: () => FormState = () => useContext(FormContext);

export const userFormState: <T>(form: FormState, name: string) => [T, (value: T) => void] =
    (form,name) => {
    const value = form.get(name);
    const setValue = (v: any) => {
        form.set(name, v);
    };
    return [value, setValue];
};

export function useForm<T>({name, initValue, initStates, statesPath, onChange}: {
    name: string;
    initValue: T;
    initStates?: MapState;
    statesPath?: (string|number)[];
    onChange?: (value: T) => void;
}) {
    const form = useFormContext();
    const value = form.get(name);
    const setValue = (v: T) => {
        form.set(name, v);
    };

    React.useEffect(() => {
        form.regist(name, initValue, initStates, statesPath);
    }, [form.root().version]);

    if (onChange){
        React.useEffect(() => {
            onChange?.(value);
        }, [value]);
    }

    return {
        value,
        setValue,
        form,
        getState(stateName: string): any {
            return getInPath(form.state, [...SimpleFormState.getStatePath(form.basePath, name, statesPath), stateName]);
        },
        setState(stateName: string, stateValue: any): void {
            form.dispatch({type: 'set', payload: {path: [...SimpleFormState.getStatePath(form.basePath, name, statesPath), stateName], value: stateValue}});
        },
    };
}

function formReducer(globalDispatch?: React.Dispatch<any>) {
    return function (state: MapState, action: FormAction | any): MapState{
        const { type: actionType, payload = {}} = action;
        const { path, value } = payload;
        switch (actionType) {
            case 'set':
                // eg. when previousState = {a: {b: 1}} ， path = ['a', 'b']， value = 2 ，Expected: {a: {b: 2}}
                return {
                    ...setInPath(state, path ?? [], value),
                    ...(((path ?? []).length>0 && !(path ?? []).some((p: any)=>`${p}`.startsWith('_'))) && {
                        [CHECKLIST_EDITED]: true,
                    }),
                };
            case 'setIfNotExist':
                if (getInPath(state, path ?? []) === undefined){
                    return setInPath(state, path ?? [], value);
                }
                return state;
            default:
                if (globalDispatch) {
                    globalDispatch(action);
                }
                return state;
        }
    };
}

function setInPath(obj: any, path: (string|number)[], value: any): any {
    if (path.length === 0){
        return value;
    }

    if (path.length === 1) {
        if (typeof path[0] === 'number') {
            let arr = Array.isArray(obj) ? [...obj] : [];
            arr[path[0]] = value;
            return arr;
        } else {
            return { ...obj, [path[0]]: value };
        }
    }

    const [first, ...rest] = path;
    if (typeof first === 'number') {
        let arr = Array.isArray(obj) ? [...obj] : [];
        arr[first] = setInPath(arr[first] || {}, rest, value);
        return arr;
    } else {
        return { ...obj, [first]: setInPath(obj[first] || {}, rest, value) };
    }
}

export function getInPath(obj: any, path: (string|number)[]): any {
    if (path.length === 0) {
        return obj;
    }

    const [first, ...rest] = path;

    if (!(first in obj)) {
        return undefined;
    }

    return getInPath(obj[first], rest);
}

function resolveRelativePath(basePath: (string|number)[], relativePath:(string|number)[]): (string|number)[]{
    const result = basePath.slice();
    for (const item of relativePath){
        if (item === '..'){
            result.pop();
        } else {
            result.push(item);
        }
    }
    return result;
}

class SimpleFormState implements FormState{
    state: MapState;
    dispatch: React.Dispatch<any>;
    basePath: (string|number)[];

    constructor(state: MapState, dispatch: React.Dispatch<any>, basePath: (string|number)[]){
        this.state = state;
        this.dispatch = dispatch;
        this.basePath = basePath;
    }

    static getStatePath(basePath: (string|number)[], key: string, statesPath?: (string|number)[]): (string|number)[]{
        if(statesPath === undefined){
            return [...basePath, '__'+key];
        } else {
            return resolveRelativePath(basePath, statesPath);
        }
    }

    regist(key: string, defaultValue: any, initStates?: MapState, statesPath?: (string|number)[]): any {
        const originValue = getInPath(this.state, [...this.basePath, key]) ?? defaultValue;
        this.dispatch({type: 'setIfNotExist', payload: {path: [...this.basePath, key], value: defaultValue}});
        const statePath = SimpleFormState.getStatePath(this.basePath, key, statesPath);
        this.dispatch({
            type: 'set',
            payload: {
                path: statePath,
                value: deepMergeIf(getInPath(this.state, statePath), {
                    ...initStates ?? {},
                    origin: originValue ?? '__NO_VALUES__',
                }),
            }
        });
        return getInPath(this.state, [...this.basePath, key]);
    }

    set(prop_name: string, value: any): void {
        this.dispatch({type: 'set', payload: {path: [...this.basePath, prop_name], value: value}});
    }

    get(prop_name: string): any {
        return getInPath(this.state,[...this.basePath, prop_name]);
    }

    data(): MapState {
        return getInPath(this.state, this.basePath);
    }

    root(): MapState {
        return this.state;
    }
}

export function RootForm(props: RootFormProps): ReactElement{
    const [rootData, rootDispatch] = React.useReducer(formReducer(props.globalDispatch) , props.defaultValue || {});

    if(props.onChange !== undefined){
        useEffect(() => {
            // @ts-ignore
            props.onChange(rootData);
        }, [rootData]);
    }
    useEffect(() => {
        // console.info('RootForm defaultValue:', props.defaultValue, 'from:', rootData);
        if(rootData?.sipCode != props?.defaultValue?.sipCode ||
            rootData?.version < props?.defaultValue?.version
        ){
            rootDispatch({type: 'set', payload: { path: [], value: deepMerge(rootData, props.defaultValue)}});
        }
    },[props.defaultValue]);
    const form = new SimpleFormState(rootData, rootDispatch, []);

    return (<FormContextProvider value={form}>
        {props.children}
    </FormContextProvider>);
}

type MapStateUpdater = (origin: MapState) => MapState;

export function SubForm({ formKey, children } : SubFormProps): ReactElement{
    const parentForm = useFormContext();

    useEffect(() => {
        parentForm?.regist(formKey, {});
    }, [formKey]);

    const form = new SimpleFormState(parentForm.state, parentForm.dispatch, [...parentForm.basePath, formKey]);

    return (<FormContextProvider value={form}>
        {children}
    </FormContextProvider>);
}

export function Form(props: FormProps): ReactElement{
    if('formKey' in props){
        return <SubForm {...props} />;
    }else{
        return <RootForm {...props} />;
    }
}

export function SubFormGroup({ formKey, defaultValue, children } : SubFormGroupProps): ReactElement{
    const parentForm = useFormContext();

    useEffect(() => {
        parentForm.regist(formKey, defaultValue || []);
    }, [formKey]);

    return (
        <>
            {React.Children.map(children, (child, index) => {
                return <FormContextProvider value={
                    new SimpleFormState(parentForm.state, parentForm.dispatch, [...parentForm.basePath, formKey, index])
                }>
                    {child}
                </FormContextProvider> ;
            })}
        </>);
}

function TextLabel(props: FormComponentProps): ReactElement{
    const form = useContext(FormContext);
    if(!form){
        throw new Error('FormComponent must be used inside Form');
    }

    return <label>{form.get(props.name)}</label>;
}

export function RelativePathForm(props: RelativePathFormProps): ReactElement{
    const parentForm = useFormContext();
    const path = resolveRelativePath(parentForm.basePath, props.path);

    const form = new SimpleFormState(parentForm.state, parentForm.dispatch, path);

    return <FormContextProvider value={form}>
        {props.children}
    </FormContextProvider>;
}

import * as actions from '../actions/SystemLogAction';

const initState = {
  systemLog: {},
  filterTypeList: [],
  filterSubjectList: [],
  filterUpdateByList: [],
  filterFieldList: [],
};

export default function SystemLogReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.GET_SYSTEM_LOG:
      return {
        ...state,
        systemLog: data,
      };
    case actions.GET_FLITER_TYPE:
      return {
        ...state,
        filterTypeList: data,
      };
    case actions.GET_FILTER_SUBJECT:
      return {
        ...state,
        filterSubjectList: data,
      };
    case actions.GET_FILTER_UPDATEBY:
      return {
        ...state,
        filterUpdateByList: data,
      };
    case actions.GET_FILTER_FIELD:
      return {
        ...state,
        filterFieldList: data,
      };
    case actions.LOG_CLEAR:
      return initState;
    default:
      return state;
  }
}

import { Box } from '@mui/material';
import SectionFolder from '../../compontents/SectionFolder';
import ActiveSI from './activeSI';
import PublishedSI from './publishedSI';
import ShelvedSI from './shelvedSI';
import { useDispatch, useSelector } from 'react-redux';

export default function AssociatedSISection(props) {
  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      CATJournalDetail: state.CATJournalDetail
    };
  };
  const { open, CATJournalDetail } = useSelector(selector);
  return (
    <SectionFolder
      title='Associated Special Issue'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          width:'1092px'
        }}>
        <Box
          sx={{
            'font-family': 'Open Sans',
            'font-size': '16px',
            'font-weight': '600',
            'line-height': '24px',
            'letter-spacing': '0em',
            'text-align': 'left',
            color: '#262E35',
          }}
        >Active SI</Box>
        <ActiveSI
          tableData={CATJournalDetail.catJournalActiveSiList}
        />
        <Box

          sx={{
            'font-family': 'Open Sans',
            'font-size': '16px',
            'font-weight': '600',
            'line-height': '24px',
            'letter-spacing': '0em',
            'text-align': 'left',
            color: '#262E35',
          }}
        >Published SI</Box>
        <PublishedSI
          tableData={CATJournalDetail.catJournalPublishedSiList}
        />
        <Box
          sx={{
            'font-family': 'Open Sans',
            'font-size': '16px',
            'font-weight': '600',
            'line-height': '24px',
            'letter-spacing': '0em',
            'text-align': 'left',
            color: '#262E35',
          }}
        >Shelved SI</Box>
        <ShelvedSI
          tableData={CATJournalDetail.catJournalShelvedSiList}
        />
      </Box>
    </SectionFolder>
  );
}
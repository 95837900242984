import './index.css';
import React, { useEffect, useState } from 'react';
import {
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Button,
  Stack,
  Divider,
} from '@mui/material';

import { SIColumnsModal } from '@/modules/Modal';
import SelectedDefaultCheckbox from '@/assets/SelectedDefaultCheckbox.svg';
import UnselectedDefaultCheckbox from '@/assets/UnselectedDefaultCheckbox.svg';
import IndeterminateDefaultCheckbox from '@/assets/IndeterminateDefaultCheckbox.svg';
import DrawerCloseButton from '@/componentsphase2/DrawerCloseButton';

import { useDispatch } from 'react-redux';
import { deepEqual } from "@/componentsphase2/SelectBox/SelectInput";
import {saveUserColumns} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueColumns";

const columnMap = {
  0: 0,
  1: 9,
  2: 18,
  3: 1,
  4: 10,
  5: 19,
  6: 2,
  7: 11,
  8: 20,
  9: 3,
  10: 12,
  11: 21,
  12: 4,
  13: 13,
  14: 22,
  15: 5,
  16: 14,
  17: 23,
  18: 6,
  19: 15,
  20: 24,
  21: 7,
  22: 16,
  23: 25,
  24: 8,
  25: 17,
  26: 37,
  27: 39,
  28: 40,
  29: 38,
  30: 26,
  31: 28,
  32: 30,
  33: 27,
  34: 29,
  35: 31,
  36: 33,
  37: 34,
  38: 32,
  39: 35,
  40: 36,
};

export const defaultColumns = [
  true, //SICode0
  true, //JC1
  true, //JN2
  false, //Online SSN 3
  false, //SIType 已更名为SI Publication Format4
  true, //SITitle5
  true, //Leader6
  true, //Co7
  true, //Stage8
  true, //HE9
  false, //Group10
  true, //PD Publisher11
  true, //PP Publisher12
  true, //Subject Group13
  false, //SISource14
  false, //IdeaDate15
  false, //AcquireDate16
  false, //Open for submission date17
  true, //Submission18
  false, //Accept19
  false, //Publication20
  false, //deadline pased21
  false, //close for submission 22
  false, //paperCommissionm method23
  false, //workflow24
  false, //PCE25

  false, //Recruited26
  true, //submitted27
  true, //Accept28
  true, //Reject29
  true, //inProgress30

  false, //Volume31
  false, //Issue32
  false, //Actual Date33
  false, //OaNumber34
  false, //OoNumber35
  false, //Output36

  false, //CreateDate37
  true, //lasActions38
  true, //stakeHoleder1  39
  true, //stakeHoleder2  40
];

// SICL for SI Change list.
let GeneralInformation1 = [ //Use this varaible to show the SI List column
  'SI Code', //0:0
  'Handling CE', //1:9
  'Submission Deadline', //2:17
  //second row
  'Journal Code', //3:1
  'Group/Title', //4:10
  'Expected Acceptance Date', //5:19
  //third row
  'Journal Name ', //6:2
  'PD Publisher', //7:11
  'Expected Publication Date', //8:20
  //forth row
  'Online ISSN' /*CT-2290 */, //9:3 //need delete (zyc)
  'PP Publisher', //10:12
  'Deadline Passed', //11:21
  'SI Publication Format', //12:4 CT-97 Special Issue Type 已更名为SI Publication Format,
  'Subject Group', //13:13
  'Closed for Submission ', //14:22
  'Special Issue Title', //15:5
  'SI Source', //16:14
  'Paper Commissioning Method', //17:23
  'Lead GE', //18:6
  'Idea Date', //19:15
  'Workflow', //20:24
  'Co-GE', //21:7
  'Acquired Date' /*CT-2287*/, //22:16 //need delete (zyc)
  'Previous Handling CE', //23:36
  'Stage', //24:8
  'Open for Submission Date', //25:17 
  'Create Date', //26:37
  undefined,//27: null
  undefined,//28: null
  "Last Action Date",//29:38
];

let SubmissionOverview = [
  'Number of Articles Recruited', //30:25
  'Number of Articles Accepted', //31:27
  'Number of Articles in Progress', //32:29

  'Number of Articles Submitted', //33:26
  'Number of Articles Rejected', //34:28
];
let PublicationDetails = [
  'Volume #', //35:30
  'Actual Publication Date', //36:32
  'Number of OA Article', //37:33

  'Issue #', //38:31
  'Number of OO Article', //39:34
  'Output Rate', //40:35
];

let saveColumnsList = [
  'SICode',
  'HE',
  'OpenForSubmissionDate' /*无法显示*/,
  'JournalCode',
  'Group',
  'ExpectedAcceptance Date',
  'JournalName',
  'PDPublisher',
  'ExpectedPublicationDate',
  'OnlineISSN',
  'PPPublisher',
  'DeadlinePassed',
  'SIPublicationFormat',
  'SubjectGroup',
  'Closed',
  'SpecialIssueTitle',
  'SISource',
  'PaperCommissioning',
  'LeadGE',
  'IdeaDate',
  'Workflow',
  'CoGE',
  'AcquiredDate',
  'PreviousHE',
  'Stage',
  'Deadline',
  'CreateDate',
  'stakeHoleder1',
  'stakeHoleder2',
  'LastActionDate',
  'ArticlesRecruited',
  'ArticlesAccepted',
  'InProgress',
  'ArticlesSubmitted',
  'ArticlesRejected',
  'Volume',
  'ActualPublicationDate',
  'OA',
  'Issue',
  'OO',
  'OutputRate',
];

let defChoice = [
  true,
  /*SICode0*/ true,
  /*HE9*/ true /*Accept18*/,
  true,
  /*JC1*/ true,
  /*Group10*/ true /*Publication19*/,
  true,
  /*JN2*/ true,
  /*JPM11*/ true /*deadline20*/,
  true,
  /*Online ISSN3*/ true,
  /*Subject Group12*/ true /*close21*/,
  true,
  /*SIType 已更名为SI Publication Format4*/ true,
  /*SISource13*/ true /*paperCommission22*/,
  true,
  /*SITitle5*/ true,
  /*IdeaDate14*/ true /*workflow23*/,
  true,
  /*Leader6*/ true,
  /*AcquireDate15*/ true /*PCE24*/,
  true,
  /*Co7*/ true,
  /*Open for Submission Date16*/ true, //
  true,
  /*Stage8*/ true /*Submission17*/,
  true,//Create Date
  true,
  true,
  true,//LastAction34

  true, //Recruited23
  true, //submitted24
  true, //Accept25
  true, //Reject26
  true, //inProgress27
  true, //Volume28
  true, //Issue29
  true, //Actual Date30
  true, //OaNumber31
  true, //OoNumber32
  true, //Output33
];

let allCancel = [
  true,
  /*SICode0*/ false,
  /*HE9*/ false /*Accept18*/,
  false,
  /*JC1*/ false,
  /*Group10*/ false /*Publication19*/,
  false,
  /*JN2*/ false,
  /*JPM11*/ false /*deadline20*/,
  false,
  /*Online ISSN3*/ false,
  /*Subject Group12*/ false /*close21*/,
  false,
  /*SIType 已更名为SI Publication Format4*/ false,
  /*SISource13*/ false /*paperCommission22*/,
  false,
  /*SITitle5*/ false,
  /*IdeaDate14*/ false /*workflow23*/,
  false,
  /*Leader6*/ false,
  /*AcquireDate15*/ false /*PCE24*/,
  false,
  /*Co7*/ false,
  /*Open for Submission Date16*/ false, //CreateDate36
  false,
  /*Stage8*/ false /*Submission17*/,false,false,false,//add three false for two empty and one new column
  true,//LastAction34

  false, //Recruited23
  false, //submitted24
  false, //Accept25
  false, //Reject26
  false, //inProgress27

  false, //Volume28
  false, //Issue29
  false, //Actual Date30
  false, //OaNumber31
  false, //OoNumber32
  false, //Output33
  false,
  false,
  false,
];

let allChoice = defChoice.map(() => {
  return true;
});

let nowChoice = defChoice;

// Show Columns 下方标题
function SICLTital(props) {
  return (
    <div
      className='sicl-column-title'
      style={{ fontWeight: 600, fontSize: '14px' }}
    >
      {props.title}
    </div>
  );
}

// Show Columns 复选框
function SICLCheckbox(props) {
  const { checked, handleCheck, index, disabled } = props;
  function onChange(event) {
    let temp = [...checked];
    temp[index] = event.target.checked;
    handleCheck(temp);
    console.log("index: ", index);
  }
  return (
    <FormControlLabel
      className='sicl-column-check'
      data-selenium-id='SIPage-ChangeList-SICLCheckbox'
      control={
        <Checkbox
          disableRipple
          icon={<UnselectedDefaultCheckbox />}
          checkedIcon={
            <SelectedDefaultCheckbox />
          }
          checked={props.checked[index]}
          onChange={onChange}
          disabled={disabled}
          style={{ padding: '12px', paddingRight: '5px' }}
          sx={{
            //NOT disable hover
            '&:not(.Mui-disabled):hover': {
              '& svg >*:nth-child(1)': {
                'stroke': '#596A7C',
              },
            },
            '&.Mui-checked:not(.Mui-disabled):hover,&.MuiCheckbox-indeterminate:not(.Mui-disabled):hover': {
              '& svg >*:nth-child(1)': {
                'stroke': '#113D95',
                'fill': '#113D95',
              },
            },
            //disable
            '&.Mui-disabled': {
              '& svg rect:nth-child(1)': {
                'stroke': '#DFE4E8',
                'fill': '#F6F7F8',
              },
            },
            '&.Mui-disabled.Mui-checked,&.Mui-disabled.MuiCheckbox-indeterminate': {
              '& svg rect:nth-child(1)': {
                'stroke': '#BCC5CF',
                'fill': '#BCC5CF',
              },
            },
            '&.Mui-disabled.MuiCheckbox-indeterminate': {
              '& svg rect:nth-child(1),& svg rect:nth-child(2)': {
                'stroke': '#BCC5CF',
                'fill': '#BCC5CF',
              },
            },
          }}
        />
      }
      label={
        <Box
          sx={{
            paddingLeft: '3px',
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '18px',
            letterSpacing: '0em',
            color: '#596A7C'
          }}
        >
          {props.label}
        </Box>
      }
    />
  );
}

const spaceRow = 4;
const ButtonFontCss = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.01em',
  textTransform: 'capitalize',
  color: '#154AB6',
};
// Show Columns 主体内容
function ContainerContent(props) {

  const {
    disableReset = false,
    checked,
    handleCheck,
    handleSave,
    handleAllCancel,
    handleAllSelect,
    setAllCheck,
    allCheck,
    handleClose,
    handleReset,
  } = props;

  const trueSum = checked.filter(x => x).length;

  const handleSetAllCheck = () => {
    if (!allCheck) {
      setAllCheck(true);
      handleAllSelect();
    } else {
      setAllCheck(false);
      handleAllCancel();
    }
  };
  return (
    <Stack
      sx={{ height: '100%' }}
      justifyContent='space-between'
      data-selenium-id='SIPage-ChangeList-ContainerContent-Stack'
    >
      <Stack
        data-selenium-id='SIPage-ChangeList-ContainerContent-Stack-Stack'
        justifyContent='space-between'
        alignItems='center'
        direction='row'
        height='74px'
      >
        <Stack
          data-selenium-id='SIPage-ChangeList-ContainerContent-Stack-Stack-Stack'
          direction='row'
          spacing='12px'
          justifyContent='center'
          alignItems='center'
        >
          <DrawerCloseButton
            onClick={handleClose}
          />

          <Box
            sx={{
              fontFamily: 'Open Sans',
              color: '#262E35',
              fontWeight: 600,
              lineHeight: '125%',
              fontSize: 18,
            }}
          >
            Columns
          </Box>
        </Stack>
        <Stack
          data-selenium-id='SIPage-ChangeList-ContainerContent-Stack-Stack-Stack'
          direction='row'
          spacing='10px'
        >
          <Button
            disableElevation
            disableRipple
            data-selenium-id='SIPage-ChangeList-SizableModal-ResetButton'
            disabled={disableReset}
            sx={{
              ...ButtonFontCss,
              width: 62,
              height: 34,
              border: 1,
              borderRadius: '5px',
              borderColor: '#0052CC',
              '&:hover': {
                color: '#6D98EE',
                borderColor: '#6D98EE',
                background: '#FFFFFF',
              },
              '&:active': {
                background: '#E8EDFB',
                color: '#6D98EE',
                borderColor: '#AEC6F6',
              },
              '&:disabled': {
                border: '1px solid #98A7B6',
                background: '#F1F3F5',
              },
            }}
            onClick={handleReset}
          >
            <div>Reset</div>
          </Button>
          <Button
            disableElevation
            disableRipple
            data-selenium-id='SIPage-ChangeList-SizableModal-SaveButton'
            variant='contained'
            sx={{
              ...ButtonFontCss,
              color: '#ffffff',
              width: 62,
              height: 34,
              padding: '8px 12px',
              background: '#0052CC',
              '&:hover': {
                background: '#6D98EE',
              },
              '&:active': {
                background: '#113D95',
              },
            }}
            onClick={handleSave}
          >
            <div>Save</div>
          </Button>
        </Stack>
      </Stack>
      <Divider
        sx={{
          color: '#DFE4E8',
          marginLeft: '-41px',
          marginRight: '-36px',
        }}
      />
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        item
        rowSpacing={spaceRow}
        className='sicl-column'
        sx={{
          marginTop: '3px',
        }}
        data-selenium-id='SIPage-ChangeList-ContainerContent-Grid'
      >
        <FormControlLabel
          id='sm-selectAll'
          data-selenium-id='SIPage-ChangeList-SizableModal-selectAll'
          control={
            <Checkbox
              disableRipple
              icon={<UnselectedDefaultCheckbox />}
              checkedIcon={<SelectedDefaultCheckbox />}
              checked={allCheck}
              onChange={handleSetAllCheck}
              indeterminate={!allCheck && trueSum > 2}
              indeterminateIcon={
                <IndeterminateDefaultCheckbox />
              }
              sx={{
                //NOT disable hover
                '&:not(.Mui-disabled):hover': {
                  '& svg >*:nth-child(1)': {
                    'stroke': '#596A7C',
                  },
                },
                '&.Mui-checked:not(.Mui-disabled):hover,&.MuiCheckbox-indeterminate:not(.Mui-disabled):hover': {
                  '& svg >*:nth-child(1)': {
                    'stroke': '#113D95',
                    'fill': '#113D95',
                  },
                },
                //disable
                '&.Mui-disabled': {
                  '& svg rect:nth-child(1)': {
                    'stroke': '#DFE4E8',
                    'fill': '#F6F7F8',
                  },
                },
                '&.Mui-disabled.Mui-checked,&.Mui-disabled.MuiCheckbox-indeterminate': {
                  '& svg rect:nth-child(1)': {
                    'stroke': '#BCC5CF',
                    'fill': '#BCC5CF',
                  },
                },
                '&.Mui-disabled.MuiCheckbox-indeterminate': {
                  '& svg rect:nth-child(1),& svg rect:nth-child(2)': {
                    'stroke': '#BCC5CF',
                    'fill': '#BCC5CF',
                  },
                },
              }}
            />
          }
          label={<div style={{ fontSize: '14px' }}>Displaying All Columns</div>}
          sx={{
            height: '20px',
            marginLeft: '-7px',
            marginTop: '18px',
            color: '#596A7C',
          }}
        />
      </Grid>
      <Box
        sx={{ position: 'relative', overflowY: 'auto', flexGrow: 1 }}
        data-selenium-id='SIPage-ChangeList-ContainerContent-Box'
      >
        <Box
          data-selenium-id='SIPage-ChangeList-ContainerContent-Box-Box'
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            color: '#596A7C',
          }}
        >
          <Box
            data-selenium-id='SIPage-ChangeList-ContainerContent-Box-Box-Box'
            sx={{
              marginBottom: '44px',
              marginTop: '10px',
            }}
          >
            <div
              id='siPageChangeListGeneralInformation'
              data-selenium-id='SIPage-ChangeList-GeneralInformation'
              item
            >
              <SICLTital title='General Information' />
            </div>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              item
              rowSpacing={spaceRow}
              className='sicl-column'
              data-selenium-id='SIPage-ChangeList-ContainerContent-Box-Box-Box-Grid'
            >
              {GeneralInformation1.map((item, index) => (
                <Grid
                  id={`siPageChangeListGeneralInformationCheckbox${index}`}
                  data-selenium-id={`SIPage-ChangeList-GeneralInformationCheckbox-${index}`}
                  xs={2}
                  sm={4}
                  md={4}
                  style={{ height: '24px', paddingTop: '30px' }}
                  item
                >
                  {item!==undefined&&<SICLCheckbox
                    label={item}
                    key={index}
                    index={index}
                    checked={checked}
                    handleCheck={handleCheck}
                    disabled={item === 'Last Action Date' || item === 'SI Code'}
                  />}
                </Grid>
              ))}
            </Grid>
          </Box>
          <div>
            <Box sx={{ marginBottom: '44px' }}>
              <Box
                sx={{
                  paddingTop: '8px'
                }}
                id='siPageChangeListSubmissionOverview'
                data-selenium-id='SIPage-ChangeList-SubmissionOverview'
                item
              >
                <SICLTital title='Submission Overview' />
              </Box>
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                item
                rowSpacing={spaceRow}
                className='sicl-column'
              >
                {SubmissionOverview.map((item, index) => (
                  <Grid
                    id={`siPageChangeListSubmissionOverviewCheckbox${index}`}
                    data-selenium-id={`SIPage-ChangeList-SubmissionOverview-Checkbox-${index}`}
                    xs={2}
                    sm={4}
                    md={4}
                    sx={{ height: '24px', paddingLeft: 0 }}
                    style={{ paddingTop: '30px' }}
                    item
                  >
                    <SICLCheckbox
                      checked={checked}
                      handleCheck={handleCheck}
                      label={item}
                      key={index + GeneralInformation1.length}
                      index={index + GeneralInformation1.length}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box sx={{ marginBottom: '68px' }}>
              <Box
                sx={{
                  paddingTop: '8px'
                }}
                id='siPageChangeListPublicationDetails'
                data-selenium-id='SIPage-ChangeList-PublicationDetails'
                item
              >
                <SICLTital title='Publication Details' />
              </Box>
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                item
                rowSpacing={spaceRow}
                className='sicl-column'
              >
                {PublicationDetails.map((item, index) => (
                  <Grid
                    id={`siPageChangeListPublicationDetailsCheckbox${index}`}
                    data-selenium-id={`SIPage-ChangeList-PublicationDetails-Checkbox-${index}`}
                    xs={2}
                    sm={4}
                    md={4}
                    item
                    sx={{ height: '24px' }}
                    style={{ paddingTop: '30px' }}
                  >
                    <SICLCheckbox
                      checked={checked}
                      handleCheck={handleCheck}
                      label={item}
                      key={
                        index +
                        GeneralInformation1.length +
                        SubmissionOverview.length
                      }
                      index={
                        index +
                        GeneralInformation1.length +
                        SubmissionOverview.length
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </div>
        </Box>
      </Box>
    </Stack>
  );
}

function columnsMap(checkList) {
  let tmpCheckedList = Array(checkList.length).fill(false);
  for (let i = 0; i < checkList.length; i++) {
    tmpCheckedList[columnMap[i]] = checkList[i];
  }
  return tmpCheckedList;
}

function IRcolumnsMap(checkList) {
  let tmpCheckedList = Array(checkList.length).fill(false);
  for (let i = 0; i < checkList.length; i++) {
    tmpCheckedList[i] = checkList[columnMap[i]];
  }
  return tmpCheckedList;
}

function ChangeList(props) {
  const { showColumns = [], setShowColumns, changeListOpen, setChangeListOpen } =
    props;

  let [checked, setChecked] = useState([...showColumns]);
  const [allCheck, setAllCheck] = useState(false);

  const dispatch = useDispatch();

  const [disableReset, setDisableReset] = useState(false);

  useEffect(()=> {
    if (deepEqual(columnsMap(checked), defaultColumns)){
      setDisableReset(true);
    } else {
      setDisableReset(false);
    }
  }, [checked, showColumns]);

  const handleColumnsSave = () => {
    let saveColumns = {};
    console.log(checked);
    saveColumnsList.forEach((value, index) => {
      saveColumns[value.replaceAll(' ', '')] = checked[index];
    });
    dispatch(saveUserColumns(saveColumns));
  };

  useEffect(() => {
    let tsum = 0;
    for (let i in checked) {
      if (checked[i]) {
        tsum += 1;
      }
    }

    if (tsum >= checked.length) {
      setAllCheck(true);
    } else {
      setAllCheck(false);
    }
  }, [checked]);

  useEffect(() => {
    if (changeListOpen) {
      setChecked(IRcolumnsMap(showColumns));
    }
  }, [changeListOpen]);

  function handleReset() {
    setChecked(IRcolumnsMap(
      defaultColumns
    ));
    setAllCheck(false);
  }

  function handleAllSelect() {
    setChecked([...allChoice]);
  }

  function handleAllCancel() {
    setChecked([...allCancel]);
  }

  function handleClose() {
    setChangeListOpen(false);
  }

  function handleSave() {
    setShowColumns(columnsMap(checked));
    handleColumnsSave();
    nowChoice = checked;
    setChangeListOpen(false);
  }
  return (
    <div>
      <SIColumnsModal
        data-selenium-id='SIPage-ChangeList-SIModal'
        open={changeListOpen}
        handleClose={handleClose}
        width={924}
        height={'calc(100vh)'}
      >
        <ContainerContent
          disableReset={disableReset}
          checked={checked}
          handleClose={handleClose}
          handleCheck={setChecked}
          handleSave={handleSave}
          handleReset={handleReset}
          handleAllSelect={handleAllSelect}
          allCheck={allCheck}
          handleAllCancel={handleAllCancel}
          setAllCheck={setAllCheck}
        />
        {/* </Modal>*/}
      </SIColumnsModal>
    </div>
  );
}

export default ChangeList;

/*
 * @Author: LiuJingQian 1250366059@qq.com
 * @Date: 2023-05-28 21:38:10
 * @LastEditors: LiuJingQian 1250366059@qq.com
 * @LastEditTime: 2023-06-07 19:39:22
 * @FilePath: \simt_front\src\pages\SIPage\Overview\anotherNewFilterDrawer\style.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {
  H4Font,
  MiniBoldFont,
  SmallBoldFont,
  SmallFont,
  MiniFont,
  InterFont,
  LargeBoldFont,
  TinyFont,
} from '../../../../components/FontStyle';
import styled from '@emotion/styled';
import { Chip, TextField } from '@mui/material';

export const drawer = {
  boxSizing: 'border-box',
  padding: '0px',
  // height: '800px'
};

export const drawer_head = {
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
};

export const drawer_head_title = {
  display: 'inline-block',
  height: '13px',
  ...LargeBoldFont,
  color: '#262E35',
  marginLeft: '17px',
  lineHeight: '13px',
  // marginBottom: '8px',
  // marginBottom: '400px',
  // marginTop: '11px',
};

export const drawer_head_icon = {
  // width: '14px',
  // height: '14px',
  padding: '0px',
  color: '#98A7B6',
  marginLeft: '5px',
  // marginLeft: '31px',
  // marginTop: '14px',
};

// export const drawer_top_buttonList = {
//   display: 'flex',
//   justifyContent: 'flex-end',
//   width: '100%',
//   marginTop: '4px',
// };

export const drawer_top_box = {
  marginLeft: '17px',
  marginTop: '28px',
  marginBottom: '26px',
  height: '20px',
  width: '111px',
};

export const drawer_top_button = {
  ...SmallFont,
  color: '#0855A5',
  textTransform: 'none',
  cursor: 'pointer',

  // marginLeft: '16px',
  // marginTop: '8px',
};
export const stack_header = {
  display: 'flex',
  justifyContent: 'flex-start',
  // width: '74px',
  alignItems: 'center',
  paddingX: '36px',
  height: '74px',
};

export const stack_left = {
  marginLeft: '36px',
  marginRight: '52px',
  display: 'flex',
  justifyContent: 'flex-start',
};

export const stack_right = {
  marginRight: '36px',
  marginLeft: '0px',
  display: 'flex',
  justifyContent: 'flex-start',
};

export const drawer_main_block_after_stage = {
  boxSizing: 'border-box',
  marginTop: '0px',
  marginBottom: '4px',
};

export const drawer_main_block = {
  boxSizing: 'border-box',
  marginTop: '24px',
  marginBottom: '0px',
};

export const drawer_main_block_fixed = {
  boxSizing: 'border-box',
  marginTop: '20px',
  marginBottom: '0px',
};

export const drawer_main_block_title = {
  ...SmallBoldFont,
  color: '#596A7C',
  width: '400px',
};

export const drawer_main_block_operation = {
  marginTop: '4px',
  width: '400px',
  // Height: '40px',
};

export const drawer_reset_date_button = {
  ...TinyFont,
  marginLeft: '4px',
  color: '#154AB6',
  cursor: 'pointer',
  // marginTop: '2px',
  display: 'flex',
  alignItems: 'center',
};

export const drawer_main_stage_select_checkbox = {
  width: '14px',
  height: '14px',
  marginRight: '6px',
  padding: '0px',
};

export const drawer_main_stage_select_checkbox_lable = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '20px',
  color: '#596A7C',
};

export const drawer_bottom_button_list = {
  width: '100%',
  height: '56px',
  background: '#FFFFFF',
  display: 'flex',
  justifyContent: 'flex-end',
  borderTop: '1px solid #DFE4E8',
  position: 'sticky',
  bottom: 0,
};

export const drawer_bottom_button_reset = {
  ...SmallBoldFont,
  color: '#0052CC',
  cursor: 'pointer',
  borderRadius: '4px',
  boxSizing: 'border-box',
  padding: '8px 12px',
  flexDirection: 'row',
  background: '#FFFFFF',
  width: '70px',
  height: '34px',
  border: '1px solid #0052CC',
  // marginLeft: '340px',
  marginLeft: 'auto',
  marginRight: '10px',
  textAlign: 'center',
  // marginTop : '8px',
  // marginLeft: '292px',
};
export const drawer_bottom_button_adapt = {
  ...SmallBoldFont,
  color: '#FFFFFF',
  cursor: 'pointer',
  padding: '8px 12px',
  flexDirection: 'row',
  background: '#0052CC',
  width: '110px',
  height: '34px',
  boxSizing: 'border-box',
  textAlign: 'center',
  // marginLeft: '22px',
  borderRadius: '5px',
};

//This type is used on GE Name and SI Code
export const StyledChipTypeOne = styled(Chip)({
  boxSizing: 'border-box',
  padding: '2px 2px 2px 6px',
  background: '#F1F3F5',
  borderRadius: '5px',
  height: '22px',
  '& .MuiChip-label': {
    padding: '0px',
    ...MiniBoldFont,
    color: '#596A7C',
  },
  '& .MuiChip-deleteIcon': {
    margin: '0px 0px 0px 4px',
  },
});

//This type is used for all types except type 1
export const StyledChipTypeSecond = styled(Chip)({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '5px 8px 5px 12px',
  gap: '6px',
  maxWidth: '387px',

  backgroundColor: '#FFFFFF',
  borderRadius: '4px',

  height: '22px',
  border: '1px solid #98A7B6',
  '&:hover': {
    backgroundColor: '#DFE4E8',
    border: '1px solid #929DAE',
    '& .MuiChip-label': {
      fontWeight: 600,
    },
  },
  '& .MuiChip-label': {
    padding: '0px',
    lineHeight: '18px',
    color: '#596A7C',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0px',
    textAlign: 'left',
  },
  '& .MuiChip-deleteIcon': {
    margin: '0px 0px 0px 0px',
    color: '#0F172A',
    '&:hover': {
      background: '#BCC5CF',
      borderRadius: '2px',
    },
  },
});

export const styledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    borderRadius: 4,
    boxSizing: 'border-box',
    width: '100%',
    height: '34px',
    ...MiniFont,
    color: '#505050',
    padding: '8px 12px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '0px 0px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #DFE4E8',
  },
});

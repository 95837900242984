/**
 * This file includes basic eChart options for barchart diagram type
 * The 1st part has some common constants
 * The 2nd defined an Options class
 */

import { BasicLayoutChartOptions } from './BasicLayoutChartOptions';
import { NEED_IMPLEMENT_METHOD } from '../constants';
const CHART_PADDING_TOP = 36;
const CHART_PADDING_BOTTOM = 36;
const ZOOM_PADDING_LEFT = 16;

const LEGEND_HEIGHT = 40;
const LEGEND_PADDING_BOTTOM = 20;

/**
 * zoom controller
 */
const ZOOM_CONTROL_PADDING_LEFT = 10;
const ZOOM_CONTROL_RIGHT_OFFSET = 30;

/**
 * dataZoom
 */
const DATA_ZOOM_INSIDE = {
  type: 'inside',
  yAxisIndex: 0,
  start: 0,
  end: 100,
  filterMode: 'none', // filter no data for zoom
};

const DATA_ZOOM_SLIDER = {
  type: 'slider',
  yAxisIndex: 0,
  // right
  show: true,
  showDetail: true,
  showDataShadow: false,
  fillerColor: '#4C81EB',
  backgroundColor: '#D9D9D9B3',
  borderRadius: 15,
  width: 4,
  moveHandleSize: 0,
  handleIcon: 'circle',
  handleSize: 12,
  handleStyle: {
    borderColor: '#FFFFFF',
    borderWidth: 3,
    color: '#4C81EB',
  },
  textStyle: {
    fontSize: 8,
    color: '#113D95',
    backgroundColor: '#F1F3F5',
    padding: 5,
    height: 18,
    lineHeight: 18,
    borderRadius: 3,
  },
  filterMode: 'empty',
};

const LINE_STYLE = {
  Color: '#DCDCDC',
  Type: 'solid',
  width: 0.4,
  opacity: 1,
};

const Y_AXIS_LABLE_STYLE = {
  color: '#6F6F6F',
  fontStyle: 'normal',
  fontWeight: 400,
  fontFamily: 'Open Sans',
  fontSize: 10,
  lineHeight: 14,
};

const Y_AXIS = {
  type: 'value',
  gridIndex: 0,
  nameTextStyle: {
    fontSize: '50px',
    align: 'left',
  },
  splitNumber: 10,
  minInterval: 1,
  axisLabel: {
    show: true,
    interval: 'auto',
    inside: false,
    rotate: 0,
    margin: 8,
    formatter: function (value, index) {
      // return value + "%"
      return value;
    },
    showMinLabel: null,
    showMaxLabel: null,
    textStyle: Y_AXIS_LABLE_STYLE,
  },
  splitLine: {
    show: true,
    interval: 'auto',
    lineStyle: LINE_STYLE,
  },
};

const X_AXIS_LABLE_STYLE = {
  color: '#6F6F6F',
  fontStyle: 'normal',
  fontWeight: 400,
  fontFamily: 'Open Sans',
  fontSize: 10,
  lineHeight: 8.5,
};

const X_AXIS = {
  type: 'category',
  // inverse: true,
  axisTick: {
    show: false,
  },
  axisLabel: {
    show: true,
    interval: 'auto',
    inside: false,
    rotate: 0,
    margin: 14, //margin top + half of font size
    formatter: function (value, index) {
      return value;
    },
    showMinLabel: null,
    showMaxLabel: null,
    textStyle: X_AXIS_LABLE_STYLE,
    overflow: 'break',
    verticalAlign: 'middle',
  },
  axisLine: {
    show: true,
    onZero: true,
    lineStyle: {
      color: '#979797',
      width: 0.4,
    },
  },
};

const BAR_SERIE_LABEL_STYLE = {
  datasetIndex: 0,
  barMinHeight: 1,
  type: 'bar',
  label: {
    show: true,
  },
  emphasis: {
    disabled: true,
  },
  itemStyle: {
    normal: {
      label: {
        show: true,
        position: 'inside',
        textStyle: {
          color: '#FFFFFF',
          fontSize: 5,
        },
      },
    },
  },
};
// settings
export const LEGEND_STYLE = {
  icon: 'circle',
  width: '750px',
  selectedMode: false,
  inactiveColor: '#BCC5CF',
  inactiveBorderColor: '#BCC5CF',
  inactiveBorderWidth: 1,
  textStyle: {
    fontFamily: 'Open Sans',
    fontSize: '10px',
    fontWeight: '400',
    color: '#596A7C',
    padding: [0, 5, 0, 5],
  },
  bottom: LEGEND_PADDING_BOTTOM,
};

const TOOLTIP_PADDING_BOTTOM = 4;
const TOOLTIP_STYLE = {
  showContent: true,
  borderWidth: 1,
  borderRadius: 4,
  trigger: 'item',
  padding: [6, 8, 6, 8], // top, right, bottom, left
  position: function (point, params, dom, rect, size) {
    const boxWidth = size.contentSize[0];
    const boxHeight = size.contentSize[1];
    const x = rect.x + 0.5 * rect.width - 0.5 * boxWidth;
    const y = rect.y - boxHeight - TOOLTIP_PADDING_BOTTOM;
    return [x, y];
  },
  formatter: params => {
    let dataStr = `<div style="padding: 0; margin: 0;">
    <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 0 0 2.5px 0;">${
      params.name
    }</p>
    <p style="font-size: 10px; font-weight: 400;color: #848484; line-height: 14px; margin: 0;">${
      params.seriesName
    }&nbsp;:&nbsp;${params.data[params.seriesName]}</p>
    </div>`;
    return dataStr;
  },
};

const TITLE = {
  textStyle: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 700,
    color: '#113D95',
    lineHeight: 17.5,
  },
  subtextStyle: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 400,
    color: '#113D95',
    lineHeight: 17.5,
  },
  left: 'center',
};

export class BarAndLineChartOptions extends BasicLayoutChartOptions {
  constructor() {
    super();
    this.chartPaddingTop = CHART_PADDING_TOP;
    this.chartPaddingBottom = CHART_PADDING_BOTTOM;
    this.dataZoomInside = Object.assign({}, DATA_ZOOM_INSIDE);
    this.dataZoomSlider = Object.assign({}, DATA_ZOOM_SLIDER);
    this.zoomControlPaddingLeft = ZOOM_CONTROL_PADDING_LEFT;
    this.zoomPaddingLeft = ZOOM_PADDING_LEFT;
    this.zoomControlRightOffset = ZOOM_CONTROL_RIGHT_OFFSET;
    this.legendHeight = LEGEND_HEIGHT;
    this.barSerieOption = Object.assign({}, BAR_SERIE_LABEL_STYLE);
    this.tooltip = Object.assign({}, TOOLTIP_STYLE);
    this.hasTitle = false;
    this.title = '';
    this.subTitle = '';
    this.hasZoom = false;
    this.legend = [];
    this.legendSG = [];
    this.legendBD = [];
    this.dataForBar = null;
  }

  getXAxis() {
    return X_AXIS;
  }

  getYAxis() {
    return Y_AXIS;
  }

  getTitle() {
    if (this.hasTitle) {
      return {
        ...TITLE,
        top: this.titleTopPadding,
        text: this.title,
      };
    }
    return undefined;
  }

  getZoom() {
    if (this.hasZoom) {
      let slider = {
        ...this.dataZoomSlider,
        right: this.chartPaddingRight - ZOOM_PADDING_LEFT,
      };
      return [this.dataZoomInside, slider];
    }
    return undefined;
  }

  getSelectedLegend() {
    throw new Error(NEED_IMPLEMENT_METHOD);
  }

  setSelectedLegend(selectedLegend) {
    this.selectedLegend = selectedLegend;
    return this;
  }

  setLegend(legend) {
    this.legend = legend;
    return this;
  }

  getDataset() {
    return [
      {
        source: this.dataForBar,
      },
    ];
  }

  setDataSource(dataForBar) {
    this.dataForBar = dataForBar;
    return this;
  }

  // create series of bar charts
  getSeries() {
    const barSeries = this.getBarSeries();

    return [...barSeries];
  }

  getBarSeries() {
    return new Error(NEED_IMPLEMENT_METHOD);
  }
  getTooltip() {
    return new Error(NEED_IMPLEMENT_METHOD);
  }
  getColor() {
    return ['#FFFFFF'];
  }
}

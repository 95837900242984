import { useState, useEffect } from 'react';

const mergeString = (str1, str2) => {
  if (!str1) return str2;
  if (!str2) return str1;
  return str1 + '; ' + str2;
};

export const useDataControl = initialData => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('');
  const [primaryEmail, setPrimaryEmail] = useState('');
  const [secondaryEmail, setSecondaryEmail] = useState('');
  const [orcidId, setOrcidId] = useState('');
  const [researchGateId, setResearchGateId] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [department, setDepartment] = useState('');
  const [institution, setInstitution] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [researchKeywords, setResearchKeywords] = useState('');
  const [profileWebsite, setProfileWebsite] = useState('');
  const [notes, setNotes] = useState('');
  const [cv, setCv] = useState('');

  const [firstNameChanged, setFirstNameChanged] = useState(false);
  const [lastNameChanged, setLastNameChanged] = useState(false);
  const [genderChanged, setGenderChanged] = useState(false);
  const [primaryEmailChanged, setPrimaryEmailChanged] = useState(false);
  const [secondaryEmailChanged, setSecondaryEmailChanged] = useState(false);
  const [orcidIdChanged, setOrcidIdChanged] = useState(false);
  const [researchGateIdChanged, setResearchGateIdChanged] = useState(false);
  const [jobTitleChanged, setJobTitleChanged] = useState(false);
  const [departmentChanged, setDepartmentChanged] = useState(false);
  const [institutionChanged, setInstitutionChanged] = useState(false);
  const [cityChanged, setCityChanged] = useState(false);
  const [countryChanged, setCountryChanged] = useState(false);
  const [researchKeywordsChanged, setResearchKeywordsChanged] = useState(false);
  const [profileWebsiteChanged, setProfileWebsiteChanged] = useState(false);
  const [notesChanged, setNotesChanged] = useState(false);
  const [cvChanged, setCvChanged] = useState(false);

  const setData = (key, value) => {
    switch (key) {
      case 'First Name':
        setFirstName(value);
        setFirstNameChanged(true);
        break;
      case 'Last Name':
        setLastName(value);
        setLastNameChanged(true);
        break;
      case 'Gender':
        setGender(value);
        setGenderChanged(true);
        break;
      case 'Primary Email':
        setPrimaryEmail(value);
        if (initialData.Two.BasicInformation['primaryEmail'] === value){
          setPrimaryEmailChanged(false);
        }else{
          setPrimaryEmailChanged(true);
        }

        break;
      case 'Secondary Email':
        setSecondaryEmail(value);
        if (initialData.Two.BasicInformation['secondaryEmail'] === value){
          setSecondaryEmailChanged(false);
        }else{
          setSecondaryEmailChanged(true);
        }

        break;
      case 'ORCID ID':
        setOrcidId(value);
        setOrcidIdChanged(true);
        break;
      case 'ResearchGate ID':
        setResearchGateId(value);
        setResearchGateIdChanged(true);
        break;
      case 'Title':
      case 'Job Title':
        setJobTitle(value);
        setJobTitleChanged(true);
        break;
      case 'Department':
        setDepartment(value);
        setDepartmentChanged(true);
        break;
      case 'Institution':
        setInstitution(value);
        setInstitutionChanged(true);
        break;
      case 'City':
        setCity(value);
        setCityChanged(true);
        break;
      case 'Country/Region':
        setCountry(value);
        setCountryChanged(true);
        break;
      case 'Research Keywords':
        setResearchKeywords(value);
        setResearchKeywordsChanged(true);
        break;
      case 'Profile Website':
        setProfileWebsite(value);
        setProfileWebsiteChanged(true);
        break;
      case 'Notes':
        setNotes(value);
        setNotesChanged(true);
        break;
      case 'CV':
        setCv(value);
        setCvChanged(true);
        break;
      default:
        break;
    }
  };

  //系undo按钮的点击触发函数，会将文本框中的值替换为默认值
  const unSetData = key => {
    switch (key) {
      case 'First Name':
        setFirstName(initialData.Two.BasicInformation['firstName']);
        setFirstNameChanged(false);
        break;
      case 'Last Name':
        setLastName(initialData.Two.BasicInformation['lastName']);
        setLastNameChanged(false);
        break;
      case 'Gender':
        setGender(initialData.Two.BasicInformation['gender']);
        setGenderChanged(false);
        break;
      case 'Primary Email':
        setPrimaryEmail(initialData.Two.BasicInformation['primaryEmail']);
        setPrimaryEmailChanged(false);
        break;
      case 'Secondary Email':
        setSecondaryEmail(initialData.Two.BasicInformation['secondaryEmail']);
        setSecondaryEmailChanged(false);
        break;
      case 'ORCID ID':
        setOrcidId(initialData.Two.BasicInformation['orcidId']);
        setOrcidIdChanged(false);
        break;
      case 'ResearchGate ID':
        setResearchGateId(
          initialData.Two.BasicInformation['researchKeywordsList']?.join(';')
        );
        setResearchGateIdChanged(false);
        break;
      case 'Title':
      case 'Job Title':
        setJobTitle(initialData.Two.BasicInformation['jobTitle']);
        setJobTitleChanged(false);
        break;
      case 'Department':
        setDepartment(initialData.Two.BasicInformation['department']);
        setDepartmentChanged(false);
        break;
      case 'Institution':
        setInstitution(initialData.Two.BasicInformation['institution']);
        setInstitutionChanged(false);
        break;
      case 'City':
        setCity(initialData.Two.BasicInformation['city']);
        setCityChanged(false);
        break;
      case 'Country/Region':
        setCountry(initialData.Two.BasicInformation['country']);
        setCountryChanged(false);
        break;
      case 'Research Keywords':
        setResearchKeywords(
          initialData.Two.BasicInformation['researchKeywords']
        );
        setResearchKeywordsChanged(false);
        break;
      case 'Profile Website':
        setProfileWebsite(initialData.Two.BasicInformation['profileWebsite']);
        setProfileWebsiteChanged(false);
        break;
      case 'Notes':
        // if(initialData.Two.BasicInformation['notes']===null) console.log("note为null");
        // if(initialData.Two.BasicInformation['notes']==='') console.log("note为空字符串");
        setNotes(initialData.Two.BasicInformation['notes']);
        setNotesChanged(false);
        break;
      case 'CV':
        setCv(initialData.Two.BasicInformation['cv']);
        setCvChanged(false);
        break;
      default:
        break;
    }
  };

  const hasEdited = key => {
    switch (key) {
      case 'First Name':
        return firstNameChanged;
      case 'Last Name':
        return lastNameChanged;
      case 'Gender':
        return genderChanged;
      case 'Primary Email':
        return primaryEmailChanged;
      case 'Secondary Email':
        return secondaryEmailChanged;
      case 'ORCID ID':
        return orcidIdChanged;
      case 'ResearchGate ID':
        return researchGateIdChanged;
      case 'Title':
      case 'Job Title':
        return jobTitleChanged;
      case 'Department':
        return departmentChanged;
      case 'Institution':
        return institutionChanged;
      case 'City':
        return cityChanged;
      case 'Country/Region':
        return countryChanged;
      case 'Research Keywords':
        return researchKeywordsChanged;
      case 'Profile Website':
        return profileWebsiteChanged;
      case 'Notes':
        return notesChanged;
      case 'CV':
        return cvChanged;
      default:
        break;
    }
  };

  const data = {
    BasicInfo: {
      isTitleLine: true,
      title: 'Basic information',
    },
    'First Name': {
      key: 'First Name',
      item1: {
        value: initialData.One.BasicInformation['firstName'],
      },
      item2: {
        value: firstNameChanged
          ? firstName
          : initialData.Two.BasicInformation['firstName'],
      },
      editType: 'cover',
      isTitleLine: false,
    },
    'Last Name': {
      key: 'Last Name',
      item1: {
        value: initialData.One.BasicInformation['lastName'],
      },
      item2: {
        value: lastNameChanged
          ? lastName
          : initialData.Two.BasicInformation['lastName'],
      },
      editType: 'cover',
      isTitleLine: false,
    },
    Gender: {
      key: 'Gender',
      item1: {
        value: initialData.One.BasicInformation['gender'],
      },
      item2: {
        value: genderChanged
          ? gender
          : initialData.Two.BasicInformation['gender'],
      },
      editType: 'cover',
      isTitleLine: false,
    },
    'Primary Email': {
      key: 'Primary Email',
      item1: {
        value: initialData.One.BasicInformation['primaryEmail'],
      },
      item2: {
        value: primaryEmailChanged
          ? primaryEmail
          : initialData.Two.BasicInformation['primaryEmail'],
      },
      editType: 'edit',
      isTitleLine: false,
    },
    'Secondary Email': {
      key: 'Secondary Email',
      item1: {
        value: initialData.One.BasicInformation['secondaryEmail'],
      },
      item2: {
        value: secondaryEmailChanged
          ? secondaryEmail
          : initialData.Two.BasicInformation['secondaryEmail'],
      },
      editType: 'edit',
      isTitleLine: false,
    },
    'ORCID ID': {
      key: 'ORCID ID',
      item1: {
        value: initialData.One.BasicInformation['orcidId'],
      },
      item2: {
        value: orcidIdChanged
          ? orcidId
          : initialData.Two.BasicInformation['orcidId'],
      },
      editType: 'cover',
      isTitleLine: false,
    },
    'ResearchGate ID': {
      key: 'ResearchGate ID',
      item1: {
        value: initialData.One.BasicInformation['researchGateId'],
      },
      item2: {
        value: researchGateIdChanged
          ? researchGateId
          : initialData.Two.BasicInformation['researchGateId'],
      },
      editType: 'cover',
      isTitleLine: false,
    },
    'Job Title': {
      key: 'Title',
      item1: {
        value: initialData.One.BasicInformation['title'],
      },
      item2: {
        value: jobTitleChanged
          ? jobTitle
          : initialData.Two.BasicInformation['title'],
      },
      editType: 'cover',
      isTitleLine: false,
    },
    Department: {
      key: 'Department',
      item1: {
        value: initialData.One.BasicInformation['department'],
      },
      item2: {
        value: departmentChanged
          ? department
          : initialData.Two.BasicInformation['department'],
      },
      editType: 'cover',
      isTitleLine: false,
    },
    Institution: {
      key: 'Institution',
      item1: {
        value: initialData.One.BasicInformation['institute'],
      },
      item2: {
        value: institutionChanged
          ? institution
          : initialData.Two.BasicInformation['institute'],
      },
      editType: 'cover',
      isTitleLine: false,
    },
    City: {
      key: 'City',
      item1: {
        value: initialData.One.BasicInformation['city'],
      },
      item2: {
        value: cityChanged ? city : initialData.Two.BasicInformation['city'],
      },
      editType: 'cover',
      isTitleLine: false,
    },
    'Country/Region': {
      key: 'Country/Region',
      item1: {
        value: initialData.One.BasicInformation['country'],
      },
      item2: {
        value: countryChanged
          ? country
          : initialData.Two.BasicInformation['country'],
      },
      editType: 'cover',
      isTitleLine: false,
    },
    'Research Keywords': {
      key: 'Research Keywords',
      item1: {
        value:
          initialData.One.BasicInformation['researchKeywordsList']?.join('; '),
      },
      item2: {
        value: researchKeywordsChanged
          ? researchKeywords
          : initialData.Two.BasicInformation['researchKeywordsList']?.join('; '),
      },
      editType: 'union',
      isTitleLine: false,
    },
    'Profile Website': {
      key: 'Profile Website',
      item1: {
        value: initialData.One.BasicInformation['profileWebsite'],
      },
      item2: {
        value: profileWebsiteChanged
          ? profileWebsite
          : initialData.Two.BasicInformation['profileWebsite'],
      },
      editType: 'union',
      isTitleLine: false,
    },
    Notes: {
      key: 'Notes',
      item1: {
        value: initialData.One.BasicInformation['notes'],
      },
      item2: {
        value: notesChanged ? notes : initialData.Two.BasicInformation['notes'],
      },
      editType: 'union',
      isTitleLine: false,
    },

    CV: {
      key: 'CV',
      item1: {
        value: initialData.One.BasicInformation['cvFileName'],
      },
      item2: {
        value: cvChanged ? cv : initialData.Two.BasicInformation['cvFileName'],
      },
      editType: 'cover',
      isTitleLine: false,
    },
  };

  return [data, setData, unSetData, hasEdited];
};

// WARNING! Don't Touch
/**
 * @param {import('./dataHook').useFlagControlParams} initialData 
 */
export const useFlagControl = initialData => {
  const {One,Two}=initialData;
  const {Flag:OneFlag}=One;
  const {Flag:TwoFlag}=Two;
  const flags = {
    One: Array.isArray(OneFlag)?OneFlag?.map(_flag => ({
      key: _flag.flagInfo.key,
      flag: {
        type: _flag.flagInfo.type,
        value: _flag.flagInfo.value,
      },
      evidences: _flag.files,
      comment: _flag.comment,
    })):[],
    Two: Array.isArray(TwoFlag)?TwoFlag?.map(_flag => ({
      key: _flag.flagInfo.key,
      flag: {
        type: _flag.flagInfo.type,
        value: _flag.flagInfo.value,
      },
      evidences: _flag.files,
      comment: _flag.comment,
    })):[],
  };

  const [flagsForReturn, setFlagsForReturn] = useState(flags);

  const [hasEdited, setHasEdited] = useState(false);

  const flagHasEdited = () => {
    return hasEdited;
  };
  useEffect(() => {
    setFlagsForReturn(flags);
    setHasEdited(false);
  }, [initialData]);

  // merge One into Two, combine the same key, add key not in Two
  const mergedFlags = {
    One: flags.One,
    Two: [
      ...flags.Two.map(flag => {
        const _flag = flags.One.find(_flag => _flag.key === flag.key && _flag.flag?.type === flag.flag?.type);
        if (!_flag) {
          return flag;
        }
        return {
          key: flag.key,
          flag: {
            type: flag.flag.type,
            value: flag.flag.value,
          },
          evidences: [...flag.evidences, ..._flag.evidences],
          comment: mergeString(flag.comment, _flag.comment),
        };
      }),
      ...flags.One.filter(
        flag => !flags.Two.some(_flag => _flag.key === flag.key && _flag.flag?.type === flag.flag?.type)
      ).map(flag => ({
        key: flag.key,
        flag: {
          type: flag.flag.type,
          value: flag.flag.value,
        },
        evidences: flag.evidences,
        comment: flag.comment,
      })),
    ],
  };

  const mergeFlag = () => {
    setFlagsForReturn(mergedFlags);
    setHasEdited(true);
  };

  const unMergeFlag = () => {
    setFlagsForReturn(flags);
    setHasEdited(false);
  };

  const setFlagData = (key, field, value) => {
    setFlagsForReturn({
      ...flagsForReturn,
      Two: flagsForReturn.Two.map(flag => {
        if (flag.key === key) {
          flag[field] = value;
        }
        return flag;
      }),
    });
    setHasEdited(true);
  };

  return [flagsForReturn, mergeFlag, unMergeFlag, setFlagData, flagHasEdited];
};

import React from 'react';
import CreateGEContainer from './CreateGEContainer';
import { useState } from 'react';
import { SIModal } from '@/modules/Modal';
import { Box, Divider, Stack, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';


/**
 * @param {import("./CreatGEPage").CreateGEPageProps} props
 * @returns
 */
function CreateGEPage(props) {
  const { open, handleCreateGEPageClose, isLead } = props;

  return (
    
    <CreateGEContainer
      open={open}
      handleCreateGEPageClose={handleCreateGEPageClose}
      isLead={isLead}
    />
    
  );
}
export default CreateGEPage;

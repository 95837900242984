import {axios_instance} from "@/utils/axios_instance";
import {GET_EMAIL_HISTORY, GET_GE_EDITING_HISTORY, GET_SIP_EDITING_HISTORY} from "@/actions/SIMT-SIP/SIP/SIPAction";

export const getSIPEditingHistory = (sipCode, pageNum, pageSize) => {
    return async dispatch => {
        try {
            const res = await axios_instance.get(`/api/v1/sip-service/special-issue-proposals/${sipCode}/edit-history`, {
                params: {
                    pageNum: pageNum,
                    pageSize: pageSize,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const{code,data} = res.data;

            if (code === 200) {
                dispatch({type: GET_SIP_EDITING_HISTORY, data: data});
            } else {
                dispatch({type: GET_SIP_EDITING_HISTORY, data: null});
            }
        } catch (err) {
            dispatch({type: GET_SIP_EDITING_HISTORY, data: null});
        }
    };
};
export const getGEEditingHistory = (sipCode, pageNum, pageSize) => {
    return async dispatch => {
        try {
            const res = await axios_instance.get(`/api/v1/sip-service/special-issue-proposals/${sipCode}/ge-edit-history`, {
                params: {
                    pageNum: pageNum,
                    pageSize: pageSize,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const{code,data} = res.data;

            if (code === 200) {
                dispatch({type: GET_GE_EDITING_HISTORY, data: data});
            } else {
                dispatch({type: GET_GE_EDITING_HISTORY, data: null});
            }
        } catch (err) {
            dispatch({type: GET_GE_EDITING_HISTORY, data: null});
        }
    };
};
export const getEmailHistory = (sipCode, pageNum, pageSize) => {
    return async dispatch => {
        try {
            const res = await axios_instance.get(`/api/v1/sip-service/special-issue-proposals/${sipCode}/emails/sending-history`, {
                params: {
                    pageNum: pageNum,
                    pageSize: pageSize,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const{code,data} = res.data;
            if (code === 200) {
                dispatch({type: GET_EMAIL_HISTORY, data: data});
            } else {
                dispatch({type: GET_EMAIL_HISTORY, data: null});
            }
        } catch (err) {
            dispatch({type: GET_EMAIL_HISTORY, data: null});
        }
    };
};
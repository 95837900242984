import { Box, Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import react, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSystemDataSize,
  exportSystemData,
  GET_SYSTEMDATA_SIZE,
} from '@/actions/SIMT-SI/Export/ExportDataAction';
import { UntitledAlertBox } from '@/components/Alert/AlertBox';
import { setPermission } from '@/actions/SIMT-User/Role/RoleManageAction';
import {
  SelectInput,
  MulSelectInput,
} from '@/componentsphase2/SelectBox/SelectInput';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import {PERMISSIONS} from "@/constant/permission";
export default function SystemData() {
  const [value, setValue] = useState(null);
  const [hasChange,setHasChange] = useState(false);
  const [lastSize,setLastSize] = useState(0);
  const [optionListBase,setOptionListBase] = useState([]);
  const [optionList,setOptionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { systemDataSize } = useSelector(state => {
    return {
      systemDataSize: state.MarketExport.systemDataSize,
    };
  });

  useEffect(() => {
    let optionList = [];
    let userPermissions = localStorage.getItem('userPermissions');
    if(userPermissions?.indexOf(PERMISSIONS.ADMIN_WRITE)!==-1) {
      optionList.push({id: 0, value: 'SI Overview', show: '  SI Overview' });
      optionList.push({ id: 1, value: 'Solicited SIP Overview', show: '  Solicited SIP Overview' });
      optionList.push({ id: 2, value: 'Unsolicited SIP Overview', show: '  Unsolicited SIP Overview' });
      optionList.push({ id: 3, value: 'GE Overview', show: '  GE Overview' });
    }else{
      if(userPermissions?.indexOf(PERMISSIONS.SI_EXPORT)!==-1) optionList.push({id: 0, value: 'SI Overview', show: '  SI Overview' });
      if(userPermissions?.indexOf('SIP:export')!==-1) {
        optionList.push({ id: 1, value: 'Solicited SIP Overview', show: '  Solicited SIP Overview' });
        optionList.push({ id: 2, value: 'Unsolicited SIP Overview', show: '  Unsolicited SIP Overview' });
      }
      if(userPermissions?.indexOf(PERMISSIONS.GE_EXPORT)!==-1) optionList.push({ id: 3, value: 'GE Overview', show: '  GE Overview' });
    }
    setOptionListBase(optionList);
    setOptionList(optionList);
  }, []);

  useEffect(() => {
    if (value !== null && value!==undefined){
      dispatch({ type: GET_SYSTEMDATA_SIZE, data: 0 });
      dispatch(getSystemDataSize(value.id));
      setHasChange(true);
    }
  }, [value]);

  useEffect(() => {
    if (lastSize !== systemDataSize) {
      setLastSize(systemDataSize);
      setHasChange(false);
    }
  }, [systemDataSize]);

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }
  const exportData = () => {
    if (value !== null && value !== undefined) {
      setLoading(true);
      async function fetchData() {
        if (systemDataSize > 1000) {
          let n = parseInt(systemDataSize / 1000);
          for (let i = 1; i <= n; i++) {
            await dispatch(exportSystemData(value.id, 1000 * (i - 1), 1000 * i));
          }
          await dispatch(exportSystemData(value.id, 1000 * n, systemDataSize));
          await delay(500);
        } else {
          await dispatch(exportSystemData(value.id, 0, systemDataSize));
          await delay(500);
        }
        setLoading(false);
        // await dispatch(exportCatJournalExcel(selectedList));
      }
      fetchData();
    } else {
      dispatch(
        setSnackbarMessageAndOpen(
          'please choose a page at first',
          {},
          'warning'
        )
      );
    }
  };

  const filterOptionList = (name = '') => {
    setOptionList(optionListBase.filter(op => op.value.includes(name) ));
  };

  return (
    <Box
      sx={{
        width: '753px',
        height: '452px',
        background: '#FFFFFF',
      }}
    >
      <Stack
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing='12px'
      >
        <Stack
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing='16px'
        >
          <Box
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '22px',
              letterSpacing: '0.01em',
              color: '#262E35',
            }}
          >
            Select data
          </Box>
          <Box
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '12px',
              fontWeight: 600,
              lineHeight: '16.34px',
              letterSpacing: '0em',
              color: '#596A7C',
            }}
          >
            System page:
          </Box>
        </Stack>
        <Stack
          direction='column'
          justifyContent='space-between'
          alignItems='flex-start'
          sx={{
            height: '386px',
          }}
        >
          <MulSelectInput
            id='ExportMarketingDataSelect'
            multiple={false}
            value={value}
            defaultOption={key => ({
              id: 0,
              value: key,
            })}
            getOptionLabel={option => option.show}
            loadOptionAction={filterOptionList}
            options={optionList}
            onValueChange={value => {
              setValue(value);
            }}
            showPopupIcon={true}
            disableCheckBox={true}
            disableClearIcon={true}
            placeholder={'Please choose a page to export all relevant data.'}
            noAddSelectItem={true}
          />
          <Stack
            direction='column'
            alignItems='center'
            spacing='4px'
            sx={{ width: '753px' }}
          >
            <Box
              sx={{
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '22px',
                letterSpacing: '0em',
                color: '#98A7B6',
              }}
            >
              {value!==null && systemDataSize !== 0 ?
                hasChange? '-- data are selected':`${systemDataSize} data are selected`
                :''}
            </Box>
            <LoadingButton
              loading={loading}
              disabled={loading}
              disableRipple
              onClick={exportData}
              sx={{
                width: '617px',
                height: '50px',
                padding: '14px 20px 14px 20px',
                borderRadius: '5px',
                background: '#0052CC',
                color: '#FFFFFF',
                fontFamily: 'Inter',
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '22px',
                letterSpacing: '0em',
                textTransform: 'none',
                '&:hover': {
                  background: '#6D98EE',
                },
                '&:active': {
                  background: '#113D95',
                },
              }}
            >
              Export
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

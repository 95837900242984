import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { selectCatJournalFilterItem } from '../../../../../../actions/SIMT-SI/CatJournal/CATJournalAction';
import { getSearchResultDisplayContent } from './JournalFilterDisplayContent';

/**
 *
 * @param {import("./JournalFilterConstantListDisplay").JournalFilterConstantListDisplayProps} props
 * @returns
 */
function JournalFilterConstantListDisplay(props) {
  const {
    name,
    data,
    selected,
    selectObjectKey,
    displayFunction,
    multiselect = true,
  } = props;

  const dispatch = useDispatch();

  /**
   * 通用点击事件实现
   * @param {import("./JournalFilterConstantListDisplay").JournalFilterDataType | string} item  点击的那个item
   */
  function handleClick(item) {
    // console.log("name = select object = ", name, item);

    let selectObject = getValueStringByObjectKey(selectObjectKey, item);
    if (multiselect) {
      // 多选情况
      if (selected.indexOf(selectObject) > -1) {
        dispatch(selectCatJournalFilterItem(name, selectObject, 'unselect'));
      } else {
        dispatch(selectCatJournalFilterItem(name, selectObject, 'select'));
      }
    } else {
      // 单选情况
      if (selected.indexOf(selectObject) > -1) {
        // 取消选择，类似多选的取消
        dispatch(selectCatJournalFilterItem(name, selectObject, 'unselect'));
      } else {
        // 使用replace
        dispatch(selectCatJournalFilterItem(name, selectObject, 'replace'));
      }
    }
  }

  /**
   * 判断某个item是否已选择
   * @param {import("./JournalFilterConstantListDisplay").JournalFilterDataType | string} item
   * @return {boolean}  如果已选，则返回true，否则false
   */
  function determineIsSelected(item) {
    const selectObject = getValueStringByObjectKey(selectObjectKey, item);
    return selected.indexOf(selectObject) > -1;
  }

  return (
    <Box
      sx={{
        height: '100%',
        overflowY: 'auto',
        color: '#484848',
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: 400,
        padding: '2px 0 2px 3px',
        fontFamily: 'Open Sans',
      }}
      data-selenium-id='CATJournal_Overview-Table-SearchRow-filterComponents-ConstantListDisplay-Box'
    >
      {getSearchResultDisplayContent(
        name,
        data,
        false,
        handleClick,
        selected,
        determineIsSelected,
        displayFunction
      )}
    </Box>
  );
}

/**
 * @type {import("./JournalFilterConstantListDisplay").GetJournalDataValueByObjectKey}
 */
export const getValueStringByObjectKey = (objectKey, data) => {
  if (objectKey == 'self') return `${data}`;
  if (typeof data === 'string') return data;
  return `${data[objectKey]}`;
};

JournalFilterConstantListDisplay.propTypes = {
  name: PropTypes.string.isRequired, // 这个filter的名字，例如journalCode, revenueModel
  data: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  selectObjectKey: PropTypes.string.isRequired,
  multiselect: PropTypes.bool,
  displayFunction: PropTypes.func.isRequired,
};

export default JournalFilterConstantListDisplay;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Tooltip,
  Stack,
  IconButton,
  Typography,
  InputBase,
  CircularProgress,
} from '@mui/material';
// import EditSIDetailTitleIcon from '@/assets/EditSIDetailTitleIcon.svg';
import EditSIDetailnewIcon from '@/assets/EditSIDetailnewIcon.svg';
import EditSIDetailnewHover from '@/assets/EditSIDetailnewHover.svg';
import EditSIDetailTitleCancelIcon from '@/assets/EditSIDetailTitleCancelIcon.svg';
import EditSIDetailTitleSubmitIcon from '@/assets/EditSIDetailTitleSubmitIcon.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
  permissionIsAdmin,
  permissionSiWrite,
} from '../../../utils/authTokenHandler';
import { trimItemFilds } from '../../../utils/commonUtils';
// import EditSIDetailnewIcon from '@/assets/EditSIDetailnewIcon.svg';
// import EditSIDetailnewHover from '@/assets/EditSIDetailnewHover.svg';
import styleModule from './GeneralInfoSection.module.scss';
import {getSIDetail} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueDetail";
import {SITitleEdit} from "@/actions/SIMT-SI/SpecialIssue/SiEditDetail";
import { UntitledAlertBox } from '@/components/Alert/AlertBox';
const StagePartTitle = props => {
  const { children } = props;
  return (
    <Typography
      component='h4'
      sx={{
        font: 'Regular Bold',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '22px',
        // color: '#596A7C',
        color: '#154AB6'
      }}
      data-selenium-id='SIDetail_TitleBlock-Typography'
    >
      {children}
    </Typography>
  );
};

const SITitleBlock = props => {
  const { siCode, loadingData } = props;
  const [titleOverflowErr, setTitleOverflowErr] = useState(false);

  const selector = state => {
    return {
      detailInfo: state.SI.detailInfo,
    };
  };
  const { detailInfo } = useSelector(selector);

  const dispatch = useDispatch();

  const [editTitleMode, setEditTitleMode] = useState(false);
  const [titleValue, setTitleValue] = useState(
    detailInfo.generalInformation === undefined
      ? 'SI Title'
      : detailInfo.generalInformation[0].specialIssueTitle
  );
  const [isSavingTitle, setIsSavingTitle] = useState(false);
  const [titleEditValue, setTitleEditValue] = useState(titleValue);

  const handleTitleChange = value => {
    var regu = '^[ ]+$';
    if (new RegExp(regu).test(value)) {
      value = '';
    }
    setTitleEditValue(value);
  };

  useEffect(() => {
    setTitleEditValue(titleValue);
  }, [titleValue, editTitleMode]);

  useEffect(() => {
    setTitleValue(
      detailInfo.generalInformation === undefined
        ? 'SI Title'
        : detailInfo.generalInformation[0].specialIssueTitle
    );
  }, [detailInfo]);

  const handleSubmitTitle = async () => {
    if (detailInfo.generalInformation === undefined) return;
    if (isSavingTitle) return;
    if(titleEditValue?.length > 400) {
      setTitleOverflowErr(true);
      return;
    }
    setIsSavingTitle(true);
    try {
      await dispatch(SITitleEdit(siCode, trimItemFilds(titleEditValue)));
      await dispatch(getSIDetail(siCode));
    } catch (e) {
      console.error(e);
    }
    setEditTitleMode(false);
    setIsSavingTitle(false);
  };

  const handleEditClick = () => {
    setIsHovered(!isHovered);
  };
  

  const [isHovered, setIsHovered] = useState(false);


  return (
    <>
       <Stack
      spacing={1}
      maxWidth='calc(100% - 120px)'
      sx={{
        flexGrow: 1,
      }}
      data-selenium-id='SIDetail_TitleBlock-StackContainer'
    >
      {!editTitleMode ? (
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          data-selenium-id='SIDetail_Stack1'
        >
          <Tooltip title={titleValue} data-selenium-id='SIDetail_TitleTooltip'>
            <Box  data-selenium-id='SIDetail_Title'
              sx={{
                display: '-webkit-box',
                '-webkit-line-clamp': titleValue.length>50?'2':'1',
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
                width: '634px',
                maxWidth: '100%',
                width: 'auto',
                height: 'auto',
                fontSize: '24px',
                fontFamily: 'Open Sans',
                
                wordBreak: 'break-word',
                textOverflow: 'ellipsis',}}
              // className={styleModule.detailTitle}
              // className={styleModule.detailTitle}
            >
              {titleValue}
            </Box>
          </Tooltip>
          {/* 添加编辑按钮 */}
          {!props.ifShelved && (permissionIsAdmin() || permissionSiWrite()) ? (
            !loadingData && (
              <IconButton
                onClick={() =>{ setEditTitleMode(true);handleEditClick();}}
                // onClick={}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                data-selenium-id='SIDetail_TitleBlock-NotShelvedEditBtn'
                className={styleModule.NotShelvedEditBtn}
              >
                {isHovered ? (
                  <EditSIDetailnewHover
                    style={{ width: '20px', height: '20px' }}
                    data-selenium-id='SIDetail_TitleBlock-EditBtnIcon'
                  />
                ) : (
                  <EditSIDetailnewIcon
                    style={{ width: '20px', height: '20px' }}
                    data-selenium-id='SIDetail_TitleBlock-EditBtnIcon'
                  />
                )}
                {/* <div
                onClick={handleEditClick}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                {hover ? (
                  <EditSIDetailnewHover data-selenium-id='SIDetail_SubmissionOverviewBlock-DefaultEditBtnIcon'
                    style={{ width: '12.75px', height: '12.75px' }} />
                ) : (
                  <EditSIDetailnewIcon data-selenium-id='SIDetail_SubmissionOverviewBlock-HoverEditBtnIcon' 
                    style={{ width: '12.75px', height: '12.75px' }}
                  />
                )}
              </div> */}
              </IconButton>
            )
          ) : (
            <></>
          )}
        </Stack>
      ) : (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent={editTitleMode ? 'space-between' : 'flex-start'}
          spacing={1}
          data-selenium-id='SIDetail_Stack2'
        >
          <InputBase
            sx={{
              // border: titleEditValue?.length > 400? '2px solid #EE5350': '1px solid #DFE4E8',
              border: '1px solid #DFE4E8',
              borderRadius: '4px',
              // padding: "8px 12px",
              flexGrow: 1,
              height: '40px',
              padding: '0px 12px',
              fontSize: '24px',
              color: '#262E35',
              '& .MuiInputBase-input': {
                padding: 0,
              },
            }}
            value={titleEditValue}
            onChange={e => handleTitleChange(e.target.value)}
            data-selenium-id='SIDetail_TitleBlock-InputBase'
          />
          {/* 添加编辑按钮 */}
          <Box data-selenium-id='SIDetail_TitleBlock-BtnBox'>
            <IconButton
              onClick={() => {
                setEditTitleMode(false);
                setTitleEditValue(titleValue);
              }}
              data-selenium-id='SIDetail_TitleBlock-EditBtn'
            >
              <EditSIDetailTitleCancelIcon
                style={{ width: '32px', height: '32px' }}
                data-selenium-id='SIDetail_TitleBlock-EditBtnIcon'
              />
            </IconButton>
            {titleEditValue === '' ||
            titleEditValue === null ||
            titleEditValue === undefined ? (
                <></>
              ) : (
                <IconButton
                  onClick={handleSubmitTitle}
                  disabled={
                    titleEditValue === '' ||
                  titleEditValue === null ||
                  titleEditValue === undefined
                  }
                  data-selenium-id='SIDetail_TitleBlock-SubmitBtn'
                >
                  {!isSavingTitle ? (
                    <EditSIDetailTitleSubmitIcon
                      style={{ width: '32px', height: '32px' }}
                      data-selenium-id='SIDetail_TitleBox-SubmitIcon'
                    />
                  ) : (
                    <CircularProgress
                      size='32px'
                      data-selenium-id='SIDetail_TitleBox-CircularProgress'
                    />
                  )}
                </IconButton>
              )}
          </Box>
        </Stack>
      )}

      {/* TODO 用来添加Stage */}
      <StagePartTitle data-selenium-id='SIDetail_StagePartTitle'>
        {detailInfo.generalInformation === undefined
          ? ' '
          : detailInfo.generalInformation[0].stage}
      </StagePartTitle>
    </Stack>
    <UntitledAlertBox
      open={titleOverflowErr}
      handleClose={()=>{setTitleOverflowErr(false);}}
      message='The maximum length for SI Title is 400 characters.'
      severity ='warning'
    >
    </UntitledAlertBox>
    </>
   
  );
};

export default SITitleBlock;

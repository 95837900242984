/**
 * 用于对返回的JWT进行处理，获取信息
 */
import {decode} from 'base-64';
import {PERMISSIONS} from "@/constant/permission";

const base64Urldecode = function (input) {
  // Replace non-url compatible chars with base64 standard chars
  input = input.replace(/-/g, '+').replace(/_/g, '/');

  // Pad out with standard base64 required padding characters
  var pad = input.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error(
        'InvalidLengthError: Input base64url string is the wrong length to determine padding'
      );
    }
    input += new Array(5 - pad).join('=');
  }

  return input;
};

export function getAuth(authToken) {
  const authArr = authToken.split('.');
  const info = JSON.parse(decode(base64Urldecode(authArr[1])));
  return info.authorities[0];
}

//获取角色权限列表
export function getPermissionList(authToken) {
  if (authToken !== null) {
    const authArr = authToken.split('.');
    const info = JSON.parse(decode(base64Urldecode(authArr[1])));
    return info.authorities;
  }
  return [];
}

export function getConnectPayload(authToken) {
  if (authToken !== null && authToken!== undefined) {
    const authArr = authToken.split('.');
    return JSON.parse(decode(base64Urldecode(authArr[1])));
  }
  return null;
}

//判断角色是否有admin:write权限
export function permissionIsAdmin() {
  let authList = getPermissionList(localStorage.getItem('authorization'));
  if (
    authList !== null &&
    authList.length !== undefined &&
    authList.indexOf(PERMISSIONS.ADMIN_WRITE) !== -1
  ) {
    return true;
  } else {
    return false;
  }
}

//判断角色是否有si:write权限
export function permissionSiWrite() {
  let authList = getPermissionList(localStorage.getItem('authorization'));
  if (
    authList !== null &&
    authList.length !== undefined &&
    authList.indexOf(PERMISSIONS.SI_WRITE) !== -1
  ) {
    return true;
  } else {
    return false;
  }
}

//判断角色是否有si:readlimited权限
export function permissionSiReadLimited() {
  let authList = getPermissionList(localStorage.getItem('authorization'));
  if (
    authList !== null &&
    authList.length !== undefined &&
    authList.indexOf(PERMISSIONS.SI_READ_LIMITED) !== -1
  ) {
    return true;
  } else {
    return false;
  }
}


export function permissionScreener() {
  let authList = getPermissionList(localStorage.getItem('authorization'));
  if (
    authList !== null &&
    authList.length !== undefined &&
    authList.indexOf(PERMISSIONS.SIP_WRITE_SCREENER) !== -1
  ) {
    return true;
  } else {
    return false;
  }
}
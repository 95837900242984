import { Box } from '@mui/material';

export default function AlertBody(props) {
  return (
    <Box
      sx={{
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '17px',
        letterSpacing: '0em',
        textAlign: 'left',
        color:'#596A7C',
        margin:typeof props.children==='undefined'?'':'12px 0px 0px'
      }}
    >
      {props.children}
    </Box>
  );
}

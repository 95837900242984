import {UiComponent} from "@/components/Form/form.d";
import {LOAD_COMPONENTS_BY_AREA} from "@/actions/UiComponentAction";

const initState: {
    [area: string]: UiComponent<any>[];
} = {

};

export const UiComponentReducer = (state = initState, action: {
    type: string;
    data: any;
}) => {
    const { type, data } = action;
    switch (type){
        case LOAD_COMPONENTS_BY_AREA:
            const {area, components} = data;
            return {
                ...state,
                [area]: components,
            };
        default:
            return state;
    }
};
import '../../../App.css';
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Popover,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import UserTable from './UserTable';
import CreateNewUser from './createUser';
import { useDispatch, useSelector } from 'react-redux';
import UserBatchUpdate from './BatchUpdateConfirm';
import { NewDesignedMain } from '../../../components/Main';
import SearchIcon from '@mui/icons-material/Search';
import { unstable_batchedUpdates } from 'react-dom';
import {
  ResponsiveButton,
  SIMTButton,
} from '../../../components/StyledButton/SIMTButton';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { cancelSelectAllUser } from '@/actions/SeleteAction';
import UserManagementResetIcon from '@/assets/UserManagementResetIcon.svg';
import UserManagementBatchUpdateIcon from '@/assets/bulkTransfer.svg';
import DropdownIcon from '@/assets/DropDownIconGray.svg';
import DropdownIconBlack from '@/assets/dropdownIcon.svg';
import UserManagementSearchIcon from '@/assets/UserManagementSearchIcon.svg';
import { DelaySearchTextField } from '../../../components/MyTextField';
import { FormattedMessage, useIntl } from 'react-intl';
import { setSnackbarMessageAndOpen } from '../../../actions/SnackbarAction';
import { SEVERITIES } from '../../../modules/ConfirmSnackBar/CommonSnackBar';
import { max } from 'date-fns';
import { getRoleInfo } from "@/actions/SIMT-User/Role";
import { getAllUserInfoByUserService } from "@/actions/SIMT-User/User/SystemAdmin";
import { exportUM } from "@/actions/SIMT-User/User/Export";
import { loadGroups, loadUserStatus } from "@/actions/SIMT-User/Config";
import { getAllUserInfoIds } from '@/actions/SIMT-User/User/SystemAdmin';

const HeaderSelect = props => {
  const {
    value = '',
    onChange,
    itemList,
    onOpen,
    name = '',
    renderOption,
    minWidth = '80px',
    maxWidth = '300px',
  } = props;

  return (
    <FormControl
      sx={{
        boxSizing: 'border-box',
        minWidth: minWidth,
        maxWidth: maxWidth,
        '& .MuiInputBase-root': {
          height: '32px',
          backgroundColor: '#F1F3F5',
        },
        '& .MuiSelect-select.MuiInputBase-input': {
          padding: '0 30px 0 12px',
          boxSizing: 'border-box',
          fontSize: '14px',
          color: '#596A7C',
        },
        '& fieldset': {
          border: 'none',
        },
      }}
      data-seleunim-id='SystemAdmin_UserManagement-Index-FormControl'
    >
      {/* <InputLabel>Role</InputLabel> */}
      <Select
        id={`systemAdminUserManagement${name}Select`}
        data-selenium-id={`SystemAdminUserManagement${name}Select`}
        value={value}
        onChange={onChange}
        onOpen={onOpen}
        displayEmpty
        renderValue={value !== '' ? undefined : () => <>{name}</>}
        IconComponent={DropdownIcon}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: '50vh',
              maxWidth: maxWidth,
            },
          },
        }}
      >
        <MenuItem
          id={`systemAdminUserManagement${name}999`}
          data-selenium-id={`SystemAdmin-UserManagement${name}999`}
          key={`${name}-999`}
          value={''}
        >
          All
        </MenuItem>
        {itemList.map(renderOption)}
      </Select>
    </FormControl>
  );
};

const vavidValue = (/** @type {string | number | boolean | null | undefined} */ v) => v !== undefined && v !== null && v !== '';
function UserManagement() {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    result: [],
    count: 0,
  });
  const [Page, setPage] = useState(1);
  const [perPageNumber, setPerPageNumber] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('fullName');

  const [Open, setOpen] = useState(false); //CreateUser弹窗的开关
  const open = useSelector(state => state.UserManage.siderOpen);
  const confirmBarOpen = useSelector(state => state.Alert.open);
  const [username, setUsername] = useState('');
  const [role, setRole] = useState();
  const [group, setGroup] = useState();
  const [accStatus, setAccStatus] = useState('');
  const statusList = ['Active', 'Inactive'];
  const [roleList, setRoleList] = useState([]);
  const [updateData, setUpdateData] = useState(false);
  const [batchUpdateOpen, setBatchUpdateOpen] = useState(false); //BatchUpdate弹窗的开关
  const [batchPopoverOpen, setBatchPopoverOpen] = useState(false); //BatchUpdate弹窗提升的开关
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [batchUpdateType, setbatchUpdateType] = useState(false);
  const [exportMsgOpen, setExportMsgOpen] = React.useState(false);
  const [responsiveButtonDownByValue, setResponsiveButtonDownByValue] =
    useState(1015);
  const exportMenuOpen = !exportMsgOpen && Boolean(anchorEl2);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const intl = useIntl();

  const selector = state => {
    return {
      UserInfo: state.User.userInfo,
      allRoles: state.Role.roleInfo,
      selected: state.Selete.selectedUserList,
      isAllSelected: state.Selete.isAllUserSeleted,
      groupList: state.SystemConfig.groupList,
      userStatusList: state.SystemConfig.userStatusList,
      createUserResult: state.User.createUserResult,
      updateUserResult: state.User.updateUserResult,
      InactiveUserResult: state.User.inactiveUser,
      unlock: state.User.unlockUser,
      userResult: state.User.editUserResult,
    };
  };
  const {
    UserInfo,
    allRoles,
    selected,
    groupList,
    userStatusList,
    createUserResult,
    updateUserResult,
    InactiveUserResult,
    unlock,
    userResult,
  } = useSelector(selector);

  const handleUserInfo = async () => {
    // await dispatch(
    //   getAllUserInfo(
    //     Page,
    //     perPageNumber,
    //     username,
    //     role,
    //     group,
    //     accStatus,
    //     order,
    //     orderBy
    //   )
    // );
    const props = {
      pageNum: Page,
      pageSize: perPageNumber,
      ...(username && { fullName: username }),
      ...(role ? { roleId: role } : {}),
      group,
      ...((Object.prototype.toString.call(accStatus) === '[object Number]' && accStatus.constructor === Number && typeof accStatus === 'number') && { active: Boolean(accStatus) }),
      orderField: orderBy === 'fullName' ? 'username' : orderBy,
      orderBy: order
    };
    for (const i in props) {
      //@ts-expect-error
      if ((Reflect.has(props, i) && i in props && props.hasOwnProperty(i) && Object.prototype.hasOwnProperty.call(props, i) && Object.hasOwn(props, i) && props.propertyIsEnumerable(i) && Object.keys(props).includes(i) && Object.getOwnPropertyDescriptor(props, i) && Reflect.get(props, i) && Reflect.getOwnPropertyDescriptor(props, i)) && !vavidValue(props?.[i]) && !vavidValue(Object.getOwnPropertyDescriptor(props, i)?.value) && !vavidValue(Reflect.getOwnPropertyDescriptor(props, i)?.value) && !vavidValue(Reflect.get(props, i))) {
        //@ts-expect-error
        delete props[i];
        Reflect.deleteProperty(props, i);
      }

    }

    await getAllUserInfoByUserService(
      Object.fromEntries(Object.entries(props).filter(([_, v]) => vavidValue(v)))
      // .reduce((accumulator, currentValue) => {
      //   //@ts-expect-error
      //   accumulator[currentValue[0]] = currentValue[1]; // 设置到对象的属性上
      //   return accumulator;
      // }, {})
    )(dispatch);

    dispatch(getAllUserInfoIds(username, role, group, accStatus));
  };

  useEffect(() => {
    dispatch(loadGroups());
    dispatch(loadUserStatus());
  }, [dispatch]);

  useEffect(() => {
    async function fetchData() {
      const result = UserInfo;
      if (result != null) {
        setUserData(result);
      }
    }

    fetchData();
  }, [dispatch, UserInfo]);

  useEffect(() => {
    if (confirmBarOpen) {
      unstable_batchedUpdates(() => {
        setOrderBy('updateTime');
        setOrder('desc');
        setPage(0);
      });
    }
  }, [confirmBarOpen]);

  function asyncLoadUserData() {
    async function fetchData() {
      setLoadingUserData(true);
      await handleUserInfo();
      setLoadingUserData(false);
    }

    if (Page === 0) {
      setTimeout(() => {
        setPage(1);
      }, 100);
    } else {
      fetchData();
    }
  }

  useEffect(() => {
    asyncLoadUserData();
  }, [
    dispatch,
    Page,
    perPageNumber,
    username,
    role,
    group,
    accStatus,
    order,
    orderBy,
  ]);

  useEffect(() => {
    if (createUserResult === 'true') {
      unstable_batchedUpdates(() => {
        setPage(0);
        setOrderBy('updateTime');
        setOrder('desc');
      });
    }
  }, [createUserResult]);

  useEffect(() => {
    if (userResult === 'true' || userResult === true) {
      unstable_batchedUpdates(() => {
        setPage(0);
        setOrderBy('updateTime');
        setOrder('desc');
      });
    }
  }, [userResult]);

  useEffect(() => {
    if (updateUserResult === 'true') {
      unstable_batchedUpdates(() => {
        setPage(0);
        setOrderBy('updateTime');
        setOrder('desc');
      });
    }
  }, [updateUserResult]);

  useEffect(() => {
    if (InactiveUserResult === 'true') {
      unstable_batchedUpdates(() => {
        setPage(0);
        setOrderBy('updateTime');
        setOrder('desc');
      });
    }
  }, [InactiveUserResult]);

  useEffect(() => {
    if (unlock === 'true') {
      unstable_batchedUpdates(() => {
        setPage(0);
        setOrderBy('updateTime');
        setOrder('desc');
      });
    }
  }, [unlock]);

  useEffect(() => {
    if (open) setResponsiveButtonDownByValue(1250);
    else setResponsiveButtonDownByValue(1015);
  }, [open]);

  const handlePerPageChange = e => {
    setPerPageNumber(e.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleBatchUpdateClose = () => {
    setBatchUpdateOpen(false);
  };

  const handleBatchUpdateOpen = event => {
    if (selected.length === 0) {
      dispatch(
        setSnackbarMessageAndOpen(
          'Please select at least one User to export.',
          {},
          SEVERITIES.warning
        )
      );
      return;
    }
    setAnchorEl(event.currentTarget);
    setBatchPopoverOpen(true);
  };

  const handleBatchPopoverClose = () => {
    setAnchorEl(null);
    setBatchPopoverOpen(false);
  };

  const handleNewUser = () => {
    setUpdateData(!updateData);
    setOpen(true);
  };

  const handleChangeUsername = newUsername => {
    unstable_batchedUpdates(() => {
      dispatch(cancelSelectAllUser());
      setUsername(newUsername);
      setPage(1);
      setOrder('asc');
      setOrderBy('fullName');
    });
  };

  const handleChangeRole = e => {
    unstable_batchedUpdates(() => {
      dispatch(cancelSelectAllUser());
      setRole(e.target.value);
      setPage(1);
      setOrder('asc');
      setOrderBy('role');
    });
  };

  const handleChangeGroup = e => {
    unstable_batchedUpdates(() => {
      dispatch(cancelSelectAllUser());
      setGroup(e.target.value);
      setPage(1);
      setOrder('asc');
      setOrderBy('group');
    });
  };

  const handleChangeStatus = e => {
    unstable_batchedUpdates(() => {
      dispatch(cancelSelectAllUser());
      setPage(1);
      setAccStatus(e.target.value);
      setOrder('asc');
      setOrderBy('fullName');
    });
  };

  const handleReset = () => {
    const event = {
      target: {
        value: '',
      },
    };
    handleChangeRole(event);
    handleChangeGroup(event);
    handleChangeStatus(event);
    handleChangeUsername('');
  };

  const handleGroupUpdate = () => {
    setbatchUpdateType('group');
    setBatchUpdateOpen(true);
    setBatchPopoverOpen(false);
  };

  const handleRoleUpdate = () => {
    setbatchUpdateType('role');
    setBatchUpdateOpen(true);
    setBatchPopoverOpen(false);
  };

  const fetchRoleData = () => {
    dispatch(getRoleInfo(0, 0, 'roleName', 'asc'));
  };

  // ---------- export 相关方法 -------------
  const handleExportMenuClick = event => {
    if (selected.length === 0) {
      // setExportMsgOpen(true); old logic
      dispatch(
        setSnackbarMessageAndOpen(
          'Please select at least one User to export.',
          {},
          SEVERITIES.warning
        )
      );
      return;
    }
    setAnchorEl2(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setExportMsgOpen(false);
    setAnchorEl2(null);
  };

  const handleExportMenuClose = () => {
    setAnchorEl2(null);
  };

  const handleExportExcel = () => {
    async function fetchData() {
      await dispatch(exportUM(selected, 'xls'));
    }

    fetchData();
    setAnchorEl2(null);
  };

  const handleExportCsv = () => {
    async function fetchData() {
      await dispatch(exportUM(selected, 'csv'));
    }

    fetchData();
    setAnchorEl2(null);
  };
  // ---------- export 相关方法 -------------

  useEffect(() => {
    if (allRoles != null && allRoles.result !== undefined) {
      setRoleList(
        allRoles.result.filter((role, index) => {
          return role.roleName !== 'Admin' && role.roleName !== 'SystemAdmin';
        })
      );
    }
  }, [allRoles]);

  const minWidth850 = useMediaQuery('(min-width: 850px)');
  const minWidth650 = useMediaQuery('(min-width: 650px)');

  // console.log(`[User Management] user status list = `, userStatusList);

  return (
    <NewDesignedMain
      data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain'
      open={open}
      sx={{
        paddingX: 3,
        paddingBottom: 2,
        '& .MuiTable-root': {
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          overflow: 'hidden',
          border: '1px solid #DFE4E8',
        },
        '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root': {
          paddingX: '16px',
          height: '42px',
          backgroundColor: '#F1F3F5',
          '& .MuiButtonBase-root.MuiTableSortLabel-root': {
            textTransform: 'uppercase',
            color: '#596A7C',
          },
        },
        '& .MuiTableCell-root.MuiTableCell-head': {
          '& > .MuiBox-root': {
            textTransform: 'uppercase',
            color: '#596A7C',
          },
        },
        '& .SIMTButton-responsive': {
          minWidth: 0,
        },
        '& .UserTable__Footer': {
          backgroundColor: '#f6f7f8',
          boxSizing: 'border-box',
        },
      }}
    >
      <Box data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box'>
        {/*两个弹出框*/}
        <CreateNewUser
          open={Open}
          setOpen={setOpen}
          updateData={updateData}
          data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-CreateNewUser'
        />
        <UserBatchUpdate
          data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-UserBatchUpdate'
          open={batchUpdateOpen}
          handleFirstClose={handleBatchUpdateClose}
          type={batchUpdateType}
        />
        {/*User表的标题和按钮*/}
        <Box
          id='systemAdminUserManagementTitleText'
          data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Box-SystemAdminUserManagementTitleText'
          sx={{
            color: '#262E35',
            fontWeight: 600,
            fontSize: '22px',
          }}
        >
          User Management
        </Box>
        <Stack
          data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Stack'
          direction={
            (open && minWidth850) || (!open && minWidth650) ? 'row' : 'column'
          }
          justifyContent='space-between'
          marginY={2}
          spacing={(open && minWidth850) || (!open && minWidth650) ? 0 : 1}
        >
          {/*Select 控件集*/}
          <Stack
            direction='row'
            alignItems='center'
            spacing={1}
            data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Stack-Stack1'
          >
            <DelaySearchTextField
              id='systemAdminUserManagementUsernameSearch'
              data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Stack-Stack1-DelaySearchTextField-SystemAdminUserManagementUsernameSearch'
              value={username}
              handleSearch={newUsername => handleChangeUsername(newUsername)}
              placeholder='Name'
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position='start'
                    data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Stack-Stack1-DelaySearchTextField-SystemAdminUserManagementUsernameSearch-InputAdornment'
                  >
                    <UserManagementSearchIcon data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Stack-Stack1-DelaySearchTextField-SystemAdminUserManagementUsernameSearch-InputAdornment-UserManagementSearchIcon' />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                sx: {
                  padding: 0,
                  '&::placeholder': {
                    color: '#596A7C',
                    opacity: 1,
                  },
                },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: '32px',
                  padding: 0,
                  paddingLeft: '12px',
                  backgroundColor: '#F6F7F8',
                },
                '& fieldset': {
                  border: '1px solid #DFE4E8',
                },
              }}
            />

            <HeaderSelect
              data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Stack-Stack1-HeaderSelect1'
              name='Role'
              value={role}
              onChange={handleChangeRole}
              onOpen={() => {
                fetchRoleData();
              }}
              itemList={roleList}
              minWidth='73px'
              renderOption={(value, index) => (
                <MenuItem
                  id={`systemAdminUserManagementRole${index}`}
                  data-selenium-id={`SystemAdmin-UserManagementRole-${index}`}
                  key={`role${index}`}
                  value={value.roleId}
                >
                  {value.roleName}
                </MenuItem>
              )}
            />

            <HeaderSelect
              data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Stack-Stack1-HeaderSelect2'
              name='Group'
              value={group}
              onChange={handleChangeGroup}
              onOpen={() => {
                dispatch(loadGroups());
              }}
              minWidth='86px'
              maxWidth='320px'
              itemList={groupList}
              renderOption={(item, index) => (
                <MenuItem
                  id={`systemAdminUserManagementGroup${index}`}
                  data-selenium-id={`SystemAdmin-UserManagementGroup-${index}`}
                  key={`systemAdminUserManagementGroup${index}`}
                  value={item.value}
                >
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {item.value}
                  </div>
                </MenuItem>
              )}
            />

            <HeaderSelect
              data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Stack-Stack1-HeaderSelect3'
              name='Status'
              value={accStatus}
              minWidth='85px'
              onChange={handleChangeStatus}
              itemList={userStatusList.length ? userStatusList : [{ value: 'Active', key: '001' }, { value: 'Inactive', key: '002' }]}
              renderOption={(item, index) => {
                const keyToActiveStatusNumber = {
                  '001': 1,
                  '002': 0,
                };
                return (
                  <MenuItem
                    id={`systemAdminUserManagementStatus${index}`}
                    data-selenium-id={`SystemAdmin-UserManagementStatus-${index}`}
                    key={`systemAdminUserManagementStatus-${item.key}`}
                    value={keyToActiveStatusNumber[item.key]}
                  >
                    {item.value}
                  </MenuItem>
                );
              }}
            />

            <ResponsiveButton
              data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Stack-Stack1-ResponsiveButton'
              variant='outlined'
              startIcon={<UserManagementResetIcon />}
              onClick={handleReset}
              downBy={responsiveButtonDownByValue}
              sx={{
                color: '#596A7C',
                padding: 0,
                height: '32px',
                paddingX: '12px',
                border: '1px solid #DFE4E8',
                '&:hover': {
                  backgroundColor: '#fff',
                },
                '&.MuiButtonBase-root.MuiButton-root': {
                  minWidth: '42px',
                },
              }}
            >
              Reset
            </ResponsiveButton>
          </Stack>
          <Stack
            data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Stack-Stack2'
            direction='row'
            spacing={1}
            justifyContent='flex-end'
            alignItems='center'
            sx={{
              marginLeft: '8px',
              '& .MuiButtonBase-root.MuiButton-root': {
                paddingY: 0,
                height: '32px',
              },
            }}
          >
            {/*导出按钮*/}
            <ResponsiveButton
              id='systemAdminUserManagementExportButton'
              data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Stack-Stack2-ResponsiveButton1-SystemAdminUserManagementExportButton'
              color='grayBlue'
              variant='contained'
              aria-controls={exportMenuOpen ? 'export-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={exportMenuOpen ? 'true' : undefined}
              onClick={handleExportMenuClick}
              startIcon={<SaveAltIcon style={{ color: '#10172a' }} />}
              endIcon={<DropdownIconBlack />}
              downBy={responsiveButtonDownByValue}
              sx={{
                background: '#fff',
                border: '1px solid #DFE4E8',
              }}
            >
              <FormattedMessage
                id='common.Export'
                data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Stack-Stack2-ResponsiveButton1-SystemAdminUserManagementExportButton-FormattedMessage-Export'
              />
            </ResponsiveButton>
            {/* Batch Update 按钮 */}
            <ResponsiveButton
              id='systemAdminUserManagementBatchUpdateButton'
              data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Stack-Stack2-ResponsiveButton2-SystemAdminUserManagementBatchUpdateButton'
              variant='contained'
              color='grayBlue'
              startIcon={<UserManagementBatchUpdateIcon />}
              onClick={handleBatchUpdateOpen}
              downBy={responsiveButtonDownByValue}
              sx={{
                // minWidth: "150px",
                background: '#fff',
                border: '1px solid #DFE4E8',
              }}
            >
              Batch Update
            </ResponsiveButton>
            <ResponsiveButton
              id='systemAdminUserManagementCreateUserButton'
              data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Stack-Stack2-ResponsiveButton3-SystemAdminUserManagementCreateUserButton'
              className='btnNewUser'
              variant='contained'
              color='darkBlue'
              onClick={handleNewUser}
              startIcon={<AddIcon />}
              sx={{ backgroundColor: '#154AB6' }}
            >
              Create
            </ResponsiveButton>
          </Stack>
        </Stack>

        {/*Update的menu*/}
        <Menu
          id='batchUpdateMenuButton'
          data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Menu1-BatchUpdateMenuButton'
          anchorEl={anchorEl}
          open={batchPopoverOpen && selected.length > 0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          MenuListProps={{
            'aria-labelledby': 'export-button',
          }}
          onClose={handleBatchPopoverClose}
        >
          <MenuItem
            id='systemAdminUserManagementBatchUpdateRoleItem'
            data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Menu1-BatchUpdateMenuButton-MenuItem1-RoleItem'
            onClick={handleRoleUpdate}
          >
            Role
          </MenuItem>
          <MenuItem
            id='systemAdminUserManagementBatchUpdateGroupItem'
            data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Menu1-BatchUpdateMenuButton-MenuItem2-GroupItem'
            onClick={handleGroupUpdate}
          >
            {' '}
            Group/Title{' '}
          </MenuItem>
        </Menu>
        <Popover
          data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Popover'
          open={batchPopoverOpen && selected.length === 0}
          anchorEl={anchorEl}
          onClose={handleBatchPopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography
            id='systemAdminUserManagementBatchUpdateHintText'
            data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Popover-Typography-SystemAdminUserManagementBatchUpdateHintText'
            sx={{ p: 2 }}
          >
            Please select at least one user.
          </Typography>
        </Popover>
        {userData != null && (
          <UserTable
            data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-UserTable'
            userData={userData}
            rows={userData.result}
            Page={Page}
            perPageNumber={perPageNumber}
            count={userData.count}
            handlePerPageChange={handlePerPageChange}
            handlePageChange={handlePageChange}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            setPage={setPage}
            loadingUserData={loadingUserData}
          />
        )}
        {/*Export的menu*/}
        <Menu
          id='export-menu'
          data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Menu2'
          anchorEl={anchorEl2}
          open={exportMenuOpen && selected.length > 0}
          MenuListProps={{
            'aria-labelledby': 'export-button',
          }}
          onClose={handleExportMenuClose}
          sx={{
            '& .MuiPopover-paper': {
              marginTop: '6px',
              boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
              padding: '2px 0px 2px 0px',
              width: '120px', //137
            },
          }}
        >
          <MenuItem
            id='exportCSV'
            data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Menu2-MenuItem1-ExportCSV'
            onClick={handleExportCsv}
            sx={{
              fontSize: '12px',
              lineHeight: '20px',
              color: '#000000',
              paddingTop: '2px',
              paddingBottom: '2px',
              paddingLeft: '12px', //31
            }}
          >
            {intl.messages['common.exportToCSV']}
          </MenuItem>
          <MenuItem
            id='exportExcel'
            data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Menu2-MenuItem2-ExportExcel'
            onClick={handleExportExcel}
            sx={{
              fontSize: '12px',
              lineHeight: '20px',
              color: '#000000',
              paddingTop: '2px',
              paddingBottom: '2px',
              paddingLeft: '12px', //24
            }}
          >
            {intl.messages['common.exportToEXCEL']}
          </MenuItem>
        </Menu>
        {/*Export的消息提示*/}
        <Popover
          data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Popover'
          open={exportMsgOpen}
          anchorEl={anchorEl2}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography
            id='systemAdminUserManagementUserExportHintText'
            data-seleunim-id='SystemAdmin_UserManagement-Index-NewDesignedMain-Box-Popover-Typography-UserExportHintText'
            sx={{ p: 2 }}
          >
            Please select at least one User to export.
          </Typography>
        </Popover>
      </Box>
    </NewDesignedMain>
  );
}

export default UserManagement;

import React, { useState } from 'react';
import '../../../App.css';
import {
  Box,
  Button,
  buttonClasses,
  Divider,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
} from '@mui/material';
import DeleteButton from '../../../assets/DeleteIcon.svg';
import EditIcon from '../../../assets/EditIcon.svg';
import {
  handleChangeOrderField,
  handleChangeOrderType,
} from '@/actions/SIMT-User/Role/RoleManageAction';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '@/modules/AlertDialog';
import { MyTableHeader, TableFooter2 } from '@/components/DataTable';
import { useDispatch } from 'react-redux';
import { formatDatetimeString } from '@/utils/commonUtils';

function RoleTable(props) {
  const {
    Page,
    perPageNumber,
    orderField,
    orderType,
    count,
    handlePerPageChange,
    handlePageChange,
  } = props;

  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [roleName, setRoleName] = useState();
  const dispatch = useDispatch();

  const handleRequestSort = (_event, property) => {
    const isAsc = orderField === property && orderType === 'asc';
    dispatch(handleChangeOrderType(isAsc ? 'desc' : 'asc'));
    dispatch(handleChangeOrderField(property));
  };

  const createRow = (
    id,
    label,
    minWidth,
    maxWidth,
    isOrder = false,
    align = 'left',
    show = true
  ) => {
    return { id, label, minWidth, maxWidth, isOrder, align, show };
  };

  const items = [
    createRow('roleName', 'ROLE NAME', '200px', '400px', true, 'left'),
    createRow('updateTime', 'LAST UPDATED AT', '200px', '400px', true, 'left'),
    createRow('updateBy', 'LAST UPDATED BY', '200px', '400px', true, 'left'),
    createRow('actions', 'ACTIONS', '200px', '400px', false, 'left'),
  ];

  const StyledTableCell = styled(TableCell)(({ _theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: '14px',
      lineHeight: '20px',
      color: '#262E35',
      background: '#FFFFFF',
      padding: '8px 16px 8px 24px',
    },
  }));

  const handleRoleEdit = name => {
    return () => {
      if (name !== 'Admin') {
        navigate(`/simt/auth/system/role/update/${name}`);
      }
    };
  };

  const handleRoleDeletion = role => {
    setRoleName(role.roleName);
    setAlertOpen(true);
  };

  const handleClose = () => {
    setAlertOpen(false);
  };
  const handleClickRoleName = name => {
    return () => {
      navigate(`/simt/auth/system/role/detail/${name}`);
    };
  };

  return (
    <Box
      id='TableBox'
      data-seleunim-id='SystemAdmin_RoleManagement-RoleTable-Box'
    >
      <TableContainer
        data-seleunim-id='SystemAdmin_RoleManagement-RoleTable-Box-TableContainer'
        sx={{
          // minHeight: '397px',
          backgroundColor: '#fff',
          '&.MuiTableContainer-root': {
            borderTopLeftRadius: '8px ',
            borderTopRightRadius: '8px',
          },
        }}
      >
        <Table
          stickyHeader
          aria-label='simple table'
          data-seleunim-id='SystemAdmin_RoleManagement-RoleTable-Box-TableContainer-Table'
        >
          <MyTableHeader
            data-seleunim-id='SystemAdmin_RoleManagement-RoleTable-Box-TableContainer-Table-MyTableHeader'
            order={orderType}
            orderBy={orderField}
            onRequestSort={handleRequestSort}
            items={items}
          />
          <TableBody data-seleunim-id='SystemAdmin_RoleManagement-RoleTable-Box-TableContainer-Table-TableBody'>
            {props.rows.map(
              (row, _index) =>
                row.roleName !== 'Admin' && (
                  <TableRow
                    data-seleunim-id='SystemAdmin_RoleManagement-RoleTable-Box-TableContainer-Table-TableBody-TableRow'
                    key={row.roleId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <StyledTableCell
                      data-seleunim-id={`SystemAdmin_RoleManagement-RoleTable-Box-TableContainer-Table-TableBody-TableRow-StyledTableCell1-${_index}`}
                      component='th'
                      scope='row'
                      sx={{ fontWeight: 600 }}
                      onClick={handleClickRoleName(row.roleName)}
                      align='left'
                    >
                      <Button
                        id={`systemAdminRoleManagementRoleNameButton${_index}`}
                        data-selenium-id={`SystemAdmin-RoleManagementRoleNameButton-${_index}`}
                        sx={{
                          [`&.${buttonClasses.root}`]: {
                            padding: 0,
                            color: '#262E35',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '24px',
                            textTransform: 'none',
                            alignItems: 'flex-start',
                            align: 'left',
                            minWidth: 0,
                            whiteSpace: 'pre-wrap',
                          },
                        }}
                      >
                        {row.roleName}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell
                      id={`systemAdminRoleManagementUpdateTime${_index}`}
                      data-selenium-id={`SystemAdmin-RoleManagementUpdateTime-${_index}`}
                      align='left'
                    >
                      {formatDatetimeString(row.updateTime)}
                    </StyledTableCell>
                    <StyledTableCell
                      id={`systemAdminRoleManagementLastUpdatedBy${_index}`}
                      data-selenium-id={`SystemAdmin-RoleManagementLastUpdatedBy-${_index}`}
                      align='left'
                    >
                      {row.updateBy}
                    </StyledTableCell>
                    <StyledTableCell
                      id={`systemAdminRoleManagementActions${_index}`}
                      data-selenium-id={`SystemAdmin-RoleManagementActions-${_index}`}
                      align='left'
                    >
                      <IconButton
                        id={`systemAdminRoleManagementDeleteButton${_index}`}
                        data-selenium-id={`SystemAdmin-RoleManagementDeleteButton-${_index}`}
                        onClick={handleRoleDeletion.bind(this, row)}
                        color='red'
                        sx={{ padding: 0, marginRight: '12px' }}
                      >
                        <DeleteButton fontSize='large' />
                      </IconButton>
                      <IconButton
                        id={`systemAdminRoleManagementEditButton${_index}`}
                        data-selenium-id={`SystemAdmin-RoleManagementEditButton-${_index}`}
                        onClick={handleRoleEdit(row.roleName)}
                        sx={{ marginRight: '12px', padding: 0 }}
                      >
                        <EditIcon />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider data-seleunim-id='SystemAdmin_RoleManagement-RoleTable-Box-Divider' />
      <TableFooter2
        data-seleunim-id='SystemAdmin_RoleManagement-RoleTable-Box-TableFooter2'
        numSelected={0}
        perPageList={[5, 10, 20]}
        Page={Page}
        perPageNumber={perPageNumber}
        count={count}
        handlePerPageChange={handlePerPageChange}
        handlePageChange={handlePageChange}
      />
      <AlertDialog
        data-seleunim-id='SystemAdmin_RoleManagement-RoleTable-Box-AlertDialog'
        type='deleteRole'
        open={alertOpen}
        handleClose={handleClose}
        data={roleName}
        fullName={roleName}
      />
    </Box>
  );
}

export default RoleTable;

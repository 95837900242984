import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import DrawerCloseButton from '@/componentsphase2/DrawerCloseButton';
import {
  Box,
  Backdrop,
  backdropClasses,
  styled,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import styleModule from '../../../../componentsphase2/SIDetail/SaveAndCancelButton.module.scss';
import MyOnlyText from '../../../../componentsphase2/MyOnlyText';
import { useSelector } from 'react-redux';
import { InlineRichTextArea } from '../../../../componentsphase2/RichText/RichText';
import { CustomInputArea } from '../../../SIPage/SIDetailPage/market-section/components/components';

export default function CATReasonDialog(props) {
  const {
    commentsOpen,
    setCommentsOpen,
    value,
    setValue,
    title,
    editable = false,
    target,
    onSubmit = (newValue)=> {},
  } = props;

  const email = useSelector(state => state.User.email);

  const [inEdit, setInEdit] = useState(false);
  const [tmpValue, setTmpValue] = useState(value);
  const [exceedError, setExceedError] = useState(false);

  useEffect(()=> {
    if (commentsOpen){
      setInEdit(false);
    }
  }, [commentsOpen]);

  const handleClose = () => {
    setCommentsOpen(false);
    setValue('');
  };

  const BlueBackdrop = styled(Backdrop)(() => ({
    [`&.${backdropClasses.root}`]: {
      background: 'none',
    },
  }));

  const handleSubmit = () => {
    onSubmit && onSubmit(tmpValue);
    handleClose();
  };

  return (
    <>
      <Dialog
        id='CatOverviewEditCATReasonDialog'
        data-selenium-id='CATJournal_Overview-Table-CATReasonDialog-EditDialog'
        maxWidth={false}
        open={commentsOpen}
        onClose={handleClose}
        aria-labelledby='draggable-dialog-title'
        components={{
          Backdrop: BlueBackdrop,
        }}
        slotProps={{
          backdrop: {
            onClick: null, // zhaoyicheng禁用点击背景关闭 Modal
          },
        }}
        sx={{
          background: ' rgba(17, 61,149, 0.5)',
          backdropFilter: 'blur(4px)',
          zIndex: 1401,
          '& .MuiPaper-root': {
            margin: '10px',
            borderRadius: '8px',
            width: '600px',
            boxShadow: 'none'
            //height: "330px",
          },
        }}
      >
        <Stack
          id='frame-2438'
          data-selenium-id='CATJournal_Overview-Table-CATReasonDialog-FrameStack2438'
          justifyContent='space-between'
          direction='row'
          sx={{
            width: '536px',
            padding: '24px 0px 16px 0px',
            margin: '0px auto'
          }}
        >
          <DialogTitle
            style={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22.5px',
              backgroundColor: '#ffffff',
              color: '#262E35',
              padding: 0,
            }}
            id='draggable-dialog-title'
            data-selenium-id='CATJournal_Overview-Table-CATReasonDialog-DraggableDialogTitle'
          >
            {title}
          </DialogTitle>
          <DrawerCloseButton
            onClick={handleClose}
            data-selenium-id='CATJournal_Overview-Table-CATReasonDialog-IconButton'
          />
        </Stack>
        <Stack
          id='Textarea-Base-Default'
          data-selenium-id='CATJournal_Overview-Table-CATReasonDialog-Textarea-Base-Default'
          sx={{
            margin: '0px auto',
            paddingBottom: '20px',
            width: '536px',
          }}
        >
          <DialogContent
            sx={{
              padding: 0,
              lineHeight: '20px',
              '&. MuiInputBase-root': {
                lineHeight: '20px',
              },
            }}
          >
            <DialogContentText
              id='CATJournalOverviewDialogContentText'
              data-selenium-id='CATJournal_Overview-Table-CATReasonDialog-DialogContentText'
              style={{
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#262E35',
                overflow: 'hidden',
              }}
            >
              {inEdit?(<>
                {/*<MyOnlyText*/}
                {/*id={'CATJournalOverviewDialogContentText-InputArea'}*/}
                {/*multiline*/}
                {/*value={tmpValue}*/}
                {/*setValue={setTmpValue}*/}
                {/*placeholder ={title}*/}
                {/*minRows={4}*/}
                {/*maxRows={20}*/}
                {/*sx={{*/}
                {/*width: '536px',*/}
                {/*}}*/}
                {/*/>*/}
                <CustomInputArea
                  id={'CATJournalOverviewDialogContentText-InputArea'}
                  emptyError={false}
                  limitLength={10000}
                  value={tmpValue}
                  setValue={setTmpValue}
                  placeholder ={title}
                  setExceedError={setExceedError}
                  minRows={4}
                  maxRows={20}
                  sx={{
                    width: '536px',
                  }}
                />
              </>)
                :( <Box
                  data-selenium-id='CATJournal_Overview-Table-CATReasonDialog-NotEditBox'
                  sx={{
                    paddingRight: '6px'
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Open Sans',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '20px',
                      letterSpacing: '0em',
                      overflowWrap: 'anywhere',
                      overflowY: 'auto',
                      maxHeight: '220px',
                      color: '#262E35',
                    }}
                  >{value}
                  </Typography>
                </Box>)}
            </DialogContentText>
          </DialogContent>
          {editable && target && target.requestUser && target.requestUser.email && (target.requestUser.email == email)?(
            <Box
              // className={styleModule.SaveAndCancelButtonBox}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '536px',
                padding: '12px 0px 20px 8px',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                gap: '16px',
                '& > button': {
                  textTransform: 'none',
                  borderRadius: '5px',
                  border: '1px solid #0052CC',
                  width: '70px',
                  padding: '8px 12px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '18px',
                },
                '& .CancelButton': {
                  color: 'var(--primary-500, #0052CC)',
                  '&:hover': {
                    background: '#DFE4E8',
                  },
                  '&:active': {
                    background: '#DFE4E8',
                  },
                },
                '& .SaveButton': {
                  background: '#0052CC',
                  color: '#FFF',
                  '&:hover': {
                    background: '#6D98EE',
                  },
                  '&:active': {
                    background: '#113D95',
                  },
                  '&:disabled': {
                    background: '#DFE4E8',
                    color: '#B3BAC5',
                    cursor: 'not-allowed',
                  },
                },
              }}
            >
              {inEdit? (<>
                <Button
                  disableElevation
                  disableRipple
                  // className={styleModule.CancelButton}
                  className={'CancelButton'}
                  onClick={() => {
                    setInEdit(false);
                    setTmpValue(value);
                  }}
                >Cancel</Button>
                <Button
                  disableElevation
                  disableRipple
                  disabled={exceedError || tmpValue.length> 10000}
                  onClick={() => handleSubmit()}
                  // className={styleModule.SaveButton}
                  className={'SaveButton'}
                >Save</Button>
              </>) : (<>
                <Button
                  disableElevation
                  disableRipple
                  // className={styleModule.CancelButton}
                  className={'CancelButton'}
                  onClick={handleClose}
                >Close</Button>
                <Button
                  disableElevation
                  disableRipple
                  onClick={() => {
                    setTmpValue(value);
                    setInEdit(true);
                  }}
                  // className={styleModule.SaveButton}
                  className={'SaveButton'}
                >Edit</Button>
              </>)}
            </Box>
          ):null}
        </Stack>
      </Dialog>
    </>
  );
}

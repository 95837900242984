import {axios_instance_fileDownload} from "@/utils/axios_instance";
import {downloadFile} from "@/actions/SIMT-SI/SpecialIssue/SIAction";
import moment from "moment/moment";
import {EXPORT_REPORT} from "@/actions/SIMT-Report/Report/ReportAction";
import { getMoment } from "../getMoment";
import getAuth from "@/utils/getAuth";

export function exportJournalJPMExcel(selectedDate) {
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/journal/getJournalExportExcel',
                {
                    responseType: 'blob',
                    params: {
                        selectedDate: selectedDate,
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        authorization: getAuth(),
                    },
                }
            );
            downloadFile(
                res.data,
                'REPORT_JOURNAL_Overview_' +
                getMoment() +
                '.xlsx'
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportJournalJPMExcelWithFilenameFromBackEnd(selectedDate) {
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/journal/getJournalExportExcel',
                {
                    responseType: 'blob',
                    params: {
                        selectedDate: selectedDate,
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        authorization: getAuth(),
                    },
                }
            );
            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(
                    res.data,
                    res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, '')
                );
            } else {
                downloadFile(
                    res.data,
                    'REPORT_JOURNAL_Overview_' +
                    getMoment() +
                    '.xlsx'
                );
            }
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };

}

export function exportJournalJPMCsv(selectedDate) {
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/journal/jpm/csv',
                {
                    params: {
                        selectedDate: selectedDate,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            downloadFile(
                res.data,
                'REPORT_JOURNAL_JPM_' +
                getMoment() +
                '.csv'
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportJournalJPMCsvWithFilenameFromBackEnd(selectedDate) {
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/journal/jpm/csv',
                {
                    params: {
                        selectedDate: selectedDate,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(
                    res.data,
                    res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, '')
                );
            } else {
                downloadFile(
                    res.data,
                    'REPORT_JOURNAL_JPM_' +
                    getMoment() +
                    '.csv'
                );
            }
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportJournalBDExcel(selectedDate) {
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/journal/bd/excel',
                {
                    responseType: 'blob',
                    params: {
                        selectedDate: selectedDate,
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        authorization: getAuth(),
                    },
                }
            );
            downloadFile(
                res.data,
                'REPORT_JOURNAL_Business_Division_' +
                getMoment() +
                '.xlsx'
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportJournalBDCsv(selectedDate) {
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/journal/bd/csv',
                {
                    params: {
                        selectedDate: selectedDate,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            downloadFile(
                res.data,
                'REPORT_JOURNAL_Business_Division_' +
                getMoment() +
                '.csv'
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportJournalBDCsvWithFilenameFromBackEnd(selectedDate) {
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/journal/bd/csv',
                {
                    params: {
                        selectedDate: selectedDate,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(
                    res.data,
                    res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, '')
                );
            } else {
                downloadFile(
                    res.data,
                    'REPORT_JOURNAL_Business_Division_' +
                    getMoment() +
                    '.csv'
                );
            }
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Footer from '@/components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { DetailScrollTopButton } from '@/components/StyledButton/ScrollToTopButton';
import { TitleBlock } from './submodule/TitleBlock';
import CommentSection from '../RevisePage/Section/CommentSection';
import { ObjectShowSection } from '../RevisePage/Section/ObjectSection';
import { GEInformationShowSection } from '../RevisePage/Section/GEInformationSection';
import { SIPInformationShowSection } from '../RevisePage/Section/SIPInformationSection';
import { ContentCommissioningApproachesShowSection } from '../RevisePage/Section/ContentCommissioningApproachesSection';
import { CustomHeader } from './submodule/CustomHeader';
import EndButtonArrowFold from '@/assets/EndButtonArrowFold.svg';
import { FormattedMessage } from 'react-intl';
import AgreeStickyBar from './submodule/AgreeStickyBar';
import { DraftStickyBar } from './submodule/DraftStickyBar';
import GEInformationEditSection from './GEInformationEditSection';
import DeclineReasonModal from './submodule/DeclineReasonModal';
import { SIPInformationEditSection } from './SIPInformationEditSection';
import { ContentCommissioningApproachesEditSection } from './ContentCommissioningApproachesEditSection';
import ThankAcceptancePage from '../SubmitCompletedPage/ThankAcceptancePage';
import { setSnackbarMessageAndOpen } from '../../../actions/SnackbarAction';
import CommonSnackBar, {
  SEVERITIES,
} from '@/modules/ConfirmSnackBar/CommonSnackBar';
import AcceptDecisionUnFold from './submodule/AcceptDecisionUnFold';
import {DecisionFoldBtn, SavedDecisionFoldBtn} from '@/pages/SIPPage/SIPDetailPage/DecisionSection/DecisionMyStyled';
import { SSIPObjectShowSection } from '../RevisePage/Section/SSIPObjectSection';
import { SSIPInformationShowSection } from '../RevisePage/Section/SSIPInformationSection';
import {
  acceptSaveDraft, declineSIP,
  getAcceptSIPInformation,
  submitAcceptSIPInformation
} from "@/actions/SIMT-SIP/SIP/AcceptanceDecision";

/**
 * @param {ReadonlyArray<{readonly researchKeywords:ReadonlyArray<string>}>} geInformation 
 * @returns 
 */
const showGeInformation = (geInformation) => {
  return !geInformation?.every(value => {
    return (
      value?.researchKeywords !== null && value?.researchKeywords?.length !== 0
    );
  });
};
const AcceptPageBodyContainer = props => {
  const { children, sx = {}, ...others } = props;
  return (
    <Stack
      data-selenium-id='SIP_AcceptPage_index-BodyContainerStack'
      marginTop={'56px'}
      marginBottom='44px'
      paddingX='32px'
      paddingTop={'24px'}
      minHeight={'calc(100vh - 56px - 71.2px)'}
      sx={{ boxSizing: 'border-box', ...sx }}
      {...others}
    >
      {children}
    </Stack>
  );
};

export const SectionsContainer = props => {
  const { children, sx = {}, ...others } = props;
  return (
    <Stack
      data-selenium-id='SIP_AcceptPage_index-SectionsContainerStack'
      sx={{
        backgroundColor: '#fff',
        '& .SectionTitleBlock + .MuiCollapse-root .MuiCollapse-wrapper': {
          paddingTop: '16px',
        },
        ...sx,
      }}
      paddingX='32px'
      paddingY='32px'
      marginTop='4px'
      spacing='24px'
      {...others}
    >
      {children}
    </Stack>
  );
};

export default function AcceptPage() {
  const [notEditable, setNotEditable] = useState({});
  const [comments, setComments] = useState('');
  const [object, setObject] = useState({});

  // GE Information
  const [geInformationList, setGeInformationList] = useState([]);
  const [geInformationError, setGeInformationError] = useState(false);

  const [sipInformation, setSipInformation] = useState({
    acceptanceDeadline: '',
    aimsAndScope: '',
    epDate: '',
    fmSubmissionDeadline: '',
    keywords: [],
    notesForEditor: null,
    references: [],
    sipTitle: '',
    topics: [],
  });
  const [sipInformationError, setSipInformationError] = useState(false);

  const [commissionApproaches, setCommissionApproaches] = useState({
    openCallForPapers: '-1',
    potentialAuthors: [],
  });

  const [openCallForPapersError, setOpenCallForPapersError] = useState(false);

  const [hasAgreed, setHasAgreed] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [researchKeywords, setResearchKeywords] = useState([]);
  const [researchKeywordsError, setResearchKeywordsError] = useState(showGeInformation(geInformationList));

  const [declineReason, setDeclienReason] = useState('');
  const [declineReasonOpen, setDeclineReasonOpen] = useState(false);
  const [declineReasonError, setDeclineReasonError] = useState(false);

  const [draft, setDraft] = useState({
    acceptanceDeadline: '',
    epDate: '',
    fmSubmissionDeadline: '',
    keywords: [],
    openCallForPapers: '-1',
    researchKeywords: [],
  });

  const selector = state => {
    return {
      declineCode: state.SIP.declineCode,
      acceptInformation: state.SIP.acceptInformation,
      acceptSubmitCode: state.SIP.acceptSubmitCode,
      acceptShowCode: state.SIP.acceptShowCode,
    };
  };
  const { declineCode, acceptInformation, acceptSubmitCode, acceptShowCode } =
    useSelector(selector);

  const { token } = useParams();
  const dispatch = useDispatch();

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    dispatch(getAcceptSIPInformation(token));
  }, []);

  useEffect(() => {
    if (acceptShowCode === 16032) {
      setHasSubmitted(true);
    }
  }, [acceptShowCode]);

  useEffect(() => {
    if (acceptSubmitCode === 200 || acceptSubmitCode === 16032) {
      setHasSubmitted(true);
    }
  }, [acceptSubmitCode]);

  useEffect(() => {
    if (declineCode === 200 || declineCode === 16032) {
      setHasSubmitted(true);
    }
  }, [declineCode]);

  useEffect(() => {
    if (acceptInformation) {
      setNotEditable(acceptInformation.notEditable);
      setComments(acceptInformation.comments);
      setObject(acceptInformation.object);
      setGeInformationList(acceptInformation.geInformation);
      setSipInformation(acceptInformation.sipInformation);
      setCommissionApproaches(acceptInformation.commissionApproaches);
      if (acceptInformation.draft) {
        setDraft(acceptInformation.draft);
      }
      if (acceptInformation.draft && acceptInformation.draft.researchKeywords) {
        setResearchKeywords(
          acceptInformation.draft.researchKeywords.map(value => {
            return {
              id: value.id,
              researchKeywords: value.researchKeywords ?? [],
            };
          })
        );
      } else {
        setResearchKeywords(
          acceptInformation.geInformation.map(value => {
            return {
              id: value.id,
              researchKeywords: value.researchKeywords ?? [],
            };
          })
        );
      }
    }
  }, [acceptInformation]);

  const handleDecline = () => {
    setDeclineReasonOpen(true);
  };

  const handleAgree = () => {
    setHasAgreed(true);
    dispatch(
      setSnackbarMessageAndOpen(
        'sipDetail.agreeMessage',
        {},
        SEVERITIES.warning
      )
    );
  };

  const handleResearchKeywordsChange = (geId, keywords) => {
    let tmpRks = JSON.parse(JSON.stringify(researchKeywords));
    for (let index in tmpRks) {
      const rk = tmpRks[index];
      if (geId === rk['id']) {
        tmpRks[index]['researchKeywords'] = keywords;
        break;
      }
    }
    setResearchKeywords(tmpRks);
    let flag = false;
    for (let rk of tmpRks) {
      if (rk['researchKeywords'].length === 0) {
        setResearchKeywordsError(true);
        flag = true;
        break;
      }
    }
    if (!flag) {
      setResearchKeywordsError(false);
    }
  };

  const checkDataValidation = () => {
    // 只要有一个 Error 数据就是无效的
    const res = !(geInformationError || sipInformationError);
    // 数据无效时显示错误信息
    setShowErrorMessage(!res);
    return res;
  };

  const handleDeclineConfirm = () => {
    if (declineReason === '') {
      setDeclineReasonError(true);
      return;
    }
    dispatch(declineSIP(token, declineReason));
    setDeclineReasonOpen(false);
    setDeclienReason('');
  };

  const handleDeclineCancel = () => {
    setDeclienReason('');
    setDeclineReasonOpen(false);
  };

  const handleSaveDraft = () => {
    dispatch(
      acceptSaveDraft(
        token,
        researchKeywords,
        draft.keywords,
        draft.fmSubmissionDeadline,
        draft.acceptanceDeadline,
        draft.epDate,
        draft.openCallForPapers
      )
    );
  };

  const handleCheck = () => {
    setShowErrorMessage(true);
    return (
      researchKeywordsError || sipInformationError || openCallForPapersError
    );
  };

  const handleSubmit = () => {
    setShowErrorMessage(true);
    if (
      researchKeywordsError ||
      sipInformationError ||
      openCallForPapersError
    ) {
      return;
    }

    dispatch(
      submitAcceptSIPInformation(
        token,
        showGeInformation(geInformationList)
          ? researchKeywords
          : geInformationList.map(v => {
              return {
                id: v.id,
                researchKeywords: v.researchKeywords ?? [],
              };
            }),
        showKeywords() ? draft.keywords : sipInformation.keywords,
        showFmSubmissionDeadline()
          ? draft.fmSubmissionDeadline
          : sipInformation.fmSubmissionDeadline,
        showAcceptanceDeadline()
          ? draft?.acceptanceDeadline
          : sipInformation.acceptanceDeadline,
        showEpDate() ? draft.epDate : sipInformation.epDate,
        showCommissionApproaches()
          ? draft.openCallForPapers
          : commissionApproaches.openCallForPapers
      )
    );
  };
  const showKeywords = () => {
    return (
      sipInformation.keywords === null || sipInformation.keywords?.length === 0
    );
  };

  const showAcceptanceDeadline = () => {
    return (
      sipInformation.acceptanceDeadline === null ||
      sipInformation.acceptanceDeadline === ''
    );
  };

  const showFmSubmissionDeadline = () => {
    return (
      sipInformation.fmSubmissionDeadline === null ||
      sipInformation.fmSubmissionDeadline === ''
    );
  };

  const showEpDate = () => {
    return sipInformation.epDate === null || sipInformation.epDate === '';
  };

  const showCommissionApproaches = () => {
    return commissionApproaches.openCallForPapers===null || commissionApproaches.openCallForPapers===undefined
      || Number(commissionApproaches.openCallForPapers) === -1;
  };
  const [decisionIsFold, setDecisionIsFold] = useState(true);
  const handleDecisionUnfold = () => {
    setDecisionIsFold(false);
  };

  const buttonContainer = {
    width: '100%',
    height: '60px',
    background: '#FFFFFF',
    boxShadow: '0px -2px 5px rgba(0,0,0,0.1)',
    position: 'sticky', // 外部需要有一个容器让他贴靠底部
    bottom: 0,
    padding: '12px 32px',
    zIndex: '1200',
    overflow: 'hidden',
  };
  const [isSave, setIsSave] = useState(false);
  return (
    <Box data-selenium-id='SIP_AcceptPage_index-Box'>
      {/* <CustomHeader sx={{ zIndex: 1301 }} /> */}
      <CustomHeader />
      {hasSubmitted ? (
        <ThankAcceptancePage />
      ) : (
        <>
          <AcceptPageBodyContainer>
            <TitleBlock
              title={notEditable.sipTitle}
              SIPCode={notEditable.sipCode}
            />
            {hasAgreed ? (
              <SectionsContainer>
                {showGeInformation(geInformationList) ? (
                  <GEInformationEditSection
                    value={geInformationList.map((v, index) => {
                      return {
                        ...v,
                        researchKeywords:
                          draft?.researchKeywords[index]?.researchKeywords,
                      };
                    })}
                    setValue={handleResearchKeywordsChange}
                    showErrorMessage={showErrorMessage}
                    error={researchKeywordsError}
                    setError={setResearchKeywordsError}
                  />
                ) : null}
                {showKeywords() ||
                showAcceptanceDeadline() ||
                showFmSubmissionDeadline() ||
                showEpDate() ? (
                  <SIPInformationEditSection
                    value={{
                      ...sipInformation,
                      keywords: draft.keywords || sipInformation.keywords,
                      acceptanceDeadline: draft.acceptanceDeadline || sipInformation.acceptanceDeadline,
                      epDate: draft.epDate || sipInformation.epDate,
                      fmSubmissionDeadline: draft.fmSubmissionDeadline || sipInformation.fmSubmissionDeadline,
                    }}
                    sipInformation={sipInformation}
                    setValue={setDraft}
                    showErrorMessage={showErrorMessage}
                    setError={setSipInformationError}
                  />
                ) : null}

                {showCommissionApproaches() ? (
                  <ContentCommissioningApproachesEditSection
                    value={draft.openCallForPapers}
                    setValue={setDraft}
                    showErrorMessage={showErrorMessage}
                    error={openCallForPapersError}
                    setError={setOpenCallForPapersError}
                  />
                ) : null}
              </SectionsContainer>
            ) : (
              <SectionsContainer>
                <CommentSection commentsContent={comments} />
                {acceptInformation?.sipInformation?.sipType === 'Yes'?
                (<SSIPObjectShowSection
                  value={{
                    type: 'ProposerRevise',
                    sipObject: acceptInformation?.object?.solicitedSipObjectInfo,
                   }}
                />):(
                  <ObjectShowSection
                  value={{
                    type: 'ProposerRevise',
                    sipObject: acceptInformation?.object,
                    }}
                  />
                )}
                <GEInformationShowSection value={geInformationList} />
                {sipInformation?.sipType === 'Yes'?
                (<SSIPInformationShowSection value={acceptInformation?.sipInformation}/>):
               (<SIPInformationShowSection value={acceptInformation?.sipInformation}/>)}
                <ContentCommissioningApproachesShowSection
                  value={commissionApproaches}
                  openCallForPapersRenderValue={{
                    '-1': 'N/A',
                    0: 'NO',
                    1: 'Yes',
                  }}
                  emptyTip={true}
                />
              </SectionsContainer>
            )}
          </AcceptPageBodyContainer>
          <DetailScrollTopButton
            boxProps={{ sx: { bottom: 250 } }}
            data-selenium-id='SIP_AcceptPage_index-Box-DetailScrollTopButton'
          />
          {acceptShowCode === 200 &&
            (hasAgreed ? (
              <DraftStickyBar
                handleSaveDraft={handleSaveDraft}
                handleSubmit={handleSubmit}
                handleCheck={handleCheck}
              />
            ) : (
              <>
                {decisionIsFold ? (

                  <div style={buttonContainer}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                        height: '100%',
                      }}
                    >
                      <Stack
                        spacing={10}
                        direction='row'
                        data-selenium-id='SIP_DetailPage_DecisionSection-Fold-Stack'
                      >

                        {isSave ?
                          (<SavedDecisionFoldBtn
                            onClick={handleDecisionUnfold}
                            id='DecisionButton'
                            data-selenium-id='SIP_DetailPage_DecisionSection-Fold-DecisionButton'
                            variant='contained'
                            endIcon={<EndButtonArrowFold></EndButtonArrowFold>}
                          >
                            <FormattedMessage
                              id='siDetail.decision'
                              data-selenium-id='SIP_DetailPage_DecisionSection-Fold-DetailDecision'
                            /></SavedDecisionFoldBtn>)
                          : (<DecisionFoldBtn
                            onClick={handleDecisionUnfold}
                            id='DecisionButton'
                            data-selenium-id='SIP_DetailPage_DecisionSection-Fold-DecisionButton'
                            variant='contained'
                            endIcon={<EndButtonArrowFold></EndButtonArrowFold>}
                          >
                            <FormattedMessage
                              id='siDetail.decision'
                              data-selenium-id='SIP_DetailPage_DecisionSection-Fold-DetailDecision'
                            />
                          </DecisionFoldBtn>)
                        }
                      </Stack>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <AcceptDecisionUnFold
                style={decisionIsFold ? { display: 'none' } : {}}
                handleAgree={handleAgree}
                setDecisionIsFold={setDecisionIsFold}
              setIsSave={setIsSave}/>
</>
            ))}
        </>
      )}
      <Footer
        sx={{ borderColor: '#1e48b4' }}
        data-selenium-id='SIP_AcceptPage_index-Box-Footer'
      />
      <DeclineReasonModal
        open={declineReasonOpen}
        handleConfirm={handleDeclineConfirm}
        handleClose={handleDeclineCancel}
        value={declineReason}
        setValue={setDeclienReason}
        error={declineReasonError}
        setError={setDeclineReasonError}
      />
      <CommonSnackBar />
    </Box>
  );
}

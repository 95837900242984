import { Skeleton, Stack, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * 骨架组件
 * 使用在表格加载的时候，可选传入height和num，分别代表单个矩形的高度和矩形的数量，默认36px和9个
 * 需要传入
 * @param {{readonly height?: number; readonly num?: number; readonly colSpan?: number; }} props
 * @returns {JSX.Element}
 */
function TableSkeleton(props) {
  const { height = 40, num = 9, colSpan, ...other } = props;
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Stack spacing={1} width='100%'>
          {[...Array(num)].map((_value, index, _array) => (
            <Skeleton
              key={`skeleton-${index}`}
              variant='rectangular'
              animation='wave'
              height={height}
              {...other}
            />
          ))}
        </Stack>
      </TableCell>
    </TableRow>
  );
}

TableSkeleton.propTypes = {
  colSpan: PropTypes.number.isRequired,
};

export default TableSkeleton;

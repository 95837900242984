import {paramsEncode} from "@/utils/commonUtils";
import {axios_instance} from "@/utils/axios_instance";
import {
    GET_ALL_TITER2JPM_INFO,
    GET_APPROVEJOURNALLIST,
    GET_ATE_JOURNAL_DROPLIST
} from "@/actions/SIMT-SIP/SIP/SIPAction";

export function getTier2JPM(username) {
    let params = paramsEncode({
        type: "publisher",
        searchKey: username,
        pageNum: 1,
        pageSize: 5000
    });
    return async dispatch => {
        try {
            const res = await axios_instance.get('/api/v1/si-service/journals/filter-metadata', {
                params: params,
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const {code,data} = res.data;

            if (code === 200) {
                dispatch({type: GET_ALL_TITER2JPM_INFO, data: data});
            } else {
                dispatch({type: GET_ALL_TITER2JPM_INFO, data: null});
            }
        } catch (err) {
            dispatch({type: GET_ALL_TITER2JPM_INFO, data: err.response.data.msg});
        }
    };
}

export const getATEJournalDropList = name => {
    return async dispatch => {
        try {
            const res = await axios_instance.get('/api/v1/si-service/journals/managed/codes-and-names', {
                params: {
                    pageNum:1,
                    pageSize:30000,
                    journalName: name,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const {code,data} = res.data;
            if (code === 200) {
                dispatch({type: GET_ATE_JOURNAL_DROPLIST, data: data});
            } else {
                dispatch({type: GET_ATE_JOURNAL_DROPLIST, data: []});
            }
        } catch (err) {
            dispatch({type: GET_ATE_JOURNAL_DROPLIST, data: []});
        }
    };
};
export const getATEJournalDropListforSSIP = (name, url = '/api/v1/si-service/journals/managed/codes-and-names', isExternalPage = false) => {
    return async dispatch => {
        try {
            const headers = isExternalPage
                ? {}  // Empty headers when isExternalPage is true
                : { authorization: localStorage.getItem('authorization') };  // Include authorization header when isExternalPage is false
            const res = await axios_instance.get(url, {
                params: {
                    pageNum: 1,
                    pageSize: 30000,
                    name: name,
                    type: isExternalPage ? 'journalAndUrlSSIP' : ''
                },
                headers: headers
            });
            const {code,data} = res.data;

            if (code === 200 && data !== 'invalid type') {
                dispatch({type: GET_ATE_JOURNAL_DROPLIST, data: data});
            } else {
                dispatch({type: GET_ATE_JOURNAL_DROPLIST, data: []});
            }
        } catch (err) {
            dispatch({type: GET_ATE_JOURNAL_DROPLIST, data: []});
        }
    };
};

export function getApproveJournalList(value) {
    return async dispatch => {
        try {
            let params = paramsEncode({
                pageNum: 1,
                pageSize: 100000,
                journalName: value
            });

            const res = await axios_instance.get(`/api/v1/si-service/journals/managed/codes-and-names`, {
                params: params,
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const {code,data} = res.data;
            if (code === 200) {
                dispatch({type: GET_APPROVEJOURNALLIST, data: data});
            }
        } catch {
            console.log('error for journal');
        }
    };
}
import { Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import '@/pages/SIPPage/SIPDetailPage/Submodule/HideText.css';
import PropTypes from 'prop-types';
import {Box} from '@mui/system';

function ViewMoreText(props) {
  const { lineNum, textStyle, viewAllStyle, id, monitor, children, setCommentsOpen, setTextValue, text } = props;

  const [rowLimit, setRowLimit] = useState(lineNum);
  const [expanding, setExpanding] = useState(false);
  const [needHide, setNeedHide] = useState(false);
  const maxHeight = lineNum * 20;
  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current.scrollHeight > maxHeight) {
      setNeedHide(true);
    } else {
      setNeedHide(false);
    }
    window.addEventListener('resize', resizeUpdate);
    return () => {
      window.removeEventListener('resize', resizeUpdate);
    };
  }, []);

  const resizeUpdate = e => {
    if (elementRef.current.scrollHeight > maxHeight) {
      setNeedHide(true);
    } else {
      setNeedHide(false);
    }
  };

  useEffect(() => {
    resizeUpdate();
  }, [monitor]);

  const useTextStyle = textStyle ?? {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    flex: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: rowLimit,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-all',
  };

  const useViewAllStyle = viewAllStyle ?? {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    height: '20px',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.01em',
    color: '#154AB6',
    flex: 'none',
    cursor: 'pointer',
    width: 'max-content',
  };

  const handleViewAllClick = () => {
    // setExpanding(true);
    // setRowLimit('inherit');
    setCommentsOpen(true);
    setTextValue(text);
  };

  const handleHideClick = () => {
    setCommentsOpen(false);
    // setExpanding(false);
    // setRowLimit(lineNum);
  };

  return (
    <Stack
      direction={expanding ? 'column' : 'row'}
      alignItems='flex-end'
      spacing={0}
      justifyContent='flex-start'
      data-selenium-id='CATJournal_Overview-OptInTable-ViewMoreText-Stack'
    >
      <div
        id={id + '-viewAllBody'}
        data-selenium-id={id + '-viewAllBody'}
        style={{
          position: 'relative',
        }}
      >
        <div style={useTextStyle} ref={elementRef}>
          {children}
        </div>
      </div>
      {needHide &&
        (expanding ? (
          <div
            style={{ marginTop: '8px', height: '20px' }}
            data-selenium-id='CATJournal_Overview-OptInTable-ViewMoreText-ExpandingDiv'
          >
            <div style={useViewAllStyle} onClick={handleHideClick}>
              Hide
            </div>
          </div>
        ) : (
          <div
            style={{ height: '20px' }}
            data-selenium-id='CATJournal_Overview-OptInTable-ViewMoreText-NotExpandingDiv'
          >
            <Box 
              sx={{
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                height: '20px',
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: '0.01em',
                color: '#154AB6',
                flex: 'none',
                cursor: 'pointer',
                width: 'max-content',
                ':hover': {
                  fontWeight: 700,
                  color: '#113D95',
                },
              }}
              onClick={handleViewAllClick}
            >
              View more
            </Box>
          </div>
        ))}
    </Stack>
  );
}

ViewMoreText.propTypes = {
  textStyle: PropTypes.object,
  viewAllStyle: PropTypes.object,
  lineNum: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default ViewMoreText;

import * as actions from '../actions/SIMT-User/Role/RoleManageAction';

const initState = {
  roleInfo: { result: [], count: 0 },
  permissionList: [],
  roleDetail: { notAddPermissionList: [], addedPermissionList: [] },
  // createRole: null,
  // create & update role in BasicPage.js
  inputRoleName: '',
  checked: [],
  left: [],
  right: [],
  roleResult: null,
  originalLeft: [],
  originalRight: [],
  // others
  roleNameValid: false,
  lastOperation: null,
  // role table
  page: 1,
  pageSize: 10,
  orderField: 'roleName',
  orderType: 'asc',
  reloadTableTrigger: null,
  // dialog control
  // dialogOpen: false,
  // dialogType: "",
};

export default function RoleManageReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.GET_ALL_ROLE:
      return {
        ...state,
        roleInfo: data,
      };
    case actions.GET_PERMISSION_LIST:
      return {
        ...state,
        permissionList: data,
      };
    case actions.GET_ROLE_DETAIL:
      return {
        ...state,
        roleDetail: data,
      };
    case actions.CREATE_ROLE:
      return {
        ...state,
        roleResult: data,
        lastOperation: 'createRole',
        // reloadTableTrigger: Math.random()
      };
    case actions.DELETE_ROLE:
      return {
        ...state,
        roleResult: data,
        lastOperation: 'deleteRole',
        reloadTableTrigger: Math.random(),
      };
    case actions.UPDATE_ROLE:
      return {
        ...state,
        roleResult: data,
        lastOperation: 'updateRole',
        // reloadTableTrigger: Math.random()
      };
    case actions.DETECT_ROLENAME:
      return {
        ...state,
        roleNameValid: data,
      };
    case actions.ROLE_CLEAR:
      return initState;
    case actions.CHANGE_PAGE:
      return {
        ...state,
        page: data,
      };
    case actions.CHANGE_PAGE_SIZE:
      return {
        ...state,
        pageSize: data,
      };
    case actions.CHANGE_ORDERFIELD:
      return {
        ...state,
        orderField: data,
      };
    case actions.CHANGE_ORDERTYPE:
      return {
        ...state,
        orderType: data,
      };
    case actions.RESET_ROLE_RESULT:
      return {
        ...state,
        roleResult: null,
        lastOperation: null,
      };
    case actions.SET_PERMISSION: {
      switch (data['which']) {
        case 'left':
          return {
            ...state,
            left: data['data'],
          };
        case 'right':
          return {
            ...state,
            right: data['data'],
          };
        case 'checked':
          return {
            ...state,
            checked: data['data'],
          };
        case 'originalLeft':
          return {
            ...state,
            originalLeft: data['data'],
          };
        case 'originalRight':
          return {
            ...state,
            originalRight: data['data'],
          };
        default:
          return state;
      }
    }
    default: {
      return state;
    }
  }
}

import { useEffect, useState } from 'react';
import { FilesDragAndDrop } from './DragHooks';
import { Box, Stack, Typography, LinearProgress } from '@mui/material';
import { ProgressFile } from './ProgressFile';
import { LabelFile } from './LabelFile';
import { ErrorBox } from './ErrorBox';
import axios from 'axios';
import { hiddenFilenameTransform, hiddenFiletypeTransform } from './utils';

const FileUploadIcon = () => {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="22" rx="4" fill="#F1F3F5" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.64645 6.14645C9.84171 5.95118 10.1583 5.95118 10.3536 6.14645L12.8536 8.64645C13.0488 8.84171 13.0488 9.15829 12.8536 9.35355C12.6583 9.54882 12.3417 9.54882 12.1464 9.35355L10.5 7.70711V12.5714C10.5 12.8081 10.2761 13 10 13C9.72386 13 9.5 12.8081 9.5 12.5714V7.70711L7.85355 9.35355C7.65829 9.54882 7.34171 9.54882 7.14645 9.35355C6.95118 9.15829 6.95118 8.84171 7.14645 8.64645L9.64645 6.14645ZM6 12.5C6 12.2239 5.77614 12 5.5 12C5.22386 12 5 12.2239 5 12.5V14.5C5 14.8978 5.15804 15.2794 5.43934 15.5607C5.72064 15.842 6.10217 16 6.5 16H13.5C13.8978 16 14.2794 15.842 14.5607 15.5607C14.842 15.2794 15 14.8978 15 14.5V12.5C15 12.2239 14.7761 12 14.5 12C14.2239 12 14 12.2239 14 12.5V14.5C14 14.6326 13.9473 14.7598 13.8536 14.8536C13.7598 14.9473 13.6326 15 13.5 15H6.5C6.36739 15 6.24021 14.9473 6.14645 14.8536C6.05268 14.7598 6 14.6326 6 14.5V12.5Z" fill="#154AB6" />
    </svg>
  );
};

const PDFIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8335 1.6665H5.00016C4.55813 1.6665 4.13421 1.8421 3.82165 2.15466C3.50909 2.46722 3.3335 2.89114 3.3335 3.33316V16.6664C3.3335 17.1085 3.50909 17.5324 3.82165 17.8449C4.13421 18.1575 4.55813 18.3331 5.00016 18.3331H15.0001C15.4421 18.3331 15.8661 18.1575 16.1786 17.8449C16.4912 17.5324 16.6668 17.1085 16.6668 16.6664V7.49981L10.8335 1.6665Z" stroke="#596A7C" stroke-width="1.66666" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.8335 1.6665V7.49981H16.6668" stroke="#596A7C" stroke-width="1.66666" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

const WordIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.23223 1.56573C3.70107 1.09689 4.33695 0.833496 4.99999 0.833496H11.6666C11.8876 0.833496 12.0996 0.921293 12.2559 1.07757L17.2559 6.07755C17.4121 6.23383 17.4999 6.44579 17.4999 6.6668V16.6668C17.4999 17.3298 17.2365 17.9657 16.7677 18.4345C16.2989 18.9034 15.663 19.1667 14.9999 19.1667H4.99999C4.33695 19.1667 3.70107 18.9034 3.23223 18.4345C2.76339 17.9657 2.5 17.3298 2.5 16.6668V3.33348C2.5 2.67045 2.76339 2.03456 3.23223 1.56573ZM4.99999 2.50016C4.77898 2.50016 4.56702 2.58795 4.41074 2.74423C4.25446 2.90051 4.16666 3.11247 4.16666 3.33348V16.6668C4.16666 16.8878 4.25446 17.0997 4.41074 17.256C4.56702 17.4123 4.77898 17.5001 4.99999 17.5001H14.9999C15.221 17.5001 15.4329 17.4123 15.5892 17.256C15.7455 17.0997 15.8333 16.8878 15.8333 16.6668V7.01198L11.3214 2.50016H4.99999Z" fill="#596A7C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.8335 14.1668C5.8335 13.7066 6.20659 13.3335 6.66683 13.3335H13.3335C13.7937 13.3335 14.1668 13.7066 14.1668 14.1668C14.1668 14.6271 13.7937 15.0002 13.3335 15.0002H6.66683C6.20659 15.0002 5.8335 14.6271 5.8335 14.1668Z" fill="#596A7C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.8335 10.8333C5.8335 10.3731 6.20659 10 6.66683 10H13.3335C13.7937 10 14.1668 10.3731 14.1668 10.8333C14.1668 11.2936 13.7937 11.6667 13.3335 11.6667H6.66683C6.20659 11.6667 5.8335 11.2936 5.8335 10.8333Z" fill="#596A7C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.8335 7.49983C5.8335 7.0396 6.20659 6.6665 6.66683 6.6665H8.33348C8.79372 6.6665 9.16681 7.0396 9.16681 7.49983C9.16681 7.96007 8.79372 8.33316 8.33348 8.33316H6.66683C6.20659 8.33316 5.8335 7.96007 5.8335 7.49983Z" fill="#596A7C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6668 0.833496C12.1271 0.833496 12.5002 1.20659 12.5002 1.66683V5.83347H16.6668C17.127 5.83347 17.5001 6.20657 17.5001 6.6668C17.5001 7.12704 17.127 7.50013 16.6668 7.50013H11.6668C11.2066 7.50013 10.8335 7.12704 10.8335 6.6668V1.66683C10.8335 1.20659 11.2066 0.833496 11.6668 0.833496Z" fill="#596A7C" />
    </svg>
  );
};

function getBit(value, bit = 1) {
  let str = Number(value);
  str = str.toFixed(bit);
  return str;
}

const getAttachmentSizeInfo = fileSize => {
  let sizeInfo = {};
  if (fileSize === "???") {
    sizeInfo.size = "???";
    sizeInfo.unit = 'B';
    return sizeInfo;
  }
  if (fileSize < 1024) {
    sizeInfo.size = fileSize;
    sizeInfo.unit = 'B';
  } else if (fileSize < 1048576) {
    sizeInfo.size = fileSize / 1024;
    sizeInfo.unit = 'KB';
  } else {
    sizeInfo.size = fileSize / (1024 * 1024);
    sizeInfo.unit = 'MB';
  }
  sizeInfo.size = getBit(sizeInfo.size);
  return sizeInfo;
};

const defaultTypes = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": 0, //docx
  "application/msword": 0, //doc
  "application/pdf": 1, //pdf
};

const parseFileInfo = (fileInfo) => {
  let fileName = "";
  let fileSize = "0";
  let fileType = "";
  if (!fileInfo) {
    return {
      fileName: fileName,
      fileSize: fileSize,
      fileType: fileType
    };
  }

  const pattern = /^name:([^;]*);size:(\d+)/;
  const fileInfoList = fileInfo.match(pattern);
  if (!fileInfoList || fileInfoList.length < 3) {
    fileName = fileInfo;
    fileSize = "???";
  } else {
    fileName = fileInfoList[1];
    fileSize = fileInfoList[2];
  }
  const fileNameList = fileName.split(".");
  if (fileNameList.length >= 2) {
    fileType = fileNameList.pop();
    fileName = fileNameList.join('.');
  }
  return {
    name: fileName,
    size: fileSize,
    type: fileType
  };
};

export function FileUploader(props) {
  const {
    progress,
    setProgress,
    uploadFlag,
    handleUpload,
    handleDelete,
    defaultFile,
    avalableTypes = defaultTypes,
    placeholder = "Drag CV to here",
    UploadIcon = FileUploadIcon,
    hint = "Support PDF and Word.",
    error,
    inifileSize = null,
  } = props;


  /**
   * 0) emtpy
   * 1）with file
   * 2) uploading
   * 3) error
   */
  const [status, setStatus] = useState(0);
  const [fileName, setFileName] = useState("File.doc");
  if (fileName === undefined && status === 1) {
    setStatus(0);
  }
  const [fileSize, setFileSize] = useState({ size: 0, unit: "B" });
  const [fileType, setFileType] = useState("");  //docx doc pdf
  const [macroType, setMacroType] = useState(0);  //word pdf
  const CancelToken = axios.CancelToken;
  const [cancelToken, setCancelToken] = useState(null);
  useEffect(() => {
    if (defaultFile && defaultFile !== "") {
      setStatus(1);
    } else {
      setStatus(0);
      document.getElementById('hiddenFilesInput').value = null;
    }
  }, [defaultFile]);

  useEffect(() => {
    if ((uploadFlag === "success" || uploadFlag === true)) {
      setProgress(100);
      setTimeout(() => {
        setStatus(1);
        setProgress(0);
        setCancelToken(CancelToken.source());
      }, 1000);
    } else if (uploadFlag === "idle" || uploadFlag === "error" || uploadFlag === false) {
      setStatus(0);
      setProgress(0);
      setCancelToken(CancelToken.source());
    }
  }, [uploadFlag]);

  useEffect(() => {
    const {
      name,
      type,
      size
    } = parseFileInfo(defaultFile);
    setCancelToken(CancelToken.source());
    if (defaultFile !== null && defaultFile !== "") {
      setStatus(1);
      if (size === '???' && inifileSize !== null) setFileSize(getAttachmentSizeInfo(inifileSize));
      else setFileSize(getAttachmentSizeInfo(Number(size)));
    } else {
      setStatus(0);
      document.getElementById('hiddenFilesInput').value = null;
      setFileSize({ size: 0, unit: "B" });
    }
    setFileName(name);
    setFileType(type);
  }, []);

  useEffect(() => {
    if (fileType === "") {
      setMacroType(-1);
    }
    if (fileType === "docx" || fileType === "doc") {
      setMacroType(0);
    }
    if (fileType === "pdf")
      setMacroType(1);
  }, [fileType]
  );

  function abortRead() {
    cancelToken.cancel("Operation canceled by the user.");
    document.getElementById('hiddenFilesInput').value = null;
    if (progress != 100) {
      setStatus(0);
      setProgress(0);
      setCancelToken(CancelToken.source());
    }
  }

  function deleteFile() {
    handleDelete();
    document.getElementById('hiddenFilesInput').value = null;
    setStatus(0);
    setProgress(0);
    setCancelToken(CancelToken.source());
  }

  function formatCheck(file) {
    if (file?.type in avalableTypes) {
      return true;
    }
    return false;
  }

  // 选择上传文件后的方法
  function handleFileSelect(e) {
    let targetFile;
    if (e.type === 'drop') {
      targetFile = e.dataTransfer.files[0];
    } else {
      targetFile = e.target.files[0];
    }
    if (!formatCheck(targetFile)) {
      document.getElementById('hiddenFilesInput').value = null;
      setStatus(3);
      return;
    }
    setFileSize(getAttachmentSizeInfo(targetFile.size));
    setFileName(hiddenFilenameTransform(targetFile.name));
    setFileType(hiddenFiletypeTransform(targetFile.name));
    if (targetFile.size > 5 * 1024 * 1024) setStatus(3);
    else {
      handleUpload(targetFile, cancelToken);
      setStatus(2);
    }
  }

  const onUpload = (e) => {
    setStatus(2);
    handleFileSelect(e);
  };

  const container = () => {
    if (status === 0) {
      return (
        <FilesDragAndDrop
          onUpload={onUpload}
          count={1}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Box
              sx={{
                height: "40px",
                pt: "11px",
                pl: "6px"
              }}
            >
              {placeholder}
            </Box>
            <Box
              sx={{
                height: "40px",
                pt: "9px",
                pr: "6px",
                cursor: "pointer"
              }}
              onClick={() => {
                document.getElementById('hiddenFilesInput').click();
              }}
              id='fileUploadIcon'
            >
              <UploadIcon id='fileUploadIcon' />
            </Box>
          </Stack>
        </FilesDragAndDrop>
      );
    } else if (status === 1) {
      return (
        <LabelFile
          FileIcon={macroType === 0 ? WordIcon : PDFIcon}
          del={deleteFile}
          fileName={fileName}
          fileSize={fileSize}
          fileType={fileType}
        />
      );
    } else if (status === 2) {
      return (
        <ProgressFile
          FileIcon={macroType === 0 ? WordIcon : PDFIcon}
          value={progress}
          abort={abortRead}
          fileName={fileName}
          fileSize={fileSize}
          fileType={fileType}
        />
      );
    } else {
      return (
        <FilesDragAndDrop
          onUpload={onUpload}
          count={1}>
          <ErrorBox
            maxSize={'5M'}
            allowTypes={'PDF or Word'}
            onfileSelect={handleFileSelect}
            height={'99px'}
          />
        </FilesDragAndDrop>
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "402px",
          border: "1px solid #DFE4E8",
          borderRadius: "4px",
        }}
      >
        {container()}
      </Box>
      <Typography
        sx={{
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontSeight: '400',
          lineHeight: '15px',
          letterSpacing: '0em',
          textAlign: 'left',
          color: '#596A7C',
        }}
      >
        {status !== 3 ? hint : ''}
      </Typography>
      <input type="file" id="hiddenFilesInput" accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" name="file" onChange={handleFileSelect} style={{ display: "none" }} />
    </>
  );

}

import * as actions from '../actions/SIMT-User/Login/LoginAction';
import { getPermissionList } from '../utils/authTokenHandler';
import MyLocalStorage from "../utils/MyLocalStorage";

const initState = {
  auth: localStorage.getItem('authorization'),
  isAuthenticated: false,
  userId: null,
  ifFirst: false,
  errorMsg: null,
  count: 0,
  forgetPassEmail: null,
  linkValid: null,
  updatePass: null,
  resetPass: null,
  updatePassNotificationsTrigger: 0,
  loginFailCount: 0,
};

export default function LoginReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.LOGIN_SUCCESS:
      localStorage.setItem('authorization', data.headers.authorization);
      localStorage.setItem(
        'userPermissions',
        getPermissionList(data.headers.authorization)
      );
      return {
        ...initState,
        auth: data.headers.authorization,
        isAuthenticated: true,
        userId: data.data.id,
        ifFirst: data.data.ifFirst,
        errorMsg: null,
        count: 0,
      };
    case actions.LOGIN_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        userId: null,
        ifFirst: false,
        errorMsg: data,
      };
    case actions.LOGOUT:
      localStorage.removeItem('authorization');
      localStorage.removeItem('hasFooterLink');
      MyLocalStorage.removeItem('userInfo');
      return {
        ...state,
        auth: null,
        isAuthenticated: false,
        userId: null,
        ifFirst: false,
        errorMsg: null,
      };
    case actions.SEND_FORGETPASS_EMAIL:
      return {
        ...state,
        forgetPassEmail: data,
      };
    case actions.CLEAR_LOGIN:
      return {
        ...initState,
        auth: '',
      };
    case actions.CHECK_LINK_VALID:
      return {
        ...state,
        linkValid: data,
      };
    case actions.UPDATE_PASSWORD:
      return {
        ...state,
        updatePass: data,
        updatePassNotificationsTrigger: state.updatePassNotificationsTrigger+1,
      };
    case actions.RESETPASS:
      return {
        ...state,
        resetPass: data,
      };
    case actions.FIRST_CLEAR:
      return {
        ...initState,
        ifFirst: state.ifFirst,
      };
    case actions.VALID_CLEAR:
      return {
        ...state,
        linkValid: null,
      };
    case actions.FIRST_FORGET_PASS:
      return {
        ...state,
        ifFirst: true,
        forgetPassEmail: data,
      };
    case actions.LOGIN_FAILED_CLEAR:
      return {
        ...state,
        isAuthenticated: false,
        errorMsg: null,
      };
    case actions.GET_LOGIN_FAIL_COUNT_FAIL:
      return {
        ...state,
        loginFailCount: 0,
      };
    case actions.GET_LOGIN_FAIL_COUNT_SUCCESS:
      return {
        ...state,
        loginFailCount: data,
      };
    case actions.SET_FORGET_PASS_EMAIL_MESSAGE:
      return {
        ...state,
        forgetPassEmail: false,
      };
    default:
      return state;
  }
}

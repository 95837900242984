import React, {useEffect, useMemo, useState} from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { StyledTableCell, TableFooter2 } from '@/components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { visuallyHidden } from '@mui/utils';

import {
  StyledTableCellPurple,
  StyledTableCellDisPurple,
  StyledTableCellGreen,
  StyledTableCellDisGreen,
  StyledTableRow, StyledTableCellNormal, StyledTableCellDisGray, StyledTableCellGray, TableBox, ReportNoData, TableBox2
} from "../../ReportSpecificComponents/SpecialColorTableHeader";

import { findLongestLineSubstring, measureLongestWidth, measureWidth } from "../../../../utils/StringWidthMeasure";
import {SimpleTooltip} from "../../../../componentsphase2/CustomTooltip";
import {isReportOverCutOffDate} from "@/utils/commonUtils";
import { useShowReportRolesByFyHook } from '@/pages/Report/utils/useShowReportRolesByFyHook';
import {getReportArticleOverviewBasicTableData} from "@/actions/SIMT-Report/Report/ReportArticle";

export default function ArticleOverviewBasicTable(props) {
  const { setIfNoResult } = props;
  const [showJPM, showNewJournalRoles] = useShowReportRolesByFyHook();
  const [loading, setLoading] = useState(false);
  //显示文字的Box，超出长度省略号显示，鼠标悬浮出现tip
  function TextLabel(props) {
    const { text, TooltipText } = props;

    return (
      <Box>
        <Tooltip title={TooltipText ? TooltipText : text}>
          <Box
            component='div'
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {text}
          </Box>
        </Tooltip>
      </Box>
    );
  }

  const createRow = (
    id,
    label,
    minWidth,
    maxWidth,
    isOrder = false,
    align = 'left',
    show = true
  ) => {
    return { id, label, minWidth, maxWidth, isOrder, align, show };
  };

  const itemsBeforeJPM = useMemo(() => {
    return [
      createRow(
          'journalName',
          'Journal Information',
          '180px',
          '220px',
          false,
          'left'
      ),
      createRow('revenueModel', 'Revenue Model', '180px', '180px', false, 'left'),
      createRow(
          'journalStatus',
          'Journal Status',
          '180px',
          '180px',
          false,
          'left'
      ),
    ];
  },[]);

  const itemsAfterJPM = useMemo(() => {
    return [
      createRow('subjectGroup', 'Subject Group', '180px', '180px', false, 'left'),
      createRow(
          'businessDivision',
          'Business Division',
          '180px',
          '340px',
          false,
          'left'
      ),
      createRow('catGroup', 'CAT Group', '180px', '340px', false, 'left'),
      createRow('handlingCe', 'Handling CE', '180px', '340px', false, 'left'),
    ];
  },[]);

  const JPMItem = useMemo(() => {
    return createRow('jpm', 'JPM', '105px', '180px', false, 'left');
  }, []);


  const pdpItem = useMemo(() => {
    return createRow(
        'pdPublisher',
        'PD Publisher',
        '105px',
        '180px',
        false,
        'left'
    );
  }, []);

  const pppItem = useMemo(() => {
    return createRow(
        'ppPublisher',
        'PP Publisher',
        '105px',
        '180px',
        false,
        'left'
    );
  }, []);

  const Subitems = [
    {
      id: 'Sub-MMM',
      label: 'Sub - ',
      maxWidth: '150px',
      minWidth: '100px',
      post: 'submittedArt',
      isOrder: false,
    },
    {
      id: 'Sub-YTD',
      label: 'Sub - YTD',
      maxWidth: '150px',
      minWidth: '100px',
      post: 'submittedArtYtd',
      isOrder: false,
    },
    {
      label: '% Against YTD Target',
      id: '%Against YTD Target',
      maxWidth: '150px',
      minWidth: '100px',
      isOrder: false,
      post: 'submittedArtYtdTarget',
    },
  ];
  const Accitems = [
    {
      id: 'Acc-MMM',
      label: 'Acc - ',
      maxWidth: '150px',
      minWidth: '100px',
      isOrder: false,
      post: 'acceptedArt',
    },
    {
      id: 'Acc-YTD',
      label: 'Acc - YTD',
      maxWidth: '150px',
      minWidth: '100px',
      isOrder: false,
      post: 'acceptedArtYtd',
    },
    {
      id: '%Against YTD Target',
      label: '% Against YTD Target',
      maxWidth: '150px',
      minWidth: '100px',
      isOrder: false,
      post: 'acceptedArtYtdTarget',
    },
  ];
  const Pubitems = [
    {
      id: 'Pub-MMM',
      label: 'Pub - ',
      maxWidth: '150px',
      minWidth: '100px',
      isOrder: false,
      post: 'publishedArt',
    },
    {
      id: 'Pub-YTD',
      label: 'Pub - YTD',
      maxWidth: '150px',
      minWidth: '100px',
      isOrder: false,
      post: 'publishedArtYtd',
    },
    {
      id: '%Against YTD Target',
      label: '% Against YTD Target',
      maxWidth: '150px',
      minWidth: '100px',
      isOrder: false,
      post: 'publishedArtYtdTarget',
    },
  ];

  const [page, setPage] = useState(1);
  const [perPageNumber, setPerPageNumber] = useState(20);

  const [orderType, setOrderType] = useState('journalName'); //排序的表头
  const [orderAs, setOrderAs] = useState('asc'); //排序的顺序
  const [flashLock, setFlashLock] = useState(true);

  const dispatch = useDispatch();
  const {
    articleOverviewBasicTableData,
    tableMonth,
    selectedArticleDate,
    articleOverviewFilters,
    cutOffDate,
  } = useSelector(state => {
    return {
      // filter display
      articleOverviewBasicTableData: state.Report.articleOverviewBasicTableData,
      tableMonth: state.Report.articleOverviewSubjectTableMonth,
      // selectedArticleDate: state.Report.selectedArticleDate,
      selectedArticleDate: state.Report.selectDate,
      articleOverviewFilters: state.ReportFilter.articleOverviewFilters,
      cutOffDate: state.Report.cutOffDate,
    };
  });

  const items = useMemo(() => {
    let flexItem = [];
    if (showJPM) {
      flexItem = [...flexItem, JPMItem];
    }
    if (showNewJournalRoles) {
      flexItem = [...flexItem, pdpItem, pppItem];
    }
    return [...itemsBeforeJPM, ...flexItem, ...itemsAfterJPM];
  }, [showJPM, showNewJournalRoles]);


  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    for (let item of items){
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '18px Open Sans');
    }

    const CUR_LEN = measureLongestWidth('Sub - APR', '18px Open Sans');
    const YTD_LEN = measureLongestWidth('Sub - YTD', '18px Open Sans');
    const OAP_LEN = measureLongestWidth('% Against YTD Target', '18px Open Sans');

    for (let type of ['sub','acc','pub']) {
      widthMap[`${type}-0`] = CUR_LEN + 18;
      widthMap[`${type}-1`] = YTD_LEN + 18;
      widthMap[`${type}-2`] = OAP_LEN + 18;
    }

    setColumnMinWidthMap(widthMap);
  }, [items]);

  useEffect(() => {
    if (articleOverviewBasicTableData && articleOverviewBasicTableData.count > 0){
      const maxMap = {};
      for (let row of articleOverviewBasicTableData['result']) {
        let {
          journalCode,
          journal,
          revenueModel,
          journalStatus,
          jpm,
          businessDivision,
          catGroup,
          handlingCe,
        } = row;
        let dataObj = {
          journalCode,
          journal,
          revenueModel,
          journalStatus,
          jpm,
          businessDivision,
          catGroup,
          handlingCe,
          subjectGroup: row.sgAbbr ?? row.subjectGroup,

          'sub-0':  row.submittedArt ?? '0',
          'sub-1':  row.submittedArtYtd ?? '0',
          'sub-2':  row.submittedArtYtdTarget ?? '0',

          'acc-0':  row.acceptedArt ?? '0',
          'acc-1':  row.acceptedArtYtd ?? '0',
          'acc-2':  row.acceptedArtYtdTarget ?? '0',

          'pub-0':  row.publishedArt ?? '0',
          'pub-1':  row.publishedArtYtd ?? '0',
          'pub-2':  row.publishedArtYtdTarget ?? '0',
        };
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      widthMap['journalName'] = Math.max(widthMap['journalCode'],widthMap['journal']);

      setColumnWidthMap(widthMap);
    }
  }, [articleOverviewBasicTableData]);

  function calcGroupWidth(type){
    let sum = 0;
    for (let i of [0,1,2]) {
      sum += Math.max((columnWidthMap[`${type}-${i}`]??0), columnMinWidthMap[`${type}-${i}`]) + 50;
    }
    return sum;
  }

  const handlePerPageChange = e => {
    setPerPageNumber(e.target.value);
  };

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  const handleChangeOrder = orderBy => {
    let newOrder;
    if (orderType === orderBy) {
      // 如果当前的排序字段没有变化，则切换正反序
      newOrder = orderAs === 'desc' ? 'asc' : 'desc';
    } else {
      // 如果当前排序字段发生变化，则直接按照新的字段升序
      newOrder = 'asc';
    }
    setOrderAs(newOrder);
    setOrderType(orderBy);
  };

  const ifEmptyFilter = () => {
    try {
      if (
        articleOverviewFilters.journalCode.length === 0 &&
        articleOverviewFilters.revenueModel.length === 0 &&
        articleOverviewFilters.jpm.length === 0 &&
        articleOverviewFilters.subjectGroup.length === 0 &&
        articleOverviewFilters.businessDivision.length === 0 &&
        articleOverviewFilters.catGroup.length === 0 &&
        articleOverviewFilters.handlingCe.length === 0
      ) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  function handleNull(num) {
    if (num === null || num === undefined) {
      return '-';
    } else {
      return num;
    }
  }

  useEffect(() => {
    async function fetchData() {
      if (!flashLock) {
        await dispatch(
          getReportArticleOverviewBasicTableData(
            page,
            perPageNumber,
            orderAs,
            orderType,
            selectedArticleDate,
            articleOverviewFilters
          )
        );
      }
      setLoading(false);
      setFlashLock(false);
    }
    setLoading(true);
    if (page === 0) {
      if (!flashLock) {
        setTimeout(() => {
          setPage(1);
        }, 2000);
      }
    } else {
      fetchData();
    }
  }, [page]); // 表格本身属性触发的加载，一切按照默认

  useEffect(() => {
    setFlashLock(false);
    setPage(0);
  }, [
    articleOverviewFilters,
    perPageNumber,
    orderType,
    orderAs,
    selectedArticleDate,
  ]); // 表格本身属性触发的加载，一切按照默认

  useEffect(() => {
    var filterEmpty = ifEmptyFilter();
    if (
      (articleOverviewBasicTableData === null ||
        articleOverviewBasicTableData === undefined ||
        articleOverviewBasicTableData.count === 0) &&
      filterEmpty
    ) {
      setIfNoResult(true);
    } else {
      setIfNoResult(false);
    }
  }, [articleOverviewBasicTableData]);

  return (
    <TableBox2
      id='BasicTableBox'
      data-selenium-id='Report_Articles_Overview-BasicTableBox'
      dataList={[articleOverviewBasicTableData, loading]}
      tableKey={'Report_Articles_Overview-BasicTable'}
      footerHeight={40}
    >
      <TableContainer
        id='reportArticlesOverviewBasicTable'
        data-selenium-id='Report_Articles_Overview-BasicTable'
        sx={{ minHeight: '300px', maxHeight: '680px' }}
      >
        <Table
          stickyHeader
          aria-label='sticky table'
          size='small'
          sx={{
            tableLayout: 'fixed',
            width: 'max-content',
            maxWidth: 'none',
          }}
        >
          <TableHead data-selenium-id='Report_Articles_Overview-BasicTableHead'>
            <TableRow data-selenium-id='Report_Articles_Overview-BasicTableRow'>
              {items.map(item => (
                <StyledTableCellNormal
                  key={item.label}
                  align={item.align}
                  rowSpan={2}
                  sx={{
                    // minWidth: item.minWidth,
                    // maxWidth: item.maxWidth,
                    backgroundColor: '#F1F3F5',
                    ...(item.id==='journalName' && {
                      position: 'sticky',
                      left: 0,
                      top: 0,
                      zIndex: 8,
                      backgroundColor: '#DFE4E8',
                    }),
                    width: `${Math.max((columnWidthMap[item.id]??0), columnMinWidthMap[item.id]) + 50 }px`,
                  }}
                  sortDirection={orderType === item.id ? orderType : false}
                  className={
                    item.id === 'handlingCe'
                      ? 'RightBottomBorder'
                      : 'BottomBorder'
                  }
                >
                  {item.isOrder ? (
                    <TableSortLabel
                      id={`articlesOverviewSortColumn-${item.id}`}
                      data-selenium-id={`Report_Articles_Overview-SortColumn-${item.id}`}
                      active={item.isOrder && orderType === item.id}
                      direction={orderType === item.id ? orderAs : 'asc'}
                      onClick={() => {
                        if (item.isOrder) {
                          handleChangeOrder(item.id);
                        }
                      }}
                    >
                      {item.id === 'acquiredSi'
                        ? item.label + tableMonth
                        : item.label}
                      {orderType === item.id ? (
                        <Box component='span' sx={visuallyHidden}>
                          {orderAs === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  ) : item.id === 'acquiredSi' ? (
                    item.label + tableMonth
                  ) : (
                    item.label
                  )}
                </StyledTableCellNormal>
              ))}
              <StyledTableCellGray
                align='left'
                colSpan={3}
                className='RightBottomBorder'
                data-selenium-id='Report_Articles_Overview-BasicTable-Submission'
                sx={{
                  width: `${calcGroupWidth('sub')}px`,
                }}
              >
                Submission
              </StyledTableCellGray>
              <StyledTableCellGray
                align='left'
                colSpan={3}
                className='RightBottomBorder'
                data-selenium-id='Report_Articles_Overview-BasicTable-Acceptance'
                sx={{
                  width: `${calcGroupWidth('acc')}px`,
                }}
              >
                Acceptance
              </StyledTableCellGray>
              <StyledTableCellGray
                align='left'
                colSpan={3}
                className='RightBottomBorder'
                data-selenium-id='Report_Articles_Overview-BasicTable-Publication'
                sx={{
                  width: `${calcGroupWidth('pub')}px`,
                }}
              >
                Publication
              </StyledTableCellGray>
            </TableRow>
            <TableRow>
              {Subitems.map((item, index) => (
                <StyledTableCellDisGray
                  align='left'
                  key={item.id}
                  sx={{
                    // top: 32.5,
                    top: 42,
                    // maxWidth: item.maxWidth,
                    // minWidth: item.minWidth,
                    width: `${Math.max((columnWidthMap[`sub-${index}`]??0), columnMinWidthMap[`sub-${index}`]) + 50 }px`,
                  }}
                  className={
                    item.id === '%Against YTD Target'
                      ? 'RightBottomBorder'
                      : 'BottomBorder'
                  }
                >
                  <TableSortLabel
                    id={`${item.id}SortLabel`}
                    data-selenium-id={`Report_Articles_Overview-BasicTable-${item.id}SortLabel`}
                    active={orderType === item.post}
                    direction={orderType === item.post ? orderAs : 'asc'}
                    onClick={() => {
                      handleChangeOrder(item.post);
                    }}
                  >
                    {item.id === 'Sub-MMM'
                      ? item.label + tableMonth
                      : item.label}
                  </TableSortLabel>
                </StyledTableCellDisGray>
              ))}
              {Accitems.map((item, index) => (
                <StyledTableCellDisGray
                  align='left'
                  key={item.id}
                  sx={{
                    // top: 32.5,
                    top: 42,
                    // maxWidth: item.maxWidth,
                    // minWidth: item.minWidth,
                    width: `${Math.max((columnWidthMap[`acc-${index}`]??0), columnMinWidthMap[`acc-${index}`]) + 50 }px`,
                  }}
                  className={
                    item.id === '%Against YTD Target'
                      ? 'RightBottomBorder'
                      : 'BottomBorder'
                  }
                >
                  <TableSortLabel
                    id={`${item.id}SortLabel`}
                    data-selenium-id={`Report_Articles_Overview-BasicTable-${item.id}SortLabel`}
                    active={orderType === item.post}
                    direction={orderType === item.post ? orderAs : 'asc'}
                    onClick={() => {
                      handleChangeOrder(item.post);
                    }}
                  >
                    {item.id === 'Acc-MMM'
                      ? item.label + tableMonth
                      : item.label}
                  </TableSortLabel>
                </StyledTableCellDisGray>
              ))}
              {Pubitems.map((item, index) => (
                <StyledTableCellDisGray
                  align='left'
                  key={item.id}
                  sx={{
                    // top: 32.5,
                    top: 42,
                    // maxWidth: item.maxWidth,
                    // minWidth: item.minWidth,
                    width: `${Math.max((columnWidthMap[`pub-${index}`]??0), columnMinWidthMap[`pub-${index}`]) + 50 }px`,
                  }}
                  className={
                    item.id === '%Against YTD Target'
                      ? 'RightBottomBorder'
                      : 'BottomBorder'
                  }
                >
                  <TableSortLabel
                    id={`${item.id}SortLabel`}
                    data-selenium-id={`Report_Articles_Overview-BasicTable-${item.id}SortLabel`}
                    active={orderType === item.post}
                    direction={orderType === item.post ? orderAs : 'asc'}
                    onClick={() => {
                      handleChangeOrder(item.post);
                    }}
                  >
                    {item.id === 'Pub-MMM'
                      ? item.label + tableMonth
                      : item.label}
                  </TableSortLabel>
                </StyledTableCellDisGray>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
            articleOverviewBasicTableData != null &&
            articleOverviewBasicTableData.count > 0 ? (
                articleOverviewBasicTableData['result']?.map((row, index) => {
                  if (row !== null) {
                    return (
                      <StyledTableRow
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Row-${index}`}
                        key={row.id}
                        aria-checked={false}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        selected={false}
                      >
                        <StyledTableCell
                          sx={{
                            fontWeight: 600,
                            // maxWidth: '110px'
                            position: 'sticky',
                            left: 0,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell1-${index}`}
                        >
                          <Box
                            sx={{
                              fontSize: '16px',
                              fontWeight: 400,
                              lineHeight: '14px',
                              color: '#484848',
                            }}
                          >
                            {row.journalCode}
                          </Box>
                          <Box
                            sx={{
                              // fontSize: '14px',
                              color: '#747474',
                              fontWeight: 400,
                            }}
                          >
                            {row.journal}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell2-${index}`}
                        >
                          {row.revenueModel}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell3-${index}`}
                        >
                          {row.journalStatus}
                        </StyledTableCell>

                        {/* JPM */}
                        {showJPM && (
                        <StyledTableCell
                          align='left'
                          sx={{ maxWidth: '50px' }}
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell3-${index}`}
                        >
                          <TextLabel
                            text={row.jpm}
                            TooltipText={row.emailOfJpm}
                          />
                        </StyledTableCell>
                        )}

                        {/* PD Publisher */}
                        {showNewJournalRoles && (
                            <StyledTableCell
                                align='left'
                                data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell4-PDP`}
                            >
                              <TextLabel
                                  text={row.fullNameOfPdp}
                                  TooltipText={row.emailOfPdp}
                              />
                            </StyledTableCell>
                        )}

                        {/* PP Publisher */}
                        {showNewJournalRoles && (
                            <StyledTableCell
                                align='left'
                                data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell4-PPP`}
                            >
                              <TextLabel
                                  text={row.fullNameOfPpp}
                                  TooltipText={row.emailOfPpp}
                              />
                            </StyledTableCell>
                        )}

                        <StyledTableCell
                          align='left'
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell4-${index}`}
                        >
                          <SimpleTooltip title={row.subjectGroup}>
                            {row.sgAbbr}
                          </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell5-${index}`}
                        >
                          <TextLabel text={row.businessDivision} />
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell6-${index}`}
                        >
                          <TextLabel text={row.catGroup} />
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder '
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell7-${index}`}
                        >
                          <TextLabel
                            text={row.handlingCe}
                            TooltipText={row.emailOfHandlingCe}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell8-${index}`}
                        >
                          <TextLabel text={handleNull(row.submittedArt)} />
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell9-${index}`}
                        >
                          <TextLabel text={handleNull(row.submittedArtYtd)} />
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportNumberTextColor'
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell10-${index}`}
                        >
                          <TextLabel
                            text={handleNull(row.submittedArtYtdTarget)}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell11-${index}`}
                        >
                          <TextLabel text={handleNull(row.acceptedArt)} />
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell12-${index}`}
                        >
                          <TextLabel text={handleNull(row.acceptedArtYtd)} />
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportNumberTextColor'
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell13-${index}`}
                        >
                          <TextLabel
                            text={handleNull(row.acceptedArtYtdTarget)}
                          />
                        </StyledTableCell>

                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell14-${index}`}
                        >
                          <TextLabel text={handleNull(row.publishedArt)} />
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell15-${index}`}
                        >
                          <TextLabel text={handleNull(row.publishedArtYtd)} />
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell16-${index}`}
                        >
                          <TextLabel
                            text={handleNull(row.publishedArtYtdTarget)}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  }
                })
              ) : !loading ? (
                <ReportNoData top='40px' id={'Report-Article-Overview-BasicTable-NoData'}/>
              ) : (
                <div></div>
              )}
          </TableBody>
        </Table>
        {loading && (
          <Box
            sx={{
              width: 'auto',
              marginTop: '150px',
              marginLeft: '50%',
            }}
            data-selenium-id='Report_Articles_Overview-BasicTable-Box'
          >
            <CircularProgress />
          </Box>
        )}
      </TableContainer>
      <Box>
        <Divider />
        {articleOverviewBasicTableData != null &&
        articleOverviewBasicTableData['count'] > 0 ? (
            <TableFooter2
              numSelected={0}
              data-selenium-id='Report_Articles_Overview-BasicTable-TableFooter2'
              Page={page}
              perPageNumber={perPageNumber}
              perPageList={[20, 50, 100]}
              count={
                articleOverviewBasicTableData != null
                  ? articleOverviewBasicTableData['count']
                  : 0
              }
              handlePerPageChange={handlePerPageChange}
              handlePageChange={handlePageChange}
              // backgroundColor='#CCD4FF'
              backgroundColor='#F1F3F5'
            />
          ) : (
            <div></div>
          )}
      </Box>
    </TableBox2>
  );
}

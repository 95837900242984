/**
 * Huang Tao
 */
import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TableCell,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '@/components/DataTable';
import {
  PurpleGreenCell, PurpleGreenDisCell, PurpleGreenRow, ReportNoData,
  StyledTableCellDisPurple, StyledTableCellNormal,
  StyledTableCellPurple,
  StyledTableRow, TableBox, TableBox2, TableLabel, TwoColorTableRowPurple
} from "../../ReportSpecificComponents/SpecialColorTableHeader";
import { StyledSwitchTwoColor } from '../../ReportSpecificComponents/SpecialColorSwitch';
import { findLongestLineSubstring, measureLongestWidth, measureWidth } from "../../../../utils/StringWidthMeasure";
import { SimpleTooltip } from "../../../../componentsphase2/CustomTooltip";
import {GetArticleOverviewSubjectTableData} from "@/actions/SIMT-Report/Report/ReportArticle";

export function ArticleOverviewSubjectGroupTable(props) {
  const { ifNoResult } = props;
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  //获取选择的日期和table2的数据
  const { currentDate, tableMonth, table2Data } = useSelector(state => {
    return {
      table2Data: state.Report.articleOverviewSubjectTableData,
      tableMonth: state.Report.articleOverviewSubjectTableMonth,
      // currentDate: state.Report.selectedArticleDate,
      currentDate: state.Report.selectDate,
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    widthMap['subjectGroup'] = measureWidth(findLongestLineSubstring('Subject Group'), '18px Open Sans');

    const CUR_LEN = measureLongestWidth('Sub - APR', '18px Open Sans');
    const YTD_LEN = measureLongestWidth('Sub - YTD', '18px Open Sans');
    const OAP_LEN = measureLongestWidth('% Against YTD Target', '18px Open Sans');
    const OAP_LEN2 = measureLongestWidth('%Against YTD Stretch target', '18px Open Sans');

    for (let type of ['sub','acc','pub']) {
      widthMap[`${type}-0`] = CUR_LEN;
      widthMap[`${type}-1`] = YTD_LEN;
      widthMap[`${type}-2`] = OAP_LEN;
      widthMap[`${type}-3`] = OAP_LEN2;
    }

    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (table2Data && table2Data.length > 0){
      const maxMap = {};
      for (let i = 0; i < table2Data.length; i++) {
        let row = table2Data[i];
        let dataObj = {
          subjectGroup: row.sgAbbr ?? row.subjectGroup,

          'sub-0':  row.submittedArt ?? '0',
          'sub-1':  row.submittedArtYtd ?? '0',
          'sub-2':  row.submittedArtYtdTarget ?? '0',
          'sub-3':  row.submittedArtYtdStretchTarget ?? '0',

          'acc-0':  row.acceptedArt ?? '0',
          'acc-1':  row.acceptedArtYtd ?? '0',
          'acc-2':  row.acceptedArtYtdTarget ?? '0',
          'acc-3':  row.acceptedArtYtdStretchTarget ?? '0',

          'pub-0':  row.publishedArt ?? '0',
          'pub-1':  row.publishedArtYtd ?? '0',
          'pub-2':  row.publishedArtYtdTarget ?? '0',
          'pub-3':  row.publishedArtYtdStretchTarget ?? '0',
        };
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [table2Data]);

  function calcGroupWidth(type){
    let sum = 0;
    for (let i of [0,1,2]) {
      sum += Math.max((columnWidthMap[`${type}-${i}`]??0), columnMinWidthMap[`${type}-${i}`]) + 50;
    }
    if (!OASwitch){
      sum += Math.max((columnWidthMap[`${type}-${3}`]??0), columnMinWidthMap[`${type}-${3}`]) + 50;
    }
    return sum;
  }

  const [OASwitch, setOASwitch] = useState(true);

  const handleChangeSwitch = () => {
    setOASwitch(!OASwitch);
  };

  useEffect(() => {
    async function getData(currentDate) {
      if (currentDate != null) {
        setIsLoading(true);
        setTimeout(() => {
          dispatch(GetArticleOverviewSubjectTableData(currentDate, !OASwitch));
        }, 2000);
      }
    }

    getData(currentDate);
  }, [currentDate, OASwitch]);

  useEffect(() => {
    setIsLoading(false);
  }, [table2Data]);

  const Subitems = [
    {
      id: 'Sub-MMM',
      label: 'Sub - ',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: 'Sub-YTD',
      label: 'Sub - YTD',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: '%Against YTD Target',
      label: '% Against YTD Target',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: '%Against YTD Stretch target',
      label: '% Against YTD Stretch target',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
  ];
  const Accitems = [
    {
      id: 'Acc-MMM',
      label: 'Acc - ',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: 'Acc-YTD',
      label: 'Acc - YTD',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: '%Against YTD Target',
      label: '% Against YTD Target',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: '%Against YTD Stretch target',
      label: '% Against YTD Stretch target',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
  ];
  const Pubitems = [
    {
      id: 'Pub-MMM',
      label: 'Pub - ',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: 'Pub-YTD',
      label: 'Pub - YTD',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: '%Against YTD Target',
      label: '% Against YTD Target',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: '%Against YTD Stretch target',
      label: '% Against YTD Stretch target',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
  ];

  //如果tablle2Data为空或undefined
  const showDataTableSG = table2Data => {
    if (table2Data === undefined || table2Data === null || table2Data === '') {
      return [];
    } else return table2Data;
  };

  //total字体加深
  function DisplayTooltip(props) {
    const { ifTotal } = props;
    if (ifTotal === 'Total')
      return (
        <div align='right'>
          < >{ifTotal}</ >
        </div>
      );
    else {
      return <div align='left'>{ifTotal}</div>;
    }
  }

  return (
    <>
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        data-selenium-id='Report_Articles_SubjectGroup-Grid'
      >
        <TableLabel>Subject Group</TableLabel>
        <Grid
          sx={{ p: '25px 0px 0px 6px', marginRight: '30px', }}
          data-selenium-id='Report_Articles_SubjectGroup-OA'
        >
          <>
            <StyledSwitchTwoColor
              id='reportSubjectOaSwitch'
              data-selenium-id='Report_Articles_SubjectGroup-OaSwitch'
              checked={OASwitch}
              onChange={handleChangeSwitch}
            ></StyledSwitchTwoColor>
          </>
        </Grid>
      </Grid>
      <TableBox2
        id='BoxTable2'
        data-selenium-id='BoxTable2'
        dataList={[table2Data]}
        tableKey={'Report_Articles_Overview-SGTable'}
        footerHeight={0}
      >
        <TableContainer
          id='reportArticlesOverviewSGTable'
          data-selenium-id='Report_Articles-SGTable'
          sx={{ maxHeight: '600px' }}
        >
          <Table
            stickyHeader
            aria-label='sticky table'
            sx={{
              tableLayout: 'fixed',
              width: 'max-content',
              maxWidth: 'none',
            }}
          >
            <TableHead data-selenium-id='Report_Articles-SGTableHeader'>
              <TableRow data-selenium-id='Report_Articles-SGTableHeader-Row'>
                <StyledTableCellNormal
                  align='left'
                  rowSpan={2}
                  sx={{
                    // maxWidth: '200px',
                    // minWidth: '140px'
                    width: `${Math.max((columnWidthMap['subjectGroup']??0), columnMinWidthMap['subjectGroup']) + 50 }px`,
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                  }}
                  className='RightBottomBorder'
                  // style={{ backgroundColor: !OASwitch ? '#96DCA9' : '#CCD4FF' }}
                  data-selenium-id='Report_Articles-SGTableHeader-SubjectGroup'
                >
                  Subject Group
                </StyledTableCellNormal>
                <PurpleGreenCell
                  isOA={!OASwitch}
                  align='left'
                  colSpan={!OASwitch ? 4 : 3}
                  className='RightBottomBorder'
                  // style={{ backgroundColor: !OASwitch ? '#96DCA9' : '#CCD4FF' }}
                  data-selenium-id='Report_Articles-SGTableHeader-Submission'
                  sx={{
                    width: `${calcGroupWidth('sub')}px`,
                  }}
                >
                  Submission
                </PurpleGreenCell>
                <PurpleGreenCell
                  align='left'
                  colSpan={!OASwitch ? 4 : 3}
                  className='RightBottomBorder'
                  isOA={!OASwitch}
                  data-selenium-id='Report_Articles-SGTableHeader-Acceptance'
                  sx={{
                    width: `${calcGroupWidth('acc')}px`,
                  }}
                >
                  Acceptance
                </PurpleGreenCell>
                <PurpleGreenCell
                  align='left'
                  colSpan={!OASwitch ? 4 : 3}
                  className='RightBottomBorder'
                  isOA={!OASwitch}
                  data-selenium-id='Report_Articles-SGTableHeader-Publication'
                  sx={{
                    width: `${calcGroupWidth('pub')}px`,
                  }}
                >
                  Publication
                </PurpleGreenCell>
              </TableRow>
              <TableRow>
                {Subitems.map((item, index) =>
                  OASwitch && item.id === '%Against YTD Stretch target' ? (
                    <></>
                  ) : (
                    <PurpleGreenDisCell
                      isOA={!OASwitch}
                      align='left'
                      key={item.id}
                      sx={{
                        // top: 52.5,
                        // maxWidth: item.maxWidth,
                        // minWidth: item.minWidth,
                        top: 42,
                        width: `${Math.max((columnWidthMap[`sub-${index}`]??0), columnMinWidthMap[`sub-${index}`]) + 50 }px`,
                      }}
                      className={
                        (!OASwitch &&
                          item.id === '%Against YTD Stretch target') ||
                        (OASwitch && item.id === '%Against YTD Target')
                          ? 'RightBottomBorder'
                          : 'BottomBorder'
                      }
                    >
                      {item.id === 'Sub-MMM' ||
                      item.id === 'Acc-MMM' ||
                      item.id === 'Pub-MMM'
                        ? item.label + tableMonth
                        : item.label}
                    </PurpleGreenDisCell>
                  )
                )}
                {Accitems.map((item, index) =>
                  OASwitch && item.id === '%Against YTD Stretch target' ? (
                    <></>
                  ) : (
                    <PurpleGreenDisCell
                      align='left'
                      key={item.id}
                      sx={{
                        // top: 52.5,
                        // maxWidth: item.maxWidth,
                        // minWidth: item.minWidth,
                        top: 42,
                        width: `${Math.max((columnWidthMap[`acc-${index}`]??0), columnMinWidthMap[`acc-${index}`]) + 50 }px`,
                      }}
                      className={
                        (!OASwitch &&
                          item.id === '%Against YTD Stretch target') ||
                        (OASwitch && item.id === '%Against YTD Target')
                          ? 'RightBottomBorder'
                          : 'BottomBorder'
                      }
                      isOA={!OASwitch}
                    >
                      {item.id === 'Sub-MMM' ||
                      item.id === 'Acc-MMM' ||
                      item.id === 'Pub-MMM'
                        ? item.label + tableMonth
                        : item.label}
                    </PurpleGreenDisCell>
                  )
                )}
                {Pubitems.map((item, index) =>
                  OASwitch && item.id === '%Against YTD Stretch target' ? (
                    <></>
                  ) : (
                    <PurpleGreenDisCell
                      align='left'
                      key={item.id}
                      sx={{
                        // top: 52.5,
                        // maxWidth: item.maxWidth,
                        // minWidth: item.minWidth,
                        top: 42,
                        width: `${Math.max((columnWidthMap[`pub-${index}`]??0), columnMinWidthMap[`pub-${index}`]) + 50 }px`,
                      }}
                      className={
                        (!OASwitch &&
                          item.id === '%Against YTD Stretch target') ||
                        (OASwitch && item.id === '%Against YTD Target')
                          ? 'RightBottomBorder'
                          : 'BottomBorder'
                      }
                      isOA={!OASwitch}
                    >
                      {item.id === 'Sub-MMM' ||
                      item.id === 'Acc-MMM' ||
                      item.id === 'Pub-MMM'
                        ? item.label + tableMonth
                        : item.label}
                    </PurpleGreenDisCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody data-selenium-id='Report_Articles-SGTableBody'>
              {!isLoading &&
              !ifNoResult &&
              table2Data != null &&
              table2Data != undefined &&
              table2Data.length != 0
                ? table2Data.map((row, index) => {
                    if (row.subjectGroup === 'Total') {
                      return (
                        <PurpleGreenRow isOA={!OASwitch}>
                          <StyledTableCell
                            align='right'
                            sx={{
                              // maxWidth: '150px'
                              position: 'sticky',
                              left: 0,
                              zIndex: 6,
                              backgroundColor: '#F1F3F5',
                            }}
                            className='RightBorder ReportTotalTextSize'
                            data-selenium-id={`Report_Articles-SGTableBody-Cell1-${index}`}
                          >
                            <SimpleTooltip title={row.subjectGroup}>
                                <DisplayTooltip ifTotal={row.subjectGroup} />
                              </SimpleTooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize ReportNumberTextColor'
                            data-selenium-id={`Report_Articles-SGTableBody-Cell2-${index}`}
                          >
                            < >
                              {row.submittedArt === null
                                ? '0'
                                : row.submittedArt}
                            </ >
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize ReportNumberTextColor'
                            data-selenium-id={`Report_Articles-SGTableBody-Cell3-${index}`}
                          >
                            < >
                              {row.submittedArtYtd === null
                                ? '0'
                                : row.submittedArtYtd}
                            </ >
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            data-selenium-id={`Report_Articles-SGTableBody-Cell4-${index}`}
                            className={
                              OASwitch
                                ? 'ReportTotalTextSize RightBorder ReportNumberTextColor'
                                : 'ReportTotalTextSize NoBorder ReportNumberTextColor'
                            }
                          >
                            < >
                              {' '}
                              {row.submittedArtYtdTarget === null
                                ? '0'
                                : row.submittedArtYtdTarget}
                            </ >
                          </StyledTableCell>
                          {!OASwitch ? (
                            <StyledTableCell
                              data-selenium-id={`Report_Articles-SGTableBody-Cell5-${index}`}
                              align='left'
                              className={
                                !OASwitch
                                  ? 'ReportTotalTextSize RightBorder ReportNumberTextColor'
                                  : 'ReportTotalTextSize NoBorder ReportNumberTextColor'
                              }
                            >
                              < >
                                {' '}
                                {row.submittedArtYtdStretchTarget === null
                                  ? '0'
                                  : row.submittedArtYtdStretchTarget}
                              </ >
                            </StyledTableCell>
                          ) : (
                            <></>
                          )}
                          <StyledTableCell
                            data-selenium-id={`Report_Articles-SGTableBody-Cell6-${index}`}
                            align='left'
                            className='ReportTotalTextSize ReportNumberTextColor'
                          >
                            < >
                              {' '}
                              {row.acceptedArt === null ? '0' : row.acceptedArt}
                            </ >
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize ReportNumberTextColor'
                            data-selenium-id={`Report_Articles-SGTableBody-Cell7-${index}`}
                          >
                            < >
                              {' '}
                              {row.acceptedArtYtd === null
                                ? '0'
                                : row.acceptedArtYtd}
                            </ >
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            data-selenium-id={`Report_Articles-SGTableBody-Cell8-${index}`}
                            className={
                              OASwitch
                                ? 'ReportTotalTextSize RightBorder ReportNumberTextColor'
                                : 'ReportTotalTextSize NoBorder ReportNumberTextColor'
                            }
                          >
                            < >
                              {' '}
                              {row.acceptedArtYtdTarget === null
                                ? '0'
                                : row.acceptedArtYtdTarget}
                            </ >
                          </StyledTableCell>
                          {!OASwitch ? (
                            <StyledTableCell
                              data-selenium-id={`Report_Articles-SGTableBody-Cell9-${index}`}
                              align='left'
                              className={
                                !OASwitch
                                  ? 'ReportTotalTextSize RightBorder ReportNumberTextColor'
                                  : 'ReportTotalTextSize NoBorder ReportNumberTextColor'
                              }
                            >
                              < >
                                {' '}
                                {row.acceptedArtYtdStretchTarget === null
                                  ? '0'
                                  : row.acceptedArtYtdStretchTarget}
                              </ >
                            </StyledTableCell>
                          ) : (
                            <></>
                          )}
                          <StyledTableCell
                            data-selenium-id={`Report_Articles-SGTableBody-Cell10-${index}`}
                            align='left'
                            className='ReportTotalTextSize ReportNumberTextColor'
                          >
                            < >
                              {' '}
                              {row.publishedArt === null
                                ? '0'
                                : row.publishedArt}
                            </ >
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize ReportNumberTextColor'
                            data-selenium-id={`Report_Articles-SGTableBody-Cell11-${index}`}
                          >
                            < >
                              {' '}
                              {row.publishedArtYtd === null
                                ? '0'
                                : row.publishedArtYtd}
                            </ >
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            data-selenium-id={`Report_Articles-SGTableBody-Cell12-${index}`}
                            className={
                              OASwitch
                                ? 'ReportTotalTextSize RightBorder ReportNumberTextColor'
                                : 'ReportTotalTextSize NoBorder ReportNumberTextColor'
                            }
                          >
                            < >
                              {' '}
                              {row.publishedArtYtdTarget === null
                                ? '0'
                                : row.publishedArtYtdTarget}
                            </ >
                          </StyledTableCell>
                          {!OASwitch ? (
                            <StyledTableCell
                              data-selenium-id={`Report_Articles-SGTableBody-Cell13-${index}`}
                              align='left'
                              className={
                                !OASwitch
                                  ? 'ReportTotalTextSize RightBorder ReportNumberTextColor'
                                  : 'ReportTotalTextSize NoBorder ReportNumberTextColor'
                              }
                            >
                              < >
                                {' '}
                                {row.publishedArtYtdStretchTarget === null
                                  ? '0'
                                  : row.publishedArtYtdStretchTarget}
                              </ >
                            </StyledTableCell>
                          ) : (
                            <></>
                          )}
                        </PurpleGreenRow>
                      );
                    }
                    return (
                      <PurpleGreenRow isOA={!OASwitch}>
                        <StyledTableCell
                          data-selenium-id={`Report_Articles-SGTableBody-Cell14-${index}`}
                          align='right'
                          sx={{
                            // maxWidth: '150px'
                            position: 'sticky',
                            left: 0,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                          className='RightBorder'
                        >
                          <SimpleTooltip title={row.subjectGroup}>
                              <DisplayTooltip
                                ifTotal={row.sgAbbr ?? row.subjectGroup}
                              />
                            </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id={`Report_Articles-SGTableBody-Cell15-${index}`}
                        >
                          {row.submittedArt === null ? '0' : row.submittedArt}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id={`Report_Articles-SGTableBody-Cell16-${index}`}
                        >
                          {row.submittedArtYtd === null
                            ? '0'
                            : row.submittedArtYtd}
                        </StyledTableCell>
                        <StyledTableCell
                          data-selenium-id={`Report_Articles-SGTableBody-Cell17-${index}`}
                          align='left'
                          className={
                            OASwitch
                              ? 'RightBorder ReportNumberTextColor'
                              : 'NoBorder ReportNumberTextColor'
                          }
                        >
                          {' '}
                          {row.submittedArtYtdTarget === null
                            ? '0'
                            : row.submittedArtYtdTarget}
                        </StyledTableCell>
                        {!OASwitch ? (
                          <StyledTableCell
                            data-selenium-id={`Report_Articles-SGTableBody-Cell18-${index}`}
                            align='left'
                            className={
                              !OASwitch
                                ? 'RightBorder ReportNumberTextColor'
                                : 'NoBorder ReportNumberTextColor'
                            }
                          >
                            {' '}
                            {row.submittedArtYtdStretchTarget === null
                              ? '0'
                              : row.submittedArtYtdStretchTarget}
                          </StyledTableCell>
                        ) : (
                          <></>
                        )}
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id={`Report_Articles-SGTableBody-Cell19-${index}`}
                        >
                          {' '}
                          {row.acceptedArt === null ? '0' : row.acceptedArt}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id={`Report_Articles-SGTableBody-Cell20-${index}`}
                        >
                          {' '}
                          {row.acceptedArtYtd === null
                            ? '0'
                            : row.acceptedArtYtd}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          data-selenium-id={`Report_Articles-SGTableBody-Cell21-${index}`}
                          className={
                            OASwitch
                              ? 'RightBorder ReportNumberTextColor'
                              : 'NoBorder ReportNumberTextColor'
                          }
                        >
                          {' '}
                          {row.acceptedArtYtdTarget === null
                            ? '0'
                            : row.acceptedArtYtdTarget}
                        </StyledTableCell>
                        {!OASwitch ? (
                          <StyledTableCell
                            data-selenium-id={`Report_Articles-SGTableBody-Cell22-${index}`}
                            align='left'
                            className={
                              !OASwitch
                                ? 'RightBorder ReportNumberTextColor'
                                : 'NoBorder ReportNumberTextColor'
                            }
                          >
                            {' '}
                            {row.acceptedArtYtdStretchTarget === null
                              ? '0'
                              : row.acceptedArtYtdStretchTarget}
                          </StyledTableCell>
                        ) : (
                          <></>
                        )}
                        <StyledTableCell
                          data-selenium-id={`Report_Articles-SGTableBody-Cell23-${index}`}
                          align='left'
                          className='ReportNumberTextColor'
                        >
                          {' '}
                          {row.publishedArt === null ? '0' : row.publishedArt}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                        >
                          {' '}
                          {row.publishedArtYtd === null
                            ? '0'
                            : row.publishedArtYtd}
                        </StyledTableCell>
                        <StyledTableCell
                          data-selenium-id={`Report_Articles-SGTableBody-Cell24-${index}`}
                          align='left'
                          className={
                            OASwitch
                              ? 'RightBorder ReportNumberTextColor'
                              : 'NoBorder ReportNumberTextColor'
                          }
                        >
                          {' '}
                          {row.publishedArtYtdTarget === null
                            ? '0'
                            : row.publishedArtYtdTarget}
                        </StyledTableCell>
                        {!OASwitch ? (
                          <StyledTableCell
                            data-selenium-id={`Report_Articles-SGTableBody-Cell25-${index}`}
                            align='left'
                            className={
                              !OASwitch
                                ? 'RightBorder ReportNumberTextColor'
                                : 'NoBorder ReportNumberTextColor'
                            }
                          >
                            {' '}
                            {row.publishedArtYtdStretchTarget === null
                              ? '0'
                              : row.publishedArtYtdStretchTarget}
                          </StyledTableCell>
                        ) : (
                          <></>
                        )}
                      </PurpleGreenRow>
                    );
                  })
                : !isLoading && (
                    <ReportNoData id='AcquiredAndActiveSiAnnualTrendsBDNoResultTableCell' />
                  )}
              {isLoading && (
                <Box
                  sx={{
                    width: 'auto',
                    marginTop: '150px',
                    marginLeft: '50%',
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TableBox2>
    </>
  );
}

export const MONTH = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const OA_PERFORMANCE =
  'Submission, Acceptance and Publication OA Performance in';
export const OA_YTD_PERFORMANCE =
  'Submission, Acceptance and Publication OA YTD Performance in';

export const SUBJECT_GROUP = 'Subject Group';
export const POST = 'POST';
export const GET = 'GET';
export const BUSINESS_DIVISION = 'Business Division';

export function getCurrentTime() {
  // format: _MMDDYYYY_HHMMSS
  let yy = new Date().getFullYear();
  let mm =
    new Date().getMonth() + 1 < 10
      ? '0' + new Date().getMonth()
      : new Date().getMonth();
  let dd =
    new Date().getDate() < 10
      ? '0' + new Date().getDate()
      : new Date().getDate();
  let hh =
    new Date().getHours() < 10
      ? '0' + new Date().getHours()
      : new Date().getHours();
  let mf =
    new Date().getMinutes() < 10
      ? '0' + new Date().getMinutes()
      : new Date().getMinutes();
  let ss =
    new Date().getSeconds() < 10
      ? '0' + new Date().getSeconds()
      : new Date().getSeconds();
  return '_' + mm + dd + yy + '_' + hh + mf + ss;
}

export const BAR_COLOR_MAP = [
  '#BCC5CF',//SubOO
  '#FF9898',//SubOA
  '#BCC5CF',//AccOO
  '#FFCA7B',//AccOA
  '#BCC5CF',//PubOO
  '#6D98EE',//PubOA
];

export const BAR_COLOR_MAP_YTD = [
  '#98A7B6',//SubOO
  '#C40000',//SubOA
  '#98A7B6',//AccOO
  '#FF8A00',//AccOA
  '#98A7B6',//PubOO
  '#113D95',//PubOA
];

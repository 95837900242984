export const COMMAND_NAME_SpecialChar = 'SpecialCharCommand';
export function loadSpecialCharCommand(EditorModule){
  const { Command } = EditorModule.loadCore();
  return class SpecialCharCommand extends Command {
    refresh() {
      this.isEnabled = true;
    }

    execute({value}) {
      
    }
  };
}

export function loadSpecialCharEditing(EditorModule){
  const { Plugin } = EditorModule.loadCore();
  return class SpecialCharEditing extends Plugin {
    static get pluginName() {
      return "SpecialCharEditing";
    }
    init() {
      const editor = this.editor;
      const SpecialCharCommand = loadSpecialCharCommand(EditorModule);
      editor.commands.add(COMMAND_NAME_SpecialChar, new SpecialCharCommand(editor));
    }
  };
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Autocomplete,
  Box,
  styled,
  TextField,
  ThemeProvider,
  Stack,
  tooltipClasses,
} from '@mui/material';
import DecisionChoiceDown from '@/assets/DecisionChoiceDown.svg';
import { Bootstraptheme } from '@/components/CreateAndUpdate/MyTheme';
import { defaultVars, ErrVars } from '../DecisionMyStyled';
import { SmallFont } from '@/components/FontStyle';
import { SelectedInput } from '@/components/SelectedInput';
import { SelectedTipInput } from '@/components/SelectedTipInput';
import { CustomTooltip } from '@/components/CustomTooltip';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import {
  TooltipMoveArrow,
  CustomTooltipSmallArrow,
} from '../../../../../componentsphase2/CustomTooltip';
import { measureWidth } from '../../../../../utils/StringWidthMeasure';
import { calcHeight } from '@/componentsphase2/Autocomplete/Utils';
import { LimitWord } from '@/componentsphase2/Autocomplete/CommonStyle';
import {getCE} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueSystemAdmin";
import {getTier2JPM} from "@/actions/SIMT-SIP/SIP/Journal";

const liStyle = {
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Open Sans',
  height: '30px',
  padding: '0px 12px',
  color: '#596A7C',
  marginTop: '4px',
  lineHeight: '20px',
  // "&:hover": {
  //   color: "#262E35",
  //   backgroundColor: "#596A7C"
  // }
};

const boxSx = {
  height: '9px',
};

const Arrow = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M3.52925 5.52876C3.7896 5.26841 4.21171 5.26841 4.47206 5.52876L8.00065 9.05735L11.5292 5.52876C11.7896 5.26841 12.2117 5.26841 12.4721 5.52876C12.7324 5.78911 12.7324 6.21122 12.4721 6.47157L8.47206 10.4716C8.21171 10.7319 7.7896 10.7319 7.52925 10.4716L3.52925 6.47157C3.2689 6.21122 3.2689 5.78911 3.52925 5.52876Z'
        fill='#596A7C'
      />
    </svg>
  );
};

export function AssigneeSelectedInput(props) {
  const {
    emptyError,
    setSelectedJPM,
    value,
    open,
    setOpen,
    maxOptionLength = 50,
    ...other
  } = props;
  const dispatch = useDispatch();
  const [vars, setVars] = useState(defaultVars);
  // 获得必要数据
  const { tier2JPMInfo } = useSelector(state => {
    return {
      tier2JPMInfo: state.SIP.tier2JPMInfo,
    };
  });

  const [useJPMData, setUseJPMData] = useState([]); // 真正展示在屏幕上的data

  const [inputContent, setInputContent] = useState(''); // 输入的内容

  const [loading, setLoading] = useState(false); // loading未来会用到，暂时不删掉

  const [changingInput, setChangingInput] = useState(false);
  const [tip, setTip] = useState('');
  const [partTip, setPartTip] = useState('');
  const [openTooltip, setOpenTooltip] = useState(false);
  const [opened, setOpened] = useState(false);
  const [focused, setFocused] = useState(false);

  // 以下两个状态用于辅助延迟发送请求
  const [timer, setTimer] = useState(null);

  const [loadTrigger, setLoadTrigger] = useState(0);

  useEffect(() => {
    if (setChangingInput) {
      document.getElementById('JPMSelected_aotucomplete').focus();
    }
  }, [changingInput]);

  // 搜索逻辑，返回后会更新CEData
  const getJPMFromSearch = async searchContent => {
    await dispatch(getTier2JPM(searchContent));
  };

  // 输入内容变化时设置timer，倒计时0.3秒后发送请求
  // 如果期间输入内容在此变化，之前的timer计划会作废
  useEffect(() => {
    setLoading(true);
    clearTimeout(timer);
    setTimer(
      setTimeout(async () => {
        setLoadTrigger(loadTrigger + 1); // 通过这个触发加载
      }, 300)
    );
  }, [inputContent]);

  // 获取用户input的值
  const handleSetInputJPM = (e, value) => {
    if (value) {
      setInputContent(value);
      if (value === '') {
        setSelectedJPM('');
      }
      setChangingInput(true);
    }
  };

  // 这里增加一个由loadTrigger触发的useEffect
  // 是为了模仿SelectBox的实现（因为当前不清楚去掉这一层是否会正常）
  useEffect(() => {
    if (loadTrigger === 0) {
      return;
    }

    async function load() {
      await getJPMFromSearch(inputContent);
    }

    load().then(_r => {
      setLoading(false);
    });
  }, [loadTrigger]);

  const handleJPMSelect = value => {
    //将新获得的值传入 mainpage
    if (value && value.email) {
      let tmpEmail = value.email;
      setSelectedJPM(tmpEmail);
      setChangingInput(false);
      // setTip(value.fullName + ' (' + value.email + ')');
      const tip = value.fullName + ' (' + value.email + ')';
      if (measureWidth(value.fullName, '16px Open Sans') > 164) {
        setPartTip(tip);
      } else {
        setPartTip(value.email);
      }
      // setPartTip(value.email);
    }
  };

  // Assignee Data变化时，为了防止error等问题，
  // 最终展现给用户的是本页面中的state，而非直接使用redux的
  useEffect(() => {
    // CEData有时候的值很奇怪，所以
    if (tier2JPMInfo && tier2JPMInfo !== 'error' && tier2JPMInfo !== 'null') {
      // 好像还有可能是null字符串，也要避免
      setUseJPMData(tier2JPMInfo); // 正常，设置为返回的值
    } else {
      setUseJPMData([]); // 出错，设置为空值
    }
  }, [tier2JPMInfo]);

  useEffect(() => {
    if (emptyError) {
      setVars(ErrVars);
    } else {
      setVars(defaultVars);
    }
  }, [emptyError]);

  const showTip = value && value !== '' && !changingInput;

  return (
    <CustomTooltipSmallArrow
      placement='top'
      title={partTip}
      disableFocusListener
      disableHoverListener
      disableInteractive
      open={openTooltip && !opened && !focused}
      sx={{
        '& .MuiTooltip-arrow': {
          width: '12px',
          height: '8px',
          top: '100% !important',
        },
        [`& .${tooltipClasses.tooltip}`]: {
          marginBottom: '-4px !important',
          width: 'unset',
          maxWidth: 'unset',
          minWidth: 'unset',
        },
      }}
    >
      <Box
        onMouseEnter={() => setOpenTooltip(true)}
        onMouseLeave={() => setOpenTooltip(false)}
        onBlur={() => setOpenTooltip(false)}
      >
        <SinpleAutocomplete
          id='JPMSelected'
          //这里的id不能修改
          options={useJPMData.map(option => option)}
          getOptionLabel={option => option?.fullName}
          optionLineHeight={34}
          config={{
            scrollProps: {
              scrollOverviwSX: {
                height: `${calcHeight(useJPMData, 6, 6, 34, 6, 0)}px`,
              },
            },
            outlinedInputProps: {
              outlinedInputSX: {
                backgroundColor: '#FFFFFF',
              },
              inputSX: {
                root: {
                  maxWidth: focused ? '246px' : '164px',
                  ...LimitWord,
                },
              },
            },
          }}
          renderOption={(props, option, state) => (
            <li
              {...props}
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '4px',
                height: '30px',
                fontSize: '12px',
                lineHeight: '9px',
                marginBottom: '4px',
              }}
              ref={node => {
                if (node) {
                  node.style.setProperty('padding-bottom', '4px', 'important');
                  node.style.setProperty('padding-top', '4px', 'important');
                  node.style.setProperty('display', 'flex', 'important');
                }
              }}
            >
              <div>{option.fullName}</div>
              <div>{option.email}</div>
            </li>
          )}
          onChange={(e, value) => handleJPMSelect(value)}
          innerInputOnChange={handleSetInputJPM}
          defaultQuery={false}
          loadOptionAction={getTier2JPM}
          needClearInputValue={true}
          onOpen={() => setOpened(true)}
          onClose={() => setOpened(false)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </Box>
    </CustomTooltipSmallArrow>
  );
}

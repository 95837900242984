import { ThemeProvider } from '@emotion/react';
import {
  Box,
  FormControl,
  TextField,
  useFormControl,
  FormHelperText,
  Stack,
  createTheme,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { styled } from '@mui/styles';
import { SmallFont } from '../../../../components/FontStyle';
import { useUpdateEffect } from 'ahooks';
import { trimItemFilds } from '../../../../utils/commonUtils';
import TextFieldErrorIcon from '@/assets/TextFieldErrorIcon.svg';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
const theme = createTheme({
  palette: {
    error: {
      main: '#EE5350',
    },
  },
});
export const CustomTextFieldTypeTwo = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    borderRadius: '4px',
    boxSizing: 'border-box',
    width: '536px',
    minWidth: '536px',
    padding: '8px 12px',
    ...SmallFont,
    maxHeight: '220px',
    minHeight: '220px',
    overflowY: 'auto'
  },
  '& .MuiOutlinedInput-input': {
    padding: '0px 0px',
    '&::placeholder': {
      ...SmallFont,
      color: '#262E35',
      opacity: '1',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #DFE4E8',
  },
});

export const RejectTextArea = props => {
  const {
    id = 'test',
    value = '',
    placeholder = '',
    limitLength = 10000,
    needWordCount = false, //是否需要单词计数
    setValue = () => {},
    excessError,
    emptyError,
    setExcessError = () => {},
    setEmptyError = () => {},
    firstClick = true,
    // firstInput,
    // setFirstInput = () => { }
  } = props;

  //用于textarea 下面那行 统计字符 的样式
  const textAreaNumberFont = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#596A7C',
  };

  const { formatNumber } = useIntl();
  let [hasError, setHasError] = useState(false);

  useUpdateEffect(() => {
    if (firstClick && ((value ? value.length > limitLength : false) || value === '')) {
      setHasError(true);
    } else {
      setHasError(false);
    }
    setExcessError(value ? (value.length > limitLength) && firstClick: false);
    setEmptyError(value === '' && firstClick);
  }, [value, needWordCount]);

  useEffect(() => {
    setHasError(excessError || emptyError);
  }, [excessError, emptyError]);

  return (
    <ThemeProvider
      theme={theme}
      data-selenium-id='CATJournal_Overview-OptInTable-RejectTextArea-ThemeProvider'
    >
      <FormControl variant='standard' sx={{ width: '536px' }} error={hasError}>
        {/* <CustomTextFieldTypeTwo
          id={id}
          data-selenium-id={id}
          value={value}
          multiline
          rows={10}
          placeholder={placeholder}
          onChange={e => {
            setValue(e.target.value);
          }}
          error={hasError}
        /> */}
        <MyOnlyText
          id={id}
          data-selenium-id={id}
          value={value}
          // setValue={setValue}
          setValue={setValue}
          placeholder={placeholder}
          minRows={10}
          maxRows={10}
          width='100%'
          // height='40px'
          needEndAdornment={false}
          // handleIconButtonClick={clear}
          isError={hasError}
          // errorMessage={getErrorMessage()}
          isWarning = {false} 
          // warningMessage = {getErrorMessage()}
        />
        <Box
          sx={{ height: '15px', marginTop: '8px', width: '536px' }}
          data-selenium-id='CATJournal_Overview-OptInTable-RejectTextArea-Box'
        >
          {needWordCount ? (
            <Box
              sx={{ float: 'left', ...textAreaNumberFont, color: '#596A7C' }}
              data-selenium-id='CATJournal_Overview-OptInTable-RejectTextArea-NeedWordCountBox'
            >
              words: {value === '' ? 0 : trimItemFilds(value).split(' ').length}
            </Box>
          ) : (
            <></>
          )}
          <Stack direction='row' sx={{height:'15px', alignItems: 'center' }}>
            {open && (excessError || emptyError)
              ? <TextFieldErrorIcon />
              : <></>}
            
            <Box
              sx={{
                width: '456px',
                color: '#EE5350',
                fontFamily: ' Open Sans',
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '16px',
                letterSpacing: '-0.08399999886751175px',
                textAlign: 'left',
              }}
              data-selenium-id='CATJournal_Overview-OptInTable-RejectPopUp-columnStack-Box'
            >
              {open && excessError
                ? 'The character count should be no more than 10,000.'
                : null}
              {open && emptyError
                ? 'This field is mandatory to input!'
                : null}
            </Box>
            <Box
              sx={{
                ...textAreaNumberFont,
                color: (
                  value && value.length ? value.length > limitLength : false
                )
                  ? '#EE5350'
                  : '#596A7C',
                float: 'right',
                ml:'auto',
                
              }}
              data-selenium-id='CATJournal_Overview-OptInTable-RejectTextArea-Box-Box'
            >
              {formatNumber(value.length)}/{formatNumber(limitLength)}
            </Box>



          </Stack>
          

        </Box>
      </FormControl>
    </ThemeProvider>
  );
};

import { Box, Button, Dialog, IconButton } from '@mui/material';
import GEDetailCloseIcon from '@/assets/GEDetailCloseIcon.svg';
import { H4Font } from '@/components/FontStyle';
import { useIntl } from 'react-intl';
import { RejectTextArea } from './RejectTextArea';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateEffect } from 'ahooks';
import { StateButton } from '@/componentsphase2/StateButton';
import CATJournalCloseButton from '@/assets/CATJournalCloseButton.svg';
import CloseIconButton from '@/componentsphase2/Button/CloseIconButton';
import DrawerCloseButton from '@/componentsphase2/DrawerCloseButton';
import {rejectOptOut} from "@/actions/SIMT-SI/CatJournal/OptOut";

export default function RejectPopUp(props) {
  const {
    setOpen = () => {},
    open = true,
    journalCode = 1234,
    page = 1,
    pageSize = 50,
  } = props;

  let limitLength = 10000;
  const dispatch = useDispatch();
  const intl = useIntl();
  const [excessError, setExcessError] = useState(false);
  const [emptyError, setEmptyError] = useState(false);
  const [reason, setReason] = useState('');
  const [firstCLick, setFristClick] = useState(false);
  
  // const [firstInput, setFirstInput] = useState(true)

  const savetBtnCss = {
    width: '69px',
    height: '32px',
    backgroundColor: '#154AB6',
    borderRadius: '3px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    letterSpacing: '1px',
    textTransform: 'capitalize',
    marginLeft: '34px',
  };

  const CancelBtnCss = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#154AB6',
    textTransform: 'capitalize',
  };
  const clickCancel = () => {
    setOpen(false);
  };

  // 检测open的修改 ，再修改对应的属性，以免发生异步导致的关闭时候样式问题
  useUpdateEffect(() => {
    if (open) {
      setEmptyError(false);
      setExcessError(false);
      setReason('');
    }
  }, [open]);

  const clickSave = () => {
    setFristClick(true);
    setExcessError(reason ? reason.length > limitLength : false);
    setEmptyError(reason === '');
    if ((reason ? reason.length > limitLength : false) || reason === '') {
      return;
    } else {
      dispatch(rejectOptOut(reason, journalCode, page, pageSize));
      setOpen(false);
    }
  };
  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            boxShadow: 'none',
          },
        }}
        open={open}
        onClose={clickCancel}
        sx={{
          zIndex: 1401,
          // background: ' rgba(9, 30, 66, 0.54)',
          '& .MuiDialog-paper': {
            minWidth: '600px',
            minHeight: '385px',
            width: '600px',
            margin: 0,
            padding: '24px 32px 0px 32px',
            boxSizing: 'border-box',
          },

          '.css-hz1bth-MuiDialog-container': {
            // background: 'rgba(9, 30, 66, 0.54)',
          },
          '& .MuiBackdrop-root': {
            background: 'rgba(17, 61,149, 0.5)',
            backdropFilter: 'blur(4px)',
  
          },
        }}
        data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-Dialog'
      >
        <Box
          sx={{ height: '28px', marginBottom: '16px' }}
          data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-Box'
        >
          <Box
            sx={{
              ...H4Font,
              height: '23px',
              float: 'left',
              color: '#262E35',
            }}
            data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-Box-Box'
          >
            {intl.messages['optOut.rejectPopUpTitle']}
          </Box>
          {/* <CloseIconButton
            sx={{ float: 'right', cursor: 'pointer', padding: '0px' }}
            onClick={clickCancel}
            data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-IconButton'
          /> */}
          <Box sx={{float: 'right', cursor: 'pointer', padding: '0px'}}>
            <DrawerCloseButton
              data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-IconButton'
              onClick={clickCancel}
            />
          </Box>
           
        </Box>
        
        <Stack
          direction='column'
          data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-ColumnStack'
        >
          <Box >
              <RejectTextArea
                id='rejectPopUp'
                data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-TextArea'
                needEmptyCheck={true}
                setValue={setReason}
                value={reason}
                limitLength={limitLength}
                emptyError={emptyError}
                excessError={excessError}
                setEmptyError={setEmptyError}
                setExcessError={setExcessError}
                firstClick={firstCLick}
              ></RejectTextArea>
            </Box>
            
            {/* <Box
                sx={{
                  width: '536px',
                  color: '#EE5350',
                  fontFamily: ' Open Sans',
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '16px',
                  letterSpacing: '-0.08399999886751175px',
                  textAlign: 'left',
                }}
                data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-columnStack-Box'
              >
                {open && excessError
                  ? 'The character count should be no more than 10,000.'
                  : null}
                {open && emptyError
                  ? 'This field is mandatory to input!'
                  : null}
          </Box> */}

          <Stack
            spacing={1.125}
            direction='row-reverse'
            sx={{
              mt:'24px',
              marginBottom: '20px',
            }}
            data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-RowReverseStack'
          >
           

              {/* <Button
                id='RejectPopUpCancelButton'
                data-selenium-id='CATJournal_Overview-OptInTable-RejectPopUp-CancelButton'
                onClick={clickCancel}
                sx={CancelBtnCss}
              >
                Cancel
              </Button> */}
               <StateButton
                id='RejectPopUpSaveButton'
                data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-SaveButton'
                onClick={clickSave}
                sx ={{width:'70px'}}
                titleName ='Submit'
                isPrimary = {true}
                disabled = {emptyError || excessError}
                sx ={{
                  '&:disabled':{
                    backgroundColor: '#BCC5CF',
                    color: '#FFFFFF',
                  },

                }}
              >
                
              </StateButton>
              <StateButton
                id='RejectPopUpCancelButton'
                data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-CancelButton'
                onClick={clickCancel}
                sx ={{width:'70px'}}
                titleName ='Cancel'
                isPrimary = {false}
              >
                
              </StateButton>
             
              {/* <Button
                id='RejectPopUpSaveButton'
                data-selenium-id='CATJournal_Overview-OptInTable-RejectPopUp-SaveButton'
                sx={savetBtnCss}
                variant='contained'
                onClick={clickSave}
              >
                Submit
              </Button> */}
           
          </Stack>

        </Stack>


        {/* <Stack
          direction='column'
          data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-ColumnStack'
        >
          <Stack
            direction='row'
            data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-RowStack'
          >
            <Stack
              direction='column'
              data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-rowStack-Stack'
            >
              <Stack
                direction='row'
                data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-columnStack-Stack'
              >
                <Box
                  style={{
                    height: '20px',
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '125%',
                    textTransform: 'capitalize',
                    color: '#596A7C',
                  }}
                  ata-selenium-id='CATJournal_Overview-OptInTable-RejectPopUp-Box1'
                >
                  Reason
                </Box>
                <Box
                  style={{
                    width: '8px',
                    height: '18px',
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '18px',
                    letterSpacing: '0.08em',
                    color: '#C40000',
                  }}
                  data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-Box2'
                >
                  *
                </Box>
              </Stack>
              <Box
                sx={{
                  width: '175px',
                  color: '#EE5350',
                  fontFamily: ' Open Sans',
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '16px',
                  letterSpacing: '-0.08399999886751175px',
                  textAlign: 'left',
                }}
                data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-columnStack-Box'
              >
                {open && excessError
                  ? 'Max character length for Reason is 10,000.'
                  : null}
                {open && emptyError
                  ? 'This field is mandatory to input!'
                  : null}
              </Box>
            </Stack>
            <Box marginLeft='8px'>
              <RejectTextArea
                id='rejectPopUp'
                data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-TextArea'
                needEmptyCheck={true}
                setValue={setReason}
                value={reason}
                limitLength={limitLength}
                emptyError={emptyError}
                excessError={excessError}
                setEmptyError={setEmptyError}
                setExcessError={setExcessError}
              ></RejectTextArea>
            </Box>
          </Stack>
          <Stack
            direction='row-reverse'
            sx={{
              marginBottom: '30px',
            }}
            data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-RowReverseStack'
          >
            <Box>
              <Button
                id='RejectPopUpCancelButton'
                data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-CancelButton'
                onClick={clickCancel}
                sx={CancelBtnCss}
              >
                Cancel
              </Button>
              <Button
                id='RejectPopUpSaveButton'
                data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp-SaveButton'
                sx={savetBtnCss}
                variant='contained'
                onClick={clickSave}
              >
                Submit
              </Button>
            </Box>
          </Stack>
        </Stack> */}

      </Dialog>
    </>
  );
}

import { useMemo } from 'react';
import Chart from '@/components/Chart/Chart';
import {
  AAndAOverviewYTDTargetOption,
  AAndAOverviewYTDMonthOption,
} from '../options';
import styles from './index.module.scss';

const ChartAAndA = ({
  barchartData,
  width,
  hasZoom = false,
  hasTitle = false,
  title,
  subTitle,
  catagoryAttributeName,
  valueAttributeNames,
  formatMonth,
}) => {
  const chartOption = useMemo(() => {
    return valueAttributeNames && valueAttributeNames[0] === 'againstYTDTarget'
      ? new AAndAOverviewYTDTargetOption(
          catagoryAttributeName,
          valueAttributeNames,
          formatMonth
        )
      : new AAndAOverviewYTDMonthOption(
          catagoryAttributeName,
          valueAttributeNames,
          formatMonth
        );
  }, [catagoryAttributeName, valueAttributeNames, formatMonth]);

  const options = useMemo(() => {
    chartOption
      .setZoom(hasZoom)
      .setTitle(hasTitle, title, subTitle)
      .setDataSource(barchartData);
    return chartOption.getOption();
  }, [hasZoom, hasTitle, title, subTitle, barchartData]);

  const chartHeight = useMemo(() => {
    return chartOption.getHeightWithTitle();
  }, [hasTitle, title, subTitle, barchartData]);

  const [minFontSize, maxFontSize] = useMemo(() => {
    return chartOption.getLabelFontSize();
  }, [hasTitle, title, subTitle, barchartData]);

  console.log('======options=====, ', options);

  return (
    <Chart
      className={styles['inner-chart']}
      options={options}
      height={chartHeight}
      width={width}
      isZoomLabelFontSize={true}
      minFontSize={minFontSize}
      maxFontSize={maxFontSize}
    />
  );
};

export default ChartAAndA;

/**
 * @typedef {import("./RevisePage").GEInformationItem} GEInformationItem
 * @typedef {import("./RevisePage").SipInformation} SipInformation
 * @typedef {import("./RevisePage").CommissionApproaches} CommissionApproaches
 */

/** @type {SipInformation} */
export const sipInformationInitialValue = {
  sipTitle: '',
  aimsAndScope: '',
  topics: ['', '', ''],
  keywords: ['', '', ''],
  references: [''],
  fmSubmissionDeadline: null,
  acceptanceDeadline: null,
  epDate: null,
  notesForEditor: '',
};
/** @type {SipInformation} */
export const sipInformationTestValue = {
  sipTitle:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. ',
  aimsAndScope:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  topics: [
    'Green and novel processing techniques for cereal-based foods',
    'Improvement of physical and quality properties',
    'Influence on nutritional and functional profile',
    'Influence on nutritional and functional profile',
  ],
  keywords: [
    'cereal-based food',
    'physical properties',
    'nutritional profile',
    'processing method',
    'food quality',
  ],
  references: [
    'Donaldson, S. & Kymlicka, W. (2011) Zoopolis: A Political Theory of Animal Rights. (Oxford: OUP). ',
    'Tovey, H. (2003) Theorising nature and society in sociology: The invisibility of animals. Sociologia Ruralis 43, 196-215.',
  ],
  fmSubmissionDeadline: '2022-06-23',
  acceptanceDeadline: '2022-06-23',
  epDate: '2022-06-23',
  notesForEditor:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
};

/** @type {CommissionApproaches} */
export const commissionApproachesInitialValue = {
  openCallForPapers: '0',
  potentialAuthors: [],
};
/** @type {CommissionApproaches} */
export const commissionApproachesTestValue = {
  openCallForPapers: '-1',
  potentialAuthors: [
    {
      id: 3,
      name: 'Wells, Martin',
      affiliation:
        'Department of Cardiovascular Sciences, University of Leicester',
      email: 'jowang@wiley.com',
      topic:
        'A novel circulating biomarker lnc-MALAT1 for acute myocardial infarction: Its relationship with disease risk, features, cytokines, and major adverse cardiovascular events',
    },
    {
      id: 4,
      name: 'Wells, Martin',
      affiliation:
        'Department of Cardiovascular Sciences, University of Leicester',
      email: 'jowang@wiley.com',
      topic:
        'A novel circulating biomarker lnc-MALAT1 for acute myocardial infarction: Its relationship with disease risk, features, cytokines, and major adverse cardiovascular events',
    },
  ],
};

/** @type {GEInformationItem} */
export const geInformationInitialValue = {
  id: -1,
  firstName: '',
  lastName: '',
  leadGe: false,
  jobTitle: '',
  emailAddress: '',
  department: '',
  institution: '',
  city: '',
  country: '',
  orcidId: '',
  researchGateId: '',
  profileWebsite: '',
  cv: '',
  hasConflict: false,
  conflictDetail: '',
  cvFileName: '',
};
/** @type {GEInformationItem} */
export const geInformationTestValue = {
  id: -1,
  firstName: '',
  lastName: '',
  leadGe: false,
  jobTitle: '',
  emailAddress: '',
  department: '',
  institution: '',
  city: '',
  country: '',
  orcidId: '',
  researchGateId: '',
  profileWebsite: '',
  cv: '',
  hasConflict: false,
  conflictDetail: '',
  cvFileName: '',
};

/** @type {GEInformationItem[]} */
export const geInformationListIntialValue = [
  {
    id: 1,
    firstName: 'Lan, Lan',
    lastName: 'Lan, Lan',
    leadGe: true,
    jobTitle: 'Professor',
    emailAddress: 'test@example.com',
    department: '',
    institution: '',
    city: '',
    country: 'China',
    orcidId: '',
    researchGateId: '',
    profileWebsite: 'www.bing.com',
    cv: '123_fake_data_11111111111111111111111111111111111111.pdf',
    hasConflict: false,
    conflictDetail: '',
    cvFileName: '',
  },
  {
    id: 2,
    firstName: 'Alicia, Li',
    lastName: 'fake_data',
    leadGe: false,
    jobTitle: 'Professor',
    emailAddress: '',
    department: '',
    institution: '',
    city: '',
    country: 'China',
    orcidId: '',
    researchGateId: '',
    profileWebsite: '',
    cv: null,
    hasConflict: false,
    conflictDetail: '',
    cvFileName: '',
  },
];
/** @type {GEInformationItem[]} */
export const geInformationListTestValue = [];

import {
  setAIAcquisitionCreateSuccessAlert,
  setSyncGeBwteenSipAndSimt,
  setTransformToSiAcquiredSiInfo,
  setTransformToSiGeCheckInfo,
  syncGeBetweenSipAndSimt,
} from '@/actions/SIMT-SIP/SIP/SIPAction';
import { SIMTButton } from '@/components/StyledButton/SIMTButton';
import CommonSnackBar from '@/modules/ConfirmSnackBar/CommonSnackBar';
import { scrollToTop } from '@/utils/commonUtils';
import { Box, CircularProgress, Stack, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { StickyBarContainer } from '../RevisePage/CustomComponents/CustomStickyBar';
import {
  LoadingContainer,
  TransformToSiPageContainer,
} from './CustomComponent/CustomContainer';
import { TransformToSiStepHeader } from './CustomComponent/Header';
import { StepControlStickyBar } from './CustomComponent/StickyBar';
import AcquiredSiCreation, {
  acquiredSiDetailInitialValue,
  acquiredSiDetailTestData,
} from './Submodule/TransformSteps/AcquiredSiCreation';
import DuplicateGeCheck, {
  geDiffVOTestData,
} from './Submodule/TransformSteps/DuplicateGeCheck';
import { useUpdateEffect } from 'ahooks';
import CreateSIPage, {
  CategoryType0,
  CategoryType1,
} from './Submodule/TransformSteps/CreateSIPage';
import moment from 'moment';
import { CREATE_SI } from '@/actions/SIMT-SI/SpecialIssue/SIAction';
import { createSIInAcquited } from "@/actions/SIMT-SI/SpecialIssue";
import { getAutoAcquiredSiData, getDuplicatedGeInfo } from "@/actions/SIMT-SIP/SIP/SiptoSi";
/**
 * @param {import("./TransformToSI").TransformToSiProps} props
 * @returns
 */
const TransformToSI = props => {
  const { transformToSiState, createSiResult, systemConfig } = useSelector(
    state => ({
      /** @type {import("./TransformToSI").TransformToSiState} */
      // @ts-ignore
      transformToSiState: state.SIP.transformToSiState,
      /** @type {import("./Submodule/TransformSteps/AcquiredSiCreation").CreateSiResult} */
      // @ts-ignore
      createSiResult: state.SI.createSIResult,
      systemConfig: state.SystemConfig.systemConfig,
    })
  );

  /** transformToSiState 对象各 status 属性时序图
    +-------------------------------+-----------------------------+-----------------------------+
    | getGeDiffOriginalDataStatus   | createGeForTransformResult  | getAcquiredSiDetailStatus   |
    +-------------------------------+-----------------------------+-----------------------------+
    | idle                          | idle                        | idle                        |
    | pending                       |                             |                             |
    | success                       |                             |                             |
    | idle                          |                             |                             |
    |                               |(await user to click confirm)|                             |
    |                               | pending                     |                             |
    |                               | updateSuccess               |                             |
    |                               | pending                     |                             |
    |                               | createSuccess               | (jump to step 1)            |
    |                               | idle                        | pending                     |
    |                               |                             | success                     |
    |                               |                             | idle                        |
    +-------------------------------+-----------------------------+-----------------------------+
   */

  const dispatch = useDispatch();

  const stepLabels = ['Duplicated GE Check', 'Add SI Information', 'Create SI'];
  const maxStep = stepLabels.length - 1;

  const { sipCode } = useParams();
  const navigate = useNavigate();

  const [currentTransformStep, setCurrentTransformStep] = useState(
    /** @type {0 | 1} */(0)
  );

  const [geDiffData, setGeDiffData] = useState(
    /** @type {import("./Submodule/TransformSteps/DuplicateGeCheck").GeDiffData} */([])
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [controlButtonDisabled, setControlButtonDisabled] = useState(false);

  // const selector = state => {
  //   return {
  //     systemConfig: state.SystemConfig.systemConfig,
  //     createSiback: state.SI.createSIResult,
  //   };
  // };
  // const { systemConfig, createSiback } = useSelector(selector);

  const [createSIInfo, setCreateSIInfo] = useState(null);
  const [createSIInfoError, setCreateSIInfoError] = useState(false);
  const [requiredDetailInfo, setRequiredDetailInfo] = useState(null);

  useEffect(() => {
    if (currentTransformStep === 2 && createSIInfo) {
      const { siCategory, journals, scaJournal } = createSIInfo;
      setCreateSIInfoError(
        !siCategory ||
        (siCategory === CategoryType1 &&
          (scaJournal.length <= 0 ||
            journals.length > systemConfig.eachJointSIMaxAddedJournalsNumber))
      );
    } else {
      setCreateSIInfoError(false);
    }
  }, [currentTransformStep, createSIInfo]);

  useEffect(() => {
    if (sipCode === undefined || sipCode === '') {
      navigate('/simt/auth/sipRelated/sip/overview');
    }
    if (sipCode) {
      dispatch(setTransformToSiGeCheckInfo('pending'));
      dispatch(getDuplicatedGeInfo(sipCode));
    }
  }, []);

  useEffect(() => {
    const { getGeDiffOriginalDataStatus: status, geDiffOriginalData: value } =
      transformToSiState;

    if (!(status === 'success' || status === 'error')) return;

    if (value !== null) {
      /** @type {import("./Submodule/TransformSteps/DuplicateGeCheck").GeDiffData} */
      const newGeDiffData = value.reduce((acc, geDiffOriginalDataItem) => {
        if (geDiffOriginalDataItem['SIMT GE'] === null) return acc;
        return [
          ...acc,
          {
            'SIMT GE': geDiffOriginalDataItem['SIMT GE'],
            'SIP GE': geDiffOriginalDataItem['SIP GE'],
          },
        ];
      }, /** @type {import("./Submodule/TransformSteps/DuplicateGeCheck").GeDiffData} */([]));

      value.filter(geDiffOriginalDataItem => {
        if (geDiffOriginalDataItem['SIMT GE'] === null) return false;
        return true;
      });
      setGeDiffData(newGeDiffData);
      setAcquiredSiDetailData(current => ({
        ...current,
        geInformation: value.reduce((acc, item) => {
          const simtGe = item['SIMT GE'];
          const sipGe = item['SIP GE'];
          if (simtGe) {
            simtGe.leadGe = sipGe.leadGe;
            return [...acc, simtGe];
          }
          return acc;
        }, /** @type {import("./Submodule/TransformSteps/DuplicateGeCheck").GeInformationForTransform[]} */([])),
      }));
      if (newGeDiffData.length === 0 && value.length > 0) {
        handleChangeStep(1);
      }
    }
    dispatch(setTransformToSiGeCheckInfo('idle'));
  }, [transformToSiState.getGeDiffOriginalDataStatus]);

  useEffect(() => {
    const { syncGeBetweenSipAndSimtStatus, geDiffOriginalData } =
      transformToSiState;

    if (syncGeBetweenSipAndSimtStatus === 'updateSuccess') {
      if (geDiffOriginalData !== null) {
        dispatch(setSyncGeBwteenSipAndSimt('pending'));
        dispatch(
          syncGeBetweenSipAndSimt({
            opType: 'create',
            data: geDiffOriginalData.reduce((acc, item) => {
              if (item['SIMT GE'] === null) {
                if (item['SIP GE'] === null) return acc;

                const sipGe = item['SIP GE'];

                /** @type {import("./TransformToSI").ResearchKeywordsToStringArray<import("./TransformToSI").GuestEditorCreateDto>} */
                const guestEditorCreateDto = {
                  ...sipGe,
                  title: sipGe.jobTitle,
                  institute: sipGe.institution,
                  primaryEmail: sipGe.emailAddress,
                  secondaryEmail: null,
                  gender: '',
                };

                return [guestEditorCreateDto, ...acc];
              }
              return acc;
            }, /** @type {import("./TransformToSI").ResearchKeywordsToStringArray<import("./TransformToSI").GuestEditorCreateDto>[]} */([])),
          })
        );
      }
      setCurrentTransformStep(1);
      scrollToTop();
    } else if (syncGeBetweenSipAndSimtStatus === 'createSuccess') {
      setCurrentTransformStep(1);
      scrollToTop();

      dispatch(setSyncGeBwteenSipAndSimt('idle'));

      if (sipCode) {
        dispatch(setTransformToSiAcquiredSiInfo('pending'));
        dispatch(getAutoAcquiredSiData(sipCode));

        dispatch(setTransformToSiGeCheckInfo('pending'));
        dispatch(getDuplicatedGeInfo(sipCode));
      }
    } else if (
      syncGeBetweenSipAndSimtStatus === 'updateError' ||
      syncGeBetweenSipAndSimtStatus === 'createError' ||
      syncGeBetweenSipAndSimtStatus === 'success' ||
      syncGeBetweenSipAndSimtStatus === 'error'
    ) {
    }
  }, [transformToSiState.syncGeBetweenSipAndSimtStatus]);

  const [acquiredSiDetailData, setAcquiredSiDetailData] = useState(
    acquiredSiDetailInitialValue
  );

  useEffect(() => {
    const { getAcquiredSiDetailStatus: status, acquiredSiDetail: value } =
      transformToSiState;

    if (!(status === 'success' || status === 'error')) return;

    if (value !== null) {
      setAcquiredSiDetailData(current => ({
        ...value,
        geInformation: current.geInformation,
      }));
    }
    dispatch(setTransformToSiAcquiredSiInfo('idle'));
  }, [transformToSiState.getAcquiredSiDetailStatus]);

  /** @type {React.MutableRefObject<import("./Submodule/TransformSteps/AcquiredSiCreation").IHandleCreateSi>} */
  const acquiredSiCreationRef = useRef({
    errorMonitor: () => { },
  });

  const formatDate = (dateStr, format, after = false) => {
    if (after) {
      if (dateStr === null) return '';
      const momentObj = moment(dateStr).utc();
      const nextDay = momentObj.add(1, 'day');
      if (format === 'YYYY-MM-DD') {
        const res = nextDay.format('YYYY-MM-DD').toString();
        return res;
      }
      const res = nextDay.toISOString();
      return res;
    }
    else {
      if (dateStr === null) return '';
      if (format === 'YYYY-MM-DD') {
        const res = moment(dateStr).utc().format('YYYY-MM-DD').toString();
        return res;
      }
      const res = moment(dateStr).toISOString();
      return res;
    }
  };

  /**
   * @param {number} stepIndex
   */
  const handleChangeStep = stepIndex => {
    if (stepIndex === -1) {
      if (sipCode) navigate(`/simt/auth/sipRelated/sip/detail/${sipCode}`);
    } else if (stepIndex === 0) {
      setCurrentTransformStep(stepIndex);
      scrollToTop();
    } else if (stepIndex === 1) {
      // setCurrentTransformStep(stepIndex);
      if (currentTransformStep < 1) {
        dispatch(setSyncGeBwteenSipAndSimt('pending'));
        dispatch(
          syncGeBetweenSipAndSimt({
            opType: 'update',
            data: geDiffData.reduce((acc, item) => {
              const sipGe = item['SIP GE'];
              const simtGe = item['SIMT GE'];
              /** @type {import("./TransformToSI").ResearchKeywordsToStringArray<import("./TransformToSI").GuestEditorUpdateDto>} */
              const updateSimtGe = {
                ...simtGe,
                notes: null,
                title: simtGe.jobTitle,
                institute: simtGe.institution,
                department: simtGe.department,
                primaryEmail: simtGe.emailAddress,
                secondaryEmail: simtGe.secondaryEmail,
                flagList: simtGe.flagList,
                gender: simtGe.gender,
              };
              return [...acc, updateSimtGe];
            }, /** @type {import("./TransformToSI").ResearchKeywordsToStringArray<import("./TransformToSI").GuestEditorUpdateDto>[]} */([])),
          })
        );
      } else {
        setCurrentTransformStep(stepIndex);
      }
    } else if (stepIndex === 2) {
      if (acquiredSiCreationRef.current.errorMonitor()) {
        setControlButtonDisabled(true);
        return;
      }
      setCurrentTransformStep(stepIndex);
    } else if (stepIndex === 3) {
      if (createSIInfoError) return;
      const siInfo = {
        ...acquiredSiDetailData,
        ideaDate: formatDate(acquiredSiDetailData.ideaDate, 'ISO'),
        acquiredDate: formatDate(acquiredSiDetailData.acquiredDate, 'ISO'),
        expectedSubmissionDeadline: formatDate(
          acquiredSiDetailData.expectedSubmissionDeadline,
          'ISO'
        ),
        expectedAcceptanceDate: formatDate(
          acquiredSiDetailData.expectedAcceptanceDate,
          'ISO'
        ),
        expectedPublicationDate: formatDate(
          acquiredSiDetailData.expectedPublicationDate,
          'ISO'
        ),
        leadGuestEditors: acquiredSiDetailData.geInformation
          .filter(item => item.leadGe)
          .map(item => item.emailAddress || ''),
        coGuestEditors: acquiredSiDetailData.geInformation
          .filter(item => !item.leadGe)
          .map(item => item.emailAddress || ''),
        paperCommitioningMethod: acquiredSiDetailData.paperCommissioningMethod,
        specialIssueType: requiredDetailInfo.siPublicationFormat,
        workflow: requiredDetailInfo.workflow,
        siRevenueModel: null,
        sipCode: sipCode,
        journalCode: Array.from(
          createSIInfo?.journals ?? [],
          ({ journalCode }) => journalCode
        ),
      };
      dispatch({ type: CREATE_SI, data: 'pending' });
      dispatch(createSIInAcquited(siInfo));
    }
  };

  useEffect(() => {
    if (createSiResult === '' || createSiResult === 'pending') return;
    if (createSiResult !== 'error' && createSiResult.code === 200) {
      if (createSIInfo.siCategory === CategoryType0) {
        navigate(`/simt/auth/siRelated/si/detail/${createSiResult.data}`);
      } else if (createSIInfo.siCategory === CategoryType1) {
        navigate(`/simt/auth/siRelated/si/overview/?${(() => createSiResult.data.map(code => `createSiResult=${code}`).join('&'))()}`);
      }
      dispatch(setAIAcquisitionCreateSuccessAlert(true, createSIInfo.journals.length));
    }
    dispatch({ type: CREATE_SI, data: '' });
  }, [createSiResult]);

  useUpdateEffect(() => {
    controlButtonDisabled && setControlButtonDisabled(isButtonDisabled);
  }, [isButtonDisabled]);
  return (
    <TransformToSiPageContainer
      bottomStickyEle={
        <StepControlStickyBar
          currentStep={currentTransformStep}
          handleChangeStep={handleChangeStep}
          nextButtonLoading={
            transformToSiState.syncGeBetweenSipAndSimtStatus === 'pending' ||
            createSiResult === 'pending'
          }
          nextButtonText={currentTransformStep !== maxStep ? 'Next' : 'Create'}
          backButtonText={currentTransformStep === 0 ? 'Cancel' : 'Back'} //modified by HJX
          isButtonDisabled={
            (isButtonDisabled && controlButtonDisabled) || createSIInfoError
          }
          handleBack={() => {
            setControlButtonDisabled(false);
          }}
        />
      }
    >
      <TransformToSiStepHeader
        currentTransformStep={currentTransformStep}
        labels={stepLabels}
      />
      <LoadingContainer
        sx={{ flexGrow: 1 }}
        loading={
          transformToSiState.getGeDiffOriginalDataStatus !== 'idle' ||
          transformToSiState.getAcquiredSiDetailStatus !== 'idle'
        }
      >
        {currentTransformStep === 0 && (
          <DuplicateGeCheck
            value={geDiffData}
            onChange={setGeDiffData}
            sipCode={sipCode}
          />
        )}
        {/* {currentTransformStep === 1 && (
          <AcquiredSiCreation
            acquiredSiDetail={acquiredSiDetailData}
            ref={acquiredSiCreationRef}
            setIsButtonDisabled={setIsButtonDisabled}
            setValue={setRequiredDetailInfo}
          />
        )} */}
        <AcquiredSiCreation
          acquiredSiDetail={acquiredSiDetailData}
          ref={acquiredSiCreationRef}
          setIsButtonDisabled={setIsButtonDisabled}
          setValue={setRequiredDetailInfo}
          isShow={currentTransformStep === 1}
        />
        {currentTransformStep === 2 && (
          <CreateSIPage
            acquiredSiDetail={acquiredSiDetailData}
            setValue={setCreateSIInfo}
          />
        )}
      </LoadingContainer>
      <CommonSnackBar data-selenium-id='SIPPage_TransformToSl-CommonSnackerBar' />
    </TransformToSiPageContainer>
  );
};

export default TransformToSI;

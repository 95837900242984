import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Stack,
} from '@mui/material';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DateRangePicker } from './MyDateRangePicker';
import { DeadlineDateRangePicker } from './SubmissionDeadlineDateRangePicker';
import SimpleRadio from '../../../componentsphase2/Input/SimpleRadio';
import {
    submissionExportData,

} from '@/actions/SIMT-SI/Export/ExportDataAction';
import { UntitledAlertBox } from '@/components/Alert/AlertBox';
import {submissionExportSize} from "@/actions/SIMT-SI/Export";


var openForSubmissionDateStart = null;
var openForSubmissionDateEnd = null;
var submissionDeadlineStart = null;
var submissionDeadlineEnd = null;
var isAllGE = true;
var dataRangeStart = null;
var dataRangeLength = null;

export default function Submission(props) {

    const {deadDateOpen, setDeadDateOpen} = props;

    const {
        submissionSelectSize,
    } = useSelector(
        state => {
            return {
                submissionSelectSize: state.MarketExport.submissionSelectSize,
            };
        }
    );
    const dispatch = useDispatch();
    const [openStart, setOpenStart] = useState(null);
    const [openEnd, setOpenEnd] = useState(null);
    const [ddlStart, setDdlStart] = useState(null);
    const [ddlEnd, setDdlEnd] = useState(null);
    const [value, setValue] = useState(true);
    const handleChange = (event) => {
        setValue(event.target.value);
        isAllGE = event.target.value;
    };

    const getFormattedDateStr = dateObj => {
        if (dateObj === null) return null;
        const momentDate = moment(dateObj);
        if (!momentDate.isValid()) return null;
        return momentDate.format('YYYY-MM-DD');
    };

    useEffect(() => {
        openForSubmissionDateStart = null;
        openForSubmissionDateEnd = null;
        submissionDeadlineStart = null;
        submissionDeadlineEnd = null;
        isAllGE = true;
    }, []);

    const [open,setOpen] = useState(false);

    function exportExcel() {
        let filename = `Export_Marketing_Data_Submission_Campaign_${moment()
            .utc()
            .format('MMDDYYYY_HHmmss')}.xlsx`;
        let obj = {
            openForSubmissionDate: {
                start: getFormattedDateStr(openForSubmissionDateStart),
                end: getFormattedDateStr(openForSubmissionDateEnd),
            },
            submissionDeadline: {
                start: getFormattedDateStr(submissionDeadlineStart),
                end: getFormattedDateStr(submissionDeadlineEnd),
            },
            isAllGE,
            dataRangeStart,
            dataRangeLength
        };
        if (submissionSelectSize != null) {
            let nk = Math.floor(submissionSelectSize / 1000);

            for (let i = 0; i < nk; i++) {
                obj.dataRangeStart = i * 1000;
                obj.dataRangeLength = 1000;
                dispatch(
                    submissionExportData(
                        obj,
                        '/api/v1/si-service/special-issues/marketing-data/export',
                        filename,
                        'submission',
                    )
                );
            }
            if (submissionSelectSize % 1000 != 0) {
                obj.dataRangeStart = Math.floor(submissionSelectSize / 1000) * 1000;
                obj.dataRangeLength = submissionSelectSize % 1000;

                dispatch(
                    submissionExportData(
                        obj,
                        '/api/v1/si-service/special-issues/marketing-data/export',
                        filename,
                        'submission'
                    )
                );
            }
            else setOpen(true);
        }
    };

    useEffect(() => {
        let obj = {
            openForSubmissionDate: {
                start: getFormattedDateStr(openForSubmissionDateStart),
                end: getFormattedDateStr(openForSubmissionDateEnd),
            },
            submissionDeadline: {
                start: getFormattedDateStr(submissionDeadlineStart),
                end: getFormattedDateStr(submissionDeadlineEnd),
            },
            isAllGE,
        };
        dispatch(
            submissionExportSize(obj)
        );
    }, [, openForSubmissionDateStart,
        openForSubmissionDateEnd,
        submissionDeadlineStart,
        submissionDeadlineEnd,
        isAllGE]);

    return (
        <Box
            sx={{
                width: '753px',
                height: deadDateOpen?'492px':'452px',
                background: '#FFFFFF',
            }}
        >
            <UntitledAlertBox
                open={open}
                handleClose={()=>setOpen(false)}
                message={'No eligible SI to export'}
                severity={'warning'}
            />
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{
                    height: deadDateOpen?'492px':'452px',
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Box
                        sx={{
                            fontFamily: 'Open Sans',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '22px',
                            letterSpacing: '0.01em',
                            color: '#262E35',
                        }}
                    >
                        Select data
                    </Box>
                    <Stack
                        direction='row'
                        alignItems='center'
                        spacing='10px'
                        sx={{
                            marginTop: '16px',
                            marginBottom: '12px',
                        }}
                    >
                        <Box
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                fontWeight: 600,
                                lineHeight: '16px',
                                letterSpacing: '0.01em',
                                color: '#596A7C',
                            }}
                        >
                            Open for Submission date
                        </Box>
                        <Box
                            onClick={() => {
                                setOpenStart(null);
                                setOpenEnd(null);
                                openForSubmissionDateStart = null;
                                openForSubmissionDateEnd = null;
                            }}
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                fontWeight: 400,
                                lineHeight: '16px',
                                letterSpacing: '0em',
                                color: '#154AB6',
                                '&:hover': {
                                    fontWeight: 600,
                                },
                                cursor:'pointer'
                            }}
                        >
                            {`Reset`}
                        </Box>
                    </Stack>
                    <DateRangePicker
                        fromDate={openStart}
                        toDate={openEnd}
                        handleDateChange={(f, t) => {
                            setOpenStart(f);
                            setOpenEnd(t);
                            openForSubmissionDateStart = f;
                            openForSubmissionDateEnd = t;
                        }}
                        maxDate={new Date}
                        innerBorder={'1px solid #DFE4E8'}
                        border={'1px solid #FFFFFF'}
                        backgroundColor='#FFFFFF'
                        iconPaddingRight={0}
                        width='753px'
                    />
                    <Stack
                        direction='row'
                        alignItems='center'
                        spacing='10px'
                        sx={{
                            marginTop: '16px',
                            marginBottom: '12px',
                        }}
                    >
                        <Box
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                fontWeight: 600,
                                lineHeight: '16px',
                                letterSpacing: '0.01em',
                                color: '#596A7C',
                            }}
                        >
                            Submission deadline
                        </Box>
                        <Box
                            onClick={() => {
                                setDdlStart(null);
                                setDdlEnd(null);
                                submissionDeadlineStart = null;
                                submissionDeadlineEnd = null;
                            }}
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                fontWeight: 400,
                                lineHeight: '16px',
                                letterSpacing: '0em',
                                color: '#154AB6',
                                '&:hover': {
                                    fontWeight: 600,
                                },
                                cursor:'pointer'
                            }}
                        >
                            {`Reset`}
                        </Box>
                    </Stack>
                    <DeadlineDateRangePicker
                        setDeadDateOpen={setDeadDateOpen}
                        fromDate={ddlStart}
                        toDate={ddlEnd}
                        handleDateChange={(f, t) => {
                            setDdlStart(f);
                            setDdlEnd(t);
                            submissionDeadlineStart = f;
                            submissionDeadlineEnd = t;
                        }}
                        minDate={new Date}
                        innerBorder={'1px solid #DFE4E8'}
                        border={'1px solid #FFFFFF'}
                        backgroundColor='#FFFFFF'
                        iconPaddingRight={0}
                        width='753px'
                    />
                    <Box
                        sx={{
                            fontFamily: 'Open Sans',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '22px',
                            letterSpacing: '0.01em',
                            color: '#262E35',
                            marginTop: '28px',
                        }}
                    >
                        Setting for export file
                    </Box>
                    <Box
                        sx={{
                            fontFamily: 'Open Sans',
                            fontSize: '12px',
                            fontWeight: 600,
                            lineHeight: '18px',
                            letterSpacing: '0.01em',
                            color: '#596A7C',
                            marginTop: '16px',
                        }}
                    >
                        GE list
                    </Box>
                    <FormControl
                        sx={{
                            marginTop: '12px',
                        }}
                    >
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                value={true} control={<SimpleRadio />} label="All GE"
                                sx={{
                                    '& span': {
                                        fontSize: 14,
                                        fontFamily: 'Open Sans',
                                        fontWeight: 400,
                                        lineHeight: '18px',
                                        letterSpacing: '0em',
                                        color: '#000000',
                                    },
                                }}
                            />
                            <FormControlLabel
                                value={false} control={<SimpleRadio />} label="First 3 lead GE"
                                sx={{
                                    '& span': {
                                        fontSize: 14,
                                        fontFamily: 'Open Sans',
                                        fontWeight: 400,
                                        lineHeight: '18px',
                                        letterSpacing: '0em',
                                        color: '#000000',
                                    },
                                }}
                            />
                        </RadioGroup>
                    </FormControl>
                </Stack>
                <Stack
                    direction='column'
                    alignItems='center'
                    spacing='4px'
                    sx={{width:'753px'}}
                >
                    <Box
                        sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '22px',
                            letterSpacing: '0em',
                            color: '#98A7B6',
                        }}
                        className={!submissionSelectSize && submissionSelectSize!==0?'text-animation-loading':''}
                    >
                      {!submissionSelectSize && submissionSelectSize!==0 ? '' : `${submissionSelectSize} data are selected`}
                    </Box>
                    <Button
                        disableRipple
                        onClick={exportExcel}
                        sx={{
                            width: '617px',
                            height: '50px',
                            padding: '14px 20px 14px 20px',
                            borderRadius: '5px',
                            background: '#0052CC',
                            color: '#FFFFFF',
                            fontFamily: 'Inter',
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '22px',
                            letterSpacing: '0em',
                            textTransform: 'none',
                            '&:hover': {
                                background: '#6D98EE',
                            },
                            '&:active': {
                                background: '#113D95',
                            },
                        }}
                    >
                        Export
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
}

import { Box, Collapse, Stack, Typography } from '@mui/material';

export const CustomEditBlock = ({
  label,
  children,
  error = { isError: false, errorMessage: '' },
}) => {
  return (
    <>
      <Stack
        direction='row'
        alignItems={'center'}
        data-seleunim-id='SystemAdmin_UserManagement-CustomComponent-CustomEditBlock-Stack'
      >
        <Box
          width={'128px'}
          data-seleunim-id='SystemAdmin_UserManagement-CustomComponent-CustomEditBlock-Stack-Box1'
        >
          <Typography
            data-seleunim-id='SystemAdmin_UserManagement-CustomComponent-CustomEditBlock-Stack-Box1-Typography'
            sx={{
              color: '#596A7C',
              '&:after': {
                content: "'*'",
                marginLeft: '2px',
                color: '#C40000',
              },
            }}
          >
            {label}
          </Typography>
        </Box>
        <Box
          width='345px'
          data-seleunim-id='SystemAdmin_UserManagement-CustomComponent-CustomEditBlock-Stack-Box2'
        >
          {children}
        </Box>
      </Stack>
      <Collapse
        in={error.isError}
        data-seleunim-id='SystemAdmin_UserManagement-CustomComponent-CustomEditBlock-Collapse'
      >
        <Stack
          direction='row'
          alignItems={'center'}
          data-seleunim-id='SystemAdmin_UserManagement-CustomComponent-CustomEditBlock-Collapse-Stack'
        >
          <Box
            width={'128px'}
            data-seleunim-id='SystemAdmin_UserManagement-CustomComponent-CustomEditBlock-Collapse-Stack-Box1'
          ></Box>
          <Box
            data-seleunim-id='SystemAdmin_UserManagement-CustomComponent-CustomEditBlock-Collapse-Stack-Box2'
            width='345px'
            sx={{
              color: '#a94442',
              fontWeight: 400,
              fontSize: '12px',
              paddingX: '14px',
              boxSizing: 'border-box',
            }}
          >
            {error.errorMessage}
          </Box>
        </Stack>
      </Collapse>
    </>
  );
};

/* eslint-disable indent */
import { Box, Stack } from '@mui/material';

import { useEffect, useMemo } from 'react';
import { DecisionTitle } from './DecisionTitle';
import { useSelector } from 'react-redux';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import { PERMISSIONS } from '@/constant/permission';

function DecisionTypeBlock(props) {
  const { decisionType, handleDecisionTypeChange, emptyError } = props;

  // 获得必要数据
  const { decisionTypeList } = useSelector(state => {
    return {
      decisionTypeList: state.SIP.decisionType,
    };
  });

  // 根据权限过滤 decisionTypeList
  const filteredDecisionTypeList = useMemo(() =>
    decisionTypeList?.filter(decision => {
      return (
        decision !== 'reject' ||
        hasPermission(PERMISSIONS.SIP_WRITE_JPA) ||
        hasPermission(PERMISSIONS.SIP_WRITE_HANDLING_CE)
      );
    }), [decisionTypeList]);

  const isfilteredDecisionTypeListlength1 = useMemo(
    () => filteredDecisionTypeList?.length === 1,
    [filteredDecisionTypeList]
  );

  useEffect(() => {
    if (filteredDecisionTypeList?.length === 1) {
      handleDecisionTypeChange(null, filteredDecisionTypeList?.[0] ?? '');
    }
  }, [filteredDecisionTypeList]);

  return (
    <>
      <Stack
        data-selenium-id='SIP_DetailPage_DecisionSection-TypeBlock-Stack'
        direction={'row'}
        justifyContent='left'
        alignItems='flex-start'
        flexWrap='wrap'
      >
        <DecisionTitle
          id='DecisionType'
          data-selenium-id='SIP_DetailPage_DecisionSection-TypeBlock-DecisionType'
          title='Decision Type'
          width='130px'
        ></DecisionTitle>
        <Box
          sx={{
            width: '286px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            backgroundColor: '#FFFFFF',
            '&>div.MuiAutocomplete-root.Mui-focused.MuiAutocomplete-hasPopupIcon':
              {
                marginTop: '.6q',
              },
            ...(isfilteredDecisionTypeListlength1 && {
              '&>div>div>div': {
                '&>input': {
                  color: '#262E35!important',
                  '-webkit-text-fill-color': '#262E35!important',
                },
                '&>fieldset': {
                  borderColor: '#98A7B6!important',
                },
                '&>div>button>svg>path': {
                  fill: '#BCC5CF',
                },
              },
            }),
          }}
        >
          <SinpleAutocomplete
            data-selenium-id='SIP_DetailPage_DecisionSection-TypeBlock-Value'
            id='sinpleAutocompleteDecisionType'
            placeholder='Select Decision Type'
            options={filteredDecisionTypeList}
            defaultValue=''
            attribute='value'
            onChange={handleDecisionTypeChange}
            value={decisionType}
            isEmpty={emptyError}
            defaultQuery={true}
            needClearInputValue={false}
            readOnly={true}
            disabled={isfilteredDecisionTypeListlength1}
          />
        </Box>
      </Stack>
    </>
  );
}

let userPermissionArray = localStorage.getItem('userPermissions')?.split(',');
// 检查 userPermissionArray 是否包含特定权限
const hasPermission = permission => {
  return userPermissionArray.includes(permission);
};

export default DecisionTypeBlock;

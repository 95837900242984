import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Tooltip,
  CircularProgress,
  TableCell,
} from '@mui/material';
import {
  StyledTableCellPurple,
  StyledTableCellDisPurple,
  StyledTableCellGreen,
  StyledTableCellDisGreen,
  TwoColorTableRowGreen,
  TwoColorTableRowPurple,
  StyledTableCellNormal,
  ReportNoData,
  TableBox2,
} from '@/pages/Report/ReportSpecificComponents/SpecialColorTableHeader';
import { SimpleTooltip } from '@/componentsphase2/CustomTooltip';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '@/components/DataTable';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  findLongestLineSubstring,
  measureWidth,
} from '@/utils/StringWidthMeasure';
import {GetJournalLastActionDate} from "@/actions/SIMT-Report/Report/ReportJournal";
import {getJournalCeCatData} from "@/actions/SIMT-Report/ReportJournal";

function CeCatTable(props) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const { isCheck } = props;

  const { selectMonthDate, CeCatData } = useSelector(state => {
    return {
      // selectMonthDate: state.Report.ReportJournalOverviewMonth,
      selectMonthDate: state.Report.selectDate,
      CeCatData: state.Report.ReportJournalCeCatTableData,
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const items = [
      {
        id: 'catGroup',
        label: intl.messages['report.Journal.catGroup'],
      },
      {
        id: 'handlingCe',
        label: intl.messages['report.Journal.handlingCe'],
      },
      {
        id: 'activeJournal',
        // label: intl.messages['report.Journal.active']
        label: 'ACTIVE_%',
      },
      {
        id: 'activePercentage',
        label: intl.messages['report.Journal.active'],
      },
      {
        id: 'targetGap',
        label: intl.messages['report.Journal.targetGap'],
      },
      {
        id: 'optInJournals',
        label: intl.messages['report.Journal.optInJournals'] + { headOptMMM },
      },
      {
        id: 'optOutJournals',
        label: intl.messages['report.Journal.optOutJournals'] + { headOptMMM },
      },
      {
        id: 'commission',
        label: intl.messages['report.Journal.commissioningModel'],
      },
      {
        id: 'basic',
        label: intl.messages['report.Journal.basicManagementModel'],
      },
      {
        id: 'onHold',
        label: intl.messages['report.Journal.onHold'],
      },
      {
        id: 'total',
        label: intl.messages['report.Journal.total'],
      },
    ];
    const widthMap = {};
    for (let item of items) {
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '12px Open Sans');
    }
    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (CeCatData && CeCatData.length > 0) {
      const maxMap = {};
      for (let i = 0; i < CeCatData.length; i++) {
        let dataObj = CeCatData[i];

        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength) {
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '16px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [CeCatData]);

  function calcAllJournalWidth() {
    let sum = 0;
    for (let id of ['commission', 'basic', 'onHold', 'total']) {
      sum += Math.max(columnWidthMap[id] ?? 0, columnMinWidthMap[id]) + 50;
    }
    return sum;
  }

  const dispatch = useDispatch();
  const [headOptMMM, setHeadOptMMM] = useState([]);
  const monthList = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];

  const handleNull = value => {
    if (value === null) {
      return '0';
    } else {
      return value;
    }
  };

  const ifGapIsNegative = value => {
    if (value !== '-' && value !== null && value < 0) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    async function getData() {
      if (selectMonthDate != null) {
        await dispatch(getJournalCeCatData(selectMonthDate, !isCheck));
        let MMM = Number(selectMonthDate.split('-')[1]);
        let s = monthList[MMM - 1];
        setHeadOptMMM(s);
        dispatch(GetJournalLastActionDate(selectMonthDate, false));
        setLoading(false);
      }
    }
    setLoading(true);
    getData();
  }, [selectMonthDate, isCheck]);

  return (
    <TableBox2
      data-selenium-id='Report_Journal-CECAT-TableContainerBox'
      dataList={[CeCatData]}
      tableKey={'Report_Journal-CETable'}
      footerHeight={0}
    >
      <TableContainer
        sx={{ maxHeight: '560px' }}
        data-selenium-id='Report_Journal-CECAT-TableContainer'
      >
        <Table
          stickyHeader
          aria--label='sticky table'
          data-selenium-id='Report_Journal-CECAT-Table'
          sx={{
            tableLayout: 'fixed',
            width: 'max-content',
            maxWidth: 'none',
          }}
        >
          <TableHead data-selenium-id='Report_Journal-CECAT-TableHead'>
            {isCheck === true ? (
              <>
                <TableRow data-selenium-id='Report_Journal-CECAT-Table-HeadRow1'>
                  <StyledTableCellNormal
                    id='reportJournalCeCatHeadCatGroupPurpleTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow1Cell1'
                    sx={{
                      // minWidth: '140px',
                      // height: '14px',
                      // padding: '34px 0px 34px 12px',
                      // width: '140px',
                      width: `${
                        Math.max(
                          columnWidthMap['catGroup'] ?? 0,
                          columnMinWidthMap['catGroup']
                        ) + 50
                      }px`,
                      position: 'sticky',
                      left: 0,
                      top: 0,
                      zIndex: 8,
                      backgroundColor: '#DFE4E8',
                      whiteSpace: 'nowrap',
                    }}
                    className='BottomBorder'
                    rowSpan={2}
                    align='left'
                  >
                    <FormattedMessage
                      id={'report.Journal.catGroup'}
                      data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow1Cell1Text'
                    />
                  </StyledTableCellNormal>
                  <StyledTableCellNormal
                    sx={{
                      // minWidth: '135px',
                      // height: '14px',
                      // lineHeight: '14px',
                      // padding: '34px 33px 34px 12px',
                      // width: '135px',
                      width: `${
                        Math.max(
                          columnWidthMap['handlingCe'] ?? 0,
                          columnMinWidthMap['handlingCe']
                        ) + 50
                      }px`,
                      position: 'sticky',
                      left: `${
                        Math.max(
                          columnWidthMap['catGroup'] ?? 0,
                          columnMinWidthMap['catGroup']
                        ) + 50
                      }px`,
                      top: 0,
                      zIndex: 8,
                      backgroundColor: '#DFE4E8',
                      whiteSpace: 'nowrap',
                    }}
                    className='RightBottomBorder'
                    rowSpan={2}
                    align='left'
                    id='reportJournalCeCatHeadHandlingCePurpleTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow1Cell2'
                  >
                    <FormattedMessage
                      id={'report.Journal.handlingCe'}
                      data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow1Cell2Text'
                    />
                  </StyledTableCellNormal>
                  <StyledTableCellPurple
                    // sx={{
                    //   minWidth: '106px',
                    //   height: '14px',
                    //   padding: '34px 32px 34px 12px',
                    //   width: '106px',
                    // }}
                    sx={{
                      width: `${
                        Math.max(
                          columnWidthMap['activeJournal'] ?? 0,
                          columnMinWidthMap['activeJournal']
                        ) + 50
                      }px`,
                      whiteSpace: 'nowrap',
                    }}
                    className='RightBottomBorder'
                    rowSpan={2}
                    align='left'
                    id='reportJournalCeCatHeadActiveJournalsPurpleTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow1Cell3'
                  >
                    <FormattedMessage
                      id={'report.Journal.activeJournals'}
                      data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow1Cell3Text'
                    />
                  </StyledTableCellPurple>
                  <StyledTableCellPurple
                    // sx={{
                    //   minWidth: '437px',
                    //   height: '17px',
                    //   padding: '10px 78px 12px 10px',
                    //   width: '437px',
                    // }}
                    sx={{
                      width: `${calcAllJournalWidth()}px`,
                    }}
                    className='RightBottomBorder'
                    colSpan={4}
                    align='left'
                    id='reportJournalCeCatHeadJournalsIncludedPurpleTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow1Cell4'
                  >
                    <FormattedMessage
                      id={'report.Journal.journalsIncluded'}
                      data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow1Cell4Text'
                    />
                  </StyledTableCellPurple>
                  <StyledTableCellPurple
                    // sx={{
                    //   minWidth: '68px',
                    //   height: '14px',
                    //   padding: '34px 16px 34px 12px',
                    //   width: '68px',
                    // }}
                    sx={{
                      width: `${
                        Math.max(
                          columnWidthMap['activePercentage'] ?? 0,
                          columnMinWidthMap['activePercentage']
                        ) + 50
                      }px`,
                      whiteSpace: 'nowrap',
                    }}
                    className='BottomBorder'
                    rowSpan={2}
                    align='left'
                    id='reportJournalCeCatHeadActivePurpleTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow1Cell5'
                  >
                    <FormattedMessage
                      id={'report.Journal.active'}
                      data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow1Cell5Text'
                    />
                  </StyledTableCellPurple>
                  <StyledTableCellPurple
                    // sx={{
                    //   minWidth: '87px',
                    //   height: '14px',
                    //   padding: '34px 16px 34px 12px',
                    //   width: '87px',
                    // }}
                    sx={{
                      width: `${
                        Math.max(
                          columnWidthMap['targetGap'] ?? 0,
                          columnMinWidthMap['targetGap']
                        ) + 50
                      }px`,
                      whiteSpace: 'nowrap',
                    }}
                    className='BottomBorder'
                    rowSpan={2}
                    align='left'
                    id='reportJournalCeCatHeadTargetGapPurpleTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow1Cell6'
                  >
                    <FormattedMessage
                      id={'report.Journal.targetGap'}
                      data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow1Cell6Text'
                    />
                  </StyledTableCellPurple>
                  <StyledTableCellPurple
                    // sx={{
                    //   minWidth: '118px',
                    //   maxHeight: '82px',
                    //   height: 'auto',
                    //   padding: '0px 24px 0px 12px',
                    // }}
                    sx={{
                      width: `${
                        Math.max(
                          columnWidthMap['optInJournals'] ?? 0,
                          columnMinWidthMap['optInJournals']
                        ) + 50
                      }px`,
                    }}
                    className='BottomBorder'
                    rowSpan={2}
                    align='left'
                    id='reportJournalCeCatHeadOptPurpleTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow1Cell7'
                  >
                    <FormattedMessage
                      id={'report.Journal.optInJournals'}
                      data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow1Cell7Text'
                    />

                    {headOptMMM}
                  </StyledTableCellPurple>
                  <StyledTableCellPurple
                    // sx={{
                    //   minWidth: '118px',
                    //   maxHeight: '82px',
                    //   height: 'auto',
                    //   padding: '0px 24px 0px 12px',
                    // }}
                    sx={{
                      width: `${
                        Math.max(
                          columnWidthMap['optOutJournals'] ?? 0,
                          columnMinWidthMap['optOutJournals']
                        ) + 50
                      }px`,
                    }}
                    className='BottomBorder'
                    rowSpan={2}
                    align='left'
                    id='reportJournalCeCatHeadOptOutPurpleTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow1Cell8'
                  >
                    <FormattedMessage
                      id={'report.Journal.optOutJournals'}
                      data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow1Cell8Text'
                    />

                    {headOptMMM}
                  </StyledTableCellPurple>
                </TableRow>
                <TableRow data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow2'>
                  <StyledTableCellDisPurple
                    // sx={{
                    //   top: '43px',
                    //   minWidth: '133px',
                    //   padding: '10px 20px 13px 10px',
                    // }}
                    sx={{
                      top: 42,
                      width: `${
                        Math.max(
                          columnWidthMap['commission'] ?? 0,
                          columnMinWidthMap['commission']
                        ) + 50
                      }px`,
                    }}
                    className='BottomBorder'
                    align='left'
                    id='reportJournalCeCatHeadCommissionModelPurpleTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow2Cell1'
                  >
                    <FormattedMessage
                      id={'report.Journal.commissioningModel'}
                      data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow2Cell1Text'
                    />
                  </StyledTableCellDisPurple>
                  <StyledTableCellDisPurple
                    // sx={{
                    //   top: '43px',
                    //   minWidth: '155px',
                    //   padding: '10px 20px 13px 10px',
                    // }}
                    sx={{
                      top: 42,
                      width: `${
                        Math.max(
                          columnWidthMap['basic'] ?? 0,
                          columnMinWidthMap['basic']
                        ) + 50
                      }px`,
                    }}
                    className='BottomBorder'
                    align='left'
                    id='reportJournalCeCatHeadBasicModelPurpleTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow2Cell2'
                  >
                    <FormattedMessage
                      id={'report.Journal.basicManagementModel'}
                      data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow2Cell2Text'
                    />
                  </StyledTableCellDisPurple>
                  <StyledTableCellDisPurple
                    // sx={{
                    //   top: '43px',
                    //   minWidth: '63.46px',
                    //   padding: '10px 20px 13px 10px',
                    // }}
                    sx={{
                      top: 42,
                      width: `${
                        Math.max(
                          columnWidthMap['onHold'] ?? 0,
                          columnMinWidthMap['onHold']
                        ) + 50
                      }px`,
                      whiteSpace: 'nowrap',
                    }}
                    className='BottomBorder'
                    align='left'
                    id='reportJournalCeCatHeadOnholdPurpleTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow2Cell3'
                  >
                    <FormattedMessage
                      id={'report.Journal.onHold'}
                      data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow2Cell3Text'
                    />
                  </StyledTableCellDisPurple>
                  <StyledTableCellDisPurple
                    // sx={{
                    //   top: '43px',
                    //   minWidth: '63.46px',
                    //   padding: '10px 10px 13px 10px',
                    // }}
                    sx={{
                      top: 42,
                      width: `${
                        Math.max(
                          columnWidthMap['total'] ?? 0,
                          columnMinWidthMap['total']
                        ) + 50
                      }px`,
                    }}
                    className='RightBottomBorder'
                    align='left'
                    id='reportJournalCeCatHeadTotalPurpleTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow2Cell4'
                  >
                    <FormattedMessage
                      id={'report.Journal.total'}
                      data-selenium-id='Report_Journal-CECAT-Table-AllModeHeadRow2Cell4Text'
                    />
                  </StyledTableCellDisPurple>
                </TableRow>
              </>
            ) : (
              <>
                <TableRow data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1'>
                  <StyledTableCellNormal
                    sx={{
                      // minWidth: '140px',
                      // height: '14px',
                      // padding: '34px 0px 34px 12px',
                      // width: '140px',
                      width: `${
                        Math.max(
                          columnWidthMap['catGroup'] ?? 0,
                          columnMinWidthMap['catGroup']
                        ) + 50
                      }px`,
                      position: 'sticky',
                      left: 0,
                      top: 0,
                      zIndex: 8,
                      backgroundColor: '#DFE4E8',
                      whiteSpace: 'nowrap',
                    }}
                    className='BottomBorder'
                    rowSpan={2}
                    align='left'
                    id='reportJournalCeCatHeadCatGroupGreenTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1Cell1'
                  >
                    <FormattedMessage
                      id={'report.Journal.catGroup'}
                      data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1Cell1Text'
                    />
                  </StyledTableCellNormal>
                  <StyledTableCellNormal
                    sx={{
                      // minWidth: '135px',
                      // height: '14px',
                      // lineHeight: '14px',
                      // padding: '34px 33px 34px 12px',
                      // width: '135px',
                      width: `${
                        Math.max(
                          columnWidthMap['handlingCe'] ?? 0,
                          columnMinWidthMap['handlingCe']
                        ) + 50
                      }px`,
                      position: 'sticky',
                      left: `${
                        Math.max(
                          columnWidthMap['catGroup'] ?? 0,
                          columnMinWidthMap['catGroup']
                        ) + 50
                      }px`,
                      top: 0,
                      zIndex: 8,
                      backgroundColor: '#DFE4E8',
                      whiteSpace: 'nowrap',
                    }}
                    className='RightBottomBorder'
                    rowSpan={2}
                    align='left'
                    id='reportJournalCeCatHeadHandlingCeGreenTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1Cell2'
                  >
                    <FormattedMessage
                      id={'report.Journal.handlingCe'}
                      data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1Cell2Text'
                    />
                  </StyledTableCellNormal>
                  <StyledTableCellGreen
                    // sx={{
                    //   minWidth: '106px',
                    //   height: '14px',
                    //   padding: '34px 32px 34px 12px',
                    //   width: '106px',
                    // }}
                    sx={{
                      width: `${
                        Math.max(
                          columnWidthMap['activeJournal'] ?? 0,
                          columnMinWidthMap['activeJournal']
                        ) + 50
                      }px`,
                      whiteSpace: 'nowrap',
                    }}
                    className='RightBottomBorder'
                    rowSpan={2}
                    align='left'
                    id='reportJournalCeCatHeadActiveJournalsGreenTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1Cell3'
                  >
                    <FormattedMessage
                      id={'report.Journal.activeJournals'}
                      data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1Cell3Text'
                    />
                  </StyledTableCellGreen>
                  <StyledTableCellGreen
                    // sx={{
                    //   minWidth: '437px',
                    //   height: '17px',
                    //   padding: '10px 78px 12px 10px',
                    //   width: '437px',
                    // }}
                    sx={{
                      width: `${calcAllJournalWidth()}px`,
                    }}
                    className='RightBottomBorder'
                    colSpan={4}
                    align='left'
                    data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1Cell4'
                  >
                    <FormattedMessage
                      id={'report.Journal.journalsIncluded'}
                      data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1Cell4Text'
                    />
                  </StyledTableCellGreen>
                  <StyledTableCellGreen
                    // sx={{
                    //   minWidth: '68px',
                    //   height: '14px',
                    //   padding: '34px 16px 34px 12px',
                    //   width: '68px',
                    // }}
                    sx={{
                      width: `${
                        Math.max(
                          columnWidthMap['activePercentage'] ?? 0,
                          columnMinWidthMap['activePercentage']
                        ) + 50
                      }px`,
                      whiteSpace: 'nowrap',
                    }}
                    className='BottomBorder'
                    rowSpan={2}
                    align='left'
                    id='reportJournalCeCatHeadActiveGreenTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1Cell5'
                  >
                    <FormattedMessage
                      id={'report.Journal.active'}
                      data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1Cell5Text'
                    />
                  </StyledTableCellGreen>
                  <StyledTableCellGreen
                    // sx={{
                    //   minWidth: '87px',
                    //   height: '14px',
                    //   padding: '34px 16px 34px 12px',
                    //   width: '87px',
                    // }}
                    sx={{
                      width: `${
                        Math.max(
                          columnWidthMap['targetGap'] ?? 0,
                          columnMinWidthMap['targetGap']
                        ) + 50
                      }px`,
                      whiteSpace: 'nowrap',
                    }}
                    className='BottomBorder'
                    rowSpan={2}
                    align='left'
                    id='reportJournalCeCatHeadTargetGapGreenTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1Cell6'
                  >
                    <FormattedMessage
                      id={'report.Journal.targetGap'}
                      data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1Cell6Text'
                    />
                  </StyledTableCellGreen>
                  <StyledTableCellGreen
                    // sx={{
                    //   minWidth: '118px',
                    //   maxHeight: '82px',
                    //   height: 'auto',
                    //   padding: '0px 24px 0px 12px',
                    // }}
                    sx={{
                      width: `${
                        Math.max(
                          columnWidthMap['optInJournals'] ?? 0,
                          columnMinWidthMap['optInJournals']
                        ) + 50
                      }px`,
                    }}
                    className='BottomBorder'
                    rowSpan={2}
                    align='left'
                    id='reportJournalCeCatHeadOptGreenTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1Cell7'
                  >
                    <FormattedMessage
                      id={'report.Journal.optInJournals'}
                      data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1Cell7Text'
                    />
                    {headOptMMM}
                  </StyledTableCellGreen>
                  <StyledTableCellGreen
                    // sx={{
                    //   minWidth: '118px',
                    //   maxHeight: '82px',
                    //   height: 'auto',
                    //   padding: '0px 24px 0px 12px',
                    // }}
                    sx={{
                      width: `${
                        Math.max(
                          columnWidthMap['optOutJournals'] ?? 0,
                          columnMinWidthMap['optOutJournals']
                        ) + 50
                      }px`,
                    }}
                    className='BottomBorder'
                    rowSpan={2}
                    align='left'
                    id='reportJournalCeCatHeadOptOutGreenTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1Cell8'
                  >
                    <FormattedMessage
                      id={'report.Journal.optOutJournals'}
                      data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow1Cell8Text'
                    />
                    {headOptMMM}
                  </StyledTableCellGreen>
                </TableRow>
                <TableRow data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow2'>
                  <StyledTableCellDisGreen
                    // sx={{
                    //   top: '43px',
                    //   minWidth: '133px',
                    //   padding: '10px 20px 13px 10px',
                    // }}
                    sx={{
                      top: 42,
                      width: `${
                        Math.max(
                          columnWidthMap['commission'] ?? 0,
                          columnMinWidthMap['commission']
                        ) + 50
                      }px`,
                    }}
                    className='BottomBorder'
                    align='left'
                    id='reportJournalCeCatHeadCommissionModelGreenTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow2Cell1'
                  >
                    <FormattedMessage
                      id={'report.Journal.commissioningModel'}
                      data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow2Cell1Text'
                    />
                  </StyledTableCellDisGreen>
                  <StyledTableCellDisGreen
                    // sx={{
                    //   top: '43px',
                    //   minWidth: '155px',
                    //   padding: '10px 20px 13px 10px',
                    // }}
                    sx={{
                      top: 42,
                      width: `${
                        Math.max(
                          columnWidthMap['basic'] ?? 0,
                          columnMinWidthMap['basic']
                        ) + 50
                      }px`,
                    }}
                    className='BottomBorder'
                    align='left'
                    id='reportJournalCeCatHeadBasicModelGreenTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow2Cell2'
                  >
                    <FormattedMessage
                      id={'report.Journal.basicManagementModel'}
                      data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow2Cell2Text'
                    />
                  </StyledTableCellDisGreen>
                  <StyledTableCellDisGreen
                    // sx={{
                    //   top: '43px',
                    //   minWidth: '63.46px',
                    //   padding: '10px 20px 13px 10px',
                    // }}
                    sx={{
                      top: 42,
                      width: `${
                        Math.max(
                          columnWidthMap['onHold'] ?? 0,
                          columnMinWidthMap['onHold']
                        ) + 50
                      }px`,
                      whiteSpace: 'nowrap',
                    }}
                    className='BottomBorder'
                    align='left'
                    id='reportJournalCeCatHeadOnholdGreenTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow2Cell3'
                  >
                    <FormattedMessage
                      id={'report.Journal.onHold'}
                      data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow2Cell3Text'
                    />
                  </StyledTableCellDisGreen>
                  <StyledTableCellDisGreen
                    // sx={{
                    //   top: '43px',
                    //   minWidth: '63.46px',
                    //   padding: '10px 10px 13px 10px',
                    // }}
                    sx={{
                      top: 42,
                      width: `${
                        Math.max(
                          columnWidthMap['total'] ?? 0,
                          columnMinWidthMap['total']
                        ) + 50
                      }px`,
                    }}
                    className='RightBottomBorder'
                    align='left'
                    id='reportJournalCeCatHeadTotalGreenTableCell'
                    data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow2Cell4'
                  >
                    <FormattedMessage
                      id={'report.Journal.total'}
                      data-selenium-id='Report_Journal-CECAT-Table-OAModeHeadRow2Cell4Text'
                    />
                  </StyledTableCellDisGreen>
                </TableRow>
              </>
            )}
          </TableHead>
          <TableBody data-selenium-id='Report_Journal-CECAT-TableBody'>
            {!loading &&
            CeCatData != null &&
            CeCatData != undefined &&
            CeCatData.length != 0
              ? CeCatData.map(item => {
                  if (
                    item.catGroup !== 'Sub-total' &&
                    item.catGroup !== 'Total'
                  ) {
                    if (isCheck === true) {
                      return (
                        <TwoColorTableRowPurple data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow1'>
                          <StyledTableCell
                            align='left'
                            // sx={{ width: '124px' }}
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow1Cell1'
                            sx={{
                              position: 'sticky',
                              left: 0,
                              zIndex: 6,
                              backgroundColor: '#F1F3F5',
                            }}
                          >
                            <SimpleTooltip title={item.catGroup}>
                              {item.catGroup}
                            </SimpleTooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder'
                            // sx={{ width: '152px' }}
                            sx={{
                              position: 'sticky',
                              left: `${
                                Math.max(
                                  columnWidthMap['catGroup'] ?? 0,
                                  columnMinWidthMap['catGroup']
                                ) + 50
                              }px`,
                              zIndex: 6,
                              backgroundColor: '#F1F3F5',
                            }}
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow1Cell2'
                          >
                            <SimpleTooltip title={item.emailOfHandlingCe}>
                              {item.handlingCe}
                            </SimpleTooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow1Cell3'
                          >
                            <div style={{ marginRight: '16px' }}>
                              {handleNull(item.activeJournal)}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow1Cell4'
                          >
                            <div style={{ marginRight: '4px' }}>
                              {handleNull(item.commission)}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow1Cell5'
                          >
                            <div style={{ marginRight: '4px' }}>
                              {handleNull(item.basic)}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow1Cell6'
                          >
                            <div style={{ marginRight: '4px' }}>
                              {handleNull(item.onHold)}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow1Cell7'
                          >
                            {handleNull(item.total)}
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow1Cell8'
                          >
                            <div style={{ marginRight: '10px' }}>
                              {handleNull(item.activePercentage)}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow1Cell9'
                          >
                            <div style={{ marginRight: '10px' }}>
                              {!ifGapIsNegative(item.targetGap) ? (
                                <div className='ReportNumberTextColor'>
                                  {item.targetGap}
                                </div>
                              ) : (
                                <div className='ReportNumberTextColorRed'>
                                  {item.targetGap}
                                </div>
                              )}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow1Cell10'
                          >
                            <div style={{ marginRight: '8px' }}>
                              {handleNull(item.optInJournals)}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow1Cell11'
                          >
                            <div style={{ marginRight: '8px' }}>
                              {handleNull(item.optOutJournals)}
                            </div>
                          </StyledTableCell>
                        </TwoColorTableRowPurple>
                      );
                    } else {
                      return (
                        <TwoColorTableRowGreen data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow1'>
                          <StyledTableCell
                            align='left'
                            // sx={{ width: '124px' }}
                            sx={{
                              position: 'sticky',
                              left: 0,
                              zIndex: 6,
                              backgroundColor: '#F1F3F5',
                            }}
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow1Cell1'
                          >
                            <SimpleTooltip title={item.catGroup}>
                              {item.catGroup}
                            </SimpleTooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder'
                            // sx={{ width: '152px' }}
                            sx={{
                              position: 'sticky',
                              left: `${
                                Math.max(
                                  columnWidthMap['catGroup'] ?? 0,
                                  columnMinWidthMap['catGroup']
                                ) + 50
                              }px`,
                              zIndex: 6,
                              backgroundColor: '#F1F3F5',
                            }}
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow1Cell2'
                          >
                            <SimpleTooltip title={item.emailOfHandlingCe}>
                              {item.handlingCe}
                            </SimpleTooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow1Cell3'
                          >
                            <div style={{ marginRight: '16px' }}>
                              {' '}
                              {item.activeJournal}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow1Cell4'
                          >
                            <div style={{ marginRight: '4px' }}>
                              {item.commission}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow1Cell5'
                          >
                            <div style={{ marginRight: '4px' }}>
                              {item.basic}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow1Cell6'
                          >
                            <div style={{ marginRight: '4px' }}>
                              {item.onHold}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow1Cell7'
                          >
                            {item.total}
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow1Cell8'
                          >
                            <div style={{ marginRight: '10px' }}>
                              {item.activePercentage}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow1Cell9'
                          >
                            <div style={{ marginRight: '10px' }}>
                              {!ifGapIsNegative(item.targetGap) ? (
                                <div className='ReportNumberTextColor'>
                                  {item.targetGap}
                                </div>
                              ) : (
                                <div className='ReportNumberTextColorRed'>
                                  {item.targetGap}
                                </div>
                              )}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow1Cell10'
                          >
                            <div style={{ marginRight: '8px' }}>
                              {item.optInJournals}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow1Cell11'
                          >
                            <div style={{ marginRight: '8px' }}>
                              {item.optOutJournals}
                            </div>
                          </StyledTableCell>
                        </TwoColorTableRowGreen>
                      );
                    }
                  } else {
                    let catGroup = '';
                    if (item.catGroup === 'Sub-total') {
                      catGroup = 'Subtotal';
                    } else {
                      catGroup = 'Total';
                    }
                    if (isCheck === true) {
                      return (
                        <TwoColorTableRowPurple data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow2'>
                          <StyledTableCell
                            align='right'
                            className='RightBorder ReportTotalTextSize'
                            colSpan={2}
                            // sx={{ width: '304px' }}
                            sx={{
                              position: 'sticky',
                              left: 0,
                              zIndex: 6,
                              backgroundColor: '#F1F3F5',
                            }}
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow2Cell1'
                          >
                            <div
                              style={{ marginRight: '-4px', fontSize: '16px' }}
                            >
                              <Box> {catGroup}</Box>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow2Cell2'
                          >
                            <div style={{ marginRight: '16px' }}>
                              <Box>{item.activeJournal}</Box>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow2Cell3'
                          >
                            <div style={{ marginRight: '4px' }}>
                              <Box>{item.commission}</Box>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow2Cell4'
                          >
                            <div style={{ marginRight: '4px' }}>
                              <Box>{item.basic}</Box>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow2Cell5'
                          >
                            <div style={{ marginRight: '4px' }}>
                              <Box>{item.onHold}</Box>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow2Cell6'
                          >
                            <Box>{item.total}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow2Cell7'
                          >
                            <div style={{ marginRight: '10px' }}>
                              <Box>{item.activePercentage}</Box>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow2Cell8'
                          >
                            <div style={{ marginRight: '10px' }}>
                              <Box>
                                {!ifGapIsNegative(item.targetGap) ? (
                                  <div className='ReportNumberTextColor'>
                                    {item.targetGap}
                                  </div>
                                ) : (
                                  <div className='ReportNumberTextColorRed'>
                                    {item.targetGap}
                                  </div>
                                )}
                              </Box>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow2Cell9'
                          >
                            <div style={{ marginRight: '8px' }}>
                              <Box>{item.optInJournals}</Box>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-AllModeBodyRow2Cell10'
                          >
                            <div style={{ marginRight: '8px' }}>
                              <Box>{item.optOutJournals}</Box>
                            </div>
                          </StyledTableCell>
                        </TwoColorTableRowPurple>
                      );
                    } else {
                      return (
                        <TwoColorTableRowGreen data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow2Cell'>
                          <StyledTableCell
                            align='right'
                            className='RightBorder ReportHeaderFirstLine'
                            colspan='2'
                            // sx={{ width: '304px' }}
                            sx={{
                              position: 'sticky',
                              left: 0,
                              zIndex: 6,
                              backgroundColor: '#F1F3F5',
                            }}
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow2Cell1'
                          >
                            <div
                              style={{ marginRight: '-4px', fontSize: '16px' }}
                            >
                              <Box>{catGroup}</Box>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow2Cell2'
                          >
                            <div style={{ marginRight: '16px' }}>
                              {item.activeJournal}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow2Cell3'
                          >
                            <div style={{ marginRight: '4px' }}>
                              {item.commission}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow2Cell4'
                          >
                            <div style={{ marginRight: '4px' }}>
                              {item.basic}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow2Cell5'
                          >
                            <div style={{ marginRight: '4px' }}>
                              {item.onHold}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow2Cell6'
                          >
                            {item.total}
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow2Cell7'
                          >
                            <div style={{ marginRight: '10px' }}>
                              {item.activePercentage}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow2Cell8'
                          >
                            <div style={{ marginRight: '10px' }}>
                              {!ifGapIsNegative(item.targetGap) ? (
                                <div className='ReportNumberTextColor'>
                                  {item.targetGap}
                                </div>
                              ) : (
                                <div className='ReportNumberTextColorRed'>
                                  {item.targetGap}
                                </div>
                              )}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow2Cell9'
                          >
                            <div style={{ marginRight: '8px' }}>
                              {item.optInJournals}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id='Report_Journal-CECAT-Table-OAModeBodyRow2Cell10'
                          >
                            <div style={{ marginRight: '8px' }}>
                              {item.optOutJournals}
                            </div>
                          </StyledTableCell>
                        </TwoColorTableRowGreen>
                      );
                    }
                  }
                })
              : !loading && (
                  <ReportNoData id='reportJournalCECATNoResultTableCell' />
                )}
          </TableBody>
        </Table>
        {loading && (
          <Box
            sx={{
              width: 'auto',
              marginTop: '45px',
              marginLeft: '50%',
            }}
            data-selenium-id='Report_Journal-CECAT-LoadingBox'
          >
            <CircularProgress data-selenium-id='Report_Journal-CECAT-CircularProgress' />
          </Box>
        )}
      </TableContainer>
    </TableBox2>
  );
}
export default CeCatTable;

// create Redux action types
export const CONFIRM_OPEN = 'CONFIRM_OPEN';
export const SET_EDIT_TYPE = 'SET_EDIT_TYPE';
export const SET_TEXT_PARAM = 'SET_TEXT_PARAM';
export const SET_POSITION = 'SET_POSITION';
export const SET_COLOR = 'SET_COLOR';
export const CLOSE_WINDOW = 'CLOSE_WINDOW';
export const CLEAR_ALERT = 'CLEAR_ALERT';

export const FETCH_UNREAD_ALERTS_COUNT = 'FETCH_UNREAD_ALERTS_COUNT';
export const FETCH_ALERTS = 'FETCH_ALERTS';
export const SET_ALERTS_FINISHED = 'FETCH_ALERTS_FINISHED';
export const FETCH_UNREAD_ALERTS = 'FETCH_UNREAD_ALERTS';
export const SET_UNREAD_ALERTS_FINISHED = 'FETCH_UNREAD_ALERTS_FINISHED';
export const DELETE_ALERT = 'DELETE_ALERT';
export const READ_ALERT = 'READ_ALERT';

export const SET_NOTIFICATION_DRAWER_OPEN = 'SET_NOTIFICATION_DRAWER_OPEN';
export const SET_CURRENT_NOTIFICATION_ITEM_INFO =
  'SET_CURRENT_NOTIFICATION_ITEM_INFO';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const SET_CURRENT_NOTIFICATION_ITEM = 'SET_CURRENT_NOTIFICATION_ITEM';

export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const CLEAR_UNREAD_ALERTS = 'CLEAR_UNREAD_ALERTS';

// Create Redux action creators that return an action
export const openConfirm = () => ({
  type: CONFIRM_OPEN,
});

export const setEditType = type => ({
  type: SET_EDIT_TYPE,
  data: type,
});

export const setTextParam = text => ({
  type: SET_TEXT_PARAM,
  data: text,
});

export const setPosition = (vertical, horizontal) => ({
  type: SET_POSITION,
  data: {
    vertical: vertical,
    horizontal: horizontal,
  },
});

export const setColor = color => ({
  type: SET_COLOR,
  data: color,
});

export const closeConfirm = () => {
  return dispatch => {
    dispatch({ type: CLOSE_WINDOW });
    dispatch({ type: CLEAR_ALERT });
  };
};

export const setNotificationDrawerOpen = open => {
  return {
    type: SET_NOTIFICATION_DRAWER_OPEN,
    data: open,
  };
};

export const setCurrentNotificationItemInfo = currentNotificationItemInfo => {
  return {
    type: SET_CURRENT_NOTIFICATION_ITEM_INFO,
    data: { ...currentNotificationItemInfo },
  };
};

export const clearAlerts = isRead => {
  return dispatch => {
    dispatch({
      type: isRead === null ? CLEAR_ALERTS : CLEAR_UNREAD_ALERTS,
    });
  };
};

import DrawerCloseButton from '@/componentsphase2/DrawerCloseButton';
import { BlueBackdrop } from '@/modules/Modal/SIModal';
import { Box, Drawer } from '@mui/material';

function BottomDrawer(props) {
  const { open, onClose, children } = props;
  return (
    <Drawer
      anchor='bottom'
      open={open}
      onClose={onClose}
      ModalProps={{
        components: {
          Backdrop: BlueBackdrop,
        },
      }}
      PaperProps={{
        sx: {
          borderTop: '1px solid #f1f3f5',
          borderRadius: '5px',
          '::-webkit-scrollbar': {
            width: '30px',
          },
          '::-webkit-scrollbar-thumb': {
            border: '12px solid transparent',
            borderRadius: '30px',
            '&:hover': {
              borderColor: '#fff',
            },
            '&:active': {
              borderColor: '#fff',
            },
          },
        },
      }}
    >
      <Box sx={{ position: 'relative', width: '100vw' }}>
        <DrawerCloseButton
          onClick={onClose}
          sx={{ position: 'absolute', right: '30px', top: '12px' }}
        />
      </Box>
      {children}
    </Drawer>
  );
}

export default BottomDrawer;

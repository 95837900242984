import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@mui/material';

export default function CustomDialog(props) {
  const { title, info, open, handleClose, handleOK, isSaving } = props;

  return (
    <Dialog
      PaperProps={{
        style: {
          boxShadow: 'none',
        },
      }}
      sx={{
        zIndex: 1401,
        // background: ' rgba(9, 30, 66, 0.54)',
        '& .MuiDialog-paper': {
          // minWidth: '650px',
          // minHeight: '135px',
          // width: '600px',
          // margin: 0,
          // padding: '16px 16px 0px 16px',
          boxSizing: 'border-box',
        },

        '.css-hz1bth-MuiDialog-container': {
          // background: 'rgba(9, 30, 66, 0.54)',
        },
        '& .MuiBackdrop-root': {
          background: 'rgba(17, 61,149, 0.5)',
          backdropFilter: 'blur(4px)',

        },
      }}
      open={open}
      maxWidth={false}
      data-selenium-id='SIP_DetailPage_Submodule-Custom-SetDiaglog'
    >
      <DialogTitle
        data-selenium-id='SIP_DetailPage_Submodule-CustomDialog-AlertFormTitle'
        className='AlertFormDialogTitle'
        sx={{ padding: '14px 24px', fontSize: '24px', lineHeight: '36px' }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        data-selenium-id='SIP_DetailPage_Submodule-CustomDialog-AlertFormContent'
        className='AlertFormDialogContent'
        sx={{ padding: '8px 24px', paddingTop: '8px' , overflow:'hidden'}}
      >
        <DialogContentText
          id='alertDialogContentText'
          data-selenium-id='SIP_DetailPage_Submodule-CustomDialog-AlertContentText'
          sx={{ color: '#383838' }}
        >
          {info}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id='alertDialogCancelButton'
          data-selenium-id='SIP_DetailPage_Submodule-CustomDialog-AlertContentButton'
          variant='text'
          color='darkBlue'
          className='AlertFormDialogActionButton'
          sx={{ fontWeight: '600', fontSize: '14px', lineHeight: '20px' }}
          onClick={handleClose}
        >
          CANCEL
        </Button>

        <Button
          id='alertDialogOKButton'
          data-selenium-id='SIP_DetailPage_Submodule-CustomDialog-AlertContentOKButton'
          variant='text'
          color='darkBlue'
          className='AlertFormDialogActionButton'
          sx={{ fontWeight: '600', fontSize: '14px', lineHeight: '20px' }}
          onClick={handleOK}
        >
          {isSaving ? (
            <CircularProgress
              size={16}
              data-selenium-id='SIP_DetailPage_Submodule-CustomDialog-CircularProgress'
            />
          ) : (
            'OK'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import SimpleLabel from '@/componentsphase2/Input/SimpleLabel';
import SimpleRadio from '@/componentsphase2/Input/SimpleRadio';
import { Label } from '@mui/icons-material';
import { Box, RadioGroup } from '@mui/material';
import { tr } from 'date-fns/locale';
import React, { useEffect } from 'react';

export default function RadioBlock(props) {
  const {
    id = 'checklist',
    title = 'Qualified:',
    editing = true,
    //如果使用默认的YES/NO，需要传入value和setValue
    value = 'N/A',
    setValue = () => { },
    radioGropList = ['Yes', 'No'],
    radioLabelList,
    error = false,
    disabled = false,
    setDisabled= () => { },
    noTitle = false,
    initialYes=false,
    mustTransferBack=false,
    radioMustTransfer=false,
  } = props;
  React.useEffect(()=>{
    if(initialYes && editing){
      setValue('Yes');
    }
  },[editing]);
  const label=radioLabelList instanceof Array && radioGropList.indexOf(value)!==-1 ?radioLabelList[
    radioGropList.indexOf(value)
  ]: value;
  return !editing && !value ? null : (
    <Box
      id={`${id}-RadioBlock`}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {!noTitle ?
        <Box
          sx={{
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            latterSpacing: '1%',
            color: '#596A7C',
          }}
        >
          {title}
        </Box>
        : <></>}
      {editing ? (
        <RadioGroup
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
          value={value}
          onChange={(e, v) => {
            setValue(v);
          }}
        >
          <SimpleLabel
            value={radioGropList[0]}
            label={ radioLabelList instanceof Array ?radioLabelList[0]: radioGropList[0]}
            control={
              <SimpleRadio
                isError={mustTransferBack===true?radioMustTransfer:error}
                disabled={disabled}
                sx={{
                  marginLeft: noTitle ? '0px' : '32px',
                  '&,& input': {
                    width: '24px',
                    height: '24px',
                  },
                  '&.MuiRadio-root': {
                    padding: '0 !important',
                    color:'#EE5350',
                    marginRight: '6px',
                  },
                }}
              />
            }
            sx={{
              marginRight: '0',
              '.MuiFormControlLabel-label': {
                //styleName: Paragraph/P1 / Regular;
                fontFamily: 'Inter !important',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: ' 24px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#0F172A',
                height: '24px',
              },
              marginLeft: '0px !important',
              '& .MuiFormControlLabel-root':{
                marginLeft: '0px !important'
              }
            }}
          />
          {
            radioGropList.length > 1 &&
            radioGropList.slice(1).map((item, index) => (
              <SimpleLabel
                key={item}
                value={item}
                label={radioLabelList instanceof Array ?radioLabelList[index+1]: item}
                control={
                  <SimpleRadio
                    isError={error}
                    disabled={mustTransferBack===true?mustTransferBack:disabled}
                    sx={{
                      '&,& input': {
                        width: '24px',
                        height: '24px',
                      },
                      '&.MuiRadio-root': {
                        padding: '0 !important',
                        marginRight: '6px',
                      },
                    }}
                  />
                }
                sx={{
                  marginRight: '0',
                  '.MuiFormControlLabel-label': {
                    //styleName: Paragraph/P1 / Regular;
                    fontFamily: 'Inter !important',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: ' 24px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                    color: '#0F172A',
                    height: '24px',
                  },
                  marginLeft: '56px !important',
                  '& .MuiFormControlLabel-root':{
                    marginLeft: '56px !important'
                  }
                }}
              />
            ))
          }
        </RadioGroup>
      ) : (
        <Box
          sx={{
            marginLeft: noTitle?'0px':'12px',
            color: '#262E35',
            fontSize: '14px',
            fontWeight: '400',
          }}
        >
          {label}
        </Box>
      )}
    </Box>
  );
}

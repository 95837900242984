/*
 * @Author: Huang Tao
 * @Date:2022/1/19
 * @Description:User Edit(New or Update) props
 */
import {
  Button,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  IconButton,
  Stack,
  Collapse,
} from '@mui/material';

import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import '../../../App.css';
import ErrorAlert from './errorAlert';
import { openConfirm, setTextParam, setEditType } from '@/actions/SIMT-User/Alert/AlertAction';
import {
  editClear,
  clear,
} from '@/actions/SIMT-User/User/UserAction';
import { CustomTextField } from './CustomComponents/CustomTextField';
import { CommonDialog } from '@/modules/AlertDialog';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { SIModal } from '@/modules/Modal';
import { SIMTButton } from '@/components/StyledButton/SIMTButton';
import { CustomEditBlock } from './CustomComponents/CustomEditBlock';
import { CustomSelect } from './CustomComponents/CustomSelect';
import SelectDownIcon from '@/assets/SelectDownIcon.svg';
import { setSnackbarMessageAndOpen } from '../../../actions/SnackbarAction';
import { SEVERITIES } from '../../../modules/ConfirmSnackBar/CommonSnackBar';
import {getRoleInfo} from "@/actions/SIMT-User/Role";
import {checkUserEmailByUserService, createUser, updateUser} from "@/actions/SIMT-User/User/SystemAdmin";
import {UserIdContext} from './UserTable';

export default function EditUser(props) {
  const {
    open,
    setOpen,
    Email,
    isCreate,
    FirstName,
    LastName,
    Group,
    Role,
    updateData,
  } = props;
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [InactiveOpen, setInactiveOpen] = useState(false);
  const [Err, setErr] = useState(false);
  const [Correct, setCorrect] = useState(false);
  const [roleId, setRoleId] = useState(-1);
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [group, setGroup] = useState();
  const [role, setRole] = useState();
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [FullName, setFullName] = useState(LastName + ', ' + FirstName);
  const [emailErr, setEmailErr] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (open) {
      if (roleId === -1) {
        getAllRole();
        setEmailErr(false);
        setErr(false);
        setEmailExitError(false);
      }
    }
  }, [open]);

  useEffect(() => {
    setEmail(Email);
    setFirstName(FirstName);
    setLastName(LastName);
    setGroup(Group);
    setRole(Role);
    setFullName(LastName + ',' + FirstName);
  }, [updateData]);

  const [ifAStatus, setIfAStatus] = useState('normal user ');
  const [roleData, setRoleData1] = useState({
    result: [
      {
        roleId: 1,
        roleName: 'Tier1',
        updateTime: 'Janurary 7,2022 07:51:20',
        updateBy: 'Alice',
      },
      {
        roleId: 2,
        roleName: 'Tier3',
        updateTime: 'Janurary 8,2022 07:51:20',
        updateBy: 'Ben',
      },
    ],
    count: 2,
  });

  const [EmailExitError, setEmailExitError] = useState(false);
  const dispatch = useDispatch();

  const selector = state => {
    return {
      createUserResult: state.User.createUserResult,
      updateUserResult: state.User.updateUserResult,
      allRoles: state.Role.roleInfo,
      allGroup: state.SystemConfig.groupList,
      checkEmail: state.User.checkEmail,
    };
  };
  const { createUserResult, updateUserResult, allRoles, allGroup, checkEmail } =
    useSelector(selector);

  const createUserAction = async ID => {
    await dispatch(createUser(firstName, lastName, email, group, ID));
  };

  const userId=useContext(UserIdContext);
  const editUserAction = async ID => {
    await dispatch(updateUser(firstName, lastName, email, groups?.find(i=>i?.value===group)?.id, ID, role, userId));
  };

  useEffect(() => {
    ChangeTextState(email, role, firstName, lastName, group);
  }, [email, role, firstName, lastName, group]);

  const ChangeTextState = (
    emailText,
    roleText,
    firstNameText,
    lastNameText,
    groupText
  ) => {
    var isok = true;
    setErr(false);
    setCorrect(false);
    if (
      roleText === '' ||
      firstNameText === '' ||
      lastNameText === '' ||
      groupText === '' ||
      emailText === ''
    ) {
      isok = false;
    }
    if (isok === true) {
      setErr(false);
      setCorrect(true);
    }
    if (isok === false) {
      setErr(true);
      setCorrect(false);
    }
  };

  const handleEmailCheck = () => {
    if (isCreate) {
      const reg =
        /^\w+((-\w+)|(\.\w+)|(_\w+))*@[A-Za-z0-9]+(([.\-])[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
      const isok = reg.test(email);
      setEmailErr(!isok);
      // dispatch(checkUserEmail(email));
      checkUserEmailByUserService({email})(dispatch);
    } else {
      setEmailErr(false);
      setEmailExitError(false);
    }
  };

  //输入清楚
  const inputClear = () => {
    setEmail('');
    setFirstName('');
    setLastName('');
    setGroup('');
    setRole('');
    setRoleId(-1);
  };

  //整体弹框关闭
  const handleClose = () => {
    // inputClear();
    dispatch(clear());
    setEmailExitError(false);
    setOpen(false);
  };

  //弹窗打开
  const handleConfirmOpen = () => {
    setOpen(false);
    setConfirmOpen(true);
  };

  //新建用户
  const handleNewUser = async () => {
    setIsUpdating(true);
    dispatch(editClear());
    let ID = RoleIdSearch(role);
    if (ID > -1) {
      await createUserAction(ID);
    }
  };

  useEffect(() => {
    setIsUpdating(false);
    let result = createUserResult;
    if (result === 'true') {
      dispatch(setTextParam(lastName + ', ' + firstName));
      dispatch(setEditType('createUser'));
      // dispatch(openConfirm());
      inputClear();
      setConfirmOpen(false);
      setOpen(false);
      dispatch(clear());
    } else if (result === 'EmailExit') {
      setEmailExitError(true);
      setConfirmOpen(false);
      if (isCreate) {
        setOpen(true);
      }
    } else {
      // inputClear();
      setConfirmOpen(false);
    }
  }, [createUserResult]);

  useEffect(() => {
    setEmailExitError(checkEmail);
  }, [checkEmail]);

  //用户更新
  const handleUpdateUser = async () => {
    setIsUpdating(true);
    dispatch(editClear());
    let ID = -1;
    ID = RoleIdSearch(role);

    if (ID > -1) {
      await editUserAction(ID);
    }
  };

  useEffect(() => {
    let result = updateUserResult;
    setIsUpdating(false);
    if (result === 'true') {
      dispatch(setTextParam(lastName + ', ' + firstName));
      dispatch(setEditType('updateUser'));
      // dispatch(openConfirm());
      dispatch(
        setSnackbarMessageAndOpen(
          'user.updateSucc',
          { fullname: `${lastName}, ${firstName}` },
          SEVERITIES.success
        )
      );
      inputClear();
      setConfirmOpen(false);
      setOpen(false);
      dispatch(editClear());
    } else {
      inputClear();
      setConfirmOpen(false);
    }
  }, [updateUserResult]);

  //弹窗关闭
  const confirmClose = () => {
    setConfirmOpen(false);
    setOpen(true);
    // handleClose();
  };

  //RoleChange
  const handleRoleChange = event => {
    setRole(event.target.value);
  };

  const handleGroupChange = event => {
    setGroup(event.target.value);
  };

  //状态弹框关闭
  const handleActiveCLose = () => {
    setInactiveOpen(false);
  };

  //状态更新
  const handleStatusChange = () => {
    setInactiveOpen(false);
  };

  const [groups, setGroups] = useState([]);

  const getAllRole = async () => {
    await dispatch(getRoleInfo(0, 0, 'roleName', 'asc'));
  };

  useEffect(() => {
    var Roles = {};
    if (allRoles.result) {
      Roles.result = allRoles.result.filter((role, index) => {
        return role.roleName !== 'Admin' && role.roleName !== 'SystemAdmin';
      });
      setRoleData1(Roles);
    }
  }, [allRoles]);

  useEffect(() => {
    setGroups(allGroup);
  }, [allGroup]);

  useEffect(() => {
    var Roles = {};
    if (allRoles.result) {
      Roles.result = allRoles.result.filter((role, index) => {
        return role.roleName !== 'Admin' && role.roleName !== 'SystemAdmin';
      });
      setRoleData1(Roles);
    }
  }, [allRoles]);

  const RoleIdSearch = roleName => {
    for (var i = 0; i < roleData.result.length; i++) {
      if (roleData.result[i].roleName === roleName) {
        setRoleId(roleData.result[i].roleId);
        return roleData.result[i].roleId;
      }
    }
  };

  return (
    <React.Fragment>
      {/* <Modal open={open}> */}
      <SIModal
        data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal'
        open={open}
        handleClose={handleClose}
        title={isCreate ? 'Create User' : `Update User ${FullName}`}
        height='auto'
        boxSx={{
          padding: '32px 35px 32px 35px',
          borderRadius: '8px',
        }}
      >
        <Box
          className='basic_create_user_part'
          data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box'
        >
          {/*<Draggable>*/}
          <Collapse
            in={EmailExitError}
            data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Collapse'
          >
            <ErrorAlert data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Collapse-ErrorAlert' />
          </Collapse>
          {/* 内容 */}
          <Stack
            spacing='16px'
            data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack'
          >
            {/* Role */}
            <CustomEditBlock
              label={'Role'}
              data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-CustomEditBlock1'
            >
              <FormControl
                fullWidth
                data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-CustomEditBlock1-FormControl'
              >
                <CustomSelect
                  id='systemAdminUserManagementEditUserRoleSelect'
                  data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-CustomEditBlock1-FormControl-CustomSelect-RoleSelect'
                  value={role}
                  onChange={handleRoleChange}
                  IconComponent={SelectDownIcon}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '300px',
                      },
                    },
                  }}
                >
                  {roleData.result.map((role, index) => (
                    <MenuItem
                      id={`systemAdminUserManagementEditUserRoleSelectItem${index}`}
                      data-selenium-id={`SystemAdmin-UserManagementEditUserRoleSelectItem-${index}`}
                      key={role.roleId}
                      value={role.roleName}
                    >
                      {role.roleName}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </CustomEditBlock>
            {/* FirstName */}
            <CustomEditBlock
              label={'First Name'}
              data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-CustomEditBlock2'
            >
              <CustomTextField
                id='systemAdminUserManagementEditUserFirstNameInput'
                data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-CustomEditBlock2-CustomTextField-FirstNameInput'
                fullWidth
                defaultValue={firstName}
                value={firstName}
                limitLength={40}
                setValue={setFirstName}
                exSpecialChar
                setSCErr={setFirstNameErr}
              />
            </CustomEditBlock>
            {/* LastName */}
            <CustomEditBlock
              label={'Last Name'}
              data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-CustomEditBlock3'
            >
              <CustomTextField
                id='systemAdminUserManagementEditUserLastNameInput'
                data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-CustomEditBlock3-CustomTextField-LastNameInput'
                placeholder=''
                fullWidth
                defaultValue={lastName}
                value={lastName}
                limitLength={40}
                setValue={setLastName}
                exSpecialChar
                setSCErr={setLastNameErr}
              />
            </CustomEditBlock>
            {/* Email Address */}
            <CustomEditBlock
              data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-CustomEditBlock4'
              label={'Email Address'}
              error={{
                isError: emailErr,
                errorMessage:
                  'The mail format is invalid. Please enter a valid email address.',
              }}
            >
              <CustomTextField
                placeholder=''
                fullWidth
                id='systemAdminUserManagementEditUserEmailInput'
                data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-CustomEditBlock4-CustomTextField-EmailInput'
                defaultValue={email}
                limitLength={100}
                disabled={!isCreate}
                value={email}
                setValue={setEmail}
                onBlur={handleEmailCheck}
                error={emailErr}
              />
            </CustomEditBlock>
            {/* Group/Title */}
            <CustomEditBlock
              label={'Group/Title'}
              data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-CustomEditBlock5'
            >
              <FormControl
                fullWidth
                data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-CustomEditBlock5-FormControl'
              >
                <CustomSelect
                  id='systemAdminUserManagementEditUserGroupSelect'
                  data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-CustomEditBlock5-FormControl-CustomSelect-GroupSelect'
                  value={group}
                  onChange={handleGroupChange}
                  IconComponent={SelectDownIcon}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '150px',
                      },
                    },
                  }}
                >
                  {groups.map((group, index) => (
                    <MenuItem
                      key={index}
                      value={group.value}
                      data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-CustomEditBlock5-FormControl-CustomSelect-GroupSelect-MenuItem'
                    >
                      <div style={{ width: '300px', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {group.value}
                      </div>
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </CustomEditBlock>
            {/* button */}
            <Stack
              direction={'row'}
              justifyContent='flex-end'
              spacing='24px'
              data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-Stack'
            >
              <SIMTButton
                onClick={handleClose}
                data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-Stack-SIMTButton1'
              >
                Cancel
              </SIMTButton>
              <SIMTButton
                disabled={
                  !isUpdating &&
                  !Err &&
                  Correct &&
                  !emailErr &&
                  !lastNameErr &&
                  !firstNameErr &&
                  !EmailExitError
                    ? false
                    : true
                }
                onClick={handleConfirmOpen}
                variant='contained'
                id='systemAdminUserManagementEditUserButton'
                data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-Stack-SIMTButton2-Button'
              >
                <div
                  id='systemAdminUserManagementCNUbuttonText'
                  data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-SIModal-Box-Stack-Stack-SIMTButton2-Button-CNUbuttonText'
                >
                  {isCreate ? 'Add' : 'Update'}
                </div>
              </SIMTButton>
            </Stack>
          </Stack>
          {/*</Draggable>*/}
        </Box>
        {/* </Modal> */}
      </SIModal>
      {/* 当点击create/update之后弹出的提示框 */}
      <CommonDialog
        title={
          isCreate
            ? 'Create a normal user ' + lastName + ',' + firstName
            : 'Update ' + Role + ' user ' + LastName + ',' + FirstName
        }
        id='EditUserConfirm'
        data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-CommonDialog1-EditUserConfirm'
        content='Are you sure you want to proceed ?'
        handleClose={confirmClose}
        handleConfirm={isCreate ? handleNewUser : handleUpdateUser}
        alertOpen={confirmOpen}
      />
      <CommonDialog
        id='UpdateUserConfirm'
        data-seleunim-id='SystemAdmin_UserManagement-EditUser-Fragment-CommonDialog2-UpdateUserConfirm'
        title={ifAStatus + LastName + ', ' + FirstName}
        content={
          'Are you sure you want to ' +
          ifAStatus +
          LastName +
          ', ' +
          FirstName +
          ' ?'
        }
        handleClose={handleActiveCLose}
        handleConfirm={handleStatusChange}
        alertOpen={InactiveOpen}
      />
    </React.Fragment>
  );
}

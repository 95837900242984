import { BarAndPieChartOptions } from '@/pages/Report/chartUtils';
import { BasicPieSeries } from '@/pages/Report/chartUtils';
import { OALabelFormatter } from './labelStyle';
import { LABEL_FONT_STYLE, parseOALabel } from './labelStyle';
import { JournalModelPieTooltipFormatter } from '../../constants';
/**
 * overwrite the default configuration
 */

const BARCHART_TOP = 40;
const GAP_BETWEEN_BAR_AND_PIE = 24;
const PIE_WIDTH = 224;
const LABLE_SIZE = [15, 11, 7];
const MAX_LABEL_SIZE = 18;
const CURVED_RADIUS = [0, 4, 4, 0];
const OA_PIE_CHART_LABEL_LAYOUT_POSITION = {
  'Basic Management': {
    x: 116,
    y: 75,
  },
  'On-Hold': {
    x: 78 + 107,
    y: 108,
  },
  'SI Commissioning': {
    x: 102 + 154,
    y: 355,
  },
};

const laybelLayout = (params, titleHeight) => {
  const title = parseOALabel(params.text);
  let { x, y } = OA_PIE_CHART_LABEL_LAYOUT_POSITION[title];
  return {
    x: x,
    y: y + titleHeight,
    verticalAlign: 'middle',
    draggable: true,
  };
};

// chart config
const BAR_COLOR = [
  '#4C81EB',
  '#45BCCA',
  '#9098E2',
  '#CEDDF9',
  '#E7F6F8',
  '#E8E9F9',
];
const BAR_BORDER_COLOR = [
  '#4C81EB',
  '#45BCCA',
  '#9098E2',
  '#4C81EB',
  '#45BCCA',
  '#9098E2',
];
const BAR_LABEL_COLOR = [
  '#FFFFFF',
  '#FFFFFF',
  '#FFFFFF',
  '#596A7C',
  '#596A7C',
  '#596A7C',
];

/**
 * pie charts
 */
const OA_PIECHART_RADIUS = 48;
const OO_PIECHART_RADIUS = [63, 100];

/**
 * legend
 */
const LEGEND_POS = [200, 400, 520];

/**
 * refactor: pass 'Basic Management' as parameters
 */
const LEGEND_LABEL_STYLE = {
  icon: 'none',
  selectedMode: false,
  itemGap: 20,
  width: '50px',
  textStyle: {
    fontFamily: 'Open Sans',
    fontSize: '10px',
    fontWeight: '700',
    color: '#596A7C',
  },
  formatter: label => {
    // use the last two legends to show the labels, and hide or others
    // temp solution to get last two legends
    const allIndex = label.indexOf('Basic Management');
    const oaIndex = label.indexOf('Basic Management OA');
    // console.log(oaIndex);
    if (oaIndex >= 0) {
      return 'OA';
    } else if (allIndex >= 0) {
      return 'All';
    }
  },
};

export class NormalChartOptions extends BarAndPieChartOptions {
  constructor(isDownload, legendText) {
    super();
    // overwrite the default properties
    this.barchartTop = BARCHART_TOP;
    this.barchartLeft = GAP_BETWEEN_BAR_AND_PIE + PIE_WIDTH;
    this.isDownload = isDownload;
    this.pieRadius = OO_PIECHART_RADIUS[1];
    this.ooPieRadius = OO_PIECHART_RADIUS;
    this.pieColors = [...BAR_COLOR];
    this.legend_pos = LEGEND_POS;
    this.legendText = legendText;
  }

  getBarColor(index) {
    return BAR_COLOR[index];
  }

  getBarLabelColor(index) {
    return BAR_LABEL_COLOR[index];
  }

  getBarBorderColor(index) {
    return BAR_BORDER_COLOR[index];
  }

  getBarSerie({ index, stack, isOnTop }) {
    const barColor = this.getBarColor(index);
    const labelColor = this.getBarLabelColor(index);
    const borderColor = this.getBarBorderColor(index);

    return {
      type: 'bar',
      emphasis: {
        disabled: true,
      },
      itemStyle: {
        borderColor: borderColor,
        borderWidth: 1,
        borderRadius: isOnTop && CURVED_RADIUS,
      },
      label: {
        show: true,
        color: labelColor,
        verticalAlign: 'middle',
        fontSize: this.getLabelMinFontSize(),
        fontWeight: 500,
        formatter: params => {
          // console.log('===params===', params);
          if (params.data[index + 1] === 0) {
            return '';
          }
          return params.data[index + 1];
        },
      },
      color: barColor,
      barMinHeight: 3,
      barMinWidth: 8,
      barMaxWidth: 150,
      stack,
      // barGap: '-12%',
      // z: 3, // cover first 2 bars
    };
  }

  /**
   * overwrite the default getBarSeries method
   */
  getBarSeries() {
    return [
      // OA
      this.getBarSerie({
        index: 0,
        stack: 'OA',
        isOnTop: false,
      }),
      this.getBarSerie({
        index: 1,
        stack: 'OA',
        isOnTop: false,
      }),
      this.getBarSerie({
        index: 2,
        stack: 'OA',
        isOnTop: true,
      }),
      // All
      this.getBarSerie({
        index: 3,
        stack: 'All',
        isOnTop: false,
      }),
      this.getBarSerie({
        index: 4,
        stack: 'All',
        isOnTop: false,
      }),
      this.getBarSerie({
        index: 5,
        stack: 'All',
        isOnTop: true,
      }),
    ];
  }

  /**
   * Get display options for pies
   * @returns
   */
  getPieSeries() {
    const center = this.getPieCenter();

    const ooPieSeries = new BasicPieSeries(
      OO_PIECHART_RADIUS,
      center,
      1,
      false,
      true
    )
      .addLabelAttr({
        color: 'white',
        fontSize: 14,
        position: 'inner',
      })
      .setItemStyle({
        borderType: 'solid',
        borderColor: '#FFF',
        borderWidth: 4,
      })
      .setZIndex(2)
      .addTooltipAttr({
        padding: [0, 10, 3, 10],
        formatter: JournalModelPieTooltipFormatter,
      });

    const oaPieSeries = new BasicPieSeries(
      OA_PIECHART_RADIUS,
      center,
      2,
      true,
      false,
      'none',
      2
    )
      .addLabelAttr({
        overflow: 'none',
        backgroundColor: 'inherit',
        padding: 4,
        borderRadius: 4,
        formatter: params => OALabelFormatter(params),
        rich: LABEL_FONT_STYLE,
      })
      .setLabelLine({
        show: true,
        length: 0, // set to zero to prevent the innormal label width,this length will auto calc after label layout
        lineStyle:{
          color: '#98A7B6',
        }
      })
      .setZIndex(3);
    oaPieSeries.setLabelLayout(params =>
      laybelLayout(params, this.hasTitle ? this.titleHeight : 0)
    );

    return [ooPieSeries.getSeries(), oaPieSeries.getSeries()];
  }

  getTooltip() {
    const tooltip = super.getTooltip();
    // overwrite formatter
    const formatter = params => {
      // console.log("overwrite tooltip")
      // console.log('params=', params);
      // console.log('componentSubType', params.componentSubType);

      const { componentSubType } = params;

      let dataStr;

      if (componentSubType === 'pie') {
        // for pie chart tooltip
        let title = params?.name?.indexOf('OA') >= 0 ? 'OA' : 'All';
        title += ' ' + 'Journals';
        let subTitle = params.name;
        const oaIndex = subTitle.indexOf('OA');
        subTitle = oaIndex >= 0 ? subTitle.slice(0, oaIndex - 1) : subTitle;

        // console.log('==title==', title);
        // console.log('==subTitle==', subTitle);

        dataStr = `<div style="padding: 0; margin: 0;">
        <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 0 0 2.5px 0;">${title}</p>
        <p style="font-size: 10px; font-weight: 400;color: #848484; line-height: 14px; margin: 0;">${subTitle}&nbsp;:&nbsp;${params.data[1]}</p>
      </div>`;
      } else {
        // for bar chart tooltip
        dataStr = `<div style="padding: 0; margin: 0;">
        <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 0 0 2.5px 0;">${
          params.name
        }</p>
        <p style="font-size: 10px; font-weight: 400;color: #848484; line-height: 14px; margin: 0;">${
          params.seriesName
        }&nbsp;:&nbsp;${params.data[params.seriesIndex + 1]}</p>
      </div>`;
      }

      return dataStr;
    };

    return { ...tooltip, formatter };
  }

  getSelectedLegend() {
    // remove 'OA' from label
    const labelFormatter = label => {
      const oaIndex = label.indexOf('OA');
      if (oaIndex >= 0) {
        return label.slice(0, oaIndex);
      } else {
        return label;
      }
    };

    const baseLegend = super.getSelectedLegend();

    const legendLabels = {
      ...LEGEND_LABEL_STYLE,
      bottom: baseLegend.bottom,
      orient: 'vertical',
      left: this.legend_pos,
      data: [this.legendText[1], this.legendText[4]],
    };

    const legendCol1 = {
      ...baseLegend,
      orient: 'vertical',
      left: this.legend_pos + 60,
      data: [this.legendText[1], this.legendText[4]],
      formatter: labelFormatter,
    };

    const legendCol2 = {
      ...baseLegend,
      orient: 'vertical',
      left: this.legend_pos + 204,
      data: [this.legendText[2], this.legendText[5]],
      formatter: labelFormatter,
    };

    const legendCol3 = {
      ...baseLegend,
      orient: 'vertical',
      left: this.legend_pos + 341,
      data: [this.legendText[3], this.legendText[6]],
      formatter: labelFormatter,
    };

    const legends = [legendLabels, legendCol1, legendCol2, legendCol3];

    return legends;
  }

  getLabelMinFontSize() {
    // console.log('===this.dataForBar===', this.dataForBar);
    const numberOfBars = this.dataForBar && this.dataForBar.length;

    let fontSize;

    if (numberOfBars < 7) {
      fontSize = LABLE_SIZE[0];
    } else if (numberOfBars < 12) {
      fontSize = LABLE_SIZE[1];
    } else {
      fontSize = LABLE_SIZE[2];
    }

    // console.log('=== fontSize===', fontSize);
    return fontSize;
  }

  getLabelFontSize() {
    return [this.getLabelMinFontSize(), MAX_LABEL_SIZE];
  }

  setWidth(width) {
    this.width = width;

    if (width !== '100%') {
      const half_width = width / 2;
      this.legend_pos = half_width - 225;
    }

    return this;
  }

  /**
   * if on webpage y-axis label should be truncate, and maximum width is 44
   * if for downloading, y-axis label should be shown in full length
   */
  getYAxis() {
    let baseYAxis = super.getYAxis();

    // console.log('===this.isDownload===', this.isDownload);

    if (this.isDownload) {
      baseYAxis = {
        ...baseYAxis,
        axisLabel: {
          ...baseYAxis.axisLabel,
          width: undefined,
          overflow: 'break',
        },
      };
    } else {
      baseYAxis = {
        ...baseYAxis,
        axisLabel: {
          ...baseYAxis.axisLabel,
          width: 48,
          overflow: 'truncate',
        },
      };
    }

    return baseYAxis;
  }
}

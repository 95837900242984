import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Stack, Typography } from '@mui/material';
import { hiddenFilenameTransform, getFileType } from './utils';
import { useEffect, useRef, useState } from 'react';

function LinearProgressWithLabel(props) {
  const { value } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: "4px" }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          sx={{
            borderRadius: "22px",
            bgcolor: "#FFFFFF",
            '& .MuiLinearProgress-bar': {
              borderRadius: "22px",
              bgcolor: "#4C81EB"
            }
          }}
          id="ProgressFileLinear"
          variant="determinate"
          value={value}
          {...props}

        />
      </Box>
      <Box>
        <Typography sx={{
          color: '#596A7C',
          fontSize: '12px',
          fontFamily: 'Open Sans'
        }}>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const DefaultCancelButton = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.47157 1.47157C9.73192 1.21122 9.73192 0.789108 9.47157 0.528758C9.21122 0.268409 8.78911 0.268409 8.52876 0.528758L5.00016 4.05735L1.47157 0.528758C1.21122 0.268409 0.789108 0.268409 0.528758 0.528758C0.268409 0.789108 0.268409 1.21122 0.528758 1.47157L4.05735 5.00016L0.528758 8.52876C0.268409 8.78911 0.268409 9.21122 0.528758 9.47157C0.789108 9.73192 1.21122 9.73192 1.47157 9.47157L5.00016 5.94297L8.52876 9.47157C8.78911 9.73192 9.21122 9.73192 9.47157 9.47157C9.73192 9.21122 9.73192 8.78911 9.47157 8.52876L5.94297 5.00016L9.47157 1.47157Z" fill="#596A7C" />
    </svg>
  );

};

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};


export function ProgressFile(props) {
  const { FileIcon, value, abort, fileName, fileSize, fileType } = props;

  const sizeEl = useRef();
  const typeEl = useRef();
  const [maxWidth, setMaxWidth] = useState("249px");

  useEffect(() => {
    setMaxWidth((321 - sizeEl.current.offsetWidth - typeEl.current.offsetWidth) + "px");
  }, []);

  return (
    <Box
      sx={{
        height: '68px',
        pl: '6px',
        border: '2px solid #0052CC',
        borderRadius: '4px',
        pr: "6px",
        bgcolor: "#FFFFFF"
      }}
    >
      <Box
        sx={{
          mt: "7.5px",
          height: "50px",
          bgcolor: "#F1F3F5",
          borderRadius: '4px',
          p: "6px",
        }}
      >
        <Box sx={{
          pt: '12.5px',
          float: "left"
        }}>
          <FileIcon />
        </Box>

        <Stack
          spacing={0}
          sx={{
            p: "0px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction="row"
              spacing={0.75}
            >
              <Stack
                direction="row"
                spacing={0}
                sx={{height: "18px"}}
              >
                <Typography
                  sx={{
                    color: '#262E35',
                    fontSize: '14px',
                    fontFamily: 'Open Sans',
                    lineHeight: '18px',
                    maxWidth: maxWidth,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {hiddenFilenameTransform(fileName)}
                </Typography>
                <Typography
                  sx={{
                    color: '#262E35',
                    fontSize: '14px',
                    fontFamily: 'Open Sans',
                    lineHeight: '18px',
                  }}
                  ref={typeEl}
                >
                  {fileType === "" ? " " : ` .${fileType}`}
                </Typography>
              </Stack>
              <Box
                sx={{
                  color: '#596A7C',
                  fontSize: '12px',
                  fontFamily: 'Open Sans',
                  height: "18px",
                  whiteSpace: "nowrap"
                }}
                ref={sizeEl}
              >
                {`(${fileSize.size} ${fileSize.unit})`}
              </Box>

            </Stack>
            <Box
              sx={{
                cursor: "pointer",
                ml: "4px"
              }}
              onClick={() => {
                abort();
              }}
            >
              <DefaultCancelButton />
            </Box>
          </Stack>
          <LinearProgressWithLabel value={value} />
        </Stack>
      </Box>
    </Box>
  );
}

export function GetProgress(props) {
  const { FileIcon, value, abort, fileName, fileSize, fileType,titleMaxWidth=218,sx } = props;

  const sizeEl = useRef();
  const typeEl = useRef();
  const [maxWidth, setMaxWidth] = useState("180px");

  useEffect(() => {
    setMaxWidth((titleMaxWidth - sizeEl.current.offsetWidth - typeEl.current.offsetWidth) + "px");
  }, []);
  return (
    <Box
      sx={{
        width:'286px',
        mt: "7.5px",
        height: "50px",
        bgcolor: "#F1F3F5",
        borderRadius: '4px',
        p: "6px",
        marginLeft:'24px',
        '&:hover': {
          bgcolor: '#CEDDF9',
        },
        ...sx
      }}
    >
      <Box sx={{
        pt: '12.5px',
        float: "left"
      }}>
        <FileIcon />
      </Box>

      <Stack
        spacing={1}
        sx={{
          pl: "6px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            spacing={0.75}
          >
            <Stack
              direction="row"
              spacing={0}
              sx={{height: "18px"}}
            >
              <Typography
                sx={{
                  color: '#262E35',
                  fontSize: '14px',
                  fontFamily: 'Open Sans',
                  lineHeight: '18px',
                  maxWidth: maxWidth,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {fileName}
              </Typography>
              <Typography
                sx={{
                  color: '#262E35',
                  fontSize: '14px',
                  fontFamily: 'Open Sans',
                  lineHeight: '18px',
                }}
                ref={typeEl}
              >
                {/* {'.'+fileType} */}
              </Typography>
            </Stack>
            <Box
              sx={{
                color: '#596A7C',
                fontSize: '12px',
                fontFamily: 'Open Sans',
                height: "18px",
                whiteSpace: "nowrap",
                fontWeight:400,
              }}
              ref={sizeEl}
            >
              {'('+fileSize+')'}
            </Box>

          </Stack>
          <Box
            sx={{
              cursor: "pointer",
              ml: "4px"
            }}
            onClick={() => {
              abort();
            }}
          >
            <DefaultCancelButton />
          </Box>
        </Stack>
        <LinearProgressWithLabel value={value} />
      </Stack>
    </Box>
  );
}

import styled from '@emotion/styled';
import { MiniFont } from '../../../components/FontStyle';
import {
  Autocomplete,
  Box,
  IconButton,
  Checkbox,
  TextField,
  Tooltip,
  Chip,
} from '@mui/material';
import SIDrawerAutoCompleteTagDelete from '../../../assets/SIDrawerAutoCompleteTagDeletePhase2.svg';
import SIDrawerAutoCompletePopupIcon from '../../../assets/SIDrawerAutoCompletePopupIconPhase2.svg';
import SIDrawerAutoCompleteClearIcon from '../../../assets/SIDrawerAutoCompleteClearIconPhase2.svg';
import SIDrawerAutoCompleteMagnifyingGlassIcon from '../../../assets/SIDrawerAutoCompleteMagnifyingGlassPhase2.svg';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useEffect, useState } from 'react';
import React from 'react';

export const StyledSIDrawerAutoComplete = styled(Autocomplete)({
  '& .MuiOutlinedInput-root': {
    width: '100%',
    minHeight: '40px',
    background: '#FFFFFF',
    fontSize: '14px',
    borderRadius: 4,
    boxSizing: 'border-box',
    padding: '6px 6px 6px 6px',
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '0px 0px',
    minWidth: '0px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #DFE4E8',
  },
  '& .MuiAutocomplete-tag': {
    margin: '2px 1px 2px 1px',
    maxWidth: 'calc(100% - 12px)',
  },
  '& .MuiAutocomplete-endAdornment': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    top: '0',
    right: '8px',
  },
  '& .MuiAutocomplete-popupIndicator': {
    order: 2, // move popupIcon to the end
  },
  '& .MuiAutocomplete-clearIndicator': {
    order: 3, // move clearIcon to the end + 1
  },
});

export const StyledChip = styled(Chip)({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '5px 8px 5px 12px',
  gap: '6px',
  maxWidth: '387px',

  backgroundColor: '#FFFFFF',
  borderRadius: '4px',

  height: '22px',
  border: '1px solid #98A7B6',
  '&:hover': {
    backgroundColor: '#DFE4E8',
    border: '1px solid #929DAE',
    '& .MuiChip-label': {
      fontWeight: 600,
    },
  },
  '& .MuiChip-label': {
    padding: '0px',
    lineHeight: '18px',
    color: '#596A7C',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0px',
    textAlign: 'left',
  },
  '& .MuiChip-deleteIcon': {
    margin: '0px 0px 0px 0px',
    color: '#0F172A',
    '&:hover': {
      background: '#BCC5CF',
      borderRadius: '2px',
    },
  },
});

export const StyledChip2 = styled(Chip)({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '2px 8px 2px 12px',
  gap: '6px',
  maxWidth: '100%',

  backgroundColor: '#FFFFFF',
  borderRadius: '4px',

  height: '28px',
  border: '1px solid #BCC5CF',
  '&:hover': {
    backgroundColor: '#DFE4E8',
    border: '1px solid #929DAE',
    '& .MuiChip-label': {
      fontWeight: 600,
    },
  },
  '& .MuiChip-label': {
    padding: '0px',
    lineHeight: '18px',
    color: '#596A7C',
  },
  '& .MuiChip-deleteIcon': {
    margin: '0px 0px 0px 0px',
    color: '#0F172A',
    '&:hover': {
      background: '#BCC5CF',
      borderRadius: '2px',
    },
  },
});

export const SIDrawerAutocomplete = props => {
  const { id, getOptionName, tagType = 'small', ...other } = props;

  const ChipComponent = tagType === 'small' ? StyledChip : StyledChip2;

  return (
    <StyledSIDrawerAutoComplete
      id={'autocomplete-' + id}
      multiple
      disablePortal
      limitTags={-1}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Tooltip
            title={getOptionName(option)}
            key={id + '-tooltip-' + index}
            disableInteractive
            followCursor={true}
          >
            <ChipComponent
              key={id + '-' + index}
              label={getOptionName(option)}
              deleteIcon={
                <IconButton
                  sx={{ height: '12px', width: '12px', padding: '0px' }}
                >
                  <SIDrawerAutoCompleteTagDelete />
                </IconButton>
              }
              {...getTagProps({ index })}
            />
          </Tooltip>
        ))
      }
      PopperProps={{
        style: {
          maxHeight: '200px',
        },
      }}
      clearIcon={
        <IconButton sx={{ width: '14px', height: '14px', padding: '0px' }}>
          <SIDrawerAutoCompleteClearIcon />
        </IconButton>
      }
      popupIcon={
        <IconButton
          sx={{
            width: '13.71px',
            height: '13.71px',
            padding: '0px',
            marginRight: '5.5px',
          }}
        >
          <SIDrawerAutoCompletePopupIcon />
        </IconButton>
      }
      {...other}
    />
  );
};

export default function SelectBox(props) {
  const {
    data,
    labelName, //used to generate Id
    placeholder,
    value: selectedList,
    setSelected,
    onLoad,
    useKey,
    loadMore,
    getId,
    getValue,
    type = 0,
    ...other
  } = props;
  const [open, setOpen] = useState(false);
  const identifier = labelName.replace(' ', '-');
  const [inputText, setInputText] = useState('');
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(null);
  const [page, setPage] = useState(1);
  const [loadTrigger, setLoadTrigger] = useState(0);
  const [lastSize, setLastSize] = useState(0);

  const hasMore = data !== undefined && lastSize !== data.length;
  const pageSize = 20;
  useEffect(() => {
    if (loadMore !== undefined) {
      setLoading(true);
      clearTimeout(timer);
      setTimer(
        setTimeout(async () => {
          setPage(1);
          setLoadTrigger(loadTrigger + 1);
        }, 300)
      );
    }
  }, [inputText]);

  useEffect(() => {
    if (loadTrigger === 0 || !open) {
      return;
    }
    setLastSize(data.length);
    async function loadMoreOptions() {
      await loadMore(inputText, page, pageSize);
    }

    loadMoreOptions().then(_r => {
      setLoading(false);
    });
  }, [open, page, loadTrigger]);

  const handleInputTextChange = e => {
    setInputText(e.target.value);
  };

  const getOptionName = option => {
    return getValue ? getValue(option) : option;
  };
  const getOptionId = option => {
    return getId ? getId(option) : getOptionName(option);
  };

  return (
    <Box
      data-selenium-id={`Report_Overview-newFilterDrawer-SelectBox${identifier}`}
    >
      <SIDrawerAutocomplete
        disableCloseOnSelect
        forcePopupIcon={type == 2 ? true : false}
        disableListWrap
        type={type}
        value={selectedList}
        filterOptions={x => x}
        ListboxProps={{
          style: { maxHeight: 'calc(6*36px)' },
          onScroll: event => {
            const listboxNode = event.currentTarget;
            if (
              listboxNode.scrollTop + listboxNode.clientHeight >=
              listboxNode.scrollHeight
            ) {
              // Load More when scroll to bottom
              if (hasMore && loadMore !== undefined) {
                // Only load more when there are more options
                // if can't find the method not to come back to top, uncomment the following line, according to Mike's suggestion, all load more operations have been cancelled
                // setPage(page + 1);   // Modify page to trigger loadMore
              }
            }
          },
        }}
        id={identifier}
        options={data ? data : []}
        openText='Search'
        loading={loading}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          setInputText('');
        }}
        getOptionLabel={option => getOptionName(option)}
        onChange={(_event, value) => {
          setSelected(value);
        }}
        isOptionEqualToValue={(option, value) =>
          getOptionId(option) === getOptionId(value)
        }
        renderOption={(optionProps, option, { selected }) => (
          <li
            {...optionProps}
            style={{
              height: '36px',
              lineHeight: '16px',
              fontSize: '14px',
              backgroundColor: '#ffffff',
            }}
          >
            <Checkbox
              key={identifier + '-' + getOptionId(option)}
              icon={
                <CheckBoxOutlineBlankIcon
                  fontSize='small'
                  sx={{ width: '14px', height: '14px' }}
                />
              }
              checkedIcon={
                <CheckBoxIcon
                  fontSize='small'
                  sx={{ width: '14px', height: '14px' }}
                />
              }
              style={{ padding: 0, marginRight: 10 }}
              checked={selected}
            />
            <Tooltip
              title={getOptionName(option)}
              disableInteractive
              leaveTouchDelay={0}
              followCursor={true}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {getOptionName(option)}
              </span>
            </Tooltip>
          </li>
        )}
        renderInput={params => {
          return (
            <TextField
              {...params}
              onChange={e => {
                handleInputTextChange(e);
              }}
              placeholder={selectedList?.length ? null : '  ' + placeholder}
              variant='outlined'
              InputProps={
                selectedList?.length || type != 1
                  ? { ...params.InputProps }
                  : {
                      ...params.InputProps,
                      startAdornment: (
                        <SIDrawerAutoCompleteMagnifyingGlassIcon />
                      ),
                    }
              }
              data-selenium-id={`Report_Overview-newFilterDrawer-SelectBox${identifier}-Input`}
            ></TextField>
          );
        }}
        getOptionName={getOptionName}
        {...other}
      />
    </Box>
  );
}

// Self Implement SIDrawerAutocomplete EndAdornment

// endAdornment: (
//   <div
//     style={{
//       display: 'flex',
//       flexDirection: 'column',
//       justifyContent: 'center',
//     }}
//     class='MuiAutocomplete-endAdornment css-1q60rmi-MuiAutocomplete-endAdornment'
//   >
//     <IconButton
//       sx={{ width: '16px', height: '16px', padding: '0px',transform: open ? 'rotate(180deg)' : 'none' }}
//       onClick={()=>{setOpen(!open);}}
//     >
//       <SIDrawerAutocompletePupIconTypeThird />
//     </IconButton>
//     <IconButton
//       sx={{ width: '16px', height: '16px', padding: '0px' }}
//       onClick={()=>{setSelected([]);}}
//     >
//       <SIDrawerAutocompleteClearIconTypeFourth />
//     </IconButton>
//   </div>
// ),

const defaultCheckIcon = () => {
  return (
    <>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.0567 5.72363L8 8.7803L4.94333 5.72363L4 6.66697L8 10.667L12 6.66697L11.0567 5.72363Z" fill="#505050"/>
      </svg>

    </>
  );
};

const defaultSearchIcon = () => {
  return (
    <>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.3335 2.66683C4.75617 2.66683 2.66683 4.75617 2.66683 7.3335C2.66683 9.91083 4.75617 12.0002 7.3335 12.0002C9.91083 12.0002 12.0002 9.91083 12.0002 7.3335C12.0002 4.75617 9.91083 2.66683 7.3335 2.66683ZM1.3335 7.3335C1.3335 4.01979 4.01979 1.3335 7.3335 1.3335C10.6472 1.3335 13.3335 4.01979 13.3335 7.3335C13.3335 10.6472 10.6472 13.3335 7.3335 13.3335C4.01979 13.3335 1.3335 10.6472 1.3335 7.3335Z" fill="#596A7C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6284 10.6284C10.8887 10.368 11.3108 10.368 11.5712 10.6284L14.4712 13.5284C14.7315 13.7887 14.7315 14.2108 14.4712 14.4712C14.2108 14.7315 13.7887 14.7315 13.5284 14.4712L10.6284 11.5712C10.368 11.3108 10.368 10.8887 10.6284 10.6284Z" fill="#596A7C"/>
      </svg>

    </>
  );
};

const defaultCloseIcon = () => {
  return (
    <>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.913 3.08785C11.1409 3.31566 11.1409 3.68502 10.913 3.91283L3.91283 10.913C3.68502 11.1409 3.31566 11.1409 3.08785 10.913C2.86004 10.6852 2.86004 10.3159 3.08785 10.0881L10.0881 3.08785C10.3159 2.86004 10.6852 2.86004 10.913 3.08785Z" fill="#596A7C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.08785 3.08785C3.31566 2.86004 3.68502 2.86004 3.91283 3.08785L10.913 10.0881C11.1409 10.3159 11.1409 10.6852 10.913 10.913C10.6852 11.1409 10.3159 11.1409 10.0881 10.913L3.08785 3.91283C2.86004 3.68502 2.86004 3.31566 3.08785 3.08785Z" fill="#596A7C"/>
      </svg>

    </>
  );
};
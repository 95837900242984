// import Editor, { defaultConfig } from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  emailVariables,
  forEmail,
  prepareBulkTransferData,
  replaceVariablesSymbolsForBackend,
} from './EditorUtils';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ReactDOMServer from 'react-dom/server';
import EmailQuestionMark from '../../assets/EmailQuestionMark.svg';
import { useCustomEditor } from '../../componentsphase2/RichText/EditorContext';

/*
  Known limitations: cannot customize id of popup
 */
export default function RichTextEditor(props) {
  // default variables will be set to common
  const {
    content, // 默认的输入内容，需要是一段html代码
    setContent, // 设置content的函数
    variables = emailVariables.common, // 变量（输入#之后的提示框）
    extraPlugins = [], // 额外的plugin，一般用不到
    removePlugins = [], // 如果不需要placeholder插件，则需要在这里传入相应的对象
    extraToolbarItems = [], // 需要额外加入到工具栏的东西，默认为空
    printOnBlur = false, // 当blur的时候，是否打印输入框的导出html代码
    // 需要从工具栏移除的工具，默认为空。如果不需要placeholder插件，则需要在这里传入['placeholder']
    // 如果这里面传入的东西也在extra里面，则不会被移除
    removeToolbarItems = ['placeholder', 'imageUpload', 'attachmentsUpload'],
    extraConfigs = {}, // 如果使用了额外的插件plugin，那么很可能会需要填入相关的config
    disableEnterKey = false,
    onReady = () => {
      /* default empty */
    },
    ...other // 添加给CKEditor外部的Box
  } = props;

  // extraConfigs 需要指定extraPlugins中需要的config
  // 例如：extraConfigs={
  //   placeholderConfig: {
  //        types: variables,
  //      },
  //    }

  // 使用初始传入的content做一份拷贝，然后对于编辑器而言，只需要维护`useContent`
  // 如果不做拷贝，可能产生以下问题：
  //    外部传入content={fun(xxx)}的时候，如果是通过函数的，可能使content不断被改变，导致编辑不正常
  // 此外，当`useContent`变化的时候，再通过`setContent`更新`content`
  // 注：编辑器不需要关心`content`，只需要管理`useContent`
  const [useContent, setUseContent] = useState(content);
  const [isInit, setIsInit] = useState(false);

  const Editor = useCustomEditor();

  useEffect(() => {
    let tmpContent = useContent.replace(/<\/span>\s,/g, '</span>,');
    tmpContent = tmpContent.replace(/<\/span>\s\./g, '</span>.');
    tmpContent = tmpContent.replace(
      new RegExp('</span> &nbsp;', 'g'),
      '</span> '
    );
    tmpContent = tmpContent.replace(/<\/span>\s"/g, '</span>"');
    tmpContent = tmpContent.replace(/<\/span>\s:/g, '</span>:');
    setContent(tmpContent);
  }, [useContent]);

  useEffect(() => {
    if (!isInit && content) {
      setUseContent(content);
      setIsInit(true);
    }
  }, [content]);

  // 从default工具栏里移除掉remove部分
  const useToolbarItems = (Editor) => {
    if (!Editor) return [];
    return Editor.defaultConfig.toolbar.items.filter(item => {
      return removeToolbarItems.indexOf(item) === -1;
    });
  };

  // CKEditor的配置属性
  const editorConfiguration = (Editor) => ({
    fullPage: false,

    toolbar: {
      items: [...useToolbarItems(Editor), ...extraToolbarItems],
      shouldNotGroupWhenFull: true,
    },
    placeholderConfig: {
      types: variables,
    },
    extraPlugins: extraPlugins,
    removePlugins: removePlugins,
    mention: {
      dropdownLimit: 100,
      feeds: [
        {
          marker: '#',
          feed: variables,
          itemRenderer: customItemRenderer, // 有可能要用到自定义的renderer（输入#之后的弹出框样式）
        },
      ],
    },
    table: {
      defaultHeadings: { rows: 1, columns: 0 },
    },
    link: {
      // 给link默认增加协议，否则无法正常跳转
      defaultProtocol: 'http://',
      decorators: {
        addTargetToExternalLinks: {
          mode: 'automatic',
          callback: url => /^(https?:)?\/\//.test(url),
          attributes: {
            target: '_blank',
            rel: 'noopener noreferrer',
          },
        },
      },
    },
    ...extraConfigs,
  });

  function customItemRenderer(item) {
    const outerButton = document.createElement('button');
    outerButton.classList.add(['ck', 'ck-button', 'ck-button_with-text']);

    const itemElement = document.createElement('span');
    itemElement.classList.add('ck');
    itemElement.classList.add('ck-button__label');
    itemElement.setAttribute('style', 'width: 100%;');
    itemElement.id = `mention-list-item-id-${item.id
      ?.replace('#', '')
      .replace(/\s/g, '')}`;
    itemElement.textContent = `${item.text} `;

    // if (item.id === '#AME first name') {
    //   const div = document.createElement('div');
    //   div.innerHTML = ReactDOMServer.renderToString(
    //     <span
    //       id='AMETip'
    //       title='If there is no AME, "EEO Support will be displayed here.'
    //       style={{
    //         ml: '20px !important',
    //       }}
    //     >
    //       <EmailQuestionMark />
    //     </span>
    //   );
    //   itemElement.appendChild(div.firstChild);
    // }
    outerButton.appendChild(itemElement);
    return outerButton;
  }

  if (!Editor) {
    return <div>Loading Editor...</div>;
  }

  return (
    <Box
      id='richTextEditorOuterBox'
      display='flex'
      maxWidth='100%'
      maxHeight='600px'
      {...other}
      sx={{
        '& div.ck-editor': {
          width: '100%',
        }
      }}
    >
      <CKEditor
        id='default-editor'
        editor={Editor}
        config={editorConfiguration(Editor)}
        data={useContent}
        onReady={editor => {
          // You can store the "editor" and use when it is needed.
          if (disableEnterKey) {
            editor.editing.view.document.on('enter', function (event) {
              event.stop();
            });
          }
          onReady();
          // setEditor(editor); // 保存editor的方式。以备未来使用
        }}
        onChange={(_event, editor) => {
          const data = editor.getData();
          setUseContent(data); // will use later
        }}
        onBlur={(_event, editor) => {
        }}
        onFocus={(_event, editor) => {
          // console.log("Focus.", editor);
        }}
      />
    </Box>
  );
}

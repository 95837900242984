import React from 'react';
import SimpleIconButton from './SimpleIconButton';
import EditIconActive from './icon/EditIconActive.svg';
import EditIconDefault from './icon/EditIconDefault.svg';
import EditIconDisable from './icon/EditIconDisable.svg';
export default function EditIconButton(props) {
  return (
    <SimpleIconButton
      DefaultIcon={EditIconDefault}
      HoverIcon={EditIconActive}
      ActiveIcon={EditIconActive}
      DisabledIcon={EditIconDisable}
      {...props}
    ></SimpleIconButton>
  );
}

import { useEffect, useRef, useState } from 'react';
import SIPViewAllArrow from '@/assets/SIPViewAllArrow.svg';
import '@/pages/SIPPage/SIPDetailPage/Submodule/HideText.css';
import PropTypes from 'prop-types';
import TextViewAllButton from '@/componentsphase2/Button/TextViewAllButton';

function TextOverflow(props) {
  const { lineNum, textStyle, viewAllStyle, id, children } = props;

  const [rowLimit, setRowLimit] = useState(lineNum);
  const [expanding, setExpanding] = useState(false);
  const [needHide, setNeedHide] = useState(false);
  const maxHeight = lineNum * 20;
  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current.scrollHeight > maxHeight) {
      setNeedHide(true);
    } else {
      setNeedHide(false);
    }
    window.addEventListener('resize', resizeUpdate);
    return () => {
      window.removeEventListener('resize', resizeUpdate);
    };
  }, []);

  const resizeUpdate = e => {
    if (elementRef.current.scrollHeight > maxHeight) {
      setNeedHide(true);
    } else {
      setNeedHide(false);
    }
  };

  const useTextStyle = textStyle ?? {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    flex: 'none',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: rowLimit,
    WebkitBoxOrient: 'vertical',
    width: '385px'
  };

  const useViewAllStyle = viewAllStyle ?? {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    height: '20px',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.01em',
    color: '#154AB6',
    flex: 'none',
    cursor: 'pointer',
    width: 'max-content',
  };

  const handleViewAllClick = () => {
    setExpanding(true);
    setRowLimit('inherit');
  };

  const handleHideClick = () => {
    setExpanding(false);
    setRowLimit(lineNum);
  };

  return (
    <div
      id={id + 'viewAllBody'}
      style={{
        position: 'relative',
      }}
    >
      <div style={useTextStyle} ref={elementRef}>
        {children}
      </div>
      {needHide &&
        (expanding ? (
          <div style={{ marginTop: '8px', height: '20px' }}>
            <div style={useViewAllStyle} onClick={handleHideClick}>
              Hide
            </div>
          </div>
        ) : (
          // <div
          //   style={{ marginTop: '8px', height: '20px', alignContent: 'center' }}
          // >
          //   <div style={useViewAllStyle} onClick={handleViewAllClick}>
          //     <span>View All</span>{' '}
          //     <span id='viewAllArrow'>
          //       <SIPViewAllArrow />
          //     </span>
          //   </div>
          // </div>
          <TextViewAllButton
            onClick={handleViewAllClick}
            sx={{paddingLeft:'0'}}
          />
        ))}
    </div>
  );
}

TextOverflow.propTypes = {
  textStyle: PropTypes.object,
  viewAllStyle: PropTypes.object,
  lineNum: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default TextOverflow;

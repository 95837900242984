import * as React from 'react';
import Chip from '@mui/material/Chip';
import {
  Autocomplete,
  Box,
  styled,
  TextField,
  ThemeProvider,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { Bootstraptheme } from '../../../../components/CreateAndUpdate/MyTheme';
import DecisionChoiceDown from '../../../../assets/DecisionChoiceDown.svg';
import DeleteIcon from '../../../../assets/DeleteIconOfJournalMutiSelect.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { defaultVars } from './DecisionMyStyled';
import { SmallFont } from '@/components/FontStyle';
import { useUpdateEffect } from 'ahooks';
// import { MulSelectInput } from './Component/SelectInput';
import { SelectInput, MulSelectInput} from '../../../../componentsphase2/SelectBox/SelectInput';
import {getJournalListWithName} from "@/actions/SIMT-SI/SpecialIssue/Journal";

export const ErrVars = {
  '--color': 'rgb(238, 83, 80)',
  '--border': '2px solid rgb(238, 83, 80)',
  '--focus-border': '2px solid #154AB6',
  '--focus-color': 'rgb(238, 83, 80)',
  '--width': '100%',
};

export const MyChip = styled(Chip)(({ theme }) => ({
  '&': {
    background: '#BCC5CF',
  },
  '& .css-11iwrvz-MuiAutocomplete-root .MuiAutocomplete-tag': {
    borderRadius: '5px',
  },
  '& .css-1pjtbja': {
    color: '#FFFFFF',
  },
  '& .MuiAutocomplete-tag': {
    height: '22px',
    width: '118px',
    left: '12px',
    top: '6px',
  },
  '& .css-wjsjww-MuiChip-label': {
    color: '#FFFFFF',
    alignItems: 'center',
    letterSpacing: '-0.011em',
  },
}));

export const JournalMutiSelectAutoComplete = styled(Autocomplete)(
  ({ theme }) => ({
    '&': {
      display: 'block',
    },
    'label + &': {
      marginTop: theme.spacing(2),
    },
    '& .MuiOutlinedInput-root': {
      width: '286px',
      height: '40px',
      background: '#FFFFFF',
      borderRadius: 4,
      boxSizing: 'border-box',
      cursor: 'pointer',
      padding: '8px 32px 8px 12px !important',
    },
    '& .css-55ib48-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment':
    {
      right: '12px',
    },
    '& .css-v516nx-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon': {
      marginLeft: '6px',
    },
    '& .css-wjsjww-MuiChip-label': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '17px',
      display: 'block',
      maxWidth: '280px',
      color: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
      padding: '0px 0px',
      height: '17px',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      // lineHeight: '17px',
      color: '#262E35',
      '&::placeholder': {
        color: '#828282',
        opacity: '1',
        lineHeight: '17px',
      },
    },
    '& :hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'var(--border) !important',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'var(--border)',
    },
    '& :focus': {
      '& .MuiOutlinedInput-notchedOutline': {
        // border: '2px solid #154AB6',
        border: 'var(--focus-border)',
      },
    },
    '& .MuiAutocomplete-popupIndicator': {
      padding: '0',
      margin: '0',
      marginRight: '2px',
    },
    '& .css-1q60rmi-MuiAutocomplete-endAdornment': {
      top: 'calc(50% - 12px)',
    },
  })
);

// export const JournalMutiSelectAutoComplete = styled(Autocomplete)(
//   ({ theme }) => ({
//     'label + &': {
//       marginTop: theme.spacing(2),
//     },
//     '& .MuiOutlinedInput-root': {
//       width: '464px',
//       height: '100%',
//       background: '#FFFFFF',
//       cursor: 'pointer',
//     },
//     '& .css-55ib48-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment':
//       {
//         right: '12px',
//       },
//     '& .css-v516nx-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon': {
//       marginLeft: '6px',
//     },
//     '& .css-wjsjww-MuiChip-label': {
//       fontFamily: 'Open Sans',
//       fontStyle: 'normal',
//       fontWeight: '400',
//       fontSize: '14px',
//       lineHeight: '17px',
//       display: 'block',
//       maxWidth: '280px',
//       color: '#FFFFFF',
//     },
//     '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
//       padding: '0px 0px',
//       height: '100%',
//       fontFamily: 'Open Sans',
//       fontStyle: 'normal',
//       fontWeight: '400',
//       fontSize: '14px',
//       lineHeight: '17px',
//       '&::placeholder': {
//         color: '#828282',
//         opacity: '1',
//         lineHeight: '17px',
//       },
//     },
//     '& :hover': {
//       '& .MuiOutlinedInput-notchedOutline': {
//         border: 'var(--border) !important',
//       },
//     },
//     '& :focus': {
//       '& .MuiOutlinedInput-notchedOutline': {
//         border: 'var(--focus-border)',
//       },
//     },
//     '& .MuiAutocomplete-popupIndicator': {
//       padding: '0',
//       margin: '0',
//       marginRight: '2px',
//     },
//     '& .css-1q60rmi-MuiAutocomplete-endAdornment': {
//       top: 'calc(50% - 12px)',
//     },
//   })
// );

export default function JournalMutiSelect(props) {
  const { setJournalSelected, emptyError, value} = props;

  const [vars, setVars] = useState(defaultVars);
  const [opened,setOpened] = useState(false);

  const { JourList } = useSelector(state => {
    return {
      JourList: state.SIP.getApproveJournalList,
    };
  });

  const [showList, setShowList] = useState(JourList);

  useEffect(() => {
    if (emptyError) {
      setVars(ErrVars);
    } else {
      setVars(defaultVars);
    }
  }, [emptyError]);

  const filterOptions = (text) => {
    let filteredList = JourList.filter(option => option.journalName.startsWith(text));
    const maxLength = Math.min(filteredList.length, 100);
    setShowList(filteredList.slice(0, maxLength));
    console.info(showList);
  };

  return (
    <>
      <MulSelectInput
        id='JournalMutiSelect-AutoComplete'
        width="286px"
        sx={vars}
        value={value}
        options={showList}
        loadOptionAction={filterOptions}
        placeholder={'Select Journal'}
        showStartIcon={false}
        disableNewItem={true}
        disableCheckBox={false}
        showPopupIcon={true}
        getOptionLabel={option => option.journalName}
        onValueChange={(newValue) => {
          setJournalSelected(newValue);
        }}
        onOpen={() => {
          setOpened(true);
          setTimeout(()=>{
            document.body.classList.add('hide-scroll');
            document.querySelector('[data-selenium-id="AuthPage-OutletGrid"]').style.overflow = 'hidden';
          },100);
        }}
        onClose={() => {
          setOpened(false);
          setTimeout(()=>{
            document.body.classList.remove('hide-scroll');
            document.querySelector('[data-selenium-id="AuthPage-OutletGrid"]').style.overflow = 'auto';
          },100);
        }}
        Cutwidth={120}
      />
      <Box sx={{
        width: '100px',
        height: opened? '300px': 0,
      }}>{opened}</Box>
    </>
  );
}

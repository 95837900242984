import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChartDataWithStatus } from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import { APIKeyMap } from '../config';
import { formatAPIData2 } from '@/pages/Report/chartUtils';

function filterSelectedData(
  catagories,
  selectedCatagories,
  catagoryAttributeName
) {
  catagories = catagories.filter(item => {
    return selectedCatagories.indexOf(item[catagoryAttributeName]) >= 0;
  });
  return catagories;
}

// Remember to start the name of your custom hook with "use"
export default function useSiPipelineOAHook(
  selectedCatagories,
  catagoryAttributeName,
  url,
  title,
  selectedDate
) {
  const dispatch = useDispatch();

  const { reportDataAPI } = useSelector(state => {
    return {
      reportDataAPI: state.ReportDiagram[title],
    };
  });

  // console.log('=============reportDataAPI==========', reportDataAPI);

  let loading = null,
    error = null,
    data = null;

  if (typeof reportDataAPI !== 'undefined') {
    ({ loading, error, data } = reportDataAPI);
  }
  const dataForChart = useMemo(() => {
    if (data !== null) {
      return formatAPIData2(data, APIKeyMap, catagoryAttributeName);
    }
    return [];
  }, [data]);

  // console.log('=============dataForChart==========', dataForChart);

  useEffect(() => {
    if (typeof reportDataAPI === 'undefined' && selectedDate !== null) {
      dispatch(
        getChartDataWithStatus(url, title, selectedDate, catagoryAttributeName)
      );
    }
  }, [url, title, reportDataAPI, selectedDate]);

  const catagoriesFullList = useMemo(() => {
    if (dataForChart) {
      return dataForChart.map(record => record[catagoryAttributeName]);
    }
    return [];
  }, [dataForChart, catagoryAttributeName]);

  // filter only the selected records
  const selectedDataForBar = useMemo(() => {
    if (dataForChart) {
      // default is select all
      return filterSelectedData(
        dataForChart,
        selectedCatagories,
        catagoryAttributeName
      );
    }
    return [];
  }, [dataForChart, selectedCatagories]);

  // console.log("selectedDataForBar", selectedDataForBar)

  return [catagoriesFullList, selectedDataForBar, dataForChart, loading, error];
}

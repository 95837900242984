import { Note } from '../Note';
import Mytable from './MyTable';
import { PageHeadAnnual } from './PageHead';
import DiagramContent from './AnnualTrendsDiagrams';

export default function AnnualTrends({ showTable, setShowTable }) {
  return (
    <>
      <PageHeadAnnual showTable={showTable} setShowTable={setShowTable} />

      {showTable ? (
        <>
          <Mytable title='Subject Group'></Mytable>
          <Mytable title='Business Division'></Mytable>
        </>
      ) : (
        <DiagramContent />
      )}

      <Note top='4px' />
    </>
  );
}

import Header from '../../../../components/Header/index';
import SelectStageButton from '../../../../components/CreateAndUpdate/SelectStageButton';
import { Box, Grid, Stack } from '@mui/material';
import AcquiredIcon from '../../../../assets/selectStageAcquiredIcon.svg';
import PaperComissionIcon from '../../../../assets/selectStagePaperIcon.svg';
import SubmissionIcon from '../../../../assets/selectStageSubmissionIcon.svg';
import SubmissionDisableIcon from '../../../../assets/selectStageSubmissionDisableIcon.svg';
import ProductionIcon from '../../../../assets/selectStageProductionIcon.svg';
import ProductionDisableIcon from '../../../../assets/selectStageProductionDisableIcon.svg';
import IdeaProposalIcon from '../../../../assets/selectStageIdeaProposalIcon.svg';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../../components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import MainPageFramework from '../CGTComponents/MainPageFramework';
import CreateAndUpdateMain from '../CommonComponents/CreateAndUpdateMain';
import { AllCentered } from '../CGTComponents/CommonStyle';
import {getStageList} from "@/actions/SIMT-SI/SpecialIssue/Config";

function SelectStage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selector = state => {
    return {
      stageList: state.SI.stageList,
    };
  };

  const { stageList } = useSelector(selector);

  useEffect(() => {
    dispatch(getStageList());
  }, []);


  function FreshWebsite() {
    setInterval(() => {
      if (stageList.length === 0) {
        return (
          <>
            <p>load Failed</p>
            <a href='javascript:void(0)'>Click Here To Fresh</a>
          </>
        );
      }
    }, 3000); // 3秒之后执行该方法
  }

  const container =
    stageList.length !== 0 ? (
      <Stack spacing={1.5} direction='column' sx={{ ...AllCentered }}>
        <Box
          data-selenium-id='SIPage_CreateAndUpdateSI-SelectStage-GridItem-Box'
          width='100%'
          maxWidth='448px'
          sx={{
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '28px',
            letterSpacing: '-0.022em',
            textTransform: 'capitalize',
            color: '#596A7C',
            marginBottom: '6px',
          }}
        >
          Select Stage
        </Box>
        <>
          <SelectStageButton
            id='updateStageSelectStageIdeaProposalButton'
            data-selenium-id='SIPage_CreateAndUpdateSI-SelectStage-UpdateStageSelectStageIdeaProposalButton'
            content={stageList[0].stageCode}
            startIcon={<IdeaProposalIcon />}
            startIconDisabled={<IdeaProposalIcon />}
            disable={false}
            onClick={() => {
              navigate(`/simt/auth/siRelated/si/create/IdeaAndProposal`, {
                state: stageList[0].stageCode,
              });
            }}
          />
          <SelectStageButton
            id='updateStageSelectStageAcquiredButton'
            data-selenium-id='SIPage_CreateAndUpdateSI-SelectStage-UpdateStageSelectStageAcquiredButton'
            content={stageList[1].stageCode}
            startIcon={<AcquiredIcon />}
            startIconDisabled={<AcquiredIcon />}
            onClick={() => {
              navigate(`/simt/auth/siRelated/si/create/Acquired`, {
                state: stageList[1].stageCode,
              });
            }}
            disable={false}
          />
          <SelectStageButton
            id='updateStageSelectStagePaperCommissionButton'
            data-selenium-id='SIPage_CreateAndUpdateSI-SelectStage-UpdateStageSelectStagePaperCommissionButton'
            content={stageList[2].stageCode}
            startIcon={<PaperComissionIcon />}
            startIconDisabled={<PaperComissionIcon />}
            onClick={() => {
              navigate(`/simt/auth/siRelated/si/create/PaperCommission`, {
                state: stageList[2].stageCode,
              });
            }}
            disable={false}
          />
          <SelectStageButton
            id='updateStageSelectStageSubmissionButton'
            data-selenium-id='SIPage_CreateAndUpdateSI-SelectStage-UpdateStageSelectStageSubmissionButton'
            content={stageList[3].stageCode}
            startIcon={<SubmissionIcon />}
            startIconDisabled={<SubmissionDisableIcon />}
            disable={false}
            onClick={() => {
              navigate(`/simt/auth/siRelated/si/create/SubmissionAndReview`, {
                state: stageList[3].stageCode,
              });
            }}
          />
          <SelectStageButton
            id='updateStageSelectStageProductionButton'
            data-selenium-id='SIPage_CreateAndUpdateSI-SelectStage-UpdateStageSelectStageProductionButton'
            content={stageList[4].stageCode}
            startIcon={<ProductionIcon />}
            startIconDisabled={<ProductionDisableIcon />}
            disable={false}
            onClick={() => {
              navigate(`/simt/auth/siRelated/si/create/Production`, {
                state: stageList[4].stageCode,
              });
            }}
          />
        </>
      </Stack>
    ) : (
      <FreshWebsite data-selenium-id='SIPage_CreateAndUpdateSI-SelectStage-FreshWebsite'></FreshWebsite>
    );

  return (
    <MainPageFramework id='createSI_selectPage'>
      <CreateAndUpdateMain
        id='createSI_selectPage_content'
        container={container}
      ></CreateAndUpdateMain>
    </MainPageFramework>
  );
}

export default SelectStage;

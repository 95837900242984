import { PropTypes } from 'prop-types';
import { Box } from '@mui/material';
import styles from './index.module.scss';
import ChartCard from './ChartCard';
import {PERMISSIONS} from "@/constant/permission";

const ChartGroup = ({
  groupTitle,
  levels,
  onSelectChart,
  onSelectChartIndex,
}) => {
  const userPermissions = localStorage.getItem('userPermissions') || '';
  return (
    <>
      <p className={styles['group-title']}>{groupTitle}</p>
      <Box className={styles['group-container']}>
        {levels &&
          levels.map(level => {
            const { title, img, chartIndex } = level;
            if (
              (title === 'CE' || title === 'CAT Group') &&
              !userPermissions.includes(PERMISSIONS.REPORT_READ_CE)
            )
              return;
            return (
              <div
                key={title}
                className={styles['container']}
                onClick={() => {
                  if (onSelectChartIndex) {
                    onSelectChartIndex(chartIndex);
                  } else {
                    onSelectChart(groupTitle, title);
                  }
                }}
              >
                <ChartCard chartImg={img} />
                <p className={styles['chart-title']}>{title}</p>
              </div>
            );
          })}
      </Box>
    </>
  );
};

ChartGroup.propTypes = {
  groupTitle: PropTypes.string,
  onSelectChart: PropTypes.func,
  levels: PropTypes.array,
  onSelectChartIndex: PropTypes.func,
};

export default ChartGroup;

import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import TimelineLeft from './TimelineLeft';
import TimelineRight from './TimelineRight';

/**
 * TimelineComponent由两部份组成：左侧和右侧
 * 左侧是显示SIP的状态的胶囊和连接符
 * 右侧是描述信息
 * 必须传入的东西通过proptypes限制了，描述信息在下面
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function TimelineComponent(props) {
  const {
    id,
    step,
    origin,
    title,
    date,
    user,
    comments,
    history,
    requiresConnector = true,
    handleClose,
    openNewFlagAddition,
  } = props;
  return (
    <Stack
      data-selenium-id='SIPPage_Components-ProcessLog-Timeline-Stack'
      direction='row'
      columnGap='16px'
      justifyContent='left'
      alignItems='stretch'
    >
      <TimelineLeft
        id={id}
        data-selenium-id={id}
        step={step}
        origin={origin}
        requiresConnector={requiresConnector}
      />
      <TimelineRight
        id={id}
        step={step}
        data-selenium-id={id}
        title={title}
        date={date}
        user={user}
        origin={origin}
        comments={comments}
        handleClose={handleClose}
        openNewFlagAddition={openNewFlagAddition}
        history={history}
      />
    </Stack>
  );
}

/**
 * id
 * --- 左侧 ---
 * step 当前SIP的状态
 * origin 有的状态有origin: XXX
 * 注：以上两个必需要用`initialReview`、`editorReview`这样的字符串传入
 * requiresConnector 是否需要虚线连接两个state。如果使用了<SIPTimeline></SIPTimeline>包裹的话，这个字段不需要传入，会自动填充
 * --- 右侧 ---
 * title 右侧最上面的大标题
 * date 日期
 * user 操作用户
 * comments 显示在comments处的内容
 *
 * @type {{date: Requireable<string>, comments: Validator<NonNullable<NonNullable<InferType<Requireable<(InferPropsInner<Pick<{name: Requireable<string>, value: Requireable<string>}, never>> & Partial<InferPropsInner<Pick<{name: Requireable<string>, value: Requireable<string>}, "name" | "value">>>)[]>|Requireable<string>>>>>, origin: Requireable<string>, requiresConnector: Requireable<boolean>, step: Validator<NonNullable<string>>, id: Validator<NonNullable<string>>, title: Requireable<string>, user: Requireable<string>}}
 */
TimelineComponent.propTypes = {
  id: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  origin: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  user: PropTypes.string,
  comments: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    PropTypes.string,
  ]).isRequired,
  requiresConnector: PropTypes.bool,
};

export default TimelineComponent;

import { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import styles from './index.module.scss';

function Chart({
  options,
  height = '400px',
  width,
  isZoomLabelFontSize = true,
  minFontSize = 10,
  maxFontSize = 18,
}) {
  // console.count("render Chart");
  // console.log("===width===",width);

  const chartRef = useRef(null);

  let chartInstance = null;

  function getEchartsInstance() {
    console.log('==getEchartsInstance==');
    let chartI;
    const renderedInstance = echarts.getInstanceByDom(chartRef.current);
    if (renderedInstance) {
      chartI = renderedInstance;
    } else {
      chartI = echarts.init(chartRef.current);
    }
    return chartI;
  }
  // 定义渲染函数
  function renderChart() {
    try {
      // `echarts.getInstanceByDom` 可以从已经渲染成功的图表中获取实例，其目的就是在 options 发生改变的时候，不需要
      // 重新创建图表，而是复用该图表实例，提升性能
      const renderedInstance = echarts.getInstanceByDom(chartRef.current);
      // console.log('==renderChart==', renderedInstance);

      if (renderedInstance) {
        // console.count('==reused chart==');
        chartInstance = renderedInstance;
      } else {
        // console.count('==new chart==');
        chartInstance = echarts.init(chartRef.current);
      }

      // animation
      chartInstance.setOption({
        ...options,
        animation: true,
        animationDuration: 1500,
        animationEasingUpdate: 'quinticInOut',
      });

      if (isZoomLabelFontSize) {
        chartInstance.on('datazoom', function (params) {
          // console.log('=== data zoom 123 ===', params);

          const start = params.start ?? params.batch[0].start;
          const end = params.end ?? params.batch[0].end;

          // first get the zoom ratio
          const ratio = (end - start) / 100;

          const fontSize = maxFontSize - ratio * (maxFontSize - minFontSize);

          // calculate new options
          const option = chartInstance.getOption();
          const series = option.series;
          const newSeries = series.map(serie => {
            if (serie.type !== 'bar') return serie;
            return {
              ...serie,
              label: { ...serie.label, fontSize },
            };
          });
          options.dataZoom[0].start = start;
          options.dataZoom[0].end = end;
          const newOptions = {
            ...options,
            series: newSeries,
          };
          // console.log('===newOptions===', newOptions);

          chartInstance.setOption(newOptions);
        });

        // dispatch datazoom action?
      }
    } catch (error) {
      console.error('error', error.message);
      chartInstance && chartInstance.dispose();
    }
  }

  // 定义窗口大小发生改变执行的回调函数
  // function resizeHandler() {
  //   /**
  //    * * Improvement: use chartInstance.resize() to avoid repaint
  //    */
  //   console.log('===========resizeHandler==========');
  //   chartInstance.resize();
  // }

  // 页面初始化时，开始渲染图表
  useEffect(() => {
    renderChart();
    return () => {
      // 销毁图表实例，释放内存
      chartInstance && chartInstance.dispose();
    };
  }, [options, width]); // ??!!

  // 监听窗口大小改变
  useEffect(() => {
    // window.addEventListener('resize', resizeHandler);
    // return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return (
    <div
      className={styles['base-chart-container']}
      style={{ height, width }}
      ref={chartRef}
    />
  );
}

export default Chart;

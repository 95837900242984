import { useDispatch, useSelector } from 'react-redux';
import { openConfirm, setEditType, setTextParam } from '@/actions/SIMT-User/Alert/AlertAction';
import GeneralInformationForm from './GeneralInfomationForm';
import GEAndDatesForm from './GEsAndDatesForm';
import ReviewPage from '../../../ReviewPage/index';
import Header from '../../../../../../components/Header';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import CreateAndUpdateMain from '../../../CommonComponents/CreateAndUpdateMain';
import CreateAndUpdateHeader from '../../../../CreateAndUpdateSI/CommonComponents/CreateAndUpdateHeader';
import CreateAndUpdateFooter from '../../../../CreateAndUpdateSI/CommonComponents/CreateAndUpdateFooter';
import moment from 'moment';
import Footer from '../../../../../../components/Footer';
import {
  backMatchUTCTime,
  getUTCZeroTimeOffset,
  turnDateToUTC,
} from '@/utils/commonUtils';
import {
  SIDateChangeFunction,
  SISubmissionDeadlineDateChangeFunction,
} from '@/utils/commonUtils';
import { useNavigate } from 'react-router-dom';
import {
  scrollToTop,
  trimItemFilds,
} from '../../../../../../utils/commonUtils';
import { formatGENoEmail, formatGEOnlyHasEmail } from '../../utils';
import MainPageFramework from '../../../CGTComponents/MainPageFramework';
import {createSIInAcquited} from "@/actions/SIMT-SI/SpecialIssue";

//MainPage包括footer
function TabPanel(props) {
  const { children, value, index } = props;

  if (value < 0) {
    window.history.back();
  }
  return (
    <div
      data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage_MainPage-TabPanel'
      style={{ width: '100%' }}
    >
      {value === index && (
        <Box
          data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage_MainPage-TabPanel-Box'
        >
          <Typography data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage_MainPage-TabPanel-Typography'>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function AcquiredPage() {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [clickedButton, setclickedButton] = useState(false);
  const [GERepeatErr, setGERepeatErr] = useState(false);
  const [jump, setJump] = useState(true);
  //每点击一次变成相反数
  const [step3NextMonitor, setStep3NextMonitor] = useState(true);
  const steps = ['General information', 'Stakeholders & Dates', 'Review & Create'];

  //初始化
  const [GeneralInformation, setGeneralInformation] = useState({
    Stage: 'Acquired',
    HandlingCE: -1,
    JournalCode: '',
    JournalName: '',
    GroupOrTitle: '',
    SIPublicationFormat: '',
    SpecialIssueTitle: '',
    SpecialIssueSource: '',
    PaperCommissionMethod: '',
    siRevenueModel: 'OA',
    WorkFlow: '',
    HandlingCeSelectValue: '', // 保存数值使用，创建SI执行的时候，并不往后端传递
    HandlingCEReviewValue: '', // 存储HandlingCE的name，不保存email，用在review阶段
    revenueModel: '',
  });

  const [GuestEditors, setGuestEditors] = useState({
    LeadGe: '',
    CoGe: '',
    LeadGeName: '', //用于review的不带邮箱的值
    CoGeName: '',
  });

  const [Dates, setDates] = useState({
    IdeaDate: null,
    AcquiredDate: null,
    SubmissionDeadLine: null,
    ExpectedAcceptanceDate: null,
    ExpectedPublicationDate: null,
  });

  //显示在review页面的内容
  const reviewData = {
    generalInfomation: {
      'Stage:': GeneralInformation.Stage,
      'Handling CE:': GeneralInformation.HandlingCEReviewValue,
      'Group/Title:': GeneralInformation.GroupOrTitle,
      'SI Publication Format:': GeneralInformation.SIPublicationFormat,
      'SI Revenue Model:': GeneralInformation.siRevenueModel,
      'Special Issue Title:': trimItemFilds(
        GeneralInformation.SpecialIssueTitle
      ),
      'Journal Code:': GeneralInformation.JournalCode,
      'Journal Name:': GeneralInformation.JournalName,
      'Special Issue Source:': GeneralInformation.SpecialIssueSource,
      'Workflow:': GeneralInformation.WorkFlow,
      'Paper Commissioning Method:': GeneralInformation.PaperCommissionMethod,
    },
    guestEditors: {
      'Lead GE:': formatGENoEmail(GuestEditors.LeadGe),
      'Co-GE:': formatGENoEmail(GuestEditors.CoGe),
    },
    dates: {
      'Idea Date:':
        Dates.IdeaDate !== null
          ? moment(Dates.IdeaDate).utc().format('YYYY-MM-DD')
          : '',
      'Acquired Date:':
        Dates.AcquiredDate !== null
          ? moment(Dates.AcquiredDate).utc().format('YYYY-MM-DD')
          : '',
      'Submission Deadline:':
        Dates.SubmissionDeadLine !== null
          ? moment(Dates.SubmissionDeadLine).utc().format('YYYY-MM-DD')
          : '',
      'Expected Acceptance Date:':
        Dates.ExpectedAcceptanceDate !== null
          ? moment(Dates.ExpectedAcceptanceDate).utc().format('YYYY-MM-DD')
          : '',
      'Expected Publication Date:':
        Dates.ExpectedPublicationDate != null
          ? moment(Dates.ExpectedPublicationDate).utc().format('YYYY-MM-DD')
          : '',
    },
  };
  const [value, setValue] = useState('');

  const selector = state => {
    return {
      CreateSIResult: state.SI.createSIResult,
    };
  };
  const { CreateSIResult } = useSelector(selector);

  const navigate = useNavigate();

  useEffect(() => {
    if ((CreateSIResult !== null) & (CreateSIResult !== '')) {
      if (CreateSIResult['code'] === 200) {
        if (CreateSIResult.data !== undefined) {
          navigate(`/simt/auth/siRelated/si/detail/${CreateSIResult.data}`);
          dispatch(setTextParam());
          dispatch(setEditType('createSI'));
          dispatch(openConfirm());
        }
      } else {
        dispatch(setTextParam());
        dispatch(setEditType('error'));
        dispatch(openConfirm());
      }
    }
  }, [CreateSIResult]);
  const [isOA, setIsOA] = React.useState(
    GeneralInformation.revenueModel == 'OA' ? true : false
  );
  useEffect(() => {
    setIsOA(GeneralInformation.revenueModel === 'OA' ? true : false);
  }, [GeneralInformation.revenueModel]);
  //handleNext数组
  const handleNextArray = [
    () => {
      //第一步点击next
      setclickedButton(true);
      if (activeStep < steps.length - 1 && jump) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setclickedButton(false);
        scrollToTop();
      }
    },
    () => {
      // 第二步点击next
      setclickedButton(true);
      if (activeStep < steps.length - 1 && jump) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setclickedButton(false);
        scrollToTop();
      }
    },
    async () => {
      // 第三步的操作,后端交互
      // 传的参数
      let SIData = {};
      SIData.stage = 'Acquired';
      SIData.handlingCe = GeneralInformation.HandlingCE;
      SIData.journalName = GeneralInformation.JournalName;
      SIData.journalCode = GeneralInformation.JournalCode;
      SIData.specialIssueType = GeneralInformation.SIPublicationFormat;
      SIData.specialIssueTitle = trimItemFilds(
        GeneralInformation.SpecialIssueTitle
      );
      SIData.siSource = GeneralInformation.SpecialIssueSource;
      SIData.leadGuestEditors = formatGEOnlyHasEmail(GuestEditors.LeadGe);
      SIData.coGuestEditors = formatGEOnlyHasEmail(GuestEditors.CoGe);
      SIData.paperCommitioningMethod = GeneralInformation.PaperCommissionMethod;
      SIData.workflow = GeneralInformation.WorkFlow;
      SIData.closedForSubmission =
        Dates.ClosedforSubmission === undefined
          ? ''
          : Dates.ClosedforSubmission;
      SIData.numberOfRecruitedArticles = 0;
      SIData.numberOfSubmittedArticles = 0;
      SIData.numberOfAcceptedArticles = 0;
      SIData.numberOfRejectedArticles = 0;
      SIData.siRevenueModel = GeneralInformation.siRevenueModel;
      SIData.ideaDate = SIDateChangeFunction(Dates.IdeaDate);
      SIData.acquiredDate = SIDateChangeFunction(Dates.AcquiredDate);
      SIData.expectedSubmissionDeadline =
        SISubmissionDeadlineDateChangeFunction(Dates.SubmissionDeadLine);
      SIData.expectedAcceptanceDate = SIDateChangeFunction(
        Dates.ExpectedAcceptanceDate
      );
      SIData.expectedPublicationDate = SIDateChangeFunction(
        Dates.ExpectedPublicationDate
      );
      await dispatch(createSIInAcquited(SIData));
    },
  ];

  const handleStringtoList = value => {
    return value.split(',');
  };

  const AcquiredMandatoryList = handleStringtoList(
    localStorage.getItem('AcquiredMandatoryList')
  );

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    setJump(true);
    scrollToTop();

    setGeneralInformationError(false);
    setGEsError(false);
    setclickedButton(false);
  };

  // clickTime是用于触发每次 button 就会滚动到错误位置的 标志
  const [clickTime, setClickTime] = useState(0);

  const [GeneralInformationError, setGeneralInformationError] = useState(false);
  const [GEsError, setGEsError] = useState(false);



  const container = (
    <>
      {/* 需要跳转的页面，index为顺序 */}
      <TabPanel
        data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage_MainPage-TabPanel-0'
        value={activeStep}
        index={0}
      >
        <GeneralInformationForm
          MandatoryList={AcquiredMandatoryList}
          id='createProductionGeneralInformationPage'
          data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage_MainPage-ContainerTabPanel-GeneralInformationPage-0'
          GeneralInformation={GeneralInformation}
          setGeneralInformation={setGeneralInformation}
          clickedButton={clickedButton}
          setJump={setJump}
          clickTime={clickTime}
          setHasError={setGeneralInformationError}
        />
      </TabPanel>
      <TabPanel
        data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage_MainPage-TabPanel-1'
        value={activeStep}
        index={1}
      >
        <GEAndDatesForm
          id='createProductionAcquiredGEAndDatesPage'
          data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage_MainPage-ContainerTabPanel-GeneralInformationPage-1'
          MandatoryList={AcquiredMandatoryList}
          GuestEditors={GuestEditors}
          setGuestEditors={setGuestEditors}
          Dates={Dates}
          setDates={setDates}
          clickedButton={clickedButton}
          setJump={setJump}
          GERepeatErr={GERepeatErr}
          setGERepeatErr={setGERepeatErr}
          clickTime={clickTime}
          setHasError={setGEsError}
        />
      </TabPanel>
      <TabPanel
        data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage_MainPage-TabPanel-2'
        value={activeStep}
        index={2}
      >
        <ReviewPage
          reviewData={reviewData}
          setValue={setValue}
          id='createProductionReviewAndCreatePage'
          data-selenium-id='SIPage_CreateAndUpdateSI_AcquiredPage_MainPage-ContainerTabPanel-ReviewAndCreatePage'
          isOA={isOA}
        />
      </TabPanel>
    </>
  );

  const stepper = (
    <CreateAndUpdateHeader
      id='createSI_acquiredPage_mainPage_header'
      activeStep={activeStep}
      steps={steps}
    />
  );

  const actionBar = (
    <CreateAndUpdateFooter
      id='createSI_acquiredPage_mainPage_actionBar'
      activeStep={activeStep}
      lastStep={steps.length - 1}
      handleBack={handleBack}
      handleNextOrCreate={async () => {
        let data = !step3NextMonitor;
        setStep3NextMonitor(data);
        await handleNextArray[activeStep]();
        setClickTime(clickTime + 1);
      }}
      Err={GeneralInformationError || GEsError}
    />
  );

  return (
    <MainPageFramework
      id='createSI_acquiredPage_mainPage'
      actionBar={actionBar}
      stepper={stepper}
    >
      <CreateAndUpdateMain
        id='createSI_acquiredPage_mainPage_content'
        container={container}
      />
    </MainPageFramework>
  );
}

export default AcquiredPage;

import { useEffect, useState, useRef } from 'react';
import SIPViewAllArrow from '@/assets/SIPViewAllArrow.svg';
import '../Submodule/HideText.css';
import { Box, Stack } from '@mui/material';

export default function HideTextConflictDetail(props) {
  const [lineNum, setLineNum] = useState(2);
  const [expanding, setExpanding] = useState(false);
  const [needHide, setNeedHide] = useState(false);
  const maxHeight = 40;
  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current.scrollHeight > maxHeight) {
      setNeedHide(true);
    } else {
      setNeedHide(false);
    }
    window.addEventListener('resize', resizeUpdate);
    return () => {
      window.removeEventListener('resize', resizeUpdate);
    };
  }, []);

  const resizeUpdate = e => {
    if (elementRef.current.scrollHeight > maxHeight) {
      setNeedHide(true);
    } else {
      setNeedHide(false);
    }
  };

  const textStyle = {
    width: '488px',
    // height: "40px",
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#262E35',
    flex: 'none',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: lineNum,
    WebkitBoxOrient: 'vertical',
    // maxHeight: maxHeight,

  };

  const viewAllStyle = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    height: '20px',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.01em',
    color: '#154AB6',
    flex: 'none',
    cursor: 'pointer',
    display:'flex',
    alignItems:'center'
  };

  const handleViewAllClick = () => {
    setExpanding(true);
    setLineNum('inherit');
  };

  const handleHideClick = () => {
    setExpanding(false);
    setLineNum(2);
  };

  return (
    <div
      style={{
        position: 'relative',
        // overflowX: "auto",
      }}
    >
      <div style={textStyle} ref={elementRef}>
        {props.children}
      </div>
      {needHide ? (
        expanding ? (
          <div style={{ marginTop: '8px', height: '20px' }}>
            <div style={viewAllStyle} onClick={handleHideClick}>
              Hide
            </div>
          </div>
        ) : (
          <div
            style={{ marginTop: '8px', height: '20px', }}
          >
            <Stack onClick={handleViewAllClick} spacing={0.5} direction='row'>
              <Box sx={viewAllStyle}>View All</Box>
              <Box id='viewAllArrow1' sx={{display:'flex',alignItems:'center'}}>
                <SIPViewAllArrow data-selenium-id='SIP_DetailPage_GE-HideTextConflict-ViewAllArrow' />
              </Box>
            </Stack>
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
}

import React, { useImperativeHandle, useRef, useState } from 'react';
import { Box, Breadcrumbs, Link, styled } from '@mui/material';
import './index.css';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const StyledBreadcrumbs = styled(Breadcrumbs, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiBreadcrumbs-separator': {
    color: theme.palette.primary[500],
  },
  '& .MuiBreadcrumbs-li a': {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  },
  '& .MuiBreadcrumbs-li:hover a': {
    color: theme.palette.primary[600],
    fontWeight: 600,
  },
  '& .Mui-disabled': {
    color: theme.palette.grey[300],
  },
  '& .Mui-selected': {
    color: theme.palette.grey[500],
    fontWeight: 600,
  },
}));

const Contain = styled('div', { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    // flexGrow: 1,
    // padding: theme.spacing(3),
    padding: '0 32px', // 设置左侧和右侧的 padding 为 32px
    // margin: '32px', // 设置外边距为32px
    // width: '1296px', // 设置宽度为1296px
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: '83px',
    marginLeft: '96px',
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: '240px',
    }),
  })
);

const Content = React.forwardRef((props, ref) => {
  const location = useLocation();
  const pathname = location.pathname;
  const { customizedPath } = useSelector(state => {
    return {
      customizedPath: state.Bread.customizedPath,
    };
  });

  useImperativeHandle(ref, () => ({
    hasContext: () => {
      for (const item of RouteMap) {
        if (pathname.match(item.match)) {
          return true;
        }
      }
      return false;
    },
  }));
  // 预定义不同的面包屑元素导航到的位置
  // 之后实际定义面包屑匹配的时候，直接通过 nav.xxx 即可
  const nav = {
    SystemManagement: ['/simt/auth/system/user', 'System Management'],
    UserManagement: [
      '/simt/auth/system/user',
      <FormattedMessage id='bread.userManagement' />,
    ],
    RoleManagement: ['/simt/auth/system/role', 'Role Management'],
    RoleDetails: ['/simt/auth/system/role/', 'Role Details'],
    CreateRole: ['/simt/auth/system/role/new', 'Create Role'],
    UpdateRole: ['/simt/auth/system/role/update', 'Update Role'],
    DisplayRole: ['/simt/auth/system/role/detail', 'Role Detail'],
    SystemLog: ['/simt/auth/system/log', 'System Log'],
    SystemConfig: ['/simt/auth/system/config', 'System Configuration'],
    EmailTemplates: ['/simt/auth/system/email', 'Email Templates'],
    ReportJobs: ['/simt/auth/system/jobs', 'Report Jobs'],
    //ExportMArketingData: ['/simt/auth/system/export', 'Export Marketing Data'],
    SpecialIssueRelated: [
      '/simt/auth/siRelated/si/overview',
      'Special Issue Related',
    ],
    SpecialIssueProposalRelated: [
      '/simt/auth/sipRelated/sip/overview',
      'Special Issue Proposal Related',
    ],
    ReportRelated: [
      '/simt/auth/reportRelated/report/siPipeline',
      'Report Related',
    ],
    SpecialIssue: [
      '/simt/auth/siRelated/si/overview',
      'Special Issue Overview',
    ],
    SpecialIssueDetail: [
      '/simt/auth/siRelated/si/overview',
      'Special Issue Detail',
    ],
    SpecialIssueProposal: [
      '/simt/auth/sipRelated/sip/overview',
      'Special Issue Proposal',
    ],
    // SpecialIssueProposalDetail: [
    //   '/simt/auth/sipRelated/sip/detail',
    //   'Special Issue Proposal Detail',
    // ],
    GuestEditor: ['/simt/auth/siRelated/ge/overview', 'Guest Editor'],
    // GuestEditorDetail: [
    //   '/simt/auth/siRelated/ge/overview',
    //   customizedPath ?? 'Guest Editor Detail',
    // ],
    Journal: ['/simt/auth/journalRelated/prp/overview', 'Journal'],
    CRContactOverview: ['/simt/auth/journalRelated/prp/overview', 'PRP Contact'],
    CRContactDetail: [
      '/simt/auth/journalRelated/prp/overview',
      customizedPath ?? 'PRP Contact Detail',
    ],
    // AnnualTargetOverview: ['/simt/auth/system/target', 'Annual Target'],
    // <<<<<<< HEAD
    // ReportOverview: ['/simt/auth/reportRelated/report/*', 'Report'], // reportRelated
    // CATJournalOverview: ['/simt/auth/journal/cat/overview', 'CAT Journal'],
    // =======
    // ReportOverview: ['/simt/auth/ReportRelated/report/overview', 'Report'],
    CATJournalOverview: [
      '/simt/auth/journalRelated/cat/overview',
      'CAT Journal',
    ],
    CATJournalDetail: [
      '/simt/auth/journalRelated/cat/overview',
      'Journal Detail',
    ],
    CMDetail: ['/simt/auth/marketing/si/overview', 'Special Issue Detail'],
    CMOverview: ['/simt/auth/marketing/si/overview', 'Special Issue Overview'],
    // >>>>>>> release-p2m1-20230919
  };

  // 面包屑匹配的路径。
  // 从上到下依次进行字符串匹配，匹配成功后将会显示对应的 bread
  // 如果有优先级靠前的（子项），放到前面
  const RouteMap = [
    {
      match: '/simt/auth/system/role/detail',
      bread: [nav.SystemManagement, nav.RoleManagement, nav.RoleDetails],
    },
    {
      match: '/simt/auth/system/role/update',
      bread: [nav.SystemManagement, nav.RoleManagement, nav.UpdateRole],
    },
    {
      match: '/simt/auth/system/role/new',
      bread: [nav.SystemManagement, nav.RoleManagement, nav.CreateRole],
    },
    {
      match: '/simt/auth/system/user',
      bread: [nav.SystemManagement, nav.UserManagement],
    },
    {
      match: '/simt/auth/system/role',
      bread: [nav.SystemManagement, nav.RoleManagement],
    },
    {
      match: '/simt/auth/system/log',
      bread: [nav.SystemManagement, nav.SystemLog],
    },
    // {
    //   match: '/simt/auth/system/config',
    //   bread: [nav.SystemManagement, nav.SystemConfig],
    // },
    // {
    //   match: '/simt/auth/system/email',
    //   bread: [nav.SystemManagement, nav.EmailTemplates],
    // },
    // {
    //   match: '/simt/auth/system/jobs',
    //   bread: [nav.SystemManagement, nav.ReportJobs],
    // },
    // {
    //   match: '/simt/auth/system/export',
    //   bread: [nav.SystemManagement, nav.ExportMArketingData],
    // },
    // Delete SI Overview page bread
    // {
    //   match: '/simt/auth/siRelated/si/overview',
    //   bread: [nav.SpecialIssueRelated, nav.SpecialIssue],
    // },
    {
      match: '/simt/auth/siRelated/si/detail',
      bread: [
        // nav.SpecialIssueRelated,
        nav.SpecialIssue,
        nav.SpecialIssueDetail,
      ],
    },
    // {
    //   match: '/simt/auth/siRelated/ge/overview',
    //   bread: [nav.SpecialIssueRelated, nav.GuestEditor],
    // },
    // {
    //   match: '/simt/auth/siRelated/ge/detail',
    //   bread: [nav.SpecialIssue, nav.GuestEditor, nav.GuestEditorDetail],
    // },
    // {
    //   match: '/simt/auth/sipRelated/sip/overview',
    //   bread: [nav.SpecialIssueProposalRelated, nav.SpecialIssueProposal],
    // },
    // {
    //   match: '/simt/auth/sipRelated/sip/detail',
    //   bread: [
    //     // nav.SpecialIssueProposalRelated,
    //     nav.SpecialIssueProposal,
    //     nav.SpecialIssueProposalDetail,
    //   ],
    // },
    // {
    //   match: '/simt/auth/journalRelated/cr/overview',
    //   bread: [nav.Journal, nav.CRContactOverview],
    // },
    {
      match: '/simt/auth/journalRelated/prp/detail',
      bread: [nav.Journal, nav.CRContactOverview, nav.CRContactDetail],
    },
    // {
    //   match: '/simt/auth/system/target',
    //   bread: [nav.SystemManagement, nav.AnnualTargetOverview],
    // },
    // {
    //   match: '/simt/auth/reportRelated/report/overview',
    //   bread: [nav.ReportRelated, nav.ReportOverview],
    // },
    //{
    //  match: '/simt/auth/journalRelated/cat/overview',
    //  bread: [nav.Journal, nav.CATJournalOverview],
    //},
    // {
    // match: '/simt/auth/reportRelated/report/*',
    // bread: [nav.ReportRelated, nav.ReportOverview],
    // },
    {
      match: '/simt/auth/journalRelated/cat/detail',
      bread: [nav.CATJournalOverview, nav.CATJournalDetail],
    },
    {
      match: '/simt/auth/marketing/si/detail',
      bread: [nav.CMOverview, nav.CMDetail],
    },
  ];

  /**
   * @description 判断当前位置是否需要进行超链接
   * @author Liu Xin
   * @param {*} item 当前的网页对应的item
   * @param {*} n
   * @param {*} i 现在处于第i级目录
   * @returns 返回在面包屑上应该出现的样式
   */
  const isNeedNavigate = (item, n, i) => {
    //不需要超链接的条件，目前为当前目录的最后一级不进行跳转
    if (i === item.bread.length - 1) {
      return (
        <Box
          id={'commonBreadCrumb' + i}
          sx={{
            color: '#596A7C',
            fontSize: '14px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontWeight: 600,
            letterSpacing: '-0.005em',
          }}
        >
          {n[1]}
        </Box>
      );
    } else {
      return (
        <Link
          id={'commonBreadCrumb' + i}
          key={n[1]}
          href={n[0]}
          underline='none'
          sx={{
            // color: 'primary.main',
            color: '#0052CC',
            fontSize: '14px',
            lineHeight: '20px',
            fontStyle: 'normal',
            fontWeight: 400,
            '&: hover': {
              color: '#113D95',
              fontWeight: 600,
            },
          }}
        >
          {n[1]}
        </Link>
      );
    }
  };

  for (const item of RouteMap) {
    if (pathname.match(item.match)) {
      // alert("return " + pathname)
      return (
        <StyledBreadcrumbs
          separator='/'
          aria-label='breadcrumb'
          sx={{
            padding: 3,
            paddingY: 2.5,
            lineHeight: 1,
          }}
        >
          {item.bread.map((n, i) => {
            return <Box>{isNeedNavigate(item, n, i)}</Box>;
          })}
        </StyledBreadcrumbs>
      );
    }
  }
  // return <StyledBreadcrumbs />;
  return <></>;
});

const AdminBreadcrumbs = React.forwardRef((props, href) => {
  const open = useSelector(state => state.UserManage.siderOpen);

  return (
    <Contain
      id='adminBreadcrumbs'
      sx={{
        padding: 0,
        marginLeft: open ? '248px' : '88px',
        '& .MuiBreadcrumbs-li': {
          lineHeight: '20px',
        },
        '& .MuiBreadcrumbs-root': {
          height: '100%',
          width: 'calc(100vw - 248px)',
          lineHeight: '20px',
          paddingTop: '20px',
          paddingBottom: '20px',
        },
      }}
      open={open}
    >
      <Content ref={href} />
    </Contain>
  );
});

export default AdminBreadcrumbs;

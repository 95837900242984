import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Modal,
  Stack,
} from '@mui/material';
import Scrollbars from 'react-custom-scrollbars';
import { useDispatch } from 'react-redux';
import { H4Font, SmallBoldFont } from '../../../../components/FontStyle';
import GEDetailCloseIcon from '@/assets/GEDetailCloseIcon.svg';
import EditContent from './EditContent';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import SIModalPro from '../../../../modules/Modal';
import { useParams } from 'react-router-dom';
import { StateButton } from '@/componentsphase2/StateButton';
import { LoadingButton } from '@mui/lab';
import { useIntl } from 'react-intl';
import { GET_SIP_STATUS } from '../../../../actions/SIMT-SIP/SIP/SIPAction';
import {AddGEInfomation, DeleteCV, getGEInformation, getSIPStatus} from "@/actions/SIMT-SIP/SIP/SipDetail";
const ContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '593px',
  padding: '32px 35px 0px',
  background: '#FFFFFF',
  boxSizing: 'border-box',
};

const HeaderStyle = {
  width: '100%',
  height: '32px',
};

const ContentStyle = {
  width: '100%',
  marginBottom: '28px',
  height: '330px',
};
const BtnBoxStyle = {
  height: '36px',
  width: '82px',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  ...SmallBoldFont,
};

export function GEInfomationAddModal(props) {
  const { title = '', open = false, handleClose = () => { } } = props;

  const [data, setData] = useState(null);
  const [isClick, setIsClick] = useState(false);

  const EmailPattern =
    /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/;

  const webPattern =
    /(https?:\/\/)?(([0-9a-z.]+\.[a-z]+)|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[0-9a-z%/.\-_]*)?(\?[0-9a-z=&%_\-]*)?(\#[0-9a-z=&%_\-]*)?/;

  const [emailAddressFormatErr, setEmailAddressFormatErr] = useState(false);
  const [webFormatErr, setWebFormatErr] = useState(false);
  const [saving, setSaving] = useState(false);
  const [orcIdFormatErr, setOrcIdFormatErr] = useState(false);

  const { sipCode } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    getSIPStatus(sipCode);
  }, []);

  const selector = state => {
    return {
      addGEResult: state.SIP.addGEResult,
      addGEMonitor: state.SIP.addGEMonitor,
      sipType: state.SIP.sipInformation?.sipType,
      sipStage: state.SIP.sipStatus?.sipStage,
      currentDecisionType: state.SIP.currentDecisionType,
      sipVersion: state.SIP.sipStatus?.sipVersion,
      sipStatus: state.SIP.sipStatus,
    };
  };

  const intl = useIntl();
  const { addGEResult, addGEMonitor, sipType, sipStage, sipVersion, sipStatus, currentDecisionType} = useSelector(selector);

  const ErrBox = () => {
    let errText = '';
    if (data) {
      if (emailAddressFormatErr) {
        errText = 'A valid email address must be filled in.';
      }  else if (
        isClick &&
        (data.jobTitle === '' ||
          data.firstName === '' ||
          data.lastName === '' ||
          data.leadGe === null ||
          data.leadGe === undefined ||
          data.emailAddress === '' ||
          (data.hasConflict === true && (data.conflictDetail === '' || data.conflictDetail?.length > 10000)) ||
          ((data.institution === '' ||
          data.country === '' ||
          (data.profileWebsite === '' && data.cv === null) ||
          data.hasConflict === null) &&
          (sipStage !== intl.messages['sipDetail.sipStageType.ideation'] ||
          currentDecisionType === intl.messages['sipDetail.decisionPanelElements.moveToSubmitted'])
          ))
      ) {
        errText = 'Please input all the mandatory fields!';
      } else if (webFormatErr&&isClick) {
        errText = 'The profile website must be a valid URL.';
      }
      // else if (isClick && orcIdFormatErr && data?.orcidId) {
      //   errText = 'Incorrect OCRID, please input like this way 0000-0000-0000-0000.';
      // }
    }
    return (
      <Box
        data-selenium-id='SIP_DetailPage_GE-GEInfomationAddModal-Box'
        sx={{
          height: '16px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '16px',
          color: '#EE5350',
          letterSpacing: '-0.084px',
          marginTop: '10px',
          display: errText === '' ? 'none' : 'block',
        }}
      >
        {errText}
      </Box>
    );
  };

  const handleAdd = () => {
    setIsClick(true);
    setTimeout(() => {
      if (
        emailAddressFormatErr ||
        (orcIdFormatErr  && data?.orcidId) || // CT7416
        webFormatErr ||
        (data &&
          (data.jobTitle === '' ||
            data.firstName === '' ||
            data.lastName === '' ||
            data.leadGe === null ||
            data.leadGe === undefined ||
            data.emailAddress === '' ||
            (data.hasConflict === true && (data.conflictDetail === '' || data.conflictDetail?.length > 10000)) ||
            ((data.institution === '' ||
            data.country === '' ||
            (data.profileWebsite === '' && data.cv === null) ||
            data.hasConflict === null) &&
            (sipStage !== intl.messages['sipDetail.sipStageType.ideation'] ||
          currentDecisionType === intl.messages['sipDetail.decisionPanelElements.moveToSubmitted'])
          )))
      ) {
        setSaving(false);
        return;
      }
      const fetchData = async () => {
        await dispatch(AddGEInfomation({ sipCode: sipCode, sipVersion: sipVersion, ...data })).then((sipNewVersion) => {
        if (sipNewVersion) {
          let newSipStatus = {
            ...sipStatus,
            sipVersion: sipNewVersion
          };
          dispatch({ type: GET_SIP_STATUS, data: {
            ...newSipStatus
          }});
        }
      });
        setSaving(false);
      };
      fetchData();
    }, 300);
  };

  useEffect(() => {
    if (addGEResult === 'OK') {
      const fetchData = async () => {
        await dispatch(getGEInformation(sipCode));
        await delay(200);
        setIsClick(false);
        setSaving(false);
        handleClose();
      };
      fetchData();

    }
  }, [addGEMonitor]);

  return (
    <SIModalPro
      isRightModel={true}
      data-selenium-id='SIP_DetailPage_GE-GEInfomationAddModal-Dialog'
      open={open}
      title={title}
      handleClose={() => {
        if (data && data.cv) {
          dispatch(DeleteCV(data.cv));
        }
        setEmailAddressFormatErr(false);
        setWebFormatErr(false);
        setIsClick(false);
        handleClose();
      }}
      titleElseElement={
        (
          <Box>
            <StateButton
              titleName='Cancel'
              isPrimary={false}
              onClick={() => {
                if (data && data.cv) {
                  dispatch(DeleteCV(data.cv));
                }
                setEmailAddressFormatErr(false);
                setWebFormatErr(false);
                setIsClick(false);
                handleClose();
              }}
            ></StateButton>
            <LoadingButton
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                height: '41px',
                padding: '12px 18px',
                ml:'10px',
                background: saving?'#6D98EE':'#0052CC',
                borderRadius: '5px',
                color: '#FFFFFF',
                width: '82px',
                height: '34px',
                textTransform: 'none',
                '&:hover':{
                  background: '#6D98EE',
                },
                '&:active':{
                  background: '#113D95',
                }
              }}
              disableElevation
              disableRipple
              id="StateButtonForPrimaryButtonSave"
              data-selenium-id="State_Button_For_Primary_Button_Save"
              onClick={() =>{
                setSaving(true);
                async function fetchData(){
                  // await delay(300);
                  await handleAdd();
                  // setSaving(false);


                }
                fetchData();

              }}
              loading={saving}
              data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions-Box-EditBtnAvailable'
            >
              Save
            </LoadingButton>
          </Box>
        )
      }
    >
      <ErrBox data-selenium-id='SIP_DetailPage_GE-GEInfomationAddModal-Dialog-ErrBox' />
      <DialogContent
        sx={{
          p: 0,
          pb: '16px',
          overflowX: 'hidden'
        }}
        data-selenium-id='SIP_DetailPage_GE-GEInfomationAddModal-Dialog-Content'
      >
        <EditContent
          data-selenium-id='SIP_DetailPage_GE-GEInfomationAddModal-Dialog-EditContent'
          setValue={setData}
          isClick={isClick}
          setEmailAddressFormatErr={setEmailAddressFormatErr}
          setWebFormatErr={setWebFormatErr}
          webFormatErr={webFormatErr}
          orcIdFormatErr={orcIdFormatErr}
          setOrcIdFormatErr={setOrcIdFormatErr}
        />
      </DialogContent>
      <DialogActions
        sx={{ padding: '0px' }}
        data-selenium-id='SIP_DetailPage_GE-GEInfomationAddModal-Dialog-Actions'
      >
        {/* <Box
          sx={{ width: '100%', height: '68px', boxSizing: 'border-box' }}
          data-selenium-id='SIP_DetailPage_GE-GEInfomationAddModal-Dialog-Actions-Box'
        >

        </Box> */}
      </DialogActions>
    </SIModalPro>
  );
}

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

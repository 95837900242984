import { checkEmptyArrayData } from '@/pages/Report/chartUtils';

import {
  LOADING,
  ERROR,
  VALID,
  NO_DATA,
  NO_SELECTION_FROM_DROPDOWN_MENU,
  NO_DISPLAYED_DATA,
} from '@/pages/Report/chartUtils/constants.js';

// useGetDiagramStatusHook(
//   unfilteredDataForBar,
//   dataForBar,
//   selectedCatagories,
//   reportType, // valueAttributeNames
//   hasCategoryFilter,
//   alertLabel,
//   loading,
//   error
// );
export default function useGetDiagramStatusHook(
  chartData,
  selectedCatagories,
  hasCategoryFilter,
  alertLabel,
  loading,
  error
) {
  let valid = VALID;
  let info = null;

  // console.log('==chartData=', chartData);
  console.log('==selectedCatagories=', selectedCatagories);
  // console.log('==valueAttributeNames=', valueAttributeNames);

  if (loading) {
    valid = LOADING;
  } else if (error) {
    valid = ERROR;
  } else if (!(Array.isArray(chartData) && chartData.length > 1)) {
    valid = NO_DATA;
    info = alertLabel;
  } else if (checkEmptyArrayData(chartData)) {
    valid = NO_DATA;
    info = alertLabel;
  } else if (
    hasCategoryFilter &&
    Array.isArray(selectedCatagories) &&
    selectedCatagories.length === 0
  ) {
    valid = NO_SELECTION_FROM_DROPDOWN_MENU;
    info = alertLabel;
  }

  return {
    valid,
    info,
  };
}

import axios from 'axios';
import Config from '../configs/Config';
import { getLocalStorage } from '@/utils/localStorageHandler';
const baseURLLink = Config.API_URL;
const cookieSet = () => {
  var ACookie = getLocalStorage('isAcceptCookie');
  if (ACookie !== undefined && ACookie) {
    return ACookie;
  } else {
    return false;
  }
};
const isCookie = cookieSet();

export default function simpleSendBeacon(url, data) {//未实现
  // window.navigator.sendBeacon(
  //   '/sip/checklist/submitted/cancelEdit',
  //   new Blob([data], {
  //     type: 'application/x-www-form-urlencoded',
  //     authorization:'aaa',
  //     baseURL: baseURLLink,
  //     timeout: 10000,
  //     withCredentials: isCookie,
  //     crossDomain: true,
  //   }),
  // );
  let queryString = '';
  QueryParams={
    stage:'Submitted',
    geCode:data.geCode,
    geId:data.geId,
    type:'cancel-edit',
  };
  if (QueryParams) {
    queryString = Object.keys(QueryParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(QueryParams[key])}`)
      .join('&');
    url += `/api/v1/sip-service/special-issue-proposals/${data.sipCode}/checkLists`;
  }
  window.navigator.sendBeacon(url, new Blob([data], {
    type: 'application/x-www-form-urlencoded',
  }));
}
import {
  Autocomplete,
  Box,
  createTheme,
  IconButton,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  ThemeProvider,
  Paper,
  FormControl
} from '@mui/material';
import { useEffect, useState } from 'react';
import { MiniBoldFont, testInputFont } from '../../../../components/FontStyle';
import SIPDetailGEInfomationEditChevronDown from '@/assets/SIPDetailGEInfomationEditChevronDown.svg';
import { useDispatch } from 'react-redux';
import TextFieldErrorIcon from '@/assets/TextFieldErrorIcon.svg';
import MyOnlyText from '@/componentsphase2/MyOnlyText';

const theme = createTheme({
  palette: {
    error: {
      main: '#FC5555',
    },
  },
});

export function ItemBlock(props) {
  const {
    title = '',
    mandatory = false,
    error = false,
    errorText = '',
    ...other
  } = props;
  return (
    <Box
      className='OPTIN-ItemBlock'
      {...other}
      data-selenium-id='CATJournal_Overview-OptInOperation-Components-OPTINItemBlockBox'
    >
      <Stack direction='column'>
        <Box data-selenium-id='CATJournal_Overview-OptInOperation-Components-OPTINItemBlock-Box'>
          <Stack
            
            direction='row'
            className='OPTIN-ItemBlock-TitleTextBox'
            data-selenium-id='CATJournal_Overview-OptInOperation-Components-OPTINItemBlock-Stack'
            sx={{
              height:'24px',
              pb:'8px',
              gap:'10px',
            }}
          >
            <Box
              sx={{
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '14px',
                lineHeight: '20px',
                height:'20px',
                color: '#596A7C',
                wordBreak: 'break-word',
                //styleName: small bold;
                letterSpacing: '0.01em',
                textAlign: 'left',

              }}
              data-selenium-id='CATJournal_Overview-OptInOperation-Components-OPTINItemBlock-Stack-Box'
            >
              {title}
            </Box>

            {mandatory ? (
              <Box
                style={{
                  ...MiniBoldFont,
                  color: '#C40000',
                }}
                data-selenium-id='CATJournal_Overview-OptInOperation-Components-MandatoryBox'
              >
                *
              </Box>
            ) : (
              <></>
            )}
          </Stack>

          <Box
            // sx={{ flexGrow: 1}}
            data-selenium-id='CATJournal_Overview-OptInOperation-Components-Box2'
          >
            {props.children}
          </Box>

          {error ? (
            <Box sx={{height:'0px'}}>
              <Box
                sx={{
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '12px',
                  lineHeight: '16px',
                  alignItems: "center",
                  color: '#EE5350',
                  letterSpacing: '-0.084px',
                  
                  mt: '2px',
                }}
                data-selenium-id='CATJournal_Overview-OptInOperation-Components-ErrorBox'
              >
                <TextFieldErrorIcon/>
                <span>{errorText}</span>
                
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>

        
      </Stack>
    </Box>
  );
}

export function OptInSelect(props) {
  const {
    id = 'test',
    dropList = [],
    value = '',
    placeholder = '',
    ...others
  } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const IconComponent = () => {
    if (open) {
      return (
        <IconButton
          disableRipple
          sx={{
            transform: 'rotate(180deg)',
            position: 'absolute',
            right: '12px',
            padding: '0px',
            backgroundColor: '#FFFFFF',
            borderRadius: '2px',
          }}
          onClick={() => {
            setOpen(false);
          }}
          data-selenium-id='CATJournal_Overview-OptInOperation-Components-OpenIconButton'
        >
          <SIPDetailGEInfomationEditChevronDown />
        </IconButton>
      );
    } else {
      return (
        <IconButton
          disableRipple
          onClick={() => {
            setOpen(true);
          }}
          sx={{ position: 'absolute', right: '12px', padding: '0px',backgroundColor: '#FFFFFF',borderRadius: '2px',}}
          data-selenium-id='CATJournal_Overview-OptInOperation-Components-CloseIconButton'
        >
          <SIPDetailGEInfomationEditChevronDown />
        </IconButton>
      );
    }
  };
  function hasValue(value) {
    if (value) {
      if (value instanceof Array) {
        // 数组情况，长度非0， 零号元素非空
        return value.length > 0 && value[0] && value[0] !== '';
      } else return value !== '';
    } else {
      return false;
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Select
        {...others}
        sx={{
          '&': { width: '100%' , height: '40px'},
          '& .MuiSelect-select': {
            background: '#FFFFFF',
            borderRadius: '5px',
            boxSizing: 'border-box',
            padding: '8px 12px',
            ...testInputFont,
            color: hasValue(value) ? '#505050' : '#C5C4C4',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #D8D9DA',
          },
        }}
        id={id}
        data-selenium-id={id}
        value={value}
        IconComponent={IconComponent}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        displayEmpty
        MenuProps={{
          sx: {
            zIndex: 1401,  // 设置较大的 z-index 值
            '& li.Mui-selected': {
              backgroundColor: '#FFFFFF',  // 设置已选中属性的背景颜色为 #FFFFFF
            },
            '& li.Mui-selected:hover': {
              backgroundColor: "rgba(0, 0, 0, 0.04)",  // 设置已选中属性悬停时的背景颜色为 #FFFFFF
            },
          },
        }}
        renderValue={value => {
          return hasValue(value) ? value : placeholder;
        }}
      >
        {dropList.map((item, index) => (
          <MenuItem value={item} key={`${id}-${index}`}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </ThemeProvider>
  );
}

export const OptInInputBase = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    borderRadius: '4px',
    boxSizing: 'border-box',
    padding: '8px 12px',
    height: '40px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '0px 0px',
    ...testInputFont,
    color: '#505050',
    height: '17px',
    '&::placeholder': {
      ...testInputFont, //lineheight 不满足ui 要求
      color: '#C5C4C4',
      // lineHeight: '120%',
      opacity: '1',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #D8D9DA',
  },
});

export function OptInAutoComplete(props) {
  //注意 这个inputValue 是value的显示值
  const {
    id,
    loadDropList,
    inputValue,
    needEmptyCheck = false,
    errorMonitor = false,
    value = '',
    secondParameter = null,
    placeholder = '',
    inputSX = {},
    ...other
  } = props;

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(null);
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    if (loadDropList !== undefined) {
      setLoading(true);
      clearTimeout(timer);
      setTimer(
        setTimeout(async () => {
          async function loadOptions() {
            if (secondParameter === null) {
              await dispatch(loadDropList(inputText));
            } else {
              await dispatch(loadDropList(inputText, secondParameter));
            }
          }
          loadOptions().then(_r => {
            setLoading(false);
          });
        }, 300)
      );
    }
  }, [inputText]);

  useEffect(() => {
    if (!open || loadDropList === undefined) {
      return;
    }
    async function loadOptions() {
      if (secondParameter === null) {
        await dispatch(loadDropList(inputText));
      } else {
        await dispatch(loadDropList(inputText, secondParameter));
      }
    }

    loadOptions().then(_r => {
      setLoading(false);
    });
  }, [open]);

  useEffect(() => {
    setInputText(inputValue ? inputValue : '');
  }, [inputValue]);

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        // limitTags={6}
        // PaperComponent={CustomPaper}
        disablePortal
        id={'autocomplete-' + id}
        data-selenium-id={'autocomplete-' + id}
        value={value}
        sx={{
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '0px',
          },
          '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
            right: '12px',
          },
        }}
        disableClearable
        ListboxProps={{
          style: { maxHeight: 'calc(6*38px)' ,gap:6, mt:6},
        }}
        filterOptions={x => x} //去掉组件默认的模糊查询
        popupIcon={
          <IconButton disableRipple
            sx={{  
              backgroundColor: '#FFFFFF',
              borderRadius: '2px',
              p:0, 
            }}>
            <SIPDetailGEInfomationEditChevronDown />
          </IconButton>
        }
        renderInput={params => (
          <OptInInputBase
            {...params}
            onChange={e => {
              setInputText(e.target.value);
            }}
            placeholder={placeholder}
            sx={{ maxWidth: '400px', height:'40px', ...inputSX }}
            error={
              (errorMonitor &&
                needEmptyCheck &&
                (inputValue === '' || inputValue === null)) ||
              props.error
            }
          />
        )}
        loading={loading}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        {...other}
      />
    </ThemeProvider>
  );
}

export const OptInTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    borderRadius: '4px',
    boxSizing: 'border-box',
    padding: '0px 0px 0px 0px',
    overflow: 'hidden',
    ...testInputFont,
    letterSpacing: '-0.011em',
    textAlign: 'left',
    height: '72px',
  },
  '& .MuiOutlinedInput-input': {
    overflowY: 'scroll',
    padding: '9px 12px 9px 12px',
    ...testInputFont,
    color: '#262E35',
    // height: '20px',
    '&::placeholder': {
      ...testInputFont, //lineheight 不满足ui 要求
      color: '#BCC5CF',
      height: '18px',
      opacity: '1',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #D8D9DA',
  },
});
export const OptInTextArea = props => {
  const {
    id = 'test',
    value = '',
    placeholder = 'Input text',
    needEmptyCheck = true,
    limitLength,
    setValue = () => {},
    errorMonitor = false, // 用于监听 某一些错误的触发 true为要显示该错误，false为不显示
  } = props;

  return (
    <ThemeProvider theme={theme}>
      <FormControl variant='outlined' sx={{width: '400px'}} fullWidth >
        <MyOnlyText
          id={id}
          data-selenium-id={id}
          value={value}
          multiline
          maxRows={3}
          minRows={3}
          width='100%'
          placeholder={placeholder}
          onChange={e => {
            setValue(e.target.value);
          }}
          isError={
            (errorMonitor && needEmptyCheck && value === '') ||
            value.length > limitLength
          }
        />
      </FormControl>
    </ThemeProvider>
  );
};

export const MONTH = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const A_AND_A_SI_ACQUISITION_YTD_TARGET =
  'SI Acquisition Progress Towards FY Target in';
export const A_AND_A_SI_ACQUISITION = 'SI Acquisition in';
export const SUBJECT_GROUP = 'Subject Group';
export const BUSINESS_DIVISION = 'Business Division';
export const CE_LEVEL = 'CE';
export const CAT_GROUP_LEVEL = 'CAT Group';

export function getCurrentTime() {
  // format: _MMDDYYYY_HHMMSS
  let yy = new Date().getFullYear();
  let mm =
    new Date().getMonth() + 1 < 10
      ? '0' + new Date().getMonth()
      : new Date().getMonth();
  let dd =
    new Date().getDate() < 10
      ? '0' + new Date().getDate()
      : new Date().getDate();
  let hh =
    new Date().getHours() < 10
      ? '0' + new Date().getHours()
      : new Date().getHours();
  let mf =
    new Date().getMinutes() < 10
      ? '0' + new Date().getMinutes()
      : new Date().getMinutes();
  let ss =
    new Date().getSeconds() < 10
      ? '0' + new Date().getSeconds()
      : new Date().getSeconds();
  return '_' + mm + dd + yy + '_' + hh + mf + ss;
}

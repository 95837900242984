import { Box, Grid } from '@mui/material';
import ReportExportMenuButton from '../../ReportSpecificComponents/ReportExportMenuButton';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  selectDate as selectOAvsTotalDate,

} from '../../../../actions/SIMT-Report/Report/ReportAction';

import { DatePickerStack } from '../../GenerationDate';
import styles from '../../header-switch.module.scss';
import ExportAllDialog from '../../ExportAllDialog';
import {
  exportOAvsTotalCsvWithFilenameFromBackEnd,
  exportOAvsTotalExcelWithFilenameFromBackEnd
} from "@/actions/SIMT-Report/Report/Export";
import {GetAcquiredActiveLastActionDate} from "@/actions/SIMT-Report/Report/ReportAcquiredAndActive";

function PageHead({ showTable, setShowTable }) {
  const { OAvsTotalSelectDate, lastActionDate } = useSelector(state => {
    return {
      OAvsTotalSelectDate: state.Report.selectDate,
      lastActionDate: state.Report.AcquiredActiveReportGenenateDate,
    };
  });
  const GenerationDateAndTime =
    'Below reports are generated on ' + lastActionDate + ' (UTC time). ';

  const [selectMonth, setSelectMonth] = useState(new Date());
  const [months, setMonths] = useState([]);
  const dispatch = useDispatch();
  const currentDate = new Date();
  const [diagramOpen, setDiagramOpen] = useState(false);

  useEffect(() => {
    if (OAvsTotalSelectDate != null) {
      var Year = OAvsTotalSelectDate.split('-')[0];
      var Month = OAvsTotalSelectDate.split('-')[1] - 1;
      setSelectMonth(new Date(Year, Month));
    }
  }, [OAvsTotalSelectDate]);

  const exportExcelWithFilenameFromBackEnd = () => {
    var selectDate =
      Number(OAvsTotalSelectDate.split('-')[0]) * 100 +
      Number(OAvsTotalSelectDate.split('-')[1]);
    dispatch(exportOAvsTotalExcelWithFilenameFromBackEnd(selectDate));
  };

  const exportCsvWithFilenameFromBackEnd = () => {
    var selectDate =
      Number(OAvsTotalSelectDate.split('-')[0]) * 100 +
      Number(OAvsTotalSelectDate.split('-')[1]);
    dispatch(
      exportOAvsTotalCsvWithFilenameFromBackEnd(
        selectDate,
        'subject_group_table'
      )
    );
    dispatch(
      exportOAvsTotalCsvWithFilenameFromBackEnd(
        selectDate,
        'business_division_table'
      )
    );
  };
  const handleExportExcel = type => {
    if (type === 'filter') {
    } else {
      exportExcelWithFilenameFromBackEnd();
    }
  };

  const handleExportCSV = type => {
    if (type === 'filter') {
    } else {
      exportCsvWithFilenameFromBackEnd();
    }
  };
  const getMonthList = () => {
    let temMonths = [];
    if (months.length === 0) {
      var year = currentDate.getFullYear();
      var month = currentDate.getMonth() + 1;
      for (var i = 0; i < 4; i++) {
        var tmpYear = year - i;
        if (i === 0) {
          for (let j = month; j > 0; j--) {
            let tmpMonth = j;
            temMonths.push(tmpYear + '-' + tmpMonth);
          }
        } else {
          for (let j = 12; j > 0; j--) {
            let tmpMonth = j;
            temMonths.push(tmpYear + '-' + tmpMonth);
          }
        }
      }
    }
    setMonths(temMonths);
  };

  const handleDateChange = value => {
    setSelectMonth(value);
    var year = value.getFullYear();
    var month = value.getMonth() + 1;
    let date = year + '-' + month;
    dispatch(selectOAvsTotalDate(date));
    dispatch(GetAcquiredActiveLastActionDate(date, false));
  };

  useEffect(() => {
    getMonthList();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth() + 1;
    var newDate = year + '-' + month;
    if (OAvsTotalSelectDate === null) {
      dispatch(selectOAvsTotalDate(newDate));
      dispatch(GetAcquiredActiveLastActionDate(newDate, false));
    }
  }, []);

  return (
    <Box
      sx={{
        marginTop: '16px',
        marginBottom: '16px',
      }}
      data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-PageHead-Box'
    >
      <ExportAllDialog
        open={diagramOpen}
        handleClose={() => setDiagramOpen(false)}
        handleCSV={handleExportCSV}
        handleExcel={handleExportExcel}
        filterData={[]}
      />
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-PageHead-Box-Grid'
      >
        <DatePickerStack
          id='Report_AcquiredAndActiveSi-OAvsTotal-PageHead-Box-Stack'
          selectedMonth={selectMonth}
          handleDateChange={handleDateChange}
          lastActionDate={lastActionDate}
          showTable={showTable}
          GenerationDateAndTime={GenerationDateAndTime}
        />
        <Grid
          data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-PageHead-Box-Grid-Grid2'
          className={styles['action-buttons-container']}
        >
          {showTable && (
            <ReportExportMenuButton
              sx={{ height: '32px', marginRight: '24px' }}
              id='reportSipipelineExportButton'
              mode={1}
              onClick={() => setDiagramOpen(true)}
            />
          )}
          <span className={styles['switch']}>
            <span
              id='show-table-button'
              className={styles[showTable ? 'selected' : '']}
              onClick={() => setShowTable(true)}
            >
              Table
            </span>
            <span
              id='show-diagram-button'
              className={styles[!showTable ? 'selected' : '']}
              onClick={() => setShowTable(false)}
            >
              Diagram
            </span>
          </span>
        </Grid>
      </Grid>
    </Box>
  );
}
export default PageHead;

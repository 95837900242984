/**
 * chart group names & chart level names
 */
export const SPECIAL_ISSUE_SI_PIPELINE = 'Special Issue (SI) Pipeline';
export const SPECIAL_ISSUE_SI_PIPELINE_OA =
  'Special Issue (SI) Pipeline for OA Journals and All Journals';
export const SUBJECT_GROUP = 'Subject Group';
export const BUSINESS_DIVISION = 'Business Division';
export const CE_LEVEL = 'CE';
export const CAT_GROUP_LEVEL = 'CAT Group';

/**
 * Stage name for chart display (including legends), and dropdown options
 */
export const IDEA_OR_PROPOSAL = 'Idea or Proposal';
export const ACQUIRED = 'Acquired';
export const PAPER_COMMISSION = 'Paper Commission';
export const SUBMISSION_AND_REVIEW = 'Submission and Review';
export const PRODUCTION = 'Production';
export const PUBLISHED = 'Published';
export const IDEA_OR_PROPOSAL_OA = 'Idea or Proposal OA';
export const ACQUIRED_OA = 'Acquired OA';
export const PAPER_COMMISSION_OA = 'Paper Commission OA';
export const SUBMISSION_AND_REVIEW_OA = 'Submission and Review OA';
export const PRODUCTION_OA = 'Production OA';
export const PUBLISHED_OA = 'Published OA';

/**
 * regular SI Stages
 */
export const SI_STAGES = [
  IDEA_OR_PROPOSAL,
  ACQUIRED,
  PAPER_COMMISSION,
  SUBMISSION_AND_REVIEW,
  PRODUCTION,
  PUBLISHED,
];
function jsonToCSS(json) {
  let cssString = '';
  for (let prop in json) {
    if (json.hasOwnProperty(prop)) {
      cssString += `${prop}: ${json[prop]}; `;
    }
  }
  return cssString;
}
const stageFontStyle = {
  'font-family': 'Inter',
  'font-size': '10px',
  'font-weight': '800',
  'line-height': '14px',
  color: ' #596A7C',
  'text-align': 'left',
};
const stageCountFontStyle = {
  ...stageFontStyle,
  'font-weight': 400,
};
const percentageFontStyle = {
  padding: '2px 4px',
  'font-family': 'Open Sans',
  'font-size': '10px',
  'font-weight': '700',
  'line-height': '10px',
  'text-align': 'center',
  color: '#FFFFFF',
  'border-radius': '2px',
  background: '#596A7C',
};

export const JournalModelPieTooltipFormatter = params => {
  return `<div style='font-family:'Inter'; padding: 0; margin: 0;display:flex;align-items:center;'>
      <span style='${jsonToCSS(stageFontStyle)}'>${params.data[0]}:</span>
      <span style='${jsonToCSS(stageCountFontStyle)}'>${params.data[2]}</span>
      <span style='${jsonToCSS(percentageFontStyle)}'>${params.data[1]}</span>
    </div>`;
};
/**
 * 关于复选框选择相关的Action
 */

import { axios_instance } from '@/utils/axios_instance';
// create Redux action types
export const ADD_SELECTED = 'ADD_SELECTED';
export const ADD_ALL_SELECTED = 'ADD_ALL_SELECTED';
export const REMOVE_SELECTED = 'REMOVE_SELECTED';
export const REMOVE_ALL_SELECTED = 'REMOVE_ALL_SELECTED';
export const SELECT_ALL = 'SELECT_ALL';
export const CANCEL_SELECT_ALL = 'CANCEL_SELECT_ALL';
export const SET_IS_ALL_SELECTED = 'SET_IS_ALL_SELECTED';
export const ADD_USER_SELECTED = 'ADD_USER_SELECTED';
export const ADD_USER_SELECTED_BATCH = 'ADD_USER_SELECTED_BATCH';
export const REMOVE_USER_SELECTED = 'REMOVE_USER_SELECTED';
export const REMOVE_ALL_USER_SELECTED = 'REMOVE_ALL_USER_SELECTED';
export const SELECT_ALL_USER = 'SELECT_ALL_USER';
export const SET_USER_IS_ALL_SELECTED = 'SET_USER_IS_ALL_SELECTED';
export const CANCEL_SELECT_ALL_USER = 'CANCEL_SELECT_ALL_USER';
export const REMOVE_USER_SELECTED_INFO = 'REMOVE_USER_SELECTED_INFO';
export const REMOVE_SELECTED_INFO = 'REMOVE_SELECTED_INFO';

// Create Redux action creators that return an action
export const addSelected = selected => ({
  type: ADD_SELECTED,
  data: selected,
});

export const addAllSelected = selected => ({
  type: ADD_ALL_SELECTED,
  data: selected,
});

export const removeSelected = selected => ({
  type: REMOVE_SELECTED,
  data: selected,
});

export const removeAllSelected = () => ({
  type: REMOVE_ALL_SELECTED,
  data: [],
});

export const removeSelectedInfo = selected => ({
  type: REMOVE_SELECTED_INFO,
  data: selected,
});

export const selectAll = () => ({
  type: SELECT_ALL,
});

export const setIsAllSelected = isAllSelected => ({
  type: SET_IS_ALL_SELECTED,
  data: isAllSelected,
});

export const cancelSelectAll = () => ({
  type: CANCEL_SELECT_ALL,
});

export const addUserSelected = selected => ({
  type: ADD_USER_SELECTED,
  data: selected,
});

export const addUserSelectedBatch = selected => ({
  type: ADD_USER_SELECTED_BATCH,
  data: selected,
});

export const removeUserSelected = selected => ({
  type: REMOVE_USER_SELECTED,
  data: selected,
});

export const removeAllUserSelected = () => ({
  type: REMOVE_ALL_USER_SELECTED,
});

export const removeUserSelectedInfo = selected => ({
  type: REMOVE_USER_SELECTED_INFO,
  data: selected,
});

export const selectAllUser = () => ({
  type: SELECT_ALL_USER,
});

export const setUserIsAllSelected = isAllSelected => ({
  type: SET_USER_IS_ALL_SELECTED,
  data: isAllSelected,
});

export const cancelSelectAllUser = () => ({
  type: CANCEL_SELECT_ALL_USER,
});

import {
  BarAndLineChartOptions,
  LEGEND_STYLE,
} from '@/pages/Report/chartUtils/chartOptions/BarAndLineChartOptions.js';
import {
  BD_CHART_COLOR,
  FY_TRENDS_OF_SI_ACQUISITION,
} from '../../Constants/index.js';

/**
 * overwrite the default configuration
 */
const LEGEND_POS_ORIGIN = 216;
const LEGEND_POS_OFFSET = -180;

export class AAndAFYTrendsBDOption extends BarAndLineChartOptions {
  constructor(headerToSelectColor, bdOO, bdOA) {
    super();
    // overwrite the default properties
    this.headerToSelectColor = headerToSelectColor;
    this.bdOO = bdOO;
    this.bdOA = bdOA;
    this.legend_pos = LEGEND_POS_ORIGIN;
  }

  getBarSeries() {
    const oo = BD_CHART_COLOR[this.headerToSelectColor].oo;
    const oa = BD_CHART_COLOR[this.headerToSelectColor].oa;
    return [
      {
        ...this.barSerieOption,
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'inside',
              textStyle: {
                color: '#FFFFFF',
                fontSize: 10,
              },
              formatter: params => {
                let position = params.encode.y;
                if (params.data[position] == 0) {
                  return '';
                }
                return params.data[position];
              },
            },
            borderRadius: [4, 4, 0, 0],
          },
        },
        name: this.bdOO,
        stack: 'oo',
        seriesLayoutBy: 'row',
        color: oo.barColor,
        barMaxWidth: 35,
        barMinHeight: 3,
        encode: {
          x: 0,
          y: 1,
        },
      },
      {
        ...this.barSerieOption,
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'inside',
              textStyle: {
                color: '#FFFFFF',
                fontSize: 10,
              },
              formatter: params => {
                let position = params.encode.y;
                if (params.data[position] == 0) {
                  return '';
                }
                return params.data[position];
              },
            },
            borderRadius: [4, 4, 0, 0],
          },
        },
        name: this.bdOA,
        stack: 'oa',
        seriesLayoutBy: 'row',
        color: oa.barColor,
        barMaxWidth: 35,
        barMinHeight: 3,
        barGap: '0%',
        encode: {
          x: 0,
          y: 2,
        },
      },
    ];
  }

  getTooltip() {
    return [
      {
        ...this.tooltip,
        formatter: params => {
          let position = params.encode.y;
          let tooltipName =
            this.headerToSelectColor == FY_TRENDS_OF_SI_ACQUISITION
              ? ' SI Acquisition'
              : ' Active SI';
          let dataStr = `<div style="padding: 0; margin: 0;">
            <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 0 0 2.5px 0;">${
              params.name + tooltipName
            }</p>
            <p style="font-size: 10px; font-weight: 400;color: #848484; line-height: 14px; margin: 0;">${
              params.seriesName
            }&nbsp;:&nbsp;${params.data[position]}</p>
          </div>`;
          return dataStr;
        },
      },
    ];
  }

  setLegendSG(legendSG) {
    this.legendSG = legendSG;
    return this;
  }

  setLegendBD(legendBD) {
    this.legendBD = legendBD;
    return this;
  }

  getSelectedLegend() {
    const newTextStyle = {
      ...LEGEND_STYLE.textStyle,
      padding: [0, 5, 0, 0],
    };

    return [
      {
        ...LEGEND_STYLE,
        textStyle: newTextStyle,
        data: [
          {
            name: this.legend[1],
          },
        ],
        left: this.legend_pos + 230,
        bottom: 45,
      },
      {
        ...LEGEND_STYLE,
        textStyle: newTextStyle,
        data: [
          {
            name: this.legend[0],
          },
        ],
        left: this.legend_pos,
        bottom: 45,
      },
    ];
  }

  setWidth(width) {
    this.width = width;

    if (width && width !== '100%') {
      const half_width = width / 2;
      this.legend_pos = half_width + LEGEND_POS_OFFSET;
    }

    return this;
  }
}

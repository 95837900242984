import { useEffect, useState } from 'react';
import { InputLabel, Grid, Stack, TextField } from '@mui/material';
import ReportExportButton from '../../ReportSpecificComponents/ReportExportMenuButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDate as selectArticlesOAvsTotalDate,

} from '@/actions/SIMT-Report/Report/ReportAction';
import { DatePickerStack, GenerationDate } from '../../GenerationDate';
import * as PropTypes from 'prop-types';
import styles from '../../header-switch.module.scss';
import ExportAllDialog from '../../ExportAllDialog';
import ReportExportMenuButton from '../../ReportSpecificComponents/ReportExportMenuButton';
import {
  exportArcticlesOAvsTotalCSV,
  exportArticlesOAvsTotalCSVWithFilenameFromBackEnd,
  exportArticlesOAvsTotalExcel,
  exportArticlesOAvsTotalExcelWithFilenameFromBackEnd
} from "@/actions/SIMT-Report/Report/Export";
import {GetArticleLastActionDate} from "@/actions/SIMT-Report/Report/ReportArticle";

export function HeadPageOAvsTotal({ showTable, setShowTable }) {
  const { selectMonthDate, ArticleReportGenenateDate } = useSelector(state => {
    return {
      selectMonthDate: state.Report.selectDate,
      ArticleReportGenenateDate: state.Report.ArticleReportGenenateDate,
    };
  });
  const GenerationDateAndTime =
    'Below reports are generated on ' +
    ArticleReportGenenateDate +
    ' (UTC time). ';
  const [selectMonth, setSelectMonth] = useState(new Date());
  const [months, setMonths] = useState([]);
  const currentDate = new Date();
  const dispatch = useDispatch();
  const getMonthList = () => {
    if (months.length === 0) {
      var year = currentDate.getFullYear();
      var month = currentDate.getMonth() + 1;
      for (var i = 0; i < 4; i++) {
        var tmpyear = year - i;
        if (i === 0) {
          for (var j = month; j > 0; j--) {
            months.push('' + tmpyear + '-' + '' + j);
            setMonths(months);
          }
        } else {
          for (var k = 12; k > 0; k--) {
            months.push('' + tmpyear + '-' + '' + k);
            setMonths(months);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (selectMonthDate != null) {
      var Year = selectMonthDate.split('-')[0];
      var Month = selectMonthDate.split('-')[1] - 1;
      setSelectMonth(new Date(Year, Month));
    }
  }, [selectMonthDate]);

  const handleDateChange = value => {
    setSelectMonth(value);
    var year = value.getFullYear();
    var month = value.getMonth() + 1;
    let date = year + '-' + month;

    dispatch(selectArticlesOAvsTotalDate(date));
    dispatch(GetArticleLastActionDate(date, false));
  };

  const exportExcelWithFilenameFromBackEnd = () => {
    dispatch(
      exportArticlesOAvsTotalExcelWithFilenameFromBackEnd(selectMonthDate)
    );
  };

  const exportCsvWithFilenameFromBackEnd = () => {
    dispatch(
      exportArticlesOAvsTotalCSVWithFilenameFromBackEnd(
        selectMonthDate,
        'subject_group_table'
      )
    );
    dispatch(
      exportArticlesOAvsTotalCSVWithFilenameFromBackEnd(
        selectMonthDate,
        'business_division_table'
      )
    );
  };

  useEffect(() => {
    getMonthList();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth() + 1;
    var newDate = '' + year + '-' + month;
    if (selectMonthDate === null) {
      dispatch(selectArticlesOAvsTotalDate(newDate));
      dispatch(GetArticleLastActionDate(newDate, false));
    }
  }, []);

  return (
    <>
      <ArticleOAvsTotalHead
        showTable={showTable}
        setShowTable={setShowTable}
        selectedMonth={selectMonth}
        handleDateChange={handleDateChange}
        lastActionDate={ArticleReportGenenateDate}
        generationDateAndTime={GenerationDateAndTime}
        operation={exportCsvWithFilenameFromBackEnd}
        operation1={exportExcelWithFilenameFromBackEnd}
      />
    </>
  );
}

export function ArticleOAvsTotalHead({
  showTable,
  setShowTable,
  selectedMonth,
  handleDateChange,
  lastActionDate,
  generationDateAndTime,
  operation,
  operation1,
}) {
  const [exportAllDialogOpen, setExportAllDialogOpen] = useState(false);

  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      data-selenium-id='Report_Articles-OAvsTotal-Head-Grid'
      sx={{
        marginTop: '16px',
        marginBottom: '16px',
      }}
    >
      <ExportAllDialog
        open={exportAllDialogOpen}
        handleClose={() => setExportAllDialogOpen(false)}
        filterData={[]}
        handleCSV={operation}
        handleExcel={operation1}
      />
      <DatePickerStack
        id='Report_Articles-OAvsTotal-Head-Grid-Grid'
        selectedMonth={selectedMonth}
        handleDateChange={handleDateChange}
        lastActionDate={lastActionDate}
        showTable={true}
        GenerationDateAndTime={generationDateAndTime}
      />
      <Grid
        className={styles['action-buttons-container']}
        // sx={{ p: '0px 30px 0px 6px' }}
        data-selenium-id='Report_Articles-OAvsTotal-Head-Grid-ReportExportButtonGrid'
      >
        {showTable ? (
          <ReportExportMenuButton
            sx={{ height: '32px', marginRight: '24px' }}
            id='reportSipipelineExportButton'
            mode={1}
            // label='common.Export'
            onClick={() => {
              setExportAllDialogOpen(true);
            }}
          />
        ) : null}

        <span className={styles['switch']}>
          <span
            id='show-table-button'
            className={styles[showTable ? 'selected' : '']}
            onClick={() => setShowTable(true)}
          >
            Table
          </span>

          <span
            id='show-diagram-button'
            className={styles[!showTable ? 'selected' : '']}
            onClick={() => setShowTable(false)}
            style={{ maxHeight: '32px' }}
          >
            Diagram
          </span>
        </span>
      </Grid>
    </Grid>
  );
}

ArticleOAvsTotalHead.propTypes = {
  selectedMonth: PropTypes.any,
  handleDateChange: PropTypes.func,
  lastActionDate: PropTypes.string,
  generationDateAndTime: PropTypes.string,
  operation: PropTypes.func,
  operation1: PropTypes.func,
};

export default HeadPageOAvsTotal;

import { Box, Divider, Table, TableContainer, Grid, Stack } from '@mui/material';
import { useEffect, useRef,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPageNum, setPageSize } from '../../../../actions/SIMT-SIP/SIP/SIPAction';
import { TableFooter2 } from '../../../../components/DataTable';
import SIPOverviewTableBody from './SIPOverviewTableBody';
import SIPOverviewTableHead from './SIPOverviewTableHead';
import * as React from 'react';
import { contextHeightChanged } from '../../../../actions/CommonAction';
import ScrollBarY from '../../../../componentsphase2/ScrollBar/ScrollBarY';
import { TableBox2 } from "../../../Report/ReportSpecificComponents/SpecialColorTableHeader";

const SIPOverviewTable = React.forwardRef((props, ref) => {
  const {showFilter = false, loading, setLoading} = props;
  const dispatch = useDispatch();
  const selector = state => {
    return {
      pageNum: state.SIP.pageNum,
      pageSize: state.SIP.pageSize,
      sipOverviewSeletedData: state.SIP.sipOverviewSeletedData,
      sipOverviewDataCount: state.SIP.sipOverviewDataCount,
      sipOverviewData: state.SIP.sipOverviewData,
      siderOpen: state.UserManage.siderOpen,
      // sipOverviewSeletedData: state.SIP.sipOverviewSeletedData,
    };
  };
  const { pageNum, pageSize, sipOverviewSeletedData, sipOverviewDataCount,sipOverviewData,siderOpen } =
    useSelector(selector);
  const [sipNum, setSipNum] = useState(0);
  const [ssipNum, setSsipNum] = useState(0);
  useEffect(() => {
    function countOccurrences() {
      let sipNum = 0;
      let ssipNum = 0;
      
      // 遍历数组中的每个元素
      for (let i = 0; i < sipOverviewSeletedData.length; i++) {
        const element = sipOverviewSeletedData[i];
        
        // 判断元素是否以"SIP"
        if (element.startsWith("SIP")) {
          sipNum++;
        }else{
          ssipNum++;
        }
      }
      
      setSipNum(sipNum);
      setSsipNum(ssipNum);
    }

    countOccurrences();
  }, [sipOverviewSeletedData]);
  

  /******************TableFooter 相关函数************************8 */
  const handlePageSizeChange = e => {
    dispatch(setPageSize(e.target.value));
  };

  const handlePageNumChange = (_event, value) => {
    dispatch(setPageNum(value));
  };

  /*************************************************************** */

  return (
    <Box
      ref={ref}
      data-selenium-id='SIPPage_Overview-Table-index-Box'
    >
      {/* <Grid item md={3} alignItems='center' justifyContent='center'>
          {/* <span id="tableFooterSelectedText" style={{ fontSize: "small", textAlign: 'center' }}> 
          <Stack
            direction='row'
            justifyContent='start'
            alignItems='center'
            spacing={1}
            sx={{marginBottom: '6px'}}
          >
            <span
              style={{
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '18px',
                color: '#262E35',
              }}
            >
              {/* <FormattedMessage
                id='table.selectedText'
                values={{ num: selected.length }}
              /> 
              Selected Item(s):
            </span>
            <span
              style={{
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '18px',
                color: '#262E35',
              }}
            >
              {sipOverviewSeletedData.length}
            </span>
          </Stack>
          {/* </span> */}
        {/* </Grid> */}
      <TableBox2
        dataList={[sipOverviewData]}
        tableKey={'SIPOverview'}
        footerHeight={0}
        containerType={TableContainer}
        containerSelector={'.SIPPage_Overview-TableContainer'}
        viewTag={'Table'}
        delay={300}
        sx={{
          position: 'relative',
          // maxHeight: issueTableMaxHeight,
          overflowX: 'hidden',
          overflowY: 'hidden',
          // height: 'calc(100vh - 291px)',
          // width: 'calc(100vw - 140px)',
          width: 'fit-content',
          borderRadius: '8px 8px 0px 0px',
          border: '1px solid #DFE4E8',
          padding: 0,
          '& .scroll-bar-container-box': {
            right: '3px',
            width: '15px',
          },
        }}
      >
        <TableContainer
          data-selenium-id='SIPPage_Overview-Table-index-ScreenSizeContainer'
          className='table-screen-size SIPPage_Overview-TableContainer'
          sx={{
            height: 'calc(100vh - 321px - 45px)',
            width: 
            siderOpen? 
              showFilter?'calc(100vw - 140px - 158px - 600px + 23px )':'calc(100vw - 140px - 158px + 23px )' :
              showFilter?'calc(100vw - 140px - 600px + 23px )':'calc(100vw - 140px + 23px )',
            // borderRadius: '8px 8px 0px 0px',
            ...(!loading && sipOverviewData.length === 0 && {
              // table scroll bar
              height: '122px',
            }),
          }}
        >
          <Table
            data-selenium-id='SIPPage_Overview-Table-index-InnerTable'
            stickyHeader
            aria-label='simple table'
            sx={{
              letterSpacing: '0.01em',
              color: '#262E35',
              // border: '1px solid #DFE4E8',
              minWidth: 'fit-content',
              minHeight: 'fit-content',
            }}
          >
            <SIPOverviewTableHead selectedNum={sipOverviewSeletedData.length} sipNum={sipNum} ssipNum={ssipNum}></SIPOverviewTableHead>
            <SIPOverviewTableBody 
              loading={loading}
              setLoading={setLoading}
            ></SIPOverviewTableBody>
           </Table>
        </TableContainer>
      </TableBox2>
      <Box data-selenium-id='SIPPage_Overview-Table-index-InnerBox'>
        <Divider
          data-selenium-id='SIPPage_Overview-Table-index-Divider'
          sx={{
            width: siderOpen?  
              showFilter?'calc(100vw - 140px - 158px - 600px + 2px)':'calc(100vw - 140px - 158px + 2px)' :
              showFilter?'calc(100vw - 140px - 600px + 2px)':'calc(100vw - 140px + 2px)'
          }}
        />
        <TableFooter2
          // width={siderOpen? 
          //   showFilter?'calc(100vw - 140px - 158px - 600px + 2px)':'calc(100vw - 140px - 158px + 2px)' :
          //   showFilter?'calc(100vw - 140px - 600px + 2px)':'calc(100vw - 140px + 2px)'}
          data-selenium-id='SIPPage_Overview-Table-index-Footer'
          numSelected={sipOverviewSeletedData.length}
          Page={pageNum}
          perPageNumber={pageSize}
          perPageList={[20, 50, 100]}
          count={sipOverviewDataCount ?? 0}
          handlePerPageChange={handlePageSizeChange}
          handlePageChange={handlePageNumChange}
          backgroundColor='#f1f3f5'
          showSelected={true}
        />
      </Box>
    </Box>
  );
});


export default SIPOverviewTable;

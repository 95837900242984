import { Divider, IconButton, Button, OutlinedInput } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import EditIcon from '../../../assets/EditButton.svg';
import CannotEditIcon from '../../../assets/CannotEditButton.svg';
import EditCancel from '../../../assets/EditCancel.svg';
import EditSave from '../../../assets/EditSave.svg';
import EditNotSave from '../../../assets/EditNotSave.svg';
import FoldIcon from '../../../assets/FoldIcon.svg';
import UnfoldIcon from '../../../assets/UnfoldIcon.svg';
import Description from '../../../components/Description';
import { useDispatch, useSelector } from 'react-redux';
import {
  EditClear,
} from '../../../actions/SIMT-SI/CRContact/CRContactAction';
import {
  editAMEDetail,
  editEOContact,
  editMEDetail,
  getCRDetailInfomationList
} from "@/actions/SIMT-SI/CRContact/Journal";
/**
 * @description
 * @author LiuXin
 * @date 2022/4/28
 * @param {*} props 参数
 * @returns 返回一个CR的展示块
 */
function CRshowBlock(props) {
  const { title, journalcode, CRdetailInfoObj } = props;
  const [isFold, setIsFold] = useState(title !== 'EO Contact');
  const [isEdit, setIsEdit] = useState(false);
  const [cannotEdit, setCannotEdit] = useState(false);
  const [cannotEditBtn, setCannotEditBtn] = useState(false); //能否点击edit按钮
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  let fleshFlag = false; //判断是否进行了更新操作

  const [flagEmailPattern, setFlagEmailPattern] = useState(false);
  const [flagEmailNotNull, setFlagEmailNotNull] = useState(false);
  const [flagEmailTooLong, setFlagEmailTooLong] = useState(false);
  const [flagFirstNameNotNull, setFlagFirstNameNotNull] = useState(false);
  const [flagFirstNameTooLong, setFlagFirstNameTooLong] = useState(false);
  const [flagLastNameNotNull, setFlagLastNameNotNull] = useState(false);
  const [flagLastNameTooLong, setFlagLastNameTooLong] = useState(false);

  useEffect(() => {
    if (title === 'EO Contact') {
      if (flagEmailPattern || flagEmailNotNull || flagEmailTooLong) {
        setCannotEdit(true);
      } else {
        setCannotEdit(false);
      }
    } else {
      if (
        flagEmailPattern ||
        flagEmailNotNull ||
        flagEmailTooLong ||
        flagFirstNameNotNull ||
        flagFirstNameTooLong ||
        flagLastNameNotNull ||
        flagLastNameTooLong
      ) {
        setCannotEdit(true);
      } else {
        setCannotEdit(false);
      }
    }
  }, [
    flagEmailPattern,
    flagEmailNotNull,
    flagEmailTooLong,
    flagFirstNameNotNull,
    flagFirstNameTooLong,
    flagLastNameNotNull,
    flagLastNameTooLong,
  ]);

  const dispatch = useDispatch();
  const selector = state => {
    return {
      editEoContactFlag: state.CRContact.editEoContactFlag,
      editAMEDetailsFlag: state.CRContact.editAMEDetailsFlag,
      editMEDetailsFlag: state.CRContact.editMEDetailsFlag,
    };
  };
  const { editEoContactFlag, editAMEDetailsFlag, editMEDetailsFlag } =
    useSelector(selector);

  useEffect(() => {
    if (editEoContactFlag || editAMEDetailsFlag || editMEDetailsFlag) {
      dispatch(getCRDetailInfomationList(journalcode));
      dispatch(EditClear());
    }
  }, [editEoContactFlag, editAMEDetailsFlag, editMEDetailsFlag]);

  //验证邮箱格式的正则表达式
  var patternEmail =
    /^\w+((-\w+)|(\.\w+)|(_\w+))*@[A-Za-z0-9]+(([.\-])[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

  const EOShowList = [{ key: 'Email', value: '', properity: 'eo' }];
  const AMEShowList = [
    { key: 'Name', value: '', properity: 'fullNameOfAme' },
    { key: 'Email', value: '', properity: 'emailOfAme' },
  ];
  const MEShowList = [
    { key: 'Name', value: '', properity: 'fullNameOfMe' },
    { key: 'Email', value: '', properity: 'emailOfMe' },
  ];

  const EOeditList = [
    {
      key: 'Email',
      newValue: email,
      setNewValue: setEmail,
    },
  ];
  const AMEeditList = [
    {
      key: 'First Name',
      newValue: firstName,
      setNewValue: setFirstName,
    },
    {
      key: 'Last Name',
      newValue: lastName,
      setNewValue: setLastName,
    },
    {
      key: 'Email',
      newValue: email,
      setNewValue: setEmail,
    },
  ];
  const MEeditList = [
    {
      key: 'First Name',
      newValue: firstName,
      setNewValue: setFirstName,
    },
    {
      key: 'Last Name',
      newValue: lastName,
      setNewValue: setLastName,
    },
    {
      key: 'Email',
      newValue: email,
      setNewValue: setEmail,
    },
  ];

  /**
   * @author LiuXin
   * @date 2022/4/30
   * @description 针对email字段进行有效性判定
   * email的格式、email最大长度为32，EO Contact的eamil字段不可以为空
   */
  useEffect(() => {
    if (typeof email === 'string' && email.length > 0) {
      setFlagEmailNotNull(false);
      if (patternEmail.test(email)) {
        setFlagEmailPattern(false);
      } else {
        setFlagEmailPattern(true);
      }
      if (email.length > 100) {
        setFlagEmailTooLong(true);
      } else {
        setFlagEmailTooLong(false);
      }
    } else {
      setFlagEmailPattern(false);
      setFlagEmailTooLong(false);
      if (title === 'EO Contact') {
        setFlagEmailNotNull(true);
      } else {
        setFlagEmailNotNull(false);
      }
    }
  }, [email]);

  /**
   * @author LiuXin
   * @date 2022/4/30
   * @description 对First Name字段进行有效性判别
   * 这个字段不能为空，最长字符长度不能超过40字符
   */
  useEffect(() => {
    if (typeof firstName === 'string' && firstName.length > 0) {
      setFlagFirstNameNotNull(false);
      if (firstName.length > 40) {
        setFlagFirstNameTooLong(true);
      } else {
        setFlagFirstNameTooLong(false);
      }
    } else {
      setFlagFirstNameNotNull(true);
      setFlagFirstNameTooLong(false);
    }
  }, [firstName]);

  /**
   * @author LiuXin
   * @date 2022/4/30
   * @description 对Last Name字段进行有效性判别
   * 这个字段不能为空，最长字符长度不能超过40字符
   */
  useEffect(() => {
    if (typeof lastName === 'string' && lastName.length > 0) {
      setFlagLastNameNotNull(false);
      if (lastName.length > 40) {
        setFlagLastNameTooLong(true);
      } else {
        setFlagLastNameTooLong(false);
      }
    } else {
      setFlagLastNameNotNull(true);
      setFlagLastNameTooLong(false);
    }
  }, [lastName]);
  /**
   * @author LiuXin
   * @date 2022/4/30
   * @description 三个模块公用一个组件，选择当前模块对应的editList
   * @returns 返回这个组件应该需要的editList
   */
  const judgeEditList = () => {
    switch (title) {
      case 'EO Contact':
        return EOeditList;
      case 'AME Details':
        return AMEeditList;
      case 'ME Details':
        return MEeditList;
      default:
        return [];
    }
  };

  /**
   * @author LiuXin
   * @date 2022/4/30
   * @description 将detail 信息对象中的信息对应到每一个展示块当中
   */
  const getInfoShowList = () => {
    if (CRdetailInfoObj !== null && CRdetailInfoObj !== undefined) {
      //设置EO 的email字段
      EOShowList[0].value = CRdetailInfoObj[EOShowList[0].properity];
      //设置AME name和email字段
      for (let ameItem of AMEShowList) {
        ameItem.value = CRdetailInfoObj[ameItem.properity];
        if (cannotEditBtn === false) {
          if (
            title === 'AME Details' &&
            ameItem.key === 'Email' &&
            (ameItem.value === null ||
              ameItem.value === undefined ||
              ameItem.value.length === 0)
          ) {
            setCannotEditBtn(true);
          }
        }
      }
      for (let meItem of MEShowList) {
        meItem.value = CRdetailInfoObj[meItem.properity];
        if (cannotEditBtn === false) {
          if (
            title === 'ME Details' &&
            meItem.key === 'Email' &&
            (meItem.value === null ||
              meItem.value === undefined ||
              meItem.value.length === 0)
          ) {
            setCannotEditBtn(true);
          }
        }
      }
    }
  };

  /**
   * @author LiuXin
   * @date 2022/4/30
   * @description 这个组件同时维护了三个展示块的信息，
   * 但只需要展示一个块即可，根据title进行展示信息的返回
   * @returns 返回需要展示的信息列表
   */
  const judgeShowList = () => {
    switch (title) {
      case 'EO Contact':
        return EOShowList;
      case 'AME Details':
        return AMEShowList;
      case 'ME Details':
        return MEShowList;
      default:
        return [];
    }
  };

  //块盒子的样式
  const blockBoxCss = {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '40px',
  };
  //块的小标题的样式
  const blockTitleCss = {
    width: '100%',
    height: '100%',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '125%',
    textTransform: 'capitalize',
    color: '#0855A5',
    marginLeft: '20px',
    marginRight: '20px',
  };
  //粗体分割线样式，小标题下面的粗体分割线
  const bigDividerCss = {
    height: '4px',
    left: '0px',
    top: '10px',
    background: '#0855A5',
  };
  //如果当前修改中有错误不能进行修改，则使用这个css样式
  const iconTitleDisabledStyle = {
    marginLeft: '5px',
    marginRight: '15px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '120%',
    letterSpacing: '-0.011em',
    color: '#C0C0C0',
    textTransform: 'none',
  };

  //按钮后面提示文字样式
  const iconTitleStyle = {
    marginLeft: '5px',
    marginRight: '15px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '120%',
    letterSpacing: '-0.011em',
    color: '#0855A5',
    textTransform: 'none',
  };

  // 信息展示key值文字部分的样式
  const keyStyle = {
    height: '33px',
    textAlign: 'right',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#828282',
    wordWrap: 'break-word',
  };

  //信息展示key值整体的样式。
  const showKeyBox = {
    width: '200px',
    height: '33px',
    marginRight: '20px',
    paddingTop: '5px',
    display: 'flex',
    justifyContent: 'flex-end',
  };

  //红色星星的样式
  const starCss = {
    height: '33px',
    textAlign: 'right',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#A94442',
    wordWrap: 'break-word',
  };

  //警告文字的样式
  const alertText = {
    width: '259px',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    color: '#A94442',
  };

  /**
   * @description 通过判断当前能否修改来返回按钮的样式
   * @param {boolean} cannotEdit 当前能否修改，能为false，不能修改为true
   * @returns 返回按钮的样式
   */
  const chooseSaveCss = cannotEdit => {
    return cannotEdit ? iconTitleDisabledStyle : iconTitleStyle;
  };

  /**
   * @description 当保存edit内容时的操作
   */
  const handleSaveChange = () => {
    let journalName = CRdetailInfoObj.journalName;
    if (title === 'EO Contact') {
      dispatch(editEOContact(journalcode, email, fleshFlag, journalName));
    } else if (title === 'AME Details') {
      dispatch(
        editAMEDetail(email, firstName, lastName, fleshFlag, journalName)
      );
    } else if (title === 'ME Details') {
      dispatch(
        editMEDetail(email, firstName, lastName, fleshFlag, journalName)
      );
    }
    fleshFlag = !fleshFlag;
    setIsEdit(false);
  };

  /**
   * @description 当取消当前更改的操作
   */
  const handleCancelChange = () => {
    setIsEdit(false);
  };

  /**
   * @description 点击edit按钮的操作
   */
  const handleEditChange = () => {
    initEditList();
    setIsEdit(true);
  };

  const judgeIsTooLong = type => {
    if (flagEmailTooLong && type === 'email') {
      return true;
    }
    if (flagFirstNameTooLong && type === 'first name') {
      return true;
    }
    if (flagLastNameTooLong && type === 'last name') {
      return true;
    }
    return false;
  };

  /**
   * @description 一个没有后面图标的输入框
   * @param {string} newValue 这个值传入的是新值的接收变量
   * @param {function} setNewValue 这个值用于设置新值
   * @param {string} type 这个字段的名字，标题
   * @param {boolean} isDisabled 这个标签限制的最大输入长度，超过这个长度报错
   * @param {number} limitLength 这个字段允许输入的最大字符长度
   * @returns 返回控制这个条目的输入的样式
   */
  function NullIconTextField(
    newValue,
    setNewValue,
    type,
    isDisabled,
    limitLength
  ) {
    return (
      <Box>
        <OutlinedInput
          id={title + type}
          data-selenium-id={title + type}
          error={judgeIsTooLong(type)}
          disabled={isDisabled}
          sx={{
            height: '33px',
            width: '259px',
            fontStyle: 'normal',
            color: '#505050',
            fontSize: '14px',
            lineHeight: '120%',
            padding: '0px',
          }}
          value={newValue}
          onChange={e => {
            setNewValue(e.target.value);
          }}
        />
        {type === 'email' && flagEmailPattern ? (
          <Box
            sx={alertText}
            data-selenium-id='CRContact_DetailPage-showBlock-NotValidBox'
          >
            The email is not a valid email address.
          </Box>
        ) : (
          <Box data-selenium-id='CRContact_DetailPage-showBlock-NullBox1'></Box>
        )}
        {type === 'email' && flagEmailNotNull ? (
          <Box
            sx={alertText}
            data-selenium-id='CRContact_DetailPage-showBlock-EmailMandatoryBox'
          >
            The email is mandatory to input.
          </Box>
        ) : (
          <Box data-selenium-id='CRContact_DetailPage-showBlock-NullBox2'></Box>
        )}
        {type === 'first name' && flagFirstNameNotNull ? (
          <Box
            sx={alertText}
            data-selenium-id='CRContact_DetailPage-showBlock-FirstNameMandatoryBox'
          >
            The first name is mandatory to input.
          </Box>
        ) : (
          <Box data-selenium-id='CRContact_DetailPage-showBlock-NullBox3'></Box>
        )}
        {type === 'last name' && flagLastNameNotNull ? (
          <Box
            sx={alertText}
            data-selenium-id='CRContact_DetailPage-showBlock-LastNameMandatoryBox'
          >
            The last name is mandatory to input.
          </Box>
        ) : (
          <Box data-selenium-id='CRContact_DetailPage-showBlock-NullBox4'></Box>
        )}
        {judgeIsTooLong(type) ? (
          <Box
            sx={alertText}
            data-selenium-id='CRContact_DetailPage-showBlock-TooLongBox'
          >
            This field should not exceed {limitLength} characters in length!
          </Box>
        ) : (
          <Box data-selenium-id='CRContact_DetailPage-showBlock-NullBox5'></Box>
        )}
      </Box>
    );
  }

  /**
   * @author LiuXin
   * @date 2022/4/28
   * @description 通过判断输入的key是否是必填项来返回显示样式,如果是必填项需要加*
   * @param {string} key 这个字段的小标题
   * @returns 小标题的样式
   */
  const judgeMustNotNull = key => {
    var flag = false;
    //EO的email字段必填
    if (title === 'EO Contact' && key === 'Email') {
      flag = true;
    }
    //AME/ME的name必填
    if (key === 'First Name' || key === 'Last Name') {
      flag = true;
    }
    // return keyTitle;
    return (
      <Box
        sx={showKeyBox}
        data-selenium-id='CRContact_DetailPage-showBlock-ShowKeyBox'
      >
        <Box sx={keyStyle}>{key}</Box>
        {flag ? <Box sx={starCss}>&nbsp;*</Box> : <Box></Box>}
        <Box sx={keyStyle}>&nbsp;:</Box>
      </Box>
    );
  };

  /**
   * @author LiuXin
   * @date 2022/4/30
   * @param {string} key
   * @param {string} value
   * @returns 根据key返回对应的修改框的格式。
   */
  const judgeValueComponent = (key, newValue, setNewValue) => {
    let isDisabled = false;
    if (
      key === 'Email' &&
      title === 'AME Details' &&
      typeof CRdetailInfoObj.emailOfAme === 'string' &&
      CRdetailInfoObj.emailOfAme.length > 0
    ) {
      isDisabled = true;
    }
    if (
      key === 'Email' &&
      title === 'ME Details' &&
      typeof CRdetailInfoObj.emailOfMe === 'string' &&
      CRdetailInfoObj.emailOfMe.length > 0
    ) {
      isDisabled = true;
    }
    switch (key) {
      case 'Email':
        return NullIconTextField(
          newValue,
          setNewValue,
          'email',
          isDisabled,
          100
        );
      case 'First Name':
        return NullIconTextField(
          newValue,
          setNewValue,
          'first name',
          isDisabled,
          40
        );
      case 'Last Name':
        return NullIconTextField(
          newValue,
          setNewValue,
          'last name',
          isDisabled,
          40
        );
      default:
        return <></>;
    }
  };

  /**
   * @author LiuXin
   * @date 2022/4/30
   * @description 用于初始化更新的时候的初始数据，将展示数据设置成更改框的初始值
   */
  const initEditList = () => {
    if (CRdetailInfoObj !== null && CRdetailInfoObj !== undefined) {
      switch (title) {
        case 'EO Contact':
          setEmail(CRdetailInfoObj.eo);
          break;
        case 'AME Details':
          setFirstName(CRdetailInfoObj.firstNameOfAme);
          setEmail(CRdetailInfoObj.emailOfAme);
          setLastName(CRdetailInfoObj.lastNameOfAme);
          break;
        case 'ME Details':
          setFirstName(CRdetailInfoObj.firstNameOfMe);
          setEmail(CRdetailInfoObj.emailOfMe);
          setLastName(CRdetailInfoObj.lastNameOfMe);
          break;
        default:
          break;
      }
    }
  };

  return (
    <Box
      sx={blockBoxCss}
      data-selenium-id='CRContact_DetailPage-showBlock-BoxCss'
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '1px',
        }}
      >
        <Box data-selenium-id='CRContact_DetailPage-showBlock-BoxCss-Box'>
          <Box
            id='crContactDetailPageBlockTitle'
            data-selenium-id='CRContact_DetailPage-showBlock-TitleBox'
            sx={blockTitleCss}
          >
            {title}
          </Box>
          <Divider
            sx={bigDividerCss}
            data-selenium-id='CRContact_DetailPage-showBlock-BigDividerCss'
          />
        </Box>
        <Box>
          <IconButton
            id={title + 'IsFoldBtn'}
            data-selenium-id={title + 'IsFoldBtn'}
            sx={{
              width: '20px',
              padding: '0',
              marginRight: '10px',
              marginTop: '20px',
            }}
            onClick={() => {
              setIsFold(!isFold);
            }}
          >
            {isFold ? <FoldIcon /> : <UnfoldIcon />}
          </IconButton>
        </Box>
      </Box>
      <Divider />
      {isFold ? (
        <Box data-selenium-id='CRContact_DetailPage-showBlock-FoldBox'></Box>
      ) : (
        <Box
          style={{
            display: 'flex',
            marginTop: '20px',
            justifyContent: 'space-between',
          }}
          data-selenium-id='CRContact_DetailPage-showBlock-NotFoldBox1'
        >
          <Box></Box>
          {isEdit ? (
            <Box
              sx={{ display: 'flex' }}
              data-selenium-id='CRContact_DetailPage-showBlock-EditBox1'
            >
              <Box style={{ display: 'flex' }}>
                <Button
                  id={title + 'SaveBtn'}
                  data-selenium-id={title + 'SaveBtn'}
                  startIcon={
                    cannotEdit ? (
                      <EditNotSave width='14px' height='14px' />
                    ) : (
                      <EditSave width='14px' height='14px' />
                    )
                  }
                  sx={chooseSaveCss(cannotEdit)}
                  onClick={handleSaveChange}
                  disabled={cannotEdit}
                >
                  Save
                </Button>
              </Box>
              <Box style={{ display: 'flex' }}>
                <Button
                  id={title + 'CancelBtn'}
                  data-selenium-id={title + 'CancelBtn'}
                  startIcon={<EditCancel width='14px' height='14px' />}
                  onClick={handleCancelChange}
                  sx={iconTitleStyle}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              style={{ display: 'flex', marginRight: '20px' }}
              data-selenium-id='CRContact_DetailPage-showBlock-NotEditBox1'
            >
              {cannotEditBtn ? (
                <Button
                  id={title + 'EditBtn'}
                  data-selenium-id={title + 'EditBtn'}
                  startIcon={<CannotEditIcon />}
                  sx={iconTitleStyle}
                  onClick={handleEditChange}
                  disabled={cannotEditBtn}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  id={title + 'EditBtn'}
                  data-selenium-id={title + 'EditBtn'}
                  startIcon={<EditIcon />}
                  sx={iconTitleStyle}
                  onClick={handleEditChange}
                  disabled={cannotEditBtn}
                >
                  Edit
                </Button>
              )}
            </Box>
          )}
        </Box>
      )}
      {isFold ? (
        <></>
      ) : (
        <Box data-selenium-id='CRContact_DetailPage-showBlock-NotFoldBox2'>
          {isEdit ? (
            <Box
              sx={{ display: 'flex' }}
              data-selenium-id='CRContact_DetailPage-showBlock-EditBox2'
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '522px',
                }}
              >
                {judgeEditList().map(item => {
                  return (
                    <Box
                      sx={{
                        width: '520px',
                        display: 'flex',
                        marginTop: '16px',
                      }}
                    >
                      {judgeMustNotNull(item.key)}
                      {judgeValueComponent(
                        item.key,
                        item.newValue,
                        item.setNewValue
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ) : (
            <Box data-selenium-id='CRContact_DetailPage-showBlock-NotEditBox2'>
              {getInfoShowList()}
              <Description
                label={`crContact${title}`}
                infoObj={judgeShowList()}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
export default CRshowBlock;

import { Box, IconButton, Stack, Grid, CircularProgress } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import LabelAndValue from '@/components/LabelAndValue';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import SIPDetailObjectConserveIcon from '@/assets/SIPDetailObjectConserveIcon.svg';
import SIPDetailObjectCancelIcon from '@/assets/SIPDetailObjectCancelIcon.svg';
import { ItemBlock, SIPDetailSelectBox } from '../EditComponents';
import SaveAndCancelButton from '@/componentsphase2/SIDetail/SaveAndCancelButton';
import { SelectInput } from '@/componentsphase2/SelectBox/SelectInput';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import { GET_SIP_STATUS } from '@/actions/SIMT-SIP/SIP/SIPAction';
import {
  getAllJournalByCodeAndName,
  getJournalListWithCodeAndName, getJournalListWithCodeOptIn,
  getJournalListWithName,
  getJournalListWithNameOptIn
} from "@/actions/SIMT-SI/SpecialIssue/Journal";
import {getObject, getSIPDetailObjectChoiceJournalDropList, getSIPStatus} from "@/actions/SIMT-SIP/SIP/SipDetail";
import {
  getSIPDetailObjectPreferredSubjectAreaDropList,
  SIPDetailObjectEdit
} from "@/actions/SIMT-SIP/SIP/SipEditDetail";
function getJournalLabel(o) {
  if (o.journalName && o.journalCode) {
    return `${o.journalName}(${o.journalCode})`;
  } else return o.journalName || o.journalCode || '';
}
export default function ObjectEditBlock(props) {
  const { setIsObjectEdit,listInOneColumn } = props;
  const [isFold, setIsFold] = useState(true);
  const [preferredSubjectArea, setPreferredSubjectArea] = useState(null);
  const [choiceJournal1, setChoiceJournal1] = useState({});
  const [choiceJournal2, setChoiceJournal2] = useState({});
  const [choiceJournal3, setChoiceJournal3] = useState({});

  const [preferredSubjectAreaDropList, setPreferredSubjectAreaDropList] =
    useState([]);
  const [choiceJournalDropList, setChoiceJournalDropList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [first, setFirst] = useState(true);

  const dispatch = useDispatch();

  const { sipCode } = useParams();

  useEffect(() => {
    getSIPStatus(sipCode);
  }, []);

  const selector = state => {
    return {
      object: state.SIP.object,
      sipDetailObjectChoiceJournalResult:
        state.SIP.sipDetailObjectChoiceJournalResult,
      sipDetailObjectPreferredSubjectAreaResult:
        state.SIP.sipDetailObjectPreferredSubjectAreaResult,
      sipDetailObjectEditResult: state.SIP.sipDetailObjectEditResult,
      sipDetailObjectEditMonitor: state.SIP.sipDetailObjectEditMonitor,
      sipType: state.SIP.sipInformation?.sipType,
      journalOptions: state.SI.JourList,
      sipVersion: state.SIP.sipStatus?.sipVersion,
      sipStatus: state.SIP.sipStatus,
    };
  };
  const {
    object,
    sipDetailObjectChoiceJournalResult,
    sipDetailObjectPreferredSubjectAreaResult,
    sipDetailObjectEditResult,
    sipDetailObjectEditMonitor,
    sipType,
    journalOptions,
    sipVersion,
    sipStatus,
  } = useSelector(selector);

  /***********************对编辑变量进行初始化********************** */
  const init = () => {
    if (object?.preferredSubjectArea) {
      setPreferredSubjectArea(object.preferredSubjectArea);
      // setChoiceJournal1(
      //   {
      //     journalName: object.journal1Name,
      //     journalCode: object.journal1Code,
      //   }
      // );
      setChoiceJournal2(
        {
          journalName: object.journal2Name,
          journalCode: object.journal2Code,
        }
      );
      setChoiceJournal3(
        {
          journalName: object.journal3Name,
          journalCode: object.journal3Code,
        }
      );
    }
    setChoiceJournal1(
      {
        journalName: object?.journal1Name,
        journalCode: object?.journal1Code,
      });
  };

  useEffect(() => {
    init();
  }, []);
  /***************************************************************** */

  /***********************dropList********************** */
  useEffect(() => {
    setChoiceJournalDropList(
      sipDetailObjectChoiceJournalResult
        ? sipDetailObjectChoiceJournalResult
        : []
    );
  }, [sipDetailObjectChoiceJournalResult]);

  useEffect(() => {
    setPreferredSubjectAreaDropList(
      sipDetailObjectPreferredSubjectAreaResult
        ? sipDetailObjectPreferredSubjectAreaResult
        : []
    );
  }, [sipDetailObjectPreferredSubjectAreaResult]);
  /***************************************************************** */

  /**********************对edit的结果进行处理****************************** */
  const buildData = () => {
    return {
      sipCode: sipCode,
      preferredSubjectArea:
        preferredSubjectArea === '' ? null : preferredSubjectArea,
      choiceJournal1st: (choiceJournal1?.journalCode === '' || choiceJournal1?.journalCode === undefined) ? null : choiceJournal1.journalCode,
      choiceJournal2nd: (choiceJournal2?.journalCode === '' || choiceJournal2?.journalCode === undefined) ? null : choiceJournal2.journalCode,
      choiceJournal3rd: (choiceJournal3?.journalCode === '' || choiceJournal3?.journalCode === undefined) ? null : choiceJournal3.journalCode,
      sipVersion: sipVersion,
    };
  };

  const handleEdit = () => {
    setIsLoading(true);
    dispatch(SIPDetailObjectEdit(buildData(), sipCode)).then((sipNewVersion) => {
      if (sipNewVersion) {
        let newSipStatus = {
          ...sipStatus,
          sipVersion: sipNewVersion
        };
        dispatch({ type: GET_SIP_STATUS, data: {
          ...newSipStatus
        }});
      }
    });
  };

  useEffect(() => {
    if (first) {
      setFirst(false);
      return;
    }
    if (sipDetailObjectEditResult === 'OK') {
      async function req() {
        await dispatch(getObject(sipCode));
      }

      req().then(_r => {
        setTimeout(() => {
          setIsLoading(false);
          setIsObjectEdit(false);
        }, 1000);
      });
    } else {
      setIsLoading(false);
    }
  }, [sipDetailObjectEditMonitor]);
  /********************************************************************** */
  const config = {
    outlinedInputProps: {
      outlinedInputSX: {
        height: '33px',
        padding: '8px 7px',
      },
      inputSX: {
        root: {
          height: '18px',
        },
      },
      notchedOutlineFocusSX: {
        border: '2px solid #154AB6',
      },
    },
    endAttchmentProps: {
      endAttchmentSX: {
        top: '8.5px',
      },
    },
    dropListProps: {
      listBoxUlSX: {
        li: {
          color: 'var(--gray-500, #596A7C)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px' /* 166.667% */,
        },
        // liHover: {
        // },
        liActive: {
          color: 'var(--gray-600, #262E35)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px' /* 166.667% */,
        },
        liHover: {
          color: 'var(--gray-600, #262E35)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px' /* 166.667% */,
        },
        // liSelectedHover: {},
      },
    },
  };
  return (
    <Box data-selenium-id='SIP_DetailPage_ObjectEditBlock-Box'>
      <Box
        sx={{ mb: '16px', mt: '20px', cursor: 'pointer' }}
        className='sipBlockTitleBox'
        data-selenium-id='SIP_DetailPage_ObjectEditBlock-TitleBox'
      >
        <Box
          class='titleFoldButton'
          id='objectTitleFoldButton'
          data-selenium-id='SIP_DetailPage_ObjectEditBlock-TitleFoldButton'
          onClick={() => {
            setIsFold(!isFold);
          }}
        >
          {isFold ? (
            <MarketChevronUp data-selenium-id='SIP_DetailPage_ObjectEditBlock-MarketChevronUp' />
          ) : (
            <SIDetailArrowDown />
          )}
        </Box>
        <Stack
          id='titleBoxLeft'
          data-selenium-id='SIP_DetailPage_ObjectEditBlock-TitleBoxLeft'
          spacing={2}
          direction='row'
          sx={{ cursor: 'default' }}
        >
          <Box>
            <FormattedMessage
              id='sipDetail.object'
              data-selenium-id='SIP_DetailPage_EditBlock-Box-Object'
            />
          </Box>
        </Stack>
      </Box>
      {isFold ? (
        <>
          <Stack
            sx={{ columnGap: '56px', rowGap: '8px',marginBottom:'16px' }}
            data-selenium-id='SIP_DetailPage_ObjectEditBlock-Stack'
            direction={'row'}
            flexWrap={'wrap'}
          >
            {
              sipType !== 'Yes' ?//USIP
                (<>
                <Grid
                  data-selenium-id='SIP_DetailPage_ObjectEditBlock-Grid'
                  container
                  columnSpacing={listInOneColumn ? 6 : 0}
                  rowSpacing={listInOneColumn ? 0 : 1}
                  sx={{ width: '100%', }}
                >
                   <Grid
                    item
                    xs={listInOneColumn ? 6 : 12}
                    data-selenium-id='SIP_DetailPage_ObjectEditBlock-InnerGrid1'
                  >
                    <ItemBlock
                      title='Preferred Subject Area:'
                      mandatory={true}
                      sx={{ width: '518px' }}
                    >
                      <SinpleAutocomplete
                        id='preferredSubjectArea'
                        data-selenium-id='SIP_DetailPage_ObjectEditBlock-PreferredSubjectArea'
                        value={preferredSubjectArea}
                        options={preferredSubjectAreaDropList}
                        isEmpty={!preferredSubjectArea}
                        onChange={(event, newValue) => {
                          setPreferredSubjectArea(newValue);
                        }}
                        needKeyboardDelete={false}
                        loadOptionAction={name => getSIPDetailObjectPreferredSubjectAreaDropList(name)}
                        placeholder={'Select the Preferred Subject Area'}
                        getOptionLabel={o => o}
                        config={config}
                        needClearInputValue={true}
                      ></SinpleAutocomplete>
                    </ItemBlock>
                    
                    <ItemBlock title='1st Choice Journal:' sx={{ width: '518px',paddingTop:'8px' }}>
                      <SinpleAutocomplete
                        id='choiceJournal1'
                        data-selenium-id='SIP_DetailPage_ObjectEditBlock-ChoiceJournal1'
                        value={choiceJournal1}
                        options={journalOptions}
                        isEmpty={!choiceJournal1.journalCode}
                        onChange={(event, newValue) => {
                          setChoiceJournal1(newValue);
                        }}
                        loadOptionAction={getAllJournalByCodeAndName}
                        placeholder={'Select journal'}
                        getOptionLabel={getJournalLabel}
                        config={config}
                        needClearInputValue={true}
                        needKeyboardDelete={true}
                        deleteValue={() => { setChoiceJournal1({}); }}
                      ></SinpleAutocomplete>
                    </ItemBlock>
                  </Grid>
                  <Grid
                    item
                    xs={listInOneColumn ? 6 : 12}
                    data-selenium-id='SIP_DetailPage_ObjectBlock-InnerGrid1'
                  >
                    <ItemBlock title='2nd Choice Journal:' sx={{ width: '518px' }}>
                      <SinpleAutocomplete
                        id='choiceJournal2'
                        data-selenium-id='SIP_DetailPage_ObjectEditBlock-ChoiceJournal2'
                        value={choiceJournal2}
                        options={journalOptions}
                        isEmpty={!choiceJournal2.journalCode}
                        onChange={(event, newValue) => {
                          setChoiceJournal2(newValue);
                        }}
                        loadOptionAction={getAllJournalByCodeAndName}
                        placeholder={'Select journal'}
                        getOptionLabel={getJournalLabel}
                        config={config}
                        needClearInputValue={true}
                        needKeyboardDelete={true}
                        deleteValue={() => { setChoiceJournal2({}); }}
                      ></SinpleAutocomplete>
                    </ItemBlock>
                    <ItemBlock title='3rd Choice Journal:' sx={{ width: '518px',paddingTop:'8px' }}>
                      <SinpleAutocomplete
                        id='choiceJournal3'
                        data-selenium-id='SIP_DetailPage_ObjectEditBlock-ChoiceJournal3'
                        value={choiceJournal3}
                        options={journalOptions}
                        isEmpty={!choiceJournal3.journalCode}
                        onChange={(event, newValue) => {
                          setChoiceJournal3(newValue);
                        }}
                        loadOptionAction={getAllJournalByCodeAndName}
                        placeholder={'Select journal'}
                        getOptionLabel={getJournalLabel}
                        config={config}
                        needClearInputValue={true}
                        needKeyboardDelete={true}
                        deleteValue={() => { setChoiceJournal3({}); }}
                      ></SinpleAutocomplete>
                    </ItemBlock>
                  </Grid>
                </Grid>
               
                  
                </>) : (<>
                  {/* USIP */}
                  {/* 待完善 */}
                  <ItemBlock title='Journal Code:' mandatory ='true' sx={{ width: '518px' }}>
                    <SinpleAutocomplete
                      id='choiceJournalCode'
                      data-selenium-id='SIP_DetailPage_ObjectEditBlock-ChoiceJournalCode'
                      value={choiceJournal1}
                      options={
                        journalOptions
                      }
                      isEmpty={!choiceJournal1.journalCode}
                      onChange={(event, newValue) => {
                        setChoiceJournal1(newValue);
                      }}
                      loadOptionAction={
                        // name => getSIPDetailObjectChoiceJournalDropList(name)
                        name => getJournalListWithCodeOptIn(name)
                      }
                      placeholder={'Select journal'}
                      getOptionLabel={o => `${o.journalCode || ''}`}
                      config={config}
                      needClearInputValue={true}
                      needKeyboardDelete={true}
                      deleteValue={() => { setChoiceJournal1({}); }}
                    ></SinpleAutocomplete>
                  </ItemBlock>

                  <ItemBlock title='Journal Homepage:' sx={{ width: '518px' }}>
                    <Box sx={{ fontSize: '14px', fontWeight: '400', height: '33px', display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}>{object?.journal1HomePage || 'N/A'}</Box>
                    </Box>
                  </ItemBlock>

                  <ItemBlock title='Journal Name:' mandatory ='true' sx={{ width: '518px' }}>
                    <SinpleAutocomplete
                      id='choiceJournal'
                      data-selenium-id='SIP_DetailPage_ObjectEditBlock-ChoiceJournalName'
                      value={choiceJournal1}
                      options={journalOptions}
                      isEmpty={!choiceJournal1.journalCode}
                      onChange={(event, newValue) => {
                        setChoiceJournal1(newValue);
                      }}
                      loadOptionAction={
                        // name => getSIPDetailObjectChoiceJournalDropList(name)
                        name => getJournalListWithNameOptIn(name)
                      }
                      placeholder={'Select journal'}
                      getOptionLabel={o => `${o.journalName || ''}`}
                      config={config}
                      needClearInputValue={true}
                      needKeyboardDelete={true}
                      deleteValue={() => { setChoiceJournal1({}); }}
                    ></SinpleAutocomplete>
                  </ItemBlock>

                  <ItemBlock title='Author Guidelines:' sx={{ width: '518px' }}>
                    <Box sx={{ fontSize: '14px', fontWeight: '400', height: '33px', display: 'flex', alignItems: 'center' }}>
                      <Box sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}>{object?.journal1AuthorGuidelines || 'N/A'}</Box>
                    </Box>
                  </ItemBlock>
                </>)
            }
          </Stack>
          <SaveAndCancelButton
            id='SIPDetail_ObjectBlock-TwoBtn'
            data-selenium-id='SIPDetail_ObjectBlock-TwoBtn'
            handleCancel={() => { setIsObjectEdit(false); }}
            handleSubmit={handleEdit}
            isSaving={isLoading}
          ></SaveAndCancelButton>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
}

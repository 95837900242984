// @ts-check
import React from 'react';
import { Box, IconButton, Stack, Typography, Link } from '@mui/material';
// @ts-ignore
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import MarketChevronDown from '@/assets/MarketChevronDown.svg';
// @ts-ignore
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import moment from 'moment';

/**
 *
 * @param {import("./CustomBlock").TitleBlockProps} props
 * @returns
 */
export const TitleBlock = props => {
  const { title, SIPCode, dueDate,isSSIP =false,} = props;
  return (
    <Box
      data-selenium-id='SIP_RevisePage_CustomComponents-Block-Title-Box'
      paddingX='32px'
      sx={{
        backgroundColor: '#fff',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        paddingTop: isSSIP?'10px':'28px',
        paddingBottom: isSSIP?'10px':'22px',
        '& .MuiTypography-root.title-primary': {
          fontWeight: 400,
          fontSize: '24px',
          lineHeight: '24px',
          color: '#262E35',
        },
        '& .MuiTypography-root.title-sub': {
          fontSize: '14px',
          lineHeight: '20px',
          color: '#154AB6',
          '&.title-due-date': {
            visibility: dueDate ? 'visible' : 'hidden',
          },
          '& span': {
            fontWeight: 600,
            marginLeft: '8px',
          },
        },
      }}
    >
      {isSSIP?
      <Stack
        spacing={'8px'}
        data-selenium-id='SIP_RevisePage_CustomComponents-Block-Title-Stack'
      >
        <Typography
          className='title-primary'
          data-selenium-id='SIP_RevisePage_CustomComponents-Block-Title-PrimaryTypography'
        >
          {title}
        </Typography>
        {/* <Typography
          sx={{fontFamily:'Open Sans',fontWeight:400,fontSize:'14px',lineHeight:'20px',color:'#596A7C'}}
          data-selenium-id='SIP_RevisePage_CustomComponents-Block-Title-SubCodeTypography'
        >
          SSIP Code:<span style={{fontWeight:600,color:'#0052CC'}}>{SIPCode}</span>
        </Typography> */}
        <Link
          id={`ssipCodeLink`}
          data-selenium-id={`SSIPPageP_RevisePage_CustomComponents-Block-SSipCodeLink`}
          href={`../auth/sipRelated/sip/detail/${SIPCode}`}
          color='#154AB6'
          sx={{
            textDecoration: 'none',
          }}
        >
          <Box >
            <Typography
              sx={{fontFamily:'Open Sans',fontWeight:400,fontSize:'14px',lineHeight:'20px',color:'#596A7C'}}
              data-selenium-id='SIP_RevisePage_CustomComponents-Block-Title-SubCodeTypography'
            >
              SSIP Code: <span style={{fontWeight:600,color:'#0052CC'}}>{SIPCode}</span>
        </Typography>
          </Box>
        </Link>
        <Typography
          sx={{fontFamily:'Open Sans',fontWeight:400,fontSize:'14px',lineHeight:'20px',color:'#596A7C',marginTop:'4px!important'}}
          data-selenium-id='SSIP_RevisePage_CustomComponents-Block-Title-SubDateTypography'
        >
          Due Date: <span>{moment(dueDate).utc().format('YYYY-MM-DD')}</span>
        </Typography>
      </Stack>
      :
      <Stack
        spacing={'8px'}
        data-selenium-id='SIP_RevisePage_CustomComponents-Block-Title-Stack'
      >
        <Typography
          className='title-primary'
          data-selenium-id='SIP_RevisePage_CustomComponents-Block-Title-PrimaryTypography'
        >
          {title}
        </Typography>
        <Typography
          className='title-sub title-sip-code'
          data-selenium-id='SIP_RevisePage_CustomComponents-Block-Title-SubCodeTypography'
        >
          SIP Code:<span>{SIPCode}</span>
        </Typography>
        <Typography
          className='title-sub title-due-date'
          data-selenium-id='SIP_RevisePage_CustomComponents-Block-Title-SubDateTypography'
        >
          Due Date:<span>{moment(dueDate).utc().format('YYYY-MM-DD')}</span>
        </Typography>
      </Stack>
      }

    </Box>
  );
};

/**
 *
 * @param {import("./CustomBlock").SectionTitleBlockProps} props
 */
export const SectionTitleBlock = props => {
  const { sectionTitle, sectionFold, handleToggleSectionFold, endAdornment } =
    props;
  return (
    <Stack
      data-selenium-id='SIP_RevisePage_CustomComponents-Block-Section-Stack'
      className='SectionTitleBlock'
      direction={'row'}
      alignItems='center'
      spacing='8px'
    >
      <Box
        class='titleFoldButton'
        sx={{ padding: 0,
          width: '24px',
          height:'24px',
          '& .MuiTouchRipple-ripple.MuiTouchRipple-rippleVisible':{
            display: 'none',
          }
        }}
        onClick={handleToggleSectionFold}
        data-selenium-id='SIP_RevisePage_CustomComponents-Block-Section-IconButton'
      >
        {sectionFold ? <SIDetailArrowDown /> : <MarketChevronUp />}

      </Box>
      <Stack data-selenium-id='SIP_RevisePage_CustomComponents-Block-Section-InnerStack'>
        <Typography
          data-selenium-id='SIP_RevisePage_CustomComponents-Block-Section-Typography'
          sx={{ fontSize: '16px', fontWeight: 600, color: '#113D95' }}
        >
          {sectionTitle}
        </Typography>
      </Stack>
      {endAdornment}
    </Stack>
  );
};

import { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { HeaderEmptyMessage } from '@/components/Alert/EmptyMessage';
import { DeleteAlert, fetchAlerts } from '@/actions/SIMT-User/Alert';
import Loading from '@/components/Loading';
import NotificationItem from '@/components/Alert/NotificationItem';
import {
  formatEmpty,
  formatSystemEmailTemplateUpdate,
  SYS_EMAIL_UPDATE_SUBJECT,
} from '../../Alert/Util';

function ListLoader({
  // Are there more items to load?
  // (This information comes from the most recent API request.)
  hasNextPage,
  // Array of items loaded so far.
  items,
  // Callback function responsible for loading the next page of items.
  loadNextPage,
  FixedSizeListProps = {},
  Row,
  useFixedSizeList = true,
  scrollToIndex = -1,
}) {
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = index => !hasNextPage || index < items.length;
  /*
  Notice: InfiniteLoader + FixedSizeList will automatically execute loadNextPage method at first render, but div (useFixedSizeList === false) will not.
  If you want div load initial data, you may need this code:

  // when use custom list instead of FixedSizeList, need get initial data like FixedSizeList
  useEffect(() => {
    if (!useFixedSizeList && hasNextPage && items.length === 0) {
      loadNextPage();
    }
  }, [useFixedSizeList, hasNextPage, items]);
  */

  const listRef = useRef();
  useEffect(() => {
    if (scrollToIndex > -1 && listRef.current) {
      listRef.current.scrollToItem(scrollToIndex, 'smart');
    }
  }, [scrollToIndex]);
  return useFixedSizeList ? (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadNextPage}
      threshold={0}
    >
      {({ onItemsRendered, ref }) => {
        return (
          <FixedSizeList
            itemCount={itemCount}
            onItemsRendered={onItemsRendered}
            ref={(list) => {
              ref(list);
              listRef.current = list;
            }}
            {...FixedSizeListProps}
          >
            {Row}
          </FixedSizeList>
        );
      }}
    </InfiniteLoader>
  ) : (
    <div
      style={{ height: FixedSizeListProps.height, overflow: 'auto' }}
      onScroll={({ target }) => {
        if (!hasNextPage) {
          return;
        }
        const { scrollTop, clientHeight, scrollHeight } = target;
        if (scrollTop + clientHeight >= scrollHeight - 100) {
          loadNextPage();
        }
      }}
    >
      {items.concat(hasNextPage ? [undefined] : []).map((item, index) => (
        <Row key={index} index={index} style={{ minHeight: 98 }} />
      ))}
    </div>
  );
}
const AlertList = ({
  show = true,
  isRead = null,
  onItemClick,
  activeID = -1,
  idPrefix = 'HeaderAlertItem-',
  useFixedSizeList = true,
  scrollToIndex = -1,
}) => {
  const { allAlerts, allAlertsFinished, unreadAlerts, unreadAlertsFinished } =
    useSelector(state => ({
      allAlerts: state.Alert.allAlerts,
      allAlertsFinished: state.Alert.allAlertsFinished,
      unreadAlerts: state.Alert.unreadAlerts,
      unreadAlertsFinished: state.Alert.unreadAlertsFinished,
    }));
  const list = isRead === false ? unreadAlerts : allAlerts;
  const listFinished =
    isRead === false ? unreadAlertsFinished : allAlertsFinished;

  const dispatch = useDispatch();

  const handleDelete = id => {
    dispatch(DeleteAlert(id));
  };
  function Row({ index, style }) {
    const item = list[index];
    if (index >= list.length) {
      return (
        <Box sx={style}>
          <Loading style={{ marginTop: '20px' }} />
        </Box>
      );
    }
    return (
      <NotificationItem
        sx={style}
        id={`${idPrefix}${item.id}`}
        key={item.id}
        subject={item.subject}
        active={item.id === activeID}
        state={item.read === 'No' ? 'Unread' : 'Read'}
        DaysOfDifference={item.createTime}
        handleSubjectClick={() => onItemClick(index, item.id, item)}
        handleDelete={() => {
          handleDelete(item.id);
        }}
      >
        {item.subject == SYS_EMAIL_UPDATE_SUBJECT
          ? formatSystemEmailTemplateUpdate(item.textList?.[0])
          : formatEmpty(item.textList?.[0])}
      </NotificationItem>
    );
  }

  return listFinished && list.length === 0 ? (
    <div style={{ display: show ? 'block' : 'none' }}>
      <HeaderEmptyMessage isRead={isRead} />
    </div>
  ) : (
    <AutoSizer
      style={{
        display: show ? 'block' : 'none',
        height: 'auto',
        width: 'auto',
      }}
    >
      {({ height }) => {
        return (
          <ListLoader
            items={list}
            Row={Row}
            hasNextPage={!listFinished}
            loadNextPage={() =>
              dispatch(fetchAlerts(isRead, list[list?.length - 1]?.id || 0))
            }
            FixedSizeListProps={{
              height,
              width: '100%',
              itemSize: 117,
            }}
            useFixedSizeList={useFixedSizeList}
            scrollToIndex={scrollToIndex}
          />
        );
      }}
    </AutoSizer>
  );
};

export default  AlertList;

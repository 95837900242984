import React, { useEffect } from 'react';
import { TextField, Button, Grid, FormHelperText } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import { SIModal } from '@/modules/Modal';
import { styled } from '@mui/system';
import { useIntl } from 'react-intl';

const exportBtnCss = {
  width: '82px',
  height: '36px',
  backgroundColor: '#154AB6',
  borderRadius: '5px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#F1F3F5',
  letterSpacing: '0.01em',
  textTransform: 'capitalize',
};

const CancelBtnCss = {
  width: '82px',
  height: '36px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#154AB6',
  textTransform: 'capitalize',
};

const labelStyle = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.08em',
  color: '#596A7C',
};

const asteriskStyle = {
  color: '#C40000',
  marginLeft: '10px',
  marginRight: '10px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '18px',
};

const TextInput = styled(props => <TextField {...props} />)({
  '.MuiInputBase-root': {
    padding: '2px 12px 2px 12px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    letterSpacing: '0.01em',
    color: '#262E35',
    width: '530px',
    height: '212px',
  },
  '& .MuiOutlinedInput-root.Mui-error fieldset': {
    border: '2px solid #EE5350',
    borderRadius: '4px',
  },
});

const counterStyle = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  height: '15px',
  color: '#596A7C',
};

const hintStyle = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '-0.084px',
  color: '#EE5350',
};

function DeclineReasonModal(props) {
  const {
    open,
    handleConfirm,
    handleClose,
    value,
    setValue,
    error = false,
    setError = () => {},
  } = props;

  const dispatch = useDispatch();
  const Intl = useIntl();

  useEffect(() => {}, [open]);

  const handleReasonChange = e => {
    if (e.target.value.length > 10000) {
      return;
    }
    setValue(e.target.value);
    if (e.target.value.length > 0) {
      setError(false);
    }
  };

  const container = (
    <Box
      marginTop='20px'
      data-seleunim-id='SIP_AcceptPage_Submodule-DeclineReasonModal-Container-Box'
    >
      <Stack
        spacing={1.5}
        data-seleunim-id='SIP_AcceptPage_Submodule-DeclineReasonModal-Container-Stack1'
      >
        <Box data-seleunim-id='SIP_AcceptPage_Submodule-DeclineReasonModal-Container-InnerBox1'>
          <span style={labelStyle}>Reason</span>
          <span style={asteriskStyle}>*</span>
        </Box>
        <Box data-seleunim-id='SIP_AcceptPage_Submodule-DeclineReasonModal-Container-InnerBox2'>
          {/* <FormControl
            variant="standard"
            sx={{ width: "100%" }}
            error
          > */}
          <TextInput
            id='sipAcceptDeclineReasonInput'
            data-seleunim-id='SIP_AcceptPage_Submodule-DeclineReasonModal-Container-AcceptInput'
            multiline
            value={value}
            onChange={handleReasonChange}
            rows={10}
            error={error}
          />
          <Grid
            data-seleunim-id='SIP_AcceptPage_Submodule-DeclineReasonModal-Container-Grid'
            container
            direction='row'
            justifyContent='space-between'
            sx={{ mt: '4px' }}
          >
            <span style={hintStyle}>
              {error ? Intl.messages['sipDetail.declineHintMessage'] : null}
            </span>
            <FormHelperText
              sx={counterStyle}
              data-seleunim-id='SIP_AcceptPage_Submodule-DeclineReasonModal-Container-FromHelpText'
            >
              {value.length}/10,000
            </FormHelperText>
          </Grid>
          {/* </FormControl> */}
        </Box>
      </Stack>
      <Stack
        data-seleunim-id='SIP_AcceptPage_Submodule-DeclineReasonModal-Container-Stack2'
        sx={{ display: 'flex', marginTop: '24px' }}
        direction='row'
        justifyContent='flex-end'
        spacing={2}
      >
        <Button
          id='exportAllSIPageCancelButton'
          data-selenium-id='SIP_AcceptPage_Submodule-DeclineReasonModal-Container-ExportAllSICancelButton'
          sx={CancelBtnCss}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          id='exportAllSIPageExportButton'
          data-selenium-id='SIP_AcceptPage_Submodule-DeclineReasonModal-Container-ExportAllSIExprotButton'
          sx={exportBtnCss}
          variant='contained'
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </Stack>
    </Box>
  );

  return (
    <div>
      <SIModal
        data-selenium-id='SIP_AcceptPage_Submodule-DeclineReason-SIModal'
        title='Decline Reason'
        open={open}
        handleClose={handleClose}
        height='475px'
        width='600px'
        // sx={{ '& .SIModalPro-container': { padding: '32px 35px 20px 35px' } }}
      >
        {container}
      </SIModal>
    </div>
  );
}

export default DeclineReasonModal;

import { Box } from '@mui/material';

function CustomLable(props) {
  const CssLabel = {
    display: 'inline-block',
    alignItems: 'center',
    padding: '2px 8px',
    gap: '10px',
    height: '18px',
    width: 'auto',
    background: '#F1F3F5',
    borderRadius: ' 4px',
    fontFamily: 'Inconsolata',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '120%',
    color: '#596A7C',
  };
  return (
    <Box
      sx={CssLabel}
      data-selenium-id='EmailTemplates-CommonMudle-CustomLabel-Box'
    >
      {props.children}
    </Box>
  );
}
export default CustomLable;

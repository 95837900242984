// @ts-check
import { DetailScrollTopButton } from '@/components/StyledButton/ScrollToTopButton';
import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { TitleBlock } from './CustomComponents/CustomBlock';
import {
  RevisePageBodyContainer,
  SectionsContainer,
} from './CustomComponents/CustomContainer';
import { AddCommentsStickyBar } from './CustomComponents/CustomStickyBar';
import {
  commissionApproachesInitialValue,
  geInformationListIntialValue,
  sipInformationInitialValue,
} from './CommonInitialValue';

import CommentSection from './Section/CommentSection';
import { ContentCommissioningApproachesShowSection } from './Section/ContentCommissioningApproachesSection';
import { GEInformationShowSection } from './Section/GEInformationSection';
import { ObjectShowSection } from './Section/ObjectSection';
import { SIPInformationShowSection } from './Section/SIPInformationSection';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadSIPReviseData,
  SET_DOWNLOAD_REVISE_DATA_STATUS,
  SET_UPLOAD_REVISE_DATA_STATUS,

} from '@/actions/SIMT-SIP/SIP/SIPAction';
import { useParams } from 'react-router-dom';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';
import { SSIPObjectShowSection } from './Section/SSIPObjectSection';
import { SSIPInformationShowSection } from './Section/SSIPInformationSection';
import {submitJPMReviewData} from "@/actions/SIMT-SIP/SIP/SipRevise";

/**
 * @type {import("./JPMReviewPage").JPMReviewData}
 */
export const jpmReviewDataInitialValue = {
  geInformation: geInformationListIntialValue,
  comments:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  sipCode: 'SIP-2022-001234',
  sipTitle:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. ',
  sipInformation: sipInformationInitialValue,
  object: {
    preferredSubjectArea: 'Nikolaou, Ioannis',
    recommendedJournals: [
      'Uniyal, Varsha',
      'Uniyal, Varsha',
      'Uniyal, Varsha',
      'Uniyal, Varsha',
    ],
  },
  commissionApproaches: commissionApproachesInitialValue,
};

/**
 *
 * @param {import("./JPMReviewPage").JPMReviewPageProps} props
 * @returns
 */
const JPMReviewPage = props => {
  const { jpmReviewData } = props;

  const { uploadReviseDataStatus } = useSelector(state => ({
    /** @type {import("./ProposerRevisePage").UploadReviseDataStatus} */
    // @ts-ignore
    uploadReviseDataStatus: state.SIP.uploadReviseDataStatus,
  }));

  const dispatch = useDispatch();

  const { token } = useParams();

  /**
   * @param {string} comment
   */
  const handleSubmitComment = comment => {
    if (token)
      dispatch(
        submitJPMReviewData(token, {
          sipCode: jpmReviewData.sipCode,
          comments: comment,
        })
      );
  };

  useEffect(() => {
    if (uploadReviseDataStatus === 'error') {
      dispatch(
        setSnackbarMessageAndOpen(
          'sipDetail.revise.submitCommentError',
          {},
          SEVERITIES.error
        )
      );
    }
    if (
      uploadReviseDataStatus === 'success' ||
      uploadReviseDataStatus === 'error'
    ) {
      if (token) {
        dispatch({
          type: SET_DOWNLOAD_REVISE_DATA_STATUS,
          /** @type {import("./ProposerRevisePage").DownloadReviseDataStatus} */ data: 'downloading',
        });
        dispatch(loadSIPReviseData(token, 'JPMReview'));
      }
      dispatch({
        type: SET_UPLOAD_REVISE_DATA_STATUS,
        /** @type {import("./ProposerRevisePage").UploadReviseDataStatus} */ data: 'idle',
      });
    }
  }, [uploadReviseDataStatus]);

  return (
    <Box data-selenium-id='SIP_RevisePage_JPMReview-Box'>
      <RevisePageBodyContainer>
        <TitleBlock
          data-selenium-id='SIP_RevisePage_JPMReview-TitleBlock'
          title={jpmReviewData.sipTitle}
          SIPCode={jpmReviewData.sipCode}
        />
        <SectionsContainer>
        {jpmReviewData?.sipInformation?.sipType === 'Yes'?
        (<SSIPObjectShowSection
          value={{
            type: 'JPMReview',
            sipObject: jpmReviewData.object?.solicitedSipObjectInfo,
          }}
        />):(
          <ObjectShowSection
            value={{
              type: 'JPMReview',
              sipObject: jpmReviewData.object,
            }}
          />
        )}
          <GEInformationShowSection value={jpmReviewData.geInformation} />
          {jpmReviewData?.sipInformation?.sipType === 'Yes'?
        (<SSIPInformationShowSection value={jpmReviewData.sipInformation}/>):
        (<SIPInformationShowSection value={jpmReviewData.sipInformation}/>)}
          <ContentCommissioningApproachesShowSection
            data-selenium-id='SIP_RevisePage_JPMReview-ContentCommissioningApproachesShowSection'
            value={jpmReviewData.commissionApproaches}
            emptyTip={true}
          />
          <CommentSection
            title='CE Comments to the Proposal'
            commentsContent={jpmReviewData.comments || ''}
          />
        </SectionsContainer>
      </RevisePageBodyContainer>
      <DetailScrollTopButton boxProps={{ sx: { bottom: 300 } }} />
      <AddCommentsStickyBar submitComment={handleSubmitComment} />
    </Box>
  );
};

export default JPMReviewPage;

import React from 'react';
import UnselectedDefaultRadio from '@/assets/UnselectedDefaultRadio.svg';
import UnselectedHoverRadio from '@/assets/UnselectedHoverRadio.svg';
import UnselectedInactiveRadio from '@/assets/UnselectedInactiveRadio.svg';
import SelectedDefaultRadio from '@/assets/SelectedDefaultRadio.svg';
import SelectedHoverRadio from '@/assets/SelectedHoverRadio.svg';
import SelectedInactiveRadio from '@/assets/SelectedInactiveRadio.svg';
import { Radio } from '@mui/material';

export default function SimpleRadio(props) {
  const { sx, isError = false, ...other } = props;
  return (
    <Radio
      icon={<UnselectedDefaultRadio />}
      checkedIcon={<SelectedDefaultRadio />}
      disableIcon={<UnselectedInactiveRadio />}
      disableRipple
      sx={{
        '&.MuiRadio-root': {
          padding: '0 !important',
        },
        '&,& input': {
          width: '40px',
          height: '30px',
        },
        '& svg circle': isError && {
          stroke: '#EE5350',
          strokeWidth: '2px',
        },
        //没选中的时候只有一个圈，所以第二条属性会被自动忽略
        '&:not(.Mui-disabled):hover': {
          '& svg circle:nth-child(1)': {
            stroke: isError ? '#EE5350' : '#596A7C',
            strokeWidth: isError ? '2px' : '1px',
          },
          '& svg circle:nth-child(2)': {
            fill: '#113D95',
          },
        },
        '&:not(.Mui-disabled)': {
          '& svg circle:nth-child(1)': {
            stroke: isError ? '#EE5350' : '#596A7C',
            strokeWidth: isError ? '2px' : '1px',
          },
          '& svg circle:nth-child(2)': {
            fill: '#113D95',
          },
        },
        '&.Mui-disabled': {
          '& svg circle:nth-child(1)': {
            stroke: '#DFE4E8',
          },
          '& svg circle:nth-child(2)': {
            fill: '#DFE4E8',
          },
        },
        ...sx,
      }}
      {...other}
    />
  );
}

import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";

function handleError(error) {
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error === 'object') {
    if (error.message && typeof error.message === 'string') {
      return error.message;
    }
    if (error.error && typeof error.error === 'string') {
      return error.error;
    }
  }
  return "Request Failed, please check your check the request info.";
}
export default function simpleDisplayError(error,type='warning') {
  //这个函数用于简单地处理错误的显示，无需关注错误的内容
  return dispatch => {
    dispatch(setSnackbarMessageAndOpen(
      handleError(error),
      {
        value: handleError(error),
      },
      type,
    ));
  };
}
import {
  Table,
  TableContainer,
  TableRow,
  TableBody,
  Box,
  tableCellClasses,
  Paper,
  TableCell,
  Typography,
  TableHead,
} from '@mui/material';
import { MyTableHeader } from '../../../../components/DataTable';
import { styled } from '@mui/system';
import { CustomTooltip } from '../../../../components/CustomTooltip';
import GEisFlagged from '@/assets/GEisFlagged.svg';
import { tooltipClasses } from '@mui/material/Tooltip';
import { Link as ReactLick } from 'react-router-dom';
import { RadioErrorMessage } from '@/componentsphase2/RadioErrorMessage';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useEffect} from 'react';
import {
  validateGEHasLeadGE,
  validateGEWithStageAndDecision,
  validateSingleGEWithStageAndDecision
} from "@/pages/SIPPage/SIPDetailPage/DecisionPanel/validation";
const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E8F2',
    color: '#505050',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '0.01em',
    width: '100px !important',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '54px',
    paddingTop: 0,
    paddingBottom: 0,
    maxWidth: '100px !important',
    // paddingLeft: '24px',
  },
}));

const getMaxFlagLevel = (function () {
  const enumValues = {
    'Flags-Severe Issue': 2,
    'Flags-Mild Issue': 1,
    '': 0,
    null: 0,
  };

  return function (value1, value2) {
    const numericValue1 = enumValues[value1];
    const numericValue2 = enumValues[value2];

    return numericValue1 > numericValue2 ? value1 : value2;
  };
})();


function GEDetailReadTable(props) {
  const { id, TableHeads, TableData, SipStage = '', sipType,SipCode } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    currentDecisionType,
    geInformation,
    sipDetailAllMandatorySubmitOrNextPressed,
  } = useSelector(state => {
    return {
      currentDecisionType: state.SIP.currentDecisionType,
      geInformation: state.SIP.geInformation,
      sipDetailAllMandatorySubmitOrNextPressed:
        state.SIP.sipDetailAllMandatorySubmitOrNextPressed,
    };
  });
  const geInformationManatoryFieldValid = type => {
    if(!validateGEHasLeadGE(geInformation)){
      return true;
    }
    return validateGEWithStageAndDecision(geInformation, SipStage, currentDecisionType);
  };

  const geInformationLeadGEValid = type => {
    let atLeastOneDTrue = false; // 用于标记是否至少有一个元素的d为true
    // 遍历数组中的每个对象
    if (SipStage !== 'Ideation' || type === 'Reject' || type === 'Withdraw')
      return true;
    for (let i = 0; i < geInformation.length; i++) {
      const obj = geInformation[i];
      // 检查对象的属性a、b、c的值是否不为空
      // 检查对象的属性d是否为true
      if (obj.leadGe === true) {
        atLeastOneDTrue = true; // 只要有一个元素的d为true，就标记为true
      }
    }
    // 如果a、b、c的条件对所有元素成立且d的条件对至少一个元素成立，则返回true，否则返回false
    return atLeastOneDTrue;
  };
  const geTableRowManatoryFieldValid = (decitionType, rowindex) => {
    if (geInformation.length === 0) return true;
    if (geInformation.length <= rowindex) return true;
    const obj = geInformation?.[rowindex];
    return validateSingleGEWithStageAndDecision(obj, SipStage, currentDecisionType);
  };


  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'none',
        mt:
          sipDetailAllMandatorySubmitOrNextPressed &&
          (!geInformationLeadGEValid(currentDecisionType) ||
            !geInformationManatoryFieldValid(currentDecisionType))
            ? '-18px'
            : '0px',
      }}
      data-selenium-id='SIP_DetailPage_GE-ReadTable-Box'
    >
      <RadioErrorMessage
        isError={
          sipDetailAllMandatorySubmitOrNextPressed &&
          !geInformationLeadGEValid(currentDecisionType)
        }
        errorMessage='Please add at least one Lead GE information.'
      />
      <RadioErrorMessage
        isError={
          sipDetailAllMandatorySubmitOrNextPressed &&
          !geInformationManatoryFieldValid(currentDecisionType)
        }
        errorMessage='Please complete highlighted GE information.'
      />
      <TableContainer
        id={id}
        data-selenium-id={`SIP_DetailPage_GE-ReadTable-${id}`}
        sx={{
          borderRadius: '8px 8px 8px 8px',
          borderWidth: '1px 1px 1px 1px',
          borderStyle: 'solid',
          borderColor: '#DFE4E8',
          boxShadow: 'none',
          overflow: 'auto',
          maxWidth: '1092px',
        }}
        component={Paper}
      >
        <Table
          data-selenium-id='SIP_DetailPage_GE-Read-Box-Table'
          stickyHeader
          aria-label='custom pagination table'
          sx={{
            minWidth: 500,
            letterSpacing: '0.01em',
            color: '#262E35',
            boxShadow: 'none',
          }}
        >
          <MyTableHeader
            data-selenium-id='SIP_DetailPage_GE-ReadTable-MyHeader'
            items={TableHeads}
            backgroundColor='#F1F3F5'
            color='#596A7C'
            CheckboxColor='wileyBlue2'
          />
          <TableBody data-selenium-id='SIP_DetailPage_GE-ReadTable-Body'>
            {TableData.map((row, rowindex) => {
              const alertGeFlagType = getMaxFlagLevel(row.alertGeFlagType, row.matchedEmailGeFlagType);
              return (
                <TableRow
                  key={rowindex}
                  data-selenium-id={"SIP_DetailPage_GE-ReadTable-Row" + rowindex}
                  sx={{
                    "&:hover": {
                      background: "#E8EDFB",
                      maxWidth: "200px"
                    },
                    maxWidth: "200px",
                    background:
                      sipDetailAllMandatorySubmitOrNextPressed &&
                      !geTableRowManatoryFieldValid(currentDecisionType, rowindex)
                        ? "#FFEFEF"
                        : "#FFFFFF"
                  }}
                >
                  {TableHeads.map((item, index) => (
                    <StyledTableCell
                      data-selenium-id='SIP_DetailPage_GE-ReadTable-StyledCell'
                      sx={{
                        color: "#262E35",
                        paddingX: "24px",
                        maxWidth: "200px",
                        borderBottom:
                          rowindex === TableData.length - 1 ? "none" : "",
                        "&:last-child": {
                          maxWidth: "unset !important",
                          "&>div:only-child": {
                            width: "max-content"
                          }
                        },
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                      }}
                      key={index}
                      align={item.align}
                    >
                      {item.id === "fullName" ? (
                        (SipStage === "Submitted" && sipType === "No") ||
                        (SipStage === "Initial Review" && sipType === "Yes") ||
                        alertGeFlagType === "" ? (
                          <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            justifyContent: 'space-between' ,

                            }}
                          >
                            <Box sx={{
                              width: "100%",
                              overflow:'hidden!important',
                              whiteSpace:'nowrap!important',
                              textOverflow:'ellipsis!important',
                            }}>
                              <CustomTooltip
                              title={row.emailAddress} //emailAddress
                              placement='top'
                              data-selenium-id='SIP_DetailPage_GE-ReadTable-CustomTooltip'
                              sx={{
                                translate: "0 12px" ,
                              }}
                            >
                                {row[item.id]}

                                </CustomTooltip>
                                </Box>

                              <Box
                                sx={{
                                  maxWidth: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  display: "flex",
                                  flexDirection: "row",

                                }}
                                data-selenium-id='SIP_DetailPage_GE-ReadTable-CustomTooltip-Box'
                              >

                                {(SipStage === "Initial Review" && !SipCode.startsWith('SSIP')) && (
                          <Box><CustomTooltip
                            title={
                              "Additional verification needed. Please transfer SIP back to JPA."
                            }
                            arrow
                            placement='top'
                            sx={{
                              [`& .${tooltipClasses.arrow}`]: {
                                color: "var(--gray-500, #596A7C)"
                              },
                              [`& .${tooltipClasses.tooltip}`]: {
                                display: "inline-flex",
                                flexDirection: "column",
                                alignItems: "center",
                                background: "var(--gray-500, #596A7C)",
                                backgroundBlendMode: "multiply",
                                color: "var(--shades-white, #FFF)",
                                textAlign: "center",
                                fontFamily: "Open Sans",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "20px",
                                whiteSpace: "nowrap",
                                maxWidth: "420px",
                                marginBottom: "8px !important"
                              }
                            }}
                          >
                            {((row.matchedNameGeCode !== null && row.matchedNameGeCode.startsWith('UNVERIFIED-'))||(row.matchedEmailGeCode !== null && row.matchedEmailGeCode.startsWith('UNVERIFIED-'))) && (
                              <Box
                                sx={{ marginTop: "20px",marginBottom:'12px' }}
                              >
                                <GEisFlagged/>
                              </Box>
                            )}
                          </CustomTooltip>
                          </Box>
                        )}
                              </Box>
                      </Box>
                    )
                        : SipStage != "" &&
                        alertGeFlagType === 'Flags-Mild Issue' ? (


                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "flex-start",
                                  width: "100%",
                                  height: "100%",
                                  justifyContent: 'space-between' ,

                                }}
                              >
                                <Box sx={{
                                  width: "100%",
                                  overflow:'hidden!important',
                                  whiteSpace:'nowrap!important',
                                  textOverflow:'ellipsis!important',
                                }}>
                                  <Box
                                    data-selenium-id={`SIP_DetailPage_GE-ReadTable-alertFlagType-mild-Box`}
                                    sx={{
                                      display: "flex",
                                      padding: "2px 17.5px 2px 18.5px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "0px 0px 4px 4px",
                                      background: "var(--warning-600, #FFB152)",
                                      maxWidth:'103px'
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "#FFF",
                                        fontFamily: "Open Sans",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        lineHeight: "normal",

                                      }}
                                    >
                                      MILD ISSUE
                                    </Typography>

                                  </Box>
                                  <CustomTooltip
                                  title={row.emailAddress} //emailAddress
                                  placement='top'
                                  data-selenium-id='SIP_DetailPage_GE-ReadTable-CustomTooltip'
                                  sx={{
                                    translate: "0 12px" ,
                                  }}
                                >
                                    {row[item.id]}

                                    </CustomTooltip>
                                    </Box>

                                  <Box
                                    sx={{
                                      maxWidth: "100%",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      display: "flex",
                                      flexDirection: "row",

                                    }}
                                    data-selenium-id='SIP_DetailPage_GE-ReadTable-CustomTooltip-Box'
                                  >

                                    {(SipStage === "Initial Review" && !SipCode.startsWith('SSIP')) && (
                              <Box><CustomTooltip
                                title={
                                  "Additional verification needed. Please transfer SIP back to JPA."
                                }
                                arrow
                                placement='top'
                                sx={{
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: "var(--gray-500, #596A7C)"
                                  },
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    display: "inline-flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    background: "var(--gray-500, #596A7C)",
                                    backgroundBlendMode: "multiply",
                                    color: "var(--shades-white, #FFF)",
                                    textAlign: "center",
                                    fontFamily: "Open Sans",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    whiteSpace: "nowrap",
                                    maxWidth: "420px",
                                    marginBottom: "8px !important"
                                  }
                                }}
                              >
                                {((row.matchedNameGeCode !== null && row.matchedNameGeCode.startsWith('UNVERIFIED-'))||(row.matchedEmailGeCode !== null && row.matchedEmailGeCode.startsWith('UNVERIFIED-'))) && (
                                  <Box
                                    sx={{ marginTop: "20px" }}
                                  >
                                    <GEisFlagged/>
                                  </Box>
                                )}
                              </CustomTooltip>
                              </Box>
                            )}
                                  </Box>



                          </Box>
                        ) : SipStage != "" &&
                        alertGeFlagType === 'Flags-Severe Issue' ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-start",
                              width: "100%",
                              height: "100%",
                              justifyContent: 'space-between'

                            }}
                          >

                                <Box sx={{
                                  width: "100%",
                                  overflow:'hidden!important',
                                  whiteSpace:'nowrap!important',
                                  textOverflow:'ellipsis!important',
                                }}>
                                <Box
                                  data-selenium-id={`SIP_DetailPage_GE-ReadTable-alertFlagType-severe-Box`}
                                  sx={{
                                    display: "flex",
                                    padding: "2px 12px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "0px 0px 4px 4px",
                                    background: "var(--error-600, #EE5350)",
                                    maxWidth:'103px'
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#FFF",
                                      fontFamily: "Open Sans",
                                      fontSize: "12px",
                                      fontStyle: "normal",
                                      fontWeight: "600",
                                      lineHeight: "normal"
                                    }}
                                  >
                                    SEVERE ISSUE
                                  </Typography>
                                </Box>
                                <CustomTooltip
                                  title={row.emailAddress} //emailAddress
                                  placement='top'
                                  data-selenium-id='SIP_DetailPage_GE-ReadTable-CustomTooltip'
                                  sx={{ translate: "0 12px" }}
                                >
                                    {row[item.id]}
                                    </CustomTooltip>
                                    </Box>
                                  <Box
                                    sx={{
                                      maxWidth: "100%",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      display: "flex",
                                      flexDirection: "row"
                                    }}
                                    data-selenium-id='SIP_DetailPage_GE-ReadTable-CustomTooltip-Box'
                                  >

                                    {(SipStage === "Initial Review" && !SipCode.startsWith('SSIP')) && (
                              <CustomTooltip
                                title={
                                  "Additional verification needed. Please transfer SIP back to JPA."
                                }
                                arrow
                                placement='top'
                                sx={{
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: "var(--gray-500, #596A7C)"
                                  },
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    display: "inline-flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    background: "var(--gray-500, #596A7C)",
                                    backgroundBlendMode: "multiply",
                                    color: "var(--shades-white, #FFF)",
                                    textAlign: "center",
                                    fontFamily: "Open Sans",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    whiteSpace: "nowrap",
                                    maxWidth: "420px",
                                    marginBottom: "8px !important"
                                  }
                                }}
                              >
                                {((row.matchedNameGeCode !== null && row.matchedNameGeCode.startsWith('UNVERIFIED-'))||(row.matchedEmailGeCode !== null && row.matchedEmailGeCode.startsWith('UNVERIFIED-'))) && (
                                  <Box
                                    sx={{marginTop: "20px" }}
                                  >
                                    <GEisFlagged/>
                                  </Box>
                                )}
                              </CustomTooltip>
                            )}
                                  </Box>

                              </Box>

                        ) : (
                          <></>
                        )
                      ) : item.id === "matchedEmailGeCode" ? (
                        SipStage != "" && (row.matchedEmailGeFlagType === "" && (row.matchedNameGeFlagType === "" || row.matchedNameGeCode === '' || row.matchedNameGeCode === null || row.matchedNameGeCode.startsWith('UNVERIFIED-')) )? (
                          <ReactLick
                            style={{
                              marginTop: "2px",
                              flexGrow: 1,
                              display: "flex",
                              alignItems: "center",
                              textDecoration: "none",
                              color: "var(--primary-500, #154AB6)",
                              fontFamily: "Open Sans",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "20px",
                              letterSpacing: "0.14px"
                            }}
                            extra
                            target={"_blank"}
                            id={`gePageTableGeCodeLink${index}`}
                            to={`/simt/auth/siRelated/ge/detail/${row.geCode?.startsWith("UNVERIFIED-")?row.geCode.slice(11):row.geCode}`}
                          >
                            {row.matchedEmailGeCode === null
                              ? row["matchedNameGeCode"] !== null &&
                              row["matchedNameGeCode"].startsWith("UNVERIFIED-")
                                ? null
                                : row["matchedNameGeCode"]
                              :row[item.id].startsWith("UNVERIFIED-")? row[item.id].slice(11):row[item.id]}
                          </ReactLick>
                        ) : SipStage != "" && (
                          row.matchedEmailGeCode !== null && row.matchedEmailGeFlagType === "Flags-Mild Issue" ||
                          row.matchedNameGeFlagType === "Flags-Mild Issue" && row.matchedNameGeCode !=='' && row.matchedNameGeCode !== null && !row.matchedNameGeCode.startsWith('UNVERIFIED-') ) ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                              paddingBottom: "12px"
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start"
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start"
                                }}
                              >
                                <Box
                                  data-selenium-id={`SIP_DetailPage_GE-ReadTable-alertFlagType-mild-Box`}
                                  sx={{
                                    display: "flex",
                                    padding: "2px 17.5px 2px 18.5px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "0px 0px 4px 4px",
                                    background: "var(--warning-600, #FFB152)"
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#FFF",
                                      fontFamily: "Open Sans",
                                      fontSize: "12px",
                                      fontStyle: "normal",
                                      fontWeight: "600",
                                      lineHeight: "normal"
                                    }}
                                  >
                                    MILD ISSUE
                                  </Typography>
                                </Box>

                                <Box
                                  sx={{
                                    maxWidth: "100%",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap"
                                  }}
                                  data-selenium-id='SIP_DetailPage_GE-ReadTable-CustomTooltip-Box'
                                >
                                  <ReactLick
                                    style={{
                                      marginTop: "2px",
                                      flexGrow: 1,
                                      display: "flex",
                                      alignItems: "center",
                                      textDecoration: "none",
                                      color: "var(--primary-500, #154AB6)",
                                      fontFamily: "Open Sans",
                                      fontSize: "14px",
                                      fontStyle: "normal",
                                      fontWeight: 600,
                                      lineHeight: "20px",
                                      letterSpacing: "0.14px"
                                    }}
                                    extra
                                    id={`gePageTableGeCodeLink${index}`}
                                    target={"_blank"}
                                    to={`/simt/auth/siRelated/ge/detail/${row.geCode?.startsWith("UNVERIFIED-")?row.geCode.slice(11):row.geCode}`}
                                  >
                                    {row.matchedEmailGeCode === null
                                      ? row["matchedNameGeCode"] !== null &&
                                      row["matchedNameGeCode"].startsWith(
                                        "UNVERIFIED-"
                                      )
                                        ? null
                                        : row["matchedNameGeCode"]
                                      :row[item.id].startsWith("UNVERIFIED-")? row[item.id].slice(11):row[item.id]}
                                  </ReactLick>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ) : SipStage != "" && (
                        row.matchedEmailGeCode !== null && row.matchedEmailGeFlagType === "Flags-Severe Issue" ||
                        row.matchedNameGeFlagType === "Flags-Severe Issue" && row.matchedNameGeCode !=='' && !row.matchedNameGeCode.startsWith('UNVERIFIED-') )
                        ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                              paddingBottom: "12px"
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start"
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start"
                                }}
                              >
                                <Box
                                  data-selenium-id={`SIP_DetailPage_GE-ReadTable-alertFlagType-severe-Box`}
                                  sx={{
                                    display: "flex",
                                    padding: "2px 12px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "0px 0px 4px 4px",
                                    background: "var(--error-600, #EE5350)"
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#FFF",
                                      fontFamily: "Open Sans",
                                      fontSize: "12px",
                                      fontStyle: "normal",
                                      fontWeight: "600",
                                      lineHeight: "normal"
                                    }}
                                  >
                                    SEVERE ISSUE
                                  </Typography>
                                </Box>

                                <Box
                                  sx={{
                                    maxWidth: "100%",
                                    display: "inline-block",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap"
                                  }}
                                  data-selenium-id='SIP_DetailPage_GE-ReadTable-CustomTooltip-Box'
                                >
                                  <ReactLick
                                    style={{
                                      marginTop: "2px",
                                      flexGrow: 1,
                                      display: "flex",
                                      alignItems: "center",
                                      textDecoration: "none",
                                      color: "var(--primary-500, #154AB6)",
                                      fontFamily: "Open Sans",
                                      fontSize: "14px",
                                      fontStyle: "normal",
                                      fontWeight: 600,
                                      lineHeight: "20px",
                                      letterSpacing: "0.14px"
                                    }}
                                    extra
                                    target={"_blank"}
                                    id={`gePageTableGeCodeLink${index}`}
                                    to={`/simt/auth/siRelated/ge/detail/${row.geCode?.startsWith("UNVERIFIED-")?row.geCode.slice(11):row.geCode}`}
                                  >
                                    {row.matchedEmailGeCode === null
                                      ? row["matchedNameGeCode"] !== null &&
                                      row["matchedNameGeCode"].startsWith(
                                        "UNVERIFIED-"
                                      )
                                        ? null
                                        : row["matchedNameGeCode"]
                                      :row[item.id].startsWith("UNVERIFIED-")? row[item.id].slice(11):row[item.id]}
                                  </ReactLick>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ) : (
                          <></>
                        )
                      ) : item.id === "emailAddress" ?
                        <CustomTooltip
                          title={row.fullName} //fullName
                          placement='top'
                          data-selenium-id='SIP_DetailPage_GE-ReadTable-CustomTooltip'
                          sx={{ translate: "0 12px" }}
                        >
                          {row[item.id]}
                        </CustomTooltip>
                        : (row[item.id])}
                    </StyledTableCell>
                  ))}
                </TableRow>
              );
            })}

            {/*
            (row.alertGeFlagType === "Flags-Mild Issue" || row.alertGeFlagType === "Flags-Severe Issue") ?
                        (
                          row.alertGeFlagType === "Flags-Mild Issue"?
                          (<></>)
                          :
                          (<></>)
                        )
                        : (
                          row[item.id]
                        )
            */}
            {TableData.length <= 0 ? (
              <TableRow
                id='siDetailPageNoDataText'
                data-selenium-id='SIP_DetailPage_GE-ReadTable-NoDataText'
                // style={{ height: '300px' }} //modified
              >
                <TableCell
                  data-selenium-id='SIP_DetailPage_GE-ReadTable-Cell'
                  colSpan={TableHeads.length}
                  sx={{
                    textAlign: 'center',
                    fontSize: '14px',
                    color: '#98A7B6',
                    letterSpacing: '.01em',
                    paddingY: '12px',
                    borderBottomStyle: 'none',
                  }}
                >
                  NO DATA
                </TableCell>
              </TableRow>
            ) : (
              <div></div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
export default GEDetailReadTable;

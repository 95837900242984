import {axios_instance} from "@/utils/axios_instance";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {EDIT_GEBASIC} from "@/actions/SIMT-SI/GuestEditor/GEAction";

export function editGEBasic(
    geId,
    firstName,
    lastName,
    primaryEmail,
    secondaryEmail,
    department,
    institute,
    city,
    country,
    gender,
    notes,
    researchKeywords,
    orcidId,
    title,
    profileWebsite,
    researchGateId,
    cvfileName
) {
    return async dispatch => {
        const url = `/api/v1/si-service/guest-editors/${geId}`;
        const res = await axios_instance.put(
            url,
            {
                firstName: firstName,
                lastName: lastName,
                primaryEmail: primaryEmail,
                secondaryEmail: secondaryEmail,
                department: department.department,
                institute: institute.institute,
                city: city,
                country: country,
                gender: gender,
                notes: notes,
                researchKeywords: researchKeywords,
                orcidId: orcidId,
                title: title,
                profileWebsite: profileWebsite,
                researchGateId: researchGateId,
                cvFileName: cvfileName,
            },
            {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
              params: {
                requestType: 'edit-guest-editor',
              }
            }
        );
        if (res.data.code === 200) {
            dispatch({type: EDIT_GEBASIC, data: ''});
        } else {
            dispatch(
                setSnackbarMessageAndOpen(
                    'gePage.updateError',
                    {
                        value: res.data.message, // TODO 以后再改为id的形式
                    },
                    SEVERITIES.error
                )
            );
            dispatch({type: EDIT_GEBASIC, data: ''});
        }
    };
}
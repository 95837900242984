import {
  Box,
  IconButton,
  Stack,
  CircularProgress,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import {PERMISSIONS} from "@/constant/permission";
import EbmTable from './EbmTable';
import {
  getEbmReviewPanel,
  getSIPStatus,
} from '@/actions/SIMT-SIP/SIP/SipDetail';
import {
  addPotentialAuthorAction,
  deletePotentialAuthorAction,
  editPotentialAuthorAction,
  editSipCallForPaper,
} from '@/actions/SIMT-SIP/SIP/SipContent';
import EBMReviewButtonGroup from './EBMReviewButtonGroup';
import { useMemo } from 'react';
const fontStyle = {
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  color: '#596A7C',
};
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
export default function EBMReviewPanel(props) {
  const [isFold, setIsFold] = useState(false);
  const dispatch = useDispatch();
  const { sipCode } = useParams();
  const selector = state => {
    return {
      ebmList: state.SIP.ebmList,
      sipStatus: state.SIP.sipStatus,
      sipChecklistStatus: state.SIPChecklist.SIPChecklistDetail.status,
    };
  };
  const { ebmList, sipStatus, sipChecklistStatus } = useSelector(selector);

  useEffect(() => {
    dispatch(getEbmReviewPanel(sipCode));
  }, [sipCode]);

  // retrieve the ebmList from the backend

  const isCanceled = useMemo(() => {
    //calc by the ebmList, if the ebmList has any canceled ebm, then return true
    return (
      sipStatus?.ebmReviewStatus === 'Canceled' ||
      sipStatus?.ebmReviewStatus === 'Completed'
    );
  }, [ebmList]);

  return (
    <Stack>
      <Box
        sx={{ mb: '18px', mt: '22px', cursor: 'pointer' }}
        className='sipBlockTitleBox'
        data-selenium-id='SIP_DetailPage-EbmReviewPanel-TitleBox'
      >
        <Box
          data-selenium-id='SIP_DetailPage-EbmReviewPanel-FoldButtonBox'
          class='titleFoldButton'
          onClick={async () => {
            setIsFold(!isFold);
          }}
        >
          {isFold ? <SIDetailArrowDown /> : <MarketChevronUp />}
        </Box>
        <Stack
          id='titleBoxLeft'
          data-selenium-id='SIP_DetailPage-EbmReviewPanel-TtitleBoxLeft'
          spacing={1}
          direction='row'
          sx={{ cursor: 'default' }}
        >
          <Box>
            <FormattedMessage
              id='sipDetail.ebmReviewPanel'
              data-selenium-id='SIP_DetailPage-EbmReviewPanel-Message'
            />
          </Box>
        </Stack>
      </Box>
      {sipStatus?.ebmReviewStatus ? (
        <Stack direction='row'>
          <Typography width='20%' marginRight='12px' sx={fontStyle}>
            Result:
          </Typography>
          <Typography>{sipStatus?.ebmReviewStatus}</Typography>
        </Stack>
      ) : (
        <></>
      )}
      {!isFold ? <EbmTable ebmList={ebmList} /> : <></>}
      {sipStatus?.sipStage === 'EBM Review' &&
        sipStatus?.ebmReviewStatus === null && (
          <ShowForPermission permission={`${PERMISSIONS.SIP_WRITE_OPS}`}>
            <EBMReviewButtonGroup ebmList={ebmList} isCanceled={isCanceled} />
          </ShowForPermission>
        )}
    </Stack>
  );
}

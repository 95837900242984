import * as actions from '../actions/SIMT-SI/CRContact/CRContactAction';

const initState = {
  CRCData: {
    result: [],
    count: 0,
  },
  CRdetailInfoObj: {},
  editEoContactFlag: 0,
  editAMEDetailsFlag: 0,
  editMEDetailsFlag: 0,
  selectedCRC: [],
  selectedCRCInfo: {},
  batchUpdateCRCList: [],
  page: 1,
  pageSize: 50,
  orderByType: {
    // 排序相关
    order: 'asc',
    orderBy: 'journalCode', // 由于后端未返回geCode，所以用别的暂时替代
  },
  searchCRContent: '',
  //batchUpdate 相关
  judgeEmailResult: true,
  isJudgeEmail: true,
  searchAmeOrMeResult: [],
  reloadAllSelectionTrigger: 0,
  batchUpdateResult: null,

  error: false,
  tableErr: false,
};

export default function CRContactReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.CRC_ORDER_BY:
      return {
        ...state,
        orderByType: data,
      };
    case actions.SELECT_BATCH_CRC: {
      let newSelected;
      if (state.selectedCRC.indexOf(data.journalCode) > -1) {
        newSelected = state.selectedCRC; // 保持不变
      } else {
        newSelected = [data.journalCode, ...state.selectedCRC]; // 新增加
        if (state.selectedCRCInfo[data.journalCode] === undefined) {
          state.selectedCRCInfo[data.journalCode] = data;
        }
      }
      return {
        ...state,
        selectedCRC: newSelected, // Set集合的地址不变，好像不会触发表头全选框的重新渲染
        selectedCRCInfo: state.selectedCRCInfo,
        reloadAllSelectionTrigger: Math.random(), // 用一个随机数去触发CRC Header选择框的刷新
      };
    }
    case actions.GET_CRDETAIL: {
      return { ...state, CRdetailInfoObj: data };
    }
    case actions.EDIT_EOCONTACT: {
      return { ...state, editEoContactFlag: data };
    }
    case actions.EDIT_AMEDETAIL: {
      return { ...state, editAMEDetailsFlag: data };
    }
    case actions.EDIT_MEDETAIL: {
      return { ...state, editMEDetailsFlag: data };
    }
    case actions.EDIT_CLEAR: {
      return {
        ...state,
        editMEDetailsFlag: false,
        editAMEDetailsFlag: false,
        editEoContactFlag: false,
      };
    }
    case actions.UNSELECT_BATCH_CRC: {
      return {
        ...state,
        selectedCRC: state.selectedCRC.filter(item => {
          return item !== data;
        }),
        reloadAllSelectionTrigger: Math.random(), // 类似select
      };
    }
    case actions.UNSELECT_BATCH_CRC_INFO: {
      delete state.selectedCRCInfo[data];
    }
    case actions.UNSELECT_BATCH_CRC_INFO_ALL: {
      return { ...state, selectedCRCInfo: {} };
    }
    case actions.CHANGE_CRC_PAGE:
      return {
        ...state,
        page: data,
      };
    case actions.CHANGE_CRC_PAGE_SIZE:
      return {
        ...state,
        pageSize: data,
        page: 1,
      };
    case actions.BATCHUPDATECLEAR:
      return {
        ...state,
        judgeEmailResult: true,
        isJudgeEmail: true,
        searchAmeOrMeResult: [],
        batchUpdateResult: null,
      };
    case actions.JUDGEEMAIL:
      return {
        ...state,
        judgeEmailResult: data,
        isJudgeEmail: !state.isJudgeEmail,
      };
    case actions.SEARCHAMEORME:
      return {
        ...state,
        searchAmeOrMeResult: data,
      };
    case actions.LOAD_CR:
      return {
        ...state,
        CRCData: data,
      };
    case actions.SET_CR_SEARCH_CONTENT:
      return {
        ...state,
        searchCRContent: data,
      };
    case actions.GENERATE_CRC_INFO:
      return {
        ...state,
        batchUpdateCRCList: data,
      };
    case actions.BATCH_UPDATE: {
      return {
        ...state,
        batchUpdateResult: data,
      };
    }
    case actions.EDIT_ERROR: {
      return {
        ...state,
        error: data,
      };
    }
    case actions.EDIT_TABLE_ERROR: {
      return {
        ...state,
        tableErr: data,
      };
    }
    default:
      return state;
  }
}

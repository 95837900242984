import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CommonDialog } from '@/modules/AlertDialog';

import { openConfirm, setEditType, setTextParam } from '@/actions/SIMT-User/Alert/AlertAction';
import { FormattedMessage } from 'react-intl';
import { SelectedInput } from '@/components/SelectedInput';
// import { StyledAutocomplete } from '@/modules/SelectBox';
import {
  Checkbox,
  FormControl,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  TextField,
  Box,
  TableHead,
  TableFooter,
  List,
  InputAdornment,
  Dialog, Autocomplete, tooltipClasses, Popper
} from '@mui/material';

import {
  addSelected,
  cancelSelectAll,
  removeSelected,
  removeSelectedInfo,
} from '@/actions/SeleteAction';

import {
  createSIClear,



} from '@/actions/SIMT-SI/SpecialIssue/SIAction';
import { SIMTButton } from '../../../../components/StyledButton/SIMTButton';
import { appendSIReqId } from "../../../../actions/SIMT-SI/SpecialIssue/SIAction";
import CloseButton from '@/componentsphase2/CloseButton';
import { CustomTooltip } from '@/components/CustomTooltip';
import SIPOverviewUncheckedIcon from '../../../../assets/SIPOverviewUncheckedIcon.svg';
import SIPOverviewCheckedIcon from '../../../../assets/SIPOverviewCheckedIcon.svg';
import CATJournalIndeterminate from '../../../../assets/catjournalindeterminate.svg';
import React from 'react';
import ScrollBarY from '../../../../componentsphase2/ScrollBar/ScrollBarY';
import { contextHeightChanged } from '../../../../actions/CommonAction';
import { axios_instance } from '../../../../utils/axios_instance';
import { paramsEncode } from "../../../../utils/commonUtils";
import TableSkeleton from "../../../../components/TableSkeleton";
import { getSIData } from "@/actions/SIMT-SI/SpecialIssue/SpecialIssuePage";
import { batchTransferSI, getActiveCE, getAllActiveCE } from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueSystemAdmin";


const StyledAutocomplete = Autocomplete;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#727272',
    minWidth: '49px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '16px',
    lineHeight: '16px',
    color: '#484848',
    padding: '9px 16px',
  },
}));

const defaultPopperComponent = props => (
  <Popper
    {...props}
    sx={{
      zIndex: '1500 !important',
      '&.MuiAutocomplete-popper': {
        inset: '6px auto !important',
      },
      '& .MuiAutocomplete-paper': {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 6px 12px rgba(38, 46, 53, 0.12)',
        borderRadius: '5px',
      },
      '& .MuiAutocomplete-noOptions': {
        padding: '0px',
      },
      '& .MuiAutocomplete-listbox': {
        p: '6px 3px 6px 12px',
        maxHeight: (32 * 6 + 12) + 'px',
      },
    }}
  />
);


function BatchContrain(props) {
  function sleep(delay = 0) {
    return new Promise(resolve => {
      setTimeout(resolve, delay);
    });
  }

  const [CEData, setCEData] = useState([
    {
      Id: 1,
      username: 'CEA',
      email: '111@11.com',
    },
    {
      Id: 2,
      username: 'CEB',
      email: '111@11.com',
    },
    {
      Id: 3,
      username: 'CEC',
      email: '111@11.com',
    },
    {
      Id: 4,
      username: 'CED',
      email: '111@11.com',
    },
    {
      Id: 5,
      username: 'CEE',
      email: '111@11.com',
    },
  ]);

  const {
    open,
    handleClose,
    CEID,
    setCEID,
    currentSelected,
    setCurrentSelected,
    handleConfirmOpen,
    ifButtonDisable,
  } = props;
  const [repeatError, setRepeatError] = useState(false);
  const [CEValue, setCEValue] = useState(null); //这个是用户选择的CE值
  const [Ce, setCe] = useState(''); //这个是输入框中的变化值

  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);

  const dispatch = useDispatch();

  const selector = state => {
    return {
      selected: state.Selete.selectedList,
      // selectedInfo: state.Selete.selectedListInfo,
      // selectedInfo: state.SI.selectedInfoMap,
      // loading: state.SI.selectedListInfoLoading,
      CEResult: state.SI.CE,
    };
  };
  // const { selected, selectedInfo, CEResult,loading } = useSelector(selector);
  const { selected, CEResult } = useSelector(selector);
  const [selectedInfo, setSelectInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const containerRef = useRef(null);
  const containerBoxRef = useRef(null);
  const scrollYRef = useRef(null);

  useState(async () => {
    // console.info("CEBatch:",open,selected);
    // if (open){
    setLoading(true);
    try {
      let params = paramsEncode({
        pageNum: 1,
        pageSize: selected.length,
        siTitleList: '',
        orderType: 'asc',
        orderField: 'siCode',
      });

      let res = await axios_instance.post('/api/v1/si-service/special-issues/overview', {
        siCode: selected,
        ...params
      }, {
        headers: {
          authorization: localStorage.getItem('authorization'),
        },
      });

      if (res.data.code === 200) {
        const map = {};
        for (let si of res.data.data.result) {
          map[si.siCode] = si;
        }
        setSelectInfo(map);
      } else {
        setSelectInfo({});
      }
    } catch (err) {
      setSelectInfo({});
    }
    setLoading(false);
    // }
  }, [open]);

  useEffect(() => {
    const containerElement = containerRef.current;
    const containerBoxElement = containerBoxRef.current;
    setTimeout(() => {
      dispatch(
        contextHeightChanged({
          contextWidth: containerElement.offsetWidth,
          contextHeight: containerElement.offsetHeight,
          boxWidth: containerBoxElement.offsetWidth,
          boxHeight: containerBoxElement.offsetHeight,
        })
      );
    }, 100);
  }, [currentSelected, loading]);

  const transformOptionTooltipFormat = option => {
    let result =
      option.split('(')[0] + ': ' + option.split('(')[1].split(')')[0];
    return result;
  };

  useEffect(() => {
    let result = CEResult;
    if (result !== null && result !== undefined && result !== 'error') {
      setCEData(result);
    }
  }, [CEResult]);

  useEffect(() => {
    setCEID(-1);
    setCEValue('');
    setCurrentSelected(selected);
    if (open) {
      dispatch(getActiveCE(''));
      setRepeatError(false);
    }
  }, [open]);

  useEffect(() => {
    if (selectedInfo != {}) {
      selected.map(id => {
        if (
          selectedInfo[id] !== undefined &&
          CEID !== undefined &&
          selectedInfo[id].handlingCe.userId === CEID
        ) {
          dispatch(removeSelected(id));
          setRepeatError(true);
        }
      });

      if (CEValue === null) {
        dispatch(getActiveCE(''));
      }
    }

  }, [CEValue, selectedInfo]);

  //延迟获取CE值
  useEffect(() => {
    if (Ce !== '') {
      let active = true;
      (async () => {
        await sleep(1e2); // For demo purposes.
        if (active) {
          dispatch(getActiveCE(Ce));
          // setCEData1(CEData)
        }
      })();

      return () => {
        active = false;
      };
    } else {
      dispatch(getActiveCE(''));
    }
  }, [Ce]);

  const filterOptions = x => {
    return x;
  };

  const handleCeChange = event => {
    setCe(event.target.value);
  };

  const handleCeSelect = value => {
    setCEValue(value);
    setRepeatError(false);

    let tmpId = -1;
    if (value != null && value != '') {
      var tmpEmail = value.split('(')[1];
      tmpEmail = tmpEmail.split(')')[0];
      for (var i = 0; i < CEData.length; i++) {
        if (CEData[i].email === tmpEmail) {
          tmpId = CEData[i].userId;
          break;
        }
      }
    }
    setCEID(tmpId);
  };

  const isSelected = (name, index) => {
    // console.log(selected.indexOf(name));
    return selected.indexOf(name) !== -1;
  };

  //处理单击复选框事件
  const handleCheck = SIInfo => {
    const selectedIndex = selected.indexOf(SIInfo.siCode);
    if (selectedIndex > -1) {
      dispatch(removeSelected(SIInfo.siCode));
    } else {
      dispatch(addSelected(SIInfo));
    }
    // if(selected.length == 0){
    //   handleClose();
    // }
  };

  // 处理全选事件
  const handleSelectAll = e => {
    let indeterminate =
      selected.length > 0 && selected.length < currentSelected.length;
    currentSelected.forEach(siCode => {
      const SIInfo = selectedInfo[siCode];
      if (
        SIInfo !== undefined &&
        CEID !== undefined &&
        SIInfo.handlingCe.userId === CEID
      ) {
        //all:checked false, indeterminate false remove
        //indeterminate: checked true, indeterminate true remove
        // empty: chcecked true, indeterminate false add
      } else {
        dispatch(
          e.target.checked !== indeterminate
            ? addSelected(SIInfo)
            : removeSelected(SIInfo.siCode)
        );
      }
    });
  };

  const isFirefox = navigator.userAgent.includes('Firefox');

  return (
    <div>
      <span
        style={{
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontWeight: 400,
          marginRight: '2px',
          lineHeight: '20px',
          width: '158px',
          color: '#000000',
          position: 'absolute',
          left: '32px',
          top: '74px',
        }}
      >
        Transfer special issue to
      </span>
      <FormControl
        variant='filled'
        sx={{
          width: '380px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '0px',
          gap: '2px',
          position: 'absolute',
          left: '195px',
          top: '64px',
        }}
      >
        <SelectedInput
          // disablePortal
          // disableClearable='false'
          id='combo-box-demo'
          data-selenium-id='SIPage_Overview-CEBatchUpdate-BatchContrain-ComboBoxDemo'
          options={CEData.map(
            option => option.username + '(' + option.email + ')'
          )}
          value={CEValue}
          filterOptions={filterOptions}
          renderOption={(props, option, state) => {
            const MAX_OPTION_LENGTH = 50;
            if (option.length > MAX_OPTION_LENGTH) {
              return (
                <CustomTooltip
                  title={transformOptionTooltipFormat(option)}
                  placement='top'
                >
                  <List
                    {...props}
                    sx={{
                      lineHeight: '20px',
                      fontSize: '12px',
                      fontWeight: 400,
                      fontFamily: 'Open Sans',
                    }}
                  >
                    {option.slice(0, MAX_OPTION_LENGTH) + '...'}
                  </List>
                </CustomTooltip>
              );
            }
            return (
              <List
                {...props}
                sx={{
                  lineHeight: '20px',
                  fontSize: '12px',
                  fontWeight: 400,
                  fontFamily: 'Open Sans',
                }}
              >
                {option}
              </List>
            );
          }}
          onChange={(event, newValue) => {
            handleCeSelect(newValue);
          }}
          // clearOnBlur={true}
          // clearOnEscape={true}
          // onBlur={(event) => {
          //   let newValue = event.target.value;
          //   if (newValue==''){
          //     console.info('set empty');
          //     handleCeSelect(newValue);
          //   }
          // }}
          // onOpen={() => setIsAutocompleteOpen(true)}
          // onClose={() => setIsAutocompleteOpen(false)}
          // PopperComponent={defaultPopperComponent}
          sx={{
            width: '370px',
            height: '40px',
            '& .MuiInputBase-root.MuiOutlinedInput-root': {
              paddingTop: 0,
              paddingBottom: 0,
            },
            '& .MuiAutocomplete-paper': {
              mt: '6px',
            }
          }}
          ListboxProps={{
            sx: {
              '& li': {
                lineHeight: '20px',
                fontSize: '12px',
                fontWeight: 400,
                fontFamily: 'Open Sans',
              },
              maxHeight: '186px',
            },
          }}
          classes={{
            option: {
              height: '14px',
            },
          }}
          popupIcon={<></>}
          renderInput={params => (
            <TextField
              onChange={handleCeChange}
              label='Search User'
              placeholder='Search User'
              {...params}
              InputLabelProps={{ shrink: 'true' }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              error={repeatError}
              sx={{
                fontSize: '14px',
                lineHeight: '17.5px',
                backgroundColor: '#FFFFFF',
                width: '370px',
                height: '40px',
                padding: 0,
                '.MuiInputLabel-root': {
                  position: 'absolute',
                  transform: 'none',
                  display: 'none',
                },
                '& .MuiAutocomplete-input': {
                  paddingLeft: '0 !important',
                },
                // '& .MuiInputAdornment-root': {
                //   marginLeft: '-2px',
                // },
                '& .MuiOutlinedInput-root': {
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  // paddingTop: '11px',
                  // paddingBottom: '11px',
                  overflow: 'hidden',
                  color: '#262E35',
                  textOverflow: 'ellipsis',
                  whitespace: 'nowrap',
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '125%', /* 17.5px */
                },
                '&.MuiOutlinedInput-input.MuiInputBase-input': {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '2px solid #0052CC !important',
                },
                '& fieldset.MuiOutlinedInput-notchedOutline': {
                  top: 0,
                  borderColor: '#DFE4E8',
                  borderRadius: '4px',
                },
                legend: {
                  display: 'none',
                },
              }}
            />
          )}
        />
        {repeatError && (
          <span
            style={{
              color: '#EE5350',
              fontSize: '12px',
              fontWeight: '400',
              marginTop: 2,
            }}
          >
            <svg
              width='10'
              height='10'
              viewBox='0 0 10 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M5.46895 0.0933113C5.61762 0.154915 5.75269 0.245207 5.86645 0.35903L9.64066 4.13263C9.75458 4.2464 9.84495 4.38152 9.90661 4.53024C9.96826 4.67897 10 4.83839 10 4.99939C10 5.16039 9.96826 5.31981 9.90661 5.46853C9.84495 5.61726 9.75458 5.75237 9.64066 5.86615L5.86645 9.63974C5.75269 9.75357 5.61762 9.84386 5.46895 9.90546C5.32028 9.96707 5.16093 9.99877 5 9.99877C4.83907 9.99877 4.67972 9.96707 4.53105 9.90546C4.38238 9.84386 4.24731 9.75357 4.13355 9.63974L0.359336 5.86615C0.245422 5.75237 0.155052 5.61726 0.0933942 5.46853C0.0317363 5.31981 0 5.16039 0 4.99939C0 4.83839 0.0317363 4.67897 0.0933942 4.53024C0.155052 4.38152 0.245422 4.2464 0.359336 4.13263L4.13355 0.35903C4.24731 0.245207 4.38238 0.154915 4.53105 0.0933113C4.67972 0.0317076 4.83907 0 5 0C5.16093 0 5.32028 0.0317076 5.46895 0.0933113ZM4.56732 6.04366C4.68207 6.15841 4.83771 6.22288 5 6.22288C5.16228 6.22288 5.31792 6.15841 5.43268 6.04366C5.54743 5.92891 5.6119 5.77327 5.6119 5.61098V2.55147C5.6119 2.38918 5.54743 2.23354 5.43268 2.11879C5.31792 2.00404 5.16228 1.93957 5 1.93957C4.83771 1.93957 4.68207 2.00404 4.56732 2.11879C4.45256 2.23354 4.3881 2.38918 4.3881 2.55147V5.61098C4.3881 5.77327 4.45256 5.92891 4.56732 6.04366ZM4.56732 7.87937C4.68207 7.99412 4.83771 8.05859 5 8.05859C5.16228 8.05859 5.31792 7.99412 5.43268 7.87937C5.54743 7.76461 5.6119 7.60897 5.6119 7.44669C5.6119 7.2844 5.54743 7.12876 5.43268 7.01401C5.31792 6.89925 5.16228 6.83478 5 6.83478C4.83771 6.83478 4.68207 6.89925 4.56732 7.01401C4.45256 7.12876 4.3881 7.2844 4.3881 7.44669C4.3881 7.60897 4.45256 7.76461 4.56732 7.87937Z'
                fill='#EE5350'
              />
            </svg>
            <span>
              &nbsp;You cannot transfer below highlight SI to the same Handling
              CE
            </span>
          </span>
        )}
        {!repeatError && (
          <span
            style={{
              color: '#EE5350',
              fontSize: '12px',
              fontWeight: '400',
              marginTop: 2,
            }}
          ></span>
        )}
      </FormControl>
      {/* <p>Special Issues will be transfered </p> */}
      <Box
        sx={{
          width: '531px',
          // height: '337px',
          left: '34px',
          top: '124px',
          position: 'relative',
          border: '1px solid #DFE4E8',
          borderRadius: '8px',
        }}
      >
        <TableContainer
          ref={containerBoxRef}
          sx={{
            overflowX: 'auto',
            overflowY: 'scroll',
            width: '531px',
            height: '100%',
            maxHeight: '303px',
            '&::-webkit-scrollbar:vertical': {
              width: 0,
            },
            '&::-webkit-scrollbar-thumb:vertical': {
              backgroundColor: 'transparent',
            },
          }}
          data-selenium-id='SIPage_Overview-CEBatchUpdate-BatchContrain-TableContainer'
          onScroll={scrollYRef.current?.scrollAsContext}
        >
          <Table
            ref={containerRef}
            stickyHeader
            aria-label='simple table'
            sx={{
              width: '531px',
              minHeight: 'fit-content',
            }}
            data-selenium-id='SIPage_Overview-CEBatchUpdate-BatchContrain-Table'
          >
            {/* <MyTableHeader
              // items={items}
              hasCheckbox={true} // width is defined here
              width="530px"
            /> */}
            {/* <MyTableHeader items={["1", "2", "3"]} /> */}
            <TableHead data-selenium-id='SIPage_Overview-CEBatchUpdate-BatchContrain-TableHead'>
              <TableRow
                data-selenium-id='SIPage_Overview-CEBatchUpdate-BatchContrain-TableHead-TableRow'
                sx={{
                  position: 'sticky',
                  left: 0,
                  top: 0,
                  zIndex: 2,
                }}
              >
                <TableCell
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    padding: 0,
                    height: '42px',
                    backgroundColor: '#F1F3F5',
                    textTransform: 'uppercase',
                    '& div': {
                      color: '#596A7C',
                      fontSize: '12px',
                      fontWeight: '600',
                      letterSpacing: '0.08em',
                    },
                    borderRadius: '8px 8px 0px 0px',
                  }}
                  data-selenium-id='SIPage_Overview-CEBatchUpdate-BatchContrain-TableHead-TableCell'
                >
                  <Checkbox
                    onClick={handleSelectAll}
                    checked={selected.length === currentSelected.length}
                    indeterminate={
                      selected.length > 0 &&
                      selected.length < currentSelected.length
                    }
                    icon={<SIPOverviewUncheckedIcon />}
                    checkedIcon={<SIPOverviewCheckedIcon />}
                    indeterminateIcon={
                      <CATJournalIndeterminate />
                    }
                    color='wileyBlue2'
                    sx={{
                      width: '60px',
                      display: 'flex',
                      justifyContent: 'center',
                      '&:hover': {
                        bgcolor: 'transparent',
                        '& g rect:last-child': {
                          stroke: '#596A7C',
                        },
                      },
                      '& .MuiTouchRipple-root': {
                        position: 'unset',
                      },
                      '&.Mui-checked:hover, &.MuiCheckbox-indeterminate:hover': {
                        rect: {
                          fill: '#113D95',
                          stroke: '#113D95',
                        },
                      },
                    }}
                    data-selenium-id='SIPage_Overview-CEBatchUpdate-BatchContrain-TableHead-TableCell-Checkbox'
                  />
                  <div
                    className='batch-update-left-box'
                    style={{ minWidth: '146px', maxWidth: '146px' }}
                    data-selenium-id='SIPage_Overview-CEBatchUpdate-BatchContrain-TableHead-TableCell-HandlingCE'
                  >
                    Handling CE
                  </div>
                  <div
                    className='batch-update-content'
                    style={{ minWidth: '211px', maxWidth: '211px' }}
                    data-selenium-id='SIPage_Overview-CEBatchUpdate-BatchContrain-TableHead-TableCell-SpecialIssueTitle'
                  >
                    Special issue title
                  </div>
                  <div
                    className='batch-update-right-box'
                    style={{ minWidth: '108px', maxWidth: '108px' }}
                    data-selenium-id='SIPage_Overview-CEBatchUpdate-BatchContrain-TableHead-TableCell-JournalCode'
                  >
                    <span>
                      Journal
                      <br />
                      Code
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody
                data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody1'
                sx={{ backgroundColor: 'fff' }}
              >
                <TableSkeleton num={9} colSpan={1} />
              </TableBody>
            ) :
              (<TableBody
                sx={{
                  // maxHeight: '260px',
                  display: 'block',
                  overflowY: 'auto',
                  '& .MuiTableBody-root': { padding: 0 },
                }}
                data-selenium-id='SIPage_Overview-CEBatchUpdate-BatchContrain-TableBody'
              >
                {currentSelected.map((id, index) => {
                  // console.log(id, selectedInfo[id]);
                  const row = selectedInfo[id];
                  if (!row) return null;
                  // console.log(row);
                  const isItemSelected = isSelected(row.siCode);
                  return (
                    <TableRow
                      key={row.siCode}
                      sx={{
                        ...(row.handlingCe.userId === CEID
                          ? {
                            '&:last-child td, &:last-child th': { border: 0 },
                            background: 'rgb(255 236 236)',
                          }
                          : {
                            '&:last-child td, &:last-child th': { border: 0 },
                            backgroundColor: '#ffffff',
                          }),
                        width: '100%',
                      }}
                      data-selenium-id={`SIPage_Overview-CEBatchUpdate-BatchContrain-TableBody-TableRow-${index}`}
                    >
                      <StyledTableCell
                        align='center'
                        show={true}
                        style={{
                          'flex-direction': 'row',
                          display: 'flex',
                          width: '100%',
                          padding: 0,
                          height: '44px',
                        }}
                        data-selenium-id={`SIPage_Overview-CEBatchUpdate-BatchContrain-TableBody-TableRow${index}-StyledTableCell`}
                      >
                        <Box
                          sx={{
                            width: '60px',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Checkbox
                            color='wileyBlue2'
                            checked={isItemSelected}
                            disabled={row.handlingCe.userId === CEID}
                            icon={<SIPOverviewUncheckedIcon />}
                            checkedIcon={<SIPOverviewCheckedIcon />}
                            indeterminateIcon={<CATJournalIndeterminate />}
                            onClick={handleCheck.bind(this, row)}
                            sx={{
                              width: '60px',
                              display: 'flex',
                              justifyContent: 'center',
                              '&:hover': {
                                bgcolor: 'transparent',
                                '& g rect:last-child': {
                                  stroke: '#596A7C',
                                },
                              },
                              '& .MuiTouchRipple-root': {
                                position: 'unset',
                              },
                              '&.Mui-checked:hover, &.MuiCheckbox-indeterminate:hover': {
                                rect: {
                                  fill: '#113D95',
                                  stroke: '#113D95',
                                },
                              },
                            }}
                            data-selenium-id={`SIPage_Overview-CEBatchUpdate-BatchContrain-TableBody-TableRow${index}-Checkbox`}
                          />
                        </Box>
                        {(!isAutocompleteOpen && row.handlingCe.siUserName !== null && row.handlingCe.siUserName.length > 12) ? (
                          <CustomTooltip
                            placement='top'
                            title={row.handlingCe.siUserName}
                            data-selenium-id={`SIPage_Overview-CEBatchUpdate-BatchContrain-TableBody-TableRow${index}-UserName`}
                            sx={{
                              [`& .${tooltipClasses.tooltip}`]: {
                                marginTop: '2px !important',
                                marginBottom: '2px !important',
                              }
                            }}
                          >
                            <div
                              className='batch-update-left-box'
                              style={{ minWidth: '146px', maxWidth: '146px' }}
                            >
                              <div className='ellipsis'>
                                {row.handlingCe.siUserName}
                              </div>
                            </div>
                          </CustomTooltip>
                        ) : (
                          <div
                            className='batch-update-left-box'
                            style={{ minWidth: '146px', maxWidth: '146px' }}
                          >
                            <div className='ellipsis'>
                              {row.handlingCe.siUserName}
                            </div>
                          </div>
                        )}
                        {(!isAutocompleteOpen && row.specialIssueTitle.length > 20) ? (
                          <CustomTooltip
                            placement='top'
                            title={row.specialIssueTitle}
                            data-selenium-id={`SIPage_Overview-CEBatchUpdate-BatchContrain-TableBody-TableRow${index}-SpecialIssueTitle`}
                            sx={{
                              [`& .${tooltipClasses.tooltip}`]: {
                                marginTop: '2px !important',
                                marginBottom: '2px !important',
                              }
                            }}
                          >
                            <div
                              className='batch-update-content'
                              style={{ minWidth: '211px', maxWidth: '211px' }}
                            >
                              <div className='ellipsis'>
                                {row.specialIssueTitle}
                              </div>
                            </div>
                          </CustomTooltip>
                        ) : (
                          <div
                            className='batch-update-content'
                            style={{ minWidth: '211px', maxWidth: '211px' }}
                          >
                            <div className='ellipsis'>
                              {row.specialIssueTitle}
                            </div>
                          </div>
                        )}
                        {(!isAutocompleteOpen && row.journalInfoVo !== null && row.journalInfoVo.journalCode.length > 7) ? (
                          <CustomTooltip
                            placement='top'
                            title={row.journalInfoVo.journalCode}
                            data-selenium-id={`SIPage_Overview-CEBatchUpdate-BatchContrain-TableBody-TableRow${index}-JournalCode`}
                            sx={{
                              [`& .${tooltipClasses.tooltip}`]: {
                                marginTop: '2px !important',
                                marginBottom: '2px !important',
                              }
                            }}
                          >
                            <div
                              className='batch-update-right-box'
                              style={{ minWidth: '108px', maxWidth: '108px' }}
                            >
                              <div className='ellipsis'>
                                {row.journalInfoVo.journalCode}
                              </div>
                            </div>
                          </CustomTooltip>
                        ) : (
                          <div
                            className='batch-update-right-box'
                            style={{ minWidth: '108px', maxWidth: '108px' }}
                          >
                            <div className='ellipsis'>
                              {row.journalInfoVo === null
                                ? ''
                                : row.journalInfoVo.journalCode}
                            </div>
                          </div>
                        )}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>)
            }
          </Table>
        </TableContainer>
        <TableFooter
          sx={{
            minHeight: '34px',
            maxHeight: '44px',
            display: 'block',
            backgroundColor: '#F1F3F5',
            borderRadius: '0px 0px 8px 8px',
          }}
        ></TableFooter>
        {!isFirefox && (<ScrollBarY
          ref={scrollYRef}
          contextHolderRef={containerBoxRef}
          sx={{
            bottom: '40px',
          }}
        />)}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          width: '536px',
          height: '66px',
          left: '32px',
          // top: '466px',
          top: '470px',
        }}
      >
        <SIMTButton
          variant='contained'
          color='wileyBlue2'
          onClick={handleConfirmOpen}
          disabled={ifButtonDisable}
          data-selenium-id='SIPage_Overview-CEBatchUpdate-BatchContrain-SIMTButton'
          sx={{
            '&.MuiButton-root.MuiButton-contained': {
              fontWeight: '500',
              letterSpacing: '0.01em',
            },
            '&.Mui-disabled': {
              backgroundColor: '#DFE4E8',
              color: '#98A7B6',
            },
            '&:hover': {
              backgroundColor: '#6D98EE',
            },
            '&:active': {
              backgroundColor: '#113D95',
            },
            backgroundColor: '#0052CC',
            position: 'absolute',
            width: '70px',
            height: '34px',
            left: '463px',
            top: '12px',
          }}
        >
          Transfer
        </SIMTButton>
        <SIMTButton
          variant='outlined'
          color='wileyBlue2'
          onClick={handleClose}
          data-selenium-id='SIPage_Overview-CEBatchUpdate-BatchContrain-SIMTButton-Cancel'
          sx={{
            position: 'absolute',
            width: '70px',
            height: '34px',
            left: '384px',
            top: '12px',
            color: '#0052cc',
            borderColor: '#0052cc',
          }}
        >
          Cancel
        </SIMTButton>
      </Box>
    </div>
  );
}

function CEBatchUpdate(props) {
  const { open, handleFirstClose } = props;
  const [SecondStageOpen, setSecondStageOpen] = useState(false);
  const [isEmpty, setEmpty] = useState(true);
  const [CEID, setCEID] = useState(-1); //这个是用户选择的CEid值
  const [currentSelected, setCurrentSelected] = useState([]);

  const dispatch = useDispatch();

  const [confirmOpen, setConfirmOpen] = useState(false);

  const selector = state => {
    return {
      SIInfoResult: state.SI.detailInfo,
      SITransferResult: state.SI.transferSIResult,
      selected: state.Selete.selectedList,
      page: state.SI.page,
      perPageNumber: state.SI.pageSize,
      orderByType: state.SI.orderByType,
      searchSI: state.Filter.searchSI,
      useFilters: state.Filter.useFilters,
      localSIRequestNo: state.SI.localSIRequestNo,
    };
  };
  const {
    SIInfoResult,
    SITransferResult,
    selected,
    page,
    perPageNumber,
    orderByType,
    searchSI,
    useFilters,
    localSIRequestNo,
  } = useSelector(selector);

  const handleClose = () => {
    //包装close函数 清空上次数据
    setCEID(-1);
    handleFirstClose();
  };

  useEffect(() => {
    if (open && currentSelected.length > 0) {
      let result = SITransferResult;
      let withCMOnly = false;
      if (result !== 'error' && result !== '' && result !== 'OK') {
        dispatch(setTextParam(result));
        dispatch(setEditType('SITransfer'));
        dispatch(openConfirm());
        handleClose();
        dispatch(cancelSelectAll());
        dispatch(appendSIReqId()); // 注：以后在调用getSIData之前都需要调用这个
        dispatch(
          getSIData(
            page,
            perPageNumber,
            orderByType.order,
            orderByType.by,
            searchSI,
            useFilters,
            localSIRequestNo, // 务必把这个参数传入，否则很可能会出问题
            withCMOnly,
          )
        );
        setConfirmOpen(false);
        setCurrentSelected([]);
      } else {
        handleClose();
        setConfirmOpen(false);
      }
      setCEID(-1);
      dispatch(createSIClear());
    }
  }, [SITransferResult]);

  const handleConfirmOpen = () => {
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const TransferSI = async () => {
    var SICodes = [];
    for (var i = 0; i < selected.length; i++) {
      SICodes.push({ siCode: selected[i], userId: CEID });
    }
    // console.log(SICodes);
    dispatch(batchTransferSI(SICodes));
  };

  // console.log(`CEID: ${CEID}; selectedLength: ${selected.length}`);

  const container = (
    <div>
      <BatchContrain
        open={SecondStageOpen}
        handleClose={handleClose}
        CEID={CEID}
        setCEID={setCEID}
        currentSelected={currentSelected}
        setCurrentSelected={setCurrentSelected}
        handleConfirmOpen={handleConfirmOpen}
        ifButtonDisable={CEID < 0 || selected.length === 0}
        data-selenium-id='SIPage_Overview-CEBatchUpdate-BatchContrain'
      ></BatchContrain>
      <CommonDialog
        title={
          <FormattedMessage
            id={'siPage.bulkTransferTitle'}
            data-selenium-id='SIPage_Overview-CEBatchUpdate-CommonDialog-BulkTransferTitle'
          />
        }
        content='Are you sure you want to proceed ?'
        handleClose={handleConfirmClose}
        handleConfirm={TransferSI}
        alertOpen={confirmOpen}
        data-selenium-id='SIPage_Overview-CEBatchUpdate-CommonDialog'
      />
    </div>
  );

  return (
    <div data-selenium-id='SIPage_Overview-CEBatchUpdate'>
      <Dialog
        // title='Bulk Transfer'
        open={open}
        onClose={handleClose}
        sx={{
          '& .SIModalPro-container': {
            padding: '24px 32px 24px 32px',
            borderRadius: '5px',
          },
          '& .MuiTypography-root': { fontSize: '18px' },
          '& .MuiDialog-paper': {
            minWidth: '600px',
            minHeight: '542px',
            margin: 0,
            padding: 0,
          },
          background: 'rgba(17, 61, 149, 0.5)',
          backdropFilter: 'blur(4px)',
        }}
        PaperProps={{
          style: {
            boxShadow: 'none',
          },
        }}
        slotProps={{
          backdrop: {
            invisible: true,
          },
        }}
        data-selenium-id='SIPage_Overview-CEBatchUpdate-SIModal'
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: 0,
            gap: '16px',
            position: 'absolute',
            width: '536px',
            height: '24px',
            left: '32px',
            top: '24px',
          }}
        >
          <span
            style={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '125%',
              color: '#262E35',
              flex: 'none',
              order: 0,
              flexGrow: 0,
            }}
          >
            Bulk Transfer
          </span>
          <CloseButton onClick={handleClose} />
        </Box>
        {container}
      </Dialog>
    </div>
  );
}

function SearchIcon() {
  return (
    <>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M7.33398 2.66671C4.75666 2.66671 2.66732 4.75605 2.66732 7.33337C2.66732 9.9107 4.75666 12 7.33398 12C9.91131 12 12.0007 9.9107 12.0007 7.33337C12.0007 4.75605 9.91131 2.66671 7.33398 2.66671ZM1.33398 7.33337C1.33398 4.01967 4.02028 1.33337 7.33398 1.33337C10.6477 1.33337 13.334 4.01967 13.334 7.33337C13.334 10.6471 10.6477 13.3334 7.33398 13.3334C4.02028 13.3334 1.33398 10.6471 1.33398 7.33337Z'
          fill='#596A7C'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M10.6289 10.6286C10.8892 10.3683 11.3113 10.3683 11.5717 10.6286L14.4717 13.5286C14.732 13.789 14.732 14.2111 14.4717 14.4714C14.2113 14.7318 13.7892 14.7318 13.5289 14.4714L10.6289 11.5714C10.3685 11.3111 10.3685 10.889 10.6289 10.6286Z'
          fill='#596A7C'
        />
      </svg>
    </>
  );
}

function CheckedCheckboxIcon() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='3'
        y='3'
        width='12'
        height='12'
        rx='2'
        stroke='#BCC5CF'
        stroke-width='2'
      />
      <rect x='2' y='2' width='14' height='14' rx='3' fill='#0052CC' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M6.71402 8.30066C6.62206 8.20534 6.51205 8.12931 6.39042 8.07701C6.26879 8.0247 6.13797 7.99717 6.0056 7.99602C5.87323 7.99487 5.74195 8.02012 5.61943 8.0703C5.49691 8.12048 5.3856 8.19458 5.292 8.28829C5.19839 8.38199 5.12437 8.49342 5.07424 8.61607C5.02411 8.73872 4.99889 8.87013 5.00004 9.00265C5.00119 9.13516 5.02869 9.26612 5.08094 9.38787C5.13319 9.50963 5.20914 9.61976 5.30435 9.71182L7.29823 11.7078C7.48518 11.8949 7.73871 12 8.00306 12C8.26741 12 8.52094 11.8949 8.7079 11.7078L12.6956 7.71584C12.7909 7.62377 12.8668 7.51365 12.9191 7.39189C12.9713 7.27013 12.9988 7.13918 13 7.00666C13.0011 6.87415 12.9759 6.74274 12.9258 6.62009C12.8756 6.49744 12.8016 6.38601 12.708 6.29231C12.6144 6.1986 12.5031 6.1245 12.3806 6.07432C12.258 6.02414 12.1268 5.99889 11.9944 6.00004C11.862 6.00119 11.7312 6.02872 11.6096 6.08102C11.4879 6.13333 11.3779 6.20936 11.286 6.30468L8.00306 9.59106L6.71402 8.30066Z'
        fill='#F6F7F8'
      />
    </svg>
  );
}

function CheckboxIcon() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='3'
        y='3'
        width='12'
        height='12'
        rx='2'
        stroke='#BCC5CF'
        stroke-width='2'
      />
    </svg>
  );
}

export default CEBatchUpdate;

import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { Box } from '@mui/system';
import { MyTableHeader } from '../../../../components/DataTable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MyTableRow from './WaitingForTableRow';
import { ApprovalConfirm, RejectConfirm } from './WaitingForSnackBar';
import {getWaitingForApprovalInfo} from "@/actions/SIMT-Report/ReportJobsSubmission";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return (a, b) => descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export function WaitingForApproval() {
  const { WaitingForData } = useSelector(state => {
    return {
      WaitingForData: state.ReportJobs.waitingForApprovalList,
    };
  });
  // 为了测试no data
  // const WaitingForData = []
  const [switch1, setSwitch] = useState(true);
  const dispatch = useDispatch();

  const tableHeadItem = [
    {
      id: 'ReportName',
      label: 'Report Name',
      minWidth: '233px',
      maxWidth: '233px',
      isOrder: false,
    },
    {
      id: 'SelectedMonth',
      label: 'Selected Month',
      minWidth: '245px',
      maxWidth: '245px',
      isOrder: false,
    },
    {
      id: 'SubmittedBy',
      label: 'Submitted By',
      minWidth: '235px',
      maxWidth: '235px',
      isOrder: false,
    },
    {
      id: 'SubmittedTime',
      label: 'Submitted AT',
      minWidth: '245px',
      maxWidth: '245px',
      isOrder: true,
    },
    {
      id: 'Actions',
      label: 'Actions',
      minWidth: '176px',
      maxWidth: '176px',
      isOrder: false,
    },
  ];

  const [orderType, setOrderType] = useState('desc');
  const [sortKey, setSortKey] = useState('SubmittedTime');

  useEffect(() => {
    dispatch(getWaitingForApprovalInfo(orderType));
  }, [sortKey, orderType, dispatch]);

  const handleRequestSort = (_event, property) => {
    const isAsc = sortKey === property && orderType === 'asc';
    setOrderType(isAsc ? 'desc' : 'asc');
    setSortKey(property);
  };

  return (
    <Box data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForApprovalTable-Box'>
      <TableContainer
        data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForApprovalTable-Box-TableContainer'
        stickyHeader
        aria-label='simple table'
        size='small'
        sx={{
          minHeight: '397px',
          backgroundColor: '#fff',
          border: '1px solid #DFE4E8',
          borderRadius: '8px',
        }}
      >
        <Table data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForApprovalTable-Box-TableContainer-Table'>
          <MyTableHeader
            id='ReportJobsWaitingForApprovalHead'
            data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForApprovalTable-Box-TableContainer-Table-MyTableHeader'
            order={orderType}
            orderBy={sortKey}
            items={tableHeadItem}
            onRequestSort={handleRequestSort}
          ></MyTableHeader>
          <TableBody data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForApprovalTable-Box-TableContainer-Table-TableBody'>
            {WaitingForData !== null &&
            WaitingForData !== undefined &&
            WaitingForData.length !== 0 ? (
              WaitingForData.map((item, index) => {
                return (
                  <MyTableRow
                    key={`systemAdminReportJobsWaitingForApprovalTableRow${index}`}
                    id={`systemAdminReportJobsWaitingForApprovalTableRow${index}`}
                    data-selenium-id={`SystemAdmin-ReportJobsWaitingForApprovalTableRow-${index}`}
                    index={index}
                    item={item}
                    everyClick={switch1}
                    setEveryClick={setSwitch}
                  />
                );
              })
            ) : (
              <TableRow style={{ height: '200px' }}>
                <TableCell
                  id='WaitingForApproveNoResultTableCell'
                  data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForApprovalTable-Box-TableContainer-Table-TableBody-TableRow-TableCell'
                  colSpan={13}
                  align='center'
                  sx={{ fontSize: '20px', border: 0 }}
                >
                  No data to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ApprovalConfirm data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForApprovalTable-Box-ApprovalConfirm' />
      <RejectConfirm data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForApprovalTable-Box-RejectConfirm' />
    </Box>
  );
}
export default WaitingForApproval;

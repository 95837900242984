import { RegularBoldFont } from '@/components/FontStyle';

export const cssFramework = {
  position:'relative',
  width: '588px',
  borderRadius: '5px',
  background: '#FFFFFF',
  boxShadow: '0px 6px 12px 0px #262E351F',
};

export const cssFrameworkHeader = {
  position: 'absolute',
  top: '0px',
  width: '100%',
  height: '69px',
  padding: '16px 11px 0px 16px',
  borderRadius: '5px 5px 0px 0px',
  zIndex:'1',
  backgroundColor:'#FFFFFF'
};

export const cssFrameworkHeaderLeftBox = {
  float: 'left',
};

export const cssFrameworkHeaderRightBox = {
  float: 'right',
};

export const cssSection = {
  width:'556px',
  background: '#FAFAFA',
  borderRadius: '5px',
  boxShadow:'0px 2px 8px 0px #262E351A',
  padding: '16px 16px 24px 16px'
};

export const cssItemTitle = {
  ...RegularBoldFont,
  color: '#596A7C'
};
import { useState, useEffect, useRef, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import styles from './index.module.scss';
import {
  StageSelector,
  GroupedSelect,
} from '@/components/Chart/ChartSelectors';
import CloseIcon from '@/assets/CloseIcon.svg';
import Chart from './Chart';
import useGetOriginDataCEHook from './hooks/useGetOriginDataCEHook';
import useGetChartDataHook from './hooks/useGetChartDataHook';
import useGetDiagramStatusHook from '../useGetDiagramStatusHook';
import ReportSaveMenuButton from '@/components/Chart/chartButton';
import { ReportDownloadMenuButton } from '@/components/Chart/chartButton';
import NoDataPage from '@/components/Chart/NoDataPage';
import LoadingPage from '@/components/Chart/LoadingPage';
import ErrorPage from '@/components/Chart/ErrorPage';
import {PUBLISHED_IN_SELECTED_FY, PUBLISHED, SI_STAGES} from '../constants.js';
import {
  saveImageAsPng,
  saveImageAsJpg,
  toActiveStageArray,
} from '@/pages/Report/chartUtils';
import { getReportExcelParam } from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import {
  LOADING,
  ERROR,
  VALID,
  NO_DATA,
  NO_SELECTION_FROM_DROPDOWN_MENU,
  NO_DISPLAYED_DATA,
  NO_DISPLAYED_DATA_FOR_SELECTED_STAGE,
} from '@/pages/Report/chartUtils/constants.js';
import { getDownloadFileName } from '@/pages/Report/utils/getDownloadFileName';

const SI_STAGES_OBJ = {};
SI_STAGES.forEach(stage => {
  SI_STAGES_OBJ[stage] = true;
});

const ChartContainer = ({
  header,
  subHeader,
  cancelSelectedChart,
  selectedMonth,
  hasCategoryFilter = false,
  catagoryAttributeName,
  url,
  tableDownloadTitle,
  tableDownloadCatagoryName,
  tableDownloadUrl,
}) => {
  const [selectedCatagories, setSelectedCatagories] = useState([]);

  const [selectedLegendObject, setSelectedLegendObject] =
    useState(SI_STAGES_OBJ);

  //  reset stage selector if chart type changes
  useEffect(() => {
    setSelectedLegendObject(SI_STAGES_OBJ);
  }, [header, subHeader]);

  const reduxTitle = header + '_' + subHeader + '_' + selectedMonth;

  const [unfilteredCEData, allCEs, groupedCEFullList, loading, error] =
    useGetOriginDataCEHook(
      catagoryAttributeName,
      url,
      reduxTitle,
      selectedMonth
    );

  // console.log('===allCEs===', allCEs);
  // console.log('===groupedCEFullList===', groupedCEFullList);
  // console.log('===loading===', loading);
  // console.log('===error===', error);

  const [dataForBar, dataForPie] = useGetChartDataHook(
    unfilteredCEData,
    selectedCatagories,
    catagoryAttributeName
  );

  const alertLabel = 'Handling CE';

  const { valid: digramStatus, info } = useGetDiagramStatusHook(
    unfilteredCEData,
    dataForBar,
    selectedCatagories,
    selectedLegendObject,
    hasCategoryFilter,
    alertLabel,
    loading,
    error
  );

  useEffect(() => {
    setSelectedCatagories(allCEs);
  }, [allCEs]);

  const [chartWidth, setChartWidth] = useState('100%');

  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);
  const downloadlink = useRef();

  const dispatch = useDispatch();

  // dependencies: Tab1, Tab2, Level, selected time,
  const fileName = useMemo(() => {
    const rmSpaceSubHeader = subHeader.replaceAll(' ', '');
    return getDownloadFileName(
      'SIPipeline',
      'Overview',
      tableDownloadTitle ?? rmSpaceSubHeader,
      selectedMonth,
      true
    );
  }, [tableDownloadTitle, subHeader, selectedMonth]);

  const downloadChart = () => {
    let selectedStages = toActiveStageArray(selectedLegendObject);
    selectedStages = selectedStages.map(function(stage) {
      return  stage === PUBLISHED_IN_SELECTED_FY ? PUBLISHED : stage;
    });
    const params = {
      [tableDownloadCatagoryName]: selectedCatagories,
      stages: selectedStages,
      withOa: false,
    };
    dispatch(getReportExcelParam(selectedMonth, tableDownloadUrl, fileName, params));
  };

  const savePng = () => {
    saveImageAsPng(chartRef.current, fileName);
  };

  const saveJpg = () => {
    saveImageAsJpg(chartRef.current, fileName);
  };

  // listen to chart-container size change
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      resizeHandler(entries);
    });
    resizeObserver.observe(chartContainerRef.current);
    return () => {
      if (chartContainerRef.current) {
        resizeObserver.unobserve(chartContainerRef.current);
      }
    };
  }, []);

  function resizeHandler(entries) {
    const chartContainer = entries.find(
      entry => entry.target == chartContainerRef.current
    );
    const width = chartContainer?.target.clientWidth;
    setChartWidth(width);
  }

  return (
    <div className={styles['chart-container']} ref={chartContainerRef}>
      <Box className={styles['chart-header']}>
        <div className={styles['selectors']}>
          {hasCategoryFilter && (
            <span id='category-select'>
              <GroupedSelect
                label={'Handling CE'}
                alertLabel={'Handling CE'}
                options={groupedCEFullList}
                selectedItems={selectedCatagories ?? []}
                setSelectedItems={setSelectedCatagories}
                disabled={[NO_DATA, LOADING, ERROR].indexOf(digramStatus) >= 0}
              />
            </span>
          )}
          <span id='legend-select'>
            <StageSelector
              options={SI_STAGES}
              selectedLegendObject={selectedLegendObject}
              setSelectedLegendObject={setSelectedLegendObject}
              disabled={[NO_DATA, LOADING, ERROR].indexOf(digramStatus) >= 0}
            />
          </span>
        </div>
        <div className={styles['chart-titles']}>
          <h1 className={styles['chart-title']}>{header}</h1>
          <h2 className={styles['chart-subtitle']}>{subHeader + ' Level'}</h2>
        </div>
        <div className={styles['buttons']}>
          <ReportDownloadMenuButton
            func={downloadChart}
            disabled={digramStatus !== VALID}
          />
          <ReportSaveMenuButton
            items={[
              {
                label: 'Save as PNG',
                operation: savePng,
              },
              {
                label: 'Save as JPG',
                operation: saveJpg,
              },
            ]}
            disabled={digramStatus !== VALID}
          />
          <button
            onClick={cancelSelectedChart}
            className={styles['button-close']}
          >
            <CloseIcon />
          </button>
        </div>
      </Box>

      {digramStatus === LOADING && <LoadingPage />}

      {digramStatus === ERROR && <ErrorPage />}

      {digramStatus === NO_DATA && (
        <NoDataPage
          title={`There is no data for any ${info}s on the selected date.`}
          subTitle={'Please choose a different date to view diagram.'}
        />
      )}

      {digramStatus === NO_SELECTION_FROM_DROPDOWN_MENU && (
        <NoDataPage title={`Please select at least one ${info}.`} />
      )}

      {digramStatus === NO_DISPLAYED_DATA && hasCategoryFilter && (
        <NoDataPage
          title={`There is no data available for selected ${info} on selected date`}
          subTitle={`Please choose a different ${info} or date to view the diagram.`}
        />
      )}

      {digramStatus === NO_DISPLAYED_DATA_FOR_SELECTED_STAGE &&
        hasCategoryFilter && (
          <NoDataPage
            title={`There is no data available for selected ${info} at the selected stage`}
            subTitle={`Please choose a different ${info} or stage to view the diagram.`}
          />
        )}

      {digramStatus === NO_DISPLAYED_DATA && !hasCategoryFilter && (
        <NoDataPage
          title={`There is no data available for ${info} on selected date`}
          subTitle={`Please choose a different date to view the diagram.`}
        />
      )}

      {digramStatus === NO_DISPLAYED_DATA_FOR_SELECTED_STAGE &&
        !hasCategoryFilter && (
          <NoDataPage
            title={`There is no data available for ${info} at the selected stage`}
            subTitle={`Please choose a different stage to view the diagram.`}
          />
        )}

      {digramStatus === VALID && (
        <>
          <Box className={styles['chart']} ref={chartRef}>
            <Chart
              selectedLegend={selectedLegendObject}
              width={chartWidth}
              hasZoom={true}
              hasTitle={false}
              dataForPie={dataForPie}
              dataForBar={dataForBar}
              isDownload={false}
            />
          </Box>
          <Box className={styles['chart-download']} ref={chartRef}>
            <Chart
              selectedLegend={selectedLegendObject}
              width={chartWidth}
              hasZoom={false}
              hasTitle={true}
              title={header}
              subTitle={subHeader + ' Level'}
              dataForPie={dataForPie}
              dataForBar={dataForBar}
              isDownload={true}
            />
          </Box>
          <a
            href='javascript:void(0)'
            download
            ref={downloadlink}
            className={styles['download-link']}
          >
            hidden download link
          </a>
        </>
      )}
    </div>
  );
};

ChartContainer.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  selectedMonth: PropTypes.string.isRequired,
  cancelSelectedChart: PropTypes.func.isRequired,
  hasCategoryFilter: PropTypes.bool,
  catagoryAttributeName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  tableDownloadUrl: PropTypes.string,
};

export default ChartContainer;

import {
  IDEA_OR_PROPOSAL,
  ACQUIRED,
  PAPER_COMMISSION,
  SUBMISSION_AND_REVIEW,
  PRODUCTION,
  PUBLISHED_IN_SELECTED_FY,
  IDEA_OR_PROPOSAL_OA,
  ACQUIRED_OA,
  PAPER_COMMISSION_OA,
  SUBMISSION_AND_REVIEW_OA,
  PRODUCTION_OA,
  PUBLISHED_OA,
} from '../../constants.js';

/**
 * key: data series name for chart
 * value: the attribute name of API data
 */
export const APIKeyMap = {
  [IDEA_OR_PROPOSAL]: 'numIdeaOrProposal',
  [ACQUIRED]: 'numAcquired',
  [PAPER_COMMISSION]: 'numPaperCommission',
  [SUBMISSION_AND_REVIEW]: 'numSubmissionAndReview',
  [PRODUCTION]: 'numProduction',
  [PUBLISHED_IN_SELECTED_FY]: 'numPublishedFy',
  [IDEA_OR_PROPOSAL_OA]: 'numOA_IdeaOrProposal',
  [ACQUIRED_OA]: 'numOA_Acquired',
  [PAPER_COMMISSION_OA]: 'numOA_PaperCommission',
  [SUBMISSION_AND_REVIEW_OA]: 'numOA_SubmissionAndReview',
  [PRODUCTION_OA]: 'numOA_Production',
  [PUBLISHED_OA]: 'numOA_Published_Fy',
};

import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import { useEffect, useState } from 'react';
import {
  InputHelperText,
  MyFormHelperText,
} from '../../../../components/CreateAndUpdate/MyFormHelperText';
import {
  ErrVars,
  defaultVars,
} from '../../../../components/CreateAndUpdate/commonStyle';
import { BootstrapInput } from '../../../../components/CreateAndUpdate/MyOperationModule';
import { BootstrapTitle } from '../../../../components/CreateAndUpdate/MyTitle';
import { Box } from '@mui/system';
import ItemBlock from '../CGTComponents/ItemBlock';
import InputBox from '../CGTComponents/InputBox';

function CreateSIInputBlock(props) {
  const {
    title,
    required,
    id,
    emptyErr,
    defaultValue,
    placeholder,
    limitLength,
    exSpecialChar,
    setSCErr,
    setEmailErr,
    checkEmail,
    moreSpecialCheck,
    setValue,
    value,
    HandleChange,
    ...other
  } = props;

  const [Vars, setVars] = useState(defaultVars);
  const [specialChar, setSpecialChar] = useState(false);
  const [isEmail, setIsEmail] = useState(true);

  const specialCharPattern1 = new RegExp(
    "[`~!@#$^&*()=|{}':;,\\[\\].<>/?！￥…（）—【】‘；：”“。，、？]"
  );
  const specialCharPattern2 = new RegExp('\\\\');
  const emailPattern =
    /^\w+((-\w+)|(\.\w+)|(_\w+))*@[A-Za-z0-9]+(([.\-])[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

  const ifSpecialChar = text => {
    setSpecialChar(
      specialCharPattern1.test(text) || specialCharPattern2.test(text)
    );
    if (moreSpecialCheck !== undefined && moreSpecialCheck !== false) {
      setSpecialChar(
        specialCharPattern1.test(text) ||
          specialCharPattern2.test(text) ||
          moreSpecialCheck.test(text)
      );
    }
    if (setSCErr !== undefined) {
      setSCErr(
        specialCharPattern1.test(text) || specialCharPattern2.test(text)
      );
      if (moreSpecialCheck !== undefined && moreSpecialCheck !== false) {
        setSCErr(
          specialCharPattern1.test(text) ||
            specialCharPattern2.test(text) ||
            moreSpecialCheck.test(text)
        );
      }
    }
  };

  const checkIsEmail = text => {
    setIsEmail(emailPattern.test(text));
    setEmailErr(!emailPattern.test(text));
  };

  const handleChange = e => {
    if (limitLength !== undefined || limitLength !== null) {
      if (e.target.value.length <= limitLength) {
        if (setValue !== undefined) {
          setValue(e.target.value);
        }

        if (HandleChange !== undefined) {
          HandleChange(e.target.value);
        }
      }
    } else {
      if (setValue !== undefined) {
        setValue(e.target.value);
      }
      if (HandleChange !== undefined) {
        HandleChange(e.target.value);
      }
    }
  };

  const getErrorText = () => {
    let helperText = '';
    if (exSpecialChar && specialChar) {
      helperText = 'Please input positive integer number!';
    } else if (checkEmail && !isEmail) {
      helperText = 'The email is error';
    } else if (props.error) {
      helperText = props.helperText;
    } else {
      helperText = 'This field is required.';
    }
    return helperText;
  };

  const [stateHover, setStateHover] = useState(false);
  const [stateFocus, setStateFocus] = useState(false);

  const [totalError, setTotalError] = useState(false);

  useEffect(() => {
    setTotalError(
      emptyErr ||
        props.error ||
        (exSpecialChar && specialChar) ||
        (checkEmail && !isEmail)
    );
  }, [emptyErr, props.error, specialChar, isEmail, exSpecialChar, checkEmail]);

  return (
    <ItemBlock
      id={id}
      title={title}
      required={required}
      isError={totalError}
      errorText={getErrorText()}
      {...other}
    >
      <InputBox
        id={`${id}_input`}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={
          props.onBlur
            ? props.onBlur
            : e => {
              setStateFocus(false);
              setTotalError(
                emptyErr ||
                  props.error ||
                  (exSpecialChar && specialChar) ||
                  (checkEmail && !isEmail)
              );
              if (exSpecialChar) {
                ifSpecialChar(e.target.value);
              } else if (checkEmail) {
                if (e.target.value.length > 0) {
                  checkIsEmail(e.target.value);
                }
              } else {
                return props.onBlur;
              }
            }
        }
        onMouseEnter={() => {
          setStateHover(true);
        }}
        onMouseLeave={() => {
          setStateHover(false);
        }}
        onFocus={() => {
          setStateFocus(true);
          setTotalError(false);
        }}
        disableClearable={!(value !== '' && (stateFocus || stateHover))}
        onDelete={() => {
          if (setValue) {
            setValue('');
          }

          if (HandleChange) {
            HandleChange('');
          }
        }}
        error={totalError}
      ></InputBox>
    </ItemBlock>
  );
}

export default CreateSIInputBlock;

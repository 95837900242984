import MyTable from './MyTable';
import PageHead from './PageHead';
import DiagramContent from './OAvsTotalDiagrams';
export default function OAvsTotal({ showTable, setShowTable }) {
  return (
    <>
      <PageHead
        data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-PageHead'
        showTable={showTable}
        setShowTable={setShowTable}
      />
      {showTable ? (
        <>
          <MyTable
            data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-MyTableSubjectGroup'
            title='Subject Group'
          ></MyTable>
          <MyTable
            data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-MyTableBusinessDivision'
            title='Business Division'
          ></MyTable>
        </>
      ) : (
        <DiagramContent />
      )}
    </>
  );
}

import ShowAndEdit from './CommonModule/ShowAndEdit';
import { Box, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import MarketChevronDown from '@/assets/MarketChevronDown.svg';
import { useIntl } from 'react-intl';

function ManuallySentEmailsShow(props) {
  const { manuallySentDetail ,userCustomize=false, cusomizeList=[]} = props;

  const intl = useIntl(); // 为国际化提供的API支持
  const [isFold, setIsFold] = useState(true);
  //蓝色标题
  const BlueTitle = props => {
    return (
      <div
        data-selenium-id='EmailTemplates-ManuallySentEmailsShow-BlueTitle'
        style={{
          width: '100%',
          height: '24px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#113D95',
        }}
      >
        {props.children}
      </div>
    );
  };

  const manuallySent = {
    SISetInSubmission: {},
    SITitleAndGERemove: {},
    GEGuidelineShare: {},
    monthlyReportOfSI: {},
    finalTaskForGE: {},
    thankLetter: {},
    JournalLevelSIStatusSharing: {},
    BannerPromotionAdjustmentWhenSubmissionDeadlineIsChanged: {},
  };
  // 根据block判断将要赋值的是哪一个ShowAndEdit

  for (const element of manuallySentDetail) {
    switch (element.block) {
      case 1:
        manuallySent.SISetInSubmission = element;
        break;
      case 2:
        manuallySent.SITitleAndGERemove = element;
        break;
      case 3:
        manuallySent.GEGuidelineShare = element;
        break;
      case 4:
        manuallySent.monthlyReportOfSI = element;
        break;
      case 5:
        manuallySent.finalTaskForGE = element;
        break;
      case 6:
        manuallySent.thankLetter = element;
        break;
      case 12:
        manuallySent.JournalLevelSIStatusSharing = element;
        break;
      case 13:
        manuallySent.BannerPromotionAdjustmentWhenSubmissionDeadlineIsChanged = element;
        break;
      default:
        break;
    }
  }
  if(cusomizeList!==null){
  for (const element of cusomizeList) {
    switch (element.block) {
      case 1:
        manuallySent.SISetInSubmission = {
          customize: manuallySent.SISetInSubmission,
          ...element,
        };
        break;
      case 2:
        manuallySent.SITitleAndGERemove = {
          customize: manuallySent.SITitleAndGERemove,
          ...element,
        };
        break;
      case 3:
        manuallySent.GEGuidelineShare = {
          customize: manuallySent.GEGuidelineShare,
          ...element,
        };
        break;
      case 4:
        manuallySent.monthlyReportOfSI = {
          customize: manuallySent.monthlyReportOfSI,
          ...element,
        };
        break;
      case 5:
        manuallySent.finalTaskForGE = {
          customize: manuallySent.finalTaskForGE,
          ...element,
        };
        break;
      case 6:
        manuallySent.thankLetter = {
          customize: manuallySent.thankLetter,
          ...element,
        };
        break;
      case 12:
        manuallySent.JournalLevelSIStatusSharing = {
          customize: manuallySent.JournalLevelSIStatusSharing,
          ...element,
        };
        break;
      case 13:
        manuallySent.BannerPromotionAdjustmentWhenSubmissionDeadlineIsChanged = {
          customize: manuallySent.BannerPromotionAdjustmentWhenSubmissionDeadlineIsChanged,
          ...element,
        };
        break;
      default:
        break;
    }
  }
}

  return (
    <>
      <Grid
        container
        style={{
          paddingTop: '32px',
          paddingBottom: '14px',
          gridGap: '16px',
        }}
        data-selenium-id='EmailTemplates-ManuallySentEmailsShow-Grid'
      >
        <Grid
          item
          data-selenium-id='EmailTemplates-ManuallySentEmailsShow-Grid-Grid1'
        >
          {isFold ? (
            <Box
              style={{ paddingBottom: '4px' }}
              onClick={() => setIsFold(false)}
              sx={{ cursor: 'pointer' }}
              data-selenium-id='EmailTemplates-ManuallySentEmailsShow-Grid-Grid1-MarketChevronUp'
            >
              <MarketChevronUp />
            </Box>
          ) : (
            <Box
              style={{ marginBottom: '4px' }}
              onClick={() => setIsFold(true)}
              id='MarketFoldDown'
              sx={{ cursor: 'pointer' }}
              data-selenium-id='EmailTemplates-ManuallySentEmailsShow-Grid-Grid1-MarketFoldDown'
            >
              <MarketChevronDown />
            </Box>
          )}
        </Grid>
        <Grid
          item
          md={11}
          sm={11}
          xs={11}
          data-selenium-id='EmailTemplates-ManuallySentEmailsShow-Grid-Grid2'
        >
          <Stack
            direction='column'
            spacing={1}
            data-selenium-id='EmailTemplates-ManuallySentEmailsShow-Grid-Grid2-Stack'
          >
            <Box style={{ paddingBottom: '4px' }}>
              <BlueTitle>{intl.messages['email.manuallySentEmail']}</BlueTitle>
            </Box>
            {isFold ? (
              <>
                <ShowAndEdit
                  userCustomize={userCustomize}
                  partTitle={intl.messages['email.SISetInSubmission']}
                  block={manuallySent.SISetInSubmission.block}
                  contentDetail={manuallySent.SISetInSubmission}
                />
                <ShowAndEdit
                  userCustomize={userCustomize}
                  partTitle={intl.messages['email.SITitleAndGERemove']}
                  block={manuallySent.SITitleAndGERemove.block}
                  contentDetail={manuallySent.SITitleAndGERemove}
                />
                <ShowAndEdit
                  userCustomize={userCustomize}
                  partTitle={intl.messages['email.GEGuidelineShare']}
                  block={manuallySent.GEGuidelineShare.block}
                  contentDetail={manuallySent.GEGuidelineShare}
                />
                <ShowAndEdit
                  userCustomize={userCustomize}
                  partTitle={intl.messages['email.monthlyReportOfSI']}
                  block={manuallySent.monthlyReportOfSI.block}
                  contentDetail={manuallySent.monthlyReportOfSI}
                />
                <ShowAndEdit
                  userCustomize={userCustomize}
                  partTitle={intl.messages['email.finalTaskForGE']}
                  block={manuallySent.finalTaskForGE.block}
                  contentDetail={manuallySent.finalTaskForGE}
                />
                <ShowAndEdit
                  userCustomize={userCustomize}
                  partTitle={intl.messages['email.thankLetter']}
                  block={manuallySent.thankLetter.block}
                  contentDetail={manuallySent.thankLetter}
                />
                <ShowAndEdit
                  userCustomize={userCustomize}
                  partTitle={intl.messages['email.JournalLevelSIStatusSharing']}
                  block={manuallySent.JournalLevelSIStatusSharing.block}
                  contentDetail={manuallySent.JournalLevelSIStatusSharing}
                />
                <ShowAndEdit
                  userCustomize={userCustomize}
                  partTitle={intl.messages['email.BannerPromotionAdjustmentWhenSubmissionDeadlineIsChanged']}
                  block={manuallySent.BannerPromotionAdjustmentWhenSubmissionDeadlineIsChanged.block}
                  contentDetail={manuallySent.BannerPromotionAdjustmentWhenSubmissionDeadlineIsChanged}
                />
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
export default ManuallySentEmailsShow;

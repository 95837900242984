import './richtext.css';
import he from 'he';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useEffect, useState } from 'react';
import { Box, FormControl, TextField } from '@mui/material';
import { useInlineEditor } from './EditorContext';
import {
  CustomFormHelperText,
  CustomInputAreaFooter,
  CustomInputAreaFormHelperText
} from '../../pages/SIPage/SIDetailPage/market-section/components/components';
import { loadSpecialCharPlugin } from './SpecialCharPlugin';
import SimpleIconButton from "../Button/SimpleIconButton";
import DeleteListItemIcon from '../../assets/DeleteListItemIcon.svg';


export function InlineRichText(props) {
  const { disableEnterKey = false, content, setContent, editorConfigGetter= (defaultConfig, EditorModule)=>defaultConfig, onBlur,onFocus, onReady,...others  } = props;
  const EditorModule = useInlineEditor();
  const Editor = EditorModule?.default;

  if (!Editor) {
    return <div>Loading Editor...</div>;
  }

  // const EditorUI = Editor.loadUI();

  return (
    <CKEditor
      id='default-editor'
      editor={Editor}
      config={editorConfigGetter(Editor.defaultConfig, Editor)}
      data={content}
      onReady={editor => {
        // You can store the "editor" and use when it is needed.
        if (disableEnterKey) {
          editor.editing.view.document.on('enter', function (event) {
            event.stop();
          });
        }
        onReady && onReady(editor, Editor);
      }}
      onChange={(_event, editor) => {
        const data = editor.getData();
        setContent(data); // will use later
      }}
      onBlur={(_event, editor) => {
        onBlur && onBlur(_event, editor);
      }}
      onFocus={(_event, editor) => {
        onFocus && onFocus(_event, editor);
      }}
      {...others}
    />
  );
}

const defaultInputClass = {
  '& .InlineRichTextArea-InputRoot': {
    position: 'relative',
    borderRadius: '4px',
    border: '1px solid #98A7B6',
    '&.data-empty':{
      border: '1px solid #DFE4E8',
    },
    '&.data-focused':{
      border: '2px solid #0052CC',
    },
    '&.data-empty.data-focused':{
      border: '2px solid #154AB6',
    },
    '&:hover':{
      border: '1px solid #262E35',
    },
    '&.has-error': {
      border: '2px solid red',
    },
    '& .endAdornment': {
      position: 'absolute',
      top: 'calc(50% - 13.5px)',
      right: '4px',
    },
  },
};

function makeEditorConfig(others){
  return (defaultConfig, EditorModule) => {
    const scPlugin = loadSpecialCharPlugin(EditorModule);
    return ({
      ...defaultConfig,
      plugins: [
        ...EditorModule.builtinPlugins,
        scPlugin,
      ],
      toolbar: {
        ...defaultConfig.toolbar,
        items: [
          'bold',
          'italic',
          'subscript',
          'superscript',
          // '|',
          // 'specialCharacters',
          // '|',
          'customSpecialCharacters'
        ]
      },
      fontSize: '14px',
      ...others
    });
  };
}

export function InlineRichTextInput(props) {
  const {
    id,
    error = false,
    placeholder,
    value,
    setValue,
    sx={},
    needEndAdornment=false,
    handleIconButtonClick = ()=>{},
  } = props;

  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  const [plainText, setPlainText] = useState(richToPlain(value));

  const onValueChange = (newValue) => {
    const plainText = richToPlain(newValue);
    setPlainText(plainText);
    setValue(newValue);
  };

  return (
    <FormControl
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      variant='standard'
      sx={{
        width: '100%',
        paddingBottom: '1px',
        ...defaultInputClass,
        ...sx,
      }}
      data-selenium-id={`${id}-FormControl`}
    >
      <Box className={'InlineRichTextArea-InputRoot'}>
        <InlineRichText
          className={`InlineRichTextArea-RichText ${(error)? 'has-error': ''} ${
            (plainText=='')? 'data-empty': ''} ${(focus)? 'data-focused': ''} ${(hover)? 'data-hover': ''}`}
          disableEnterKey={true}
          content={value}
          setContent={onValueChange}
          editorConfigGetter={makeEditorConfig({placeholder:placeholder})}
          // onReady={initCustomSpecialCharacters}
        />
        {needEndAdornment ? (<SimpleIconButton className='endAdornment' onClick={handleIconButtonClick} DefaultIcon={DeleteListItemIcon}/>):null}
      </Box>
      <CustomFormHelperText
        propsError={error}
        propsHelperText={props.helperText}
        data-selenium-id='SIDetail_MarketSection-CustomInput-HelperText'
      />
    </FormControl>
  );
}

export function richToPlain(richText) {
  let plainText = richText || '';
  plainText = plainText.replace( /<p>&nbsp;<\/p>/g, '\n');
  plainText = plainText.replace( /<\/p>/g, '\n');
  plainText = plainText.replace(/<[^>]*>/g, '');
  // plainText = plainText.replace(/&nbsp;/g, ' ');
  plainText = he.decode(plainText);
  plainText = plainText.substring(0, plainText.length-1);
  return plainText;
}

export function InlineRichTextArea(props) {
  const {
    id,
    placeholder,
    error = false,
    limitLength,
    value,
    setValue,
    exceedError,
    setExceedError,
    minRows,
    maxRows,
    sx={},
  } = props;

  const [focused, setFocused] = useState(null);
  const [plainText, setPlainText] = useState(richToPlain(value));

  const onValueChange = (newValue) => {
    const plainText = richToPlain(newValue);
    setPlainText(plainText);
    if (plainText.length > limitLength){
      setExceedError && setExceedError(true);
    } else {
      setExceedError && setExceedError(false);
    }
    setValue(newValue);
  };

  return (
    <FormControl
      variant='standard'
      sx={{
        width: '100%',
        '& .ck-editor__editable_inline': {
          minHeight: '120px',
        },
        '& .InlineRichTextArea-InputRoot .ck.ck-editor__editable_inline':{
          padding: '8px 0 16px 12px',
          '&>*':{
            marginLeft: 0,
          },
          '&>:first-child': {
            marginTop: 0,
          },
          '&>:last-child': {
            marginBottom: 0,
          },
        },
        ...defaultInputClass,
        ...sx,
      }}
      data-selenium-id={`${id}-FormControl`}
      error={exceedError || error}
    >
      <Box className={'InlineRichTextArea-InputRoot'}>
        <InlineRichText
          className={`InlineRichTextArea-RichText ${(error || exceedError)? 'has-error': ''} ${
            (plainText == '')? 'data-empty': ''} ${(focused)? 'data-focused': ''}`}
          disableEnterKey={false}
          content={value}
          setContent={onValueChange}
          editorConfigGetter={makeEditorConfig({
            placeholder:placeholder,
            minRows,
            maxRows,
          })}
          onFocus={()=>setFocused(true)}
          onBlur={()=>setFocused(false)}
          // onReady={initCustomSpecialCharacters}
        />
      </Box>
      <CustomInputAreaFooter
        currentLength={plainText == '' ? 0 : plainText.length}
        maxLength={limitLength}
        exceedError={exceedError}
        data-selenium-id='SIDetail_MarketSection-CsutomInputAreaFooter'
      />
      <CustomInputAreaFormHelperText
        propsError={error}
        propsHelperText={props.helperText}
        exceedErr={exceedError}
        data-selenium-id='SIDetail_MarketSection-CustomInputAreaFormHelperText'
      />
    </FormControl>
  );
}

import { Box, Stack } from '@mui/material';
import { MiniBoldFont } from '../../../../components/FontStyle';

export function ItemBlock(props) {
  const { title = '', mandatory = true, ...other } = props;
  return (
    <Box
      {...other}
      data-selenium-id='CATJournal_Overview-BatchUpdatePop-ItemBlock-Box'
    >
      <Stack sx={{width: '531px'}} direction='row'>
        <Box
          className='SIP-Detail-ItemBlock-TitleTextBox'
          data-selenium-id='CATJournal_Overview-BatchUpdatePop-ItemBlock-TitleTextBox'
          sx={{
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#596A7C',
            width: '143px',
          }}
        >
          {title}
          {mandatory ? (
            <span
              style={{ marginLeft: '2px', ...MiniBoldFont, color: '#C40000' }}
            >
              *
            </span>
          ) : (
            <></>
          )}
        </Box>
        <Box data-selenium-id='CATJournal_Overview-BatchUpdatePop-ItemBlock-Box-Box'
          sx={{ paddingLeft:'30px'}}>
          {props.children}
        </Box>
      </Stack>
    </Box>
  );
}

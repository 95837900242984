import {
  Box, Button,
  Dialog,
  InputBase,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DropdownValuesStyledSelect } from './DropdownValuesStyledSelect';
import * as React from 'react';
import { useEffect, useState } from 'react';
import DropdownDraggableList from './DropdownDraggableList';
import { FormattedMessage, useIntl } from 'react-intl';
import { GeneralSettingsTypography } from '../GeneralSettings';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadDropdown,
  resetOrder,
  setNewItemInput,
  setSelectDropdownType,
} from '@/actions/SIMT-User/Config/SystemConfigAction';
import { dropdownType } from '@/reducers/SystemConfigReducer';
import { StyledLoadingButton } from '@/components/AsyncButton/SIMTAsyncButton';
import { DropdownSkeleton } from './DropdownSkeleton';
import {trimItemFilds} from '@/utils/commonUtils';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import {loadData} from '@/actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import {StateButton} from '@/componentsphase2/StateButton';
import styleModule from '@/componentsphase2/SIDetail/SaveAndCancelButtonForGeneralInfoSection.module.scss';
import {AlertBox} from '@/components/Alert/AlertBox';
import {addNewItem, saveDropdownOrder} from "@/actions/SIMT-User/Config";
import {getDepartmentList} from "@/actions/SIMT-SI/GuestEditor/GuestEditorCreate";

/**
 * 这个input是为了修改add new item的样式
 */
export const StyledInput = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    height: '40px',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '24px',
  },
}));

export default function DropdownValues() {
  const theme = useTheme();
  const intl = useIntl();
  const dispatch = useDispatch();

  const selector = state => {
    return {
      currSelectType: state.SystemConfig.currSelectType,
      originalData: state.SystemConfig.originalData,
      currDataList: state.SystemConfig.currDataList,
      addNewInput: state.SystemConfig.addNewInput,
    };
  };

  const { currSelectType, originalData, currDataList, addNewInput } =
    useSelector(selector);

  // loading --- adding
  const [adding, setAdding] = useState(false);

  // loading --- change type and load data
  const [loading, setLoading] = useState(false);

  // loading --- save order
  const [saving, setSaving] = useState(false);

  // 对话框开关状态
  const [dialogOpen, setDialogOpen] = useState(false);

  const [infoAlertOpen, setInfoAlertOpen] = useState(false);

  const [newInput, setNewInput] = useState(addNewInput);

  // 屏幕是否小于md
  const underMD = useMediaQuery(theme.breakpoints.down('md'));

  // 列表是否变化了（只指顺序）
  const orderNoChange =
    JSON.stringify(originalData) === JSON.stringify(currDataList);

  const isLegalInput =
    addNewInput.length <= currSelectType.lengthLimit &&
    addNewInput.trim().length !== 0;

  /**
   * 当选择的type变化时加载列表
   */
  useEffect(() => {
    setLoading(true);

    async function load() {
      await dispatch(loadDropdown(currSelectType));
    }

    load().then(_r => {
      setLoading(false);
    });
  }, [dispatch, currSelectType]);

  useEffect(() => {
    setInfoAlertOpen(!orderNoChange);
  }, [orderNoChange]);

  const handleSelectType = event => {
    dispatch(setSelectDropdownType(event.target.value));
  };

  useEffect(() => {
    dispatch(setNewItemInput(newInput));
  },[newInput]);

  /**
   * 新增输入框中的输入内容变化时响应事件
   * @param event onChange的event
   */
  const handleAddNewInputChange = event => {
    dispatch(setNewItemInput(event.target.value));
  };

  /**
   * 取消新增
   */
  const handleCancelAddItem = () => {
    dispatch(setNewItemInput(''));
  };

  /**
   * 对话框打开
   * 同时将adding设置为true
   */
  const handleDialogOpen = () => {
    setAdding(true);
    setDialogOpen(true);
  };

  /**
   * 对话框关闭，但是不需要将adding设置为false，
   * adding设置为false的操作在handleAddNewItem中执行
   */
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  /**
   * 新增一个item的事件
   */
  const handleAddNewItem = () => {
    setAdding(true);
    const item = {
      type: currSelectType.type,
      typeObj: currSelectType,
      value: addNewInput.trim(),
    };

    async function addItem() {
      await dispatch(addNewItem(item));
    }

    addItem().then(_r => {
      setAdding(false);
      dispatch(setNewItemInput(''));
    });
  };

  /**
   * 重置顺序
   */
  const handleResetOrder = () => {
    dispatch(resetOrder());
  };

  /**
   * 保存顺序响应事件
   */
  const handleSaveOrder = () => {
    setSaving(true);

    async function save() {
      dispatch(saveDropdownOrder(currDataList, currSelectType));
    }

    save().then(_r => {
      setSaving(false);
    });
  };

  const handleSaveAll = () => {
    handleDialogOpen();
  };

  const defaultBorder = '1px solid #98A7B6';
  /* Default Without Content Border Style */
  const emptyBorder = '1px solid #DFE4E8';
  /* Hover Border Style */
  const hoverBorder = '1px solid #262E35';
  /* Focus Border Style */
  const focusBorder = '2px solid #154AB6';
  /* Error Border Style */
  const errorBorder = '2px solid #EE5350';
  /* Disabled Border Style */
  const disabledBorder = '1px solid #DFE4E8';


  return (
    <Box data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Box'>
      <Box data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Box-Grid1'>
        {/* Categories Box */}
        <Box data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Box-Grid1-Grid1'>
          <Box data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Box-Grid1-Grid1-Grid1'>
            <GeneralSettingsTypography
              id='systemAdminSystemConfigurationCategoriesLabel'
              data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Box-Grid1-Grid1-Grid1-GeneralSettingsTypography-CategoriesLabel'
              sx={{
                color: '596A7C',
                fontSize: '12px',
                fontWeight: 600,
                fontStyle: 'normal',
                lineHeight: 'normal',
                letterSpacing: '0.12px',
                textTransform: 'capitalize',
              }}
            >
              <FormattedMessage
                id='sysConfig.categoriesLabel'
                data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Box-Grid1-Grid1-Grid1-GeneralSettingsTypography-CategoriesLabel-FormattedMessage'
              />
            </GeneralSettingsTypography>
          </Box>
          {/* Dropdown Select Box */}
          <Box paddingTop='12px' width={'617px'} data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Box-Grid1-Grid1-Grid2'>
            <SinpleAutocomplete
              id='systemAdminSystemConfigurationCategoriesSelect'
              data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Grid-Grid2-DropdownValuesStyledSelect-CategoriesSelect'
              value={currSelectType}
              getOptionLabel={option => option.type}
              options={dropdownType}
              onChange={ (e, value) => {
                dispatch(setSelectDropdownType(value));
              }}
              defaultQuery={true}
              readOnly={true}
            />
          </Box>
          {/* 修改顺序后，右侧的提示语 */}
          <AlertBox
            data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-InfoAlertBox'
            open={infoAlertOpen}
            title={'Save Reminder.'}
            autoHideDuration={4000}
            handleClose={() => {setInfoAlertOpen(false);}}
            message={'Category order has been modified. Please save the change.'}
            severity={'info'}
          />
        </Box>
        {/* 添加、修改顺序的grid */}
        <Box paddingTop='10px' data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Box-Grid1-Grid2'>
          {/* 可拖拽组件左侧的label */}
          <Box data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Box-Grid1-Grid2-Grid'>
            {/* label content */}
            <GeneralSettingsTypography
              id='systemAdminSystemConfigurationDropdownValuesLabel'
              data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Grid2-Grid1-GeneralSettingsTypography-DropdownValuesLabel'
              sx={{
                color: '596A7C',
                fontSize: '12px',
                fontWeight: 600,
                lineHeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: '0.12px',
                textTransform: 'capitalize',
              }}
            >
              <FormattedMessage
                id='sysConfig.dvLabel'
                data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Grid2-Grid1-GeneralSettingsTypography-DropdownValuesLabel-FormattedMessage'
              />
            </GeneralSettingsTypography>
          </Box>
          {/* add new item 的输入框 */}
          <Box paddingTop={'12px'} width={'617px'} data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Grid2-Grid2'>
            <MyOnlyText
              data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-AddValueInputBox'
              value={newInput}
              setValue={setNewInput}
              width={'617px'}
              height={'40px'}
              needEndAdornment
              handleIconButtonClick={()=> {setNewInput('');}}
              minRows={1}
              maxRows={1}
              placeholder={'Add a Value'}
            />
            <Typography
              id='systemAdminSystemConfigurationMaxLengthTip'
              data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Grid2-Grid2-Typography-MaxLengthTip'
              variant='caption'
              color='error'
              hidden={addNewInput.length <= currSelectType.lengthLimit}
            >
              <FormattedMessage
                id='sysConfig.maxLengthTip'
                data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Grid2-Grid2-Typography-MaxLengthTip-FormattedMessag-MaxLengthTipe'
                values={{ value: currSelectType.lengthLimit }}
              />
            </Typography>
          </Box>
          {/* ！！可拖拽组件！！ */}
          <Box data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Grid2-Grid4'>
            <Box paddingTop={'10px'} data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Box-Grid1-Grid2-Grid'>
              {/* label content */}
              <GeneralSettingsTypography
                id='systemAdminSystemConfigurationReorderLabel'
                data-seleunim-id='SystemAdmin_SystemConfiguration-ReorderValues-Grid2-Grid1-GeneralSettingsTypography-Label'
                sx={{
                  color: '596A7C',
                  fontSize: '12px',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  fontStyle: 'normal',
                  letterSpacing: '0.12px',
                  textTransform: 'capitalize',
                }}
              >
                <FormattedMessage
                  id='sysConfig.reorderLabel'
                  data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Grid2-Grid1-GeneralSettingsTypography-DropdownValuesLabel-FormattedMessage'
                />
              </GeneralSettingsTypography>
            </Box>
            {
              // loading 的时候显示骨架
              loading ? (
                <DropdownSkeleton data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Grid2-Grid4-DropdownSkeleton' />
              ) : (
                <DropdownDraggableList
                  data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Grid2-Grid4-DropdownSkeleton-DropdownDraggableList'
                  data={currDataList}
                  currSelectType={currSelectType}
                />
              )
            }
          </Box>
        </Box>
      </Box>
      {/* Buttons */}
      <Box
        data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Box-Grid2'
        justifyContent='flex-end'
        wrap='nowrap'
      >
        <Box data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Box-Grid2-Grid1'>
          {/* Save */}
          <StateButton
            id='systemAdminSystemConfigurationResetOrderButton'
            onClick={handleSaveAll}
            disabled={orderNoChange && addNewInput === ''||addNewInput.length>currSelectType.lengthLimit}
            isPrimary={true}
            titleName={'Save'}
            sx={{
              width: '114px',
              height: '41px',
            }}
          />
          {/* Cancel */}
          <Button
            id='systemAdminSystemConfigurationResetOrderButton'
            data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-Box-Grid2-Grid1-SIMTButton-ResetOrder'
            onClick={handleResetOrder}
            disabled={orderNoChange && addNewInput === ''}
            className={styleModule.CancelButton}
            sx={{
              textTransform: 'none',
              width: '68px',
              height: '32px',
            }}
          >Cancel</Button>
        </Box>
      </Box>
      <Dialog
        data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-SaveDialog'
        open={dialogOpen}
        maxWidth={false}
        onClose={() => {
          handleDialogClose();
          setAdding(false);
        }}
        sx={{
          zIndex: 1402,
          '& .MuiDialog-paper': {
            margin: 0,
            boxSizing: 'border-box',
            boxShadow: 'none',
            borderRadius:'5px',
          },
          '& .MuiBackdrop-root': {
            background: ' rgba(17, 61,149, 0.5)',
            backdropFilter: 'blur(4px)',
          },
        }}
      >
        <Box
          data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-SaveDialog-Box'
          sx={{
            paddingTop: '24px',
            paddingRight: '32px',
            paddingLeft: '32px',
            width: '600px',
          }}
        >
          <Box
            data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-SaveDialog-Box-Title'
            sx={{
              fontSize: '18px',
              lineHeight: '22.5px',
              color: '#262E35',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 600,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: '16px',
            }}
          >
            { orderNoChange? 'Are you sure to add this new value?' : 'Are you sure to change the order?'}
          </Box>
          <Box
            data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-SaveDialog-Box-Buttons'
            sx={{
              display: 'flex',
              width: '536px',
              padding: '12px 0px 20px 0px',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'stretch',
              gap: '10px',
            }}
          >
            <StateButton
              data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-SaveDialog-Box-Buttons-CancelButton'
              isPrimary={false}
              titleName={'Cancel'}
              onClick={() => {
                handleDialogClose();
                setAdding(false);
              }}
              sx={{width: '72px'}}
            />
            <StateButton
              data-seleunim-id='SystemAdmin_SystemConfiguration-DropDownValues-SaveDialog-Box-Buttons-AddButton'
              isPrimary={true}
              titleName={orderNoChange? 'Add' : 'Save'}
              onClick={() => {
                handleDialogClose();
                if(addNewInput.length >80) return;
                if(!orderNoChange) handleSaveOrder();
                if(addNewInput !== '') {
                  handleAddNewItem();
                  setNewInput('');
                }
              }}
              sx={{width: '70px'}}
            />
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}

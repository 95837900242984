import styles from '../../index.module.scss';
import TitleLine from '../../components/TitleLine';
import {useDispatch, useSelector} from 'react-redux';
import {SIPFormState} from '@/reducers/SIPFormReducer';
import ProcessIndicatorPanel from '../../components/ProcessIndicatorPanel';
import React, {useEffect} from 'react';
import {Box, Button} from '@mui/material';
import {SpecialIssueInfoSection} from "@/pages/SIPForm/subpages/Proposal/SpecialIssueInfoSection";
import {ProposedDatesSection} from "@/pages/SIPForm/subpages/Proposal/ProposedDatesSection";
import {GuestEditorInfoSection} from "@/pages/SIPForm/subpages/Proposal/GuestEditorInfoSection";
import {PotentialAuthorsSection} from "@/pages/SIPForm/subpages/Proposal/PotentialAuthorsSection";
import {ConflictOfIntersetSection} from "@/pages/SIPForm/subpages/Proposal/ConflictOfIntersetSection";
import {StatementsSection} from "@/pages/SIPForm/subpages/Proposal/StatementsSection";
import {
    clearSIPFormError,
    loadCountryByName,
    loadJobTitle,
    loadJournalByName,
    loadSubjectByName,
    setSIPFormData,
    setSIPFormProceedState,
    submitSIPForm
} from "@/actions/SIMT-SIP/SIPForm";
import {Country, Journal, SectionItem, SectionState, FormData} from "@/pages/SIPForm/types.d";
import {jumpToSection} from "@/pages/SIPForm/components/Card";
import MyLocalStorage from "@/utils/MyLocalStorage";
import {ErrorAlert} from "@/pages/SIPForm/components/ErrorAlert";
import {AppDispatch} from "@/store";

function Proposal() {
    const params = new URLSearchParams(location.search);
    const id_token = params.get('id_token');
    const state = params.get('state');

    const selector = (state: {
        SIPForm: SIPFormState;
        [key: string]: any;
    }) => state.SIPForm;

    const dispatch = useDispatch<AppDispatch>();

    const {
        formData,
        currentUser,
        hasError: formHasError,
    } = useSelector(selector);

    useEffect(() => {
        if (currentUser){
            const email = currentUser.email;
            const formDataLocal: FormData | undefined = MyLocalStorage.getGroupItem("SIP-Form", email);
            if (formDataLocal){
                dispatch(setSIPFormData(formDataLocal));
            }
        }
    }, [currentUser]);

    const {
        specialIssueInformation,
        proposedDates,
        guestEditorInformation,
        potentialAuthors,
        conflictOfInterest,
        statements,
    } = formData;

    const sections = [
        specialIssueInformation,
        proposedDates,
        guestEditorInformation,
        potentialAuthors,
        conflictOfInterest,
        statements,
    ];

    const submit = () => {
        dispatch(setSIPFormProceedState('proceeding'));

        // check form if has error
        const allCompleted = specialIssueInformation.state === SectionState.completed &&
            proposedDates.state === SectionState.completed &&
            guestEditorInformation.state === SectionState.completed &&
            potentialAuthors.state !== SectionState.error &&
            conflictOfInterest.state === SectionState.completed &&
            statements.state === SectionState.completed;

        if (allCompleted){
            submitSIPForm(id_token ?? '', formData)(dispatch);
        } else {
            if (specialIssueInformation.state === SectionState.error){
                jumpToSection(specialIssueInformation);
            } else if (proposedDates.state === SectionState.error){
                jumpToSection(proposedDates);
            } else if (guestEditorInformation.state === SectionState.error){
                jumpToSection(guestEditorInformation);
            } else if (potentialAuthors.state === SectionState.error){
                jumpToSection(potentialAuthors);
            } else if (conflictOfInterest.state === SectionState.error){
                jumpToSection(conflictOfInterest);
            } else if (statements.state === SectionState.error){
                jumpToSection(statements);
            }
        }
    };

    const hasError = specialIssueInformation.state === SectionState.error ||
        proposedDates.state === SectionState.error ||
        guestEditorInformation.state === SectionState.error ||
        potentialAuthors.state === SectionState.error ||
        conflictOfInterest.state === SectionState.error ||
        statements.state === SectionState.error;

    useEffect(() => {
        if (specialIssueInformation.state === SectionState.error){
            jumpToSection(specialIssueInformation);
        } else if (proposedDates.state === SectionState.error){
            jumpToSection(proposedDates);
        } else if (guestEditorInformation.state === SectionState.error){
            jumpToSection(guestEditorInformation);
        } else if (potentialAuthors.state === SectionState.error){
            jumpToSection(potentialAuthors);
        } else if (conflictOfInterest.state === SectionState.error){
            jumpToSection(conflictOfInterest);
        } else if (statements.state === SectionState.error){
            jumpToSection(statements);
        }
    }, [specialIssueInformation.state, proposedDates.state,
        guestEditorInformation.state, potentialAuthors.state,
        conflictOfInterest.state, statements.state]);

    useEffect(() => {
        dispatch(loadSubjectByName(id_token ?? '', ''));
        dispatch(loadJournalByName(id_token ?? '', ''));
        dispatch(loadCountryByName(id_token ?? '', ''));
        dispatch(loadJobTitle(id_token ?? ''));
    }, []);

    return (
        <Box className={styles['body']}>
            <TitleLine />
            <Box className={styles['form-context']}>
                <ProcessIndicatorPanel sections={sections} />
                <Box className={styles['section-container']}>
                    <SpecialIssueInfoSection/>
                    <ProposedDatesSection/>
                    <GuestEditorInfoSection />
                    <PotentialAuthorsSection />
                    <ConflictOfIntersetSection />
                    <StatementsSection />
                    <Button
                        className={styles['submit-button'] + ' ' + (hasError ? styles['hasError'] :'') }
                        onClick={submit}
                        disabled={hasError}
                    >Submit my Proposal</Button>
                </Box>
            </Box>
            <ErrorAlert
                open={formHasError}
                onClose={() => { dispatch(clearSIPFormError()); }}
                title={"Submission Failed"}
                message={"There may be a network issue. Please refresh the page and try submitting again."}
                buttonGroups={[
                    <Button color="secondary" size="small" onClick={() => window.location.reload()}>
                        Refresh
                    </Button>
                ]}
                // autoHideDuration={buttonAlertAutoHideDuration}
            />
        </Box>
    );
}

export default Proposal;
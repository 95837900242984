import React from 'react';
import {
  Backdrop,
  backdropClasses,
  Box,
  Fade,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExportAllCloseIcon from '@/assets/ExportAllCloseIcon.svg';
import { styled } from '@mui/material/styles';
import CloseIconButton from '@/componentsphase2/Button/CloseIconButton';
import { StateButton } from '@/componentsphase2/StateButton';

export const BlueBackdrop = styled(Backdrop)(() => ({
  [`&.${backdropClasses.root}`]: {
    background: 'rgba(17, 61,149, 0.5)',
    backdropFilter: 'blur(4px)',
  },
}));

const SmallRightModal = props => {
  const {
    open = false, // 是否打开模态框
    handleClose = () => { }, // 关闭模态框的回调函数

    title = '', // 模态框标题

    haveCloseButton = true,
    isRightModel = true,//是否是靠右、与屏幕等高的模态框
    width = isRightModel ? '924px' : '600px', // 模态框宽度
    height = isRightModel ? '100vh' : '600px', // 模态框高度
    backgroundColor = '#fff', // 背景颜色

    children, // 模态框的主体内容
    titleElseElement,

    sx = {}, // 这个 sx 将被应用到 MUI 提供的 Modal 的 sx 中
    boxSx = {}, // 模态框最外层是一个 Box，boxSx 和其他非 sx 的 props 将被添加到这个 Box 中

    ...others
  } = props;

  const boxStyle = {
    margin: 0,
    // padding: '24px 36px 24px 36px',

    position: 'absolute',
    top: '50%',
    left: isRightModel ? '' : '50%',
    right: isRightModel ? '0' : '',
    transform: isRightModel ? 'translate(0, -50%)' : 'translate(-50%, -50%)',

    width: width,
    height: height,
    maxHeight: '100%',
    backgroundColor: backgroundColor,
    boxSizing: 'border-box',
    ...boxSx,
  };

  const titleStyle = {

  };

  const closeButton = (
    <CloseIconButton
      sx={{
        height: '24px',
        width: '24px',
        padding: '0px',
      }}
      onClick={handleClose}
    ></CloseIconButton>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      components={{
        Backdrop: BlueBackdrop,
      }}
      sx={{
        zIndex: '1300',
        ...sx,
      }}
    >
      <Fade in={open}>
        <Box
          className='SmallRightModal-container'
          sx={{ width: '100%', ...boxStyle }}
          {...others}
        >
          <Stack sx={{ position: 'relative', height: '100%' }}>
            <Box sx={{
              zIndex: '1300',

              minHeight: '70px',
              // borderBottom: '1px solid #DFE4E8'
            }}>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} minHeight='74px'
                sx={{
                  padding: '0px 16px 0px 16px',
                }}>
                {haveCloseButton ? (closeButton) : (<></>)}
                <Typography variant='h4' component='h2' sx={{
                  fontSize: '18px',
                  fontWeight: '600',
                  lineHeight: '22.5px',
                  color: '#262E35',
                  paddingLeft: '10px',
                  flex: '1'
                }}>
                  {title}
                </Typography>
                <Box>{titleElseElement}</Box>

              </Stack>
            </Box>
            <Box
              sx={{
                padding: '0px 16px 16px 16px',
                overflow: 'scroll',
                flex: '1',
              }}>
              {children}
            </Box>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SmallRightModal;

import { useIntl } from 'react-intl';
import { Box, Stack } from '@mui/material';

export function DecisionTitle(props) {

  const { id, title, width, paddingRight, isOthersSelected, decisionType } = props;
  const intl = useIntl();

  let shouldDisplayBox = true;
  if (!isOthersSelected && (
    (decisionType === 'Reject')|| 
    decisionType === 'Direct Acquire' ||
      decisionType === intl.messages['sipDetail.decisionPanelElements.moveToSubmitted'] ||  // CT-7201
      decisionType === intl.messages['sipDetail.decisionPanelElements.moveToInitialReview'] || // CT-7204
      decisionType === intl.messages['sipDetail.decisionPanelElements.moveToEBMReview']  // CT-7989
  )) {
    shouldDisplayBox = false;
  }
  if(decisionType==='Agree'||decisionType==='Decline'&&!isOthersSelected){
    shouldDisplayBox = false;
  }
  if (decisionType === intl.messages['sipDetail.decisionPanelElements.moveToInitialReview']) {
    shouldDisplayBox = false;
  }
  if(decisionType === intl.messages['sipDetail.decisionPanelElements.assignToScreener']){
    shouldDisplayBox = false;
  }
  const {pathname}=location;
  const pathnameSplit=pathname.split('/');
  if(pathnameSplit[2]==='reviseJE'&&pathnameSplit[3]?.startsWith('eyJhbGciOiJIUzI1NiJ9.')&&decisionType === 'Accept'){
    shouldDisplayBox = false; //CT-6748
  }
  return (
    <Stack
      id={`${id}InputLable`}
      data-selenium-id={`SIP_DetailPage_DecisionSection-Title-Stack-${id}InputLable`}
      direction='row'
      spacing={0.25}
      width={width}
      sx={{ pl: '0px', pt: '10px', pb: '10px', height: '40px', paddingRight: { paddingRight } }}
    >
      
      <Box
        data-selenium-id='SIP_DetailPage_DecisionSection-Title-Box1'
        style={{
          width:'auto',
          height: '20px',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          color: '#596A7C',
          textTransform: 'none',
          fontFamily: 'Inter', 
          ...(title?.length>=17&&{transform: 'translateY(-8px)',/* width:'48%'*/}),
        }}
      >
        {title}
        { shouldDisplayBox  &&(<Box
        data-selenium-id='SIP_DetailPage_DecisionSection-Title-Box2'
        style={{
          width: '8px',
          height: '18px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '18px',
          letterSpacing: '0.96px',
          color: '#C40000',
          marginTop:title==='Revision Deadline'?'-10px':'0px',
          marginLeft:'2px',
          display:'inline-block',
        }}
      >
        *
      </Box>)}
      </Box>

      
    </Stack>
  );
}

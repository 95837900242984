import { Button, Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { InviteEBMModal } from './InviteEBMModal';
import { CancelEbmModal } from './CancelEbmModal';
const MAX_EBM_INVITATION = 20; //TODO , this will be retrieved from the backend
export default function EBMReviewButtonGroup(props) {
  const { ebmList, isCanceled } = props;

  const canBeCanceled = useMemo(() => {
    return ebmList?.length > 0 && !isCanceled;
  }, [ebmList]);

  const isOverTheMaxInvitation = useMemo(() => {
    return ebmList?.length >= MAX_EBM_INVITATION;
  }, [ebmList]);

  const [isEbmInviteOpen, setIsEbmInviteOpen] = useState(false);
  const [isEbmCancelOpen, setIsEbmCancelOpen] = useState(false);

  return (
    <div
      data-selenium-id='SIP_DetailPage_EBMReviewPanel-EBMReviewButtonGroup'
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
        marginBottom: '20px',
        gap: '4px',
      }}
    >
      <Box>
        {isOverTheMaxInvitation
          ? `You has invited ${MAX_EBM_INVITATION} EBMs`
          : `Inivite a maximum of ${MAX_EBM_INVITATION} EBMs`}
      </Box>
      <Button
        data-selenium-id='SIP_DetailPage_EBMReviewPanel-EBMReviewButtonGroup-ReviewButton'
        sx= {{textTransform:'none'}}
        style={
          isOverTheMaxInvitation
            ? {
                backgroundColor: '#DFE4E8',
                color: '#98A7B6',
                borderRadius: '4px',
                padding: '8px 16px',
                marginRight: '10px',
              }
            : {
                backgroundColor: '#154AB6',
                color: '#FFFFFF',
                borderRadius: '4px',
                padding: '8px 16px',
                marginRight: '10px',
              }
        }
        disabled={isOverTheMaxInvitation}
        onClick={() => {
          setIsEbmInviteOpen(true);
        }}
      >
        {`Invite EBM(s) +`}
      </Button>
      {canBeCanceled && (
        <Button
          data-selenium-id='SIP_DetailPage_EBMReviewPanel-EBMReviewButtonGroup-ReviewHistoryButton'
          style={{
            backgroundColor: '#FFFFFF',
            color: '#154AB6',
            borderRadius: '4px',
            padding: '4px 16px',
            fontWeight: 400,
            lineHeight: '18px',
            textTransform: 'none',
            hover: {
              backgroundColor: '#154AB6',
              color: '#FFFFFF',
              cursor: 'pointer',
            },
          }}
          onClick={() => setIsEbmCancelOpen(true)}
        >
          Cancel EBM Invitation(s)
        </Button>
      )}
      <InviteEBMModal
        title='Invite EBM(s)'
        invitedEbm={ebmList||[]}
        open={isEbmInviteOpen}
        handleClose={() => {
          setIsEbmInviteOpen(false);
        }}
      />
      <CancelEbmModal
        title='Cancel EBM Invitations'
        open={isEbmCancelOpen}
        handleClose={() => {
          setIsEbmCancelOpen(false);
        }}
      />
    </div>
  );
}

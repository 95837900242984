import {
  TextField,
  ThemeProvider,
  Box,
  FormControl,
  IconButton,
} from '@mui/material';
import {
  defaultVars,
  ErrVars,
} from '../../../../components/CreateAndUpdate/commonStyle';
import { BootstrapAutoComplete } from '../../../../components/CreateAndUpdate/MyOperationModule';
import { Bootstraptheme } from '../../../../components/CreateAndUpdate/MyTheme';
import { BootstrapTitle } from '../../../../components/CreateAndUpdate/MyTitle';
import { MyFormHelperText } from '../../../../components/CreateAndUpdate/MyFormHelperText';
import { useEffect, useState } from 'react';
import CreateAndUpdateSIChevronDown from '../../../../assets/CreateAndUpdateSIChevronDown.svg';
import ItemBlock from '../CGTComponents/ItemBlock';
import MultipleAutocomplete from '../CGTComponents/MultipleAutocomplete';
import ArrowTag from '../CGTComponents/SVGComponents/ArrowTag.svg';
import { calcHeight, calcMarginBottom } from '../CGTComponents/Utils';
function CreateAndUpdateAutoComplete(props) {
  const {
    title,
    required,
    isErr,
    errorText,
    value,
    placeholder,
    empty,
    ...other
  } = props;

  const [autocompleteError,setAutocompleteError] = useState(false);

  useEffect(()=>{
    setAutocompleteError(isErr);
  },[isErr]);


  
  return (
    <>
      <ItemBlock
        id='SI_CreateAndUpdateSI-Autocomplete'
        isError={autocompleteError}
        required={required}
        title={title}
        errorText={errorText}
      >
        <MultipleAutocomplete
          value={value}
          renderInput={params => (
            <TextField
              id = 'SIPage_CreateAndUpdateSI-AutoComplete-TextField'
              data-selenium-id='SIPage_CreateAndUpdateSI-AutoComplete-TextField'
              placeholder={placeholder}
              error={autocompleteError}
              {...params}
            />
          )}
          multiple={false}
          // popupIcon={<ArrowTag></ArrowTag>}
          endAttchmentProps={{
            endAttchmentSX: {
              top: '12px',
              right: '12px !important',
            },
            popupIconSX: {
              backgroundColor: '#FFFFFF',
              padding: '0px',
              borderRadius: '2px',
            },
            deleteIconHoverSX :{
              backgroundColor:'#FFFFFF'
            },
            deleteIconFocusSX:  {
              backgroundColor:'#FFFFFF'
            }
          }}
          // disableClearable={true}
          outlinedInputProps={{
            outlinedInputSX: {
              padding: '11px 12px',
            },
            inputSX: {
              root: {
                height: '18px',
              },
            },
            notchedOutlineSX: {
              border:
                empty
                  ? '1px solid #DFE4E8'
                  : '1px solid #98A7B6',
            },
          }}
          dropListEndAttachment={<></>}
          dropListNoOptions={<Box>No Options</Box>}
          dropListProps={{
            listBoxSX: {
              paddingBottom: '12px',
            },
            listBoxUlSX: {
              root:{
                padding: '12px 0px 0px 0px',
              }
            },
          }}
          scrollProps={{
            scrollOverviwSX:{
              height:`${calcHeight(props.options,6,6,20,12)}px`
            }
          }}
          onFocus ={(e)=>{
            setAutocompleteError(false);
            props.onInputChange({target:{value:''}});
          }}
          onBlur = {(e)=>{
            setAutocompleteError(isErr);
          }}
          {...other}
        />
      </ItemBlock>
    </>
  );
}
export default CreateAndUpdateAutoComplete;

import { useState, useEffect, useMemo, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Box } from '@mui/material';
import styles from './index.module.scss';
import CloseIcon from '@/assets/CloseIcon.svg';
import { MultipleSelect } from '@/components/Chart/ChartSelectors';
import ReportSaveMenuButton from '@/components//Chart/chartButton';
import { ReportDownloadMenuButton } from '@/components/Chart/chartButton';
import ArticlesChart from './Chart';
import NoDataPage from '@/components/Chart/NoDataPage';
import LoadingPage from '@/components/Chart/LoadingPage';
import ErrorPage from '@/components/Chart/ErrorPage';
import useGetChartsDataHook from './hooks';
import useGetDiagramStatusHook from './hooks/useGetDiagramStatusHook';
import {GetArticleOAvsTotalExcel, getArticleOaVsTotalExcelByPost} from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  saveImageAsPng,
  saveImageAsJpg,
  checkEmptyArrayData,
} from '@/pages/Report/chartUtils';
import {
  LOADING,
  ERROR,
  VALID,
  NO_DATA,
  NO_SELECTION_FROM_DROPDOWN_MENU,
  NO_DISPLAYED_DATA,
} from '@/pages/Report/chartUtils/constants.js';
import { getFormattedMonthYear } from '@/utils/commonUtils';
import { getDownloadFileName } from '@/pages/Report/utils/getDownloadFileName';
import {GET, SUBJECT_GROUP} from "@/pages/Report/Articles/OAvsTotal/OAvsTotalDiagrams/Constants";

const ChartContainer = ({
  header,
  subHeader,
  selectedDate,
  formatdate,
  cancelSelectedChart,
  hasAdditionalFilter,
  hasSelectAllOption,
  catagoryAttributeName,
  barSeriesValue,
  barSeriesColor,
  url,
  downloadUrl,
  downloadMethod = GET,
  tableDownloadTitle
}) => {
  const [selectedCatagories, setSelectedCatagories] = useState([]);
  const reduxTitle = useMemo(() => {
    return header + '_' + subHeader + '_' + selectedDate;
  }, [header, subHeader, selectedDate]);

  const [SGsFullList, barchartData, unfilteredDataForBar, loading, error] =
    useGetChartsDataHook(
      selectedDate,
      selectedCatagories,
      catagoryAttributeName,
      url,
      reduxTitle
    );

  useEffect(() => {
    setSelectedCatagories(SGsFullList);
  }, [SGsFullList]);

  const alertLabel = subHeader;

  const { valid: digramStatus, info } = useGetDiagramStatusHook(
    unfilteredDataForBar,
    barchartData,
    selectedCatagories,
    hasAdditionalFilter,
    alertLabel,
    loading,
    error
  );

  const [chartWidth, setChartWidth] = useState('100%');
  // for chart download
  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);
  const downloadlink = useRef(); // ADDED

  const dispatch = useDispatch();

  // dependencies: Tab1, Tab2, Level, selected time,  
  const fileName = useMemo(() => {
    const rmSpaceSubHeader = subHeader.replaceAll(' ', '');
    const tab1Name = tableDownloadTitle;
    const tab2Name = 'OAvsTotal';
    const level = rmSpaceSubHeader;
    return getDownloadFileName(tab1Name, tab2Name, level, selectedDate);
  }, [tableDownloadTitle, header, subHeader, selectedDate]);
  
  const downloadChart = () => {
    if(selectedDate !== null) {
      if (downloadMethod === GET) {
        dispatch(
            GetArticleOAvsTotalExcel(downloadUrl, subHeader, selectedDate, fileName)
        );
      } else {
        const requestBody = {
          selectedDate: selectedDate,
          [subHeader === SUBJECT_GROUP ? 'subjectGroup' : 'businessDivision']: selectedCatagories
        };
        dispatch(
            getArticleOaVsTotalExcelByPost(downloadUrl, requestBody, fileName)
        );
      }
    }
  };

  const savePng = () => {
    saveImageAsPng(chartRef.current, fileName);
  };
  const saveJpg = () => {
    saveImageAsJpg(chartRef.current, fileName);
  };

  // listen to chart-container size change
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      resizeHandler(entries);
    });
    resizeObserver.observe(chartContainerRef.current);
    return () => {
      if (chartContainerRef.current) {
        resizeObserver.unobserve(chartContainerRef.current);
      }
    };
  }, []);

  function resizeHandler(entries) {
    const chartContainer = entries.find(
      entry => entry.target == chartContainerRef.current
    );
    const width = chartContainer?.target.clientWidth;
    setChartWidth(width);
  }

  const downloadDisabled =
    [
      NO_DATA,
      LOADING,
      ERROR,
      NO_DISPLAYED_DATA,
      NO_SELECTION_FROM_DROPDOWN_MENU,
    ].indexOf(digramStatus) >= 0;

  return (
    <div className={styles['chart-container']} ref={chartContainerRef}>
      <Box className={styles['chart-header']}>
        <div className={styles['selectors']}>
          {hasAdditionalFilter && (
            <span id='SG-select'>
              <MultipleSelect
                label={alertLabel}
                alertLabel={alertLabel}
                options={SGsFullList}
                selectedItems={selectedCatagories ?? []}
                setSelectedItems={setSelectedCatagories}
                hasSelectAllOption={hasSelectAllOption}
                disabled={[NO_DATA, LOADING, ERROR].indexOf(digramStatus) >= 0}
              />
            </span>
          )}
        </div>
        <div className={styles['chart-titles']}>
          <h1 className={styles['chart-title']}>{header + ' ' + formatdate}</h1>
          <h2 className={styles['chart-subtitle']}>{subHeader + ' Level'}</h2>
        </div>
        <div className={styles['buttons']}>
          <ReportDownloadMenuButton
            func={downloadChart}
            disabled={downloadDisabled}
          />
          <ReportSaveMenuButton
            items={[
              {
                label: 'Save as PNG',
                operation: savePng,
              },
              {
                label: 'Save as JPG',
                operation: saveJpg,
              },
            ]}
            disabled={downloadDisabled}
          />
          <button
            onClick={cancelSelectedChart}
            className={styles['button-close']}
          >
            <CloseIcon />
          </button>
        </div>
      </Box>

      {digramStatus === LOADING && <LoadingPage />}

      {digramStatus === ERROR && <ErrorPage />}

      {digramStatus === NO_DATA && (
        <NoDataPage
          title={`There is no data for any ${info}s on the selected date.`}
          subTitle={'Please choose a different date to view diagram.'}
        />
      )}

      {digramStatus === NO_SELECTION_FROM_DROPDOWN_MENU && (
        <NoDataPage title={`Please select at least one ${info}.`} />
      )}

      {digramStatus === NO_DISPLAYED_DATA && (
        <NoDataPage
          title={`There is no data available for the selected ${info}.`}
          subTitle={`Please choose a different ${info} to view diagram.`}
        />
      )}

      {digramStatus === VALID && (
        <>
          <Box className={styles['chart']} ref={chartRef}>
            <ArticlesChart
              barchartData={barchartData}
              width={chartWidth}
              hasZoom={true}
              hasTitle={false}
              catagoryAttributeName={catagoryAttributeName}
              barSeriesValue={barSeriesValue}
              barSeriesColor={barSeriesColor}
            />
          </Box>
          <Box className={styles['chart-download']} ref={chartRef}>
            <ArticlesChart
              barchartData={barchartData}
              width={chartWidth}
              hasZoom={false}
              hasTitle={true}
              title={header + ' ' + formatdate}
              subTitle={subHeader + ' Level'}
              catagoryAttributeName={catagoryAttributeName}
              barSeriesValue={barSeriesValue}
              barSeriesColor={barSeriesColor}
            />
          </Box>
          <a
            href='javascript:void(0)'
            download
            ref={downloadlink}
            className={styles['download-link']}
          >
            hidden download link
          </a>
        </>
      )}
    </div>
  );
};

ChartContainer.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  selectedDate: PropTypes.string.isRequired,
  cancelSelectedChart: PropTypes.func.isRequired,
  hasAdditionalFilter: PropTypes.bool.isRequired,
  hasSelectAllOption: PropTypes.bool.isRequired,
  showSubjectGroupsSelector: PropTypes.bool,
};

export default ChartContainer;

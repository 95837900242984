import { Box, styled, Tab, tabClasses, Tabs } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { NewDesignedMain } from '../../components/Main';
import ManuallySentEmailsShow from './ManuallySentEmailsShow';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import {
  switchEmailTemplateTab
} from "../../actions/SIMT-Email/EmailAction";
import ManuallySendingEmails from './SIPEmailTemplate/ManuallySendingEmails';
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import {
    getCustomizeEmailTemplates,
    getEmailRedDotList,
    getEmailTemplates, getSIPCustomizeEmailTemplates, getSIPEmailTemplates,
    getSolicitedSIPCustomizeEmailTemplates, getSolicitedSIPEmailTemplates
} from "@/actions/SIMT-Email/Template";
import { useSearchParams } from 'react-router-dom';
import {PERMISSIONS} from "@/constant/permission";

function CustomEmailTemplatesMain() {
  const intl = useIntl(); // 为国际化提供的API支持

  const userPermissionArray = localStorage
    .getItem('userPermissions')
    .split(',');
  const emailSIPermisssion = userPermissionArray?.includes(PERMISSIONS.EMAIL_SI);
  const emailSIPPermisssion = userPermissionArray?.includes(PERMISSIONS.EMAIL_SIP);
  const SIPJPAPermisssion = userPermissionArray?.includes(PERMISSIONS.SIP_WRITE_JPA);

  const dispatch = useDispatch();

  const [search] = useSearchParams();
  const tabForInit = search.get('tabForInit');

  useEffect(() => {
    if (emailSIPermisssion) {
      dispatch(getEmailTemplates());
      dispatch(getCustomizeEmailTemplates());
    }

    if (emailSIPPermisssion) {
      dispatch(getSIPEmailTemplates());
      dispatch(getSIPCustomizeEmailTemplates());
      dispatch(getSolicitedSIPEmailTemplates());
      dispatch(getSolicitedSIPCustomizeEmailTemplates());
      // if (SIPJPAPermisssion){
      //   dispatch(getSolicitedSIPEmailTemplates());
      //   dispatch(getSolicitedSIPCustomizeEmailTemplates());
      // }
    }
    dispatch(getEmailRedDotList());
    dispatch(switchEmailTemplateTab(+tabForInit));
  }, []);

  const selector = state => {
    return {
      sidebarOpen: state.UserManage.siderOpen,
      emailDetail: state.Email.emailTemplatesList,
      emailCusomizeDetail: state.Email.emailCusomizeTemplatesList,
      emailSIPTemplatesList: state.Email.emailSIPTemplatesList,
      emailCustomizeSIPTemplatesList:
        state.Email.emailCustomizeSIPTemplatesList,
      emailSolicitedSIPTemplatesList:
        state.Email.emailSolicitedSIPTemplatesList,
      emailCustomizeSolicitedSIPTemplatesList:
        state.Email.emailCustomizeSolicitedSIPTemplatesList,
      currentEmailTemplateTab: state.Email.currentEmailTemplateTab,
    };
  };
  const {
    sidebarOpen,
    emailDetail,
    emailCusomizeDetail,
    emailSIPTemplatesList,
    emailCustomizeSIPTemplatesList,
    emailSolicitedSIPTemplatesList,
    emailCustomizeSolicitedSIPTemplatesList,
    currentEmailTemplateTab,
  } = useSelector(selector);

  const EmailTemplateTab = styled(Tab)(({ _theme }) => ({
    [`&.${tabClasses.root}`]: {
      padding: '16px 16px 14px 16px',
      fontStyle: 'normal',
      fontSize: '14px',
      fontWeight: 600,
      color: '#596A7C',
      textAlign: 'center',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      lineHeight: '20px',
      fontFamily: 'Open Sans',
    },
    [`&.${tabClasses.selected}`]: {
      color: '#113D95 !important',
    },
  }));

  const handleChange = (_event, newValue) => {
    dispatch(switchEmailTemplateTab(newValue));
  };

  function a11yProps(index) {
    return {
      id: `systemAdminEmailTemplateTab${index}`,
      'aria-controls': `emailTemplateTabPanel${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`emailTemplateTabPanel${index}`}
        data-selenium-id={`EmailTemplate-TabPanel${index}`}
        aria-labelledby={`emailTemplateTab${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  return (
    <NewDesignedMain open={sidebarOpen} sx={{ minWidth: '550px' }}>
      <Box
        p={'21px 26px 0px 20px'}
        data-selenium-id='EmailTemplates-EmailTemplatesMain-Box'
      >
        <Box
          id='detailTitleBox'
          data-selenium-id='EmailTemplates-EmailTemplatesMain-DetailTitleBox'
          p={'0px 0px 16px 0px'}
        >
          <div
            style={{
              width: '100%',
              height: '28px',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '22px',
              lineHeight: '125%',
            }}
          >
            {intl.messages['email.emailCustomizeTemplates']}
          </div>
          <Box
            data-selenium-id='EmailTemplates-EmailTemplatesMain-Box2'
            sx={{
              borderBottom: '2px solid #DFE4E8',
              width: 'fit-content',
              // marginLeft: '8px',
              marginBottom: '15px',
              marginTop: '12px',
            }}
          >
            <Tabs
              value={currentEmailTemplateTab}
              onChange={handleChange}
              aria-label='basic tabs example'
              sx={{
                marginBottom: '-2px', // 这个属性是为了让底部的蓝色线条跟外部的灰白色线条平齐
              }}
            >
              {emailSIPermisssion ? (
                <EmailTemplateTab
                  id='emailTemplateFirst'
                  label='SI Email Templates'
                  {...a11yProps(0)}
                />
              ) : null}
              {/* {emailSIPPermisssion && SIPJPAPermisssion? ( */}
              {emailSIPPermisssion ? (
                <EmailTemplateTab
                  id='emailTemplateSecond'
                  label='Solicited SIP Email Templates'
                  {...a11yProps(emailSIPermisssion ? 1 : 0)}
                />
              ) : null}
              {emailSIPPermisssion ? (
                <EmailTemplateTab
                  id='emailTemplateThird'
                  label='Unsolicited SIP Email Templates'
                  {...a11yProps(emailSIPermisssion ? 2 : 1)}
                />
              ) : null}
            </Tabs>
          </Box>
        </Box>
        <Box p={'0px 46px 0px 32px'} style={{ backgroundColor: '#ffffff' }}>
          <TabPanel
            value={currentEmailTemplateTab}
            index={0}
            data-selenium-id='EmailTemplates-EmailTemplatesMain-TabPanel1'
          >
            {emailDetail &&
            emailDetail.manuallySentEmails !== undefined &&
            emailDetail.manuallySentEmails !== null &&
            emailSIPermisssion ? (
              <>
                <ManuallySentEmailsShow
                  manuallySentDetail={emailDetail.manuallySentEmails}
                  userCustomize={true}
                  cusomizeList={emailCusomizeDetail}
                />
              </>
            ) : (
              <></>
            )}
          </TabPanel>
          <TabPanel
            value={currentEmailTemplateTab}
            index={emailSIPermisssion ? 1 : 0}
            // index={1}
            data-selenium-id='EmailTemplates-EmailTemplatesMain-TabPanel2'
          >
            {emailSolicitedSIPTemplatesList &&
            emailSolicitedSIPTemplatesList !== [] &&
            emailSolicitedSIPTemplatesList.manuallySendingEmails !==
              undefined &&
            emailSolicitedSIPTemplatesList.automaticallySendingEmails !==
              undefined &&
            emailSIPPermisssion ? (
              <>
                <ManuallySendingEmails
                  manuallySentDetail={
                    emailSolicitedSIPTemplatesList.manuallySendingEmails
                  }
                  userCustomize={true}
                  cusomizeList={emailCustomizeSolicitedSIPTemplatesList}
                  isSolicited={true}
                ></ManuallySendingEmails>
              </>
            ) : (
              <></>
            )}
          </TabPanel>
          <TabPanel
            value={currentEmailTemplateTab}
            index={emailSIPermisssion ? 2 : 1}
            // index={2}
            data-selenium-id='EmailTemplates-EmailTemplatesMain-TabPanel3'
          >
            {emailSIPTemplatesList &&
            emailSIPTemplatesList !== [] &&
            emailSIPTemplatesList.manuallySendingEmails !== undefined &&
            emailSIPTemplatesList.automaticallySendingEmails !== undefined &&
            emailSIPPermisssion ? (
              <>
                <ManuallySendingEmails
                  manuallySentDetail={
                    emailSIPTemplatesList.manuallySendingEmails
                  }
                  userCustomize={true}
                  cusomizeList={emailCustomizeSIPTemplatesList}
                ></ManuallySendingEmails>
              </>
            ) : (
              <></>
            )}
          </TabPanel>
        </Box>
      </Box>
    </NewDesignedMain>
  );
}
export default CustomEmailTemplatesMain;

import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { ChartGroups } from '@/components/Chart/ChartGroups';
import { ChartList } from '@/components/Chart/ChartList';
import styles from './index.module.scss';
import { changeSiderOpen } from '@/actions/SIMT-User/User/UserManageAction';
import { getFyYearFromDate } from '@/utils/date_string';

import SubmissionSG from '@/assets/ReportDiagrams/Articles/FYTrends/SubmissionSG.png';
import SubmissionBD from '@/assets/ReportDiagrams/Articles/FYTrends/SubmissionBD.png';
import AcceptanceSG from '@/assets/ReportDiagrams/Articles/FYTrends/AcceptanceSG.png';
import AcceptanceBD from '@/assets/ReportDiagrams/Articles/FYTrends/AcceptanceBD.png';
import PublicationSG from '@/assets/ReportDiagrams/Articles/FYTrends/PublicationSG.png';
import PublicationBD from '@/assets/ReportDiagrams/Articles/FYTrends/PublicationBD.png';

import ArticleFYTendsChart from './Diagrams';

import {
  ARTICLE,
  FY_TRENDS_IN_SUBJECT_GROUP_LEVEL,
  FY_TRENDS_IN_BUSINESS_DIVISION_LEVEL,
  SUBMISSION,
  ACCEPTANCE,
  PUBLICATION,
} from './Constants/index.js';

/**
 * chart settings
 **/

// basic diagram setting
const DIAGRAM_SETTINGS = [
  {
    groupName: FY_TRENDS_IN_SUBJECT_GROUP_LEVEL,
    chartName: SUBMISSION,
    hasAdditionalFilter: true,
    catagoryAttributeName: 'sg',
    singleSelectListUrl: '/reports/article/get-sg/',
    url: '/reports/article/diagram/fy-trends/sg/submission/',
    downloadUrl: '/reports/export/article/fy-trends/sg',
    tableDownloadTitle: SUBMISSION
  },
  {
    groupName: FY_TRENDS_IN_SUBJECT_GROUP_LEVEL,
    chartName: ACCEPTANCE,
    hasAdditionalFilter: true,
    catagoryAttributeName: 'sg',
    singleSelectListUrl: '/reports/article/get-sg/',
    url: '/reports/article/diagram/fy-trends/sg/acceptance/',
    downloadUrl: '/reports/export/article/fy-trends/sg',
    tableDownloadTitle: ACCEPTANCE
  },
  {
    groupName: FY_TRENDS_IN_SUBJECT_GROUP_LEVEL,
    chartName: PUBLICATION,
    hasAdditionalFilter: true,
    catagoryAttributeName: 'sg',
    singleSelectListUrl: '/reports/article/get-sg/',
    url: '/reports/article/diagram/fy-trends/sg/publication/',
    downloadUrl: '/reports/export/article/fy-trends/sg',
    tableDownloadTitle: PUBLICATION
  },
  //Business Division Level
  {
    groupName: FY_TRENDS_IN_BUSINESS_DIVISION_LEVEL,
    chartName: SUBMISSION,
    hasAdditionalFilter: true,
    catagoryAttributeName: 'businessDivision',
    url: '/reports/article/diagram/fy-trends/bd/submission/', // get chat data
    singleSelectListUrl: '/reports/article/get-busdiv/', // get business divisions
    downloadUrl: '/reports/export/article/fy-trends/bd', // excel download
    tableDownloadTitle: SUBMISSION
  },
  {
    groupName: FY_TRENDS_IN_BUSINESS_DIVISION_LEVEL,
    chartName: ACCEPTANCE,
    hasAdditionalFilter: true,
    catagoryAttributeName: 'businessDivision',
    url: '/reports/article/diagram/fy-trends/bd/acceptance/', // get chat data
    singleSelectListUrl: '/reports/article/get-busdiv/', // get business divisions
    downloadUrl: '/reports/export/article/fy-trends/bd', // excel download
    tableDownloadTitle: ACCEPTANCE
  },
  {
    groupName: FY_TRENDS_IN_BUSINESS_DIVISION_LEVEL,
    chartName: PUBLICATION,
    hasAdditionalFilter: true,
    catagoryAttributeName: 'businessDivision',
    url: '/reports/article/diagram/fy-trends/bd/publication/', // get chat data
    singleSelectListUrl: '/reports/article/get-busdiv/', // get business divisions
    downloadUrl: '/reports/export/article/fy-trends/bd', // excel download
    tableDownloadTitle: PUBLICATION
  },
];

// settings for ChartGroups on overview page
const CHART_GROUP_TITLES = [
  {
    groupTitle: FY_TRENDS_IN_SUBJECT_GROUP_LEVEL,
    levels: [
      {
        chartIndex: 0,
        title: SUBMISSION,
        img: SubmissionSG,
      },
      {
        chartIndex: 1,
        title: ACCEPTANCE,
        img: AcceptanceSG,
      },
      {
        chartIndex: 2,
        title: PUBLICATION,
        img: PublicationSG,
      },
    ],
  },
  {
    groupTitle: FY_TRENDS_IN_BUSINESS_DIVISION_LEVEL,
    levels: [
      {
        chartIndex: 3,
        title: SUBMISSION,
        img: SubmissionBD,
      },
      {
        chartIndex: 4,
        title: ACCEPTANCE,
        img: AcceptanceBD,
      },
      {
        chartIndex: 5,
        title: PUBLICATION,
        img: PublicationBD,
      },
    ],
  },
];

// base setting for ChartList on diagram detail page (right side)
const CHART_LIST_TITLES = [
  {
    groupTitle: 'Month-on-Month Submission Trends',
    levels: [
      {
        chartIndex: 0,
        title: 'Subject Group',
        img: SubmissionSG,
      },
    ],
  },
  {
    groupTitle: 'Month-on-Month Acceptance Trends',
    levels: [
      {
        chartIndex: 1,
        title: 'Subject Group',
        img: AcceptanceSG,
      },
    ],
  },
  {
    groupTitle: 'Month-on-Month Publication Trends',
    levels: [
      {
        chartIndex: 2,
        title: 'Subject Group',
        img: PublicationSG,
      },
    ],
  },
  {
    groupTitle: 'Month-on-Month Submission Trends',
    levels: [
      {
        chartIndex: 3,
        title: 'Business Division',
        img: SubmissionBD,
      },
    ],
  },
  {
    groupTitle: 'Month-on-Month Acceptance Trends',
    levels: [
      {
        chartIndex: 4,
        title: 'Business Division',
        img: AcceptanceBD,
      },
    ],
  },
  {
    groupTitle: 'Month-on-Month Publication Trends',
    levels: [
      {
        chartIndex: 5,
        title: 'Business Division',
        img: PublicationBD,
      },
    ],
  },
];

// update chart list titles by adding fiscal year
const addFYToChartListTitles = (chartListTitles, fiscalYear) => {
  const newListTitles = chartListTitles.map(group => ({
    ...group,
    groupTitle: `${group.groupTitle} in ${fiscalYear}`,
  }));

  return newListTitles;
};

const DiagramContent = () => {
  // get showTable from URL
  let [searchParams, setSearchParams] = useSearchParams();
  let selectedChartIndex = searchParams.get('selectedChartIndex');
  if (selectedChartIndex == null) {
    selectedChartIndex = -1;
  } else {
    selectedChartIndex = parseInt(selectedChartIndex);
  }

  const setShowTableNav = chartIndex => {
    setSearchParams({ showTable: false, selectedChartIndex: chartIndex });
  };

  const siderOpen = useSelector(state => state.UserManage.siderOpen);
  const dispatch = useDispatch();

  const selectMonthDate = useSelector(state => state.Report.selectDate);
  const [selectedFY, setSelectFY__] = useState(
    getFyYearFromDate(selectMonthDate)
  );
  useEffect(() => {
    if (selectMonthDate != null) {
      const fyYear = getFyYearFromDate(selectMonthDate);
      setSelectFY__(fyYear);
    }
  }, [selectMonthDate]);

  const chartListTitles = useMemo(() => {
    return addFYToChartListTitles(CHART_LIST_TITLES, selectedFY);
  }, [selectedFY]);

  const {
    groupName,
    chartName,
    hasAdditionalFilter,
    catagoryAttributeName,
    url,
    singleSelectListUrl,
    downloadUrl,
    tableDownloadTitle
  } = useMemo(() => {
    if (
      selectedChartIndex >= 0 &&
      selectedChartIndex < DIAGRAM_SETTINGS.length
    ) {
      return DIAGRAM_SETTINGS[selectedChartIndex];
    } else {
      return {
        groupName: '',
        chartName: '',
        hasAdditionalFilter: null,
        catagoryAttributeName: null,
        singleSelectListUrl: null,
        url: null,
        downloadUrl: null,
        tableDownloadTitle: null,
      };
    }
  }, [selectedChartIndex]);

  // select an index from configuartion array.
  const onSelectChartIndex = index => {
    setShowTableNav(index);

    // if a chart is selected and the side menu is open, close the side menu
    if (index >= 0 && index < DIAGRAM_SETTINGS.length && siderOpen) {
      dispatch(changeSiderOpen(false));
    }
  };

  const cancelSelectedChart = () => onSelectChartIndex(-1);

  return (
    <Box sx={{ marginTop: '32px', paddingBottom: '62px', width: '100%' }}>
      {selectedChartIndex >= 0 ? (
        <Box className={styles['card-content-container']}>
          <div
            className={styles['card-content-paper']}
            disableGutters
            maxWidth={false}
          >
            <ArticleFYTendsChart
              tabName={ARTICLE}
              header={groupName}
              subHeader={chartName}
              selectedFY={selectedFY}
              cancelSelectedChart={cancelSelectedChart}
              showSubjectGroupsSelector={false}
              hasAdditionalFilter={hasAdditionalFilter}
              catagoryAttributeName={catagoryAttributeName}
              url={url}
              singleSelectListUrl={singleSelectListUrl}
              downloadUrl={downloadUrl}
              tableDownloadTitle={tableDownloadTitle}
            />
          </div>
          <ChartList
            chartGroupTitles={chartListTitles}
            onSelectChartIndex={onSelectChartIndex}
            selectedChartIndex={selectedChartIndex}
          />
        </Box>
      ) : (
        <ChartGroups
          chartGroupTitles={CHART_GROUP_TITLES}
          onSelectChartIndex={onSelectChartIndex}
          selectedChartIndex={selectedChartIndex}
        />
      )}
    </Box>
  );
};

export default DiagramContent;

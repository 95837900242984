import { Box, DialogActions, DialogContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import SIModalPro from '@/modules/Modal';
import { useParams } from 'react-router-dom';
import { StateButton } from '@/componentsphase2/StateButton';
import { LoadingButton } from '@mui/lab';
import { useIntl } from 'react-intl';
import { DeleteCV, getSIPStatus } from '@/actions/SIMT-SIP/SIP/SipDetail';
import InviteEBMPage from './InviteEBMPage';
import InviteEBMEmailPreview from './InviteEBMEmailPreview';
import { EMAIL_PATTERN } from '@/constant/common';
import { getEbmReviewPanel } from '@/actions/SIMT-SIP/SIP/SipDetail';
import CommonSnackBar from '@/modules/ConfirmSnackBar/CommonSnackBar';
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";
import { axios_instance } from '@/utils/axios_instance';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { ButtonAlertBox } from '@/components/Alert/AlertBox';
import { getBtoa } from '@/actions/SIMT-Email/Template';
function findDuplicateEmails(ebmList) {
  const emails = ebmList.map(obj => obj.email);
  const emailMap = new Map();
  const duplicateIndices = [];

  // 遍历邮箱列表
  emails.forEach((email, index) => {
    if (emailMap.has(email)) {
      // 如果邮箱已经在 Map 中存在，则将其索引添加到重复索引列表中
      const indices = emailMap.get(email);
      indices.push(index);
      emailMap.set(email, indices);
    } else {
      // 如果邮箱不在 Map 中，则将其添加到 Map 中
      emailMap.set(email, [index]);
    }
  });

  // 遍历 Map 中的条目，找到重复的邮箱索引
  for (const [email, indices] of emailMap.entries()) {
    if (indices.length > 1) {
      duplicateIndices.push(...indices);
    }
  }
  return duplicateIndices;
}

function ebmListValidate(ebmList) {
  let hasError = false;
  ebmList.forEach(ebm => {
    if (!ebm.email || !ebm.email.match(EMAIL_PATTERN)) {
      hasError = true;
    }
    if (ebm.firstName === '' || ebm.lastName === '') {
      hasError = true;
    }
  });
  return hasError;
}

export function InviteEBMModal(props) {
  const { title = '', open = false, handleClose = () => { }, invitedEbm = [] } = props;

  const [data, setData] = useState([]);
  const [isClick, setIsClick] = useState(false);
  const [isNextClick, setIsNextClick] = useState(false);

  const [emailAddressFormatErr, setEmailAddressFormatErr] = useState(false);
  const [webFormatErr, setWebFormatErr] = useState(false);
  const [saving, setSaving] = useState(false);
  const [page, setPage] = useState(1);
  const [canDelete, setCanDelete] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [emailRepeatIndex, setEmailRepeatIndex] = useState([]);
  const [buttonAlertOpen, setButtonAlertOpen] = useState(false);
  const [emailBody, setEmailBody] = useState('');

  const { sipCode } = useParams();
  const selector = state => {
    return {
      sipStatus: state.SIP.sipStatus,
    };
  };
  const { sipStatus } = useSelector(selector);

  useEffect(() => {
    dispatch(getSIPStatus(sipCode));
  }, [sipCode]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.length > 1) {
      setCanDelete(true);
    } else {
      setCanDelete(false);
    }
  }, [data]);

  useEffect(() => {
    const repeatIndex = findDuplicateEmails(data.concat(invitedEbm || []));
    setEmailRepeatIndex(repeatIndex);
    if (ebmListValidate(data) || repeatIndex.length > 0) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [data, invitedEbm]);

  const alertButton = [
    {
      text: 'Keep Editing',
      onClick: () => { setButtonAlertOpen(false); }
    },
    {
      text: 'Submit',
      onClick: () => {
        setButtonAlertOpen(false);
        submitEbm(sipCode, sipStatus.sipVersion, data, emailBody);
      }
    }
  ];

  const actionButtonStyle = useMemo(() => {
    return {
      fontWeight: 600,
      fontSize: '14px',
      height: '41px',
      padding: '12px 18px',
      ml: '10px',
      background: saving ? '#6D98EE' : '#0052CC',
      borderRadius: '5px',
      color: '#FFFFFF',
      width: '82px',
      height: '34px',
      textTransform: 'none',
      '&:hover': {
        background: '#6D98EE',
      },
      '&:active': {
        background: '#113D95',
      },
      '&:disabled': {
        background: '#DFE4E8',
        color: '#98A7B6',
      },
    };
  }, [saving]);

  const intl = useIntl();

  const ErrBox = () => {
    let errText = '';
    if (data) {
      if (emailAddressFormatErr) {
        errText = 'A valid email address must be filled in.';
      } else if (webFormatErr && isClick) {
        errText = 'The profile website must be a valid URL.';
      }
    }
    return (
      <Box
        data-selenium-id='SIP_DetailPage_GE-GEInfomationAddModal-Box'
        sx={{
          height: '16px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '16px',
          color: '#EE5350',
          letterSpacing: '-0.084px',
          marginTop: '10px',
          display: errText === '' ? 'none' : 'block',
        }}
      >
        {errText}
      </Box>
    );
  };

  const handleModalClose = () => {
    setEmailAddressFormatErr(false);
    setWebFormatErr(false);
    setIsClick(false);
    setIsNextClick(false);
    setPage(1);
    handleClose();
  };

  function submitEbm(sipCode, version, ebmList, emailBody) {
    axios_instance
      .post(`/api/v1/sip-service/special-issue-proposals/${sipCode}/ebms`, {
        version: version,
        sipEbmCreateDtos: ebmList,
        emailBody: getBtoa(emailBody),
      })
      .then(res => {
        if (res.data.code === 200) {
          dispatch(getEbmReviewPanel(sipCode));
          dispatch(getSIPStatus(sipCode));
          handleModalClose();
          dispatch(
            setSnackbarMessageAndOpen(
              'sipDetail.ebmReview.inviteSuccess',
              {},
              SEVERITIES.success
            )
          );
          return;
        } else {
          dispatch(
            setSnackbarMessageAndOpen(
              'sipDetail.ebmReview.inviteError',
              {},
              SEVERITIES.error
            )
          );
        }
      })
      .catch(e => {
        dispatch(
          setSnackbarMessageAndOpen(
            'sipDetail.ebmReview.inviteError',
            {},
            SEVERITIES.error
          )
        );
      })
      .finally(() => { });
  }

  return (
    <SIModalPro
      isRightModel={true}
      data-selenium-id='SIP_DetailPage_GE-GEInfomationAddModal-Dialog'
      open={open}
      title={title}
      handleClose={handleModalClose}
      titleElseElement={
        <Box>
          <StateButton
            titleName='Cancel'
            isPrimary={false}
            onClick={handleModalClose}
          ></StateButton>
          {page === 1 && (
            <LoadingButton
              sx={actionButtonStyle}
              disableElevation
              disableRipple
              id='StateButtonForPrimaryButtonSave'
              data-selenium-id='State_Button_For_Primary_Button_Save'
              onClick={() => {
                setIsNextClick(true);
                if (!hasError) {
                  setPage(2);
                }
              }}
              disabled={(isNextClick && hasError) || data?.length === 0}
              loading={saving}
              data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions-Box-EditBtnAvailable'
            >
              Next
            </LoadingButton>
          )}
          {page === 2 && (
            <LoadingButton
              sx={actionButtonStyle}
              disableElevation
              disableRipple
              id='StateButtonForPrimaryButtonSave'
              data-selenium-id='State_Button_For_Primary_Button_Save'
              onClick={() => {
                setButtonAlertOpen(true);
              }}
              loading={saving}
              data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions-Box-EditBtnAvailable'
            >
              Submit
            </LoadingButton>
          )}
        </Box>
      }
    >
      <ButtonAlertBox
        open={buttonAlertOpen}
        handleClose={() => { setButtonAlertOpen(false); }}
        title="Send this email?"
        message="After submission,the invitation will be sent out to assigned EBM(s). and the email cannot be revoked"
        buttons={alertButton}
        severity="warning"
        position={{ vertical: 'center', horizontal: 'center' }}
        autoHideDuration={-1}
        style={{
          width: '546px',
          height: '163px',
          top: '20px !important',
        }}
      />
      <CommonSnackBar />
      <>
        {page === 1 && (
          <InviteEBMPage
            canDelete={canDelete}
            existingEbmList={[...data, ...invitedEbm]}
            ebmListChange={setData}
            isNextClick={isNextClick}
            emailRepeatIndex={emailRepeatIndex}
          />
        )}
        {page === 2 && (
          <InviteEBMEmailPreview
            sipCode={sipCode}
            requestType='EBM Review - EBM Invitation'
            emailBody={emailBody}
            setEmailBody={setEmailBody}
          />
        )}
      </>
    </SIModalPro>
  );
}

import { Box, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import MarketChevronDown from '@/assets/MarketChevronDown.svg';
import { useIntl } from 'react-intl';
import SIPEmailTemplateBlock from './SIPEmailTemplateBlock';

function ManuallySendingEmails(props) {
  const { manuallySentDetail, userCustomize=false, cusomizeList=[], isSolicited=false } = props;

  const intl = useIntl(); // 为国际化提供的API支持
  const [isFold, setIsFold] = useState(true);
  //蓝色标题
  const BlueTitle = props => {
    return (
      <div
        style={{
          width: '100%',
          height: '24px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#113D95',
        }}
      >
        {props.children}
      </div>
    );
  };

  return (
    <>
      <Grid
        container
        style={{ gridGap: '16px', paddingTop: '32px', paddingBottom: '14px', }}
        data-selenium-id='EmailTemplates-SIPEmailTemplates-ManuallySendingEmails-Grid'
      >
        <Grid
          item
          data-selenium-id='EmailTemplates-SIPEmailTemplates-ManuallySendingEmails-Grid-Gird1'
        >
          {isFold ? (
            <Box
              data-selenium-id='EmailTemplates-SIPEmailTemplates-ManuallySendingEmails-Grid-Gird1-Box'
              style={{ paddingBottom: '4px' }}
              onClick={() => setIsFold(false)}
              sx={{ cursor: 'pointer' }}
            >
              <MarketChevronUp />
            </Box>
          ) : (
            <Box
              style={{ marginBottom: '4px'}}
              onClick={() => setIsFold(true)}
              id='MarketFoldDown'
              data-selenium-id='EmailTemplates-SIPEmailTemplates-ManuallySendingEmails-MarketFoldDown'
              sx={{ cursor: 'pointer' }}
            >
              <MarketChevronDown />
            </Box>
          )}
        </Grid>
        <Grid
          item
          md={11}
          sm={11}
          xs={11}
          data-selenium-id='EmailTemplates-SIPEmailTemplates-ManuallySendingEmails-Grid-Gird2'
        >
          <Stack
            direction='column'
            spacing={1}
            data-selenium-id='EmailTemplates-SIPEmailTemplates-ManuallySendingEmails-Grid-Gird2-Stack'
          >
            <Box data-selenium-id='EmailTemplates-SIPEmailTemplates-ManuallySendingEmails-Grid-Gird2-Stack-Box'>
              <BlueTitle>
                {intl.messages['email.ManuallySendingEmails']}
              </BlueTitle>
            </Box>
            {isFold ? (
              <>
                {manuallySentDetail === [] ? (
                  <></>
                ) : (
                  manuallySentDetail.map((item, index) => {
                    // const customize = cusomizeList.find(c => c.block == item.block);
                    // https://jira.wiley.com/browse/CT-8228
                    const customize = cusomizeList.find(c => c.name == item.name);

                    return (
                      <SIPEmailTemplateBlock
                        key={`${item.block}${item.name}`}
                        partTitle={item.name}
                        block={item.block}
                        contentDetail={customize ?? item}
                        type='Manual'
                        nextKey={`${item.block}+${item.name}`}
                        userCustomize={userCustomize}
                        customize={customize ? item : null }
                        isSolicited={isSolicited}
                      ></SIPEmailTemplateBlock>
                    );
                  })
                )}
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
export default ManuallySendingEmails;

import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  Stack,
} from '@mui/material';
import { H4Font, SmallBoldFont } from '@/components/FontStyle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ItemBlock } from './ItemBlock';
import { SelectBox } from './SelectBox';
import CATJournalBatchUpdateTable from './CATJournalBatchUpdateTable';
import ErrorIcon from '@/assets/Error.svg';
import { SmallFont } from '@/components/FontStyle';
import {
  cancelSelectAll,
  clearBatchUpdateCode,

} from '@/actions/SIMT-SI/CatJournal/CATJournalAction';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';
import SelectBoxWithSearch from './SelectBoxWithSearch';
import DrawerCloseButton from '@/componentsphase2/DrawerCloseButton';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import TextFieldErrorIcon from '../../../../assets/TextFieldErrorIcon.svg';
import { ca } from 'date-fns/locale';
import {CATJournalBatchUpdate, getCATJournalList} from "@/actions/SIMT-SI/CatJournal";
import {getCE} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueSystemAdmin";

export default function CATJournalBatchUpdatePop(props) {
  const { open = false, handleClose = () => {} } = props;

  const [index, setIndex] = useState(0);
  const [category, setCategory] = useState('');
  const [modeValue, setModeValue] = useState('');
  const [ceValue, setCEValue] = useState({ username: '' });
  // const [inputValue, setInputValue] = useState('');
  const [hasOut, setHasOut] = useState(false);
  const dispatch = useDispatch();

  const {
    CEResult,
    selected,
    batchUpdateResult,
    batchUpdateMonitor,
    batchUpdateCode,
    orderByType,
    page,
    pageSize,
    searchCATContent,
    CATData,
  } = useSelector(state => {
    return {
      CEResult: state.SI.CE,
      selected: state.CATJournal.selectedList,
      batchUpdateResult: state.CATJournal.batchUpdateResult,
      batchUpdateMonitor: state.CATJournal.batchUpdateMonitor,
      batchUpdateCode: state.CATJournal.batchUpdateCode,
      orderByType: state.CATJournal.orderByType,
      page: state.CATJournal.page,
      pageSize: state.CATJournal.pageSize,
      searchCATContent: state.CATJournal.searchCATContent,
      CATData: state.CATJournal.CATData.result,
    };
  });

  const [selectedList, setSelectedList] = useState([]);
  const init = () => {
    setIndex(0);
    setCategory('');
    setModeValue('');
    setCEValue({ username: '' });
    setHasClick(false);
    // setInputValue('');
  };

  const handleCancel = () => {
    setHasOut(false);
    if (index === 1) {
      setIndex(0);
    } else {
      handleClose();
      init();
      dispatch(clearBatchUpdateCode());
    }
  };

  const [sameHandlingCEError, setSameHandlingCEError] = useState(false);
  const [UpdateToCEFocus, setUpdateToCEFocus] = useState(false);
  useEffect(() => {
    // Find Selected Journals' Handling CE in CATData

    const selectedHandlingCEID = selected.map(item => {
      const matchedObject = CATData.find(x => x.journalCode === item);
      return matchedObject?.handlingCe?.userId ?? null;
    });

    if (selectedHandlingCEID.includes(ceValue?.userId)) {
      setSameHandlingCEError(true);
    } else {
      setSameHandlingCEError(false);
    }
  }, [ceValue]);


  const buildData = () => {
    if (category === 'Handling CE') {
      return {
        type: category,
        targetHandlingCEEmail: ceValue.email,
        batchCode: selectedList,
      };
    } else {
      return {
        type: category,
        targetDevelopmentModel: modeValue,
        batchCode: selectedList,
      };
    }
  };

  function isEmpty() {
    return (
      category === '' || category === null ||
      (category === 'Handling CE' && (ceValue === null|| ceValue?.username === '' )) ||
      (category === 'Development Model' && modeValue === '' || modeValue===null)
    );
  }

  const ExistEmptyValue = isEmpty();

  useEffect(() => {
    if (!isEmpty()) {
      setHasClick(false);
    }
  }, [ExistEmptyValue]);

  const [hasClick, setHasClick] = useState(false);

  const handleSave = () => {
    setHasOut(false);
    if (index === 0) {
      if (isEmpty()) {
        setHasClick(true);
        return;
      }
      setIndex(1);
    } else {
      if (selectedList.length === 0) {
        dispatch(
          setSnackbarMessageAndOpen(
            'catJournal.batchUpdateNoSelectorErr',
            {},
            SEVERITIES.error
          )
        );
        return;
      }
      dispatch(CATJournalBatchUpdate(buildData()));
    }
  };

  useEffect(() => {
    if (batchUpdateCode === null && batchUpdateResult !== null) {
      async function fetchData() {
        dispatch(cancelSelectAll());
        // await dispatch(
        //   getCATJournalList({
        //     pageNum: page,
        //     pageSize: pageSize,
        //     orderField: orderByType.orderBy,
        //     orderType: orderByType.order,
        //     keywords: searchCATContent,
        //   })
        // );
      }
      fetchData();
      handleClose();
      init();
    } else if (batchUpdateCode !== null) {
      setIndex(0);
    }
  }, [batchUpdateMonitor]);

  function ErrorMessage(props) {
    const { isError, text = 'Please provide the required information.' } =
      props;
    return isError ? (
      <Stack
        direction='row'
        spacing='2px'
        alignItems='center'
        justifyContent='flex-start'
      >
        <TextFieldErrorIcon />
        <Box
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
            color: '#EE5350',
            letterSpacing: '-0.084px',
          }}
        >
          {text}
        </Box>
      </Stack>
    ) : null;
  }


  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        init();
        dispatch(clearBatchUpdateCode());
      }}
      data-selenium-id='CATJournal_Overview-BatchUpdatePop-Dialog'
      sx={{
        //marginTop: '48px',
        //zIndex: 1401,
        '& .MuiDialog-paper': {
          minWidth: '600px',
          margin: 0,
          padding: '24px 32px 0px 32px',
          boxSizing: 'border-box',
          boxShadow: 'none',
        },
        '& .MuiBackdrop-root': {
          background: ' rgba(17, 61,149, 0.5)',
          backdropFilter: 'blur(4px)',
        },
      }}
    >
      {/* title */}
      <Box
        sx={{
          // marginBottom: index === 0 ? '0px' : '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
        data-selenium-id='CATJournal_Overview-BatchUpdatePop-Box1'
      >
        <Box
          sx={{
            position: 'relative',
            width: '536px',
            // height: '24px',
            wordBreak: 'break-word',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '0px',
            gap: '16px',
          }}
          data-selenium-id='CATJournal_Overview-BatchUpdatePop-Box1-Box'
        >
          <div
            style={{
              fontSize: '18px',
              fontFamily: 'Open Sans',
              fontWeight: 600,
              lineHeight: '125%',
              color: '#262E35',
              width: '442px',
            }}
          >
            {index === 0
              ? 'Batch Update '
              : `The ${category} of below ${
                  selected.length > 1 ? 'journals' : 'journal'
                } will be updated to ${
                  category === 'Handling CE' ? ceValue?.username : modeValue
                }`}
          </div>
          <DrawerCloseButton
            onClick={() => {
              handleClose();
              init();
              dispatch(clearBatchUpdateCode());
            }}
            data-selenium-id='CATJournal_Overview-BatchUpdatePop-IconButton'
          />
        </Box>
        {/* placeholder */}
        <Box
          sx={{
            width: '531px',
            wordBreak: 'break-word',
            marginTop: '16px',
            marginBottom: '8px',
            display: index === 0 ? 'none' : 'block',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '18px',
            color: '#262E35',
          }}
          data-selenium-id='CATJournal_Overview-BatchUpdatePop-Box2'
        >
          Please confirm. You can unselect a journal to avoid making changes to
          that journal if in any case you had made a wrong selection.
        </Box>
        {/* Error Message*/}
        {index === 0 || !hasOut ? null : (
          <Box
            data-selenium-id='CATJournal_Overview-BatchUpdatePop-Box2-ErrorBox'
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '12px',
              lineHeight: '16px',
              color: '#EE5350',
              height: '16px',
            }}
          >
            <ErrorIcon sx={{ height: '12px' }} />
            <p>&nbsp;Below highlighted opt-out journals can not be updated!</p>
          </Box>
        )}
        {index === 0 ||!sameHandlingCEError ? null : (
          <Box
            data-selenium-id='CATJournal_Overview-BatchUpdatePop-Box2-ErrorBox'
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '12px',
              lineHeight: '16px',
              color: '#EE5350',
              height: '16px',
            }}
          >
            <ErrorIcon sx={{ height: '12px' }} />
            <p>&nbsp; You cannot transfer below highlight SI to the same Handling CE</p>
          </Box>
        )}
      </Box>
      
      <DialogContent
        sx={{
          paddingLeft: index === 0 ? '5px' : '0px',
          paddingTop: '16px',
          paddingBottom: '18px',
          paddingRight: '0px',
          overflow: 'hidden',
        }}
        data-selenium-id='CATJournal_Overview-BatchUpdatePop-DialogContent1'
      >
        {index === 0 ? (
          <>
            <ItemBlock
              data-selenium-id='CATJournal_Overview-BatchUpdatePop-ItemBlock1'
              title='Select category'
              sx={{ marginBottom: '10px' }}
            >
              <FormControl
                sx={{
                  width: '358px',
                }}
              >
                <SinpleAutocomplete
                  isErr={category === '' && hasClick}
                  id='selectCategory'
                  data-selenium-id='CATJournal_Overview-BatchUpdatePop-SelectCategoryBox'
                  placeholder='Select category'
                  value={category}
                  onChange={(e, value) => {
                    setCategory(value);
                  }}
                  options={['Development Model', 'Handling CE']}
                  defaultQuery={true}
                  needClearInputValue={true}
                  readOnly={true}
                  needKeyboardDelete = {false}
                />
                <ErrorMessage isError={category === '' && hasClick} />
              </FormControl>
              {/*<SelectBox
                id='selectCategory'
                data-selenium-id='CATJournal_Overview-BatchUpdatePop-SelectCategoryBox'
                dropList={['Development Model', 'Handling CE']}
                value={category}
                placeholder='Select category'
                onChange={e => {
                  setCategory(e.target.value);
                }}
                error={batchUpdateCode === 14124}
                onFocus={() => {
                  dispatch(clearBatchUpdateCode());
                }}
              />*/}
            </ItemBlock>
            <ItemBlock
              title='Update to'
              // sx={{ display: category === '' ? 'none' : 'block' }}
              data-selenium-id='CATJournal_Overview-BatchUpdatePop-ItemBlock2'
            >
              {category === 'Handling CE' ? (
                <FormControl
                  sx={{
                    width: '358px',
                  }}
                >
                  <SinpleAutocomplete
                    id='updateToWithHandlingCE'
                    data-selenium-id='CATJournal_Overview-BatchUpdatePop-UpdateToSelectBoxWithSearch'
                    placeholder='Update to'
                    value={ceValue}
                    isErr={
                      ((ceValue?.username === ''|| ceValue === null ) && hasClick)
                    }
                    onChange={(e, newValue) => {
                      setCEValue(newValue ? newValue : null);
                    }}
                    options={CEResult}
                    getOptionLabel={option => {
                      if (option === null) {
                        return option;
                      } else {
                        return option.username;
                      }
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option.userId}>
                        <Stack direction='row' spacing={1.5}>
                          <Box
                            sx={{
                              fontFamily: 'Open Sans',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              fontSize: '12px',
                              lineHeight: '20px',
                              color: '#596A7C',
                            }}
                            data-selenium-id='CATJournal_Overview-BatchUpdatePop-Box'
                          >
                            {option.username}
                          </Box>
                        </Stack>
                      </li>
                    )}
                    defaultQuery={false}
                    loadOptionAction={getCE}
                    readOnly={false}
                    onFocus={() => {
                      setUpdateToCEFocus(true);
                      // console.log('ceValue', ceValue);
                    }}
                    onBlur={() => {
                      setUpdateToCEFocus(false);
                    }}
                  />
                  <ErrorMessage isError={(ceValue?.username === ''|| ceValue === null) && hasClick} />
                  {/* <ErrorMessage
                    isError={sameHandlingCEError && !UpdateToCEFocus}
                    text='You cannot batch update to the same Handling CE'
                  /> */}
                </FormControl>
              ) : (
                <FormControl
                  sx={{
                    width: '358px',
                  }}
                >
                  <SinpleAutocomplete
                    isErr={modeValue === '' && hasClick}
                    id='updateToWithMode'
                    data-selenium-id='CATJournal_Overview-BatchUpdatePop-UpdateToSelectBox'
                    placeholder='Update to'
                    value={modeValue}
                    onChange={(e, value) => {
                      setModeValue(value);
                    }}
                    options={[
                      'Basic Management',
                      'SI Commissioning',
                      'On Hold',
                    ]}
                    defaultQuery={true}
                    needClearInputValue={true}
                    readOnly={true}
                    needKeyboardDelete = {false}
                  />
                  <ErrorMessage isError={modeValue === '' && hasClick} />
                </FormControl>
              )}
            </ItemBlock>
          </>
        ) : (
          <>
            <CATJournalBatchUpdateTable
              setSelected={setSelectedList}
              setHasOut={setHasOut}
              hasOut={hasOut}
              category={category}
              data-selenium-id='CATJournal_Overview-BatchUpdatePop-CATJournalBatchUpdateTable'
              sameHandlingCEError={sameHandlingCEError}
              errorBatchUpdate={ceValue?.username}

            ></CATJournalBatchUpdateTable>
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{ padding: '0px', height: '68px' }}
        data-selenium-id='CATJournal_Overview-BatchUpdatePop-DialogContent2'
      >
        <Stack
          direction='row'
          spacing='9px'
          alignItems='center'
          justifyContent='flex-end'
          sx={{
            padding: '12px 0px 20px 8px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '34px',
              width: '70px',
              color: '#0052CC',
              border: '1px solid #0052CC',
              borderRadius: '5px',
              '&:hover': {
                color: '#6D98EE',
                background: '#FFFFFF',
              },
              '&:active': {
                color: '#6D98EE',
                background: '#e8edfb',
              },
            }}
            onClick={handleCancel}
          >
            <span
              style={{
                cursor: 'pointer',
                ...SmallBoldFont,
              }}
            >
              {index === 0 ? 'Cancel' : 'Back'}
            </span>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '34px',
              width: '70px',
              background:
                index === 0 && (((isEmpty() ) && hasClick) )
                  ? 'rgba(0, 0, 0, 0.12)'
                  : '#0052CC',
              color:
                index === 0 && (((isEmpty()) && hasClick) )
                  ? 'rgba(0, 0, 0, 0.26)'
                  : '#FFFFFF',
              borderRadius: '5px',
              cursor: 'pointer',
              pointerEvents:
                index === 0 && (((isEmpty()) && hasClick) )
                  ? 'none'
                  : 'auto',
              ...SmallBoldFont,
              '&:hover': {
                background: '#6D98EE',
              },
              '&:active': {
                background: '#113D95',
              },
            }}
            onClick={handleSave}
          >
            {index === 0 ? 'Next' : 'Confirm'}
          </Box>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

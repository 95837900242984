import { PERMISSIONS } from "@/constant/permission";

const QUICK_BTN_FIELDS = [
  'sipCode',
  'sipTitle',
];

const DROPDOWN_FIELDS = [
  'geName',
  'geEmail',
  'aimsAndScope',
  'topics',
  'keywords',
];

/**
 * input : permission
 * output: {quickBtnList, dropdownList}
 */
export const getFieldListByPermission = (permissions) => {
  let quickBtnFields = QUICK_BTN_FIELDS;
  let dropdownFields = DROPDOWN_FIELDS;

  if (permissions?.includes(PERMISSIONS.SIP_WRITE_HANDLING_CE)){
    quickBtnFields = [...quickBtnFields, 'handlingCe'];
    dropdownFields = ['operationsLead', 'screener', ...dropdownFields];
  }
  else if (permissions?.includes(PERMISSIONS.SIP_WRITE_OPS)) {
    quickBtnFields = [...quickBtnFields, 'operationsLead'];
    dropdownFields = ['handlingCe', 'screener', ...dropdownFields];
  }
  else if (permissions?.includes(PERMISSIONS.SIP_WRITE_SCREENER)) {
    quickBtnFields = [...quickBtnFields, 'screener'];
    dropdownFields = ['handlingCe', 'operationsLead', ...dropdownFields];
  }
  else {
    quickBtnFields = [...quickBtnFields, 'handlingCe'];
    dropdownFields = ['operationsLead', 'screener', ...dropdownFields];
  }

  return {
    quickBtnFields,
    dropdownFields,
  };
};



export function getLabelByField(field) {
  switch (field) {
    case 'sipCode':
      return 'SIP Code';
    case 'sipTitle':
      return 'SIP Title';
    case 'handlingCe':
      return 'CE';
    case 'operationsLead':
      return 'Operations Lead';
    case 'screener':
      return 'Screener';
    case 'geName':
      return 'GE Name';
    case 'geEmail':
      return 'GE Email Address';
    case 'aimsAndScope':
      return 'Aims and Scope';
    case 'topics':
      return 'Topics';
    case 'keywords':
      return 'Keywords';
    default:
      return '未知字段';
  }
}

export function getFieldByLabel(label) {
  switch (label) {
    case 'SIP Code':
      return 'sipCode';
    case 'SIP Title':
      return 'sipTitle';
    case 'CE':
      return 'handlingCe';
    case 'Operations Lead':
      return 'operationsLead';
    case 'Screener':
      return 'screener';
    case 'GE Name':
      return 'geName';
    case 'GE Email Address':
      return 'geEmail';
    case 'Aims and Scope':
      return 'aimsAndScope';
    case 'Topics':
      return 'topics';
    case 'Keywords':
      return 'keywords';
    default:
      return '未知字段';
  }
}

// for button color
export function getThemeByField(field) {
  switch (field) {
    case 'sipCode':
      return 'green';
    case 'sipTitle':
      return 'orange';
    case 'handlingCe':
    case 'operationsLead':
    case 'screener':
      return 'purple';
    default:
      return 'default';
  }
}

// define button theme color
export const getThemeColor = theme => {
  switch (theme) {
    case 'purple':
      return {
        first: '#4B56BB',
        second: '#C1C6EF',
        third: '#7882DC',
      };
    case 'green':
      return {
        first: '#0D6A61',
        second: '#84D4CA',
        third: '#48A79A',
      };
    case 'orange':
      return {
        first: '#D97706',
        second: '#FFD495',
        third: '#FF8A00',
      };
    default:
      return {};
  }
};

export function calcDropListHeight(len, height, gap, other) {
  let num = len < 6 ? len : 6;
  return (height + gap) * (num - 1) + height + other;
};

export function getPlaceHolderByLabel(label) {
  switch (label) {
    case 'Journal Name/Code':
      return 'Refine by Journal Name or Journal Code';
    case 'Lead GE':
      return 'Refine by GE Name';
    default:
      return `Refine by ${label}`;
  }
};

export const checkIsObjectArray = (data) => {
  const firstItem = Array.isArray(data) && data.length > 0 ? data[0] : null;
  const isObject = firstItem && typeof firstItem === 'object' ? true : false;
  return isObject;
};

import {
  BarAndLineChartOptions,
  LEGEND_STYLE,
} from '@/pages/Report/chartUtils/chartOptions/BarAndLineChartOptions.js';
import {
  FY_TRENDS_OF_SI_ACQUISITION,
  BAR_COLORS,
  ACCEPTANCE,
  PUBLICATION,
  SUBMISSION,
} from '../../Constants/index.js';
import {
  mergeFyTrendsLineData,
  replaceNullWithZero,
} from '@/pages/Report/utils/reportUtils.js';

/**
 * overwrite the default configuration
 */
const LEGEND_POS_ORIGIN = 200;
const LEGEND_POS_OFFSET = -200;
const LEGEND_POS_ICON_OFFSET = 8;
const LINE_X_AXIS_INDEX = {
  xAxisIndex: 1,
};
const MAX_WIDTH = 1364;
export class AAndAFYTrendsSGOption extends BarAndLineChartOptions {
  constructor(header, subHeader, sgOO, sgOA, bdOO, bdOA, haveBD) {
    super();
    // overwrite the default properties
    // e.g. this.titleHeight = TITLE_HEIGHT
    this.header = header;
    this.subHeader = subHeader;
    this.sgOO = sgOO;
    this.sgOA = sgOA;
    this.bdOO = bdOO;
    this.bdOA = bdOA;
    this.haveBD = haveBD;
    this.legend_pos = LEGEND_POS_ORIGIN;
  }

  getBarSeries() {
    if (this.haveBD) {
      const nameList = this.dataForBar[0].slice(1);
      const dataLength = nameList.length;
      const oaLineData = mergeFyTrendsLineData(
        nameList,
        this.dataForBar[4].slice(1)
      ).map((x, i) => {
        return {
          name: x.name,
          value: [
            MAX_WIDTH / (dataLength * 2) +
              35 / 2 +
              i * (MAX_WIDTH / dataLength),
            x.value,
          ],
        };
      });
      const allLineData = mergeFyTrendsLineData(
        nameList,
        this.dataForBar[3].slice(1)
      ).map((x, i) => {
        return {
          name: x.name,
          value: [
            MAX_WIDTH / (dataLength * 2) -
              35 / 2 +
              i * (MAX_WIDTH / dataLength),
            x.value,
          ],
        };
      });
      let barSeries = [
        {
          ...this.barSerieOption,
          itemStyle: {
            normal: {
              label: {
                show: false,
                position: 'inside',
                textStyle: {
                  color: '#596A7C',
                  fontSize: 5,
                },
              },
              borderColor: this.getBarColors().upBarBorderColor,
              borderWidth: 1,
            },
          },
          stack: 'oo',
          seriesLayoutBy: 'row',
          color: this.getBarColors().upBarColor,
          tooltip: {
            ...this.getTooltip(),
            borderColor: this.getBarColors().upBarBorderColor,
          },
          barMaxWidth: 35,
          barMinHeight: 3,
          name: this.bdOO,
          encode: {
            x: 0,
            y: 1,
          },
        },
        {
          ...this.barSerieOption,
          itemStyle: {
            normal: {
              label: {
                show: false,
                position: 'inside',
                textStyle: {
                  color: '#596A7C',
                  fontSize: 5,
                },
              },
              borderColor: this.getBarColors().downBarBorderColor,
              borderWidth: 1,
            },
          },
          stack: 'oa',
          seriesLayoutBy: 'row',
          color: this.getBarColors().downBarColor,
          tooltip: {
            ...this.getTooltip(),
            borderColor: this.getBarColors().downBarBorderColor,
          },
          barMaxWidth: 35,
          barMinHeight: 3,
          barGap: '0%',
          name: this.bdOA,
          encode: {
            x: 0,
            y: 2,
          },
        },
        {
          type: 'line',
          ...LINE_X_AXIS_INDEX,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#596A7C',
                  fontSize: 10,
                },
                formatter: params => {
                  if (params.data[4] === 0) {
                    return '';
                  }
                  return params.data[4];
                },
              },
            },
            borderColor: this.getBarColors().rightLineColor,
            borderWidth: 0.5,
          },
          stack: 'myline',
          seriesLayoutBy: 'row',
          color: this.getBarColors().rightLineColor,
          // symbol: 'circle', //设定为实心点
          symbol: 'image://' + this.getBarColors().rightLineIcon,
          symbolSize: 14, //设定实心点的大小
          name: this.sgOA,
          data: oaLineData,
          encode: {
            x: 0,
            y: 4,
          },
        },
        {
          type: 'line',
          ...LINE_X_AXIS_INDEX,
          data: allLineData,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#596A7C',
                  fontSize: 10,
                },
                formatter: params => {
                  if (params.data[3] === 0) {
                    return '';
                  }
                  return params.data[3];
                },
              },
            },
          },
          stack: 'myline2',
          seriesLayoutBy: 'row',
          color: this.getBarColors().leftLineColor,
          // symbol: 'circle', //设定为实心点
          symbol: 'image://' + this.getBarColors().leftLineIcon,
          symbolSize: 14, //设定实心点的大小
          name: this.sgOO,
          encode: {
            x: 0,
            y: 3,
          },
        },
      ];
      return barSeries;
    } else {
      return [
        {
          ...this.barSerieOption,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#596A7C',
                  fontSize: 10,
                },
                formatter: params => {
                  if (params.data[4] === 0) {
                    return '';
                  }
                  return params.data[4];
                },
              },
            },
            borderColor: this.getBarColors().rightLineColor,
            borderWidth: 0.5,
          },
          type: 'line',
          stack: 'myline',
          seriesLayoutBy: 'row',
          color: this.getBarColors().rightLineColor,
          // symbol: 'circle', //设定为实心点
          symbol: 'image://' + this.getBarColors().rightLineIcon,
          symbolSize: 14, //设定实心点的大小
          name: this.sgOA,
          encode: {
            x: 0,
            y: 2,
          },
        },
        {
          ...this.barSerieOption,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#596A7C',
                  fontSize: 10,
                },
                formatter: params => {
                  if (params.data[3] === 0) {
                    return '';
                  }
                  return params.data[3];
                },
              },
            },
          },
          type: 'line',
          stack: 'myline2',
          seriesLayoutBy: 'row',
          color: this.getBarColors().leftLineColor,
          // symbol: 'circle', //设定为实心点
          symbol: 'image://' + this.getBarColors().leftLineIcon,
          symbolSize: 14, //设定实心点的大小
          name: this.sgOO,
          encode: {
            x: 0,
            y: 1,
          },
        },
      ];
    }
  }

  getTooltip() {
    return [
      {
        ...this.tooltip,
        formatter: params => {
          let fontColor;
          let backgroundColor;
          let data;
          if (params.componentSubType === 'line') {
            fontColor = '#FFFFFF';
            backgroundColor = params.color;
            data = params.data.value[1];
          } else {
            data = params.data[params.encode.y];
          }
          let dataStr = `<div style="padding: 6px 8px; margin: 0; background-color: ${backgroundColor};">
            <p style="font-size: 12px; font-weight: 700; color:${
              !fontColor ? '#243C9C' : fontColor
            }; line-height: 16px; margin: 0 0 2.5px 0;">${params.name}</p>
            <p style="font-size: 10px; font-weight: 400;color: ${
              !fontColor ? '#848484' : fontColor
            }; line-height: 14px; margin: 0;">${
            params.seriesName
          }&nbsp;:&nbsp;${data}</p>
          </div>`;
          return dataStr;
        },
        padding: 0,
      },
    ];
  }

  getXAxis() {
    const xAxis = super.getXAxis();
    return [
      { ...xAxis },
      { type: 'value', max: MAX_WIDTH, show: false },
    ];
  }

  setLegendSG(legendSG) {
    this.legendSG = legendSG;
    return this;
  }

  setLegendBD(legendBD) {
    this.legendBD = legendBD;
    return this;
  }

  getSelectedLegend() {
    if (this.haveBD) {
      return [
        {
          ...LEGEND_STYLE,
          itemWidth: 32,
          data: [
            {
              name: this.legendSG[1],
              icon: 'image://' + this.getBarColors().legendIconOO,
            },
          ],
          left: this.legend_pos,
          bottom: 45,
        },
        {
          ...LEGEND_STYLE,
          itemWidth: 32,
          data: [
            {
              name: this.legendBD[1],
              icon: 'image://' + this.getBarColors().legendIconOA,
            },
          ],
          left: this.legend_pos + 250,
          bottom: 45,
        },
        {
          ...LEGEND_STYLE,
          data: [{ name: this.legendSG[0] }],
          left: this.legend_pos + LEGEND_POS_ICON_OFFSET,
          bottom: 15,
        },
        {
          ...LEGEND_STYLE,
          data: [{ name: this.legendBD[0] }],
          left: this.legend_pos + 250 + LEGEND_POS_ICON_OFFSET,
          bottom: 15,
        },
      ];
    } else {
      return [
        {
          ...LEGEND_STYLE,
          itemWidth: 32,
          data: [
            {
              name: this.legend[0],
              icon: 'image://' + this.getBarColors().legendIconOO,
            },
          ],
          left: this.legend_pos,
          bottom: 45,
        },
        {
          ...LEGEND_STYLE,
          itemWidth: 32,
          data: [
            {
              name: this.legend[1],
              icon: 'image://' + this.getBarColors().legendIconOA,
            },
          ],
          left: this.legend_pos + 200,
          bottom: 45,
        },
      ];
    }
  }

  getBarColors = () => {
    switch (this.subHeader) {
      case SUBMISSION:
        return {
          upBarColor: BAR_COLORS.subjectGroup.submission.oo,
          upBarBorderColor: BAR_COLORS.subjectGroup.submission.borderOO,
          downBarColor: BAR_COLORS.subjectGroup.submission.oa,
          downBarBorderColor: BAR_COLORS.subjectGroup.submission.borderOA,
          leftLineColor: BAR_COLORS.subjectGroup.submission.lineOO,
          rightLineColor: BAR_COLORS.subjectGroup.submission.lineOA,
          leftLineIcon: BAR_COLORS.subjectGroup.submission.lineIconOO,
          rightLineIcon: BAR_COLORS.subjectGroup.submission.lineIconOA,
          legendIconOA: BAR_COLORS.subjectGroup.submission.legendIconOA,
          legendIconOO: BAR_COLORS.subjectGroup.submission.legendIconOO,
        };
      case ACCEPTANCE:
        return {
          upBarColor: BAR_COLORS.subjectGroup.acceptance.oo,
          upBarBorderColor: BAR_COLORS.subjectGroup.acceptance.borderOO,
          downBarColor: BAR_COLORS.subjectGroup.acceptance.oa,
          downBarBorderColor: BAR_COLORS.subjectGroup.acceptance.borderOA,
          leftLineColor: BAR_COLORS.subjectGroup.acceptance.lineOO,
          rightLineColor: BAR_COLORS.subjectGroup.acceptance.lineOA,
          leftLineIcon: BAR_COLORS.subjectGroup.acceptance.lineIconOO,
          rightLineIcon: BAR_COLORS.subjectGroup.acceptance.lineIconOA,
          legendIconOA: BAR_COLORS.subjectGroup.acceptance.legendIconOA,
          legendIconOO: BAR_COLORS.subjectGroup.acceptance.legendIconOO,
        };
      case PUBLICATION:
        return {
          upBarColor: BAR_COLORS.subjectGroup.publication.oo,
          upBarBorderColor: BAR_COLORS.subjectGroup.publication.borderOO,
          downBarColor: BAR_COLORS.subjectGroup.publication.oa,
          downBarBorderColor: BAR_COLORS.subjectGroup.publication.borderOA,
          leftLineColor: BAR_COLORS.subjectGroup.publication.lineOO,
          rightLineColor: BAR_COLORS.subjectGroup.publication.lineOA,
          leftLineIcon: BAR_COLORS.subjectGroup.publication.lineIconOO,
          rightLineIcon: BAR_COLORS.subjectGroup.publication.lineIconOA,
          legendIconOA: BAR_COLORS.subjectGroup.publication.legendIconOA,
          legendIconOO: BAR_COLORS.subjectGroup.publication.legendIconOO,
        };
      default:
        return {
          upBarColor: BAR_COLORS.subjectGroup.submission.oo,
          upBarBorderColor: BAR_COLORS.subjectGroup.submission.borderOO,
          downBarColor: BAR_COLORS.subjectGroup.submission.oa,
          downBarBorderColor: BAR_COLORS.subjectGroup.submission.borderOA,
          leftLineColor: BAR_COLORS.subjectGroup.submission.lineOO,
          rightLineColor: BAR_COLORS.subjectGroup.submission.lineOA,
          leftLineIcon: BAR_COLORS.subjectGroup.submission.lineIconOO,
          rightLineIcon: BAR_COLORS.subjectGroup.submission.lineIconOA,
          legendIconOA: BAR_COLORS.subjectGroup.submission.legendIconOA,
          legendIconOO: BAR_COLORS.subjectGroup.submission.legendIconOO,
        };
    }
  };
  setDataSource(dataForBar) {
    if (this.haveBD) {
      replaceNullWithZero(dataForBar, [3, 4]);
      this.dataForBar = dataForBar;
    } else {
      replaceNullWithZero(dataForBar, [1, 2]);
      this.dataForBar = dataForBar;
    }
    return this;
  }

  setWidth(width) {
    this.width = width;

    if (width && width !== '100%') {
      const half_width = width / 2;
      this.legend_pos = half_width + LEGEND_POS_OFFSET;
    }

    return this;
  }
}

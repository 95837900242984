import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
} from '@mui/material';
import { H4Font, SmallBoldFont } from '../../../../components/FontStyle';
import GEDetailCloseIcon from '@/assets/GEDetailCloseIcon.svg';

const emailContentFont = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '125%',
  color: '#596A7C',
};

export default function EmailContent(props) {
  const {
    open = false,
    handleClose = () => {},
    emailContent = {
      emailSubject: '',
      from: '',
      to: '',
      copy: '',
      bcc: '',
      content: '',
    },
  } = props;
  return (
    <Dialog
      data-selenium-id='SIP_DetailPage_Log-EmailContent-Dialog'
      open={open}
      onClose={handleClose}
      sx={{
        marginTop: '48px',
        '& .MuiDialog-paper': {
          minWidth: '1014px',
          margin: 0,
          padding: '32px 35px 0px 35px',
          boxSizing: 'border-box',
        },
      }}
    >
      <Box
        sx={{ height: '28px', marginBottom: '24px' }}
        data-selenium-id='SIP_DetailPage_Log-EmailContent-Dialog-Box'
      >
        <Box
          data-selenium-id='SIP_DetailPage_Log-EmailContent-Dialog-EmailBox'
          sx={{
            ...H4Font,
            height: '28px',
            float: 'left',
            color: '#262E35',
          }}
        >
          Email Content
        </Box>
        <IconButton
          data-selenium-id='SIP_DetailPage_Log-EmailContent-Dialog-IconButton'
          sx={{ float: 'right', cursor: 'pointer', padding: '0px' }}
          onClick={handleClose}
        >
          <GEDetailCloseIcon data-selenium-id='SIP_DetailPage_Log-EmailContent-Dialog-GECloseIcon' />
        </IconButton>
      </Box>
      <DialogContent
        sx={{ padding: '0px' }}
        data-selenium-id='SIP_DetailPage_Log-EmailContent-Dialog-Content'
      >
        <Stack
          direction='column'
          spacing={1}
          sx={{ marginBottom: '20px' }}
          data-selenium-id='SIP_DetailPage_Log-EmailContent-Dialog-Stack'
        >
          <Box
            data-selenium-id='SIP_DetailPage_Log-EmailContent-Dialog-SubjectBox'
            sx={{
              ...emailContentFont,
              display:
                !emailContent.emailSubject || emailContent.emailSubject === ''
                  ? 'none'
                  : 'block',
            }}
          >
            Subject: {emailContent.emailSubject}
          </Box>
          <Box
            data-selenium-id='SIP_DetailPage_Log-EmailContent-Dialog-FromBox'
            sx={{
              ...emailContentFont,
              display:
                !emailContent.from || emailContent.from === ''
                  ? 'none'
                  : 'block',
            }}
          >
            From: {emailContent.from}
          </Box>
          <Box
            data-selenium-id='SIP_DetailPage_Log-EmailContent-Dialog-ToBox'
            sx={{
              ...emailContentFont,
              display:
                !emailContent.to || emailContent.to === '' ? 'none' : 'block',
            }}
          >
            To: {emailContent.to}
          </Box>
          <Box
            data-selenium-id='SIP_DetailPage_Log-EmailContent-Dialog-CcBox'
            sx={{
              ...emailContentFont,
              display:
                !emailContent.copy || emailContent.copy === ''
                  ? 'none'
                  : 'block',
            }}
          >
            Cc: {emailContent.copy}
          </Box>
          <Box
            data-selenium-id='SIP_DetailPage_Log-EmailContent-Dialog-BccBox'
            sx={{
              ...emailContentFont,
              display:
                !emailContent.bcc || emailContent.bcc === '' ? 'none' : 'block',
            }}
          >
            Bcc: {emailContent.bcc}
          </Box>
        </Stack>
        <Box
          data-selenium-id='SIP_DetailPage_Log-EmailContent-Dialog-HtmlBox'
          dangerouslySetInnerHTML={{
            __html: emailContent.content?.replace(/class="mention"/g, ''),
          }}
        ></Box>
      </DialogContent>
      <DialogActions
        sx={{ padding: '0px', height: '68px' }}
        data-selenium-id='SIP_DetailPage_Log-EmailContent-Dialog-Actions'
      >
        <Box
          data-selenium-id='SIP_DetailPage_Log-EmailContent-Dialog-Actions-Box'
          sx={{
            float: 'right',
            margin: '16px',
            background: '#154AB6',
            color: '#FFFFFF',
            height: '32px',
            width: '61px',
            borderRadius: '3px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            ...SmallBoldFont,
          }}
          onClick={handleClose}
        >
          Close
        </Box>
      </DialogActions>
    </Dialog>
  );
}

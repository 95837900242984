import { axios_instance } from "@/utils/axios_instance";
import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {
    APPROVE_OPT_OUT_CODE,
    CHECK_JOURNAl_OPT_OUT_CODE,
    EDIT_JOURNAl_OPT_OUT_CODE,
    REJECT_OPT_OUT_CODE,
    SET_OPT_OUT_DATA,
    SUBMIT_JOURNAl_OPT_OUT_CODE,
    WITHDRAW_JOURNAl_OPT_OUT_CODE
} from "@/actions/SIMT-SI/CatJournal/CATJournalAction";
import { GetAllManagedJournals } from ".";

export const getOptOutData = (pageNum, pageSize) => {
    return async dispatch => {
        try {
            const url = '/api/v1/si-service/journals/managed/requests';
            const res = await axios_instance.get(url, {
                params: {
                    pageNum: pageNum,
                    pageSize: pageSize,
                    type: 'opt:out'
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.status === 200) {
                if (res.data.code === 200) {
                    dispatch({ type: SET_OPT_OUT_DATA, data: res.data.data });
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'catJournal.optOutGetError',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            }
        } catch (e) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'catJournal.optOutGetError',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
};
export const approveOptOut = (journalCode, page, pageSize) => {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/journals/managed/requests/${journalCode}`;
            const res = await axios_instance.put(
                url,
                {},
                {
                    params: {
                        requestType: 'approve'
                    },
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.status === 200) {
                if (res.data.code === 200) {
                    dispatch({ type: APPROVE_OPT_OUT_CODE, data: res.code });
                    dispatch(getOptOutData(page, pageSize));
                } else if (res.data.code === 14127) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'please opt-in this journal before opt-out ',
                            {},
                            SEVERITIES.warning
                        )
                    );
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'catJournal.optOutAppoveError',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            }
        } catch (e) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'catJournal.optOutAppoveError',
                    {},
                    SEVERITIES.error
                )
            );

        }
    };
};
export const rejectOptOut = (reason, journalCode, page, pageSize) => {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/journals/managed/requests/${journalCode}`;
            const res = await axios_instance.put(
                url,
                {
                    rejectReason: reason,
                    journalCode: journalCode,
                },
                {
                    params: {
                        requestType: 'reject'
                    },
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.status === 200) {
                if (res.data.code === 200) {
                    dispatch({ type: REJECT_OPT_OUT_CODE, data: res.code });
                    dispatch(getOptOutData(page, pageSize));
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'catJournal.optOutRejectError',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            }
        } catch (e) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'catJournal.optOutRejectError',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
};
/**
 * @param {string} journalCode 
 */
export const checkJournalOptOut = journalCode => {
    /**
     * @param {import("redux").Dispatch} dispatch
     */
    return async dispatch => {
        try {
            const res = await GetAllManagedJournals({
                journalCode, type: 'submitOptOutRequestCheckJournal'
            });
            if (res.status === 200) {
                dispatch({ type: CHECK_JOURNAl_OPT_OUT_CODE, data: res.data.code });
            }
        } catch (e) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'catJournal.optOutCheckError',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
};
export const submitJournalOptOut = (journalCode, reason, page, pageSize) => {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/journals/managed/requests`;
            const res = await axios_instance.post(
                url,
                {
                    journalCode: journalCode,
                    reason: reason,
                    type:'opt:out'
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.status === 200) {
                if (res.data.code === 200) {
                    dispatch({ type: SUBMIT_JOURNAl_OPT_OUT_CODE, data: res.code });
                    dispatch(getOptOutData(1, 50));
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'catJournal.optOutSubmitError',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            }
        } catch (e) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'catJournal.optOutSubmitError',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
};
///optOut/withdrawOptOutRequest/${journalCode}
export const withdrawJournalOptOut = (journalCode, page, pageSize) => {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/journals/managed/${journalCode}/requests`;
            const res = await axios_instance.delete(url, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
                params: {
                    type: 'out'
                }
            });
            if (res.status === 200) {
                if (res.data.code === 200) {
                    dispatch({ type: WITHDRAW_JOURNAl_OPT_OUT_CODE, data: res.code });
                    dispatch(getOptOutData(page, pageSize));
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'catJournal.optOutWithdrawError',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            }
        } catch (e) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'catJournal.optOutWithdrawError',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
};
export const editJournalOptOut = (
    eidtId,
    journalCode,
    reason,
    page,
    pageSize
) => {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/journals/managed/requests/${journalCode}`;
            const res = await axios_instance.put(
                url,
                {
                    id: eidtId,
                    journalCode: journalCode,
                    rejectReason: reason,
                },
                {
                    params: {
                        requestType: 'edit'
                    },
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.status === 200) {
                if (res.data.code === 200) {
                    dispatch({ type: EDIT_JOURNAl_OPT_OUT_CODE, data: res.code });
                    dispatch(getOptOutData(page, pageSize));
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'catJournal.optOutEditError',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            }
        } catch (e) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'catJournal.optOutEditError',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
};
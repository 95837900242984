import React from 'react';
import { Link } from 'react-router-dom';

export default function StyledLink(props) {
  const {
    type = 'a',
    style,
    ...other
  } = props;
  switch (type) {
    case 'a':
    default:
      return (
        <Link
          style={{
            color: '#154AB6',
            textDecoration: 'none',
            ...style
          }}
          {...other}
        />
      );
  }

}

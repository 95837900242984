import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import CheckBoxUncheckedIcon from '@/assets/ReportDiagrams/icons/unchecked.svg';
import CheckBoxCheckedIcon from '@/assets/ReportDiagrams/icons/checked.svg';
import CheckBoxIntermediateIcon from '@/assets/ReportDiagrams/icons/indeterminate.svg';
import { SelectorMenus } from '../SelectorMenus';

const SELECT_ALL = 'Select All';

export const MultipleSelect = ({
  disabled,
  label,
  options,
  selectedItems,
  setSelectedItems,
  hasSelectAllOption = true,
}) => {
  const [open, setOpen] = useState(false);

  const handleMenuItemClick = (_event, index) => {
    let newSelectedItems = [...selectedItems];
    const clickedOption = options[index];

    const indexOfClicked = selectedItems?.indexOf(clickedOption);

    if (indexOfClicked === -1) {
      // select it
      newSelectedItems.push(clickedOption);
    } else {
      //unselect it
      newSelectedItems.splice(indexOfClicked, 1);
    }
    setSelectedItems(newSelectedItems);
  };

  const isAllSelected = options?.length === selectedItems.length;

  const handleSelectAllClick = () => {
    let newSelectedItems;

    if (isAllSelected) {
      // uncheck all options
      newSelectedItems = [];
    } else {
      // check all options
      newSelectedItems = options;
    }
    setSelectedItems(newSelectedItems);
  };

  return (
    <SelectorMenus
      label={label}
      disabled={disabled}
      highlight={!isAllSelected}
      open={open}
      setOpen={setOpen}
    >
      {hasSelectAllOption && Array.isArray(options) && options.length > 0 && (
        <MenuItem
          key={SELECT_ALL}
          value={SELECT_ALL}
          onClick={handleSelectAllClick}
          disableGutters={true}
          disableRipple
          sx={{
            padding: '6px 12px',
          }}
        >
          <Checkbox
            checked={options.length === selectedItems.length}
            indeterminate={
              selectedItems.length > 0 && selectedItems.length < options.length
            }
            sx={{
              padding: '0 12px 0 0 ',
              [`&, &.${checkboxClasses.checked} svg, &.${checkboxClasses.indeterminate} svg`]:
                {
                  fill: '#0052CC',
                },
            }}
            icon={<CheckBoxUncheckedIcon />}
            checkedIcon={<CheckBoxCheckedIcon />}
            indeterminateIcon={<CheckBoxIntermediateIcon />}
            disableRipple
          />
          <ListItemText primary={SELECT_ALL} />
        </MenuItem>
      )}

      {Array.isArray(options) &&
        options.map((option, index) => (
          <MenuItem
            key={option}
            value={option}
            onClick={event => handleMenuItemClick(event, index)}
            disableRipple
            disableGutters={true}
            sx={{
              padding: '6px 12px',
            }}
          >
            <Checkbox
              checked={selectedItems?.indexOf(option) > -1 ?? false}
              sx={{
                padding: '0 12px 0 0 ',
                [`&, &.${checkboxClasses.checked} svg`]: {
                  fill: '#0052CC',
                },
              }}
              icon={<CheckBoxUncheckedIcon />}
              checkedIcon={<CheckBoxCheckedIcon />}
            />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
    </SelectorMenus>
  );
};

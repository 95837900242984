import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect } from "react";
import PeopleImage from '../../../assets/SIPPage/ThankYouPage/People.webp';
import SubmitCompletedPage, {
  buttonStyle,
  textStyle,
} from './SubmitCompletedPage';

const ThankAcceptancePage = props => {
  const {
    showCreateProposalButton = true,
    handleCreateProposal = () => {},
    theme = 'blue', // 三种主题颜色可选：blue, green, light
    ...others
  } = props;

  const matches900 = useMediaQuery('(min-width:900px)');
  const matches425 = useMediaQuery('(min-width:425px)');

  useEffect(()=>{
    document.body.classList.add('hide-snackbar');
    return () => {
      document.body.classList.remove('hide-snackbar');
    };
  },[]);

  return (
    <SubmitCompletedPage theme={theme} {...others}>
      <Stack
        data-selenium-id='SIP_SubmitComplete-ThankAcceptancePage-Stack'
        direction={matches900 ? 'row' : 'column'}
        sx={{
          position: 'relative',
          zIndex: 0,
          minHeight: '100%',
          paddingX: matches900 ? '128px' : '32px',
        }}
        alignItems='center'
        spacing={matches900 ? '32px' : '64px'}
        paddingY={matches900 ? 0 : '128px'}
      >
        <Stack
          data-selenium-id='SIP_SubmitComplete-ThankAcceptancePage-StactStack'
          sx={{
            width: matches900 ? '54%' : '100%',
            color: '#fff',
            flexShrink: 0,
            ...textStyle[theme],
          }}
          alignItems='flex-start'
          spacing='32px'
        >
          <Typography
            data-selenium-id='SIP_SubmitComplete-ThankAcceptancePage-StartStack-Typography1'
            sx={{ fontSize: matches425 ? '56px' : '44px', fontWeight: 800 }}
          >
            Thank you!
          </Typography>
          <Typography
            data-selenium-id='SIP_SubmitComplete-ThankAcceptancePage-StartStack-Typography2'
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              '& a': {
                fontWeight: 600,
                textDecoration: 'underline',
                color: 'inherit',
              },
            }}
          >
            Thank you for submitting your decision. We will get back to you soon
            with further steps if any. In case you have any questions regarding
            the proposal, please contact{' '}
            <a href='mailto:specialcontent@wiley.com'>
              specialcontent@wiley.com
            </a>{' '}
            with SIP Code and SIP Title in subject line. Thank you.
          </Typography>
        </Stack>
        <Box
          sx={{ flexGrow: 1 }}
          data-selenium-id='SIP_SubmitComplete-ThankAcceptancePage-Box'
        >
          <Box
            data-selenium-id='SIP_SubmitComplete-ThankAcceptancePage-InnerBox'
            maxWidth={'443px'}
            sx={{ boxSizing: 'border-box', position: 'relative' }}
          >
            <Box
              data-selenium-id='SIP_SubmitComplete-ThankAcceptancePage-BackGroundBox'
              sx={{
                position: 'absolute',
                top: '-16px',
                right: '-8px',
                width: '224px',
                height: '241px',
                zIndex: 2,
                backgroundColor:
                  theme === 'light' ? '#DFE4E8' : 'rgba(255, 255, 255, 0.5)',
                borderRadius: '6px',
              }}
            />
            <img
              data-selenium-id='SIP_SubmitComplete-ThankAcceptancePage-Box-Img'
              style={{
                objectFit: 'cover',
                width: '100%',
                position: 'relative',
                zIndex: 3,
              }}
              src={PeopleImage}
              alt='people'
            />
          </Box>
        </Box>
      </Stack>
    </SubmitCompletedPage>
  );
};

export default ThankAcceptancePage;

import {
    Box,
    Stack,
  } from '@mui/material';

import ErrorTag from '@/assets/Soild.svg';
import { SmallBoldFont } from '@/components/FontStyle';

  
  export function RadioErrorMessage(props) {
    const { isError, errorMessage='Please fill in the required field below:', sx} = props;
    return (
      isError && (
        <Stack
          direction='row'
          spacing={0.25}
          sx={{
            height: '16px',
            display: 'flex',
            alignItems: 'center',
            ...sx
          }}
        >
          <ErrorTag />
          <Box sx={{
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '12px',
            lineHeight:'16px',
            letterSpacing: '0.08em',
            color:'#EE5350',
            textAlign: 'left',
          }}>{errorMessage}</Box>
        </Stack>
      )
    );
  }
import {
  Table,
  TableContainer,
  tableCellClasses,
  TableCell,
  TableRow,
  Switch,
  Button,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '../../../../assets/rejectIcon.svg';
import TickIcon from '../../../../assets/tickIcon.svg';
import { FormattedMessage } from 'react-intl';
import { ResponsiveButton } from '../../../../components/StyledButton/SIMTButton';
import { ApprovalDialog } from './WaitingForApprovalDialog';
import { RejectDialog } from './WaitingForRejectDialog';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetWaitingForApprovalInfo,
  waitingApprovalMessageChange,
  waitingRejectMessageChange,
} from '../../../../actions/SIMT-Report/ReportJobsSubmission/ReportJobsAction';
import { ApprovalConfirm, RejectConfirm } from './WaitingForSnackBar';
import { unstable_batchedUpdates } from 'react-dom';
import { turnDateToUTC, formatDatetimeString } from '@/utils/commonUtils';
import {handleWaitingForApprovalApproveOrReject} from "@/actions/SIMT-Report/ReportJobsSubmission";

function MyTableRow(props) {
  const { index, item, everyClick, setEveryClick } = props;
  const [ApprovalOpen, setApprovalOpen] = useState(false);
  const [RejectOpen, setRejectOpen] = useState(false);
  const [ApprovalSnackBarOpen, setApprovalSnackBarOpen] = useState(false);
  const [RejectSnackBarOpen, setRejectSnackBarOpen] = useState(false);

  //everyClick每次点击弹窗内的approve就会变为相反数，用来记录每一次click
  const { ApprovalOrRejectResult, RejectResult, EveryClick } = useSelector(
    state => {
      return {
        ApprovalOrRejectResult: state.ReportJobs.ApprovalOrRejectResult,
        RejectResult: state.ReportJobs.RejectResult,
        EveryClick: state.ReportJobs.EveryClick,
      };
    }
  );
  const dispatch = useDispatch();

  const handleDialogPop = (value, reportName, selectMonth, status) => {
    let reportNameStandard = '';
    if (reportName.indexOf('SI Pipeline') !== -1) {
      reportNameStandard = 'SIPipeline';
    } else if (reportName.indexOf('Articles') !== -1) {
      reportNameStandard = 'Articles';
    } else if (reportName.indexOf('Acquired & Active SI') !== -1) {
      reportNameStandard = 'AcquiredAndActiveSI';
    } else if (reportName.indexOf('CAT') !== -1) {
      reportNameStandard = 'CATJournal';
    }
    let s = !everyClick;
    setEveryClick(s);
    async function Approval() {
      unstable_batchedUpdates(() => {
        dispatch(
          handleWaitingForApprovalApproveOrReject(
            value,
            reportNameStandard,
            selectMonth,
            status,
            everyClick
          )
        );
      });
    }
    Approval();
    setApprovalOpen(false);
    setRejectOpen(false);
  };

  useEffect(() => {
    if (ApprovalOrRejectResult === 'ApprovalOK') {
      dispatch(waitingApprovalMessageChange(true));
    } else if (RejectResult === 'RejectOK') {
      dispatch(waitingRejectMessageChange(true));
    }
    dispatch(resetWaitingForApprovalInfo());
  }, [EveryClick]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      ...tableRowCss,
      height: '27px', // 为了确保算上padding后是44px（figma）
    },
  }));

  const tableRowCss = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#262E35',
    alignItems: 'center',
    letterSpacing: '0.01em',
    padding: '8px 24px',
  };
  let selectedMonth = '',
    submittedTime = '';
  if (item.selectedMonth !== null && item.selectedMonth !== undefined) {
    selectedMonth =
      item.selectedMonth.slice(0, 4) + '-' + item.selectedMonth.slice(4);
  }
  if (item.submittedTime !== null && item.submittedTime !== undefined) {
    submittedTime = formatDatetimeString(item.submittedTime);
  }
  return (
    <TableRow
      id={`WaitingForApprovalTable`}
      data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForTableRow-TableRow'
    >
      <StyledTableCell
        id={`systemAdminReportJobsWaitingForApprovalReportName${index}`}
        data-selenium-id={`SystemAdmin-ReportJobsWaitingForApprovalReportName-${index}`}
        align='left'
        sx={{ ...tableRowCss, fontWeight: '600' }}
      >
        {item.reportName}
      </StyledTableCell>
      <StyledTableCell
        id={`systemAdminReportJobsWaitingForApprovalSelectedMonth${index}`}
        data-selenium-id={`SystemAdmin-ReportJobsWaitingForApprovalSelectedMonth-${index}`}
        align='left'
        sx={{ ...tableRowCss, color: '#747474' }}
      >
        {selectedMonth}
      </StyledTableCell>
      <StyledTableCell
        id={`systemAdminReportJobsWaitingForApprovalSubmittedBy${index}`}
        data-selenium-id={`SystemAdmin-ReportJobsWaitingForApprovalSubmittedBy-${index}`}
        align='left'
        sx={{ ...tableRowCss }}
      >
        {item.submittedBy}
      </StyledTableCell>
      <StyledTableCell
        id={`systemAdminReportJobsWaitingForApprovalSubmittedTime${index}`}
        data-selenium-id={`SystemAdmin-ReportJobsWaitingForApprovalSubmittedTime-${index}`}
        align='left'
        sx={{ ...tableRowCss }}
      >
        {submittedTime}
      </StyledTableCell>
      {item.sameUserFlag ? (
        <TableCell
          id={`systemAdminReportJobsWaitingForApprovalActions${index}`}
          data-selenium-id={`SystemAdmin-ReportJobsWaitingForApprovalActions-${index}`}
        ></TableCell>
      ) : (
        <StyledTableCell
          id={`systemAdminReportJobsWaitingForApprovalActions${index}`}
          data-selenium-id={`SystemAdmin-ReportJobsWaitingForApprovalActions-${index}`}
        >
          <IconButton
            id='ReportJobsWaitingForApprovalRejectButton'
            data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForTableRow-TableRow-StyledTableCell1-IconButton1'
            onClick={() => {
              setRejectOpen(true);
            }}
            sx={{
              padding: '0px 6px 0px 0px',
            }}
          >
            <DeleteIcon data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForTableRow-TableRow-StyledTableCell1-IconButton1-DeleteIcon' />
          </IconButton>
          <IconButton
            id='ReportJobsWaitingForApprovalApprovalButton'
            data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForTableRow-TableRow-StyledTableCell1-IconButton2-ApprovalButton'
            disableRipple
            onClick={() => {
              setApprovalOpen(true);
            }}
            sx={{
              padding: '0px 6px 0px 6px',
            }}
          >
            <TickIcon data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForTableRow-TableRow-StyledTableCell1-IconButton2-ApprovalButton-TickIcon' />
          </IconButton>
        </StyledTableCell>
      )}
      <ApprovalDialog
        id='ReportJobsWaitingForApprovalApprovalDialog'
        data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForTableRow-TableRow-ApprovalDialog'
        propOpen={ApprovalOpen}
        setOpenApproval={setApprovalOpen}
        reportName={item.reportName}
        selectMonth={item.selectedMonth}
        handleApprovalDialogPop={handleDialogPop}
      />
      <RejectDialog
        data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForTableRow-TableRow-RejectDialog'
        propOpen={RejectOpen}
        setRejectOpen={setRejectOpen}
        reportName={item.reportName}
        selectMonth={item.selectedMonth}
        handleRejectDialogPop={handleDialogPop}
      />
    </TableRow>
  );
}

export default MyTableRow;

import ChartGroup from './ChartGroup';
import { PropTypes } from 'prop-types';

export const ChartGroups = ({
  chartGroupTitles,
  onSelectChart,
  onSelectChartIndex,
  selectedChartIndex,
}) => {
  return (
    <>
      {chartGroupTitles &&
        chartGroupTitles.map(group => {
          const { groupTitle, levels } = group;

          return (
            <ChartGroup
              key={groupTitle}
              groupTitle={groupTitle}
              levels={levels}
              onSelectChart={onSelectChart}
              onSelectChartIndex={onSelectChartIndex}
              selectedChartIndex={selectedChartIndex}
            />
          );
        })}
    </>
  );
};

ChartGroups.propTypes = {
  chartGroupTitles: PropTypes.array.isRequired,
  onSelectChart: PropTypes.func,
  selectedChartIndex: PropTypes.number,
  onSelectChartIndex: PropTypes.func,
};

import { StrictMode, ReactElement, Component, Dispatch, SetStateAction } from 'react';
import PropTypes from 'prop-types';
import SIModalPro from '@/modules/Modal/SIModal';
import styleModule from './DeleteConfirmModal.module.scss';
import ErrorSvg from '@/assets/SoildError16.svg';
import { StateButton } from '@/componentsphase2/StateButton';
type RRN_<T> = Readonly<Required<NonNullable<T>>>;
type RRN_Boolean = RRN_<boolean>;
type RRN_String = RRN_<string>;
type RRN_Object = RRN_<object>;
type RRN_Component = new () => RRN_<Component>;
type RRN_ReactElementGenericity<T> = RRN_<ReactElement<T, RRN_Component>>;
type anyReactElementGenericity = RRN_ReactElementGenericity<RRN_Object>;
type DeleteConfirmModalProps = {
  readonly open?: RRN_Boolean;
  readonly handleClose?: RRN_<Dispatch<RRN_<SetStateAction<RRN_Boolean>>>>;
  readonly handleDelete?: RRN_<() => RRN_<void>>;
  readonly id?: RRN_String;
  readonly styleModuleClassName?: RRN_<{ readonly [key: RRN_String]: RRN_String; }>;
  readonly children?: anyReactElementGenericity;
};
type RRN_DeleteConfirmModalProps = RRN_<DeleteConfirmModalProps>;
type ReactElementGenericity = RRN_ReactElementGenericity<RRN_DeleteConfirmModalProps>;
export default function DeleteConfirmModal (props: Readonly<NonNullable<DeleteConfirmModalProps>>): ReactElementGenericity {
  const {
    id = 'ChinaMarketing_SIDetailPage-DeleteConfirmModal',
    open = false,
    handleClose = () => { },
    handleDelete = () => { },
    styleModuleClassName = styleModule,
    ...others
  } = props;

  return (
    <StrictMode>
      <SIModalPro
        open={open}
        id={id}
        data-selenium-id={id}
        className={styleModuleClassName['delete-confirm-modal']}
        {...others}
      >
        <div>
          <div className={styleModuleClassName['delete-confirm-modal__title']}>Delete this marketing activity?</div>
          <div
            className={styleModuleClassName['delete-confirm-modal__content']}
          >
            <ErrorSvg />
            <div>Please note that if all Marketing Activities are deleted, the China Marketing section will be removed from this page.</div></div>
          <div className={styleModuleClassName['delete-confirm-modal__footer']}>
            <StateButton
              titleName='Cancel'
              isPrimary={false}
              themeSize={true}
              onClick={handleClose} />
            <StateButton
              titleName='Delete'
              isPrimary={true}
              themeSize={true}
              onClick={handleDelete}
            />
          </div>
        </div>
      </SIModalPro>
    </StrictMode>
  );
}
DeleteConfirmModal.prototype = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  id: PropTypes.string,
  styleModuleClassName: PropTypes.object,
  children: PropTypes.any
};
DeleteConfirmModal.defaultProps = {
  open: false,
  handleClose: () => { },
  handleDelete: () => { },
  id: 'ChinaMarketing_SIDetailPage-DeleteConfirmModal',
  styleModuleClassName: styleModule
};
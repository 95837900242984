import React from 'react';
import HeaderAlertEmptyMessagePattern from '@/assets/HeaderAlertEmptyMessagePattern.svg';
import { Box, Link, Typography, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import {DeleteAlert, fetchAlerts, ReadAlert} from "@/actions/SIMT-User/Alert";

const HeaderEmptyMessage = ({isRead = null}) => {
  const dispatch = useDispatch();

  const handleRefresh = () => {
    dispatch(fetchAlerts(isRead));
  };

  return (
    <Box padding='0 42px 38px 42px'>
      <Stack alignItems='center' paddingBottom='24px' paddingTop='70px'>
        <HeaderAlertEmptyMessagePattern style={{ opacity: 0.3 }} />
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '600',
            marginTop: '50px',
            marginBottom: '4px',
          }}
        >
          No new alerts!
        </Typography>
        <Typography sx={{ fontSize: '12px', color: '#484848' }}>
          Your inbox is empty. Click{' '}
          {
            <Link
              sx={{ cursor: 'pointer', color: '#264ab7', fontWeight: 500 }}
              onClick={handleRefresh}
            >
              here
            </Link>
          }{' '}
          to refresh.
        </Typography>
      </Stack>
    </Box>
  );
};

export { HeaderEmptyMessage };

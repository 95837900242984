import {
    Box,
    Button,
    Stack,
} from '@mui/material';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { MyMonthPicker } from './MyMonthPicker';
import { MonthPicker } from '@/components/DateRangePicker/MonthPicker';
import {
    monthlyExportData,
    setMonthlyDate,
} from '@/actions/SIMT-SI/Export/ExportDataAction';
import { UntitledAlertBox } from '@/components/Alert/AlertBox';
import {monthlyExportSize} from "@/actions/SIMT-SI/Export";


export default function Monthly() {
    const {
        monthlySelectDate,
        monthlySelectSize,
    } = useSelector(
        state => {
            return {
                monthlySelectDate: state.MarketExport.monthlySelectDate,
                monthlySelectSize: state.MarketExport.monthlySelectSize,
            };
        }
    );
    const dispatch = useDispatch();
    const currentDate = new Date();
    const [selectMonth, setSelectMonth] = useState(new Date());
    const [open, setOpen] = useState(false);
    const handleDateChange = value => {
        setSelectMonth(value);
        var year = value.getFullYear();
        var month = value.getMonth() + 1;
        let date = year + '-' + month;
        dispatch(setMonthlyDate(date));
    };
    useEffect(() => {
        if (monthlySelectDate != null) {
            var Year = monthlySelectDate.split('-')[0];
            var Month = monthlySelectDate.split('-')[1] - 1;
            setSelectMonth(new Date(Year, Month));
        }
    }, [monthlySelectDate]);
    useEffect(() => {
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1;
        let newDate = '' + year + '-' + month;
        if (monthlySelectDate === null) {
            dispatch(setMonthlyDate(newDate));
        }
    }, [dispatch]);
    function exportExcel() {
        let filename = `Export_Marketing_Data_Monthly_Campaign_${moment()
            .utc()
            .format('MMDDYYYY_HHmmss')}.xlsx`;
        let selectDate =
            Number(monthlySelectDate.split('-')[0]) * 100 +
            Number(monthlySelectDate.split('-')[1]);
        if (monthlySelectSize != null) {
            //条目为1000的文件数量
            let nk = Math.floor(monthlySelectSize / 1000);
            for (let i = 0; i < nk; i++) {
                dispatch(
                    monthlyExportData(
                        selectDate,
                        i * 1000,
                        1000,
                        '/api/v1/si-service/special-issues/marketing-data/export',
                        filename,
                        'monthly'
                    )
                );
            }
            if (monthlySelectSize % 1000 != 0) {
                dispatch(
                    monthlyExportData(
                        selectDate,
                        Math.floor(monthlySelectSize / 1000) * 1000,
                        monthlySelectSize % 1000,
                        '/api/v1/si-service/special-issues/marketing-data/export',
                        filename,
                        'monthly'
                    )
                );
            }
            else setOpen(true);
        }
    };

    /*var selectDate =
            Number(monthlySelectDate.split('-')[0]) * 100 +
            Number(monthlySelectDate.split('-')[1]);
*/

    useEffect(() => {
        if (monthlySelectDate != null) {
            let selectDate =
                Number(monthlySelectDate.split('-')[0]) * 100 +
                Number(monthlySelectDate.split('-')[1]);
            dispatch(
                monthlyExportSize(selectDate)
            );
        }
        else {
            let year = currentDate.getFullYear();
            let month = currentDate.getMonth() + 1;
            let newDate = '' + year + '-' + month;
            let selectDate =
                Number(newDate.split('-')[0]) * 100 +
                Number(newDate.split('-')[1]);
            dispatch(
                monthlyExportSize(selectDate)
            );
        }
    }, [, monthlySelectDate]);

    return (
        <Box
            sx={{
                width: '753px',
                height: '452px',
                background: '#FFFFFF',
            }}
        >
            <UntitledAlertBox
                open={open}
                handleClose={() => setOpen(false)}
                message={'No eligible SI to export'}
                severity={'warning'}
            />
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing='12px'
            >
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing='16px'
                >
                    <Box
                        sx={{
                            fontFamily: 'Open Sans',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '22px',
                            letterSpacing: '0.01em',
                            color: '#262E35',
                        }}
                    >
                        Select data
                    </Box>
                    <Box
                        sx={{
                            fontFamily: 'Open Sans',
                            fontSize: '12px',
                            fontWeight: 600,
                            lineHeight: '16px',
                            letterSpacing: '0em',
                            color: '#596A7C',
                        }}
                    >
                        Month:
                    </Box>
                </Stack>
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{
                        height: '386px',
                    }}
                >
                    <MyMonthPicker
                        maxDate={new Date()}
                        width='753px'
                        handleDateChange={handleDateChange}
                        value={selectMonth}
                    />
                    <Stack
                        direction='column'
                        alignItems='center'
                        spacing='4px'
                        sx={{width:'753px'}}
                    >
                        <Box
                            sx={{
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '22px',
                                letterSpacing: '0em',
                                color: '#98A7B6',
                            }}
                            className={!monthlySelectSize && monthlySelectSize !== 0 ? 'text-animation-loading' : ''}
                        >
                            {!monthlySelectSize && monthlySelectSize !== 0 ? '' : `${monthlySelectSize} data are selected`}
                        </Box>
                        <Button
                            disableRipple
                            onClick={exportExcel}
                            sx={{
                                width: '617px',
                                height: '50px',
                                padding: '14px 20px 14px 20px',
                                borderRadius: '5px',
                                background: '#0052CC',
                                color: '#FFFFFF',
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontWeight: 600,
                                lineHeight: '22px',
                                letterSpacing: '0em',
                                textTransform: 'none',
                                '&:hover': {
                                    background: '#6D98EE',
                                },
                                '&:active': {
                                    background: '#113D95',
                                },
                            }}
                        >
                            Export
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}

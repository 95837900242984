import { useMemo } from 'react';
import { SI_STAGES } from '../../constants.js';

/**
 *  Purpose: to get bar and pie chart data
 */

// filter only the selected records
export default function useGetChartDataHook(
  unfilteredDataForBar,
  selectedCatagories,
  catagoryAttributeName
) {
  const barData = useMemo(() => {
    // console.log('===unfilteredDataForBar===', unfilteredDataForBar);
    if (unfilteredDataForBar) {
      return filterSelectedData(
        unfilteredDataForBar,
        selectedCatagories,
        catagoryAttributeName
      );
    }
    return [];
  }, [unfilteredDataForBar, selectedCatagories, catagoryAttributeName]);

  // data for piechart
  const pieData = useMemo(() => {
    return getDataForPieChart(barData);
  }, [barData]);

  // console.log('barData', barData);

  // console.log('pieData', pieData);

  return [barData, pieData];
}

function filterSelectedData(
  catagories,
  selectedCatagories,
  catagoryAttributeName
) {
  catagories = catagories.filter(item => {
    return selectedCatagories.indexOf(item[catagoryAttributeName]) >= 0;
  });
  return catagories;
}

function getDataForPieChart(selectedData) {
  if (Array.isArray(selectedData)) {
    let stageSum = {}; // {stage: sumOfStage}
    let allStagesSum = 0;

    SI_STAGES.forEach(stage => {
      // calculate the sum for every stage
      const sumOfStage = selectedData.reduce((sum, record) => {
        return sum + record[stage];
      }, 0);

      // insert into the sumOjbect key and sumOfStage
      stageSum[stage] = sumOfStage;

      // calculate the sum of all stages
      allStagesSum += sumOfStage;
    });

      const formattedData = SI_STAGES
          .map(stage => {
              const value = stageSum[stage];
              const percentage = getPercentage(value, allStagesSum);
              return [stage, percentage, value];
          });
    // console.log("formattedData========================", formattedData)
    return formattedData;
  }
  return [];
}

const getPercentage = (value, sum) => {
  return ((value * 100) / sum).toFixed(2) + '%';
};

//注意 这个顺序是确定的，改之前先了解清楚代码逻辑
export const fieldList = [
  'handlingCe',
  'leadGeFullName',
  'journal',
  'siTitle',
  'siCode',
  'subjectGroup',
  'pdPublisher',
  'coGeFullName',
];

export function getLabelByField(field) {
  switch (field) {
    case 'handlingCe':
      return 'Handling CE';
    case 'leadGeFullName':
      return 'Lead GE';
    case 'journal':
      return 'Journal Name/Code';
    case 'siTitle':
      return 'SI Title';
    case 'siCode':
      return 'SI Code';
    case 'subjectGroup':
      return 'Subject Group';
    case 'pdPublisher':
      return 'PD Publisher';
    case 'coGeFullName':
      return 'CO-GE';
    default:
      return '未知字段';
  }
}

export function getFieldByLabel(label) {
  switch (label) {
    case 'Handling CE':
      return 'handlingCe';
    case 'Lead GE':
      return 'leadGeFullName';
    case 'Journal Name/Code':
      return 'journal';
    case 'SI Title':
      return 'siTitle';
    case 'SI Code':
      return 'siCode';
    case 'Subject Group':
      return 'subjectGroup';
    case 'PD Publisher':
      return 'pdPublisher';
    case 'CO-GE':
      return 'coGeFullName';
    default:
      return '未知字段';
  }
}

export function getThemeByField(field) {
  switch (field) {
    case 'handlingCe':
      return 'purple';
    case 'leadGeFullName':
      return 'green';
    case 'journal':
      return 'orange';
    default:
      return 'default';
  }
}

export const getThemeColor = theme => {
  switch (theme) {
    case 'purple':
      return {
        first: '#4B56BB',
        second: '#C1C6EF',
        third: '#7882DC',
      };
    case 'green':
      return {
        first: '#0D6A61',
        second: '#84D4CA',
        third: '#48A79A',
      };
    case 'orange':
      return {
        first: '#D97706',
        second: '#FFD495',
        third: '#FF8A00',
      };
    default:
      return {};
  }
};

export function calcDropListHeight(len,height,gap,other){
  let num = len < 6 ? len : 6;
  return (height + gap) * (num -1) + height + other;
};

export function getPlaceHolderByLabel(label){
  switch(label){
    case 'Journal Name/Code':
      return 'Refine by Journal Name or Journal Code';
    case 'Lead GE':
      return 'Refine by GE Name';
    default:
      return `Refine by ${label}`;
  }
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Autocomplete,
  Box,
  styled,
  TextField,
  ThemeProvider,
} from '@mui/material';
import DecisionChoiceDown from '../../../../assets/DecisionChoiceDown.svg';
import { Bootstraptheme } from '../../../../components/CreateAndUpdate/MyTheme';
import { defaultVars, ErrVars } from './DecisionMyStyled';
import { SmallFont } from '@/components/FontStyle';
import {getCE, getSIPCE} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueSystemAdmin";

export const HandleCEAutoComplete = styled(Autocomplete)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2),
  },
  '& .MuiOutlinedInput-root': {
    width: '286px',
    height: '42px',
    background: '#FFFFFF',
    borderRadius: 4,
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: '8px 32px 8px 12px !important',
  },
  '& .css-55ib48-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment':
    {
      right: '12px',
    },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '0px 0px',
    height: '17px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    // lineHeight: '17px',
    color: '#262E35',
    '&::placeholder': {
      color: '#828282',
      opacity: '1',
      lineHeight: '17px',
    },
  },
  '& :hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'var(--border) !important',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'var(--border)',
  },
  '& :focus': {
    '& .MuiOutlinedInput-notchedOutline': {
      // border: '2px solid #154AB6',
      border: 'var(--focus-border)',
    },
  },
  '& .MuiAutocomplete-popupIndicator': {
    padding: '0',
    margin: '0',
    marginRight: '2px',
  },
  '& .css-1q60rmi-MuiAutocomplete-endAdornment': {
    top: 'calc(50% - 12px)',
  },
}));

export const HandleAutoComplete400px = styled(Autocomplete)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2),
  },
  '& .MuiOutlinedInput-root': {
    width: '400px',
    height: '40px',
    background: '#FFFFFF',
    borderRadius: 4,
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: '8px 32px 8px 12px !important',
  },
  '& .css-55ib48-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment':
    {
      right: '12px',
    },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '0px 0px',
    height: '17px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    // lineHeight: '17px',
    color: '#262E35',
    '&::placeholder': {
      color: '#828282',
      opacity: '1',
      lineHeight: '20px',
    },
  },
  '& :hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'var(--border) !important',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'var(--border)',
  },
  '& :focus': {
    '& .MuiOutlinedInput-notchedOutline': {
      // border: '2px solid #154AB6',
      border: 'var(--focus-border)',
    },
  },
  '& .MuiAutocomplete-popupIndicator': {
    padding: '0',
    margin: '0',
    marginRight: '2px',
  },
  '& .css-1q60rmi-MuiAutocomplete-endAdornment': {
    top: 'calc(50% - 12px)',
  },
}));

export function HandlingCEAutoCompleteWithoutTitle(props) {
  const {
    handingCeNameAndEmail,
    setEmptyError,
    emptyError,
    setHandingCEId,
    setHandingCeNameAndEmail,
    useStandardSize = false,
  } = props;
  const dispatch = useDispatch();
  const [vars, setVars] = useState(defaultVars);

  // 获得必要数据
  const { CEData } = useSelector(state => {
    return {
      CEData: state.SI.CE,
    };
  });

  const [useCEData, setUseCEData] = useState([]); // 真正展示在屏幕上的data

  const [inputContent, setInputContent] = useState(''); // 输入的内容

  const [loading, setLoading] = useState(false); // loading未来会用到，暂时不删掉

  // 以下两个状态用于辅助延迟发送请求
  const [timer, setTimer] = useState(null);

  const [loadTrigger, setLoadTrigger] = useState(0);

  // 搜索逻辑，返回后会更新CEData
  const getCEFromSearch = async searchContent => {
    await dispatch(getSIPCE(searchContent));
  };

  // 输入内容变化时设置timer，倒计时0.3秒后发送请求
  // 如果期间输入内容在此变化，之前的timer计划会作废
  useEffect(() => {
    setLoading(true);
    clearTimeout(timer);
    setTimer(
      setTimeout(async () => {
        setLoadTrigger(loadTrigger + 1); // 通过这个触发加载
      }, 300)
    );
  }, [inputContent]);

  // 获取用户input的值
  const handleSetInputHandlingCE = e => {
    if (e) {
      setInputContent(e.target.value);
      setHandingCeNameAndEmail && setHandingCeNameAndEmail(e.target.value);
      if (e.target.value === '') {
        setHandingCEId('');
      }

    }
  };

  // 这里增加一个由loadTrigger触发的useEffect
  // 是为了模仿SelectBox的实现（因为当前不清楚去掉这一层是否会正常）
  useEffect(() => {
    if (loadTrigger === 0) {
      return;
    }

    async function load() {
      await getCEFromSearch(inputContent);
    }

    load().then(_r => {
      setLoading(false);
    });
  }, [loadTrigger]);

  const handleCeSelect = value => {

    setHandingCeNameAndEmail && setHandingCeNameAndEmail(value);
    //将新获得的值传入 mainpage
    if (value) {
      let tmpEmail = value.split('(')[1];
      tmpEmail = tmpEmail.split(')')[0]; // 取出email
      let tmpId = -1;

      for (const element of CEData) {
        if (element.email === tmpEmail) {
          tmpId = element.userId;
          break;
        }
      }
      if (tmpId > 0) {
        setHandingCEId(tmpId);
      }
    }
  };

  // CE Data变化时，为了防止error等问题，
  // 最终展现给用户的是本页面中的state，而非直接使用redux的
  useEffect(() => {
    // CEData有时候的值很奇怪，所以
    if (CEData && CEData !== 'error' && CEData !== 'null') {
      // 好像还有可能是null字符串，也要避免
      setUseCEData(CEData); // 正常，设置为返回的值
    } else {
      setUseCEData([]); // 出错，设置为空值
    }
  }, [CEData]);

  useEffect(() => {
    if (emptyError) {
      setVars(ErrVars);
    } else {
      setVars(defaultVars);
    }
  }, [emptyError]);

  return (
    <ThemeProvider
      theme={Bootstraptheme}
      data-selenium-id='SIP_DetailPage_DecisionSection-HandlingCEAutoCompleteWithoutTitle-ThemeProvider'
    >
      {useStandardSize?
        <HandleAutoComplete400px
      
          id='HandingCEAC'
          data-selenium-id='SIP_DetailPage_DecisionSection-HandlingCEAutoCompleteWithoutTitle-CEAC'
          style={vars}
          defaultValue={handingCeNameAndEmail}
          options={useCEData.map(
            option => option.username + ' (' + option.email + ')'
          )}
          renderOption={(props, option) => (
            <li style={{ ...SmallFont, color: '#596A7C' }} {...props}>
              {option}
            </li>
          )}
          ListboxProps={{
            style: { maxHeight: 'calc(6*33px)' ,gap:6, mt:6},
          }}
          renderInput={params => (
            <TextField
              {...params}
              placeholder='Select a HandlingCE'
              data-selenium-id='SIP_DetailPage_DecisionSection-HandlingCEAutoCompleteWithoutTitle-ParmsTextField'
            />
          )}
          popupIcon={
            <Box sx={{ width: '17px', height: '17px', lineHeight: '17px' }}>
              <DecisionChoiceDown
                disableRipple
                sx={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '2px',
                }}
                data-selenium-id='SIP_DetailPage_DecisionSection-HandlingCEAutoCompleteWithoutTitle-Box-ChoiceDown'></DecisionChoiceDown>
            </Box>
          }
          onInputChange={handleSetInputHandlingCE}
          onChange={(e, value) => {
            handleCeSelect(value);
            if (value) {
              setEmptyError && setEmptyError(false);
            } else {
              setEmptyError && setEmptyError(true);
            }
            setHandingCeNameAndEmail && setHandingCeNameAndEmail(value);
          }}
          loading={CEData.length === 0 && inputContent === '' ? true : false}
          clearIcon={null}
        />
    
    
        :
        <HandleCEAutoComplete
          id='HandingCEAC'
          data-selenium-id='SIP_DetailPage_DecisionSection-HandlingCEAutoCompleteWithoutTitle-CEAC'
          style={vars}
          defaultValue={handingCeNameAndEmail}
          options={useCEData.map(
            option => option.username + ' (' + option.email + ')'
          )}
          renderOption={(props, option) => (
            <li style={{ ...SmallFont, color: '#596A7C' }} {...props}>
              {option}
            </li>
          )}
          renderInput={params => (
            <TextField
              {...params}
              placeholder='Select a HandlingCE'
              data-selenium-id='SIP_DetailPage_DecisionSection-HandlingCEAutoCompleteWithoutTitle-ParmsTextField'
            />
          )}
          popupIcon={
            <Box sx={{ width: '17px', height: '17px', lineHeight: '17px' }}>
              <DecisionChoiceDown data-selenium-id='SIP_DetailPage_DecisionSection-HandlingCEAutoCompleteWithoutTitle-Box-ChoiceDown'></DecisionChoiceDown>
            </Box>
          }
          onInputChange={handleSetInputHandlingCE}
          onChange={(e, value) => {
            handleCeSelect(value);
            if (value) {
              setEmptyError && setEmptyError(false);
            } else {
              setEmptyError && setEmptyError(true);
            }
            setHandingCeNameAndEmail && setHandingCeNameAndEmail(value);
          }}
          loading={CEData.length === 0 && inputContent === '' ? true : false}
          clearIcon={null}
        />

      }
    </ThemeProvider>
  );
}

import SIModal from '@/modules/Modal/Deprecation/SIModal';
import BatchUpdateContainer from './BatchUpdateContainer';
import {
  selectBatchCRC,
  unselectBatchCRC,
  unselectBatchCRCAllInfo,
} from '../../../../actions/SIMT-SI/CRContact/CRContactAction';
import { useDispatch, useSelector } from 'react-redux';
function BatchUpdatePage(props) {
  const { open, handleCloce, type, searchBy } = props;

  const selector = state => {
    return {
      selectedCRC: state.CRContact.selectedCRC,
    };
  };

  const { selectedCRC } = useSelector(selector);
  const dispatch = useDispatch();

  const handleUnselectClose = () => {
    const CRCIdList = selectedCRC;
    // console.log("CRC",CRCIdList)
    for (const tmpJournal of CRCIdList) {
      // console.log("CRC",tmpJournal)
      dispatch(unselectBatchCRC(tmpJournal));
    }
    dispatch(unselectBatchCRCAllInfo());
    handleCloce();
  };

  const container = (
    <BatchUpdateContainer
      type={type}
      handleCloce={handleUnselectClose}
      searchBy={searchBy}
      open={open}
      data-selenium-id='CRContact_Overview-BatchUpdate-Page-Container'
    ></BatchUpdateContainer>
  );

  return (
    <div>
      <SIModal
        title='Batch Update Journals'
        open={open}
        handleClose={handleUnselectClose}
        container={container}
        showButton={false}
        data-selenium-id='CRContact_Overview-BatchUpdate-Page-SIModal'
      ></SIModal>
    </div>
  );
}
export default BatchUpdatePage;

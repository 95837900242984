import * as actions from '../actions/SIMT-SI/Export/ExportDataAction';

const initState = {
    monthlySelectDate: null,
    monthlySelectSize: null,
    submissionSelectSize: null,
    publishedSelectSize: null,
    submissionDate: {
        openForSubmissionDateStart: [null],
        openForSubmissionDateEnd: [null],
        submissionDeadlineStart: [null],
        submissionDeadlineEnd: [null],
    },
    systemDataSize: null,
};

export default function MarkerExportReducer(state = initState, action) {
    const { type, data } = action;
    switch (type) {
        case actions.SET_MONTHLY_DATE:
            return {
                ...state,
                monthlySelectDate: data,
            };
        case actions.GET_MONTHLY_SIZE:
            return {
                ...state,
                monthlySelectSize: data,
            };
        case actions.GET_SUBMISSION_SIZE:
            return {
                ...state,
                submissionSelectSize: data,
            };
        case actions.GET_PUBLISHED_SIZE:
            return {
                ...state,
                publishedSelectSize: data,
            };
        case actions.GET_SYSTEMDATA_SIZE:
            return {
                ...state,
                systemDataSize:data,
            };
        default:
            return state;
    }
};
import React from 'react';
import {
  Table,
  TableContainer,
  TableRow,
  TableBody,
  Box,
  tableCellClasses,
  Paper,
  TableCell,
  Tooltip,
  TextField,
  Stack,
  IconButton,
} from '@mui/material';
import { MyTableHeader } from '@/components/DataTable';
import { styled } from '@mui/system';
import { formatDatetimeString } from '@/utils/commonUtils';
import { useEffect } from 'react';
import SIPAuthorEdit from '@/assets/SIPAuthorEdit.svg';
import ProfileWebsite from '@/assets/ProfileWebsite.svg';
import { useState } from 'react';
import AuthorEditCancel from '@/assets/AuthorEditCancel.svg';
import AuthorEditSave from '@/assets/AuthorEditSave.svg';
import { SpaRounded } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E8F2',
    color: '#505050',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '0.01em',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '44px',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '24px',
  },
}));

export const OpaqueInput = styled(props => (
  <TextField
    {...props}
    data-selenium-id='SIP_AcceptPage_Submodule-GETable-PropsTextField'
  />
))({
  '&.MuiTextField-root': {
    display: 'block',
  },
  '.MuiInputBase-root': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    letterSpacing: '0.01em',
    color: '#262E35',
  },
  '.MuiInputBase-input': {
    padding: '0px 0px 0px 0px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
});

const GETableRow = props => {
  const { geId, row, TableHeads, handleInfoChange } = props;
  const [keywords, setKeywords] = useState(row.researchKeywords.join(','));
  const [isEditing, setIsEditing] = useState(false);

  const [value, setValue] = useState(row.researchKeywords.join(','));

  const handleInputChange = e => {
    setValue(e.target.value);
    handleInfoChange(geId, transformKeywords(e.target.value));
  };

  const transformKeywords = value => {
    if (value === '') {
      return [];
    }
    let ks = value.split(',').map(element => {
      return element.trim();
    });
    return ks;
  };

  const getTableCell = id => {
    if (id === 'researchKeywords') {
      return (
        <OpaqueInput
          data-selenium-id='SIP_AcceptPage_Submodule-GETable-OpaqueInput'
          placeholder='Please use comma to seperate the keywords!'
          value={value}
          onChange={handleInputChange}
        />
      );
    }
    return row[id];
  };

  return (
    <TableRow hover data-selenium-id='SIP_AcceptPage_Submodule-GETable-Row'>
      {TableHeads.map((item, index) => (
        <StyledTableCell
          data-selenium-id='SIP_AcceptPage_Submodule-GETable-StyledCell'
          sx={{
            minWidth: item.minWidth,
            maxWidth: item.maxWidth,
            wordWrap: 'break-word',
            color: '#262E35',
            paddingX: '24px',
          }}
          key={index}
          align={item.align}
        >
          {getTableCell(item.id)}
        </StyledTableCell>
      ))}
    </TableRow>
  );
};

function GETable(props) {
  //从父组件获取的属性
  const { id, TableHeads, TableData, handleInfoChange, setError } = props;

  return (
    <Box
      sx={{ width: '100%' }}
      data-selenium-id='SIP_AcceptPage_Submodule-GETable-Box'
    >
      <TableContainer
        id={id}
        data-selenium-id={`SIP_AcceptPage_Submodule-GETable-Container-${id}`}
        sx={{
          borderRadius: '8px 8px 8px 8px',
          borderWidth: '1px 1px 0px 1px',
          borderStyle: 'solid',
          borderColor: '#DFE4E8',
        }}
        component={Paper}
      >
        <Table
          data-selenium-id='SIP_AcceptPage_Submodule-GE-Table'
          stickyHeader
          aria-label='custom pagination table'
          sx={{
            minWidth: 500,
            letterSpacing: '0.01em',
            color: '#262E35',
          }}
        >
          <MyTableHeader
            data-selenium-id='SIP_AcceptPage_Submodule-GETable-MyHeader'
            items={TableHeads}
            backgroundColor='#F1F3F5'
            color='#596A7C'
            CheckboxColor='wileyBlue2'
          />
          <TableBody data-selenium-id='SIP_AcceptPage_Submodule-GETable-Body'>
            {TableData.map((row, index) => (
              <GETableRow
                data-selenium-id='SIP_AcceptPage_Submodule-GETable-Row'
                row={row}
                key={index}
                TableHeads={TableHeads}
                handleInfoChange={handleInfoChange}
                geId={row['id']}
              />
            ))}

            {TableData.length <= 0 ? (
              <TableRow
                id='siDetailPageNoDataText'
                data-selenium-id='SIP_AcceptPage_Submodule-GETable-SiDetailNoDataText'
                style={{ height: '300px' }}
              >
                <TableCell
                  data-selenium-id='SIP_AcceptPage_Submodule-GETable-Box-Cell'
                  colSpan={6}
                  sx={{ textAlign: 'center', fontSize: '20px' }}
                >
                  No Data
                </TableCell>
              </TableRow>
            ) : (
              <div></div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
export default GETable;

import Box from '@mui/material/Box';
import { SectionItem } from '../../types';
import styles from './index.module.scss';
import {Typography} from "@mui/material";

export interface CardProps {   
    section: SectionItem<any>;
    withStar?: boolean;
    children?: React.ReactNode | React.ReactNode[];
}

function Card(props: CardProps) {
    return (
        <Box
            id={`sip-form-section-${props.section.sectionIndex}`}
            className={styles['card'] + ' ' +
            (props.section.state === 'completed' ? styles['completed'] : '') + ' ' +
            (props.section.state === 'error' ? styles['error'] : '')
        }>
            <Box className={styles['card-header']}>
                <span className={styles['card-title']}>
                    {props.section.title} {props.withStar && <Typography className={styles['section-star']}>&nbsp;*</Typography>}
                </span>
                <span className={styles['card-index']}>

                    Section {props.section.sectionIndex}
                </span>
            </Box>
            <Box className={styles['card-content']}>
                {props.children}
            </Box>
        </Box>
    );
}

export const jumpToSection = (section: SectionItem<any>) => {
    // jump to section
    const sectionElement = document.getElementById(`sip-form-section-${section.sectionIndex}`);
    if (sectionElement) {
        let topPosition = sectionElement.getBoundingClientRect().top + window.pageYOffset - 90;
        window.scrollTo({
            top: topPosition,
            behavior: 'smooth'
        });
    }
};

export default Card;

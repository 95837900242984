import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/system';
import { CustomTooltip } from '../components/CustomTooltip';
import { Box } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const CustomTooltipSmallArrow = styled(CustomTooltip)({
  '& .MuiTooltip-arrow': {
    width: '24px', // 修改箭头的宽度
    height: '18px', // 修改箭头的高度
  },
});
export const TooltipMoveArrow = styled(CustomTooltipSmallArrow)({
  '& .MuiTooltip-arrow': {
    width: '12px',
    height: '8px',
    top: '100% !important',
    left: '40% !important',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    marginBottom: '4px !important',
  },
});

export const SimpleTooltip = props => {
  const { id = 'default-SimpleTooltip-id', title, children, ...others } = props;
  return (
    <TooltipMoveArrow
      placement='top'
      data-selenium-id={id}
      title={title}
      disableInteractive
      {...others}
    >
      <div
        id={`${id}-innerDiv`}
        data-selenium-id={`${id}-innerDiv`}
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          display: 'inline',
        }}
      >
        {children}
      </div>
    </TooltipMoveArrow>
  );
};

export const SimpleTooltipChoice = props => {
  const { id, ifTooltip = true, title, children, ...others } = props;
  return (
    <Box data-selenium-id={id}>
      {ifTooltip ? (
        <TooltipMoveArrow
          id={`${id}-Tooltip`}
          placement='top'
          data-selenium-id={`${id}-Tooltip`}
          title={title}
          disableInteractive
          {...others}
        >
          {children}
        </TooltipMoveArrow>
      ) : (
        children
      )}
    </Box>
  );
};

export const TooltipIfShow = props => {
  const {
    id,
    outerRef,
    canOpen = true,
    title,
    children,
    onMouseEnter,
    ...others
  } = props;
  const childRef = useRef(null);
  const [show, setShow] = useState(false);
  let foundFirst = false;

  const handleHover = e => {
    if (outerRef && childRef) {
      let isIn = true;
      const outerBox = outerRef.current.getBoundingClientRect();
      const innerBox = childRef.current.getBoundingClientRect();
      if (outerBox.left > innerBox.left) isIn = false;
      if (outerBox.right < innerBox.right) isIn = false;
      if (outerBox.top > innerBox.top) isIn = false;
      if (outerBox.bottom < innerBox.bottom) isIn = false;
      setShow(isIn);
    }
    onMouseEnter && onMouseEnter(e);
    // console.info(show && canOpen);
  };

  return (
    <Box data-selenium-id={id} onMouseEnter={handleHover}>
      <TooltipMoveArrow
        id={`${id}-Tooltip`}
        title={title}
        placement='top'
        disableFocusListener={!(show && canOpen)}
        disableHoverListener={!(show && canOpen)}
        disableInteractive={!(show && canOpen)}
        {...others}
      >
        {
          React.Children.map(children, child => {
            if (!foundFirst && React.isValidElement(child)) {
              foundFirst = true;
              return React.cloneElement(child, {
                ref: childRef,
              });
            }
            return child;
          })?.[0]
        }
      </TooltipMoveArrow>
    </Box>
  );
};

export const MulLineLabel = React.forwardRef(({ label, id, line }, ref) => {
  return (
    <Box ref={ref} data-selenium-id={id} sx={{ whiteSpace: 'pre-line' }}>
      <div
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: line,
        }}
      >
        {label}
      </div>
    </Box>
  );
});

export const MulLineTooltipIfShow = props => {
  const {
    id,
    outerRef,
    alwaysShow = false,
    line = 2,
    label,
    labelSx = {},
    children,
    ...others
  } = props;
  const [show, setShow] = useState(false);

  const handleMouseEnter = () => {
    const parentElement = document.querySelector(
      `[data-selenium-id="${id}-Label"]`
    );
    const divElement = parentElement.querySelector('div');
    if (divElement) {
      let isIn = true;
      if (
        divElement.scrollTop <
        divElement.scrollHeight - divElement.offsetHeight
      )
        isIn = false;
      if (
        divElement.scrollLeft <
        divElement.scrollWidth - divElement.offsetWidth
      )
        isIn = false;
      setShow(!isIn);
    }
  };

  return (
    <TooltipIfShow
      id={id}
      outerRef={outerRef}
      title={label}
      onMouseEnter={handleMouseEnter}
      canOpen={alwaysShow || show}
      {...others}
    >
      <Box data-selenium-id={`${id}-Label`} sx={{ whiteSpace: 'pre-line' }}>
        <div style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: line,
            wordBreak: 'break-all',
            ...labelSx,
          }}>
            {children ?? label}
        </div>
      </Box>
      {/*<MulLineLabel label={label} id={`${id}-Label`} line={line}/>*/}
    </TooltipIfShow>
  );
};

export function TextLabel(props) {
  const { id, text, ifTooltip = true, TooltipText, ...others } = props;
  return (
    <SimpleTooltipChoice
      id={id}
      ifTooltip={ifTooltip}
      title={TooltipText || text}
      {...others}
    >
      <Box
        data-selenium-id={`${id}-Text`}
        component='div'
        sx={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {text}
      </Box>
    </SimpleTooltipChoice>
  );
}

export default TooltipMoveArrow;

import * as actions from '@/actions/SIMT-SIP/SIPForm';
import {
    Author,
    Country,
    FormData,
    GE,
    GuestEditorInformation,
    Journal,
    SectionState,
    User
} from '@/pages/SIPForm/types.d';
import {SET_SIP_FORM_DATA, SET_SIP_FORM_PROCEED_STATE} from "@/actions/SIMT-SIP/SIPForm";

export interface SIPFormState {
    formData: FormData;
    options: {
        subjectArea: string[];
        journal: Journal[];
        jobTitle: string[];
        country: Country[];
        [key: string]: any;
    };
    currentUser: User | null;
    state: "initial" | "proceeding";
    hasError: boolean;
    errorMessage: string | null;
}

const initialGuestEditor: GE = {
    country: null,
    email: "",
    firstName: "",
    institution: "",
    isLead: null,
    jobTitle: "",
    lastName: "",
    orcidID: "",
    website: ""
};

const initialAuthor: Author = {
    affilication: "",
    email: "",
    name: "",
    topic: ""
};

const initialFormData: FormData = {
    specialIssueInformation: {
        state: SectionState.default,
        sectionIndex: 1,
        title: 'Special Issue Information',
        data: {
            subject: '',
            journals: [],
            proposedTitle: '',
            aimsAndScope: '',
            topics: ['','',''],
        },
    },
    proposedDates: {
        state: SectionState.default,
        sectionIndex: 2,
        title: 'Proposed Dates',
        data: {
            fullManuscriptSubmissionDeadline: '',
            acceptanceDeadline: '',
            expectedPublicationDate: '',
        },
    },
    guestEditorInformation: {
        state: SectionState.default,
        sectionIndex: 3,
        title: 'Guest Editor Information',
        data: {
            GEList: [
                Object.assign({}, initialGuestEditor),
            ],
        },
    },
    potentialAuthors: {
        state: SectionState.default,
        sectionIndex: 4,
        title: 'Potential Authors',
        data: {
            potentialAuthors: [
                Object.assign({}, initialAuthor),
            ],
        },
    },
    conflictOfInterest: {
        state: SectionState.default,
        sectionIndex: 5,
        title: 'Conflict of Interest Declaration',
        data: {
            conflictOfInterest: null,
        },
    },
    statements: {
        state: SectionState.default,
        sectionIndex: 6,
        title: 'Statements',
        data: {
            consent1: false,
            consent2: true,
            consent3: true,
        },
    },
};

const initState: SIPFormState = {
    formData: initialFormData,
    options: {
        subjectArea: [],
        journal: [],
        jobTitle: [],
        country: [],
    },
    currentUser: null,
    state: "initial",
    hasError: false,
    errorMessage: null,
};

export const SIPFormReducer = (state = initState, action: {
    type: string;
    data: any;
}) => {
    const { type, data } = action;
    switch (type) {
        case actions.LOAD_CURRENT_USER:
            return {
                ...state,
                currentUser: data,
            };
        case actions.SET_SIP_FORM_VALUE:
        {
            // @ts-ignore
            const preSection = state.formData[data.section];
            const preData = preSection?.data;
            const newData = {
                ...preData,
                [data.key]: data.value,
            };
            return {
                ...state,
                formData: {
                    ...state.formData,
                    [data.section]: {
                        ...preSection,
                        data: newData
                    },
                }
            };
        }
        case actions.SET_SIP_FORM_GELIST_VALUE:
        {
            // @ts-ignore
            const preSection = state.formData[data.section];
            const preData = preSection?.data;
            const preItems = preData.GEList[data.index];
            const newItems = {
                ...preItems,
                [data.key]: data.value,
            };
            let newGEList = [...preData.GEList];
            newGEList[data.index] = newItems;
            return {
                ...state,
                formData: {
                    ...state.formData,
                    [data.section]: {
                        ...preSection,
                        data: {
                            ...preData,
                            GEList: newGEList,
                        }
                    }
                }
            };
        }
        case actions.SET_SIP_FORM_AUTHOR_LIST_VALUE:
        {
            // @ts-ignore
            const preSection = state.formData[data.section];
            const preData = preSection?.data;
            const preItems = preData.potentialAuthors[data.index];
            const newItems = {
                ...preItems,
                [data.key]: data.value,
            };
            let newAuthorList = [...preData.potentialAuthors];
            newAuthorList[data.index] = newItems;
            return {
                ...state,
                formData: {
                    ...state.formData,
                    [data.section]: {
                        ...preSection,
                        data: {
                            ...preData,
                            potentialAuthors: newAuthorList,
                        }
                    }
                }
            };
        }
        case actions.SET_SIP_FORM_OPTIONS:
            return {
                ...state,
                options: {
                    ...state.options,
                    [data.key]: data.options,
                }
            };
        case actions.SET_SIP_FORM_PROCEED_STATE:
            return {
                ...state,
                state: data
            };
        case actions.SET_SIP_FORM_SECTION_STATE:
        {
            // @ts-ignore
            const preSection = state.formData[data.section];
            const newSection = {
                ...preSection,
                state: data.state,
            };
            return {
                ...state,
                formData: {
                    ...state.formData,
                    [data.section]: newSection,
                }
            };
        }
        case actions.ADD_SIP_FORM_GE:
            return {
                ...state,
                formData: {
                    ...state.formData,
                    guestEditorInformation: {
                        ...state.formData.guestEditorInformation,
                        data: {
                            GEList: [
                                ...state.formData.guestEditorInformation.data.GEList,
                                initialGuestEditor,
                            ],
                        }
                    }
                }
            };
        case actions.DEL_SIP_FORM_GE:
        {
            const oldList = state.formData.guestEditorInformation.data.GEList;
            const newList = oldList.splice(data, 1);
            return {
                ...state,
                formData: {
                    ...state.formData,
                    guestEditorInformation: {
                        ...state.formData.guestEditorInformation,
                        data: {
                            GEList: oldList,
                        },
                    },
                },
            };
        }
        case actions.ADD_SIP_FORM_AUTHOR:
            return {
                ...state,
                formData: {
                    ...state.formData,
                    potentialAuthors: {
                        ...state.formData.potentialAuthors,
                        data: {
                            potentialAuthors: [
                                ...state.formData.potentialAuthors.data.potentialAuthors,
                                initialAuthor,
                            ],
                        }
                    }
                }
            };
        case actions.DEL_SIP_FORM_AUTHOR:
        {
            const oldList = state.formData.potentialAuthors.data.potentialAuthors;
            const newList = oldList.splice(data, 1);
            return {
                ...state,
                formData: {
                    ...state.formData,
                    potentialAuthors: {
                        ...state.formData.potentialAuthors,
                        data: {
                            potentialAuthors: oldList
                        }
                    }
                },
            };
        }
        case actions.SET_SIP_FORM_DATA:
            return {
                ...state,
                formData: data,
            };
        case actions.SET_SIP_FORM_ERROR:
            return {
                ...state,
                hasError: true,
                errorMessage: data,
            };
        case actions.CLEAR_SIP_FORM_ERROR:
            return {
                ...state,
                hasError: false,
                errorMessage: null,
            };
        default:
        return state;
    }
};
import {
  Box,
  Checkbox,
  styled,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import { visuallyHidden } from '@mui/utils';
import {
  cancelSelectAll,
  cancelSelectAllUser,
  selectAll,
  selectAllUser,
} from '../../../../actions/SeleteAction';
import { MiniBoldFont } from '../../../../components/FontStyle';
export default function SIPLogTableHeader(props) {
  //order:排序顺序，asc、desc、none
  //orderBy：根据某一字段排序
  //items：表头元素
  //hasCheckbox:表头是否有复选框
  //isAllCheck：是否全选
  //onSelectAllClick：全选后的逻辑处理
  //CheckboxColor：CheckBox的颜色，默认是MUI的Primary
  const {
    order = 'none',
    orderBy,
    items,
    onRequestSort = () => {},
    hasCheckbox = false,
    showColumns,
    handleSelectAll = () => {},
    handleUnselectAll = () => {},
    CheckboxColor,
    checkboxIcon,
    checkboxCheckedIcon,
    forUserTable,
    color = '#596A7C',
    backgroundColor = '#f1f3f5',
  } = props;
  const dispatch = useDispatch();
  const selector = state => {
    return {
      isAllSeleted: state.Selete.isAllSeleted,
      isAllUserSeleted: state.Selete.isAllUserSeleted,
    };
  };
  const { isAllSeleted, isAllUserSeleted } = useSelector(selector);

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  // 改变标题行的颜色
  const NewStyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: backgroundColor,
      color: color,
      ...MiniBoldFont,
      textTransform: 'uppercase',
      letterSpacing: '0.08em',
      borderBottom: 'none',
      boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
    },
    [`&.${tableCellClasses.root}`]: {
      height: '18px',
      padding: '12px 24px',
    },
  }));

  const handleCheckBox = () => {
    if (forUserTable !== undefined && forUserTable === true) {
      if (handleUnselectAll !== undefined) {
        if (isAllUserSeleted) {
          handleUnselectAll();
        } else {
          handleSelectAll();
        }
        return;
      }
      if (isAllUserSeleted) {
        dispatch(cancelSelectAllUser());
      } else {
        dispatch(selectAllUser());
      }
    } else {
      if (handleUnselectAll !== undefined) {
        if (isAllSeleted) {
          handleUnselectAll();
        } else {
          handleSelectAll();
        }
        return;
      }
      if (isAllSeleted) {
        dispatch(cancelSelectAll());
      } else {
        dispatch(selectAll());
      }
    }
  };

  return (
    <TableHead data-selenium-id='SIP_DetailPage_Log-TableHeader-Head'>
      <TableRow data-selenium-id='SIP_DetailPage_Log-TableHeader-Row'>
        {hasCheckbox ? (
          <NewStyledTableCell
            data-selenium-id='SIP_DetailPage_Log-TableHeader-NewStyledCell1'
            style={{
              position: props.hasCheckboxStick ? 'sticky' : '',
              left: props.hasCheckboxStick ? 0 : '',
              zIndex: 6,
            }}
          >
            <Checkbox
              id='myTableHeaderSelectAllCheckBox'
              data-selenium-id='SIP_DetailPage_Log-TableHeader-MySelectAllCheckBox'
              color={CheckboxColor ? CheckboxColor : 'primary'}
              checked={forUserTable ? isAllUserSeleted : isAllSeleted}
              onChange={handleCheckBox}
              icon={checkboxIcon}
              checkedIcon={checkboxCheckedIcon}
            />
          </NewStyledTableCell>
        ) : null}

        {items.map((item, index) => (
          <NewStyledTableCell
            data-selenium-id='SIP_DetailPage_Log-TableHeader-NewStyledCell2'
            key={item.id}
            align={item.align}
            sx={{
              maxWidth: item.maxWidth,
              minWidth: item.minWidth,
              display:
                showColumns && !showColumns[index] ? 'none' : 'table-cell',
              whiteSpace:'nowrap'
            }}
            sortDirection={orderBy === item.id ? order : false}
            style={{
              position: item.stick ? 'sticky' : '',
              left: item.stick ? item.stickPX : '',
              zIndex: item.stick ? 6 : '',
            }}
          >
            {!item.isOrder ? (
              <Box
                id={`myTableHeaderItem${index}`}
                data-selenium-id={`SIP_DetailPage_Log-TableHeader-MyItem-${index}`}
                sx={{whiteSpace:'nowrap'}}
              >
                {item.label}
              </Box>
            ) : (
              <TableSortLabel
                id={`myTableHeaderItem${index}`}
                data-selenium-id={`SIP_DetailPage_Log-TableHeader-MyItem-${index}`}
                active={orderBy === item.id}
                direction={orderBy === item.id ? order : 'asc'}
                onClick={item.isOrder ? createSortHandler(item.id) : null}
                style={{
                  paddingLeft: item.stick && !hasCheckbox ? '40px' : '0px',
                  
                }}
                sx={{whiteSpace:'nowrap'}}
              >
                {item.label}
                {orderBy === item.id ? (
                  <Box
                    component='span'
                    sx={visuallyHidden}
                    data-selenium-id='SIP_DetailPage_Log-TableHeader-Box'
                  >
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </NewStyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

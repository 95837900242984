import { axios_instance } from "@/utils/axios_instance";
import {
    DO_FLAGGED_GE_VERIFY,
    SET_FLAGGED_GE_PROPOSER_DETAIL
} from "@/actions/SIMT-SIP/FlaggedGEProposer/FlaggedGEProposerAction";

export function getFlaggedGEProposerDetail() {
    const url = `/api/v1/sip-service/special-issue-proposals/synchronize-ge`;
    return async dispatch => {
        try {
            const data = await axios_instance
                .get(url, {
                    headers: { authorization: localStorage.getItem('authorization') },
                });
            dispatch({
                type: SET_FLAGGED_GE_PROPOSER_DETAIL,
                data: data.data,
            });
        } catch (err) {
            console.log('getFlaggedGEProposerDetail cannot get data');
        }
    };
}

export function doFlaggedGEVerify(geCode, geId, sipCode) {
    const url = `/api/v1/sip-service/special-issue-proposals/synchronize-ge`;
    return async dispatch => {
        try {
            const res = await axios_instance.put(url, {
                geCode: geCode,
                geId: geId,
            }, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({
                    type: DO_FLAGGED_GE_VERIFY,
                    data: res.data,
                });
                return res.data.data;
            } else {

            }

        } catch (err) {

        }
    };
}
import {useState, useEffect} from 'react';
// styles
import { AllCentered } from '@/pages/SIPage/Overview/Filter/component/Css';
// Components
import { Box, Stack, ThemeProvider, createTheme } from '@mui/material';
import { StyledButton } from '@/pages/SIPage/Overview/Filter/component/StyledComponents/styledButton';
// Assets
import NumberIcon from '@/pages/SIPage/Overview/Filter/component/CommonComponets/NumberIcon';
// utils
import { getThemeColor } from '../utils';

export default function ThemeButton(props) {
  const { id, label, number, theme, currentTab='', ...other } = props;
  const themeColor = getThemeColor(theme);
  const [active, setActive] = useState(false);
  const themeCss = createTheme({
    themeColor: themeColor,
    active: active,
  });
  useEffect(()=>{
    setActive(label === currentTab);
  },[currentTab]);
  return (
    <ThemeProvider theme={themeCss}>
      <StyledButton
        id={id}
        data-selenium-id={id}
        {...other}
      >
        <Stack direction='row' spacing={0.75} sx={AllCentered}>
          <Box>{label}</Box>
          <NumberIcon number={number} />
        </Stack>
      </StyledButton>
    </ThemeProvider>
  );
}

import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ResponsiveButton } from '../../../components/StyledButton/SIMTButton';
import CATJournalBatchUpdateIconBlack from '@/assets/CATJournalBatchUpdateIconBlack.svg';
import CATJournalBatchUpdateIconWhite from '@/assets/CATJournalBatchUpdateIconWhite.svg';
import { setSnackbarMessageAndOpen } from '../../../actions/SnackbarAction';
import CATJournalBatchUpdateIcon from '../../../assets/CatJournalBatchUpdateNew.svg';
import CATJournalBatchUpdateIconHover from '../../../assets/CATJournalBatchUpdateIconHover.svg';
import CATJournalBatchUpdateIconWhiteNew from '../../../assets/CatJournalBatchUpdateWhiteNew.svg';
import { SEVERITIES } from '../../../modules/ConfirmSnackBar/CommonSnackBar';
import CATJournalBatchUpdatePop from './CATJournalBatchUpdatePop';
export default function CATJournalBatchUpdate(props) {
  const { downBy } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  
  const selector = state => {
    return {
      selectedList: state.CATJournal.selectedList,
    };
  };
  const { selectedList } = useSelector(selector);

  const [hasSelector, setHasSelector] = useState(false);
  const [open, setOpen] = useState(false);

  const handleUpdateOpen = () => {
    if (!hasSelector) {
      dispatch(
        setSnackbarMessageAndOpen(
          'catJournal.batchUpdateNoSelectorErr',
          {},
          SEVERITIES.warning,
        )
      );
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    setHasSelector(selectedList.length > 0);
  }, [selectedList]);
  return (
    <>
      <ResponsiveButton
        id='CatJounalBatchUpdate'
        data-selenium-id='CATJournal_Overview-BatchUpdate-ResponsiveButton'
        color='white'
        variant='contained'
        downBy={downBy}
        startIcon={
          hasSelector ? (
            <CATJournalBatchUpdateIconWhiteNew id='iconBatchUpdate'/>
          ) : (
            <CATJournalBatchUpdateIcon id='iconBatchUpdate'/>
          )
        }
        onClick={handleUpdateOpen}
        sx={{
          minWidth: 'auto',
          border: hasSelector ? 'none' : '1px solid #0052CC',
          height: '34px',
          width: '123px',
          whiteSpace: 'nowrap',
          color: hasSelector ? '#FFFFFF' : '#0052CC',
          backgroundColor: hasSelector ? '#0052CC' : '#FFFFFF',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '18px',
          /*'&:hover': {
            background: '#6D98EE',
          },
          '&:active': {
            background: '#113D95',
          },*/
          '&:hover': {
            color: hasSelector ?'#FFFFFF' : '#6D98EE',
            background: hasSelector ?'#6d98ee' : '#FFFFFF',
            'svg path': {
              fill: hasSelector ?'#FFFFFF' :'#6D98EE',
            },
          },
          '&:active': {
            color: hasSelector ?'#FFFFFF' : '#6D98EE',
            background: hasSelector ?'#113d95' : '#e8edfb',
            'svg path': {
              fill: hasSelector ?'#FFFFFF' :'#6D98EE',
            },
          },
        }}
      >
        <FormattedMessage
          id='catJournal.batchUpdate'
          data-selenium-id='CATJournal_Overview-BatchUpdate-FormattedMessage'
        />
      </ResponsiveButton>
      <CATJournalBatchUpdatePop
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        data-selenium-id='CATJournal_Overview-BatchUpdate-CATJournalBatchUpdatePop'
      />
    </>
  );
}

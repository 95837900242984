import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';
import {
  TableRow,
  tableCellClasses,
  IconButton,
  TextField,
  Box,
} from '@mui/material';
import DropDownIcon from '../../../../../assets/CatOverviewDropDownIcon.svg';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StyledTableCell } from '@/components/DataTable';
import CatFilterDatePickerIcon from '../../../../../assets/CatOverviewDatePicker.svg';
import {
  useIsMount,
} from '@/utils/commonUtils';
import PopupState, { bindTrigger } from 'material-ui-popup-state';
import JournalFilterPopup from './journal-filter-components/JournalFilterPopup';
import { useDispatch, useSelector } from 'react-redux';
import { getValueStringByObjectKey } from './journal-filter-components/JournalFilterConstantListDisplay';
import {
  loadCatJournalConstFilterData,
  selectCatJournalFilterItem,

} from '@/actions/SIMT-SI/CatJournal/CATJournalAction';
import { DatePicker } from '@mui/x-date-pickers';
import DateRangePicker from './CatDateRangePicker';
import { format } from 'date-fns';
import {getSubjectGroupList, loadCatJournalFilter} from "@/actions/SIMT-SI/CatJournal";


/**
 * @param {import("./CATSearchRow").FilterTableCellProps} props
 * @returns
 */
function FilterTableCell(props) {
  const { data, align, sticky, stickyPX = 'auto', children } = props;
  /** @type {import("@mui/system").SxProps} */
  const style = {
    display: 'table-cell',
    position: sticky ? 'sticky' : '',
    left: sticky ? stickyPX : '',
    zIndex: sticky ? 5 : '',
    fontWeight: 400,
    fontSize: '12px',
    color: ' #596A7C',
    padding: '0px 24px',
    background: '#FFFFFF',
    lineHeight: '24px',
    [`&.${tableCellClasses.root}`]: {
      height: '44px',
      padding: '0x 24px',
    },
  };
  if (data) {
    return (
      <StyledTableCell
        sx={style}
        align={align}
        data-selenium-id='CATJournal_Overview-Table-SearchRow-StyledTableCell1'
      >
        {data}
      </StyledTableCell>
    );
  }
  return (
    <StyledTableCell
      sx={style}
      align={align}
      data-selenium-id='CATJournal_Overview-Table-SearchRow-StyledTableCell2'
    >
      {children}
    </StyledTableCell>
  );
}

/**
 * @param {import("./CATSearchRow").FilterDatePickerProps} props
 * @returns
 */
function FilterDatePicker(props) {
  const { id, defaultValue = new Date(), value, onChange, ...others } = props;

  const [inputValue, setInputValue] = useState(
    /** @type {Date | null} */(null)
  );

  const isMount = useIsMount();

  useEffect(() => {
    if (!isMount) return;

    const timer = setTimeout(() => {
      onChange(inputValue);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [inputValue]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      data-selenium-id='CATJournal_Overview-Table-SearchRow-LocalizationProvider'
    >
      <DatePicker
        // openTo="month"
        inputFormat='yyyy-MM-dd'
        value={inputValue}
        onChange={setInputValue}
        components={{
          OpenPickerIcon: CatFilterDatePickerIcon,
        }}
        renderInput={params => (
          <TextField
            {...params}
            sx={{
              // border: "0.8px solid #DFE4E8",
              borderRadius: '3.2px',
              color: '#262E35',
              margin: '0px 8px',
              '& .MuiInputBase-input': {
                fontWeight: value != null ? 700 : 400,
                fontSize: '12px',
                fontFamily: 'Open Sans',
                lineHeight: '12px',
                height: '16px',
                letterSpacing: '-0.011em',
                padding: '6px 0px 6px 10px',
                width: '68px',
                textAlign: 'center',
              },
              '& .MuiInputAdornment-root': {
                marginLeft: 0,
              },
            }}
            placeholder='yyyy-mm'
          />
        )}
        {...others}
      />
    </LocalizationProvider>
  );
}

export default function CATSearchRow() {
  const { catFilterData, catFilterSelected, subjectGroup } = useSelector(
    state => {
      return {
        /** @type {import("./journal-filter-components/JournalFilterPopup").CatFilterDataState} */
        catFilterData: state.CATJournal.catFilterData,
        /** @type {import("./journal-filter-components/JournalFilterPopup").CatFilterSelectedState} */
        catFilterSelected: state.CATJournal.catFilterSelected,
        subjectGroup: state.CATJournal.subjectGroup,
      };
    }
  );

  const { orderByType, page, pageSize } = useSelector(state => {
    return {
      orderByType: state.CATJournal.orderByType,
      page: state.CATJournal.page,
      pageSize: state.CATJournal.pageSize,
    };
  });
  const [abbreviations, setAbbreviations] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCatJournalConstFilterData());
    dispatch(getSubjectGroupList());
  }, []);

  useEffect(() => {
    if(!Array.isArray(subjectGroup)){
      return;
    }
    let arr = [];
    for (let i = 0; i < subjectGroup.length; i++) {
      arr.push(subjectGroup[i].abbreviations);
    }
    setAbbreviations(arr);
  }, [subjectGroup]);

  return (
    <TableRow
      style={{
        position: 'sticky',
        top: '42px',
        background: 'white',
        zIndex: 6,
      }}
      data-selenium-id='CATJournal_Overview-Table-SearchRow-TableRow'
    >
      <FilterTableCell
        sticky
        stickyPX='0'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell1'
      ></FilterTableCell>
      {/* code */ }
      <FilterTableCell
        align='left'
        sticky
        stickyPX='72px'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell2'
      >
        <PopupState variant='popover' popupId='journalCodePanel'>
          {popupState => (
            <>
              <Box
                component={'span'} {...bindTrigger(popupState)}
                sx={{
                  color: catFilterSelected.journalCode.length > 0 ? '#262E35' : '#596A7C',
                  fontWeight: catFilterSelected.journalCode.length > 0 ? 700 : 400,
                  'svg path': {
                    fill: catFilterSelected.journalCode.length > 0 ? '#262E35' : '#596A7C',
                  },
                }}
              >
                <FormattedMessage id='catJournal.filters.journalCode' data-selenium-id='CATJournal_Overview-Table-SearchRow-CodeFormattedMessage' />
                <IconButton
                  sx={{ padding: '14px' }}
                  disableFocusRipple
                  disableRipple
                >
                  <DropDownIcon></DropDownIcon>
                </IconButton>
              </Box>
              <JournalFilterPopup
                popupState={popupState}
                id='journalCode'
                data-selenium-id='CATJournal_Overview-Table-SearchRow-CodeFilterPopup'
                data={catFilterData.journalCode}
                selected={catFilterSelected.journalCode}
                selectObjectKey='self'
                displayFunction={item => {
                  return getValueStringByObjectKey('self', item);
                }}
                placeholder='Search Journal by code'
                handleSearch={async searchContent => {
                  dispatch(loadCatJournalFilter('journalCode', searchContent));
                  // setJournalCodeSearchContent(searchContent);
                }}
              />
            </>
          )}
        </PopupState>
      </FilterTableCell>
      {/* name */ }
      <FilterTableCell
        align='left'
        sticky
        stickyPX='247px'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell3'
      >
        <PopupState variant='popover' popupId='journalNamePanel'>
          {popupState => (
            <>
              <Box component={'span'} {...bindTrigger(popupState)}
                sx={{
                  color: catFilterSelected.journalName.length > 0 ? '#262E35' : '#596A7C',
                  fontWeight: catFilterSelected.journalName.length > 0 ? 700 : 400,
                  'svg path': {
                    fill: catFilterSelected.journalName.length > 0 ? '#262E35' : '#596A7C',
                  },
                }}
              >
                <FormattedMessage id='catJournal.filters.journalName' data-selenium-id='CATJournal_Overview-Table-SearchRow-NameFormattedMessage' />
                <IconButton
                  sx={{ padding: '14px' }}
                  disableFocusRipple
                  disableRipple
                >
                  <DropDownIcon></DropDownIcon>
                </IconButton>
              </Box>
              <JournalFilterPopup
                popupState={popupState}
                id='journalName'
                data-selenium-id='CATJournal_Overview-Table-SearchRow-NameFilterPopup'
                data={catFilterData.journalName}
                selected={catFilterSelected.journalName}
                selectObjectKey='self'
                displayFunction={item => {
                  return getValueStringByObjectKey('self', item);
                }}
                placeholder='Search Journal by name'
                handleSearch={async searchContent => {
                  dispatch(loadCatJournalFilter('journalName', searchContent));
                  // setJournalNameSearchContent(searchContent);
                }}
              />
            </>
          )}
        </PopupState>
      </FilterTableCell>
      {/* status */ }
      <FilterTableCell
        align='left'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell4'
      >
        <PopupState variant='popover' popupId='journalStatusPanel'>
          {popupState => (
            <>
              <Box component={'span'} {...bindTrigger(popupState)}
                sx={{
                  color: catFilterSelected.status.length > 0 ? '#262E35' : '#596A7C',
                  fontWeight: catFilterSelected.status.length > 0 ? 700 : 400,
                  'svg path': {
                    fill: catFilterSelected.status.length > 0 ? '#262E35' : '#596A7C',
                  },
                }}
              >
                <FormattedMessage id='catJournal.filters.status' data-selenium-id='CATJournal_Overview-Table-SearchRow-FiltersStatusFormattedMessage' />
                <IconButton
                  sx={{ padding: '14px' }}
                  disableFocusRipple
                  disableRipple
                >
                  <DropDownIcon></DropDownIcon>
                </IconButton>
              </Box>
              <JournalFilterPopup
                popupState={popupState}
                id='status'
                data-selenium-id='CATJournal_Overview-Table-SearchRow-StatusFilterPopup'
                data={catFilterData.status}
                selected={catFilterSelected.status}
                isConstantList
              />
            </>
          )}
        </PopupState>
      </FilterTableCell>
      {/* dev model */ }
      <FilterTableCell
        align='left'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell7'
      >
        <PopupState variant='popover' popupId='journalDevModalPanel'>
          {popupState => (
            <>
              <Box component={'span'} data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell7-Box' {...bindTrigger(popupState)}
                sx={{
                  color: catFilterSelected.developmentModel.length > 0 ? '#262E35' : '#596A7C',
                  fontWeight: catFilterSelected.developmentModel.length > 0 ? 700 : 400,
                  'svg path': {
                    fill: catFilterSelected.developmentModel.length > 0 ? '#262E35' : '#596A7C',
                  },
                }}
              >
                <FormattedMessage id='catJournal.filters.devModel' data-selenium-id='CATJournal_Overview-Table-SearchRow-FiltersDevModelFormattedMessage' />
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{ padding: '14px' }}
                  data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell7-IconButton'
                >
                  <DropDownIcon></DropDownIcon>
                </IconButton>
              </Box>
              <JournalFilterPopup
                popupState={popupState}
                id='developmentModel'
                data-selenium-id='CATJournal_Overview-Table-SearchRow-DevelopmentModelFilterPopup'
                data={catFilterData.developmentModel}
                selected={catFilterSelected.developmentModel}
                isConstantList
              />
            </>
          )}
        </PopupState>
      </FilterTableCell>
      {/* handling ce */ }
      <FilterTableCell
        align='left'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell8'
      >
        <PopupState variant='popover' popupId='journalHandlingCePanel'>
          {popupState => (
            <>
              <Box
                component={'span'}
                data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell8-Box'
                {...bindTrigger(popupState)}

                sx={{
                  color: catFilterSelected.journalHandlingCe.length > 0 ? '#262E35' : '#596A7C',
                  fontWeight: catFilterSelected.journalHandlingCe.length > 0 ? 700 : 400,
                  'svg path': {
                    fill: catFilterSelected.journalHandlingCe.length > 0 ? '#262E35' : '#596A7C',
                  },
                }}
              >
                <FormattedMessage
                  id='catJournal.filters.handlingCe'
                  data-selenium-id='CATJournal_Overview-Table-SearchRow-FiltersHandlingCeFormattedMessage'
                />
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{ padding: '14px' }}
                  data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell8-IconButton'
                >
                  <DropDownIcon></DropDownIcon>
                </IconButton>
              </Box>
              <JournalFilterPopup
                popupState={popupState}
                id='journalHandlingCe'
                data-selenium-id='CATJournal_Overview-Table-SearchRow-HandlingCeFilterPopup'
                data={catFilterData.journalHandlingCe}
                selected={catFilterSelected.journalHandlingCe}
                selectObjectKey='self'
                displayFunction={item => {
                  const [username] = getValueStringByObjectKey(
                    'self',
                    item
                  ).split(':');
                  return username;
                }}
                placeholder='Search Journal by SCA LEAD'
                handleSearch={async searchContent => {
                  dispatch(
                    loadCatJournalFilter('journalHandlingCe', searchContent)
                  );
                  // setJournalHandlingCeSearchContent(searchContent);
                }}
              />
            </>
          )}
        </PopupState>
      </FilterTableCell>
        {/* SCA Manager */ }
        <FilterTableCell
        align='left'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell8'
      >
        <PopupState variant='popover' popupId='journalScamPanel'>
          {popupState => (
            <>
              <Box
                component={'span'}
                data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell8-Box'
                {...bindTrigger(popupState)}

                sx={{
                  color: catFilterSelected.scam.length > 0 ? '#262E35' : '#596A7C',
                  fontWeight: catFilterSelected.scam.length > 0 ? 700 : 400,
                  'svg path': {
                    fill: catFilterSelected.scam.length > 0 ? '#262E35' : '#596A7C',
                  },
                }}
              >
                <FormattedMessage
                  id='catJournal.filters.scam'
                  data-selenium-id='CATJournal_Overview-Table-SearchRow-FiltersScamFormattedMessage'
                />
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{ padding: '14px' }}
                  data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell8-IconButton'
                >
                  <DropDownIcon></DropDownIcon>
                </IconButton>
              </Box>
              <JournalFilterPopup
                popupState={popupState}
                id='scam'
                data-selenium-id='CATJournal_Overview-Table-SearchRow-ScamFilterPopup'
                data={catFilterData.scam}
                selected={catFilterSelected.scam}
                selectObjectKey='self'
                displayFunction={item => {
                  const [username] = getValueStringByObjectKey(
                    'self',
                    item
                  ).split(':');
                  return username;
                }}
                placeholder='Search Journal by SCA Manager'
                handleSearch={async searchContent => {
                  dispatch(
                    loadCatJournalFilter('scam', searchContent)
                  );
                  // setJournalHandlingCeSearchContent(searchContent);
                }}
              />
            </>
          )}
        </PopupState>
      </FilterTableCell>
      {/* comments */}
      <FilterTableCell data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell17'></FilterTableCell>
      {/* group */ }
      <FilterTableCell
        align='left'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell9'
      >
        <PopupState variant='popover' popupId='journalgroupPanel'>
          {popupState => (
            <>
              <Box component={'span'} data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell9-Box' {...bindTrigger(popupState)}
                sx={{
                  color: catFilterSelected.catGroup.length > 0 ? '#262E35' : '#596A7C',
                  fontWeight: catFilterSelected.catGroup.length > 0 ? 700 : 400,
                  'svg path': {
                    fill: catFilterSelected.catGroup.length > 0 ? '#262E35' : '#596A7C',
                  },
                }}
              >
                <FormattedMessage id='catJournal.filters.group' data-selenium-id='CATJournal_Overview-Table-SearchRow-FiltersGroupFormattedMessage' />
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{ padding: '14px' }}
                  data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell9-IconButton'
                >
                  <DropDownIcon></DropDownIcon>
                </IconButton>
              </Box>
              <JournalFilterPopup
                popupState={popupState}
                id='catGroup'
                data-selenium-id='CATJournal_Overview-Table-SearchRow-CatGroupFilterPopup'
                data={catFilterData.catGroup}
                selected={catFilterSelected.catGroup}
                // isConstantList
                handleSearch={async searchContent => {
                  dispatch(loadCatJournalFilter('catGroup', searchContent));
                  // setJournalJpmSearchContent(searchContent);
                }}
              />
            </>
          )}
        </PopupState>
      </FilterTableCell>
      {/* pdPublisher */ }
      <FilterTableCell
        align='left'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10'
      >
        <PopupState variant='popover' popupId='journalpdPublisherPanel'>
          {popupState => (
            <>
              <Box component={'span'} data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10-Box' {...bindTrigger(popupState)}
                sx={{
                  color: catFilterSelected.pdPublisher.length > 0 ? '#262E35' : '#596A7C',
                  fontWeight: catFilterSelected.pdPublisher.length > 0 ? 700 : 400,
                  'svg path': {
                    fill: catFilterSelected.pdPublisher.length > 0 ? '#262E35' : '#596A7C',
                  },
                }}
              >
                <FormattedMessage id='catJournal.filters.pdPublisher' data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterspdPublisherFormattedMessage' />
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{ padding: '14px' }}
                  data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10-IconButton'
                >
                  <DropDownIcon></DropDownIcon>
                </IconButton>
              </Box>
              <JournalFilterPopup
                popupState={popupState}
                id='pdPublisher'
                data-selenium-id='CATJournal_Overview-Table-SearchRow-pdPublisherFilterPopup'
                data={catFilterData.pdPublisher}
                selected={catFilterSelected.pdPublisher}
                selectObjectKey='self'
                displayFunction={item => {
                  return getValueStringByObjectKey('value', item);
                }}
                placeholder='Search Journal by PD Publisher'
                handleSearch={async searchContent => {
                  dispatch(loadCatJournalFilter('pdPublisher', searchContent));
                }}
              />
            </>
          )}
        </PopupState>
      </FilterTableCell>
      {/* pdManager */ }
      <FilterTableCell
        align='left'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10'
      >
        <PopupState variant='popover' popupId='journalpdManagerPanel'>
          {popupState => (
            <>
              <Box component={'span'} data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10-Box' {...bindTrigger(popupState)}
                sx={{
                  color: catFilterSelected.pdManager.length > 0 ? '#262E35' : '#596A7C',
                  fontWeight: catFilterSelected.pdManager.length > 0 ? 700 : 400,
                  'svg path': {
                    fill: catFilterSelected.pdManager.length > 0 ? '#262E35' : '#596A7C',
                  },
                }}
              >
                <FormattedMessage id='catJournal.filters.pdManager' data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterspdManagerFormattedMessage' />
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{ padding: '14px' }}
                  data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10-IconButton'
                >
                  <DropDownIcon></DropDownIcon>
                </IconButton>
              </Box>
              <JournalFilterPopup
                popupState={popupState}
                id='pdManager'
                data-selenium-id='CATJournal_Overview-Table-SearchRow-pdManagerFilterPopup'
                data={catFilterData.pdManager}
                selected={catFilterSelected.pdManager}
                selectObjectKey='self'
                displayFunction={item => {
                  return getValueStringByObjectKey('value', item);
                }}
                placeholder='Search Journal by PD Manager'
                handleSearch={async searchContent => {
                  dispatch(loadCatJournalFilter('pdManager', searchContent));
                }}
              />
            </>
          )}
        </PopupState>
      </FilterTableCell>
      {/* pdDirector */ }
      <FilterTableCell
        align='left'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10'
      >
        <PopupState variant='popover' popupId='journalpdDirectorPanel'>
          {popupState => (
            <>
              <Box component={'span'} data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10-Box' {...bindTrigger(popupState)}
                sx={{
                  color: catFilterSelected.pdDirector.length > 0 ? '#262E35' : '#596A7C',
                  fontWeight: catFilterSelected.pdDirector.length > 0 ? 700 : 400,
                  'svg path': {
                    fill: catFilterSelected.pdDirector.length > 0 ? '#262E35' : '#596A7C',
                  },
                }}
              >
                <FormattedMessage id='catJournal.filters.pdDirector' data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterspdDirectorFormattedMessage' />
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{ padding: '14px' }}
                  data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10-IconButton'
                >
                  <DropDownIcon></DropDownIcon>
                </IconButton>
              </Box>
              <JournalFilterPopup
                popupState={popupState}
                id='pdDirector'
                data-selenium-id='CATJournal_Overview-Table-SearchRow-pdDirectorFilterPopup'
                data={catFilterData.pdDirector}
                selected={catFilterSelected.pdDirector}
                selectObjectKey='self'
                displayFunction={item => {
                  return getValueStringByObjectKey('value', item);
                }}
                placeholder='Search Journal by PD Director'
                handleSearch={async searchContent => {
                  dispatch(loadCatJournalFilter('pdDirector', searchContent));
                }}
              />
            </>
          )}
        </PopupState>
      </FilterTableCell>
      {/* ppPublisher */ }
      <FilterTableCell
        align='left'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10'
      >
        <PopupState variant='popover' popupId='journalppPublisherPanel'>
          {popupState => (
            <>
              <Box component={'span'} data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10-Box' {...bindTrigger(popupState)}
                sx={{
                  color: catFilterSelected.ppPublisher.length > 0 ? '#262E35' : '#596A7C',
                  fontWeight: catFilterSelected.ppPublisher.length > 0 ? 700 : 400,
                  'svg path': {
                    fill: catFilterSelected.ppPublisher.length > 0 ? '#262E35' : '#596A7C',
                  },
                }}
              >
                <FormattedMessage id='catJournal.filters.ppPublisher' data-selenium-id='CATJournal_Overview-Table-SearchRow-FiltersppPublisherFormattedMessage' />
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{ padding: '14px' }}
                  data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10-IconButton'
                >
                  <DropDownIcon></DropDownIcon>
                </IconButton>
              </Box>
              <JournalFilterPopup
                popupState={popupState}
                id='ppPublisher'
                data-selenium-id='CATJournal_Overview-Table-SearchRow-ppPublisherFilterPopup'
                data={catFilterData.ppPublisher}
                selected={catFilterSelected.ppPublisher}
                selectObjectKey='self'
                displayFunction={item => {
                  return getValueStringByObjectKey('value', item);
                }}
                placeholder='Search Journal by PP Publisher'
                handleSearch={async searchContent => {
                  dispatch(loadCatJournalFilter('ppPublisher', searchContent));
                }}
              />
            </>
          )}
        </PopupState>
      </FilterTableCell>
      {/* ppManager */ }
      <FilterTableCell
        align='left'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10'
      >
        <PopupState variant='popover' popupId='journalppManagerPanel'>
          {popupState => (
            <>
              <Box component={'span'} data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10-Box' {...bindTrigger(popupState)}
                sx={{
                  color: catFilterSelected.ppManager.length > 0 ? '#262E35' : '#596A7C',
                  fontWeight: catFilterSelected.ppManager.length > 0 ? 700 : 400,
                  'svg path': {
                    fill: catFilterSelected.ppManager.length > 0 ? '#262E35' : '#596A7C',
                  },
                }}
              >
                <FormattedMessage id='catJournal.filters.ppManager' data-selenium-id='CATJournal_Overview-Table-SearchRow-FiltersppManagerFormattedMessage' />
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{ padding: '14px' }}
                  data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10-IconButton'
                >
                  <DropDownIcon></DropDownIcon>
                </IconButton>
              </Box>
              <JournalFilterPopup
                popupState={popupState}
                id='ppManager'
                data-selenium-id='CATJournal_Overview-Table-SearchRow-ppManagerFilterPopup'
                data={catFilterData.ppManager}
                selected={catFilterSelected.ppManager}
                selectObjectKey='self'
                displayFunction={item => {
                  return getValueStringByObjectKey('value', item);
                }}
                placeholder='Search Journal by PP Manager'
                handleSearch={async searchContent => {
                  dispatch(loadCatJournalFilter('ppManager', searchContent));
                }}
              />
            </>
          )}
        </PopupState>
      </FilterTableCell>
      {/* ppDirector */ }
      <FilterTableCell
        align='left'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10'
      >
        <PopupState variant='popover' popupId='journalppDirectorPanel'>
          {popupState => (
            <>
              <Box component={'span'} data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10-Box' {...bindTrigger(popupState)}
                sx={{
                  color: catFilterSelected.ppDirector.length > 0 ? '#262E35' : '#596A7C',
                  fontWeight: catFilterSelected.ppDirector.length > 0 ? 700 : 400,
                  'svg path': {
                    fill: catFilterSelected.ppDirector.length > 0 ? '#262E35' : '#596A7C',
                  },
                }}
              >
                <FormattedMessage id='catJournal.filters.ppDirector' data-selenium-id='CATJournal_Overview-Table-SearchRow-FiltersppDirectorFormattedMessage' />
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{ padding: '14px' }}
                  data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell10-IconButton'
                >
                  <DropDownIcon></DropDownIcon>
                </IconButton>
              </Box>
              <JournalFilterPopup
                popupState={popupState}
                id='ppDirector'
                data-selenium-id='CATJournal_Overview-Table-SearchRow-ppDirectorFilterPopup'
                data={catFilterData.ppDirector}
                selected={catFilterSelected.ppDirector}
                selectObjectKey='self'
                displayFunction={item => {
                  return getValueStringByObjectKey('value', item);
                }}
                placeholder='Search Journal by PP Director'
                handleSearch={async searchContent => {
                  dispatch(loadCatJournalFilter('ppDirector', searchContent));
                }}
              />
            </>
          )}
        </PopupState>
      </FilterTableCell>
      {/* SubGroup */ }
      <FilterTableCell
        align='left'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell12'
      >
        <PopupState variant='popover' popupId='journalSubGroupPanel'>
          {popupState => (
            <>
              <Box component={'span'} data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell12-Box' {...bindTrigger(popupState)}
                sx={{
                  color: catFilterSelected.subjectGroup.length > 0 ? '#262E35' : '#596A7C',
                  fontWeight: catFilterSelected.subjectGroup.length > 0 ? 700 : 400,
                  'svg path': {
                    fill: catFilterSelected.subjectGroup.length > 0 ? '#262E35' : '#596A7C',
                  },
                }}
              >
                <FormattedMessage id='catJournal.filters.subGroup' data-selenium-id='CATJournal_Overview-Table-SearchRow-FiltersSubGroupFormattedMessage' />
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{ padding: '14px' }}
                  data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell12-IconButton'
                >
                  <DropDownIcon></DropDownIcon>
                </IconButton>
              </Box>
              <JournalFilterPopup
                popupState={popupState}
                id='subjectGroup'
                data-selenium-id='CATJournal_Overview-Table-SearchRow-SubjectGroupFilterPopup'
                data={abbreviations}
                selected={catFilterSelected.subjectGroup}
                isConstantList
              />
            </>
          )}
        </PopupState>
      </FilterTableCell>
      {/* BusDivision */}
      <FilterTableCell
        align='left'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell13'
      >
        <PopupState variant='popover' popupId='journalBusDivisionGroupPanel'>
          {popupState => (
            <>
              <Box component={'span'} {...bindTrigger(popupState)} data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell13-Box'
                sx={{
                  color: catFilterSelected.businessDivision.length > 0 ? '#262E35' : '#596A7C',
                  fontWeight: catFilterSelected.businessDivision.length > 0 ? 700 : 400,
                  'svg path': {
                    fill: catFilterSelected.businessDivision.length > 0 ? '#262E35' : '#596A7C',
                  },
                }}
              >
                <FormattedMessage id='catJournal.filters.busDivision' data-selenium-id='CATJournal_Overview-Table-SearchRow-FiltersBusDivisionFormattedMessage' />
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{ padding: '14px' }}
                  data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell13-IconButton'
                >
                  <DropDownIcon></DropDownIcon>
                </IconButton>
              </Box>
              <JournalFilterPopup
                popupState={popupState}
                id='businessDivision'
                data-selenium-id='CATJournal_Overview-Table-SearchRow-BusinessDivisionFilterPopup'
                data={catFilterData.businessDivision}
                selected={catFilterSelected.businessDivision}
                isConstantList
              />
            </>
          )}
        </PopupState>
      </FilterTableCell>
      {/* RevenueModel */}
      <FilterTableCell
        align='left'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell14'
      >
        <PopupState variant='popover' popupId='journalRevenueModelPanel'>
          {popupState => (
            <>
              <Box component={'span'} {...bindTrigger(popupState)} data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell14-Box'
                sx={{
                  color: catFilterSelected.revenueModel.length > 0 ? '#262E35' : '#596A7C',
                  fontWeight: catFilterSelected.revenueModel.length > 0 ? 700 : 400,
                  'svg path': {
                    fill: catFilterSelected.revenueModel.length > 0 ? '#262E35' : '#596A7C',
                  },
                }}
              >
                <FormattedMessage id='catJournal.filters.revenueModel' data-selenium-id='CATJournal_Overview-Table-SearchRow-FiltersRevenueModelFormattedMessage' />
                <IconButton
                  disableFocusRipple
                  disableRipple
                  sx={{ padding: '14px' }}
                  data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell14-IconButton'
                >
                  <DropDownIcon></DropDownIcon>
                </IconButton>
              </Box>
              <JournalFilterPopup
                popupState={popupState}
                id='revenueModel'
                data-selenium-id='CATJournal_Overview-Table-SearchRow-RevenueModelFilterPopup'
                data={catFilterData.revenueModel}
                selected={catFilterSelected.revenueModel}
                isConstantList
              />
            </>
          )}
        </PopupState>
      </FilterTableCell>

      {/* outin date */ }
      <FilterTableCell data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell5'>
        <DateRangePicker
          fromDate={catFilterSelected.optInDateFrom[0]}
          toDate={catFilterSelected.optInDateTo[0]}
          handleDateChange={(f, t) => {
            dispatch(
              selectCatJournalFilterItem('optInDateFrom', f, 'replace')
            );
            dispatch(
              selectCatJournalFilterItem('optInDateTo', t, 'replace')
            );
          }}
          maxDate={new Date}
          innerBorder={'1px solid #DFE4E8'}
          border={'1px solid #FFFFFF'}
          backgroundColor='#FFFFFF'
          iconPaddingRight={0}
          type={'optIn'}
        />
        {/* <Box
          sx={{ lineHeight: '28px' }}
          data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell5-Box1'
        >
            From
        </Box>
        <FilterDatePicker
          value={catFilterSelected.optInDateFrom[0]}
          data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell5-DatePicker1'
          onChange={value =>
            dispatch(
              selectCatJournalFilterItem('optInDateFrom', value, 'replace')
            )
          }
          maxDate={
            catFilterSelected.optInDateTo[0]
              ? moment(catFilterSelected.optInDateTo[0])
                .subtract(1, 'day')
                .toDate()
              : undefined
          }
        />
        <Box
          sx={{ lineHeight: '28px' }}
          data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell5-Box2'
        >
            To
        </Box>
        <FilterDatePicker
          value={catFilterSelected.optInDateTo[0]}
          data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell5-DatePicker2'
          onChange={value =>
            dispatch(
              selectCatJournalFilterItem('optInDateTo', value, 'replace')
            )
          }
          minDate={
            catFilterSelected.optInDateFrom[0]
              ? moment(catFilterSelected.optInDateFrom[0])
                .add(1, 'day')
                .toDate()
              : undefined
          }
        /> */}

      </FilterTableCell>
      {/* optin reason*/}
      <FilterTableCell data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell15'></FilterTableCell>

      {/* output date */ }
      <FilterTableCell data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell6'>
        <DateRangePicker
          fromDate={catFilterSelected.optOutDateFrom[0]}
          toDate={catFilterSelected.optOutDateTo[0]}
          handleDateChange={(f, t) => {
            dispatch(
              selectCatJournalFilterItem('optOutDateFrom', f, 'replace')
            );
            dispatch(
              selectCatJournalFilterItem('optOutDateTo', t, 'replace')
            );
          }}
          maxDate={new Date}
          innerBorder={'1px solid #DFE4E8'}
          border={'1px solid #FFFFFF'}
          backgroundColor='#FFFFFF'
          iconPaddingRight={0}
          type={'optOut'}
        />
        {/*<Stack direction='row' spacing={1}>
          <Box
            sx={{ lineHeight: '28px' }}
            data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell6-Box1'
          >
            From
          </Box>
          <FilterDatePicker
            value={catFilterSelected.optOutDateFrom[0]}
            data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell6-DatePicker1'
            onChange={value =>
              dispatch(
                selectCatJournalFilterItem('optOutDateFrom', value, 'replace')
              )
            }
            maxDate={
              catFilterSelected.optOutDateTo[0]
                ? moment(catFilterSelected.optOutDateTo[0])
                  .subtract(1, 'day')
                  .toDate()
                : undefined
            }
          />
          <Box
            sx={{ lineHeight: '28px' }}
            data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell6-Box2'
          >
            To
          </Box>
          <FilterDatePicker
            value={catFilterSelected.optOutDateTo[0]}
            data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell6-DatePicker2'
            onChange={value =>
              dispatch(
                selectCatJournalFilterItem('optOutDateTo', value, 'replace')
              )
            }
            minDate={
              catFilterSelected.optOutDateFrom[0]
                ? moment(catFilterSelected.optOutDateFrom[0])
                  .add(1, 'day')
                  .toDate()
                : undefined
            }
          />
          </Stack>*/}
      </FilterTableCell>
      {/* optout reason */}
      <FilterTableCell data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell16'></FilterTableCell>
      
      {/* last update time */}
      <FilterTableCell data-selenium-id='CATJournal_Overview-Table-SearchRow-FilterTableCell18'></FilterTableCell>
    </TableRow>
  );
}

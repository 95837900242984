import { useState, useEffect, useMemo, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Box } from '@mui/material';
import styles from './index.module.scss';
import CloseIcon from '@/assets/CloseIcon.svg';
import { SingleSelect } from '@/components/Chart/ChartSelectors';
import ReportSaveMenuButton from '@/components//Chart/chartButton';
import { ReportDownloadMenuButton } from '@/components/Chart/chartButton';
import ChartArticleOverview from './Chart';
import NoDataPage from '@/components/Chart/NoDataPage';
import LoadingPage from '@/components/Chart/LoadingPage';
import ErrorPage from '@/components/Chart/ErrorPage';
import useGetCategoryListHook from './hooks/useGetCategoryListHook';
import useGetChartsDataHook from './hooks';
import useGetDiagramStatusHook from './hooks/useGetDiagramStatusHook';
import { useDispatch, useSelector } from 'react-redux';
import { saveImageAsPng, saveImageAsJpg } from '@/pages/Report/chartUtils';
import {
  saveUserSelectedItem,
  GetArticleChartsExcel, getArticleOverviewExcelPost,
} from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import {
  BUS_DIV,
  GET, SUBJECT_GROUP,
  SUBMISSION_ACCEPTANCE_AND_PUBLICATION_PERFORMANCE_OF_INDIVIDUAL_BUSINESS_DIVISION
} from '../Constants/index.js';
import {
  LOADING,
  ERROR,
  VALID,
  NO_DATA,
  NO_SELECTION_FROM_DROPDOWN_MENU,
  NO_DISPLAYED_DATA,
} from '@/pages/Report/chartUtils/constants.js';
import { getDownloadFileName } from '@/pages/Report/utils/getDownloadFileName';

const ChartContainer = ({
  header,
  subHeader,
  cancelSelectedChart,
  selectDate,
  formatdate,
  hasAdditionalFilter = false,
  catagoryAttributeName,
  url,
  singleSelectListUrl,
  downloadUrl,
  downloadMethod = GET,
  tableDownloadTitle
}) => {
  const dispatch = useDispatch();

  const reduxListTitle = useMemo(() => {
    return `Article/${header}/${subHeader}/Dropdown Menu`;
  }, [header, subHeader]);

  /**
   * get menu dropdown values
   */
  const [itemList, loadingMenu, errorMenu] = useGetCategoryListHook(
    singleSelectListUrl,
    reduxListTitle,
    hasAdditionalFilter
  );

  const isEmptyList = useMemo(() => {
    // !this is important, since 2nd graph does not have a dropdown list
    return (
      (!itemList || (Array.isArray(itemList) && itemList.length === 0)) &&
      hasAdditionalFilter
    );
  }, [itemList]);

  //更新chart title
  const [selectedSG, setselectedSG] = useState('');

  //初始化selectedSG
  useEffect(() => {
    setselectedSG(!itemList ? '' : itemList[0]);
  }, [itemList]);

  //保存用户选择的SG或者BD
  const userSelectedSG = useSelector(state => {
    return state.ReportDiagram[reduxListTitle + '_selected'];
  });

  useEffect(() => {
    dispatch(saveUserSelectedItem(reduxListTitle + '_selected', selectedSG));
  }, [selectedSG, userSelectedSG]);

  useEffect(() => {
    setselectedSG(userSelectedSG);
  }, [header, subHeader]);

  const [chartTitle, setChartTitle] = useState('');
  useEffect(() => {
    if (
      header.indexOf(
        SUBMISSION_ACCEPTANCE_AND_PUBLICATION_PERFORMANCE_OF_INDIVIDUAL_BUSINESS_DIVISION
      ) >= 0 &&
      selectedSG
    ) {
      setChartTitle(
        `Submission, Acceptance and Publication Performance of ${selectedSG} in ${formatdate}`
      );
    } else {
      setChartTitle(
        `Submission, Acceptance and Publication Performance in ${formatdate}`
      );
    }
    // !this is important, when date change the title should change as well
    // you can figure out the flow of change, I may be wrong.
  }, [selectDate, selectedSG, header, subHeader, userSelectedSG]);

  // get data for selected business division
  let dateArray = selectDate === null ? ['--', '----'] : selectDate.split('-');

  let formattedSelectedDate = dateArray[0] + ('0' + dateArray[1]).slice(-2);

  const [barchartData, loading, error] = useGetChartsDataHook(
    formattedSelectedDate,
    catagoryAttributeName,
    url,
    hasAdditionalFilter,
    selectedSG
  );

  /**
   * check if there is no data for selected subject group / business division
   *
   * will disable dropdown list, download button and export button
   */
  const isEmptyData = useMemo(() => {
    return (
      !barchartData ||
      (Array.isArray(barchartData) && barchartData.length === 0)
    );
  }, [barchartData]);

  const alertLabel = subHeader;
  const { valid: digramStatus, info } = useGetDiagramStatusHook(
    barchartData,
    selectedSG,
    alertLabel,
    loadingMenu,
    errorMenu,
    loading,
    error,
    hasAdditionalFilter
  );

  const [chartWidth, setChartWidth] = useState('100%');
  // for chart download
  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);
  const downloadlink = useRef(); // ADDED

  // dependencies: Tab1, Tab2, Level, selected time,  
  const fileName = useMemo(() => {
    const tab1Name = tableDownloadTitle;
    const tab2Name = null;
    const level = selectedSG ? selectedSG : null;
    return getDownloadFileName(tab1Name, tab2Name, level, selectDate);
  }, [tableDownloadTitle, selectedSG, selectDate]);

  const downloadChart = () => {
    if (downloadMethod === GET) {
      dispatch(
          GetArticleChartsExcel(
              downloadUrl,
              selectedSG ? 'sg_bd' : 'bd',
              formattedSelectedDate,
              fileName
          )
      );
    } else {
      const paramList = barchartData.slice(1).map(arr => arr[0]);
      const requestBody = {
        selectedDate: selectDate,
        ['businessDivision']: catagoryAttributeName === BUS_DIV ? [selectedSG] :  paramList
      };
      dispatch(
          getArticleOverviewExcelPost(downloadUrl, requestBody, fileName)
      );
    }
  };

  const savePng = () => {
    saveImageAsPng(chartRef.current, fileName);
  };

  const saveJpg = () => {
    saveImageAsJpg(chartRef.current, fileName);
  };

  // listen to chart-container size change
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      resizeHandler(entries);
    });
    resizeObserver.observe(chartContainerRef.current);
    return () => {
      if (chartContainerRef.current) {
        resizeObserver.unobserve(chartContainerRef.current);
      }
    };
  }, []);

  function resizeHandler(entries) {
    const chartContainer = entries.find(
      entry => entry.target == chartContainerRef.current
    );
    const width = chartContainer?.target.clientWidth;
    setChartWidth(width);
  }

  return (
    <div className={styles['chart-container']} ref={chartContainerRef}>
      <Box className={styles['chart-header']}>
        <div className={styles['selectors']}>
          {hasAdditionalFilter && (
            <span id='SG-select'>
              <SingleSelect
                label={subHeader}
                alertLabel={alertLabel}
                options={itemList ? itemList : []}
                selectedItem={selectedSG}
                setSelectedItem={setselectedSG}
                disabled={isEmptyList}
              />
            </span>
          )}
        </div>
        <div className={styles['chart-titles']}>
          <h1 className={styles['chart-title']}>{chartTitle}</h1>
        </div>
        <div className={styles['buttons']}>
          <ReportDownloadMenuButton
            func={downloadChart}
            // disabled={isEmptyList || isEmptyData}
            disabled={isEmptyList || digramStatus !== VALID}
          />
          <ReportSaveMenuButton
            items={[
              {
                label: 'Save as PNG',
                operation: savePng,
              },
              {
                label: 'Save as JPG',
                operation: saveJpg,
              },
            ]}
            // disabled={isEmptyList || isEmptyData}
            disabled={isEmptyList || digramStatus !== VALID}
          />
          <button
            onClick={cancelSelectedChart}
            className={styles['button-close']}
          >
            <CloseIcon />
          </button>
        </div>
      </Box>

      {digramStatus === LOADING && <LoadingPage />}

      {digramStatus === ERROR && <ErrorPage />}

      {digramStatus === NO_DATA && (
        <NoDataPage
          title={`There is no data for any ${info}s on the selected date.`}
          subTitle={'Please choose a different date to view diagram.'}
        />
      )}

      {digramStatus === NO_SELECTION_FROM_DROPDOWN_MENU && (
        <NoDataPage title={`Please select at least one ${info}.`} />
      )}

      {digramStatus === VALID && (
        <>
          <Box className={styles['chart']} ref={chartRef}>
            <ChartArticleOverview
              isEmptyList={isEmptyList}
              isEmptyData={isEmptyData}
              disabled={isEmptyList || isEmptyData}
              alertLabel={alertLabel}
              barchartData={barchartData}
              width={chartWidth}
              hasZoom={true}
              hasTitle={false}
              catagoryAttributeName={catagoryAttributeName}
            />
          </Box>
          <Box className={styles['chart-download']} ref={chartRef}>
            <ChartArticleOverview
              isEmptyList={isEmptyList}
              isEmptyData={isEmptyData}
              disabled={isEmptyList || isEmptyData}
              alertLabel={alertLabel}
              barchartData={barchartData}
              width={chartWidth}
              hasZoom={false}
              hasTitle={true}
              title={chartTitle}
              catagoryAttributeName={catagoryAttributeName}
            />
          </Box>
          <a
            href='javascript:void(0)'
            download
            ref={downloadlink}
            className={styles['download-link']}
          >
            hidden download link
          </a>
        </>
      )}
    </div>
  );
};

ChartContainer.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  selectedMonth: PropTypes.string.isRequired,
  cancelSelectedChart: PropTypes.func.isRequired,
  showSubjectGroupsSelector: PropTypes.bool,
};

export default ChartContainer;

import { Box, Stack } from '@mui/material';
import { useUpdateEffect } from 'ahooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { clearTime } from '../../../../utils/commonUtils';
import CreateAndUpdateNumber from '../CommonComponents/CreateAndUpdateNumber';
import {
  CreateAndUpdateTitle,
  CreateAndUpdateLittleTitle,
} from '../CommonComponents/CreateAndUpdateTitle';
import CreateSIDatePickerBlock from '../CommonComponents/CreateSIDatePickerBlock';
import CreateSIInputBlock from '../CommonComponents/CreateSIInputBlock';
function PublicationDeatilsPage(props) {
  const {
    value,
    setValue,
    acceptArticle,
    NextMonitor,
    setErr,
    isNotVirtual,
    isOA,
    clickTime,
  } = props;
  const [volume, setVloume] = useState(value.volume);
  const [issue, setIssue] = useState(value.issue);
  const [issuePublicationLink, setIssuePublicationLink] = useState(
    value.issuePublicationLink
  );
  const [actualPublicationDate, setActualPublicationDate] = useState(
    value.actualPublicationDate
  );
  const [ooarticle, setOoArticle] = useState(value.ooarticle);
  const [oaarticle, setOaArticle] = useState(value.oaarticle);
  const [waivernum, setWaivernum] = useState(value.numberOfWaiversUsed);
  const [oaarticleOutRangeErr, setOaArticleOutRangeErr] = useState(false);
  const [ooarticleOutRangeErr, setOoArticleOutRangeErr] = useState(false);
  const [waiverOutRangeErr, setWaiverOutRangeErr] = useState(false);
  const [relationErr, setRelationErr] = useState(false);
  const [waiverErr, setWaiverErr] = useState(false);
  const patternLink =
    /^(https?:\/\/){0,1}(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/;
  const [isClick, setIsClick] = useState(false);
  const [first, setFirst] = useState(true);

  const selector = state => {
    return {
      configInfo: state.SystemConfig.systemConfig,
    };
  };
  const { configInfo } = useSelector(selector);

  const maxNumber =
    configInfo === null ? 1000 : configInfo.eachSiArticlesMaxNumber;

  const isEmpty = value => {
    return (
      value === '' ||
      value === [] ||
      value === null ||
      value === undefined ||
      value === {} ||
      // 防止输入全为空格的情况
      (typeof value === 'string' && !value.trim().length)
    );
  };

  const isError = () => {
    return (
      (isNotVirtual && isEmpty(volume)) ||
      (isNotVirtual && isEmpty(issue)) ||
      isEmpty(issuePublicationLink) ||
      isEmpty(actualPublicationDate) ||
      isEmpty(ooarticle) ||
      isEmpty(oaarticle) ||
      (isEmpty(waivernum) && isOA) ||
      oaarticleOutRangeErr ||
      ooarticleOutRangeErr ||
      relationErr ||
      waiverErr ||
      waiverOutRangeErr ||
      !patternLink.test(issuePublicationLink)
    );
  };

  const parseInt = value => {
    return isEmpty(value) ? 0 : Number(value);
  };

  useEffect(() => {
    if (!first) {
      setIsClick(true);
    } else {
      setFirst(false);
    }
  }, [NextMonitor]);

  useEffect(() => {
    setValue({
      volume: volume,
      issue: issue,
      issuePublicationLink: issuePublicationLink,
      actualPublicationDate: actualPublicationDate,
      ooarticle: ooarticle,
      oaarticle: oaarticle,
      numberOfWaiversUsed: waivernum,
    });
  }, [
    volume,
    issue,
    issuePublicationLink,
    actualPublicationDate,
    ooarticle,
    oaarticle,
    waivernum,
  ]);

  useEffect(() => {
    setRelationErr(
      parseInt(ooarticle) + parseInt(oaarticle) > parseInt(acceptArticle)
    );
  }, [ooarticle, oaarticle]);

  useEffect(() => {
    setWaiverErr(parseInt(waivernum) > parseInt(oaarticle));
  }, [waivernum, oaarticle]);

  useEffect(() => {
    setErr(isError());
    if (isClick && !isError()) setIsClick(false);
  });

  useEffect(() => {
    setOaArticleOutRangeErr(oaarticle < 0 || oaarticle > maxNumber);
    setOoArticleOutRangeErr(ooarticle < 0 || ooarticle > maxNumber);
    setWaiverOutRangeErr(waivernum < 0 || waivernum > maxNumber);
  }, []);

  // 记录错误信息的Id
  const recoredError = () => {
    if (isNotVirtual && isNotVirtual && isEmpty(volume)) {
      return 'Volume#';
    } else if (isNotVirtual && isEmpty(issue)) {
      return 'Issue#';
    } else if (
      isEmpty(issuePublicationLink) ||
      !patternLink.test(issuePublicationLink)
    ) {
      return 'IssuePublicationLink';
    } else if (isEmpty(actualPublicationDate)) {
      return 'actualPublicationDate';
    } else if (
      oaarticleOutRangeErr ||
      isEmpty(oaarticle) ||
      relationErr ||
      oaarticleOutRangeErr
    ) {
      return 'NumberOfOAarticles';
    } else if (
      ooarticleOutRangeErr ||
      isEmpty(ooarticle) ||
      relationErr ||
      ooarticleOutRangeErr
    ) {
      return 'NumberOfOOArticles';
    } else if (
      isOA &&
      (isEmpty(waivernum) || waiverErr || waiverOutRangeErr || waiverErr)
    ) {
      return 'updateWaiversUsed';
    }
  };

  // useUpdateEffect 初次进入页面不会渲染 ， 出错的话再进行定位，先定位到顶部，在定位到屏幕中间
  useUpdateEffect(() => {
    if (isError()) {
      let currentDOM = document.getElementById('' + recoredError());
      if (currentDOM) {
        setTimeout(() => {
          window.scrollTo({ left: 0, top: 0, behavior: 'instant' });
          window.scroll({
            left: 0,
            top:
              currentDOM.getBoundingClientRect().top -
              window.screen.availHeight / 2 +
              100,
            behavior: 'instant',
          });
        }, 200);
      }
    }
  }, [clickTime]);

  return (
    <Box
      data-selenium-id='SIPage_CreateAndUpdateSI_PublicationDetails-Box'
      // sx={{ marginTop: '5%' }}
    >
      <CreateAndUpdateTitle
        data-selenium-id='SIPage_CreateAndUpdateSI_PublicationDetails-Title'
        title='Update Stage to Published'
      />
      <CreateAndUpdateLittleTitle
        data-selenium-id='SIPage_CreateAndUpdateSI_PublicationDetails-LittleTitle'
        littleTitle='Publication Details'
      />
      <Stack spacing={2} direction='column'>
        {isNotVirtual && (
          <>
            <CreateSIInputBlock
              title='Volume#'
              required={isNotVirtual}
              id='createSIInputBlockVolume'
              data-selenium-id='SIPage_CreateAndUpdateSI_PublicationDetails-CreateSIInputBlockVolume#'
              emptyErr={
                isNotVirtual &&
                isClick &&
                isEmpty(volume) &&
                !volume.trim().length
              }
              placeholder='Type Volume#'
              limitLength={101}
              setValue={setVloume}
              value={volume}
            />
            <CreateSIInputBlock
              title='Issue#'
              required={isNotVirtual}
              id='createSIInputBlockIssue'
              data-selenium-id='SIPage_CreateAndUpdateSI_PublicationDetails-CreateSIInputBlockIssue#'
              emptyErr={
                isNotVirtual &&
                isClick &&
                isEmpty(issue) &&
                !issue.trim().length
              }
              placeholder='Type Issue#'
              limitLength={101}
              setValue={setIssue}
              value={issue}
            />
          </>
        )}

        <CreateSIInputBlock
          title='Issue Publication Link'
          required={true}
          id='createSIInputBlockIssuePublicationLink'
          data-selenium-id='SIPage_CreateAndUpdateSI_PublicationDetails-IssuePublicationLink'
          emptyErr={isClick && isEmpty(issuePublicationLink)}
          placeholder='Type Issue Publication Link'
          limitLength={1001}
          setValue={setIssuePublicationLink}
          value={issuePublicationLink}
          error={
            !isEmpty(issuePublicationLink) &&
            !patternLink.test(issuePublicationLink)
          }
          helperText='This is an invalid Link!'
        />
        <CreateSIDatePickerBlock
          id='createSIDatePickerBlockActualPublicationDate'
          data-selenium-id='SIPage_CreateAndUpdateSI_PublicationDetails-ActualPublicationDate'
          title='Actual Publication Date'
          required={true}
          placeholder='YYYY-MM-DD'
          emptyErr={isClick && isEmpty(actualPublicationDate)}
          value={actualPublicationDate}
          handleChange={newValue =>
            setActualPublicationDate((newValue))
          }
        />
        <CreateAndUpdateNumber
          maxNumber={maxNumber}
          minNumber={0}
          exSpecialChar
          value={oaarticle}
          setValue={setOaArticle}
          id='createAndUpdateNumberNumberOfOAarticles'
          data-selenium-id='SIPage_CreateAndUpdateSI_PublicationDetails-NumberOfOAarticles'
          title='Number of OA Articles'
          required={true}
          setOutRangeErr={setOaArticleOutRangeErr}
          emptyErr={isClick && isEmpty(oaarticle)}
          error={relationErr}
          helperText=''
          outRangeErr={oaarticleOutRangeErr}
        />
        <CreateAndUpdateNumber
          maxNumber={maxNumber}
          minNumber={0}
          exSpecialChar
          value={ooarticle}
          setValue={setOoArticle}
          id='createAndUpdateNumberNumberOfOOarticles'
          data-selenium-id='SIPage_CreateAndUpdateSI_PublicationDetails-NumberOfOOArticles'
          title='Number of OO Articles'
          required={true}
          setOutRangeErr={setOoArticleOutRangeErr}
          emptyErr={isClick && isEmpty(ooarticle)}
          error={relationErr}
          helperText='The sum of OO Articles and OA Articles (Publication section) should be less than or equal to Number of Articles Accepted'
          outRangeErr={ooarticleOutRangeErr}
        />

        {isOA ? (
          <CreateAndUpdateNumber
            maxNumber={maxNumber}
            minNumber={0}
            exSpecialChar
            value={waivernum}
            setValue={setWaivernum}
            id='createAndUpdateNumberWaiversUsed'
            data-selenium-id='SIPage_CreateAndUpdateSI_PublicationDetails-UpdateWaiversUsed'
            title='Waiver(s) Used'
            required={true}
            setOutRangeErr={setWaiverOutRangeErr}
            error={waiverErr}
            emptyErr={isClick && isEmpty(waivernum)}
            helperText='Number of Waiver(s) Used should be ≤ number of OA Articles.'
            outRangeErr={waiverOutRangeErr}
          />
        ) : null}
      </Stack>
    </Box>
  );
}
export default PublicationDeatilsPage;

import { Drawer, Box, Backdrop } from '@mui/material';
import Overview from './CreatGEPage';
import { useEffect } from 'react';

export default function NewCreateGEPage(props) {
  const { open, handleCreateGEPageClose, isLead } = props;

  useEffect(() => {
    if (open) {
      document.body.classList.add('hide-scroll');
    } else {
      document.body.classList.remove('hide-scroll');
    }
  }, [open]);

  return (
    <Box
      sx={{
        width: '100%',
      }}
      data-selenium-id='GEPage_Overview-anotherNewFilterBox'
    >
      <Backdrop
        id='SIPageOverviewAnotherNewFilterDrawerBackdrop'
        data-selenium-id={`GEPage_Overview-anotherNewFilterDrawerBackdrop`}
        sx={{
          zIndex: 1400,
          backdropFilter: 'blur(4px)',
          background: '#113D9580',
        }}
        open={open}
        backdropFilter='blur(4px)'
      ></Backdrop>
      <Drawer
        id='SIPageOverviewAnotherNewFilterDrawer'
        variant='persistent'
        anchor='right'
        // scrollY='auto'
        open={open}
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: '924px',
            zIndex: 1400,
            backgroundColor: '#FFFFFF',
          },
        }}
        data-selenium-id={`GEPage_Overview-anotherNewFilterDrawer`}
      >
        <Overview {...props}></Overview>
      </Drawer>
    </Box>
  );
}

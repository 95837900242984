import {
  Table,
  TableContainer,
  TableRow,
  TableBody,
  Box,
  tableCellClasses,
  Paper,
  TableCell,
  Link,
  Tooltip,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { NewTableHeader, otherCell } from './NewTableHeader';
import '../index.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setSavePDNumberError, setCurrentRow } from '@/actions/SIMT-SI/SpecialIssue/SIAction';
import {
  formatDateString,
  turnDateToUTC,
  getUTCZeroTimeOffset,
  backMatchUTCTime,
  handleLinkWithoutProtocol,
  patternLink,
} from '@/utils/commonUtils';
import { SET_CURRENT_ROW } from '@/actions/SIMT-SI/SpecialIssue/SIAction';
import SIDetailsCalendarIcon from '@/assets/SIDetailsCalendarIcon.svg';
import { DatePicker } from '@/components/DateRangePicker/DatePicker';
import * as DateFnsUtils from 'date-fns';

const EDIT_TYPE = 'EDIT';
const ADD_TYPE = 'ADD';

const StyledTableCell = styled(TableCell)(props => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E8F2',
    color: '#505050',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '0.01em',
  },
  [`&.${tableCellClasses.root}`]: {
    ...props.cellStyle,
    height: '44px',
    padding: 0,
  },
}));

const CommonInput = styled(props => <TextField {...props} />)({
  '.MuiInputBase-input': {
    padding: '2px 12px 2px 12px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    letterSpacing: '0.01em',
    color: '#262E35',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
});

const EditableDateCell = props => {
  /**
   * 由于日期输入控件比较特殊，因此单列出来单独处理，这是EditableCell的一个特殊类型
   * 可编辑的日期单元格
   */
  const {
    handleDateClick,
    value,
    handlePublicationDate,
    handlePublicationDateAccept,
    item,
    width,
    setSelected,
    setIsEditingValue,
    index,
  } = props;

  const [dateValue, setDateValue] = useState(value);

  useEffect(() => {
    return () => {
      setSelected(false);
      setIsEditingValue(false);
    };
  }, []);

  const dispatch = useDispatch();

  const handlePublicationDateOnFocus = () => {
    dispatch({ type: 'SET_DATECELL', data: index });
  };

  const handlePublicationDateInput = e => {
    // console.log(e.target.value)
    setDateValue(e.target.value);
    let pattern =
      /^(?:(?!0000)[0-9]{4}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[0-9]{2}(?:0[48]|[2468][048]|[13579][26])|(?:0[48]|[2468][048]|[13579][26])00)-02-29)$/;
    let regExp = new RegExp(pattern);
    // console.log(regExp.test(e.target.value))
    if (regExp.test(e.target.value)) {
      handlePublicationDate(new Date(e.target.value));
    }
  };

  return (
    <Box
      className='dateClass'
      onClick={handleDateClick}
      data-selenium-id='SIDetail_Editable-ContainerBox'
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        data-selenium-id='SIDetail_Editable-LocalizetionProvider'
      >
        <DesktopDatePicker
          value={value}
          onChange={handlePublicationDate}
          onAccept={handlePublicationDateAccept}
          inputFormat='yyyy-MM-dd'
          mask='____-__-__'
          renderInput={params => (
            <TextField
              id='editableDateClass'
              data-selenium-id='SIDetail_Editable-DateClass'
              style={{
                textAlign: item.align,
                width: width,
                height: '24px',
              }}
              className='editingInput'
              autoFocus
              onFocus={handlePublicationDateOnFocus}
              autoComplete
              variant='outlined'
              size='small'
              {...params}
              inputProps={{
                ...params.inputProps,
                value: dateValue,
                onChange: handlePublicationDateInput,
              }}
            />
          )}
          data-selenium-id='SIDetail_Editable-DesktopDatePicker'
        />
      </LocalizationProvider>
    </Box>
  );
};

const EditableTableCell = props => {
  /**
   * 可编辑列（EditableRow）中的一个单元格————可编辑单元格
   */
  const {
    title,
    item,
    index,
    row,
    length,
    isEditing,
    isAdding,
    cellType,
    type,
    handleInfoChange,
    handleInfoAdd,
    setVolumeErrorFlag,
    setIssueErrorFlag,
    setLinkErrorFlag,
    setDateErrorFlag,
    setArticleNumErrorFlag,
    setWaiversNumErrorFlag,

    volumeErrorFlag,
    issueErrorFlag,
    linkErrorFlag,
    dateErrorFlag,
    articleNumErrorFlag,
    waiversNumErrorFlag,
    selected,
    setSelected,
    clickWhiteList,
    cellStyle,
    isHovered,

    setVolumeErrorColor,
    volumeErrorColor,
    setIssueErrorColor,
    issueErrorColor,
    dateErrorColor,
    setDateErrorColor,
    setArticleNumErrorColor,
    articleNumErrorColor,
    linkErrorColor,
    setLinkErrorColor,
  } = props;

  //输入框类型
  const Type_PublicationDate = 'PUBLICATIONDATE';
  const Type_Text = 'TEXT'; //单行输入框
  const Type_PDNumber = 'PDNUMBER';
  const Type_Link = 'LINK';
  const Type_Volume = 'VOLUME';
  const Type_Volume_V = 'VOLUME_V';
  const Type_Issue = 'ISSUE';
  const Type_Issue_V = 'ISSUE_V';

  const patternNumber = /^-?[0-9]*$/;

  const [value, setValue] = useState('');
  const [isEditingValue, setIsEditingValue] = useState(false);
  const [isShadow, setIsShadow] = useState(false);

  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      configInfo: state.SystemConfig.systemConfig,
      clickOutOfDate: state.SI.clickOutOfDate,
      currentDateCell: state.SI.currentDateCell,
      inProgressArticles: state.SI.siInprogress,
    };
  };
  const {
    open,
    configInfo,
    clickOutOfDate,
    currentDateCell,
    inProgressArticles,
  } = useSelector(selector);

  const dispatch = useDispatch();

  useEffect(() => {
    setValue(checkAndTransformForDate(item.id, row[item.id]));
  }, [selected, isAdding]);

  useEffect(() => {
    if (
      item.id == 'inProgress' &&
      row['dataSource'] &&
      row['dataSource'] == 'EEO'
    ) {
      setValue(inProgressArticles);
    }
  }, [inProgressArticles]);

  const maxSIArticles =
    configInfo === null ? 1000 : configInfo.eachSiArticlesMaxNumber;

  /**
   * 编辑状态时，点击单元格变成输入状态，同时背景颜色变蓝
   */
  const handleEditingCellClick = () => {
    if ((isEditing && type === EDIT_TYPE) || (isAdding && type === ADD_TYPE)) {
      if (!clickWhiteList || !clickWhiteList.includes(item.id)) {
        setIsEditingValue(true);
      }
    }
  };

  /**
   * 若数据为日期，则需要将日期转化为 YYYY-mm-dd 的格式
   * @param {string} id 标识当前数据所在列的id，例如在PublicationDetails中可能为1)volume；2)issue；3)actualPublicationDate；4)numberOfOaArticles等等
   * @param {string} data 单元格中的真实数据
   * @returns 处理后的数据
   */
  const checkAndTransformForDate = (id, data) => {
    if (id === 'actualPublicationDate') {
      return formatDateString(data);
    } else {
      return data;
    }
  };

  const checkAndTransformForDateAndLink = (id, data) => {
    if (
      id === 'inProgress' &&
      row['dataSource'] &&
      row['dataSource'] === 'EEO'
    ) {
      return inProgressArticles;
    }
    if (id === 'actualPublicationDate') {
      // return formatDateString(data)
      const pub_date = formatDateString(data);
      return (
        <Box data-selenium-id='SIDetail_Editable-PubDateBox'>
          <Tooltip
            title={pub_date}
            placement='bottom'
            data-selenium-id='SIDetail_Editable-PubDateTooltip'
          >
            {pub_date}
          </Tooltip>
        </Box>
      );
    } else if (id === 'issuePublicationLink') {
      return (
        <Tooltip
          title={data}
          data-selenium-id='SIDetail_Editable-DataTooltip'
          placement='bottom'
        >
          <Box>
            <Link
              href={handleLinkWithoutProtocol(data)}
              color='#154AB6'
              target='_blank'
              sx={{ textDecoration: 'none', fontWeight: 600 }}
              data-selenium-id='SIDetail_Editable-DataLink'
            >
              {data}
            </Link>
          </Box>
        </Tooltip>
      );
    } else {
      if (data !== 0 && (!data || data === '') && !isAdding) {
        return '-';
      }
      if (id === 'volume' || id === 'issue') {
        return (
          <Tooltip
            title={data}
            placement='bottom-start'
            data-selenium-id='SIDetail_Editable-DataTooltip'
          >
            <Box data-selenium-id='SIDetail_Editable-DataBox'>{data}</Box>
          </Tooltip>
        );
      }
      return data;
    }
  };

  /**
   * 当编辑状态下的值改变后进行的操作，一般是更新数据更新之后的内容
   * @param {*} value 改变后的值
   */
  const handleInputChange = value => {
    if (type === EDIT_TYPE) {
      handleInfoChange(row['id'], item.id, value);
    } else {
      handleInfoAdd(item.id, value);
    }
  };

  /**
   * 该方法用于点击叉号×，从输入状态恢复本来的文本值，
   * @param {*} v 原来的文本值
   * @returns
   */
  const checkClose = v => {
    if (isEditing) {
      return value || value === '0' || value === 0 ? value : '-';
    } else if (isAdding) {
      return value || value === '0' || value === 0 ? value : '';
    } else {
      return v;
    }
  };

  //以下内容为各个输入组件发生1）值改变；2）失去焦点；3）...所进行的操作
  const handlePublicationDate = value => {
    setValue(
      formatDateString(
        turnDateToUTC(backMatchUTCTime(getUTCZeroTimeOffset(value)))
      )
    );
    handleInputChange(
      turnDateToUTC(backMatchUTCTime(getUTCZeroTimeOffset(value)))
    );
    if (moment(value).format('YYYY-MM-DD') === 'Invalid date') {
      setDateErrorFlag(true);
      setDateErrorColor(true);
    } else {
      setDateErrorFlag(false);
      setDateErrorColor(false);
    }
  };

  const handleLinkChange = e => {
    if (e.target.value === '') {
      dispatch({ type: 'LINK_ERROR', data: 1 });
      setLinkErrorFlag(true);
      setLinkErrorColor(true);
    } else if (!patternLink.test(e.target.value)) {
      dispatch({ type: 'LINK_ERROR', data: 2 });
      setLinkErrorFlag(true);
      setLinkErrorColor(true);
    } else if (e.target.value.length > 100) {
      dispatch({ type: 'LINK_ERROR', data: 3 });
      setLinkErrorFlag(true);
      setLinkErrorColor(true);
    } else {
      setLinkErrorFlag(false);
      setLinkErrorColor(false);
    }
    setValue(e.target.value);
    handleInputChange(e.target.value);
  };

  const handleLinkOnBlur = e => {
    setIsShadow(false);
    if (e.target.value === '') {
      dispatch({ type: 'LINK_ERROR', data: 1 });
      setLinkErrorFlag(true);
      setLinkErrorColor(true);
    } else if (!patternLink.test(e.target.value)) {
      dispatch({ type: 'LINK_ERROR', data: 2 });
      setLinkErrorFlag(true);
      setLinkErrorColor(true);
    } else if (e.target.value.length > 100) {
      dispatch({ type: 'LINK_ERROR', data: 3 });
      setLinkErrorFlag(true);
      setLinkErrorColor(true);
    } else {
      setLinkErrorFlag(false);
      setLinkErrorColor(false);
    }
    setValue(e.target.value);
  };

  const handleVolumeChange = e => {
    setValue(e.target.value);
    handleInputChange(e.target.value);
    if (e.target.value === '') {
      setVolumeErrorFlag(true);
      setVolumeErrorColor(true);
    } else {
      setVolumeErrorFlag(false);
      setVolumeErrorColor(false);
    }
  };

  const handleVolumeOnBlur = e => {
    setIsShadow(false);
    if (e.target.value === '') {
      setVolumeErrorFlag(true);
      setVolumeErrorColor(true);
    } else {
      setVolumeErrorFlag(false);
      setVolumeErrorColor(false);
    }
  };

  const handleIssueChange = e => {
    setValue(e.target.value);
    handleInputChange(e.target.value);
    if (e.target.value === '') {
      setIssueErrorFlag(true);
      setIssueErrorColor(true);
    } else {
      setIssueErrorFlag(false);
      setIssueErrorColor(false);
    }
  };

  const handleIssueOnBlur = e => {
    setIsShadow(false);
    if (e.target.value === '') {
      setIssueErrorFlag(true);
      setIssueErrorColor(true);
    } else {
      setIssueErrorFlag(false);
      setIssueErrorColor(false);
    }
  };

  const handlePDNumberChange = e => {
    if (e.target.value === '') {
      setValue(0);
      handleInputChange(0);
    } else if (patternNumber.test(e.target.value)) {
      let n = Number(e.target.value);
      if (n === 0 && e.target.value.length > 1) {
        e.target.value = 0;
      } else if (n > 0 && e.target.value[0] === '0') {
        e.target.value = n;
      }
      if (e.target.value >= 0 && e.target.value < maxSIArticles + 1) {
        setValue(n);
        handleInputChange(e.target.value);
      }
    }
  };

  const [isFocused, setIsFocused] = useState(false);

  const handlePDNumberOnBlur = e => {
    setIsFocused(false);
    setIsShadow(false);
  };
  const handlePDNumberOnFocus = e => {
    setIsFocused(true);
    setIsShadow(true);
    if (e.target.value === '') {
      setValue(0);
      handleInputChange(0);
    }
    dispatch(setSavePDNumberError(false));
  };

  const inputStyle = {
    borderWidth: '1px',
    borderStyle: 'solid',
  };

  const handleInputOnChange = e => {
    setValue(e.target.value);
    handleInputChange(e.target.value);
  };

  const handleInputOnFocus = () => {
    // setIsShadow(true);
  };

  const handleInputOnBlur = () => {
    // setIsShadow(false);
  };

  /**
   * cellType为单元格类型，具体可见 /SIDetailPage/index.js中的publicationDetailType ，此为Publication Details表格对应的类型
   * 若为SubmissionOverview，则“可能”类型全部为PDNUMBER
   * @returns 根据类型返回对应的单元格
   */
  const getCorrespondingCell = () => {
    switch (cellType) {
      case Type_PublicationDate:
        return (
          <DatePicker
            handleDateChange={f => {
              setValue(f);
              handlePublicationDate(
                DateFnsUtils.parse(f, 'yyyy-MM-dd', new Date())
              );
            }}
            value={value}
            width='223px'
            minHeight='36px'
            border={dateErrorColor ? '1px solid #EE5350' : '1px solid #DFE4E8'}
            innerBorder='0px'
            backgroundColor='#FFF'
            hoverInputColor='#AEC6F6'
            focusInputColor='#AEC6F6'
          />
        );
      case Type_Issue:
        return (
          <Box
            data-selenium-id='SIDetail_Editable-ContainerBox-TypeIssue'
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <input
              style={{
                borderColor: issueErrorColor ? '#EE5350' : '#DFE4E8',
                position: 'absolute',
                width: '100%',
                height: '100%',
                textAlign: 'left',
                ...inputStyle,
              }}
              className='editingInput'
              value={value}
              variant='outlined'
              size='small'
              onChange={handleIssueChange}
              onBlur={handleIssueOnBlur}
              onFocus={handleInputOnFocus}
              autoComplete='off'
              data-selenium-id='SIDetail_Editable-Input'
            />
          </Box>
        );
      case Type_Issue_V:
        return (
          <Box
            data-selenium-id='SIDetail_Editable-ContainerBox-TypeIssueV'
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <input
              style={{
                borderColor: issueErrorColor ? '#EE5350' : '#DFE4E8',
                position: 'absolute',
                width: '100%',
                height: '100%',
                textAlign: 'left',
                ...inputStyle,
              }}
              className='editingInput'
              value={value}
              variant='outlined'
              size='small'
              onChange={handleInputOnChange}
              onFocus={handleInputOnFocus}
              onBlur={handleInputOnBlur}
              autoComplete='off'
              data-selenium-id='SIDetail_Editable-Input'
            />
          </Box>
        );
      case Type_Volume:
        return (
          <Box
            data-selenium-id='SIDetail_Editable-ContainerBox-TypeVolume'
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <input
              style={{
                borderColor: volumeErrorColor ? '#EE5350' : '#DFE4E8',
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                textAlign: 'left',
                ...inputStyle,
              }}
              className='editingInput'
              value={value}
              variant='outlined'
              id='standard-size-small'
              data-selenium-id='SIDetail_Editable-Input'
              size='small'
              onChange={handleVolumeChange}
              onBlur={handleVolumeOnBlur}
              onFocus={handleInputOnFocus}
              autoComplete='off'
            />
          </Box>
        );
      case Type_Volume_V:
        return (
          <Box
            data-selenium-id='SIDetail_Editable-ContainerBox-TypeVolume'
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <input
              style={{
                borderColor: volumeErrorColor ? '#EE5350' : '#DFE4E8',
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                textAlign: 'left',
                ...inputStyle,
              }}
              className='editingInput'
              value={value}
              variant='outlined'
              id='standard-size-small'
              data-selenium-id='SIDetail_Editable-Input'
              size='small'
              onChange={handleInputOnChange}
              onFocus={handleInputOnFocus}
              onBlur={handleInputOnBlur}
              autoComplete='off'
            />
          </Box>
        );
      case Type_Link:
        return (
          <Box
            data-selenium-id='SIDetail_Editable-ContainerBox'
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <input
              style={{
                borderColor: linkErrorColor ? '#EE5350' : '#DFE4E8',
                position: 'absolute',
                width: '100%',
                height: '100%',
                ...inputStyle,
              }}
              className='editingInput'
              value={value}
              variant='outlined'
              size='small'
              onChange={handleLinkChange}
              onBlur={handleLinkOnBlur}
              onFocus={handleInputOnFocus}
              autoComplete='off'
              data-selenium-id='SIDetail_Editable-Input'
            />
          </Box>
        );
      case Type_PDNumber:
        return (
          <Box
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <input
              style={{
                width: '100%',
                height: '100%',
                textAlign: 'center',
                ...inputStyle,
                borderColor: articleNumErrorFlag ? '#EE5350' : '#DFE4E8',
                transition: 'border-color 0.3s ease', // Add transition effect for the border color change
              }}
              value={value}
              onChange={handlePDNumberChange}
              onBlur={handlePDNumberOnBlur}
              onFocus={handlePDNumberOnFocus}
              className='editingInput'
              size='small'
              autoComplete='off'
              data-selenium-id='SIDetail_Editable-Input'
            />
          </Box>
        );
    }
  };

  /**
   * 用于判断日期输入框是否显示
   */
  const isDateShow = () => {
    return cellType !== 'PUBLICATIONDATE' || !clickOutOfDate;
  };

  const isSubmissionOverviewCellShow = () => {
    return item.id !== 'dataSource' && item.id !== 'inProgress';
  };

  return (
    <StyledTableCell
      sx={{
        minWidth: item.minWidth,
        maxWidth: item.maxWidth,
        wordWrap: 'break-word',
        color: '#262E35',
      }}
      align={item.align}
      index={index}
      length={length}
      className={cellType === 'PUBLICATIONDATE' ? 'dateClass' : ''}
      onClick={handleEditingCellClick}
      cellStyle={cellStyle}
      data-selenium-id='SIDetail_Editable-TableCell'
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          verticalAlign: 'middle',
          wordWrap: 'break-word',
          pt: '4px', // 上内边距设置为 4 像素
          pb: '4px', // 下内边距设置为 4 像素
          pl: '4px', // 左内边距设置为 4 像素
          pr: '4px', // 右内边距设置为 4 像素
          textAlign: item.align,
        }}
        style={{
          backgroundColor: selected && isHovered ? '#E8EDFB' : 'inherit',
        }}
        align={item.align}
        data-selenium-id='SIDetail_Editable-ContainerBox'
      >
        {/* (isEditing || isAdding)保证在编辑时间时，点击close按钮Cell会复原 */}
        {isSubmissionOverviewCellShow() &&
        (selected || (type === ADD_TYPE && isAdding)) ? (
          //  && (isEditing || isAdding)
          getCorrespondingCell()
        ) : (
          <Box
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              paddingTop: '8px',
              paddingLeft:
                index === 0 || index === 1 ? '20px' : otherCell(item),
              paddingBottom: '8px',
              gap: '12px',
            }}
            data-selenium-id='SIDetail_Editable-Box'
          >
            {checkClose(checkAndTransformForDateAndLink(item.id, row[item.id]))}
          </Box>
        )}
      </Box>
    </StyledTableCell>
  );
};

function EditableRow(props) {
  /**
   * 可编辑的表（Editable）中的一行—————可编辑行
   * 创建该组件的目的是为了统一管理每一行的背景颜色
   */
  const {
    title,
    row,
    index,
    typeList,
    TableHeads,
    isEditing,
    isAdding,
    type,
    handleInfoChange,
    handleInfoAdd,
    setVolumeErrorFlag,
    setIssueErrorFlag,
    setLinkErrorFlag,
    setDateErrorFlag,
    setArticleNumErrorFlag,
    setWaiversNumErrorFlag,

    volumeErrorFlag,
    issueErrorFlag,
    linkErrorFlag,
    dateErrorFlag,
    articleNumErrorFlag,
    waiversNumErrorFlag,

    clickWhiteList,
    cellStyle,
    editError,
    handleCheckButtonClick,
    setEditInfo,
    hasError,
    extraInfo,
    isOA,
  } = props;

  /**
   * 该变量控制 编辑/新增 状态时每一行的背景颜色（a blue background color）
   * User Story原话：
   *      When a user clicks a cell, the cell becomes
   *      a shaded and rounded white rectangle with a blue
   *      background color for the corresponding row.
   */
  const [selected, setSelected] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [volumeErrorColor, setVolumeErrorColor] = useState(false);
  const [issueErrorColor, setIssueErrorColor] = useState(false);
  const [dateErrorColor, setDateErrorColor] = useState(false);
  const [linkErrorColor, setLinkErrorColor] = useState(false);
  const [articleNumErrorColor, setArticleNumErrorColor] = useState(false);

  const dispatch = useDispatch();

  const selector = state => {
    return {
      detailInfo: state.SI.detailInfo,
      currentRow: state.SI.currentRow,
    };
  };

  const { detailInfo, currentRow } = useSelector(selector);
  const userPermissions = localStorage.getItem('userPermissions');

  useEffect(() => {
    if (type === ADD_TYPE && isAdding) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [isAdding]);

  useEffect(() => {
    if (type !== ADD_TYPE && title === 'Publication Detail') {
      if (currentRow === index) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }
  }, [currentRow]);

  useEffect(() => {
    if (isEditing || isAdding) {
      setSelected(true);
    } else if (!isEditing) {
      setSelected(false);
    }
  }, [isEditing]);

  const handleEditClick = () => {
    let SUM = 0;
    for (let item of detailInfo.publicationDetails) {
      SUM = SUM + item['numberOfOoArticle'] * 1;
      SUM = SUM + item['numberOfOaArticle'] * 1;
    }
    if (SUM > extraInfo) {
      setArticleNumErrorFlag(true);
      setArticleNumErrorColor(true);
    } else {
      setArticleNumErrorFlag(false);
      setArticleNumErrorColor(false);
    }

    if (row.numberOfWaiversUsed === '' && isOA) {
      setWaiversNumErrorFlag(true);
    } else {
      setWaiversNumErrorFlag(false);
    }
    dispatch({ type: SET_CURRENT_ROW, data: index });
  };

  return (
    <TableRow
      key={index}
      data-selenium-id='SIDetail_Editable-TableRow'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        '&:hover': {
          background: '#E8EDFB',
        },
      }}
    >
      {TableHeads.map((item, index) => (
        <EditableTableCell
          title={title}
          //Cell的类型，例如（PDNUMBER/LINK/PUBLICATIONDATE/VOLUME/ISSUE）
          cellType={typeList[item.id]}
          //每一列的类型
          item={item}
          //该Cell在表格的第几列
          index={index}
          //列总长度
          length={TableHeads.length}
          //列所在行的数据
          row={row}
          //是否处于编辑状态
          isEditing={isEditing}
          //是否处于添加状态
          isAdding={isAdding}
          //该Cell所支持的编辑状态（EDIT或ADD）
          type={type}
          //Edit状态下修改一个Cell调用的函数
          handleInfoChange={handleInfoChange}
          //Add状态下修改一个Cell调用的函数
          handleInfoAdd={handleInfoAdd}
          //控制背景颜色的变量和方法
          selected={selected}
          setSelected={setSelected}
          isHovered={isHovered}
          cellStyle={cellStyle}
          //以下为控制一些错误信息的Function
          setVolumeErrorFlag={setVolumeErrorFlag}
          setIssueErrorFlag={setIssueErrorFlag}
          setLinkErrorFlag={setLinkErrorFlag}
          setDateErrorFlag={setDateErrorFlag}
          setArticleNumErrorFlag={setArticleNumErrorFlag}
          setWaiversNumErrorFlag={setWaiversNumErrorFlag}
          volumeErrorFlag={volumeErrorFlag}
          issueErrorFlag={issueErrorFlag}
          linkErrorFlag={linkErrorFlag}
          dateErrorFlag={dateErrorFlag}
          articleNumErrorFlag={articleNumErrorFlag}
          waiversNumErrorFlag={waiversNumErrorFlag}
          setVolumeErrorColor={setVolumeErrorColor}
          volumeErrorColor={volumeErrorColor}
          setIssueErrorColor={setIssueErrorColor}
          issueErrorColor={issueErrorColor}
          setDateErrorColor={setDateErrorColor}
          dateErrorColor={dateErrorColor}
          setArticleNumErrorColor={setArticleNumErrorColor}
          articleNumErrorColor={articleNumErrorColor}
          setLinkErrorColor={setLinkErrorColor}
          linkErrorColor={linkErrorColor}
          clickWhiteList={clickWhiteList}
          data-selenium-id='SIDetail_Editable-EditableTableCell'
        />
      ))}
    </TableRow>
  );
}

function Editable(props) {
  /**
   * 可编辑的表
   */

  //从父组件获取的属性
  const {
    title,
    id,
    typeList,
    TableHeads,
    TableData,
    isAdding = false,
    isEditing,
    handleInfoChange,
    handleInfoAdd,
    setVolumeErrorFlag,
    setIssueErrorFlag,
    setLinkErrorFlag,
    setDateErrorFlag,
    setArticleNumErrorFlag,
    setWaiversNumErrorFlag,

    volumeErrorFlag,
    issueErrorFlag,
    linkErrorFlag,
    dateErrorFlag,
    articleNumErrorFlag,
    waiversNumErrorFlag,
    clickWhiteList,
    cellStyle,
    handleCheckButtonClick,
    hasError,
    setEditInfo,
    extraInfo,
    isOA,
  } = props;

  /**
   * 空白行，用于Publication Details的新增栏，其他Block，例如Submission Overview（没有新增功能）可以忽略
   */
  const spaceRow = () => {
    let res = {};
    for (let h in TableHeads) {
      res[h.id] = '';
    }
    return res;
  };

  return (
    <Box sx={{ width: '100%' }} data-selenium-id='SIDetail_EditTableBox'>
      <TableContainer
        id={id}
        data-selenium-id={id}
        sx={{
          borderRadius: '8px 8px 8px 8px',
          borderWidth: '1px 1px 0px 1px',
          borderStyle: 'solid',
          borderColor: '#DFE4E8',
          boxShadow: 'none',
        }}
        component={Paper}
      >
        <Table
          stickyHeader
          aria-label='custom pagination table'
          sx={{
            minWidth: 500,
            letterSpacing: '0.01em',
            color: '#262E35',
          }}
          data-selenium-id='SIDetail_EditTable'
        >
          <NewTableHeader
            title={title}
            items={TableHeads}
            backgroundColor='#F1F3F5'
            color='#596A7C'
            CheckboxColor='wileyBlue2'
            letterSpacing='0.08em'
            data-selenium-id='SIDetail_EditTableHeader'
          />
          <TableBody data-selenium-id='SIDetail_EditTableBody'>
            {TableData.map((row, index) => (
              <EditableRow
                title={title}
                row={row}
                index={index}
                typeList={typeList}
                TableHeads={TableHeads}
                isEditing={isEditing}
                isAdding={isAdding}
                type={EDIT_TYPE}
                handleInfoChange={handleInfoChange}
                handleInfoAdd={handleInfoAdd}
                setVolumeErrorFlag={setVolumeErrorFlag}
                setIssueErrorFlag={setIssueErrorFlag}
                setLinkErrorFlag={setLinkErrorFlag}
                setDateErrorFlag={setDateErrorFlag}
                setArticleNumErrorFlag={setArticleNumErrorFlag}
                setWaiversNumErrorFlag={setWaiversNumErrorFlag}
                volumeErrorFlag={volumeErrorFlag}
                issueErrorFlag={issueErrorFlag}
                linkErrorFlag={linkErrorFlag}
                dateErrorFlag={dateErrorFlag}
                articleNumErrorFlag={articleNumErrorFlag}
                waiversNumErrorFlag={waiversNumErrorFlag}
                clickWhiteList={clickWhiteList}
                cellStyle={cellStyle}
                handleCheckButtonClick={handleCheckButtonClick}
                hasError={hasError}
                setEditInfo={setEditInfo}
                extraInfo={extraInfo}
                isOA={isOA}
                data-selenium-id='SIDetail_EditTableRow'
              />
            ))}
            {isAdding ? (
              <EditableRow
                row={spaceRow()}
                typeList={typeList}
                TableHeads={TableHeads}
                isEditing={isEditing}
                isAdding={isAdding}
                type={ADD_TYPE}
                handleInfoChange={handleInfoChange}
                handleInfoAdd={handleInfoAdd}
                setVolumeErrorFlag={setVolumeErrorFlag}
                setIssueErrorFlag={setIssueErrorFlag}
                setLinkErrorFlag={setLinkErrorFlag}
                setDateErrorFlag={setDateErrorFlag}
                setArticleNumErrorFlag={setArticleNumErrorFlag}
                setWaiversNumErrorFlag={setWaiversNumErrorFlag}
                volumeErrorFlag={volumeErrorFlag}
                issueErrorFlag={issueErrorFlag}
                linkErrorFlag={linkErrorFlag}
                dateErrorFlag={dateErrorFlag}
                articleNumErrorFlag={articleNumErrorFlag}
                waiversNumErrorFlag={waiversNumErrorFlag}
                clickWhiteList={clickWhiteList}
                cellStyle={cellStyle}
                hasError={hasError}
                extraInfo={extraInfo}
                isOA={isOA}
                data-selenium-id='SIDetail_EditTable-AddingRow'
              />
            ) : null}

            {TableData.length <= 0 ? (
              <TableRow
                id='siDetailPaegNoDataText'
                data-selenium-id='SIDetail_EditTable-PageNoDataRow'
                style={{ height: '44px' }}
              >
                <TableCell
                  colSpan={7}
                  sx={{
                    textAlign: 'center',
                    fontSize: '20px',
                    color: '#98A7B6',
                  }}
                  data-selenium-id='SIDetail_EditTable-PageNoDataCell'
                >
                  NO DATA
                </TableCell>
              </TableRow>
            ) : (
              <div></div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Editable;

/**
 * yueying
 * AnnualTrends
 */
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Tooltip,
  TableCell,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '../../../../components/DataTable';
import {
  StyledTableCellPurple,
  StyledTableCellDisPurple,
  StyledTableCellGreen,
  StyledTableCellDisGreen,
  StyledTableRow,
  StyledTableCellNormal,
  TwoColorTableRowPurple,
  TwoColorTableRowGreen,
  TableBox,
  ReportNoData,
  TableBox2
} from "../../ReportSpecificComponents/SpecialColorTableHeader";
import '../../../../App.css';
import { findLongestLineSubstring, measureWidth } from "../../../../utils/StringWidthMeasure";
import React from "react";
import { SimpleTooltip } from "../../../../componentsphase2/CustomTooltip";
import {
    GetAcquiredActiveLastActionDate,
    GetAnnualSubjectTableData
} from "@/actions/SIMT-Report/Report/ReportAcquiredAndActive";

export default function AnnualSubjectTable(props) {
  const { isOA } = props;
  const { selectMonthDate, AnnualTrendsSGDate } = useSelector(state => {
    return {
      // selectMonthDate: state.Report.AnnualTrendsDate,
      selectMonthDate: state.Report.selectDate,
      AnnualTrendsSGDate: state.Report.AnnualTrendsSubjectGroupData,
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    widthMap['subjectGroup'] = measureWidth(findLongestLineSubstring('Subject Group'), '18px Open Sans');

    for (let i = 0; i < itemTable.length; i++) {
      let item = itemTable[i];
      let len =  measureWidth(item.label, '18px Open Sans');
      for (let type of ['acquired','active','published']){
        widthMap[`${type}-${i}`] = len;
      }
    }

    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (AnnualTrendsSGDate && AnnualTrendsSGDate.length > 0){
      const maxMap = {};
      for (let i = 0; i < AnnualTrendsSGDate.length; i++) {
        let row = AnnualTrendsSGDate[i];
        let dataObj = {
          subjectGroup: row.sgAbbr ?? row.subjectGroup,

          'acquired-0':  row.two_fy_ago_acquired ?? '0',
          'acquired-1':  row.one_fy_ago_acquired ?? '0',
          'acquired-2':  row.current_fy_acquired ?? '0',
          'acquired-3':  row.acquired_ytd_1 ?? '0',
          'acquired-4':  row.acquired_ytd_2 ?? '0',

          'active-0':  row.two_fy_ago_active ?? '0',
          'active-1':  row.one_fy_ago_active ?? '0',
          'active-2':  row.current_fy_active ?? '0',
          'active-3':  row.active_ytd_1 ?? '0',
          'active-4':  row.active_ytd_2 ?? '0',

          'published-0':  row.two_fy_ago_pulished ?? '0',
          'published-1':  row.one_fy_ago_pulished ?? '0',
          'published-2':  row.current_fy_pulished ?? '0',
          'published-3':  row.pulished_ytd_1 ?? '0',
          'published-4':  row.pulished_ytd_2 ?? '0',
        };
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [AnnualTrendsSGDate]);

  function calcGroupWidth(type){
    let sum = 0;
    for (let i = 0; i < itemTable.length; i++) {
      sum += Math.max((columnWidthMap[`${type}-${i}`]??0), columnMinWidthMap[`${type}-${i}`]) + 50;
    }
    return sum;
  }


  const dispatch = useDispatch();
  const [itemTable, setitemTable] = useState([]);

  useEffect(() => {
    if (selectMonthDate != null && selectMonthDate != undefined) {
      var beforeYearHeader, afterYearHeader, currentYearHeader;
      currentYearHeader = Number(selectMonthDate.split('-')[0].slice(-2));
      beforeYearHeader = (
        '' +
        (Number(selectMonthDate.split('-')[0]) - 1)
      ).slice(-2);
      afterYearHeader = (
        '' +
        (Number(selectMonthDate.split('-')[0]) + 1)
      ).slice(-2);
      if (selectMonthDate.split('-')[1] < 5) {
        currentYearHeader -= 1;
        beforeYearHeader -= 1;
        afterYearHeader -= 1;
      }
      var itemsChanged = [
        {
          id: 'FY' + beforeYearHeader + '' + 'TD',
          label: 'FY' + beforeYearHeader + '' + 'TD',
          maxWidth: '50px',
          minWidth: '50px',
        },
        {
          id: 'FY' + currentYearHeader + '' + 'TD',
          label: 'FY' + currentYearHeader + '' + 'TD',
          maxWidth: '50px',
          minWidth: '50px',
        },
        {
          id: 'FY' + afterYearHeader + '' + '' + 'TD',
          label: 'FY' + afterYearHeader + '' + '' + 'TD',
          maxWidth: '50px',
          minWidth: '50px',
        },
        {
          id: 'FY' + currentYearHeader + '/FY' + beforeYearHeader,
          label: 'FY' + currentYearHeader + '/FY' + beforeYearHeader,
          maxWidth: '165px',
          minWidth: '43px',
        },
        {
          id: 'FYTwo' + afterYearHeader + '/FYTwo' + currentYearHeader,
          label: 'FY' + afterYearHeader + '/FY' + currentYearHeader,
          maxWidth: '165px',
          minWidth: '65px',
        },
      ];
      async function getData() {
        await dispatch(GetAnnualSubjectTableData(selectMonthDate, !isOA));
      }
      getData();
      dispatch(GetAcquiredActiveLastActionDate(selectMonthDate, false));
      setitemTable(itemsChanged);
    }
  }, [selectMonthDate, isOA]);

  //表头显示
  const displayPurpleHeader = (type) => {
    return itemTable.map((item, index) => {
      if (item.id.indexOf('/') != -1) {
        if (item.id.indexOf('FYTwo')) {
          return (
            <StyledTableCellDisPurple
              align='left'
              sx={{
                top: 42,
                // minWidth: item.minWidth,
                // maxWidth: item.maxWidth,
                width: `${Math.max((columnWidthMap[`${type}-${index}`]??0), columnMinWidthMap[`${type}-${index}`]) + 50 }px`,
              }}
              key={item.id}
              className='BottomBorder'
              style={{ 'padding-top': '5px', 'padding-bottom': '5px' }}
              data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-DisplayPurpleHeader-StyledTableCellDisPurple-${index}`}
            >
              {item.label}
              <br />
              TD(%)
            </StyledTableCellDisPurple>
          );
        }
        return (
          <StyledTableCellDisPurple
            align='left'
            sx={{
              top: 42,
              // minWidth: item.minWidth,
              // maxWidth: item.maxWidth,
              width: `${Math.max((columnWidthMap[`${type}-${index}`]??0), columnMinWidthMap[`${type}-${index}`]) + 50 }px`,
            }}
            key={item.id}
            className='RightBottomBorder'
            style={{ 'padding-top': '5px', 'padding-bottom': '5px' }}
            data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-DisplayPurpleHeader-StyledTableCellDisPurple-${index}`}
          >
            {item.label}
            <br />
            TD(%)
          </StyledTableCellDisPurple>
        );
      } else {
        return (
          <StyledTableCellDisPurple
            align='left'
            sx={{
              top: 42,
              // minWidth: item.minWidth,
              // maxWidth: item.maxWidth,
              width: `${Math.max((columnWidthMap[`${type}-${index}`]??0), columnMinWidthMap[`${type}-${index}`]) + 50 }px`,
            }}
            key={item.id}
            className='BottomBorder'
            data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-DisplayPurpleHeader-StyledTableCellDisPurple-${index}`}
          >
            {item.label}
          </StyledTableCellDisPurple>
        );
      }
    });
  };

  const displayGreenHeader = (type) => {
    return itemTable.map((item, index) => {
      if (item.id.indexOf('/') != -1) {
        if (item.id.indexOf('FYTwo')) {
          return (
            <StyledTableCellDisGreen
              align='left'
              sx={{
                top: 42,
                // minWidth: item.minWidth,
                // maxWidth: item.maxWidth,
                width: `${Math.max((columnWidthMap[`${type}-${index}`]??0), columnMinWidthMap[`${type}-${index}`]) + 50 }px`,
              }}
              key={item.id}
              className='BottomBorder'
              style={{ 'padding-top': '5px', 'padding-bottom': '5px' }}
              data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-DisplayGreenHeader-StyledTableCellDisGreen-${index}`}
            >
              {item.label}
              <br />
              TD(%)
            </StyledTableCellDisGreen>
          );
        }
        return (
          <StyledTableCellDisGreen
            align='left'
            sx={{
              top: 42,
              // minWidth: item.minWidth,
              // maxWidth: item.maxWidth,
              width: `${Math.max((columnWidthMap[`${type}-${index}`]??0), columnMinWidthMap[`${type}-${index}`]) + 50 }px`,
            }}
            key={item.id}
            className='RightBottomBorder'
            style={{ 'padding-top': '5px', 'padding-bottom': '5px' }}
            data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-DisplayGreenHeader-StyledTableCellDisGreen-${index}`}
          >
            {item.label}
            <br />
            TD(%)
          </StyledTableCellDisGreen>
        );
      } else {
        return (
          <StyledTableCellDisGreen
            align='left'
            sx={{
              top: 42,
              // minWidth: item.minWidth,
              // maxWidth: item.maxWidth,
              width: `${Math.max((columnWidthMap[`${type}-${index}`]??0), columnMinWidthMap[`${type}-${index}`]) + 50 }px`,
            }}
            key={item.id}
            className='BottomBorder'
            data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-DisplayGreenHeader-StyledTableCellDisGreen-${index}`}
          >
            {item.label}
          </StyledTableCellDisGreen>
        );
      }
    });
  };
  //isOA为true其实是switch为true，也就是选择all
  if (isOA === true) {
    return (
      <TableBox2
        sx={{ height: '30' }}
        data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-Box'
        dataList={[AnnualTrendsSGDate, isOA]}
        tableKey={'Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable'}
        footerHeight={0}
      >
        <TableContainer
          id='reportAAASATSGTable'
          data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableContainer'
          sx={{ maxHeight: '560px' }}
        >
          <Table
            stickyHeader
            aria-label='sticky table'
            data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-Table'
            sx={{
              tableLayout: 'fixed',
              width: 'max-content',
              maxWidth: 'none',
            }}
          >
            <TableHead data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableHead'>
              <TableRow data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow1'>
                <StyledTableCellNormal
                  rowSpan={2}
                  className='RightBottomBorder'
                  data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow1-StyledTableCellPurple1'
                  sx={{
                    width: `${Math.max((columnWidthMap['subjectGroup']??0), columnMinWidthMap['subjectGroup']) + 50 }px`,
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                  }}
                >
                  Subject Group
                </StyledTableCellNormal>
                <StyledTableCellPurple
                  colSpan={5}
                  align='left'
                  className='RightBottomBorder ReportHeaderFirstLine'
                  data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow1-StyledTableCellPurple2'
                  sx={{
                    width: `${calcGroupWidth('acquired')}px`,
                  }}
                >
                  Acquired SI
                </StyledTableCellPurple>
                <StyledTableCellPurple
                  colSpan={5}
                  align='left'
                  className='RightBottomBorder ReportHeaderFirstLine'
                  data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow1-StyledTableCellPurple3'
                  sx={{
                    width: `${calcGroupWidth('active')}px`,
                  }}
                >
                  Active SI
                </StyledTableCellPurple>
                <StyledTableCellPurple
                  colSpan={5}
                  align='left'
                  className='RightBottomBorder ReportHeaderFirstLine'
                  data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow1-StyledTableCellPurple4'
                  sx={{
                    width: `${calcGroupWidth('published')}px`,
                  }}
                >
                  Published SI
                </StyledTableCellPurple>
              </TableRow>
              <TableRow>
                {displayPurpleHeader('acquired')}
                {displayPurpleHeader('active')}
                {displayPurpleHeader('published')}
              </TableRow>
            </TableHead>
            <TableBody data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableBody'>
              {AnnualTrendsSGDate != null &&
              AnnualTrendsSGDate != undefined &&
              AnnualTrendsSGDate.length != 0 ? (
                AnnualTrendsSGDate.map((row, index) => {
                  if (row.subjectGroup == 'Total') {
                    return (
                      <TwoColorTableRowPurple
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-${index}`}
                      >
                        <StyledTableCell
                          align='right'
                          className='RightBorder ReportTotalTextSize'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-StyledTableCell1-${index}`}
                          sx={{
                            position: 'sticky',
                            left: 0,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                        >
                          <SimpleTooltip title={row.subjectGroup}>
                              < >{row.sgAbbr ?? row.subjectGroup}</ >
                            </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-StyledTableCell2-${index}`}
                        >
                          < >
                            {' '}
                            {row.two_fy_ago_acquired === null
                              ? '0'
                              : row.two_fy_ago_acquired}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-StyledTableCell3-${index}`}
                        >
                          < >
                            {row.one_fy_ago_acquired === null
                              ? '0'
                              : row.one_fy_ago_acquired}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-StyledTableCell4-${index}`}
                        >
                          < >
                            {row.current_fy_acquired === null
                              ? '0'
                              : row.current_fy_acquired}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-StyledTableCell5-${index}`}
                        >
                          < >{row.acquired_ytd_1}</ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor RightBorder'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-StyledTableCell6-${index}`}
                        >
                          < >{row.acquired_ytd_2}</ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-StyledTableCell7-${index}`}
                        >
                          < >
                            {row.two_fy_ago_active === null
                              ? '0'
                              : row.two_fy_ago_active}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-StyledTableCell8-${index}`}
                        >
                          < >
                            {row.one_fy_ago_active === null
                              ? '0'
                              : row.one_fy_ago_active}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-StyledTableCell9-${index}`}
                        >
                          < >
                            {row.current_fy_active === null
                              ? '0'
                              : row.current_fy_active}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-StyledTableCell10-${index}`}
                        >
                          < >{row.active_ytd_1}</ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor RightBorder'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-StyledTableCell11-${index}`}
                        >
                          < >{row.active_ytd_2}</ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-StyledTableCell12-${index}`}
                        >
                          < >
                            {row.two_fy_ago_published === null
                              ? '0'
                              : row.two_fy_ago_published}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-StyledTableCell13-${index}`}
                        >
                          < >
                            {row.one_fy_ago_published === null
                              ? '0'
                              : row.one_fy_ago_published}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-StyledTableCell14-${index}`}
                        >
                          < >
                            {row.current_fy_published === null
                              ? '0'
                              : row.current_fy_published}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-StyledTableCell15-${index}`}
                        >
                          < >{row.published_ytd_1}</ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor RightBorder'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow-StyledTableCell16-${index}`}
                        >
                          < >{row.published_ytd_2}</ >
                        </StyledTableCell>
                      </TwoColorTableRowPurple>
                    );
                  }
                  return (
                    <TwoColorTableRowPurple
                      data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableRow-${index}`}
                    >
                      <StyledTableCell
                        align='left'
                        className='RightBorder'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell-${index}`}
                        sx={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 6,
                          backgroundColor: '#F1F3F5',
                        }}
                      >
                        <SimpleTooltip title={row.subjectGroup}>
                            {row.sgAbbr ?? row.subjectGroup}
                          </SimpleTooltip>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell1-${index}`}
                      >
                        {row.two_fy_ago_acquired === null
                          ? '0'
                          : row.two_fy_ago_acquired}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell2-${index}`}
                      >
                        {row.one_fy_ago_acquired === null
                          ? '0'
                          : row.one_fy_ago_acquired}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell3-${index}`}
                      >
                        {row.current_fy_acquired === null
                          ? '0'
                          : row.current_fy_acquired}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell4-${index}`}
                      >
                        {row.acquired_ytd_1}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell5-${index}`}
                      >
                        {row.acquired_ytd_2}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell6-${index}`}
                      >
                        {row.two_fy_ago_active === null
                          ? '0'
                          : row.two_fy_ago_active}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell7-${index}`}
                      >
                        {row.one_fy_ago_active === null
                          ? '0'
                          : row.one_fy_ago_active}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell8-${index}`}
                      >
                        {row.current_fy_active === null
                          ? '0'
                          : row.current_fy_active}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell9-${index}`}
                      >
                        {row.active_ytd_1}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell10-${index}`}
                      >
                        {row.active_ytd_2}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell11-${index}`}
                      >
                        {row.two_fy_ago_published === null
                          ? '0'
                          : row.two_fy_ago_published}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell12-${index}`}
                      >
                        {row.one_fy_ago_published === null
                          ? '0'
                          : row.one_fy_ago_published}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell13-${index}`}
                      >
                        {row.current_fy_published === null
                          ? '0'
                          : row.current_fy_published}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell14-${index}`}
                      >
                        {row.published_ytd_1}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell15-${index}`}
                      >
                        {row.published_ytd_2}
                      </StyledTableCell>
                    </TwoColorTableRowPurple>
                  );
                })
              ) : (
                <ReportNoData id='AcquiredAndActiveSiAnnualTrendsSGNoResultTableCell' />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TableBox2>
    );
  } else {
    return (
      <TableBox2
        sx={{ height: '30' }}
        data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-Box'
        dataList={[AnnualTrendsSGDate, isOA]}
        tableKey={'Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable'}
        footerHeight={0}
      >
        <TableContainer
          sx={{ maxHeight: '560px' }}
          data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableContainer'
        >
          <Table
            stickyHeader
            aria--label='sticky table'
            data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-Table'
            sx={{
              tableLayout: 'fixed',
              width: 'max-content',
              maxWidth: 'none',
            }}
          >
            <TableHead data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableHead'>
              <TableRow data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow1'>
                <StyledTableCellNormal
                  rowSpan={2}
                  className='RightBottomBorder'
                  data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCellGreen'
                  sx={{
                    width: `${Math.max((columnWidthMap['subjectGroup']??0), columnMinWidthMap['subjectGroup']) + 50 }px`,
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                  }}
                >
                  Subject Group
                </StyledTableCellNormal>
                <StyledTableCellGreen
                  colSpan={5}
                  align='left'
                  className='RightBottomBorder ReportHeaderFirstLine'
                  data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCellGreen1'
                  sx={{
                    width: `${calcGroupWidth('acquired')}px`,
                  }}
                >
                  Acquired SI
                </StyledTableCellGreen>
                <StyledTableCellGreen
                  colSpan={5}
                  align='left'
                  className='RightBottomBorder ReportHeaderFirstLine'
                  data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCellGreen2'
                  sx={{
                    width: `${calcGroupWidth('active')}px`,
                  }}
                >
                  Active SI
                </StyledTableCellGreen>
                <StyledTableCellGreen
                  colSpan={5}
                  align='left'
                  className='RightBottomBorder ReportHeaderFirstLine'
                  data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCellGreen3'
                  sx={{
                    width: `${calcGroupWidth('published')}px`,
                  }}
                >
                  Published SI
                </StyledTableCellGreen>
              </TableRow>
              <TableRow data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableRow2'>
                {displayGreenHeader('acquired')}
                {displayGreenHeader('active')}
                {displayGreenHeader('published')}
              </TableRow>
            </TableHead>
            <TableBody data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-TableBody'>
              {AnnualTrendsSGDate != null &&
              AnnualTrendsSGDate != undefined &&
              AnnualTrendsSGDate.length != 0 ? (
                AnnualTrendsSGDate.map((row, index) => {
                  if (row.subjectGroup == 'Total') {
                    return (
                      <TwoColorTableRowGreen
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableRow-${index}`}
                      >
                        <StyledTableCell
                          align='right'
                          className='RightBorder ReportTotalTextSize'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell1-${index}`}
                          sx={{
                            position: 'sticky',
                            left: 0,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                        >
                          <SimpleTooltip title={row.subjectGroup}>
                              < >{row.sgAbbr ?? row.subjectGroup}</ >
                            </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell2-${index}`}
                        >
                          < >
                            {' '}
                            {row.two_fy_ago_acquired === null
                              ? '0'
                              : row.two_fy_ago_acquired}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell3-${index}`}
                        >
                          < >
                            {row.one_fy_ago_acquired === null
                              ? '0'
                              : row.one_fy_ago_acquired}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell4-${index}`}
                        >
                          < >
                            {row.current_fy_acquired === null
                              ? '0'
                              : row.current_fy_acquired}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell5-${index}`}
                        >
                          < >{row.acquired_ytd_1}</ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell6-${index}`}
                        >
                          < >{row.acquired_ytd_2}</ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell7-${index}`}
                        >
                          < >
                            {row.two_fy_ago_active === null
                              ? '0'
                              : row.two_fy_ago_active}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell8-${index}`}
                        >
                          < >
                            {row.one_fy_ago_active === null
                              ? '0'
                              : row.one_fy_ago_active}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell9-${index}`}
                        >
                          < >
                            {row.current_fy_active === null
                              ? '0'
                              : row.current_fy_active}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell10-${index}`}
                        >
                          < >{row.active_ytd_1}</ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell11-${index}`}
                        >
                          < >{row.active_ytd_2}</ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell12-${index}`}
                        >
                          < >
                            {row.two_fy_ago_published === null
                              ? '0'
                              : row.two_fy_ago_published}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell13-${index}`}
                        >
                          < >
                            {row.one_fy_ago_published === null
                              ? '0'
                              : row.one_fy_ago_published}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell14-${index}`}
                        >
                          < >
                            {row.current_fy_published === null
                              ? '0'
                              : row.current_fy_published}
                          </ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell15-${index}`}
                        >
                          < >{row.published_ytd_1}</ >
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportTotalTextSize ReportNumberTextColor'
                          data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell16-${index}`}
                        >
                          < >{row.published_ytd_2}</ >
                        </StyledTableCell>
                      </TwoColorTableRowGreen>
                    );
                  }
                  return (
                    <TwoColorTableRowGreen
                      data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableRow-${index}`}
                    >
                      <StyledTableCell
                        align='left'
                        className='RightBorder'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell1-${index}`}
                        sx={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 6,
                          backgroundColor: '#F1F3F5',
                        }}
                      >
                        <SimpleTooltip title={row.subjectGroup}>
                            {row.sgAbbr ?? row.subjectGroup}
                          </SimpleTooltip>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell2-${index}`}
                      >
                        {row.two_fy_ago_acquired === null
                          ? '0'
                          : row.two_fy_ago_acquired}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell3-${index}`}
                      >
                        {row.one_fy_ago_acquired === null
                          ? '0'
                          : row.one_fy_ago_acquired}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell4-${index}`}
                      >
                        {row.current_fy_acquired === null
                          ? '0'
                          : row.current_fy_acquired}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell5-${index}`}
                      >
                        {row.acquired_ytd_1}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell6-${index}`}
                      >
                        {row.acquired_ytd_2}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell7-${index}`}
                      >
                        {row.two_fy_ago_active === null
                          ? '0'
                          : row.two_fy_ago_active}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell8-${index}`}
                      >
                        {row.one_fy_ago_active === null
                          ? '0'
                          : row.one_fy_ago_active}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell9-${index}`}
                      >
                        {row.current_fy_active === null
                          ? '0'
                          : row.current_fy_active}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell10-${index}`}
                      >
                        {row.active_ytd_1}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell11-${index}`}
                      >
                        {row.active_ytd_2}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell12-${index}`}
                      >
                        {row.two_fy_ago_published === null
                          ? '0'
                          : row.two_fy_ago_published}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell13-${index}`}
                      >
                        {row.one_fy_ago_published === null
                          ? '0'
                          : row.one_fy_ago_published}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell14-${index}`}
                      >
                        {row.current_fy_published === null
                          ? '0'
                          : row.current_fy_published}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell15-${index}`}
                      >
                        {row.published_ytd_1}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-AnnualTrends-AnnualSubjectTable-StyledTableCell16-${index}`}
                      >
                        {row.published_ytd_2}
                      </StyledTableCell>
                    </TwoColorTableRowGreen>
                  );
                })
              ) : (
                <ReportNoData id='AcquiredAndActiveSiAnnualTrendsSGNoResultTableCell' />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TableBox2>
    );
  }
}

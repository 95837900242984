import {PATTERNORCID} from "@/constant/common";

export function validateNotEmpty(value: string|null|undefined): boolean {
  return value!==null && value!==undefined && value.trim() !== '';
}

export function validateDateString(value: string|null|undefined): boolean {
  return value!==null && value!==undefined && value.match(/^\d{4}-\d{2}-\d{2}$/) !== null;
}

export function validateEmail(value: string|null|undefined): boolean {
    return value!==null && value!==undefined && value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) !== null;
}

export function validateOrcidID(value: string|null|undefined): boolean {
    return value!==null && value!==undefined && value.match(PATTERNORCID) !== null;
}

export function validateURL(value: string|null|undefined): boolean {
    return value!==null && value!==undefined && value.match(/^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%.+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%+.~#?&/=]*)/) !== null;
}
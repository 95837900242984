import WileyLogo from '../../../../assets/LoginAsset/WileyLogo.svg';
import ExpireLogo from '../../../../assets/LoginAsset/ExpireLogo.svg';
import EmailResentAlert from '../../../../assets/LoginAsset/EmailResentAlert.svg';
import styled from '@emotion/styled';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clear } from '@/actions/SIMT-User/Login/LoginAction';
import { UntitledAlertBox } from '@/components/Alert/AlertBox';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import {forgetPassEmail} from "@/actions/SIMT-User/Login";
import { resetPassFirstTime } from '@/actions/LoginAction';


const SuccessTypography = styled(Typography)({
  color: 'var(--gray-500, #596A7C)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '48px',
  fontFamily: 'Open Sans',
  fontWeight: '800',
  lineHeight: '125%',
  letterSpacing: '0.48px',
});

const InfoTypography = styled(Typography)({
  color: 'var(--gray-300, #BCC5CF)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  lineHeight: '140%',
});

const CloseButton = styled(Button)({
  borderRadius: '5px',
  background: 'var(--primary-500, #0052CC)',
  color: 'var(--shades-white, #FFF)',
  textAlign: 'center',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  lineHeight: '18px',
  width: '400px',
  height: '40px',
  padding: '8px 12px',
  gap: '6px',
  '&:hover': {
    background: 'var(--primary-300, #6D98EE)',
  },

  '&:active': {
    background: 'var(--primary-600, #113D95)',
  },
});

const ExpireTypography = styled(Typography)({
  color: 'var(--gray-300, #BCC5CF)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  lineHeight: '125%',
});

const ResendTypography = styled(Typography)({
  color: 'var(--primary-500, #154AB6)',
  textAlign: 'center',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  fontWeight: 700,
  lineHeight: '18px',
  '&:hover': {
    fontWeight: 900,
  },
});

const LinkExpirePage = props => {
  const [alertShow, setAlertShow] = useState(false);

  const dispatch = useDispatch();

  // const [searchParams] = useSearchParams();
  // const email = searchParams.get('email');
  const location = useLocation();
  const { email } = location.state ?? { email: '' };

  const handleResend = () => {
    if (email) {
		dispatch(sendActiveEmail(getUserId())
			// forgetPassEmail(email)
		);
      setAlertShow(true);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        background: '#FFF',
        width: '100vw',
        height: '100vh',
      }}
      data-selenium-id='Login_NewLogin_LinkExpire'
    >
      <div
        style={{ width: '311px', height: '100%', background: '#0052CC' }}
        data-selenium-id='Login_NewLogin_LinkExpire-LeftPart'
      >
        <WileyLogo
          style={{
            marginLeft: '16px',
            marginTop: '16px',
          }}
        />
      </div>
      <div
        style={{
          width: 'calc(100% - 311px)',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
        data-selenium-id='Login_NewLogin_LinkExpire-RightPart'
      >
        <div
          data-selenium-id='Login_NewLogin_LinkExpire-RightPart-Content'
          style={{
            marginTop: '148px',
          }}
        >
          <div
            style={{ width: '573px' }}
            data-selenium-id='Login_NewLogin_LinkExpire-SuccessTitle'
          >
            <SuccessTypography>
              Oops! It looks like this link has expired.
            </SuccessTypography>
          </div>
          <div
            style={{ marginTop: '20px', width: '553px' }}
            data-selenium-id='Login_NewLogin_LinkExpire-SuccessInfo'
          >
            <InfoTypography>
              To continue with the password reset process, please click the
              "Resend the email" button located on this page.
            </InfoTypography>
          </div>
          <div
            style={{ marginLeft: '80px', marginTop: '52px' }}
            data-selenium-id='Login_NewLogin_LinkExpire-SuccessLogo'
          >
            <ExpireLogo />
          </div>
          <div
            style={{ marginTop: '-27px' }}
            data-selenium-id='Login_NewLogin_LinkExpire-BackLogin'
          >
            <CloseButton
              onClick={() => {
                window.close();
              }}
              disableRipple={true}
            >
              CLOSE THIS PAGE
            </CloseButton>
          </div>
          <div
            style={{
              marginTop: '17px',
              marginLeft: '6px',
              display: 'flex',
              flexDirection: 'row',
            }}
            data-selenium-id='Login_NewLogin_LinkExpire-LinkExpireResend'
          >
            <ExpireTypography>Your link has expired?</ExpireTypography>
            <Button
              sx={{
                marginLeft: '42px',
                textTransform: 'none',
                padding: '0',
                '&:hover': {
                  background: 'transparent',
                },
              }}
              disableRipple={true}
              disableTouchRipple={true}
              onClick={handleResend}
            >
              <ResendTypography>Resend the email</ResendTypography>
            </Button>
          </div>
        </div>
        <UntitledAlertBox
          open={alertShow}
          handleClose={() => setAlertShow(false)}
          message='The email has been successfully resent.'
          severity='success'
          style={{
            top: '20px',
            right: '20px',
          }}
        />
      </div>
    </div>
  );
};

export default LinkExpirePage;

//Get all admin information
import { axios_instance } from "@/utils/axios_instance";
import {
    ADMIN_EMAIL_CHECK,
    CREATE_ADMIN,
    DOWNGRADE_ADMIN,
    EDIT_ADMIN,
    GET_ALL_ADMIN_INFO,
    INACTIVE_ADMIN,
    LOAD_ADMIN_DATA,
    UNLOCK_ADMIN,
    UPDATE_ADMIN,
    UPGRADE_USER
} from "@/actions/SIMT-User/User/UserAction";

export function getAllAdminInfo(page, pageSize, order, orderBy) {
    return async dispatch => {
        try {
            const res = await axios_instance.get(`/api/v1/user-service/admin-users`, {
                params: {
                    pageNum: page,
                    pageSize: pageSize,
                    orderField: orderBy,
                    orderBy: order,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            // console.log("in superadmin actions, getAllAdminInfo", res);
            if (res.data.code === 200) {
                // console.log("in superadmin actions, getAllAdminInfo", res);
                dispatch({ type: GET_ALL_ADMIN_INFO, data: res.data.data });
            } else {
                dispatch({ type: GET_ALL_ADMIN_INFO, data: null });
            }
        } catch (err) {
            dispatch({ type: GET_ALL_ADMIN_INFO, data: err.response?.data.msg });
        }
    };
} // The action to the admin(resend, reset, delete)
// console.log("in superadmin actions, getAllAdminInfo", res);
export function editAdmin(userId, type) {
    return async dispatch => {
        let url;
        let res;
        try {
            switch (type) {
                case 'resendInvitationToAdmin': //Send activation emails to specified users
                    url = `/api/v1/user-service/admin-users/${userId}/activate`;
                    res = await axios_instance.put(url, null, {
                        headers: {
                            authorization: localStorage.getItem('authorization'),
                        },
                    });
                    break;
                case 'resetAdminPass': //Reset user password to default password
                    url = `/api/v1/user-service/admin-users/${userId}/password`;
                    res = await axios_instance.put(url, null, {
                        headers: {
                            authorization: localStorage.getItem('authorization'),
                        },
                    });
                    break;
                case 'deleteAdmin': //remove specified user
                    url = `/api/v1/user-service/admin-users/${userId}`;
                    res = await axios_instance.delete(url, {
                        headers: {
                            authorization: localStorage.getItem('authorization'),
                        },
                    });
                    break;
                default:
                    url = null;
                    res = null;
                    break;
            }

            // const res = await axios_instance.get(url, {
            //     headers: {
            //         authorization: localStorage.getItem('authorization'),
            //     },
            // });
            if (url && res) {
                if (res.data.code === 200 || res.status === 200) {
                    dispatch({ type: EDIT_ADMIN, data: true });
                } else if (res.status === 204 || res.data.code === 204) {
                    dispatch({ type: EDIT_ADMIN, data: true });
                } else if (
                    res.data.code === 500 &&
                    (res.data.message === 'SIAssociated' ||
                        res.data.message == 'PreSIAssociated')
                ) {
                    dispatch({ type: EDIT_ADMIN, data: 'SIAssociated' });
                }
                else {
                    dispatch({ type: EDIT_ADMIN, data: false });
                }
            }
            // if (res.data.code === 200) {
            //     dispatch({ type: EDIT_ADMIN, data: true });
            // } else if (
            //     res.data.code === 500 &&
            //     (res.data.message === 'SIAssociated' ||
            //         res.data.message == 'PreSIAssociated')
            // ) {
            //     dispatch({ type: EDIT_ADMIN, data: 'SIAssociated' });
            // } else {
            //     dispatch({ type: EDIT_ADMIN, data: false });
            // }
        } catch (err) {
            dispatch({ type: EDIT_ADMIN, data: err.response.data.msg });
        }
    };
} // Create a new Admin
export function createAdmin(FirstName, LastName, email, group) {
    return async dispatch => {
        try {
            const url = '/api/v1/user-service/admin-users';
            const result = await axios_instance.post(
                url,
                {
                    firstName: FirstName,
                    lastName: LastName,
                    email: email,
                    group: group,
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );

            if (result.data.code === 200) {
                if (result.data.message === 'update to admin successfully') {
                    dispatch({ type: UPGRADE_USER, data: 'true' });
                } else {
                    dispatch({ type: CREATE_ADMIN, data: 'true' });
                }
            } else {
                if (result.data.code === 500) {
                    if (result.data.message === 'the email address has been registered')
                        //判断是否是邮箱存在错误
                        dispatch({ type: CREATE_ADMIN, data: 'EmailExit' });
                }
            }
        } catch (err) {
            dispatch({ type: CREATE_ADMIN, data: err.response.data.msg });
        }
    };
} //Inactive a system admin
export function inactiveAdmin(email) {
    return async dispatch => {
        try {
            let res = await axios_instance.get(
                `/super/inactivateSystemAdmin/` + email,
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );

            if (res.data.code === 200) {
                dispatch({ type: INACTIVE_ADMIN, data: 'true' });
            } else {
                dispatch({ type: INACTIVE_ADMIN, data: false });
            }
        } catch (err) {
            dispatch({ type: INACTIVE_ADMIN, data: err.response.data.msg });
        }
    };
} //unlock a admin
export function unlockAdmin(email) {
    return async dispatch => {
        try {
            let res = await axios_instance.get(
                `/super/unlockSystemAdmin?email=` + email,
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );

            if (res.data.code === 200) {
                dispatch({ type: UNLOCK_ADMIN, data: 'true' });
            } else {
                dispatch({ type: UNLOCK_ADMIN, data: 'error' });
            }
        } catch (err) {
            dispatch({ type: UNLOCK_ADMIN, data: err.response.data.msg });
        }
    };
}

/**
 * 超级管理员使用邮箱获得Admin信息
 * @param {string} email 需要检查的邮件地址
 * @returns 如果对应用户存在，返回由first name、last name和role组成的object，否则返回null
 */
export function getAdminInfoByEmail(email) {
    return async dispatch => {
        try {
            let url = '/api/v1/user-service/admin-users';
            let res = await axios_instance.get(url, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
                params: { email: email },
            });
            if (res.data.code === 200) {
                dispatch({
                    type: ADMIN_EMAIL_CHECK,
                    data: { data: res, email: email },
                });
            } else {
                dispatch({ type: ADMIN_EMAIL_CHECK, data: res, email: '' });
            }
        } catch (err) {
            // dispatch({ type: GET_ADMIN_INFO, data: err.response.data.msg });
        }
    };
}

/**
 * 超级管理员根据邮箱，将对应用户的角色升级为System Admin
 * @param {string} email 邮箱
 * @returns 修改成功返回true，否则返回false
 */
export function upgradeUser(email, fullName, group) {
    return async dispatch => {
        try {
            var n = fullName.split(' ');
            var FirstName = n[0];
            var LastName = n[1];
            let url = '/super/toSystemAdmin';
            let result = await axios_instance.post(
                url + '?email=' + email,
                {},
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (result.data.code === 200) {
                dispatch({ type: UPGRADE_USER, data: 'true' });
            } else {
                if (result.data.code === 500) {
                    if (result.data.message === 'the email address has been registered')
                        //判断是否是邮箱存在错误
                        dispatch({ type: UPGRADE_USER, data: 'EmailExit' });
                }
                dispatch({ type: UPGRADE_USER, data: 'error' });
            }
        } catch (err) {
            dispatch({ type: UPGRADE_USER, data: err.response.data.msg });
        }
    };
} //Downgrade Admin
export function downgradeAdmin(email, role, group) {
    return async dispatch => {
        try {
            let url = '/super/degradeSystemAdmin';
            let res = await axios_instance.post(
                url,
                {
                    email: email,
                    group: group,
                    role: role,
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: DOWNGRADE_ADMIN, data: 'true' });
            } else {
                dispatch({ type: DOWNGRADE_ADMIN, data: 'error' });
            }
        } catch (err) {
            dispatch({ type: DOWNGRADE_ADMIN, data: err.response.data.msg });
        }
    };
} // Update a new Admin
export function updateAdmin(userId, email, group, role, locked, active) {
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/admin-users/${userId}`;
            const result = await axios_instance.put(
                url,
                {
                    role: role,
                    locked: locked,
                    active: active,
                    email: email,
                    group: group,
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );

            if (result.data.code === 200) {
                if (role !== 'Admin') {
                    dispatch({ type: DOWNGRADE_ADMIN, data: 'true' });
                } else {
                    dispatch({ type: UPDATE_ADMIN, data: 'true' });
                }
            } else if (result.data.message === 'can not inactivate handling ce') {
                dispatch({ type: UPDATE_ADMIN, data: 'HEInactive' });
            } else {
                //判断是否是邮箱存在错误
                dispatch({ type: DOWNGRADE_ADMIN, data: 'error' });
            }
        } catch (err) {
            dispatch({ type: DOWNGRADE_ADMIN, data: err.response.data.msg });
        }
    };
}

/**
 * 获取admin列表数据
 * @param {*} page
 * @param {*} pageSize
 * @param {*} order
 * @param {*} orderBy
 * @returns
 */
export function loadAdminData(page, pageSize, order, orderBy) {
    return async dispatch => {
        try {
            const res = await axios_instance.get(`/api/v1/user-service/admin-users`, {
                params: {
                    pageNum: page,
                    pageSize: pageSize,
                    orderField: orderBy,
                    orderBy: order,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                //将返回数据添加独一无二的ID，以适应表格
                const returnData = {
                    result: [],
                    count: res.data.data.total,
                };
                for (const resultEle of res.data.data.admins) {
                    const temp = {
                        ...resultEle,
                    };
                    temp['id'] = temp.email;
                    returnData.result.push(temp);
                }

                dispatch({ type: LOAD_ADMIN_DATA, data: returnData });
            } else {
                dispatch({
                    type: LOAD_ADMIN_DATA,
                    data: {
                        result: [],
                        count: 0,
                    },
                });
            }
        } catch (err) {
            dispatch({
                type: LOAD_ADMIN_DATA,
                data: {
                    result: [],
                    count: 0,
                },
            });
        }
    };
}
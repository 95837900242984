import { SmallBoldFont } from '@/components/FontStyle';
import ItemBlock from '@/componentsphase2/Itemblock';

export default function ItemBlockInstance(props) {
  const { id, title } = props;
  return (
    <ItemBlock
      id={id}
      required={true}
      title={title}
      stackProps={{
        first: {
          spacing: 3,
          direction: 'row',
        },
      }}
      titleProps={{
        titleSX: {
          //styleName: small bold;
          ...SmallBoldFont,
          lineHeight: '20px',
          letterSpacing: '0.01em',
        },
        stackProps: {
          spacing: 0.25,
        },
        boxSX: {
          width: '143px',
        },
      }}
    >
      {props.children}
    </ItemBlock>
  );
}

import { Select, TextField } from '@mui/material';
import { styled } from '@mui/system';

export const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select.MuiOutlinedInput-input': {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  '& fieldset': {
    border: '1px solid #DFE4E8',
  },
}));

import { LargeBoldFont } from '@/components/FontStyle';
import { RowCentered } from '@/componentsphase2/Autocomplete/CommonStyle';
import { StateButton } from '@/componentsphase2/StateButton';
import { Dialog, DialogActions, DialogContent, Stack } from '@mui/material';

export default function MyDialog(props) {
  const {
    open,
    handleClose,
    handleAgree,
    dialogContent = '',
    dialogContentHeight = 'auto',
  } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '600px',
          margin: 0,
          padding: '24px 32px 0px 32px',
          boxSizing: 'border-box',
          boxShadow: 'none',
        },
        '& .MuiBackdrop-root': {
          background: ' rgba(17, 61,149, 0.5)',
          backdropFilter: 'blur(4px)',
        },
      }}
    >
      <DialogContent
        sx={{
          padding: '0px',
          ...RowCentered,
          marginBottom: '16px',
          ...LargeBoldFont,
          height: dialogContentHeight,
          overflow: 'visible',
        }}
      >
        {dialogContent}
      </DialogContent>
      <DialogActions
        sx={{ ...RowCentered, paddingTop: '12px', paddingBottom: '20px' }}
      >
        <Stack spacing={1.125} direction='row'>
          <StateButton
            titleName='No'
            isPrimary={false}
            sx={{ padding: '8px 25px' }}
            onClick={handleClose}
          />
          <StateButton
            titleName='Yes'
            isPrimary={true}
            sx={{ padding: '8px 23px' }}
            onClick={handleAgree}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

import { SmallBoldFont } from '@/components/FontStyle';
import { Box } from '@mui/material';

export default function ExportBtn(props) {
  const { disable, handleClick } = props;
  return (
    <Box
      sx={{
        padding: '8px 12.5px',
        width: '70px',
        height: '34px',
        boxSizing: 'border-box',
        ...SmallBoldFont,
        color: '#FFFFFF',
        cursor: !disable ? 'pointer' : 'default',
        backgroundColor: !disable ? '#0052CC' : '#BCC5CF',
        borderRadius:'5px',
        ':hover':!disable && {
          backgroundColor:'#6D98EE'
        },
        ':active':!disable && {
          backgroundColor:'#113D95'
        },
      }}
      onClick={() => {
        if (!disable) {
          handleClick();
        }
      }}
    >
        Export
    </Box>
  );
}

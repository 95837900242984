import {
  Table,
  TableContainer,
  TableRow,
  TableBody,
  Box,
  tableCellClasses,
  Paper,
  TableCell,
  styled,
  Tooltip,
} from '@mui/material';
import { formatDatetimeString } from '@/utils/commonUtils';
import { useState, useRef, useEffect } from 'react';
import { TableFooter2 } from '../../../../components/DataTable';
import { SmallBoldFont } from '../../../../components/FontStyle';
import EmailContent from './EmailContent';
import SIPLogTableHeader from './SIPLogTableHeader';
import { MulLineTooltipIfShow } from '@/componentsphase2/CustomTooltip';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    // backgroundColor: '#FFFFFF',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.14px'
  },
  [`&.${tableCellClasses.root}`]: {
    height: '20px',
    padding: '12px 24px',
  },
}));


function SIPLogTable(props) {
  //从父组件获取的属性
  const {
    id,
    TableHeads,
    page,
    rowsPerPage,
    TableData,
    rowsPerPageOptions,
    count,
    handlePageChange,
    handleRowsPerPageChange,
  } = props;

  const [open, setOpen] = useState(false);
  const [emailContent, setEmailContent] = useState('');

  const Text = (props) => {
    const { text } = props;
    const parent = useRef(null);
    const child = useRef(null);
    const [ellipsis, setEllipsis] = useState(false);

    useEffect(() => {
      const textElement = child.current;
      const parentElement = parent.current;
      if (textElement && parentElement) {
        const textWidth = textElement.offsetWidth;
        const parentWidth = parentElement.offsetWidth;

        if (textWidth > parentWidth) {
          setEllipsis(true);
        } else {
          setEllipsis(false);
        }
      }
    }, []);
    return (
      <>
        <div
          ref={parent}
          style={{
            position: 'relative',
          }}
        >
          <Tooltip
            title={text}
            disableHoverListener={!ellipsis}
            placement='top'
            arrow
          >
            <div
              style={{
                textOverflow: 'ellipsis',
                width: TableHeads.maxWidth,
                overflow: 'hidden',
                whiteSpace: 'nowrap',               
              }}
            >
              <span ref={child} style={{ position: 'relative' }}>{text}</span>
            </div>
          </Tooltip>
          <style>
            {`
              .MuiTooltip-tooltip {
                top: 6px !important;
              }
            `}
          </style>
        </div>
      </>
    );
  };

  const DoubleLineText = (props) => {
    const { text } = props;
    const parent = useRef(null);
    const child = useRef(null);
    const [ellipsis, setEllipsis] = useState(false);

    useEffect(() => {
      const textElement = child.current;
      const parentElement = parent.current;
      if (textElement && parentElement) {
        const textHeight = textElement.offsetHeight;
        const parentHeight = parentElement.offsetHeight;
        if (textHeight > parentHeight) {
          setEllipsis(true);
        } else {
          setEllipsis(false);
        }
      }
    }, []);
    return (
      <>
        <div
          ref={parent}
          style={{
            position: 'relative',
          }}
        >
          <Tooltip title={text} disableHoverListener={!ellipsis} placement="top" arrow >
            <div
              style={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2, 
                width: TableHeads.maxWidth,
              }}
            >
              <span ref={child} style={{ position: 'relative' }}>{text}</span>
            </div>
          </Tooltip>
          <style>
            {`
              .MuiTooltip-tooltip {
                top: 6px !important;
              }
            `}
          </style>
        </div>
      </>
    );
  };



  return (
    <Box sx={{ width: '100%', boxShadow: 'none' }} data-selenium-id='SIP_DetailPage_Log-Table-Box'>
      <TableContainer
        id={id}
        data-selenium-id={`SIP_DetailPage_Log-Table-Box-${id}`}
        sx={{
          borderRadius: '8px 8px 0px 0px',
          borderWidth: '1px 1px 0px 1px',
          borderStyle: 'solid',
          borderColor: '#DFE4E8',
          boxShadow: 'none',
          overflow:'hidden',
        }}
        component={Paper}
      >
        <Table
          data-selenium-id='SIP_DetailPage_Log-Box-InnerTable'
          stickyHeader
          aria-label='custom pagination table'
          sx={{
            minWidth: 500,
            letterSpacing: '0.01em',
            color: '#262E35',
          }}
        >
          <SIPLogTableHeader
            items={TableHeads}
            backgroundColor='#F1F3F5'
            color='#596A7C'
            CheckboxColor='wileyBlue2'
            letterSpacing='0.08em'
          />
          <TableBody data-selenium-id='SIP_DetailPage_Log-Table-Box-Body'>
            {TableData.map(row => (
              <TableRow
                // hover
                key={row['historyId']}
                data-selenium-id='SIP_DetailPage_Log-Table-Box-Row'
                sx={{
                  '&:hover': {
                    background: '#E8EDFB'
                  }
                }}
              >
                {TableHeads.map((item, index) => {
                  if (item.isClick !== undefined && item.isClick) {
                    return (

                      <StyledTableCell
                        data-selenium-id='SIP_DetailPage_Log-Table-Box-StyledCell1'
                        sx={{
                          minWidth: item.minWidth,
                          maxWidth: item.maxWidth,
                          wordWrap: 'break-word',
                          color: '#154AB6',
                        }}
                        key={index}
                        align='left'
                      ><MulLineTooltipIfShow
                          title={row[item.id]}
                          key={item.id}
                          data-selenium-id='SIP_DetailPage_Log-Table-Box-Tooltip1'
                        >
                          <Box
                            id={`${item.id}-${index}`}
                            data-selenium-id={`SIP_DetailPage_Log-Table-${item.id}-${index}`}
                            sx={{
                              cursor: 'pointer',
                              color: '#154AB6',
                              ...SmallBoldFont,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                            onClick={() => {
                              setEmailContent(row);
                              setOpen(true);
                            }}
                          >
                            <div style={{
                              maxHeight:'40px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}> {row[item.id]}</div>
                          </Box>
                        </MulLineTooltipIfShow>
                      </StyledTableCell>
                    );
                  } else if (item.id === 'oldValue' || item.id === 'newValue'){
                    return (
                      
                      <StyledTableCell
                        key={index}
                        data-selenium-id='SIP_DetailPage_Log-Table-Box-StyledCell2'
                        sx={{
                          minWidth: item.minWidth,
                          maxWidth: item.maxWidth,
                          wordWrap: 'break-word',
                          color: '#262E35',
                        }}
                        align='left'
                      >
                        <DoubleLineText text={row[item.id]==='Null'&&row['item']==='Extended Deadline'?null:row[item.id]}></DoubleLineText>
                      </StyledTableCell>
                    );
                  }
                  else {
                    return (

                      <StyledTableCell
                        data-selenium-id='SIP_DetailPage_Log-Table-Box-StyledCell2'
                        sx={{
                          minWidth: item.minWidth,
                          maxWidth: item.maxWidth,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          letterSpacing: '1%',
                          color: '#262E35',
                        }}
                        align='left'
                      >
                        {/* <MulLineTooltipIfShow
                          data-selenium-id='SIP_DetailPage_Log-Table-Box-Tooltip2'
                          title={
                            item.id === 'editTime' || item.id === 'time'
                              ? formatDatetimeString(row[item.id])
                              : row[item.id]
                          }
                          key={item.id}
                        >
                          <div style={{
                            maxHeight:'20px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}>
                            {item.id === 'editTime' || item.id === 'time'
                              ? formatDatetimeString(row[item.id])
                              : row[item.id]}
                          </div>
                        </MulLineTooltipIfShow> */}
                        <Text
                          text={item.id === 'editTime' || item.id === 'time'
                            ? formatDatetimeString(row[item.id])
                            : row[item.id]}
                        />

                      </StyledTableCell>
                    );
                  }
                })}
              </TableRow>
            ))}

            {TableData.length <= 0 ? (
              <TableRow
                id='siDetailPaegNoDataText'
                data-selenium-id='SIP_DetailPage_Log-Table-DetailPaegNoDataText'
                style={{ height: '300px' }}
              >
                <TableCell
                  data-selenium-id='SIP_DetailPage_Log-Table-Box-Cell'
                  colSpan={6}
                  sx={{ textAlign: 'center', fontSize: '20px' }}
                >
                  No Data
                </TableCell>
              </TableRow>
            ) : (
              <div></div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter2
        data-selenium-id='SIP_DetailPage_Log-Table-Footer2'
        Page={page}
        perPageNumber={rowsPerPage}
        perPageList={rowsPerPageOptions}
        count={count}
        handlePerPageChange={handleRowsPerPageChange}
        handlePageChange={handlePageChange}
        backgroundColor='#f1f3f5'
      />
      <EmailContent
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        emailContent={emailContent}
      ></EmailContent>
    </Box>
  );
}
export default SIPLogTable;

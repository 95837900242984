import {
  Table,
  TableContainer,
  TableRow,
  TableBody,
  Box,
  tableCellClasses,
  textFieldClasses,
  Paper,
  TableCell,
  Link,
  Tooltip,
  CircularProgress,
  TextField,
  IconButton,
  Grid,
} from '@mui/material';
import DeleteContainerButton from '@/componentsphase2/Button/ContainerButtons/DeleteContainerButton';
import { useState, useEffect } from 'react';
import { MyTableHeader, NewTableHeader } from '@/components/DataTable';
import { styled } from '@mui/system';
import '../index.css';
import { useDispatch, useSelector } from 'react-redux';
import SIPAuthorEdit from '@/assets/SIPAuthorEdit.svg';
import SIPAuthorDelete from '@/assets/SIPAuthorDelete.svg';
import AuthorEditCancel from '@/assets/AuthorEditCancel.svg';
import AuthorEditSave from '@/assets/AuthorEditSave.svg';
import YesDefault from '../../../../componentsphase2/Button/icon/ContainerYesIconDefault.svg';
import YesDisable from '../../../../componentsphase2/Button/icon/ContainerYesIconDisable.svg';

import { FormattedMessage } from 'react-intl';
import EditContainerButton from '@/componentsphase2/Button/ContainerButtons/EditContainerButton';
import NoContainerButton from '@/componentsphase2/Button/ContainerButtons/NoContainerButton';
import YesContainerButton from '@/componentsphase2/Button/ContainerButtons/YesContainerButton';
import { CustomTooltip } from '@/components/CustomTooltip';
import { CreateAndUpdateInput, OnlyTextWithX } from '@/componentsphase2/OnlyText';
import CreateAndUpdateAddNew from '@/pages/SIPage/CreateAndUpdateSI/CommonComponents/CreateAndUpdateAddNew';

export const EDIT_TYPE = 'EDIT';
export const ADD_TYPE = 'ADD';

export const CellType = {
  Type_BOLD: 'BOLD',
  Type_TEXT: 'TEXT',
  Type_EMAIL: 'EMAIL',
  Type_UL: 'UL',
  Type_ACTIONS: 'ACTIONS',
};

export const StyledTableCell = styled(TableCell)(props => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E8F2',
    color: '#505050',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '0.01em',
  },
  // [`&.${tableCellClasses.root}`]: {
  //   padding: '0 24px',
  // },
}));

export const TextInput = styled(props => (
  <TextField
    {...props}
    data-selenium-id='SIP_DetailPage_Submodule-WriteTable-PropsTextInput'
  />
))({
  [`&.${textFieldClasses.root}`]: {
    // padding: "12px 12px 12px 12px"
  },
  '.MuiInputBase-root': {
    padding: '8px 20px 8px 20px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    letterSpacing: '0.01em',
    color: '#262E35',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
});

export const CommonInput = styled(props => (
  <TextField
    {...props}
    data-selenium-id='SIP_DetailPage_Submodule-WriteTable-PropsCommonInput'
  />
))({
  [`&.${textFieldClasses.root}`]: {
    // padding: "12px 12px 12px 12px"
  },
  '.MuiInputBase-input': {
    padding: '8px 20px 8px 20px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    // fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    letterSpacing: '0.01em',
    // color: '#262E35',
    // width: 'calc(100% - 24px)',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
});

export const EmailInput = styled(props => (
  <TextField
    {...props}
    data-selenium-id='SIP_DetailPage_Submodule-WriteTable-PropsEmailInput'
  />
))({
  [`&.${textFieldClasses.root}`]: {
    // padding: "12px 12px 12px 12px"
  },
  '.MuiInputBase-input': {
    padding: '8px 20px 8px 20px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    // fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    letterSpacing: '0.01em',
    // color: '#154AB6',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
});

/**
 *
 * @param {import("./WriteTable").EditableTableCellProps} props
 * @returns
 */
const EditableTableCell = props => {
  /**
   * 可编辑列（EditableRow）中的一个单元格————可编辑单元格
   */
  const {
    item,
    index,
    rowIndex,
    row,
    length,
    isAdding,
    setIsAdding,
    cellType,
    type,
    selected,
    setSelected,
    cellStyle,
    handleInfoChange,
    handleDeleteInfoChange,
    setDeleteDialogOpen,
    doEdit,
    doAdd,
    initEditInfo,
    hasError,
    showAction,
    firstClick,
    setFirstClick,
    clearLastLineBottom = false,
  } = props;

  //输入框类型
  const Type_BOLD = 'BOLD';
  const Type_TEXT = 'TEXT';
  const Type_EMAIL = 'EMAIL';
  const Type_UL = 'UL';
  const Type_ACTIONS = 'ACTIONS';

  const [value, setValue] = useState(row[item.id]);
  const [isSaving, setIsSaving] = useState();
  const dispatch = useDispatch();


  const selector = state => {
    return {
      afterClickSave: state.SIP.afterClickSave,
      authorNameErrorFlag: state.SIP.authorNameErrorFlag,
      affiliationErrorFlag: state.SIP.affiliationErrorFlag,
      emailErrorFlag: state.SIP.emailErrorFlag,
    };
  };

  const {
    afterClickSave,
    authorNameErrorFlag,
    affiliationErrorFlag,
    emailErrorFlag,
  } = useSelector(selector);

  const patternEmail =
    /^\w+((-\w+)|(\.\w+)|(_\w+))*@[A-Za-z0-9]+(([.\-])[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

  useEffect(() => {
    if (selected === false) {
      setValue(row[item.id]);
    }
  }, [selected]);

  const commonStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    flex: 0,
  };

  const boldStyle = {
    ...commonStyle,
    fontWeight: 400,
    letterSpacing: '0.01em',
    color: '#262E35',
    marginTop: '4px',
    marginBottom: '4px',
    marginLeft: '24px',
    marginRight: '4px',
  };

  const textStyle = {
    ...commonStyle,
    fontWeight: 400,
    letterSpacing: '0.01em',
    color: '#262E35',
    // marginTop:'12px',
    // marginBottom: '12px',
    // marginLeft: '24px',
    // marginRight: '24px',
  };

  const emailStyle = {
    ...commonStyle,
    // fontWeight: 600,
    letterSpacing: '0.01em',
    // color: '#154AB6',
    marginTop: '12px',
    marginBottom: '12px',
    marginLeft: '24px',
    marginRight: '24px',
  };

  const ulStyle = {
    ...commonStyle,
    fontWeight: 400,
    letterSpacing: '0.01em',
    color: '#262E35',
  };

  const commonInputStyle = {
    // width: '100%',
    background: '#FFFFFF',
    border: '1px solid #DFE4E8',
    borderRadius: '4px',
    marginTop: '4px',
    marginBottom: '4px',
    marginLeft: '4px',
    marginRight: '4px',
  };

  const textareaStyle = {
    width: '100%',
    background: '#FFFFFF',
    border: '1px solid #DFE4E8',
    borderRadius: '4px',
  };

  const handleAuthorEdit = () => {
    dispatch({ type: 'SET_POTENTIAL_AUTHOR_SELECTED_ROW', data: rowIndex });
    initEditInfo(row);
    dispatch({ type: 'SET_CLICK_AFTER_SAVE', data: false });
    dispatch({ type: 'SET_AUTHOR_NAME_ERROR', data: false });
    dispatch({ type: 'SET_AFFILIATION_ERROR', data: false });
    dispatch({ type: 'SET_EMAIL_ERROR', data: false });
    setSelected(true);
  };

  const handleAuthorDelete = () => {
    handleDeleteInfoChange(row['id'], row['name']);
    setDeleteDialogOpen(true);
  };

  const handleAuthorSave = async () => {
    setFirstClick(true);
    if (type === ADD_TYPE && isAdding) {
      dispatch({ type: 'SET_CLICK_AFTER_SAVE', data: true });
      if (hasError()) {
        return;
      }
      await doAdd(setIsSaving);
      setIsAdding(false);
      setFirstClick(false);
      return;
    }
    if (hasError()) {
      return;
    }
    await doEdit(setIsSaving, row['id']);
    setSelected(false);
    setFirstClick(false);
  };

  const handleAuthorCancel = () => {
    setFirstClick(false);
    if (type === ADD_TYPE && isAdding) {
      setIsAdding(false);
    } else {
      setSelected(false);
    }

    dispatch({ type: 'SET_CLICK_AFTER_SAVE', data: false });
    dispatch({ type: 'SET_AUTHOR_NAME_ERROR', data: false });
    dispatch({ type: 'SET_AFFILIATION_ERROR', data: false });
    dispatch({ type: 'SET_EMAIL_ERROR', data: false });
  };

  const handleBoldChange = e => {
    setValue(e.target.value);
    if (e.target.value == '') {
      dispatch({ type: 'SET_AUTHOR_NAME_ERROR', data: true });
    } else {
      dispatch({ type: 'SET_AUTHOR_NAME_ERROR', data: false });
    }
    // handleInfoChange(item.id, e.target.value)
  };

  const handleBoldBlur = e => {
    handleInfoChange(item.id, e.target.value, type);
  };

  const handleTextareaChange = e => {
    setValue(e.target.value);
    if (e.target.value == '') {
      dispatch({ type: 'SET_AFFILIATION_ERROR', data: true });
    } else {
      dispatch({ type: 'SET_AFFILIATION_ERROR', data: false });
    }
    // handleInfoChange(item.id, e.target.value)
  };

  const handleTextareaBlur = e => {
    handleInfoChange(item.id, e.target.value, type);
  };

  const handleEmailChange = e => {
    setValue(e.target.value);
    if (e.target.value == '' || patternEmail.test(e.target.value)) {
      dispatch({ type: 'SET_EMAIL_ERROR', data: false });
    } else {
      dispatch({ type: 'SET_EMAIL_ERROR', data: true });
    }
    // handleInfoChange(item.id, e.target.value)
  };

  const handleEmailBlur = e => {
    handleInfoChange(item.id, e.target.value, type);
  };

  const handleUlChange = e => {
    setValue(e.target.value);
    // handleInfoChange(item.id, e.target.value)
  };

  const handleUlBlur = e => {
    handleInfoChange(item.id, e.target.value, type);
  };

  const getCorrespondingCell = () => {
    switch (cellType) {
      case Type_BOLD:
        return (
          <Box data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-BOLD-Box'>
            {selected || (type == ADD_TYPE && isAdding) ? (
              <Box data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-BOLD-CommonInput'>
                {/* <CommonInput
                  variant='outlined'
                  style={commonInputStyle}
                  onChange={handleBoldChange}
                  onBlur={handleBoldBlur}
                  value={value}
                /> */}
                <input
                  style={{
                    // borderColor: ((!authorNameErrorFlag || value =='') && firstClick) ? '#EE5350' : '#DFE4E8',
                    // borderColor: ((value =='' || !value) && firstClick) ? '#EE5350' : '#DFE4E8',
                    borderColor: ((isAdding && afterClickSave && (value ==''|| !value)) ||
                  (!isAdding && firstClick && (value ==''|| !value)))  ? '#EE5350' : '#DFE4E8',
                    width: '212px',
                    height: '36px',
                    textAlign: 'left',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    marginLeft: '4px',
                    borderRadius: '4px',
                    padding: '8px 20px',
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '20px',
                  }}
                  className='SIPeditingInput'
                  onChange={handleBoldChange}
                  onBlur={handleBoldBlur}
                  value={value}
                ></input>
              </Box>
            ) : (
              // <Tooltip
              //   title={row[item.id]}
              //   placement='bottom-start'
              //   data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-BOLD-Tooltip'
              // >
                <Box
                  style={boldStyle}
                  data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-BOLD-ItemIdBox'
                >
                  {row[item.id]}
                </Box>
              // </Tooltip>
            )}
          </Box>
        );
      case Type_TEXT:
        return (
          <Box
            data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-TEXT-Box'>
            {selected || (type == ADD_TYPE && isAdding) ? (
              // <TextInput
              //   data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-TEXT-TextInput'
              //   style={textareaStyle}
              //   multiline
              //   value={value}
              //   onChange={handleTextareaChange}
              //   onBlur={handleTextareaBlur}
              //   marginLeft='22px'
              // />
              <input
                style={{
                  // borderColor: ((!affiliationErrorFlag || value =='') && firstClick)? '#EE5350' : '#DFE4E8',
                  // borderColor: ((value ==''|| !value) && firstClick)? '#EE5350' : '#DFE4E8',
                  borderColor: ((isAdding && afterClickSave && (value ==''|| !value)) ||
                  (!isAdding && firstClick && (value ==''|| !value)))  ? '#EE5350' : '#DFE4E8',
                  width: 'calc(100% - 8px)',
                  height: '36px',
                  textAlign: 'left',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  marginLeft: '4px',
                  borderRadius: '4px',
                  padding: '8px 20px',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
                className='SIPeditingInput'
                data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-TEXT-TextInput'
                multiline
                value={value}
                onChange={handleTextareaChange}
                onBlur={handleTextareaBlur}
              ></input>
            ) : (
              // <Tooltip
              //   title={row[item.id]}
              //   placement='bottom-start'
              //   data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-TEXT-Tooltip'
              // >
                <Box
                  style={textStyle}
                  marginLeft='22px'
                  data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-Text-ItemIdBox'
                >
                  {row[item.id]}
                </Box>
              // </Tooltip>
            )}
          </Box>
        );
      case Type_EMAIL:
        return (
          <Box data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-Email-Box'>
            {selected || (type == ADD_TYPE && isAdding) ? (
              // <EmailInput
              //   data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-Email-Input'
              //   variant='outlined'
              //   style={{
              //     width: '252px',
              //     ...commonInputStyle
              //   }}
              //   value={value}
              //   onChange={handleEmailChange}
              //   onBlur={handleEmailBlur}
              //   marginLeft='22px'
              // />
              <input
                style={{
                  borderColor: ((isAdding && afterClickSave && emailErrorFlag) ||
                  (!isAdding && firstClick && emailErrorFlag))  ? '#EE5350' : '#DFE4E8',
                  width: 'calc(100% - 8px)',
                  height: '36px',
                  textAlign: 'left',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  marginLeft: '4px',
                  borderRadius: '4px',
                  padding: '8px 20px',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
                className='SIPeditingInput'
                data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-Email-Input'
                variant='outlined'
                value={value}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
              ></input>
            ) : (
              <Box
                // style={emailStyle}
                sx={textStyle}
                marginLeft='22px'
                data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-Email-ItemIdBox'
                display='flex'
              >
                <CustomTooltip
                  title={row[item.id]}
                  placement='top'
                  data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-Email-Tooltip'
                  sx={{ translate: '0 12px' }}
                >
                  <Box
                    sx={{ flex: 0 }}>
                    {row[item.id]}
                  </Box>
                </CustomTooltip>
              </Box>
            )}
          </Box>
        );
      case Type_UL:
        return (
          <Box data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-UL-Box'>
            {selected || (type == ADD_TYPE && isAdding) ? (
              // <TextInput
              //   data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-UL-TextInput'
              //   style={textareaStyle}
              //   multiline
              //   value={value}
              //   onChange={handleUlChange}
              //   onBlur={handleUlBlur}
              //   marginLeft='22px'
              // />
              <input
                data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-UL-TextInput'
                style={{
                  borderColor: '#DFE4E8',
                  width: 'calc(100% - 8px)',
                  height: '36px',
                  textAlign: 'left',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  marginLeft: '4px',
                  borderRadius: '4px',
                  padding: '8px 20px',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '20px',
                }}
                className='SIPeditingInput'
                multiline
                value={value}
                onChange={handleUlChange}
                onBlur={handleUlBlur}

              ></input>
            ) : (
              // <Tooltip
              //   title={row[item.id]}
              //   placement='bottom-start'
              //   data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-UL-Tooltip'
              // >
                <Box
                  style={textStyle}
                  data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-UL-ItemIdBox'
                  marginLeft='22px'
                >
                  {row[item.id]}
                </Box>
              // </Tooltip>
            )}
          </Box>
        );
      case Type_ACTIONS:
        return (
          <Box
            sx={{ display: 'inline-block' }}
            data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-ACTIONs-Box'
          >
            {showAction() ? (
              selected || (type == ADD_TYPE && isAdding) ? (
                isSaving ? (
                  <CircularProgress
                    sx={{ mt: '12px' }}
                    size={20}
                    data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-ACTIONs-CircularProgress'
                  />
                ) : (
                  <Grid
                    container
                    data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-ACTIONs-ContainerGrid1'
                    sx={{ paddingLeft: '20px' }}
                  >
                    <IconButton
                      disableRipple
                      data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-ACTIONs-SaveIconButton'
                      onClick={handleAuthorSave}
                      disabled={
                        (isAdding && afterClickSave && hasError()) ||
                        (!isAdding && firstClick && hasError())
                      }

                      sx={{ 
                        padding: '3.1px',
                        '&:hover':{
                          'svg rect':{
                            fill: '#dfe4e8',
                          },
                        } 
                      }}
                    >
                      {
                        (isAdding && afterClickSave && hasError()) ||
                        (!isAdding && firstClick && hasError())?<YesDisable/>:<YesDefault/>
                      }
                    </IconButton>
                    <NoContainerButton
                      onClick={handleAuthorCancel}
                      data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-ACTIONs-CancelIconButton'
                      sx={{ padding: '3.1px' }}
                    ></NoContainerButton>
                    {/* <IconButton
                     
                    >
                      <AuthorEditCancel data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-ACTIONs-AuthorCancel' />
                    </IconButton> */}
                  </Grid>
                )
              ) : (
                <Grid
                  container
                  data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-ACTIONs-ContainerGrid2'
                  sx={{ paddingLeft: '20px' }}
                >
                  {/* <IconButton
                    onClick={handleAuthorEdit}
                    disabled={isAdding}
                    data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-ACTIONs-EditIconButton'
                  >
                    <SIPAuthorEdit data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-ACTIONs-AuthorEdit' />
                  </IconButton> */}
                  <EditContainerButton
                    onClick={handleAuthorEdit}
                    disabled={isAdding}
                    data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-ACTIONs-EditIconButton'
                    sx={{ padding: '3.1px' }}
                  ></EditContainerButton>
                  {/* <IconButton
                    onClick={handleAuthorDelete}
                    disabled={isAdding}
                    data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-ACTIONs-DeleteIconButton'
                  >
                    <SIPAuthorDelete data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-ACTIONs-AuthorDelete' />
                  </IconButton> */}
                  <DeleteContainerButton
                    onClick={handleAuthorDelete}
                    disabled={isAdding}
                    data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-ACTIONs-DeleteIconButton'
                    sx={{ padding: '3.1px' }}
                  ></DeleteContainerButton>
                </Grid>
              )
            ) : (
              null
            )}
          </Box>
        );
    }
  };

  return (
    <StyledTableCell
      data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-Styled'
      sx={{
        minWidth: item.minWidth,
        maxWidth: item.maxWidth,
        wordWrap: 'break-word',
        color: '#262E35',
        backgroundColor: selected || rowIndex == -1 ? '#E8EDFB' : 'white',
        paddingTop: '0px',
        paddingLeft: '0px',
        paddingBottom: '0px',
        paddingRight: '0px',
        borderBottom:clearLastLineBottom?'none':'', 
        ...cellStyle,
      }}
      align={item.align}
      index={index}
      length={length}
      actions={item.id === 'actions'}
    // cellStyle={}
    >
      <Box
        data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditCell-ACTIONs-Styled-Box'
        sx={{
          height: '100%',
          wordWrap: 'break-word',
          paddingLeft: '0px',
          paddingRight: '0px',
          textAlign: item.align,
        }}
        align={item.align}
      >
        {getCorrespondingCell()}
      </Box>
    </StyledTableCell>
  );
};

/**
 *
 * @param {import("./WriteTable").EditableRowProps} props
 * @returns
 */
function EditableRow(props) {
  /**
   * 可编辑的表（Editable）中的一行—————可编辑行
   * 创建该组件的目的是为了统一管理每一行的背景颜色
   */
  const {
    row,
    rowIndex,
    typeList,
    TableHeads,
    isAdding,
    setIsAdding,
    type,
    cellStyle,
    doEdit,
    handleInfoChange,
    handleDeleteInfoChange,
    setDeleteDialogOpen,
    initEditInfo,
    doAdd,
    hasError,
    showAction,
    firstClick,
    setFirstClick,
    clearLastLineBottom = false,
  } = props;

  /**
   * 该变量控制 编辑/新增 状态时每一行的背景颜色（a blue background color）
   * User Story原话：
   *      When a user clicks a cell, the cell becomes
   *      a shaded and rounded white rectangle with a blue
   *      background color for the corresponding row.
   */
  const [selected, setSelected] = useState(false);

  const selector = state => {
    return {
      potentialAuthorSelectedRow: state.SIP.potentialAuthorSelectedRow,
    };
  };
  const { potentialAuthorSelectedRow } = useSelector(selector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (potentialAuthorSelectedRow !== rowIndex) {
      setSelected(false);
    }
  }, [potentialAuthorSelectedRow]);

  const [hover, setHover] = useState(false);

  return (
    <TableRow
      key={rowIndex}
      data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditRow-Row'
      height='44px'
      onMouseEnter={()=>setHover(true)}
      onMouseLeave={()=>setHover(false)}
      sx={{
        background: hover?'#E8EDFB':'#FFFFFF',
        '&:hover': {
          background: '#E8EDFB'
        }
      }}
    >
      {TableHeads.map((item, index) => (
        <EditableTableCell
          data-selenium-id='SIP_DetailPage_Submodule-WriteTable-EditableCell'
          //Cell的类型，例如（PDNUMBER/LINK/PUBLICATIONDATE/VOLUME/ISSUE）
          cellType={typeList[item.id]}
          //每一列的类型
          item={item}
          //该Cell在表格第几行
          rowIndex={rowIndex}
          //该Cell在表格的第几列
          index={index}
          //列总长度
          length={TableHeads.length}
          //列所在行的数据
          row={row}
          //是否处于添加状态
          isAdding={isAdding}
          setIsAdding={setIsAdding}
          //该Cell所支持的编辑状态（EDIT或ADD）
          type={type}
          //控制背景颜色的变量和方法
          selected={selected}
          setSelected={setSelected}
          cellStyle={cellStyle}
          handleInfoChange={handleInfoChange}
          handleDeleteInfoChange={handleDeleteInfoChange}
          doEdit={doEdit}
          doAdd={doAdd}
          initEditInfo={initEditInfo}
          setDeleteDialogOpen={setDeleteDialogOpen}
          hasError={hasError}
          showAction={showAction}
          firstClick={firstClick}
          setFirstClick={setFirstClick}
          clearLastLineBottom={clearLastLineBottom}
        />
      ))}
    </TableRow>
  );
}

/**
 *
 * @param {import("./WriteTable").WriteTableProps} props
 * @returns
 */
function WriteTable(props) {
  /**
   * 可编辑的表
   */

  //从父组件获取的属性
  const {
    id,
    typeList,
    TableHeads,
    TableData,
    isAdding = false,
    setIsAdding,
    cellStyle,
    emptyTip,
    doEdit,
    doAdd,
    handleInfoChange,
    handleDeleteInfoChange,
    setDeleteDialogOpen,
    initEditInfo,
    hasError,
    showAction,
    firstClick,
    setFirstClick,
    clearLastLineBottom = false, //因为tablecell的底边和table底边重合会导致表的底边过粗，若该值为true,则让tablecell的底边不显示
  } = props;

  /**
   * 空白行，用于Publication Details的新增栏，其他Block，例如Submission Overview（没有新增功能）可以忽略
   */
  const spaceRow = () => {
    let res = {};
    for (let h in TableHeads) {
      res[h.id] = '';
    }
    return res;
  };

  const emptyStyle = {
    height: '20px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '1%',
    color: '#98A7B6',
    flex: 'none',
  };
  return (
    <Box
      sx={{ width: '100%' }}
      data-selenium-id='SIP_DetailPage_Submodule-WriteTable-Box'
    >
      <TableContainer
        id={id}
        data-selenium-id={`SIP_DetailPage_Submodule-WriteTable-Box-${id}`}
        sx={{
          borderRadius: '8px 8px 8px 8px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: '#DFE4E8',
          boxShadow: 'none'
        }}
      >
        <Table
          data-selenium-id='SIP_DetailPage_Submodule-WriteTable-Box-Table'
          stickyHeader
          aria-label='custom pagination table'
          sx={{
            minWidth: 500,
            letterSpacing: '0.01em',
            color: '#262E35',
            boxShadow: 'none'
          }}
        >
          <NewTableHeader
            data-selenium-id='SIP_DetailPage_Submodule-WriteTable-NewHeader'
            items={TableHeads}
            backgroundColor='#F1F3F5'
            color='#596A7C'
            CheckboxColor='wileyBlue2'
            letterSpacing='0.08em'
          />
          <TableBody data-selenium-id='SIP_DetailPage_Submodule-WriteTable-Box-Body'>
            {TableData.map((row, rowIndex) => (
              <EditableRow
                data-selenium-id='SIP_DetailPage_Submodule-WriteTable-Box-EditableRow1'
                row={row}
                rowIndex={rowIndex}
                typeList={typeList}
                TableHeads={TableHeads}
                type={EDIT_TYPE}
                isAdding={isAdding}
                cellStyle={cellStyle}
                doEdit={doEdit}
                handleInfoChange={handleInfoChange}
                handleDeleteInfoChange={handleDeleteInfoChange}
                initEditInfo={initEditInfo}
                setDeleteDialogOpen={setDeleteDialogOpen}
                hasError={hasError}
                showAction={showAction}
                firstClick={firstClick}
                setFirstClick={setFirstClick}
                clearLastLineBottom={rowIndex===TableData.length-1?clearLastLineBottom:false}
              />
            ))}
            {isAdding ? (
              <EditableRow
                data-selenium-id='SIP_DetailPage_Submodule-WriteTable-Box-EditableRow2'
                row={spaceRow()}
                rowIndex={-1}
                typeList={typeList}
                TableHeads={TableHeads}
                isAdding={isAdding}
                setIsAdding={setIsAdding}
                type={ADD_TYPE}
                doAdd={doAdd}
                handleInfoChange={handleInfoChange}
                cellStyle={cellStyle}
                hasError={hasError}
                showAction={showAction}
                firstClick={firstClick}
                setFirstClick={setFirstClick}
                clearLastLineBottom={clearLastLineBottom}
              />
            ) : null}

            {TableData.length <= 0 && !isAdding ? (
              <TableRow
                id='sipDetailPageNoDataText'
                data-selenium-id='SIP_DetailPage_Submodule-WriteTable-NoDataText'
                style={{}}
              >
                <TableCell
                  data-selenium-id='SIP_DetailPage_Submodule-WriteTable-Box-Row-Cell'
                  colSpan={5}
                  sx={{ textAlign: 'center', fontSize: '20px',borderBottom:'none' }}
                  style={emptyStyle}
                >
                  <FormattedMessage
                    id={`sipDetail.${emptyTip}`}
                    data-selenium-id={`SIP_DetailPage_Submodule-WriteTable-Box-${emptyTip}`}
                  />
                </TableCell>
              </TableRow>
            ) : (
              null
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default WriteTable;

import { COMMAND_NAME_SpecialChar } from './SpecialCharCommand';
import { useState } from 'react';
import React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import * as ReactDOM from 'react-dom';
import Button from '@mui/material/Button';
import { Box, styled, Tab, tabClasses, Tabs } from '@mui/material';

export const SC_PLUGIN_NAME = 'customSpecialCharacters';

const greekAlphabet = 'αβγδεζηθικλμνξοπρστυφχψωΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩ';
const mathAlphabet = '<>≤≥–—¯‾°−±÷⁄×ƒ∫∑∞√∼≅≈≠≡∈∉∋∏∧∨¬∩∪∂∀∃∅∇∗∝∠¼½¾';

function handleCharButtonClick(event, editor, char) {
  // console.log('Button clicked:', event);
  editor.model.change(writer => {
    const insertPosition = editor.model.document.selection.getFirstPosition();
    writer.insertText(char, insertPosition);
    let history = localStorage.getItem('RichText-History') || '';
    history = moveOrInsertCharacter(history, char);
    localStorage.setItem('RichText-History', history);
  });
}

function moveOrInsertCharacter(str, ch) {
  if (str.indexOf(ch)!==-1) {
    // 如果str中包含ch，将ch移到str开头并返回str
    const chIndex = str.indexOf(ch);
    const chRemoved = str.slice(0, chIndex) + str.slice(chIndex + 1);
    return ch + chRemoved;
  } else {
    // 如果str不包含ch，在str开头插入ch然后返回str
    return ch + str;
  }
}

const AlphabetButton = props => {
  const { editor, char } = props;

  return <Button sx={{
    // height: '16px !important',
    // width: '16px !important',
    minWidth: 'unset',
    p: '10px !important',
    background: '#F6F7F8',
    '&:hover':{
      borderRadius: '2px !important',
      background: '#DFE4E8 !important',
    },
  }} onClick={(e) => handleCharButtonClick(e, editor, char)}
  >
    <Box sx={{
      height: '16px !important',
      width: '16px !important',
      lineHeight: '16px !important',
      textAlign: 'center !important',
      color: '#596A7C',
    }} className={'custom-special-characters-button'} >{char}</Box>
  </Button>;
};

const alphaContextBoxStyles = {
  display: 'flex !important',
  flexWrap: 'wrap !important',
  alignContent: 'flex-start',
  justifyContent: 'flex-start',
  p: '8px !important',
  width: '278px !important',
  height: '124px !important',
  overflow: 'auto !important',
  background: '#F6F7F8',
};

const StaticAlphabet = props => {
  const { editor, charList } = props;

  return <Box sx={{
    pr: '3px !important',
  }}>
    <Box sx={alphaContextBoxStyles}>
      {charList.map((item, index) => (
        <AlphabetButton editor={editor} char={item} key={index}/>
      ))}
    </Box>
  </Box>;
};

const HistoryAlphabet = props => {
  const { editor } = props;
  const charHistory = localStorage.getItem('RichText-History') || '';
  const charList = Array.from(charHistory);

  return <Box sx={{
    pr: '3px !important',
  }}>
    <Box sx={alphaContextBoxStyles}>
      {charList.map((item, index) => (
        <AlphabetButton editor={editor} char={item} key={index}/>
      ))}
    </Box>
  </Box>;
};

const CustomSpecialCharactersView = props => {
  const { editor, ...others } = props;
  const [currentTab, setCurrentTab] = useState(2);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return <TabContext value={currentTab}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <TabList onChange={handleChange} aria-label="">
        <Tab label="History" value={1} />
        <Tab label="Greek" value={2} />
        <Tab label="Math" value={3} />
      </TabList>
    </Box>
    <TabPanel value={1}>
      <HistoryAlphabet editor={editor} />
    </TabPanel>
    <TabPanel value={2}>
      <StaticAlphabet editor={editor} charList={Array.from(greekAlphabet)}/>
    </TabPanel>
    <TabPanel value={3}>
      <StaticAlphabet editor={editor} charList={Array.from(mathAlphabet)}/>
    </TabPanel>
  </TabContext>;
};

export default function loadSpecialCharToolbarUI(EditorModule){
  const { Plugin  } = EditorModule.loadCore();
  const { createDropdown,  View } = EditorModule.loadUI();

  class DropdownTabView extends View {
    constructor( editor ) {
      super( editor.locale );
      this.editor = editor;

      const bind = this.bindTemplate;

      // Views define their interface (state) using observable attributes.
      this.set( 'elementClass', 'dropdown-tab-view' );

      this.setTemplate( {
        tag: 'div',
        // The element of the view can be defined with its children.
        children: ['Loading......'],
        attributes: {
          class: [
            'dropdown-tab-view-root',
            // Observable attributes control the state of the view in DOM.
            bind.to( 'elementClass' )
          ]
        },
      });
    }

    render() {
      const viewInstance = super.render();
      const container = this.element;
      ReactDOM.render(<CustomSpecialCharactersView editor={this.editor} />, container);

      console.info('Render:', viewInstance);

      return viewInstance;
    }
  }

  return class SpecialCharToolbarUI extends Plugin  {
    init() {
      this._createToolbarButton();
    }

    _createToolbarButton() {
      const editor = this.editor;
      // const command = editor.commands.get(COMMAND_NAME_SpecialChar);
      editor.ui.componentFactory.add(SC_PLUGIN_NAME, (locale) => {
        const dropdown = createDropdown(locale);

        dropdown.buttonView.set({
          label: 'Insert Symbol',
          withText: true,
          // tooltip: true,
          // icon: OmegaIcon,
          class: 'toolbar_button_special_char',
          icon: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Frame">
<path id="Vector" d="M1.5 15.0012H6.75146C4.25048 13.3633 3 11.3629 3 8.99984C3 5.45527 5.63126 2.99994 9.00311 2.99994C12.375 2.99994 15 5.62495 15 8.99984C15 11.2498 13.7508 13.2502 11.2525 15.0012H16.5" stroke="#0052CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>`,
        });

        dropdown.buttonView.on('click', (event) => {
         
        });

        const tabView = new DropdownTabView(editor);

        dropdown.panelView.children.add(tabView);

        dropdown.extendTemplate({
          attributes: { class: 'custom-special-characters' },
          items: '[aria-label="dropdown-list"]'
        });

        // 将按钮的状态关联到命令对应值上
        // view.bind("isOn", "isEnabled").to(command, "value", "isEnabled");
        // 点击按钮时触发相应命令
        // this.listenTo(view, "execute", () => editor.execute(COMMAND_NAME_SpecialChar, {value: ch}));
        return dropdown;
      });
    }
  };
}

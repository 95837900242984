import {  createRef,  useCallback,  useEffect,  useRef,  useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// styles
import {
  sip_overview_container,
  sip_overview_head,
  sip_overview_head_title,
  sip_overview_main,
} from './css';
import { StyledEngineProvider } from '@mui/material/styles';
// components
import { Box, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ExportButton } from './SIPOverviewButton';
import SIPOverviewTable from './SIPOverviewTable';
import ColumnsButton from './ColumnsButton';
import ColumnsModel from './ColumnsModel';
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import FilterButton from './Filter/FilterButton';
import FilterPage from './Filter/index';
import SIPOverviewTabs from './SIPOverviewTabs';
import { ResponsiveButton } from '@/components/StyledButton/SIMTButton';
import SSIPColumnsModel from "@/pages/SIPPage/Overview/SSIPColumnsModel";
import { loadSIPOverviewColumn } from "@/actions/SIMT-SIP/SIP/SipColumn";
// assets
import AddIconWhite from '../../../assets/AddIconWhite.svg';
// utils
import { WindowHeightContext } from '../../SIPage/Overview/Filter/Context';
import {PERMISSIONS} from "@/constant/permission";

const simplePatternThreshold = 1110;
const responsiveButtonSimplePatternThreshold = 1185;
const sideBarOpenWidth = 240;

/**
 * Description of the main component
 * @returns 
 */
export default function SIPOverview() {
  
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [hasOpen, setHasOpen] = useState(false);
  const [visibleHeight, setVisibleHeight] = useState(window.innerHeight);
  const [isSIPTableLoading, setIsSIPTableLoading] = useState(false);
  const [isClear, setIsClear] = useState(false); // 用于在filterPage和filterButton组件中传递‘是否clear’的信息
  const dispatch = useDispatch();
  const contextHolderRef = useRef(null);

  const selector = state => {
    return {
      userManageSiderOpen: state.UserManage.siderOpen,
      useFilters: state.SIPFilter.useFilters,
      columnsSelectedStore: state.SIP.columnsSelected,
    };
  };
  const {
    userManageSiderOpen,
    useFilters,
    columnsSelectedStore
  } = useSelector(selector);

  // add resize event handler
  useEffect(() => {
    const handleResize = () => {
      setVisibleHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    if (useFilters && !hasOpen) {
      const array = Object.keys(useFilters).filter(
        prop => useFilters[prop] !== undefined && useFilters[prop].length > 0
      );
      // 默认sip filter不展开，避免出现网络原因报错 2023-10-12 QA-bug
      // setShowFilter(!array.length>0);
    }
    if (showFilter) setHasOpen(true);
  }, [useFilters]);

  /***************  listen to the width change event of button box above (监听 顶部按钮box 的宽度变化) *********************************/
  const sip_overview_head_btnBox_monitor = createRef();
  // const handleWindowResize = useCallback(() => {
  //   if (!sip_overview_head_btnBox_monitor.current) {
  //     return;
  //   }
  //   // setSimplePattern(
  //   //   sip_overview_head_btnBox_monitor.current.clientWidth <=
  //   //   simplePatternThreshold
  //   // );
  // }, [sip_overview_head_btnBox_monitor]);
  // useEffect(() => {
  //   handleWindowResize();
  //   window.addEventListener('resize', handleWindowResize);
  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // }, [handleWindowResize]);
  /****************************************************************************/
  /***************  获取权限  **************************************************/
  const getPermission = () => {
    let userPermissions = localStorage.getItem('userPermissions');
    /**
     * admin： 只要含有 admin:write 就是admin
     * t1： 同时拥有report:read si:write
     */
    //2023-09-11：规则更改，不再根据角色来控制，而是根据权限来控制，详见：https://jira.wiley.com/browse/CT-4755
    //在src\pages\SIPPage\Overview\SIPOverviewTable\SIPOverviewTableBody.js还有一个相同的函数，别忘了改！！！
    if (userPermissions?.indexOf(PERMISSIONS.SIP_WRITE_JPA) !== -1) {
      return 'admin';
    } else if (
      userPermissions?.indexOf(PERMISSIONS.SIP_WRITE_HANDLING_CE) !== -1
    ) {
      return 'tier1';
    } else {
      return 'none';
    }
  };
  /****************************************************************************/

  // Load column settings
  const { sipOrigin } = useParams();
  useEffect(() => {
    dispatch(loadSIPOverviewColumn(sipOrigin === 'solicited'));
  }, [sipOrigin]);


  return (
    <>
      <WindowHeightContext.Provider value={visibleHeight + 19}>
        <StyledEngineProvider
          injectFirst
          data-selenium-id='SIPage_Overview-SIOverview'
        >
          <Grid container direction='row' justifyContent='space-between'>
            <Box
              data-selenium-id='SIPPage_Overview-Index-Box'
              sx={{
                ...sip_overview_container,
                marginLeft: userManageSiderOpen
                  ? `${sideBarOpenWidth}px`
                  : '70px',
                flexGrow: 1,
                paddingLeft: '22px',
                paddingRight: '25px',
                paddingBottom: '16px',
                paddingTop: '24px',
                minHeight: 'calc(100vh - 56px - 68px)',
              }}
            >
              {/* Title and box on the top (标题 和 顶部按钮box) */}
              <Box
                sx={sip_overview_head}
                data-selenium-id='SIPPage_Overview-Index-HeadBox'
              >
                {/* Title (标题) */}
                <Box sx={sip_overview_head_title}>
                  <FormattedMessage
                    id='sipPage.pageTitle'
                    data-selenium-id='SIPPage_Overview-Index-Box-Title'
                  />
                </Box>
                {/* 标签栏 */}
                <SIPOverviewTabs />
                <Stack direction='row' spacing={1.5} sx={{ paddingTop: '2px' }}>
                  <FilterPage
                    show={showFilter}
                    handleClose={() => {
                      setShowFilter(false);
                    }}
                    isClear={isClear}
                    setIsClear={setIsClear}
                  />
                  <Stack
                    sx={{
                      padding: '14px 0px 19px 0px',
                      height: '54px',
                      boxSizing: 'border-box',
                      background: 'transparent',
                      width: showFilter ? 'calc(100% - 600px)' : '100%',
                      marginLeft: showFilter ? '600px' : '0px',
                    }}
                  >
                    <Box
                      data-selenium-id='SIPPage_Overview-Index-BtnBox'
                      sx={{ float: 'left', width: '100%' }}
                      ref={sip_overview_head_btnBox_monitor}
                    >
                      {/* 顶部按钮box */}
                      <Stack
                        direction='row'
                        spacing={1.5}
                        sx={{ paddingBottom: '19px' }}
                      >
                        <ShowForPermission
                          permission={`${PERMISSIONS.SIP_READ}`}
                          data-selenium-id='SIPPage_Overview-SIPOverview-ShowForPermission'
                        >
                          <FilterButton
                            open={showFilter}
                            setOpen={setShowFilter}
                            isClear={isClear}
                            setIsClear={setIsClear}
                          />
                        </ShowForPermission>
                        <div>
                          <ColumnsButton
                            onClick={() => {
                              setColumnsOpen(true);
                            }}
                            isPrimary={true}
                          />
                        </div>
                        {/* this is 'Only show SIP to be assigned' checkbox, it is not used in the new design */}
                        {/*<ShowForPermission permission={`${PERMISSIONS.SIP_WRITE_JPA} ${PERMISSIONS.SIP_WRITE_HANDLING_CE}`}>*/}
                        {/*  <SIPOverviewCheckbox*/}
                        {/*    userPermission={getPermission()}*/}
                        {/*    sipOrigin={sipOrigin}*/}
                        {/*  />*/}
                        {/*</ShowForPermission>*/}
                        <div style={{ marginLeft: 'auto' }}>
                          <ExportButton
                            downBy={
                              responsiveButtonSimplePatternThreshold +
                              (userManageSiderOpen ? sideBarOpenWidth : 0)
                            }
                          />
                        </div>
                        {/* Create */}
                        {sipOrigin === 'solicited' ? (
                          <ShowForPermission permission={`${PERMISSIONS.SIP_WRITE_HANDLING_CE}`}>
                            <ResponsiveButton
                              id='btnCreateSIP'
                              data-selenium-id='SIPPage_Overview-SIPOverview-CreateButton'
                              color='wileyBlue2'
                              variant='contained'
                              startIcon={<AddIconWhite />}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '8px 12px !important',
                                gap: '6px',
                                minWidth: '48px',
                                height: '34px',
                                background: '#0052CC',
                                borderRadius: '5px',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '12px',
                                lineHeight: '18px',
                                textAlign: 'center',
                                color: '#FFFFFF',
                                flex: 'none',
                                order: 1,
                                flexGrow: 0,
                                '& .MuiButton-startIcon': {
                                  margin: 0,
                                },
                                '&:hover': {
                                  background: '#6D98EE',
                                },
                                '&:active': {
                                  background: '#113D95',
                                },
                                marginRight: userManageSiderOpen
                                  ? '10px'
                                  : '20px',
                              }}
                              onClick={() => {
                                window.open(
                                  '/simt/auth/sipRelated/sip/createSIPPage'
                                );
                              }}
                            >
                              {!userManageSiderOpen && (
                                <FormattedMessage id='common.create' />
                              )}
                            </ResponsiveButton>
                          </ShowForPermission>
                        ) : null}
                      </Stack>
                      {/* main content (主体内容部分) */}
                      <Box
                        sx={sip_overview_main}
                        data-selenium-id='SIPPage_Overview-Index-MainBox'
                      >
                        <SIPOverviewTable
                          ref={contextHolderRef}
                          showFilter={showFilter}
                          loading={isSIPTableLoading}
                          setLoading={setIsSIPTableLoading}
                        ></SIPOverviewTable>
                      </Box>
                    </Box>
                  </Stack>
                </Stack>
              </Box>

              {/* Column setting Panel/Modal */}
              {sipOrigin === 'solicited' ? (
                <SSIPColumnsModel
                  open={columnsOpen}
                  handleClose={() => {
                    setColumnsOpen(false);
                  }}
                  columnsSelectedStore={columnsSelectedStore}
                />
              ) : (
                <ColumnsModel
                  open={columnsOpen}
                  handleClose={() => {
                    setColumnsOpen(false);
                  }}
                  columnsSelectedStore={columnsSelectedStore}
                />
              )}
            </Box>
          </Grid>
        </StyledEngineProvider>
      </WindowHeightContext.Provider>
    </>
  );
}

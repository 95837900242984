import {CardContent, FormControl, Grid, ListItem, Stack, useMediaQuery,} from '@mui/material';
import {Box} from '@mui/system';
import * as React from 'react';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import InputNullFlag from '@/assets/InputNullFlag.svg';

import {blankTrim, trimItemFilds} from '@/utils/commonUtils';
import {CreateAndUpdateInput} from '@/componentsphase2/OnlyText';
import {MulSelectInput} from '@/componentsphase2/SelectBox/SelectInput';

import {CancelBtn, CVUpload, Description2,} from './Commponents';
import {UntitledAlertBox} from '@/components/Alert/AlertBox';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import SingleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import {LoadingButton} from '@mui/lab';
import {createKeyword, getKeywords, getAllKeywords} from "@/actions/SIMT-SI/GuestEditor/KeyWord";
import {
  createDepartment,
  createInstitute,
  getDepartmentList,
  getInstituteList
} from "@/actions/SIMT-SI/GuestEditor/GuestEditorCreate";
import {getCountryList} from "@/actions/SIMT-SI/GuestEditor/Country";
import {getBasicInformation} from "@/actions/SIMT-SI/GuestEditor";
import {editGEBasic} from "@/actions/SIMT-SI/GuestEditor/GEEditDetail";
import {getFlaglist, getGEGenderConfig, getGETitleConfig} from "@/actions/SIMT-SI/GuestEditor/Config";
import { PATTERNORCID, PATTERNWEBSITE } from '@/constant/common';

export const OnlyTextForGEDetailPage = props => {
  return (
    <MyOnlyText
      {...props}
      fontSize={14}
      height='33px'
      textFieldPadding='16px 12px'
    />
  );
};

// Customized autocomplete for GEDetailPage
// TODO: 1. add selected effect for option
//       2. Change font color and size
//       3. add Item Method, How to Realize
const SingleAutocompleteForGEDetailPage = props => {
  const config = {
    outlinedInputProps: {
      outlinedInputSX: {
        height: '33px',
        padding: '8px  34px 8px 12px !important',
      },
    },
    endAttchmentProps: {
      endAttchmentSX: {
        top: '8.5px',
      },
    },
    dropListProps: {
      listBoxUlSX: {
        li: {
          color: 'var(--gray-500, #596A7C)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px' /* 166.667% */,
        },
        // liHover: {
        // },
        liActive: {
          color: 'var(--gray-600, #262E35)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px' /* 166.667% */,
        },
        liHover: {
          color: 'var(--gray-600, #262E35)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px' /* 166.667% */,
        },
        // liSelectedHover: {},
      },
    },
  };
  return (
    <SingleAutocomplete {...props} config={{ ...props.config, ...config }} />
  );
};

const maxEmailLength = 100;
const ORCIDLengthLimit = 30;
const NotesLengthLimit = 5000;
const ResearchGateLengthLimit = 500;

function extraValueList() {
  return (list, key) => {
    let valueList = [];
    for (let i in list) {
      valueList.push(list[i][key]);
    }
    return valueList;
  };
}

function extraValueEffactJudge() {
  return (value, list) => {
    if (value === null) {
      return value;
    }
    if (list.indexOf(value) > -1) {
      return value;
    } else {
      return null;
    }
  };
}

function judgeMustNoNull() {
  return key => {
    let flag = false;
    if (
        // key === "Gender" ||
        key === 'First Name' ||
        key === 'Last Name' ||
        key === 'Institution' ||
        key.indexOf('Country') !== -1 ||
        key === 'Primary Email' ||
        key === 'Research Keywords'
    ) {
      flag = true;
    }
    // return keyTitle;
    return (
        <>
        <span data-selenium-id="GEPage_GEDetailPage-BasicInfoBlock-JudgeMustNotNull">
          {key === 'GE code' ? 'GE Code' : key}
        </span>
          {flag ? <span style={{color: '#C40000'}}> * </span> : <></>}
          <>&nbsp;:</>
        </>
    );
  };
}

function CheckError(flagFirstName, flagLastName, flagInstitute, flagCountry, flagPrimaryEmail, flagOkPrimaryEmail, flagOkSecondaryEmail, flagResearchKeywords, flagORCID) {
  return () => {
    return (
        flagFirstName ||
        flagLastName ||
        flagInstitute ||
        flagCountry ||
        flagPrimaryEmail ||
        flagOkPrimaryEmail ||
        flagOkSecondaryEmail ||
        flagResearchKeywords || 
        flagORCID
    );
  };
}

const BasicInforBlock = forwardRef((props, ref) => {
  const {
    GEBasicInformation,
    isEdit,
    setIsEdit,
    error,
    setError,
    saveClick,
  } = props;
  useSelector(state => state.Common.keyMap);
  const [flagORCID, setFlagORCID] = useState(false);
  const [flagFirstName, setFlagFirstName] = useState(false);

  const [flagInstitute, setFlagInstitute] = useState(false);
  const [flagCountry, setFlagCountry] = useState(false);
  const [flagProfileWebsite, setFlagProfileWebsite] = useState(false);
  const [flagPrimaryEmail, setFlagPrimaryEmail] = useState(false);

  const [flagLastName, setFlagLastName] = useState(false);
  const [flagGender] = useState(false);


  const [flagOkPrimaryEmail, setFlagOkPrimaryEmail] = useState(false);
  const [flagOkSecondaryEmail, setFlagOkSecondaryEmail] = useState(false);

  const [flagSecondaryEmail, setFlagSecondaryEmail] = useState(false);
  const [flagResearchKeywords, setFlagResearchKeywords] = useState(false);
  const [flagGateIdCharLimits, setFlagGateIdCharLimits] = useState(false);
  const [flagORCIDCharLimits, setFlagORCIDCharLimits] = useState(false);

  const [flagDepartCharLimits] = useState(false);
  const [flagInstiCharLimits] = useState(false);
  const [flagProfileLimits, setFlagProfileLimits] = useState(false);
  const [flagNotesLimits, setFlagNotesLimits] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [instituteFocus, setInstituteFocus] = useState(false);
  const [countryFocus, setCountryFocus] = useState(false);
  useEffect(() => {
    setShowErrorMessage(false);
  }, [isEdit]);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [title, setTitle] = useState({});
  const [gender, setGender] = useState({});
  const [department, setDepartment] = useState({});
  const [DepartmentOpen, setDepartmentOpen] = useState(false);
  const [institute, setInstitute] = useState({});
  const [InstituteOpen, setInstituteOpen] = useState(false);
  const [city, setCity] = useState('');
  const [country, setCountry] = useState({});
  const [primaryEmail, setPrimaryEmail] = useState('');
  const [secondaryEmail, setSecondaryEmail] = useState('');
  const [researchKeywords, setResearchKeywords] = useState([]);
  const [profileWebsite, setProfileWebsite] = useState('');
  const [ORCID, setORCID] = useState('');
  const [researchGate, setResearchGate] = useState('');
  const [flags, setFlags] = useState([]);
  const [notes, setNotes] = useState('');
  const [keywordsOpen, setKeywordsOpen] = useState(false); //用来设置Research Keywords弹出框是否显示
  const [cvfileName,setCvfileName] = useState(null);
  const [loadingBasicInformation, setLoadingBasicInformation] = useState(false);


  const primaryEmailInput = value => {
    if (blankTrim(value).length > maxEmailLength) {
      return;
    }
    setPrimaryEmail(blankTrim(value));
  };

  const secondEmailInput = value => {
    if (blankTrim(value).length > maxEmailLength) {
      return;
    }
    setSecondaryEmail(blankTrim(value));
  };

  const selector = state => {
    return {
      keywords: state.GE.keywords,
      allKeywords: state.GE.allKeywords,
      countryList: state.GE.countryList,
      isEmailMessage: state.GE.isEmailMessage,
      isEmailMessageGet: state.GE.isEmailMessageGet,
      flagsList: state.GE.flagsList,
      departmentList: state.GE.departmentList,
      instituteList: state.GE.instituteList,
      geTitleConfigDropdownList: state.GE.geTitleConfigDropdownList,
      geGenderConfigDropdownList: state.GE.geGenderConfigDropdownList,
      sideMenuOpen: state.UserManage.siderOpen,
    };
  };

  const {
    keywords,
    allKeywords,
    countryList,
    isEmailMessage,
    isEmailMessageGet,
    flagsList,
    departmentList,
    instituteList,
    geTitleConfigDropdownList,
    geGenderConfigDropdownList,
    sideMenuOpen,
  } = useSelector(selector);

  const addNewDepartmentManager = useSelector(state => ({
    newData: state.GE.newDepartment,
    itemExist: state.GE.newDepartmentExits,
    dataGet: state.GE.isNewDepartmentGet,
    addNewOption: value => {
      dispatch(createDepartment(value));
      setDepartmentOpen(true);
      setDepartment({id: 0, department: value});
    },
    afterAddNewOption: () => {},
    afterDateGetSucess: () => {},
  }));

  const addNewInstituteManager = useSelector(state => ({
    newData: state.GE.newInstitute,
    itemExist: state.GE.newInstituteExits,
    dataGet: state.GE.isNewInstituteGet,
    addNewOption: value => {
      dispatch(createInstitute(value));
      setInstituteOpen(true);
      setInstitute({ id: 0, institute: value });
    },
    afterAddNewOption: () =>  {},
    afterDateGetSucess: () => {},
  }));

  const addNewKeywordManager = useSelector(state => ({
    newData: state.GE.newKeyword,
    itemExist: state.GE.newKeywordExits,
    dataGet: state.GE.isNewKeywordGet,
    addNewOption: value => {
      dispatch(createKeyword(value));
      setKeywordsOpen(true);
    },
    afterAddNewOption: () => {},
    afterDateGetSucess: () => {},
  }));

  const dispatch = useDispatch();

  const ErrorMessage = ({ message }) => {
    return (
      <div style={{ display: 'flex' }}>
        <InputNullFlag
          style={{
            marginTop: '5px',
          }}
        />
        <Box sx={{
          marginTop: '2px',
          marginLeft: '1px',
          width: '274px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '16px',
          color: '#EE5350',
        }}>
          {'\u00A0'}
          {message}
        </Box>
      </div>
    );
  };

  const basicInfoLeftList = [
    {
      key: 'GE code',
      value: '',
      properity: 'geCode',
      isEdit: false,
    },
    {
      key: 'First Name',
      value: '',
      properity: 'firstName',
      isEdit: true,
      type: 'TEXT',
      isTooltip: true,
    },
    {
      key: 'Last Name',
      value: '',
      properity: 'lastName',
      isEdit: true,
      type: 'TEXT',
      isTooltip: true,
    },
    {
      key: 'Title',
      value: '',
      properity: 'title',
      isEdit: true,
      type: 'SELECT',
    },
    {
      key: 'Gender',
      value: '',
      properity: 'gender',
      isEdit: true,
      type: 'SELECT',
    },
    {
      key: 'Department',
      value: '',
      properity: 'department',
      isEdit: true,
      type: 'AutoText',
      isTooltip: true,
    },
    {
      key: 'Institution',
      value: '',
      properity: 'institute',
      isEdit: true,
      type: 'AutoText',
      isTooltip: true,
    },
    {
      key: 'City',
      value: '',
      properity: 'city',
      isEdit: true,
      type: 'TEXT',
    },
    {
      key: 'Country/Region',
      value: '',
      properity: 'country',
      isEdit: true,
      type: 'AutoText',
    },
  ];
  const basicInfoRightList = [
    {
      key: 'Primary Email',
      value: '',
      properity: 'primaryEmail',
      isEdit: true,
      type: 'TEXT',
      isTooltip: true,
    },
    {
      key: 'Secondary Email',
      value: '',
      properity: 'secondaryEmail',
      isEdit: true,
      type: 'TEXT',
      isTooltip: true,
    },
    {
      key: 'Research Keywords',
      value: '',
      properity: 'researchKeywordsList',
      isEdit: true,
      type: 'TEXT',
      isTooltip: true,
    },
    {
      key: 'Profile website',
      value: '',
      properity: 'profileWebsite',
      isEdit: true,
      type: 'TEXT',
      isTooltip: true,
      ifLink: true,
    },
    {
      key: 'ORCID ID',
      value: '',
      properity: 'orcidId',
      isEdit: false,
      type: 'TEXT',
    },
    {
      key: 'ResearchGate ID',
      value: '',
      properity: 'researchGateId',
      isEdit: false,
      type: 'TEXT',
    },
    {
      key: 'Notes',
      value: '',
      properity: 'notes',
      isEdit: true,
      type: 'TEXT',
      isTooltip: true,
    },
    {
      key: 'CV',
      value:'',
      properity:'cvFileName',
      isEdit:true,
      type:'TEXT',
      isTooltip: false,
    }
  ];

  const getBasicInfo = () => {
    if (GEBasicInformation !== undefined) {
      for (let item of basicInfoLeftList) {
        item.value = GEBasicInformation[item.properity];
      }
      for (let item of basicInfoRightList) {
        if (
          item.properity === 'researchKeywordsList' ||
          item.properity === 'flags'
        ) {
          if (
            GEBasicInformation[item.properity] === null ||
            GEBasicInformation[item.properity] === undefined
          ) {
            item.value = '';
          } else {
            if (item.properity === 'flags') {
              for (let flag_id in GEBasicInformation.flags) {
                item.value += GEBasicInformation.flags[flag_id] + ',';
              }
            } else {
              for (let keyword of GEBasicInformation[item.properity]) {
                item.value += keyword + ', ';
              }
            }
          }
          item.value = item.value.substring(0, item.value.length - 2);
        } else {
          item.value = GEBasicInformation[item.properity];
        }
      }
    }
  };

  const [titleList, setTitleList] = useState([]);

  const [genderList, setGenderList] = useState([]);

  // const flagsList = [
  //   { id: 1, keyword: "Plagiarism" },
  //   { id: 2, keyword: "Redundant/Duplication" },
  //   { id: 3, keyword: "Questionable/Unethical research" },
  //   { id: 4, keyword: "Data fabrication/falsification" },
  //   { id: 5, keyword: "Conflicts of interest" },
  //   { id: 6, keyword: "Copyright Infringement" },
  //   { id: 7, keyword: "Papermills" },
  //   { id: 8, keyword: "Authorship Disputes" },
  // ];
  //profile website验证格式的正则表达式
  const patternLink = PATTERNWEBSITE;

  //验证邮箱格式的正则表达式
  const patternEmail =
      /^\w+((-\w+)|(\.\w+)|(_\w+))*@[A-Za-z0-9]+(([.\-])[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

  function containsNewline(str) {
    // 使用正则表达式检查是否包含换行符
    const newlinePattern = /[\r\n]/;
    return newlinePattern.test(str);
  }

  // const patternORCID = /^[a-zA-Z0-9\-]{1,30}$/;
  const patternORCID = PATTERNORCID;

  useEffect(() => {
    getKeywordList('').then(() =>{} );
  }, []);

  useEffect(() => {
    if (
      (typeof ORCID === 'string' && ORCID.length === 0) ||
      patternORCID.test(ORCID)
    ) {
      setFlagORCID(false);
    } else {
      setFlagORCID(true);
    }
    if (typeof ORCID === 'string' && ORCID.length > ORCIDLengthLimit) {
      setFlagORCIDCharLimits(true);
    } else {
      setFlagORCIDCharLimits(false);
    }
  }, [ORCID]);

  useEffect(() => {
    if (typeof firstName === 'string' && firstName.length > 0) {
      setFlagFirstName(false);
    } else {
      setFlagFirstName(true);
    }
  }, [firstName]);

  useEffect(() => {
    if (typeof lastName === 'string' && lastName.length > 0) {
      setFlagLastName(false);
    } else {
      setFlagLastName(true);
    }
  }, [lastName]);

  useEffect(() => {
    if (typeof notes === 'string' && notes.length > NotesLengthLimit) {
      setFlagNotesLimits(true);
    } else {
      setFlagNotesLimits(false);
    }
  }, [notes]);


  useEffect(() => {
    if (
      institute &&
      typeof institute.institute === 'string' &&
      institute.institute.length > 0
    ) {
      setFlagInstitute(false);
    } else {
      setFlagInstitute(true);
    }
  }, [institute]);

  useEffect(() => {
    if (
      country &&
      typeof country.name === 'string' &&
      country.name.length > 0
    ) {
      setFlagCountry(false);
    } else {
      setFlagCountry(true);
    }
  }, [country]);

  useEffect(() => {
    if (typeof profileWebsite === 'string' && profileWebsite.length > 0) {
      (patternLink.test(profileWebsite) && !containsNewline(profileWebsite)) 
        ? setFlagProfileWebsite(false)
        : setFlagProfileWebsite(true);
      if (profileWebsite.length > 1000) {
        setFlagProfileLimits(true);
      } else {
        setFlagProfileLimits(false);
      }
    } else {
      setFlagProfileWebsite(false);
    }
  }, [profileWebsite]);

  useEffect(() => {
    if (typeof primaryEmail === 'string' && primaryEmail.length > 0) {
      setFlagPrimaryEmail(false);
      if (patternEmail.test(primaryEmail)) {
        setFlagOkPrimaryEmail(false);
      } else {
        setFlagOkPrimaryEmail(true);
      }
    } else {
      setFlagPrimaryEmail(true);
      setFlagOkPrimaryEmail(false);
    }
  }, [primaryEmail]);
  useEffect(() => {
    if (
      GEBasicInformation.primaryEmail !== primaryEmail &&
      isEmailMessage.message === 'the email address has been registered'
    ) {
      setFlagOkPrimaryEmail(true);
    } else if (!flagOkPrimaryEmail) {
      setFlagOkPrimaryEmail(false);
    }
  }, [isEmailMessageGet]);

  useEffect(() => {
    if (secondaryEmail === primaryEmail) {
      setFlagSecondaryEmail(true);
      setFlagOkSecondaryEmail(false);
    } else {
      setFlagSecondaryEmail(false);
      if (typeof secondaryEmail === 'string' && secondaryEmail.length > 0) {
        if (patternEmail.test(secondaryEmail)) {
          setFlagOkSecondaryEmail(false);
        } else {
          setFlagOkSecondaryEmail(true);
        }
      } else {
        setFlagOkSecondaryEmail(false);
      }
    }
  }, [secondaryEmail, primaryEmail]);

  useEffect(() => {
    if (researchKeywords.length > 0) {
      setFlagResearchKeywords(false);
    } else {
      setFlagResearchKeywords(true);
    }
  }, [researchKeywords]);

  useEffect(() => {
    if (typeof researchGate === 'string' && researchGate.length > ResearchGateLengthLimit) {
      setFlagGateIdCharLimits(true);
    } else {
      setFlagGateIdCharLimits(false);
    }
  }, [researchGate]);

  // 1168 is not enough, 1253 instead
  const matches1168 = useMediaQuery('(min-width:1253px)');
  // 1368 is not enough, 1410 instead
  const matches1368 = useMediaQuery('(min-width:1410px)');
  const [listInOneColumn, setListInOneColumn] = useState(false);

  useEffect(() => {
    if ((!sideMenuOpen && matches1168) || (sideMenuOpen && matches1368))
      setListInOneColumn(true);
    else setListInOneColumn(false);
  }, [sideMenuOpen, matches1368, matches1168]);

  useEffect(() => {
    if (
      flagORCID ||
      flagFirstName ||
      flagLastName ||
      flagInstitute ||
      flagCountry ||
      flagProfileWebsite ||
      flagPrimaryEmail ||
      flagResearchKeywords ||
      flagSecondaryEmail ||
      flagGender ||
      flagOkPrimaryEmail ||
      flagOkSecondaryEmail ||
      flagGateIdCharLimits ||
      flagORCIDCharLimits ||
      flagDepartCharLimits ||
      flagInstiCharLimits ||
      flagProfileLimits ||
      flagNotesLimits
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [
    flagORCID,
    flagFirstName,
    flagLastName,
    flagInstitute,
    flagCountry,
    flagProfileWebsite,
    flagPrimaryEmail,
    flagResearchKeywords,
    flagSecondaryEmail,
    flagGender,
    flagOkSecondaryEmail,
    flagOkPrimaryEmail,
    flagGateIdCharLimits,
    flagORCIDCharLimits,
    flagDepartCharLimits,
    flagInstiCharLimits,
    flagProfileLimits,
    flagNotesLimits,
  ]);
  const getKeywordList = async keywordsValue => {
    dispatch(getKeywords(keywordsValue));
    dispatch(getAllKeywords(keywordsValue));
    dispatch(getFlaglist());
    dispatch(getCountryList());
  };

  const handleCreateKeywordsPageClose = () => {
    setKeywordsOpen(false);
  };

  //用来关闭添加RInstitute的弹出框
  const handleCreateInstitutePageClose = () => {
    setInstituteOpen(false);
  };

  //用来关闭添加RInstitute的弹出框
  const handleCreateDepartmentPageClose = () => {
    setDepartmentOpen(false);
  };



  useEffect(() => {
    dispatch(getGETitleConfig());
    dispatch(getGEGenderConfig());
  }, []);

  useEffect(() => {
    if (
      geTitleConfigDropdownList !== null &&
      geTitleConfigDropdownList !== 'error'
    ) {
      setTitleList(geTitleConfigDropdownList);
    }
  }, [geTitleConfigDropdownList]);

  useEffect(() => {
    if (
      geGenderConfigDropdownList !== null &&
      geGenderConfigDropdownList !== 'error'
    ) {
      setGenderList(geGenderConfigDropdownList);
    }
  }, [geGenderConfigDropdownList]);

  useEffect(() => {
    if (isEdit) {
      handleEditChange();
    } else if (!saveClick) {
      handleCancelChange();
    }
  }, [isEdit]);

  useEffect(() => {
    if (saveClick) {
      handleSaveChange();
    }
  }, [saveClick]);

  useImperativeHandle(ref, () => ({
    handleCancel: () => {
      handleCancelChange();
    },
    handleSubmit: () => {
      handleSaveChange();
    },
  }));
  function judgeLeftValueComponent(key) {
    switch (key) {
      case 'GE code':
        return (
          <Box
            sx={{
              width: '286x',
              textAlign: 'left',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '120%',
              color: '#262E35',
              wordWrap: 'break-word',
            }}
            data-selenium-id='GEPage_GEDetailPage-BasicInfoBlock-JudgeLeftValueComponent-GECodeBox'
          >
            {GEBasicInformation === undefined ? '' : GEBasicInformation.geCode}
          </Box>
        );
      case 'First Name':
        return (
          <Box>
            <CreateAndUpdateInput
              id='createAndUpdateInputFirstName'
              inputWidth='286px'
              inputHeight='33px'
              inputPadding='4px 12px 4px 12px'
              placeholder={key}
              needX={true}
              required
              value={firstName}
              setValue={setFirstName}
              error={flagFirstName && showErrorMessage}
              setError={setFlagFirstName}
            />
            {flagFirstName && showErrorMessage ? (
              <ErrorMessage message={'The first name is mandatory to input'} />
            ) : null}
          </Box>
        );
      case 'Last Name':
        return (
          <Box>
            <CreateAndUpdateInput
              id='createAndUpdateInputLastName'
              inputWidth='286px'
              inputHeight='33px'
              inputPadding='4px 12px 4px 12px'
              placeholder={key}
              needX={true}
              required
              value={lastName}
              setValue={setLastName}
              error={flagLastName && showErrorMessage}
              setError={setFlagLastName}
            />
            {flagLastName && showErrorMessage ? (
              <ErrorMessage message={'The last name is mandatory to input'} />
            ) : null}
          </Box>
        );
      case 'Title':
        return (
          <Box data-selenium-id='GEPage_GEDetailPage-BasicInfoBlock-PullListTextField-Box'>
            <FormControl
              sx={{ width: '286px' }}
              data-selenium-id={`GEPage_GEDetailPage-BasicInfoBlock-PullListTextField-Box-FormControl-title`}
            >
              <SingleAutocompleteForGEDetailPage
                placeholder={key}
                id='titleSelect'
                value={title}
                options={titleList}
                freeSolo={true}
                createFreeSoloOption={(v) => ({
                  value: v,
                })}
                isEmpty={
                  title === undefined ||
                    title === null ||
                    title.value === null ||
                    title.value === undefined ||
                    title.value === ''
                }
                loadOptionAction={getGETitleConfig}
                getOptionLabel={option => option.value}
                getListOptionLabel={option => option.value}
                onChange={(e, newValue) => {
                  setTitle(newValue);
                }}
                disableNewItem={true}
                // readOnly={true}
              />
            </FormControl>
          </Box>
        );
      case 'Gender':
        return (
          <Box data-selenium-id='GEPage_GEDetailPage-BasicInfoBlock-PullListTextField-Box'>
            <FormControl
              sx={{ width: '286px' }}
              data-selenium-id={`GEPage_GEDetailPage-BasicInfoBlock-PullListTextField-Box-FormControl-gender`}
            >
              <SingleAutocompleteForGEDetailPage
                placeholder={key}
                id='genderSelect'
                value={gender}
                options={genderList}
                isEmpty={
                  gender === undefined || gender === null || gender === '' ||
                  gender.value === null || gender.value === undefined || gender.value === ''
                    ? true
                    : false
                }
                loadOptionAction={getGEGenderConfig}
                getOptionLabel={option => option.value}
                getListOptionLabel={option => option.value}
                onChange={(e, newValue) => {
                  setGender(newValue);
                }}
                disableNewItem={true}
                // readOnly={true}
              />
            </FormControl>
            {flagGender ? (
              <ErrorMessage message={'The gender must be chosen one'} />
            ) : (
              <Box />
            )}
          </Box>
        );
      case 'Department':
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '286px',
              height: '33px',
            }}
            data-selenium-id={
              'GEPage_GEDetailPage-BasicInfoBlock-JudgeLeftValueComponent-DepartmentBox'
            }
          >
            <FormControl
              sx={{ width: '286px' }}
              data-selenium-id={`GEPage_GEDetailPage-BasicInfoBlock-PullListTextField-Box-FormControl-Department`}
            >
              <SingleAutocompleteForGEDetailPage
                placeholder={key}
                id='CreateGeDepartmentInput'
                value={department}
                options={departmentList}
                isEmpty={department === undefined ||
                  department === null ||
                  department === '' ||
                  department.department === undefined ||
                  department.department === null ||
                  department.department === ''
                    ? true
                    : false}
                loadOptionAction={getDepartmentList}
                getOptionLabel={option =>
                  option ? (option.department ? option.department : '') : ''
                }
                getListOptionLabel={option => option.department}
                onChange={(e, newValue) => {
                  if(newValue === undefined ||
                    newValue === null ||
                    newValue === '' ) setDepartment({ id: 0, department: newValue });
                  else setDepartment(newValue);
                }}
                disableNewItem={false}
                addNewItemManager={addNewDepartmentManager}
                config={{ popupIcon: null }}
              />
            </FormControl>
          </Box>
        );
      case 'Institution':
        return (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', width: '286px' }}
            data-selenium-id={
              'GEPage_GEDetailPage-BasicInfoBlock-JudgeLeftValueComponent-InstituteBox'
            }
          >
            <SingleAutocompleteForGEDetailPage
              placeholder={key}
              id='CreateGeInstituteInput'
              value={institute}
              options={instituteList}
              isEmpty={
                institute === undefined ||
                  institute === null ||
                  institute.institute === ''
              }
              loadOptionAction={getInstituteList}
              getOptionLabel={option => option.institute}
              getListOptionLabel={option => option.institute}
              onChange={(e, newValue) => {
                setInstitute(newValue);
              }}
              isErr={flagInstitute && showErrorMessage && !instituteFocus}
              config={{ popupIcon: null }}
              disableNewItem={false}
              addNewItemManager={addNewInstituteManager}
              onFocus={() => {
                setInstituteFocus(true);
              }}
              onBlur={() => {
                setInstituteFocus(false);
              }}
            />
            {flagInstitute && !instituteFocus && showErrorMessage ? (
              <ErrorMessage
                message={'The Institute must be chosen at least one'}
              />
            ) : (
              <Box></Box>
            )}
          </Box>
        );
      case 'City':
        return (
          <Box>
            <OnlyTextForGEDetailPage
              placeholder={key}
              value={city}
              setValue={setCity}
              width='286px'
              needEndAdornment={true}
              handleIconButtonClick={() => {
                setCity('');
              }}
            />
          </Box>
        );
      case 'Country/Region': {
        return (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', width: '286px' }}
            data-selenium-id={
              'GEPage_GEDetailPage-BasicInfoBlock-JudgeLeftValueComponent-country'
            }
          >
            <SingleAutocompleteForGEDetailPage
              placeholder={key}
              id='CreateGeCountryInput'
              value={country}
              options={countryList}
              isEmpty={country === undefined || country === null || country.name === ''}
              loadOptionAction={getCountryList}
              defaultQuery={true}
              defaultQueryMode={'startsWith'}
              getOptionLabel={option => option.name}
              getListOptionLabel={option => option.name}
              onChange={(e, newValue) => {
                setCountry(newValue);
              }}
              config={{ popupIcon: null }}
              isErr={flagCountry && showErrorMessage && !countryFocus}
              onFocus={() => {
                setCountryFocus(true);
              }}
              onBlur={() => {
                setCountryFocus(false);
              }}
            />
            {flagCountry && !countryFocus && showErrorMessage ? (
              <ErrorMessage
                message={'The Country must be chosen at least one'}
              />
            ) : (
              <Box></Box>
            )}
          </Box>
        );
      }

      default:
        return <Box></Box>;
    }
  }
  function judgeRightValueComponent(key) {
    switch (key) {
      case 'Primary Email':
        return (
          <Box>
            <OnlyTextForGEDetailPage
              value={primaryEmail}
              setValue={primaryEmailInput}
              width='286px'
              needEndAdornment={true}
              handleIconButtonClick={() => {
                setPrimaryEmail('');
              }}
              placeholder={key}
              isError={
                (flagPrimaryEmail || flagOkPrimaryEmail) && showErrorMessage
              }
              errorMessage={
                flagPrimaryEmail
                  ? 'The primary email is mandatory to input'
                  : 'The primary email is not a valid email address or has exists.'
              }
            />
          </Box>
        );
      case 'Secondary Email':
        return (
          <Box>
            <OnlyTextForGEDetailPage
              value={secondaryEmail}
              setValue={secondEmailInput}
              width='286px'
              needEndAdornment={true}
              handleIconButtonClick={() => {
                setSecondaryEmail('');
              }}
              placeholder={key}
              isError={
                (flagSecondaryEmail || flagOkSecondaryEmail) && showErrorMessage
              }
              errorMessage={
                flagSecondaryEmail
                  ? 'Primary Email and Secondary Email could NOT be same.'
                  : 'The secondary email is not a valid email address.'
              }
            />
          </Box>
        );
      case 'Research Keywords':
        return (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', width: '286px' }}
            data-selenium-id={
              'GEPage_GEDetailPage-BasicInfoBlock-JudgeRightValueComponent-ResearchKeywordsBox'
            }
          >
            <MulSelectInput
              id='CreateGeResearchKeywordsBox'
              data-selenium-id='CreateGeResearchKeywordsBox'
              maxTagWidth={286}
              value={researchKeywords}
              placeholder={''}
              defaultOption={key => ({
                id: 0,
                keyword: key,
              })}
              getOptionLabel={option => option.keyword}
              options={keywords}
              loadOptionAction={(...props) => dispatch(getKeywords(...props))}
              onValueChange={value => {
                setResearchKeywords(value);
              }}
              isOptionEqualToValue={(option, value) => {
                return option.keyword === value.keyword;
              }}
              sx={{
                // '& .SelectInput-Tags-Container': {
                //   paddingRight: 2,
                // },
                '& .MuiAutocomplete-clearIndicator': {
                  position: 'absolute',
                  right: 6, // 将 ClearIndicator 放置到右侧
                  top: 11, // 将 ClearIndicator 放置到顶部
                },
              }}
              addNewItemManager={addNewKeywordManager}
              showPopupIcon={false}
              error={flagResearchKeywords && showErrorMessage}
            />
            {flagResearchKeywords && showErrorMessage ? (
              <ErrorMessage
                message={'The research keywords must be chosen at least one'}
              />
            ) : (
              <Box></Box>
            )}
            {/* <UntitledAlertBox
              id="siPageFilterDrawerLoadFilterSuccessAlert"
              style={{
                position: 'fixed',
                top: '72px',
                right: '36px',
              }}
              data-selenium-id="GEPage_Overview-anotherNewFilterDrawer-Overview-Load-Filter-Success-Alert"
              open={keywordsOpen}
              severity="success"
              message="The new keyword has been added to the database."
              autoHideDuration={3000}
              handleClose={handleCreateKeywordsPageClose}
            /> */}
          </Box>
        );
      case 'Profile website':
        return (
          <Box>
            <OnlyTextForGEDetailPage
              value={profileWebsite}
              setValue={setProfileWebsite}
              width='286px'
              needEndAdornment={true}
              handleIconButtonClick={() => {
                setProfileWebsite('');
              }}
              placeholder={key}
              isError={(flagProfileLimits || flagProfileWebsite) && showErrorMessage}
              errorMessage={
                flagProfileLimits
                  ? `This field should not exceed ${1000} characters in length`
                  : 'The profile website must be a valid URL'
              }
            />
          </Box>
        );
      case 'ORCID ID':
        return (
          <Box>
            <OnlyTextForGEDetailPage
              value={ORCID}
              setValue={setORCID}
              width='286px'
              needEndAdornment={true}
              handleIconButtonClick={() => {
                setORCID('');
              }}
              placeholder={'0000-0000-0000-0000'}
              isError={flagORCID && showErrorMessage}
              errorMessage={
                flagORCIDCharLimits
                  ? `This field should not exceed ${ORCIDLengthLimit} characters in length`
                  : 'Incorrect OCRID, please input like this way 0000-0000-0000-0000'
              }
            />
          </Box>
        );
      case 'ResearchGate ID':
        return (
          <Box>
            <OnlyTextForGEDetailPage
              value={researchGate}
              setValue={setResearchGate}
              width='286px'
              needEndAdornment={true}
              handleIconButtonClick={() => {
                setResearchGate('');
              }}
              placeholder={key}
              isError={flagGateIdCharLimits && showErrorMessage}
              errorMessage={`This field should not exceed ${ResearchGateLengthLimit} characters in length`}
            />
          </Box>
        );
      case 'Flags':
        return (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', width: '286px' }}
            data-selenium-id={
              'GEPage_GEDetailPage-BasicInfoBlock-JudgeRightValueComponent-FlagsBox'
            }
          >
            <MulSelectInput
              id='CreateGeResearchFlagsBox'
              data-selenium-id='CreateGeFlagsBox'
              value={flags}
              placeholder={''}
              defaultOption={key => ({
                id: 0,
                value: key,
              })}
              getOptionLabel={option => option.value}
              options={flagsList}
              loadOptionAction={(...props) => dispatch(getFlaglist(...props))}
              onValueChange={value => {
                setFlags(value);
              }}
              disableNewItem={true}
              showPopupIcon={true}
            />
          </Box>
        );
      case 'Notes':
        return (
          <Box>
            <OnlyTextForGEDetailPage
              value={notes}
              setValue={setNotes}
              width='286px'
              needEndAdornment={true}
              handleIconButtonClick={() => {
                setNotes('');
              }}
              placeholder={key}
              isError={flagNotesLimits && showErrorMessage}
              errorMessage={`This field should not exceed ${NotesLengthLimit} characters in length`}
            />
          </Box>
        );
      case 'CV':
        return (
          <CVUpload
            initialValue={GEBasicInformation.cvFileName}
            onFileChange={(fileName)=>{
              if(typeof fileName==='string') setCvfileName(fileName);
              else setCvfileName(null);
            }}
            setIsUploading={setIsuploading}
          ></CVUpload>
        );
      default:
        return <Box></Box>;
    }
  }
  const handleSaveChange = () => {
    setLoadingBasicInformation(true);
    if(error) {
      setLoadingBasicInformation(false);
      return;
    }
    let editResearchKeywordsList = [];
    for (let item of researchKeywords) {
      editResearchKeywordsList.push(item.keyword);
    }
    async function fetchData() {
      await dispatch(
        editGEBasic(
          GEBasicInformation.geCode,
          trimItemFilds(firstName),
          trimItemFilds(lastName),
          primaryEmail,
          secondaryEmail,
          department,
          institute,
          trimItemFilds(city),
          country.name,
          gender?.value ?? null,
          notes,
          editResearchKeywordsList,
          ORCID,
          title?.value ?? null,
          profileWebsite,
          researchGate,
        cvfileName,
        )
      );
      setTimeout(() => {
        dispatch(getBasicInformation(GEBasicInformation.geCode));
      }, 1000);
      // await delay(300);
      setLoadingBasicInformation(false);
      setIsEdit(false);
    }
    fetchData().then(()=>{});
  };

  const handleCancelChange = () => {
    setIsEdit(false);
  };
  const judgeValueEffect = extraValueEffactJudge();
  const getValueList = extraValueList();

  const handleEditChange = () => {
    if (GEBasicInformation !== undefined) {
      setFirstName(GEBasicInformation.firstName);
      setLastName(GEBasicInformation.lastName);
      setTitle({
        value: GEBasicInformation.title ?? ''
          // judgeValueEffect(
          //   GEBasicInformation.title,
          //   getValueList(titleList, 'value')
          // ) ?? '',
      });
      setGender({
        value:
          judgeValueEffect(
            GEBasicInformation.gender,
            getValueList(genderList, 'value')
          ) ?? '',
      });
      if (GEBasicInformation.department === null) {
        setDepartment({ id: 0, department: '' });
      } else {
        setDepartment({ id: 0, department: GEBasicInformation.department });
      }
      if (GEBasicInformation.institute === null) {
        setInstitute({ id: 0, institute: '' });
      } else {
        setInstitute({ id: 0, institute: GEBasicInformation.institute });
      }
      setCity(GEBasicInformation.city);
      if (GEBasicInformation.country === null) {
        setCountry({ name: '' });
      } else {
        setCountry({ name: GEBasicInformation.country });
      }

      setPrimaryEmail(GEBasicInformation.primaryEmail);
      setSecondaryEmail(GEBasicInformation.secondaryEmail);
      let nowKeywordsList;
      if (GEBasicInformation && GEBasicInformation.hasOwnProperty('researchKeywordsList') && GEBasicInformation.researchKeywordsList) {
        nowKeywordsList = GEBasicInformation.researchKeywordsList;
      } else {
        nowKeywordsList=[];
      }
      let formatList = [];
      for (let item of nowKeywordsList) {
        // find item in keywords
        for (let keyword of allKeywords) {
          if (item === keyword.keyword) {
            formatList.push(keyword);
          }
        }
      }
      setResearchKeywords(formatList);
      setProfileWebsite(GEBasicInformation.profileWebsite);
      setORCID(GEBasicInformation.orcidId);
      setResearchGate(GEBasicInformation.researchGateId);

      if (GEBasicInformation.flags === null) {
        setFlags([]);
      } else {
        let formatflagsList = [];
        for (let flag_id in GEBasicInformation.flags) {
          formatflagsList.push({
            id: flag_id,
            keyword: GEBasicInformation.flags[flag_id],
          });
        }
        setFlags(formatflagsList);
      }
      setNotes(GEBasicInformation.notes);
      setCvfileName(GEBasicInformation.cvFileName);
    }
    setIsEdit(true);
  };
  const judgeMustNotNull = judgeMustNoNull();

  const checkError = CheckError(flagFirstName, flagLastName, flagInstitute, flagCountry, flagPrimaryEmail, flagOkPrimaryEmail, flagOkSecondaryEmail, flagResearchKeywords, flagORCID);
  const [isUploading,setIsuploading] = useState(false);
  const [marginTop,setMarginTop] = useState('9px');
  useEffect(()=>{
    let top=cvfileName!==null?
      showErrorMessage&&(primaryEmail===''||researchKeywords.length===0)?ORCIDLengthLimit:-15:
      showErrorMessage&&(primaryEmail===''||researchKeywords.length===0)?15:15;
    if(isUploading) top=top+6;
    setMarginTop(top+'px');
  },[cvfileName,showErrorMessage,primaryEmail,researchKeywords,isUploading]);
  return (
    <Box data-selenium-id='GEPage_GEDetailPage-BasicInfoBlock-Wider_Box'>
      <Box
        data-selenium-id='GEPage_GEDetailPage-BasicInfoBlock-Box'
        sx={{
          width: '1108px',
          maxWidth: '100%',
        }}
      >
        {isEdit ? (
          <Box data-selenium-id='GEPage_GEDetailPage-BasicInfoBlock-EditBox'>
            <CardContent
              sx={{ padding: '16px 16px', }}
            >
              <Stack
                spacing={3}
                data-selenium-id='GEPage_GEDetailPage-BasicInfoBlock-EditBox-Stack'
              >
                <Box data-selenium-id='GEPage_GEDetailPage-BasicInfoBlock-EditBox-Box'>
                  <Grid
                    container
                    spacing={listInOneColumn ? 0 : 1}
                    justifyContent='flex-end'
                    sx={{
                      // transform: 'scale(1,1)',
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      columnGap: '40px',
                      rowGap: '20px',
                      justifyContent: 'flex-start',
                      '&>div': {
                        width: '518px',
                        // overflow: 'auto',
                      },
                      marginBottom:'30px',
                    }}
                  >
                    {/* GE Details 信息左半部分  */}
                    {/* <Grid item xs={listInOneColumn ? 6 : 12}> 动态适应屏幕功能弃用 */}
                    <Box>
                      <Stack
                        spacing={2.5}
                        data-selenium-id='GEPage_GEDetailPage-BasicInfoBlock-EditBox-LeftStack'
                      >
                        {basicInfoLeftList.map((item, index) => {
                          return (
                            <ListItem
                              key={index}
                              id={`geDetailPageLeftItem${index}`}
                              data-selenium-id={`GEPage_GEDetailPage-BasicInfoBlock-EditBox-LeftStack-ListItem-${index}`}
                              sx={{
                                height: '33px !important',
                                fontWeight: 600,
                                fontSize: '14px',
                                alignItems: 'center',
                                padding:
                                  index === 0 ? '0px 0px 13px 0px' : '0px 0px',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <Stack
                                  width='224px'
                                  textAlign='left'
                                  marginRight={1}
                                  direction='row'
                                  spacing='10px'
                                  sx={{
                                    color: '#596A7C',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                  }}
                                  data-selenium-id={`GEPage_GEDetailPage-BasicInfoBlock-EditBox-LeftStack-ListItem${index}-Stack`}
                                >
                                  {judgeMustNotNull(item.key)}
                                </Stack>
                                {judgeLeftValueComponent(item.key, item.value)}
                              </Box>
                            </ListItem>
                          );
                        })}
                      </Stack>
                    </Box>
                    {/* GE Details 信息右半部分  */}
                    {/* <Grid item xs={listInOneColumn ? 6 : 12}> 动态适应屏幕功能弃用 */}
                    <Box>
                      <Stack
                        spacing={2.5}
                        data-selenium-id='GEPage_GEDetailPage-BasicInfoBlock-EditBox-RightStack'
                      >
                        {basicInfoRightList.map((item, index) => {
                          return (
                            <ListItem
                              key={index}
                              id={`geDetailPageLeftItem${index}`}
                              data-selenium-id={`GEPage_GEDetailPage-BasicInfoBlock-EditBox-RightStack-ListItem-${index}`}
                              sx={{
                                fontWeight: 600,
                                fontSize: '14px',
                                alignItems: 'center',
                                padding: 0,
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <Stack
                                  width='224px'
                                  textAlign='left'
                                  marginRight={1}
                                  direction='row'
                                  spacing='10px'
                                  sx={{
                                    color: '#596A7C',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                  }}
                                  data-selenium-id={`GEPage_GEDetailPage-BasicInfoBlock-EditBox-RightStack-ListItem${index}-Stack`}
                                >
                                  {judgeMustNotNull(item.key)}
                                </Stack>
                                {judgeRightValueComponent(item.key, item.value)}
                              </Box>
                            </ListItem>
                          );
                        })}
                      </Stack>
                    </Box>
                  </Grid>
                  {/* cancel/save Btn */}
                  <Box
                    // style={buttonContainer}
                    style={{
                      width: 'auto',
                      height: '62px',
                      background: '#FFFFFF',
                      position: 'sticky', // 外部需要有一个容器让他贴靠底部
                      bottom: 0,
                      margin: '12px 32px',
                      marginTop:marginTop,
                      marginRight:'0px',
                      zIndex: '10',
                    }}
                    data-selenium-id='GEPage_GEDetailPage-Box-Box2'
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-end',
                        height: '100%',
                        marginLeft:'10px',
                      }}
                    >
                      <Stack
                        spacing={'10px'}
                        direction='row'
                        data-selenium-id='GEPage_GEDetailPage-Box-Box2-Stack'
                      >
                        <CancelBtn
                          id='GECancelBtn'
                          data-selenium-id='GEPage_GEDetailPage-Box-Box2-Stack-CancelBtn'
                          onClick={() => {
                            handleCancelChange();
                          }}
                        >
                          Cancel
                        </CancelBtn>
                        <LoadingButton
                          id='GESaveBtn'
                          data-selenium-id='GEPage_GEDetailPage-Box-Box2-Stack-SaveBtn'
                          variant='contained'
                          loading={loadingBasicInformation}
                          sx={{
                            fontWeight: 600,
                            fontSize: '14px',
                            height: '41px',
                            padding: '12px 18px',
                            background: loadingBasicInformation?'#6D98EE !important':'#0052CC',
                            borderRadius: '5px',
                            color: '#FFFFFF',
                            width: '114px',
                            textTransform: 'none',
                            '&:hover':{
                              background: '#6D98EE',
                            },
                            '&:active':{
                              background: '#113D95',
                            },
                            '&:loading':{
                              background: '#6D98EE',
                            },
                          }}
                          onClick={() => {
                            setShowErrorMessage(true);
                            if (checkError()) {
                              return;
                            }
                            handleSaveChange();
                          }}
                          // disabled={
                          //   // outSaveDisabled
                          //   error
                          // }
                        >
                          Save
                        </LoadingButton>

                      </Stack>
                    </div>
                  </Box>
                </Box>
              </Stack>
            </CardContent>
          </Box>
        ) : (
          <Box data-selenium-id='GEPage_GEDetailPage-BasicInfoBlock-EditBox'>
            {getBasicInfo}
            <Description2
              infoObj={GEBasicInformation}
              listInOneColumn={listInOneColumn}
              leftGEDetails={basicInfoLeftList}
              rightGEDetails={basicInfoRightList}
              data-selenium-id='GEPage_GEDetailPage-BasicInfoBlock-EditBox-Description2'
            />
          </Box>
        )}
      </Box>
      <UntitledAlertBox
        id='siPageFilterDrawerLoadFilterSuccessAlert'
        style={{
          position: 'fixed',
          top: '76px',
          right: '36px',
        }}
        data-selenium-id='GEPage_Overview-anotherNewFilterDrawer-Overview-Load-Filter-Success-Alert'
        open={keywordsOpen}
        severity='success'
        message='The new keyword has been added to the database.'
        autoHideDuration={3000}
        handleClose={handleCreateKeywordsPageClose}
      />
      <UntitledAlertBox
        id='siPageFilterDrawerLoadFilterSuccessAlert'
        style={{
          position: 'fixed',
          top: '76px',
          right: '36px',
        }}
        data-selenium-id='GEPage_Overview-anotherNewFilterDrawer-Overview-Load-Filter-Success-Alert'
        open={InstituteOpen}
        severity='success'
        message='The new institution has been added to the database.'
        autoHideDuration={3000}
        handleClose={handleCreateInstitutePageClose}
      />
      <UntitledAlertBox
        id='siPageFilterDrawerLoadFilterSuccessAlert'
        style={{
          position: 'fixed',
          top: '76px',
          right: '36px',
        }}
        data-selenium-id='GEPage_Overview-anotherNewFilterDrawer-Overview-Load-Filter-Success-Alert'
        open={DepartmentOpen}
        severity='success'
        message='The new department has been added to the database.'
        autoHideDuration={3000}
        handleClose={handleCreateDepartmentPageClose}
      />
    </Box>
  );
});

export default BasicInforBlock;

import React, { useEffect, useRef, useState } from "react";
import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';
import AuthHeader from '../../components/AuthHeader';
import Footer from '../../components/Footer';
import CommonBreadCrumb from '../../components/CommonBreadCrumb';
import CommonSnackBar from '../../modules/ConfirmSnackBar/CommonSnackBar';
import AdminBreadcrumbs from '../../components/CommonBreadCrumb';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

export default function AuthPage() {
  // 设置了页面的基本框架。包含标题、主体（可被覆盖）、页脚三部分。
  // 本页面没有鉴权相关的操作。但是路由配置里面使用了 Logined 要求登录访问，权限限制在路由配置中
  const ref = useRef(null);
  const open = useSelector(state => state.UserManage.siderOpen);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [ct, setCt] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        if (ref.current.hasContext()) {
          setCt(60);
        } else {
          setCt(0);
        }
      } else {
        setCt(0);
      }
    }, 1000);
  }, [location.pathname]);

  return (
    <Grid
      container
      alignItems='center'
      justifyContent='flex-start'
      direction='column'
      data-selenium-id='AuthPage-Grid'
      minHeight={'100vh'}
    >
      {
        !window.location.search.includes('hideHeader') && <Grid item data-selenium-id='AuthPage-Grid1' sx={{ height: 56 }}>
          <AuthHeader />
        </Grid>
      }
      <Grid
        item
        container
        direction='column'
        xs={12}
        // sx={{ minHeight: '100vh' }}
        justifyContent='flex-start'
        alignItems='flex-start'
        data-selenium-id='AuthPage-Grid2'
      // sx={{
      //   width:  'calc(100vw - 3px)',
      //   height: `calc(100vh - ${124 + 60}px)`,
      //   overflowX: 'auto',
      //   overflowY: 'auto',
      // }}
      >
        <Grid
          id='CommonBreadCrumb'
          data-selenium-id='AuthPage-CommonBreadCrumb'
          item
          width='100%'
          sx={{
            height: `${ct}px`
          }}
        >
          <CommonBreadCrumb ref={ref} />
        </Grid>
        <Grid
          id="outer"
          data-selenium-id='AuthPage-OutletGrid'
          item
          width='100%'
          sx={{
            width: 'calc(100vw - 0px)',
            height: `calc(100vh - ${56 + ct}px)`,
            overflowX: 'auto',
            overflowY: 'auto',
            '& > :first-child': {
              minHeight: 'calc(100% - 68px)',
              flexDirection: 'column',
            },
            ...(location?.pathname?.includes('/simt/auth/sipRelated/sip/aiScreeningReport/') && {
              '&>div.MuiBox-root:first-child>div.MuiGrid-root.MuiGrid-container:only-child>div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12:only-child': {
                '@media screen and (min-width: 721px)': {
                  overflowX: 'clip',
                },
              }
            }),
          }}
        >
          <Outlet />
          {!window.location.search.includes('hideHeader') && <Footer sx={{ position: 'relative', zIndex: '1201' }} />}
        </Grid>
      </Grid>
      {/* <Grid item width='100%' zIndex={1201} data-selenium-id='AuthPage-Grid3' marginTop={'auto'}>
        <Footer />
      </Grid> */}
      <CommonSnackBar data-selenium-id='AuthPage-CommonSnackBar' />
    </Grid>
  );
}

import React, { useEffect, useState } from 'react';
import { Box, Stack, IconButton, TextField } from '@mui/material';
import GETable from './submodule/GETable';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SectionContainer } from '../RevisePage/CustomComponents/CustomContainer';
import SIPAuthorEdit from '@/assets/SIPAuthorEdit.svg';
import { styled } from '@mui/system';

const createRow = (
  id,
  label,
  minWidth,
  maxWidth,
  isOrder = true,
  align = 'left'
) => {
  return { id, label, minWidth, maxWidth, isOrder, align };
};

const asteriskStyle = {
  color: '#C40000',
  marginLeft: '10px',
  marginRight: '10px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '18px',
};

const hintStyle = {
  color: '#EE5350',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '-0.084px',
  textTransform: 'none',
};

export const OpaqueInput = styled(props => (
  <TextField
    {...props}
    data-selenium-id='SIP_AcceptPage_GEInformationEditSection-PropsTextField'
  />
))({
  '&.MuiTextField-root': {
    display: 'block',
  },
  '.MuiInputBase-root': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    letterSpacing: '0.01em',
    color: '#262E35',
  },
  '.MuiInputBase-input': {
    padding: '0px 0px 0px 0px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
});

export default function GEInformationEditSection(props) {
  const {
    value: geInformationList,
    setValue = () => {},
    showErrorMessage = false,
    error = false,
    setError = () => {},
  } = props;

  const dispatch = useDispatch();
  const { token } = useParams();

  // 这里的 tableData 与 edit 的作用类似
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      geInformationList.map((item, index) => {
        return {
          id: item.id,
          leadGe: item.leadGe ? 'Yes' : 'No',
          fullName: `${item.firstName}, ${item.lastName}`,
          researchKeywords: item.researchKeywords ?? [],
          actions: (
            <Stack
              direction='row'
              justifyContent={'center'}
              data-selenium-id='SIP_AcceptPage_GEInformationEditSection-Stack'
            >
              <IconButton data-selenium-id='SIP_AcceptPage_GEInformationEditSection-IconButton'>
                <SIPAuthorEdit data-selenium-id='SIP_AcceptPage_GEInformationEditSection-SIPAuthorEdit' />
              </IconButton>
            </Stack>
          ),
        };
      })
    );
  }, [geInformationList]);

  useEffect(() => {
    setError(
      !geInformationList.every(item => {
        return item.researchKeywords && item.researchKeywords.length > 0;
      })
    );
  }, []);

  const tableHeads = [
    createRow('leadGe', 'LEAD GE', '80px', '80px', false, 'center'),
    createRow('fullName', 'FULL Name', '150px', '150px', false),
    createRow(
      'researchKeywords',
      <span>
        RESEARCH KEYWORDS
        <span style={asteriskStyle}>*</span>
        {showErrorMessage && error ? (
          <span style={hintStyle}>This field is mandatory to input!</span>
        ) : null}
      </span>,
      '250px',
      '250px',
      false
    ),
  ];

  return (
    <SectionContainer
      title='GE Information'
      data-selenium-id='SIP_AcceptPage_GEInformationEditSection-Container'
    >
      <Box maxWidth={'1243px'}>
        <GETable
          id='SIPAcceptGETable'
          data-selenium-id='SIP_AcceptPage_GEInformationEditSection-GETable'
          TableHeads={tableHeads}
          TableData={tableData}
          handleInfoChange={setValue}
          setError={setError}
        />
      </Box>
    </SectionContainer>
  );
}

// <<<<<<< HEAD
// import { Grid, Typography } from '@mui/material';
// import { FormattedMessage } from 'react-intl';
// import { Box } from '@mui/system';
import { useState } from 'react';
// =======
// import React, { useState } from 'react';

// >>>>>>> release-p2m1-20230919
import { AcqurireOverviewHeader, AcquirePageOverview } from './overHeader';
import AcquireOverviewBasicTable from './BasicTable';
import DiagramContent from './OverviewDiagrams';
import { AcquiredOverviewSubjectGroupTable } from './SubjectGroupTable';
import { AcquireBusinessDivisionTable } from './BusinseeTable';
import { StyledEngineProvider, useTheme } from '@mui/material/styles';
import { AcquireCETable } from './CETable';

import AcquireOverviewFilter from './AcquireOverviewFilter';
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import {
  TableGroupBox,
  TableLabel,
} from '../../ReportSpecificComponents/SpecialColorTableHeader';
import {PERMISSIONS} from "@/constant/permission";

export function AcquireOverviewTableContent(props) {
  // const open = useSelector((state) => state.UserManage.siderOpen); //侧边栏开关

  const filterDrawerWidth = 380;
  const theme = useTheme();

  const [filterOpen, setFilterOpen] = useState(false);
  const [ifNoResult, setIfNoResult] = useState(false);

  const filterOpenChange = () => {
    setFilterOpen(!filterOpen);
  };

  const BasicTableBoxCss = {
    width: filterOpen ? 'calc(100% - 308px)' : '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  };

  return (
    <div>
      {/*<AcquirePageOverview data-selenium-id='Report_AcquiredActiveSi-Overview-AcquirePageOverview' />*/}
      <TableGroupBox
        style={{
          display: 'flex',
          flex: 'row',
          width: '100%',
          padding: '0px',
        }}
        data-selenium-id='Report_AcquiredActiveSi-Overview-Box'
      >
        <StyledEngineProvider
          injectFirst
          data-selenium-id='Report_AcquiredActiveSi-Overview-StyledEngineProvider'
        >
          <div style={BasicTableBoxCss}>
            <AcqurireOverviewHeader
              filterOpen={filterOpen}
              filterOpenChange={filterOpenChange}
              data-selenium-id='Report_AcquiredActiveSi-Overview-StyledEngineProvider-AcqurireOverviewHeader'
            />
            <AcquireOverviewBasicTable
              setIfNoResult={setIfNoResult}
              data-selenium-id='Report_AcquiredActiveSi-Overview-StyledEngineProvider-AcquireOverviewBasicTable'
            />
          </div>
        </StyledEngineProvider>
        {filterOpen ? (
          <AcquireOverviewFilter
            filterOpen={filterOpen}
            drawerWidth={filterDrawerWidth}
            handleClose={filterOpenChange}
            data-selenium-id='Report_AcquiredActiveSi-Overview-AcquireOverviewFilter'
          />
        ) : (
          <div></div>
        )}
      </TableGroupBox>
      <TableGroupBox>
        <TableLabel>Subject Group</TableLabel>
        <AcquiredOverviewSubjectGroupTable
          ifNoResult={ifNoResult}
          data-selenium-id='Report_AcquiredActiveSi-Overview-AcquiredOverviewSubjectGroupTable'
        />
      </TableGroupBox>
      <TableGroupBox>
        <TableLabel>Business Division</TableLabel>
        <AcquireBusinessDivisionTable
          ifNoResult={ifNoResult}
          data-selenium-id='Report_AcquiredActiveSi-Overview-AcquireBusinessDivisionTable'
        />
      </TableGroupBox>
      <ShowForPermission permission={`${PERMISSIONS.CAT_REPORT_READ} ${PERMISSIONS.REPORT_READ_CE}`}>
        <TableGroupBox>
          <TableLabel>CE/CAT</TableLabel>
          <AcquireCETable
            ifNoResult={ifNoResult}
            data-selenium-id='Report_AcquiredActiveSi-Overview-AcquireCETable'
          />
        </TableGroupBox>
      </ShowForPermission>
    </div>
  );
}

// export function AcquiredAndActiveSiOverview({ showTable, setShowTableNav }) {
export function AcquiredAndActiveSiOverview({ showTable, setShowTable }) {
  // test
  // console.log('setShowTable =', setShowTable);

  return (
    <>
      {/* <<<<<<< HEAD */}
      {/* <AcquirePageOverview
        showTable={showTable}
        setShowTableNav={setShowTableNav}
      />
      {showTable == 'true' ? (
        <AcquireOverviewTableContent />
      ) : (
        <DiagramContent />
      )} */}
      {/* ======= */}
      <AcquirePageOverview
        showTable={showTable}
        setShowTable={setShowTable}
        data-selenium-id='Report_AcquiredActiveSi-Overview-AcquirePageOverview'
      />
      {showTable ? <AcquireOverviewTableContent /> : <DiagramContent />}
      {/* >>>>>>> release-p2m1-20230919 */}
    </>
  );
}

export default AcquiredAndActiveSiOverview;

import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { Checkbox } from '@mui/material';
import { Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import { tableCellClasses } from '@mui/material';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  selectBatchCRC,
  unselectBatchCRC,
} from '../../../../actions/SIMT-SI/CRContact/CRContactAction';

function MyTable(props) {
  const dispatch = useDispatch();
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      color: '#727272',
      minWidth: '49px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '16px',
      lineHeight: '16px',
      color: '#484848',
      padding: '9px 16px',
    },
  }));
  //type 为AME/ME
  //selectValue 为选中的值
  const { type, selectValue, setErr, open } = props;

  const [display, setDisplay] = useState('none');
  const [transferredType, setTransferredType] = useState('');
  const [transferredSelectValue, setTransferredSelectValue] = useState('');
  const [currentSelect, setCurrentSelect] = useState([]);

  const selector = state => {
    return {
      selectedCRC: state.CRContact.selectedCRC,
      selectedCRCInfo: state.CRContact.batchUpdateCRCList,
      selectedCRCInfo2: state.CRContact.selectedCRCInfo,
    };
  };

  const { selectedCRC, selectedCRCInfo, selectedCRCInfo2 } =
    useSelector(selector);

  const fontStyle = (lineHeight, fontSize, weight, color) => {
    return {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: weight,
      fontSize: fontSize,
      lineHeight: lineHeight,
      color: color,
    };
  };

  function createData(type, style) {
    return {
      type,
      style,
    };
  }

  const MyTableHeader = [
    createData('ameFullName', {
      ...fontStyle('20px', '14px', '400', '#091E42'),
      padding: 4,
      marginTop: 4,
      marginLeft: 10,
      width: 120,
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
      'white-space': 'nowrap',
    }),
    createData('meFullName', {
      ...fontStyle('20px', '14px', '400', '#091E42'),
      padding: 4,
      marginTop: 4,
      marginLeft: 10,
      width: 120,
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
      'white-space': 'nowrap',
    }),
    createData('journalName', {
      ...fontStyle('20px', '14px', '400', '#42526E'),
      padding: 4,
      marginTop: 4,
      marginLeft: 30,
      width: 180,
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
      'white-space': 'nowrap',
      background: '#EBECF0',
    }),
    createData('journalCode', {
      ...fontStyle('14px', '12px', '400', '#484848'),
      padding: 4,
      marginTop: 4,
      marginLeft: 30,
      width: 100,
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
      'white-space': 'nowrap',
    }),
  ];

  const errorStyle = display => {
    return {
      width: '550px',
      display: display,
      marginTop: '10px',
      marginBottom: '10px',
      ...fontStyle('18px', '12px', '400', '#A94442'),
    };
  };

  const isSelected = (obj, index) => {
    return selectedCRC.indexOf(obj) > -1;
  };

  const handleCheck = (obj, index) => {
    if (selectedCRC.indexOf(obj.journalCode) > -1) {
      dispatch(unselectBatchCRC(obj.journalCode));
    } else {
      dispatch(selectBatchCRC(obj));
    }
  };

  useEffect(() => {
    type === 'ME'
      ? setTransferredType('meFullName')
      : setTransferredType('ameFullName');
  }, [type]);

  useEffect(() => {
    if (open) {
      setCurrentSelect(selectedCRC);
    }
  }, [open]);

  useEffect(() => {
    if (selectValue === '' || selectValue === null) {
      return;
    }
    let isDisplayEqualBlock = false;
    let isError = true;
    let fullName = selectValue['lastName'] + ',' + selectValue['firstName'];
    // console.log(selectValue)
    setTransferredSelectValue(fullName);
    for (let i in selectedCRCInfo2) {
      if (
        selectedCRCInfo2[i]['ameEmail'] === selectValue['email'] ||
        selectedCRCInfo2[i]['meEmail'] === selectValue['email']
      ) {
        setDisplay('block');
        dispatch(unselectBatchCRC(selectedCRCInfo2[i]['journalCode']));
        isDisplayEqualBlock = true;
        continue;
      }
      // if (selectedCRC.indexOf(selectedCRCInfo2[i]["journalCode"])<0) {
      //   dispatch(selectBatchCRC([selectedCRCInfo2[i]["journalCode"]]));
      // }
      isError = false;
    }
    if (!isDisplayEqualBlock) {
      setDisplay('none');
    }
    dispatch(setErr(isError));
  }, [selectValue]);

  return (
    <Box
      sx={{ marginTop: '20px', marginLeft: '20px' }}
      data-selenium-id='CRContact_Overview-BatchUpdate-MyTable-Box'
    >
      <Box data-selenium-id='CRContact_Overview-BatchUpdate-MyTable-Box-Box'>
        <div
          style={fontStyle('18px', '12px', '600', '#6B778c')}
          data-selenium-id='CRContact_Overview-BatchUpdate-MyTable-Box-Div1'
        >
          Journals will be updated
        </div>
        <div
          style={errorStyle(display)}
          data-selenium-id='CRContact_Overview-BatchUpdate-MyTable-Box-Div2'
        >
          Below highlighted items have the same AME/ME with your selection,so no
          update will be performed on these items
        </div>
      </Box>
      <TableContainer
        sx={{ minHeight: '100px', maxHeight: '300px' }}
        data-selenium-id='CRContact_Overview-BatchUpdate-MyTable-TableContainer'
      >
        <Table stickyHeader aria-label='simple table'>
          <TableBody>
            {currentSelect.map((row, index) => {
              const isItemSelected = isSelected(row, index);
              return (
                selectedCRCInfo2[row] !== undefined &&
                selectedCRCInfo2[row] !== null && (
                  <TableRow
                    key={selectedCRCInfo2[row]}
                    sx={
                      selectValue !== null &&
                      (selectedCRCInfo2[row]['ameEmail'] ===
                        selectValue['email'] ||
                        selectedCRCInfo2[row]['meEmail'] ===
                          selectValue['email'])
                        ? {
                            '&:last-child td, &:last-child th': { border: 0 },
                            background: 'rgb(255 236 236)',
                          }
                        : {
                            '&:last-child td, &:last-child th': { border: 0 },
                            backgroundColor: '#ffffff',
                          }
                    }
                  >
                    <StyledTableCell
                      align='left'
                      style={{ 'flex-direction': 'row', display: 'flex' }}
                    >
                      <Checkbox
                        id={index}
                        data-selenium-id={index}
                        color='primary'
                        checked={isItemSelected}
                        disabled={
                          selectValue !== null &&
                          (selectedCRCInfo2[row]['ameEmail'] ===
                            selectValue['email'] ||
                            selectedCRCInfo2[row]['meEmail'] ===
                              selectValue['email'])
                        }
                        onClick={handleCheck.bind(this, selectedCRCInfo2[row])}
                      />
                      {MyTableHeader.map((item, index) => {
                        if (
                          (type === 'ME' && item.type === 'ameFullName') ||
                          (type === 'AME' && item.type === 'meFullName')
                        ) {
                          return <div></div>;
                        }
                        return (
                          <Tooltip
                            title={
                              item.type === 'ameFullName'
                                ? selectedCRCInfo2[row]['ameEmail']
                                : item.type === 'meFullName'
                                ? selectedCRCInfo2[row]['meEmail']
                                : selectedCRCInfo2[row][item.type]
                            }
                          >
                            <div
                              id={`crContactBatchUpdate${item.type}${index}`}
                              data-selenium-id={`CRContact_Overview-BatchUpdate${item.type}${index}`}
                              style={item.style}
                            >
                              {selectedCRCInfo2[row][item.type]}
                            </div>
                          </Tooltip>
                        );
                      })}
                    </StyledTableCell>
                  </TableRow>
                )
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
export default MyTable;

import {
  Box,
  IconButton,
  Stack,
  Backdrop,
  Typography,
  Drawer,
  Divider,
  Checkbox,
} from '@mui/material';
import {
  SmallFont,
} from '@/components/FontStyle';
import { SIMTButton } from '@/components/StyledButton/SIMTButton';
import { StateButton } from '@/componentsphase2/StateButton';
import { loadData } from '@/actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import {
  clearReportFilter,
  setReportAcquireOverviewFilters,
  setSelect,
} from '@/actions/SIMT-Report/ReportFilter/ReportFilterAction';

import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { blankTrim } from '@/utils/commonUtils';
import SelectBoxWithFixedDropList from '@/modules/SelectBox/SelectBoxWithFixedDropList';
import FilterFrame from '../../Filter/FilterFrame';
import Block from '../../Filter/Block';
import SelectBox from '../../Filter/Phase2Component';
import SIDrawerAutocompletePupIconTypeSecond from '@/assets/SIDrawerAutocompletePupIconTypeSecond.svg';
import { handleFileterJournalName } from '@/utils/commonUtils';
import ReportFilterSwitchIconOn from '@/assets/ReportFilterSwitchIconOn.svg';
import ReportFilterSwitchIconOff from '@/assets/ReportFilterSwitchIconOff.svg';
import CloseIconButton from '@/componentsphase2/Button/CloseIconButton';
import { MulSelectInput } from '@/componentsphase2/SelectBox/SelectInput';
import { useShowReportRolesByFyHook } from '@/pages/Report/utils/useShowReportRolesByFyHook';
import {PERMISSIONS} from "@/constant/permission";

export default function AcquireOverviewFilter(props) {
  const { filterOpen, drawerWidth, handleClose } = props;
  const dispatch = useDispatch();
  const [showJPM, showNewJournalRoles] = useShowReportRolesByFyHook();
  const {
    selectDate,
    cutOffDate,
    handlingCeData,
    catGroupData,
    journalData,
    jpmData,
    pdPublisherData,//add pdPublisher
    ppPublisherData,//add ppPublisher
    subjectGroupData,
    businessDivisionData,
    reportJournal,
    revenueModel,
    reportJPM,
    reportPDPublisher, //add pdPublisher selected choice
    reportPPPublisher, //add ppPublisher selected choice
    reportSubjectGroup,
    businessDivision,
    reportCATGroup,
    reportHandlingCE,
    reportAcquireOverviewJournalStatus,
    filterData = {},
  } = useSelector(state => {
    return {
      selectDate: state.Report.selectDate,
      cutOffDate: state.Report.cutOffDate,
      // filter display
      handlingCeData: state.Filter.handlingCe,
      catGroupData: state.Filter.catGroup,
      journalData: state.Filter.journal,
      jpmData: state.Filter.jpm,
      pdPublisherData: state.Filter.pdPublisher, //add pdPublisher choice
      ppPublisherData: state.Filter.ppPublisher, //add ppPublisher choice
      subjectGroupData: state.Filter.subjectGroup,
      businessDivisionData: state.Filter.businessDivision,
      reportJournal: state.ReportFilter.reportAcquireOverviewJournal,
      revenueModel: state.ReportFilter.reportAcquireOverviewrevenueModel,
      reportJPM: state.ReportFilter.reportAcquireOverviewJPM,
      reportPDPublisher: state.ReportFilter.reportAcquireOverviewPDPublisher, //add pdPublisher
      reportPPPublisher: state.ReportFilter.reportAcquireOverviewPPPublisher, //add ppPublisher
      reportSubjectGroup: state.ReportFilter.reportAcquireOverviewSubjectGroup,
      businessDivision:
        state.ReportFilter.reportAcquireOverviewBusinessDivision,
      reportCATGroup: state.ReportFilter.reportAcquireOverviewCATGroup,
      reportHandlingCE: state.ReportFilter.reportAcquireOverviewHandlingCE,
      reportAcquireOverviewJournalStatus:
        state.ReportFilter.reportAcquireOverviewJournalStatus,
      filterData: state.ReportFilter.reportAcquireOverviewFilters,
    };
  });

  const [Apply, setApply] = useState(false);

  useEffect(() => {
    if (filterOpen) {
      document.body.classList.add('hide-scroll');

      const {
        journalName: journalCode = [],
        revenueModel = [],
        jpm = [],
        subjectGroup = [],
        businessDivision = [],
        catGroup = [],
        handlingCe = [],
        journalStatus = [],
      } = filterData;

      const journalList = [];
      for (let code of journalCode){
        for (let journal of journalData){
          if (journal.includes(`${code}(`)){
            journalList.push(journal);
          }
        }
      }

      const handlingCes = [];
      for (let ceId of handlingCe){
        for (let ce of handlingCeData) {
          if (ce.userId === ceId) {
            handlingCes.push(ce);
          }
        }
      }

      const groups = [];
      for (let group of catGroup){
        for (let cat of catGroupData){
          if (cat.value === group){
            groups.push(cat);
          }
        }
      }

      dispatch(setSelect('reportJournal', journalList));
      dispatch(setSelect('reportJPM', jpm));
      dispatch(setSelect('reportSubjectGroup', subjectGroup));
      dispatch(setSelect('reportCATGroup', groups));
      dispatch(setSelect('reportBusinessDivision', businessDivision));
      dispatch(setSelect('reportHandlingCE', handlingCes));
      dispatch(setSelect('revenueModel', revenueModel.length !== 0));
      dispatch(setSelect('reportJournalStatus', journalStatus));
    } else {
      document.body.classList.remove('hide-scroll');
    }
  },[filterOpen]);

  const permssion = localStorage
    .getItem('userPermissions')
    .includes(PERMISSIONS.SI_READ_LIMITED); //权限判断 修复403错误

  const boxStyle = {
    bgcolor: '#F3F4F8',
    mt: 1,
    p: 0,
  };

  const selectFilter = (which, data) => {
    dispatch(setSelect(which, data));
  };

  const onLoad = async (which, searchContent, page = 1, pageSize = 5000) => {
    if (!permssion) {
      await dispatch(loadData(which, blankTrim(searchContent), page, pageSize));
    }
  };

  const handleSelectedChange = (which, data) => {
    selectFilter(which, data);
  };

  const handleReset = () => {
    dispatch(clearReportFilter());
  };

  const handlerevenueModellChange = e => {
    dispatch(setSelect('revenueModel', !revenueModel));
  };

  const prepareFilters = () => {
    // 处理journal code----感觉不规范，以后要跟后端协商
    var journalName = [];
    for (const journalSelectedElement of reportJournal) {
      journalName.push(
        handleFileterJournalName(journalSelectedElement)['journalName']
      );
    }

    // 处理handling ce
    var handlingCeIds = [];
    for (const element of reportHandlingCE) {
      handlingCeIds.push(element.userId);
    }

    // 处理group
    const groups = [];
    for (const element of reportCATGroup) {
      groups.push(element.value);
    }

    return {
      handlingCe: handlingCeIds,
      catGroup: groups,
      journalName: journalName,
      jpm: reportJPM,
      pdPublisher: reportPDPublisher, //add pdPublisher
      ppPublisher: reportPPPublisher, //add ppPublisher
      subjectGroup: reportSubjectGroup,
      businessDivision: businessDivision,
      revenueModel: revenueModel ? ['OA'] : [],
      journalStatus: reportAcquireOverviewJournalStatus,
    };
  };

  const handleApply = async () => {
    const filters = prepareFilters();
    await dispatch(setReportAcquireOverviewFilters(filters));
    setApply(true);
    handleCloseFilter();
  };

  const handleCloseFilter = () => {
    document.body.classList.remove('hide-scroll');
    handleClose();
  };
  const [open1, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
    dispatch(setArticleOAPFilterSelect('revenueModel', !newOpen));
    // console.log(revenueModel);
  };

  useEffect(() => {
    // load 两个常量列表
    if (!permssion) {
      dispatch(loadData('catGroup'));
    }
  }, [permssion, dispatch]);

  const filterBody = useRef(null);
  function goBottom() {
    setTimeout(()=>{
      const box = filterBody.current;
      if (box){
        box.scrollTop = box.scrollHeight - box.offsetHeight;
      }
    },301);
  }

  return (
    <Box
      sx={{
        width: '100%',
      }}
      data-selenium-id='SIPage_Overview-anotherNewFilterBox'
    >
      <Backdrop
        id='SIPageOverviewAnotherNewFilterDrawerBackdrop'
        data-selenium-id={'SIPage_Overview-anotherNewFilterDrawerBackdrop'}
        sx={{
          zIndex: 1400,
          backdropFilter: 'blur(4px)',
          background: '#113D9580',
        }}
        open={filterOpen}
      ></Backdrop>
      <Drawer
        id='SIPageOverviewAnotherNewFilterDrawer'
        variant='persistent'
        anchor='right'
        open={filterOpen}
        sx={{
          flexShrink: 0,
          ['& .MuiDrawer-paper']: {
            width: '472px',
            zIndex: 1400,
            backgroundColor: '#FFFFFF',
            paddingBottom: '20px',
          },

        }}
        data-selenium-id={'SIPage_Overview-anotherNewFilterDrawer'}
      >
        <Box
        // direction= 'row'
          sx={{
            m: '20px 36px',
            height: '34px',
          }}
        >
          <CloseIconButton
            sx={{float: 'left', padding: '0px', marginRight:'12px', pt:'5px'}}
            onClick={() => {
              handleCloseFilter();
              // setIsClick(false);
              // dispatch(clearEditOptInCode());
            }}
            data-selenium-id='ReportRelated-ReportOverview-Filter-CloseButton'
          />
          <Box
            // display="flex" justifyContent="center"
            sx={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22.5px',
              height: '13px',
              float: 'left',
              color: '#262E35',
              mt: '5px',
              // mb: '10px',
            }}
            data-selenium-id='CATJournal_Overview-OptInOperation-Edit-Box-Box'
          >
        Filters
          </Box>
          <StateButton
            onClick={handleApply}
            data-selenium-id='CATJournal_Overview-OptInOperation-Edit-Box2'
            titleName='Filter'
            isPrimary={true}
            sx={{
              lineHeight: '18px',
              marginLeft: '10px',
              float: 'right',
            // '&:disabled': {
            //   backgroundColor: '#BCC5CF',
            //   boxShadow: 'none',
            //   color: '#FFFFFF',
            // },
            }}
          // disabled={isError()}
          ></StateButton>

          <StateButton
            onClick={handleReset}
            data-selenium-id='CATJournal_Overview-OptInOperation-Edit-Box1'
            titleName='Clear'
            isPrimary={false}
            sx={{lineHeight: '18px',float: 'right'}}
          ></StateButton>
        </Box>

        <Divider></Divider>
        <Stack
          direction="column"
          spacing={3}
          sx = {{
            p: '20px 30px 0px 36px',
            marginRight: '6px',
            overflowY: 'auto',
            scrollbarWidth: 'thin',  // 设置滚动条宽度
            // scrollbarColor: 'transparent',  // 设置滚动条颜色为透明
            // '&::-webkit-scrollbar': {
            //   width: '0px',  // 设置滚动条宽度
            // },
            // '&::-webkit-scrollbar-thumb': {
            //   background: 'transparent',  // 设置滚动条背景为透明
            // },
          }}
          ref={filterBody}
        >
          {/* Journal */}
          <Box>

            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
        Journal
            </Box>

            <SelectBox
              disablePortal
              id='reportArticlesFilterjournalSelect'
              data-selenium-id='Report_Articles_Filter-journalSelect'
              // sx={{ mt: 1 }}
              data={journalData}
              type ={1}
              labelName={'Journal'}
              popupIcon={
                <IconButton
                  sx={{ width: '16px', height: '16px', padding: '0px' }}
                >
                  <SIDrawerAutocompletePupIconTypeSecond></SIDrawerAutocompletePupIconTypeSecond>
                </IconButton>
              }
              placeholder='Search Journal Name or Code'
              value={reportJournal}
              setSelected={handleSelectedChange.bind(this, 'reportJournal')}
              loadMore={onLoad.bind(this, 'journal')}
              limitTags={-1}
              multiple
            />
          </Box>

          {/* JPM */}
          {showJPM && <Box>
            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
              JPM
            </Box>
            <SelectBox
              disablePortal
              id='reportArticlesFilterJpmSelect'
              disablePortal
              data-selenium-id='Report_Articles_Filter-JpmSelect'
              // sx={{ mt: 1 }}
              type ={1}
              data={jpmData}
              labelName={'JPM'}
              popupIcon={
                <IconButton
                  sx={{ width: '16px', height: '16px', padding: '0px' }}
                >
                  <SIDrawerAutocompletePupIconTypeSecond></SIDrawerAutocompletePupIconTypeSecond>
                </IconButton>
              }
              placeholder='Search JPM'
              value={reportJPM}
              setSelected={handleSelectedChange.bind(this, 'reportJPM')}
              loadMore={onLoad.bind(this, 'jpm')}
              limitTags={-1}
              multiple
            />
          </Box>}

          {/* PD Publisher */}
          { showNewJournalRoles && <Box>
            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
            PD Publisher
            </Box>
            <SelectBox
              disablePortal
              id='reportArticlesFilterPdPublisherSelect'
              disablePortal
              data-selenium-id='Report_Articles_Filter-PDPublisherSelect'
              // sx={{ mt: 1 }}
              type ={1}
              data={pdPublisherData}
              labelName={'PD Publisher'}
              popupIcon={
                <IconButton
                  sx={{ width: '16px', height: '16px', padding: '0px' }}
                >
                  <SIDrawerAutocompletePupIconTypeSecond></SIDrawerAutocompletePupIconTypeSecond>
                </IconButton>
              }
              placeholder='Search PD Publisher'
              value={reportPDPublisher}
              setSelected={handleSelectedChange.bind(this, 'reportPDPublisher')}
              loadMore={onLoad.bind(this, 'pdPublisher')}
              limitTags={-1}
              multiple
            />
          </Box>}
          {/* PP Publisher */}
          { showNewJournalRoles && <Box>
            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
            PP Publisher
            </Box>
            <SelectBox
              disablePortal
              id='reportArticlesFilterPpPublisherSelect'
              disablePortal
              data-selenium-id='Report_Articles_Filter-PPPublisherSelect'
              // sx={{ mt: 1 }}
              type ={1}
              data={ppPublisherData}
              labelName={'PP Publisher'}
              popupIcon={
                <IconButton
                  sx={{ width: '16px', height: '16px', padding: '0px' }}
                >
                  <SIDrawerAutocompletePupIconTypeSecond></SIDrawerAutocompletePupIconTypeSecond>
                </IconButton>
              }
              placeholder='Search PP Publisher'
              value={reportPPPublisher}
              setSelected={handleSelectedChange.bind(this, 'reportPPPublisher')}
              loadMore={onLoad.bind(this, 'ppPublisher')}
              limitTags={-1}
              multiple
            />
          </Box>}
          
          {/* Subject Group */}
          <Box>

            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
        Subject Group
            </Box>
            <SelectBox
              disablePortal
              id='reportArticlesFilterSubjectgroupSelect'
              data-selenium-id='Report_Articles_Filter-SubjectgroupSelect'
              // sx={{ mt: 1 }}
              type ={1}
              data={subjectGroupData}
              labelName={'Subject Group'}
              popupIcon={
                <IconButton
                  sx={{ width: '16px', height: '16px', padding: '0px' }}
                >
                  <SIDrawerAutocompletePupIconTypeSecond></SIDrawerAutocompletePupIconTypeSecond>
                </IconButton>
              }
              multiple
              placeholder='Search Subject Group'
              value={reportSubjectGroup}
              setSelected={handleSelectedChange.bind(this, 'reportSubjectGroup')}
              loadMore={onLoad.bind(this, 'subjectGroup')}
              limitTags={-1}
            />
          </Box>
          {/* CAT Group */}
          <Box>

            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
        CAT Group
            </Box>
            <SelectBox
              disablePortal
              id='reportArticlesFilterCatgroupSelect'
              data-selenium-id='Report_Articles_Filter-CatgroupSelect'
              // sx={{ mt: 1 }}
              type ={2}
              data={catGroupData}
              labelName={'CAT Group'}
              placeholder='Choose Group Title'
              value={reportCATGroup}
              setSelected={handleSelectedChange.bind(this, 'reportCATGroup')}
              loadMore={onLoad.bind(this, 'catGroup')}
              multiple
              limitTags={3}
              getValue={item => {
                return item.value;
              }}
              getId={item => {
                return item.id;
              }}
            />
          </Box>
          {/* Business Division */}
          <Box>

            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
        Business Division
            </Box>
            <SelectBox
              disablePortal
              id='reportArticlesFilterBusinessdivisionSelect'
              data-selenium-id='Report_Articles_Filter-BusinessdivisionSelect'
              type ={2}
              // sx={{ mt: 1 }}
              data={businessDivisionData}
              labelName={'Business Division'}
              placeholder='Choose Business Division'
              value={businessDivision}
              setSelected={handleSelectedChange.bind(
                this,
                'reportBusinessDivision'
              )}
              loadMore={onLoad.bind(this, 'businessDivision')}
              multiple
              limitTags={-1}
            />
          </Box>
          {/* Handling CE */}
          <Box>

            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
        Handling CE
            </Box>
            <MulSelectInput
              id='reportArticlesFilterHeSelect'
              data-selenium-id='Report_Articles_Filter-HeSelect'
              value={reportHandlingCE}
              placeholder='Search Handling CE'
              defaultOption={ key =>(key)}
              getOptionLabel={item => {
                return `${item.username} (${item.email})`;
              }}
              options={handlingCeData}
              loadOptionAction={(...props) => onLoad.bind(this, 'handlingCe')(...props)}
              onValueChange={(value) => {
                handleSelectedChange.bind(this, 'reportHandlingCE')(value);
              }}
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
              onOpen={goBottom}
            />
          </Box>
          {/* Journal Status */}
          <Box>

            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
        Journal Status
            </Box>
            <MulSelectInput
              id='journalStatus'
              data-selenium-id='Report_Articles_Filter-journalStatus'
              value={reportAcquireOverviewJournalStatus}
              placeholder='Choose Journal Status'
              defaultOption={ key =>(key)}
              getOptionLabel={option => option}
              options={['opt-in', 'opt-out']}
              onValueChange={(value) => {
                handleSelectedChange.bind(this, 'reportJournalStatus')(value);
              }}
              disableNewItem={true}
              showPopupIcon={true}
              showStartIcon={false}
              dropDownListNumber={2}
              onOpen={goBottom}
            />
          </Box>
          <Stack direction='row' alignItems="center" spacing='50px'>
            <Stack direction='row' alignItems="center" spacing='8px'>
              <Checkbox
                disableRipple
                disabled={!revenueModel}
                icon={<ReportFilterSwitchIconOff />}
                checkedIcon={<ReportFilterSwitchIconOn />}
                checked={!revenueModel}
                onChange={handlerevenueModellChange}
                sx={{
                  padding: '0px',
                }}
              />
              <Box
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '18px',
                  letterSpacing: '0em',
                }}
              >
              All
              </Box>
            </Stack>
            <Stack direction='row' alignItems="center" spacing='8px'>
              <Checkbox
                disableRipple
                disabled={revenueModel}
                icon={<ReportFilterSwitchIconOff />}
                checkedIcon={<ReportFilterSwitchIconOn />}
                checked={revenueModel}
                onChange={handlerevenueModellChange}
                sx={{
                  padding: '0px',
                }}
              />
              <Box
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '18px',
                  letterSpacing: '0em',
                }}
              >
              OA
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Drawer>
    </Box>
  );
}

import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { StyledTableCell } from '../../../components/DataTable';
import DownloadIcon from '../../../assets/downloadTemp.svg';
import UploadIcon from '../../../assets/uploadIcon.svg';
import ViewTargetIcon from '../../../assets/viewTargetIcon.svg';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { switchTab } from '@/actions/SIMT-Report/Report/ReportAction';
import { setNavigationState } from '../../../actions/SideBarAction';
import {
  emptyFileError,

} from '../../../actions/SIMT-Report/Target/AnnualTargetAction';
import {
  switchTarget,
} from '../../../actions/SIMT-Report/Report/ReportAction';
import { getFyYearFromDate } from "../../../utils/date_string";
import { useEffect, useMemo, useState,useRef } from "react";
import moment from 'moment/moment';
import { selectYear as updateRportSelectedFY } from '@/actions/SIMT-Report/Report/ReportAction';
import { FileWarningAlert } from './ATAlert';
import {getTargetsBD, getTargetsCECAT, getTargetsJournal, getTargetsSG} from "@/actions/SIMT-Report/Report/Target";
import {uploadFile} from "@/actions/SIMT-Report/Target";
import {downloadATFile} from "@/actions/SIMT-Report/Target/Export";
const Input = styled('input')({
  display: 'none',
});

function createRow(
  id,
  label,
  isOrder = true,
  minWidth = '110px',
  maxWidth = '220px',
  show = true
) {
  return { id, label, isOrder, minWidth, maxWidth, show };
}

const headItems = [
  createRow('targetLevel', 'TARGET LEVEL', false, '240px', '280px', true),
  createRow('status', 'STATUS', false, '190px', '190px', true),
  createRow('updateTime', 'UPDATED AT', false, '200px', '240px', true),
  createRow('updateBy', 'UPDATED BY', false, '150px', '150px', true),
  createRow('actions', 'ACTIONS', false, '170px', '200px', true),
];

//模拟数据
const ATData = {
  result: [
    {
      targetLevel: 'Journal level targets',
      status: 'Empty',
      updateTime: '2022-05-02 23:00:00',
      updateBy: 'Lee, Alice',
    },
    {
      targetLevel: 'Subject Group level targets',
      status: 'Empty',
      updateTime: 'N/A',
      updateBy: 'N/A',
    },
    {
      targetLevel: 'Business Division level targets',
      status: 'Uploaded',
      updateTime: '2022-05-02 23:00:00',
      updateBy: 'Lee, Alice',
    },
    {
      targetLevel: 'CE/CAT Group level targets',
      status: 'Uploaded',
      updateTime: '2022-05-02 23:00:00',
      updateBy: 'Lee, Alice',
    },
  ],
};

export const formatDatetimeString = x =>
  x ? moment(x).utc().format('yyyy-MM-DD HH:mm:ss') : '';

const handleDate = time => {
  if (time === 'N/A') {
    return time;
  }
  let d = new Date(time);
  let year = d.getFullYear();
  let month = d.getMonth() + 1;
  let day = d.getDate();
  let hours = d.getHours();
  let min = d.getMinutes();
  let seconds = d.getSeconds();

  if (month < 10) month = '0' + month;
  if (day < 10) day = '0' + day;
  if (hours < 10) hours = '0' + hours;
  if (min < 10) min = '0' + min;
  if (seconds < 10) seconds = '0' + seconds;

  return (
    year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + seconds
  );
};

export default function ATTable(props) {
  const { data, loading, setLoading } = props;
  // const data = ATData.result
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const TARGET_TABS = [
    'JournalTarget',
    'SubjectTarget',
    'BusinessTarget',
    'CETarget',
  ];


  const { fiscalYear } = useSelector(state => {
    return {
      fiscalYear: state.AnnualTarget.fiscalYear,
    };
  });

  const fy = useMemo(() => {
    return 'FY' + (fiscalYear % 1000);
  }, [fiscalYear]);
  const upload = (event, targetLevel) => {
    let fileType = event.target.files[0].name.split('.')[1];
    let fileSize = event.target.files[0].size;
    if ((fileType === 'xls' || fileType === 'xlsx') && fileSize <= 5 * 1024 * 1024) {
      setLoading(true);
      dispatch(uploadFile(event.target.files[0], fy, targetLevel));
    } else {
      FileWarningAlert(dispatch,0);
    }
    document.getElementById('annual-target-table-button-file-' + targetLevel).value = null;
  };

  const handleDownload = targetLevel => {
    dispatch(downloadATFile(fy, targetLevel));
  };

  const handleTargetNavigate = (row, index) => {
    if (row.updateTime === 'N/A') {
      dispatch(emptyFileError(true));
    } else {
      dispatch(emptyFileError(false));
      dispatch(updateRportSelectedFY(fy));  // update report state to the selected month.
      dispatch(switchTarget(TARGET_TABS[index])); // locate to the specific table
      navigate(`/simt/auth/reportRelated/report/targets`);
    }
  };


  return (
    <Box>
      <Box
        id='TableBox'
        data-selenium-id='AnnualTarget_Overview-ATTable-TableBox'
      >
        <TableContainer
          id='annual-target-table-container'
          data-selenium-id='AnnualTarget_Overview-ATTable-TableContainer'
          component={Paper}
          className='table-screen-size'
          sx={{
            boxShadow: 'none',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            border: '1px solid #DFE4E8',
          }}
        >
          <Table
            stickyHeader
            id='annual-target-table'
            data-selenium-id='AnnualTarget_Overview-ATTable-Table'
            aria-label='customized table'
            size='small'
          >
            <TableHead
              id='annualTargetTableHead'
              data-selenium-id='AnnualTarget_Overview-ATTable-TableHead'
              style={{ background: '#f1f3f5' }}
            >
              <TableRow
                id='annualTargetTableHeadRow'
                data-selenium-id='AnnualTarget_Overview-ATTable-TableHeadRow'
              >
                {headItems.map(item => (
                  <StyledTableCell
                    id={`annualTargetTableHead${item.id}`}
                    key={item.id}
                    align={'left'}
                    style={{
                      maxWidth: item.maxWidth,
                      minWidth: item.minWidth,
                      display: item.show ? 'table-cell' : 'none',
                      color: '#596A7C',
                      fontSize: '12px',
                      fontWeight: '600',
                      lineHeight: '18px',
                      letterSpacing: '0.08em',
                    }}
                  >
                    {item.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              id='annualTargetTableBody'
              data-selenium-id='AnnualTarget_Overview-ATTable-TableBody'
            >
              {!loading &&
                (data.map((row, index) => (
                  <TableRow key={row.targetLevel}>
                    <StyledTableCell
                      id={`annualTargetTableBodyTargetLevel${index}`}
                      data-selenium-id={`AnnualTarget_Overview-TableBodyTargetLevel-${index}`}
                      component='th'
                      scope='row'
                      sx={{
                        color: 'Gray600.main',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '14px',
                      }}
                    >
                      {row.targetLevel}
                    </StyledTableCell>
                    <StyledTableCell
                      id={`annualTargetTableBodyStatus${index}`}
                      data-selenium-id={`AnnualTarget_Overview-TableBodyStatus-${index}`}
                      component='th'
                      scope='row'
                    >
                      <Box
                        sx={
                          row.status === 'Uploaded'
                            ? {
                                display: 'flex',
                                justifyContent: 'center',
                                minHeight: '28px',
                                alignItems: 'center',
                                width: '88px',
                                color: '#0D6A61',
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '16px',
                                backgroundColor: '#D7F1EE',
                                borderRadius: '50px',
                              }
                            : {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '66px',
                                minHeight: '28px',
                                color: '#596A7C',
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '16px',
                                backgroundColor: '#F1F3F5',
                                borderRadius: '50px',
                              }
                        }
                      >
                        {row.status}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell
                      id={`annualTargetTableBodyUpdateTime${index}`}
                      data-selenium-id={`AnnualTarget_Overview-TableBodyUpdateTime-${index}`}
                      component='th'
                      scope='row'
                      sx={{
                        color: 'Gray600.main',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.01em',
                      }}
                    >
                      {formatDatetimeString(row.updateTime)}
                    </StyledTableCell>
                    <StyledTableCell
                      id={`annualTargetTableBodyUpdateBy${index}`}
                      data-selenium-id={`AnnualTarget_Overview-TableBodyUpdateBy-${index}`}
                      component='th'
                      scope='row'
                      sx={{
                        color: 'Gray600.main',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '14px',
                      }}
                    >
                      {row.updateBy}
                    </StyledTableCell>
                    <StyledTableCell
                      id={`annualTargetTableBodyActionButtons${index}`}
                      data-selenium-id={`AnnualTarget_Overview-TableBodyActionButtons-${index}`}
                      component='th'
                      scope='row'
                      sx={{ p: '0 8px', minWidth: '150px' }}
                    >
                      <Box sx={{ gap: '12px', display: 'flex' }}>
                        <MyTooltip
                          title='download template'
                          child={
                            <IconButton
                              disableRipple
                              id={`annualTargetTableBodyDownloadTemplateButton${index}`}
                              data-selenium-id={`AnnualTarget_Overview-TableBodyDownloadTemplateButton-${index}`}
                              onClick={() => {
                                handleDownload(row.targetLevel);
                              }}
                              sx={{ borderRadius: 0, padding: 0 }}
                            >
                              <DownloadIcon />
                            </IconButton>
                          }
                        />
                        <MyTooltip
                          title='upload a target file'
                          child={
                            <label
                              id={`annualTargetTableBodyUploadLabel${index}`}
                              data-selenium-id={`AnnualTarget_Overview-TableBodyUploadLabel-${index}`}
                              htmlFor={
                                'annual-target-table-button-file-' +
                                row.targetLevel
                              }
                            >
                              <Input
                                accept='.xls,.xlsx'
                                id={
                                  'annual-target-table-button-file-' +
                                  row.targetLevel
                                }
                                data-selenium-id={
                                  'annual-target-table-button-file-' +
                                  row.targetLevel
                                }
                                type='file'
                                onChange={event => {
                                  upload(event, row.targetLevel);
                                }}
                              />
                              <IconButton
                                disableRipple
                                component='span'
                                sx={{ borderRadius: 0, padding: 0 }}
                              >
                                <UploadIcon />
                              </IconButton>
                            </label>
                          }
                        />
                        <MyTooltip
                          title='view target'
                          child={
                            <IconButton
                              disableRipple
                              id={`annualTargetTableBodyViewTargetButton${index}`}
                              data-selenium-id={`AnnualTarget_Overview-TableBodyViewTargetButton-${index}`}
                              onClick={() => {
                                handleTargetNavigate(row, index);
                              }}
                              sx={{ borderRadius: 0, padding: 0 }}
                            >
                              <ViewTargetIcon />
                            </IconButton>
                          }
                        />
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                )) ??
                  'No data to show')}
              {loading && (
                <>
                  <StyledTableCell></StyledTableCell>
                  <Box
                    sx={{
                      width: 'auto',
                      marginTop: '100px',
                      marginBottom: '100px',
                      marginLeft: '250px',
                      marginRight: '10px',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

function MyTooltip(props) {
  const { title, child } = props;
  return (
    <Tooltip
      title={title}
      disableInteractive
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            background: '#FFFFFF',
            border: '1px solid #D8D9DA',
            borderRadius: '4px',
            color: '#484848',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '120%',
            padding: '8px 12px',
          },
        },
      }}
    >
      {child}
    </Tooltip>
  );
}

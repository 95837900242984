import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Dialog,
  Box,
} from '@mui/material';
import { useDispatch } from 'react-redux';
//import SelectDownIcon from '@/assets/SelectDownIcon.svg';
import SelectDownIcon from '../../../../assets/SelectDownArrow.svg';
import CloseButton from '@/componentsphase2/CloseButton';
import ErrorIcon from '@/assets/Error.svg';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import DrawerCloseButton from '@/componentsphase2/DrawerCloseButton';

/**
 * A dialog exporting selected SI pages with appointed format.
 * @param {*} props properties handled from the parent component
 * @returns {JSX.Element} structure of this component
 */
function ExportSIPage(props) {
  // Get properties handled to this component.
  const { open, handleClose, handleExport } = props;

  // Define states to use in this component.
  const [exportSIFormat, setExportSIFormat] = useState('');
  const [canExport, setCanExport] = useState(true);
  const [hasError, setHasError] = useState(false);

  // Get reference of dispatch.
  const dispatch = useDispatch();

  // Execute when the dialog is being opened or closed.
  useEffect(() => {
    // Reset all states when the hook is activated.
    setExportSIFormat('');
  }, [open]);

  useEffect(() => {
    if (exportSIFormat !== '') setHasError(false);
  }, [exportSIFormat]);

  useEffect(() => {
    if ((exportSIFormat === '' && !hasError) || exportSIFormat !== '')
      setCanExport(true);
    else setCanExport(false);
  }, [exportSIFormat, hasError]);

  const getShowValue = exportSIFormat => {
    if (exportSIFormat === 'csv') return 'Export CSV';
    else if (exportSIFormat === 'xls') return 'Export EXCEL';
    return '';
  };
  // Structure of this component.
  return (
    <Dialog
      id='dialogExportSIPage'
      data-selenium-id='SIPage_Overview-ExportSIPage-DialogExportSIPage'
      open={open}
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
      PaperProps={{
        style: {
          boxShadow: 'none',
        },
      }}
      sx={{
        '& .MuiDialog-paper': {
          minWidth: '600px',
          minHeight: hasError ? '199px' : '194px',
          margin: 0,
          padding: 0,
          boxShadow: 'none',
        },
        background: 'rgba(17, 61, 149, 0.5)',
        backdropFilter: 'blur(4px)',
        borderRadius: '5px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '536px',
          height: '24px',
          left: '32px',
          top: '24px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          padding: '0px',
          gap: '16px',
        }}
      >
        <span
          style={{
            fontSize: '18px',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            lineHeight: '125%',
            color: '#262E35',
          }}
        >
          Export Special Issues
        </span>
        <DrawerCloseButton onClick={handleClose} ></DrawerCloseButton>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          width: '536px',
          height: '40px',
          left: '32px',
          top: '68px',
        }}
      >
        <InputLabel
          sx={{
            position: 'absolute',
            height: '20px',
            left: '0px',
            top: '0px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            textTransform: 'capitalize',
            color: '#596A7C',
            paddingLeft: '5px',
          }}
        >
          <span>Format</span>
          <span
            style={{
              fontSize: '12px',
              letterSpacing: '0.08em',
              fontWeight: 600,
              textTransform: 'uppercase',
              marginLeft: '2px',
              color: '#C40000',
            }}
          >
            *
          </span>
        </InputLabel>
        <FormControl
          sx={{
            position: 'absolute',
            width: '358px',
            height: '40px',
            left: '178px',
            top: '0px',
          }}
        >
          <SinpleAutocomplete
            id='export-si-format'
            data-selenium-id='SIOverview_Export-export-si-format'
            placeholder='Choose Format'
            value={getShowValue(exportSIFormat)}
            onChange={(e, value) => {
              if (value === 'Export CSV') setExportSIFormat('csv');
              else if (value === 'Export EXCEL') setExportSIFormat('xls');
            }}
            options={['Export CSV', 'Export EXCEL']}
            defaultQuery={true}
          />
          {/*<Select*/}
          {/*  displayEmpty*/}
          {/*  input={<OutlinedInput />}*/}
          {/*  id='export-si-format'*/}
          {/*  data-selenium-id='SIOverview_Export-export-si-format'*/}
          {/*  value={exportSIFormat}*/}
          {/*  onChange={e => {*/}
          {/*    setExportSIFormat(e.target.value);*/}
          {/*  }}*/}
          {/*  renderValue={(selected) => {*/}
          {/*    if (selected.length === 0) {*/}
          {/*      return <span style={{*/}
          {/*        color: '#BCC5CF',*/}
          {/*        fontFamily: 'Open Sans',*/}
          {/*        fontSize: '14px',*/}
          {/*        fontWeight: 400,*/}
          {/*        lineHeight: '18px',*/}
          {/*        letterSpacing: '0em',*/}
          {/*      }}>Choose Format</span>;*/}
          {/*    }*/}
          {/*    else if (selected === 'csv') {*/}
          {/*      return <span style={{ color: '#262E35' }}>Export CSV</span>;*/}
          {/*    }*/}
          {/*    return <span style={{ color: '#262E35' }}>Export EXCEL</span>;*/}
          {/*  }}*/}
          {/*  IconComponent={SelectDownIcon}*/}
          {/*  sx={{*/}
          {/*    height: '40px',*/}
          {/*    width: '358px',*/}
          {/*    '& .MuiOutlinedInput-notchedOutline': { border: hasError? '2px solid #EE5350':'1px solid #DFE4E8'},*/}
          {/*  }}*/}
          {/*  MenuProps={{*/}
          {/*    PaperProps: {*/}
          {/*      style: {*/}
          {/*        boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',*/}
          {/*        marginTop: '6px',*/}
          {/*      }*/}
          {/*    },*/}
          {/*    MenuListProps: {*/}
          {/*      style: {*/}
          {/*        paddingTop: '7px',*/}
          {/*        paddingBottom: '7px',*/}
          {/*      }*/}
          {/*    }*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <MenuItem*/}
          {/*    id='exportAllSIPageCSVOption'*/}
          {/*    data-selenium-id='SIPage_Overview-ExportAllSIPage-CSVOption'*/}
          {/*    value={'csv'}*/}
          {/*    sx={{*/}
          {/*      fontSize: '12px',*/}
          {/*      '&:hover': {*/}
          {/*        color: '#262E35',*/}
          {/*      },*/}
          {/*      height: '30px',*/}
          {/*      color: '#596A7C',*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    Export CSV*/}
          {/*  </MenuItem>*/}
          {/*  <MenuItem*/}
          {/*    id='exportAllSIPageXLSOption'*/}
          {/*    data-selenium-id='SIPage_Overview-ExportAllSIPage-XLSOption'*/}
          {/*    value={'xls'}*/}
          {/*    sx={{*/}
          {/*      fontSize: '12px',*/}
          {/*      '&:hover': {*/}
          {/*        color: '#262E35',*/}
          {/*      },*/}
          {/*      height: '30px',*/}
          {/*      color: '#596A7C',*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    Export EXCEL*/}
          {/*  </MenuItem>*/}
          {/*</Select>*/}
        </FormControl>
      </Box>
      {hasError ? (
        <Box
          data-selenium-id='SIOverview-Export-FormatChoose-ErrorMessage'
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            lineHeight: '16px',
            color: '#EE5350',
            position: 'absolute',
            bottom: '71px',
            left: '210px',
            height: '16px',
          }}
        >
          <ErrorIcon sx={{ height: '12px' }} />
          <p style={{ margin: '0px' }}>
            &nbsp;Please provide the required information.
          </p>
        </Box>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          gap: '16px',
          position: 'absolute',
          width: '536px',
          height: '66px',
          bottom: '0px',
          left: '32px',
          // position: 'absolute',
          // width: '536px',
          // height: '66px',
          // left: '32px',
          // top: '128px',
          // right: '32px',
        }}
      >
        <Button
          disableElevation
          disableRipple
          variant='outlined'
          id='exportAllSIPageCancelButton'
          data-selenium-id='SIPage_Overview-ExportAllSIPage-CancelButton'
          onClick={() => {
            handleClose();
          }}
          sx={{
            boxSizing: 'border-box',
            padding: '8px 12px',
            gap: '6px',
            position: 'absolute',
            width: '70px',
            height: '34px',
            left: '387px',
            top: '12px',
            border: '1px solid #0052CC',
            borderRadius: '5px',
            color: '#0052CC',
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '18px',
            letterSpacing: '0em',
            textTransform: 'none',
            '&:hover': {
              color: '#6D98EE',
              borderColor: '#6D98EE',
              background: '#FFFFFF',
            },
            '&:active': {
              background: '#E8EDFB',
              color: '#6D98EE',
              borderColor: '#AEC6F6',
            },
          }}
        >
          Cancel
        </Button>
        <Button
          disableElevation
          disableRipple
          variant='contained'
          id='exportAllSIPageExportButton'
          data-selenium-id='SIPage_Overview-ExportAllSIPage-ExportButton'
          disabled={!canExport}
          onClick={() => {
            if (exportSIFormat === '') {
              setCanExport(false);
              setHasError(true);
            } else {
              handleExport(exportSIFormat);
              handleClose();
            }
          }}
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '18px',
            letterSpacing: '0em',
            boxSizing: 'border-box',
            padding: '8px 12px',
            gap: '6px',
            position: 'absolute',
            width: '70px',
            height: '34px',
            left: '466px',
            top: '12px',
            borderRadius: '5px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#6D98EE',
            },
            '&:active': {
              backgroundColor: '#113D95',
            },
            backgroundColor: '#0052CC',
          }}
        >
          Export
        </Button>
      </Box>
    </Dialog>
  );
}

export default ExportSIPage;

import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { style, styled, width } from '@mui/system';
import { StateButton } from '@/componentsphase2/StateButton';
import TextFieldErrorIcon from '@/assets/TextFieldErrorIcon.svg';
  
  
export default function ResetAlterPopup(props) {
  const {
    open = true, //控制popup的打开和关闭
    setOpen = () => {},
    onClickOk = () => {},
    onClickCancel = () => {},
    // journalCode = 1234,
    // page = 1,
    // pageSize = 50,
  } = props;
  
  const intl = useIntl();
  
  const dispatch = useDispatch();
  const titleCss = {
    // width: '69px',
    // height: '32px',
    // backgroundColor: '#154AB6',
    // borderRadius: '8px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22.5px',
    color: '#262E35',
  };
  
  const titleCss2 = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17.5px',
    color: '#262E35',
  };
  const clickCancel = e => {
    setOpen(false);
  };
  
  const clickSave = e => {
    e.preventDefault();
    dispatch(approveOptOut(journalCode, page, pageSize));
    setOpen(false);
  };
  
  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            boxShadow: 'none',
          },
        }}
        open={open}
        onClose={clickCancel}
        sx={{
          zIndex: 1401,
          // background: ' rgba(9, 30, 66, 0.54)',
          '& .MuiDialog-paper': {
            width: '600px',
            minWidth: '600px',
            margin: 0,
            paddingTop: '24px',
            paddingBottom:'0px',
            paddingLeft: '32px',
            paddingRight: '32px',
            gap: '16px',
            boxSizing: 'border-box',
            marginTop: '33px',
          },
          '.css-hz1bth-MuiDialog-container': {
            // background: 'rgba(9, 30, 66, 0.54)',
          },
          '& .MuiBackdrop-root': {
            background: 'rgba(17, 61,149, 0.5)',
            backdropFilter: 'blur(4px)',
    
          },
        }}
        data-selenium-id='Email-ResetAlter-PopUp-Dialog'
      >
        <Box
          data-selenium-id='Email-ResetAlter-PopUp-Title'
        >
          <Box
            style={titleCss}
          >
            Replace your customized template with the system template?
          </Box>
         
        </Box>

        <Stack
          direction='row'
          alignItems='center'
          spacing={0.25}
          data-selenium-id='Email-ResetAlter-PopUp-Title1'
        >
          <TextFieldErrorIcon  />
          <Box
            style={titleCss2}
            // sx={{ml:'2px'}}
          >
            This action will overwrite changes you have made to your current template.
          </Box>
         
        </Stack>
          
  
        <Stack
          direction='row' spacing={1.125} justifyContent="center" sx={{paddingTop: '12px', paddingBottom:'20px'}}
            
          data-selenium-id='Email-ResetAlter-PopUp-MainBox'
        >
          <StateButton
            isPrimary = {false}
            titleName = 'Cancel'
            onClick={onClickCancel}
            data-selenium-id='Email-ResetAlter-PopUp-Box1'
            sx ={{
              fontSize : '14px',
              lineHeight: '18px',
              width: '70px'
            }}
          >
          </StateButton>
  
          <StateButton
            isPrimary = {true}
            titleName = 'Ok'
            onClick={onClickOk}
            data-selenium-id='Email-ResetAlter-PopUp-Box2'
            sx ={{
              fontSize : '14px',
              lineHeight: '18px',
              width: '70px'
            }}
          >
          </StateButton>
  
  
        </Stack>
      </Dialog>
    </>
  );
}
  
import { Box, FormControl } from '@mui/material';
import { DisabledTitle } from '../../../../components/CreateAndUpdate/MyTitle';
import {
  DisabledInput,
  DisabledInputForSubmissionPass,
} from '../../../../components/CreateAndUpdate/MyOperationModule';
import ItemBlock from '../CGTComponents/ItemBlock';
import DisabledBox from '../CGTComponents/DisabledBox';
function CreateSIDisabledBlock(props) {
  const { value, defaultValue, title, id } = props;
  return (
    <ItemBlock id={id} title={title} disabled={true}>
      <DisabledBox
        value={value}
        defaultValue={defaultValue}
        id={id}
        submissionPass={props?.submissionPass}
      />
    </ItemBlock>
  );
}
export default CreateSIDisabledBlock;

/**
 * get a list of FY by passing current year and number of years before
 */
export const getFYs = (yearMonth, numberOfYears = 3) => {
  if (!yearMonth) return null;
  let minusIndex = yearMonth.indexOf('-');
  let year = parseInt(yearMonth.substr(0, minusIndex));
  let month = parseInt(yearMonth.substr(minusIndex + 1));

  if (month > 4) {
    // May is the first month of a Wiley Fiscal Year
    year += 1;
  }

  const listOfFYs = [];
  for (let i = numberOfYears - 1; i >= 0; i--)
    listOfFYs.push('FY' + getPaddedYear(year - i) + ' TD');

  // console.log('===listOfFYs===', listOfFYs);

  return listOfFYs;
};

/**
 * get FY year, padding 0 if there is only digit. e.g "02"
 */

const getPaddedYear = year => {
  if (!year) return null;
  let result = year.toString().substr(-2);
  return result;
};

/**
 * check if has valid data (any value which is a number but neither 0s, nor 0%)
 * 
 * for sample data like below:
 * 
 *   // sample data
 * [
    {
        "acquiredSi": 0,
        "acquiredYtd": 0,
        "activeSi": 0,
        "acquiredSiOa": 0,
        "acquiredYtdOa": 0,
        "activeSiOa": 0,
        "againstYTDOa": "-",
        "againstAcquiredSiOa": "-",
        "againActiveSiOa": "-",
        "businessDivision": "STMS Health Sciences"
    },
]
 */

export const checkEmptyData = (data, attributes) => {
  console.log('===func "checkEmptyData" ===');
  console.log('===data===', data);
  console.log('===attributes===', data);
  if (Array.isArray(data) && data.length === 0) {
    return true;
  }

  let isEmpty = true;

  // check
  data.forEach(entry => {
    attributes.forEach(attribute => {
      // console.log(entry[attribute]);
      const num = parseFloat(entry[attribute]);
      const isNum = !Number.isNaN(num);
      // console.log('===num===', num);
      const isValid = isNum && num !== 0;
      if (isValid) {
        isEmpty = false;
      }
    });
  });

  // console.log('===isEmpty===', isEmpty);

  return isEmpty;
};

/**
 * check if has valid data (any value which is a number but neither 0s, nor 0%)
 * 
 * for sample data like below:
 * 
 *   // sample data
 * [
    ['subjectGroup', 'FY21 TD', 'FY22 TD', 'FY23 TD', 'ytd_1', 'ytd_2'],
    ['ISORN', 0, 3, 16, null, '433%'],
    ['CMI', 0, 0, 0, null, null],
  ];
 */

export const checkEmptyArrayData = data => {
  console.log('===func "checkEmptyArrayData" ===', data);
  if (Array.isArray(data) && data.length === 0) {
    return true;
  }

  let isEmpty = true;

  // check
  data.forEach(row => {
    for (let i = 1; i <= 3; i++) {
      const num = parseFloat(row[i]);
      const isNum = !Number.isNaN(num);
      const isValid = isNum && num !== 0;
      if (isValid) {
        isEmpty = false;
      }
    }
  });

  // console.log('===isEmpty===', isEmpty);

  return isEmpty;
};

/**
 * check if there is any data for selected certeria
 * @param {chartData, indices}
 * @returns boolean
 */
export const checkEmptyDataWithIndices = (data, indices) => {
  console.log('===func "checkEmptyArrayData" ===', data);
  if (Array.isArray(data) && data.length === 0) {
    return true;
  }

  let isEmpty = true;

  // check
  data.forEach((row, i) => {
    indices.forEach(index => {
      // console.log('i | index : ', i + ' | ' + index + ' : ' + row[index]);
      const num = parseFloat(row[index]);
      const isNum = !Number.isNaN(num);
      const isValid = isNum && num !== 0;
      if (isValid) {
        isEmpty = false;
      }
    });
  });

  console.log('===isEmpty===', isEmpty);

  return isEmpty;
};

/**
 * convert stage object to an array of active stages
 * @param {*} selectedStageObj
 * @returns
 */
export const toActiveStageArray = selectedStageObj => {
  return Object.keys(selectedStageObj)
    .map(stage => (selectedStageObj[stage] === true ? stage : null))
    .filter(stage => stage !== null);
};
/**
 * calculate bar gap percentage, positive means right shift, negative means left shift
 * @param {*} gapWidth
 * @returns percentage string
 *
 */
export const calcBarGapPercentage = (gapWidth, BarWidth) => {
  return (gapWidth / BarWidth) * 100 + '%';
};

export const toLowerCaseFirstLetter = str =>
  str.charAt(0).toLowerCase() + str.slice(1);

import { Box } from '@mui/system';

function LittleLabel(props) {
  const { textContent } = props;

  // get [color, backgroundColor] by textContent
  const getColor = () => {
    switch (String(textContent).toLocaleLowerCase()) {
      case 'delete':
        return ['#854300', '#FFF0DD'];
      case 'add':
        return ['#0D6A61', '#D7F1EE'];
      case 'update':
        return ['#596A7C', '#F1F3F5'];
      default:
        return ['#596A7C', '#F1F3F5'];
    }
  };

  const [color, backgroundColor] = getColor();

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        textAlign: 'center',
        borderRadius: '100px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
        color: color,
        display: 'inline',
        padding: '4px 12px',
      }}
    >
      {textContent}
    </Box>
  );
}

export default LittleLabel;

import styled from '@mui/material/styles/styled';
import InputBase from '@mui/material/InputBase';
import {
  TextField,
  Select,
  MenuItem,
  ThemeProvider,
  IconButton,
  Switch,
  Autocomplete,
} from '@mui/material';
import { Bootstraptheme } from './MyTheme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useEffect, useState } from 'react';
import CreateAndUpdateCalendarMonthIcon from '../../assets/CreateAndUpdateCalendarMonthIcon.svg';
import CancelIcon from '../../assets/CancelIcon.svg';
import { defaultVars, ErrVars } from './commonStyle';
import CreateAndUpdateSIChevronDown from '../../assets/CreateAndUpdateSIChevronDown.svg';

import moment from 'moment';

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2),
  },
  '& .MuiInputBase-input': {
    background: '#FFFFFF',
    borderRadius: 4,
    border: '2px solid var(--color)',
    boxSizing: 'border-box',
    padding: '10px 0px 10px 12px',
    width: '100%',
    height: '40px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '125%',
    cursor: 'pointer',
    '&:focus': {
      border: 'var(--focus-border)',
      borderRadius: 4,
    },
    '&::placeholder': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '125%',
      color: '#BCC5CF',
      opacity: '1',
    },
  },
}));

export const BootstrapTextField = styled(TextField)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2),
  },
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    borderRadius: 4,
    boxSizing: 'border-box',
    width: 'auto',
    height: '40px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '125%',
    cursor: 'pointer',
    padding: '8.5px 0px 8.5px 12px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '0px 0px',
    '&::placeholder': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '125%',
      color: '#BCC5CF',
      opacity: '1',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '2px solid var(--color)',
  },
  '& :hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid var(--color)',
    },
  },
}));

export const DisabledInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2),
  },
  '& .MuiInputBase-input': {
    padding: '10px 0px 10px 12px',
    borderRadius: 4,
    width: '100%',
    height: '40px',
    background: '#F6F7F8',
    boxSizing: 'border-box',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '125%',
    border: '2px solide #F6F7F8',
  },
  '& .Mui-disabled': {
    '-webkit-text-fill-color': '#BCC5CF !important',
  },
}));

export const DisabledInputForSubmissionPass = styled(InputBase)(
  ({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
      padding: '10px 0px 10px 12px',
      borderRadius: 4,
      width: '100%',
      height: '40px',
      background: '#F6F7F8',
      boxSizing: 'border-box',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '125%',
      border: '2px solide #F6F7F8',
      '-webkit-text-fill-color': '#d32f2f',
    },
  })
);

export const BootstrapAutoComplete = styled(Autocomplete)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2),
  },
  '& .MuiOutlinedInput-root': {
    height: '40px',
    background: '#FFFFFF',
    borderRadius: 4,
    boxSizing: 'border-box',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '125%',
    cursor: 'pointer',
    padding: '8.5px 0px 8.5px 12px',
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '0px 0px',
    '&::placeholder': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '125%',
      color: '#BCC5CF',
      opacity: '1',
    },
  },
  '& :hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid var(--color)',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '2px solid #DFE4E8',
  },
}));

export const CssSelect = styled(Select)(({ theme }) => ({
  '& .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input':
    {
      paddingRight: '0px',
    },
}));

export function BootstrapSelect(props) {
  const { id, list, attribute, vars, value, defaultValue, ...others } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getIcon = () => {
    if (open) {
      return (
        <IconButton
          sx={{
            transform: 'rotate(180deg)',
            position: 'absolute',
            right: '7px',
            padding: '0px',
          }}
          onClick={handleClose}
        >
          <CreateAndUpdateSIChevronDown></CreateAndUpdateSIChevronDown>
        </IconButton>
      );
    } else {
      return (
        <IconButton
          onClick={handleOpen}
          sx={{ position: 'absolute', right: '7px', padding: '0px' }}
        >
          <CreateAndUpdateSIChevronDown></CreateAndUpdateSIChevronDown>
        </IconButton>
      );
    }
  };
  return (
    <CssSelect
      {...others}
      id={id}
      value={value}
      // defaultValue={defaultValue}
      input={<BootstrapInput style={vars} />}
      IconComponent={getIcon}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
    >
      {list.map((item, index) => (
        <MenuItem value={item[attribute]} key={`${id}${index}`}>
          {item[attribute]}
        </MenuItem>
      ))}
    </CssSelect>
  );
}

export function BootstrapDatePicker(props) {
  const {
    id,
    value,
    defaultValue,
    maxDate,
    minDate,
    handleChange,
    placeholder,
    emptyErr,
  } = props;
  const [dateOpen, setDateOpen] = useState(false);
  const [vars, setVars] = useState(defaultVars);
  useEffect(() => {
    setVars(emptyErr || props.error ? ErrVars : defaultVars);
  }, [emptyErr, props.error]);

  // 初始渲染的时候value会在null的时候 就会修改dataValue
  const [dateValue, setDateValue] = useState(new DataTransfer(value));

  // 所以需要在value修改的时候 重新修改dateValue
  useEffect(() => {
    setDateValue(moment(value).utc().format('YYYY-MM-DD'));
  }, [value]);

  const dateInput = e => {
    setDateValue(e.target.value);
    let pattern =
      /^(?:(?!0000)[0-9]{4}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[0-9]{2}(?:0[48]|[2468][048]|[13579][26])|(?:0[48]|[2468][048]|[13579][26])00)-02-29)$/;
    let regExp = new RegExp(pattern);
    //当他符合时间的正则时候 就会修改
    if (regExp.test(e.target.value)) {
      handleChange(new Date(e.target.value));
    }
    // 当他输入为空时候就会修改 为空值
    if (e.target.value === '') {
      handleChange(null);
    }
  };

  const dataValueJudge = () => {
    let pattern =
      /^(?:(?!0000)[0-9]{4}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[0-9]{2}(?:0[48]|[2468][048]|[13579][26])|(?:0[48]|[2468][048]|[13579][26])00)-02-29)$/;
    let regExp = new RegExp(pattern);
    // 当关闭时候 当输入框有值但不符合时候 就会返回到上次修改的那个值
    if (!regExp.test(dateValue) && dateValue !== '') {
      setDateValue(moment(value).utc().format('YYYY-MM-DD'));
    }
  };

  return (
    <ThemeProvider theme={Bootstraptheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          {...props}
          mask='____-__-__'
          inputFormat='yyyy-MM-dd'
          open={dateOpen}
          onOpen={() => {
            setDateOpen(true);
          }}
          onClose={() => {
            setDateOpen(false);
            dataValueJudge();
          }}
          id={id}
          value={value}
          defaultValue={defaultValue}
          maxDate={maxDate}
          minDate={minDate}
          onChange={changeValue => {
            handleChange(changeValue);
            setDateValue(moment(changeValue).utc().format('YYYY-MM-DD'));
          }}
          renderInput={params => (
            <BootstrapTextField
              id={id + 'Input'}
              placeholder={placeholder}
              style={vars}
              {...params}
              error={emptyErr || props.errors}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setDateOpen(!dateOpen);
                    }}
                  >
                    <CreateAndUpdateCalendarMonthIcon />
                  </IconButton>
                ),
              }}
              inputProps={{
                ...params.inputProps,
                value: dateValue === 'Invalid date' ? '' : dateValue,
                onChange: dateInput,
                onBlur: dataValueJudge,
                placeholder: placeholder,
              }}
            />
          )}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export const BootstrapSwitch = styled(Switch)(({ theme }) => ({
  width: '51px',
  height: '22px',
  padding: '1px 2px',
  borderRadius: '16px',
  display: 'flex',
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(27.5px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#154AB6',
        '&:before': {
          content: '"Yes"',
          left: 6,
        },
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 18,
    height: 18,
    borderRadius: 16,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16,
    opacity: 1,
    backgroundColor: 'rgb(223,228,232)',
    boxSizing: 'border-box',
    '&:before, &:after': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 22,
      height: 22,
      padding: '2px',
      fontFamily: '"Open Sans", serif',
      fontSytle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '22px',
      color: '#FFFFFF',
    },
  },
}));

export function BootstrapNumber(props) {
  //maxNumber:最大值(number)
  //minNumber:最小值(number)
  //exSpecialChar：排除特殊字符
  //setValue：设置文本框的值(必须设置value值)
  //setSCErr:传递包含特殊字符的Err信息给父组件
  const { maxNumber, minNumber, exSpecialChar, setValue, setSCErr } = props;
  const [specialChar, setSpecialChar] = useState(false);
  const [outRangeError, setOutRangeError] = useState(false);

  //检测是否包含特殊字符
  const ifSpecialChar = text => {
    let pattern = new RegExp('^-?[0-9]*$');
    setSpecialChar(!pattern.test(text));
    if (setSCErr) {
      setSCErr(pattern.test(text));
    }
    return pattern.test(text);
  };

  const helperTextSelector = () => {
    if (exSpecialChar && specialChar) {
      return 'Please input positive integer number!';
    } else if (outRangeError) {
      return `Number must between ${minNumber} and ${maxNumber}`;
    } else if (props.error) {
      return props.helperText;
    } else {
      return null;
    }
  };

  const handleTextChange = e => {
    // try {
    var n = e.target.value;
    if (n !== '' && !ifSpecialChar(n)) {
      e.target.value = 0;
    } else {
      n = Number(n);
      if (n === 0 && e.target.value.length > 1) {
        e.target.value = 0;
      } else if (n > 0 && e.target.value[0] === '0') {
        e.target.value = n;
      }
      if (n > maxNumber) {
        e.target.value = maxNumber;
        setOutRangeError(true);
      } else if (n < minNumber) {
        e.target.value = minNumber;
        setOutRangeError(true);
      } else {
        setOutRangeError(false);
      }
    }
    setValue(e.target.value);
  };

  return (
    <BootstrapInput
      {...props}
      onChange={handleTextChange}
      error={(exSpecialChar && specialChar) || props.error || outRangeError}
      helperText={helperTextSelector()}
    />
  );
}

export const BootstrapTextArea = styled('textarea')(({ theme, value }) => ({
  'label + &': {
    marginTop: theme.spacing(2),
  },
  width: 'auto',
  height: 'var(--height)',
  resize: 'none',
  border: value && value !== '' ? '1px solid #98A7B6' : '1px solid #DFE4E8',
  borderRadius: 3,
  background: '#FFFFFF',
  padding: '8px 12px',
  overflowY: 'auto',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '125%',
  '&:hover': {
    border: '1px solid #596A7C',
  },
  '&:focus-visible': {
    outlineColor: 'var(--focus-color)',
  },
  '&::placeholder': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '125%',
    color: '#BCC5CF',
    opacity: '1',
  },
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-thumb': {
    border: '3px solid #fff',
  },
}));

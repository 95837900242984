import { createTheme } from '@mui/material';

export const themeTextField = createTheme({
  // 用于控制TextField最外层的formControl的样式
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
  },
});

export const themeSelect = createTheme({
  //用于控制select框外面最外层的outlinedInput
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: '100%',
          '& .Mui-error.MuiOutlinedInput-notchedOutline':{
            border: '2px solid #EE5350 !important'
          }
        },
      },
    },
  },
});

export const themeButton = (backgroundColor = '#DFE4E8') =>{
  return createTheme({
    //用于控制select框外面最外层的outlinedInput
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '&:hover':{
              backgroundColor:backgroundColor,
            },
            '&:focus':{
              backgroundColor:backgroundColor,
            }
          },
        },
      },
    },
  });
};
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  tooltipClasses,
} from '@mui/material';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import {
  CustomTooltipSmallArrow,
} from '@/componentsphase2/CustomTooltip';
import { measureWidth } from '@/utils/StringWidthMeasure';
import { calcHeight } from '@/componentsphase2/Autocomplete/Utils';
import { LimitWord } from '@/componentsphase2/Autocomplete/CommonStyle';
import { getSIPUser } from "@/actions/SIMT-SIP/SIP/SipUsers";

const DECISION_PANEL = 'DECISION_PANEL';
const scope = DECISION_PANEL;

export default function UserSelectedInput(props) {
  const {
    permissionCode,
    user = {
      userId: null,
      username: null,
      email: null,
    },
    update = () => {},
    disabled = false,
  } = props;

  const [partTip, setPartTip] = useState('');
  const [openTooltip, setOpenTooltip] = useState(false);
  const [opened, setOpened] = useState(false);
  const [focused, setFocused] = useState(false);
  const { sipUsers } = useSelector(state => {
    return {
      sipUsers: state.SIP.users ?? {},
    };
  });

  const userOptions = useMemo(() => {
    try{
      const users = sipUsers[`${scope}::${permissionCode}`];
      return users ?? [];
    }catch(e){
      console.error(`sip users (${scope}::${permissionCode}) not found.`);
      return [];
    }
  }, [sipUsers, scope, permissionCode]); 
  
  useEffect(() => {
    const tip = getTip(user?.username, user?.email);
    setPartTip(tip);
    if(hasInitialUser){
      update(user);
    }
  },[user]);

  const onSelect = user => {
    const tip = getTip(user?.username, user?.email);
    setPartTip(tip);
    update(user);
  };

  const hasInitialUser = useMemo(() => {
    return user?.userId !== null;
  }, [user]);

  const loadOptions = useCallback((text) => {
    return getSIPUser(text, {
      scope,
      permissionCode,
    });
  }, [permissionCode]);

  return (
    <CustomTooltipSmallArrow
      placement='top'
      title={partTip}
      disableFocusListener
      disableHoverListener
      disableInteractive
      open={openTooltip && !opened && !focused}
      sx={{
        '& .MuiTooltip-arrow': {
          width: '12px',
          height: '8px',
          top: '100% !important',
        },
        [`& .${tooltipClasses.tooltip}`]: {
          marginBottom: '-4px !important',
          width:'unset',
          maxWidth:'unset',
          minWidth:'unset'
        },
      }}
    >
      <Box
        width='286px'
        onMouseEnter={() => setOpenTooltip(true)}
        onMouseLeave={() => setOpenTooltip(false)}
        onBlur={() => setOpenTooltip(false)}
      >
        <SinpleAutocomplete
          value={ hasInitialUser ? user : null }
          id='HandingCEAC'
          options={[...userOptions]}
          getOptionLabel={option => {
            return option?.username;
          }}
          config={{
            scrollProps: {
              scrollOverviwSX: {
                height: `${calcHeight(userOptions, 6, 6, 34, 6, 0)}px`,
              },
            },
            outlinedInputProps: {
              outlinedInputSX: {
                backgroundColor: '#FFFFFF',
              },
              inputSX:{
                root:{
                  maxWidth:focused?'246px':'164px',
                  ...LimitWord
                }
              }
            },
          }}
          renderOption={(props, option, state) => (
            <li
              {...props}
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '4px',
                height: '30px',
                fontSize: '12px',
                lineHeight: '9px',
                marginBottom: '4px',
              }}
              ref={node => {
                if (node) {
                  node.style.setProperty('padding-bottom', '4px', 'important');
                  node.style.setProperty('padding-top', '4px', 'important');
                  node.style.setProperty('display', 'flex', 'important');
                }
              }}
            >
              <div>{option.username}</div>
              <div>{option.email}</div>
            </li>
          )}
          filterOptions={x => x}
          onChange={(e, value) => {
            onSelect(value||'');
          }}
          defaultQuery={false}
          loadOptionAction={loadOptions}
          needClearInputValue={true}
          onOpen={() => {
            setOpened(true);
            setTimeout(() => {
              document.body.classList.add('hide-scroll');
              document.querySelector(
                '[data-selenium-id="AuthPage-OutletGrid"]'
              ).style.overflow = 'hidden';
            }, 100);
          }}
          onClose={() => {
            setOpened(false);
            setTimeout(() => {
              document.body.classList.remove(
                'hide-scroll'
              );
              document.querySelector(
                '[data-selenium-id="AuthPage-OutletGrid"]'
              ).style.overflow = 'auto';
            }, 100);
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={disabled}
        />
      </Box>
    </CustomTooltipSmallArrow>
  );
}

const getTip = (username, email) => {
  if (measureWidth(username, '16px Open Sans') > 164) {
    return username + ' (' + email + ')';
  } else {
    return email;
  }
};
import {
  Table,
  TableContainer,
  TableRow,
  TableBody,
  Tooltip,
  Box,
  tableCellClasses,
  TableHead,
} from '@mui/material';
import { MyTableHeader, TableFooter2 } from '../../../../components/DataTable';
import { styled } from '@mui/system';
import { Paper, TableCell } from '@mui/material';
import { formatDatetimeString } from '@/utils/commonUtils';
import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { SmallBoldFont } from '../../../../components/FontStyle';
import EmailContent from '../../../SIPPage/SIPDetailPage/SIPLog/EmailContent';
import { GetEvidentLabel } from '@/components/FileUploader/LabelFile';
import { useDispatch } from 'react-redux';
import { modifyfileName } from '@/pages/GEPage/GEDetailPage/FlagBlock';
import {downloadSingleFile} from "@/actions/SIMT-SI/GuestEditor/GEFlag";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E8F2',
    color: '#505050',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '0.01em',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '44px',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '24px',
    borderBottom: '1px solid #DFE4E8'
  },
}));

function MyTable(props) {
  //从父组件获取的属性
  const {
    id,
    TableHeads,
    page,
    rowsPerPage,
    TableData,
    rowsPerPageOptions,
    count,
    handlePageChange,
    handleRowsPerPageChange,
    extraInfo,
    geCode = "GE-0000-000000"
  } = props;

  const [open, setOpen] = useState(false);
  const [emailContent, setEmailContent] = useState('');



  const Text = (props) => {
    const { text } = props;
    const parent = useRef(null);
    const child = useRef(null);
    const [ellipsis, setEllipsis] = useState(false);

    useEffect(() => {
      const textElement = child.current;
      const parentElement = parent.current;
      if (textElement && parentElement) {
        const textWidth = textElement.offsetWidth;
        const parentWidth = parentElement.offsetWidth;

        if (textWidth > parentWidth) {
          setEllipsis(true);
        } else {
          setEllipsis(false);
        }
      }
    }, []);
    return (
      <>
        <div
          ref={parent}
        >
          <Tooltip
            title={text}
            disableHoverListener={!ellipsis}
            placement='top'
            arrow
          >
            <div
              style={{
                textOverflow: 'ellipsis',
                width: TableHeads.maxWidth,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              <span ref={child} >{text}</span>
            </div>
          </Tooltip>
        </div>
      </>
    );
  };
  const DoubleLineText = (props) => {
    const { text } = props;
    const parent = useRef(null);
    const child = useRef(null);
    const [ellipsis, setEllipsis] = useState(false);

    useEffect(() => {
      const textElement = child.current;
      const parentElement = parent.current;
      if (textElement && parentElement) {
        const textHeight = textElement.offsetHeight;
        const parentHeight = parentElement.offsetHeight - 24;
        if (textHeight > parentHeight) {
          setEllipsis(true);
        } else {
          setEllipsis(false);
        }
      }
    }, []);
    return (
      <>
        <div style={{ maxHeight: '128px', overflow: 'hidden',paddingTop:'12px',paddingBottom:'12px' }} ref={parent}>
          <Tooltip title={text} disableHoverListener={!ellipsis} placement="top" arrow>
            <div
              style={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2, 
                width: TableHeads.maxWidth,
              }}
            >
              <span ref={child}>{text}</span>
            </div>
          </Tooltip>
        </div>
      </>
    );
  };
  const FlagEvident = (props) => {
    const { fileNames,TableIndex } = props;
    // let fileArray=fileNames.split(',');
    let fileArray=fileNames===undefined||fileNames===null||fileNames===[]||fileNames===""?[]:JSON.parse(decodeURIComponent(fileNames));
    const dispatch=useDispatch();
    return (
      <>
        <div style={{paddingTop:'12px',paddingBottom:'12px'}}>
          {fileArray.map((file, index) => (
          <div style={{paddingBottom:index===fileArray.length-1?'':'4px'}}>
              <GetEvidentLabel del={()=>{dispatch(downloadSingleFile(file,  `${extraInfo}-${TableData[TableIndex].flagName}-${index + 1}.${modifyfileName(file).split('.')[1]}`, geCode));}} fileName={modifyfileName(file)}></GetEvidentLabel>
          </div>
        
            ))}
        </div>
      
      </>
    );
  };
  return (
    <Box sx={{ width: '100%' }} data-selenium-id='SIDetail_MyTableBox'>
      <TableContainer
        id={id}
        data-selenium-id={id}
        sx={{
          borderRadius: '8px 8px 0px 0px',
          borderWidth: '1px 1px 0px 1px',
          borderStyle: 'solid',
          borderColor: '#DFE4E8',
        }}

      >
        <Table
          stickyHeader
          aria-label='custom pagination table'
          sx={{
            minWidth: 500,
            letterSpacing: '0.01em',
            color: '#262E35',
          }}
          data-selenium-id='SIDetail_MyTable'
        >
          <MyTableHeader
            items={TableHeads}
            backgroundColor='#F1F3F5'
            color='#596A7C'
            CheckboxColor='wileyBlue2'
            letterSpacing='0.08em'
            data-selenium-id='SIDetail_NyTableHeader'
          />
          <TableBody data-selenium-id='SIDetail_MyTableBody'>
            {TableData.map((row,TableIndex) => (
              <TableRow
                sx={{
                  '&:hover': {
                    background: '#E8EDFB',
                  },
                }}
                key={row['historyId']}
                data-selenium-id='SIDetail_MyTableRow'
              >
                {TableHeads.map((item, index) => {
                  if (item.isClick !== undefined && item.isClick) {
                    return (
                      <StyledTableCell
                        key={index}
                        sx={{
                          minWidth: item.minWidth,
                          maxWidth: item.maxWidth,
                          wordWrap: 'break-word',
                          color: '#154AB6',
                          ...SmallBoldFont,
                        }}
                        data-selenium-id={`SIDetail_MyTableCell-${index}`}
                        align='left'
                      >
                        <Box
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            setEmailContent(row);
                            setOpen(true);
                          }}
                          data-selenium-id={`SIDetail_MyTableCellBox-${index}`}
                        >
                          {row[item.id]}
                        </Box>
                      </StyledTableCell>
                    );
                  } else if(item.id === 'GEBasic-newValue' || item.id === 'GEBasic-oldValue' ){
                    return (
                      <StyledTableCell
                        key={index}
                        sx={{
                          minWidth: item.minWidth,
                          maxWidth: item.maxWidth,
                          wordWrap: 'break-word',
                          color: '#262E35',
                        }}
                        align='left'
                        data-selenium-id={`SIDetail_MyTableCell-${index}`}
                      >
                        <DoubleLineText text={row[item.id.split('-')[1]]}/>
                      </StyledTableCell>
                    );
                  } else if(item.id === 'Flag-newValue' || item.id === 'Flag-oldValue'){
                    return (
                      <StyledTableCell
                        key={index}
                        sx={{
                          minWidth: item.minWidth,
                          maxWidth: item.maxWidth,
                          wordWrap: 'break-word',
                          color: '#262E35',
                        }}
                        align='left'
                        data-selenium-id={`SIDetail_MyTableCell-${index}`}
                      >
                        {row['item']==='Evidence'?
                          <FlagEvident fileNames={row[item.id.split('-')[1]]} TableIndex={TableIndex}/>:
                          <DoubleLineText text={row[item.id.split('-')[1]]}/>
                        }
                      </StyledTableCell>
                    );
                  } else if(item.id === 'newValue' || item.id === 'oldValue' ){
                    return (
                      <StyledTableCell
                        key={index}
                        sx={{
                          minWidth: item.minWidth,
                          maxWidth: item.maxWidth,
                          wordWrap: 'break-word',
                          color: '#262E35',
                        }}
                        align='left'
                        data-selenium-id={`SIDetail_MyTableCell-${index}`}
                      >
                        <DoubleLineText text={row[item.id]}/>
                      </StyledTableCell>
                    );
                  }
                  else {
                    return (
                      <StyledTableCell
                        key={index}
                        sx={{
                          minWidth: item.minWidth,
                          maxWidth: item.maxWidth,
                          wordWrap: 'break-word',
                          color: '#262E35',
                        }}
                        align='left'
                        data-selenium-id={`SIDetail_MyTableCell-${index}`}
                      >
                        <Text
                          text={item.id === 'editTime' || item.id === 'time'
                            ? formatDatetimeString(row[item.id])
                            : row[item.id]}
                        />
                      </StyledTableCell>
                    );
                  }
                })}
              </TableRow>
            ))}

            {TableData.length <= 0 ? (
              <TableRow
                id='siDetailPaegNoDataText'
                data-selenium-id='SIDetail_MyTable-PageNoDataRow'
                style={{ height: '44px' }}
              >
                <TableCell
                  colSpan={7}
                  sx={{
                    textAlign: 'center',
                    fontFamily: 'Open Sans',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '0.01em',
                    color: '#98A7B6',
                  }}
                  data-selenium-id='SIDetail_MyTable-PageNoDataCell'
                >
                  No Data
                </TableCell>
              </TableRow>
            ) : (
              null
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter2
        Page={page}
        perPageNumber={rowsPerPage}
        perPageList={rowsPerPageOptions}
        count={count}
        handlePerPageChange={handleRowsPerPageChange}
        handlePageChange={handlePageChange}
        backgroundColor='#f1f3f5'
        data-selenium-id='SIDetail_MyTable-Footer'
      />
      <EmailContent
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        emailContent={emailContent}
        data-selenium-id='SIDetail_MyTable-EmailContent'
      ></EmailContent>
    </Box>
  );
}
export default MyTable;

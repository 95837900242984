import { IconButton, Link, Popover } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import VectorIcon from '../../../assets/Arrow2.svg';
import styleModule from '../SIDetailPage/GeneralInfoSection.module.scss';
import SevereIssueFlag from '@/assets/SIDetailSevereIssuseFlag.svg';
import MildIssueFlag from '@/assets/SIDetailMildIssueFlag.svg';
import Dropdown from '@/componentsphase2/Dropdown';
import * as React from 'react';

/**
 * @author SZn
 * @date 2022/5/24
 * @param {*} props 传递组件编号id和需要展示的geList列表
 * @returns 返回展示组件
 */
function GeMenuIconOld(props) {
  const { id, geList, NoHref, anchorRef } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = event => {
    if (!anchorRef) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(anchorRef);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (geList.length === 0) {
    return <></>;
  }


  return (
    <>
      <IconButton
        aria-label='more'
        id={`siPageIssueTableMenuButtonlead${id}`}
        data-selenium-id={`SIPage_SpecialIssueManagement-GeMenuIcon-SiPageIssueTableGEMenuButton-${id}`}
        aria-controls={open ? `long-menu-ge${id}` : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        disableRipple
        sx={{
          padding: '0px 0px 0px 0px',
          transform: open
            ? 'rotate(-90deg) translateX(2px)'
            : 'rotate(0deg) translateY(-2px)',
          transition: 'all 0.3s',
          height: '20px',
          top: '2px',
        }}
      >
        <VectorIcon data-selenium-id='SIPage_SpecialIssueManagement-GeMenuIcon-VectorIcon' />
      </IconButton>

      <Popover
        data-selenium-id='SIPage_SpecialIssueManagement-GeMenuIcon-Popover'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            borderRadius: '5px', //modify
            borderLeft: 'solid', //modify
            borderLeftWidth: '8px',
            borderLeftColor: '#154AB6', //modify
            marginTop: 6,
            boxShadow: 'none',
            filter: 'drop-shadow(0px 6px 12px rgba(38, 46, 53, 0.12))',
            '&>div>div': {
              boxShadow: 'none',
            },
          },
        }}
        className={styleModule.popover}
      >
        <Box
          sx={{
            maxHeight: 48 * 6 + 12,
            overflowY: 'auto',
            overflowX: 'hidden',
            paddingBottom: '12px',
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-moz-scrollbar-track': {
              backgroundColor: 'transparent',
            },
          }}
        >
          {geList.map(
            (ge, index) =>
              ge !== null && (
                <Box
                  id={`siPageIssueTableGE${id}-${index}`}
                  data-selenium-id={`SIPage_SpecialIssueManagement-SiPageIssueTableGE-${id}-${index}`}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    paddingTop: '12px',
                    width: '205px',
                    background: '#FFFFFF',
                  }}
                  key={index}
                >
                  <Box
                    id={`siPageIssueTableGEName${id}-${index}`}
                    data-selenium-id={`SIPage_SpecialIssueManagement-SiPageIssueTableGEName-${id}-${index}`}
                    sx={{
                      width: '100%',
                      fontFamily: 'Open Sans',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#154AB6',
                      display: 'flex',
                      // flexDirection: 'column',
                      alignItems: 'center',
                      paddingLeft: '12px',
                      height: '20px',
                      overflowX: 'hidden',
                      '& :hover': {
                        color: '#113D95',
                      },
                    }}
                  >
                    {NoHref ? (
                      ge.fullName
                    ) : (
                      <Link
                        data-selenium-id={`SIPage_SpecialIssueManagement-GeMenuIcon-Popover-Box2-Box2-Box1-Link-${index}`}
                        key={'ge-email-' + id}
                        href={`/simt/auth/siRelated/ge/detail/${ge.email}`}
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          textDecoration: 'none',
                          maxWidth: '100px',
                          color:
                            ge.alertGeFlagType === 'Flags-Severe Issue'
                              ? '#EE5350'
                              : ge.alertGeFlagType === 'Flags-Mild Issue'
                                ? '#FF8A00'
                                : '#154AB6',
                          '&:hover': {
                            color: '#00FF00'
                          },
                        }}
                      >
                        {ge.fullName}
                      </Link>
                    )}
                    {ge.alertGeFlagType === 'Flags-Severe Issue' ? (
                      <Box
                        ml='4px'
                        width='78px'
                        height='20px'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                      >
                        <SevereIssueFlag />
                      </Box>
                    ) : ge.alertGeFlagType === 'Flags-Mild Issue' ? (
                      <Box
                        ml='4px'
                        width='63px'
                        height='20px'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                      >
                        <MildIssueFlag />
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>

                  <Box
                    id={`siPageIssueTableGEEmail${id}-${index}`}
                    data-selenium-id={`SIPage_SpecialIssueManagement-SiPageIssueTableGEEmail-${id}-${index}`}
                    sx={{
                      fontFamily: 'Open Sans',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '12px',
                      lineHeight: '16px',
                      color: '#98A7B6',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      paddingLeft: '12px',
                      gap: '10px',
                      height: '16px',
                      width: '205px',
                    }}
                  >
                    {ge.email}
                  </Box>
                </Box>
              )
          )}
        </Box>
      </Popover>
    </>
  );
}

function GeMenuIconBase(props) {
  const { id, blueIsLeft = true, geList, NoHref,NoOverXHide, anchorRef, ...others } = props;

  function getHeight(){
    return (geList?.length ?? 0) * 48 + 12;
  }
  return (
    <Dropdown
      id={id}
      blueIsLeft={blueIsLeft}
      anchorRef={anchorRef}
      popoverSx={{
        // transform: blueIsLeft || anchorRef ? 'unset' : 'translateX(-205px)',
        // ...(!blueIsLeft?{'& .MuiPaper-root':{right:'205px !important',left:'unset !important'}}:{}),
        zIndex:1200,
      }}
      outerId='outer'
      height={getHeight()}
      {...others}
    >
      {geList.map(
        (ge, index) =>
          ge !== null && (
            <Box
              id={`siPageIssueTableGE${id}-${index}`}
              data-selenium-id={`SIPage_SpecialIssueManagement-SiPageIssueTableGE-${id}-${index}`}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingTop: index===0 ? '0px' : '12px',
                width: '205px',
                background: '#FFFFFF',
              }}
              key={index}
            >
              <Box
                id={`siPageIssueTableGEName${id}-${index}`}
                data-selenium-id={`SIPage_SpecialIssueManagement-SiPageIssueTableGEName-${id}-${index}`}
                sx={{
                  width: '100%',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#154AB6',
                  display: 'flex',
                  // flexDirection: 'column',
                  alignItems: 'center',
                  paddingLeft: '12px',
                  height: NoOverXHide?'auto':'20px',
                  overflowX: NoOverXHide?null:'hidden',
                }}
              >
                {NoHref ? (
                  ge.fullName
                ) : (
                  <Link
                    data-selenium-id={`SIPage_SpecialIssueManagement-GeMenuIcon-Popover-Box2-Box2-Box1-Link-${index}`}
                    key={'ge-email-' + id}
                    href={`/simt/auth/siRelated/ge/detail/${ge.geCode?ge.geCode:ge.email}`}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      textDecoration: 'none',
                      // maxWidth: '100px',
                      fontWeight: 600,
                      color:
                        ge.alertGeFlagType === 'Flags-Severe Issue'
                          ? '#EE5350'
                          : ge.alertGeFlagType === 'Flags-Mild Issue'
                            ? '#FF8A00'
                            : '#0052CC',
                      '&:hover': {
                        fontWeight: 700,
                        color: ge.alertGeFlagType === 'Flags-Severe Issue'
                          ? '#C40000'
                          : ge.alertGeFlagType === 'Flags-Mild Issue'
                            ? '#D97706'
                            : '#113D95',
                      }
                    }}
                  >
                    {ge.fullName}
                  </Link>
                )}
                {ge.alertGeFlagType === 'Flags-Severe Issue' ? (
                  <Box
                    ml='4px'
                    width='78px'
                    height='20px'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <SevereIssueFlag />
                  </Box>
                ) : ge.alertGeFlagType === 'Flags-Mild Issue' ? (
                  <Box
                    ml='4px'
                    width='63px'
                    height='20px'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <MildIssueFlag />
                  </Box>
                ) : (
                  <></>
                )}
              </Box>

              <Box
                id={`siPageIssueTableGEEmail${id}-${index}`}
                data-selenium-id={`SIPage_SpecialIssueManagement-SiPageIssueTableGEEmail-${id}-${index}`}
                sx={{
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '12px',
                  lineHeight: '16px',
                  color: '#98A7B6',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  paddingLeft: '12px',
                  gap: '10px',
                  height: '16px',
                  width: '205px',
                }}
              >
                {ge.email}
              </Box>
            </Box>
          )
      )}
    </Dropdown>
  );
}

const GeMenuIcon = GeMenuIconBase;

export default GeMenuIcon;

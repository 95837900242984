import {axios_instance, axios_instance_fileDownload} from "@/utils/axios_instance";
import {
    ADD_FLAG,
    clearGeCache,
    DELETE_FLAG,
    DOWNLOAD_ALLFILE,
    DOWNLOAD_SINGLEFILE,
    downloadFileOfNewStyle,
    downloadFileOfZip,
    EDIT_FLAG,
    GET_FLAG,
    GET_FLAG_HISTORY,
    GET_MERGE_FLAG_ONE,
    GET_MERGE_FLAG_TWO,
    UP_EVIDENTFILE
} from "@/actions/SIMT-SI/GuestEditor/GEAction";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {fetchUnreadAlertsCount} from "@/actions/SIMT-User/Alert";
import Config from "@/configs/Config";

/**
 * @param {string} geId
 */
export function getFlagByGECode(geId, isMergeMode = false, isFirstOne = true) {
    const actionType = isMergeMode
        ? isFirstOne
            ? GET_MERGE_FLAG_ONE
            : GET_MERGE_FLAG_TWO
        : GET_FLAG;

    /**
     * @param {import("redux").Dispatch} dispatch
     */
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/guest-editors/${geId}/flags`;
            const res = await axios_instance.get(url, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: actionType, data: res.data.data});
            } else {
                dispatch({type: actionType, data: {result: [], count: 0}});
            }
        } catch (e) {
            dispatch({type: actionType, data: 'error'});
        }
    };
}

export function editFlag(editData) {

    return async dispatch => {
        const url =  `/api/v1/si-service/guest-editors/${editData[0].geCode}/flags`;
        const res = await axios_instance.put(url, editData, {
            headers: {
                authorization: localStorage.getItem('authorization'),
            },
        });
        if (res.data.code === 200) {
            dispatch({type: EDIT_FLAG, data: ''});
            clearGeCache();
        } else {
            dispatch(
                setSnackbarMessageAndOpen(
                    'gePage.updateError',
                    {
                        value: res.data.message,
                    },
                    SEVERITIES.error
                )
            );
            dispatch({type: EDIT_FLAG, data: ''});
        }
    };
}

export function addFlag(geId, geFlagId, flagInfo, comment, fileName) {
    return async dispatch => {
        const url = `/api/v1/si-service/guest-editors/${geId}/flags`;
        const res = await axios_instance.post(
            url,
            {
 
                geCode: geId,
                geFlagId: geFlagId,
                flagInfo: flagInfo,
                comment: comment,
                files: fileName,
            },
            {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            }
        );
        if (res.data.code === 200) {
            dispatch({type: ADD_FLAG, data: ''});
            dispatch(fetchUnreadAlertsCount());
            clearGeCache();
        } else {
            dispatch(
                setSnackbarMessageAndOpen(
                    'gePage.updateError',
                    {
                        value: res.data.message,
                    },
                    SEVERITIES.error
                )
            );
            dispatch({type: ADD_FLAG, data: ''});
        }
    };
}

export function uploadFile(geId,file, cancelToken, updateFunc, finishFunc) {
    return async dispatch => {
        const url = `/api/v1/si-service/guest-editors/${geId}/flags/files/upload`;
        let param = new FormData();
        param.append('file', file);
        const config = {
            headers: {
                authorization: localStorage.getItem('authorization'),
            },
            onUploadProgress: e => {
                if (e.lengthComputable) {
                    let percentLoaded = Math.round((e.loaded / e.total) * 100);
                    // 更新进度条长度
                    if (percentLoaded <= 100) {
                        updateFunc(percentLoaded);
                    }
                }
            },
            cancelToken: cancelToken.token,
            timeout: 100000,
        };
        const res = await axios_instance.post(url, param, config);

        if (res.data.code === 200) {
            dispatch({type: UP_EVIDENTFILE, data: res.data.data});
            finishFunc(res.data.data);
        } else {
            dispatch(
                setSnackbarMessageAndOpen(
                    'gePage.updateError',
                    {
                        value: res.data.message,
                    },
                    SEVERITIES.error
                )
            );
            dispatch({type: UP_EVIDENTFILE, data: ''});
        }
    };
}

export function deleteFlag(geId, geFlagId, flagInfo,comment,fileName) {
    return async dispatch => {
        let url =  `/api/v1/si-service/guest-editors/${geId}/flags`;
        const res = await axios_instance.delete(
            url,
            {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
                data: {
                    geCode: geId,
                    geFlagId: geFlagId,
                    flagInfo: flagInfo,
                    comment: comment,
                    files: fileName,
                  },
            }
        );
        if (res.data.code === 200) {
            dispatch({type: DELETE_FLAG, data: ''});
            // dispatch(setSnackbarMessageAndOpen("user.success", {}, SEVERITIES.success));
        } else {
            dispatch(
                setSnackbarMessageAndOpen(
                    'gePage.updateError',
                    {
                        value: res.data.message,
                    },
                    SEVERITIES.error
                )
            );
            dispatch({type: DELETE_FLAG, data: ''});
        }
    };
}

export function downloadAllFile(geId, flagId) {
    return async dispatch => {
        const requestURL = `/api/v1/si-service/guest-editors/${geId}/flags/files/download?flagId=${flagId}`;
        try {
            let res = await fetch(
                `${Config.API_URL}/api/v1/si-service/guest-editors/${geId}/flags/files/download?flagId=${flagId}&filename=`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            let data = await res.blob();
            // let res = await axios_instance_fileDownload.post(requestURL, {
            //   responseType: 'blob',
            //   // params: {
            //   //   geCode: geId,
            //   //   flagId:flagId,
            //   // },
            // },
            // {
            //   headers: {
            //     'Content-Type': 'application/x-www-form-urlencoded',
            //     authorization: localStorage.getItem('authorization'),
            //   },
            // });

            let x = res.headers
                .get('Content-Disposition')
                .split('=')[1]
                .split('-')[0];
            let geName;
            if (x.includes('+'))
                geName =
                    decodeURIComponent(x).split('+')[0] +
                    ' ' +
                    decodeURIComponent(x).split('+')[1];
            else geName = x;
            geName = geName.substring(1, geName.length);
            let y = res.headers.get('Content-Disposition').split('=')[1].split('-');
            let zipName = '';
            for (let i = 1; i < y.length; i++) zipName = zipName + '-' + y[i];
            zipName = zipName.substring(1, zipName.length - 1);
            // console.log("geName",geName);
            // console.log("zipName",zipName,y);
            downloadFileOfZip(data, geName + '-' + zipName);
            dispatch({type: DOWNLOAD_ALLFILE, data: true});
        } catch (err) {
            dispatch({type: DOWNLOAD_ALLFILE, data: false});
        }
    };
}

export function downloadSingleFile(filename, downloadName, geCode = 'GE-0000-000000') {
    downloadName = downloadName || filename;
    return async dispatch => {
        const requestURL = `/api/v1/si-service/guest-editors/${geCode}/flags/files/download`;
        try {
            let res = await axios_instance_fileDownload.post(requestURL, {}, 
                {
                responseType: 'blob',
                params: {
                    filename: filename,
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    authorization: localStorage.getItem('authorization'),
                },
            });
            console.log('downloadSignleFile success', res);
            downloadFileOfNewStyle(res.data, downloadName);
            dispatch({type: DOWNLOAD_SINGLEFILE, data: true});
        } catch (err) {
            dispatch({type: DOWNLOAD_SINGLEFILE, data: false});
        }
    };
}

export function get_FlagHistory(geCode, pageNum, pageSize) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/guest-editors/${geCode}/flags/editing-history`;
            const res = await axios_instance.get(url, {
                params: {
                    geCode:geCode,
                    pageNum: pageNum,
                    pageSize: pageSize,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: GET_FLAG_HISTORY, data: res.data.data});
            } else {
                dispatch({type: GET_FLAG_HISTORY, data: 'error'});
            }
        } catch {
            dispatch({type: GET_FLAG_HISTORY, data: 'error'});
        }
    };
}
import {useState, useEffect, useMemo, forwardRef, ChangeEvent, MutableRefObject} from 'react';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Stack,
} from '@mui/material';

import OnlyTextXIcon from '../assets/OnlyTextX.svg';
import TextFieldErrorIcon from '../assets/TextFieldErrorIcon.svg';
import TextFieldWarningIcon from '../assets/TextFieldWarningIcon.svg';
import { use } from 'echarts';
import * as React from "react";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export interface MyOnlyTextProps{
    value: string,
    setValue: (v: string) => void;
    width?: string;
    needEndAdornment?: boolean; //是否需要行末的叉
    alwaysHaveEndAdornment?: boolean; //在needEndAdornment为true的情况下，是否忽略非空判断而一直有行末的叉
    handleIconButtonClick?: () => void; //点击叉的onClick，需要叉则必选
    //可选props
    height?: string;
    autoFocus? : any; //TextFiled的API
    isError?: boolean; //是否为错误状态(会有红色边框, 默认为false), 配合errorMessage使用
    errorMessage?: string; //isError为true时的提示信息
    isWarning?: boolean; //是否为错误状态(会有黄色边框, 默认为false), 配合warningMessage使用
    warningMessage?: string; //isWarning为true时的提示信息
    //minRows用来设置初始时的行数, maxRows会在文本超过行数时出现滚动条
    //如果有minRows, 一定要设置maxRows且maxRows>=minRows
    maxRows?: number; //最大行数，默认为1
    minRows?: number; //最小行数，默认为1
    placeholder?: string;
    fontSize?: number;
    onFocus?: () => void;
    onBlur?: () => void;
    textFieldPadding?: string;
    textFieldPaddingWithoutEndAdornment?: string;
    fontColor?: string;
    lineHeight?: number;
    multiline?: boolean;
    TextFieldInputProps?: any;
    type?: React.InputHTMLAttributes<unknown>['type'];
    //用于对是否有滚动条进行判定
    hasScrollbar?: boolean,
    setHasScrollbar?: (v: boolean) => void;

    sx?: SxProps<Theme>;

    realTimeErrorShow?: boolean;
    needFormatCheck?: boolean;
    pattern?: string;
    setFormatError?: (v: boolean) => void;

    alwaysEndCursor?: boolean; // if true, the cursor will always be at the end of the text

    [key: string] : any;
}

const MyOnlyText = forwardRef((props: MyOnlyTextProps , ref) => {
      const {
        //必选props
        value, //value的state
        setValue, //value的set方法
        width = '100%',
        needEndAdornment, //是否需要行末的叉
        alwaysHaveEndAdornment = false, //在needEndAdornment为true的情况下，是否忽略非空判断而一直有行末的叉
        handleIconButtonClick = () => {}, //点击叉的onClick，需要叉则必选
        //可选props
        height = '100%',
        autoFocus, //TextFiled的API
        isError = false, //是否为错误状态(会有红色边框, 默认为false), 配合errorMessage使用
        errorMessage, //isError为true时的提示信息
        isWarning = false, //是否为错误状态(会有黄色边框, 默认为false), 配合warningMessage使用
        warningMessage = 'Warning.', //isWarning为true时的提示信息
        //minRows用来设置初始时的行数, maxRows会在文本超过行数时出现滚动条
        //如果有minRows, 一定要设置maxRows且maxRows>=minRows
        maxRows, //最大行数，默认为1
        minRows, //最小行数，默认为1
        placeholder,
        fontSize = 14,
        onFocus,
        onBlur,
        textFieldPadding,
        textFieldPaddingWithoutEndAdornment,
        fontColor = '#262E35',
        lineHeight = 17.5,
        multiline = true,
        TextFieldInputProps,
        type,
        //用于对是否有滚动条进行判定
        hasScrollbar,
        setHasScrollbar = () => {},

        sx,

        realTimeErrorShow = false,
        needFormatCheck = false,
        pattern = ".*",
        setFormatError= () => {},
        alwaysEndCursor = false,

        ...other
      } = props;

      const [hover, setHover] = useState(false);
      const [focus, setFocus] = useState(false);

      const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setValue(e.target.value);
      };

      const isEmpty = (value?: any) : boolean => {
        // @ts-ignore
          let flag =
            value === undefined ||
            value === null ||
            value === '' ||
            (typeof value === 'string' && !value.trim().length);
        return flag;
      };

      const textFieldPaddingToUse = textFieldPadding
          ? textFieldPadding
          : '8px 12px';
      const textFieldPaddingWithoutEndAdornmentToUse =
          textFieldPaddingWithoutEndAdornment
              ? textFieldPaddingWithoutEndAdornment
              : '8px 4px 8px 12px';

      //在文本变化时对高度进行判断
      const checkScrollbar = () => {
        if ((ref as MutableRefObject<any>)?.current ?? false) {
          const hasVerticalScrollbar =
              ((ref as MutableRefObject<any>)?.current?.scrollHeight ?? 0) > ((ref as MutableRefObject<any>)?.current?.clientHeight ?? 0);
          setHasScrollbar(hasVerticalScrollbar);
        }
      };

      useEffect(() => {
        if (alwaysEndCursor && (ref as MutableRefObject<any>)?.current) {
            const input = (ref as MutableRefObject<any>)?.current;
            const length = input.value.length;
            input.setSelectionRange(length, length);
        }
      }, [focus]);

      const regExp = useMemo(() => {
        return new RegExp(pattern);
      }, [pattern]);

      useEffect(() => {
        if (ref !== null) {
          checkScrollbar();
          if (needFormatCheck) {
            const passTest = regExp.test(value);
            setFormatError(!passTest);
          }
        }
      }, [value, ref]);




      return (
          <Stack
              direction='column'
              alignItems='flex-start'
              justifyContent='center'
              spacing='2px'
          >
            <FormControl
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onFocus={() => {
                  if (onFocus) {
                    onFocus();
                  }
                  setFocus(true);
                }}
                onBlur={() => {
                  if (onBlur) {
                    onBlur();
                  }
                  setFocus(false);
                }}
                variant='standard'
                sx={{
                  width: width,
                }}
            >
              <TextField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      background: '#FFFFFF',
                      borderRadius: '4px',
                      boxSizing: 'border-box',
                      width: '100%',
                      height: height,
                      fontFamily: 'Open Sans',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: `${fontSize}px`, //`14px
                      lineHeight: `${lineHeight}px`, //`17.5px
                      padding: needEndAdornment
                          ? textFieldPaddingToUse
                          : textFieldPaddingWithoutEndAdornmentToUse,
                      color: fontColor,
                    },
                    '& .MuiOutlinedInput-input': {
                      padding: '0px 0px',
                      '&::placeholder': {
                        fontFamily: 'Open Sans',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: `${fontSize}px`,
                        lineHeight: '17.5px',
                        color: '#BCC5CF',
                        opacity: '1',
                      },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: isError
                          ? '2px solid #EE5350'
                          : isWarning
                              ? '2px solid #FFB152 !important'
                              : isEmpty(value)
                                  ? '2px solid #DFE4E8'
                                  : '1px solid #98A7B6',
                    },
                  ... !props?.disabled && {
                      '& :hover': {
                          '& .MuiOutlinedInput-notchedOutline': {
                              border: isError
                                  ? '2px solid #EE5350'
                                  : isWarning
                                      ? '2px solid #FFB152 '
                                      : '1px solid #262E35 !important',
                          },
                      },
                  },
                    '& .Mui-focused': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: isError
                            ? '2px solid #EE5350'
                            :isEmpty(value)
                                ? '2px solid #154AB6 !important'
                                : '2px solid #0052CC !important',
                      },
                    },
                    '& .ForwardRef(TextField)-root-13 .MuiOutlinedInput-input::placeholder':
                        {
                          color: 'blue',
                        },
                    ...sx,
                  }}
                  // 避免Ge2的notes从未输入为null时点击undo按钮无效
                  value={value==null?'':value}
                  multiline={multiline}
                  maxRows={maxRows ?? 1}
                  minRows={minRows ?? 1}
                  placeholder={
                    placeholder !== undefined && placeholder !== null ? placeholder : ''
                  }
                  onChange={handleChange}
                  InputProps={
                    TextFieldInputProps
                        ? TextFieldInputProps
                        : {
                          endAdornment:
                              needEndAdornment &&
                              (!isEmpty(value) || alwaysHaveEndAdornment) &&
                              (hover || focus) ? (
                                  <InputAdornment
                                      position='end'
                                      data-selenium-id='MyOnlyText-InputAdornment'
                                      onClick={() => {
                                        handleIconButtonClick();
                                        setFocus(false);
                                      }}
                                      sx={{
                                        cursor: 'pointer',
                                      }}
                                  >
                                    <IconButton
                                        disableRipple
                                        sx={{
                                          padding: '0px',
                                          '&:hover': {
                                            'svg path': {
                                              fill: '#262E35',
                                            },
                                          },
                                        }}
                                    >
                                      <OnlyTextXIcon />
                                    </IconButton>
                                  </InputAdornment>
                              ) : (
                                  <></>
                              ),
                        }
                  }
                  autoFocus={autoFocus ?? false}
                  type={type}
                  inputRef={ref}
                  {...other}
              />
            </FormControl>
            {isError && errorMessage && (!focus || realTimeErrorShow) ? (
                <Stack
                    direction='row'
                    spacing='2px'
                    alignItems='flex-Start'
                    justifyContent='flex-start'
                    sx={{
                      maxWidth: { width },
                    }}
                >
                  <Stack
                      alignItems='center'
                      justifyContent='center'
                      sx={{
                        marginTop: '2px',
                      }}
                  >
                    <TextFieldErrorIcon />
                  </Stack>
                  <Box
                      sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        color: '#EE5350',
                        letterSpacing: '-0.084px',
                      }}
                  >
                    {errorMessage}
                  </Box>
                </Stack>
            ) : null}
            {isWarning && (!focus || realTimeErrorShow) && warningMessage ? (
                <Stack
                    direction='row'
                    spacing='2px'
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    sx={{
                      maxWidth: { width },
                    }}
                >
                  <Stack
                      alignItems='center'
                      justifyContent='center'
                      sx={{
                        marginTop: '2px',
                      }}
                  >
                    <TextFieldWarningIcon />
                  </Stack>
                  <Box
                      sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        color: '#FFB152',
                      }}
                  >
                    {warningMessage}
                  </Box>
                </Stack>
            ) : null}
          </Stack>
      );
    }
);

export default MyOnlyText;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CommonDialog } from '@/modules/AlertDialog';
import { SIMTButton } from '@/components/StyledButton/SIMTButton';
import { openConfirm, setTextParam, setEditType } from '@/actions/SIMT-User/Alert/AlertAction';
import { SIModal } from '@/modules/Modal';
import SelectDownIcon from '@/assets/SelectDownIcon.svg';
import CheckBoxIcon from '@/assets/CheckBoxIcon.svg';
import CheckBoxIconClick from '@/assets/CheckBoxIconClick.svg';

import {
  FormControl,
  MenuItem,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  tableCellClasses,
  TableRow,
  styled,
  Tooltip,
  Box,
  Typography,
  Stack,
  TableHead,
  Collapse,
} from '@mui/material';

import {
  addUserSelected,
  removeUserSelected,
  removeUserSelectedInfo,
  cancelSelectAllUser,
} from '@/actions/SeleteAction';

import { updateClear } from '@/actions/SIMT-User/User/UserAction';
import { CustomSelect } from './CustomComponents/CustomSelect';
import {getRoleInfo} from "@/actions/SIMT-User/Role";
import {batchUpdateUser} from "@/actions/SIMT-User/User/SystemAdmin";
import {loadGroups} from "@/actions/SIMT-User/Config";

function BatchContrain(props) {
  const [roleData, setRoleData1] = useState({
    result: [
      {
        roleId: 1,
        roleName: 'Tier1',
        updateTime: 'Janurary 7,2022 07:51:20',
        updateBy: 'Alice',
      },
      {
        roleId: 2,
        roleName: 'Tier3',
        updateTime: 'Janurary 8,2022 07:51:20',
        updateBy: 'Ben',
      },
    ],
    count: 2,
  });

  const [groups, setGroups] = useState([]);

  const [repeatError, setRepeatError] = useState(false);
  const [notSelectError, setNotSelectError] = useState(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    boxSizing: 'border-box',

    [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      color: '#596A7C',
      minWidth: '49px',
      padding: 0,
      textTransform: 'uppercase',
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '16px',
      lineHeight: '16px',
      color: '#484848',
      padding: 0,
      paddingLeft: '24px',
      paddingRight: '24px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&.table-cell__checkbox': {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  }));

  const {
    open,
    handleClose,
    type,
    role,
    setRole,
    group,
    setGroup,
    currentSelected,
    setCurrentSelected,
    handleConfirmOpen,
  } = props;

  const dispatch = useDispatch();

  const selector = state => {
    return {
      selected: state.Selete.selectedUserList,
      selectedInfo: state.Selete.selectedUserListInfo,
      allRoles: state.Role.roleInfo,
      allGroup: state.SystemConfig.groupList,
    };
  };
  const { selected, selectedInfo, allRoles, allGroup } = useSelector(selector);

  useEffect(() => {
    setGroups(allGroup);
  }, [allGroup]);

  useEffect(() => {
    var Roles = {};
    if (allRoles.result) {
      Roles.result = allRoles.result.filter((role, index) => {
        return role.roleName !== 'Admin' && role.roleName !== 'SystemAdmin';
      });
      setRoleData1(Roles);
    }
  }, [allRoles]);

  useEffect(() => {
    setRole('');
    setGroup('');
    setRepeatError(false);
    if (open) {
      setCurrentSelected(selected);
      setNotSelectError(checkNotSelectError());
    } else {
      setCurrentSelected([]);
    }
  }, [open]);

  useEffect(() => {
    setRepeatError(checkRepeatError());
    if (type === 'group') {
      selected.map(id => {
        if (
          selectedInfo[id] != undefined &&
          group != '' &&
          selectedInfo[id].group === group
        ) {
          dispatch(removeUserSelected(id));
        }
      });
    } else if (type === 'role') {
      if (type === 'role') {
        selected.map(id => {
          if (
            selectedInfo[id] !== undefined &&
            role !== '' &&
            selectedInfo[id].role === role
          ) {
            dispatch(removeUserSelected(id));
          }
        });
      }
    }
    setNotSelectError(checkNotSelectError());
  }, [group, role]);

  useEffect(() => {
    setNotSelectError(checkNotSelectError());
  }, [selected]);

  const checkNotSelectError = () => {
    // return true when error occurs
    return (
      (type === 'role' && role === '') ||
      (type === 'group' && group === '') ||
      selected.length == 0
    );
  };

  const checkRepeatError = () => {
    // return true when error occurs
    const repeatList = selected.filter(id => {
      if (type === 'role') {
        return (
          selectedInfo[id] !== undefined &&
          role !== '' &&
          selectedInfo[id].role === role
        );
      } else if (type === 'group') {
        return (
          selectedInfo[id] != undefined &&
          group != '' &&
          selectedInfo[id].group === group
        );
      }
      return false;
    });
    return repeatList.length > 0;
  };

  const handleRoleChange = event => {
    setRole(event.target.value);
  };

  const handleGroupChange = event => {
    setGroup(event.target.value);
  };

  const isSelected = (name, index) => {
    return selected.indexOf(name) !== -1;
  };

  //处理单击复选框事件
  const handleCheck = async UserInfo => {
    const selectedIndex = selected.indexOf(UserInfo.userId);
    if (selectedIndex > -1) {
      await dispatch(removeUserSelected(UserInfo.userId));
    } else {
      await dispatch(addUserSelected(UserInfo));
    }
  };

  const handleCheckAll = e => {
    if (selected.length > 0) {
      currentSelected.forEach((id, index) => {
        const userInfo = selectedInfo[id];

        dispatch(removeUserSelected(userInfo.userId));
      });
    } else {
      currentSelected.forEach((id, index) => {
        const userInfo = selectedInfo[id];
        if (
          (type === 'group' && userInfo.group === group) ||
          (type === 'role' && userInfo.role === role)
        )
          return;
        dispatch(addUserSelected(userInfo));
      });
    }
  };

  const handleRoleFocus = () => {
    dispatch(getRoleInfo(0, 100000, 'roleName', 'asc'));
  };

  const handleGroupFocus = () => {
    dispatch(loadGroups());
  };

  return (
    <Stack
      marginTop={'20px'}
      data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack'
    >
      <Stack
        direction={'row'}
        spacing='8px'
        alignItems={'center'}
        data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Stack'
      >
        <Typography
          data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Stack-Typography'
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            color: '#596A7C',
          }}
        >
          {type === 'role' ? 'New Role' : 'New Group/Title'}
        </Typography>
        <Box
          data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Stack-Box'
          sx={{
            flexGrow: 1,
          }}
        >
          {type === 'role' ? (
            <Box data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Stack-Box1'>
              <FormControl
                fullWidth
                data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Stack-Box1-FormControl'
              >
                <CustomSelect
                  id='systemAdminUserManagementBatchUpdateRoleSelect'
                  data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Stack-Box1-FormControl-CustomSelect-RoleSelect'
                  value={role}
                  onChange={handleRoleChange}
                  onFocus={handleRoleFocus}
                  IconComponent={SelectDownIcon}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '300px',
                      },
                    },
                  }}
                >
                  {roleData.result.map((role, index) => (
                    <MenuItem
                      id={`systemAdminUserManagementBatchUpdateRoleItem${index}`}
                      data-selenium-id={`SystemAdmin-UserManagementBatchUpdateRoleItem-${index}`}
                      key={role.roleId}
                      value={role.roleName}
                    >
                      {role.roleName}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </Box>
          ) : (
            <Box data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Stack-Box2'>
              <FormControl
                fullWidth
                data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Stack-Box2-FormControl'
              >
                <CustomSelect
                  id='systemAdminUserManagementBatchUpdateGroupSelect'
                  data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Stack-Box2-FormControl-CustomSelect-GroupSelect'
                  value={group}
                  onChange={handleGroupChange}
                  onFocus={handleGroupFocus}
                  IconComponent={SelectDownIcon}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '300px',
                        maxWidth: '320px',
                      },
                    },
                  }}
                >
                  {groups.map((group, index) => (
                    <MenuItem
                      id={`systemAdminUserManagementBatchUpdateGroupItem${index}`}
                      data-selenium-id={`SystemAdmin-UserManagementBatchUpdateGroupItem-${index}`}
                      key={index}
                      value={group.value}
                    >
                      <div
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {group.value}
                      </div>
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </Box>
          )}
        </Box>
        <SIMTButton
          data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Stack-SIMTButton'
          sx={{
            fontSize: '14px',
            lineHeight: '20px',
            padding: '6px 18px',
            fontWeight: 400,
            '&.MuiButtonBase-root': {
              marginLeft: '16px',
            },
          }}
          variant='contained'
          onClick={handleConfirmOpen}
          disabled={notSelectError}
        >
          Update
        </SIMTButton>
      </Stack>
      <Collapse
        in={repeatError}
        data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Collapse'
      >
        <Typography
          id='systemAdminUserManagementBatchUpdateHintText'
          data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Collapse-Typography-HintText'
          marginTop={'12px'}
          sx={{ color: '#A94442', fontWeight: 400, fontSize: '12px' }}
        >
          {`Below highlighted items have the same ${
            type === 'role' ? 'User Role' : 'User Group'
          } with your selection, so no update will be performed on these items`}
        </Typography>
      </Collapse>
      <Box
        marginTop={'12px'}
        data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Box'
      >
        <TableContainer
          data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Box-TableContainer'
          sx={{
            height: '218px',
            maxHeight: 'calc(60vh - 48px)',
            border: '1px solid #DFE4E8',
            borderRadius: '8px',
            '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root': {
              backgroundColor: '#f1f3f5',
            },
            '& .MuiTableRow-root': {
              height: '41px',
            },
          }}
        >
          <Table
            stickyHeader
            aria-label='user-batch-update-table'
            data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Box-TableContainer-Table'
          >
            <TableHead data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Box-TableContainer-Table-TableHead'>
              <TableRow data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Box-TableContainer-Table-TableHead-TableRow'>
                <StyledTableCell
                  data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Box-TableContainer-Table-TableHead-TableRow-StyledTableCell1'
                  className='table-cell__checkbox'
                  sx={{ width: '66px' }}
                >
                  <Checkbox
                    data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Box-TableContainer-Table-TableHead-TableRow-StyledTableCell1-Checkbox'
                    checked={selected.length == currentSelected.length}
                    indeterminate={
                      selected.length > 0 &&
                      selected.length < currentSelected.length
                    }
                    onClick={handleCheckAll}
                    icon={<CheckBoxIcon />}
                    checkedIcon={<CheckBoxIconClick />}
                  />
                </StyledTableCell>
                <StyledTableCell data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Box-TableContainer-Table-TableHead-TableRow-StyledTableCell2'>
                  User
                </StyledTableCell>
                <StyledTableCell data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Box-TableContainer-Table-TableHead-TableRow-StyledTableCell3'>
                  Email
                </StyledTableCell>
                <StyledTableCell data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Box-TableContainer-Table-TableHead-TableRow-StyledTableCell4'>
                  {type === 'group' ? 'Group/Title' : 'Role'}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Box-TableContainer-Table-TableBody'>
              {currentSelected.map((id, index) => {
                const row = selectedInfo[id];
                if (row === undefined) {
                  return <div></div>;
                }
                const isItemSelected = isSelected(row.userId);
                const itemRepeatError =
                  (type === 'group' && row.group === group) ||
                  (type === 'role' && row.role === role);
                return (
                  <TableRow
                    data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Box-TableContainer-Table-TableBody-TableRow'
                    key={row.userId}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      backgroundColor: itemRepeatError ? '#ffdddc' : '#ffffff',
                      '& .MuiTableCell-root .MuiBox-root': {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      },
                    }}
                  >
                    <StyledTableCell
                      data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Box-TableContainer-Table-TableBody-TableRow-StyledTableCell1'
                      className='table-cell__checkbox'
                      align='left'
                      sx={{ minWidth: '66px', maxWidth: '66px' }}
                    >
                      <Checkbox
                        id={`systemAdminUserManagementBatchUpdateCheckBox${index}`}
                        data-selenium-id={`SystemAdmin-UserManagementBatchUpdateCheckBox-${index}`}
                        color='primary'
                        checked={isItemSelected}
                        disabled={
                          type === 'role'
                            ? row.role === role
                            : row.group === group
                        }
                        onClick={handleCheck.bind(this, row)}
                        icon={<CheckBoxIcon />}
                        checkedIcon={<CheckBoxIconClick />}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Box-TableContainer-Table-TableBody-TableRow-StyledTableCell2'
                      sx={{ minWidth: '150px', maxWidth: '300px' }}
                    >
                      {row.fullName}
                    </StyledTableCell>
                    <StyledTableCell
                      data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Box-TableContainer-Table-TableBody-TableRow-StyledTableCell3'
                      sx={{ minWidth: '186px', maxWidth: '300px' }}
                    >
                      {row.email.length >= 30 ? ( // 超长的显示弹框---没有要求这个，但是如果不加体验会很差
                        <Tooltip title={row.email} disableInteractive>
                          <Box
                            id={`systemAdminUserManagementBatchUpdateRole${index}`}
                            data-selenium-id={`SystemAdmin-UserManagementBatchUpdateRole-${index}`}
                            sx={{ lineHeight: '16px' }}
                          >
                            {row.email}
                          </Box>
                        </Tooltip>
                      ) : (
                        row.email
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-Stack-Box-TableContainer-Table-TableBody-TableRow-StyledTableCell4'
                      sx={{ maxWidth: '300px' }}
                    >
                      {type === 'role' ? row.role : row.group}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  );
}

function UserBatchUpdate(props) {
  const { open, handleFirstClose, type } = props;
  const [group, setGroup] = useState('');
  const [role, setRole] = useState(''); //这个是用户选择的CEid值
  const [currentSelected, setCurrentSelected] = useState([]);

  const dispatch = useDispatch();

  const [confirmOpen, setConfirmOpen] = useState(false);

  const selector = state => {
    return {
      selected: state.Selete.selectedUserList,
      batchUpdateResult: state.User.batchUpdateResult,
    };
  };
  const { selected, batchUpdateResult } = useSelector(selector);

  const handleClose = () => {
    //包装一下close函数
    setRole('');
    setGroup('');
    handleFirstClose();
  };

  useEffect(() => {
    let result = batchUpdateResult;
    if (result === 'true') {
      dispatch(setTextParam('Batch Update user'));
      dispatch(setEditType('updateUser'));
      dispatch(openConfirm());
      handleClose();
      for (var i = 0; i < currentSelected.length; i++) {
        // console.log(currentSelected[i])
        dispatch(removeUserSelectedInfo(currentSelected[i]));
      }
      dispatch(cancelSelectAllUser());
      setConfirmOpen(false);
    } else {
      handleClose();
      setConfirmOpen(false);
    }
    dispatch(updateClear());
  }, [batchUpdateResult]);

  const handleConfirmOpen = () => {
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const BatchUpdateFunction = async () => {
    var userStr = '' + selected[0];
    for (var i = 1; i < selected.length; i++) {
      userStr = userStr + ',' + selected[i];
    }
    // console.log(userStr, role, group);
    dispatch(batchUpdateUser(userStr, role, group));
  };

  return (
    <div data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-ContainerDiv'>
      <SIModal
        data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-ContainerDiv-SIModal'
        title='Batch Update'
        open={open}
        handleClose={handleClose}
        height='auto'
        sx={{
          marginTop: '48px',
        }}
        width='fit-content'
      >
        <Box data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-ContainerDiv-SIModal-Box'>
          <BatchContrain
            data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-ContainerDiv-SIModal-Box-BatchContrain'
            open={open}
            handleClose={handleClose}
            type={type}
            role={role}
            setRole={setRole}
            group={group}
            setGroup={setGroup}
            currentSelected={currentSelected}
            setCurrentSelected={setCurrentSelected}
            handleConfirmOpen={handleConfirmOpen}
          ></BatchContrain>
          <CommonDialog
            id='systemAdminUserManagementBatchUpdate'
            data-seleunim-id='SystemAdmin_UserManagement-BatchUpdateConfirm-ContainerDiv-SIModal-Box-CommonDialog'
            title={'Batch Update'}
            content='Are you sure you want to proceed ?'
            handleClose={handleConfirmClose}
            handleConfirm={BatchUpdateFunction}
            alertOpen={confirmOpen}
          />
        </Box>
      </SIModal>
    </div>
  );
}
export default UserBatchUpdate;

export const calcHeight = (options, maxNum, gap, liHeight, padding,other) => {
  let numOptions = options?.length ?? 0;
  let maxHeight = (maxNum - 1) * (gap + liHeight) + liHeight + padding;
  let currentHeight =numOptions===0 ? padding +other :(numOptions - 1) * (gap + liHeight) + liHeight + padding +other;
  return currentHeight <= maxHeight ? currentHeight :maxHeight;
};

export const calcMarginBottom = (options, maxNum, gap, liHeight, other) => {
  let numOptions = options?.length ?? 0;
  if (numOptions <= maxNum) {
    return 0;
  } else {
    return liHeight - other;
  }
};

import { Box } from '@mui/material';
import CreateAndUpdateInfomationBox from '../CommonComponents/CreateAndUpdateInfomationBox';
import { CreateAndUpdateLittleTitle } from '../CommonComponents/CreateAndUpdateTitle';
import CreateAndUpdateTable from '../CommonComponents/CreateAndUpdateTable';
function ChildBlock(props) {
  const { data, littleTitle, isOA, currentStage } = props;
  const getHeaderByProperty = property => {
    switch (property) {
      case 'recruited':
        return {
          property: property,
          value: 'RECRUITED',
          align: 'center',
          minWidth: '75px',
          maxWidth: '75px',
        };
      case 'submitted':
        return {
          property: property,
          value: 'SUBMITTED',
          align: 'center',
          minWidth: '75px',
          maxWidth: '75px',
        };
      case 'accepted':
        return {
          property: property,
          value: 'ACCEPTED',
          align: 'center',
          minWidth: '75px',
          maxWidth: '75px',
        };
      case 'rejected':
        return {
          property: property,
          value: 'REJECTED',
          align: 'center',
          minWidth: '75px',
          maxWidth: '75px',
        };
      case 'volume':
        return {
          property: property,
          value: 'VOLUME',
          align: 'left',
          minWidth: '55px',
          maxWidth: '55px',
        };
      case 'issuePublicationLink':
        return {
          property: property,
          value: 'ISSUE PUBLICATION LINK',
          align: 'left',
          minWidth: '216px',
          maxWidth: '216px',
        };
      case 'actualPublicationDate':
        return {
          property: property,
          value: 'ACTUAL PUBLICATION DATE',
          align: 'left',
          minWidth: '183px',
          maxWidth: '183px',
        };
      case 'ooarticle':
        return {
          property: property,
          value: 'OO ARTICLES',
          align: 'center',
          minWidth: '85px',
          maxWidth: '85px',
        };
      case 'oaarticle':
        return {
          property: property,
          value: 'OA ARTICLES',
          align: 'center',
          minWidth: '85px',
          maxWidth: '85px',
        };
      case 'numberOfWaiversUsed':
        return isOA
          ? {
              property: property,
              value: 'WAIVER(S) USED',
              align: 'center',
              minWidth: '90px',
              maxWidth: '90px',
            }
          : {
              property: property,
              value: '',
              align: 'center',
              minWidth: '0px',
              maxWidth: '0px',
            };
      case 'issue':
        return {
          property: property,
          value: 'ISSUE',
          align: 'center',
          minWidth: '37px',
          maxWidth: '37px',
        };
      default:
        return {
          property: property,
          value: 'Data source',
          align: 'left',
          minWidth: '92px',
          maxWidth: '92px',
        };
    }
  };
  const getContent = () => {
    switch (littleTitle) {
      case 'General Information':
      case 'Guest Editors':
      case 'Stakeholders':
      case 'Dates':
      case '':
        return (
          <CreateAndUpdateInfomationBox
            data-selenium-id='SI_CreateAndUpdate_ReviewPage-ChildBlock-InfomationBox'
            infomation={data}
            isOA={isOA}
          ></CreateAndUpdateInfomationBox>
        );
      case 'Submission Overview':
      case 'Publication Details':
        let head = [];
        Object.keys(data).map(property =>
          head.push(getHeaderByProperty(property))
        );

        return (
          <CreateAndUpdateTable
            data-selenium-id='SI_CreateAndUpdate_ReviewPage-ChildBlock-Table'
            head={head}
            rows={[data]}
            identifier='id'
          />
        );

      default:
        return '';
    }
  };
  return (
    <Box>
      <CreateAndUpdateLittleTitle
        data-selenium-id='SI_CreateAndUpdate_ReviewPage-ChildBlock-LittleTitle'
        littleTitle={littleTitle}
      />
      {getContent()}
    </Box>
  );
}
export default ChildBlock;

import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Popover,
  Stack,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material';
import UpdatePasswordIcon from '@/assets/UpdatePasswordIcon.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LogOutIcon from '@/assets/LogOutIcon.svg';
import { FormattedMessage } from 'react-intl';
import UserProfileLogo from '@/assets/user-profile.svg';
import HeaderArrowIcon from '@/assets/HeaderArrowIcon.svg';
import { sleep } from '@/utils/commonUtils';
import { setNotificationDrawerOpen } from '../../../actions/SIMT-User/Alert/AlertAction';
import {logout} from "@/actions/SIMT-User/Login";
import {getUserInfo} from "@/actions/SIMT-User/User";

function UserProfilePopper(props) {
  const { open, anchorEl, handleClose, ...other } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = state => {
    return {
      firstName: state.User.firstName,
      lastName: state.User.lastName,
      email: state.User.email,
      role: state.User.role,
      group: state.User.group,
    };
  };
  const { firstName, lastName, email, role:roleObj, group } = useSelector(selector);
  const [loading, setLoading] = useState(false);
  const role=typeof roleObj==='object'?roleObj?.name:roleObj;
  useEffect(() => {
    if (!Boolean(firstName || lastName || email || role || group) && open) {
      // 如果全部用户信息都是空的话就请求后端
      setLoading(true);

      async function loadUserInfo() {
        await dispatch(getUserInfo());
      }

      loadUserInfo().then(_r => setLoading(false));
    }
  }, [dispatch, open, email, firstName, lastName, group, role]);

  const handleLogOut = async () => {
    dispatch(setNotificationDrawerOpen(false));
    await dispatch(logout());
    handleClose();
    navigate('/simt/login');
    setTimeout(() => {
      window.location.reload();//强制刷新，解决退出登录后，再次登录，部分信息显示奇奇怪怪的问题
    }, 100);
  };

  const handleUpdatePass = () => {
    dispatch(setNotificationDrawerOpen(false));
    handleClose();
    navigate('/simt/auth/updatePassword');
  };
  const fullName = `${firstName}, ${lastName}`;
  return (
    <Popover
      id={open ? 'UserProfilePanel' : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...other}
      sx={{
        marginTop: '4px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Box minWidth={202} pb={1}>
        {/*用户信息*/}
        {!loading && (
          <Box p={1} mt={0.5}>
            <Stack
              spacing={0.5}
              justifyContent='flex-start'
              alignItems='center'
            >
              <Typography fontSize={14} id='userProfilePanelAccountUsername'>
                <FormattedMessage
                  id='account.username'
                  values={{ firstName: firstName, lastName: lastName }}
                />
              </Typography>
              <Typography fontSize={12} id='userProfilePanelAccountEmail'>
                {email}
              </Typography>
              <Grid container justifyContent='center'>
                {/*role*/}
                <Grid container item xs spacing={0.5} justifyContent='flex-end'>
                  <Grid item xs={4}>
                    <Typography
                      id='userProfilePanelRole'
                      style={{ fontSize: 10 }}
                      fontWeight={300}
                    >
                      <FormattedMessage id='account.role' />:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Tooltip title={String(role)} arrow>
                      <Typography
                        id='userProfilePanelAccountRole'
                        fontWeight={400}
                        fontSize={10}
                        sx={{
                          wordWrap: 'break-word',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          maxWidth: '40px',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {String(role)}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
                {/*group*/}
                <Grid
                  container
                  item
                  xs
                  spacing={0.5}
                  justifyContent='flex-start'
                >
                  <Grid item xs={5}>
                    <Typography
                      id='userProfilePanelGroup'
                      style={{ fontSize: 10 }}
                      fontWeight={300}
                    >
                      <FormattedMessage id='account.group' />:{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Tooltip title={String(group)} arrow>
                      <Typography
                        id='userProfilePanelAccountGroup'
                        fontWeight={400}
                        fontSize={10}
                        sx={{
                          wordWrap: 'break-word',
                          maxWidth: '61px',
                          height: '18px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {String(group)}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        )}
        {loading && (
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            height={50}
            mt={1}
          >
            <CircularProgress size={28} />
          </Grid>
        )}
        {/*修改密码 、退出登录*/}
        <Box mt={1}>
          <MenuItem
            id='userProfilePanelUpdatePassword'
            onClick={handleUpdatePass}
            disableRipple
            sx={{ pt: '4px', pb: '4px' }}
          >
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='flex-start'
              spacing={1}
            >
              <UpdatePasswordIcon />
              <Typography fontSize={10}>
                <FormattedMessage id='account.updatePassword' />
              </Typography>
            </Stack>
          </MenuItem>

          <MenuItem
            id='userProfilePanelLogout'
            onClick={handleLogOut}
            disableRipple
            sx={{ pt: '4px', pb: '4px' }}
          >
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='flex-start'
              spacing={1}
            >
              <LogOutIcon fontSize='10px' />
              <Typography fontSize={10}>
                <FormattedMessage id='account.logout' />
              </Typography>
            </Stack>
          </MenuItem>
        </Box>
      </Box>
    </Popover>
  );
}

const UserProfilePanel = props => {
  const { headerContainerRef, anchorEl, setAnchorEl } = props;
  const dispatch = useDispatch();
  const selector = state => {
    return {
      firstName: state.User.firstName,
      lastName: state.User.lastName,
    };
  };
  const { firstName, lastName } = useSelector(selector);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(true); // 改为弹出式动画，loading暂时不使用

  const nameBoxRef = useRef(null); // 包裹名字的 Box 的 DOM 元素，用于获取加载成功之后的内容宽度

  const handleAccountClick = event => {
    setAnchorEl(headerContainerRef.current);
  };

  const handleAccountClose = () => {
    setAnchorEl(null);
  };
  const accountOpen = Boolean(anchorEl);

  useEffect(() => {
    if (!Boolean(firstName) || !Boolean(lastName)) {
      // 如果全部用户信息都是空的话就请求后端
      async function loadUserInfo() {
        await dispatch(getUserInfo());
        sleep(200).then(() => setLoading(false));
      }
      loadUserInfo();
    } else {
      sleep(200).then(() => setLoading(false));
    }
  }, [dispatch, nameBoxRef, firstName, lastName]);

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        spacing={1.5}
        className='MuiStack-root'
        justifyContent='flex-end'
        sx={{
          cursor: 'pointer',
          height: '48px',
          borderRadius: '4px',
          transition: 'all 0.5s',
          paddingX: '12px',
          backgroundColor: hover || accountOpen ? '#113D95' : '#154AB6',
        }}
        onClick={handleAccountClick}
        onMouseLeave={() => {
          setHover(false);
        }}
        onMouseOver={() => {
          setHover(true);
        }}
      >
        <UserProfileLogo
          style={{
            width: '26.67px',
            height: '26.67px',
            transition: 'inherit',
          }}
          id='UserProfileLogo'
        />
        <Box
          className={`UserProfileText`}
          id='UserProfileText'
          sx={{
            transition: `all 1s`,
            '&.MuiBox-root': { fontWeight: '400' },
            width: loading ? '0px' : `${nameBoxRef.current.scrollWidth}px`,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            opacity: loading ? 0 : 1,
          }}
          ref={nameBoxRef}
        >
          {`${lastName}, ${firstName}`}
        </Box>
        <IconButton
          sx={{
            padding: 0,
            transition: 'inherit',
          }}
        >
          <HeaderArrowIcon id='HeaderArrowIcon' />
        </IconButton>
      </Stack>
      <UserProfilePopper
        open={accountOpen}
        anchorEl={anchorEl}
        handleClose={handleAccountClose}
      />
    </>
  );
};

export default UserProfilePanel;

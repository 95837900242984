import * as actions from '../actions/SIMT-User/Alert/AlertAction';

const initState = {
  open: false,
  editType: '',
  textParam: '',
  vertical: 'top',
  horizontal: 'right',
  color: 'lightBlue',

  unreadAlertsCount: 0,
  allAlerts: [],
  unreadAlerts: [],
  allAlertsFinished: false,
  unreadAlertsFinished: false,

  fetchAlertsMonitor: false,
  deleteAlertResult: null,
  deleteAlertMonitor: false,
  readAlertResult: null,
  readAlertMonitor: false,

  notificationDrawerOpen: false,
  currentNotificationTab: 0,
  currentNotificationIndex: -1,
  currentNotificationItem: null,
};

export default function AlertReducer(state = initState, action) {
  const { type, data, id = 0, finished } = action;
  switch (type) {
    case actions.SET_CURRENT_NOTIFICATION_ITEM_INFO:
      return {
        ...state,
        currentNotificationTab: data.currentNotificationTab  ?? state.currentNotificationTab,
        currentNotificationIndex: data.currentNotificationIndex > -1 ? data.currentNotificationIndex : -1,
        currentNotificationItem: data.currentNotificationItem !== undefined ? data.currentNotificationItem : state.currentNotificationItem,
      };

    case actions.SET_NOTIFICATION_DRAWER_OPEN:
      return {
        ...state,
        notificationDrawerOpen: data,
      };
    case actions.FETCH_UNREAD_ALERTS_COUNT:
      return {
        ...state,
        unreadAlertsCount: data,
      };
    case actions.CLEAR_ALERTS:
      return {
        ...state,
        allAlerts: [],
        allAlertsFinished: false,
      };
    case actions.CLEAR_UNREAD_ALERTS:
      return {
        ...state,
        unreadAlerts: [],
        unreadAlertsFinished: false,
      };
    case actions.FETCH_ALERTS:
      return {
        ...state,
        allAlerts: [...state.allAlerts, ...data],
        allAlertsFinished: finished,
      };
    case actions.FETCH_UNREAD_ALERTS:
      return {
        ...state,
        unreadAlerts: [...state.unreadAlerts, ...data],
        unreadAlertsFinished: finished,
      };
    case actions.SET_ALERTS_FINISHED:
      return {
        ...state,
        allAlertsFinished: data,
      };
    case actions.SET_UNREAD_ALERTS_FINISHED:
      return {
        ...state,
        unreadAlertsFinished: data,
      };
    case actions.DELETE_ALERT:
      if (id > 0) {
        const index = state.allAlerts.findIndex(item => item.id === id);
        if (index > -1) {
          state.allAlerts.splice(index, 1);
        }
        const index2 = state.unreadAlerts.findIndex(item => item.id === id);
        if (index2 > -1) {
          state.unreadAlerts.splice(index2, 1);
        }
        if (id === state.currentNotificationItem?.id) {
          state.currentNotificationItem = null;
        }
      }

      return {
        ...state,
        allAlerts: [...state.allAlerts],
        unReadAlerts: [...state.unreadAlerts],
        deleteAlertResult: data,
        deleteAlertMonitor: !state.deleteAlertMonitor,
      };
    case actions.READ_ALERT:
      if (id > 0) {
        const index = state.allAlerts.findIndex(item => item.id === id);
        if (index > -1) {
          state.allAlerts[index].read = 'Yes';
          state.allAlerts[index] = {
            ...state.allAlerts[index]
          };
        }
        const index2 = state.unreadAlerts.findIndex(item => item.id === id);
        if (index2 > -1) {
          state.unreadAlerts[index2].read = 'Yes';
          state.unreadAlerts[index2] = {
            ...state.unreadAlerts[index2]
          };
        }

        state.unreadAlertsCount = state.unreadAlertsCount - 1;
      }
      return {
        ...state,
        allAlerts: [...state.allAlerts],
        unReadAlerts: [...state.unreadAlerts],
        readAlertResult: data,
        readAlertMonitor: !state.readAlertMonitor,
      };
    case actions.CONFIRM_OPEN:
      return {
        ...state,
        open: true,
      };
    case actions.SET_EDIT_TYPE:
      return {
        ...state,
        editType: data,
      };
    case actions.SET_TEXT_PARAM:
      return {
        ...state,
        textParam: data,
      };
    case actions.SET_POSITION:
      return {
        ...state,
        vertical: data.vertical,
        horizontal: data.horizontal,
      };
    case actions.SET_COLOR:
      return {
        ...state,
        color: data,
      };
    case actions.CLOSE_WINDOW:
      return {
        ...state,
        open: false,
      };
    case actions.CLEAR_ALERT:
      return initState;
    default:
      return state;
  }
}

import React, { useState, useEffect, useRef } from 'react';

import DatePickerIcon from '../SVGComponents/DatePickerIcon.svg';
import { AllCentered } from '../CommonStyle';

import { formatMonth, getDateObj, getDateStr, getNewParams } from './Utils';

import ArrowLeft from '../SVGComponents/ArrowLeft.svg';
import ArrowRight from '../SVGComponents/ArrowRight.svg';
import YearArrowLeft from '../SVGComponents/YearArrowLeft.svg';
import YearArrowRight from '../SVGComponents/YearArrowRight.svg';
import { Box, Button, Stack, ThemeProvider } from '@mui/material';
import { themeButton, themeTextField } from '../CommonTheme';
import { SmallBoldFont, SmallFont } from '@/components/FontStyle';
import YearPage from './YearPage';
import MonthPage from './MonthPage';
import DayPage from './DayPage';
import DatePickerDefaultIcon from '../SVGComponents/DatePickerDefaultIcon.svg';
import { GetPopperBottomContext } from '@/componentsphase2/Autocomplete/CommonContext';
import DatePickerHover from '@/assets/DatePickerHover.svg';
import SimpleIconButton from '@/componentsphase2/Button/SimpleIconButton';
import DefaultClear from '@/assets/DefaultClear.svg';
import HoverClear from '@/assets/HoverClear.svg';
import DisableClear from '@/assets/DisableClear.svg';
import moment from 'moment';
import { getUTCToDate } from '../../../../../utils/commonUtils';
const CustomDatePicker = props => {
  const {
    id,
    'data-selenium-id': dataSeleniumId,
    value,
    onChange,
    maxDateStr = '',
    minDateStr = '',
    error = false,
    defaultValue = '',
    handleFocus,
    handleBlur,
    height = '40px',
    ifdataOpen = false,
    dateOpen,
    setDateOpen,
    ifNoData = false,
    setNoData,
    needClear = false,
    needPadding = true,
    placement = 'bottom',
  } = props;
  let initNewParam = getNewParams(defaultValue);
  const limit = props.limit === undefined ? false : props.limit;
  const placeholder = props.placeholder || 'YYYY-MM-DD';
  const disabled = props.disabled === undefined ? false : props.disabled;
  // value
  const [year, setYear] = useState(initNewParam.year);
  const [month, setMonth] = useState(initNewParam.month);
  const [day, setDay] = useState(initNewParam.day);

  // 最终值
  const [inputValue, setInputValue] = useState(initNewParam.value);
  // visible
  const [plate, setPlate] = useState(3); // 板块状态 1:年，2：月，3：日
  const [visible, setVisible] = useState(false);
  // ref
  const monthRef = useRef();
  const yearRef = useRef();

  const [temYear, setTemYear] = useState(initNewParam.year);
  const [temMonth, setTemMonth] = useState(initNewParam.month);

  const maxDate = getDateObj(maxDateStr);
  const minDate = getDateObj(minDateStr);

  const [stateHover, setStateHover] = useState(false);

  const LabelButton = props => {
    const { plateIdx, label, ...other } = props;
    return (
      <ThemeProvider theme={themeButton('#DFE4E8')}>
        <Button
          sx={{
            display: 'flex',
            width: '56px !important',
            height: '22px',
            minWidth: '0px',
            borderRadius: '2px',
            padding: '0px',
            ...SmallBoldFont,
            lineHeight: '19px',
            color: '#596A7C',
            leadingTrim: 'both',
            textEdge: 'cap',
            textTransform: 'none',
          }}
          onClick={() => {
            setPlate(plateIdx);
          }}
          {...other}
        >
          {label}
        </Button>
      </ThemeProvider>
    );
  };

  const IconButton = props => {
    const { Icon, ...other } = props;
    return (
      <ThemeProvider theme={themeButton('#FFFFFF')}>
        <Button
          disableTouchRipple
          sx={{
            display: 'flex',
            width: '14px',
            height: '14px',
            minWidth: '0px',
            borderRadius: '2px',
            padding: '0px',
          }}
          {...other}
        >
          {Icon}
        </Button>
      </ThemeProvider>
    );
  };

  // change 年
  function yearChange(params) {
    setYear(params.value);
    setTemYear(params.value);
    setPlate(2);
  }
  // change 月
  function monthChange(params, temYear) {
    setMonth(params.value);
    setYear(temYear);
    setTemMonth(params.value);
    setPlate(3);
  }
  // chnage 日
  function dayChange(params, temMonth, temYear) {
    setDay(params.value);
    setYear(temYear);
    setMonth(temMonth);
    let newMonth = temMonth < 10 ? '0' + temMonth : temMonth;
    let newDay = params.value < 10 ? `0${params.value}` : `${params.value}`;
    onChange(
      `${temYear}-${newMonth}-${newDay}`,
      moment.utc().year(temYear).month(newMonth-1).date(newDay).hour(0).minute(0).second(0).millisecond(0).toDate()
    );
    setVisible(false);
    if (ifdataOpen) setDateOpen(false);
    if (ifNoData) setNoData(true);
  }
  // fn 定位当前时间
  function setNewTime(time) {
    let newParams = getNewParams(time);
    setYear(newParams.year);
    setTemYear(newParams.year);
    setMonth(newParams.month);
    setTemMonth(newParams.month);
    setDay(newParams.day);
  }

  const setPopperBottom = React.useContext(GetPopperBottomContext).setValue;

  useEffect(() => {
    if (visible) {
      let myPopper = document.getElementById(`datepicker_popper`);
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (myPopper) {
        let bottom =
          myPopper.getBoundingClientRect().top +
          myPopper.offsetHeight +
          scrollTop;
        if (isErrorWhenClick) {
          bottom += 16;
        }
        setPopperBottom(bottom);
      }
    } else {
      setPopperBottom(null);
    }
  }, [visible]);

  const [disable, setDisable] = useState(false);
  const clearSelectedDate = () => {
    onChange(null, null); // Clear the selected date
    setDisable(true);
  };
  useEffect(() => {
    if (disable && value !== '') setDisable(false);
  }, [onChange]);
  useEffect(() => {
    if (value === '') setDisable(true);
  }, [value]);

  const [isErrorWhenClick,setIsErrorWhenClick] = useState(false);
  return (
    <div
      style={{ position: 'relative', width: '100%' }}
      id={`datepicker_Container`}
      data-selenium-id={`${dataSeleniumId || id}`}
    >
      {needClear ? (
        <SimpleIconButton
          DefaultIcon={DefaultClear}
          HoverIcon={HoverClear}
          ActiveIcon={DefaultClear}
          DisabledIcon={DisableClear}
          isDisabled={disable}
          sx={
            needPadding
              ? {
                  paddingLeft: '257px',
                  paddingBottom: '2px',
                  marginBottom: '0px',
                  paddingTop: '0px',
                }
              : { position: 'absolute', right: -7, top: -23 }
          }
          onClick={() => {
            clearSelectedDate();
          }}
        ></SimpleIconButton>
      ) : (
        <></>
      )}
      <Box
        id={id}
        data-selenium-id={`datepicker_input_box`}
        onClick={() => {
          if (!visible) {
            setNewTime(value);
            setPlate(3);
            handleFocus();
          }
          setIsErrorWhenClick(error);
          setVisible(!visible);
          if (ifdataOpen) setDateOpen(!dateOpen);
        }}
        sx={{
          width: '100%',
          height: height,
          opacity: '1',
          background: '#ffffff',
          border: error
            ? '2px solid #EE5350'
            : visible
            ? '2px solid #154AB6'
            : stateHover
            ? '1px solid #262E35'
            : value && value !== ''
            ? '1px solid #98A7B6'
            : '1px solid #DFE4E8',
          padding: '11px 12px',
          boxSizing: 'border-box',
          borderRadius: '4px',
          position: 'relative',
          ...AllCentered,
          gap: '10px',
          '& input::placeholder': {
            color: '#BCC5CF',
            opacity: '1',
          },
        }}
        onMouseEnter={() => {
          setStateHover(true);
        }}
        onMouseLeave={() => {
          setStateHover(false);
        }}
      >
        <input
          id={`datepicker_input`}
          data-selenium-id={`datepicker_input`}
          placeholder={placeholder}
          type='text'
          value={value}
          readOnly
          style={{
            border: 'none',
            outline: 'none',
            width: 'calc(100% - 26px)',
            ...SmallFont,
            lineHeight: '125%',
            color: '#262E35',
            height: '18px',
            padding: '0px',
          }}
        />
        <IconButton
          id={`datepicker_input_endAdornment`}
          data-selenium-id={`datepicker_input_endAdornment`}
          Icon={
            visible || stateHover ? (
              <DatePickerHover />
            ) : (
              <DatePickerDefaultIcon />
            )
          }
          sx={{
            width: '16px',
            height: '16px',
            minWidth: '0px',
            borderRadius: '2px',
            padding: '0px',
          }}
        ></IconButton>
      </Box>
      {visible ? (
        <Box
          id={`datepicker_bg`}
          data-selenium-id={`datepicker_bg`}
          onClick={() => {
            handleBlur();
            setVisible(false);
            if (ifdataOpen) setDateOpen(false);
          }}
          sx={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100vw',
            height: '100vh',
            zIndex: '2021',
            backgroundColor: 'transparent',
          }}
        />
      ) : null}
      {visible ? (
        <Box
          id={`datepicker_popper`}
          data-selenium-id={`datepicker_popper`}
          sx={{
            display: visible ? 'block' : 'none',
            position: 'absolute',
            top: placement === 'bottom' ?(
              needClear && needPadding
                ? `calc(${height} + 25px)`
                : `calc(${height} + 6px)`) :
                'unset',
            bottom: placement === 'top' ? `calc(${height} + 6px)` : 'unset',
            right: '0px',
            zIndex: '2022',
            width: '284px',
            height: plate === 3 ? 'auto' : '274px',
            padding: plate === 1 ? '20px 0px 4px 16px' : '16px',
            boxSizing: 'border-box',
            background: '#FFFFFF',
            boxShadow: '0px 6px 12px rgba(38, 46, 53, 0.12)',
            borderRadius: '8px',
          }}
        >
          {/* 年/月切换 */}
          {/* DatePickerHeader */}
          <Box
            id={`datepicker_popper_header`}
            data-selenium-id={`datepicker_popper_header`}
            sx={{
              width: '244px',
              height: '22px',
              ...AllCentered,
              display: plate === 1 ? 'none' : 'flex',
              marginBottom: '16px',
            }}
          >
            <IconButton
              id={`datepicker_popper_header_leftIcon`}
              data-selenium-id={`datepicker_popper_header_leftIcon`}
              Icon={plate === 2 ? <YearArrowLeft /> : <ArrowLeft />}
              onClick={() => {
                if (plate === 2) {
                  yearRef.current.reduceFn();
                } else {
                  monthRef.current.reduceFn();
                }
              }}
            ></IconButton>
            <Box
              sx={{ width: 'calc(100% - 28px)', ...AllCentered }}
              id={`datepicker_popper_header_title_box`}
              data-selenium-id={`datepicker_popper_header_title_box`}
            >
              <Stack spacing={1.5} direction='row'>
                {plate === 3 ? (
                  <LabelButton
                    plateIdx={2}
                    label={formatMonth(temMonth - 1)}
                    id={`datepicker_popper_header_title_month`}
                    data-selenium-id={`datepicker_popper_header_title_month`}
                  ></LabelButton>
                ) : null}
                <LabelButton
                  plateIdx={1}
                  label={temYear}
                  id={`datepicker_popper_header_title_year`}
                  data-selenium-id={`datepicker_popper_header_title_year`}
                ></LabelButton>
              </Stack>
            </Box>
            <IconButton
              id={`datepicker_popper_header_rightIcon`}
              data-selenium-id={`datepicker_popper_header_rightIcon`}
              Icon={plate === 2 ? <YearArrowRight /> : <ArrowRight />}
              onClick={() => {
                if (plate === 2) {
                  yearRef.current.addFn();
                } else {
                  monthRef.current.addFn();
                }
              }}
            ></IconButton>
          </Box>
          {/* 日期列表 */}
          <Box>
            <YearPage
              id={`datepicker_popper_yearPage`}
              ref={yearRef}
              change={yearChange.bind(this)}
              active={year}
              visible={plate === 1}
              temYear={temYear}
              setTemYear={setTemYear}
              maxDate={maxDate}
              minDate={minDate}
            />
            <MonthPage
              id={`datepicker_popper_monthPage`}
              ref={monthRef}
              change={monthChange.bind(this)}
              year={year}
              active={month}
              visible={plate === 2}
              limit={limit}
              temYear={temYear}
              setTemYear={setTemYear}
              temMonth={temMonth}
              setTemMonth={setTemMonth}
              maxDate={maxDate}
              minDate={minDate}
            />
            <DayPage
              id={`datepicker_popper_dayPage`}
              monthRef={monthRef}
              change={dayChange.bind(this)}
              year={year}
              month={month}
              active={day}
              visible={plate === 3}
              show={visible}
              limit={limit}
              temYear={temYear}
              temMonth={temMonth}
              maxDate={maxDate}
              minDate={minDate}
            />
          </Box>
        </Box>
      ) : null}
    </div>
  );
};

export default CustomDatePicker;

import { useEffect, useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import { styled } from '@mui/styles';
import { testInputFont } from '@/components/FontStyle';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SIFilterCalendarIcon from '@/assets/SIFilterCalendarIcon.svg';

export function DatePicker(props) {
  const {
    value,
    setValue,
    errorMonitor,
    setError = () => {},
    ...other
  } = props;

  const [dateOpen, setDateOpen] = useState(false);

  const isEmpty = () => {
    return value === '' || value === null;
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      data-selenium-id='SIP_AcceptPage_Submodule-DatePicker-LocalizationProvider'
    >
      <DesktopDatePicker
        data-selenium-id='SIP_AcceptPage_Submodule-DatePicker-LocalizationProvider-Desktop'
        inputFormat='yyyy-MM-dd'
        mask='____-__-__'
        value={value}
        onChange={newValue => {
          setValue(newValue);
        }}
        open={dateOpen}
        onOpen={() => {
          setDateOpen(true);
        }}
        onClose={() => {
          setDateOpen(false);
        }}
        renderInput={params => (
          <TextField
            data-selenium-id='SIP_AcceptPage_Submodule-DatePicker-LocalizationProvider-Desktop-TextField'
            sx={{
              '&': {
                width: '100%',
              },
              '& .MuiOutlinedInput-root': {
                background: '#FFFFFF',
                borderRadius: '4px',
                boxSizing: 'border-box',
                height: '33px',
                ...testInputFont,
                color: '#262E35',
                padding: '8px 0px 8px 12px',
              },
              '& .MuiOutlinedInput-input': {
                padding: '0px 0px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #DFE4E8',
              },
              '& .MuiOutlinedInput-root.Mui-error fieldset': {
                border: '2px solid #EE5350',
                borderRadius: '4px',
              },
            }}
            fullWidth
            variant='outlined'
            {...params}
            error={errorMonitor && isEmpty()}
            InputProps={{
              endAdornment: (
                <IconButton
                  data-selenium-id='SIP_AcceptPage_Submodule-DatePicker-LocalizationProvider-Desktop-TextField-IconButton'
                  sx={{ padding: '7px' }}
                  onClick={() => {
                    setDateOpen(!dateOpen);
                  }}
                >
                  <SIFilterCalendarIcon data-selenium-id='SIP_AcceptPage_Submodule-DatePicker-LocalizationProvider-Desktop-TextField-IconButton-SIFilterCalendar' />
                </IconButton>
              ),
            }}
          />
        )}
        {...other}
      />
    </LocalizationProvider>
  );
}

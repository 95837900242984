import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
} from '@mui/material';
import {
  FormControlLabel,
  InputLabel,
  Switch,
  Tab,
  Tabs,
  Backdrop,
  Typography,
  Drawer,
  Grid,
  Divider,
} from '@mui/material';
import { H4Font, SmallBoldFont } from '@/components/FontStyle';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateButton } from '@/componentsphase2/StateButton';
import CloseIconButton from '@/componentsphase2/Button/CloseIconButton';
import {
  clearEditOptInCode,
  clearSubmitOptInCode,

} from '../../../../actions/SIMT-SI/CatJournal/CATJournalAction';
import EditContent from './EditContent';
import CATJournalCloseButton from '@/assets/CATJournalCloseButton.svg';
import DrawerCloseIcon from '../../../../assets/DrawerCloseIcon.svg';
import {editOptIn} from "@/actions/SIMT-SI/CatJournal";
import {getOptInData} from "@/actions/SIMT-SI/CatJournal/OptIn";

export default function OptInEdit(props) {
  const { open = false, handleClose = () => {}, defaultValue } = props;

  const [isClick, setIsClick] = useState(false);
  const [data, setData] = useState(null);

  const dispatch = useDispatch();

  const { editOptInResult, editOptInMonitor, editOptInCode, page, pageSize } =
    useSelector(state => {
      return {
        editOptInResult: state.CATJournal.editOptInResult,
        editOptInMonitor: state.CATJournal.editOptInMonitor,
        editOptInCode: state.CATJournal.editOptInCode,
        page: state.CATJournal.optInPage,
        pageSize: state.CATJournal.optInPageSize,
      };
    });

  const handleCancel = () => {
    document.body.classList.remove('hide-scroll');
    handleClose();
    setIsClick(false);
    dispatch(clearEditOptInCode());
    dispatch(clearSubmitOptInCode());
  };

  const handleSave = () => {
    setIsClick(true);
    if (
      data &&
      data.journalCode !== '' &&
      data.developmentModel !== '' &&
      data.reason !== '' &&
      data.currentSIPipelineAndFutureSIPlan !== '' &&
      data.reason.length <= 10000 &&
      data.currentSIPipelineAndFutureSIPlan.length <= 100000
    ) {
      dispatch(editOptIn(data));
      document.body.classList.remove('hide-scroll');
    }
  };

  useEffect(() => {
    if (editOptInResult !== null) {
      dispatch(getOptInData(page, pageSize));
      setTimeout(() => {
        document.body.classList.remove('hide-scroll');
        handleClose();
        setIsClick(false);
        dispatch(clearEditOptInCode());
      }, 300);
    }
  }, [editOptInMonitor]);

  useEffect(() => {
    if(open){
      document.body.classList.add('hide-scroll');
    }
  },[open]);

  const isError = () => {
    if (editOptInCode !== null) {
      return true;
    }
    if (
      data &&
      (data.reason.length > 10000 ||
        data.currentSIPipelineAndFutureSIPlan.length > 100000)
    ) {
      return true;
    }
    if (!isClick) {
      return false;
    } else {
      return !(
        data &&
        data.journalCode !== '' &&
        data.developmentModel !== '' &&
        data.reason !== '' &&
        data.currentSIPipelineAndFutureSIPlan !== ''
      );
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
      data-selenium-id='CATJournal_Overview-OptIn-Eidt-anotherNewFilterBox'
    >
      <Backdrop
        id='CATJournalOverviewOptInEidtAnotherNewFilterDrawerBackdrop'
        data-selenium-id={`CATJournal_Overview-OptIn-Eidt-anotherNewFilterDrawerBackdrop`}
        sx={{
          zIndex: 1400,
          backdropFilter: 'blur(4px)',
          background: '#113D9580',
        }}
        open={open}
      ></Backdrop>
      <Drawer
        id='CATJournalOverviewOptInEditAnotherNewFilterDrawer'
        variant='persistent'
        anchor='right'
        open={open}
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: '472px',
            zIndex: 1400,
            backgroundColor: '#FFFFFF',
          },
        }}
        data-selenium-id={`CATJournal_Overview-OptIn-Edit-anotherNewFilterDrawer`}
      >
        <Box
          sx={{
            m: '20px 36px',
            height: '34px',
          }}
          id='closeButtonOpt'
          data-selenium-id='CATJournal_Overview-OptInOperation-Edit-Box'
        >
          {/* <CloseIconButton
            sx={{ float: 'left', cursor: 'pointer', padding: '0px',marginRight:'12px', pt:'5px'}}
            onClick={() => {
              document.body.classList.remove('hide-scroll');
              handleClose();
              setIsClick(false);
              dispatch(clearEditOptInCode());
            }}
            data-selenium-id='CATJournal_Overview-OptInOperation-Edit-IconButton'
          /> */}
          <IconButton
            disableRipple
            sx={{
              float: 'left',
              cursor: 'pointer',
              padding: '0px',
              marginRight:'12px',
              pt:'5px',
              '&:hover':{
                'svg path':{
                  fill: '#596A7C'
                }
              }
            }}
            onClick={() => {
              document.body.classList.remove('hide-scroll');
              handleClose();
              setIsClick(false);
              dispatch(clearEditOptInCode());
              dispatch(clearSubmitOptInCode());
            }}
            data-selenium-id='CATJournal_Overview-OptInOperation-Edit-IconButton'
          >
            <DrawerCloseIcon/>
          </IconButton>

          <Box
            sx={{
              ...H4Font,
              height: '13px',
              float: 'left',
              color: '#262E35',
              pt:'5px'
            }}
            data-selenium-id='CATJournal_Overview-OptInOperation-Edit-Box-Box'
          >
            Opt-in Request
          </Box>

          <StateButton
            onClick={handleSave}
            data-selenium-id='CATJournal_Overview-OptInOperation-Edit-Box2'
            titleName='Submit'
            isPrimary={true}
            sx={{
              lineHeight: '18px',
              marginLeft: '10px',
              float: 'right',
              '&:disabled': {
                backgroundColor: '#BCC5CF',
                boxShadow: 'none',
                color: '#FFFFFF',
              },
            }}
            disabled={isError()}
          ></StateButton>


          <StateButton
            onClick={handleCancel}
            data-selenium-id='CATJournal_Overview-OptInOperation-Edit-Box1'
            titleName='Cancel'
            isPrimary={false}
            sx={{lineHeight: '18px',float: 'right'}}
          ></StateButton>

        </Box>
        <Divider></Divider>

        <EditContent
          errorMonitor={isClick}
          setValue={setData}
          defaultValue={defaultValue}
          open={open}
        >

        </EditContent>









      </Drawer>
    </Box>

    // <Dialog
    //   open={open}
    //   onClose={() => {
    //     handleClose();
    //     setIsClick(false);
    //     dispatch(clearEditOptInCode());
    //   }}
    //   sx={{
    //     '& .MuiDialog-paper': {
    //       minWidth: '529px',
    //       margin: 0,
    //       padding: '32px 35px 0px 35px',
    //       boxSizing: 'border-box',
    //     },
    //     '& .MuiBackdrop-root': {
    //       // backgroundColor: 'rgba(9, 30, 66, 0.54)',
    //     },
    //     '& .MuiBackdrop-root': {
    //       background: 'rgba(17, 61,149, 0.5)',
    //       backdropFilter: 'blur(4px)',
    //     },
    //   }}
    //   data-selenium-id='CATJournal_Overview-OptInOperation-Edit-Dialog'
    // >
    //   <Box
    //     sx={{ height: '28px', marginBottom: '24px' }}
    //     data-selenium-id='CATJournal_Overview-OptInOperation-Edit-Box'
    //   >
    //     <IconButton
    //       sx={{ float: 'left', cursor: 'pointer', padding: '0px',marginRight:'12px'}}
    //       onClick={() => {
    //         handleClose();
    //         setIsClick(false);
    //         dispatch(clearEditOptInCode());
    //       }}
    //       data-selenium-id='CATJournal_Overview-OptInOperation-Edit-IconButton'
    //     >
    //       <CATJournalCloseButton />
    //     </IconButton>
    //     <Box
    //       sx={{
    //         ...H4Font,
    //         height: '13px',
    //         float: 'left',
    //         color: '#262E35',
    //       }}
    //       data-selenium-id='CATJournal_Overview-OptInOperation-Edit-Box-Box'
    //     >
    //       Opt-in Request
    //     </Box>

    //       <StateButton
    //         onClick={handleSave}
    //         data-selenium-id='CATJournal_Overview-OptInOperation-Edit-Box2'
    //         titleName='Submit'
    //         isPrimary={true}
    //         sx={{
    //           lineHeight: '18px',
    //           marginLeft: '10px',
    //           float: 'right',
    //           '&:disabled': {
    //             backgroundColor: '#BCC5CF',
    //             boxShadow: 'none',
    //             color: '#FFFFFF',
    //           },
    //         }}
    //         disabled={isError()}
    //       ></StateButton>

    //       <StateButton
    //         onClick={handleCancel}
    //         data-selenium-id='CATJournal_Overview-OptInOperation-Edit-Box1'
    //         titleName='Cancel'
    //         isPrimary={false}
    //         sx={{lineHeight: '18px',float: 'right'}}
    //       ></StateButton>

    //   </Box>

    //   <DialogContent
    //     sx={{ padding: '0px' }}
    //     data-selenium-id='CATJournal_Overview-OptInOperation-Edit-DialogContent'
    //   >
    //     <EditContent
    //       errorMonitor={isClick}
    //       setValue={setData}
    //       defaultValue={defaultValue}
    //     ></EditContent>
    //   </DialogContent>

    //   <DialogActions
    //     sx={{ padding: '0px', height: '68px' }}
    //     data-selenium-id='CATJournal_Overview-OptInOperation-Edit-DialogActions'
    //   >
    //     <Stack direction='row' spacing={1.125} sx={{ float: 'right' }}>

    //     </Stack>
    //   </DialogActions>
    // </Dialog>



  );
}

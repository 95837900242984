import { axios_instance, axios_instance_l } from '@/utils/axios_instance';
import { Box, Stack, Tooltip, Typography, tooltipClasses } from '@mui/material';
import { useEffect, useState } from 'react';
import IntegrityCheckLowProbability from '@/assets/IntegrityCheckLowProbability.svg';
import IntegrityCheckHighProbability from '@/assets/IntegrityCheckHighProbability.svg';
import GrayAIRobot from '@/assets/GrayAIRobot.svg';
import { useSelector } from 'react-redux';
import Soild from '../SVG/Soild.svg';
import { AllCentered } from '@/componentsphase2/Autocomplete/CommonStyle';
import { styled } from '@mui/styles';

const CusTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement='top' />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#596A7C',
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderTop: '6px solid #596A7C',
    marginBottom:'-6px !important',
    '::before': {
      display: 'none',
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    // title
    backgroundColor: '#596A7C',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#FFFFFF',
    padding: '8px 16px',
    borderRadius: '5px',
    boxSizing: 'border-box',
    marginBottom: '6px !important',
    maxWidth:'385px'
  },
}));

export default function IntergrityChecks(props) {
  const { sipCode } = props;
  const { sipInformation } = useSelector(state => {
    return {
      sipInformation: state.SIP.sipInformation,
    };
  });

  //AiGenerateProbility: 相似度, similarityCriteria：相似度标准
  const [AiGenerateProbility, setAiGenerateProbility] = useState(null);
  const [similarityCriteria, setSimilarityCriteria] = useState(0);
  //flag变量用以判断相似度是否高于相似度标准
  const [flag, setFlag] = useState(0);

  //渲染组件前get相似度及相似度标准
  useEffect(() => {
    const fetchData = async () => {
      try {
        //同时get相似度以及相似度标准
        const [res1, res2] = await Promise.all([
          axios_instance_l.get(`/api/v1/sip-service/special-issue-proposals/${sipCode}/ai-screening/content-reality`, {
            headers: {
              authorization: localStorage.getItem('authorization'),
            },
          }),
          axios_instance.get(`/api/v1/user-service/configs`, {
            params: {
              type: 'AI Screening Similarity Criteria',
              value: '',
            },
            headers: {
              authorization: localStorage.getItem('authorization'),
            },
          }),
        ]);
        if (res1.data.code === 200)
          setAiGenerateProbility(res1.data.data.machine_detection.probability);
        if (res2.data.code === 200)
          setSimilarityCriteria(res2.data.data[0].value);
      } catch (error) {
        console.log('请求出错', error);
      }
    };
    fetchData();
  }, []);

  //get AiGenerateProbility及SimilarityCriteria后更改Flag
  useEffect(() => {
    setFlag(AiGenerateProbility > similarityCriteria);
  }, [AiGenerateProbility, similarityCriteria]);

  const [characterCount,setCharacterCount] = useState(0);
  useEffect(()=>{
    let aimsAndScope = sipInformation?.aimsAndScope ?? '';
    let topics = sipInformation?.topics ?? [];
    setCharacterCount(aimsAndScope.length + topics.reduce((count, string) => count + string.length,0));
  },[sipInformation]);

  return (
    <Box data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-Box'>
      <Box
        data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '16px',
          maxWidth: '920px',
          minWidth: '634px',
        }}
      >
        <Box
          data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-titleBox'
          sx={{
            display: 'flex',
            width: '218.822px',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Typography
            data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-titleTypography'
            color='var(--gray-500, #596A7C)'
            fontFamily='Open Sans'
            fontSize='20px'
            fontStyle='normal'
            fontWeight='400'
            lineHeight='24px'
          >
            Integrity Checks
          </Typography>
        </Box>
        {sipInformation?.aimsAndScope ? (
          <Box
            data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-reportBox'
            sx={{
              display: 'flex',
              padding: '12px 24px 24px 24px', //当屏幕小于1920px时, paddingLeft/Right 设置为24px
              '@media screen and (min-width: 1920px)': {
                padding: '12px 50px 24px 50px', //当屏幕大于等于1920px时, paddingLeft/Right 设置为50px
              },
              alignSelf: 'stretch',
              flexDirection: 'column',
              alignItems: 'flex-start',
              borderRadius: '5px',
              background:
                flag == 0
                  ? 'var(--success-50, #ECFDF5)'
                  : 'var(--error-50, #FFEFEF)',
            }}
          >
            <Box
              data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-reportBox-InnerBox'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px',
              }}
            >
              <Box
                data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-reportBox-InnerBox-Box1'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '4px',
                }}
              >
                <Stack spacing={0.5} direction='row'>
                  <Typography
                    data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-reportBox-InnerBox-Box1-Typography1'
                    color='var(--gray-600, #262E35)'
                    textOverflow='ellipsis'
                    fontFamily='Open Sans'
                    fontSize='16px'
                    fontStyle='normal'
                    fontWeight='600'
                    lineHeight='24px'
                    sx={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: '1',
                    }}
                  >
                    The Probability of the Content Being AI-Generated
                  </Typography>
                  {characterCount > 5000 ? (
                    <CusTooltip
                      title={
                        <Box sx={{ width: '353' }}>
                          <Box sx={AllCentered}>
                            Note that only first 5000 characters in
                          </Box>
                          <Box sx={AllCentered}>
                            Aims and Scope are put into detection because of
                          </Box>
                          <Box sx={AllCentered}>
                            {' '}
                            word limitations in this tool.
                          </Box>
                        </Box>
                      }
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          width: '20',
                          height: '24px',
                          ...AllCentered,
                        }}
                      >
                        <Soild />
                      </Box>
                    </CusTooltip>
                  ) : null}
                </Stack>
                <Typography
                  data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-reportBox-InnerBox-Box1-Typography2'
                  color={
                    flag == 0
                      ? 'var(--gray-500, #596A7C)'
                      : 'var(--gray-600, #262E35)'
                  }
                  fontFamily='Open Sans'
                  fontSize='12px'
                  fontStyle='normal'
                  fontWeight='400'
                  lineHeight='normal'
                >
                  {flag == 0
                    ? 'Our system uses machine learning to measure the probability of the special issue proposal (SIP) description being automatically generated by Artificial Intelligence. The percentage leverages contextual signals and can facilitate the detection of questionable scientific content.'
                    : 'Our system uses machine learning to measure the probability of the special issue proposal (SIP) description being automatically generated by Artificial Intelligence. The score leverages contextual signals and can facilitate the detection of questionable scientific content.'}
                </Typography>
              </Box>
              <Box
                data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-reportBox-InnerBox-Box2'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  gap: '6px',
                  alignItems: 'flex-start',
                }}
              >
                <Box
                  data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-reportBox-InnerBox-Box2-IconProbilityBox'
                  sx={{
                    display: 'flex',
                    gap: '6px',
                    alignItems: 'center',
                  }}
                >
                  {flag == 0 ? (
                    <>
                      <Box
                        data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-reportBox-InnerBox-Box2-IconProbabilityBox-SuccessIcon'
                        sx={{
                          width: '37px',
                          height: '37px',
                          fill: 'var(--success-500, #42C1B3)',
                        }}
                      >
                        <IntegrityCheckLowProbability />
                      </Box>
                      <Typography
                        data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-reportBox-InnerBox-Box2-IconProbabilityBox-SuccessProbability'
                        sx={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: '1',

                          // 不注释掉这一行百分率数字部分会显示异常？
                          // overflow: "hidden",
                          color: 'var(--success-700, #0D6A61)',
                          leadingTrim: 'both',
                          textEdge: 'cap',
                          textOverflow: 'ellipsis',
                          fontFamily: 'Open Sans',
                          fontSize: '36px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '24px',
                        }}
                      >
                        {AiGenerateProbility !== null
                          ? `${(AiGenerateProbility * 100).toFixed(1)}%`
                          : '%'}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Box
                        data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-reportBox-InnerBox-Box2-IconProbabilityBox-FailureIcon'
                        sx={{
                          width: '37px',
                          height: '37px',
                          fill: 'var(--error-500, #FF8180)',
                        }}
                      >
                        <IntegrityCheckHighProbability />
                      </Box>
                      <Typography
                        data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-reportBox-InnerBox-Box2-IconProbabilityBox-FailureProbability'
                        sx={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: '1',

                          // 不注释掉这一属性百分率数字部分会显示异常？
                          // overflow: "hidden",
                          color: 'var(--error-700, #C40000)',
                          leadingTrim: 'both',
                          textEdge: 'cap',
                          textOverflow: 'ellipsis',
                          fontFamily: 'Open Sans',
                          fontSize: '36px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '24px',
                        }}
                      >
                        {AiGenerateProbility !== null
                          ? `${(
                              Math.floor(AiGenerateProbility * 1000) / 10
                            ).toFixed(1)}%`
                          : '%'}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box
                  data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-reportBox-InnerBox-Box2-BarBox'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '10px',
                  }}
                >
                  {flag == 0 ? (
                    <Box
                      data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-reportBox-InnerBox-Box2-BarBox-outerSuccessBar'
                      sx={{
                        width: '500px',
                        height: '10px',
                        borderRadius: '10px',
                        background: 'var(--success-100, #D7F1EE)',
                      }}
                    >
                      <Box
                        data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-reportBox-InnerBox-Box2-BarBox-innerSuccessBar'
                        sx={{
                          //根据相似度设置进度条
                          width: `${AiGenerateProbility * 500}px`,
                          height: '10px',
                          borderRadius: '10px',
                          background: 'var(--success-700, #0D6A61)',

                          //根据甲方Figma要求设置position为absolute后，bar中的显示进度会随滑轮滚动乱飞
                          position: 'relative',
                        }}
                      ></Box>
                    </Box>
                  ) : (
                    <Box
                      data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-reportBox-InnerBox-Box2-BarBox-outerFailureBar'
                      sx={{
                        width: '500px',
                        height: '10px',
                        borderRadius: '10px',
                        background: 'var(--error-100, #FDD)',
                      }}
                    >
                      <Box
                        data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack-IntegrityChecks-InnerBox-reportBox-InnerBox-Box2-BarBox-innerFailureBar'
                        sx={{
                          //根据相似度设置进度条
                          width: `${AiGenerateProbility * 500}px`,
                          height: '10px',
                          borderRadius: '10px',
                          background: 'var(--error-700, #C40000)',

                          //根据甲方Figma要求设置position为absolute后，bar中的显示进度会随滑轮滚动乱飞
                          position: 'relative',
                        }}
                      ></Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '24px 24px 24px 24px', //当屏幕小于1920px时, paddingLeft/Right 设置为24px
              '@media screen and (min-width: 1920px)': {
                padding: '24px 50px 24px 50px', //当屏幕大于等于1920px时, paddingLeft/Right 设置为50px
              },
              borderRadius: '5px',
              background: '#f6f7f8',
              width: '100%',
            }}
          >
            <GrayAIRobot />
            <Box
              sx={{
                color: '#262e35',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
              }}
            >
              Not enough information to provide a score
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

import { AAAnnualTrendsChartOptions } from '@/pages/Report/chartUtils';
import DownArrowIcon from '@/assets/ReportDiagrams/A&A/AnnualTrends/DownArrow.png';
import UpperArrowIcon from '@/assets/ReportDiagrams/A&A/AnnualTrends/UpperArrow.png';
import MinusSignIcon from '@/assets/ReportDiagrams/A&A/AnnualTrends/MinusSign.png';

// chart config
const BAR_COLOR_FOR_ALL = ['#BCC5CF', '#66CABE', '#0D6A61'];
const BAR_COLOR_FOR_OA = ['#F1F3F5', '#D7F1EE', '#66CABE'];

// Rate related color
const INCREASE_LABEL_COLOR = '#FF8180';
const INCREASE_LABEL_BG_COLOR = '#FFDDDD';
const DECREASE_LABEL_COLOR = '#42C1B3';
const DECREASE_LABEL_BG_COLOR = '#D7F1EE';
const EQUAL_LABEL_COLOR = '#596A7C';
const EQUAL_LABEL_BG_COLOR = '#F1F3F5';
const NA_LABEL_COLOR = '#596A7C';

const BAR_GROUP_GAP = 16;
const BAR_WIDTH_THRESHOLD_FOR_FONT_SIZE = 32;

const SG_BAR_WIDTH_MAX = 40;
const BD_BAR_WIDTH = 42;

const BAR_OVERLAP_DISTANCE = 10;

const Y_AXIS_LABEL_WIDTH = 30;
const X_AXIS_LABEL_WIDTH = 45;

// Improvement: dynamic width for label
const RATE_LABEL_WIDTH = 70;
const RATE_LABEL_HEIGHT = 21;

const SG_BAR_LABEL_FONT_SIZE = [10, 14];
const BD_BAR_LABEL_FONT_SIZE = 15;

const LEGEND_PADDING_BOTTOM = 12;
const LEGEND_PADDING_BOTTOM_DOWNLOAD = 48;

const LEGEND_LABEL_STYLE = {
  icon: 'none',
  selectedMode: false,
  itemGap: 15,
  width: '50px',
  textStyle: {
    fontFamily: 'Open Sans',
    fontSize: '10px',
    fontWeight: '700',
    color: '#596A7C',
  },
  formatter: label => {
    // use the last two legends to show the labels, and hide or others
    // temp solution to get last two legends
    const allIndex = label.indexOf('ALL');
    const oaIndex = label.indexOf('OA');
    // console.log(oaIndex);
    if (oaIndex >= 0) {
      return 'OA';
    } else if (allIndex >= 0) {
      return 'All';
    }
  },
};

/**
 * overwrite the default configuration
 */

export class AllInOneChartOptions extends AAAnnualTrendsChartOptions {
  constructor(isSubjectGroup, isDownload, width, legendData) {
    super();
    // overwrite the default properties
    this.isSubjectGroup = isSubjectGroup;
    this.isDownload = isDownload;
    this.xAxisLabelWidth = isSubjectGroup ? X_AXIS_LABEL_WIDTH : undefined;
    this.numberOfSG = 0;
    this.rateLabelPositionX = -20;
    this.allBarsWidth =
      width - this.barchartLeft - this.barchartRight - Y_AXIS_LABEL_WIDTH;
    this.legendData = legendData;
    this.barOverlapDistance = BAR_OVERLAP_DISTANCE;
  }

  // annual number bar series
  getNumberBarSerie({ barColor, borderColor, showLabel = false, stack }) {
    return {
      type: 'bar',
      emphasis: {
        disabled: true,
      },
      barMinHeight: 3,
      itemStyle: {
        borderColor: borderColor ? borderColor : barColor,
        borderWidth: 1,
      },
      label: {
        show: showLabel,
        color: '#FFFFFF',
        verticalAlign: 'middle',
        // fontSize: 7,
        fontSize: this.getLabelFontSize(),
        fontWeight: 500,
      },
      color: barColor,
      barWidth: this.getBarWidth(),
      stack,
    };
  }

  // current year bar serie
  getHighlightedNumberBarSerie({ barColor, borderColor, columnIndex, stack }) {
    return {
      type: 'bar',
      emphasis: {
        disabled: true,
      },
      barMinHeight: 3,
      itemStyle: {
        borderColor: borderColor ? borderColor : barColor,
        borderWidth: 1,
      },
      label: {
        show: true,
        color: '#FFFFFF',
        verticalAlign: 'middle',
        // fontSize: 7,
        fontSize: this.getLabelFontSize(),
        fontWeight: 500,
        formatter: params => {
          // console.log('===params===', params);
          // if (params.data[3] === 0) {
          if (params.data[columnIndex] === 0) {
            return '';
          }
          return params.data[columnIndex];
        },
      },
      color: barColor,
      barWidth: this.getBarWidth(),
      barGap: this.getBarGap(),
      stack,
    };
  }

  // get group of Rate bars
  getGroupRateBars = (
    rateColumnIndex,
    comparedBaseColumnIndex,
    currentColumnIndex
  ) => [
    this.getIncreaseRateBar(rateColumnIndex, currentColumnIndex),
    this.getDecreaseRateBar(rateColumnIndex, currentColumnIndex),
    this.getEqualRateBar(rateColumnIndex, currentColumnIndex),
    this.getNARateBar(
      rateColumnIndex,
      comparedBaseColumnIndex,
      currentColumnIndex
    ),
  ];

  // get increase Rate bar +%
  getIncreaseRateBar = (rateColumnIndex, currentColumnIndex) => {
    const formatter = params => {
      const value = params.data[rateColumnIndex];
      console.log('****value: ', value);
      const isShow = getNumber(value) > 0;
      if (isShow) {
        const displayNumber = value + '%';
        return `{icon|}{title|${displayNumber}}`;
      } else {
        return '';
      }
    };

    return {
      ...this.getRateBarSerie(currentColumnIndex),
      label: {
        ...this.getRateBarSerieLabel({
          backgroundColor: INCREASE_LABEL_BG_COLOR,
          titleColor: INCREASE_LABEL_COLOR,
          iconImg: UpperArrowIcon,
          formatter,
        }),
      },
    };
  };

  // get decrease Rate bar -%
  getDecreaseRateBar = (rateColumnIndex, currentColumnIndex) => {
    const formatter = params => {
      const value = params.data[rateColumnIndex];
      const isShow = getNumber(value) < 0;
      if (isShow) {
        const displayNumber = value + '%';
        return `{icon|}{title|${displayNumber}}`;
      } else {
        return '';
      }
    };

    return {
      ...this.getRateBarSerie(currentColumnIndex),
      label: {
        ...this.getRateBarSerieLabel({
          backgroundColor: DECREASE_LABEL_BG_COLOR,
          titleColor: DECREASE_LABEL_COLOR,
          iconImg: DownArrowIcon,
          formatter,
        }),
      },
    };
  };

  // get equal Rate bar -
  getEqualRateBar = (rateColumnIndex, currentColumnIndex) => {
    const formatter = params => {
      const value = params.data[rateColumnIndex];
      const isShow = 
        // true || 
        getNumber(value) === 0;
      if (isShow) {
        return `{icon|}`;
      } else {
        return '';
      }
    };

    // equal rate bar
    return {
      ...this.getRateBarSerie(currentColumnIndex),
      label: {
        ...this.getRateBarSerieLabel({
          backgroundColor: EQUAL_LABEL_BG_COLOR,
          titleColor: EQUAL_LABEL_COLOR,
          iconImg: MinusSignIcon,
          formatter,
        }),
        width: 200,
      },
    };
  };

  // get N/A Rate bar: N/A
  getNARateBar = (
    rateColumnIndex,
    comparedBaseColumnIndex,
    currentColumnIndex
  ) => {
    const formatter = params => {
      const rate = params.data[rateColumnIndex];
      const comparedValue = params.data[comparedBaseColumnIndex];
      // console.log('comparedValue: ', comparedValue);
      if (
        // true ||
        rate === null &&
        comparedValue !== null &&
        getNumber(comparedValue) !== 0
      ) {
        return `{text|N/A}`;
      } else {
        return '';
      }
    };

    // equal rate bar
    return {
      ...this.getRateBarSerie(currentColumnIndex),
      label: {
        ...this.getRateBarSerieLabel({
          backgroundColor: EQUAL_LABEL_BG_COLOR,
          titleColor: NA_LABEL_COLOR,
          formatter,
        }),
        width: 200,
      },
    };
  };

  // get FY increasing rate bar serie - invisible
  // getRateBarSerie() {
  getRateBarSerie(currentColumnIndex) {
    return {
      type: 'bar',
      emphasis: {
        disabled: true,
      },
      tooltip: {
        show: false, // hide tooltip
      },
      color: 'rgba(0,0,0,0)',
      barMaxWidth: 1,
      z: 2, // cover first two bars, but under 3rd bar
      encode: {
        // y: 3, // data source for rate
        // y: 6, // data source for rate
        y: currentColumnIndex, // data source for rate
      },
    };
  }

  // get FY increasing rate bar serie - invisible
  getRateBarSerieLabel({ backgroundColor, titleColor, iconImg, formatter }) {
    return {
      show: true,
      verticalAlign: 'top',
      backgroundColor,
      borderRadius: 1,
      height: RATE_LABEL_HEIGHT,
      width: RATE_LABEL_WIDTH,
      padding: [0, 2, 0, 2],
      lineHeight: 15,
      formatter,
      rich: {
        title: {
          color: titleColor,
          padding: [10, 0, 1, 2],
          // padding: [10, 10, 1, 20],
          fontSize: 12,
          lineHeight: 15,
          verticalAlign: 'middle',
        },
        icon: {
          align: 'middle',
          verticalAlign: 'bottom',
          backgroundColor: {
            image: iconImg,
          },
          height: 9.05,
          width: 10.45,
        },
        text: {
          color: titleColor,
          padding: [10, 0, 1, 2],
          fontSize: 12,
          lineHeight: 15,
          verticalAlign: 'middle',
          align: 'center',
        },
      },
      position: [this.getRateLabelPositionX(), -(RATE_LABEL_HEIGHT + 1)],
    };
  }

  /**
   * overwrite the default getBarSeries method
   */
  getBarSeries() {
    const barColorsForAll = BAR_COLOR_FOR_ALL;
    const barColorsForOa = BAR_COLOR_FOR_OA;
  
    const bars = [
      // 1st
      this.getNumberBarSerie({ barColor: barColorsForAll[0], stack: '0' }),
      this.getNumberBarSerie({ barColor: barColorsForAll[1], stack: '1' }),
      this.getHighlightedNumberBarSerie({
        barColor: barColorsForAll[2],
        columnIndex: 3,
        stack: '2',
      }),
      ...this.getGroupRateBars(7, 2, 3),
      this.getNumberBarSerie({
        barColor: barColorsForOa[0],
        borderColor: barColorsForAll[0],
        stack: '0',
      }),
      this.getNumberBarSerie({
        barColor: barColorsForOa[1],
        borderColor: barColorsForAll[1],
        stack: '1',
      }),
      this.getHighlightedNumberBarSerie({
        barColor: barColorsForOa[2],
        borderColor: barColorsForAll[2],
        columnIndex: 6,
        stack: '2',
      }),
      ...this.getGroupRateBars(8, 5, 6),
    ];
    
    return bars;
  }

  /**
   * calculate the bar width
   * variants: Subject group/ Business division
   * variants: number of subject groups
   */
  getBarWidth() {
    // is business division
    if (!this.isSubjectGroup) {
      return BD_BAR_WIDTH;
    }

    // use linear function to get the width of bars for subject groups
    const maxWidth = SG_BAR_WIDTH_MAX;
    const groupWidth =
      (this.allBarsWidth - BAR_GROUP_GAP * (this.numberOfSG - 1)) /
      this.numberOfSG;
    let barWidth = (groupWidth + 10 * 2) / 3;
    // apply the max constraints
    barWidth = barWidth > maxWidth ? maxWidth : barWidth;

    return barWidth;
  }

  /**
   * calculate the bar label font size
   * variants: Subject group/ Business division
   * variants: number of subject groups
   */
  // todo: size is according to bar width only
  getLabelFontSize() {
    // is business division
    if (!this.isSubjectGroup) {
      return BD_BAR_LABEL_FONT_SIZE;
    }

    // is subject group
    let fontSize = 0;

    let barWidth = this.getBarWidth();
    fontSize =
      barWidth > BAR_WIDTH_THRESHOLD_FOR_FONT_SIZE
        ? SG_BAR_LABEL_FONT_SIZE[1]
        : SG_BAR_LABEL_FONT_SIZE[0];

    return fontSize;
  }

  /**
   *  get rate label position
   */
  getRateLabelPositionX() {
    let barWidth = this.getBarWidth();

    let positionX = this.barOverlapDistance - (RATE_LABEL_WIDTH + barWidth) / 2;

    return positionX;
  }

  /**
   * set the number of Subject groups
   */
  setNumOfSG(numOfSG) {
    // console.log('setting numOfSG...', numOfSG);
    this.numberOfSG = numOfSG;
  }

  /**
   * overwrite the default getTooltip method
   */
  getTooltip() {
    const tooltip = super.getTooltip();

    // overwrite formatter
    const formatter = params => {
      // console.log("overwrite tooltip");
      // console.log(params);
      const value = params.data[params?.encode?.y[0]];
      const subTitle = this.labelFormatter(params.seriesName);
      // console.log("subTitle: ", subTitle);

      const dataStr = `<div style="padding: 0; margin: 0;">
        <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 2px 0 2.5px 0;">${params.name}</p>
        <p style="font-size: 12px; font-weight: 600; color: #848484; line-height: normal; margin: 0;">${subTitle}&nbsp;:&nbsp;${value}</p>
      </div>`;
      return dataStr;
    };

    // overwrite the tooltip position
    const dataIndexHigherTooltip = [3, 6];
    const position = getTooltipPos(dataIndexHigherTooltip);

    let newTooltip = { ...tooltip, formatter, position };
    return newTooltip;
  }

  /**
   * overwrite the default method
   */
  getCategoryAxisLabel() {
    const baseAxisLabel = super.getCategoryAxisLabel();

    const newAxisLabel = {
      ...baseAxisLabel,
      width: this.xAxisLabelWidth ?? undefined,
      overflow: this.isDownload ? 'break' : 'truncate', // If download, break label text into lines
    };

    return newAxisLabel;
  }

  // remove 'ALL' and 'OA' from label
  labelFormatter(label) {
    return label.replace(' ALL', '').replace(' OA', '');
  }

  /**
   * get correct legend
   */
  getSelectedLegend() {
    const bottom = this.isDownload
      ? LEGEND_PADDING_BOTTOM_DOWNLOAD
      : LEGEND_PADDING_BOTTOM;

    const baseLegend = super.getSelectedLegend();

    const legendLabels = {
      // ['All', 'OA']
      ...LEGEND_LABEL_STYLE,
      orient: 'vertical',
      top: undefined,
      bottom: bottom + 1,
      itemGap: 17,
      left: this.legend_pos + 10,
      data: [this.legendData[1], this.legendData[1 + 3]],
    };

    const legendCol1 = {
      ...baseLegend,
      orient: 'vertical',
      itemGap: 11,
      top: undefined,
      bottom,
      left: this.legend_pos + 80,
      data: [this.legendData[1], this.legendData[1 + 3]],
      formatter: this.labelFormatter,
    };
    const legendCol2 = {
      ...baseLegend,
      orient: 'vertical',
      itemGap: 11,
      top: undefined,
      bottom,
      left: this.legend_pos + 80 + 110,
      data: [this.legendData[2], this.legendData[2 + 3]],
      formatter: this.labelFormatter,
    };

    const legendCol3 = {
      ...baseLegend,
      orient: 'vertical',
      itemGap: 11,
      top: undefined,
      bottom,
      left: this.legend_pos + 80 + 110 * 2,
      data: [this.legendData[3], this.legendData[3 + 3]],
      formatter: this.labelFormatter,
    };

    const legends = [legendLabels, legendCol1, legendCol2, legendCol3];
    return legends;
  }

  setWidth(width) {
    this.width = width;

    if (width !== '100%') {
      const half_width = width / 2;
      this.legend_pos = half_width - 200;
    }

    return this;
  }
}

const getNumber = str => {
  return parseFloat(str) / 100;
};

/**
 *
 * @param {array[number]} dataIndexArr
 * @returns function // to get tooltip position
 */
const getTooltipPos = dataIndexArr => (point, params, dom, rect, size) => {
  const boxWidth = size.contentSize[0];
  const boxHeight = size.contentSize[1];
  console.log('***params: ', params);
  const dataIndex = params.encode.y[0];
  const x = rect.x + 0.5 * rect.width - 0.5 * boxWidth;
  const y = rect.y - boxHeight - (dataIndexArr.includes(dataIndex) ? 24 : 2); // 24px or 2px above the item
  return [x, y];
};

import { Box, Grid, InputLabel, Stack, TextField } from '@mui/material';
import BusinessDivisionTable from './BusinessDivision/BusinessDivisionTable';
import { FormattedMessage } from 'react-intl';
import { StyledSwitchTwoColor } from '../../ReportSpecificComponents/SpecialColorSwitch';
import { useEffect, useState } from 'react';
import SGTable from './SubjectGroup/subjectGroupTable';
import CeCatTable from './JournalCeCat/ceCatTable';
import JPMTable from './JPM/JPMTable';
import JournalOverviewDiagrams from './OverviewDiagrams';
import { DatePickerStack, GenerationDate } from '../../GenerationDate';
import ReportExportButton from '../../ReportSpecificComponents/ReportExportMenuButton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MonthPicker } from '@/components/DateRangePicker/MonthPicker';
import {
  selectDate as selectJournalOverviewDate,
} from '@/actions/SIMT-Report/Report/ReportAction';
import { useDispatch, useSelector } from 'react-redux';
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import {
  TableBox,
  TableGroupBox,
  TableLabel,
} from '../../ReportSpecificComponents/SpecialColorTableHeader';
import styles from '../../header-switch.module.scss';
import ExportAllDialog from '../../ExportAllDialog';
import ReportExportMenuButton from '../../ReportSpecificComponents/ReportExportMenuButton';
import { isReportOverCutOffDate } from '@/utils/commonUtils';
import {
  exportJournalBDCsv,
  exportJournalBDCsvWithFilenameFromBackEnd, exportJournalBDExcel,
  exportJournalJPMCsv,
  exportJournalJPMCsvWithFilenameFromBackEnd, exportJournalJPMExcel, exportJournalJPMExcelWithFilenameFromBackEnd
} from "@/actions/SIMT-Report/Report/ExportJournalReport";
import {GetJournalLastActionDate} from "@/actions/SIMT-Report/Report/ReportJournal";
import {
  exportReportJournalCeCatCsv,
  exportReportJournalCeCatCsvWithFilenameFromBackEnd,
  exportReportJournalCeCatExcel, exportReportJournalSGCsv,
  exportReportJournalSGCsvWithFilenameFromBackEnd, exportReportJournalSGExcel
} from "@/actions/SIMT-Report/ReportJournal/ExportJournalReport";
import {PERMISSIONS} from "@/constant/permission";

export default function JournalOverView({ showTable, setShowTable }) {
  // const [showTable, setShowTable] = useState(true);
  const dispatch = useDispatch();
  const { selectMonthDate, JournalReportGenenateDate, cutOffDate } =
    useSelector(state => {
      return {
        // selectMonthDate: state.Report.ReportJournalOverviewMonth,
        selectMonthDate: state.Report.selectDate,
        JournalReportGenenateDate: state.Report.JournalReportGenenateDate,
        cutOffDate: state.Report.cutOffDate,
      };
    });
  const [sgChecked, setSGChecked] = useState(true);
  const [bdChecked, setBDChecked] = useState(true);
  const [ceCatChecked, setCECatChecked] = useState(true);
  const [jpmChecked, setJpmChecked] = useState(true);
  const [selectMonth, setSelectMonth] = useState(new Date());

  const GenerationDateAndTime =
    'Below reports are generated on ' +
    JournalReportGenenateDate +
    ' (UTC time). ';

  const currentDate = new Date();

  useEffect(() => {
    if (selectMonthDate != null) {
      let Year = selectMonthDate.split('-')[0];
      let Month = selectMonthDate.split('-')[1] - 1;
      setSelectMonth(new Date(Year, Month));
    }
  }, [selectMonthDate]);

  const handleDateChange = value => {
    setSelectMonth(value);
    let year = value.getFullYear();
    let month = value.getMonth() + 1;
    let date = year + '-' + month;
    dispatch(selectJournalOverviewDate(date));
    dispatch(GetJournalLastActionDate(date, false));
  };

  const exportCsv = () => {
    let handledSelectMonthDate =
      Number(selectMonthDate.split('-')[0]) * 100 +
      Number(selectMonthDate.split('-')[1]);
    dispatch(exportReportJournalSGCsv(selectMonthDate));
    dispatch(exportJournalJPMCsv(handledSelectMonthDate));
    dispatch(exportReportJournalCeCatCsv(selectMonthDate));
    dispatch(exportJournalBDCsv(handledSelectMonthDate));
  };

  const exportCsvWithFilenameFromBackEnd = () => {
    let handledSelectMonthDate =
      Number(selectMonthDate.split('-')[0]) * 100 +
      Number(selectMonthDate.split('-')[1]);
    dispatch(exportReportJournalSGCsvWithFilenameFromBackEnd(selectMonthDate));
    if(!isReportOverCutOffDate(selectMonthDate, cutOffDate)){
      dispatch(
        exportJournalJPMCsvWithFilenameFromBackEnd(handledSelectMonthDate)
      );
    }
    if (localStorage.getItem('userPermissions').includes(PERMISSIONS.REPORT_READ_CE)){
      dispatch(
        exportReportJournalCeCatCsvWithFilenameFromBackEnd(selectMonthDate)
      );
    };
    dispatch(exportJournalBDCsvWithFilenameFromBackEnd(handledSelectMonthDate));
  };
  const exportExcel = () => {
    let handledSelectMonthDate =
      Number(selectMonthDate.split('-')[0]) * 100 +
      Number(selectMonthDate.split('-')[1]);
    // dispatch(exportReportJournalSGExcel(selectMonthDate));
    dispatch(exportJournalJPMExcel(handledSelectMonthDate));
    // dispatch(exportReportJournalCeCatExcel(selectMonthDate));
    // dispatch(exportJournalBDExcel(handledSelectMonthDate));
  };

  const exportExcelWithFilenameFromBackEnd = () => {
    let handledSelectMonthDate =
      Number(selectMonthDate.split('-')[0]) * 100 +
      Number(selectMonthDate.split('-')[1]);
    dispatch(
      exportJournalJPMExcelWithFilenameFromBackEnd(handledSelectMonthDate)
    );
  };

  useEffect(() => {
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    let newDate = '' + year + '-' + month;
    if (selectMonthDate === null) {
      dispatch(selectJournalOverviewDate(newDate));
      dispatch(GetJournalLastActionDate(newDate, false));
    }
  }, []);
  const [openExportAllDialog, setOpenExportAllDialog] = useState(false);
  return (
    <>
      <ExportAllDialog
        open={openExportAllDialog}
        handleClose={() => setOpenExportAllDialog(false)}
        filterData={[]}
        handleCSV={exportCsvWithFilenameFromBackEnd}
        handleExcel={exportExcelWithFilenameFromBackEnd}
      />
      {/* PageHead */}
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        id='reportJournalSubjectGroupGrid'
        data-selenium-id='Report_Journal-PageHeadContainer'
        sx={{
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        <DatePickerStack
          id='Report_Journal-DatePickerStack'
          selectedMonth={selectMonth}
          handleDateChange={handleDateChange}
          lastActionDate={JournalReportGenenateDate}
          showTable={true}
          GenerationDateAndTime={GenerationDateAndTime}
        />
        {/* exportButton */}
        <Grid
          id='reportJournalOverviewGrid'
          data-selenium-id='Report_Journal-BtnContainer'
          // sx={{ m: '0px 6px 0px 6px' }}
          className={styles['action-buttons-container']}
        >
          {showTable ? (
            <ReportExportMenuButton
              sx={{ height: '32px', marginRight: '24px' }}
              id='reportSipipelineExportButton'
              mode={1}
              // label='common.Export'
              onClick={() => {
                setOpenExportAllDialog(true);
              }}
            />
          ) : null}

          <span className={styles['switch']}>
            <span
              id='show-table-button'
              className={styles[showTable ? 'selected' : '']}
              onClick={() => setShowTable(true)}
            >
              {/* <FormattedMessage id="common.exportAll" /> */}
              Table
            </span>

            <span
              id='show-diagram-button'
              className={styles[!showTable ? 'selected' : '']}
              onClick={() => setShowTable(false)}
              style={{ maxHeight: '32px' }}
            >
              {/* <FormattedMessage id="common.exportAll" /> */}
              Diagram
            </span>
          </span>
        </Grid>
      </Grid>
      {/* PageContent */}
      {showTable ? (
        <>
          {/* SubjectGroup */}
          <TableGroupBox data-selenium-id='Report_Journal-SubjectGroupContainer'>
            <Grid
              container
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              id='reportJournalSubjectGroupGrid'
              data-selenium-id='Report_Journal-SubjectGroup-HeaderContainer'
            >
              <TableLabel>
                <FormattedMessage
                  id={'report.Journal.subjectGroup'}
                  data-selenium-id='Report_Journal-SubjectGroup-TitleText'
                />
              </TableLabel>
              <Grid
                sx={{ p: '25px 30px 0px 6px' }}
                data-selenium-id='Report_Journal-SubjectGroup-SwitchContainer'
              >
                <StyledSwitchTwoColor
                  id='reportJournalSubjectGroupAllOASwitch'
                  data-selenium-id='Report_Journal-SubjectGroup-SwitchBar'
                  checked={sgChecked}
                  onChange={e => {
                    setSGChecked(e.target.checked);
                  }}
                ></StyledSwitchTwoColor>
              </Grid>
            </Grid>
            <>
              <SGTable
                id='reportJournalSubjectGroupTable'
                data-selenium-id='Report_Journal-SGTable'
                isCheck={sgChecked}
              />
            </>
          </TableGroupBox>
          {/* BusinessDivisionTable */}
          <TableGroupBox data-selenium-id='Report_Journal-BusinessDivisionContainer'>
            <Grid
              container
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              data-selenium-id='Report_Journal-BusinessDivision-HeaderContainer'
            >
              <TableLabel>Business Division</TableLabel>
              <Grid
                sx={{ p: '25px 30px 0px 6px' }}
                data-selenium-id='Report_Journal-BusinessDivision-SwitchContainer'
              >
                <>
                  <StyledSwitchTwoColor
                    checked={bdChecked}
                    onChange={e => {
                      setBDChecked(e.target.checked);
                    }}
                    data-selenium-id='Report_Journal-BusinessDivision-SwitchBar'
                  ></StyledSwitchTwoColor>
                </>
              </Grid>
            </Grid>
            <>
              <BusinessDivisionTable
                isOA={bdChecked}
                data-selenium-id='Report_Journal-BusinessDivisionTable'
              ></BusinessDivisionTable>
            </>
          </TableGroupBox>
          {/* CeCatTable */}
          <ShowForPermission permission={`${PERMISSIONS.CAT_REPORT_READ} ${PERMISSIONS.REPORT_READ_CE}`}>
            <TableGroupBox data-selenium-id='Report_Journal-CeCatConatiner'>
              <Grid
                container
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                id='reportJournalCeCatGrid'
                data-selenium-id='Report_Journal-CeCat-HeaderContainer'
              >
                <TableLabel>
                  <FormattedMessage
                    id={'report.ceCat'}
                    data-selenium-id='Report_Journal-CeCatText'
                  />
                </TableLabel>
                <Grid
                  sx={{ p: '25px 30px 0px 6px' }}
                  data-selenium-id='Report_Journal-CeCat-SwitchContainer'
                >
                  <StyledSwitchTwoColor
                    id='reportJournalCeCatOAALLSwitch'
                    data-selenium-id='Report_Journal-CeCat-SwitchBar'
                    checked={ceCatChecked}
                    onChange={e => {
                      setCECatChecked(e.target.checked);
                    }}
                  ></StyledSwitchTwoColor>
                </Grid>
              </Grid>
              <>
                <CeCatTable
                  isCheck={ceCatChecked}
                  data-selenium-id='Report_Journal-CeCatTable'
                />
              </>
            </TableGroupBox>
          </ShowForPermission>
          {!isReportOverCutOffDate(selectMonthDate, cutOffDate) && (
            <TableGroupBox data-selenium-id='Report_Journal-JPMContainer'>
              <Grid
                container
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                data-selenium-id='Report_Journal-JPM-HeaderContainer'
              >
                <TableLabel>JPM</TableLabel>
                <Grid
                  id='reportJournalJPMSwitch'
                  data-selenium-id='Report_Journal-JPM-SwitchContainer'
                  sx={{ p: '25px 30px 0px 6px' }}
                >
                  <>
                    <StyledSwitchTwoColor
                      checked={jpmChecked}
                      onChange={e => {
                        setJpmChecked(e.target.checked);
                      }}
                      data-selenium-id='Report_Journal-JPM-SwitchBar'
                    ></StyledSwitchTwoColor>
                  </>
                </Grid>
              </Grid>
              <>
                <JPMTable
                  isOA={jpmChecked}
                  data-selenium-id='Report_Journal-JPMTable'
                ></JPMTable>
              </>
            </TableGroupBox>
          )}
        </>
      ) : (
        <JournalOverviewDiagrams />
      )}
    </>
  );
}

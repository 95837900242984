import { OAVsTotalBarChartOptions } from '@/pages/Report/chartUtils';
/**
 * overwrite the default configuration
 */
const BARCHART_HEIGHT = 390;
const BAR_COLOR = [
  ['#4C81EB', '#BCC5CF'],
  ['#113D95', '#98A7B6'],
  ['#FF8180', '#98A7B6'],
];
const BAR_LABEL_COLOR = ['#FFFFFF', '#596A7C'];
const LABLE_SIZE = [15, 12, 9];
const MAX_LABEL_SIZE = 18;

export class AAndAOverviewOptions extends OAVsTotalBarChartOptions {
  constructor(catagoryAttributeName, valueAttributeNames, barSeriesValue) {
    super();
    // overwrite the default properties
    this.barchartHeight = BARCHART_HEIGHT;
    this.catagoryAttributeName = catagoryAttributeName;
    this.valueAttributeNames = valueAttributeNames[0];
    this.valueAttributeNamesOA = valueAttributeNames[1];
    this.barSeriesValueOA = barSeriesValue[0];
    this.barSeriesValueOO = barSeriesValue[1];
    this.barColorSet = this.getBarColor(this.barSeriesValueOA); // get bar color
    this.rightBarLabelColor = this.getRightBarLabelColor(this.barSeriesValueOA); // get bar label color
  }

  getBarColor(barSeriesValueOA) {
    if (!barSeriesValueOA) {
      return BAR_COLOR[0];
    }
    let colorIndex = 0; // default
    if (barSeriesValueOA.indexOf('Acquired') >= 0) {
      if (barSeriesValueOA.indexOf('YTD') === -1) {
        // Acquired SI monthly
        colorIndex = 0;
      } else {
        // Acquired SI YTD
        colorIndex = 1;
      }
    } else {
      // Active SI
      colorIndex = 2;
    }
    return BAR_COLOR[colorIndex];
  }

  getRightBarLabelColor(barSeriesValueOA) {
    let colorIndex = 0; // default
    if (
      barSeriesValueOA &&
      barSeriesValueOA.indexOf('Acquired') >= 0 &&
      barSeriesValueOA.indexOf('YTD') === -1
    ) {
      // Acquired SI monthly
      colorIndex = 1;
    }
    return BAR_LABEL_COLOR[colorIndex];
  }

  getBarSeries() {

    return [
      {
        ...this.barSerieOption,
        barMaxWidth: 150,
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'inside',
              color: '#FFFFFF',
              // fontSize: 12,
              fontSize: this.getLabelMinFontSize(),

              verticalAlign: 'left',
              lineHeight: 2,

              formatter: params => {
                let position = params.seriesIndex;
                if (params.seriesName.substring(0, 12) == 'Active SI OA') {
                  position = 'activeSiOa';
                } else if (
                  params.seriesName.substring(0, 12) == 'Active SI OO'
                ) {
                  position = 'activeSi';
                } else if (
                  params.seriesName.substring(0, 18) == 'Acquired SI OO-YTD'
                ) {
                  position = 'acquiredYtd';
                } else if (
                  params.seriesName.substring(0, 18) == 'Acquired SI OA-YTD'
                ) {
                  position = 'acquiredYtdOa';
                } else if (
                  params.seriesName.substring(0, 14) == 'Acquired SI OO'
                ) {
                  position = 'acquiredSi';
                } else {
                  position = 'acquiredSiOa';
                }

                if (params.data[position] == 0) {
                  return '';
                }
                return params.data[position];
              },
            },
          },
        },
        name: this.barSeriesValueOA,
        stack: 'total',
        encode: { x: this.valueAttributeNames, y: this.catagoryAttributeName },
        color: this.barColorSet[0],
      },
      {
        ...this.barSerieOption,
        barMaxWidth: 150,
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'inside',
              color: this.rightBarLabelColor,
              // fontSize: 12,
              fontSize: this.getLabelMinFontSize(),

              verticalAlign: 'left',
              lineHeight: 2,

              formatter: params => {
                let position = params.seriesIndex;
                if (params.seriesName.substring(0, 12) == 'Active SI OA') {
                  position = 'activeSiOa';
                } else if (
                  params.seriesName.substring(0, 12) == 'Active SI OO'
                ) {
                  position = 'activeSi';
                } else if (
                  params.seriesName.substring(0, 18) == 'Acquired SI OO-YTD'
                ) {
                  position = 'acquiredYtd';
                } else if (
                  params.seriesName.substring(0, 18) == 'Acquired SI OA-YTD'
                ) {
                  position = 'acquiredYtdOa';
                } else if (
                  params.seriesName.substring(0, 14) == 'Acquired SI OO'
                ) {
                  position = 'acquiredSi';
                } else {
                  position = 'acquiredSiOa';
                }

                if (params.data[position] == 0) {
                  return '';
                }
                return params.data[position];
              },
            },
          },
        },
        name: this.barSeriesValueOO,
        stack: 'total',
        encode: {
          x: this.valueAttributeNamesOA,
          y: this.catagoryAttributeName,
        },
        color: this.barColorSet[1],
      },
    ];
  }

  getTooltip() {
    return [
      {
        ...this.tooltip,
        formatter: params => {
          // console.log("overwrite tooltip")
          // console.log(params)
          let position = params.seriesIndex;
          if (params.seriesName.substring(0, 12) == 'Active SI OA') {
            position = 'activeSiOa';
          } else if (params.seriesName.substring(0, 12) == 'Active SI OO') {
            position = 'activeSi';
          } else if (
            params.seriesName.substring(0, 18) == 'Acquired SI OO-YTD'
          ) {
            position = 'acquiredYtd';
          } else if (
            params.seriesName.substring(0, 18) == 'Acquired SI OA-YTD'
          ) {
            position = 'acquiredYtdOa';
          } else if (params.seriesName.substring(0, 14) == 'Acquired SI OO') {
            position = 'acquiredSi';
          } else {
            position = 'acquiredSiOa';
          }
          let dataStr = `<div style="padding: 0; margin: 0;">
            <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 0 0 2.5px 0;">${params.name}</p>
            <p style="font-size: 10px; font-weight: 400;color: #848484; line-height: 14px; margin: 0;">${params.seriesName}&nbsp;:&nbsp;${params.data[position]}</p>
          </div>`;
          return dataStr;
        },
      },
    ];
  }

  getBarchartHeight() {
    return this.barchartHeight;
  }

  getLabelMinFontSize() {
    // console.log('=== this.dataForBar===', this.dataForBar);
    const numberOfBars = this.dataForBar && this.dataForBar.length;

    let fontSize;

    if (numberOfBars < 7) {
      fontSize = LABLE_SIZE[0];
    } else if (numberOfBars < 12) {
      fontSize = LABLE_SIZE[1];
    } else {
      fontSize = LABLE_SIZE[2];
    }

    return fontSize;
  }

  getLabelFontSize() {
    return [this.getLabelMinFontSize(), MAX_LABEL_SIZE];
  }
}

import { useState, useEffect, useMemo, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Box } from '@mui/material';
import styles from './index.module.scss';
import CloseIcon from '@/assets/CloseIcon.svg';
import { SingleSelect } from '@/components/Chart/ChartSelectors';
import ReportSaveMenuButton from '@/components//Chart/chartButton';
import { ReportDownloadMenuButton } from '@/components/Chart/chartButton';
import ChartAAndA from './Chart';
import NoDataPage from '@/components/Chart/NoDataPage';
import LoadingPage from '@/components/Chart/LoadingPage';
import ErrorPage from '@/components/Chart/ErrorPage';
import useGetCategoryListHook from '@/pages/Report/utils/useGetCategoryListHook';
import useGetChartsDataHook from './hooks';
import useGetDiagramStatusHook from './hooks/useGetDiagramStatusHook';
import { useSaveUserSelectionHook } from '@/pages/Report/utils/useSaveUserSelectionHook';
import { useDispatch } from 'react-redux';
import { saveImageAsPng, saveImageAsJpg } from '@/pages/Report/chartUtils';
import {
  saveUserSelectedItem,
  getReportExcelParam, // get excel file
} from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import {
  FY_TRENDS_OF_SI_ACQUISITION,
  EXCEL_HEADER,
  ACQUIRED,
  ACTIVE,
} from '../Constants/index.js';
import {
  LOADING,
  ERROR,
  VALID,
  NO_DATA,
  NO_SELECTION_FROM_DROPDOWN_MENU,
} from '@/pages/Report/chartUtils/constants.js';
import { getDownloadFileName } from '@/pages/Report/utils/getDownloadFileName';

const ChartContainer = ({
  tabName,
  header,
  subHeader,
  cancelSelectedChart,
  selectedFY = 'FY23',
  hasAdditionalFilter = false,
  catagoryAttributeName,
  url,
  singleSelectListUrl,
  downloadUrl,
  tableDownloadTitle = null,
}) => {
  const dispatch = useDispatch();

  const reduxListTitle = useMemo(() => {
    return `${tabName}/${header}/${subHeader}/${selectedFY}/Dropdown Menu`;
  }, [header, subHeader, selectedFY]);

  /**
   * 1 get all subject groups / business divisions for articles of the selected FY
   */
  const [itemList, loadingMenu, errorMenu] = useGetCategoryListHook(
    singleSelectListUrl,
    reduxListTitle,
    selectedFY
  );

  /**
   * 2 get & save selected SG/BD
   */
  const reduxUserSelectTitle = `${tabName}/${subHeader}/user selected`;

  const selectedItem = useSaveUserSelectionHook(reduxUserSelectTitle, itemList);

  const saveSelectedSGToStore = selectedItem => {
    dispatch(saveUserSelectedItem(reduxUserSelectTitle, selectedItem));
  };

  /**
   * 3 update chart title
   */
  const chartTitle = useMemo(() => {
    if (header == FY_TRENDS_OF_SI_ACQUISITION) {
      return `Month-on-Month SI Acquisition ${
        selectedItem ? 'of ' + selectedItem : ''
      } in ${selectedFY}`;
    } else {
      return `Month-on-Month Active SI ${
        selectedItem ? 'of ' + selectedItem : ''
      } in ${selectedFY}`;
    }
  }, [selectedItem, selectedFY, header]);

  /**
   * 5 retrieve data of selected chart
   */
  const [barchartData, loading, error] = useGetChartsDataHook(
    selectedFY,
    catagoryAttributeName,
    url,
    chartTitle,
    selectedItem
  );

  const isEmptyList = useMemo(() => {
    return !itemList || (Array.isArray(itemList) && itemList.length === 0);
  }, [itemList]);

  /**
   * check if there is no data for selected subject group / business division
   *
   * will disable dropdown list, download button and export button
   */
  const isEmptyData = useMemo(() => {
    return (
      !barchartData ||
      (Array.isArray(barchartData) && barchartData.length === 0)
    );
  }, [barchartData]);

  // new diagram status hook
  const alertLabel = subHeader;
  const { valid: digramStatus, info } = useGetDiagramStatusHook(
    barchartData,
    selectedItem,
    alertLabel,
    loadingMenu,
    errorMenu,
    loading,
    error
  );

  const [chartWidth, setChartWidth] = useState('100%');
  // for chart download
  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);
  const downloadlink = useRef(); // ADDED

  // dependencies: Tab1, Tab2, Level, selected time,
  const fileName = useMemo(() => {
    const tab1Name = tableDownloadTitle;
    const tab2Name = 'FY Trends';
    const level = selectedItem;
    return getDownloadFileName(tab1Name, tab2Name, level, selectedFY);
  }, [tableDownloadTitle, selectedItem, selectedFY]);

  // const downloadChart = () => {
  //   dispatch(
  //     GetAAndAFYTrendsExcel(downloadUrl, subHeader, selectedFY, fileName)
  //   );
  // };

  const downloadChart = () => {
    console.log('tableDownloadTitle: ', tableDownloadTitle);
    const type = tableDownloadTitle.toLowerCase().includes('active') ?   ACTIVE : ACQUIRED;
    const params = {
      // prepare the request body
      selectFy: selectedFY,
      [catagoryAttributeName === 'sg' ? 'subjectGroup' : catagoryAttributeName]:
        selectedItem,
      exportHeader: EXCEL_HEADER[type].exportHeader,
      partHeader: EXCEL_HEADER[type].partHeader,
    };
    dispatch(getReportExcelParam(selectedFY, downloadUrl, fileName, params));
  };

  const savePng = () => {
    saveImageAsPng(chartRef.current, fileName);
  };

  const saveJpg = () => {
    saveImageAsJpg(chartRef.current, fileName);
  };

  // listen to chart-container size change
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      resizeHandler(entries);
    });
    resizeObserver.observe(chartContainerRef.current);
    return () => {
      if (chartContainerRef.current) {
        resizeObserver.unobserve(chartContainerRef.current);
      }
    };
  }, []);

  function resizeHandler(entries) {
    const chartContainer = entries.find(
      entry => entry.target == chartContainerRef.current
    );
    const width = chartContainer?.target.clientWidth;
    setChartWidth(width);
  }

  return (
    <div className={styles['chart-container']} ref={chartContainerRef}>
      <Box className={styles['chart-header']}>
        <div className={styles['selectors']}>
          {hasAdditionalFilter && (
            <span id='SG-select'>
              <SingleSelect
                label={subHeader}
                alertLabel={subHeader}
                options={itemList ? itemList : []}
                selectedItem={selectedItem}
                setSelectedItem={saveSelectedSGToStore}
                disabled={isEmptyList}
              />
            </span>
          )}
        </div>
        <div className={styles['chart-titles']}>
          <h1 className={styles['chart-title']}>{chartTitle}</h1>
        </div>
        <div className={styles['buttons']}>
          <ReportDownloadMenuButton
            func={downloadChart}
            disabled={isEmptyList || digramStatus !== VALID}
          />
          <ReportSaveMenuButton
            items={[
              {
                label: 'Save as PNG',
                operation: savePng,
              },
              {
                label: 'Save as JPG',
                operation: saveJpg,
              },
            ]}
            disabled={isEmptyList || digramStatus !== VALID}
          />
          <button
            onClick={cancelSelectedChart}
            className={styles['button-close']}
          >
            <CloseIcon />
          </button>
        </div>
      </Box>

      {digramStatus === LOADING && <LoadingPage />}

      {digramStatus === ERROR && <ErrorPage />}

      {digramStatus === NO_DATA && (
        <NoDataPage
          title={`There is no data for any ${info}s on the selected date.`}
          subTitle={'Please choose a different date to view diagram.'}
        />
      )}

      {digramStatus === NO_SELECTION_FROM_DROPDOWN_MENU && (
        <NoDataPage title={`Please select at least one ${info}.`} />
      )}

      {digramStatus === VALID && (
        <>
          <Box className={styles['chart']} ref={chartRef}>
            <ChartAAndA
              isEmptyList={isEmptyList}
              isEmptyData={isEmptyData}
              disabled={isEmptyList || isEmptyData}
              alertLabel={subHeader == 'CE' ? 'Handling CE' : subHeader}
              barchartData={barchartData}
              width={chartWidth}
              hasZoom={true}
              hasTitle={false}
              catagoryAttributeName={catagoryAttributeName}
              header={header}
            />
          </Box>
          <Box className={styles['chart-download']} ref={chartRef}>
            <ChartAAndA
              isEmptyList={isEmptyList}
              isEmptyData={isEmptyData}
              disabled={isEmptyList || isEmptyData}
              alertLabel={subHeader == 'CE' ? 'Handling CE' : subHeader}
              barchartData={barchartData}
              width={chartWidth}
              hasZoom={false}
              hasTitle={true}
              title={chartTitle}
              // subTitle={subHeader + " Level"}
              catagoryAttributeName={catagoryAttributeName}
              header={header}
            />
          </Box>
          <a
            href='javascript:void(0)'
            download
            ref={downloadlink}
            className={styles['download-link']}
          >
            hidden download link
          </a>
        </>
      )}
    </div>
  );
};

ChartContainer.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  selectedMonth: PropTypes.string.isRequired,
  cancelSelectedChart: PropTypes.func.isRequired,
  showSubjectGroupsSelector: PropTypes.bool,
};

export default ChartContainer;

import React, { useState, useEffect, useRef } from 'react';

import { AllCentered } from '../CommonStyle';

import { formatMonth, getDateObj, getDateStr, getNewParams } from './Utils';

import ArrowLeft from '../SVGComponents/ArrowLeft.svg';
import ArrowRight from '../SVGComponents/ArrowRight.svg';
import YearArrowLeft from '../SVGComponents/YearArrowLeft.svg';
import YearArrowRight from '../SVGComponents/YearArrowRight.svg';
import { Box, Button, Stack, ThemeProvider, Typography } from '@mui/material';
import { themeButton, themeTextField } from '../CommonTheme';
import { SmallBoldFont, SmallFont } from '@/components/FontStyle';
import YearPage from './YearPage';
import MonthPage from './MonthPage';
import DayPage from './DayPage';
import DatePickerDefaultIcon from '../SVGComponents/DatePickerDefaultIcon.svg';
import { GetPopperBottomContext } from '@/componentsphase2/Autocomplete/CommonContext';
import DatePickerHover from '@/assets/DatePickerHover.svg';
import styled from '@emotion/styled';

const FromToTypography = styled(Typography)`
  color: var(--gray-500, #596a7c);
  text-align: center;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const ToTypography = styled(Typography)`
  color: var(--gray-400, #98a7b6);
  leading-trim: both;
  text-edge: cap;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 17.5px */
`;

const IconButton = props => {
  const { Icon, ...other } = props;
  return (
    <ThemeProvider theme={themeButton('#FFFFFF')}>
      <Button
        disableTouchRipple
        sx={{
          display: 'flex',
          width: '14px',
          height: '14px',
          minWidth: '0px',
          borderRadius: '2px',
          padding: '0px',
        }}
        {...other}
      >
        {Icon}
      </Button>
    </ThemeProvider>
  );
};

const getNowDate = (monthOnly = false) => {
  const nowDate = new Date();
  const year = nowDate.getFullYear();
  const month = nowDate.getMonth() + 1;
  const day = nowDate.getDate();
  if (monthOnly) {
    return `${year}-${month < 10 ? `0${month}` : `${month}`}`;
  }
  return `${year}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;
};

const MonthPicker = props => {
  const {
    title,
    value,
    onChange,
    defaultValue = '',
    maxDateStr = '',
    minDateStr = '',
  } = props;

  let initNewParam =
    defaultValue === ''
      ? getNewParams(getNowDate())
      : getNewParams(defaultValue);

  const limit = props.limit === undefined ? false : props.limit;

  const [plate, setPlate] = useState(2); // 板块状态 1:年，2：月，3：日
  const [temYear, setTemYear] = useState(initNewParam.year);
  const [temMonth, setTemMonth] = useState(initNewParam.month);
  const [year, setYear] = useState(initNewParam.year);
  const [month, setMonth] = useState(initNewParam.month);
  const yearRef = useRef();
  const monthRef = useRef();

  const LabelButton = props => {
    const { plateIdx, label, ...other } = props;
    return (
      <ThemeProvider theme={themeButton('#DFE4E8')}>
        <Button
          sx={{
            display: 'flex',
            width: '56px !important',
            height: '22px',
            minWidth: '0px',
            borderRadius: '2px',
            padding: '0px',
            ...SmallBoldFont,
            lineHeight: '19px',
            color: '#596A7C',
            leadingTrim: 'both',
            textEdge: 'cap',
            textTransform: 'none',
          }}
          onClick={() => {
            setPlate(plateIdx);
          }}
          {...other}
        >
          {label}
        </Button>
      </ThemeProvider>
    );
  };

  function setNewTime(time) {

    const timeToSet =
      time === null || time === '' ? initNewParam : getNewParams(time + '-01');
    setYear(timeToSet.year);
    setTemYear(timeToSet.year);
    setMonth(timeToSet.month);
    setTemMonth(timeToSet.month);
  }

  useEffect(() => {
    setNewTime(value);
  }, [value]);

  function yearChange(params) {
    setYear(params.value);
    setTemYear(params.value);
    setPlate(2);
  }
  // change 月
  function monthChange(params, temYear) {
    setMonth(params.value);
    setYear(temYear);
    let newMonth = params.value < 10 ? '0' + params.value : params.value;
    // Attention update not real time
    onChange(`${temYear}-${newMonth}`);
  }

  // setNewTime(value);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <FromToTypography>{title}</FromToTypography>
      </Box>

      {/* 年/月切换 */}
      {/* DatePickerHeader */}
      <Box
        sx={{
          marginTop: '16px',
          marginBottom: '30px',
          width: '244px',
          height: '22px',
          ...AllCentered,
          display: plate === 1 ? 'none' : 'flex',
        }}
      >
        <IconButton
          Icon={plate === 2 ? <YearArrowLeft /> : <ArrowLeft />}
          onClick={() => {
            if (plate === 2) {
              yearRef.current.reduceFn();
            } else {
              monthRef.current.reduceFn();
            }
          }}
        ></IconButton>
        <Box sx={{ width: 'calc(100% - 28px)', ...AllCentered }}>
          <Stack spacing={1.5} direction='row'>
            <LabelButton plateIdx={1} label={temYear}></LabelButton>
          </Stack>
        </Box>
        <IconButton
          Icon={plate === 2 ? <YearArrowRight /> : <ArrowRight />}
          onClick={() => {
            if (plate === 2) {
              yearRef.current.addFn();
            } else {
              monthRef.current.addFn();
            }
          }}
        ></IconButton>
      </Box>
      {/* 日期列表 */}
      <Box>
        <YearPage
          ref={yearRef}
          change={yearChange.bind(this)}
          active={year}
          visible={plate === 1}
          temYear={temYear}
          setTemYear={setTemYear}
          maxDate={getDateObj(maxDateStr)}
          minDate={getDateObj(minDateStr)}
        />
        <MonthPage
          ref={monthRef}
          change={monthChange.bind(this)}
          year={year}
          active={month}
          visible={plate === 2}
          limit={limit}
          temYear={temYear}
          setTemYear={setTemYear}
          temMonth={temMonth}
          setTemMonth={setTemMonth}
          maxDate={getDateObj(maxDateStr)}
          minDate={getDateObj(minDateStr)}
          rangeModeFormat={true}
        />
      </Box>
    </div>
  );
};

const calculateInMonth = (increment, date) => {
  let newDate = new Date(date);
  if (isNaN(newDate)) {
    return '';
  }
  newDate.setMonth(newDate.getMonth() + increment);
  return getDateStr(newDate).slice(0, 7);
};


const CustomMonthRangePicker = props => {
  const {
    id,
    value,
    onChange,
    //Not important
    error = false,
    defaultValue = '',
    handleFocus = () => {},
    handleBlur = () => {},
    height = '33px',
    ifdataOpen = false,
    dateOpen,
    setDateOpen,
  } = props;

  const placeholder = props.placeholder || '请选择日期';
  const [startValue, setStartValue] = useState(
    value !== '' ? value.split('To')[0] : ''
  );
  const [endValue, setEndValue] = useState(
    value !== '' ? value.split('To')[1] : ''
  );

  useEffect(() => {
    setStartValue(value !== '' ? value.split('To')[0] : '');
    setEndValue(value !== '' ? value.split('To')[1] : '');
  }, [value]);

  useEffect(() => {
    if (startValue !== '' || endValue !== '') {
      onChange(startValue + 'To' + endValue);
    }
  }, [startValue, endValue]);

  const [visible, setVisible] = useState(false);
  const [stateHover, setStateHover] = useState(false);
  const setPopperBottom = React.useContext(GetPopperBottomContext).setValue;

  useEffect(() => {
    if (visible) {
      let myPopper = document.getElementById(`${id}_datepicker_popper`);
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (myPopper) {

        const bottom =
          myPopper.getBoundingClientRect().top +
          myPopper.offsetHeight +
          scrollTop;

        setPopperBottom(bottom);
      }
    } else {
      setPopperBottom(null);
    }
  }, [visible]);

  return (
    <div
      style={{ position: 'relative', width: '100%' }}
      id={`${id}_datepicker_box`}
      data-selenium-id={`${id}_datepicker_box`}
    >
      <Box
        id={`${id}_datepicker_input_box`}
        data-selenium-id={`${id}_datepicker_input_box`}
        onClick={() => {
          if (!visible) {
            handleFocus();
          }
          setVisible(!visible);
          if (ifdataOpen) setDateOpen(!dateOpen);
        }}
        sx={{
          width: '100%',
          height: height,
          opacity: '1',
          background: '#ffffff',
          border: error
            ? '2px solid #EE5350'
            : visible
            ? '2px solid #154AB6'
            : stateHover
            ? '1px solid #262E35'
            : value && value !== ''
            ? '1px solid #98A7B6'
            : '1px solid #DFE4E8',
          padding: '11px 12px',
          boxSizing: 'border-box',
          borderRadius: '4px',
          position: 'relative',
          ...AllCentered,
          '& input::placeholder': {
            color: '#BCC5CF',
            opacity: '1',
          },
        }}
        onMouseEnter={() => {
          setStateHover(true);
        }}
        onMouseLeave={() => {
          setStateHover(false);
        }}
      >
        <input
          id={`${id}_datepicker_input`}
          data-selenium-id={`${id}_datepicker_input`}
          placeholder={placeholder}
          type='text'
          value={startValue}
          readOnly
          style={{
            border: 'none',
            outline: 'none',
            width: '53px',
            ...SmallFont,
            lineHeight: '125%',
            color: '#262E35',
            height: '18px',
            padding: '0px',
            marginRight: '10px',
          }}
        />
        <ToTypography>To</ToTypography>
        <input
          id={`${id}_datepicker_input`}
          data-selenium-id={`${id}_datepicker_input`}
          placeholder={'yyyy-mm'}
          type='text'
          value={endValue}
          readOnly
          style={{
            border: 'none',
            outline: 'none',
            width: '53px',
            ...SmallFont,
            lineHeight: '125%',
            color: '#262E35',
            height: '18px',
            padding: '0px',
            marginLeft: '10px',
            marginRight: '6px',
          }}
        />
        <IconButton
          id={`${id}_datepicker_input_endAdornment`}
          data-selenium-id={`${id}_datepicker_input_endAdornment`}
          Icon={
            visible || stateHover ? (
              <DatePickerHover />
            ) : (
              <DatePickerDefaultIcon />
            )
          }
          sx={{
            width: '16px',
            height: '16px',
            minWidth: '0px',
            borderRadius: '2px',
            padding: '0px',
          }}
        ></IconButton>
      </Box>
      {visible ? (
        <Box
          id={`${id}_datepicker_bg`}
          data-selenium-id={`${id}_datepicker_bg`}
          onClick={() => {
            handleBlur();
            setVisible(false);
            if (ifdataOpen) setDateOpen(false);
          }}
          sx={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100vw',
            height: '100vh',
            zIndex: '2021',
          }}
        />
      ) : null}
      {visible ? (
        <Box
          id={`${id}_datepicker_popper`}
          data-selenium-id={`${id}_datepicker_popper`}
          sx={{
            display: visible ? 'block' : 'none',
            position: 'absolute',
            top: '46px',
            // right: '0px',
            zIndex: '2022',
            width: '606px',
            height: 'auto',
            padding: '16px 24px 20px 24px',
            boxSizing: 'border-box',
            background: '#FFFFFF',
            boxShadow: '0px 6px 12px rgba(38, 46, 53, 0.12)',
            borderRadius: '8px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
            }}
          >
            {/* Start Month*/}
            <Box
              sx={{
                width: '252px',
              }}
            >
              <MonthPicker
                title={'From:'}
                onChange={setStartValue}
                value={startValue}
                defaultValue={defaultValue}
                maxDateStr={endValue}
              />
            </Box>
            {/* END Month */}
            <Box
              sx={{
                marginLeft: '54px',
                width: '252px',
              }}
            >
              <MonthPicker
                title={'To:'}
                onChange={setEndValue}
                value={endValue}
                defaultValue={defaultValue}
                // startValue+1 2023-08 => 2023-09
                minDateStr={calculateInMonth(1, startValue)}
              />
            </Box>
          </Box>
        </Box>
      ) : null}
    </div>
  );
};

export default CustomMonthRangePicker;

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {GetAAAnualTrendsChartDataWithStatus} from "@/actions/SIMT-Report/ReportDiagram/ReportAcquireAndActive";
import {replaceZero} from "@/pages/Report/utils/reportUtils";

// Remember to start the name of your custom hook with "use"
const useChartsDataHook = (
  type,
  selectedCatagories,
  url,
  title,
  selectedDate
) => {
  const dispatch = useDispatch();

  let { reportDataAPI } = useSelector(state => {
    return {
      reportDataAPI: state.ReportDiagram[title],
    };
  });

  let loading = null,
    error = null,
    data = null;

  if (typeof reportDataAPI !== 'undefined') {
    ({ loading, error, data } = reportDataAPI);
  }
  replaceZero(data);

  const dataForChart = useMemo(() => {
    return data;
  }, [data]);

  useEffect(() => {
    if (typeof reportDataAPI === 'undefined' && selectedDate !== null) {
      dispatch(
        GetAAAnualTrendsChartDataWithStatus(url, title, selectedDate, type)
      );
    }
  }, [url, title, selectedDate, type]);

  // get a full list of categories (e.g. subject groups)
  const catagoriesFullList = useMemo(() => {
    // valid if has at least 1 category,
    if (Array.isArray(dataForChart) && dataForChart.length > 1) {
      // index 0 is 'subject group'
      const categories = dataForChart
        .map(categoryEntry => categoryEntry[0])
        .slice(1);
      return categories;
    }
    return [];
  }, [dataForChart]);

  // filter only the selected records
  const selectedDataForBar = useMemo(() => {
    // if (dataForChart != undefined && dataForChart != null) {
    if (dataForChart) {
      return filterSelectedData(dataForChart, selectedCatagories);
    }
    return [];
  }, [dataForChart, selectedCatagories]);

  // return [catagoriesFullList, selectedDataForBar, dataForChart];
  return [catagoriesFullList, selectedDataForBar, dataForChart, loading, error];
};

function filterSelectedData(catagoryEntries, selectedCatagories) {
  const selectedCatagoryEntries = catagoryEntries.filter(
    (catagoryEntry, index) => {
      if (index === 0) return true; // labels
      return selectedCatagories.indexOf(catagoryEntry[0]) >= 0;
    }
  );
  return selectedCatagoryEntries;
}

export default useChartsDataHook;

import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { SemiBoldFont } from '../Css';

export const StyledBox = styled(Box)(({ theme }) => {
  return {
    cursor:'pointer',
    backgroundColor:theme.boxSX.backgroundColor,
    padding:theme.boxSX.padding,
    height:'34px',
    //border
    borderWidth: theme.boxSX.borderWidth,
    borderColor: theme.boxSX.borderColor,
    borderStyle: 'solid',
    borderRadius: '5px',
    //font
    ...SemiBoldFont,
    color: theme.boxSX.color,
  };
});

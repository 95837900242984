import React from 'react';
import SimpleIconButton from '../Button/SimpleIconButton';
import IconActive from './icon/TextConfirmIconActive.svg';
import IconDefault from './icon/TextConfirmIconDefault.svg';
import IconDisable from './icon/TextConfirmIconDisable.svg';
export default function TextConfirmButton(props) {
    return (
        <SimpleIconButton
          DefaultIcon={IconDefault}
          HoverIcon={IconActive}
          ActiveIcon={IconActive}
          DisabledIcon={IconDisable}
          {...props}
        ></SimpleIconButton>
      );
}

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetAAndAFYTrendsSingleSelectDataWithStatus,
} from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';

export default function useGetCategoryListHook(
  singleSelectListUrl,
  reduxListTitle,
  selectedFY
) {
  const dispatch = useDispatch();

  const itemList = useSelector(state => {
    return state.ReportDiagram[reduxListTitle];
  });

  let loading = null,
    error = null,
    data = null;

  if (typeof itemList !== 'undefined') {
    ({ loading, error, data } = itemList);
  }

  useEffect(() => {
    if (typeof itemList === 'undefined' && selectedFY !== null) {
      dispatch(
        GetAAndAFYTrendsSingleSelectDataWithStatus(
          singleSelectListUrl,
          reduxListTitle,
          selectedFY
        )
      );
    }
  }, [selectedFY, itemList, reduxListTitle]);

  return [data, loading, error];
}

import { useNavigate, useSearchParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import HeadPageOAvsTotal from './OAvsTotal';
import { FormattedMessage } from 'react-intl';
import ArticleOverview from './Overview';
// import FYTrends from './FYTrends/FYTrends';
import FYTrends from './FYTrends';
import AnnualTrends from './AnnualTrends';
import OAPublicationInHybridJournals from './OAPublicationInHybridJournals';
import { ReportTabChildren } from '../ReportSpecificComponents/ReportTabComponents';
import OAWaiverUse from './OAWaiverUse';
import { ShowForPermission } from "@/components/PermissionControl/ShowForPermission";
import {PERMISSIONS} from "@/constant/permission";

function a11yProps(index) {
  return {
    id: `articlesTab${index}`,
    'aria-controls': `articlesTabPanel${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`articlesTabPanel${index}`}
      data-selenium-id={`Articles-TabPanel-${index}`}
      aria-labelledby={`articlesTab${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ paddingTop: '14px', paddingLeft: '5px', paddingRight: '5px' }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ARTICLE_CATEGRORIES = [
  'overview',
  'oaVsTotal',
  'fyTrends',
  'annualTrends',
  'OAPublicationInHybridJournals',
  'OAWaiverUse',
];

export function Articles({ childTab }) {
  const navigate = useNavigate();

  // get index of active tab
  let selectedIndex = ARTICLE_CATEGRORIES.indexOf(childTab);

  // the first child tab is selected by default
  if (selectedIndex === -1) {
    selectedIndex = 0;
  }

  // get showTable from URL
  let [searchParams, setSearchParams] = useSearchParams();
  let showTable = searchParams.get('showTable');
  showTable = showTable === 'false' ? false : true;

  const setShowTable = showTable => {
    // setSearchParams({ showTable: Boolean(showTable) });
    setSearchParams({ showTable });
  };

  // pass showTable and navigate to new URL
  const handleChange = (_event, newValue) => {
    const newTab = ARTICLE_CATEGRORIES[newValue];
    // hardcoded:
    navigate(
      `/simt/auth/reportRelated/report/articles/${newTab}?showTable=${showTable}`
    );
  };

  return (
    <Box
      sx={{ width: '100%', padding: 0 }}
      data-selenium-id='Report_Articles-Box'
    >
      <ShowForPermission permission={`${PERMISSIONS.REPORT_READ_GENERAL}`}>
        <Box
          sx={{ mt: '-70px', borderColor: 'divider' }}
          data-selenium-id='Report_Articles-Box-Box'
        >
          <Tabs
            data-selenium-id='Report_Articles-Tabs'
            onChange={handleChange}
            value={selectedIndex}
            aria-label='Articles tabs example'
          >
            <ReportTabChildren
              id='reportArticlesOverviewTab'
              data-selenium-id='Report_Articles-Tab'
              label={
                <FormattedMessage
                  id={'report.overview'}
                  data-selenium-id={'report.overview'}
                />
              }
              {...a11yProps(0)}
            />
            <ReportTabChildren
              id='reportArticlesOsTab'
              data-selenium-id='Report_Articles-OsTab'
              label={
                <FormattedMessage
                  id={'report.oaVsTotal'}
                  data-selenium-id={'report.oaVsTotal'}
                />
              }
              {...a11yProps(1)}
            />
            <ReportTabChildren
              id='reportArticlesFYTab'
              data-selenium-id='Report_Articles-FYTab'
              label={
                <FormattedMessage
                  id={'report.fyTrends'}
                  data-selenium-id={'report.fyTrends'}
                />
              }
              {...a11yProps(2)}
            />
            <ReportTabChildren
              id='reportArticlesAnnualTab'
              data-selenium-id='Report_Articles-AnnualTab'
              label={
                <FormattedMessage
                  id={'report.annualTrends'}
                  data-selenium-id={'report.annualTrends'}
                />
              }
              {...a11yProps(3)}
            />
            <ReportTabChildren
              id='reportArticlesOAPublicationInHybridJournals'
              data-selenium-id='Report_Articles-OAPublicationInHybridJournals'
              label={
                <FormattedMessage
                  id={'report.OAPublicationInHybridJournals'}
                  data-selenium-id={'report.OAPublicationInHybridJournals'}
                />
              }
              {...a11yProps(4)}
            />
            <ReportTabChildren
              id='reportArticlesOAWaiverUse'
              data-selenium-id='Report_Articles-OAWaiverUse'
              label={
                <FormattedMessage
                  id={'report.OAWaiverUse'}
                  data-selenium-id={'report.OAWaiverUse'}
                />
              }
              {...a11yProps(5)}
            />
          </Tabs>
        </Box>
        {/* <<<<<<< HEAD */}
        <TabPanel
          value={selectedIndex}
          index={0}
          data-selenium-id='Report_Articles-TabPanel1'
          sx={{ marginTop: '-20px' }}
        >
          <ArticleOverview
            showTable={showTable}
            setShowTable={setShowTable}
            data-selenium-id='Report_Articles-ArticleOverview'
          />
        </TabPanel>
        <TabPanel
          value={selectedIndex}
          index={1}
          data-selenium-id='Report_Articles-TabPanel2'
          sx={{ marginTop: '-20px' }}
        >
          <HeadPageOAvsTotal
            showTable={showTable}
            setShowTable={setShowTable}
            data-selenium-id='Report_Articles-HeadPageOAvsTotal'
          />
        </TabPanel>
        <TabPanel
          value={selectedIndex}
          index={2}
          data-selenium-id='Report_Articles-TabPanel3'
          sx={{ marginTop: '-20px' }}
        >
          <FYTrends
            showTable={showTable}
            setShowTable={setShowTable}
            data-selenium-id='Report_Articles-FYTrends'
          />
        </TabPanel>
        <TabPanel
          value={selectedIndex}
          index={3}
          data-selenium-id='Report_Articles-TabPanel4'
          sx={{ marginTop: '-20px' }}
        >
          <AnnualTrends
            showTable={showTable}
            setShowTable={setShowTable}
            data-selenium-id='Report_Articles-AnnualTrends'
          ></AnnualTrends>
        </TabPanel>
        <TabPanel
          value={selectedIndex}
          index={4}
          data-selenium-id='Report_Articles-TabPanel5'
          sx={{ marginTop: '-20px' }}
        >
          <OAPublicationInHybridJournals
            showTable={showTable}
            setShowTable={setShowTable}
            data-selenium-id='Report_Articles-OAPublicationInHybridJournals'
          />
        </TabPanel>
        <TabPanel
          value={selectedIndex}
          index={5}
          data-selenium-id='Report_Articles-TabPanel6'
          sx={{ marginTop: '-20px' }}
        >
          <OAWaiverUse
            showTable={showTable}
            setShowTable={setShowTable}
            data-selenium-id='Report_Articles-OAWaiverUse'
          ></OAWaiverUse>
        </TabPanel>
      </ShowForPermission>
    </Box>
  );
}

export default Articles;

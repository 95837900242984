import {
  BarAndLineChartOptions,
  LEGEND_STYLE,
} from '@/pages/Report/chartUtils/chartOptions/BarAndLineChartOptions.js';
import {
  FY_TRENDS_OF_SI_ACQUISITION,
  SG_CHART_COLOR,
} from '../../Constants/index.js';
import {
  mergeFyTrendsLineData,
  replaceNullWithZero,
} from '@/pages/Report/utils/reportUtils.js';

/**
 * overwrite the default configuration
 */
const LEGEND_POS_ORIGIN = 192;
const LEGEND_POS_OFFSET = -200;
const LEGEND_POS_ICON_OFFSET = 8;
const LINE_X_AXIS_INDEX = {
  xAxisIndex: 1,
};
const MAX_WIDTH = 1374;
export class AAndAFYTrendsSGOption extends BarAndLineChartOptions {
  constructor(headerToSelectColor, sgOO, sgOA, bdOO, bdOA, haveBD) {
    super();
    // overwrite the default properties
    this.headerToSelectColor = headerToSelectColor;
    this.sgOO = sgOO;
    this.sgOA = sgOA;
    this.bdOO = bdOO;
    this.bdOA = bdOA;
    this.haveBD = haveBD;
    this.legend_pos = LEGEND_POS_ORIGIN;
  }
  getBarSeries() {
    const ooStyle = SG_CHART_COLOR[this.headerToSelectColor].oo;
    const oaStyle = SG_CHART_COLOR[this.headerToSelectColor].oa;
    const nameList = this.dataForBar[0].slice(1);
    const dataLength = nameList.length;

    if (this.haveBD) {
      const oaLineData = mergeFyTrendsLineData(
        nameList,
        this.dataForBar[4].slice(1)
      ).map((x, i) => {
        return {
          name: x.name,
          value: [
            MAX_WIDTH / (dataLength * 2) + 35 / 2 + i * (MAX_WIDTH / dataLength),
            x.value,
          ],
        };
      });
      const allLineData = mergeFyTrendsLineData(
        nameList,
        this.dataForBar[3].slice(1)
      ).map((x, i) => {
        return {
          name: x.name,
          value: [
            MAX_WIDTH / (dataLength * 2) -
              35 / 2 +
              i * (MAX_WIDTH / dataLength),
            x.value,
          ],
        };
      });
      
      let barSeries = [
        {
          //oo bar
          ...this.barSerieOption,
          itemStyle: {
            normal: {
              label: {
                show: false,
                position: 'inside',
                textStyle: {
                  color: '#596A7C',
                  fontSize: 5,
                },
              },
              borderColor: ooStyle.borderColor,
              borderRadius: [4, 4, 0, 0],
              borderWidth: 1,
            },
          },
          stack: 'oo',
          seriesLayoutBy: 'row',
          color: ooStyle.barColor,
          tooltip: { ...this.getTooltip(), borderColor: ooStyle.borderColor },
          barMaxWidth: 35,
          barMinHeight: 3,
          name: this.bdOO,
          encode: {
            x: 0,
            y: 1,
          },
        },
        {
          //oa bar
          ...this.barSerieOption,
          itemStyle: {
            normal: {
              label: {
                show: false,
                position: 'inside',
                textStyle: {
                  color: '#596A7C',
                  fontSize: 5,
                },
              },
              borderColor: oaStyle.borderColor,
              borderRadius: [4, 4, 0, 0],
              borderWidth: 1,
            },
          },
          stack: 'oa',
          seriesLayoutBy: 'row',
          color: oaStyle.barColor,
          tooltip: { ...this.getTooltip(), borderColor: oaStyle.borderColor },
          barMaxWidth: 35,
          barMinHeight: 3,
          barGap: '0%',
          name: this.bdOA,
          encode: {
            x: 0,
            y: 2,
          },
        },
        {
          //oa line
          type: 'line',
          data: oaLineData,
          ...LINE_X_AXIS_INDEX,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#596A7C',
                  fontSize: 10,
                },
                formatter: params => {
                  if (params.data[4] === 0) {
                    return '';
                  }
                  return params.data[4];
                },
              },
            },
            borderColor: oaStyle.lineColor,
            borderWidth: 0.5,
          },
          type: 'line',
          // ...OA_AXIS_INDEX,
          stack: 'myline',
          seriesLayoutBy: 'row',
          color: oaStyle.lineColor,
          symbol: 'image://' + oaStyle.lineIcon,
          symbolSize: 14, //设定实心点的大小
          name: this.sgOA,
          encode: {
            x: 0,
            y: 4,
          },
        },
        {
          //all line
          type: 'line',
          data: allLineData,
          ...LINE_X_AXIS_INDEX,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#596A7C',
                  fontSize: 10,
                },
                formatter: params => {
                  if (params.data[3] === 0) {
                    return '';
                  }
                  return params.data[3];
                },
              },
            },
          },
          stack: 'myline2',
          seriesLayoutBy: 'row',
          color: ooStyle.lineColor,
          borderColor: ooStyle.lineColor,
          symbol: 'image://' + ooStyle.lineIcon,
          symbolSize: 14, //设定实心点的大小
          name: this.sgOO,
          encode: {
            x: 0,
            y: 3,
          },
        },
      ];
      return barSeries;
    } else {
      return [
        {
          ...this.barSerieOption,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#596A7C',
                  fontSize: 10,
                },
                formatter: params => {
                  if (params.data[4] === 0) {
                    return '';
                  }
                  return params.data[4];
                },
              },
            },
            borderColor: oaStyle.lineColor,
            borderWidth: 0.5,
          },
          type: 'line',
          stack: 'myline',
          seriesLayoutBy: 'row',
          color: oaStyle.lineColor,
          symbol: 'image://' + oaStyle.lineIcon,
          symbolSize: 14, //设定实心点的大小
          name: this.sgOA,
          encode: {
            x: 0,
            y: 2,
          },
        },
        {
          ...this.barSerieOption,
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#596A7C',
                  fontSize: 10,
                },
                formatter: params => {
                  if (params.data[3] === 0) {
                    return '';
                  }
                  return params.data[3];
                },
              },
            },
          },
          type: 'line',
          stack: 'myline2',
          seriesLayoutBy: 'row',
          color: ooStyle.lineColor,
          symbol: 'image://' + ooStyle.lineIcon,
          symbolSize: 14, //设定实心点的大小
          name: this.sgOO,
          encode: {
            x: 0,
            y: 1,
          },
        },
      ];
    }
  }
  getXAxis() {
    const xAxis = super.getXAxis();
    return [{ ...xAxis }, { type: 'value', max: MAX_WIDTH, show: false }];
  }

  getTooltip() {
    return [
      {
        ...this.tooltip,

        formatter: params => {
          console.log(params);
          let fontColor;
          let backgroundColor;
          let data;
          if (params.componentSubType === 'line') {
            fontColor = '#FFFFFF';
            backgroundColor = params.color;
            data = params.data.value[1];
          } else {
            data = params.data[params.encode.y];
          }
          let tooltipName =
            this.headerToSelectColor == FY_TRENDS_OF_SI_ACQUISITION
              ? ' SI Acquisition'
              : ' Active SI';
          let dataStr = `<div style="padding: 6px 8px; margin: 0; background-color: ${backgroundColor};">
            <p style="font-size: 12px; font-weight: 700; color:${
              !fontColor ? '#243C9C' : fontColor
            }; line-height: 16px; margin: 0 0 2.5px 0;">${
            params.name + tooltipName
          }</p>
            <p style="font-size: 10px; font-weight: 400;color: ${
              !fontColor ? '#848484' : fontColor
            }; line-height: 14px; margin: 0;">${
            params.seriesName
          }&nbsp;:&nbsp;${data}</p>
          </div>`;
          return dataStr;
        },
        padding: 0,
      },
    ];
  }

  setLegendSG(legendSG) {
    this.legendSG = legendSG;
    return this;
  }

  setLegendBD(legendBD) {
    this.legendBD = legendBD;
    return this;
  }

  getSelectedLegend() {
    const OOLegendIcon = SG_CHART_COLOR[this.headerToSelectColor].oo.legendIcon;
    const OALegendIcon = SG_CHART_COLOR[this.headerToSelectColor].oa.legendIcon;

    if (this.legend.length > 2) {
      return [
        {
          ...LEGEND_STYLE,
          itemWidth: 32,
          data: [{ name: this.legendSG[1], icon: 'image://' + OOLegendIcon }],
          left: this.legend_pos,
          bottom: 45,
        },
        {
          ...LEGEND_STYLE,
          itemWidth: 32,
          data: [
            {
              name: this.legendBD[1],
              icon: 'image://' + OALegendIcon,
            },
          ],
          left: this.legend_pos + 250,
          bottom: 45,
        },
        {
          ...LEGEND_STYLE,
          data: [{ name: this.legendSG[0] }],
          left: this.legend_pos + LEGEND_POS_ICON_OFFSET,
          bottom: 15,
        },
        {
          ...LEGEND_STYLE,
          data: [{ name: this.legendBD[0] }],
          left: this.legend_pos + 250 + LEGEND_POS_ICON_OFFSET,
          bottom: 15,
        },
      ];
    } else {
      return [
        {
          ...LEGEND_STYLE,
          itemWidth: 32,
          data: [{ name: this.legend[0], icon: 'image://' + OOLegendIcon }],
          left: this.legend_pos + 200,
          bottom: 45,
        },
        {
          ...LEGEND_STYLE,
          itemWidth: 32,
          data: [{ name: this.legend[1], icon: 'image://' + OALegendIcon }],
          left: this.legend_pos,
          bottom: 45,
        },
      ];
    }
  }

  setDataSource(dataForBar) {
    if (this.haveBD) {
      replaceNullWithZero(dataForBar, [3, 4]);
      this.dataForBar = dataForBar;
    } else {
      replaceNullWithZero(dataForBar, [1, 2]);
      this.dataForBar = dataForBar;
    }
    return this;
  }

  setWidth(width) {
    this.width = width;

    if (width && width !== '100%') {
      const half_width = width / 2;
      this.legend_pos = half_width + LEGEND_POS_OFFSET;
    }

    return this;
  }
}

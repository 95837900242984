import {
  Box,
  Button,
} from '@mui/material';
import styleModule from './SaveAndCancelButtonForGeneralInfoSection.module.scss';
export default function ({ handleSubmit, handleCancel, isDisabled }) {
  return (<Box
    className={styleModule.SaveAndCancelButtonBoxForGeneralInfoSection}
  >
    <Button
      disableRipple
      className={styleModule.CancelButton}
      onClick={() => handleCancel()}
    >Cancel</Button>
    <Button
      disableRipple
      onClick={() => handleSubmit()}
      disabled={isDisabled}
      className={styleModule.SaveButton}
    >Save</Button>
  </Box >);
}
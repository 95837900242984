import {axios_instance} from "@/utils/axios_instance";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {getMessageIdFromResponse} from "@/utils/commonUtils";
import {GET_AT_INFO, UPLOAD_AT_XML, UPLOAD_FAIL_MESSAGE} from "@/actions/SIMT-Report/Target/AnnualTargetAction";
import getAuth from "@/utils/getAuth";

/**
 * 上传文件
 * @param {*} file
 * @param {*} fy
 * @param {*} level
 * @returns
 */
export function uploadFile(file, fy, level) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('fy', fy);
    formData.append('level', level);

    return async dispatch => {
        try {
            let res = await axios_instance.post('/reports/target/upload', formData, {
                headers: {
                    authorization: getAuth(),
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (res.data.code === 200) {
                dispatch({type: UPLOAD_AT_XML, data: true});
                dispatch(
                    setSnackbarMessageAndOpen(
                        'annualTarget.success',
                        {},
                        SEVERITIES.success
                    )
                );
            } else {
                dispatch({type: UPLOAD_AT_XML, data: false});
                dispatch({type: UPLOAD_FAIL_MESSAGE, data: res.data.message});
                const mid = getMessageIdFromResponse(res);
                if (res.data.code === 13012) {
                    dispatch(setSnackbarMessageAndOpen(mid, {}, SEVERITIES.warning));
                } else {
                    dispatch(setSnackbarMessageAndOpen(mid, {}, SEVERITIES.error));
                }
            }
        } catch (e) {
            dispatch({type: UPLOAD_AT_XML, data: false});
        }
    };
}

/**
 * 获取AnnualTarget相关信息
 * @param {*} fy
 * @returns
 */
export function getATInfo(fy) {
    return async dispatch => {
        try {
            let res = await axios_instance.get('/reports/target/status', {
                params: {
                    fiscalYear: fy,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.data.code === 200) {
                let data = [];
                data.push({
                    targetLevel: 'Journal level targets',
                    status: res.data.data.journalTarget.status,
                    updateTime: res.data.data.journalTarget.updateTime,
                    updateBy: res.data.data.journalTarget.updateBy,
                });
                data.push({
                    targetLevel: 'Subject Group level targets',
                    status: res.data.data.subjectGroupTarget.status,
                    updateTime: res.data.data.subjectGroupTarget.updateTime,
                    updateBy: res.data.data.subjectGroupTarget.updateBy,
                });
                data.push({
                    targetLevel: 'Business Division level targets',
                    status: res.data.data.businessDivisionTarget.status,
                    updateTime: res.data.data.businessDivisionTarget.updateTime,
                    updateBy: res.data.data.businessDivisionTarget.updateBy,
                });
                data.push({
                    targetLevel: 'CE/SCA Group level targets',
                    status: res.data.data.groupCeTarget.status,
                    updateTime: res.data.data.groupCeTarget.updateTime,
                    updateBy: res.data.data.groupCeTarget.updateBy,
                });
                dispatch({type: GET_AT_INFO, data: data});
            }
        } catch (e) {
        }
    };
}
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  FormControl,
  Grid,
  Stack,
  IconButton,
  Link,
  ListItem,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  CircularProgress,
  Popover,
  buttonClasses,
  DialogTitle,
  createTheme,
  Popper,
  SvgIcon,
} from '@mui/material';
import { styled } from '@mui/system';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React, {
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
  createContext,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropdownIcon from '@/assets/dropdownIcon_8_457.svg';
import QuestionMark from '@/assets/QuestionMark.svg';
import SearchIcon from '@/assets/Search.svg';
import CloseIcon from '@/assets/GEDetailPageClearInput.svg';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { handleLinkWithoutProtocol } from '@/utils/commonUtils';
import MyOption from './CustomModal/MyOption';

import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { contextHeightChanged, setKey } from '../../../actions/CommonAction';
import { CreateGEAutocompleteBase } from '../Overview/CreateGE/CustomComponent/CustomAutocomplete';
import axios from 'axios';
import UploadIcon2 from '@/assets/uploadIcon2.svg';
import { ChooseIconByType, formatFileSize } from './FlagBlock';
import { GetLabel } from '@/components/FileUploader/LabelFile';
import { GetProgress } from '@/components/FileUploader/ProgressFile';
import {downloadCVFile, UploadCV} from "@/actions/SIMT-SI/GuestEditor/SipDetail";
const filter = createFilterOptions();
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const GETooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement='top' />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#596A7C',
    width: '6px',
    height: '3px',
    top: '28px',
  },

  // [`& .${tooltipClasses.arrow}`]: {
  //   display: 'none',
  // },
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: '#596A7C',
    // color: '#FFFFFF',
    // // boxShadow: theme.shadows[1],
    // fontFamily: 'Open Sans !important',
    // fontStyle: 'normal !important',
    // fontWeight: '400 !important',
    // fontSize: '14px !important',
    // lineHeight: '20px !important',

    // maxWidth: '385px !important',

    // padding: '8px 16px 8px 16px',
    backgroundColor: '#596A7C',

    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#FFFFFF',

    padding: '4px 8px 4px 8px',
    // width: '203px',
    // height: '28px',

    borderRadius: '5px',
    maxWidth: '385px',
    boxSizing: 'border-box',

    marginBottom: '3px !important',
  },
}));

export function RenderNoOptionsText({ keyName }) {
  const keyMap = useSelector(state => state.Common.keyMap);
  return (
    <>
      <Typography
        sx={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '20px',
          color: '#98A7B6',
        }}
        align='center'
      >
        No Results you want?
      </Typography>
      <Typography
        sx={{
          color: '#98A7B6',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '20px',
        }}
        align='center'
      >
        <span style={{ color: '#154AB6' }}>Add</span> "
        {keyMap[keyName] || 'New Value'}' to database
      </Typography>
    </>
  );
}

export const CreateListboxComponent = React.forwardRef((props, ref) => {
  const { children, onAdd, showAddBox, keyName, ...other } = props;
  // const [v,setV] = useState(inputValue);
  // useEffect(() => {
  //   console.info('Value change:',inputValue);
  //   setV(inputValue);
  // },[inputValue]);
  return (
    <ul
      style={{
        maxHeight: '210px',
        overflowY: 'auto',
      }}
      ref={ref}
      {...other}
    >
      {children}
      {showAddBox ? (
        <li sx={{ justifyContent: 'center' }} onClick={onAdd}>
          <RenderNoOptionsText keyName={keyName} />
        </li>
      ) : (
        <></>
      )}
    </ul>
  );
});

//格式自定义AutoComplete
export const CssAutoComplete = React.forwardRef((props, tref) => {
  const ref = useRef(tref);
  const [h, setH] = useState(25);
  const [isEmpty, setIsEmpty] = useState(!props.value);
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const dispatch = useDispatch();

  // const getInputValue = () => {
  //   return inputValue;
  // };
  //
  // React.useImperativeHandle(ref, () => ({
  //   getInputValue
  // }));

  useEffect(() => {
    const element = ref.current;
    if (props.multiple) {
      const observer = new ResizeObserver(() => {
        setH(element.offsetHeight);
      });
      if (element) {
        observer.observe(element);
      }
      return () => {
        if (element) {
          observer.unobserve(element);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (props.multiple) {
      if (props.value && props.value.length && props.value.length > 0) {
        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }
    } else if (
      props.value &&
      (props.value.department === undefined || props.value.department !== '') &&
      (props.value.institute === undefined || props.value.institute !== '')
    ) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  }, [props.value]);

  const {
    sx,
    onChange,
    ListboxProps,
    ListboxComponent,
    noOptionsText,
    keyName,
    ...other
  } = props;

  const handleChange = (e, newValue, reason, details) => {
    // setH(ref.current.offsetHeight);
    if (onChange) {
      onChange(e, newValue, reason, details);
    }
  };

  return (
    <Autocomplete
      ref={ref}
      onChange={handleChange}
      sx={{
        // '& .MuiAutocomplete-popper' : {
        //   boxShadow: '0px 6px 12px rgba(38, 46, 53, 0.12)',
        //   borderRadius: '5px',
        // },
        '& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiInputBase-root':
          {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '120%',
            letterSpacing: '-0.011em',
            color: '#262E35',
            padding: '4px 65px 4px 4px',
            width: '286px',
            boxSizing: 'border-box',
            borderRadius: '4px',
            borderStyle: 'solid',
            borderWidth: '1px',
            ...(!isEmpty && {
              borderColor: '#98A7B6',
            }),
            ...(isEmpty && {
              borderColor: '#DFE4E8',
              // borderColor: 'green',
            }),
            ...(isHovered && {
              borderColor: '#262E35',
            }),
            ...((isActive || isOpen) && {
              border: '2px solid #0052CC',
            }),
          },
        '& .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall, & .MuiOutlinedInput-input.MuiInputBase-input':
          {
            padding: '0px 41px 0px 6px',
            height: '25px',
            width: 0,
            minWidth: 'unset',
            ...(props.multiple &&
              !isEmpty &&
              !(isActive || isOpen) && {
                padding: 0,
              }),
            ...(props.multiple &&
              (isEmpty || isActive || isOpen) && {
                padding: '0px 41px 0px 6px',
              }),
            ...(!props.multiple && {
              padding: '0px 41px 0px 6px',
            }),
          },
        '& .MuiButtonBase-root.MuiIconButton-root path': {
          fill: '#596A7C',
        },
        '& .MuiChip-label': {
          maxWidth: '180px',
        },
        '& .MuiAutocomplete-endAdornment': {
          paddingRight: '6px',
          ...(h >= 45 && {
            display: 'flex',
            flexDirection: 'column-reverse',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            height: h * 0.8 + 'px',
            top: 'auto',
          }),
          ...(h < 45 && {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            height: h * 0.8 + 'px',
            top: 'auto',
            alignItems: 'center',
          }),
          '& button': {
            height: '16px',
            width: '16px',
            padding: 0,
            borderRadius: '2px',
            '&:hover': {
              backgroundColor: '#DFE4E8',
            },
          },
        },
        '& .MuiAutocomplete-clearIndicator': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
          ...(isEmpty && {
            visibility: 'hidden',
            ':hover': {
              visibility: 'hidden',
            },
          }),
          // ...(!isEmpty && {
          //   visibility: 'visible',
          // }),
          '& svg': {
            height: '100%',
            width: '100%',
          },
        },
        '& .MuiAutocomplete-popupIndicator': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
          '& svg': {
            width: '10px',
            height: '7px',
          },
        },
        '& svg': {
          minWidth: 'fit-content',
          minHeight: 'fit-content',
        },
        ...sx,
      }}
      disableClearable={isEmpty}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onInputChange={(event, value) => {
        setInputValue(value);
        keyName && dispatch(setKey(keyName, value));
      }}
      onKeyDown={event => {
        if (props.multiple && keyName) {
          let outputValue = event.target.value;
          const cursorStart = event.target.selectionStart;
          if (event.keyCode === 8) {
            outputValue =
              outputValue.slice(0, cursorStart - 1) +
              outputValue.slice(cursorStart);
          } else if (event.keyCode >= 48 && event.keyCode <= 90) {
            // outputValue += String.fromCharCode(event.keyCode);
            outputValue =
              outputValue.slice(0, cursorStart) +
              event.key +
              outputValue.slice(cursorStart);
          }
          dispatch(setKey(keyName, outputValue));
        }
      }}
      ListboxProps={{
        keyName,
        ...ListboxProps,
      }}
      ListboxComponent={ListboxComponent || CreateListboxComponent}
      noOptionsText={
        ListboxProps && ListboxProps.showAddBox ? (
          <Box onClick={ListboxProps.onAdd}>
            <RenderNoOptionsText keyName={keyName} />
          </Box>
        ) : (
          noOptionsText || 'No Options'
        )
      }
      PopperComponent={props => (
        <Popper
          {...props}
          sx={{
            '&.MuiAutocomplete-popper': {
              inset: '6px auto !important',
            },
            '& .MuiAutocomplete-paper': {
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 6px 12px rgba(38, 46, 53, 0.12)',
              borderRadius: '5px',
            },
            '& .MuiAutocomplete-noOptions': {
              padding: '0px',
            },
          }}
        />
      )}
      {...other}
      //PopperComponent
    ></Autocomplete>
  );
});

//所有带有搜索的的文本框
export function AutocompleteTextField(props) {
  //getOptionLabel：处理Option获得标签
  //TextFieldLabel：默认的值
  //excuteFunc：获取数据的函数
  //data：数据项
  //setValue：向上级传递选择的数据
  //setGroup：仅用于Handling CE与Group的联动
  const {
    id,
    getOptionLabel,
    TextFieldLabel,
    excuteFunc,
    data,
    setValue,
    setGroup,
  } = props;
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  //根据输入的数据进行更新数据
  useEffect(() => {
    let active = true;

    const timer = setTimeout(() => {
      setLoading(true);
      (async () => {
        setOptions([]);
        await dispatch(excuteFunc(inputValue)); // For demo purposes.
        setLoading(false);
      })();
    }, 500);

    return () => {
      clearTimeout(timer);
      active = false;
    };
  }, [inputValue]);

  //当点击打开获取数据
  useEffect(() => {
    if (!open) {
      setOptions([]);
    } else {
      setLoading(true);
      (async () => {
        await dispatch(excuteFunc(inputValue)); // For demo purposes.
        setLoading(false);
      })();
    }
  }, [open]);

  useEffect(() => {
    setOptions([...data]);
  }, [data]);

  return (
    <CssAutoComplete
      id={id}
      data-selenium-id={`GEPage_GEDetailPage-Components-AutocompleteTextField${id}-CssAutoComplete`}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      getOptionLabel={getOptionLabel}
      defaultValue={TextFieldLabel}
      autoComplete
      includeInputInList
      filterSelectedOptions
      loading={loading}
      filterOptions={x => x}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue.email);
        if (typeof setGroup === 'function') {
          setGroup(newValue.group);
        }
      }}
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      popupIcon={open ? null : <SearchIcon />}
      clearIcon={<CloseIcon />}
      renderInput={params => <TextField {...params} />}
    />
  );
}

//自定义的选择框
export const CssSelector = styled(Select)({
  '& .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '120%',
    letterSpacing: '-0.011em',
    color: '#262E35',
    padding: '0 14px',
    height: '33px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  '& .MuiAutocomplete-endAdornment': {
    '& button': {
      height: '16px',
      width: '16px',
      borderRadius: '2px',
      '&:hover': {
        backgroundColor: '#DFE4E8',
      },
    },
  },
  '& .MuiAutocomplete-clearIndicator': {
    padding: 0,
    visibility: 'visible',
    // '& svg':{
    //   height: '80%',
    //   width: '80%',
    // }
  },
  '& .MuiAutocomplete-popupIndicator': {
    padding: 0,
  },
  '& svg': {
    minWidth: 'fit-content',
    minHeight: 'fit-content',
  },
});

export function BasicSelect(props) {
  //list：下拉框选项
  const { id, list, value, setValue } = props;

  const handleChange = event => {
    setValue(event.target.value);
  };

  const selector = state => {
    return {
      detailInfo: state.SI.detailInfo,
    };
  };
  const { detailInfo } = useSelector(selector);

  const MenuProps = {
    MenuListProps: {
      style: {
        padding:
          detailInfo !== null &&
          detailInfo !== undefined &&
          detailInfo.siMarketing !== null &&
          detailInfo.siMarketing !== undefined &&
          detailInfo.siMarketing.length > 0 &&
          detailInfo.siMarketing[0].readyForPromotion === 'Yes'
            ? '8px 0 0'
            : '8px 0',
        boxShadow: '0px 6px 12px rgba(38, 46, 53, 0.12)',
        borderRadius: '5px',
      },
    },
  };
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getIcon = () => {
    return (
      <IconButton
        sx={{
          width: '16px',
          height: '16px',
          ...(isOpen && {
            transform: 'rotate(180deg)',
          }),
          position: 'absolute',
          right: '13px',
          top: '8px',
          padding: '0px',
          borderRadius: '2px',
          '&:hover': {
            backgroundColor: '#DFE4E8',
          },
        }}
        onClick={toggleOpen}
        data-selenium-id='GEPage_GEDetailPage-Components-BasicSelect-DropdownIcon'
      >
        <DropdownIcon />
      </IconButton>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      const dom = document.querySelector(
        '#menu- > div.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper'
      );
      if (dom) {
        dom.style.boxShadow = '0px 6px 12px 0px rgb(38, 46, 53, 0.12)';
      }
    });
  });

  return (
    <Box
      id={id}
      sx={{
        width: '286px',
        '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
          borderRadius: '4px',
          borderStyle: 'solid',
          borderWidth: '1px',
          ...(value &&
            value.length && {
              borderColor: '#98A7B6',
            }),
          ...(!(value && value.length) && {
            borderColor: '#DFE4E8',
          }),
          ...(isHovered && {
            borderColor: '#596A7C',
          }),
          ...(isActive && {
            border: '2px solid #154AB6',
          }),
        },
      }}
    >
      <FormControl
        fullWidth
        data-selenium-id='GEPage_GEDetailPage-Components-BasicSelect-FormControl'
      >
        <CssSelector
          value={value}
          onChange={handleChange}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onMouseDown={() => setIsActive(true)}
          onBlur={() => {
            setIsActive(false);
            setIsHovered(false);
          }}
          fullWidth
          MenuProps={MenuProps}
          // autoWidth={true}
          IconComponent={getIcon}
          onOpen={handleOpen}
          open={isOpen}
          onClose={handleClose}
          renderValue={value => {
            return (
              <Box
                sx={{
                  width: '200px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {value}
              </Box>
            );
          }}
          data-selenium-id='GEPage_GEDetailPage-Components-BasicSelect-FormControl-CssSelector'
        >
          {list.map((value, index) => {
            if (
              value.value !== null &&
              value.value !== undefined &&
              value.value === 'Open Call or Hybrid Only'
            ) {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    padding: '12px',
                    background: '#DFE4E8',
                    borderRadius: '0px 0px 4px 4px',
                  }}
                  data-selenium-id='GEPage_GEDetailPage-Components-BasicSelect-FormControl-CssSelector-Box'
                >
                  <Box
                    sx={{ marginRight: '12px' }}
                    data-selenium-id='GEPage_GEDetailPage-Components-BasicSelect-FormControl-CssSelector-Box-QuestionMark'
                  >
                    <QuestionMark></QuestionMark>
                  </Box>
                  <Box
                    sx={{
                      fontFamily: 'Open Sans',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#596A7C',
                      // width: "203px",
                      wordWrap: 'break-word',
                    }}
                    data-selenium-id='GEPage_GEDetailPage-Components-BasicSelect-FormControl-CssSelector-Box-Text'
                  >
                    <span>
                      You can only choose <em>Open Call</em> or <em>Hybrid</em>{' '}
                      when the <em>ready for promotion</em> switch is open.
                    </span>
                  </Box>
                </Box>
              );
            } else {
              return (
                <MenuItem
                  value={value.Name === undefined ? value.value : value.Name}
                  data-selenium-id='GEPage_GEDetailPage-Components-BasicSelect-FormControl-CssSelector-MenuItem'
                >
                  {value.Name === undefined ? value.value : value.Name}
                </MenuItem>
              );
            }
          })}
        </CssSelector>
      </FormControl>
    </Box>
  );
}

//自定义的文本框
export const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '120%',
    letterSpacing: '-0.011em',
    color: '#505050',
    maxHeight: 33,
  },
});

export function BasicTextField(props) {
  const { id, value, setValue } = props;

  const handleChange = e => {
    if (e.target.value.length <= 400) {
      setValue(e.target.value);
    }
  };

  return (
    <CssTextField
      id={id}
      data-selenium-id={`GEPage_GEDetailPage-Components-BasicTextField-${id}`}
      value={value}
      variant='outlined'
      onChange={handleChange}
    />
  );
}

//GE中的小标签
export const CssChip = styled(Chip)({
  '& .MuiChip-root': {
    borderRadius: '4px',
    height: '26px',
  },
  '& .MuiChip-label': {
    // fontWeight: 400,
    // fontSize: '12px',
    // lineHeight: '24px',
    // letterSpacing: '-0.011em',
    color: '#596A7C',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
  },
  '& .MuiChip-deleteIcon': {
    fontSize: 12,
  },
  '& .MuiSvgIcon-root path': {
    fill: '#272e35',
  },
});

//GE的选择框，与AutoComplete类似的用法
export const AutocompleteTextFieldMulit = React.forwardRef((props, tref) => {
  const {
    data,
    setValue,
    options,
    id,
    getOptionLabel,
    TextFieldLabel,
    selectlist,
    showAddBox,
    addMethod,
    keyName,
    ...others
  } = props;
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectValue, setSelectValue] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const ref = useRef(tref);

  const selector = state => {
    return {
      configInfo: state.SystemConfig.systemConfig,
    };
  };
  const { configInfo } = useSelector(selector);

  const maxGeNumber =
    configInfo === null ? 20 : configInfo.eachSiGuestEditorMaxNumber;

  useEffect(() => {
    let active = true;

    // const timer = setTimeout(() => {
    //   setLoading(true);
    //   (async () => {
    //     setOptions([]);
    //     await dispatch(excuteFunc(inputValue)); // For demo purposes.
    //     setLoading(false);
    //   })();
    // }, 500);

    // return () => {
    //   clearTimeout(timer);
    //   active = false;
    // };
  }, [inputValue]);

  useEffect(() => {
    // if (!open) {
    //   // setOptions([]);
    // } else {
    //   setLoading(true);
    //   (async () => {
    //     // await dispatch(excuteFunc(inputValue)); // For demo purposes.
    //     setLoading(false);
    //   })();
    // }
  }, [open]);

  useEffect(() => {
    setSelectValue(data);
  }, [data]);

  return (
    <CssAutoComplete
      ref={ref}
      options={options ? options : []}
      id={id}
      data-selenium-id={`GEPage_GEDetailPage-Components-AutocompleteTextFieldMulti-${id}-CssAutoComplete`}
      multiple
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={selectValue}
      getOptionLabel={getOptionLabel}
      // defaultValue={TextFieldLabel}  没有这个需求
      ListboxProps={{
        onAdd: addMethod,
        showAddBox: showAddBox,
      }}
      keyName={keyName}
      autoComplete
      includeInputInList
      // filterSelectedOptions = {true}
      loading={loading}
      // filterOptions={(x) => x}
      onChange={(event, newValue) => {
        function judgeRepetition() {
          //GE为空或只有一个元素时不做判断
          if (newValue.length === 0 || newValue.length === 1) {
            return true;
          } else {
            for (var i = 1; i < newValue.length; i++) {
              if (
                newValue[newValue.length - 1].keyword ===
                newValue[i - 1].keyword
              ) {
                return false;
              }
            }
            return true;
          }
        }

        if (judgeRepetition()) {
          setValue(newValue);
        }
      }}
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      popupIcon={
        <IconButton>
          <DropdownIcon />
        </IconButton>
      }
      renderInput={params => <TextField {...params} />}
      //多选框中的每个子选项
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <GETooltip title={getOptionLabel(option)} key={index}>
            <CssChip
              variant='outlined'
              label={getOptionLabel(option)}
              {...getTagProps({ index })}
              sx={{
                borderRadius: '4px',
                height: '26px',
                borderColor: '#BCC5CF !important',
              }}
              deleteIcon={<ClearOutlinedIcon />}
            />
          </GETooltip>
        ))
      }
      limitTags={6}
      //给多选AutocompleteTextFieldMulit的AutoComplete追加的自定义候选项样式
      // renderOption={MyOption}
      renderOption={(props, option, { selected }) => {
        const choosed =
          selected ||
          (option.id && selectValue.some(op => op.id == option.id)) ||
          (option.keyword &&
            selectValue.some(op => op.keyword == option.keyword));
        return (
          <li {...props}>
            <Box
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width: '100%',
                paddingTop: 0,
                paddingBottom: 0,
                marginTop: 0,
                marginBottom: 0,
                height: '21px',
                lineHeight: '21px',
              }}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{
                  marginRight: 8,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                checked={choosed}
              />
              {option.keyword}
            </Box>
          </li>
        );
      }}
      disableCloseOnSelect={true}
      {...others}
    />
  );
});

export function Description2(props) {
  const { infoObj, listInOneColumn, leftGEDetails, rightGEDetails } = props;
  const dispatch = useDispatch();
  const properityHandle = key => {
    switch (key) {
      case 'researchKeywordsList': {
        if (infoObj[key]) {
          let keywords = '';
          for (let i = 0; i < infoObj['researchKeywordsList'].length; i++) {
            if (i !== 0) {
              keywords = keywords + ', ';
            }
            keywords = keywords + infoObj['researchKeywordsList'][i];
          }
          return keywords;
        }
        return infoObj[key];
      }
      case 'flags': {
        let flags = '';
        for (let i in infoObj['flags']) {
          if (flags !== '') {
            flags = flags + ', ';
          }
          flags = flags + infoObj['flags'][i];
        }
        return flags;
      }
      case 'cvFileName': {
        if (infoObj[key] !== null && infoObj[key] !== undefined && infoObj[key] !== '') {
          let fileName;
          let fileSize;
          if (infoObj[key].indexOf(';') === -1) {
            fileName = infoObj[key];
          } else {
            fileName = infoObj[key]?.split(';size')[0]?.split('name:')[1];
            fileSize = infoObj[key]?.split(';')[1]?.split(':')[1];
          }
          const match = fileName.match(/(\d{5,13})_/);
          return (
            <GetLabel
              sx={{
                marginTop: '0px',
                marginLeft: '0px',
                width: '286px!important',
                height: '32px!important',
              }}
              FileIcon={ChooseIconByType(
                fileName?.split('.')[fileName?.split('.')?.length - 1]
              )}
              del={() => {
                dispatch(downloadCVFile(fileName));
              }}
              fileName={
                fileName?.substring(match.index + match[0].length)?.replace(/(.*)(?=\.)/, '$1') ||
                fileName.replace(/(.*)(?=\.)/, '$1')
              }
              fileSize={formatFileSize(fileSize)}
              fileMIMEType={
                fileName?.split('.')[fileName?.split('.')?.length - 1]
              }
              isDownLoad={true}
            />
          );
        }
      }
      default:
        return infoObj[key];
    }
  };

  const GEDetailItem = (items, index) => {
    return (
      /* 如果不是这三个字段或者是这三个字段而且有内容 那么就会正常显示 否则就不显示 这里要用infoObj[0][items.properity] 如果用items.value 加载该js文件中定义的列表 逻辑有错误*/

      // infoObj[items.properity] != null ? (
      <ListItem
        id={`geDetailPageGISLeft${index}`}
        data-selenium-id={`GEPage_GEDetailPage-Components-Description2-GEDetailItem-ListItem-${index}`}
        alignItems='baseline'
        key={index}
        sx={{
          fontSize: '16px',
          lineHeight: '24px',
          alignItems: 'baseline',
          padding: 0,
        }}
      >
        {/* item key */}
        <Box
          minWidth='224px'
          textAlign='left'
          marginRight={1}
          sx={{ color: '#596A7C', fontSize: '14px', fontWeight: '400' }}
          data-selenium-id={`GEPage_GEDetailPage-Components-Description2-GEDetailItem-ListItem${index}-Box`}
        >
          {items.key === 'GE code' ? 'GE Code' : items.key}:
        </Box>
        {/* item value */}
        {
          // 判断剩余字段是否需要提示 ？isTooltip
          items.isTooltip ? (
            // 如果需要提示 则按以下渲染
            <Stack
              direction='row'
              alignItems='center'
              sx={{
                color: '#262E35',
                fontSize: '14px',
              }}
              data-selenium-id={`GEPage_GEDetailPage-Components-Description2-GEDetailItem-ListItem${index}-Stack`}
            >
              <GETooltip
                title={properityHandle(items.properity)}
                data-selenium-id={`GEPage_GEDetailPage-Components-Description2-GEDetailItem-ListItem${index}-Stack-Tooltip`}
              >
                <Box
                  maxWidth='250px'
                  sx={{
                    color: '#262E35',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {items.ifLink ? (
                    <Link
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        textDecoration: 'none',
                      }}
                      target='_blank'
                      sx={{ fontWeight: 600, cursor: 'pointer' }}
                      href={handleLinkWithoutProtocol(
                        properityHandle(items.properity)
                      )}
                      key={index}
                      color='#154AB6'
                    >
                      {properityHandle(items.properity)}
                    </Link>
                  ) : (
                    properityHandle(items.properity)
                  )}
                </Box>
              </GETooltip>
            </Stack>
          ) : (
            // 如果不需要提示 则按以下渲染
            <Box
              // width="300px"
              sx={{
                color: '#262E35',
                'overflow-x': 'hidden',
                'overflow-y': 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'flex',
                'flex-flow': 'row',
                fontSize: '14px',
                fontWeight: '400',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  maxWidth: '100%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {properityHandle(items.properity)}
              </Box>
            </Box>
          )
        }
      </ListItem>
      // ) : (<></>)
    );
  };

  return (
    <Box>
      <CardContent
        sx={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        data-selenium-id={
          'GEPage_GEDetailPage-Components-Description2-CardContent'
        }
      >
        <Stack spacing={0}>
          <Box>
            <Grid
              container
              columnSpacing={listInOneColumn ? 6 : 0}
              rowSpacing={listInOneColumn ? 0 : 1}
              data-selenium-id={
                'GEPage_GEDetailPage-Components-Description2-CardContent-Grid'
              }
            >
              {/* GE Details 左半部分  */}
              <Grid
                item
                xs={listInOneColumn ? 6 : 12}
                data-selenium-id={
                  'GEPage_GEDetailPage-Components-Description2-CardContent-Grid-GridLeft'
                }
              >
                <Stack spacing={1}>{leftGEDetails.map(GEDetailItem)}</Stack>
              </Grid>
              {/* GE Details 右半部分  */}
              <Grid
                item
                xs={listInOneColumn ? 6 : 12}
                data-selenium-id={
                  'GEPage_GEDetailPage-Components-Description2-CardContent-Grid-GridRight'
                }
              >
                <Stack spacing={1}>{rightGEDetails.map(GEDetailItem)}</Stack>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </CardContent>
    </Box>
  );
}

export const buttonTextStyle = color => {
  return {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'center',
    color: color,
    textTransform: 'capitalize',
  };
};

export const SaveBtn = styled(Button)({
  ...buttonTextStyle('#F6F7F8'),
  [`&.${buttonClasses.root}`]: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',

    textTransform: 'capitalize',
    boxShadow: 'none',
    borderRadius: '3px',
    opacity: 1,
  },
  width: '114px',
  height: '38.6px',
  padding: '12px 18px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#0052CC',
  '&:hover': {
    backgroundColor: '#6D98EE',
  },
  '&:active': {
    backgroundColor: '#113D95',
  },
});

export const CancelBtn = styled(Button)({
  ...buttonTextStyle('#154AB6'),
  [`&.${buttonClasses.root}`]: {
    textTransform: 'capitalize',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    boxShadow: 'none',
    borderRadius: '3px',
  },
  width: '114px',
  height: '38.6px',
  padding: '12px 18px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#DFE4E8',
    fontWeight: 600,
  },
  '&:active': {
    fontWeight: 600,
  },
});

export const theme = createTheme({
  palette: {
    error: {
      main: '#EE5350',
    },
  },
});

export const CVUpload = ({ onFileChange, initialValue, setIsUploading }) => {
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [iserrorMessage, setIsErrorMessage] = useState(false);
  const [progressUpload, setProgressUpload] = useState({});
  const [cancelTokenList, setCancelTokenList] = useState({});
  const [upfileName, setUpfileName] = useState({});
  const [isUpload, setIsUpload] = useState({});
  const [stopUpload, setStopUpload] = useState(false);
  //初始化
  useEffect(() => {
    const initialIsUpload = {};
    const initialProgress = {};
    const initialUpfileName = {};
    const initialCancelToken = {};
    const updatedFiles = [];
    const source = CancelToken.source();

    if (initialValue !== undefined && initialValue !== null) {
      initialIsUpload[0] = 1;
      initialProgress[0] = 100;
      initialCancelToken[0] = source;
      let fileName;
      let fileSize;
      if (initialValue.indexOf(';') === -1) {
        fileName = initialValue;
      } else {
        fileName = initialValue.split(';')[0].split('name:')[1];
        fileSize = initialValue.split(';')[1].split('name:')[1];
      }
      initialUpfileName[0] = initialValue;
      const newFile = {
        name: fileName?.split('_')[1]?.replace(/(.*)(?=\.)/, '$1'),
        size: formatFileSize(fileSize),
        type: fileName.split('.')[fileName.split('.')?.length - 1],
      };
      updatedFiles.push(newFile);
      setSelectedFiles(prevFiles => [...prevFiles, ...updatedFiles]);
    }
    setProgressUpload(initialProgress);
    setIsUpload(initialIsUpload);
    setUpfileName(initialUpfileName);
    setCancelTokenList(initialCancelToken);
  }, []);
  useEffect(() => {
    if (isUpload[0] === 1) onFileChange(upfileName[0]);
    else if (isUpload[0] === -1) onFileChange(null);
  }, [upfileName]);
  const handleDragOver = event => {
    event.preventDefault();
    setIsDragging(true);
  };
  const handleDrop = event => {
    if (Object.keys(isUpload).filter(x => isUpload[x] !== -1).length < 1) {
      event.preventDefault();
      handleFileChange(event, 0, true);
    }
    setIsDragging(false);
  };

  const [upfile, setUpfile] = useState([]);
  const handleFileChange = (event, index, isDrag = false) => {
    if (Object.keys(isUpload).filter(x => isUpload[x] !== -1).length < 1) {
      let files;
      if (!isDrag) files = Array.from(event.target.files);
      else files = Array.from(event.dataTransfer.files);
      const allow = handleFileUpload(files);
      if (!allow) return;
      let newupfile = upfile;
      newupfile.push(files[0]);
      setUpfile(newupfile);
      const source = CancelToken.source();
      dispatch(
        UploadCV(
          files[0],
          source,
          percentage => {
            let tmpProgress = JSON.parse(JSON.stringify(progressUpload));
            tmpProgress[index] = percentage;
            setProgressUpload(tmpProgress);
          },
          upfileBack => {
            let tmpIsUpload = JSON.parse(JSON.stringify(isUpload));
            tmpIsUpload[index] = 1;
            setIsUpload(tmpIsUpload);
            let tmpUpfileName = JSON.parse(JSON.stringify(upfileName));
            tmpUpfileName[index] = upfileBack;
            setUpfileName(tmpUpfileName);
          }
        )
      );
      let tmpIsUpload = { ...isUpload };
      tmpIsUpload[index] = 0;
      setIsUpload(tmpIsUpload);
      let tmpProgress = { ...progressUpload };
      tmpProgress[index] = 0;
      setProgressUpload(tmpProgress);
      let tmpCanceltoken = { ...cancelTokenList };
      tmpCanceltoken[index] = source;
      setCancelTokenList(tmpCanceltoken);
      setIsDragging(false);
    } else {
      setErrorMessage(<></>);
      setIsErrorMessage(true);
      setIsDragging(false);
    }
    document.getElementById('fileInput').value = null;
  };
  const handleFileUpload = files => {
    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const allowedSize = 5 * 1024 * 1024; // 5MB
    const updatedFiles = [];
    for (let i = 0; i < files?.length; i++) {
      const file = files[i];
      if (allowedTypes.includes(file.type) && file.size <= allowedSize) {
        const newFile = {
          name: file.name,
          size: formatFileSize(file.size),
          type: file.type,
        };
        updatedFiles.push(newFile);
        setErrorMessage(<></>);
        setIsErrorMessage(false);
      } else {
        setErrorMessage(
          <p
            style={{
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px',
              color: '#EE5350',
              marginTop: '0px',
              paddingTop: '0px',
            }}
          >
            Please upload one of the supported format: <br></br>PDF and Word
            <br></br>Max &nbsp;
            <span
              style={{
                fontWeight: 600,
              }}
              onClick={handleTextClick}
            >
              5M
            </span>{' '}
            each.
          </p>
        );
        setIsErrorMessage(true);
        return false;
      }
    }
    setSelectedFiles(prevFiles => [...prevFiles, ...updatedFiles]);
    return true;
  };

  const handleTextClick = () => {
    fileInputRef.current.click();
  };

  const handleDeleteFile = () => {
    setErrorMessage(<></>);
    setIsErrorMessage(false);
    let tmpIsUpload = JSON.parse(JSON.stringify(isUpload));
    tmpIsUpload[0] = -1;
    setIsUpload(tmpIsUpload);
    setSelectedFiles([]);
    setUpfileName({});
    // FileChange();
  };
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };
  const getTypeName = type => {
    if (type === 'application/msword') return 'doc';
    else if (
      type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    )
      return 'docx';
    else return type;
  };
  const CancelToken = axios.CancelToken;
  function abortRead(cancelToken, index) {
    if (
      progressUpload[index] !== 100 ||
      (progressUpload[index] === 100 && isUpload[index] !== 1)
    ) {
      let tmpProgress = JSON.parse(JSON.stringify(progressUpload));
      tmpProgress[index] = 0;
      setProgressUpload(tmpProgress);

      let tmpIsUpload = JSON.parse(JSON.stringify(isUpload));
      tmpIsUpload[index] = -1;
      setIsUpload(tmpIsUpload);

      if (progressUpload[index] !== 100) {
        setCancelTokenList(CancelToken.source());
      }
      setStopUpload(true);
      setSelectedFiles([]);
    }
  }
  const [boxHeight, setBoxHeight] = useState('100px');
  useEffect(() => {
    if (isUpload === {} || isUpload[0] === -1) {
      setBoxHeight('100px');
      setIsUploading(false);
    }
    if (isUpload[0] === 0) {
      setBoxHeight('74px');
      setIsUploading(true);
    }
    if (isUpload[0] === 1) {
      if (!stopUpload) {
        setBoxHeight('56px');
      } else {
        setIsUpload({ 0: -1 });
        setUpfileName({ 0: null });
        setStopUpload(false);
      }
      setIsUploading(false);
    }
  }, [selectedFiles, isUpload]);
  const [borderColor, setBorderColor] = useState('#ccc');
  useEffect(() => {
    setBorderColor(
      iserrorMessage
        ? '#C40000'
        : (isDragging || hovered) &&
          (isUpload[0] === undefined || isUpload[0] === -1)
        ? '#0052CC'
        : '#ccc'
    );
  }, [iserrorMessage, isDragging, hovered, isUpload]);
  return (
    <div style={{ position: 'relative', width: '286px', height: boxHeight }}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        id='file-input'
        className='file-upload'
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          width: '286px',
          height: boxHeight,
          borderRadius: '4px',
          border: iserrorMessage ? '2px solid #FFFFFF' : '1px solid #FFFFFF',
          backgroundColor: !iserrorMessage
            ? isDragging
              ? '#CEDDF9'
              : '#FFFFFF'
            : hovered
            ? '#FFEFEF'
            : '#FFFFFF',
          backgroundImage: `linear-gradient(to right, ${borderColor} 50%, transparent 50%), linear-gradient(to right, ${borderColor} 50%, transparent 50%),
          linear-gradient(to bottom, ${borderColor} 50%, transparent 50%), linear-gradient(to bottom, ${borderColor} 50%, transparent 50%)`,
          backgroundPosition: 'left top, left bottom, left top, right top',
          backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
          backgroundSize: iserrorMessage
            ? '8px 2px, 8px 2px, 2px 8px,2.5px 8px'
            : '8px 1px,8px 1px,1px 8px,1px 8px',
        }}
        onDragOver={handleDragOver}
        onDragLeave={() => setIsDragging(false)}
        onDrop={handleDrop}
      >
        <input
          id='fileInput'
          type='file'
          accept='.pdf,.doc,.docx,image/png,image/jpeg,image/gif'
          style={{ display: 'none' }}
          onChange={e => handleFileChange(e, 0)}
          ref={fileInputRef}
        />
        {(isUpload[0] === undefined || isUpload[0] === -1) && (
          <Stack
            direction='row'
            sx={{
              paddingLeft: '6px',
              paddingTop: iserrorMessage ? '12.5px' : '18px',
              height: '18px',
            }}
          >
            <SvgIcon viewBox='0 0 18 18' sx={{ width: '18px', height: '18px' }}>
              {' '}
              <UploadIcon2 sx={{ width: '18px', height: '18px' }}></UploadIcon2>
            </SvgIcon>
            <p
              style={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '18px',
                color: '#596A7C',
                height: '18px',
                marginTop: '0px',
                marginBottom: '0px',
                paddingLeft: '8px',
              }}
            >
              Drag & Drop or&nbsp;
              <span
                style={{
                  color: '#154AB6',
                  fontWeight: 400,
                }}
                onMouseEnter={e => {
                  e.target.style.color = '#113D95';
                  e.target.style.fontWeight = 600;
                }}
                onMouseLeave={e => {
                  e.target.style.color = '#154AB6';
                  e.target.style.fontWeight = 400;
                }}
                onClick={handleTextClick}
              >
                Choose File
              </span>{' '}
              to upload
            </p>
          </Stack>
        )}

        {isUpload[0] !== -1 && selectedFiles.length > 0 ? (
          <>
            {isUpload[0] === 0 ? (
              <GetProgress
                sx={{
                  marginTop: '12px',
                  marginBottom: '12px',
                  marginLeft: '6px',
                  marginRight: '6px',
                  width: '274px!important',
                  height: '50px!important',
                }}
                FileIcon={ChooseIconByType(selectedFiles[0].type)}
                value={progressUpload[0]}
                abort={() => abortRead(cancelTokenList[0], 0)}
                fileName={selectedFiles[0].name}
                fileSize={selectedFiles[0].size}
                fileType={getTypeName(selectedFiles[0].type)}
                titleMaxWidth={215}
              />
            ) : (
              <GetLabel
                sx={{
                  marginTop: '12px',
                  marginBottom: '12px',
                  marginLeft: '6px',
                  marginRight: '6px',
                  width: '274px!important',
                  height: '32px!important',
                }}
                FileIcon={ChooseIconByType(selectedFiles[0].type)}
                del={() => handleDeleteFile()}
                fileName={selectedFiles[0].name}
                fileSize={selectedFiles[0].size}
                fileMIMEType={getTypeName(selectedFiles[0].type)}
                isDownLoad={false}
                titleMaxWidth={208}
              />
            )}
          </>
        ) : (
          <Stack
            sx={{
              paddingTop: iserrorMessage ? '24.5px' : '34px',
              height: '30px',
            }}
          >
            {iserrorMessage ? (
              <>{errorMessage}</>
            ) : (
              <>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#98A7B6',
                    marginTop: '0px',
                  }}
                >
                  Support PDF and Word
                </p>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#98A7B6',
                    marginTop: '-12px',
                  }}
                >
                  Max <span style={{ fontWeight: 600 }}>5M</span> each.{' '}
                </p>
              </>
            )}
          </Stack>
        )}
      </div>
      <div
        style={{
          position: 'relative',
          width: '360px',
          marginBottom: '0px',
          height: '15px',
          zIndex: 11,
        }}
      >
        {Object.keys(isUpload).filter(x => isUpload[x] !== -1).length > 0 ? (
          iserrorMessage ? (
            <></>
          ) : (
            <>
              <p
                style={{
                  marginTop: 0,
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#596A7C',
                  width: '360px',
                  marginBottom: 0,
                  height: '15px',
                  marginLeft: isUpload[0] === 0 ? '36.5px' : '0px',
                }}
              >
                Support PDF, Word. Max{' '}
                <span style={{ fontWeight: 600 }}>5M</span> each.
              </p>
            </>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

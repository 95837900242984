import {ChecklistStatus, extractHtmlId, FormComponentProps, useForm, VALIDATE_KEY} from "@/components/Form/form.d";
import {Box} from "@mui/material";
import BoxWithShadow from "@/pages/SIPPage/SIPCheckListPage/compontents/BoxWithShadow";
import ChecklistItemBlock from "@/pages/SIPPage/SIPCheckListPage/compontents/ChecklistItemBlock";
import RadioBlock from "@/pages/SIPPage/SIPCheckListPage/compontents/RadioBlock";
import React, {ReactElement, useEffect} from "react";
import {CHECKLIST_PROCEEDING} from "@/pages/SIPPage/SIPCheckListPage/configable/constants";

export interface CheckListItemForImmediateDecisionProps extends FormComponentProps{
    required: boolean,
    label: string,
    info: string,
    optionsLabel?: string,
    options: string[],
}

export default function CheckListItemForImmediateDecision(props: CheckListItemForImmediateDecisionProps): ReactElement{
    const htmlId = extractHtmlId(props);
    const {
        value,
        setValue,
        form,
        setState,
        getState,
    } = useForm<string | null>({
        name: props.name,
        initValue: null,
        initStates: {
            ['html-id']: htmlId,
        }
    });

    const optionsLabel = props.optionsLabel || "Qualified:";

    const status = form?.root().status;
    const locked = form?.root().locked;
    const proceeding = form?.root()[CHECKLIST_PROCEEDING] ?? false;
    const editable = status === ChecklistStatus.IN_PROGRESS && !locked;

    useEffect(() => {
        const validateState = value !== null ? 'checked' : proceeding ? 'checked': 'default';
        setState(VALIDATE_KEY, validateState);
    }, [value, proceeding]);

    return (
        <Box
            id={htmlId}
            sx={{
                marginBottom: '-15px !important',
            }}
        >
            <BoxWithShadow>
                <ChecklistItemBlock
                    title={props.label}
                    info={props.info}
                    needClear={editable}
                    handleClear={() => {
                        setValue(null);
                    }}
                    status={ getState(VALIDATE_KEY) ?? 'default'}
                >
                    <RadioBlock
                        id='immediateDecisionRadioBlock'
                        title={optionsLabel}
                        editing={status === ChecklistStatus.IN_PROGRESS && !locked}
                        value={value}
                        setValue={setValue}
                        radioGropList={props.options}
                    />

                </ChecklistItemBlock>
            </BoxWithShadow>
        </Box>
    );
}
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Stack,
} from '@mui/material';


export default function SaveAndCancelButton(props) {
    const { handleSubmit, handleCancel, isDisabled , isSaving = false} = props;
    return (
        <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
            spacing='50px'
        >
            <Button
                sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    height: '41px',
                    padding: '12px 18px',
                    background: '#FFFFFF',
                    borderRadius: '5px',
                    color: '#154AB6',
                    width: '68px',
                    textTransform: 'none',
                    '&:hover':{
                        fontWeight: 600,
                        background: '#DFE4E8',
                    },
                    '&:active':{
                        fontWeight: 600,
                        background: '#DFE4E8',
                    }
                }}
                disableRipple
                onClick={() => handleCancel()}
            >
                Cancel
            </Button>
            <LoadingButton
                sx={{
                    fontWeight: 600,
                    fontSize: '14px',
                    height: '41px',
                    padding: '12px 18px',
                    background: isSaving?'#6D98EE': isDisabled ?'#DFE4E8':'#0052CC',
                    borderRadius: '5px',
                    color: isDisabled ? '#98A7B6' : '#FFFFFF',
                    width: '114px',
                    textTransform: 'none',
                    '&:hover':{
                        background: '#6D98EE',
                    },
                    '&:active':{
                        background: '#113D95',
                    }
                }}
                disableRipple
                loading={isSaving}
                onClick={() => handleSubmit()}
                disabled={isDisabled}
            >
                Save
            </LoadingButton>
        </Stack >);
}
import {
  Box,
  Checkbox,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { MiniBoldFont } from '@/components/FontStyle';
import CheckboxIcon from '@/assets/CatOverviewUnchecked.svg';
import CheckboxCheckedIcon from '@/assets/CatOverviewChecked.svg';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useRef } from 'react';
import CATJournalIndeterminate from '../../../../assets/catjournalindeterminate.svg';
import UnselectedHoverCheckbox from '../../../../assets/UnselectedHoverCheckbox.svg';
import SelectedHoverCheckbox from '../../../../assets/SelectedHoverCheckbox.svg';
import IndeterminateHoverCheckbox from '../../../../assets/IndeterminateHoverCheckbox.svg';
import UnClickable from '@/assets/CheckIconDisable.svg';
import { ScrollBarY } from '@/componentsphase2/ScrollBar';
import { contextHeightChanged } from '../../../../actions/CommonAction';


const NewStyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F1F3F5',
    color: '#596A7C',
    ...MiniBoldFont,
    textTransform: 'uppercase',
    letterSpacing: '0.96px',
    borderBottom: 'none',
    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '42px',
    padding: '12px 24px 12px 24px',
    boxSizing: 'border-box',
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#FFFFFF',
    color: '#262E35',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.14px',
    height: '42px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    borderBottom: '1px solid #DFE4E8',
  },
}));

const CATJournalBatchUpdateTable= React.forwardRef((props, ref)=> {
  window.x = this;
  const { setSelected = () => {}, category = '',sameHandlingCEError,errorBatchUpdate } = props;
  const dispatch = useDispatch();
  const selector = state => {
    return {
      CATResult: state.CATJournal.CATData,
      selected: state.CATJournal.selectedList,
    };
  };
 
  const { CATResult, selected } = useSelector(selector);
  const [isAllSeleted, setIsAllSelected] = useState(false);
  const [selectedList, setSelectedList] = useState(selected.concat());
  const handleAllSelect = () => {
    if (isAllSeleted) {
      setSelectedList([]);
      setIsAllSelected(false);
    } else {
      const rows = CATResult.result;
      let newSelectedList = selectedList.concat();
      for (let i = 0; i < selected.length; i++) {
        if (newSelectedList.indexOf(selected[i]) === -1) {
          for (let j=0;j<rows.length;j++){
            if(rows[j].journalCode === selected[i] && rows[j].status !== 'Opt-out'&& rows[j].handlingCe?.username!==errorBatchUpdate){
              newSelectedList.push(selected[i]);
            }
          }
        }
      }
      setSelectedList(newSelectedList);
      setIsAllSelected(true);
    }
  };

  const handleClick = row => {
    const selectedIndex = selectedList.indexOf(row.journalCode);
    if (selectedIndex > -1) {
      let newSelectedList = selectedList.concat();
      newSelectedList.splice(selectedIndex, 1);
      setSelectedList(newSelectedList);
      if (isAllSeleted) {
        setIsAllSelected(false);
      }
    } else {
      if (selected.length === selectedList.length + 1) {
        setIsAllSelected(true);
      }
      let newSelectedList = selectedList.concat();
      if(row.status !== 'Opt-out' && row.handlingCe?.username!==errorBatchUpdate ){
        newSelectedList.push(row.journalCode);
      }
      setSelectedList(newSelectedList);
    }
  };
 
  useEffect(()=>{
    setIsAllSelected(true);

    const rows = CATResult.result;
    let newSelectedList = [];
    for (let i=0;i<rows.length;i++){
      if(selectedList.indexOf(rows[i].journalCode) !== -1 && (rows[i].status !== 'Opt-out' && rows[i].handlingCe?.username!==errorBatchUpdate )){
        newSelectedList.push(rows[i].journalCode);
      }
    }
    setSelectedList(newSelectedList);
  },[]);

  useEffect(() => {
    for(let i =0;i<selected.length;i++){
      if(selectedList.indexOf(selected[i]) === -1){
        setIsAllSelected(false);
      }
    }
    setSelected(selectedList);
  }, [selectedList]);
  const [hover, setHover] = useState(false);


  const containerRef = useRef(null);
  const containerBoxRef = useRef(null);
  const scrollYRef = useRef(null);

  const getContextRef = () => {
    return containerBoxRef;
  };

  React.useImperativeHandle(ref, () => ({
    getContextRef,
  }));

  const [ct, setCt] = useState(0);
  useEffect(() => {
    const containerElement = containerRef.current;
    const containerBoxElement = containerBoxRef.current;
    setTimeout(()=>{
      dispatch(
        contextHeightChanged({
          contextWidth: containerElement.offsetWidth,
          contextHeight: containerElement.offsetHeight,
          boxWidth: containerBoxElement.offsetWidth,
          boxHeight: containerBoxElement.offsetHeight,
        })
      );
      setCt(ct+1);
    }, 100);
  },[CATResult]);

  return (
    <Box
      id='CATJournalBatchUpdateTableBox'
      data-selenium-id='CATJournal_Overview-BatchUpdatePop-Table-Box'
    >
      <div
        style={{
          border: '1px solid #DFE4E8',
          borderRadius: '8px 8px 0px 0px',
          overflowX: 'hidden',
          overflowY: 'hidden',
          position: 'relative',
          // paddingRight: '3px',
        }}
      >
      <TableContainer
        ref={containerBoxRef}
        id='cat-journal-batch-update-table-container'
        data-selenium-id='CATJournal_Overview-BatchUpdatePop-Table-TableContainer'
        sx={{
          overflowX: 'auto',
          overflowY: 'scroll',
          border: '1px solid #DFE4E8',
          height: '343px',
          width: '536px',
          scrollbarColor: 'transparent transparent',
            '&::-webkit-scrollbar:vertical': {
              width: 0,
            },
            '&::-webkit-scrollbar-thumb:vertical': {
              backgroundColor: 'transparent',
            },  
        }}
        onScroll={scrollYRef.current?.scrollAsContext}
      >
        <Table
          ref={containerRef}
          stickyHeader
          id='cat-journal-batch-update-table'
          data-selenium-id='CATJournal_Overview-BatchUpdatePop-Table-Table'
          aria-label='customized table'
          size='small'
          sx={{
            letterSpacing: '0.01em',
            color: '#262E35',
          }}
        >
          <TableHead 
            data-selenium-id='CATJournal_Overview-BatchUpdatePop-Table-TableHead'
          >
            <TableRow>
              {/* Select All box */}
              <NewStyledTableCell
                sx={{
                  width: '66px',
                }}
                data-selenium-id='CATJournal_Overview-BatchUpdatePop-Table-NewStyledTableCell1'
              >
                {props.hasOut?
                  (
                    <Checkbox
                      id='CATJournalBatchUpdateTableHeaderSelectAllCheckBox'
                      data-selenium-id='CATJournal_Overview-BatchUpdatePop-Table-HeaderSelectAllCheckBox'
                      checked={false}
                      disabled={true}
                      icon={<UnClickable />}
                      checkedIcon={<UnClickable />}
                      indeterminate={!isAllSeleted && selectedList.length>0}
                      indeterminateIcon={<UnClickable />}
                      sx={{ padding: '3px' }}
                    />
                  ) : (
                    <Checkbox
                      id='CATJournalBatchUpdateTableHeaderSelectAllCheckBox'
                      data-selenium-id='CATJournal_Overview-BatchUpdatePop-Table-HeaderSelectAllCheckBox'
                      checked={isAllSeleted}
                      onChange={handleAllSelect}
                      icon={hover?<UnselectedHoverCheckbox/>:<CheckboxIcon />}
                      checkedIcon={hover?<SelectedHoverCheckbox/>:<CheckboxCheckedIcon />}
                      indeterminate={!isAllSeleted && selectedList.length>0}
                      indeterminateIcon={hover?<IndeterminateHoverCheckbox/>:<CATJournalIndeterminate />}
                      sx={{ padding: '3px' }}
                      disableRipple
                      onMouseEnter={()=>setHover(true)}
                      onMouseLeave={()=>setHover(false)}
                    />
                  )}
              </NewStyledTableCell>
              {/* Journal Code */}
              <NewStyledTableCell
                key='journalCode'
                align='left'
                sx={{
                  width: '233px',
                }}
                data-selenium-id='CATJournal_Overview-BatchUpdatePop-Table-NewStyledTableCell2'
              >
                JOURNAL CODE
              </NewStyledTableCell>
              {/* Development Model */}
              <NewStyledTableCell
                key='developmentMode'
                align='left'
                sx={{
                  maxWidth: '237px',
                  minWidth: '237px',
                }}
                data-selenium-id='CATJournal_Overview-BatchUpdatePop-Table-NewStyledTableCell3'
              >
                {category === 'Handling CE'
                  ? 'HANDLING CE'
                  : 'DEVELOPMENT MODEL'}
              </NewStyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody
            id='cat-journal-batch-update-table-body'
            data-selenium-id='CATJournal_Overview-BatchUpdatePop-Table-TableBody'
          >
            {CATResult.result.map((row, index) => {
              const isItemSelected = (selectedList.indexOf(row.journalCode) > -1 && row.status !=='Opt-out' && row.handlingCe?.username!==errorBatchUpdate);
  
              if (selected.indexOf(row.journalCode) === -1) {
                return null;
              }
              return (
                
                <TableRow key={row.journalCode}>
                  {row.status === 'Opt-out' ? props.setHasOut(true) : console.log(selectedList)}
                  {row.status === 'Opt-out' ? 
                    (<NewStyledTableCell
                      sx={{ backgroundColor: '#FFDDDD !important',}}>
                      <Checkbox
                        id={`catJournalBatchUpdateTableRow-Checkbox-${index}`}
                        data-selenium-id={`CatJournalBatchUpdateTableRow-Checkbox-${index}`}
                        onClick={handleClick.bind(this, row)}
                        checked={false}
                        icon={<CheckboxIcon />}
                        sx={{ padding: '3px',}}
                        checkedIcon={<CheckboxCheckedIcon />}
                        disableRipple
                        disabled={true}
                      />
                    </NewStyledTableCell>
                    ): 
                    (<NewStyledTableCell
                      sx={{
                        backgroundColor :  row.handlingCe?.username===errorBatchUpdate?'#FFDDDD !important':'#FFFFFF',
                      }}>
                      
                      <Checkbox
                        id={`catJournalBatchUpdateTableRow-Checkbox-${index}`}
                        data-selenium-id={`CatJournalBatchUpdateTableRow-Checkbox-${index}`}
                        onClick={handleClick.bind(this, row)}
                        checked={isItemSelected}
                        icon={<CheckboxIcon />}
                        sx={{ padding: '3px'}}
                        checkedIcon={<CheckboxCheckedIcon />}
                        disableRipple
                      />
                    </NewStyledTableCell>)
                  }
                  {row.status === 'Opt-out' ? 
                    (<NewStyledTableCell
                      id={`catJournalBatchUpdateTableRow-journalCode-${index}`}
                      data-selenium-id={`CatJournal-BatchUpdateTableRow-JournalCode-${index}`}
                      sx={{
                        backgroundColor :  '#FFDDDD !important',
                        fontSize: '14px !important',
                      }}
                      
                    >
                    
                      {row.journalCode}
                    </NewStyledTableCell>) :
                    (<NewStyledTableCell
                      id={`catJournalBatchUpdateTableRow-journalCode-${index}`}
                      data-selenium-id={`CatJournal-BatchUpdateTableRow-JournalCode-${index}`}
                      sx={{
                        backgroundColor :  row.handlingCe?.username===errorBatchUpdate?'#FFDDDD !important':'#FFFFFF',
                        fontSize: '14px !important',
                      }}
                    >
                      {row.journalCode}
                    </NewStyledTableCell>
                    )}
                  {row.status === 'Opt-out' ?
                    (
                      <NewStyledTableCell
                        id={`catJournalBatchUpdateTableRow-developmentModel-${index}`}
                        data-selenium-id={`CatJournal-BatchUpdateTableRow-DevelopmentModel-${index}`}
                        sx={{
                          backgroundColor : '#FFDDDD !important',
                          fontSize: '14px !important',
                        }}
                      >
                        {category === 'Handling CE'
                          ? row.handlingCe?.username
                          : row.developmentModel}
                      </NewStyledTableCell>
                    ):(
                      <NewStyledTableCell
                        id={`catJournalBatchUpdateTableRow-developmentModel-${index}`}
                        data-selenium-id={`CatJournal-BatchUpdateTableRow-DevelopmentModel-${index}`}
                        sx={{
                          backgroundColor :  row.handlingCe?.username===errorBatchUpdate?'#FFDDDD !important':'#FFFFFF',
                          fontSize: '14px !important',
                        }}
                      >
                        {
                        category === 'Handling CE'
                          ? row.handlingCe?.username
                          : row.developmentModel}
                      </NewStyledTableCell>
                    )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ScrollBarY ref={scrollYRef} contextHolderRef={containerBoxRef}/>
      </div>
      <Box
        id='cat-journal-table-batch-update-footer-box'
        data-selenium-id='CATJournal_Overview-BatchUpdatePop-Table-FooterBox'
      >
        <Box
          sx={{
            background: '#F1F3F5',
            width: '536px',
            borderRadius: '0px 0px 8px 8px',
            border: '1px solid #DFE4E8',
            borderTop: 'none',
            height: '20px',
          }}
        ></Box>
      </Box>
    </Box>
  );
});

export default CATJournalBatchUpdateTable;

import { Stack } from '@mui/material';
import SIPStepTitle from './SIPStepTitle';
import SIPStepSubtitle from './SIPStepSubtitle';
import PropTypes from 'prop-types';
import stageMapping from './backend-mapping';

const backgroundColors = {
  accept: '#E7F6F8',
  editorReview: '#E8E9F9',
  initialReview: '#FFF0DD',
  submit: '#CEDDF9',
  ideation: '#CEDDF9',
  revision: '#FFDDDD',
  completeAccept: '#ECFDF5',
  completeReject: '#F1F3F5',
  shelved: '#F1F3F5',
  jpmInquiry: '#E8E9F9',
  start: '#FFFFFF',
};

const borderColors = {
  accept: '0.5px solid #45BCCA',
  editorReview: '0.5px solid #9098E2',
  initialReview: '0.5px solid #D97706',
  submit: '0.5px solid #0052CC',
  ideation:'0.5px solid #0052CC',
  revision: '0.5px solid #C40000',
  completeAccept: '0.5px solid #42C1B3',
  completeReject: '0.5px solid #98A7B6',
  shelved: '0.5px solid #98A7B6',
  jpmInquiry: '0.5px solid #9098E2',
  start: '1px solid #0052CC',
};

const titleColors = {
  accept: '#00A4B7',
  editorReview: '#7882DC',
  initialReview: '#D97706',
  submit: '#113D95',
  ideation:'#113D95',
  revision: '#C40000',
  completeAccept: '#0D6A61',
  completeReject: '#596A7C',
  shelved: '#596A7C',
  jpmInquiry: '#7882DC',
  start: ' #113D95',
};

const subtitleColors = {
  editorReview: '#6D98EE',
  initialReview: '#FFB152',
  submit: '#45BCCA',
  ideation:'#45BCCA',
  revision: '#FF8180',
  // 下面几个stage是不会有subtitle的，但是为了避免数据问题，给他们加上颜色
  jpmInquiry: '#6D98EE',
  accept: '#FFFFFF',
  completeAccept: '#FFFFFF',
  completeReject: '#98A7B6',
  shelved: '#98A7B6',
  start: '#48A79A',
};

/**
 * SIPStep的具体实现，这个组件包括了样式
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function SIPStepBase(props) {
  const { id, step, origin, sx, ...other } = props;

  const frontendStepName = stageMapping(step);

  return (
    <Stack
      id={`sipStepBase-${id}`}
      data-selenium-id={`SIPPage_Components-Step-Base-Stack-${id}`}
      justifyContent='center'
      alignItems='center'
      marginTop={0}
      bgcolor={backgroundColors[frontendStepName] ?? 'primary.main'} // use step to get the color
      border={borderColors[frontendStepName] ?? 'unset'}
      sx={{
        padding: '0px 0px',
        borderRadius: '50px',
        width: '147px',
        height: '41px',
        minHeight: '41px',
        '&.MuiStack-root': {
          marginTop: 0,
        },
        ...sx,
      }}
      {...other}
    >
      <SIPStepTitle
        id={`sipStepTitle-${id}`}
        data-selenium-id={`SIPPage_Components-Step-SIPStepBase-Title-${id}`}
        step={step}
        color={titleColors[frontendStepName]}
      />
      {/* {origin && (
        <SIPStepSubtitle
          id={`sipStepSubtitle-${id}`}
          data-selenium-id={`SIPPage_Components-Step-SIPStepBase-Subtitle-${id}`}
          origin={origin}
          color={subtitleColors[frontendStepName]}
        />
      )} */}
    </Stack>
  );
}

SIPStepBase.propTypes = {
  id: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  origin: PropTypes.string,
  sx: PropTypes.object,
};

export default SIPStepBase;

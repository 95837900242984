import './index.css';
import React, { useState, useEffect } from 'react';
import { Box, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {getUserInfo} from "@/actions/SIMT-User/User";

function MyAccountPage() {
  const dispatch = useDispatch();
  const selector = state => {
    return {
      firstName: state.User.firstName,
      lastName: state.User.lastName,
      email: state.User.email,
      role: state.User.role,
      group: state.User.group,
    };
  };
  const { firstName, lastName, email, role, group } = useSelector(selector);

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  return (
    <Box display='flex' alignItems='center' justifyContent='center'>
      <Box id='AccountBox' data-selenium-id='AuthPage_MyAccount-AccountBox'>
        <div className='AccountTitle'>Account Details</div>
        <Divider
          id='DivideLine'
          data-selenium-id='AuthPage_MyAccount-DivideLine1'
        />
        <Box id='UserName' data-selenium-id='AuthPage_MyAccount-UserName'>
          <div className='DisplayTitle'>Name</div>
          <div className='DisplayInfo'>{firstName + ' ' + lastName}</div>
        </Box>
        <Divider
          id='DivideLine'
          data-selenium-id='AuthPage_MyAccount-DivideLine2'
        />
        <Box id='UserEmail' data-selenium-id='AuthPage_MyAccount-UserEmail'>
          <div className='DisplayTitle'>Email Address</div>
          <div className='DisplayInfo'>{email}</div>
        </Box>
        <Divider
          id='DivideLine'
          data-selenium-id='AuthPage_MyAccount-DivideLine3'
        />
        <Box id='UserRole' data-selenium-id='AuthPage_MyAccount-UserRole'>
          <div className='DisplayTitle'>Role</div>
          <div className='DisplayInfo'>{role}</div>
        </Box>
        <Divider
          id='DivideLine'
          data-selenium-id='AuthPage_MyAccount-DivideLine4'
        />
        <Box id='UserGroup' data-selenium-id='AuthPage_MyAccount-UserGroup'>
          <div className='DisplayTitle'>Group</div>
          <div className='DisplayInfo'>{group}</div>
        </Box>
      </Box>
    </Box>
  );
}

export default MyAccountPage;

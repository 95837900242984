import {
  SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR,
  SET_SIP_EBM_REVIEW_DATA,
  SET_UPLOAD_REVISE_DATA_STATUS,
  SET_SIP_EBM_REVIEW_LOADING,
} from './SIPAction';
import { axios_instance } from '@/utils/axios_instance';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';
export const loadSIPEbmReviewData = (token, mode = 'run') => {
  const getUrl = () => {
    return `/api/v1/sip-service/special-issue-proposals/ebms-review/${token}`;
  };

  return async dispatch => {
    try {
      /** @type {import('@/utils/axios-response').AxiosResponse<any, number>} */
      const res = await axios_instance.get(getUrl(), {});

      if (res.data.code === 200) {
        dispatch({
          type: SET_SIP_EBM_REVIEW_DATA,
          /** @type {import("@/pages/SIPPage/RevisePage").SIPReviseData} */
          data: { ...res.data.data },
        });
      } else if (res.data.code === 16032 || res.data.code === 16043) {
        dispatch({
          type: SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR,
          /** @type {DownloadReviseDataErrorType} */ data: 'has been submitted',
        });
      } else if (res.data.code === 16033) {
        dispatch({
          type: SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR,
          /** @type {DownloadReviseDataErrorType} */ data: 'deadline pass',
        });
      } else if (res.data.code === 16030 || res.data.code === 16041) {
        dispatch({
          type: SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR,
          /** @type {DownloadReviseDataErrorType} */ data: 'path not find',
        });
      } else if (res.data.code === 16039 || res.data.code === 16044) {
        dispatch({
          type: SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR,
          /** @type {DownloadReviseDataErrorType} */ data: 'shelved',
        });
      } else if (res.data.code === 16112) {
        dispatch({
          type: SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR,
          /** @type {DownloadReviseDataErrorType} */ data: 'initial review revision cancelled',
        });
      } else {
        dispatch({
          type: SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR,
          /** @type {DownloadReviseDataErrorType} */ data: 'otherError',
        });
      }
    } catch (err) {
      dispatch({
        type: SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR,
        /** @type {DownloadReviseDataErrorType} */ data: 'otherError',
      });
      console.error(err);
    } finally{
        dispatch({
          type: SET_SIP_EBM_REVIEW_LOADING,
        });
    }
  };
};

export const submitEbmReviewData = (token, data) => {
  /**
   * @param {import("redux").Dispatch} dispatch
   */
  return async dispatch => {
    try {
      const res = await axios_instance.put(
        `/api/v1/sip-service/special-issue-proposals/ebms-review/${token}`,
        data
      );
      const { code } = res.data;
      if (code === 200) {
        console.log('success ops');
        location.reload();
      } else {
        console.log('fail ops');
        dispatch({
          type: SET_UPLOAD_REVISE_DATA_STATUS,
          /** @type {UploadReviseDataStatus} */ data: 'error',
        });
        dispatch(
          setSnackbarMessageAndOpen(
            'sipDetail.ebmReview.submitReviewError',
            {},
            SEVERITIES.error
          )
        );
      }
    } catch (err) {
      dispatch({
        type: SET_UPLOAD_REVISE_DATA_STATUS,
        /** @type {UploadReviseDataStatus} */ data: 'error',
      });
      dispatch(
        setSnackbarMessageAndOpen(
          'sipDetail.ebmReview.submitReviewError',
          {},
          SEVERITIES.error
        )
      );
      console.error(err);
    }
  };
};

import {
  Box,
  createTheme,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  ThemeProvider,
} from '@mui/material';
import { useEffect, useState } from 'react';
import SIPDetailGEInfomationEditChevronDown from '@/assets/SIPDetailGEInfomationEditChevronDown.svg';
import { SmallFont } from '../../../../components/FontStyle';
import { styled } from '@mui/system';
import Checkbox from '@mui/material/Checkbox';
import CATJournalBatchUpdateRadioSelect from '@/assets/CATJournalBatchUpdateRadioSelect.svg';
import CATJournalBatchUpdateRadio from '@/assets/CATJournalBatchUpdateRadio.svg';
import CATJournalBatchUpdateSearch from '@/assets/CATJournalBatchUpdateSearch.svg';
import './batchUpdateMenuItem.css';

const theme = createTheme({
  palette: {
    error: {
      main: '#FC5555',
    },
  },
});

export function SelectBox(props) {
  const {
    id = 'test',
    dropList = [],
    value = '',
    placeholder = '',
    needSearch = false,
    loadFun = () => { },
    ...others
  } = props;
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const MenuProps = {
    sx: {
      marginTop: '6px',
    },
    onkeydown: e => {
      e.preventDefault();
    },
    MenuListProps: {
      sx: {
        padding: '12px 0px 12px 0px',
        maxHeight: '219px',
        boxSizing: 'border-box',
      },
    },
    PaperProps: {
      sx: {
        boxShadow: '0px 2px 8px 0px rgba(38, 46, 53, 0.1)',
      }
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const IconComponent = () => {
    if (open) {
      return (
        <IconButton
          disableRipple
          sx={{
            transform: 'rotate(180deg)',
            position: 'absolute',
            right: '12px',
            padding: '0px',
          }}
          onClick={() => {
            setOpen(false);
          }}
          data-selenium-id='CATJournal_Overview-BatchUpdatePop-SelectBox-IconButton1'
        >
          <SIPDetailGEInfomationEditChevronDown />
        </IconButton>
      );
    } else {
      return (
        <IconButton
          disableRipple
          onClick={() => {
            setOpen(true);
          }}
          sx={{ position: 'absolute', right: '12px', padding: '0px' }}
          data-selenium-id='CATJournal_Overview-BatchUpdatePop-SelectBox-IconButton2'
        >
          <SIPDetailGEInfomationEditChevronDown />
        </IconButton>
      );
    }
  };
  function hasValue(value) {
    if (value) {
      if (value instanceof Array) {
        // 数组情况，长度非0， 零号元素非空
        return value.length > 0 && value[0] && value[0] !== '';
      } else return value !== '';
    } else {
      return false;
    }
  }
  useEffect(() => {
    loadFun(inputValue);
  }, [inputValue]);
  return (
    <ThemeProvider
      theme={theme}
      data-selenium-id='CATJournal_Overview-BatchUpdatePop-SelectBox-ThemeProvider'
    >
      <Select
        {...others}
        sx={{
          '&': { width: '358px', height: '40px' },
          '& .MuiSelect-select': {
            background: '#FFFFFF',
            borderRadius: '3px',
            boxSizing: 'border-box',
            padding: '16px 12px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '17.5px',
            color: hasValue(value) ? '#262E35' : '#C5C4C4',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #DFE4E8',
          },
        }}
        id={id}
        data-selenium-id={id}
        value={value}
        IconComponent={IconComponent}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        displayEmpty
        renderValue={value => {
          return hasValue(value) ? value : placeholder;
        }}
        MenuProps={MenuProps}
        onkeydown={e => {
          e.preventDefault();
        }}
      >
        {dropList.map((item, index) => {
          if (index === 0 && needSearch) {
            return (
              <Box
                key={`${id}-${index}`}
                sx={{ margin: '3px 0px 6px 0px' }}
                data-selenium-id='CATJournal_Overview-BatchUpdatePop-SelectBox-Box'
              >
                <TextField
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ padding: '6px', lineHeight: '12px' }}>
                        <CATJournalBatchUpdateSearch></CATJournalBatchUpdateSearch>
                      </Box>
                    ),
                  }}
                  sx={{
                    '&': {
                      width: '100%',
                    },
                    '& .MuiOutlinedInput-root': {
                      background: '#EFEFF0',
                      borderRadius: '4px',
                      boxSizing: 'border-box',
                      padding: '4px 0px',
                    },
                    '& .MuiOutlinedInput-input': {
                      padding: '0px 0px',
                      fontFamily: 'Open Sans',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      fontSize: '12px',
                      lineHeight: '16px',
                      color: '#262E35',
                      height: '24px',
                      letterSpacing: '-0.011em',
                      '&::placeholder': {
                        fontFamily: 'Open Sans',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#828282',
                        opacity: '1',
                      },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                  value={inputValue}
                  onChange={e => {
                    setInputValue(e.target.value);
                  }}
                />
              </Box>
            );
          } else {
            return (
              <MenuItem
                value={item}
                key={`${id}-${index}`}
                className='batch-update-menuitem'
                sx={{
                  padding: '0px',
                  height: '24px',
                  color: '#596A7C',
                }}
                data-selenium-id='CATJournal_Overview-BatchUpdatePop-SelectBox-MenuItem'
              >
                <Stack direction='row' spacing={1.5}>
                  <Box
                    sx={{
                      fontFamily: 'Open Sans',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '12px',
                      lineHeight: '20px',
                      paddingLeft: '12px',
                    }}
                  >
                    {item}
                  </Box>
                </Stack>
              </MenuItem>
            );
          }
        })}
      </Select>
    </ThemeProvider>
  );
}

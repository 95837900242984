/**
 * 用于一些通用的行为
 */

import { axios_instance } from '@/utils/axios_instance';
// create Redux action types
export const CHANGE_SIDER_VALUE = 'CHANGE_SIDER_VALUE';
export const SAVE_FOOTER_LINK = 'SAVE_FOOTER_LINK';
export const REFRESH_DATA = 'REFRESH_DATA';
export const BODY_HEIGHT_ACTION = 'BODY_HEIGHT_ACTION';
export const BODY_HEIGHT_ACTION_WITH_KEY = 'BODY_HEIGHT_ACTION_WITH_KEY';

export const RAISE_ERROR = 'RAISE_ERROR';
export const RESOLVE_ERROR = 'RESOLVE_ERROR';

export const SET_KEY = 'SET_KEY';
export const DEL_KEY = 'DEL_KEY';

// Create Redux action creators that return an action
export const changeSiderValue = value => ({
  type: CHANGE_SIDER_VALUE,
  data: value,
});

export function getFooterLink() {
  return async dispatch => {
    let url = '/api/v1/user-service/footerLink/configs';
    try {
      const res = await axios_instance.get(url);
      if (res.data.code === 200) {
        //
        const configMap = {};
        res.data.data.forEach(item => {
          configMap[item.key] = item.value;
        });
        localStorage.setItem('rightLinkValue', configMap.rightLink);
        localStorage.setItem('copyrightLinkValue', configMap.copyrightLink);
        localStorage.setItem('termsOfUseLinkValue', configMap.termsOfUseLink);
        localStorage.setItem('privacyLinkValue', configMap.privacyLink);
        localStorage.setItem('ICPLinkValue', configMap.ICPLink);  // this is deprecated.
        localStorage.setItem('hasFooterLink', true);
        dispatch({ type: SAVE_FOOTER_LINK });
      }
    } catch (err) {}
  };
}

/**
 * 用于处理一个页面数据的刷新
 * @returns
 */
export const refreshData = () => ({
  type: REFRESH_DATA,
});

export const contextHeightChanged = data => ({
  type: BODY_HEIGHT_ACTION_WITH_KEY,
  data: {
    key: null,
    context: data,
  },
});

export const tableHeightChanged = (data, tableKey) => ({
  type: BODY_HEIGHT_ACTION_WITH_KEY,
  data: {
    key: tableKey,
    context: data,
  },
});

export const raiseError = ({ code, message, ...rest }) => ({
  type: RAISE_ERROR,
  data: {
    code,
    message,
    ...rest,
  },
});

export const resolveError = code => ({
  type: RESOLVE_ERROR,
  data: code,
});

export const setKey = (key, value) => ({
  type: SET_KEY,
  data: {
    [key]: value,
  },
});

export const delKey = key => ({
  type: DEL_KEY,
  data: key,
});

import React from 'react';
import {
  Box,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelSelectAll,
  cancelSelectAllUser,
  selectAll,
  selectAllUser,
} from '../../../../actions/SeleteAction';
import '../../../../App.css';

export const lastCell = (title) => {
  if (title === 'Publication Detail') {
    return '50px';
  } else {
    return '0px';
  }
};

export const otherCell = (item) => {
  if (item.label === 'ACTUAL PUBLICATION DATE' || item.label === 'ISSUE PUBLICATION LINK') {
    return '20px';
  } else {
    return '0px';
  }
};

export const firstCell = title => {
  if (title === 'Submission Overview')
    return '24px';
  if (title === 'Publication Detail')
    return '24px';
  return '0px';
};

export function NewTableHeader(props) {
  //order:排序顺序，asc、desc、none
  //orderBy：根据某一字段排序
  //items：表头元素
  //hasCheckbox:表头是否有复选框
  //isAllCheck：是否全选
  //onSelectAllClick：全选后的逻辑处理
  //CheckboxColor：CheckBox的颜色，默认是MUI的Primary
  const {
    title,
    items,
    color = '#505050',
    backgroundColor = '#E3E8F2',
  } = props;

  // 改变标题行的颜色
  const NewStyledTableCell = styled(TableCell)(props => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: backgroundColor,
      color: color,
      // backgroundColor: "green",
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      textTransform: 'uppercase',
      letterSpacing: '0.08em',
    },
    [`&.${tableCellClasses.root}`]: {
      height: '42px',
      padding: 0,
      paddingLeft: '24px',
      paddingTop: '12px',
      paddingBottom: '12px',
      // paddingLeft:
      //   props.index == 0
      //     ? firstCell(props.title)
      //     : lastCell(props.index, props.length),
      // paddingRight: props.index == props.length - 1 ? '16px' : 0,
    },
  }));

  return (
    <TableHead data-selenium-id='SIDetail_TableHead'>
      <TableRow data-selenium-id='SIDetail_TableHeadRow'>
        {/* {title === 'Submission Overview' ? null : ( //此为删除的空白表头第一个位置
          <NewStyledTableCell
            style={{ 'text-transform': 'capitalize' }}
            sx={{
              maxWidth: '40px',
              minWidth: '40px',
            }}
            data-selenium-id='SIDetail_TableHeadCell'
          >
            <Box
              id={`myTableHeaderItem`}
              data-selenium-id='SIDetail_TableHeaderItem'
            ></Box>
          </NewStyledTableCell>
        )} */}

        {items.map((item, index) => (
          <NewStyledTableCell
            title={title}
            index={index}
            length={items.length}
            style={{ 'text-transform': 'capitalize' }}
            key={item.id}
            // align={item.align ? item.align : 'left'}
            sx={{
              maxWidth: item.maxWidth,
              minWidth: item.minWidth,
            }}
            data-selenium-id={`SIDetail_TableHeadCell-${index}`}
          >
            <Box
              id={`myTableHeaderItem${index}`}
              data-selenium-id={`SIDetail_TableHeaderItem-${index}`}
            >
              {item.label}
            </Box>
          </NewStyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

import { Box } from '@mui/material';
import React from 'react';
import { css } from "@emotion/react";

export default function BoxWithShadow(props) {
  const { sx,mustTransferBack=false, ...other } = props;

  return (
    <Box
      sx={{
        boxShadow: '0px 2px 8px 0px #262E351A',
        backgroundColor: '#FFFFFF',
        borderRadius: '5px',
        opacity: mustTransferBack?'0.5':'1',
        ...sx,
      }}
      {...other}
    />
  );
}

import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { ChartGroups } from '@/components/Chart/ChartGroups';
import { ChartList } from '@/components/Chart/ChartList';
import styles from './index.module.scss';
import { changeSiderOpen } from '@/actions/SIMT-User/User/UserManageAction';
import { getFyYearFromDate } from '@/utils/date_string';

import SubjectGroupSubmissionImg from '@/assets/ReportDiagrams/Articles/AnnualTrends/SubjectGroupSubmission.png';
import SubjectGroupAcceptanceImg from '@/assets/ReportDiagrams/Articles/AnnualTrends/SubjectGroupAcceptance.png';
import SubjectGroupPublicationImg from '@/assets/ReportDiagrams/Articles/AnnualTrends/SubjectGroupPublication.png';
import SubjectGroupAllInOneImg from '@/assets/ReportDiagrams/Articles/AnnualTrends/SubjectGroupAllInOne.png';

import BusinessDivisionSubmissionImg from '@/assets/ReportDiagrams/Articles/AnnualTrends/BusinessDivisionSubmission.png';
import BusinessDivisionAcceptanceImg from '@/assets/ReportDiagrams/Articles/AnnualTrends/BusinessDivisionAcceptance.png';
import BusinessDivisionPublicationImg from '@/assets/ReportDiagrams/Articles/AnnualTrends/BusinessDivisionPublication.png';
import BusinessDivisionAllInOneImg from '@/assets/ReportDiagrams/Articles/AnnualTrends/BusinessDivisionAllInOne.png';

import NormalChart from './Diagrams';
import AllInOneChart from './AllInOneDiagrams';

import {
  ARTICLE,
  ANNUAL_TRENDS_IN_SUBJECT_GROUP_LEVEL,
  ANNUAL_TRENDS_IN_BUSINESS_DIVISION_LEVEL,
  SUBMISSION,
  ACCEPTANCE,
  PUBLICATION,
  SUBJECT_GROUP,
  BUSINESS_DIVISION, 
  All_IN_ONE,
} from './Constants/index';

const SG_TABLE_DOWNLOAD_URL = `/reports/export/article/annual-trends/export/sg`;
const BD_TABLE_DOWNLOAD_URL = `/reports/export/article/annual-trends/export/bd`;
// basic diagram setting
const DIAGRAM_SETTINGS = [
  // 1st group
  {
    groupName: 'SI ' + SUBMISSION + ' Annual Trends',
    chartName: SUBJECT_GROUP,
    hasCategoryFilter: true,
    hasSelectAllOption: true,
    type: SUBMISSION.toLowerCase(),
    url: '/reports/article/diagram/annual-trends/sg',
    tableDownloadUrl: SG_TABLE_DOWNLOAD_URL, // a const string for 6 diagram
    reportType: 'sg',
    tableDownloadTitle: SUBMISSION
  },
  {
    groupName: 'SI ' + ACCEPTANCE + ' Annual Trends',
    chartName: SUBJECT_GROUP,
    hasCategoryFilter: true,
    hasSelectAllOption: true,
    type: ACCEPTANCE.toLowerCase(),
    url: '/reports/article/diagram/annual-trends/sg',
    tableDownloadUrl: SG_TABLE_DOWNLOAD_URL, // a const string for 6 diagram
    reportType: 'sg',
    tableDownloadTitle: ACCEPTANCE
  },
  {
    groupName: 'SI ' + PUBLICATION + ' Annual Trends',
    chartName: SUBJECT_GROUP,
    hasCategoryFilter: true,
    hasSelectAllOption: true,
    type: PUBLICATION.toLowerCase(),
    url: '/reports/article/diagram/annual-trends/sg',
    tableDownloadUrl: SG_TABLE_DOWNLOAD_URL,
    reportType: 'sg',
    tableDownloadTitle: PUBLICATION
  },
  {
    groupName: 'SI ' + All_IN_ONE + ' Annual Trends',
    chartName: SUBJECT_GROUP,
    hasCategoryFilter: true,
    catagoryAttributeName: 'sg',
    singleSelectListUrl: '/reports/article/get-sg/',
    url: '/reports/article/diagram/annual-trends/sg/all-in-one/{date}',
    tableDownloadUrl: SG_TABLE_DOWNLOAD_URL,
    tableDownloadTitle: All_IN_ONE
  },
  // 2nd group
  {
    groupName: 'SI ' + SUBMISSION + ' Annual Trends',
    chartName: BUSINESS_DIVISION,
    hasCategoryFilter: true,
    hasSelectAllOption: false,
    type: SUBMISSION.toLowerCase(),
    url: '/reports/article/diagram/annual-trends/bd',
    tableDownloadUrl: BD_TABLE_DOWNLOAD_URL,
    reportType: 'bd',
    tableDownloadTitle: SUBMISSION
  },
  {
    groupName: 'SI ' + ACCEPTANCE + ' Annual Trends',
    chartName: BUSINESS_DIVISION,
    hasCategoryFilter: true,
    hasSelectAllOption: false,
    type: ACCEPTANCE.toLowerCase(),
    url: '/reports/article/diagram/annual-trends/bd',
    tableDownloadUrl: BD_TABLE_DOWNLOAD_URL,
    reportType: 'bd',
    tableDownloadTitle: ACCEPTANCE
  },
  {
    groupName: 'SI ' + PUBLICATION + ' Annual Trends',
    chartName: BUSINESS_DIVISION,
    hasCategoryFilter: true,
    hasSelectAllOption: false,
    type: PUBLICATION.toLowerCase(),
    url: '/reports/article/diagram/annual-trends/bd',
    tableDownloadUrl: BD_TABLE_DOWNLOAD_URL,
    reportType: 'bd',
    tableDownloadTitle: PUBLICATION
  },
  {
    groupName: 'SI ' + All_IN_ONE + ' Annual Trends',
    chartName: BUSINESS_DIVISION,
    hasCategoryFilter: true,
    catagoryAttributeName: 'busDiv',
    singleSelectListUrl: '/reports/article/get-busdiv/',
    url: '/reports/article/diagram/annual-trends/bd/all-in-one/{date}',
    tableDownloadUrl: BD_TABLE_DOWNLOAD_URL,
    tableDownloadTitle: All_IN_ONE
  },
];

// settings for ChartGroups on overview page
const CHART_GROUP_TITLES = [
  {
    groupTitle: ANNUAL_TRENDS_IN_SUBJECT_GROUP_LEVEL,
    levels: [
      {
        chartIndex: 0,
        title: SUBMISSION,
        img: SubjectGroupSubmissionImg,
      },
      {
        chartIndex: 1,
        title: ACCEPTANCE,
        img: SubjectGroupAcceptanceImg,
      },
      {
        chartIndex: 2,
        title: PUBLICATION,
        img: SubjectGroupPublicationImg,
      },
      {
        chartIndex: 3,
        title: All_IN_ONE,
        img: SubjectGroupAllInOneImg,
      },
    ],
  },
  {
    groupTitle: ANNUAL_TRENDS_IN_BUSINESS_DIVISION_LEVEL,
    levels: [
      {
        chartIndex: 4,
        title: SUBMISSION,
        img: BusinessDivisionSubmissionImg,
      },
      {
        chartIndex: 5,
        title: ACCEPTANCE,
        img: BusinessDivisionAcceptanceImg,
      },
      {
        chartIndex: 6,
        title: PUBLICATION,
        img: BusinessDivisionPublicationImg,
      },
      {
        chartIndex: 7,
        title: All_IN_ONE,
        img: BusinessDivisionAllInOneImg,
      },
    ],
  },
];

const CHART_LIST_TITLES = [
  // subject group level
  {
    groupTitle: 'SI ' + SUBMISSION + ' Annual Trends',
    levels: [
      {
        chartIndex: 0,
        title: SUBJECT_GROUP,
        img: SubjectGroupSubmissionImg,
      },
    ],
  },
  {
    groupTitle: 'SI ' + ACCEPTANCE + ' Annual Trends',
    levels: [
      {
        chartIndex: 1,
        title: SUBJECT_GROUP,
        img: SubjectGroupAcceptanceImg,
      },
    ],
  },
  {
    groupTitle: 'SI ' + PUBLICATION + ' Annual Trends',
    levels: [
      {
        chartIndex: 2,
        title: SUBJECT_GROUP,
        img: SubjectGroupPublicationImg,
      },
    ],
  },
  {
    groupTitle: 'SI ' + All_IN_ONE + ' Annual Trends',
    levels: [
      {
        chartIndex: 3,
        title: SUBJECT_GROUP,
        img: SubjectGroupAllInOneImg,
      },
    ],
  },
  // business division level
  {
    groupTitle: 'SI ' + SUBMISSION + ' Annual Trends',
    levels: [
      {
        chartIndex: 4,
        title: BUSINESS_DIVISION,
        img: BusinessDivisionSubmissionImg,
      },
    ],
  },
  {
    groupTitle: 'SI ' + ACCEPTANCE + ' Annual Trends',
    levels: [
      {
        chartIndex: 5,
        title: BUSINESS_DIVISION,
        img: BusinessDivisionAcceptanceImg,
      },
    ],
  },
  {
    groupTitle: 'SI ' + PUBLICATION + ' Annual Trends',
    levels: [
      {
        chartIndex: 6,
        title: BUSINESS_DIVISION,
        img: BusinessDivisionPublicationImg,
      },
    ],
  },
  {
    groupTitle: 'SI ' + All_IN_ONE + ' Annual Trends',
    levels: [
      {
        chartIndex: 7,
        title: BUSINESS_DIVISION,
        img: BusinessDivisionAllInOneImg,
      },
    ],
  },
];

const DiagramContent = () => {
  // get showTable from URL
  let [searchParams, setSearchParams] = useSearchParams();
  let selectedChartIndex = searchParams.get('selectedChartIndex');
  if (selectedChartIndex == null) {
    selectedChartIndex = -1;
  } else {
    selectedChartIndex = parseInt(selectedChartIndex);
  }

  const setShowTableNav = chartIndex => {
    setSearchParams({ showTable: false, selectedChartIndex: chartIndex });
  };

  const siderOpen = useSelector(state => state.UserManage.siderOpen);
  const dispatch = useDispatch();

  const {
    groupName,
    chartName,
    hasCategoryFilter,
    hasSelectAllOption,
    type,
    singleSelectListUrl,
    catagoryAttributeName,
    url,
    tableDownloadTitle,
    tableDownloadUrl,
    reportType,
  } = useMemo(() => {
    if (
      selectedChartIndex >= 0 &&
      selectedChartIndex < DIAGRAM_SETTINGS.length
    ) {
      return DIAGRAM_SETTINGS[selectedChartIndex];
    } else {
      return {
        groupName: '',
        chartName: '',
        hasCategoryFilter: false,
        hasSelectAllOption: false,
        type: null,
        singleSelectListUrl: null,
        catagoryAttributeName: null,
        url: null,
        tableDownloadTitle: null,
        tableDownloadUrl: null,
        reportType: null,
      };
    }
  }, [selectedChartIndex]);

  const { selectMonthDate } = useSelector(state => ({
    selectMonthDate: state.Report.selectDate,
  }));

  const selectedFY = useMemo(() => {
    return getFyYearFromDate(selectMonthDate);
  }, [selectMonthDate]);

  // select an index from configuartion array.
  const onSelectChartIndex = index => {
    setShowTableNav(index);

    // if a chart is selected and the side menu is open, close the side menu
    if (index >= 0 && index < DIAGRAM_SETTINGS.length && siderOpen) {
      dispatch(changeSiderOpen(false));
    }
  };

  const cancelSelectedChart = () => onSelectChartIndex(-1);

  return (
    <Box sx={{ marginTop: '32px', paddingBottom: '62px' }}>
      {selectedChartIndex >= 0 ? (
        <Box className={styles['card-content-container']}>
          <div
            className={styles['card-content-paper']}
            disableGutters
            maxWidth={false}
          >
            {selectedChartIndex !== 3 && selectedChartIndex !== 7 && (
              <NormalChart
                header={groupName}
                subHeader={chartName}
                selectedMonth={selectMonthDate}
                cancelSelectedChart={cancelSelectedChart}
                hasCategoryFilter={hasCategoryFilter}
                hasSelectAllOption={hasSelectAllOption}
                type={type}
                url={url}
                tableDownloadTitle={tableDownloadTitle}
                tableDownloadUrl={tableDownloadUrl}
                reportType={reportType}
                />
                )}
            {(selectedChartIndex === 3 || selectedChartIndex === 7) && (
              <AllInOneChart
                tabName={ARTICLE}
                header={groupName}
                subHeader={chartName}
                selectedMonth={selectMonthDate}
                selectedFY={selectedFY}  // need translate from month to FY
                cancelSelectedChart={cancelSelectedChart}
                showSubjectGroupsSelector={false}
                hasAdditionalFilter={true}
                catagoryAttributeName={catagoryAttributeName} // todo: still used?
                url={url}
                singleSelectListUrl={singleSelectListUrl} // added
                downloadUrl={tableDownloadUrl}
                tableDownloadTitle={tableDownloadTitle}
              />
            )}
          </div>
          <ChartList
            chartGroupTitles={CHART_LIST_TITLES}
            onSelectChartIndex={onSelectChartIndex}
            selectedChartIndex={selectedChartIndex}
          />
        </Box>
      ) : (
        <ChartGroups
          chartGroupTitles={CHART_GROUP_TITLES}
          onSelectChartIndex={onSelectChartIndex}
          selectedChartIndex={selectedChartIndex}
        />
      )}
    </Box>
  );
};

export default DiagramContent;

/*
 * @Author: Huang Tao
 * @Date:2022/1/19
 * @Description:errorAlert For EditUser props
 */

import '../../../App.css';
import ErrorOutline from '@/assets/error_outline.svg';
import { Box, Icon, Stack } from '@mui/material';

function ErrorAlert() {
  return (
    <Stack
      data-seleunim-id='SystemAdmin_UserManagement-ErrorAlert-Stack'
      className='CNUerror_alert'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      paddingX={'18px'}
      spacing={1}
    >
      <Stack
        justifyContent={'center'}
        id='error_outline'
        data-seleunim-id='SystemAdmin_UserManagement-ErrorAlert-Stack-Stack-ErrorOutline'
      >
        <ErrorOutline />
      </Stack>
      <Box
        id='CNUerr_msg'
        data-selenium-id='CNUerr_msg'
        data-seleunim-id='SystemAdmin_UserManagement-ErrorAlert-Stack-Box-CNUerrMsg'
      >
        An account already exists with this email address
      </Box>
    </Stack>
  );
}

export default ErrorAlert;

import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import MyRoutes from './Route';
import CookieDialog from './components/CookieDialog';
import ConfirmSnackBar from '@/modules/ConfirmSnackBar';

const App = () => {
  return (
    <div className='Myindex'>
      <BrowserRouter>
        <MyRoutes />
      </BrowserRouter>
      <CookieDialog />
      <ConfirmSnackBar />
    </div>
  );
};

export default App;

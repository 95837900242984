/**
 * This file includes basic layout eChart options
 * The 1st part has some common constants
 * The 2nd defined an Options class
 */

/**
 * default general padding for whole chart
 */
const CHART_PADDING_LEFT = 59;
const CHART_PADDING_RIGHT = 59;
const CHART_PADDING_TOP = 49;
const CHART_PADDING_BOTTOM = 49;

/**
 * defalue general chart height
 */
const CHART_HEIGHT = 412;
/**
 * default general title
 */
const TITLE_HEIGHT = 70;
const TITLE_TOP_PADDING = 30;
const TITLE_STYLE = {
  textStyle: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 700,
    color: '#113D95',
    lineHeight: 17.5,
  },
  subtextStyle: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 400,
    color: '#113D95',
    lineHeight: 17.5,
  },
  left: 'center',
};
import { NEED_IMPLEMENT_METHOD } from "../constants";

/**
 * default general legend
 */
const LEGEND_HEIGHT = 14;
const GAP_BETWEEN_CHART_AND_LEGEND = 12;
const DEFAULT_DOWNLOAD_OPTION = {
  isDonwload: false,
  title: '',
  subTitle: '',
};

export class BasicLayoutChartOptions {
  constructor() {
    this.chartPaddingLeft = CHART_PADDING_LEFT;
    this.chartPaddingRight = CHART_PADDING_RIGHT;
    this.chartPaddingTop = CHART_PADDING_TOP;
    this.chartPaddingBottom = CHART_PADDING_BOTTOM;
    this.chartHeight = CHART_HEIGHT;
    this.legendHeight = LEGEND_HEIGHT;
    this.titleHeight = TITLE_HEIGHT;
    this.titleTopPadding = TITLE_TOP_PADDING;
    this.hasZoom = false;
    this.gapBetweenChartAndLegend = GAP_BETWEEN_CHART_AND_LEGEND;
    this.downloadOption = DEFAULT_DOWNLOAD_OPTION;
  }

  setDownloadOption(downloadOption = DEFAULT_DOWNLOAD_OPTION) {
    this.downloadOption = downloadOption;
    return this;
  }

  getByTitle() {
    if (this.downloadOption.isDonwload) {
      return {
        ...TITLE_STYLE,
        top: this.titleTopPadding,
        text: this.downloadOption.title,
        subtext:
          this.downloadOption.subTitle && `(${this.downloadOption.subTitle})`,
      };
    }
    return undefined;
  }

  getHeightWithTitle() {
    let totalHeight =
      (this.downloadOption.isDonwload && this.titleHeight) +
      this.chartPaddingTop +
      this.chartHeight +
      this.gapBetweenChartAndLegend +
      this.legendHeight +
      this.chartPaddingBottom;
    return totalHeight;
  }
  getOption() {
    return {
      grid: this.getGrid(),
      legend: this.getSelectedLegend(),
      xAxis: this.getXAxis(),
      yAxis: this.getYAxis(),
      tooltip: this.getTooltip(),
      series: this.getSeries(),
      color: this.getColor(),
      title: this.getByTitle(),
      dataZoom: this.getZoom(),
      dataset: this.getDataset(),
    };
  }
  getGrid() {
    const grid = {
      containLabel: true,
      left: this.chartPaddingLeft,
      right: this.chartPaddingRight,
      top: !this.downloadOption.isDonwload
        ? this.chartPaddingTop
        : this.chartPaddingTop + this.titleHeight,
      height: this.chartHeight,
    };
    return grid;
  }
  setTitle(hasTitle, title, subTitle) {
    this.downloadOption.isDonwload = hasTitle;
    this.downloadOption.title = title;
    this.downloadOption.subTitle = subTitle;
    return this;
  }
  setHasZoom(hasZoom) {
    this.hasZoom = hasZoom;
    return this;
  }

  getSelectedLegend() {
    throw new Error(NEED_IMPLEMENT_METHOD);
  }
  getXAxis() {
    throw new Error(NEED_IMPLEMENT_METHOD);
  }
  getYAxis() {
    throw new Error(NEED_IMPLEMENT_METHOD);
  }
  getTooltip() {
    throw new Error(NEED_IMPLEMENT_METHOD);
  }
  getSeries() {
    throw new Error(NEED_IMPLEMENT_METHOD);
  }
  getColor() {
    console.warn(NEED_IMPLEMENT_METHOD);
    return ['#FFFFFF'];
  }
  getZoom() {
    throw new Error(NEED_IMPLEMENT_METHOD);
  }
  getDataset() {
    throw new Error(NEED_IMPLEMENT_METHOD);
  }
}

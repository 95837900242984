import DeleteIconSVG from '@/assets/CMAssets/DeleteIcon.svg';
import DeleteIconHoverSVG from '@/assets/CMAssets/DeleteIconHover.svg';
import AddIconSVG from '@/assets/CMAssets/AddIcon.svg';
import AddIconHoverSVG from '@/assets/CMAssets/AddIconHover.svg';

import ArrowSVG from '@/assets/CMAssets/Arrow.svg';
import ArrowHoverSVG from '@/assets/CMAssets/ArrowHover.svg';
import ArrowActivateSVG from '@/assets/CMAssets/ArrowActivate.svg';

import ArrowIconForTextButtonSVG from '@/assets/CMAssets/ArrowForTextButton.svg';
import ArrowIconForTextButtonHoverSVG from '@/assets/CMAssets/ArrowForTextButtonHover.svg';

import EditSIDetailnewIcon from '@/assets/EditSIDetailnewIcon.svg';
import EditSIDetailnewHover from '@/assets/EditSIDetailnewHover.svg';
import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  createTheme,
  FormControl,
  Button,
} from '@mui/material';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import SingleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import InputNullFlag from '@/assets/InputNullFlag.svg';
import { useIntl } from 'react-intl';
import { SmallFont } from '@/components/FontStyle';
import { useUpdateEffect } from 'ahooks';
import { ThemeProvider } from '@emotion/react';

import styled from '@emotion/styled';

export const DeleteIcon = () => {
  const [isHover, setIsHover] = useState(false);

  return (
    <Box
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      {isHover ? <DeleteIconHoverSVG /> : <DeleteIconSVG />}
    </Box>
  );
};

export const DeleteButton = props => {
  const { onClick } = props;
  return (
    <Box onClick={onClick}>
      <DeleteIcon />
    </Box>
  );
};

export const AddIcon = () => {
  const [isHover, setIsHover] = useState(false);
  return (
    <Box
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      {isHover ? <AddIconHoverSVG /> : <AddIconSVG />}
    </Box>
  );
};

export const EditIcon = props => {
  const theStyle = {
    height: '16px',
    width: '16px',
    transform: 'translateY(7px)',
    cursor: 'pointer',
  };

  const [isHover, setIsHover] = useState(false);

  return (
    <Box
      sx={theStyle}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      {isHover ? <EditSIDetailnewHover /> : <EditSIDetailnewIcon />}
    </Box>
  );
};

export const EditButton = props => {
  const { onClick, display } = props;

  return (
    <Box
      sx={{
        height: '32px',
        transition: 'all 0.5s',
        opacity: display ? '1' : '0',
        visibility: display ? 'visible' : 'hidden',
      }}
      onClick={onClick}
    >
      <Box>
        <EditIcon />
      </Box>
    </Box>
  );
};

export const ArrowButton = props => {
  const { isFold, setIsFold } = props;
  const [isHover, setIsHover] = useState(false);
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px',
        gap: '12px',
        width: '24px',
        height: '24px',
        background: '#dfe4e8',
        borderRadius: '4px',
        flex: 'none',
        order: '0',
        flexGrow: '0',
      }}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={() => {
        setIsFold(!isFold);
      }}
    >
      {!isFold ? (
        <ArrowActivateSVG />
      ) : isHover ? (
        <ArrowHoverSVG />
      ) : (
        <ArrowSVG />
      )}
    </Box>
  );
};

export const AddMoreButton = props => {
  const { onClick } = props;
  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const labelStyle = {
    color: '#262E35',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
    marginRight: '8px',
  };

  return (
    <Box style={buttonStyle}>
      <Typography style={labelStyle}>Marketing Activity</Typography>
      <Box
        onClick={onClick}
        style={{
          height: '16px',
        }}
      >
        <AddIcon />
      </Box>
    </Box>
  );
};

//按钮后面提示文字样式
const iconTitleStyle = {
  marginLeft: '5px',
  marginRight: '15px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '120%',
  letterSpacing: '-0.011em',
  color: '#0855A5',
  textTransform: 'none',
};

const iconTitleDisabledStyle = {
  marginLeft: '5px',
  marginRight: '15px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '120%',
  letterSpacing: '-0.011em',
  color: '#C0C0C0',
  textTransform: 'none',
};
//展示的值的样式
const normalValueStyle = {
  width: '286x',
  textAlign: 'left',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '120%',
  color: '#262E35',
  wordWrap: 'break-word',
};

const alertText = {
  marginTop: '2px',
  marginLeft: '1px',
  width: '274px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '16px',
  color: '#EE5350',
};

export const OnlyTextForCMPage = props => {
  return (
    <MyOnlyText
      fontSize={14}
      height='33px'
      textFieldPadding='16px 12px'
      {...props}
    />
  );
};

export const SingleAutocompleteForCMPage = props => {
  const config = {
    outlinedInputProps: {
      outlinedInputSX: {
        height: '33px',
        padding: '8px  12px',
        // has PopUp
        paddingRight: '33px !important ',
      },
    },
    endAttchmentProps: {
      endAttchmentSX: {
        top: '8.5px',
      },
    },
    dropListProps: {
      listBoxUlSX: {
        li: {
          color: 'var(--gray-500, #596A7C)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px' /* 166.667% */,
        },
        // liHover: {
        // },
        liActive: {
          color: 'var(--gray-600, #262E35)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px' /* 166.667% */,
        },
        liHover: {
          color: 'var(--gray-600, #262E35)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px' /* 166.667% */,
        },
        // liSelectedHover: {},
      },
      ...props.config?.dropListProps,
    },
  };

  return <SingleAutocomplete defaultQuery={true} {...props} config={config} />;
};

export const activityTypeList = [
  {
    label: 'Webinar',
    value: 'Webinar',
  },
  {
    label: 'Event',
    value: 'Event',
  },
  {
    label: 'Email Campaign',
    value: 'Email Campaign',
  },
  {
    label: 'Organic social',
    value: 'Organic social',
  },
  {
    label: 'Paid Social',
    value: 'Paid Social',
  },
  {
    label: 'Paid Ads',
    value: 'Paid Ads',
  },
  {
    label: 'Marketing survey',
    value: 'Marketing survey',
  },
  {
    label: 'Social video creation',
    value: 'Social video creation',
  },
  {
    label: 'Academic Conference',
    value: 'Academic Conference',
  },
  {
    label: 'Wiley-led event',
    value: 'Wiley-led event',
  },
  {
    label: 'PR',
    value: 'PR',
  },
  {
    label: 'Giveaways',
    value: 'Giveaways',
  },
  {
    label: 'Marketing materials',
    value: 'Marketing materials',
  },
  {
    label: 'Others',
    value: 'Others',
  },
  {
    label: '',
    value: '',
  },
];

export const MultiContentTypography = styled(Typography)`
  display: block;
  display: -webkit-box;
  max-width: 263px;
  max-height: 87.5px;
  font-size: 14px;
  line-height: 125%;
  wrap: break-word;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContentTypography = styled(Typography)`
  overflow: hidden;
  color: var(--gray-600, #262e35);
  text-overflow: ellipsis;
  whitespace: nowrap;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  max-width: 263px;
`;

export const TitleTypography = styled(Typography)`
  color: var(--gray-600, #262e35);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
`;

export const ErrorMessage = ({ message }) => {
  return (
    <div style={{ display: 'flex' }}>
      <InputNullFlag
        style={{
          marginTop: '5px',
        }}
      />
      <Box sx={alertText}>
        {'\u00A0'}
        {message}
      </Box>
    </div>
  );
};

export const TextAreaWithCount = props => {
  return <MyOnlyText multiline={true} minRows={5} maxRows={5} />;
};

export function NotesTextField(props) {
  const {
    id,
    itemKey,
    value,
    setValue,
    setErr,
    maxLength,
    InputSX,
    minRows = 2.5,
    maxRows = 50,
    placeholder = '',
  } = props;
  const [tooLongError, setTooLongError] = useState(false);

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleMaxLengthStr = length => {
    if (length < 1000) {
      return length;
    }
    let Millimeter = parseInt(length / 1000);
    let unit = length % 1000;
    return Millimeter + ',' + (Array(3).join(0) + unit).slice(-3);
  };

  const helpTextBuilder = () => {
    let length;
    if (value === null || value === undefined) {
      length = 0;
    } else {
      length = value.length;
    }

    return `${format(length)}/${handleMaxLengthStr(maxLength)}`;
  };

  const handleBlur = () => {
    let length;
    if (value === null || value === undefined) {
      length = 0;
    } else {
      length = value.length;
    }

    if (length > maxLength) {
      setErr(true);
      setTooLongError(true);
    } else {
      setErr(false);
      setTooLongError(false);
    }
  };

  return (
    <CssNotesTextField
      id={id}
      data-selenium-id={id}
      multiline
      // rows={2.5}
      placeholder={placeholder}
      minRows={minRows} //modified
      maxRows={maxRows}
      disabled={props.disabled}
      helperText={
        tooLongError
          ? `[${itemKey}] should not exceed ${handleMaxLengthStr(
              maxLength
            )} characters in length!`
          : helpTextBuilder()
      }
      sx={{
        '& .MuiFormHelperText-root': {
          fontSize: '10px',
        },
        ...InputSX,
      }}
      value={value}
      onChange={handleChange}
      error={tooLongError}
      onBlur={handleBlur}
    />
  );
}

export const CustomTextFieldForCMDescription = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    position: 'relative',
    width: '287px',
    minWidth: '287px',
    padding: '0px 0px 0px 0px',
    overflow: 'hidden',
    ...SmallFont,
    lineHeight: '17.5px',
    letterSpacing: '-0.011em',
    textAlign: 'left',
  },

  '& .MuiOutlinedInput-input': {
    // height: '72px',
    height: '97px',
    overflowY: 'scroll',
    padding: '8px 12px',
    '&::placeholder': {
      ...SmallFont,
      color: '#BCC5CF',
      lineHeight: '16.8px',
      opacity: '1',
    },
  },
});

export const TextAreaWithLimit = props => {
  const {
    id = '',
    value = '',
    placeholder = '',
    limitLength = 10000,
    needWordCount = false, //是否需要单词计数
    setValue = () => {},
    needExcessError = true,
    excessError,
    setExcessError = () => {},
    needEmptyError = false,
    emptyError = false,
    setEmptyError = () => {},
    width = '400px',
    rows = 3,
    CustomTextFieldInject = CustomTextFieldForCMDescription,
    realTimeCheck=false
  } = props;

  const theme = createTheme({
    palette: {
      error: {
        main: '#EE5350',
      },
    },
  });

  //用于textarea 下面那行 统计字符 的样式
  const textAreaNumberFont = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    height: '16px',
    letterSpacing: '0px',
    textAlign: 'right',
  };

  const { formatNumber } = useIntl();

  const ExistError = needEmptyError&&emptyError || needExcessError&&excessError;

  const syncError = () => {
    if ( needExcessError && value.length > limitLength) {
      setExcessError(true);
    } else {
      setExcessError(false);
    };
    if (needEmptyError && value.length === 0) {
      setEmptyError(true);
    } else {
      setEmptyError(false);
    };
  };

  useEffect(() => {
    if (realTimeCheck) syncError();
  }, [value]);


  return (
    <ThemeProvider
      theme={theme}
      data-selenium-id=''
    >
      <FormControl
        variant='outlined'
        sx={{ width: width }}
        fullWidth
        error={emptyError || value.length>limitLength}
      >
        <MyOnlyText
          id={id}
          data-selenium-id={id}
          value={value}
          multiline
          width='100%'
          isError={emptyError || value.length>limitLength}
          setValue={setValue}
          placeholder={placeholder}
          maxRows={rows}
          minRows={rows}
          onFocus={() => {
            if (realTimeCheck) return;
            setEmptyError(false);
            setExcessError(false);
          }}
          onBlur={() => {
            if (value === '') {
              setEmptyError(true);
            } else {
              setEmptyError(false);
            }
            
           
          }}
          
        />
        <Box
          sx={{ height: '24px', marginTop: '4px', width: width }}
          data-selenium-id='CATJournal_Overview-OptOutTable-ReasonForPopTextArea-Box'
        >
          { needExcessError && excessError? (
            <ErrorMessage
            message={
              'The character count should be no more than 10,000.'
            }
          />
          ) : (
            <></>
          )}
          <Box
            sx={{
              ...textAreaNumberFont,
              color: value.length>limitLength ? '#EE5350' : '#596A7C',
              float: 'right',
            }}
            data-selenium-id='CATJournal_Overview-OptOutTable-ReasonForPopTextArea-Box-Box'
          >
            {formatNumber(value.length)}/{formatNumber(limitLength)}
          </Box>
        </Box>
      </FormControl>
    </ThemeProvider>
  );
};

export const ArrowIconForTextButton = props => {
  const { activate, isHover } = props;
  const degreeToTurn = activate ? 180 : 0;
  return (
    <Box>
      {!isHover ? (
        <ArrowIconForTextButtonSVG
          style={{ transform: `rotate(${degreeToTurn}deg)` }}
        />
      ) : (
        <ArrowIconForTextButtonHoverSVG
          style={{ transform: `rotate(${degreeToTurn}deg)` }}
        />
      )}
    </Box>
  );
};

export const ViewAllAndHideButton = props => {
  const StyledTypography = styled(Typography)`
    color: var(--primary-500, #154ab6);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    text-transform: capitalize;
  `;

  const StyledTypographyHovered = styled(Typography)`
    color: var(--primary-600, #113d95);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    text-transform: capitalize;
  `;

  const { isHidden, setIsHidden } = props;
  const [isHover, setIsHover] = useState(false);

  const TypographyToUse = !isHover ? StyledTypography : StyledTypographyHovered;

  return (
    <Box
      onClick={() => {
        setIsHidden(!isHidden);
      }}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <TypographyToUse>{isHidden ? 'View All' : 'Hide'}</TypographyToUse>
      <ArrowIconForTextButton activate={!isHidden} isHover={isHover} />
    </Box>
  );
};

const ButtonTypography = styled(Typography)`
  color: var(--primary-500, #154ab6);
  leading-trim: both;
  text-edge: cap;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 15px */
  text-transform: none;
`;

const ButtonTypographyDisabled = styled(Typography)`
  color: var(--gray-300, #bcc5cf);
  leading-trim: both;
  text-edge: cap;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 15px */
  text-transform: none;
`;

export const CustomTypographyButton = props => {
  const { label, disabled, onClick } = props;
  const TypoToUse = disabled ? ButtonTypographyDisabled : ButtonTypography;
  return (
    <Box
      onClick={onClick}
      // pointer
      sx={{
        cursor: 'pointer',
      }}
    >
      <TypoToUse>{label}</TypoToUse>
    </Box>
  );
};

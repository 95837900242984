import {
  Table,
  TableContainer,
  TableRow,
  TableBody,
  Box,
  tableCellClasses,
  Paper,
  TableCell,
  Link,
  Tooltip,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { NewTableHeader } from './NewTableHeader';
import '../index.css';
import { useDispatch, useSelector } from 'react-redux';
import { setSavePDNumberError } from '@/actions/SIMT-SI/SpecialIssue/SIAction';
import {
  formatDateString,
  handleLinkWithoutProtocol,
} from '@/utils/commonUtils';

const EDIT_TYPE = 'EDIT';
const ADD_TYPE = 'ADD';

const StyledTableCell = styled(TableCell)(props => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E8F2',
    color: '#505050',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '0.01em',
  },
  [`&.${tableCellClasses.root}`]: {
    ...props.cellStyle,
    height: '44px',
    padding: 0,
  },
}));

const EditableTableCell = props => {
  /**
   * 可编辑列（EditableRow）中的一个单元格————可编辑单元格
   */
  const {
    item,
    index,
    row,
    length,
    isEditing,
    isAdding,
    cellType,
    type,
    handleInfoChange,
    handleInfoAdd,
    selected,
    clickWhiteList,
    cellStyle,
    isHovered,
    hasError,
  } = props;

  const patternNumber = /^-?[0-9]*$/;

  const [value, setValue] = useState('');
  const [, setIsEditingValue] = useState(false);
  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      configInfo: state.SystemConfig.systemConfig,
      clickOutOfDate: state.SI.clickOutOfDate,
      currentDateCell: state.SI.currentDateCell,
      inProgressArticles: state.SI.siInprogress,
    };
  };
  const {
    configInfo,
    inProgressArticles,
  } = useSelector(selector);

  const dispatch = useDispatch();

  useEffect(() => {
    setValue(checkAndTransformForDate(item.id, row[item.id]));
  }, [isEditing, isAdding]);

  useEffect(() => {
    if (
      item.id === 'inProgress' &&
      row['dataSource'] &&
      row['dataSource'] === 'EEO'
    ) {
      setValue(inProgressArticles);
    }
  }, [inProgressArticles]);

  const maxSIArticles =
    configInfo === null ? 1000 : configInfo.eachSiArticlesMaxNumber;

  /**
   * 编辑状态时，点击单元格变成输入状态，同时背景颜色变蓝
   */
  const handleEditingCellClick = () => {
    if ((isEditing && type === EDIT_TYPE) || (isAdding && type === ADD_TYPE)) {
      if (row['dataSource'] && row['dataSource'] === 'Snowflake') {
        return;
      }
      if (!clickWhiteList || !clickWhiteList.includes(item.id)) {
        setIsEditingValue(true);
      }
    }
  };

  /**
   * 若数据为日期，则需要将日期转化为 YYYY-mm-dd 的格式
   * @param {string} id 标识当前数据所在列的id，例如在PublicationDetails中可能为1)volume；2)issue；3)actualPublicationDate；4)numberOfOaArticles等等
   * @param {string} data 单元格中的真实数据
   * @returns 处理后的数据
   */
  const checkAndTransformForDate = (id, data) => {
    if (id === 'actualPublicationDate') {
      return formatDateString(data);
    } else {
      return data;
    }
  };

  const checkAndTransformForDateAndLink = (id, data) => {
    if (id === 'inProgress' && row['dataSource'] && row['dataSource'] === 'EEO') {
      return inProgressArticles;
    }
    if (id === 'actualPublicationDate') {
      const pubDate = formatDateString(data);
      return (
        <Tooltip
          title={pubDate}
          placement='bottom-end'
          data-selenium-id='SIDetail_Editable-PubDateTooltip'
        >
          <Box data-selenium-id='SIDetail_Editable-PubDateBox'>{pubDate}</Box>
        </Tooltip>
      );
    } else if (id === 'issuePublicationLink') {
      return (
        <Tooltip title={data} data-selenium-id='SIDetail_Editable-DataTooltip'>
          <Link
            href={handleLinkWithoutProtocol(data)}
            color='#154AB6'
            target='_blank'
            sx={{ textDecoration: 'none', fontWeight: 600 }}
            data-selenium-id='SIDetail_Editable-DataLink'
          >
            {data}
          </Link>
        </Tooltip>
      );
    } else {
      if (data !== 0 && (!data || data === '') && !isAdding) {
        return '-';
      }
      if (id === 'volume' || id === 'issue') {
        return (
          <Tooltip
            title={data}
            placement='bottom-start'
            data-selenium-id='SIDetail_Editable-DataTooltip'
          >
            <Box data-selenium-id='SIDetail_Editable-DataBox'>{data}</Box>
          </Tooltip>
        );
      }
      return data;
    }
  };

  /**
   * 当编辑状态下的值改变后进行的操作，一般是更新数据更新之后的内容
   * @param {*} value 改变后的值
   */
  const handleInputChange = value => {
    if (type === EDIT_TYPE) {
      handleInfoChange(row['id'], item.id, value);
    } else {
      handleInfoAdd(item.id, value);
    }
  };

  /**
   * 该方法用于点击叉号×，从输入状态恢复本来的文本值，
   * @param {*} v 原来的文本值
   * @returns
   */
  const checkClose = v => {
    if (isEditing) {
      return value || value === '0' || value === 0 ? value : '-';
    } else if (isAdding) {
      return value || value === '0' || value === 0 ? value : '';
    } else {
      return v;
    }
  };

  const handlePDNumberChange = e => {
    if (e.target.value === '') {
      setValue(0);
      handleInputChange(0);
    } else if (patternNumber.test(e.target.value)) {
      let n = Number(e.target.value);
      if (n === 0 && e.target.value.length > 1) {
        e.target.value = 0;
      } else if (n > 0 && e.target.value[0] === '0') {
        e.target.value = n;
      }
      if (e.target.value >= 0 && e.target.value < maxSIArticles + 1) {
        setValue(n);
        handleInputChange(e.target.value);
      }
    }
  };

  const [, setIsFocused] = useState(false);

  const handlePDNumberOnFocus = e => {
    setIsFocused(true);
    if (e.target.value === '') {
      setValue(0);
      handleInputChange(0);
    }
    dispatch(setSavePDNumberError(false));
  };

  const isSubmissionOverviewCellShow = () => {
    return item.id !== 'dataSource' && item.id !== 'inProgress';
  };

  return (
    <StyledTableCell
      sx={{
        minWidth: item.minWidth,
        maxWidth: item.maxWidth,
        wordWrap: 'break-word',
        color: '#262E35',
      }}
      align={item.align}
      index={index}
      length={length}
      className={cellType === 'PUBLICATIONDATE' ? 'dateClass' : ''}
      onClick={handleEditingCellClick}
      cellStyle={cellStyle}
      data-selenium-id='SIDetail_Editable-TableCell'
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          verticalAlign: 'middle',
          wordWrap: 'break-word',
          pt: '4px', // 上内边距设置为 4 像素
          pb: '4px', // 下内边距设置为 4 像素
          pl: '4px', // 左内边距设置为 4 像素
          pr: '4px', // 右内边距设置为 4 像素
          textAlign: item.align,
          backgroundColor: selected && isHovered ? '#E8EDFB' : 'white',
        }}
        align={item.align}
        data-selenium-id='SIDetail_Editable-ContainerBox'
      >
        {/* (isEditing || isAdding)保证在编辑时间时，点击close按钮Cell会复原 */}
        {isSubmissionOverviewCellShow() &&
        (selected || (type === ADD_TYPE && isAdding)) ? (
          //  && (isEditing || isAdding)
            <Box>
              <input
                style={{
                  width: '100%',
                  height: '100%',
                  textAlign: 'center',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: hasError ? '#EE5350': '#DFE4E8',
                  transition: 'border-color 0.3s ease', // Add transition effect for the border color change
                }}
                value={value}
                onChange={handlePDNumberChange}
                onFocus={handlePDNumberOnFocus}
                className='editingInput'
                size='small'
                autoComplete='off'
                data-selenium-id='SIDetail_Editable-Input'
              />
            </Box>
          ) : (
            <Box
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                paddingTop: '8px',
                paddingLeft:
                  index === 0 ? '20px' : '0px',
                paddingBottom: '8px',
                gap: '12px',
              }}
              data-selenium-id='SIDetail_Editable-Box'
            >
              {checkClose(checkAndTransformForDateAndLink(item.id, row[item.id]))}
            </Box>
          )}
      </Box>
    </StyledTableCell>
  );
};

function EditableRow(props) {
  /**
   * 可编辑的表（Editable）中的一行—————可编辑行
   * 创建该组件的目的是为了统一管理每一行的背景颜色
   */
  const {
    title,
    row,
    index,
    typeList,
    TableHeads,
    isEditing,
    isAdding,
    type,
    handleInfoChange,
    handleInfoAdd,
    setVolumeErrorFlag,
    setIssueErrorFlag,
    setLinkErrorFlag,
    setDateErrorFlag,
    setArticleNumErrorFlag,
    setWaiversNumErrorFlag,
    clickWhiteList,
    cellStyle,
    hasError,
  } = props;

  /**
   * 该变量控制 编辑/新增 状态时每一行的背景颜色（a blue background color）
   * User Story原话：
   *      When a user clicks a cell, the cell becomes
   *      a shaded and rounded white rectangle with a blue
   *      background color for the corresponding row.
   */
  const [selected, setSelected] = useState(false);
  const [isHovered, setHovered] = useState(false);

  useEffect(() => {
    if (type === ADD_TYPE && isAdding) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [isAdding]);

  useEffect(() => {
    if (title === 'Submission Overview') {
      if (
        isEditing &&
        (!row['dataSource'] || row['dataSource'] !== 'Snowflake')
      ) {
        setSelected(true);
      } else if (!isEditing) {
        setSelected(false);
      }
    }
  }, [isEditing]);

  return (
    <TableRow
      key={index}
      data-selenium-id='SIDetail_Editable-TableRow'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        '&:hover':{
          background: '#E8EDFB'
        }
      }}
    >
      {TableHeads.map((item, index) => (
        <EditableTableCell
          title={title}
          //Cell的类型，例如（PDNUMBER/LINK/PUBLICATIONDATE/VOLUME/ISSUE）
          cellType={typeList[item.id]}
          //每一列的类型
          item={item}
          //该Cell在表格的第几列
          index={index}
          //列总长度
          length={TableHeads.length}
          //列所在行的数据
          row={row}
          //是否处于编辑状态
          isEditing={isEditing}
          //是否处于添加状态
          isAdding={isAdding}
          //该Cell所支持的编辑状态（EDIT或ADD）
          type={type}
          //Edit状态下修改一个Cell调用的函数
          handleInfoChange={handleInfoChange}
          //Add状态下修改一个Cell调用的函数
          handleInfoAdd={handleInfoAdd}
          //控制背景颜色的变量和方法
          selected={selected}
          setSelected={setSelected}
          isHovered={isHovered}
          cellStyle={cellStyle}
          //以下为控制一些错误信息的Function
          setVolumeErrorFlag={setVolumeErrorFlag}
          setIssueErrorFlag={setIssueErrorFlag}
          setLinkErrorFlag={setLinkErrorFlag}
          setDateErrorFlag={setDateErrorFlag}
          setArticleNumErrorFlag={setArticleNumErrorFlag}
          setWaiversNumErrorFlag={setWaiversNumErrorFlag}
          clickWhiteList={clickWhiteList}
          hasError={hasError}
          data-selenium-id='SIDetail_Editable-EditableTableCell'
        />
      ))}
    </TableRow>
  );
}

function Editable(props) {
  /**
   * 可编辑的表
   */

  //从父组件获取的属性
  const {
    title,
    id,
    typeList,
    TableHeads,
    TableData,
    isAdding = false,
    isEditing,
    handleInfoChange,
    handleInfoAdd,
    setVolumeErrorFlag,
    setIssueErrorFlag,
    setLinkErrorFlag,
    setDateErrorFlag,
    setArticleNumErrorFlag,
    setWaiversNumErrorFlag,
    clickWhiteList,
    cellStyle,
    handleCheckButtonClick,
    hasError,
    setEditInfo,
    extraInfo,
    isOA,
  } = props;

  /**
   * 空白行，用于Publication Details的新增栏，其他Block，例如Submission Overview（没有新增功能）可以忽略
   */
  const spaceRow = () => {
    let res = {};
    for (let h in TableHeads) {
      res[h.id] = '';
    }
    return res;
  };

  return (
    <Box sx={{ width: '100%' }} data-selenium-id='SIDetail_EditTableBox'>
      <TableContainer
        id={id}
        data-selenium-id={id}
        sx={{
          borderRadius: '8px 8px 8px 8px',
          borderWidth: '1px 1px 0px 1px',
          borderStyle: 'solid',
          borderColor: '#DFE4E8',
          boxShadow: 'none'
        }}
        component={Paper}
      >
        <Table
          stickyHeader
          aria-label='custom pagination table'
          sx={{
            minWidth: 500,
            letterSpacing: '0.01em',
            color: '#262E35',
          }}
          data-selenium-id='SIDetail_EditTable'
        >
          <NewTableHeader
            title={title}
            items={TableHeads}
            backgroundColor='#F1F3F5'
            color='#596A7C'
            CheckboxColor='wileyBlue2'
            letterSpacing='0.08em'
            data-selenium-id='SIDetail_EditTableHeader'
          />
          <TableBody data-selenium-id='SIDetail_EditTableBody'>
            {TableData.map((row, index) => (
              <EditableRow
                title={title}
                row={row}
                index={index}
                typeList={typeList}
                TableHeads={TableHeads}
                isEditing={isEditing}
                isAdding={isAdding}
                type={EDIT_TYPE}
                handleInfoChange={handleInfoChange}
                handleInfoAdd={handleInfoAdd}
                setVolumeErrorFlag={setVolumeErrorFlag}
                setIssueErrorFlag={setIssueErrorFlag}
                setLinkErrorFlag={setLinkErrorFlag}
                setDateErrorFlag={setDateErrorFlag}
                setArticleNumErrorFlag={setArticleNumErrorFlag}
                setWaiversNumErrorFlag={setWaiversNumErrorFlag}
                clickWhiteList={clickWhiteList}
                cellStyle={cellStyle}
                handleCheckButtonClick={handleCheckButtonClick}
                hasError={hasError}
                setEditInfo={setEditInfo}
                extraInfo={extraInfo}
                isOA={isOA}
                data-selenium-id='SIDetail_EditTableRow'
              />
            ))}
            {isAdding ? (
              <EditableRow
                row={spaceRow()}
                typeList={typeList}
                TableHeads={TableHeads}
                isEditing={isEditing}
                isAdding={isAdding}
                type={ADD_TYPE}
                handleInfoChange={handleInfoChange}
                handleInfoAdd={handleInfoAdd}
                setVolumeErrorFlag={setVolumeErrorFlag}
                setIssueErrorFlag={setIssueErrorFlag}
                setLinkErrorFlag={setLinkErrorFlag}
                setDateErrorFlag={setDateErrorFlag}
                setArticleNumErrorFlag={setArticleNumErrorFlag}
                setWaiversNumErrorFlag={setWaiversNumErrorFlag}
                clickWhiteList={clickWhiteList}
                cellStyle={cellStyle}
                hasError={hasError}
                extraInfo={extraInfo}
                isOA={isOA}
                data-selenium-id='SIDetail_EditTable-AddingRow'
              />
            ) : null}

            {TableData.length <= 0 ? (
              <TableRow
                id='siDetailPaegNoDataText'
                data-selenium-id='SIDetail_EditTable-PageNoDataRow'
                style={{ height: '44px' }}
              >
                <TableCell
                  colSpan={7}
                  sx={{ textAlign: 'center', fontSize: '20px' ,color:'#98A7B6'}}
                  data-selenium-id='SIDetail_EditTable-PageNoDataCell'
                >
                  NO DATA
                </TableCell>
              </TableRow>
            ) : (
              <div></div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Editable;

import { axios_instance } from "@/utils/axios_instance";
import { LOAD_REQUEST_HISTORY_LIST } from "@/actions/SIMT-SI/CatJournal/CATJournalAction";

export function loadRequestHistoryList(data) {
    const url = '/api/v1/si-service/journals/managed/requests';
    data['requestType'] = data['requestType'].join(',');
    data['status'] = data['status'].join(',');
    return async dispatch => {
        try {
            let res = await axios_instance.get(url, {
                params: {
                    type: 'opt:history',
                    ...data,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            if (res.data.code === 200) {
                dispatch({ type: LOAD_REQUEST_HISTORY_LIST, data: res.data.data });
            } else {
                dispatch({ type: LOAD_REQUEST_HISTORY_LIST, data: null });
            }
        } catch (e) {
            dispatch({ type: LOAD_REQUEST_HISTORY_LIST, data: null });
        }
    };
}
/**
 * Huang Tao
 */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Grid,
  Tooltip,
  TableCell,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '@/components/DataTable';
import {
  PurpleGreenCell,
  PurpleGreenDisCell,
  StyledTableCellGreen,
  StyledTableCellDisGreen,
  StyledTableRow, StyledTableCellNormal, PurpleGreenRow, TableBox, TableLabel, ReportNoData, TableBox2
} from "../../ReportSpecificComponents/SpecialColorTableHeader";
import { StyledSwitchTwoColor } from '../../ReportSpecificComponents/SpecialColorSwitch';
import { findLongestLineSubstring, measureLongestWidth, measureWidth } from "../../../../utils/StringWidthMeasure";
import { TextLabel } from "../../../../componentsphase2/CustomTooltip";
import {GetArticleOverviewCETableData} from "@/actions/SIMT-Report/Report/ReportArticle";

export function ArticleOverviewCETable(props) {
  const { ifNoResult } = props;

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { currentDate, tableMonth, table3Data } = useSelector(state => {
    return {
      // currentDate: state.Report.selectedArticleDate,
      currentDate: state.Report.selectDate,
      tableMonth: state.Report.articleOverviewCETableMonth,
      table3Data: state.Report.articleOverviewCETableData,
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    widthMap['catGroup'] = measureWidth(findLongestLineSubstring('CAT Group'), '18px Open Sans');
    widthMap['handlingCeFullName'] = measureWidth(findLongestLineSubstring('Handling CE'), '18px Open Sans');

    const CUR_LEN = measureLongestWidth('Sub - APR', '18px Open Sans');
    const YTD_LEN = measureLongestWidth('Sub - YTD', '18px Open Sans');
    const OAP_LEN = measureLongestWidth('% Against YTD Target', '18px Open Sans');
    const OAP_LEN2 = measureLongestWidth('%Against YTD Stretch target', '18px Open Sans');

    for (let type of ['sub','acc','pub']) {
      widthMap[`${type}-0`] = CUR_LEN;
      widthMap[`${type}-1`] = YTD_LEN;
      widthMap[`${type}-2`] = OAP_LEN;
      widthMap[`${type}-3`] = OAP_LEN2;
    }

    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (table3Data && table3Data.length > 0){
      const maxMap = {};
      for (let i = 0; i < table3Data.length; i++) {
        let row = table3Data[i];
        let dataObj = {
          catGroup: row.catGroup,
          handlingCeFullName: row.handlingCeFullName,

          'sub-0':  row.submittedArt ?? '0',
          'sub-1':  row.submittedArtYtd ?? '0',
          'sub-2':  row.submittedArtYtdTarget ?? '0',
          'sub-3':  row.submittedArtYtdStretchTarget ?? '0',

          'acc-0':  row.acceptedArt ?? '0',
          'acc-1':  row.acceptedArtYtd ?? '0',
          'acc-2':  row.acceptedArtYtdTarget ?? '0',
          'acc-3':  row.acceptedArtYtdStretchTarget ?? '0',

          'pub-0':  row.publishedArt ?? '0',
          'pub-1':  row.publishedArtYtd ?? '0',
          'pub-2':  row.publishedArtYtdTarget ?? '0',
          'pub-3':  row.publishedArtYtdStretchTarget ?? '0',
        };
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [table3Data]);

  function calcGroupWidth(type){
    let sum = 0;
    for (let i of [0,1,2]) {
      sum += Math.max((columnWidthMap[`${type}-${i}`]??0), columnMinWidthMap[`${type}-${i}`]) + 50;
    }
    if (!OASwitch){
      sum += Math.max((columnWidthMap[`${type}-${3}`]??0), columnMinWidthMap[`${type}-${3}`]) + 50;
    }
    return sum;
  }

  const [OASwitch, setOASwitch] = useState(true);

  const handleChangeSwitch = () => {
    setOASwitch(!OASwitch);
  };

  useEffect(() => {
    async function getData(currentDate) {
      if (currentDate != null) {
        if (currentDate != null) {
          setIsLoading(true);
          setTimeout(() => {
            dispatch(GetArticleOverviewCETableData(currentDate, !OASwitch));
          }, 2000);
        }
        // getMonth(currentDate.split("-")[1]);
      }
    }
    getData(currentDate);
  }, [currentDate, OASwitch]);

  useEffect(() => {
    setIsLoading(false);
  }, [table3Data]);

  const Subitems = [
    {
      id: 'Sub-MMM',
      label: 'Sub - ',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: 'Sub-YTD',
      label: 'Sub - YTD',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: '%Against YTD Target',
      label: '% Against YTD Target',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: '%Against YTD Stretch target',
      label: '% Against YTD Stretch target',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
  ];
  const Accitems = [
    {
      id: 'Acc-MMM',
      label: 'Acc - ',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: 'Acc-YTD',
      label: 'Acc - YTD',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: '%Against YTD Target',
      label: '% Against YTD Target',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: '%Against YTD Stretch target',
      label: '% Against YTD Stretch target',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
  ];
  const Pubitems = [
    {
      id: 'Pub-MMM',
      label: 'Pub - ',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: 'Pub-YTD',
      label: 'Pub - YTD',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: '%Against YTD Target',
      label: '% Against YTD Target',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: '%Against YTD Stretch target',
      label: '% Against YTD Stretch target',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
  ];

  const showDataTableSG = table3Data => {
    if (table3Data === undefined || table3Data === null) {
      return [];
    } else return table3Data;
  };

  //total字体加深
  function DisplayTooltip(props) {
    const { ifTotal } = props;
    if (ifTotal === 'Total' || ifTotal == 'Subtotal')
      return (
        <div align='right' style={{ 'font-size': '16px' }}>
          < >{ifTotal}</ >
        </div>
      );
    else {
      return <div align='left'>{ifTotal}</div>;
    }
  }
  return (
    <>
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        data-selenium-id='Report_Articles_Overview-CETableGrid'
      >
        <TableLabel>CE/CAT</TableLabel>
        <Grid
          sx={{ p: '25px 0px 0px 6px', marginRight: '30px', }}
          data-selenium-id='Report_Articles_Overview-CETableGrid-OA'
        >
          <>
            <StyledSwitchTwoColor
              id='reportCeOaSwitch'
              data-selenium-id='Report_Articles_Overview-CETableGrid-ReportCeOaSwitch'
              checked={OASwitch}
              onChange={handleChangeSwitch}
            ></StyledSwitchTwoColor>
          </>
        </Grid>
      </Grid>
      <TableBox2
        id='BoxTable2'
        data-selenium-id='Report_Articles_Overview-CETableGrid-BoxTable2'
        dataList={[table3Data]}
        tableKey={'Report_Articles_Overview-CETable'}
        footerHeight={0}
      >
        <TableContainer
          id='reportArticlesOverviewCETable'
          data-selenium-id='Report_Articles_Overview-CETable'
          sx={{ maxHeight: '600px' }}
        >
          <Table
            stickyHeader
            aria-label='sticky table'
            sx={{
              tableLayout: 'fixed',
              width: 'max-content',
              maxWidth: 'none',
            }}
          >
            <TableHead data-selenium-id='Report_Articles_Overview-CETableGrid-TableHeader'>
              <TableRow data-selenium-id='Report_Articles_Overview-CETableGrid-TableRow'>
                <StyledTableCellNormal
                  align='left'
                  rowSpan={2}
                  sx={{
                    // maxWidth: '200px',
                    // minWidth: '140px'
                    width: `${Math.max((columnWidthMap['catGroup']??0), columnMinWidthMap['catGroup']) + 50 }px`,
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                  }}
                  // style={{ backgroundColor: !OASwitch ? '#96DCA9' : '#CCD4FF' }}
                  className='BottomBorder'
                  data-selenium-id='Report_Articles_Overview-CETableGrid-TableHeader-CATGroup'
                >
                  CAT Group
                </StyledTableCellNormal>
                <StyledTableCellNormal
                  align='left'
                  rowSpan={2}
                  // sx={{ maxWidth: '200px', minWidth: '140px' }}
                  // style={{ backgroundColor: !OASwitch ? '#96DCA9' : '#CCD4FF' }}
                  sx={{
                    // maxWidth: '200px',
                    // minWidth: '140px'
                    width: `${Math.max((columnWidthMap['handlingCeFullName']??0), columnMinWidthMap['handlingCeFullName']) + 50 }px`,
                    position: 'sticky',
                    left: `${Math.max((columnWidthMap['catGroup']??0), columnMinWidthMap['catGroup']) + 50 }px`,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                  }}
                  className='RightBottomBorder'
                  data-selenium-id='Report_Articles_Overview-CETableGrid-TableHeader-HandingCE'
                >
                  Handling CE
                </StyledTableCellNormal>
                <PurpleGreenCell
                  align='left'
                  colSpan={!OASwitch ? 4 : 3}
                  className='RightBottomBorder'
                  isOA={!OASwitch}
                  data-selenium-id='Report_Articles_Overview-CETableGrid-TableHeader-Submission'
                  sx={{
                    width: `${calcGroupWidth('sub')}px`,
                  }}
                >
                  Submission
                </PurpleGreenCell>
                <PurpleGreenCell
                  align='left'
                  colSpan={!OASwitch ? 4 : 3}
                  className='RightBottomBorder'
                  isOA={!OASwitch}
                  data-selenium-id='Report_Articles_Overview-CETableGrid-TableHeader-Acceptance'
                  sx={{
                    width: `${calcGroupWidth('acc')}px`,
                  }}
                >
                  Acceptance
                </PurpleGreenCell>
                <PurpleGreenCell
                  align='left'
                  colSpan={!OASwitch ? 4 : 3}
                  className='RightBottomBorder'
                  isOA={!OASwitch}
                  data-selenium-id='Report_Articles_Overview-CETableGrid-TableHeader-Publication'
                  sx={{
                    width: `${calcGroupWidth('pub')}px`,
                  }}
                >
                  Publication
                </PurpleGreenCell>
              </TableRow>
              <TableRow>
                {Subitems.map((item, index) =>
                  OASwitch && item.id === '%Against YTD Stretch target' ? (
                    <></>
                  ) : (
                    <PurpleGreenDisCell
                      align='right'
                      key={item.id}
                      sx={{
                        // top: 52.5,
                        // maxWidth: item.maxWidth,
                        // minWidth: item.minWidth,
                        top: 42,
                        width: `${Math.max((columnWidthMap[`sub-${index}`]??0), columnMinWidthMap[`sub-${index}`]) + 50 }px`,
                      }}
                      className={
                        (!OASwitch &&
                          item.id === '%Against YTD Stretch target') ||
                        (OASwitch && item.id === '%Against YTD Target')
                          ? 'RightBottomBorder'
                          : 'BottomBorder'
                      }
                      isOA={!OASwitch}
                    >
                      {item.id === 'Sub-MMM' ||
                      item.id === 'Acc-MMM' ||
                      item.id === 'Pub-MMM'
                        ? item.label + tableMonth
                        : item.label}
                    </PurpleGreenDisCell>
                  )
                )}
                {Accitems.map((item, index) =>
                  OASwitch && item.id === '%Against YTD Stretch target' ? (
                    <></>
                  ) : (
                    <PurpleGreenDisCell
                      align='right'
                      key={item.id}
                      sx={{
                        // top: 52.5,
                        // maxWidth: item.maxWidth,
                        // minWidth: item.minWidth,
                        top: 42,
                        width: `${Math.max((columnWidthMap[`acc-${index}`]??0), columnMinWidthMap[`acc-${index}`]) + 50 }px`,
                      }}
                      className={
                        (!OASwitch &&
                          item.id === '%Against YTD Stretch target') ||
                        (OASwitch && item.id === '%Against YTD Target')
                          ? 'RightBottomBorder'
                          : 'BottomBorder'
                      }
                      isOA={!OASwitch}
                    >
                      {item.id === 'Sub-MMM' ||
                      item.id === 'Acc-MMM' ||
                      item.id === 'Pub-MMM'
                        ? item.label + tableMonth
                        : item.label}
                    </PurpleGreenDisCell>
                  )
                )}
                {Pubitems.map((item, index) =>
                  OASwitch && item.id === '%Against YTD Stretch target' ? (
                    <></>
                  ) : (
                    <PurpleGreenDisCell
                      align='right'
                      key={item.id}
                      sx={{
                        // top: 52.5,
                        // maxWidth: item.maxWidth,
                        // minWidth: item.minWidth,
                        top: 42,
                        width: `${Math.max((columnWidthMap[`pub-${index}`]??0), columnMinWidthMap[`pub-${index}`]) + 50 }px`,
                      }}
                      className={
                        (!OASwitch &&
                          item.id === '%Against YTD Stretch target') ||
                        (OASwitch && item.id === '%Against YTD Target')
                          ? 'RightBottomBorder'
                          : 'BottomBorder'
                      }
                      isOA={!OASwitch}
                    >
                      {item.id === 'Sub-MMM' ||
                      item.id === 'Acc-MMM' ||
                      item.id === 'Pub-MMM'
                        ? item.label + tableMonth
                        : item.label}
                    </PurpleGreenDisCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody data-selenium-id='Report_Articles_Overview-CETableGrid-TableBody'>
              {!isLoading &&
              !ifNoResult &&
              table3Data != null &&
              table3Data != undefined &&
              table3Data.length != 0
                ? table3Data.map((row, index) => {
                    if (
                      row.handlingCeFullName === 'Total' ||
                      row.handlingCeFullName === 'Subtotal'
                    ) {
                      return (
                        <>
                          <PurpleGreenRow isOA={!OASwitch}>
                            <StyledTableCell
                              align='left'
                              sx={{
                                // maxWidth: '150px'
                                position: 'sticky',
                                left: 0,
                                zIndex: 6,
                                backgroundColor: '#F1F3F5',
                              }}
                              data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell1-${index}`}
                            >
                              <DisplayTooltip ifTotal={''} />
                            </StyledTableCell>
                            <StyledTableCell
                              align='left'
                              sx={{
                                // maxWidth: '150px'
                                position: 'sticky',
                                left: `${Math.max((columnWidthMap['catGroup']??0), columnMinWidthMap['catGroup']) + 50 }px`,
                                zIndex: 6,
                                backgroundColor: '#F1F3F5',
                              }}
                              className='RightBorder ReportTotalTextSize'
                              data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell2-${index}`}
                            >
                              <DisplayTooltip
                                ifTotal={row.handlingCeFullName}
                              />
                            </StyledTableCell>
                            <StyledTableCell
                              align='left'
                              className='ReportNumberTextColor ReportTotalTextSize'
                              data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell3-${index}`}
                            >
                              < >
                                {row.submittedArt === null
                                  ? '0'
                                  : row.submittedArt}
                              </ >
                            </StyledTableCell>
                            <StyledTableCell
                              align='left'
                              className='ReportNumberTextColor ReportTotalTextSize'
                              data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell4-${index}`}
                            >
                              < >
                                {row.submittedArtYtd === null
                                  ? '0'
                                  : row.submittedArtYtd}
                              </ >
                            </StyledTableCell>
                            <StyledTableCell
                              align='right'
                              data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell5-${index}`}
                              className={
                                OASwitch
                                  ? 'RightBorder ReportNumberTextColor ReportTotalTextSize'
                                  : 'NoBorder ReportNumberTextColor ReportTotalTextSize'
                              }
                            >
                              < >
                                {' '}
                                {row.submittedArtYtdTarget === null
                                  ? '0'
                                  : row.submittedArtYtdTarget}
                              </ >
                            </StyledTableCell>
                            {!OASwitch ? (
                              <StyledTableCell
                                align='right'
                                data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell6-${index}`}
                                className={
                                  !OASwitch
                                    ? 'RightBorder ReportNumberTextColor ReportTotalTextSize'
                                    : 'NoBorder ReportNumberTextColor ReportTotalTextSize'
                                }
                              >
                                < >
                                  {' '}
                                  {row.submittedArtYtdStretchTarget === null
                                    ? '0'
                                    : row.submittedArtYtdStretchTarget}
                                </ >
                              </StyledTableCell>
                            ) : (
                              <></>
                            )}
                            <StyledTableCell
                              align='left'
                              className='ReportNumberTextColor ReportTotalTextSize'
                              data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell7-${index}`}
                            >
                              < >
                                {' '}
                                {row.acceptedArt === null
                                  ? '0'
                                  : row.acceptedArt}
                              </ >
                            </StyledTableCell>
                            <StyledTableCell
                              align='left'
                              className='ReportNumberTextColor ReportTotalTextSize'
                              data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell8-${index}`}
                            >
                              < >
                                {' '}
                                {row.acceptedArtYtd === null
                                  ? '0'
                                  : row.acceptedArtYtd}
                              </ >
                            </StyledTableCell>
                            <StyledTableCell
                              align='right'
                              data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell9-${index}`}
                              className={
                                OASwitch
                                  ? 'RightBorder ReportNumberTextColor ReportTotalTextSize'
                                  : 'NoBorder ReportNumberTextColor ReportTotalTextSize'
                              }
                            >
                              < >
                                {' '}
                                {row.acceptedArtYtdTarget === null
                                  ? '0'
                                  : row.acceptedArtYtdTarget}
                              </ >
                            </StyledTableCell>
                            {!OASwitch ? (
                              <StyledTableCell
                                align='right'
                                data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell10-${index}`}
                                className={
                                  !OASwitch
                                    ? 'RightBorder ReportNumberTextColor ReportTotalTextSize'
                                    : 'NoBorder ReportNumberTextColor ReportTotalTextSize'
                                }
                              >
                                < >
                                  {' '}
                                  {row.acceptedArtYtdStretchTarget === null
                                    ? '0'
                                    : row.acceptedArtYtdStretchTarget}
                                </ >
                              </StyledTableCell>
                            ) : (
                              <></>
                            )}
                            <StyledTableCell
                              align='left'
                              className='ReportNumberTextColor ReportTotalTextSize'
                              data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell11-${index}`}
                            >
                              < >
                                {' '}
                                {row.publishedArt === null
                                  ? '0'
                                  : row.publishedArt}
                              </ >
                            </StyledTableCell>
                            <StyledTableCell
                              align='left'
                              className='ReportNumberTextColor ReportTotalTextSize'
                              data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell12-${index}`}
                            >
                              < >
                                {' '}
                                {row.publishedArtYtd === null
                                  ? '0'
                                  : row.publishedArtYtd}
                              </ >
                            </StyledTableCell>
                            <StyledTableCell
                              align='right'
                              data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell13-${index}`}
                              className={
                                OASwitch
                                  ? 'RightBorder ReportNumberTextColor ReportTotalTextSize'
                                  : 'NoBorder ReportNumberTextColor ReportTotalTextSize'
                              }
                            >
                              < >
                                {' '}
                                {row.publishedArtYtdTarget === null
                                  ? '0'
                                  : row.publishedArtYtdTarget}
                              </ >
                            </StyledTableCell>
                            {!OASwitch ? (
                              <StyledTableCell
                                data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell14-${index}`}
                                align='right'
                                className={
                                  !OASwitch
                                    ? 'RightBorder ReportNumberTextColor ReportTotalTextSize'
                                    : 'NoBorder ReportNumberTextColor ReportTotalTextSize'
                                }
                              >
                                < >
                                  {' '}
                                  {row.publishedArtYtdStretchTarget === null
                                    ? '0'
                                    : row.publishedArtYtdStretchTarget}
                                </ >
                              </StyledTableCell>
                            ) : (
                              <></>
                            )}
                          </PurpleGreenRow>
                        </>
                      );
                    }
                    return (
                      <PurpleGreenRow isOA={!OASwitch}>
                        <StyledTableCell
                          data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell15-${index}`}
                          align='left'
                          sx={{
                            // maxWidth: '150px'
                            position: 'sticky',
                            left: 0,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                        >
                          <DisplayTooltip
                            ifTotal={
                              row.handlingCeEmail == 'SubTotal'
                                ? ''
                                : row.catGroup
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell16-${index}`}
                          align='left'
                          sx={{
                            // maxWidth: '150px'
                            position: 'sticky',
                            left: `${Math.max((columnWidthMap['catGroup']??0), columnMinWidthMap['catGroup']) + 50 }px`,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                          style={{}}
                          className='RightBorder'
                        >
                          <TextLabel
                            text={
                              row.handlingCeEmail == 'SubTotal'
                                ? row.handlingCeEmail
                                : row.handlingCeFullName
                            }
                            TooltipText={
                              row.handlingCeEmail == 'SubTotal'
                                ? false
                                : row.handlingCeEmail
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell17-${index}`}
                        >
                          {row.submittedArt === null ? '0' : row.submittedArt}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                        >
                          {row.submittedArtYtd === null
                            ? '0'
                            : row.submittedArtYtd}
                        </StyledTableCell>
                        <StyledTableCell
                          data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell18-${index}`}
                          align='right'
                          className={
                            OASwitch
                              ? 'RightBorder ReportNumberTextColor'
                              : 'NoBorder ReportNumberTextColor'
                          }
                        >
                          {' '}
                          {row.submittedArtYtdTarget === null
                            ? '0'
                            : row.submittedArtYtdTarget}
                        </StyledTableCell>
                        {!OASwitch ? (
                          <StyledTableCell
                            data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell19-${index}`}
                            align='right'
                            className={
                              !OASwitch
                                ? 'RightBorder ReportNumberTextColor'
                                : 'NoBorder ReportNumberTextColor'
                            }
                          >
                            {' '}
                            {row.submittedArtYtdStretchTarget === null
                              ? '0'
                              : row.submittedArtYtdStretchTarget}
                          </StyledTableCell>
                        ) : (
                          <></>
                        )}
                        <StyledTableCell
                          data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell20-${index}`}
                          align='left'
                          className='ReportNumberTextColor'
                        >
                          {' '}
                          {row.acceptedArt === null ? '0' : row.acceptedArt}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell21-${index}`}
                        >
                          {' '}
                          {row.acceptedArtYtd === null
                            ? '0'
                            : row.acceptedArtYtd}
                        </StyledTableCell>
                        <StyledTableCell
                          align='right'
                          data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell22-${index}`}
                          className={
                            OASwitch
                              ? 'RightBorder ReportNumberTextColor'
                              : 'NoBorder ReportNumberTextColor'
                          }
                        >
                          {' '}
                          {row.acceptedArtYtdTarget === null
                            ? '0'
                            : row.acceptedArtYtdTarget}
                        </StyledTableCell>
                        {!OASwitch ? (
                          <StyledTableCell
                            data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell23-${index}`}
                            align='right'
                            className={
                              !OASwitch
                                ? 'RightBorder ReportNumberTextColor'
                                : 'NoBorder ReportNumberTextColor'
                            }
                          >
                            {' '}
                            {row.acceptedArtYtdStretchTarget === null
                              ? '0'
                              : row.acceptedArtYtdStretchTarget}
                          </StyledTableCell>
                        ) : (
                          <></>
                        )}
                        <StyledTableCell
                          data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell24-${index}`}
                          align='left'
                          className='ReportNumberTextColor'
                        >
                          {' '}
                          {row.publishedArt === null ? '0' : row.publishedArt}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell25-${index}`}
                          className='ReportNumberTextColor'
                        >
                          {' '}
                          {row.publishedArtYtd === null
                            ? '0'
                            : row.publishedArtYtd}
                        </StyledTableCell>
                        <StyledTableCell
                          data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell26-${index}`}
                          align='right'
                          className={
                            OASwitch
                              ? 'RightBorder ReportNumberTextColor'
                              : 'NoBorder ReportNumberTextColor'
                          }
                        >
                          {' '}
                          {row.publishedArtYtdTarget === null
                            ? '0'
                            : row.publishedArtYtdTarget}
                        </StyledTableCell>
                        {!OASwitch ? (
                          <StyledTableCell
                            data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell27-${index}`}
                            align='right'
                            className={
                              !OASwitch
                                ? 'RightBorder ReportNumberTextColor'
                                : 'NoBorder ReportNumberTextColor'
                            }
                          >
                            {' '}
                            {row.publishedArtYtdStretchTarget === null
                              ? '0'
                              : row.publishedArtYtdStretchTarget}
                          </StyledTableCell>
                        ) : (
                          <></>
                        )}
                      </PurpleGreenRow>
                    );
                  })
                : !isLoading && (
                    <ReportNoData id='AcquiredAndActiveSiAnnualTrendsBDNoResultTableCell' />
                  )}
              {isLoading && (
                <Box
                  sx={{
                    width: 'auto',
                    marginTop: '150px',
                    marginLeft: '50%',
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TableBox2>
    </>
  );
}
// export default SubjectGroupTable

import { Breadcrumbs, Link, Typography } from "@mui/material";

export default function MyBreadCrums() {
    return (
      <Breadcrumbs
        aria-label='breadcrumb'
        sx={{
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Link
          underline='hover'
          color='inherit'
          href='/simt/auth/sipRelated/sip/overview'
          sx={{
            color: '#0052CC',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            width: '146px',
            height: '20px',
            '&:hover': {
              // textDecoration: 'underline',
              color: 'var(--primary-600, #113D95)',
              fontWeight: '600',
              textDecoration: 'none !important',
            },
            '&:active': {
              fontWeight: '600',
              color: '#113D95',
              textDecoration: 'none !important',
            },
          }}
        >
          Special Issue Proposal
        </Link>
        <Typography
          sx={{
            height: '20px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#596A7C',
          }}
        >
          Create a Special Issue Proposal
        </Typography>
      </Breadcrumbs>
    );
  }
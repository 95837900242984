/*
 * @Author: LiuJingQian 1250366059@qq.com
 * @Date: 2023-07-08 14:57:48
 * @LastEditors: LiuJingQian 1250366059@qq.com
 * @LastEditTime: 2023-07-28 13:07:03
 * @FilePath: \simt_front\src\pages\CATJournal\Overview\RequestHistoryTable\ViewMorePop.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Box, Dialog, DialogContent, IconButton, Stack } from '@mui/material';
import { H4Font } from '../../../../components/FontStyle';
import GEDetailCloseIcon from '@/assets/GEDetailCloseIcon.svg';
import DrawerCloseButton from '@/componentsphase2/DrawerCloseButton';
export default function ViewMorePop(props) {
  const { open, handleClose, title, content } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          boxShadow: 'none',
        },
      }}
      sx={{
        zIndex:'1400',
        // marginTop: '48px',
        '& .MuiDialog-paper': {
          minWidth: '600px',
          margin: 0,
          padding: '24px 32px',
          boxSizing: 'border-box',
          borderRadius: '5px',
        },
        '& .MuiBackdrop-root': {
          // backgroundColor: 'rgba(9, 30, 66, 0.54)',
          backgroundColor: 'rgba(17, 61, 149, 0.5)',
          backdropFilter: 'blur(4px)',
        },
      }}
      data-selenium-id='CATJournal_Overview-RequestHistoryTable-ViewMorePop-Dialog'
    >
      <Stack
        sx={{ marginBottom: '16px' }}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        data-selenium-id='CATJournal_Overview-RequestHistoryTable-ViewMorePop-Stack'
      >
        <Box
          sx={{
            ...H4Font,
            height: '23px',
            float: 'left',
            color: '#262E35',
          }}
          data-selenium-id='CATJournal_Overview-RequestHistoryTable-ViewMorePop-Box-Box'
        >
          {title}
        </Box>
        <DrawerCloseButton
          onClick={handleClose}
          data-selenium-id='CATJournal_Overview-RequestHistoryTable-ViewMorePop-IconButton'
        />
      </Stack>
      <DialogContent
        sx={{
          wigth: '530px',
          padding: '0px',
          marginRight: '6px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          color: '#262E35',
          overflowWrap: 'anywhere',
          height: '230px'
        }}
        data-selenium-id='CATJournal_Overview-RequestHistoryTable-ViewMorePop-DialogContent'
      >
        {content}
      </DialogContent>
    </Dialog>
  );
}

import React from 'react';
import { getPermissionList } from '@/utils/authTokenHandler';

export function Case({prediction, params, children}) {
  if (typeof prediction === 'function' && prediction(...params) || prediction){
    return <>{children}</>;
  }
  return <></>;
}

export function DefaultCase({children}) {
  return <>{children}</>;
}

export function Switch({params, children}) {
  let hasMatched = false;
  let defaultCase = null;

  for (const child of React.Children.toArray(children)) {
    if (!hasMatched && React.isValidElement(child)) {
      if (child.type === Case) {
        const { prediction, ...others } = child.props;

        if (check(prediction, ...params)) {
          hasMatched = true;
          return React.cloneElement(child, {
            ...others,
            prediction,
            params,
          });
        }
      } else if (child.type === DefaultCase){
        defaultCase = child;
      }
    }
  }
  return defaultCase;
}

export function ListSwitch({list,additionParams = [], children}){
  return <Switch params={[list, ...additionParams]} children={children}/>;
}

export function PermissionListSwitch({additionParams,children}) {
  const auth = localStorage.getItem('authorization');
  const userPermissions = getPermissionList(auth);
  return <ListSwitch list={userPermissions} additionParams={additionParams} children={children}/>;
}

export function PropsProvider({ children, ...props }) {
  const propagateProps = (children) => {
    return React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { ...props }, propagateProps(child.props.children));
      }
      return child;
    });
  };

  return <>{propagateProps(children)}</>;
}

export function PermissionProvider({ children, additionParams = [], ...props }) {
  const auth = localStorage.getItem('authorization');
  const userPermissions = getPermissionList(auth);
  return <PropsProvider params={[userPermissions, ...additionParams]}{...props}>
    {children}
  </PropsProvider>;
}

function check(predicate, ...params) {
  if (typeof predicate === 'function') {
    return predicate(...params);
  }else {
    return predicate;
  }
}

export function equalsTo(t) {
  return function(arg0) {
    return t === arg0;
  };
}

export function notEqualsTo(t) {
  return function(arg0) {
    return t !== arg0;
  };
}

export function isEmpty(o) {
  return !o
    || (Array.isArray(o) && o.length === 0)
    || (typeof o === 'object' && Object.keys(o).length === 0);
}

export function isNotEmpty(o) {
  return !isEmpty(o);
}

export function lessThen(num) {
  return function(arg0) {
    return arg0 < num;
  };
}

export function lessThanOrEqual(num) {
  return function(arg0) {
    return arg0 <= num;
  };
}

export function greaterThan(num) {
  return function(arg0) {
    return arg0 > num;
  };
}

export function greaterThanOrEqual(num) {
  return function(arg0) {
    return arg0 >= num;
  };
}

export function and(...predicts) {
  return function (...args) {
    for (const predict of predicts) {
      if (typeof predict === 'function') {
        if (!predict(...args)) {
          return false;
        }
      } else if (!predict) {
        return false;
      }
    }
    return true;
  };
}

export function or(...predicts) {
  return function (...args) {
    for (const predict of predicts) {
      if (typeof predict === 'function') {
        if (predict(...args)) {
          return true;
        }
      } else if (predict) {
        return true;
      }
    }
    return false;
  };
}

export function not(predict) {
  return function (...args) {
    if (typeof predict === 'function') {
      return !predict(...args);
    } else {
      return !predict;
    }
  };
}

export function contains(element) {
  return (list) => {
    return list.includes(element);
  };
}

export function containsAny(...elements) {
  return (list) => {
    return elements.some(item => list.includes(item));
  };
}

export function containsAll(...elements) {
  return (list) => {
    return elements.every(item => list.includes(item));
  };
}

export function containsExcept(element) {
  return (list) => {
    return !list.includes(element);
  };
}

import * as React from 'react';
import { useEffect, useState, useRef, useMemo, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  CardContent,
  Stack,
  Grid,
  Typography,
  Divider,
  SvgIcon,
  IconButton,
  useMediaQuery,
  Link,
} from '@mui/material';
import axios from 'axios';

import SaveAndCancelButton from '@/componentsphase2/SIDetail/SaveAndCancelButton';
import { GetLabel } from '@/components/FileUploader/LabelFile';
import { GetProgress } from '@/components/FileUploader/ProgressFile';
import { SimpleIconButtonWithTootip } from '@/componentsphase2/ShowFile';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import { measureWidth } from '@/utils/StringWidthMeasure';
import { getFileExtension } from '@/utils/commonUtils';
import { IconButtonNewStyle } from './FlagBlockAdd';
import './FlagBlock.css';
import styleModule from '@/pages/SIPage/SIDetailPage/GeneralInfoSection.module.scss';

import MandatoryIcon from '@/assets/MandatoryIcon.svg';
import MildIssueIcon from '@/assets/MildIssueIcon.svg';
import SevereIssueIcon from '@/assets/SevereIssueIcon.svg';
import UploadIcon2 from '@/assets/uploadIcon2.svg';
import DownloadIcon from '@/assets/downloadIcon.svg';
import DownloadIcon2 from '@/assets/donwloadIcon2.svg';
import Warning500_6 from '@/assets/Warning500_6.svg';
import Error500_6 from '@/assets/Error500_6.svg';
import Off from '@/assets/AlertGeOff.svg';
import AutocompleteArrowIcon from '@/componentsphase2/Autocomplete/SVGComponents/AutocompleteArrowIcon.svg';
import TextFieldErrorIcon from '@/assets/TextFieldErrorIcon.svg';
import ViewMoreDownArrow from '@/assets/ViewMoreDownArrow.svg';
import {
  deleteFlag,
  downloadAllFile,
  downloadSingleFile,
  editFlag,
  getFlagByGECode,
  uploadFile
} from "@/actions/SIMT-SI/GuestEditor/GEFlag";
export const WordIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M3.23223 1.56573C3.70107 1.09689 4.33695 0.833496 4.99999 0.833496H11.6666C11.8876 0.833496 12.0996 0.921293 12.2559 1.07757L17.2559 6.07755C17.4121 6.23383 17.4999 6.44579 17.4999 6.6668V16.6668C17.4999 17.3298 17.2365 17.9657 16.7677 18.4345C16.2989 18.9034 15.663 19.1667 14.9999 19.1667H4.99999C4.33695 19.1667 3.70107 18.9034 3.23223 18.4345C2.76339 17.9657 2.5 17.3298 2.5 16.6668V3.33348C2.5 2.67045 2.76339 2.03456 3.23223 1.56573ZM4.99999 2.50016C4.77898 2.50016 4.56702 2.58795 4.41074 2.74423C4.25446 2.90051 4.16666 3.11247 4.16666 3.33348V16.6668C4.16666 16.8878 4.25446 17.0997 4.41074 17.256C4.56702 17.4123 4.77898 17.5001 4.99999 17.5001H14.9999C15.221 17.5001 15.4329 17.4123 15.5892 17.256C15.7455 17.0997 15.8333 16.8878 15.8333 16.6668V7.01198L11.3214 2.50016H4.99999Z'
        fill='#596A7C'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.8335 14.1668C5.8335 13.7066 6.20659 13.3335 6.66683 13.3335H13.3335C13.7937 13.3335 14.1668 13.7066 14.1668 14.1668C14.1668 14.6271 13.7937 15.0002 13.3335 15.0002H6.66683C6.20659 15.0002 5.8335 14.6271 5.8335 14.1668Z'
        fill='#596A7C'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.8335 10.8333C5.8335 10.3731 6.20659 10 6.66683 10H13.3335C13.7937 10 14.1668 10.3731 14.1668 10.8333C14.1668 11.2936 13.7937 11.6667 13.3335 11.6667H6.66683C6.20659 11.6667 5.8335 11.2936 5.8335 10.8333Z'
        fill='#596A7C'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.8335 7.49983C5.8335 7.0396 6.20659 6.6665 6.66683 6.6665H8.33348C8.79372 6.6665 9.16681 7.0396 9.16681 7.49983C9.16681 7.96007 8.79372 8.33316 8.33348 8.33316H6.66683C6.20659 8.33316 5.8335 7.96007 5.8335 7.49983Z'
        fill='#596A7C'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11.6668 0.833496C12.1271 0.833496 12.5002 1.20659 12.5002 1.66683V5.83347H16.6668C17.127 5.83347 17.5001 6.20657 17.5001 6.6668C17.5001 7.12704 17.127 7.50013 16.6668 7.50013H11.6668C11.2066 7.50013 10.8335 7.12704 10.8335 6.6668V1.66683C10.8335 1.20659 11.2066 0.833496 11.6668 0.833496Z'
        fill='#596A7C'
      />
    </svg>
  );
};
export const JPGIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4.16649 3.33329C3.70626 3.33329 3.33316 3.70638 3.33316 4.16661V15.8332C3.33316 16.2935 3.70626 16.6666 4.16649 16.6666H15.8331C16.2933 16.6666 16.6664 16.2935 16.6664 15.8332V4.16661C16.6664 3.70638 16.2933 3.33329 15.8331 3.33329H4.16649ZM1.6665 4.16661C1.6665 2.78591 2.78579 1.66663 4.16649 1.66663H15.8331C17.2138 1.66663 18.3331 2.78591 18.3331 4.16661V15.8332C18.3331 17.2139 17.2138 18.3332 15.8331 18.3332H4.16649C2.78579 18.3332 1.6665 17.2139 1.6665 15.8332V4.16661Z'
        fill='#596A7C'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.7442 7.74408C13.0696 7.41864 13.5973 7.41864 13.9227 7.74408L18.0894 11.9107C18.4148 12.2362 18.4148 12.7638 18.0894 13.0892C17.7639 13.4147 17.2363 13.4147 16.9108 13.0892L13.3335 9.51184L4.75608 18.0892C4.43064 18.4146 3.90301 18.4146 3.57757 18.0892C3.25214 17.7638 3.25214 17.2361 3.57757 16.9107L12.7442 7.74408Z'
        fill='#596A7C'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.08332 6.66666C6.85321 6.66666 6.66666 6.85321 6.66666 7.08332C6.66666 7.31344 6.85321 7.49999 7.08332 7.49999C7.31344 7.49999 7.49999 7.31344 7.49999 7.08332C7.49999 6.85321 7.31344 6.66666 7.08332 6.66666ZM5 7.08332C5 5.93274 5.93274 5 7.08332 5C8.23391 5 9.16665 5.93274 9.16665 7.08332C9.16665 8.23391 8.23391 9.16665 7.08332 9.16665C5.93274 9.16665 5 8.23391 5 7.08332Z'
        fill='#596A7C'
      />
    </svg>
  );
};

export const PDFIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.8335 1.6665H5.00016C4.55813 1.6665 4.13421 1.8421 3.82165 2.15466C3.50909 2.46722 3.3335 2.89114 3.3335 3.33316V16.6664C3.3335 17.1085 3.50909 17.5324 3.82165 17.8449C4.13421 18.1575 4.55813 18.3331 5.00016 18.3331H15.0001C15.4421 18.3331 15.8661 18.1575 16.1786 17.8449C16.4912 17.5324 16.6668 17.1085 16.6668 16.6664V7.49981L10.8335 1.6665Z'
        stroke='#596A7C'
        stroke-width='1.66666'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10.8335 1.6665V7.49981H16.6668'
        stroke='#596A7C'
        stroke-width='1.66666'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
export const GIFIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_4343_151285)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M3.23223 1.5656C3.70107 1.09676 4.33695 0.833374 4.99999 0.833374H10.8333C11.0543 0.833374 11.2663 0.921171 11.4225 1.07745L17.2559 6.91076C17.4121 7.06704 17.4999 7.279 17.4999 7.50001V16.6666C17.4999 17.3297 17.2365 17.9656 16.7677 18.4344C16.2989 18.9032 15.663 19.1666 14.9999 19.1666H4.99999C4.33695 19.1666 3.70107 18.9032 3.23223 18.4344C2.76339 17.9656 2.5 17.3297 2.5 16.6666V3.33336C2.5 2.67032 2.76339 2.03444 3.23223 1.5656ZM4.99999 2.50003C4.77898 2.50003 4.56702 2.58783 4.41074 2.74411C4.25446 2.90039 4.16666 3.11235 4.16666 3.33336V16.6666C4.16666 16.8876 4.25446 17.0996 4.41074 17.2559C4.56702 17.4122 4.77898 17.5 4.99999 17.5H14.9999C15.221 17.5 15.4329 17.4122 15.5892 17.2559C15.7455 17.0996 15.8333 16.8876 15.8333 16.6666V7.84519L10.4881 2.50003H4.99999Z'
          fill='#596A7C'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M10.8333 0.833374C11.2936 0.833374 11.6667 1.20647 11.6667 1.6667V6.66668H16.6666C17.1269 6.66668 17.5 7.03978 17.5 7.50001C17.5 7.96025 17.1269 8.33334 16.6666 8.33334H10.8333C10.3731 8.33334 10 7.96025 10 7.50001V1.6667C10 1.20647 10.3731 0.833374 10.8333 0.833374Z'
          fill='#596A7C'
        />
        <path
          d='M7.01822 11.3216H8.90623V13.789C8.60024 13.8888 8.31162 13.9583 8.04035 13.9973C7.77125 14.0386 7.49565 14.0592 7.21353 14.0592C6.49522 14.0592 5.94618 13.8487 5.5664 13.4277C5.1888 13.0045 5 12.3979 5 11.608C5 10.8398 5.21918 10.2408 5.65755 9.81115C6.09809 9.38147 6.70789 9.16663 7.48697 9.16663C7.97525 9.16663 8.44616 9.26428 8.89972 9.45959L8.56444 10.2669C8.21722 10.0933 7.85589 10.0065 7.48046 10.0065C7.04426 10.0065 6.69487 10.1529 6.43229 10.4459C6.1697 10.7389 6.03841 11.1328 6.03841 11.6276C6.03841 12.144 6.14366 12.539 6.35416 12.8124C6.56683 13.0837 6.87499 13.2193 7.27864 13.2193C7.48914 13.2193 7.7029 13.1976 7.91991 13.1542V12.1614H7.01822V11.3216Z'
          fill='#596A7C'
        />
        <path
          d='M10.0423 13.9941V9.23499H11.0514V13.9941H10.0423Z'
          fill='#596A7C'
        />
        <path
          d='M13.2422 13.9941H12.2493V9.23499H14.9772V10.0618H13.2422V11.289H14.8567V12.1126H13.2422V13.9941Z'
          fill='#596A7C'
        />
      </g>
      <defs>
        <clipPath id='clip0_4343_151285'>
          <rect width='19.9999' height='19.9999' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
export const EXCLLICON = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4.16665 3.33329C3.70641 3.33329 3.33332 3.70638 3.33332 4.16661V15.8332C3.33332 16.2935 3.70641 16.6666 4.16665 16.6666H15.8333C16.2935 16.6666 16.6666 16.2935 16.6666 15.8332V4.16661C16.6666 3.70638 16.2935 3.33329 15.8333 3.33329H4.16665ZM1.66666 4.16661C1.66666 2.78591 2.78594 1.66663 4.16665 1.66663H15.8333C17.214 1.66663 18.3332 2.78591 18.3332 4.16661V15.8332C18.3332 17.2139 17.214 18.3332 15.8333 18.3332H4.16665C2.78594 18.3332 1.66666 17.2139 1.66666 15.8332V4.16661Z'
        fill='#596A7C'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.49996 6.66663C7.9602 6.66663 8.33329 7.03972 8.33329 7.49996V17.4999C8.33329 17.9601 7.9602 18.3332 7.49996 18.3332C7.03973 18.3332 6.66663 17.9601 6.66663 17.4999V7.49996C6.66663 7.03972 7.03973 6.66663 7.49996 6.66663Z'
        fill='#596A7C'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M18.3333 11.6666C18.3333 12.1268 17.9602 12.4999 17.4999 12.4999L2.49999 12.4999C2.03975 12.4999 1.66666 12.1268 1.66666 11.6666C1.66666 11.2063 2.03975 10.8333 2.49999 10.8333L17.4999 10.8333C17.9602 10.8333 18.3333 11.2063 18.3333 11.6666Z'
        fill='#596A7C'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M1.66666 7.49996C1.66666 7.03972 2.03975 6.66663 2.49999 6.66663H17.4999C17.9602 6.66663 18.3332 7.03972 18.3332 7.49996C18.3332 7.96019 17.9602 8.33329 17.4999 8.33329H2.49999C2.03975 8.33329 1.66666 7.96019 1.66666 7.49996Z'
        fill='#596A7C'
      />
    </svg>
  );
};

export const TXTICON = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_5658_20214)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M3.23221 1.5656C3.70105 1.09676 4.33694 0.833374 4.99997 0.833374H10.8333C11.0543 0.833374 11.2663 0.921171 11.4225 1.07745L17.2558 6.91076C17.4121 7.06704 17.4999 7.279 17.4999 7.50001V16.6666C17.4999 17.3297 17.2365 17.9656 16.7677 18.4344C16.2988 18.9032 15.663 19.1666 14.9999 19.1666H4.99997C4.33693 19.1666 3.70105 18.9032 3.23221 18.4344C2.76338 17.9656 2.49998 17.3297 2.49998 16.6666V3.33336C2.49998 2.67032 2.76338 2.03444 3.23221 1.5656ZM4.99997 2.50003C4.77896 2.50003 4.567 2.58783 4.41072 2.74411C4.25444 2.90039 4.16664 3.11235 4.16664 3.33336V16.6666C4.16664 16.8876 4.25444 17.0996 4.41072 17.2559C4.567 17.4122 4.77896 17.5 4.99997 17.5H14.9999C15.2209 17.5 15.4329 17.4122 15.5892 17.2559C15.7455 17.0996 15.8333 16.8876 15.8333 16.6666V7.84519L10.4881 2.50003H4.99997Z'
          fill='#596A7C'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M10.8333 0.833374C11.2935 0.833374 11.6666 1.20647 11.6666 1.6667V6.66668H16.6666C17.1268 6.66668 17.4999 7.03978 17.4999 7.50001C17.4999 7.96025 17.1268 8.33334 16.6666 8.33334H10.8333C10.373 8.33334 9.99995 7.96025 9.99995 7.50001V1.6667C9.99995 1.20647 10.373 0.833374 10.8333 0.833374Z'
          fill='#596A7C'
        />
        <path
          d='M7.19141 14H6.2832V10.4727H5.12012V9.7168H8.35449V10.4727H7.19141V14ZM11.9766 14H10.9395L9.94336 12.3799L8.94727 14H7.97461L9.39551 11.791L8.06543 9.7168H9.06738L9.99023 11.2578L10.8955 9.7168H11.874L10.5293 11.8408L11.9766 14ZM13.668 14H12.7598V10.4727H11.5967V9.7168H14.8311V10.4727H13.668V14Z'
          fill='#596A7C'
        />
      </g>
      <defs>
        <clipPath id='clip0_5658_20214'>
          <rect width='19.9999' height='19.9999' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PPTICON = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M3.73223 1.73223C4.20107 1.26339 4.83695 1 5.49999 1H11.3333C11.5543 1 11.7663 1.0878 11.9225 1.24408L17.7559 7.07738C17.9121 7.23366 17.9999 7.44562 17.9999 7.66664V16.8333C17.9999 17.4963 17.7365 18.1322 17.2677 18.601C16.7989 19.0699 16.163 19.3333 15.4999 19.3333H5.49999C4.83695 19.3333 4.20107 19.0699 3.73223 18.601C3.26339 18.1322 3 17.4963 3 16.8333V3.49999C3 2.83695 3.26339 2.20107 3.73223 1.73223ZM5.49999 2.66666C5.27898 2.66666 5.06702 2.75446 4.91074 2.91074C4.75446 3.06702 4.66666 3.27898 4.66666 3.49999V16.8333C4.66666 17.0543 4.75446 17.2662 4.91074 17.4225C5.06702 17.5788 5.27898 17.6666 5.49999 17.6666H15.4999C15.721 17.6666 15.9329 17.5788 16.0892 17.4225C16.2455 17.2662 16.3333 17.0543 16.3333 16.8333V8.01181L10.9881 2.66666H5.49999Z'
        fill='#596A7C'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11.3333 1C11.7935 1 12.1666 1.37309 12.1666 1.83333V6.83331H17.1666C17.6268 6.83331 17.9999 7.2064 17.9999 7.66664C17.9999 8.12687 17.6268 8.49997 17.1666 8.49997H11.3333C10.8731 8.49997 10.5 8.12687 10.5 7.66664V1.83333C10.5 1.37309 10.8731 1 11.3333 1Z'
        fill='#596A7C'
      />
      <path
        d='M11 11.5138C10.9293 11.9847 11 12.5992 10.1154 13.4418C9.79577 13.6983 9.41729 13.8737 9.01304 13.9526C8.60879 14.0315 8.19108 14.0116 7.79643 13.8947C7.40178 13.7777 7.04221 13.5671 6.74915 13.2815C6.45609 12.9958 6.23847 12.6437 6.11531 12.256C5.99214 11.8683 5.96719 11.4567 6.04264 11.0573C6.11808 10.6578 6.29162 10.2827 6.54808 9.96468C7.04937 9.34303 8 9 9 9'
        stroke='#596A7C'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11 11.5C11 11.5 10.9843 11.2159 10.882 10.8519C10.7796 10.488 10.6296 10.1573 10.4404 9.87868C10.2512 9.6001 10.0267 9.37913 9.77954 9.22836C9.5324 9.0776 9.26751 9 9 9V11.5H11Z'
        stroke='#596A7C'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M13 13L15 13'
        stroke='#596A7C'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11 14.5L15 14.5'
        stroke='#596A7C'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9 16L15 16'
        stroke='#596A7C'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export const ZIPICON = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_5658_20587)'>
        <path
          d='M7.64166 5.00004L9.30833 6.66671H16.6667V15H3.33333V5.00004H7.64166ZM8.33333 3.33337H3.33333C2.41666 3.33337 1.675 4.08337 1.675 5.00004L1.66666 15C1.66666 15.9167 2.41666 16.6667 3.33333 16.6667H16.6667C17.5833 16.6667 18.3333 15.9167 18.3333 15V6.66671C18.3333 5.75004 17.5833 5.00004 16.6667 5.00004H10L8.33333 3.33337Z'
          fill='#596A7C'
        />
        <path
          d='M9.33105 13H6.14355V12.4111L8.19727 9.4668H6.19922V8.7168H9.27539V9.30273L7.22461 12.25H9.33105V13ZM9.76367 13V8.7168H10.6719V13H9.76367ZM12.4082 10.7324H12.707C12.9863 10.7324 13.1953 10.6777 13.334 10.5684C13.4727 10.457 13.542 10.2959 13.542 10.085C13.542 9.87207 13.4834 9.71484 13.3662 9.61328C13.251 9.51172 13.0693 9.46094 12.8213 9.46094H12.4082V10.7324ZM14.459 10.0527C14.459 10.5137 14.3145 10.8662 14.0254 11.1104C13.7383 11.3545 13.3291 11.4766 12.7979 11.4766H12.4082V13H11.5V8.7168H12.8682C13.3877 8.7168 13.7822 8.8291 14.0518 9.05371C14.3232 9.27637 14.459 9.60938 14.459 10.0527Z'
          fill='#596A7C'
        />
      </g>
      <defs>
        <clipPath id='clip0_5658_20587'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const RARICON = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_5658_20749)'>
        <path
          d='M7.64166 5.00004L9.30833 6.66671H16.6667V15H3.33333V5.00004H7.64166ZM8.33333 3.33337H3.33333C2.41666 3.33337 1.675 4.08337 1.675 5.00004L1.66666 15C1.66666 15.9167 2.41666 16.6667 3.33333 16.6667H16.6667C17.5833 16.6667 18.3333 15.9167 18.3333 15V6.66671C18.3333 5.75004 17.5833 5.00004 16.6667 5.00004H10L8.33333 3.33337Z'
          fill='#596A7C'
        />
        <path
          d='M5.86851 10.7847H6.14867C6.42323 10.7847 6.62588 10.7402 6.75662 10.6512C6.88736 10.5622 6.95274 10.4223 6.95274 10.2316C6.95274 10.0427 6.8855 9.90827 6.75102 9.82834C6.61841 9.74841 6.41202 9.70845 6.13186 9.70845H5.86851V10.7847ZM5.86851 11.4714V13H5V9.01635H6.19349C6.75008 9.01635 7.16192 9.11535 7.42901 9.31335C7.6961 9.50954 7.82965 9.80836 7.82965 10.2098C7.82965 10.4441 7.76334 10.653 7.63073 10.8365C7.49812 11.0182 7.31041 11.1608 7.0676 11.2643C7.68396 12.1599 8.08553 12.7384 8.2723 13H7.30854L6.33077 11.4714H5.86851Z'
          fill='#596A7C'
        />
        <path
          d='M11.0345 13L10.7375 12.0518H9.24422L8.94724 13H8.0115L9.45714 9H10.519L11.9702 13H11.0345ZM10.5302 11.3433C10.2556 10.4841 10.1006 9.99818 10.0651 9.88556C10.0315 9.77293 10.0072 9.68392 9.99225 9.61853C9.93061 9.85104 9.75411 10.426 9.46274 11.3433H10.5302Z'
          fill='#596A7C'
        />
        <path
          d='M13.0962 10.7847H13.3764C13.6509 10.7847 13.8536 10.7402 13.9843 10.6512C14.1151 10.5622 14.1804 10.4223 14.1804 10.2316C14.1804 10.0427 14.1132 9.90827 13.9787 9.82834C13.8461 9.74841 13.6397 9.70845 13.3596 9.70845H13.0962V10.7847ZM13.0962 11.4714V13H12.2277V9.01635H13.4212C13.9778 9.01635 14.3896 9.11535 14.6567 9.31335C14.9238 9.50954 15.0573 9.80836 15.0573 10.2098C15.0573 10.4441 14.991 10.653 14.8584 10.8365C14.7258 11.0182 14.5381 11.1608 14.2953 11.2643C14.9117 12.1599 15.3132 12.7384 15.5 13H14.5362L13.5585 11.4714H13.0962Z'
          fill='#596A7C'
        />
      </g>
      <defs>
        <clipPath id='clip0_5658_20749'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ChooseIconByType = fileType => {
  if (fileType === 'application/pdf' || fileType === 'pdf') return PDFIcon;
  else if (
    fileType === 'application/msword' ||
    fileType === 'doc' ||
    fileType === 'docx' ||
    fileType ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  )
    return WordIcon;
  else if (fileType === 'image/gif' || fileType === 'gif') return GIFIcon;
  else if (fileType === 'image/jpeg' || fileType === 'jpg') return JPGIcon;
  else if (
    fileType ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    fileType === 'application/vnd.ms-excel' ||
    fileType === 'text/csv' ||
    fileType === 'xls' ||
    fileType === 'xlsx' ||
    fileType === 'csv'
  )
    return EXCLLICON;
  else if (fileType === 'text/plain' || fileType === 'txt') return TXTICON;
  else if (
    fileType === 'application/vnd.ms-powerpoint' ||
    fileType === 'ppt' || fileType === 'pptx' ||
    fileType ===
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  )
    return PPTICON;
  else if (
    fileType === 'application/zip' ||
    fileType === 'application/x-gzip' ||
    fileType === 'application/x-zip-compressed' ||
    fileType === 'zip'
  )
    return ZIPICON;
  else if (
    fileType === 'application/rar' ||
    fileType === 'application/x-compressed' ||
    fileType === 'rar'
  )
    return RARICON;
  else return JPGIcon;
};
export const modifyfileName = str => {
  let firstUnderscoreIndex = str.indexOf('_');
  let secondUnderscoreIndex = str.indexOf('_', firstUnderscoreIndex + 1);
  let result = str.substring(secondUnderscoreIndex + 1);
  return result;
};
export const formatFileSize = size => {
  if (size === undefined) return;
  if (size === 0) return '0 B';
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  const digitGroups = Math.floor(Math.log10(size) / Math.log10(1024));
  const formattedSize = (size / Math.pow(1024, digitGroups)).toFixed(2);
  return `${formattedSize} ${units[digitGroups]}`;
};
document.addEventListener('drop', function (e) {
  e.preventDefault();
}, false);
document.addEventListener('dragover', function (e) {
  e.preventDefault();
}, false);
const FileUpload = ({
  geId,
  onFileChange,
  isSevere,
  initialValue,
  Fileindex,
  isClear,
  setIsClear,
  disable,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [iserrorMessage, setIsErrorMessage] = useState(false);
  const [isSizeError, setIsSizeError] = useState(false);
  const [isDeleteError, setIsDeleteError] = useState(false);
  const [fileProgress, setFileProgress] = useState([]);
  const [progressUpload, setProgressUpload] = useState({});
  const [cancelTokenList, setCancelTokenList] = useState({});
  const [upfileName, setUpfileName] = useState({});
  // -1 delete 0 uploading 1 exist
  const [isUpload, setIsUpload] = useState({});
  const [numerr, setNumerr] = useState(false);

  const fileUploadSize =
    Object.keys(isUpload).filter(x => isUpload[x] !== -1)?.length ?? 0;
  const hasFileUploaded = fileUploadSize > 0;

  useEffect(() => {
    const initialIsUpload = {};
    const initialProgress = {};
    const initialUpfileName = {};
    const initialCancelToken = {};
    const updatedFiles = [];
    const source = CancelToken.source();
    if (initialValue !== undefined) {
      for (let i = 0; i < initialValue?.length; i++) {
        initialIsUpload[i] = 1;
        initialProgress[i] = 100;
        initialUpfileName[i] = initialValue[i];
        initialCancelToken[i] = source;

        const newFile = {
          name: modifyfileName(initialValue[i]),
          size: formatFileSize(initialValue[i].split('_')[0]),
          type: initialValue[i].split('.')[
            initialValue[i].split('.')?.length - 1
          ],
        };
        updatedFiles.push(newFile);
      }
      setSelectedFiles(prevFiles => [...prevFiles, ...updatedFiles]);
    }
    setProgressUpload(initialProgress);
    setIsUpload(initialIsUpload);
    setUpfileName(initialUpfileName);
    setCancelTokenList(initialCancelToken);
  }, []);

  useEffect(() => {
    if (!isClear[Fileindex])
      onFileChange(upfile, upfileName, isUpload, Fileindex);
  }, [upfileName, isUpload]);

  const handleDragOver = event => {
    event.preventDefault();
    setIsDragging(true);
  };
  const handleDrop = event => {
    console.log('cqm',fileUploadSize);
    setIsDeleteError(false);
    if (fileUploadSize < 20) {
      event.preventDefault();
      const files = Array.from(event.dataTransfer.files);
      const index = Object.keys(isUpload)?.length;
      let muchFile = files.length>20-fileUploadSize;
      let num=muchFile?20-fileUploadSize:files.length;
      for (let i = 0; i < num ; i++) {
        FileUp([files[i]], index + i);
      }
      if(muchFile){
        setTimeout(()=>{
          setIsErrorMessage(true);
          setNumerr(true);
        },[1000]);
      }
      setIsDragging(false);
    } else {
      setIsErrorMessage(true);
      setNumerr(true);
      setIsDragging(false);
    }
  };

  const [upfile, setUpfile] = useState([]);
  const handleFileChange = (event, index) => {
    setIsDeleteError(false);
    if (fileUploadSize < 20) {
      const files = Array.from(event.target.files);
      let muchFile = files.length>20-fileUploadSize;
      let num=muchFile?20-fileUploadSize:files.length;
      for (let i = 0; i < num ; i++) {
        FileUp([files[i]], index + i);
      }
      if(muchFile){
        setTimeout(()=>{
          setIsErrorMessage(true);
          setNumerr(true);
        },[1000]);
      }
      setIsDragging(false);
    } else {
      setIsErrorMessage(true);
      setNumerr(true);
      setIsDragging(false);
    }

    fileInputRef.current.value = null;
  };
  const FileUp = (files, index) => {
    const allow = handleFileUpload(files);
    if (!allow) {
      fileInputRef.current.value = null;
      return;
    }
    let newupfile = upfile;
    newupfile.push(files[0]);
    setUpfile(newupfile);
    const source = CancelToken.source();
    dispatch(
      uploadFile(
        geId,
        files[0],
        source,
        percentage => {
          setProgressUpload(prevProgress => {
            let tmpProgress = { ...prevProgress };
            tmpProgress[index] = percentage;
            return tmpProgress;
          });
        },
        upfileBack => {
          setIsUpload(prevIsUpload => {
            let tmpIsUpload = { ...prevIsUpload };
            tmpIsUpload[index] = 1;
            return tmpIsUpload;
          });
          setUpfileName(prevUpfileName => {
            let tmpUpfileName = { ...prevUpfileName };
            tmpUpfileName[index] = upfileBack;
            return tmpUpfileName;
          });
        }
      )
    );
    setIsUpload(prevIsUpload => {
      let tmpIsUpload = { ...prevIsUpload };
      tmpIsUpload[index] = 0;
      return tmpIsUpload;
    });

    setProgressUpload(prevProgress => {
      let tmpProgress = { ...prevProgress };
      tmpProgress[index] = 0;
      return tmpProgress;
    });
    setCancelTokenList(prevCanceltoken => {
      let tmpCanceltoken = { ...prevCanceltoken };
      tmpCanceltoken[index] = source;
      return tmpCanceltoken;
    });
  };

  const handleFileUpload = files => {
    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'image/png',
      'image/jpeg',
      'image/gif',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'PNGimage/png',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'text/plain',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/x-zip-compressed',
      'application/zip',
      'application/x-gzip',
      'application/rar',
      'application/x-compressed',
      'text/csv',
    ];
    const allowedSize = 5 * 1024 * 1024; // 5MB
    const updatedFiles = [];
    for (let i = 0; i < files?.length; i++) {
      const file = files[i];
      if (allowedTypes.includes(file.type) || file.name.split('.').pop().toLowerCase() === 'rar') {
        if (file.size <= allowedSize) {
          const newFile = {
            name: file.name,
            size: formatFileSize(file.size),
            type: file.type,
          };
          updatedFiles.push(newFile);
          setIsErrorMessage(false);
          setIsSizeError(false);
        } else {
          setIsErrorMessage(true);
          setIsSizeError(true);
          return false;
        }
      } else {
        setIsErrorMessage(true);
        return false;
      }
    }
    setSelectedFiles(prevFiles => [...prevFiles, ...updatedFiles]);
    return true;
  };

  const handleTextClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (isClear[Fileindex]) {
      setSelectedFiles([]);
      setIsUpload({});
      setProgressUpload({});
      setCancelTokenList({});
      setUpfileName({});
      setIsErrorMessage(false);
      setNumerr(false);
    }
  }, [isClear]);

  const handleDeleteFile = (index, event) => {
    if (isSevere[Fileindex] && fileUploadSize <= 1) {
      setIsDeleteError(true);
      setIsErrorMessage(true);
      return;
    }

    setIsDeleteError(false);
    setIsErrorMessage(false);
    setNumerr(false);

    let tmpIsUpload = JSON.parse(JSON.stringify(isUpload));
    tmpIsUpload[index] = -1;
    setIsUpload(tmpIsUpload);
  };
  const handleFileDownload = file => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = file.name;
    link.click();
  };

  const handleDownloadAll = () => {
    selectedFiles.forEach(file => {
      handleFileDownload(file);
    });
  };
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    if (
      (selectedFiles === undefined || selectedFiles?.length === 0) &&
      isSevere[Fileindex] &&
      (initialValue === undefined || initialValue?.length === 0) &&
      disable
    ) {
      setIsErrorMessage(true);
    }
  }, [selectedFiles, isSevere, disable]);

  useEffect(() => {
    if (!isSevere[Fileindex]) {
      setIsErrorMessage(false);
    }
  }, [isSevere]);

  const getTypeName = type => {
    if (type === 'application/msword') return 'doc';
    else if (
      type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    )
      return 'docx';
    else if (type === 'PNGimage/png') return 'png';
    else return type;
  };

  const CancelToken = axios.CancelToken;
  function abortRead(cancelToken, index) {
    if (
      progressUpload[index] !== 100 ||
      (progressUpload[index] === 100 && uploadFile[index] === undefined)
    ) {
      let tmpProgress = JSON.parse(JSON.stringify(progressUpload));
      tmpProgress[index] = 0;
      setProgressUpload(tmpProgress);

      let tmpIsUpload = JSON.parse(JSON.stringify(isUpload));
      tmpIsUpload[index] = -1;
      setIsUpload(tmpIsUpload);

      if (progressUpload[index] !== 100) {
        setCancelToken(CancelToken.source());
      }
    }
  }
  const [boxHeight, setBoxHeight] = useState('114px');
  useEffect(() => {
    let sum1 = selectedFiles.filter(
      (file, index) => isUpload[index] === 0
    )?.length;
    let sum2 = selectedFiles.filter(
      (file, index) => isUpload[index] !== -1
    )?.length;
    if (sum1 > 0 || sum2 > 0) {
      let length = 100 + (sum2 - 1) * 40 + sum1 * 14;
      setBoxHeight(length + 'px');
    } else {
      setBoxHeight('100px');
    }
  }, [selectedFiles, isUpload]);
  return (
    <div style={{ position: 'relative' }}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        id='file-input'
        className='file-upload'
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          width: '360px',
          height: boxHeight,
          borderRadius: '4px',
          border: iserrorMessage ? '2px dashed #ccc' : '1px dashed #ccc',
          borderColor: iserrorMessage
            ? '#C40000'
            : isDragging || hovered
            ? '#0052CC'
            : '#ccc',
          backgroundColor: !isDragging
            ? iserrorMessage && hovered
              ? '#FFEFEF'
              : '#FFFFFF'
            : iserrorMessage
            ? '#FFFFFF'
            : '#CEDDF9',
          padding: '12px 30px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: hasFileUploaded ? '8px' : '12px',
        }}
        onDragOver={handleDragOver}
        onDragLeave={() => setIsDragging(false)}
        onDrop={handleDrop}
      >
        <input
          id='uploadInput'
          type='file'
          accept='.pdf,.doc,.docx,image/png,image/jpeg,image/gif,.xlsx,.xls,.csv,.txt,.ppt,.pptx,.zip,.rar'
          style={{ display: 'none' }}
          onChange={e => handleFileChange(e, Object.keys(isUpload)?.length)}
          ref={fileInputRef}
          multiple={true}
        />
        <Stack direction='row' sx={{ alignItems: 'center', mb: '4px' }}>
          <SvgIcon viewBox='0 0 18 18' sx={{ width: '18px', height: '18px' }}>
            {' '}
            <UploadIcon2 sx={{ width: '18px', height: '18px' }}></UploadIcon2>
          </SvgIcon>
          <p
            style={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '18px',
              color: '#596A7C',
              marginBottom: '0',
              marginTop: '0',
              marginLeft: '5px',
            }}
          >
            Drag & Drop or&nbsp;
            <span
              style={{
                color: '#154AB6',
                fontWeight: 400,
              }}
              onMouseEnter={e => {
                e.target.style.color = '#113D95';
                e.target.style.fontWeight = 600;
              }}
              onMouseLeave={e => {
                e.target.style.color = '#154AB6';
                e.target.style.fontWeight = 400;
              }}
              onClick={handleTextClick}
            >
              Choose File
            </span>{' '}
            to upload
          </p>
        </Stack>
        {hasFileUploaded ? (
          selectedFiles.map((file, index) =>
            isUpload[index] !== -1 ? (
              <>
                {isUpload[index] === 0 ? (
                  <GetProgress
                    sx={{ marginLeft: '0px' }}
                    FileIcon={ChooseIconByType(file.type)}
                    value={progressUpload[index]}
                    abort={() => abortRead(cancelTokenList[index], index)}
                    fileName={file.name}
                    fileSize={file.size}
                    fileType={getTypeName(file.type)}
                  />
                ) : (
                  <GetLabel
                    sx={{ marginLeft: '0px' }}
                    FileIcon={ChooseIconByType(file.type)}
                    del={() => handleDeleteFile(index)}
                    fileName={file.name}
                    fileSize={file.size}
                    fileMIMEType={getTypeName(file.type)}
                    isDownLoad={false}
                  />
                )}
              </>
            ) : (
              <></>
            )
          )
        ) : (
          <div>
            {(selectedFiles === undefined || selectedFiles.length === 0) &&
            isSevere &&
            disable ? (
              <p
                style={{
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#EE5350',
                  margin: 0,
                }}
              >
                <span
                  style={{
                    fontWeight: 600,
                  }}
                  onClick={handleTextClick}
                >
                  Please upload at a least file!
                </span>
                <br></br>
                Support Word,PDF,TXT,PPT,Excel,CSV,PNG,JPEG,JPG,GIF,Zip,rar{' '}
                <br></br>Max &nbsp;
                <span
                  style={{
                    fontWeight: 600,
                  }}
                  onClick={handleTextClick}
                >
                  5M
                </span>{' '}
                each
              </p>
            ) : (
              <>
                {iserrorMessage ? (
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: '12px',
                      lineHeight: '15px',
                      color: '#EE5350',
                      margin: -7,
                    }}
                  >
                    Please upload files in supported format:<br></br>Word, PDF,
                    TXT, PPT, Excel, CSV, PNG, JPEG, JPG,<br></br> GIF, Zip, rar
                    <br></br>Maximum size:{' '}
                    <span style={{ fontWeight: 600 }}>5M</span>{' '}
                  </p>
                ) : (
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: '12px',
                      lineHeight: '15px',
                      color: '#98A7B6',
                      margin: 0,
                    }}
                  >
                    Support Word, PDF, TXT, PPT, Excel, CSV, PNG, JPEG,<br></br>
                    GIF, Zip, rar.<br></br>Max{' '}
                    <span style={{ fontWeight: 600 }}>5M</span> each.{' '}
                  </p>
                )}
              </>
            )}
          </div>
        )}
        {/* {} */}
      </div>
      <div style={{ width: '360px' }}>
        {hasFileUploaded && !iserrorMessage ? (
          <p
            style={{
              marginTop: 0,
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px',
              color: '#596A7C',
              width: '360px',
            }}
          >
            Support Word,PDF,TXT,PPT,Excel,CSV,PNG,JPEG,JPG,GIF,Zip,rar Max{' '}
            <span style={{ fontWeight: 600 }}>5M</span> each.
          </p>
        ) : null}

        {hasFileUploaded && iserrorMessage ? (
          numerr ? (
            <p
              style={{
                marginTop: 0,
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '15px',
                color: '#EE5350',
                width: '360px',
              }}
            >
              You cannot upload more than 20 evidence
            </p>
          ) : isDeleteError ? (
            <p
              style={{
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '15px',
                color: '#EE5350',
                marginTop: '0',
              }}
            >
              Please upload at a least file!
            </p>
          ) : (
            <p
              style={{
                marginTop: 0,
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '15px',
                color: '#EE5350',
                width: '360px',
              }}
            >
              Support Word,PDF,TXT,PPT,Excel,CSV,PNG,JPEG,JPG,GIF,Zip,rar Max{' '}
              <span style={{ fontWeight: 600 }}>5M</span> each.
            </p>
          )
        ) : null}
      </div>
    </div>
  );
};

const TextInputWithLimit = ({
  onTextChange,
  initialValue,
  index,
  isClear,
  setIsClear,
}) => {
  const [text, setText] = useState(initialValue);

  useEffect(() => {
    if (isClear[index]) {
      setText('');
    }
  }, [isClear[index]]);

  useEffect(() => {
    onTextChange(text, index);
  }, [text]);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const padding1 = '8px 4px 8px 12px';
  const padding2 = '8px 12px 8px 12px';
  const [isFocus, setIsFocus] = useState(false);
  const overflowError = useMemo(
    () => (isFocus ? false : text?.length > 10000),
    [text, isFocus]
  );
  return (
    <div>
      <MyOnlyText
        value={text}
        setValue={setText}
        width={'286px'}
        height={'100px'}
        needEndAdornment={false}
        handleIconButtonClick={() => {
          setText('');
        }}
        minRows={6}
        maxRows={5.5}
        placeholder={''}
        fontSize={12}
        fontColor={'#596A7C'}
        lineHeight={16.34}
        hasScrollbar={hasScrollbar}
        setHasScrollbar={setHasScrollbar}
        textFieldPaddingWithoutEndAdornment={hasScrollbar ? padding1 : padding2}
        isError={overflowError}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      />
      {text !== undefined ? (
        <p
          style={{
            marginTop: 6,
            marginBottom: 0,
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16.34px',
            color: overflowError ? '#EE5350' : '#596A7C',
            width: '286px',
            textAlign: 'right',
            display: 'flex',
            justifyContent: overflowError ? 'space-between' : 'flex-end',
          }}
        >
          {overflowError && (
            <div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
              <TextFieldErrorIcon />
              Should no more than 10000.
            </div>
          )}
          <span>{text?.length + '/10,000'}</span>
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};
const ShowComment = ({ comment }) => {
  const [showFullText, setShowFullText] = useState(false);
  function isElipsis(id) {
    let clientWidth = document.getElementById(id).clientWidth;
    let scrollWidth = document.getElementById(id).scrollWidth;
    return clientWidth < scrollWidth;
  }
  const [showButton, setShowButtom] = useState(false);
  useEffect(() => {
    setShowButtom(isElipsis('showComment'));
  }, [comment]);
  return (
    <Box
      data-selenium-id='GEPage_GEDetailPage-FlagBlock-ShowComment-Box'
      position={'relative'}
      marginBottom={'10px'}
    >
      <Typography
        id='showComment'
        data-selenium-id='GEPage_GEDetailPage-FlagBlock-ShowComment-Typography'
        variant='body2'
        sx={
          showFullText
            ? {
                maxWidth: 286,
                width: '286px',
                fontSize: '12px',
                wordBreak: 'break-word',
              }
            : {
                width: '286px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
                fontSize: '12px',
                wordBreak: 'break-word',
              }
        }
      >
        {comment !== null && comment !== undefined ? <> {comment}</> : <></>}
      </Typography>

      {comment !== null &&
        comment !== undefined &&
        (measureWidth(comment, '12px Open Sans') > 828 || showButton) && (
          <Box
            fontSize={12}
            data-selenium-id='GEDetail_FlagBlock-showComment-ContainerBox'
            sx={{ paddingTop: '2px' }}
          >
            <Link
              component='button'
              onClick={() => setShowFullText(!showFullText)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#154AB6',
                textDecoration: 'none',
                gap: '2px',
                cursor: 'pointer',
              }}
              className={styleModule.ViewAllBox}
              data-selenium-id='GEDetail_FlagBlock-showComment-Link1'
            >
              <Box>{showFullText ? 'Hide' : 'View All'}</Box>
              <ViewMoreDownArrow
                {...(showFullText && {
                  className: styleModule.ViewMoreDownArrow,
                })}
              />
            </Link>
          </Box>
        )}
    </Box>
  );
};

export const ShowFlag = ({ flagType, flagValue }) => {
  let type = flagType;
  if (type !== undefined) type = flagType.split('-')[1];
  return (
    <Box
      data-selenium-id='GEPage_GEDetailPage-FlagBlock-ShowFlagBox'
      sx={{
        height: '24px',
        backgroundColor: `${type === 'Severe Issue' ? '#FFEFEF' : '#FFF0DD'}`,
        border: `1px solid ${type === 'Severe Issue' ? '#FFB0AE' : '#FFD495'}`,
        borderRadius: '4px',
      }}
    >
      <p
        style={{
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          fontFamily: 'Open Sans',
          color: `${type === 'Severe Issue' ? '#EE5350' : '#FF8A00'}`,
          marginRight: '11px',
          marginLeft: '12px',
          marginTop: '1px',
        }}
      >
        {flagValue}
      </p>
    </Box>
  );
};

const DropdownSelect = ({
  onSelectChange,
  initialValue,
  index,
  isClear,
  setIsClear,
  FlagOptions,
  SevereOptions,
}) => {
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    if (initialValue && initialValue !== '') {
      setSelectedValue(initialValue);
    }
  }, [initialValue]);

  const handleSelectChange = event => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    onSelectChange(newValue, index);
  };
  const handleClaerState = () => {
    onSelectChange(null, index);
    setSelectedValue('');
    isClear[index] = true;
    setIsClear([...isClear]);
  };
  const renderOptions = () => {
    let currentGroup = null;
    return FlagOptions.map((option, index) => {
      const { group, value, icon } = option;
      const isFirstOptionInGroup = group !== currentGroup;
      let renderedOption;
      if (group === 'Divider') {
        renderedOption = (
          <Divider
            data-selenium-id='GEPage_GEDetailPage-FlagBlock-DropdownSelect-Divider'
            width='91%'
            variant='middle'
            light={true}
          ></Divider>
        );
      } else {
        renderedOption = (
          <MenuItem
            data-selenium-id='GEPage_GEDetailPage-FlagBlock-DropdownSelect-MenuItem'
            key={index}
            value={value}
            className={isFirstOptionInGroup ? 'first-option' : ''}
            disabled={isFirstOptionInGroup}
            sx={{
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '20px',
              color: '#596A7C',
              paddingLeft: '30px',
              paddingTop: '2px',
              paddingBottom: '2px',
              '&.Mui-disabled': {
                color: 'inherit',
                background: 'transparent',
                cursor: 'not-allowed',
                paddingLeft: '0px',
                opacity: 1,
              },
            }}
          >
            {isFirstOptionInGroup && icon && (
              <IconButton
                sx={{ height: '16px', paddingTop: '16px' }}
                className={isFirstOptionInGroup ? 'icon-inherit' : ''}
                disabled
                data-selenium-id='GEPage_GEDetailPage-FlagBlock-DropdownSelect-IconButton'
              >
                {group === 'Category A' ? (
                  <SevereIssueIcon />
                ) : (
                  <MildIssueIcon />
                )}
              </IconButton>
            )}
            {value}
          </MenuItem>
        );
      }
      if (isFirstOptionInGroup) {
        currentGroup = group;
      }
      return renderedOption;
    });
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
    setTimeout(() => {
      let select = document.getElementById(
        'GEPage_GEDetailPage-FlagBlock-DropdownSelect-FormControl'
      );
      let papper = document.getElementById(
        'GEPage_GEDetailPage-FlagBlock-DropdownSelect-Select-PaperProps'
      );
      let bottom =
        Number(papper.style.marginTop.split('px')[0]) +
        Number(papper.style.top.split('px')[0]) +
        Number(papper.style.height.split('px')[0]);
      let selectBottom = select.getBoundingClientRect().top + 33;
      let difHeightOrigin =
        selectBottom -
        (Number(papper.style.marginTop.split('px')[0]) +
          Number(papper.style.top.split('px')[0]));
      let outer = document.getElementById('outer');
      if (window.innerHeight < bottom + 68 + 20) {
        let difHeight = bottom + 68 + 20 - window.innerHeight;
        let scrollTop = outer.scrollTop;
        outer.scrollTo(0, scrollTop + difHeight + difHeightOrigin);
        setTimeout(() => {
          papper.style.top = `${
            Number(papper.style.top.split('px')[0]) - difHeight + 6
          }px`;
        }, 100);
      }
    }, 100);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <FormControl
      sx={{ width: 286, height: 33, padding: 0, backgroundColor: '#FFFFFF' }}
      onClick={isOpen ? handleClose : handleOpen}
      data-selenium-id='GEPage_GEDetailPage-FlagBlock-DropdownSelect-FormControl'
      id='GEPage_GEDetailPage-FlagBlock-DropdownSelect-FormControl'
    >
      <Select
        open={isOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        inputProps={{ readOnly: true }} // not focus on when click the inputbox
        IconComponent={() => (
          <div
            style={{
              paddingRight: '6px',
              paddingTop: '0px',
            }}
          >
            <IconButtonNewStyle
              sx={
                isOpen
                  ? {
                      transform: 'rotate(180deg)',
                      pt: '2px !important',
                    }
                  : {
                      pb: '2px !important',
                    }
              }
            >
              <AutocompleteArrowIcon
                onClick={isOpen ? handleClose : handleOpen}
              ></AutocompleteArrowIcon>
            </IconButtonNewStyle>
          </div>
        )}
        data-selenium-id='GEPage_GEDetailPage-FlagBlock-DropdownSelect-Select'
        sx={{ height: 33, backgroundColor: '#FFFFFF' }}
        className='custom-select'
        value={selectedValue}
        onChange={handleSelectChange}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
          },
          getContentAnchorEl: null,
          PaperProps: {
            id: 'GEPage_GEDetailPage-FlagBlock-DropdownSelect-Select-PaperProps',
            style: {
              height: '380px',
              width: '20ch',
              marginTop: '6px',
              marginLeft: '143px',
              boxShadow: '0px 2px 5px 0px #00000010',
            },
          },
        }}
        renderValue={() => (
          <Box
            data-selenium-id='GEPage_GEDetailPage-FlagBlock-DropdownSelect-Select-RenderBox'
            sx={{
              boxSizing: 'border-box',
              height: '22px',
              p: '5px 6px',
              display: 'flex',
              width: 'fit-content',
              alignItems: 'center',
              gap: '6px',
              borderRadius: '4px',
              border: '1px solid #BCC5CF',
              color: '#484848',
              fontFamily: 'Open Sans',
              fontSize: '12px',
              lineHeight: '20px',
              cursor: 'default',
              marginLeft: '-8px!important',
            }}
            onClick={e => e.stopPropagation()}
          >
            {!SevereOptions.includes(selectedValue) ? (
              <Warning500_6 />
            ) : (
              <Error500_6 />
            )}
            {selectedValue}
            <Off
              cursor={'pointer'}
              onClick={handleClaerState}
              cursor={'pointer'}
              style={{
                borderRadius: '2px',
              }}
            />
          </Box>
        )}
      >
        {renderOptions()}
      </Select>
    </FormControl>
  );
};

const getFlagId = (flagName, list) => {
  for (let i = 0; i < list?.length; i++) {
    let flag = list[i];
    if (flag.value === flagName) return flag.id;
  }
};
const getFlagType = (flagName, list) => {
  for (let i = 0; i < list?.length; i++) {
    let flag = list[i];
    if (flag.value === flagName) return flag.type;
  }
};
const getFlagKey = (flagName, list) => {
  for (let i = 0; i < list?.length; i++) {
    let flag = list[i];
    if (flag.value === flagName) return flag.key;
  }
};

const FlagBlock = forwardRef((props, ref) => {
  const [counter, setCounter] = useState(0);
  const dispatch = useDispatch();
  const [isSevere, setIsSevere] = useState([]);
  const [isClear, setIsClear] = useState([]);
  const {
    geId,
    GEBasicInformation,
    isEdit,
    setIsEdit,
    FlagData,
    isEditType,
    setAdd,
    // flagNum,
    setEditType,
  } = props;
  const selector = state => {
    return {
      flagsList: state.GE.flagsList,
      upfileBack: state.GE.upfileBack,
      fileProgressArray: state.GE.fileProgressArray,
    };
  };

  useEffect(() => {
    dispatch(getFlagByGECode(geId));
  }, [dispatch, geId, counter]);
  const { flagsList } = useSelector(selector);

  const [flagKey, setFlagKey] = useState([]);
  const [fileName, setFlieName] = useState([]);
  const [flagName, setFlagName] = useState([]);
  const [comment, setComment] = useState([]);
  const [pastComment, setPastComment] = useState([]);
  const [pastFlagName, setPastFlagName] = useState([]);
  const [pastFlagType, setPastFlagType] = useState([]);
  const [pastFileName, setPastFileName] = useState([]);
  const [pastIsSvere, setPastIsSevere] = useState([]);
  const [geFlagId, setGeFlagId] = useState([]);
  const [flagType, setFlagType] = useState([]);

  let FlagOptions = [],
    SevereOptions = [];
  FlagOptions.push({ value: '', group: 'Category A', icon: 'severe issue' });
  if(flagsList!==null && flagsList!==undefined && flagsList!=='error'){
    flagsList?.map(flag => {
      if (flag.type === 'Flags-Severe Issue') {
        FlagOptions.push({ value: flag.value, group: 'Category A' });
        SevereOptions.push(flag.value);
      }
    });
    FlagOptions.push({ value: '', group: 'Category B', icon: 'mild issue' });
    flagsList?.map(flag => {
      if (flag.type === 'Flags-Mild Issue')
        FlagOptions.push({ value: flag.value, group: 'Category B' });
    });
  }

  useEffect(() => {
    let iniflagName = [],
      inicomment = [],
      inigeFlagId = [],
      inifileName = [],
      iniSevere = [];
    let iniFlagType = [],
      iniFlagKey = [];
    for (let i = 0; i < FlagData?.length; i++) {
      if (FlagData[i] !== [] && FlagData[i] !== undefined && FlagData !== 'error') {
        iniflagName.push(FlagData[i].flagInfo.value);
        inicomment.push(FlagData[i].comment);
        inigeFlagId.push(FlagData[i].geFlagId);
        inifileName.push(FlagData[i].files);
        iniFlagKey.push(FlagData[i].flagInfo.key);
        iniFlagType.push(FlagData[i].type);
        let value = FlagData[i].flagInfo.value;
        if (SevereOptions.includes(value)) iniSevere.push(true);
        else iniSevere.push(false);
      }
    }
    setFlagName(iniflagName);
    setComment(inicomment);
    setGeFlagId(inigeFlagId);
    setFlieName(inifileName);
    setFlagType(iniFlagType);
    setFlagKey(iniFlagKey);
    setIsSevere(iniSevere);
    setPastComment(comment);
    setPastFlagName(flagName);
    setPastIsSevere(isSevere);
    setPastFileName(fileName);
    const newIsClear = Array(FlagData?.length).fill(false);
    setIsClear(newIsClear);
  }, [FlagData]);
  let upfiles = [];

  const handleCloseButtonClick = () => {
    let iniflagName = [],
      inicomment = [],
      inigeFlagId = [],
      inifileName = [],
      iniSevere = [];
    let iniFlagType = [],
      iniFlagKey = [];
    for (let i = 0; i < FlagData?.length; i++) {
      if (FlagData[i] !== [] && FlagData[i] !== undefined) {
        iniflagName.push(FlagData[i].flagInfo.value);
        inicomment.push(FlagData[i].comment);
        inigeFlagId.push(FlagData[i].geFlagId);
        inifileName.push(FlagData[i].fileName);
        iniFlagKey.push(FlagData[i].flagInfo.key);
        iniFlagType.push(FlagData[i].type);
        let value = FlagData[i].flagInfo.value;
        if (SevereOptions.includes(value)) iniSevere.push(true);
        else iniSevere.push(false);
      }
    }
    setFlagName(iniflagName);
    setComment(inicomment);
    setGeFlagId(inigeFlagId);
    setFlieName(inifileName);
    setFlagType(iniFlagType);
    setFlagKey(iniFlagKey);
    setIsSevere(iniSevere);
    const newIsClear = Array(FlagData?.length).fill(false);
    setIsClear(newIsClear);

    setIsEdit(false);
    setEditType(false);
    setAdd(false);
  };

  const handleDownloadAll = index => {

    dispatch(downloadAllFile(geId, getFlagId(flagName[index], flagsList)));
  };

  const handleTextChange = (newText, index) => {
    let inicomment = comment.slice();
    inicomment[index] = newText;
    setComment(inicomment);
  };
  const handleSelectChange = (newFlag, index) => {
    let newClear = isClear;
    newClear[index] = false;
    setIsClear([...newClear]);

    let iniflagName = flagName;
    iniflagName[index] = newFlag;
    setFlagName(iniflagName);
    let iniSevere = isSevere;
    if (!SevereOptions.includes(newFlag)) iniSevere[index] = false;
    else iniSevere[index] = true;
    setIsSevere([...iniSevere]);
  };
  useEffect(() => {
    let iniflagKey = [],
      iniflagType = [];
    for (let i = 0; i < flagName; i++) {
      iniflagKey.push(getFlagKey(flagName[i], flagsList));
      iniflagType.push(getFlagType(flagName[i], flagsList));
    }
    setFlagKey(iniflagKey);
    setFlagType(iniflagType);
  }, [flagName]);

  const [fileChange, setFileChang] = useState(false);

  const handleFilesChange = (newfile, upfileName, isUpload, index) => {
    let inifileName = fileName,
      partFileName = [];
    const key = Object.keys(upfileName).filter(
      (item, index) => isUpload[item] !== -1
    );
    for (let i = 0; i < key?.length; i++) {
      partFileName.push(upfileName[key[i]]);
    }
    inifileName[index] = partFileName;
    setFlieName(inifileName);
    upfiles = newfile;
    setFileChang(!fileChange);
  };
  const [fileLength, setFilelength] = useState(20);
  const [fileProgress, setFileProgress] = useState([]);

  const [loadingFlag, setLoadingFlag] = useState(false);

  useEffect(() => {
    let iniFileName = fileName;
    let i;
    for (i = 0; i < isClear.length; i++) {
      if (isClear[i]) iniFileName[i] = [];
    }
    setFlieName(iniFileName);
  }, [isClear]);

  const handleCheckButtonClick = () => {
    let ClearNum = [];
    let num = 0;
    setButtonCheck(true);
    let flagInfo = [];

    // generate flagInfo's values
    for (let i = 0; i < FlagData?.length; i++) {
      if (
        (SevereOptions.includes(flagName[i]) && fileName[i]?.length === 0) ||
        HasbuttonDisable
      )
        return;
      flagInfo.push({
        id: getFlagId(flagName[i], flagsList),
        type: getFlagType(flagName[i], flagsList),
        key: getFlagKey(flagName[i], flagsList),
        value: flagName[i],
      });
    }
    // check if is preparing to delete
    for (let i = 0; i < isClear?.length; i++) {
      if (isClear[i] === true) {
        let flagInfo= {
          id: getFlagId(flagName[i], flagsList),
          type: getFlagType(flagName[i], flagsList),
          key: getFlagKey(flagName[i], flagsList),
          value: flagName[i],
        };
        dispatch(deleteFlag(geId, geFlagId[i], flagInfo,comment[i],fileName[i]));
        ClearNum.push(i);
        num++;
      }
    }
    const newIsClear = Array(isClear?.length).fill(false);
    setIsClear(newIsClear);
    num = 0;

    // update the previous data
    let editData = [];
    for (let i = 0; i < FlagData?.length; i++) {
      if (i === ClearNum[num]) {
        num++;
        continue;
      }
      editData.push({
        geCode: geId,
        geFlagId: geFlagId[i],
        flagInfo: flagInfo[i],
        comment: comment[i],
        files: fileName[i],
      });
    }
    if (editData?.length > 0) {
      dispatch(editFlag(editData));
    }

    setFilelength(upfiles?.length);

    setTimeout(() => {
      dispatch(getFlagByGECode(geId));
    }, 1000);
    setEditType(false);
    setAdd(false);
  };
  const DownloadSingleFile = (filename, fileIndex, flagIndex) => {
    const extName = getFileExtension(filename);
    const fullName=GEBasicInformation?.fullName.replace(/,/g, ' ');
    const downloadName = `${fullName}-${
      flagName[flagIndex]
    }-${fileIndex + 1}${extName}`;
    dispatch(downloadSingleFile(filename, downloadName, geId));
  };
  const ShowFile = ({ fileName, index: flagIndex }) => {
    let file;
    if (typeof fileName === 'string') file = fileName[0];
    else if (typeof fileName === 'object') file = Object.values(fileName)[0];
    return (
      <Stack
        position='relative'
        sx={{
          paddingLeft: '0px',
        }}
      >
        {fileName !== undefined && fileName?.length > 0 ? (
          <div
            style={{
              position: 'absolute',
              right: -6,
              top: 10,
              marginBottom: 10,
              zIndex: 499,
              paddingLeft: '0px!important',
              marginLeft: '0px!important',
            }}
          >
            <SimpleIconButtonWithTootip
              tooltipWord={'Download all evidence'}
              sx={{ marginTop: '-75px' }}
              DefaultIcon={DownloadIcon2}
              hoverIcon={DownloadIcon}
              ActiveIcon={DownloadIcon}
              onClickWork={() => {
                handleDownloadAll(flagIndex);
              }}
            ></SimpleIconButtonWithTootip>
          </div>
        ) : (
          <></>
        )}
        {fileName !== undefined && fileName?.length > 0 ? (
          fileName.map((file, index) => (
            <GetLabel
              sx={{
                marginTop: index === 0 ? '0px' : '8px',
                zIndex: 500,
                // marginLeft: '28px',
                marginLeft: '10px',
              }}
              FileIcon={ChooseIconByType(
                file.split('.')[file.split('.')?.length - 1]
              )}
              del={() => DownloadSingleFile(file, index, flagIndex)}
              // fileName={file.split('_')[2].replace(/(.*)(?=\.)/, '$1')}
              fileName={modifyfileName(file)}
              fileSize={formatFileSize(file.split('_')[0])}
              fileMIMEType={file.split('.')[file.split('.')?.length - 1]}
              isDownLoad={true}
            />
          ))
        ) : (
          <></>
        )}
      </Stack>
    );
  };

  const matches1458 = useMediaQuery('(min-width: 1458px)');

  const renderShowFlag = () => {
    const childFlag = [];
    if(FlagData==='error') return childFlag;
    childFlag.push(
      <Box data-selenium-id='GEPage_GEDetailPage-FlagBlock-ShowBox'>
        <CardContent
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: '28px!important',
            paddingBottom: '32px!important',
          }}
        >
          {FlagData.map((item, index) => {
            return (
              <Stack
                spacing={3}
                data-selenium-id='GEPage_GEDetailPage-FlagBlock-ShowBox-Stack'
                sx={{ paddingTop: index === 0 ? '0px' : '45px' }}
              >
                <Box>
                  <Grid container spacing={1} justifyContent='flex-end'>
                    {/* GE Details Left Grid  */}
                    <Grid item xs={matches1458 ? 6 : 12}>
                      <Stack
                        spacing={2.5}
                        data-selenium-id='GEPage_GEDetailPage-FlagBlock-ShowBox-LeftStack'
                        sx={{ width: '522px' }}
                      >
                        <Box
                          display='flex'
                          alignItems='center'
                          data-selenium-id='GEPage_GEDetailPage-FlagBlock-ShowBox-LeftBox'
                        >
                          <Box
                            display='flex'
                            sx={{ width: '224px', marginRight: '8px' }}
                          >
                            <Typography
                              data-selenium-id='GEPage_GEDetailPage-FlagBlock-ShowBox-LeftBox-FlagTypography'
                              sx={{ color: '#596A7C', fontSize: '14px' }}
                            >
                              {'Flag:'}
                            </Typography>
                          </Box>
                          <Box
                            display='flex'
                            sx={{ width: '280px' }}
                            data-selenium-id='GEPage_GEDetailPage-FlagBlock-ShowBox-LeftBox-Flag'
                          >
                            <ShowFlag
                              flagType={getFlagType(flagName[index], flagsList)}
                              flagValue={flagName[index]}
                            ></ShowFlag>
                          </Box>
                        </Box>
                        <Box
                          display='flex'
                          data-selenium-id='GEPage_GEDetailPage-FlagBlock-ShowBox-LeftBox-Comment'
                          sx={{ marginTop: '8px!important' }}
                        >
                          <Box
                            display='flex'
                            sx={{ width: '224px', marginRight: '8px' }}
                          >
                            <Typography
                              sx={{ color: '#596A7C', fontSize: '14px' }}
                            >
                              {'Comment:'}
                            </Typography>
                          </Box>
                          <Box display='flex' sx={{ width: '224px' }}>
                            <ShowComment comment={comment[index]}></ShowComment>
                          </Box>
                        </Box>
                      </Stack>
                    </Grid>
                    {/* GE Details Right Grid */}
                    <Grid item xs={matches1458 ? 6 : 12}>
                      <Stack
                        spacing={2.5}
                        data-selenium-id='GEPage_GEDetailPage-FlagBlock-ShowBox-RightStack'
                        sx={{
                          paddingLeft: matches1458 ? '26px' : '0px',
                          marginTop: matches1458 ? '0px' : '40px',
                        }}
                      >
                        <Box
                          display='flex'
                          data-selenium-id='GEPage_GEDetailPage-FlagBlock-ShowBox-Right-EvidenceFile'
                        >
                          <Box
                            display='flex'
                            sx={{
                              width: '224px',
                              marginRight: '0px!important',
                              paddingRight: '0px!important',
                            }}
                          >
                            <Typography
                              sx={{ color: '#596A7C', fontSize: '14px' }}
                            >
                              {'Evidence:'}
                            </Typography>
                          </Box>
                          <Box display='flex' sx={{ width: '286px' }}>
                            <ShowFile
                              fileName={fileName[index]}
                              index={index}
                            ></ShowFile>
                          </Box>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
            );
          })}
        </CardContent>
      </Box>
    );

    return childFlag;
  };

  const [buttonDisable, setButtonDisable] = useState([]);
  const [buttonCheck, setButtonCheck] = useState(false);
  const [HasbuttonDisable, setHasButtonDisable] = useState(false);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    setHasError(false);
    for (let i = 0; i < FlagData?.length; i++) {
      if (
        (isSevere[i] && fileName[i]?.length === 0) ||
        comment[i]?.length > 10000
      )
        setHasError(true);
    }
    let iniDisable = [];
    for (let i = 0; i < FlagData?.length; i++) {
      if (isSevere[i] && fileName[i]?.length === 0 && buttonCheck) {
        iniDisable.push(true);
      } else {
        iniDisable.push(false);
      }
    }
    setButtonDisable(iniDisable);
    setHasButtonDisable(false);
    for (let i = 0; i < FlagData?.length; i++) {
      if (iniDisable[i] === true || comment[i]?.length > 10000) {
        setHasButtonDisable(true);
      }
    }
  }, [isSevere, fileChange, buttonCheck, comment, fileName]);
  useEffect(() => {
    if (buttonCheck) setButtonCheck(false);
  }, [isSevere, fileChange, comment.every(item => item.length <= 10000)]);
  const renderEditFlag = () => {
    const childFlag = [];
    if(FlagData==='error') return childFlag;
    childFlag.push(
      <CardContent
        sx={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: '28px!important',
          paddingBottom: '32px!important',
        }}
      >
        {FlagData.map((item, index) => {
          return (
            <Stack
              spacing={3}
              data-selenium-id='GEPage_GEDetailPage-FlagBlock-EditBox-Stack'
            >
              <Box data-selenium-id='GEPage_GEDetailPage-FlagBlock-EditBox-Left'>
                <Grid container spacing={1} justifyContent='flex-end'>
                  {/* GE Details Left Grid */}
                  <Grid item xs={6}>
                    <Stack
                      spacing={2.5}
                      data-selenium-id='GEPage_GEDetailPage-FlagBlock-EditBox-LeftStack'
                    >
                      <Box display='flex' alignItems='center'>
                        <Box display='flex' sx={{ width: '224px' }}>
                          <Typography
                            sx={{ color: '#596A7C', fontSize: '14px' }}
                            data-selenium-id='GEPage_GEDetailPage-FlagBlock-Edit-LeftBox-FlagTypography'
                          >
                            {'Flag\u00A0\u00A0'}
                          </Typography>
                          <Stack
                            sx={{ paddingTop: '4px' }}
                            data-selenium-id='GEPage_GEDetailPage-FlagBlock-Edit-LeftBox-FlagIcon'
                          >
                            <MandatoryIcon></MandatoryIcon>
                          </Stack>
                        </Box>
                        <DropdownSelect
                          onSelectChange={handleSelectChange}
                          index={index}
                          isClear={isClear}
                          setIsClear={setIsClear}
                          initialValue={flagName[index]}
                          FlagOptions={FlagOptions}
                          SevereOptions={SevereOptions}
                        ></DropdownSelect>
                      </Box>
                      <Box
                        display='flex'
                        alignItems='center'
                        data-selenium-id='GEPage_GEDetailPage-FlagBlock-Edit-LeftBox-CommentTypography'
                        sx={{ marginTop: '0px!important' }}
                      >
                        <Box
                          display='flex'
                          sx={{ width: '224px', paddingBottom: '38px' }}
                        >
                          <Typography
                            sx={{ color: '#596A7C', fontSize: '14px' }}
                          >
                            {'Comment'}
                          </Typography>
                        </Box>
                        <Box
                          display='flex'
                          sx={{ width: '224px', paddingTop: '75px' }}
                        >
                          <TextInputWithLimit
                            onTextChange={handleTextChange}
                            initialValue={comment[index]}
                            index={index}
                            isClear={isClear}
                            setIsClear={setIsClear}
                            sx={{ paddingTop: '10px' }}
                          />
                        </Box>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* GE Details Right Grid */}
                  <Grid item xs={6}>
                    <Stack
                      spacing={2.5}
                      sx={{ paddingLeft: '12px' }}
                      data-selenium-id='GEPage_GEDetailPage-FlagBlock-EditBox-RightStack'
                    >
                      <Box display='flex'>
                        <Box
                          display='flex'
                          sx={{ width: '224px' }}
                          data-selenium-id='GEPage_GEDetailPage-FlagBlock-Edit-RightBox-FileUpload'
                        >
                          <Typography
                            sx={{ color: '#596A7C', fontSize: '14px' }}
                          >
                            {'\u00A0\u00A0\u00A0\u00A0Evidence\u00A0\u00A0'}
                          </Typography>
                          {isSevere[index] ? (
                            <Stack sx={{ paddingTop: '4px' }}>
                              <MandatoryIcon></MandatoryIcon>
                            </Stack>
                          ) : (
                            <></>
                          )}
                        </Box>
                        <FileUpload
                        geId={geId}
                          onFileChange={handleFilesChange}
                          isSevere={isSevere}
                          fileProgress={fileProgress}
                          initialValue={fileName[index]}
                          Fileindex={index}
                          isClear={isClear}
                          setIsClear={setIsClear}
                          disable={buttonDisable[index]}
                        ></FileUpload>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          );
        })}
        <Box
          style={{
            width: 'auto',
            background: '#FFFFFF',
            bottom: 0,
            zIndex: '10',
            paddingTop: '16px',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          }}
          data-selenium-id='GEPage_GEDetailPage-FlagBlock-Edit-ButtonBox'
        >
          <Stack
            spacing={'10px'}
            direction='row'
            data-selenium-id='GEPage_GEDetailPage-FlagBlock-Edit-ButtonStack'
          >
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <SaveAndCancelButton
                id='GEPage_GEDetailPage-FlagBlock-Edit-TwoBtn'
                data-selenium-id='GEPage_GEDetailPage-FlagBlock-Edit-TwoBtn'
                handleCancel={handleCloseButtonClick}
                handleSubmit={() => {
                  setLoadingFlag(true);
                  async function fetchData() {
                    handleCheckButtonClick();
                    await delay(300);
                    setLoadingFlag(false);
                    if (!hasError) setIsEdit(false);
                  }
                  fetchData();
                }}
                isDisabled={HasbuttonDisable && buttonCheck}
                isSaving={loadingFlag}
              ></SaveAndCancelButton>
            </Box>
          </Stack>
        </Box>
      </CardContent>
    );
    return childFlag;
  };

  return (
    <Box
      data-selenium-id='GEPage_GEDetailPage-FalgBlock-Box'
      sx={{ width: 1092 }}
    >
      {isEdit ? (
        <Box data-selenium-id='GEPage_GEDetailPage-FlagBlock-EditBox'>
          {renderEditFlag()}
        </Box>
      ) : (
        <Box data-selenium-id='GEPage_GEDetailPage-FlagBlock-ShowBox'>
          {renderShowFlag()}
        </Box>
      )}
    </Box>
  );
});
function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export default FlagBlock;

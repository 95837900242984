import { useMemo } from 'react';
import Chart from '@/components/Chart/Chart';
import { ArticlesOAvsTotalOptions } from '../options';

const ArticlesChart = ({
  barchartData,
  width,
  hasZoom = false,
  hasTitle = false,
  title,
  subTitle,
  catagoryAttributeName,
  barSeriesValue,
  barSeriesColor,
}) => {
  const chartOption = useMemo(() => {
    return new ArticlesOAvsTotalOptions(
      catagoryAttributeName,
      barSeriesValue,
      barSeriesColor,
      width,
    );
  }, [catagoryAttributeName, barSeriesValue, barSeriesColor,width]);

  const options = useMemo(() => {
    chartOption
      .setZoom(hasZoom)
      .setTitle(hasTitle, title, subTitle)
      .setDataSource(barchartData)
      .setWidth(width);

    return chartOption.getOption();
  }, [hasZoom, hasTitle, title, subTitle, barchartData, width]);

  console.log('======options=====, ', options,hasTitle?'hasTitle':'noTitle');

  const chartHeight = useMemo(() => {
    return chartOption.getHeightWithTitle();
  }, [hasTitle, title, subTitle, barchartData]);
  // console.log("======chartHeight=====, ", chartHeight)

  const [minFontSize, maxFontSize] = useMemo(() => {
    return chartOption.getLabelFontSize();
  }, [hasTitle, title, subTitle, barchartData]);

  return (
    <Chart
      options={options}
      height={chartHeight}
      width={width}
      isZoomLabelFontSize={false}
      minFontSize={minFontSize}
      maxFontSize={maxFontSize}
    />
  );
};

export default ArticlesChart;

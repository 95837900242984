import {
  Autocomplete,
  Box,
  CircularProgress,
  FilledInput,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchIcon from '../../assets/search-icon.svg';
import { styled } from '@mui/system';

export default function MyTextField(props) {
  //limitLength:限制文本框输入长度
  //exSpecialChar：排除特殊字符
  //setValue：设置文本框的值(必须设置value值)
  //setSCErr:传递包含特殊字符的Err信息给父组件
  //checkEmail: 检查是否符合邮件格式
  //setEmailErr: 传递是否符合邮件格式的Err信息给父组件
  const {
    limitLength,
    exSpecialChar,
    setValue,
    setSCErr,
    setEmailErr,
    checkEmail,
    moreSpecialCheck,
  } = props;
  const [specialChar, setSpecialChar] = useState(false);
  const [isEmail, setIsEmail] = useState(true);

  //检测是否包含特殊字符
  const ifSpecialChar = text => {
    let pattern = new RegExp(
      "[`~!@#$^&*()=|{}':;,\\[\\].<>/?！￥…（）—【】‘；：”“。，、？]"
    );
    let pattern2 = new RegExp('\\\\');
    setSpecialChar(pattern.test(text) || pattern2.test(text));
    if (moreSpecialCheck !== undefined && moreSpecialCheck !== false) {
      setSpecialChar(
        pattern.test(text) || pattern2.test(text) || moreSpecialCheck.test(text)
      );
    }
    if (setSCErr !== undefined) {
      setSCErr(pattern.test(text) || pattern2.test(text));
      if (moreSpecialCheck !== undefined && moreSpecialCheck !== false) {
        setSCErr(
          pattern.test(text) ||
            pattern2.test(text) ||
            moreSpecialCheck.test(text)
        );
      }
    }
  };

  const checkIsEmail = text => {
    var pattern =
      /^\w+((-\w+)|(\.\w+)|(_\w+))*@[A-Za-z0-9]+(([.\-])[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    setIsEmail(pattern.test(text));
    setEmailErr(!pattern.test(text));
  };

  const helperTextSelector = () => {
    if (exSpecialChar && specialChar) {
      return 'Cannot contain special characters!';
    } else if (checkEmail && !isEmail) {
      return 'Please enter a valid email address!';
    } else if (props.error) {
      return props.helperText;
    } else {
      return null;
    }
  };

  return (
    <TextField
      {...props}
      onChange={e => {
        if (e.target.value.length <= limitLength) {
          setValue(e.target.value);
        }
      }}
      error={
        (exSpecialChar && specialChar) ||
        (checkEmail && !isEmail) ||
        props.error
      }
      helperText={helperTextSelector()}
      onBlur={
        props.onBlur
          ? props.onBlur
          : e => {
              if (exSpecialChar) {
                ifSpecialChar(e.target.value);
              } else if (checkEmail) {
                if (e.target.value.length > 0) {
                  checkIsEmail(e.target.value);
                }
              } else {
                return props.onBlur;
              }
            }
      }
    />
  );
}

//带有搜索图标的文本输入框
export function SearchTextField(props) {
  const { inputLabel, value, onChange } = props;

  return (
    <Box id='selectInfoName'>
      <SearchIcon id='SearchIcon' />
      <FormControl variant='filled' id='InputLable'>
        <InputLabel htmlFor='nameInput' id='NameTitle'>
          {inputLabel}
        </InputLabel>
        <FilledInput id='nameInput' value={value} onChange={onChange} />
      </FormControl>
    </Box>
  );
}

export const BootstrapTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    boxSizing: 'border-box',
    minHeight: '33px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#262E35',
    // padding: "0px",
    paddingLeft: '14px',
    paddingRight: '14px',
    '& fieldset': {
      border: '1px solid #DFE4E8',
    },
    '& input': {
      padding: 0,
    },
  },
}));

export function AsnycLoadingTextField(props) {
  const { getData, data, handleOptions, label, width } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [keyWord, setKeyWord] = useState('');
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await getData(keyWord);
    })();

    return () => {
      active = false;
    };
  }, [loading, keyWord]);

  useEffect(() => {
    if (data !== null) {
      setOptions(data);
    }
  }, [data]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      sx={{ width: width }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={handleOptions}
      loading={loading}
      filterOptions={x => x}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          onChange={e => setKeyWord(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

/**
 * @param {import("@/pages/CATJournal/Overview/CATTable/CATSearchRow/journal-filter-components/JournalFilterSearchResultDisplay").JournalFilterDelaySearchTextFieldProps} props
 * @returns
 */
export function DelaySearchTextField(props) {
  // handleSearch 延迟更新的值的方法
  // delay 延迟的毫秒数，不填则默认300ms
  // mark-zyq: 这里是不是应该把defaultValue拿出来到大括号里，避免将该参数传入到TextField中？
  const { handleSearch, value, delay = 300, defaultValue, ...other } = props;

  const [inputText, setInputText] = useState(
    /** @type {string} */ (defaultValue)
  );

  useEffect(() => {
    const timer = setTimeout(async () => {
      handleSearch(inputText);
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, [inputText]);

  useEffect(() => {
    setInputText(value);
  }, [value]);

  return (
    <TextField
      id='DelaySearchTextFieldInput'
      onChange={event => {
        if (inputText === '' && event.target.value === inputText) {
          return;
        }
        setInputText(event.target.value);
      }}
      value={inputText}
      {...other}
      sx={{
        // 这个style是指placeholder超长后显示"..."
        '[placeholder]': {
          textOverflow: 'ellipsis',
        },
        '& .MuiOutlinedInput-root': {
          paddingLeft: '8px',
        },
        ...other.sx,
      }}
    />
  );
}

export function NumberTextField(props) {
  //maxNumber:最大值(number)
  //minNumber:最小值(number)
  //exSpecialChar：排除特殊字符
  //setValue：设置文本框的值(必须设置value值)
  //setSCErr:传递包含特殊字符的Err信息给父组件
  const { maxNumber, minNumber, exSpecialChar, setValue, setSCErr } = props;
  const [specialChar, setSpecialChar] = useState(false);
  const [outRangeError, setOutRangeError] = useState(false);

  //检测是否包含特殊字符
  const ifSpecialChar = text => {
    let pattern = new RegExp('^-?[0-9]*$');
    setSpecialChar(!pattern.test(text));
    if (setSCErr) {
      setSCErr(pattern.test(text));
    }
    return pattern.test(text);
  };

  const helperTextSelector = () => {
    if (exSpecialChar && specialChar) {
      return 'Please input positive integer number!';
    } else if (outRangeError) {
      return `Number must between ${minNumber} and ${maxNumber}`;
    } else if (props.error) {
      return props.helperText;
    } else {
      return null;
    }
  };

  const handleTextChange = e => {
    // try {
    var n = e.target.value;
    if (n !== '' && !ifSpecialChar(n)) {
      e.target.value = 0;
    } else {
      n = Number(n);
      if (n === 0 && e.target.value.length > 1) {
        e.target.value = 0;
      } else if (n > 0 && e.target.value[0] === '0') {
        e.target.value = n;
      }
      if (n > maxNumber) {
        e.target.value = maxNumber;
        setOutRangeError(true);
      } else if (n < minNumber) {
        e.target.value = minNumber;
        setOutRangeError(true);
      } else {
        setOutRangeError(false);
      }
    }
    setValue(e.target.value);
    // } catch {
    //   console.log(e);
    // }
  };

  return (
    <TextField
      {...props}
      value={props.value}
      onChange={handleTextChange}
      error={(exSpecialChar && specialChar) || props.error}
      helperText={helperTextSelector()}
    />
  );
}

import React, { useState, useEffect } from 'react';

import { getNewParams, getDayList, weekList, checkDisabled } from './Utils';
import { Box, Stack, ThemeProvider, Button } from '@mui/material';
import { SmallFont, TinyFont } from '@/components/FontStyle';
import { themeButton } from '../CommonTheme';
import { AllCentered } from '../CommonStyle';

let keydownStatic = -1;
let activeStatic = -1;
let listStatic = [];

const DayPage = props => {
  const {
    id,
    year,
    month,
    active,
    change,
    visible,
    monthRef,
    show,
    limit,
    temYear,
    temMonth,
    maxDate,
    minDate,
  } = props;
  const [list, setList] = useState(
    getDayList(year, month, 0, limit, maxDate, minDate)
  );
  const [keydown, setKeydown] = useState(-1);

  const PlaceholderBox = () => {
    return (
      <Box
        sx={{
          width: '36px',
          height: '36px',
        }}
      />
    );
  };

  const IconButton = props => {
    const { toDay, selected, label, ...other } = props;
    return label === '' ? (
      <PlaceholderBox />
    ) : (
      <ThemeProvider theme={themeButton(selected ? '#154AB6' : '#AEC6F6')}>
        <Button
          sx={{
            display: 'flex',
            width: '36px',
            height: '36px',
            minWidth: '0px',
            borderRadius: '8px',
            padding: '0px',
            ...TinyFont,
            color: selected ? '#FFFFFF' : '#262E35',
            backgroundColor: selected ? '#154AB6' : '#FFFFFF',
            leadingTrim: 'both',
            textEdge: 'cap',
            border: toDay ? '1px solid #AEC6F6' : 'none',
          }}
          {...other}
        >
          {label}
        </Button>
      </ThemeProvider>
    );
  };
  const DisabledIconButton = props => {
    const { toDay, label, ...other } = props;
    return (
      <Box
        sx={{
          width:toDay ? '34px' :'36px',
          height:toDay ? '34px' : '36px',
          ...AllCentered,
          ...TinyFont,
          leadingTrim: 'both',
          textEdge: 'cap',
          color: '#BCC5CF',
          border: toDay ? '1px solid #AEC6F6' : 'none',
          borderRadius: '8px',
        }}
        {...other}
      >
        {label}
      </Box>
    );
  };
  function checkToDay(day) {
    let newDate = new Date();
    let toDay = newDate.getDate();
    let toYear = newDate.getFullYear();
    let toMonth = newDate.getMonth() + 1;
    return toYear === temYear && toMonth === temMonth && toDay === day;
  }
  function checkActiveDay(day) {
    return year === temYear && month === temMonth && active === day;
  }
  //render head
  function renderHead() {
    return weekList.map(item => {
      return (
        <Box
          id={`${id}_title`}
          data-selenium-id={`${id}_title`}
          sx={{
            width: '36px',
            height: '16px',
            ...TinyFont,
            textAlign: 'center',
            color: '#596A7C',
          }}
          key={item}
        >
          {item}
        </Box>
      );
    });
  }
  function activeChange(params, temMonth, temYear) {
    change(params, temMonth, temYear);
  }
  // render List
  function renderList() {
    return list.map((item, index) => {
      return item.disabled ? (
        <DisabledIconButton
          id={`${id}_content_disabled`}
          data-selenium-id={`${id}_content_disabled_${item.label}`}
          key={item.label === '' ? `placeholder_${index}` : item.label}
          label={item.label}
          toDay={checkToDay(item.value)}
        />
      ) : (
        <IconButton
          id={`${id}_content`}
          data-selenium-id={`${id}_content_${item.label}`}
          key={item.label === '' ? `placeholder_${index}` : item.label}
          toDay={checkToDay(item.value)}
          selected={checkActiveDay(item.value)}
          label={item.label}
          onClick={activeChange.bind(this, item, temMonth, temYear)}
        />
      );
    });
  }

  useEffect(() => {
    let newList = getDayList(temYear, temMonth, 0, limit, maxDate, minDate);
    setList(newList);
  }, [temYear, temMonth]);

  return visible ? (
    <Box
      id={`${id}_box`}
      data-selenium-id={`${id}_box`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      <Stack spacing={0} direction='row'>
        {renderHead()}
      </Stack>
      <Box
        id={`${id}_content_box`}
        data-selenium-id={`${id}_content_box`}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '0px',
          flexWrap: 'wrap',
        }}
      >
        {renderList()}
      </Box>
    </Box>
  ) : null;
};

export default DayPage;

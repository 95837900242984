import * as actions from '../actions/SeleteAction';
import { ADD_ALL_SELECTED } from "../actions/SeleteAction";
import { ADD_USER_SELECTED_BATCH } from "../actions/SeleteAction";
import { REMOVE_ALL_USER_SELECTED } from "../actions/SeleteAction";

const initState = {
  isAllSeleted: false,
  isAllUserSeleted: false,
  selectedList: [],
  selectedListInfo: {},
  selectedUserList: [],
  selectedUserListInfo: {},
};

export default function SeleteReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.ADD_SELECTED: {
      let newSelected;
      if (state.selectedList.indexOf(data.siCode) > -1) {
        newSelected = state.selectedList; // 保持不变
      } else {
        newSelected = [data.siCode, ...state.selectedList];
        if (state.selectedListInfo[data.siCode] === undefined) {
          state.selectedListInfo[data.siCode] = data;
        } // 新增加
      }
      return {
        ...state,
        selectedList: newSelected,
      };
    }

    case actions.ADD_ALL_SELECTED: {
      // let newSelected = new Set(state.selectedList);
      // for (let siCode of data){
      //   newSelected.add(siCode);
        // if (state.selectedListInfo[siCode] === undefined) {
        //   state.selectedListInfo[siCode] = {
        //     siCode
        //   };
        // }
      // }
      return {
        ...state,
        selectedList: [...data],
      };
    }
    case actions.REMOVE_SELECTED:
      // delete(state.selectedListInfo[data]);
      return {
        ...state,
        isAllSeleted: false,
        selectedList: state.selectedList.filter(item => {
          return item !== data;
        }),
      };
    case actions.REMOVE_ALL_SELECTED:
      return {
        ...state,
        isAllSeleted: false,
        selectedList: [],
      };
    case actions.REMOVE_SELECTED_INFO:
      delete state.selectedListInfo[data];
      return {
        ...state,
      };
    case actions.SELECT_ALL:
      return {
        ...state,
        isAllSeleted: true,
        // selectedList: [],
      };
    case actions.CANCEL_SELECT_ALL:
      return {
        ...state,
        isAllSeleted: false,
        selectedList: [],
      };
    case actions.SET_IS_ALL_SELECTED:
      return {
        ...state,
        isAllSeleted: data,
      };
    case actions.ADD_USER_SELECTED: {
      let newSelected;
      if (state.selectedUserList.indexOf(data.userId) > -1) {
        newSelected = state.selectedUserList; // 保持不变
      } else {
        newSelected = [data.userId, ...state.selectedUserList]; // 新增加
        if (state.selectedUserListInfo[data.userId] === undefined) {
          state.selectedUserListInfo[data.userId] = data;
        }
      }
      return {
        ...state,
        selectedUserList: newSelected,
      };
    }
    case actions.ADD_USER_SELECTED_BATCH:
      return {
        ...state,
        selectedUserList: data,
      };
    case actions.REMOVE_USER_SELECTED:
      // delete(state.selectedUserListInfo[data]);
      return {
        ...state,
        isAllUserSeleted: false,
        selectedUserList: state.selectedUserList.filter(item => {
          return item !== data;
        }),
      };
    case actions.REMOVE_ALL_USER_SELECTED:
      return {
        ...state,
        selectedUserList: [],
      };
    case actions.REMOVE_USER_SELECTED_INFO:
      delete state.selectedUserListInfo[data];
      return {
        ...state,
      };
    case actions.SELECT_ALL_USER:
      return {
        ...state,
        isAllUserSeleted: true,
        // selectedUserList: [],
      };
    case actions.CANCEL_SELECT_ALL_USER:
      return {
        ...state,
        isAllUserSeleted: false,
        selectedUserList: [],
        selectedUserListInfo: {},
      };
    case actions.SET_USER_IS_ALL_SELECTED:
      return {
        ...state,
        isAllUserSeleted: data,
      };
    default:
      return state;
  }
}

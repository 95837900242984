import { Autocomplete, Box, Checkbox, TextField, Tooltip } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import StyledTag from '../../components/StyledTag/StyledTag';

export const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-popupIndicatorOpen {
    transform: none; // 展开popup时不要翻转
  }
`;

export default function SelectBox(props) {
  const {
    data,
    labelName,
    placeholder,
    value: selectedList,
    setSelected,
    onLoad,
    useKey,
    loadMore,
    getId,
    getValue,
    ...other
  } = props;
  const [open, setOpen] = React.useState(false);
  const identifier = labelName.replace(' ', '-');
  const [inputText, setInputText] = useState('');
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(null);
  const [page, setPage] = useState(1);
  const [loadTrigger, setLoadTrigger] = useState(0);
  const [lastSize, setLastSize] = useState(0); // 用于判断是否还有更多的数据，这将决定是否继续调用loadMore
  // 逻辑上应该使用小于号，但是第二次进入这个页面的时候，
  // page会因为inputText变化而置1，但是lastSize却不变化，导致不能触发loadMore，
  // 这应该是不难解决的逻辑上的问题，但是没看出是哪里错了orz
  const hasMore = data !== undefined && lastSize !== data.length;
  const pageSize = 20;

  useEffect(() => {
    if (loadMore !== undefined) {
      // setLastSize(0);
      // add timeout
      setLoading(true);
      clearTimeout(timer);
      setTimer(
        setTimeout(async () => {
          setPage(1); // 输入内容变化时引发page变化，进而出发数据加载
          setLoadTrigger(loadTrigger + 1); // page 如果一直是1就无法触发加载，需要用这个触发
          // await loadMore(inputText);
          // setLoading(false);
        }, 300)
      );
    }
  }, [inputText]);

  useEffect(() => {
    if (loadTrigger === 0 || !open) {
      return;
    }
    setLastSize(data.length); // 记录之前的长度
    async function loadMoreOptions() {
      await loadMore(inputText, page, pageSize); // 加载完成后会更新data，进而更新长度，从而hasMore变化
    }

    loadMoreOptions().then(_r => {
      setLoading(false);
    });
  }, [open, page, loadTrigger]); // page 变化时触发load下一页，loadTrigger触发作为备用

  const handleInputTextChange = e => {
    setInputText(e.target.value);
  };

  const getOptionName = option => {
    return getValue ? getValue(option) : option;
  };
  const getOptionId = option => {
    return getId ? getId(option) : getOptionName(option);
  };

  return (
    <Box>
      <StyledAutocomplete
        disableCloseOnSelect
        disableListWrap
        disableClearable
        value={selectedList}
        filterOptions={x => x}
        ListboxProps={{
          onScroll: event => {
            const listboxNode = event.currentTarget;
            if (
              listboxNode.scrollTop + listboxNode.clientHeight >=
              listboxNode.scrollHeight
            ) {
              // 划到底的时候触发加载更多
              if (hasMore && loadMore !== undefined) {
                // 只有存在更多的时候才触发加载
                // 如果找到不会返回顶部的方法则取消注释，按照Mike的建议，当前已经取消所有 load more 操作
                // setPage(page + 1);   // 修改page以触发loadMore
              }
            }
          },
        }}
        size='small'
        id={'autocomplete-' + identifier}
        options={data ? data : []}
        openText='Search'
        loading={loading}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          setInputText('');
        }}
        getOptionLabel={option => getOptionName(option)}
        onChange={(_event, value) => {
          setSelected(value);
        }}
        isOptionEqualToValue={(option, value) =>
          getOptionId(option) === getOptionId(value)
        }
        renderOption={(optionProps, option, { selected }) => (
          <li
            {...optionProps}
            style={{ height: '36px', lineHeight: '16px', fontSize: '14px' }}
          >
            <Checkbox
              key={identifier + '-' + getOptionId(option)}
              icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
              checkedIcon={<CheckBoxIcon fontSize='small' />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <Tooltip
              title={getOptionName(option)}
              disableInteractive
              leaveTouchDelay={0}
              followCursor={true}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {getOptionName(option)}
              </span>
            </Tooltip>
          </li>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            return (
              <StyledTag
                key={identifier + '-' + index}
                label={getOptionName(option)}
                maxWidth='240px'
                {...getTagProps({ index })}
              />
            );
          })
        }
        renderInput={params => (
          <TextField
            {...params}
            onChange={e => {
              handleInputTextChange(e);
            }}
            placeholder={selectedList?.length ? null : placeholder}
            variant='outlined'
            InputProps={{
              ...params.InputProps,
            }}
          ></TextField>
        )}
        {...other}
      />
    </Box>
  );
}

import {axios_instance} from "@/utils/axios_instance";
import {GET_FLAGLIST, GET_GE_Gender_CONFIG, GET_GE_TITLE_CONFIG} from "@/actions/SIMT-SI/GuestEditor/GEAction";

export function getFlaglist() {
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/configs`;
            const res_Severe = await axios_instance.get(url, {
                params: {
                    type:'Flags-Severe Issue',
                    fuzzy_type:false,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const res_Mild = await axios_instance.get(url, {
                params: {
                    type:'Flags-Mild Issue',
                    fuzzy_type:false,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const flagData = [...res_Severe.data.data, ...res_Mild.data.data];
            if (res_Mild.data.code === 200 && res_Severe.data.code === 200) {
                dispatch({type: GET_FLAGLIST, data: flagData});
            } else {
                dispatch({type: GET_FLAGLIST, data: []});
            }
        } catch {
            dispatch({type: GET_FLAGLIST, data: 'error'});
        }
    };
}


export function getGETitleConfig() {
    return async dispatch => {
        try {
            let res = await axios_instance.get('/api/v1/user-service/configs', {
                params: {
                    type:"GE Title",
                    fuzzy_type:false
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const{code,data} = res.data;
            if (code === 200) {
                dispatch({
                    type: GET_GE_TITLE_CONFIG,
                    data: data,
                });
            } else {
                dispatch({
                    type: GET_GE_TITLE_CONFIG,
                    data: 'error',
                });
            }
        } catch (err) {
            dispatch({
                type: GET_GE_TITLE_CONFIG,
                data: 'error',
            });
        }
    };
}


export function getGEGenderConfig() {
    return async dispatch => {
        try {
            let res = await axios_instance.get('/api/v1/user-service/configs', {
                params: {
                    type:'GE Gender',
                    fuzzy_type:false,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const{code,data} = res.data;
            if (code === 200) {
                dispatch({
                    type: GET_GE_Gender_CONFIG,
                    data: data,
                });
            } else {
                dispatch({
                    type: GET_GE_Gender_CONFIG,
                    data: 'error',
                });
            }
        } catch (err) {
            dispatch({
                type: GET_GE_Gender_CONFIG,
                data: 'error',
            });
        }
    };
}

export const PERMISSIONS = {
    SUPER_ADMIN_WRITE: 'super_admin:write',
    ADMIN_WRITE: 'admin:write',
    SI_READ: 'si:read',
    SI_READ_LIMITED: 'si:readLimited',
    SI_WRITE: 'si:write',
    SI_EXPORT: 'si:export',
    CHINA_MARKETING_READ: 'china_marketing:read',
    CHINA_MARKETING_WRITE: 'china_marketing:write',
    GE_READ: 'ge:read',
    GE_WRITE: 'ge:write',
    GE_MERGE: 'ge:merge',
    GE_EXPORT: 'ge:export',
    SIP_READ: 'sip:read',
    SIP_WRITE_JPA: 'sip:write-jpa',
    SIP_WRITE_HANDLING_CE: 'sip:write-ce',
    SIP_WRITE_SCREENER: 'sip:write-screener',
    SIP_WRITE_OPS: 'sip:write-ops',
    REPORT_READ_GENERAL: 'report:read-general',
    REPORT_READ_CE: 'report:read-ce',
    REPORT_READ_TARGET: 'report:read-target',
    JOURNAL_CR_CONTACT: 'journal-CRContact',
    JOURNAL_CAT_READ: 'journal-catjournal:read',
    JOURNAL_CAT_WRITE: 'journal-catjournal:write',
    JOURNAL_CAT_BATCH_UPDATE: 'journal-catjournal:batch-update',
    CAT_REPORT_READ: 'catreport:read',
    OPT_IN: 'opt:in',
    OPT_OUT: 'opt:out',
    OPT_HISTORY: 'opt:history',
    OPT_APPROVAL: 'opt:approval',
    EMAIL_SI: 'email:si',
    EMAIL_SIP: 'email:sip'
} as const;

export type PermissionConstants = typeof PERMISSIONS[keyof typeof PERMISSIONS];

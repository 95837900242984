import { Box, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import MarketChevronDown from '@/assets/MarketChevronDown.svg';
import { useIntl } from 'react-intl';
import SIPEmailTemplateBlock from './SIPEmailTemplateBlock';

function AutomaticallySendingEmails(props) {
  const { AutoSentDetail, isSolicited=false } = props;

  const intl = useIntl(); // 为国际化提供的API支持
  const [isFold, setIsFold] = useState(true);
  //蓝色标题
  const BlueTitle = props => {
    return (
      <div
        style={{
          width: '100%',
          height: '24px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#113D95',
        }}
      >
        {props.children}
      </div>
    );
  };

  const deadlineSent = {
    XDayBeforeDeadline: {},
    XDayAfterDeadline: {},
    publishedIssue: {},
  };
  // 根据block判断将要赋值的是哪一个ShowAndEditForAuto
  for (const element of AutoSentDetail) {
    switch (element.block) {
      case 7:
        deadlineSent.XDayBeforeDeadline = element;
        break;
      case 8:
        deadlineSent.XDayAfterDeadline = element;
        break;
      case 9:
        deadlineSent.publishedIssue = element;
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Grid
        container
        style={{ gridGap: '16px', marginTop: '32px', paddingBottom: '32px' }}
        data-selenium-id='EmailTemplates-SIPEmailTemplates-AutomaticallySendingEmails-Grid'
      >
        <Grid
          item
          data-selenium-id='EmailTemplates-SIPEmailTemplates-AutomaticallySendingEmails-Grid-Grid1'
        >
          {isFold ? (
            <Box onClick={() => setIsFold(false)} sx={{ cursor: 'pointer' }}>
              <MarketChevronUp />
            </Box>
          ) : (
            <Box
              onClick={() => setIsFold(true)}
              id='MarketFoldDown'
              sx={{ cursor: 'pointer' }}
              data-selenium-id='MarketFoldDown'
            >
              <MarketChevronDown />
            </Box>
          )}
        </Grid>
        <Grid
          item
          md={11}
          sm={8}
          xs={5}
          data-selenium-id='EmailTemplates-SIPEmailTemplates-AutomaticallySendingEmails-Grid-Grid2'
        >
          <Stack
            direction='column'
            spacing={1}
            data-selenium-id='EmailTemplates-SIPEmailTemplates-AutomaticallySendingEmails-Grid-Grid2-Stack'
          >
            <Box data-selenium-id='EmailTemplates-SIPEmailTemplates-AutomaticallySendingEmails-Grid-Grid2-Stack-Box'>
              <BlueTitle>
                {intl.messages['email.AutomaticallySendingEmails']}
              </BlueTitle>
            </Box>
            {isFold ? (
              <>
                {AutoSentDetail === [] ? (
                  <></>
                ) : (
                  AutoSentDetail.map((item, index) => (
                    <SIPEmailTemplateBlock
                      key={`${item.block}+${item.name}`}
                      partTitle={item.name}
                      block={item.block}
                      contentDetail={item}
                      type='Auto'
                      nextKey={`${item.block}+${item.name}`}
                      isSolicited={isSolicited}
                    ></SIPEmailTemplateBlock>
                  ))
                )}
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
export default AutomaticallySendingEmails;

import { Box, Checkbox, ListItem, Stack } from '@mui/material';
import {
  CheckedBoxSmallIcon,
  UncheckedBoxSmallIcon,
} from './CheckBoxSmallIcon';
import { CustomTooltip } from '../../../../../../components/CustomTooltip';
import PropTypes from 'prop-types';
import React from 'react';
import SimpleCheckBox from '@/componentsphase2/Input/SimpleCheckBox';

// 按照figma实现的list item
/**
 *
 * @param {import("./JournalFilterListItem").JournalFilterListItemProps} props
 * @returns
 */
function JournalFilterListItem(props) {
  const { id, content, handleClick, checked } = props;

  return (
    <ListItem
      sx={{
        height: '24px',
        width: '245px',
        color: '#484848',
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        padding: '2px 0 2px 3px',
        fontFamily: 'Open Sans',
      }}
      data-selenium-id='CATJournal_Overview-Table-SearchRow-filterComponents-ListItem-ListItem'
    >
      <Stack 
        direction='row' 
        alignItems='center' 
        justifyContent='flex-start' 
        spacing='15px'
        width='100%'
        >
        <SimpleCheckBox
          id={`journalCheckbox${id}`}
          data-selenium-id={`CATJournal_Overview-JournalCheckbox${id}`}
          onClick={handleClick}
          checked={checked}
          disableRipple
          sx={{
            padding: 0,
          }}
        />
        {content?.length > 24 ? ( // UI 设计中要求的是32，但实际测试发现20左右就会显示不下
          <CustomTooltip
            title={content}
            placement='top'
            disableInteractive
            data-selenium-id='CATJournal_Overview-Table-SearchRow-filterComponents-ListItem-CustomTooltip'
          >
            <Box
              sx={{
                p: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              data-selenium-id='CATJournal_Overview-Table-SearchRow-filterComponents-ListItem-OverLengthBox'
            >
              {content}
            </Box>
          </CustomTooltip>
        ) : (
          <Box
            sx={{
              p: 0,
            }}
            data-selenium-id='CATJournal_Overview-Table-SearchRow-filterComponents-ListItem-NoOverLengthBox'
          >
            {content}
          </Box>
        )}
      </Stack>
    </ListItem>
  );
}

JournalFilterListItem.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default JournalFilterListItem;

import { type HTMLAttributes, StrictMode, useEffect, useRef } from 'react';
import FlaggedGESynchronizationAndVerificationTable, { radioGropListArray, type GeDataType, radioGropList, FlaggedGESynchronizationAndVerificationTable as GETableString, email as emailString, geCode as geCodeString, verification, clickCancelButtonString } from '@/componentsphase2/FlaggedGESynchronizationAndVerificationTable';
import { useDispatch, useSelector } from 'react-redux';

import {getGeByFullname} from "@/actions/SIMT-SI/GuestEditor";
import { bool, string, shape, object } from 'prop-types';
const { freeze } = Object;
type Type = 'error' | 'default' | 'checked';
export const compareToStatus = (a: Type, b: Type) => {
  if (a === 'error' || b === 'error') {
    return 'error';
  }
  if (a === 'checked' && b === 'checked') {
    return 'checked';
  }
  return 'default';
};
const geList = 'geList';
type ReadonlyArrayGeDataType = ReadonlyArray<GeDataType>;
export const geCodeOrNull = (arr: radioGropListArray, index: number, FlaggedGETable: ReadonlyArray<GeDataType>) => {
  const ind = arr?.indexOf(radioGropList.Matched);
  if (typeof ind !== 'number' || ind === -1) {
    return null;
  }
  return FlaggedGETable?.[index]?.[ind]?.geCode;
};
export const getNewSIPChecklistDetail = ({ SIPChecklistDetail, FlaggedGETable }: {
  readonly SIPChecklistDetail: {
    readonly [geList]: ReadonlyArray<{
      "flags":any;
      readonly [GETableString]: radioGropListArray;
      readonly [geCodeString]: string | null;
    }>;
  };
  readonly FlaggedGETable: ReadonlyArrayGeDataType;
}) => ({
  ...SIPChecklistDetail,
  [geList]: SIPChecklistDetail?.[geList]?.map((item, index) =>
  ({
    ...item,
    ["flags"]:(() => {
      let flag=[];
      for(let i=0;i<item.flags?.length;i++){
        if(item.flags[i].flagId!==undefined) flag.push(item.flags[i]);
      }
      return flag;
    })(),
    // [GETableString]: null,
    [geCodeString]: (() => {
      const ind = item?.[GETableString]?.indexOf(radioGropList.Matched);
      if (typeof ind !== 'number' || ind === -1) {
        return null;
      }
      return FlaggedGETable?.[index]?.[ind]?.geCode;
    })(),
  })
  ),
});
interface FlaggedGESynchronizationAndVerificationProps extends HTMLAttributes<HTMLDivElement> {
  readonly value: {
    readonly fullname: string;
    readonly email: string | null;
    readonly geCode: string;
    readonly index: number;
  };
  readonly editing: boolean;
  readonly processlogValue?: GeDataType;
}
export default function FlaggedGESynchronizationAndVerification ({ value = { fullname: '', email: null, geCode: '', index: 0 }, editing = false, processlogValue, ...others }: FlaggedGESynchronizationAndVerificationProps) {
  const pathInProcessLog = location.pathname.includes('/simt/auth/sipRelated/sip/detail/S');
  const isInProcessLog = pathInProcessLog && Boolean(processlogValue);
  const dispatch = useDispatch();
  const { fullname, email, index, geCode } = value;
  const FlaggedGETable = useSelector((state: {
    readonly GE: {
      readonly [GETableString]: ReadonlyArrayGeDataType;
    };
  }) => state?.GE?.[GETableString]?.[index]);
  const { verificationArr, clickCancelButton, } = useSelector((state: {
    readonly SIPChecklist: {
      readonly [clickCancelButtonString]: number;
      readonly SIPChecklistDetail: {
        readonly [geList]: ReadonlyArray<{
          readonly [GETableString]: radioGropListArray;
        }>;
      };
      readonly FlaggedGETable: ReadonlyArrayGeDataType;
    };
  }) => ({ verificationArr: state?.SIPChecklist?.SIPChecklistDetail?.[geList]?.[index]?.[GETableString], clickCancelButton: state?.SIPChecklist?.[clickCancelButtonString], }));
  const nowData = processlogValue ?? FlaggedGETable;
  const nowDataLength = nowData?.length;
  const nowData0 = nowData?.[0];
  const isEditing = editing && (nowDataLength > 1 || !((email === nowData0?.[emailString]?.toString())));
  const ref = useRef<HTMLDivElement>();
  useEffect(() => {
    setTimeout(() => {
      const GEChecklistFolder = ref?.current?.parentNode?.parentNode?.parentNode?.parentNode;
      const NewFlagAdditiontoGE = GEChecklistFolder?.children[GEChecklistFolder?.childElementCount - 2];
      const id = NewFlagAdditiontoGE?.children[0]?.id;
      if (!id) return;
      if (/^ge_\d+-checklist-newFlagAddition-itemBlock$/.test(id))
        NewFlagAdditiontoGE?.setAttribute('style', 'order:1');
    });
  }, []);
  return <StrictMode>
    <FlaggedGESynchronizationAndVerificationTable
      ref={ref}
      geDataResult={nowData?.map((item, ind) => ({
        ...item,
        // [verification]: ((geCode === item?.[geCodeString]?.toString()) || (nowDataLength === 1)) ? radioGropList.Matched : ((!isEditing || geCode) ? radioGropList.Mismatched : undefined)
        [verification]: (!pathInProcessLog && Array.isArray(verificationArr) && verificationArr.length === nowData.length && (Date.now() - clickCancelButton > 3e3)) ? verificationArr?.[ind] : (((geCode === item?.[geCodeString]?.toString()) || (email === item?.[emailString]?.toString())) ?  //CT-6633
          radioGropList.Matched :
          (geCode ? radioGropList.Mismatched : undefined))
      }))}
      {...(!isInProcessLog && {
        fetchData: async () => {
        dispatch(
          getGeByFullname(
            fullname, email, index, true
          )
        );
      }}
      )}
      editing={isEditing}
      tableMaxWidth='calc(100vw - 242px)'
      {...others}
    />
  </StrictMode>;
}
FlaggedGESynchronizationAndVerification.propTypes = freeze({
  value: shape({
    fullname: string,
    email: string,
    geCode: string,
    index: string,
  }),
  editing: bool,
  processlogValue: object || undefined,
});
FlaggedGESynchronizationAndVerification.defaultProps = freeze({
  value: {
    fullname: '',
    email: null,
    geCode: '',
    index: 0,
  },
  editing: false,
  processlogValue: undefined,
});

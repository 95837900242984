// import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { emailVariables } from '../../../modules/RichTextEditor/EditorUtils';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ReactDOMServer from 'react-dom/server';
import EmailQuestionMark from '../../../assets/EmailQuestionMark.svg';
import { useCustomEditor } from '../../../componentsphase2/RichText/EditorContext';

export default function CustomCKEditor(props) {
  // default variables will be set to common
  const {
    content, // 默认的输入内容，需要是一段html代码
    setContent, // 设置content的函数
    variables = emailVariables.common, // 变量（输入#之后的提示框）
    ...other // 添加给CKEditor外部的Box
  } = props;

  // 保存editor以供使用
  const [currEditor, setEditor] = useState(null);

  const [useContent, setUseContent] = useState(content);
  const [isInit, setIsInit] = useState(false);

  const Editor = useCustomEditor();

  // 当editor变化时打印
  useEffect(() => {}, [currEditor]);

  useEffect(() => {
    let tmpContent = useContent.replace(/<\/span>\s,/g, '</span>,');
    tmpContent = tmpContent.replace(/<\/span>\s\./g, '</span>.');
    tmpContent = tmpContent.replace(
      new RegExp('</span> &nbsp;', 'g'),
      '</span> '
    );
    tmpContent = tmpContent.replace(/<\/span>\s"/g, '</span>"');
    tmpContent = tmpContent.replace(/<\/span>\s]/g, '</span>]');
    console.info(tmpContent);
    setContent(tmpContent);
  }, [useContent]);

  useEffect(() => {
    if (!isInit && content) {
      setUseContent(content);
      setIsInit(true);
    }
  }, [content]);

  // CKEditor的配置属性
  const editorConfiguration = (defaultConfig) => ({
    ...defaultConfig,
    toolbar: {
      items: [],
    },
    placeholderConfig: {
      types: variables,
    },
    mention: {
      dropdownLimit: 100,
      feeds: [
        {
          marker: '#',
          feed: variables,
          itemRenderer: customItemRenderer, // 有可能要用到自定义的renderer（输入#之后的弹出框样式）
        },
      ],
    },
  });

  function customItemRenderer(item) {
    const outerButton = document.createElement('button');
    outerButton.classList.add(['ck', 'ck-button', 'ck-button_with-text']);

    const itemElement = document.createElement('span');
    itemElement.classList.add('ck');
    itemElement.classList.add('ck-button__label');
    itemElement.setAttribute('style', 'width: 100%;');
    itemElement.id = `mention-list-item-id-${item.id
      ?.replace('#', '')
      .replace(/\s/g, '')}`;
    itemElement.textContent = `${item.text} `;

    // if (item.id === '#AME first name') {
    //   const div = document.createElement('div');
    //   div.innerHTML = ReactDOMServer.renderToString(
    //     <span
    //       id='AMETip'
    //       data-selenium-id='EmailTemplates-CommonModule-CustomItem-AMETip'
    //       title='If there is no AME, "EEO Support will be displayed here.'
    //       style={{
    //         ml: '20px !important',
    //       }}
    //     >
    //       <EmailQuestionMark />
    //     </span>
    //   );
    //   itemElement.appendChild(div.firstChild);
    // }
    outerButton.appendChild(itemElement);
    return outerButton;
  }

  if (!Editor) {
    return <div>Loading Editor...</div>;
  }

  return (
    <Box
      id='richTextEditorOuterBox'
      data-selenium-id='EmailTemplates-CommonModule-RichTextEditorOuterBox'
      display='flex'
      {...other}
      className='emailSubjectCKEditor'
      sx={{
        '& .ck-content.ck-editor__editable.ck-editor__editable_inline': {
          paddingTop: 0,
          paddingBottom: 0,
          display: 'flex',
          alignItems: 'center',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '& p': {
            whiteSpace: 'nowrap',
          },
        },
      }}
    >
      <CKEditor
        data-selenium-id='EmailTemplates-CommonModule-RichTextEditorOuterBox-CkEditor'
        editor={Editor}
        config={editorConfiguration(Editor.defaultConfig)}
        data={useContent}
        onReady={editor => {
          // You can store the "editor" and use when it is needed.
          setEditor(editor); // 保存editor的方式。以备未来使用
        }}
        onChange={(_event, editor) => {
          const data = editor.getData();
          setUseContent(data); // will use later
        }}
        onBlur={(_event, editor) => {}}
        onFocus={(_event, editor) => {}}
      />
    </Box>
  );
}

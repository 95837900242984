/**
 * This file includes basic eChart options for barchart diagram type
 * The 1st part has some common constants
 * The 2nd defined an Options class
 */

import { BasicLayoutChartOptions } from './BasicLayoutChartOptions';

/**
 * title & legend
 */
const TITLE_HEIGHT = 70;
const TITLE_TOP_PADDING = 30;

/**
 * bar chart
 */
const BARCHART_HEIGHT = 412;
const BARCHART_TOP = 36;

const GAP_BETWEEN_CHART_AND_LEGEND = 12;
/**
 * legend height change
 */
const LEGEND_HEIGHT = 40;
const LEGEND_MARGIN_BOTTOM = 36;
const LEGEND_GAP = 12;
/**
 * zoom controller
 */
const ZOOM_CONTROL_PADDING_RIGHT = 14;
const ZOOM_CONTROL_MARGIN_RIGHT = 26;

/**
 * dataZoom
 */
const DATA_ZOOM_INSIDE = {
  type: 'inside',
  yAxisIndex: 0,
  start: 0,
  end: 100,
  filterMode: 'none',
};

const DATA_ZOOM_SLIDER = {
  type: 'slider',
  yAxisIndex: 0,
  // right
  show: true,
  showDetail: true,
  showDataShadow: false,
  fillerColor: '#4C81EB',
  backgroundColor: '#D9D9D9B3',
  borderRadius: 15,
  width: 4,
  moveHandleSize: 0,
  handleIcon: 'circle',
  handleSize: 12,
  handleStyle: {
    borderColor: '#FFFFFF',
    borderWidth: 3,
    color: '#4C81EB',
  },
  textStyle: {
    fontSize: 8,
    color: '#113D95',
    backgroundColor: '#F1F3F5',
    padding: 5,
    height: 18,
    lineHeight: 18,
    borderRadius: 3,
  },
  filterMode: 'empty',
};

const LINE_STYLE = {
  Color: '#DCDCDC',
  Type: 'solid',
  width: 0.4,
  opacity: 1,
};

const X_AXIS_LABLE_STYLE = {
  color: '#6F6F6F',
  fontStyle: 'normal',
  fontWeight: 400,
  fontFamily: 'Open Sans',
  fontSize: 10,
  lineHeight: 10,
};

const Y_AXIS_LABLE_STYLE = {
  color: '#6F6F6F',
  fontStyle: 'normal',
  fontWeight: 400,
  fontFamily: 'Open Sans',
  fontSize: 10,
  lineHeight: 8.5,
};


const Y_AXIS = {
  type: 'value',
  gridIndex: 0,
  nameTextStyle: {
    fontSize: '50px',
    align: 'left',
  },
  splitNumber: 10,
  minInterval: 1,
  axisLabel: {
    show: true,
    interval: 'auto',
    inside: false,
    rotate: 0,
    margin: 12,
    showMinLabel: null,
    showMaxLabel: null,
    formatter: function (value, index) {
      return value;
    },
    textStyle: Y_AXIS_LABLE_STYLE,
  },
  splitLine: {
    show: true,
    interval: 'auto',
    lineStyle: {
      Color: '#DCDCDC',
      Type: 'solid',
      width: 0.4,
      opacity: 1,
    },
  },
};

const BAR_SERIE_LABEL_STYLE = {
  barMinHeight: 3,
  datasetIndex: 0,
  type: 'bar',
  barGap: 0,
  // stack: "total",
  label: {
    show: true,
  },
  emphasis: {
    disabled: true,
  },
  itemStyle: {
    normal: {
      label: {
        show: true,
        position: 'inside',
        textStyle: {
          color: '#FFFFFF',
          fontSize: 5,
        },
      },
    },
  },
};
// settings
const LEGEND_STYLE = {
  icon: 'circle',
  itemGap: LEGEND_GAP,
  width: '750px',
  selectedMode: false,
  inactiveColor: '#BCC5CF',
  inactiveBorderColor: '#BCC5CF',
  inactiveBorderWidth: 1,
  padding: 0, //default 5, change to 0
  textStyle: {
    fontFamily: 'Open Sans',
    fontSize: '10px',
    fontWeight: '400',
    color: '#596A7C',
  },
  bottom: LEGEND_MARGIN_BOTTOM,
};

const TOOLTIP_STYLE = {
  showContent: true,
  trigger: 'item',
  padding: [6, 8, 6, 8], // top, right, bottom, left
  position: function (point, params, dom, rect, size) {
    const boxWidth = size.contentSize[0];
    const boxHeight = size.contentSize[1];

    const x = rect.x + 0.5 * rect.width - 0.5 * boxWidth;
    const y = rect.y - boxHeight - 4; // 4px above the item

    return [x, y];
  },
  // alwaysShowContent: true,
  formatter: params => {
    // console.log(params)
    // const stageName = params.dimensionNames[params.seriesIndex + 1]
    let dataStr = `<div style="padding: 0; margin: 0;">
    <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 0 0 2.5px 0;">${
      params.name
    }</p>
    <p style="font-size: 10px; font-weight: 400;color: #848484; line-height: 14px; margin: 0;">${
      params.seriesName
    }&nbsp;:&nbsp;${params.data[params.seriesName]}</p>
    </div>`;
    // let dataStr = `${params.name}`
    // let dataStr = `${params.seriesName}`
    // let dataStr = `${params.data[params.seriesName]}`
    return dataStr;
  },
};

const TITLE = {
  textStyle: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 700,
    color: '#113D95',
    lineHeight: 17.5,
  },
  subtextStyle: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 400,
    color: '#113D95',
    lineHeight: 17.5,
  },
  left: 'center',
};

const GRID = {
  containLabel: true,
};

export class StackedVerticalBarChartOptions extends BasicLayoutChartOptions {
  constructor() {
    super();
    this.hasPie = true;
    this.titleHeight = TITLE_HEIGHT;
    this.titleTopPadding = TITLE_TOP_PADDING;
    this.barchartTop = BARCHART_TOP;
    this.barChartHeight = BARCHART_HEIGHT;
    this.gapBetweenChartAndLegend = GAP_BETWEEN_CHART_AND_LEGEND;
    this.dataZoomInside = Object.assign({}, DATA_ZOOM_INSIDE);
    this.dataZoomSlider = Object.assign({}, DATA_ZOOM_SLIDER);
    this.zoomControlMarginRight = ZOOM_CONTROL_MARGIN_RIGHT;
    this.zoomControlPaddingRight = ZOOM_CONTROL_PADDING_RIGHT;
    this.barSerieOption = Object.assign({}, BAR_SERIE_LABEL_STYLE);
    this.tooltip = Object.assign({}, TOOLTIP_STYLE);
    this.hasTitle = false;
    this.title = '';
    this.subTitle = '';
    this.hasZoom = false;
    this.selectedLegend = [];
    this.dataForBar = null;
    this.width = 0;
  }

  getOption() {
    return {
      grid: this.getGrid(),
      legend: this.getSelectedLegend(),
      xAxis: this.getXAxis(),
      yAxis: this.getYAxis(),
      tooltip: this.getTooltip(),
      series: this.getSeries(),
      title: this.getByTitle(),
      dataZoom: this.getZoom(),
      dataset: this.getDataset(),
    };
  }

  getYAxis(){
    return Y_AXIS;
  }

  getHeightWithTitle() {
    let totalHeight =
      (this.hasTitle && this.titleHeight) +
      this.barchartTop +
      this.getBarchartHeight() +
      this.gapBetweenChartAndLegend +
      LEGEND_HEIGHT +
      LEGEND_MARGIN_BOTTOM;
    return totalHeight;
  }

  getBarchartHeight() {
    return this.barChartHeight;
  }

  getGrid() {
    const grid = {
      ...GRID,
      left: this.chartPaddingLeft,
      right: this.chartPaddingRight,
      top: !this.hasTitle
        ? this.barchartTop
        : this.barchartTop + this.titleHeight,
      height: this.getBarchartHeight(),
    };
    return grid;
  }

  getByTitle() {
    if (this.hasTitle) {
      return {
        ...TITLE,
        top: this.titleTopPadding,
        text: this.title,
        subtext: '(' + this.subTitle + ')',
      };
    }
    return undefined;
  }

  setHasPie(hasPie) {
    this.hasPie = hasPie;
    return this;
  }
  // set Title if hasTitle
  setTitle(hasTitle, text, subtext) {
    this.hasTitle = hasTitle;
    this.title = text;
    this.subTitle = subtext;
    return this;
  }

  /**
   * add a new method to get dataZoomInside
   */
  getDataZoomInside() {
    const dataZoomInside = Object.assign({}, DATA_ZOOM_INSIDE);
    return dataZoomInside;
  }

  getZoom() {
    if (this.hasZoom) {
      let slider = {
        ...this.dataZoomSlider,
        right: this.zoomControlPaddingRight + this.zoomControlMarginRight,
      };
      return [this.getDataZoomInside(), slider];
    }
    return undefined;
  }

  setZoom(hasZoom) {
    this.hasZoom = hasZoom;
    return this;
  }

  getSelectedLegend() {
    let legendMarginTop =
      (this.hasTitle && this.titleHeight) +
      this.barchartTop +
      this.getBarchartHeight() +
      this.gapBetweenChartAndLegend;
    return {
      ...LEGEND_STYLE,
      top: legendMarginTop,
      selected: this.selectedLegend,
    };
  }

  setSelectedLegend(selectedLegend) {
    this.selectedLegend = selectedLegend;
    return this;
  }

  getDataset() {
    return [
      {
        source: this.dataForBar,
      },
    ];
  }

  setDataSource(dataForBar) {
    this.dataForBar = dataForBar;
    return this;
  }

  setWidth(width) {
    this.width = width;
    return this;
  }

  // create series of bar charts
  getSeries() {
    const barSeries = this.getBarSeries();

    return [...barSeries];
  }

  getBarSeries() {
    return [];
  }
  getTooltip() {
    return [];
  }

  getXAxis() {
    return {
      type: 'category',
      axisTick: {
        show: false,
      },
      axisLabel: this.getXAxisLabel(),
      axisLine: {
        show: true,
        onZero: true,
        lineStyle: {
          color: '#979797',
          width: 0.4,
        },
      },
    };
  }

  getXAxisLabel() {
    return {
      show: true,
      interval: 0,
      inside: false,
      rotate: 0,
      margin: 22, //gap between axis and baseline
      formatter: function (value, index) {
        return value;
      },
      showMinLabel: true,
      showMaxLabel: true,
      textStyle: X_AXIS_LABLE_STYLE,
      overflow: 'truncate',
      verticalAlign: 'middle',
    };
  }
}

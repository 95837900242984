import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Dialog,
  Stack,
  tooltipClasses,
} from '@mui/material';
import React,{ useEffect, useState,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  StyledTableCell,
  TableFooter2,
} from '@/components/DataTable';
import {
  handleChangeCRCPageSize,
  handleCRCPageChangeAction,
  selectBatchCRC,
  setCRCOrderBy,
  unselectBatchCRC,
  unselectBatchCRCInfo,
} from '@/actions/SIMT-SI/CRContact/CRContactAction';
import { visuallyHidden } from '@mui/utils';
import { unstable_batchedUpdates } from 'react-dom';
import ScrollBarY from '../../../../componentsphase2/ScrollBar/ScrollBarY';
import { contextHeightChanged } from '@/actions/CommonAction';
import { StateButton } from '@/componentsphase2/StateButton';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import EditIconButton from '@/componentsphase2/Button/EditIconButton';
import { MulLineTooltipIfShow } from "@/componentsphase2/CustomTooltip";
import {editEOContact, loadCRData} from "@/actions/SIMT-SI/CRContact/Journal";

function createRow(
  id,
  label,
  isOrder = true,
  minWidth = '110px',
  maxWidth = '220px',
  show = true,
  widthFixed = true,
) {
  return { id, label, isOrder, minWidth, maxWidth, show, widthFixed };
}

const headItems = [
  createRow('journalCode', 'JOURNAL CODE', true, '108px', '108px', true),
  createRow('journalName', 'JOURNAL NAME', true, '260px', '260px', true),
  createRow(
    'editorialContact',
    'EDITORIAL OFFICE CONTACT',
    true,
    '366px',
    '366px',
    true
  ),
  createRow(
    'prpp',
    'Peer Review Performance Lead',
    true,
    '274px',
    '274px',
    true
  ),
  createRow('prpm', 'Peer Review Performance Manager', true, '306px', '306px', true),
];
const EditModal = props => {
  const {
    open,
    handleClose,
    journalCode,
    journalName,
    defaultValue,
    loadData,
  } = props;
  // console.log(props);
  const [contact, setContact] = useState('');
  useEffect(() => {

    if(open){
      if(defaultValue===null) setContact('');
      else setContact(defaultValue);
    }
    setEmailFormatErr(false);
    setIsClick(false);
  }, [defaultValue,open]);
  const dispatch = useDispatch();
  const emailPattern = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/;
  const [emailFormatErr, setEmailFormatErr] = useState(false);
  const [buttonDisable,setButtonDisable]=useState(false);
  const [isClick,setIsClick]=useState(false);
  const handleSave = async () => {
    setEmailFormatErr(!(emailPattern.test(contact)||contact===''||contact===null));
    setIsClick(true);
    if(emailPattern.test(contact)||contact===''||contact===null){
      await dispatch(editEOContact(journalCode, contact, null, journalName));
      await loadData();
      if(emailPattern.test(contact)||contact===''||contact===null) handleClose();
    }
  };
  useEffect(()=>{
    if(isClick){
      setIsClick(false);
    }

  },[contact]);

  return (
    <Dialog
      PaperProps={{
        style: {
          boxShadow: 'none',
        },
      }}
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
      open={open}
      // onClose={handleClose}
      sx={{
        background: 'rgba(17, 61, 149, 0.5)',
        backdropFilter: 'blur(4px)',
        '& .MuiDialog-paper': {
          width: '600px',
          minHeight: '174px',
          pt: '24px',
          px: '32px',
          m: 0,
        },
      }}
    >
      <Stack spacing={'16px'}>
        <Box
          sx={{
            color: '#262e35',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '125%',
            flexGrow: 1,
          }}
        >
          {`${journalName}(${journalCode})`}
        </Box>
        <Stack spacing={'0px'}>
          <div style={{height:'45px'}}>
            <Box sx={{ display: 'flex',  gap: '24px' }}>
              <Box
                sx={{
                  paddingTop:'7.5px',
                  color: '#596a7c',
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontWeight: 400,
                  letterSpacing: '0.14px',
                }}
              >
                Editorial Office Contact:
              </Box>
              <MyOnlyText
                width='286px'
                height='33px'
                multiline={false}
                value={contact}
                setValue={setContact}
                isError={!(emailPattern.test(contact)||contact===''||contact===null)}
                errorMessage={'email format error'}
              />
            </Box>
          </div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '9px',
              height: '66px',
            }}
          >
            <StateButton
              titleName='Cancel'
              isPrimary={false}
              onClick={()=>{
                handleClose();
                setEmailFormatErr(false);
                setContact(defaultValue);}}
              sx={{width:'70px',height:'34px'}}
            />
            <StateButton
              titleName='Save'
              onClick={()=>{

                handleSave();
              }}
              disabled={emailFormatErr && isClick}
              sx={{width:'70px',height:'34px'}}
            />
          </Box>
        </Stack>
      </Stack>
    </Dialog>
  );
};
export default function CRTable(props) {
  const { searchBy } = props;

  const [isDisplayedAllSelected, setIsAllSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  const selector = state => {
    return {
      CRCData: state.CRContact.CRCData,
      orderByType: state.CRContact.orderByType,
      selectedCRC: state.CRContact.selectedCRC,
      page: state.CRContact.page,
      pageSize: state.CRContact.pageSize,
      searchCRContent: state.CRContact.searchCRContent,
      reloadAllSelectionTrigger: state.CRContact.reloadAllSelectionTrigger,
      siderOpen: state.UserManage.siderOpen,
    };
  };

  const {
    CRCData,
    orderByType,
    selectedCRC,
    page,
    pageSize,
    searchCRContent,
    reloadAllSelectionTrigger,
    siderOpen,
  } = useSelector(selector);

  const dispatch = useDispatch();
  const [editEOContact,setEditEOContact]=useState(false);
  useEffect(() => {
    // setLoading(true);
    async function loadData() {
      dispatch(
        loadCRData(
          page,
          pageSize,
          orderByType.order,
          orderByType.orderBy,
          searchBy,
          searchCRContent
        )
      );
    }
    loadData().then(_r => {
      // setLoading(false);
    });
  }, [dispatch, page, pageSize, orderByType, searchCRContent, searchBy]);
  useEffect(()=>{
    setTimeout(() => {
      dispatch(
        loadCRData(
          page,
          pageSize,
          orderByType.order,
          orderByType.orderBy,
          searchBy,
          searchCRContent
        )
      );
    }, 1000);
  },[editEOContact]);
  useEffect(() => {
    function test() {
      if (CRCData.result.length === 0) {
        return false;
      }
      for (const cr of CRCData.result) {
        if (selectedCRC.indexOf(cr.journalCode) < 0) {
          return false;
        }
      }
      return true;
    }

    const a = test();
    setIsAllSelected(a);
  }, [selectedCRC, CRCData, reloadAllSelectionTrigger]);

  /**
   *
   * @param {string} content
   * @returns {string[]}
   */
  const handleSearchContent = content => {
    return (content || '').split(',').map(item => item.trim());
  };

  const buildCRCIdList = useData => {
    const CRCIdList = [];
    for (const CRCElement of useData) {
      CRCIdList.push(CRCElement.journalCode);
    }
    return CRCIdList;
  };

  //处理点击全选事件
  const handleToggleSelectAll = () => {
    const CRCIdList = CRCData.result;

    if (isDisplayedAllSelected) {
      // 取消选择当前内容
      for (const tmpJournal of CRCIdList) {
        dispatch(unselectBatchCRC(tmpJournal.journalCode));
        dispatch(unselectBatchCRCInfo(tmpJournal.journalCode));
      }
    } else {
      for (const tmpJournal of CRCIdList) {
        dispatch(selectBatchCRC(tmpJournal));
      }
      // 选中全部
    }
  };

  //用来改变表格的排序顺序
  const handleChangeOrder = orderBy => {
    let newOrder;
    if (orderByType.orderBy === orderBy) {
      // 如果当前的排序字段没有变化，则切换正反序
      newOrder = orderByType.order === 'desc' ? 'asc' : 'desc';
    } else {
      // 如果当前排序字段发生变化，则直接按照新的字段升序
      newOrder = 'asc';
    }
    dispatch(setCRCOrderBy(newOrder, orderBy));
  };
  //单选
  const setCRSelection = (journal, newState) => {
    if (newState) {
      dispatch(selectBatchCRC(journal));
    } else {
      dispatch(unselectBatchCRC(journal.journalCode));
      dispatch(unselectBatchCRCInfo(journal.journalCode));
    }
  };

  //改变每页的数量
  const handlePerPageChange = e => {
    unstable_batchedUpdates(() => {
      // 修改页面大小后，页码置为1
      dispatch(handleCRCPageChangeAction(1));
      dispatch(handleChangeCRCPageSize(Number(e.target.value)));
    });
  };

  //改变页码
  const handlePageChange = (_event, newPage) => {
    dispatch(handleCRCPageChangeAction(newPage));
  };
  const containerRef = useRef(null);
  const containerBoxRef = useRef(null);
  const scrollYRef = useRef(null);
  useEffect(() => {
    const containerElement = containerRef.current;
    const containerBoxElement = containerBoxRef.current;
    setTimeout(()=>{
      dispatch(
        contextHeightChanged({
          contextWidth: containerElement.offsetWidth,
          contextHeight: containerElement.offsetHeight,
          boxWidth: containerBoxElement.offsetWidth,
          boxHeight: containerBoxElement.offsetHeight,
        })
      );
    }, 100);
  },[CRCData]);

  const [modalOpen, setModalOpen] = useState(false);
  // const [modalProps, setModalProps] = useState();
  const [journalCode,setJournalCode]=useState();
  const [journalName,setJournalName]=useState();
  const [defaultValue,setDefaultValue]=useState();
  const handleEdit = (journalCode, journalName, contact) => {
    //  setModalProps({ journalCode, journalName, defaultValue: contact });
    setJournalCode(journalCode);
    setJournalName(journalName);
    setDefaultValue(contact);
    setModalOpen(true);
  };

  async function loadData() {
    dispatch(
      loadCRData(
        page,
        pageSize,
        orderByType.order,
        orderByType.orderBy,
        searchBy,
        searchCRContent
      )
    );
  }

  return (
    <Box>
      <EditModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        loadData={loadData}
        journalCode={journalCode}
        journalName={journalName}
        defaultValue={defaultValue}
        // {...modalProps}
      />
      <Box id='TableBox' data-selenium-id='CRContact_Overview-CRTable-Box'>
        <div
          style={{
            overflowX: 'hidden',
            overflowY: 'hidden',
            position: 'relative',
            width: 'fit-content',
          }}>
          <TableContainer
            ref={containerBoxRef}
            id='cr-contact-table-container'
            data-selenium-id='CRContact_Overview-CRTable-Container'
            component={Paper}
            className='table-screen-size'
            sx={{
              borderRadius: '8px 8px 0px 0px',
              borderWidth: '1px 1px 0px 1px',
              borderStyle: 'solid',
              borderColor: '#DFE4E8',
              height: 'calc(108vh - 385px)',
              width: siderOpen? 'calc(100vw - 129px - 158px)':'calc(100vw - 129px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar:vertical': {
                width: 0,
              },
              '&::-webkit-scrollbar-thumb:vertical': {
                backgroundColor: 'transparent',
              },
            }}
            onScroll={scrollYRef.current?.scrollAsContext}
          >
            <Table
              ref={containerRef}
              stickyHeader
              id='cr-contact-table'
              data-selenium-id='CRContact_Overview-CRTable-Table'
              aria-label='customized table'
              size='small'
              sx={{
                tableLayout: 'fixed',
                minWidth: '100%',
                width: 'auto',
              }}
            >
              <TableHead
                id='cr-contact-table-head'
                data-selenium-id='CRContact_Overview-CRTable-Head'
              >
                <TableRow
                  id='cr-contact-table-head-row'
                  data-selenium-id='CRContact_Overview-CRTable-HeadRow'
                >
                  {/* <StyledTableCell>
                  <Checkbox
                    id='cr-contact-table-head-checkbox'
                    data-selenium-id='CRContact_Overview-CRTable-HeadCheckbox'
                    color='primary'
                    // indeterminate={false}
                    checked={isDisplayedAllSelected}
                    onChange={handleToggleSelectAll}
                  />
                </StyledTableCell> */}
                  {headItems.map(item => (
                    <StyledTableCell
                      key={item.id}
                      align={'left'}
                      style={{
                        maxWidth: item.maxWidth,
                        minWidth: item.minWidth,
                        display: item.show ? 'table-cell' : 'none',
                        height:'42px!important',
                        width: item.widthFixed? item.minWidth : null,
                      }}
                      sx={{
                        paddingTop:item.id==='journalCode'?'3px!important':'12px!important',
                        paddingBottom:item.id==='journalCode'?'3px!important':'12px!important',
                        paddingLeft:'24px',
                        paddingRight:'24px',
                        position:'sticky!important',
                        // border: '0px solid #DFE4E8',
                        // letterSpacing: '0.08em',
                        // borderBottom: 'none',
                      }}
                      sortDirection={
                        orderByType.orderBy === item.id
                          ? orderByType.order
                          : false
                      }
                    >
                      {
                        <TableSortLabel
                          id={`cr-contact-table-head-${item.id}`}
                          data-selenium-id={`CRContact_Overview-Contact-Tablehead-${item.id}`}
                          active={item.isOrder && orderByType.orderBy === item.id}
                          direction={
                            orderByType.orderBy === item.id
                              ? orderByType.order
                              : 'asc'
                          }
                          onClick={() => {
                            if (item.isOrder) {
                              handleChangeOrder(item.id);
                            }
                          }}
                          sx={{
                            color:'#596A7C!important',
                            display: 'flex',
                            alignItems: 'center',
                            // padding:0,
                            // margin:0,
                            // verticalAlign:'bottom',
                          }}
                        >
                          {item.label}
                          {orderByType.orderBy === item.id ? (
                            <Box component='span' sx={visuallyHidden} >
                              {orderByType.order === 'desc'
                                ? 'sorted descending'
                                : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      }
                    </StyledTableCell>
                  ))}
                </TableRow>
                <Divider sx={{width: siderOpen? 'calc(100vw - 129px - 158px)':'calc(100vw - 129px)',position:'absolute',borderColor:'#CBD5E1'}}/>
              </TableHead>

              <TableBody
                id='cr-contact-table-body'
                data-selenium-id='CRContact_Overview-CRTable-Body'
              >
                {!loading &&
                (CRCData.result.map((row, index) => {
                  return (
                    <TableRow key={row.journalCode}
                      sx={{
                        '&:hover td': {
                          background: '#E8EDFB'
                        }
                      }}>

                      <StyledTableCell
                        data-selenium-id={`CRContact_Overview-CRTable-JournalCodeCell-${index}`}
                        sx={{
                          width: '108px'
                        }}
                      >
                        {row.journalCode}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`CRContact_Overview-CRTable-JournalNameCell-${index}`}
                        sx={{
                          paddingTop: '6px',
                          paddingBottom: '6px',
                        }}
                      >
                        <MulLineTooltipIfShow
                          id={`CRContact_Overview-CRTable-EditorialStyledCell-${index}-EditorialContact`}
                          outerRef={containerBoxRef}
                          line={2}
                          label={row.journalName}
                          placement='top'
                          sx={{
                            [`& .${tooltipClasses.tooltip}`]:{
                              maxWidth: '385px',
                            }
                          }}

                        />
                      </StyledTableCell>
                      <StyledTableCell
                        id='crContactTableEditorialContact'
                        data-selenium-id={`CRContact_Overview-CRTable-EditorialStyledCell-${index}`}
                        align='left'
                        sx={{
                          // width: '426px',
                          paddingRight: '24px',
                          paddingTop: '6px',
                          paddingBottom: '6px',
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center',justifyContent: 'space-between', }}>
                          <MulLineTooltipIfShow
                            id={`CRContact_Overview-CRTable-EditorialStyledCell-${index}-EditorialContact`}
                            outerRef={containerBoxRef}
                            alwaysShow={true}
                            line={2}
                            label={row.editorialContact}
                            placement='top'
                            labelSx={{
                              whiteSpace: 'normal',
                              wordBreak: 'break-word',
                            }}
                            sx={{
                              [`& .${tooltipClasses.tooltip}`]:{
                                maxWidth: '385px',
                              }
                            }}
                          />
                          <EditIconButton
                            style={{ cursor: 'pointer' }}
                            onClick={
                              () => {
                                handleEdit(
                                  row.journalCode,
                                  row.journalName,
                                  row.editorialContact
                                );
                              }
                            }>
                          </EditIconButton>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        id='crContactTablePRPLFullName'
                        data-selenium-id={`CRContact_Overview-CRTable-PRPLStyledCell-${index}`}
                        align='left'
                        sx={{
                          width: '260px'
                        }}
                      >
                        {row.prpp}
                      </StyledTableCell>
                      <StyledTableCell
                        id='crContactTablePRPMFullName'
                        data-selenium-id={`CRContact_Overview-CRTable-PRPMStyledCell-${index}`}
                        align='left'
                        sx={{
                          width: '260px'
                        }}
                      >
                        {row.prpm}
                      </StyledTableCell>
                    </TableRow>
                  );
                }
                ) ??
                  'No data to show')}
              </TableBody>
            </Table>
            {loading && (
              <Box
                id='cr-contact-table-loading-box'
                data-selenium-id='CRContact_Overview-CRTable-LoadingBox'
                sx={{
                  width: 'auto',
                  marginTop: '150px',
                  marginLeft: '50%',
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </TableContainer>
          <ScrollBarY ref={scrollYRef} contextHolderRef={containerBoxRef}/>
        </div>
        <Divider sx={{width: siderOpen? 'calc(100vw - 129px - 158px)':'calc(100vw - 129px)',color:'#DFE4E8',border:'1.5px solid #DFE4E8'}}/>
        <Box
          id='cr-contact-table-footer-box'
          data-selenium-id='CRContact_Overview-CRTable-FooterBox'
        >

          <TableFooter2
            id='cr-contact-table-footer-box'
            data-selenium-id='CRContact_Overview-CRTable-BoxFooter2'
            numSelected={selectedCRC.length}
            Page={page}
            perPageNumber={pageSize}
            perPageList={[20, 50, 100]}
            count={CRCData.count}
            handlePerPageChange={handlePerPageChange}
            handlePageChange={handlePageChange}
            sx={{width: siderOpen? 'calc(100vw - 129px - 158px)':'calc(100vw - 129px)',color:'#F1F3F5'}}
          />
        </Box>
      </Box>
    </Box>
  );
}

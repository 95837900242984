/*
 * @Author: Huang Tao
 * @Date:2022/2/9
 * @Description:Admin Edit(New or Update) props
 */
import { FormattedMessage } from 'react-intl';

import { CommonDialog } from '@/modules/AlertDialog';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.css';
import {
  openConfirm,
  setEditType,
  setPosition,
  setTextParam,
} from '@/actions/SIMT-User/Alert/AlertAction';

import {
  editClear,
  handleChangeAdminUpdateName,
  setAdminOrderBy,

} from '@/actions/SIMT-User/User/UserAction';
import {
  closePop,
  openPop,
  popClear,
  setSuperAdminPopData,
} from '@/actions/PopupAction';
import { refreshData } from '@/actions/CommonAction';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import ErrorAlert from './errorAlert';
import EditAdminInputLabel from './edit-admin/EditAdminInputLabel';
import EditAdminTextField from './edit-admin/EditAdminTextField';
import SIModalPro from '../../modules/Modal/SIModal';
import DropdownIcon from '../../assets/DropDownIconGray.svg';
import { getRoleInfo } from "@/actions/SIMT-User/Role";
import {
  createAdmin,
  downgradeAdmin,
  getAdminInfoByEmail,
  inactiveAdmin,
  unlockAdmin, updateAdmin
} from "@/actions/SIMT-User/User/SuperAdmin";

export default function EditAdmin() {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [DowngradeOpen, setDowngradeOpen] = useState(false);
  const [InactiveOpen, setInactiveOpen] = useState(false);
  const [Err, setErr] = useState(false);
  const [Correct, setCorrect] = useState(false);
  const [roleId, setRoleId] = useState(-1);
  const [ifUpdate, setIfUpdate] = useState(false);
  const [Role, setRole] = useState('Admin');
  const [Group, setGroup] = useState('Admin');

  const [userId, setUserId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState();
  const [lock, setLock] = useState();
  const [EmailExitError, setEmailExitError] = useState(false);
  const dispatch = useDispatch();
  const [alertOpen, setAlertOpen] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [emailAlertOpen, setEmailAlertOpen] = useState(false);
  const [emailErr, setEmailErr] = useState(false);

  const [ifAStatus, setIfAStatus] = useState('normal user ');

  const [existUser, setExistUser] = useState('');
  const [LockTemp, setLockTemp] = useState();

  const [roleData, setRoleData1] = useState({
    result: [
      {
        roleId: 1,
        roleName: 'Tier1',
        updateTime: 'Janurary 7,2022 07:51:20',
        updateBy: 'Alice',
      },
      {
        roleId: 2,
        roleName: 'Tier3',
        updateTime: 'Janurary 8,2022 07:51:20',
        updateBy: 'Ben',
      },
      {
        roleId: 3,
        roleName: 'Admin',
        updateTime: 'Janurary 8,2022 07:51:20',
        updateBy: 'Ben',
      },
    ],
    count: 2,
  });

  const [groups, setGroups] = useState([]);

  const selector = state => {
    return {
      createAdminResult: state.User.createAdminResult,
      upgradeUserResult: state.User.upgradeUser,
      downgradeAdminResult: state.User.downgardeAdmin,
      updateAdminResult: state.User.updateAdmin,
      singleAdmin: state.User.singleAdmin,
      adminEmailCheck: state.User.adminEmailCheck,
      adminEmailCheckEmail: state.User.adminEmailCheckEmail,
      allRoles: state.Role.roleInfo,
      allGroup: state.SystemConfig.groupList,
      createEmail: state.User.email,
      popupOpen: state.Pop.open,
      popupType: state.Pop.superAdminPop,
      popupData: state.Pop.superAdminData,
    };
  };
  const {
    createAdminResult,
    upgradeUserResult,
    downgradeAdminResult,
    updateAdminResult,
    adminEmailCheck,
    allRoles,
    allGroup,
    popupOpen,
    popupType,
    popupData,
  } = useSelector(selector);
  const createAdminAction = async ID => {
    dispatch(editClear());
    dispatch(
      setSuperAdminPopData({
        userId: userId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        group: Group,
      })
    );
    await dispatch(createAdmin(firstName, lastName, email, Group));
  };

  const updateAdminAction = () => {
    dispatch(editClear());
    dispatch(
      updateAdmin(
        userId,
        email,
        Group,
        Role,
        lock !== 'unlocked',
        status !== 'Inactive'
      )
    );
  };

  const getAdminInfoAction = async ID => {
    await dispatch(getAdminInfoByEmail(email));
  };

  const upgradeUserAction = async (email, fullname) => {
    await dispatch(createAdmin(firstName, lastName, email, Group));
  };

  useEffect(() => {
    if (popupOpen) {
      updateDataInit();
    }
  }, [popupOpen]);

  useEffect(() => {
    ChangeTextState(email, Role, firstName, lastName, Group);
  }, [email, Role, firstName, lastName, Group]);

  useEffect(() => {
    if (roleId === -1) {
      getAllRole();
    }

    return () => {
      dispatch(editClear());
    };
  }, []);

  useEffect(() => {
    let result1 = adminEmailCheck.data;
    if (result1 != null && adminEmailCheck.data.code === 200) {
      dispatch(closePop());
      setExistUser(result1);
      setEmailAlertOpen(true);
    } else if (adminEmailCheck.data !== undefined) {
      setConfirmOpen(true);
    }
  }, [adminEmailCheck]);

  useEffect(() => {
    if (upgradeUserResult === 'true') {
      dispatch(setPosition('bottom', 'center'));
      dispatch(setTextParam(existUser.message));
      dispatch(setEditType('upgradeToAdmin'));
      dispatch(openConfirm());
      dispatch(refreshData());
      dispatch(setAdminOrderBy('desc', 'updateTime'));
      dispatch(popClear());
      setUserId('');
      setEmail('');
      setFirstName('');
      setLastName('');
      setEmailAlertOpen(false);
    }
  }, [upgradeUserResult]);

  useEffect(() => {
    if (downgradeAdminResult === 'true') {
      dispatch(setPosition('bottom', 'center'));
      dispatch(setTextParam(lastName + ', ' + firstName));
      dispatch(setEditType('updateAdmin'));
      dispatch(openConfirm());
      dispatch(refreshData());
      dispatch(setAdminOrderBy('desc', 'updateTime'));
      dispatch(popClear());
      setUserId('');
      setEmail('');
      setFirstName('');
      setLastName('');
      setEmailAlertOpen(false);
    }
  }, [downgradeAdminResult]);

  useEffect(() => {
    if (updateAdminResult === 'true') {
      if (email !== '') {
        //逻辑待定 userid
        setUserId('');
        setEmail('');
        setFirstName('');
        setLastName('');
        setEmailAlertOpen(false);
      }
    }
  }, [updateAdminResult]);

  useEffect(() => {
    let result = createAdminResult;
    if (result === 'true') {
      dispatch(setPosition('bottom', 'center'));
      dispatch(setTextParam(lastName + ', ' + firstName));
      dispatch(setEditType('createAdmin'));
      dispatch(refreshData());
      dispatch(setAdminOrderBy('desc', 'updateTime'));
      dispatch(openConfirm());
      dispatch(popClear());
      dispatch(editClear());
      setUserId('');
      setEmail('');
      setFirstName('');
      setLastName('');
      setConfirmOpen(false);
    } else if (result === 'EmailExit') {
      setEmailExitError(true);
      setConfirmOpen(false);
      dispatch(openPop());
    } else {
      setConfirmOpen(false);
    }
  }, [createAdminResult]);

  useEffect(() => {
    if (allRoles.result !== undefined) {
      allRoles.result.push({ roleId: 1, roleName: 'Admin' });
      setRoleData1(allRoles);
    }
  }, [allRoles]);

  useEffect(() => {
    setGroups(allGroup);
  }, [allGroup]);

  const ChangeTextState = (text, Role, FirstName, LastName, Group) => {
    setEmailExitError(false);
    setErr(false);
    setCorrect(false);
    let reg =
      /^\w+((-\w+)|(\.\w+)|(_\w+))*@[A-Za-z0-9]+(([.\-])[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    let isok = reg.test(text);
    if (text === '') {
      setEmailErr(false);
    } else {
      setEmailErr(!isok);
    }
    if (Role === '' || FirstName === '' || LastName === '' || Group === '') {
      isok = false;
    }
    if (isok === true) {
      setErr(false);
      setCorrect(true);
    }
    if (isok === false) {
      setErr(true);
      setCorrect(false);
    }
  };

  const updateDataInit = () => {
    // console.log("popupData",popupData);
    setUserId(popupData.userId);
    setEmail(popupData.email);
    setFirstName(popupData.firstName);
    setLastName(popupData.lastName);
    setLock(popupData.lock);
    setStatus(popupData.status);
    setGroup(popupData.group);
    setRole('Admin');
  };

  const inputClear = () => {
    if (popupType.isCreate) {
      setUserId('');
      setEmail('');
      setFirstName('');
      setLastName('');
    } else {
      setRole('Admin');
      setStatus('');
      setLock('');
      setIfUpdate(false);
      setRoleId(-1);
    }
  };

  const handleClose = () => {
    inputClear();
    dispatch(editClear());
    dispatch(closePop());
  };

  const handleConfirmOpen = async () => {
    dispatch(closePop());
    if (popupType.isCreate) {
      await getAdminInfoAction();
    } else {
      dispatch(
        setSuperAdminPopData({
          firstName: firstName,
          lastName: lastName,
          email: email,
          status: status,
          lock: lock,
        })
      );

      setConfirmOpen(true);
    }
  };

  const handleUpgradeAdmin = async () => {
    await upgradeUserAction(email, existUser.message);
  };

  const handleNewAdmin = async () => {
    await createAdminAction();
  };

  const handleUpdateUser = async () => {
    updateAdminAction();
    dispatch(handleChangeAdminUpdateName(lastName + ', ' + firstName));
    dispatch(setEditType('updateAdmin'));
    inputClear();
    setConfirmOpen(false);
  };

  const confirmClose = () => {
    setConfirmOpen(false);
    handleClose();
  };

  // Email 输入失焦时
  const emailInputBlurred = async () => {
    let emailExists = false;
    if (emailExists) {
      setAlertOpen(true);
    }
  };

  const handleGroupChange = event => {
    setGroup(event.target.value);
  };

  //状态弹框关闭
  const handleActiveCLose = () => {
    if (ifAStatus === 'Inactive admin user ') {
      setLock(LockTemp);
      setStatus('active');
    } else if (ifAStatus === 'Unlock admin user ') {
      if (lock === 'unlocked') {
        setLock('locked');
      } else {
        setLock('unlocked');
      }
    }
    setInactiveOpen(false);
  };

  //状态更新
  const handleStatusChange = () => {
    setInactiveOpen(false);
  };

  const handelDowngrade = () => {
    setDowngradeOpen(true);
  };
  const handleDowngradeChange = () => {
    setIfUpdate(true);
    setDowngradeOpen(false);
  };

  const handleDowngradeCLose = () => {
    setRole('Admin');
    setDowngradeOpen(false);
  };

  const handleAlertClose = () => {
    setEmailAlertOpen(false);
  };

  const getAllRole = async () => {
    await dispatch(getRoleInfo(0, 0, 'roleName', 'asc'));
  };

  const handleRoleChange = event => {
    if (Role == 'Admin') {
      handelDowngrade();
    }
    if (event.target.value == 'Admin') {
      setIfUpdate(false);
    }
    setRole(event.target.value);
  };

  const handleGetRole = () => {
    if (roleId === -1) {
      getAllRole();
    }
  };

  return (
    <React.Fragment>
      <SIModalPro
        width={600}
        height='auto'
        open={popupOpen}
        handleClose={handleClose}
        boxSx={{
          padding: '32px 35px',
          magin: 0,
          '&.MuiBox-root': {
            padding: '32px 35px',
          },
          borderRadius: '8px',
        }}
        title={
          popupType.isCreate ? (
            <FormattedMessage
              id={'superAdminManmage.createAdminTitle'}
              data-selenium-id={'superAdminManmage.createAdminTitle'}
            />
          ) : (
            <FormattedMessage
              id={'superAdminManmage.updateAdminTitle'}
              data-selenium-id={'superAdminManmage.updateAdminTitle'}
              values={{
                lastName: popupData.lastName,
                firstName: popupData.firstName,
              }}
            />
          )
        }
      >
        <Stack
          data-selenium-id='SuperAdminManage_EditSysAdmin-Stack1'
          spacing='16px'
          justifyContent='flex-start'
          alignItems='flex-start'
          sx={{
            marginTop: '8px',
            width: '100%',
          }}
        >
          <Box
            data-selenium-id='SuperAdminManage_EditSysAdmin-ErrorAlertBox'
            sx={{ width: '100%' }}
          >
            {EmailExitError ? (
              <ErrorAlert data-selenium-id='SuperAdminManage_EditSysAdmin-ErrorAlert' />
            ) : null}
          </Box>
          {/* ROLE */}
          {!popupType.isCreate && (
            <Stack
              data-selenium-id='SuperAdminManage_EditSysAdmin-Stack1-Stack'
              direction='row'
              justifyContent='flex-start'
              alignItems='flex-start'
              sx={{ width: '480px' }}
            >
              <EditAdminInputLabel
                data-selenium-id='SuperAdminManage_EditSysAdmin-InputLabel'
                text={
                  <FormattedMessage
                    id={'superAdminManmage.editAdminRoleLabel'}
                    data-selenium-id={'superAdminManmage.editAdminRoleLabel'}
                  />
                }
                sx={{
                  width: '165px',
                  mt: '5px',
                }}
              />
              <FormControl
                data-selenium-id='SuperAdminManage_EditSysAdmin-FormControl'
                variant='outlined'
                fullWidth
                sx={{
                  minWidth: '345px',
                  height: '32px',
                  '& .MuiSelect-select': {
                    padding: '8px 12px',
                    height: '32px',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '120%',
                    letterSpacing: '-0.011em',
                  },
                }}
              >
                <Select
                  id='createAdminRoleSelect'
                  data-selenium-id='SuperAdminManage_EditSysAdmin-FormControl-CreateAdminRoleSelect'
                  value={Role}
                  onChange={handleRoleChange}
                  onClick={handleGetRole}
                  IconComponent={DropdownIcon}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                >
                  {roleData.result.map((role, index) => (
                    <MenuItem
                      id={`createAdminRoleItem_${index}`}
                      data-selenium-id={`SuperAdminManage_EditSysAdmin-FormControl-CreateAdminRoleItem-${index}`}
                      disabled={role.roleName == 'Admin'}
                      key={role.roleId}
                      value={role.roleName}
                    >
                      {role.roleName == 'Admin'
                        ? 'System Admin'
                        : role.roleName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          )}
          {/*First name*/}
          <Stack
            id='frame2454FirstName'
            data-selenium-id='SuperAdminManage_EditSysAdmin-FirstName'
            direction='row'
            sx={{
              width: '480px',
            }}
            justifyContent='flex-start'
            alignItems='flex-start'
          >
            <EditAdminInputLabel
              data-selenium-id='SuperAdminManage_EditSysAdmin-FirstNameInputLabel'
              text='First Name'
              sx={{
                justifyContent: 'flex-start',
                width: '165px',
                mt: '5px', // 为了让它仅仅跟text field对齐
              }}
            />
            <EditAdminTextField
              autoFocus
              id='editAdminFirstNameInput'
              data-selenium-id='SuperAdminManage_EditSysAdmin-FirstNameTextField'
              fullWidth
              variant='outlined'
              defaultValue={firstName}
              value={firstName}
              limitLength={40}
              setValue={setFirstName}
              exSpecialChar
              setSCErr={setFirstNameErr}
              disabled={!popupType.isCreate}
              sx={{
                padding: 0,
              }}
            />
          </Stack>

          {/*Last Name*/}
          <Stack
            data-selenium-id='SuperAdminManage_EditSysAdmin-LastName'
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            sx={{ width: '480px' }}
          >
            <EditAdminInputLabel
              data-selenium-id='SuperAdminManage_EditSysAdmin-LastNameInputLabel'
              text='Last Name'
              sx={{
                width: '165px',
                mt: '5px',
              }}
            />
            <EditAdminTextField
              id='editAdminLastNameInput'
              data-selenium-id='SuperAdminManage_EditSysAdmin-LastNameTextField'
              fullWidth
              variant='outlined'
              defaultValue={lastName}
              setValue={setLastName}
              limitLength={40}
              value={lastName}
              exSpecialChar
              setSCErr={setLastNameErr}
              disabled={!popupType.isCreate}
              sx={{
                padding: 0,
              }}
            />
          </Stack>

          {/*Email*/}
          <Stack
            data-selenium-id='SuperAdminManage_EditSysAdmin-Email'
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            sx={{ width: '480px' }}
          >
            <EditAdminInputLabel
              data-selenium-id='SuperAdminManage_EditSysAdmin-EmailInputLabel'
              text='Email Address'
              sx={{
                width: '165px',
                mt: '5px',
              }}
            />
            <EditAdminTextField
              variant='outlined'
              fullWidth
              id='editAdminEmailInput'
              data-selenium-id='SuperAdminManage_EditSysAdmin-EmailTextField'
              defaultValue={email}
              limitLength={100}
              setValue={setEmail}
              onBlur={emailInputBlurred}
              value={email}
              disabled={!popupType.isCreate}
              error={emailErr}
              helperText='The mail format is invalid. Please enter a valid email address.'
            />
          </Stack>

          {/*Group 选择*/}
          <Stack
            data-selenium-id='SuperAdminManage_EditSysAdmin-Group'
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            sx={{ width: '480px' }}
          >
            <EditAdminInputLabel
              data-selenium-id='SuperAdminManage_EditSysAdmin-GroupInputLabel'
              text='Group/Title'
              sx={{
                width: '165px',
                mt: '5px',
              }}
            />
            <FormControl
              data-selenium-id='SuperAdminManage_EditSysAdmin-GroupFormControl'
              variant='outlined'
              fullWidth
              sx={{
                minWidth: '345px',
                height: '32px',
                '& .MuiSelect-select': {
                  padding: '8px 12px',
                  height: '32px',
                  alignItems: 'center',
                  boxSizing: 'border-box',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '120%',
                  letterSpacing: '-0.011em',
                },
              }}
            >
              <Select
                id='createAdminGroupSelect'
                data-selenium-id='SuperAdminManage_EditSysAdmin-GroupSelect'
                value={Group}
                onChange={handleGroupChange}
                IconComponent={DropdownIcon}
                MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
              >
                {groups.map((group, index) => (
                  <MenuItem
                    key={index}
                    value={group.value}
                    id={`editAdminGroupItem_${index}`}
                    data-selenium-id={`SuperAdminManage_EditSysAdmin-EditAdminGroupItem_${index}`}
                    disabled={group.value === 'Admin'}
                  >
                    <div
                      style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '300px' }}
                    >
                      {group.value}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          {/* 确认&取消按钮 */}
          <Stack
            data-selenium-id='SuperAdminManage_EditSysAdmin-Buttons'
            direction='row'
            sx={{ width: '100%' }}
            justifyContent='flex-end'
          >
            <Button
              data-selenium-id='SuperAdminManage_EditSysAdmin-CancelButton'
              onClick={handleClose}
              variant='text'
              sx={{
                width: '40px',
                height: '32px',
                border: 0,
                textTransform: 'capitalize',
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={
                !(
                  !Err &&
                  Correct &&
                  !EmailExitError &&
                  !firstNameErr &&
                  !lastNameErr
                )
              }
              id='editAdminConfirmButton'
              data-selenium-id='SuperAdminManage_EditSysAdmin-ConfirmButton'
              onClick={handleConfirmOpen}
              variant='contained'
              sx={{
                height: '32px',
                width: '51px',
                boxShadow: 'none',
                borderRadius: '3px',
                textTransform: 'capitalize',
                ml: '34px',
              }}
            >
              {popupType.isCreate ? 'Add' : 'Update'}
            </Button>
          </Stack>
        </Stack>
      </SIModalPro>

      <CommonDialog
        data-selenium-id='SuperAdminManage_EditSysAdmin-CommonDialog1'
        title={
          popupType.isCreate
            ?
            'Create a system admin ' + lastName + ', ' + firstName
            :
            Role === 'Admin' ?
              "user email has exist" +
              ', ' +
              'do you want to upgrade to system admin?' :
              'Update system admin ' +
              popupData.lastName +
              ', ' +
              popupData.firstName +
              "'s information"
        }
        content='Are you sure you want to proceed ?'
        alertOpen={confirmOpen}
        handleClose={confirmClose}
        handleConfirm={popupType.isCreate ? handleNewAdmin : handleUpdateUser}
      />

      <CommonDialog
        data-selenium-id='SuperAdminManage_EditSysAdmin-CommonDialog2'
        title={
          'Downgrade system admin user ' +
          popupData.lastName +
          ', ' +
          popupData.firstName +
          ' to ' +
          Role +
          ' user'
        }
        content='Are you sure you want to proceed ?'
        alertOpen={DowngradeOpen}
        handleClose={handleDowngradeCLose}
        handleConfirm={handleDowngradeChange}
      />
      <CommonDialog
        data-selenium-id='SuperAdminManage_EditSysAdmin-CommonDialog3'
        title={ifAStatus + popupData.lastName + ', ' + popupData.firstName}
        content='Are you sure you want to proceed ?'
        alertOpen={InactiveOpen}
        handleClose={handleActiveCLose}
        handleConfirm={handleStatusChange}
      />
      <CommonDialog
        data-selenium-id='SuperAdminManage_EditSysAdmin-CommonDialog4'
        title='User already exists'
        content={
          'Upgrade ' +
          existUser.data +
          ' user ' +
          existUser.message +
          ' to system admin ?'
        }
        alertOpen={emailAlertOpen}
        handleClose={handleAlertClose}
        handleConfirm={handleUpgradeAdmin}
      />
    </React.Fragment>
  );
}

import {
  LOADING,
  ERROR,
  VALID,
  NO_DATA,
  NO_SELECTION_FROM_DROPDOWN_MENU,
  NO_DISPLAYED_DATA,
} from '@/pages/Report/chartUtils/constants.js';

export default function useGetDiagramStatusHook(
  chartData,
  filteredChartData,
  selectedCatagories,
  hasCategoryFilter,
  alertLabel,
  loading,
  error
) {
  // console.log('==chartData==', chartData);
  let valid = VALID;
  let info = null;

  if (loading) {
    valid = LOADING;
  } else if (error) {
    valid = ERROR;
  } else if (!(Array.isArray(chartData) && chartData.length > 1)) {
    valid = NO_DATA;
    info = alertLabel;
  } else if (
    hasCategoryFilter &&
    Array.isArray(selectedCatagories) &&
    selectedCatagories.length === 0
  ) {
    valid = NO_SELECTION_FROM_DROPDOWN_MENU;
    info = alertLabel;
  } else if (!validateFilteredData(filteredChartData)) {
    valid = NO_DISPLAYED_DATA;
    info = alertLabel;
  }

  return {
    valid,
    info,
  };
}

/**
 * check if there is any data for selected certeria
 * @param {chartData}
 * @returns boolean
 */
const validateFilteredData = chartData => {
  let isValid = false;

  try {
    const records = chartData.slice(1);
    records.forEach(record => {
      record.slice(1).forEach(value => {
        // console.log('value', value);
        isValid ||= parseInt(value) !== 0;
        // console.log('isValid', isValid);
      });
    });
  } catch (e) {
    isValid = true;
  }

  return isValid;
};

// @ts-check
import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { SIMTButton } from '../../../components/StyledButton/SIMTButton';
// @ts-ignore
import PeopleImage from '../../../assets/SIPPage/ThankYouPage/People.webp';
import SubmitCompletedPage, {
  buttonStyle,
  textStyle,
} from './SubmitCompletedPage';

/**
 *
 * @param {import("./ProposalShelvedPage").ProposalShelvedProps} props
 * @returns
 */
const ProposalShelvedPage = props => {
  const { theme = 'blue', ...others } = props;

  const matches900 = useMediaQuery('(min-width:900px)');
  const matches425 = useMediaQuery('(min-width:425px)');

  return (
    <SubmitCompletedPage theme={theme} {...others}>
      <Stack
        justifyContent={'center'}
        sx={{ height: '100%' }}
        data-selenium-id='SIP_SubmitComplete-ProposalShelvedPage-Stack'
      >
        <Stack
          sx={{ textAlign: 'center', ...textStyle[theme] }}
          spacing='24px'
          data-selenium-id='SIP_SubmitComplete-ProposalShelvedPage-TypographyStack'
        >
          <Typography
            data-selenium-id='SIP_SubmitComplete-ProposalShelvedPage-TitleTypography'
            sx={{ fontSize: matches425 ? '56px' : '44px', fontWeight: 800 }}
          >
            Proposal Shelved
          </Typography>
          <Typography
            data-selenium-id='SIP_SubmitComplete-ProposalShelvedPage-ApologizeTypography'
            sx={{
              fontSize: '22px',
              fontWeight: 400,
              '& span': {
                fontWeight: 600,
                // textDecoration: "underline",
              },
            }}
          >
            We apologize for having to shelve your Special Issue proposal.
            <br />
            If you have any questions, please feel free to contact
            <span> </span>
            <a
              href='mailto:specialcontent@wiley.com'
              style={{
                paddingBottom: '-1px',
                color: 'white',
              }}
            >
              specialcontent@wiley.com
            </a>
          </Typography>
        </Stack>
      </Stack>
    </SubmitCompletedPage>
  );
};

export default ProposalShelvedPage;

import { Stack } from '@mui/material';
import { ThemeContext } from '.';
import {Box} from "@mui/system";
import React from 'react';

export default function AlertActions(props) {
  const { actions } = props;
  return actions?.length > 0 ? (
    <Stack direction='row' spacing={3} sx={{marginTop:'20px'}}>
      {actions.map(action => (
        <AlertAction
          key={action.name}
          value={action.name}
          onClick={action.exe}
        />
      ))}
    </Stack>
  ) : null;
}

export function AlertAction(props) {
  const theme = React.useContext(ThemeContext);
  const { value, onClick } = props;
  return (
    <Box
      sx={{
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '20px',
        letterSpacing: '-0.02em',
        textAlign: 'left',
        color: '#D97706',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {value}
    </Box>
  );
}

import { Box, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { selectCatJournalFilterItem } from '../../../../../../actions/SIMT-SI/CatJournal/CATJournalAction';
import {
  getSearchResultDisplayContent,
  SelectedDisplayContent,
} from './JournalFilterDisplayContent';
import { getValueStringByObjectKey } from './JournalFilterConstantListDisplay';

/**
 *
 * @param {import("./JournalFilterSearchResultDisplay").JournalFilterSearchResultDisplayProps} props
 * @returns
 */
function JournalFilterSearchResultDisplay(props) {
  const {
    name,
    data,
    selected,
    value,
    selectObjectKey,
    displayFunction,
    multiselect = true,
    isLoading = false,
  } = props;

  const dispatch = useDispatch();

  // 这里做一份拷贝，这样就不会导致用户取消选择时，在"已选择"区域直接消失的问题
  const [selectedDataForDisplay, setSelectedDataForDisplay] = useState(
    /** @type {string[]} */ (selected)
  );

  // 当用户变更了搜索内容时，已选择项目会进入上栏
  useEffect(() => {
    setSelectedDataForDisplay(current => {
      const newValue = [...current, ...selected];
      return newValue.filter((item, index) => newValue.indexOf(item) === index);
    });
  }, [value]);

  /**
   * 通用点击事件实现
   * @param {string | import("./JournalFilterConstantListDisplay").JournalFilterDataType} item  点击的那个item
   */
  function handleClick(item) {
    // console.log("[journal fileter result] item = ", item);
    let selectObject = getValueStringByObjectKey(selectObjectKey, item);
    // console.log("name = select object = ", name, item);
    if (multiselect) {
      // 多选情况
      if (selected.indexOf(selectObject) > -1) {
        dispatch(selectCatJournalFilterItem(name, selectObject, 'unselect'));
      } else {
        dispatch(selectCatJournalFilterItem(name, selectObject, 'select'));
      }
    } else {
      // 单选情况
      if (selected.indexOf(selectObject) > -1) {
        // 取消选择，类似多选的取消
        dispatch(selectCatJournalFilterItem(name, selectObject, 'unselect'));
      } else {
        // 使用replace
        dispatch(selectCatJournalFilterItem(name, selectObject, 'replace'));
      }
    }
  }

  /**
   * 判断某个item是否已选择
   * @param {string | import("./JournalFilterConstantListDisplay").JournalFilterDataType} item
   * @return {boolean}  如果已选，则返回true，否则false
   */
  function determineIsSelected(item) {
    let selectObject = getValueStringByObjectKey(selectObjectKey, item);

    return selected.indexOf(selectObject) > -1;
  }

  return (
    <Box
      sx={{
        height: '100%',
        overflowY: 'auto',
        color: '#484848',
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: 400,
        padding: '2px 0 2px 3px',
        fontFamily: 'Open Sans',
      }}
      data-selenium-id='CATJournal_Overview-Table-SearchRow-filterComponents-SearchResultDisplay-Box'
    >
      {/*已选择的区域*/}
      <SelectedDisplayContent
        name={name}
        handleClick={handleClick}
        checkedList={selectedDataForDisplay}
        determineIsSelected={determineIsSelected}
        displayFunction={displayFunction}
      />
      {/*分割线，如果`selectedDataForDisplay`不为空且输入内容不为空时就需要显示*/}
      {selectedDataForDisplay.length > 0 && data.length > 0 && <Divider />}
      {/*搜索结果展示区域，当输入内容为空的时候不需要显示*/}
      {data.length > 0 &&
        getSearchResultDisplayContent(
          name,
          data,
          isLoading,
          handleClick,
          selectedDataForDisplay,
          determineIsSelected,
          displayFunction,
          selectObjectKey
        )}
    </Box>
  );
}

JournalFilterSearchResultDisplay.propTypes = {
  name: PropTypes.string.isRequired, // 这个filter的名字，例如journalCode, revenueModel
  isLoading: PropTypes.bool,
  data: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  selectObjectKey: PropTypes.string.isRequired,
  multiselect: PropTypes.bool,
  displayFunction: PropTypes.func.isRequired,
};

export default JournalFilterSearchResultDisplay;

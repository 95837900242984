import Header from '../../../../../../components/Header';
import Footer from '../../../../../../components/Footer';
import { Box, Stack, Button, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { CreateAndUpdateTitle } from '../../../CommonComponents/CreateAndUpdateTitle';
import CreateSIDisabledBlock from '../../../CommonComponents/CreateSIDisabledBlock';
import CreateAndUpdateTextArea from '../../../CommonComponents/CreateAndUpdateTextArea';
import CreateAndUpdateInfomationBlock from '../../../CommonComponents/CreateAndUpdateInfomationBlock';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { openConfirm, setEditType, setTextParam } from '@/actions/SIMT-User/Alert/AlertAction';
import { useNavigate } from 'react-router-dom';
import CreateAndUpdateMain from '../../../CommonComponents/CreateAndUpdateMain';
import CreateAndUpdateFooter from '../../../CommonComponents/CreateAndUpdateFooter';
import MainPageFramework from '../../../CGTComponents/MainPageFramework';
import ItemBlock from '../../../CGTComponents/ItemBlock';
import {updateToShelved} from "@/actions/SIMT-SI/SpecialIssue/UpdateSpecialIssue";
function ShelvedPage(props) {
  const { nextClickListener, setErr, setValue, value } = props;
  const [isClick, setIsClick] = useState(false);
  const [shelvedReason, setShelvedReason] = useState(value);
  const [limitErr, setLimitErr] = useState(false);
  const [first, setFirst] = useState(true);

  //获取 siId
  const url = window.location.pathname;
  const getSiId = () => {
    let urlArr = url.split('/');
    let siIdArr = urlArr[urlArr.length - 3].split('-');
    return siIdArr[0] + '-' + siIdArr[1] + '-' + siIdArr[2];
  };

  const isEmpty = value => {
    return (
      value === null || value === undefined || value === [] || value === ''
    );
  };

  //统计 页面错误
  const isError = () => {
    return isEmpty(shelvedReason) || limitErr;
  };

  useEffect(() => {
    if (!first) {
      setIsClick(true);
    }
  }, [nextClickListener]);

  useEffect(() => {
    setErr(isError());
    if (!isError()) {
      setIsClick(false);
    }
  });

  useEffect(() => {
    setValue(shelvedReason);
  }, [shelvedReason]);

  useEffect(() => {
    setFirst(false);
  }, []);

  return (
    <Box>
      <CreateAndUpdateTitle title='Update Stage to Shelved' />
      <Stack spacing={2} direction='column'>
        <CreateSIDisabledBlock
          defaultValue={getSiId()}
          title='SI Code'
          id='SICode'
          data-selenium-id='SICode'
        />
        <CreateAndUpdateTextArea
          id='shelvedReason'
          title='Shelved Reason'
          required={true}
          emptyErr={isClick && isEmpty(shelvedReason)}
          placeholder='Type Shelved Reason'
          max={10000}
          value={shelvedReason}
          setValue={setShelvedReason}
          height='200px'
          exceedsLimitHelperText='Shelved reason should not exceed 10000 characters in length'
          setlimitErr={setLimitErr}
        ></CreateAndUpdateTextArea>
      </Stack>
    </Box>
  );
}

function ReviewPage(props) {
  const { value } = props;
  const warningBox = {
    width: '100%',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#C40000',
    marginBottom: '20px',
  };
  return (
    <Box>
      <CreateAndUpdateTitle title='Update Stage to Shelved' />
      <Stack
        spacing={1}
        direction='column'
        sx={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '20px',
        }}
      >
        <Box sx={{ height: '20px', color: '#596A7C' }}>Shelved Reason:</Box>
        <Box
          sx={{
            color: '#262E35',
            wordBreak: 'break-all',
            wordWrap: 'break-word',
          }}>{value}</Box>
      </Stack>
    </Box>
  );
}

export default function MainPage() {
  const [step, setStep] = useState(0);

  const [nextClickListener, setNextClickListener] = useState(false);
  const [step1Err, setStep1Err] = useState(false);
  const dispatch = useDispatch();
  const [shelvedReason, setShelvedReason] = useState('');
  const [closedForSubmission, setClosedForSubmission] = useState('Yes');

  //获取 siId
  const url = window.location.pathname;
  const getSiId = () => {
    let urlArr = url.split('/');
    return urlArr[urlArr.length - 3];
  };

  //实时监听innerwidth的变化
  const getWindowSize = () => ({
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
  });
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const handleResize = () => {
    setWindowSize(getWindowSize());
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });
  //////////////////////////////////////////
  //button的样式
  const buttonTextStyle = color => {
    return {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      textAlian: 'center',
      letterSpacing: '0.01em',
      color: color,
      textTransform: 'capitalize',
    };
  };

  const backBtn = {
    ...buttonTextStyle('#154AB6'),
    '&:hover': {
      background: 'transparent',
    },
  };
  const nextAndCreateBtn = {
    ...buttonTextStyle('#F6F7F8'),
    width: '121.09px',
    height: '36px',
    borderRadius: ' 5px',
    backgroundColor: 'primary.main',
    '&:hover': {
      backgroundColor: 'primary.main',
    },
  };
  ///////////////////////////////////

  const handleBack = () => {
    if (step !== 0) {
      setStep(step - 1);
    } else {
      window.history.go(-1);
    }
  };

  const handleUpdateToShelved = async (SIid, shelvedReason, closedSubmit) => {

    await dispatch(updateToShelved(SIid, shelvedReason, closedSubmit));
  };

  const selector = state => {
    return {
      UpdateSIResult: state.SI.updateSIResult,
    };
  };
  const { UpdateSIResult } = useSelector(selector);
  const navigate = useNavigate();

  useEffect(() => {
    if (UpdateSIResult !== '') {
      if (UpdateSIResult !== 'error') {
        // 当updateSIResult更新时，说明update接口已经返回，意味着更新工作已经完成，无需限制两秒跳转
        navigate(`/simt/auth/siRelated/si/detail/${getSiId()}`);
        dispatch(setTextParam());
        dispatch(setEditType('updateSI'));
        dispatch(openConfirm());
      } else {
        dispatch(setTextParam());
        dispatch(setEditType('error'));
        dispatch(openConfirm());
      }
    }
  }, [UpdateSIResult]);

  const handleNextOrCreate = () => {
    if (step === 0) {
      setNextClickListener(!nextClickListener);
      if (!step1Err) {
        setStep(1);
      }
    } else if (step === 1) {
      setIsShelving(true);

      async function shelve() {
        await handleUpdateToShelved(getSiId(), shelvedReason, 'Yes');
      }

      shelve().then(_r => {
        setIsShelving(false);
      });
    }
  };

  const getContainer = () => {
    return step === 0 ? (
      <ShelvedPage
        nextClickListener={nextClickListener}
        setErr={setStep1Err}
        setValue={setShelvedReason}
        value={shelvedReason}
      />
    ) : (
      <ReviewPage value={shelvedReason} />
    );
  };

  const [shelving, setIsShelving] = useState(false);

  const actionBar = (
    <CreateAndUpdateFooter
      id='updateSI_shelved_mainPage_actionBar'
      activeStep={step}
      lastStep={1}
      handleBack={handleBack}
      handleNextOrCreate={handleNextOrCreate}
      isShelved={true && step === 1}
    />
  );

  return (
    <MainPageFramework id='updateSI_shelved_mainPage' actionBar={actionBar}>
      <CreateAndUpdateMain
        id='updateSI_shelved_mainPage_content'
        container={getContainer()}
      ></CreateAndUpdateMain>
    </MainPageFramework>
  );
}

import {axios_instance, axios_instance_fileDownload} from "@/utils/axios_instance";
import {
    EXPORT_REPORT,
    GET_MONTHLY_SIZE,
    GET_PUBLISHED_SIZE,
    GET_SUBMISSION_SIZE
} from "@/actions/SIMT-SI/Export/ExportDataAction";
import {downloadFile} from "@/actions/SIMT-SI/SpecialIssue/SIAction";
import moment from "moment/moment";

export function monthlyExportSize(selectDate) {
    return async dispatch => {
        try {
            dispatch({type: GET_MONTHLY_SIZE, data: null});
            var res = await axios_instance.post(`/api/v1/si-service/special-issues/marketing-data/export/size`,
             {
                monthlyCampaign: {
                    selectedDate: selectDate
                  }
             }
            ,{
                params: {
                    type: 'monthly',
                    },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const {code,data} = res.data;
            if (code === 200) {
                dispatch({type: GET_MONTHLY_SIZE, data: data});
            } else {
                dispatch({type: GET_MONTHLY_SIZE, data: 'error'});
            }
        } catch {
            return dispatch({type: GET_MONTHLY_SIZE, data: 'error'});
        }
    };
}

export function submissionExportSize(obj = {}) {
    return async dispatch => {
        try {
            dispatch({type: GET_SUBMISSION_SIZE, data: null});
            var res = await axios_instance.post(`/api/v1/si-service/special-issues/marketing-data/export/size`,
                {submissionCampaign:obj},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('authorization'),
                    },
                    params: {
                        type: 'submission'
                    },
                });
            const {code,data} = res.data;
            if (code === 200) {
                dispatch({type: GET_SUBMISSION_SIZE, data: data});
            } else {
                dispatch({type: GET_SUBMISSION_SIZE, data: 'error'});
            }
        } catch {
            return dispatch({type: GET_SUBMISSION_SIZE, data: 'error'});
        }
    };
}

export function publishedExportSize(obj = {}) {
    return async dispatch => {
        try {
            dispatch({type: GET_PUBLISHED_SIZE, data: null});
            var res = await axios_instance.post(`/api/v1/si-service/special-issues/marketing-data/export/size`,
            {publishedCampaign:obj},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('authorization'),
                    },
                    params: {
                        type: 'published'
                    },
                });
            const {code,data} = res.data;
            if (code === 200) {
                dispatch({type: GET_PUBLISHED_SIZE, data: data});
            } else {
                dispatch({type: GET_PUBLISHED_SIZE, data: 'error'});
            }
        } catch {
            return dispatch({type: GET_PUBLISHED_SIZE, data: 'error'});
        }
    };
}

export function exportData(selectDate) {
    selectDate = new Date(selectDate);
    let year = selectDate.getFullYear();
    let month = selectDate.getMonth() + 1;
    let day = selectDate.getDate();

    if (month < 10) {
        month = '0' + month;
    }

    if (day < 10) {
        day = '0' + day;
    }

    let dateString = '' + year + month + day;
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/export/getSiMarketing',
                {
                    responseType: 'blob',
                    params: {
                        selectedDate: dateString,
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            downloadFile(
                res.data,
                `Marketing Campaign File_${moment().utc().format('YYMMDD_HHmmss')}.xls`
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}
// @ts-check
import { DetailScrollTopButton } from '@/components/StyledButton/ScrollToTopButton';
import { Box, Stack} from '@mui/material';
import React, { useEffect , useState} from 'react';
import { TitleBlock } from './CustomComponents/CustomBlock';
import EndButtonArrowFold from '@/assets/EndButtonArrowFold.svg';
import { FormattedMessage } from 'react-intl';
import {
  RevisePageBodyContainer,
  SectionsContainer,
} from './CustomComponents/CustomContainer';
import { MakeDecisionStickyBar } from './CustomComponents/CustomStickyBar';
import {
  commissionApproachesInitialValue,
  geInformationListIntialValue,
  sipInformationInitialValue,
} from './CommonInitialValue';

import CommentSection from './Section/CommentSection';
import { ContentCommissioningApproachesShowSection } from './Section/ContentCommissioningApproachesSection';
import { GEInformationShowSection } from './Section/GEInformationSection';
import { ObjectShowSection } from './Section/ObjectSection';
import { SIPInformationShowSection } from './Section/SIPInformationSection';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadSIPReviseData,
  SET_DOWNLOAD_REVISE_DATA_STATUS,
  SET_UPLOAD_REVISE_DATA_STATUS,

} from '@/actions/SIMT-SIP/SIP/SIPAction';
import { useParams } from 'react-router-dom';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';
import {DecisionFoldBtn, SavedDecisionFoldBtn} from '@/pages/SIPPage/SIPDetailPage/DecisionSection/DecisionMyStyled';
import { SSIPObjectShowSection } from './Section/SSIPObjectSection';
import { SSIPInformationShowSection } from './Section/SSIPInformationSection';
import {submitEditorReviewData} from "@/actions/SIMT-SIP/SIP/SipEditorReview";

/**
 * @type {import("./EditorReviewPage").SIPEditorReviewData}
 */
export const sipEditorReviewInitialValue = {
  sipCode: 'SIP-2022-001234',
  object: {
    preferredSubjectArea: 'Nikolaou, Ioannis',
  },
  GEInformation: geInformationListIntialValue,
  sipInformation: sipInformationInitialValue,
  CommissioningApproaches: commissionApproachesInitialValue,
  CommentsToTheProposal:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
};

/**
 *
 * @param {import("./EditorReviewPage").EditorReviewPageProps} props
 * @returns
 */
const EditorReviewPage = props => {
  const { sipEditorReviewData } = props;

  const dispatch = useDispatch();
  const { uploadReviseDataStatus } = useSelector(state => ({
    /** @type {import("./ProposerRevisePage").UploadReviseDataStatus} */
    // @ts-ignore
    uploadReviseDataStatus: state.SIP.uploadReviseDataStatus,
  }));

  const { token } = useParams();

  /**
   *
   * @param {import("./EditorReviewPage").EditorReviewDecision} decision
   */
  const handleSubmitDecision = async decision => {
    if (token === undefined) return;
    // console.log("[handleSubmitDecision] decision = ", decision);
    dispatch({
      type: SET_UPLOAD_REVISE_DATA_STATUS,
      /** @type {import("./ProposerRevisePage").UploadReviseDataStatus} */ data: 'uploading',
    });
    dispatch(
      submitEditorReviewData(token, {
        sipCode: sipEditorReviewData.sipCode,
        comments: decision.comment,
        ...((({ comment, ...rest }) => rest)(decision))
      })
    );
  };
  const [isSave, setIsSave] = useState(false);
  const buttonContainer = {
    width: '100%',
    height: '60px',
    background: '#FFFFFF',
    boxShadow: '0px -2px 5px rgba(0,0,0,0.1)',
    position: 'sticky', // 外部需要有一个容器让他贴靠底部
    bottom: 0,
    padding: '12px 32px',
    zIndex: '1200',
    overflow: 'hidden',
  };

  useEffect(() => {
    if (uploadReviseDataStatus === 'error') {
      dispatch(
        setSnackbarMessageAndOpen(
          'sipDetail.revise.submitDecisionError',
          {},
          SEVERITIES.error
        )
      );
    }
    if (
      uploadReviseDataStatus === 'success' ||
      uploadReviseDataStatus === 'error'
    ) {
      if (token) {
        dispatch({
          type: SET_DOWNLOAD_REVISE_DATA_STATUS,
          /** @type {import("./ProposerRevisePage").DownloadReviseDataStatus} */ data: 'downloading',
        });
        dispatch(loadSIPReviseData(token, 'EditorReview'));
      }
      dispatch({
        type: SET_UPLOAD_REVISE_DATA_STATUS,
        /** @type {import("./ProposerRevisePage").UploadReviseDataStatus} */ data: 'idle',
      });
    }
  }, [uploadReviseDataStatus]);
  const [decisionIsFold, setDecisionIsFold] = useState(true);
  const handleDecisionUnfold = () => {
    setDecisionIsFold(false);
  };

  return (
    <Box data-selenium-id='SIP_Revise_EditorPage-Box'>
      <RevisePageBodyContainer>
        <TitleBlock
          title={sipEditorReviewData.sipInformation.sipTitle || ''}
          SIPCode={sipEditorReviewData.sipCode}
        />
        <SectionsContainer>
        {sipEditorReviewData?.sipInformation?.sipType === 'Yes'?
        (<SSIPObjectShowSection
          value={{
            type: 'EditorReview',
            sipObject: sipEditorReviewData.object?.solicitedSipObjectInfo,
          }}
        />):(
          <ObjectShowSection
            value={{
              type: 'EditorReview',
              sipObject: sipEditorReviewData.object,
            }}
          />
        )}
          
          <GEInformationShowSection value={sipEditorReviewData.GEInformation} />
          {sipEditorReviewData?.sipInformation?.sipType === 'Yes'?
        (<SSIPInformationShowSection
          value={sipEditorReviewData.sipInformation}
        />):(
          <SIPInformationShowSection
            value={sipEditorReviewData.sipInformation}
          />
        )}
          
          <ContentCommissioningApproachesShowSection
            value={sipEditorReviewData.CommissioningApproaches}
            emptyTip={true}
          />
          <CommentSection
            commentsContent={sipEditorReviewData.CommentsToTheProposal}
          />
        </SectionsContainer>
      </RevisePageBodyContainer>
      <DetailScrollTopButton
        boxProps={{ sx: { bottom: 300 } }}
        data-selenium-id='SIP_Revise_EditorPage-DetailScrollTopButton'
      />
      {/* <AddCommentsStickyBar /> */}
      {(decisionIsFold ? (
        <div style={buttonContainer}>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              height: '100%',
            }}
          >
            <Stack
              spacing={10}
              direction='row'
              data-selenium-id='SIP_DetailPage_DecisionSection-Fold-Stack'
            >

{isSave? 
(<SavedDecisionFoldBtn
                onClick={handleDecisionUnfold}
                id='DecisionButton'
                data-selenium-id='SIP_DetailPage_DecisionSection-Fold-DecisionButton'
                variant='contained'
                endIcon={<EndButtonArrowFold></EndButtonArrowFold>}
              >
                <FormattedMessage
                  id='siDetail.decision'
                  data-selenium-id='SIP_DetailPage_DecisionSection-Fold-DetailDecision'
                /></SavedDecisionFoldBtn>)
              : (<DecisionFoldBtn
                onClick={handleDecisionUnfold}
                id='DecisionButton'
                data-selenium-id='SIP_DetailPage_DecisionSection-Fold-DecisionButton'
                variant='contained'
                endIcon={<EndButtonArrowFold></EndButtonArrowFold>}
              >
                <FormattedMessage
                  id='siDetail.decision'
                  data-selenium-id='SIP_DetailPage_DecisionSection-Fold-DetailDecision'
                />
              </DecisionFoldBtn>)
          }

            </Stack>
          </div>
        </div>


      ) : (
        <></>

      ))}
        
        <MakeDecisionStickyBar 
        style={decisionIsFold ? { display: 'none' } : {}}
        handleSubmitDecision={handleSubmitDecision}
        setDecisionIsFold={setDecisionIsFold}
        setIsSave={setIsSave} />
      
        
      
    </Box>
  );
};

export default EditorReviewPage;

import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link as ReactLick } from 'react-router-dom';
import { styled } from '@mui/system';
import {
  Box,
  Checkbox,
  Divider,
  Link,
  TableSortLabel,
  Tooltip,
  tableCellClasses,
  Typography as MuiTypography,
  Grid,
  Stack,
} from '@mui/material';
import {
  MyTableHeader,
  StyledTableCell,
  TableFooter2,
} from '../../../components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleChangeGEPageSize,
  handleGEPageChangeAction,
  selectBatchGE,
  setGEOrderBy,
  unselectBatchGE,
} from '../../../actions/SIMT-SI/GuestEditor/GEAction';
import { FormattedMessage } from 'react-intl';
import CheckboxIcon from '@/assets/CheckBoxIcon.svg';
import CheckboxCheckedIcon from '@/assets/CheckBoxIconClick.svg';
import { setIsAllSelected } from '../../../actions/SeleteAction';
import TableSkeleton from '@/components/TableSkeleton';
import TooltipMoveArrow from '@/componentsphase2/CustomTooltip';
import NoDataMessage from '@/componentsphase2/NoDataMessage';
import SIPOverviewUncheckedIcon from '../../../assets/SIPOverviewUncheckedIcon.svg';
import SIPOverviewCheckedIcon from '../../../assets/SIPOverviewCheckedIcon.svg';
import CATJournalIndeterminate from '../../../assets/catjournalindeterminate.svg';
import { useRef } from 'react';
import {
  contextHeightChanged,
  tableHeightChanged,
} from '../../../actions/CommonAction';
import ScrollBarY from '../../../componentsphase2/ScrollBar/ScrollBarY';
import { formatDateString } from '../../../utils/commonUtils';
import {
  MulLineTooltipIfShow,
  TooltipIfShow,
} from '../../../componentsphase2/CustomTooltip';
import { TableBox2 } from "../../Report/ReportSpecificComponents/SpecialColorTableHeader";
import {loadGECodes, loadGEData} from "@/actions/SIMT-SI/GuestEditor/GuestEditorPage";
function createRow(
  id,
  label,
  isOrder = true,
  minWidth = '110px',
  maxWidth = '220px',
  show = true,
  stick = false,
  stickPX = '0px'
) {
  return { id, label, isOrder, minWidth, maxWidth, show, stick, stickPX };
}
const Typography = styled(MuiTypography)({
  lineHeight: '20px',
  fontWeight: '600',
  color: '#154AB6',
  '&:hover': {
    color: '#113D95',
    fontWeight: '700',
  },
});
const HoverTableRow = styled(TableRow)({
  // boxSizing: 'border-box',
  height: '64px',
  background: '#FFFFFF',
});
const NEWStyledTableCell = styled(StyledTableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    paddingLeft: '24px',
    paddingTop: '12px',
    paddingBottom: '12px',
    lineHeight: '20px',
    maxWidth: '240px',
  },
  '&.GE-Code-Column>div.MuiBox-root:only-child>div.MuiBox-root:first-child': {
    marginLeft: 'auto',
    marginRight: 'auto',
    transform: 'translateX(-15px)',
  }, //modified
}));
function HeaderLabel(props) {
  const { isOrder, children, ...other } = props;

  if (isOrder) {
    return (
      <TableSortLabel
        {...other}
        data-selenium-id={`GEPage_Overview-GETable-HeaderLabel${props.id}`}
      >
        {children}
      </TableSortLabel>
    );
  } else {
    return (
      <Box
        id={props.id}
        data-selenium-id={`GEPage_Overview-GEFilterDrawer-HeaderLabel${props.id}`}
      >
        {children}
      </Box>
    );
  }
}

const headerItems = [
  createRow('geCode', 'GE CODE', true, '200px', '200px', true, true, '66px'),
  createRow('title', 'TITLE', true, '160px', '160px', true),
  createRow('fullName', 'FULL NAME', true, '190px', '200px', true),
  createRow('gender', 'GENDER', true, '110px', '110px', true),
  createRow('institute', 'INSTITUTION', true, '160px', '160px', true),
  createRow('city', 'CITY', true, '180px', '180px', true),
  createRow('country', 'COUNTRY/REGION', true, '180px', '180px', true),
  createRow('primaryEmail', 'PRIMARY EMAIL', true, '240px', '240px', true),
  createRow(
    'researchKeywords',
    'RESEARCH KEYWORDS',
    false,
    '200px',
    '200px',
    true
  ),
  createRow('flags', 'FLAGS', false, '240px', '240px', true),
  createRow('createdate', 'Create Date', true, '180px', '180px', true),
  // createRow('createdate', 'Create Date', '180px', '180px', false, 'left'),
];

const GETable = React.forwardRef((props,ref) => {
  const dispatch = useDispatch();
  // 当前页是否被全部选中
  const [isDisplayedAllSelected, setIsDisplayedAllSelected] = useState(false);
  // 当前页是否被部分选中
  const [isDisplayedPartialSelected, setIsPartialSelected] = useState(false);

  const [loading, setLoading] = useState(true);
  const selector = state => {
    return {
      geData: state.GE.geData,
      geCodes: state.GE.geCodes,
      page: state.GE.page,
      pageSize: state.GE.pageSize,
      orderByType: state.GE.orderByType,
      searchGEContent: state.GE.searchGEContent,
      selectedGE: state.GE.selectedGE,
      reloadAllSelectionTrigger: state.GE.reloadAllSelectionTrigger,
      // filter
      // filterSelected: state.GE.filterSelected,
      useFilter: state.GE.useFilter,
      isAllSeleted: state.Selete.isAllSeleted,
      siderOpen: state.UserManage.siderOpen,
    };
  };

  const {
    geData,
    geCodes,
    page,
    pageSize,
    orderByType,
    searchGEContent,
    selectedGE,
    reloadAllSelectionTrigger,
    useFilter,
    isAllSeleted,
    siderOpen,
  } = useSelector(selector);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      await dispatch(
        loadGEData(
          page,
          pageSize,
          orderByType.order,
          orderByType.orderBy,
          searchGEContent,
          useFilter
        )
      );
    }
    fetchData().then(_r => {
      setLoading(false);
    });
  }, [dispatch, page, pageSize, orderByType, useFilter, searchGEContent]);

  useEffect(() => {
    dispatch(loadGECodes(useFilter));
    return () => {
      dispatch(unselectBatchGE(geCodes));
    };
  }, [useFilter, searchGEContent]);

  useEffect(() => {
    /**
     * 判断当前页面是部分选中还是全部选中
     * @return {boolean[]}  长度为2的数组[boolean, boolean]，
     *                      第0号元素表示是否部分选中，第1号元素表示全部选中，
     *                      二者可以同时为false，但不能同时为true
     */
    function intermediateOrAllSelected() {
      // let numCurrentPageSelect = 0; // 当前页面选中的数量
      // for (const ge of geData.result) {
      //   if (selectedGE.has(ge.geCode)) {
      //     numCurrentPageSelect += 1;
      //   }
      // }

      // if (numCurrentPageSelect === 0) {
      //   // 当前页面没有任何被选中的
      //   return [false, false];
      // } else if (numCurrentPageSelect === geData.result.length) {
      //   // 当前页面选中数量跟当前页的数据量一样，说明是全部选中，而不是部分选中
      //   return [false, true];
      // } else {
      //   // 中间值
      //   return [true, false];
      // }
      return [
        selectedGE.size > 0 && selectedGE.size != geCodes.length,
        selectedGE.size == geCodes.length,
      ];
    }

    const a = intermediateOrAllSelected();
    setIsPartialSelected(a[0]);
    setIsAllSelected(a[1]);
    // setTimeout(async () => { setLoading(false); }, 500)
  }, [selectedGE, geData, reloadAllSelectionTrigger]);

  const buildGEIdList = useData => {
    const geIdList = [];
    for (const geElement of useData) {
      geIdList.push(geElement.geCode);
    }
    return geIdList;
  };

  const handleToggleSelectAll = () => {
    // const geIdList = buildGEIdList(geData.result);
    if (isAllSeleted) {
      // 取消选择当前内容
      // dispatch(unselectBatchGE(geIdList));
      dispatch(unselectBatchGE(geCodes));
      dispatch(setIsAllSelected(false));
    } else {
      // 选中全部
      // dispatch(selectBatchGE(geIdList));
      dispatch(selectBatchGE(geCodes));
      dispatch(setIsAllSelected(true));
    }
  };

  const handleChangeOrder = orderBy => {
    let newOrder;
    if (orderByType.orderBy === orderBy) {
      // 如果当前的排序字段没有变化，则切换正反序
      newOrder = orderByType.order === 'desc' ? 'asc' : 'desc';
    } else {
      // 如果当前排序字段发生变化，则直接按照新的字段升序
      newOrder = 'asc';
    }
    dispatch(setGEOrderBy(newOrder, orderBy));
  };

  const setGESelection = (geId, newState) => {
    if (newState) {
      dispatch(selectBatchGE([geId]));
    } else {
      dispatch(unselectBatchGE([geId]));
    }
  };

  const handlePerPageChange = e => {
    dispatch(handleChangeGEPageSize(Number(e.target.value)));
  };

  const handlePageChange = (_event, newPage) => {
    dispatch(handleGEPageChangeAction(newPage));
  };

  const handleRequestSort = (_event, property) => {
    // console.log('AAAA', property, orderByType);
    const isAsc =
      orderByType.orderBy === property && orderByType.order === 'asc';
    dispatch(setGEOrderBy(isAsc ? 'desc' : 'asc', property));
  };
  let typeId = 0;
  const decideLines = (label, index, line, type) => {
    if (!type) {
      type = typeId;
      typeId = typeId + 1;
    }
    return (
      <MulLineTooltipIfShow
        id={`GEPage_Overview-GETable-TableContainer-Table-TableBody-TableRow${index}-${type}`}
        outerRef={containerBoxRef}
        line={line}
        label={label}
      />
    );
  };

  const containerBoxRef = useRef(null);

  return (
    <Box ref={ref}
      data-selenium-id={'GEPage_Overview-GETable'}>
      <Box id='TableBox' data-selenium-id={'GEPage_Overview-GETable-TableBox'}>
        <Grid item md={3} alignItems='center' justifyContent='center'>
          {/* <span id="tableFooterSelectedText" style={{ fontSize: "small", textAlign: 'center' }}> */}
          <Stack
            direction='row'
            justifyContent='start'
            alignItems='center'
            spacing={1}
            sx={{ marginBottom: '6px' }}
          >
            <span
              style={{
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '18px',
                color: '#262E35',
              }}
            >
              {/* <FormattedMessage
                id='table.selectedText'
                values={{ num: selected.length }}
              /> */}
              Selected Item(s):
            </span>
            <span
              style={{
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '18px',
                color: '#262E35',
              }}
            >
              {selectedGE.size}
            </span>
            {/*All： {geCodes.length}*/}
          </Stack>
          {/* </span> */}
        </Grid>
        <TableBox2
          dataList={[geData, loading]}
          tableKey={'GEOverview'}
          footerHeight={0}
          containerType={TableContainer}
          containerSelector={'.GEPage_Overview-GETable-TableContainer'}
          viewTag={'Table'}
          delay={300}
          sx={{
            overflowX: 'hidden',
            overflowY: 'hidden',
            position: 'relative',
            borderRadius: '8px 8px 0px 0px',
            border: '1px solid #DFE4E8',
            width: 'fit-content',
            padding: 0,
            '& .scroll-bar-container-box': {
              right: '3px',
              width: '15px',
              top: '3px',
              bottom: '3px',
            },
          }}
        >
          <TableContainer
            ref={containerBoxRef}
            component={Paper}
            sx={{
              width: siderOpen
                ? 'calc(100vw - 136px - 158px)'
                : 'calc(100vw - 136px)',
              // border: '1px solid #DFE4E8',
              ...(geData.count !== 0 && {
                height: 'calc(100vh - 329px)',
              }),
            }}
            className={'table-screen-size GEPage_Overview-GETable-TableContainer'}
            data-selenium-id={'GEPage_Overview-GETable-TableContainer'}
          >
            <Table
              stickyHeader
              sx={{
                letterSpacing: '0.01em',
                // color: '#262E35',
                minWidth: 'fit-content',
                minHeight: 'fit-content',
              }}
              aria-label='customized table'
              size='small'
              data-selenium-id={'GEPage_Overview-GETable-TableContainer-Table'}
            >
              <MyTableHeader
                hasCheckbox={true} // width is defined here
                order={orderByType.order}
                orderBy={orderByType.orderBy}
                onRequestSort={handleRequestSort}
                items={headerItems}
                handleSelectAll={handleToggleSelectAll}
                handleUnselectAll={handleToggleSelectAll}
                backgroundColor='#F1F3F5'
                color='#596A7C'
                CheckboxColor='wileyBlue2'
                letterSpacing='0.08em'
                checkboxIcon={
                  <SIPOverviewUncheckedIcon></SIPOverviewUncheckedIcon>
                }
                checkboxCheckedIcon={
                  <SIPOverviewCheckedIcon></SIPOverviewCheckedIcon>
                }
                checkboxindeterminateIcon={<CATJournalIndeterminate />}
                hasCheckboxStick={false}
                data-selenium-id={
                  'GEPage_Overview-GETable-TableContainer-Table-MyTableHeader'
                }
                ifIndeterminate={true}
                selected={selectedGE.size}
                allNum={geCodes.length}
                PaddingLeft={'24px'}
                Header0PaddingLeft={'0px'}
                hasCheckboxStick={true}
              />
              {loading ? (
                <TableBody
                  sx={{ backgroundColor: 'fff' }}
                  data-selenium-id={
                    'GEPage_Overview-GETable-TableContainer-Table-TableBody'
                  }
                >
                  <TableSkeleton num={9} colSpan={36} />
                </TableBody>
              ) : (
                <TableBody
                  data-selenium-id={
                    'GEPage_Overview-GETable-TableContainer-Table-TableBody'
                  }
                >
                  {!loading &&
                  // geData.result.map((row, index) => (
                  geData.result?.length > 0 ? (
                    geData.result.map((row, index) => {
                      return (
                        <HoverTableRow
                          key={row.geCode}
                          data-selenium-id={`GEPage_Overview-GETable-TableContainer-Table-TableBody-TableRow-${index}`}
                          sx={{
                            '&:hover': {
                              background:
                                row.alertGeFlagType === 'Flags-Severe Issue'
                                  ? '#FFEFEF'
                                  : row.alertGeFlagType === 'Flags-Mild Issue'
                                  ? '#FFF0DD'
                                  : '#E8EDFB',
                            },
                          }}
                        >
                          <NEWStyledTableCell
                            data-selenium-id={`GEPage_Overview-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell1`}
                            sx={{
                              paddingLeft: '15px !important',
                              paddingRight: '15px !important',
                              position: 'sticky',
                              left: '0px',
                              zIndex: 5,
                              background: 'inherit',
                            }}
                          >
                            <Checkbox
                              id={`gePageTableCheckbox${index}`}
                              icon={
                                <SIPOverviewUncheckedIcon></SIPOverviewUncheckedIcon>
                              }
                              checkedIcon={
                                <SIPOverviewCheckedIcon></SIPOverviewCheckedIcon>
                              }
                              disableRipple
                              // style={{ width: '42px', height: '18px' }}
                              color='wileyBlue2'
                              checked={selectedGE.has(row.geCode)}
                              onChange={event => {
                                setGESelection(
                                  row.geCode,
                                  event.target.checked
                                );
                              }}
                              //style={{paddingLeft:'16px'}}
                              sx={{
                                height: '18px',
                                '&:hover': {
                                  '& g rect:last-child': {
                                    stroke: '#596A7C',
                                  },
                                },
                                '& .MuiTouchRipple-root': {
                                  position: 'unset',
                                },
                                '&.Mui-checked:hover': {
                                  rect: {
                                    fill: '#113D95',
                                    stroke: '#113D95',
                                  },
                                },
                              }}
                            />
                          </NEWStyledTableCell>
                          {row.alertGeFlagType === 'Flags-Severe Issue' ? (
                            <NEWStyledTableCell
                              component='th'
                              scope='row'
                              data-selenium-id={`GEPage_Overview-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell2`}
                              sx={{
                                pt: '0 !important',
                                background: 'inherit',
                                position: 'sticky',
                                left: '66px',
                                zIndex: 5,
                              }}
                              className={'GE-Code-Column'}
                            >
                              <Box
                                sx={{
                                  pt: '0 !important',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: '100%',
                                }}
                              >
                                <Box
                                  sx={{
                                    boxSizing: 'border-box',
                                    height: '20px',
                                    width: '102px',
                                    p: '2px 12px',
                                    background: '#EE5350',
                                    textAlign: 'center',
                                    borderRadius: '0 0 4px 4px',
                                    color: '#fff',
                                    fontSize: '12px',
                                    fontFamily: 'Open Sans',
                                    fontWeight: 600,
                                    ml: '3px',
                                    lineHeight: '16px',
                                  }}
                                >
                                  SEVERE&nbsp;ISSUE
                                </Box>
                                <ReactLick
                                  style={{
                                    marginTop: '2px',
                                    flexGrow: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    textDecoration: 'none',
                                    color: '#154AB6',
                                  }}
                                  extra
                                  id={`gePageTableGeCodeLink${index}`}
                                  to={`/simt/auth/siRelated/ge/detail/${row.geCode}`}
                                >
                                  <Box
                                    sx={{
                                      fontWeight: 600,
                                    }}
                                  >
                                    <Typography>
                                      {decideLines(row.geCode, index, 1)}
                                    </Typography>
                                  </Box>
                                </ReactLick>
                              </Box>
                            </NEWStyledTableCell>
                          ) : row.alertGeFlagType === 'Flags-Mild Issue' ? (
                            <NEWStyledTableCell
                              component='th'
                              scope='row'
                              data-selenium-id={`GEPage_Overview-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell2`}
                              sx={{
                                background: 'inherit',
                                pt: '0 !important',
                                position: 'sticky',
                                left: '66px',
                                zIndex: 5,
                              }}
                              className={'GE-Code-Column'}
                            >
                              <Box
                                sx={{
                                  pt: '0 !important',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: '100%',
                                }}
                              >
                                <Box
                                  sx={{
                                    boxSizing: 'border-box',
                                    height: '20px',
                                    width: '102px',
                                    p: '2px 17.5px 2px 18.5px',
                                    background: '#FFB152',
                                    textAlign: 'center',
                                    borderRadius: '0 0 4px 4px',
                                    color: '#fff',
                                    fontSize: '12px',
                                    fontFamily: 'Open Sans',
                                    fontWeight: 600,
                                    ml: '3px',
                                    lineHeight: '16px',
                                  }}
                                >
                                  MILD&nbsp;ISSUE
                                </Box>
                                <ReactLick
                                  style={{
                                    marginTop: '2px',
                                    flexGrow: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    textDecoration: 'none',
                                    color: '#154AB6',
                                  }}
                                  extra
                                  id={`gePageTableGeCodeLink${index}`}
                                  to={`/simt/auth/siRelated/ge/detail/${row.geCode}`}
                                >
                                  <Box
                                    sx={{
                                      fontWeight: 600,
                                    }}
                                  >
                                    <Typography>
                                      {decideLines(row.geCode, index, 1)}
                                    </Typography>
                                  </Box>
                                </ReactLick>
                              </Box>
                            </NEWStyledTableCell>
                          ) : (
                            <NEWStyledTableCell
                              // component='th'
                              sx={{
                                position: 'sticky',
                                left: '66px',
                                zIndex: 5,
                                background: 'inherit',
                              }}
                              className={'GE-Code-Column'}
                              scope='row'
                              data-selenium-id={`GEPage_Overview-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell2`}
                            >
                              <ReactLick
                                style={{
                                  textDecoration: 'none',
                                  color: '#154AB6',
                                }}
                                extra
                                id={`gePageTableGeCodeLink${index}`}
                                to={`/simt/auth/siRelated/ge/detail/${row.geCode}`}
                              >
                                <Box
                                  sx={{
                                    fontWeight: 600,
                                  }}
                                >
                                  <Typography>
                                    {decideLines(row.geCode, index, 1)}
                                  </Typography>
                                </Box>
                              </ReactLick>
                            </NEWStyledTableCell>
                          )}

                          <NEWStyledTableCell
                            id={`gePageTableTitle${index}`}
                            align='left'
                            data-selenium-id={`GEPage_Overview-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell3`}
                          >
                            {decideLines(row.title, index, 1)}
                          </NEWStyledTableCell>
                          <NEWStyledTableCell
                            align='left'
                            sx={{ maxWidth: '160px' }}
                            data-selenium-id={`GEPage_Overview-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell4`}
                          >
                            <ReactLick
                              id={`gePageTableGeFullNameLink${index}`}
                              to={`/simt/auth/siRelated/ge/detail/${row.geCode}`}
                              style={{
                                textDecoration: 'none',
                                color: '#154AB6',
                              }}
                              extra
                            >
                              <TooltipMoveArrow
                                placement='top'
                                title={row.primaryEmail}
                                disableInteractive
                              >
                                <Box
                                  maxWidth='160px'
                                  sx={{
                                    weight: '200px',
                                    fontWeight: 600,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <Typography>{row.fullName}</Typography>
                                </Box>
                              </TooltipMoveArrow>
                            </ReactLick>
                          </NEWStyledTableCell>
                          <NEWStyledTableCell
                            id={`gePageTableGender${index}`}
                            data-selenium-id={`GEPage_Overview-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell5`}
                            align='left'
                          >
                            {decideLines(row.gender, index, 1)}
                          </NEWStyledTableCell>
                          <NEWStyledTableCell
                            align='left'
                            data-selenium-id={`GEPage_Overview-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell6`}
                          >
                            {decideLines(row.institute, index, 2)}
                          </NEWStyledTableCell>
                          <NEWStyledTableCell
                            id={`gePageTableCity${index}`}
                            data-selenium-id={`GEPage_Overview-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell7`}
                            align='left'
                          >
                            {decideLines(row.city, index, 2)}
                          </NEWStyledTableCell>
                          <NEWStyledTableCell
                            id={`gePageTableCountry${index}`}
                            data-selenium-id={`GEPage_Overview-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell8`}
                            align='left'
                          >
                            {decideLines(row.country, index, 2)}
                          </NEWStyledTableCell>
                          <NEWStyledTableCell
                            id={`gePageTablePrimaryEmail${index}`}
                            data-selenium-id={`GEPage_Overview-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell9`}
                            align='left'
                          >
                            {decideLines(row.primaryEmail, index, 1)}
                          </NEWStyledTableCell>
                          <NEWStyledTableCell
                            id={`gePageTableKeywords${index}`}
                            data-selenium-id={`GEPage_Overview-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell10`}
                            align='left'
                          >
                            {decideLines(
                              row.researchKeywordsList.join(', '),
                              index,
                              2,
                              'researchKeyWords'
                            )}
                          </NEWStyledTableCell>
                          <NEWStyledTableCell
                            id={`gePageTableFlags${index}`}
                            data-selenium-id={`GEPage_Overview-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell11`}
                            align='left'
                          >
                            {decideLines(
                              row.flags.join(', '),
                              index,
                              2,
                              'flags'
                            )}
                          </NEWStyledTableCell>
                          <NEWStyledTableCell
                            id={`gePageTableFlags${index}`}
                            data-selenium-id={`GEPage_Overview-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell12`}
                            align='left'
                          >
                            {decideLines(
                              formatDateString(row.createTime),
                              index,
                              2,
                              'createDate'
                            )}
                          </NEWStyledTableCell>
                        </HoverTableRow>
                      );
                    })
                  ) : (
                    <NoDataMessage
                      id='NoDataMessageAfterFiliter'
                      MarginTop={'10px'}
                      data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-NoDataMessageAfterFiliter'
                      message={
                        'No results were found. Please adjust your filters and try again.'
                      }
                    ></NoDataMessage>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </TableBox2>
        {geData.count === 0 ? (
          <Box
            display='flex'
            sx={{
              background: '#f1f3f5',
              pl: 0,
              pr: 0,
              width: siderOpen
                ? 'calc(100vw - 136px - 158px + 2px)'
                : 'calc(100vw - 136px + 2px)',
              borderRadius: '0px 0px 8px 8px',
              border: '1px solid #DFE4E8',
              borderTop: 'none',
              height: '42px',
            }}
            // paddingY='3px'
          ></Box>
        ) : (
          <TableFooter2
            width={
              siderOpen
                ? 'calc(100vw - 136px - 158px + 2px)'
                : 'calc(100vw - 136px + 2px)'
            }
            numSelected={selectedGE.size}
            Page={page}
            perPageNumber={pageSize}
            perPageList={[20, 50, 100]}
            count={geData.count}
            handlePerPageChange={handlePerPageChange}
            handlePageChange={handlePageChange}
            data-selenium-id={
              'GEPage_Overview-GETable-TableContainer-TableFooter2'
            }
          />
        )}
      </Box>
    </Box>
  );
});

export default GETable;

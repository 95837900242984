/**
 *  Purpose: to get filter counter according to cut-off date for Fiscal years
 * 
 * show JPM before Fiscal year of cut-off date (FY24)
 * show JPM, PD and PP Publishers before Fiscal year of cut-off date (FY24)
 * show PD and PP Publishers in Fiscal year of cut-off date (FY24)
 * 
 * @returns {[boolean, boolean]} [showJPM, showNewJournalRoles]
 * 
 */

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFyDistanceByDates } from './reportUtils';

export function useShowReportRolesByFyHook() {
  const { selectDate, cutOffDate } = useSelector(state => {
    return {
      selectDate: state.Report.selectDate,
      cutOffDate: state.Report.cutOffDate,
    };
  });

  const fyDistanceToCutOff = useMemo(() => {
    const fyDistanceToCutOff = getFyDistanceByDates(selectDate, cutOffDate);
    return fyDistanceToCutOff;
  }, [selectDate, cutOffDate]);

  // [showJPM, showNewJournalRoles]
  if (fyDistanceToCutOff > 0) {
    // After cut-off FY, show 'PD Publisher' and 'PP Publisher' only
    return [false, true];
  } else if (fyDistanceToCutOff < 0) {
    // Before cut-off FY, show 'JPM' only
    return [true, false];
  } else {
    // in cut-off FY, show all
    return [true, true];
  }
}

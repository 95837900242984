import {axios_instance} from "@/utils/axios_instance";
import {JOURNAL_CE_CAT_DATA, JOURNAL_SUBJECT_GROUP_DATA} from "@/actions/SIMT-Report/ReportJournal/ReportJournalAction";
import getAuth from "@/utils/getAuth";

export function getJournalCeCatData(selectedDate, isOA) {
    var selectDateYear = selectedDate.split('-')[0];
    var selectDateMonth = selectedDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            let res = await axios_instance.get(`/reports/journal/ce/${date}`, {
                params: {
                    isOA: isOA,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: JOURNAL_CE_CAT_DATA, data: res.data});
            } else {
                dispatch({type: JOURNAL_CE_CAT_DATA, data: {data: [], month: []}});
            }
        } catch (err) {
            dispatch({type: JOURNAL_CE_CAT_DATA, data: {data: [], month: []}});
        }
    };
}

export function getJournalSGData(selectedDate, isOA) {
    var selectDateYear = selectedDate.split('-')[0];
    var selectDateMonth = selectedDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            let res = await axios_instance.get(`/reports/journal/sg/${date}`, {
                params: {
                    isOA: isOA,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: JOURNAL_SUBJECT_GROUP_DATA, data: res.data});
            } else {
                dispatch({
                    type: JOURNAL_SUBJECT_GROUP_DATA,
                    data: {data: [], month: []},
                });
            }
        } catch (err) {
            dispatch({
                type: JOURNAL_SUBJECT_GROUP_DATA,
                data: {data: [], month: []},
            });
        }
    };
}
import { Box, Grid } from '@mui/material';
import { ColumnCentered, RowCentered } from '../CGTComponents/CommonStyle';
import { useEffect, useState } from 'react';

function CreateAndUpdateMain(props) {
  const { id, container } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  function getWidth() {
    if (windowWidth < 960) {
      return '100%';
    } else if (windowWidth < 1440) {
      return '50%';
    } else {
      return '680px';
    }
  }
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // 添加 resize 事件的监听器，并在组件卸载时进行清理
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box
      id={id}
      data-selenium-id={id}
      sx={{
        // width: getWidth(),
        width:'680px'
      }}
    >
      <Box sx={{ width: '100%' }}>{container}</Box>
    </Box>
  );
}

export default CreateAndUpdateMain;

import { getFyYearFromDate } from '@/utils/date_string';

/**
 * Compute the distance of Fiscal years of two dates
 * @param {string} date1 "YYYY-MM"
 * @param {string} date2 "YYYY-MM"
 * @returns {number}
 */
export const getFyDistanceByDates = (date1, date2) => {
  try {
    const FY1 = parseInt(getFyYearFromDate(date1).substring(2));
    const FY2 = parseInt(getFyYearFromDate(date2).substring(2));
    return FY1 - FY2;
  } catch (e) {
    console.log('getFyDistanceByDates exception: ' + e.message);
    return 0;
  }
};

/**
 * Replace zero values with null
 * @param data - data for graphic chart
 */
export const replaceZero = data => {
  if (data) {
    data.forEach((row, rowIndex) => {
      data[rowIndex] = row.map(value => (value == 0 ? null : value));
    });
  }
};

/**
 * Replace null with 0 for specific column
 * columnIndex is an array of column index
 */
export const replaceNullWithZero = (data, columnIndex) => {
  if (data) {
    data.forEach((row, rowIndex) => {
      if (columnIndex.includes(rowIndex)) {
        data[rowIndex] = row.map(value => (value == null ? 0 : value));
      }
    });
  }
};

/**
 * Merge data for fy trends diagram
 */
export const mergeFyTrendsLineData = (name, data) => {
  let mergedArray = new Array();
  for (let i = 0; i < name.length && i < data.length; i++) {
    mergedArray.push({
      name: name[i],
      value: data[i],
    });
  }
  return mergedArray;
};

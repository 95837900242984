import SmallRightModal from './SmallRightModal';
import LobbyIcon from './assets/LobbyIcon.svg';
import { Box } from '@mui/material';
import { StateButton } from '@/componentsphase2/StateButton';
import JournalFolder from './JournalFolder';
import LobbyErrorIcon from './assets/LobbyErrorIcon.svg';
import LoadingIcon from './assets/LoadingIcon';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { UntitledAlertBox } from '@/components/Alert/AlertBox';
import {getMarkingAIOption} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueDetail";
export default function CompetitiveJournalsModel(props) {
  const {
    open,
    handleClose,
    handleSave,

    //打开面板前的数值
    oldValue = [],
    optionsSearchData,
  } = props;
  const {
    MarketingAIOptions,
    MarketingAIOptionsLoading,
    MarketingAIOptionsError
  } = useSelector(state => state.SI);
  const {
    options,
    networkError,
    loading,
  } = {
    options: MarketingAIOptions,
    networkError: MarketingAIOptionsError,
    loading: MarketingAIOptionsLoading,
  };
  const dispatch = useDispatch();
  const [values, setValues] = useState(oldValue);
  const [successAlertShow, setSuccessAlertShow] = useState(false);
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);
  // useEffect(() => {
  //   console.log(values);
  // }, [values]);
  useEffect(() => {
    setValues(oldValue);
    dispatch(getMarkingAIOption(optionsSearchData));
  }, [open]);
  useEffect(() => {
    //检查values和oldValue是否相同
    let flag = true;
    if (values.length !== oldValue.length) {
      flag = false;
    } else {
      for (let i = 0; i < values.length; i++) {
        if (oldValue.indexOf(values[i]) === -1) {
          flag = false;
          break;
        }
      }
    }
    setAddButtonDisabled(flag);
  }, [values]);
  return (
    <>
      <SmallRightModal
        isRightModel={true}
        open={open}
        title='Competitive Journals'
        handleClose={() => {
          handleClose();
        }}
        width='504px'
        titleElseElement={
          !networkError && !loading && (
            <Box>
              <StateButton
                titleName='Add'
                onClick={() => {
                  handleSave(values.filter(item => item !== ''));
                  handleClose();
                  setSuccessAlertShow(true);
                }}
                disabled={addButtonDisabled}
              ></StateButton>
            </Box>
          )
        }
      >
        {
          networkError ?
            (<Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                }}>
                <LobbyErrorIcon
                  style={{
                    height: '65.5px',
                    width: '65.5px',
                  }} />
                <Box
                  sx={{
                    width: '315px',
                    color: '#596A7C',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}>Apologies, a network error has occurred. Please check your connection and try again.
                </Box>
              </Box>
            </Box>) :
            loading ?
              (<Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  <LobbyIcon
                    style={{
                      height: '65.5px',
                      width: '65.5px',
                    }} />
                  <Box
                    sx={{
                      color: '#596A7C',
                      fontSize: '14px',
                      fontWeight: '600',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '12px',
                    }}>
                    <LoadingIcon />
                    <Box>
                      Searching... Please wait.
                    </Box>
                  </Box>
                </Box>
              </Box>)
              : (<Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}>
                <Box
                  sx={{
                    padding: '16px 12px 16px 12px',
                    height: '61px',
                    gap: '12px',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Box
                    sx={{
                      width: '28px',
                      height: '28px',
                    }}>
                    <LobbyIcon style={{
                      height: '28px',
                      width: '28px',
                    }} />
                  </Box>
                  <Box sx={{
                    color: '#596A7C',
                    lineHeight: '18.9px',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}>The top 10 relevant competitive journals are listed below. Please select 3-5 items to add.</Box>
                </Box>
                {options.map(item => {
                  return <JournalFolder
                    title={item.journalName}
                    issn={item.issn}
                    info={item.relevantPublications}
                    number={`${Math.trunc(item.score * 100)}%`}
                    checked={values.includes(item.value)}
                    handleChange={(event) => {
                      const temp = [...values];
                      if (event.target.checked) {
                        temp.push(item.value);
                      } else {
                        temp.splice(temp.indexOf(item.value), 1);
                      }
                      setValues(temp);
                    }}
                    disabled={
                      oldValue.includes(item.value)
                    }
                  />;
                })}
              </Box>)}

      </SmallRightModal>
      <UntitledAlertBox
        open={successAlertShow}
        handleClose={() => {
          setSuccessAlertShow(false);
        }}
        severity='success'
        message='Competitive journals have been added successfully.'
      />
    </>
  );
}

import { useMemo } from 'react';
import Chart from '@/components/Chart/Chart';
import NoDataPage from '@/components/Chart/NoDataPage';
import { NormalChartOptions } from '../options';
import { SUBJECT_GROUP } from '../../constants';
import { getFYs } from '@/pages/Report/chartUtils';

const ChartSiPipelineNormal = ({
  width,
  hasZoom = false,
  hasTitle = false,
  selectedMonth,
  title,
  subTitle,
  dataForBar,
  isDownload = false,
}) => {
  
  const type = title;
  const level = subTitle;
  const isSubjectGroup = level && level.indexOf(SUBJECT_GROUP) >= 0;

  const chartOption = useMemo(() => {
    return new NormalChartOptions(type, isSubjectGroup, isDownload, width);
  }, [type, isSubjectGroup, isDownload, width]);

  const legendData = getFYs(selectedMonth);

  let options = useMemo(() => {

    if (isSubjectGroup) {
      // data include a line of title
      chartOption.setNumOfSG(dataForBar.length - 1 ?? 0);
    }

    chartOption
      .setZoom(hasZoom)
      .setTitle(hasTitle, title, subTitle)
      .setLegendData(legendData)
      .setDataSource(dataForBar);

    return chartOption.getOption();
  }, [chartOption, hasZoom, hasTitle, title, subTitle, dataForBar]);

  console.log('======options=====, ', options);

  const chartHeight = useMemo(() => {

    return chartOption.getHeightWithTitle();
  }, [hasTitle, title, subTitle, dataForBar]);

  // console.log("======chartHeight=====, ", chartHeight)

  return (
    <Chart
      options={options}
      height={chartHeight}
      width={width}
      isZoomLabelFontSize={false}
    />
  );
};

export default ChartSiPipelineNormal;

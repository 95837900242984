import { StrictMode, useState, type ReactNode, useEffect, type HTMLAttributes } from 'react';
import { Collapse, Box, CardActions, CircularProgress, Stack } from '@mui/material';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import { useUpdateEffect } from 'ahooks';
import styleModule from './AIScreeningReportPage.module.scss';
import PropTypes from 'prop-types';
interface CommonCollapseProps extends HTMLAttributes<HTMLElement> {
  readonly title: string;
  readonly editType?: boolean;
  readonly EditButton?: ReactNode;
  readonly id?: string;
  // readonly clickFetchData?: () => void;
  readonly fetchData?: () => Promise<void>;
  readonly EditComponent?: ReactNode;
  readonly ShowComponet?: ReactNode;
  // readonly children?: ReactNode;
  readonly loadingDataProp?: boolean;
}
const { freeze } = Object;
const EditType = freeze({
  Edit: true,
  Show: false,
});
export default function CommonCollapse (props: CommonCollapseProps) {
  const {
    title,
    editType = EditType.Show,
    EditButton = null,
    id = 'CommonCollapse',
    // clickFetchData = () => { },
    fetchData = () => Promise.resolve(),
    EditComponent = null,
    ShowComponet = null,
    children = null,
    loadingDataProp = false,  //method of application please refer to: @/pages/TestPages/CNmarketingDeleteModal.tsx
    ...others
  } = props;
  const [loadingData, setLoadingData] = useState(loadingDataProp);
  const [isFold, setIsFold] = useState(false);
  useEffect(() => {
    setLoadingData(true);
    fetchData().then(() => setLoadingData(loadingDataProp || false)).catch(console.error);
  }, []);
  useUpdateEffect(() => setLoadingData(loadingDataProp), [loadingDataProp]);
  const realLoadingData = loadingDataProp || loadingData;
  return (
    <StrictMode>
      <Box
        // mt={0.5}
        id={id}
        data-selenium-id={id}
        className={styleModule.CommonCollapse}
        {...others}
      >
        <Box>
          {/* 标题部分 */}
          <Stack
            sx={{
              mb: 1,
            }}
            direction='row'
            alignItems='center'
            spacing={1}
            id={`${id}-ContainerStack`}
            data-selenium-id={`${id}-ContainerStack`}
          >
            <Box
              onClick={() => {
                setIsFold(!isFold);
                // clickFetchData();
              }}
              id={`${id}-TitleFoldBtn`}
              data-selenium-id={`${id}-TitleFoldBtn`}
              sx={{
                cursor: 'pointer',
                maxHeight: '24px',
                '&>svg': {
                  maxWidth: '24px',
                  maxHeight: '24px',
                  borderRadius: '4px',
                  '>path': {
                    transition: 'transform .3s',
                    fill: '#113D95',
                    ...(isFold && { transform: 'rotate(180deg) translate(-28px,-28px)' })
                  }
                }
              }}
            >
              <SIDetailArrowDown
              // style={{
              // }}
              />
              <div
              />
            </Box>
            <Box
              id={`${id}-TitleBoxLeft`}
              data-selenium-id={`${id}-TitleBoxLeft`}
              className={styleModule.CommonCollapseTitle}
            >
              {title}
            </Box>
            {!realLoadingData && EditButton && <CardActions
              // sx={{ padding: 0 }}
              id={`${id}-CardActions`}
              data-selenium-id={`${id}-CardActions`}
            >
              {EditButton}
            </CardActions>}
          </Stack>
          {/* 主体部分 */}
          <Box
            id={`${id}-ContainerBox`}
            data-selenium-id={`${id}- ContainerBox`}
          >
            <Collapse
              in={isFold}
              timeout='auto'
              unmountOnExit
              id={`${id}-Collapse`}
              data-selenium-id={`${id}-Collapse`}
            >
              {realLoadingData ? (
                <Box
                  sx={{ display: 'flex', justifyContent: 'center' }}
                  id={`${id}-CircularProgressBox`}
                  data-selenium-id={`${id} -CircularProgressBox`}
                >
                  <CircularProgress
                    id={`${id}-CircularProgress`}
                    data-selenium-id={`${id}-CircularProgress`}
                  />
                </Box>
              ) : editType ? (
                EditComponent
              ) : (
                ShowComponet ?? children
              )}
            </Collapse>
          </Box>
        </Box>
      </Box>
    </StrictMode>
  );
}
CommonCollapse.propTypes = freeze({
  title: PropTypes.string.isRequired,
  editType: PropTypes.bool,
  EditButton: PropTypes.node,
  id: PropTypes.string,
  // clickFetchData: PropTypes.func,
  fetchData: PropTypes.func,
  EditComponent: PropTypes.node,
  ShowComponet: PropTypes.node,
  children: PropTypes.node,
  loadingDataProp: PropTypes.bool,
});
CommonCollapse.defaultProps = freeze({
  editType: EditType.Show,
  EditButton: null,
  id: 'CommonCollapse',
  // clickFetchData: () => { },
  fetchData: () => Promise.resolve(),
  EditComponent: null,
  ShowComponet: null,
  children: null,
  loadingDataProp: false,
});

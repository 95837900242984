import './index.css';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FullTable from './FullTable';
import { useDispatch, useSelector } from 'react-redux';

import EditAdmin from './EditSysAdmin';

import {
  openPop,
  setSuperAdminPop,
  setSuperAdminPopData,
} from '@/actions/PopupAction';
import { NewDesignedMain } from '../../components/Main';
import { FormattedMessage } from 'react-intl';
import { SIMTButton } from '@/components/StyledButton/SIMTButton';
import AddIcon from '@mui/icons-material/Add';
import {getAllAdminInfo} from "@/actions/SIMT-User/User/SuperAdmin";
import {getGroupInfo} from "@/actions/SIMT-User/User";
import {loadGroups} from "@/actions/SIMT-User/Config";

function SuperAdminManage(props) {
  let open = useSelector(state => state.Alert.open);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadGroups());
  }, [dispatch]);

  const handelCreateAdmin = () => {
    // console.log("SYSC1");
    dispatch(
      setSuperAdminPop({
        isCreate: true,
      })
    );
    dispatch(
      setSuperAdminPopData({
        userId: '',
        email: '',
        firstName: '',
        lastName: '',
        status: '',
        group: '',
        lock: '',
      })
    );
    dispatch(openPop());
  };

  return (
    <Box
      data-selenium-id='SuperAdminManage_OverView-Box'
      p={'30px 24px 26px 24px'}
    >
      <NewDesignedMain
        id='admin-manage-overview-paper'
        data-selenium-id='SuperAdminManage_OverView-Main'
        open={false}
        sx={{
          '& .MuiTableContainer-root': {
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            border: '1px solid #DFE4E8',
            boxSizing: 'border-box',
            '& .MuiTableCell-root.MuiTableCell-head': {
              backgroundColor: '#f1f3f5',
              textTransform: 'uppercase',
              letterSpacing: '0.08em',
              '& .MuiTableSortLabel-root': {
                color: '#596A7C',
              },
            },
          },
          '& #admin-manage-table-footer-box': {
            backgroundColor: '#f6f7f8',
            boxSizing: 'border-box',
          },
          '& .MuiTableCell-root.MuiTableCell-body': {
            fontSize: '14px',
            fontWeight: '400',
            color: '#262E35',
          },
        }}
      >
        <Box
          id='admin-manage-overview-top'
          data-selenium-id='SuperAdminManage_OverView-Main-TopBox'
        >
          <Stack
            data-selenium-id='SuperAdminManage_OverView-TopBox-Stack'
            justifyContent='space-between'
            alignItems='center'
            direction='row'
          >
            <Typography
              id='admin-manage-overview-title'
              data-selenium-id='SuperAdminManage_OverView-Title'
              style={{ display: 'flex' }}
              sx={{
                fontWeight: 600,
                fontSize: '22px',
                lineHeight: '125%',
                letterSpacing: '0.02em',
                color: '#262E35',
              }}
            >
              <FormattedMessage
                id={'superAdminManmage.adminOverviewTitle'}
                data-selenium-id={'superAdminManmage.adminOverviewTitle'}
              />
            </Typography>

            <Grid
              data-selenium-id='SuperAdminManage_OverView-Grid'
              item
              container
              sm={5}
              justifyContent='flex-end'
            >
              <SIMTButton
                id='superAdminCreateAdminButton'
                data-selenium-id='SuperAdminManage_OverView-CreateAdminButton'
                className='btnNewUser'
                variant='contained'
                onClick={handelCreateAdmin}
                startIcon={<AddIcon />}
              >
                <FormattedMessage
                  id={'superAdminManmage.adminNewAdminButtonText'}
                  data-selenium-id={'superAdminManmage.adminNewAdminButtonText'}
                />
              </SIMTButton>
              <EditAdmin />
            </Grid>
          </Stack>
        </Box>

        <FullTable />
      </NewDesignedMain>
    </Box>
  );
}

export default SuperAdminManage;

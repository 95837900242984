import { Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import EndButtonArrowFold from '../../../../assets/EndButtonArrowFold.svg';
import { DecisionFoldButtonWithSave } from './DecisionMyStyled';
import { useParams } from 'react-router-dom';
import CheckListButton from './Component/CheckListButton';
import styleModule from './styles/index.module.scss';
import Notice from '@/assets/notice.svg';
import GeNameMatchedFlag from '@/assets/GeNameMatchedFlag.svg';
import { isInitialReview } from '../utils/isSubmitted';
import { clickCancelButtonString } from '@/componentsphase2/FlaggedGESynchronizationAndVerificationTable';
import {
  getDecisionConfigList,
  getDecisionTypeList,
} from '@/actions/SIMT-SIP/SIP/StageDecision';
import { getSIPChecklistDetail } from '@/actions/SIMT-SIP/SIPChecklistSubmitted';
import { PANEL } from '../index.js';
import { PERMISSIONS } from '@/constant/permission';
import MyLocalStorage from '@/utils/MyLocalStorage';

/**
 * @param {object} props
 * @param {function} props.setPanelDisplayed
 * @param {string} props.sipStage
 * @param {boolean} props.isDecisionSaved
 * @param {boolean} props.isAssignScreenerSaved
 * @param {boolean} props.isDecisionButtonDisabled
 * @param {boolean} props.showAssignToScreenerBtn
 * @param {(e:boolean)=>void} props.setDecisionIsFold
 * @param {boolean} props.isProceeded
 * @param {string} props.id
 * @param {boolean} props.isGetSIPChecklistDetailLoading
 */
export default function DecisionFold(props) {
  const {
    setPanelDisplayed,
    sipStage,
    isDecisionSaved,
    isAssignScreenerSaved,
    isDecisionButtonDisabled = true,
    isProceeded = false,
    id = '',
    isGetSIPChecklistDetailLoading = true,
    showAssignToScreenerBtn = false,
    showDecisionBtn = false, // to show decision button
    showCheckListBtn = false,
  } = props;

  const dispatch = useDispatch();
  const { sipCode } = useParams();

  const sipStatus = useSelector(state => state?.SIP?.sipStatus);

  const userInfo = MyLocalStorage.getItem('userInfo');
  const userEmail = userInfo?.email;
  const userPermissions = localStorage.getItem('userPermissions');
  let userPermissionArray = localStorage.getItem('userPermissions').split(',');
  const hasSipWriteScreener = 
  userPermissionArray.includes(PERMISSIONS.SIP_WRITE_SCREENER) &&
  userEmail === sipStatus?.emailOfScreener;

  const buttonContainer = {
    width: '100%',
    height: '60px',
    background: '#FFFFFF',
    boxShadow: '0px -2px 5px rgba(0,0,0,0.1)',
    position: 'sticky', // 外部需要有一个容器让他贴靠底部
    bottom: 0,
    padding: '12px 32px',
    zIndex: '1200',
    overflow: 'hidden',
    paddingTop: '10px',
    paddingBottom: '9px',
  };

  const displayDicisionPanel = () => {
    setPanelDisplayed(PANEL.DECISION_PANEL);
    dispatch(getDecisionTypeList(sipCode));
    dispatch(getDecisionConfigList());
  };

  const handleAssignToScreenerUnfold = () => {
    setPanelDisplayed(PANEL.ASSIGN_SCREENER_PANEL);
    dispatch(getDecisionTypeList(sipCode));
    dispatch(getDecisionConfigList());
  };

  const clickCancelButton = useSelector(
    /**
     * @param {{readonly SIPChecklist:{readonly [clickCancelButtonString]:number;};}} state*/
    state => state?.SIPChecklist?.[clickCancelButtonString] ?? 0
  );

  if (
    Date.now() - clickCancelButton < 3e3 &&
    isInitialReview(sipStage) && // need modification
    sipCode
  ) {
    dispatch(
      getSIPChecklistDetail(
        sipCode,
        sipStage?.trim()?.split(' ')?.[0]?.toLowerCase()
      )
    );
  }


  return (
    <div style={buttonContainer}>
      <div
        id={id}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          height: '100%',
        }}
      >
        <Stack
          spacing={3}
          direction='row'
          data-selenium-id='SIP_DetailPage_DecisionSection-Fold-Stack'
        >
          {showAssignToScreenerBtn && (
            <DecisionFoldButtonWithSave
              id='PanelButton_Assign_to_Screener'
              data-selenium-id='SIP_DetailPage_Panel-Fold-PanelButton_Assign_to_Screener'
              variant='contained'
              saved={isAssignScreenerSaved}
              endIcon={<EndButtonArrowFold/>}
              onClick={handleAssignToScreenerUnfold}
            >
              <FormattedMessage
                id='sipDetail.decisionPanelElements.assignToScreener'
                data-selenium-id='SIP_DetailPage_DecisionSection-Fold-assignToScreener'
              />
            </DecisionFoldButtonWithSave>
          )}
          {showCheckListBtn && (
            <CheckListButton {...{ sipCode, sipStage }} />
          )}
          {showDecisionBtn && (<DecisionFoldButtonWithSave
            id='PanelButton_Decision'
            data-selenium-id='SIP_DetailPage_DecisionSection-Fold-PanelButton_Decision'
            variant='contained'
            saved={isDecisionSaved}
            disabled={isDecisionButtonDisabled}
            endIcon={<EndButtonArrowFold/>}
            onClick={displayDicisionPanel}
          >
            <FormattedMessage
              id='siDetail.decision'
              data-selenium-id='SIP_DetailPage_DecisionSection-Fold-DetailDecision'
            />
          </DecisionFoldButtonWithSave>)}
        </Stack>
      </div>
    </div>
  );
}

import {useState, useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
// Components
import Item from '@/pages/SIPage/Overview/Filter/component/Framework/Block/Item';
import Section from '@/pages/SIPage/Overview/Filter/component/Framework/Block/Section';
import SelectBox from './CommonComponents/SelectBox';
// Actions
import { setSelect } from '@/actions/SIMT-SIP/SIPFilter/SIPFilterAction';
import { loadData } from "@/actions/SIMT-SIP/SIPFilter/SIPFilterAction";
// Utils
import { activeStageList } from './utils';
import { blankTrim } from "@/utils/commonUtils";
import {loadGroups} from "@/actions/SIMT-User/Config";

export default function SecondSection(_props) {

  const [activeSIChecked, setActiveSIChecked] = useState(false);

  const { sipOrigin } = useParams();

  const { stageList, sipOrigins, sipFilterSelected, changeList, groupList } = useSelector(state => {
    return {
      stageList: state.SIPFilter.stageList,
      sipOrigins: state.SIPFilter.sipOrigin,
      sipFilterSelected: state.SIPFilter.sipFilterSelected,
      changeList: state.SIPFilter.changeList,
      groupList: state.SystemConfig.groupList,
    };
  });
  const NEW = "New";
  const IN_PROGRESS = "In Progress";
  const COMPLETED = "Completed";
  const sipChecklistStatus = [NEW, IN_PROGRESS, COMPLETED];
  
  const dispatch = useDispatch();

  const handleSelectedChange = (which, data) => {
    dispatch(setSelect(which, data));
  };

  const onLoad = async (which, searchContent, page = 1, pageSize = 5000, solicited) => {
    dispatch(loadData(which, blankTrim(searchContent), page, pageSize, solicited));
    dispatch(loadGroups());
  };

  const scaGroups = useMemo(() => {
    return groupList.map(group => group.value);
  }, [groupList]);

  useEffect(() => {
    if (activeStageList.length !== sipFilterSelected.stageSelected.length) {
      setActiveSIChecked(false);
      return;
    }
    for (let i = 0; i < activeStageList.length; i++) {
      if (!sipFilterSelected.stageSelected.includes(activeStageList[i])) {
        setActiveSIChecked(false);
        return;
      }
    }
    setActiveSIChecked(true);
  }, [sipFilterSelected.stageSelected]);

  useEffect(() => {
    onLoad('sipOrigin', '', 1, 20, sipOrigin === 'solicited');
    onLoad('stageList', '', 1, 20, sipOrigin === 'solicited');
  }, [changeList]);

  return (
    <Section id='second_section'>
      <Item
        id='SIP Stage'
        title='SIP Stage'
        needResetBtn={false}
      >
        <SelectBox
          id='SIPStageSelectBox'
          dropList={stageList}
          value={sipFilterSelected?.stageSelected}
          setValue={handleSelectedChange.bind(this, 'stage')}
          placeholder='Select Stage'
          dropListHeight='154px'
        />
      </Item>
      <Item
        id='SIP Origin'
        title='SIP Origin'
        needResetBtn={false}
      >
        <SelectBox
          id='SIPOriginSelectBox'
          dropList={sipOrigins}
          value={sipFilterSelected?.sipOriginSelected}
          setValue={handleSelectedChange.bind(this, 'sipOrigin')}
          placeholder='Choose Origin'
          dropListHeight='154px'
        />
      </Item>
      {/* SIP checklist status filter */}
      <Item
        id='SIP Checklist Status'
        title='SIP Checklist Status'
        needResetBtn={false}
      >
        <SelectBox
          id='sipChecklistStatusSelectBox'
          dropList={sipChecklistStatus}
          value={sipFilterSelected.sipChecklistStatusSelected}
          setValue={handleSelectedChange.bind(this, 'sipChecklistStatus')}
          placeholder='Select Checklist Status'
          dropListHeight='79px'
        />
      </Item>
      <Item
        id='SCA Group'
        title='SCA Group'
        needResetBtn={false}
      >
        <SelectBox
          id='SCAGroupSelectBox'
          dropList={scaGroups}
          value={sipFilterSelected.catGroupSelected}
          setValue={handleSelectedChange.bind(this, 'catGroup')}
          placeholder='Select Group'
          dropListHeight='140.5px'
        />
      </Item>
    </Section>
  );
}

import { Box } from '@mui/material';

export default function Header(props) {
  return (
    <Box
      id='createSIPPage_header'
      sx={{
        width: '100%',
        height: '44px',
        boxSizing: 'border-box',
        fontFamily: 'Open Sans',
        fontSize: '24px',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0em',
        color:'#262E35',
        backgroundColor:'#FFFFFF',
        paddingTop:'10px',
        paddingBottom:'10px',
        paddingLeft:'32px'
      }}
    >
        Create a Special Issue Proposal
    </Box>
  );
}

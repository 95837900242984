/**
 * 拖动排序的实现来自 https://codesandbox.io/s/oj3wz?file=/src/App.tsx
 * 只能尽量填充注释
 */

import { Box, List } from '@mui/material';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DraggableListItem from './DraggableComponents/DraggableListItem';
import { useDispatch } from 'react-redux';
import { setDropdownDisplay } from '@/actions/SIMT-User/Config/SystemConfigAction';
import { useState } from 'react';

// 暂未使用，期望定义为class，每个item都是其对象
const Item = {
  id: String,
  key: String,
  // add
  type: String,
  value: String,
};

// 重新排序
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// 可拖拽list
export default function DropdownDraggableList(props) {
  const { data, currSelectType } = props;
  const dispatch = useDispatch();

  /**
   * 当前编辑的item，用处是当点击编辑按钮的时候，上一个点击了编辑并仍处于编辑状态的会被关闭掉
   */
  const [currEdit, setCurrEdit] = useState(null);

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(data, source.index, destination.index);

    dispatch(setDropdownDisplay(newItems));
  };

  return (
    <Box
      data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownDraggableList-Box'
      // sx={{
      //   padding: '0 4px',
      //   maxWidth: '600px',
      // }}
    >
      <DragDropContext
        onDragEnd={onDragEnd}
        data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownDraggableList-Box-DragDropContext'
      >
        <Droppable
          droppableId='droppable-list'
          data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownDraggableList-Box-DragDropContext-Droppable'
        >
          {provided => (
            <List
              ref={provided.innerRef}
              {...provided.droppableProps}
              data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownDraggableList-Box-DragDropContext-Droppable-List'
            >
              {data.map((item, index) => (
                <DraggableListItem
                  data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownDraggableList-Box-DragDropContext-Droppable-List-DraggableListItem'
                  item={item}
                  index={index}
                  key={item.key}
                  // 此处传入type是为了edit的时候能够获取到当前的类型，然后执行刷新流程
                  type={currSelectType}
                  currEdit={currEdit}
                  setCurrEdit={setCurrEdit}
                />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}

import { Box, Stack, tooltipClasses } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import AIError from '@/assets/AIError.svg';
import AIInstitutional from '@/assets/AIInstitutional.svg';
import AINonInstitutional from '@/assets/AINonInstitutional.svg';
import ArrowUp from '@/assets/AIArrowUp.svg';
import ArrowDown from '@/assets/AIArrowDown.svg';
import BlueEllipse from '@/assets/BlueEllipse.svg';
import BlueArrowDown from '@/assets/BlueArrowDown.svg';
import BlueArrowUp from '@/assets/BlueArrowUp.svg';
import BlueArrowRight from '@/assets/BlueArrowRight.svg';
import { StateButton } from '@/componentsphase2/StateButton';
import SimpleTooltip from '@/componentsphase2/SimpleTooltip';
import { getGEBackgroundCheck } from '@/actions/SIPAIScreeningReportAction';
import CommonCollapse from '../components/CommonCollapse';
import AboutAI from '../components/AboutAI';

export default function GEBackgroundCheck({ sipCode }) {
  const [ges, setGes] = useState([]);

  const fetchData = async () => {
    const {
      data: { data },
    } = await getGEBackgroundCheck(sipCode);
    setGes(data);
  };

  return (
    <CommonCollapse
      title={'Guest Editors Background Check'}
      fetchData={fetchData}
      data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Collapse'
    >
      <AboutAI
        title='About Similarly Score'
        content='Relevance percentage score is calculated based on matching to
          researcher profiles in our system using AI- and Machine Learning based
          matching algorithms.'
      />
      <Stack
        spacing={1}
        data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-ProfileStack'
        sx={{ pb: '2px' }}
      >
        {ges?.map(ge => (
          <GEPanel {...ge.info} profiles={ge.matchingGEs} />
        ))}
      </Stack>
    </CommonCollapse>
  );
}

const GEPanel = props => {
  const {
    title,
    firstName,
    lastName,
    country,
    email,
    emailType,
    profiles,
  } = props;

  const [isFold, setIsFold] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const changeProfileShowNum = () => {
    setShowMore(prevState => !prevState);
  };
  const profilesToShow = useMemo(
    () => (showMore ? profiles : profiles?.slice(0, 3)),
    [showMore, profiles]
  );

  const InstitutionalTag = () => (
    <Box
      sx={{
        display: 'flex',
        padding: '2px 6px',
        alignItems: 'center',
        gap: '2px',
        borderRadius: '4px',
        border: '1px solid #84d4ca',
        background: '#ecfdf5',
      }}
      data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-InstitutionalTagBox'
    >
      <AIInstitutional data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-InstitutionalIcon' />
      <Box
        sx={{
          color: '#66cabe',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '20px',
        }}
        data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-InstitutionalTagText'
      >
        Institutional
      </Box>
    </Box>
  );

  const NonInstitutionalTag = props => {
    const { type } = props;
    return (
      <Box
        sx={{
          display: 'flex',
          padding: '2px 6px',
          alignItems: 'center',
          gap: '2px',
          borderRadius: '4px',
          border: '1px solid #ffb0ae',
          background: '#ffefef',
        }}
        data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-NonInstitutionalTagBox'
      >
        <AINonInstitutional data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-NonInstitutionalIcon' />
        <Box
          sx={{
            color: '#ff9898',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
          }}
          data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-NonInstitutionalTagText'
        >
          {type}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        borderRadius: '5px',
        overflow: 'hidden',
        boxShadow: '0px 2px 8px 0px rgba(38, 46, 53, 0.10)',
      }}
      data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-ContainerBox'
    >
      <Box
        sx={{
          padding: '16px 32px',
          background: '#f1f3f5',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
        }}
        data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-TitleBox'
      >
        <Stack
          spacing='4px'
          sx={{ fontSize: '16px', fontWeight: 400, lineHeight: 'normal' }}
          data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-TitleBox-LeftInfoStack'
        >
          <Box
            sx={{
              display: 'flex',
              color: '#262e35',
            }}
            data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-TitleBox-LeftInfoStack-TopBox'
          >
            <Box
              sx={{ mr: '4px' }}
              data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-TitleBox-LeftInfoStack-TopBox-JobTitle'
            >
              {title ?? 'N/A'}
            </Box>
            <Box
              sx={{ mr: '6px', fontWeight: 700 }}
              data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-TitleBox-LeftInfoStack-TopBox-Name'
            >{`${firstName} ${lastName}`}</Box>
            {country ?? 'N/A'}
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
              height: '24px',
            }}
            data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-TitleBox-LeftInfoStack-BottomBox'
          >
            <Box
              sx={{ color: '#596a7c' }}
              data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-TitleBox-LeftInfoStack-BottomBox-Email'
            >
              {email}
            </Box>
            <Box data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-TitleBox-LeftInfoStack-BottomBox-IsInstitutionalTag'>
              {emailType ==='Institutional' ? <InstitutionalTag /> : <NonInstitutionalTag type={emailType}/>}
            </Box>
          </Box>
        </Stack>
        <Box
          sx={{ cursor: 'pointer', px: '2px', height: '24px' }}
          onClick={() => setIsFold(prevState => !prevState)}
          data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-TitleBox-RightFoldBox'
        >
          {isFold ? <ArrowDown /> : <ArrowUp />}
        </Box>
      </Box>
      {!isFold && (
        <>
          {!profiles?.length > 0 && (
            <Box
              sx={{
                mt: '10px',
                py: '24px',
                px: '32px',
                color: '#596a7c',
                fontSize: '20px',
                fontWeight: 600,
                lineHeight: '24px',
              }}
            >
              No mathcing profile for this researcher.
            </Box>
          )}
          {profilesToShow?.map((profile, i) => (
            <Profile {...profile} key={i} />
          ))}
          {profiles?.length > 3 && (
            <Box
              sx={{ mt: '10px', px: '50px' }}
              data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-ViewTop10ButtonBoxContainer'
            >
              <Box
                onClick={changeProfileShowNum}
                data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-GEPanel-ViewTop10ButtonBox'
              >
                {showMore ? (
                  <StateButton
                    titleName='View Less'
                    isPrimary={false}
                    endIcon={<BlueArrowUp />}
                  />
                ) : (
                  <StateButton
                    titleName='View top 10 matching profiles'
                    isPrimary={false}
                    endIcon={<BlueArrowDown />}
                    sx={{ textTransform: 'unset' }}
                  />
                )}
              </Box>
            </Box>
          )}
          <Box height='16px' />
        </>
      )}
    </Box>
  );
};

const Profile = props => {
  const {
    name,
    publicationCount,
    unusualPublicationsFlag,
    topPublications,
    areasOfExpertise,
    hIndex,
    retractedPublicationsCount,
    bestMatch,
    score,
    pkgJournalLink,
  } = props;

  const InfoBlock = ({ title, valueFC, forId }) => (
    <Box
      sx={{ display: 'flex', gap: '8px' }}
      data-selenium-id={`SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-InfoBlock-${forId}`}
    >
      <Box
        sx={{ width: '224px', flexShrink: 0, color: '#596a7c' }}
        data-selenium-id={`SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-InfoBlockKey-${forId}`}
      >
        {title}:
      </Box>
      <Box
        sx={{ flexGrow: 1 }}
        data-selenium-id={`SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-InfoBlockValue-${forId}`}
      >
        {valueFC()}
      </Box>
    </Box>
  );

  const [showMore, setShowMore] = useState(false);
  const changePubShowNum = () => {
    setShowMore(prevState => !prevState);
  };
  const pubToShow = useMemo(
    () => (showMore ? topPublications : topPublications?.slice(0, 3)),
    [topPublications, showMore]
  );

  return (
    <Box
      sx={{
        p: '16px 32px',
        '@media screen and (min-width: 1080px)': {
          px: '50px',
        },
      }}
      data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-ContainerBox'
    >
      <Stack
        spacing='10px'
        data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-Stack'
      >
        <Box
          sx={{ display: 'flex', height: '24px' }}
          data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-TitleBox'
        >
          <Box
            sx={{
              color: '#154ab6',
              fontSize: '16px',
              fontWeight: 600,
              cursor: 'pointer',
            }}
            onClick={() => window.open(pkgJournalLink)}
            data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-TitleBox-NameBox'
          >
            {name}
          </Box>
          {bestMatch && (
            <Box
              sx={{
                ml: '12px',
                p: '6px 12px',
                borderRadius: '35px',
                background: '#4c81eb',
                color: '#fff',
                fontSize: '10px',
                fontWeight: 600,
                lineHeight: '120%',
              }}
              data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-TitleBox-BestMatchBox'
            >
              Best Match
            </Box>
          )}
          <Box
            sx={{
              ml: 'auto',
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
              color: '#596a7c',
              fontSize: '14px',
              lineHeight: '20px',
            }}
            data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-TitleBox-SimilarityScoreBox'
          >
            <Box
              width='100px'
              data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-TitleBox-SimilarityScoreKey'
            >
              Similarly Score:
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: '2px 12px',
                borderRadius: '4px',
                border: '1px solid #bcc5cf',
                background: '#f6f7f8',
                fontWeight: 600,
                letterSpacing: '0.14px',
              }}
              data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-TitleBox-SimilarityScoreValue'
            >
              {(score * 100).toFixed(1)}%
            </Box>
          </Box>
        </Box>
        <Stack
          spacing={1}
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
          }}
          data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-InfoBlock-Stack'
        >
          <InfoBlock
            forId='NumOfPub'
            title='Number of Publications'
            valueFC={() => (
              <Box
                sx={{ display: 'flex', gap: '2px', color: '#262e35' }}
                data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-NumOfPubBlock-ContainerBox'
              >
                <Box data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-NumOfPubBlock-NumberBox'>
                  {publicationCount}
                </Box>
                {unusualPublicationsFlag && (
                  <SimpleTooltip
                    title='This researcher published more than 20 publications in a single year.'
                    placement='top'
                    sx={{
                      [`& .${tooltipClasses.tooltip}`]: {
                        textAlign: 'center',
                        marginBottom: '8px !important',
                        maxWidth: '277px',
                      },
                    }}
                  >
                    <AIError data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-NumOfPubBlock-ErrorTag' />
                  </SimpleTooltip>
                )}
              </Box>
            )}
          />
          <InfoBlock
            forId='Top10Pub'
            title='Top 10 Recent Publications'
            valueFC={() => {
              return (
                <Box data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-Top10PubBlock-ContainerBox'>
                  <Stack
                    spacing={1}
                    data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-Top10PubBlock-Stack'
                  >
                    {pubToShow?.map(pub => {
                      const ref = useRef(null);
                      const [open, setOpen] = useState(false);
                      return (
                        <Box
                          key={pub.title}
                          sx={{
                            color: '#154ab6',
                            display: 'flex',
                            gap: '4px',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={() => window.open(pub.doiLink)}
                          data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-Top10PubBlock-Stack-Box'
                        >
                          <BlueEllipse
                            data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-Top10PubBlock-BlueEllipse'
                            style={{ flexShrink: 0 }}
                          />
                          <SimpleTooltip
                            title={pub.title}
                            placement='top'
                            sx={{
                              [`& .${tooltipClasses.tooltip}`]: {
                                marginBottom: '8px !important',
                                maxWidth: '277px',
                              },
                            }}
                            open={open}
                            onOpen={() => {
                              if (
                                ref.current.scrollHeight >
                                ref.current.clientHeight
                              )
                                setOpen(true);
                            }}
                            onClose={() => {
                              if (
                                ref.current.scrollHeight >
                                ref.current.clientHeight
                              )
                                setOpen(false);
                            }}
                          >
                            <Box
                              sx={{
                                display: '-webkit-box',
                                '-webkit-box-orient': 'vertical',
                                overflow: 'hidden',
                                '-webkit-line-clamp': '2',
                              }}
                              ref={ref}
                            >
                              {pub.title}
                            </Box>
                          </SimpleTooltip>
                        </Box>
                      );
                    })}
                    {topPublications?.length > 3 && (
                      <Box
                        sx={{
                          pb: '6px',
                        }}
                        onClick={changePubShowNum}
                        data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-Top10PubBlock-ViewMoreButton'
                      >
                        {showMore ? (
                          <StateButton
                            titleName='View Less'
                            endIcon={<BlueArrowUp />}
                            isInnerText={true}
                            sx={{ minWidth: 'unset', width: 'fit-content' }}
                          />
                        ) : (
                          <StateButton
                            titleName='View All'
                            endIcon={<BlueArrowDown />}
                            isInnerText={true}
                            sx={{ minWidth: 'unset', width: 'fit-content' }}
                          />
                        )}
                      </Box>
                    )}
                  </Stack>
                </Box>
              );
            }}
          />
          <InfoBlock
            forId='AreasOfExpertise'
            title='Areas of Expertise'
            valueFC={() => (
              <Box
                sx={{ color: '#262e35' }}
                data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-AreasOfExpertiseBlockBox'
              >
                {areasOfExpertise?.map(item => item.name).join(';')}
              </Box>
            )}
          />
          <InfoBlock
            forId='HIndex'
            title='H-index'
            valueFC={() => (
              <Box
                sx={{ color: '#262e35' }}
                data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-HIndexBlockBox'
              >
                {hIndex}
              </Box>
            )}
          />
          <InfoBlock
            forId='RetractionHistory'
            title='Retraction History'
            valueFC={() =>
              retractedPublicationsCount === 0 ? (
                'N/A'
              ) : (
                <Box
                  sx={{ display: 'flex', alignItems: 'center', height: '20px' }}
                  data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-RetractionHistoryBlock-ContainerBox'
                >
                  <Box
                    sx={{
                      p: '0 8px',
                      borderRadius: '4px',
                      border: '1px solid #ffb0ae',
                      background: '#ffefef',
                      color: '#ee5350',
                      fontWeight: 600,
                      letterSpacing: '0.14px',
                    }}
                    data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-RetractionHistoryBlock-RetractionHistoryNumBox'
                  >
                    {retractedPublicationsCount}
                  </Box>
                  <Box
                    sx={{ ml: '4px', color: '#262e35' }}
                    data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-RetractionHistoryBlock-PlainTextBox'
                  >
                    paper(s) retracted
                  </Box>
                  <Box
                    sx={{
                      ml: '8px',
                    }}
                    onClick={() => window.open(pkgJournalLink)}
                    data-selenium-id='SIPPage_AIScreeningReportPage-GEBackgroundCheck-Profile-RetractionHistoryBlock-LinkBox'
                  >
                    <StateButton
                      titleName='Publications List'
                      endIcon={<BlueArrowRight />}
                      isInnerText={true}
                      sx={{
                        minWidth: 'unset',
                        width: 'fit-content',
                        display: 'flex',
                        alignItems: 'center',
                        height: '14px',
                      }}
                    />
                  </Box>
                </Box>
              )
            }
          />
        </Stack>
      </Stack>
    </Box>
  );
};

import { TextField, Box } from '@mui/material';
import React,{ useEffect, useState } from 'react';
import BaseAutocomplete from './BaseAutocomplete';
import { calcHeight } from './Utils';
import AutocompleteDropDownIcon from '@/assets/dropdownIcon_8_457.svg';
import { AllCentered } from './CommonStyle';
import { ColumnCentered } from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/CommonStyle';
import AutocompleteArrowIcon from './SVGComponents/AutocompleteArrowIcon.svg';
import AutocompleteClearIcon from './SVGComponents/AutocompleteClearIcon.svg';
import { useDispatch } from 'react-redux';
import { trimItemFilds } from '@/utils/commonUtils';
import { AddSelectItem } from '../SelectBox/SelectInput';
import { GetPopperBottomContext } from './CommonContext';
export default function CreateSISinple(props) {
  const {
    id,
    value,
    options = [], //下拉数据
    placeholder,
    isErr = false, //判断是否存在错误
    isEmpty = true, //判断输入框的是否为空
    config = {}, //用于控制BaseAutocompelte的输入，具体什么输入建议查看BaseAutocomplete或者阅读下面的代码,
    //模糊查询的相关参数
    defaultQuery = false, // 类型： bool， true：使用Autocomplete的默认的模糊查询行为；false： 不使用; 默认值 ：false
    loadOptionAction, //后端查询的接口函数
    renderOption, //自定义下拉框中的内容，需要注意的是，可选项必须使用 li，否则你无法使用默认的样式，你需要自己提供样式
    getOptionLabel, //显示在输入框内的内容，如果不提供，输入框的内容是 option 本身
    getListOptionLabel, //显示在li中的内容，如果不提供，li的内容取决于getOptionLabel,
    getInputValueLabel, //控制value->inputValue的一个映射，因为getOptionLabel获得的字符串可能无法被后端查询到，所以我们需要重新对inputValue做映射
    onChange, //选中可选项后的回调函数,需要注意，它的函数参数应该是（e，newValue）
    disableNewItem = true, //是否禁用添加新item到数据库的功能，类型：bool，true：禁用；false：不禁用。默认值：true
    addNewItemManager = {},
    setError = () => {}, //将组件的error传递到外面
    needClearInputValue = false, //bool 类型， false: 在onchange之后，下拉框会根据onchange选择的值进行加载数据；true：在onchange后，下拉框会根据''进行加载数据
    readOnly = false, //bool 类型, false:输入框是可以输入的，true：输入框只支持点击，不支持输入，需要注意的是，必须要保证id是在当前页面是独一无二的
    // ...other
  } = props;

  const dispatch = useDispatch();

  const DELAY_LOAD_TIME = 300;

  const [autocompleteError, setAutocompleteError] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [delayLoadTimer, setDelayLoadTimer] = useState(null);
  const [optionsToShow, setOptionsToShow] = useState([]);
  const [isAddSelectItemShow, setIsAddSelectItemShow] = useState(false);

  const delayLoadOptions = value => {
    if (defaultQuery) return;

    // 停止输入 ${DELAY_LOAD_TIME}ms 后开始查询
    if (delayLoadTimer) clearTimeout(delayLoadTimer);
    setDelayLoadTimer(
      setTimeout(() => {
        // 加载
        dispatch(loadOptionAction(trimItemFilds(value)));
      }, DELAY_LOAD_TIME)
    );
  };

  useEffect(() => {
    delayLoadOptions(inputValue);
  }, [inputValue]);

  const {
    newData,
    itemExist,
    dataGet,
    addNewOption,
    afterAddNewOption,
    afterDateGetSucess,
  } = addNewItemManager;

  function In(value, dataList) {
    let isIn = dataList.some(item => {
      if (!item) return false;
      return value === defaultGetListOptionLabel(item);
    });
    return isIn;
  }

  function getNewOptions() {
    let newOptions = options.concat();
    newOptions.push(null);
    return newOptions;
  }

  function defaultGetOptionLabel(option) {
    if (option === null) return '';
    if (getOptionLabel) {
      return getOptionLabel(option);
    } else {
      return option;
    }
  }

  function defaultGetListOptionLabel(option) {
    if (option === null) return 'qwerty-add-select-item';
    if (getListOptionLabel) {
      return getListOptionLabel(option);
    } else {
      return defaultGetOptionLabel(option);
    }
  }

  function defaultGetInputValueLabel(option){
    if(option === null) return null;
    if(getInputValueLabel){
      return getInputValueLabel(option);
    }else{
      return defaultGetOptionLabel(option);
    }
  }

  const defaultRenderOption = (props, option) => {
    if (option === null) {
      if (!disableNewItem && !In(inputValue, options) && inputValue !== '') {
        setIsAddSelectItemShow(true);
        return (
          <AddSelectItem
            handleAdd={addNewOption.bind(this, trimItemFilds(inputValue))}
            value={trimItemFilds(inputValue)}
            key='qwerty-add-select-item'
            sx={{ marginTop: options.length === 0 ? '0px' : '-2px' }}
          />
        );
      }
      setIsAddSelectItemShow(false);
      return null;
    }
    if (defaultGetListOptionLabel(option) === '') return null;
    return <li {...props}>{defaultGetListOptionLabel(option)}</li>;
  };

  useEffect(() => {
    // 当新获得新的有效 item 时，将其添加至 optionsToShow ，
    // 同时存入缓存，等待 option 刷新

    if (dataGet === 'success') {
      onChange(null, newData);

      afterDateGetSucess(newData);

      if (loadOptionAction !== undefined) {
        dispatch(loadOptionAction(trimItemFilds(inputValue)));
      }
    }

    if (dataGet === 'success' || dataGet === 'error') {
      if (afterAddNewOption !== undefined) afterAddNewOption();
    }
  }, [dataGet]);

  useEffect(() => {
    if (!disableNewItem) {
      setOptionsToShow(getNewOptions());
    }
  }, [options]);

  function filterOptions() {
    let newOptions = [];
    for (let i = 0; i < options.length; i++) {
      let option = options[i];
      if (
        defaultGetListOptionLabel(option)
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      ) {
        newOptions.push(option);
      }
    }

    return newOptions;
  }

  useEffect(() => {
    setAutocompleteError(isErr);
    setError(isErr);
  }, [isErr]);

  useEffect(() => {
    let input = document.getElementById(`${id}_aotucomplete`);
    if (input && readOnly) {
      input.readOnly = true;
    }
  }, []);

  const setPopperBottom = React.useContext(GetPopperBottomContext).setValue;

  return (
    <>
      <BaseAutocomplete
        // {...other}
        id={`${id}_aotucomplete`}
        data-selenium-id={`${id}_aotucomplete`}
        value={value}
        options={disableNewItem ? options : optionsToShow}
        disableClearable={
          config.popupIcon !== null ? true : config?.disableClearable ?? false
        }
        multiple={false}
        // {...(getOptionLabel ? {getOptionLabel:defaultGetOptionLabel}: {})}
        getOptionLabel={defaultGetOptionLabel}
        onInputChange={(event, newValue) => {
          setInputValue(newValue);
        }}
        onOpen={() => {
          setInputValue(defaultGetInputValueLabel(value));
          if(defaultQuery || needClearInputValue){
            setInputValue('');
          }
          setTimeout(() => {
            let myPopper = document.getElementById('my-popper');
            if (myPopper) {
              const transformValue = myPopper.style.transform;
              const x = parseFloat(
                transformValue.split('(')[1].split('px,')[0]
              );
              const y = parseFloat(
                transformValue.split(', ')[1].split('px)')[0]
              );
              const height =
                !disableNewItem && isAddSelectItemShow
                  ? calcHeight(options, 6, 6, 20, 6, 48)
                  : calcHeight(
                    defaultQuery ? filterOptions() : options,
                    6,6,20,6,0
                  );
              const bottom = y + 12 + height;
              setPopperBottom(bottom);
            }
          });
        }}
        onChange={(event, newValue) => {
          onChange(event, newValue);
          //似乎执行完onChange后，Autocomplete会自动修改 inputValue的值
          setInputValue(defaultGetInputValueLabel(newValue));
          if (defaultQuery || needClearInputValue) {
            setInputValue('');
          }
        }}
        onClose={() => {
          if (value && !needClearInputValue) {
            delayLoadOptions(trimItemFilds(defaultGetInputValueLabel(value)));
          } else {
            delayLoadOptions('');
          }
          setPopperBottom(null);
        }}
        onFocus={() => {
          setAutocompleteError(false);
          setError(false);
          if (props.onFocus) {
            props.onFocus();
          }
        }}
        onBlur={() => {
          setAutocompleteError(isErr);
          setError(isErr);
          if (props.onBlur) {
            props.onBlur();
          }
        }}
        renderInput={params => (
          <TextField
            id={`${id}_aotucomplete_textfield`}
            data-selenium-id={`${id}_aotucomplete_textfield`}
            placeholder={placeholder}
            error={autocompleteError}
            {...params}
          />
        )}
        renderOption={renderOption ?? defaultRenderOption}
        deleteIcon={config?.clearIcon ?? <AutocompleteClearIcon />}
        popupIcon={
          config.popupIcon === undefined ? (
            <AutocompleteArrowIcon />
          ) : (
            config.popupIcon
          )
        }
        focusPopusIcon={config?.focusPopusIcon ?? null}
        dropListEndAttachment={config?.dropListEndAttachment ?? <></>}
        dropListNoOptions={config?.dropListNoOptions}
        {...(defaultQuery ? {} : { filterOptions: x => x })}
        endAttchmentProps={{
          endAttchmentSX: {
            top: '12px',
            right: '12px !important',
            display: 'flex',
            flexDirection: 'row',
            gap:
              config.popupIcon === null || config.disableClearable
                ? '0px'
                : '4px',
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
            ...(config?.endAttchmentProps?.endAttchmentSX ?? {}),
          },
          popupIconSX: {
            backgroundColor: '#FFFFFF',
            padding: '0px',
            ...(config?.endAttchmentProps?.popupIconSX ?? {}),
          },
          popupIconHoverSX: {
            backgroundColor: '#DFE4E8',
            borderRadius: '2px',
            ...(config?.endAttchmentProps?.popupIconHoverSX ?? {}),
          },
          popupIconActiveSX: {
            backgroundColor: '#DFE4E8',
            borderRadius: '2px',
            ...(config?.endAttchmentProps?.popupIconActiveSX ?? {}),
          },
          deleteIconSX: {
            padding: '0px',
            ...(config?.endAttchmentProps?.deleteIconSX ?? {}),
          },
          deleteIconHoverSX: {
            backgroundColor: '#FFFFFF',
            ...(config?.endAttchmentProps?.deleteIconHoverSX ?? {}),
          },
          deleteIconActiveSX: {
            backgroundColor: '#FFFFFF',
            ...(config?.endAttchmentProps?.deleteIconActiveSX ?? {}),
          },
        }}
        outlinedInputProps={{
          outlinedInputSX: {
            padding: '11px 12px !important',
            cursor: readOnly ? 'pointer' : 'text',
            ...(config?.outlinedInputProps?.outlinedInputSX ?? {}),
          },
          inputSX: {
            root: {
              height: '18px',
              margin: '0px !important',
              cursor: readOnly ? 'pointer' : 'text',
              ...(config?.outlinedInputProps?.inputSX?.root ?? {}),
            },
            placeholder: {
              ...(config?.outlinedInputProps?.inputSX?.placeholder ?? {}),
            },
          },
          notchedOutlineSX: {
            border: isEmpty ? '1px solid #DFE4E8' : '1px solid #98A7B6',
            ...(config?.outlinedInputProps?.notchedOutlineSX ?? {}),
          },
          notchedOutlineHoverSX: {
            ...(config?.outlinedInputProps?.notchedOutlineHoverSX ?? {}),
          },
          notchedOutlineFocusSX: {
            border: '2px solid #154AB6 !important',
            ...(config?.outlinedInputProps?.notchedOutlineFocusSX ?? {}),
          },
          errorSX: {
            notchedOutlineSX: {
              ...(config?.outlinedInputProps?.errorSX?.notchedOutlineSX ?? {}),
            },
            notchedOutlineHoverSX: {
              ...(config?.outlinedInputProps?.errorSX?.notchedOutlineHoverSX ??
                {}),
            },
            notchedOutlineFocusSX: {
              ...(config?.outlinedInputProps?.errorSX?.notchedOutlineFocusSX ??
                {}),
            },
          },
        }}
        dropListProps={{
          listBoxSX: {
            paddingBottom: '6px',
            ...(config?.dropListProps?.listBoxSX ?? {}),
          },
          listBoxUlSX: {
            root: {
              padding: '6px 0px 0px 0px',
              ...(config?.dropListProps?.listBoxUlSX?.root ?? {}),
            },
            li: {
              color: '#596A7C',
              ...(config?.dropListProps?.listBoxUlSX?.li ?? {}),
            },
            liSelected: {
              color: '#596A7C',
              ...(config?.dropListProps?.listBoxUlSX?.liSelected ?? {}),
            },
            liActive: {
              color: '#262E35',
              ...(config?.dropListProps?.listBoxUlSX?.liActive ?? {}),
            },
            liHover: {
              color: '#262E35',
              ...(config?.dropListProps?.listBoxUlSX?.liHover ?? {}),
            },
          },
          listBoxAttachmentSX: {
            ...(config?.dropListProps?.listBoxAttachmentSX ?? {}),
          },
          popperSX: {
            zIndex: 2000,
            ...(config?.dropListProps?.popperSX ?? {}),
          },
          paperSX: {
            boxShadow: '0px 6px 12px 0px #262E351F',
            ...(config?.dropListProps?.paperSX ?? {}),
          },
          noOptionsBoxSX: {
            ...(config?.dropListProps?.noOptionsBoxSX ?? {}),
          },
        }}
        scrollProps={{
          scrollOverviwSX: {
            height: `${
              !disableNewItem && isAddSelectItemShow
                ? calcHeight(options, 6, 6, 20, 6, 48)
                : calcHeight(
                    defaultQuery ? filterOptions() : options,
                    6,
                    6,
                    20,
                    6,
                    0
                  )
            }px`,
            ...(config?.scrollProps?.scrollOverviwSX ?? {}),
          },
          scrollTrackVerticalSX: {
            ...(config?.scrollProps?.scrollTrackVerticalSX ?? {}),
          },
          scrollThumbVerticalSX: {
            ...(config?.scrollProps?.scrollThumbVerticalSX ?? {}),
          },
          scrollThumbSizeSX: config?.scrollProps?.scrollThumbSizeSX,
        }}
      />
    </>
  );
}

// @ts-check
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  DELETE_CV,
  loadSIPReviseData,
  SET_DOWNLOAD_REVISE_DATA_STATUS,
  SET_UPLOAD_CV_STATUS,

} from '../../../../actions/SIMT-SIP/SIP/SIPAction';
// @ts-ignore
import SIPDetailGEInfomationUploadCVIcon from '@/assets/SIPDetailGEInfomationUploadCVIcon.svg';
// @ts-ignore
import SIPDetailGEInfomationDeleteCVIcon from '@/assets/SIPDetailGEInfomationDeleteCVIcon.svg';
import { LoadingButton } from '@mui/lab';
import { SIMTButton } from '@/components/StyledButton/SIMTButton';
// @ts-ignore
import SIPViewAllArrow from '@/assets/SIPViewAllArrow.svg';
import { axios_instance } from '@/utils/axios_instance';
import styles from './CustomButton.module.scss';
import { ErrorBox } from '@/components/FileUploader/ErrorBox';
import { DeleteCV, UploadCV } from "@/actions/SIMT-SIP/SIP/SipDetail";

/** @typedef {import("./CustomButton").SIPDetailGEInfomationUploadCVIdStatus} SIPDetailGEInfomationUploadCVIdStatus */
/** @typedef {import("./CustomButton").SIPDetailGEInfomationDeleteCVIdStatus} SIPDetailGEInfomationDeleteCVIdStatus */

/**
 *
 * @param {import("./CustomButton").UpLoadCVButtonProps} props
 * @returns
 */
export const UpLoadCVButton = props => {
  const { cvId, setCvId, cvName, setCvName } = props;

  /**
   * @type {React.MutableRefObject<HTMLInputElement | null>}
   */
  const fileInput = useRef(null);
  const { token } = useParams();
  const dispatch = useDispatch();

  const [uploading, setUploading] = useState(false);
  const [isError, setIsError] = useState(false);
  const {
    sipDetailGEInfomationCVIdResult,
    sipDetailGEInfomationCVIdMonitor,
    sipDetailGEInfomationUploadCVIdStatus,
  } = useSelector(state => {
    return {
      /** @type {string | null} */
      sipDetailGEInfomationCVIdResult:
        // @ts-ignore
        state.SIP.sipDetailGEInfomationCVIdResult,
      /** @type {boolean} */
      sipDetailGEInfomationCVIdMonitor:
        // @ts-ignore
        state.SIP.sipDetailGEInfomationCVIdMonitor,
      /** @type {SIPDetailGEInfomationUploadCVIdStatus} */
      sipDetailGEInfomationUploadCVIdStatus:
        // @ts-ignore
        state.SIP.sipDetailGEInfomationUploadCVIdStatus,
    };
  });

  useEffect(() => {
    if (sipDetailGEInfomationCVIdResult === null) return;
    if (sipDetailGEInfomationUploadCVIdStatus === 'success') {
      setCvId(sipDetailGEInfomationCVIdResult);
    }

    if (
      sipDetailGEInfomationUploadCVIdStatus === 'success' ||
      sipDetailGEInfomationUploadCVIdStatus === 'error'
    ) {
      dispatch({
        type: SET_UPLOAD_CV_STATUS,
        /** @type {SIPDetailGEInfomationUploadCVIdStatus} */
        data: 'idle',
      });
    }
  }, [sipDetailGEInfomationUploadCVIdStatus]);

  /**
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const handleUploadFile = async event => {
    if (event.target.files?.length) {
      let type = event.target.files[0].type;
      let size = event.target.files[0].size;
      const avalableTypes = {
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": 0, //docx
        "application/msword": 0, //doc
        "application/pdf": 1, //pdf
      };
      if (!(type in avalableTypes) || size > 5 * 1024 * 1024) {
        setIsError(true);
        return;
      }
      setCvName(event.target.files[0].name);
      setUploading(true);

      dispatch({
        type: SET_UPLOAD_CV_STATUS,
        /** @type {SIPDetailGEInfomationUploadCVIdStatus} */
        data: 'uploading',
      });

      await dispatch(
        UploadCV({
          cv: event.target.files[0],
          url: `/api/v1/sip-service/special-issue-proposals/revise/${token}/guest-editors/cv`,
          config: {},
          isExternalPage: true

        })
      );
      setUploading(false);
      setIsError(false);
    }
    event.target.value = ''; // 上传完成后清除选择的文件
  };

  const handleDeleteFile = () => {
    setCvId(null);
  };
  return (
    <Box
      className={`${styles['UpLoadCVButton-root']}`}
      data-selenium-id='SIP_RevisePage_CustomComponents-Button-UpLoadCVBox'
    >
      {cvId ? (
        <Stack
          data-selenium-id='SIP_RevisePage_CustomComponents-Button-UpLoadCVStack'
          className={`${styles['UpLoadCVButton--delete-root']}`}
          direction='row'
          spacing='8px'
        >
          <Box data-selenium-id='SIP_RevisePage_CustomComponents-Button-UpLoadCVTypography'>
            <Typography
              className={`${styles['UpLoadCVButton--delete-textContent']}`}
            >
              {cvId.substring(cvId.indexOf('_') + 1).split(';size:')[0]}
            </Typography>
          </Box>
          <Box data-selenium-id='SIP_RevisePage_CustomComponents-Button-IconBox'>
            <IconButton
              onClick={handleDeleteFile}
              data-selenium-id='SIP_RevisePage_CustomComponents-Button-Icon'
            >
              <SIPDetailGEInfomationDeleteCVIcon data-selenium-id='SIP_RevisePage_CustomComponents-Button-DetailGEInfomationDeleteCVIcon' />
            </IconButton>
          </Box>
        </Stack>
      ) :
        isError ?
          <ErrorBox
            maxSize={'5M'}
            allowTypes={'PDF or Word'}
            onfileSelect={handleUploadFile}
            height={'99px'}
          /> : (
            <Box data-selenium-id='SIP_RevisePage_CustomComponents-Button-UploadBox'>
              <LoadingButton
                data-selenium-id='SIP_RevisePage_CustomComponents-Button-UpLoad'
                className={`${styles['UpLoadCVButton--upload-button']}`}
                loading={uploading}
                onClick={() => fileInput.current?.click()}
                endIcon={<SIPDetailGEInfomationUploadCVIcon />}
                sx={{
                  backgroundColor: '#DFE4E8',
                  fontWeight: 600,
                  justifyContent: 'space-between',
                  textTransform: 'none',
                }}
              >
                Upload CV
              </LoadingButton>
              <input
                ref={fileInput}
                type='file'
                hidden
                onChange={handleUploadFile}
              />
            </Box>
          )}
    </Box>
  );
};

/**
 *
 * @param {import("./CustomButton").StickyBarWithAccordionPanelButtonProps} props
 * @returns
 */
export const StickyBarWithAccordionPanelButton = props => {
  /** @type {import("react").CSSProperties} */
  const arrowStyle = {
    width: '16px',
    height: '16px',
    transform: 'rotate(180deg)',
  };
  const {
    sx,
    endIcon = (
      <SIPViewAllArrow
        style={arrowStyle}
        data-selenium-id='SIP_RevisePage_CustomComponents-Button-SIPViewAllArrow'
      />
    ),
    children,
    ...others
  } = props;
  return (
    <SIMTButton
      data-selenium-id='SIP_RevisePage_CustomComponents-Button-SIMTBtn'
      sx={{ padding: '7px 22px', backgroundColor: '#DFE4E8', ...sx }}
      endIcon={endIcon}
      {...others}
    >
      {children}
    </SIMTButton>
  );
};

/**
 *
 * @param {import("./CustomButton").CancelSubmitButtonForDevProps} props
 * @returns
 */
export const CancelSubmitButtonForDev = props => {
  const { type } = props;
  const { token } = useParams();
  const dispatch = useDispatch();
  return (
    <Button
      data-selenium-id='SIP_RevisePage_CustomComponents-Button-CancelBtn'
      onClick={async () => {
        if (token && type === 'EditorReview') {
          const res = await axios_instance.get(
            `/sip/editorReview/unSubmit/${token}`,
            {
              // headers: {
              //   authorization: localStorage.getItem('authorization') || '',
              // },
            }
          );
          dispatch({
            type: SET_DOWNLOAD_REVISE_DATA_STATUS,
            /** @type {import("../ProposerRevisePage").DownloadReviseDataStatus} */ data: 'downloading',
          });
          dispatch(loadSIPReviseData(token, type));
        }
      }}
    >
      Unsubmit
    </Button>
  );
};

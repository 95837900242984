import * as React from 'react';
import { Box, Divider, Drawer, Stack, TextField } from '@mui/material';
import MultipleAutocomplete from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/ColorFulTagMultipleAutocomplete';
import AddTag from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/SVGComponents/AddTag.svg';
import KeepAdd from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/SVGComponents/KeepAdd.svg';
import AddTagWhite from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/SVGComponents/AddTagWhite.svg';
import {
  AllCentered,
  ColumnCentered,
  LimitWord,
} from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/CommonStyle';
import { MiniBoldFont, MiniFont } from '@/components/FontStyle';
import { useSelector, useDispatch } from 'react-redux';


class AutoCompleteBox extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const { setHeight } = this.props;
    const observer = new ResizeObserver(entries => {
      const { width, height } = entries[0].contentRect;
      // onResize({ width, height });
      setHeight(height);
    });
    observer.observe(this.ref.current);
  }

  render() {
    return (
      <div ref={this.ref} style={{ position: 'relative' }}>
        {this.props.children}
      </div>
    );
  }
}

export default function GESelectBox(props) {
  const {
    emptyErr,
    id,
    defaultValue,
    options,
    getOptionLabel,
    onChange,
    Value,
    handleAddNewClick,
    excuteFunc = () => {},
    type,
    ...other
  } = props;
  const isLeadGeSavedInputAvailable = useSelector((state) => state.GeAdd.isLeadGeSavedInputAvailable);
  const isCoGeSavedInputAvailable = useSelector((state) => state.GeAdd.isCoGeSavedInputAvailable);
  const isleadGeSavedInputEmpty=useSelector((state) => state.GeAdd.isleadGeSavedInputEmpty);
  const iscoGeSavedInputEmpty=useSelector((state) => state.GeAdd.iscoGeSavedInputEmpty);

  const dispatch = useDispatch();
  const handleReturnToInputScreen = () => {
    if(type==='LeadGE')
      dispatch({ type: 'RESET_LEAD_GE_SAVED_INPUT' });
    else
      dispatch({ type: 'RESET_CO_GE_SAVED_INPUT' });
  };
  const [totalError, setTotalError] = React.useState(false);
  const [height, setHeight] = React.useState(0);
  React.useEffect(() => {
    setTotalError(emptyErr || props.error);
  }, [props.error, emptyErr]);
  const getErrorText = () => {
    let helperText = '';
    if (props.error) {
      helperText = props.helperText;
    } else {
      helperText = 'This field is required.';
    }
    return helperText;
  };
  const focusPopusIcon = (
    <Box
      sx={{ width: '20px', height: '22px', ...AllCentered }}
      onClick={event => {
        event.stopPropagation();
        handleAddNewClick();
      }}
    >
      <AddTag></AddTag>
    </Box>
  );
  const focusPopusIconEnterDown = (
    <Stack
      direction='row'
      spacing={0.5}
      onClick={event => {
        event.stopPropagation();
        handleAddNewClick();
        setEnterDown(false);
      }}
      sx={{ width: '92px', height: '22px', ...AllCentered }}
    >
      <AddTagWhite></AddTagWhite>
      <Box
        id = 'buttonAddNewGe'
        sx={{
          ...MiniFont,
          ...ColumnCentered,
          color: '#FFFFFF',
        }}
      >
        Add new GE
      </Box>
    </Stack>
  );
  const popupIcon = (
    <Stack
      direction='row'
      spacing={0.5}
      onClick={event => {
        event.stopPropagation();
        handleAddNewClick();
      }}
      sx={{ width: '92px', height: '22px', ...AllCentered }}
    >
      <Box sx={AllCentered}>
        <AddTag></AddTag>
      </Box>
      <Box
        id = 'buttonAddNewGe'
        sx={{
          ...MiniFont,
          ...ColumnCentered,
          color: '#154AB6',
        }}
      >
        Add new GE
      </Box>
    </Stack>);
  const saveForLaterIcon = (
    <Stack
      direction='row'
      spacing={0.5}
      onClick={event => {
        event.stopPropagation();
        handleAddNewClick();
        handleReturnToInputScreen();
      }}
      sx={{ width: '92px', height: '22px',background:'#A1DDD6',borderRadius:'4px', ...AllCentered }}
    >
      <Box sx={AllCentered}>
        <KeepAdd></KeepAdd>
      </Box>
      <Box
        id = 'buttonKeepAdd'
        sx={{
          ...MiniFont,
          ...ColumnCentered,
          color: '#0D6A61',
        }}
      >
        Keep Adding
      </Box>
    </Stack>
  );

  const [inputValue, setInputValue] = React.useState('');
  React.useEffect(() => {
    dispatch(excuteFunc(inputValue));
  }, [inputValue]);

  const dropListEndAttachment = (
    <Stack direction='column' alignItems='center'>
      <Divider
        sx={{ width: 'calc(100% - 24px)', borderBottom: '0.5px solid #DFE4E8' }}
      />
      <Stack
        direction='column'
        alignItems='center'
        spacing='0px'
        sx={{
          padding: '8px 12px 0px 12px',
        }}
      >
        <Box
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '20px',
            letterSpacing: '0em',
            color: '#98A7B6',
          }}
        >
          No Results you want?
        </Box>
        <Box
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0em',
            color: '#98A7B6',
          }}
        >
          You can{' '}
          <span
            style={{ color: '#154AB6', cursor: 'pointer', fontWeight: 600 }}
            onClick={handleAddNewClick}
          >
            click here
          </span>{' '}
          to add a new one.{' '}
        </Box>
      </Stack>
    </Stack>
  );

  const dropListNoOptions = (
    <Stack
      direction='column'
      alignItems='center'
      spacing='0px'
      sx={{
        padding: '12px',
      }}
    >
      <Box
        sx={{
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '20px',
          letterSpacing: '0em',
          color: '#98A7B6',
        }}
      >
        No Results
      </Box>
      <Box
        sx={{
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '20px',
          letterSpacing: '0em',
          color: '#98A7B6',
        }}
      >
        You can{' '}
        <span
          style={{ color: '#154AB6', cursor: 'pointer', fontWeight: 600 }}
          onClick={handleAddNewClick}
        >
          click here
        </span>{' '}
        to add a new one.{' '}
      </Box>
    </Stack>
  );
  const calcHeight = (options, maxNum, gap, liHeight, other, other1) => {
    let numOptions = options?.length ?? 0;
    let currentHeight = numOptions * (gap + liHeight) + other + other1;
    let maxHeight = (maxNum - 1) * (gap + liHeight) + liHeight + other;
    return currentHeight <= maxHeight ? currentHeight : maxHeight;
  };
  const lastOption = {
    fistNmae: 'End',
    lastName: 'Attachment',
    primaryEmail: 'null',
  };
  const getNewOptions = dropList => {
    let newDropList = [];
    if (dropList && dropList.length > 0) {
      newDropList = dropList.concat();
      newDropList.push({
        fistNmae: 'End',
        lastName: 'Attachment',
        primaryEmail: 'null',
      });
    }
    return newDropList;
  };
  const getListOptionLabel = option => {
    return (
      option.lastName +
      ', ' +
      option.firstName +
      ' (' +
      option.primaryEmail +
      ')'
    );
  };
  const [enterDown, setEnterDown] = React.useState(false);
  const deleteIcon = (
    <Box
      sx={{
        ...MiniFont,
        width: '29px',
        height: '9px',
        color: '#154AB6',
        leadingTrim: ' both',
        textEdge: 'cap',
        lineHeight: '9px',
        marginBottom: '4px',
      }}
    >
      Clear
    </Box>
  );
  const [stateHover, setStateHover] = React.useState(false);
  const [stateFocus, setStateFocus] = React.useState(false);
  return (
    <AutoCompleteBox setHeight={setHeight}>
      {Value?.length === 0 &&
      inputValue &&
      inputValue !== '' ? (
          <Box
            sx={{
              position: 'absolute',
              top: '-13px',
              right: '0px',
              cursor: 'pointer',
            }}
          >
            {deleteIcon}
          </Box>
        ) : null}

      <MultipleAutocomplete
        id={id}
        data-selenium-id={id}
        // defaultValue={defaultValue}
        value={Value}
        options={getNewOptions(options).filter(itemA => !Value.some(itemB => itemA.primaryEmail === itemB.primaryEmail))}
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        onInputChange={e => {
          setInputValue(e.target.value);
        }}
        isOptionEqualToValue={(option, value) => {
          return getListOptionLabel(option) === getListOptionLabel(value);
        }}
        onFocus={() => {
          setTotalError(false);
          setStateFocus(true);
          dispatch(excuteFunc(''));
        }}
        onBlur={() => {
          setTotalError(emptyErr || props.error);
          setEnterDown(false);
          setStateFocus(false);
          setInputValue('');
        }}
        // onMouseEnter={() => {
        //   setStateHover(true);
        // }}
        // onMouseLeave={() => {
        //   setStateHover(false);
        // }}
        onLoading={props?.onLoading ?? false}
        popupIcon={type==='LeadGE'&&isLeadGeSavedInputAvailable&&!isleadGeSavedInputEmpty||type==='COGE'&&isCoGeSavedInputAvailable&&!iscoGeSavedInputEmpty?saveForLaterIcon:popupIcon}
        focusPopusIcon={enterDown ? focusPopusIconEnterDown : focusPopusIcon}
        renderInput={params => (
          <TextField
            id={`${id}_multi_autocomplete_textfield`}
            data-selenium-id={`${id}_multi_autocomplete_textfield`}
            value={inputValue}
            placeholder={''}
            error={totalError}
            {...params}
          />
        )}
        chipProps={{
          chipRootSX: {
            margin: '0px',
            maxWidth: '176px',
            height: '22px',
          },
        }}
        endAttchmentProps={{
          endAttchmentSX: {
            right: '6px !important',
            top: height <= 40 ? '9px' : '6px',
          },
          popupIconSX: {
            padding: '0px',
            backgroundColor: enterDown ? '#0052CC' : '#F1F3F5',
            transform: enterDown ? 'none' : '',
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
          },
          popupIconHoverSX: {
            backgroundColor: enterDown ? '#6D98EE' : '#DFE4E8',
          },
          popupIconFocusSX: {
            backgroundColor: enterDown ? '#113D95' : '#DFE4E8',
          },
          deleteIconSX: {
            position: 'absolute',
            top: '-19px',
            right: '-6px',
            width: '29px',
            height: '9px',
            padding: '0px',
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
          },
          deleteIconHoverSX: {
            backgroundColor: '#FFFFFF',
          },
          deleteIconFocusSX: {
            backgroundColor: '#FFFFFF',
          },
        }}
        outlinedInputProps={{
          outlinedInputSX: {
            padding: '6px',
            paddingRight: '104px !important',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            rowGap: '4px',
            columnGap: '2px',
          },
          inputSX: {
            root: {
              height: '28px',
              padding: '4px 0px',
              boxSizing: 'border-box',
              // display:stateFocus ? 'block' :'none'
            },
          },
          notchedOutlineSX: {
            border:
              Value?.length === 0 ? '1px solid #DFE4E8' : '1px solid #98A7B6',
          },
        }}
        renderOption={(props, option) => {
          return getListOptionLabel(option) ===
            getListOptionLabel(lastOption) ? (
              dropListEndAttachment
            ) : (
              <li
                {...props}
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '4px',
                  height: '30px',
                  fontSize: '12px',
                  lineHeight: '9px',
                  color: '#596A7C',
                  marginBottom: '4px',
                }
                }
                ref={node => {
                  if (node) {
                    node.style.setProperty('padding-bottom', '4px', 'important');
                    node.style.setProperty('padding-top', '4px', 'important');
                    node.style.setProperty('display', 'flex', 'important');
                  }
                }}
                // 添加:hover样式
                onMouseEnter={e => {
                  e.currentTarget.style.color = '#262E35';
                }}
                onMouseLeave={e => {
                  e.currentTarget.style.color = '#596A7C';
                }}
              >
                <div>{getOptionLabel(option)}</div>
                <div>{option.primaryEmail}</div>
              </li>
          // <li {...props}>
          //   <Stack direction='column' spacing={0.5}>

          //     <Box
          //       sx={{
          //         ...LimitWord,
          //         width: '100%',
          //         height: '9px',
          //         fontSize:'12px',
          //         color: '#596A7C',
          //         lineHeight: '9px',
          //       }}
          //     >
          //       {getOptionLabel(option)}
          //     </Box>
          //     <Box
          //       sx={{
          //         ...LimitWord,
          //         width: '100%',
          //         height: '9px',
          //         fontSize:'12px',
          //         color: '#596A7C',
          //         lineHeight: '9px',
          //       }}
          //     >
          //       {option.primaryEmail}
          //     </Box>

            //   </Stack>
            // </li>
            );
        }}
        dropListEndAttachment={null}
        scrollProps={{
          scrollOverviwSX: {
            height: `${calcHeight(options, 6, 4, 34, 12, 46)}px`,
          },
        }}
        dropListProps={{
          listBoxSX: {
            paddingBottom: '12px',
          },
          listBoxUlSX: {
            root: {
              padding: '12px 0px 0px 0px',
              gap: '4px',
            },
            li: {
              padding: '4px 12px !important',
              lineHeight: '9px',
            },
          },
        }}
        getListOptionLabel={getListOptionLabel}
        dropListNoOptions={dropListNoOptions}
        onKeyDown={e => {
          if (e.key === 'Enter' && options.length === 0) {
            setEnterDown(true);
          }
        }}
        deleteIcon={deleteIcon}
        {...other}
      >
        
      </MultipleAutocomplete>
      
    </AutoCompleteBox>
   
  );
}

import {
  Box,
  Checkbox,
  Link,
  TableBody,
  TableRow,
  Tooltip,
  Typography as MuiTypography,
} from '@mui/material';
import { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { useMount, useUpdateEffect } from 'ahooks';
import { useSelector, useDispatch } from 'react-redux';
import {
  addSelected,
  removeSelected,
  removeSelectedAll,
  setIsAllSeleted,
  setOrderBy,
  setPageNum,
} from '../../../../actions/SIMT-SIP/SIP/SIPAction';
import GEisFlagged from '@/assets/GEisFlagged.svg';
import {
  setFilters,
  setSearchSI,
  batchSelectSIFilter,
  setFavSIPFilterSelected,
  setAdaptSIPFilterSelected, resetFilter,
} from '@/actions/SIMT-SIP/SIPFilter/SIPFilterAction';
import TableSkeleton from '../../../../components/TableSkeleton';
import { StyledTableCell } from './cssComponent';
import SIPOverviewUncheckedIcon from '../../../../assets/SIPOverviewUncheckedIcon.svg';
import SIPOverviewCheckedIcon from '../../../../assets/SIPOverviewCheckedIcon.svg';
import { SSIPitems, USIPitems, items} from './constantData';
import moment from 'moment';
import { styled } from '@mui/system';
import TooltipMoveArrow, {
  MulLineTooltipIfShow,
} from '@/componentsphase2/CustomTooltip';
import { unstable_batchedUpdates } from 'react-dom';
import {initSIPFilterSelected, prepareFilters} from '@/pages/SIPPage/Overview/Filter/utils';
import { measureWidth } from '@/utils/StringWidthMeasure';
import AlertFlag from '@/assets/AlertFlag.svg';
import SimpleTooltip from '@/componentsphase2/SimpleTooltip';
import { tooltipClasses } from '@mui/material/Tooltip';
import { CustomTooltip } from '@/components/CustomTooltip';
import { useParams } from 'react-router-dom';
import FlagIcon from '../assets/flagIcon.svg';
import FlagIconM from '../assets/flagIconM.svg';
import { getGEShowString } from '@/pages/SIPage/SpecialIssueManagement/IssueTable';
import GeMenuIcon from '@/pages/SIPage/SpecialIssueManagement/GeMenuIcon';
import { loadSIPCodes, loadSIPOverviewData } from "@/actions/SIMT-SIP/SIP/SipPage";
import {
  loadFavourite,
  loadFavouriteFirst,
  loadFavouriteFromLocalStorage,
  saveFilters
} from "@/actions/SIMT-SIP/SIPFilter";
import NoDataMessage from '@/componentsphase2/NoDataMessage';
import {PERMISSIONS} from "@/constant/permission";
import {initState} from "@/reducers/SIPFilterReducer";
import MyLocalStroge from "@/utils/MyLocalStorage";
import {deepEqual} from "@/componentsphase2/SelectBox/SelectInput";
const Typography = styled(MuiTypography)({
  fontWeight: '600',
  color: '#154AB6',
});
const HoverTableRow = styled(TableRow)({
  '&:hover': {},
});

export default function SIPOverviewTableBody(props) {
  const {
    loading, setLoading,
    ...others
  } = props;
  const dispatch = useDispatch();
  const { sipOrigin } = useParams();
  useEffect(() => {
    dispatch(setOrderBy("desc", "sipCode"));
  }, [sipOrigin]);
  const ref = useRef(null);
  const selector = state => {
    return {
      sipOverviewData: state.SIP.sipOverviewData,
      pageNum: state.SIP.pageNum,
      pageSize: state.SIP.pageSize,
      orderType: state.SIP.orderType,
      orderField: state.SIP.orderField,
      sipOverviewSeletedData: state.SIP.sipOverviewSeletedData,
      isAllSeleted: state.SIP.isAllSeleted,
      isSIPOverviewDataByPermission: state.SIP.isSIPOverviewDataByPermission,
      columnsSelected: state.SIP.columnsSelected,
      useFilters: state.SIPFilter.useFilters,
      sipFilterSelected: state.SIPFilter.sipFilterSelected,
      adaptSIPFilterSelected: state.SIPFilter.adaptSIPFilterSelected,
      searchSI: state.SIPFilter.searchSI,
      firstSIPFilterSelected: state.SIPFilter.firstSIPFilterSelected,
      userId: state.User.userId,
    };
  };
  const {
    sipOverviewData,
    pageNum,
    pageSize,
    orderType,
    orderField,
    sipOverviewSeletedData,
    isAllSeleted,
    isSIPOverviewDataByPermission,
    columnsSelected,
    useFilters,
    sipFilterSelected,
    adaptSIPFilterSelected,
    searchSI,
    firstSIPFilterSelected,
    userId,
  } = useSelector(selector);

  const prevUseFilters = useRef(useFilters);
  const prevRequest = useRef(null);

  /***************  获取权限  **************************************************/
  const getPermission = () => {
    let userPermissions = localStorage.getItem('userPermissions');
    /**
     * admin： 只要含有 admin:write 就是admin
     * t1： 同时拥有report:read si:write
     */
    //2023-09-11：规则更改，不再根据角色来控制，而是根据权限来控制，详见：https://jira.wiley.com/browse/CT-4755
    //在src\pages\SIPPage\Overview\index.js还有一个相同的函数，别忘了改！！！
    if (userPermissions?.indexOf(PERMISSIONS.SIP_WRITE_JPA) !== -1) {
      return 'admin';
    } else if (
      // userPermissions.indexOf(PERMISSIONS.SI_WRITE) !== -1 &&
      // userPermissions.indexOf('report:read') !== -1||userPermissions.indexOf('sip') !== -1
      userPermissions?.indexOf(PERMISSIONS.SIP_WRITE_HANDLING_CE) !== -1
    ) {
      return 'tier1';
    } else {
      return 'none';
    }
  };
  /****************************************************************************/

  /***************加载表格数据******************** */
  // const [loading, setLoading] = useState(true);

  function loadSipOverview() {
    async function fetchData() {
      await dispatch(
          loadSIPOverviewData(
              pageNum,
              pageSize,
              orderType,
              orderField,
              getPermission(),
              isSIPOverviewDataByPermission,
              {...useFilters},
              sipOrigin === 'solicited' ? true : false
          )
      );
    }

    const queryParams = {
      pageNum,
      pageSize,
      orderType,
      orderField,
      isSIPOverviewDataByPermission,
      filters: {...useFilters},
      sipOrigin
    };

    if (JSON.stringify(prevRequest.current) === JSON.stringify(queryParams)) {
      return;
    }

    prevRequest.current = queryParams;

    // if (!loading) {
      setLoading(true);
      MyLocalStroge.setItem('sip-overview-previous-params', queryParams);

      fetchData().then(() => {
        setLoading(false);
      }).catch((e) => {
        setLoading(false);
      });

      // // unlock the loading after 3s
      // setTimeout(() => {
      //   if (loading) {
      //     setLoading(false);
      //   }
      // }, 1000);
    // }
  }

  useEffect(() => {
    dispatch(loadFavouriteFromLocalStorage(sipOrigin, userId));
    dispatch(loadFavourite(true, false, sipOrigin === 'solicited', userId));
  }, [sipOrigin]);

  useUpdateEffect(() => {
    // deep compare and if has change, reset pageNum to 1 and load data
    if (!deepEqual(prevUseFilters.current, useFilters)) {
      unstable_batchedUpdates(() => {
        dispatch(setPageNum(1));
        loadSipOverview();
      });
    }

    prevUseFilters.current = useFilters;
  }, [useFilters]);

  useUpdateEffect(() => {
    // reset pageNum to 1 and load data
    unstable_batchedUpdates(() => {
      dispatch(setPageNum(1));
      loadSipOverview();
    });
  }, [
    pageSize,
    orderType,
    orderField,
    isSIPOverviewDataByPermission,
    sipOrigin,
  ]);

  useUpdateEffect(() => {
    // compare with the previous loading params, if (origin,useFilter,pageSize,...) has change, no need to load data
    loadSipOverview();
  }, [pageNum]);

  /************************************************** */

  /*********执行每行前面checkbox的点击事件************ */
  const handleRowSelected = sipCode => {
    if (sipOverviewSeletedData.indexOf(sipCode) !== -1) {
      dispatch(removeSelected(sipCode));
    } else {
      dispatch(addSelected(sipCode));
    }
  };
  /*************************************************** */

  /******************间接变更itemCheckbox和allselectCheckbox********************* */
  // useEffect(()=>{
  //   if(sipOverviewSeletedData.length>0&&sipOverviewSeletedData.length === sipOverviewData.length){
  //     dispatch(setIsAllSeleted(true))
  //   }
  // },[sipOverviewSeletedData])

  //当表格中的数据变化时 需要变更
  useEffect(() => {
    // dispatch(removeSelectedAll())
    // dispatch(setIsAllSeleted(false))
    if (sipOverviewData.length === 0 && pageNum !== 1) {
      dispatch(setPageNum(1));
      return;
    }
    if (sipOverviewSeletedData.length === 0) {
      dispatch(setIsAllSeleted(false));
      return;
    }
    // 判断新的siData是否全部选中，然后更新全选选择框的状态
    let isAllSelectedTemp = true;
    for (const sipItem of sipOverviewData) {
      if (sipOverviewSeletedData.indexOf(sipItem.sipCode) === -1) {
        isAllSelectedTemp = false;
        break;
      }
    }

    dispatch(setIsAllSeleted(isAllSelectedTemp));
  }, [sipOverviewData, sipOverviewSeletedData]);
  const replaceNbsp = (str, Maxlength, type) => {
    if (!str) {
      return '';
    }
    let bool =
      str.toLowerCase().includes(' ') ||
      str.toLowerCase().includes('String.fromCharCode(160)');
    if (!bool && measureWidth(str, type) > Maxlength) {
      const midIndex = Math.floor(str.length / 2);
      return str;
    } else {
      return str.replaceAll(
        String.fromCharCode(160) /*&nbsp*/,
        ' ' /*normal space*/
      );
    }
  };

  const leadGeRef = useRef([]);
  const coGeRef = useRef([]);
  const getGeLiteVoList = (fullName, email) => {
    let LiteVoList = [];
    let length = fullName.split(';').length;
    for (let i = 0; i < length; i++) {
      LiteVoList.push({
        fullName: fullName.split(';')[i],
        email: email.split(';')[i],
        firstName: fullName.split(';')[i].split(', ')[1],
        lastName: fullName.split(';')[i].split(',')[0],
        geCode: 'GE-0000-000000',
      });
    }
    return LiteVoList;
  };
  return loading ? (
    <TableBody
      sx={{ backgroundColor: 'fff' }}
      data-selenium-id='SIPPage_Overview-Table-Body-InnerBody1'
    >
      <TableSkeleton
        num={9}
        colSpan={36}
        data-selenium-id='SIPPage_Overview-Table-Body-Skelecton'
      />
    </TableBody>
  ) : (
    <TableBody
      ref={ref}
      data-selenium-id='SIPPage_Overview-Table-Body-InnerBody2'
      sx={{
        '& tr': {
          '& td': {
            backgroundColor: '#FFFFFF',
          },
          '&:hover td': {
            backgroundColor: '#E8EDFB',
          },
        },
      }}
    >
      {!loading && 
        sipOverviewData?.length > 0 ? 
      (sipOverviewData?.map((row, index) => {
        const isRowSelected =
          sipOverviewSeletedData.indexOf(row.sipCode) !== -1;
        return (
          <HoverTableRow
            data-selenium-id={`SIPPage_Overview-Table-Body-Row-${index}`}
            //hover
            key={row.sipCode + '_' + index}
            aria-checked={isRowSelected}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}
            selected={isRowSelected}
          >
            {/* Checkbox */}
            <StyledTableCell
              data-selenium-id={`SIPPage_Overview-Table-Body-StyledCell1-${index}`}
              sx={{
                position: 'sticky',
                left: 0,
                zIndex: 5,
              }}
            >
              <Checkbox
                id={`sipOverviewTableSelectBtn_${index}`}
                data-selenium-id={`SIPPage_Overview-SIPTable-Body-Checkbox-sipOverviewTableSelectBtn_-${index}`}
                checked={isRowSelected}
                onChange={() => {
                  handleRowSelected(row.sipCode);
                }}
                sx={{
                  padding: '3px',
                  '&:hover': {
                    bgcolor: 'transparent',
                    '& g rect:last-child': {
                      stroke: '#596A7C',
                    },
                  },
                  '& .MuiTouchRipple-root': {
                    position: 'unset',
                  },
                  '&.Mui-checked:hover': {
                    rect: {
                      fill: '#113D95',
                      stroke: '#113D95',
                    },
                  },
                }}
                icon={<SIPOverviewUncheckedIcon></SIPOverviewUncheckedIcon>}
                checkedIcon={<SIPOverviewCheckedIcon></SIPOverviewCheckedIcon>}
              />
            </StyledTableCell>
            {/* ct5777也需要显示这个flag，无论是否是solicited sip，待定看甲方是否有异议 */}

            <StyledTableCell
              data-selenium-id={`SIPPage_Overview-Table-Body-IdCell1-${index}`}
              VerticalPadding={'12px'}
              lineHeight={'20px'}
            >
              <Box
                id={'SipPageOverviewTableBodyGeNewlyFlagged'}
                data-selenium-id={'SIPPage_Overview-Table-Body-IdCell1-showBox'}
                style={{ display: 'flex', alignItems: 'center' }}
                sx={{
                  rect: { fill: 'transparent' },
                }}
              >
                {
                  <CustomTooltip
                    title={(function () {
                      let res = '';
                      if (row.sipStage === 'Initial Review')
                        res = 'Proposer’s associated GE is flagged';
                      if (
                        row.sipStage === 'Publisher Inquiry' ||
                        row.sipStage === 'Editor Review' ||
                        row.sipStage === 'Mutual Accept'
                      )
                        res =
                          'There are one or more GEs newly flagged with some issues after the "Initial Review" stage, due to the update of the GE database';
                      return res;
                    })()}
                    arrow
                    placement='top'
                    sx={{
                      [`& .${tooltipClasses.arrow}`]: {
                        color: 'var(--gray-500, #596A7C)',
                      },
                      [`& .${tooltipClasses.tooltip}`]: {
                        display: 'inline-flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        background: 'var(--gray-500, #596A7C)',
                        backgroundBlendMode: 'multiply',
                        color: 'var(--shades-white, #FFF)',
                        textAlign: 'center',
                        fontFamily: 'Open Sans',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                      },
                    }}
                  >
                    {((row.sipStage === 'Initial Review' &&
                      sipOrigin !== 'solicited' &&
                      row.geNewlyFlagged) ||
                      ((
                        row.sipStage === 'Publisher Inquiry' ||
                        row.sipStage === 'Editor Review' ||
                        row.sipStage === 'Mutual Accept') &&
                        row.siGEFlagAlert)) && <GEisFlagged />}
                  </CustomTooltip>
                }
              </Box>
            </StyledTableCell>

            {/* SIP Code */}
            {/* unsolicited的submitted阶段的sip不显示flag 且 initial及以后阶段的SIP中无unethical GE也不显示unethical flag*/}
            {sipOrigin !== 'solicited' &&
              (row.sipStage === 'Submitted' || row.alertGeFlagType === '' || row.alertGeFlagType === null || row.alertGeFlagType === undefined ? (
                <StyledTableCell
                  data-selenium-id={`SIPPage_Overview-Table-Body-StyledCell2-${index}`}
                  sx={{
                    position: 'sticky',
                    left: 72,
                    zIndex: 5,
                    paddingTop: '1px!important',
                    paddingBottom: '1px!important',
                  }}
                  // VerticalPadding={'12px'}
                  lineHeight={'20px'}
                >
                  <Link
                    id={`sipCodeLink_${index}`}
                    data-selenium-id={`SIPPage_Overview-Table-Body-SipCodeLink-${index}`}
                    href={`../detail/${row.sipCode}`}
                    color='#154AB6'
                    sx={{
                      textDecoration: 'none',
                    }}
                    target='_blank'
                  >
                    <Box
                      sx={{ fontWeight: 600 }}
                      data-selenium-id={`SIPPage_Overview-Table-Body-InnerBox-${index}`}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          '&:hover': {
                            color: '#113D95',
                            fontWeight: '700',
                          },
                        }}
                      >
                        {row.sipCode}
                      </Typography>
                    </Box>
                  </Link>
                </StyledTableCell>
              ) : (
                <StyledTableCell
                  data-selenium-id={`SIPPage_Overview-Table-Body-StyledCell2-${index}`}
                  sx={{
                    position: 'sticky',
                    left: 72,
                    zIndex: 5,
                    paddingTop: '0px!important',
                    paddingBottom: '9px!important',
                  }}
                  // VerticalPadding={'12px'}
                  lineHeight={'20px'}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      // alignItems: "center",
                      gap: '2px',
                    }}
                  >
                    {/* 显示Mild Issue */}
                    {row.alertGeFlagType === 'Flags-Mild Issue' ? (
                      <>
                        <Box
                          sx={{
                            paddingLeft: '5px',
                            paddingRight: '35px',
                          }}
                        >
                          <Box
                            data-selenium-id={`SIPPage_Overview-Table-Body-SipCodeAlertFlag-${index}`}
                            sx={{
                              display: 'flex',
                              padding: '2px 17.5px 2px 18.5px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: '0px 0px 4px 4px',
                              background: 'var(--warning-600, #FFB152)',
                            }}
                          >
                            <Typography
                              sx={{
                                color: '#FFF',
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                              }}
                            >
                              MILD ISSUE
                            </Typography>
                          </Box>
                        </Box>
                        <Link
                          id={`sipCodeLink_${index}`}
                          data-selenium-id={`SIPPage_Overview-Table-Body-SipCodeLink-${index}`}
                          href={`../detail/${row.sipCode}`}
                          color='#154AB6'
                          sx={{
                            textDecoration: 'none',
                          }}
                          target='_blank'
                        >
                          <Box
                            sx={{ fontWeight: 600 }}
                            data-selenium-id={`SIPPage_Overview-Table-Body-InnerBox-${index}`}
                          >
                            <Typography
                              sx={{
                                fontSize: '14px',
                                '&:hover': {
                                  color: '#113D95',
                                  fontWeight: '700',
                                },
                              }}
                            >
                              {row.sipCode}
                            </Typography>
                          </Box>
                        </Link>
                      </>
                    ) : (
                      <>
                        {/* 显示Severe Issue */}
                        <Box
                          sx={{
                            paddingLeft: '5px',
                            paddingRight: '35px',
                          }}
                        >
                          <Box
                            data-selenium-id={`SIPPage_Overview-Table-Body-SipCodeAlertFlag-${index}`}
                            sx={{
                              display: 'flex',
                              padding: '2px 12px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: '0px 0px 4px 4px',
                              background: 'var(--error-600, #EE5350)',
                            }}
                          >
                            <Typography
                              sx={{
                                color: '#FFF',
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                              }}
                            >
                              SEVERE ISSUE
                            </Typography>
                          </Box>
                        </Box>
                        <Link
                          id={`sipCodeLink_${index}`}
                          data-selenium-id={`SIPPage_Overview-Table-Body-SipCodeLink-${index}`}
                          href={`../detail/${row.sipCode}`}
                          color='#154AB6'
                          sx={{
                            textDecoration: 'none',
                          }}
                          target='_blank'
                        >
                          <Box
                            sx={{ fontWeight: 600 }}
                            data-selenium-id={`SIPPage_Overview-Table-Body-InnerBox-${index}`}
                          >
                            <Typography
                              sx={{
                                fontSize: '14px',
                                '&:hover': {
                                  color: '#113D95',
                                  fontWeight: '700',
                                },
                              }}
                            >
                              {row.sipCode}
                            </Typography>
                          </Box>
                        </Link>
                      </>
                    )}
                  </Box>
                </StyledTableCell>
              ))}
            {/* solicited的Initial Review阶段的sip不显示flag 无unethical GE也不显示unethical flag*/}
            {sipOrigin === 'solicited' &&
              (row.sipStage === 'Initial Review' ||
                row.alertGeFlagType === '' || row.alertGeFlagType === null || row.alertGeFlagType === undefined ? (
                <StyledTableCell
                  data-selenium-id={`SIPPage_Overview-Table-Body-StyledCell2-${index}`}
                  sx={{
                    position: 'sticky',
                    left: 72,
                    zIndex: 5,
                    paddingTop: '1px!important',
                    paddingBottom: '1px!important',
                  }}
                  // VerticalPadding={'12px'}
                  lineHeight={'20px'}
                >
                  <Link
                    id={`sipCodeLink_${index}`}
                    data-selenium-id={`SIPPage_Overview-Table-Body-SipCodeLink-${index}`}
                    href={`../detail/${row.sipCode}`}
                    color='#154AB6'
                    sx={{
                      textDecoration: 'none',
                    }}
                    target='_blank'
                  >
                    <Box
                      sx={{ fontWeight: 600 }}
                      data-selenium-id={`SIPPage_Overview-Table-Body-InnerBox-${index}`}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          '&:hover': {
                            color: '#113D95',
                            fontWeight: '700',
                          },
                        }}
                      >
                        {row.sipCode}
                      </Typography>
                    </Box>
                  </Link>
                </StyledTableCell>
              ) : (
                <StyledTableCell
                  data-selenium-id={`SIPPage_Overview-Table-Body-StyledCell2-${index}`}
                  sx={{
                    position: 'sticky',
                    left: 72,
                    zIndex: 5,
                    paddingTop: '0px!important',
                    paddingBottom: '9px!important',
                  }}
                  // VerticalPadding={'12px'}
                  lineHeight={'20px'}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      // alignItems: "center",
                      gap: '2px',
                    }}
                  >
                    {/* 显示Mild Issue */}
                    {row.alertGeFlagType === 'Flags-Mild Issue' ? (
                      <>
                        <Box
                          sx={{
                            paddingLeft: '5px',
                            paddingRight: '35px',
                          }}
                        >
                          <Box
                            data-selenium-id={`SIPPage_Overview-Table-Body-SipCodeAlertFlag-${index}`}
                            sx={{
                              display: 'flex',
                              padding: '2px 17.5px 2px 18.5px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: '0px 0px 4px 4px',
                              background: 'var(--warning-600, #FFB152)',
                            }}
                          >
                            <Typography
                              sx={{
                                color: '#FFF',
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                '&:hover': {
                                  color: '#FAFAFA',
                                  fontWeight: '700',
                                },
                              }}
                            >
                              MILD ISSUE
                            </Typography>
                          </Box>
                        </Box>
                        <Link
                          id={`sipCodeLink_${index}`}
                          data-selenium-id={`SIPPage_Overview-Table-Body-SipCodeLink-${index}`}
                          href={`../detail/${row.sipCode}`}
                          color='#154AB6'
                          sx={{
                            textDecoration: 'none',
                          }}
                          target='_blank'
                        >
                          <Box
                            sx={{ fontWeight: 600 }}
                            data-selenium-id={`SIPPage_Overview-Table-Body-InnerBox-${index}`}
                          >
                            <Typography
                              sx={{
                                fontSize: '14px',
                                '&:hover': {
                                  color: '#113D95',
                                  fontWeight: '700',
                                },
                              }}
                            >
                              {row.sipCode}
                            </Typography>
                          </Box>
                        </Link>
                      </>
                    ) : (
                      <>
                        {/* 显示Severe Issue */}
                        <Box
                          sx={{
                            paddingLeft: '5px',
                            paddingRight: '35px',
                          }}
                        >
                          <Box
                            data-selenium-id={`SIPPage_Overview-Table-Body-SipCodeAlertFlag-${index}`}
                            sx={{
                              display: 'flex',
                              padding: '2px 12px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: '0px 0px 4px 4px',
                              background: 'var(--error-600, #EE5350)',
                            }}
                          >
                            <Typography
                              sx={{
                                color: '#FFF',
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                              }}
                            >
                              SEVERE ISSUE
                            </Typography>
                          </Box>
                        </Box>
                        <Link
                          id={`sipCodeLink_${index}`}
                          data-selenium-id={`SIPPage_Overview-Table-Body-SipCodeLink-${index}`}
                          href={`../detail/${row.sipCode}`}
                          color='#154AB6'
                          sx={{
                            textDecoration: 'none',
                          }}
                          target='_blank'
                        >
                          <Box
                            sx={{ fontWeight: 600 }}
                            data-selenium-id={`SIPPage_Overview-Table-Body-InnerBox-${index}`}
                          >
                            <Typography
                              sx={{
                                fontSize: '14px',
                                '&:hover': {
                                  color: '#113D95',
                                  fontWeight: '700',
                                },
                              }}
                            >
                              {row.sipCode}
                            </Typography>
                          </Box>
                        </Link>
                      </>
                    )}
                  </Box>
                </StyledTableCell>
              ))}
            {(sipOrigin === 'solicited' ? SSIPitems : USIPitems).map(
              (item, i) => {
                if (columnsSelected[item.id] === false) return null;
                else if (
                  item.id === 'creationDate' ||
                  item.id === 'submissionDate' ||
                  item.id === 'latestDecisionDate' ||
                  item.id === 'acceptanceDeadline' ||
                  item.id === 'fullManuscriptSubmissionDeadline' ||
                  item.id === 'expectedPublicationDate'
                ) {
                  return (
                    <StyledTableCell
                      data-selenium-id={`SIPPage_Overview-Table-Body-MomentCell-${index}-${i}`}
                      // VerticalPadding={'12px'}
                      lineHeight={'20px'}
                    >
                      <MuiTypography>
                        {row[item.id] === null
                          ? 'N/A'
                          : moment.utc(new Date(row[item.id])).format('YYYY-MM-DD')}
                      </MuiTypography>
                    </StyledTableCell>
                  );
                } else if (item.id === 'handlingCe') {
                  return (
                    <StyledTableCell
                      data-selenium-id={`SIPPage_Overview-Table-Body-IdCell2-${index}-${i}`}
                      // VerticalPadding={'12px'}
                      lineHeight={'20px'}
                    >
                      <MuiTypography
                        sx={{ fontColor: '#262E35', fontSize: '14px' }}
                      >
                        {row[item.id] === null
                          ? 'to Be Aassigned'
                          : row[item.id]}
                      </MuiTypography>
                    </StyledTableCell>
                  );
                } else if (item.id === 'sipTitle') {
                  let isDoubleLine = false;
                  if (row[item.id]?.length > 21) isDoubleLine = true;
                  return (
                    <StyledTableCell
                      data-selenium-id={`SIPPage_Overview-Table-Body-ReturnCell-${index}-${i}`}
                      isDoubleLine={isDoubleLine}
                      // VerticalPadding={'12px'}
                      lineHeight={'20px'}
                    >
                      <Link
                        id={`sipCodeLink_${index}`}
                        data-selenium-id={`SIPPage_Overview-Table-Body-CodeLink_-${index}-${i}`}
                        href={`../detail/${row.sipCode}`}
                        color='#154AB6'
                        sx={{
                          textDecoration: 'none',
                        }}
                        target='_blank'
                      >
                        {row[item.id]?.length > 60 ? ( // 超长部分省略号+Tooltip
                          <TooltipMoveArrow
                            placement='top'
                            title={row[item.id]}
                            disableInteractive
                            data-selenium-id={`SIPPage_Overview-Table-Body-Tooltip-${index}-${i}`}
                          >
                            <Box
                              data-selenium-id={`SIPPage_Overview-Table-Body-StyleBox-${index}-${i}`}
                              style={{ whiteSpace: 'pre-line' }}
                            >
                              <div
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  WebkitLineClamp: 2,
                                  fontWeight: 600,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: '14px',
                                    '&:hover': {
                                      color: '#113D95',
                                      fontWeight: '700',
                                    },
                                  }}
                                >
                                  {replaceNbsp(
                                    row[item.id],
                                    212,
                                    '14px Open Sans'
                                  )}
                                </Typography>
                              </div>
                            </Box>
                          </TooltipMoveArrow>
                        ) : (
                          <Box
                            data-selenium-id={`SIPPage_Overview-Table-Body-StyleBox-${index}-${i}`}
                            style={{ whiteSpace: 'pre-line' }}
                          >
                            <div
                              style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                                fontWeight: 600,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  '&:hover': {
                                    color: '#113D95',
                                    fontWeight: '700',
                                  },
                                }}
                              >
                                {replaceNbsp(
                                  row[item.id],
                                  212,
                                  '14px Open Sans'
                                )}
                              </Typography>
                            </div>
                          </Box>
                        )}
                      </Link>
                    </StyledTableCell>
                  );
                } else if (item.id === 'preferredSubjectArea') {
                  return (
                    <StyledTableCell
                      data-selenium-id={`SIPPage_Overview-Table-Body-IdCell1-${index}-${i}`}
                      VerticalPadding={'0px'}
                      lineHeight={'20px'}
                    >
                      <Box
                        style={{ whiteSpace: 'pre-line' }}
                        id={'SipPageOverviewTableBodyPreferredSubjectArea'}
                        data-selenium-id={
                          'SIPPage_Overview-Table-Body-IdCell1-showBox'
                        }
                      >
                        <div
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                          }}
                        >
                          <MuiTypography
                            sx={{
                              color: '#262E35',
                              fontSize: '14px',
                              fontWeight: '600',
                            }}
                          >
                            {' '}
                            {replaceNbsp(row[item.id], 252, '14px Open Sans')}
                          </MuiTypography>
                        </div>
                      </Box>
                    </StyledTableCell>
                  );
                } else if (item.id === 'leadGeName' && row[item.id] !== 'N/A') {
                  let LiteVoList = getGeLiteVoList(
                    row.leadGeName,
                    row.leadGeEmail
                  );
                  return (
                    <StyledTableCell
                      data-selenium-id={`SIPPage_Overview-Table-Body-IdCell1-${index}-${i}`}
                      VerticalPadding={'1px'} //SIPOverview的列高度
                      lineHeight={'20px'}
                    >
                      <Box
                        id={`sipLeadGE${row.sipCode}`}
                        data-selenium-id={`SIPPage-TableBodyLeadGE${row.sipCode}`}
                        sx={{ display: 'flex' }}
                        ref={ref => (leadGeRef.current[index] = ref)}
                      >
                        <Tooltip
                          placement='top-start'
                          open={false}
                          title={getGEShowString(LiteVoList)}
                          disableInteractive
                        >
                          <div
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                              color: '#262E35',
                              wordBreak: 'break-all',
                            }}
                          >
                            {LiteVoList.map(ge => ge?.fullName).join(';')}
                          </div>
                        </Tooltip>
                        {LiteVoList !== null &&
                          LiteVoList !== undefined &&
                          LiteVoList.length > 0 && (
                            <GeMenuIcon
                              id={'lead' + row.sipCode}
                              blueIsLeft={false}
                              data-selenium-id={'lead' + row.sipCode}
                              geList={LiteVoList}
                              anchorRef={leadGeRef.current[index]}
                              NoHref={true}
                              NoOverXHide={true}
                            />
                          )}
                      </Box>
                    </StyledTableCell>
                  );
                } else if (item.id === 'coGe' && row[item.id] !== 'N/A') {
                  let LiteVoList = getGeLiteVoList(row.coGe, row.coGeEmail);
                  return (
                    <StyledTableCell
                      data-selenium-id={`SIPPage_Overview-Table-Body-IdCell1-${index}-${i}`}
                      VerticalPadding={'1px'} //SIPOverview的列高度
                      lineHeight={'20px'}
                    >
                      <Box
                        id={`sipPageCoGE${row.sipCode}`}
                        data-selenium-id={`SIPPage-CoGE${row.sipCode}`}
                        sx={{ display: 'flex' }}
                        ref={ref => (coGeRef.current[index] = ref)}
                      >
                        <Tooltip
                          open={false}
                          placement='top-start'
                          data-selenium-id={`SIPPage-Box-Tooltip-${index}`}
                          title={getGEShowString(LiteVoList)}
                          disableInteractive
                        >
                          <div
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                              color: '#262E35',
                              wordBreak: 'break-all',
                            }}
                          >
                            {LiteVoList
                              .map(ge => ge?.fullName)
                              .join(';')}
                          </div>
                        </Tooltip>
                        {LiteVoList !== null &&
                          LiteVoList !== undefined &&
                          LiteVoList.length > 0 && (
                            <GeMenuIcon
                              id={'co' + row.sipCode}
                              blueIsLeft={false}
                              data-selenium-id={'co' + row.sipCode}
                              geList={LiteVoList}
                              NoHref={true}
                              anchorRef={coGeRef.current[index]}
                            />
                          )}
                      </Box>
                    </StyledTableCell>
                  );
                } else if (
                  //不需要再显示的内容
                  item.id !== 'sipCode'
                ) {
                  return (
                    <StyledTableCell
                      data-selenium-id={`SIPPage_Overview-Table-Body-IdCell1-${index}-${i}`}
                      VerticalPadding={'1px'} //SIPOverview的列高度
                      lineHeight={'20px'}
                    >
                      <MulLineTooltipIfShow
                        id={`SIPPage_Overview-Table-Body-IdCell1-${index}-${i}-tooltipContainer`}
                        label={row[item.id]}
                        outerRef={ref}
                      >
                        <MuiTypography
                          sx={{ color: '#262E35', fontSize: '14px' }}
                        >
                          {row[item.id]}
                        </MuiTypography>
                      </MulLineTooltipIfShow>
                    </StyledTableCell>
                  );
                }
              }
            )}
          </HoverTableRow>
        );
      } )
      ) : sipOverviewData !== undefined && sipOverviewData !== null ? (
        <NoDataMessage
          id='NoDataMessageAfterFiliter'
          MarginTop={'5px'}
          data-selenium-id='SIPPage-NoDataMessageAfterFiliter'
          message={
          'No results were found. Please adjust your filters and try again.'
        }
      ></NoDataMessage>
      ) : (
        <NoDataMessage
            id='NoDataMessageOfAll'
            MarginTop={'5px'}
            data-selenium-id='SIPPage-NoDataMessageOfAllFiliter'
            message={'No data to show'}
        ></NoDataMessage>
      )
      }
    </TableBody>
  );
}

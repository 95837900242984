import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CreateAndUpdateLittleTitle,
  CreateAndUpdateTitle,
} from '../../CommonComponents/CreateAndUpdateTitle';
import CreateSIDisabledBlock from '../../CommonComponents/CreateSIDisabledBlock';
import CreateSIDatePickerBlock from '../../CommonComponents/CreateSIDatePickerBlock';
import moment from 'moment';
import { openConfirm, setEditType } from '@/actions/SIMT-User/Alert/AlertAction';
import {
  DeadPassJuedge,
  getUTCToDate,
  clearTime,
  SISubmissionDeadlineDateChangeFunction,
} from '@/utils/commonUtils';
import CreateAndUpdateSwitch from '../../CommonComponents/CreateAndUpdateSwitch';
import CreateSIAutocompleteMultiple from '../../CommonComponents/CreateSIAutocompleteMultiple';
import { clearCreateGEResult } from '@/actions/SIMT-SI/GuestEditor/GEAction';
import CreateGEPage from '../../../../GEPage/Overview/CreateGE';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { set } from 'date-fns';
import { useUpdateEffect } from 'ahooks';
import { Stack } from '@mui/material';
import { DatePicker } from '@/components/DateRangePicker';
import {getSystemConfig} from "@/actions/SIMT-User/Config";
import {getGE} from "@/actions/SIMT-SI/SpecialIssue/GuestEditor";
import {judgeSubmissionPass} from "@/actions/SIMT-SI/SpecialIssue";
import {getStageList} from "@/actions/SIMT-SI/SpecialIssue/Config";
import {PERMISSIONS} from "@/constant/permission";

function StakeholdersAndDates(props) {
  //获取用户权限
  const userPermissions = localStorage
    .getItem('userPermissions')
    .includes(PERMISSIONS.ADMIN_WRITE);

  const {
    siCode,
    detailInfo,
    clickedButton,
    StakeholdersAndDatesdata,
    setStakeholdersAndDatesdata,
    setHasError,
    everyClick,
    setDeadlinePassed,
    MandatoryList,
    clickTime,
  } = props;

  const intl = useIntl(); // 为国际化提供的API支持
  const openForSubTitle = intl.messages['createSI.openForSub'];
  const openForSubErrorText = intl.messages['createSI.openForSubErrorText'];
  const [OpenSubDate, setOpenSubDate] = useState(null);
  const [IdeaDate, setIdeaDate] = useState(null);
  const [AcquiredDate, setAcquiredDate] = useState(null);
  const [ifAcquireDateisNull, setIfAcquireDateisNull] = useState(false);
  const [ExpectedSubmissionDeadline, setExpectedSubmissionDeadline] =
    useState(null);
  const [ExpectedAcceptanceDate, setExpectedAcceptanceDate] = useState(null);
  const [ExpectedPublicationDate, setExpectedPublicationDate] = useState(null);
  //close on submission
  const [checked, setChecked] = React.useState(
    StakeholdersAndDatesdata.closedSubmit === 'Yes' ? true : false
  );
  //  const [checked, setChecked] = React.useState(true)
  /**GE部分 */
  const [GeOpen, setGeOpen] = useState(false);
  const [compareThreeDate, setCompareThreeDate] = useState(false); //比较那三个时间，true为满足条件，false为不满足
  const [closedSubmit, setClosedSubmit] = useState('Yes');

  const [GE, setGE] = useState('');

  const [GEValue, setGEValue] = useState([]);
  const [LeadGEValue, setLeadGEValue] = useState([]);
  const [COGEValue, setCOGEValue] = useState([]);

  const [GERepeatErr, setGERepeatErr] = useState(false);

  const [CoGEData, setCoGEData] = useState([]);
  const [leadGEData, setLeadGEData] = useState([]);
  //Create GE open
  const [createOpen, setCreateOpen] = useState(false);
  const [EmailError, setEmailError] = useState(false);
  const dispatch = useDispatch();
  const [loadLock, setLoadLock] = useState(true);
  //   configInfo === null ? 20 : configInfo.eachSiGuestEditorMaxNumber

  const [isLead, setIsLead] = useState();

  const [geDataLoading, setGeDataLoading] = useState(true);

  //error信息
  const [emptyErrMsg, setEmptyErrMsg] = useState(
    'This attribute is mandatory to input'
  );
  const selector = state => {
    return {
      CEResult: state.SI.CE, //CE下拉框数据
      CreateGEResult: state.SI.createGEResult, //创建GE提示
      GEResult: state.SI.GE, //GE下拉框数据
      configInfo: state.SystemConfig.systemConfig,
      submissionJudgeValue: state.SI.submissionJudgeValue,
      stageList: state.SI.stageList,
    };
  };
  const {
    CEResult,
    CreateGEResult,
    GEResult,
    configInfo,
    submissionJudgeValue,
    stageList,
  } = useSelector(selector);

  const [maxGeNumber, setMaxGeNumber] = useState(20);

  useEffect(() => {
    dispatch(getStageList());
    dispatch(getSystemConfig());
  }, []);

  useEffect(() => {
    setMaxGeNumber(
      configInfo === null ? 20 : configInfo.eachSiGuestEditorMaxNumber
    );
  }, [configInfo]);

  useEffect(() => {
    setMaxGeNumber(
      configInfo === null ? 20 : configInfo.eachSiGuestEditorMaxNumber
    );
  }, [configInfo]);

  // create ge callback
  useEffect(() => {
    let result1 = CreateGEResult;
    if (CreateGEResult !== 'error' && CreateGEResult !== '') {
      if (CreateGEResult !== 'EmailExit') {
        setCreateOpen(false);
        setGeOpen(false);
        let GES = [result1];
        if (result1.isLead) {
          GES = LeadGEValue.concat(GES);
          setLeadGEValue(GES);
        } else {
          GES = COGEValue.concat(GES);
          setCOGEValue(GES);
        }
        dispatch(getGE('')); //更新GEdropdown 否则拿不到新数据
        dispatch(clearCreateGEResult());
      } else {
        setCreateOpen(true);
        setGeOpen(true);
        setEmailError(true);
      }
    }
  }, [CreateGEResult]);

  //SI变化改变的记录
  useEffect(() => {
    let data = JSON.parse(JSON.stringify(StakeholdersAndDatesdata));
    data.IdeaDate = IdeaDate;
    setStakeholdersAndDatesdata(data);
  }, [IdeaDate]);

  useEffect(() => {
    let data = JSON.parse(JSON.stringify(StakeholdersAndDatesdata));
    data.AcquiredDate = AcquiredDate;
    setStakeholdersAndDatesdata(data);
  }, [AcquiredDate]);

  useEffect(() => {
    let data = JSON.parse(JSON.stringify(StakeholdersAndDatesdata));
    data.OpenForSubmissionDate = OpenSubDate;
    setStakeholdersAndDatesdata(data);
  }, [OpenSubDate]);

  useEffect(() => {
    let data = JSON.parse(JSON.stringify(StakeholdersAndDatesdata));
    data.ExpectedSubmissionDeadline = ExpectedSubmissionDeadline;
    setStakeholdersAndDatesdata(data);
  }, [ExpectedSubmissionDeadline]);

  useEffect(() => {
    let data = JSON.parse(JSON.stringify(StakeholdersAndDatesdata));
    data.ExpectedAcceptanceDate = ExpectedAcceptanceDate;
    setStakeholdersAndDatesdata(data);
  }, [ExpectedAcceptanceDate]);

  useEffect(() => {
    let data = JSON.parse(JSON.stringify(StakeholdersAndDatesdata));
    data.ExpectedPublicationDate = ExpectedPublicationDate;
    setStakeholdersAndDatesdata(data);
  }, [ExpectedPublicationDate]);

  useEffect(() => {
    let data = JSON.parse(JSON.stringify(StakeholdersAndDatesdata));
    data.LeadGEValue = LeadGEValue;
    setStakeholdersAndDatesdata(data);
  }, [LeadGEValue]);

  useEffect(() => {
    let data = JSON.parse(JSON.stringify(StakeholdersAndDatesdata));
    data.COGEValue = COGEValue;
    setStakeholdersAndDatesdata(data);
  }, [COGEValue]);

  //选择日期的同时修改date选择框中的信息
  const handExpectedAcceptanceDateChange = newValue => {
    setExpectedAcceptanceDate((newValue));
  };

  const handExpectedPublicationDateChange = newValue => {
    setExpectedPublicationDate((newValue));
  };

  const handIdeaDateChange = newValue => {
    setIdeaDate(newValue);
  };

  const handAcquiredChange = newValue => {
    setAcquiredDate(newValue);
  };

  // OpenForSubmissionDate下拉框值改变时触发的函数
  const handleOpenSubDateChange = newValue => {
    setOpenSubDate(newValue);
  };

  const handSubmissionDeadlineChange = newValue => {
    // setExpectedSubmissionDeadline(clearTime(newValue));
    setExpectedSubmissionDeadline((newValue));
  };

  const handleLeadGESelect = value => {
    setLeadGEValue(value);
    setGE('');
  };
  const handleCOGeSelect = value => {
    if (value.length < maxGeNumber + 1) {
      setCOGEValue(value);
    }
    setGE('');
  };
  const getGEFromEmail = async value => {
    dispatch(getGE(value));
  };

  const isGERepeatErr = (array1, array2) => {
    for (let i = 0; i < array1.length; i++) {
      for (let j = 0; j < array2.length; j++) {
        if (array1[i].primaryEmail === array2[j].primaryEmail) {
          return true;
        }
      }
    }
    return false;
  };

  const handleCreateClose = () => {
    setCreateOpen(false);
    setGeOpen(false);
  };

  const handleCreateOpen = isLead => {
    if (
      (isLead && LeadGEValue.length < maxGeNumber) ||
      (!isLead && COGEValue.length < maxGeNumber)
    ) {
      setCreateOpen(true);
      setGeOpen(true);
    }
  };
  //判断一个值是否为空或者空串
  const isEmptyValue = value => {
    return (
      value === null ||
      value === '' ||
      value === undefined ||
      value.length === 0 ||
      value === 'Invalid date'
    );
  };

  const MandatoryJudge = (value, list) => {
    return list.indexOf(value) > -1;
  };

  useEffect(() => {
    setTimeout(() => {
      setGeDataLoading(false);
    }, 10000);
    let result = GEResult;
    if (result != null && result !== 'error') {
      setCoGEData(result);
      setLeadGEData(result);
    }
  }, [GEResult]);

  //延迟获取GE值
  useEffect(() => {
    setGeDataLoading(true);
    if (GE === ' ') {
      getGEFromEmail(GE);
    } else {
      let active = true;
      (async () => {
        if (GE !== undefined && GE !== '') {
          await sleep(1e3); // For demo purposes.
        }
        if (active) {
          if (GE !== undefined) {
            getGEFromEmail(GE);
          }
        }
      })();

      return () => {
        active = false;
      };
    }
  }, [GE]);

  useEffect(() => {
    if (isGERepeatErr(COGEValue, LeadGEValue)) {
      setGERepeatErr(true);
    } else {
      setGERepeatErr(false);
    }
    if (!loadLock) {
      getGEFromEmail('');
    }
    setTimeout(() => {
      setLoadLock(false);
    }, 2000);
  }, [LeadGEValue, COGEValue]);

  useEffect(() => {
    if (ExpectedSubmissionDeadline != null){
      let ExpectedSubmissionDeadlineUTCDate = new Date(ExpectedSubmissionDeadline);
      let ExpectedSubmissionDeadlineUTC = Date.UTC(
        ExpectedSubmissionDeadlineUTCDate.getUTCFullYear(),
        ExpectedSubmissionDeadlineUTCDate.getUTCMonth(),
        ExpectedSubmissionDeadlineUTCDate.getUTCDate(),
        23,
        59,
        59,
        0
      );
      const ExpectedAcceptanceDateUTC = new Date(ExpectedAcceptanceDate).getTime();
      const ExpectedPublicationDateUTC = new Date(ExpectedPublicationDate).getTime();
      setCompareThreeDate(
        ExpectedSubmissionDeadlineUTC < ExpectedAcceptanceDateUTC &&
        ExpectedAcceptanceDateUTC < ExpectedPublicationDateUTC
      );
    } else {
      setCompareThreeDate(false);
    }

  }, [
    ExpectedSubmissionDeadline,
    ExpectedAcceptanceDate,
    ExpectedPublicationDate,
  ]);

  function sleep(delay = 0) {
    return new Promise(resolve => {
      setTimeout(resolve, delay);
    });
  }

  /**日期部分 */
  //idea和acquired日期比较结果,idea大于acquired为true
  const [ideaDateGreaterDateOfGet, setIdeaDateGreaterDateOfGet] =
    React.useState(false);
  const [submissionDeadlineGreaterOfGet, setSubmissionDeadlineGreaterOfGet] =
    React.useState(false);
  const [DeadlineBeforeAcceptanceDate, setDeadlineBeforeAcceptanceDate] =
    React.useState(false);
  const [PublicationDateCmp, setPublicationDateCmp] = React.useState(false);

  useEffect(() => {
    if (IdeaDate !== null && AcquiredDate !== null) {
      setIdeaDateGreaterDateOfGet(
        moment(IdeaDate).isAfter(moment(AcquiredDate))
      );
    } else {
      setIdeaDateGreaterDateOfGet(false);
    }
  }, [IdeaDate, AcquiredDate]);

  useEffect(() => {
    setDeadlineBeforeAcceptanceDate(
      ExpectedAcceptanceDate !== null &&
        ExpectedSubmissionDeadline !== null &&
        ExpectedAcceptanceDate !== 'Invalid date' &&
        ExpectedSubmissionDeadline !== 'Invalid date' &&
        !moment(ExpectedSubmissionDeadline).isBefore(
          moment(ExpectedAcceptanceDate)
        )
    );
  }, [ExpectedAcceptanceDate, ExpectedSubmissionDeadline]);
  useEffect(() => {
    let newPublicationDateGreaterOfGet = false;
    if (ExpectedPublicationDate !== null) {
      newPublicationDateGreaterOfGet =
        (ExpectedSubmissionDeadline &&
          !moment(ExpectedSubmissionDeadline).isBefore(
            moment(ExpectedPublicationDate)
          )) ||
        (ExpectedAcceptanceDate &&
          !moment(ExpectedAcceptanceDate).isBefore(
            moment(ExpectedPublicationDate)
          ));
    }
    setPublicationDateCmp(newPublicationDateGreaterOfGet);
  }, [
    ExpectedAcceptanceDate,
    ExpectedSubmissionDeadline,
    ExpectedPublicationDate,
  ]);
  //都是监听的ExpectedPublicationDate的变化设置的setPublicationDateCmp会有问题
  // useEffect(() => {
  //   if (ExpectedAcceptanceDate !== null &&
  //     ExpectedPublicationDate !== null &&
  //     ExpectedAcceptanceDate !== "Invalid date" &&
  //     ExpectedPublicationDate !== "Invalid date") {
  //     setPublicationDateCmp(
  // !moment(ExpectedAcceptanceDate).isBefore(moment(ExpectedPublicationDate))
  //     )
  //   } else {
  //     setPublicationDateCmp(false)
  //   }
  // }, [ExpectedAcceptanceDate, ExpectedPublicationDate])

  //OpenSubDate和ExpectedSubmissionDeadline日期比较结果,OpenSubDate大于或者等于ExpectedSubmissionDeadline为true
  const [openForSubGreaterDateOfGet, setOpenForSubGreaterDateOfGet] =
    useState(false);
  useEffect(() => {
    //console.log("OpenSubDate", OpenSubDate === "Invalid date", ExpectedSubmissionDeadline, openForSubGreaterDateOfGet)
    if (
      OpenSubDate !== null &&
      ExpectedSubmissionDeadline !== null &&
      OpenSubDate !== 'Invalid date' &&
      ExpectedSubmissionDeadline !== 'Invalid date' &&
      stage !== 'Acquired'
    ) {
      setSubmissionDeadlineGreaterOfGet(
        !moment(OpenSubDate).isBefore(moment(ExpectedSubmissionDeadline))
      );
    } else {
      setSubmissionDeadlineGreaterOfGet(false);
    }
  }, [OpenSubDate, ExpectedSubmissionDeadline]);

  //si注入
  useEffect(() => {
    //下拉框
    dispatch(getGE(''));
    //GE的获取
    let LeadGESA = [];
    let LeadGES = StakeholdersAndDatesdata.LeadGEValue;
    if (LeadGES !== undefined) {
      for (const element of LeadGES) {
        LeadGESA.push(element);
      }
    }
    handleLeadGESelect(LeadGESA);

    let COGESA = [];
    let COGES = StakeholdersAndDatesdata.COGEValue;
    if (COGES !== undefined) {
      for (const element of COGES) {
        COGESA.push(element);
      }
      handleCOGeSelect(COGESA);
    }

    //日期选择
    if (
      StakeholdersAndDatesdata.IdeaDate === null ||
      StakeholdersAndDatesdata.IdeaDate === undefined ||
      StakeholdersAndDatesdata.IdeaDate === ''
    ) {
      setIdeaDate(null);
    } else {
      if (moment(StakeholdersAndDatesdata.IdeaDate).isValid()) {
        setIdeaDate(
          moment(StakeholdersAndDatesdata.IdeaDate).utc().format('YYYY-MM-DD')
        );
      } else {
        setIdeaDate(null);
      }
    }
    if (
      StakeholdersAndDatesdata.AcquiredDate !== null &&
      StakeholdersAndDatesdata.AcquiredDate !== undefined &&
      StakeholdersAndDatesdata.AcquiredDate !== ''
    ) {
      setAcquiredDate(
        moment(StakeholdersAndDatesdata.AcquiredDate).utc().format('YYYY-MM-DD')
      );
    }
    if (
      detailInfo.generalInformation[0].expectedAcceptanceDate !== null &&
      detailInfo.generalInformation[0].expectedAcceptanceDate !== undefined &&
      detailInfo.generalInformation[0].expectedAcceptanceDate !== ''
    ) {
      setIfAcquireDateisNull(false);
    } else {
      setIfAcquireDateisNull(true);
    }

    if (StakeholdersAndDatesdata.OpenForSubmissionDate === null) {
      setOpenSubDate(null);
    } else {
      setOpenSubDate(
        moment(StakeholdersAndDatesdata.OpenForSubmissionDate).utc().format(
          'YYYY-MM-DD'
        )
      );
    }

    if (StakeholdersAndDatesdata.ExpectedSubmissionDeadline) {
      setExpectedSubmissionDeadline(
        moment(StakeholdersAndDatesdata.ExpectedSubmissionDeadline).utc().format(
          'YYYY-MM-DD'
        )
      );
    }
    if (StakeholdersAndDatesdata.ExpectedAcceptanceDate) {
      setExpectedAcceptanceDate(
        moment(StakeholdersAndDatesdata.ExpectedAcceptanceDate).utc().format(
          'YYYY-MM-DD'
        )
      );
    }
    if (StakeholdersAndDatesdata.ExpectedPublicationDate) {
      setExpectedPublicationDate(
        moment(StakeholdersAndDatesdata.ExpectedPublicationDate).utc().format(
          'YYYY-MM-DD'
        )
      );
    }
  }, [siCode]);

  //计算是否过了deadline(Deadline Passed)
  const calDeadlinePassed = () => {
    var date = SISubmissionDeadlineDateChangeFunction(
      ExpectedSubmissionDeadline
    );
    dispatch(judgeSubmissionPass(date));
  };

  useEffect(() => {
    calDeadlinePassed();
  }, [ExpectedSubmissionDeadline]);

  useEffect(() => {
    setDeadlinePassed(submissionJudgeValue);
  }, [submissionJudgeValue]);

  //Closed For Submission
  const handleSwitchChange = event => {
    let data = JSON.parse(JSON.stringify(StakeholdersAndDatesdata));
    if (stage === 'Published' || stage === 'Shelved') {
      event.target.checked = true;
    }
    data.closedSubmit = event.target.checked === true ? 'Yes' : 'No';
    setStakeholdersAndDatesdata(data);
    setChecked(event.target.checked);
    setClosedSubmit(event.target.checked === true ? 'Yes' : 'No');
  };

  //获取当前页面的url
  const url = window.location.pathname.split('/');
  //获得 stage
  const getStage = () => {
    let stage = url[url.length - 1];
    switch (stage) {
      case 'Acquired':
      case 'Production':
      case 'Published':
        return stage;
      case 'PaperCommission':
        return 'Paper Commission';
      case 'SubmissionAndReview':
        return 'Submission and Review';
      case 'Shelved':
        return 'Shelved';
      default:
        return '';
    }
  };
  const stage = getStage();

  // 判断 Open For Submission Date 时间选择器是否是必选项
  const getOpenForSubDateRequired = () => {
    return MandatoryJudge(openForSubTitle, MandatoryList);
  };

  const isOpenForSubDateRequired = getOpenForSubDateRequired();

  //错误信息的处理-----------//
  //统计错误
  const check = () => {
    return (
      ideaDateGreaterDateOfGet ||
      openForSubGreaterDateOfGet ||
      DeadlineBeforeAcceptanceDate ||
      PublicationDateCmp ||
      GERepeatErr ||
      submissionDeadlineGreaterOfGet ||
      AcquiredDate === null ||
      ExpectedAcceptanceDate === null ||
      ExpectedSubmissionDeadline === null ||
      ExpectedPublicationDate === null ||
      ExpectedAcceptanceDate === 'Invalid date' ||
      ExpectedSubmissionDeadline === 'Invalid date' ||
      ExpectedPublicationDate === 'Invalid date' ||
      (isOpenForSubDateRequired && isEmptyValue(OpenSubDate)) ||
      (MandatoryJudge('Lead-GE', MandatoryList) && LeadGEValue.length === 0) ||
      (MandatoryJudge('Co-GE', MandatoryList) && COGEValue.length === 0) ||
      (MandatoryJudge('Idea Date', MandatoryList) && IdeaDate === null)
    );
  };
  const currentPageHasError = check();

  //当前页面是否有错误的设置
  useEffect(() => {
    setHasError(currentPageHasError);
  }, [currentPageHasError]);

  // 记录错误地方的ID
  const recoredError = () => {
    if (MandatoryJudge('Lead-GE', MandatoryList) && LeadGEValue.length === 0) {
      return 'UpdatepaperComissionStageCreateSILeadGEAutocompleteMultiple_LeadGE';
    } else if (
      (MandatoryJudge('Co-GE', MandatoryList) && COGEValue.length === 0) ||
      GERepeatErr
    ) {
      return 'UpdatepaperComissionStageCreateSILeadGEAutocompleteMultiple_COGE';
    } else if (
      (MandatoryJudge('Idea Date', MandatoryList) && !IdeaDate) ||
      ideaDateGreaterDateOfGet
    ) {
      return 'UpdatepaperComissionStageCreateSIDatePickerIdeaDateBlock';
    } else if (
      ((userPermissions || ifAcquireDateisNull) && !AcquiredDate) ||
      ideaDateGreaterDateOfGet
    ) {
      return 'UpdatepaperComissionStageCreateSIDatePickeracquiredDateBlock';
    } else if (
      stage !== 'Acquired' &&
      ((isOpenForSubDateRequired && isEmptyValue(OpenSubDate)) ||
        openForSubGreaterDateOfGet)
    ) {
      return 'createSIProductionOpenForSubmissionDate';
    } else if (
      !ExpectedSubmissionDeadline ||
      ExpectedSubmissionDeadline === 'Invalid date' ||
      submissionDeadlineGreaterOfGet
    ) {
      return 'UpdatepaperComissionStageCreateSIDatePickersubmissionDeadlineBlock';
    } else if (
      !ExpectedAcceptanceDate ||
      ExpectedAcceptanceDate === 'Invalid date' ||
      DeadlineBeforeAcceptanceDate
    ) {
      return 'UpdatepaperComissionStageCreateSIDatePickerExpectedAcceptanceDateBlock';
    } else if (
      !ExpectedPublicationDate ||
      ExpectedPublicationDate === 'Invalid date' ||
      PublicationDateCmp
    ) {
      return 'UpdatepaperComissionStageCreateSIDatePickerExpectedPublicationDateBlock';
    }
    return 'zhutou';
  };
  // useUpdateEffect 初次进入页面不会渲染 ， 出错的话再进行定位，先定位到顶部，在定位到屏幕中间
  useUpdateEffect(() => {
    if (check()) {
      let currentDOM = document.getElementById('' + recoredError());
      if (currentDOM) {
        setTimeout(() => {
          window.scrollTo({ left: 0, top: 0, behavior: 'instant' });
          window.scroll({
            left: 0,
            top:
              currentDOM.getBoundingClientRect().top -
              window.screen.availHeight / 2 +
              100,
            behavior: 'instant',
          });
        }, 200);
      }
    }
  }, [clickTime]);

  function yestoday(date){
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 1);
    return newDate;
  }

  function tomorrow(date){
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    return newDate;
  }

  return (
    // JPM
    <div data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div'>
      <CreateAndUpdateTitle
        data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-Title'
        title={`Update Stage to ${stage}`}
      />
      <CreateAndUpdateLittleTitle
        data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-LittleTitle1'
        littleTitle='Stakeholders'
      />
      <Stack spacing={2} direction='column' sx={{ marginBottom: '20px' }}>
        <CreateSIDisabledBlock
          title='JPM'
          defaultValue={StakeholdersAndDatesdata.JPM}
          id='UpdatepaperComissionStageDisabledJPMBlock'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-DisabledJPMBlock'
        />
        {/*Previous handling*/}
        {detailInfo.generalInformation[0].previousHandlingCe[0] && (
          <CreateSIDisabledBlock
            title='Previous Handling CE'
            defaultValue={
              String(
                detailInfo.generalInformation[0].previousHandlingCe[0]
              ).split('(')[0]
            }
            id='UpdatepaperComissionStageDisabledPreviousHandlingCEBlock'
            data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-DisabledPreviousHandlingCEBlock'
          />
        )}
        {/* lead GE */}
        <CreateSIAutocompleteMultiple
          emptyErr={
            !clickedButton &&
            MandatoryJudge('Lead-GE', MandatoryList) &&
            LeadGEValue.length === 0
          }
          id='createSIAutocompleteMultipleLeadGE'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-CreateSILeadGEAutocompleteMultiple'
          title='Lead GE'
          required={MandatoryJudge('Lead-GE', MandatoryList)}
          Value={LeadGEValue}
          options={leadGEData}
          getOptionLabel={option => option.lastName + ', ' + option.firstName}
          maxGeNum={maxGeNumber}
          handleAddNewClick={() => {
            setIsLead(true);
            handleCreateOpen(true);
          }}
          onFocus={() => {
            setGeDataLoading(true);
            dispatch(getGE(''));
          }}
          onChange={(event, newValue) => {
            //判断GE是否有重复(使用邮箱比对进行判断，如果重复不能进入 handleGeSelect 进行填充)
            function judgeRepetition() {
              //GE为空或只有一个元素时不做判断
              if (newValue.length === 0 || newValue.length === 1) {
                return true;
              } else {
                for (let i = 1; i < newValue.length; i++) {
                  if (
                    newValue[newValue.length - 1].primaryEmail ===
                    newValue[i - 1].primaryEmail
                  ) {
                    return false;
                  }
                }
                return true;
              }
            }
            // GE数量小于20，以及不重复时记录填充给操作
            if (newValue.length < maxGeNumber + 1 && judgeRepetition()) {
              handleLeadGESelect(newValue);
            }
          }}
          error={false}
          helperText={<FormattedMessage id='createSI.geRepeatErr' />}
          onLoading={geDataLoading}
        />

        {/* CO-GE */}
        <CreateSIAutocompleteMultiple
          emptyErr={
            !clickedButton &&
            MandatoryJudge('Co-GE', MandatoryList) &&
            COGEValue.length === 0
          }
          id='createSIAutocompleteMultipleCoGE'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-CreateSILeadGEAutocompleteMultiple'
          title='Co-GE'
          required={MandatoryJudge('Co-GE', MandatoryList)}
          Value={COGEValue}
          options={CoGEData}
          getOptionLabel={option => option.lastName + ', ' + option.firstName}
          onFocus={() => {
            setGeDataLoading(true);
            dispatch(getGE(''));
          }}
          onChange={(event, newValue) => {
            //判断GE是否有重复(使用邮箱比对进行判断，如果重复不能进入 handleGeSelect 进行填充)
            function judgeRepetition() {
              //GE为空或只有一个元素时不做判断
              if (newValue.length === 0 || newValue.length === 1) {
                return true;
              } else {
                for (let i = 1; i < newValue.length; i++) {
                  if (
                    newValue[newValue.length - 1].primaryEmail ===
                    newValue[i - 1].primaryEmail
                  ) {
                    return false;
                  }
                }
                return true;
              }
            }
            if (newValue.length < maxGeNumber + 1 && judgeRepetition()) {
              handleCOGeSelect(newValue);
            }
          }}
          onInputChange={() => {}}
          error={GERepeatErr}
          helperText={<FormattedMessage id='createSI.geRepeatErr' />}
          maxGeNum={maxGeNumber}
          handleAddNewClick={() => {
            handleCreateOpen();
            setIsLead(false);
            handleCreateOpen(false);
          }}
          onLoading={geDataLoading}
        />
      </Stack>

      <CreateAndUpdateLittleTitle
        id='createSIGe&DateDatestitle'
        data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-LittleTitle2'
        littleTitle='Dates'
      />
      <Stack spacing={2} direction='column'>
        {/* idea date */}
        <CreateSIDatePickerBlock
          title='Idea Date'
          required={MandatoryJudge('Idea Date', MandatoryList)}
          value={IdeaDate}
          setValue={setIdeaDate}
          handleChange={handIdeaDateChange}
          placeholder='YYYY-MM-DD'
          emptyErr={
            !clickedButton &&
            MandatoryJudge('Idea Date', MandatoryList) &&
            !IdeaDate
          }
          error={ideaDateGreaterDateOfGet}
          helperText='[Idea Date] should be before or equal to [Acquired Date]'
          id='createSIDatePickerBlockIdeaDate'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-CreateSIDatePickerIdeaDateBlock'
          maxDate={AcquiredDate ? tomorrow(AcquiredDate) : undefined}
        ></CreateSIDatePickerBlock>

        {/* Acquired date */}
        {!userPermissions && !ifAcquireDateisNull ? (
          <CreateSIDisabledBlock
            title='Acquired Date'
            defaultValue={moment(
              detailInfo.generalInformation[0].acquiredDate
            ).utc().format('YYYY-MM-DD')}
          />
        ) : (
          <CreateSIDatePickerBlock
            title='Acquired Date'
            required={true}
            value={AcquiredDate}
            //setValue={setAcquiredDate}
            handleChange={handAcquiredChange}
            placeholder='YYYY-MM-DD'
            emptyErr={!clickedButton && !AcquiredDate}
            error={ideaDateGreaterDateOfGet}
            minDate={IdeaDate ? yestoday(IdeaDate) : undefined}
            // maxDate={ExpectedSubmissionDeadline ? new Date(ExpectedSubmissionDeadline) : undefined}
            helperText='[Acquired Date] should be after or equal to [Idea Date]!'
            id='createSIDatePickerBlockAcquiredDate'
            data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-CreateSIDatePickeracquiredDateBlock'
          ></CreateSIDatePickerBlock>
        )}

        {/* Open for Submission Date */}
        {stage !== 'Acquired' ? (
          <CreateSIDatePickerBlock
            title={openForSubTitle}
            required={isOpenForSubDateRequired}
            value={OpenSubDate}
            placeholder='YYYY-MM-DD'
            emptyErr={
              !clickedButton &&
              isOpenForSubDateRequired &&
              isEmptyValue(OpenSubDate)
            }
            handleChange={handleOpenSubDateChange}
            error={openForSubGreaterDateOfGet}
            helperText={openForSubErrorText}
            // maxDate={ExpectedSubmissionDeadline ? new Date(ExpectedSubmissionDeadline) : undefined}
            id='createSIDatePickerBlockOpenForSubmissionDate'
            data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-createSIProductionOpenForSubmissionDate'
          ></CreateSIDatePickerBlock>
        ) : null}

        {/* submission Deadline */}
        <CreateSIDatePickerBlock
          title='Submission Deadline'
          required={true}
          value={ExpectedSubmissionDeadline}
          // setValue={setExpectedSubmissionDeadline}
          minDate={
            stage !== 'Acquired'
              ? OpenSubDate === null
                ? undefined
                : new Date(moment(OpenSubDate, 'YYYY-MM-DD')).local2utc()
              : AcquiredDate === null
                ? undefined
                : new Date(moment(AcquiredDate, 'YYYY-MM-DD')).local2utc()
          }
          // maxDate={
          //   ExpectedAcceptanceDate
          //     ? new Date(ExpectedAcceptanceDate)
          //     : undefined
          // }
          handleChange={handSubmissionDeadlineChange}
          placeholder='YYYY-MM-DD'
          emptyErr={
            !clickedButton &&
            (isEmptyValue(ExpectedSubmissionDeadline) ||
              !ExpectedSubmissionDeadline ||
              ExpectedSubmissionDeadline === 'Invalid date')
          }
          id='createSIDatePickerBlockSubmissionDeadline'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-CreateSIDatePickersubmissionDeadlineBlock'
          error={submissionDeadlineGreaterOfGet}
          helperText='[Submission Deadline] should be after [Open for Submission Date]!'
        ></CreateSIDatePickerBlock>

        {/* Expected Acceptance Date */}
        <CreateSIDatePickerBlock
          title='Expected Acceptance Date'
          required={true}
          value={ExpectedAcceptanceDate}
          // setValue={setExpectedAcceptanceDate}
          handleChange={handExpectedAcceptanceDateChange}
          placeholder='YYYY-MM-DD'
          minDate={
            ExpectedSubmissionDeadline === null
              ? undefined
              : new Date(moment(ExpectedSubmissionDeadline, 'YYYY-MM-DD'))
          }
          // maxDate={
          //   ExpectedPublicationDate
          //     ? new Date(ExpectedPublicationDate)
          //     : undefined
          // }
          emptyErr={
            !clickedButton &&
            (!ExpectedAcceptanceDate ||
              ExpectedAcceptanceDate === 'Invalid date')
          }
          error={DeadlineBeforeAcceptanceDate}
          helperText='[Acceptance Date] should be after [Submission Deadline]!'
          id='createSIDatePickerBlockAcceptanceDate'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-CreateSIDatePickerExpectedAcceptanceDateBlock'
        ></CreateSIDatePickerBlock>

        {/* Expected Publication Date */}
        <CreateSIDatePickerBlock
          title='Expected Publication Date'
          required={true}
          value={ExpectedPublicationDate}
          //setValue={setExpectedPublicationDate}
          handleChange={handExpectedPublicationDateChange}
          placeholder='YYYY-MM-DD'
          emptyErr={
            !clickedButton &&
            (!ExpectedPublicationDate ||
              ExpectedPublicationDate === 'Invalid date')
          }
          minDate={
            ExpectedAcceptanceDate === null
              ? undefined
              : new Date(moment(ExpectedAcceptanceDate, 'YYYY-MM-DD'))
          }
          error={PublicationDateCmp}
          helperText='[Publication Date] should be after [Acceptance Date] and [Submission Deadline]!'
          id='createSIDatePickerBlockPublicationDate'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-CreateSIDatePickerExpectedPublicationDateBlock'
        ></CreateSIDatePickerBlock>
        <CreateSIDisabledBlock
          title='Deadline Passed'
          value={submissionJudgeValue ? 'Yes' : 'No'}
          defaultValue='No'
          id='UpdatepaperComissionStageDisabledDeadlinePassedBlock'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-DisabledDeadlinePassedBlock'
          submissionPass={submissionJudgeValue}
        />

        {/* Closed For Submission */}

        <CreateAndUpdateSwitch
          setValue={setChecked}
          // defaultChecked={true}
          checked={checked}
          defaultChecked={
            stage === 'Published' || stage === 'Shelved' ? true : false
          }
          disabled={stage === 'Published'}
          onChange={handleSwitchChange}
          id='createAndUpdateSwitchClosedforSubmission'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-ClosedForSubmission'
          title='Closed for Submission'
          required={
            MandatoryJudge('Closed for submission?', MandatoryList) ? '*' : ''
          }
        />
      </Stack>

      <div data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-Div'>
        {/*  GE pop-up */}
        <CreateGEPage
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-StakeholdersAndDatesForm-Div-Div-CreateGEPage'
          isLead={isLead}
          open={GeOpen}
          handleCreateGEPageClose={handleCreateClose}
        ></CreateGEPage>
      </div>
    </div>
  );
}
export default StakeholdersAndDates;

import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { WindowHeightContext } from '../../../Context';
import React from 'react';

export default function FrameworkMain(props) {
  const windowHeight = React.useContext(WindowHeightContext);
  return (
    <Scrollbars
      style={{ height: `${windowHeight-229-(window.location.href.includes('sip/overview')?60:0)}px` }}
      renderTrackVertical={({ style, ...props }) => (
        <div
          style={{
            ...style,
            position: 'absolute',
            top: '69px',
            height: 'calc(100% - 69px)',
            right: '1px',
          }}
          {...props}
        ></div>
      )}
      renderTrackHorizontal={({ style, ...props }) => (
        <div
          style={{
            ...style,
            position: 'absolute',
            width: '0px',
            height: '0px',
          }}
          {...props}
        ></div>
      )}
      renderThumbVertical={({ style, ...props }) => (
        <div
          style={{
            ...style,
            backgroundColor: '#DFE4E8',
            opacity: '0.7',
            borderRadius: '10px',
            width: '6px',
          }}
          {...props}
        ></div>
      )}
      renderView={({ style, ...props }) => (
        <div
          id='sioverview-filter-scrollbox'
          style={{
            ...style,
            overflow: 'auto',
            paddingBottom: '20px',
            // height: `${windowHeight-229}px`
          }}
          {...props}
        ></div>
      )}
      // thumbSize={scrollProps?.scrollThumbSizeSX ?? 69}
    >
      <Stack
        spacing={2}
        direction='column'
        sx={{ marginTop: '69px', padding: '0px 16px 16px' }}
      >
        {props.children}
      </Stack>
    </Scrollbars>
  );
}

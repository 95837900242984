import { useState, useEffect, useMemo, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import styles from './index.module.scss';
import { MultipleSelect } from '@/components/Chart/ChartSelectors';
import CloseIcon from '@/assets/CloseIcon.svg';
import Chart from './Chart';
import NoDataPage from '@/components/Chart/NoDataPage';
import LoadingPage from '@/components/Chart/LoadingPage';
import ErrorPage from '@/components/Chart/ErrorPage';
import useChartsDataHook from './hooks/index';
import useGetDiagramStatusHook from './hooks/useGetDiagramStatusHook';
import ReportSaveMenuButton from '@/components/Chart/chartButton';
import { ReportDownloadMenuButton } from '@/components/Chart/chartButton';
import {
  saveImageAsPng,
  saveImageAsJpg,
  checkEmptyArrayData,
} from '@/pages/Report/chartUtils';
import { GetReportExcel, getReportExcelParam } from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import { ARTICLE, EXCEL_HEADER,toLowerCaseFirstLetter } from '../Constants/index';
import {
  LOADING,
  ERROR,
  VALID,
  NO_DATA,
  NO_SELECTION_FROM_DROPDOWN_MENU,
  // NO_DISPLAYED_DATA,
} from '@/pages/Report/chartUtils/constants.js';
import { getDownloadFileName } from '@/pages/Report/utils/getDownloadFileName';

const ChartContainer = ({
  type,
  header,
  subHeader,
  cancelSelectedChart,
  selectedMonth,
  hasCategoryFilter,
  hasSelectAllOption,
  url,
  tableDownloadTitle,
  tableDownloadUrl,
  reportType,
}) => {
  const reduxTitle = `${ARTICLE}/${header}/${subHeader}/${selectedMonth}`;

  const [selectedCatagories, setSelectedCatagories] = useState([]);

  const [catagoriesFullList, dataForBar, unfilteredDataForBar, loading, error] =
    useChartsDataHook(type, selectedCatagories, url, reduxTitle, selectedMonth);

  useEffect(() => {
    setSelectedCatagories(catagoriesFullList);
  }, [catagoriesFullList]);

  /**
   * check if there is no data for diagram
   *
   * will disable download and export buttons
   */
  const isEmptyData = useMemo(() => {
    /**
     * check if data for diagram is empty (including all 0)
     */
    if (!unfilteredDataForBar) return true;
    return checkEmptyArrayData(unfilteredDataForBar);
  }, [unfilteredDataForBar]);

  const alertLabel = subHeader;

  const { valid: digramStatus, info } = useGetDiagramStatusHook(
    unfilteredDataForBar,
    selectedCatagories,
    hasCategoryFilter,
    alertLabel,
    loading,
    error
  );

  console.log('==digramStatus===', digramStatus);
  console.log('==info===', info);

  const [chartWidth, setChartWidth] = useState('100%');

  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);
  const downloadlink = useRef();

  const dispatch = useDispatch();

  // dependencies: Tab1, Tab2, Level, selected time,  
  const fileName = useMemo(() => {
    const rmSpaceSubHeader = subHeader.replaceAll(' ', '');
    const tab1Name = tableDownloadTitle;
    const tab2Name = 'Annual Trends';
    const level = rmSpaceSubHeader;
    return getDownloadFileName(tab1Name, tab2Name, level, selectedMonth);
  }, [tableDownloadTitle, header, subHeader, selectedMonth]);
    
  const exportParam = useMemo(()=>{
    return EXCEL_HEADER[tableDownloadTitle];
  },[tableDownloadTitle]);


  const downloadChart = () => {
    const selectName = toLowerCaseFirstLetter(subHeader.replaceAll(' ',''));
    dispatch(
      getReportExcelParam(selectedMonth, tableDownloadUrl, fileName, 
        {
          ...exportParam,
          [selectName]:selectedCatagories,
          performance:false,
        }  
      )
    );
  };

  const savePng = () => {
    saveImageAsPng(chartRef.current, fileName);
  };

  const saveJpg = () => {
    saveImageAsJpg(chartRef.current, fileName);
  };

  // listen to chart-container size change
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      resizeHandler(entries);
    });
    resizeObserver.observe(chartContainerRef.current);
    return () => {
      if (chartContainerRef.current) {
        resizeObserver.unobserve(chartContainerRef.current);
      }
    };
  }, []);

  function resizeHandler(entries) {
    const chartContainer = entries.find(
      entry => entry.target == chartContainerRef.current
    );
    const width = chartContainer?.target.clientWidth;
    setChartWidth(width);
  }

  return (
    <div className={styles['chart-container']} ref={chartContainerRef}>
      <Box className={styles['chart-header']}>
        <div className={styles['selectors']}>
          {hasCategoryFilter && (
            <span id='category-select'>
              <MultipleSelect
                label={subHeader}
                alertLabel={subHeader}
                options={catagoriesFullList}
                selectedItems={selectedCatagories ?? []}
                setSelectedItems={setSelectedCatagories}
                hasSelectAllOption={hasSelectAllOption}
                // disabled={isEmptyData}
                disabled={[NO_DATA, LOADING, ERROR].indexOf(digramStatus) >= 0}
              />
            </span>
          )}
        </div>
        <div className={styles['chart-titles']}>
          <h1 className={styles['chart-title']}>{header}</h1>
          <h2 className={styles['chart-subtitle']}>{subHeader + ' Level'}</h2>
        </div>
        <div className={styles['buttons']}>
          <ReportDownloadMenuButton
            func={downloadChart}
            // disabled={isEmptyData}
            disabled={digramStatus !== VALID}
          />
          <ReportSaveMenuButton
            items={[
              {
                label: 'Save as PNG',
                operation: savePng,
              },
              {
                label: 'Save as JPG',
                operation: saveJpg,
              },
            ]}
            // disabled={isEmptyData}
            disabled={digramStatus !== VALID}
          />
          <button
            onClick={cancelSelectedChart}
            className={styles['button-close']}
          >
            <CloseIcon />
          </button>
        </div>
      </Box>

      {digramStatus === LOADING && <LoadingPage />}

      {digramStatus === ERROR && <ErrorPage />}

      {digramStatus === NO_DATA && (
        <NoDataPage
          title={`There is no data for any ${info}s on the selected date.`}
          subTitle={'Please choose a different date to view diagram.'}
        />
      )}

      {digramStatus === NO_SELECTION_FROM_DROPDOWN_MENU && (
        <NoDataPage title={`Please select at least one ${info}.`} />
      )}

      {digramStatus === VALID && (
        <>
          <Box className={styles['chart']} ref={chartRef}>
            <Chart
              selectedMonth={selectedMonth}
              width={chartWidth}
              hasZoom={true}
              hasTitle={false}
              title={header}
              subTitle={subHeader + ' Level'}
              diagramType={type}
              dataForBar={dataForBar}
              isDownload={false}
            />
          </Box>
          <Box className={styles['chart-download']} ref={chartRef}>
            <Chart
              selectedMonth={selectedMonth}
              width={chartWidth}
              hasZoom={false}
              hasTitle={true}
              title={header}
              subTitle={subHeader + ' Level'}
              diagramType={type}
              dataForBar={dataForBar}
              isDownload={true}
            />
          </Box>
          <a
            href='javascript:void(0)'
            download
            ref={downloadlink}
            className={styles['download-link']}
          >
            hidden download link
          </a>
        </>
      )}
    </div>
  );
};

ChartContainer.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  selectedMonth: PropTypes.string.isRequired,
  cancelSelectedChart: PropTypes.func.isRequired,
  hasCategoryFilter: PropTypes.bool.isRequired,
  hasSelectAllOption: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  tableDownloadUrl: PropTypes.string,
};

export default ChartContainer;

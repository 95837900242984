import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// styles
import { MiniFont } from '@/components/FontStyle';
import { AllCentered, LimitWord } from '@/pages/SIPage/Overview/Filter/component/Css';
// components
import {
  Box,
  Checkbox,
  TextField,
  Chip,
  Stack,
  Popper,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { StyledFirstSectionAutoComplete } from '@/pages/SIPage/Overview/Filter/component/StyledComponents/styledAutocomplete';
import { Scrollbars } from 'react-custom-scrollbars';
import { GETooltipIf } from '@/componentsphase2/SelectBox/SelectInput';
// assets
import ChipClearIcon from '@/pages/SIPage/Overview/Filter/component/SVGComponents/ChipClearIcon.svg';
import CheckboxCheckedIcon from '@/pages/SIPage/Overview/Filter/component/SVGComponents/CheckboxCheckedIcon.svg';
import CheckboxIcon from '@/pages/SIPage/Overview/Filter/component/SVGComponents/CheckboxIcon.svg';
import Off from '@/pages/SIPage/Overview/Filter/component/SVGComponents/Off.svg';
import DropdownUDIcon from '@/assets/dropdownUDIcon.svg';
import DropdownIcon from '@/assets/dropdownIcon4.svg';
// Actions
import { setSelect } from '@/actions/SIMT-SIP/SIPFilter/SIPFilterAction';
// utils
import {
  calcDropListHeight,
  getFieldByLabel,
  getPlaceHolderByLabel,
  getThemeByField,
  getThemeColor,
} from '../utils';
import { measureWidth } from '@/utils/StringWidthMeasure';

export default function SelectBox(props) {
  const {
    id,
    data = [],
    labelName, //used to generate Id
    value: selectedList,
    setSelected,
    loadMore,
    dropListHeight = '0px',
    ...other
  } = props;
  const [open, setOpen] = useState(false);
  const identifier = labelName.replace(' ', '-');
  const [inputText, setInputText] = useState('');
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(null);
  const [stateHover, setStateHover] = useState(false);
  const [clear, setClear] = useState(false);
  const [hideSum, setHideSum] = useState(0);
  
  const { sipFilterSelected } = useSelector(state => {
    return {
      sipFilterSelected: state.SIPFilter.sipFilterSelected,
    };
  });
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  
  useEffect(() => {
    if (clear && selectedList) setHideSum(selectedList.length);
  }, [clear]);

  const cssTheme = createTheme({
    themeColor: getThemeColor(getThemeByField(getFieldByLabel(labelName))),
  });

  async function loadMoreOptions() {
    await loadMore(inputText, 1, 20);
  }

  useEffect(() => {
    if (loadMore) {
      setLoading(true);
      clearTimeout(timer);

      setTimer(
        setTimeout(() => {
          loadMoreOptions().then(() => {
            setLoading(false);
          });
        }, 300)
      );
    }
  }, [inputText]);

  const handleInputTextChange = e => {
    setInputText(e.target.value);
  };

  const getOptionName = option => {
    return labelName === 'Handling CE'
      ? `${option.username} (${option.email})`
      : option;
  };

  const getOptionId = option => {
    return labelName === 'Handling CE' ? option.userId : getOptionName(option);
  };

  function isValueInSipfilterSelected(value) {
    let selectData = sipFilterSelected[getFieldByLabel(labelName) + 'Selected'];
    if (labelName === 'Handling CE') {
      for (let i = 0; i < selectData.length; i++) {
        if (selectData[i].userId === value.userId) {
          return i;
        }
      }
      return -1;
    } else {
      return selectData.indexOf(value);
    }
  }

  function handleOnOpen() {
    setOpen(true);
    setClear(false);
    setTimeout(() => {
      const dropdown = dropdownRef.current.getBoundingClientRect();
      if (dropdown) {
        const bottom = dropdown.bottom + 154;
        let outer = document.getElementById('sioverview-filter-scrollbox');
        let scrollTop = outer.scrollTop;
        let Height = window.innerHeight - 66 - 16;
        if (bottom > Height) {
          outer.scrollTo(0, scrollTop + bottom - Height + 40);
        }
      }
    }, 100);
  }

  return (
    <ThemeProvider theme={cssTheme}>
      <Box
        sx={{ position: 'relative' }}
        data-selenium-id={`SIPPageOverview-Filter-SelectBox-${identifier}-Box`}
      >
        <StyledFirstSectionAutoComplete
          ref={dropdownRef}
          disableCloseOnSelect
          disableListWrap
          multiple
          disablePortal
          limitTags={-1}
          filterOptions={x => x}
          id={`SIPPageOverview-Filter-SelectBox-${identifier}`}
          data-selenium-id={`SIPPageOverview-Filter-SelectBox-${identifier}`}
          value={selectedList}
          options={data ?? []}
          loading={loading}
          open={open}
          onOpen={handleOnOpen}
          onClose={() => {
            setOpen(false);
            setClear(true);
          }}
          getOptionLabel={option => getOptionName(option)}
          paddingLeft={'12px'}
          onChange={(_event, value) => {
            let field = getFieldByLabel(labelName);
            let selectedData = sipFilterSelected[field + 'Selected'].concat();
            for (let i = 0; i < value.length; i++) {
              let idx = isValueInSipfilterSelected(value[i]);
              if (idx !== -1) {
                selectedData.splice(idx, 1);
                dispatch(setSelect(field, selectedData));
              }
            }
            setSelected && setSelected(_event, value);
          }}
          isOptionEqualToValue={(option, value) =>
            getOptionId(option) === getOptionId(value)
          }
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setClear(false);
            }
          }}
          onMouseEnter={() => {
            setStateHover(true);
          }}
          onMouseLeave={() => {
            setStateHover(false);
          }}
          renderOption={(optionProps, option, { selected }) => (
            <li {...optionProps}
              aria-selected={
                selected || isValueInSipfilterSelected(option) !== -1
              }>
              <Stack spacing={1} direction='row' sx={{ ...AllCentered, width: '100%' }}>
                <Checkbox
                  key={identifier + '_' + getOptionId(option)}
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                  checked={selected || isValueInSipfilterSelected(option) !== -1}
                  sx={{
                    height: '24px',
                    width: '24px',
                    padding: '0px',
                    ':hover': {
                      backgroundColor: 'transparent',
                    },
                    '& .MuiTouchRipple-root': {
                      display: 'none',
                    },
                  }}
                />
                <GETooltipIf
                  title={getOptionName(option)}
                  key={getOptionName(option)}
                  disabled={measureWidth(
                    getOptionName(option),
                    '12px Open Sans'
                  ) < 468}
                  tooltipWidth={'486px'}
                >
                  <Box sx={{ ...LimitWord, width: 'calc(100% - 32px)' }}>
                    {getOptionName(option)}
                  </Box>
                </GETooltipIf>
              </Stack>
            </li>
          )}
          renderInput={params => {
            return (
              <TextField
                {...params}
                placeholder={
                  !clear && selectedList?.length && selectedList?.length !== hideSum ? '' : getPlaceHolderByLabel(labelName)
                }
                data-selenium-id={`SIPPageOverview-Filter-SelectBox-${identifier}-Input`}
                onChange={e => {
                  handleInputTextChange(e);
                }}
              />
            );
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.slice(hideSum).map((option, index) => {
              const expectWidth = measureWidth(
                getOptionName(option),
                '12px Open Sans'
              );
              return (
                !clear && tagValue.length !== hideSum ?
                  <GETooltipIf
                    title={getOptionName(option)}
                    key={getOptionName(option)}
                    disabled={expectWidth < 347}
                  >
                    <Chip
                      {...getTagProps({ index })}
                      label={getOptionName(option)}
                      deleteIcon={
                        <Box sx={{ display: 'flex' }}>
                          <ChipClearIcon />
                        </Box>
                      }
                    />
                  </GETooltipIf> : <></>
              );
            })
          }
          ListboxProps={{
            sx: {
              overflow: 'visible',
              position: 'absolute',
              width: 'calc(100% - 0px)',
              display: 'grid',
              gap: '2px',
              padding: '8px 0px 0px 0px',
              '& li': {
                padding: '0px 12px !important',
                boxSizing: 'border-box',
                ...MiniFont,
                lineHeight: '20px',
                color: '#596A7C',
              },
              '& li[aria-selected="true"]': {
                backgroundColor: '#F1F3F5 !important',
              },
              '& li[aria-selected="true"]:hover': {
                backgroundColor: '#F1F3F5 !important',
                '& svg rect': {
                  fill: '#113D95',
                  stroke: '#113D95',
                  'stroke-width': '0px',
                },
              },
              '& li:active': {
                backgroundColor: '#F1F3F5',
                '& svg rect': { stroke: '#596A7C' },
              },
              '& li:hover': {
                backgroundColor: '#F1F3F5',
                '& svg rect': { stroke: '#596A7C' },
              },
            },
          }}
          ListboxComponent={props => (
            <Box sx={{ paddingBottom: '8px' }}>
              <Scrollbars
                style={{
                  height: calcDropListHeight(data.length, 24, 2, 8) + 'px',
                }}
                renderTrackVertical={({ style, ...props }) => (
                  <div
                    style={{
                      ...style,
                      position: 'absolute',
                      top: '3px',
                      height: 'calc(100% - 3px)',
                      right: '3px',
                    }}
                    {...props}
                  ></div>
                )}
                renderTrackHorizontal={({ style, ...props }) => (
                  <div
                    style={{
                      ...style,
                      position: 'absolute',
                      width: '0px',
                      height: '0px',
                    }}
                    {...props}
                  ></div>
                )}
                renderThumbVertical={({ style, ...props }) => (
                  <div
                    style={{
                      ...style,
                      backgroundColor: '#DFE4E8',
                      opacity: '0.7',
                      borderRadius: '10px',
                      width: '6px',
                    }}
                    {...props}
                  ></div>
                )}
                renderView={({ style, ...props }) => (
                  <div
                    style={{
                      ...style,
                      overflow: 'auto',
                      height: calcDropListHeight(data.length, 24, 2, 8) + 'px',
                    }}
                    {...props}
                  ></div>
                )}
                thumbSize={53}
              >
                <ul {...props} />
              </Scrollbars>
            </Box>
          )}
          PopperComponent={props => (
            <Popper
              {...props}
              sx={{
                '&.MuiAutocomplete-popper': {
                  top: '6px !important',
                },
                '& .MuiAutocomplete-paper': {
                  backgroundColor: '#FFFFFF',
                  boxShadow: ' 0px 6px 12px 0px #262E351F',
                  borderRadius: '4px',
                },
                '& .MuiAutocomplete-noOptions': {},
              }}
            />
          )}
          clearText=''
          closeText=''
          openText=''
          popupIcon={open ? < DropdownUDIcon /> : <DropdownIcon />}
          clearIcon={<Off />}
          {...other}
        />
      </Box>
    </ThemeProvider>
  );
}

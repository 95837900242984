import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Tooltip,
  TableHead,
} from '@mui/material';
import {
  MyTableHeader,
  StyledTableCell,
  TableFooter2,
} from '@/components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { isReportOverCutOffDate } from '@/utils/commonUtils';
import {
  ReportNoData,
  StyledTableCellNormal,
  StyledTableCellPurple,
  StyledTableRow,
  TableBox, TableBox2
} from "../../ReportSpecificComponents/SpecialColorTableHeader";
import {
  findLongestLineSubstring,
  measureWidth,
} from '../../../../utils/StringWidthMeasure';
import {
  SimpleTooltip,
  TextLabel,
} from '../../../../componentsphase2/CustomTooltip';
import { useShowReportRolesByFyHook } from '@/pages/Report/utils/useShowReportRolesByFyHook';
import {getReportBasicTableData} from "@/actions/SIMT-Report/Report/ReportSiPipeline";

export default function BasicTable(props) {
  const { formatYear = 'FY**' } = props;
  const dispatch = useDispatch();
  const [showJPM, showNewJournalRoles] = useShowReportRolesByFyHook();
  const { basicTableData, basicReportFilters, selectDate , cutOffDate  } = useSelector(
    state => {
      return {
        // filter display
        basicTableData: state.Report.basicTableData,
        selectDate: state.Report.selectDate,
        cutOffDate: state.Report.cutOffDate,
        basicReportFilters: state.Filter.basicReportFilters,
      };
    }
  );

  const [columnWidthMap, setColumnWidthMap] = useState({});
  // const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  // useEffect(() => {
  //   const widthMap = {};
  //   for (let item of items) {
  //     let maxStr = findLongestLineSubstring(item.label);
  //     widthMap[item.id] = measureWidth(maxStr, '12px Open Sans');

  //     if(item.id==='catgroup') widthMap[item.id]=widthMap[item.id]+1;
  //   }
  //   setColumnMinWidthMap(widthMap);
  // }, []);

  useEffect(() => {
    if (basicTableData && basicTableData.count > 0) {
      const maxMap = {};
      for (let dataObj of basicTableData['result']) {

        // JPM
        dataObj['jpm'] =
          dataObj.lastNameOfJpm === null
            ? ''
            : dataObj.lastNameOfJpm + ', ' + dataObj.firstNameOfJpm;

        // PD Publisher
        dataObj['pdPublisher'] =
          dataObj.lastNameOfPdp === null
            ? ''
            : dataObj.lastNameOfPdp + ', ' + dataObj.firstNameOfPdp;

        // PP Publisher
        dataObj['ppPublisher'] =
          dataObj.lastNameOfPpp === null
            ? ''
            : dataObj.lastNameOfPpp + ', ' + dataObj.firstNameOfPpp;

        dataObj['handlingCe'] =
          dataObj.lastName === null
            ? ''
            : dataObj.lastName + ', ' + dataObj.firstName;
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength) {
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '16px Open Sans');
        }
      }

      widthMap['journalName'] = Math.max(
        widthMap['journalCode'],
        widthMap['journalName']
      );
      widthMap['catgroup'] = widthMap['group']+1;
      widthMap['subjectGroup'] = widthMap['sgAbbr'];

      setColumnWidthMap(widthMap);
    }
  }, [basicTableData]);

  const [loading, setLoading] = useState(false);

  const createRow = (
    id,
    label,
    minWidth,
    maxWidth,
    isOrder = false,
    align = 'left',
    show = true
  ) => {
    return {
      id,
      label,
      minWidth,
      maxWidth,
      isOrder,
      align,
      show,
    };
  };

  const itemsBeforeJPM = useMemo(() => {
    return [
      createRow(
        'journalName',
        'Journal Information',
        '180px',
        '220px',
        false,
        'left'
      ),
      createRow(
        'revenueModel',
        'Revenue Model',
        '180px',
        '180px',
        false,
        'left'
      ),
      createRow(
        'journalStatus',
        'Journal Status',
        '180px',
        '180px',
        false,
        'left'
      ),
    ];
  }, []);

    const itemsAfterJPM = useMemo(() => {
      return [
        createRow(
          'subjectGroup',
          'Subject Group',
          '180px',
          '180px',
          false,
          'left'
        ),
        createRow(
          'businessDivision',
          'Business Division',
          '180px',
          '340px',
          false,
          'left'
        ),
        createRow('catgroup', 'SCA Group', '180px', '340px', false, 'left'),
        createRow('handlingCe', 'Handling CE', '180px', '340px', false, 'left'),
        createRow('solicitedSipCount', 'SOLICITED PROPOSAL', '120px', '120px', false, 'left'),
        createRow('unSolicitedSipCount', 'UNSOLICITED PROPOSAL', '120px', '120px', false, 'left'),
        createRow(
          'numIdeaOrProposal',
          'Idea / Proposal',
          '180px',
          '340px',
          false,
          'left'
        ),
        createRow('numAcquired', 'Acquired', '180px', '340px', false, 'left'),
        createRow(
          'numPaperCommission',
          'Paper Commission',
          '180px',
          '340px',
          false,
          'left'
        ),
        createRow(
          'numSubmissionAndReview',
          'Submission &Review',
          '180px',
          '340px',
          false,
          'left'
        ),
        createRow(
          'numProduction',
          'Production',
          '180px',
          '340px',
          false,
          'left'
        ),
        createRow(
          'numPublishedFy',
          `Published in ${formatYear}`,
          '180px',
          '340px',
          false,
          'left'
        ),
        createRow('numPublished', 'Published', '180px', '340px', false, 'left'),
      ];
    }, [formatYear]);

    const JPMItem = useMemo(() => {
      return createRow('jpm', 'JPM', '105px', '180px', false, 'left');
    }, []);

    const pdpItem = useMemo(() => {
      return createRow(
        'pdPublisher',
        'PD Publisher',
        '105px',
        '180px',
        false,
        'left'
      );
    }, []);

    const pppItem = useMemo(() => {
      return createRow(
        'ppPublisher',
        'PP Publisher',
        '105px',
        '180px',
        false,
        'left'
      );
    }, []);

    const items = useMemo(() => {
      let flexItem = [];
      if (showJPM) {
        flexItem = [...flexItem, JPMItem];
      }
      if (showNewJournalRoles) {
        flexItem = [...flexItem, pdpItem, pppItem];
      }
      return [...itemsBeforeJPM, ...flexItem, ...itemsAfterJPM];
    }, [showJPM, showNewJournalRoles, formatYear]);

  // width map
  const columnMinWidthMap = useMemo(() => {
    const widthMap = {};
    for (let item of items) {
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '12px Open Sans');
      if (item.id === 'catgroup') widthMap[item.id] = widthMap[item.id] + 1;
    }
    return widthMap;
  }, [items]);


  const JournalData = [
    {
      journalCode: 1,
      journalName: 2,
      revenueModel: 3,
      JPM: 4,
      subjectGroup: 5,
      bunsinessDivision: 6,
      CAT: 7,
      handlingCE: 8,
      ideaOrProposal: 9,
      Acquired: 10,
      paperCommission: 11,
      submissionReview: 12,
      production: 13,
      published: 14,
    },
  ];

  const [page, setPage] = useState(1);
  const [perPageNumber, setPerPageNumber] = useState(20);

  const [orderType, setOrderType] = useState('journalCode'); //排序的表头
  const [orderAs, setOrderAs] = useState('asc'); //排序的顺序
  const [flashLock, setFlashLock] = useState(true);

  const handlePerPageChange = e => {
    setPerPageNumber(e.target.value);
  };

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderType === property && orderAs === 'asc';
    setOrderAs(isAsc ? 'desc' : 'asc');
    setOrderType(property);
  };

  useEffect(() => {
    async function fetchData() {
      if (!flashLock) {
        await dispatch(
          getReportBasicTableData(
            page,
            perPageNumber,
            orderAs,
            orderType,
            selectDate,
            basicReportFilters
          )
        );
      }
      setLoading(false);
      setFlashLock(false);
    }
    setLoading(true);
    if (page === 0) {
      if (!flashLock) {
        setTimeout(() => {
          setPage(1);
        }, 2000);
      }
    } else {
      fetchData();
    }
  }, [page]); // 表格本身属性触发的加载，一切按照默认

  useEffect(() => {
    setFlashLock(false);
    setPage(0);
  }, [basicReportFilters, perPageNumber, orderType, orderAs, selectDate]); // 表格本身属性触发的加载，一切按照默认

  return (
    <TableBox2
      id='BasicTableBox'
      data-selenium-id='Report-SIPipeline-BasicTableBox'
      dataList={[basicTableData, loading]}
      tableKey={'Report-SIPipeline-BasicTable'}
      footerHeight={40}
    >
      <TableContainer sx={{ minHeight: '300px', maxHeight: '680px' }}>
        <Table
          stickyHeader
          aria-label='simple table'
          size='small'
          sx={{
            tableLayout: 'fixed',
            width: '100%',
          }}
        >
          {/* <MyTableHeader
            hasCheckbox={false} // width is defined here
            items={items}
            order={orderAs}
            orderBy={orderType}
            onRequestSort={handleRequestSort}
            backgroundColor='#d6ddff'
          /> */}
          <TableHead data-selenium-id='Report-SIPipeline-BasicTableHead'>
            <TableRow data-selenium-id='Report-SIPipeline-BasicTableHead-ROw'>
              {items.map(item => (
                (item.id === 'numIdeaOrProposal' || item.id === 'numAcquired' ||
                item.id === 'numSubmissionAndReview' || item.id === 'numPaperCommission' ||
                item.id === 'numProduction' || item.id === 'numPublished' || item.id === 'numPublishedFy') ?
                null
                :
                <StyledTableCellNormal
                  key={item.label}
                  align={item.align}
                  rowSpan={2}
                  sx={{
                    backgroundColor: '#98A7B6',
                    color: '#FFF',
                    height: '102px',
                    ...(item.id === 'journalName' && {
                      position: 'sticky',
                      left: 0,
                      top: 0,
                      zIndex: 8,
                      color: '#596A7C',
                      backgroundColor: '#DFE4E8',
                    }),
                    ...{
                      width: `${
                        Math.max(
                          columnWidthMap[item.id] ?? 0,
                          columnMinWidthMap[item.id]
                        ) + 50
                      }px`,
                    },
                  }}
                  sortDirection={orderType === item.id ? orderType : false}
                >
                  {item.label}
                </StyledTableCellNormal>
              ))}
              <StyledTableCellNormal
                  colSpan={7}
                  sx={{
                    zIndex: 8,
                    position: 'sticky',
                    backgroundColor: '#98A7B6',
                    color: '#FFF',
                    height: '42px',
                    width: '960px',
                    top: 0,
                  }}
                >
                  Special Issue
                </StyledTableCellNormal>
              </TableRow>
              <TableRow>
                {items.map(item => (
                  (item.id === 'numIdeaOrProposal' || item.id === 'numAcquired' ||
                  item.id === 'numSubmissionAndReview' || item.id === 'numPaperCommission' ||
                  item.id === 'numProduction' || item.id === 'numPublished' || item.id === 'numPublishedFy') ?
                  <StyledTableCellNormal
                    key={item.label}
                    align={item.align}
                    rowSpan={2}
                    sx={{
                      backgroundColor: '#F1F3F5',
                      color: '#596A7C',
                      height: '60px',
                      position: 'sticky',
                      top: 42,
                      zIndex: 8,
                      ...{
                        width: `${
                          Math.max(
                            columnWidthMap[item.id] ?? 0,
                            columnMinWidthMap[item.id]
                          ) + 50
                        }px`,
                      },
                    }}
                    sortDirection={orderType === item.id ? orderType : false}
                  >
                    {item.label}
                  </StyledTableCellNormal>
                  :
                  null
                  ))}
            </TableRow>
          </TableHead>
          <TableBody data-selenium-id='Report-SIPipeline-BasicTableBody'>
            {!loading && basicTableData != null && basicTableData.count > 0 ? (
              basicTableData['result']?.map((row, index) => {
                return (
                  <StyledTableRow
                    key={row.id}
                    aria-checked={false}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      height: '64px',
                      fontColor: '#747474',
                    }}
                    selected={false}
                  >
                    <StyledTableCell
                      id='reportSiOverviewBtJnStyledTableCell'
                      data-selenium-id={`Report-SiOverview-BtJnStyledTableCell-${index}`}
                      sx={{
                        fontWeight: 600,
                        maxWidth: '110px',
                        position: 'sticky',
                        left: 0,
                        zIndex: 6,
                        backgroundColor: '#F1F3F5',
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '14px',
                          color: '#484848',
                        }}
                      >
                        {row.journalCode}
                      </Box>
                      <Box
                        sx={{
                          // fontSize: '14px',
                          color: '#747474',
                          fontWeight: 400,
                        }}
                      >
                        {row.journalName}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell
                      id='reportSiOverviewBtRmStyledTableCell'
                      data-selenium-id={`Report-SiOverview-BtRmStyledTableCell-${index}`}
                      align='left'
                      sx={{ backgroundColor: '#FFFFFF', lineHeight:'20px' }}
                    >
                      {row.revenueModel}
                    </StyledTableCell>
                    <StyledTableCell
                      id='reportSiOverviewBtJsStyledTableCell'
                      data-selenium-id={`Report-SiOverview-BtJsStyledTableCell-${index}`}
                      align='left'
                      sx={{ backgroundColor: '#FFFFFF', lineHeight:'20px' }}
                    >
                      {row.journalStatus}
                    </StyledTableCell>

                    {/* JPM */}
                    {showJPM && <StyledTableCell
                      id='reportSiOverviewJPMStyledTableCell'
                      data-selenium-id={`Report-SiOverview-JPMStyledTableCell-${index}`}
                      align='left'
                      sx={{ backgroundColor: '#FFFFFF', lineHeight:'20px' }}
                    >
                      <TextLabel
                        text={
                          row.lastNameOfJpm === null
                            ? ''
                            : row.lastNameOfJpm + ', ' + row.firstNameOfJpm
                        }
                        ifTooltip={true}
                        TooltipText={row.emailOfJpm}
                      />
                    </StyledTableCell>}

                    {/* PD Publisher */}
                    {showNewJournalRoles && <StyledTableCell
                      id='reportSiOverviewPDPublisherStyledTableCell'
                      data-selenium-id={`Report-SiOverview-PDPublisherStyledTableCell-${index}`}
                      align='left'
                      sx={{ backgroundColor: '#FFFFFF' }}
                    >
                      <TextLabel
                        text={
                          row.lastNameOfPdp === null
                            ? ''
                            : row.lastNameOfPdp + ', ' + row.firstNameOfPdp
                        }
                        ifTooltip={true}
                        TooltipText={row.emailOfPdp}
                      />
                    </StyledTableCell>}

                     {/* PP Publisher */}
                     {showNewJournalRoles && <StyledTableCell
                      id='reportSiOverviewPPPublisherStyledTableCell'
                      data-selenium-id={`Report-SiOverview-PPPublisherStyledTableCell-${index}`}
                      align='left'
                      sx={{ backgroundColor: '#FFFFFF' }}
                    >
                      <TextLabel
                        text={
                          row.lastNameOfPpp === null
                            ? ''
                            : row.lastNameOfPpp + ', ' + row.firstNameOfPpp
                        }
                        ifTooltip={true}
                        TooltipText={row.emailOfPpp}
                      />
                    </StyledTableCell>}

                    <StyledTableCell
                      id='reportSiOverviewBtSgStyledTableCell'
                      data-selenium-id={`Report-SiOverview-BtSgStyledTableCell-${index}`}
                      align='left'
                      sx={{ backgroundColor: '#FFFFFF', lineHeight:'20px' }}
                    >
                      <TextLabel
                        id='reportSiOverviewBtSgTextLabel'
                        data-selenium-id={`Report-SiOverview-BtSgTextLabel-${index}`}
                        TooltipText={row.subjectGroup}
                        text={row.sgAbbr}
                        ifTooltip={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      id='reportSiOverviewBtBdStyledTableCell'
                      data-selenium-id={`Report-SiOverview-BtBdStyledTableCell-${index}`}
                      align='left'
                      sx={{ backgroundColor: '#FFFFFF', lineHeight:'20px' }}
                    >
                      <TextLabel
                        id='reportSiOverviewBtBdTextLabel'
                        data-selenium-id={`Report-SiOverview-BtBdTextLabel-${index}`}
                        text={row.businessDivision}
                        ifTooltip={false}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      id='reportSiOverviewBtGroupStyledTableCell'
                      data-selenium-id={`Report-SiOverview-BtGroupStyledTableCell-${index}`}
                      align='left'
                      sx={{ backgroundColor: '#FFFFFF', lineHeight:'20px' }}
                    >
                      <TextLabel
                        id='reportSiOverviewBtGroupTextLabel'
                        data-selenium-id={`Report-SiOverview-BtGroupTextLabel-${index}`}
                        text={row.group}
                        ifTooltip={false}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      sx={{ backgroundColor: '#FFFFFF', lineHeight:'20px' }}
                    >
                      <TextLabel
                        text={
                          row.lastName === null
                            ? ''
                            : row.lastName + ', ' + row.firstName
                        }
                        ifTooltip={true}
                        TooltipText={row.emailOfHandlingCe}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      id='reportSiOverviewBtGroupStyledTableCell'
                      data-selenium-id={`Report-SiOverview-BtGroupStyledTableCell-${index}`}
                      align='left'
                      sx={{ backgroundColor: '#FFFFFF', lineHeight:'20px' }}
                    >
                      <TextLabel
                        id='reportSiOverviewBtGroupTextLabel'
                        data-selenium-id={`Report-SiOverview-BtGroupTextLabel-${index}`}
                        text={row.solicitedSipCount}
                        ifTooltip={false}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      id='reportSiOverviewBtGroupStyledTableCell'
                      data-selenium-id={`Report-SiOverview-BtGroupStyledTableCell-${index}`}
                      align='left'
                      sx={{ backgroundColor: '#FFFFFF', lineHeight:'20px' }}
                    >
                      <TextLabel
                        id='reportSiOverviewBtGroupTextLabel'
                        data-selenium-id={`Report-SiOverview-BtGroupTextLabel-${index}`}
                        text={row.unsolicitedSipCount}
                        ifTooltip={false}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      id='reportSiOverviewBtNumIdeaStyledTableCell'
                      data-selenium-id={`Report-SiOverview-BtNumIdeaStyledTableCell-${index}`}
                      align='left'
                      className='ReportNumberTextColor'
                      sx={{ backgroundColor: '#FFFFFF', lineHeight:'20px' }}
                    >
                      <TextLabel
                        id='reportSiOverviewBtNumIdeaTextLabel'
                        data-selenium-id={`Report-SiOverview-BtNumIdeaTextLabel-${index}`}
                        text={row.numIdeaOrProposal}
                        ifTooltip={false}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      id='reportSiOverviewBtNumAcStyledTableCell'
                      data-selenium-id={`Report-SiOverview-BtNumAcStyledTableCell-${index}`}
                      align='left'
                      className='ReportNumberTextColor'
                      sx={{ backgroundColor: '#FFFFFF', lineHeight:'20px' }}
                    >
                      <TextLabel
                        id='reportSiOverviewBtNumAcTextLabel'
                        data-selenium-id={`Report-SiOverview-BtNumAcTextLabel-${index}`}
                        text={row.numAcquired}
                        ifTooltip={false}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      id='reportSiOverviewBtNumPcStyledTableCell'
                      data-selenium-id={`Report-SiOverview-BtNumPcStyledTableCell-${index}`}
                      align='left'
                      className='ReportNumberTextColor'
                      sx={{ backgroundColor: '#FFFFFF', lineHeight:'20px' }}
                    >
                      <TextLabel
                        id='reportSiOverviewBtNumPcTextLabel'
                        data-selenium-id={`Report-SiOverview-BtNumPcTextLabel-${index}`}
                        text={row.numPaperCommission}
                        ifTooltip={false}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      id='reportSiOverviewBtNumSrStyledTableCell'
                      data-selenium-id={`Report-SiOverview-BtNumSrStyledTableCell-${index}`}
                      align='left'
                      className='ReportNumberTextColor'
                      sx={{ backgroundColor: '#FFFFFF', lineHeight:'20px' }}
                    >
                      <TextLabel
                        id='reportSiOverviewBtNumSrTextLabel'
                        data-selenium-id={`Report-SiOverview-BtNumSrTextLabel-${index}`}
                        text={row.numSubmissionAndReview}
                        ifTooltip={false}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      id='reportSiOverviewBtNumPrStyledTableCell'
                      data-selenium-id={`Report-SiOverview-BtNumPrStyledTableCell-${index}`}
                      align='left'
                      className='ReportNumberTextColor'
                      sx={{ backgroundColor: '#FFFFFF', lineHeight:'20px' }}
                    >
                      <TextLabel
                        id='ReportSiOverviewBtNumPrTextLabel'
                        data-selenium-id={`Report-SiOverview-BtNumPrTextLabel-${index}`}
                        text={row.numProduction}
                        ifTooltip={false}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      id='reportSiOverviewBtNumPuStyledFYTableCell'
                      data-selenium-id={`Report-SiOverview-BtNumPuStyledFYTableCell-${index}`}
                      align='left'
                      className='ReportNumberTextColor'
                      sx={{ backgroundColor: '#FFFFFF', lineHeight:'20px' }}
                    >
                      <TextLabel
                        id='ReportSiOverviewBtNumPuFYTextLabel'
                        data-selenium-id={`Report-SiOverview-BtNumPuFYTextLabel-${index}`}
                        text={row.numPublishedFy ? row.numPublishedFy : 0}
                        ifTooltip={false}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      id='reportSiOverviewBtNumPuStyledTableCell'
                      data-selenium-id={`Report-SiOverview-BtNumPuStyledTableCell-${index}`}
                      align='left'
                      className='ReportNumberTextColor'
                      sx={{ backgroundColor: '#FFFFFF', lineHeight:'20px' }}
                    >
                      <TextLabel
                        id='ReportSiOverviewBtNumPuTextLabel'
                        data-selenium-id={`Report-SiOverview-BtNumPuTextLabel-${index}`}
                        text={row.numPublished}
                        ifTooltip={false}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            ) : !loading ? (
              <ReportNoData top='50px' id={'Report-SiOverview-NoData'} />
            ) : (
              <div></div>
            )}
          </TableBody>
        </Table>
        {loading && (
          <Box
            data-selenium-id='Report-SiOverview-LoadingBox'
            sx={{
              width: 'auto',
              marginTop: '150px',
              marginLeft: '50%',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </TableContainer>
      <Box>
        <Divider />
        {basicTableData != null && basicTableData['count'] > 0 ? (
          <TableFooter2
            data-selenium-id='Report-SiOverview-TableFooter2'
            numSelected={0}
            Page={page}
            perPageNumber={perPageNumber}
            perPageList={[20, 50, 100]}
            count={basicTableData != null ? basicTableData['count'] : 0}
            handlePerPageChange={handlePerPageChange}
            handlePageChange={handlePageChange}
            // backgroundColor='#CCD4FF'
            backgroundColor='#F1F3F5'
          />
        ) : (
          <div></div>
        )}
      </Box>
    </TableBox2>
  );
}

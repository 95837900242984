import React, { useEffect, useState } from 'react';
import { activeEmailClear } from '@/actions/SIMT-User/User/UserAction';
import { useSearchParams } from 'react-router-dom';
import ActiveSuccessPage from './ActiveSuccess';
import ActiveExpiredPage from './ActiveExpired';
import { useDispatch, useSelector } from 'react-redux';
import {activeAccount} from "@/actions/SIMT-User/User/Login";

export default function ActiveEmailPage() {
  let [searchParams] = useSearchParams();
  const digitalSignature = searchParams.get('digitalSignature');
  const email = searchParams.get('email');
  const [Valid, setValid] = useState(null);
  const dispatch = useDispatch();
  const selector = state => {
    return {
      activeResult: state.User.activeResult,
    };
  };
  const { activeResult } = useSelector(selector);

  useEffect(() => {
    async function fetchData() {
      await dispatch(activeAccount(digitalSignature, email));
    }
    fetchData();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(activeEmailClear());
    };
  }, []);

  useEffect(() => {
    setValid(activeResult);
  }, [activeResult]);

  if (Valid === null) {
    return <div />;
  } else if (Valid === true) {
    return <ActiveSuccessPage />;
  } else if (Valid === false) {
    return <ActiveExpiredPage />;
  }
}

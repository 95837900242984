// components
import { Box, Radio, Stack } from '@mui/material';
import CheckedRadio from '../../../../../../SIPage/Overview/Filter/component/SVGComponents/CheckedRadio.svg';
import UnCheckedRadio from '../../../../../../SIPage/Overview/Filter/component/SVGComponents/Radio.svg';
import RadioLabel from './RadioLabel';
import SimpleCheckBox from '@/componentsphase2/Input/SimpleCheckBox';
// utils
import { capitalizeFirstLetter } from '../../../../../../SIPage/Overview/Filter/utils';

export default function RadioBox(props) {
  const {
    id,
    dataSeleniumId,
    value,
    handleChange,
    radiolist,
    stackProps,
    labelProps,
    isCNmarketing=false,
  } = props;
  return (
    <Box
      id={id}
      data-selenium-id={dataSeleniumId}
      sx={{
        display: 'flex',
        flexDirection: stackProps?.first?.direction ?? 'row',
        gap: stackProps?.first?.gap ?? '10px',
        flexWrap: 'wrap',
      }}
    >
      {radiolist.map(item => (
        <Stack
          id={`${id}Stack${capitalizeFirstLetter(item.key)}`}
          data-selenium-id={`${dataSeleniumId}-Box-Stack-${capitalizeFirstLetter(
            item.key
          )}`}
          key={item.key}
          spacing={stackProps?.second?.spacing ?? 0.75}
          direction={stackProps?.second?.direction ?? 'row'}
        >
          {isCNmarketing?<SimpleCheckBox
            id={`${id}Radio${capitalizeFirstLetter(item.key)}`}
            data-selenium-id={`${dataSeleniumId}-Stack-Radio-${capitalizeFirstLetter(
              item.key
            )}`}
            checked={value && value.includes(item.key)}
            onClick={handleChange.bind(this, item.key)}
            sx={{
              padding: '0px',
              marginLeft: '2px',
              marginRight: '3px',
              ':hover': {
                backgroundColor: '#FAFAFA',
              },
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            }}
          />:<Radio
            id={`${id}Radio${capitalizeFirstLetter(item.key)}`}
            data-selenium-id={`${dataSeleniumId}-Stack-Radio-${capitalizeFirstLetter(
              item.key
            )}`}
            icon={<UnCheckedRadio />}
            checkedIcon={<CheckedRadio />}
            checked={value && value.includes(item.key)}
            onClick={handleChange.bind(this, item.key)}
            sx={{
              padding: '0px',
              ':hover': {
                backgroundColor: '#FAFAFA',
                '& svg .circle-inner':value && value.includes(item.key) && {
                  fill:'#113D95'
                },
                '& svg .circle-out':{
                  stroke:'#596A7C',
                }
              },
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            }}
          />}
          <RadioLabel
            id={`${id}RadioLabel${capitalizeFirstLetter(item.key)}`}
            dataSeleniumId={`${dataSeleniumId}-Stack-RadioLabel-${capitalizeFirstLetter(
              item.key
            )}`}
            label={item.label}
            labelProps={labelProps ?? {}}
          />
        </Stack>
      ))}
    </Box>
  );
}

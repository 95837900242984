import { Button, ThemeProvider, createTheme } from '@mui/material';
import Grid from '@mui/material/Grid';

export default function SelectStageButton(props) {
  const { startIcon, startIconDisabled, content, onClick, disable } = props;
  const selectStageButtonStyle = {
    width: '100%',
    height: '86px',
    boxShadow: '0px 3px 6px rgba(9, 30, 66, 0.25)',
    borderRadius: '5px',
  };
  const buttonTextStyle = {
    textTransform: 'none',
    color: '#BCC5CF',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: '16px',
    textAlign: 'left',
  };
  const themeButton = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: { width: '100%' },
        },
      },
    },
  });
  return (
    <>
      {!disable ? (
        <ThemeProvider theme={themeButton}>
          <Button
            variant='contained'
            style={{ ...selectStageButtonStyle, backgroundColor: '#FFFFFF' }}
            onClick={onClick}
            sx={{ maxWidth: '448px' }}
            disabled={false}
          >
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
            >
              <Grid
                container
                item
                xs={2}
                sm={4}
                md={4}
                justifyContent='flex-end'
              >
                {startIcon}
              </Grid>
              <Grid
                container
                item
                xs={6}
                sm={8}
                md={8}
                justifyContent='flex-start'
              >
                <div
                  style={{
                    textTransform: 'none',
                    color: '#262E35',
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '24px',
                    marginLeft: '16px',
                    textAlign: 'left',
                  }}
                >
                  {content}
                </div>
              </Grid>
            </Grid>
          </Button>
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={themeButton}>
          <Button
            variant='contained'
            style={{ ...selectStageButtonStyle, backgroundColor: '#F6F7F8' }}
            onClick={onClick}
            disabled={true}
            sx={{ maxWidth: '448px' }}
          >
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
            >
              <Grid
                container
                item
                xs={2}
                sm={4}
                md={4}
                justifyContent='flex-end'
              >
                {startIconDisabled}
              </Grid>
              <Grid
                container
                item
                xs={6}
                sm={8}
                md={8}
                justifyContent='flex-start'
              >
                <div style={buttonTextStyle}>{content}</div>
              </Grid>
            </Grid>
          </Button>
        </ThemeProvider>
      )}
    </>
  );
}

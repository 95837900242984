import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import XIcon from '../../../../assets/x - Feather icon.svg';
import { useEffect, useState } from 'react';
import { Box, styled, Grid, IconButton, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { RejectConfirm } from './WaitingForSnackBar';
import { BlueBackdrop } from '../../../../modules/Modal/SIModal';

export function RejectDialog(props) {
  const {
    propOpen,
    setRejectOpen,
    reportName,
    selectMonth,
    handleRejectDialogPop,
  } = props;
  const [value, setValue] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [ButtonDisable, setButtonDisable] = useState(true);
  const [RejectSnackBarOpen, setRejectSnackBarOpen] = useState(false);
  const handleClose = () => {
    setRejectOpen(false);
    setValue('');
  };

  const handleChange = e => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (value === ' ') {
      setValue('');
    }
    if (value !== '' && value.length <= 5000) {
      setButtonDisable(false);
      setWordCount(value.length);
    } else if (value.length >= 5000) {
      setValue(value.substring(0, 5000));
    } else {
      setButtonDisable(true);
      setWordCount(value.length);
    }
  }, [value]);

  const handleRejectDialog = () => {
    handleRejectDialogPop(value, reportName, selectMonth, 'Reject');
    setValue('');
  };
  return (
    <>
      <Dialog
        id='waitingForApproveRejectDialog'
        data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-Dialog'
        maxWidth={false}
        open={propOpen}
        onClose={handleClose}
        aria-labelledby='draggable-dialog-title'
        components={{
          Backdrop: BlueBackdrop,
        }}
        sx={{
          '& .MuiPaper-root': {
            width: '600px',
            height: '423px',
          },
        }}
      >
        <Stack
          id='frame-2438'
          data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-Dialog-Stack1'
          justifyContent='space-between'
          direction='row'
          sx={{
            width: '530px',
            padding: '32px 0px 17px 35px',
          }}
        >
          <DialogTitle
            style={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '22px',
              lineHeight: '125%',
              backgroundColor: '#ffffff',
              color: '#262E35',
              padding: 0,
            }}
            id='draggable-dialog-title'
            data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-Dialog-Stack1-DialogTitle'
          >
            Reject Request
          </DialogTitle>
          <IconButton
            data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-Dialog-Stack1-IconButton'
            onClick={handleClose}
            sx={{
              padding: 0,
            }}
          >
            <XIcon />
          </IconButton>
        </Stack>
        <Stack
          id='Textarea-Base-Default'
          data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-Dialog-Stack2'
          sx={{
            paddingLeft: '35px',
            width: '530px',
            height: '263px',
            lineHeight: '20px',
            paddingBottom: '16px',
          }}
        >
          <DialogTitle
            data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-Dialog-Stack2-DialogTitle'
            style={{
              // width: "600px",
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 600,
              color: '#596A7C',
              letterSpacing: '0.08em',
              lineHeight: '18px',
              fontSize: '14px',
              padding: '0 0 12px 0',
            }}
          >
            Reason:
          </DialogTitle>
          <DialogContent
            data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-Dialog-Stack2-DialogContent'
            sx={{
              padding: 0,
              lineHeight: '20px',
              '&. MuiInputBase-root': {
                lineHeight: '20px',
              },
            }}
          >
            <DialogContentText
              id='systemAdminReportJobsWaitingForRejectDialogContentText'
              data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-Dialog-Stack2-DialogContent-DialogContentText'
              style={{
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '20px',
                padding: '0px ',
              }}
            >
              <Box
                style={{ marginTop: '0px', height: '212px' }}
                data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-Dialog-Stack2-DialogContent-DialogContentText-Box'
              >
                <TextField
                  data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-Dialog-Stack2-DialogContent-DialogContentText-Box-TextField'
                  // id="outlined-multiline-flexible"
                  fullWidth
                  multiline
                  rows={7}
                  value={value}
                  onChange={handleChange}
                  sx={{
                    '&.MuiInputBase-root.MuiOutlinedInput-root': {
                      width: '530px',
                      height: '212px',
                    },
                    '& .MuiOutlinedInput-root': {},
                    lineSpacing: 1,
                  }}
                />
                <div
                  data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-Dialog-Stack2-DialogContent-DialogContentText-Box-ContainerDiv'
                  style={{
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '18px',
                    textAlign: 'right',
                    color: '#706E6B',
                    top: '8px',
                    width: '530px',
                  }}
                >
                  {wordCount}/5,000
                </div>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Stack>

        <DialogActions
          data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-Dialog-DialogActions'
          sx={{ paddingRight: '35px', paddingBottom: '20px' }}
        >
          <Grid
            data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-Dialog-DialogActions-Grid'
            container
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
          >
            <Button
              autoFocus
              onClick={handleClose}
              id='systemAdminReportJobsWaitingForRejectDialogCancelButton'
              data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-Dialog-DialogActions-Grid-CancelButton'
              style={{
                alignItems: 'center',
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '20px',
                letterSpacing: '0.01em',
                color: '#154AB6',
                textTransform: 'capitalize',
                padding: '8px 18px',
              }}
            >
              Cancel
            </Button>
            <Button
              id='systemAdminReportJobsWaitingForRejectDialogRejectButton'
              data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-Dialog-DialogActions-Grid-RejectButton'
              onClick={handleRejectDialog}
              variant='contained'
              disabled={ButtonDisable}
              style={{
                padding: '8px 12px 8px 12px',
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '20px',
                letterSpacing: '1px',
                color: '#154AB6',
                textTransform: 'capitalize',
              }}
            >
              <div
                data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-Dialog-DialogActions-Grid-RejectButton-ContainerDiv'
                style={{ color: 'white' }}
              >
                Reject
              </div>
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <RejectConfirm
        data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForRejectDialog-RejectConfirm'
        open={RejectSnackBarOpen}
        setOpen={setRejectSnackBarOpen}
      />
    </>
  );
}

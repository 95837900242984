import { Box, InputAdornment, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { DelaySearchTextField } from '../../../../../../components/MyTextField';
// @ts-ignore
import SearchIcon from '../../../../../../assets/Search.svg';
import JournalFilterSearchResultDisplay from './JournalFilterSearchResultDisplay';
import { useEffect, useState } from 'react';
import JournalFilterConstantListDisplay from './JournalFilterConstantListDisplay';
import { useDispatch } from 'react-redux';

import {loadCatJournalFilter} from "@/actions/SIMT-SI/CatJournal";
/**
 * @type {import("@mui/material").InputProps}
 */
const InputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <SearchIcon fontSize='12px' />
    </InputAdornment>
  ),
  style: {
    padding: '6px',
    color: '#828282',
    background: '#EFEFF0',
  },
};
/**
 * @type {import("@mui/material").InputBaseComponentProps}
 */
const inputProps = {
  style: {
    height: '14px',
    padding: 0,
    fontSize: '12px',
    lineHeight: '16px',
  },
};
/**
 * @type {import("@mui/material").SxProps}
 */
const searchSx = {
  maxWidth: '100%',
  // 这里去掉边框颜色
  '& .MuiInputBase-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
};

/**
 * @param {import("./JournalFilterPopupContent").JournalFilterPopupContentProps} props
 * @returns
 */
function JournalFilterPopupContent(props) {
  const {
    name,
    handleSearch,
    placeholder,
    selectObjectKey,
    data = [],
    selected = [],
    isLoading = false,
    displayFunction,
    isConstantList,
    ...other
  } = props;
  // Note: You might set `selectObjectKey` as `self`, or any key of the element of the `data`
  // e.g., if data = [{
  //   id: "409",
  //   value: "ASAD",
  // }],
  // You can set `selectObjectKey` as `id` or `value` or `self`
  // Difference:
  //    self: The component will select the element itself,
  //    [key]: The component will only select the key

  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    handleSearch('');
    return () => {
      handleSearch('');
    };
  }, []);

  return (
    <Stack
      width='269px'
      data-selenium-id='CATJournal_Overview-Table-SearchRow-filterComponents-PopupContent-Stack'
    >
      {isConstantList ? (
        <JournalFilterConstantListDisplay
          data={data}
          selected={selected}
          name={name}
          selectObjectKey={selectObjectKey}
          value={value}
          displayFunction={displayFunction}
          {...other}
          data-selenium-id='CATJournal_Overview-Table-SearchRow-filterComponents-PopupContent-ConstantListDisplay'
        />
      ) : (
        <>
          {/*搜索框*/}
          <DelaySearchTextField
            id={name + 'SearchTextField'}
            data-selenium-id={name + 'SearchTextField'}
            placeholder={placeholder}
            size='small'
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={value}
            value={value}
            delay={300}
            handleSearch={handleSearch}
            color='warning'
            InputProps={InputProps}
            sx={searchSx}
            inputProps={inputProps}
            onChange={event => {
              setValue(event.target.value);
            }}
            autoFocus
          />
          {/*已选择和搜索结果展示区域*/}
          <JournalFilterSearchResultDisplay
            data={data}
            selected={selected}
            isLoading={isLoading}
            name={name}
            selectObjectKey={selectObjectKey}
            value={value}
            displayFunction={displayFunction}
            data-selenium-id='CATJournal_Overview-Table-SearchRow-filterComponents-PopupContent-ResultDisplay'
            {...other}
          />
        </>
      )}
    </Stack>
  );
}

JournalFilterPopupContent.propTypes = {
  name: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  selected: PropTypes.array,
  placeholder: PropTypes.string.isRequired,
  selectObjectKey: PropTypes.string.isRequired,
  multiselect: PropTypes.bool,
  displayFunction: PropTypes.func.isRequired,
  isConstantList: PropTypes.bool.isRequired, // 是否为常量列表
};

export default JournalFilterPopupContent;

import { SmallFont } from '@/components/FontStyle';
import {
  ColumnCentered,
  RowCentered,
} from '@/componentsphase2/Autocomplete/CommonStyle';
import ItemBlock from '@/componentsphase2/Itemblock';
import RequiredTag from '../SVG/RequiredTag.svg';
import { Box } from '@mui/material';

export default function MyItemBlock(props) {
  const { columnAlign, height = '100%', sx = {}, ...other } = props;

  return (
    <Box sx={sx}>
      <ItemBlock
        stackProps={{
          first: {
            spacing: 3,
            direction: 'row',
          },
          // second: {
          //   stackSX: {
          //     width: '208px',
          //   },
          // },
        }}
        titleProps={{
          titleSX: {
            ...SmallFont,
            color: '#596A7C',
          },
          boxSX: {
            height: height,
            width: '208px',
            ...(columnAlign ? ColumnCentered : {}),
          },
        }}
        requiredTag={<RequiredTag />}
        {...other}
      />
    </Box>
  );
}

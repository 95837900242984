import * as actions from '../actions/SIMT-SI/CatJournal/CATJournalAction';

const initState = {
  CATData: {
    result: [],
    count: 0,
  },
  allResult: [],
  orderByType: {
    // 排序相关
    order: 'desc',
    orderBy: 'lastUpdateTime',
  },
  page: 1,
  pageSize: 50,
  journalData: [],
  journalCreateResult: null,
  searchCATContent: '',
  developmentMode: [],
  createCatchJournal: null,
  createCatchCe: null,
  createCatchDevelopment: null,
  createCatchOptDate: null,
  journalDeleteResult: null,

  submitOptInResult: null,
  submitOptInCode: null,
  submitOptInMonitor: false,
  optInPage: 1,
  optInPageSize: 50,
  optInData: {
    result: [],
    count: 0,
  },
  optOutPage: 1,
  optOutPageSize: 50,
  optOutData: {
    result: [],
    count: 0,
  },
  isAllSeleted: false,
  isAllUserSeleted: false,
  selectedList: [],
  selectedListInfo: {},
  selectedUserList: [],
  selectedUserListInfo: {},
  approveOptInCode: null,
  rejectOptInCode: null,
  withdrawOptInResult: null,
  withdrawOptInMonitor: false,
  editOptInResult: null,
  editOptInCode: null,
  editOptInMonitor: false,
  batchUpdateResult: null,
  batchUpdateMonitor: false,
  batchUpdateCode: null,
  approveOptOutCode: null,
  rejectOptOutCode: null,
  checkJournalOptOutCode: null,
  JournalListResult: [],
  submitJournalOptOutCode: null,
  editOptOutCode: null,
  withdrawOptOutCode: null,
  requestHistoryTypeSelectedList: ['Opt-in', 'Opt-out'],
  requestHistoryStatusSelectedList: ['Approved', 'Rejected', 'Withdrawn'],
  requestHistoryOrderByType: {
    order: 'desc',
    orderBy: 'statusDate',
  },
  requestHistoryPage: 1,
  requestHistoryPageSize: 50,
  requestHistoryTabelData: null,

  /** @type {import("@/pages/CATJournal/Overview/CATTable/CATSearchRow/journal-filter-components/JournalFilterPopup").CatFilterDataState} */
  catFilterData: {
    // 显示在filter中的数据
    journalCode: [],
    journalName: [],
    status: [],
    developmentModel: [],
    journalHandlingCe: [],
    scam:[],
    catGroup: [],
    pdPublisher: [],
    pdManager: [],
    pdDirector: [],
    ppPublisher: [],
    ppManager: [],
    ppDirector: [],
    subjectGroup: [],
    businessDivision: [],
    revenueModel: [],
  },
  subjectGroup: [],
  /** @type {import("@/pages/CATJournal/Overview/CATTable/CATSearchRow/journal-filter-components/JournalFilterPopup").CatFilterSelectedState} */
  catFilterSelected: {
    // filter中选取了的数据
    journalCode: [],
    journalName: [],
    status: [],
    optInDateFrom: [null],
    optInDateTo: [null],
    optOutDateFrom: [null],
    optOutDateTo: [null],
    developmentModel: [],
    journalHandlingCe: [],
    scam: [],
    catGroup: [],
    pdPublisher: [],
    pdManager: [],
    pdDirector: [],
    ppPublisher: [],
    ppManager: [],
    ppDirector: [],
    subjectGroup: [],
    businessDivision: [],
    revenueModel: [],
  },
};

export default function CATJournalReducer(state = initState, action) {
  const { type, data, which } = action;
  switch (type) {
    case actions.GET_CAT_JOURNALS_BY_CODE: {
      return {
        ...state,
        JournalListResult: data.data
      };
    }
    case actions.GET_CAT_JOURNALS_BY_NAME: {
      return {
        ...state,
        JournalListResult: data.data
      };
    }
    case actions.SUBMIT_OPTIN_CHECK_JOURNAL:
      return {
        ...state,
        submitOptInCode: data,
      };
    case actions.LOAD_REQUEST_HISTORY_LIST:
      return {
        ...state,
        requestHistoryTabelData: data,
      };
    case actions.CHANGE_REQUEST_HISTORY_PAGE:
      return {
        ...state,
        requestHistoryPage: data,
      };
    case actions.CHANGE_REQUEST_HISTORY_PAGE_SIZE:
      return {
        ...state,
        requestHistoryPageSize: data,
      };
    case actions.REQUEST_HISTORY_ORDER_BY:
      return {
        ...state,
        requestHistoryOrderByType: data,
      };
    case actions.HANDLE_REQUEST_HISTOYE_Status:
      let index1 = state.requestHistoryStatusSelectedList.indexOf(data);
      let newArr1 = state.requestHistoryStatusSelectedList.concat();
      if (index1 !== -1) {
        newArr1.splice(index1, 1);
      } else {
        newArr1.push(data);
      }
      return {
        ...state,
        requestHistoryStatusSelectedList: newArr1,
      };
    case actions.HANDLE_REQUEST_HISTOYE_TYPE:
      let index2 = state.requestHistoryTypeSelectedList.indexOf(data);
      let newArr2 = state.requestHistoryTypeSelectedList.concat();
      if (index2 !== -1) {
        newArr2.splice(index2, 1);
      } else {
        newArr2.push(data);
      }
      return {
        ...state,
        requestHistoryTypeSelectedList: newArr2,
      };
    case actions.CLEAR_BATCH_UPDATE_CODE:
      return {
        ...state,
        batchUpdateCode: null,
      };
    case actions.BATCH_UPDATE_ERROR:
      return {
        ...state,
        batchUpdateCode: data,
      };
    case actions.BATCH_UPDATE_SUCCESS:
      return {
        ...state,
        batchUpdateResult: data,
        batchUpdateMonitor: !state.batchUpdateMonitor,
      };
    case actions.CLEAR_EDIT_OPTIN_CODE:
      return {
        ...state,
        editOptInCode: data,
      };
    case actions.Edit_OPTIN_SUCCESS:
      return {
        ...state,
        editOptInResult: data,
        editOptInMonitor: !state.editOptInMonitor,
      };
    case actions.Edit_OPTIN_FAIL:
      return {
        ...state,
        editOptInCode: data,
        editOptInResult: null,
        editOptInMonitor: !state.editOptInMonitor,
      };
    case actions.WITHDRAW_OPTIN:
      return {
        ...state,
        withdrawOptInResult: data,
        withdrawOptInMonitor: !state.withdrawOptInMonitor,
      };
    case actions.CLEAR_SUBMIT_OPTIN_CODE:
      return {
        ...state,
        submitOptInCode: data,
      };
    case actions.SUBMIT_OPTIN_SUCCESS:
      return {
        ...state,
        submitOptInResult: data,
        submitOptInMonitor: !state.submitOptInMonitor,
      };
    case actions.SUBMIT_OPTIN_FAIL:
      return {
        ...state,
        submitOptInCode: data,
        submitOptInResult: null,
        submitOptInMonitor: !state.submitOptInMonitor,
      };
    case actions.CAT_ORDER_BY:
      return {
        ...state,
        orderByType: data,
      };
    case actions.CHANGE_CAT_PAGE:
      return {
        ...state,
        page: data,
      };
    case actions.CHANGE_CAT_PAGE_SIZE:
      return {
        ...state,
        pageSize: data,
        page: 1,
      };
    case actions.GET_JOURNAL:
      return {
        ...state,
        journalData: data,
      };
    case actions.CREATE_JOURNAL:
      return {
        ...state,
        journalCreateResult: data,
      };
    case actions.LOAD_CAT_DATA:
      return {
        ...state,
        CATData: {
          result: data.data,
          count: data.total,
        },
      };
    case actions.LOAD_ALL_CAT_DATA:
      return {
        ...state,
        allResult: data.data,
      };

    case actions.SET_CAT_SEARCH_CONTENT:
      return {
        ...state,
        searchCATContent: data,
      };
    case actions.GET_DEVELOPMENTMODE:
      return {
        ...state,
        developmentMode: data,
      };
    case actions.CATCH_SELECT: {
      switch (which) {
        case 'journal':
          return {
            ...state,
            createCatchJournal: data,
          };
        case 'ce':
          return {
            ...state,
            createCatchCe: data,
          };
        case 'development':
          return {
            ...state,
            createCatchDevelopment: data,
          };
        case 'optDate':
          return {
            ...state,
            createCatchOptDate: data,
          };
      }
    }
    case actions.CLEAR_JOURNAL:
      return {
        ...state,
        createCatchJournal: null,
        createCatchCe: null,
        createCatchDevelopment: null,
        createCatchOptDate: null,
        journalCreateResult: '',
      };
    case actions.DELETE_CAT_JOURNAL:
      return {
        ...state,
        journalDeleteResult: data,
      };
    case actions.CLEAR_JOURNAL_RESULT:
      return {
        ...state,
        journalCreateResult: '',
      };
    case actions.ADD_SELECTED: {
      // let newSelected;
      // if (state.selectedList.indexOf(data) > -1) {;
      //   newSelected = state.selectedList;
      // } else {
      //   newSelected = [data, ...state.selectedList];
      //   if (state.selectedListInfo[data] === undefined) {
      //     state.selectedListInfo[data] = data;
      //   }
      // }
      let newSelected;
      if (state.selectedList.indexOf(data.journalCode) > -1) {
        newSelected = state.selectedList; // 保持不变
      } else {
        newSelected = [data.journalCode, ...state.selectedList];
        if (state.selectedListInfo[data.journalCode] === undefined) {
          state.selectedListInfo[data.journalCode] = data;
        } // 新增加
      }
      return {
        ...state,
        selectedList: newSelected,
      };
    }

    case actions.REMOVE_SELECTED:
      // delete(state.selectedListInfo[data]);
      return {
        ...state,
        isAllSeleted: false,
        selectedList: state.selectedList.filter(item => {
          return item !== data;
        }),
      };
    case actions.REMOVE_SELECTED_INFO:
      delete state.selectedListInfo[data];
      return {
        ...state,
      };
    case actions.SELECT_ALL:
      return {
        ...state,
        // isAllSeleted: true,
        // selectedList: [],
        selectedList: [...data],
      };
    case actions.CANCEL_SELECT_ALL:
      return {
        ...state,
        // isAllSeleted: false,
        selectedList: [],
      };
    case actions.SET_IS_ALL_SELECTED:
      return {
        ...state,
        isAllSeleted: data,
      };
    case actions.SELECT_ALL_USER:
      return {
        ...state,
        isAllUserSeleted: true,
        // selectedUserList: [],
      };
    case actions.CANCEL_SELECT_ALL_USER:
      return {
        ...state,
        isAllUserSeleted: false,
        selectedUserList: [],
        selectedUserListInfo: {},
      };
    case actions.CHANGE_OPT_IN_PAGE:
      return {
        ...state,
        optInPage: data,
      };
    case actions.CHANGE_OPT_IN_PAGE_SIZE:
      return {
        ...state,
        optInPageSize: data,
      };
    case actions.SET_OPT_IN_DATA:
      return {
        ...state,
        optInData: {
          result: data.result,
          count: data.count,
        },
      };
    case actions.CHANGE_OPT_OUT_PAGE:
      return {
        ...state,
        optOutPage: data,
      };
    case actions.CHANGE_OPT_OUT_PAGE_SIZE:
      return {
        ...state,
        optOutPageSize: data,
      };
    case actions.SET_OPT_OUT_DATA:
      return {
        ...state,
        optOutData: {
          result: data.result,
          count: data.count,
        },
      };
    case actions.REJECT_OPT_IN_CODE:
      return {
        ...state,
        rejectOptInCode: data,
      };
    case actions.APPROVE_OPT_IN_CODE:
      return {
        ...state,
        approveOptInCode: data,
      };
    case actions.CHECK_JOURNAl_OPT_OUT_CODE:
      return {
        ...state,
        checkJournalOptOutCode: data,
      };
    case actions.RESET_CHECK_JOURNAl_OPT_OUT_CODE:
      return {
        ...state,
        checkJournalOptOutCode: null,
      };
    case actions.SUBMIT_JOURNAl_OPT_OUT_CODE:
      return {
        ...state,
        submitJournalOptOutCode: data,
      };
    case actions.REJECT_OPT_OUT_CODE:
      return {
        ...state,
        rejectOptOutCode: data,
      };
    case actions.APPROVE_OPT_OUT_CODE:
      return {
        ...state,
        approveOptOutCode: data,
      };
    case actions.EDIT_JOURNAl_OPT_OUT_CODE:
      return {
        ...state,
        editOptOutCode: data,
      };
    case actions.WITHDRAW_JOURNAl_OPT_OUT_CODE:
      return {
        ...state,
        withdrawOptOutCode: data,
      };
    case actions.SELECT_CAT_JOURNAL_FILTER_ITEM: {
      let newSel;
      if (data.operation === 'select') {
        // if the operation is select
        // then the clicked item will be selected
        // the corresponding list will append the item if not selected before
        newSel = [...state.catFilterSelected[data.name], data.item];
      } else if (data.operation === 'unselect') {
        // unselect the item
        newSel = state.catFilterSelected[data.name].filter(item => {
          return item !== data.item;
        });
      } else if (data.operation === 'replace') {
        newSel = [data.item];
      } else {
        return state; // 不匹配任何指令
      }
      return {
        ...state,
        catFilterSelected: {
          ...state.catFilterSelected,
          [data.name]: newSel,
        },
      };
    }
    case actions.LOAD_CAT_JOURNAL_FILTER: {
      return {
        ...state,
        catFilterData: {
          ...state.catFilterData,
          [data.name]: data.data.filter(item=>item!==null),
        },
      };
    }
    case actions.GET_SUBJECT_GROUP_LIST: {
      return {
        ...state,
        subjectGroup: data?.data,
      };
    }
    default:
      return state;
  }
}

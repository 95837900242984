import {
    DecisionFormItemProperties,
    DynamicOptions, SelectLabel
} from "@/pages/SIPPage/SIPDetailPage/DecisionPanel/FormItem/MultiSelect";
import {useEffect, useState} from "react";
import {axios_instance} from "@/utils/axios_instance";
import {SelectInput} from "@/componentsphase2/SelectBox/SelectInput";
import {judgeCondition} from "@/pages/SIPPage/SIPDetailPage/DecisionPanel/utils";
import {useParams} from "react-router-dom";

export interface SingleSelectProps<T> {
    value?: T;
    update: (value: T) => void;
    properties?: {
        options: DynamicOptions<T>;
    } & DecisionFormItemProperties,
    onCommand?: (command: string, params: any) => void;
    onOpen?: () => void;
    onClose?: () => void;
}

export default function SingleSelect<T>({
    value,
    update,
    properties = {
        options: {},
        searchable: false,
    },
    onCommand,
    onOpen = () => {},
    onClose = () => {},
}: SingleSelectProps<T>) {
    const { sipCode } = useParams();

    const [options, setOptions] = useState<T[]>(properties?.options?.initial || [] as T[]);
    const [filteredOptions, setFilteredOptions] = useState<T[]>(options);

    const loadOptions = async (text: string) => {
        const { url, params, queryKey } = properties.options;
        if (url){
            // to support dynamic url with sipCode
            const queryUrl = url.replace('${sipCode}', sipCode as string);
            const res = await axios_instance.get(queryUrl, {
                params: {
                    ...params,
                    [queryKey ?? 'text']: text,
                }
            });
            const optionsToBeSet = Array.isArray(res.data?.data)
                ? res.data.data
                : Array.isArray(res.data?.data?.result)
                    ? res.data.data.result
                    : [];
            setOptions(optionsToBeSet);
        }
    };

    useEffect(() => {
       if (properties?.options?.queryWhenLoading) {
          loadOptions('');
       }
    }, [properties.options]);

    // @ts-ignore
    const getValueFromObject = (option: T) => option?.[properties.options?.label ?? 'value'] ?? ' ';
    // @ts-ignore
    const getOptionLabel: ((option: T) => string) = properties.options.label ?
        getValueFromObject:
        option => option?.toString() ?? '';

    // @ts-ignore
    const getOptionSubLabel: ((option: T) => string) = option => option?.[properties.options?.subLabel] ?? ' ';

    const filterOptions = (text: string) => {
        if (properties.options?.queryWhenInput) {
            return;
        }
        setFilteredOptions(options.filter(option => {
            const value = getOptionLabel(option);
            return value.toLowerCase().includes(text.toLowerCase());
        }));
    };

    const loadOptionsAction = properties?.options?.queryWhenLoading ?
        (properties?.options?.queryWhenInput ? loadOptions : filterOptions)
        : undefined;

    // @ts-ignore
    return (<SelectInput sx={{ '& .MuiInputBase-input': { paddingLeft: '8px !important',}}}
            inputProps={{
                readonly: !properties?.searchable ? 'readonly' : undefined,
            }}
            value={value}
            loadOptionAction={loadOptionsAction}
            getOptionLabel={getOptionLabel}
            // InputProps={{disabled: true}}
            options={properties?.options?.queryWhenInput ? options : filteredOptions}
            placeholder={properties.placeholder || `Choose ${properties.title}`}
            showStartIcon={false}
            disableNewItem={true}
            noAddSelectItem={true}
            disableCheckBox={false}
            showPopupIcon={true}
            onOpen={onOpen}
            onClose={onClose}
            onValueChange={ (newValue: T) => {
                update(newValue);
                if (properties?.events?.change) {
                    properties.events.change.forEach(conf => {
                        if (judgeCondition(conf.condition, newValue)) {
                            onCommand?.(conf.command, conf[conf.command]);
                        }
                    });
                }
            }}
            renderOptionLabel={properties.options.subLabel ? (option: T) => (
                <SelectLabel
                    label={getOptionLabel(option)}
                    subLabel={getOptionSubLabel(option)}
                    reverse={properties.options.labelReverse}
                />
            ) : undefined}
        />
    );
}

import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SIPViewAllArrow from '@/assets/SIPViewAllArrow.svg';
import { useParams } from 'react-router-dom';
import './HideText.css';
import TextViewAllButton from '@/componentsphase2/Button/TextViewAllButton';
import {getSIPInformation} from "@/actions/SIMT-SIP/SIP/SipDetail";

export default function HideText(props) {
  /**
   * To modify this document, please contact Zhenxiang Zhao (915123030@qq.com)
   */
  const { defaultLineNum = 3 } = props;

  const [lineNum, setLineNum] = useState(defaultLineNum);
  const [expanding, setExpanding] = useState(false);
  const { sipCode } = useParams();
  const dispatch = useDispatch();
  const [needHide, setNeedHide] = useState(false);
  const maxHeight = lineNum * 20;
  const elementRef = useRef(null);
  const selector = state => {
    return {
      sipInformation: state.SIP.sipInformation,
    };
  };
  const { sipInformation } = useSelector(selector);

  useEffect(() => {
    dispatch(getSIPInformation(sipCode));
  }, []);

  useEffect(() => {
    if (elementRef.current.scrollHeight > maxHeight) {
      setNeedHide(true);
    } else {
      setNeedHide(false);
    }
    window.addEventListener('resize', resizeUpdate);
    return () => {
      window.removeEventListener('resize', resizeUpdate);
    };
  }, [sipInformation]);

  const resizeUpdate = e => {
    if (elementRef.current.scrollHeight > maxHeight) {
      setNeedHide(true);
    } else {
      setNeedHide(false);
    }
  };

  const textStyle = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#262E35',
    flex: 'none',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: lineNum,
    WebkitBoxOrient: 'vertical',
    // maxHeight: maxHeight,
  };

  const viewAllStyle = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.01em',
    color: '#154AB6',
    flex: 'none',
    cursor: 'pointer',
  };

  const handleViewAllClick = () => {
    setExpanding(true);
    setLineNum('inherit');
  };

  const handleHideClick = () => {
    setExpanding(false);
    setLineNum(defaultLineNum);
  };

  return (
    <div
      data-selenium-id='SIP_DetailPage_Submodule-HideText-Div'
      style={{
        position: 'relative',
        // overflowX: "auto",
      }}
    >
      <div
        style={textStyle}
        ref={elementRef}
        data-selenium-id='SIP_DetailPage_Submodule-HideText-PropsDiv'
      >
        {props.children}
      </div>
      {needHide ? (
        expanding ? (
          <div
            style={{ marginTop: '4px' }}
            data-selenium-id='SIP_DetailPage_Submodule-HideText-DivHandleClickSpan'
          >
            <span style={viewAllStyle} onClick={handleHideClick}>
              Hide
            </span>
          </div>
        ) : (
          <div
            style={{ marginTop: '4px' }}
            data-selenium-id='SIP_DetailPage_Submodule-HideText-DivViewAllClick'
          >
            <TextViewAllButton
              style={viewAllStyle}
              onClick={handleViewAllClick}
              data-selenium-id='SIP_DetailPage_Submodule-HideText-HandleViewAllClickSpan'
            />
            {/* <span
              style={viewAllStyle}
              onClick={handleViewAllClick}
              data-selenium-id='SIP_DetailPage_Submodule-HideText-HandleViewAllClickSpan'
            >
              <span data-selenium-id='SIP_DetailPage_Submodule-HideText-ViewAllSpan'>
                View All
              </span>{' '}
              <span
                id='viewAllArrow'
                data-selenium-id='SIP_DetailPage_Submodule-HideText-ViewAllArrowSpan'
              >
                <SIPViewAllArrow />
              </span>
            </span> */}
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
}

// create Redux action types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const SEND_FORGETPASS_EMAIL = 'SEND_FORGETPASS_EMAIL';
export const CLEAR_LOGIN = 'CLEAR_LOGIN';
export const CHECK_LINK_VALID = 'CHECK_LINK_VALID';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const RESETPASS = 'RESETPASS';
export const FIRST_CLEAR = 'FIRST_CLEAR';
export const VALID_CLEAR = 'VALID_CLEAR';
export const FIRST_FORGET_PASS = 'FIRST_FORGET_PASS';
export const LOGIN_FAILED_CLEAR = 'LOGIN_FAILED_CLEAR';
export const GET_LOGIN_FAIL_COUNT_SUCCESS = 'GET_LOGIN_FAIL_COUNT_SUCCESS';
export const GET_LOGIN_FAIL_COUNT_FAIL = 'GET_LOGIN_FAIL_COUNT_FAIL';
export const SET_FORGET_PASS_EMAIL_MESSAGE = 'SET_FORGET_PASS_EMAIL_MESSAGE';

// Create Redux action creators that return an action
export const loginSuccess = res => ({
  type: LOGIN_SUCCESS,
  data: res,
});

export const loginFailed = errMsg => ({
  type: LOGIN_FAILED,
  data: errMsg,
});

export const clear = () => ({
  type: CLEAR_LOGIN,
});

export const firstClear = () => ({
  type: FIRST_CLEAR,
});

export const validClear = () => ({
  type: VALID_CLEAR,
});

export const loginFailedClear = () => ({
  type: LOGIN_FAILED_CLEAR,
});

export const getLoginFailCountFail = () => ({
  type: GET_LOGIN_FAIL_COUNT_FAIL,
});

export const getLoginFailCountSuccess = data => ({
  type: GET_LOGIN_FAIL_COUNT_SUCCESS,
  data: data.data,
});

export const setForgetPassEmailMessage = () => ({
  type: SET_FORGET_PASS_EMAIL_MESSAGE,
});


import {axios_instance, axios_instance_fileUpload} from "@/utils/axios_instance";
import {forEmail} from "@/modules/RichTextEditor/EditorUtils";
import {extractAttachmentId} from "@/utils/commonUtils";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {
    DELETE_ATTACHMENT,
    GET_EMAIL_SHOW_INFO,
    SEND_EMAILRETURNCODE,
    UPLOAD_ATTACHMENT
} from "@/actions/SIMT-Email/EmailAction";
import getAuth from "@/utils/getAuth";

/**
 * @param {'deadlineChanged'|'editorialWritingToGE'|'geGuidelineSharing'|'siGeAccessRemoving'|'siGeEicMonth'|'siGeGranting'|'thankYouLetterToGE'} type
 * @param {`SI-{string}-{string}`|string} [siCode]
 */
const getSIemail=(type,siCode=location.search.match(/\?siCode=(SI-\d{4}-\d{6})&/)?.[1])=>
/**
 * @param {import("redux").Dispatch} dispatch 
 */
(dispatch)=>axios_instance.get(`/api/v1/si-service/special-issues/${siCode}/emails`,{
  params:{
    type,
  },
  headers:{
    authorization: getAuth()??'',
  }
}).then((/**@type {import("@/utils/axios-response").AxiosResponse<import("@/pages/Email/SendEmail").responseData>}*/res)=>{
  if (res.data.code === 200) {
                dispatch({
                    type: GET_EMAIL_SHOW_INFO,
                    data: {
                        ...res.data.data,
                        content: res.data.data.content,
                    },
                });
            } else {
                dispatch({type: GET_EMAIL_SHOW_INFO, data: null});
                dispatch(
                    setSnackbarMessageAndOpen('email.showError', {}, SEVERITIES.error)
                );
            }
      }).catch(err=>{
            dispatch(
                setSnackbarMessageAndOpen(
                    'email.updateError',
                    {
                        value: err.data.error,
                    },
                    SEVERITIES.error
                )
            );
        }
);
/**@typedef {Parameters<typeof getSIemail>[1]} siCode*/
/**
 * @param {siCode} siCode 
 */
export function getEmailShowInfoSetUp(siCode) {
  return getSIemail('siGeGranting',siCode);
};

/**
 * @param {siCode} siCode 
 */
export function getEmailShowInfoRemove(siCode) {
    return getSIemail('siGeAccessRemoving',siCode);
}
/**
 * @param {siCode} siCode 
 */
export function getEmailShowInfoGeGuideLineSharing(siCode) {
    return getSIemail('geGuidelineSharing',siCode);
}
/**
 * @param {siCode} siCode 
 */
export function getEmailShowInfoEditorial(siCode) {
    return getSIemail('editorialWritingToGE',siCode);
}

export function sendManualEmail(
    from,
    to,
    copy,
    bcc,
    subject,
    content,
    files,
    siCode
) {
    return async dispatch => {
        try {
            const url =  `/api/v1/si-service/special-issues/${siCode}/emails`;
            const res = await axios_instance.post(
                url,
                {
                    siCode: siCode,
                    from: from,
                    to: to,
                    bcc: bcc,
                    copy: copy,
                    subject: subject,
                    content: forEmail(content),
                    attachment: extractAttachmentId(files),
                    contentWithCss: null, // 后端期望获得null
                },
                {
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );

            // 获取发送邮件后的状态码
            dispatch({type: SEND_EMAILRETURNCODE, data: res.data.code});
            if (res.data.code == 200) {
                dispatch(
                    setSnackbarMessageAndOpen('email.sendSuccess', {}, SEVERITIES.success)
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen('email.sendError', {}, SEVERITIES.error)
                );
            }
        } catch (err) {
            // 发送邮件错误时给前端反馈
            dispatch({type: SEND_EMAILRETURNCODE, data: err.data.timestamp});
            dispatch(
                setSnackbarMessageAndOpen(
                    'email.updateError',
                    {
                        value: err.data.error,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
}

export function UploadAttachment(attachment, fileIdentifier, FileControl = false) {
    return async dispatch => {
        try {
            // let formData = new FormData();
            // formData.append("file", attachment);
            let url = '/api/v1/si-service/special-issues/emails/attachments';
            const res = await axios_instance_fileUpload.post(url, attachment, {
                headers: {
                    authorization: getAuth(),
                },
            });

            if (res.data.code === 200) {
                dispatch({
                    type: UPLOAD_ATTACHMENT,
                    data: {
                        state: 'success',
                        fileIdentifier: fileIdentifier,
                        fileID: res.data.data,
                    },
                });
            } else if (res.data.code === 14105) {
                dispatch({
                    type: UPLOAD_ATTACHMENT,
                    data: {state: 'emptyError', fileIdentifier: fileIdentifier},
                });
            } else {
                dispatch({
                    type: UPLOAD_ATTACHMENT,
                    data: {state: 'error', fileIdentifier: fileIdentifier},
                });
            }
        } catch (err) {
            dispatch({
                type: UPLOAD_ATTACHMENT,
                data: {state: 'error', fileIdentifier: fileIdentifier},
            });
        }
    };
}

export function DeleteAttachment(id) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/special-issues/emails/attachments/${id}`;
            const res = await axios_instance.delete(url, {
                headers: {
                    authorization: getAuth(),
                },
            });

            if (res.data.code === 200) {
                dispatch({type: DELETE_ATTACHMENT, data: res.data.data});
            } else {
                dispatch({type: DELETE_ATTACHMENT, data: 'error'});
            }
        } catch (err) {
            dispatch({type: DELETE_ATTACHMENT, data: 'error'});
        }
    };
}

/**
 * @param {siCode} siCode 
 */
export function getEmailShowInfoMonthlySubmission(siCode) {
    return getSIemail('siGeEicMonth',siCode);
}
/**
 * @param {siCode} siCode 
 */
export function getEmailShowInfoThankyouLetter(siCode) {
    return getSIemail('thankYouLetterToGE',siCode);
}
/**
 * @param {siCode} siCode 
 */
export function getEmailShowDeadlineChanged(siCode) {
    return getSIemail('deadlineChanged',siCode);
}
import { Box, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JournalTable from './JournalTable';
import CECATTable from './CECATTable';
import TargetSubjectGroupTable from './TargetSubjectGroupTable';
import TargetBusinessDivisionTable from './TargetBusinessDivisionTable';
import { CustomizedInput } from '../../../AnnualTarget/Overview';

// import { setFiscalYear } from '@/actions/AnnualTargetAction';
import { selectYear as fyChangeFiscalYear, switchTarget } from '../../../../actions/SIMT-Report/Report/ReportAction';
import { getFyYearFromDate } from '../../../../utils/date_string';
import { FormattedMessage } from 'react-intl';
import FiscalYearSelector from '../../ReportSpecificComponents/FiscalYearSelector';
import React from 'react';
import { ShowForPermission } from '../../../../components/PermissionControl/ShowForPermission';
import { TableBox, TableGroupBox, TableLabel } from '../../ReportSpecificComponents/SpecialColorTableHeader';
import {GetAcquiredActiveLastActionDateFY} from "@/actions/SIMT-Report/Report/ReportAcquiredAndActive";
import {PERMISSIONS} from "@/constant/permission";

/**
 * @author LiuXin
 * @date 2022/5/11
 * @description Targets 页面中选择overview栏的信息展示
 */
function TargetsOverview() {
  const dispatch = useDispatch();
  const TARGET_TABS = [
    'JournalTarget',
    'SubjectTarget',
    'BusinessTarget',
    'CETarget',
  ];

  const {
    currentReportTab,
    currentReportTarget,
    // targetFiscalYear
    selectMonthDate,
  } =
    useSelector(state => {
      return {
        currentReportTab: state.Report.currentReportTab,
        currentReportTarget: state.Report.currentReportTarget,
        // targetFiscalYear: state.AnnualTarget.fiscalYear,
        selectMonthDate: state.Report.selectDate,
      };
    });

  const [fiscalYear, setFiscalYear] = useState(getFyYearFromDate(selectMonthDate));
  useEffect(() => {
    if (selectMonthDate != null) {
      const fyYear = getFyYearFromDate(selectMonthDate);
      setFiscalYear(fyYear);
    }
  }, [selectMonthDate]);


  useEffect(() => {
    if (currentReportTab === 0 && currentReportTarget != TARGET_TABS[0]) {
      scrollToAnchor(currentReportTarget);
      dispatch(switchTarget(TARGET_TABS[0]));
    }
  }, [currentReportTab]);

  const scrollToAnchor = anchorName => {
    if (anchorName !== '') {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        document.getElementById(anchorName).scrollIntoView(); // scroll to exact div id
      }
    }
  };

  //每个表的小标题的样式
  const tableTitleCss = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '16px',
    color: '#828282',
    marginLeft: '30px',
    marginTop: '28px',
    marginBottom: '24px',
  };

  return (
    <Box data-selenium-id='Report_Targets_OverviewPage-RootBox'>
      <Grid
        data-selenium-id='Report_Targets_OverviewPage-GridContainer'
        container
        direction='row'
        alignItems='center'
        sx={{
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        <Grid
          data-selenium-id='Report_Targets_OverviewPage-GridItem'
          item
          xs={12}
          sm={12}
          md={6}
        >
          {/*财年选择器*/}
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-start'
            spacing={1}
            data-selenium-id='Report_Targets_OverviewPage-FY-Stack'
          >
            {/*label*/}
            <InputLabel
              id='fiscalYearLabel'
              className='report-selector-label'
              style={{
                'font-family': 'Open Sans',
                'font-weight': '600',
                'font-size': '16px',
                'line-height': '24px',
                color: '#828282',
              }}
              data-selenium-id='Report_Targets_OverviewPage-Stack-InputLabel'
            >
              <FormattedMessage id='report.fiscalYear' />:
            </InputLabel>
            {/*选择器*/}
            <Box width='108px'>
              <FiscalYearSelector
                id='FiscalYearSelector'
                data-selenium-id='Report_Targets_OverviewPage-Stack-FiscalYearSelector'
                value={fiscalYear}
                handleYearChange={year => {
                  const fyYear = getFyYearFromDate(selectMonthDate);
                  if (year != fyYear) dispatch(fyChangeFiscalYear(year));
                }}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <TableGroupBox data-selenium-id='Report_Targets_OverviewPage-JournalTargetBox'>
        <TableLabel>Journal</TableLabel>
        <>
          <JournalTable
            data-selenium-id='Report_Targets_OverviewPage-JournalTarget-JournalTable'
            fiscalYear={fiscalYear}
          />
        </>
      </TableGroupBox>
      <TableGroupBox data-selenium-id='Report_Targets_OverviewPage-SubjectTargetBox'>
        <div
          data-selenium-id='Report_Targets_OverviewPage-SubjectTarget-Div'
          id={'SubjectTarget'}
        >
          <TableLabel><FormattedMessage
            id={'report.Journal.subjectGroup'}
            data-selenium-id='Report_Journal-SubjectGroup-TitleText'
          /></TableLabel>
          <>
            <TargetSubjectGroupTable
              data-selenium-id='Report_Targets_OverviewPage-SubjectTarget-TargetSubjectGroupTable'
              fiscalYear={fiscalYear}
            />
          </>
        </div>
      </TableGroupBox>
      <TableGroupBox data-selenium-id='Report_Targets_OverviewPage-BusinessTargetBox'>
        <div
          data-selenium-id='Report_Targets_OverviewPage-BusinessTarget-Div'
          id={'BusinessTarget'}
        >
          <TableLabel>Business Division</TableLabel>
          <>
            <TargetBusinessDivisionTable
              data-selenium-id='Report_Targets_OverviewPage-BusinessTarget-TargetBusinessDivisionTable'
              fiscalYear={fiscalYear}
            />
          </>
        </div>
      </TableGroupBox>
      <ShowForPermission permission={`${PERMISSIONS.REPORT_READ_TARGET}`} >
        <TableGroupBox data-selenium-id='Report_Targets_OverviewPage-CETargetBox'>
          <div
            data-selenium-id='Report_Targets_OverviewPage-CETarget-Div'
            id={'CETarget'}
          >
            <TableLabel>
              <FormattedMessage
                id={'report.ceCat'}
                data-selenium-id='Report_Journal-CeCatText'
              />
            </TableLabel>
            <>
              <CECATTable
                data-selenium-id='Report_Targets_OverviewPage-CETarget-CECATTable'
                fiscalYear={fiscalYear}
              />
            </>
          </div>
        </TableGroupBox>
      </ShowForPermission>
    </Box>
  );
}

export default TargetsOverview;

import { type HTMLAttributes, StrictMode, useMemo, useState } from 'react';
import CommonCollapse from './CommonCollapse';
import { Box } from '@mui/material';
import AboutAI from './AboutAI';
import {
  getJournalScopeCheckOrRecommendations,
  JournalScopeCheckOrRecommendationsTypes,
} from '@/actions/SIPAIScreeningReportAction';
import PropTypes from 'prop-types';
import BoxToMap from '../sections/journalScopeCheckBox';
import styleModule from './AIScreeningReportPage.module.scss';
import { useSelector } from 'react-redux';
const { freeze } = Object;
interface PropsType {
  readonly journalName?: string;
  readonly pkgJournalLink?: string; //不知是否带http(s)://开头，建议自行判断
  readonly matchScore?: number;
  readonly issn?: ReadonlyArray<string>;
  readonly numberOfPublications?: number; //自行判断是否>=5
  readonly publications?: ReadonlyArray<{
    readonly title: string;
    readonly doiLink: string; //不知是否带http(s)://开头，建议自行判断
  }>;
}
export type PropsTypeArray = ReadonlyArray<PropsType>;
interface JournalScopeCheckOrRecommendationsMainFrameProps
  extends HTMLAttributes<HTMLElement> {
  readonly sipCode: string;
  readonly title: string;
  readonly type: JournalScopeCheckOrRecommendationsTypes;
}
export default function JournalScopeCheckOrRecommendationsMainFrame(
  props: JournalScopeCheckOrRecommendationsMainFrameProps
) {
  const { sipCode = '', title = '', type, ...others } = props;
  const [dataToMap, setDataToMap] = useState<PropsTypeArray>([]);
  const dataToMapLength = useMemo(() => dataToMap.length, [dataToMap]);
  const id = useMemo(
    () => `AIScreenReportPage_${title.replace(/\s/g, '')}`,
    []
  );

  const aimsAndScope = useSelector(
    // @ts-ignore
    state => state.SIP.sipInformation?.aimsAndScope
  );

  return (
    <StrictMode>
      <CommonCollapse
        title={title}
        id={id}
        data-selenium-id={id}
        fetchData={() =>
          new Promise<PropsTypeArray>((resolve, reject) =>
            getJournalScopeCheckOrRecommendations(
              type,
              sipCode,
              resolve,
              reject
            )
          ).then(res => setDataToMap(res))
        }
        {...others}
      >
        <Box id={`${id}_content`} data-selenium-id={`${id}_content`}>
          {dataToMapLength ? (
            <AboutAI
              title='About percentage'
              content='Our system uses machine learning to assess how well this special issue proposal (SIP) fits the scope of the recommended journal by comparing it to content previously published. The percentage score is partly based on relevant articles similar to content of this SIP'
            />
          ) : (
            <></>
          )}
          <Box className={styleModule.boxToMap}>
            {aimsAndScope ? (
              dataToMapLength === 0 ? (
                <BoxToMap />
              ) : (
                dataToMap.map((item, index) => {
                  const matchScore = item.matchScore;
                  return (
                    <BoxToMap
                      key={index}
                      journalName={item.journalName}
                      publications={item.publications?.map(item => {
                        const doiLink = item.doiLink;
                        return {
                          ...item,
                          url: doiLink,
                          DOILink: doiLink,
                        };
                      })}
                      PKGJournalLink={item.pkgJournalLink}
                      ISSN={item.issn?.join(';')}
                      numberOfPublications={item.numberOfPublications}
                      matchScore={matchScore && (matchScore * 100).toFixed(0)}
                    ></BoxToMap>
                  );
                })
              )
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#596a7c',
                  fontSize: '20px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  borderRadius: '5px',
                  background: '#fff',
                  boxShadow: '0px 2px 8px 0px rgba(38, 46, 53, 0.10)',
                  py: '32px',
                  px: '24px',
                }}
              >
                {(function () {
                  let res = '';
                  if (
                    type ===
                    JournalScopeCheckOrRecommendationsTypes.JournalScopeCheck
                  )
                    res = 'Not enough information to provide check';
                  else if (
                    type ===
                    JournalScopeCheckOrRecommendationsTypes.JournalRecommendations
                  )
                    res = 'Not enough information to provide recommendation';
                  return res;
                })()}
              </Box>
            )}
          </Box>
        </Box>
      </CommonCollapse>
    </StrictMode>
  );
}
JournalScopeCheckOrRecommendationsMainFrame.propTypes = freeze({
  sipCode: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});
JournalScopeCheckOrRecommendationsMainFrame.defaultProps = freeze({
  sipCode: '',
  title: '',
  url: '',
});

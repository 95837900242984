import { useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import { SelectorMenus } from '../SelectorMenus';
import styles from './index.module.scss';

export function ChartOutlinedInput() {
  return <OutlinedInput label='Tag' />;
}

export const SingleSelect = ({
  disabled,
  label,
  options,
  selectedItem,
  setSelectedItem,
}) => {
  const [open, setOpen] = useState(false);

  const handleSelect = option => {
    setSelectedItem(option);
    setOpen(false);
  };

  return (
    <SelectorMenus
      label={label}
      disabled={disabled}
      highlight={false}
      open={open}
      setOpen={setOpen}
    >
      {Array.isArray(options) &&
        options.map((option, index) => (
          <MenuItem
            key={option}
            value={option}
            onClick={event => handleSelect(option)}
            disableRipple
            disableGutters={true}
            sx={{
              padding: '6px 12px',
            }}
          >
            <ListItemText
              primary={option}
              className={styles[option === selectedItem ? 'selected' : '']}
            />
          </MenuItem>
        ))}
    </SelectorMenus>
  );
};

import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import moment from 'moment';
import { SIMTButton } from '@/components/StyledButton/SIMTButton';
import Filter000 from '../../../../assets/Filter/Filter000.svg';
import ReportExportAllFolder from '../../../../assets/ReportExportAllFolder.svg';
import FilterListIcon from '@mui/icons-material/FilterList';
import { MonthPicker } from '../../../../components/DateRangePicker/MonthPicker';
import ReportOAPOAWExportAll from '../../../../assets/ReportOAPOAWExportAll.svg';
import {useFilterCounterFyHook} from '@/pages/Report/utils/useFilterCounterFyHook';

import {
  exportReportCSV,
  exportReportExcel,
  selectDate as selectArticleOverviewDate,
  exportReportCSVWithFilenameFromBackEnd,
  exportReportExcelWithFilenameFromBackEnd,
  exportReportExcelFilter,
  exportReportCSVFilter,
} from '@/actions/SIMT-Report/Report/ReportAction';

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import ReportExportMenuButton from '../../ReportSpecificComponents/ReportExportMenuButton';
import ReportArticleOAPOAWExportButton from '../../ReportSpecificComponents/ReportArticleOAPOAWExportButton';
import { CustomizedInput } from '../../../AnnualTarget/Overview';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DatePickerStack, GenerationDate } from '../../GenerationDate';
import { FormattedMessage } from 'react-intl';
import { StateButton } from '@/componentsphase2/StateButton';
import { TableLabel } from '../../ReportSpecificComponents/SpecialColorTableHeader';
import ExportAllDialog from '../../ExportAllDialog';

export function ArticleOverviewPageOverview(_props) {
  const {
    selectedArticleDate,
    articleOAWaiverUseBasicTableData,
    articleOAWaiverUseFilters,
  } = useSelector(state => {
    return {
      // filter display
      // selectedArticleDate: state.Report.selectedArticleDate,
      selectedArticleDate: state.Report.selectDate,
      articleOAWaiverUseBasicTableData:
        state.Report.articleOAWaiverUseBasicTableData,
      articleOAWaiverUseFilters: state.ReportFilter.articleOAWaiverUseFilters,
    };
  });

  const lastActionDate = articleOAWaiverUseBasicTableData?.lastActionDate;
  const GenerationDateAndTime =
    'Below reports are generated on ' + lastActionDate + ' (UTC time). ';
  const [selectMonth, setSelectMonth] = useState(new Date());
  const [months, setMonths] = useState([]);
  const dispatch = useDispatch();
  const currentDate = new Date();

  useEffect(() => {
    if (selectedArticleDate != null) {
      var Year = selectedArticleDate.split('-')[0];
      var Month = selectedArticleDate.split('-')[1] - 1;
      setSelectMonth(new Date(Year, Month));
    }
  }, [selectedArticleDate]);
  const exportExcel = () => {
    var filename = `Articles_Overview_${moment()
      .utc()
      .format('MMDDYYYY_HHmmss')}.xls`;
    var selectDate =
      Number(selectedArticleDate.split('-')[0]) * 100 +
      Number(selectedArticleDate.split('-')[1]);
    dispatch(
      exportReportExcel(
        selectDate,
        '/reports/export/article/overview/excel',
        filename
      )
    );
  };

  const exportExcelWithFilenameFromBackEnd = () => {
    var filename = `Articles_OAWaiverUse_${moment()
      .utc()
      .format('MMDDYYYY_HHmmss')}.xls`;
    var selectDate =
      Number(selectedArticleDate.split('-')[0]) * 100 +
      Number(selectedArticleDate.split('-')[1]);
    dispatch(
      exportReportExcelWithFilenameFromBackEnd(
        selectDate,
        '/reports/export/article/oa-waiver-use/excel',
        filename
      )
    );
  };

  const exportCsv = () => {
    var url = '/reports/export/article/overview/csv';
    var selectDate =
      Number(selectedArticleDate.split('-')[0]) * 100 +
      Number(selectedArticleDate.split('-')[1]);
    dispatch(
      exportReportCSV(
        selectDate,
        url,
        `Articles_Overview_basic_table_${moment()
          .utc()
          .format('MMDDYYYY_HHmmss')}.csv`,
        'basic_table'
      )
    );
    dispatch(
      exportReportCSV(
        selectDate,
        url,
        `Articles_Overview_subject_group_table_${moment()
          .utc()
          .format('MMDDYYYY_HHmmss')}.csv`,
        'subject_group_table'
      )
    );
    dispatch(
      exportReportCSV(
        selectDate,
        url,
        `Articles_Overview_business_division_table_${moment()
          .utc()
          .format('MMDDYYYY_HHmmss')}.csv`,
        'business_division_table'
      )
    );
    dispatch(
      exportReportCSV(
        selectDate,
        url,
        `Articles_Overview_CE/CAT_group_table_${moment()
          .utc()
          .format('MMDDYYYY_HHmmss')}.csv`,
        'CE/CAT_group_table'
      )
    );
  };

  const exportCsvWithFilenameFromBackEnd = () => {
    var url = '/reports/export/article/oa-waiver-use/csv';
    var selectDate =
      Number(selectedArticleDate.split('-')[0]) * 100 +
      Number(selectedArticleDate.split('-')[1]);
    dispatch(
      exportReportCSVWithFilenameFromBackEnd(
        selectDate,
        url,
        `Articles_OAWaiverUse_basic_table_${moment()
          .utc()
          .format('MMDDYYYY_HHmmss')}.csv`,
        'basic_table'
      )
    );
    dispatch(
      exportReportCSVWithFilenameFromBackEnd(
        selectDate,
        url,
        `Articles_OAWaiverUse_subject_group_table_${moment()
          .utc()
          .format('MMDDYYYY_HHmmss')}.csv`,
        'subject_group_table'
      )
    );
    dispatch(
      exportReportCSVWithFilenameFromBackEnd(
        selectDate,
        url,
        `Articles_OAWaiverUse_business_division_table_${moment()
          .utc()
          .format('MMDDYYYY_HHmmss')}.csv`,
        'business_division_table'
      )
    );
    dispatch(
      exportReportCSVWithFilenameFromBackEnd(
        selectDate,
        url,
        `Articles_OAWaiverUse_CE/CAT_group_table_${moment()
          .utc()
          .format('MMDDYYYY_HHmmss')}.csv`,
        'CE/CAT_group_table'
      )
    );
  };

  const getMonthList = () => {
    if (months.length === 0) {
      var year = currentDate.getFullYear();
      var month = currentDate.getMonth() + 1;
      for (var i = 0; i < 4; i++) {
        var tmpYear = year - i;
        var tmpMonth = 0;
        if (i === 0) {
          for (var j = month; j > 0; j--) {
            tmpMonth = j;
            months.push('' + tmpYear + '-' + tmpMonth);
            setMonths(months);
          }
        } else {
          for (var j = 12; j > 0; j--) {
            tmpMonth = j;
            months.push('' + tmpYear + '-' + tmpMonth);
            setMonths(months);
          }
        }
      }
    }
  };

  const handleDateChange = value => {
    setSelectMonth(value);
    var year = value.getFullYear();
    var month = value.getMonth() + 1;
    let date = year + '-' + month;
    dispatch(selectArticleOverviewDate(date));
  };

  useEffect(() => {
    getMonthList();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth() + 1;
    var newDate = '' + year + '-' + month;
    if (selectedArticleDate === null) {
      dispatch(selectArticleOverviewDate(newDate));
    }
  }, [dispatch]);

  const [exportOpen, setExportOpen] = useState(false);
  const exportExcelFilter = () => {
    let url = '/reports/export/article/oa-waiver-use/excelWithFilter';
    let selectDate =
      Number(selectedArticleDate.split('-')[0]) * 100 +
      Number(selectedArticleDate.split('-')[1]);
    // let newFilter  = {...articleOAWaiverUseFilters};
    // newFilter['CATgroup'] = newFilter.catGroup;
    // newFilter['journalName'] = [];
    // delete newFilter.catGroup;
    // delete newFilter.journalCode;
    dispatch(
      exportReportExcelFilter(selectDate, url, articleOAWaiverUseFilters)
    );
  };

  const handleExportExcel = type => {
    if (type === 'filter') {
      exportExcelFilter();
    } else {
      exportExcelWithFilenameFromBackEnd();
    }
  };

  const exportCsvFilterd = () => {
    let url = '/reports/export/article/oa-waiver-use/csvWithFilter';
    let selectDate =
      Number(selectedArticleDate.split('-')[0]) * 100 +
      Number(selectedArticleDate.split('-')[1]);
    // console.log(selectDate);
    dispatch(
      exportReportCSVFilter(
        selectDate,
        url,
        'basic_table',
        articleOAWaiverUseFilters
      )
    );
    dispatch(
      exportReportCSVFilter(
        selectDate,
        url,
        'subject_group_table',
        articleOAWaiverUseFilters
      )
    );
    dispatch(
      exportReportCSVFilter(
        selectDate,
        url,
        'business_division_table',
        articleOAWaiverUseFilters
      )
    );
    dispatch(
      exportReportCSVFilter(
        selectDate,
        url,
        'CE/CAT_group_table',
        articleOAWaiverUseFilters
      )
    );
  };
  const handleExportCSV = type => {
    if (type === 'filter') {
      exportCsvFilterd();
    } else {
      exportCsvWithFilenameFromBackEnd();
    }
  };

  return (
    <div>
      <ExportAllDialog
        open={exportOpen}
        handleClose={() => {
          setExportOpen(false);
        }}
        filterData={articleOAWaiverUseFilters}
        handleCSV={handleExportCSV}
        handleExcel={handleExportExcel}
      />
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        data-selenium-id='Report_Articles_OverHeader-Grid'
        sx={{
          margin: '16px 0px 16px 0px',
        }}
      >
        <DatePickerStack
          id='Report_Articles-OverHeader-DatePickerStack'
          selectedMonth={selectMonth}
          handleDateChange={handleDateChange}
          lastActionDate={lastActionDate}
          showTable={true}
          GenerationDateAndTime={GenerationDateAndTime}
        />

        <Grid>
          {/* <ReportArticleOAPOAWExportButton
            disableRipple
            startIcon={<ReportOAPOAWExportAll/>}
            sx={{
              width: '101px',
              height: '34px',
              background: '#0052CC',
              fontFamily: 'Inter',
              fontSize: '12px',
              fontWeight: 600,
              lineHeight: '18px',
              letterSpacing: '0em',
              padding: '8px 12px',
              '&:hover':{
                background: '#6D98EE',
              },
              '&:active':{
                background: '#113D95',
              }
            }}
            data-selenium-id='Report_Articles_OverHeader-ReportArticleOAWExportButton'
            items={[
              {
                label: 'Export to CSV',
                operation: exportCsvWithFilenameFromBackEnd,
              },
              {
                label: 'Export to Excel',
                operation: exportExcelWithFilenameFromBackEnd,
              },
            ]}
          /> */}
          <ReportExportMenuButton
            sx={{ height: '32px', marginRight: '24px' }}
            id='ReportArticleOAWExportButton'
            mode={1}
            // label='common.Export'
            onClick={() => {
              setExportOpen(true);
            }}
            data-selenium-id='Report_Articles_OverHeader-ReportArticleOAWExportButton'
          />
        </Grid>
      </Grid>
    </div>
  );
}

export function ArticleOverviewHeader(props) {
  const { filterOpen, filterOpenChange } = props;
  const { articleOAWaiverUseFilters } = useSelector(state => {
    return {
      articleOAWaiverUseFilters: state.ReportFilter.articleOAWaiverUseFilters,
    };
  });

  const filterNumber = useFilterCounterFyHook(articleOAWaiverUseFilters);

  const selector = state => {
    // console.log(state.UserManage);
    // console.log("state.UserManage");
    // console.log(state);
    return {
      userManageSiderOpen: state.UserManage.siderOpen,
      selectedList: state.CATJournal.selectedList,
    };
  };

  const { selectedList } = useSelector(selector);

  return (
    <div>
      <Box>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          gap= '20px'
          sx={{
            paddingTop: '1px',
            paddingRight: '30px',
          }}
        >
          <TableLabel>Basic Table</TableLabel>
          <Grid>
            {filterOpen ? (
              <div></div>
            ) : (
              <StateButton
                id='reportArticlesOverviewFilterOpen'
                data-selenium-id='Report_Articles_OAW_OverHeader-FilterOpen'
                onClick={filterOpenChange}
                startIcon={filterNumber === 0 ? <Filter000 /> : <div></div>}
                isPrimary={filterNumber !== 0}
                filterNumber={filterNumber}
                titleName='Filter'
                sx={{
                  height: '34px',
                  width: '74px',
                }}
              ></StateButton>
            )}
          </Grid>
        </Stack>
      </Box>
    </div>
  );
}

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
} from '@mui/material';
import {
  FormControlLabel,
  InputLabel,
  Switch,
  Tab,
  Tabs,
  Backdrop,
  Typography,
  Drawer,
  Grid,
  Divider,
} from '@mui/material';
import { H4Font, SmallBoldFont } from '@/components/FontStyle';
import GEDetailCloseIcon from '@/assets/GEDetailCloseIcon.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSubmitOptInCode,


} from '../../../../actions/SIMT-SI/CatJournal/CATJournalAction';
import SubmitContent from './SubmitContent';
import { StateButton } from '@/componentsphase2/StateButton';
import CATJournalCloseButton from '@/assets/CATJournalCloseButton.svg';
import CloseIconButton from '@/componentsphase2/Button/CloseIconButton';
import DrawerCloseIcon from '../../../../assets/DrawerCloseIcon.svg';
import {getOptInData, submitOptIn} from "@/actions/SIMT-SI/CatJournal/OptIn";

export default function OptInSubmit(props) {
  const { open = false, handleClose = () => {} } = props;

  const [isClick, setIsClick] = useState(false);
  const [data, setData] = useState(null);

  const dispatch = useDispatch();

  const {
    submitOptInResult,
    submitOptInMonitor,
    submitOptInCode,
    page,
    pageSize,
  } = useSelector(state => {
    return {
      submitOptInResult: state.CATJournal.submitOptInResult,
      submitOptInMonitor: state.CATJournal.submitOptInMonitor,
      submitOptInCode: state.CATJournal.submitOptInCode,
      page: state.CATJournal.optInPage,
      pageSize: state.CATJournal.optInPageSize,
    };
  });

  const handleCancel = () => {
    document.body.classList.remove('hide-scroll');
    handleClose();
    setIsClick(false);
    dispatch(clearSubmitOptInCode());
  };

  const handleSubmit = () => {
    setIsClick(true);
    if (
      data &&
      data.journalCode !== '' &&
      data.developmentModel !== '' &&
      data.reason !== '' &&
      data.currentSIPipelineAndFutureSIPlan !== '' &&
      submitOptInCode === null &&
      data.reason.length <= 10000 &&
      data.currentSIPipelineAndFutureSIPlan.length <= 100000
    ) {
      dispatch(submitOptIn(data));
      document.body.classList.remove('hide-scroll');
    }
  };

  useEffect(() => {
    if (submitOptInResult !== null) {
      dispatch(getOptInData(page, pageSize));
      setTimeout(() => {
        handleClose();
        setIsClick(false);
        dispatch(clearSubmitOptInCode());
      }, 300);
    }
  }, [submitOptInMonitor]);


  useEffect(() => {
    if(open){
      document.body.classList.add('hide-scroll');
    }else {
      document.body.classList.remove('hide-scroll');
      setData(null);
    }
  },[open]);

  const isError = () => {
    if (submitOptInCode !== null) {
      return true;
    }
    if (
      data &&
      (data.reason.length > 10000 ||
        data.currentSIPipelineAndFutureSIPlan.length > 100000)
    ) {
      return true;
    }
    if (!isClick) {
      return false;
    } else {
      return !(
        data &&
        data.journalCode !== '' &&
        data.developmentModel !== '' &&
        data.reason !== '' &&
        data.currentSIPipelineAndFutureSIPlan !== ''
      );
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
      data-selenium-id='CATJournal_Overview-OptIn-Submit-anotherNewFilterBox'
    >



      <Backdrop
        id='CATJournalOverviewOptInSubmitAnotherNewFilterDrawerBackdrop'
        data-selenium-id={`CATJournal_Overview-OptIn-Submit-anotherNewFilterDrawerBackdrop`}
        sx={{
          zIndex: 1400,
          backdropFilter: 'blur(4px)',
          background: '#113D9580',
        }}
        open={open}
      ></Backdrop>
      <Drawer
        id='CATJournalOverviewOptInSubmitAnotherNewFilterDrawer'
        variant='persistent'
        anchor='right'
        open={open}
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: '472px',
            zIndex: 1400,
            backgroundColor: '#FFFFFF',

          },

        }}
        data-selenium-id={`CATJournal_Overview-OptIn-Submit-anotherNewFilterDrawer`}
      >
        <Box
          sx={{
            m: '20px 36px',
            height: '34px',
          }}
          data-selenium-id='CATJournal_Overview-OptInOperation-Submit-Box'
        >
          <IconButton
            disableRipple
            sx={{
              float: 'left',
              cursor: 'pointer',
              padding: '0px',
              marginRight:'12px',
              pt:'5px',
              '&:hover':{
                'svg path':{
                  fill: '#596A7C'
                }
              }
            }}
            id = 'closeButtonOpt'
            onClick={() => {
              document.body.classList.remove('hide-scroll');
              handleClose();
              setIsClick(false);
              dispatch(clearSubmitOptInCode());
            }}
            data-selenium-id='CATJournal_Overview-OptInOperation-Submit-IconButton'
          >
            <DrawerCloseIcon/>
          </IconButton>

          <Box
            sx={{
              ...H4Font,
              height: '13px',
              float: 'left',
              color: '#262E35',
              pt:'5px'
            }}
            data-selenium-id='CATJournal_Overview-OptInOperation-Submit-Box-Box'
          >
            Opt-in Request
          </Box>

          <StateButton
            onClick={handleSubmit}
            data-selenium-id='CATJournal_Overview-OptInOperation-Submit-Box2'
            titleName='Submit'
            isPrimary={true}
            sx={{
              lineHeight: '18px',
              marginLeft: '10px',
              float: 'right',
              '&:disabled': {
                backgroundColor: '#BCC5CF',
                boxShadow: 'none',
                color: '#FFFFFF',
              },
            }}
            disabled={isError()}
          ></StateButton>

          <StateButton
            onClick={handleCancel}
            data-selenium-id='CATJournal_Overview-OptInOperation-Submit-Box1'
            titleName='Cancel'
            isPrimary={false}
            sx={{lineHeight: '18px',float: 'right'}}
          ></StateButton>

        </Box>
        <Divider></Divider>

        <SubmitContent
          errorMonitor={isClick}
          setValue={setData}
          open={open}
        ></SubmitContent>
      </Drawer>
    </Box>




    // <Dialog
    //   open={open}
    //   onClose={() => {
    //     handleClose();
    //     setIsClick(false);
    //     dispatch(clearSubmitOptInCode());
    //   }}
    //   sx={{
    //     marginTop: '48px',
    //     '& .MuiDialog-paper': {
    //       minWidth: '529px',
    //       margin: 0,
    //       padding: '32px 35px 0px 35px',
    //       boxSizing: 'border-box',
    //     },
    //     '& .MuiBackdrop-root': {
    //       backgroundColor: 'rgba(9, 30, 66, 0.54)',
    //     },
    //   }}
    //   data-selenium-id='CATJournal_Overview-OptInOperation-Submit-Dialog'
    // >
    //   <Box
    //     sx={{ height: '28px', marginBottom: '24px' }}
    //     data-selenium-id='CATJournal_Overview-OptInOperation-Submit-Box'
    //   >
    //     <Box
    //       sx={{
    //         ...H4Font,
    //         height: '28px',
    //         float: 'left',
    //         color: '#262E35',
    //       }}
    //       data-selenium-id='CATJournal_Overview-OptInOperation-Submit-Box-Box'
    //     >
    //       Opt-in Re
    //     </Box>
    //     <IconButton
    //       sx={{ float: 'right', cursor: 'pointer', padding: '0px' }}
    //       onClick={() => {
    //         handleClose();
    //         setIsClick(false);
    //         dispatch(clearSubmitOptInCode());
    //       }}
    //       data-selenium-id='CATJournal_Overview-OptInOperation-Submit-IconButton'
    //     >
    //       <GEDetailCloseIcon />
    //     </IconButton>
    //   </Box>
    //   <DialogContent
    //     sx={{ padding: '0px' }}
    //     data-selenium-id='CATJournal_Overview-OptInOperation-Submit-DialogContent'
    //   >
    //     <SubmitContent
    //       errorMonitor={isClick}
    //       setValue={setData}
    //     ></SubmitContent>
    //   </DialogContent>
    //   <DialogActions
    //     sx={{ padding: '0px', height: '68px' }}
    //     data-selenium-id='CATJournal_Overview-OptInOperation-Submit-DialogActions'
    //   >
    //     <Stack direction='row' spacing={1.125} sx={{ float: 'right' }}>
    //       <Box
    //         sx={{
    //           display: 'flex',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //           height: '36px',
    //           width: '82px',
    //         }}
    //         onClick={handleCancel}
    //         data-selenium-id='CATJournal_Overview-OptInOperation-Submit-Box1'
    //       >
    //         <span
    //           style={{ color: '#154AB6', cursor: 'pointer', ...SmallBoldFont }}
    //         >
    //           Cancel
    //         </span>
    //       </Box>
    //       <Box
    //         sx={{
    //           display: 'flex',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //           height: '36px',
    //           width: '82px',
    //           background: isError() ? '#BCC5CF' : '#154AB6',
    //           color: '#F1F3F5',
    //           borderRadius: '5px',

    //           cursor: isError() ? 'text' : 'pointer',
    //           ...SmallBoldFont,
    //         }}
    //         onClick={handleSubmit}
    //         data-selenium-id='CATJournal_Overview-OptInOperation-Submit-Box2'
    //       >
    //         Submit
    //       </Box>
    //     </Stack>
    //   </DialogActions>
    // </Dialog>
  );
}

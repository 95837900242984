import { TableFooter2 } from '@/components/DataTable';
import { StyledTableCell } from '@/components/DataTable';
import NoDataMessage from '@/componentsphase2/NoDataMessage';
import {
  Box,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  Typography, Table
} from "@mui/material";
import React, { useState, useEffect, useRef } from 'react';
import TextViewAllButton from '@/componentsphase2/Button/TextViewAllButton';
import { useNavigate } from 'react-router';
import { MulLineTooltipIfShow } from '@/componentsphase2/CustomTooltip';
import styleModule from '@/componentsphase2/FlaggedGESynchronizationAndVerificationTable/index.module.scss';
let ready = true;
export default props => {
  const navigate = useNavigate();
  const {
    titleList = [], //[['title','width','title text-align','info text-align','type']]
    tableData = [],
    flag,
    tableWidth = '100%',
    ...other
  } = props;
  //当前页数
  const [page, setPage] = useState(1);
  const [force, forceUpdate] = useState(1);
  const [open, setOpen] = useState([]);
  const [needView, setNeedView] = useState([]);
  const randomId = useState(Math.random())[0];
  const decideLines = (label, index, line) => {
    return (
      <MulLineTooltipIfShow
        id={`SIPPage_CheckList-ForGESection-GETable-TableContainer-Table-TableBody-TableRow${index}`}
        outerRef={containerBoxRef}
        line={line}
        label={label}
        className={styleModule.MulLineTooltipIfShow}
        sx={{
          '& .MuiTooltip-arrow': {
            left: '0 !important',
          },
        }}
      />
    );
  };
  const containerBoxRef = useRef < HTMLDivElement > null;
  // force;
  // const [ready, setReady] = useState(true);

  return (
    <Box
      id='dataTableRoot'
      sx={{
        border: '1px solid #DFE4E8',
        borderRadius: '8px',
        overflow: 'hidden',
        minWidth: '1092px',
        maxWidth: '100%',
        padding: '0px',
      }}
    >
      <Box
        id='dataTableScrollContainer'
        sx={{
          overflowX: 'auto',
          width: '100%',
          padding: '0px',
        }}
      >
          <TableContainer
            id='dataTableContainer'
            sx={{
              tableLayout: 'fixed',
              width: tableWidth,
            }}
          >
            <Table
              stickyHeader
              aria-label='simple table'
              size='small'
              data-selenium-id='Report_AcquireOverview-Overview-BasicTable-Table'
              sx={{
                tableLayout: 'fixed',
                // width: '1266px',
                minWidth: '100%',
                width: 'auto',
              }}
            >
            <TableHead id='dataTableHead'>
              <TableRow id='dataTableRow'>
                {titleList.map(item => {
                  return (
                    <StyledTableCell
                      sx={{
                        height: '42px',
                        width: item[1],
                        //styleName: MINI BOLD UPPERCASE;
                        'font-family': 'Open Sans',
                        'font-size': '12px',
                        'font-weight': '600',
                        'line-height': '18px',
                        'letter-spacing': '0.08em',
                        'text-align': 'left',
                        color: '#596A7C',
                        'box-shadow': '0px -1px 0px 0px #BCC5CF inset',
                        padding: '12px 24px 12px 24px',
                        whiteSpace: 'nowrap',
                        overflow: 'visible',
                        boxSizing: 'border-box',
                        borderBottom: 'none',
                      }}
                    >
                      {item[0]}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((rowItem, rowIndex) => {
                // console.log('rowItem', rowItem);
                return (
                  <TableRow
                    randomid={`tableRow-${rowIndex}-${randomId}`}
                    sx={{
                      '&:hover': {
                        'background-color': flag === 'Flags-Severe Issue'
                          ? '#FFEFEF'
                          : flag === 'Flags-Mild Issue'
                          ? '#FFF0DD'
                          : '#E8EDFB',
                      },
                    }}
                  >
                    {/* ID SHOULD NOT CHANGE !!! */}
                    {rowItem.map((item, index) => {
                      return (
                        <StyledTableCell
                          id={`tableCell-${rowIndex}-${index}`}
                          sx={{
                            width: titleList[index][1],
                            maxWidth: titleList[index][2],
                            height: needView[rowIndex] ? '83px' : '58px',
                            padding: '0px 24px 0px 24px',
                            color:
                              titleList[index][4] === 'url' ||
                              titleList[index][4] === 'nativeURL'
                                ? '#154AB6'
                                : '#262E35',
                            'font-weight':
                              titleList[index][4] === 'url' ||
                              titleList[index][4] === 'nativeURL'
                                ? '600'
                                : '400',
                            '&:hover': {
                              'font-weight':
                                titleList[index][4] === 'url' ||
                                titleList[index][4] === 'nativeURL'
                                  ? '700 !important'
                                  : {},
                            },
                          }}
                        >
                          {titleList[index][0] === 'FULL NAME' ? (
                            flag === 'Flags-Severe Issue' ? (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: '100%',
                                }}
                              >
                                <Box
                                  sx={{
                                    height: '20px',
                                    width: '102px',
                                    p: '2px 12px',
                                    background: '#EE5350',
                                    textAlign: 'center',
                                    borderRadius: '0 0 4px 4px',
                                    color: '#fff',
                                    fontSize: '12px',
                                    fontFamily: 'Open Sans',
                                    fontWeight: 600,
                                    lineHeight: '16px',
                                    mb: '2px',
                                  }}
                                >
                                  SEVERE&nbsp;ISSUE
                                </Box>
                                {decideLines(item, index, 2)}
                              </Box>
                            ) : flag === 'Flags-Mild Issue' ? (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: '100%',
                                }}
                              >
                                <Box
                                  sx={{
                                    height: '20px',
                                    width: '102px',
                                    p: '2px 17.5px 2px 18.5px',
                                    background: '#FFB152',
                                    textAlign: 'center',
                                    borderRadius: '0 0 4px 4px',
                                    color: '#fff',
                                    fontSize: '12px',
                                    fontFamily: 'Open Sans',
                                    fontWeight: 600,
                                    lineHeight: '16px',
                                    mb: '2px',
                                  }}
                                >
                                  MILD&nbsp;ISSUE
                                </Box>
                                {decideLines(item, index, 2)}
                              </Box>
                            ) : (
                              <div
                                  style={{
                                    lineHeight: '20px',
                                  }}
                                >
                                  {decideLines(item, index, 2)}
                                </div>
                            )
                          ) : titleList[index][0] === 'SIP CODE' ||
                            titleList[index][0] === 'SIP TITLE' ? (
                            <div
                              style={{
                                lineHeight: '20px',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                // navigate(
                                //   `/simt/auth/sipRelated/sip/detail/${
                                //     titleList[index][0] === 'SIP CODE'
                                //       ? item
                                //       : rowItem[index - 1]
                                //   }`
                                // )
                                {
                                  const url = `/simt/auth/sipRelated/sip/detail/${
                                    titleList[index][0] === 'SIP CODE'
                                      ? item
                                      : rowItem[index - 1]
                                  }`;
                                  const newWindow = window.open(url, '_blank');
                                  if (newWindow) {
                                    newWindow.onload = () => {
                                      newWindow.location = url;
                                    };
                                  }
                                }
                              }
                            >
                              {decideLines(item, index, 2)}
                            </div>
                          ) : (
                            <div
                              id={`tableCell-${rowIndex}-${index}-Info`}
                              style={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                '-webkit-box-orient': 'vertical',
                                '-webkit-line-clamp': '2',
                                'line-clamp': '2',
                              }}
                            >
                              {decideLines(item, index, 2)}
                            </div>
                          )}

                          {/* {decideLines(item, index, 2)} */}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {tableData.length > 0 ? (
                <></>
              ) : (
                <TableRow
                  style={{
                    display: 'flex',
                    height: '76px',
                  }}
                  id='NoDataMessageTableRow'
                  data-selenium-id='Componentsphase2-NoDataMessage-TableRow'
                >
                  <TableCell
                    style={{
                      height: '76px',
                      // left: '50%',
                      transform: 'translateX(500px)',
                      borderBottom: 'none',
                      backgroundColor: 'transparent',
                      alignItems: 'center',
                    }}
                    id='NoDataMessageTableCell'
                    data-selenium-id='Componentsphase2-NoDataMessage-TableCell'
                  >
                    <Typography
                      style={{
                        fontFamily: 'Open Sans',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '16px',
                        lineHeight: '40px',
                        letterSpacing: '0.01em',
                        color: '#596A7C',
                      }}
                      id='NoDataMessageTypography'
                      data-selenium-id='Componentsphase2-NoDataMessage-Typography'
                    >
                      No Data
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            </Table>
          </TableContainer>
      </Box>
      {tableData.length > 10 ? (
        <TableFooter2
          Page={page}
          perPageNumber={10}
          count={tableData.length}
          handlePageChange={(e, v) => {
            setPage(v);
          }}
          sx={{
            border: 'none',
          }}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

//表格相关
export const LOAD_CR = 'LOAD_CR'; // 读取CR列表
export const CRC_ORDER_BY = 'CRC_ORDER_BY'; // 设置CR列表排序
export const CHANGE_CRC_PAGE = 'CHANGE_CRC_PAGE'; // 设置CR列表页码
export const CHANGE_CRC_PAGE_SIZE = 'CHANGE_CRC_PAGE_SIZE'; // CR列表每页大小
export const SET_CR_SEARCH_CONTENT = 'SET_CR_SEARCH_CONTENT'; //设置CR搜索的内容

// CRContact 选择与取消选择
export const SELECT_BATCH_CRC = 'SELECT_BATCH_CRC';
export const UNSELECT_BATCH_CRC = 'UNSELECT_BATCH_CRC';
export const UNSELECT_BATCH_CRC_INFO = 'UNSELECT_BATCH_CRC_INFO';
export const UNSELECT_BATCH_CRC_INFO_ALL = 'UNSELECT_BATCH_CRC_INFO_ALL';
//CRContact Detail page获取信息
export const GET_CRDETAIL = 'GET_CRDETAIL';
//CRContact Detail page页面的三个部分修改的接口
export const EDIT_EOCONTACT = 'EDIT_EOCONTACT';
export const EDIT_AMEDETAIL = 'EDIT_AMEDETAIL';
export const EDIT_MEDETAIL = 'EDIT_MEDETAIL';

//batchUpdate
export const JUDGEEMAIL = 'JUDGEEMAIL';
export const SEARCHAMEORME = 'SEARCHAMEORME';
export const BATCHUPDATECLEAR = 'BATCHUPDATECLEAR';
export const BATCH_UPDATE = 'BATCH_UPDATE';

export const GENERATE_CRC_INFO = 'GENERATE_CRC_INFO';
export const EDIT_CLEAR = 'EDIT_CLEAR';

export const EDIT_ERROR = 'EDIT_ERROR';
export const EDIT_TABLE_ERROR = 'EDIT_TABLE_ERROR';

/**
 * 更改排序顺序和字段
 * @param order
 * @param by
 * @returns {(function(*): void)|*}
 */
export function setCRCOrderBy(order, by) {
  return dispatch => {
    dispatch({ type: CRC_ORDER_BY, data: { order: order, orderBy: by } });
  };
}

/**
 * 批量选择CRC列表中的CRC
 * @param shouldSelectCRCList  需要被选择的CRC列表
 * @returns {(function(*): void)|*}
 */
export function selectBatchCRC(shouldSelectCRCList) {
  return dispatch => {
    dispatch({
      type: SELECT_BATCH_CRC,
      data: shouldSelectCRCList,
    });
  };
}

/**
 * 批量取消选择CRC列表中的CRC
 * @param shouldUnselectCRCList  需要被取消选中的CRC列表
 * @returns {(function(*): void)|*}
 */
export function unselectBatchCRC(shouldUnselectCRCList) {
  return dispatch => {
    dispatch({
      type: UNSELECT_BATCH_CRC,
      data: shouldUnselectCRCList,
    });
  };
}

export function unselectBatchCRCInfo(shouldUnselectCRCList) {
  return dispatch => {
    dispatch({
      type: UNSELECT_BATCH_CRC_INFO,
      data: shouldUnselectCRCList,
    });
  };
}

export function unselectBatchCRCAllInfo() {
  return dispatch => {
    dispatch({
      type: UNSELECT_BATCH_CRC_INFO_ALL,
    });
  };
}

export function generateCRCInfo(CRCInfo) {
  return dispatch => {
    dispatch({
      type: GENERATE_CRC_INFO,
      data: CRCInfo,
    });
  };
}

/**
 * 更改表格页码
 * @param {int} page 页码
 * @returns dispatch
 */
export function handleCRCPageChangeAction(page) {
  return dispatch => {
    dispatch({
      type: CHANGE_CRC_PAGE,
      data: page,
    });
  };
}

/**
 * 更改表格一页数量
 * @param {int} pageSize 一页的大小
 * @returns dispatch
 */
export function handleChangeCRCPageSize(pageSize) {
  return dispatch => {
    dispatch({
      type: CHANGE_CRC_PAGE_SIZE,
      data: pageSize,
    });
  };
}

export function EditClear() {
  return async dispatch => {
    dispatch({ type: EDIT_CLEAR });
  };
}

export function batchUpdateClear() {
  return async dispatch => {
    dispatch({ type: BATCHUPDATECLEAR, data: '' });
  };
}

export function setSearchContent(content) {
  return dispatch => {
    dispatch({
      type: SET_CR_SEARCH_CONTENT,
      data: content,
    });
  };
}

export function setErr(data) {
  return dispatch => {
    dispatch({
      type: EDIT_ERROR,
      data: data,
    });
  };
}

export function setTableErr(data) {
  return dispatch => {
    dispatch({
      type: EDIT_TABLE_ERROR,
      data: data,
    });
  };
}

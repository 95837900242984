import {
  Stack,
  CircularProgress,
  styled,
  Box,
  Button,
  useMediaQuery,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import EmailEditor from './EmailEditor';
import { SIModal } from '@/modules/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { TurnedIn } from '@mui/icons-material';
import CustomAttachmentsView from '@/pages/Email/SendEmail/CommonComponents/CustomAttachmentsView';
import { extractEmailFromList } from '@/utils/commonUtils';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { FileWarningAlert } from '@/pages/AnnualTarget/Overview/ATAlert';
import RejectEmailTemplateNoLeadGE from '@/assets/SIPPage/RejectEmailTemplateNoLeadGE.svg';
import CommonSnackBar, {
  SEVERITIES,
} from '@/modules/ConfirmSnackBar/CommonSnackBar';
import { UploadSIPAttachment } from "@/actions/SIMT-Email/StageDecision";
import {
  CustomTextField,
  CustomChipTextField,
} from '@/pages/Email/SendEmail/CommonComponents/CustomTextField';

const TopText = styled(props => <Box {...props} />)({
  '&.MuiBox-root': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#596A7C',
  },
});
const editWidthStyle = {
  flexGrow: 1,
};
const editBoxStyle = {
  ...editWidthStyle,
  overflow: 'hidden',
  wordWrap: 'word-wrap',
  wordBreak: 'break-all',
};

const titleBoxWidthStyle = {
  width: '60px'
};

export default function EmailContainer(props) {
  const {
    emailLoading,
    emailTemplate,
    setEmailTemplate,
    subject = null,
    setSubject = null,
    isSubjectEditable = null,
    from = null,
    to = null,
    setTo = null,
    isToEditable = null,
    cc = null,
    setCc = null,
    isCcEditable = null,
    bcc = null,
    isShowFrom = true,
    isShowBcc = true,
    isShowCc = true,
    attachment,
    setAttachments,
  } = props;

  const dispatch = useDispatch();

  const theme = useTheme();
  const firstSmaller = useMediaQuery(theme.breakpoints.down(1400));
  const secondSmaller = useMediaQuery(theme.breakpoints.down(1250));
  const [fromError, setFromError] = useState(false);
  const [fromErrorType, setFromErrorType] = useState(0);
  const [toError, setToError] = useState(false);
  const [toErrorType, setToErrorType] = useState(0);
  const [ccError, setCcError] = useState(false);
  const [ccErrorType, setCcErrorType] = useState(0);
  const patternEmail =
    /^\w+((-\w+)|(\.\w+)|(_\w+))*@[A-Za-z0-9]+(([.\-])[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

  const [files, setFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [fileflag, setFileFlag] = useState(true);
  const isValidUser = () => {
    let pattern = /.*@wiley.com$/;
    return pattern.test(from);
  };

  useEffect(() => {
    if (from && from !== 'N/A' && open && !isValidUser()) {
      dispatch(
        setSnackbarMessageAndOpen(
          'email.wileyEmailError',
          {},
          SEVERITIES.warning
        )
      );
    }
  }, [from]);

  const isDuplicate = (list, item) => {
    const res = extractEmailFromList(list);
    return res.includes(item);
  };

  function handleRepeatError() {
    repeatCheck();
    handleCheckError();
  }
  function handleCheckError() {
    if (to && to.length == 0) {
      setToErrorType(2);
      setToError(true);
    } else if (toErrorType == 1 || toErrorType == 3 || toErrorType == 4) {
    } else {
      setToErrorType(0);
    }
  }

  function repeatCheck() {
    var toWithoutBracket = removeBracket(to);
    var copyWithoutBracket = removeBracket(cc);
    var toRepeatWithFrom =
      toWithoutBracket.filter(item => item == from).length > 0 &&
      from.length !== 0;
    var copyRepeatWithFrom =
      copyWithoutBracket.filter(item => item == from).length > 0 &&
      from.length !== 0;
    var toNew = new Set(toWithoutBracket);
    var copyNew = new Set(copyWithoutBracket);
    var copyRepeatWithTo = new Set([...toNew].filter(x => copyNew.has(x)));

    if (toRepeatWithFrom || copyRepeatWithFrom) {
      setFromErrorType(4);
      setFromError(true);
    } else if (fromErrorType == 2 || fromErrorType == 1 || fromErrorType == 3) {
    } else {
      setFromError(false);
    }

    if (copyRepeatWithTo.size > 0 || copyRepeatWithFrom) {
      setCcErrorType(4);
      setCcError(true);
    } else if (ccErrorType == 1 || ccErrorType == 3) {
    } else {
      setCcError(false);
    }
    if (copyRepeatWithTo.size > 0 || toRepeatWithFrom) {
      setToErrorType(4);
      setToError(true);
    } else if (toErrorType == 1 || toErrorType == 3 || toErrorType == 2) {
    } else {
      setToError(false);
    }
  }

  function removeBracket(arr = []) {
    const arrWithoutBracket = arr.map(item => {
      let indexFirst = item.indexOf('(');
      let indexSecond = item.indexOf(')');
      if (indexFirst !== -1 && indexSecond !== -1) {
        item = item.substring(indexFirst + 1, indexSecond);
      }
      return item;
    });
    return arrWithoutBracket;
  }

  const selector = state => {
    return {
      emailShowInfo: state.Email.emailShowInfo,
      emailSendCode: state.Email.sendEmailReturnCode,
      uploadAttachmentResult: state.Email.uploadAttachmentResult,
      uploadAttachmentMonitor: state.Email.uploadAttachmentMonitor,
      userEmail: state.User.email,
      userLastName: state.User.lastName,
      userFirstName: state.User.firstName,
      sipStage: state.SIP.sipStatus?.sipStage,
    };
  };
  const {
    emailShowInfo,
    uploadAttachmentResult,
    uploadAttachmentMonitor,
    emailSendCode,
    userEmail,
    userLastName,
    userFirstName,
    sipStage,
  } = useSelector(selector);

  const editorHeight = () => {
    if (secondSmaller) {
      return '120px';
    }
    if (firstSmaller) {
      return '170px';
    }
    return '250px';
  };

  const Max_Size = 10 * 1024 * 1024;

  const pattern =
    /exe|ade|adp|app|application|appref-ms|asp|aspx|asx|bas|bat|bgi|cab|cer|chm|cmd|cnt|com|cpl|crt|csh|der|diagcab|fxp|gadget|grp|hlp|hpj|hta|htc|inf|ins|iso|isp|its|jar|jnlp|js|jse|ksh|lnk|mad|maf|mag|mam|maq|mar|mas|mat|mau|mav|maw|mcf|mda|mdb|mde|mdt|mdw|mdz|msc|msh|msh1|msh2|mshxml|msh1xml|msh2xml|msi|msp|mst|msu|ops|osd|pcd|pif|pl|plg|prf|prg|printerexport|ps1|ps1xml|ps2|ps2xml|psc1|psc2|psd1|psdm1|pst|py|pyc|pyo|pyw|pyz|pyzw|reg|scf|scr|sct|shb|shs|theme|tmp|url|vb|vbe|vbp|vbs|vhd|vhdx|vsmacros|vsw|webpnp|website|ws|wsc|wsf|wsh|xbap|xll|xnk/;

  /**
   *
   * @param {number} size
   * @returns
   */
  const checkSize = size => {
    return size < Max_Size;
  };

  /**
   *
   * @param {File} file
   * @returns
   */
  const getFileFormat = file => {
    let strArr = file.name.split('.');
    return strArr[strArr.length - 1];
  };

  /**
   *
   * @param {string} fileFormat
   * @returns
   */
  const checkFileFormat = fileFormat => {
    return !pattern.test(fileFormat);
  };

  /**
   *
   * @param {File} file
   * @returns
   */
  const getAttachmentSizeInfo = file => {
    let sizeInfo = {};
    if (file.size < 1024) {
      sizeInfo.size = file.size;
      sizeInfo.unit = 'B';
    } else if (file.size < 1048576) {
      sizeInfo.size = file.size / 1024;
      sizeInfo.unit = 'KB';
    } else {
      sizeInfo.size = file.size / (1024 * 1024);
      sizeInfo.unit = 'MB';
    }
    return sizeInfo;
  };

  const upload = event => {
    let newFiles = [];
    let newUploadingFiles = [];
    let iniFlag = true;
    const allowedTypes = [
      'doc', 'docx', 'pdf', 'txt', 'ppt', 'pptx', 'xls', 'xlsx',
      'csv', 'png', 'jpg', 'jpeg', 'gif', 'zip', 'rar'
    ];
    for (const element of event.target.files) {
      if (!allowedTypes.includes(getFileFormat(element)) || element.size > 10 * 1024 * 1024) {
        FileWarningAlert(dispatch, 1);
        iniFlag = false;
        continue;
      }
      // if (!checkSize(element.size)) {
      //   dispatch(
      //     setSnackbarMessageAndOpen(
      //       'email.attachmentSizeError',
      //       {},
      //       SEVERITIES.error
      //     )
      //   );

      //   continue;
      // }
      // if (!checkFileFormat(getFileFormat(element))) {
      //   dispatch(
      //     setSnackbarMessageAndOpen(
      //       'email.attachmentFormatError',
      //       {},
      //       SEVERITIES.error
      //     )
      //   );

      //   continue;
      // }
      let newFile = {
        name: element.name,
        size: getAttachmentSizeInfo(element).size.toFixed(2),
        unit: getAttachmentSizeInfo(element).unit,
        loading: true,
        state: 'uploading',
      };
      let formData = new FormData();
      formData.append('file', element);
      newFiles.push(newFile);
      newUploadingFiles.push(formData);
    }
    setFileFlag(iniFlag);
    setFiles(files.concat(newFiles));
    setAttachments(files.concat(newFiles));
    setUploadingFiles(uploadingFiles.concat(newUploadingFiles));
  };

  useEffect(() => {
    if (uploadingFiles.length > 0) {

      dispatch(UploadSIPAttachment(uploadingFiles[0]));
    } else {

    }
  }, [uploadingFiles]);

  useEffect(() => {
    if (uploadAttachmentResult === '') {
      return;
    }
    let newUploadingFiles = uploadingFiles.concat();
    let flag = false;
    let shouldRemove = null;
    for (const element of files) {
      if (element.loading) {
        // 正在上传的文件
        flag = true;
        element.loading = false; // 无论成功与否都结束loading状态
        if (uploadAttachmentResult.state === 'success') {
          // 上传成功

          element.id = uploadAttachmentResult.fileID;
          element.state = uploadAttachmentResult.state;
          newUploadingFiles.shift();
        } else if (uploadAttachmentResult.state === 'emptyError') {
          // 上传失败

          element.state = uploadAttachmentResult.state;
          newUploadingFiles = newUploadingFiles.slice(
            1,
            newUploadingFiles.length
          );
          shouldRemove = element;
          dispatch(
            setSnackbarMessageAndOpen(
              'email.attachmentUploadEmptyError',
              {},
              SEVERITIES.error
            )
          );
        } else {

          element.state = uploadAttachmentResult.state;
          newUploadingFiles = newUploadingFiles.slice(
            1,
            newUploadingFiles.length
          );
          shouldRemove = element;
          dispatch(
            setSnackbarMessageAndOpen(
              'email.attachmentUploadError',
              {},
              SEVERITIES.error
            )
          );
        }
        break;
      }
    }
    if (shouldRemove != null) {
      const newFiles = files.filter(item => {
        return item !== shouldRemove;
      });
      setFiles(newFiles);
      setAttachments(newFiles);
    }
    if (!flag) {

      return;
    }
    setUploadingFiles(newUploadingFiles);
  }, [uploadAttachmentMonitor]);

  const onReady = () => {
    document
      .querySelector("button[data-cke-tooltip-text='Attachments']")
      .addEventListener('click', () => {
        document.getElementById('test').click();
      });
  };

  return (
    <Box>
      {!emailLoading && ((to == null || to == 'N/A' || Array.isArray(to) && to.length === 0) && sipStage === 'Ideation') ? (
        <Box sx={{ overflowY: 'auto' }}>
          <Box
            sx={{
              color: '#113D95',
              fontSize: '16px',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '24px',
            }}
          >
            Decision
          </Box>
          <Stack
            direction='rowls'
            spacing={0}
            alignItems='center'
            sx={{
              mt: '16px',
              mb: '8px',
              backgroundColor: '#4C81EB',
              minHeight: '52px',
              width: '1092px',
              padding: '16px  32px 16px 24px',
              borderRadius: '5px',
              gap: '10px',

            }}>
            <RejectEmailTemplateNoLeadGE></RejectEmailTemplateNoLeadGE>
            <Box
              sx={{
                color: '#FFFFFF',
                fontWeight: 600,
                fontFamily: 'Open Sans',
                fontSize: '14px',
                lineHeight: '17px',
                letterSpacing: '0em',
                textAlign: 'left'
              }}
            >
              <Typography>
                As there is no Guest Editor in this Special Issue Proposal, no email notification will be sent out.
              </Typography>
            </Box>
          </Stack>
        </Box>
      ) :
        (<>
          {emailLoading || emailTemplate === 'N/A' ? (
            <Box
              sx={{
                height: '100%',
                textAlign: 'center',
                marginTop: '5%',
                marginBottom: '5%'
              }}
            >
              <CircularProgress size={80} />
            </Box>
          ) : (
            <Box sx={{ overflowY: 'auto' }}>
              <Box
                sx={{
                  color: '#113D95',
                  fontSize: '16px',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                Email Preview
              </Box>
              <Stack direction='column' spacing={0} sx={{ mt: '16px', mb: '8px' }}>
                {subject ? (
                  <TopText>
                    <Stack direction={'row'} spacing={0.5} alignItems={'center'} gap={'24px'}>
                      <Box sx={titleBoxWidthStyle}>
                        <FormattedMessage id='sipDetail.revisionSubject' />
                      </Box>
                      {!isSubjectEditable ? (
                        <Box
                          sx={{
                            overflow: 'hidden',
                            wordWrap: 'word-wrap',
                            wordBreak: 'break-all',
                          }}
                        >{subject}</Box>
                      ) : (
                        <CustomTextField
                          value={subject}
                          sx={editWidthStyle}
                          onChange={(e) => setSubject(e.target.value)}
                        />
                      )
                      }
                    </Stack>
                  </TopText>
                ) : null}

                {isShowFrom ? (
                  <TopText>
                    <Stack direction={'row'} spacing={0.5} alignItems={'center'} gap={'24px'}>
                      <Box sx={titleBoxWidthStyle}>
                        <FormattedMessage id='sipDetail.revisionFrom' />
                      </Box>
                      <Box>{from}</Box>
                    </Stack>
                  </TopText>
                ) : null}

                {to ? (
                  <TopText>
                    <Stack direction='row' spacing={0.5} gap={'24px'} alignItems='center'>
                      <Box sx={titleBoxWidthStyle}>
                        <FormattedMessage id='sipDetail.revisionTo' />
                      </Box>
                      {!isToEditable ? (
                        <Box
                          sx={{
                            overflow: 'hidden',
                            wordWrap: 'word-wrap',
                            wordBreak: 'break-all',
                          }}
                        >
                          {Array.isArray(to) ? to.join("; ") : to}
                        </Box>
                      ) : (
                        <CustomChipTextField
                          sx={editBoxStyle}
                          contentList={to}
                          setContentList={setTo}
                          pattern={patternEmail}
                          setError={setToError}
                          setErrorType={setToErrorType}
                          // fullWidth
                          isDuplicate={isDuplicate}
                          handleRepeatError={handleRepeatError}
                          removeBracket={removeBracket}
                        />
                      )
                      }
                    </Stack>
                  </TopText>
                ) : null}

                {isShowCc ? (
                  <TopText>
                    <Stack direction='row' spacing={0.5} gap={'24px'} alignItems='center'>
                      <Box sx={titleBoxWidthStyle}>
                        <FormattedMessage id='sipDetail.revisionCc' />
                      </Box>
                      {!isCcEditable ? (
                        <Box
                          sx={{
                            overflow: 'hidden',
                            wordWrap: 'word-wrap',
                            wordBreak: 'break-all',
                          }}
                        >{Array.isArray(cc) ? cc.join("; ") : cc}</Box>
                      ) : (
                        <CustomChipTextField
                          sx={editBoxStyle}
                          contentList={cc}
                          setContentList={setCc}
                          pattern={patternEmail}
                          setError={setCcError}
                          setErrorType={setCcErrorType}
                          // fullWidth
                          isDuplicate={isDuplicate}
                          handleRepeatError={handleRepeatError}
                          removeBracket={removeBracket}
                        />
                      )
                      }
                    </Stack>
                  </TopText>
                ) : null}

                {isShowBcc ? (
                  <TopText>
                    <Stack direction={'row'} spacing={0.5} alignItems={'center'} gap={'24px'}>
                      <Box sx={titleBoxWidthStyle}>
                        <FormattedMessage id='sipDetail.revisionBcc' />
                      </Box>
                      <Box
                        sx={{
                          overflow: 'hidden',
                          wordWrap: 'word-wrap',
                          wordBreak: 'break-all',
                        }}
                      >
                        {Array.isArray(bcc) ? bcc.join("; ") : bcc}
                      </Box>
                    </Stack>
                  </TopText>
                ) : null}
              </Stack>

              <Box
                sx={{
                  '& .ck-reset.ck-editor': {
                    width: '100%',
                  },
                  '& .ck-content.ck-editor__editable': {
                    minWidth: '100%',
                    maxWidth: '100%',
                    height: editorHeight(),
                    boxSizing: 'border-box',
                  },
                  marginBottom: '8px!important',
                }}
              >
                <input
                  id='test'
                  type='file'
                  accept='.pdf,.doc,.docx,image/png,image/jpeg,image/gif,.xlsx,.xls,.csv,.txt,.ppt,.pptx,.zip,.rar'
                  style={{ display: 'none', marginBottom: '8px' }}
                  multiple={true}
                  onChange={event => {
                    upload(event);
                    event.target.value = ''; // 清除这个值，以允许重复选择同一个文件
                  }}
                />
                <CustomAttachmentsView
                  files={files}
                  setFiles={setFiles}
                  isSIP={true}
                  FileControl={false}
                  flag={fileflag}
                  setFlag={setFileFlag}
                ></CustomAttachmentsView>
                <EmailEditor
                  content={emailTemplate}
                  setContent={e => {
                    setEmailTemplate(e);
                  }}
                  removePlugins={['Mention']}
                  extraToolbarItems={['attachmentsUpload']}
                  onReady={onReady}
                />
              </Box>


            </Box>
          )}
        </>)

      }
    </Box>
  );
}

export function formatGENoEmail(geValue) {
  let GES = '';
  for (let i = 0; i < geValue.length; i++) {
    if (i !== geValue.length - 1) {
      GES += geValue[i].lastName + ',' + ' ' + geValue[i].firstName + '; ';
    } else {
      GES += geValue[i].lastName + ',' + ' ' + geValue[i].firstName;
    }
  }
  return GES;
}

export function formatGEHasEmail(geValue) {
  let GES = [];
  for (let i = 0; i < geValue.length; i++) {
    let str = '';
    str =
      geValue[i].lastName +
      ', ' +
      geValue[i].firstName +
      '(' +
      geValue[i].primaryEmail +
      ')';
    GES.push(str);
  }
  return GES;
}

export function formatGEOnlyHasEmail(geValue) {
  let GES = [];
  for (let i = 0; i < geValue.length; i++) {
    GES.push(geValue[i].primaryEmail + '');
  }
  return GES;
}

export function formatGENoGECode(geValue) {
  let LeadGESA = [];
  if (geValue !== undefined) {
    for (let i = 0; i < geValue.length; i++) {
      LeadGESA.push({
        firstName: geValue[i].firstName,
        lastName: geValue[i].lastName,
        primaryEmail: geValue[i].primaryEmail,
      });
    }
  }
  return LeadGESA;
}

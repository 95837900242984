import TimelineConnector from './TimelineConnector';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import SIPStep from '../sip-step/SIPStep';

function TimelineLeft(props) {
  const { id, step, origin, requiresConnector = true } = props;

  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      minWidth='147px'
      ml='2px'
      data-selenium-id='SIPPage_Components-ProcessLog-TimelineLeft-Stack'
    >
      {/* Chip */}
      <SIPStep id={id} data-selenium-id={id} step={step} origin={origin} />
      {/* 这里如果不需要虚线连接，则需要一个height100%的东西占满，否则会居中 */}
      {requiresConnector ? (
        <TimelineConnector data-selenium-id='SIPPage_Components-ProcessLog-TimelineLeft-Connector' />
      ) : (
        <div
          style={{
            height: '100%',
          }}
        >
          {/* empty，仅用于占满这段空间 */}
        </div>
      )}
    </Stack>
  );
}

TimelineLeft.propTypes = {
  id: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  origin: PropTypes.string,
  requiresConnector: PropTypes.bool,
};

export default TimelineLeft;

import { axios_instance } from "@/utils/axios_instance";
import {
    CHECK_LINK_VALID,
    clear,
    FIRST_FORGET_PASS,
    getLoginFailCountFail,
    getLoginFailCountSuccess,
    loginFailed,
    loginSuccess,
    LOGOUT,
    RESETPASS,
    SEND_FORGETPASS_EMAIL
} from "@/actions/SIMT-User/Login/LoginAction";

import { getUserInfo } from "@/actions/SIMT-User/User";

export function getLoginFailCount(formData) {
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/api/v1/user-service/field-metadata`, {
                params: {
                    email: formData.email,
                    queryType: "user-fail-number",
                }
            }
            );
            if (res.data.code === 200) {
                dispatch(getLoginFailCountSuccess(res.data));
            } else {
                dispatch(getLoginFailCountFail());
            }
        } catch (err) {
            dispatch(getLoginFailCountFail());
        }
    };
}
// login user
export function login(formData) {
    return async dispatch => {
        try {
            const data = {
                username: formData.email,
                password: formData.password,
                'remember-me': formData.remember_flag,
                ...(formData.hCaptchaToken ? { hCaptchaToken: formData.hCaptchaToken } : {}),
                isChina: localStorage.getItem('isChina') === 'true',
            };
            const res = await axios_instance.post('/login', data);
            if (res.data.code === 200) {
              localStorage.setItem('userInfo',JSON.stringify({data:{userId:res.data?.id}}));
                // dispatch(clear()); // 登入成功后清除所有缓存，防止Bug
                // dispatch(getUserInfo()); // 在登录成功后一次性获得用户信息
                await dispatch(loginSuccess(res));
                await getUserInfo()(dispatch);
                localStorage.removeItem('lastFilters');
                localStorage.removeItem('lastSearch');
            } else {
                if (res.data.message !== undefined && res.data.message !== null) {
                    dispatch(loginFailed(res.data.message));
                } else {
                    dispatch(loginFailed('Bad credentials'));
                }
            }
        } catch (err) {
            dispatch(loginFailed(err.response.data.msg));
        }
    };
} //sent forget password email
export function forgetPassEmail(emailAddress, hCaptchaToken) {
    return async dispatch => {
        try {
            dispatch(clear());
            const result = await axios_instance.get(
                `/login/forgetPassword/${emailAddress}?hCaptchaToken=${hCaptchaToken}`
            );

            if (result.data.code === 200) {
                dispatch({ type: SEND_FORGETPASS_EMAIL, data: true });
            } else if (
                result.data.code === 500 &&
                result.data.message === 'login first' &&
                result.data.data === 1
            ) {
                dispatch({ type: FIRST_FORGET_PASS, data: result.data.message });
            } else {
                dispatch({ type: SEND_FORGETPASS_EMAIL, data: result.data.message });
            }
        } catch (err) {
            dispatch({ type: SEND_FORGETPASS_EMAIL, data: err.response.data.msg });
        }
    };
} // check reset link is valid
export function checkLinkValid(digitalSignature, email) {
    return async dispatch => {
        try {
            const res = await axios_instance.post(`/api/v1/user-service/users/signature/validate`, {},{
                params: {
                    signature: digitalSignature,
                    email: email
                }
            });

            if (res.data.code === 200) {
                dispatch({ type: CHECK_LINK_VALID, data: true });
            } else {
                dispatch({ type: CHECK_LINK_VALID, data: false });
            }
        } catch (err) {
            dispatch({ type: CHECK_LINK_VALID, data: err.response.data.msg });
        }
    };
}
// forget password reset password
export function resetPassForget(digitalSignature, email, password) {
    return async dispatch => {
        try {
            const result = await axios_instance.post('/login/resetPassword', {
                digitalSignature: digitalSignature,
                email: email,
                password: password,
            });

            if (result.data.code === 200) {
                dispatch({ type: RESETPASS, data: true });
            } else if (
                result.data.code === 500 &&
                result.data.message === 'the password is invalid'
            ) {
                dispatch({ type: RESETPASS, data: 'Email Exit' });
            } else {
                dispatch({ type: RESETPASS, data: false });
            }
        } catch (err) {
            dispatch({ type: RESETPASS, data: err.response.data.msg });
        }
    };
}
//logout
export function logout() {
    return async dispatch => {
        try {
            const result = await axios_instance.post('/logout', {},
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                });
            if (result.status === 200) {
                dispatch({ type: LOGOUT });
            }
        } catch (err) {
        }
    };
}
import {
  Box,
  Divider,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  handleRequestHistoryPage,
  handleRequestHistoryPageSize,
  setRequestHistoryOrderBy,
} from '../../../../actions/SIMT-SI/CatJournal/CATJournalAction';
import { TableFooter2 } from '../../../../components/DataTable';
import TableSkeleton from '../../../../components/TableSkeleton';
import { formatDateString, getUTCToDate } from '../../../../utils/commonUtils';
import { HeadItems } from './Data';
import TableHeader from './TableHeader';
import ViewMorePop from './ViewMorePop';
import ScrollBarY from "../../../../componentsphase2/ScrollBar/ScrollBarY";
import { contextHeightChanged } from "../../../../actions/CommonAction";
import * as React from "react";
import { MulLineTooltipIfShow } from "../../../../componentsphase2/CustomTooltip";
import {loadRequestHistoryList} from "@/actions/SIMT-SI/CatJournal/OptHistory";

const CommonCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    // textTransform: 'capitalize',
    color: '#262E35',
    borderBottom: '1px solid #DFE4E8',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '20px',
    padding: '12px 24px',
  },
}));

export default function RequestHistoryTable() {
  const dispatch = useDispatch();
  const selector = state => {
    return {
      requestHistoryTypeSelectedList:
        state.CATJournal.requestHistoryTypeSelectedList,
      requestHistoryStatusSelectedList:
        state.CATJournal.requestHistoryStatusSelectedList,
      requestHistoryOrderByType: state.CATJournal.requestHistoryOrderByType,
      requestHistoryPage: state.CATJournal.requestHistoryPage,
      requestHistoryPageSize: state.CATJournal.requestHistoryPageSize,
      requestHistoryTabelData: state.CATJournal.requestHistoryTabelData,
      siderOpen: state.UserManage.siderOpen,
    };
  };

  const {
    requestHistoryTypeSelectedList,
    requestHistoryStatusSelectedList,
    requestHistoryOrderByType,
    requestHistoryPage,
    requestHistoryPageSize,
    requestHistoryTabelData,
    siderOpen,
  } = useSelector(selector);

  const containerRef = useRef(null);
  const containerBoxRef = useRef(null);
  const scrollYRef = useRef(null);

  useEffect(() => {
    const containerElement = containerRef.current;
    const containerBoxElement = containerBoxRef.current;
    setTimeout(()=>{
      dispatch(
        contextHeightChanged({
          contextWidth: containerElement.offsetWidth,
          contextHeight: containerElement.offsetHeight,
          boxWidth: containerBoxElement.offsetWidth,
          boxHeight: containerBoxElement.offsetHeight,
        })
      );
    }, 100);
  },[requestHistoryTabelData]);

  const headItems = HeadItems();

  const [loading, setLoading] = useState(false);
  const [viewMorePopOpen, setViewMorePopOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const handleRequestSort = (_event, orderBy) => {
    let newOrder;
    if (requestHistoryOrderByType.orderBy === orderBy) {
      // 如果当前的排序字段没有变化，则切换正反序
      newOrder = requestHistoryOrderByType.order === 'desc' ? 'asc' : 'desc';
    } else {
      // 如果当前排序字段发生变化，则直接按照新的字段升序
      newOrder = 'asc';
    }
    dispatch(setRequestHistoryOrderBy(newOrder, orderBy));
  };

  useEffect(() => { }, [requestHistoryOrderByType]);

  const handlePerPageChange = e => {
    dispatch(handleRequestHistoryPageSize(Number(e.target.value)));
  };

  //改变页码
  const handlePageChange = (_event, newPage) => {
    dispatch(handleRequestHistoryPage(newPage));
  };

  useEffect(() => {
    if (requestHistoryPage === 0) {
      setTimeout(() => {
        dispatch(handleRequestHistoryPage(1));
      }, 1000);
    } else {
      setLoading(true);
      async function fetchData() {
        await dispatch(
          loadRequestHistoryList({
            pageNum: requestHistoryPage,
            pageSize: requestHistoryPageSize,
            sortBy: requestHistoryOrderByType.orderBy,
            sortOrder: requestHistoryOrderByType.order,
            requestType: requestHistoryTypeSelectedList,
            status: requestHistoryStatusSelectedList,
          })
        );
        setLoading(false);
      }
      fetchData();
    }
  }, [
    dispatch,
    requestHistoryPage,
    requestHistoryPageSize,
    requestHistoryOrderByType,
    requestHistoryTypeSelectedList,
    requestHistoryStatusSelectedList,
  ]);
  return (
    <Box
      id='TableBox'
      data-selenium-id='CATJournal_Overview-RequestHistoryTable-Box'
    >
      <div
        style={{
          overflowX: 'hidden',
          overflowY: 'hidden',
          position: 'relative',
          width: 'fit-content',
        }}
      >
      <TableContainer
        ref={containerBoxRef}
        id='request-history-table-container'
        data-selenium-id='CATJournal_Overview-RequestHistoryTable-TableContainer'
        sx={{
          border: '1px solid #DFE4E8',
          borderRadius: '8px 8px 0px 0px',
          height: 'calc(100vh - 335px)',
          width: siderOpen? 'calc(100vw - 129px - 158px)':'calc(100vw - 129px)',
          overflowY: 'scroll',
          '&::-webkit-scrollbar:vertical': {
            width: 0,
          },
          '&::-webkit-scrollbar-thumb:vertical': {
            backgroundColor: 'transparent',
          },
        }}
        onScroll={scrollYRef.current?.scrollAsContext}
      >
        <Table
          ref={containerRef}
          stickyHeader
          id='request-history-table'
          data-selenium-id='CATJournal_Overview-RequestHistoryTable-Table'
          aria-label='customized table'
          size='small'
          sx={{
            letterSpacing: '0.01em',
            color: '#262E35',
          }}
        >
          <TableHeader
            order={requestHistoryOrderByType.order}
            orderBy={requestHistoryOrderByType.orderBy}
            items={headItems}
            onRequestSort={handleRequestSort}
            data-selenium-id='CATJournal_Overview-RequestHistoryTable-OrderByTypeHeader'
          />
          {loading && (
            <TableBody
              sx={{ backgroundColor: 'fff' }}
              data-selenium-id='CATJournal_Overview-RequestHistoryTable-LoadingBody'
            >
              <TableSkeleton num={9} colSpan={36} />
            </TableBody>
          )}
          <TableBody data-selenium-id='CATJournal_Overview-RequestHistoryTable-Body'>
            {requestHistoryTabelData &&
              requestHistoryTabelData.list.map((row, index) => (
                <TableRow
                  id={`requestHistoryTableRow-${index}`}
                  key={row.id}
                  sx={{
                    '&:hover':{
                      background: '#E8EDFB',
                    }
                  }}
                >
                  {headItems.map((item,colIndex) => {
                    if (item.id === 'requestDate' || item.id === 'statusDate') {
                      return (
                        <CommonCell
                          id={`requestHistoryTable-${item.id}-${index}`}
                          data-selenium-id={`RequestHistoryTable-${item.id}-${index}`}
                          align='left'
                          key={item.id}
                        >
                          {row[item.id] !== null
                            ? formatDateString(getUTCToDate(row[item.id]))
                            : '-'}
                        </CommonCell>
                      );
                    } else if (
                      item.id === 'requestReason' ||
                      item.id === 'decisionReason'
                    ) {
                      return (
                        <CommonCell
                          id={`requestHistoryTable-${item.id}-${index}`}
                          data-selenium-id={`RequestHistoryTable-${item.id}-${index}`}
                          align='left'
                          key={item.id}
                        >
                          <Stack direction='row' spacing={0.5}>
                            <Box
                              sx={{
                                width: '399px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                              }}
                            >
                              {row[item.id]}
                            </Box>
                            {row[item.id] && row[item.id].length >= 55 && (
                              <Box
                                id={`requestHistoryTable-${item.id}-${index}-Viewmore`}
                                data-selenium-id={`RequestHistoryTable-${item.id}-${index}-Viewmore`}
                                sx={{
                                  fontFamily: 'Open Sans',
                                  fontStyle: 'normal',
                                  fontWeight: 600,
                                  fontSize: '14px',
                                  lineHeight: '20px',
                                  color: '#154AB6',
                                  cursor: 'pointer',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  '&:hover':{
                                    fontWeight: 700
                                  }
                                }}
                                onClick={() => {
                                  setViewMorePopOpen(true);
                                  if (item.id === 'decisionReason') {
                                    setTitle('Decision Reason');
                                  } else {
                                    setTitle('Request Reason');
                                  }
                                  setContent(row[item.id]);
                                }}
                              >
                                View more
                              </Box>
                            )}
                          </Stack>
                        </CommonCell>
                      );
                    } else {
                      return (
                        <CommonCell
                          id={`requestHistoryTable-${item.id}-${index}`}
                          data-selenium-id={`RequestHistoryTable-${item.id}-${index}`}
                          align='left'
                          key={item.id}
                        >
                          <MulLineTooltipIfShow
                            id={`requestHistoryTable-Table-TableRow${index}-${colIndex}`}
                            outerRef={containerBoxRef}
                            line={2}
                            label={row[item.id]}
                          />
                        </CommonCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
          </TableBody>
          {(!requestHistoryTabelData ||
            (requestHistoryTabelData &&
              requestHistoryTabelData.list.length === 0)) && (
              <TableBody data-selenium-id='CATJournal_Overview-RequestHistoryTable-NoDataBody'>
                <TableCell colSpan={5}>
                  <Box
                    sx={{
                      height: '300px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '20px',
                    }}
                  >
                    No Data
                  </Box>
                </TableCell>
              </TableBody>
            )}
        </Table>
      </TableContainer>
        <ScrollBarY ref={scrollYRef} contextHolderRef={containerBoxRef}/>
      </div>
      <Box
        id='request-history-table-footer-box'
        data-selenium-id='CATJournal_Overview-RequestHistoryTable-FooterBox'
      >
        <Divider />
        <TableFooter2
          id='request-history-table-footer-box'
          data-selenium-id='CATJournal_Overview-RequestHistoryTable-TableFooter2'
          // numSelected={selectedCRC.length}
          Page={requestHistoryPage}
          perPageNumber={requestHistoryPageSize}
          perPageList={[20, 50, 100]}
          count={requestHistoryTabelData ? requestHistoryTabelData.total : 0}
          handlePerPageChange={handlePerPageChange}
          handlePageChange={handlePageChange}
          width="calc(100% + 3px)"
        />
      </Box>
      <ViewMorePop
        open={viewMorePopOpen}
        handleClose={() => {
          setViewMorePopOpen(false);
        }}
        title={title}
        content={content}
        data-selenium-id='CATJournal_Overview-RequestHistoryTable-ViewMorePop'
      />
    </Box>
  );
}

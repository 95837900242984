import React, { useState } from 'react';
import './index.css';
import {
  styled,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  tableCellClasses,
  Divider,
  Tooltip,
} from '@mui/material';
import { MyTableHeader } from '@/components/DataTable';
import { TableFooter2 } from '../../../components/DataTable';
import LittleLabel from '../../../components/SmallComponents/LittleLabel';
import { formatDatetimeString } from '@/utils/commonUtils';
import TableSkeleton from '../../../components/TableSkeleton';

function LogTable(props) {
  const {
    Page,
    perPageNumber,
    handlePerPageChange,
    handlePageChange,
    showValue,
    sortKey,
    setSortKey,
    orderType,
    setOrderType,
    loadingLogData,
  } = props;

  const handleRequestSort = (event, property) => {
    const isAsc = sortKey === property && orderType === 'asc';
    setOrderType(isAsc ? 'desc' : 'asc');
    setSortKey(property);
  };

  const items = [
    {
      id: 'type',
      label: 'Type',
      minWidth: '120px',
      width: '120px',
      maxWidth: '120px',
      isOrder: false,
    },
    {
      id: 'subject',
      label: 'Subject',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: true,
    },
    {
      id: 'action',
      label: 'Action',
      minWidth: '80px',
      maxWidth: '80px',
      isOrder: false,
    },
    {
      id: 'field',
      label: 'Field',
      minWidth: '134px',
      maxWidth: '134px',
      isOrder: false,
    },
    {
      id: 'oldValue',
      label: 'Old Value',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'newValue',
      label: 'New Value',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'editUser',
      label: 'Updated By',
      minWidth: '100px',
      maxWidth: '100px',
      isOrder: true,
    },
    {
      id: 'editTime',
      label: 'Updated Time',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: true,
    },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: '14px',
      lineHeight: '20px',
      color: '#262E35',
      padding: '0 24px',
      height: '44px',
    },
  }));

  return (
    <Box
      id='TableBox'
      data-seleunim-id='SystemAdmin_SystemLog-LogTable-TableBox'
    >
      <TableContainer
        data-seleunim-id='SystemAdmin_SystemLog-LogTable-TableBox-TableContainer'
        sx={{
          minHeight: '260px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          border: '1px solid #DFE4E8',
          backgroundColor: '#fff',
        }}
      >
        <Table
          stickyHeader
          aria-label='simple table'
          data-seleunim-id='SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table'
        >
          <MyTableHeader
            data-seleunim-id='SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader'
            order={orderType}
            orderBy={sortKey}
            onRequestSort={handleRequestSort}
            items={items}
          />
          {loadingLogData ? (
            <TableBody data-seleunim-id='SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody1'>
              <TableSkeleton
                data-seleunim-id='SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody1-TableSkeleton'
                num={9}
                colSpan={36}
              />
            </TableBody>
          ) : (
            <TableBody data-seleunim-id='SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2'>
              {showValue.result.map((row, index) => (
                <TableRow
                  data-selenium-id={`SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-${index}`}
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell
                    id={`systemAdminSystemLogTableType${index}`}
                    data-selenium-id={`SystemAdmin-SystemLogTableType-${index}`}
                    component='th'
                    scope='row'
                  >
                    {row.type}
                  </StyledTableCell>
                  <StyledTableCell
                    id={`systemAdminSystemLogTableSubject${index}`}
                    data-selenium-id={`SystemAdmin-SystemLogTableSubject-${index}`}
                    align='left'
                  >
                    {/* 超长的显示弹框---没有要求这个，但是如果不加体验会很差 */}
                    <Tooltip
                      title={row.subject}
                      disableInteractive
                      data-selenium-id={`SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-StyledTableCell1-Tooltip-${index}`}
                    >
                      <div
                        data-selenium-id={`SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-StyledTableCell1-Tooltip-ContainerDiv-${index}`}
                        style={{
                          textOverflow: 'ellipsis',
                          width: '200px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {row.subject}
                      </div>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    id={`systemAdminSystemLogTableAction${index}`}
                    data-selenium-id={`SystemAdmin-SystemLogTableAction-${index}`}
                    align='left'
                  >
                    <LittleLabel textContent={row.action} />
                  </StyledTableCell>
                  <StyledTableCell
                    id={`systemAdminSystemLogTableField${index}`}
                    data-selenium-id={`SystemAdmin-SystemLogTableField-${index}`}
                    align='left'
                  >
                    {/*  超长的显示弹框---没有要求这个，但是如果不加体验会很差 */}
                    <Tooltip
                      title={row.field}
                      disableInteractive
                      data-selenium-id={`SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-StyledTableCell2-Tooltip-${index}`}
                    >
                      <div
                        data-selenium-id={`SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-StyledTableCell2-Tooltip-ContainerDiv-${index}`}
                        style={{
                          textOverflow: 'ellipsis',
                          width: '100%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {row.field === 'Country' ? 'Country/Region' : row.field}
                      </div>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    id={`systemAdminSystemLogTableOldValue${index}`}
                    data-selenium-id={`SystemAdmin-SystemLogTableOldValue-${index}`}
                    align='left'
                  >
                    {/*  超长的显示弹框---没有要求这个，但是如果不加体验会很差 */}
                    <Tooltip
                      title={row.oldValue}
                      disableInteractive
                      data-selenium-id={`SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-StyledTableCell3-Tooltip-${index}`}
                    >
                      <div
                        data-selenium-id={`SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-StyledTableCell3-Tooltip-ContainerDiv-${index}`}
                        style={{
                          textOverflow: 'ellipsis',
                          width: '200px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {row.oldValue==='Null'&&row.field==='Extended Deadline'?null:row.oldValue}
                      </div>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    id={`systemAdminSystemLogTableNewValue${index}`}
                    data-selenium-id={`SystemAdminSystem-LogTableNewValue-${index}`}
                    align='left'
                  >
                    <Box
                      data-selenium-id={`SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-StyledTableCell4-Box-${index}`}
                    >
                      {/*  超长的显示弹框---没有要求这个，但是如果不加体验会很差 */}
                      <Tooltip
                        title={row.newValue}
                        disableInteractive
                        data-selenium-id={`SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-StyledTableCell4-Box-Tooltip-${index}`}
                      >
                        <div
                          disableInteractive
                          data-selenium-id={`SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-StyledTableCell4-Box-Tooltip-ContainerDiv-${index}`}
                          style={{
                            textOverflow: 'ellipsis',
                            width: '200px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {row.newValue==='Null'&&row.field==='Extended Deadline'?null:row.newValue}
                        </div>
                      </Tooltip>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell
                    id={`systemAdminSystemLogTableEditUser${index}`}
                    data-selenium-id={`SystemAdmin-SystemLogTableEditUser-${index}`}
                    align='left'
                  >
                    {/* // 超长的显示弹框---没有要求这个，但是如果不加体验会很差 */}
                    <Tooltip
                      title={row.editUser}
                      disableInteractive
                      data-selenium-id={`SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-StyledTableCell5-Tooltip-${index}`}
                    >
                      <div
                        data-selenium-id={`SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-StyledTableCell5-Tooltip-ContainerDiv-${index}`}
                        style={{
                          textOverflow: 'ellipsis',
                          width: '150px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {row.editUser}
                      </div>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell
                    id={`systemAdminSystemLogTableEditTime${index}`}
                    data-selenium-id={`SystemAdmin-SystemLogTableEditTime-${index}`}
                    align='left'
                  >
                    {/* // 超长的显示弹框---没有要求这个，但是如果不加体验会很差 */}
                    <Tooltip
                      data-selenium-id={`SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-StyledTableCell6-Tooltip-${index}`}
                      title={formatDatetimeString(row.editTime)}
                      disableInteractive
                    >
                      <div
                        data-seleunim-id='SystemAdmin_SystemLog-LogTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-StyledTableCell6-Tooltip-ContainerDiv'
                        style={{
                          textOverflow: 'ellipsis',
                          width: '100%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {formatDatetimeString(row.editTime)}
                      </div>
                    </Tooltip>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Divider data-seleunim-id='SystemAdmin_SystemLog-LogTable-TableBox-Divider' />
      <TableFooter2
        data-seleunim-id='SystemAdmin_SystemLog-LogTable-TableBox-TableFooter2'
        numSelected={0}
        Page={Page}
        perPageNumber={perPageNumber}
        perPageList={[20, 50, 100]}
        count={showValue.total}
        handlePerPageChange={handlePerPageChange}
        handlePageChange={handlePageChange}
      />
    </Box>
  );
}

export default LogTable;

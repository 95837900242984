import {ChecklistStatus, extractHtmlId, FormComponentProps, useForm, VALIDATE_KEY, ValidateState} from "@/components/Form/form.d";
import {string} from "prop-types";
import {Box, Stack} from "@mui/material";
import SectionTitle from "@/pages/SIPPage/SIPCheckListPage/compontents/SectionTitle";
import MyOnlyText from "@/componentsphase2/MyOnlyText";
import {formatNumber} from "@/pages/SIPPage/SIPCheckListPage/utils";
import React, {ReactElement, useEffect} from "react";
import {CHECKLIST_PROCEEDING} from "@/pages/SIPPage/SIPCheckListPage/configable/constants";

export interface OverAllCommentsSectionProps extends FormComponentProps{
    label: string;
    placeholder?: string;
    required: boolean;
}

export function OverAllCommentsSection(props: OverAllCommentsSectionProps): ReactElement{
    const htmlId = extractHtmlId(props);
    const {
        value: comment,
        setValue: setComment,
        form,
        setState,
        getState,
    } = useForm<string>({
        name: props.name,
        initValue: '',
        initStates: {
            [VALIDATE_KEY]: 'default',
            required: props.required,
            statusFocus: false,
            ['html-id']: htmlId,
        }
    });

    const status = form?.root().status;
    const locked = form?.root().locked;
    const editable = status === ChecklistStatus.IN_PROGRESS && !locked;

    const proceeding = form?.root()[CHECKLIST_PROCEEDING] ?? false;

    useEffect(() => {
        const validate = (): ValidateState => {
            if (props.required && !comment) {
                return proceeding ? 'error' : 'default';
            }
            return 'checked';
        };
        setState(VALIDATE_KEY, validate());
        setState('required', props.required);
    }, [comment, proceeding, props.required]);

    return (
        <Stack id={htmlId} direction='column' spacing={2}>
            <SectionTitle>Overall Comments</SectionTitle>
            {status === ChecklistStatus.IN_PROGRESS && !locked ? (
                <Box sx={{ position: 'relative', height: '142px' }}>
                    <MyOnlyText
                        multiline={true}
                        value={comment}
                        setValue={setComment}
                        maxRows={6}
                        minRows={6}
                        height='124px'
                        placeholder='Add comments'
                        onFocus={() => {
                            setState('statusFocus',true);
                        }}
                        onBlur={() => {
                            setState('statusFocus',false);
                        }}
                        isError={!getState('statusFocus') && comment?.length > 100000}
                        errorMessage={'Input exceeds maximum character limit.'}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            fontFamily: 'Open Sans',
                            fontSize: '12px',
                            fontWeight: '400',
                            lineHeight: '16px',
                            letterSpacing: '0px',
                            textAlign: 'left',
                            color: '#596A7C',
                            height: '16px',
                        }}
                    >{`${formatNumber(comment)}/100,000`}</Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        //styleName: text input;
                        fontFamily: 'Open Sans',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '17px',
                        letterSpacing: '-0.011em',
                        color: comment ? '#262E35':'#BCC5CF',
                        wordBreak:'break-all'
                    }}
                >
                    {comment?comment: 'No comment'}
                </Box>
            )}
        </Stack>
    );
}
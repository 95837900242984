import { useEffect, useState } from 'react';
import { defaultVars, errorVars } from '../styles';
import { FormControl, IconButton, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import DeleteListItemIcon from '../../../../../assets/DeleteListItemIcon.svg';
import * as React from 'react';
import { CustomFormHelperText, CustomTextField } from './components';
import DrawerCloseButton from '@/componentsphase2/DrawerCloseButton';
export default function CustomInput(props) {
  const {
    hasText,
    id,
    placeholder,
    NeedCustomCheck,
    CustomCheckPattern,
    setCustomCheckError,
    emptyError,
    limitLength,
    value,
    setValue,
    HandleChange,
    customCheckText,
    needEndAdornment,
    handleIconButtonClick,
    maxRows,
    autoFocus,
  } = props;

  const [customCheckErr, setCustomCheckErr] = useState(false);

  const Effective = value => {
    if (value === '') {
      setCustomCheckErr(false);
      setCustomCheckError(false);
    } else {
      setCustomCheckErr(!CustomCheckPattern.test(value));
      setCustomCheckError(!CustomCheckPattern.test(value));
    }
  };

  const [vars, setVars] = useState(defaultVars);

  const handleChange = e => {
    if (limitLength !== undefined && limitLength !== null) {
      if (e.target.value.length <= limitLength) {
        if (setValue !== undefined) {
          setValue(e.target.value);
        }

        if (HandleChange !== undefined) {
          HandleChange(e.target.value);
        }
      }
    } else {
      if (setValue !== undefined) {
        setValue(e.target.value);
      }
      if (HandleChange !== undefined) {
        HandleChange(e.target.value);
      }
    }
  };

  useEffect(() => {
    setVars(
      emptyError || (NeedCustomCheck && customCheckErr) || props.error
        ? errorVars
        : defaultVars
    );
  }, [emptyError, customCheckErr, props.error]);

  useEffect(() => {
    if (NeedCustomCheck) {
      if (value === '') {
        setCustomCheckErr(false);
        setCustomCheckError(false);
      } else {
        setCustomCheckErr(!CustomCheckPattern.test(value));
        setCustomCheckError(!CustomCheckPattern.test(value));
      }
    }
  }, []);

  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);

  const isEmpty = value => {
    let flag =
      value === undefined ||
      value === null ||
      value === '' ||
      value === [] ||
      value === {} ||
      (typeof value === 'string' && !value.trim().length);
    return flag;
  };

  return (
    <FormControl
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      variant='standard'
      error={emptyError || (NeedCustomCheck && customCheckErr) || props.error}
      sx={{ width: '100%', paddingBottom: '1px' }}
      data-selenium-id='SIDetail_MarketSection-CustomInput-FormControl'
    >
      {needEndAdornment && (hover || focus) ? (
        <TextField
          error={
            emptyError || (NeedCustomCheck && customCheckErr) || props.error
          }
          id={id}
          data-selenium-id={id}
          sx={{
            '& .MuiOutlinedInput-root': {
              background: '#FFFFFF',
              borderRadius: '4px',
              boxSizing: 'border-box',
              width: '100%',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '20px',
              padding: '8px 12px',
              color: '#262E35',
            },
            '& .MuiOutlinedInput-input': {
              padding: '0px 0px',
              '&::placeholder': {
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '17.5px',
                color: '#BCC5CF',
                opacity: '1',
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: isEmpty(value)
                ? '1px solid #DFE4E8'
                : '1px solid #98A7B6',
            },
            '& :hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #262E35 !important',
              },
            },
            '& .Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: isEmpty(value)
                  ? '2px solid #154AB6 !important'
                  : '2px solid #0052CC !important',
              },
            },
            '& .ForwardRef(TextField)-root-13 .MuiOutlinedInput-input::placeholder':
              {
                color: 'blue',
              },
          }}
          value={value}
          multiline
          maxRows={maxRows}
          placeholder={
            placeholder !== undefined && placeholder !== null ? placeholder : ''
          }
          onChange={handleChange}
          onBlur={
            props.onBlur
              ? props.onBlur
              : e => {
                  if (
                    NeedCustomCheck !== undefined &&
                    NeedCustomCheck !== null &&
                    NeedCustomCheck
                  ) {
                    Effective(e.target.value);
                  } else {
                    return props.onBlur;
                  }
                }
          }
          InputProps={{
            endAdornment: (
              <InputAdornment
                position='end'
                data-selenium-id='SIDetail_MarketSection-CustomInput-InputAdornment'
                onClick={handleIconButtonClick}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <DeleteListItemIcon data-selenium-id='SIDetail_MarketSection-CustomInput-CanselListItemBtnIcon'></DeleteListItemIcon>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <TextField
          error={
            emptyError || (NeedCustomCheck && customCheckErr) || props.error
          }
          id={id}
          data-selenium-id={id}
          autoFocus={autoFocus ?? false}
          sx={{
            '& .MuiOutlinedInput-root': {
              background: '#FFFFFF',
              borderRadius: '4px',
              boxSizing: 'border-box',
              width: '100%',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '20px',
              padding: '8px 12px',
              color: '#262E35',
            },
            '& .MuiOutlinedInput-input': {
              padding: '0px 0px',
              '&::placeholder': {
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '17.5px',
                color: '#BCC5CF',
                opacity: '1',
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: isEmpty(value)
                ? '1px solid #DFE4E8'
                : '1px solid #98A7B6',
            },
            '& :hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #262E35 !important',
              },
            },
            '& .Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: isEmpty(value)
                  ? '2px solid #154AB6 !important'
                  : '2px solid #0052CC !important',
              },
            },
            '& .ForwardRef(TextField)-root-13 .MuiOutlinedInput-input::placeholder':
              {
                color: 'blue',
              },
          }}
          value={value}
          placeholder={
            placeholder !== undefined && placeholder !== null ? placeholder : ''
          }
          multiline
          maxRows={maxRows}
          onChange={handleChange}
          onBlur={
            props.onBlur
              ? props.onBlur
              : e => {
                  if (
                    NeedCustomCheck !== undefined &&
                    NeedCustomCheck !== null &&
                    NeedCustomCheck
                  ) {
                    Effective(e.target.value);
                  } else {
                    return props.onBlur;
                  }
                }
          }
        />
      )}

      <CustomFormHelperText
        propsError={props.error}
        propsHelperText={props.helperText}
        needCustomCheck={NeedCustomCheck}
        customCheckError={customCheckErr}
        customCheckText={customCheckText}
        data-selenium-id='SIDetail_MarketSection-CustomInput-HelperText'
      />
    </FormControl>
  );
}

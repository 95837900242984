import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import CreateAndUpdateTextArea from '../CommonComponents/CreateAndUpdateTextArea';
import {
  CreateAndUpdateTitle,
  CreateAndUpdateLittleTitle,
} from '../CommonComponents/CreateAndUpdateTitle';
import ChildBlock from './ChildBlock';
function ReviewPage(props) {
  const { reviewData, setValue, value, isOA, setHasError=(e) => {} } = props;
  const [remarksValue, setRemarksValue] = useState(value);
  //获取当前页面的url
  const url = window.location.pathname;
  //判断当前页面的操作是否为update,如果是 返回 true， 否则返回false
  const isUpdate = () => {
    return url.indexOf('update') !== -1 ? true : false;
  };
  //获得 stage
  const getStage = () => {
    let SIArr = url.split('/');
    let stage = SIArr[SIArr.length - 1];
    switch (stage) {
      case 'Acquired':
      case 'Production':
      case 'Published':
        return stage;
      case 'PaperCommission':
        return 'Paper Commission';
      case 'SubmissionAndReview':
        return 'Submission and Review';
      default:
        return '';
    }
  };
  //根据stage和操作（update/create） 动态获取大标题
  const getTitle = () => {
    return isUpdate()
      ? `Update Stage to ${getStage()}`
      : 'Create New Special Issue';
  };
  const getLittleTitle = property => {
    switch (property) {
      case 'generalInfomation':
        return 'General Information';
      case 'guestEditors':
        return 'Guest Editors';
      case 'dates':
        return 'Dates';
      case 'submissionOverview':
        return 'Submission Overview';
      case 'stakeholders':
        return 'Stakeholders';
      case 'publicationDetails':
        return 'Publication Details';
      case 'nothing':
        return '';
      default:
        return 'Notes';
    }
  };

  useEffect(() => {
    setValue(remarksValue);
  }, [remarksValue]);

  return (
    <>
      <CreateAndUpdateTitle
        data-selenium-id='SI_CreateAndUpdate_ReviewPage-Title'
        title={getTitle()}
      />
      <Stack
        data-selenium-id='SI_CreateAndUpdate_ReviewPage-Stack'
        spacing='24px'
        justifyContent='flex-start'
      >
        {Object.keys(reviewData).map(property => (
          <ChildBlock
            littleTitle={getLittleTitle(property)}
            data={reviewData[property]}
            isOA={isOA}
            currentStage={getStage()}
          />
        ))}
        {isUpdate() ? (
          <Box data-selenium-id='SI_CreateAndUpdate_ReviewPage-Box'>
            <CreateAndUpdateLittleTitle
              data-selenium-id='SI_CreateAndUpdate_ReviewPage-LittleTitle'
              littleTitle='Notes'
            />
            <CreateAndUpdateTextArea
              id='ReviewRemarks'
              data-selenium-id='SI_CreateAndUpdate_ReviewPage-TextArea'
              title='Remarks'
              placeholder='Remarks'
              required={false}
              emptyErr={false}
              max={5000}
              value={remarksValue}
              setValue={setRemarksValue}
              height='68px'
              exceedsLimitHelperText='[Remarks] should not exceed 5,000 characters in length!'
            ></CreateAndUpdateTextArea>
          </Box>
        ) : (
          <></>
        )}
      </Stack>
    </>
  );
}
export default ReviewPage;

import { useSingleSelectDatePicker } from "@/pages/SIPage/Overview/Filter/utils";
import { handleFileterJournalName } from "@/utils/commonUtils";

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

//比较两个字符串数组是否相同
export function compareStrArray(arrayA, arrayB){
  if(!arrayA||!arrayB){
    return false;
  }
  if(arrayA.length !== arrayB.length){
    return false;
  }
  if(arrayA.length>0 && typeof(arrayA[0])!=='string' ){
    for(let i=0;i<arrayA.length;i++){
      arrayA[i] = arrayA[i].toISOString();
    }
  }
  if(arrayB.length>0 && typeof(arrayB[0])!=='string'){
    for(let i=0;i<arrayB.length;i++){
      arrayB[i] = arrayB[i].toISOString();
    }
  }
  for(let i=0;i<arrayA.length;i++){
    if(!arrayB.includes(arrayA[i])){
      return false;
    }
  }
  return true;
}

export function compareNumberArray(arrayA, arrayB){

  if(typeof arrayA === undefined || typeof arrayB === undefined ){
    console.warn('arrayA or arrayB is NOT defined');
    return false;
  }else if(!Array.isArray(arrayA) || !Array.isArray(arrayB)){
    console.warn('arrayA or arrayB is NOT array');
    return false;
  }else if(arrayA.length !== arr2.arrayB) {
    return false;
  }

  let arr1 = [...arrayA];
  let arr2 = [...arrayB];

  // Step 2: Sort the arrays
  arr1.sort((a, b) => a - b);
  arr2.sort((a, b) => a - b);

  // Step 3: Compare elements
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  // Step 4: Arrays are equal
  return true;
}

//比较A,B两个filter是否相同，它们都有相同的key:value形式，即value：array
export function compareFilter(FilterA, FilterB){
  if(!FilterA || !FilterB){
    return false;
  }
  for(let key in FilterA){
    //这里由于handlingCeName已经可以比较出handlingCE了，就不需要用handlingCE在进行比较了
    if(key === 'handlingCe' || key === 'operationsLead' || key === 'screener'){
      continue;
    }

    let valueA = FilterA[key];
    let valueB = FilterB[key];
    if(!valueA || !valueB){
      continue;
    }
    
    let areEqual;
    if(typeof (valueA[0]) === 'number'){
      // compare arrays of numbers
      areEqual = compareNumberArray(valueA, valueB);
    }else if(typeof (valueA[0]) === 'string'){
      areEqual = compareStrArray(valueA.concat(),valueB.concat());
    }
    if(!areEqual) return false;
  }
  return true;
}

const ALL_ACTIVE_STAGES_STRING = 'Active Stages';
export const prepareFilters = (FilterSelected, keepCEName, keepJournalCode) => {
  if (FilterSelected === null) {
    FilterSelected = {
      sipCodeSelected: [],
      sipTitleSelected: [],
      handlingCeSelected: [],
      operationsLeadSelected: [],
      screenerSelected: [],
      geNameSelected: [],
      geEmailSelected: [],
      aimsAndScopeSelected: [],
      topicsSelected: [],
      keywordsSelected: [],

      stageSelected: [],
      sipOriginSelected: [],
      sipChecklistStatusSelected: [],
      catGroupSelected: [],

      preferredSubjectAreaSelected: [],
      preferredJournalSelected: [],
      regionSelected: [],
      geRegionSelected: [],

      timeSensitiveSelected: [],
      potentialAuthorsSelected: [],

      // leadGeFullNameSelected: [],
      // coGeFullNameSelected: [],
      journalSelected: [],
      // jpmSelected: [],
      // subjectGroupSelected: [],
      // journalrevenueModelSelected: [],
      // stateSelected:[],
      submissionFromDate: null,
      submissionToDate: null,
      decisionFromDate: null,
      decisionToDate: null,
      creationFromDate: null,
      creationToDate: null,
    };
  }

  const journalName = [];
  for (let journalSelectedElement of (FilterSelected.journalSelected ?? [])) {
    journalName.push(
      handleFileterJournalName(journalSelectedElement)['journalName']
    );
  }

  // 处理handling ce
  const handlingCeIds = [];
  for (const element of (FilterSelected.handlingCeSelected ?? [])) {
    handlingCeIds.push(element.userId);
  }

  // console.log('FilterSelected ***', FilterSelected);
  const operationsLeadIds = [];
    for (const element of (FilterSelected.operationsLeadSelected ?? [])) {
      operationsLeadIds.push(element.userId);
    }
  
  const screenerIds = [];
    for (const element of (FilterSelected.screenerSelected ?? [])) {
      screenerIds.push(element.userId);
    }

  // stage列表无论如何都要把All本身移除
  const stages = (FilterSelected.stageSelected ?? []).filter(item => {
    return item !== ALL_ACTIVE_STAGES_STRING;
  });

  const tempReturn = {
    sipCode: FilterSelected.sipCodeSelected,
    sipTitle: FilterSelected.sipTitleSelected,
    handlingCe: handlingCeIds,
    operationsLead: operationsLeadIds,
    screener: screenerIds,
    geName: FilterSelected.geNameSelected,
    geEmail: FilterSelected.geEmailSelected,
    aimsAndScope: FilterSelected.aimsAndScopeSelected,
    topics: FilterSelected.topicsSelected,
    keywords: FilterSelected.keywordsSelected,

    stage: stages,
    sipOrigin: FilterSelected.sipOriginSelected,
    sipChecklistStatus: FilterSelected.sipChecklistStatusSelected,
    catGroup: FilterSelected.catGroupSelected,

    preferredSubjectArea: FilterSelected.preferredSubjectAreaSelected,
    preferredJournal: FilterSelected.preferredJournalSelected,
    region: FilterSelected.regionSelected,

    timeSensitive: FilterSelected.timeSensitiveSelected,
    potentialAuthors: FilterSelected.potentialAuthorsSelected,

    submissionFromDate:
      FilterSelected.submissionFromDate === null
        ? []
        : [FilterSelected.submissionFromDate],
    submissionToDate:
      FilterSelected.submissionToDate === null
        ? []
        : [FilterSelected.submissionToDate],
    decisionFromDate:
      FilterSelected.decisionFromDate === null
        ? []
        : [FilterSelected.decisionFromDate],
    decisionToDate:
      FilterSelected.decisionToDate === null
        ? []
        : [FilterSelected.decisionToDate],
    creationFromDate:
      FilterSelected.creationFromDate === null
        ? []
        : [FilterSelected.creationFromDate],
    creationToDate:
      FilterSelected.creationToDate === null
        ? []
        : [FilterSelected.creationToDate],
  };
  
  if (keepCEName) {
    // 存在特殊情况需要保留CE的name，
    const handlingCeNames = [];
    for (const user of (FilterSelected.handlingCeSelected ?? [])) { //
      handlingCeNames.push(`${user.username}(${user.email})`);
    }
    tempReturn['handlingCeName'] = handlingCeNames;

    // 存在特殊情况需要保留operations Lead的name，
    const operationsLeadNames = [];
    for (const user of FilterSelected.operationsLeadSelected ?? []) { //
      operationsLeadNames.push(`${user.username}(${user.email})`);
    }
    tempReturn['operationsLeadName'] = operationsLeadNames;

    // 存在特殊情况需要保留screeners的name，
    const screeners = [];
    for (const user of FilterSelected.screenerSelected ?? []) { //
      screeners.push(`${user.username}(${user.email})`);
    }
    tempReturn['screenerName'] = screeners;
  }

  return tempReturn;
};

export function maxOne(array) {
  return array && array.length > 0 && array[0] !== undefined ? 1 : 0;
}

// filter counter of bigger categories
export function getSum(FilterSelected) {
  if (FilterSelected === null) {
    return 0;
  }
  let sum = 0;
  for (let key in FilterSelected) {
    if(key.includes('From') || key ==='handlingCe' || key ==='operationsLead' || key ==='screener'){
      continue;
    }
    sum += maxOne(FilterSelected[key]);
  }
  return sum;
}

export const useSingleSIPDateRangePicker = (FilterSelected) => {
  if(!FilterSelected){
    // invalid Filter
    return true;
  } 
  return useSingleSelectDatePicker(FilterSelected?.submissionFromDate, FilterSelected?.submissionToDate)|| 
          useSingleSelectDatePicker(FilterSelected?.decisionFromDate, FilterSelected?.decisionToDate)|| 
          useSingleSelectDatePicker(FilterSelected?.creationFromDate, FilterSelected?.creationToDate);
};

export const initSIPFilterSelected={
  sipCodeSelected: [],
  sipTitleSelected: [],
  handlingCeSelected: [],
  operationsLeadSelected: [],
  screenerSelected: [],
  geNameSelected: [],
  geEmailSelected: [],
  aimsAndScopeSelected: [],
  topicsSelected: [],
  keywordsSelected: [],

  stageSelected: [],
  sipOriginSelected: [],
  sipChecklistStatusSelected: [],
  catGroupSelected: [],

  preferredSubjectAreaSelected: [],
  preferredJournalSelected: [],
  regionSelected: [],

  timeSensitiveSelected: [],
  potentialAuthorsSelected:[],

  journalSelected: [],

  submissionFromDate: null,
  submissionToDate: null,
  decisionFromDate: null,
  decisionToDate: null,
  creationFromDate: null,
  creationToDate: null,

  siTitleSelected: [],
  leadGeFullNameSelected: [],
  coGeFullNameSelected: [],
};

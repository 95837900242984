import {
  BarAndLineChartOptions,
  LEGEND_STYLE,
} from '@/pages/Report/chartUtils/chartOptions/BarAndLineChartOptions.js';
import {
  ACCEPTANCE,
  BAR_COLORS,
  // FY_TRENDS_OF_SI_ACQUISITION,
  PUBLICATION,
  SUBMISSION,
} from '../../Constants/index.js';

/**
 * overwrite the default configuration
 */
const BARCHART_HEIGHT = 335;
const BAR_SIZE = [24, 32, 48];
const LEGEND_POS_ORIGIN = 216;
const LEGEND_POS_OFFSET = -180;

export class AAndAFYTrendsBDOption extends BarAndLineChartOptions {
  constructor(header, subHeader, bdOO, bdOA) {
    super();
    // overwrite the default properties
    // e.g. this.titleHeight = TITLE_HEIGHT
    this.barchartHeight = BARCHART_HEIGHT[1];
    this.header = header;
    this.subHeader = subHeader;
    this.bdOO = bdOO;
    this.bdOA = bdOA;
    this.legend_pos = LEGEND_POS_ORIGIN;
  }

  getBarSeries() {
    let upBarColor;
    let downBarColor;
    switch (this.subHeader) {
      case SUBMISSION:
        upBarColor = BAR_COLORS.businessDivision.submission.oo;
        downBarColor = BAR_COLORS.businessDivision.submission.oa;
        break;
      case ACCEPTANCE:
        upBarColor = BAR_COLORS.businessDivision.acceptance.oo;
        downBarColor = BAR_COLORS.businessDivision.acceptance.oa;
        break;
      case PUBLICATION:
        upBarColor = BAR_COLORS.businessDivision.publication.oo;
        downBarColor = BAR_COLORS.businessDivision.publication.oa;
        break;
      default:
        upBarColor = BAR_COLORS.businessDivision.submission.oo;
        downBarColor = BAR_COLORS.businessDivision.submission.oa;
    }

    return [
      {
        ...this.barSerieOption,
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'inside',
              verticalAlign: 'left',
              lineHeight: 2,
              textStyle: {
                color: '#FFFFFF',
                fontSize: 10,
              },
              formatter: params => {
                const position = params.encode.y;
                if (params.data[position] == 0) {
                  return '';
                }
                return params.data[position];
              },
            },
          borderRadius:[4,4,0,0],
          },
        },
        name: this.bdOO,
        stack: 'all',
        seriesLayoutBy: 'row',
        color: upBarColor,
        barWidth: this.getBarSize(),
        barMinHeight: 12,
        barGap: '0%',
        encode: {
          x: 0,
          y: 1,
        },
      },
      {
        ...this.barSerieOption,
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'inside',
              verticalAlign: 'left',
              lineHeight: 2,
              textStyle: {
                color: '#FFFFFF',
                fontSize: 10,
              },
              formatter: params => {
                const position = params.encode.y;
                if (params.data[position] == 0) {
                  return '';
                }
                return params.data[position];
              },
            },
          borderRadius:[4,4,0,0],

          },
        },
        name: this.bdOA,
        stack: 'total',
        seriesLayoutBy: 'row',
        color: downBarColor,
        barWidth: this.getBarSize(),
        barMinHeight: 12,
        encode: {
          x: 0,
          y: 2,
        },
      },
      
    ];
  }

  getTooltip() {
    return [
      {
        ...this.tooltip,
        formatter: params => {
          let position = params.encode.y;
          let dataStr = `<div style="padding: 0; margin: 0;">
            <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 0 0 2.5px 0;">${params.name}</p>
            <p style="font-size: 10px; font-weight: 400;color: #848484; line-height: 14px; margin: 0;">${params.seriesName}&nbsp;:&nbsp;${params.data[position]}</p>
          </div>`;
          return dataStr;
        },
      },
    ];
  }

  getBarchartHeight() {
    return BARCHART_HEIGHT;
  }

  setLegendSG(legendSG) {
    this.legendSG = legendSG;
    return this;
  }

  setLegendBD(legendBD) {
    this.legendBD = legendBD;
    return this;
  }

  getSelectedLegend() {
    const newTextStyle = {
      ...LEGEND_STYLE.textStyle,
      padding: [0, 5, 0, 0],
    };

    return [
      {
        ...LEGEND_STYLE,
        textStyle: newTextStyle,
        data: [
          {
            name: this.legend[0],
          },
        ],
        left: this.legend_pos,
        bottom: 45,
      },
      {
        ...LEGEND_STYLE,
        textStyle: newTextStyle,
        data: [
          {
            name: this.legend[1],
          },
        ],
        left: this.legend_pos + 230,
        bottom: 45,
      },
    ];
  }

  getBarSize() {
    const numberOfBars = this.dataForBar && this.dataForBar[0].length - 1;
    if (numberOfBars > 6) {
      return BAR_SIZE[0];
    } else if (numberOfBars >= 2) {
      return BAR_SIZE[1];
    } else {
      return BAR_SIZE[2];
    }
  }

  setWidth(width) {
    this.width = width;

    if (width && width !== '100%') {
      const half_width = width / 2;
      this.legend_pos = half_width + LEGEND_POS_OFFSET;
    }

    return this;
  }
}

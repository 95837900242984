import {axios_instance} from "@/utils/axios_instance";
import {SET_SIP_SOURCE_OPTIONS} from "@/actions/SIMT-SIP/SIP/SIPAction";

export function getSIPSourceOption() {
    return async dispatch => {
        try {
            let res = await axios_instance.get(
                '/api/v1/user-service/configs',
                {
                    //和SISource的Option一样
                    params: {
                        type:'Special Issue Source',
                        fuzzy_type:false,
                    },
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: SET_SIP_SOURCE_OPTIONS, data: res.data.data});
            }
        } catch {
        }
    };
}
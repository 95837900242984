const DEFAULT_LABEL = {
  formatter: params => {
    if (params.data[1] === '0%') {
      return '';
    }
    return params.data[1];
  },
  fontSize: 10,
  fontWeight: 500,
  color: '#596A7C',
};

const DEFAULT_LABEL_LINE = {
  show: true,
  length: 5,
  length2: 5,
};

const DEFAULT_ITEM_STYLE = {
  borderWidth: 1,
  borderColor: '#FFFFFF',
  borderType: 'solid',
};

export class BasicPieSeries {
  constructor(
    pieRadius = 20,
    center = ['50%', '50%'],
    datasetIndex = 1,
    disableEmphasis = true,
    showTooltip = false,
    focus = 'none',
    z = 2
  ) {
    this.pieRadius = pieRadius;
    this.center = center;
    this.datasetIndex = datasetIndex;
    this.disableEmphasis = disableEmphasis;
    this.showTooltip = showTooltip;
    this.focus = focus;
    this.z = z;
    this.labelLine = DEFAULT_LABEL_LINE;
    this.laybelLayout = undefined; //default labelLayout
    this.label = DEFAULT_LABEL;
    this.itemStyle = DEFAULT_ITEM_STYLE;
  }

  addLabelAttr(label) {
    this.label = {
      ...this.label,
      ...label,
    };
    return this;
  }

  setZIndex(z) {
    this.z = z;
    return this;
  }

  setLabelLine(labelLine) {
    this.labelLine = labelLine;
    return this;
  }

  setLabelLayout(labelLayout) {
    this.labelLayout = labelLayout;
    return this;
  }
  setItemStyle(itemStyle) {
    this.itemStyle = itemStyle;
    return this;
  }
  addTooltipAttr(tooltip) {
    this.tooltip = {
      ...this.tooltip,
      ...tooltip,
    };
    return this;
  }

  getSeries() {
    return {
      type: 'pie',
      radius: this.pieRadius,
      center: this.center,
      label: this.label,
      labelLine: this.labelLine,
      labelLayout: this.labelLayout,
      itemStyle: this.itemStyle,
      emphasis: {
        disabled: this.disableEmphasis,
        scaleSize: this.disableEmphasis ? undefined : 5,
        focus: this.focus,
        itemStyle: {
          ...DEFAULT_ITEM_STYLE,
          borderWidth: 5,
          color: 'inherit', // no highlight
        },
      },
      tooltip: {
        show: this.showTooltip,
        ...this.tooltip,
      },
      datasetIndex: this.datasetIndex,
      encode: {
        itemName: 0,
        value: 2,
      },
      z: this.z,
    };
  }
}

import { Popover } from '@mui/material';
import PropTypes from 'prop-types';
import JournalFilterPopupContent from './JournalFilterPopupContent';
import { useEffect, useState } from 'react';
import { bindPopover } from 'material-ui-popup-state';
import { getValueStringByObjectKey } from './JournalFilterConstantListDisplay';
import items from '@/pages/SIPage/SpecialIssueManagement/header-rows';

/**
 *
 * @param {import("./JournalFilterPopup").JournalFilterPopupProps} props
 * @returns
 */
function JournalFilterPopup(props) {
  const {
    id,
    data,
    selected,
    handleSearch = async () => {},
    placeholder = '',
    multiselect = true,
    popupState,
    isConstantList = false,
    selectObjectKey = 'self',
    displayFunction = item => {
      return getValueStringByObjectKey(selectObjectKey, item);
    },
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  /**
   *
   * @param {string} searchContent
   */
  function handleAsyncSearch(searchContent) {
    setIsLoading(true);
    async function search() {
      await handleSearch(searchContent);
    }
    search().then(_r => {
      setIsLoading(false);
    });
  }

  const [popUpMaxHeight, setPopUpMaxHeight] = useState(0);

  const HEIGHT_OF_EVERY_ROW = 24;
  const NUMBER_OF_ROWS_TO_DISPLAY = 6;
  const HEIGHT_OF_DEVIDER = 1;
  const HEIGHT_OF_SEARCH_TEXTFIELD = 26;
  const SUM_OF_VERTICAL_PADDING = 4;

  useEffect(() => {
    if (isConstantList) {
      setPopUpMaxHeight(
        SUM_OF_VERTICAL_PADDING +
          HEIGHT_OF_EVERY_ROW * NUMBER_OF_ROWS_TO_DISPLAY
      );
    } else {
      setPopUpMaxHeight(
        HEIGHT_OF_SEARCH_TEXTFIELD +
          HEIGHT_OF_EVERY_ROW * NUMBER_OF_ROWS_TO_DISPLAY +
          HEIGHT_OF_DEVIDER
      );
    }
  }, [isConstantList, data, selected]);

  return (
    <Popover
      {...bindPopover(popupState)}
      id={id}
      data-selenium-id={id}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{
        '& .MuiPopover-paper': {
          background: '#fff',
          borderRadius: '5px',
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
          mt: '6px',
          padding: '6px 9px 3px 12px',
          maxHeight: `${popUpMaxHeight}px`,
        },
      }}
    >
      <JournalFilterPopupContent
        name={id}
        data={data}
        selected={selected}
        isLoading={isLoading}
        handleSearch={handleAsyncSearch}
        placeholder={placeholder}
        selectObjectKey={selectObjectKey}
        multiselect={multiselect}
        displayFunction={displayFunction}
        isConstantList={isConstantList}
      />
    </Popover>
  );
}

JournalFilterPopup.propTypes = {
  id: PropTypes.string.isRequired, // 对应到store里面的id，这块把它跟redux耦合了可能不是很好
  data: PropTypes.array.isRequired, // 对应到store里面的数据
  selected: PropTypes.array.isRequired, // 已选择的，对应到store
  handleSearch: PropTypes.func.isRequired, // search函数
  popupState: PropTypes.any.isRequired, // 把popupState第三方库的属性传入即可
  placeholder: PropTypes.string, // 输入框的占位符
  selectObjectKey: PropTypes.string, // 从data中选取什么东西
  displayFunction: PropTypes.func, // 这个功能是后来才想到的，所以跟`selectObjectKey`的实现不一样，这里需要是函数
  isConstantList: PropTypes.bool, // 是否为常量列表
};

export default JournalFilterPopup;

/*
 * @Author: LiuJingQian 1250366059@qq.com
 * @Date: 2023-06-27 15:00:35
 * @LastEditors: LiuJingQian 1250366059@qq.com
 * @LastEditTime: 2023-06-30 08:12:53
 * @FilePath: \simt_front\src\pages\CATJournal\Overview\css.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {
  H4Font,
  SmallBoldFont,
  SmallFont,
} from '../../../components/FontStyle';
export const cat_overview_container = {
  padding: '11px 32px',
  backgroudColor: 'rgb(250,250,250)',
};

export const cat_overview_head = {
  marginBottom: '16px',
};

export const cat_overview_head_title = {
  ...H4Font,
  fontSize: '22px',
  lineHeight: '27.5px',
  color: '#262E35',
  marginBottom: '16px',
};

export const cat_overview_head_btnBox = {
  height: '32px',
  minWidth: '440px',
};

export const cat_overview_head_btnBox_right = {
  float: 'right',
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '8px',
  mb:'16px',
};

export const start_endAttachment = {
  height: '20px',
  boxSizing: 'border-box',
  padding: '2px 0px',
};

export const disable_button_value = color => {
  return {
    ...SmallBoldFont,
    textAlign: 'center',
    letterSpacing: '0.01em',
    color: color,
    height: '20px',
  };
};

/**
 * @type {import("@mui/material").SxProps}
 */
export const responsive_button = {
  minWidth: 'auto',
  whiteSpace: 'nowrap',
  border: '1px solid #DFE4E8',
  height: '32px',
  color: '#596A7C',
};

export const cat_overview_main = {};

export const cat_overview_main_checkBox = {
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '12px',
  marginBottom: '16px',
};

export const cat_overview_main_checkBox_checkBox = {
  padding: '3px',
};

export const cat_overview_main_checkBox_label = {
  ...SmallFont,
  color: '#484848',
  height: '20px',
  padding: '2px 0px',
};

import {axios_instance} from "@/utils/axios_instance";
import {downloadFile} from "@/actions/SIMT-SI/SpecialIssue/SIAction";
import moment from "moment/moment";
import {EXPORT_REPORT} from "@/actions/SIMT-Report/ReportJournal/ReportJournalAction";
import getAuth from "@/utils/getAuth";
import { getMoment } from "../getMoment";

export function exportReportJournalCeCatExcel(selectedDate) {
    var selectDateYear = selectedDate.split('-')[0];
    var selectDateMonth = selectedDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            let res = await axios_instance.get(`/reports/export/journal/ce/excel`, {
                responseType: 'blob',
                params: {
                    selectedDate: date,
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    authorization: getAuth(),
                },
            });
            downloadFile(
                res.data,
                'REPORT_JOURNAL_CECAT_' +
                getMoment() +
                '.xlsx'
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportReportJournalCeCatCsv(selectedDate) {
    var selectDateYear = selectedDate.split('-')[0];
    var selectDateMonth = selectedDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            let res = await axios_instance.get(`/reports/export/journal/ce/csv`, {
                params: {
                    selectedDate: date,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            downloadFile(
                res.data,
                'REPORT_JOURNAL_CECAT_' +
                '_' +
                getMoment() +
                '.csv'
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportReportJournalCeCatCsvWithFilenameFromBackEnd(selectedDate) {
    var selectDateYear = selectedDate.split('-')[0];
    var selectDateMonth = selectedDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            let res = await axios_instance.get(`/reports/export/journal/ce/csv`, {
                params: {
                    selectedDate: date,
                },
                headers: {
                    authorization: getAuth(),
                },
            });

            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(
                    res.data,
                    res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, '')
                );
            } else (
                downloadFile(
                    res.data,
                    'REPORT_JOURNAL_CECAT_' +
                    '_' +
                    getMoment() +
                    '.csv'
                )
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportReportJournalSGExcel(selectedDate) {
    var selectDateYear = selectedDate.split('-')[0];
    var selectDateMonth = selectedDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            let res = await axios_instance.get(`/reports/export/journal/sg/excel`, {
                responseType: 'blob',
                params: {
                    selectedDate: date,
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    authorization: getAuth(),
                },
            });
            downloadFile(
                res.data,
                'REPORT_JOURNAL_SUBJECT_GROUP_' +
                getMoment() +
                '.xlsx'
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportReportJournalSGCsv(selectedDate) {
    var selectDateYear = selectedDate.split('-')[0];
    var selectDateMonth = selectedDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            let res = await axios_instance.get(`/reports/export/journal/sg/csv`, {
                params: {
                    selectedDate: date,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            downloadFile(
                res.data,
                'REPORT_JOURNAL_SUBJECT_GROUP_' +
                '_' +
                getMoment() +
                '.csv'
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportReportJournalSGCsvWithFilenameFromBackEnd(selectedDate) {
    var selectDateYear = selectedDate.split('-')[0];
    var selectDateMonth = selectedDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            let res = await axios_instance.get(`/reports/export/journal/sg/csv`, {
                params: {
                    selectedDate: date,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(
                    res.data,
                    res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, '')
                );
            } else {
                downloadFile(
                    res.data,
                    'REPORT_JOURNAL_SUBJECT_GROUP_' +
                    '_' +
                    getMoment() +
                    '.csv'
                );
            }
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}
// Components
import { Box } from '@mui/material';
// styles
import { SmallBoldFont } from '@/components/FontStyle';
import { AllCentered } from '../Css';

export default function ResetButton({ disabled = false, handleClick, Label = 'Clear' }) {
  return (
    <Box
      id='btnClearFilter'
      data-selenium-id={
        'SIPage_Overview-Filter-Overview-btnClearFilter'
      }
      sx={{
        width: '62px',
        height: '34px',
        ...SmallBoldFont,
        color: disabled ? '#98A7B6' : '#0052CC',
        border: `1px solid ${disabled ? '#98A7B6' : '#0052CC'}`,
        borderRadius: '5px',
        cursor: disabled ? 'default' : 'pointer',
        backgroundColor: disabled ? '#F1F3F5' : '#FFFFFF',
        ':hover': !disabled && {
          color: '#6D98EE',
          borderColor: '#6D98EE',
        },
        ':active': !disabled && {
          borderColor: '#AEC6F6',
          color: '#6D98EE',
          backgroundColor: '#E8EDFB',
        },
        ...AllCentered
      }}
      onClick={() => {
        if (!disabled) {
          handleClick();
        }
      }}
    >
      {Label}
    </Box>
  );
}

import { Stack, useMediaQuery, Box } from '@mui/material';
import SIOverviewFilterIcon1 from '../assets/SIOverviewFilterIcon1.svg';
import SIOverviewFilterIcon2_0052CC from '../assets/SIOverviewFilterIcon2_0052CC.svg';
import SIOverviewFilterIcon2_113D95 from '../assets/SIOverviewFilterIcon2_113D95.svg';
import SIOverviewFilterIcon2_6D98EE from '../assets/SIOverviewFilterIcon2_6D98EE.svg';

import SIOverviewExportIconWhite from '../assets/SIOverviewExportIconWhite.svg';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import Filter001 from '../assets/Filter/Filter001.svg';
import Filter002 from '../assets/Filter/Filter002.svg';
import Filter003 from '../assets/Filter/Filter003.svg';
import Filter004 from '../assets/Filter/Filter004.svg';
import Filter005 from '../assets/Filter/Filter005.svg';
import Filter006 from '../assets/Filter/Filter006.svg';
import Filter007 from '../assets/Filter/Filter007.svg';
import Filter008 from '../assets/Filter/Filter008.svg';
import Filter009 from '../assets/Filter/Filter009.svg';
import Filter010 from '../assets/Filter/Filter010.svg';
import Filter011 from '../assets/Filter/Filter011.svg';
import Filter012 from '../assets/Filter/Filter012.svg';


export function StateButton(props) {
  // startIcon : button left Icon
  // EndIcon : button right Icon
  // titleName : button title name
  // disabled : if true, button will use disabled style
  // sx : you can use it to DIY your own Button
  // allowCompression : if true, when the window is compressed, the button will Compress. Default: middle page about 900 px
  // downBy : change where the button begin to compress
  // isPrimary : if true, button use primary style
  // onlyDefault : the button without hover and active style
  // themeSize : a large size button, used in SI detail
  // isText : if true, button use text style, like Back Button;
  // isInnerText: if true ,button use inner text style, like viewmore button;
  const {
    startIcon,
    endIcon,
    titleName,
    disabled = false,
    isPrimary = true,
    allowCompression = false,
    downBy,
    sx,
    onlyDefault = false,
    themeSize = false,
    isText = false,
    isInnerText = false,
    filterNumber = 0,
    disabledThemeColor,
    ...other
  } = props;
  const theme = useTheme();
  const isSmallerThanMedScreen = useMediaQuery(
    theme.breakpoints.down(downBy ?? 'md')
  );
  const disabledColor = disabledThemeColor??theme.palette.action.disabled;

  const GhostButton = styled(Button)(() => ({
    border: '1px solid #0052CC',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    color: '#0052CC',
    '&:hover': onlyDefault===false?{
      border: '1px solid #6D98EE',
      backgroundColor: '#FFFFFF',
      color: '#6D98EE',
      boxShadow: 'none',
      'svg path': {
        fill: '#6D98EE',
      },
    }:{
      border: '1px solid #0052CC',
      backgroundColor: '#FFFFFF',
      color: '#0052CC',
      boxShadow: 'none',
    },
    '&:active': onlyDefault===false?{
      border: '1px solid #AEC6F6',
      backgroundColor: '#E8EDFB',
      color: '#AEC6F6',
      boxShadow: 'none',
      'svg path': {
        fill: '#AEC6F6',
      },
    }:{
      border: '1px solid #0052CC',
      backgroundColor: '#FFFFFF',
      color: '#0052CC',
      boxShadow: 'none',
    },
    '&:disabled':{
      border: '1px solid  #98A7B6',
      'svg path': {
        fill: disabledColor,
      },
    },
  }));

  const PrimaryButton = styled(Button)(() => ({
    backgroundColor: '#0052CC',
    boxShadow: 'none',
    color: '#FFFFFF',
    '&:hover': onlyDefault===false?{
      backgroundColor: '#6D98EE',
      boxShadow: 'none',
      'svg path': {
        fill: '#6D98EE',
      },
    }:{
      backgroundColor: '#0052CC',
      boxShadow: 'none',
    },
    '&:active': onlyDefault===false?{
      backgroundColor: '#113D95',
      boxShadow: 'none',
      'svg path': {
        fill: '#113D95',
      }
    }:{
      backgroundColor: '#0052CC',
      boxShadow: 'none',
    },
    '&:disabled':{
      color:disabledColor,
      'svg path': {
        fill: disabledColor,
      },
    },
  }));

  const TextStateButton = styled(Button)(() => ({
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    fontWeight: '400',
    color: '#154AB6',
    cursor: 'pointer',
    '&:hover': onlyDefault===false?{
      backgroundColor: '#DFE4E8',
      fontWeight: '600',
      boxShadow: 'none',
      // 'svg path': {
      //   fill: '#6D98EE',
      // },
    }:{
      backgroundColor: '#FFFFFF',
      fontWeight: '400',
      boxShadow: 'none',
    },
    '&:active': onlyDefault===false?{
      backgroundColor: '#DFE4E8',
      fontWeight: '600',
      boxShadow: 'none',
      // 'svg path': {
      //   fill: '#AEC6F6',
      // },

    }:{
      backgroundColor: '#FFFFFF',
      fontWeight: '400',
      boxShadow: 'none',
    },
    '&:disabled':{
      color: '#BCC5CF',
      backgroundColor: '#FFFFFF',
      // 'svg path': {
      //   fill: disabledColor,
      // },
    },
  }));

  const InnerTextStateButton = styled(Button)(() => ({
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    fontWeight: '400',
    color: '#154AB6',
    cursor: 'pointer',
    height: '9px',
    '&:hover': onlyDefault===false?{
      backgroundColor: '#FFFFFF',
      fontWeight: '600',
      boxShadow: 'none',
      // color:'#113D95',
      'svg path': {
        fill: '#113D95',
      },
    }:{
      backgroundColor: '#FFFFFF',
      fontWeight: '400',
      boxShadow: 'none',
    },
    '&:active': onlyDefault===false?{
      backgroundColor: '#FFFFFF',
      fontWeight: '600',
      boxShadow: 'none',
      // color:'#113D95',
      'svg path': {
        fill: '#113D95',
      },

    }:{
      backgroundColor: '#FFFFFF',
      fontWeight: '400',
      boxShadow: 'none',
    },
    '&:disabled':{
      color: '#BCC5CF',
      backgroundColor: '#FFFFFF',
      'svg path': {
        fill: disabledColor,
      },
    },

  }));

  const getFilterIcon = () => {
    if (filterNumber === 0) {
      return endIcon;
    }
    switch (filterNumber) {
      case 1:
        return <Filter001 style={{ width: '14px', height: '14px'}}/>;
      case 2:
        return <Filter002 style={{ width: '14px', height: '14px'}}/>;
      // Add the remaining cases for values 3 to 12
      case 3:
        return <Filter003 style={{ width: '14px', height: '14px'}}/>;
      case 4:
        return <Filter004 style={{ width: '14px', height: '14px'}}/>;
      case 5:
        return <Filter005 style={{ width: '14px', height: '14px'}}/>;
      case 6:
        return <Filter006 style={{ width: '14px', height: '14px'}}/>;
      case 7:
        return <Filter007 style={{ width: '14px', height: '14px'}}/>;
      case 8:
        return <Filter008 style={{ width: '14px', height: '14px'}}/>;
      case 9:
        return <Filter009 style={{ width: '14px', height: '14px'}}/>;
      case 10:
        return <Filter010 style={{ width: '14px', height: '14px'}}/>;
      case 11:
        return <Filter011 style={{ width: '14px', height: '14px'}}/>;
      // ...
      case 12:
        return <Filter012 style={{ width: '14px', height: '14px'}}/>;
      default:
        return endIcon; // Return a default value if 'a' doesn't match any cases
    }
  };

  return isPrimary === true && isText === false && isInnerText === false? (
    <PrimaryButton
      disableRipple = {true}
      id={`StateButtonForPrimaryButton${titleName}`}
      variant="contained"
      data-selenium-id={`State_Button_For_Primary_Button_${titleName}`}
      disabled = {disabled}
      startIcon={allowCompression === true && isSmallerThanMedScreen ? null : startIcon}
      endIcon={allowCompression === true && isSmallerThanMedScreen ? null : getFilterIcon()}

      {...other}
      sx={{
        fontWeight: '600',
        fontSize: themeSize === true || !(startIcon||endIcon)?'14px':'12px',
        lineHeight: themeSize === true?'17px':'18px',
        gap:'6px',
        paddingTop: themeSize === true?'12px':'8px',
        paddingBottom: themeSize === true?'12px':'8px',
        paddingLeft: themeSize === true?'18px':'12px',
        paddingRight: themeSize === true?'18px':'12px',
        borderRadius: '5px',
        textTransform: 'capitalize',
        '& .MuiButton-startIcon': {
          margin:'0px',
          p:'0px'
        },
        '& .MuiButton-endIcon': {
          margin:'0px',
          p:'0px'

        },
        '& .MuiButton-label': {
          margin:'0px',
          p:'0px',
          textAlign: 'center',

        },
        ...sx,
      }}



      {...other}
    >
      {allowCompression === true && isSmallerThanMedScreen ? (startIcon || endIcon || titleName) : titleName}
    </PrimaryButton>
  ):
    isText === true?(
      <TextStateButton
        disableRipple
        id={`StateButtonForTextButton${titleName}`}
        variant="contained"
        data-selenium-id={`State_Button_For_Text_Button_${titleName}`}
        disabled = {disabled}
        startIcon={allowCompression === true && isSmallerThanMedScreen ? null : startIcon}
        endIcon={allowCompression === true && isSmallerThanMedScreen ? null : endIcon}
        {...other}
        sx={{
          fontSize:'14px',
          lineHeight:'20px',
          gap:'10px',
          paddingTop: '6px',
          paddingBottom: '6px',
          paddingLeft: '12px',
          paddingRight: '12px',
          borderRadius: '4px',
          textTransform: 'capitalize',
          boxShadow: 'none',
          '& .MuiButton-startIcon': {
            margin:'0px',
            p:'0px'
          },
          '& .MuiButton-endIcon': {
            margin:'0px',
            p:'0px'

          },
          '& .MuiButton-label': {
            margin:'0px',
            p:'0px',
            textAlign: 'center',

          },
          ...sx,
        }}
        {...other}
      >
        {allowCompression === true && isSmallerThanMedScreen ? (startIcon || endIcon || titleName) : titleName}
      </TextStateButton>

    ):
      isInnerText === true?(
        <InnerTextStateButton
          disableRipple
          id={`StateButtonForInnerTextButton${titleName}`}
          variant="contained"
          data-selenium-id={`State_Button_For_Inner_Text_Button_${titleName}`}
          disabled = {disabled}
          startIcon={allowCompression === true && isSmallerThanMedScreen ? null : startIcon}
          endIcon={allowCompression === true && isSmallerThanMedScreen ? null : endIcon}
          {...other}
          sx={{
            fontSize:'12px',
            lineHeight: '16px',
            gap:'2px',
            p: 0,
            textTransform: 'capitalize',
            boxShadow: 'none',
            '& .MuiButton-startIcon': {
              margin:'0px',
              p:'0px'
            },
            '& .MuiButton-endIcon': {
              margin:'0px',
              p:'0px'

            },
            '& .MuiButton-label': {
              margin:'0px',
              p:'0px',
              textAlign: 'center',

            },
            ...sx,
          }}
          {...other}
        >
          {allowCompression === true && isSmallerThanMedScreen ? (startIcon || endIcon || titleName) : titleName}

        </InnerTextStateButton>

      ):(

        <GhostButton
          disableRipple
          id={`StateButtonForGhostButton${titleName}`}
          variant="contained"
          data-selenium-id={`State_Button_For_Ghost_Button_${titleName}`}
          disabled = {disabled}
          startIcon={allowCompression === true && isSmallerThanMedScreen ? null : startIcon}
          endIcon={allowCompression === true && isSmallerThanMedScreen ? null : endIcon}
          {...other}
          sx={{
            fontWeight: '600',
            fontSize: themeSize === true || !(startIcon||endIcon)?'14px':'12px',
            lineHeight: themeSize === true?'17px':'18px',
            gap:'6px',
            paddingTop: themeSize === true?'12px':'8px',
            paddingBottom: themeSize === true?'12px':'8px',
            paddingLeft: themeSize === true?'18px':'12px',
            paddingRight: themeSize === true?'18px':'12px',
            borderRadius: '5px',
            textTransform: 'capitalize',
            boxShadow: 'none',
            '& .MuiButton-startIcon': {
              margin:'0px',
              p:'0px'
            },
            '& .MuiButton-endIcon': {
              margin:'0px',
              p:'0px'

            },
            '& .MuiButton-label': {
              margin:'0px',
              p:'0px',
              textAlign: 'center',

            },
            ...sx,
          }}
          {...other}
        >
          {allowCompression === true && isSmallerThanMedScreen ? (startIcon || endIcon || titleName) : titleName}
        </GhostButton>
    );

};



export function TestStateButton(props){
  return(
    <Stack direction="column" spacing={1} sx={{margin:'5px'}}>
      <Box>
        <StateButton
          startIcon = {<SIOverviewFilterIcon1></SIOverviewFilterIcon1>}
          endIcon = {<SIOverviewFilterIcon2_113D95></SIOverviewFilterIcon2_113D95>}
          titleName = "GButton DIcon"
          isPrimary = {false}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          endIcon = {<SIOverviewFilterIcon2_113D95></SIOverviewFilterIcon2_113D95>}
          titleName = "GButton LIcon"
          isPrimary = {false}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          startIcon = {<SIOverviewFilterIcon2_6D98EE></SIOverviewFilterIcon2_6D98EE>}
          titleName = "GButton RIcon"
          isPrimary = {false}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          titleName = "GButton NIcon"
          isPrimary = {false}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          startIcon = {<SIOverviewFilterIcon2_6D98EE></SIOverviewFilterIcon2_6D98EE>}
          endIcon = {<SIOverviewFilterIcon2_113D95></SIOverviewFilterIcon2_113D95>}
          titleName = "GButton Disable"
          isPrimary = {false}
          disabled = {true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          startIcon = {<SIOverviewFilterIcon2_6D98EE></SIOverviewFilterIcon2_6D98EE>}
          endIcon = {<SIOverviewFilterIcon2_113D95></SIOverviewFilterIcon2_113D95>}
          titleName = "PButton DIcon"
          isPrimary = {true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          startIcon = {<SIOverviewFilterIcon2_6D98EE></SIOverviewFilterIcon2_6D98EE>}
          titleName = "PButton LIcon"
          isPrimary = {true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          endIcon = {<SIOverviewFilterIcon2_113D95></SIOverviewFilterIcon2_113D95>}
          titleName = "PButton RIcon"
          isPrimary = {true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          titleName = "PButton NIcon"
          isPrimary = {true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          startIcon = {<SIOverviewFilterIcon2_6D98EE></SIOverviewFilterIcon2_6D98EE>}
          endIcon = {<SIOverviewFilterIcon2_113D95></SIOverviewFilterIcon2_113D95>}
          titleName = "PButton Disable"
          isPrimary = {true}
          disabled = {true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          startIcon = {<SIOverviewFilterIcon2_6D98EE></SIOverviewFilterIcon2_6D98EE>}
          endIcon = {<SIOverviewExportIconWhite></SIOverviewExportIconWhite>}
          titleName = "Compression DIcon"
          isPrimary = {true}
          allowCompression ={true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          startIcon = {<SIOverviewFilterIcon2_6D98EE></SIOverviewFilterIcon2_6D98EE>}
          titleName = "Compression LIcon"
          isPrimary = {true}
          allowCompression ={true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          endIcon = {<SIOverviewFilterIcon1></SIOverviewFilterIcon1>}
          titleName = "Compression RIcon"
          isPrimary = {false}
          allowCompression ={true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          endIcon = {<SIOverviewFilterIcon1></SIOverviewFilterIcon1>}
          titleName = "Compression RIcon"
          isPrimary = {false}
          allowCompression ={true}
          sx={{minWidth:'156px'}}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          titleName = "Compression NIcon "
          isPrimary = {true}
          allowCompression ={true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          startIcon = {<SIOverviewFilterIcon2_6D98EE></SIOverviewFilterIcon2_6D98EE>}
          endIcon = {<SIOverviewExportIconWhite></SIOverviewExportIconWhite>}
          titleName = "Compression DownBy 300"
          isPrimary = {true}
          allowCompression ={true}
          downBy = {300}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          startIcon = {<SIOverviewFilterIcon2_6D98EE></SIOverviewFilterIcon2_6D98EE>}
          endIcon = {<SIOverviewExportIconWhite></SIOverviewExportIconWhite>}
          titleName = "PButton DIY sx"
          isPrimary = {true}
          sx ={{
            paddingLeft: '0px',
            paddingTop: '20px',
            backgroundColor:"#333333",
            '&:hover': {
                backgroundColor: '#FFFFFF',
                boxShadow: 'none',
                color: '#333333',
              },
              '&:active': {
                backgroundColor: '#111111',
                boxShadow: 'none',

              },

          }}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          startIcon = {<SIOverviewFilterIcon1></SIOverviewFilterIcon1>}
          endIcon = {<SIOverviewFilterIcon2_113D95></SIOverviewFilterIcon2_113D95>}
          titleName = "GButton onlyDefault"
          isPrimary = {false}
          onlyDefault = {true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          startIcon = {<SIOverviewFilterIcon1></SIOverviewFilterIcon1>}
          endIcon = {<SIOverviewFilterIcon2_113D95></SIOverviewFilterIcon2_113D95>}
          titleName = "PButton onlyDefault"
          isPrimary = {true}
          onlyDefault = {true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          titleName = "Next"
          isPrimary = {true}
          themeSize = {true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          titleName = "Next"
          isPrimary = {true}
          themeSize = {true}
          disabled = {true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          titleName = "Next"
          isPrimary = {false}
          themeSize = {true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          titleName = "Next"
          isPrimary = {false}
          themeSize = {true}
          disabled = {true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          titleName = "back"
          isText = {true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          titleName = "back"
          isText ={true}
          disabled = {true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          titleName = "ViewMore"
          isInnerText ={true}
          disabled = {true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          startIcon = {<SIOverviewFilterIcon1></SIOverviewFilterIcon1>}
          endIcon = {<SIOverviewFilterIcon2_113D95></SIOverviewFilterIcon2_113D95>}
          titleName = "Creat New Table"
          isInnerText ={true}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          startIcon = {<SIOverviewFilterIcon1></SIOverviewFilterIcon1>}
          titleName = "back"
          isInnerText ={true}
        ></StateButton>
      </Box>

      <Box>
        <StateButton
          titleName = "Filter"
          filterNumber = {1}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          titleName = "Filter"
          filterNumber = {3}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          titleName = "Filter"
          filterNumber = {5}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          titleName = "Filter"
          filterNumber = {7}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          titleName = "Filter"
          filterNumber = {9}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          titleName = "Filter"
          filterNumber = {11}
        ></StateButton>
      </Box>
      <Box>
        <StateButton
          titleName = "Filter"
          filterNumber = {12}
          disabled = {true}
        ></StateButton>
      </Box>


    </Stack>
  );
}

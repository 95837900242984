import {
  H4Font,
  SmallBoldFont,
  SmallFont,
} from '../../../components/FontStyle';
export const sip_overview_container = {
  padding: '11px 32px',
  backgroudColor: 'rgb(250,250,250)',
};

export const sip_overview_head = {
  marginBottom: '16px',
};

export const sip_overview_head_title = {
  ...H4Font,
  color: '#262E35',
  // marginBottom: '16px',
  fontSize: '22px',
};

export const sip_overview_head_btnBox = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  height: '34px',
  minWidth: '840px',
  marginBottom: '19px',
};

export const sip_overview_head_btnBox_left = {
  float: 'left',
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '4px',
};

export const sip_overview_head_btnBox_right = {
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '8px',
  paddingRight: '30px',
};

export const disable_input = {
  boxSizing: 'border-box',
  width: '404px',
  height: '32px',
  background: '#FFFFFF',
  border: '1px solid #BCC5CF',
  borderRadius: '5px',
  //font style （ui 未提供）
};

export const disable_select = {
  display: 'flex',
  flexDirection: ' row',
  alignItems: 'center',
  padding: '6px 12px',
  width: '164px',
  height: '32px',
  boxSizing: 'border-box',
  background: ' #FFFFFF',
  border: '1px solid #BCC5CF',
  borderRadius: '5px',
};
export const disable_select_value = {
  width: '120px',
  height: '20px',
  ...SmallBoldFont,
  letterSpacing: '0.01em',
  color: '#BCC5CF',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginRight: '8px',
};

export const start_endAttachment = {
  height: '20px',
  boxSizing: 'border-box',
  padding: '2px 0px',
};

export const disable_search_button = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 12px',
  gap: ' 4px',
  height: '32px',
  background: ' #BCC5CF',
  borderRadius: '5px',
  boxSizing: 'border-box',
};

export const disable_button_value = color => {
  return {
    ...SmallBoldFont,
    textAlign: 'center',
    letterSpacing: '0.01em',
    color: color,
    height: '20px',
  };
};

export const disable_filter_button = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '6px 12px',
  gap: ' 4px',
  height: '32px',
  background: '#FFFFFF',
  border: '1px solid #BCC5CF',
  borderRadius: '5px',
  boxSizing: 'border-box',
};

export const disable_columns_button = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '6px 12px',
  gap: ' 6px',
  height: '32px',
  background: '#FFFFFF',
  border: '1px solid #BCC5CF',
  borderRadius: '5px',
  boxSizing: 'border-box',
};

/**
 * @type {import("@mui/material").SxProps}
 */
export const responsive_button = {
  minWidth: 'auto',
  whiteSpace: 'nowrap',
  border: '1px solid #DFE4E8',
  height: '32px',
  color: '#596A7C',
};

export const sip_overview_main = {};

export const sip_overview_main_checkBox = {
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '8px',
  width: '280px',
  marginLeft: '8px',
  paddingTop:'8px',
};

export const sip_overview_main_checkBox_checkBox = {
  padding: 0,
  width: '18px',
  height: '18px',
};

export const sip_overview_main_checkBox_label = {
  ...SmallFont,
  color: '#484848',
  height: '18px',
  lineHeight: '125%',
};

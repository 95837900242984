import { Drawer, Box, Backdrop } from '@mui/material';
import { useSelector } from 'react-redux';
import Overview from './Overview';
import { useEffect } from 'react';
export default function NewSIFilterDrawer(props) {
  const selector = state => {
    return {
      filterOpen: state.GE.filterOpen,
    };
  };

  const { filterOpen } = useSelector(selector);

  useEffect(() => {
    if (filterOpen) {
      document.body.classList.add('hide-scroll');
    } else {
      document.body.classList.remove('hide-scroll');
    }
  }, [filterOpen]);

  return (
    <Box
      sx={{
        width: '100%',
      }}
      data-selenium-id='GEPage_Overview-anotherNewFilterBox'
    >
      <Backdrop
        id='SIPageOverviewAnotherNewFilterDrawerBackdrop'
        data-selenium-id={'GEPage_Overview-anotherNewFilterDrawerBackdrop'}
        sx={{
          zIndex: 1400,
          backdropFilter: 'blur(4px)',
          background: '#113D9580',
        }}
        open={filterOpen}
        backdropFilter='blur(4px)'
      ></Backdrop>
      <Drawer
        id='SIPageOverviewAnotherNewFilterDrawer'
        variant='persistent'
        anchor='right'
        scrollY='auto'
        open={filterOpen}
        sx={{
          flexShrink: 0,
          ['& .MuiDrawer-paper']: {
            width: '924px',
            zIndex: 1400,
            backgroundColor: '#FFFFFF',
          },
        }}
        data-selenium-id={'GEPage_Overview-anotherNewFilterDrawer'}
      >
        <Overview></Overview>
      </Drawer>
    </Box>
  );
}

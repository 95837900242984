import Number1 from '@/assets/Filter/Filter001.svg';
import Number2 from '@/assets/Filter/Filter002.svg';
import Number3 from '@/assets/Filter/Filter003.svg';
import Number4 from '@/assets/Filter/Filter004.svg';
import Number5 from '@/assets/Filter/Filter005.svg';
import Number6 from '@/assets/Filter/Filter006.svg';
import Number7 from '@/assets/Filter/Filter007.svg';
import Number8 from '@/assets/Filter/Filter008.svg';
import Number9 from '@/assets/Filter/Filter009.svg';
import Number10 from '@/assets/Filter/Filter010.svg';
import Number11 from '@/assets/Filter/Filter011.svg';
import Number12 from '@/assets/Filter/Filter012.svg';

export default function NumberIcon(props) {
  const { number, iconSX = {} } = props;
  switch (number) {
    case 1:
      return <Number1 style={iconSX} />;
    case 2:
      return <Number2 style={iconSX} />;
    case 3:
      return <Number3 style={iconSX} />;
    case 4:
      return <Number4 style={iconSX} />;
    case 5:
      return <Number5 style={iconSX} />;
    case 6:
      return <Number6 style={iconSX} />;
    case 7:
      return <Number7 style={iconSX} />;
    case 8:
      return <Number8 style={iconSX} />;
    case 9:
      return <Number9 style={iconSX} />;
    case 10:
      return <Number10 style={iconSX} />;
    case 11:
      return <Number11 style={iconSX} />;
    case 12:
      return <Number12 style={iconSX} />;
    default:
      return null;
  }
}

import WileyLogo from '../../../../assets/LoginAsset/WileyLogo.svg';
import styled from '@emotion/styled';
import { useState, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { validClear } from '../../../../actions/SIMT-User/Login/LoginAction';

import EncryptedTextField from '../Components/EncryptedTextField';
import { set } from 'date-fns';
import {checkLinkValid, resetPassForget} from "@/actions/SIMT-User/Login";

const NewPassWordTitleTypography = styled(Typography)({
  color: 'var(--gray-500, #596A7C)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '48px',
  fontFamily: 'Open Sans',
  fontWeight: '800',
  lineHeight: '125%',
  letterSpacing: '0.48px',
});

const InfoTypography = styled(Typography)({
  color: 'var(--gray-300, #BCC5CF)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  lineHeight: '140%',
});

const InfoTypography2 = styled(Typography)({
  color: 'var(--gray-300, #BCC5CF)',
  fontSize: '14px',
  fontFamily: 'Open Sans',
  lineHeight: '20px',
});

const InfoTypography2Red = styled(Typography)({
  color: 'var(--error-600, #EE5350)',
  fontSize: '14px',
  fontFamily: 'Open Sans',
  lineHeight: '20px',
});

const LabelTypography = styled(Typography)({
  color: 'var(--gray-500, #596A7C)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  lineHeight: '125%',
});

const ResetButton = styled(Button)({
  borderRadius: '5px',
  background: 'var(--primary-500, #0052CC)',
  color: 'var(--shades-white, #FFF)',
  textAlign: 'center',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  lineHeight: '18px',
  width: '400px',
  height: '40px',
  padding: '8px 12px',
  gap: '6px',
  '&:hover': {
    background: 'var(--primary-300, #6D98EE)',
  },

  '&:focus': {
    background: 'var(--primary-600, #113D95)',
  },

  '&:disabled': {
    background: 'var(--gray-200, #DFE4E8)',
  },
});

const CreateNewPasswordPage = () => {
  const location = useLocation();
  const { email, digitalSignature } = location.state;


  const dispatch = useDispatch();

  const selector = state => {
    return {
      linkValid: state.Login.linkValid,
      resetPass: state.Login.resetPass,
    };
  };
  const { resetPass } = useSelector(selector);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(checkLinkValid(digitalSignature, email));
    };
    fetchData();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(validClear());
    };
  }, []);



  useEffect(() => {
    if (resetPass) {
      navigate('/simt/ResetSuccess', {
        state: {
          email: email,
          password: newPassword,
        },
      });
    }
  }, [resetPass]);

  // Check Password valid

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  //error => true
  function testPassword(password) {
    //判断密码至少由3种字符组成：1.数字 2 小写字母 3 大写字母 4 特殊字符
    var regNumber = new RegExp(/.*[0-9]+.*/);
    var regUpper = new RegExp(/.*[A-Z]+.*/);
    var regLower = new RegExp(/.*[a-z]+.*/);
    var regSymbol = new RegExp(/.*[\x21-\x2f,\x3a-\x40\x5b-\x60\x7b-\x7e]+.*/);
    var count = 0;
    if (regNumber.test(password)) {
      count++;
    }
    if (regUpper.test(password)) {
      count++;
    }
    if (regLower.test(password)) {
      count++;
    }
    if (regSymbol.test(password)) {
      count++;
    }
    //判断密码位数必须大于8位
    if (password.length < 8 || password.length > 32 || count < 3) {
      return true;
    } else {
      return false;
    }
  }

  //error => true
  const testConfirmPassword = (password, confirmPassword) => {
    return password !== confirmPassword;
  };

  const navigate = useNavigate();

  const [errorShow, setErrorShow] = useState(false);

  const [newPasswordFocus, setNewPasswordFocus] = useState(false);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);

  const newPasswordHelperTextShow =
    testPassword(newPassword) && errorShow && !newPasswordFocus;
  const confirmPasswordHelperTextShow =
    testConfirmPassword(newPassword, confirmPassword) &&
    errorShow &&
    !confirmPasswordFocus;

  const handleReset = () => {
    setErrorShow(true);
    if (
      testPassword(newPassword) ||
      testConfirmPassword(newPassword, confirmPassword)
    )
      return;
    const passwordToSet = newPassword;
    const emailAddress = email;
    const digitalSignatureToSet = digitalSignature;
    dispatch(
      resetPassForget(digitalSignatureToSet, emailAddress, passwordToSet)
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        background: '#FFF',
      }}
      data-selenium-id='Login_NewLogin_CreateNewPasswordPage'
    >
      <div
        style={{
          width: '311px',
          height: '100%',
          background: '#0052CC',
        }}
        data-selenium-id='Login_NewLogin_CreateNewPasswordPage-LeftPart'
      >
        <WileyLogo
          style={{
            marginLeft: '16px',
            marginTop: '16px',
          }}
        />
      </div>
      <div
        style={{
          width: 'calc(100% - 311px)',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
        data-selenium-id='Login_NewLogin_CreateNewPasswordPage-RightPart'
      >
        <div
          style={{
            marginTop: '148px',
          }}
          data-selenium-id='Login_NewLogin_CreateNewPasswordPage-RightPart-Content'
        >
          <div data-selenium-id='Login_NewLogin_CreateNewPasswordPage-CreateNewPasswordTitle'>
            <NewPassWordTitleTypography>
              Create new password
            </NewPassWordTitleTypography>
          </div>
          <div
            style={{ marginTop: '20px', width: '553px' }}
            data-selenium-id='Login_NewLogin_CreateNewPasswordPage-InfoText'
          >
            <InfoTypography>
              Please create a new password that is distinct from any previously
              used passwords.
            </InfoTypography>
          </div>
          <div
            style={{ marginTop: '30px' }}
            data-selenium-id='Login_NewLogin_CreateNewPasswordPage-newPasswordLabel'
          >
            <LabelTypography>New Password</LabelTypography>
          </div>
          <div
            style={{ marginTop: '12px' }}
            data-selenium-id='Login_NewLogin_CreateNewPasswordPage-newPasswordInput'
          >
            <EncryptedTextField
              placeholder='Enter your new password'
              isError={newPasswordHelperTextShow}
              value={newPassword}
              setValue={setNewPassword}
              height='40px'
              width='400px'
              onFocus={() => {
                setNewPasswordFocus(true);
              }}
              onBlur={() => {
                setNewPasswordFocus(false);
              }}
            />
          </div>
          <div
            style={{ marginTop: '4px', width: '400px' }}
            data-selenium-id='Login_NewLogin_CreateNewPasswordPage-newPasswordInfoText'
          >
            <InfoTypography2
              style={{
                color: newPasswordHelperTextShow
                  ? 'var(--error-600, #EE5350)'
                  : 'var(--gray-300, #BCC5CF)',
              }}
            >
              Password length must be at least 8 characters long and no more than 32 characters long, including 3 of the
              following: uppercase letters, lowercase letters, digits, and
              special characters.
            </InfoTypography2>
          </div>
          <div
            style={{ marginTop: '32px' }}
            data-selenium-id='Login_NewLogin_CreateNewPasswordPage-confirmPasswordLabel'
          >
            <LabelTypography>Confirm Password</LabelTypography>
          </div>
          <div
            style={{ marginTop: '12px' }}
            data-selenium-id='Login_NewLogin_CreateNewPasswordPage-confirmPasswordInput'
          >
            <EncryptedTextField
              placeholder='Re-enter your password'
              isError={confirmPasswordHelperTextShow}
              value={confirmPassword}
              setValue={setConfirmPassword}
              height='40px'
              width='400px'
              onFocus={() => {
                setConfirmPasswordFocus(true);
              }}
              onBlur={() => {
                setConfirmPasswordFocus(false);
              }}
            />
          </div>
          <div data-selenium-id='Login_NewLogin_CreateNewPasswordPage-confirmPasswordInfoText'>
            <InfoTypography2Red
              style={{
                marginTop: '4px',
                display: confirmPasswordHelperTextShow ? 'block' : 'none',
              }}
            >
              The passwords you entered don't match. Please try again.
            </InfoTypography2Red>
            <div
              style={{
                display: !confirmPasswordHelperTextShow ? 'block' : 'none',
                height: '20px',
                marginTop: '4px',
              }}
            ></div>
          </div>
          <div
            style={{ marginTop: '45px' }}
            data-selenium-id='Login_NewLogin_CreateNewPasswordPage-SendButton'
          >
            <ResetButton
              onClick={handleReset}
              disabled={
                newPasswordHelperTextShow || confirmPasswordHelperTextShow
              }
              disableRipple={true}
            >
              RESET PASSWORD
            </ResetButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewPasswordPage;

import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormControlLabel,
  Grid,
  InputLabel,
  Stack,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import ReportExportMenuButton from '../../ReportSpecificComponents/ReportExportMenuButton';
import FYSubjectGroupTable from './FYSubjectGroupTable';
import FYBasicTable from './FYBasicTable';
import FYBusinessDivisionTable from './FYBusinessDivisionTable';
import { StyledSwitchTwoColor } from '../../ReportSpecificComponents/SpecialColorSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '../../../../components/DataTable';
import {
  // fyChangeFiscalYear,
  fySetBusinessShowAll,
  fySetSubjectShowAll,
  fyExportFilter,
  fyExportCSVFilter,
} from '../../../../actions/SIMT-Report/ReportArticle/ReportArticleAction';
import { selectYear as fyChangeFiscalYear } from '../../../../actions/SIMT-Report/Report/ReportAction';
import FYFilterDrawer from './NewFYFilterDrawer';
import {
  PurpleGreenCell,
  PurpleGreenDisCell,
  StyledTableCellDisGray,
  TableGroupBox,
  TableLabel,
} from '../../ReportSpecificComponents/SpecialColorTableHeader';
import monthIds from '../../monthIds';
import { Note } from '../Note';
import { FYSelectStack, GenerationDate } from '../../GenerationDate';
import { getFyYearFromDate } from '../../../../utils/date_string';
import ExportAllDialog from '../../ExportAllDialog';
import * as PropTypes from 'prop-types';
import styles from '../../header-switch.module.scss';
import {GetAcquiredActiveLastActionDateFY} from "@/actions/SIMT-Report/Report/ReportAcquiredAndActive";
import {
    fyExport,
    fyExportCSV,
    fyExportCSVWithFilenameFromBackEnd,
    fyExportWithFilenameFromBackEnd
} from "@/actions/SIMT-Report/ReportArticle/Export";

export function CommonHeaderFirstRow(props) {
  const {
    left,
    orderByType = { orderBy: null, order: null },
    handleChangeOrder = null,
    isBasic,
    isOA,
    tableName = '',
    columnWidthMap,
    columnMinWidthMap,
  } = props;

  function calcGroupWidth(type) {
    let sum = 0;
    for (let item of monthIds) {
      sum += Math.max(
        (columnWidthMap[`${type}${item.post}`] ?? 0) + 46,
        columnWidthMap[`${type}${item.post}`] + 32
      );
    }
    sum +=
      Math.max(
        columnWidthMap[`${type}Total`] ?? 0,
        columnMinWidthMap[`${type}Total`]
      ) + 50;
    return sum;
  }

  return (
    <>
      {/*表头的几个部分*/}
      <TableRow data-selenium-id='Report_Articles-FYTrends-CommonHeaderFirstRow'>
        {left.map((item, index) => (
          <StyledTableCellDisGray
            id={`${item.id}${tableName}Header`}
            data-selenium-id={`${item.id}${tableName}Header`}
            isOA={isOA}
            key={item.label}
            align={item.align}
            rowSpan={2}
            className={
              index === left.length - 1 ? 'RightBottomBorder' : 'BottomBorder'
            }
            sx={{
              // minWidth: item.minWidth,
              // maxWidth: item.maxWidth,
              ...(index === 0 && {
                position: 'sticky',
                left: 0,
                top: 0,
                zIndex: 8,
                backgroundColor: '#DFE4E8',
              }),
              ...{
                width: `${
                  Math.max(
                    columnWidthMap[item.id] ?? 0,
                    columnMinWidthMap[item.id]
                  ) + 50
                }px`,
              },
              textTransform: 'uppercase',
            }}
            sortDirection={
              orderByType.orderBy === item.id ? orderByType.order : false
            }
          >
            {item.isOrder ? (
              <TableSortLabel
                id={`${item.id}${tableName}SortLabel`}
                data-selenium-id={`${item.id}${tableName}SortLabel`}
                active={orderByType.orderBy === item.id}
                direction={
                  orderByType.orderBy === item.id ? orderByType.order : 'asc'
                }
                onClick={() => {
                  if (item.isOrder) {
                    handleChangeOrder(item.id);
                  }
                }}
              >
                {item.label}
              </TableSortLabel>
            ) : (
              <>{item.label}</>
            )}
          </StyledTableCellDisGray>
        ))}
        <PurpleGreenCell
          isBasic={isBasic}
          isOA={isOA}
          colSpan={13}
          align='left'
          className='RightBottomBorder ReportHeaderFirstLine'
          data-selenium-id='Report_Articles-FYTrends-CommonHeaderFirstRow-PurpleGreenCell1'
          sx={{
            width: `${calcGroupWidth('sub')}px`,
          }}
        >
          <FormattedMessage id='report.numSub' />
        </PurpleGreenCell>
        <PurpleGreenCell
          isBasic={isBasic}
          isOA={isOA}
          colSpan={13}
          align='left'
          className='RightBottomBorder ReportHeaderFirstLine'
          data-selenium-id='Report_Articles-FYTrends-CommonHeaderFirstRow-PurpleGreenCell2'
          sx={{
            width: `${calcGroupWidth('acc')}px`,
          }}
        >
          <FormattedMessage id='report.numAcc' />
        </PurpleGreenCell>
        <PurpleGreenCell
          isBasic={isBasic}
          isOA={isOA}
          colSpan={13}
          align='left'
          className='BottomBorder ReportHeaderFirstLine'
          data-selenium-id='Report_Articles-FYTrends-CommonHeaderFirstRow-PurpleGreenCell3'
          sx={{
            width: `${calcGroupWidth('pub')}px`,
          }}
        >
          <FormattedMessage
            id='report.numPub'
            data-selenium-id='Report_Articles-FYTrends-CommonHeaderFirstRow-ReportNumPub'
          />
        </PurpleGreenCell>
      </TableRow>
    </>
  );
}

export function CommonHeaderSecondRow(props) {
  const intl = useIntl();
  const {
    isOA,
    isBasic,
    orderByType = { orderBy: null, order: null },
    handleChangeOrder = null,
    columnWidthMap,
    columnMinWidthMap,
  } = props;
  return (
    <TableRow>
      {/*月份，在这个表中重复三次*/}
      {/*前两次是右下border加宽，最后一个是下border加宽*/}
      {monthIds.map((item, index) => (
        <PurpleGreenDisCell
          isBasic={isBasic}
          isOA={isOA}
          key={'sub-' + item.id}
          align='left'
          sx={{
            // top: 52.5,
            top: 42,
            width: `${Math.max(
              (columnWidthMap['sub' + item.post] ?? 0) + 46,
              columnWidthMap['sub' + item.post] + 38
            )}px`,
            fontSize: '12px',
          }}
          className='BottomBorder'
        >
          {/* <TableSortLabel
            id={`${item.id}SortLabel`}
            data-selenium-id={`${item.id}SortLabel`}
            active={orderByType.orderBy === `sub${item.post}`}
            direction={
              orderByType.orderBy === `sub${item.post}`
                ? orderByType.order
                : 'asc'
            }
            onClick={() => {
              handleChangeOrder(`sub${item.post}`);
            }}
          > */}

          <TableSortLabel
            id={`${item.id}SortLabel`}
            data-selenium-id={`Report_Articles-FYTrends-CommonHeaderSecondRow-${item.id}SortLabel`}
            active={false}
            direction={
              orderByType.orderBy === `sub${item.post}`
                ? orderByType.order
                : 'asc'
            }
            hideSortIcon={true}
          >
            {intl.messages[`month.${item.id}`]}
          </TableSortLabel>
        </PurpleGreenDisCell>
      ))}
      <PurpleGreenDisCell
        isBasic={isBasic}
        isOA={isOA}
        key={'sub-total'}
        className='RightBorder BottomBorder'
        sx={{
          // top: 52.5,
          top: 42,
          width: `${
            Math.max(
              columnWidthMap['subTotal'] ?? 0,
              columnMinWidthMap['subTotal']
            ) + 50
          }px`,
          fontSize: '12px',
        }}
      >
        {/* <TableSortLabel
          id={`sub-total-SortLabel`}
          active={orderByType.orderBy === `subTotal`}
          direction={
            orderByType.orderBy === `subTotal` ? orderByType.order : 'asc'
          }
          onClick={() => {
            handleChangeOrder(`subTotal`);
          }}
          sx={{ color: '#0e43a9' }}
        > */}

        <TableSortLabel
          id={'sub-total-SortLabel'}
          data-selenium-id={
            'Report_Articles-FYTrends-CommonHeaderSecondRow-SubTotal-SortLabel'
          }
          active={false}
          direction={
            orderByType.orderBy === 'subTotal' ? orderByType.order : 'asc'
          }
          // sx={{ color: '#0e43a9' }}
          hideSortIcon={true}
        >
          <strong>{intl.messages['report.Journal.total']}</strong>
        </TableSortLabel>
      </PurpleGreenDisCell>
      {monthIds.map((item, index) => (
        <PurpleGreenDisCell
          isBasic={isBasic}
          isOA={isOA}
          key={'acc-' + item.id}
          align='left'
          sx={{
            // top: 52.5,
            top: 42,
            width: `${
              Math.max(
                columnWidthMap['acc' + item.post] ?? 0,
                columnMinWidthMap['acc' + item.post]
              ) + 50
            }px`,
            fontSize: '12px',
          }}
          className='BottomBorder'
        >
          {/* <TableSortLabel
            id={`${item.id}SortLabel`}
            data-selenium-id={`${item.id}SortLabel`}
            active={orderByType.orderBy === `acc${item.post}`}
            direction={
              orderByType.orderBy === `acc${item.post}`
                ? orderByType.order
                : 'asc'
            }
            onClick={() => {
              handleChangeOrder(`acc${item.post}`);
            }}
          > */}
          <TableSortLabel
            id={`${item.id}SortLabel`}
            data-selenium-id={`Report_Articles-FYTrends-CommonHeaderSecondRow-${item.id}SortLabel`}
            active={false}
            direction={
              orderByType.orderBy === `acc${item.post}`
                ? orderByType.order
                : 'asc'
            }
            hideSortIcon={true}
          >
            {intl.messages[`month.${item.id}`]}
          </TableSortLabel>
        </PurpleGreenDisCell>
      ))}
      <PurpleGreenDisCell
        isOA={isOA}
        isBasic={isBasic}
        key={'acc-total'}
        className='RightBorder BottomBorder'
        sx={{
          // top: 52.5,
          top: 42,
          width: `${
            Math.max(
              columnWidthMap['accTotal'] ?? 0,
              columnMinWidthMap['accTotal']
            ) + 50
          }px`,
          fontSize: '12px',
        }}
      >
        {/* <TableSortLabel
          id={`acc-total-SortLabel`}
          active={orderByType.orderBy === `accTotal`}
          direction={
            orderByType.orderBy === `accTotal` ? orderByType.order : 'asc'
          }
          onClick={() => {
            handleChangeOrder(`accTotal`);
          }}
          sx={{ color: '#0e43a9' }}
        > */}

        <TableSortLabel
          id={'acc-total-SortLabel'}
          data-selenium-id={
            'Report_Articles-FYTrends-CommonHeaderSecondRow-AccTotalSortLabel'
          }
          active={false}
          direction={
            orderByType.orderBy === 'accTotal' ? orderByType.order : 'asc'
          }
          // sx={{ color: '#0e43a9' }}
          hideSortIcon={true}
        >
          <strong> {intl.messages['report.Journal.total']}</strong>
        </TableSortLabel>
      </PurpleGreenDisCell>
      {monthIds.map(item => (
        <PurpleGreenDisCell
          isOA={isOA}
          isBasic={isBasic}
          key={'pub-' + item.id}
          className='BottomBorder'
          sx={{
            // top: 52.5,
            top: 42,
            width: `${
              Math.max(
                columnWidthMap['pub' + item.post] ?? 0,
                columnMinWidthMap['pub' + item.post]
              ) + 50
            }px`,
            fontSize: '12px',
          }}
        >
          {/* <TableSortLabel
            id={`${item.id}SortLabel`}
            data-selenium-id={`${item.id}SortLabel`}
            active={orderByType.orderBy === `pub${item.post}`}
            direction={
              orderByType.orderBy === `pub${item.post}`
                ? orderByType.order
                : 'asc'
            }
            onClick={() => {
              handleChangeOrder(`pub${item.post}`);
            }}
          > */}

          <TableSortLabel
            id={`${item.id}SortLabel`}
            data-selenium-id={`Report_Articles-FYTrends-CommonHeaderSecondRow-${item.id}SortLabel`}
            active={false}
            direction={
              orderByType.orderBy === `pub${item.post}`
                ? orderByType.order
                : 'asc'
            }
            hideSortIcon={true}
          >
            {intl.messages[`month.${item.id}`]}
          </TableSortLabel>
        </PurpleGreenDisCell>
      ))}
      {/* 由于orderBy 和 handleChangeOrder 实际为null 所以这部分的id使用任意的字符串 */}
      <PurpleGreenDisCell
        isOA={isOA}
        isBasic={isBasic}
        key={'pub-total'}
        className='BottomBorder'
        sx={{
          // top: 52.5,
          top: 42,
          width: `${
            Math.max(
              columnWidthMap['pubTotal'] ?? 0,
              columnMinWidthMap['pubTotal']
            ) + 50
          }px`,
          fontSize: '12px',
        }}
      >
        {/* <TableSortLabel
          id={`pub-total-SortLabel`}
          active={orderByType.orderBy === `pubTotal`}
          direction={
            orderByType.orderBy === `pubTotal` ? orderByType.order : 'asc'
          }
          onClick={() => {
            handleChangeOrder(`pubTotal`);
          }}
          sx={{ color: '#0e43a9' }}
        > */}

        <TableSortLabel
          id={'pub-total-SortLabel'}
          data-selenium-id={
            'Report_Articles-FYTrends-CommonHeaderSecondRow-PubTotalSortLabel'
          }
          active={false}
          direction={
            orderByType.orderBy === 'pubTotal' ? orderByType.order : 'asc'
          }
          // sx={{ color: '#0e43a9' }}
          hideSortIcon={true}
        >
          <strong> {intl.messages['report.Journal.total']}</strong>
        </TableSortLabel>
      </PurpleGreenDisCell>
    </TableRow>
  );
}

export function BasicTableSecondRow(props) {
  const intl = useIntl();
  const {
    isOA,
    isBasic,
    orderByType = { orderBy: null, order: null },
    handleChangeOrder = null,
    columnWidthMap,
    columnMinWidthMap,
  } = props;
  return (
    <TableRow>
      {/*月份，在这个表中重复三次*/}
      {/*前两次是右下border加宽，最后一个是下border加宽*/}
      {monthIds.map((item, index) => (
        <PurpleGreenDisCell
          isBasic={isBasic}
          isOA={isOA}
          key={'sub-' + item.id}
          align='left'
          sx={{
            // top: 52.5,
            top: 42,
            width: `${Math.max(
              (columnWidthMap['sub' + item.post] ?? 0) + 46,
              columnWidthMap['sub' + item.post] + 38
            )}px`,
            fontSize: '12px',
          }}
          className='BottomBorder'
        >
          <TableSortLabel
            id={`${item.id}SortLabel`}
            data-selenium-id={`Report_Articles-FYTrends-BasicTableSecondRow-${item.id}SortLabel`}
            active={orderByType.orderBy === `sub${item.post}`}
            direction={
              orderByType.orderBy === `sub${item.post}`
                ? orderByType.order
                : 'asc'
            }
            onClick={() => {
              handleChangeOrder(`sub${item.post}`);
            }}
          >
            {intl.messages[`month.${item.id}`]}
          </TableSortLabel>
        </PurpleGreenDisCell>
      ))}
      <PurpleGreenDisCell
        isBasic={isBasic}
        isOA={isOA}
        key={'sub-total'}
        className='RightBorder BottomBorder'
        sx={{
          // top: 52.5,
          top: 42,
          width: `${
            Math.max(
              columnWidthMap['subTotal'] ?? 0,
              columnMinWidthMap['subTotal']
            ) + 50
          }px`,
          fontSize: '12px',
        }}
      >
        <TableSortLabel
          id={'sub-total-SortLabel'}
          data-selenium-id={
            'Report_Articles-FYTrends-BasicTableSecondRow-SubTotalSortLabel'
          }
          active={orderByType.orderBy === 'subTotal'}
          direction={
            orderByType.orderBy === 'subTotal' ? orderByType.order : 'asc'
          }
          onClick={() => {
            handleChangeOrder('subTotal');
          }}
          // sx={{ color: '#0e43a9' }}
        >
          <strong>{intl.messages['report.Journal.total']}</strong>
        </TableSortLabel>
      </PurpleGreenDisCell>
      {monthIds.map((item, index) => (
        <PurpleGreenDisCell
          isBasic={isBasic}
          isOA={isOA}
          key={'acc-' + item.id}
          align='left'
          sx={{
            // top: 52.5,
            top: 42,
            width: `${
              Math.max(
                columnWidthMap['acc' + item.post] ?? 0,
                columnMinWidthMap['acc' + item.post]
              ) + 50
            }px`,
            fontSize: '12px',
          }}
          className='BottomBorder'
        >
          <TableSortLabel
            id={`${item.id}SortLabel`}
            data-selenium-id={`Report_Articles-FYTrends-BasicTableSecondRow-${item.id}SortLabel`}
            active={orderByType.orderBy === `acc${item.post}`}
            direction={
              orderByType.orderBy === `acc${item.post}`
                ? orderByType.order
                : 'asc'
            }
            onClick={() => {
              handleChangeOrder(`acc${item.post}`);
            }}
          >
            {intl.messages[`month.${item.id}`]}
          </TableSortLabel>
        </PurpleGreenDisCell>
      ))}
      <PurpleGreenDisCell
        isBasic={isBasic}
        isOA={isOA}
        key={'acc-total'}
        className='RightBorder BottomBorder'
        sx={{
          // top: 52.5,
          top: 42,
          width: `${
            Math.max(
              columnWidthMap['accTotal'] ?? 0,
              columnMinWidthMap['accTotal']
            ) + 50
          }px`,
          fontSize: '12px',
        }}
      >
        <TableSortLabel
          id={'acc-total-SortLabel'}
          data-selenium-id={
            'Report_Articles-FYTrends-BasicTableSecondRow-AccTotalSortLabel'
          }
          active={orderByType.orderBy === 'accTotal'}
          direction={
            orderByType.orderBy === 'accTotal' ? orderByType.order : 'asc'
          }
          onClick={() => {
            handleChangeOrder('accTotal');
          }}
          // sx={{ color: '#0e43a9' }}
        >
          <strong>{intl.messages['report.Journal.total']}</strong>
        </TableSortLabel>
      </PurpleGreenDisCell>
      {monthIds.map(item => (
        <PurpleGreenDisCell
          isBasic={isBasic}
          isOA={isOA}
          key={'pub-' + item.id}
          className='BottomBorder'
          sx={{
            // top: 52.5,
            top: 42,
            width: `${
              Math.max(
                columnWidthMap['pub' + item.post] ?? 0,
                columnMinWidthMap['pub' + item.post]
              ) + 50
            }px`,
            fontSize: '12px',
          }}
        >
          <TableSortLabel
            id={`${item.id}SortLabel`}
            data-selenium-id={`Report_Articles-FYTrends-BasicTableSecondRow-${item.id}SortLabel`}
            active={orderByType.orderBy === `pub${item.post}`}
            direction={
              orderByType.orderBy === `pub${item.post}`
                ? orderByType.order
                : 'asc'
            }
            onClick={() => {
              handleChangeOrder(`pub${item.post}`);
            }}
          >
            {intl.messages[`month.${item.id}`]}
          </TableSortLabel>
        </PurpleGreenDisCell>
      ))}
      {/* 由于orderBy 和 handleChangeOrder 实际为null 所以这部分的id使用任意的字符串 */}
      <PurpleGreenDisCell
        isBasic={isBasic}
        isOA={isOA}
        key={'pub-total'}
        className='BottomBorder'
        sx={{
          // top: 52.5,
          top: 42,
          width: `${
            Math.max(
              columnWidthMap['pubTotal'] ?? 0,
              columnMinWidthMap['pubTotal']
            ) + 50
          }px`,
          fontSize: '12px',
        }}
      >
        <TableSortLabel
          id={'pub-total-SortLabel'}
          data-selenium-id={
            'Report_Articles-FYTrends-BasicTableSecondRow-PubTotalSortLabel'
          }
          active={orderByType.orderBy === 'pubTotal'}
          direction={
            orderByType.orderBy === 'pubTotal' ? orderByType.order : 'asc'
          }
          onClick={() => {
            handleChangeOrder('pubTotal');
          }}
          // sx={{ color: '#0e43a9' }}
        >
          <strong>{intl.messages['report.Journal.total']}</strong>
        </TableSortLabel>
      </PurpleGreenDisCell>
    </TableRow>
  );
}

export function handleNull(num) {
  if (num === null || num === undefined || num === '') {
    return '0';
  } else {
    return num;
  }
}

export function CommonContentCells(props) {
  const { row } = props;
  return (
    <>
      {/* Submission */}
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell1'
      >
        {handleNull(row.subMay)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell2'
      >
        {handleNull(row.subJun)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell3'
      >
        {handleNull(row.subJul)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell4'
      >
        {handleNull(row.subAug)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell5'
      >
        {handleNull(row.subSep)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell6'
      >
        {handleNull(row.subOct)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell7'
      >
        {handleNull(row.subNov)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell8'
      >
        {handleNull(row.subDec)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell9'
      >
        {handleNull(row.subJan)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell10'
      >
        {handleNull(row.subFeb)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell11'
      >
        {handleNull(row.subMar)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell12'
      >
        {handleNull(row.subApr)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='RightBorder ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell13'
      >
        {handleNull(row.subTotal)}
      </StyledTableCell>
      {/* Acceptance */}
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell4'
      >
        {handleNull(row.accMay)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell15'
      >
        {handleNull(row.accJun)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell16'
      >
        {handleNull(row.accJul)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell17'
      >
        {handleNull(row.accAug)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell18'
      >
        {handleNull(row.accSep)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell19'
      >
        {handleNull(row.accOct)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell20'
      >
        {handleNull(row.accNov)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell21'
      >
        {handleNull(row.accDec)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell22'
      >
        {handleNull(row.accJan)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell23'
      >
        {handleNull(row.accFeb)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell24'
      >
        {handleNull(row.accMar)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell25'
      >
        {handleNull(row.accApr)}
      </StyledTableCell>
      <StyledTableCell
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell26'
        align='left'
        className='RightBorder ReportNumberTextColor ReportTotalTextSize'
      >
        {handleNull(row.accTotal)}
      </StyledTableCell>
      {/* Publication */}
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell27'
      >
        {handleNull(row.pubMay)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell28'
      >
        {handleNull(row.pubJun)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell29'
      >
        {handleNull(row.pubJul)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell30'
      >
        {handleNull(row.pubAug)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell31'
      >
        {handleNull(row.pubSep)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell32'
      >
        {handleNull(row.pubOct)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell33'
      >
        {handleNull(row.pubNov)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell34'
      >
        {handleNull(row.pubDec)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell35'
      >
        {handleNull(row.pubJan)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell36'
      >
        {handleNull(row.pubFeb)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell37'
      >
        {handleNull(row.pubMar)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell38'
      >
        {handleNull(row.pubApr)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-CommonContentCells-TableCell39'
      >
        {handleNull(row.pubTotal)}
      </StyledTableCell>
    </>
  );
}

export function TotalCommonContentCells(props) {
  const { row } = props;
  return (
    <>
      {/* Submission */}
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell1'
      >
        {handleNull(row.subMay)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell2'
      >
        {handleNull(row.subJun)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell3'
      >
        {handleNull(row.subJul)}
      </StyledTableCell>
      <StyledTableCell
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell4'
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
      >
        {handleNull(row.subAug)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell5'
      >
        {handleNull(row.subSep)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell6'
      >
        {handleNull(row.subOct)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell7'
      >
        {handleNull(row.subNov)}
      </StyledTableCell>
      <StyledTableCell
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell8'
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
      >
        {handleNull(row.subDec)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell9'
      >
        {handleNull(row.subJan)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell10'
      >
        <Box>{handleNull(row.subFeb)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell11'
      >
        <Box>{handleNull(row.subMar)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell12'
      >
        <Box>{handleNull(row.subApr)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='RightBorder ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell13'
      >
        <Box>{handleNull(row.subTotal)}</Box>
      </StyledTableCell>
      {/* Acceptance */}
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell14'
      >
        <Box>{handleNull(row.accMay)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell15'
      >
        <Box>{handleNull(row.accJun)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell16'
      >
        <Box>{handleNull(row.accJul)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell17'
      >
        <Box>{handleNull(row.accAug)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell18'
      >
        <Box>{handleNull(row.accSep)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell19'
      >
        <Box>{handleNull(row.accOct)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell20'
      >
        <Box>{handleNull(row.accNov)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell21'
      >
        <Box>{handleNull(row.accDec)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell22'
      >
        <Box>{handleNull(row.accJan)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell23'
      >
        <Box>{handleNull(row.accFeb)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell24'
      >
        <Box>{handleNull(row.accMar)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell25'
      >
        <Box>{handleNull(row.accApr)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='RightBorder ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell26'
      >
        <Box>{handleNull(row.accTotal)}</Box>
      </StyledTableCell>
      {/* Publication */}
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell27'
      >
        <Box>{handleNull(row.pubMay)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell28'
      >
        <Box>{handleNull(row.pubJun)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell29'
      >
        <Box>{handleNull(row.pubJul)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell30'
      >
        <Box>{handleNull(row.pubAug)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell31'
      >
        <Box>{handleNull(row.pubSep)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell32'
      >
        <Box>{handleNull(row.pubOct)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell33'
      >
        <Box>{handleNull(row.pubNov)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell34'
      >
        <Box>{handleNull(row.pubDec)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell35'
      >
        <Box>{handleNull(row.pubJan)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell36'
      >
        <Box>{handleNull(row.pubFeb)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell37'
      >
        <Box>{handleNull(row.pubMar)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell38'
      >
        <Box>{handleNull(row.pubApr)}</Box>
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className='ReportNumberTextColor ReportTotalTextSize'
        data-selenium-id='Report_Articles-FYTrends-TotalCommonContentCells-TableCell39'
      >
        <Box>{handleNull(row.pubTotal)}</Box>
      </StyledTableCell>
    </>
  );
}

function FYTrendHead(props) {
  const { showTable, setShowTable } = props;
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      width='100%'
      sx={{
        marginTop: '16px',
        marginBottom: '16px',
      }}
    >
      <FYSelectStack
        id='Report_Articles-FYTrends-FYSelectStack'
        fiscalYear={props.fiscalYear}
        handleYearChange={props.handleYearChange}
        lastActionDate={props.lastActionDate}
        showTable={true}
        GenerationDateAndTime={props.generationDateAndTime}
      />

      <div className={styles['action-buttons-container']}>
        {/*导出按钮*/}
        {showTable && (
          <ReportExportMenuButton
            sx={{ height: '32px', marginRight: '24px' }}
            id='fyTrendsExportAllButton'
            data-selenium-id='Report_Articles-FYTrends-ExportAllButton'
            mode={1}
            // label='common.Export'
            onClick={props.onClick}
          />
        )}

        <span className={styles['switch']}>
          <span
            id='show-table-button'
            className={styles[showTable ? 'selected' : '']}
            onClick={() => setShowTable(true)}
          >
            Table
          </span>

          <span
            id='show-diagram-button'
            className={styles[!showTable ? 'selected' : '']}
            onClick={() => setShowTable(false)}
            style={{ maxHeight: '32px' }}
          >
            Diagram
          </span>
        </span>
      </div>
    </Stack>
  );
}

FYTrendHead.propTypes = {
  fiscalYear: PropTypes.string,
  handleYearChange: PropTypes.func,
  lastActionDate: PropTypes.string,
  generationDateAndTime: PropTypes.string,
  onClick: PropTypes.func,
};

export default function FYTrendsPageHead({ showTable, setShowTable }) {
  const dispatch = useDispatch();

  const {
    // fiscalYear,
    selectMonthDate,
    subjectGroupShowAll,
    businessDivisionShowAll,
    ArticleReportGenenateDateFy,
    useFilter,
  } = useSelector(state => {
    return {
      // fiscalYear: state.Report.fyTrendsArticle.fiscalYear,
      selectMonthDate: state.Report.selectDate,
      subjectGroupShowAll: state.Report.fyTrendsArticle.subjectGroupShowAll,
      businessDivisionShowAll:
        state.Report.fyTrendsArticle.businessDivisionShowAll,
      ArticleReportGenenateDateFy: state.Report.ArticleReportGenenateDateFy,
      useFilter: state.Report.fyTrendsArticle.useFilter,
    };
  });

  const [fiscalYear, setFiscalYear] = useState(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    let date = year + '-' + month;
    return getFyYearFromDate(date);
  });

  useEffect(() => {
    if (selectMonthDate != null) {
      const fyYear = getFyYearFromDate(selectMonthDate);
      setFiscalYear(fyYear);
    }
  }, [selectMonthDate]);

  const GenerationDateAndTime =
    'Below reports are generated on ' +
    ArticleReportGenenateDateFy +
    ' (UTC time). ';

  // const [filterOpen, setFilterOpen] = useState(false);

  // const handleExportExcel = () => {
  //   dispatch(fyExport(fiscalYear));
  // };

  const handleExportExcelWithFilenameFromBackEnd = () => {
    dispatch(fyExportWithFilenameFromBackEnd(fiscalYear));
  };

  // const handleExportCSV = async () => {
  //   // 不知道为何这样写会导致timeout，短时间内请求太多？
  //   // 所以全部加上await逐个发送请求
  //   await dispatch(fyExportCSV(fiscalYear, 'basic_table'));
  //   await dispatch(fyExportCSV(fiscalYear, 'subject_group_table'));
  //   await dispatch(fyExportCSV(fiscalYear, 'business_division_table'));
  // };

  const handleExportCSVWithFilenameFromBackEnd = () => {
    dispatch(fyExportCSVWithFilenameFromBackEnd(fiscalYear, 'basic_table'));
    dispatch(
      fyExportCSVWithFilenameFromBackEnd(fiscalYear, 'subject_group_table')
    );
    dispatch(
      fyExportCSVWithFilenameFromBackEnd(fiscalYear, 'business_division_table')
    );
  };

  // const handleFilter = () => {
  //   setFilterOpen(true);
  // };

  // const [filterNumber, setFilterNum] = useState(0);

  // useEffect(() => {
  //   setFilterNum(0);
  //   for (const key in useFilter) {
  //     if ((useFilter[key]?.length ?? 0) !== 0) setFilterNum(val => val + 1);
  //   }
  // }, [useFilter]);

  const [exportOpen, setExportOpen] = useState(false);
  const exportExcelFilter = () => {
    let url = '/reports/export/article/FyTrends/excelWithFilter';
    dispatch(fyExportFilter(fiscalYear, url, useFilter));
  };

  const handleExportExcel = type => {
    if (type === 'filter') {
      exportExcelFilter();
    } else {
      handleExportExcelWithFilenameFromBackEnd();
    }
  };

  const exportCsvFilterd = () => {
    let url = '/reports/export/article/FyTrends/csvWithFilter';
    dispatch(fyExportCSVFilter(fiscalYear, 'basic_table', url, useFilter));
    dispatch(
      fyExportCSVFilter(fiscalYear, 'subject_group_table', url, useFilter)
    );
    dispatch(
      fyExportCSVFilter(fiscalYear, 'business_division_table', url, useFilter)
    );
  };
  const handleExportCSV = type => {
    if (type === 'filter') {
      exportCsvFilterd();
    } else {
      handleExportCSVWithFilenameFromBackEnd();
    }
  };

  return (
    <Box sx={{ width: 'auto' }} data-selenium-id='Report_Articles-FYTrends-Box'>
      <ExportAllDialog
        open={exportOpen}
        handleClose={() => {
          setExportOpen(false);
        }}
        filterData={useFilter}
        handleCSV={handleExportCSV}
        handleExcel={handleExportExcel}
      />
      <>
        {/*顶部几个操作*/}
        <FYTrendHead
          showTable={showTable}
          setShowTable={setShowTable}
          fiscalYear={fiscalYear}
          handleYearChange={year => {
            //year是下拉框选中的年份
            const fyYear = getFyYearFromDate(selectMonthDate);
            if (year != fyYear) dispatch(fyChangeFiscalYear(year));
            dispatch(GetAcquiredActiveLastActionDateFY(year, false));
          }}
          lastActionDate={ArticleReportGenenateDateFy}
          generationDateAndTime={GenerationDateAndTime}
          onClick={() => {
            setExportOpen(true);
          }}
        />
      </>
    </Box>
  );
}

import React from 'react';
import Tabs from '@mui/material/Tabs';
import { Box } from '@mui/system';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import FYTrends from './FYTrends/FYTrends';
import AcquireOverview from './Overview';
import AnnualTrends from './AnnualTrends/index';
import OAvsTotal from './OAvsTotal';
import {ReportTabChildren} from '../ReportSpecificComponents/ReportTabComponents';
import {ShowForPermission} from "@/components/PermissionControl/ShowForPermission";
import {PERMISSIONS} from "@/constant/permission";

function a11yProps(index) {
  return {
    id: `acquiredTab${index}`,
    'aria-controls': `acquiredTabPanel${index}`,
  };
}

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`acquiredTabPanel${index}`}
      aria-labelledby={`acquiredTab${index}`}
      {...other}
      data-selenium-id={`Report_AcquiredAndActiveSi-AcquiredAndActiveSi-TabPanel-${index}`}
    >
      {value === index && (
        <Box
          sx={{ paddingTop: '14px', paddingLeft: '5px', paddingRight: '5px' }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const AANDA_CATEGRORIES = ['overview', 'oaVsTotal', 'fyTrends', 'annualTrends'];

export function AcquiredAndActiveSi({ childTab }) {
  /** refactor to change URL and remove useState */
  const navigate = useNavigate();

  // get index of active tab
  let selectedIndex = AANDA_CATEGRORIES.indexOf(childTab);

  // the first child tab is selected by default
  if (selectedIndex === -1) {
    selectedIndex = 0;
  }

  // get showTable from URL
  let [searchParams, setSearchParams] = useSearchParams();
  let showTable = searchParams.get('showTable');
  showTable = showTable === 'false' ? false : true;

  const setShowTable = showTable => {
    setSearchParams({ showTable });
  };

  // pass showTable in
  const handleChange = (_event, newValue) => {
    const newTab = AANDA_CATEGRORIES[newValue];
    // hardcoded:
    navigate(
      `/simt/auth/reportRelated/report/acquiredAndActiveSI/${newTab}?showTable=${showTable}`
    );
  };

  return (
    <Box
      sx={{width: '100%', padding: 0}}
      data-selenium-id='Report_AcquiredAndActiveSi-AcquiredAndActiveSi-Box'
    >
      <ShowForPermission permission={`${PERMISSIONS.REPORT_READ_GENERAL}`}>
        <Box
          sx={{mt: '-70px', borderColor: 'divider'}}
          data-selenium-id='Report_AcquiredAndActiveSi-AcquiredAndActiveSi-Box-Box'
        >
          <Tabs
            onChange={handleChange}
            value={selectedIndex}
            aria-label='Acquired tabs example'
            data-selenium-id='Report_AcquiredAndActiveSi-AcquiredAndActiveSi-Box-Tabs'
          >
            <ReportTabChildren
              label='Overview'
              {...a11yProps(0)}
              data-selenium-id='Report_AcquiredAndActiveSi-AcquiredAndActiveSi-Box-Box-Tabs-Overview'
            />
            <ReportTabChildren
              label='OA VS Total'
              {...a11yProps(1)}
              data-selenium-id='Report_AcquiredAndActiveSi-AcquiredAndActiveSi-Box-Box-Tabs-OAvsTotal'
            />
            <ReportTabChildren
              label='FY Trends'
              {...a11yProps(2)}
              data-selenium-id='Report_AcquiredAndActiveSi-AcquiredAndActiveSi-Box-Box-Tabs-FYTrends'
            />
            <ReportTabChildren
              label='Annual Trends'
              {...a11yProps(3)}
              data-selenium-id='Report_AcquiredAndActiveSi-AcquiredAndActiveSi-Box-Box-Tabs-AnnualTrends'
            />
          </Tabs>
        </Box>

        <TabPanel
          sx={{marginTop: '-20px'}}
          value={selectedIndex}
          index={0}
          data-selenium-id='Report_AcquiredAndActiveSi-AcquiredAndActiveSi-Box-TabPanel1'
        >
          <AcquireOverview showTable={showTable} setShowTable={setShowTable} />
        </TabPanel>
        <TabPanel
          sx={{marginTop: '-20px'}}
          value={selectedIndex}
          index={1}
          data-selenium-id='Report_AcquiredAndActiveSi-AcquiredAndActiveSi-Box-TabPanel2'
        >
          <OAvsTotal showTable={showTable} setShowTable={setShowTable} />
        </TabPanel>
        <TabPanel
          sx={{marginTop: '-20px'}}
          value={selectedIndex}
          index={2}
          data-selenium-id='Report_AcquiredAndActiveSi-AcquiredAndActiveSi-Box-TabPanel3'
        >
          <FYTrends showTable={showTable} setShowTable={setShowTable} />
        </TabPanel>
        <TabPanel
          sx={{marginTop: '-20px'}}
          value={selectedIndex}
          index={3}
          data-selenium-id='Report_AcquiredAndActiveSi-AcquiredAndActiveSi-Box-TabPanel4'
        >
          <AnnualTrends showTable={showTable} setShowTable={setShowTable} />
        </TabPanel>
      </ShowForPermission>
    </Box>
  );
}

export default AcquiredAndActiveSi;

import { Box, Button, Grid, Link, Snackbar, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import {
  getLocalStorage,
  setLocalStorage,
} from '../../utils/localStorageHandler';

export default function CookieDialog(props) {
  const [valid, setValid] = useState(getLocalStorage('isAcceptCookie'));

  const StyledSnackBar = styled(Box)({
    left: 0,
    bottom: 0,
    width: '100%',
    background: '#003271',
    zIndex: 1201,
    position: 'fixed',
    padding: '18px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  const StyledButton = styled(Button)({
    background: '#0EACEF',
    borderRadius: '2px',
    textTransform: 'none',
    marginLeft: '32px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
  });

  const handleCookieAccept = () => {
    setValid(true);
    setLocalStorage('isAcceptCookie', true, 2592000000);
  };

  useEffect(() => {
    handleCookieAccept();
  }, []);

  return (
    !valid && (
      <StyledSnackBar>
        {/* <Typography
          sx={{
            fontSize: '13px',
            lineHeight: '20px',
            color: '#FFFFFF',
            width: '1042px',
          }}
        >
          By clicking “Accept All Cookies”, you agree to the storing of cookies
          on your device to enhance site navigation, analyze site usage, and
          assist in our marketing efforts.
        </Typography>
        <StyledButton
          id='acceptAllCookiesButton'
          variant='contained'
          onClick={handleCookieAccept}
        >
          Accept All Cookies
        </StyledButton> */}
      </StyledSnackBar>
    )
  );
}

import { useState, useEffect, useMemo, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Box } from '@mui/material';
import styles from './index.module.scss';
import CloseIcon from '@/assets/CloseIcon.svg';
import { SingleSelect } from '@/components/Chart/ChartSelectors';
import ReportSaveMenuButton from '@/components//Chart/chartButton';
import { ReportDownloadMenuButton } from '@/components/Chart/chartButton';
import { ChartAllInOne } from './Chart';
import useGetCategoryListHook from '@/pages/Report/utils/useGetCategoryListHook';
import { useSaveUserSelectionHook } from '@/pages/Report/utils/useSaveUserSelectionHook';
import { useGetChartsDataHook } from './hooks';
import useGetDiagramStatusHook from './hooks/useGetDiagramStatusHook';
import { useDispatch } from 'react-redux';
import { saveImageAsPng, saveImageAsJpg } from '@/pages/Report/chartUtils';
import {
  saveUserSelectedItem,
  GetReportExcelSimple,
  getReportExcelParam, // get excel file
} from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import { ANNUAL_TRENDS, SUBJECT_GROUP, BUSINESS_DIVISION, SI_ARTICLES_PERFORMANCE_ANNUAL_TRENDS, toLowerCaseFirstLetter } from '../Constants/index.js';
import NoDataPage from '@/components/Chart/NoDataPage';
import LoadingPage from '@/components/Chart/LoadingPage';
import ErrorPage from '@/components/Chart/ErrorPage';
import {
  LOADING,
  ERROR,
  VALID,
  NO_DATA,
  NO_SELECTION_FROM_DROPDOWN_MENU,
} from '@/pages/Report/chartUtils/constants.js';
import { getDownloadFileName } from '@/pages/Report/utils/getDownloadFileName';

const ChartContainer = ({
  tabName,
  header,
  subHeader,
  cancelSelectedChart,
  selectedMonth,
  selectedFY,
  hasAdditionalFilter = false,
  catagoryAttributeName,
  url,
  singleSelectListUrl,
  downloadUrl,
  tableDownloadTitle = null,
}) => {
  const dispatch = useDispatch();

  // improvement: to add level 1 & 2 menu titles
  const reduxListTitle = useMemo(() => {
    return `${tabName}/${header}/${subHeader}/${selectedFY}/Dropdown Menu`;
  }, [header, subHeader, selectedFY]);

  // need to keep "FYxx"
  //  Example:
  // Article/SI All In One Annual Trends/Subject Group/FY24/Dropdown Menu
  
  /**
   * 1 get all subject groups / business divisions for articles of the selected FY
   */

  const [itemList, loadingMenu, errorMenu] = useGetCategoryListHook(
    singleSelectListUrl,
    reduxListTitle,
    selectedFY
    );
    
    /**
     * 2 get & save selected SG/BD
    */
   const reduxUserSelectTitle = `${tabName}/${header}/user selected`;
   console.log('reduxUserSelectTitle: ', reduxUserSelectTitle);

  const selectedItem = useSaveUserSelectionHook(reduxUserSelectTitle, itemList);

  const saveSelectedSGToStore = selectedItem => {
    dispatch(saveUserSelectedItem(reduxUserSelectTitle, selectedItem));
  };

  /**
   * 3 update chart title
   */
  // e.g.
  // SI Articles Performance Annual Trends of STMS Health Sciences
  const chartTitle = useMemo(() => {
    if (Array.isArray(itemList) && itemList.length > 0 && selectedItem) {
      return `${SI_ARTICLES_PERFORMANCE_ANNUAL_TRENDS} of ${selectedItem}`;
    } else {
      return `${SI_ARTICLES_PERFORMANCE_ANNUAL_TRENDS}`;
    }
  }, [selectedItem, itemList]);

  // console.log('**chartTitle: ', chartTitle);

  /**
   * 5 retrieve data of selected chart
   */
  const reduxTitleForChart = chartTitle + '/' + selectedMonth;
  const [barchartData, loading, error] = useGetChartsDataHook(
    selectedMonth,
    catagoryAttributeName,
    url,
    reduxTitleForChart,
    selectedItem
  );

  /**
   * 6 check diagram status. e.g. 'no data'
   */

  let alertLabel =
    catagoryAttributeName === 'sg' ? SUBJECT_GROUP : BUSINESS_DIVISION;

  const { valid: digramStatus, info } = useGetDiagramStatusHook(
    itemList,
    barchartData,
    alertLabel,
    loadingMenu,
    errorMenu,
    loading,
    error
  );

  // console.group('6 check diagram status. e.g. no data:: ');
  // console.log('digramStatus: ', digramStatus);
  // console.log('info: ', info);
  // console.groupEnd();

  const [chartWidth, setChartWidth] = useState('100%');
  // for chart download
  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);
  const downloadlink = useRef(); // ADDED

  /**
   * 7 excel download file name
   * e.g. Articles_FYTrends_Subject Group_05312023_072409
   */

  // dependencies: Tab1, Tab2, Level, selected time,
  // return 'Article_Annual Trends_Oct2023_ISORN_YYYYMMDD';
  const fileName = useMemo(() => {
    const tab1Name = tabName;
    const tab2Name = ANNUAL_TRENDS;
    const level = selectedItem;
    const fileName = getDownloadFileName(tab1Name, tab2Name, level, selectedMonth);
    return fileName;
  }, [tableDownloadTitle, selectedItem, selectedMonth]);


  const downloadChart = () => {
    const selectName = toLowerCaseFirstLetter(subHeader.replaceAll(' ',''));
    dispatch(
      getReportExcelParam(selectedMonth, downloadUrl, fileName, 
        {
          [selectName]:[selectedItem],
          performance: true,
        }  
      )
    );
  };

  /**
   * 8 chart image download file name
   */
  const savePng = () => {
    saveImageAsPng(chartRef.current, fileName);
  };

  const saveJpg = () => {
    saveImageAsJpg(chartRef.current, fileName);
  };

  // listen to chart-container size change
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      resizeHandler(entries);
    });
    resizeObserver.observe(chartContainerRef.current);
    return () => {
      if (chartContainerRef.current) {
        resizeObserver.unobserve(chartContainerRef.current);
      }
    };
  }, []);

  function resizeHandler(entries) {
    const chartContainer = entries.find(
      entry => entry.target == chartContainerRef.current
    );
    const width = chartContainer?.target.clientWidth;
    setChartWidth(width);
  }

  // label for dropdown
  let singleSelectLabel =
    catagoryAttributeName === 'sg' ? SUBJECT_GROUP : BUSINESS_DIVISION;
  // Improvement: to replace default label if there is any selection
  if(selectedItem){
    singleSelectLabel = selectedItem;
  }

  return (
    <div className={styles['chart-container']} ref={chartContainerRef}>
      <Box className={styles['chart-header']}>
        <div className={styles['selectors']}>
          {hasAdditionalFilter && (
            <span id='SG-select'>
              <SingleSelect
                label={singleSelectLabel}
                options={itemList ? itemList : []}
                selectedItem={selectedItem}
                setSelectedItem={saveSelectedSGToStore}
                disabled={digramStatus === NO_SELECTION_FROM_DROPDOWN_MENU}
              />
            </span>
          )}
        </div>
        <div className={styles['chart-titles']}>
          <h1 className={styles['chart-title']}>{chartTitle}</h1>
        </div>
        <div className={styles['buttons']}>
          <ReportDownloadMenuButton
            func={downloadChart}
            disabled={digramStatus !== VALID}
          />
          <ReportSaveMenuButton
            items={[
              {
                label: 'Save as PNG',
                operation: savePng,
              },
              {
                label: 'Save as JPG',
                operation: saveJpg,
              },
            ]}
            disabled={digramStatus !== VALID}
          />
          <button
            onClick={cancelSelectedChart}
            className={styles['button-close']}
          >
            <CloseIcon />
          </button>
        </div>
      </Box>

      {digramStatus === LOADING && <LoadingPage />}

      {digramStatus === ERROR && <ErrorPage />}

      {digramStatus === NO_SELECTION_FROM_DROPDOWN_MENU && (
        <NoDataPage
          title={`There is no data for any ${info}s on the selected date.`}
          subTitle={'Please choose a different date to view diagram.'}
        />
      )}

      {digramStatus === NO_DATA && (
        <NoDataPage
          title={`There is no data available for the selected ${info}.`}
          subTitle={`Please choose a different ${info} to view diagram.`}
        />
      )}

      {digramStatus === VALID && (
        <>
          <Box className={styles['chart']} ref={chartRef}>
            <ChartAllInOne
              selectedMonth={selectedMonth}
              width={chartWidth}
              hasZoom={true}
              hasTitle={false}
              title={header}
              barchartData={barchartData}
              isDownload={false}
            />
          </Box>
          <Box className={styles['chart-download']} ref={chartRef}>
            <ChartAllInOne
              selectedMonth={selectedMonth}
              width={chartWidth}
              hasZoom={false}
              hasTitle={true}
              title={chartTitle}
              subTitle={null}
              barchartData={barchartData}
              isDownload={true}
            />
          </Box>
          <a
            href='javascript:void(0)'
            download
            ref={downloadlink}
            className={styles['download-link']}
          >
            hidden download link
          </a>
        </>
      )}
    </div>
  );
};

ChartContainer.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  selectedMonth: PropTypes.string.isRequired,
  cancelSelectedChart: PropTypes.func.isRequired,
  showSubjectGroupsSelector: PropTypes.bool,
};

export default ChartContainer;

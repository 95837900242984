import { useDispatch, useSelector } from 'react-redux';
import { openConfirm, setEditType, setTextParam } from '@/actions/SIMT-User/Alert/AlertAction';
import GeneralInformationForm from './GeneralInformationForm';
import GEAndDatesForm from './GEsAndDatesForm';
import SubmissionOverviewPage from '../../../SubmissionOverviewPage/index';
import ReviewPage from '../../../ReviewPage/index';
import Header from '../../../../../../components/Header';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import {
  backMatchUTCTime,
  getUTCZeroTimeOffset,
  turnDateToUTC,
} from '@/utils/commonUtils';
import {
  SIDateChangeFunction,
  SISubmissionDeadlineDateChangeFunction,
} from '@/utils/commonUtils';
import CreateAndUpdateMain from '../../../CommonComponents/CreateAndUpdateMain';
import CreateAndUpdateHeader from '../../../../CreateAndUpdateSI/CommonComponents/CreateAndUpdateHeader';
import CreateAndUpdateFooter from '../../../../CreateAndUpdateSI/CommonComponents/CreateAndUpdateFooter';
import moment from 'moment';
import Footer from '../../../../../../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  scrollToTop,
  trimItemFilds,
} from '../../../../../../utils/commonUtils';
import { formatGENoEmail, formatGEOnlyHasEmail } from '../../utils';
import MainPageFramework from '../../../CGTComponents/MainPageFramework';
import {createSIInProduction} from "@/actions/SIMT-SI/SpecialIssue";

//MainPage包括footer
function TabPanel(props) {
  const { children, value, index } = props;

  if (value < 0) {
    window.history.back();
  }
  return (
    <div style={{ width: '100%' }}>
      {value === index && (
        <Box
          data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_MainPage-TabPanel-RootBox'
        >
          <Typography data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_MainPage-TabPanel-Typography'>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function ProductionPage() {
  const dispatch = useDispatch();
  const [step3Err, setStep3Err] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [clickedButton, setclickedButton] = useState(false);
  const [GERepeatErr, setGERepeatErr] = useState(false);
  const [jump, setJump] = useState(true);
  const [step3NextMonitor, setStep3NextMonitor] = useState(true);
  const steps = [
    'General information',
    'Stakeholders & Dates',
    'Submission Overview',
    'Review & Create',
  ];
  //初始化
  const [GeneralInformation, setGeneralInformation] = useState({
    Stage: 'Production',
    HandlingCE: -1,
    JournalCode: '',
    JournalName: '',
    GroupOrTitle: '',
    SIPublicationFormat: '',
    SpecialIssueTitle: '',
    SpecialIssueSource: '',
    PaperCommissionMethod: '',
    WorkFlow: '',
    siRevenueModel: 'OA',
    // 保存数值使用，创建SI执行的时候，并不往后端传递
    HandlingCeSelectValue: '',
    HandlingCEReviewValue: '', // 存储HandlingCE的name，不保存email，用在review阶段
    revenueModel: '',
  });

  const [GuestEditors, setGuestEditors] = useState({
    LeadGe: '',
    CoGe: '',
    LeadGeName: '', //用于review的不带邮箱的值
    CoGeName: '',
  });

  const [Dates, setDates] = useState({
    IdeaDate: null,
    AcquiredDate: null,
    OpenForSubmissionDate: null,
    SubmissionDeadLine: null,
    ExpectedAcceptanceDate: null,
    ExpectedPublicationDate: null,
    ClosedforSubmission: 'Yes',
  });

  const [submissionOverview, setSubmissionOverview] = useState({
    numberOfArticlesRecruited: null,
    numberOfArticlesSubmitted: null,
    numberOfArticlesAccepted: null,
    numberOfArticlesRejected: null,
  });

  const reviewData = {
    generalInfomation: {
      'Stage:': GeneralInformation.Stage,
      'Handling CE:': GeneralInformation.HandlingCEReviewValue,
      'Group/Title:': GeneralInformation.GroupOrTitle,
      'SI Publication Format:': GeneralInformation.SIPublicationFormat,
      'SI Revenue Model:': GeneralInformation.siRevenueModel,
      'Special Issue Title:': trimItemFilds(
        GeneralInformation.SpecialIssueTitle
      ),
      'Journal Code:': GeneralInformation.JournalCode,
      'Journal Name:': GeneralInformation.JournalName,
      'Special Issue Source:': GeneralInformation.SpecialIssueSource,
      'Workflow:': GeneralInformation.WorkFlow,
      'Paper Commissioning Method:': GeneralInformation.PaperCommissionMethod,
    },
    guestEditors: {
      'Lead GE:': formatGENoEmail(GuestEditors.LeadGe),
      'Co-GE:': formatGENoEmail(GuestEditors.CoGe),
    },
    dates: {
      'Idea Date:':
        Dates.IdeaDate !== null
          ? moment(Dates.IdeaDate).utc().format('YYYY-MM-DD')
          : '',
      'Acquired Date:':
        Dates.AcquiredDate !== null
          ? moment(Dates.AcquiredDate).utc().format('YYYY-MM-DD')
          : '',
      'Open for Submission Date:':
        Dates.OpenForSubmissionDate !== null
          ? moment(Dates.OpenForSubmissionDate).utc().format('YYYY-MM-DD')
          : '',
      'Submission Deadline:':
        Dates.SubmissionDeadLine !== null
          ? moment(Dates.SubmissionDeadLine).utc().format('YYYY-MM-DD')
          : '',
      'Expected Acceptance Date:':
        Dates.ExpectedAcceptanceDate !== null
          ? moment(Dates.ExpectedAcceptanceDate).utc().format('YYYY-MM-DD')
          : '',
      'Expected Publication Date:':
        Dates.ExpectedPublicationDate != null
          ? moment(Dates.ExpectedPublicationDate).utc().format('YYYY-MM-DD')
          : '',
      'Closed for Submission:': Dates.ClosedforSubmission,
    },
    submissionOverview: {
      'Data source:': 'EEO',
      recruited:
        submissionOverview.numberOfArticlesRecruited === null
          ? 0
          : submissionOverview.numberOfArticlesRecruited,
      submitted:
        submissionOverview.numberOfArticlesSubmitted === null
          ? 0
          : submissionOverview.numberOfArticlesSubmitted,
      accepted:
        submissionOverview.numberOfArticlesAccepted === null
          ? 0
          : submissionOverview.numberOfArticlesAccepted,
      rejected:
        submissionOverview.numberOfArticlesRejected === null
          ? 0
          : submissionOverview.numberOfArticlesRejected,
    },
  };

  const selector = state => {
    return {
      CreateSIResult: state.SI.createSIResult,
    };
  };
  const { CreateSIResult } = useSelector(selector);
  const navigate = useNavigate();

  useEffect(() => {
    if ((CreateSIResult !== null) & (CreateSIResult !== '')) {
      if (CreateSIResult['code'] === 200) {
        if (CreateSIResult.data !== undefined) {
          navigate(`/simt/auth/siRelated/si/detail/${CreateSIResult.data}`);
          dispatch(setTextParam());
          dispatch(setEditType('createSI'));
          dispatch(openConfirm());
        }
      } else {
        dispatch(setTextParam());
        dispatch(setEditType('error'));
        dispatch(openConfirm());
      }
    }
  }, [CreateSIResult]);

  const [isOA, setIsOA] = React.useState(
    GeneralInformation.revenueModel == 'OA' ? true : false
  );
  useEffect(() => {
    setIsOA(GeneralInformation.revenueModel === 'OA' ? true : false);
  }, [GeneralInformation.revenueModel]);
  //handleNext数组
  const handleNextArray = [
    () => {
      //第一步点击next
      let data = !step3NextMonitor;
      setStep3NextMonitor(data);
      setclickedButton(true);
      if (activeStep < steps.length - 1 && jump) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setclickedButton(false);
        scrollToTop();
      }
    },
    () => {
      //第二步点击next
      let data = !step3NextMonitor;
      setStep3NextMonitor(data);
      // if (GERepeatErr) {
      //   dispatch(setEditType("GERepeatErr"))
      //   dispatch(openConfirm())
      //   //setGERepeatErr(true)
      // }
      setclickedButton(true);
      // console.log("jump=========================" + jump)
      if (activeStep < steps.length - 1 && jump) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setclickedButton(false);
        scrollToTop();
      }
    },
    () => {
      //第三步的操作
      let data = !step3NextMonitor;
      setStep3NextMonitor(data);
      setclickedButton(true);
      if (!step3Err && activeStep < steps.length - 1) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setclickedButton(false);
        scrollToTop();
      }
    },
    async () => {
      //第四步的操作
      let data = !step3NextMonitor;
      setStep3NextMonitor(data);
      // 第三步的操作,后端交互
      // 传的参数
      let SIData = {};
      SIData.stage = 'Production';
      SIData.handlingCe = GeneralInformation.HandlingCE;
      SIData.journalName = GeneralInformation.JournalName;
      SIData.journalCode = GeneralInformation.JournalCode;
      SIData.specialIssueType = GeneralInformation.SIPublicationFormat;
      SIData.specialIssueTitle = trimItemFilds(
        GeneralInformation.SpecialIssueTitle
      );
      SIData.siSource = GeneralInformation.SpecialIssueSource;
      SIData.leadGuestEditors = formatGEOnlyHasEmail(GuestEditors.LeadGe);
      SIData.coGuestEditors = formatGEOnlyHasEmail(GuestEditors.CoGe);
      SIData.paperCommitioningMethod = GeneralInformation.PaperCommissionMethod;
      SIData.workflow = GeneralInformation.WorkFlow;
      SIData.siRevenueModel = GeneralInformation.siRevenueModel;
      SIData.closedForSubmission =
        Dates.ClosedforSubmission === undefined
          ? ''
          : Dates.ClosedforSubmission;
      SIData.numberOfRecruitedArticles =
        submissionOverview.numberOfArticlesRecruited;
      SIData.numberOfSubmittedArticles =
        submissionOverview.numberOfArticlesSubmitted;
      SIData.numberOfAcceptedArticles =
        submissionOverview.numberOfArticlesAccepted;
      SIData.numberOfRejectedArticles =
        submissionOverview.numberOfArticlesRejected;
      SIData.ideaDate = SIDateChangeFunction(Dates.IdeaDate);
      SIData.acquiredDate = SIDateChangeFunction(Dates.AcquiredDate);
      SIData.openForSubmissionDate = SIDateChangeFunction(
        Dates.OpenForSubmissionDate
      );
      SIData.expectedSubmissionDeadline =
        SISubmissionDeadlineDateChangeFunction(Dates.SubmissionDeadLine);
      SIData.expectedAcceptanceDate = SIDateChangeFunction(
        Dates.ExpectedAcceptanceDate
      );
      SIData.expectedPublicationDate = SIDateChangeFunction(
        Dates.ExpectedPublicationDate
      );
      await dispatch(createSIInProduction(SIData));
    },
  ];

  const handleBack = () => {
    setclickedButton(false);
    setGeneralInformationError(false);
    setGEsError(false);
    setSubmissionError(false);

    setActiveStep(prevActiveStep => prevActiveStep - 1);
    setJump(true);
    scrollToTop();
  };

  const handleStringtoList = value => {
    return value.split(',');
  };
  const ProductionMandatoryList = handleStringtoList(
    localStorage.getItem('ProductionMandatoryList')
  );

  const [value, setValue] = useState([]);

  // 检测next 按钮的触发 用于滚动到错误信息
  const [clickTime, setClickTime] = useState(0);

  const [GeneralInformationError, setGeneralInformationError] = useState(false);
  const [GEsError, setGEsError] = useState(false);
  const [SubmissionError, setSubmissionError] = useState(false);

  const container = (
    <>
      {/* 需要跳转的页面，index为顺序 */}
      <TabPanel
        data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_MainPage-TabPanel-0'
        value={activeStep}
        index={0}
      >
        <GeneralInformationForm
          MandatoryList={ProductionMandatoryList}
          id='createProductionGeneralInformationPage'
          data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_MainPage-GeneralInformationPage'
          GeneralInformation={GeneralInformation}
          setGeneralInformation={setGeneralInformation}
          clickedButton={clickedButton}
          setHasError={setGeneralInformationError}
          setJump={setJump}
          clickTime={clickTime}
        />
      </TabPanel>
      <TabPanel
        data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_MainPage-TabPanel-1'
        value={activeStep}
        index={1}
      >
        <GEAndDatesForm
          id='createProductionAcquiredGEAndDatesPage'
          data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_MainPage-AcquiredGEAndDatesPage'
          MandatoryList={ProductionMandatoryList}
          GuestEditors={GuestEditors}
          setGuestEditors={setGuestEditors}
          Dates={Dates}
          setDates={setDates}
          clickedButton={clickedButton}
          setHasError={setGEsError}
          setJump={setJump}
          GERepeatErr={GERepeatErr}
          setGERepeatErr={setGERepeatErr}
          clickTime={clickTime}
        />
      </TabPanel>
      <TabPanel
        data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_MainPage-TabPanel-2'
        value={activeStep}
        index={2}
      >
        <SubmissionOverviewPage
          MandatoryList={ProductionMandatoryList}
          setErr={setStep3Err}
          step3NextMonitor={step3NextMonitor}
          value={submissionOverview}
          setValue={setSubmissionOverview}
          clickedButton={clickedButton}
          setHasError={setSubmissionError}
          id='createProductionSubmissionOverviewFormPage'
          data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_MainPage-SubmissionOverviewFormPage'
          clickTime={clickTime}
        />
      </TabPanel>
      <TabPanel
        data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_MainPage-TabPanel-3'
        value={activeStep}
        index={3}
      >
        <ReviewPage
          reviewData={reviewData}
          setValue={setValue}
          isOA={isOA}
          id='createProductionReviewAndCreatePage'
          data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_MainPage-ReviewAndCreatePage'
        />
      </TabPanel>
    </>
  );
  const stepper = (
    <CreateAndUpdateHeader
      id='createSI_acquiredPage_mainPage_header'
      activeStep={activeStep}
      steps={steps}
    />
  );

  const actionBar = (
    <CreateAndUpdateFooter
      id='createSI_ProductionPage_mainPage_actionBar'
      activeStep={activeStep}
      lastStep={steps.length - 1}
      handleBack={handleBack}
      handleNextOrCreate={async () => {
        await handleNextArray[activeStep]();
        setClickTime(clickTime + 1);
      }}
      Err={GeneralInformationError||GEsError||SubmissionError}
    />
  );
  return (
    <MainPageFramework
      id='createSI_productionPage_mainPage'
      actionBar={actionBar}
      stepper={stepper}
    >
      <CreateAndUpdateMain
        id='createSI_productionPage_mainPage_content'
        container={container}
      />
    </MainPageFramework>
  );
}

export default ProductionPage;

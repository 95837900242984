import {AnyAction, applyMiddleware, createStore, EmptyObject, Store} from "redux";
import rootReducer from "@/reducers";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk, {ThunkDispatch} from "redux-thunk";

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
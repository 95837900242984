import {axios_instance} from "@/utils/axios_instance";
import {GET_SIREVENUEMODEL, GET_SISCOURCE, GET_STAGES, GET_WORKLISTFLOW} from "@/actions/SIMT-SI/SpecialIssue/SIAction";

export function getWorkFlowList() {
    return async dispatch => {
        try {
            const url = '/api/v1/user-service/configs';
            const res = await axios_instance.get(url, {
                params: {
                    type:'Workflow',
                    fuzzy_type:false,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const {code,data} = res.data;
            if (code === 200) {
                dispatch({type: GET_WORKLISTFLOW, data: data});
            } else {
                dispatch({type: GET_WORKLISTFLOW, data: 'error'});
            }
        } catch (e) {
            dispatch({type: GET_WORKLISTFLOW, data: 'error'});
        }
    };
}

export function getSIRevenueModel() {
    return async dispatch => {
        try {
            const url = '/api/v1/user-service/configs';
            const res = await axios_instance.get(url, {
                params: {
                    type:'SI revenue model',
                    fuzzy_type:false,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },

            });
            const {code,data} = res.data;
            if (code === 200) {
                dispatch({type: GET_SIREVENUEMODEL, data: data});
            } else {
                dispatch({type: GET_SIREVENUEMODEL, data: 'error'});
            }
        } catch (e) {
            dispatch({type: GET_SIREVENUEMODEL, data: 'error'});
        }
    };
}

export function getSepcialIssueSource() {
    return async dispatch => {
        try {
            const url = '/api/v1/user-service/configs';
            const res = await axios_instance.get(url, {
                params: {
                    type:'Special Issue Source',
                    fuzzy_type:false,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            
            const {code,data} = res.data;
            if (code === 200) {
                dispatch({type: GET_SISCOURCE, data: data});
            } else {
                dispatch({type: GET_SISCOURCE, data: 'error'});
            }
        } catch (e) {
            dispatch({type: GET_SISCOURCE, data: 'error'});
        }
    };
}

export function getStageList() {
    return async dispatch => {
        try {
            // const url = '/api/v1/user-service/configs';
            const url = '/api/v1/user-service/flow/stages/SI';
            const res = await axios_instance.get(url, {
                params: {
                    // type:'Stage',
                    // fuzzy_type:false,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            
            const {code,data} = res.data;
            if (code === 200) {
                dispatch({type: GET_STAGES, data: data});
            } else {
                dispatch({type: GET_STAGES, data: 'error'});
            }
        } catch (e) {
            dispatch({type: GET_STAGES, data: 'error'});
        }
    };
}
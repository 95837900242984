import React, { useState } from 'react';
import {
  ArticleOverviewHeader,
  ArticleOverviewPageOverview,
} from './overHeader';
import ArticleOverviewBasicTable from './BasicTable';
import { ArticleOverviewSubjectGroupTable } from './SubjectGroupTable';
import { ArticleOverviewBusinessDivisionTable } from './BusinseeTable';
import { StyledEngineProvider, useTheme } from '@mui/material/styles';
import { ArticleOverviewCETable } from './CETable';
import ArticleOverviewDiagrams from './OverviewDiagrams';
import ArticlesOverviewFilter from './ArticlesOverviewFilter';
import { Note } from '../Note';
import { ShowForPermission } from '../../../../components/PermissionControl/ShowForPermission';
import { TableGroupBox } from '../../ReportSpecificComponents/SpecialColorTableHeader';
import {PERMISSIONS} from "@/constant/permission";

export function ArticleOverview({ showTable, setShowTable }) {
  return (
    <>
      <ArticleOverviewPageOverview
        showTable={showTable}
        setShowTable={setShowTable}
      />
      {showTable ? <ArticleOverviewTable /> : <ArticleOverviewDiagrams />}
    </>
  );
}

export function ArticleOverviewTable() {
  const filterDrawerWidth = 380;
  const theme = useTheme();

  const [filterOpen, setFilterOpen] = useState(false);
  const [ifNoResult, setIfNoResult] = useState(false);

  const filterOpenChange = () => {
    setFilterOpen(!filterOpen);
  };

  const BasicTableBoxCss = {
    width: filterOpen ? 'calc(100% - 308px)' : '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  };

  return (
    <div>
      <TableGroupBox
        data-selenium-id='Report_Articles_Overview-Box'
        style={{
          display: 'flex',
          flex: 'row',
          width: '100%',
          padding: '0px',
        }}
      >
        <StyledEngineProvider injectFirst>
          <div style={BasicTableBoxCss}>
            <ArticleOverviewHeader
              filterOpen={filterOpen}
              filterOpenChange={filterOpenChange}
            />
            <ArticleOverviewBasicTable setIfNoResult={setIfNoResult} />
          </div>
        </StyledEngineProvider>
        {filterOpen ? (
          <ArticlesOverviewFilter
            filterOpen={filterOpen}
            drawerWidth={filterDrawerWidth}
            handleClose={filterOpenChange}
          />
        ) : (
          <div></div>
        )}
      </TableGroupBox>
      {/* <TableLabel>Subject Group</TableLabel> */}
      <TableGroupBox>
        <ArticleOverviewSubjectGroupTable ifNoResult={ifNoResult} />
      </TableGroupBox>
      {/* <TableLabel>Business Division</TableLabel> */}
      <TableGroupBox>
        <ArticleOverviewBusinessDivisionTable ifNoResult={ifNoResult} />
      </TableGroupBox>
      {/* <TableLabel>CE/CAT</TableLabel> */}
      <ShowForPermission permission={`${PERMISSIONS.CAT_REPORT_READ} ${PERMISSIONS.REPORT_READ_CE}`}>
        <TableGroupBox>
          <ArticleOverviewCETable ifNoResult={ifNoResult} />
        </TableGroupBox>
      </ShowForPermission>
      {/*提示语*/}
      <Note top='20px' />
    </div>
  );
}

export default ArticleOverview;

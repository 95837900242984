export default function TimelineConnector() {
  return (
    <div
      style={{
        borderRadius:'100px',// A big number
        width: '4px',
        minHeight: '36px',
        backgroundColor: '#DFE4E8',
        // paddingTop: '12px',
        // paddingBottom: '12px',
        height: '100%',
        marginTop: '17px',
        marginBottom: '12px',
      }}
    >
      {/* empty content */}
    </div>
  );
}

import React, {JSXElementConstructor, ReactElement} from "react";
import {string} from "prop-types";

export interface UiComponent<T> {
    id: number,
    stage?: string,
    area: string,
    action?: string,
    componentType: string,
    componentKey?: string,
    order?: number,
    properties: T,
    componentVersion: number,
    createBy?: string,
    createTime?: string| Date,
    [key: string]: any,
}

export enum ChecklistStatus {
    NEW = 'New',
    IN_PROGRESS = 'In Progress',
    COMPLETED = "Completed",
    ARCHIVED = "Archived",
    READ_ONLY = "Readonly",
}

export type CheckedType = 'Yes' | 'No' | null;

export type ValidateState = 'default' | 'checked' | 'error';
export const VALIDATE_KEY = '_validate_';

export interface CheckListItemBlock {
    ["checked"] : CheckedType,
    ["comment"] : string | null,
}

export interface ChecklistData {
    sipCode: string,
    status: ChecklistStatus | string | null,
    immediateDecision: string | null,
    overallComment: string | null,
    details: {
        [key: string]: CheckListItemBlock,
    },
    version: number,
    locked: boolean,
    lastModifyUser: number,
    lastModifyTime: string | Date,
    geList: SipGE[],
    checklistVersion: number,
    [key: string]: any,
}

export declare const EMPTY_CHECKLIST_DATA: ChecklistData;

export interface Range {
    min: number;
    max: number;
    comment?: string;
}

export interface SipGE {
    sipCode?: string,
    geId: number,
    fullname: string,
    details: {
        [key: string]: CheckListItemBlock| Range | null
    },
    geCode?: string | null,
    guestEditorInSip: any,
    siGeInfoList?: any[] | null,
    [key: string]: any,
}

export enum FormComponentType{
    INPUT = 'input',
    SELECT = 'select',
    RADIO = 'radio',
    CHECKBOX = 'checkbox',
    TEXT = 'text',
    JSON = 'json',
}

export interface FormComponentProps {
    name: string; // key of the form data
    onChange? : (value: any) => void;
    ['html-id']?: string;
    ['parent-html-id']?: string;
    ['html-index']?: number;
}

export type FormComponent<T> = (props: FormComponentProps & T) => ReactElement;

export declare function ComputedComponent<T>(component: FormComponent<T>): FormComponent<T>;

export declare function extractHtmlId(props: FormComponentProps): string|undefined;

export interface RootFormProps {
    defaultValue?: MapState;
    globalDispatch?: React.Dispatch<any>;
    onChange?: (newState: MapState) => void;
    children?: ReactElement | ReactElement[];
}

export interface SubFormProps {
    formKey: string;
    children?: ReactElement | ReactElement[];
}

export type FormProps = RootFormProps | SubFormProps;

export interface SubFormGroupProps{
    formKey: string;
    defaultValue?: MapState[];
    children?: ReactElement | ReactElement[];
}

export interface RelativePathFormProps {
    // relative path of the form data
    // such as ['..', 'detail', 0]
    path: (string|number)[];
    children?: ReactElement | ReactElement[];
}

export interface MapState {
    [key: string]: any;
}

export interface FormAction {
    type: string;
    payload?: {
        path?: (string|number)[],
        value?: any;
    };
    [key: string]: any;
}

/**
 * Convention:
 * 1. Properties that start with an underscore are state attributes
 *    that can be removed by the function as a whole, such as _proceeding
 * 2. When registering a new key, the state map will be created by default,
 *    with the '__key' as the state key
 */
export interface FormState {
    state: MapState;
    dispatch: React.Dispatch<any>;
    basePath: (string|number)[];
    regist: (key: string, defaultValue: any, initStates?: MapState, statesPath?: (string|number)[]) => any;
    set: (prop_name: string, value: any) => void;
    get: (prop_name: string) => any;
    data: () => MapState;
    root: () => MapState;
}

export declare function pureData(data: MapState): MapState;

export function getInPath(obj: any, path: (string|number)[]): any;

export declare const useFormContext: () => FormState;

export declare const userFormState: <T>(form: FormState, name: string) => [T, (value: T) => void];

export declare function useForm<T>(props: {
    name: string;
    initValue: T;
    initStates?: MapState;
    statesPath?: (string|number)[];
    onChange?: (value: T) => void;
}): {
    value: T,
    setValue: (value: T) => void,
    form: FormState,
    getState: (stateName: string) => any,
    setState: (stateName: string, stateValue: any) => void,
};

// export declare function loadFormComponent(type: string | FormComponentType): JSXElementConstructor<any>;

export declare function Form(props: FormProps): ReactElement;

export declare function RootForm(props: RootFormProps): ReactElement;

export declare function SubForm(props: SubFormProps): ReactElement;

export declare function RelativePathForm(props: RelativePathFormProps): ReactElement;
export * from './form';
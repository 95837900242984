// ReportAction里面已经比较复杂了，所以吧部分Article的方法抽到这里面来
// 但是reducer依然使用的是ReportReducer

import {axios_instance} from '../../../utils/axios_instance';
import {setSnackbarMessageAndOpen} from '../../SnackbarAction';
import moment from 'moment';
import {downloadFile} from '../../SIMT-SI/SpecialIssue/SIAction';
import {isTimeoutError} from '../../../utils/commonUtils';
import {SEVERITIES} from '../../../modules/ConfirmSnackBar/CommonSnackBar';
import getAuth from '@/utils/getAuth';

// FYTrends 操作
export const FY_ARTICLE_GET_BASIC_DATA = 'FY_ARTICLE_GET_BASIC_DATA';
export const FY_ARTICLE_GET_SUBJECT_GROUP_DATA =
  'FY_ARTICLE_GET_SUBJECT_GROUP_DATA';
export const FY_ARTICLE_GET_BUSINESS_DATA = 'FY_ARTICLE_GET_BUSINESS_DATA';
export const FY_ARTICLE_ORDER_BY = 'FY_ARTICLE_ORDER_BY';
export const FY_ARTICLE_CHANGE_PAGE = 'FY_ARTICLE_CHANGE_PAGE';
export const FY_ARTICLE_CHANGE_PAGE_SIZE = 'FY_ARTICLE_CHANGE_PAGE_SIZE';
export const FY_ARTICLE_CHANGE_FISCAL_YEAR = 'FY_ARTICLE_CHANGE_FISCAL_YEAR';
export const FY_ARTICLE_CHANGE_SUBJECT_SHOW_ALL =
  'FY_ARTICLE_CHANGE_SUBJECT_SHOW_ALL';
export const FY_ARTICLE_CHANGE_BUSINESS_SHOW_ALL =
  'FY_ARTICLE_CHANGE_BUSINESS_SHOW_ALL';
export const FY_ARTICLE_FILTER_SELECT = 'FY_ARTICLE_FILTER_SELECT';
export const FY_ARTICLE_FILTER_APPLY = 'FY_ARTICLE_FILTER_APPLY';
export const FY_ARTICLE_FILTER_CLEAR = 'FY_ARTICLE_FILTER_CLEAR';

/**
 * basic table排序方式变更
 * @param order asc or desc
 * @param orderBy 依据什么排序
 * @returns {(function(*): void)|*}
 */
export function fyOrderBy(order, orderBy) {
  return dispatch => {
    dispatch({
      type: FY_ARTICLE_ORDER_BY,
      data: { order: order, orderBy: orderBy },
    });
  };
}

/**
 * 更改basic table页码
 * @param page  新的页码
 * @returns {(function(*): void)|*}
 */
export function fyChangePage(page) {
  return dispatch => {
    dispatch({ type: FY_ARTICLE_CHANGE_PAGE, data: page });
  };
}

/**
 * 更改basic table页面大小
 * @param pageSize  一页显示多少
 * @returns {(function(*): void)|*}
 */
export function fyChangePageSize(pageSize) {
  return dispatch => {
    dispatch({ type: FY_ARTICLE_CHANGE_PAGE_SIZE, data: pageSize });
  };
}

/**
 * 修改财年
 * @param year
 * @returns {(function(*): void)|*}
 */
export function fyChangeFiscalYear(year) {
  return dispatch => {
    dispatch({ type: FY_ARTICLE_CHANGE_FISCAL_YEAR, data: year });
  };
}

export function fyExportFilter(fiscalYear,url,filterData) {
  const filename = `Articles_FYTrends_${moment()
    .utc()
    .format('MMDDYYYY_HHmmss')}.xls`;
  return async dispatch => {
    try {
      let res = await axios_instance.post(url,filterData, {
        responseType: 'blob',
        params: {
          selectedFY: fiscalYear,
        },
        headers: {
          authorization: getAuth(),
        },
      });
      if(res.headers['content-disposition']&&res.headers['content-disposition'].includes('filename')){
        downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
      }else{
        downloadFile(res.data, filename);
      }
    } catch (err) {
      let reasonId;
      if (isTimeoutError(err)) {
        reasonId = 'common.errorTimeout';
      } else {
        reasonId = `common.error${err.status}`;
      }
      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToExport',
          {
            value: filename,
            reason: reasonId,
          },
          SEVERITIES.error
        )
      );
    }
  };
}


export function fyExportCSVFilter(fiscalYear, tableType,url,filterData) {
  return async dispatch => {
    const filename = `Articles_FYTrends_${tableType}_${moment()
      .utc()
      .format('MMDDYYYY_HHmmss')}.csv`;
    try {
      let res = await axios_instance.post(url,filterData, {
        params: {
          selectedFY: fiscalYear,
          type: tableType,
        },
        headers: {
          authorization: getAuth(),
        },
      });
      if(res.headers['content-disposition']&&res.headers['content-disposition'].includes('filename')){
        downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
      }else{
        downloadFile(res.data, filename);
      }
    } catch (err) {
      let reasonId;
      if (isTimeoutError(err)) {
        reasonId = 'common.errorTimeout';
      } else {
        reasonId = `common.error${err.status}`;
      }
      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToExport',
          {
            value: filename,
            reason: reasonId,
          },
          SEVERITIES.error
        )
      );
    }
  };
}

/**
 * 控制subject group上面的All状态变化
 * @param newState  开启或关闭All
 * @returns {(function(*): void)|*}
 */
export function fySetSubjectShowAll(newState) {
  return dispatch => {
    dispatch({ type: FY_ARTICLE_CHANGE_SUBJECT_SHOW_ALL, data: newState });
  };
}

/**
 * 控制business division上面的All状态变化
 * @param newState  开启或关闭All
 * @returns {(function(*): void)|*}
 */
export function fySetBusinessShowAll(newState) {
  return dispatch => {
    dispatch({ type: FY_ARTICLE_CHANGE_BUSINESS_SHOW_ALL, data: newState });
  };
}

/**
 * 选择FY filter的一项
 * @param targetId
 * @param data
 * @returns {(function(*): void)|*}
 */
export function fyFilterSelect(targetId, data) {
  return dispatch => {
    dispatch({ type: FY_ARTICLE_FILTER_SELECT, data: { targetId, data } });
  };
}

/**
 * filter应用
 * @param useFilters  需要被应用的filter，直接替换掉state中的`useFilters`
 * @returns {(function(*): void)|*}
 */
export function fyFilterApply(useFilters) {
  return dispatch => {
    dispatch({ type: FY_ARTICLE_FILTER_APPLY, data: useFilters });
  };
}

/**
 * filter清空，会将`filterSelected`恢复为什么也没选择的状态
 * @returns {(function(*): void)|*}
 */
export function fyFilterClear() {
  return dispatch => {
    dispatch({ type: FY_ARTICLE_FILTER_CLEAR });
  };
}

import * as React from 'react';
import {
  useEffect,
  useState,
  useRef,
  useMemo,
  forwardRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  CardContent,
  Stack,
  Grid,
  Typography,
  Divider,
  SvgIcon,
  IconButton,
  styled,
} from '@mui/material';
import axios from 'axios';

import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';

import SaveAndCancelButton from '@/componentsphase2/SIDetail/SaveAndCancelButton';
import { GetLabel } from '@/components/FileUploader/LabelFile';
import { GetProgress } from '@/components/FileUploader/ProgressFile';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import {
  ChooseIconByType,
  formatFileSize,
} from './FlagBlock';
import './FlagBlock.css';

import MandatoryIcon from '@/assets/MandatoryIcon.svg';
import MildIssueIcon from '@/assets/MildIssueIcon.svg';
import SevereIssueIcon from '@/assets/SevereIssueIcon.svg';
import UploadIcon2 from '@/assets/uploadIcon2.svg';
import Warning500_6 from '@/assets/Warning500_6.svg';
import Error500_6 from '@/assets/Error500_6.svg';
import Off from '@/assets/AlertGeOff.svg';
import AutocompleteArrowIcon from '@/componentsphase2/Autocomplete/SVGComponents/AutocompleteArrowIcon.svg';
import TextFieldErrorIcon from '@/assets/TextFieldErrorIcon.svg';
import {addFlag, getFlagByGECode, uploadFile} from "@/actions/SIMT-SI/GuestEditor/GEFlag";

export const IconButtonNewStyle = styled(IconButton)({
  padding: '0px!important',
  borderRadius: '0px!important',
});
export const NewMyOnlyText = styled(MyOnlyText)({
  '& .css-1n9srpt-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root':
  {
    lineHeight: '16.34px',
    color: '#596A7C',
  },
});
document.addEventListener('drop', function (e) {
  e.preventDefault();
}, false);
document.addEventListener('dragover', function (e) {
  e.preventDefault();
}, false);
const FileUpload = ({
  geId,
  onFileChange,
  isSevere,
  isClear,
  setIsClear,
  disable,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [iserrorMessage, setIsErrorMessage] = useState(false);
  const [isSizeError, setIsSizeError] = useState(false);
  const [isDeleteError, setIsDeleteError] = useState(false);
  const [fileProgress, setFileProgress] = useState([]);
  const [progressUpload, setProgressUpload] = useState({});
  const [cancelTokenList, setCancelTokenList] = useState({});
  const [upfileName, setUpfileName] = useState({});
  const [isUpload, setIsUpload] = useState({});
  const [numerr, setNumerr] = useState(false);

  const fileUploadSize =
    Object.keys(isUpload).filter(x => isUpload[x] !== -1)?.length ?? 0;
  const hasFileUploaded = fileUploadSize > 0;

  useEffect(() => {
    onFileChange(upfile, upfileName, isUpload);
  }, [upfileName, isUpload]);

  const handleDragOver = event => {
    event.preventDefault();
    setIsDragging(true);
  };
  const handleDrop = event => {
    setIsDeleteError(false);
    if (fileUploadSize < 20) {
      event.preventDefault();
      const files = Array.from(event.dataTransfer.files);
      const index = Object.keys(isUpload)?.length;
      let muchFile = files.length>20-fileUploadSize;
      let num=muchFile?20-fileUploadSize:files.length;
      for (let i = 0; i < num ; i++) {
        FileUp([files[i]], index + i);
      }
      if(muchFile){
        setTimeout(()=>{
          setIsErrorMessage(true);
          setNumerr(true);
        },[1000]);
      }
      setIsDragging(false);
    } else {
      setIsErrorMessage(true);
      setNumerr(true);
      setIsDragging(false);
    }
  };

  const CancelToken = axios.CancelToken;
  function abortRead(cancelToken, index) {
    if (
      progressUpload[index] != 100 ||
      (progressUpload[index] === 100 && uploadFile[index] === undefined)
    ) {
      let tmpProgress = JSON.parse(JSON.stringify(progressUpload));
      tmpProgress[index] = 0;
      setProgressUpload(tmpProgress);

      let tmpIsUpload = JSON.parse(JSON.stringify(isUpload));
      tmpIsUpload[index] = -1;
      setIsUpload(tmpIsUpload);

      if (progressUpload[index] !== 100) {
        setCancelToken(CancelToken.source());
      }
    }
  }

  const [upfile, setUpfile] = useState([]);
  const handleFileChange = (event, index) => {
    setIsDeleteError(false);
    if (fileUploadSize < 20) {
      const files = Array.from(event.target.files);
      let muchFile = files.length>20-fileUploadSize;
      let num=muchFile?20-fileUploadSize:files.length;
      for (let i = 0; i < num ; i++) {
        FileUp([files[i]], index + i);
      }
      if(muchFile){
        setTimeout(()=>{
          setIsErrorMessage(true);
          setNumerr(true);
        },[1000]);
      }
      setIsDragging(false);
    } else {
      setIsErrorMessage(true);
      setNumerr(true);
      setIsDragging(false);
    }
    fileInputRef.current.value = null;
  };

  const FileUp = (files, index) => {
    const allow = handleFileUpload(files);
    if (!allow) {
      fileInputRef.current.value = null;
      return;
    }
    // let newfileName=fileName;
    let newupfile = upfile;
    // newfileName.push(files[0].name);
    newupfile.push(files[0]);
    // setFileName(newfileName);
    setUpfile(newupfile);
    const source = CancelToken.source();
    dispatch(
      uploadFile(
        geId,
        files[0],
        source,
        percentage => {
          setProgressUpload(prevProgress => {
            let tmpProgress = { ...prevProgress };
            tmpProgress[index] = percentage;
            return tmpProgress;
          });
        },
        upfileBack => {
          setIsUpload(prevIsUpload => {
            let tmpIsUpload = { ...prevIsUpload };
            tmpIsUpload[index] = 1;
            return tmpIsUpload;
          });

          setUpfileName(prevUpfileName => {
            let tmpUpfileName = { ...prevUpfileName };
            tmpUpfileName[index] = upfileBack;
            return tmpUpfileName;
          });
        }
      )
    );
    setIsUpload(prevIsUpload => {
      let tmpIsUpload = { ...prevIsUpload };
      tmpIsUpload[index] = 0;
      return tmpIsUpload;
    });

    setProgressUpload(prevProgress => {
      let tmpProgress = { ...prevProgress };
      tmpProgress[index] = 0;
      return tmpProgress;
    });

    setCancelTokenList(prevCanceltoken => {
      let tmpCanceltoken = { ...prevCanceltoken };
      tmpCanceltoken[index] = source;
      return tmpCanceltoken;
    });
  };


  const handleFileUpload = files => {
    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'image/png',
      'image/jpeg',
      'image/gif',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'PNGimage/png',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'text/plain',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/x-zip-compressed',
      'application/zip',
      'application/x-gzip',
      'application/rar',
      'application/x-compressed',
      'text/csv',
    ];
    const allowedSize = 5 * 1024 * 1024; // 5MB
    const updatedFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (allowedTypes.includes(file.type) ||  file.name.split('.').pop().toLowerCase() === 'rar') {
        if (file.size <= allowedSize) {
          const newFile = {
            name: file.name,
            size: formatFileSize(file.size),
            type: file.type,
          };
          updatedFiles.push(newFile);
          setIsErrorMessage(false);
        } else {
          setIsErrorMessage(true);
          setIsSizeError(true);
          return false;
        }
      } else {
        setIsErrorMessage(true);
        return false;
      }
    }
    setSelectedFiles(prevFiles => [...prevFiles, ...updatedFiles]);
    return true;
  };

  const handleTextClick = () => {
    fileInputRef.current.click();
  };
  useEffect(() => {
    if (isClear) {
      setSelectedFiles([]);
      setIsUpload({});
      setProgressUpload({});
      setCancelTokenList({});
      setUpfileName({});
      setIsClear(false);
      setIsErrorMessage(false);
      setNumerr(false);
    }
  }, [isClear]);

  const handleDeleteFile = (index, event) => {
    if (isSevere && fileUploadSize <= 1) {
      setIsDeleteError(true);
      setIsErrorMessage(true);
      return;
    }

    setIsDeleteError(false);
    setIsErrorMessage(false);
    setNumerr(false);

    let tmpIsUpload = JSON.parse(JSON.stringify(isUpload));
    tmpIsUpload[index] = -1;
    setIsUpload(tmpIsUpload);
  };
  const handleFileDownload = file => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = file.name;
    link.click();
  };

  const handleDownloadAll = () => {
    selectedFiles.forEach(file => {
      handleFileDownload(file);
    });
  };
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    if (
      (selectedFiles === undefined || selectedFiles.length === 0) &&
      isSevere &&
      disable
    ) {
      setIsErrorMessage(true);
    }
  }, [selectedFiles, isSevere, disable]);

  useEffect(() => {
    if (!isSevere) {
      setIsErrorMessage(false);
    }
  }, [isSevere]);

  const getTypeName = type => {
    if (type === 'application/msword') return 'doc';
    else if (
      type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    )
      return 'docx';
    else if (type === 'PNGimage/png') return 'png';
    else return type;
  };

  const [boxHeight, setBoxHeight] = useState('114px');
  useEffect(() => {
    let sum1 = selectedFiles.filter(
      (file, index) => isUpload[index] === 0
    ).length;
    let sum2 = selectedFiles.filter(
      (file, index) => isUpload[index] !== -1
    ).length;
    if (sum1 > 0 || sum2 > 0) {
      let length = 100 + (sum2 - 1) * 40 + sum1 * 14;
      setBoxHeight(length + 'px');
    } else {
      setBoxHeight('100px');
    }
  }, [selectedFiles, isUpload]);

  return (
    <div style={{ position: 'relative' }}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        id='file-input'
        className='file-upload'
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          width: '360px',
          height: boxHeight,
          borderRadius: '4px',
          border: iserrorMessage ? '2px dashed #ccc' : '1px dashed #ccc',
          borderColor: iserrorMessage
            ? '#C40000'
            : isDragging || hovered
              ? '#0052CC'
              : '#ccc',
          backgroundColor: !isDragging
            ? iserrorMessage && hovered
              ? '#FFEFEF'
              : '#FFFFFF'
            : iserrorMessage
            ? '#FFFFFF'
            : '#CEDDF9',
          padding: '12px 30px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: hasFileUploaded ? '8px' : '12px',
        }}
        onDragOver={handleDragOver}
        onDragLeave={() => setIsDragging(false)}
        onDrop={handleDrop}
      >
        <input
          id='uploadInput'
          type='file'
          accept='.pdf,.doc,.docx,image/png,image/jpeg,image/gif,.xlsx,.xls,.csv,.txt,.ppt,.pptx,.zip,.rar'
          style={{ display: 'none' }}
          onChange={e => handleFileChange(e, Object.keys(isUpload).length)}
          ref={fileInputRef}
          multiple={true}
        />
        <Stack direction='row' sx={{ alignItems: 'center', mb: '4px' }}>
          <SvgIcon viewBox='0 0 18 18' sx={{ width: '18px', height: '18px' }}>
            {' '}
            <UploadIcon2 sx={{ width: '18px', height: '18px' }}></UploadIcon2>
          </SvgIcon>
          <p
            style={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '18px',
              color: '#596A7C',
              marginBottom: '0px',
              marginTop: '0px',
              marginLeft: '5px',
            }}
          >
            Drag & Drop or&nbsp;
            <span
              style={{
                color: '#154AB6',
                fontWeight: 400,
              }}
              onMouseEnter={e => {
                e.target.style.color = '#113D95';
                e.target.style.fontWeight = 600;
              }}
              onMouseLeave={e => {
                e.target.style.color = '#154AB6';
                e.target.style.fontWeight = 400;
              }}
              onClick={handleTextClick}
            >
              Choose File
            </span>{' '}
            to upload
          </p>
        </Stack>
        {hasFileUploaded ? (
          selectedFiles.map((file, index) =>
            isUpload[index] !== -1 ? (
              <>
                {isUpload[index] === 0 ? (
                  <GetProgress
                    sx={{ marginLeft: '0px' }}
                    FileIcon={ChooseIconByType(file.type)}
                    value={progressUpload[index]}
                    abort={() => abortRead(cancelTokenList[index], index)}
                    fileName={file.name}
                    fileSize={file.size}
                    fileType={getTypeName(file.type)}
                  />
                ) : (
                  <GetLabel
                    sx={{ marginLeft: '0px' }}
                    FileIcon={ChooseIconByType(file.type)}
                    del={() => handleDeleteFile(index)}
                    fileName={file.name}
                    fileSize={file.size}
                    fileMIMEType={getTypeName(file.type)}
                    isDownLoad={false}
                  />
                )}
              </>
            ) : (
              <></>
            )
          )
        ) : (
          <div>
            {(selectedFiles === undefined || selectedFiles.length === 0) &&
              isSevere &&
              disable ? (
              <p
                style={{
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#EE5350',
                  margin: 0,
                }}
              >
                <span
                  style={{
                    fontWeight: 600,
                  }}
                  onClick={handleTextClick}
                >
                  Please upload at a least file!
                </span>
                <br></br>
                Supported format: Word, PDF, TXT, PPT, Excel, CSV, PNG, JPEG, JPG,<br></br> GIF, Zip, rar
                &nbsp;
                <span
                  style={{
                    fontWeight: 600,
                  }}
                  onClick={handleTextClick}
                >
                  5M
                </span>{' '}
                each
              </p>
            ) : (
              <>
                {iserrorMessage ?
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: '12px',
                      lineHeight: '15px',
                      color: '#EE5350',
                      margin: -7,
                    }}
                  >
                    Please upload files in supported format:<br></br>Word, PDF, TXT, PPT, Excel, CSV, PNG, JPEG, JPG,<br></br> GIF, Zip, rar<br></br> Max <span style={{ fontWeight: 600 }}>5M</span> each.{' '}
                  </p> :
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: '12px',
                      lineHeight: '15px',
                      color: '#98A7B6',
                      margin: 0,
                    }}
                  >
                    Support Word, PDF, TXT, PPT, Excel, CSV, PNG, JPEG,<br></br>GIF, Zip, rar.<br></br> Max <span style={{ fontWeight: 600 }}>5M</span> each.{' '}
                  </p>
                }
              </>
            )}
          </div>
        )}
      </div>
      <div style={{ width: '360px' }}>
        {hasFileUploaded && !iserrorMessage ? (
          <p
            style={{
              marginTop: 0,
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px',
              color: '#596A7C',
              width: '360px',
            }}
          >
            Support Word,PDF,TXT,PPT,Excel,CSV,PNG,JPEG,JPG,GIF,Zip,rar Max{' '}
            <span style={{ fontWeight: 600 }}>5M</span> each.
          </p>
        ) : null}

        {hasFileUploaded && iserrorMessage ? (
          numerr ? (
            <p
              style={{
                marginTop: 0,
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '15px',
                color: '#EE5350',
                width: '360px',
              }}
            >
              You cannot upload more than 20 evidence
            </p>
          ) :
            isDeleteError ? (
              <p
                style={{
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#EE5350',
                  marginTop: '0',
                }}
              >
                Please upload at a least file!
              </p>
            ) : (
              <p
                style={{
                  marginTop: 0,
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#EE5350',
                  width: '360px',
                }}
              >
                Support Word,PDF,TXT,PPT,Excel,CSV,PNG,JPEG,JPG,GIF,Zip,rar Max{' '}
                <span style={{ fontWeight: 600 }}>5M</span> each.
              </p>
            )
        ) : null}
      </div>
    </div>
  );
};

const TextInputWithLimit = ({ onTextChange, isClear }) => {
  const [text, setText] = useState('');
  useEffect(() => {
    if (isClear) setText('');
  }, [isClear]);
  useEffect(() => {
    onTextChange(text);
  }, [text]);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const padding1 = '8px 4px 8px 12px';
  const padding2 = '8px 12px 8px 12px';
  const [isFocus, setIsFocus] = useState(false);
  const overflowError = useMemo(
    () => (isFocus ? false : text?.length > 10000),
    [text, isFocus]
  );
  return (
    <div>
      <MyOnlyText
        value={text}
        setValue={setText}
        width={'286px'}
        height={'100px'}
        needEndAdornment={false}
        handleIconButtonClick={() => {
          setText('');
        }}
        minRows={6}
        maxRows={5.5}
        placeholder={''}
        fontSize={12}
        fontColor={'#596A7C'}
        lineHeight={16.34}
        hasScrollbar={hasScrollbar}
        setHasScrollbar={setHasScrollbar}
        textFieldPaddingWithoutEndAdornment={hasScrollbar ? padding1 : padding2}
        isError={overflowError}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      />
      <p
        style={{
          marginTop: 6,
          marginBottom: 0,
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '16.34px',
          color: overflowError ? '#EE5350' : '#596A7C',
          width: '286px',
          textAlign: 'right',
          display: 'flex',
          justifyContent: overflowError ? 'space-between' : 'flex-end',
        }}
      >
        {overflowError && (
          <div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
            <TextFieldErrorIcon />
            Should no more than 10000.
          </div>
        )}
        <span>{text?.length + '/10,000'}</span>
      </p>
    </div>
  );
};
const DropdownSelect = ({
  onSelectChange,
  setIsSevere,
  setIsClear,
  FlagList,
  error,
}) => {
  let FlagOptions = [],
    SevereOptions = [];
  FlagOptions.push({ value: '', group: 'Category A', icon: 'severe issue' });
  FlagList.map(flag => {
    if (flag.type === 'Flags-Severe Issue') {
      FlagOptions.push({ value: flag.value, group: 'Category A' });
      SevereOptions.push(flag.value);
    }
  });
  FlagOptions.push({ value: '', group: 'Category B', icon: 'mild issue' });
  FlagList.map(flag => {
    if (flag.type === 'Flags-Mild Issue')
      FlagOptions.push({ value: flag.value, group: 'Category B' });
  });
  const [selectedValue, setSelectedValue] = useState('');
  useEffect(() => {
    if (!SevereOptions.includes(selectedValue)) setIsSevere(false);
    if (SevereOptions.includes(selectedValue)) setIsSevere(true);
  }, [selectedValue]);
  const handleSelectChange = event => {
    const newValue = event.target.value;
    if (onSelectChange(newValue)) {
      setSelectedValue(newValue);
    }
  };
  const renderOptions = () => {
    let currentGroup = null;
    return FlagOptions.map((option, index) => {
      const { group, value, icon } = option;
      const isFirstOptionInGroup = group !== currentGroup;
      let renderedOption;
      if (group === 'Divider') {
        renderedOption = (
          <Divider
            data-selenium-id='GEPage_GEDetailPage-FlagBlock-DropdownSelect-Divider'
            width='91%'
            variant='middle'
            light={true}
          ></Divider>
        );
      } else {
        renderedOption = (
          <MenuItem
            data-selenium-id='GEPage_GEDetailPage-FlagBlock-DropdownSelect-MenuItem'
            key={index}
            value={value}
            className={isFirstOptionInGroup ? 'first-option' : ''}
            disabled={isFirstOptionInGroup}
            sx={{
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '20px',
              color: '#596A7C',
              paddingLeft: '30px',
              paddingTop: '2px',
              paddingBottom: '2px',
              '&.Mui-disabled': {
                color: 'inherit',
                background: 'transparent',
                cursor: 'not-allowed',
                paddingLeft: '0px',
                opacity: 1,
              },
            }}
          >
            {isFirstOptionInGroup && icon && (
              <IconButton
                sx={{ height: '16px', paddingTop: '16px' }}
                className={isFirstOptionInGroup ? 'icon-inherit' : ''}
                disabled
                data-selenium-id='GEPage_GEDetailPage-FlagBlock-DropdownSelect-IconButton'
              >
                {group === 'Category A' ? (
                  <SevereIssueIcon />
                ) : (
                  <MildIssueIcon />
                )}
              </IconButton>
            )}
            {value}
          </MenuItem>
        );
      }
      if (isFirstOptionInGroup) {
        currentGroup = group;
      }
      return renderedOption;
    });
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
    setTimeout(() => {
      let select = document.getElementById(
        'GEPage_GEDetailPage-FlagBlock-DropdownSelect-FormControl'
      );
      let papper = document.getElementById(
        'GEPage_GEDetailPage-FlagBlock-DropdownSelect-Select-PaperProps'
      );
      let bottom =
        Number(papper.style.marginTop.split('px')[0]) +
        Number(papper.style.top.split('px')[0]) +
        Number(papper.style.height.split('px')[0]);
      let selectBottom = select.getBoundingClientRect().top + 33;
      let difHeightOrigin =
        selectBottom -
        (Number(papper.style.marginTop.split('px')[0]) +
          Number(papper.style.top.split('px')[0]));
      let outer = document.getElementById('outer');
      if (window.innerHeight < bottom + 68 + 20) {
        let difHeight = bottom + 68 + 20 - window.innerHeight;
        let scrollTop = outer.scrollTop;
        outer.scrollTo(0, scrollTop + difHeight + difHeightOrigin);
        setTimeout(() => {
          papper.style.top = `${Number(papper.style.top.split('px')[0]) - difHeight + 6
            }px`;
        }, 100);
      }
    }, 100);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Stack
      direction='column'
      alignItems='flex-start'
      justifyContent='center'
      spacing='2px'
    >
      <FormControl
        sx={{
          width: 286,
          height: 33,
          padding: 0,
          backgroundColor: '#FFFFFF',
          '.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#EE5350 !important',
            borderWidth: '2px',
          },
        }}
        onClick={isOpen ? handleClose : handleOpen}
        data-selenium-id='GEPage_GEDetailPage-FlagBlockAdd-DropdownSelect-FormControl'
        id='GEPage_GEDetailPage-FlagBlock-DropdownSelect-FormControl'
        error={error}
      >
        <Select
          data-selenium-id='GEPage_GEDetailPage-FlagBlock-DropdownSelect-Select'
          sx={{ height: 33, backgroundColor: '#FFFFFF' }}
          open={isOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          inputProps={{ readOnly: true }} // not focus on when click the inputbox
          IconComponent={() => (
            <div style={{ paddingRight: '6px', paddingTop: '0px' }}>
              <IconButtonNewStyle sx={
                isOpen ? {
                  transform: 'rotate(180deg)',
                  pt: '2px !important',
                } : {
                  pb: '2px !important',
                }
              }>
                <AutocompleteArrowIcon onClick={isOpen?handleClose:handleOpen}></AutocompleteArrowIcon>
              </IconButtonNewStyle>
            </div>
          )}
          value={selectedValue}
          onChange={handleSelectChange}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
            },
            PaperProps: {
              id: 'GEPage_GEDetailPage-FlagBlock-DropdownSelect-Select-PaperProps',
              style: {
                height: '380px',
                width: '20ch',
                marginTop: '10px',
                marginLeft: '143px',
                boxShadow: '0px 2px 5px 0px #00000010',
              },
            },
          }}
          renderValue={() => (
            <Box
              data-selenium-id='GEPage_GEDetailPage-FlagBlock-DropdownSelect-Select-RenderBox'
              sx={{
                boxSizing: 'border-box',
                height: '22px',
                p: '5px 6px',
                display: 'flex',
                width: 'fit-content',
                alignItems: 'center',
                gap: '6px',
                borderRadius: '4px',
                border: '1px solid #BCC5CF',
                color: '#484848',
                fontFamily: 'Open Sans',
                fontSize: '12px',
                lineHeight: '20px',
                cursor: 'default',
                marginLeft: '-8px!important',
              }}
              onClick={e => e.stopPropagation()}
            >
              {!SevereOptions.includes(selectedValue) ? (
                <Warning500_6
                  width={8}
                  height={8}
                  style={{ paddingBottom: '1px', paddingRight: '2px' }}
                />
              ) : (
                <Error500_6
                  width={8}
                  height={8}
                  style={{ paddingBottom: '1px', paddingRight: '2px' }}
                />
              )}
              {selectedValue}
              <Off
                width={12}
                height={12}
                onClick={() => {
                  onSelectChange('');
                  setSelectedValue('');
                  setIsClear(true);
                }}
                cursor={'pointer'}
                style={{
                  borderRadius: '2px',
                  paddingTop: '2px',
                  marginLeft: '2px',
                }}
              />
            </Box>
          )}
        >
          {renderOptions()}
        </Select>
      </FormControl>
      {error && (
        <Stack
          direction='row'
          spacing='2px'
          alignItems='flex-Start'
          justifyContent='flex-start'
        >
          <Stack
            alignItems='center'
            justifyContent='center'
            sx={{
              marginTop: '2px',
            }}
          >
            <TextFieldErrorIcon />
          </Stack>
          <Box
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '16px',
              color: '#EE5350',
              letterSpacing: '-0.084px',
            }}
          >
            Please enter this field.
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

const getFlagId = (flagName, list) => {
  for (let i = 0; i < list?.length; i++) {
    let flag = list[i];
    if (flag.value === flagName) return flag.id;
  }
};
const getFlagType = (flagName, list) => {
  for (let i = 0; i < list?.length; i++) {
    let flag = list[i];
    if (flag.value === flagName) return flag.type;
  }
};
const getFlagKey = (flagName, list) => {
  for (let i = 0; i < list?.length; i++) {
    let flag = list[i];
    if (flag.value === flagName) return flag.key;
  }
};

const FlagBlockAdd = forwardRef((props, ref) => {
  const [counter, setCounter] = useState(0);
  const dispatch = useDispatch();
  const [isSevere, setIsSevere] = useState(false);
  const [isClear, setIsClear] = useState(false);
  const [fileLength, setFilelength] = useState(20);
  const {
    geId,
    isEdit,
    setIsEdit,
    FlagData,
    flagNum,
    closeAdd,
    setAdd,
    setEditAdd,
  } = props;
  const selector = state => {
    return {
      flagsList: state.GE.flagsList,
      upfileBack: state.GE.upfileBack,
      fileProgressArray: state.GE.fileProgressArray,
    };
  };
  
  useEffect(() => {
    dispatch(getFlagByGECode(geId));
  }, [dispatch, geId, counter]);
  const { flagsList, upfileBack, fileProgressArray } = useSelector(selector);
  const [fileProgress, setFileProgress] = useState([]);
  const [comment, setComment] = useState('');
  const [flagName, setFlagName] = useState('');
  const [flagKey, setFlagKey] = useState();
  const [fileName, setFileName] = useState([]);
  let upfiles = [];
  useEffect(() => {
    dispatch(getFlagByGECode(geId));
  }, [geId]);

  const handleCloseButtonClick = () => {
    setIsClear(false);
    setIsEdit(false);
    closeAdd();
    setEditAdd(false);
  };

  const handleTextChange = newText => {
    setComment(newText);
  };
  const handleSelectChange = newFlag => {
    setIsClear(false);

    // Check If newFlag has Exist
    let mark = FlagData.some(item => {
      if (item.flagInfo.value === newFlag) {
        dispatch(
          setSnackbarMessageAndOpen(
            'gePage.updateError',
            {
              value: 'cannot have the same flag in one GE',
            },
            SEVERITIES.error
          )
        );
        return true;
      }
    });
    if (mark) return false;
    setFlagName(newFlag);
    setFlagKey(getFlagKey(flagName, flagsList));
    return true;
  };
  const handleFilesChange = (newfile, upfileName, isUpload) => {
    let FileName = [];
    const key = Object.keys(upfileName).filter(
      (item, index) => isUpload[item] !== -1
    );
    for (let i = 0; i < key.length; i++) {
      FileName.push(upfileName[key[i]]);
    }
    setFileName(FileName);
    upfiles = newfile;
  };
  const handleCheckButtonClick = () => {
    setIsClear(false);
    setButtonCheck(true);
    if (
      flagName === '' ||
      (isSevere && (fileName === undefined || fileName.length === 0)) ||
      comment.length > 10000 ||
      buttonDisable
    )
      return;
    let geFlagId = null;
    let flagId = getFlagId(flagName, flagsList);
    let flagType = getFlagType(flagName, flagsList);
    let key = getFlagKey(flagName, flagsList);
    let flagInfo = {
      id: flagId,
      type: flagType,
      key: key,
      value: flagName,
    };
    for (let i = 0; i < upfiles.length; i++) {
      dispatch(uploadFile(upfiles[i]));
      fileName.push(upfileBack);
    }
    dispatch(addFlag(geId, geFlagId, flagInfo, comment, fileName));
    setTimeout(() => {
      dispatch(getFlagByGECode(geId));
    }, 1000);
    setIsEdit(false);
    closeAdd();
    setAdd(false);
    setEditAdd(false);
  };

  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonCheck, setButtonCheck] = useState(false);
  useEffect(() => {
    if (
      (flagName === '' ||
        (isSevere && fileName.length === 0) ||
        comment.length > 10000) &&
      buttonCheck
    )
      setButtonDisable(true);
    else setButtonDisable(false);
  }, [isSevere, fileName, buttonCheck, flagName]);
  useEffect(() => {
    if (buttonCheck) setButtonCheck(false);
  }, [isSevere, fileName, comment, flagName]);

  return (
    <Box
      data-selenium-id='GEPage_GEDetailPage-FalgBlock-Box'
      sx={{ width: 1092 }}
    >
      <Box data-selenium-id='GEPage_GEDetailPage-FlagBlock-EditBox'>
        <CardContent
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: '16px!important',
            paddingBottom: '32px!important',
          }}
        >
          <Stack
            spacing={3}
            data-selenium-id='GEPage_GEDetailPage-FlagBlock-EditBox-Stack'
          >
            <Box data-selenium-id='GEPage_GEDetailPage-FlagBlock-EditBox-Box'>
              <Grid container spacing={1} justifyContent='flex-end'>
                {/* GE Details Left Grid */}
                <Grid item xs={6}>
                  <Stack
                    spacing={2.5}
                    data-selenium-id='GEPage_GEDetailPage-FlagBlock-EditBox-LeftStack'
                  >
                    <Box display='flex'>
                      <Box
                        display='flex'
                        sx={{
                          width: '224px',
                          height: '33px',
                          alignItems: 'center',
                        }}
                      >
                        <Typography sx={{ color: '#596A7C', fontSize: '14px' }}>
                          {'Flag\u00A0\u00A0'}
                        </Typography>
                        <Stack sx={{ paddingTop: '4px' }}>
                          <MandatoryIcon></MandatoryIcon>
                        </Stack>
                      </Box>
                      <DropdownSelect
                        onSelectChange={handleSelectChange}
                        setIsSevere={setIsSevere}
                        setIsClear={setIsClear}
                        //data.flagInfo.key // flagsList.key
                        FlagList={(flagsList==='error'?[]:flagsList).filter(flag => {
                          return !FlagData.some(
                            item => item.flagInfo.key === flag.key
                          );
                        })}
                        error={buttonCheck && flagName === ''}
                      ></DropdownSelect>
                    </Box>
                    <Box
                      display='flex'
                      alignItems='center'
                      sx={{ marginTop: '0px!important' }}
                    >
                      <Box
                        display='flex'
                        sx={{ width: '224px', paddingBottom: '38px' }}
                      >
                        <Typography sx={{ color: '#596A7C', fontSize: '14px' }}>
                          {'Comment'}
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        sx={{ width: '224px', paddingTop: '75px' }}
                      >
                        <TextInputWithLimit
                          onTextChange={handleTextChange}
                          sx={{ paddingTop: '10px' }}
                          isClear={isClear}
                        />
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
                {/* GE Details Right Grid */}
                <Grid item xs={6}>
                  <Stack
                    spacing={2.5}
                    data-selenium-id='GEPage_GEDetailPage-FlagBlock-EditBox-LeftStack'
                    sx={{paddingLeft:'12px'}}
                  >
                    <Box display='flex'>
                      <Box display='flex' sx={{ width: '224px' }}>
                        <Typography sx={{ color: '#596A7C', fontSize: '14px' }}>
                          {'\u00A0\u00A0\u00A0\u00A0Evidence\u00A0\u00A0'}
                        </Typography>
                        {isSevere ? (
                          <Stack sx={{ paddingTop: '4px' }}>
                            <MandatoryIcon></MandatoryIcon>
                          </Stack>
                        ) : (
                          <></>
                        )}
                      </Box>
                      <FileUpload
                        geId={geId}                      
                        onFileChange={handleFilesChange}
                        isSevere={isSevere}
                        isClear={isClear}
                        setIsClear={setIsClear}
                        disable={buttonDisable}
                      ></FileUpload>
                    </Box>
                  </Stack>
                </Grid>
                {/* cancel/save Btn */}
                <Box
                  style={{
                    width: 'auto',
                    background: '#FFFFFF',
                    bottom: 0,
                    zIndex: '10',
                    display: 'flex',
                    paddingTop: '16px',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                  }}
                  data-selenium-id='GEPage_GEDetailPage-Box-Box2'
                >
                  <Stack
                    spacing={'10px'}
                    direction='row'
                    data-selenium-id='GEPage_GEDetailPage-Box-Box2-Stack'
                  >
                    <Box
                      sx={{
                        position: 'relative',
                      }}
                    >
                      <SaveAndCancelButton
                        id='SIDetail_SubmissionOverviewBlock-TwoBtn'
                        data-selenium-id='SIDetail_SubmissionOverviewBlock-TwoBtn'
                        handleCancel={handleCloseButtonClick}
                        handleSubmit={handleCheckButtonClick}
                        isDisabled={buttonDisable && buttonCheck}
                      ></SaveAndCancelButton>
                    </Box>
                  </Stack>
                </Box>
              </Grid>
            </Box>
          </Stack>
        </CardContent>
      </Box>
    </Box>
  );
});

export default FlagBlockAdd;

import { TableFooter2 } from '@/components/DataTable';
import { StyledTableCell } from '@/components/DataTable';
import NoDataMessage from '@/componentsphase2/NoDataMessage';
import {
  Box,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import TableHeader from '../../Overview/RequestHistoryTable/TableHeader';
import TextViewAllButton from '@/componentsphase2/Button/TextViewAllButton';
import { useNavigate } from 'react-router';
import MyHideText from './HideText';
let ready = true;
export default props => {
  const navigate = useNavigate();
  const {
    titleList = [], //[['title','width','title text-align','info text-align','type']]
    tableData = [],
    tableWidth = 'auto',
    ...other
  } = props;
  //当前页数
  const [page, setPage] = useState(1);
  const [force, forceUpdate] = useState(1);
  const [open, setOpen] = useState([]);
  const [needView, setNeedView] = useState([]);
  const randomId = useState(Math.random())[0];
  // force;
  // const [ready, setReady] = useState(true);

  // if (ready) {
  //   ready = false;
  //   setTimeout(() => {
  //     setNeedView(tableData.slice((page - 1) * 10, page * 10).map((row, rowIndex) => {
  //       console.log(`[randomid="tableRow-${rowIndex}-${randomId}"]`);
  //       console.log(document.querySelector(`[randomid="tableRow-${rowIndex}-${randomId}"]`)?.scrollHeight);
  //       return document.querySelector(`[randomid="tableRow-${rowIndex}-${randomId}"]`)?.scrollHeight >= 60;
  //     }));
  //     setTimeout(() => {
  //       ready = true;
  //     }, 1000);
  //   }, 1000);
  // }
  //
  return (
    <Box
      id='dataTableRoot'
      sx={{
        border: '1px solid #DFE4E8',
        borderRadius: '8px',
        overflow: 'hidden',
        minWidth: '1092px',
        maxWidth: '1092px',
        padding: '0px',
      }}
    >
      <Box
        id='dataTableScrollContainer'
        sx={{
          overflowX: 'auto',
          width: '1092px',
          padding: '0px',
        }}
      >
        <TableContainer
          id='dataTableContainer'
          sx={{
            tableLayout: 'fixed',
            width: tableWidth,
          }}
        >
          <TableHead id='dataTableHead'>
            <TableRow id='dataTableRow'>
              {titleList.map(item => {
                return (
                  <StyledTableCell
                    sx={{
                      height: '42px',
                      width: item[1],
                      //styleName: MINI BOLD UPPERCASE;
                      'font-family': 'Open Sans',
                      'font-size': '12px',
                      'font-weight': '600',
                      'line-height': '18px',
                      'letter-spacing': '0.08em',
                      'text-align': 'left',
                      color: '#596A7C',
                      'box-shadow': '0px -1px 0px 0px #BCC5CF inset',
                      padding: '12px 24px 12px 24px',
                      whiteSpace: 'nowrap',
                      overflow: 'visible',
                      boxSizing: 'border-box',
                      borderBottom: 'none',
                    }}
                  >
                    {item[0]}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .slice((page - 1) * 10, page * 10)
              .map((rowItem, rowIndex) => {
                return (
                  <TableRow randomid={`tableRow-${rowIndex}-${randomId}`}>
                    {/* ID SHOULD NOT CHANGE !!! */}
                    {rowItem.map((item, index) => {
                      return (
                        <StyledTableCell
                          id={`tableCell-${rowIndex}-${index}`}
                          sx={{
                            width: titleList[index][1],
                            maxWidth: titleList[index][1],
                            height: needView[rowIndex] ? '83px' : '58px',
                            padding: '0px 24px 0px 24px',

                            color:
                              titleList[index][4] === 'url' ||
                              titleList[index][4] === 'nativeURL'
                                ? '#154AB6'
                                : '#262E35',
                            'font-weight':
                              titleList[index][4] === 'url' ||
                              titleList[index][4] === 'nativeURL'
                                ? '600'
                                : '400',
                            '&:hover':
                              titleList[index][4] === 'url' ||
                              titleList[index][4] === 'nativeURL'
                                ? {
                                    'font-weight': '700 !important',
                                  }
                                : {},
                          }}
                        >
                          {titleList[index][0] === 'SI CODE' ? (
                            item.type === 'Flags-Severe Issue' ? (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: '100%',
                                }}
                              >
                                <Box
                                  sx={{
                                    height: '20px',
                                    width: '102px',
                                    p: '2px 12px',
                                    background: '#EE5350',
                                    textAlign: 'center',
                                    borderRadius: '0 0 4px 4px',
                                    color: '#fff',
                                    fontSize: '12px',
                                    fontFamily: 'Open Sans',
                                    fontWeight: 600,
                                    lineHeight: '16px',
                                    mb: '2px',
                                  }}
                                >
                                  SEVERE&nbsp;ISSUE
                                </Box>
                                <div
                                  style={{
                                    lineHeight: '20px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    navigate(
                                      `/simt/auth/siRelated/si/detail/${item.code}`
                                    )
                                  }
                                >
                                  {item.code}
                                </div>
                              </Box>
                            ) : item.type === 'Flags-Mild Issue' ? (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: '100%',
                                }}
                              >
                                <Box
                                  sx={{
                                    height: '20px',
                                    width: '102px',
                                    p: '2px 17.5px 2px 18.5px',
                                    background: '#FFB152',
                                    textAlign: 'center',
                                    borderRadius: '0 0 4px 4px',
                                    color: '#fff',
                                    fontSize: '12px',
                                    fontFamily: 'Open Sans',
                                    fontWeight: 600,
                                    lineHeight: '16px',
                                    mb: '2px',
                                  }}
                                >
                                  MILD&nbsp;ISSUE
                                </Box>
                                <div
                                  style={{
                                    lineHeight: '20px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    navigate(
                                      `/simt/auth/siRelated/si/detail/${item.code}`
                                    )
                                  }
                                >
                                  {item.code}
                                </div>
                              </Box>
                            ) : (
                              <div
                                style={{
                                  lineHeight: '20px',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  navigate(
                                    `/simt/auth/siRelated/si/detail/${item.code}`
                                  )
                                }
                              >
                                {item.code}
                              </div>
                            )
                          ) : (
                            <div
                              id={`tableCell-${rowIndex}-${index}-Info`}
                              style={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                '-webkit-box-orient': 'vertical',
                                '-webkit-line-clamp': '2',
                                'line-clamp': '2',
                              }}
                              onClick={() => {
                                if (titleList[index][4] === 'nativeURL') {
                                  location.href = item;
                                }
                              }}
                            >
                              {titleList[index][4] === 'withMore' ? (
                                <MyHideText itemLength={item?.length > 100} label='View More' >
                                  {item}
                                </MyHideText>
                              ) : (
                                item
                              )}
                            </div>
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {tableData.length > 0 ? (
              <></>
            ) : (
              <TableRow
                style={{
                  display: 'flex',
                  height: '76px',
                }}
                id='NoDataMessageTableRow'
                data-selenium-id='Componentsphase2-NoDataMessage-TableRow'
              >
                <TableCell
                  style={{
                    height: '76px',
                    // left: '50%',
                    transform: 'translateX(500px)',
                    borderBottom: 'none',
                    backgroundColor: 'transparent',
                    alignItems: 'center',
                  }}
                  id='NoDataMessageTableCell'
                  data-selenium-id='Componentsphase2-NoDataMessage-TableCell'
                >
                  <Typography
                    style={{
                      fontFamily: 'Open Sans',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      fontSize: '16px',
                      lineHeight: '40px',
                      letterSpacing: '0.01em',
                      color: '#596A7C',
                    }}
                    id='NoDataMessageTypography'
                    data-selenium-id='Componentsphase2-NoDataMessage-Typography'
                  >
                    No Data
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableContainer>
      </Box>
      {tableData.length > 10 ? (
        <TableFooter2
          Page={page}
          perPageNumber={10}
          count={tableData.length}
          handlePageChange={(e, v) => {
            setPage(v);
          }}
          sx={{
            border: 'none',
          }}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

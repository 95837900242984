import { StrictMode, useState, type MouseEvent, type CSSProperties, type HTMLAttributes } from 'react';
import { Box, Popover, Stack, Typography } from '@mui/material';
import styleModule from './AIScreeningReportPage.module.scss';
import WarningSoild from '@/assets/WarningSoild.svg';
import PropTypes from 'prop-types';
const { freeze } = Object;
interface Props extends HTMLAttributes<HTMLElement> {
  readonly title: string;
  readonly id?: string;
  readonly content: string;
}
export default function AboutAI(props: Props) {
  const {
    title = '',
    id = 'SIPPage_AIScreeningReport-AboutAI',
    content = '',
    ...others
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <StrictMode>
      <Box className={styleModule.AboutAI} {...others}>
        <Typography
          id={`${id}-Title`}
          data-selenium-id={`${id}-Title`}
          className={styleModule.Title}
        >
          {title}
        </Typography>
        <Box
          onClick={handleClick}
          // onMouseOver={handleClick}
          // onMouseOut={handleClose}
          onMouseEnter={handleClick}
          onMouseLeave={handleClose}
          id={`${id}-Box`}
          data-selenium-id={`${id}-Box`}
          className={styleModule.AboutAIIcon}
          style={
            { '--pathColor': open ? '#113D95' : '#0052CC' } as CSSProperties
          }
        >
          <WarningSoild />
        </Box>
        <Popover
          id={`${id}-DropdownList`}
          data-selenium-id={`${id}-DropdownList`}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          className={styleModule.DropdownList}
          disableRestoreFocus
        >
          <Stack
            spacing={1.25}
            sx={{ p: '12px' }}
            id={`${id}-Stack`}
            data-selenium-id={`${id}-Stack`}
          >
            <Box
              id={`${id}-ContainerStack-TitleBox`}
              data-selenium-id={`${id}-ContainerStack-TitleBox`}
            >
              {content}
            </Box>
          </Stack>
        </Popover>
      </Box>
    </StrictMode>
  );
}
AboutAI.prototype = freeze({
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
  content: PropTypes.string.isRequired,
});
AboutAI.defaultProps = freeze({
  title: '',
  id: 'AboutAI',
  content: '',
});

import {
  Alert,
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Off from '../../../../../assets/Off.svg';
import Error from '../../../../../assets/requiredError.svg';
import Warning from '../../../../../assets/ExistedWarning.svg';
import { useNavigate } from 'react-router-dom';
import { setShowViewMore } from '@/actions/SIMT-SI/GuestEditor/GEAction';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import { useIntl } from 'react-intl';
/**
 * @type {import("@mui/material").SxProps}
 */
const formControlStyle = {
  width: '100%',
  '& .MuiInputLabel-root': {
    position: 'static',
  },
  legend: {
    display: 'none',
  },
  '& .MuiOutlinedInput-root.MuiInputBase-root': {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: '9px',
    minHeight: '40px',
    alignItems: 'center',
    fontSize: '14px',
    border: '1px solid #BCC5CF',
    '&.Mui-focused': {
      borderWidth: '2px',
      borderColor: '#154AB6',
    },
    // overflowX: "scroll",
    '& .MuiOutlinedInput-input.MuiInputBase-input': {
      minHeight: '40px',
    },
    '& fieldset': {
      border: 'none',
    },
    '& .MuiSelect-select.MuiSelect-outlined': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      paddingRight: 0,
    },
  },
  '& .MuiFormLabel-asterisk': {
    color: '#C40000',
  },
};
/** @type {import("react").CSSProperties} */
const inputLabelStyle = {
  fontSize: '12px',
  transform: 'none',
  fontWeight: '600',
  marginBottom: '8px',
  letterSpacing: '0.08em',
};
/**
 *
 * @param {import("./CustomTextField").CustomFormControlProps} props
 * @returns
 */
export const CustomFormControl = ({ sx, ...others }) => {
  return (
    <FormControl
      {...others}
      sx={{
        ...formControlStyle,
        ...sx,
      }}
      data-selenium-id={
        'GEPage_Overview-CreateGE-CustomTextField-CustomFormControl'
      }
    />
  );
};
/**
 *
 * @param {import("./CustomTextField").CustomInputLabelProps} props
 * @returns
 */
export const CustomInputLabel = ({ sx, ...others }) => {
  return (
    <InputLabel
      {...others}
      shrink
      sx={{
        ...inputLabelStyle,
        ...sx,
      }}
      data-selenium-id={
        'GEPage_Overview-CreateGE-CustomTextField-CustomInputLabel-InputLabel'
      }
    />
  );
};
/**
 *
 * @param {import("./CustomTextField").CreateGETextFieldBaseProps} props
 * @returns
 */
export const CreateGETextFieldBase = props => {
  const { sx, value, clear, ...others } = props;

  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);

  return (
    <TextField
      fullWidth
      InputLabelProps={{
        shrink: true,
        sx: {
          transform: 'none',
        },
      }}
      sx={{
        ...sx,
        ...{
          '& .MuiOutlinedInput-root': {
            minHeight: '40px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            top: 0,
          },
          '& .MuiFormHelperText-root': {
            ml: 0,
            mt: '2px',
          },
          '& legend': {
            display: 'none',
          },
        },
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              disableRipple
              sx={{ p: '4px', mt: '-2.5px' }}
              onClick={clear}
            >
              {(hover || focus) && value && <Off />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={value}
      {...others}
      data-selenium-id={
        'GEPage_Overview-CreateGE-CustomTextField-CreateGETextFieldBase-TextField'
      }
    />
  );
};

/**
 * @param {import("./CustomTextField").CreateGETextFieldProps} props
 * @returns
 */
export const CreateGETextField = props => {
  const {
    value,
    required,
    showErrorMessage = false,
    setError = () => {},
    constraintPattern,
    inputType,
    setValue,
    clear,
    error,
    sameEmailError = false,
    helperText='',
    ...others
  } = props;

  const [totalError, setTotalError] = useState(false);
  const [requiredError, setRequiredError] = useState(false);
  const [constraintPatternError, setConstraintPatternError] = useState(false);
  const intl = useIntl();

  const validator = require('validator');
  useEffect(() => {
    if (typeof value !== 'string') return;
    if (required) {
      setRequiredError(value === '');
    }
    if (required || (!required && value !== '')) {
      if (constraintPattern) {
        setConstraintPatternError(!constraintPattern.test(value));
      } else if (inputType) {
        switch (inputType) {
          case 'Primary Email':
          case 'Secondary Email':
            setConstraintPatternError(
              !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
                value
              )
            );
            break;
          case 'URL':
            setConstraintPatternError(!validator.isURL(value));
            break;
        }
      }
    } else {
      setConstraintPatternError(false);
    }
  }, [value]);

  useEffect(() => {
    setTotalError(requiredError || constraintPatternError);
    setError(requiredError || constraintPatternError);
  }, [requiredError, constraintPatternError]);

  const getErrorMessage = () => {
    if (requiredError) return 'Please enter this field.';
    if (constraintPatternError) {
      if (constraintPattern)
        return 'The value format is invalid. Please enter a valid value.';
      else {
        switch (inputType) {
          case 'Primary Email':
          case 'Secondary Email':
            return 'The mail format is invalid.Please enter a valid email address.';
          case 'URL':
            return 'The profile website must be a valid URL.';
          default:
            return '';
        }
      }
    }
    if (sameEmailError) {
      switch (inputType) {
        case 'Primary Email':
          return intl.messages['gePage.primaryEmailSameToSecondaryEmailError'];
        case 'Secondary Email':
          return intl.messages['gePage.secondaryEmailSameToPrimaryEmailError'];
        default:
          return '';
      }
    }
    return helperText || '';
  };

  const isWarn = (
    getErrorMessage()?.trim() === intl.messages['gePage.GEEmailAlreadlyInDatabaseError']
      || getErrorMessage()?.trim() === intl.messages['gePage.primaryEmailSameToSecondaryEmailError']
      || getErrorMessage()?.trim() === intl.messages['gePage.secondaryEmailSameToPrimaryEmailError']
  );
  return (
    <MyOnlyText
      id={props.id}
      value={value}
      setValue={setValue}
      width='100%'
      height='40px'
      needEndAdornment={true}
      handleIconButtonClick={clear}
      isError={!isWarn && showErrorMessage && (totalError || error)}
      errorMessage={getErrorMessage()}
      isWarning={isWarn}
      warningMessage={getErrorMessage()}
    />
  );
};

/**
 *
 * @param {import("./CustomTextField").CreateGEOnlineEmailVerifyTextFieldProps} props
 * @returns
 */
export const CreateGEOnlineEmailVerifyTextField = props => {
  const {
    checkEmailStateSelector,
    checkEmailAction,
    value,
    sameEmailError,
    inputType,
    error,
    setError = () => {},
    helperText,
    showErrorMessage,
    setValue,
    ...others
  } = props;
  const { isEmailMessage, isEmailMessageGet } = useSelector(
    checkEmailStateSelector
  );
  const dispatch = useDispatch();

  const [emailDuplicatedError, setEmailDuplicatedError] = useState(false);
  const [lowOrderError, setLowOrderError] = useState(false);

  useEffect(() => {

    if (isEmailMessage === '' || isEmailMessage === 'error') return;
    setEmailDuplicatedError(
      isEmailMessage.message === 'the email address has been registered'
    );
  }, [isEmailMessageGet]);

  useEffect(() => {
    if (typeof value !== 'string') return;

    const checkEmailTimer = setTimeout(() => {
      dispatch(checkEmailAction(value));
    }, 100);

    return () => {
      clearTimeout(checkEmailTimer);
    };
  }, [value]);

  useEffect(() => {
    setError(error || emailDuplicatedError || lowOrderError);
  }, [error, emailDuplicatedError, lowOrderError]);

  const getErrorMessage = () => {
    if (emailDuplicatedError)
      return 'The Guest Editor has been added in database';
    return helperText || '';
  };
  {
    /* This guest editor exists. &nbsp;
          <Link
            href={`/simt/auth/siRelated/ge/detail/${value}`}
            target='_blank'
          >
            Click here to navigate to detail page.
          </Link> */
  }

  // const showViewMore = useSelector(state => state.GE.showViewMore);
  const [showViewMore, setShowViewMore] = useState(false);
  const handleClose = () => setShowViewMore(false);
  useEffect(() => {
    if (emailDuplicatedError) setShowViewMore(true);
    else setShowViewMore(false);
  }, [emailDuplicatedError]);

  const navigate = useNavigate();

  return (
    <>
      <CreateGETextField
        value={value}
        sameEmailError={sameEmailError}
        error={error || emailDuplicatedError || lowOrderError}
        setError={setLowOrderError}
        inputType={inputType}
        helperText={getErrorMessage()}
        showErrorMessage={showErrorMessage}
        setValue={setValue}
        {...others}
      />
      <Snackbar
        open={showViewMore}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          top: '36px !important',
        }}
      >
        <Alert
          onClose={handleClose}
          severity='info'
          action=''
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            width: '546px',
            height: '118px',
            bgcolor: '#E8EDFB',
            border: '1px solid #4C81EB',
            boxShadow: '0px 12px 24px rgba(38,46,53,0.12)',
            p: '16px 32px 16px 24px',
            borderRadius: '5px',
            '& .MuiAlert-icon': {
              color: '#154AB6',
              p: 0,
            },
            '& .MuiAlert-message': {
              p: 0,
            },
          }}
        >
          <Stack>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '17px',
                color: '#154AB6',
              }}
            >
              The Guest Editor has been added in database.
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '17px',
                color: '#596A7C',
                mt: '12px',
              }}
            >
              View more details about this Guest Edtor
            </Typography>
            <Box
              display={'flex'}
              sx={{
                gap: '18px',
                mt: '20px',
              }}
            >
              <Typography
                sx={{
                  cursor: 'pointer',
                  fontFamily: 'Inter',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#154AB6',
                }}
                onClick={() =>
                  // navigate(`/simt/auth/siRelated/ge/detail/${value}`)
                  window.open(
                    `/simt/auth/siRelated/ge/detail/${value}`,
                    '_blank'
                  )
                }
              >
                View more
              </Typography>
              <Typography
                sx={{
                  cursor: 'pointer',
                  fontFamily: 'Inter',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#154AB6',
                }}
                onClick={handleClose}
              >
                Dismiss
              </Typography>
            </Box>
          </Stack>
        </Alert>
      </Snackbar>
    </>
  );
};

import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Tooltip,
  TableCell,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '../../../../components/DataTable';
import {
  StyledTableCellPurple,
  StyledTableCellDisPurple,
  StyledTableRow, StyledTableCellDisGray, StyledTableCellNormal, StyledTableCellGray, TableBox, ReportNoData, TableBox2
} from "../../ReportSpecificComponents/SpecialColorTableHeader";
import '../../../../App.css';
import { findLongestLineSubstring, measureWidth } from "../../../../utils/StringWidthMeasure";
import React from "react";
import { SimpleTooltip } from "../../../../componentsphase2/CustomTooltip";
import {getAllBusinessDivision} from "@/actions/SIMT-Report/Report/ReportAcquiredAndActive";

function BusinessDivisionTable() {
  const { selectMonthDate, OAvsTotalBDTableData } = useSelector(state => {
    return {
      // selectMonthDate: state.Report.OAvsTotalSelectDate,
      selectMonthDate: state.Report.selectDate,
      OAvsTotalBDTableData: state.Report.OAvsTotalBusinessDivisionResult,
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    widthMap['businessDivision'] = measureWidth(findLongestLineSubstring('Business Division'), '18px Open Sans');

    const ALL_LEN = measureWidth('All', '18px Open Sans');
    const OA_LEN = measureWidth('OA', '18px Open Sans');
    const OAP_LEN = measureWidth('OA %', '18px Open Sans');

    for (let type of ['Current','YTD','Active']) {
      widthMap[`${type}-SI`] = ALL_LEN;
      widthMap[`${type}-OA`] = OA_LEN;
      widthMap[`${type}-%`] = OAP_LEN;
    }

    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (OAvsTotalBDTableData && OAvsTotalBDTableData.length > 0){
      const maxMap = {};
      for (let i = 0; i < OAvsTotalBDTableData.length; i++) {
        let row = OAvsTotalBDTableData[i];
        let dataObj = {
          businessDivision: row.businessDivision,

          'Current-SI':  row.acquiredSi ?? '0',
          'Current-OA':  row.acquiredSiOa ?? '0',
          'Current-%':  row.againstAcquiredSiOa ?? '0',

          'YTD-SI':  row.acquiredYtd ?? '0',
          'YTD-OA':  row.acquiredYtdOa ?? '0',
          'YTD-%':  row.againstYTDOa ?? '0',

          'Active-SI':  row.activeSi ?? '0',
          'Active-OA':  row.activeSiOa ?? '0',
          'Active-%':  row.againActiveSiOa ?? '0',
        };
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [OAvsTotalBDTableData]);

  function calcGroupWidth(type){
    let sum = 0;
    for (let item of ['SI','OA','%']) {
      sum += Math.max((columnWidthMap[`${type}-${item}`]??0), columnMinWidthMap[`${type}-${item}`]) + 50;
    }
    return sum;
  }

  const [headers, setHeaders] = useState({
    AcquiredSls: [],
    AcquiredSlsYTD: [
      {
        // label: 'Acquired SIs - YTD',
        id: 'Acquired SIs - YTD',
        label: 'All',
        minWidth: '90px',
        maxWidth: '90px',
      },
      {
        // label: 'Acquired SIs - YTD OA',
        id: 'Acquired SIs - YTD OA',
        label: 'OA',
        minWidth: '90px',
        maxWidth: '90px',
      },
      {
        label: 'OA %',
        id: 'OA %',
        minWidth: '100px',
        maxWidth: '100px',
      },
    ],
    ActiveSls: [
      {
        id: 'Active SIs',
        // id: 'Active SIs',
        label: 'All',
        minWidth: '90px',
        maxWidth: '90px',
      },
      {
        // label: 'Active SIs OA',
        id: 'Active SIs OA',
        label: 'OA',
        minWidth: '90px',
        maxWidth: '90px',
      },
      {
        label: 'OA %',
        id: 'OA %',
        minWidth: '100px',
        maxWidth: '100px',
      },
    ],
  });
  const [currentMonthString, setCurrentMonthString] = useState('');
  const dispatch = useDispatch();

  const monthList = [
    'JAN',
    'FEB',
    'MAR',
    ' APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    ' NOV',
    'DEC',
  ];

  useEffect(() => {
    if (selectMonthDate !== null) {
      var num = selectMonthDate.split('-')[1];
      var MonthString = monthList[num - 1];
      setCurrentMonthString(MonthString);
      var tableHead = [
        {
          // label: 'Acquired SIs -' + MonthString,
          id: 'Acquired SIs -' + MonthString,
          label: 'All',
          minWidth: '90px',
          maxWidth: '90px',
        },
        {
          // label: 'Acquired SIs OA -' + MonthString,
          id: 'Acquired SIs OA -' + MonthString,
          label: 'OA',
          minWidth: '90px',
          maxWidth: '90px',
        },
        {
          label: 'OA %',
          id: 'OA %',
          minWidth: '100px',
          maxWidth: '100px',
        },
      ];
      setHeaders({
        ...headers,
        AcquiredSls: tableHead,
      });
      dispatch(getAllBusinessDivision(selectMonthDate));
    }
  }, [selectMonthDate]);

  const displayHeader = title => {
    let type = '';
    switch (title) {
      case 'AcquiredSls':
        type = 'Current';
        break;
      case 'AcquiredSlsYTD':
        type = 'YTD';
        break;
      case 'ActiveSls':
        type = 'Active';
        break;
    }
    return headers[title].map(item => {
      if (item.id.indexOf('OA %') !== -1) {
        return (
          <StyledTableCellDisGray
            align='left'
            key={item.id}
            sx={{
              top: 42,
              // minWidth: item.minWidth,
              // maxWidth: item.maxWidth,
              ...(columnWidthMap[`Current-${item.label}`] && {
                width: `${Math.max((columnWidthMap[`${type}-${item.label}`]??0), columnMinWidthMap[`Current-${item.label}`]) + 50 }px`,
              }),
            }}
            className='RightBottomBorder'
            data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-DisplayHeader-StyledTableCellDisPurple'
          >
            {item.label}
          </StyledTableCellDisGray>
        );
      } else {
        return (
          <StyledTableCellDisGray
            align='left'
            key={item.id}
            sx={{
              top: 42,
              // minWidth: item.minWidth,
              // maxWidth: item.maxWidth,
              ...(columnWidthMap[`Current-${item.label}`] && {
                width: `${Math.max((columnWidthMap[`${type}-${item.label}`]??0), columnMinWidthMap[`Current-${item.label}`]) + 50 }px`,
              }),
            }}
            className='BottomBorder'
            data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-DisplayHeader-StyledTableCellDisPurple'
          >
            {item.label}
          </StyledTableCellDisGray>
        );
      }
    });
  };

  return (
    <TableBox2
      id='reportAAASOATBDTable'
      data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-Box'
      dataList={[OAvsTotalBDTableData]}
      tableKey={'Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable'}
      footerHeight={0}
    >
      <TableContainer
        sx={{ maxHeight: '560px' }}
        data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableContainer'
      >
        <Table
          stickyHeader
          aria-label='sticky table'
          data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-Table'
          sx={{
            tableLayout: 'fixed',
            width: 'max-content',
            maxWidth: 'none',
          }}
        >
          <TableHead data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableHead'>
            <TableRow data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableRow1'>
              <StyledTableCellNormal
                sx={{
                  width: `${Math.max((columnWidthMap['businessDivision']??0), columnMinWidthMap['businessDivision']) + 50 }px`,
                  position: 'sticky',
                  left: 0,
                  top: 0,
                  zIndex: 8,
                  backgroundColor: '#DFE4E8',
                }}
                className='RightBottomBorder'
                rowSpan={2}
                align='left'
                data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-StyledTableCellPurple1'
              >
                Business Division
              </StyledTableCellNormal>
              <StyledTableCellGray
                align='left'
                colSpan={3}
                className='RightBottomBorder ReportHeaderFirstLine'
                data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-StyledTableCellPurple2'
                sx={{
                  width: `${calcGroupWidth('Current')}px`,
                }}
              >
                Acquired SI - {currentMonthString}
              </StyledTableCellGray>
              <StyledTableCellGray
                align='left'
                colSpan={3}
                className='RightBottomBorder ReportHeaderFirstLine'
                data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-StyledTableCellPurple3'
                sx={{
                  width: `${calcGroupWidth('YTD')}px`,
                }}
              >
                Acquired SI - YTD
              </StyledTableCellGray>
              <StyledTableCellGray
                align='left'
                colSpan={3}
                className='RightBottomBorder ReportHeaderFirstLine'
                data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-StyledTableCellPurple4'
                sx={{
                  width: `${calcGroupWidth('Active')}px`,
                }}
              >
                Active SI
              </StyledTableCellGray>
            </TableRow>
            <TableRow data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableRow2'>
              {displayHeader('AcquiredSls')}
              {displayHeader('AcquiredSlsYTD')}
              {displayHeader('ActiveSls')}
            </TableRow>
          </TableHead>
          <TableBody data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody'>
            {OAvsTotalBDTableData != null &&
            OAvsTotalBDTableData != undefined &&
            OAvsTotalBDTableData.length != 0 ? (
              OAvsTotalBDTableData.map(row => {
                if (row.businessDivision === 'Total') {
                  return (
                    <StyledTableRow data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableRow'>
                      <StyledTableCell
                        align='right'
                        className='RightBorder ReportTotalTextSize'
                        data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell1'
                        sx={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 6,
                          backgroundColor: '#F1F3F5',
                        }}
                      >
                        <SimpleTooltip title={row.businessDivision}>
                            < >{row.businessDivision}</ >
                          </SimpleTooltip>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportTotalTextSize ReportNumberTextColor'
                        data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell2'
                      >
                        < >
                          {row.acquiredSi === null ? '0' : row.acquiredSi}
                        </ >
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportTotalTextSize ReportNumberTextColor'
                        data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell3'
                      >
                        < >
                          {row.acquiredSiOa === null ? '0' : row.acquiredSiOa}
                        </ >
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportTotalTextSize ReportNumberTextColor'
                        data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell4'
                      >
                        < >{row.againstAcquiredSiOa}</ >
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportTotalTextSize ReportNumberTextColor'
                        data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell5'
                      >
                        < >
                          {row.acquiredYtd === null ? '0' : row.acquiredYtd}
                        </ >
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportTotalTextSize ReportNumberTextColor'
                        data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell6'
                      >
                        < >
                          {row.acquiredYtdOa === null ? '0' : row.acquiredYtdOa}
                        </ >
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportTotalTextSize ReportNumberTextColor'
                        data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell7'
                      >
                        < >{row.againstYTDOa}</ >
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportTotalTextSize ReportNumberTextColor'
                        data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell8'
                      >
                        < >
                          {row.activeSi === null ? '0' : row.activeSi}
                        </ >
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportTotalTextSize ReportNumberTextColor'
                        data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell9'
                      >
                        < >
                          {row.activeSiOa === null ? '0' : row.activeSiOa}
                        </ >
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportTotalTextSize ReportNumberTextColor'
                        data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell10'
                      >
                        < >{row.againActiveSiOa}</ >
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                }
                return (
                  <StyledTableRow data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableRow'>
                    <StyledTableCell
                      align='left'
                      className='RightBorder'
                      data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell1'
                      sx={{
                        position: 'sticky',
                        left: 0,
                        zIndex: 6,
                        backgroundColor: '#F1F3F5',
                      }}
                    >
                      <SimpleTooltip title={row.businessDivision}>
                          {row.businessDivision}
                        </SimpleTooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell2'
                    >
                      {row.acquiredSi === null ? '0' : row.acquiredSi}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell3'
                    >
                      {row.acquiredSiOa === null ? '0' : row.acquiredSiOa}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='RightBorder ReportNumberTextColor'
                      data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell4'
                    >
                      {row.againstAcquiredSiOa}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell5'
                    >
                      {row.acquiredYtd === null ? '0' : row.acquiredYtd}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell6'
                    >
                      {row.acquiredYtdOa === null ? '0' : row.acquiredYtdOa}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='RightBorder ReportNumberTextColor'
                      data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell7'
                    >
                      {row.againstYTDOa}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell8'
                    >
                      {row.activeSi === null ? '0' : row.activeSi}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell9'
                    >
                      {row.activeSiOa === null ? '0' : row.activeSiOa}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='RightBorder ReportNumberTextColor'
                      data-selenium-id='Report_AcquiredAndActiveSi-OAvsTotal-BusinessDivisionTable-TableBody-StyledTableCell10'
                    >
                      {row.againActiveSiOa}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <ReportNoData id='reportJournalSGNoResultTableCell' />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TableBox2>
  );
}
export default BusinessDivisionTable;

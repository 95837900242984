import styles from './index.module.scss';
import {Box, Snackbar} from "@mui/material";

import ErrorMessageIcon from '../../assets/error-message-icon.svg';
import React, {ReactElement} from "react";

export interface ErrorAlertProps {
    open: boolean;
    onClose: () => void;
    autoHideDuration?: number;
    title: string;
    message: string;
    buttonGroups?: ReactElement<any>[];
}

export function ErrorAlert(props: ErrorAlertProps) {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={props.open}
            autoHideDuration={props.autoHideDuration}
            onClose={props.onClose}
            className={styles['error-alert-snackbar']}
        >
            <Box className={styles['error-alert-body']}>
                 <Box className={styles['error-alert-icon']}>
                    <ErrorMessageIcon />
                 </Box>
                 <Box className={styles['error-alert-content']}>
                    <Box className={styles['error-alert-title']}>
                        {props.title}
                    </Box>
                    <Box className={styles['error-alert-message']}>
                        {props.message}
                    </Box>
                     {props.buttonGroups && props.buttonGroups.length > 0 && (
                         <Box className={styles['error-alert-button-group']}>
                             {props.buttonGroups.map((button, index) => (
                                 React.cloneElement(button, {
                                     key: index,
                                     className: styles['error-alert-button']
                                 })
                             ))}
                         </Box>
                     )}
                 </Box>
            </Box>
        </Snackbar>
    );
}
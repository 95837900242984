import {
    CheckedType,
    CheckListItemBlock, ComputedComponent, extractHtmlId,
    FormComponentProps,
    useForm, VALIDATE_KEY,
    ValidateState
} from "@/components/Form/form.d";
import React, {ReactElement, useCallback, useEffect} from "react";
import ChecklistItemBlock from "@/pages/SIPPage/SIPCheckListPage/compontents/ChecklistItemBlock";
import RadioBlock from "@/pages/SIPPage/SIPCheckListPage/compontents/RadioBlock";
import OnlyTextBlock from "@/pages/SIPPage/SIPCheckListPage/compontents/OnlyTextBlock";
import StyledLink from "@/componentsphase2/StyledLink";
import {CHECKLIST_PROCEEDING} from "@/pages/SIPPage/SIPCheckListPage/configable/constants";
import { ChecklistStatus } from "@/components/Form/form.d";


export interface CheckListItemProps extends FormComponentProps {
    required?: boolean;
    label: string;
    info: string;
    optionsLabel?: string;
    errorMessage?: string;
}

export interface LinkedCheckListItemProps extends CheckListItemProps {
    links?: {
        url: string,
        label: string,
    }[];
    commentReverse?: boolean;
}

export function CheckListItem(props: CheckListItemProps): ReactElement{
    return <LinkedCheckListItem {...props} links={[]} />;
}

export function ReverseCommentCheckListItem(props: CheckListItemProps): ReactElement{
    return <LinkedCheckListItem {...props} links={[]} commentReverse={true} />;
}

function LinkedCheckListItemBase(props: LinkedCheckListItemProps): ReactElement{
    const htmlId = extractHtmlId(props);
    
    const {
        value: block,
        setValue: setBlock,
        form,
        setState,
        getState,
    } = useForm<CheckListItemBlock>({
        name: props.name,
        initValue: {
            checked: null,
            comment: "",
        },
        initStates: {
            ['html-id']: htmlId,
            [VALIDATE_KEY]: 'default',
            required: props.required === true,
        }
    });

    const optionsLabel = props.optionsLabel || "Qualified:";
    const errorMessage = props.errorMessage || ' Please provide the required information.';

    const status = form?.root().status;
    const locked = form?.root().locked;
    const editable = status === ChecklistStatus.IN_PROGRESS && !locked;

    const proceeding = form?.root()[CHECKLIST_PROCEEDING] ?? false;

    const isRequired = getState('required');

    useEffect(() => {
        const validate = (): ValidateState => {
            if (block?.checked === null) {
                return isRequired ? (proceeding ? 'error' : 'default') : 'default';
            }

            if ((block?.comment?.length ?? 0) > 100000) {
                return 'error';
            }

            if (props?.commentReverse ?? false) {
                if (block?.checked === 'No' ||
                    (block?.checked === 'Yes' && (block?.comment?.length ?? 0) > 0)) {
                    return 'checked';
                }
            } else {
                if (block?.checked === 'Yes' ||
                    (block?.checked === 'No' && (block?.comment?.length ?? 0) > 0)) {
                    return 'checked';
                }
            }

            if (isRequired) {
                return proceeding ? 'error' : 'default';
            } else {
                return 'default';
            }
        };

        setState(VALIDATE_KEY, validate());
        // setState('required', isRequired);
    }, [block, proceeding, isRequired]);

    useEffect(() => {
        if(props.required === true || props.required === false){
            if (isRequired !== props.required){
                setState('required', props.required);
            }
            return;
        }
    }, [form?.root()?.version, props.required]);

    const clearAll = () => {
        setBlock({
            checked: null,
            comment: "",
        });
    };

    const setChecked = (checked: CheckedType) => {
        setBlock({
            ...block,
            checked,
        });
    };

    const setComment = (comment: string) => {
        setBlock({
            ...block,
            comment,
        });
    };

    const links = (props.links ?? []).map((link, index) => (
        <StyledLink to={link.url} target='_blank'>{link.label}</StyledLink>
    ));

    function createStringWithLinks(template: string, links: JSX.Element[]) {
        // use Regex to match the {links[n]} pattern in the template string
        const regex = /\{links\[(\d+)]}/g;

        // replace the {links[n]} to the actual link element
        const combined = template.split(regex).map((part, index) => {
            if (index % 2 === 1) {
                // if part is a number，then it should be the index of the links array
                const linkIndex = parseInt(part);
                return links[linkIndex];
            } else {
                // else: part is the text part of the template
                return part;
            }
        });

        return <span>{combined}</span>;
    }

    const title = links.length === 0 ? props.label : createStringWithLinks(props.label, links);
    const info = links.length === 0 ? props.info : createStringWithLinks(props.info, links);

    return (<>
        <ChecklistItemBlock
            id={htmlId}
            title={title}
            necessary={isRequired || false}
            info={info}
            status={getState(VALIDATE_KEY) ?? 'default'}
            needClear={editable && !isRequired || false}
            handleClear={clearAll}
        >
            <RadioBlock
                title={optionsLabel}
                editing={editable}
                value={block?.checked}
                setValue={setChecked}
                radioLabelList={['Yes', 'No']}
                error={ isRequired && proceeding && block?.checked == null}
            />
            <OnlyTextBlock
                title={editable ? 'Comments' : 'Comments:'}
                required={isRequired && ((props?.commentReverse && block?.checked == 'Yes') || ( !(props?.commentReverse ?? false ) && block?.checked == 'No'))}
                value={block?.comment}
                setValue={setComment}
                editing={editable}
                isError={getState(VALIDATE_KEY) == 'error' || false}
                errorMessage={errorMessage}
                radioValue={props?.commentReverse ? ( 'Yes' ) : block?.checked}
            />
        </ChecklistItemBlock>
    </>
    );
}

export const LinkedCheckListItem = ComputedComponent<LinkedCheckListItemProps>(LinkedCheckListItemBase);
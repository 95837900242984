import { Box, Stack, Typography,SvgIcon, } from '@mui/material';
import UploadIcon2 from '@/assets/uploadIcon2.svg';
import React, { useEffect, useState, useRef } from "react";
export function ErrorBox(props){
  const {
    maxSize,
    allowTypes,
    height,
    onfileSelect=()=>{},
  } = props;
  const fileInputRef = useRef(null);
  return (
    <div style={{ position: 'relative', width: '100%',height: height, }}>
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          width: '100%',
          height: height,
          borderRadius: '4px',
          border: '2px dashed #C40000',
          backgroundColor:'#FFFFFF',
          display:'flex',
          flexWrap:'wrap',
          justifyContent:'space-around',
        }}
      >
        <input 
          type="file" 
          id="hiddenFilesInput" 
          accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
          name="file" 
          onChange={(e)=>{onfileSelect(e);}} 
          ref={fileInputRef}
          style={{ display: 'none' }} />
        <Stack
          direction='row'
          sx={{marginTop:  '12px' , height: '18px' }}
        >
          <SvgIcon viewBox='0 0 18 18' sx={{ width: '18px', height: '18px' }}>
            {' '}
            <UploadIcon2 sx={{ width: '18px', height: '18px' }}></UploadIcon2>
          </SvgIcon>
          <p
            style={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '18px',
              color: '#596A7C',
              height: '18px',
              marginTop: '0px',
              marginBottom: '0px',
              paddingLeft: '8px',
            }}
          >
            Drag & Drop or&nbsp;
            <span
              style={{
                color: '#154AB6',
                fontWeight: 400,
              }}
              onMouseEnter={e => {
                e.target.style.color = '#113D95';
                e.target.style.fontWeight = 600;
              }}
              onMouseLeave={e => {
                e.target.style.color = '#154AB6';
                e.target.style.fontWeight = 400;
              }}
              onClick={()=>{fileInputRef.current.click();}}
            >
              Choose File
            </span>{' '}
            to upload
          </p>
        </Stack>
        <Stack
          direction='row'
          sx={{ marginBottom:  '42px' , height: '15px' }}
        >
          <p
            style={{
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '15px',
              color: '#EE5350',
              height: '15px',
              marginTop: '0px',
              marginBottom: '0px',
            }}
          >
            Please upload files in supported format:<br></br>{allowTypes}.<br></br>Maximum size:&nbsp;
            <span
              style={{
                fontWeight: 700,
              }}
            > {maxSize} </span>
          </p>
        </Stack>
      </div>
    </div>
  ); 
}

import { setDate } from '@/actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import Item from '../../Framework/Block/Item';
import Section from '../../Framework/Block/Section';
import DateRangePicker from '../../../../../../../components/DateRangePicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearTime,
  formatDateString,
  getUTCZeroTimeOffset,
} from '@/utils/commonUtils';
import {useContext} from 'react';
import {isCNmarketingContext} from '@/Route';

export default function ThirdSection(props) {
  const dispatch = useDispatch();
  const { siFilterSelected } = useSelector(state => {
    return {
      siFilterSelected: state.Filter.siFilterSelected,
    };
  });

  const handleSetAcquireDateNull = newDate => {
    dispatch(setDate('acquire', 'to', null));
    dispatch(setDate('acquire', 'from', null));
  };

  const handleSetDeadlineDateNull = newDate => {
    dispatch(setDate('deadline', 'to', null));
    dispatch(setDate('deadline', 'from', null));
  };
  const handleSetAPDateNull = newDate => {
    dispatch(setDate('apDate', 'to', null));
    dispatch(setDate('apDate', 'from', null));
  };
  const handleSetCreateDateNull = newDate => {
    dispatch(setDate('createDate', 'to', null));
    dispatch(setDate('createDate', 'from', null));
  };
  const handleSetAcquireDateFrom = newDate => {
    const newDateWithoutTime = getUTCZeroTimeOffset(clearTime(newDate));
    // console.log("Date",newDateWithoutTime)
    if (
      formatDateString(newDateWithoutTime) >
      formatDateString(siFilterSelected.acquiredToDate)
    ) {
      // If the From date is greater than the to date, the to date is null
      dispatch(setDate('acquire', 'to', null));
    }
    dispatch(setDate('acquire', 'from', newDateWithoutTime)); // Set the date of the current click
  };

  const handleSetAcquireDateTo = newDate => {
    const newDateWithoutTime = getUTCZeroTimeOffset(clearTime(newDate));
    if (
      newDate === null ||
      formatDateString(newDateWithoutTime) >=
        formatDateString(siFilterSelected.acquiredFromDate)
    ) {
      dispatch(setDate('acquire', 'to', newDateWithoutTime));
    }
  };

  const handleSetDDLDateFrom = newDate => {
    const newDateWithoutTime = getUTCZeroTimeOffset(clearTime(newDate));
    if (
      formatDateString(newDateWithoutTime) >
      formatDateString(siFilterSelected.deadlineToDate)
    ) {
      // If the From date is greater than the to date, the to date is null
      dispatch(setDate('deadline', 'to', null));
    }
    dispatch(setDate('deadline', 'from', newDateWithoutTime)); // Set the date of the current click
  };

  const handleSetDDLDateTo = newDate => {
    if (newDate === null) {
      dispatch(setDate('deadline', 'to', null));
    } else {
      const newDateWithoutTime = getUTCZeroTimeOffset(clearTime(newDate));
      if (
        formatDateString(newDateWithoutTime) >=
        formatDateString(siFilterSelected.deadlineFromDate)
      ) {
        dispatch(setDate('deadline', 'to', newDateWithoutTime));
      }
    }
  };
  const handleSetAPDateFrom = newDate => {
    const newDateWithoutTime = getUTCZeroTimeOffset(clearTime(newDate));
    if (
      formatDateString(newDateWithoutTime) >
      formatDateString(siFilterSelected.apToDate)
    ) {
      // If the From date is greater than the to date, the to date is null
      dispatch(setDate('apDate', 'to', null));
    }
    dispatch(setDate('apDate', 'from', newDateWithoutTime)); // Set the date of the current click
  };

  const handleSetAPDateTo = newDate => {
    if (newDate === null) {
      dispatch(setDate('apDate', 'to', null));
    } else {
      const newDateWithoutTime = getUTCZeroTimeOffset(clearTime(newDate));
      if (
        formatDateString(newDateWithoutTime) >=
        formatDateString(siFilterSelected.apFromDate)
      ) {
        dispatch(setDate('apDate', 'to', newDateWithoutTime));
      }
    }
  };
  const handleSetCreateDateFrom = newDate => {
    const newDateWithoutTime = getUTCZeroTimeOffset(clearTime(newDate));
    if (
      formatDateString(newDateWithoutTime) >
      formatDateString(siFilterSelected.createToDate)
    ) {
      // If the From date is greater than the to date, the to date is null
      dispatch(setDate('createDate', 'to', null));
    }
    dispatch(setDate('createDate', 'from', newDateWithoutTime)); // Set the date of the current click
  };

  const handleSetCreateDateTo = newDate => {
    if (newDate === null) {
      dispatch(setDate('createDate', 'to', null));
    } else {
      const newDateWithoutTime = getUTCZeroTimeOffset(clearTime(newDate));
      if (
        formatDateString(newDateWithoutTime) >=
        formatDateString(siFilterSelected.createFromDate)
      ) {
        dispatch(setDate('createDate', 'to', newDateWithoutTime));
      }
    }
  };
  const isCNmarketing = useContext(isCNmarketingContext) ?? false;
  return (
    <Section id='thirdSection' isCNmarketing={isCNmarketing}>
      <Item
        id='acquiredDate'
        title='Acquired Date'
        needResetBtn={true}
        handleReset={handleSetAcquireDateNull}
        isCNmarketing={isCNmarketing}
      >
        <DateRangePicker
          id='siPageFilterDrawerAcquiredDateTimeRangeInput'
          data-selenium-id='SIPage_Overview-newFilterDrawer-Overview-AcquiredDate-TimeRangeInput'
          fromDate={siFilterSelected.acquiredFromDate}
          toDate={siFilterSelected.acquiredToDate}
          handleDateChange={(f, t) => {
            handleSetAcquireDateFrom(f);
            handleSetAcquireDateTo(t);
          }}
          popoverIndex={1400}
          innerBorder={'1px solid #DFE4E8'}
          border={'1px solid #FFFFFF'}
          backgroundColor='#FFFFFF'
          iconPaddingRight={0}
          minHeight={40}
          width='100%'
          outerId='sioverview-filter-scrollbox'
          needErrorCheck = {true}
          needErrorMessage = {true}
        />
      </Item>
      {!isCNmarketing&&<><Item
        id='submissionDeadline'
        title='Submission Deadline'
        needResetBtn={true}
        handleReset={handleSetDeadlineDateNull}
      >
        <DateRangePicker
          id='siPageFilterDrawerSubmissionDeadlineTimeRangeInput'
          data-selenium-id='SIPage_Overview-newFilterDrawer-Overview-Submission-Deadline-TimeRangeInput'
          fromDate={siFilterSelected.deadlineFromDate}
          toDate={siFilterSelected.deadlineToDate}
          handleDateChange={(f, t) => {
            handleSetDDLDateFrom(f);
            handleSetDDLDateTo(t);
          }}
          popoverIndex={1400}
          innerBorder={'1px solid #DFE4E8'}
          border={'1px solid #FFFFFF'}
          backgroundColor='#FFFFFF'
          iconPaddingRight={0}
          minHeight={40}
          width='100%'
          outerId='sioverview-filter-scrollbox'
          needErrorCheck = {true}
          needErrorMessage = {true}
        />
      </Item>
      <Item
        id='actualPublicationDate'
        title='Actual Publication Date'
        needResetBtn={true}
        handleReset={handleSetAPDateNull}
      >
        <DateRangePicker
          id='siPageFilterDrawerActualPublicationDateTimeRangeInput'
          data-selenium-id='SIPage_Overview-newFilterDrawer-Overview-PublicationDate-TimeRangeInput'
          fromDate={siFilterSelected.apFromDate}
          toDate={siFilterSelected.apToDate}
          handleDateChange={(f, t) => {
            handleSetAPDateFrom(f);
            handleSetAPDateTo(t);
          }}
          popoverIndex={1400}
          innerBorder={'1px solid #DFE4E8'}
          border={'1px solid #FFFFFF'}
          backgroundColor='#FFFFFF'
          iconPaddingRight={0}
          minHeight={40}
          width='100%'
          outerId='sioverview-filter-scrollbox'
          needErrorCheck = {true}
          needErrorMessage = {true}
        />
      </Item>
      <Item
        id='createdDate'
        title='Created Date'
        needResetBtn={true}
        handleReset={handleSetCreateDateNull}
      >
        <DateRangePicker
          id='siPageFilterDrawerCreatedDateTimeRangeInput'
          data-selenium-id='SIPage_Overview-newFilterDrawer-Overview-CreatedDate-TimeRangeInput'
          fromDate={siFilterSelected.createFromDate}
          toDate={siFilterSelected.createToDate}
          handleDateChange={(f, t) => {
            handleSetCreateDateFrom(f);
            handleSetCreateDateTo(t);
          }}
          popoverIndex={1400}
          innerBorder={'1px solid #DFE4E8'}
          border={'1px solid #FFFFFF'}
          backgroundColor='#FFFFFF'
          iconPaddingRight={0}
          minHeight={40}
          width='100%'
          outerId='sioverview-filter-scrollbox'
          needErrorCheck = {true}
          needErrorMessage = {true}
        />
      </Item></>}
    </Section>
  );
}

import React from 'react';
import SimpleIconButton from './SimpleIconButton';
import IconActive from './icon/ProcessIconActive.svg';
import IconDefault from './icon/ProcessIconDefault.svg';
import IconDisable from './icon/ProcessIconDisable.svg';
export default function ProcessIconButton(props) {
  return (
    <SimpleIconButton
      DefaultIcon={IconDefault}
      HoverIcon={IconActive}
      ActiveIcon={IconActive}
      DisabledIcon={IconDisable}
      {...props}
    ></SimpleIconButton>
  );
}

import { LoadingButton } from '@mui/lab';
import { Button, buttonClasses, useMediaQuery } from '@mui/material';

import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import React from 'react';

/**
 * @type {import("react").CSSProperties}
 */
const SIMTButtonStyle = {
  textTransform: 'capitalize',
  boxShadow: 'none',
  borderRadius: '5px',
  fontWeight: 600,
};

export const SIMTButton = styled(Button)(() => ({
  [`&.${buttonClasses.root}`]: SIMTButtonStyle,
}));

export const SIMTLoadingButton = styled(LoadingButton)(() => ({
  [`&.${buttonClasses.root}`]: SIMTButtonStyle,
}));

export function ResponsiveButton(props) {
  const {
    startIcon,
    endIcon,
    children,
    downBy,
    disabled = false,
    sx,
    ...other
  } = props;
  const theme = useTheme();
  const isSmallerThanMedScreen = useMediaQuery(
    theme.breakpoints.down(downBy ?? 'md')
  );
  return (
    <Button disableRipple
      className='SIMTButton-responsive'
      disabled={disabled}
      startIcon={isSmallerThanMedScreen ? null : startIcon}
      endIcon={isSmallerThanMedScreen ? null : endIcon}
      {...other}
      sx={{
        [`&.${buttonClasses.root}`]: {
          textTransform: 'capitalize',
          boxShadow: 'none',
          borderRadius: '5px',
          fontWeight: 600,
          padding: '6px 12px',
        },
        ...sx,
      }}
    >
      {isSmallerThanMedScreen ? (startIcon || endIcon) : children}
    </Button>
  );
}

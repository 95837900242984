/*
 * @Author: LiuJingQian 1250366059@qq.com
 * @Date: 2024-03-13 10:51:17
 * @LastEditors: LiuJingQian 1250366059@qq.com
 * @LastEditTime: 2024-03-13 11:42:31
 * @FilePath: \simt_front-1\src\pages\Report\Articles\AnnualTrends\AnnualTrendsDiagrams\AllInOneDiagrams\hooks\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetArticleAnualTrendsAllInOneChartDataWithStatus } from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import { formatDate } from '@/utils/date_string';

export const useGetChartsDataHook = (
  selectedMonth,
  catagoryAttributeName,
  urlWithParam,
  reduxTitleForChart,
  selectedItem
) => {
  const dispatch = useDispatch();

  let { reportDataAPI } = useSelector(state => {
    return {
      reportDataAPI: state.ReportDiagram[reduxTitleForChart],
    };
  });

  let loading = null,
    error = null,
    data = null;

  if (typeof reportDataAPI !== 'undefined') {
    ({ loading, error, data } = reportDataAPI);
  }

  const dataForChart = useMemo(() => {
    return data;
  }, [data]);

  useEffect(() => {
    if (typeof reportDataAPI === 'undefined') {
      try {
        const date = formatDate(selectedMonth);
        const level = catagoryAttributeName;

        if (date && level && selectedItem) {
          console.log('valid dispatch');
          //  create URL. Sample URL:
          // '/reports/article/diagram/annual-trends/{level}/all-in-one/{date}'
          const url = urlWithParam
            .replace('{date}', date);

          dispatch(
            GetArticleAnualTrendsAllInOneChartDataWithStatus(
              url,
              reduxTitleForChart,
              selectedItem
            )
          );
        }
      } catch (e) {
        console.error(e.message);
      }
    }
  }, [
    urlWithParam,
    reduxTitleForChart,
    selectedMonth,
    catagoryAttributeName,
    selectedItem,
  ]);

  return [dataForChart, loading, error];
};

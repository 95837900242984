import React, { useEffect } from 'react';
import ResetPassword from '@/modules/ResetPassword';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { clear } from '@/actions/SIMT-User/Login/LoginAction';
import { Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
function FirstTimeLogin() {
  const isFirst = useSelector(state => state.Login.ifFirst);
  const dispatch = useDispatch();

  if (isFirst) {
    return (
      <Box
        display='flex'
        flexDirection='column'
        data-selenium-id='FirstTimeLogin'
      >
        <Header data-selenium-id='FirstTimeLogin-Header' />
        <ResetPassword
          firstTime={true}
          data-selenium-id='FirstTimeLogin-ResetPassWord'
        />
        <footer>
          <Footer data-selenium-id='FirstTimeLogin-Footer' />
        </footer>
      </Box>
    );
  } else {
    return <Navigate to='/simt/login' />;
  }
}

export default FirstTimeLogin;

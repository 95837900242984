import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  // GetAAndAYTDAgainstChartData,
  getAAChartDataWithStatus,
} from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import { getAllGroups } from './helper.js';

export default function useChartsDataHook(
  selectedDate,
  selectedSg,
  catagoryAttributeName,
  valueAttributeNames,
  url,
  title
) {
  const dispatch = useDispatch();

  const { reportDataAPI } = useSelector(state => {
    return {
      reportDataAPI: state.ReportDiagram[title],
    };
  });

  let loading = null,
    error = null,
    data = null;

  if (typeof reportDataAPI !== 'undefined') {
    ({ loading, error, data } = reportDataAPI);
  }

  // why below?
  const dataForChart = useMemo(() => {
    return data;
  }, [data, title]);

  useEffect(() => {
    // if (!dataForChart && selectedDate !== null) {
    // if ((typeof reportDataAPI === 'undefined' || error) && selectedDate !== null) {
    if (typeof reportDataAPI === 'undefined' && selectedDate !== null) {
      dispatch(
        // GetAAndAYTDAgainstChartData(
        //   url,
        //   title,
        //   selectedDate,
        //   catagoryAttributeName
        // )
        getAAChartDataWithStatus(
          url,
          title,
          selectedDate,
          catagoryAttributeName
        )
      );
    }
  }, [url, title, selectedDate, reportDataAPI]);

  const catagoriesFullList = useMemo(() => {
    if (dataForChart) {
      return dataForChart.map(record => record[catagoryAttributeName]);
    }
    return [];
  }, [dataForChart, catagoryAttributeName]);

  // const allsg = useMemo(() => {
  //   if (
  //     dataForChart !== null &&
  //     dataForChart.length != 0
  //   ) {
  //     return dataForChart.map(item => {
  //       return item[catagoryAttributeName];
  //     });
  //   }
  //   return [];
  // }, [dataForChart]);

  const allGroups = useMemo(() => {
    return getAllGroups(dataForChart);
  }, [dataForChart]);

  const selectedDataForBar = useMemo(() => {
    if (dataForChart != undefined && dataForChart != null) {
      // if (selectedSg == undefined || selectedSg.length == 0) {
      // selectedSg = allsg;
      // }
      return filterSelectedCEData(
        dataForChart,
        selectedSg,
        catagoryAttributeName
      ).map(item => {
        valueAttributeNames.forEach(prop => {
          if (item[prop] != null && item[prop] != '-') {
            item[prop] = parseInt(item[prop]) === 0 ? null : parseInt(item[prop]);
          }
        });
        return item;
      });
    }
    return [];
  }, [dataForChart, selectedSg]);

  // return [catagoriesFullList, selectedDataForBar, dataForChart, allGroups];
  return [
    catagoriesFullList,
    selectedDataForBar,
    dataForChart,
    allGroups,
    loading,
    error,
  ];
}

function filterSelectedCEData(
  subjectGroupAllData,
  selectedSg,
  catagoryAttributeName
) {
  subjectGroupAllData = subjectGroupAllData.filter(item => {
    return selectedSg.indexOf(item[catagoryAttributeName]) >= 0;
  });

  return subjectGroupAllData;
}

import { titleStyle, asteriskStyle } from './commonStyle';
import { InputLabel, Box } from '@mui/material';

export function BootstrapTitle(props) {
  const { id, title, required, lineHeight } = props;
  return (
    <Box
      id={`${id}InputLable`}
      sx={{ ...titleStyle, lineHeight: lineHeight, padding: '8px 0px' }}
    >
      {title}
      {required ? <span style={asteriskStyle}> *</span> : <></>}
    </Box>
  );
}

export function DisabledTitle(props) {
  const { id, title, required } = props;
  return (
    <Box
      id={`${id}InputLable`}
      sx={{ ...titleStyle, color: '#BCC5CF', padding: '8px 0px' }}
    >
      {title}
      {required ? <span style={asteriskStyle}> *</span> : <></>}
    </Box>
  );
}

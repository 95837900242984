import React from 'react';
import { SIMTButton } from '@/components/StyledButton/SIMTButton';
import SaveIcon from '@/assets/SaveIcon2.svg';
import SaveIconDisabled from '@/assets/ReportDiagrams/SaveIconDisabled.svg';
import DownloadIcon from '@/assets/ReportDiagrams/DownloadIcon.svg';
import DownloadIconDisabled from '@/assets/ReportDiagrams/DownloadIconDisabled.svg';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './index.module.scss';

/**
 * 在Report 几个页面中可以复用的导出按钮
 * @param props   需要包括至少`items`参数
 *                items是一个列表，每个元素是一个Object，例如：
 *                {
 *                  label: "CSV",
 *                  operation: handleExportCSV
 *                }
 *                ---------------------
 *                于是items的结构为：
 *                [
 *                  {
 *                    label: "CSV",
 *                    operation: handleExportCSV
 *                  },
 *                  {
 *                    label: "Excel",
 *                    operation: handleExportExcel
 *                  },
 *                ]
 * @returns {JSX.Element} 导出按钮
 * @constructor
 */
export default function ReportSaveMenuButton(props) {
  const { items, disabled, ...other } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const exportMenuOpen = Boolean(anchorEl);
  const useStyles = makeStyles({
    tooltip: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      padding: '8px 16px',
      backgroundColor: '#596A7C',
    },
  });
  const classes = useStyles();

  if (disabled) {
    return (
      <div className={styles['button-disabled']}>
        <SaveIconDisabled />
      </div>
    );
  }

  const handleExportMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip
        title='Save chart'
        placement='top'
        arrow
        classes={{ tooltip: classes.tooltip }}
      >
        <button
          className={styles['save-button']}
          onClick={handleExportMenuClick}
          {...other}
        >
          <SaveIcon />
        </button>
      </Tooltip>

      <Menu
        id='save-menu'
        anchorEl={anchorEl}
        open={exportMenuOpen}
        MenuListProps={{
          'aria-labelledby': 'save-button',
        }}
        onClose={handleExportMenuClose}
      >
        {items.map((item, index) => (
          <MenuItem
            id={`menuItem${index}`}
            key={`export-menu-item-${index}`}
            onClick={() => {
              item.operation();
              handleExportMenuClose();
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export function ReportDownloadMenuButton(props) {
  const { func, disabled, ...other } = props;
  const useStyles = makeStyles({
    tooltip: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      padding: '8px 16px',
      backgroundColor: '#596A7C',
    },
  });

  const classes = useStyles();

  if (disabled) {
    return (
      <div className={styles['button-disabled']}>
        <DownloadIconDisabled />
      </div>
    );
  }

  return (
    <>
      <Tooltip
        title='Download data'
        placement='top'
        arrow
        classes={{ tooltip: classes.tooltip }}
      >
        <button onClick={func} className={styles['button-download']}>
          <DownloadIcon />
        </button>
      </Tooltip>
    </>
  );
}

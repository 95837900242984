import {
  Box,
  Collapse,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { GEInformationCheckItemBlock } from '../../CustomComponent/CustomBlock';
import { GEDiffContainer } from '../../CustomComponent/CustomContainer';
import styleModule from './TransformSteps.module.scss';
import { ShowFile } from '@/componentsphase2/ShowFile';
import { useDispatch } from 'react-redux';
import {downloadCvInCheckGe} from "@/actions/SIMT-SIP/SIP/SipDetail";
/** @typedef {import("./DuplicateGeCheck").GeInformationForTransform} GeInformationForTransform */

/** @type {GeInformationForTransform} */
export const geInformationForTransformInitialValue = {
  id: 0,
  geCode: null,
  emailAddress: null,
  firstName: null,
  lastName: null,
  jobTitle: null,
  department: null,
  institution: null,
  city: null,
  country: null,
  orcidId: null,
  researchGateId: null,
  profileWebsite: null,
  researchKeywords: [],
  leadGe: null,
  cvFileName: null,
};

/** @type {import("./DuplicateGeCheck").GeDiffDataVO} */
export const geDiffVOTestData = [
  {
    'SIMT GE': null,
    'SIP GE': {
      id: 115,
      geCode: null,
      emailAddress: '2499626733@qq.com',
      firstName: 'jiajing',
      lastName: 'Li',
      jobTitle: 'Dr.',
      department: 'hit',
      institution: 'ins',
      city: 'harbin',
      country: 'Angola',
      orcidId: '',
      researchGateId: 'fake_reasearch_id',
      profileWebsite: 'www.huangtao.com',
      researchKeywords: [],
      leadGe: true,
    },
  },
  {
    'SIMT GE': {
      id: 22002785,
      geCode: 'GE-2022-002785',
      emailAddress: '1291980381@qq.com',
      firstName: 'Bo',
      lastName: 'DongWu',
      jobTitle: 'Assoc. Prof.',
      department: '1.Psychology  2. Dental Practice and Rural Healtdddddh',
      institution: '(UPC, \nBarcelonaTECH)',
      city: 'Shenzhen',
      country: 'Antigua and Barbuda',
      orcidId: null,
      researchGateId: null,
      profileWebsite: 'cn.bing.com',
      researchKeywords: [
        '"Hippocampus Synaptic plasticity; Mitochondria; Local protein synthesis',
      ],
      leadGe: null,
      secondaryEmail: '',
      gender: 'Male',
      notes: '',
      flagList: [],
    },
    'SIP GE': {
      id: 136,
      geCode: null,
      emailAddress: '1291980381@qq.com',
      firstName: 'Bo',
      lastName: 'DongWu',
      jobTitle: 'Assoc. Prof.',
      department: 'dep',
      institution: 'ins',
      city: 'Shenzhen',
      country: 'Albania',
      orcidId: '',
      researchGateId: '',
      profileWebsite: 'www.baidu.com',
      researchKeywords: [],
      leadGe: true,
    },
  },
  {
    'SIP GE': {
      id: 0,
      geCode: 'GE-2022-002785',
      leadGe: false,
      firstName: 'Name1',
      lastName: 'Surname1',
      jobTitle: 'Job title1',
      emailAddress: 'test@example.com',
      department: 'Department1',
      institution: 'Institution1',
      city: 'city1',
      country: 'country1',
      orcidId: 'orcidId1',
      researchGateId: 'ResearchGateId1',
      profileWebsite: 'cn.bing.com',
      researchKeywords: [
        'cereal-based food',
        'physical properties',
        'nutritional profile',
        'processing method',
        'food quality1',
      ],
    },
    'SIMT GE': {
      id: 0,
      geCode: null,
      leadGe: false,
      firstName: 'Name1',
      lastName: 'Surname1',
      jobTitle: 'Job title1',
      emailAddress: 'test@example.com',
      department: 'Department2',
      institution: 'Institution1',
      city: 'city1',
      country: 'country1',
      orcidId: 'orcidId1',
      researchGateId: 'ResearchGateId1',
      profileWebsite:
        'https://outlook.office.com/mail/inbox/id/AAQkADZiMGI5ODUyLTBmZDYtNDdjOS1hYzdmLTc5NDdhYTc2N2E1MwAQADjaSCHhav1HjPiuT%2BAVepE%3D',
      researchKeywords: [
        'cereal-based food',
        'physical properties',
        'nutritional profile',
        'processing method',
        'food quality',
      ],
      secondaryEmail: '',
      gender: null,
      notes: '',
      flagList: [],
    },
  },
];
/** @type {import("./DuplicateGeCheck").GeInformationKeyToTitleType} */
const GeInformationKeyToTitle = {
  id: 'id',
  geCode: 'GE Code',
  leadGe: 'Lead Ge',
  firstName: 'First Name',
  lastName: 'Last Name',
  jobTitle: 'Title',
  emailAddress: 'Email Address',
  department: 'Department',
  institution: 'Institution',
  city: 'City',
  country: 'Country/Region',
  orcidId: 'ORCID ID',
  researchGateId: 'ResearchGate ID',
  profileWebsite: 'Profile Website',
  researchKeywords: 'Research Keywords',
  cvFileName: 'CV',
};

/**
 * @param {import("./DuplicateGeCheck").DuplicateGeCheckProps} props
 * @returns
 */
const DuplicateGeCheck = props => {
  const { value, onChange, sipCode } = props;
  const dispatch = useDispatch();
  /**
   * @param {number} mergeIndex
   * @param {keyof GeInformationForTransform} key
   * @param {string | string[] | number | null} mergeValue
   */
  const handleMerge = (mergeIndex, key, mergeValue) => {
    const newValue = value.map((geDiffDataItem, index) => {
      if (index === mergeIndex) {
        return {
          ...geDiffDataItem,
          'SIMT GE': {
            ...geDiffDataItem['SIMT GE'],
            [key]: mergeValue,
          },
        };
      }
      return geDiffDataItem;
    });
    onChange(newValue);
  };

  /**
   *
   * @param {GeInformationForTransform} itemLeft
   * @param {GeInformationForTransform} itemRight
   * @param {number} itemIndex
   * @returns
   */
  const getGEDiffItems = (itemLeft, itemRight, itemIndex) => {
    /** @type {(keyof GeInformationForTransform)[]} */
    const keys = [
      'emailAddress',
      'firstName',
      'lastName',
      'jobTitle',
      'department',
      'institution',
      'city',
      'country',
      'orcidId',
      'researchGateId',
      'profileWebsite',
      'researchKeywords',
      'cvFileName',
    ];
    let isDifferent = false;

    function decodeCV(CV) {
      let decodeCV = {};
      let arr1 = CV.split(';');
      for (let i = 0; i < arr1.length; i++) {
        let objArr = arr1[i].split(':');
        let key = objArr[0];
        let value = objArr[1];
        decodeCV[key] = value;
      }
      // let jsonCV = `{${CV}}`;
      // let decodeCV = JSON.parse(jsonCV);
      return decodeCV;
    }

    const contentToString = content => {
      if (typeof content === 'string' || typeof content === 'number')
        return `${content}`;
      else if (typeof content === 'boolean') return content ? 'Yes' : 'No';
      else if (content === null) return '';
      return content.join(', ');
    };

    function correctFileName(fileName) {
      if (!fileName) return;
      let index = fileName.indexOf('_');
      let newFilename = fileName.substring(index + 1);
      return newFilename;
    }

    const res = keys.reduce((acc, key, index) => {
      const leftValue = itemLeft[key];
      const rightValue = itemRight[key];

      if (typeof leftValue === 'boolean' || typeof rightValue === 'boolean')
        return acc;

      const title = GeInformationKeyToTitle[key];

      const getDiff = () => {
        if (key === 'cvFileName') {
          if (!leftValue) return false;
           //修复CT6634
           if (!rightValue) return true;
           let decodeLeftValue = decodeCV(leftValue);
           let decodeRightValue = decodeCV(rightValue);
           if (
             correctFileName(decodeLeftValue.name) ===
               correctFileName(decodeRightValue.name) &&
             decodeLeftValue.size === decodeRightValue.size
           ) {
             return false;
           }
          return true;
        }
        if (!Array.isArray(leftValue) && !Array.isArray(rightValue)) {
          if (typeof leftValue !== 'number' && typeof rightValue !== 'number') {
            const emptyValue = [null, ''];
            if (
              emptyValue.includes(leftValue) &&
              emptyValue.includes(rightValue)
            )
              return;
          }
          return leftValue !== rightValue;
        }

        if (!Array.isArray(leftValue)) {
          return true;
        } else {
          if (!Array.isArray(rightValue)) {
            return true;
          } else {
            if (rightValue.length !== leftValue.length) return true;
            return leftValue.some(
              (value, index) => value !== rightValue[index]
            );
          }
        }
        return true;
      };
      const diffHighlight = getDiff();
      if (diffHighlight) isDifferent = diffHighlight;
      let newLeftValue, newRightValue;
      if (key === 'cvFileName') {
        if (leftValue) {
          let leftFullName = `${itemLeft['lastName']} ${itemLeft['firstName']}`;

          let decodeLeftValue = decodeCV(leftValue);
          let fileSize = decodeLeftValue.size ;
          let fileName = decodeLeftValue.name ?? leftValue;
          newLeftValue = (
            <ShowFile
              fileName={[`${fileSize}_${fileName}`]}
              DownloadSingleFile={() => {
                dispatch(downloadCvInCheckGe(fileName, leftFullName, true));
              }}
            />
          );
        } else {
          newLeftValue = '-';
        }
        if (rightValue) {
          let rightFullName = `${itemRight['lastName']} ${itemRight['firstName']}`;
          let decodeRightValue = decodeCV(rightValue);
          let fileSize = decodeRightValue.size;
          let fileName = decodeRightValue.name;
          newRightValue = (
            <ShowFile
              fileName={[`${fileSize}_${fileName}`]}
              DownloadSingleFile={() => {
                dispatch(downloadCvInCheckGe(fileName, rightFullName, false));
              }}
            />
          );
        } else {
          newRightValue = '-';
        }
      } else {
        newLeftValue = contentToString(leftValue);
        newRightValue = contentToString(rightValue);
      }

      return [
        ...acc,
        <GEInformationCheckItemBlock
          key={`geInSip-${itemIndex}`}
          title={GeInformationKeyToTitle[key]}
          content={newLeftValue}
        />,
        <GEInformationCheckItemBlock
          key={`geInSimt-${itemIndex}`}
          title={title}
          content={newRightValue}
          diffHighlight={diffHighlight}
          handleMerge={() => {
            // console.log(itemIndex, key, leftValue);
            handleMerge(itemIndex, key, leftValue);
          }}
        />,
      ];
    }, /** @type {React.ReactNode[]} */ ([]));

    return {
      isDifferent: isDifferent,
      reactNodes: res,
    };
  };

  const geDiffNodes = value.map((geDiffDataItem, index) => {
    const geDiffResult = getGEDiffItems(
      geDiffDataItem['SIP GE'],
      geDiffDataItem['SIMT GE'],
      index
    );
    return (
      <GEDiffContainer
        key={index}
        index={index + 1}
        isDifferent={geDiffResult.isDifferent}
      >
        {geDiffResult.reactNodes}
      </GEDiffContainer>
    );
  });

  return (
    <Container
      data-selenium-id='SIPPage_TransformToSl-DuplicateGeCheck-Container'
      sx={{
        paddingY: '40px',
        flexGrow: 1,
        position: 'relative',
        paddingLeft: '16px !important',
        paddingRight: '16px !important',
      }}
      className={styleModule['DuplicateGeCheck-Container']}
    >
      <Stack
        spacing='40px'
        data-selenium-id='SIPPage_TransformToSl-Submodule-DuplicateGeCheck-Stack'
      >
        {geDiffNodes}
      </Stack>
    </Container>
  );
};

export default DuplicateGeCheck;

import { SmallBoldFont } from '@/components/FontStyle';
import { Box } from '@mui/material';

export default function CancelBtn(props) {
  const { handleClick } = props;
  return (
    <Box
      sx={{
        border: '1px solid #0052CC',
        padding: '7px 11.5px',
        width: '70px',
        height: '34px',
        boxSizing: 'border-box',
        ...SmallBoldFont,
        color: '#0052CC',
        cursor: 'pointer',
        borderRadius: '5px',
        ':hover': {
          borderColor: '#6D98EE',
          color: '#6D98EE',
        },
        ':active': {
          backgroundColor: '#E8EDFB',
          borderColor: '#AEC6F6',
          color: '#6D98EE',
        },
      }}
      onClick={handleClick}
    >
      Cancel
    </Box>
  );
}

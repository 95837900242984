import { TextField, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import SendEmailChipDeleteIcon from '@/assets/SendEmailChipDeleteIcon.svg';
import { CustomDefaultChip } from './CustomChip';
import { extractAndTransformUsername } from '@/utils/commonUtils';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    boxSizing: 'border-box',
    minHeight: '33px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#262E35',
    // padding: "0px",
    paddingLeft: '14px',
    paddingRight: '14px',
    '& fieldset': {
      border: '1px solid #DFE4E8',
    },
    '& input': {
      padding: 0,
    },
  },
}));

const CustomDisabledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#F6F7F8',
    boxSizing: 'border-box',
    minHeight: '33px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#262E35',
    // padding: "0px",
    paddingLeft: '14px',
    paddingRight: '14px',
    '& fieldset': {
      border: '0px solid #DFE4E8',
    },
    '& input': {
      padding: 0,
    },
  },
}));

const CustomSubjectTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    boxSizing: 'border-box',
    height: '33px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#262E35',
    padding: '0px',
    '& .MuiInputBase-input': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& fieldset': {
      border: 'none',
      borderRadius: 0,
      borderBottom: '1px solid #DFE4E8',
    },
  },
}));

const CustomChipTextField = props => {
  const {
    contentList,
    setContentList,
    pattern,
    setError,
    setErrorType,
    handleRepeatError,
    removeBracket,
    sx,
    isDuplicate,
    ...others
  } = props;
  var contentListWithoutBracket = removeBracket(contentList);
  const [value, setValue] = useState('');

  const handleInputChange = e => {
    if (
      typeof e.target.value == 'string' &&
      (e.target.value.substr(-1) == ';' || e.target.value.substr(-1) == ',')
    ) {
      return;
    }
    if (e.target.value.length > 100) {
      setErrorType(3);
      setError(true);
      return;
    }

    setError(false);
    setValue(e.target.value);
  };

  const onDelete = deleteItemIndex => {
    setContentList(
      contentList.filter((item, index) => index !== deleteItemIndex)
    );
  };

  const handleKeyDown = e => {
    setError(false);

    if (value == '' && e.keyCode == 8) {
      if (contentList.length == 0) return;
      e.preventDefault();
      let list = JSON.parse(JSON.stringify(contentList));
      list.pop();
      setContentList(list);
      setValue('');
    }
    if (
      typeof e.target.value == 'string' &&
      (e.keyCode == 186 || e.keyCode == 13 || e.keyCode == 188)
    ) {
      if (e.target.value.length == 0) return;
      const realValue = e.target.value;
      if (pattern && !pattern.test(realValue)) {
        setErrorType(1);
        setError(true);
        return;
      }
      if (!isDuplicate(contentListWithoutBracket, realValue)) {
        setContentList([...contentList, realValue]);
      }
      setValue('');
    }
  };

  return (
    <CustomTextField
      onChange={handleInputChange}
      data-selenium-id='Email_Send-CommonComponents-CustomTextField'
      onBlur={e => {
        handleRepeatError();
        if (e.target.value.length == 0) return;
        if (pattern && !pattern.test(e.target.value) && e.target.value !== '') {
          setErrorType(1);
          setError(true);
          return;
        } else if (!isDuplicate(contentListWithoutBracket, e.target.value)) {
          setContentList([...contentList, e.target.value]);
        }
        setValue('');
      }}
      onKeyDown={handleKeyDown}
      value={value}
      sx={{
        '&.MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiOutlinedInput-root':
          {
            minHeight: '44px',
            flexFlow: 'wrap',
            paddingY: '4px',
            '& input': {
              flexGrow: 1,
              width: '10%',
            },
          },
        ...sx,
      }}
      InputProps={{
        startAdornment: contentList?contentList.map((value, index) => {
          return (
            <CustomDefaultChip
              sx={{
                marginY: '4px',
              }}
              // label={value}
              label={value}
              index={index}
              onDelete={onDelete.bind(this, index)}
              key={index}
              deleteIcon={<SendEmailChipDeleteIcon />}
            />
          );
        }):[],
      }}
      {...others}
    />
  );
};

export {
  CustomTextField,
  CustomSubjectTextField,
  CustomChipTextField,
  CustomDisabledTextField,
};

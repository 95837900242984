export const SET_NAVIGATIONSTATE = 'SET_NAVIGATIONSTATE';
export const SET_UPDOWN = 'SET_UPDOWN';
export const SET_MENU_HEIGHT_REDUCE = 'SET_MENU_HEIGHT_REDUCE';

export const setNavigationState = NewNavigationState => ({
  type: SET_NAVIGATIONSTATE,
  data: NewNavigationState,
});

export const setUpdown = NewUpdown => ({
  type: SET_UPDOWN,
  data: NewUpdown,
});

export const setMenuReduceHeight = height => ({
  type: SET_MENU_HEIGHT_REDUCE,
  data: height,
});

export const setUpdownDispatch = NewUpdown => {
  return dispatch => {
    dispatch(setUpdown(NewUpdown));
  };
};

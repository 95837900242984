import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import theme from './componentsphase2/Theme';
import { messages } from './lang/intl';
import { IntlProvider } from 'react-intl';

import Config from '@/configs/Config';

//Determine whether the configuration file enables log debugging or not.
const logDebug = Config.SHOW_LOG;
// console.log = (function (oriLogFunc) {
//   return function () {
//     if (logDebug) {
//       oriLogFunc.apply(this, arguments);
//     }
//   };
// })(console.log);

// to solve browser cannot location the original place of console.log function
if (!logDebug) {
  console.log = (function (oriLogFunc) {
    return () => {};
  })(console.log);
}

const locale = 'en';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <IntlProvider
          locale={locale}
          defaultLocale='en'
          messages={messages[locale]}
        >
          <App />
        </IntlProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

export default store;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

import { Box } from '@mui/material';
import CreateAndUpdateInfomationBlock from './CreateAndUpdateInfomationBlock';
function CreateAndUpdateInfomationBox(props) {
  //判断一个值是否为空或者空串
  const isEmptyValue = value => {
    return (
      value === null ||
      value === '' ||
      value === undefined ||
      value.length === 0 ||
      value === 'Invalid date'
    );
  };
  const { infomation, isOA } = props;
  return (
    <Box
      sx={{ width: '100%', 'div:last-child': { marginBottom: 0 } }}
      data-selenium-id='SIPage_CreateAndUpdateSI-InfomationBox-Box'
    >
      {Object.keys(infomation).map((property, index) => (
        // Task2635: commented the following three lines of codes
        // isEmptyValue(infomation[property])
        //   ? <></>
        //   :
        <CreateAndUpdateInfomationBlock
          data-selenium-id='SIPage_CreateAndUpdateSI-InfomationBox-CreateAndUpdateInfomationBlock'
          property={property}
          value={infomation[property]}
          isOA={isOA}
        ></CreateAndUpdateInfomationBlock>
      ))}
    </Box>
  );
}
export default CreateAndUpdateInfomationBox;

//@ts-check
/**
 * @typedef {import("@/pages/SIPPage/RevisePage/ProposerRevisePage").SIPProposerReviseData} SIPProposerReviseData
 * @typedef {import("@/pages/SIPPage/RevisePage/ProposerRevisePage").SIPProposerReviseFromJEData} SIPProposerReviseFromJEData
 */
import {axios_instance} from "@/utils/axios_instance";
// import {fakeAxiosUniversalResponse} from "@/utils/commonUtils";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {SET_SAVING_REVISE_DATA_STATUS, SET_UPLOAD_REVISE_DATA_STATUS} from "@/actions/SIMT-SIP/SIP/SIPAction";
import getAuth from "@/utils/getAuth";

/** https://app.apifox.com/link/project/3824051/apis/api-147510124
 * @param {import("@/pages/SIPPage/RevisePage").ReviseRequestData} data
 * @param {import("@/pages/SIPPage/RevisePage").ReviseRequestParam} params
 * @param {string} [token]
 */
export const sipRevise=(data,params={},token=(()=>{
  const {pathname}=location;
  const pathnameSplit=pathname.split('/');
  return pathnameSplit[pathnameSplit.length-1];
})())=>axios_instance.put(`/api/v1/sip-service/special-issue-proposals/revise/${token}`,data,{
  headers:{
    authorization:getAuth()??''
  },
  params
}).then((/**@type {import('@/utils/axios-response').AxiosResponse<string>}*/e)=>e.data);
/**
 *
 * @param {string} token
 * @param {"ProposerRevise" | "ProposerReviseFromJE"} type
 * @param {SIPProposerReviseData | SIPProposerReviseFromJEData} data
 * @param {"run" | "test"} mode
 * @returns {(dispatch: import("redux").Dispatch) => Promise<void>}
 */
const ERROR_CODES = {
    SUBMIT_REVISE_GEERROR: 16009,
};

/**
 * @param {Parameters<typeof upLoadSIPReviseData>['2']} data
 * @param {string} type
 * @param {string} token
 */
export const saveSIPReviseDraft = (token, type, data, mode = 'run') => {
    /** @typedef {import("../../../pages/SIPPage/RevisePage/ProposerRevisePage").SaveReviseDataStatus} SaveReviseDataStatus */
    /**@type {()=>import("@/pages/SIPPage/RevisePage").ReviseRequestParam['requestType']}*/
    const getUrl = () => {
        switch (type) {
            case 'ProposerRevise':
                return 'revise-sip-saveDraft-token';
            case 'ProposerReviseFromJE':
            default:
                return 'revise-fromJE-saveDraft-token';
        }
    };
    /**
     * @param {import("redux").Dispatch} dispatch
     */
    return async dispatch => {
        try {
            // /** @type {import("@/utils/axios-response").AxiosResponse<string,number>} */
            // const res =
            //     mode === 'run'
            //         ? await axios_instance.post(getUrl(), data)
            //         : await fakeAxiosUniversalResponse(
            //             null,
            //             1000,
            //             500,
            //             'test error message'
            //         );
            const{code,message} = await sipRevise(data,{
              requestType:getUrl()
            },token);
            if (code === 200) {
                dispatch({
                    type: SET_SAVING_REVISE_DATA_STATUS,
                    /** @type {SaveReviseDataStatus} */ data: 'success',
                });
            } else {
                dispatch({
                    type: SET_SAVING_REVISE_DATA_STATUS,
                    /** @type {SaveReviseDataStatus} */ data: 'error',
                });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.revise.draftSaveError',
                        {
                            value: message,
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({
                type: SET_SAVING_REVISE_DATA_STATUS,
                /** @type {SaveReviseDataStatus} */ data: 'error',
            });
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.revise.draftSaveError',
                    {
                        value: `${err}`,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
};
/**
 * 通过 token 上传 SIP Revise 的数据
 * @param {string} token
 * @param {"ProposerRevise" | "ProposerReviseFromJE"} type
 * @param {SIPProposerReviseData | SIPProposerReviseFromJEData} data
 * @param {"run" | "test"} mode
 * @returns {(dispatch: import("redux").Dispatch) => Promise<void>}
 */
export const upLoadSIPReviseData = (token, type, data, mode = 'run') => {
    /** @typedef {import("../../../pages/SIPPage/RevisePage/ProposerRevisePage").UploadReviseDataStatus} UploadReviseDataStatus */
    return async dispatch => {
        try {
            // /** @type {import('@/utils/axios-response').AxiosResponse<string, number>} */
            // const res =
            //     mode === 'run'
            //         ? await axios_instance.post(url, data)
            //         : await fakeAxiosUniversalResponse(
            //             null,
            //             1000,
            //             500,
            //             'test error message'
            //         );
            const{code,message} = await sipRevise(data,{
              requestType:type === 'ProposerRevise'?'revise-sip-submit-token':'revise-fromJE-submit-token'
            },token);
            if (code === 200) {
                dispatch({
                    type: SET_UPLOAD_REVISE_DATA_STATUS,
                    /** @type {UploadReviseDataStatus} */ data: 'success',
                });
            } else {
                dispatch({
                    type: SET_UPLOAD_REVISE_DATA_STATUS,
                    /** @type {UploadReviseDataStatus} */ data: 'error',
                });
                if (code === ERROR_CODES.SUBMIT_REVISE_GEERROR) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.revise.submitReviseGEError',
                            {
                                value: message,
                            },
                            SEVERITIES.error
                        )
                    );
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.revise.submitReviseError',
                            {
                                value: message,
                            },
                            SEVERITIES.error
                        )
                    );
                }
            }
        } catch (err) {
          console.error(err);
            dispatch({
                type: SET_UPLOAD_REVISE_DATA_STATUS,
                /** @type {UploadReviseDataStatus} */ data: 'error',
            });
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.revise.submitReviseError',
                    {
                        value: `${(Object.prototype.isPrototypeOf(Object(err)) && err instanceof Object && Object.prototype.toString.call(err) === '[object Object]' && err.constructor===Object && typeof err === 'object')?JSON.parse(JSON.stringify(err))?.data?.message:err}`,
                    },
                    SEVERITIES.error
                )
            );
            // console.error(err);
        }
    };
};
/**
 *
 * @param {string} token
 * @param {import("@/pages/SIPPage/RevisePage/JPMReviewPage").JPMSubmitType} data
 * @returns {(dispatch: import("redux").Dispatch) => Promise<void>}
 */
export const submitJPMReviewData = (token, data) => {
    /** @typedef {import("../../../pages/SIPPage/RevisePage/ProposerRevisePage").UploadReviseDataStatus} UploadReviseDataStatus */
    return async dispatch => {
        try {
            const{code} = await sipRevise(data,{
              requestType:'jpm-reSubmit-submit-token'
            },token);
            if (code === 200) {
                dispatch({
                    type: SET_UPLOAD_REVISE_DATA_STATUS,
                    /** @type {UploadReviseDataStatus} */ data: 'success',
                });
            } else {
                dispatch({
                    type: SET_UPLOAD_REVISE_DATA_STATUS,
                    /** @type {UploadReviseDataStatus} */ data: 'error',
                });
            }
        } catch (err) {
            dispatch({
                type: SET_UPLOAD_REVISE_DATA_STATUS,
                /** @type {UploadReviseDataStatus} */ data: 'error',
            });
            console.error(err);
        }
    };
};
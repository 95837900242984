import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AutocompleteArrowIcon from '@/componentsphase2/Autocomplete/SVGComponents/AutocompleteArrowIcon.svg';
import Label from '../Label';
import { useSelector } from 'react-redux';

export default function DecisionTypeSelect({value, onChange}) {
  const { decisionTypeList = [] } = useSelector(state => {
    return {
      decisionTypeList: state?.SIP?.decisionType,
    };
  });
  return (
    <Label name='Decision Type' required>
      <Autocomplete
        value={value}
        onChange={onChange}
        popupIcon={<AutocompleteArrowIcon />}
        options={decisionTypeList}
        disableClearable
        // getOptionLabel={option => option.title}
        renderInput={params => (
          <TextField
            {...params}
            // error
            helperText=' '
            hiddenLabel
            readonly
            placeholder='Select Decision Type'
            size='small'
            inputProps={{  
              ...params.inputProps,
              readOnly: true, 
              tabIndex: -1, // disable focus
            }} 
          />
        )}
      />
    </Label>
  );
}

import Tabs from '@mui/material/Tabs';
import { Box } from '@mui/system';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ReportTabChildren } from '../ReportSpecificComponents/ReportTabComponents';
import { useSearchParams } from 'react-router-dom';
import JournalOverView from './Overview/index';
import {ShowForPermission} from "@/components/PermissionControl/ShowForPermission";
import {PERMISSIONS} from "@/constant/permission";

function a11yProps(index) {
  return {
    id: `journalTab${index}`,
    'aria-controls': `journalTabPanel${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`journalTabPanel${index}`}
      data-selenium-id={`Report_Journal-TabPanel-${index}`}
      aria-labelledby={`journalTab${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ paddingTop: '14px', paddingLeft: '5px', paddingRight: '5px' }}
          data-selenium-id='Report_Journal-TabPanel-Container'
        >
          <Typography data-selenium-id='Report_Journal-TabPanel-Content'>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function Journal() {
  // get showTable from URL
  let [searchParams, setSearchParams] = useSearchParams();
  let showTable = searchParams.get('showTable');
  showTable = showTable === 'false' ? false : true;

  const setShowTable = showTable => {
    setSearchParams({ showTable });
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ width: '100%', padding: 0 }}
      data-selenium-id='Report_Journal-Container'
    >
      <ShowForPermission permission={`${PERMISSIONS.REPORT_READ_GENERAL}`}>
      <Box
        sx={{ borderColor: 'divider', mt: '-70px' }}
        data-selenium-id='Report_Journal-TabsContainer'
      >
        <Tabs
          onChange={handleChange}
          value={value}
          aria-label='Articles tabs example'
          data-selenium-id='Report_Journal-Tabs'
        >
          <ReportTabChildren
            id='reportJournalOverview'
            data-selenium-id='Report_Journal-TabChildren'
            label={
              <FormattedMessage
                id={'report.overview'}
                data-selenium-id='Report_Journal-OverViewText'
              />
            }
            {...a11yProps(0)}
          />
        </Tabs>
      </Box>

      <TabPanel
        sx={{ marginTop: '-20px' }}
        value={value}
        index={0}
        data-selenium-id='Report_Journal-TabPanel'
      >
        <JournalOverView
          showTable={showTable}
          setShowTable={setShowTable}
          data-selenium-id='Report_Journal-OverView'
        ></JournalOverView>
      </TabPanel>
      </ShowForPermission>
    </Box>
  );
}
export default Journal;

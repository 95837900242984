import { styled } from '@mui/material/styles';
import { SIMTButton } from '../../../../components/StyledButton/SIMTButton';

const CustomButton = styled(SIMTButton)(theme => ({
  '&.MuiButtonBase-root.MuiButton-root': {
    fontSize: '14px',
    padding: '10px 45px',
    borderRadius: '3px',
    fontWeight: '400',
    lineHeight: '17px',
  },
}));

export default CustomButton;

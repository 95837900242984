import { useState } from 'react';
import MyOnlyText from '@/componentsphase2/MyOnlyText';

function EmailInput(props) {
  const { setEmailErr, setValue, value, clickMonitor } = props;

  const [isEmail, setIsEmail] = useState(true);
  const emailPattern =
    /^\w+((-\w+)|(\.\w+)|(_\w+))*@[A-Za-z0-9]+(([.\-])[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

  const checkIsEmail = text => {
    setIsEmail(emailPattern.test(text));
    setEmailErr(!emailPattern.test(text));
  };

  const getErrorText = () => {
    let helperText = '';
    if (!isEmail) {
      helperText = 'The email is error';
    } else {
      helperText = 'This field is required.';
    }
    return helperText;
  };

  return (
    <MyOnlyText
      id='emailAddress'
      height='40px'
      data-selenium-id='SIP_DetailPage_GE-EditContent-EmailAddress'
      placeholder=''
      value={value}
      setValue={setValue}
      width='400px'
      isError={(clickMonitor && !value) || !isEmail}
      errorMessage={getErrorText()}
      onBlur={() => {
        if (value) {
          checkIsEmail(value);
        }
      }}
      onFocus={() => {
        setIsEmail(true);
        setEmailErr(false);
      }}
    />
  );
}

export default EmailInput;

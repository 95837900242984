export const getAllGroups = CEs => {
  let allGroups = [];

  try {
    // loop through CEs, check CE catGroup
    CEs.forEach((CE, index) => {
      // console.log(CE, ' ', index);
      const thisGroup = CE['catGroup'];
      // find the index of target catGroup
      const indexOfCatGroup = allGroups.findIndex(
        group => group.groupName === thisGroup
      );

      // if catGroup is exist in allGroup, then add CE name to the group options
      if (indexOfCatGroup >= 0) {
        allGroups[indexOfCatGroup].options.push(CE['handlingCe']);
      } else {
        // else add new group with name, add options with CE name
        const newGroup = {
          groupName: thisGroup,
          options: [CE['handlingCe']],
        };
        allGroups.push(newGroup);
      }
    });
  } catch (e) {
    console.warn('***getAllGroups exception***');
    return [];
  }

  // console.log('==allGroups==', allGroups);

  return allGroups;

  // return [
  //   {
  //     groupName: 'CAT General',
  //     options: ['Zhao, Zian'],
  //   },
  //   {
  //     groupName: 'CAT HS',
  //     options: [
  //       'Iyer, Kshitija',
  //       'Lan, Lan',
  //       'Ma, Zora',
  //       'Wang, Jocelyn',
  //       'Xu, Michael',
  //     ],
  //   },
  //   {
  //     groupName: 'CAT LS',
  //     options: [
  //       'Chaudhary, Nidhi',
  //       'Dasari, Hoglah',
  //       'Liu, Ella',
  //       'Shen, Stephen',
  //       'Tan, Yuling',
  //       'Zhang, Bing',
  //       'Zhang, Xinli',
  //     ],
  //   },
  //   {
  //     groupName: 'CAT PS-SSH',
  //     options: [
  //       'Li, Alicia',
  //       'Liu, Shangyuan',
  //       'Luo, Yan',
  //       'Wang, Celine',
  //       'Yadav, Anita',
  //     ],
  //   },
  //   {
  //     groupName: 'Others',
  //     options: ['Chen, Huayi'],
  //   },
  // ];
};

import styled from '@mui/material/styles/styled';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
const MyTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#596A7C',
    width: 0,
    height: 0,
    borderLeft: '3px solid transparent',
    borderRight: '3px solid transparent',
    borderTop: '3px solid #596A7C',
    marginBottom: '-3px !important',
    '::before': {
      display: 'none',
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#596A7C',

    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#FFFFFF',

    padding: '4px 8px 4px 8px',
    // width: '203px',
    // height: '28px',

    borderRadius: '5px',
    // maxWidth: '385px',
    boxSizing: 'border-box',

    marginBottom: '0px !important',
  },
}));
export default MyTooltip;

import {
  IDEA_OR_PROPOSAL,
  ACQUIRED,
  PAPER_COMMISSION,
  SUBMISSION_AND_REVIEW,
  PRODUCTION,
  PUBLISHED_IN_SELECTED_FY,
} from '../../constants.js';

/**
 * key: data series name for chart
 * value: the array of possible attribute names of API data
 */
export const APIKeyMap = {
  [IDEA_OR_PROPOSAL]: ['numIdeaOrProposal', 'ideaOrProposal'],
  [ACQUIRED]: ['numAcquired', 'acquired'],
  [PAPER_COMMISSION]: ['numPaperCommission', 'paperCommission'],
  [SUBMISSION_AND_REVIEW]: ['numSubmissionAndReview', 'submissionAndReview'],
  [PRODUCTION]: ['numProduction', 'production'],
  [PUBLISHED_IN_SELECTED_FY]: ['numPublishedFy', 'published'],
  catGroup: ['catGroup'], // for CE level data to get CE's cat group
};

import {axios_instance} from "@/utils/axios_instance";
import {
    ANNUALTRENDS_BUSINESS_DATA,
    ANNUALTRENDS_SUBJECTGRUOP_DATA,
    FY_GET_BASIC_DATA,
    FY_GET_BUSINESS_DATA,
    FY_GET_SUBJECT_GROUP_DATA,
    GET_ACQUIRE_OVERVIEW_BASIC,
    GET_ACQUIRE_OVERVIEW_BUSINESS,
    GET_ACQUIRE_OVERVIEW_CE,
    GET_ACQUIRE_OVERVIEW_SUBJECT,
    GET_ACQUIRED_ACTIVE_LAST_ACTION_DATE,
    GET_ACQUIRED_ACTIVE_LAST_ACTION_DATE_FY,
    GET_OAVSTOTAL_ALL_BUSINESSDIVISION,
    GET_OAVSTOTAL_ALL_SUBJECTGROUP
} from "@/actions/SIMT-Report/Report/ReportAction";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {FormattedMessage} from "react-intl";
import getAuth from "@/utils/getAuth";

/**
 * @author HuangTao
 * @date 2022/5/13
 * @description 获取Acquire页面basictable的展示信息
 * @returns
 * @param page
 * @param pageSize
 * @param order
 * @param orderBy
 * @param selectDate
 * @param filterMap
 */
export function getReportAcquireOverviewBasicTableData(
    page,
    pageSize,
    order,
    orderBy,
    selectDate,
    filterMap = {}
) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];

    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);

    return async dispatch => {
        try {
            let res = await axios_instance.post(
                '/reports/acquired-active/overview/basic',
                filterMap,
                {
                    params: {
                        pageNo: page,
                        limit: pageSize,
                        sortMethod: order,
                        sortKey: orderBy,
                        selectedDate: date,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );

            if (res.data.code === 200) {
                let returnData;
                if (res.data.data && res.data.data.result) {
                    returnData = res.data;
                } else {
                    returnData = {result: {data: [], month: []}, count: 0};
                }
                dispatch({type: GET_ACQUIRE_OVERVIEW_BASIC, data: returnData});
            } else {
                dispatch({
                    type: GET_ACQUIRE_OVERVIEW_BASIC,
                    data: {result: {data: [], month: []}, count: 0},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ACQUIRE_OVERVIEW_BASIC,
                data: {result: {data: [], month: []}, count: 0},
            });
        }
    };
}

export function GetAcquireOverviewSubjectTableData(selectDate) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/acquired-active/overview/sg/${date}`,
                {
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: GET_ACQUIRE_OVERVIEW_SUBJECT, data: res.data});
            } else {
                dispatch({
                    type: GET_ACQUIRE_OVERVIEW_SUBJECT,
                    data: {data: [], month: []},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ACQUIRE_OVERVIEW_SUBJECT,
                data: {data: [], month: []},
            });
        }
    };
}

export function GetAcquireOverviewBusinessTableData(selectDate) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/acquired-active/overview/bd/${date}`,
                {
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: GET_ACQUIRE_OVERVIEW_BUSINESS, data: res.data});
            } else {
                dispatch({
                    type: GET_ACQUIRE_OVERVIEW_BUSINESS,
                    data: {data: [], month: []},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ACQUIRE_OVERVIEW_BUSINESS,
                data: {data: [], month: []},
            });
        }
    };
}

export function GetAcquireOverviewCETableData(selectDate) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/acquired-active/overview/ce/${date}`,
                {
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: GET_ACQUIRE_OVERVIEW_CE, data: res.data});
            } else {
                dispatch({
                    type: GET_ACQUIRE_OVERVIEW_CE,
                    data: {data: [], month: []},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ACQUIRE_OVERVIEW_CE,
                data: {data: [], month: []},
            });
        }
    };
}

/**
 * 获得FY Basic Table数据
 * @param page  页码
 * @param pageSize  一页显示多少
 * @param sortMethod  asc或desc
 * @param sortKey 依据什么排序
 * @param fiscalYear  财年
 * @param useFilters  filter obj
 * @returns {(function(*): Promise<void>)|*}
 */
export function getFYBasicData(
    page,
    pageSize,
    sortMethod,
    sortKey,
    fiscalYear,
    useFilters = {}
) {
    return async dispatch => {
        try {
            let res = await axios_instance.post(
                '/reports/acquired-active/fy-trends/basic',
                useFilters,
                {
                    params: {
                        limit: pageSize,
                        pageNo: page,
                        sortMethod: sortMethod,
                        selectedFY: fiscalYear,
                        sortKey: sortKey,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: FY_GET_BASIC_DATA, data: res.data.data});
            } else {
                dispatch({type: FY_GET_BASIC_DATA, data: {result: [], count: 0}});
                dispatch(
                    setSnackbarMessageAndOpen(
                        'common.failToLoad',
                        {
                            value: 'Basic Table',
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({type: FY_GET_BASIC_DATA, data: {result: [], count: 0}});
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.errorundefinedOcc',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

/**
 * 获得subject group 数据
 * @param fiscalYear 财年
 * @param isOA
 */
export function getFYSubjectData(fiscalYear, isOA) {
    return async dispatch => {
        try {
            let res = await axios_instance.get(
                `/reports/acquired-active/fy-trends/sg/${fiscalYear}`,
                {
                    params: {
                        isOA: isOA,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: FY_GET_SUBJECT_GROUP_DATA, data: res.data.data});
            } else {
                dispatch({type: FY_GET_BUSINESS_DATA, data: []});
                dispatch(
                    setSnackbarMessageAndOpen(
                        'common.failToLoad',
                        {
                            value: <FormattedMessage id='report.subjectGroup'/>,
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({
                type: FY_GET_SUBJECT_GROUP_DATA,
                data: [],
            });
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToLoad',
                    {
                        value: <FormattedMessage id='report.subjectGroup'/>,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
}

export function getFYBusinessData(fiscalYear, isOA) {
    return async dispatch => {
        try {
            let res = await axios_instance.get(
                `/reports/acquired-active/fy-trends/bd/${fiscalYear}`,
                {
                    params: {
                        isOA: isOA,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: FY_GET_BUSINESS_DATA, data: res.data.data});
            } else {
                dispatch({type: FY_GET_BUSINESS_DATA, data: []});
                dispatch(
                    setSnackbarMessageAndOpen(
                        'common.failToLoad',
                        {
                            value: <FormattedMessage id='report.businessDivision'/>,
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({type: FY_GET_BUSINESS_DATA, data: []});
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToLoad',
                    {
                        value: <FormattedMessage id='report.businessDivision'/>,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
}

export function getAllSubjectGroup(selectDate) {
    let selectDateYear = selectDate.split('-')[0];
    let selectDateMonth = selectDate.split('-')[1];
    let currentMonth = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/acquired-active/oa-vs-total/sg/${currentMonth}`,
                {
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: GET_OAVSTOTAL_ALL_SUBJECTGROUP, data: res.data});
            } else {
                dispatch({
                    type: GET_OAVSTOTAL_ALL_SUBJECTGROUP,
                    data: {data: [], month: []},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_OAVSTOTAL_ALL_SUBJECTGROUP,
                data: {data: [], month: []},
            });
        }
    };
}

export function getAllBusinessDivision(selectDate) {
    let selectDateYear = selectDate.split('-')[0];
    let selectDateMonth = selectDate.split('-')[1];
    let currentMonth = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/acquired-active/oa-vs-total/bd/${currentMonth}`,
                {
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({
                    type: GET_OAVSTOTAL_ALL_BUSINESSDIVISION,
                    data: res.data,
                });
            } else {
                dispatch({
                    type: GET_OAVSTOTAL_ALL_BUSINESSDIVISION,
                    data: {data: [], month: []},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_OAVSTOTAL_ALL_BUSINESSDIVISION,
                data: {data: [], month: []},
            });
        }
    };
}

/**
 *
 *获取AnnualTrends中SubjectGroup中的数据
 */
export function GetAnnualSubjectTableData(selectDateAnnual, isOA) {
    var selectDateYear = selectDateAnnual.split('-')[0];
    var selectDateMonth = selectDateAnnual.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/acquired-active/annual-trends/sg/${date}`,
                {
                    params: {
                        // selectedDate: date,
                        isOA: isOA,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: ANNUALTRENDS_SUBJECTGRUOP_DATA, data: res.data.data});
            } else {
                dispatch({type: ANNUALTRENDS_SUBJECTGRUOP_DATA, data: []});
            }
        } catch (err) {
            dispatch({type: ANNUALTRENDS_SUBJECTGRUOP_DATA, data: []});
        }
    };
}

/**
 *
 *获取AnnualTrends中BusinessDivision中的数据
 */
export function GetAnnualBusinessTableData(selectDateAnnual, isOA) {
    var selectDateYear = selectDateAnnual.split('-')[0];
    var selectDateMonth = selectDateAnnual.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/acquired-active/annual-trends/bd/${date}`,
                {
                    params: {
                        // selectedDate: date,
                        isOA: isOA,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: ANNUALTRENDS_BUSINESS_DATA, data: res.data.data});
            } else {
                dispatch({type: ANNUALTRENDS_BUSINESS_DATA, data: []});
            }
        } catch (err) {
            dispatch({type: ANNUALTRENDS_BUSINESS_DATA, data: []});
        }
    };
}

export function GetAcquiredActiveLastActionDate(selectDate, isOA) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/acquired-active/last-action-date/${date}`,
                {
                    params: {
                        isOA: isOA,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({
                    type: GET_ACQUIRED_ACTIVE_LAST_ACTION_DATE,
                    data: res.data,
                });
            } else {
                dispatch({
                    type: GET_ACQUIRED_ACTIVE_LAST_ACTION_DATE,
                    data: {data: []},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ACQUIRED_ACTIVE_LAST_ACTION_DATE,
                data: {data: []},
            });
        }
    };
}

export function GetAcquiredActiveLastActionDateFY(fiscalYear, isOA) {
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/acquired-active/last-action-date/fy/${fiscalYear}`,
                {
                    params: {
                        isOA: isOA,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({
                    type: GET_ACQUIRED_ACTIVE_LAST_ACTION_DATE_FY,
                    data: res.data,
                });
            } else {
                dispatch({
                    type: GET_ACQUIRED_ACTIVE_LAST_ACTION_DATE_FY,
                    data: {data: []},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ACQUIRED_ACTIVE_LAST_ACTION_DATE_FY,
                data: {data: []},
            });
        }
    };
}
import { Height } from '@mui/icons-material';
import { TableRow,TableCell, Typography } from '@mui/material';
import React from 'react';

function NoDataMessage({message,MarginTop, left='50%'}){
  const marginTop=MarginTop;
  const TableRowStyle={
    display: 'flex',
    height: '76px',
  };
  const TableCellStyle={
    position:'absolute',
    //width:'492px',
    height:'44px',
    left: left,
    backgroundColor: 'transparent',
    transform: 'translateX(-50%)',
    borderBottom:'none',
    alignItems: 'center',
    marginTop:MarginTop,
  };
  const TypographyStyle = {
    fontFamily: 'Open Sans',
    fontStyle:'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing:'0.01em',
    color: '#596A7C',
    marginTop: marginTop, 
  };
  return (
    <TableRow style={TableRowStyle} 
      id='NoDataMessageTableRow'
      data-selenium-id='Componentsphase2-NoDataMessage-TableRow'
    >
      <TableCell style={TableCellStyle}
        id='NoDataMessageTableCell'
        data-selenium-id='Componentsphase2-NoDataMessage-TableCell'
      >
        <Typography style={TypographyStyle}
          id='NoDataMessageTypography'
          data-selenium-id='Componentsphase2-NoDataMessage-Typography'
        >
          {message}
        </Typography>          
      </TableCell>
    </TableRow>
  );
}

export default NoDataMessage;   

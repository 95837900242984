import { axios_instance } from '../utils/axios_instance';
import { setSnackbarMessageAndOpen } from './SnackbarAction';
import { SEVERITIES } from '../modules/ConfirmSnackBar/CommonSnackBar';
import { paramsEncode } from '@/utils/commonUtils';
import MyLocalStorage from "../utils/MyLocalStorage";
import {getUserId} from '@/utils/getUserId';

// create Redux action types
export const GET_ALL_ADMIN_INFO = 'GET_ALL_ADMIN_INFO';
export const EDIT_ADMIN = 'EDIT_ADMIN';
export const EDIT_USER = 'EDIT_USER';
export const GET_ALL_USER_INFO = 'GET_ALL_USER_INFO';
export const GET_ALL_USER_IDS = 'GET_ALL_USER_IDS';
export const ACTIVE_ACCOUNT = 'ACTIVE_ACCOUNT';
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const GET_USER_INFO = 'GET_USER_INFO';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const INACTIVE_ADMIN = 'INACTIVE_ADMIN';
export const INACTIVE_USER = 'INACTIVE_USER';
export const UNLOCK_ADMIN = 'UNLOCK_ADMIN';
export const UNLOCK_USER = 'UNLOCK_USER';
export const GET_ADMIN_INFO = 'GET_ADMIN_INFO';
export const UPGRADE_USER = 'UPGRADE_USER';
export const DOWNGRADE_ADMIN = 'DOWNGRADE_ADMIN';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const CLEAR_USER_ACTION = 'CLEAR_USER_ACTION';
export const CLEAR_USER_LOCK = 'CLEAR_USER_LOCK';
export const BTACH_UPDATE_RESULT = 'BTACH_UPDATE_RESULT';
export const EDIT_CLEAR = 'EDIT_CLEAR';
export const ACTIVE_CLEAR = 'ACTIVE_CLEAR';
export const CHECK_USER_EMAIL = 'CHECK_USER_EMAIL';
export const GET_GROUP_INFO = 'GET_GROUP_INFO';
export const ADMIN_EMAIL_CHECK = 'ADMIN_EMAIL_CHECK';

export const ADMIN_ORDER_BY = 'ADMIN_ORDER_BY'; // Set Admin list sorting
export const LOAD_ADMIN_DATA = 'LOAD_ADMIN_DATA';
export const CHANGE_ADMIN_PAGE = 'CHANGE_ADMIN_PAGE';
export const CHANGE_ADMIN_PAGE_SIZE = 'CHANGE_ADMIN_PAGE_SIZE';
export const CHANGE_UPDATE_ADMIN = 'CHANGE_UPDATE_ADMIN';

// Create Redux action creators that return an action
export const clear = () => ({
	type: CLEAR_USER_ACTION,
});

export const lockClear = () => ({
	type: CLEAR_USER_LOCK,
});

export const activeEmailClear = () => ({
	type: ACTIVE_CLEAR,
});

//Get all admin information
export function getAllAdminInfo(page, pageSize, order, orderBy) {
	return async dispatch => {
		try {
			const res = await axios_instance.get(`/super/systemAdmin`, {
				params: {
					pageNum: page,
					pageSize: pageSize,
					orderField: orderBy,
					orderBy: order,
				},
				headers: {
					authorization: localStorage.getItem('authorization'),
				},
			});
			if (res.data.code === 200) {
				dispatch({ type: GET_ALL_ADMIN_INFO, data: res.data.data });
			} else {
				dispatch({ type: GET_ALL_ADMIN_INFO, data: null });
			}
		} catch (err) {
			dispatch({ type: GET_ALL_ADMIN_INFO, data: err.response?.data.msg });
		}
	};
}

// The action to the admin(resend, reset, delete)
export function editAdmin(email, type) {
	return async dispatch => {
		try {
			let url;
			switch (type) {
				case 'resendInvitationToAdmin': //Send activation emails to specified users
					url = `/super/resendActiveMail/${email}`;
					break;
				case 'resetAdminPass': //Reset user password to default password
					url = `/super/resetSystemAdmin/${email}`;
					break;
				case 'deleteAdmin': //remove specified user
					url = `/super/deleteSystemAdmin/${email}`;
					break;
				default:
					url = null;
					break;
			}

			const res = await axios_instance.get(url, {
				headers: {
					authorization: localStorage.getItem('authorization'),
				},
			});

			if (res.data.code === 200) {
				dispatch({ type: EDIT_ADMIN, data: true });
			} else if (
				res.data.code === 500 &&
				(res.data.message === 'SIAssociated' ||
					res.data.message == 'PreSIAssociated')
			) {
				dispatch({ type: EDIT_ADMIN, data: 'SIAssociated' });
			} else {
				dispatch({ type: EDIT_ADMIN, data: false });
			}
		} catch (err) {
			dispatch({ type: EDIT_ADMIN, data: err.response.data.msg });
		}
	};
}

//delete a user by userId
//old api:Get /system/users/deleteNormalUsers
export function deleteUser(userId) {
	return async dispatch => {
		try {
			const url = `/api/v1/user-service/users/${userId}`;
			const res = await axios_instance.delete(url, {
				headers: {
					authorization: localStorage.getItem('authorization'),
					fullName: 'Tao, HUang'
				},
			});
			if (res.data.code === 200) {
				dispatch({ type: EDIT_USER, data: 1 });
			} else {
				dispatch({ type: EDIT_USER, data: 0 });
			}
		} catch (err) {
			dispatch({ type: EDIT_USER, data: err.response?.data.msg });
		}
	};
}
//system send active mail to normal user by userId
//old api:Get /system/users/resendActiveMail
export function sendActiveEmail(userId) {
	return async dispatch => {
		try {
			const url = `/api/v1/user-service/users/${userId}/active`;
			const res = await axios_instance.put(url, null,{
				headers: {
					authorization: localStorage.getItem('authorization'),
					fullName: 'Tao, HUang'
				},
			});
			if (res.data.code === 200) {
				dispatch({ type: EDIT_USER, data: 1 });
			} else {
				dispatch({ type: EDIT_USER, data: 0 });
			}
		} catch (err) {
			dispatch({ type: EDIT_USER, data: err.response?.data.msg });
		}
	};
}
// //The action to the user(resend, reset, delete)
// export function editUser(email, type) {
// 	return async dispatch => {
// 		try {
// 			let url;
// 			switch (type) {
// 				case 'deleteUser': //remove specified user
// 					url = `/system/users/deleteNormalUsers`;//分出
// 					break;
// 				case 'resendInvitationToUser': //Send activation emails to specified users
// 					url = ``;//分出
// 					break;
// 				case 'resetUserPass':
// 					url = `/system/users/resetNormalUser`;//分出
// 					break;
// 				default:
// 					url = null;
// 					break;
// 			}

// 			const res = await axios_instance.get(url, {
// 				params: {
// 					email: email,
// 				},
// 				headers: {
// 					authorization: localStorage.getItem('authorization'),
// 				},
// 			});

// 			if (res.data.code === 200) {
// 				dispatch({ type: EDIT_USER, data: true });
// 				// dispatch(setSnackbarMessageAndOpen("user.success", {}, SEVERITIES.success));
// 			} else if (
// 				res.data.code === 500 &&
// 				(res.data.message === 'SIAssociated' ||
// 					res.data.message == 'PreSIAssociated')
// 			) {
// 				dispatch({ type: EDIT_USER, data: 'SIAssociated' });
// 			} else {
// 				dispatch({ type: EDIT_USER, data: false });
// 			}
// 		} catch (err) {
// 			dispatch({ type: EDIT_USER, data: err.response.data.msg });
// 		}
// 	};
// }

//Get all user information
export function getAllUserInfo(
	page,
	pageSize,
	username,
	role,
	group,
	accStatus,
	order,
	orderBy
) {
	let params = paramsEncode({
		pageNum: page,
		pageSize: pageSize,
		name: username,
		roleName: role,
		group: group,
		activeStatus: accStatus,
		orderField: orderBy,
		orderBy: order,
	});
	return async dispatch => {
		try {
			const res = await axios_instance.get('/system/users', {
				params: params,
				headers: {
					authorization: localStorage.getItem('authorization'),
				},
			});

			if (res.data.code === 200) {
				dispatch({ type: GET_ALL_USER_INFO, data: res.data.data });
			} else {
				dispatch({ type: GET_ALL_USER_INFO, data: null });
			}
		} catch (err) {
			dispatch({ type: GET_ALL_USER_INFO, data: err.response.data.msg });
		}
	};
}

//Get all user information
export function getAllUserInfoIds(
	username,
	role,
	group,
	accStatus
) {
	let params = paramsEncode({
		name: username,
		roleName: role,
		group: group,
		activeStatus: accStatus,
	});
	return async dispatch => {
		try {
			const res = await axios_instance.get('/system/users/ids', {
				params: params,
				headers: {
					authorization: localStorage.getItem('authorization'),
				},
			});

			if (res.data.code === 200) {
				dispatch({ type: GET_ALL_USER_IDS, data: res.data.data });
			} else {
				dispatch({ type: GET_ALL_USER_IDS, data: [] });
			}
		} catch (err) {
			dispatch({ type: GET_ALL_USER_IDS, data: [] });
		}
	};
}

// Acctive account by email and digitalSignature
export function activeAccount(signature, email) {
	return async dispatch => {
		try {
			const res = await axios_instance.get(
				`/login/activateAccount/${signature}/${email}`
			);

			if (res.data.code === 200) {
				dispatch({ type: ACTIVE_ACCOUNT, data: true });
			} else {
				dispatch({ type: ACTIVE_ACCOUNT, data: false });
			}
		} catch (err) {
			dispatch({ type: ACTIVE_ACCOUNT, data: err.response.data.msg });
		}
	};
}

// Create a new Admin
export function createAdmin(FirstName, LastName, email, group) {
	return async dispatch => {
		try {
			const url = '/super/createSystemAdmin';
			const result = await axios_instance.post(
				url,
				{
					firstName: FirstName,
					lastName: LastName,
					email: email,
					group: group,
				},
				{
					headers: {
						authorization: localStorage.getItem('authorization'),
					},
				}
			);

			if (result.data.code === 200) {
				if (result.data.message === 'update to admin successfully') {
					dispatch({ type: UPGRADE_USER, data: 'true' });
				} else {
					dispatch({ type: CREATE_ADMIN, data: 'true' });
				}
			} else {
				if (result.data.code === 500) {
					if (result.data.message === 'the email address has been registered')
						//判断是否是邮箱存在错误
						dispatch({ type: CREATE_ADMIN, data: 'EmailExit' });
				}
			}
		} catch (err) {
			dispatch({ type: CREATE_ADMIN, data: err.response.data.msg });
		}
	};
}

// //Get user information
// // 原API：Get /user/profile
// export function getUserInfo(userId=getUserId()) {
// 	return async dispatch => {
// 		try {
// 			const url = `/api/v1/user-service/users/${userId}`;
// 			const result = await axios_instance.get(url, {
// 				headers: {
// 					authorization: localStorage.getItem('authorization'),
// 				},
// 			});
// 			MyLocalStorage.setItem('userInfo', result.data.data); // 储存到local里面减少网络请求
// 			dispatch({ type: GET_USER_INFO, data: result.data });
// 			if (res.data.code === 200) {
// 				dispatch({ type: EDIT_EMAILMANUALDETAIL, data: 'Ok' });
// 			} else {
// 				dispatch({ type: EDIT_EMAILMANUALDETAIL, data: 'error' });
// 			}
// 		} catch (err) {
// 			dispatch(
// 				setSnackbarMessageAndOpen(
// 					'common.failToLoadOcc',
// 					{
// 						value: 'account detail', // TODO 以后再改为id的形式
// 					},
// 					SEVERITIES.error
// 				)
// 			);
// 		}
// 	};
// }

// //system admin creat normal user
// //old api:Post /system/users/createNormalUser
// export function createUser(FirstName, LastName, email, Group, Role) {
// 	return async dispatch => {
// 		try {
// 			const url = '/api/v1/user-service/users';

// 			const result = await axios_instance.post(
// 				url,
// 				{
// 					firstName: FirstName,
// 					lastName: LastName,
// 					email: email,
// 					group: Group,
// 					roleId: Role,
// 				},
// 				{
// 					headers: {
// 						Authorization: localStorage.getItem('authorization'),
// 						fullname:'Tao, HUang'
// 					},
// 				}
// 			);

// 			if (result.data.code === 200) {
// 				dispatch({ type: CREATE_USER, data: 'true' });
// 				dispatch(
// 					setSnackbarMessageAndOpen(
// 						'user.createSucc',
// 						{ fullname: `${LastName}, ${FirstName}` },
// 						SEVERITIES.success
// 					)
// 				);
// 			} else {
// 				if (result.data.code === 500) {
// 					if (result.data.message === 'the email address has been registered')
// 						//判断是否是邮箱存在错误
// 						dispatch({ type: CREATE_USER, data: 'EmailExit' });
// 				} else {
// 					dispatch({ type: CREATE_USER, data: 'error' });
// 				}
// 			}
// 		} catch (err) {
// 			dispatch({ type: CREATE_USER, data: err.response.data.msg });
// 		}
// 	};
// }

export function updateClear() {
	return dispatch => {
		dispatch({ type: UPDATE_USER, data: '' });
		dispatch({ type: CREATE_USER, data: '' });
		dispatch({ type: BTACH_UPDATE_RESULT, data: '' });
	};
}

//对所有的更新操作初始化
export function editClear() {
	return dispatch => {
		dispatch({ type: EDIT_CLEAR });
	};
}

//Update a user
export function updateUser(FirstName, LastName, email, Group, Role) {
	return async dispatch => {
		try {
			let url = '/system/users/updateNormalUser';
			let result = await axios_instance.post(
				url,
				{
					firstName: FirstName,
					lastName: LastName,
					email: email,
					group: Group,
					roleId: Role,
				},
				{
					headers: {
						authorization: localStorage.getItem('authorization'),
					},
				}
			);

			if (result.data.code === 200) {
				dispatch({ type: UPDATE_USER, data: 'true' });
			} else {
				if (result.data.code === 500) {
					if (result.data.message === 'EmailExit')
						//判断是否是邮箱存在错误
						dispatch({ type: UPDATE_USER, data: 'EmailExit' });
				} else {
					dispatch({ type: CREATE_USER, data: 'error' });
				}
			}
		} catch (err) {
			dispatch({ type: UPDATE_USER, data: err.response.data.msg });
		}
	};
}

//Inactive a system admin
export function inactiveAdmin(email) {
	return async dispatch => {
		try {
			let res = await axios_instance.get(
				`/super/inactivateSystemAdmin/` + email,
				{
					headers: {
						authorization: localStorage.getItem('authorization'),
					},
				}
			);

			if (res.data.code === 200) {
				dispatch({ type: INACTIVE_ADMIN, data: 'true' });
			} else {
				dispatch({ type: INACTIVE_ADMIN, data: false });
			}
		} catch (err) {
			dispatch({ type: INACTIVE_ADMIN, data: err.response.data.msg });
		}
	};
}

// //system admin inactivate a normal user by userId
// //old api:Get /system/users/inactivateNormalUser
// export function inactiveUser(userId) {
// 	return async dispatch => {
// 		try {
// 			const url=`/api/v1/user-service/users/${userId}/active`
// 			let res = await axios_instance.get(
// 				url,
// 				{
// 					headers: {
// 						authorization: localStorage.getItem('authorization'),
// 						fullName:'Tao, HUang'
// 					},
// 				}
// 			);

// 			if (res.data.code === 200) {
// 				dispatch({ type: INACTIVE_USER, data: 'OK' });
// 			} else if (res.data.message === 'can not inactivate handling ce') {
// 				dispatch({ type: INACTIVE_USER, data: 'HEInactive' });
// 			} else {
// 				dispatch({ type: INACTIVE_USER, data: false });
// 			}
// 		} catch (err) {
// 			dispatch({ type: INACTIVE_USER, data: err.response.data.msg });
// 		}
// 	};
// }

//unlock a admin
export function unlockAdmin(email) {
	return async dispatch => {
		try {
			let res = await axios_instance.get(
				`/super/unlockSystemAdmin?email=` + email,
				{
					headers: {
						authorization: localStorage.getItem('authorization'),
					},
				}
			);

			if (res.data.code === 200) {
				dispatch({ type: UNLOCK_ADMIN, data: 'true' });
			} else {
				dispatch({ type: UNLOCK_ADMIN, data: 'error' });
			}
		} catch (err) {
			dispatch({ type: UNLOCK_ADMIN, data: err.response.data.msg });
		}
	};
}

//Unlock a User
export function unlockUser(email) {
	return async dispatch => {
		try {
			let res = await axios_instance.get(
				`/system/users/unlockNormalUser?email=` + email,
				{
					headers: {
						authorization: localStorage.getItem('authorization'),
					},
				}
			);

			if (res.data.code === 200) {
				dispatch({ type: UNLOCK_USER, data: true });
			} else {
				dispatch({ type: UNLOCK_USER, data: false });
			}
		} catch (err) {
			dispatch({ type: UNLOCK_USER, data: err.response.data.msg });
		}
	};
}

/**
 * 超级管理员使用邮箱获得Admin信息
 * @param {string} email 需要检查的邮件地址
 * @returns 如果对应用户存在，返回由first name、last name和role组成的object，否则返回null
 */
export function getAdminInfoByEmail(email) {
	return async dispatch => {
		try {
			let url = '/super/checkEmail/' + email;
			let res = await axios_instance.get(url, {
				headers: {
					authorization: localStorage.getItem('authorization'),
				},
			});
			if (res.data.code === 200) {
				dispatch({
					type: ADMIN_EMAIL_CHECK,
					data: { data: res, email: email },
				});
			} else {
				dispatch({ type: ADMIN_EMAIL_CHECK, data: res, email: '' });
			}
		} catch (err) {
			// dispatch({ type: GET_ADMIN_INFO, data: err.response.data.msg });
		}
	};
}

/**
 * 超级管理员根据邮箱，将对应用户的角色升级为System Admin
 * @param {string} email 邮箱
 * @returns 修改成功返回true，否则返回false
 */
export function upgradeUser(email, fullName, group) {
	return async dispatch => {
		try {
			var n = fullName.split(' ');
			var FirstName = n[0];
			var LastName = n[1];
			let url = '/super/toSystemAdmin';
			let result = await axios_instance.post(
				url + '?email=' + email,
				{},
				{
					headers: {
						authorization: localStorage.getItem('authorization'),
					},
				}
			);
			if (result.data.code === 200) {
				dispatch({ type: UPGRADE_USER, data: 'true' });
			} else {
				if (result.data.code === 500) {
					if (result.data.message === 'the email address has been registered')
						//判断是否是邮箱存在错误
						dispatch({ type: UPGRADE_USER, data: 'EmailExit' });
				}
				dispatch({ type: UPGRADE_USER, data: 'error' });
			}
		} catch (err) {
			dispatch({ type: UPGRADE_USER, data: err.response.data.msg });
		}
	};
}

//Downgrade Admin
export function downgradeAdmin(email, role, group) {
	return async dispatch => {
		try {
			let url = '/super/degradeSystemAdmin';
			let res = await axios_instance.post(
				url,
				{
					email: email,
					group: group,
					role: role,
				},
				{
					headers: {
						authorization: localStorage.getItem('authorization'),
					},
				}
			);
			if (res.data.code === 200) {
				dispatch({ type: DOWNGRADE_ADMIN, data: 'true' });
			} else {
				dispatch({ type: DOWNGRADE_ADMIN, data: 'error' });
			}
		} catch (err) {
			dispatch({ type: DOWNGRADE_ADMIN, data: err.response.data.msg });
		}
	};
}

export function batchUpdateUser(users, role, group) {
	return async dispatch => {
		try {
			let url = '/system/users/changeGroupOrRole';
			let res = await axios_instance.get(url, {
				params: {
					userCodes: users,
					group: group,
					role: role,
				},
				headers: {
					authorization: localStorage.getItem('authorization'),
				},
			});
			if (res.data.code === 200) {
				dispatch({ type: BTACH_UPDATE_RESULT, data: 'true' });
			} else {
				dispatch({ type: BTACH_UPDATE_RESULT, data: 'error' });
			}
		} catch (err) {
			dispatch({ type: BTACH_UPDATE_RESULT, data: err.response.data.msg });
		}
	};
}

/**创建User检查Email */
export function checkUserEmail(email) {
	return async dispatch => {
		try {
			let url = '/system/users/checkUserEmail?email=' + email;
			let res = await axios_instance.get(url, {
				headers: {
					authorization: localStorage.getItem('authorization'),
				},
			});
			if (res.data.code == 500 && res.data.message === 'Existing Email') {
				dispatch({
					type: CHECK_USER_EMAIL,
					data: true,
				});
			} else {
				dispatch({ type: CHECK_USER_EMAIL, data: false });
			}
		} catch (err) {
			// dispatch({ type: GET_ADMIN_INFO, data: err.response.data.msg });
		}
	};
}

// Update a new Admin
export function updateAdmin(email, group, role, locked, active) {
	return async dispatch => {
		try {
			const url = '/super/updateSystemAdmin';
			const result = await axios_instance.post(
				url,
				{
					role: role,
					locked: locked,
					active: active,
					email: email,
					group: group,
				},
				{
					headers: {
						authorization: localStorage.getItem('authorization'),
					},
				}
			);

			if (result.data.code === 200) {
				if (role !== 'Admin') {
					dispatch({ type: DOWNGRADE_ADMIN, data: 'true' });
				} else {
					dispatch({ type: UPDATE_ADMIN, data: 'true' });
				}
			} else if (result.data.message === 'can not inactivate handling ce') {
				dispatch({ type: UPDATE_ADMIN, data: 'HEInactive' });
			} else {
				//判断是否是邮箱存在错误
				dispatch({ type: DOWNGRADE_ADMIN, data: 'error' });
			}
		} catch (err) {
			dispatch({ type: DOWNGRADE_ADMIN, data: err.response.data.msg });
		}
	};
}

export function getGroupInfo() {
	return async dispatch => {
		try {
			let url = '/user/group';
			let res = await axios_instance.get(url, {
				headers: {
					authorization: localStorage.getItem('authorization'),
				},
			});
			if (res.data.code === 200) {
				dispatch({
					type: GET_GROUP_INFO,
					data: res.data.data,
				});
			} else {
				dispatch({ type: GET_GROUP_INFO, data: [] });
			}
		} catch (err) {
			// dispatch({ type: GET_ADMIN_INFO, data: err.response.data.msg });
		}
	};
}

/**
 * 更改排序顺序和字段
 * @param order
 * @param by
 * @returns {(function(*): void)|*}
 */
export function setAdminOrderBy(order, by) {
	return dispatch => {
		dispatch({ type: ADMIN_ORDER_BY, data: { order: order, orderBy: by } });
	};
}

/**
 * 获取admin列表数据
 * @param {*} page
 * @param {*} pageSize
 * @param {*} order
 * @param {*} orderBy
 * @returns
 */
export function loadAdminData(page, pageSize, order, orderBy) {
	return async dispatch => {
		try {
			const res = await axios_instance.get(`/super/systemAdmin`, {
				params: {
					pageNum: page,
					pageSize: pageSize,
					orderField: orderBy,
					orderBy: order,
				},
				headers: {
					authorization: localStorage.getItem('authorization'),
				},
			});
			if (res.data.code === 200) {
				//将返回数据添加独一无二的ID，以适应表格
				const returnData = {
					result: [],
					count: res.data.data.total,
				};
				for (const resultEle of res.data.data.admins) {
					const temp = {
						...resultEle,
					};
					temp['id'] = temp.email;
					returnData.result.push(temp);
				}

				dispatch({ type: LOAD_ADMIN_DATA, data: returnData });
			} else {
				dispatch({
					type: LOAD_ADMIN_DATA,
					data: {
						result: [],
						count: 0,
					},
				});
			}
		} catch (err) {
			dispatch({
				type: LOAD_ADMIN_DATA,
				data: {
					result: [],
					count: 0,
				},
			});
		}
	};
}

/**
 * 更改表格页码
 * @param {int} page 页码
 * @returns dispatch
 */
export function handleAdminPageChangeAction(page) {
	return dispatch => {
		dispatch({
			type: CHANGE_ADMIN_PAGE,
			data: page,
		});
	};
}

/**
 * 更改表格一页数量
 * @param {int} pageSize 一页的大小
 * @returns dispatch
 */
export function handleChangeAdminPageSize(pageSize) {
	return dispatch => {
		dispatch({
			type: CHANGE_ADMIN_PAGE_SIZE,
			data: pageSize,
		});
	};
}

export function handleChangeAdminUpdateName(fullName) {
	return dispatch => {
		dispatch({
			type: CHANGE_UPDATE_ADMIN,
			data: fullName,
		});
	};
}

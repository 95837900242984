import { useDispatch } from "react-redux";
import { IconButton, ListItem, Box, } from "@mui/material";
import { GetLabel } from './fileRelated/fileLabel';
import { useEffect } from 'react';
import { ChooseIconByType, formatFileSize, ShowFlag, getFlagType } from '@/pages/GEPage/GEDetailPage/FlagBlock';
import {downloadSingleFile} from "@/actions/SIMT-SI/GuestEditor/GEFlag";
import {downloadCVFile} from "@/actions/SIMT-SI/GuestEditor/SipDetail";

export const PreviewGEDetailItem = ({
    _key,
    value,
    isTitleLine,
    title,
}) => {

    if (isTitleLine) {
        return (
            <ListItem
                alignItems='baseline'
                sx={{
                    // border: '1px solid #E3E8EE',
                    width: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    padding: '0px',
                }}
            >
                <Box
                    sx={{
                        color: 'var(--gray-500, #596A7C)',
                        fontFamily: 'Open Sans',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        width: '526px',

                    }}
                    textAlign='left'
                >
                    {title}
                </Box>
            </ListItem>
        );
    };

    const dispatch = useDispatch();
    const TypeField = () => {
        if (_key === 'CV' && value) {
            const match = value.match(/(\d{5,13})_/); // 查找匹配的部分
            return (
                <GetLabel
                    sx={{
                        marginTop: '0px',
                        marginLeft: '0px',
                        width: '286px!important',
                        height: '32px!important'
                    }}
                    FileIcon={ChooseIconByType(
                        value?.split(';')[0].split('.').slice(-1)[0]
                    )}
                    download={() => {dispatch(downloadCVFile(value.split(';size')[0].split('name:')[1]));}}
                    fileName={value?.split(';')[0].substring(match.index + match[0].length).replace(/(.*)(?=\.)/, '$1')}
                    fileSize={formatFileSize(Number(value?.split(';')[1].split(':')[1]))}
                    fileMIMEType={value?.split(';')[0].split('.').slice(-1)[0]}
                    isDownLoad={true}
                />
            );
        };
        return (
            <Box
                sx={{
                    width: '286px',
                    wordBreak: 'break-all',
                    color: '#262E35',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                {value}
            </Box>);
    };

    return (
        <ListItem
            alignItems='baseline'
            // key={index}
            sx={{
                // border: '1px solid #E3E8EE',
                padding: 0,
                width: 'auto',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
            }}
        >
            {/* item key */}
            <Box sx={{ display: 'flex', padding: '6px' }}>
                <Box
                    width='160px'
                    textAlign='left'
                    sx={{ color: '#596A7C', fontSize: '14px', fontWeight: '400', marginRight: '68px' }}
                >
                    {_key + ':'}
                </Box>
                {TypeField()}
            </Box>
        </ListItem>
    );

};

export const PreviewGEFlagItem = ({
    _key = '',
    flag = {},
    evidences = [],
    comment = '',
    index = 1,
}) => {
    
    const urlParams = new URLSearchParams(window.location.search);
    // const code1 = urlParams.get('ge1');
    const code2 = urlParams.get('ge2');


    const dispatch = useDispatch();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '21px', marginLeft: '6px', marginRight: '6px' }} >
                <ListItem
                    alignItems='baseline'
                    // key={index}
                    sx={{
                        // border: '1px solid #E3E8EE',
                        width: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        padding: '0px',
                        marginBottom: '21px',
                    }}
                >
                    <Box
                        width='160px'
                        textAlign='left'
                        sx={{ color: '#596A7C', fontSize: '14px', fontWeight: '400', marginRight: '68px' }}
                    >
                        {'Flag' + index + ':'}
                    </Box>
                    <Box
                        sx={{
                            width: '286px',
                            wordBreak: 'break-all',
                            color: '#262E35',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '20px',
                        }}
                    >
                        <ShowFlag flagType={flag.type} flagValue={flag.value} />
                    </Box>
                </ListItem>
                <ListItem
                    alignItems='baseline'
                    sx={{
                        width: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        padding: '0px',
                    }}
                >
                    <Box
                        width='160px'
                        textAlign='left'
                        sx={{ color: '#596A7C', fontSize: '14px', fontWeight: '400', marginRight: '68px' }}
                    >
                        {'Flag' + index + ' Comments:'}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }} >
                        <Box
                            sx={{
                                width: '286px',
                                wordBreak: 'break-all',
                                color: '#262E35',
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '20px',
                            }}
                        >
                            {comment}
                        </Box>
                    </Box>
                </ListItem>
            </Box>
            <Box sx={{ width: '16px' }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '6px', marginRight: '6px', gap:'21px' }} >
                <ListItem
                    alignItems='baseline'
                    // key={index}
                    sx={{
                        width: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        padding: '0px',
                        marginBottom: '21px',
                    }}
                >
                    <Box
                        width='160px'
                        textAlign='left'
                        sx={{ color: '#596A7C', fontSize: '14px', fontWeight: '400', marginRight: '68px' }}
                    >
                        {'Flag' + index + ' Evidence:'}
                    </Box>
                    <Box sx={{ gap: '8px', display: 'flex', flexDirection: 'column' }} >
                        {evidences?.map((evidence, index) => {
                            const file_size = Number(evidence.split('_')[0]);
                            const left_part = evidence.slice(evidence.indexOf('_') + 1);
                            const fileName = left_part?.slice(left_part.indexOf('_') + 1)?.replace(/(.*)(?=\.)/, '$1');
                            return <GetLabel
                                sx={{
                                    marginTop: '0px',
                                    marginLeft: '0px',
                                    width: '286px!important',
                                    height: '32px!important'
                                }}
                                FileIcon={ChooseIconByType(
                                    left_part?.split('.').slice(-1)[0]
                                )}
                                del={() => {
                                }}
                                download={() => {
                                    dispatch(downloadSingleFile(evidence, fileName, code2));
                                }}
                                fileName={fileName}
                                fileSize={formatFileSize(file_size)}
                                fileMIMEType={left_part?.split('.').slice(-1)[0]}
                                needDelete={false}
                            />;
                        })}
                    </Box>
                </ListItem>
            </Box>

        </Box>
    );
};

import React from 'react';
import {
  TransformToSiPageContainer,
  TransformToSiStepperContainer,
} from '../CustomComponent/CustomContainer';
import {
  TranformToSiStep,
  TranformToSiStepper,
} from '../CustomComponent/CustomStepper';

/**
 *
 * @param {import("./Header").TransformToSiStepHeaderProps} props
 * @returns
 */
export const TransformToSiStepHeader = props => {
  const { labels, currentTransformStep } = props;
  return (
    <TransformToSiStepperContainer>
      <TranformToSiStepper activeStep={currentTransformStep}>
        {labels.map(label => (
          <TranformToSiStep key={label}>{label}</TranformToSiStep>
        ))}
      </TranformToSiStepper>
    </TransformToSiStepperContainer>
  );
};

import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  IconButton,
  TextField,
  Popover
} from '@mui/material';
import SIFilterCalendarIcon from '../../../../../assets/SIFilterCalendarIcon.svg';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateCalendar } from '@/components/DateRangePicker';
import * as DateFnsUtils from 'date-fns';
import zIndex from '@mui/material/styles/zIndex';
import SimpleIconButton from '@/componentsphase2/Button/SimpleIconButton';
import DefaultReset from '@/assets/DefaultReset.svg';
import {
  selectCatJournalFilterItem,
} from '@/actions/SIMT-SI/CatJournal/CATJournalAction';
import { TinyFont } from '@/components/FontStyle';
import { useDispatch, useSelector } from 'react-redux';
export default function DateRangePicker(props){
  const dispatch = useDispatch();
  const {
    fromDate, toDate,
    minDate, maxDate,
    handleDateChange, placeholder, minHeight,
    width = '328px', border = '1px solid #98A7B6', popoverIndex = zIndex, innerBorder,
    iconPaddingRight, backgroundColor, type
  } = props;
  const [dateOpen, setDateOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);

  useEffect(() => {
    setStartDate(fromDate);
  }, [fromDate]);

  useEffect(() => {
    setEndDate(toDate);
  }, [toDate]);

  const handleOpenPicker = (event) => {
    setDateOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePicker = () => {
    setDateOpen(false);
    setAnchorEl(null);
    handleDateChange(startDate, endDate);
  };

  const handleFromDateChange = (date) => {
    if (!(endDate && DateFnsUtils.isAfter(date, endDate))) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date) => {
    if (!(startDate && DateFnsUtils.isBefore(date, startDate))) {
      setEndDate(date);
    }
  };

  const emptyDateString = '                    ';
  const formatDateOptional = (dt) => {
    return dt ? DateFnsUtils.format(dt, 'yyyy-MM-dd') : emptyDateString;
  };
  const textFieldString = () => {
    if (fromDate || toDate) {
      return `${formatDateOptional(fromDate)} ${fromDate && toDate ? '  To  ': '    '} ${formatDateOptional(toDate)}`;
    } else {
      return '';
    }
  };

  const id = dateOpen ? 'date-range-popover' : undefined;

  const clearSelectedDate=(type)=>{
    
    if(type==='optIn'){
      dispatch(
        selectCatJournalFilterItem('optInDateFrom', null, 'replace')
      );
      dispatch(
        selectCatJournalFilterItem('optInDateTo', null, 'replace')
      );
      
    }
    else{// type === optOut
      
      dispatch(
        selectCatJournalFilterItem('optOutDateFrom', null, 'replace')
      );
      dispatch(
        selectCatJournalFilterItem('optOutDateTo', null, 'replace')
      );
    }
    
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} data-selenium-id={`SIPage_Overview-newFilterDrawer-FilterDataPicker`} >
      <Box sx={{
        width: width,
        padding: 0,
        display:'flex',
        '& .MuiFormControl-root': {
          marginTop: 0,
          marginBottom: 0,
          '& .MuiInputBase-input': {
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: minHeight
          }
        }
      }}>
        <TextField
          fullWidth={true}
          value={textFieldString()}
          placeholder={placeholder || 'yyyy-mm-dd  To  yyyy-mm-dd'}
          onClick={handleOpenPicker}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={handleOpenPicker}
                sx={{
                  paddingRight: iconPaddingRight, '&:hover': {
                    backgroundColor: 'transparent !important',
                  }
                }}>
                <SIFilterCalendarIcon />
              </IconButton>
            ),
          }}
          sx={{
            '& input': {
              //width: '196px',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '14px',
              lineHeight: '24px',
              display: 'flex',
              alignItems: 'center',
              '&::placeholder': {
                fontWeight: 400,
              },
            },
            width: width,
            borderRadius: '4px',
            backgroundColor: backgroundColor,
            border: border,
            ...innerBorder === undefined ? (
              dateOpen ? { borderColor: '#0052CC' } : { borderColor: '#98A7B6' }
            ) : {
              '& .MuiOutlinedInput-notchedOutline': {
                border: innerBorder,
              }
            },
            '& :empty': {
              borderColor: '#DFE4E8',
            },
            '& :hover': {
              borderColor: '#262E35',
            },
            '& :focus': {
              borderColor: '#0052CC',
            },
          }}
        />
        <Box
          id={`siPageFilterDrawerReset-${id}`}
          data-selenium-id={`siPageFilterDrawerReset-${id}`}
          sx={{
            ...TinyFont,
            color: '#154AB6',
            cursor: 'pointer',
            paddingTop:'9px',
            paddingLeft:'3px',
            
            ':hover':{
              fontWeight: '600',
              color: '#113D95',
            },
          }}
          onClick={() => {
            clearSelectedDate(type);
          }}
        >
          Reset
        </Box>
        <Popover
          id={id}
          open={dateOpen}
          anchorEl={anchorEl}
          onClose={handleClosePicker}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            zIndex: popoverIndex,
            '& .MuiPaper-root': {
              mt: '6px',
              width: 'fit-content',
              height: 'fit-content',
              overflowX: 'hidden',
              overflowY: 'hidden',
            }
          }}
        >
          <Stack direction="row" spacing={2} sx={{
            width: '100%',
            height: '100%',
            gap: '54px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            padding: '16px 24px 20px 24px',
            '& .MuiDateCalendar-root': {
              marginTop: 0,
            },
            '& .DateRange-FromCalendar': {
              // marginLeft: '24px',
            },
            '& .DateRange-ToCalendar': {
              marginLeft: '0px',
              marginRight: '0px',
            },
            '& .DateRange-Title': {
              width: '37px',
              height: '16px',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '16px',
              textAlign: 'center',
              color: '#596A7C',
              marginBottom: '16px',
            },
          }}>
            <Stack className="DateRange-FromCalendar" spacing={2} >
              <Box className="DateRange-Title">From:</Box>
              <DateCalendar date={startDate} toDay={endDate} minDate={minDate} maxDate={maxDate} onChange={handleFromDateChange} fromAndTo={true} />
            </Stack>
            <Stack className="DateRange-ToCalendar" spacing={2}>
              <Box className="DateRange-Title">To:</Box>
              <DateCalendar date={endDate} fromDay={startDate} minDate={minDate} maxDate={maxDate} onChange={handleEndDateChange} fromAndTo={true} />
            </Stack>
          </Stack>
        </Popover>
      </Box>
    </LocalizationProvider>
  );
};

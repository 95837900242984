import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { tabClasses } from '@mui/material';
import { NewDesignedMain } from '../../../components/Main';
import Grid from '@mui/material/Grid';
import { Box, } from '@mui/material';
import ExportMarkrtingData from '../../../assets/exportMarketingData.svg';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import Monthly from './Monthly';
import Submission from './Submission';
import Published from './Published';
import SystemData from './SystemData';
import {exportData} from "@/actions/SIMT-SI/Export";


function a11yProps(index) {
  return {
    id: `marketExportTab${index}`,
    'aria-controls': `marketExportTabPanel${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`marketExportTabPanel${index}`}
      data-selenium-id={`MarketExport-TabPanel-${index}`}
      aria-labelledby={`marketExportTab${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ paddingTop: '12px', }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};




function ExportData() {
  const [value, setValue] = useState(0);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  const MarketTabChildren = styled(Tab)(({ theme }) => ({
    [`&.${tabClasses.root}`]: {
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '1px',
      color: '#596A7C',
      padding: '16px',
    },
    [`&.${tabClasses.selected}`]: {
      color: '#113D95',
    },
  }));
  const open = useSelector(state => state.UserManage.siderOpen);
  const dispatch = useDispatch();
  const textStyle = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '22px',
    color: '#262E35',
  };
  /*const handleExportClick = () => {
    dispatch(exportData(exportData));
  };*/
  const [deadDateOpen, setDeadDateOpen] = useState(false);

  return (
    <>
      <Box
        p={'24px 20px 28px 20px'}
        data-selenium-id='SystemAdmin_ExportData-Box'
      >
        <NewDesignedMain
          open={open}
          data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain'
        >
          <Grid
            container
            data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid'
          >
            <Box
              sx={{
                width: '817px',
                height: deadDateOpen?'664px':'624px',
                padding: '32px',
                background: '#FFFFFF',
              }}
            >
              <Grid
                data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid'
                container
                direction='column'
                sx={{ width: '753px' }}
                style={{
                  gridGap: '16px',
                }}
              >
                <div
                  style={textStyle}
                  data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-ContainerDiv'
                >
                  <FormattedMessage
                    id='exportData.title'
                    data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-ContainerDiv-FormattedMessage'
                  />
                </div>
                <Box
                  sx={{ width: '100%', padding: 0 }}
                  data-selenium-id='Market_Export-Box'
                >
                  <Box
                    sx={{ mt: '0px', borderColor: 'divider',width:'100%' }}
                    data-selenium-id='Market_Export-Box-Box'
                  >
                    <Tabs
                      data-selenium-id='Market_Export-Tabs'
                      onChange={handleChange}
                      value={value}
                      sx={{
                        '& .MuiTabs-indicator':{
                          background: '#113D95',
                        },
                      }}
                      aria-label='Market_Export tabs example'
                    >
                      <MarketTabChildren
                        disableRipple
                        id='Monthly Campaign'
                        data-selenium-id='Report_Articles-Tab'
                        label={
                          'MONTHLY CAMPAIGN'
                        }
                        {...a11yProps(0)}
                      />
                      <MarketTabChildren
                        disableRipple
                        id='Submission Campaign'
                        data-selenium-id='Report_Articles-OsTab'
                        label={
                          'SUBMISSION CAMPAIGN'
                        }
                        {...a11yProps(1)}
                      />
                      <MarketTabChildren
                        disableRipple
                        id='published Campaign'
                        data-selenium-id='Report_Articles-FYTab'
                        label={
                          'PUBLISHED CAMPAIGN'
                        }
                        {...a11yProps(2)}
                      />
                      <MarketTabChildren
                        disableRipple
                        id='System Data'
                        data-selenium-id='Report_Articles-EDTab'
                        label={
                          'SYSTEM DATA'
                        }
                        {...a11yProps(3)}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel
                    value={value}
                    index={0}
                    data-selenium-id='Report_Articles-TabPanel1'
                    sx={{ marginTop: '-20px', }}
                  >
                    <Monthly />
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={1}
                    data-selenium-id='Report_Articles-TabPanel2'
                    sx={{ marginTop: '-20px', }}
                  >
                    <Submission 
                      deadDateOpen={deadDateOpen}
                      setDeadDateOpen={setDeadDateOpen}
                    />
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={2}
                    data-selenium-id='Report_Articles-TabPanel3'
                    sx={{ marginTop: '-20px', }}
                  >
                    <Published />
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={3}
                    data-selenium-id='Report_Articles-TabPanel4'
                    sx={{ marginTop: '-20px', }}
                  >
                    <SystemData/>
                  </TabPanel>
                </Box>
                {/*<Grid
                data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-Grid'
                container
                style={{
                  gridGap: '20px',
                }}
              >
                <Grid
                  container
                  sm={0.5}
                  md={0.5}
                  xs={0.5}
                  alignItems='center'
                  data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-Grid-Grid1'
                >
                  <div
                    data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-Grid-Grid1-ContainerDiv'
                    style={{
                      ...textStyle,
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#596A7C',
                    }}
                  >
                    <FormattedMessage
                      id='exportData.date'
                      data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-Grid-Grid1-ContainerDiv-FormattedMessage'
                    />
                  </div>
                </Grid>
                <Grid
                  container
                  sm={5}
                  md={5}
                  xs={5}
                  justifyContent='center'
                  data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-Grid-Grid2'
                >
                  <FormControl
                    data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-Grid-Grid2-FormControl'
                    variant='filled'
                    sx={{ width: '100%', maxWidth: '200px', height: '40px' }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-Grid-Grid2-FormControl-LocalizationProvider'
                    >
                      <MobileDatePicker
                        //sx={{ width: "100%", height: "40px" }}
                        open={exportDataOpen}
                        onOpen={() => {
                          setExportDataOpen(true);
                        }}
                        onClose={() => {
                          setExportDataOpen(false);
                        }}
                        // required = {MandatoryJudge("exported Publication Date",MandatoryList)}
                        id='ExportDataDateInput'
                        data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-Grid-Grid2-FormControl-LocalizationProvider-MobileDatePicker'
                        // label="exported Publication Date *"
                        value={exportData}
                        onChange={handleExportedMarketingData}
                        // //minDate里写的是最小时间是上个日期加一天
                        // minDate={
                        //   exportedAcceptanceDate === null
                        //     ? undefined
                        //     : new Date(
                        //       moment(
                        //         moment(exportedAcceptanceDate, "YYYY-MM-DD").add(
                        //           1,
                        //           "days"
                        //         )
                        //       ).format("YYYY-MM-DD")
                        //     )
                        // }
                        renderInput={params => (
                          <TextField
                            id='CreateSIPublicationDateInput'
                            data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-Grid-Grid2-FormControl-LocalizationProvider-CreateSIPublicationDateInput'
                            {...params}
                            InputProps={{
                              style: {
                                ...textStyle,
                                fontWeight: '400',
                                fontSize: '14px',
                                letterSpacing: '-0.011em',
                                color: '#262E35',
                                height: '40px',
                                padding: '1px 1px',
                                fontSize: '14px',
                              },
                              endAdornment: (
                                <IconButton
                                  data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-Grid-Grid2-FormControl-LocalizationProvider-CreateSIPublicationDateInput-IconButton'
                                  onClick={() => {
                                    setExportDataOpen(
                                      !exportDataOpen
                                    );
                                  }}
                                >
                                  <ExportMarkrtingData data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-Grid-Grid2-FormControl-LocalizationProvider-CreateSIPublicationDateInput-IconButton-ExportMarkrtingData' />
                                </IconButton>
                              ),
                            }}
                          />
                        )}
                        inputFormat='yyyy-MM-dd'
                        mask='____-__-__'
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid
                  container
                  sm={2}
                  md={2}
                  xs={2}
                  alignItems='center'
                  data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-Grid-Grid3'
                >
                  <ResponsiveButton
                    data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-Grid-Grid3-ResponsiveButton'
                    color='wileyBlue2'
                    variant='contained'
                    sx={{
                      minWidth: 'auto',
                      height: '32px',
                    }}
                    onClick={handleExportClick}
                  >
                    <div
                      data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-Grid-Grid3-ResponsiveButton-ContainerDiv'
                      style={{
                        ...textStyle,
                        fontSize: '14px',
                        textAlign: 'center',
                        letterSpacing: '0.01em',
                        color: '#F1F3F5',
                      }}
                    >
                      <FormattedMessage
                        id='exportData.export'
                        data-selenium-id='SystemAdmin_ExportData-Box-NewDesignedMain-Grid-Grid-Grid-Grid3-ResponsiveButton-ContainerDiv-FormattedMessage'
                      />
                    </div>
                  </ResponsiveButton>
                </Grid>
                    </Grid>*/}
              </Grid>
            </Box>
          </Grid>
        </NewDesignedMain>
      </Box>
    </>
  );
}
export default ExportData;

const labelColor = {
  'Basic Management OA': {
    containerColor: '#CEDDF9',
    percentageColor: '#4C81EB',
  },
  'On-Hold OA': {
    containerColor: '#E8E9F9',
    percentageColor: '#7882DC',
  },
  'SI Commissioning OA': {
    containerColor: '#D7F1EE',
    percentageColor: '#42C1B3',
  },
};

const oa = {
  color: '#596A7C',
  fontFamily: 'Inter',
  fontSize: 10,
  fontWeight: 'normal',
  lineHeight: 14,
};
const commonTitle = {
  fontSize: 10,
  color: '#596A7C',
  fontWeight: 'bold',
  lineHeight: 14,
  padding: [0, 4, 0, 0],
};
const commonValue = {
  fontSize: 10,
  color: '#ffffff',
  fontWeight: 400,
  lineHeight: 14,
  padding: [2, 4],
  borderRadius: 2,
};

export const LABEL_FONT_STYLE = {
  oa: oa,
  title: commonTitle,
  value: {
    ...commonValue,
    backgroundColor: labelColor['Basic Management OA'].percentageColor,
  },
  value2: {
    ...commonValue,
    backgroundColor: labelColor['On-Hold OA'].percentageColor,
  },
  value3: {
    ...commonValue,
    backgroundColor: labelColor['SI Commissioning OA'].percentageColor,
  },
};

export const LABEL_CONTAINER_STYLE = {
  borderRadius: 4,
};
export const OALabelFormatter = params => {
  const title = params.data[0];
  const value = params.data[1];
  if (title === 'Basic Management OA') {
    return (
      '{oaStyle|(OA)}{title|' +
      title.replace(/ OA$/, '') +
      '}{value|' +
      value +
      '}'
    );
  }
  if (title === 'On-Hold OA') {
    return (
      '{oaStyle|(OA)}{title|' +
      title.replace(/ OA$/, '') +
      '}{value2|' +
      value +
      '}'
    );
  }
  if (title === 'SI Commissioning OA') {
    return (
      '{oaStyle|(OA)}{title|' +
      title.replace(/ OA$/, '') +
      '}{value3|' +
      value +
      '}'
    );
  }
  return '';
};

/**
 * to parse {oaStyle|(OA)}{title|' + title + '}{value2|' + value + '}' and get the title
 * Only get the first shortest match and before
 * @param {*} text
 */
export const parseOALabel = text => {
  const regex = /\{title\|([^}]+)\}/;
  const match = text.match(regex);
  if (match && match.length > 1) {
    const title = match[1];
    return title;
  } else {
    return null;
  }
};

import { Button, buttonClasses } from '@mui/material';
import { styled } from '@mui/system';

export const buttonTextStyleDecision = color => {
  return {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'center',
    color: color,
    textTransform: 'capitalize',
    height: '41px !important',
    width: '147 !important',
    // padding:'12 18 12 18',
  };
};
export const buttonTextStyleSubmit = (color, fontWeight) => {
  return {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: fontWeight,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
  };
};
const CommonButtonClassesRootStyle = {
  textTransform: 'capitalize',
  borderRadius: '5px',
  fontWeight: 600,
  boxShadow: 'none',
};
const disabledBackgroundColor = '#DFE4E8';
const DecisionFoldBtnDisabledColor = '#98A7B6';

export const CancelBtn = styled(Button)(props => ({
  ...buttonTextStyleSubmit('#154AB6', 600),
  [`&.${buttonClasses.disabled}`]: {
    backgroundColor: '#F1F3F5',
    borderColor: DecisionFoldBtnDisabledColor,
    color: DecisionFoldBtnDisabledColor,
  },
  [`&.${buttonClasses.root}`]: {
    ...CommonButtonClassesRootStyle,
    variant: 'text',
  },
  backgroundColor: props.empty ? '#E8EDFB' : 'transparent',
  width: '70px',
  height: '34px',
  padding: '8px, 12px, 8px, 12px',
  alignItems: 'center',
  borderRadius: '5px',
  boxSizing: 'border-box',
  color: props.empty ? '#AEC6F6' : '#0052CC',
  border: props.empty ? '1px solid #AEC6F6' : '1px solid #0052CC',
  '&:hover': {
    color: props.empty ? '#AEC6F6' : '#0052CC',
    border: props.empty ? '1px solid #AEC6F6' : '1px solid #0052CC',
  },
  '&:active': {
    color: props.empty ? '#AEC6F6' : '#0052CC',
    border: props.empty ? '1px solid #AEC6F6' : '1px solid #0052CC',
  },
}));

export const SubmitAndNextDisabledBtnStyle = {
  [`&.${buttonClasses.disabled}`]: {
    backgroundColor: disabledBackgroundColor,
    color: DecisionFoldBtnDisabledColor,
  },
};

export const SubmitBtn = styled(Button)({
  ...buttonTextStyleSubmit('#FFFFFF', 600),
  [`&.${buttonClasses.root}`]: CommonButtonClassesRootStyle,
  ...SubmitAndNextDisabledBtnStyle,
  width: '70px',
  height: '34px',
  padding: '8px, 12px, 8px, 12px',
  borderRadius: '5px',
  variant: 'contained',
  background: '#0052CC',
  '&:hover': {
    backgroundColor: '#6D98EE',
  },
  '&:active': {
    backgroundColor: '#113D95',
  },
  padding: '6px 12px',
  alignItems: 'center',
  opacity: 1,
});

export const NextBtn = styled(Button)({
  ...buttonTextStyleSubmit('#FFFFFF', 600),
  [`&.${buttonClasses.root}`]: CommonButtonClassesRootStyle,
  ...SubmitAndNextDisabledBtnStyle,
  width: '60px',
  height: '32px',
  variant: 'contained',
  background: '#0052CC',
  '&:hover': {
    backgroundColor: '#6D98EE',
  },
  '&:active': {
    backgroundColor: '#113D95',
  },
  padding: '6px 12px',
  alignItems: 'center',
  opacity: 1,
});
const DecisionFoldBtnDisabledStyle = {
  [`&.${buttonClasses.disabled}`]: {
    backgroundColor: '#DFE4E8',
    color: DecisionFoldBtnDisabledColor,
    '&>span>svg>path': {
      fill: DecisionFoldBtnDisabledColor,
    },
  },
};


/**
 * Panel Btns - Folded
 */

// normal - fold
export const DecisionFoldBtn = styled(Button)({
  ...buttonTextStyleDecision('#F6F7F8'),
  [`&.${buttonClasses.root}`]: {
    ...CommonButtonClassesRootStyle,
    opacity: 1,
  },
  ...DecisionFoldBtnDisabledStyle,
  minWidth: '147px',
  height: '41px',
  padding: '12px 18px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#0052CC',
  '&:hover': {
    backgroundColor: '#6D98EE',
  },
  '&:active': {
    backgroundColor: '#113D95',
  },
});

// saved - fold
export const SavedDecisionFoldBtn = styled(Button)({
  ...buttonTextStyleDecision('#F6F7F8'),
  [`&.${buttonClasses.root}`]: {
    ...CommonButtonClassesRootStyle,
    opacity: 1,
  },
  ...DecisionFoldBtnDisabledStyle,
  minWidth: '147px',
  height: '41px',
  padding: '12px 18px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#A1DDD6',
  color: '#0D6A61',
  '& svg path': {
    fill: '#0D6A61',
  },
  '&:hover': {
    backgroundColor: '#48A79A',
    color: '#fff',
    '& svg path': {
      fill: '#fff',
    },
  },
  '&:active': {
    backgroundColor: '#0D6A61',
    color: '#fff',
    '& svg path': {
      fill: '#fff',
    },
  },
});

export const DecisionFoldButtonWithSave = ({saved = false, ...props}) =>
  saved ? <SavedDecisionFoldBtn {...props} /> : <DecisionFoldBtn {...props} />;


/**
 * Panel Btns - Unfolded
 */
const DecisionUnfoldBtnDisabledColor = '#BCC5CF';

// disabled style
const DecisionUnfoldBtnDisabledStyle = {
  [`&.${buttonClasses.disabled}`]: {
    backgroundColor: 'transparent',
    color: DecisionUnfoldBtnDisabledColor,
    borderColor: DecisionUnfoldBtnDisabledColor,
    '&>span>svg>path': {
      fill: DecisionUnfoldBtnDisabledColor,
    },
  },
};

// normal - unfold
export const DecisionUnfoldBtn = styled(Button)({
  ...buttonTextStyleDecision('#0052CC'),
  [`&.${buttonClasses.root}`]: CommonButtonClassesRootStyle,
  ...DecisionUnfoldBtnDisabledStyle,
  minWidth: '147px',
  height: '41px',
  padding: '12px 18px',
  justifyContent: 'center',
  alignItems: 'center',
  // DEFAULT
  backgroundColor: 'transparent',
  border: '1px solid #0052CC',
  '& svg path': {
    fill: '#0052CC',
  },
  // HOVER
  '&:hover': {
    backgroundColor: 'transparent',
    border: '1px solid #6D98EE',
    color: '#6D98EE',
    '& svg path': {
      fill: '#6D98EE',
    },
  },
  // ACTIVE
  '&:active': {
    backgroundColor: '#E8EDFB',
    border: '1px solid #AEC6F6',
    color: '#AEC6F6',
    '& svg path': {
      fill: '#AEC6F6',
    },
  },
});

// save - unfold
export const DecisionUnfoldSavedBtn = styled(Button)({
  ...buttonTextStyleDecision('#0D6A61'),
  [`&.${buttonClasses.root}`]: CommonButtonClassesRootStyle,
  ...DecisionUnfoldBtnDisabledStyle,
  minWidth: '147px',
  height: '41px',
  padding: '12px 18px',
  justifyContent: 'center',
  alignItems: 'center',
  // DEFAULT
  backgroundColor: 'transparent',
  border: '1px solid #0D6A61',
  '& svg path': {
    fill: '#0D6A61',
  },
  // HOVER
  '&:hover': {
    backgroundColor: 'transparent',
    border: '1px solid #84D4CA',
    color: '#84D4CA',
    '& svg path': {
      fill: '#84D4CA',
    },
  },
  // ACTIVE
  '&:active': {
    backgroundColor: '#ECFDF5',
    border: '1px solid #A1DDD6',
    color: '#A1DDD6',
    '& svg path': {
      fill: '#A1DDD6',
    },
  },
});

export const DecisionUnfoldBtnWithSave = ({children, saving, ...props}) => {
  return saving ? <DecisionUnfoldSavedBtn {...props}>{children}</DecisionUnfoldSavedBtn> :
  <DecisionUnfoldBtn {...props}>{children}</DecisionUnfoldBtn>;
};


export const ErrVars = {
  '--color': 'rgb(238, 83, 80)',
  '--border': '2px solid rgb(238, 83, 80)',
  '--focus-border': '2px solid #154AB6',
  '--focus-color': 'rgb(238, 83, 80)',
  '--width': '100%',
};

export const defaultVars = {
  '--border': '1px solid #DFE4E8',
  '--color': '#DFE4E8',
  '--focus-border': '2px solid #154AB6',
  '--focus-color': '#154AB6',
  '--width': '100%',
};

import { Box } from '@mui/material';

const LittleTitle = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#262E35',
};

const Title = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: ' 400',
  fontSize: '20px',
  lineHeight: '28px',
  letterSpacing: '-0.022em',
  color: '#596A7C',
};

export function CreateAndUpdateLittleTitle(props) {
  const { littleTitle } = props;
  return (
    <Box
      data-selenium-id='SIPage_CreateAndUpdateSI-LittleTitleBox'
      sx={{
        width: '100%',
        height: '24px',
        ...LittleTitle,
        marginBottom: '16px',
      }}
    >
      {littleTitle}{' '}
    </Box>
  );
}

export function CreateAndUpdateTitle(props) {
  const { title,sx={} } = props;
  return (
    <Box
      data-selenium-id='SIPage_CreateAndUpdateSI-TitleBox'
      sx={{ width: '100%', height: '28px', ...Title, marginBottom: '24px' }}
    >
      {title}{' '}
    </Box>
  );
}

import {SIPFormState} from "@/reducers/SIPFormReducer";
import {useDispatch, useSelector} from "react-redux";
import {setSIPFormSectionState, setSIPFormValue} from "@/actions/SIMT-SIP/SIPForm";
import {validateDateString} from "@/pages/SIPForm/utils/validate_utils";
import {useEffect} from "react";
import {SectionState} from "@/pages/SIPForm/types.d";
import Card from "@/pages/SIPForm/components/Card";
import {DateInput} from "@/pages/SIPForm/components/DateInput";
import FormGroup from "@/pages/SIPForm/components/FormGroup";
import {useIntl} from "react-intl";

export interface ProposedDatesSectionProps {

}

export function ProposedDatesSection(props: ProposedDatesSectionProps) {
    const selector = (state: {
        SIPForm: SIPFormState;
        [key: string]: any;
    }) => state.SIPForm;

    const {
        formData,
        state: formStatus,
    } = useSelector(selector);

    const {
        proposedDates,
    } = formData;

    const dispatch = useDispatch();
    const intl = useIntl();

    const proceeding = formStatus === 'proceeding';

    const sectionData = proposedDates.data;
    const section = 'proposedDates';
    const setValue = (key: string, value: any) => dispatch(setSIPFormValue(
        section,
        key,
        value
    ));

    const valueSetter = (key: string) => (value: any) => setValue(key, value);

    const setFullManuscriptSubmissionDeadline = valueSetter('fullManuscriptSubmissionDeadline');
    const setAcceptanceDeadline = valueSetter('acceptanceDeadline');
    const setExpectedPublicationDate = valueSetter('expectedPublicationDate');

    const errorOfFullManuscriptSubmissionDeadline = !validateDateString(sectionData.fullManuscriptSubmissionDeadline);
    const errorOfAcceptanceDeadline_empty = !validateDateString(sectionData.acceptanceDeadline);
    const errorOfAcceptanceDeadline = errorOfAcceptanceDeadline_empty || (sectionData.fullManuscriptSubmissionDeadline > sectionData.acceptanceDeadline);
    const errorOfExpectedPublicationDate_empty = !validateDateString(sectionData.expectedPublicationDate);
    const errorOfExpectedPublicationDate = errorOfExpectedPublicationDate_empty || (sectionData.acceptanceDeadline > sectionData.expectedPublicationDate);

    const hasError = errorOfFullManuscriptSubmissionDeadline || errorOfAcceptanceDeadline || errorOfExpectedPublicationDate;

    useEffect(() => {
        dispatch(setSIPFormSectionState(section, hasError ? (proceeding ? SectionState.error: SectionState.default) : SectionState.completed));
    }, [hasError, proceeding]);

    return (
        <Card section={proposedDates}>
            <FormGroup
                title="Manuscript Submission Deadline:"
                isRequired={true}
                error={proceeding && errorOfFullManuscriptSubmissionDeadline}
                errorMessage={intl.messages['sipForm.errorText.required'] as string}
            >
                <DateInput
                    id={'fullManuscriptSubmissionDeadline-data-input'}
                    value={sectionData.fullManuscriptSubmissionDeadline}
                    onChange={(oldValue, newValue) => setFullManuscriptSubmissionDeadline(newValue)}
                    error={proceeding && errorOfFullManuscriptSubmissionDeadline}
                    placeholder={'MM/DD/YYYY'}
                />
            </FormGroup>

            <FormGroup
                title="Acceptance Deadline:"
                isRequired={true}
                error={proceeding && errorOfAcceptanceDeadline}
                errorMessage={intl.messages['sipForm.errorText.dateOfAcceptanceDeadline'] as string}
            >
                <DateInput
                    id={'acceptanceDeadline-data-input'}
                    value={sectionData.acceptanceDeadline}
                    onChange={(oldValue, newValue) => setAcceptanceDeadline(newValue)}
                    error={proceeding && errorOfAcceptanceDeadline}
                    placeholder={'MM/DD/YYYY'}
                    minDate={sectionData.fullManuscriptSubmissionDeadline}
                />
            </FormGroup>

            <FormGroup
                title="Expected Publication Date:"
                isRequired={true}
                error={proceeding && errorOfExpectedPublicationDate}
                errorMessage={ intl.messages['sipForm.errorText.dateOfPublication'] as string}
            >
                <DateInput
                    id={'expectedPublicationDate-data-input'}
                    value={sectionData.expectedPublicationDate}
                    onChange={(oldValue, newValue) => setExpectedPublicationDate(newValue)}
                    error={proceeding && errorOfExpectedPublicationDate}
                    placeholder={'MM/DD/YYYY'}
                    minDate={sectionData.acceptanceDeadline}
                />
            </FormGroup>
        </Card>
    );
}
import React, { useEffect } from 'react';
import {Box} from "@mui/material";
import Header from './components/Header';
import Footer from './components/Footer';
import { SIPFormState } from '@/reducers/SIPFormReducer';
import { useDispatch, useSelector } from 'react-redux';
import {loadCurrentUser} from '@/actions/SIMT-SIP/SIPForm';
import { useLocation} from 'react-router-dom';
import styles from './index.module.scss';
import Proposal from './subpages/Proposal';

import SpinnerIcon from './assets/app-loader-spinner.svg';


export default function SIPForm() {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id_token = params.get('id_token');
    const state = params.get('state');

    const selector = (state: {
        SIPForm: SIPFormState;
        [key: string]: any;
    }) => state.SIPForm;

    const {
        currentUser,
    } = useSelector(selector);

    useEffect(() => {
        dispatch(loadCurrentUser(id_token || undefined, state || undefined));
    }, []);

    if (!currentUser) {
        return (
            <Box className={styles['pre-load-frame']}>
                <Box className={styles['globalAppLoader']}>
                    <SpinnerIcon className={styles['spinner']} />
                    <Box className={styles['loading-text']}>Loading</Box>
                </Box>
            </Box>);
    }

    return (
        <Box className={styles['wire-frame']}>
            <Header />
            <Proposal />
            <Footer />
        </Box>
    );
}
import React from 'react';
import { Navigate } from 'react-router-dom';
import {
  Case,
  PermissionListSwitch,
  contains,
  containsAny,
  DefaultCase,
} from '../../components/PermissionControl/SwitchCase';
import {PERMISSIONS} from "@/constant/permission";

export default function HomePage() {
  // /simt/auth/index 中引入。根据用户不同的权限进行分流，到达各自的首页。
  if (localStorage.getItem('authorization') === null) {
    return (
      <Navigate
        to='/simt/login'
        data-selenium-id='AuthPage_HomePage-LoginNavigate'
      />
    );
  }

  return (
    <PermissionListSwitch>
      <Case prediction={contains(PERMISSIONS.SUPER_ADMIN_WRITE)}>
        <Navigate
          to='/simt/auth/superAdmin'
          data-selenium-id='AuthPage_HomePage-SuperAdminNavigate'
        />
      </Case>
      <Case prediction={contains(PERMISSIONS.ADMIN_WRITE)}>
        <Navigate
          to='/simt/auth/system/user'
          data-selenium-id='AuthPage_HomePage-UserNavigate'
        />
      </Case>
      <Case prediction={containsAny(PERMISSIONS.SI_WRITE, PERMISSIONS.SI_READ, PERMISSIONS.SI_READ_LIMITED)}>
        <Navigate
          to='/simt/auth/siRelated/si/overview'
          data-selenium-id='AuthPage_HomePage-SiNavigate'
        />
      </Case>
      <Case prediction={containsAny(PERMISSIONS.SIP_READ, PERMISSIONS.SIP_WRITE_JPA, PERMISSIONS.SIP_WRITE_HANDLING_CE)}>
          <Navigate
              to='/simt/auth/sipRelated/sip/overview'
              data-selenium-id='AuthPage_HomePage-SiNavigate'
          />
      </Case>
      <Case prediction={containsAny(PERMISSIONS.GE_WRITE, PERMISSIONS.GE_READ, PERMISSIONS.GE_EXPORT)}>
        <Navigate
          to='/simt/auth/siRelated/ge/overview'
          data-selenium-id='AuthPage_HomePage-SiNavigate'
        />
      </Case>
      <Case prediction={containsAny(PERMISSIONS.REPORT_READ_GENERAL)}>
        <Navigate
          to='/simt/auth/reportRelated/report/siPipeline'
          data-selenium-id='AuthPage_HomePage-ReportNavigate'
        />
      </Case>
      <Case prediction={containsAny(PERMISSIONS.JOURNAL_CR_CONTACT)}>
        <Navigate
          to='/simt/auth/journalRelated/prp/overview'
          data-selenium-id='AuthPage_HomePage-CrJournalNavigate'
        />
      </Case>
      <Case prediction={containsAny(PERMISSIONS.CHINA_MARKETING_WRITE)}>
        <Navigate
          to='/simt/auth/marketing/si/overview'
          data-selenium-id='AuthPage_HomePage-ChinaMarketingNavigate'
        />
      </Case>
      <Case prediction={containsAny(PERMISSIONS.CHINA_MARKETING_READ)}>
        <Navigate
          to='/simt/auth/siRelated/si/overview'
          data-selenium-id='AuthPage_HomePage-ChinaMarketingNavigate'
        />
      </Case>
      <DefaultCase>
        <div />
      </DefaultCase>
    </PermissionListSwitch>
  );
}

import { Box, Stack } from '@mui/material';
import React from 'react';

const EditAdminInputLabel = props => {
  const { text, sx, textSx, starSx } = props;
  return (
    <Stack
      id='TextBox'
      data-selenium-id='SuperAdminManage_EditAdmin-InputLabel-Stack'
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      sx={{
        width: '122px',
        color: '#596A7C',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '125%',
        ...sx,
      }}
    >
      <Box
        id='text'
        data-selenium-id='SuperAdminManage_EditAdmin-InputLabel-TextBox'
        sx={{ ...textSx }}
      >
        {text}
      </Box>
      <Box
        id='*'
        data-selenium-id='SuperAdminManage_EditAdmin-InputLabel-*Box'
        sx={{ color: '#C40000', width: '5px', marginLeft: '2px', ...starSx }}
      >
        *
      </Box>
    </Stack>
  );
};

export default EditAdminInputLabel;

import React from 'react';
import { Box, Button, IconButton, Divider, Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import {
  loadData,
  resetFilter,
  setDate,
  setFilters,
  setSelect,
} from '../../../../actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import DateRangePicker from '../../../../components/DateRangePicker';
import SIFilterClose from '../../../../assets/SIFilterClose.svg';
import { Block, Block_Top } from './Block';
import {
  drawer_main_block,
  drawer_main_block_operation,
  drawer_main_block_title,
  drawer_reset_date_button,
} from './style';
import {
  H4Font,
  MiniBoldFont,
  SmallBoldFont,
  SmallFont,
  MiniFont,
  InterFont,
  LargeBoldFont,
} from '../../../../components/FontStyle';
import StageSelector from './Components';
import SelectBox from './Phase2Component';
import {
  drawer,
  drawer_head,
  drawer_head_title,
  drawer_head_icon,
  stack_left,
  stack_right,
  stack_header,
  drawer_top_box,
  drawer_top_button,
  drawer_bottom_button_list,
  drawer_bottom_button_reset,
  drawer_bottom_button_adapt,
  drawer_main_block_after_stage,
  drawer_main_block_fixed,
} from './style';
import { blankTrim, getUTCZeroTimeOffset } from '@/utils/commonUtils';
import { useEffect } from 'react';
import { clearTime, formatDateString } from '../../../../utils/commonUtils';
import { handleFileterJournalName } from '@/utils/commonUtils';
import { cancelSelectAll } from '../../../../actions/SeleteAction';
import {
  setGEFilterOpen,
  handleGEPageChangeAction,
  setGEFilterSelect,
  loadGEFilterOptions,
  setGEFilters,
  resetGEFilter,
  unselectBatchGE,
} from '@/actions/SIMT-SI/GuestEditor/GEAction';
import { unstable_batchedUpdates } from 'react-dom';
import { AlertBox, UntitledAlertBox } from '@/components/Alert/AlertBox';
import { CloseButton } from './Phase2Component';
import CloseIcon from '../../../../assets/SIOverviewColumnCloseFigma.svg';
import CloseHoverIcon from '../../../../assets/SIOverviewColumnCloseHoverFigma.svg';
import SIDrawerAutoCompleteMagnifyingGlassIcon from '../../../../assets/SIDrawerAutoCompleteMagnifyingGlassPhase2.svg';
import { SelectInput, MulSelectInput } from '../../../../componentsphase2/SelectBox/SelectInput';
import { getFlaglist } from "@/actions/SIMT-SI/GuestEditor/Config";
import {PERMISSIONS} from "@/constant/permission";
import { StateButton } from '@/componentsphase2/StateButton';
import { useSingleSelectDatePicker } from '@/pages/SIPage/Overview/Filter/utils';
const ALL_ACTIVE_STAGES_STRING = 'Active Stages';


export default function Overview(props) {
  const dispatch = useDispatch();
  const { filterOptions, filterSelected, selectedGE } = useSelector(state => ({
    // filter display
    filterOptions: state.GE.filterOptions,
    // filter selected
    filterSelected: state.GE.filterSelected,
    selectedGE: state.GE.selectedGE,
  }));

  const permssion = localStorage
    .getItem('userPermissions')
    .includes(PERMISSIONS.SI_READ_LIMITED);

  const prepareFilters = () => {
    // 这里修改应用的filter的格式，因为filterSelected中的fullName储存的事first+last的数组，这里先组合成fullName
    const handlingCeIds = [];
    for (const element of filterSelected.handlingCe) {
      handlingCeIds.push(element.userId);
    }
    // 处理group id
    const groupIds = [];
    for (const element of filterSelected.group) {
      groupIds.push(element.id);
    }
    // flag id
    const flagIds = [];
    for (const element of filterSelected.flags) {
      flagIds.push(element.id);
    }
    return {
      ...filterSelected,
      handlingCe: handlingCeIds,
      group: groupIds,
      flags: flagIds,
      fullName: filterSelected.fullName.map(item => {
        return item.geLastName + ', ' + item.geFirstName;
      }),
      createDateFrom: filterSelected.createDateFrom ? [filterSelected.createDateFrom] : [],
      createDateTo: filterSelected.createDateTo ? [filterSelected.createDateTo] : []
    };
  };

  // add alert component
  const [openSaveSuccess, setOpenSaveSuccess] = React.useState(false);

  const [openLoadedFilterOptions, setOpenLoadedFilterOptions] =
    React.useState(false);

  const [hover, setHover] = React.useState(false);

  useEffect(() => {
    // load Two constant list
    if (!permssion) {
      dispatch(loadGEFilterOptions('group'));
      dispatch(loadGEFilterOptions('gender'));
    }
    // if ()
  }, [permssion, dispatch]);

  const onLoad = async (which, searchContent, page = 1, pageSize = 20) => {
    // This list is constant, no more load
    // if (which === 'gender') {
    //   return;
    // }
    if (!permssion) {
      await dispatch(
        loadGEFilterOptions(which, blankTrim(searchContent), page, pageSize)
      );
    }
  };

  const handleSelectedChange = (which, data) => {
    dispatch(setGEFilterSelect(which, data));
  };

  const handleApply = () => {
    const filters = prepareFilters();
    unstable_batchedUpdates(() => {
      dispatch(setGEFilters(filters));
      dispatch(handleGEPageChangeAction(1)); // set page to 1 after changing the filters
      dispatch(setGEFilterOpen(false));
      dispatch(unselectBatchGE(selectedGE)); // clear all selected items
    });
  };

  const handleReset = () => {
    dispatch(resetGEFilter());
  };

  const handleSetCreateDateFrom = newDate => {
    const newDateWithoutTime = getUTCZeroTimeOffset(clearTime(newDate));
    if (
      formatDateString(newDateWithoutTime) >
      formatDateString(filterSelected.createDateTo)
    ) {
      // If the From date is greater than the to date, the to date is null
      handleSelectedChange('createDateTo', null);
    }
    handleSelectedChange('createDateFrom', newDateWithoutTime);
  };

  const handleSetCreateDateTo = newDate => {
    if (newDate === null) {
      handleSelectedChange('createDateTo', null);
    } else {
      const newDateWithoutTime = getUTCZeroTimeOffset(clearTime(newDate));
      if (
        formatDateString(newDateWithoutTime) >=
        formatDateString(filterSelected.createDateFrom)
      ) {
        handleSelectedChange('createDateTo', newDateWithoutTime);
      }
    }
  };

  return (
    <Box
      id='gePageFilterDrawerMainBox'
      data-selenium-id={'GEPage_Overview-anotherNewFilterDrawer-Overview'}
      height='100%'
    >
      <Box
        id='gePageFilterDrawerMainContentBox'
        data-selenium-id={
          'GEPage_Overview-anotherNewFilterDrawer-Overview-Content'
        }
        sx={drawer}
      >
        {/* The header of filter */}
        <Stack
          id='gePageFilterDrawerStackHeader'
          data-selenium-id={
            'GEPage_Overview-anotherNewFilterDrawer-Overview-Stack-Header'
          }
          direction='row'
          spacing={0}
          sx={stack_header}
        >
          <Box
            id='btnCloseSIFilter'
            data-selenium-id={
              'GEPage_Overview-anotherNewFilterDrawer-Overview-btnCloseSIFilter'
            }
            sx={{
              width: '24px',
              height: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => {
              dispatch(setGEFilterOpen(false));
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {hover ? <CloseHoverIcon /> : <CloseIcon />}

            {/* <SIFilterClose /> */}
          </Box>

          <Box
            id='gePageFilterDrawerTitle'
            data-selenium-id={
              'GEPage_Overview-anotherNewFilterDrawer-Overview-Title'
            }
            sx={drawer_head_title}
          >
            Filters
          </Box>

          <Box
            id='btnClearFilter'
            data-selenium-id={
              'GEPage_Overview-anotherNewFilterDrawer-Overview-btnClearFilter'
            }
            sx={drawer_bottom_button_reset}
            onClick={handleReset}
          >
            Reset
          </Box>

          <StateButton
            id='btnApplyFilter'
            data-selenium-id={
              'GEPage_Overview-anotherNewFilterDrawer-Overview-btnApplyFilter'
            }
            sx={drawer_bottom_button_adapt}
            onClick={handleApply}
            titleName='Adapt Filters'
            disabled={useSingleSelectDatePicker(filterSelected?.createDateFrom, filterSelected?.createDateTo)}
          >
            Adapt Filters
          </StateButton>
        </Stack>

        <Divider
          id='gePageFilterDrivider'
          data-selenium-id={
            'GEPage_Overview-anotherNewFilterDrawer-Overview-Drivider'
          }
        />
        {/* <Box sx={drawer_top_buttonList}>

        </Box> */}
        <Stack
          id='gePageFilterDrawerMainStack'
          data-selenium-id={
            'GEPage_Overview-anotherNewFilterDrawer-Overview-Main-Stack'
          }
          direction='row'
          spacing={0}
        >
          <Stack
            id='gePageFilterDrawerLeftStack'
            data-selenium-id={
              'GEPage_Overview-anotherNewFilterDrawer-Overview-Left-Stack'
            }
            direction='column'
            spacing={0}
            sx={stack_left}
          >
            <Block_Top title='GE code'>
              <MulSelectInput
                id='GEPageOverviewanotherNewFilterDrawerOverviewGECodeInput'
                data-selenium-id={
                  'GEPage_Overview-anotherNewFilterDrawer-Overview-GECodeInput'
                }
                value={filterSelected.geCode}
                placeholder={'Search GE code'}
                defaultOption={key => key}
                getOptionLabel={option => option}
                options={filterOptions.geCode}
                loadOptionAction={(...props) =>
                  onLoad.bind(this, 'geCode')(...props)
                }
                onValueChange={value => {
                  handleSelectedChange.bind(this, 'geCode')(value);
                }}
                disableNewItem={true}
                showPopupIcon={false}
                showStartIcon={true}
              />
            </Block_Top>
            <Block title='Primary Email'>
              {/* <SelectBox
                id='siPageFilterDrawerSelectSICodeInput'
                data-selenium-id={'GEPage_Overview-anotherNewFilterDrawer-Overview-SICodeInput'}
                type={1}
                data={filterOptions.primaryEmail}
                labelName='Primary Email'
                placeholder='Search Email Address'
                value={filterSelected.primaryEmail}
                setSelected={handleSelectedChange.bind(this, 'primaryEmail')}
                loadMore={onLoad.bind(this, 'primaryEmail')}
              /> */}
              <MulSelectInput
                id='GEPageOverviewanotherNewFilterDrawerOverviewPrimaryEmailInput'
                data-selenium-id={
                  'GEPage_Overview-anotherNewFilterDrawer-Overview-Primary-EmailInput'
                }
                value={filterSelected.primaryEmail}
                placeholder={'Search Email Address'}
                defaultOption={key => key}
                getOptionLabel={option => option}
                options={filterOptions.primaryEmail}
                loadOptionAction={(...props) =>
                  onLoad.bind(this, 'primaryEmail')(...props)
                }
                onValueChange={value => {
                  handleSelectedChange.bind(this, 'primaryEmail')(value);
                }}
                disableNewItem={true}
                showPopupIcon={false}
                showStartIcon={true}
              />
            </Block>

            <Block title='Full Name'>
              {/* <SelectBox
                id='siPageFilterDrawerSelectLeadGENameInput'
                data-selenium-id={'GEPage_Overview-anotherNewFilterDrawer-Overview-LeadGENameInput'}
                type={1}
                data={filterOptions.fullName}
                labelName='Full Name'
                placeholder='Search GE Full Name'
                value={filterSelected.fullName}
                setSelected={handleSelectedChange.bind(this, 'fullName')}
                loadMore={onLoad.bind(this, 'fullName')}
                getValue={item => item.geFirstName + ' ' + item.geLastName}
              /> */}
              <MulSelectInput
                id='GEPageOverviewanotherNewFilterDrawerOverviewLeadGENameInput'
                data-selenium-id={
                  'GEPage_Overview-anotherNewFilterDrawer-Overview-LeadGENameInput'
                }
                value={filterSelected.fullName}
                placeholder={'Search GE Full Name'}
                defaultOption={key => key}
                getOptionLabel={item =>
                  item.geFirstName + ' ' + item.geLastName
                }
                options={filterOptions.fullName}
                loadOptionAction={(...props) =>
                  onLoad.bind(this, 'fullName')(...props)
                }
                onValueChange={value => {
                  handleSelectedChange.bind(this, 'fullName')(value);
                }}
                disableNewItem={true}
                showPopupIcon={false}
                showStartIcon={true}
              />
            </Block>

            <Block title='Gender'>
              {/* <SelectBox
                id='siPageFilterDrawerSelectHandlingCEInput'
                data-selenium-id={'GEPage_Overview-anotherNewFilterDrawer-Overview-HandlingCEInput'}
                type={2}
                data={filterOptions.gender}
                labelName='Gender'
                placeholder='Choose Gender'
                value={filterSelected.gender}
                setSelected={handleSelectedChange.bind(this, 'gender')}
                loadMore={onLoad.bind(this, 'gender')}
              /> */}
              <MulSelectInput
                id='GEPageOverviewanotherNewFilterDrawerOverviewHandlingCEInput'
                data-selenium-id={
                  'GEPage_Overview-anotherNewFilterDrawer-Overview-HandlingCEInput'
                }
                value={filterSelected.gender}
                placeholder={'Choose Gender'}
                defaultOption={key => key}
                getOptionLabel={option => option}
                options={filterOptions.gender}
                loadOptionAction={(...props) =>
                  onLoad.bind(this, 'gender')(...props)
                }
                onValueChange={value => {
                  handleSelectedChange.bind(this, 'gender')(value);
                }}
                disableNewItem={true}
                showPopupIcon={true}
                showStartIcon={false}
              />
            </Block>

            <Block title='Institution'>
              {/* <SelectBox
                id='siPageFilterDrawerSelectGroupInput'
                data-selenium-id={'GEPage_Overview-anotherNewFilterDrawer-Overview-GroupInput'}
                type={1}
                data={filterOptions.institute}
                labelName='Institution'
                placeholder='Search Institution'
                value={filterSelected.institute}
                setSelected={handleSelectedChange.bind(this, 'institute')}
                loadMore={onLoad.bind(this, 'institute')}
              /> */}
              <MulSelectInput
                id='GEPageOverviewanotherNewFilterDrawerOverviewGroupInput'
                data-selenium-id={
                  'GEPage_Overview-anotherNewFilterDrawer-Overview-GroupInput'
                }
                value={filterSelected.institute}
                placeholder={'Search Institution'}
                defaultOption={key => key}
                getOptionLabel={option => option}
                options={filterOptions.institute}
                loadOptionAction={(...props) =>
                  onLoad.bind(this, 'institute')(...props)
                }
                onValueChange={value => {
                  handleSelectedChange.bind(this, 'institute')(value);
                }}
                disableNewItem={true}
                showPopupIcon={false}
                showStartIcon={true}
              />
            </Block>

            <Block title='Country/Region'>
              {/* <SelectBox
                id='siPageFilterDrawerSelectJournalInput'
                data-selenium-id={'GEPage_Overview-anotherNewFilterDrawer-Overview-JournalInput'}
                type={1}
                data={filterOptions.country}
                labelName='Country/Region'
                placeholder='Search Country/Region'
                value={filterSelected.country}
                setSelected={handleSelectedChange.bind(this, 'country')}
                loadMore={onLoad.bind(this, 'country')}
              /> */}
              <MulSelectInput
                id='GEPageOverviewanotherNewFilterDrawerOverviewJournalInput'
                data-selenium-id={
                  'GEPage_Overview-anotherNewFilterDrawer-Overview-JournalInput'
                }
                value={filterSelected.country}
                placeholder={'Search Country/Region'}
                defaultOption={key => key}
                getOptionLabel={option => option}
                options={filterOptions.country}
                loadOptionAction={(...props) =>
                  onLoad.bind(this, 'country')(...props)
                }
                onValueChange={value => {
                  handleSelectedChange.bind(this, 'country')(value);
                }}
                disableNewItem={true}
                showPopupIcon={false}
                showStartIcon={true}
              />
            </Block>

            <Block title='Associate Journal'>
              {/* <SelectBox
                id='siPageFilterDrawerSelectRevenueModelInput'
                data-selenium-id={'GEPage_Overview-anotherNewFilterDrawer-Overview-RevenueModelInput'}
                type={1}
                data={filterOptions.associateJournal}
                labelName='Associate Journal'
                placeholder='Search Associate Journal'
                value={filterSelected.associateJournal}
                setSelected={handleSelectedChange.bind(
                  this,
                  'associateJournal'
                )}
                loadMore={onLoad.bind(this, 'associateJournal')}
              /> */}
              <MulSelectInput
                id='GEPageOverviewanotherNewFilterDrawerOverviewRevenueModelInput'
                data-selenium-id={
                  'GEPage_Overview-anotherNewFilterDrawer-Overview-RevenueModelInput'
                }
                value={filterSelected.associateJournal}
                placeholder={'Search Country/Region'}
                defaultOption={key => key}
                getOptionLabel={option => option}
                options={filterOptions.associateJournal}
                loadOptionAction={(...props) =>
                  onLoad.bind(this, 'associateJournal')(...props)
                }
                onValueChange={value => {
                  handleSelectedChange.bind(this, 'associateJournal')(value);
                }}
                disableNewItem={true}
                showPopupIcon={false}
                showStartIcon={true}
              />
            </Block>
          </Stack>

          <Stack
            id='siPageFilterDrawerRightStack'
            data-selenium-id={
              'GEPage_Overview-anotherNewFilterDrawer-Overview-Right-Stack'
            }
            direction='column'
            spacing={0}
            sx={stack_right}
          >
            <Block_Top title='PD Publisher'>
              <MulSelectInput
                id='GEPageOverviewanotherNewFilterDrawerOverviewPDPublisherInput'
                data-selenium-id={
                  'GEPage_Overview-anotherNewFilterDrawer-Overview-PDPublisherInput'
                }
                value={filterSelected.pdPublisher}
                placeholder={'Search PD Publisher'}
                defaultOption={key => key}
                getOptionLabel={option => option}
                options={filterOptions.pdPublisher}
                loadOptionAction={(...props) =>
                  onLoad.bind(this, 'pdPublisher')(...props)
                }
                onValueChange={value => {
                  handleSelectedChange.bind(this, 'pdPublisher')(value);
                }}
                disableNewItem={true}
                showPopupIcon={false}
                showStartIcon={true}
              />
            </Block_Top>
            <Block_Top title='PP Publisher'>
              <MulSelectInput
                id='GEPageOverviewanotherNewFilterDrawerOverviewPPPublisherInput'
                data-selenium-id={
                  'GEPage_Overview-anotherNewFilterDrawer-Overview-PPPublisherInput'
                }
                value={filterSelected.ppPublisher}
                placeholder={'Search PP Publisher'}
                defaultOption={key => key}
                getOptionLabel={option => option}
                options={filterOptions.ppPublisher}
                loadOptionAction={(...props) =>
                  onLoad.bind(this, 'ppPublisher')(...props)
                }
                onValueChange={value => {
                  handleSelectedChange.bind(this, 'ppPublisher')(value);
                }}
                disableNewItem={true}
                showPopupIcon={false}
                showStartIcon={true}
              />
            </Block_Top>

            <Block title='Subject Group'>
              <MulSelectInput
                id='GEPageOverviewanotherNewFilterDrawerOverviewSubjectGroupInput'
                data-selenium-id={
                  'GEPage_Overview-anotherNewFilterDrawer-Overview-SubjectGroupInput'
                }
                value={filterSelected.subjectGroup}
                placeholder={'Choose Subject Group'}
                defaultOption={key => key}
                getOptionLabel={option => option}
                options={filterOptions.subjectGroup}
                loadOptionAction={(...props) =>
                  onLoad.bind(this, 'subjectGroup')(...props)
                }
                onValueChange={value => {
                  handleSelectedChange.bind(this, 'subjectGroup')(value);
                }}
                disableNewItem={true}
                showPopupIcon={true}
              />
              {/*<SelectBox*/}
              {/*id='siPageFilterDrawerSelectSubjectGroupInput'*/}
              {/*data-selenium-id={`GEPage_Overview-anotherNewFilterDrawer-Overview-SubjectGroupInput`}*/}
              {/*type={2}*/}
              {/*data={filterOptions.subjectGroup}*/}
              {/*labelName='Subject Group'*/}
              {/*placeholder='Choose Subject Group'*/}
              {/*value={filterSelected.subjectGroup}*/}
              {/*setSelected={handleSelectedChange.bind(this, 'subjectGroup')}*/}
              {/*loadMore={onLoad.bind(this, 'subjectGroup')}*/}
              {/*/>*/}
            </Block>

            <Block title='Group/Title'>
              {/* <SelectBox
                id='siPageFilterDrawerSelectSubjectGroupInput'
                data-selenium-id={'GEPage_Overview-anotherNewFilterDrawer-Overview-SubjectGroupInput'}
                type={2}
                data={filterOptions.group}
                labelName='Group/Title'
                placeholder='Choose Group/Title'
                value={filterSelected.group}
                setSelected={handleSelectedChange.bind(this, 'group')}
                loadMore={onLoad.bind(this, 'group')}
                getValue={item => item.value}
                getId={item => item.key}
              /> */}
              <MulSelectInput
                id='GEPageOverviewanotherNewFilterDrawerOverviewSubjectGroupInput'
                data-selenium-id={
                  'GEPage_Overview-anotherNewFilterDrawer-Overview-SubjectGroupInput'
                }
                value={filterSelected.group}
                placeholder={'Choose Group/Title'}
                defaultOption={key => ({
                  id: 0,
                  value: key,
                })}
                getOptionLabel={option => option.value}
                options={filterOptions.group}
                loadOptionAction={(...props) =>
                  onLoad.bind(this, 'group')(...props)
                }
                onValueChange={value => {
                  handleSelectedChange.bind(this, 'group')(value);
                }}
                disableNewItem={true}
                showPopupIcon={true}
              />
            </Block>
            <Block title='Handling CE'>
              {/*<MulSelectInput*/}
              {/*id='siPageFilterDrawerSelectSubjectGroupInput'*/}
              {/*data-selenium-id={'GEPage_Overview-anotherNewFilterDrawer-Overview-SubjectGroupInput'}*/}
              {/*value={filterSelected.handlingCe}*/}
              {/*placeholder='   Search Handling CE'*/}
              {/*defaultOption={ key =>({*/}
              {/*id: 0, fullName: key*/}
              {/*})}*/}
              {/*getOptionLabel={item => {*/}
              {/*if (item.username === undefined) {*/}
              {/*return `${item.fullName}`;*/}
              {/*}*/}
              {/*return `${item.username} (${item.email})`;*/}
              {/*}}*/}
              {/*options={filterOptions.handlingCe}*/}
              {/*loadOptionAction={(...props) => onLoad.bind(this, 'handlingCe')(...props)}*/}
              {/*onValueChange={(value) => {*/}
              {/*handleSelectedChange.bind(this, 'handlingCe')(value);*/}
              {/*}}*/}
              {/*disableNewItem={true}*/}
              {/*showPopupIcon={true}*/}
              {/*renderInput={params => {*/}
              {/*const { isEmpty,placeholder ,...others } = params;*/}
              {/*return (*/}
              {/*<TextField*/}
              {/*{...params}*/}
              {/*placeholder={!isEmpty ? null : '  ' + placeholder}*/}
              {/*variant='outlined'*/}
              {/*InputProps={*/}
              {/*!isEmpty*/}
              {/*? { ...params.InputProps }*/}
              {/*: {*/}
              {/*...params.InputProps,*/}
              {/*startAdornment: (*/}
              {/*<SIDrawerAutoCompleteMagnifyingGlassIcon />*/}
              {/*),*/}
              {/*}*/}
              {/*}*/}
              {/*{...others}*/}
              {/*></TextField>*/}
              {/*);*/}
              {/*}}*/}
              {/*/>*/}
              {/* <SelectBox
                id='GEPageOverviewanotherNewFilterDrawerOverviewSubjectGroupInput'
                data-selenium-id={'GEPage_Overview-anotherNewFilterDrawer-Overview-SubjectGroupInput'}
                type={1}
                data={filterOptions.handlingCe}
                labelName='Handling CE'
                placeholder='Search Handling CE'
                value={filterSelected.handlingCe}
                setSelected={handleSelectedChange.bind(this, 'handlingCe')}
                loadMore={onLoad.bind(this, 'handlingCe')}
                getValue={item => {
                  if (item.username === undefined) {
                    return `${item.fullName}`;
                  }
                  return `${item.username} (${item.email})`;
                }}
                getId={item => {
                  return item.userId;
                }}
              /> */}
              <MulSelectInput
                id='GEPageOverviewanotherNewFilterDrawerOverviewSubjectGroupInput'
                data-selenium-id={
                  'GEPage_Overview-anotherNewFilterDrawer-Overview-SubjectGroupInput'
                }
                value={filterSelected.handlingCe}
                placeholder={'Search Handling CE'}
                defaultOption={key => ({
                  id: 0,
                  value: key,
                })}
                getOptionLabel={item => {
                  if (item.username === undefined) {
                    return `${item.fullName}`;
                  }
                  return `${item.username} (${item.email})`;
                }}
                options={filterOptions.handlingCe}
                loadOptionAction={(...props) =>
                  onLoad.bind(this, 'handlingCe')(...props)
                }
                onValueChange={value => {
                  handleSelectedChange.bind(this, 'handlingCe')(value);
                }}
                disableNewItem={true}
                showPopupIcon={false}
                showStartIcon={true}
              />
            </Block>
            <Block title='Flags'>
              <MulSelectInput
                id='gePageFilterDrawerSelectCoGENameInput'
                data-selenium-id={
                  'GEPage_Overview-anotherNewFilterDrawer-Overview-CoGENameInput'
                }
                value={filterSelected.flags}
                placeholder={'Choose Flags'}
                defaultOption={key => ({
                  id: 0,
                  value: key,
                })}
                getOptionLabel={option => option.value}
                options={filterOptions.flags}
                loadOptionAction={(...props) =>
                  onLoad.bind(this, 'flags')(...props)
                }
                onValueChange={value => {
                  handleSelectedChange.bind(this, 'flags')(value);
                }}
                disableNewItem={true}
                showPopupIcon={true}
              />
              {/*<SelectBox*/}
              {/*id='siPageFilterDrawerSelectCoGENameInput'*/}
              {/*data-selenium-id={`GEPage_Overview-anotherNewFilterDrawer-Overview-CoGENameInput`}*/}
              {/*type={2}*/}
              {/*data={filterOptions.flags}*/}
              {/*labelName='Flags'*/}
              {/*placeholder='Choose Flags'*/}
              {/*value={filterSelected.flags}*/}
              {/*setSelected={handleSelectedChange.bind(this, 'flags')}*/}
              {/*loadMore={onLoad.bind(this, 'flags')}*/}
              {/*getValue={item => item.value}*/}
              {/*getId={item => item.key}*/}
              {/*/>*/}
            </Block>
            <Block title='Create Date'>
              <DateRangePicker
                id='gePageFilterDrawerSelectCreatedDateTimeRangeInput'
                data-selenium-id='GEPage_Overview-anotherNewFilterDrawer-Overview-CreatedDate-TimeRangeInput'
                fromDate={filterSelected.createDateFrom}
                toDate={filterSelected.createDateTo}
                handleDateChange={(f, t) => {
                  handleSetCreateDateFrom(f);
                  handleSetCreateDateTo(t);
                }}
                popoverIndex={1400}
                innerBorder={'1px solid #DFE4E8'}
                border={'1px solid #FFFFFF'}
                backgroundColor='#FFFFFF'
                iconPaddingRight={0}
                minHeight={40}
                width='100%'
                needErrorCheck = {true}
                needErrorMessage = {true}
              />
            </Block>
          </Stack>
        </Stack>
      </Box>
      {/* <Box sx={drawer_bottom_button_list}>

      </Box> */}
      <UntitledAlertBox
        id='siPageFilterDrawerSaveFilterSuccessAlert'
        data-selenium-id={
          'GEPage_Overview-anotherNewFilterDrawer-Overview-Save-Filter-Success-Alert'
        }
        open={openSaveSuccess}
        severity='success'
        message='Successfully saved your filter.'
        autoHideDuration={3000}
        handleClose={() => {
          setOpenSaveSuccess(false);
        }}
      />
      <UntitledAlertBox
        id='siPageFilterDrawerLoadFilterSuccessAlert'
        data-selenium-id={
          'GEPage_Overview-anotherNewFilterDrawer-Overview-Load-Filter-Success-Alert'
        }
        open={openLoadedFilterOptions}
        severity='info'
        message='Your saved filter options have been loaded.'
        autoHideDuration={3000}
        handleClose={() => {
          setOpenLoadedFilterOptions(false);
        }}
      />
    </Box>
  );
}

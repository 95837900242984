import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import SIPLogTable from './SIPLogTable';
import {getEmailHistory, getGEEditingHistory, getSIPEditingHistory} from "@/actions/SIMT-SIP/SIP/SipEditHistory";

function TabPanel(props) {
  const { SIid, children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 1 }}>
          <Typography data-selenium-id='SIP_DetailPage_Log-Index-Box-Typography'>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function SIPLog(props) {
  const { SIPCode } = props;

  //SIHistory表
  const [SIPHistoryPage, setSIPHistoryPage] = useState(1);
  const [GEHistoryPage, setGEHistoryPage] = useState(1);
  const [EmailHistoryPage, setEmailHistoryPage] = useState(1);

  //...RowsPerPage 设置展示行数
  const [SIPHistoryRowsPerPage, setSIPHistoryRowsPerPage] = useState(20);
  const [GEHistoryRowsPerPage, setGEHistoryRowsPerPage] = useState(20);
  const [EmailHistoryRowsPerPage, setEmailHistoryRowsPerPage] = useState(20);

  const [SIPHistoryTableData, setSIPHistoryTableData] = useState([]);
  const [GEHistoryTableData, setGEHistoryTableData] = useState([]);
  const [EmailHistoryTableData, setEmailHistoryTableData] = useState([]);

  const [SIPHistoryConut, setSIPHistoryCount] = useState(0);
  const [GEHistoryCount, setGEHistoryCount] = useState(0);
  const [EmailHistoryCount, setEmailHistoryCount] = useState(0);

  const rowsPerPageOptions = [10, 20, 50];

  const dispatch = useDispatch();

  const selector = state => {
    return {
      sipEditingHistory: state.SIP.sipEditingHistory,
      geEditingHistory: state.SIP.geEditingHistory,
      emailHistory: state.SIP.emailHistory,
      sipType: state.SIP.sipInformation?.sipType,
    };
  };

  const { sipEditingHistory, geEditingHistory, emailHistory, sipType } =
    useSelector(selector);

  useEffect(() => {
    if (sipEditingHistory && sipEditingHistory.total !== undefined) {
      setSIPHistoryCount(sipEditingHistory.total);
      sipEditingHistory.list === null
        ? setSIPHistoryTableData([])
        : setSIPHistoryTableData(sipEditingHistory.list);
    }
  }, [sipEditingHistory]);

  useEffect(() => {
    if (geEditingHistory && geEditingHistory.total !== undefined) {
      setGEHistoryCount(geEditingHistory.total);
      geEditingHistory.list === null
        ? setGEHistoryTableData([])
        : setGEHistoryTableData(geEditingHistory.list);
    }
  }, [geEditingHistory]);

  useEffect(() => {
    if (emailHistory && emailHistory.total !== undefined) {
      setEmailHistoryCount(emailHistory.total);
      emailHistory.list === null
        ? setEmailHistoryTableData([])
        : setEmailHistoryTableData(emailHistory.list);
    }

  }, [emailHistory]);

  // EH Editing History
  const EHTabs = props => (
    <Tabs
      data-selenium-id='SIP_DetailPage_Log-Index-Tabs'
      {...props}
      TabIndicatorProps={{
        children: <span className='MuiTabs-indicatorSpan' />,
      }}
      sx={{
        '& .MuiTabs-indicator': {
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          '.MuiTabs-indicatorSpan': {
            backgroundColor: '#1B5BA0',
          },
        },
        '& .MuiTabs-indicatorSpan': {
          maxWidth: '102px',
          // props.label?.startsWith('SIP') ? '106px'
          //   : props.label?.startsWith('GE') ? '104px'
          //     : props.label?.startsWith('Email') ? '77px' : '99px',
          width: '100%',
          backgroundColor: '#113D95',
        },
      }}
    />
  );

  const EHTab = styled(Tab)({
    padding: '0px',
    alignItems: 'self-center',
    width: '136px',
    minHeight: '29px',
    // marginRight: '44px',

    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '29px',
    textTransform: 'none',
    color: '#596A7C',

    flex: 'none',
    order: 0,
    flexGrow: 0,
    '&.Mui-selected': {
      color: '#113D95',
    },
    '&:hover': {
      backgroundColor: '#CEDDF9',
      color: '#113D95',
    },
  });

  const handleSIPPageChange = (e, value) => {
    setSIPHistoryPage(value);
    dispatch(getSIPEditingHistory(SIPCode, value, SIPHistoryRowsPerPage));
  };

  const handleGEPageChange = (e, value) => {
    setGEHistoryPage(value);
    dispatch(getGEEditingHistory(SIPCode, value, GEHistoryRowsPerPage));
  };

  const handleEmailPageChange = (e, value) => {
    setEmailHistoryPage(value);
    dispatch(getEmailHistory(SIPCode, value, EmailHistoryRowsPerPage));
  };

  const handleSIPRowsPerPageChange = e => {
    setSIPHistoryPage(1);
    setSIPHistoryRowsPerPage(Number(e.target.value));
    dispatch(getSIPEditingHistory(SIPCode, 1, Number(e.target.value)));
  };

  const handleGERowsPerPageChange = e => {
    setGEHistoryPage(1);
    setGEHistoryRowsPerPage(Number(e.target.value));
    dispatch(getGEEditingHistory(SIPCode, 1, Number(e.target.value)));
  };

  const handleEmailRowsPerPageChange = e => {
    setEmailHistoryPage(1);
    setEmailHistoryRowsPerPage(Number(e.target.value));
    dispatch(getEmailHistory(SIPCode, 1, Number(e.target.value)));
  };

  const createRow = (
    id,
    label,
    minWidth,
    maxWidth,
    isClick = false,
    isOrder = false,
    align = 'left'
  ) => {
    return { id, label, minWidth, maxWidth, isClick, isOrder, align };
  };

  const [isFold, setIsFold] = useState(false);

  const [TabValue, setTabValue] = useState(0);

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const USIPSIPHistoryTableHeads = [
    createRow('editTime', 'EDITING TIME', '183px', '183px'),
    createRow('item', 'ITEM', '420px', '420px'),
    createRow('oldValue', 'OLD VALUE', '124px', '124px'),
    createRow('newValue', 'NEW VALUE', '124px', '124px'),
    createRow('editUserName', 'EDIT USER', '243px', '243px'),
  ];

  const SSIPSIPHistoryTableHeads = [
    createRow('editTime', 'EDITING TIME', '183px', '183px'),
    createRow('item', 'ITEM', '252px', '252px'),
    createRow('oldValue', 'OLD VALUE', '252px', '252px'),
    createRow('newValue', 'NEW VALUE', '252px', '252px'),
    createRow('editUserName', 'EDIT USER', '183px', '183px'),
  ];


  const USIPGEHistoryTableHeads = [
    createRow('editTime', 'EDITING TIME', '197px', '197px'),
    createRow('geName', 'GUEST EDITOR NAME', '220px', '220px'),
    createRow('item', 'ITEM', '120px', '120px'),
    createRow('oldValue', 'OLD VALUE', '294px', '294px'),
    createRow('newValue', 'NEW VALUE', '294px', '294px'),
    createRow('editUser', 'EDIT USER', '120px', '120px'),
  ];

  const SSIPGEHistoryTableHeads = [
    createRow('editTime', 'EDIT TIME', '183px', '183px', false),
    createRow('geName', 'GE NAME', '183px', '183px', false),
    createRow('item', 'ITEM', '113px', '113px', false),
    createRow('oldValue', 'OLD VALUE', '215px', '215px', false),
    createRow('newValue', 'NEW VALUE', '215px', '215px', false),
    createRow('editUser', 'EDIT USER', '183px', '183px', false),
  ];

  const USIPEmailHistoryTableHeads = [
    createRow('time', 'Sending Time', '183px', '183px'),
    createRow('emailSubject', 'Email Subject', '183px', '183px', true),
    createRow('from', 'From', '135px', '135px'),
    createRow('to', 'To', '135px', '135px'),
    createRow('copy', 'Cc', '135px', '135px'),
    createRow('bcc', 'Bcc', '135px', '135px'),
    createRow('status', 'Status', '186px', '18px'), 
  ];

  const SSIPEmailHistoryTableHeads = [
    createRow('time', 'Sending Time', '183px', '183px', false),
    createRow('emailSubject', 'Email Subject', '183px', '183px', true),
    createRow('from', 'From', '135px', '135px', false),
    createRow('to', 'To', '135px', '135px', false),
    createRow('copy', 'CC', '135px', '135px', false),
    createRow('bcc', 'BCC', '135px', '135px', false),
    createRow('status', 'Status', '186px', '186px', false),
  ];



  return (
    <Box data-selenium-id='SIP_DetailPage_Log-Index-Box'>
      <Box
        sx={{ mb: '10px', mt: '20px',cursor:'pointer' }}
        id='titleBox'
        data-selenium-id='SIP_DetailPage_Log-Index-TitleBox'
      >
        <Box
          data-selenium-id='SIP_DetailPage_Log-Index-TitleFoldButtonBox'
          class='titleFoldButton'
          onClick={() => {
            async function fetchData() {
              await dispatch(getSIPEditingHistory(SIPCode, 1, 20));
              await dispatch(getGEEditingHistory(SIPCode, 1, 20));
              await dispatch(getEmailHistory(SIPCode, 1, 20));
            }
            fetchData();
            setIsFold(!isFold);
          }}
        >
          {isFold ? (
            <MarketChevronUp data-selenium-id='SIP_DetailPage_Log-Index-MarketChevronUp' />
          ) : (
            <SIDetailArrowDown />
          )}
        </Box>
        <Box
          id='titleBoxLeft'
          data-selenium-id='SIP_DetailPage_Log-Index-TitleBoxLeft'
          sx={{cursor:'default'}}
        >
          SIP History
        </Box>
      </Box>
      {isFold ? (
        <Box
          sx={{ width: '100%' }}
          data-selenium-id='SIP_DetailPage_Log-Index-InnerBox'
        >
          <Box data-selenium-id='SIP_DetailPage_Log-Index-EHTabsBox'>
            <EHTabs
              value={TabValue}
              onChange={handleTabChange}
              aria-label='basic tabs example'
              style={{ 'min-height': '29px' }}
            >
              <EHTab
                data-selenium-id='SIP_DetailPage_Log-Index-EHTab1'
                label='SIP&nbsp;Editing&nbsp;History'
                {...a11yProps(0)}
                wrapped
              />
              <EHTab
                data-selenium-id='SIP_DetailPage_Log-Index-EHTab2'
                label='GE&nbsp;Editing&nbsp;History'
                {...a11yProps(1)}
                wrapped
              />
              <EHTab
                label='Email&nbsp;History'
                {...a11yProps(2)}
                wrapped
                data-selenium-id='SIP_DetailPage_Log-Index-EHTab3'
              />
            </EHTabs>
          </Box>
          <TabPanel
            value={TabValue}
            index={0}
            data-selenium-id='SIP_DetailPage_Log-Index-TabPanel1'
          >
            <SIPLogTable
              id='sipDetailPageSIPEditingHistoryTable'
              data-selenium-id='SIP_DetailPage_Log-Index-SIPEditingHistoryTable'
              TableHeads={sipType === 'Yes' ? SSIPSIPHistoryTableHeads : USIPSIPHistoryTableHeads}
              page={SIPHistoryPage}
              rowsPerPage={SIPHistoryRowsPerPage}
              TableData={SIPHistoryTableData}
              rowsPerPageOptions={rowsPerPageOptions}
              count={SIPHistoryConut}
              handlePageChange={handleSIPPageChange}
              handleRowsPerPageChange={handleSIPRowsPerPageChange}
              sx={{ boxShadow: 'none' }}
            ></SIPLogTable>
          </TabPanel>
          <TabPanel
            value={TabValue}
            index={1}
            data-selenium-id='SIP_DetailPage_Log-Index-TabPanel2'
          >
            <SIPLogTable
              id='siDetailPageGEEditingHistoryTable'
              data-selenium-id='SIP_DetailPage_Log-Index-GEEditingHistoryTable'
              TableHeads={sipType === 'Yes' ? SSIPGEHistoryTableHeads : USIPGEHistoryTableHeads}
              page={GEHistoryPage}
              rowsPerPage={GEHistoryRowsPerPage}
              TableData={GEHistoryTableData}
              rowsPerPageOptions={rowsPerPageOptions}
              count={GEHistoryCount}
              handlePageChange={handleGEPageChange}
              handleRowsPerPageChange={handleGERowsPerPageChange}
            ></SIPLogTable>
          </TabPanel>
          <TabPanel
            value={TabValue}
            index={2}
            data-selenium-id='SIP_DetailPage_Log-Index-TabPanel3'
          >
            <SIPLogTable
              id='siDetailPageEmailEditingHistoryTable'
              data-selenium-id='SIP_DetailPage_Log-Index-EmailEditingHistoryTable'
              TableHeads={sipType === 'Yes' ? SSIPEmailHistoryTableHeads : USIPEmailHistoryTableHeads}
              page={EmailHistoryPage}
              rowsPerPage={EmailHistoryRowsPerPage}
              TableData={EmailHistoryTableData}
              rowsPerPageOptions={rowsPerPageOptions}
              count={EmailHistoryCount}
              handlePageChange={handleEmailPageChange}
              handleRowsPerPageChange={handleEmailRowsPerPageChange}
            ></SIPLogTable>
          </TabPanel>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}

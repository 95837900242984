import WileyLogo from '../../../../assets/LoginAsset/WileyLogo.svg';
import styled from '@emotion/styled';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {clear, setForgetPassEmailMessage} from '@/actions/SIMT-User/Login/LoginAction';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  IconButton,
  Typography,
  Dialog,
} from '@mui/material';

import React from "react";
import Footer from "../../../../components/Footer";
import {forgetPassEmail} from "@/actions/SIMT-User/Login";

const ForgotTitleTypography = styled(Typography)({
  color: 'var(--gray-500, #596A7C)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '48px',
  fontFamily: 'Open Sans',
  fontWeight: '800',
  lineHeight: '125%',
  letterSpacing: '0.48px',
});

const InfoTypography = styled(Typography)({
  color: 'var(--gray-300, #BCC5CF)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  lineHeight: '140%',
});


const SendButton = styled(Button)({
  borderRadius: '5px',
  background: 'var(--primary-500, #0052CC)',
  color: 'var(--shades-white, #FFF)',
  textAlign: 'center',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  lineHeight: '18px',
  width: '400px',
  height: '40px',
  padding: '8px 12px',
  gap: '6px',
  '&:hover': {
    background: 'var(--primary-300, #6D98EE)',
  },
  '&:active':{
    background: 'var(--primary-600, #113D95)',
  },
  '&:disabled': {
    background: 'var(--gray-200, #DFE4E8)',
  },
});

const NotAuthorizedPage = () => {
  const navigate = useNavigate();

  const [scaleToUse, setScaleToUse] = useState(1);
  const originWidth = 1129;
  const originHeight = 800;

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const newScaleX = screenWidth / originWidth;
      const newScaleY = screenHeight / originHeight;
      setScaleToUse(Math.min(newScaleX, newScaleY));
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        background: '#FFF',
        height: '100vh',
        width: '100vw',
      }}
      data-selenium-id='Login_NewLogin_ForgotPasswordPage'
    >
      <div
        style={{ width: '311px', height: '100%', background: '#0052CC' }}
        data-selenium-id='Login_NewLogin_ForgotPasswordPage-LeftPart'
      >
        <WileyLogo
          style={{
            marginLeft: '16px',
            marginTop: '16px',
          }}
        />
      </div>
      <div
        style={{
          width: 'calc(100% - 311px)',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
        data-selenium-id='Login_NewLogin_ForgotPasswordPage-RightPart'
      >
        <div
          data-selenium-id='Login_NewLogin_ForgotPasswordPage-RightPart-Content'
          style={{
            // marginTop: '132px',
            marginTop: `${scaleToUse*132 - 270 * (1-scaleToUse)}px`,
            transform: `scale(${scaleToUse}, ${scaleToUse})`,
            height: '540px',
          }}
        >
          <div data-selenium-id='Login_NewLogin_ForgotPasswordPage-ForgotTitle'>
            <ForgotTitleTypography>The page you are <br/>
              accessing is not exist</ForgotTitleTypography>
          </div>
          <div
            style={{ marginTop: '20px', height: '58px' }}
            data-selenium-id='Login_NewLogin_ForgotPasswordPage-ForgotInfoOrInvalidInfo'
          >
            <div
              style={{
                marginTop: '0px',
                width: '553px',
                display: 'block',
              }}
            >
              <InfoTypography>
                To continue accessing SIMT, please click the "Back to login" button located on this page.
              </InfoTypography>
            </div>
          </div>
          <div
            style={{ marginTop: '36px', paddingLeft: '80px', }}
            data-selenium-id='Login_NewLogin_ForgotPasswordPage-EmailLabel'
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="240" height="240" viewBox="0 0 240 240" fill="none">
              <g transform="translate(0 35)">
                <circle opacity="0.6" cx="120" cy="120" r="120" fill="url(#paint0_radial_3192_65647)" fill-opacity="0.95"/>
                <defs>
                  <radialGradient id="paint0_radial_3192_65647" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(120 120) rotate(90) scale(120)">
                    <stop offset="0.223958" stop-color="#BCC5CF"/>
                    <stop offset="1" stop-color="#F1F3F5" stop-opacity="0"/>
                  </radialGradient>
                </defs>
              </g>
              <g transform="translate(60 0)">
                <circle cx="60" cy="60" r="60" fill="url(#paint0_linear_3192_65683)"/>
                <defs>
                  <linearGradient id="paint0_linear_3192_65683" x1="96" y1="7" x2="47" y2="117.5" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0178281" stop-color="#DFE4E8"/>
                    <stop offset="0.211928" stop-color="#BCC5CF"/>
                    <stop offset="0.428488" stop-color="#98A7B6"/>
                    <stop offset="0.777227" stop-color="#596A7C"/>
                  </linearGradient>
                </defs>
              </g>
              <g transform="translate(93 33)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M52.669 1.75736C50.3259 -0.585786 46.5269 -0.585787 44.1838 1.75736L26.5062 19.435L10.2427 3.17149C7.89956 0.828347 4.10057 0.828347 1.75742 3.17149C-0.585723 5.51464 -0.585724 9.31363 1.75742 11.6568L18.0209 27.9202L1.75736 44.1838C-0.585786 46.5269 -0.585786 50.3259 1.75736 52.669C4.1005 55.0122 7.89949 55.0122 10.2426 52.669L26.5062 36.4055L44.1838 54.0832C46.527 56.4263 50.326 56.4263 52.6691 54.0832C55.0123 51.74 55.0123 47.941 52.6691 45.5979L34.9915 27.9202L52.669 10.2426C55.0122 7.8995 55.0122 4.10051 52.669 1.75736Z" fill="#FAFAFA"/>
              </g>
              <g transform="translate(50 160)">
                <ellipse cx="70" cy="20" rx="50" ry="20" fill="url(#paint0_linear_3192_65684)"/>
                <defs>
                  <linearGradient id="paint0_linear_3192_65684" x1="68" y1="-3" x2="53.7755" y2="45.8211" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0355433" stop-color="#DFE4E8"/>
                    <stop offset="0.25519" stop-color="#BCC5CF"/>
                    <stop offset="0.498785" stop-color="#98A7B6"/>
                    <stop offset="0.946859" stop-color="#596A7C"/>
                  </linearGradient>
                </defs>
              </g>
            </svg>
          </div>
          <div
            style={{ marginTop: '0px' }}
            data-selenium-id='Login_NewLogin_ForgotPasswordPage-SendButton'
          >
            <SendButton
              onClick={() => {
                navigate('/simt/auth/index');
              }}
              disableRipple
              disableTouchRipple
            >
              BACK TO LOGIN
            </SendButton>
          </div>
        </div>

        <Footer sx={{
          position: 'absolute',
          bottom: 0,
          background: '#FFF',
          color: '#596A7C !important',
          borderWidth: 0,
          '& a,span': {
            color: '#596A7C !important',
          },
          transform: `scale(${scaleToUse}, ${scaleToUse})`,
        }}/>
      </div>
    </div>
  );
};

export default NotAuthorizedPage;

import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import TextOverflow from '../../../../components/TextOverflow';
import { handleLinkWithoutProtocol } from '@/utils/commonUtils';
import { CustomTooltip } from '@/components/CustomTooltip';
import { useEffect, useState } from 'react';
import SIModalPro from '@/modules/Modal/SIModal';
import BoxWithShadow from '../../SIPCheckListPage/compontents/BoxWithShadow';
import StyledLink from '@/componentsphase2/StyledLink';
import SIPCheckListPage from '../../SIPCheckListPage';
import simpleDisplayError from '@/utils/simpleDisplayError';
import { useDispatch } from 'react-redux';
import {StaticSIPCheckListPage} from "@/pages/SIPPage/SIPCheckListPage/configable/SIPCheckListPage";

/**
 * 右侧
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function TimelineRight(props) {
  const {
    id,
    title,
    date,
    user,
    comments,
    origin,
    handleClose,
    openNewFlagAddition,
    history,
    step,
  } = props;

  const [checklistOpen, setChecklistOpen] = useState(false);

  let details = comments;

  if (typeof comments === 'string') {
    details = [
      {
        name: 'Comments',
        value: comments,
      },
    ];
  }
  //处理userName是否需要tooltip
  const [needToolTip, setNeedToolTip] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      const userNameEle = document.getElementById(`Timeline-user-${id}`);
      if (userNameEle?.clientWidth < userNameEle?.scrollWidth) {
        if (userNameEle?.clientWidth < userNameEle?.scrollWidth) {
          setNeedToolTip(true);
        } else {
          setNeedToolTip(false);
        }
      }
    }, 2000);
  }, []);

  const dispatch = useDispatch();
  return (
    <Stack
      direction='row'
      sx={{
        flex: '1',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          flex: '0 0',
          minWidth: '154px !important',
          maxWidth: '154px !important',
          width: '154px !important',
          overflow: 'hidden',
          color: '#596A7C',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontSize: '14px',
          lineHeight: '20px',
          marginRight: '27px',
        }}
      >
        {origin ||
        (details instanceof Array &&
          details.some(detail => detail.name === 'NewFlagAddition')) ||
        ((step === 'initialReview' || step === 'submit') &&
          id !== 'step-current') ? (
          // <>Origin: {origin}</>
          <>
            {origin ? (
              <Stack
                flexDirection='row'
                sx={{
                  // marginRight:'25px',
                  minWidth: '154px !important',
                  maxWidth: '154px !important',
                  width: '154px',
                  overflow: 'hidden',
                }}
              >
                <Box>Origin:</Box>
                <Box
                  sx={{
                    width: '106px',
                    marginLeft: '4px',
                    whiteSpace: 'break-spaces',
                  }}
                >
                  {origin === 'initialReview' ? 'Initial Review' : origin}
                </Box>
              </Stack>
            ) : (
              <></>
            )}
            <Box
              sx={{
                color: '#154ab6',
                fontWeight: 600,
                cursor: 'pointer',
                display:
                  details instanceof Array &&
                  details.some(
                    detail =>
                      detail.name === 'NewFlagAddition' &&
                      detail.value?.length > 0
                  )
                    ? 'unset'
                    : 'none',
              }}
              onClick={() => {
                handleClose && handleClose();
                openNewFlagAddition &&
                  openNewFlagAddition(
                    (function () {
                      const res = details.filter(
                        detail => detail.name === 'NewFlagAddition'
                      );
                      return res[0].value;
                    })()
                  );
              }}
            >
              New flag addition
            </Box>
            {/* <Box
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '20px',
                letterSpacing: '0.14px',
                color: '#154AB6',
                cursor: 'pointer',
              }}
              // onClick={setChecklistOpen(true)}
            >
              Review checklist
            </Box> */}
            <Box
              display={
                (details.some(detail => detail.name === 'Checklist')) &&
                id !== 'step-current'
                  ? 'block'
                  : 'none'
              }
            >
              <StyledLink
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '20px',
                  letterSpacing: '0.14px',
                  color: '#154AB6',
                }}
                type='button'
                onClick={() => {
                  if (details.some(detail => detail.name === 'Checklist'))
                    setChecklistOpen(true);
                  else
                    dispatch(
                      simpleDisplayError(
                        'Cannot find a checklist for this SIP, maybe caused by history data compatible problem.',
                        'info'
                      )
                    );
                }}
              >
                Review checklist
              </StyledLink>

              {/* <popChecklist></popChecklist> */}
              <BoxWithShadow>
                <SIModalPro
                  backgroundColor={'#FFFFFF'}
                  borderRadius={'5px'}
                  open={checklistOpen}
                  handleClose={() => {
                    setChecklistOpen(false);
                  }}
                  needTitle={false}
                  haveCloseButton={true}
                  boxSx={{
                    // height:'calc(100vh - 80px)',
                    height: 'auto',
                    // height: 'calc(100vh * 0.875)',
                    width: '100vw',
                    transform: 'translate(-50%,0)',
                    top: '13.2vh',
                    bottom: '0',
                    right: '0',
                    // overflow:'auto',
                    // '& > div> div> div':{
                    //   overflow:'auto',
                    // }
                  }}
                  containerSx={{
                    // overflow:'auto',
                    padding: '48px 48px 0 48px',
                  }}
                  closeButtonSx={{
                    left: 'auto',
                    right: '12px',
                    top: '12px',
                  }}
                >
                  <Box backgroundColor={'#FAFAFA'} overflow={'auto'}>
                      <StaticSIPCheckListPage outerData={history}/>
                    {/*<SIPCheckListPage*/}
                    {/*  noNeedQueryData={true}*/}
                    {/*  outerData={history}*/}
                    {/*  stage={*/}
                    {/*    step === 'initialReview'*/}
                    {/*      ? 'initial'*/}
                    {/*      : step === 'submit'*/}
                    {/*      ? 'submitted'*/}
                    {/*      : ''*/}
                    {/*  }*/}
                    {/*/>*/}
                  </Box>
                </SIModalPro>
              </BoxWithShadow>
            </Box>
          </>
        ) : (
          <></>
        )}
      </Box>

      <Stack
        width='100%'
        // rowGap='4px'
        data-selenium-id='SIPPage_Components-ProcessLog-TimelineRight-Stack'
      >
        <Typography
          id={`title-${id}`}
          data-selenium-id={`SIPPage_Components-ProcessLog-TimelineRight-title-${id}`}
          fontWeight={700}
          fontSize='16px'
          lineHeight='24px'
          color='#113D95'
          position='relative'
          sx={{
            marginBottom: '6px',
          }}
        >
          {title}
        </Typography>
        {details.map((item, index) => {
          let detailContentComponent;
          if (['NewFlagAddition', 'Checklist'].includes(item.name)) return;

          if (item.name === 'SI Code') {
            const regex = /SI-\d+-\d+/;
            detailContentComponent = (
              <Link
                id={`link-${id}-${index}`}
                data-selenium-id={`SIPPage_Components-ProcessLog-TimelineRight-link-${id}-${index}`}
                style={{
                  fontFamily: 'Open Sans',
                }}
                href={handleLinkWithoutProtocol(item.value)}
              >
                {item.value.match(regex)[0]}
              </Link>
            );
          } else if (index === details.length - 1) {
            detailContentComponent = (
              <TextOverflow
                lineNum={2}
                id={`commentContent-${id}-${index}`}
                data-selenium-id={`SIPPage_Components-ProcessLog-TimelineRight-CommentContent1-${id}-${index}`}
              >
                {item.value}
              </TextOverflow>
            );
          } else {
            detailContentComponent = (
              <div
                id={`commentContent-${id}-${index}`}
                data-selenium-id={`SIPPage_Components-ProcessLog-TimelineRight-CommentContent2-${id}-${index}`}
                style={{
                  fontFamily: 'Open Sans',
                }}
              >
                {typeof item.value === 'object' ? (Array.isArray(item.value)? item.value.toString():'object' ): item.value}
              </div>
            );
          }

          return (
            <Grid
              key={`comment-${id}-${index}`}
              id={`comment-${id}-${index}`}
              data-selenium-id={`SIPPage_Components-ProcessLog-TimelineRight-Comment-${id}-${index}`}
              container
              columnSpacing='10px'
              justifyContent='flex-start'
              alignItems='flex-start'
              fontWeight={400}
              fontSize='14px'
              // columns={34}
              lineHeight='20px'
              sx={{
                fontFamily: 'Open Sans',
                marginBottom: '5px',
              }}
            >
              <Grid
                id={`commentName-${id}-${index}`}
                data-selenium-id={`SIPPage_Components-ProcessLog-TimelineRight-CommentName-${id}-${index}`}
                item
                // md={10}
                // sm={34}
                minWidth='114px'
                maxWidth='114px'
                color='Gray600.main'
                sx={{
                  overflow: 'visable',
                  // textOverflow: 'ellipsis',
                  color: '#596A7C',
                  // whiteSpace: 'nowrap',
                }}
                title={item.name}
              >
                {item.name + ': '}
              </Grid>
              <Grid
                id={`commentContent-${id}-${index}`}
                data-selenium-id={`SIPPage_Components-ProcessLog-TimelineRight-Grid-CommentContent-${id}-${index}`}
                item
                // md={24}
                // sm={34}
                minWidth='385px'
                maxWidth='385px'
                color='Gray600.main'
              >
                {detailContentComponent}
              </Grid>
            </Grid>
          );
        })}
      </Stack>
      <Stack
        sx={{
          position: 'absolute',
          top: 0,
          left: 495,
          width: '0',
          overflow: 'visable',
          color: '#113D95',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 'inherit',
          flexDirection: 'row',
          justifyContent: 'start',
        }}
      >
        <div
          style={{
            display: 'inline-block',
            marginRight: '10px',
            lineHeight: '20px',
          }}
        >
          {date}
        </div>
        {needToolTip ? (
          <CustomTooltip
            title={user}
            placement='top'
            sx={{ translate: '0 12px' }}
          >
            <Box
              sx={{
                display: 'inline-block',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                minWidth: '122px',
                maxWidth: '122px',
                lineHeight: '20px',
              }}
              id={`Timeline-user-${id}`}
            >
              {user}
            </Box>
          </CustomTooltip>
        ) : (
          <Box
            sx={{
              display: 'inline-block',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              minWidth: '122px',
              maxWidth: '122px',
              lineHeight: '20px',
            }}
            id={`Timeline-user-${id}`}
          >
            {user}
          </Box>
        )}
      </Stack>
    </Stack>
  );
}

/**
 *
 * @type {{date: Requireable<string>, comments: Validator<NonNullable<NonNullable<InferType<Requireable<(InferPropsInner<Pick<{name: Requireable<string>, value: Requireable<string>}, never>> & Partial<InferPropsInner<Pick<{name: Requireable<string>, value: Requireable<string>}, "name" | "value">>>)[]>|Requireable<string>>>>>, id: Validator<NonNullable<string>>, title: Requireable<string>, user: Requireable<string>}}
 */
TimelineRight.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  date: PropTypes.string,
  user: PropTypes.string,
  comments: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    PropTypes.string,
  ]).isRequired,
};

export default TimelineRight;

// @ts-check
import React from 'react';
import { Box } from '@mui/material';
// @ts-ignore
import BlueBackground from '@/assets/SIPPage/ThankYouPage/Background/blue-background.svg';
// @ts-ignore
import GreenBackground from '@/assets/SIPPage/ThankYouPage/Background/green-background.svg';
// @ts-ignore
import LightBackground from '@/assets/SIPPage/ThankYouPage/Background/light-background.svg';

/**
 * @type {import("./SubmitCompletedPage").SubmitCompletedPageThemeToStyle}
 */
export const buttonStyle = {
  blue: { color: '#154AB6' },
  green: { color: '#115C36' },
  light: {
    color: '#fff',
    backgroundColor: '#1e48b4',
    '&:hover': {
      color: '#1e48b4',
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
  },
};

/**
 * @type {import("./SubmitCompletedPage").SubmitCompletedPageThemeToStyle}
 */
export const textStyle = {
  blue: { color: '#fff' },
  green: { color: '#fff' },
  light: {
    color: '#154AB6',
  },
};

/**
 *
 * @param {import("./SubmitCompletedPage").SubmitCompletedPageProps} props
 * @returns
 */
const SubmitCompletedPage = props => {
  const { theme = 'blue', sx, children, ...others } = props;
  return (
    <Box
      data-selenium-id='SIP_SubmitCompletePage-Box'
      marginTop={'56px'}
      height={'calc(100vh - 56px - 71.2px)'}
      sx={{
        position: 'relative',
        ...sx,
      }}
      {...others}
    >
      <Box
        data-selenium-id='SIP_SubmitCompletePage-BackGroundBox'
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}
      >
        {theme === 'blue' && <BlueBackground />}
        {theme === 'green' && <GreenBackground />}
        {theme === 'light' && <LightBackground />}
      </Box>
      <Box
        sx={{ height: '100%', overflow: 'overlay' }}
        data-selenium-id='SIP_SubmitCompletePage-ChildrenBox'
      >
        <Box
          sx={{
            position: 'relative',
            zIndex: 0,
            height: '100%',
            minHeight: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default SubmitCompletedPage;

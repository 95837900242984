/* eslint-disable indent */
import {
  // eslint-disable-next-line camelcase
  axios_instance_fileDownload,
  // eslint-disable-next-line camelcase
  axios_instance_l,
} from '@/utils/axios_instance';
// import moment from 'moment';
import { downloadFile } from '@/utils/commonUtils';
// import { Dispatch } from 'redux';
// import PropTypes from 'prop-types';
import { type AxiosResponse } from 'axios';
import { type PropsTypeArray } from '@/pages/SIPPage/AIScreeningReportPage/components/JournalScopeCheckOrRecommendationsMainFrame';
export /**
 * @param {string} sipCode - 需要导出的 SIP Code
 * @returns
 */
  const exportAIScreeningReport = (
    sipCode = '',
  resolve = () => {},
  reject = (e?: unknown) => e
) => {
  // const filename = `SIPList_${moment()
  //   .utc()
  //   .format('MMDDYYYY_HHmmss')}.docx`;
  const filename = `${sipCode} Integrity Screening Report.docx`;
  return async (/*dispatch: Dispatch*/) => {
    try {
      const authorization = localStorage.getItem('authorization');
      if (!authorization || !sipCode) {
        reject();
        return;
      }
      // eslint-disable-next-line camelcase
      const res = await axios_instance_fileDownload.get(
        `/api/v1/sip-service/special-issue-proposals/${sipCode}/ai-screening/report`,
        {
          // params: { sipCode },
          responseType: 'blob',
          headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded',
            authorization,
          },
        }
      );
      downloadFile(res.data, filename);
      resolve();
    } catch (err) {
      reject(err);
    }
  };
};
// exportAIScreeningReport.propTypes = {
//   sipCode: PropTypes.string,
//   resolve: PropTypes.func,
//   reject: PropTypes.func,
// };
// exportAIScreeningReport.defaultProps = {
//   sipCode: '',
//   resolve: () => { },
//   reject: (e: unknown) => e,
// };
export enum JournalScopeCheckOrRecommendationsTypes {
  // eslint-disable-next-line no-unused-vars
  JournalScopeCheck,
  // eslint-disable-next-line no-unused-vars
  JournalRecommendations,
}
const case_insensitive = (str1: string | undefined, str2: string) => str1 && str1?.toLowerCase()?.trim() === str2?.toLowerCase()?.trim();
export const getJournalScopeCheckOrRecommendations = (
  type: JournalScopeCheckOrRecommendationsTypes,
  sipCode = '',
  // eslint-disable-next-line no-unused-vars
  resolve: (value: PropsTypeArray) => void = e => e,
  // eslint-disable-next-line no-unused-vars
  reject: (reason?: unknown) => void = e => e
) => {
  // return async (dispatch: Dispatch) => {
  try {
    const authorization = localStorage.getItem('authorization');
    if (!authorization || !sipCode) {
      reject();
      return;
    }
    // eslint-disable-next-line camelcase
    const commonAxiosGet = <T> (url = '') => axios_instance_l
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          authorization,
        },
      })
      .then(
        (
          res: AxiosResponse<
            {
              readonly code?: number;
              readonly status?: number;
              readonly message?: string;
              readonly data: T;
            }
          >
        ) => {
          if (res?.status === 200) {
            const data = res?.data;
            if (
              data?.code === 200 ||
              data?.status === 200 ||
              data?.message === 'OK'
            ) {
              return data?.data;
            }
          }
          throw new Error();
        }
    );
    type ReadonlyArrayString = ReadonlyArray<string>;
    switch (type) {
      case JournalScopeCheckOrRecommendationsTypes.JournalScopeCheck:
        Promise.all(
          [commonAxiosGet<PropsTypeArray>(`/api/v1/sip-service/special-issue-proposals/${sipCode}/ai-screening/matched-journals?getContent=JournalScopeCheck`)]
        ).then(([journalScopeCheck]) => {
          // ((obj: ReadonlyArray<unknown>): obj is ReadonlyArrayString => true)(journalNameArr) && ((obj: ReadonlyArray<unknown>): obj is PropsTypeArray => true)(journalScopeCheck) &&
          resolve(
            // for each journalScopeCheck, check if the score is null, if is null, then only return journalName, else return jouranlScopeCheck.
            journalScopeCheck.map((journalScopeCheckItem:any) => {
              if(journalScopeCheckItem.matchScore === null){
                return {journalName:journalScopeCheckItem.journalName};
              }else{
                return journalScopeCheckItem;
              }
            })
          );
        }).catch(console.error);
        break;
      case JournalScopeCheckOrRecommendationsTypes.JournalRecommendations:
        commonAxiosGet<PropsTypeArray>(`/api/v1/sip-service/special-issue-proposals/${sipCode}/ai-screening/recommended-journals`).then((data: any) => resolve(data)).catch(console.error);
        break;
      default:
        break;
    }
  } catch (err) {
    console.error(err);
    reject(err);
  }
  // };
};
// getJournalScopeCheckOrRecommendations.propTypes={
//   url:PropTypes.string,
//   sipCode:PropTypes.string,
//   resolve:PropTypes.func,
//   reject:PropTypes.func,
// };

export const getGEBackgroundCheck = (sipCode: string) => {
  const authorization = localStorage.getItem('authorization') ?? '';
  // eslint-disable-next-line camelcase
  return axios_instance_l.get(`/api/v1/sip-service/special-issue-proposals/${sipCode}/ai-screening/ge-background`, {
    timeout: 100000,
    headers: {
      'Content-Type': 'application/json',
      authorization,
    },
  });
};

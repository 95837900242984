import { Box, Button, CircularProgress } from '@mui/material';
import MarketEditCancel from '@/assets/MarketEditCancel.svg';
import MarketEditSave from '@/assets/MarketEditSave.svg';
import MarketEdit from '@/assets/MarketEdit2.svg';
import MarketEditReset from '@/assets/MarketEditReset.svg';
import ViewControlClosed from '@/assets/ViewControlClosed.svg';
import ViewControlOpened from '@/assets/ViewControlOpened.svg';
import ViewControlHovered from '@/assets/ViewControlHovered.svg';
import RedAlertCircleIcon from '@/assets/RedAlertCircleIcon.svg';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomEmailBlock from './CustomEmailBlock';
import CustomText from './CustomText';
import CustomLabel from './CustomLabel';
import CustomSwitch from './CustomSwitch';
import CustomContent, { commonCss } from './CustomContent';
import CustomCKEditor from './CustomCKEditor';
import RichTextEditor from '../../../modules/RichTextEditor/RichTextEditor';
import {
  replaceVariablesSymbolsForFrontend,
  replaceVariablesSymbolsForBackend,
  common,
  emailVariables,
  replaceVariablesForFrontShow,
} from '../../../modules/RichTextEditor/EditorUtils';
import * as React from 'react';
import Chip from '@mui/material/Chip';
import { AlertBox, ButtonAlertBox } from '../../../components/Alert/AlertBox';
import styleModule from '../../../componentsphase2/SIDetail/SaveAndCancelButtonForGeneralInfoSection.module.scss';
import ResetAlterPopup from './ResetAlterPopup';
import MyLocalStorage from "../../../utils/MyLocalStorage";
import {
    editEmailManual,
    editUserEmailManual,
    removeEmailRedDot,
    removeUserEmailManual
} from "@/actions/SIMT-Email/Template";

function ShowAndEdit(props) {
  const { partTitle, contentDetail, block, userCustomize=false } = props;

  const [moreOrLess, setMoreOrLess] = useState(MyLocalStorage.getItem(`email-moreOrLess-${contentDetail.id}`,true)); // true为more， false为less
  const [isEdit, setIsEdit] = useState(false); //展示和编辑状态切换的关键字
  const [isCompare, setIsCompare] = useState(MyLocalStorage.getItem(`email-isCompare-${contentDetail.id}`,false));
  const [isCompareHover, setIsCompareHover] = useState(false);
  // const [isDirty, setIsDirty] = useState(false);
  const redDots = useSelector(state => state.Email.redDots);
  const isDirty = userCustomize && redDots.some(dot => dot.templateId == contentDetail.id);
  // const [isLoading, setIsLoading] = useState(false);
  // const [emailSendSuccess, setEmailSendSuccess] = useState(false);
  // const [dropUserEmailSendSuccess, setDropUserEmailSendSuccess] = useState(false);

  const [checkState, setCheckState] = useState(
    contentDetail?.requireAttachment == 'Yes'
  ); // 确定按钮状态
  const [tempCheckState, setTempCheckState] = useState(checkState); // 暂存按钮状态 在放弃修改时候使用
  const [emailContent, setEmailContent] = useState(contentDetail.content); // 修改email内容
  const [tempEmailContent, setTempEmailContent] = useState(emailContent); // 暂存email内容 在放弃修改时候使用

  const dispatch = useDispatch();

  // useEffect(()=>{
  //   if (contentDetail.customize){
  //     const userTime = new Date(contentDetail.updateTime);
  //     const sysTime = new Date(contentDetail.customize.updateTime);
  //     if (userTime.getTime() < sysTime.getTime()){
  //       setIsDirty(true);
  //     } else {
  //       setIsDirty(false);
  //     }
  //   }else {
  //     setIsDirty(false);
  //   }
  // },[contentDetail]);

  useEffect(()=>{
    if (isCompare && isDirty){
      MyLocalStorage.setItem(`email-isCompare-${contentDetail.id}`,isCompare);
      MyLocalStorage.setItem(`email-moreOrLess-${contentDetail.id}`,moreOrLess);
      dispatch(removeEmailRedDot(contentDetail.id));
      setTimeout(()=>{
        MyLocalStorage.removeItem(`email-isCompare-${contentDetail.id}`);
        MyLocalStorage.removeItem(`email-moreOrLess-${contentDetail.id}`);
      }, 5000);
    }
  }, [isCompare]);

  useEffect(()=> {
    if (isDirty && !moreOrLess && !contentDetail.customize){
      MyLocalStorage.setItem(`email-moreOrLess-${contentDetail.id}`,moreOrLess);
      dispatch(removeEmailRedDot(contentDetail.id));
      setTimeout(()=>{
        MyLocalStorage.removeItem(`email-moreOrLess-${contentDetail.id}`);
      }, 5000);
    }
  },[moreOrLess]);

  // 小标题
  const CustomPartTitle = props => {
    const { children } = props;
    return (
      <Box
        data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
          .split(' ')
          .join('')}-CustomPartTitle-Box`}
        sx={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: ' 24px',
          display: 'flex',
          alignItems: 'center',
          color: '#262E35',
          marginRight: '12px',
        }}
      >
        {children}
      </Box>
    );
  };

  // 文本替换，将${} 变为 []
  const textReplace = props => {
    let testDiv = document.createElement('div');
    testDiv.innerHTML = props;
    let value = testDiv.innerText;
    if (value) {
      return value.replace(/\${/g, '[').replace(/}/g, ']').replace(/_/g, ' ');
    } else {
      return value;
    }
  };

  // 文本替换，将${} 去掉
  const discardBracket = props => {
    let value = props;
    if (value) {
      return value.replace(/\${/g, '').replace(/}/g, '').replace(/_/g, ' ');
    } else {
      return value;
    }
  };
  const fromsArray = [
    '[Handling CE]',
    '[Handling CE]',
    '[Handling CE]',
    '[Handling CE]',
    '[Handling CE]',
    '[Handling CE]',
    ,
    ,
    ,
    ,
    ,
    '[Handling CE]',
    '[Handling CE]',
  ];
  const tosArray = [
    'PRPL, EO Or only EEO',
    'PRPL, EO Or only EEO',
    ' [GE 1]. [GE2], ...',
    '[EiC], [GE1]. [GE2], ...',
    '[EiC], [GE1], [GE2], …',
    '[GE1], [GE2], …',
  ];
  const copysArray = [
    'PD Publisher',
    'PD Publisher',
    '[PD Publisher], [PRPL], [EO]',
    '[PD Publisher], [PRPL], [EO], [PM], [PE]',
    '[PD Publisher], [PRPL], [EO], [PM], [PE], [EiC]',
    'PD Publisher',
  ];

  const HardCodeFromShowPart = props => {
    const { from } = props;
    return (
      <CustomText
        data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
          .split(' ')
          .join('')}-HardCodeFromShowPart-Box`}
      >
        {from}
      </CustomText>
    );
  };
  const HardCodeFromEditPart = props => {
    const { from } = props;
    return (
      <CustomLabel
        data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
          .split(' ')
          .join('')}-HardCodeFromEditPart-Box`}
      >
        {from}
      </CustomLabel>
    );
  };
  const HardCodeToShowPart = props => {
    const { block } = props;
    if (block === 1 || block === 2) {
      return (
        <CustomText
          data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
            .split(' ')
            .join('')}-HardCodeToShowPart-Box`}
        >
          [PRPL], [EO] Or only [EEO]
        </CustomText>
      );
    } else if (block === 3 || block === 5 || block === 6) {
      return (
        <CustomText
          data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
            .split(' ')
            .join('')}-HardCodeToShowPart-Box`}
        >
          [GE1], [GE2], ...
        </CustomText>
      );
    }
    else if (block===12){
      return (
        <CustomText
          data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
            .split(' ')
            .join('')}-HardCodeToShowPart-Box`}
        >
          [EiCs], [PD Publisher]
        </CustomText>
      );
    }
    else if (block === 13) {
      return (
        <CustomText
          data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
            .split(' ')
            .join('')}-HardCodeToShowPart-Box`}
        >
          {'Devall, Alex<adevall@wiley.com>'}
        </CustomText>
      );
    }
    else {
      return (
        <CustomText
          data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
            .split(' ')
            .join('')}-HardCodeToShowPart-Box`}
        >
          [GE1], [GE2], ...
        </CustomText>
      );
    }
  };
  const HardCodeToEditPart = props => {
    const { block } = props;
    if (block === 1 || block === 2) {
      return (
        <>
          <CustomLabel>PRPL</CustomLabel>, <CustomLabel>EO</CustomLabel>
          <CustomText>Or only</CustomText>
          <CustomLabel>EEO</CustomLabel>
        </>
      );
    } else if (block === 3 || block === 5 || block === 6) {
      return (
        <>
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeToEditPart1`}
          >
            GE1
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeToEditPart2`}
          >
            GE2
          </CustomLabel>
          , ...
        </>
      );
    }
    else if (block === 12) {
      return (
        <>
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeToEditPart1`}
          >
            EiCs
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeToEditPart2`}
          >
            PD Publisher
          </CustomLabel>
        </>
      );
    }
    else if (block === 13) {
      return (
        <>
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeToEditPart2`}
          >
           {'Devall, Alex<adevall@wiley.com>'}
          </CustomLabel>
        </>
      );
    }
    else {
      return (
        <>
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeToEditPart1`}
          >
            EiC
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeToEditPart2`}
          >
            GE1
          </CustomLabel>
          ,
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeToEditPart3`}
          >
            GE2
          </CustomLabel>
          , ...
        </>
      );
    }
  };
  const HardCodeCopyShowPart = props => {
    const { block } = props;
    if (block === 1 || block === 2 || block === 6) {
      return (
        <CustomText
          data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
            .split(' ')
            .join('')}-HardCodeCopyShowPart1`}
        >
          [PD Publisher]
        </CustomText>
      );
    } else if (block === 3) {
      return (
        <CustomText
          data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
            .split(' ')
            .join('')}-HardCodeCopyShowPart2`}
        >
          [PD Publisher], [PRPL], [EO]
        </CustomText>
      );
    } else if (block === 4) {
      return (
        <CustomText
          data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
            .split(' ')
            .join('')}-HardCodeCopyShowPart3`}
        >
          [PD Publisher], [PRPL], [EO], [PM], [PE]
        </CustomText>
      );
    } else if (block === 5) {
      return (
        <CustomText
          data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
            .split(' ')
            .join('')}-HardCodeCopyShowPart4`}
        >
          [PD Publisher], [PRPL], [EO], [PM], [PE], [EiC]
        </CustomText>
      );
    }else if(block === 12){
      return (
        <CustomText
          data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
            .split(' ')
            .join('')}-HardCodeCopyShowPart5`}
        >
          [PRPL]
        </CustomText>
      );
    }
  };
  const HardCodeCopyEditPart = props => {
    const { block } = props;
    if (block === 1 || block === 2 || block === 6) {
      return (
        <>
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart1`}
          >
            PD Publisher
          </CustomLabel>
        </>
      );
    } else if (block === 3) {
      return (
        <>
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart1`}
          >
            PD Publisher
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart2`}
          >
            PRPL
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart3`}
          >
            EO
          </CustomLabel>
        </>
      );
    } else if (block === 4) {
      return (
        <>
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart1`}
          >
            PD Publisher
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart2`}
          >
            PRPL
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart3`}
          >
            EO
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart5`}
          >
            PM
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart4`}
          >
            PE
          </CustomLabel>
        </>
      );
    } else if (block === 5) {
      return (
        <>
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart1`}
          >
            PD Publisher
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart2`}
          >
            PRPL
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart3`}
          >
            EO
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart5`}
          >
            PM
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart4`}
          >
            PE
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart5`}
          >
            EiC
          </CustomLabel>
        </>
      );
    } else if (block === 12) {
      return (
        <>
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart2`}
          >
            PRPL
          </CustomLabel>
        </>
      );
    } else if (block === 13) {
      return <></>;
    } else {
      return (
        <>
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart1`}
          >
            PD Publisher
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart2`}
          >
            PRPL
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart3`}
          >
            EO
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart4`}
          >
            PM
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart5`}
          >
            PE
          </CustomLabel>
          ,{' '}
          <CustomLabel
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-HardCodeCopyEditPart6`}
          >
            EiC
          </CustomLabel>
        </>
      );
    }
  };
  // 原始subject为了在show和edit链接绑定 temp是用来cancel时候取消绑定 还原数据
  const [origSubjectDetail, setOrigSubjectDetail] = useState(
    contentDetail.subject
  );
  const [tempSubjectDetail, setTempSubjectDetail] = useState(origSubjectDetail); // 保存edit编辑时原始数据 如果放弃修改使用这个

  // 将后端传来的to数组 进行分割
  const listOfTo = () => {
    if (contentDetail.to != null) {
      return discardBracket(contentDetail.to).split(',');
    } else {
      return null;
    }
  };

  // 为了符合ToPart 组件的三种状态编写的组件 避免三元表达式的嵌套
  const SubToPart = props => {
    const { item, index, selfArray } = props;
    if (block === 4) {
      if (index === 0) {
        return <CustomLabel>{item}</CustomLabel>;
      }
      return (
        <>
          <> </>
          <CustomLabel>{item}</CustomLabel>
        </>
      );
    } else if (index != selfArray.length - 1) {
      if (index === 0) {
        return <CustomLabel>{item}</CustomLabel>;
      }
      return (
        <>
          <CustomText>,</CustomText>
          <CustomLabel>{item}</CustomLabel>
        </>
      );
    }
    return (
      <>
        <CustomText> or only </CustomText>
        <CustomLabel>{item}</CustomLabel>
      </>
    );
  };
  // 因为To字段中会有多个字段显示，编写动态显示的新组件
  const ToPart = () => {
    let spiltedList = listOfTo();
    if (spiltedList != null) {
      return (
        // 如果是数组有多个元素 在后续的元素块前面加上逗号
        spiltedList.map((item, index, selfArray) => (
          <SubToPart
            key={index}
            item={item}
            index={index}
            selfArray={selfArray}
          />
        ))
      );
    } else {
      return <></>;
    }
  };

  // 拆分后台传来的Copy/From字段
  const listOfString = props => {
    if (props != null) {
      return discardBracket(props).split(',');
    } else {
      return null;
    }
  };
  const SubPart = props => {
    const { item, index } = props;
    if (index === 0) {
      return <CustomLabel>{item}</CustomLabel>;
    }
    return (
      <>
        <> </>
        <CustomLabel>{item}</CustomLabel>
      </>
    );
  };
  // 因为Copy/From字段中会有多个字段显示，编写动态显示的新组件
  const CopyorFromPart = props => {
    const { value } = props;
    let spiltedList = listOfString(value);
    if (spiltedList != null) {
      return (
        // 如果是数组有多个元素 在后续的元素块前面加上逗号
        spiltedList.map((item, index) => (
          <SubPart key={index} item={item} index={index} />
        ))
      );
    } else {
      return <></>;
    }
  };

  // 编辑状态时，点击取消按钮的操作
  const handleCancelClick = () => {
    setOrigSubjectDetail(
      replaceVariablesSymbolsForBackend(tempSubjectDetail, common)
    );
    setEmailContent(
      replaceVariablesSymbolsForBackend(tempEmailContent, common)
    );
    setCheckState(tempCheckState);
    setIsEdit(false);
  };

  const selector = state => {
    return {
      emailDetail: state.Email.emailTemplatesList,
    };
  };
  const { emailDetail } = useSelector(selector);
  // 编辑状态时，点击保存按钮的操作
  const handleSaveClick = () => {
    // 每次点击确定后 要更新暂存的内容
    setTempSubjectDetail(origSubjectDetail);
    setTempEmailContent(emailContent);
    setTempCheckState(checkState);
    // 点击确定后 要把当前更新的数据传到后台
    contentDetail.requireAttachment = checkState ? 'Yes' : 'No';
    contentDetail.content = emailContent;
    contentDetail.subject = origSubjectDetail;
    // 在这里获取所有的数据源 然后更改对应block的数据 再整体传递
    // for of可以修改原数组
    for (const iterator of emailDetail.manuallySentEmails) {
      if (iterator.block === block) {
        iterator.requireAttachment = contentDetail.requireAttachment;
        iterator.content = replaceVariablesSymbolsForBackend(
          contentDetail.content,
          common
        );
        iterator.subject = replaceVariablesSymbolsForBackend(
          contentDetail.subject,
          common
        );
        if (userCustomize){
          const sendFlag = dispatch(editUserEmailManual(iterator));
          // if (sendFlag){
          //   setEmailSendSuccess(true);
          // }
        } else {
          dispatch(editEmailManual(iterator));
        }
      }
    }
    // console.log(emailDetail.manuallySentEmails)
    setOrigSubjectDetail(
      replaceVariablesSymbolsForBackend(contentDetail.subject, common)
    );
    setEmailContent(
      replaceVariablesSymbolsForBackend(contentDetail.content, common)
    );
    setIsEdit(false);
  };

  // 设置按钮状态 并修改后台传来的按钮值
  const handleClickButton = () => {
    if (contentDetail.requireAttachment == 'No') {
      contentDetail.requireAttachment = 'Yes';
      setCheckState(true);
    } else {
      contentDetail.requireAttachment = 'No';
      setCheckState(false);
    }
  };

  const handleReset = () => {
    // setButtonAlertOpen(true);
    setButtonDialogOpen(true);
  };
  // if (partTitle==='SI Set Up in Submission System'){
  //   console.log("DSAD");
  //   console.log(userCustomize);
  //   console.log(contentDetail.customize);
  //   console.log("QWEWQ");
  //   console.log(userCustomize && contentDetail.customize);
  //   console.log(contentDetail);
  // };

  // 标题和编辑按钮
  const TopBlock = props => {
    const { title } = props;
    const containerStyle = (justifyContent, width) => {
      return {
        display: 'flex',
        justifyContent: justifyContent,
        // width: width,
      };
    };
    const IconButtonStyle = {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    };
    // if (isEdit) {
    //   return (
    //     <Box
    //       sx={{
    //         ...containerStyle('flex-start', '100%'),
    //         minWidth: '550px',
    //         height: '24px',
    //       }}
    //       data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
    //         .split(' ')
    //         .join('')}-TopBlock-Box`}
    //     >
    //       <CustomPartTitle>{title}</CustomPartTitle>
    //       <Box
    //         onClick={handleCancelClick}
    //         sx={{ marginRight: '16px', ...IconButtonStyle }}
    //         id='TemplateCancel'
    //         data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
    //           .split(' ')
    //           .join('')}-TopBlock-TemplateCancel`}
    //       >
    //         <MarketEditCancel></MarketEditCancel>
    //       </Box>
    //       <Box
    //         onClick={() => handleSaveClick()}
    //         sx={IconButtonStyle}
    //         id='TemplateSave'
    //         data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
    //           .split(' ')
    //           .join('')}-TopBlock-TemplateSave`}
    //       >
    //         {isLoading ? <CircularProgress size='16px' /> : <MarketEditSave />}
    //       </Box>
    //     </Box>
    //   );
    // } else
    {
      return (
        <Box sx={{
          ...containerStyle('flex-start', '100%'),
          height: '24px',
          position: 'relative',
        }}>
          <CustomPartTitle>{title}</CustomPartTitle>
          <Box
            onClick={() => setIsEdit(true)}
            sx={{
              marginRight: '16px',
              ...IconButtonStyle,
              '&:hover svg path': {
                fill: '#113D95',
              }
            }}
            id='MarketEdit'
            data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
              .split(' ')
              .join('')}-TopBlock-MarketEdit`}
          >
            <MarketEdit></MarketEdit>
          </Box>
          {
            (userCustomize && contentDetail.customize) ? ( <Box
              onClick={() => handleReset()}
              sx={{
                marginRight: '16px',
                ...IconButtonStyle,
                '&:hover svg path': {
                  stroke: '#113D95',
                }
              }}
              id='MarketEditReset'
              data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
                .split(' ')
                .join('')}-TopBlock-MarketEditReset`}
            >
              <MarketEditReset></MarketEditReset>
            </Box>) : null
          }
          { (userCustomize && contentDetail.customize) ?
            (<Box
              onClick={()=> {
                setIsCompare(!isCompare);
                setMoreOrLess(false);
              }}
              onMouseEnter={(e)=> {
                setIsCompareHover(true);
              }}
              onMouseLeave={()=> {
                setIsCompareHover(false);
              }}
              sx={{
                ...IconButtonStyle,
                position: 'absolute',
                right: 0,
                backgroundColor: isCompare? '#E8EDFB': '#FFFFFF',
                borderRadius: '3.75px',
                border: isCompare ? '0.75px solid #AEC6F6': (isCompareHover ? '1px solid #6D98EE' : '0.75px solid #0052CC'),
                padding: '3.75px',
                width: '24px',
                height: '24px',
              }}
              data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
                .split(' ')
                .join('')}-TopBlock-ViewControl`}
            >
              {isCompare ? (<ViewControlOpened/>): (isCompareHover? (<ViewControlHovered/>) : (<ViewControlClosed/>))}
            </Box>) :
            null}
        </Box>
      );
    }
  };

  const emailSubjectForShow = replaceVariablesForFrontShow(
    origSubjectDetail,
    emailVariables.full
  );

  const [buttonDialogOpen, setButtonDialogOpen] = useState(false);

  const [buttonAlertOpen, setButtonAlertOpen] = useState(false);

  const resetAlertButtons = [
    {
      text: 'Cancel',
      onClick: () => {setIsEdit(false);}
    },
    {
      text: 'Ok',
      onClick: () => {
        dispatch(removeUserEmailManual(contentDetail.id,'SI'));
      }
    }
  ];

  const compareStyles =  {
    margin: '16px 0px 8px 0px',
  };

  return (<Box
    style={{
      padding: '12px 20px 8px 20px',
      marginLeft: '-32px',
      marginTop: '16px',
      position: 'relative',
      borderRadius: '0px 4px 4px 0px',
      background: '#FFF',
      boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
    }}
    data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
      .split(' ')
      .join('')}-Box`}
  >
    <div style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '-8px',
      width: '8px',
      background: '#FFB152',
      borderRadius: '4px 0px 0px 4px',
    }}></div>

    {
      isDirty? (<RedAlertCircleIcon style={{
        position: 'absolute',
        top: 0,
        right: 0,
        marginRight: '-10px',
        marginTop: '-10px',
      }} />):null
    }

    <TopBlock title={partTitle}></TopBlock>
    <Box sx={{
      display: 'flex',
      flexFlow: 'row',
      marginTop: '0px',
      '& .ShowAndEdit-LeftBox': {
        width: isCompare ? 'calc(50% + 72px)': '100%',
        paddingTop: '0px',
        paddingBottom: '6px',
        paddingRight: '6px',
      },
      '& .ShowAndEdit-RightBox': {
        borderRadius: '4px',
        background: '#E8EDFB',
        width:  'calc(50% - 72px)',
        padding: '0px 6px 6px 6px',
        marginLeft: '16px',
      },
    }}>
      <Box className={'ShowAndEdit-LeftBox'}>

        {userCustomize ?
          (<CustomEmailBlock title={'Template Type:'} margin='16px 0px 8px 0px' sx={{
          lineHeight: '20px',
        }}>
          { contentDetail.customize ? ( <Chip label={'Customized'} sx={{
            borderRadius: '4px',
            border: '1px solid var(--secondary-300, #A2DDE4)',
            background: 'var(--secondary-100, #E7F6F8)',
            color: '#1A7A85',
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            height: '24px',
          }}/>) :
            ( <Chip label={'System Template'} sx={{
            borderRadius: '4px',
            border: '1px solid #6D98EE',
            background: '#CEDDF9',
            color: '#113D95',
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            height: '24px',
          }}/>)
          }
        </CustomEmailBlock>): null}

        {isEdit? (<>
          <CustomEmailBlock title='Subject:' margin={`${userCustomize?8:16}px 0px 8px 0px`}>
            <CustomCKEditor
              content={replaceVariablesSymbolsForFrontend(origSubjectDetail)}
              setContent={e => {
                setOrigSubjectDetail(e);
              }}
            ></CustomCKEditor>
          </CustomEmailBlock>

          <CustomEmailBlock title='From:' margin='8px 0px'>
            <HardCodeFromEditPart from={fromsArray[block - 1]} />
          </CustomEmailBlock>

          <CustomEmailBlock title='To:' margin='8px 0px'>
            <HardCodeToEditPart block={block}></HardCodeToEditPart>
          </CustomEmailBlock>

          <CustomEmailBlock title='Cc:' margin='8px 0px'>
            <HardCodeCopyEditPart block={block}></HardCodeCopyEditPart>
          </CustomEmailBlock>

          <CustomEmailBlock title='Bcc:' margin='8px 0px'>
            <HardCodeFromEditPart from={fromsArray[block - 1]} />
          </CustomEmailBlock>

          <CustomEmailBlock title='Require Attachment:' margin='8px 0px'>
            <CustomSwitch
              checked={checkState}
              handleChange={handleClickButton}
            ></CustomSwitch>
          </CustomEmailBlock>

          <CustomEmailBlock title='Email Content:' margin='8px 0px'>
            <RichTextEditor
              content={replaceVariablesSymbolsForFrontend(emailContent)}
              setContent={e => {
                setEmailContent(e);
              }}
            ></RichTextEditor>
          </CustomEmailBlock>

          <CustomEmailBlock title={''} margin='8px 0px'>
            <Box
              className={styleModule.SaveAndCancelButtonBox}
              sx={{
                justifyContent: 'flex-start',
                gap: '12px',
              }}
            >
              <Button
                disableRipple
                onClick={() => handleSaveClick()}
                // disabled={isDisabled}
                className={styleModule.SaveButton}
                sx={{ width: '70px !important', }}
              >Save</Button>
              <Button
                disableRipple
                className={styleModule.CancelButton}
                onClick={() => handleCancelClick()}
                sx={{ width: '70px !important', }}
              >Cancel</Button>
            </Box>
          </CustomEmailBlock>

        </>) :
          (<>
            <CustomEmailBlock title='Subject:' margin={`${userCustomize?8:16}px 0px 8px 0px`}>
              <CustomText>{emailSubjectForShow.innerText} </CustomText>
            </CustomEmailBlock>

            {!moreOrLess ? (
              <>
                <CustomEmailBlock title='From:' margin='8px 0px'>
                  {/* <CustomText>{textReplace(contentDetail.from)}</CustomText> */}
                  <HardCodeFromShowPart from={fromsArray[block - 1]} />
                </CustomEmailBlock>
                <CustomEmailBlock title='To:' margin='8px 0px'>
                  {/* <CustomText>{textReplace(contentDetail.to)}</CustomText> */}
                  <HardCodeToShowPart block={block}></HardCodeToShowPart>
                </CustomEmailBlock>
                <CustomEmailBlock title='Copy:' margin='8px 0px'>
                  {/* <CustomText>{textReplace(contentDetail.copy)}</CustomText> */}
                  <HardCodeCopyShowPart block={block}></HardCodeCopyShowPart>
                </CustomEmailBlock>
                <CustomEmailBlock title='Bcc:' margin='8px 0px'>
                  {/* <CustomText>{textReplace(contentDetail.from)}</CustomText> */}
                  <HardCodeFromShowPart from={fromsArray[block - 1]} />
                </CustomEmailBlock>
                {contentDetail.requireAttachment !== null ? (
                  <CustomEmailBlock title='Require Attachment:' margin='8px 0px'>
                    <CustomText>{contentDetail.requireAttachment}</CustomText>
                  </CustomEmailBlock>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            {/* Email Content*/}
            <CustomEmailBlock title='Email Content:' margin='8px 0px 0px 0px'>
              <CustomContent
                id={block}
                data-selenium-id={block}
                // value={contentDetail.content}
                value={emailContent}
                maxHeight='68px'
                moreOrLess={moreOrLess}
                setMoreOrLess={(val) => {
                  if (val){
                    setIsCompare(false);
                  }
                  setMoreOrLess(val);
                }}
              ></CustomContent>
            </CustomEmailBlock>

          </>)}

      </Box>
      {isCompare?(<Box className={'ShowAndEdit-RightBox'}>

        <Box sx={{...compareStyles,lineHeight: '20px', marginTop: '16px', }}>
          <Chip label={'System Template'} sx={{
            borderRadius: '4px',
            border: '1px solid #6D98EE',
            background: '#CEDDF9',
            color: '#113D95',
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            height: '24px',
          }}/>
        </Box>

        <Box sx={{
          ...compareStyles,
          margin: `8px 0px 8px 0px`,
        }}>
          <CustomText>{ replaceVariablesForFrontShow(
            contentDetail.customize.subject,
            emailVariables.full
          )?.innerText ?? ''} </CustomText>
        </Box>

        {!moreOrLess ? (
          <>
            <Box sx={{margin:'8px 0px'}}>
              {/* <CustomText>{textReplace(contentDetail.from)}</CustomText> */}
              <HardCodeFromShowPart from={fromsArray[block - 1]} />
            </Box>
            <Box sx={{margin:'8px 0px'}}>
              {/* <CustomText>{textReplace(contentDetail.to)}</CustomText> */}
              <HardCodeToShowPart block={block}></HardCodeToShowPart>
            </Box>
            <Box sx={{margin:'8px 0px'}}>
              {/* <CustomText>{textReplace(contentDetail.copy)}</CustomText> */}
              <HardCodeCopyShowPart block={block}></HardCodeCopyShowPart>
            </Box>
            <Box sx={{margin:'8px 0px'}}>
              {/* <CustomText>{textReplace(contentDetail.from)}</CustomText> */}
              <HardCodeFromShowPart from={fromsArray[block - 1]} />
            </Box>
            {contentDetail.customize.requireAttachment !== null ? (
              <Box sx={{margin:'8px 0px'}}>
                <CustomText>{contentDetail.customize.requireAttachment}</CustomText>
              </Box>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        {/* Email Content*/}
        <Box sx={{
          margin:'8px 0px 0px 0px',
          wordBreak: 'break-word',
          '&>div':{
            ...commonCss,
            marginTop: '-14px',
          }
        }}>
          {React.createElement('div', {
            dangerouslySetInnerHTML: {
              __html: replaceVariablesForFrontShow(
                contentDetail.customize.content,
                emailVariables.full).innerHTML }
          })}
        </Box>

      </Box>):null}
    </Box>

    <ButtonAlertBox
      open={buttonAlertOpen}
      handleClose={() => { setButtonAlertOpen(false); }}
      title="Replace your customized template with the system template?"
      message="This action will overwrite changes you have made to your current template."
      buttons={resetAlertButtons}
      severity="info"
      position={{vertical: 'center', horizontal: 'center'}}
      autoHideDuration={-1}
      style={{
        width: '600px',
        height: '163px',
        top: 'calc(50vh - 80px) !important',
        padding: '24px 32px 20px 32px',
      }}
    />
    <ResetAlterPopup
      open={buttonDialogOpen}
      setOpen={() => { setButtonDialogOpen(false);}}
      onClickOk={() => {
        setButtonDialogOpen(false);
        dispatch(removeUserEmailManual(contentDetail.id, 'SI'));
      }}
      onClickCancel={() => {
        setIsEdit(false);
        setButtonDialogOpen(false);
      }}
    >
    </ResetAlterPopup>
  </Box>);
}
export default ShowAndEdit;

import {Box, Stack} from "@mui/material";
import StyledSlider from "./StyledSlider";
import { useEffect } from "react";
import TextFieldErrorIcon from "@/assets/TextFieldErrorIcon.svg";
import * as React from "react";

export default function SliderBlock(props) {
  //這個組件建議只用於CheckList頁面
  const {
    title = 'Number of Articles:',
    boxSx,
    value = [0, 10],
    onChange = () => { },
    range = 10,//max-min，单独列出是因为甲方的逆天、反直觉的UI设计需要特殊的数据
    from = 0,//title显示的最小值
    to = 1000,
    min = 0,//silder实际的最小值
    max = 1000,
    error,
    errorMessage='Please drag the slider to select a number range',
    disabled,
    getSpecialLabel = (value) => {
      return value[0] + ' - ' + value[1];
    },
    needAsyncSetValue = false,//是否在初始狀態將傳入的value傳入onChange
    ...other
  } = props;
  useEffect(() => {
    setTimeout(() => {
      if (needAsyncSetValue) {
        onChange({},[...value]);
      }
    }, 500);
  }, [disabled]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '7px',
        width: '311px',
        ...boxSx
      }}
      {...other}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        <Box sx={{
          fontWeight: '600',
          fontSize: '14px',
          letterSpacing: '1%',
          color: '#596A7C',
        }}>{title}</Box>
        <Box sx={{
          minWidth: '78px',
          height: '28px',
          textAlign: 'center',
          borderRadius: '5px',
          border: '1px solid #BCC5CF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          color: (error || disabled) ? '#BCC5CF' : '#0F172A',
          fontWeight: '600',
          fontSize: '14px',
          letterSpacing: '1%',
          lineHeight: '20px',
        }}>
          {
                (<>{getSpecialLabel(value)}</>)
          }

        </Box>
      </Box>
      <StyledSlider
        value={value}
        error={error}
        disabled={disabled}
        min={min}
        max={max}
        onChange={onChange}
        {...other}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: '#0F172A',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '17px',
          height: '17px',
        }}>
        <Box>{from}</Box>
        <Box>{to}</Box>
      </Box>
      {error ? (
          <Stack
              direction='row'
              spacing='2px'
              alignItems='flex-Start'
              justifyContent='flex-start'
          >
            <Stack
                alignItems='center'
                justifyContent='center'
                sx={{
                  marginTop: '2px',
                }}
            >
              <TextFieldErrorIcon />
            </Stack>
            <Box
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '16px',
                  color: '#EE5350',
                  letterSpacing: '-0.084px',
                }}
            >
              {errorMessage}
            </Box>
          </Stack>
      ) : null}
    </Box>
  );
}
import { useState, useMemo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import CheckBoxUncheckedIcon from '@/assets/ReportDiagrams/icons/unchecked.svg';
import CheckBoxCheckedIcon from '@/assets/ReportDiagrams/icons/checked.svg';
import CheckBoxIntermediateIcon from '@/assets/ReportDiagrams/icons/indeterminate.svg';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CollapsedButton from './CollapsedButton2.svg';
import styles from './index.module.scss';

export const SelectorAccordion = ({
  groupName,
  options,
  selectedItems,
  setSelectedItems,
}) => {
  // console.log('===options===', options);
  // console.log('===selectedItems===', selectedItems);

  const [accordionExpanded, setAccordionExpanded] = useState(false);

  // check all state
  const numberOfCheckedOptions = useMemo(() => {
    try {
      const selectedOptions = options.filter(option => {
        const index = selectedItems.indexOf(option);
        return index >= 0;
      });
      return selectedOptions.length;
    } catch (e) {
      console.warn('Accordion error: isChecked()');
      return 0;
    }
  }, [options, selectedItems]);

  // accordion
  const handleAccordionExpansion = e => {
    if (e.target.tagName === 'svg' || e.target.tagName === 'path') {
      setAccordionExpanded(expanded => !expanded);
    } else {
      setAccordionExpanded(expanded => expanded);
    }
  };

  const handleMenuItemClick = (_event, index) => {
    let newSelectedItems = [...selectedItems];
    const clickedOption = options[index];

    // console.log('==clickedOption==', clickedOption);
    const indexOfClicked = selectedItems?.indexOf(clickedOption);

    if (indexOfClicked === -1) {
      // select it
      newSelectedItems.push(clickedOption);
    } else {
      //unselect it
      newSelectedItems.splice(indexOfClicked, 1);
    }
    // console.log('=newSelectedItems=', newSelectedItems);
    setSelectedItems(newSelectedItems);
  };

  const handleSelectAllClick = () => {
    let newSelectedItems = [...selectedItems];

    // check how many options are selected
    let numberOfCheckedOptions = 0;

    options.forEach(option => {
      const index = newSelectedItems?.indexOf(option);
      if (index >= 0) numberOfCheckedOptions++;
    });

    const isAllSelected = options?.length === numberOfCheckedOptions;

    // console.log('==isAllSelected==', isAllSelected);

    if (isAllSelected) {
      // uncheck all options
      options.forEach(option => {
        const index = newSelectedItems?.indexOf(option);
        if (index >= 0) {
          newSelectedItems.splice(index, 1);
        }
      });
    } else {
      // check all options
      options.forEach(option => {
        const index = newSelectedItems?.indexOf(option);
        if (index === -1) {
          newSelectedItems.push(option);
        }
      });
    }
    // console.log('==newSelectedItems==', newSelectedItems);
    setSelectedItems(newSelectedItems);
  };

  return (
    <Accordion
      onChange={handleAccordionExpansion}
      expanded={accordionExpanded}
      id={`CEAccordion ${groupName}`}
      sx={{
        margin: '0px 0px 1px 0px',
        '&:not(:last-child)': {
          borderBottom: '1px solid #DFE4E8',
        },
        padding: '10px 0px',
        borderRadius: '0',
        '&.MuiPaper-elevation': {
          margin: '0px !important',
          borderRadius: 'unset',
        },
        '&:before': {
          height: '0 !important',
        },
        boxShadow: 'none !important',
      }}
    >
      {/* add select all option */}
      <AccordionSummary
        expandIcon={<CollapsedButton />}
        aria-controls='panel-content'
        id='panel-header'
        sx={{
          padding: 0,

          borderRadius: 0,
          minHeight: '27px !important',

          '& .MuiAccordionSummary-content': {
            margin: '0px 15px 0px 7px !important',
            disableRipple: true,
            minHeight: 'unset',
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0px 15px 0px 7px !important',
            minHeight: '40px',
            minHeight: 'unset',
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            marginRight: '10px',
          },
          '& .MuiPaper-root': {
            marginTop: '0px !important',
            borderRadius: '6px',
            color: '#264ab7',
            boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
          },
          '& .MuiSvgIcon-root': {
            color: '#264ab7 !important',
          },
        }}
        disableRipple
      >
        <MenuItem
          key={groupName}
          value={groupName}
          onClick={handleSelectAllClick}
          className={styles['menu-item']}
          sx={{
            margin: 0,
            padding: 0,
            color: '#264ab7 !important',
            backgroundColor: 'unset !important',
            '&.MuiSvgIcon-root': {
              color: '#264ab7 !important',
            },
          }}
          disableRipple
        >
          <Checkbox
            checked={options?.length === numberOfCheckedOptions ?? false}
            indeterminate={
              numberOfCheckedOptions > 0 &&
              numberOfCheckedOptions < options?.length
            }
            icon={<CheckBoxUncheckedIcon />}
            checkedIcon={<CheckBoxCheckedIcon />}
            indeterminateIcon={<CheckBoxIntermediateIcon />}
            sx={{
              padding: '0 12px 0 0 ',
              [`&, &.${checkboxClasses.checked} svg, &.${checkboxClasses.indeterminate} svg`]:
                {
                  fill: '#0052CC',
                },
            }}
            disableRipple
          />
          <h1>{groupName}</h1>
        </MenuItem>
      </AccordionSummary>

      {/* normal options */}
      <AccordionDetails
        sx={{
          margin: 0,
          padding: '4px 7px 0px',
        }}
      >
        {options?.map((option, index) => (
          <MenuItem
            key={option}
            onClick={event => handleMenuItemClick(event, index)}
            className={styles['menu-item']}
            disableRipple
            sx={{
              margin: 0,
              padding: 0,
              color: '#264ab7 !important',
              backgroundColor: 'unset !important',
              '& .MuiSvgIcon-root': {
                color: '#264ab7 !important',
              },
            }}
          >
            <Checkbox
              checked={selectedItems?.indexOf(option) >= 0 ?? false}
              icon={<CheckBoxUncheckedIcon />}
              checkedIcon={<CheckBoxCheckedIcon />}
              indeterminateIcon={<CheckBoxIntermediateIcon />}
              sx={{
                padding: '0 12px 0 0 ',
                [`&, &.${checkboxClasses.checked} svg, &.${checkboxClasses.indeterminate} svg`]:
                  {
                    fill: '#0052CC',
                  },
              }}
              disableRipple
            />
            <span>{option}</span>
          </MenuItem>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export const SET_CUSTOMIZED_PATH = 'SET_CUSTOMIZED_PATH';
export const CLEAR_CUSTOMIZED_PATH = 'CLEAR_CUSTOMIZED_PATH';

export function setCustomizedPath(path) {
  return dispatch => {
    dispatch({ type: SET_CUSTOMIZED_PATH, data: path });
  };
}

export function clearCustomizedPath() {
  return dispatch => {
    dispatch({ type: CLEAR_CUSTOMIZED_PATH, data: null });
  };
}

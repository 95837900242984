
const CanvasSingleton = (function() {
  let canvas;
  let context;

  function createCanvas() {
    canvas = document.createElement('canvas');
    context = canvas.getContext('2d');
    return context;
  }

  return {
    getContext: function() {
      if (!context) {
        context = createCanvas();
      }
      return context;
    }
  };
})();

// Calc the width of text
// eg. measureWidth('Hello world!', '14px Arial')
export function measureWidth(text, fontStyle='16px Arial') {
  const context = CanvasSingleton.getContext();
  context.font = fontStyle;
  const textMetrics = context.measureText(text);
  return Math.ceil(textMetrics.width);
}

// export function findLongestLineSubstring(textLine) {
//   const substrArray = textLine.split(/\s+/);
//   return substrArray.reduce((acc, str) => str.length > acc.length ? str : acc);
// }

export function findLongestLineSubstring(text) {
  if (!text.includes(' ')) {
    return text;
  }

  const words = text.split(/\s+/);
  const totalLength = text.length;
  const targetLength = Math.floor(totalLength / 2);

  let closestLength = Infinity;
  let closestIndex = -1;

  for (let i = 1; i < words.length; i++) {
    const firstPart = words.slice(0, i).join(' ');
    // const secondPart = words.slice(i).join(' ');
    const lengthDifference = Math.abs(firstPart.length - targetLength);

    if (lengthDifference < closestLength) {
      closestLength = lengthDifference;
      closestIndex = i;
    }
  }

  const firstPart = words.slice(0, closestIndex).join(' ');
  const secondPart = words.slice(closestIndex).join(' ');

  return firstPart.length > secondPart.length ? firstPart : secondPart;
}

export function nullableMax(...values) {
  return Math.max(...values.map(value => value??0));
}

export function measureLongestWidth(text, fontStyle='16px Arial'){
  return measureWidth(findLongestLineSubstring(text), fontStyle);
}

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetAAndAFYTrendsChartData,
  GetAAndAFYTrendsChartDataWithStatus,
} from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import {replaceZero} from "@/pages/Report/utils/reportUtils";

export default function useChartsDataHook(
  selectedFY,
  catagoryAttributeName,
  url,
  hasAdditionalFilter,
  selectedItem
) {
  // create a new redux title for data
  const reduxDataTitle = `Article/${selectedFY}/${catagoryAttributeName}/${selectedItem}`;

  const dispatch = useDispatch();

  const { reportDataAPI } = useSelector(state => {
    return {
      reportDataAPI: state.ReportDiagram[reduxDataTitle],
    };
  });

  let loading = null,
    error = null,
    data = null;

  if (typeof reportDataAPI !== 'undefined') {
    ({ loading, error, data } = reportDataAPI);
  }
  replaceZero(data);
  useEffect(() => {
    const isValidDate = Number.isInteger(parseFloat(selectedFY));

    const isValidRequest = !hasAdditionalFilter || !!selectedItem;

    // console.group('hook');
    // console.log('==selectedItem==', selectedItem);

    // console.log('==isValidRequest==', isValidRequest);
    // console.groupEnd();

    if (typeof reportDataAPI === 'undefined' && isValidDate && isValidRequest) {
      dispatch(
        GetAAndAFYTrendsChartDataWithStatus(
          url, // url + '_fake_test',
          reduxDataTitle,
          selectedFY,
          catagoryAttributeName,
          selectedItem
        )
      );
    }
  }, [
    reportDataAPI,
    selectedFY,
    catagoryAttributeName,
    selectedItem,
    hasAdditionalFilter,
  ]);

  console.log('==data==', data);

  return [data, loading, error];
}

import { useMemo } from 'react';
import Chart from '@/components/Chart/Chart';
// import { AAndAFYTrendsSGOption, AAndAFYTrendsBDOption } from '../options';
import { AAndAFYTrendsSGOption } from '../options/AAndAFYTrendsSGOption';
import { AAndAFYTrendsBDOption } from '../options/AAndAFYTrendsBDOption';
import styles from './index.module.scss';

const ChartAAndA = ({
  barchartData,
  width,
  hasZoom = false,
  hasTitle = false,
  title,
  subTitle,
  loading = false,
  error = false,
  catagoryAttributeName,
  header,
  subHeader,
}) => {
  let sgOO;
  let sgOA;
  let bdOO;
  let bdOA;
  let haveBD = true;
  // const isSG = catagoryAttributeName === 'subjectGroup';
  const isSG = catagoryAttributeName === 'sg';
  if (barchartData) {
    if (isSG) {
      //这个sg没有bd的情况下，barchartData的长度为3，有bd的情况下，barchartData的长度为5
      if (barchartData.length < 4) {
        // quick fix for losing a row of data
        if (barchartData.length === 2) {
          const isOA = barchartData[1][0].indexOf('-OA') >= 0;
          if (isOA) {
            sgOA = barchartData[1][0];
            haveBD = false;
          } else {
            sgOO = barchartData[1][0];
            haveBD = false;
          }
        } else {
          sgOO = barchartData[1][0];
          sgOA = barchartData[2][0];
          haveBD = false;
        }
      } else {
        bdOO = barchartData[1][0];
        bdOA = barchartData[2][0];
        if (barchartData[3] && barchartData[4]) {
          sgOO = barchartData[3][0];
          sgOA = barchartData[4][0];
        }
        haveBD = true;
      }
    } else {
      bdOO = barchartData[1][0];
      bdOA = barchartData[2][0];
    }
  }

  const chartOption = useMemo(() => {
    return isSG
      ? new AAndAFYTrendsSGOption(
          header,
          subHeader,
          sgOO,
          sgOA,
          bdOO,
          bdOA,
          haveBD
        )
      : new AAndAFYTrendsBDOption(header, subHeader, bdOO, bdOA);
  });

  const options = useMemo(() => {
    // if loading or error skip drawing the chart
    if (loading || error) {
      return null;
    }
    chartOption
      .setHasZoom(hasZoom)
      .setTitle(hasTitle, title, subTitle)
      .setDataSource(barchartData)
      .setWidth(width);

    if (isSG) {
      if (haveBD) {
        chartOption
          .setLegend([bdOO, bdOA, sgOO, sgOA])
          .setLegendSG([bdOO, sgOO])
          .setLegendBD([bdOA, sgOA]);
      } else {
        chartOption
          .setLegend([sgOO, sgOA])
          .setLegendSG([sgOO])
          .setLegendBD([sgOA]);
      }
    } else {
      chartOption.setLegend([bdOO, bdOA]);
    }

    return chartOption.getOption();
  }, [hasZoom, width, hasTitle, title, subTitle, barchartData, loading, error]);
  console.log('======options=====, ', options);

  const chartHeight = useMemo(() => {
    // if loading or error set chart height to 0
    if (loading || error) {
      return 0;
    }
    return chartOption.getHeightWithTitle();
  }, [hasTitle, title, subTitle, barchartData, loading, error]);
  // console.log('======chartHeight=====, ', chartHeight);

  return (
    <Chart
      className={styles['inner-chart']}
      options={options}
      height={chartHeight}
      width={width}
      isZoomLabelFontSize={false}
    />
  );
};

export default ChartAAndA;

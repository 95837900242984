import React from 'react';
import SimpleIconButton from '../Button/SimpleIconButton';
import IconActive from './icon/TextViewAllIconActive.svg';
import IconDefault from './icon/TextViewAllIconDefault.svg';
import IconDisable from './icon/TextViewAllIconDisable.svg';
export default function TextViewAllButton(props) {
    return (
        <SimpleIconButton
          sx={{
            padding:'0px'
          }}
          DefaultIcon={IconDefault}
          HoverIcon={IconActive}
          ActiveIcon={IconActive}
          DisabledIcon={IconDisable}
          {...props}
        ></SimpleIconButton>
      );
}

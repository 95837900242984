// @ts-check
import { Box, Stack,Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { TitleBlock } from './CustomComponents/CustomBlock';
import CommentSection from './Section/CommentSection';
import SipStatusSection from './Section/SipStatusSection';
import { ObjectEditSection } from './Section/ObjectSection';
import { SSIPObjectEditSection} from './Section/SSIPObjectSection';
import { GEInformationEditSection } from './Section/GEInformationSection';
import { SIPInformationEditSection } from './Section/SIPInformationSection';
import { SSIPInformationEditSection, SSIPInformationShowSection } from './Section/SSIPInformationSection';
import { ContentCommissioningApproachesEditSection } from './Section/ContentCommissioningApproachesSection';
import { DetailScrollTopButton } from '../../../components/StyledButton/ScrollToTopButton';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import SaveAndCancelButton from '@/componentsphase2/SIDetail/SaveAndCancelButton';
import styleModule from '@/componentsphase2/SIDetail/SaveAndCancelButton.module.scss';
import { SIPInformationShowSection } from './Section/SIPInformationSection';
import { ContentCommissioningApproachesShowSection } from './Section/ContentCommissioningApproachesSection';
import { useParams } from 'react-router-dom';
import {
  loadSIPReviseData,
  SET_DOWNLOAD_REVISE_DATA_STATUS,
  SET_SAVING_REVISE_DATA_STATUS,
  SET_UPLOAD_REVISE_DATA_STATUS,

} from '../../../actions/SIMT-SIP/SIP/SIPAction';
import { setSnackbarMessageAndOpen } from '../../../actions/SnackbarAction';
import { SEVERITIES } from '../../../modules/ConfirmSnackBar/CommonSnackBar';
import {
  RevisePageBodyContainer,
  SectionsContainer,
} from './CustomComponents/CustomContainer';
import { SaveOrSubmitStickyBar } from './CustomComponents/CustomStickyBar';
import {
  commissionApproachesInitialValue,
  geInformationListIntialValue,
  sipInformationInitialValue,
} from './CommonInitialValue';
import { SSIPContentCommissioningApproachesEditSection } from './Section/SSIPContentCommissioningApproachesSection';
import { SubmitConfirmModal } from './CustomComponents/CustomModal';
import {CANCEL_EDIT} from '@/actions/SIMT-SIP/SIPChecklistSubmitted/SIPChecklistAction';
import {saveSIPReviseDraft, upLoadSIPReviseData} from "@/actions/SIMT-SIP/SIP/SipRevise";
/**
 * @typedef {import("./ProposerRevisePage").SIPProposerReviseData} SIPProposerReviseData
 * @typedef {import("./ProposerRevisePage").SIPProposerReviseFromJEData} SIPProposerReviseFromJEData
 * @typedef {import("./ProposerRevisePage").SIPProposerReviseObject} SIPProposerReviseObject
 * @typedef {import("./RevisePage").GEInformationItem} GEInformationItem
 * @typedef {import("./RevisePage").SipInformation} SipInformation
 * @typedef {import("./RevisePage").CommissionApproaches} CommissionApproaches
 */

/** @type {SIPProposerReviseObject} */
export const sipObjectInitialValue = {
  preferredSubjectArea: 'Nikolaou, Ioannis',
  choiceJournal1st: 'Uniyal, Varsha',
  choiceJournal2nd: 'Uniyal, Varsha',
  choiceJournal3rd: 'Uniyal, Varsha',
};
/** @type {SIPProposerReviseObject} */
export const ssipObjectInitialValue = {
  choiceJournal1st:'Uniyal, Varsha',
  journal1Code:'0000',
  journal1Name:'journal Name',
  journal1HomePage:'',
  journal1AuthorGuidelines:'',
};

/**
 * @type {SIPProposerReviseData}
 */
export const sipReviseDataInitialState = {
  geInformation: geInformationListIntialValue,
  notEditable: {
    comment:
      'revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise',
    sipCode: 'SIP-2022-001234',
    sipTitle:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. ',
    dueDate: '2023-01-08T04:05:59.077Z',
    sipStage: '',
  },
  sipInformation: sipInformationInitialValue,
  object: sipObjectInitialValue,
  commissionApproaches: commissionApproachesInitialValue,
};
/** @type {SIPProposerReviseFromJEData} */
export const sipReviseDataFromJEInitialState = {
  geInformation: geInformationListIntialValue,
  notEditable: {
    comment:
      'revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise revise',
    sipCode: 'SIP-2022-001234',
    sipTitle:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. ',
    dueDate: '2023-01-08T04:05:59.077Z',
    fromJournal: 'Lorem Ipsum is simply dummy text of the printing',
  },
  sipInformation: sipInformationInitialValue,
  commissionApproaches: commissionApproachesInitialValue,
};

/** @typedef {import("./ProposerRevisePage").DownloadReviseDataStatus} DownloadReviseDataStatus */
/** @typedef {import("./ProposerRevisePage").UploadReviseDataStatus} UploadReviseDataStatus */
/** @typedef {import("./ProposerRevisePage").SaveReviseDataStatus} SaveReviseDataStatus */

/**
 *
 * @param {import("./ProposerRevisePage").ProposerRevisePageProps} props
 * @returns
 */
const ProposerRevisePage = props => {
  const { type, sipProposerReviseData } = props;
  const { uploadReviseDataStatus, saveReviseDataStatus } = useSelector(
    state => ({
      /** @type {UploadReviseDataStatus} */
      // @ts-ignore
      uploadReviseDataStatus: state.SIP.uploadReviseDataStatus,
      /** @type {SaveReviseDataStatus} */
      // @ts-ignore
      saveReviseDataStatus: state.SIP.saveReviseDataStatus,
    })
  );

  // Object
  const [object, setObject] = useState(sipObjectInitialValue);
  const [ssipObject,setSSIPObject] = useState(ssipObjectInitialValue);
  const [objectError, setObjectError] = useState(false);

  // GE Information
  const [geInformationList, setGeInformationList] = useState(
    /** @type {GEInformationItem[]} */ ([])
  );
  const [geInformationError, setGeInformationError] = useState(false);

  // SIP Information
  const [sipInformation, setSipInformation] = useState(
    sipInformationInitialValue
  );
  const [sipInformationError, setSipInformationError] = useState(false);

  // Content Commissioning Approaches
  const [commissionApproaches, setCommissionApproaches] = useState(
    commissionApproachesInitialValue
  );
  const [commissionApproachesError, setCommissionApproachesError] =
    useState(false);

  const extractJournalCode = inputString => {
    if (!inputString) return null;
    const regex = /.*\((.*)\)/;
    const match = inputString.match(regex);

    if (match) {
      const extractedPart = match[1];
      return extractedPart;
    } else {
      return inputString;
    }
  };

  // sipReviseData 每次改变意味着数据更新，需要广播至整个表单
  useEffect(() => {
    if (type === 'ProposerRevise'){
      const objectVo = sipProposerReviseData.object;
      const choiceJournal1st = objectVo?.choiceJournal1st;
      const choiceJournal2nd = objectVo?.choiceJournal2nd;
      const choiceJournal3rd = objectVo?.choiceJournal3rd;
      const object = {
        ...objectVo,
        choiceJournal1st: extractJournalCode(choiceJournal1st),
        choiceJournal2nd: extractJournalCode(choiceJournal2nd),
        choiceJournal3rd: extractJournalCode(choiceJournal3rd),
      };
      setObject(object);
      setSSIPObject(object);
    }

    setGeInformationList(sipProposerReviseData.geInformation);

    setSipInformation(sipProposerReviseData.sipInformation);

    setCommissionApproaches(sipProposerReviseData.commissionApproaches);
  }, [sipProposerReviseData]);
  const { token } = useParams();
  const dispatch = useDispatch();
  const [SSIPstate,setSSIPState] = useState('edit');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const isEmpty = (value) =>{
    if(value.length===0) return true;
    else{
      for(let i=0;i<value.length;i++){
        if(value[i]!=='') return false;
      }
      return true;
    }
  };
  /**
   * @returns {SIPProposerReviseData | SIPProposerReviseFromJEData}
   */
  const getNewReviseData = () => {
    if (type === 'ProposerRevise') {
      if (sipProposerReviseData.notEditable.sipCode.split('-')[0] === 'SSIP') {
        if (isEmpty(sipInformation.keywords)) sipInformation.keywords = [];
        if (isEmpty(sipInformation.topics)) {
          sipInformation.topics = [];
        } else {
          let Topic = [];
          if (sipInformation.topics !== null) {
            for (let i = 0; i < sipInformation.topics.length; i++) {
              if (sipInformation.topics[i] !== '') Topic.push(sipInformation.topics[i]);
            }
          }
          sipInformation.topics = Topic;
        }
        sipInformation.references = (sipInformation.references || []).filter(
          reference => reference !== ''
        );

        // @ts-ignore
        return {
          geInformation: geInformationList,
          notEditable: sipProposerReviseData.notEditable,
          sipInformation: sipInformation,
          object: ssipObject,
          commissionApproaches: commissionApproaches,
          sipStatus: sipProposerReviseData.sipStatus,
        };
      } else {
        // @ts-ignore
        return {
          geInformation: geInformationList,
          notEditable: sipProposerReviseData.notEditable,
          sipInformation: sipInformation,
          object: object,
          commissionApproaches: commissionApproaches,
          sipStatus: sipProposerReviseData.sipStatus,
        };
      }
    }
    else {
        // @ts-ignore
        return {
            geInformation: geInformationList,
            notEditable: sipProposerReviseData.notEditable,
            sipInformation: sipInformation,
            commissionApproaches: commissionApproaches,
        };
    }
  };

  const handleSaveDraft = () => {
    if(SSIPstate==='edit'){
      if (!handleErrorCheck()) {
        //has error
        return;
      }

      const newReviseData = getNewReviseData();

      if (token) {
        dispatch({
          type: SET_SAVING_REVISE_DATA_STATUS,
          /** @type {SaveReviseDataStatus} */
          data: 'saving',
        });
        dispatch(saveSIPReviseDraft(token, type, newReviseData));
      }
    }else{
      setSSIPState('edit');
    }

  };

  useEffect(() => {
    if (saveReviseDataStatus === 'success') {
      dispatch(
        setSnackbarMessageAndOpen(
          'Your recent inputs have been saved successfully!',
          {},
          'success'
        )
      );
      setSSIPState('save');
    }

    if (
      saveReviseDataStatus === 'success' ||
      saveReviseDataStatus === 'error'
    ) {
      dispatch({
        type: SET_SAVING_REVISE_DATA_STATUS,
        /** @type {SaveReviseDataStatus} */
        data: 'idle',
      });
    }
  }, [saveReviseDataStatus]);
  const [confirmSubmitModalOpen,setConfirmSubmitModalOpen] = useState(false);
  const handleSubmitRevise = () => {
    if (!handleErrorCheck()) {
      //has error
      return;
    }
    setConfirmSubmitModalOpen(true);
    const newReviseData = getNewReviseData();

    if (token) dispatch(upLoadSIPReviseData(token, type, newReviseData));
  };

  const handleErrorCheck = (/** @type {boolean} */ needSnackbar=true) => {
    if (!checkDataValidation() && needSnackbar) {
      // dispatch(
      //   setSnackbarMessageAndOpen(
      //     'sipDetail.revise.submitReviseInvalidData',
      //     {},
      //     SEVERITIES.error
      //   )
      // );
      setIsDisable(true);
      return false;
    }
    setIsDisable(false);
    return true;
  };

  useEffect(() => {
    if (
      uploadReviseDataStatus === 'success' ||
      uploadReviseDataStatus === 'error'
    ) {
      if (token) {
        dispatch({
          type: SET_DOWNLOAD_REVISE_DATA_STATUS,
          /** @type {DownloadReviseDataStatus} */ data: 'downloading',
        });
        dispatch(loadSIPReviseData(token, type));
      }
      dispatch({
        type: SET_UPLOAD_REVISE_DATA_STATUS,
        /** @type {UploadReviseDataStatus} */ data: 'idle',
      });
    }
  }, [uploadReviseDataStatus]);

  const checkDataValidation = () => {
    // Check whether these has any Error
    const res = !(
      objectError ||
      geInformationError ||
      sipInformationError ||
      commissionApproachesError
    );
    // Show error message when has erro
    setShowErrorMessage(!res);
    dispatch({type: CANCEL_EDIT});
    return res;
  };
  const [isSaving,setIsSaving]=useState(false);
  const [isDisabled,setIsDisable] = useState(false);
  useEffect(()=>{
    if(isDisabled) handleErrorCheck(false);
  },[object,geInformationList,sipInformation,commissionApproaches]);
  return (
    sipProposerReviseData.notEditable.sipCode.split('-')[0]==='SSIP'?
    <Box data-selenium-id='SSIP_RevisePage_Proposer-Box'>
      <RevisePageBodyContainer sx={{paddingTop:'32px'}}>
        <TitleBlock
          data-selenium-id='SSIP_RevisePage_Proposer-TitleBlock'
          title={sipProposerReviseData.notEditable.sipTitle}
          SIPCode={sipProposerReviseData.notEditable.sipCode}
          dueDate={sipProposerReviseData.notEditable.dueDate}
          isSSIP={true}
        />
        <SectionsContainer sx={{paddingTop:'30px'}} spacing='32px'>
          <CommentSection
            title={
              type === 'ProposerRevise'
                ? 'Comments to the Proposal'
                : 'Editor Comments to the Proposal'
            }
            fromJournal={
              (type === 'ProposerReviseFromJE' &&
                sipProposerReviseData.notEditable.fromJournal) ||
              ''
            }
            commentsContent={sipProposerReviseData.notEditable.comment}
            isSSIP={true}
          />
          {/* <SipStatusSection
            sipCode={sipProposerReviseData.sipStatus?.sipCode}
            handlingCE={sipProposerReviseData.sipStatus?.handlingCE}
            CreationDate={sipProposerReviseData.sipStatus?.submissionDate}
            LatestDecisionDate={sipProposerReviseData.sipStatus?.lastDecisionDate}
          /> */}
          {type === 'ProposerRevise' && (
            <SSIPObjectEditSection
              value={ssipObject}
              setValue={setSSIPObject}
              showErrorMessage={showErrorMessage}
              setError={setObjectError}
              isSSIP={true}
              state={SSIPstate}
            />
          )}
          <GEInformationEditSection
            value={geInformationList}
            setValue={setGeInformationList}
            showErrorMessage={showErrorMessage}
            setError={setGeInformationError}
            isSSIP={true}
          />
          {SSIPstate==='edit'? <SSIPInformationEditSection
            value={sipInformation}
            setValue={setSipInformation}
            showErrorMessage={showErrorMessage}
            setError={setSipInformationError}
            isOpenCall={commissionApproaches.openCallForPapers==="1"}
            // state={SSIPstate}
          />: <SSIPInformationShowSection value={sipInformation} />}
          {SSIPstate!=='edit'?<ContentCommissioningApproachesShowSection
            data-selenium-id='SSIP_RevisePage_JPMReview-ContentCommissioningApproachesShowSection'
            value={commissionApproaches}
            emptyTip={true}
          />: <SSIPContentCommissioningApproachesEditSection
          data-selenium-id='SSIP_RevisePage_Proposer-ContentCommissioningApproachesEditSection'
          value={commissionApproaches}
          setValue={setCommissionApproaches}
          showErrorMessage={showErrorMessage}
          setError={setCommissionApproachesError}
        />}

           <Box
              className={styleModule.SaveAndCancelButtonBox}
              sx={{paddingTop:'52px',paddingBottom:'64px',paddingRight:'106px'}}
            >
              <Button
                disableElevation
                disableRipple
                onClick={handleSaveDraft}
                sx={{width:'119px',border:'1px #0052CC solid'}}
              >{SSIPstate==='edit'?'Save draft':'Edit'}</Button>
              <LoadingButton
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                height: '41px',
                padding: '12px 18px',
                background: isSaving?'#6D98EE': isDisabled ?'#DFE4E8':'#0052CC',
                borderRadius: '5px',
                color: isDisabled ? '#98A7B6' : '#FFFFFF',
                width: '119px',
                textTransform: 'none',
                '&:hover':{
                    background: '#6D98EE',
                },
                '&:active':{
                    background: '#113D95',
                }
            }}
                disableElevation
                disableRipple
                onClick={()=>{
                  if (!handleErrorCheck()) {
                    setConfirmSubmitModalOpen(false);
                  }else{
                    setConfirmSubmitModalOpen(true);
                  }}}
                disabled={isDisabled}
                loading={isSaving}
                // className={styleModule.SaveButton}
              >Submit</LoadingButton>
            </Box >
            <SubmitConfirmModal
              data-selenium-id='SSIP_RevisePage_CustomComponents-StickyBar-SaveOfSubmit-SubmitConfirmModal'
              open={confirmSubmitModalOpen}
              handleClose={() => setConfirmSubmitModalOpen(false)}
              confirmText={
                type === 'ProposerRevise'
                  ? `Are you sure to submit the revision? After you click on "OK", you
              will not be able to access this page anymore.`
                  : `Are you sure to submit the revision? After you click on "OK", you will not be able to access this page anymore. Please note that your revision will be sent directly to the journal editor for further evaluation.`
              }
              handleSubmit={() => {
                handleSubmitRevise();
                setConfirmSubmitModalOpen(false);
              }}
            />
        </SectionsContainer>

      </RevisePageBodyContainer>

    </Box>
    :

    <Box data-selenium-id='SIP_RevisePage_Proposer-Box'>
      <RevisePageBodyContainer>
        <TitleBlock
          data-selenium-id='SIP_RevisePage_Proposer-TitleBlock'
          title={sipProposerReviseData.notEditable.sipTitle}
          SIPCode={sipProposerReviseData.notEditable.sipCode}
          dueDate={sipProposerReviseData.notEditable.dueDate}
        />
        <SectionsContainer>
          <CommentSection
            title={
              type === 'ProposerRevise'
                ? 'Comments to the Proposal'
                : 'Editor Comments to the Proposal'
            }
            fromJournal={
              (type === 'ProposerReviseFromJE' &&
                sipProposerReviseData.notEditable.fromJournal) ||
              ''
            }
            commentsContent={sipProposerReviseData.notEditable.comment}
            isSSIP={false}
          />
          {type === 'ProposerRevise' && (
            <ObjectEditSection
              value={object}
              setValue={setObject}
              showErrorMessage={showErrorMessage}
              setError={setObjectError}
              isSSIP={false}
            />
          )}
          <GEInformationEditSection
            value={geInformationList}
            setValue={setGeInformationList}
            showErrorMessage={showErrorMessage}
            setError={setGeInformationError}
          />
          <SIPInformationEditSection
            value={sipInformation}
            setValue={setSipInformation}
            showErrorMessage={showErrorMessage}
            setError={setSipInformationError}
          />
          <ContentCommissioningApproachesEditSection
            data-selenium-id='SIP_RevisePage_Proposer-ContentCommissioningApproachesEditSection'
            value={commissionApproaches}
            setValue={setCommissionApproaches}
            showErrorMessage={showErrorMessage}
            setError={setCommissionApproachesError}
          />
        </SectionsContainer>
      </RevisePageBodyContainer>
      <DetailScrollTopButton
        boxProps={{ sx: { bottom: 135 } }}
        data-selenium-id='SIP_RevisePage_Proposer-DetailScrollTopButton'
      />
      <SaveOrSubmitStickyBar
        savingDraft={saveReviseDataStatus === 'saving'}
        handleSaveDraft={handleSaveDraft}
        handleSubmit={handleSubmitRevise}
        handleErrorCheck={handleErrorCheck}
        confirmText={
          type === 'ProposerRevise'
            ? `Are you sure to submit the revision? After you click on "OK", you
        will not be able to access this page anymore.`
            : `Are you sure to submit the revision? After you click on "OK", you will not be able to access this page anymore. Please note that your revision will be sent directly to the journal editor for further evaluation.`
        }
      />
    </Box>
  );
};

export default ProposerRevisePage;

import IconBox from '../../../CommonComponets/IconBox';
import TimeSolid from '../../../SVGComponents/TimeSolid.svg';
import Solid from '../../../SVGComponents/Solid.svg';
export default function ChipIcon(props) {
  const { mode = 0 } = props;
  switch (mode) {
    case 0:
      return (
        <IconBox pathFill='#EE5350'>
          <TimeSolid />
        </IconBox>
      );
    case 1:
      return (
        <IconBox pathFill='#FFB152'>
          <TimeSolid />
        </IconBox>
      );
    case 2:
      return (
        <IconBox pathFill='#4C81EB'>
          <TimeSolid />
        </IconBox>
      );
    case 3:
      return (
        <IconBox circleFill='#FF8180'>
          <Solid />
        </IconBox>
      );
    case 4:
      return (
        <IconBox circleFill='#FFCA7B'>
          <Solid />
        </IconBox>
      );
    default:
      return null;
  }
}

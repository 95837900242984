import {axios_instance} from "@/utils/axios_instance";
import {downloadFile} from "@/utils/commonUtils";
import getAuth from "@/utils/getAuth";

/**
 * 下载AnnualTarget模板
 * @param {*} fy 年份
 * @param {*} level
 * @returns
 */
export function downloadATFile(fy, level) {
    return async dispatch => {
        try {
            let res = await axios_instance.get('/reports/export/target/template', {
                responseType: 'blob',
                params: {
                    fy: fy,
                    level: level,
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    authorization: getAuth(),
                },
            });

            downloadFile(res.data, `${level}.xls`);
        } catch (e) {
        }
    };
}
import Header from '../../../../components/Header/index';
import SelectStageButton from '../../../../components/CreateAndUpdate/SelectStageButton';
import { Box, Grid, Stack } from '@mui/material';
import AcquiredIcon from '../../../../assets/updateAcquriedIcon.svg';
import AcquiredDisableIcon from '../../../../assets/selectStageAcquiredDisabledIcon.svg';
import PaperComissionIcon from '../../../../assets/updatePaperComission.svg';
import PaperComissionDisableIcon from '../../../../assets/selectStagePaperDisableIcon.svg';
import SubmissionIcon from '../../../../assets/updateSubmissionAndReview.svg';
import SubmissionDisableIcon from '../../../../assets/selectStageSubmissionDisableIcon.svg';
import ProductionIcon from '../../../../assets/updateProductionIcon.svg';
import ProductionDisableIcon from '../../../../assets/selectStageProductionDisableIcon.svg';
import PublishedIcon from '../../../../assets/selectStagePublishedIcon.svg';
import PublishedDisableIcon from '../../../../assets/selectStagePublishedDisableIcon.svg';
import ShelvedIcon from '../../../../assets/selectStageShelvedIcon.svg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSIClear } from '@/actions/SIMT-SI/SpecialIssue/SIAction';
import { openConfirm, setEditType, setTextParam } from '@/actions/SIMT-User/Alert/AlertAction';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../../components/Footer';
import { setSICodeToJump } from '../../../../actions/SIMT-SI/SpecialIssue/SIAction';
import MainPageFramework from '../CGTComponents/MainPageFramework';
import CreateAndUpdateMain from '../CommonComponents/CreateAndUpdateMain';
import { AllCentered } from '../CGTComponents/CommonStyle';
import {getSIDetail} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueDetail";
import {getStageList} from "@/actions/SIMT-SI/SpecialIssue/Config";

function SelectStage() {
  const [currentStage, setCurrentStage] = useState(-1);
  //通过获取url进行切片获取sicode
  let url = window.location.href.split('/');
  let siCode = url[url.length - 2];

  //定义一个action生成方法 获取原本的信息和更新的信息
  const selector = state => {
    return {
      SIInfoResult: state.SI.detailInfo, // 会从Reducer里面拿取到 detailInfo
      SIUpdateResult: state.SI.updateSIResult,
      stageList: state.SI.stageList,
    };
  };

  //当两个信息一有变化 useSelector()中的SIInfoResult,SIUpdateResult参数进行更新
  const { SIInfoResult, SIUpdateResult, stageList } = useSelector(selector);

  //当SIInfoResult 一有更新 就会执行回调函数 将状态调整成传来的状态
  useEffect(() => {
    try {
      // setCurrentStage(stageSelect[SIInfoResult.generalInformation[0].stage])
      stageList.forEach(element => {
        if (element.stageCode === SIInfoResult.generalInformation[0].stage) {
          setCurrentStage(element.id);
        }
      });
    } catch {}
  }, [SIInfoResult]);

  const nevigate = useNavigate();
  const dispatch = useDispatch();

  //当SIInfoResult 一有更新 就会执行回调函数 将状态调整成传来的状态
  useEffect(() => {
    if (SIUpdateResult !== '') {
      if (SIUpdateResult === 'OK') {
        dispatch(createSIClear());
        dispatch(
          setTextParam(SIInfoResult.generalInformation[0].specialIssueTitle)
        );
        dispatch(setEditType('updateSI'));
        dispatch(openConfirm());
      } else {
        dispatch(createSIClear());
        dispatch(setTextParam());
        dispatch(setEditType('error'));
        dispatch(openConfirm());
      }
    }
  }, [SIUpdateResult]);

  useEffect(() => {
    dispatch(getStageList());
  }, []);

  useEffect(() => {
    async function fetchData(SIid) {
      // setSICodeToJump(SIid)
      dispatch(getSIDetail(SIid));
    }

    fetchData(siCode);
  }, [siCode]);

  const container = currentStage !== -1 && (
    <Stack spacing={1.5} direction='column' sx={{...AllCentered}}>
      <Box
        data-selenium-id='SI_CreateAndUpdate_Update-SelectStage-Box-Box-Grid-Grid-Box'
        sx={{
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '20px',
          lineHeight: '28px',
          letterSpacing: '-0.022em',
          textTransform: 'capitalize',
          color: '#596A7C',
          marginBottom: '6px',
          width:'448px'
        }}
      >
        Select Stage
      </Box>
      {/* 直接加按钮才能实现center，这一层不需要item */}
      <SelectStageButton
        data-selenium-id='SI_CreateAndUpdate_Update-SelectStage-Box-Box-Grid-Grid-SelectStageButton1'
        content={stageList[1].stageCode}
        startIcon={<AcquiredIcon />}
        startIconDisabled={<AcquiredDisableIcon />}
        onClick={() => {
          nevigate(`/simt/auth/siRelated/si/detail/${siCode}/update/Acquired`, {
            state: SIInfoResult,
          });
        }}
        disable={currentStage >= stageList[1].id}
      />
      <SelectStageButton
        data-selenium-id='SI_CreateAndUpdate_Update-SelectStage-Box-Box-Grid-Grid-SelectStageButton2'
        content={stageList[2].stageCode}
        startIcon={<PaperComissionIcon />}
        startIconDisabled={<PaperComissionDisableIcon />}
        onClick={() => {
          nevigate(
            `/simt/auth/siRelated/si/detail/${siCode}/update/PaperCommission`,
            { state: SIInfoResult }
          );
        }}
        disable={currentStage >= stageList[2].id}
      />
      <SelectStageButton
        data-selenium-id='SI_CreateAndUpdate_Update-SelectStage-Box-Box-Grid-Grid-SelectStageButton3'
        content={stageList[3].stageCode}
        startIcon={<SubmissionIcon />}
        startIconDisabled={<SubmissionDisableIcon />}
        onClick={() => {
          nevigate(
            `/simt/auth/siRelated/si/detail/${siCode}/update/SubmissionAndReview`,
            { state: SIInfoResult }
          );
        }}
        disable={currentStage >= stageList[3].id}
      />
      <SelectStageButton
        data-selenium-id='SI_CreateAndUpdate_Update-SelectStage-Box-Box-Grid-Grid-SelectStageButton4'
        content={stageList[4].stageCode}
        startIcon={<ProductionIcon />}
        startIconDisabled={<ProductionDisableIcon />}
        onClick={() => {
          nevigate(
            `/simt/auth/siRelated/si/detail/${siCode}/update/Production`,
            { state: SIInfoResult }
          );
        }}
        disable={currentStage >= stageList[4].id}
      />
      <SelectStageButton
        data-selenium-id='SI_CreateAndUpdate_Update-SelectStage-Box-Box-Grid-Grid-SelectStageButton5'
        content={stageList[5].stageCode}
        startIcon={<PublishedIcon />}
        startIconDisabled={<PublishedDisableIcon />}
        onClick={() => {
          nevigate(
            `/simt/auth/siRelated/si/detail/${siCode}/update/Published`,
            { state: SIInfoResult }
          );
        }}
        disable={currentStage >= stageList[5].id}
      />
      <SelectStageButton
        data-selenium-id='SI_CreateAndUpdate_Update-SelectStage-Box-Box-Grid-Grid-SelectStageButton6'
        content={stageList[6].stageCode}
        startIcon={<ShelvedIcon />}
        onClick={() => {
          nevigate(`/simt/auth/siRelated/si/detail/${siCode}/update/Shelved`, {
            state: SIInfoResult,
          });
        }}
      />
    </Stack>
  );

  return (
    <MainPageFramework id='updateSI_selectPage'>
      {currentStage !== -1 && (
        <CreateAndUpdateMain
          id='updateSI_selectPage_content'
          container={container}
        ></CreateAndUpdateMain>
      )}
    </MainPageFramework>
  );
}

export default SelectStage;

import { Box, CircularProgress } from '@mui/material';
import MarketEditCancel from '@/assets/MarketEditCancel.svg';
import MarketEditSave from '@/assets/MarketEditSave.svg';
import MarketEdit from '@/assets/MarketEdit2.svg';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomEmailBlock from './CustomEmailBlock';
import CustomText from './CustomText';
import CustomContent from './CustomContent';
import CustomDigitInput from './CustomDigitInput';
import CustomCKEditor from './CustomCKEditor';
import CustomLabel from './CustomLabel';
import RichTextEditor from '@/modules/RichTextEditor/RichTextEditor';
import {
  replaceVariablesSymbolsForFrontend,
  replaceVariablesSymbolsForBackend,
  emailVariables,
} from '../../../modules/RichTextEditor/EditorUtils';
import styleModule from "../../../componentsphase2/SIDetail/SaveAndCancelButtonForGeneralInfoSection.module.scss";
import Button from "@mui/material/Button";
import {editEmailTemplate} from "@/actions/SIMT-Email/Template";

function ShowAndEditForAuto(props) {
  const { partTitle, contentDetail, block } = props;

  const [moreOrLess, setMoreOrLess] = useState(true); // true为more， false为less
  const [isEdit, setIsEdit] = useState(false); //展示和编辑状态切换的关键字
  const [isLoading, setIsLoading] = useState(false);

  const [digitInterval, setDigitInterval] = useState(contentDetail.interval); // 修改interval内容
  const [subjectDetail, setSubjectDetail] = useState(contentDetail.subject);
  const [emailContent, setEmailContent] = useState(contentDetail.content); // 修改email内容
  const [hasErr, setHasErr] = useState(false);
  const dispatch = useDispatch();

  // 小标题
  const CustomPartTitle = props => {
    const { children } = props;
    return (
      <Box
        data-selenium-id={`EmailTemplates-CommonModule-ShowAndEditForActionForAuto-${partTitle
          .split(' ')
          .join('')}-CustomPartTitle-Box`}
        sx={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: ' 24px',
          display: 'flex',
          alignItems: 'center',
          color: '#262E35',
          marginRight: '12px',
        }}
      >
        {children}
      </Box>
    );
  };

  // 文本替换，将${} 去掉
  const discardBracket = props => {
    let value = props;
    if (value) {
      return value.replace(/\${/g, '').replace(/}/g, '').replace(/_/g, ' ');
    } else {
      return value;
    }
  };

  // 文本替换，将${} 变为 []
  const textReplace = props => {
    let testDiv = document.createElement('div');
    testDiv.innerHTML = props;
    let value = testDiv.innerText;
    if (value) {
      return value.replace(/\${/g, '[').replace(/}/g, ']').replace(/_/g, ' ');
    } else {
      return value;
    }
  };

  // subjectReplace中内容文本替换，将<span>标签中的 ${} 变为 []
  const subjectReplace = props => {
    let testDiv = document.createElement('div');
    testDiv.innerHTML = props;

    let spanValue = testDiv.getElementsByTagName('span');
    //循环遍历这个数组
    for (const element of spanValue) {
      element.innerHTML = element.innerHTML
        .replace(/\${/g, '[')
        .replace(/}/g, ']')
        .replace(/_/g, ' ');
    }

    return testDiv.innerText;
  };

  const selector = state => {
    return {
      emailDetail: state.Email.emailTemplatesList,
    };
  };
  const { emailDetail } = useSelector(selector);
  // 编辑状态时，点击取消按钮的操作
  const handleCancelClick = () => {
    setDigitInterval(contentDetail.interval);
    setSubjectDetail(contentDetail.subject);
    setEmailContent(contentDetail.content);
    setIsEdit(false);
  };
  // 编辑状态时，点击保存按钮的操作
  const handleSaveClick = () => {
    if (hasErr) {
    } else {
      // 在这里获取所有的数据源 然后更改对应block的数据 再整体传递
      // for of可以修改原数组
      for (const iterator of emailDetail.autoSentEmailByDeadline) {
        if (iterator.block === block) {
          iterator.interval = digitInterval;
          iterator.content = replaceVariablesSymbolsForBackend(
            emailContent,
            emailVariables.deadline
          );
          iterator.subject = replaceVariablesSymbolsForBackend(
            subjectDetail,
            emailVariables.interval
          );
          dispatch(editEmailTemplate(iterator));
        }
        setIsEdit(false);
      }
    }
  };

  // 标题和编辑按钮
  const TopBlock = props => {
    const { title } = props;
    const containerStyle = (justifyContent, width) => {
      return {
        display: 'flex',
        justifyContent: justifyContent,
        width: width,
      };
    };
    const IconButtonStyle = {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    };
    // if (isEdit) {
    //   return (
    //     <Box
    //       sx={{ ...containerStyle('flex-start', '100%'), height: '24px' }}
    //       data-selenium-id={`EmailTemplates-CommonModule-ShowAndEditForActionForAuto-${partTitle
    //         .split(' ')
    //         .join('')}-TopBlock-Box`}
    //     >
    //       <CustomPartTitle>{title}</CustomPartTitle>
    //       <Box
    //         onClick={handleCancelClick}
    //         sx={{ marginRight: '16px', ...IconButtonStyle }}
    //         id='MarketCancel'
    //         data-selenium-id={`EmailTemplates-CommonModule-ShowAndEditForActionForAuto-${partTitle
    //           .split(' ')
    //           .join('')}-TopBlock-MarketCancel`}
    //       >
    //         <MarketEditCancel></MarketEditCancel>
    //       </Box>
    //       <Box
    //         onClick={() => handleSaveClick()}
    //         sx={IconButtonStyle}
    //         id='MarketSave'
    //         data-selenium-id={`EmailTemplates-CommonModule-ShowAndEditForActionForAuto-${partTitle
    //           .split(' ')
    //           .join('')}-TopBlock-MarketSave`}
    //       >
    //         {isLoading ? <CircularProgress size='16px' /> : <MarketEditSave />}
    //       </Box>
    //     </Box>
    //   );
    // } else
      {
      return (
        <Box sx={{ ...containerStyle('flex-start', '100%'), height: '24px' }}>
          <CustomPartTitle>{title}</CustomPartTitle>
          <Box
            onClick={() => setIsEdit(true)}
            sx={{
              marginRight: '16px',
              ...IconButtonStyle,
              '&:hover svg path': {
                fill: '#113D95',
              }
            }}
            id='MarketEdit'
            data-selenium-id='MarketEdit'
          >
            <MarketEdit></MarketEdit>
          </Box>
        </Box>
      );
    }
  };

  if (isEdit === false) {
    return (
      <Box
        style={{
          padding: '12px 20px 8px 20px',
          marginLeft: '-32px',marginTop: '16px',
          position: 'relative',
          borderRadius: '0px 4px 4px 0px',
          background: '#FFF',
          boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
        }}
        data-selenium-id={`EmailTemplates-CommonModule-ShowAndEditForActionForAuto-${partTitle
          .split(' ')
          .join('')}-Box`}
      >
        <div style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: '-8px',
          width: '8px',
          background: '#0052CC',
          borderRadius: '4px 0px 0px 4px',
        }}></div>
        {/* 标题部分 */}
        <TopBlock title={partTitle}></TopBlock>

        {/* Interval*/}
        {/*<CustomEmailBlock title='Interval:' margin='16px 0px 8px 0px'>*/}
          {/*<CustomText>{contentDetail.interval} days</CustomText>*/}
        {/*</CustomEmailBlock>*/}

        {/* Subject*/}
        <CustomEmailBlock title='Subject:' margin='16px 0px 8px 0px'>
          <CustomText>{subjectReplace(contentDetail.subject)}</CustomText>
        </CustomEmailBlock>

        {!moreOrLess ? (
          <>
            <CustomEmailBlock title='Recipients:' margin='8px 0px'>
              <CustomText>{textReplace(contentDetail.recipients)}</CustomText>
            </CustomEmailBlock>
          </>
        ) : (
          <></>
        )}

        {/* Email Content*/}
        <CustomEmailBlock title='Email Content:' margin='8px 0px 6px 0px'>
          <CustomContent
            id={partTitle}
            data-selenium-id={partTitle}
            value={contentDetail.content}
            maxHeight='60px'
            moreOrLess={moreOrLess}
            setMoreOrLess={setMoreOrLess}
          ></CustomContent>
        </CustomEmailBlock>
      </Box>
    );
  } else {
    return (
      <Box
        style={{
          padding: '12px 20px 8px 20px',
          marginLeft: '-32px',marginTop: '16px',
          position: 'relative',
          borderRadius: '0px 4px 4px 0px',
          background: '#FFF',
          boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
        }}
        data-selenium-id={`EmailTemplates-CommonModule-ShowAndEditForActionForAuto-${partTitle
          .split(' ')
          .join('')}-Box`}
      >
        <div style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: '-8px',
          width: '8px',
          background: '#0052CC',
          borderRadius: '4px 0px 0px 4px',
        }}></div>
        {/* 标题部分 */}
        <TopBlock title={partTitle}></TopBlock>

        {/* Interval*/}
        <CustomEmailBlock title='Interval:' margin='16px 0px 8px 0px'>
          <CustomDigitInput
            value={digitInterval}
            handleChange={setDigitInterval}
            min={1}
            max={1825}
            setHasErr={setHasErr}
          ></CustomDigitInput>
        </CustomEmailBlock>

        {/* Subject*/}
        <CustomEmailBlock title='Subject:' margin='8px 0px'>
          <CustomCKEditor
            content={replaceVariablesSymbolsForFrontend(
              subjectDetail,
              emailVariables.interval
            )}
            setContent={setSubjectDetail}
            variables={emailVariables.interval}
          ></CustomCKEditor>
        </CustomEmailBlock>

        <CustomEmailBlock title='Recipients:' margin='8px 0px'>
          <CustomLabel>{discardBracket(contentDetail.recipients)}</CustomLabel>
        </CustomEmailBlock>
        <CustomEmailBlock title='Email Content:' margin='8px 0px'>
          <RichTextEditor
            content={replaceVariablesSymbolsForFrontend(
              emailContent,
              emailVariables.deadline
            )}
            setContent={e => {
              setEmailContent(e);
            }}
            variables={emailVariables.deadline}
          ></RichTextEditor>
        </CustomEmailBlock>

        <CustomEmailBlock title={''} margin='8px 0px'>
          <Box
            className={styleModule.SaveAndCancelButtonBox}
            sx={{
              justifyContent: 'flex-start',
              gap: '12px',
            }}
          >
            <Button
              disableRipple
              onClick={() => handleSaveClick()}
              // disabled={isDisabled}
              className={styleModule.SaveButton}
              sx={{ width: '70px !important', }}
            >Save</Button>
            <Button
              disableRipple
              className={styleModule.CancelButton}
              onClick={() => handleCancelClick()}
              sx={{ width: '70px !important', }}
            >Cancel</Button>
          </Box>
        </CustomEmailBlock>
      </Box>
    );
  }
}
export default ShowAndEditForAuto;

import React, { useEffect, useState } from 'react';
import './index.css';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '../../../components/DataTable';
import {
  StyledTableCellPurple,
  StyledTableCellDisPurple,
  StyledTableCellDisGreen,
  StyledTableCellGreen,
  StyledTableRow, StyledTableCellNormal, StyledBodyCellPurple, StyledBodyCellGreen, TableBox, ReportNoData, TableBox2
} from "../ReportSpecificComponents/SpecialColorTableHeader";
import { findLongestLineSubstring, measureWidth } from "../../../utils/StringWidthMeasure";
import {GetTable3Data, GetTable4Data} from "@/actions/SIMT-Report/Report/ReportSiPipeline";

export function CECATTable(props) {
  const { formatYear = 'FY**' } = props;
  const [loading, setLoading] = useState(false);
  const items = [
    {
      id: 'solicitedSipCount',
      label: 'Solicited Proposal',
      minWidth: '100px',
      maxWidth: '100px',
      isOrder: false,
    },
    {
      id: 'unsolicitedSipCount',
      label: 'Unsolicited Proposal',
      minWidth: '100px',
      maxWidth: '100px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewCeCatIdeaProposal',
      label: 'Idea / Proposal',
      minWidth: '100px',
      maxWidth: '100px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewCeCatAcquired',
      label: 'Acquired',
      minWidth: '50px',
      maxWidth: '100px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewCeCatPaperCommission',
      label: 'Paper Commission',
      minWidth: '125px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewCeCatSubmissionReview',
      label: 'Submission & Review',
      minWidth: '130px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewCeCatProduction',
      label: 'Production',
      minWidth: '70px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewCeCatPublishedFY',
      label: `Published in ${formatYear}`,
      minWidth: '65px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewCeCatPublished',
      label: 'Published',
      minWidth: '65px',
      maxWidth: '150px',
      isOrder: false,
    },
  ];
  const dispatch = useDispatch();
  const { currentDate, table4Data } = useSelector(state => {
    return {
      currentDate: state.Report.selectDate,
      table4Data: state.Report.table4Data,
    };
  });

  //columWidthMap 用以储存表头标签的文本长度
  const [columnWidthMap, setColumnWidthMap] = useState({});
  //columMinWidthMap 用以存储表体每一列数据的最大文本长度
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    for (let item of items) {
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '12px Open Sans');
    }
    widthMap['businessDivision'] = measureWidth(findLongestLineSubstring('Business Division'), '12px Open Sans');
    widthMap['catGroup'] = measureWidth(findLongestLineSubstring('SCA GROUP'), '12px Open Sans');
    widthMap['handlingCE'] = measureWidth(findLongestLineSubstring('HANDLING CE'), '12px Open Sans');
    setColumnMinWidthMap(widthMap);
  }, []);

  //更新 columMinWidthMap
  useEffect(() => {
    if (table4Data && table4Data.length > 0) {
      const maxMap = {};
      for (let i = 0; i < table4Data.length; i++) {
        let row = table4Data[i];
        let dataObj = {
          catGroup: row.catGroup,
          handlingCE: row.handlingCeFullName,

          reportPipelineOverviewCeCatIdeaProposal: row.numIdeaOrProposal ?? '0',
          reportPipelineOverviewCeCatAcquired: row.numAcquired ?? '0',
          reportPipelineOverviewCeCatPaperCommission: row.numPaperCommission ?? '0',
          reportPipelineOverviewCeCatSubmissionReview: row.numSubmissionAndReview ?? '0',
          reportPipelineOverviewCeCatProduction: row.numProduction ?? '0',
          reportPipelineOverviewCeCatPublishedFY: row.numPublishedFy ?? '0',
          reportPipelineOverviewCeCatPublished: row.numPublished ?? '0',

          OAreportPipelineOverviewCeCatIdeaProposal: row.numOA_IdeaOrProposal ?? '0',
          OAreportPipelineOverviewCeCatAcquired: row.numOA_Acquired ?? '0',
          OAreportPipelineOverviewCeCatPaperCommission: row.numOA_PaperCommission ?? '0',
          OAreportPipelineOverviewCeCatSubmissionReview: row.numOA_SubmissionAndReview ?? '0',
          OAreportPipelineOverviewCeCatProduction: row.numOA_Production ?? '0',
          OAreportPipelineOverviewCeCatPublishedFY: row.numOA_Published_Fy ?? '0',
          OAreportPipelineOverviewCeCatPublished: row.numOA_Published ?? '0',
        };
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength) {
              maxMap[key] = value;
            }
          }
        }
      }
      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '16px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [table4Data]);

  // useEffect(() => {
  //   console.log("columnWidthMap:", columnWidthMap);
  // }, [columnWidthMap]);

  // useEffect(() => {
  //   console.log("columnMinWidthMap:", columnMinWidthMap);
  // }, [columnMinWidthMap]);

  function calcAllJournalWidth() {
    let sum = 0;
    for (let item of items) {
      sum += Math.max((columnWidthMap[item.id] ?? 0), columnMinWidthMap[item.id]) + 50;
    }
    return sum;
  }

  function calcOAJournalWidth() {
    let sum = 0;
    for (let item of items) {
      sum += Math.max((columnWidthMap['OA' + item.id] ?? 0), columnMinWidthMap[item.id]) + 50;
    }
    return sum;
  }

  function calcTableWidth() {
    let baseWidth = Math.max((columnWidthMap['businessDivision'] ?? 0), columnMinWidthMap['businessDivision']) + 50 + 2;
    return baseWidth + calcAllJournalWidth() + calcOAJournalWidth();
  }

  useEffect(() => {
    async function getData() {
      if (currentDate != null) {
        await dispatch(GetTable4Data(currentDate));
        setLoading(false);
      }
    }
    setLoading(true);
    getData();
  }, [dispatch, currentDate]);

  const showDataTableSG = () => {
    if (table4Data === undefined || table4Data === null) {
      return [];
    } else return table4Data;
  };

  //Total和Subtotal一行加深
  function DisplayTooltip(props) {
    const { ifTotal } = props;
    if (ifTotal === 'Total'||ifTotal === 'Subtotal')
      return (
        <div align='right'>
          <  >{ifTotal}</  >
        </div>
      );
    else {
      return <div align='left'>{ifTotal}</div>;
    }
  }
  return (
    <TableBox2
      id='BoxTable2'
      data-selenium-id='Report-SIPipeline-CeCatTable-Box'
      dataList={[table4Data]}
      tableKey={'Report-SIPipeline-BusinessTable'}
      footerHeight={0}
    >
      <TableContainer sx={{ maxHeight: '600px' }}>
        <Table
          stickyHeader aria-label='sticky table'
          sx={{
            tableLayout: 'fixed',
            width: 'max-content',
          }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCellNormal
                id='reportPipelineOverviewCeCatStyledTableCellPurple'
                data-selenium-id='Report_PipelineOverview-CeCatStyledTableCellPurple'
                // className='RightBottomBorder'
                align='left'
                rowSpan={2}
                sx={{
                  width: `${Math.max((columnWidthMap['catGroup'] ?? 0), columnMinWidthMap['catGroup']) + 50 + 2}px`,
                  position: 'sticky',
                  left: 0,
                  top: 0,
                  zIndex: 8,
                  backgroundColor: '#DFE4E8',
                }}
              >
                Sca Group
              </StyledTableCellNormal>
              <StyledTableCellNormal
                id='reportPipelineOverviewCeCatStyledTableCellPurple'
                data-selenium-id='Report_PipelineOverview-CeCatStyledTableCellPurple'
                className='RightBottomBorder'
                align='left'
                rowSpan={2}
                sx={{
                  width: `${Math.max((columnWidthMap['handlingCE'] ?? 0), columnMinWidthMap['handlingCE']) + 50 + 2}px`,
                  position: 'sticky',
                  left: `${Math.max((columnWidthMap['catGroup'] ?? 0), columnMinWidthMap['catGroup']) + 50 + 2}px`,
                  top: 0,
                  zIndex: 8,
                  backgroundColor: '#DFE4E8',
                }}
              >
                handling CE
              </StyledTableCellNormal>
              <StyledTableCellPurple
                id='reportPipelineOverviewCeCatAllStyledTableCellPurple'
                data-selenium-id='Report_PipelineOverview-CeCatAllStyledTableCellPurple'
                className='RightBottomBorder'
                align='left'
                colSpan={9}
                sx={{
                  width: `${calcAllJournalWidth()}px`,
                }}
              >
                All journals
              </StyledTableCellPurple>
              <StyledTableCellGreen
                id='reportPipelineOverviewCeCatOaStyledTableCellGreen'
                data-selenium-id='Report_PipelineOverview-CeCatOaStyledTableCellGreen'
                className='RightBottomBorder'
                align='left'
                colSpan={9}
                sx={{
                  width: `${calcOAJournalWidth()}px`,
                }}
              >
                OA journals
              </StyledTableCellGreen>
            </TableRow>
            <TableRow>
              {items.map((item, index) =>
                item.id === 'reportPipelineOverviewCeCatPublished' ? (
                  <StyledTableCellDisPurple
                    id={`reportPipelineOverviewCeCatAllStyledTableCellPurple${index}`}
                    data-selenium-id={`Report-PipelineSubjectTable-CeCatAllStyledTableCellPurple-${index}`}
                    className='RightBottomBorder'
                    align='left'
                    sx={{
                      top: 42,
                      // minWidth: item.minWidth,
                      // maxWidth: item.maxWidth,
                      ...({
                        width: `${Math.max((columnWidthMap[item.id] ?? 0), columnMinWidthMap[item.id]) + 50}px`,
                      }),
                    }}
                    key={item.id}
                    style={{
                      'text-transform': 'capitalize',
                    }}
                  >
                    {item.label}
                  </StyledTableCellDisPurple>
                ) : (
                  <StyledTableCellDisPurple
                    id={`reportPipelineOverviewCeCatAllStyledTableCellPurple${index}`}
                    data-selenium-id={`Report-PipelineSubjectTable-CeCatAllStyledTableCellPurple-${index}`}
                    align='left'
                    sx={{
                      top: 42,
                      // minWidth: item.minWidth,
                      // maxWidth: item.maxWidth,
                      ...({
                        width: `${Math.max((columnWidthMap[item.id] ?? 0), columnMinWidthMap[item.id]) + 50}px`,
                      }),
                    }}
                    key={item.id}
                    style={{ 'text-transform': 'capitalize' }}
                    className='BottomBorder'
                  >
                    {item.label}
                  </StyledTableCellDisPurple>
                )
              )}
              {items.map((item, index) =>
                item.id === 'reportPipelineOverviewCeCatPublished' ? (
                  <StyledTableCellDisGreen
                    id={`reportPipelineOverviewCeCatOaStyledTableCellGreen${index}`}
                    data-selenium-id={`Report-PipelineSubjectTable-CeCatOaStyledTableCellGreen-${index}`}
                    className='RightBottomBorder'
                    align='left'
                    sx={{
                      top: 42,
                      // minWidth: item.minWidth,
                      // maxWidth: item.maxWidth,
                      ...({
                        width: `${Math.max((columnWidthMap['OA' + item.id] ?? 0), columnMinWidthMap[item.id]) + 50}px`,
                      }),
                    }}
                    key={item.id}
                    style={{
                      'text-transform': 'capitalize',
                    }}
                  >
                    {item.label}
                  </StyledTableCellDisGreen>
                ) : (
                  <StyledTableCellDisGreen
                    id={`reportPipelineOverviewCeCatOaStyledTableCellGreen${index}`}
                    data-selenium-id={`Report-PipelineSubjectTable-CeCatOaStyledTableCellGreen-${index}`}
                    align='left'
                    sx={{
                      top: 42,
                      // minWidth: item.minWidth,
                      // maxWidth: item.maxWidth,
                      ...({
                        width: `${Math.max((columnWidthMap['OA' + item.id] ?? 0), columnMinWidthMap[item.id]) + 50}px`,
                      }),
                    }}
                    key={item.id}
                    style={{ 'text-transform': 'capitalize' }}
                    className='BottomBorder'
                  >
                    {item.label}
                  </StyledTableCellDisGreen>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              table4Data != null &&
              table4Data != undefined &&
              table4Data.length != 0
              ? table4Data.map((row, index) => {
                if (row.handlingCeFullName == 'Total' || row.handlingCeFullName == 'Subtotal') {
                  return (
                    <StyledTableRow
                      sx={{
                        height: '64px'
                      }}
                    >
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewCeCatCatGroup${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatCatGroup-${index}`}
                        align='left'
                        sx={{
                          maxWidth: '150px',
                          position: 'sticky',
                          left: 0,
                          zIndex: 6,
                          backgroundColor: '#F1F3F5',
                        }}
                      >
                        <DisplayTooltip ifTotal='' />
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewCeCatBusinessDivision${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatBusinessDivision-${index}`}
                        align='right'
                        sx={{
                          maxWidth: '150px',
                          position: 'sticky',
                          left: `${Math.max((columnWidthMap['catGroup'] ?? 0), columnMinWidthMap['catGroup']) + 50 + 2}px`,
                          zIndex: 6,
                          backgroundColor: '#F1F3F5',
                        }}
                        className='RightBorder ReportTotalTextSize'
                      >
                        <DisplayTooltip ifTotal={row.handlingCeFullName} />
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewCeCatNumIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatNumIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.solicitedSipCount === null
                            ? '0'
                            : row.solicitedSipCount}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewCeCatNumIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatNumIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.unsolicitedSipCount === null
                            ? '0'
                            : row.unsolicitedSipCount}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewCeCatNumIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatNumIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numIdeaOrProposal === null
                            ? '0'
                            : row.numIdeaOrProposal}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewCeCatNumAcquired${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatNumAcquired-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numAcquired === null ? '0' : row.numAcquired}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewCeCatNumPaperCommission${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatNumPaperCommission-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {' '}
                          {row.numPaperCommission === null
                            ? '0'
                            : row.numPaperCommission}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewCeCatNumSubmissionAndReview${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatNumSubmissionAndReview-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numSubmissionAndReview === null
                            ? '0'
                            : row.numSubmissionAndReview}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewCeCatNumProduction${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatNumProduction-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numProduction === null
                            ? '0'
                            : row.numProduction}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewCeCatNumPublishedFY${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatNumPublishedFY-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numPublishedFy === null
                            ? '0'
                            : row.numPublishedFy}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewCeCatNumPublished${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatNumPublished-${index}`}
                        align='left'
                        className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numPublished === null ? '0' : row.numPublished}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewCeCatOAIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatOAIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.oaSolicitedSipCount === null
                            ? '0'
                            : row.oaSolicitedSipCount}
                        </  >
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewCeCatOAIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatOAIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.oaUnsolicitedSipCount === null
                            ? '0'
                            : row.oaUnsolicitedSipCount}
                        </  >
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewCeCatOAIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatOAIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numOA_IdeaOrProposal === null
                            ? '0'
                            : row.numOA_IdeaOrProposal}
                        </  >
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewCeCatOAAcquired${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatOAAcquired-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numOA_Acquired === null
                            ? '0'
                            : row.numOA_Acquired}
                        </  >
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewCeCatOAPaperCommission${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatOAPaperCommission-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numOA_PaperCommission === null
                            ? '0'
                            : row.numOA_PaperCommission}
                        </  >
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewCeCatOASubmissionAndReview${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatOASubmissionAndReview-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numOA_SubmissionAndReview === null
                            ? '0'
                            : row.numOA_SubmissionAndReview}
                        </  >
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewCeCatOAProduction${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatOAProduction-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numOA_Production === null
                            ? '0'
                            : row.numOA_Production}
                        </  >
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewCeCatOAPublishedFY${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatOAPublishedFY-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numOA_Published_Fy === null
                            ? '0'
                            : row.numOA_Published_Fy}
                        </  >
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewCeCatOAPublished${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-CeCatOAPublished-${index}`}
                        align='left'
                        className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numOA_Published === null
                            ? '0'
                            : row.numOA_Published}
                        </  >
                      </StyledBodyCellGreen>
                    </StyledTableRow>
                  );
                }
                return (
                  <StyledTableRow
                    sx={{
                      height: '64px'
                    }}
                  >
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewCeCatCatGroup${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatCatGroup-${index}`}
                      align='left'
                      sx={{
                        maxWidth: '150px',
                        position: 'sticky',
                        left: 0,
                        zIndex: 6,
                        backgroundColor: '#F1F3F5',
                      }}
                    // className='RightBorder'
                    >
                      <DisplayTooltip ifTotal={row.catGroup} />
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewCeCatHandlingCE${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatHandlingCE-${index}`}
                      align='left'
                      sx={{
                        maxWidth: '150px',
                        position: 'sticky',
                        left: `${Math.max((columnWidthMap['catGroup'] ?? 0), columnMinWidthMap['catGroup']) + 50 + 2}px`,
                        zIndex: 6,
                        backgroundColor: '#F1F3F5',
                      }}
                      className='RightBorder'
                    >
                      <DisplayTooltip ifTotal={row.handlingCeFullName} />
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewCeCatNumIdeaOrProposal${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatNumIdeaOrProposal-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.solicitedSipCount === null
                        ? '0'
                        : row.solicitedSipCount}
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewCeCatNumIdeaOrProposal${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatNumIdeaOrProposal-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.unsolicitedSipCount === null
                        ? '0'
                        : row.unsolicitedSipCount}
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewCeCatNumIdeaOrProposal${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatNumIdeaOrProposal-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numIdeaOrProposal === null
                        ? '0'
                        : row.numIdeaOrProposal}
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewCeCatNumAcquired${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatNumAcquired-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numAcquired === null ? '0' : row.numAcquired}
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewCeCatNumPaperCommission${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatNumPaperCommission-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numPaperCommission === null
                        ? '0'
                        : row.numPaperCommission}
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewCeCatNumSubmissionAndReview${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatNumSubmissionAndReview-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numSubmissionAndReview === null
                        ? '0'
                        : row.numSubmissionAndReview}
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewCeCatNumProduction${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatNumProduction-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numProduction === null ? '0' : row.numProduction}
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewCeCatNumPublishedFY${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatNumPublishedFY-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numPublishedFy === null ? '0' : row.numPublishedFy}
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewCeCatNumPublished${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatNumPublished-${index}`}
                      align='left'
                      className='RightBorder ReportNumberTextColor'
                    >
                      {row.numPublished === null ? '0' : row.numPublished}
                    </StyledBodyCellPurple>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewCeCatOAUnSolicitedSipCount${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatOAUnSolicitedSipCount-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.oaSolicitedSipCount === null
                        ? '0'
                        : row.oaSolicitedSipCount}
                    </StyledBodyCellGreen>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewCeCatOASolicitedSipCount${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatOASolicitedSipCount-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.oaUnsolicitedSipCount === null
                        ? '0'
                        : row.oaUnsolicitedSipCount}
                    </StyledBodyCellGreen>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewCeCatOAIdeaOrProposal${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatOAIdeaOrProposal-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numOA_IdeaOrProposal === null
                        ? '0'
                        : row.numOA_IdeaOrProposal}
                    </StyledBodyCellGreen>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewCeCatOAAcquired${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatOAAcquired-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numOA_Acquired === null ? '0' : row.numOA_Acquired}
                    </StyledBodyCellGreen>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewCeCatOAPaperCommission${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatOAPaperCommission-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numOA_PaperCommission === null
                        ? '0'
                        : row.numOA_PaperCommission}
                    </StyledBodyCellGreen>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewCeCatOASubmmissionAndReview${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatOASubmmissionAndReview-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numOA_SubmissionAndReview === null
                        ? '0'
                        : row.numOA_SubmissionAndReview}
                    </StyledBodyCellGreen>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewCeCatOAProduction${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatOAProduction-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numOA_Production === null
                        ? '0'
                        : row.numOA_Production}
                    </StyledBodyCellGreen>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewCeCatOAPublishedFY${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatOAPublishedFY-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numOA_Published_Fy === null
                        ? '0'
                        : row.numOA_Published_Fy}
                    </StyledBodyCellGreen>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewCeCatOAPublished${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-CeCatOAPublished-${index}`}
                      align='left'
                      className='RightBorder ReportNumberTextColor'
                    >
                      {row.numOA_Published === null
                        ? '0'
                        : row.numOA_Published}
                    </StyledBodyCellGreen>
                  </StyledTableRow>
                );
              })
              : !loading && (
                <ReportNoData id='reportSiOverviewSGNoResultTableCell' />
              )}
            <TableRow></TableRow>
          </TableBody>
        </Table>
        {loading && (
          <Box
            data-selenium-id='Report_Si_Overview-LoadingBox'
            sx={{
              width: 'auto',
              marginTop: '150px',
              marginLeft: '50%',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </TableContainer>
    </TableBox2>
  );
}

export const MONTH = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const FY_TRENDS_OF_SI_ACQUISITION = 'FY Trends of SI Acquisition'; // need to be removed
export const SUBMISSION_AND_ACCEPTANCE_YTD_AGAINST_FY_TARGET =
'Article Progress Towards FY Target in ';
export const SUBMISSION_ACCEPTANCE_AND_PUBLICATION_PERFORMANCE_OF_INDIVIDUAL_BUSINESS_DIVISION =
  'Submission, Acceptance and Publication Performance of Individual Business Division in ';
export const SUBMISSION_ACCEPTANCE_AND_PUBLICATION_PERFORMANCE_IN =
  'Submission, Acceptance and Publication Performance in ';
export const FY_TRENDS_OF_ACTIVE_SI = 'FY Trends of Active SI';
export const SUBJECT_GROUP = 'Subject Group';
export const BUSINESS_DIVISION = 'Business Division';

export const BUS_DIV = 'busDiv';

export const POST = 'POST';
export const GET = 'GET';

export function getCurrentTime() {
  // format: _MMDDYYYY_HHMMSS
  let yy = new Date().getFullYear();
  let mm =
    new Date().getMonth() + 1 < 10
      ? '0' + new Date().getMonth()
      : new Date().getMonth();
  let dd =
    new Date().getDate() < 10
      ? '0' + new Date().getDate()
      : new Date().getDate();
  let hh =
    new Date().getHours() < 10
      ? '0' + new Date().getHours()
      : new Date().getHours();
  let mf =
    new Date().getMinutes() < 10
      ? '0' + new Date().getMinutes()
      : new Date().getMinutes();
  let ss =
    new Date().getSeconds() < 10
      ? '0' + new Date().getSeconds()
      : new Date().getSeconds();
  return '_' + mm + dd + yy + '_' + hh + mf + ss;
}

import {PERMISSIONS} from "@/constant/permission";

const userPermissions = localStorage.getItem('userPermissions'); //权限管理

const createRow = (
  id,
  label,
  minWidth,
  maxWidth,
  isOrder = true,
  align = 'left',
  show = true,
  stick = undefined,
  stickPX = userPermissions?.includes(PERMISSIONS.SI_EXPORT) ||
  userPermissions?.includes(PERMISSIONS.CHINA_MARKETING_WRITE)
    ? '90px'
    : '0px'
) => {
  const userPermissions = localStorage.getItem('userPermissions');
  const defaultStickPX = userPermissions?.includes(PERMISSIONS.SI_WRITE) ? '90px' : '0px';
  return {
    id,
    label,
    minWidth,
    maxWidth,
    isOrder,
    align,
    show,
    stick,
    stickPX: stickPX || defaultStickPX,
  };
};

const items = [
  createRow(
    'siCode',
    'SI Code',
    '180px',
    '220px',
    true,
    'left',
    userPermissions?.includes(PERMISSIONS.SI_EXPORT),
    '90px'
  ),
  createRow('journalCode', 'Journal Code', '180px', '180px', true, 'left'),
  createRow('journalName', 'Journal Name', '180px', '340px', true, 'left'),
  createRow('onlineISSN', 'Online ISSN', '130px', '200px', true), //CT2287
  createRow(
    'specialIssueType',
    'SI Publication Format', //CT-97 将Special Issue Type改为SI Publication Format
    '243px',
    '243px',
    true,
    'left'
  ),
  createRow(
    'specialIssueTitle',
    'Special Issue Title',
    '243px',
    '341px',
    true,
    'left'
  ),
  createRow('leadGeFullName', 'Lead GE', '180px', '180px', false, 'left'),
  createRow('coGeFullName', 'Co GE', '180px', '180px', false, 'left'),

  createRow('stage', 'Stage', '105px', '180px', true, 'left'),

  createRow('handlingCe', 'Handling CE', '180px', '180px', true, 'left'),
  createRow('groupTitle', 'Group/Title', '105px', '180px', true, 'left'),

  createRow('pdPublisher', 'PD Publisher', '180px', '220px', true, 'left'),
  createRow('subjectGroup', 'Subject Group', '205px', '240px', true, 'left'),
  createRow('siSource', 'SI Source', '160px', '180px', true, 'left'),
  createRow('ideaDate', 'Idea Date', '160px', '180px', true, 'left'),
  createRow('acquiredDate', 'Acquired Date', '180px', '180px', true, 'left'),
  createRow(
    'openForSubmissionDate',
    'Open for Submission Date',
    '180px',
    '280px',
    true
  ),
  createRow(
    'expectedSubmissionDeadline',
    'Submission Deadline',
    '180px',
    '180px',
    true,
    'left'
  ),
  createRow(
    'expectedAcceptanceDate',
    'Expected Acceptance Date',
    '180px',
    '180px',
    true,
    'left'
  ),
  createRow(
    'expectedPublicationDate',
    'Expected Publication Date',
    '180px',
    '180px',
    true,
    'left'
  ),
  createRow(
    'deadlinePassed',
    'Deadline Passed',
    '105px',
    '105px',
    false,
    'left'
  ),
  createRow(
    'closedForSubmission',
    'Closed for submission',
    '150px',
    '150px',
    true,
    'left'
  ),
  createRow(
    'paperCommissioningMethod',
    'Paper Commissioning Method',
    '0px',
    '500px',
    true,
    'left'
  ),
  createRow('workflow', 'Workflow', '160px', '160px', true, 'left'),
  createRow(
    'previousHandlingCe',
    'Previous Handling CE',
    '200px',
    '200px',
    false,
    'left'
  ),

  createRow(
    'numberOfRecruitedArticles',
    'Recruited',
    '130px',
    '130px',
    true,
    'left'
  ),
  createRow(
    'numberOfSubmittedArticles',
    'Submitted',
    '130px',
    '130px',
    true,
    'left'
  ),
  createRow(
    'numberOfAcceptedArticles',
    'Accepted',
    '130px',
    '130px',
    true,
    'left'
  ),
  createRow(
    'numberOfRejectedArticles',
    'Rejected',
    '130px',
    '130px',
    true,
    'left'
  ),
  createRow(
    'numberOfArticlesInProgress',
    'In Progress',
    '130px',
    '130px',
    true,
    'left'
  ),
  createRow('volume', 'Volume #', '130px', '130px', false, 'left'),
  createRow('issue', 'Issue #', '130px', '130px', false, 'left'),
  createRow(
    'actualPublicationDate',
    'Actual Publication Date',
    '180px',
    '180px',
    false,
    'left'
  ),
  createRow(
    'numberOfOOArticle',
    'Number of OO Article',
    '160px',
    '160px',
    false,
    'left'
  ),
  createRow(
    'numberOfOAArticle',
    'Number of OA Article',
    '160px',
    '160px',
    false,
    'left'
  ),
  createRow('outputRate', 'Output Rate', '130px', '130px', false, 'left'),
  createRow(
    'lastActionDate',
    'Last Action Date',
    '180px',
    '180px',
    true,
    'left'
  ),
];

const getItems = () => {
  const userPermissions = localStorage.getItem('userPermissions');
  const newitems = [
    createRow(
      'siCode',
      'SI Code',
      '142px',
      '142px',
      true,
      'left',
      userPermissions?.includes(PERMISSIONS.SI_EXPORT),
      true,
      userPermissions?.includes(PERMISSIONS.SI_EXPORT) ||
        userPermissions?.includes(PERMISSIONS.CHINA_MARKETING_WRITE)
        ? '130px'
        : '0px'
    ),
    createRow('journalCode', 'Journal Code', '60px', '180px', true, 'left'),
    createRow('journalName', 'Journal Name', '180px', '340px', true, 'left'),
    createRow('onlineISSN', 'Online ISSN', '130px', '200px', true), //CT2287
    createRow(
      'specialIssueType',
      'SI Publication Format', //CT-97 将Special Issue Type改为SI Publication Format
      '243px',
      '243px',
      true,
      'left'
    ),
    createRow(
      'specialIssueTitle',
      'Special Issue Title',
      '260px',
      '280px',
      true,
      'left'
    ),
    createRow('leadGeFullName', 'Lead GE', '180px', '180px', false, 'left'),
    createRow('coGeFullName', 'Co GE', '180px', '180px', false, 'left'),

    createRow('stage', 'Stage', '105px', '180px', true, 'left'),

    createRow('handlingCe', 'Handling CE', '180px', '180px', true, 'left'),
    createRow('groupTitle', 'Group/Title', '105px', '180px', true, 'left'),

    createRow('pdPublisher', 'PD Publisher', '180px', '220px', true, 'left'),
    createRow('ppPublisher', 'PP Publisher', '180px', '220px', true, 'left'),
    createRow('subjectGroup', 'Subject Group', '205px', '240px', true, 'left'),
    createRow('siSource', 'SI Source', '160px', '180px', true, 'left'),
    createRow('ideaDate', 'Idea Date', '160px', '180px', true, 'left'),
    createRow('acquiredDate', 'Acquired Date', '180px', '180px', true, 'left'),
    createRow(
      'openForSubmissionDate',
      'Open for Submission Date',
      '180px',
      '280px',
      true
    ),
    createRow(
      'expectedSubmissionDeadline',
      'Submission Deadline',
      '180px',
      '180px',
      true,
      'left'
    ),
    createRow(
      'expectedAcceptanceDate',
      'Expected Acceptance Date',
      '180px',
      '260px',
      true,
      'left'
    ),
    createRow(
      'expectedPublicationDate',
      'Expected Publication Date',
      '180px',
      '260px',
      true,
      'left'
    ),
    createRow(
      'deadlinePassed',
      'Deadline Passed',
      '105px',
      '105px',
      false,
      'left'
    ),
    createRow(
      'closedForSubmission',
      'Closed for submission',
      '150px',
      '150px',
      true,
      'left'
    ),
    createRow(
      'paperCommissioningMethod',
      'Paper Commissioning Method',
      '260px',
      '260px',
      true,
      'left'
    ),
    createRow('workflow', 'Workflow', '160px', '160px', true, 'left'),
    createRow(
      'previousHandlingCe',
      'Previous Handling CE',
      '220px',
      '260px',
      false,
      'left'
    ),

    createRow(
      'numberOfRecruitedArticles',
      'Recruited',
      '130px',
      '130px',
      true,
      'left'
    ),
    createRow(
      'numberOfSubmittedArticles',
      'Submitted',
      '130px',
      '130px',
      true,
      'left'
    ),
    createRow(
      'numberOfAcceptedArticles',
      'Accepted',
      '130px',
      '130px',
      true,
      'left'
    ),
    createRow(
      'numberOfRejectedArticles',
      'Rejected',
      '130px',
      '130px',
      true,
      'left'
    ),
    createRow(
      'numberOfArticlesInProgress',
      'In Progress',
      '130px',
      '130px',
      true,
      'left'
    ),
    createRow('volume', 'Volume #', '130px', '130px', false, 'left'),
    createRow('issue', 'Issue #', '130px', '130px', false, 'left'),
    createRow(
      'actualPublicationDate',
      'Actual Publication Date',
      '180px',
      '260px',
      true,
      'left'
    ),
    createRow(
      'numberOfOOArticle',
      'Number of OO Article',
      '160px',
      '160px',
      false,
      'left'
    ),
    createRow(
      'numberOfOAArticle',
      'Number of OA Article',
      '160px',
      '160px',
      false,
      'left'
    ),
    createRow('outputRate', 'Output Rate', '130px', '130px', false, 'left'),
    createRow('createdate', 'Create Date', '180px', '180px', true, 'left'),
    createRow(
      'lastActionDate',
      'Last Action Date',
      '180px',
      '180px',
      true,
      'left'
    ),
  ];
  newitems.forEach(item => {
    item.label = item.label.toUpperCase();
  });
  return newitems;
};

export { getItems };
items.forEach(item => {
  item.label = item.label.toUpperCase();
});
export default items;

/* eslint-disable indent */
import * as TYPES from '../actions/SIMT-SIP/SIPChecklistSubmitted/SIPChecklistAction.js';
import {
  scrollIntoViewId,
  clickCancelButtonString,
} from '@/componentsphase2/FlaggedGESynchronizationAndVerificationTable';
import {ChecklistStatus} from "@/components/Form/form.d";

export const EMPTY_CHECKLIST_DATA = {
  sipCode: '',
  status: null,
  immediateDecision: null,
  overallComment: null,
  details: {},
  version: 0,
  locked: true,
  lastModifyUser: 0,
  lastModifyTime: '',
  geList: [],
  checklistVersion: 1,
};

const initStage = {
  SIPChecklistDetail: {
    ...EMPTY_CHECKLIST_DATA,
  },
  iniChecklistDetail: {
    ...EMPTY_CHECKLIST_DATA,
  },
  SIPChecklistEditingInfo: {}, // @Deprecated
  SnackbarShow: false,
  SnackbarId: null,
  proceedResult: null,// @Deprecated
  proceeding: false, // @Deprecated
  firstErrorInfo: null,// @Deprecated
  totalError: false, // @Deprecated
  [scrollIntoViewId]: null, // @Deprecated
  proposer: null,
  [clickCancelButtonString]: 0, // @Deprecated
  isFlagChanged: false, // @Deprecated
};

export function deepMerge(obj1, obj2) {
  obj1 = obj1 || {};
  let output = Object.assign({}, obj1);

  for (let key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (isObject(obj2[key]) && obj1.hasOwnProperty(key) && isObject(obj1[key])) {
        output[key] = deepMerge(obj1[key], obj2[key]);
      } else if (Array.isArray(obj2[key]) && Array.isArray(obj1[key])) {
        output[key] = obj2[key].map((e2,i) => deepMerge(obj1[key][i]??{}, e2));
      } else {
        output[key] = obj2[key];
      }
    }
  }

  return output;
}

/**
 * if field 2 is not null or undefined, merge it to field 1
 * @param obj1
 * @param obj2
 * @returns {unknown}
 */
export function deepMergeIf(obj1, obj2) {
  obj1 = obj1 || {};
  let output = Object.assign({}, obj1);

  for (let key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (isObject(obj2[key]) && obj1.hasOwnProperty(key) && isObject(obj1[key])) {
        output[key] = deepMerge(obj1[key], obj2[key]);
      } else if (Array.isArray(obj2[key]) && Array.isArray(obj1[key])) {
        output[key] = obj2[key].map((e2,i) => deepMerge(obj1[key][i]??{}, e2));
      } else if(obj2[key] !== null && obj2[key] !== undefined){
        output[key] = obj2[key];
      }
    }
  }

  return output;
}

function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export default function SIPChecklistReducer(state = initStage, action) {
  const { type, data } = action;
  switch (type) {
    case TYPES.SET_TOTAL_ERROR:
      return {
        ...state,
        totalError: data,
      };
    case TYPES.SET_SNACKBAR_ID:
      return {
        ...state,
        SnackbarId: data,
      };
    case TYPES.SET_FIRSET_ERROR:
      return {
        ...state,
        firstErrorInfo: data,
      };
    case TYPES.SET_PROCEEDING:
      return {
        ...state,
        proceeding: data,
      };
    case TYPES.CLEAR_PROCEED_RESULT:
      return {
        ...state,
        proceedResult: data,
      };
    case TYPES.EXCUTE_PROCEED: {
      return {
        ...state,
        proceedResult: data,
      };
    }
    case TYPES.SET_GE_ITEMVALUE: {
      let newGEList = state.SIPChecklistDetail.geList.concat();
      newGEList[data.index][data.witch] = data.newValue;
      return {
        ...state,
        SIPChecklistDetail: {
          ...state.SIPChecklistDetail,
          geList: newGEList,
        },
      };
    }
    case TYPES.SET_ITEMVALUE:
      return {
        ...state,
        SIPChecklistDetail: {
          ...state.SIPChecklistDetail,
          [data.witch]: data.newValue,
        },
      };
    case TYPES.SET_SNACKBAR_SHOW:
      return {
        ...state,
        SnackbarShow: data,
      };
    case TYPES.SAVE_FOR_LATER:
      return {
        ...state,
        SIPChecklistDetail: data,
        iniChecklistDetail: JSON.parse(JSON.stringify(data)),
      };
    case TYPES.SET_SIP_CHECKLIST_DETAIL:
      return {
        ...state,
        // SIPChecklistDetail: deepMerge(state.SIPChecklistDetail, JSON.parse(JSON.stringify(data))),
        SIPChecklistDetail: JSON.parse(JSON.stringify(data)),
        iniChecklistDetail: JSON.parse(JSON.stringify(data)),
      };
    case TYPES.SET_CHECKLIST_VERSION:
      return {
        ...state,
        SIPChecklistDetail: {
          ...state.SIPChecklistDetail,
          version: data,
        },
      };
    case TYPES.SET_SIP_CHECKLIST_EDITING_INFO:
      return {
        ...state,
        SIPChecklistEditingInfo: {
          ...state.SIPChecklistEditingInfo,
          ...data,
        },
      };
    case TYPES.SET_SCROLLINTOVIEW:
      return {
        ...state,
        [scrollIntoViewId]: data ?? null,
      };
    case TYPES.INI_REDUCER:
      return {
        ...initStage,
      };
    case TYPES.GET_PROPOSER:
      return {
        ...state,
        proposer: data,
      };
    case TYPES.CANCEL_EDIT:
      return {
        ...state,
        [clickCancelButtonString]: Date.now(),
      };
    case TYPES.SET_IS_FLAG_CHANGED:
      return {
        ...state,
        isFlagChanged: data,
      };
    case TYPES.CHANGE_STATUS:
      return {
        ...state,
        SIPChecklistDetail: {
          ...state.SIPChecklistDetail,
          status: state.SIPChecklistDetail.status == null ? null: data,
        },
      };
    default:
      return state;
  }
}

import React from 'react';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
  ThemeProvider,
  TextField,
  createTheme,
} from '@mui/material';
import { styled } from '@mui/styles';
import { MiniBoldFont, SmallFont } from '@/components/FontStyle';

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import CanselListItem from '@/assets/CanselListItem.svg';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';

const theme = createTheme({
  palette: {
    error: {
      main: '#EE5350',
    },
  },
});

export const CustomTextFieldTypeTwo = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    borderRadius: '4px',
    boxSizing: 'border-box',
    width: '100%',
    padding: '6.5px 12px',
    ...SmallFont,
  },
  '& .MuiOutlinedInput-input': {
    padding: '0px 0px',
    '&::placeholder': {
      ...SmallFont,
      color: '#BCC5CF',
      opacity: '1',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #DFE4E8',
  },
  '& .MuiOutlinedInput-root.Mui-error fieldset': {
    border: '2px solid #EE5350',
    borderRadius: '4px',
  },
});

export const CustomTextField = props => {
  const {
    id = 'test',
    value = '',
    minRows = 1,
    maxRows = 1,
    placeholder = '',
    setValue = () => { },
    handleIconButtonClick = () => { },
    needEndAdornment = false,
    limitLength = 10000,
    errorMonitor = false,
    needEmptyCheck = false,
  } = props;
  return (
    <ThemeProvider
      theme={theme}
      data-selenium-id='SIP_AcceptPage_Submodule-CustomInputList-ThemeProvider'
    >
      <FormControl
        data-selenium-id='SIP_AcceptPage_Submodule-CustomInputList-FormControl'
        error={
          props.error ||
          value.length > limitLength ||
          (errorMonitor && needEmptyCheck && value === '')
        }
        sx={{ width: '100%' }}
      >
        {needEndAdornment ? (
          <CustomTextFieldTypeTwo
            id={id}
            data-selenium-id={`SIP_AcceptPage_Submodule-CustomInputList-TextFieldType1-${id}`}
            value={value}
            multiline
            minRows={minRows}
            maxRows={maxRows}
            placeholder={placeholder}
            onChange={e => {
              setValue(e.target.value);
            }}
            error={
              props.error ||
              value.length > limitLength ||
              (errorMonitor && value === '')
            }
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position='end'
                  data-selenium-id='SIP_AcceptPage_Submodule-CustomInputList-TextFieldType-Adornment'
                >
                  <IconButton
                    id={`${id}-endAdornment-botton`}
                    data-selenium-id={`SIP_AcceptPage_Submodule-CustomInputList-TextFieldType-${id}-endAdornment-botton`}
                    onClick={() => {
                      handleIconButtonClick();
                    }}
                    sx={{ padding: '0px' }}
                  >
                    <CanselListItem></CanselListItem>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <CustomTextFieldTypeTwo
            id={id}
            data-selenium-id={`SIP_AcceptPage_Submodule-CustomInputList-TextFieldType2-${id}`}
            value={value}
            multiline
            minRows={minRows}
            maxRows={maxRows}
            placeholder={placeholder}
            onChange={e => {
              setValue(e.target.value);
            }}
            error={
              props.error ||
              value.length > limitLength ||
              (errorMonitor && needEmptyCheck && value === '')
            }
          />
        )}
      </FormControl>
    </ThemeProvider>
  );
};

export const CustomInputList = props => {
  const {
    id = 'test',
    value = [],
    setValue = value => { },
    maxRows = 1,
    minItems = 1,
    maxItems = 1,
    limitLength = 10000,
    errorMonitor = false,
    needEmptyCheck = false,
    snackbarMessage = '',
    exceedErrText = '',
    setError = () => { },
    bottomHelperText = '',
  } = props;

  const dispatch = useDispatch();

  //用于检查是否达成n个不为空的要求 仅在needEmptyCheck = true的时候调用
  const isUptostandard = () => {
    let count = 0;
    for (let i = 0; i < value.length; i++) {
      if (value[i] === '') {
        continue;
      }
      count++;
    }
    return count >= minItems;
  };

  //遍历value的所有值判断是否存在某个值超过了限定的字数
  const isExceed = () => {
    for (let i = 0; i < value.length; i++) {
      if (value[i].length > limitLength) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setError(isExceed() || (needEmptyCheck && !isUptostandard()));
  }, [value]);

  return (
    <Box data-selenium-id='SIP_AcceptPage_Submodule-CustomInputList-Box'>
      <Stack
        spacing={0.5}
        data-selenium-id='SIP_AcceptPage_Submodule-CustomInputList-Stack'
      >
        {value.map((item, index) => (
          <CustomTextField
            id={`${id}-${index}`}
            data-selenium-id={`SIP_AcceptPage_Submodule-CustomInputList-TextField-${id}-${index}`}
            key={`${id}-${index}`}
            value={item}
            maxRows={maxRows}
            setValue={newValue => {
              let newArr = value.concat();
              newArr.splice(index, 1, newValue);
              setValue(newArr);
            }}
            handleIconButtonClick={
              index >= minItems
                ? () => {
                  let newArr = value.concat();
                  newArr.splice(index, 1);
                  setValue(newArr);
                }
                : () => { }
            }
            needEndAdornment={index >= minItems}
            limitLength={limitLength}
            error={errorMonitor && needEmptyCheck && !isUptostandard()}
            helperText={''}
          />
        ))}
      </Stack>
      <Box sx={{ marginTop: '8px', height: '18px', width: '100%' }}>
        <Box
          data-selenium-id='SIP_AcceptPage_Submodule-CustomInputList-InnerBox'
          sx={{
            ...MiniBoldFont,
            color: '#154AB6',
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={() => {
            if (value.length === maxItems) {
              dispatch(
                setSnackbarMessageAndOpen(snackbarMessage, {}, SEVERITIES.error)
              );
              return;
            }
            let newArr = value.concat();
            newArr.push('');
            setValue(newArr);
          }}
        >
          + Add
        </Box>
      </Box>
    </Box>
  );
};

import './index.css';
import React from 'react';
import { Button, Box, Typography, Alert, TextField } from '@mui/material';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

function UserLockPage() {
  let navigate = useNavigate();
  const location = useLocation();

  const backLogin = () => {
    navigate('/simt/login');
  };

  if (location.state != null) {
    return (
      <Box
        data-selenium-id='Login_UserLocked-Box'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mt: '136px',
          mb: '231px',
        }}
      >
        <Box
          data-selenium-id='Login_UserLocked-Box-Box'
          width='632px'
          sx={{ border: '1px solid #dddddd', borderRadius: '4px' }}
        >
          <Box
            data-selenium-id='Login_UserLocked-Box-Box-Box'
            sx={{ width: '632px', background: '#113161', padding: '47px 0' }}
          >
            <Typography
              data-selenium-id='Login_UserLocked-Typography'
              sx={{
                fontSize: '34px',
                lineHeight: '46px',
                textAlign: 'center',
                color: '#fff',
              }}
            >
              Log in to your SIMT account
            </Typography>
          </Box>

          <Box
            data-selenium-id='Login_UserLocked-Box-Box-SecondBox'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Alert
              severity='error'
              sx={{ width: '568px', mt: '16px' }}
              data-selenium-id='Login_UserLocked-Alert'
            >
              The following account was locked for 24 hours. <br />
              Please contact your administrator to unlock the account
              immediately.
            </Alert>
            <TextField
              id='userLockPageEmailAddressInput'
              data-selenium-id='Login_UserLocked-EmailAddressInput'
              disabled
              label='Email Address'
              defaultValue={location.state.email}
              variant='filled'
              sx={{
                width: '600px',
                height: '56px',
                background: '#ebebeb',
                mt: '16px',
              }}
            />
            <Button
              id='userLockPageBackButton'
              data-selenium-id='Login_UserLocked-BackButton'
              variant='contained'
              onClick={backLogin}
              color={'lightBlue'}
              sx={{ m: '16px 0' }}
            >
              BACK
            </Button>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Navigate to='/simt/login' data-selenium-id='Login_UserLocked-Navigate' />
    );
  }
}

export default UserLockPage;

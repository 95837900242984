import { StackedVerticalBarChartOptions } from '@/pages/Report/chartUtils/chartOptions';
import { calcBarGapPercentage } from '@/pages/Report/chartUtils';

/**
 * overwrite the default configuration
 */
const BAR_WIDTH_MAX = 48;
const Y_AXIS_WIDTH = 30;
const CATEGORY_GROUP_GAP = 20;
const BAR_GAP_PX_WIDTH = 2;

const NUM_BARS_IN_A_GROUP = 4;
const BAR_COLOR = ["#FF8180", "#FFDDDD", "#FFB152", "#FFF0DD"];
const BAR_BORDER_COLOR = ['#FF8180', '#EE5350', '#FFB152', '#FFB152'];

export class ArticlesOverviewTargetOptions extends StackedVerticalBarChartOptions {
  constructor() {
    super();
    this.numBarsInGroup = NUM_BARS_IN_A_GROUP;
  }

  getAllBarsWidth(){
    return this.width - this.chartPaddingLeft - this.chartPaddingRight - Y_AXIS_WIDTH;
  }

  getBarSerie({ index }) {
    const barColor = this.getBarColor(index);
    const borderColor = this.getBarBorderColor(index);

    return {
      type: 'bar',
      emphasis: {
        disabled: true,
      },
      barMinHeight: 12,
      itemStyle: {
        normal: {
          borderWidth: 1,
          borderType: 'solid',
          borderColor: borderColor,
        },
      },
      label: {
        show: false,
      },
      color: barColor,
      barWidth: this.getBarWidth(),
      barGap: this.getBarGap(),
    };
  }

  getBarColor(index) {
    return BAR_COLOR[index];
  }

  getBarBorderColor(index) {
    return BAR_BORDER_COLOR[index];
  }

  // to simplify the code?
  getBarSeries() {
    return [
      this.getBarSerie({
        index: 0,
      }),
      this.getBarSerie({
        index: 1,
      }),
      this.getBarSerie({
        index: 2,
      }),
      this.getBarSerie({
        index: 3,
      }),
    ];
  }


  getTooltip() {
    return [
      {
        ...this.tooltip,
        // format value as percentage
        formatter: params => {
          let dataStr = `<div style="padding: 0; margin: 0;">
            <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 0 0 2.5px 0;">${
              params.name
            }</p>
            <p style="font-size: 10px; font-weight: 400;color: #848484; line-height: 14px; margin: 0;">${
              params.seriesName
            }&nbsp;:&nbsp;${params.data[params.encode.y[0]] + '%'}</p>
          </div>`;
          return dataStr;
        },
      },
    ];
  }

  getBarWidth() {
      // console.log('numOfGroups: ', this.numOfGroups);
      const allBarsWidth = this.getAllBarsWidth();
      const numOfGroups = this.numOfGroups;
      const numBarsInGroup = this.numBarsInGroup;
      const barGap = BAR_GAP_PX_WIDTH;
      
      const maxWidth = BAR_WIDTH_MAX;
      const groupWidth = (allBarsWidth - CATEGORY_GROUP_GAP * (numOfGroups - 1)) / numOfGroups;
      // console.log('groupWidth', groupWidth);
      
      let barWidth = (groupWidth - barGap * (numBarsInGroup - 1)) / numBarsInGroup;

      // apply the max constraints
      barWidth = barWidth > maxWidth ? maxWidth : barWidth;
      // console.log('barWidth', barWidth);

      return barWidth;
  }

  getBarGap() {
    const barWidth = this.getBarWidth();
    const barGapinPercentage = calcBarGapPercentage(BAR_GAP_PX_WIDTH, barWidth);
    return barGapinPercentage;
  }

  getXAxisLabel() {
    const baseAxisLabel = super.getXAxisLabel();
    const isDownload = this.hasTitle;
    const width = (this.getBarWidth() + BAR_GAP_PX_WIDTH) * this.numBarsInGroup + CATEGORY_GROUP_GAP * 0.7;
    // If download, break label text into lines
    const overflow = isDownload ? 'breakAll' : 'truncate';
    const verticalAlign = isDownload ? 'top' : 'middle';

    const newAxisLabel = {
      ...baseAxisLabel,
      width,
      overflow, 
      verticalAlign
    };

    return newAxisLabel;
  }

  // format y-axis value as percentage
  getYAxis(){
    let baseYAxis =  super.getYAxis();
    const yAxis = {...baseYAxis, axisLabel: {
      ...baseYAxis.axisLabel,
      formatter: function (value, index) {
          return value + '%';
        },
    }};
    return yAxis;
  }

  // overwritte the super method
  getSelectedLegend() {
    return [
      {
        icon: 'circle',
        itemGap: this.getLengendGap(),
        width: '1015px',
        selectedMode: false,
        textStyle: {
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontWeight: '400',
          color: '#596A7C',
        },
        orient: 'horizontal',
        bottom: 40,
      },
    ];
  }

  customLabelFormatter = params => {
    return params.data[params.encode.y[0]] === 0
      ? ''
      : params.data[params.encode.y[0]];
  };

  setWidth(width) {
    this.width = width;
    return this;
  }

  getLengendGap() {
    let legendGap = (this.width - 120 - 107 * 4) / (this.numBarsInGroup - 1);
    legendGap = legendGap > 100 ? 100 : legendGap <= 0 ? 1 : legendGap;
    return legendGap;
  }

  /**
   * set the number of Subject groups
  */
  setNumOfGroups(numOfGroups) {
    // console.log('setting numOfGroups...', numOfGroups);
    this.numOfGroups = numOfGroups;
    return this;
  }
  
}

import { Box, Grid } from '@mui/material';
import SubjectGroupTable from './SubjectGroupTable';
import BusinessDivisionTable from './BusinessDivisionTable';
import React from 'react';
import { TableGroupBox, TableLabel } from '../../ReportSpecificComponents/SpecialColorTableHeader';

export default function MyTable(props) {
  const { title } = props;

  return (
    <TableGroupBox>
      <TableLabel>{title}</TableLabel>
      {title === 'Subject Group' ? (
        <SubjectGroupTable></SubjectGroupTable>
      ) : (
        <BusinessDivisionTable></BusinessDivisionTable>
      )}
    </TableGroupBox>
  );
}

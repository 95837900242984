/*
 * @Author: jojo 903497174@qq.com
 * @Date: 2023-08-11 16:14:50
 * @LastEditors: jojo 903497174@qq.com
 * @LastEditTime: 2023-09-06 12:48:49
 * @FilePath: \simt_front\src\pages\CATJournal\Detail\compontents\InfoItem.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Box } from '@mui/material';
import React from 'react';

const handleUrlBoxClicked = (url) => {
  // 访问链接的逻辑
  window.open(url, '_blank');
};

export default function InfoItem(props) {
  const {
    itemTitle = 'Item Title',
    itemInfo = 'Item Info',
    itemType = 'text',
  } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        width: '518px',
        gap: '8px',
      }}>
      <Box
        sx={{
          width: '224px',
          'font-family': 'Open Sans',
          'font-size': '14px',
          'font-weight': '400',
          'line-height': '20px',
          'letter-spacing': '0em',
          'text-align': ' left',

          color: '#596A7C',
        }}>{itemTitle}:</Box>
      {
        itemType === 'url'? (itemInfo !== ''?
          <Box
            sx={{
              width: '286px',
              height: '20px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              'font-family': 'Open Sans',
              'font-size': '14px',
              'font-weight': '600',
              'line-height': '20px',
              'letter-spacing': '0em',
              'text-align': 'left',
              color: '#154AB6',
              cursor: 'pointer',

            }}
            onClick = {() => handleUrlBoxClicked(itemInfo)}      
          >{itemInfo}</Box>:<></>)
          :
          <Box
            sx={{
              width: '286px',
              height: '20px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              'font-family': 'Open Sans',
              'font-size': '14px',
              'font-weight': '400',
              'line-height': '20px',
              'letter-spacing': '0em',
              'text-align': 'left',
              color: '#262E35',
            }}
          >{itemInfo}</Box>
      }
    </Box>
  );
}

import { Switch, Box } from '@mui/material';
import styled from '@mui/material/styles/styled';
function CustomSwitch(props) {
  const { id, checked, handleChange } = props;
  const CssSwitch = styled(Switch)({
    width: '51px',
    height: '22px',
    padding: '1px 2px',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(28px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#154AB6',
          '&:before': {
            color: '#fff',
            content: '"Yes"',
            left: 6,
          },
        },
      },
    },
    '& .MuiSwitch-thumb': {
      width: 18,
      height: 18,
      borderRadius: 16,
      filter: 'drop-shadow(0px 2px 4px rgba(0, 35, 11, 0.2))',
    },
    '& .MuiSwitch-track': {
      borderRadius: 16,
      opacity: 1,
      backgroundColor: 'rgb(223,228,232)',
      boxSizing: 'border-box',
      '&:before, &:after': {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 20,
        height: 20,
        fontFamily: 'Roboto',
        fontSytle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '20px',
      },
    },
  });
  return (
    <Box data-selenium-id='EmailTemplates-CommonModule-CustomSwitch-Box'>
      <CssSwitch
        id={id}
        data-selenium-id={'EmailTemplates-CommonModule-CustomSwitch' + id}
        checked={checked}
        sx={{
          '& .MuiSwitch-track': {
            '&:after': {
              content: checked ? '""' : '"No"',
              right: 2,
              color: '#596A7C',
            },
          },
        }}
        onChange={handleChange}
      />
    </Box>
  );
}
export default CustomSwitch;

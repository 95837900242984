/*
 * @Author: LiuJingQian 1250366059@qq.com
 * @Date: 2024-03-01 14:01:43
 * @LastEditors: LiuJingQian 1250366059@qq.com
 * @LastEditTime: 2024-03-02 16:06:06
 * @FilePath: \simt_front-1\src\actions\SIMT-SIP\SIP\SipColumn.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import {axios_instance} from "@/utils/axios_instance";
import {CHANGE_SIP_OVERVIRW_COLUMNS} from "@/actions/SIMT-SIP/SIP/SIPAction";
import { getUserId } from "@/utils/getUserId";

export function changeSIPOverviewColumn(data, solicited) {
    const userId = getUserId();
    const type = 'SIP';
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/users/${userId}/columns/${type}`;
            const res = await axios_instance.post(
                url,
                data,
                {
                    params: {
                        solicited: solicited,
                    },
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: CHANGE_SIP_OVERVIRW_COLUMNS, data: data});
            }
        } catch (err) {
        }
    };
}

export function loadSIPOverviewColumn(solicited) {
    const userId = getUserId();
    const type = 'SIP';
    return async dispatch => {
        try {
            const res = await axios_instance.get(`/api/v1/user-service/users/${userId}/columns/${type}`,
                {
                    params: {
                        solicited: solicited,
                    },
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            const {code,data} = res.data;
            if (code === 200) {
                dispatch({type: CHANGE_SIP_OVERVIRW_COLUMNS, data: data});
            }
        } catch (err) {
        }
    };
}
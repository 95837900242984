

// 定义初始状态
const initialState = {
  isLeadGeSavedInputAvailable: false,
  isCoGeSavedInputAvailable: false,
  leadGeSavedInput: {},
  coGeSavedInput:{},
  isleadGeSavedInputEmpty: false,
  iscoGeSavedInputEmpty: false,
};
function checkAllValuesNotEmpty(obj) {
  // 如果对象为空，则直接返回false
  if (Object.keys(obj).length === 0) {
    return false;
  }
  
  return Object.values(obj).some(value => {
    if (typeof value === 'string') {
      return value.trim() !== '';
    }
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return false;
  });
}


// 定义一个reducer函数来处理状态更新
export  default function GeAddReducer  (state = initialState, action)  {
  switch (action.type) {
    case 'SET_LEAD_GE_SAVED_INPUT':
      return {
        ...state,
        leadGeSavedInput: action.payload,
        isLeadGeSavedInputAvailable: true,
        isleadGeSavedInputEmpty: !checkAllValuesNotEmpty(action.payload),
      };
    case 'SET_CO_GE_SAVED_INPUT':
      return {
        ...state,
        coGeSavedInput: action.payload,
        isCoGeSavedInputAvailable: true,
        iscoGeSavedInputEmpty: !checkAllValuesNotEmpty(action.payload),
      };
    case 'RESET_LEAD_GE_SAVED_INPUT':
      return {
        ...state,
        isLeadGeSavedInputAvailable: true,
      };
    case 'RESET_CO_GE_SAVED_INPUT':
      return {
        ...state,
        isCoGeSavedInputAvailable: true,
      };
    case 'CLOSE_LEAD_GE_SAVED_INPUT':
      return {
        ...state,
        isLeadGeSavedInputAvailable: false,
      };
    case 'CLOSE_CO_GE_SAVED_INPUT':
      return {
        ...state,
        isCoGeSavedInputAvailable: false,
      };
    default:
      return state;
  }
};



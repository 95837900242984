import React from 'react';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import UserLockPage from './UserLocked/userLock';
import { Box } from '@mui/material';

function UserLock() {
  return (
    <Box
      display='flex'
      flexDirection='column'
      data-selenium-id='Login_UserLock-Box'
    >
      <Header data-selenium-id='Login_UserLock-Header' />
      <UserLockPage data-selenium-id='Login_UserLock-UserLockPage' />
      <footer>
        <Footer data-selenium-id='Login_UserLock-Footer' />
      </footer>
    </Box>
  );
}

export default UserLock;

import {axios_instance} from "@/utils/axios_instance";
import {UPDATE_SI, UPDATE_SI_MESSAGE, updatePop} from "@/actions/SIMT-SI/SpecialIssue/SIAction";

export function updateToPublished(siCode,value) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/special-issues/${siCode}`;
            const res = await axios_instance.put(
                url,
                {
                    updateToPublishedDto:{
                        projectCode: value.projectCode,
                        handlingCe: value.ceId,
                        journalCode: value.journalCodeSelect,
                        journalName: value.journalNameSelect,
                        specialIssueType: value.SIType,
                        specialIssueTitle: value.SITitle,
                        ideaDate: value.IdeaDate,
                        siSource: value.SISource,
                        leadGuestEditors: value.LeadGEValue,
                        coGuestEditors: value.COGEValue,
                        paperCommissioningMethod: value.paperCommition,
                        workflow: value.workFlow,
                        acquiredDate: value.AcquiredDate,
                        expectedSubmissionDeadline: value.ExpectedSubmissionDeadline,
                        expectedAcceptanceDate: value.ExpectedAcceptanceDate,
                        expectedPublicationDate: value.ExpectedPublicationDate,
                        closedForSubmission: value.closedSubmit,
                        numberOfSubmittedArticles: value.submissions,
                        numberOfAcceptedArticles: value.acceptArticle,
                        numberOfRejectedArticles: value.rejectArticle,
                        volume: value.volume,
                        issue: value.issue,
                        issuePublicationLink: value.issuePublicationLink,
                        actualPublicationDate: value.actualPublicationDate,
                        numberOfOoArticle: value.ooarticle,
                        numberOfOaArticle: value.oaarticle,
                        remarks: value.remarks,
                        numberOfRecruitedArticles: value.recruitedArticle,
                        stage: value.stage,
                        openForSubmissionDate: value.OpenForSubmissionDate,
                        siRevenueModel: value.siRevenueModel,
                        numberOfWaiversUsed: value.numberOfWaiversUsed,
                        revenueModel: value.revenueModel,
                    }
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: UPDATE_SI, data: 'OK'});
            } else {
                dispatch({type: UPDATE_SI, data: 'error'});
                dispatch({type: UPDATE_SI_MESSAGE, data: res.data.message});
                updatePop(res, dispatch);
            }
        } catch (err) {
            dispatch({type: UPDATE_SI, data: 'error'});
            updatePop(err, dispatch);
        }
    };
}

export function updateToPaperCommission(siCode,value) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/special-issues/${siCode}`;
            const res = await axios_instance.put(
                url,
                {
                    updateToPaperCommissionDto:{
                        projectCode: value.projectCode,
                        handlingCe: value.ceId,
                        journalCode: value.journalCodeSelect,
                        journalName: value.journalNameSelect,
                        specialIssueType: value.SIType,
                        specialIssueTitle: value.SITitle,
                        ideaDate: value.IdeaDate,
                        siSource: value.SISource,
                        leadGuestEditors: value.LeadGEValue,
                        coGuestEditors: value.COGEValue,
                        paperCommissioningMethod: value.paperCommition,
                        workflow: value.workFlow,
                        acquiredDate: value.AcquiredDate,
                        expectedSubmissionDeadline: value.ExpectedSubmissionDeadline,
                        expectedAcceptanceDate: value.ExpectedAcceptanceDate,
                        expectedPublicationDate: value.ExpectedPublicationDate,
                        closedForSubmission: value.closedSubmit,
                        numberOfSubmittedArticles: value.submissions,
                        numberOfAcceptedArticles: value.acceptArticle,
                        numberOfRejectedArticles: value.rejectArticle,
                        volume: value.volume,
                        issue: value.issue,
                        issuePublicationLink: value.issuePublicationLink,
                        actualPublicationDate: value.actualPublicationDate,
                        numberOfOoArticle: value.ooarticle,
                        remarks: value.remarks,
                        numberOfRecruitedArticles: value.recruitedArticle,
                        stage: value.stage,
                        openForSubmissionDate: value.OpenForSubmissionDate,
                        siRevenueModel: value.siRevenueModel,
                    }
                    
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: UPDATE_SI, data: 'OK'});
                // dispatch(
                //   setSnackbarMessageAndOpen(
                //     "siPage.updateSucc",
                //     {},
                //     SEVERITIES.success
                //   )
                // );
            } else {
                dispatch({type: UPDATE_SI, data: 'error'});
                dispatch({type: UPDATE_SI_MESSAGE, data: res.data.message});
                updatePop(res, dispatch);
            }
        } catch (err) {
            dispatch({type: UPDATE_SI, data: 'error'});
            updatePop(err, dispatch);
        }
    };
}

export function updateToSubmissionAndReview(siCode,value) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/special-issues/${siCode}`;
            const res = await axios_instance.put(
                url,
                {
                    updateToSubmissionAndReviewDto:{
                        projectCode: value.projectCode,
                        handlingCe: value.ceId,
                        journalCode: value.journalCodeSelect,
                        journalName: value.journalNameSelect,
                        specialIssueType: value.SIType,
                        specialIssueTitle: value.SITitle,
                        ideaDate: value.IdeaDate,
                        siSource: value.SISource,
                        leadGuestEditors: value.LeadGEValue,
                        coGuestEditors: value.COGEValue,
                        paperCommissioningMethod: value.paperCommition,
                        workflow: value.workFlow,
                        acquiredDate: value.AcquiredDate,
                        expectedSubmissionDeadline: value.ExpectedSubmissionDeadline,
                        expectedAcceptanceDate: value.ExpectedAcceptanceDate,
                        expectedPublicationDate: value.ExpectedPublicationDate,
                        closedForSubmission: value.closedSubmit,
                        numberOfSubmittedArticles: value.submissions,
                        numberOfAcceptedArticles: value.acceptArticle,
                        numberOfRejectedArticles: value.rejectArticle,
                        volume: value.volume,
                        issue: value.issue,
                        issuePublicationLink: value.issuePublicationLink,
                        actualPublicationDate: value.actualPublicationDate,
                        numberOfOoArticle: value.ooarticle,
                        remarks: value.remarks,
                        numberOfRecruitedArticles: value.recruitedArticle,
                        stage: value.stage,
                        openForSubmissionDate: value.OpenForSubmissionDate,
                        siRevenueModel: value.siRevenueModel,
                    }
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: UPDATE_SI, data: 'OK'});
                // dispatch(
                //   setSnackbarMessageAndOpen(
                //     "siPage.updateSucc",
                //     {},
                //     SEVERITIES.success
                //   )
                // );
            } else {
                dispatch({type: UPDATE_SI, data: 'error'});
                dispatch({type: UPDATE_SI_MESSAGE, data: res.data.message});
                updatePop(res, dispatch);
            }
        } catch (err) {
            dispatch({type: UPDATE_SI, data: 'error'});
            updatePop(err, dispatch);
        }
    };
}

export function updateToProduction(siCode, value) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/special-issues/${siCode}`;
            const res = await axios_instance.put(
                url,
                {
                    updateToProductionDto:{
                        projectCode: value.projectCode,
                        handlingCe: value.ceId,
                        journalCode: value.journalCodeSelect,
                        journalName: value.journalNameSelect,
                        specialIssueType: value.SIType,
                        specialIssueTitle: value.SITitle,
                        ideaDate: value.IdeaDate,
                        siSource: value.SISource,
                        leadGuestEditors: value.LeadGEValue,
                        coGuestEditors: value.COGEValue,
                        paperCommissioningMethod: value.paperCommition,
                        workflow: value.workFlow,
                        acquiredDate: value.AcquiredDate,
                        expectedSubmissionDeadline: value.ExpectedSubmissionDeadline,
                        expectedAcceptanceDate: value.ExpectedAcceptanceDate,
                        expectedPublicationDate: value.ExpectedPublicationDate,
                        closedForSubmission: value.closedSubmit,
                        numberOfSubmittedArticles: value.submissions,
                        numberOfAcceptedArticles: value.acceptArticle,
                        numberOfRejectedArticles: value.rejectArticle,
                        volume: value.volume,
                        issue: value.issue,
                        issuePublicationLink: value.issuePublicationLink,
                        actualPublicationDate: value.actualPublicationDate,
                        numberOfOoArticle: value.ooarticle,
                        remarks: value.remarks,
                        numberOfRecruitedArticles: value.recruitedArticle,
                        stage: value.stage,
                        openForSubmissionDate: value.OpenForSubmissionDate,
                        siRevenueModel: value.siRevenueModel,
                    }
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: UPDATE_SI, data: 'OK'});
                // dispatch(
                //   setSnackbarMessageAndOpen(
                //     "siPage.updateSucc",
                //     {},
                //     SEVERITIES.success
                //   )
                // );
            } else {
                console.log('cgttest,res = ', res, res.data.message);
                dispatch({type: UPDATE_SI, data: 'error'});
                dispatch({type: UPDATE_SI_MESSAGE, data: res.data.message});
                updatePop(res, dispatch);
            }
        } catch (err) {
            dispatch({type: UPDATE_SI, data: 'error'});
            updatePop(err, dispatch);
        }
    };
}

export function updateToAcquired(siCode,value) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/special-issues/${siCode}`;
            const res = await axios_instance.put(
                url,
                {
                    updateToAcquiredDto:{
                        projectCode: value.projectCode,
                        handlingCe: value.ceId,
                        journalCode: value.journalCodeSelect,
                        journalName: value.journalNameSelect,
                        specialIssueType: value.SIType,
                        specialIssueTitle: value.SITitle,
                        ideaDate: value.IdeaDate,
                        siSource: value.SISource,
                        leadGuestEditors: value.LeadGEValue,
                        coGuestEditors: value.COGEValue,
                        paperCommissioningMethod: value.paperCommition,
                        workflow: value.workFlow,
                        acquiredDate: value.AcquiredDate,
                        expectedSubmissionDeadline: value.ExpectedSubmissionDeadline,
                        expectedAcceptanceDate: value.ExpectedAcceptanceDate,
                        expectedPublicationDate: value.ExpectedPublicationDate,
                        closedForSubmission: value.closedSubmit,
                        numberOfSubmittedArticles: value.submissions,
                        numberOfAcceptedArticles: value.acceptArticle,
                        numberOfRejectedArticles: value.rejectArticle,
                        volume: value.volume,
                        issue: value.issue,
                        issuePublicationLink: value.issuePublicationLink,
                        actualPublicationDate: value.actualPublicationDate,
                        numberOfOoArticle: value.ooarticle,
                        numberOfOaArticle: value.oaarticle,
                        remarks: value.remarks,
                        numberOfRecruitedArticles: value.recruitedArticle,
                        stage: value.stage,
                        siRevenueModel: value.siRevenueModel,
                    }
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: UPDATE_SI, data: 'OK'});
                // dispatch(
                //   setSnackbarMessageAndOpen(
                //     "siPage.updateSucc",
                //     {},
                //     SEVERITIES.success
                //   )
                // );
            } else {
                dispatch({type: UPDATE_SI, data: 'error'});
                dispatch({type: UPDATE_SI_MESSAGE, data: res.data.message});
                updatePop(res, dispatch);
            }
        } catch (err) {
            dispatch({type: UPDATE_SI, data: 'error'});
            updatePop(err, dispatch);
        }
    };
}

export function updateToShelved(SIid, Reason, closedSubmit) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/special-issues/${SIid}`;
            const res = await axios_instance.put(
                url,
                {
                    updateToShelvedDto:{
                        projectCode: SIid,
                        shelvedReason: Reason,
                        closedForSubmission: closedSubmit,
                    }
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: UPDATE_SI, data: 'OK'});
                // dispatch(
                //   setSnackbarMessageAndOpen(
                //     "siPage.updateSucc",
                //     {},
                //     SEVERITIES.success
                //   )
                // );
            } else {
                dispatch({type: UPDATE_SI, data: 'error'});
                dispatch({type: UPDATE_SI_MESSAGE, data: res.data.message});
                updatePop(res, dispatch);
            }
        } catch (err) {
            dispatch({type: UPDATE_SI, data: 'error'});
            updatePop(err, dispatch);
        }
    };
}

import '../../../App.css';
import { Box, Button, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect } from 'react';
import RoleTable from './RoleTable';
import {
  handleChangePageSize,
  handlePageChangeAction,
} from '@/actions/SIMT-User/Role/RoleManageAction';
import { useNavigate } from 'react-router-dom';
import { NewDesignedMain } from '../../../components/Main';
import {getRoleInfo} from "@/actions/SIMT-User/Role";

function RoleManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      roleData: state.Role.roleInfo,
      // confirmBarOpen: state.UserManage.open,
      page: state.Role.page,
      perPageNumber: state.Role.pageSize,
      orderField: state.Role.orderField,
      orderType: state.Role.orderType,
      reloadTableTrigger: state.Role.reloadTableTrigger,
    };
  };
  const {
    open,
    roleData,
    page,
    perPageNumber,
    orderField,
    orderType,
    reloadTableTrigger,
  } = useSelector(selector);

  // 修改页码、页大小和其他修改trigger的时候重新加载table
  useEffect(() => {
    async function fetchData() {
      await dispatch(getRoleInfo(page, perPageNumber, orderField, orderType));
    }
    fetchData();
  }, [
    perPageNumber,
    page,
    orderField,
    orderType,
    reloadTableTrigger,
    dispatch,
  ]);

  const handlePerPageChange = e => {
    dispatch(handleChangePageSize(e.target.value));
  };

  const handlePageChange = (_event, value) => {
    dispatch(handlePageChangeAction(value));
  };

  const handleNewRole = () => {
    navigate('/simt/auth/system/role/new');
  };

  return (
    <NewDesignedMain
      open={open}
      sx={{ padding: '0 34px 0 24px' }}
      data-seleunim-id='SystemAdmin_RoleManagement-NewDesignedMain'
    >
      <Box
        className='Box'
        data-seleunim-id='SystemAdmin_RoleManagement-NewDesignedMain-Box'
        sx={{ backgroundColor: 'transparent', border: 0 }}
      >
        <Stack
          data-seleunim-id='SystemAdmin_RoleManagement-NewDesignedMain-Box-Stack'
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          p={3}
          sx={{ padding: 0, 'margin-bottom': '20px', 'margin-top': '12px' }}
        >
          <Box
            id='systemAdminRoleManagementDiv'
            data-seleunim-id='SystemAdmin_RoleManagement-NewDesignedMain-Box-Stack-Box'
            className='TitleText'
            sx={{ fontSize: '22px', color: '#262E35', lineHeight: '125%' }}
          >
            Role Management
          </Box>
          <Button
            id='systemAdminRoleManagementNewUserButton'
            data-seleunim-id='SystemAdmin_RoleManagement-NewDesignedMain-Box-Stack-NewUserButton'
            className='btnNewUser'
            variant='contained'
            color='wileyBlue2'
            onClick={handleNewRole}
            style={{ 'text-transform': 'capitalize' }}
            startIcon={<AddIcon />}
          >
            Create
          </Button>
        </Stack>
        {/**表格，传入角色数据 */}
        <RoleTable
          rows={roleData.result}
          data-seleunim-id='SystemAdmin_RoleManagement-NewDesignedMain-Box-RoleTable'
          Page={page}
          perPageNumber={perPageNumber}
          orderField={orderField}
          orderType={orderType}
          count={roleData.count}
          handlePerPageChange={handlePerPageChange}
          handlePageChange={handlePageChange}
        />
      </Box>
    </NewDesignedMain>
  );
}

export default RoleManagement;

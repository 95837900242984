import styled from '@emotion/styled';
import { MiniFont } from '../../../../components/FontStyle';
import {
  Autocomplete,
  Box,
  IconButton,
  Checkbox,
  TextField,
  Tooltip,
  Chip,
  Popper,
} from '@mui/material';
import SIDrawerAutoCompleteTagDelete from '../../../../assets/SIDrawerAutoCompleteTagDeletePhase2.svg';
import SIDrawerAutoCompletePopupIcon from '../../../../assets/SIDrawerAutoCompletePopupIconPhase2.svg';
import SIDrawerAutoCompleteClearIcon from '../../../../assets/SIDrawerAutoCompleteClearIconPhase2.svg';
import SIDrawerAutoCompleteMagnifyingGlassIcon from '../../../../assets/SIDrawerAutoCompleteMagnifyingGlassPhase2.svg';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useEffect, useState } from 'react';
import React from 'react';
import SelectInput from '@mui/material/Select/SelectInput';


const CustomPopper = (props) => {
  const { children, ...other } = props;

  return (
    <div
      style={{
        zIndex: '1500',
        maxHeight: '200px',
        '& .MuiAutocomplete-popper': {
          inset: '6px auto !important',
        },
      }}
      {...other}
    >
      {children}
    </div>
  );
};

const defaultPopperComponent = props => (
  <Popper
    {...props}
    sx={{
      zIndex: '1500 !important',
      '&.MuiAutocomplete-popper': {
        inset: '6px auto !important',
      },
      '& .MuiAutocomplete-paper': {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 6px 12px rgba(38, 46, 53, 0.12)',
        borderRadius: '5px',
      },
      '& .MuiAutocomplete-noOptions': {
        padding: '0px',
      },
      '& .MuiAutocomplete-listbox': {
        maxHeight: (36*6)+'px',
      },
    }}
  />
);

export const StyledSIDrawerAutoComplete = styled(Autocomplete)({
  '& .MuiOutlinedInput-root': {
    width: '100%',
    minHeight: '40px',
    background: '#FFFFFF',
    fontSize: '14px',
    borderRadius: 4,
    boxSizing: 'border-box',
    padding: '6px 6px 6px 6px',
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '0px 0px',
    minWidth: '0px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #DFE4E8',
  },
  '& .MuiAutocomplete-tag': {
    margin: '2px 1px 2px 1px',
    maxWidth: 'calc(100% - 12px)',
  },
  // '&.MuiAutocomplete-popper': {
  //   inset: '6px auto !important',
  // },
  '& .MuiAutocomplete-endAdornment': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    top: '0',
    right: '8px',
  },
  '& .MuiAutocomplete-popupIndicator': {
    order: 2, // move popupIcon to the end
  },
  '& .MuiAutocomplete-clearIndicator': {
    order: 3, // move clearIcon to the end + 1
  },
});

export const StyledChip = styled(Chip)({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '5px 8px 5px 12px',
  gap: '6px',
  maxWidth: '387px',

  backgroundColor: '#FFFFFF',
  borderRadius: '4px',

  height: '22px',
  border: '1px solid #98A7B6',
  '&:hover': {
    backgroundColor: '#DFE4E8',
    border: '1px solid #929DAE',
    '& .MuiChip-label': {
      fontWeight: 600,
    },
  },
  '& .MuiChip-label': {
    padding: '0px',
    lineHeight: '18px',
    color: '#596A7C',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0px',
    textAlign: 'left',
  },
  '& .MuiChip-deleteIcon': {
    margin: '0px 0px 0px 0px',
    color: '#0F172A',
    '&:hover': {
      background: '#BCC5CF',
      borderRadius: '2px',
    },
  },
});

export const StyledChip2 = styled(Chip)({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '2px 8px 2px 12px',
  gap: '6px',
  maxWidth: '100%',

  backgroundColor: '#FFFFFF',
  borderRadius: '4px',

  height: '28px',
  border: '1px solid #BCC5CF',
  '&:hover': {
    backgroundColor: '#DFE4E8',
    border: '1px solid #929DAE',
    '& .MuiChip-label': {
      fontWeight: 600,
    },
  },
  '& .MuiChip-label': {
    padding: '0px',
    lineHeight: '18px',
    color: '#596A7C',
  },
  '& .MuiChip-deleteIcon': {
    margin: '0px 0px 0px 0px',
    color: '#0F172A',
    '&:hover': {
      background: '#BCC5CF',
      borderRadius: '2px',
    },
  },
});

export const SIDrawerAutocomplete = props => {
  const { id, getOptionName, tagType = 'small', ...other } = props;

  const ChipComponent = tagType === 'small' ? StyledChip : StyledChip2;

  

  return (
    <StyledSIDrawerAutoComplete
      id={'autocomplete-' + id}
      multiple
      disablePortal
      limitTags={-1}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Tooltip
            // title={getOptionName(option)}
            key={id + '-tooltip-' + index}
            disableInteractive
            followCursor={true}
          >
            <ChipComponent
              key={id + '-' + index}
              label={getOptionName(option)}
              deleteIcon={
                <IconButton
                  sx={{ height: '12px', width: '12px', padding: '0px' }}
                >
                  <SIDrawerAutoCompleteTagDelete />
                </IconButton>
              }
              {...getTagProps({ index })}
            />
          </Tooltip>
        ))
      }
      PopperProps={CustomPopper}
      clearIcon={
        <IconButton sx={{ width: '14px', height: '14px', padding: '0px' }}>
          <SIDrawerAutoCompleteClearIcon />
        </IconButton>
      }
      popupIcon={
        <IconButton
          // sx={{
          //   width: '13.71px',
          //   height: '13.71px',
          //   padding: '0px',
          //   marginRight: '5.5px',
          // }}
        >
          <SIDrawerAutoCompletePopupIcon />
        </IconButton>
      }
      {...other}
    />
  );
};

export default function SelectBox(props) {
  const {
    data,
    labelName, //used to generate Id
    placeholder,
    value: selectedList,
    setSelected,
    onLoad,
    useKey,
    loadMore,
    getId,
    getValue,
    type = 0,
    ...other
  } = props;
  const [open, setOpen] = useState(false);
  const identifier = labelName.replace(' ', '-');
  const [inputText, setInputText] = useState('');
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(null);
  const [page, setPage] = useState(1);
  const [loadTrigger, setLoadTrigger] = useState(0);
  const [lastSize, setLastSize] = useState(0);

  const hasMore = data !== undefined && lastSize !== data.length;
  const pageSize = 20;
  useEffect(() => {
    if (loadMore !== undefined) {
      setLoading(true);
      clearTimeout(timer);
      setTimer(
        setTimeout(async () => {
          setPage(1);
          setLoadTrigger(loadTrigger + 1);
        }, 300)
      );
    }
  }, [inputText]);

  useEffect(() => {
    if (loadTrigger === 0 || !open) {
      return;
    }
    setLastSize(data.length);
    async function loadMoreOptions() {
      await loadMore(inputText, page, pageSize);
    }

    loadMoreOptions().then(_r => {
      setLoading(false);
    });
  }, [open, page, loadTrigger]);

  const handleInputTextChange = e => {
    setInputText(e.target.value);
  };

  const getOptionName = option => {
    return getValue ? getValue(option) : option;
  };
  const getOptionId = option => {
    return getId ? getId(option) : getOptionName(option);
  };

  return (
    <Box
      data-selenium-id={`SIPage_Overview-newFilterDrawer-SelectBox${identifier}`}
    >
      <SIDrawerAutocomplete
        disableCloseOnSelect
        forcePopupIcon={type == 2 ? true : false}
        disableListWrap
        type={type}
        value={selectedList}
        filterOptions={x => x}
        ListboxProps={{
          style: { maxHeight: 'calc(6*36px)' },
          onScroll: event => {
            const listboxNode = event.currentTarget;
            if (
              listboxNode.scrollTop + listboxNode.clientHeight >=
              listboxNode.scrollHeight
            ) {
              // Load More when scroll to bottom
              if (hasMore && loadMore !== undefined) {
                // Only load more when there are more options
                // if can't find the method not to come back to top, uncomment the following line, according to Mike's suggestion, all load more operations have been cancelled
                // setPage(page + 1);   // Modify page to trigger loadMore
              }
            }
          },
        }}
        id={identifier}
        options={data ? data : []}
        openText='Search'
        loading={loading}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          setInputText('');
        }}
        getOptionLabel={option => getOptionName(option)}
        onChange={(_event, value) => {
          setSelected(value);
        }}
        isOptionEqualToValue={(option, value) =>
          getOptionId(option) === getOptionId(value)
        }
        renderOption={(optionProps, option, { selected }) => (
          <li
            {...optionProps}
            style={{
              height: '36px',
              lineHeight: '16px',
              fontSize: '14px',
              backgroundColor: '#ffffff',
            }}
          >
            <Checkbox
              key={identifier + '-' + getOptionId(option)}
              icon={
                <CheckBoxOutlineBlankIcon
                  fontSize='small'
                  sx={{ width: '14px', height: '14px' }}
                />
              }
              checkedIcon={
                <CheckBoxIcon
                  fontSize='small'
                  sx={{ width: '14px', height: '14px' }}
                />
              }
              style={{ padding: 0, marginRight: 10 }}
              checked={selected}
            />
            <Tooltip
              // title={getOptionName(option)}
              disableInteractive
              leaveTouchDelay={0}
              followCursor={true}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {getOptionName(option)}
              </span>
            </Tooltip>
          </li>
        )}
        renderInput={params => {
          return (
            <TextField
              {...params}
              onChange={e => {
                handleInputTextChange(e);
              }}
              placeholder={selectedList?.length ? null : '  ' + placeholder}
              variant='outlined'
              InputProps={
                selectedList?.length || type != 1
                  ? { ...params.InputProps }
                  : {
                      ...params.InputProps,
                      startAdornment: (
                        <SIDrawerAutoCompleteMagnifyingGlassIcon />
                      ),
                    }
              }
              data-selenium-id={`SIPage_Overview-newFilterDrawer-SelectBox${identifier}-Input`}
            ></TextField>
          );
        }}
        getOptionName={getOptionName}
        {...other}
      />
    </Box>
  );
};

// export function CustomSelectInput(props) {
//   const {
//     data,
//     labelName, //used to generate Id
//     placeholder,
//     value: selectedList,
//     setSelected,
//     onLoad,
//     useKey,
//     loadMore,
//     getId,
//     getValue,
//     type = 0,
//     ...other
//   } = props;

//   const getOptionName = option => {
//     return getValue ? getValue(option) : option;
//   };
//   const getOptionId = option => {
//     return getId ? getId(option) : getOptionName(option);
//   };
  
// };




























// Self Implement SIDrawerAutocomplete EndAdornment

// endAdornment: (
//   <div
//     style={{
//       display: 'flex',
//       flexDirection: 'column',
//       justifyContent: 'center',
//     }}
//     class='MuiAutocomplete-endAdornment css-1q60rmi-MuiAutocomplete-endAdornment'
//   >
//     <IconButton
//       sx={{ width: '16px', height: '16px', padding: '0px',transform: open ? 'rotate(180deg)' : 'none' }}
//       onClick={()=>{setOpen(!open);}}
//     >
//       <SIDrawerAutocompletePupIconTypeThird />
//     </IconButton>
//     <IconButton
//       sx={{ width: '16px', height: '16px', padding: '0px' }}
//       onClick={()=>{setSelected([]);}}
//     >
//       <SIDrawerAutocompleteClearIconTypeFourth />
//     </IconButton>
//   </div>
// ),

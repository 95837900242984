// format API data to update to the label
// same as barchart dataset
export const formatAPIData = (data, dataMapping, categoryMapping) => {
  let result = data;
  if (data instanceof Array) {
    // remove total from API data
    const withoutTotal = removeTotal(data, categoryMapping.label);
    result = withoutTotal.map(obj =>
      formatObject(obj, dataMapping, categoryMapping)
    );
  } else if (data instanceof Object) {
    result = formatObject(data, dataMapping, categoryMapping);
  }
  return result;
};

// format object data
const formatObject = (obj, keyMapping, categoryMapping) => {
  const keys = Object.keys(obj);
  let newObj = {};
  keys.forEach(key => {
    if (key === categoryMapping.label) {
      newObj[key] = categoryMapping.mapping[obj[key]] ?? obj[key]; // if no mapping rule, keep the text
    }
    if (keyMapping[key]) {
      newObj[keyMapping[key]] = obj[key];
    }
  });
  return newObj;
};

// format API data to update to the label in chart
const removeTotal = (data, label) => {
  if (data instanceof Array && data.length > 0) {
    return data.filter(bd => bd[label].toLowerCase() !== 'total');
  }

  return [];
};

// Get sum for piechart
export const getpiechartDataSet = (objArr, keys) => {
  const sum = getSum(objArr);

  // create piechart dataset
  const piechartDataset = keys.map(key => ({
    name: key,
    value: sum[key],
  }));

  return piechartDataset;
};

// Get sum (as object) from array of objects which have the same sets of attributes
const getSum = objArr => {
  let sum = {};
  objArr.forEach(obj => {
    const keys = Object.keys(obj);
    keys.forEach(key => {
      if (typeof obj[key] === 'number') {
        if (typeof sum[key] === 'undefined') {
          sum[key] = 0;
        }
        sum[key] += obj[key];
      }
    });
  });

  return sum;
};

/**
 * new API formatter
 *
 * user only need to specify the newkey oldkey mapping
 */
/**
 * modified start
 *
 * add category name
 */
// export const formatAPIData2 = (subjectGroups, APIKeyMap) => {
//   try {
//     if (subjectGroups && subjectGroups instanceof Array) {
//       // for each item of array
//       return subjectGroups.map((subjectGroup) => {
//         // for each key in map
//         let newSubjectGroup = {}
//         const newKeys = Object.keys(APIKeyMap)
//         // insert key value pair
//         newKeys.forEach((newKey) => {
//           const oldKey = APIKeyMap[newKey]
//           const value = subjectGroup[oldKey] || 0
//           newSubjectGroup[newKey] = value
//         })
//         return newSubjectGroup
//       })
//     } else {
//       return []
//     }
//   } catch (err) {
//     console.error("exception", err)
//     return []
//   }
// }

export const formatAPIData2 = (
  subjectGroups,
  APIKeyMap,
  catagoryAttributeName
) => {
  try {
    if (subjectGroups && subjectGroups instanceof Array) {
      // for each item of array
      return subjectGroups.map(subjectGroup => {
        // for each key in map
        let newSubjectGroup = {};
        const newKeys = Object.keys(APIKeyMap);
        // insert the category
        newSubjectGroup[catagoryAttributeName] =
          subjectGroup[catagoryAttributeName];
        // insert key value pair
        newKeys.forEach(newKey => {
          /**
           * modified start
           *
           * to extend the mapKey to array of strings
           */
          // const oldKey = APIKeyMap[newKey]
          // const value = subjectGroup[oldKey] || 0
          const oldKeys = APIKeyMap[newKey];
          let value = 0;
          if (Array.isArray(oldKeys)) {
            oldKeys.forEach(oldKey => {
              if (typeof subjectGroup[oldKey] !== 'undefined') {
                value = subjectGroup[oldKey];
              }
            });
          } else {
            const oldKey = oldKeys;
            value = subjectGroup[oldKey];
          }
          /**
           * modified start
           *
           * to extend the mapKey to array of strings
           */
          newSubjectGroup[newKey] = value === 0 ? null : value;
        });
        // console.log('===newSubjectGroup===', newSubjectGroup);
        return newSubjectGroup;
      });
    } else {
      return [];
    }
  } catch (err) {
    console.error('exception', err);
    return [];
  }
};
/**
 * modified end
 *
 * add category name
 */

import React from 'react';
import {
  SIMTButton,
  SIMTLoadingButton,
} from '@/components/StyledButton/SIMTButton';
import { Stack } from '@mui/material';
import { StickyBarContainer } from '../../RevisePage/CustomComponents/CustomStickyBar';
import styleModule from './CustomComponent.module.scss';
/**
 * @param {import("./StickyBar").StepControlStickyBarProps} props
 * @returns
 */
export const StepControlStickyBar = props => {
  const {
    handleChangeStep,
    nextButtonText = 'Next',
    backButtonText = 'Cancel', //modified by HJX
    nextButtonLoading: confirmPending,
    currentStep,
    isButtonDisabled = false,
    handleBack=()=>{}
  } = props;
  return (
    <StickyBarContainer>
      <Stack
        data-selenium-id='SIPPage_TransformToSl-CustomCompoent-StickyBar-Stack'
        direction={'row'}
        justifyContent='center'
        paddingY='12px'
        spacing='64px'
      >
        <SIMTButton
          onClick={() => {
            handleChangeStep(currentStep - 1);
            handleBack();
          }}
          data-selenium-id='SIPPage_TransformToSl-CustomCompoent-StickyBar-Stack-SIMTButton'
          className={styleModule.cancelButton}
          sx={{
            '&.MuiButton-root': {
              fontFamily: 'Inter',
            },
          }}
        >
          {backButtonText}
        </SIMTButton>
        <SIMTLoadingButton
          data-selenium-id='SIPPage_TransformToSl-CustomCompoent-StickyBar-Stack-SIMTLoadingButton'
          loading={confirmPending}
          variant='contained'
          sx={{
            '&.MuiButton-root': {
              minWidth: '114px', //modified by HJX
              fontWeight: 600,
              minHeight: '41px',
              backgroundColor: '#0052CC',
              fontFamily: 'Inter',
            },
          }}
          disabled={currentStep && isButtonDisabled}
          onClick={() => handleChangeStep(currentStep + 1)}
          className={styleModule.nextButton}
        >
          {nextButtonText}
        </SIMTLoadingButton>
      </Stack>
    </StickyBarContainer>
  );
};

//@ts-check
import { JournalScopeCheckOrRecommendationsTypes } from '@/actions/SIPAIScreeningReportAction';
import { StrictMode } from 'react';
import JournalScopeCheckOrRecommendationsMainFrame from '../components/JournalScopeCheckOrRecommendationsMainFrame';
/**
 * @param {{ sipCode: string; }} props
 */
export default function JournalRecommendations(props) {
  const { sipCode } = props;
  return (
    <StrictMode>
      <JournalScopeCheckOrRecommendationsMainFrame
        sipCode={sipCode}
        title='Journal Recommendations:'
        type={JournalScopeCheckOrRecommendationsTypes.JournalRecommendations}
      />
    </StrictMode>
  );
}

import * as loginActions from '@/actions/SIMT-User/Login/LoginAction';

const initState = {
  siderOpen: false,
};

export default function LoginReducers(preState = initState, action) {
  const { type, data } = action;

  switch (type) {
    case 'changeSiderOpen':
      return { ...preState, siderOpen: data };
    case loginActions.LOGOUT:
      return initState;
    default:
      return { ...preState };
  }
}

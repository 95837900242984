import React from 'react';
import DefaultIcon from '../assets/isCheckedIcon-default.svg';
import CheckedIcon from '../assets/isCheckedIcon-checked.svg';
import ErrorIcon from '../assets/isCheckedIcon-error.svg';
import { Box } from '@mui/material';
export default function IsCheckedIcon(props) {
  const {
    status='default',// 'checked' | 'error | 'default'默认值
    show = true,
    sx,
    ...other
  }
    = props;
  return (
    <Box id={`isCheckedIcon`}
      sx={{
        opacity: show ? 1 : 0,
        height: '22px',
        width: '18px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        ...sx
      }}
      {...other}>
      {
        status === 'checked' ? <CheckedIcon /> :
          status === 'error' ? <ErrorIcon /> :
            <DefaultIcon />
      }
    </Box>
  );
}

import { createTheme } from '@mui/material';

const globalTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#154AB6',
      light: '#56ade8',
      dark: '#113b92',
    },
    Primary100: {
      main: '#CEDDF9',
    },
    secondary: {
      main: '#131f45',
      light: '#3f4671',
      dark: '#00001f',
    },
    error: {
      main: '#a94442',
      light: '#df736d',
      dark: '#75131b',
    },
    warning: {
      main: '#f5cb5c',
      light: '#fffe8c',
      dark: '#bf9a2b',
    },
    info: {
      main: '#007eb6',
      light: '#56ade8',
      dark: '#005286',
    },
    success: {
      main: '#3c763d',
      light: '#6aa569',
      dark: '#094a14',
    },
    text: {
      primary: '#484848',
      secondary: '#727272',
    },
    indigo: {
      main: '#131F45',
    },
    lightBlue: {
      main: '#007EB6',
      contrastText: '#fff',
    },
    savedGreen: {
      main: '#A1DDD6',
      contrastText: '#0D6A61',
    },
    darkBlue: {
      main: '#1B5BA0',
      contrastText: '#fff',
    },
    secondeBlue: {
      main: '#56ADE8',
      contrastText: '#fff',
    },
    white: {
      main: '#fff',
    },
    red: {
      main: '#dd5b5b',
    },
    Grey700: {
      main: '#3B3B3B',
      contrastText: '#fff',
    },
    Gray700: {
      // will replace `grey` by gray
      main: '#3B3B3B',
      contrastText: '#fff',
    },
    Gray300: {
      main: '#BCC5CF',
    },
    Gray400: {
      main: '#98A7B6',
    },
    Gray500: {
      main: '#596A7C',
    },
    Grey600: {
      main: '#262E35',
    },
    Gray600: {
      // will replace `grey` by gray
      main: '#262E35',
    },
    Gray200: {
      main: '#DFE4E8',
    },
    Gray100: {
      main: '#F1F3F5',
    },
    grayBlue: {
      main: '#E3E8F2',
      contrastText: '#505050',
    },
    wileyBlue2: {
      main: '#264ab7',
      contrastText: '#fff',
    },
    wileyGrey3: {
      main: '#828282',
    },
    bg: {
      main: '#F3F4F8',
    },
    ActiveChip: {
      main: '#D7F1EE',
      contrastText: '#0D6A61',
    },
    LockedChip: {
      main: '#FFF0DD',
      contrastText: '#854300',
    },
    InactiveChip: {
      main: '#f1f3f5',
      contrastText: '#596A7C',
    },
    UserPopHead: {
      main: '#113161',
      contrastText: '#FFFFFF',
    },
    Green50: {
      main: '#E3FCEF',
    },
    Yellow50: {
      main: '#FFFAE6',
    },
    Error100: {
      main: '#FFDDDD',
    },
  },
  typography: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#D9D9D9',
    opacity: '0.7',
    borderRadius: '10px',
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: '#E3FCEF',
          color: '#262E35',
        },
        standardError: {
          backgroundColor: '#FFDDDD',
          color: '#262E35',
        },
        standardWarning: {
          backgroundColor: '#FFFAE6',
          color: '#262E35',
        },
        standardInfo: {
          backgroundColor: '#CEDDF9',
          color: '#262E35',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        style: {
          textTransform: 'none',
        },
      },
    },
  },
});

export default globalTheme;

import juice from 'juice';

const allTags = [
  'AME first name or EEO Support',
  'PRPL first name or EEO Support',
  'Journal name (Journal code)',
  'Journal name',
  'Journal code',
  'Submission URL',
  'Special Issue title',
  'Submission deadline',
  'GE name, GE email',
  'CE name',
  'GE first name',
  'EiC email address',
  'Handling CE email address',
  'JPM email address',
  'PD Publisher email address',
  'AME email address',
  'PRPL email address', // "PRPP_email_address" is the Database column name (t_email_template)
  'EA email address',
  'PM email address',
  'PE email address',
  'Handling CE first name',
  'PE name',
  'Number of Articles Submitted',
  'Number of Articles Accepted',
  'Number of Articles Rejected',
  'Number of Articles in Progress',
  'Issue Publication Link',
  'Stage',
  'GE Name',
  'GE Email',
  'Acquired Date',
  'SI Source',
  'Paper Commissioning Method',
  'Closed for submission?',
  'Remarks',
  'Handling CE full name',
  'Previous Handling CE full name',
  'Expected Acceptance Date', // Ref: tag required in CT4584
  'SI code', // Ref: tag required in CT-4759
  'SI Details Page Link', // Ref: tag required in CT-4759
];

const sipTags = [
  'SIP Operations Lead First Name',
  'SIP Lead ge', //[Job Title]. [Lead GE's Last Name], [Job Title] [Lead GE's Last Name], ... and [Job Title] [Lead GE's Last Name]
  'SIP JE first names', //[JE's First Name], [JE's First Name], ..., and [JE's First Name]  /[Assignee 1's First Name], [Assignee 2's First Name], ..., [Assignee N's First Name]
  'Journal name', //
  'Subject journal name',
  'Journals Title list', //
  'Journal Title',
  'SIP Title', //
  'SIP Code', //
  'Lead GE Full Names', //
  'Co-GE Full Names', //
  "GEs' Full names", //
  'Link to SIP detail page', //
  'Revision Deadline', //
  'Temporary URL', //
  'Date of resubmission', //
  "System Admin's First Name", //
  'Primary URL', //
  'Date of revision', //
  'lead GE email address', //
  "Handling CE Name", //
  "Handling CE's First Name", //
  'Comments', //
  'JPM First Name', //
  'PD Publisher First Name',
  'Journal name (Journal code)',
  'New Handling CE Name',
  'New CE Name',
  'New CE First Name',
  'Current Handling CE Name',
  'Current CE Name',
  'CE name',
  'Commissioning Editor Name',
  'Commissioning Editor First Name',
  'Operations Lead Full Name',
  'Operations Lead First Name',
  'EBM First Name',
  'Screener First Name',
  'Screener Full Name',
];

export const common = allTags.map(item => {
  return '#' + item;
});

export const commonForSIP = sipTags.map(item => {
  return '#' + item;
});
/**
 * 部分邮件模板的变量可能不同，这里以此来区分
 * @type {{editorialWriting: string[],
 *        thankYou: string[],
 *        common: string[],
 *        geGuideline: string[],
 *        testMore: string[],
 *        siTitleAdding: string[],
 *        deadline: string[],
 *        siRemoving: string[],
 *        monthlySubmission: string[]
 *        }}
 */
export const emailVariables = {
  common: common,
  siTitleAdding: common,
  siRemoving: common,
  geGuideline: common,
  monthlySubmission: common,
  editorialWriting: common,
  thankYou: common,
  deadline: [...common, '#Interval'],
  interval: ['#Interval'],
  action: common,
  full: [...common, '#Interval'],
  commonForSIP: commonForSIP,
};

function addTableBorder(div) {
  let tableList = div.querySelectorAll('figure');
  for (const figureItem of tableList) {
    figureItem.setAttribute('id', 'email-template-show');
  }
}

function replaceVariableToJinghao(div, variablesReplacement) {
  let spanList = div.querySelectorAll('span[data-mention]');
  for (const spanItem of spanList) {
    // 替换${xxx} 为#xxx
    for (const v of variablesReplacement) {
      const v2 = `\${${v.replace('#', '').replace(/\s/g, '_')}}`;
      spanItem.innerHTML = spanItem.innerHTML.replace(v2, `${v}`); // 替换
    }
  }
}

/**
 * 将${}替换为[]，
 * @param {string} rawData    html代码
 * @param {Array} variablesReplacement    此次使用到的变量数组
 * @param {*} key                         后端接收的key，默认为issue
 */
export function replaceVariablesForFrontShow(
  rawData,
  variablesReplacement,
  key = 'issue'
) {
  let div = document.createElement('div');
  div.innerHTML = rawData;
  // 移除style
  removeStyle(div);
  // 移除多余的tr
  removeRedundantTr(div);
  let spanList = div.querySelectorAll('span[data-mention]');
  for (const spanItem of spanList) {
    const grand = spanItem.parentNode?.parentNode;
    const grandGrand = grand?.parentNode;
    for (const v of variablesReplacement) {
      if (grand?.nodeName === 'TR' || grandGrand?.nodeName === 'TR') {
        // 正常来说是span->tr->td
        // ${key.xxx}
        // 注意：v是带#的字符串！！！
        // replace ${key.variable} to [variable]
        const match = `\${${key}.${v.replace('#', '').replace(/\s/g, '_')}}`;
        spanItem.innerHTML = spanItem.innerHTML.replace(
          match,
          `[${v.replace('#', '')}]`
        ); // 替换
        spanItem.innerHTML = spanItem.innerHTML.replace(
          `\${${v.replace('#', '').replace(/\s/g, '_')}}`,
          `[${v.replace('#', '')}]`
        ); // 替换2
      } else {
        // // 替换${xxx} 为[xxx]
        // spanItem.innerHTML = spanItem.innerHTML.replace(
        //   `\${${v.replace('#', '').replace(/\s/g, '_')}}`,
        //   `[${v.replace('#', '')}]`
        // ); // 替换
        const variableRegex = /\${([^}]+)}/g; // 匹配形式为${xxx}的变量正则表达式

        let innerHTML = spanItem.innerHTML;
        let match;
        while ((match = variableRegex.exec(innerHTML)) !== null) {
          const variable = match[1];
          const replacedVariable = variable.replace('#', '').replace(/\s/g, '_');
          const replacement = `[${replacedVariable}]`;
          innerHTML = innerHTML.replace(match[0], replacement);
        }
        spanItem.innerHTML = innerHTML;

      }
    }
    spanItem.removeAttribute('data-mention');
    spanItem.removeAttribute('class', '');
  }
  return div;
}

/**
 * 替换<span date-mention="#abc">${abc}</span>的${abc}为#abc，前端的CKEditor可用
 *
 * @param rawData
 * @param variables
 * @return {string}
 */
export function replaceVariablesSymbolsForFrontend(
  rawData,
  variables = emailVariables.full
) {
  let testDiv = document.createElement('div');
  testDiv.innerHTML = rawData;
  // 替换回井号
  replaceVariableToJinghao(testDiv, variables);
  // 删除style标签
  removeStyle(testDiv);
  return testDiv.innerHTML;
}

/**
 * 与`replaceVariablesSymbolsForFrontend`功能类似，但是额外去除多余的tr标签，以及根据transfer的规则进行匹配和替换
 * @param rawData
 * @param variables
 * @return {string}
 */
export function replaceVariablesSymbolsForFrontendInTransfer(
  rawData,
  variables = emailVariables.full
) {
  let testDiv = document.createElement('div');
  testDiv.innerHTML = rawData;
  // 替换回井号
  replaceToJinghaoForTransfer(testDiv, variables);
  // 移除多余的tr标签
  removeRedundantTr(testDiv);
  return testDiv.innerHTML;
}

/**
 * 将井号变量替换为dollar号变量
 * @param div
 * @param variables
 */
function replaceVariablesToDollar(div, variables) {
  let spanList = div.querySelectorAll('span[data-mention]');
  for (const spanItem of spanList) {
    // data-mention属性不为空，进行替换
    for (const v of variables) {
      // 先将`v`的井号替换为空字符：例如#SI Title -> SI Title
      // 然后构造${}，例如SI Title -> ${SI Title}
      // 然后将spanItem.innerHTML中的 #SI Title 全部替换为 ${SI Title}
      spanItem.innerHTML = spanItem.innerHTML.replace(
        v,
        `\${${v.replace('#', '').replace(/\s/g, '_')}}`
      ); // 替换
    }
  }
}

/**
 * 替换<span date-mention="#abc">#abc</span>的#abc为${abc}，然后可以发送给后端
 * 注：data-mention="xxx"的内容【不会】被替换
 * @param rawData
 * @param variables
 * @return {string}
 */
export function replaceVariablesSymbolsForBackend(rawData, variables) {
  let div = document.createElement('div');
  div.innerHTML = rawData;
  // 替换为dollar
  replaceVariablesToDollar(div, variables);
  // 给table加border
  addTableBorder(div);
  // 去掉多余的标记性tr标签
  // removeRedundantTr(testDiv);
  // ----- 给tr增加标记 -----
  let trList = div.querySelectorAll('tr');
  for (const trItem of trList) {
    if (trItem.innerHTML.indexOf('data-mention=') > 0) {
      // 存在变量
      trItem.setAttribute('list-for-backend-former', 'true'); // 给改标签增加一个属性（上半段tr）
      // 由于下半段无法有attribute，所以通过insert一个新的element来完成给后端的标记
      // 由于没有insertAfter，所以只能使用下面的替代方案

      const nextSibling = trItem.nextSibling;
      if (!(nextSibling && nextSibling.tagName == 'TR'
        && nextSibling.getAttribute('list-for-backend-later') == 'true')) {
        let newTrItem = document.createElement('tr');
        newTrItem.setAttribute('list-for-backend-later', 'true');

        let parentItem = trItem.parentNode;
        if (parentItem.lastChild === trItem) {
          parentItem.appendChild(newTrItem);
        } else {
          parentItem.insertBefore(newTrItem, nextSibling);
        }
      }
    }
  }
  // ----- 给tr增加标记 完成 -----

  return div.innerHTML; // return innerHTML
}

/**
 * 将CKEditor导出的content转换为后端可以接受的格式
 * convert raw data from CKEditor to the type that backend accepts
 * Detail:
 *  - Add border to <table>
 *  - Add <style></style> tag and add some styles
 *  - Replace #xxx by ${xxx} in `replaceVariablesSymbolsForBackend` function
 *
 * @param rawData   raw data from CKEditor as string
 * @param variablesReplacement
 * @return {string}   converted data that backend accepts
 */
export function prepareBackendData(rawData, variablesReplacement) {
  let div = document.createElement('div'); // a copy to fetch innerHTML
  div.innerHTML = rawData;
  // ----- 给table增加border -----
  addTableBorder(div);
  // ----- 给table增加border 完成 -----
  return replaceVariablesSymbolsForBackend(div, variablesReplacement); // its inner HTML should be a string
}

function removeStyle(rootDiv) {
  let styleList = rootDiv.querySelectorAll('style');
  for (const styleItem of styleList) {
    rootDiv.removeChild(styleItem);
  }
}

/**
 * 去掉action模板中多余的tr标签
 * @param div
 */
function removeRedundantTr(div) {
  let trList = div.querySelectorAll('tr');
  for (const trItem of trList) {
    if (trItem.getAttribute('list-for-backend-later')) {
      // 仅仅移除没用的tr，也就是后半个later的tr，不需要移除former的tr
      trItem.parentNode.removeChild(trItem);
    }
  }
}

/**
 * 对Action部分的变量替换（key.variable）
 * 单独写出这个函数的目的是降低`prepareBulkTransferData`的复杂度
 * @param div
 * @param variablesReplacement
 * @param key
 */
function replaceToDollarForTransfer(div, variablesReplacement, key = 'issue') {
  let spanList = div.querySelectorAll('span[data-mention]'); // 可以改成(span[data-mention=true]"")
  for (const spanItem of spanList) {
    if (spanItem.parentNode.parentNode.nodeName === 'TR') {
      // 正常来说是span->tr->td
      // 替换方式为${key.xxx}
      for (const v of variablesReplacement) {
        // replace # to ${key.variable}
        spanItem.innerHTML = spanItem.innerHTML.replace(
          v,
          `\${${key}.${v.replace('#', '').replace(/\s/g, '_')}}`
        ); // 替换
      }
    } else {
      // 替换方式为${xxx}
      for (const v of variablesReplacement) {
        spanItem.innerHTML = spanItem.innerHTML.replace(
          v,
          `\${${v.replace('#', '').replace(/\s/g, '_')}}`
        ); // 替换
      }
    }
  }
}

/**
 * 将后端数据转换为井号数据以供前端显示
 * @param div
 * @param variablesReplacement
 * @param key
 */
function replaceToJinghaoForTransfer(div, variablesReplacement, key = 'issue') {
  let spanList = div.querySelectorAll('span[data-mention]');
  for (const spanItem of spanList) {
    const grand = spanItem.parentNode?.parentNode;
    const grandGrand = grand?.parentNode;
    for (const v of variablesReplacement) {
      if (grand?.nodeName === 'TR' || grandGrand?.nodeName === 'TR') {
        // 正常来说是span->tr->td
        // ${key.xxx}
        // 注意：v是带#的字符串！！！
        // replace ${key.variable} to #variable
        const match = `\${${key}.${v.replace('#', '').replace(/\s/g, '_')}}`;
        spanItem.innerHTML = spanItem.innerHTML.replace(match, `${v}`); // 替换
      } else {
        // 替换${xxx} 为#xxx
        spanItem.innerHTML = spanItem.innerHTML.replace(
          `\${${v.replace('#', '').replace(/\s/g, '_')}}`,
          `${v}`
        ); // 替换
      }
    }
  }
}

/**
 * 为Action触发的模块准备数据以供发给后端
 * @param rawData
 * @param variablesReplacement
 * @param key
 * @return {string}
 */
export function prepareBulkTransferData(
  rawData,
  variablesReplacement,
  key = 'issue'
) {
  let div = document.createElement('div'); // a copy to fetch innerHTML
  div.innerHTML = rawData;

  // ----- 替换变量为dollar -----
  replaceToDollarForTransfer(div, variablesReplacement, key);

  // ----- 给table增加border -----
  // addTableBorder(div);

  addTableBorder(div);

  // ----- 给tr增加标记 -----
  let trList = div.querySelectorAll('tr');
  for (const trItem of trList) {
    if (trItem.innerHTML.indexOf('data-mention=') > 0) {
      // 存在变量
      trItem.setAttribute('list-for-backend-former', 'true'); // 给改标签增加一个属性（上半段tr）
      // 由于下半段无法有attribute，所以通过insert一个新的element来完成给后端的标记
      // 由于没有insertAfter，所以只能使用下面的替代方案
      let newTrItem = document.createElement('tr');
      newTrItem.setAttribute('list-for-backend-later', 'true');

      let parentItem = trItem.parentNode;
      if (parentItem.lastChild === trItem) {
        parentItem.appendChild(newTrItem);
      } else {
        parentItem.insertBefore(newTrItem, trItem.nextSibling);
      }
    }
  }
  // ----- 给tr增加标记 完成 -----

  return div.innerHTML; // its inner HTML should be a string
}

/**
 * 将email template格式的字符串/HTML转换为可以发送给用户的样子
 * @param templateTypeData
 */
export function emailTemplateToEmailContent(templateTypeData) {
  let div = document.createElement('div');
  div.innerHTML = templateTypeData;
  let spanList = div.querySelectorAll('span[data-mention]');
  for (const spanItem of spanList) {
    spanItem.outerHTML = spanItem.innerText;
  }
  return div.innerHTML;
}

const cssMinify =
  `.table table {
    background-color: #596A7C;
    /*border-radius: 5px;*/
    border-spacing: 1px;
}
.table table th {
    min-width: 50px;
}
.table table td {
    min-width: 50px;
}
.table table tr th {
    padding: 12px 4px;
    background-color: #ffffff;
    font-family: 'Open Sans', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.08em;
    color: #262E35;
}
.table table tr td {
    padding: 12px 4px;
    background-color: #ffffff;
    font-family: 'Open Sans', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #262E35;
}
/*.table thead tr:first-child th:first-child {*/
    /*border-top-left-radius: 4px;*/
/*}*/
/*.table thead tr:first-child th:last-child {*/
    /*border-top-right-radius: 4px;*/
/*}*/
/*.table tbody tr:last-child td:first-child {*/
    /*border-bottom-left-radius: 4px;*/
/*}*/
/*.table tbody tr:last-child td:last-child {*/
    /*border-bottom-right-radius: 4px;*/
/*}*/
/*.table tbody tr:last-child th:first-child {*/
    /*border-bottom-left-radius: 4px;*/
/*}*/
/*.table tbody tr:last-child th:last-child {*/
    /*border-bottom-right-radius: 4px;*/
/*}*/
`;

/**
 * 为email加上style
 * @param content
 * @return {string}
 */
export function forEmail(content) {
  return juice.inlineContent(content, cssMinify);
}

import SIModal from './SIModal';
import CommonModal from './CommonModal';
import DeprecationSIModal from './Deprecation/SIModal';
import SIColumnsModal from './SIColumnsModal';

const DeprecationModals = {
  SIModal: DeprecationSIModal,
};

export { SIModal, CommonModal, DeprecationModals, SIColumnsModal };
export default SIModal;

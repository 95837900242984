import { Box } from '@mui/material';

export default function NecessaryIcon() {
  return (
    <Box
      sx={{
        //styleName: MINI BOLD UPPERCASE;
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '18px',
        letterSpacing: '0.08em',
        textAlign: 'left',
        color: '#C40000',
        width: '8px',
        height: '18px',
      }}
    >
      *
    </Box>
  );
}

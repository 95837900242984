import { axios_instance } from '@/utils/axios_instance';
import {
  DELETE_ALERT,
  FETCH_UNREAD_ALERTS_COUNT,
  FETCH_ALERTS,
  SET_ALERTS_FINISHED,
  FETCH_UNREAD_ALERTS,
  SET_UNREAD_ALERTS_FINISHED,
  READ_ALERT,
} from '@/actions/SIMT-User/Alert/AlertAction';
import { getUserId } from '@/utils/getUserId';
import getAuth from '@/utils/getAuth';

export const fetchUnreadAlertsCount = () => {
  return async dispatch => {
    try {
      const url = `/api/v1/user-service/users/${getUserId()}/notifications/count`;
      const result = await axios_instance.get(url, {
        params: {
          isRead: false,
        },
      });
      if (result.data.code === 200) {
        dispatch({ type: FETCH_UNREAD_ALERTS_COUNT, data: result.data.data });
      } else {
        dispatch({ type: FETCH_UNREAD_ALERTS_COUNT, data: 0 });
      }
    } catch {
      dispatch({ type: FETCH_UNREAD_ALERTS_COUNT, data: 0 });
    }
  };
};

let fetchingOfAll = false;
let fetchingOfUnread = false;
function isFetching(isRead) {
  if (isRead === false) {
    return fetchingOfUnread;
  }
  return fetchingOfAll;
}
function setFetching(isRead, flag) {
  if (isRead === false) {
    fetchingOfUnread = flag;
  }
  if (isRead === null) {
    fetchingOfAll = flag;
  }
}

const PAGE_SIZE = 20;
export const fetchAlerts = (isRead = null, startId = 0) => {
  /**
   * @param {import("redux").Dispatch} dispatch
   */
  return async dispatch => {
    if (isFetching(isRead)) {
      return;
    }
    setFetching(isRead, true);
    axios_instance
      .get(`/api/v1/user-service/users/${getUserId()}/notifications`, {
        params: {
          isRead,
          startId,
          limit: PAGE_SIZE,
        },
      })
      .then(res => {
        setFetching(isRead, false);
        if (res.data.code === 200) {
          const newList = res.data.data;
          // isRead equal null stand for get all alerts
          // isRead equal false stand for get unread alerts
          if (isRead === false) {
            dispatch({
              type: FETCH_UNREAD_ALERTS,
              data: newList,
              finished: newList.length < PAGE_SIZE,
            });
          } else {
            dispatch({
              type: FETCH_ALERTS,
              data: newList,
              finished: newList.length < PAGE_SIZE,
            });
          }
          return Promise.resolve();
        } else {
          return Promise.reject(res.data);
        }
      })
      .catch(err => {
        setFetching(isRead, false);
        console.error(err);
      });
  };
};
export const fetchAlertsOld = () => {
  /**
   * @param {import("redux").Dispatch} dispatch
   */
  return async dispatch => {
    try {
      const url = `/api/v1/user-service/users/${getUserId()}/notifications`;
      const result = await axios_instance.get(url, {
        headers: {
          authorization: getAuth() ?? '',
        },
      });
      if (result.data.code === 200) {
        dispatch({ type: FETCH_ALERTS, data: result.data.data });
      } else {
        dispatch({ type: FETCH_ALERTS, data: 'error' });
      }
      return result?.data?.data;
    } catch {
      dispatch({ type: FETCH_ALERTS, data: 'error' });
    }
  };
};

export const DeleteAlert = id => {
  return async dispatch => {
    try {
      const url = `/api/v1/user-service/users/${getUserId()}/notifications/${id}`;
      const result = await axios_instance.delete(url, {
        headers: {
          authorization: getAuth() ?? '',
        },
      });
      if (result.data.code === 200) {
        dispatch({ type: DELETE_ALERT, data: result.data.message, id });
      } else {
        dispatch({ type: DELETE_ALERT, data: 'error' });
      }
    } catch {
      dispatch({ type: DELETE_ALERT, data: 'error' });
    }
  };
};
export const ReadAlert = id => {
  return async dispatch => {
    try {
      const url = `/api/v1/user-service/users/${getUserId()}/notifications/${id}`;
      const result = await axios_instance.put(url);
      if (result.data.code === 200) {
        dispatch({ type: READ_ALERT, data: result.data.message, id });
      } else {
        dispatch({ type: READ_ALERT, data: 'error' });
      }
    } catch {
      dispatch({ type: READ_ALERT, data: 'error' });
    }
  };
};

// create Redux action types
export const GET_ALL_ROLE = 'GET_ALL_ROLE';
export const GET_PERMISSION_LIST = 'GET_PERMISSION_LIST';
export const GET_ROLE_DETAIL = 'GET_ROLE_DETAIL';
export const CREATE_ROLE = 'CREATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DETECT_ROLENAME = 'DETECT_ROLENAME';
export const ROLE_CLEAR = 'ROLE_CLEAR';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_PAGE_SIZE = 'CHANGE_PAGE_SIZE';
export const CHANGE_ORDERFIELD = 'CHANGE_ORDERFIELD';
export const CHANGE_ORDERTYPE = 'CHANGE_ORDERTYPE';
export const RESET_ROLE_RESULT = 'RESET_ROLE_RESULT';
export const SET_PERMISSION = 'SET_PERMISSION';

// Create Redux action creators that return an action
export const clear = () => ({
  type: ROLE_CLEAR,
});

/**
 * 更改表格页码
 * @param {int} page 页码
 * @returns dispatch
 */
export function handlePageChangeAction(page) {
  return dispatch => {
    dispatch({
      type: CHANGE_PAGE,
      data: page,
    });
  };
}

/**
 * 更改表格一页数量
 * @param {int} pageSize 一页的大小
 * @returns dispatch
 */
export function handleChangePageSize(pageSize) {
  return dispatch => {
    dispatch({
      type: CHANGE_PAGE_SIZE,
      data: pageSize,
    });
  };
}

/**
 * 更改表格OrderField
 * @param {int} pageSize 一页的大小
 * @returns dispatch
 */
export function handleChangeOrderField(orderField) {
  return dispatch => {
    dispatch({
      type: CHANGE_ORDERFIELD,
      data: orderField,
    });
  };
}
/**
 * 更改表格OrderType
 * @param {int} pageSize 一页的大小
 * @returns dispatch
 */
export function handleChangeOrderType(orderType) {
  return dispatch => {
    dispatch({
      type: CHANGE_ORDERTYPE,
      data: orderType,
    });
  };
}

//移除列表中的AdminPortal
const removeAdminPortal = permisList => {
  return permisList.filter(function (permis) {
    return permis.indexOf('admin');
  });
};

/**
 * 设置TransferList的数据，包括左侧、右侧和打勾的；
 * 以及原始（修改前）的数据
 * @param {string} which 设置TransferList的哪一部分,
 *                       `which` 只可以在这里面选择 {'left', 'right', 'check', 'originalLeft', 'originalRight'}
 * @param {list} data 新值的列表
 * @param removeAdmin
 * @returns dispatch
 */
export function setPermission(which, data, removeAdmin = false) {
  return dispatch => {
    dispatch({
      type: SET_PERMISSION,
      data: {
        which: which,
        data: removeAdmin ? removeAdminPortal(data) : data,
      },
    });
  };
}

export function resetRoleResult() {
  return dispatch => {
    dispatch({
      type: RESET_ROLE_RESULT,
      data: null,
    });
  };
}


import './index.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import BackToTop from '../../../assets/backToTop.svg';
import { Box, Fab, fabClasses, Stack } from '@mui/material';
import JournalDetailsBlock from './JournalDetailsBlock';
import WiderMain from '../../../components/WiderMain';
import GeneralInfoSection from './GeneralInfoSection';
import ReactivatedBox from './ReactivatedBox/ReactivatedBox';
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import { openConfirm, setEditType, setTextParam } from '@/actions/SIMT-User/Alert/AlertAction';
import { FormattedMessage } from 'react-intl';
import EditingHistoryBlock from './EditingHistoryBlock';
import SubmissionOverviewBlock from './SubmissionOverviewBlock';
import PublicationDetailsBlock from './PublicationDetailsBlock';
import MarketSection from './market-section';
import SITitleBlock from './SITitleBlock';
import ScrollTop from './ScrollTop';
import { SIMTButton } from '@/components/StyledButton/SIMTButton';
import { SiDetailToPreviewAndNext } from '@/components/SIDetailToPreviewAndNext/SiDetailToPreviewAndNext';
import SipReferenceSection from './SipReferenceSection';

import ChinaMarketingBlock from '@/pages/ChinaMarketing/SIDetailPage/ChinaMarketingBlock';
import { getSystemConfig, loadPaperConfig, loadSITypes } from "@/actions/SIMT-User/Config";
import { getMandatoryFields } from "@/actions/SIMT-SI/SpecialIssue";
import { getSIDetail, getSnowFlakeInfo } from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueDetail";
import { UntitledAlertBox } from '@/components/Alert/AlertBox';
import { setAIAcquisitionCreateSuccessAlert } from '@/actions/SIMT-SIP/SIP/SIPAction';
import {PERMISSIONS} from "@/constant/permission";

function SIDetailPage(props) {
  let { siCode } = useParams();
  const dispatch = useDispatch();
  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      detailInfo: state.SI.detailInfo,
      snowFlakeInfo: state.SI.SnowFlake,
      MandatoryTable: state.SI.MandatoryTable,
      updateSIResult: state.SI.updateSIResult,
      aiAcquisitionCreateSuccessAlert:
        state.SIP.aiAcquisitionCreateSuccessAlert
    };
  };
  const { open, detailInfo, snowFlakeInfo, MandatoryTable, updateSIResult, aiAcquisitionCreateSuccessAlert } =
    useSelector(selector);
  const [reactivatedOpen, setReactivatedOpen] = useState(false);
  const [loadingSIDetails, setLoadingSIDetails] = useState(false);
  const userPermissions = localStorage.getItem('userPermissions'); //权限管理
  const [isOA, setIsOA] = useState(false);

  const [isReactivateShow, setIsReactivateShow] = useState(false);
  const [isUpdateShow, setIsUpdateShow] = useState(true);
  const [addMarketSection, setAddMarketSection] = useState(false);
  const [addMarketSectionClicked, setAddMarketSectionClicked] = useState(false);
  const [firstOpenMarketSection, setFirstOpenMarketSection] = useState(false);

  useEffect(() => {
    if (
      detailInfo.journalDetails !== undefined &&
      detailInfo.journalDetails[0].revenueModel === 'OA'
    ) {
      setIsOA(true);
    } else {
      setIsOA(false);
    }
  }, [detailInfo]);

  useEffect(() => {
    //获取必填表
    dispatch(getMandatoryFields());

    if (userPermissions.includes(PERMISSIONS.SI_WRITE)) {
      dispatch(loadSITypes());
      dispatch(loadPaperConfig());
      dispatch(getSystemConfig());
    }
  }, [dispatch]);

  //必填表
  useEffect(() => {
    if (MandatoryTable === 'error') {
      setTimeout(() => {
        let mandatoryList = localStorage.getItem('AcquiredMandatoryList');
        if (mandatoryList === undefined || mandatoryList === null) {
          dispatch(getMandatoryFields());
        }
      }, 1000);
    }
  }, [MandatoryTable]);

  const isVirtualIssue = () => {
    return !!(
      detailInfo.generalInformation !== undefined &&
      detailInfo.generalInformation[0].specialIssueType === 'Virtual Issue'
    );
  };

  const publicationDetailType = {
    volume: isVirtualIssue() ? 'VOLUME_V' : 'VOLUME',
    issue: isVirtualIssue() ? 'ISSUE_V' : 'ISSUE',
    issuePublicationLink: 'LINK',
    actualPublicationDate: 'PUBLICATIONDATE',
    numberOfOaArticle: 'PDNUMBER',
    numberOfOoArticle: 'PDNUMBER',
    numberOfWaiversUsed: 'PDNUMBER',
  };

  const submissionOverviewType = {
    dataSource: 'ISSUE',
    recruitedNumber: 'PDNUMBER',
    submittedNumber: 'PDNUMBER',
    acceptedNumber: 'PDNUMBER',
    rejectedNumber: 'PDNUMBER',
    inProgress: 'PDNUMBER',
  };

  const OutputRate = [
    { key: 'Output Rate', value: '', properity: 'outputRate' },
  ];

  const journalDetList = [
    [
      { key: 'Revenue Model', value: '', properity: 'revenueModel' },
      { key: 'Subject Group', value: '', properity: 'subjectGroup' },
      { key: 'Ownership Status', value: '', properity: 'ownershipStatus' },
      { key: 'Primary URL', value: '', properity: 'primaryUrl' },
      { key: 'Peer Review Model', value: '', properity: 'peerReviewModel' },
      { key: 'Business Division', value: '', properity: 'businessDivision' },
      { key: 'Society', value: '', properity: 'society' },
      { key: 'EEO System Link', value: '', properity: 'eeoSystemLink' },
    ],
  ];

  const handleUpdateOpen = () => {
    // setIsUpdateShow(true)
    window.open(`/simt/auth/siRelated/si/detail/${siCode}/update`);
  };

  const handlereactivatedBoxClose = () => {
    setReactivatedOpen(false);
  };

  const handleReactivatedBoxOpen = () => {
    if (isReactivateShow) {
      setReactivatedOpen(true);
    } else {
      dispatch(
        setTextParam(detailInfo.generalInformation[0].specialIssueTitle)
      );
      dispatch(setEditType('reactiveSIWarning'));
      dispatch(openConfirm());
    }
  };

  //为submission值更新使用的publish数据
  const handleExtraInfoForSubmission = () => {
    if (
      detailInfo.publicationDetails !== undefined &&
      detailInfo.publicationDetails.length > 0
    ) {
      let SUM = 0;
      for (let index in detailInfo.publicationDetails) {
        let oo = detailInfo.publicationDetails[index]['numberOfOoArticle'];
        let oa = detailInfo.publicationDetails[index]['numberOfOaArticle'];
        SUM = SUM + oo + oa;
      }
      return SUM;
    } else {
      return null;
    }
  };

  const handleExtraInfoForPublish = () => {
    if (
      detailInfo.submissionOverview !== undefined &&
      detailInfo.publicationDetails !== undefined &&
      detailInfo.publicationDetails.length > 0
    ) {
      return detailInfo.submissionOverview[0]['numberOfArticlesAccepted'];
    } else {
      return null;
    }
  };

  const handleStageId = () => {
    let stage =
      detailInfo.generalInformation === undefined
        ? 'Idea'
        : detailInfo.generalInformation[0].stage;
    switch (stage) {
      case 'Idea/Proposal':
        return 0;
      case 'Acquired':
        return 1;
      case 'Paper Commission':
        return 2;
      case 'Submission and Review':
        return 3;
      case 'Production':
        return 4;
      case 'Published':
        return 5;
      default:
        return 6;
    }
  };

  useEffect(() => {
    async function fetchData() {
      setLoadingSIDetails(true);
      // pass in setLoadingSIDetails funtion,
      // so that set it false when getting successfully
      dispatch(
        getSIDetail(siCode, value => {
          if (value) setLoadingSIDetails(false);
        })
      );

      dispatch(getSnowFlakeInfo(siCode));
    }

    fetchData();
  }, [siCode, dispatch]);

  useEffect(() => {
    if (detailInfo.generalInformation !== undefined) {
      if (detailInfo.generalInformation[0].stage === 'Shelved') {
        setIsUpdateShow(false);
        if (detailInfo.publicationDetails.length === 0) {
          setIsReactivateShow(true);
        }
      } else {
        setIsUpdateShow(true);
        setIsReactivateShow(false);
      }
    }
    if (detailInfo.siMarketing !== undefined) {
      setAddMarketSection(
        detailInfo.siMarketing.length > 0 ||
        JSON.parse(
          localStorage.getItem(detailInfo.generalInformation[0].projectCode)
        )
      );
    }
  }, [detailInfo]);

  useEffect(() => {
    setAddMarketSectionClicked(addMarketSection);
  }, [addMarketSection]);

  const whiteList = [
    'PrivatePickersFadeTransitionGroup-root',
    'MuiCalendarPicker-viewTransitionContainer',
    'css-1wvgxus-MuiCalendarPicker-viewTransitionContainer',
    'dateClass',
    'MuiPickersPopper-root',
  ];

  /**
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} e
   * @returns
   */
  const handleSIDetailClick = e => {
    const target = e.target;
    const path = [];
    let currentElement = target;
    while (currentElement !== null) {
      path.push(currentElement);
      currentElement = currentElement.parentElement;
    }
    for (const element of path) {
      if (element.className && typeof element.className === 'string') {
        const classList = element.className.split(' ');
        for (const c of classList) {
          if (whiteList.includes(c)) {
            dispatch({ type: 'CLICK_INSIDE_DATE' });
            return;
          }
        }
      }
    }
    dispatch({ type: 'CLICK_OUT_OF_DATE' });
  };
  // const
  // 获取localStorage的sicode列表 通过本地的sicode 定位在列表里面的下标
  const getSiCurrentList = localStorage.getItem('siCodeList')
    ? localStorage.getItem('siCodeList').split(',')
    : [];
  const [siCurrentIndex, setSiCurrentIndex] = useState(0);
  useEffect(() => {
    if (detailInfo && detailInfo.generalInformation) {
      setSiCurrentIndex(
        getSiCurrentList.indexOf(detailInfo.generalInformation[0].projectCode)
      );
    }
  }, [detailInfo]);
  const [siCurrentList, setSiCurrentList] = useState(getSiCurrentList);
  // 标题边上的编辑按钮
  // 用来判断是否有overview 上下跳转
  const url = window.location.search.split('=');

  const sipMarketing = {
    aimsAndScope:
      'test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline test Test decline ',
    topics: ['1', '2', '3'],
    keywords: ['test1', 'test1', 'test1'],
    reference: [],
  };

  return (
    <Box
      id='detailPageBody'
      data-selenium-id='SIDetail_PageBody'
      sx={{
        overflowX: 'scroll',
      }}
      onClick={handleSIDetailClick}
    >
      {/* 各个Section 目前有Journal Details和Editing History */}

      <WiderMain
        sx={{
          padding: '0 24px 0px 32px',
          margin: 0,
          marginLeft: open ? '240px' : '80px',
          boxSizing: 'border-box',
          backgroundColor: 'transparent',
          width: 'auto',
          '& *': {
            boxSizing: 'border-box',
          },
          //       transform: 'scale(0.9)', // 缩放为0.9
          // transformOrigin: 'top left', // 缩放的原点设置为左上角
        }}
        data-selenium-id='SIDetail_WiderMain'
      >
        {siCurrentList && url[url.length - 1] === 'overview' ? (
          <SiDetailToPreviewAndNext
            siListIndex={siCurrentIndex}
            siList={siCurrentList}
            data-selenium-id='SIDetail_ToPreviewAndNext'
          ></SiDetailToPreviewAndNext>
        ) : (
          <></>
        )}

        {/* SI title box */}
        <Stack
          direction='row'
          justifyContent='space-between'
          id='detailTitleBox'
          data-selenium-id='SIDetail_TitleBoxStack'
          width='100%'
          sx={{
            backgroundColor: '#ffffff',
            padding: '10px 32px 10px 32px',
            // marginBottom: '4px',
          }}
        >
          <SITitleBlock
            siCode={siCode}
            loadingData={loadingSIDetails}
            ifShelved={
              detailInfo.generalInformation !== undefined &&
              detailInfo.generalInformation[0].stage === 'Shelved'
            }
            data-selenium-id='SIDetail_TitleBlock'
          />
          {/* Upstage按钮 */}
          <Box id='detBtnBox' data-selenium-id='SIDetail_DetBtnBox'>
            {
              // remove Reactive Button(which show when stage = 'Shelved' & the user with 'admin:write' permission) in ct-6353

              // detailInfo.generalInformation !== undefined && !isUpdateShow
              //   ? (detailInfo.publicationDetails === null ||
              //       detailInfo.publicationDetails === undefined ||
              //       detailInfo.publicationDetails.length === 0) &&
              //     (detailInfo.allowReactive === 'Yes' ||
              //       detailInfo.allowReactive === null) && (
              //       <div>
              //         <ShowForPermission
              //           permission={`admin:write`}
              //           data-selenium-id='SIDetail_WrapReactiveBtn'
              //         >
              //           <SIMTButton
              //             id='reactivatedBtn'
              //             data-selenium-id='SIDetail_ReactivateBtn'
              //             variant='contained'
              //             onClick={handleReactivatedBoxOpen}
              //             sx={
              //               {
              //                 // "&MuiButtonBase-root": { BoxShadow: 'none', }
              //               }
              //             }
              //           >
              //             Reactivate
              //           </SIMTButton>
              //         </ShowForPermission>
              //         <ReactivatedBox
              //           open={reactivatedOpen}
              //           handlereactivatedBoxClose={handlereactivatedBoxClose}
              //           siId={siCode}
              //           data-selenium-id='SIDetail_ReactivateBox'
              //         ></ReactivatedBox>
              //       </div>
              //     )
              //   :
              detailInfo.generalInformation !== undefined &&
              detailInfo.generalInformation[0].stage !== 'Shelved' && (
                <div>
                  <ShowForPermission
                    permission={`${PERMISSIONS.SI_WRITE}`}
                    data-selenium-id='SIDetail_WarpUpdateStateBtn'
                  >
                    <SIMTButton
                      id='detActiveBtn'
                      data-selenium-id='SIDetail_DetActiveBtn'
                      variant='contained'
                      onClick={handleUpdateOpen}
                      sx={{
                        minWidth: '120px',
                        backgroundColor: '#0052CC',
                      }}
                    >
                      Update Stage
                    </SIMTButton>
                  </ShowForPermission>
                </div>
              )
            }
          </Box>
        </Stack>

        <Box
          paddingX='32px'
          paddingY='0px'
          sx={{
            backgroundColor: '#fff',
            // minWidth: '1092px',
          }}
          data-selenium-id='SIDetail_ContainerBox'
        >
          <GeneralInfoSection
            title={
              <FormattedMessage
                id='siDetail.generalInfo'
                data-selenium-id='SIDetail_GeneralInfo'
              />
            }
            // title=`${detailInfo.allowReactive}`
            siId={siCode}
            stageId={handleStageId()}
            loadingData={loadingSIDetails}
            addMarketSectionClicked={addMarketSectionClicked}
            setAddMarketSection={setAddMarketSection}
            setFirstOpenMarketSection={setFirstOpenMarketSection}
            sideMenuOpen={open}
            data-selenium-id='SIDetail_GeneralInfoSection'
          />

          <SipReferenceSection
            siCode={siCode || ''}
            data-selenium-id='SIDetail_ReferenceSection'
          />

          <SubmissionOverviewBlock
            title='Submission Overview'
            siCode={siCode}
            data={
              detailInfo.submissionOverview !== undefined
                ? detailInfo.submissionOverview
                : []
            }
            typeList={submissionOverviewType}
            snowFlakeInfo={snowFlakeInfo}
            extraInfo={handleExtraInfoForSubmission()}
            stage={
              detailInfo.generalInformation !== null
                ? detailInfo.generalInformation?.[0].stage
                : 'Shelved'
            }
            stageId={handleStageId()}
            loadingSIDetails={loadingSIDetails}
            data-selenium-id='SIDetail_SubmissionOverviewBlock'
          ></SubmissionOverviewBlock>
          <PublicationDetailsBlock
            title='Publication Detail'
            open={open}
            typeList={publicationDetailType}
            data={
              detailInfo.publicationDetails !== undefined
                ? detailInfo.publicationDetails
                : []
            }
            outputRateInfo={
              detailInfo.outputRate !== undefined ? detailInfo.outputRate : []
            }
            outputRate={OutputRate}
            isOA={isOA}
            extraInfo={handleExtraInfoForPublish()}
            loadingSIDetails={loadingSIDetails}
            data-selenium-id='SIDetail_PublicationDetailBlock'
          />
          <ShowForPermission permission={`${PERMISSIONS.ADMIN_WRITE} ${PERMISSIONS.CHINA_MARKETING_WRITE} ${PERMISSIONS.CHINA_MARKETING_READ} ${PERMISSIONS.SI_EXPORT}`}>
            <ChinaMarketingBlock
              title='China Marketing'
              siCode={siCode}
              mode='read'
            />
          </ShowForPermission>
          {addMarketSection && (
            <MarketSection
              id='MarketSection'
              data-selenium-id='SIDetail_MarketSection'
              addMarketSection={addMarketSection}
              setAddMarketSection={setAddMarketSection}
              firstOpenMarketSection={firstOpenMarketSection}
              detailInfo={detailInfo}
            />
          )}
          <JournalDetailsBlock
            title={
              <FormattedMessage
                id='siDetail.journalDetails'
                data-selenium-id='SIDetail_JournalDetailsBlock'
              />
            }
            infoObj={
              detailInfo.journalDetails !== undefined
                ? detailInfo.journalDetails
                : []
            }
            ObjList={journalDetList}
            open={open}
          />
          <EditingHistoryBlock
            SIid={siCode}
            open={open}
            data-selenium-id='SIDetail_EditingHistoryBlock'
          />
        </Box>
        <ScrollTop
          {...props}
          data-selenium-id='SIDetail_ScrollTop'
          getWindow={() => document.getElementById('outer')}
        >
          <Fab
            size='medium'
            variant='extended'
            color='primary'
            aria-label='scroll back to top'
            disableFocusRipple
            disableRipple
            id={'BackToTopButton'}
            data-selenium-id='SIDetail_BackToTopBtn'
            sx={{
              backgroundColor: '#0052CC',
              borderRadius: '30px',
              [`&.${fabClasses.root}`]: {
                textTransform: 'none',
                boxShadow: 'none',
                fontWeight: 400,
              },
              '&:hover': {
                backgroundColor: 'primary.main',
              },
            }}
          >
            Back To Top &nbsp;
            <BackToTop />
          </Fab>
        </ScrollTop>
      </WiderMain>
      <UntitledAlertBox
        open={aiAcquisitionCreateSuccessAlert}
        handleClose={() => {
          dispatch(setAIAcquisitionCreateSuccessAlert(false, 1));
        }}
        message='You Created a Single SI successfully'
        severity='success'
      />
    </Box>
  );
}

export default SIDetailPage;

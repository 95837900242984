import {
  Typography,
  Container,
  Box,
  Stack,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import React, {
  useState,
  useRef,
  createRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { TranformToSiItemBlock } from '../../CustomComponent/CustomBlock';
import {
  AcquiredSiCreationSectionContainer,
  SiOperationEditContainer,
} from '../../CustomComponent/CustomContainer';
import { AcquiredSiCreationHeading } from '../../CustomComponent/Typography';
import { createRow } from '@/pages/SIPPage/RevisePage/Section/GEInformationSection';
import ReadTable from '@/pages/SIPPage/SIPDetailPage/Submodule/ReadTable';
import { BootstrapSelect } from '../../CustomComponent/Select';
import moment from 'moment';
import { GEDetailModalForTransform } from '../../CustomComponent/Modal';
import { geInformationForTransformInitialValue } from './DuplicateGeCheck';
// @ts-ignore
import SIPActionsView from '@/assets/SIPActionsView.svg';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_SI } from '@/actions/SIMT-SI/SpecialIssue/SIAction';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateEffect } from 'ahooks';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
// import CreateSISinple from '@/componentsphase2/Autocomplete/CreateSISinple';
import styleModule from './TransformSteps.module.scss';
import { DatePicker } from '@/components/DateRangePicker/DatePicker';
import CustomDatePicker from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/CustomDatePicker';

import { loadSITypes } from "@/actions/SIMT-User/Config";
import { createSIInAcquited } from "@/actions/SIMT-SI/SpecialIssue";
const tableHeads = [
  createRow('leadGe', 'LEAD GE', '64px', '128px', false, 'left'),
  createRow('jobTitle', 'JOB TITLE', '96px', '256px', false),
  createRow('fullName', 'FULL NAME', '96px', '256px', false),
  createRow('actions', 'ACTIONS', '64px', 'auto', false, 'center'),
];

const siPublicationFormatOptions = [
  {
    value: 'Regular Issue Replacement',
  },
  {
    value: 'Special Section',
  },
  {
    value: 'Supplement',
  },
  {
    value: 'Virtual Issue',
  },
];
const siWorkflowOptions = [
  {
    value: 'GE Has no Decision Role in Peer Review',
  },
  {
    value: 'GE Makes Final Decision',
  },
  {
    value: 'GE Works as Temporary AE',
  },
];

/** @type {import("./AcquiredSiCreation").AcquiredSiDetailVO} */
export const acquiredSiDetailTestData = {
  stage: 'Acquired',
  handlingCe: 1234,
  handlingCeName: 'Fake Name',
  ideaDate: '2022-12-27 18:18:31',
  acquiredDate: '2023-01-03 12:38:56',
  journalName: 'Die Geowissenschaften',
  journalCode: '2034',
  specialIssueTitle: 'Test decline ',
  siSource: 'SI Proposal Platform',
  expectedSubmissionDeadline: '2022-12-26 05:14:09',
  expectedAcceptanceDate: '2022-12-26 05:14:13',
  expectedPublicationDate: '2022-12-26 05:14:15',
  paperCommissioningMethod: 'Open Call',
};
/** @type {import("./AcquiredSiCreation").AcquiredSiDetail} */
export const acquiredSiDetailInitialValue = {
  stage: '',
  handlingCe: 0,
  handlingCeName: '',
  ideaDate: '',
  acquiredDate: '',
  journalName: '',
  journalCode: '',
  specialIssueTitle: '',
  siSource: '',
  expectedSubmissionDeadline: '',
  expectedAcceptanceDate: '',
  expectedPublicationDate: '',
  paperCommissioningMethod: '',
  geInformation: [],
};

/**
 * @type {React.FC<import("./AcquiredSiCreation").AcquiredSiCreationProps>}
 */
const AcquiredSiCreation = React.forwardRef((props, ref) => {
  const { acquiredSiDetail, setIsButtonDisabled, setValue, isShow } = props;
  const dispatch = useDispatch();

  const { createSiResult, sipType, SITypeList } = useSelector(state => ({
    /** @type {import("./AcquiredSiCreation").CreateSiResult} */
    // @ts-ignore
    createSiResult: state.SI.createSIResult,
    sipType: state.SIP.sipInformation?.sipType,
    SITypeList: state.SystemConfig.SITypeList,
  }));

  useEffect(() => {
    dispatch(loadSITypes());
  }, []);

  const matches744 = useMediaQuery('(min-width:744px)');
  /**
   * @param {string | null} dateStr
   * @param {"ISO"| "YYYY-MM-DD"} format
   */
  const formatDate = (dateStr, format, after = false) => {
    if (after) {
      if (dateStr === null) return '';
      const momentObj = moment(dateStr).utc();
      const nextDay = momentObj.add(1, 'day');
      if (format === 'YYYY-MM-DD') {
        const res = nextDay.format('YYYY-MM-DD').toString();
        return res;
      }
      const res = nextDay.utc().toISOString();
      return res;
    } else {
      if (dateStr === null) return '';
      if (format === 'YYYY-MM-DD') {
        const loaclDate = new Date(dateStr);
        const res = moment(loaclDate).utc().format('YYYY-MM-DD').toString();
        return res;
      }
      const loaclDate = new Date(dateStr);
      const res = moment(loaclDate).utc().toISOString();
      return res;
    }
  };

  const formatDateForDatePicker = (dateStr, format) => {
    if (dateStr === null) return '';
    if (format === 'YYYY-MM-DD') {
      const res = moment(dateStr).utc().format('YYYY-MM-DD').toString();
      return res;
    }
    const res = moment(dateStr).toISOString();
    return res;
  };

  const [geDetailModalOpen, setGeDetailModalOpen] = useState(false);
  const [geDetailModalData, setGeDetailModalData] = useState(
    /** @type {import("./DuplicateGeCheck").GeInformationForTransform} */(
      geInformationForTransformInitialValue
    )
  );

  /**
   * @returns {import("@/pages/SIPPage/SIPDetailPage/Submodule/ReadTable").TableDataItem[]}
   */
  const geInfoToTableData = () => {
    return acquiredSiDetail.geInformation.map((geInfoItem, index) => {
      return {
        leadGe: geInfoItem.leadGe ? 'Yes' : 'No',
        jobTitle: geInfoItem.jobTitle,
        fullName: `${geInfoItem.firstName || ''}${geInfoItem.firstName !== null ? ', ' : ''
          }${geInfoItem.lastName || ''}`,
        emailAddress: geInfoItem.emailAddress,
        country: geInfoItem.country,
        profileWebsite: geInfoItem.profileWebsite,
        cv: <></>,
        actions: (
          <Stack
            direction='row'
            justifyContent={'center'}
            data-selenium-id='SIPPage_TransformToSl-Submodule-AcquiredSiCreation-Stack'
          >
            <IconButton
              data-selenium-id='SIPPage_TransformToSl-Submodule-AcquiredSiCreation-Stack-IconButton'
              onClick={() => {
                setGeDetailModalData(acquiredSiDetail.geInformation[index]);
                setGeDetailModalOpen(true);
              }}
              className={styleModule.SIPActionsView} //modified by HJX
            >
              {/* <SIPActionsView data-selenium-id='SIPPage_TransformToSl-Submodule-AcquiredSiCreation-Stack-ActionsView' /> */}
            </IconButton>
          </Stack>
        ),
      };
    });
  };

  const [siPublicationFormat, setSiPublicationFormat] = useState('');
  const [siPublicationFormatError, setSiPublicationFormatError] =
    useState(false);

  const [workflow, setWorkflow] = useState('');
  const [workflowError, setWorkflowError] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const checkDataValidation = () => {
    const isInvalid = siPublicationFormatError || workflowError;

    setShowErrorMessage(isInvalid);

    return !isInvalid;
  };

  const [acceptanceDeadline, setAcceptanceDeadline] = useState('');
  const [acceptanceDeadlineError, setAcceptanceDeadlineError] = useState(false);

  const [expectedPublicationDate, setExpectedPublicationDate] = useState('');
  const [expectedPublicationDateError, setExpectedPublicationDateError] =
    useState(false);

  const isEmpty = value => {
    return (
      value === '' ||
      value === [] ||
      value === null ||
      value === undefined ||
      value === {} ||
      // 防止输入全为空格的情况
      (typeof value === 'string' && !value.trim().length)
    );
  };

  const handleAcceptanceDeadlineChange = value => {
    setAcceptanceDeadline(value);
    setAcceptanceDeadlineError(isEmpty(acceptanceDeadline));
    acquiredSiDetail.expectedAcceptanceDate = formatDate(value, 'ISO');
    // acquiredSiDetail.expectedAcceptanceDate = value;
  };

  const handleExpectedPublicationDateChange = value => {
    setExpectedPublicationDate(value);
    setExpectedPublicationDateError(isEmpty(expectedPublicationDate));
    acquiredSiDetail.expectedPublicationDate = formatDate(value, 'ISO');
    // acquiredSiDetail.expectedPublicationDate = value;
  };

  useUpdateEffect(() => {
    setIsButtonDisabled(
      workflowError || expectedPublicationDateError || acceptanceDeadlineError
    );
  }, [workflowError, expectedPublicationDateError, acceptanceDeadlineError]);
  useImperativeHandle(ref, () => ({
    errorMonitor: () => {
      if (!checkDataValidation() || sipCode === undefined) return true;
      return false;
    },
  }));

  const navigate = useNavigate();
  const { sipCode } = useParams();

  useEffect(() => {
    setValue({
      siPublicationFormat: siPublicationFormat,
      workflow: workflow,
      acceptanceDeadline: acceptanceDeadline,
      expectedPublicationDate: expectedPublicationDate,
    });
  }, [
    siPublicationFormat,
    workflow,
    acceptanceDeadline,
    expectedPublicationDate,
  ]);

  useEffect(() => {
    if (createSiResult === '' || createSiResult === 'pending') return;

    if (createSiResult !== 'error' && createSiResult.code === 200)
      navigate(`/simt/auth/siRelated/si/detail/${createSiResult.data}`);

    dispatch({ type: CREATE_SI, data: '' });
  }, [createSiResult]);
  const paddingY = 59;
  const [paddingBottom, setPaddingBottom] = useState(paddingY);

  const initData = () => {
    setAcceptanceDeadline(
      acquiredSiDetail.expectedAcceptanceDate
        ? acquiredSiDetail.expectedAcceptanceDate
        : ''
    );
    setExpectedPublicationDate(
      acquiredSiDetail.expectedPublicationDate
        ? acquiredSiDetail.expectedPublicationDate
        : ''
    );
  };

  const SIInformation = [
    {
      title: 'Special Issue Title',
      content: acquiredSiDetail.specialIssueTitle || '',
    },
    {
      title: 'Special Issue Source',
      content: acquiredSiDetail.siSource || '',
    },
    {
      title: 'Submission Deadline',
      content: formatDate(
        acquiredSiDetail.expectedSubmissionDeadline,
        'YYYY-MM-DD'
      ),
    },
  ];
  // if (sipType !== 'Yes') {
  SIInformation.push(
    {
      title: 'Expected Accepted Date',
      content: formatDate(
        acquiredSiDetail.expectedAcceptanceDate,
        'YYYY-MM-DD'
      ),
    },
    {
      title: 'Expected Publication Date',
      content: formatDate(
        acquiredSiDetail.expectedPublicationDate,
        'YYYY-MM-DD'
      ),
    }
  );
  // }

  useEffect(() => {
    initData();
  }, []);

  return (
    <Box
      data-selenium-id='SIPPage_TransformToSl-Submodule-AcquiredSiCreation-Box'
      sx={{
        paddingY: `${paddingY}px`,
        margin: '0 auto',
        paddingBottom: paddingBottom + 'px',
        display: isShow ? 'block' : 'none',
      }} //modified by HJX
      minWidth={matches744 ? '744px' : 'auto'}
      paddingX='32px'
    >
      <AcquiredSiCreationHeading variant='primary' marginBottom={'24px'}>
        Required Details for Acquired SI
      </AcquiredSiCreationHeading>
      <Stack
        spacing='28px'
        data-selenium-id='SIPPage_TransformToSl-Submodule-AcquiredSiCreation-Box-DateStack'
        className={
          styleModule[
          'SIPPage_TransformToSl-Submodule-AcquiredSiCreation-Box-Stack'
          ]
        }
      >
        <AcquiredSiCreationSectionContainer
          sectionTitle='SI Status'
          type='itemList'
        >
          {[
            {
              title: 'Handling CE',
              content: acquiredSiDetail.handlingCeName || '',
            },
            {
              title: 'Idea Date',
              content: formatDate(acquiredSiDetail.ideaDate, 'YYYY-MM-DD'),
            },
            {
              title: 'Acquired Date',
              content: formatDate(acquiredSiDetail.acquiredDate, 'YYYY-MM-DD'),
            },
          ]}
        </AcquiredSiCreationSectionContainer>
        {/* <AcquiredSiCreationSectionContainer
          sectionTitle='Object'
          type='itemList'
        >
          {[
            {
              title: 'Journal Name',
              content: acquiredSiDetail.journalName || '',
            },
            {
              title: 'Journal Code',
              content: acquiredSiDetail.journalCode || '',
            },
          ]}
        </AcquiredSiCreationSectionContainer> */}
        <AcquiredSiCreationSectionContainer
          sectionTitle='SI Information'
          type='itemList'
        >
          {SIInformation}
        </AcquiredSiCreationSectionContainer>
        <AcquiredSiCreationSectionContainer
          sectionTitle='Content Commissioning Approaches'
          type='itemList'
        >
          {[
            {
              title: 'Paper Commissioning Method',
              content: acquiredSiDetail.paperCommissioningMethod || '',
            },
          ]}
        </AcquiredSiCreationSectionContainer>
        <AcquiredSiCreationSectionContainer
          sectionTitle='GE Information'
          type='commonBlock'
          sx={{
            '& #AcquiredSiCreationGEInformationTable.MuiTableContainer-root': {
              backgroundColor: 'transparent',
              boxShadow: 'none', //modified by HJX
            },
          }}
        >
          <ReadTable
            id='AcquiredSiCreationGEInformationTable'
            data-selenium-id='SIPPage_TransformToSl-Submodule-AcquiredSiCreation-GEInformationTable'
            TableHeads={tableHeads}
            TableData={geInfoToTableData()}
          />
        </AcquiredSiCreationSectionContainer>
        <AcquiredSiCreationSectionContainer
          sectionTitle='SI Operation'
          type='commonBlock'
        >
          <Stack
            spacing='8px'
            data-selenium-id='SIPPage_TransformToSl-Submodule-AcquiredSiCreation-Box-OpreationEditStack'
          >
            <SiOperationEditContainer title='SI Publication Format' required>
              <BootstrapSelect
                placeholder='Select SI Publication Format'
                fullWidth
                options={SITypeList}
                value={siPublicationFormat}
                onChange={event => setSiPublicationFormat(event.target.value)}
                required
                showErrorMessage={showErrorMessage}
                setError={setSiPublicationFormatError}
                index={1}
                setPaddingBottom={setPaddingBottom}
              />
              {/* <SinpleAutocomplete
                placeholder='Select SI Publication Format'
                fullWidth
                options={siPublicationFormatOptions.map((item) => (item.label || item.value))}
                value={siPublicationFormat}
                onChange={event => setSiPublicationFormat(event.target.value)}
                required
                isErr={showErrorMessage}
                setError={setSiPublicationFormatError}
                defaultQuery={false}
              // loadOptionAction={()=>{}}
              /> */}
            </SiOperationEditContainer>
            <SiOperationEditContainer title='Workflow' required>
              <BootstrapSelect
                placeholder='Select Workflow'
                fullWidth
                options={siWorkflowOptions}
                value={workflow}
                onChange={event => setWorkflow(event.target.value)}
                required
                showErrorMessage={showErrorMessage}
                setError={setWorkflowError}
                index={2}
                setPaddingBottom={setPaddingBottom}
              />
            </SiOperationEditContainer>
            {
              // sipType === 'Yes' &&
              (
                <>
                  <SiOperationEditContainer title='Acceptance Deadline' required>
                    <CustomDatePicker
                      placeholder='Select Acceptance Deadline'
                      width='full'
                      value={
                        acceptanceDeadline
                          ? formatDate(acceptanceDeadline, 'YYYY-MM-DD')
                          : ''
                      }
                      onChange={(newValue, newDate) =>
                        handleAcceptanceDeadlineChange(newValue)
                      }
                      error={isEmpty(acceptanceDeadline)}
                      handleFocus={() => { }}
                      handleBlur={() => { }}
                    />
                  </SiOperationEditContainer>
                  <SiOperationEditContainer
                    title='Expected Publication Date'
                    required
                  >
                    <CustomDatePicker
                      placeholder='Select Expected Publication Date'
                      width='full'
                      value={
                        expectedPublicationDate
                          ? formatDate(expectedPublicationDate, 'YYYY-MM-DD')
                          : ''
                      }
                      onChange={(newValue, newDate) =>
                        handleExpectedPublicationDateChange(newValue)
                      }
                      // error={expectedPublicationDateError}
                      error={isEmpty(expectedPublicationDate)}
                      handleFocus={() => { }}
                      handleBlur={() => { }}
                    />
                  </SiOperationEditContainer>
                </>
              )}
          </Stack>
        </AcquiredSiCreationSectionContainer>
      </Stack>
      <GEDetailModalForTransform
        open={geDetailModalOpen}
        geInformation={geDetailModalData}
        handleClose={() => setGeDetailModalOpen(false)}
      />
    </Box>
  );
});
export default AcquiredSiCreation;

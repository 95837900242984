import * as actions from '@/actions/SIMT-Report/ReportFilter/ReportFilterAction';
import MyLocalStorage from '../utils/MyLocalStorage';

const initState = {
  reportAcquireOverviewJournal: MyLocalStorage.getItem('ReportFilterReducer-reportAcquireOverviewJournal') ?? [],
  reportAcquireOverviewrevenueModel: MyLocalStorage.getItem('ReportFilterReducer-reportAcquireOverviewrevenueModel') ?? false,
  reportAcquireOverviewJPM: MyLocalStorage.getItem('ReportFilterReducer-reportAcquireOverviewJPM') ?? [],
  reportAcquireOverviewPDPublisher: MyLocalStorage.getItem('ReportFilterReducer-reportAcquireOverviewPDPublisher') ?? [],
  reportAcquireOverviewPPPublisher: MyLocalStorage.getItem('ReportFilterReducer-reportAcquireOverviewPPPublisher') ?? [],
  reportAcquireOverviewSubjectGroup: MyLocalStorage.getItem('ReportFilterReducer-reportAcquireOverviewSubjectGroup') ?? [],
  reportAcquireOverviewBusinessDivision: MyLocalStorage.getItem('ReportFilterReducer-reportAcquireOverviewBusinessDivision') ?? [],
  reportAcquireOverviewCATGroup: MyLocalStorage.getItem('ReportFilterReducer-reportAcquireOverviewCATGroup') ?? [],
  reportAcquireOverviewHandlingCE: MyLocalStorage.getItem('ReportFilterReducer-reportAcquireOverviewHandlingCE') ?? [],
  reportAcquireOverviewJournalStatus: MyLocalStorage.getItem('ReportFilterReducer-reportAcquireOverviewJournalStatus') ?? [],

  reportAcquireOverviewFilters: MyLocalStorage.getItem('ReportFilterReducer-reportAcquireOverviewFilters') ?? {
    journalName: [],
    revenueModel: [],
    jpm: [],
    subjectGroup: [],
    businessDivision: [],
    catGroup: [],
    handlingCe: [],
  },

  articleOverviewJournal: MyLocalStorage.getItem('ReportFilterReducer-articleOverviewJournal') ?? [],
  articleOverviewrevenueModel: MyLocalStorage.getItem('ReportFilterReducer-articleOverviewrevenueModel') ?? [],
  articleOverviewJPM: MyLocalStorage.getItem('ReportFilterReducer-articleOverviewJPM') ?? false,
  articleOverviewPDPublisher: MyLocalStorage.getItem('ReportFilterReducer-articleOverviewPDPublisher') ?? [],
  articleOverviewPPPublisher: MyLocalStorage.getItem('ReportFilterReducer-articleOverviewPPPublisher') ?? [],
  articleOverviewSubjectGroup: MyLocalStorage.getItem('ReportFilterReducer-articleOverviewSubjectGroup') ?? [],
  articleOverviewBusinessDivision: MyLocalStorage.getItem('ReportFilterReducer-articleOverviewBusinessDivision') ?? [],
  articleOverviewCATGroup: MyLocalStorage.getItem('ReportFilterReducer-articleOverviewCATGroup') ?? [],
  articleOverviewHandlingCE: MyLocalStorage.getItem('ReportFilterReducer-articleOverviewHandlingCE') ?? [],
  articleOverviewJournalStatus: MyLocalStorage.getItem('ReportFilterReducer-articleOverviewJournalStatus') ?? [],

  articleOverviewFilters: MyLocalStorage.getItem('ReportFilterReducer-articleOverviewFilters') ?? {
    journalCode: [],
    revenueModel: [],
    jpm: [],
    subjectGroup: [],
    businessDivision: [],
    catGroup: [],
    handlingCe: [],
  },


  articleOAWaiverUseJournal: [],
  articleOAWaiverUserevenueModel: false,
  articleOAWaiverUseJPM: [],
  articleOAWaiverUsePDPublisher: [],
  articleOAWaiverUsePPPublisher: [],
  articleOAWaiverUseSubjectGroup: [],
  articleOAWaiverUseBusinessDivision: [],
  articleOAWaiverUseCATGroup: [],
  articleOAWaiverUseHandlingCE: [],
  articleOAWaiverUseJournalStatus: [],
  articleOAWaiverUseFilters: {
    journalCode: [],
    revenueModel: [],
    jpm: [],
    subjectGroup: [],
    businessDivision: [],
    catGroup: [],
    handlingCe: [],
    journalStatus: [],
  },

  articleOAPJournal: [],
  articleOAPrevenueModel: false,
  articleOAPJPM: [],
  articleOAPPDPublisher: [],
  articleOAPPPPublisher: [],
  articleOAPSubjectGroup: [],
  articleOAPBusinessDivision: [],
  articleOAPCATGroup: [],
  articleOAPHandlingCE: [],
  articleOAPJournalStatus: [],
  articleOAPFilters: {
    journalCode: [],
    revenueModel: [],
    jpm: [],
    pdPublisher: [],
    ppPublisher: [],
    subjectGroup: [],
    businessDivision: [],
    catGroup: [],
    handlingCe: [],
    journalStatus: [],
  },
};

export default function ReportFilterReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.LOAD_DATA: {
      // 因为没有分页需求了，所以删除了处理分页的代码
      return {
        ...state,
        [data.which]: data.data.result,
      };
    }
    case actions.SET_ACQUIRE_FILTER_SELECT: {
      // 其他属性的set
      switch (data.which) {
        case 'reportJournal': {
          MyLocalStorage.setItem('ReportFilterReducer-reportAcquireOverviewJournal', data.data);
          return {
            ...state,
            reportAcquireOverviewJournal: data.data,
          };
        }
        case 'revenueModel': {
          MyLocalStorage.setItem('ReportFilterReducer-reportAcquireOverviewrevenueModel', data.data);
          return {
            ...state,
            reportAcquireOverviewrevenueModel: data.data,
          };
        }
        case 'reportJPM': {
          MyLocalStorage.setItem('ReportFilterReducer-reportAcquireOverviewJPM', data.data);
          return {
            ...state,
            reportAcquireOverviewJPM: data.data,
          };
        }
        case 'reportPDPublisher': {
          MyLocalStorage.setItem('ReportFilterReducer-reportAcquireOverviewPDPublisher', data.data);
          return {
            ...state,
            reportAcquireOverviewPDPublisher: data.data,
          };
        }
        case 'reportPPPublisher': {
          MyLocalStorage.setItem('ReportFilterReducer-reportAcquireOverviewPPPublisher', data.data);
          return {
            ...state,
            reportAcquireOverviewPPPublisher: data.data,
          };
        }
        case 'reportSubjectGroup': {
          MyLocalStorage.setItem('ReportFilterReducer-reportAcquireOverviewSubjectGroup', data.data);
          return {
            ...state,
            reportAcquireOverviewSubjectGroup: data.data,
          };
        }
        case 'reportBusinessDivision': {
          MyLocalStorage.setItem('ReportFilterReducer-reportAcquireOverviewBusinessDivision', data.data);
          return {
            ...state,
            reportAcquireOverviewBusinessDivision: data.data,
          };
        }
        case 'reportCATGroup': {
          MyLocalStorage.setItem('ReportFilterReducer-reportAcquireOverviewCATGroup', data.data);
          return {
            ...state,
            reportAcquireOverviewCATGroup: data.data,
          };
        }
        case 'reportHandlingCE': {
          MyLocalStorage.setItem('ReportFilterReducer-reportAcquireOverviewHandlingCE', data.data);
          return {
            ...state,
            reportAcquireOverviewHandlingCE: data.data,
          };
        }
        case 'reportJournalStatus': {
          MyLocalStorage.setItem('ReportFilterReducer-reportAcquireOverviewJournalStatus', data.data);
          return {
            ...state,
            reportAcquireOverviewJournalStatus: data.data,
          };
        }
        default:
          return {
            ...state,
            [data.which + 'Selected']: data.data,
          };
      }
    }
    case actions.SET_ACQUIRE_OVERVIEW_FILTE:
      MyLocalStorage.setItem('ReportFilterReducer-reportAcquireOverviewFilters', data);
      return {
        ...state,
        reportAcquireOverviewFilters: data,
      };
    case actions.ACQUIRE_OVERVIEW_FILTER_RESET:
      MyLocalStorage.removeItem('ReportFilterReducer-reportAcquireOverviewJournal');
      MyLocalStorage.removeItem('ReportFilterReducer-reportAcquireOverviewrevenueModel');
      MyLocalStorage.removeItem('ReportFilterReducer-reportAcquireOverviewJPM');
      MyLocalStorage.removeItem('ReportFilterReducer-reportAcquireOverviewPDPublisher');
      MyLocalStorage.removeItem('ReportFilterReducer-reportAcquireOverviewPPPublisher');
      MyLocalStorage.removeItem('ReportFilterReducer-reportAcquireOverviewSubjectGroup');
      MyLocalStorage.removeItem('ReportFilterReducer-reportAcquireOverviewBusinessDivision');
      MyLocalStorage.removeItem('ReportFilterReducer-reportAcquireOverviewCATGroup');
      MyLocalStorage.removeItem('ReportFilterReducer-reportAcquireOverviewHandlingCE');
      MyLocalStorage.removeItem('ReportFilterReducer-reportAcquireOverviewJournalStatus');

      return {
        ...state,
        reportAcquireOverviewJournal: [],
        reportAcquireOverviewrevenueModel: false,
        reportAcquireOverviewJPM: [],
        reportAcquireOverviewPDPublisher: [],
        reportAcquireOverviewPPPublisher: [],
        reportAcquireOverviewSubjectGroup: [],
        reportAcquireOverviewBusinessDivision: [],
        reportAcquireOverviewCATGroup: [],
        reportAcquireOverviewHandlingCE: [],
        reportAcquireOverviewJournalStatus: [],
      };
    case actions.SET_ARTICLE_FILTER_SELECT: {
      // 其他属性的set
      switch (data.which) {
        case 'reportJournal': {
          MyLocalStorage.setItem('ReportFilterReducer-articleOverviewJournal', data.data);
          return {
            ...state,
            articleOverviewJournal: data.data,
          };
        }
        case 'revenueModel': {
          MyLocalStorage.setItem('ReportFilterReducer-articleOverviewrevenueModel', data.data);
          return {
            ...state,
            articleOverviewrevenueModel: data.data,
          };
        }
        case 'reportJPM': {
          MyLocalStorage.setItem('ReportFilterReducer-articleOverviewJPM', data.data);
          return {
            ...state,
            articleOverviewJPM: data.data,
          };
        }
        case 'reportPDPublisher': {
          MyLocalStorage.setItem('ReportFilterReducer-articleOverviewPDPublisher', data.data);
          return {
            ...state,
            articleOverviewPDPublisher: data.data,
          };
        }
        case 'reportPPPublisher': {
          MyLocalStorage.setItem('ReportFilterReducer-articleOverviewPPPublisher', data.data);
          return {
            ...state,
            articleOverviewPPPublisher: data.data,
          };
        }
        case 'reportSubjectGroup': {
          MyLocalStorage.setItem('ReportFilterReducer-articleOverviewSubjectGroup', data.data);
          return {
            ...state,
            articleOverviewSubjectGroup: data.data,
          };
        }
        case 'reportBusinessDivision': {
          MyLocalStorage.setItem('ReportFilterReducer-articleOverviewBusinessDivision', data.data);
          return {
            ...state,
            articleOverviewBusinessDivision: data.data,
          };
        }
        case 'reportCATGroup': {
          MyLocalStorage.setItem('ReportFilterReducer-articleOverviewCATGroup', data.data);
          return {
            ...state,
            articleOverviewCATGroup: data.data,
          };
        }
        case 'reportHandlingCE': {
          MyLocalStorage.setItem('ReportFilterReducer-articleOverviewHandlingCE', data.data);
          return {
            ...state,
            articleOverviewHandlingCE: data.data,
          };
        }
        case 'reportJournalStatus': {
          MyLocalStorage.setItem('ReportFilterReducer-articleOverviewJournalStatus', data.data);
          return {
            ...state,
            articleOverviewJournalStatus: data.data,
          };
        }
        default:
          return {
            ...state,
            [data.which + 'Selected']: data.data,
          };
      }
    }
    case actions.SET_ARTICLE_OAWAIVERUSE_FILTER_SELECT: {
      // 其他属性的set
      switch (data.which) {
        case 'reportJournal': {
          return {
            ...state,
            articleOAWaiverUseJournal: data.data,
          };
        }
        case 'revenueModel': {
          return {
            ...state,
            articleOAWaiverUserevenueModel: data.data,
          };
        }
        case 'reportJPM': {
          return {
            ...state,
            articleOAWaiverUseJPM: data.data,
          };
        }
        case 'reportPDPublisher': {
          return {
            ...state,
            articleOAWaiverUsePDPublisher: data.data,
          };
        }
        case 'reportPPPublisher': {
          return {
            ...state,
            articleOAWaiverUsePPPublisher: data.data,
          };
        }
        case 'reportSubjectGroup': {
          return {
            ...state,
            articleOAWaiverUseSubjectGroup: data.data,
          };
        }
        case 'reportBusinessDivision': {
          return {
            ...state,
            articleOAWaiverUseBusinessDivision: data.data,
          };
        }
        case 'reportCATGroup': {
          return {
            ...state,
            articleOAWaiverUseCATGroup: data.data,
          };
        }
        case 'reportHandlingCE': {
          return {
            ...state,
            articleOAWaiverUseHandlingCE: data.data,
          };
        }
        case 'reportJournalStatus': {
          return {
            ...state,
            articleOAWaiverUseJournalStatus: data.data,
          };
        }
        default:
          return {
            ...state,
            [data.which + 'Selected']: data.data,
          };
      }
    }
    case actions.SET_ARTICLE_OAP_FILTER_SELECT: {
      // 其他属性的set
      switch (data.which) {
        case 'reportJournal': {
          return {
            ...state,
            articleOAPJournal: data.data,
          };
        }
        case 'revenueModel': {
          return {
            ...state,
            articleOAPrevenueModel: data.data,
          };
        }
        case 'reportJPM': {
          return {
            ...state,
            articleOAPJPM: data.data,
          };
        }
        case 'reportPDPublisher': {
          return {
            ...state,
            articleOAPPDPublisher: data.data,
          };
        }
        case 'reportPPPublisher': {
          return {
            ...state,
            articleOAPPPPublisher: data.data,
          };
        }
        case 'reportSubjectGroup': {
          return {
            ...state,
            articleOAPSubjectGroup: data.data,
          };
        }
        case 'reportBusinessDivision': {
          return {
            ...state,
            articleOAPBusinessDivision: data.data,
          };
        }
        case 'reportCATGroup': {
          return {
            ...state,
            articleOAPCATGroup: data.data,
          };
        }
        case 'reportHandlingCE': {
          return {
            ...state,
            articleOAPHandlingCE: data.data,
          };
        }
        case 'reportJournalStatus': {
          return {
            ...state,
            articleOAPJournalStatus: data.data,
          };
        }
        default:
          return {
            ...state,
            [data.which + 'Selected']: data.data,
          };
      }
    }
    case actions.SET_ARTICLE_OVERVIEW_FILTE:
      MyLocalStorage.setItem('ReportFilterReducer-articleOverviewFilters', data);
      return {
        ...state,
        articleOverviewFilters: data,
      };
    case actions.SET_ARTICLE_OAWAIVERUSE_FILTE:
      return {
        ...state,
        articleOAWaiverUseFilters: data,
      };
    case actions.SET_ARTICLE_OAP_FILTE:
      return {
        ...state,
        articleOAPFilters: data,
      };
    case actions.ARTICLE_OVERVIEW_FILTER_RESET:
      MyLocalStorage.removeItem('ReportFilterReducer-articleOverviewJournal');
      MyLocalStorage.removeItem('ReportFilterReducer-articleOverviewrevenueModel');
      MyLocalStorage.removeItem('ReportFilterReducer-articleOverviewJPM');
      MyLocalStorage.removeItem('ReportFilterReducer-articleOverviewPDPublisher');
      MyLocalStorage.removeItem('ReportFilterReducer-articleOverviewPPPublisher');
      MyLocalStorage.removeItem('ReportFilterReducer-articleOverviewSubjectGroup');
      MyLocalStorage.removeItem('ReportFilterReducer-articleOverviewBusinessDivision');
      MyLocalStorage.removeItem('ReportFilterReducer-articleOverviewCATGroup');
      MyLocalStorage.removeItem('ReportFilterReducer-articleOverviewHandlingCE');
      MyLocalStorage.removeItem('ReportFilterReducer-articleOverviewJournalStatus');
      return {
        ...state,
        articleOverviewJournal: [],
        articleOverviewrevenueModel: false,
        articleOverviewJPM: [],
        articleOverviewPDPublisher: [],
        articleOverviewPPPublisher: [],
        articleOverviewSubjectGroup: [],
        articleOverviewBusinessDivision: [],
        articleOverviewCATGroup: [],
        articleOverviewHandlingCE: [],
        articleOverviewJournalStatus: [],
      };
    case actions.ARTICLE_OAWAIVERUSE_FILTER_RESET:
      return {
        ...state,
        articleOAWaiverUseJournal: [],
        articleOAWaiverUserevenueModel: false,
        articleOAWaiverUseJPM: [],
        articleOAWaiverUsePDPublisher: [],
        articleOAWaiverUsePPPublisher: [],
        articleOAWaiverUseSubjectGroup: [],
        articleOAWaiverUseBusinessDivision: [],
        articleOAWaiverUseCATGroup: [],
        articleOAWaiverUseHandlingCE: [],
        articleOAWaiverUseJournalStatus: [],
      };
    case actions.ARTICLE_OAP_FILTER_RESET:
      return {
        ...state,
        articleOAPJournal: [],
        articleOAPrevenueModel: false,
        articleOAPJPM: [],
        articleOAPPDPublisher: [],
        articleOAPPPPublisher: [],
        articleOAPSubjectGroup: [],
        articleOAPBusinessDivision: [],
        articleOAPCATGroup: [],
        articleOAPHandlingCE: [],
        articleOAPJournalStatus: [],
      };
    default:
      return state;
  }
}

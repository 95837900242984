import WileyLogo from '../../../../assets/LoginAsset/WileyLogo.svg';
import EmailLogo from '../../../../assets/LoginAsset/EmailLogo.svg';
import styled from '@emotion/styled';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { UntitledAlertBox } from '@/components/Alert/AlertBox';
import {forgetPassEmail} from "@/actions/SIMT-User/Login";
import { resetPassFirstTime } from '@/actions/SIMT-User/Login/User';
import { sendActiveEmail } from '@/actions/UserAction';
import { getUserId } from '@/utils/getUserId';
const EmailSendTitleTypography = styled(Typography)({
  color: 'var(--gray-500, #596A7C)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '48px',
  fontFamily: 'Open Sans',
  fontWeight: '800',
  lineHeight: '125%',
  letterSpacing: '0.48px',
});

const InfoTypography = styled(Typography)({
  color: 'var(--gray-300, #BCC5CF)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  lineHeight: '140%',
});

const ResendTypography = styled(Typography)({
  color: 'var(--primary-500, #154AB6)',
  textAlign: 'center',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  fontWeight: 700,
  lineHeight: '18px',
  '&:hover': {
    fontWeight: 900
  },
});

const BackButton = styled(Button)({
  borderRadius: '5px',
  background: 'var(--primary-500, #0052CC)',
  color: 'var(--shades-white, #FFF)',
  textAlign: 'center',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  lineHeight: '18px',
  width: '400px',
  height: '40px',
  padding: '8px 12px',
  gap: '6px',
  '&:hover': {
    background: 'var(--primary-300, #6D98EE)',
  },
  '&:active': {
    background: 'var(--primary-600, #113D95)',
  },
});

const NotReceiveTypography = styled(Typography)({
  color: 'var(--gray-300, #BCC5CF)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  lineHeight: '125%',
});

const EmailSendPage = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const email = props.location?.state?.email ?? '';

  const handleResend = () => {
    if (email) {
		dispatch(sendActiveEmail(getUserId())
			// forgetPassEmail(email)
		);
      setAlertShow(true);
    }
    setAlertShow(true); // for reproduction
  };

  const [alertShow, setAlertShow] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        background: '#FFF',
        height: '100vh',
        width: '100vw',
      }}
      data-selenium-id='Login_NewLogin_EmailSendPage'
    >
      <div
        style={{ width: '311px', height: '100%', background: '#0052CC' }}
        data-selenium-id='Login_NewLogin_EmailSendPage-LeftPart'
      >
        <WileyLogo
          style={{
            marginLeft: '16px',
            marginTop: '16px',
          }}
        />
      </div>
      <div
        style={{
          width: 'calc(100% - 311px)',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
        data-selenium-id='Login_NewLogin_EmailSendPage-RightPart'
      >
        <div data-selenium-id='Login_NewLogin_EmailSendPage-RightPart-Content'
          style={{
            marginTop:'148px'
          }}
        >
          <div data-selenium-id='Login_NewLogin_EmailSendPage-RightPart-EmailSendTitle'>
            <EmailSendTitleTypography>
              Email has been sent!
            </EmailSendTitleTypography>
          </div>
          <div
            style={{ marginTop: '20px', width: '553px' }}
            data-selenium-id='Login_NewLogin_EmailSendPage-RightPart-Info'
          >
            <InfoTypography>
              Please check your email for detailed instructions on how to reset
              your password.
            </InfoTypography>
          </div>
          <div
            style={{ marginLeft: '80px', marginTop: '59px' }}
            data-selenium-id='Login_NewLogin_EmailSendPage-RightPart-EmailLogo'
          >
            <EmailLogo />
          </div>
          <div style={{ marginTop: '-27px' }}>
            <BackButton
              onClick={() => navigate('/simt/login')}
              disableRipple={true}
              data-selenium-id='Login_NewLogin_EmailSendPage-RightPart-BackButton'
            >
              BACK TO LOGIN
            </BackButton>
          </div>
          <div
            style={{
              marginTop: '19px',
              marginLeft:'6px',
              display: 'flex',
              flexDirection: 'row',
            }}
            data-selenium-id='Login_NewLogin_EmailSendPage-RightPart-NotReceiveLink'
          >
            <NotReceiveTypography>
              Did not receive the link?
            </NotReceiveTypography>
            <Button
              sx={{
                marginLeft: '32px',
                padding: '0',
                textTransform: 'none',
                '&:hover': {
                  background: 'transparent',
                },
              }}
              disableRipple
              disableTouchRipple
              onClick={handleResend}
            >
              <ResendTypography>Resend the email</ResendTypography>
            </Button>
          </div>
        </div>
        <UntitledAlertBox
          open={alertShow}
          autoHideDuration={3000}
          handleClose={() => setAlertShow(false)}
          message='The email has been successfully resent.'
          severity='success'
          style={{
            top: '20px',
            right: '20px',
          }}
        />
      </div>
    </div>
  );
};

export default EmailSendPage;

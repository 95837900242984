import { axios_instance } from "@/utils/axios_instance";
import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {
    filterUsed2SelectState,
    FIRST_LOAD_FAVOURITE,
    handleFavData,
    LOAD_FAVOURITE, RESET_FILTER,
    SAVE_FAVOURITE,
    SET_ADAPT_SIPFILTERSELECTED,
    SET_FAV_SIPFILTERSELECTED,
    SET_FILTER
} from "@/actions/SIMT-SIP/SIPFilter/SIPFilterAction";
import { prepareFilters } from "@/pages/SIPPage/Overview/Filter/utils";
import { getUserId } from "@/utils/getUserId";
import MyLocalStorage from "@/utils/MyLocalStorage";

export function saveFilters(filters, solicited = false, userId) {
    return async dispatch => {
        try {
            let res = await axios_instance.post(
                `/api/v1/user-service/users/${getUserId()}/filters/${'SIP'}`,
                {
                    ...filters, // 该接口不需要加key
                },
                {
                    params: {
                        solicited: solicited,
                    },
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: SAVE_FAVOURITE, data: true });
                return true;
            } else {
                dispatch({ type: SAVE_FAVOURITE, data: false });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'siPage.filterSaveFail',
                        {},
                        SEVERITIES.error
                    )
                );
                return false;
            }
        } catch (err) {
            dispatch({ type: SAVE_FAVOURITE, data: false });
            dispatch(
                setSnackbarMessageAndOpen('siPage.filterSaveFail', {}, SEVERITIES.error)
            );
            return false;
        }
    };
}

export function loadFavourite(
    setByTheWay = false,
    requiresSnackbarOpen = false,
    solicited = false,
    userId,
) {
    return async dispatch => {
        try {
            let res = await axios_instance.get(`/api/v1/user-service/users/${getUserId()}/filters/${'SIP'}`, {
                params: {
                    solicited: solicited,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                const favData = handleFavData(res.data.data);
                dispatch({ type: LOAD_FAVOURITE, data: favData });
                let sipFilterSelected = filterUsed2SelectState(favData)['sipFilterSelected'];
                MyLocalStorage.setItem(`SIP-Filter-${solicited?'solicited':'unSolicted'}-${userId}`, sipFilterSelected);
                dispatch({ type: SET_FILTER, data: prepareFilters(sipFilterSelected, true, false) });//reset后根据filter进行表格更新
                dispatch({ type: SET_ADAPT_SIPFILTERSELECTED, data: prepareFilters(sipFilterSelected, true, true) });
                // if (setByTheWay) {
                //     dispatch({ type: SET_FILTER, data: res.data?.data }); // Note: not favData
                // }
                return true;
            } else {
                dispatch({ type: LOAD_FAVOURITE, data: null });
                dispatch({ type: SET_FAV_SIPFILTERSELECTED, data: null });
                dispatch({ type: SET_ADAPT_SIPFILTERSELECTED, data: prepareFilters(null, true, true) });
                if (setByTheWay) {
                    // load failed, set the init filter
                    dispatch({ type: RESET_FILTER });
                }
                if (requiresSnackbarOpen) {
                    return false;
                }
            }
        } catch (err) {
            dispatch({ type: LOAD_FAVOURITE, data: null });
            if (requiresSnackbarOpen) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'siPage.filterLoadFail',
                        {},
                        SEVERITIES.error
                    )
                );
            }
            if (setByTheWay) {
                // load failed, set the init filter
                dispatch({ type: SET_FILTER, data: 'default' });
            }
        }
        return false;
    };
}

export function loadFavouriteFromLocalStorage(sipOrigin, userId) {
    return async dispatch => {
        const filterLocal = MyLocalStorage.getItem(`SIP-Filter-${sipOrigin}-${userId}`, null);
        console.info('filterLocal:', filterLocal);
        dispatch({ type: SET_FILTER, data: prepareFilters(filterLocal, true, false) });
    };
}

export function loadFavouriteFirst(requiresSnackbarOpen = false, solicited = false, userId) {
    return async dispatch => {
        try {
            let res = await axios_instance.get(`/api/v1/user-service/users/${getUserId()}/filters/${'SIP'}`, {
                params: {
                    solicited: solicited,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            if (res.data.code === 200) {
                const favData = handleFavData(res.data.data);
                const fav = filterUsed2SelectState(favData);
                dispatch({ type: FIRST_LOAD_FAVOURITE, data: fav });
                return fav['sipFilterSelected'];
            } else {
                dispatch({ type: FIRST_LOAD_FAVOURITE, data: null });
            }
        } catch (err) {
            dispatch({ type: FIRST_LOAD_FAVOURITE, data: null });
        }
        return null;
    };
}
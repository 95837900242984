import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {StateButton} from "@/componentsphase2/StateButton";
import {
    pureData,
    useFormContext,
    ValidateState, VALIDATE_KEY
} from "@/components/Form/form.d";

import {CHECKLIST_PROCEEDING, SNACKBAR_ID} from "@/pages/SIPPage/SIPCheckListPage/configable/constants";
import {excuteSave, excuteProceed, fireMessage, WARNING_CHECKPOINTS, getMessageInfo} from "./actions";

export interface CheckListPanelProps {
    hasFormChange?: boolean;
    setCheckCancel?: (value: boolean) => void;
    sipCode?: string;
}

export function CheckListPanel(props: CheckListPanelProps) {
    const [bottom, setBottom] = React.useState('0px');

    const form = useFormContext();
    const SnackbarId = form.get(SNACKBAR_ID);
    const messageInfo = getMessageInfo(SnackbarId, () => {}, '', '') || {};
    const { needRefresh = false } = messageInfo;

    React.useEffect(() => {
        setTimeout(() => {
            const handleScroll = () => {
                if (document.getElementById('outer') && document.getElementById('PageFooter')) {
                    const outer: HTMLElement = document.getElementById('outer') as HTMLElement;
                    const footer: HTMLElement = document.getElementById('PageFooter') as HTMLElement;

                    if (outer.scrollHeight - footer.scrollHeight - outer.clientHeight - outer.scrollTop > 0) {
                        setBottom('0px');
                    } else {
                        setBottom((-(outer.scrollHeight - footer.scrollHeight - outer.clientHeight - outer.scrollTop)) + 'px');
                    }
                }
            };
            document.getElementById('outer')?.addEventListener('scroll', handleScroll);
            return () => {
                document.getElementById('outer')?.removeEventListener('scroll', handleScroll);
            };
        }, 1000);
    }, []);
    return (
        <Box
            sx={{
                width: '100vw',
                height: '60px',
                backgroundColor: '#ffffff',
                display: 'flex',
                gap: '64px',
                justifyContent: 'end',
                alignItems: 'center',
                padding: '0 100px 0 0',
                boxShadow: '0px -6px 12px 0px #262E351F',

                position: 'fixed',
                bottom: bottom,
                left: '0',
                zIndex: '1000',

            }}
            id="CheckListPanel"
        >
            <CancelButton setCheckCancel={props.setCheckCancel} disabled={needRefresh} />
            <SaveButton hasFormChange={props.hasFormChange} sipCode={props.sipCode} disabled={needRefresh}/>
            <ProceedButton disabled={needRefresh} />
        </Box>
    );
}

function CancelButton(props: {
    setCheckCancel?: (value: boolean) => void;
    disabled?: boolean;
}) {
    const {
        setCheckCancel = () => {
        },
    } = props;

    function handleCancel() {
        setCheckCancel(true);
    }

    const form = useFormContext();
    const checklist = form.root();

    const proceeding = checklist[CHECKLIST_PROCEEDING] ?? false;

    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() => {
        if (proceeding) {
            setIsDisabled(true);
            setTimeout(() => {
                setIsDisabled(false);
            }, 1000);
        }
    }, [proceeding]);
    return (
        <>
            <StateButton
                titleName="Cancel"
                isText={true}
                sx={{
                    height: '41px',
                    width: '68px',
                }}
                onClick={handleCancel}
                disabled={isDisabled || props.disabled}
            />
        </>
    );
}

function SaveButton(props: {
    hasFormChange?: boolean;
    sipCode?: string;
    disabled?: boolean;
}) {

    const form = useFormContext();
    const checklist = form.root();

    const proceeding = checklist[CHECKLIST_PROCEEDING] ?? false;

    const {
        hasFormChange,
        sipCode,
    } = props;

    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() => {
        if (proceeding) {
            setIsDisabled(true);
            setTimeout(() => {
                setIsDisabled(false);
            }, 1000);
        }
    }, [proceeding]);

    function handleSave() {
        setTimeout(() => {
            const checklistDetail = pureData(checklist);
            excuteSave(form.dispatch ,checklistDetail,sipCode);
        }, 100);
    }

    return (
        <StateButton
            titleName="Save for later"
            isPrimary={false}
            sx={{
                height: '41px',
                width: '140px',
                backgroundColor: !hasFormChange ? '#F1F3F5!important' : '',
                color: !hasFormChange ? '#98A7B6!important' : '',
                textTransform: 'initial!important',
                lineHeight: '17px!important',
            }}
            onClick={handleSave}
            disabled={
                !(hasFormChange
                )
                || isDisabled
                || props.disabled
            }
        />
    );
}

function ProceedButton(props: {
    disabled?: boolean;
}) {
    const [totalError, setTotalError] = React.useState(false);
    const [runtimeError, setRuntimeError] = React.useState(false);

    const form = useFormContext();
    const checklist = form.root();

    const proceeding = checklist[CHECKLIST_PROCEEDING] ?? false;

    const immediateDecision = form.get('immediateDecision');

    function handleProceed() {
        const geList = form.get('geList') ?? [];

        let firstErrorHtmlId = null;

        //1. check the immediateDecision part
        if ((immediateDecision?.length ?? 0) > 0) {
            const hasFlagError = geList.some((ge: { flags: any[]; detail: any  }) => {
                const validate = ge?.detail?.__flags__?._validate_;
                if (validate === 'error' || validate === 'default') {
                    firstErrorHtmlId = ge?.detail?.__flags__?.['html-id'];
                    return true;
                }
            });

            if (hasFlagError) {
                fireMessage(form.dispatch, WARNING_CHECKPOINTS);

                if(firstErrorHtmlId) {
                    let firstErrorItemBlock = document.getElementById(firstErrorHtmlId);
                    firstErrorItemBlock?.scrollIntoView({ behavior: 'smooth' });
                }

                setTotalError(true);
                console.log('FlaggedGE error');
                return;
            }
            excuteProceed(form.dispatch, checklist, form.get('sipCode'));
            return 0;
        }

        form.set(CHECKLIST_PROCEEDING, true);

        //检查CheckListForSIP, TODO 将来改为从组件获取
        const sipDetails = form.get('details');

        let result: ValidateState = "checked";
        for (const key in sipDetails) {
            if(key.startsWith('__') && sipDetails.hasOwnProperty(key)){
                const itemState = (sipDetails[key] ?? {})[VALIDATE_KEY] ?? 'default';
                const isRequired = (sipDetails[key] ?? {})['required'] ?? false;
                if(!isRequired){
                    continue;
                }
                if(itemState == 'error'){
                    result = 'error';
                    firstErrorHtmlId = (sipDetails[key] ?? {})['html-id'];
                    break;
                }

                if(result == 'checked'){
                    result = itemState;
                    firstErrorHtmlId = (sipDetails[key] ?? {})['html-id'];
                }
            }
        }

        const vaildCheckListForSIP = result === 'checked';

        if (!vaildCheckListForSIP) {
            fireMessage(form.dispatch, WARNING_CHECKPOINTS);

            if(firstErrorHtmlId) {
                let firstErrorItemBlock = document.getElementById(firstErrorHtmlId);
                firstErrorItemBlock?.scrollIntoView({ behavior: 'smooth' });
            }

            console.log('CheckListForSIP error');
            return;
        }

        //检查ChecklistForGE
        for (let i = 0; i < geList.length; i++) {
            let ge = geList[i];
            if (ge?.['_validate_'] != 'checked') {
                fireMessage(form.dispatch, WARNING_CHECKPOINTS);

                const geDetails = ge?.['details'];
                for (const key in geDetails) {
                    if(key.startsWith('__') && geDetails.hasOwnProperty(key)){
                        const itemState = (geDetails[key] ?? {})[VALIDATE_KEY] ?? 'default';
                        const isRequired = (geDetails[key] ?? {})['required'] ?? false;
                        if(!isRequired){
                            continue;
                        }
                        if(itemState == 'error'){
                            result = 'error';
                            firstErrorHtmlId = (geDetails[key] ?? {})['html-id'];
                            break;
                        }

                        if(result == 'checked'){
                            result = itemState;
                            firstErrorHtmlId = (geDetails[key] ?? {})['html-id'];
                        }
                    }
                }

                if(firstErrorHtmlId) {
                    let firstErrorItemBlock = document.getElementById(firstErrorHtmlId);
                    firstErrorItemBlock?.scrollIntoView({ behavior: 'smooth' });
                }

                console.log('CheckListForGE error');
                return false;
            }
        }

        // check the overall comments
        // TODO update the process logic, the SIP Section & GE Section and this part should follow the same logic
        const overallComments = form.get('overallComment');
        if (overallComments?.length > 100000) {
            let overallCommentBlock = document.getElementById('sip-checklist-overall-comments');
            overallCommentBlock?.scrollIntoView({ behavior: 'smooth' });
            return false;
        }

        setTimeout(() => {
            excuteProceed(form.dispatch, checklist, form.get('sipCode'));
        }, 500);
    }

    React.useEffect(() => {
        if (!totalError) {
            form.set(CHECKLIST_PROCEEDING, false);
        }
    }, [totalError]);


    return (
        <StateButton
            titleName='Complete Checklist'
            sx={{
                height: '41px',
                width: '235px',
                textTransform: 'initial!important',
                lineHeight: '17px!important',
                fontFamily: 'Inter!important',
            }}
            onClick={handleProceed}
            disabled={(proceeding && totalError) || runtimeError || props.disabled}
        />
    );
}

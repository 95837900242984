import React from 'react';
import { IDContext } from '../../../Context';
import { Box, Stack } from '@mui/material';
import { AllCentered, ColumnCentered } from '../../Css';
import { cssItemTitle } from '../../Css/Framework';
import { TinyFont } from '@/components/FontStyle';

export default function Item(props) {
  const {
    id,
    title,
    needResetBtn = false,
    handleReset = () => {},
    stackProps = {},
    titleProps = {},
    isCNmarketing = false,
    resetLabel = 'Clear',
  } = props;
  const prefix = React.useContext(IDContext);
  return (
    <Stack
      id={`${prefix}_framework_${id}`}
      data-selenium-id={`${prefix}_framework_${id}`}
      spacing={stackProps?.spacing ?? 0.5}
      direction={stackProps?.direction ?? 'column'}
      {...(isCNmarketing&&{sx:id==='Stage'?{marginBottom:'66px'}:id==='acquiredDate'?{transform:'translateY(-80px)'}:{}})}
    >
      <Stack
        id={`${prefix}_framework_${id}_title_box`}
        data-selenium-id={`${prefix}_framework_${id}_title_box`}
        spacing={1.25}
        direction={'row'}
        sx={ColumnCentered}
      >
        <Box
          id={`${prefix}_framework_${id}_title`}
          data-selenium-id={`${prefix}_framework_${id}_title`}
          sx={{...cssItemTitle,...titleProps}}
        >
          {title}
        </Box>
        {needResetBtn ? (
          <Box
            id={`siPageFilterDrawerReset-${id}`}
            data-selenium-id={`siPageFilterDrawerReset-${id}`}
            sx={{
              ...TinyFont,
              color: '#154AB6',
              cursor: 'pointer',
              ':hover':{
                fontWeight: '600',
                color: '#113D95',
              },
            }}
            onClick={handleReset}
          >
            {resetLabel}
          </Box>
        ) : null}
      </Stack>
      {props.children}
    </Stack>
  );
}

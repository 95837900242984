import styles from "../../index.module.scss";
import {SIPFormState} from "@/reducers/SIPFormReducer";
import {useDispatch, useSelector} from "react-redux";
import {setSIPFormSectionState, setSIPFormValue} from "@/actions/SIMT-SIP/SIPForm";
import {useEffect} from "react";
import {Box, Checkbox, Link, Typography} from "@mui/material";
import {SectionState} from "@/pages/SIPForm/types.d";
import Card from "@/pages/SIPForm/components/Card";

export interface StatementsSectionProps {

}

export function StatementsSection(props: StatementsSectionProps) {
    const selector = (state: {
        SIPForm: SIPFormState;
        [key: string]: any;
    }) => state.SIPForm;

    const {
        formData,
        state: formStatus,
    } = useSelector(selector);

    const { statements } = formData;
    const dispatch = useDispatch();

    const proceeding = formStatus === 'proceeding';

    const sectionData = statements.data;
    const section = 'statements';
    const setValue = (key: string, value: any) => dispatch(setSIPFormValue(
        section,
        key,
        value
    ));

    const valueSetter = (key: string) => (value: any) => setValue(key, value);
    const setConsent1 = valueSetter('consent1');

    const hasValue = sectionData.consent1;

    useEffect(() => {
        dispatch(setSIPFormSectionState(section,
            hasValue ? SectionState.completed : (proceeding ? SectionState.error : SectionState.default)));
    }, [hasValue, proceeding]);

    return (<Card section={statements} withStar={true}>
        <Box className={styles['statements-options-box']}>
            <Checkbox
                checked={sectionData.consent1}
                onChange={(e) => setConsent1(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            <Box className={styles['statements-options-box-text']}>
                <Typography>I understand the responsibilities of a Guest Editor,
                    as well as the Special Issue process and have read</Typography>
                <Link target="_blank" href={"https://www.wiley.com/en-us/publish/special-issues/guidelines"}>
                    Wiley's Guest Editor Guidelines.
                </Link>
            </Box>
        </Box>
    </Card>);
}
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useIntl } from 'react-intl';
import GEDetailCloseIcon from '@/assets/GEDetailCloseIcon.svg';
import { H4Font } from '@/components/FontStyle';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { style, styled, width } from '@mui/system';
import { StateButton } from '@/componentsphase2/StateButton';
import {approveOptOut, withdrawJournalOptOut} from "@/actions/SIMT-SI/CatJournal/OptOut";

export default function WithdrawPopUp(props) {
  const {
    open = true, //控制popup的打开和关闭
    setOpen = () => {},
    journalCode,
    page,
    pageSize,
  } = props;

  const intl = useIntl();

  const dispatch = useDispatch();
  const savetBtnCss = {
    width: '69px',
    height: '32px',
    backgroundColor: '#154AB6',
    borderRadius: '3px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    letterSpacing: '1px',
    textTransform: 'capitalize',
  };

  const CancelBtnCss = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#154AB6',
    textTransform: 'capitalize',
    marginRight: '34px',
  };
  const clickCancel = e => {
    setOpen(false);
  };

  const clickSave = e => {
    e.preventDefault();
    dispatch(withdrawJournalOptOut(journalCode, page, pageSize));
    setOpen(false);
  };

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            boxShadow: 'none',
          },
        }}
        open={open}
        onClose={clickCancel}
        sx={{
          zIndex: 1401,
          // background: ' rgba(9, 30, 66, 0.54)',
          '& .MuiDialog-paper': {
            width: '600px',
            minWidth: '600px',
            margin: 0,
            paddingTop: '24px',
            paddingBottom:'0px',
            paddingLeft: '32px',
            paddingRight: '32px',
            gap: '16px',
            boxSizing: 'border-box',
          },
          '.css-hz1bth-MuiDialog-container': {
            // background: 'rgba(9, 30, 66, 0.54)',
          },
          '& .MuiBackdrop-root': {
            background: 'rgba(17, 61,149, 0.5)',
            backdropFilter: 'blur(4px)',
  
          },
        }}
        data-selenium-id='CATJournal_Overview-OptOutTable-WithdrawPopUp-Dialog'
      >
        <Box
          
          data-selenium-id='CATJournal_Overview-OptOutTable-WithdrawPopUp-Box'
        >
          <Box
            sx={{
              ...H4Font,
              height: '23px',
              // float: 'left',
              textAlign: 'center',
              color: '#262E35',
            }}
            data-selenium-id='CATJournal_Overview-OptOutTable-WithdrawPopUp-Box-Box'
          >
            {intl.messages['optOut.withdrawPopUpTitle']}
          </Box>
          {/* <IconButton
            sx={{ float: 'right', cursor: 'pointer', padding: '0px' }}
            onClick={clickCancel}
            data-selenium-id='CATJournal_Overview-OptOutTable-WithdrawPopUp-Box-IconButton'
          >
            <GEDetailCloseIcon />
          </IconButton> */}
        </Box>

        <Stack
          direction='row' spacing={1.125} justifyContent="center" sx={{paddingTop: '12px', paddingBottom:'20px'}}
          data-selenium-id='CATJournal_Overview-OptOutTable-WithdrawPopUp-Stack'
        >
          <StateButton
            isPrimary = {false}
            titleName = 'Cancel'
            onClick={clickCancel}
            data-selenium-id='CATJournal_Overview-OptOutOperation-Withdraw-Box1'
            sx ={{
              fontSize : '14px',
              lineHeight: '18px',
              width: '70px'
            }}
          >
          </StateButton>

          <StateButton
            isPrimary = {true}
            titleName = 'Ok'
            onClick={clickSave}
            data-selenium-id='CATJournal_Overview-OptOutOperation-Withdraw-Box1'
            sx ={{
              fontSize : '14px',
              width: '70px'
            }}
            data-selenium-id='CATJournal_Overview-OptOutOperation-Withdraw-Box2'
          >
          </StateButton>
          
          {/* <Button
            id='ApprovePopUpSaveButton'
            data-selenium-id='CATJournal_Overview-OptOutTable-WithdrawPopUp-ApproveSaveButton'
            sx={savetBtnCss}
            variant='contained'
            onClick={clickSave}
          >
            OK
          </Button>
          <Button
            id='ApprovePopUpCancelButton'
            data-selenium-id='CATJournal_Overview-OptOutTable-WithdrawPopUp-ApproveCancelButton'
            onClick={clickCancel}
            sx={CancelBtnCss}
          >
            Cancel
          </Button> */}
        </Stack>
      </Dialog>
    </>
  );
}

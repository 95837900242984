import {axios_instance} from "@/utils/axios_instance";
import {GET_CUT_OFF_DATE} from "@/actions/SIMT-Report/Report/ReportAction";
import getAuth from "@/utils/getAuth";
import { getMoment } from "../getMoment";

export function getCutOffDate() {
    const defaultCutOffDate = '2023-11';
    const url = `/api/v1/user-service/configs`;
    //get cut off date from BE
    return async dispatch => {
        try {
            const res = await axios_instance.get(url, {
                headers: {
                    authorization: getAuth(),
                },
                params: {
                    type: 'CUT_OFF_DATE',
                    value:'',
                },
            });
            if (res.data.code === 200) {
                const date = res.data.data[0].value;
                const formattedDate = date.substring(0, 4) + '-' + date.substring(4);
                dispatch({type: GET_CUT_OFF_DATE, data: formattedDate});
            } else {
                dispatch({type: GET_CUT_OFF_DATE, data: defaultCutOffDate});
            }
        } catch (err) {
            dispatch({type: GET_CUT_OFF_DATE, data: defaultCutOffDate});
        }
    };
}
import * as actions from '../actions/SIMT-Report/ReportJobsSubmission/ReportJobsAction';

const initState = {
  currentReportJobsTab: 0,
  historyRequestPage: 0,
  historyRequestPageSize: 20,
  newSubmissionList: [],
  historyRequestData: {
    result: [],
    count: 0,
  },
  submitResult: {
    flag: 0,
    result: '',
  },
  ApprovalOrRejectResult: '',
  RejectResult: '',
  EveryClick: '',
  waitingForApprovalList: [],

  waitingForApprovalOpen: false,
  waitingForRejectOpen: false,
};

export default function ReportJobsReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case 'TAB_CHANGE':
      return {
        ...state,
        currentReportJobsTab: action.data,
      };
    case actions.CHANGE_HR_PAGE:
      return {
        ...state,
        historyRequestPage: data,
      };
    case actions.CHANGE_HR_PAGE_SIZE:
      return {
        ...state,
        historyRequestPageSize: data,
        historyRequestPage: 1,
      };
    case 'GET_NEWSUBMISSION_OVERVIEW':
      return {
        ...state,
        newSubmissionList: action.data,
      };
    case actions.SUBMITNEWSUBMISSION:
      return {
        ...state,
        submitResult: action.data,
      };
    case actions.LOAD_HISTORY_REQUEST_DATA:
      return {
        ...state,
        historyRequestData: {
          result: data.result,
          count: data.count,
        },
      };

    case 'GET_WAITINGFORAPPROVAL_DATA':
      return {
        ...state,
        waitingForApprovalList: data,
      };
    case actions.APPROVAL_WAITINGFORAPPROVAL:
      return {
        ...state,
        ApprovalOrRejectResult: data.Approve,
        RejectResult: data.Reject,
        EveryClick: data.Every,
      };
    case actions.REJECT_WAITINGFORAPPROVAL:
      return {
        ...state,
        ApprovalOrRejectResult: data.Approve,
        RejectResult: data.Reject,
        EveryClick: data.Every,
      };
    case actions.CHANGE_WAITING_APPROVAL_MESSAGE:
      return {
        ...state,
        waitingForApprovalOpen: data,
      };
    case actions.CHANGE_WAITING_REJECT_MESSAGE:
      return {
        ...state,
        waitingForRejectOpen: data,
      };
    default:
      return state;
  }
}

import { handleFileterJournalName } from "@/utils/commonUtils";

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

//比较两个字符串数组是否相同
export function compareStrArray(arrayA,arrayB){
  if(!arrayA||!arrayB){
    return false;
  }
  if(arrayA.length !== arrayB.length){
    return false;
  }
  if(arrayA.length>0 && typeof(arrayA[0])!=='string' ){
    for(let i=0;i<arrayA.length;i++){
      arrayA[i] = arrayA[i].toISOString();
    }
  }
  if(arrayB.length>0 && typeof(arrayB[0])!=='string'){
    for(let i=0;i<arrayB.length;i++){
      arrayB[i] = arrayB[i].toISOString();
    }
  }
  for(let i=0;i<arrayA.length;i++){
    if(!arrayB.includes(arrayA[i])){
      return false;
    }
  }
  return true;
}

//比较A,B两个filter是否相同，它们都有相同的key:value形式，即value：array
export function compareFilter(FilterA,FilterB){
  if(!FilterA || !FilterB){
    return false;
  } 
  for(let key in FilterA){
    if(key==='handlingCe'){//这里由于handlingCeName已经可以比较出handlingCE了，就不需要用handlingCE在进行比较了
      continue;
    }
    let valueA = FilterA[key];
    let valueB = FilterB[key];
    if(!valueA || !valueB){
      continue;
    }
    let areEqual = compareStrArray(valueA.concat(),valueB.concat());
    if(!areEqual){

      return false;
    }
  }
  return true;
}

const ALL_ACTIVE_STAGES_STRING = 'Active Stages';
export const prepareFilters = (siFilterSelected, keepCEName,keepJournalCode) => {
  const journalName = [];
  for (let journalSelectedElement of siFilterSelected.journalSelected) {
    journalName.push(
      handleFileterJournalName(journalSelectedElement)['journalName']
    );
  }

  // 处理handling ce
  const handlingCeIds = [];
  for (const element of siFilterSelected.handlingCeSelected) {
    handlingCeIds.push(element.userId);
  }

  // 处理group id
  // const groupIds = [];
  // const groupNames = [];
  // for (const element of siFilterSelected.catGroupSelected) {
  //   groupIds.push(element.id);
  //   groupNames.push(element.value);
  // }

  // stage列表无论如何都要把All本身移除
  const stages = siFilterSelected.stageSelected.filter(item => {
    return item !== ALL_ACTIVE_STAGES_STRING;
  });

  const tempReturn = {
    siCode: siFilterSelected.siCodeSelected,
    leadGeFullName: siFilterSelected.leadGeFullNameSelected,
    coGeFullName: siFilterSelected.coGeFullNameSelected,
    handlingCe: handlingCeIds,
    group: [],
    groupNames: siFilterSelected.catGroupSelected,
    journalName: keepJournalCode?siFilterSelected.journalSelected:journalName,
    journalRevenueModel: siFilterSelected.journalrevenueModelSelected,
    pdPublisher: siFilterSelected.pdPublisherSelected,
    SubjectGroup: siFilterSelected.subjectGroupSelected,
    stage: stages,
    acquiredDateFrom:
      siFilterSelected.acquiredFromDate === null  || siFilterSelected.acquiredFromDate === undefined || siFilterSelected.acquiredFromDate?.length===0
        ? []
        : [siFilterSelected.acquiredFromDate],
    acquiredDateTo:
      siFilterSelected.acquiredToDate === null || siFilterSelected.acquiredToDate === undefined || siFilterSelected.acquiredToDate?.length===0
        ? []
        : [siFilterSelected.acquiredToDate],
    submissionDeadlineFrom:
      siFilterSelected.deadlineFromDate === null
        ? []
        : [siFilterSelected.deadlineFromDate],
    submissionDeadlineTo:
      siFilterSelected.deadlineToDate === null
        ? []
        : [siFilterSelected.deadlineToDate],
    state: siFilterSelected.stateSelected,
    closedforSubmission: siFilterSelected.closedforSubmissionSelected,
    createFromDate:
      siFilterSelected.createFromDate === null ||   siFilterSelected.createFromDate === undefined || siFilterSelected.createFromDate?.length===0
        ? []
        : [siFilterSelected.createFromDate],
    createToDate:
      siFilterSelected.createToDate === null ||  siFilterSelected.createToDate === undefined ||  siFilterSelected.createToDate?.length===0
        ? []
        : [siFilterSelected.createToDate],
    apFromDate:
      siFilterSelected.apFromDate === null
        ? []
        : [siFilterSelected.apFromDate],
    apToDate:
      siFilterSelected.apToDate === null ? [] : [siFilterSelected.apToDate],
    siTitle: siFilterSelected.siTitleSelected,
    sisWithAlerts: siFilterSelected.sisWithAlertsSelected,
    siWithChinaGE: siFilterSelected.siWithChinaGESelected?siFilterSelected.siWithChinaGESelected:[],
  };
  if (keepCEName) {
    // 存在特殊情况需要保留CE的name，
    const handlingCeNames = [];
    for (const element of siFilterSelected.handlingCeSelected) {
      handlingCeNames.push(`${element.username}(${element.email})`);
    }
    tempReturn['handlingCeName'] = handlingCeNames;
  }

  return tempReturn;
};

export function maxOne(array) {
  return array && array.length > 0 ? 1 : 0;
}

export function getSum(FilterSelected) {
  if (FilterSelected === null) {
    return 0;
  }
  let sum = 0;
  for (let key in FilterSelected) {
    if(key.includes('From') || key ==='handlingCe'){
      continue;
    }
    sum += maxOne(FilterSelected[key]);
  }
  return sum;
}

export const useSingleSIDateRangePicker = (siFilterSelected) => {
  if(!siFilterSelected){
    // invalid Filter
    return true;
  } 
  return useSingleSelectDatePicker(siFilterSelected?.acquiredFromDate, siFilterSelected?.acquiredToDate)|| 
          useSingleSelectDatePicker(siFilterSelected?.apFromDate, siFilterSelected?.apToDate)|| 
          useSingleSelectDatePicker(siFilterSelected?.createFromDate, siFilterSelected?.createToDate)|| 
          useSingleSelectDatePicker(siFilterSelected?.deadlineFromDate, siFilterSelected?.deadlineToDate);
};

export const useSingleSelectDatePicker = (fromDate, toDate) => {
  return (fromDate == null && toDate != null) || (fromDate != null && toDate == null);
};

export const initSIFilterSelected={
  siTitleSelected: [],
  leadGeFullNameSelected: [],
  coGeFullNameSelected: [],
  handlingCeSelected: [],
  catGroupSelected: [],
  journalSelected: [],
  pdPublisherSelected: [],
  subjectGroupSelected: [],
  siCodeSelected: [],
  journalrevenueModelSelected: [],
  siWithChinaGE:[],
  siWithChinaGESelected:[],
  stageSelected: [],
  sisWithAlertsSelected:[],
  stateSelected:[],
  closedforSubmissionSelected:[],
  acquiredFromDate: [],
  acquiredToDate: [],
  deadlineFromDate: null,
  deadlineToDate: null,
  apToDate:null,
  apFromDate:null,
  createToDate:[],
  createFromDate:[]
};
import {
  Box,
  IconButton,
  Stack,
  Backdrop,
  Typography,
  Drawer,
  Divider,
  Checkbox,
} from '@mui/material';
import {
  SmallFont,
} from '@/components/FontStyle';
import { StateButton } from '@/componentsphase2/StateButton';
import { loadData } from '@/actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import {
  clearArticleFilter,
  setArticleFilterSelect,
  setArticleOverviewFilters,
} from '@/actions/SIMT-Report/ReportFilter/ReportFilterAction';

import React, { useRef } from "react";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectBox from '../../Filter/Phase2Component';
import SIDrawerAutocompletePupIconTypeSecond from '@/assets/SIDrawerAutocompletePupIconTypeSecond.svg';
import { handleFileterJournalName } from '@/utils/commonUtils';
import ReportFilterSwitchIconOn from '@/assets/ReportFilterSwitchIconOn.svg';
import ReportFilterSwitchIconOff from '@/assets/ReportFilterSwitchIconOff.svg';
import CloseIconButton from '@/componentsphase2/Button/CloseIconButton';
import { MulSelectInput } from '@/componentsphase2/SelectBox/SelectInput';
import { setArticleOAPFilterSelect } from "@/actions/SIMT-Report/ReportFilter/ReportFilterAction";
import { useShowReportRolesByFyHook } from '@/pages/Report/utils/useShowReportRolesByFyHook';
import {PERMISSIONS} from "@/constant/permission";

export default function ArticlesOverviewFilter(props) {

  const { filterOpen, drawerWidth, handleClose } = props;
  const dispatch = useDispatch();
  const [showJPM, showNewJournalRoles] = useShowReportRolesByFyHook();
  const {
    selectDate,
    cutOffDate,
    handlingCeData,
    catGroupData,
    journalData,
    jpmData,
    pdPublisherData,
    ppPublisherData,
    subjectGroupData,
    businessDivisionData,
    articleOverviewJournal,
    revenueModel,
    articleOverviewJPM,
    articleOverviewPDPublisher,
    articleOverviewPPPublisher,// add new journal role
    articleOverviewSubjectGroup,
    articleOverviewBusinessDivision,
    articleOverviewCATGroup,
    articleOverviewHandlingCE,
    articleOverviewJournalStatus,
    filterData = {},
  } = useSelector(state => {
    return {
      // filter display
      selectDate: state.Report.selectDate,
      cutOffDate: state.Report.cutOffDate,
      handlingCeData: state.Filter.handlingCe,
      catGroupData: state.Filter.catGroup,
      journalData: state.Filter.journal,
      jpmData: state.Filter.jpm,
      pdPublisherData: state.Filter.pdPublisher,
      ppPublisherData: state.Filter.ppPublisher,// add new journal role
      subjectGroupData: state.Filter.subjectGroup,
      businessDivisionData: state.Filter.businessDivision,
      articleOverviewJournal: state.ReportFilter.articleOverviewJournal,
      revenueModel: state.ReportFilter.articleOverviewrevenueModel,
      articleOverviewJPM: state.ReportFilter.articleOverviewJPM,
      articleOverviewPDPublisher: state.ReportFilter.articleOverviewPDPublisher,// add new journal role
      articleOverviewPPPublisher: state.ReportFilter.articleOverviewPPPublisher,// add new journal role
      articleOverviewSubjectGroup:
        state.ReportFilter.articleOverviewSubjectGroup,
      articleOverviewBusinessDivision:
        state.ReportFilter.articleOverviewBusinessDivision,
      articleOverviewCATGroup: state.ReportFilter.articleOverviewCATGroup,
      articleOverviewHandlingCE: state.ReportFilter.articleOverviewHandlingCE,
      articleOverviewJournalStatus:
        state.ReportFilter.articleOverviewJournalStatus,
      filterData: state.ReportFilter.articleOverviewFilters,
    };
  });

  const [Apply, setApply] = useState(false);

  useEffect(() => {
    if (filterOpen) {
      document.body.classList.add('hide-scroll');

      const {
        journalCode = [],
        revenueModel = [],
        jpm = [],
        pdPublisher = [],
        ppPublisher = [],// add new journal role
        subjectGroup = [],
        businessDivision = [],
        catGroup = [],
        handlingCe = [],
        journalStatus = [],
      } = filterData;

      const journalList = [];
      for (let code of journalCode){
        for (let journal of journalData){
          if (journal.includes(`(${code})`)){
            journalList.push(journal);
          }
        }
      }

      const handlingCes = [];
      for (let ceId of handlingCe){
        for (let ce of handlingCeData) {
          if (ce.userId === ceId) {
            handlingCes.push(ce);
          }
        }
      }

      const groups = [];
      for (let group of catGroup){
        for (let cat of catGroupData){
          if (cat.value === group){
            groups.push(cat);
          }
        }
      }

      const setSelect = setArticleFilterSelect;
      dispatch(setSelect('reportJournal', journalList));
      dispatch(setSelect('reportJPM', jpm));
      dispatch(setSelect('reportPDPublisher', pdPublisher));
      dispatch(setSelect('reportPPPublisher', ppPublisher));// add new journal role
      dispatch(setSelect('reportSubjectGroup', subjectGroup));
      dispatch(setSelect('reportCATGroup', groups));
      dispatch(setSelect('reportBusinessDivision', businessDivision));
      dispatch(setSelect('reportHandlingCE', handlingCes));
      dispatch(setSelect('revenueModel', revenueModel.length !== 0));
      dispatch(setSelect('reportJournalStatus', journalStatus));
    } else {
      document.body.classList.remove('hide-scroll');
    }
  },[filterOpen]);

  const permssion = localStorage
    .getItem('userPermissions')
    .includes(PERMISSIONS.SI_READ_LIMITED); //权限判断 修复403错误

  const boxStyle = {
    bgcolor: '#F3F4F8',
    mt: 1,
    p: 0,
  };

  const selectFilter = (which, data) => {
    dispatch(setArticleFilterSelect(which, data));
  };

  const onLoad = async (which, searchContent, page = 1, pageSize = 5000) => {
    if (!permssion) {
      await dispatch(loadData(which, searchContent, page, pageSize));
    }
  };

  const handleSelectedChange = (which, data) => {
    selectFilter(which, data);
  };

  const handleReset = () => {
    dispatch(clearArticleFilter());
  };

  const handlerevenueModelChange = e => {
    dispatch(setArticleFilterSelect('revenueModel', !revenueModel));
  };

  const prepareFilters = () => {
    // 处理journal code----感觉不规范，以后要跟后端协商
    var journalCode = [];
    for (const journalSelectedElement of articleOverviewJournal) {
      var code = handleFileterJournalName(journalSelectedElement)[
        'journalCode'
      ];
      journalCode.push(code);
    }

    // 处理handling ce
    var handlingCeIds = [];
    for (const element of articleOverviewHandlingCE) {
      handlingCeIds.push(element.userId);
    }

    // 处理group
    const groups = [];
    for (const element of articleOverviewCATGroup) {
      groups.push(element.value);
    }

    return {
      handlingCe: handlingCeIds,
      catGroup: groups,
      journalCode: journalCode,
      jpm: articleOverviewJPM,
      pdPublisher: articleOverviewPDPublisher,
      ppPublisher: articleOverviewPPPublisher,// add new journal role
      subjectGroup: articleOverviewSubjectGroup,
      businessDivision: articleOverviewBusinessDivision,
      revenueModel: revenueModel ? ['OA'] : [],
      journalStatus: articleOverviewJournalStatus,
    };
  };

  const handleApply = async () => {
    const filters = prepareFilters();
    await dispatch(setArticleOverviewFilters(filters));
    setApply(true);
    handleCloseFilter();
  };

  const handleCloseFilter = () => {
    // document.body.classList.remove('hide-scroll');
    handleClose();
  };
  const [open1, setOpen] = useState(false);

  const handleOpenChange = newOpen => {
    setOpen(newOpen);
    dispatch(setArticleOAPFilterSelect('revenueModel', !newOpen));
    // console.log(revenueModel);
  };
  // console.log('#@$@$@4');
  const filterBody = useRef(null);
  function goBottom() {
    setTimeout(()=>{
      const box = filterBody.current;
      if (box){
        box.scrollTop = box.scrollHeight - box.offsetHeight;
      }
    },301);
  }

  return (
    <Box
      sx={{
        width: '100%',
      }}
      data-selenium-id='Report_Overview-anotherNewFilterBox'
    >
      <Backdrop
        id='reportOverviewAnotherNewFilterDrawerBackdrop'
        data-selenium-id={'Report_Overview-anotherNewFilterDrawerBackdrop'}
        sx={{
          zIndex: 1400,
          backdropFilter: 'blur(4px)',
          background: '#113D9580',
        }}
        open={filterOpen}
      ></Backdrop>
      <Drawer
        id='ReporteOverviewAnotherNewFilterDrawer'
        variant='persistent'
        anchor='right'
        open={filterOpen}
        sx={{
          flexShrink: 0,
          ['& .MuiDrawer-paper']: {
            width: '472px',
            zIndex: 1400,
            backgroundColor: '#FFFFFF',
            paddingBottom: '20px',
          },
        }}
        data-selenium-id={'Report_Overview-anotherNewFilterDrawer'}
      >
        <Box
          // direction= 'row'
          sx={{
            m: '20px 36px',
            height: '34px',
          }}
        >
          <CloseIconButton
            sx={{
              float: 'left',
              padding: '0px',
              marginRight: '12px',
              pt: '5px',
            }}
            onClick={() => {
              handleCloseFilter();
            }}
            data-selenium-id='Report-Overview-ArticlesOverviewFilter-CloseButton'
          />
          <Box
            // display="flex" justifyContent="center"
            sx={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22.5px',
              height: '13px',
              float: 'left',
              color: '#262E35',
              mt: '5px',
              // mb: '10px',
            }}
            data-selenium-id='Report_Overview-OptInOperation-Edit-Box-Box'
          >
            Filters
          </Box>
          <StateButton
            onClick={handleApply}
            data-selenium-id='Report_Overview-OptInOperation-Edit-Box2'
            titleName='Filter'
            isPrimary={true}
            sx={{
              lineHeight: '18px',
              marginLeft: '10px',
              float: 'right',
              // '&:disabled': {
              //   backgroundColor: '#BCC5CF',
              //   boxShadow: 'none',
              //   color: '#FFFFFF',
              // },
            }}
            // disabled={isError()}
          ></StateButton>
          <StateButton
            onClick={handleReset}
            data-selenium-id='Report_Overview-OptInOperation-Edit-Box1'
            titleName='Clear'
            isPrimary={false}
            sx={{ lineHeight: '18px', float: 'right' }}
          ></StateButton>
        </Box>
        <Divider></Divider>
        <Stack
          ref={filterBody}
          direction='column'
          spacing={3}
          sx={{
            p: '20px 30px 0px 36px',
            marginRight: '6px',
            overflowY: 'auto',
            scrollbarWidth: 'thin', // 设置滚动条宽度
            // scrollbarColor: 'transparent',  // 设置滚动条颜色为透明
            // '&::-webkit-scrollbar': {
            //   width: '0px',  // 设置滚动条宽度
            // },
            // '&::-webkit-scrollbar-thumb': {
            //   background: 'transparent',  // 设置滚动条背景为透明
            // },
          }}
        >
          {/* Journal */}
          <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_Overview-Filter-Block-Title'
            >
              Journal
            </Box>
            <MulSelectInput
              id='reportArticlesFilterjournalSelect'
              data-selenium-id='Report_Overview_Filter-journalSelect'
              value={articleOverviewJournal}
              placeholder='Search Journal Name or Code'
              defaultOption={key => key}
              getOptionLabel={option => option}
              options={journalData}
              loadOptionAction={(...props) =>
                onLoad.bind(this, 'journal')(...props)
              }
              onValueChange={value => {
                handleSelectedChange.bind(this, 'reportJournal')(value);
              }}
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
            />
          </Box>
          {/* JPM */}
          {showJPM && <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_Overview-Filter-Block-Title'
            >
              JPM
            </Box>
            <SelectBox
              disablePortal
              id='reporOverviewFilterJpmSelect'
              disablePortal
              data-selenium-id='Report_Overview_Filter-JpmSelect'
              // sx={{ mt: 1 }}
              type={1}
              data={jpmData}
              labelName={'JPM'}
              popupIcon={
                <IconButton
                  sx={{ width: '16px', height: '16px', padding: '0px' }}
                >
                  <SIDrawerAutocompletePupIconTypeSecond></SIDrawerAutocompletePupIconTypeSecond>
                </IconButton>
              }
              placeholder='Search JPM'
              value={articleOverviewJPM}
              setSelected={handleSelectedChange.bind(this, 'reportJPM')}
              loadMore={onLoad.bind(this, 'jpm')}
              limitTags={-1}
              multiple
            />
          </Box>}

          {/* PD Publisher */}
          { showNewJournalRoles && <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_Overview-Filter-Block-Title'
            >
              PD Publisher
            </Box>
            <SelectBox
              disablePortal
              id='reporOverviewFilterPDPublisherSelect'
              disablePortal
              data-selenium-id='Report_Overview_Filter-PDPublisherSelect'
              type={1}
              data={pdPublisherData}
              labelName={'PD Publisher'}
              popupIcon={
                <IconButton
                  sx={{ width: '16px', height: '16px', padding: '0px' }}
                >
                  <SIDrawerAutocompletePupIconTypeSecond></SIDrawerAutocompletePupIconTypeSecond>
                </IconButton>
              }
              placeholder='Search PD Publisher'
              value={articleOverviewPDPublisher}
              setSelected={handleSelectedChange.bind(this, 'reportPDPublisher')}
              loadMore={onLoad.bind(this, 'pdPublisher')}
              limitTags={-1}
              multiple
            />
          </Box>}

          {/* PP Publisher */}
          { showNewJournalRoles && <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_Overview-Filter-Block-Title'
            >
              PP Publisher
            </Box>
            <SelectBox
              disablePortal
              id='reporOverviewFilterPPPublisherSelect'
              disablePortal
              data-selenium-id='Report_Overview_Filter-PPPublisherSelect'
              type={1}
              data={ppPublisherData}
              labelName={'PP Publisher'}
              popupIcon={
                <IconButton
                  sx={{ width: '16px', height: '16px', padding: '0px' }}
                >
                  <SIDrawerAutocompletePupIconTypeSecond></SIDrawerAutocompletePupIconTypeSecond>
                </IconButton>
              }
              placeholder='Search PP Publisher'
              value={articleOverviewPPPublisher}
              setSelected={handleSelectedChange.bind(this, 'reportPPPublisher')}
              loadMore={onLoad.bind(this, 'ppPublisher')}
              limitTags={-1}
              multiple
            />
          </Box>}
          {/* Subject Group */}
          <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_Overview-Filter-Block-Title'
            >
              Subject Group
            </Box>
            <SelectBox
              disablePortal
              id='ReportOverviewFilterSubjectgroupSelect'
              data-selenium-id='Report_Overview_Filter-SubjectgroupSelect'
              // sx={{ mt: 1 }}
              type={1}
              data={subjectGroupData}
              labelName={'Subject Group'}
              popupIcon={
                <IconButton
                  sx={{ width: '16px', height: '16px', padding: '0px' }}
                >
                  <SIDrawerAutocompletePupIconTypeSecond></SIDrawerAutocompletePupIconTypeSecond>
                </IconButton>
              }
              multiple
              placeholder='Search Subject Group'
              value={articleOverviewSubjectGroup}
              setSelected={handleSelectedChange.bind(
                this,
                'reportSubjectGroup'
              )}
              loadMore={onLoad.bind(this, 'subjectGroup')}
              limitTags={-1}
            />
          </Box>
          {/* CAT Group */}
          <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_Overview-Filter-Block-Title'
            >
              CAT Group
            </Box>
            <MulSelectInput
              id='Report_OverviewFilterCatgroupSelect'
              data-selenium-id='Report_Overview_Filter-CatgroupSelect'
              value={articleOverviewCATGroup}
              placeholder='Choose Group Title'
              defaultOption={key => key}
              getOptionLabel={option => option.value}
              options={catGroupData}
              loadOptionAction={(...props) =>
                onLoad.bind(this, 'catGroup')(...props)
              }
              onValueChange={value => {
                handleSelectedChange.bind(this, 'reportCATGroup')(value);
              }}
              disableNewItem={true}
              showPopupIcon={true}
              showStartIcon={false}
            />
          </Box>
          {/* Business Division */}
          <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_Overview-Filter-Block-Title'
            >
              Business Division
            </Box>
            <MulSelectInput
              id='reportOverviewFilterBusinessdivisionSelect'
              data-selenium-id='Report_Overview_Filter-BusinessdivisionSelect'
              value={articleOverviewBusinessDivision}
              placeholder='Choose Business Division'
              defaultOption={key => key}
              getOptionLabel={option => option}
              options={businessDivisionData}
              loadOptionAction={(...props) =>
                onLoad.bind(this, 'businessDivision')(...props)
              }
              onValueChange={value => {
                handleSelectedChange.bind(
                  this,
                  'reportBusinessDivision'
                )(value);
              }}
              disableNewItem={true}
              showPopupIcon={true}
              showStartIcon={false}
            />
          </Box>
          {/* Handling CE */}
          <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_OverviewReport_Articles-Filter-Block-Title'
            >
              Handling CE
            </Box>
            <MulSelectInput
              id='reportOverviewFilterHeSelect'
              data-selenium-id='Report_Overview_Filter-HeSelect'
              value={articleOverviewHandlingCE}
              placeholder='Search Handling CE'
              defaultOption={key => key}
              getOptionLabel={item => {
                return `${item.username} (${item.email})`;
              }}
              options={handlingCeData}
              loadOptionAction={(...props) =>
                onLoad.bind(this, 'handlingCe')(...props)
              }
              onValueChange={value => {
                handleSelectedChange.bind(this, 'reportHandlingCE')(value);
              }}
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
              onOpen={goBottom}
            />
          </Box>
          {/* Journal Status */}
          <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_Overview-Filter-Block-Title'
            >
              Journal Status
            </Box>
            <MulSelectInput
              id='journalStatus'
              data-selenium-id='Report_Ovewview_Filter-journalStatus'
              value={articleOverviewJournalStatus}
              placeholder='Choose Journal Status'
              defaultOption={key => key}
              getOptionLabel={option => option}
              options={['opt-in', 'opt-out']}
              onValueChange={value => {
                handleSelectedChange.bind(this, 'reportJournalStatus')(value);
              }}
              disableNewItem={true}
              showPopupIcon={true}
              showStartIcon={false}
              dropDownListNumber={2}
              onOpen={goBottom}
            />
          </Box>
          <Stack direction='row' alignItems='center' spacing='50px'>
            <Stack direction='row' alignItems='center' spacing='8px'>
              <Checkbox
                disableRipple
                disabled={!revenueModel}
                icon={<ReportFilterSwitchIconOff />}
                checkedIcon={<ReportFilterSwitchIconOn />}
                checked={!revenueModel}
                onChange={handlerevenueModelChange}
                sx={{
                  padding: '0px',
                }}
              />
              <Box
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '18px',
                  letterSpacing: '0em',
                }}
              >
                All
              </Box>
            </Stack>
            <Stack direction='row' alignItems='center' spacing='8px'>
              <Checkbox
                disableRipple
                disabled={revenueModel}
                icon={<ReportFilterSwitchIconOff />}
                checkedIcon={<ReportFilterSwitchIconOn />}
                checked={revenueModel}
                onChange={handlerevenueModelChange}
                sx={{
                  padding: '0px',
                }}
              />
              <Box
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '18px',
                  letterSpacing: '0em',
                }}
              >
                OA
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Drawer>
    </Box>
  );
}

export const SET_SIP_CHECKLIST_DETAIL = 'SET_SIP_CHECKLIST_DETAIL';
export const SET_SIP_CHECKLIST_EDITING_INFO = 'SET_SIP_CHECKLIST_EDITING_INFO';
export const SET_SNACKBAR_SHOW = 'SET_SNACKBAR_SHOW';
export const SET_ITEMVALUE = 'SET_ITEMVALUE';
export const SET_GE_ITEMVALUE = 'SET_GE_ITEMVALUE';
export const EXCUTE_PROCEED = 'EXCUTE_PROCEED';
export const SAVE_FOR_LATER = 'SAVE_FOR_LATER';
export const CLEAR_PROCEED_RESULT = 'CLEAR_PROCEED_RESULT';
export const SET_PROCEEDING = 'SET_PROCEEDING';
export const SET_TOTAL_ERROR = 'SET_TOTAL_ERROR';
export const SET_FIRSET_ERROR = 'SET_FIRSET_ERROR';
export const SET_SNACKBAR_ID = 'SET_SNACKBAR_ID';
export const SET_CHECKLIST_VERSION = 'SET_CHECKLIST_VERSION';
export const SET_SCROLLINTOVIEW = 'SET_SCROLLINTOVIEW';
export const INI_REDUCER = 'INI_REDUCER';
export const UPLOAD_GEEVIENCE = 'UPLOAD_GEEVIENCE';
export const GET_PROPOSER = 'GET_PROPOSER';
export const CANCEL_EDIT = 'CANCEL_EDIT';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export function iniSIPChecklistReducer() {
  return {
    type: INI_REDUCER,
    data: null,
  };
}

export function setSIPChecklistEditingInfo(data) {
  //这个应该是废弃了
  return {
    type: SET_SIP_CHECKLIST_EDITING_INFO,
    data: data,
  };
}

export function setSnackbarShow(data) {
  return {
    type: SET_SNACKBAR_SHOW,
    data: data,
  };
}
export function setSnackbarId(data) {
  return {
    type: SET_SNACKBAR_ID,
    data: data,
  };
}

//witch：option=['immediateDecision','validSipDetails','validSipDetailsComments','overallComment']
export function setItemValue(witch, newValue) {
  return {
    type: SET_ITEMVALUE,
    data: { witch: witch, newValue: newValue },
  };
}

export function setGeItemValue(witch, newValue, index) {
  return {
    type: SET_GE_ITEMVALUE,
    data: { witch: witch, newValue: newValue, index: index },
  };
}

export function clearProceedResult() {
  return {
    type: CLEAR_PROCEED_RESULT,
    data: null,
  };
}

export function setProceeding(data) {
  return {
    type: SET_PROCEEDING,
    data: data,
  };
}

export function setTotalError(data) {
  return {
    type: SET_TOTAL_ERROR,
    data: data,
  };
}

export function setFirstError(data) {
  return {
    type: SET_FIRSET_ERROR,
    data: data,
  };
}

export const SET_IS_FLAG_CHANGED = 'SET_IS_FLAG_CHANGED';
export function setIsFlagChanged(data) {
  return {
    type: SET_IS_FLAG_CHANGED,
    data: data,
  };
}

export function changeCheckListStatus(status) {
  return {
    type: CHANGE_STATUS,
    data: status,
  };
}

import * as React from "react";
import {ReactElement, useEffect} from "react";
import {Box, Stack} from "@mui/material";
import MyOnlyText from "@/componentsphase2/MyOnlyText";
import AddNewFieldButton from "@/componentsphase2/Button/ContainerButtons/AddNewFieldButton";

import styles from './index.module.scss';
import {InlineRichTextInput} from "@/componentsphase2/RichText/RichText";


export interface ErrorChecker<T, Context> {
    enable: boolean;
    check: (item: T, context: Context) => boolean;
    message: string | ((item: T, context: Context) => string);
}

export type InputRenderFunction = (item: string, index: number, listProps: InputListProps) => ReactElement;

export interface InputListProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'placeholder'> {
    placeholder?: string | ((index: number) => string) ;
    bottomHelperText?: string;
    value: string[];
    setValue: (value: string[]) => void;
    minItems?: number;
    maxItems?: number;
    onItemExceed?: () => void;
    errorCheckers?: ErrorChecker<string, string[]>[];
    setError(value: boolean): void;
    id?: string;
    ['data-selenium-id']?: string;
    inputRenderFunction?: InputRenderFunction;
}

function renderInput(item: string, index: number, listProps: InputListProps): ReactElement {
    const {
        id,
        ['data-selenium-id']: dataSeleniumId,
        value,
        setValue,
        minItems = 1,
        errorCheckers = [],
        placeholder
    } = listProps;
    return <MyOnlyText
        id={`${id}-${index}`}
        data-selenium-id={`${dataSeleniumId}-${index}`}
        key={`${id}-${index}`}
        width={'100%'}
        height={'33px'}
        alwaysHaveEndAdornment={true}
        value={item}
        setValue={(newValue: string) => {
            setValue(
                value.map((valueItem, valueItemIndex) => {
                    if (valueItemIndex === index) return newValue;
                    return valueItem;
                })
            );
        }}
        handleIconButtonClick={
            index >= minItems
                ? () => {
                    let newArr = value.concat();
                    newArr.splice(index, 1);
                    setValue(newArr);
                }
                : () => {
                }
        }
        needEndAdornment={index >= minItems}
        isError={errorCheckers.some(checker => checker.enable && checker.check(item, value))}
        placeholder={placeholder instanceof Function ? placeholder(index) : placeholder ?? 'Type here'}
    />;
}

export function InputList(props: InputListProps): ReactElement {
    const {
        placeholder,
        bottomHelperText = '',
        value,
        setValue,
        minItems = 1,
        maxItems = 1,
        onItemExceed = () => {},
        errorCheckers = [],
        setError = () => {},
        id,
        ['data-selenium-id']: dataSeleniumId,
        inputRenderFunction = renderInput,
        ...others
    } = props;

    useEffect(() => {
        setError(errorCheckers.some(checker => checker.enable && value.some(item => checker.check(item, value))));
    }, [value, ...errorCheckers.map(checker => checker.enable)]);

    const errText: string = React.useMemo(() => {
        for (const checker of errorCheckers) {
            if (checker.enable) {
                for (const item of value) {
                    if (checker.check(item, value)) {
                        return checker.message instanceof Function ? checker.message(item, value) : checker.message;
                    }
                }
            }
        }
        return '';
    }, [value, ...errorCheckers.map(checker => checker.enable)]);

    return (
        <Box id={id}
             data-selenium-id={dataSeleniumId}
             {...others}
        >
            <Stack spacing={0.5}>
                {value.map((item,index) => inputRenderFunction(item, index, props))}
            </Stack>
            <Box className={styles['bottom-line']}>
                <Box className={styles['left-box']}>
                    <Box className={styles['helper-text']}>
                        {bottomHelperText}
                    </Box>
                    <Box className={styles['error-box']}>
                        {errText}
                    </Box>
                </Box>
                <AddNewFieldButton
                    className={styles['new-field-button']}
                    onClick={() => {
                        if (value.length === maxItems) {
                            onItemExceed();
                            return;
                        }
                        let newArr = value.concat();
                        newArr.push('');
                        setValue(newArr);
                    }}
                />
            </Box>
        </Box> );
}

function renderRichInput(item: string, index: number, listProps: InputListProps): ReactElement {
    const {
        id,
        ['data-selenium-id']: dataSeleniumId,
        value,
        setValue,
        minItems = 1,
        errorCheckers = [],
        placeholder
    } = listProps;

    return <InlineRichTextInput
        id={`${id}-${index}`}
        data-selenium-id={`${dataSeleniumId}-${index}`}
        key={`${id}-${index}`}
        placeholder={placeholder instanceof Function ? placeholder(index) : placeholder ?? ''}
        value={item}
        setValue={(newValue: string) => {
            setValue(
                value.map((valueItem, valueItemIndex) => {
                    if (valueItemIndex === index) return newValue;
                    return valueItem;
                })
            );
        }}
        needEndAdornment={index >= minItems}
        handleIconButtonClick={index >= minItems
            ? () => {
                let newArr = value.concat();
                newArr.splice(index, 1);
                setValue(newArr);
            }
            : () => {
            }}
        error={errorCheckers.some(checker => checker.enable && checker.check(item, value))}
        helperText={errorCheckers.some(checker => checker.enable && checker.check(item, value)) ?
            errorCheckers.find(checker => checker.enable && checker.check(item, value))?.message : ''}
    />;
}

export function RichInputList(props: InputListProps): ReactElement {
    const {
        className,
        ...rest
    } = props;

    return (<InputList
        className={` ${className??''} ${styles['rich-input-list-root']} `}
        inputRenderFunction={renderRichInput}
        {...rest}
    />);
}


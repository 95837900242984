import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomHeading = styled(Typography)(theme => ({
  font: 'Open Sans',
  fontWeight: '600',
  fontSize: '22px',
  lineHeight: '27.5px',
  color: '#596A7C',
}));

export default CustomHeading;

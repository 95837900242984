import { Box } from "@mui/material";
import { useState } from "react";
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';


export default function SectionFolder(props) {
  const {
    title = '',
    titleEndElement = (<></>),
    children = (<></>),
    id = 'SectionFolder',
    defaultOpen = true,
    ...other
  } = props;
  const [open, setOpen] = useState(defaultOpen);
  return (
    <Box
      id={`${id}`}
      sx={{
        width:'1092px'
      }}
      {...other}>
      <Box
        id={`${id}-title`}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom:'16px',
          cursor:'pointer',
        }}>
        <Box
          id={`${id}-arrowIcon`}
          onClick={() => { setOpen(!open); }}
          sx={{ height: '24px', width: '24px' }}
          className='titleFoldButton'>
          {open ? <MarketChevronUp /> : <SIDetailArrowDown />}
        </Box>
        <Box
          sx={{
            //styleName: Regular Bold;
            'font-family': 'Open Sans',
            'font-size': '16px',
            'font-weight': '600',
            'line-height': '24px',
            'letter-spacing': '0em',
            'text-align': 'left',

            marginLeft:'8px',
            color:'#113D95',
            cursor:'default'
          }}>
          {title}
        </Box>
        {titleEndElement}
      </Box>
      {open?children:<></>}
    </Box>
  );
};
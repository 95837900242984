import Main from '@/components/Main';
import { Box } from '@mui/system';

import { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// import { changeSiderOpen } from '../../../actions/UserManageAction';
import { openConfirm, setTextParam, setEditType } from '@/actions/SIMT-User/Alert/AlertAction';
import ShowBlockBox from './ShowBlockBox';
import {
  clearCustomizedPath,
  setCustomizedPath,
} from '@/actions/BreadcrumbAction';
import WiderMain from '@/components/WiderMain';
import { IconButton, Stack } from '@mui/material';

// import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import SevereIssueHeadIcon from '@/assets/SevereIssueHeadIcon.svg';
import MildIssueheadIcon from '@/assets/MildIssueheadIcon.svg';
import { getBasicInformation } from "@/actions/SIMT-SI/GuestEditor";
import { getFlagByGECode } from "@/actions/SIMT-SI/GuestEditor/GEFlag";

function GEDetailPage(props) {
  const dispatch = useDispatch();

  let { geId } = useParams();

  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      filterOpen: state.SI.filterOpen,
      GEBasicInformation: state.GE.GEBasicInformation,
      FlagData: state.GE.FlagData,
    };
  };

  const childRef = useRef();

  const { open, filterOpen, GEBasicInformation, FlagData } =
    useSelector(selector);
  const navigate = useNavigate();
  const [widthpx, setWidthpx] = useState('1195px');
  const [marginAddPx, setMarginAddPx] = useState('240px'); //侧边栏边距修正
  const [outCancelBtnClick, setCancelBtnClick] = useState(false);
  const [outSaveBtnClick, setSaveBtnClick] = useState(false);
  const [outSaveDisabled, setSaveDisabled] = useState(false);
  const [ifEdit, setIfEdit] = useState(false); //ge是否编辑设置

  let width = document.body.clientWidth;
  /**
   * @author LiuXin
   * @date 2022/5/24
   * @description 实时监控浏览器窗口大小变化，在过小时自适应屏幕，在过大时固定显示尺寸
   */
  window.onresize = function () {
    resetMainBoxWidth();
  };

  useEffect(() => {
    resetMainBoxWidth();
  }, [open]);

  /**
   * @author LiuXin
   * @date 2022/5/24
   * @description 监控当前窗口大小，在过小时自适应屏幕，在过大时固定显示尺寸
   */
  const resetMainBoxWidth = () => {
    width = document.body.clientWidth;
    if (open === true) {
      width = width - 266;
    }
    if (width < 1215) {
      if (width < 1070) {
        let leftAdd = 1070 - width + 266;
        setWidthpx('1070px');
        setMarginAddPx(leftAdd + 'px');
      } else {
        setWidthpx(width - 20 + 'px');
      }
    } else {
      setWidthpx('1195px');
    }
  };

  //Main主盒子样式
  const maincss = {
    width: widthpx,
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    border: '1px solid #dddddd',
    boxSizing: 'border-box',
    borderRadius: '4px',
    paddingLeft: '50px',
    paddingRight: '100px',
    paddingTop: '40px',
    paddingBottom: '80px',
  };
  //GE Name 样式
  const titlecss = {
    width: '1000px',
    height: '28px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '125%',
    fontFamily: 'Open Sans',
    display: 'flex',
    alignItems: 'flex-end',
    color: '#262E35',
  };

  useEffect(() => {
    dispatch(getBasicInformation(geId));
  }, [geId]);

  useEffect(() => {
    if (
      typeof GEBasicInformation === 'string' &&
      (GEBasicInformation.match(/no[0-9]+exist/g) ||
        GEBasicInformation === 'Access is denied')
    ) {
      if (GEBasicInformation === 'Access is denied') {
        // dispatch(setEditType("noAccess"));
        // dispatch(openConfirm());
      } else {
        dispatch(setTextParam(GEBasicInformation));
        dispatch(setEditType('GEDetailError'));
        dispatch(openConfirm());
      }
      navigate('/simt/auth/siRelated/ge/overview');
    }
    if (GEBasicInformation) {
      dispatch(setCustomizedPath(GEBasicInformation?.fullName ?? 'GE Detail'));
      if (GEBasicInformation.geCode !== undefined) {
        dispatch(getFlagByGECode(GEBasicInformation.geCode));
      }
    }
  }, [GEBasicInformation]);

  function MyBreadCrums() {
    return (
      <Breadcrumbs
        aria-label='breadcrumb'
        sx={{
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Link
          underline='hover'
          color='inherit'
          href='/simt/auth/siRelated/ge/overview/'
          sx={{
            color: '#0052CC',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            width: '146px',
            height: '20px',
            '&:hover': {
              // textDecoration: 'underline',
              color: 'var(--primary-600, #113D95)',
              fontWeight: '600',
              textDecoration: 'none !important',
            },
            '&:active': {
              fontWeight: '600',
              color: '#113D95',
              textDecoration: 'none !important',
            },
          }}
        >
          Guest Editor Overview
        </Link>
        <Typography
          sx={{
            height: '20px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#596A7C',
          }}
        >
          {GEBasicInformation === undefined
            ? 'GE NAME'
            : `${GEBasicInformation.lastName} ${GEBasicInformation.firstName}`}
        </Typography>
      </Breadcrumbs>
    );
  }
  const isSevere = useMemo(() => {
    let res = 0;
    if (FlagData !== 'error') {
      for (let i = 0; i < FlagData.length; i++) {
        if (FlagData[i].flagInfo.type.split('-')[1] === 'Severe Issue') {
          res = 1;
          break;
        } else if (FlagData[i].flagInfo.type.split('-')[1] === 'Mild Issue') {
          res = 2;
        }
      }
    }
    return res;
  }, [FlagData]);
  return (
    <Box data-selenium-id='GEPage_GEDetailPage-Box'>
      <WiderMain
        sx={{
          padding: '0 24px 24px 24px',
          margin: 0,
          marginLeft: open ? '240px' : '83px',
          boxSizing: 'border-box',
          backgroundColor: 'transparent',
          width: 'auto',
          '& *': {
            boxSizing: 'border-box',
          },
        }}
        open={open}
        filterOpen={filterOpen}
        drawerWidth={marginAddPx}
        data-selenium-id='GEPage_GEDetailPage-Box-WiderMain'
      >
        {/* breadcrumbs */}
        <MyBreadCrums data-selenium-id='GEPage_GEDetailPage-Box-WiderMain-MyBreadCrums' />
        {/* title box */}
        <Stack
          direction='row'
          justifyContent='space-between'
          id='GEdetailTitleBox'
          sx={{
            backgroundColor: '#ffffff',
            padding: '23px 32px 23px 33px',
            marginBottom: '4px',
          }}
          data-selenium-id='GEPage_GEDetailPage-Box-GEdetailTitleBox'
        >
          <Box
            id='gePageGeFullNameText'
            data-selenium-id='GEPage_GEDetailPage-Box-GEdetailTitleBox-gePageGeFullNameText'
            sx={titlecss}
          >
            <Box position={'relative'}>
              {GEBasicInformation === undefined
                ? 'GE NAME'
                : GEBasicInformation.fullName}
              {isSevere > 0 ? (
                isSevere < 2 ? (
                  <IconButton
                    sx={{ position: 'absolute', right: -110, top: -3 }}
                  >
                    <SevereIssueHeadIcon></SevereIssueHeadIcon>{' '}
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{ position: 'absolute', right: -110, top: -3 }}
                  >
                    <MildIssueheadIcon></MildIssueheadIcon>{' '}
                  </IconButton>
                )
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Stack>

        <Box
          paddingX='32px'
          paddingY='22px'
          sx={{ backgroundColor: '#fff' }}
          data-selenium-id='GEPage_GEDetailPage-Box-Box1'
        >
          <ShowBlockBox
            title='Basic Information'
            data-selenium-id='GEPage_GEDetailPage-Box-Box1-ShowBlockBoxBasicInformation'
            geId={geId}
            GEBasicInformation={GEBasicInformation}
            setIfEdit={setIfEdit}
            ref={childRef}
            outSaveDisabled={outSaveDisabled}
            setSaveDisabled={setSaveDisabled}
            ifEdit={ifEdit}
          />
          <ShowBlockBox
            title='Flag'
            data-selenium-id='GEPage_GEDetailPage-Box-Box1-ShowBlockBoxFlag'
            geId={GEBasicInformation.geCode}
            GEBasicInformation={GEBasicInformation}
            FlagData={FlagData}
          />
          <ShowBlockBox
            title='Associated Special Issues'
            data-selenium-id='GEPage_GEDetailPage-Box-Box1-ShowBlockBoxAssociatedSpecialIssues'
            geId={GEBasicInformation.geCode}
            GEBasicInformation={GEBasicInformation}
          />
          <ShowBlockBox
            title='Editing History'
            data-selenium-id='GEPage_GEDetailPage-Box-Box1-EditingHistory'
            geId={GEBasicInformation.geCode}
            GEBasicInformation={GEBasicInformation}
          />
        </Box>
      </WiderMain>
    </Box>
  );
}

export default GEDetailPage;

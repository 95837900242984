/**
 * This file includes basic eChart options for barchart & piechart diagram type
 * The 1st part has some common constants
 * The 2nd defined an Options class
 */

/**
 * title & legend
 */
const TITLE_HEIGHT = 70;
const TITLE_TOP_PADDING = 30;

/**
 * bar chart
 */
const BARCHART_TOP = 52;
const BARCHART_LEFT = 59;
const BARCHART_RIGHT = 59;
const BARCHART_HEIGHT = 405; 

/**
 * title & legend
 */
const LEGEND_HEIGHT = 15;
const LEGEND_PADDING_TOP = 5;
const LEGEND_PADDING_BOTTOM = 49;
const EXTRA_PADDING_FOR_LABEL_TEXT_WRAPPING = 30;

/**
 * zoom controller
 */
const ZOOM_CONTROL_RIGHT_OFFSET = 42;

/**
 * Pie chart
 */
const PIE_CHART_PADDING_LEFT = 40;
const PIECHART_RADIUS = 100;

/**
 * dataZoom
 */
const DATA_ZOOM_INSIDE = {
  type: 'inside',
  yAxisIndex: 0,
  start: 0,
  end: 100,
  filterMode: 'none',
  // to check 1: shall we fix bottom to 0?
  // to check 2: shall we set integer span?
  minValueSpan: 1,
  minSpan: 20,
};

const DATA_ZOOM_SLIDER = {
  type: 'slider',
  yAxisIndex: 0,
  // right
  show: true,
  showDetail: true,
  showDataShadow: false,
  fillerColor: '#4C81EB',
  backgroundColor: '#D9D9D9B3',
  borderRadius: 15,
  width: 4,
  moveHandleSize: 0,
  handleIcon: 'circle',
  handleSize: 12,
  handleStyle: {
    borderColor: '#FFFFFF',
    borderWidth: 3,
    color: '#4C81EB',
  },
  textStyle: {
    fontSize: 8,
    color: '#113D95',
    backgroundColor: '#F1F3F5',
    padding: 5,
    height: 18,
    lineHeight: 18,
    borderRadius: 3,
  },
  /**
   * filter mode set to show partial bar
   */
  // filterMode: "filter"
  // filterMode: "weakFilter"
  // filterMode: "empty"
  filterMode: 'none',
  /**
   * filter mode set to show partial bar
   */
  /**
   * set min span
   */
  minSpan: 20,
  minValueSpan: 1,
  /**
   * set min span
   */
};

const LINE_STYLE = {
  Color: '#DCDCDC',
  Type: 'solid',
  width: 0.4,
  opacity: 1,
};

const VALUE_AXIS_LABLE_STYLE = {
  color: '#6F6F6F',
  fontStyle: 'normal',
  fontWeight: 400,
  fontFamily: 'Open Sans',
  fontSize: 10,
  lineHeight: 10,
};

const VALUE_AXIS = {
  type: 'value',
  gridIndex: 0,
  nameTextStyle: {
    fontSize: '50px',
    align: 'left',
  },
  splitNumber: 10,
  /**
   * modified start
   *
   * min is integer
   */
  minInterval: 1,
  /**
   * modified end
   *
   * min is integer
   */
  axisLabel: {
    show: true,
    interval: 'auto',
    inside: false,
    rotate: 0,
    margin: 8,
    formatter: function (value, index) {
      return value;
    },
    showMinLabel: null,
    showMaxLabel: null,
    textStyle: VALUE_AXIS_LABLE_STYLE,
  },
  splitLine: {
    show: true,
    interval: 'auto',
    lineStyle: LINE_STYLE,
  },
};

const CATEGORY_AXIS_LABLE_STYLE = {
  color: '#6F6F6F',
  fontStyle: 'normal',
  fontWeight: 400,
  fontFamily: 'Open Sans',
  fontSize: 10,
  lineHeight: 10,
};

const BAR_SERIE_LABEL_STYLE = {
  datasetIndex: 0,
  type: 'bar',
  stack: 'total',
  label: {
    show: true,
  },
  emphasis: {
    disabled: true,
  },
  itemStyle: {
    normal: {
      label: {
        show: true,
        position: 'inside',
        textStyle: {
          color: '#FFFFFF',
          fontSize: 7,
        },
      },
    },
  },
};

const LEGEND_STYLE = {
  icon: 'circle',
  itemGap: 16,
  width: '415px',
  selectedMode: false,
  inactiveColor: '#ccc',
  textStyle: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
    color: '#596A7C',
  },
  // bottom: LEGEND_PADDING_BOTTOM,
};

const TOOLTIP_STYLE = {
  showContent: true,
  trigger: 'item',
  padding: [6, 8, 6, 8], // top, right, bottom, left
  position: function (point, params, dom, rect, size) {
    const boxWidth = size.contentSize[0];
    const boxHeight = size.contentSize[1];
    const seriesIndex = params.seriesIndex;
    const x = rect.x + 0.5 * rect.width - 0.5 * boxWidth;
    const y = rect.y - boxHeight - (seriesIndex === 2 ? 24 : 2); // 23px or 2px above the item

    return [x, y];
  },
  // alwaysShowContent: true,
  formatter: params => {
    // console.log(params)
    const stageName = params.dimensionNames[params.seriesIndex + 1];
    let dataStr = `<div style="padding: 0; margin: 0;">
      <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 0 0 2.5px 0;">${params.name}</p>
      <p style="font-size: 10px; font-weight: 400;color: #848484; line-height: 14px; margin: 0;">${params.seriesName}&nbsp;:&nbsp;${params.data[stageName]}</p>
    </div>`;
    return dataStr;
  },
};

const TITLE = {
  textStyle: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 700,
    color: '#113D95',
    lineHeight: 17.5,
  },
  subtextStyle: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 400,
    color: '#113D95',
    lineHeight: 17.5,
  },
  left: 'center',
};

const GRID = {
  containLabel: true,
};

const PIE_COLORS = [
  '#71C8FF',
  '#4C81EB',
  '#9098E2',
  '#FFB152',
  '#42C1B3',
  '#FF8180',
];

export class JournalStackChartOptions {
  constructor() {
    this.barchartTop = BARCHART_TOP;
    this.barchartLeft = BARCHART_LEFT;
    this.barchartRight = BARCHART_RIGHT;
    this.barchartHeight = BARCHART_HEIGHT;
    this.titleHeight = TITLE_HEIGHT;
    this.titleTopPadding = TITLE_TOP_PADDING;
    this.legendHeight = LEGEND_HEIGHT;
    this.legendPaddingTop = LEGEND_PADDING_TOP;
    this.legendPaddingBottom = LEGEND_PADDING_BOTTOM;
    this.dataZoomInside = Object.assign({}, DATA_ZOOM_INSIDE);
    this.dataZoomSlider = Object.assign({}, DATA_ZOOM_SLIDER);
    this.zoomControlRightOffset = ZOOM_CONTROL_RIGHT_OFFSET;
    this.pieChartPaddingLeft = PIE_CHART_PADDING_LEFT;
    this.pieRadius = PIECHART_RADIUS;
    this.pieColors = [...PIE_COLORS];
    this.barSerieOption = Object.assign({}, BAR_SERIE_LABEL_STYLE);
    this.hasTitle = false;
    this.title = '';
    this.subTitle = '';
    this.hasZoom = false;
    this.selectedLegend = [];
    this.legendData = [];
    this.dataForBar = null;
    this.dataForPie = null;
  }

  getOption() {
    return {
      grid: this.getGrid(),
      legend: this.getSelectedLegend(),
      xAxis: this.getCategoryAxis(),
      yAxis: VALUE_AXIS,
      tooltip: this.getTooltip(),
      series: this.getSeries(),
      color: this.pieColors,
      title: this.getByTitle(),
      dataZoom: this.getZoom(),
      dataset: this.getDataset(),
    };
  }

  getHeightWithTitle() {
    let totalHeight = 0;
    
    if(this.hasTitle){
      totalHeight += this.titleHeight + this.titleTopPadding;
    }

    const barchartHeightWithPaddingTop = this.barchartTop + this.barchartHeight;

    let legendHeightWithPadding = this.legendPaddingTop + this.legendHeight + this.legendPaddingBottom;

    if(this.hasTitle){
      legendHeightWithPadding += EXTRA_PADDING_FOR_LABEL_TEXT_WRAPPING;
    }

    totalHeight +=  barchartHeightWithPaddingTop + legendHeightWithPadding;

    return totalHeight;
  }

  getLegendHeight() {
    return this.legendPaddingTop + this.legendHeight  + this.legendPaddingBottom;
  }

  getBarChartTop(){
    let top = this.barchartTop;
    if(this.hasTitle){
      top += this.titleHeight;
    }
    return top;
  }

  getGrid() {
    const grid = {
      ...GRID,
      left: this.barchartLeft,
      right: this.barchartRight,
      top: this.getBarChartTop(),
      height: this.barchartHeight,
    };
    return grid;
  }

  getByTitle() {
    if (this.hasTitle) {
      return {
        ...TITLE,
        top: this.titleTopPadding,
        text: this.title,
        subtext: '(' + this.subTitle + ')',
      };
    }
    return undefined;
  }

  // set Title if hasTitle
  setTitle(hasTitle, text, subtext) {
    this.hasTitle = hasTitle;
    this.title = text;
    this.subTitle = subtext;
    return this;
  }

  getCategoryAxis = () => {
    return {
      type: 'category',
      axisTick: {
        show: false,
      },
      axisLabel: this.getCategoryAxisLabel(),
      axisLine: {
        show: true,
        onZero: true,
        lineStyle: {
          color: '#979797',
          width: 0.4,
        },
      },
    };
  };

  getCategoryAxisLabel() {
    return {
      show: true,
      interval: false,
      inside: false,
      rotate: 0,
      margin: 12,
      formatter: function (value, index) {
        return value;
      },
      showMinLabel: true,
      showMaxLabel: true,
      hideOverlap: false,
      textStyle: CATEGORY_AXIS_LABLE_STYLE,
      width: 42,
      overflow: 'truncate',
      verticalAlign: 'top',
    };
  }

  getZoom() {
    if (this.hasZoom) {
      let slider = {
        ...this.dataZoomSlider,
        right: this.zoomControlRightOffset,
      };
      return [this.dataZoomInside, slider];
    }
    return undefined;
  }

  setZoom(hasZoom) {
    this.hasZoom = hasZoom;
    return this;
  }

  getSelectedLegend() {
    let top = this.getBarChartTop() + this.barchartHeight + this.legendPaddingTop;

    if(this.hasTitle){
      top += EXTRA_PADDING_FOR_LABEL_TEXT_WRAPPING;
    }

    return {
      ...LEGEND_STYLE,
      selected: this.selectedLegend,
      data: this.legendData,
      top
    };
  }

  setLegendData(data) {
    this.legendData = data;
    // console.log('this legend data', data);
    return this;
  }
  
  setSelectedLegend(selectedLegend) {
    this.selectedLegend = selectedLegend;
    return this;
  }

  getDataset() {
    return [
      {
        source: this.dataForBar,
      },
    ];
  }

  setDataSource(dataForBar) {
    this.dataForBar = dataForBar;
    return this;
  }

  // create series of pie and bar charts
  getSeries() {
    return this.getBarSeries();
  }

  getBarSeries() {
    return [];
  }

  getTooltip() {
    return TOOLTIP_STYLE;
  }
}

import PageHead from './FYTrendsPageHead';
import TableContent from './TableContent';
import DiagramContent from './FYTrendsDiagrams';
export default function OAvsTotal({ showTable, setShowTable }) {
  return (
    <>
      <PageHead showTable={showTable} setShowTable={setShowTable} />
      {showTable ? <TableContent /> : <DiagramContent />}
    </>
  );
}

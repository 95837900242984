import {
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  FormHelperText,
} from '@mui/material';
import { SectionContainer } from '../CustomComponents/CustomContainer';

import { useState, useMemo, useEffect } from 'react';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
const fontStyle = {
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
};
const labelStyle = { width: '260px' };
const DECISION_AGREE = 'Agree';
const DECISION_DECLINE = 'Decline';



export default function EditorialBoardMemberReviewSection(props) {
  const { onChange, setBtnEnable } = props;
  const [value, setValue] = useState('');
  const [comments, setComments] = useState('');
  const [error, setError] = useState(false);
  const [isCommentError, setIsCommentError] = useState(true);
  const [focusComments, setFoucusComments] = useState(false);
  const handleRadioChange = event => {
    setValue(event.target.value);
    setError(false);
  };

  function validateData(ebmReviewDto) {
    const { status, comments } = ebmReviewDto;
    if (DECISION_AGREE !== status && DECISION_DECLINE !== status) {
      return false;
    }
    if (DECISION_AGREE === status) {
      if (comments === '') {
        return false;
      } else {
        if (comments.length > 1000) {
          return false;
        }
        setIsCommentError(false);
      }
    }
    return true;
  }

  useEffect(() => {
    const ebmReviewDto = {
      status: value,
      comments: comments,
    };
    setBtnEnable(validateData(ebmReviewDto));
    onChange(ebmReviewDto);
    //this will set the parent value.
  }, [value, comments]);

  const isAccept = useMemo(() => {
    return value === DECISION_AGREE;
  }, [value]);

  return (
    <SectionContainer title='Editorial Board Member Review'>
      <Stack direction='row' alignItems='center' gap={'8px'}>
        <Stack sx={labelStyle} direction='row'>
          <Typography sx={fontStyle}>
            {isAccept
              ? `Provide Comments:`
              : `Would you like to provide Comments:`}
          </Typography>
          {value === '' && madatoryBox()}
        </Stack>
        <RadioGroup
          aria-label='quiz'
          name='quiz'
          value={value}
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value={DECISION_AGREE}
            control={<Radio />}
            label={DECISION_AGREE}
          />
          <FormControlLabel
            value={DECISION_DECLINE}
            control={<Radio />}
            label={DECISION_DECLINE}
          />
        </RadioGroup>
      </Stack>
      {value === DECISION_AGREE && (
        <Stack direction='row' alignItems='flex-start' gap={'8px'}>
          <Stack direction='row' sx={labelStyle}>
            <Typography sx={fontStyle}>Comments:</Typography>
            {madatoryBox()}
          </Stack>
          <Box>
            <MyOnlyText
              placeholder='Add Comment here.'
              value={comments}
              setValue={setComments}
              width='500px'
              height='100px'
              multiline={true}
              sx={{ flexGrow: 1 }}
              minRows={5}
              maxRows={5}
              isError={isCommentError&&!focusComments}
              focusComments={focusComments}
              onFocus={() =>{setFoucusComments(true);}}
              onBlur={() =>{setFoucusComments(false);}}
              errorMessage='If you accept to provide comments, please ensure they are added before submitting.'
            />

            <FormHelperText
              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormHelperText'
              sx={{
                textAlign: 'right',
                marginTop:
                  comments.length > 1000 && !focusComments ? '-14px' : '4px',
                fontSize: '12px',
                lineHeight: '18px',
                height: '15px',
                color: comments.length > 1000 ? '#DE350B' : '#596A7C',
                marginBottom: '3px',
              }}
            >
              {comments.length}/1000
            </FormHelperText>
          </Box>
        </Stack>
      )}
    </SectionContainer>
  );
}

function madatoryBox() {
  return (
    <Box
      data-selenium-id='SIP_DetailPage_DecisionSection-Title-Box2'
      style={{
        width: '8px',
        height: '18px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '0.96px',
        color: '#C40000',
        marginLeft: '2px',
        display: 'inline-block',
      }}
    >
      *
    </Box>
  );
}

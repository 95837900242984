import { useMemo } from 'react';
import Chart from '@/components/Chart/Chart';
// import NoDataPage from '@/components/Chart/NoDataPage';
import { AAndAOverviewOptions } from '../options';

const ChartAAndA = ({
  // isEmptyData,
  // selectedSGs,
  // alertLabel,
  barchartData,
  width,
  hasZoom = false,
  hasTitle = false,
  title,
  subTitle,
  // loading = false,
  // error = false,
  catagoryAttributeName,
  valueAttributeNames,
  barSeriesValue,
}) => {
  // if (isEmptyData) {
  //   return (
  //     <NoDataPage
  //       title={`There is no data for any ${alertLabel.toLowerCase()}s on the selected date.`}
  //       subTitle={'Please choose a different date to view diagram.'}
  //     />
  //   );
  // } else if (Array.isArray(selectedSGs) && selectedSGs.length === 0) {
  //   return <NoDataPage title={`Please select at least one ${alertLabel}.`} />;
  // }

  const chartOption = useMemo(() => {
    return new AAndAOverviewOptions(
      catagoryAttributeName,
      valueAttributeNames,
      barSeriesValue
    );
  }, [catagoryAttributeName, valueAttributeNames, barSeriesValue]);

  const options = useMemo(() => {
    // if loading or error skip drawing the chart
    // if (loading || error) {
    //   return null;
    // }
    chartOption
      .setZoom(hasZoom)
      .setTitle(hasTitle, title, subTitle)
      .setDataSource(barchartData);
    return chartOption.getOption();
    // }, [hasZoom, hasTitle, title, subTitle, barchartData, loading, error]);
  }, [hasZoom, hasTitle, title, subTitle, barchartData]);


  const chartHeight = useMemo(() => {
    // if loading or error set chart height to 0
    // if (loading || error) {
    //   return 0;
    // }
    return chartOption.getHeightWithTitle();
  }, [hasTitle, title, subTitle, barchartData]);
  // console.log("======chartHeight=====, ", chartHeight)

  const [minFontSize, maxFontSize] = useMemo(() => {
    // if loading or error set chart height to 0
    // if (loading || error) {
    //   return 0;
    // }
    return chartOption.getLabelFontSize();
    // }, [hasTitle, title, subTitle, barchartData, loading, error]);
  }, [hasTitle, title, subTitle, barchartData]);

  return (
    <Chart
      options={options}
      height={chartHeight}
      width={width}
      isZoomLabelFontSize={true}
      minFontSize={minFontSize}
      maxFontSize={maxFontSize}
    />
  );
};

export default ChartAAndA;

export const stateRadioList = [
  {
    key: 'marketingSectionAdded',
    label: 'Marketing Section Added',
  },
  {
    key: 'readyforPromotion',
    label: 'Ready for Promotion',
  },
];

export const cfsRadioList = [
  {
    key: 'yes',
    label: 'Yes',
  },
  {
    key: 'no',
    label: 'No',
  },
];

export const rmRadioList = [
  {
    key: 'free',
    label: 'Free',
  },
  {
    key: 'hybridModel',
    label: 'Hybrid Model',
  },
  {
    key: 'OA',
    label: 'OA',
  },
  {
    key: 'subscriptionOnly',
    label: 'Subscription Only',
  },
];
export const ChinaGERadioList = [
  {
    key: 'siWithChinaGE',
    label: 'SIs with China GE (lead or co-GE)',
  },
];

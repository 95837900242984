//@ts-check
import { axios_instance } from "@/utils/axios_instance";
import { GET_CE, GET_SCAM, TRANSFORM_SI } from "@/actions/SIMT-SI/SpecialIssue/SIAction";
import { getHandlingCeUserService } from "@/actions/SIMT-User/User/SystemAdmin";

/**@typedef {import("redux").Dispatch} Dispatch */
/**@typedef {string | null | undefined} value */

/**
 * @param {value} value
 * @param {import("@/actions/SIMT-User/User/SystemAdmin").SystemAdminRequest} [otherParam]
 */
// use permission code for user type
export function getCE(value, otherParam, type = GET_CE) {
    if (value === undefined || value === null) {
        return;
    }
    /**
      * @param {Dispatch} dispatch 
      */
    return async dispatch => { // this service should be decoupled from SI or SIP store.
        try {
            const res = await getHandlingCeUserService({
                fullName: value,
                pageNum: 1,
                pageSize: 10,
                fuzzy: true,
                ...otherParam,
            })(dispatch);
            if (!res) {
                dispatch({ type, data: 'error' });
                return;
            }
            dispatch({ type, data: res.result });
        } catch {
            return dispatch({ type, data: 'error' });
        }
    };
}

/**
 * @param {value} value
 */
// get user by type
export const getSIPCE = (value) => getCE(value, { activeTierSip: true });

/**
 * @param {value} value
 */
export const getAllActiveCE = (value) => getCE(value, { active: true });

/**
 * @param {value} value
 */
export const getActiveCE = (value) => getCE(value, { activeTierSi: true });

/**
 * @param {value} value
 */
export const getScam = (value) => getCE(value, { scam: true }, GET_SCAM);

/**
 * @param {{readonly siCode?: string;readonly userId?: number;}} value
 */
export function batchTransferSI(value) {
    /**
    * @param {Dispatch} dispatch 
    */
    return async dispatch => {
        try {
            const url = '/api/v1/si-service/special-issues';
            const res = await axios_instance.put(url, { siCodesDto: value }, {
                headers: {
                    authorization: localStorage.getItem('authorization') ?? '',
                },
            });
            const { code, message } = res.data;
            if (code === 200) {
                dispatch({ type: TRANSFORM_SI, data: message });
            } else {
                dispatch({ type: TRANSFORM_SI, data: 'error' });
            }
        } catch {
            dispatch({ type: TRANSFORM_SI, data: 'error' });
        }
    };
}
/*
 * @Author: LiuJingQian 1250366059@qq.com
 * @Date: 2024-02-29 13:22:09
 * @LastEditors: LiuJingQian 1250366059@qq.com
 * @LastEditTime: 2024-04-11 20:43:55
 * @FilePath: \simt_front-1\src\actions\SIMT-SI\GuestEditor\KeyWord.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {axios_instance} from "@/utils/axios_instance";
import {CREATE_KEYWORD_EXITS, createGeKeyword, GET_KEYWORDS, GET_ALL_KEYWORDS} from "@/actions/SIMT-SI/GuestEditor/GEAction";

/**
 * @param {string} keyword
 * @returns
 */
export function createKeyword(keywords) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/guest-editors/keywords`;
            const res = await axios_instance.post(
                url,
                {
                    keywords: [keywords],
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            const{code,data} = res.data;
            if (code === 200) {
                dispatch(createGeKeyword('success', data));
            } else {
                if (res.data.message === 'the keyword already exists') {
                    dispatch({type: CREATE_KEYWORD_EXITS, data: true});
                } else {
                    dispatch({type: CREATE_KEYWORD_EXITS, data: false});
                }
                dispatch(createGeKeyword('error', null));
            }
        } catch {
            dispatch(createGeKeyword('error', null));
        }
    };
}

export function getKeywords(keywordValue) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/guest-editors/keywords`;
            const res = await axios_instance.get(url, {
                params: {
                    key: keywordValue,
                    pageNum: 1,
                    pageSize: 20,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            dispatch({type: GET_KEYWORDS, data: res.data.data});
        } catch {
            dispatch({type: GET_KEYWORDS, data: 'error'});
        }
    };
}


export function getAllKeywords(keywordValue) {
    return async dispatch => {
      try {
        const url = `/api/v1/si-service/guest-editors/keywords`;
        const res = await axios_instance.get(url, {
          params: {
            key: keywordValue,
            pageNum: 1,
            pageSize: 10000,
          },
          headers: {
            authorization: localStorage.getItem('authorization'),
          },
        });
        dispatch({ type: GET_ALL_KEYWORDS, data: res.data.data });
      } catch {
        dispatch({ type: GET_ALL_KEYWORDS, data: 'error' });
      }
    };
  }
  
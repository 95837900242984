import * as React from 'react';
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { scrollToTop } from '../../../utils/commonUtils';

function ScrollTop(props) {
  const { children, getWindow, sx, ...other } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  
  // window() may return null

  const windowTestGet = getWindow ? getWindow() : undefined;
  const windowToDeal = windowTestGet ? windowTestGet : window;


  const trigger = useScrollTrigger({
    target: windowToDeal,
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <Fade in={trigger} data-selenium-id='SIDetail_ScrollTop-Fade'>
      <Box
        onClick={()=>scrollToTop(windowToDeal)}
        role='presentation'
        sx={{
          position: 'fixed',
          bottom: 80,
          right: 28,
          zIndex: 1101,
          ...sx,
        }}
        data-selenium-id='SIDetail_ScrollTop-Box'
        {...other}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ScrollTop;

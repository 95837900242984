const createRow = (
  id,
  label,
  minWidth,
  maxWidth,
  isOrder = true,
  align = 'left',
  show = true,
  stick = false,
  stickPX = '0px'
) => {
  return {
    id,
    label,
    minWidth,
    maxWidth,
    isOrder,
    align,
    show,
    stick,
    stickPX,
  };
};

export const items = [//似乎已经弃用了
  createRow(
    'sipCode',
    'SIP Code',
    '180px',
    '220px',
    true,
    'left',
    true,
    true,
    '72px'
  ),
  createRow('sipStage', 'SIP Stage', '180px', '180px', true, 'left'),
  createRow('sipOrigin', 'SIP Origin', '180px', '340px', true, 'left'),
  createRow('sipTitle', 'SIP Title', '130px', '200px', true, 'left'),
  createRow(
    'preferredSubjectArea',
    'Preferred Subject Area',
    '243px',
    '243px',
    true,
    'left'
  ),

  createRow('leadGeName', 'Lead Ge', '243px', '341px', true, 'left'),
  createRow('coGe', 'CO-Ge', '180px', '180px', false, 'left'),
  createRow('authorList', 'Author List', '180px', '180px', true, 'left'),//?

  createRow('timeSensitive', 'Time Sensitive', '105px', '180px', true, 'left'),

  createRow('handlingCe', 'Handling CE', '180px', '220px', true, 'left'),
  createRow(
    'submissionDate',
    'Submission Date',
    '205px',
    '240px',
    true,
    'left'
  ),
  createRow(
    'latestDecisionDate',
    'Latest Decision Date',
    '160px',
    '180px',
    true,
    'left'
  ),
];

export const USIPitems = [
  createRow(
    'sipCode',
    'SIP Code',
    '180px',
    '220px',
    true,
    'left',
    true,
    true,
    '72px'
  ),
  createRow('sipStage', 'SIP Stage', '180px', '180px', true, 'left'),
  createRow('sipOrigin', 'SIP Origin', '147px', '260px', true, 'left'),
  createRow('sipChecklistStatus', 'SIP Checklist Status', '227px', '260px', true, 'left'),
  createRow('sipTitle', 'SIP Title', '260px', '260px', true, 'left'),
  //Keywords
  createRow('keywords', 'Keywords', '200px', '260px', true, 'left'),
  // createRow(
  //   'preferredSubjectArea',
  //   'Preferred Subject Group Area',
  //   '300px',
  //   '360px',
  //   true,
  //   'left'
  // ),
  //1st Choice Journal
  createRow('firstChoiceJournal', '1st Choice Journal', '218px', '260px', true, 'left'),
  //2nd Choice Journal
  createRow('secondChoiceJournal', '2nd Choice Journal', '225px', '260px', true, 'left'),
  //3rd Choice Journal
  createRow('thirdChoiceJournal', '3rd Choice Journal', '225px', '260px', true, 'left'),
  createRow('leadGeName', 'Lead Ge', '165px', '260px', true, 'left'),
  //Lead GE Email
  createRow('leadGeEmail', 'Lead GE Email', '180px', '260px', true, 'left'),
  createRow('coGe', 'Co-Ge', '120px', '180px', true, 'left'),
  //Co-GE Email
  createRow('coGeEmail', 'Co-GE Email', '180px', '260px', true, 'left'),
  // createRow('timeSensitive', 'Time Sensitive', '160px', '260px', true, 'left'),
  //Open Call for Papers
  createRow('openCallForPapers', 'Open Call for Papers', '200px', '300px', true, 'left'),
  //Potential Authors //CT4464 将Author List 改为 Potential Authors
  createRow('authorList', 'Potential Authors', '215px', '300px', true, 'left'),
  //Full Manuscript Submission Deadline
  createRow('fullManuscriptSubmissionDeadline', 'Full Manuscript Submission Deadline', '300px', '300px', true, 'left'),
  //Acceptance Deadline
  createRow('acceptanceDeadline', 'Acceptance Deadline', '200px', '300px', true, 'left'),
  //Expected Publication Date
  createRow('expectedPublicationDate', 'Expected Publication Date', '250px', '300px', true, 'left'),

  createRow('handlingCe', 'Commissioning editor', '167px', '220px', true, 'left'),
  createRow('operationsLead', 'Operations Lead', '167px', '220px', true, 'left'), // operations lead
  createRow('screener', 'Screener', '167px', '220px', true, 'left'), // screener

  createRow(
    'submissionDate',
    'Submission Date',
    '0px',
    '240px',
    true,
    'left'
  ),
  createRow(
    'latestDecisionDate',
    'Latest Decision Date',
    '180px',
    '180px',
    true,
    'left'
  ),
];
export const SSIPitems = [//SSIP
  createRow(
    'sipCode',
    'SIP Code',
    '180px',
    '220px',
    true,
    'left',
    true,
    true,
    '72px'
  ),
  createRow('sipSource', 'SIP SOURCE', '260px', '260px', true, 'left'),
  createRow('sipStage', 'SIP Stage', '180px', '180px', true, 'left'),
  createRow('sipOrigin', 'SIP Origin', '147px', '260px', true, 'left'),
  createRow('sipChecklistStatus', 'SIP Checklist Status', '227px', '260px', true, 'left'),
  createRow('sipTitle', 'SIP Title', '260px', '260px', true, 'left'),
  //Keywords
  createRow('keywords', 'Keywords', '200px', '260px', true, 'left'),
  // createRow(
  //   'preferredSubjectArea',
  //   'Preferred Subject Group Area',
  //   '300px',
  //   '360px',
  //   true,
  //   'left'
  // ),
  //1st Choice Journal
  // createRow('firstChoiceJournal', '1st Choice Journal', '218px', '260px', true, 'left'),
  //2nd Choice Journal
  // createRow('secondChoiceJournal', '2nd Choice Journal', '225px', '260px', true, 'left'),
  //3rd Choice Journal
  // createRow('thirdChoiceJournal', '3rd Choice Journal', '225px', '260px', true, 'left'),
  createRow('journalCode', 'Journal Code', '165px', '260px', true, 'left'),
  createRow('journalName', 'Journal Name', '165px', '260px', true, 'left'),

  createRow('leadGeName', 'Lead Ge', '165px', '260px', true, 'left'),
  //Lead GE Email
  createRow('leadGeEmail', 'Lead GE Email', '180px', '260px', true, 'left'),
  createRow('coGe', 'Co-Ge', '120px', '180px', true, 'left'),
  //Co-GE Email
  createRow('coGeEmail', 'Co-GE Email', '180px', '260px', true, 'left'),
  // createRow('timeSensitive', 'Time Sensitive', '160px', '260px', true, 'left'),
  //Open Call for Papers
  createRow('openCallForPapers', 'Open Call for Papers', '200px', '300px', true, 'left'),
  //Potential Authors //CT4464 将Author List 改为 Potential Authors
  createRow('authorList', 'Potential Authors', '215px', '300px', true, 'left'),
  //Full Manuscript Submission Deadline
  createRow('fullManuscriptSubmissionDeadline', 'Full Manuscript Submission Deadline', '300px', '300px', true, 'left'),
  //Acceptance Deadline
  createRow('acceptanceDeadline', 'Acceptance Deadline', '200px', '300px', true, 'left'),
  //Expected Publication Date
  createRow('expectedPublicationDate', 'Expected Publication Date', '250px', '300px', true, 'left'),

  createRow('handlingCe', 'Commissioning editor', '167px', '220px', true, 'left'),
  createRow('operationsLead', 'Operations Lead', '167px', '220px', true, 'left'), // operations lead
  createRow('screener', 'Screener', '167px', '220px', true, 'left'), // screener

  createRow(
    'creationDate',
    'Creation Date',
    '0px',
    '240px',
    true,
    'left'
  ),
  createRow(
    'submissionDate',
    'Submission Date',
    '0px',
    '240px',
    true,
    'left'
  ),
  createRow(
    'latestDecisionDate',
    'Latest Decision Date',
    '180px',
    '180px',
    true,
    'left'
  ),
];
import styles from './index.module.scss';
import {Autocomplete, AutocompleteValue, Chip, List, ListItem, Popper, SxProps, TextField, Theme} from "@mui/material";
import {AutocompleteRenderInputParams} from "@mui/material/Autocomplete/Autocomplete";
import {SyntheticEvent} from "react";
// @ts-ignore
import { Scrollbars } from 'react-custom-scrollbars';
import DropdownIcon from '../../assets/dropdown-icon-16_16.svg';
import DropdownClearIcon from '../../assets/dropdown-clear-16_16.svg';
import DropdownTagCancelIcon from '../../assets/dropdown-tag-cancel-icon-12_12.svg';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import * as React from "react";

const MIN_LENGTH = 2;
const getIsSearchAvailable = (inputValue?: string) => (inputValue || '').length >= MIN_LENGTH;

export interface SelectableInputBoxBaseProps<T> {
    options: T[];
    getOptionLabel: (option: T | null) => string;
    placeholder?: string;
    error?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    onInputChange?: (value: string) => void;
    onClear: () => void;
    noOptionsText?: string;
    filterOptions?: (options: T[], state: { inputValue: string }) => T[];
    sx?: SxProps<Theme>;
    dropDownSx?: SxProps<Theme>;
}

export interface SelectableInputBoxProps<T> extends SelectableInputBoxBaseProps<T>{
    value: T | null;
    onChange: (value: T | null) => void;
}

export interface MultiSelectableInputBoxProps<T> extends SelectableInputBoxBaseProps<T>{
    value: T[];
    onChange: (value: T[]) => void;
    maxSize?: number;
}

const DefaultListboxComponent: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>> = (props) => {
    const {children, ...other} = props;
    return (<Scrollbars
            className={styles['scrollbars']}
            autoHeight
            autoHeightMax={240}
            renderView={(p: any) => (
                <List {...p} {...other} />
            )}
        >
            {children}
        </Scrollbars>
    );
};

export function SelectableInputBox<T>(props: SelectableInputBoxProps<T>) {
    const {
        error,
        sx,
        dropDownSx,
        noOptionsText,
        onInputChange = (_) => {},
        onClear,
        getOptionLabel,
        filterOptions = (x, _) => x,
    } = props;

    const renderInput = (params: AutocompleteRenderInputParams) => (
        <TextField
            {...params}
            label=""
            placeholder={props.placeholder}
            className={styles['input-box']}
            inputProps={{
                ...params.inputProps,
                readonly: props.readonly ? 'readonly' : undefined,
            }}
        />
    );

    const handleChange = (event: SyntheticEvent, value: T | null) => {
        if (props.onChange) {
            props.onChange(value);
        }
    };

    return (
        <Autocomplete
            className={styles['selectable-input-box'] + ' ' + (error ? styles['error'] : '')}
            sx={sx}
            componentsProps={{
                paper: {
                    sx: {
                        ...dropDownSx,
                    }
                }
            }}
            options={props.options}
            value={props.value}
            isOptionEqualToValue={(option: T | null, value: T | null) => getOptionLabel(option) === getOptionLabel(value)}
            disabled={props.disabled}
            onChange={handleChange}
            onInputChange={(event, newInputValue, reason) => {
                if (event?.type === 'change') {
                    onInputChange(newInputValue);
                }
                if (!props.readonly && (event?.type === 'blur' || reason === 'clear')) {
                    onClear();
                }
            }}
            getOptionLabel={getOptionLabel}
            noOptionsText={noOptionsText}
            renderInput={renderInput}
            PopperComponent={(props) => (<Popper {...props} className={styles['popper']}/>)}
            ListboxComponent={DefaultListboxComponent}
            filterOptions={filterOptions}
            popupIcon={<DropdownIcon className={styles['dropdown-icon']}/>}
            clearIcon={<DropdownClearIcon className={styles['dropdown-close-icon']}/>}
        />
    );
}

export function MultiSelectableInputBox<T>(props: MultiSelectableInputBoxProps<T>) {
    const {
        value,
        error,
        sx,
        dropDownSx,
        noOptionsText,
        onInputChange = (_) => {},
        onClear,
        getOptionLabel,
        filterOptions = (x, _) => x,
        maxSize,
    } = props;

    const renderInput = (params: AutocompleteRenderInputParams) => (
        <TextField
            {...params}
            label=""
            placeholder={value.length === 0 ? props.placeholder : undefined}
            className={styles['input-box']}
            inputProps={{
                ...params.inputProps,
                readonly: props.readonly ? 'readonly' : undefined,
            }}
        />
    );

    const handleChange = (event: SyntheticEvent, value?: T[]) => {
        if (props.onChange) {
            if (maxSize && value && value.length > maxSize) {
                return;
            }
            props.onChange(value ?? []);
        }
    };

    return (
        <Autocomplete<T, true, false, false>
            multiple={true}
            className={styles['selectable-input-box'] + ' ' + styles['multiple'] + ' ' + (error ? styles['error'] : '')}
            sx={sx}
            componentsProps={{
                paper: {
                    sx: {
                        ...dropDownSx,
                    }
                }
            }}
            options={props.options}
            value={value}
            isOptionEqualToValue={(option: T | null, value: T | null) => getOptionLabel(option) === getOptionLabel(value)}
            disabled={props.disabled}
            onChange={handleChange}
            onInputChange={(event, newInputValue, reason) => {
                if (event?.type === 'change') {
                    onInputChange(newInputValue);
                }
                if (event?.type === 'blur' || reason === 'clear') {
                    onClear();
                }
            }}
            getOptionLabel={getOptionLabel}
            noOptionsText={noOptionsText}
            renderInput={renderInput}
            PopperComponent={(props) => (<Popper {...props} className={styles['popper']}/>)}
            ListboxComponent={DefaultListboxComponent}
            filterOptions={filterOptions}
            popupIcon={<DropdownIcon className={styles['dropdown-icon']}/>}
            clearIcon={<DropdownClearIcon className={styles['dropdown-close-icon']}/>}
            renderTags={(value, getTagProps) => (
                value.map((option, index) => (
                    <Chip label={getOptionLabel(option)}
                        {...getTagProps({ index })}
                    deleteIcon={<DropdownTagCancelIcon className={styles['dropdown-tag-cancel-icon']}/>}
                    />
                ))
            )}
        />
    );
}
import { Box } from "@mui/material";
import InfoItem from "./InfoItem";

export default function InfoList(props) {
  const {
    id = 'infoList',
    sx,//extend from parent node
    list = [],
  } = props;
  return (

    <Box
      sx={{
        display:'flex',
        columnGap:'56px',
        rowGap:'8px',
        width:'1092px',
        flexWrap:'wrap',
        ...sx}}>
      {list.map((item) => {
        return (
          <InfoItem
            itemTitle={item[0]}
            itemInfo={item[1]}
            itemType={item[2]}
          />
        );
      })}
    </Box>
  );
}
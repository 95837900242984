import { Box, Stack, ThemeProvider, createTheme } from '@mui/material';
import { StyledButton } from '../../../StyledComponents/styledButton';
import NumberIcon from '../../../CommonComponets/NumberIcon';

import React from 'react';
import { AllCentered } from '../../../Css';
import { getThemeColor } from '../utils';

export default function ThemeButton(props) {
  const { id, label, number, theme, currentTab='',...other } = props;
  const themeColor = getThemeColor(theme);
  const [active, setActive] = React.useState(false);
  const themeCss = createTheme({
    themeColor: themeColor,
    active: active,
  });
  React.useEffect(()=>{
    setActive(label === currentTab);
  },[currentTab]);
  return (
    <ThemeProvider theme={themeCss}>
      <StyledButton
        id={id}
        data-selenium-id={id}
        {...other}
      >
        <Stack direction='row' spacing={0.75} sx={AllCentered}>
          <Box>{label}</Box>
          <NumberIcon number={number} />
        </Stack>
      </StyledButton>
    </ThemeProvider>
  );
}

import {
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  tableRowClasses,
} from '@mui/material';
import { StyledTableCell } from '../../../components/DataTable';
import * as React from 'react';
import Box from '@mui/material/Box';
import NoDataMessage from '../../../componentsphase2/NoDataMessage';
import { useRef } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import ScrollBarY from '../../../componentsphase2/ScrollBar/ScrollBarY';
import { useEffect } from 'react';
import {
  contextHeightChanged,
  tableHeightChanged,
} from '../../../actions/CommonAction';
import { useDispatch } from 'react-redux';

/**
 * @description 显示数字的，颜色比显示文字的深
 */
export const TextTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#09214F',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '43px',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export const StyledBaseTableHeaderCell = styled(TableCell, {
  shouldForwardProp(propName) {
    return !['width', 'miniMode', 'sx'].includes(propName);
  },
})(({ width, miniMode = false, sx = {} }) => {
  const { width: sxWidth, ...otherSx } = sx;
  const styledWidth = width || sxWidth;

  return {
    [`&.${tableCellClasses.root}`]: {
      boxShadow: '0px -1px 0px 0px #CBD5E1 inset',
      fontSize: '12px',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      letterSpacing: '0.96px',
      ...otherSx,
      ...(miniMode &&
        styledWidth && {
        // width: styledWidth,
        minWidth: styledWidth,
        maxWidth: styledWidth,
      }),
      ...(!miniMode &&
        styledWidth && {
        minWidth: styledWidth,
      }),
    },
  };
});

const blockSx = {
  shouldForwardProp(propName) {
    return propName != 'sx';
  },
};

//绿色的tableCell
export const StyledTableCellGreen = styled(
  StyledBaseTableHeaderCell,
  blockSx
)(({ sx }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#45BCCA',
    color: '#FFF',
    textTransform: 'uppercase',
    ...sx,
  },
}));

export const StyledTableCellNormal = styled(
  StyledBaseTableHeaderCell,
  blockSx
)(({ sx }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F1F3F5',
    color: '#596A7C',
    textTransform: 'uppercase',
    ...sx,
  },
}));
//紫色的tableCell
export const StyledTableCellPurple = styled(
  StyledBaseTableHeaderCell,
  blockSx
)(({ sx }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#9098E2',
    color: '#FFF',
    textTransform: 'uppercase',
    ...sx,
  },
}));

export const StyledTableCellGray = styled(
  StyledBaseTableHeaderCell,
  blockSx
)(({ sx }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#98A7B6',
    color: '#FFF',
    textTransform: 'uppercase',
    ...sx,
  },
}));

//浅绿色
export const StyledTableCellDisGreen = styled(
  StyledBaseTableHeaderCell,
  blockSx
)(({ sx }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E7F6F8',
    color: '#596A7C',
    textTransform: 'capitalize',
    ...sx,
  },
}));

//浅紫色
export const StyledTableCellDisPurple = styled(
  StyledBaseTableHeaderCell,
  blockSx
)(({ sx }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E8E9F9',
    color: '#596A7C',
    textTransform: 'capitalize',
    ...sx,
  },
}));

export const StyledTableCellDisGray = styled(
  StyledBaseTableHeaderCell,
  blockSx
)(({ sx }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F1F3F5',
    color: '#596A7C',
    textTransform: 'capitalize',
    ...sx,
  },
}));

export const StyledBodyCellPurple = styled(TableCell)(({ sx }) => ({
  backgroundColor: 'rgba(232, 233, 249, 0.40)',
  color: '#262E35',
  fontSize: '16px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  ...sx,
}));

export const StyledBodyCellGreen = styled(TableCell)(({ sx }) => ({
  backgroundColor: '#F5FBFC',
  color: '#262E35',
  fontSize: '16px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  ...sx,
}));

export const LittlePurpleTableCell = styled(TableCell)(({ theme, sx }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F5F7FB',
    color: theme.palette.common.white,
    ...sx,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const LittleGreenTableCell = styled(TableCell)(({ theme, sx }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEFAE9',
    color: theme.palette.common.white,
    ...sx,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

/**
 * @author LiuXin
 * @date 2022/5/12
 * @description 表格的单双行变色，一行白色，一行浅紫色
 */
export const StyledTableRow = TableRow;

/**
 * @author LiuXin
 * @date 2022/5/12
 * @description 单双行变色，一部分为浅紫与白色交替，一部分为浅绿与白色交替
 */
export const TwoColorTableRow = styled(TableRow)(({ _theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F5F7FB',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#FFFFFF',
  },
  //去掉行内边框
  ' td, th': {
    border: 0,
  },
}));

export const TwoColorTableRowPurple = styled(TableRow)(({ _theme }) => ({
  backgroundColor: 'rgba(232, 233, 249, 0.40)',
  //去掉行内边框
  // ' td, th': {
  //   border: 0,
  // },
}));

export const TwoColorTableRowGreen = styled(TableRow)(({ _theme }) => ({
  backgroundColor: '#F5FBFC',
  //去掉行内边框
  // ' td, th': {
  //   border: 0,
  // },
}));

/**
 * @author LiuXin
 * @date 2022/5/27
 * @description 没有边框的tableRow
 */
export const NoBorderTableRow = styled(TableRow)(({ _theme }) => ({
  // ' td, th': {
  //   border: 0,
  // },
}));

/**
 * 根据行号获得紫色的cell，行号从0开始，偶数为紫色，奇数为白色
 * @param rowIndex  行号
 * @param content   cell中间需要显示的东西，可以是任意type
 * @param other     其他需要应用到cell上的style，可以是cell的属性也可以是sx
 * @returns {JSX.Element}
 */
export function getPurpleCell(rowIndex, content, other) {
  if (rowIndex % 2 === 0) {
    return (
      <StyledTableCell sx={{ background: '#F5F7FB' }} {...other}>
        {content}
      </StyledTableCell>
    );
  } else {
    return <StyledTableCell {...other}>{content}</StyledTableCell>;
  }
}

/**
 * 根据行号获得绿的cell，行号从0开始，偶数为紫色，奇数为白色
 * @param rowIndex  行号
 * @param content   cell中间需要显示的东西，可以是任意type
 * @param other     其他需要应用到cell上的style，可以是cell的属性也可以是sx
 * @returns {JSX.Element}
 */
export function getGreenCell(rowIndex, content, other) {
  if (rowIndex % 2 === 0) {
    return (
      <StyledTableCell sx={{ background: '#EEFAE9' }} {...other}>
        {content}
      </StyledTableCell>
    );
  } else {
    return <StyledTableCell {...other}>{content}</StyledTableCell>;
  }
}

/**
 * 根据isOA返回不同颜色的Cell， OA=绿色，其他=紫色
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function PurpleGreenCell(props) {
  const { isBasic, isOA, children, ...other } = props;
  if (isBasic) {
    return <StyledTableCellGray {...other}>{children}</StyledTableCellGray>;
  }
  if (isOA) {
    return <StyledTableCellGreen {...other}>{children}</StyledTableCellGreen>;
  } else {
    return <StyledTableCellPurple {...other}>{children}</StyledTableCellPurple>;
  }
}

export function PurpleGreenDisCell(props) {
  const { isBasic, isOA, children, ...other } = props;
  if (isBasic) {
    return (
      <StyledTableCellDisGray {...other}>{children}</StyledTableCellDisGray>
    );
  } else if (isOA) {
    return (
      <StyledTableCellDisGreen {...other}>{children}</StyledTableCellDisGreen>
    );
  } else {
    return (
      <StyledTableCellDisPurple {...other}>{children}</StyledTableCellDisPurple>
    );
  }
}

export function PurpleGreenRow(props) {
  const { isOA, children, ...other } = props;
  if (isOA) {
    return <TwoColorTableRowGreen {...other}>{children}</TwoColorTableRowGreen>;
  } else {
    return (
      <TwoColorTableRowPurple {...other}>{children}</TwoColorTableRowPurple>
    );
  }
}

export function PurpleGreenDataCell(props) {
  const { isOA, children, ...other } = props;
  if (isOA) {
    return <StyledBodyCellGreen {...other}>{children}</StyledBodyCellGreen>;
  } else {
    return <StyledBodyCellPurple {...other}>{children}</StyledBodyCellPurple>;
  }
}

export const TableGroupBox = styled(Box)(() => ({
  backgroundColor: '#fff',
  marginTop: '16px',
  marginBottom: '16px',
}));

export const TableBox = styled(Box)(() => ({
  paddingTop: '3px',
  paddingBottom: '35px',
  paddingLeft: '30px',
  paddingRight: '30px',
}));

export const TableBox2 = props => {
  const {
    sx,
    children,
    dataList = [],
    tableKey,
    footerHeight = 0,
    containerType = TableContainer,
    containerSelector = '.MuiTableContainer-root',
    viewTag = 'table',
    delay = 300,
    ...others
  } = props;
  const boxRef = useRef(null);
  const containerRef = useRef(null);
  // const tableRef = useRef(null);
  const scrollYRef = useRef(null);

  let foundTableContainer = false;

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const tableContainerElement =
        boxRef.current.querySelector(containerSelector);
      containerRef.current = tableContainerElement;
      let tableElement = tableContainerElement.querySelector(viewTag);

      if (tableElement) {
        setTimeout(() => {
          try{
            const tableContainerElement =
                boxRef.current?.querySelector(containerSelector);
            tableElement = tableContainerElement.querySelector(viewTag);
            dispatch(
                tableHeightChanged(
                    {
                      contextWidth: tableElement.offsetWidth,
                      contextHeight: tableElement.offsetHeight,
                      boxWidth: tableContainerElement.offsetWidth,
                      boxHeight: tableContainerElement.offsetHeight,
                    },
                    tableKey
                )
            );
          } catch (e) {
            console.warn("ReferenceError in component", "TableBox2", containerSelector);
          }
        }, delay);
      }
    } catch {
      console.warn("ReferenceError in component", "TableBox2", containerSelector);
    }
  }, [...dataList]);

  const isFirefox = navigator.userAgent.includes('Firefox');

  const containerScroll = event => {
    const delta = event.deltaY || -event.detail;
    // Update scrollTop based on delta
    const container = boxRef.current;
    container.children[0].scrollTop += delta;
    // debugger;
    scrollYRef.current?.scrollAsContext();
  };

  return (
    <Box
      ref={boxRef}
      sx={{
        paddingTop: '3px',
        paddingBottom: '35px',
        paddingLeft: '30px',
        paddingRight: '30px',
        position: 'relative',
        [`& ${containerSelector}`]: {
          overflowY: 'hidden',
          '&::-webkit-scrollbar:vertical': {
            width: 0,
          },
          '&::-webkit-scrollbar-thumb:vertical': {
            backgroundColor: 'transparent',
          },
        },
        ...sx,
      }}
      {...others}
    >
      {React.Children.map(children, child => {
        if (
          !foundTableContainer &&
          React.isValidElement(child) &&
          child.type === containerType
        ) {
          foundTableContainer = true;
          return React.cloneElement(child, {
            // ref: containerRef,
            // onScroll: containerScroll,
            onWheel: containerScroll,
          });
        }
        return child;
      })}
      {!isFirefox && (
        <ScrollBarY
          ref={scrollYRef}
          contextHolderRef={containerRef}
          tableKey={tableKey}
          sx={{
            top: '9px',
            bottom: `${41 + footerHeight}px`,
            right: '33px',
          }}
        />
      )}
    </Box>
  );
};

export const TableLabel = styled(Box)(() => ({
  paddingTop: '22px',
  paddingBottom: '25px',
  paddingLeft: '30px',
  color: '#596A7C',
  fontFamily: 'Open Sans',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '125%',
}));

export const ReportNoData = ({
  id,
  top = '10px',
  message = 'There are no results',
  ...others
}) => (
  <NoDataMessage
    id={id}
    data-selenium-id={id}
    MarginTop={top}
    message={message}
    {...others}
  />
);

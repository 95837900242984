const filters = {
  // normal
  fullName: {
    apiPath: `/api/v1/si-service/guest-editors/filter-metadata/${'get-ge-name-list'}`,
    firstParamName: 'searchKey',
  },
  leadGeFullName: {
    apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
    firstParamName: 'name',
    action:'getGeListFuzzily',
  },
  coGeFullName: {
    apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
    firstParamName: 'name',
    action:'getGeListFuzzily',
  },
  gender: {
    apiPath: '',
    firstParamName: '',
  },
  primaryEmail: {
    apiPath: `/api/v1/si-service/guest-editors/filter-metadata/${'get-ge-email-list'}`,
    firstParamName: 'searchKey',
  },
  institute: {
    apiPath: `/api/v1/si-service/guest-editors/filter-metadata/${'get-ge-institute-list'}`,
    firstParamName: 'searchKey',
  },
  country: {
    apiPath: `/api/v1/si-service/guest-editors/filter-metadata/${'get-ge-country-list'}`,
    firstParamName: 'searchKey',
  },
  associateJournal: {
    apiPath: '/api/v1/si-service/journals/managed/codes-and-names',
    firstParamName: 'journalName',
  },
  journal: {
    apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
    firstParamName: 'name',
    action:'getJournalNameAndCode',
  },
  jpm: { //keep it because used in report page
    apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
    firstParamName: 'name',
    action:'getJpm',
  },
  pdPublisher: { //add for the journal role change, it will used in the filter
    apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
    firstParamName: 'name',
    action:'getPDPublisher',
  },
  ppPublisher: {
    apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
    firstParamName: 'name',
    action:'getPPPublisher',
  },
  subjectGroup: {
    apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
    firstParamName: 'name',
    action:'getSubjectGroup',
  },
  stage: {
    apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
    firstParamName: 'name',
    action:'getStage',
  },
  businessDivision: {
    apiPath: '/reports/pipeline/filter/getBusinessDivision',
    firstParamName: 'name',
  },
  handlingCe: {
    // apiPath: "/siFilter/getHandlingCeListFuzzily",
    apiPath: '/api/v1/user-service/users',
    firstParamName: 'fullName',
  },

  // system config
  group: {
    type: 'config',
    typeValue: 'Group/Title',
    apiPath: `/api/v1/user-service/configs`,
    firstParamName: 'value',
    secParamName: 'type',
  },
  catGroup: {
    // 和group实际上是同一个，以后需要合并掉
    type: 'config',
    typeValue: 'Group/Title',
    apiPath: `/api/v1/user-service/configs`,
    firstParamName: 'value',
    secParamName: 'type',
  },
  flags: {
    type: 'config',
    typeValue: '%Flags%',//这里加%是因为要模糊搜索。虽然按理来说这个%应该是后端去加
    apiPath: `/api/v1/user-service/configs`,
    firstParamName: 'value',
    secParamName: 'type',
    fuzzy_type:true,
  },
  siCode: {
    apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
    firstParamName: 'name',
    action:'getSiCodeFuzzily',
  },
  journalRevenueModel: {
    apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
    firstParamName: 'name',
    action:'getJournalRevenueModel',
  },
  siTitle:{
    apiPath: '/api/v1/si-service/special-issues',
    firstParamName:'title'
  },
  geCode:{
    apiPath: `/api/v1/si-service/guest-editors/filter-metadata/${'get-ge-code-list'}`,
    firstParamName:'searchKey'
  }
};

export function getAPIPath(which) {
  return filters[which].apiPath;
}

export function getAPIAction(which) {
  return filters[which].action;
}

/**
 * 给load options方法构造请求参数
 * 主要区分旧的数据和新的config数据
 * @param which
 * @param searchContent
 * @param page
 * @param pageSize
 * @returns {{}|{type: *, value}|{pageSize, pageNum}}
 */
export function buildParamsForFilter(which, searchContent, page, pageSize) {
  if (filters[which].type === 'config') {
    return {
      type: filters[which].typeValue,
      value: searchContent,
      fuzzyType: filters[which].fuzzy_type
    };
  }else {
    if(which === 'title'){
      return {
        [filters[which].firstParamName]: searchContent,
      };
    } else if (which === 'handlingCe') {
      return {
          [filters[which].firstParamName]: searchContent,
          fuzzy: true,
          activeTierSi: true,
          pageNum: page,
          pageSize: pageSize,
      };
    }
    return {
      [filters[which].firstParamName]: searchContent,
      pageNum: page,
      pageSize: pageSize,
      action:getAPIAction(which),
    };
  }
}

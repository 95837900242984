import { H4Font, SmallBoldFont } from '@/components/FontStyle';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';

import DeleteIcon from './SVGComponents/DeleteIcon.svg';
import ItemBlock from '@/componentsphase2/Itemblock';
import ItemBlockInstance from './Components/ItemBlock';
import RadioBox from './Components/RadioBox';
import React from 'react';
import CancelBtn from './Components/CancelBtn';
import ExportBtn from './Components/ExportBtn';
import { FormattedMessage } from 'react-intl';
import DrawerCloseButton from '@/componentsphase2/DrawerCloseButton';
import {useFilterCounterFyHook} from '@/pages/Report/utils/useFilterCounterFyHook';

export default function ExportAllDialog(props) {
  const { open, handleClose, filterData, handleCSV, handleExcel } = props;
  const [dataTypeChecked, setDataTypeChecked] = React.useState(false);
  const [formatChecked, setFormatChecked] = React.useState(null);
  const filterCounter =  useFilterCounterFyHook(filterData);

  const handleExport = () => {
    //To Do
    let dataType = dataTypeChecked ? 'filter' : 'allData';
    if (formatChecked) {
      handleCSV(dataType);
    } else {
      handleExcel(dataType);
    }
    handleClose();
  };

  React.useEffect(() => {
    if (open) {
      setDataTypeChecked(filterCounter > 0);
      setFormatChecked(null);
    }
  }, [open]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(17, 61,149, 0.5)',
          backdropFilter: 'blur(4px)',
        },
        '& .MuiPaper-root': {
          boxShadow: 'none',
          width: '600px',
        },
        '& .MuiDialogTitle-root': {
          display: 'flex',
          flexDirection: 'row',
          padding: '24px 32px 17px',
          height: '65px',
          ...H4Font,
          color: '#262E35',
        },
        '& .MuiDialogContent-root': {
          padding: '0px 32px 14px 37px',
        },
        '& .MuiDialogActions-root': {
          padding: '12px 32px 20px',
          gap: '9px',
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ width: 'calc(100% - 24px)' }}>
          <FormattedMessage id='common.Export' />
        </Box>
        {/* <Box sx={{ display: 'flex' }} onClick={handleClose}>
          <DeleteIcon />
        </Box> */}
        <DrawerCloseButton onClick={handleClose}/>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1} direction='column'>
          <ItemBlockInstance id='dataType' title='Data Type'>
            <Stack spacing={3} direction='row'>
              <RadioBox
                checked={!dataTypeChecked}
                disabled={false}
                label='All data'
                setChecked={setDataTypeChecked}
                mode={0}
              />
              <RadioBox
                checked={dataTypeChecked}
                disabled={filterCounter === 0}
                label='Filtered data'
                setChecked={setDataTypeChecked}
                mode={1}
              />
            </Stack>
          </ItemBlockInstance>
          <ItemBlockInstance id='format' title='Format'>
            <Stack spacing={3} direction='row'>
              <RadioBox
                checked={formatChecked === null ? null : !formatChecked}
                disabled={false}
                label='Excel'
                setChecked={setFormatChecked}
                mode={0}
              />
              <RadioBox
                checked={formatChecked === null ? null : formatChecked}
                disabled={false}
                label='CSV'
                setChecked={setFormatChecked}
                mode={1}
              />
            </Stack>
          </ItemBlockInstance>
        </Stack>
      </DialogContent>
      <DialogActions>
        <CancelBtn handleClick={handleClose} />
        <ExportBtn
          disable={formatChecked === null}
          handleClick={handleExport}
        />
      </DialogActions>
    </Dialog>
  );
}

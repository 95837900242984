import { Tooltip, IconButton, Stack } from '@mui/material';
import React from 'react';
import DownloadIcon from '@/assets/downloadIcon.svg';
import DownloadIcon2 from '@/assets/donwloadIcon2.svg';
import { GetLabel } from '@/components/FileUploader/LabelFile';
import {
  ChooseIconByType,
  formatFileSize,
} from '@/pages/GEPage/GEDetailPage/FlagBlock';
import { useEffect, useState, useImperativeHandle, useRef } from 'react';
import { CustomTooltip } from '@/components/CustomTooltip';
import { styled } from '@mui/system';
const CustomTooltipSmallArrow = styled(Tooltip)({
  // width:'180px',
  height: '10px',
  textAlign: 'center',
  fontWeight: '400px',
  fontSize: '14px',
  lineHeight: '20px',
  paddingBottom: '33px',
  top: '12px',
  '& .MuiTooltip-arrow': {
    width: '12px!important', // 修改箭头的宽度
    height: '12px!important', // 修改箭头的高度
  },
});
export const ShowFile = ({
  fileName,
  DownLoadAll,
  DownloadSingleFile,
  DownLoadAllTip,
  sx={}
}) => {
  if (
    DownLoadAllTip === null ||
    DownLoadAllTip === '' ||
    DownLoadAllTip === undefined
  )
    DownLoadAllTip = 'Download all evidence';
  return (
    <Stack position='relative'>
      {fileName !== undefined && fileName.length > 0 && DownLoadAll ? (
        <div
          style={{
            right: -6,
            top: 10,
            marginBottom: 10,
            zIndex: 499,
            paddingLeft: '0px!important',
            marginLeft: '0px!important',
          }}
        >
          <SimpleIconButtonWithTootip
            sx={{ marginTop: '-75px' }}
            tooltipWord={DownLoadAllTip}
            DefaultIcon={DownloadIcon2}
            hoverIcon={DownloadIcon}
            ActiveIcon={DownloadIcon}
            onClickWork={DownLoadAll}
          ></SimpleIconButtonWithTootip>
        </div>
      ) : null}
      {fileName !== undefined && fileName.length > 0
        ? fileName.map((file, index) => (
            <GetLabel
              title='DownLoadAllTip'
              aria-label='DownLoadAllTip'
              arrow
              position='top'
              sx={{ marginTop: index === 0 ? '0px' : '8px',...sx }}
              FileIcon={ChooseIconByType(file.split('.')[-1])}
              del={DownloadSingleFile}
              fileName={file?.split('_')[2]?.split('.').slice(0,-1).join('.')}
              fileSize={formatFileSize(file.split('_')[0])}
              fileMIMEType={file.split('.')[1]}
              isDownLoad={true}
              titleMaxWidth={213}
            />
          ))
        : null}
    </Stack>
  );
};

export function SimpleIconButtonWithTootip(props) {
  const {
    DefaultIcon,
    HoverIcon = DefaultIcon,
    ActiveIcon = DefaultIcon,
    DisabledIcon = DefaultIcon,
    isDisabled = false,
    id,
    onClickWork,
    tooltipWord = '',
    ...others
  } = props;
  const [useId] = useState(Math.random());
  const sid = id || 'IconButton-' + useId;
  const [isHover, setIsHover] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const handleEnter = () => {
    setIsHover(true);
  };
  const handleLeave = () => {
    setIsHover(false);
    setIsActive(false);
  };
  const handleDown = () => {
    setIsHover(true);
    setIsActive(true);
  };
  const handleUp = () => {
    setIsActive(false);
  };
  React.useEffect(() => {
    //AddListenerh
    setTimeout(() => {
      try {
        const btnElement = document.getElementById(sid);
        btnElement.addEventListener('mouseenter', handleEnter);
        btnElement.addEventListener('mouseleave', handleLeave);
        btnElement.addEventListener('mousedown', handleDown);
        btnElement.addEventListener('mouseup', handleUp);
      } catch (e) {}
    }, 1000);
    return () => {
      //Remove Listener
      try {
        const btnElement = document.getElementById(sid);
        btnElement.removeEventListener('mouseenter', handleEnter);
        btnElement.removeEventListener('mouseleave', handleLeave);
        btnElement.removeEventListener('mousedown', handleDown);
        btnElement.removeEventListener('mouseup', handleUp);
      } catch (e) {}
    };
  });
  return (
    <CustomTooltipSmallArrow title={tooltipWord} arrow placement='top'>
      <IconButton
        id={sid}
        disabled={isDisabled}
        disableFocusRipple
        disableRipple
        {...others}
        onClick={onClickWork}
      >
        {isDisabled ? (
          <DisabledIcon />
        ) : isActive ? (
          <ActiveIcon />
        ) : isHover ? (
          <HoverIcon />
        ) : (
          <DefaultIcon />
        )}
      </IconButton>
    </CustomTooltipSmallArrow>
  );
}

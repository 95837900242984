import React, {
  useState,
  createContext,
  useLayoutEffect,
} from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import FirstTimeLogin from '../pages/Login/FirstTimeLogin';
import UserLock from '../pages/Login/UserLock';
import AuthPage from '../pages/AuthPage';
import SuperAdminManage from '../pages/SuperAdminManage';
import UserManagement from '../pages/SystemAdmin/UserManagement';
import ActiveEmailPage from '../pages/Login/ActiveEmailPage';
import CreateRole from '../pages/SystemAdmin/RoleManagement/ActionsToRolePage/CreateRolePage';
import UpdateRole from '../pages/SystemAdmin/RoleManagement/ActionsToRolePage/UpdateRolePage';
import MyAccount from '../pages/AuthPage/MyAccount';
import HomePage from '../pages/AuthPage/HomePage';
import RoleManagement from '../pages/SystemAdmin/RoleManagement';
import SystemLog from '../pages/SystemAdmin/SystemLog';
import Overview from '../pages/SIPage/Overview';
import SIDetailPage from '../pages/SIPage/SIDetailPage';
import SIChangeList from '../pages/SIPage/ChangeList';
import ResetEmailSentPage from '../pages/Login/ResetEmailSentPage';
import GEOverview from '../pages/GEPage/Overview';
import GEDetailPage from '../pages/GEPage/GEDetailPage';
import CommonMainFrame from '../components/CommonMainFrame';
import { ReportPage } from '@/pages/Report/ReportOverview';
import CRContactDetailPage from '../pages/CRContact/CRContactDetailPage';
import CROverviewPage from '../pages/CRContact/Overview';
import AnnualTarget from '../pages/AnnualTarget/Overview';
import CATJournalOverview from '../pages/CATJournal/Overview/CATJournalOverview';
import SystemConfiguration from '../pages/SystemAdmin/SystemConfiguration/SystemConfiguration';
import EmailTemplatesMain from '../pages/EmailTemplates/EmailTemplatesMain';
import ReportJobs from '../pages/SystemAdmin/ReportJobs';
import ExportData from '../pages/SystemAdmin/ExportData';
import UpdateSelectStage from '../pages/SIPage/CreateAndUpdateSI/Update/SelectStage';
import CreateSelectStage from '../pages/SIPage/CreateAndUpdateSI/Create/SelectStage';
import ShelvedPage from '../pages/SIPage/CreateAndUpdateSI/Update/UpdatePages/ShelvedPage/MainPage';
import CreatePublicationPage from '../pages/SIPage/CreateAndUpdateSI/Create/CreatePages/ProductionPage/MainPage';
import CreateAcquiredPage from '../pages/SIPage/CreateAndUpdateSI/Create/CreatePages/AcquiredPage/MainPage';
import CreateIdeaAndProposalPage from '../pages/SIPage/CreateAndUpdateSI/Create/CreatePages/IdeaAndProposalPage/MainPage';
import CreatePaperComissionPage from '../pages/SIPage/CreateAndUpdateSI/Create/CreatePages/PaperComissionPage/MainPage';
import CreateSubmissionPage from '../pages/SIPage/CreateAndUpdateSI/Create/CreatePages/SubmissionAndReview/MainPage';
import UpdateMainPage from '../pages/SIPage/CreateAndUpdateSI/MainPage.js/UpdateMainPage';
import SendEmailPage from '../pages/Email/SendEmail';
import SIPDetailPage from '../pages/SIPPage/SIPDetailPage';
import { useDispatch } from 'react-redux';
import SIPOverview from '../pages/SIPPage/Overview';
import SIPFlagGEVerification from '../pages/SIPPage/FlaggedGEVerificationPage';
import RevisePage from '../pages/SIPPage/RevisePage';
import EbmReviewPage from '@/pages/SIPPage/RevisePage/EbmReviewPage';
import AcceptPage from '../pages/SIPPage/AcceptPage';
import SIPTransformToSI from '@/pages/SIPPage/TransformToSI';
import NewBasicLogin from '../pages/Login/NewLogin/BasicLogin';
import ForgotPasswordPage from '@/pages/Login/NewLogin/ForgotPassword';
import NotAuthorizedPage from '@/pages/Login/NewLogin/NotAuthorized';
import EmailSendPage from '@/pages/Login/NewLogin/EmailSend';
import CreateNewPasswordPage from '@/pages/Login/NewLogin/CreateNewPassword';
import ResetSuccessPage from '@/pages/Login/NewLogin/ResetSuccess';
import ChangePassword from '@/pages/AuthPage/NewUpdatePassword';
import LinkExpirePage from '@/pages/Login/NewLogin/LinkExpire';
import LinkPage from '@/pages/Login/NewLogin/LinkPage';
import CMPage from '@/pages/ChinaMarketing';
import CATJournalDetail from '@/pages/CATJournal/Detail';
import CustomEmailTemplatesMain from '../pages/EmailTemplates/CustomEmailTemplatesMain';
import { clearReportFilter } from '@/actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import {
  clearArticleFilter,
  clearReportFilter as clearReportFilterAcquireOverview,
} from '../actions/SIMT-Report/ReportFilter/ReportFilterAction';
import { fyFilterClear } from '@/actions/SIMT-Report/Report/ReportAction';
import { fyFilterClear as fyFilterClearArticle } from '../actions/SIMT-Report/ReportArticle/ReportArticleAction';
// import SIPCheckListPage from '@/pages/SIPPage/SIPCheckListPage';
import {SIPCheckListLayoutPage as SIPCheckListPage} from '@/pages/SIPPage/SIPCheckListPage/configable/SIPCheckListPage';
import AIScreeningReportPage from '@/pages/SIPPage/AIScreeningReportPage';
import GEMergePage from '@/pages/GEPage/GEMergePage';
import CreateSIPPage from '@/pages/SIPPage/CreateSIP';
import {PERMISSIONS} from "@/constant/permission";
// import {FormTest as TestPage} from "@/components/Form/form.test";
// import TestPage from "@/pages/SIPForm/components/SelectableInputBox/TestPage";
// import TestPage from "@/pages/SIPForm/subpages/Proposal/TestPage";
// import TestPage from "@/pages/SIPForm/components/DateInput/TestPage";
import SIPForm from "@/pages/SIPForm";
import SIPFormThankYou from "@/pages/SIPForm/ThankYouPage";


function Authed(props) {
  const { authList } = props;

  const isLogin =
    localStorage.getItem('authorization') &&
    localStorage.getItem('authorization').length > 4;
  let userPermissionArray = localStorage.getItem('userPermissions')?.split(',');
  if (!userPermissionArray) {
    localStorage.removeItem('authorization');
    localStorage.removeItem('hasFooterLink');
    return <Navigate to='/simt/login' />;
  }
  let authed =
    authList === undefined ||
    authList.some(item => userPermissionArray.includes(item));
  let { element } = props;

  if (authed) {
    if (element === undefined) {
      element = <Outlet />;
    }
    return element;
  } else if (isLogin) {
    return <Navigate to='/simt/notAuthorized' />;
  } else {
    return <Navigate to='/simt/login' />;
  }
}

function Logined(props) {
  const isLogin = !!(
    localStorage.getItem('authorization') &&
    localStorage.getItem('authorization').length > 4
  );
  const { Element } = props;

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    // console.info('Start clear ...');
    dispatch(fyFilterClear());
    dispatch(fyFilterClearArticle());
    dispatch(clearReportFilter());
    dispatch(clearReportFilterAcquireOverview());
    dispatch(clearArticleFilter());

    // MyLocalStorage.removeAllItemStartsWith('ReportReducer-');
    // MyLocalStorage.removeAllItemStartsWith('FilterReducer-');
    // MyLocalStorage.removeAllItemStartsWith('ReportFilterReducer-');
    // console.info('Finish clear ...');
  }, []);

  if (isLogin) {
    return <Element />;
  } else {
    return <Navigate to='/simt/login' />;
  }
}
export const isCNmarketingContext = createContext(false);
export const marketing = 'marketing';
export const siOverview = 'si/overview';
export const siDetail = 'si/detail';

export default function newRoute() {
  const isLogin = !!(
    localStorage.getItem('authorization') &&
    localStorage.getItem('authorization').length > 4
  );
  return (
    <Routes>
      <Route path='*' element={<Navigate replace to={isLogin ? '/simt/notAuthorized' : '/simt/login'} />} />
      <Route path='/' element={<Navigate replace to={isLogin ? '/simt/auth/index' : '/simt/login'} />} />
      <Route path='/sip-web-form' element={<SIPForm />} />
      <Route path='/sip-web-form/thank-you' element={<SIPFormThankYou />} />
      {/*<Route path='/test' element={<TestPage />} />*/}
      <Route path='/simt/'>
        {/* Login Related */}
        <Route path='login' element={<NewBasicLogin />} />
        <Route path='forgotPassword' element={<ForgotPasswordPage />} />
        <Route path='notAuthorized' element={<NotAuthorizedPage />} />
        <Route path='emailSend' element={<EmailSendPage />} />
        <Route path='linkExpire' element={<LinkExpirePage />} />
        {/* also match forgetReset */}
        <Route path='forgetReset' element={<LinkPage />} />
        <Route path='createNewPassword' element={<CreateNewPasswordPage />} />
        <Route path='resetSuccess' element={<ResetSuccessPage />} />
        <Route path='resetEmailSent' element={<ResetEmailSentPage />} />
        <Route path='SIChangeList' element={<SIChangeList />} />
        <Route path='overview' element={<Overview />} />
        <Route path='userlock' element={<UserLock />} />
        <Route path='firstReset' element={<FirstTimeLogin />} />
        {/* <Route path='forgetReset' element={<ForgetPassword />} /> */}
        <Route path='activeEmail' element={<ActiveEmailPage />} />
        <Route path='auth' element={<Logined Element={AuthPage} />}>
          <Route path='index' element={<HomePage />} />
          <Route path='userInfo' element={<MyAccount />} />
          <Route path='updatePassword' element={<ChangePassword />} />
          <Route path='myAccount' element={<MyAccount />} />
          <Route
            path='superAdmin'
            element={
              <Authed
                authList={[ PERMISSIONS.SUPER_ADMIN_WRITE]}
                element={<SuperAdminManage title='System Admin Users' />}
              />
            }
          />
          <Route
            path='system'
            element={
              <Authed
                authList={[PERMISSIONS.ADMIN_WRITE]}
                element={<CommonMainFrame />}
              />
            }
          >
            <Route path='user' element={<UserManagement />} />
            <Route path='role' element={<RoleManagement />} />
            <Route path='role/new' element={<CreateRole />} />
            <Route path='role/update/:RoleName' element={<UpdateRole />} />
            <Route
              path='role/detail/:RoleName'
              element={<UpdateRole readonly={true} />}
            />
            <Route path='log' element={<SystemLog />} />
            <Route path='config' element={<SystemConfiguration />} />
            <Route path='email' element={<EmailTemplatesMain />} />
            <Route path='target' element={<AnnualTarget />} />
            <Route path='jobs' element={<ReportJobs />} />
            <Route path='export' element={<ExportData />} />
          </Route>
          <Route
            path='email'
            element={
              <Authed authList={[PERMISSIONS.EMAIL_SI, PERMISSIONS.EMAIL_SIP]} element={<CommonMainFrame />} />
            }
          >
            <Route path='email' element={<CustomEmailTemplatesMain />} />
          </Route>
          <Route path='siRelated' element={<CommonMainFrame />}>
            <Route
              path='si/overview'
              element={
                <Authed
                  authList={[PERMISSIONS.SI_READ, PERMISSIONS.SI_READ_LIMITED, PERMISSIONS.SI_WRITE, PERMISSIONS.CHINA_MARKETING_READ]}
                  element={<Overview />}
                />
              }
            />
            <Route
              path='si/detail/:siCode'
              element={
                <Authed
                  authList={[PERMISSIONS.SI_READ, PERMISSIONS.SI_READ_LIMITED, PERMISSIONS.SI_WRITE, PERMISSIONS.CHINA_MARKETING_READ]}
                  element={<SIDetailPage />}
                />
              }
            />

            <Route
              path='ge/overview'
              element={
                <Authed
                  authList={[PERMISSIONS.GE_READ, PERMISSIONS.GE_WRITE, PERMISSIONS.GE_EXPORT]}
                  element={<GEOverview />}
                />
              }
            />
            <Route
              path='ge/detail/:geId'
              element={
                <Authed
                  authList={[PERMISSIONS.GE_READ, PERMISSIONS.GE_WRITE, PERMISSIONS.GE_EXPORT]}
                  element={<GEDetailPage />}
                />
              }
            />
          </Route>
          <Route path='sipRelated' element={<CommonMainFrame />}>
          <Route
              path='sip/overview/:sipOrigin'
              element={
                <Authed
                  authList={[
                      PERMISSIONS.SIP_READ, PERMISSIONS.SIP_WRITE_JPA, PERMISSIONS.SIP_WRITE_HANDLING_CE
                  ]}
                  element={<SIPOverview />}
                />
              }
            />
            <Route
              path='sip/overview'
              element={
                <Navigate to='./solicited' />
                // 兼容原地址，防止404
              }
            />
            {/* report 在当前阶段是 disable的  */}
            <Route 
              path="geVerification/flagGEVerification" 
              element={
                <Authed
                  authList={[
                      PERMISSIONS.SIP_WRITE_OPS
                  ]}
                  element={<SIPFlagGEVerification />}
                />
              }
            />
            <Route
              path='sip/detail/:sipCode'
              element={
                <Authed
                  authList={[
                      PERMISSIONS.SIP_READ, PERMISSIONS.SIP_WRITE_JPA, PERMISSIONS.SIP_WRITE_HANDLING_CE
                  ]}
                  element={<SIPDetailPage />}
                />
              }
            />
            <Route
              path='sip/createSIPPage'
              element={
                <Authed
                  authList={[
                      PERMISSIONS.SIP_WRITE_HANDLING_CE
                  ]}
                  element={<CreateSIPPage />}
                />
              }
            />
            <Route
              path='sip/aiScreeningReport/:sipCode'
              element={
                <Authed
                  authList={[
                      PERMISSIONS.SIP_READ
                  ]}
                  element={<AIScreeningReportPage />}
                />
              }
            />
            <Route
              path='sip/checklist/:sipCode/:sipStage'
              element={
                <SIPCheckListPage/>
              }
            />
            <Route
              path='sip/checklist/:sipCode'
              element={
                <Navigate to='./submitted' />
              }
            />
          </Route>
          <Route
            path='reportRelated'
            element={
              <Authed
                authList={[PERMISSIONS.ADMIN_WRITE, PERMISSIONS.REPORT_READ_GENERAL]}
                element={<CommonMainFrame />}
              />
            }
          >
            <Route path='report/:parentTab' element={<ReportPage />} />
            <Route
              path='report/:parentTab/:childTab'
              element={<ReportPage />}
            />
          </Route>
          <Route
            path={marketing}
            element={
              <Authed
                authList={[PERMISSIONS.CHINA_MARKETING_WRITE]}
                element={<CommonMainFrame />}
              />
            }
          >
            <Route
              path={siOverview}
              element={
                <isCNmarketingContext.Provider value={true}>
                  <Overview />
                </isCNmarketingContext.Provider>
              }
            />
            <Route
              path={`${siDetail}/:siCode`}
              element={<CMPage siCode='1' />}
            />
          </Route>
          <Route
            path='journalRelated'
            element={
              <Authed
                authList={[PERMISSIONS.ADMIN_WRITE, PERMISSIONS.JOURNAL_CR_CONTACT, PERMISSIONS.JOURNAL_CAT_READ]}
                element={<CommonMainFrame />}
              />
            }
          >
            <Route path='prp/overview' element={<CROverviewPage />} />
            <Route
              path='prp/detail/:journalcode'
              element={<CRContactDetailPage />}
            />
            <Route path='cat' element={<Authed
              authList={[PERMISSIONS.JOURNAL_CAT_READ]}
              element={<CommonMainFrame />} />}>
              <Route path='overview' element={<CATJournalOverview />} />
              <Route
                path='detail/:journalcode'
                element={<CATJournalDetail />}
              />
            </Route>

          </Route>
        </Route>

        <Route
          path='auth/siRelated'
          element={
            <Authed
              authList={[
                  PERMISSIONS.ADMIN_WRITE,
                  PERMISSIONS.SI_READ,
                  PERMISSIONS.SI_READ_LIMITED,
                  PERMISSIONS.SI_WRITE
                ]}
              element={<CommonMainFrame />}
            />
            // <Outlet />
          }
        >
          <Route
            path='si/create'
            element={<CreateSelectStage />}
          />
          <Route
            path='si/create/IdeaAndProposal'
            element={<CreateIdeaAndProposalPage />}
          />
          <Route
            path='si/create/Acquired'
            element={<CreateAcquiredPage />}
          />
          <Route
            path='si/create/PaperCommission'
            element={<CreatePaperComissionPage />}
          />
          <Route
            path='si/create/SubmissionAndReview'
            element={<CreateSubmissionPage />}
          />
          <Route
            path='si/create/Production'
            element={<CreatePublicationPage />}
          />
          {/*
        这里的siCode 是在其他页面
        nevigate(`/simt/auth/siRelated/si/detail/${siCode}/update/Acquired`,{ state: SIInfoResult }) 传进来参数
        useLocation 就可以使用这个参数了
        */}
          <Route
            path='si/detail/:siCode/update'
            element={<UpdateSelectStage />}
          />
          <Route
            path='si/detail/:siCode/update/Acquired'
            element={<UpdateMainPage />}
          />
          <Route
            path='si/detail/:siCode/update/PaperCommission'
            element={<UpdateMainPage />}
          />
          <Route
            path='si/detail/:siCode/update/SubmissionAndReview'
            element={<UpdateMainPage />}
          />
          <Route
            path='si/detail/:siCode/update/Production'
            element={<UpdateMainPage />}
          />
          <Route
            path='si/detail/:siCode/update/Published'
            element={<UpdateMainPage />}
          />
          <Route
            path='si/detail/:siCode/update/Shelved'
            element={<ShelvedPage />}
          />

          <Route
            path='emailSend'
            element={
              <Authed
                authList={[PERMISSIONS.SI_WRITE]}
                element={<SendEmailPage />}
              />
            } />
        </Route>

          <Route
              path='auth/siRelated/ge/merge'
              element={
                  <Authed
                      authList={[PERMISSIONS.GE_WRITE]}
                      element={<GEMergePage />}
                  />
              }
          />

        <Route
          path='auth/sipRelated/sip/transformToSI/:sipCode'
          element={<SIPTransformToSI />}
        />

        <Route path='accept/:token' element={<AcceptPage />} />
        <Route
          path='revise/:token'
          element={<RevisePage type='ProposerRevise' />}
        />
        <Route
          path='reviseFromJE/:token'
          element={<RevisePage type='ProposerReviseFromJE' />}
        />
        <Route
          path='reviseJE/:token'
          element={<RevisePage type='EditorReview' />}
        />
        <Route
          path='JPMresubmit/:token'
          element={<RevisePage type='JPMReview' />}
        />
        <Route
          path='ebm-review/:token'
          element={<EbmReviewPage/>}
        />
      </Route>
    </Routes>
  );
}

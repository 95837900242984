import { Box, Tooltip } from '@mui/material';
import AttachmentClose from '../../../../assets/AttachmentClose.svg';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import {DeleteAttachment} from "@/actions/SIMT-Email/SiEmail";
import {DeleteSIPAttachment} from "@/actions/SIMT-Email/StageDecision";
export default function CustomAttachmentBlock(props) {
  const { file, index, files, setFiles, ...other } = props;
  const CssBox = {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: ' 4px 12px',
    width: 'auto',
    height: '28px',
    border: '1px solid #DFE4E8',
    borderRadius: '6px',
    marginRight: '5px',
  };
  const CssFont = color => {
    return {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: color,
    };
  };
  const dispatch = useDispatch();
  const handleDeleteAttachment = () => {
    if (props.isSIP) {
      dispatch(DeleteSIPAttachment(file.id));
    } else {
      dispatch(DeleteAttachment(file.id));
    }
    let newFiles = files.concat();
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };
  return (
    <Box
      sx={CssBox}
      {...other}
      data-selenium-id='Email_Send-CommonComponents-CustomAttachmentsBlock-Box'
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginRight: '16px',
        }}
        data-selenium-id='Email_Send-CommonComponents-CustomAttachmentsBlock-Box1'
      >
        <Tooltip
          title={file.name}
          data-selenium-id='Email_Send-CommonComponents-CustomAttachmentsBlock-Tooltip'
        >
          <Box
            sx={{
              ...CssFont('#154AB6'),
              marginRight: '8px',
              maxWidth: '300px',
              overflow: 'hidden',
              height: '20px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {file.name}
          </Box>
        </Tooltip>
        <Box
          sx={{ ...CssFont('#596A7C') }}
          data-selenium-id='Email_Send-CommonComponents-CustomAttachmentsBlock-Box1-Box'
        >
          ({file.size}
          {file.unit})
        </Box>
      </Box>
      <Box
        sx={{ margin: '1px 0px', width: '18px', height: '18px' }}
        data-selenium-id='Email_Send-CommonComponents-CustomAttachmentsBlock-Box2'
      >
        {file.loading ? (
          <CircularProgress
            size={18}
            data-selenium-id='Email_Send-CommonComponents-CustomAttachmentsBlock-Box2-CircularProgress'
          />
        ) : (
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={handleDeleteAttachment}
            data-selenium-id='Email_Send-CommonComponents-CustomAttachmentsBlock-Box2-Box'
          >
            <AttachmentClose />
          </Box>
        )}
      </Box>
    </Box>
  );
}

import moment from "moment/moment";
import {axios_instance} from "@/utils/axios_instance";
import {downloadFile} from "@/actions/SIMT-SI/SpecialIssue/SIAction";
import {isTimeoutError} from "@/utils/commonUtils";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import getAuth from "@/utils/getAuth";

/**
 * fy 导出excel
 * @param fiscalYear  财年
 * @returns {(function(*): Promise<void>)|*}
 */
export function fyExport(fiscalYear) {
    const url = '/reports/export/article/FyTrends/excel';
    return async dispatch => {
        const filename = `Articles_FYTrends_${moment()
            .utc()
            .format('MMDDYYYY_HHmmss')}.xls`;
        try {
            let res = await axios_instance.get(url, {
                responseType: 'blob',
                params: {
                    selectedFY: fiscalYear,
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    authorization: getAuth(),
                },
            });
            downloadFile(res.data, filename);
        } catch (err) {
            let reasonId;
            if (isTimeoutError(err)) {
                reasonId = 'common.errorTimeout';
            } else {
                reasonId = `common.error${err.status}`;
            }
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToExport',
                    {
                        value: filename,
                        reason: reasonId,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
}

export function fyExportWithFilenameFromBackEnd(fiscalYear) {
    const url = '/reports/export/article/FyTrends/excel';
    return async dispatch => {
        const filename = `Articles_FYTrends_${moment()
            .utc()
            .format('MMDDYYYY_HHmmss')}.xls`;
        try {
            let res = await axios_instance.get(url, {
                responseType: 'blob',
                params: {
                    selectedFY: fiscalYear,
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    authorization: getAuth(),
                },
            });
            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
            } else {
                downloadFile(res.data, filename);
            }
        } catch (err) {
            let reasonId;
            if (isTimeoutError(err)) {
                reasonId = 'common.errorTimeout';
            } else {
                reasonId = `common.error${err.status}`;
            }
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToExport',
                    {
                        value: filename,
                        reason: reasonId,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
} // if(res.headers['content-disposition']&&res.headers['content-disposition'].includes('filename')){
//   downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
// }else{
/**
 * fy 导出csv
 * @param fiscalYear  财年
 * @param tableType 表格名称，格式例如subject_group_table
 * @returns {(function(*): Promise<void>)|*}
 */
export function fyExportCSV(fiscalYear, tableType) {
    const url = '/reports/export/article/FyTrends/csv';
    return async dispatch => {
        const filename = `Articles_FYTrends_${tableType}_${moment()
            .utc()
            .format('MMDDYYYY_HHmmss')}.csv`;
        try {
            let res = await axios_instance.get(url, {
                params: {
                    selectedFY: fiscalYear,
                    type: tableType,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            downloadFile(res.data, filename);
        } catch (err) {
            let reasonId;
            if (isTimeoutError(err)) {
                reasonId = 'common.errorTimeout';
            } else {
                reasonId = `common.error${err.status}`;
            }
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToExport',
                    {
                        value: filename,
                        reason: reasonId,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
}

export function fyExportCSVWithFilenameFromBackEnd(fiscalYear, tableType) {
    const url = '/reports/export/article/FyTrends/csv';
    return async dispatch => {
        const filename = `Articles_FYTrends_${tableType}_${moment()
            .utc()
            .format('MMDDYYYY_HHmmss')}.csv`;
        try {
            let res = await axios_instance.get(url, {
                params: {
                    selectedFY: fiscalYear,
                    type: tableType,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
            } else {
                downloadFile(res.data, filename);
            }
        } catch (err) {
            let reasonId;
            if (isTimeoutError(err)) {
                reasonId = 'common.errorTimeout';
            } else {
                reasonId = `common.error${err.status}`;
            }
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToExport',
                    {
                        value: filename,
                        reason: reasonId,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
}
import React, { useEffect, useRef, useState } from 'react';
import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material';
import './index.css';
import HeaderMenuIcon from '../../assets/HeaderMenuIcon.svg';
import WileyLogo from '@/assets/wiley_logo.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeSiderOpen } from '@/actions/SIMT-User/User/UserManageAction';
import { getAuth } from '@/utils/authTokenHandler';
import CommonSider from '../CommonSider';
import UserProfilePanel from './CustomComponents/UserProfilePanel';
import AlertPanel from './CustomComponents/AlertPanel';
import { NotificationDrawer } from '../CommonSider/NotificationDrawer';
const noNeedSiderPages = [
  //模糊搜索，请输入尽可能完整的路由防止误伤
  'simt/auth/sipRelated/sip/checklist',
  '/simt/auth/sipRelated/sip/aiScreeningReport',
];
function needSider() {
  let result = true;
  noNeedSiderPages.forEach(item => {
    if (window.location.pathname.indexOf(item) !== -1) {
      return (result = false);
    }
  });
  return result;
}
const AuthHeader = props => {
  const { disableSider } = props;
  const [isNeedSider, setIsNeedSider] = useState(needSider());
  useEffect(() => {
    setIsNeedSider(needSider());
  }, [window.location.pathname]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const siderOpen = useSelector(state => state.UserManage.siderOpen);
  const headerContainerRef = useRef();
  const [anchorElements, setAnchorElements] = useState({
    // 控制，同时只能有一个 panel 打开
    userProfilePanel: null,
    alertPanel: null,
  });
  const setUserProfilePanel = userProfilePanelAnchorEl => {
    setAnchorElements({
      userProfilePanel: userProfilePanelAnchorEl,
      alertPanel: null,
    });
  };
  const setAlertPanel = alertAnchorEl => {
    setAnchorElements({
      userProfilePanel: null,
      alertPanel: alertAnchorEl,
    });
  };

  useEffect(() => {
    if (localStorage.getItem('authorization')) {
      setIsSuperAdmin(
        getAuth(localStorage.getItem('authorization')).split(':')[0] ===
          'super_admin'
      );
      if (
        getAuth(localStorage.getItem('authorization')).split(':')[0] ===
        'super_admin'
      ) {
        dispatch(changeSiderOpen(false));
      }
    }
  }, [dispatch]);

  const handleClickIcon = () => {
    navigate('/simt/auth/index');
  };

  const handleOpenSider = () => {
    if (!isSuperAdmin) {
      dispatch(changeSiderOpen(!siderOpen));
    }
  };

  return (
    <Box>
      {disableSider || !isNeedSider ? null : <CommonSider />}
      <NotificationDrawer />
      <AppBar
        id='AuthHeader-AppBar'
        color='primary'
        sx={{
          // zIndex: (theme) => theme.zIndex.drawer + 1,    // 旧版
          zIndex: theme => 1200, // 新版直接写死1400。可能引发的问题：点击用户信息之后无法再次点击来关闭，只能点击外面来关闭
          boxShadow: 'none',
          '&. SIMTHeaderMenuButton': {
            color: '#fff',
            padding: '12px 0',
            '& .MuiTouchRipple-root': {
              overflow: 'visible',
            },
          },
          height: '56px',
        }}
        position='fixed'
      >
        <Toolbar
          variant='dense'
          style={{ padding: '0 28px 0 36px', height: '56px' }}
        >
          {/* 导航栏左侧侧边栏展开按钮 */}
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{ width: '100%', height: '56px' }}
            ref={headerContainerRef}
          >
            <Box>
              <IconButton
                onClick={handleClickIcon}
                id='HeaderLogoBtn'
                sx={{ padding: '0px', height: '20px' }}
              >
                <WileyLogo />
              </IconButton>
            </Box>
            <Stack direction='row' alignItems='center'>
              <AlertPanel
                headerContainerRef={headerContainerRef}
                anchorEl={anchorElements.alertPanel}
                setAnchorEl={setAlertPanel}
              />
              <UserProfilePanel
                headerContainerRef={headerContainerRef}
                anchorEl={anchorElements.userProfilePanel}
                setAnchorEl={setUserProfilePanel}
              />
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      {/*占位用*/}
      <Box sx={{ height: '56px' }} />
    </Box>
  );
};

export default AuthHeader;

import { Box, Stack, Backdrop, Drawer, Divider } from '@mui/material';
import { SmallFont } from '@/components/FontStyle';
import { StateButton } from '@/componentsphase2/StateButton';
import { loadData } from '@/actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import { blankTrim, isReportOverCutOffDate } from '@/utils/commonUtils';
import { useShowReportRolesByFyHook } from '@/pages/Report/utils/useShowReportRolesByFyHook';

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { handleFileterJournalName } from '@/utils/commonUtils';
import ReportFilterSwitchIconOn from '@/assets/ReportFilterSwitchIconOn.svg';
import ReportFilterSwitchIconOff from '@/assets/ReportFilterSwitchIconOff.svg';
import { Checkbox } from '@mui/material';
import CloseIconButton from '@/componentsphase2/Button/CloseIconButton';
import { MulSelectInput } from '@/componentsphase2/SelectBox/SelectInput';
import {
  fyChangePage,
  fyFilterApply,
  fyFilterClear,
  fyFilterSelect,
} from '@/actions/SIMT-Report/Report/ReportAction';
import { unstable_batchedUpdates } from 'react-redux/lib/utils/reactBatchedUpdates';

export default function PipelineFilter(props) {
  const { filterOpen, drawerWidth, handleClose } = props;
  const dispatch = useDispatch();
  const [showJPM, showNewJournalRoles] = useShowReportRolesByFyHook();
  const {
    selectDate,
    cutOffDate,
    handlingCeData,
    catGroupData,
    journalData,
    jpmData,
    pdPublisherData,
    ppPublisherData,
    subjectGroupData,
    businessDivisionData,
    revenueModel,
    filterSelected,
    filterData = {},
  } = useSelector(state => {
    return {
      selectDate: state.Report.selectDate,
      cutOffDate: state.Report.cutOffDate,
      // filter display
      handlingCeData: state.Filter.handlingCe,
      catGroupData: state.Filter.catGroup,
      journalData: state.Filter.journal,
      jpmData: state.Filter.jpm,
      pdPublisherData: state.Filter.pdPublisher, // add pd publisher choices
      ppPublisherData: state.Filter.ppPublisher,
      subjectGroupData: state.Filter.subjectGroup,
      businessDivisionData: state.Filter.businessDivision,
      filterSelected: state.Report.fyTrends.filterSelected,
      revenueModel: state.Report.fyTrends.filterSelected.oa[0],
      filterData: state.Report.fyTrends.useFilter,
    };
  });

  useEffect(() => {
    if (filterOpen) {
      document.body.classList.add('hide-scroll');

      const {
        journalName: journalCode = [],
        revenueModel = [],
        jpm = [],
        pdPublisher = [],
        ppPublisher = [],
        subjectGroup = [],
        businessDivision = [],
        catGroup = [],
        handlingCe = [],
        journalStatus = [],
      } = filterData;

      const journalList = [];
      for (let code of journalCode){
        for (let journal of journalData){
          if (journal.includes(`${code}(`)){
            journalList.push(journal);
          }
        }
      }

      const handlingCes = [];
      for (let ceId of handlingCe){
        for (let ce of handlingCeData) {
          if (ce.userId === ceId) {
            handlingCes.push(ce);
          }
        }
      }

      const groups = [];
      for (let group of catGroup){
        for (let cat of catGroupData){
          if (cat.value === group){
            groups.push(cat);
          }
        }
      }

      const setSelect = fyFilterSelect;
      dispatch(setSelect('journal', journalList));
      dispatch(setSelect('jpm', jpm));
      dispatch(setSelect('pdPublisher', pdPublisher));
      dispatch(setSelect('ppPublisher', ppPublisher));
      dispatch(setSelect('subjectGroup', subjectGroup));
      dispatch(setSelect('catGroup', groups));
      dispatch(setSelect('businessDivision', businessDivision));
      dispatch(setSelect('handlingCe', handlingCes));
      dispatch(setSelect('oa', revenueModel));
      dispatch(setSelect('journalStatus', journalStatus));
    } else {
      document.body.classList.remove('hide-scroll');
    }
  },[filterOpen]);

  const onLoad = async (which, searchContent, page = 1, pageSize = 5000) => {
    dispatch(loadData(which, blankTrim(searchContent), page, pageSize));
  };

  const handleSelectedChange = (which, data) => {
    // selectFilter(which, data);
    dispatch(fyFilterSelect(which, data));
  };

  const handleReset = () => {
    // dispatch(clearReportFilter());
    dispatch(fyFilterClear());
  };

  const handlerevenueModellChange = e => {
    dispatch(fyFilterSelect('oa', [!revenueModel]));
  };

  const prepareFilters = () => {
    // 处理journal code----感觉不规范，以后要跟后端协商
    var journalName = [];
    for (const journalSelectedElement of filterSelected.journal) {
      journalName.push(
        handleFileterJournalName(journalSelectedElement)['journalName']
      );
    }

    // 处理handling ce
    var handlingCeIds = [];
    for (const element of filterSelected.handlingCe) {
      handlingCeIds.push(element.userId);
    }

    // 处理group id
    const groupIds = [];
    for (const element of filterSelected.catGroup) {
      groupIds.push(element.value);
    }

    return {
      handlingCe: handlingCeIds,
      catGroup: groupIds,
      journalName: journalName,
      jpm: filterSelected.jpm,
      pdPublisher: filterSelected.pdPublisher,
      ppPublisher: filterSelected.ppPublisher,
      subjectGroup: filterSelected.subjectGroup,
      businessDivision: filterSelected.businessDivision,
      revenueModel: revenueModel ? ['OA'] : [],
      journalStatus: filterSelected.journalStatus,
    };
  };

  const handleApply = async () => {
    const filters = prepareFilters();
    unstable_batchedUpdates(() => {
      dispatch(fyFilterApply(filters));
      dispatch(fyChangePage(1)); // 修改了filter之后需要把页码置1，否则请求的内容会有问题
    });
    handleCloseFilter();
  };

  const handleCloseFilter = () => {
    // document.body.classList.remove('hide-scroll');
    handleClose();
  };

  const filterBody = useRef(null);
  function goBottom() {
    setTimeout(()=>{
      const box = filterBody.current;
      if (box){
        box.scrollTop = box.scrollHeight - box.offsetHeight;
      }
    },301);
  }

  return (
    <Box
      sx={{
        width: '100%',
      }}
      data-selenium-id='SIPage_Overview-anotherNewFilterBox'
    >
      <Backdrop
        id='SIPageOverviewAnotherNewFilterDrawerBackdrop'
        data-selenium-id={'SIPage_Overview-anotherNewFilterDrawerBackdrop'}
        sx={{
          zIndex: 1400,
          backdropFilter: 'blur(4px)',
          background: '#113D9580',
        }}
        open={filterOpen}
      ></Backdrop>
      <Drawer
        id='SIPageOverviewAnotherNewFilterDrawer'
        variant='persistent'
        anchor='right'
        open={filterOpen}
        sx={{
          flexShrink: 0,
          ['& .MuiDrawer-paper']: {
            width: '472px',
            zIndex: 1400,
            backgroundColor: '#FFFFFF',
            paddingBottom: '20px',
          },
        }}
        data-selenium-id={'SIPage_Overview-anotherNewFilterDrawer'}
      >
        <Box
          sx={{
            m: '20px 36px',
            height: '34px',
          }}
        >
          <CloseIconButton
            sx={{
              float: 'left',
              padding: '0px',
              marginRight: '12px',
              pt: '5px',
            }}
            onClick={() => {
              handleCloseFilter();
            }}
            data-selenium-id='ReportRelated-ReportOverview-Filter-CloseButton'
          />
          <Box
            // display="flex" justifyContent="center"
            sx={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22.5px',
              height: '13px',
              float: 'left',
              color: '#262E35',
              mt: '5px',
              // mb: '10px',
            }}
            data-selenium-id='CATJournal_Overview-OptInOperation-Edit-Box-Box'
          >
            Filters
          </Box>
          <StateButton
            onClick={handleApply}
            data-selenium-id='CATJournal_Overview-OptInOperation-Edit-Box2'
            titleName='Filter'
            isPrimary={true}
            sx={{
              lineHeight: '18px',
              marginLeft: '10px',
              float: 'right',
              // '&:disabled': {
              //   backgroundColor: '#BCC5CF',
              //   boxShadow: 'none',
              //   color: '#FFFFFF',
              // },
            }}
            // disabled={isError()}
          ></StateButton>
          <StateButton
            onClick={handleReset}
            data-selenium-id='CATJournal_Overview-OptInOperation-Edit-Box1'
            titleName='Clear'
            isPrimary={false}
            sx={{ lineHeight: '18px', float: 'right' }}
          ></StateButton>
        </Box>
        <Divider></Divider>
        <Stack
          ref={filterBody}
          direction='column'
          spacing={3}
          sx={{
            p: '20px 30px 0px 36px',
            marginRight: '6px',
            overflowY: 'auto',
            scrollbarWidth: 'thin', // 设置滚动条宽度
            // scrollbarColor: 'transparent',  // 设置滚动条颜色为透明
            // '&::-webkit-scrollbar': {
            // width: '0px',  // 设置滚动条宽度
            // },
            // '&::-webkit-scrollbar-thumb': {
            //   background: 'transparent',  // 设置滚动条背景为透明
            // },
          }}
        >
          {/* Journal */}
          <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
              Journal
            </Box>
            <MulSelectInput
              id='reportArticlesFilterjournalSelect'
              data-selenium-id='Report_Articles_Filter-journalSelect'
              value={filterSelected.journal}
              placeholder='Search Journal Name or Code'
              defaultOption={key => key}
              getOptionLabel={option => option}
              options={journalData}
              loadOptionAction={(...props) =>
                onLoad.bind(this, 'journal')(...props)
              }
              onValueChange={value => {
                handleSelectedChange.bind(this, 'journal')(value);
              }}
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
            />
          </Box>
          {/* JPM */}
          {showJPM && <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
              JPM
            </Box>
            <MulSelectInput
              id='reportArticlesFilterJpmSelect'
              data-selenium-id='Report_Articles_Filter-JpmSelect'
              value={filterSelected.jpm}
              placeholder='Search JPM'
              defaultOption={key => key}
              getOptionLabel={option => option}
              options={jpmData}
              loadOptionAction={(...props) =>
                onLoad.bind(this, 'jpm')(...props)
              }
              onValueChange={value => {
                handleSelectedChange.bind(this, 'jpm')(value);
              }}
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
            />
          </Box>}
          {/* PD Publisher */}
          {showNewJournalRoles && <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
              PD Publisher
            </Box>
            <MulSelectInput
              id='reportArticlesFilterPdPublisherSelect'
              data-selenium-id='Report_Articles_Filter-PdPublisherSelect'
              value={filterSelected.pdPublisher}
              placeholder='Search PD Publisher'
              defaultOption={key => key}
              getOptionLabel={option => option}
              options={pdPublisherData}
              loadOptionAction={(...props) =>
                onLoad.bind(this, 'pdPublisher')(...props)
              }
              onValueChange={value => {
                handleSelectedChange.bind(this, 'pdPublisher')(value);
              }}
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
            />
          </Box>}
          {/* PP Publisher */}
          {showNewJournalRoles && <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
              PP Publisher
            </Box>
            <MulSelectInput
              id='reportArticlesFilterPpPublisherSelect'
              data-selenium-id='Report_Articles_Filter-PpPublisherSelect'
              value={filterSelected.ppPublisher}
              placeholder='Search PP Publisher'
              defaultOption={key => key}
              getOptionLabel={option => option}
              options={ppPublisherData}
              loadOptionAction={(...props) =>
                onLoad.bind(this, 'ppPublisher')(...props)
              }
              onValueChange={value => {
                handleSelectedChange.bind(this, 'ppPublisher')(value);
              }}
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
            />
          </Box>}
          {/* Subject Group */}
          <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
              Subject Group
            </Box>
            <MulSelectInput
              id='reportArticlesFilterSubjectgroupSelect'
              data-selenium-id='Report_Articles_Filter-SubjectgroupSelect'
              value={filterSelected.subjectGroup}
              placeholder='Search Subject Group'
              defaultOption={key => key}
              getOptionLabel={option => option}
              options={subjectGroupData}
              loadOptionAction={(...props) =>
                onLoad.bind(this, 'subjectGroup')(...props)
              }
              onValueChange={value => {
                handleSelectedChange.bind(this, 'subjectGroup')(value);
              }}
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
            />
          </Box>
          {/* CAT Group */}
          <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
              CAT Group
            </Box>
            <MulSelectInput
              id='reportArticlesFilterCatgroupSelect'
              data-selenium-id='Report_Articles_Filter-CatgroupSelect'
              value={filterSelected.catGroup}
              placeholder='Choose Group Title'
              defaultOption={key => key}
              getOptionLabel={option => option.value}
              options={catGroupData}
              loadOptionAction={(...props) =>
                onLoad.bind(this, 'catGroup')(...props)
              }
              onValueChange={value => {
                handleSelectedChange.bind(this, 'catGroup')(value);
              }}
              disableNewItem={true}
              showPopupIcon={true}
              showStartIcon={false}
            />
          </Box>
          {/* Business Division */}
          <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
              Business Division
            </Box>
            <MulSelectInput
              id='reportArticlesFilterBusinessdivisionSelect'
              data-selenium-id='Report_Articles_Filter-BusinessdivisionSelect'
              value={filterSelected.businessDivision}
              placeholder='Choose Business Division'
              defaultOption={key => key}
              getOptionLabel={option => option}
              options={businessDivisionData}
              loadOptionAction={(...props) =>
                onLoad.bind(this, 'businessDivision')(...props)
              }
              onValueChange={value => {
                handleSelectedChange.bind(this, 'businessDivision')(value);
              }}
              disableNewItem={true}
              showPopupIcon={true}
              showStartIcon={false}
            />
          </Box>
          {/* Handling CE */}
          <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_Articles-Filter-Block-Title'
            >
              Handling CE
            </Box>
            <MulSelectInput
              id='reportArticlesFilterHeSelect'
              data-selenium-id='Report_Articles_Filter-HeSelect'
              value={filterSelected.handlingCe}
              placeholder='Search Handling CE'
              defaultOption={key => key}
              getOptionLabel={item => {
                return `${item.username} (${item.email})`;
              }}
              options={handlingCeData}
              loadOptionAction={(...props) =>
                onLoad.bind(this, 'handlingCe')(...props)
              }
              onValueChange={value => {
                handleSelectedChange.bind(this, 'handlingCe')(value);
              }}
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
              onOpen={goBottom}
            />
          </Box>
          {/* Journal Status */}
          <Box>
            <Box
              sx={{
                ...SmallFont,
                color: '#596A7C',
                pb: '4px',
                fontWeight: '600',
                letterSpacing: '0.01em',
              }}
              data-selenium-id='Report_AcquiredAndActiveSi-Filter-Block-Title'
            >
              Journal Status
            </Box>
            <MulSelectInput
              id='journalStatus'
              data-selenium-id='Report_AcquiredAndActiveSi_Filter-journalStatus'
              value={filterSelected.journalStatus}
              placeholder='Choose Journal Status'
              defaultOption={key => key}
              getOptionLabel={option => option}
              options={['opt-in', 'opt-out']}
              onValueChange={value => {
                handleSelectedChange.bind(this, 'journalStatus')(value);
              }}
              disableNewItem={true}
              showPopupIcon={true}
              showStartIcon={false}
              dropDownListNumber={2}
              onOpen={goBottom}
            />
          </Box>
          <Stack direction='row' alignItems='center' spacing='50px'>
            <Stack direction='row' alignItems='center' spacing='8px'>
              <Checkbox
                disableRipple
                disabled={!revenueModel}
                icon={<ReportFilterSwitchIconOff />}
                checkedIcon={<ReportFilterSwitchIconOn />}
                checked={!revenueModel}
                onChange={handlerevenueModellChange}
                sx={{
                  padding: '0px',
                }}
              />
              <Box
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '18px',
                  letterSpacing: '0em',
                }}
              >
                All
              </Box>
            </Stack>
            <Stack direction='row' alignItems='center' spacing='8px'>
              <Checkbox
                disableRipple
                disabled={revenueModel}
                icon={<ReportFilterSwitchIconOff />}
                checkedIcon={<ReportFilterSwitchIconOn />}
                checked={revenueModel}
                onChange={handlerevenueModellChange}
                sx={{
                  padding: '0px',
                }}
              />
              <Box
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '18px',
                  letterSpacing: '0em',
                }}
              >
                OA
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Drawer>
    </Box>
  );
}

import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { buttonClasses } from '@mui/material';

export const StyledLoadingButton = styled(LoadingButton)(() => ({
  [`&.${buttonClasses.root}`]: {
    textTransform: 'capitalize',
    boxShadow: 'none',
    borderRadius: '4px',
    fontWeight: 600,
  },
}));

/**
 * ！需要改进！暂不建议使用！
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
export default function SIMTAsyncButton(props) {
  const { onClick, ...other } = props;

  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
  };

  useEffect(() => {
    if (loading) {
      async function clickButton() {
        await onClick();
      }
      clickButton().then(_r => {
        setLoading(false);
      });
    }
  }, [loading]);

  return (
    <StyledLoadingButton
      onClick={handleClick}
      loading={loading}
      disabled={loading}
      {...other}
    >
      {props.children}
    </StyledLoadingButton>
  );
}

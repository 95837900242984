import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Divider, Link, TableSortLabel, Tooltip } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import {
  CustomPagination,
  MyTableHeader,
  StyledTableCell,
  TableFooter2,
} from '../../../components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleChangeGEPageSize,
  handleGEPageChangeAction,
  selectBatchGE,
  setGEOrderBy,
  unselectBatchGE,
} from '../../../actions/SIMT-SI/GuestEditor/GEAction';
import { useNavigate, Link as ReactLick } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import {getAssociatedSITableInfo} from "@/actions/SIMT-SI/GuestEditor";

// import {DataGrid} from "@mui/x-data-grid";

function createRow(
  id,
  label,
  isOrder = true,
  minWidth = '110px',
  maxWidth = '220px',
  show = true
) {
  return { id, label, isOrder, minWidth, maxWidth, show };
}

const headerItems = [
  createRow('tag', 'TAG', false, '80px', '110px', true),
  createRow('title', 'SI TITLE', false, '150px', '280px', true),
  createRow('siCode', 'SI CODE', false, '80px', '160px', true),
  createRow('stage', 'STAGE', false, '110px', '110px', true),
  createRow('journalName', 'JOURNAL NAME', false, '160px', '160px', true),
];

export default function AssociatedSIBlock(props) {
  const { geId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selector = state => {
    return {
      AssociatedSIList: state.GE.AssociatedSIList,
      page: state.GE.page,
      pageSize: state.GE.pageSize,
      orderByType: state.GE.orderByType,
    };
  };

  var url = '/simt/auth/siRelated/si/detail';

  const { AssociatedSIList, page, pageSize, orderByType } =
    useSelector(selector);

  useEffect(() => {
    dispatch(getAssociatedSITableInfo(geId, page, pageSize));
  }, [dispatch, page, pageSize, geId]);

  const handleAssociatedSI = () => {
    // if(AssociatedSIList.result){
    //   console.log(AssociatedSIList.result.length);
    // }
    if (
      AssociatedSIList.result === undefined ||
      AssociatedSIList.result.length === 0
    )
      return [
        {
          siId: '1',
          tag: '',
          siTitle: '',
          siCode: 'NO DATA',
          stage: '',
          journalName: '',
        },
      ];
    return AssociatedSIList.result;
  };

  const handleChangeOrder = orderBy => {
    dispatch(
      setGEOrderBy(
        orderByType.orderBy === orderBy
          ? orderByType.order === 'desc'
            ? 'asc'
            : 'desc'
          : 'asc',
        orderBy
      )
    );
  };

  const handlePerPageChange = e => {
    dispatch(handleChangeGEPageSize(e.target.value));
  };

  const handlePageChange = (_event, newPage) => {
    dispatch(handleGEPageChangeAction(newPage));
  };
  const array = handleAssociatedSI(); // 调用 handleAssociatedSI() 获取Associated Special Issues
  return (
    <Box sx={{ marginTop: '24px' }}>
      <Box
        id='TableBox'
        data-selenium-id='GEPage_GEDetailPage-AssociatedSIBlock-TableBox'
      >
        <TableContainer
          sx={{
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderWidth: '1px 1px 0px 1px',
            borderStyle: 'solid',
            borderColor: '#DFE4E8',
            maxHeight: '397px',
          }
            // array.length > 0 ? undefined :{ minHeight: '100px', }
          }
          data-selenium-id='GEPage_GEDetailPage-AssociatedSIBlock-TableBox-TableContainer'
        >
          <Table
            stickyHeader
            sx={{ minWidth: 700 }}
            aria-label='customized table'
            data-selenium-id='GEPage_GEDetailPage-AssociatedSIBlock-TableBox-TableContainer-Table'
          >
            <MyTableHeader
              items={headerItems}
              backgroundColor='#F1F3F5'
              color='#596A7C'
              CheckboxColor='wileyBlue2'
              letterSpacing='0.08em'
              data-selenium-id='GEPage_GEDetailPage-AssociatedSIBlock-TableBox-TableContainer-Table-MyTableHeader'
            />
            <TableBody data-selenium-id='GEPage_GEDetailPage-AssociatedSIBlock-TableBox-TableContainer-Table-TableBody'>
              {(Array.isArray(array)?array:[]).map((row, index) => (
                <TableRow
                  sx={{
                    '&:hover': {
                      background: '#E8EDFB'
                    }
                  }}
                  key={row.siCode}
                  data-selenium-id={`GEPage_GEDetailPage-AssociatedSIBlock-TableBox-TableContainer-Table-TableBody-TableRow-${index}`}
                >
                  <StyledTableCell
                    id={`gePageAssociatedSITableTag${index}`}
                    data-selenium-id={`GEPage_GEDetailPage-AssociatedSIBlock-TableBox-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell1`}
                    component='th'
                    scope='row'
                    align='left'
                    sx={{
                      padding: '12px 24px',
                    }}
                  >
                    {row.tag}
                  </StyledTableCell>
                  <StyledTableCell
                    id={`gePageAssociatedSITableTitle${index}`}
                    data-selenium-id={`GEPage_GEDetailPage-AssociatedSIBlock-TableBox-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell2`}
                    align='left'
                    sx={{
                      padding: '12px 24px',
                    }}
                  >
                    <ReactLick
                      style={{ textDecoration: 'none', color: '#154AB6' }}
                      extra
                      id={`siCodeLink${row.siCode}`}
                      data-selenium-id={`GEPage_GEDetailPage-AssociatedSIBlock-TableBox-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell2-ReactLick${row.siCode}`}
                      to={`${url}/${row.siCode}`}
                      target='_blank'
                    >
                      <Box
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        {row.siTitle.length >= 30 ? ( // 超长的显示弹框---没有要求这个，但是如果不加体验会很差
                          <Tooltip title={row.siTitle} disableInteractive>
                            <div
                              style={{
                                textOverflow: 'ellipsis',
                                width: '280px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {row.siTitle}
                            </div>
                          </Tooltip>
                        ) : (
                          row.siTitle
                        )}
                      </Box>
                    </ReactLick>
                  </StyledTableCell>

                  <StyledTableCell
                    id={`gePageAssociatedSITableSICode${index}`}
                    data-selenium-id={`GEPage_GEDetailPage-AssociatedSIBlock-TableBox-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell3`}
                    align='left'
                    sx={{
                      padding: '12px 24px',
                      maxWidth: 160,
                    }}
                  >
                    {row.siCode !== 'NO DATA' && (
                      <ReactLick
                        style={{ textDecoration: 'none', color: '#154AB6' }}
                        extra
                        id={`siCodeLink${row.siCode}`}
                        data-selenium-id={`GEPage_GEDetailPage-AssociatedSIBlock-TableBox-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell3-ReactLick${row.siCode}`}
                        to={`${url}/${row.siCode}`}
                        target='_blank'
                      >
                        <Box
                          sx={{
                            fontWeight: 600,
                          }}
                        >
                          {row.siCode}
                        </Box>
                      </ReactLick>
                    )}
                    {row.siCode === 'NO DATA' && <div>{row.siCode}</div>}
                  </StyledTableCell>
                  <StyledTableCell
                    id={`gePageAssociatedSITableStage${index}`}
                    data-selenium-id={`GEPage_GEDetailPage-AssociatedSIBlock-TableBox-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell4`}
                    align='left'
                    sx={{
                      padding: '12px 24px',
                    }}
                  >
                    {row.stage}
                  </StyledTableCell>
                  <StyledTableCell
                    id={`gePageAssociatedSITableJournalName${index}`}
                    data-selenium-id={`GEPage_GEDetailPage-AssociatedSIBlock-TableBox-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell5`}
                    align='left'
                    sx={{
                      padding: '12px 24px',
                    }}
                  >
                    {row.journalName != null && row.journalName.length >= 18 ? ( // 超长的显示弹框---没有要求这个，但是如果不加体验会很差
                      <Tooltip title={row.journalName} disableInteractive>
                        <div
                          style={{
                            textOverflow: 'ellipsis',
                            width: '180px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {row.journalName}
                        </div>
                      </Tooltip>
                    ) : (
                      row.journalName
                    )}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter2
          Page={page}
          perPageNumber={pageSize}
          perPageList={[20, 50, 100]}
          count={AssociatedSIList.count}
          handlePerPageChange={handlePerPageChange}
          handlePageChange={handlePageChange}
          data-selenium-id='GEPage_GEDetailPage-AssociatedSIBlock-TableBox-TableFooter2'
        />
      </Box>
    </Box>
  );
}

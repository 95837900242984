// @ts-check
import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SectionTitleBlock } from '../CustomComponents/CustomBlock';
import SingleKV from '../../SIPDetailPage/Submodule/SingleKV';
import { SingleKVOther } from '../../SIPDetailPage/Submodule/SingleKV';
import SSIPCustomRadio from '../../SIPDetailPage/Submodule/SSIPCustomRadio';
// @ts-ignore
import SSIPReviseRadioUnSelectedIcon from '@/assets/SSIPReviseRadioUnSelectedIcon.svg';
// @ts-ignore
import GEInfoAdd from '@/assets/GEInfoAdd.svg';
import NumberRadio from '../../../../components/NumberRadio';
import { PotentialAuthorsTable } from '../CustomComponents/CustomTable';
import { useSelector } from 'react-redux';
import { SectionContainer } from '../CustomComponents/CustomContainer';
import { ItemBlock } from '../../SIPDetailPage/EditComponents';
import { ItemBlockShowText } from '../CustomComponents/CustomTypography';
import { EMAIL_PATTERN } from '@/constant/common';

/**
 *
 * @param {import("./ContentCommissioningApproachesSection").ContentCommissioningApproachesEditSectionProps} props
 * @returns
 */
export const SSIPContentCommissioningApproachesEditSection = props => {
  const {
    value: { openCallForPapers, potentialAuthors },
    setValue: setCommissioningApproaches,
    showErrorMessage = false,
    setError = () => {},
  } = props;

  const { formatMessage } = useIntl();

  /**
   * @type {import("../CustomComponents/CustomTable").HandlePotentialAuthorsTableChangeFunction}
   */
  const handlePotentialAuthorsTableChange = (
    rowIndex,
    rowItemKey,
    newValue
  ) => {
    setCommissioningApproaches(current => ({
      ...current,
      potentialAuthors: current.potentialAuthors.map((item, index) => {
        const keyValue = `${rowItemKey}`;
        if (index !== rowIndex) return item;
        else
          return {
            ...item,
            [keyValue]: newValue,
          };
      }),
    }));
  };

  const handleAuthorAdd = () => {
    setCommissioningApproaches(current => ({
      ...current,
      potentialAuthors: [
        {
          id: -1,
          name: '',
          affiliation: '',
          email: '',
          topic: '',
        },
        ...current.potentialAuthors,
      ],
    }));
  };

  /**
   *
   * @param {number} rowIndex
   */
  const handleDeleteRow = rowIndex => {
    setCommissioningApproaches(current => ({
      ...current,
      potentialAuthors: current.potentialAuthors.filter(
        (item, index) => index !== rowIndex
      ),
    }));
  };

  const [inputRequiredError, setInputRequiredError] = useState(false);
  const [emailRepeatError, setEmailRepeatError] = useState(false);
  const [emailInvalidError, setEmaiInvalideError] = useState(false);
  const [tooManyAuthorsError, setTooManyAuthorsError] = useState(false);

  useEffect(() => {
    setInputRequiredError(
      potentialAuthors.filter(
        potentialAuthor =>
          potentialAuthor.name === '' || potentialAuthor.affiliation === ''
      ).length > 0
    );

    const authorEmailList = potentialAuthors.map(item => item.email);

    setEmailRepeatError(
      authorEmailList.filter(
        (item, index) =>
          item !== null && item !== '' && authorEmailList.indexOf(item) != index
      ).length > 0
    );
    setEmaiInvalideError(
      potentialAuthors.filter(
        item =>
          !(
            item.email === '' ||
            item.email === null ||
            item.email
              .toLowerCase()
              .match(EMAIL_PATTERN)
          )
      ).length > 0
    );
    setTooManyAuthorsError(potentialAuthors.length > 50);
  }, [potentialAuthors]);

  useEffect(() => {
    setError(
      inputRequiredError ||
        emailRepeatError ||
        tooManyAuthorsError ||
        emailInvalidError
    );
  }, [
    inputRequiredError,
    emailRepeatError,
    tooManyAuthorsError,
    emailInvalidError,
  ]);
  const SSIPReviseRadioSelectedIcon = () =>{
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="12" cy="12" r="6.5" fill="white" stroke="#98A7B6"/>
  <circle cx="12" cy="12" r="4.66667" fill="#0052CC"/>
  </svg>
    );
  };
  return (
    <SectionContainer
      title='Content Commissioning Approaches'
      data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-HandleDeleteRow-Container'
    >
      <Stack
        data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-HandleDeleteRow-Stack'
        spacing='8px'
        sx={{
          '& .SingleKV-root': {
            display: 'flex',
            alignItems: 'center',
            '& .SingleKV-title': {
              width: 'auto',
              marginRight: '16px',
            },
            '& .SingleKV-content': {
              width: 'auto',
            },
          },
        }}
      >
        <SingleKVOther
          id='sipCCA'
          data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-HandleDeleteRow-SipCCA'
          title={formatMessage({ id: 'sipDetail.openCallForPapers' })}
          type='TEXT'
          margin='0px 0px 0px 0px'
        >
          <Box sx={{paddingLeft:'80px'}}>
            <SSIPCustomRadio
              // @ts-ignore
              value={Number(openCallForPapers !== null ? openCallForPapers : -1)}
              setValue={newValue =>
                setCommissioningApproaches(current => ({
                  ...current,
                  openCallForPapers: `${newValue}`,
                }))
              }
              data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-HandleDeleteRow-CustomRadio'
              selectedIcon={
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7" cy="7" r="6.5" fill="white" stroke="#98A7B6"/>
                <circle cx="7" cy="7" r="4.66667" fill="#0052CC"/>
                </svg>
                }
              unSelectedIcon={
                <SSIPReviseRadioUnSelectedIcon data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-RadioUnselectedIcon' />
              }
              labelStyle={{
                fontWeight: 400,
                marginLeft: '16px',
              }}
            />
          </Box>
        </SingleKVOther>
        <Box
          maxWidth={'1243px'}
          data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Box1'
        >
          <SingleKVOther
            id='sipCCA'
            data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Box1-CCA'
            title={formatMessage({ id: 'sipDetail.potentialAuthors' })}
            type='TEXT'
            margin='0px 0px 0px 0px'
          >
            <IconButton
              onClick={handleAuthorAdd}
              data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Box1-IconButton'
            >
              <GEInfoAdd data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Box1-GEInfoAdd' />
            </IconButton>
          </SingleKVOther>
          <Box
            data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Box2'
            sx={{
              '& .MuiTypography-root.Typography-error-message': {
                color: '#C40000',
                fontSize: '14px',
              },
              marginBottom: '8px',
            }}
          >
            <Collapse
              in={inputRequiredError && showErrorMessage}
              data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Box2-Collapse1'
            >
              <Typography
                className='Typography-error-message'
                data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Box2-ErrorMessageTypoGrphy1'
              >
                Author name and affiliation are required.
              </Typography>
            </Collapse>
            <Collapse
              in={emailInvalidError && showErrorMessage}
              data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Box2-Collapse2'
            >
              <Typography
                className='Typography-error-message'
                data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Box2-ErrorMessageTypoGrphy2'
              >
                Invalid email address(es).
              </Typography>
            </Collapse>
            <Collapse
              in={emailRepeatError && showErrorMessage}
              data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Box2-Collapse3'
            >
              <Typography
                className='Typography-error-message'
                data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Box2-ErrorMessageTypoGrphy3'
              >
                There are duplicated email addresses.
              </Typography>
            </Collapse>
            <Collapse
              in={tooManyAuthorsError && showErrorMessage}
              data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Box2-Collapse4'
            >
              <Typography
                className='Typography-error-message'
                data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Box2-ErrorMessageTypoGrphy4'
              >
                Number of potential authors should not more than 50.
              </Typography>
            </Collapse>
          </Box>
          <PotentialAuthorsTable
            data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-PotentialAuthorsTable'
            tableRowList={potentialAuthors}
            mode='edit'
            handleTableRowItemChange={handlePotentialAuthorsTableChange}
            handleDeleteRow={handleDeleteRow}
            emptyTip={true}
            isSSIP={true}
          />
        </Box>
      </Stack>
    </SectionContainer>
  );
};

/**
 *
 * @param {import("./ContentCommissioningApproachesSection").ContentCommissioningApproachesShowSectionProps} props
 * @returns
 */
export const ContentCommissioningApproachesShowSection = props => {
  return (
    <SectionContainer
      title='Content Commissioning Approaches'
      data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Container'
    >
      <ContentCommissioningApproachesShowSectionCore
        {...props}
        data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-PropsCore'
      />
    </SectionContainer>
  );
};

/**
 *
 * @param {import("./ContentCommissioningApproachesSection").ContentCommissioningApproachesShowSectionCoreProps} props
 * @returns
 */
export const ContentCommissioningApproachesShowSectionCore = props => {
  const {
    value: commissionApproaches,
    openCallForPapersRenderValue = {
      '-1': 'N/A',
      0: 'No',
      1: 'Yes',
    },
    emptyTip = false,
  } = props;
  const { formatMessage } = useIntl();
  return (
    <Stack
      spacing='8px'
      data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Core-Stack'
    >
      <ItemBlock
        data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Core-ItemBlock1'
        title={formatMessage({ id: 'sipDetail.openCallForPapers' })}
        marginBottom='8px'
      >
        <ItemBlockShowText data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Core-ItemBlockShowText'>
          {
            openCallForPapersRenderValue[
              (commissionApproaches.openCallForPapers === undefined || commissionApproaches.openCallForPapers === null) ? '-1' : commissionApproaches.openCallForPapers
            ]
          }
        </ItemBlockShowText>
      </ItemBlock>
      <ItemBlock
        title={formatMessage({ id: 'sipDetail.potentialAuthors' })}
        data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Core-ItemBlock2'
      />
      <Box
        maxWidth={'1243px'}
        data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Core-Box'
      >
        <PotentialAuthorsTable
          data-selenium-id='SSIP_RevisePage_Section-ContentCommissioningApproaches-Core-PotentialAuthorsTable'
          tableRowList={commissionApproaches.potentialAuthors}
          mode='show'
          emptyTip={emptyTip}
        />
      </Box>
    </Stack>
  );
};

/* eslint-disable indent */
import { Box, Stack, Tooltip } from '@mui/material';
import ItemButtonUS from './CollapsedButton/ItemFolderButtonUS';
import ItemButtonSI from './CollapsedButton/ItemFolderButton_SI';
import ItemButtonSIP from './CollapsedButton/ItemFolderButton_SIP';
import ItemButtonR from './CollapsedButton/ItemFolderButton_R';
import ItemButtonJ from './CollapsedButton/ItemFolderButton_J';
import ItemButtonCM from './CollapsedButton/ItemFolderButton_CM';
import { ReactElement, Component, Children, isValidElement, cloneElement } from 'react';
import './ItemFolder.css';
import styleModule from './ItemFolder.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setUpdownDispatch } from '@/actions/SideBarAction';
const DifferentStyle = Object.freeze({
  UserSystem: {
    //top: '24px',
    background: '#E8EDFB',
  },
  CustomEmail: {
    background: '#F1F3F5',
  },
  SpecialIssue: {
    //top: '392px',
    background: '#E7F6F8',
  },
  SIProposal: {
    //top: '538px',
    background: '#FFF0DD',
  },
  Report: {
    //top: '692px',
    background: '#E8E9F9',
  },
  Journal: {
    //top: '946px',
    background: '#F0F9FF',
  },
  ChinaMarketing: {
    background: '#E7F6F8',
  }
});
const ItemFolderStyle = Object.freeze({
  display: 'flex',
  flexDirection: 'column',
  // position: 'absolute',
  borderRadius: '4px',
  transition: 'all .5s',
  // left: '6px',
  // paddingTop: '0',
  // marginTop: '0',
});
const DefaultStyle = Object.freeze({
  alignItems: 'center',
  width: '68px',
  transition: 'all .5s',
});
const ExpendedStyle = Object.freeze({
  alignItems: 'flex-start',
  width: '225px',
  transition: 'all .5s',
});
const DifferentDefaultStyle = Object.freeze({
  UserSystem: {
    //padding: '4px',
    gap: '6.6px',
    //height: '360px',
  },
  CustomEmail: {
    gap: '6.6px',
  },
  SpecialIssue: {
    //padding: '4px 6px',
    gap: '6.6px',
    //height: '136px',
  },
  SIProposal: {
    //padding: '4px',
    gap: '6.6px',
    //height: '144px',
  },
  Report: {
    //padding: '4px 2px',
    gap: '6.6px',
    //height: '244px',
  },
  Journal: {
    //padding: '4px 2px',
    gap: '6.6px',
    //height: '136px',
  },
  ChinaMarketing: {
    gap: '6.6px',
  }
});
const DifferentExpendedStyle = Object.freeze({
  UserSystem: {
    //padding: '6px 0px',
    gap: '10px',
    //height: '312px',
  },
  CustomEmail: {
    gap: '10px',
  },
  SpecialIssue: {
    //padding: '6px 12px',
    gap: '10px',
    //height: '100px',
  },
  SIProposal: {
    //padding: '6px 12px 6px 0px',
    gap: '10px',
    //height: '108px',
  },
  Report: {
    //padding: '6px 12px',
    gap: '12px',
    //height: '212px',
  },
  Journal: {
    //padding: '6px 12px 12px 0px',
    gap: '12px',
    //height: '110px',
  },
  ChinaMarketing: {
    gap: '12px',
  }
});
const TitleStyle = Object.freeze({
  height: '20px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '20px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  letterSpacing: '0.01em',
  flex: 'none',
  order: '0',
  flexGrow: '0',
  // paddingTop: '4px',  //fix bug: Menu's U&S and other first ones have an extra padding top
  marginBottom: '0',
  transition: 'all .5s',
});
const DifferentTitleColor = Object.freeze({
  UserSystem: '#0052CC',
  CustomEmail: '#596A7C',
  SpecialIssue: '#00A4B7',
  SIProposal: '#FF8A00',
  Report: '#7882DC',
  Journal: '#4DBBFF',
  ChinaMarketing: '#00A4B7'
});
const FlexRight = Object.freeze({
  justifyContent: 'flex-end',
  position: 'absolute',
  right: '8px',
});
type RRN_<T> = Readonly<Required<NonNullable<T>>>;
type RRN_Array<T> = RRN_<ReadonlyArray<T>>;
type RRN_String = RRN_<string>;
type RRN_Boolean = RRN_<boolean>;
type RRN_Object = RRN_<object>;
type RRN_Component = new () => RRN_<Component>;
type RRN_ReactElementGenericity<T> = RRN_<ReactElement<T, RRN_Component>>;
type anyReactElementGenericity = RRN_ReactElementGenericity<RRN_Object>;
type RRN_ItemProps = RRN_<{
  readonly id: RRN_String;
  readonly label: RRN_String;
  readonly Icon: anyReactElementGenericity;
  readonly active: boolean;
}>;
type ItemReactElementGenericity = RRN_ReactElementGenericity<RRN_ItemProps>;
type ItemFolderProps = {
  readonly label: RRN_String | RRN_ReactElementGenericity<{ id: RRN_String; }>;
  readonly authList?: RRN_Array<RRN_String>;
  readonly id: RRN_String;
  readonly isExtend: RRN_Boolean;
  readonly currentId?: RRN_String;
  // eslint-disable-next-line no-unused-vars
  readonly childItemSelect?: (id: RRN_String) => void;
  readonly children: RRN_<ItemReactElementGenericity | RRN_Array<ItemReactElementGenericity>>;
}
type RRN_ItemFolderProps = RRN_<ItemFolderProps>;
type ItemFolderReactElementGenericity = RRN_<ReactElement<RRN_ItemFolderProps, new () => RRN_<Component<RRN_ItemFolderProps>>>>;
export default function ItemFolder (props: ItemFolderProps): ItemFolderReactElementGenericity {
  const { label, authList, id, isExtend, currentId = '', childItemSelect = () => { }, children, ...others } = props;
  const isNotCNmarketing = Children.count(children) > 1;
  const isValidChildren = Children.count(children) === 1 && isValidElement(children);
  const CNmarketingChildrenProps = !isNotCNmarketing && isValidChildren && children.props;
  const CNmarketingChildrenPropsId = !isNotCNmarketing && CNmarketingChildrenProps && CNmarketingChildrenProps?.id;
  const isCNmarketingChildrenNotSelected = !isNotCNmarketing && CNmarketingChildrenPropsId !== currentId;
  let userPermissionArray = localStorage.getItem('userPermissions')
    ? localStorage.getItem('userPermissions')?.split(',')
    : [];
  let authed =
    authList === undefined ||
    authList.some(item => userPermissionArray?.includes(item));
  const [isUnFoldStyle, isUnFoldDifferentStyle] = isExtend ? [ExpendedStyle, DifferentExpendedStyle] : [DefaultStyle, DifferentDefaultStyle];//if isUnFold is true, use ExpendedStyle, else use DefaultStyle
  // const [Updown, setUpdown] = useState(true);
  let typeStyle = {}, TitleColor = '', ButtonComponent;
  const UpdownSelector = useSelector((state: {
    SideBar: {
      Updown: {
        commonSiderUserAndSystem: boolean,
        commonSiderEmail: boolean,
        commonSiderSpecialIssue: boolean,
        commonSiderSpecialIssueProposal: boolean,
        commonSiderReport: boolean,
        commonSiderJournal: boolean,
        commonSiderChinaMarketing: boolean,
      };
    };
  }) => state.SideBar.Updown);
  const dispatch = useDispatch();
  const setUpdown = (id: 'commonSiderUserAndSystem' | 'commonSiderEmail' | 'commonSiderSpecialIssue' | 'commonSiderSpecialIssueProposal' | 'commonSiderReport' | 'commonSiderJournal' | 'commonSiderChinaMarketing') => dispatch(setUpdownDispatch({ ...UpdownSelector, [id]: !UpdownSelector[id] }));
  let Updown = true;
  switch (id) {
    case 'commonSiderUserAndSystem':
      {
        Updown = UpdownSelector.commonSiderUserAndSystem;
        typeStyle = Object.freeze({
          ...DifferentStyle.UserSystem,
          ...isUnFoldDifferentStyle.UserSystem
        }); TitleColor = DifferentTitleColor.UserSystem;
        ButtonComponent = <ItemButtonUS
          ItemButtonClick={() => setUpdown(id)}
            LeftRight={isExtend}
          isClicked={!Updown}
            // sx={FlexRight}
            />;
        break;
      }
    case 'commonSiderEmail':
      {
        Updown = UpdownSelector.commonSiderEmail;
      typeStyle = Object.freeze({
        ...DifferentStyle.CustomEmail,
        ...isUnFoldDifferentStyle.CustomEmail
      }); TitleColor = DifferentTitleColor.CustomEmail;
      // ButtonComponent = <></>;
      ButtonComponent = isExtend? (<ItemButtonUS
        isClicked={!Updown}
        ItemButtonClick={() => setUpdown(id)}
        LeftRight={isExtend}
        sx={{
          '& svg': {
            '& rect': {
              fill: isExtend ? '#F1F3F5' : '#D6DCE2'
          },
            '& path': {
              fill: '#596A7C'
          }
        },
          '&.ItemFolderButton-isHovered svg': {
            '& rect': {
              fill: '#BCC5CF'
          },
            '& path': {
              fill: '#596A7C'
          }
        }
      }}
        />)
        : (<></>);
      break;
    }
    case 'commonSiderSpecialIssue':
      {
        Updown = UpdownSelector.commonSiderSpecialIssue;
        typeStyle = Object.freeze({
          ...DifferentStyle.SpecialIssue,
          ...isUnFoldDifferentStyle.SpecialIssue
        }); TitleColor = DifferentTitleColor.SpecialIssue;
        ButtonComponent = <ItemButtonSI ItemButtonClick={() => setUpdown(id)} LeftRight={isExtend} sx={FlexRight} isClicked={!Updown}></ItemButtonSI>;
        break;
      }
    case 'commonSiderSpecialIssueProposal':
      {
        Updown = UpdownSelector.commonSiderSpecialIssueProposal;
        typeStyle = Object.freeze({
          ...DifferentStyle.SIProposal,
          ...isUnFoldDifferentStyle.SIProposal
        }); TitleColor = DifferentTitleColor.SIProposal;
        ButtonComponent = <ItemButtonSIP ItemButtonClick={() => setUpdown(id)} LeftRight={isExtend} sx={FlexRight} isClicked={!Updown}></ItemButtonSIP>;
        break;
      }
    case 'commonSiderReport':
      {
        Updown = UpdownSelector.commonSiderReport;
        typeStyle = Object.freeze({
          ...DifferentStyle.Report,
          ...isUnFoldDifferentStyle.Report
        }); TitleColor = DifferentTitleColor.Report;
        ButtonComponent = <ItemButtonR ItemButtonClick={() => setUpdown(id)} LeftRight={isExtend} sx={FlexRight} isClicked={!Updown}></ItemButtonR>;
        break;
      }
    case 'commonSiderJournal':
      {
        Updown = UpdownSelector.commonSiderJournal;
        typeStyle = Object.freeze({
          ...DifferentStyle.Journal,
          ...isUnFoldDifferentStyle.Journal
        }); TitleColor = DifferentTitleColor.Journal;
        ButtonComponent = <ItemButtonJ ItemButtonClick={() => setUpdown(id)} LeftRight={isExtend} sx={FlexRight} isClicked={!Updown}></ItemButtonJ>;
        break;
      }
    case 'commonSiderChinaMarketing':
      {
        Updown = UpdownSelector.commonSiderChinaMarketing;
        typeStyle = Object.freeze({
          ...DifferentStyle.ChinaMarketing,
          ...isUnFoldDifferentStyle.ChinaMarketing
        }); TitleColor = DifferentTitleColor.ChinaMarketing;
        ButtonComponent = <ItemButtonCM ItemButtonClick={() => setUpdown(id)} LeftRight={isExtend} sx={FlexRight} isClicked={!Updown}></ItemButtonCM>;
        break;
      }
  };
  ButtonComponent = (isNotCNmarketing || isCNmarketingChildrenNotSelected) && ButtonComponent;
  const cloneChildrenElement = (item: ItemReactElementGenericity, propsId: RRN_String, itemProps: RRN_ItemProps) => (Updown || item.props.active) ?
    cloneElement(item, Object.freeze({
      childItemSelected: (() => childItemSelect(propsId)),
      parentId: id,
      ...(!isExtend && {
        Icon: <Tooltip title={itemProps.label} placement='right' arrow disableTouchListener>{itemProps.Icon}</Tooltip>
      })
    }))
    : null;
  if (authed) {
    return (
      <Stack
        id={id + 'RootStack'}
        justifyContent='flex-start'
        sx={{
          boxSizing: 'border-box',
          marginBottom: '8px',
          paddingBottom: '6px',
          marginLeft: '6px',
          ...ItemFolderStyle, ...isUnFoldStyle, ...typeStyle,
          ...(isExtend && !Updown && (isNotCNmarketing ? Array.isArray(children) && children.every(i => i.props.id !== currentId) : isCNmarketingChildrenNotSelected) && { gap: '0px' }),
        }
        }
        {...(isExtend && { className: styleModule.itemIcon })}
        {...others}
      >
        <Box id={id}
          className='SiderText'
          data-selenium-id={id}
          sx={{
            ...TitleStyle, color: TitleColor, boxSizing: 'border-box', paddingLeft: isExtend ? '12px' : '0px',
            marginTop: isExtend ? '6px' : '4px'  //fix bug: Menu's U&S and other first ones have an extra padding top
          }}
        >
          {label}
          {isExtend && ButtonComponent}
        </Box>
        <Stack
          justifyContent='flex-start'
          id={`${id}Stack`}
          data-selenium-id={`${id}Stack`}
          sx={{
            gap: '4px', boxSizing: 'border-box', transition: '.1s',
          }}
        >{
            isNotCNmarketing ?
              Array.isArray(children) && children.map(item => cloneChildrenElement(item, item.props.id, item.props)
              ) : isValidChildren && CNmarketingChildrenPropsId && cloneChildrenElement(children, CNmarketingChildrenPropsId, CNmarketingChildrenProps)
          }</Stack>
        {!isExtend && ButtonComponent}
      </Stack>
    );
  } else {
    return <></>;
  }
}

import { Stack } from '@mui/material';
import React from 'react';

/**
 * 传入子组件自动渲染为timeline的样子
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function SIPTimeline(props) {
  const { children } = props;

  return (
    <Stack
      rowGap='12px'
      data-selenium-id='SIPPage_Components-ProcessLog-TimeLine-Stack'
    >
      {React.Children.map(children, (child, index) => {
        const { childProps } = child;
        return React.cloneElement(child, {
          ...childProps,
          requiresConnector: index < children.length - 1,
        });
      })}
    </Stack>
  );
}

export default SIPTimeline;

import { useEffect, useState } from 'react';
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DateRangePickerX as DateRangePicker } from '../../../../components/DateRangePicker';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Dialog,
} from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import './index.css';
import OutlinedInput from '@mui/material/OutlinedInput';
import SelectDownIcon from '@/assets/SelectDownIcon.svg';
import moment from 'moment';
import CloseButton from '@/componentsphase2/CloseButton';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import {getAllSIExport, getSIExportRange} from "@/actions/SIMT-SI/SpecialIssue/Export";

const SelectDown = () => {
  return (
    <SelectDownIcon
      size='20px'
      data-selenium-id='SIPage_Overview-ExportAllSIPage-SelectDown'
    />
  );
};

function ExportAllSIPage(props) {
  const { open, handleClose } = props;

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [exportSIFormat, setExportSIFormat] = useState('');
  const [canExport, setCanExport] = useState(false);
  const dispatch = useDispatch();

  const { exportSIList } = useSelector(state => {
    return {
      exportSIList: state.SI.exportSiList,
    };
  });

  useEffect(() => {
    setFromDate(null);
    setToDate(null);
    setExportSIFormat('');
  }, [open]);

  useEffect(() => {
    if (fromDate !== null && toDate !== null && exportSIFormat !== '') {
      if (fromDate.getTime() <= toDate.getTime()) {
        setCanExport(true);
      } else {
        setCanExport(false);
      }
    } else {
      setCanExport(false);
    }
  }, [fromDate, toDate, exportSIFormat]);

  useEffect(() => {

    let tmpfilename = `SIdata-${moment().utc().format('YYYY-MM-DD HH:mm:ss')}`;
    let filename = '';
    if (exportSIList) {
      for (let i = 0; i < exportSIList.length; i++) {
        let objectSI = exportSIList[i];
        let start = objectSI['begin'];
        let end = objectSI['end'];
        let DateList = handleExportDate();
        if (exportSIList.length > 1) {
          filename = tmpfilename + `-${i + 1}.${exportSIFormat}`;
        } else {
          filename = tmpfilename + `.${exportSIFormat}`;
        }
        dispatch(
          getAllSIExport(
            DateList[0],
            DateList[1],
            start,
            end,
            exportSIFormat,
            filename
          )
        );
      }
    }

    //TODO show error when exportSIList is false, maybe to update GetSIExportRange method
    handleClose();
  }, [exportSIList]);

  const handleExportDate = () => {
    if (
      fromDate === null ||
      fromDate === undefined ||
      toDate === null ||
      toDate === undefined
    ) {
      return [null, null];
    }
    let fromYear = fromDate.getFullYear();
    let fromMonth = fromDate.getMonth() + 1;
    let fromDay = fromDate.getDate();
    let toYear = toDate.getFullYear();
    let toMonth = toDate.getMonth() + 1;
    let toDay = toDate.getDate();
    if (fromMonth < 10) {
      fromMonth = '0' + fromMonth;
    }
    if (fromDay < 10) {
      fromDay = '0' + fromDay;
    }
    if (toMonth < 10) {
      toMonth = '0' + toMonth;
    }
    if (toDay < 10) {
      toDay = '0' + toDay;
    }
    let startDate = fromYear + '' + fromMonth + '' + fromDay;
    let endDate = toYear + '' + toMonth + '' + toDay;
    return [startDate, endDate];
  };

  const handleExport = () => {
    let DateList = handleExportDate();
    dispatch(getSIExportRange(DateList[0], DateList[1]));
  };

  const handleDateRangerPick = (fromDt, toDt) => {
    setFromDate(fromDt);
    setToDate(toDt);
  };

  const getShowValue = exportSIFormat => {
    if(exportSIFormat === 'csv')return 'Export CSV';
    else if(exportSIFormat === 'xls') return 'Export EXCEL';
    return '';
  };

  return (
    <div data-selenium-id={`SIPage_Overview-ExportAllSIPage`}>
      <Dialog
        PaperProps={{
          style: {
            boxShadow: 'none',
          },
        }}
        id='dialogExportAllSIPageV2'
        data-selenium-id='SIPage_Overview-ExportAllSIPageV2-DialogExportAllSIPageV2'
        open={open}
        onClose={handleClose}
        slotProps={{
          backdrop: {
            invisible: true,
          },
        }}
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '600px',
            minHeight: '239px',
            margin: 0,
            padding: 0,
          },
          background: 'rgba(17, 61, 149, 0.5)',
          backdropFilter: 'blur(4px)',
          borderRadius: '5px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '536px',
            height: '24px',
            left: '32px',
            top: '24px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '0px',
            gap: '16px',
          }}
        >
          <span
            style={{
              fontSize: '18px',
              fontFamily: 'Open Sans',
              fontWeight: 600,
              lineHeight: '125%',
              color: '#262E35',
            }}
          >
            Export Special Issues
          </span>
          <CloseButton onClick={handleClose} />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            width: '536px',
            height: '175px',
            left: '32px',
            top: '64px',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '536px',
              height: '49px',
              left: '0px',
              top: '1px',
            }}
          >
            <InputLabel
              sx={{
                position: 'absolute',
                height: '20px',
                left: '0px',
                top: '0px',
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px',
                textTransform: 'capitalize',
                color: '#596A7C',
                paddingLeft: '5px',
              }}
            >
              <span>Selected Date</span>
              <span
                style={{
                  fontSize: '12px',
                  letterSpacing: '0.08em',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  marginLeft: '2px',
                  color: '#C40000',
                }}
              >
                *
              </span>
            </InputLabel>
            <FormControl
              variant='filled'
              sx={{
                position: 'absolute',
                width: '358px',
                height: '40px',
                left: '178px',
                top: '0px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#DFE4E8',
                },
                '& .MuiInputBase-root': { paddingRight: 0 },
              }}
            >
              <DateRangePicker
                id='ExportAllSIPageV2TimeRangeInput'
                data-selenium-id='SIPage_Overview-ExportAllSIPageV2-TimeRangeInput'
                fromDate={fromDate}
                toDate={toDate}
                maxDate={new Date()}
                handleDateChange={handleDateRangerPick}
                minHeight={40}
                width='358px'
                border='0px solid #DFE4E8'
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              width: '536px',
              height: '50px',
              left: '0px',
              top: '50px',
            }}
          >
            <InputLabel
              sx={{
                position: 'absolute',
                height: '20px',
                left: '0px',
                top: '0px',
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px',
                textTransform: 'capitalize',
                color: '#596A7C',
                paddingLeft: '5px',
              }}
            >
              <span>Format</span>
              <span
                style={{
                  fontSize: '12px',
                  letterSpacing: '0.08em',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  marginLeft: '2px',
                  color: '#C40000',
                }}
              >
                *
              </span>
            </InputLabel>
            <FormControl
              sx={{
                position: 'absolute',
                width: '358px',
                height: '40px',
                left: '178px',
                top: '0px',
              }}
            >
              <SinpleAutocomplete
                id='export-all-si-format'
                data-selenium-id='SIPage_Overview-ExportAllSIPageV2-export-all-si-format'
                placeholder=''
                value={getShowValue(exportSIFormat)}
                onChange={(e, value) => {
                  if(value === 'Export CSV') setExportSIFormat('csv');
                  else if(value === 'Export EXCEL') setExportSIFormat('xls');
                }}
                options={['Export CSV', 'Export EXCEL']}
                defaultQuery={true}
              ></SinpleAutocomplete>
              {/* <Select
                placeholder='Format *'
                input={<OutlinedInput placeholder='Format' />}
                id='export-all-si-format'
                data-selenium-id='SIPage_Overview-ExportAllSIPageV2-export-all-si-format'
                value={exportSIFormat}
                onChange={e => {
                  setExportSIFormat(e.target.value);
                }}
                IconComponent={SelectDownIcon}
                sx={{
                  height: '40px',
                  width: '358px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #DFE4E8',
                    padding: 0,
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)'
                    }
                  },
                  MenuListProps: {
                    style: {
                      paddingTop: '7px',
                      paddingBottom: '7px'
                    }
                  }
                }}
              >
                <MenuItem
                  id='ExportAllSIPageV2CSVOption'
                  data-selenium-id='SIPage_Overview-ExportAllSIPageV2-CSVOption'
                  value={'csv'}
                  sx={{ height: '30px', fontSize: '12px', color: 'var(--gray-500, #596A7C)' }}
                >
                  Export CSV
                </MenuItem>
                <MenuItem
                  id='ExportAllSIPageV2XLSOption'
                  data-selenium-id='SIPage_Overview-ExportAllSIPageV2-XLSOption'
                  value={'xls'}
                  sx={{ height: '30px', fontSize: '12px', color: 'var(--gray-500, #596A7C)' }}
                >
                  Export Excel
                </MenuItem>

              </Select> */}
            </FormControl>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              gap: '16px',
              position: 'absolute',
              width: '536px',
              height: '66px',
              left: '0px',
              top: '109px',
            }}
          >
            <Button
              variant='outlined'
              id='ExportAllSIPageV2CancelButton'
              data-selenium-id='SIPage_Overview-ExportAllSIPageV2-CancelButton'
              onClick={() => {
                handleClose();
              }}
              sx={{
                boxSizing: 'border-box',
                padding: '8px 12px',
                gap: '6px',
                position: 'absolute',
                width: '70px',
                height: '34px',
                left: '387px',
                top: '12px',
                border: '1px solid #0052CC',
                borderRadius: '5px',
                textTransform: 'none',
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              id='ExportAllSIPageV2ExportButton'
              data-selenium-id='SIPage_Overview-ExportAllSIPageV2-ExportButton'
              disabled={!canExport}
              disableElevation
              onClick={handleExport}
              sx={{
                boxSizing: 'border-box',
                padding: '8px 12px',
                gap: '6px',
                position: 'absolute',
                width: '70px',
                height: '34px',
                left: '466px',
                top: '12px',
                borderRadius: '5px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#6D98EE',
                },
                '&:active': {
                  backgroundColor: '#113D95',
                },
                backgroundColor: '#0052CC',
              }}
            >
              Export
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}

export default ExportAllSIPage;

import React, { useEffect, useState, useRef } from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { CreateGETextFieldBase } from './CustomTextField';
import { useDispatch, useSelector } from 'react-redux';
import { trimItemFilds } from '@/utils/commonUtils';
import { is } from 'date-fns/locale';
import { AddSelectItem } from './CustomMenuItem';
import { openConfirm, setEditType, setTextParam } from '@/actions/SIMT-User/Alert/AlertAction';
import {
  CreateGEAutocompleteChipDeleteIcon,
  createGEAutocompleteChipStyle,
  CustomDropDownIcon,
} from './CustomIcon';
import Off from '../../../../../assets/Off.svg';
import Arrow from '../../../../../assets/Arrow1.svg';
import Error from '../../../../../assets/requiredError.svg';
import Error500_12 from '../../../../../assets/Error500_12.svg';
import Warning500_12 from '../../../../../assets/Warning500_12.svg';
import Error500_6 from '../../../../../assets/Error500_6.svg';
import Warning500_6 from '../../../../../assets/Warning500_6.svg';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DefaultCheckIcon from '../../../../../assets/DefaultCheckIcon.svg';
import CheckedIcon from '../../../../../assets/CheckedIcon.svg';
import { LimitWord } from '@/componentsphase2/Autocomplete/CommonStyle';
import { MiniFont, SmallBoldFont } from '@/components/FontStyle';
import {
  MulSelectInput,
  SelectInput,
} from '@/componentsphase2/SelectBox/SelectInput';
import ItemBlock from '@/componentsphase2/Itemblock';

/** @type {import("./CustomAutocomplete").CreateGEAutocompleteBaseFC} */

export const CreateGEAutocompleteBase = props => {
  const {
    error,
    helperText,
    label,
    required,
    multiple,
    // sx,
    ...others
  } = props;

  const [statusError, setStatusError] = useState(error);

  // const autoCompleteRef = useRef(null);
  // const [clearShow, setClearShow] = useState(true);

  // useEffect(() => {
  //   if (autoCompleteRef.current.offsetHeight === 64) setClearShow(false);
  //   else setClearShow(true);
  // });

  // const [controlledPlaceholder, setControlledPlaceholder] =
  //   useState(placeholder);
  // useEffect(() => {
  //   if (value?.length !== 0) setControlledPlaceholder('');
  //   else setControlledPlaceholder(placeholder);
  // }, [placeholder, value]);

  return (
    // <Autocomplete
    //   ref={autoCompleteRef}
    //   id={`create-GE-autocomplete-${label || ''}`}
    //   data-selenium-id={`GEPage_Overview-CreateGE-CustomAutocomplete-CreateGEAutocompleteBase${
    //     label || ''
    //   }-Autocomplete`}
    //   value={value}
    //   renderInput={params => {
    //     const { InputLabelProps, ...others } = params;
    //     return (
    //       <CreateGETextFieldBase
    //         {...others}
    //         multiline = {multiline}
    //         label={label}
    //         required={required}
    //         error={error}
    //         helperText={helperText}
    //         placeholder={controlledPlaceholder}
    //       />
    //     );
    //   }}
    //   ChipProps={{
    //     deleteIcon: <Off width={12} height={12} style={{ margin: 0 }} />,
    //     sx: createGEAutocompleteChipStyle,
    //   }}
    //   options={options}
    //   // popupIcon={<CustomDropDownIcon />}
    //   clearIcon={<Off />}
    //   popupIcon={<Arrow />}
    //   ListboxProps={{
    //     sx: {
    //       maxHeight: '200px',
    //       py: '6px',
    //       display:'grid',
    //       // flexDirection:'column',
    //       gap:'4px',
    //       '& li[aria-selected="true"]': {
    //         backgroundColor: '#FFFFFF !important',
    //       },
    //       '& li[aria-selected="true"]:hover': {
    //         backgroundColor: '#F1F3F5 !important',
    //       },
    //       '& li': {
    //         height:'16px',
    //         padding: '0px 12px !important',
    //         boxSizing: 'border-box',
    //         ...MiniFont,
    //         lineHeight: '16px',
    //         color: '#596A7C',
    //         ...LimitWord,
    //       },
    //       '& li:hover': {
    //         backgroundColor: '#F1F3F5',
    //       },
    //     },
    //   }}
    //   componentsProps={{
    //     paper: {
    //       sx: {
    //         paddingY: 0,
    //         transform: 'translateY(6px)',
    //         boxShadow: '0px 6px 12px rgba(38, 46, 53, 0.12)',
    //         borderRadius: '5px',
    //         width: '400px',
    //       },
    //     },
    //     popper: {
    //       sx: {
    //         zIndex: 1401,
    //       },
    //     },
    //   }}
    //   // sx={
    //   //   sx && {
    //   //     ...sx,
    //   //     '& .MuiAutocomplete-clearIndicator': {
    //   //       ...sx['& .MuiAutocomplete-clearIndicator'],
    //   //       display: clearShow ? 'flex' : 'none',
    //   //     },
    //   //   }
    //   // }
    //   sx={
    //     {
    //       '& .MuiAutocomplete-clearIndicator': {
    //         '& .MuiTouchRipple-root':{
    //           display:'none'
    //         }
    //       },
    //       '& .MuiAutocomplete-clearIndicator:hover': {
    //         backgroundColor: '#FFFFFF',
    //       },
    //       ...sx ?? {}
    //     }
    //   }
    //   {...others}
    // />
    <ItemBlock
      id={props.id}
      mode={0}
      isError={statusError}
      required={required}
      title={label}
      errorText={helperText}
      stackProps={{
        first: {
          spacing: 0.5,
        },
      }}
      titleProps={{
        titleSX: {
          ...SmallBoldFont,
          //styleName: small bold;
          lineHeight:'20px',
          letterSpacing: '0.01em',
          color: '#596A7C',
        },
        stackProps: {
          spacing: 0.25,
          direction: 'row',
        },
      }}
    >
      {multiple ? (
        <MulSelectInput
          id={`create-GE-autocomplete-${label || ''}`}
          error={error}
          setError={setStatusError}
          // sx={{
          //   '& .MuiAutocomplete-clearIndicator':{
          //     position: 'absolute',
          //     right: 6, // 将 ClearIndicator 放置到右侧
          //     top: 11,   // 将 ClearIndicator 放置到顶部
          //   }
          // }}
          // data-selenium-id={`GEPage_Overview-CreateGE-CustomAutocomplete-CreateGEAutocompleteBase${
          //   label || ''
          // }-Autocomplete`}
          {...others}
        />
      ) : (
        <SelectInput
          id={`create-GE-autocomplete-${label || ''}`}
          error={error}
          setError={setStatusError}
          // data-selenium-id={`GEPage_Overview-CreateGE-CustomAutocomplete-CreateGEAutocompleteBase${
          //   label || ''
          // }-Autocomplete`}
          {...others}
        />
      )}
    </ItemBlock>
  );
};

/**
 * @type {import("./CustomAutocomplete").CreateGEAddItemAutocompleteFC}
 */
export const CreateGEAddItemAutocomplete = props => {
  // 由于对 Autocomplete 封装产生的一个问题，暂时只提供这几个属性的传递，之后如果有需要再加上
  const {
    label,
    loadOptionAction,
    onChange = () => {},
    value,
    stringLabelKey,
    options,
    addNewItemManager = {},
    multiple,
    required,
    setError = () => {},
    showErrorMessage,
    showPopupIcon,
    showClearIcon,
    placeholder,
    optionPlaceholderValue,
    ...others
  } = props;

  const dispatch = useDispatch();
  /* ------------------------ Required 功能 ------------------------ */

  const [requiredError, setRequiredError] = useState(false);
  useEffect(() => {
    if (required) {
      if (multiple) {
        // 多值时， value 是一个数组
        if (value !== null && value !== undefined && Array.isArray(value))
          setRequiredError(value.length === 0);
      } else {
        setRequiredError(value === null || value === undefined);
      }
    }
  }, [value]);

  useEffect(() => {
    setError(requiredError);
  }, [requiredError]);

  const getErrorMessage = () => {
    if (requiredError) return 'Please enter this field.';
    return '';
  };

  return (
    <CreateGEAutocompleteBase
      value={value}
      options={options}
      // options={[]}
      getOptionLabel={option => option[stringLabelKey]}
      defaultOption={key => optionPlaceholderValue} //我在查看 源代码发现这个函数的返回结果似乎不那么重要
      loadOptionAction={value => {
        dispatch(loadOptionAction(value));
      }}
      onValueChange={onChange}
      disableNewItem={false}
      addNewItemManager={addNewItemManager}
      showPopupIcon={showPopupIcon}
      placeholder={placeholder}
      // disableClearable={!showClearIcon}
      label={label}
      required={required}
      error={showErrorMessage && requiredError}
      helperText={showErrorMessage && getErrorMessage()}
      multiple={multiple}
      data-selenium-id={`GEPage_Overview-CreateGE-CustomAutocomplete-CreateGEAddItemAutocomplete${label}-CreateGEAutocompleteBase`}
    />
  );
};

/**
 * @type {import("./CustomAutocomplete").CreateGEAutocompleteFC}
 */
export const CreateGEAutocomplete = props => {
  const {
    label,
    multiple = false,
    multiline = false,
    value,
    options,
    required,
    onChange,
    onInputChange,
    loadOptionAction,
    showErrorMessage,
    showPopupIcon = true,
    defaultOption = () => {},
    showClearIcon = true,
    setError = () => {},
    getOptionLabel,
    ...others
  } = props;

  const [requiredError, setRequiredError] = useState(false);
  useEffect(() => {
    if (required) {
      if (multiple) {
        // 多值时， value 是一个数组
        if (value !== null && value !== undefined && Array.isArray(value))
          setRequiredError(value.length === 0);
      } else {
        setRequiredError(value === null || value === undefined);
      }
    }
  }, [value]);

  useEffect(() => {
    setError(requiredError);
  }, [requiredError]);

  const getErrorMessage = () => {
    if (requiredError) return 'Please enter this field.';
    return '';
  };

  return (
    <CreateGEAutocompleteBase
      label={label}
      value={value}
      options={options}
      getOptionLabel={getOptionLabel}
      // disableClearable={!showClearIcon}
      required={required}
      onValueChange={onChange}
      disableNewItem={true}
      multiple={multiple}
      showPopupIcon={showPopupIcon}
      defaultOption={key => {}}
      // multiline={multiline}
      error={showErrorMessage && requiredError}
      helperText={showErrorMessage && getErrorMessage()}
      filterOptions={(options, params) => {
        let newOptions = [];
        for (let i = 0; i < options.length; i++) {
          let option = options[i];
          if (option?.id === -100) {
            continue;
          }
          if (getOptionLabel(option).includes(params.inputValue)) {
            newOptions.push(option);
          }
        }

        return newOptions;
      }}
      {...others}
      data-selenium-id={`GEPage_Overview-CreateGE-CustomAutocomplete-CreateGEAutocomplete-CreateGEAutocompleteBase`}
    />
  );
};

export const FlagAutocomplete = props => {
  // 由于对 Autocomplete 封装产生的一个问题，暂时只提供这几个属性的传递，之后如果有需要再加上
  const {
    label,
    loadOptionAction,
    onChange = () => {},
    value,
    stringLabelKey,
    options,
    optionPlaceholderValue,
    addNewItemManager: {
      newData,
      itemExist,
      dataGet,
      addNewOption,
      afterAddNewOption,
    },
    multiple,
    required,
    setError = () => {},
    showErrorMessage,
    showPopupIcon,
    showClearIcon,
    ...others
  } = props;

  const dispatch = useDispatch();

  /* ------------------------ Required 功能 ------------------------ */

  const [requiredError, setRequiredError] = useState(false);
  useEffect(() => {
    if (required) {
      if (multiple) {
        // 多值时， value 是一个数组
        if (value !== null && value !== undefined && Array.isArray(value))
          setRequiredError(value.length === 0);
      } else {
        setRequiredError(value === null || value === undefined);
      }
    }
  }, [value]);

  useEffect(() => {
    setError(requiredError);
  }, [requiredError]);

  const getErrorMessage = () => {
    if (requiredError) return 'Please enter this field.';
    return '';
  };

  /* ------------------------ 后端搜索 ------------------------ */

  const [delayLoadTimer, setDelayLoadTimer] = useState(
    /** @type {NodeJS.Timeout | null} */ (null)
  );
  const [inputValue, setInputValue] = useState('');
  const [optionsToShow, setOptionsToShow] = useState(options);
  const [newDataCache, setNewDataCache] = useState(value);
  const [loading, setLoading] = useState(false);
  const [loadingStateTimer, setLoadingStateTimer] = useState(
    /** @type {NodeJS.Timeout | null} */ (null)
  );

  const ADD_ITEM_ID = -100;
  const DELAY_LOAD_TIME = 300; // 0.3s
  const LOAD_TIME_OUT = 10000; // 10s
  /**
   * @param {string} value
   */
  const delayLoadOptions = value => {
    if (loadOptionAction === undefined) return;

    // 停止输入 ${DELAY_LOAD_TIME}ms 后开始查询
    if (delayLoadTimer) clearTimeout(delayLoadTimer);
    setDelayLoadTimer(
      setTimeout(async () => {
        // 加载
        dispatch(loadOptionAction(trimItemFilds(value)));
      }, DELAY_LOAD_TIME)
    );
  };

  const configTimeoutLoading = () => {
    // 如果 ${LOAD_TIME_OUT} 后计时器没有取消，说明超时，取消 loading 状态
    // 显示 add item 选项
    setLoading(true);
    setOptionsToShow([]);
    if (loadingStateTimer) clearTimeout(loadingStateTimer);
    setLoadingStateTimer(
      setTimeout(() => {
        setOptionsToShow(current => {
          if (current.length === 0)
            return [
              {
                ...optionPlaceholderValue,
                id: ADD_ITEM_ID,
              },
            ];
          return current;
        });
        setLoading(false);
      }, LOAD_TIME_OUT)
    );
  };

  const checkIfInputValueExistingInOptions = () => {
    return (
      options &&
      Array.isArray(options) &&
      options.some(
        item =>
          trimItemFilds(`${item[stringLabelKey]}`) === trimItemFilds(inputValue)
      )
    );
  };

  const getOptionsToShowByOptionsAndInputValue = () => {
    return !checkIfInputValueExistingInOptions()
      ? [
          ...options,
          {
            ...optionPlaceholderValue,
            id: ADD_ITEM_ID,
          },
        ]
      : options;
  };

  useEffect(() => {
    // options 变更时，重新计算需要展示的 options
    const newOptionsToShow = getOptionsToShowByOptionsAndInputValue();
    setOptionsToShow(newOptionsToShow);

    // options 更新时，需要取消 loading 状态
    // 同时清空检测 loading 超时的计时器
    // 注意清空 loadingStateTimer 前一定要将 loading 状态设为 false
    setLoading(false);
    if (loadingStateTimer) clearTimeout(loadingStateTimer);
    setLoadingStateTimer(null);

    // options 更新时，若发现 newDataCache 不是 undefine，说明此次更新是添加新 item 导致的，
    // 可以直接把新 item 设为值，并清空缓存
    if (newDataCache) {
      onChange(newDataCache);
      setNewDataCache(undefined);
    }
  }, [options]);

  /* ------------------------ Add 功能 ------------------------ */
  const handleAddNewItem = () => {
    if (addNewOption === undefined) return;
    addNewOption(trimItemFilds(inputValue));
  };

  useEffect(() => {
    // 当新获得新的有效 item 时，将其添加至 optionsToShow ，
    // 同时存入缓存，等待 option 刷新

    if (dataGet === 'success') {
      // setOptionsToShow((current) => {
      //   return [newData, ...current];
      // });

      if (multiple) {
        // 理论上 multiple = true 时， newDataCache 应该是 T[] 的父类，可以兼容
        // 但此处类型推导失效，若产生 bug 应留意
        if (value !== null && value !== undefined && Array.isArray(value))
          setNewDataCache([...value, newData]);
      } else {
        setNewDataCache(newData);
      }

      dispatch(setTextParam(`${newData[stringLabelKey]}`));
      dispatch(setEditType(label));
      dispatch(openConfirm());

      if (loadOptionAction !== undefined) {
        const newInputValue = trimItemFilds(inputValue);
        dispatch(loadOptionAction(newInputValue));
      }
    }

    if (dataGet === 'success' || dataGet === 'error') {
      if (afterAddNewOption !== undefined) afterAddNewOption();
    }
  }, [dataGet]);

  const optionWhenDev = [
    {
      degree: 'severe',
      id: 1,
      flag: 'Papermills',
    },
    {
      degree: 'severe',
      id: 2,
      flag: 'Peer review manipulation',
    },
    {
      degree: 'mild',
      id: 3,
      flag: 'Unresponsive',
    },
  ];

  const [inputValue1, setInputValue1] = useState('');

  return (
    <CreateGEAutocompleteBase
      value={value}
      options={[
        ...optionsToShow.filter(item => item.key === 'severe'),
        ...optionsToShow.filter(item => item.key === 'mild'),
      ]}
      // options={optionWhenDev}
      freeSolo={false}
      onChange={(event, value, reason, details) => {
        onChange(value);
      }}
      inputValue={inputValue1}
      onInputChange={(event, value) => {
        setInputValue1('');
        return;
        //zhaoyicheng sidetial add new GE奇怪的bug flag没内容
        // Autocomplete 的 inputValue 变更时 需要
        // 1. 同步存储的 inputValue
        setInputValue(value);
        // 2. 设置 loading 状态 ，若超过 ${LOAD_TIME_OUT} ms 后 loading 计时器还没有取消
        // 视作加载超时，取消 loading 状态
        configTimeoutLoading();
        // 3. 延迟加载 option ，停止输入 ${DELAY_LOAD_TIME} ms 才从后端加载 options
        delayLoadOptions(value);
      }}
      getOptionLabel={option => {
        return `${option[stringLabelKey]}`;
      }}
      isOptionEqualToValue={(option, value) => {
        if (option.id === ADD_ITEM_ID) return false;
        return (
          trimItemFilds(`${option[stringLabelKey]}`) ===
          trimItemFilds(`${value[stringLabelKey]}`)
        );
      }}
      renderOption={(optionProps, option, { selected }) => {
        if (option[stringLabelKey] === '') return <></>;
        if (option.id === ADD_ITEM_ID) return <></>;
        return (
          <li
            {...optionProps}
            style={{
              height: '24px',
              paddingY: '2px',
              lineHeight: '20px',
              fontSize: '12px',
              fontWeight: 400,
              fontFamily: 'Open Sans',
              color: '#484848',
              padding: 0,
            }}
          >
            <Checkbox
              key={option.id}
              icon={<DefaultCheckIcon />}
              checkedIcon={<CheckedIcon />}
              style={{ padding: 0, marginRight: 8 }}
              checked={selected}
            />
            <Tooltip
              title={option[stringLabelKey]}
              disableInteractive
              leaveTouchDelay={0}
              followCursor={true}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {option[stringLabelKey]}
              </span>
            </Tooltip>
          </li>
        );
      }}
      filterOptions={x => x}
      label={label}
      loading={loading}
      multiple={multiple}
      required={required}
      error={showErrorMessage && requiredError}
      helperText={
        showErrorMessage &&
        getErrorMessage() !== '' && (
          <Box display={'flex'} alignItems={'center'} gap={'1px'}>
            <Error />
            {getErrorMessage()}
          </Box>
        )
      }
      forcePopupIcon={showPopupIcon}
      disableClearable={!showClearIcon}
      sx={
        multiple && {
          '& .MuiOutlinedInput-root': {
            pl: '6px',
            pr: '39px !important',
          },
          '& .MuiAutocomplete-endAdornment': {
            height: '14px',
            top: '12px',
            right: '10px !important',
            padding: '0px',
            // pb: '11px',
            display: 'flex',
            // flexDirection: 'column-reverse',
            flexDirection: 'column',
            // justifyContent: 'space-between',
            alignItems: 'center',
            '& button': {
              height: '16px',
              width: '16px',
              padding: 0,
              borderRadius: '2px',
              '&:hover': {
                backgroundColor: '#DFE4E8',
              },
            },
          },
          // '& .MuiAutocomplete-popupIndicator': {
          //   display: 'flex',
          //   justifyContent: 'center',
          //   alignItems: 'center',
          //   position: 'unset',
          //   height: '16px',
          //   width: '20px',
          // },
          '& .MuiAutocomplete-popupIndicator': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '16px',
            width: '16px',
            padding: 0,
          },
          '& .MuiAutocomplete-clearIndicator': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '16px',
            width: '16px',
            padding: 0,
          },
          // '& .MuiAutocomplete-tag': {
          //   padding: '5px 8px 5px 12px !important',
          //   gap: '6px',
          //   height: '22px',
          //   border: '1px solid #BCC5CF',
          //   borderRadius: '4px',
          //   background: '#fff !important',
          //   '& svg': {
          //     width: '7px !important',
          //     height: '7px !important',
          //   },
          //   '&:hover': {
          //     background: '#DFE4E8 !important',
          //     border: '1px solid #98A7B6',
          //     fontWeight: 600,
          //   },
          //   '& .MuiChip-deleteIcon:hover': {
          //     background: '#BCC5CF',
          //     borderRadius: '2px',
          //   },
          // },
        }
      }
      ListboxProps={{
        sx: {
          p: '12px',
          maxHeight: 999999999,
        },
      }}
      groupBy={option => option.key}
      renderGroup={params => (
        <Box>
          <Box height={'16px'} mb={'6px'}>
            <Box display={'flex'} alignItems={'center'} gap='6px'>
              {params.group === 'severe' ? <Error500_12 /> : <Warning500_12 />}
              <Typography
                fontFamily={'Open Sans'}
                fontWeight={'600'}
                fontSize={'12px'}
                lineHeight={'16.34px'}
              >
                {params.group === 'severe' ? 'SEVERE ISSUE' : 'MILD ISSUE'}
              </Typography>
            </Box>
          </Box>
          <ul
            style={{
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
            }}
          >
            {params.children}
          </ul>
          {params.group === 'severe' && (
            <Divider
              sx={{ my: '12px', width: '376px', border: '0.5px solid #DFE4E8' }}
            />
          )}
        </Box>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Box
            key={index}
            {...getTagProps({ index })}
            sx={{
              boxSizing: 'border-box',
              height: '24px',
              p: '5px 6px',
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
              borderRadius: '4px',
              border: '1px solid #BCC5CF',
              color: '#484848',
              fontFamily: 'Open Sans',
              fontSize: '12px',
              lineHeight: '20px',
              cursor: 'default',
              m: '2px 1px !important',
              '&:hover': {
                background: '#DFE4E8',
                border: '1px solid #98A7B6',
                fontWeight: 600,
                color: '#596A7C',
                lineHeight: '18px',
                '& svg circle': {
                  fill: option.key === 'severe' ? '#EE5350' : '#FF8A00',
                },
                '& svg:last-child:hover': {
                  background: '#BCC5CF',
                },
              },
            }}
          >
            {option.key === 'severe' ? (
              <Error500_6 width={6} height={6} />
            ) : (
              <Warning500_6 width={6} height={6} />
            )}
            {option[stringLabelKey]}
            <Off
              width={12}
              height={12}
              onClick={() => {
                const newValue = [...value];
                newValue.splice(index, 1);
                onChange(newValue);
              }}
              cursor={'pointer'}
              style={{ borderRadius: '2px' }}
            />
          </Box>
        ))
      }
      {...others}
      data-selenium-id={`GEPage_Overview-CreateGE-CustomAutocomplete-CreateGEAddItemAutocomplete${label}-CreateGEAutocompleteBase`}
    />
  );
};

export function ItemBlockInstence(props) {
  const { id, error, helperText, label, required } = props;
  return (
    <ItemBlock
      id={id}
      mode={0}
      isError={error}
      required={required}
      title={label}
      errorText={helperText}
      stackProps={{
        first: {
          spacing: 0.5,
        },
      }}
      titleProps={{
        titleSX: {
          ...SmallBoldFont,
          //styleName: small bold;
          lineHeight:'20px',
          letterSpacing: '0.01em',
          color: '#596A7C',
        },
        stackProps: {
          spacing: 0.25,
          direction: 'row',
        },
      }}
    >
      {props.children}
    </ItemBlock>
  );
}

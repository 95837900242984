import {
    ChecklistData, extractHtmlId,
    Form, MapState,
    RelativePathForm,
    RootForm,
    UiComponent,
    useFormContext,
    userFormState
} from "@/components/Form/form.d";
import SIPChecklistHeader from "@/pages/SIPPage/SIPCheckListPage/configable/SIPChecklistHeader";
import CheckListItemForImmediateDecision from "@/pages/SIPPage/SIPCheckListPage/configable/ImmdeiateDecsionSection";
import {SubFormWrapper} from "@/pages/SIPPage/SIPCheckListPage/configable/SubFormWrapper";
import {CheckListItem, LinkedCheckListItem, ReverseCommentCheckListItem} from "@/pages/SIPPage/SIPCheckListPage/configable/CheckListItem";
import {CheckListItemRanger} from "@/pages/SIPPage/SIPCheckListPage/configable/CheckListItemRanger";
import {SubFormGroupWrapper} from "@/pages/SIPPage/SIPCheckListPage/configable/SubFormGroupWrapper";
import {CheckListGEFlagTable} from "@/pages/SIPPage/SIPCheckListPage/configable/CheckListGEFlagTable";
import {CheckListGEFlagAddition} from "@/pages/SIPPage/SIPCheckListPage/configable/CheckListGEFlagAddition";
import {OverAllCommentsSection} from "@/pages/SIPPage/SIPCheckListPage/configable/OverAllCommentsSection";
import React, {useEffect, useState} from "react";
import {Stack} from "@mui/system";
import {Box, Dialog, Typography} from "@mui/material";
import {StateButton} from "@/componentsphase2/StateButton";
import {getSIPChecklistDetail} from "@/actions/SIMT-SIP/SIPChecklistSubmitted";
import {
    CANCEL_EDIT, iniSIPChecklistReducer
} from "@/actions/SIMT-SIP/SIPChecklistSubmitted/SIPChecklistAction";
import { ChecklistStatus } from "@/components/Form/form.d";
import {CheckListPanel} from "@/pages/SIPPage/SIPCheckListPage/configable/CheckListPanel";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {loadComponentsByArea} from "@/actions/UiComponentAction";
import {EMPTY_CHECKLIST_DATA} from "@/reducers/SIPChecklistReducer";
import {CheckListItemSlider} from "@/pages/SIPPage/SIPCheckListPage/configable/CheckListItemSlider";
import SIPChecklistSnackbar from "@/pages/SIPPage/SIPCheckListPage/configable/SIPChecklistSnackbar";
import {getFlaglist} from "@/actions/SIMT-SI/GuestEditor/Config";
import {
    CHECK_CANCEL,
    CHECKLIST_EDITED, CHECKLIST_PROCEEDING,
    SNACKBAR_ID,
    SNACKBAR_SHOW
} from "./constants";
import { excuteCancelEdit, INFO_NOT_SAVED } from "./actions";

export interface SIPCheckListPageProps {
    componentVersion: number;
    checkListItems: UiComponent<any>[];
}

export interface SIPCheckListLayoutPageProps {
    sipCode?: string;
    sipStage?: string;
    getWindow?(): {
        [key: string]: any
    };
}

export interface StaticSIPCheckListPageProps {
    outerData?: ChecklistData;
}

export function mapAsFormComponent(item: UiComponent<any>, fullData: UiComponent<any>[], parentId?: string, index?:number) {
    const props = {
        name: item.componentKey,
        ...item.properties,
        ['parent-html-id']: parentId,
        ['html-index']: index,
    };
    switch (item.componentType) {
        case 'CheckListHeader':
            return (<SIPChecklistHeader {...props} />);
        case 'CheckListItemForImmediateDecision':
            return (<CheckListItemForImmediateDecision {...props} />);
        case 'SubForm':
            return (<SubFormWrapper {...props}>
                {
                    fullData.filter(subItem => subItem.area === item.properties.subArea)
                        .map((subItem) => mapAsFormComponent(subItem, fullData, extractHtmlId(props)))
                }
            </SubFormWrapper>);
        case 'CheckListItem':
            return (<CheckListItem {...props} />);
        case 'ReverseCommentCheckListItem':
            return (<ReverseCommentCheckListItem {...props} />);
        case 'LinkedCheckListItem':
            return (<LinkedCheckListItem {...props} />);
        case 'CheckListItemRanger':
            return (<CheckListItemRanger {...props} />);
        case 'CheckListItemSlider':
            return (<CheckListItemSlider {...props} />);
        case 'SubFormGroup':
            return <SubFormGroupWrapper {...props}>
                {
                    fullData.filter(subItem => subItem.area === item.properties.subArea)
                        .map((subItem) => mapAsFormComponent(subItem, fullData, extractHtmlId(props)))
                }
            </SubFormGroupWrapper>;
        case 'RelativePathForm':
            return <RelativePathForm {...props}>
                {
                    fullData.filter(subItem => subItem.area === item.properties.subArea)
                        .map((subItem) => mapAsFormComponent(subItem, fullData, extractHtmlId(props)))
                }
            </RelativePathForm>;
        case 'CheckList_GE_FlagTable':
            return (<CheckListGEFlagTable {...props}/>);
        case 'CheckList_GE_FlagAddition':
            return (<CheckListGEFlagAddition {...props}/>);
        case 'OverAllCommentsSection':
            return (<OverAllCommentsSection {...props}/>);
        default:
            return <>Unknown Component {item.componentType}</>;
    }
}

function checkUpdate(checklist: MapState) {
    for(const key in checklist){
        if (key.startsWith('__')){
            const origin = checklist[key]?.origin;
            const current = checklist[key.substring(2)];
            if (origin !== current){
                return true;
            }
            if (checklist[key] instanceof Array){
                for (const item of checklist[key]){
                    if (typeof item === 'object'){
                        if (checkUpdate(item)){
                            return true;
                        }
                    }
                }
            } else if (typeof checklist[key] === 'object'){
                if (checkUpdate(checklist[key])){
                    return true;
                }
            }
        }
    }
    return false;
}

export function SIPCheckListPage(props: SIPCheckListPageProps) {
    const { componentVersion, checkListItems} = props;

    const currentCheckListItems = checkListItems.filter(item => item.componentVersion === componentVersion);

    const form = useFormContext();
    const checklist = form.root();

    const status = checklist.status;
    const locked = checklist.locked;
    const editable = status === ChecklistStatus.IN_PROGRESS && !locked;

    const proceedResult = checklist._proceedResult ?? null;

    const [checkCancel, setCheckCancel] = userFormState<boolean>(form, CHECK_CANCEL);
    const hasFormChange : boolean = form.get(CHECKLIST_EDITED) ?? false;

    const navigate = useNavigate();
    useEffect(() => {
        if (proceedResult !== null && proceedResult?.status === ChecklistStatus.COMPLETED){
            const immediateDecision = proceedResult?.immediateDecision;
            const sipCode = proceedResult?.sipCode;
            const checkListResult = immediateDecision ?? ChecklistStatus.COMPLETED;
            navigate(
                `/simt/auth/sipRelated/sip/detail/${sipCode}?checkListResult=${checkListResult}`
            );
        }
    }, [proceedResult]);

    const PopWindow = () => {
        return (
            <Dialog
                PaperProps={{
                    style: {
                        boxShadow: 'none',
                    },
                }}
                slotProps={{
                    backdrop: {
                        invisible: true,
                    },
                }}
                open={checkCancel}
                sx={{
                    background: 'rgba(17, 61, 149, 0.5)',
                    backdropFilter: 'blur(4px)',
                    '& .MuiDialog-paper': {
                        width: '600px',
                        minHeight: '152px',
                        pt: '24px',
                        px: '32px',
                        m: 0,
                        borderRadius: '5px',
                    },
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        fontWeight: 600,
                        fontSize: '18px',
                        lineHeight: '22.5px',
                        textAlign: 'center',
                    }}
                >
                    Your input has not been saved yet. <br></br>Are you sure you want to
                    cancel your action?
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        paddingTop: '28px',
                        paddingLeft: '193px',
                        gap: '16px!important',
                    }}
                >
                    <StateButton
                        titleName='No'
                        isText={true}
                        sx={{
                            height: '34px',
                            width: '70px',
                            border: '1px solid #0052CC!important',
                            borderRadius: '5px!important',
                            fontWeight: 600,
                            lineHeight: '18px',
                        }}
                        onClick={() => {
                            setCheckCancel(false);
                        }}
                    />
                    <StateButton
                        titleName='Yes'
                        sx={{
                            height: '34px',
                            width: '70px',
                            borderRadius: '5px!important',
                            fontWeight: 600,
                            lineHeight: '18px',
                        }}
                        onClick={() => {
                            setCheckCancel(false);
                            excuteCancelEdit(form.dispatch, checklist, checklist.sipCode);
                        }}
                    />
                </Box>
            </Dialog>
        );
    };

    return (
            <>
                <Stack
                    sx={{
                        padding: '32px 98px 32px 102px',
                    }}
                    direction='column'
                    spacing={4}
                >
                    {currentCheckListItems.filter(item => item.area === 'SIP_Checklist')
                        .map((item) => {
                            return mapAsFormComponent(item, currentCheckListItems);
                        })}
                </Stack>

                {editable && <Box sx={{ height: '60px' }}></Box>}

                <PopWindow />

                {editable &&
                    !checkCancel && (
                        <CheckListPanel
                            hasFormChange={hasFormChange}
                            setCheckCancel={setCheckCancel}
                            sipCode={checklist.sipCode}
                        />
                    )}
                <SIPChecklistSnackbar/>
            </>
    );
}

export function SIPCheckListLayoutPage(props: SIPCheckListLayoutPageProps) {
    const { sipCode: paramSipCode, sipStage: paramSipStage } = useParams();

    const {
        sipCode = paramSipCode,
        sipStage = paramSipStage,
        getWindow = () => window,
    } = props;

    const businessType = sipCode?.startsWith('SSIP')?'SSIP':'USIP';

    const dispatch = useDispatch();

    const localWindow = getWindow();

    const selector = (state :{
        SIPChecklist: {
            SIPChecklistDetail: ChecklistData;
            SnackbarId: number;
            [key: string]: any;
        };
        UiComponents: {
            [area: string]: UiComponent<any>[];
        };
        [key: string]: any;
    }) => {
        return {
            SIPChecklistDetail: state.SIPChecklist.SIPChecklistDetail,
            SnackbarId: state.SIPChecklist.SnackbarId,
            UiComponents: state.UiComponents,
        };
    };
    const { SIPChecklistDetail, UiComponents } = useSelector(selector);
    useEffect(() => {
        console.log("SIPChecklistDetail",SIPChecklistDetail);
        localWindow.SIPChecklistStatus = SIPChecklistDetail.status;
        localWindow.SIPChecklistVersion = SIPChecklistDetail.version;
        localWindow.locked = SIPChecklistDetail.locked;
    }, [SIPChecklistDetail]);


    useEffect(() => {
        localWindow.SIPCode = sipCode;
    }, [sipCode]);
    useEffect(() => {
        localWindow.SIPStage = sipStage;
    }, [sipStage]);

    React.useEffect(() => {
        dispatch(iniSIPChecklistReducer());
        dispatch(getSIPChecklistDetail(sipCode,  sipStage));
    },  [sipCode, sipStage]);

    React.useEffect(() => {
        dispatch(loadComponentsByArea('SIP_Checklist', businessType));
        dispatch(loadComponentsByArea('SIP_Checklist_For_SIP',businessType));
        dispatch(loadComponentsByArea('SIP_Checklist_For_GE',businessType));
        dispatch(loadComponentsByArea('SIP_Checklist_For_GE_Flag',businessType));

        dispatch(getFlaglist());
    }, []);

    const checklist: ChecklistData = {
        ...SIPChecklistDetail,
        [CHECKLIST_PROCEEDING]: false,
        [CHECK_CANCEL]: false,
        [SNACKBAR_SHOW]: false,
        [CHECKLIST_EDITED]: false,
        [SNACKBAR_ID]: null,
    };

    const checkListItems = [
        ... UiComponents['SIP_Checklist'] ?? [],
        ... UiComponents['SIP_Checklist_For_SIP'] ?? [],
        ... UiComponents['SIP_Checklist_For_GE'] ?? [],
        ... UiComponents['SIP_Checklist_For_GE_Flag'] ?? [],
    ];

    return (
        <RootForm defaultValue={checklist} globalDispatch={dispatch}>
            <SIPCheckListPage
                componentVersion={checklist.checklistVersion}
                checkListItems={checkListItems}
            />
        </RootForm>
    );
}

export function StaticSIPCheckListPage(props: StaticSIPCheckListPageProps) {
    const dispatch = useDispatch();
    const selector = (state :{
        SIPChecklist: {
            [key: string]: any;
        };
        UiComponents: {
            [area: string]: UiComponent<any>[];
        };
        [key: string]: any;
    }) => {
        return {
            UiComponents: state.UiComponents,
        };
    };

    const { UiComponents } = useSelector(selector);
    const businessType = props.outerData?.sipCode?.startsWith('SSIP')?'SSIP':'USIP';
    const checkListItems = [
        ... UiComponents['SIP_Checklist'] ?? [],
        ... UiComponents['SIP_Checklist_For_SIP'] ?? [],
        ... UiComponents['SIP_Checklist_For_GE'] ?? [],
        ... UiComponents['SIP_Checklist_For_GE_Flag'] ?? [],
    ];

    React.useEffect(() => {
        dispatch(loadComponentsByArea('SIP_Checklist',businessType));
        dispatch(loadComponentsByArea('SIP_Checklist_For_SIP',businessType));
        dispatch(loadComponentsByArea('SIP_Checklist_For_GE',businessType));
        dispatch(loadComponentsByArea('SIP_Checklist_For_GE_Flag',businessType));

        dispatch(getFlaglist());
    }, []);

    const checklist: ChecklistData = {
        ...EMPTY_CHECKLIST_DATA,
        ...props.outerData ?? {},
        [CHECKLIST_PROCEEDING]: false,
        [CHECK_CANCEL]: false,
        [SNACKBAR_SHOW]: false,
        [SNACKBAR_ID]: null,
    };

    return (
        <RootForm defaultValue={checklist}>
            <SIPCheckListPage
                componentVersion={checklist.checklistVersion}
                checkListItems={checkListItems}
            />
        </RootForm>
    );
}


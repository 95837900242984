import { styled } from '@mui/material';
import MyTextField from '../../../components/MyTextField';

const EditAdminTextField = styled(MyTextField)({
  '& .MuiInputBase-input.MuiOutlinedInput-input': {
    padding: '8px 12px',
    width: '345px',
    height: '33px',
    boxSizing: 'border-box',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '120%',
    letterSpacing: '-0.011em',
  },
});

export default EditAdminTextField;

import {
    Range,
    CheckListItemBlock,
    FormComponentProps,
    useForm,
    VALIDATE_KEY,
    extractHtmlId, ComputedComponent
} from "@/components/Form/form.d";
import React, {ReactElement, useEffect} from "react";
import SliderBlock from "@/componentsphase2/Slider/SilderBlock";
import ChecklistItemBlock from "@/pages/SIPPage/SIPCheckListPage/compontents/ChecklistItemBlock";
import OnlyTextBlock from "@/pages/SIPPage/SIPCheckListPage/compontents/OnlyTextBlock";
import {CHECKLIST_PROCEEDING} from "@/pages/SIPPage/SIPCheckListPage/configable/constants";
import { ChecklistStatus } from "@/components/Form/form.d";

export interface CheckListItemRangerProps extends FormComponentProps{
    required?: boolean;
    label: string;
    info: string;
    rangeLabel?: string;
    rangeMin?: number;
    rangeMax?: number;
    rangeLength?: number;
    rangeDisplayLength?: number;
    displayType?: string;
    hasComments?: boolean;
    errorMessage?: string;
}

function CheckListItemRangerBase(props: CheckListItemRangerProps): ReactElement{
    const htmlId = extractHtmlId(props);
    const {
        rangeLabel = 'Number of Articles:',
        rangeMin = 0,
        rangeMax = 1000,
        rangeLength = 10,
        rangeDisplayLength = 200,
        displayType = 'default',// 'complex' | 'simple' | 'default
        hasComments = false,
    } = props;
    const initValue = {
        min: rangeMin,
        max: rangeMin + rangeLength,
        comment: '',
    };

    const errorMessage = props.errorMessage || ' Please provide the required information.';

    const {
        value,
        setValue,
        form,
        setState,
        getState,
    } = useForm<Range | null>({
        name: props.name,
        initValue: null,
        initStates: {
            [VALIDATE_KEY]: 'checked',
            required: props.required === true,
            ['html-id']: htmlId,
        }
    });

    const isRequired = getState('required');

    const status = form?.root().status;
    const locked = form?.root().locked;
    const editable = status === ChecklistStatus.IN_PROGRESS && !locked;

    const proceeding = form?.root()[CHECKLIST_PROCEEDING] ?? false;

    useEffect(() => {
        if(props.required === true || props.required === false){
            if (isRequired !== props.required){
                setState('required', props.required);
            }
            return;
        }
    }, [form?.root()?.version, props.required]);

    const handleChange: ((event: Event, value: number[], activeThumb: number) => void) =
        (event, values, activeThumb) => {

        let left;

        if(activeThumb === 0){
            left = values[0];
        } else {
            left = values[1] - rangeDisplayLength;
        }

        if (left <  rangeMin){
            setValue({
                ...initValue,
                ...value,
            });
        } else if (left + rangeLength >  rangeMax){
            setValue({
                ...value ?? {},
                min: rangeMax -  rangeLength,
                max: rangeMax
            });
        } else {
            setValue({
                ...value ?? {},
                min: left,
                max: left +  rangeLength,
            });
        }
    };

    useEffect(() => {
        let stateValue;
        if (value===undefined || value === null || value.min  <  rangeMin || value.max >  rangeMax || (value.comment?.length ?? 0) > 100000){
            stateValue = proceeding && isRequired ? 'error' : 'default';
        } else {
            stateValue = 'checked';
        }

        if(getState(VALIDATE_KEY) !== stateValue){
            setState(VALIDATE_KEY, stateValue);
            // setState('required', isRequired);
        }
    }, [value, proceeding, isRequired]);

    return (
        <>
        <ChecklistItemBlock
        id={htmlId}
        title={props.label}
        info={props.info}
        necessary={isRequired || false}
        status={getState(VALIDATE_KEY) ?? 'default'}
        needClear={!isRequired || false}
        handleClear={() => {
            setValue(initValue);
        }}
    >
        <SliderBlock
            title={rangeLabel}
            disabled={!editable}
            displayDisabled={value === null}
            value={[value?.min ?? 0, (value?.min??0) + rangeDisplayLength]}
            needAsyncSetValue={false}
            onChange={handleChange}
            error={isRequired && proceeding && getState(VALIDATE_KEY) == 'error'}
            getSpecialLabel={(value: number[]) => {
                return value[0] + ' - ' + (value[0] +  rangeLength);
            }}
            form={rangeMin}
            to={rangeMax}
            min={rangeMin}
            max={rangeMax + rangeDisplayLength}
            range={rangeLength}
        />
        {
            hasComments &&
            <OnlyTextBlock
                title={editable ? 'Comments' : 'Comments:'}
                required={false}
                value={value?.comment}
                setValue={(text: string) => {
                    setValue({
                        ...value ?? {
                            min: rangeMin,
                            max: rangeMin + rangeLength,
                        },
                        comment: text,
                    });
                }}
                editing={editable}
                // isError={getState(VALIDATE_KEY) == 'error' || false}
                // errorMessage={errorMessage}
                radioValue={"Yes"}
            />
        }
    </ChecklistItemBlock>
        </>);
}

export const CheckListItemRanger = ComputedComponent<CheckListItemRangerProps>(CheckListItemRangerBase);
import {exportSIPDataUrl} from '@/actions/SIMT-SIP/SIP/SipExport';
import {axios_instance, axios_instance_fileDownload,} from '@/utils/axios_instance';
import getAuth from '@/utils/getAuth';
import {GuestEditorsExport} from '../GuestEditor/Export';
import {downloadFile} from '../SpecialIssue/SIAction';

export const EXPORT_REPORT = 'EXPORT_REPORT';

export const SET_MONTHLY_DATE = 'SET_MONTHLY_DATE';
export const GET_MONTHLY_SIZE = 'GET_MONTHLY_SIZE';
export const GET_SUBMISSION_SIZE = 'GET_SUBMISSION_SIZE';
export const GET_PUBLISHED_SIZE = 'GET_PUBLISHED_SIZE';
export const GET_SYSTEMDATA_SIZE = 'GET_SYSTEMDATA_SIZE';
export const GET_SYSTEMDATA_DATA = 'GET_SYSTEMDATA_DATA';
const handleFileDownload = async (url, body, headers, filename,type=null) => {
  try {
    const response = await axios_instance_fileDownload.post(url, body, {
      responseType: 'blob',
      params:{
        type:type,
      },
      headers:headers,
    });

    const content_disposition = response.headers['content-disposition'];
    if (content_disposition && content_disposition.includes('filename')) {
      downloadFile(response.data, content_disposition.split('filename=')[1].replace(/["']/g, ''));
    } else {
      downloadFile(response.data, filename);
    }
    return true;
  } catch (error) {
    return false;
  }
};

const handleActionDispatch = (dispatch, type, data) => {
  dispatch({ type, data });
};
/*Monthly*/
export function setMonthlyDate(newDate) {
  return async dispatch => {
    dispatch({ type: SET_MONTHLY_DATE, data: newDate });
  };
}
export function monthlyExportData(selectDate, dataRangeStart,
  dataRangeLength, url, filename,type) {
    return async (dispatch) => {
      try {
        const body = {
          monthlyCampaign: {
            selectedDate: selectDate,
          },
          dataRangeStart: dataRangeStart,
          dataRangeLength: dataRangeLength,
        };
        const headers = {
          'Content-Type': 'application/json',
          authorization: localStorage.getItem('authorization'),
        };
        const success = await handleFileDownload(url, body, headers, filename,type);
        handleActionDispatch(dispatch, EXPORT_REPORT, success);
      } catch (err) {
        handleActionDispatch(dispatch, EXPORT_REPORT, false);
      }
    };
}

/*Submission*/
export function submissionExportData(obj = {}, url, filename,type) {
  return async (dispatch) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('authorization'),
      };
      const body={
        submissionCampaign: {
          openForSubmissionDate: obj.openForSubmissionDate,
          submissionDeadline: obj.submissionDeadline,
          isAllGE: obj.isAllGE,
      },
      dataRangeStart: obj.dataRangeStart,
      dataRangeLength: obj.dataRangeLength,
      };
      const success = await handleFileDownload(url, body, headers, filename,type);
      handleActionDispatch(dispatch, EXPORT_REPORT, success);
    } catch (err) {
      handleActionDispatch(dispatch, EXPORT_REPORT, false);
    }
  };
}

/*Published*/
export function publishedExportData(obj = {}, url, filename,type) {
  return async (dispatch) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('authorization'),
      };
      const body={
        publishedCampaign: {
          actualPublicationDate: obj.actualPublicationDate,
          number: obj.number,
      },
      dataRangeStart: obj.dataRangeStart,
      dataRangeLength: obj.dataRangeLength
      };
      const success = await handleFileDownload(url, body, headers, filename,type);
      handleActionDispatch(dispatch, EXPORT_REPORT, success);
    } catch (err) {
      handleActionDispatch(dispatch, EXPORT_REPORT, false);
    }
  };
}

/**
 * @param {0|1|2|3} type
 */
export function getSystemDataSize(type) {
  /**
   * @type {string}
   */
  let url;
  if(type===0) url='/api/v1/si-service/special-issues/export';
  else if(type===1||type===2) url=exportSIPDataUrl;
  else if(type===3) url='/api/v1/user-service/guest-editors/selected/size';
  else return async dispatch => {
    dispatch({ type: GET_SYSTEMDATA_SIZE, data: 'error' });
  };
  /**
   * @param {Dispatch} dispatch
   */
  return async dispatch => {
    try {
      const authorization=getAuth();
      if(!authorization) return;
      let res;
      if(type === 1) {
        res = await axios_instance.post(url,
          {
            solicited: true,
            exportData: false,
            exportAll: true
          },
           {
          headers: {
            'Content-Type': 'application/json',
            authorization,
          },
        });
      }else if(type === 2){
        res = await axios_instance.post(url,
          {
            solicited: false,
            exportData: false,
            exportAll: true
          },
           {
          headers: {
            'Content-Type': 'application/json',
            authorization,
          },
        });
      }
      else if (type === 3) {
        res = await axios_instance.get(url, {
          headers: {
            'Content-Type': 'application/json',
            authorization,
          },
        });
      } else {
        res = await axios_instance.post(url, {
          exportAll: true,
          queryData: false,
        }, {
          headers: {
            'Content-Type': 'application/json',
            authorization,
          },
        });
      }
      const {code,data} = res.data;
      if (code === 200) {
        dispatch({ type: GET_SYSTEMDATA_SIZE, data: data });
      } else {
        dispatch({ type: GET_SYSTEMDATA_SIZE, data: 'error' });
      }
    } catch {
      return dispatch({ type: GET_SYSTEMDATA_SIZE, data: 'error' });
    }
  };
}

/**
 * @typedef {import('redux').Dispatch} Dispatch
 * @param {0|1|2|3} type
 * @param {number} start
 * @param {number} end
 */
export function exportSystemData(type,start,end) {
  /**
   * @type {string}
   */
  let url;
  if(type===0) url='/api/v1/si-service/special-issues/export';
  else if(type===1) url='/api/v1/sip-service/special-issue-proposals/overview?solicited=true&type=exportAllSipData';
  else if(type===2) url='/api/v1/sip-service/special-issue-proposals/overview?solicited=false&type=exportAllSipData';
  else if(type===3) url='/export/exportAllGeSystemData';
  else return async (/** @type {Dispatch} */ dispatch) => {
    dispatch({ type: GET_SYSTEMDATA_DATA, data: false });
  };
  const param = {
    end: end,
    start: start,
    exportAll : true,
    fileType : 'excel',
    queryData : true,
  };
  if (type === 1 || type === 2) {
    param.filterMap = {};
  }
  /**
   * @param {Dispatch} dispatch
   */
  return async dispatch => {
    try {
      const res=await (async ()=>{
      if(type===3){
        return GuestEditorsExport({start:param.start,end:param.end},'export-all');
      }else{
      let res = await axios_instance_fileDownload.post(
        url,
        param,
        {
          responseType: 'blob',
          headers: {
            authorization: getAuth()??'',
          },
        }
      );
      return res;}})();
      let x=res.headers["content-disposition"].split('=')[1];
      let fileName=x.slice(1,x.length-1);
      downloadFile(res.data,fileName);
      dispatch({ type: GET_SYSTEMDATA_DATA, data: true });
    } catch (err) {
      dispatch({ type: GET_SYSTEMDATA_DATA, data: false });
    }
  };
}
import { ListItem, Box, Modal, Button, Backdrop } from '@mui/material';
import { styled } from '@mui/system';
import Copied from '@/assets/GEMergeAssets/Copied.svg';

import MyOnlyText from '@/componentsphase2/MyOnlyText';
import React, { useEffect, useState } from 'react';

import ArrowSVG from '@/assets/CMAssets/Arrow.svg';
import ArrowHoverSVG from '@/assets/CMAssets/ArrowHover.svg';
import ArrowUp from '@/assets/GEMergeAssets/ArrowUp.svg';
import Check from '@/assets/GEMergeAssets/Check.svg';
import {
  ChooseIconByType,
  formatFileSize,
  ShowFlag,
  getFlagType,
} from '@/pages/GEPage/GEDetailPage/FlagBlock';
import { useDispatch } from 'react-redux';
import { GetLabel } from './fileRelated/fileLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import TextFieldErrorIcon from '@/assets/TextFieldErrorIcon.svg';
import { OnlyTextForGEDetailPage } from '@/pages/GEPage/GEDetailPage/BasicInforBlock';
const patternLink =
  /^(https?:\/\/){0,1}(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/;

//验证邮箱格式的正则表达式
const patternEmail =
  /^\w+((-\w+)|(\.\w+)|(_\w+))*@[A-Za-z0-9]+(([.\-])[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

const mergeString = (str1, str2) => {
  if (!str1) return str2;
  if (!str2) return str1;
  return str1 + '; ' + str2;
};

// 这个组件里的局部状态可能会带来数据不一致的问题，但是目前没有发现，所以先不管了
const OnlyTextWithCheck = ({
  value,
  setValue,
  onCheck = () => { },
  allowEmptyCheck = false,
  isError = () => {
    return false;
  },
  errorMessage = () => {
    return false;
  },
  needLimit = false,
  limit = 0,
  maxRows = 1,
  ...rest
}) => {
  const [focus, setFocus] = useState(false);
  const [temValue, setTemValue] = useState(value);
  const moreThanOneRowl = maxRows && maxRows > 1;

  useEffect(() => {
    setTemValue(value);
  }, [value]);

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
    setTemValue(value);
  };

  const handleCheck = () => {
    setValue(temValue);
    onCheck();
  };

  const overflowError = needLimit && temValue?.length > limit;

  const checkShow =
    focus && ((temValue !== '' && temValue !== null) || allowEmptyCheck);

  const limitString = limit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // 甲方设计可能有问题
  const valueLengthString =
    temValue?.length < limit
      ? (temValue?.length?.toString() ?? '0').replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ','
      )
      : limit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItem: 'flex-end' }}
    >
      <MyOnlyText
        multiline={false}
        autoComplete='off'
        value={temValue ?? ''}
        setValue={setTemValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        maxRows={maxRows}
        alwaysEndCursor={true}
        textFieldPadding='12px'
        textFieldPaddingWithoutEndAdornment='12px'
        TextFieldInputProps={{
          sx: {
            display: 'flex',
            width: '100%',
            alignItems: moreThanOneRowl ? 'flex-start' : '',
          },
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                onMouseDown={e => {
                  handleCheck();
                  e.preventDefault();
                }}
                onClick={() => {
                  document.activeElement.blur();
                }}
                style={{ paddingRight: '0px' }}
                disableRipple
                sx={{
                  display: 'flex',
                  visibility: checkShow ? 'visible' : 'hidden',
                  zIndex: 10,
                  marginTop: moreThanOneRowl ? '12px' : '0px',
                }}
              >
                <Check />
              </IconButton>
            </InputAdornment>
          ),
        }}
        isError={isError(value) || overflowError}
        errorMessage={errorMessage(value)}
        // realTimeErrorShow={true}
        {...rest}
      />
      {needLimit && (
        <p
          style={{
            marginTop: 6,
            marginBottom: 0,
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16.34px',
            color: '#596A7C',
            width: '286px',
            textAlign: 'right',
            display: 'flex',
            justifyContent: overflowError ? 'space-between' : 'flex-end',
          }}
        >
          {overflowError && (
            <div
              style={{
                display: 'flex',
                gap: '2px',
                alignItems: 'center',
                color: '#EE5350',
              }}
            >
              <TextFieldErrorIcon />
              Please delete excess words.
            </div>
          )}
          <span>{valueLengthString + '/' + limitString}</span>
        </p>
      )}
    </Box>
  );
};

export const GEDetailItem = ({
  _key = '',
  item1 = {
    value: 'AAQkADZiMGI5ODUyLTBmZDYtNDdjOS1hYzdmLTc5NDdhYTc2N2E1MwAQADjaSCHhav1HjPiuT+AVepE=',
  },
  item2 = {
    value: 'AAQkADZiMGI5ODUyLTBmZDYtNDdjOS1hYzdmLTc5NDdhYTc2N2E1MwAQADjaSCHhav1HjPiuT+AVepE=',
  },
  setData = () => { },
  unSetData = () => { },
  editType = 'cover',
  showLeft = true,
  showRight = true,
  isTitleLine = false,
  title,
  hasEdited,
  priEmailSametoSecEmailError = false,
  emailDuplicatedError = false
}) => {
  // if(_key == 'CV')
  // {
  //     console.log('_key: ', _key);
  //     console.log("item1: ", item1);
  //     console.log("item2: ", item2);
  // }

  const reallyNeedCopyIcon =
    !isTitleLine && item1.value !== item2.value && editType !== 'edit';

  // CopyButton Status
  const [isHovered, setIsHovered] = useState(false);
  const [hasCovered, setHasCovered] = useState(hasEdited(_key));

  // UndoButton Status
  //isHovered2:当鼠标悬停在某一行文本框所属区块时，区块背景色会自动变为蓝色
  //点击undo按钮后，背景色会恢复默认颜色
  const [isHovered2, setIsHovered2] = useState(false);
  const [nodeEdit, setNodeEdit] = useState(false);

  // useEffect(() => {
  //     if (!hasCovered) setNodeEdit(false);
  // }, [hasCovered]);
  const undoButton = () => {
    return (
      <Box
        sx={{
          color: 'var(--primary-600, #113D95)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '16px',
          textTransform: 'capitalize',
          cursor: 'pointer',
          // no split line
          wordBreak: 'keep-all',
        }}
        onClick={() => {
          setHasCovered(false);
          setIsHovered(false);
          setIsHovered2(false);
          unSetData(_key);
          setNodeEdit(false);
        }}
        onMouseEnter={() => setIsHovered2(true)}
        onMouseLeave={() => setIsHovered2(false)}
      >
        Undo
      </Box>
    );
  };

  const saveButton = () => {
    return (
      <Box
        sx={{
          color: 'var(--primary-600, #113D95)',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '16px',
          textTransform: 'capitalize',
          cursor: 'pointer',
          // no split line
          wordBreak: 'keep-all',
        }}
        onClick={() => {
          setHasCovered(false);
          setIsHovered(false);
          setIsHovered2(false);
          setNodeEdit(false);
        }}
        onMouseEnter={() => setIsHovered2(true)}
        onMouseLeave={() => setIsHovered2(false)}
      >
        Save
      </Box>
    );
  };

  const copyIcon = () => {
    const isActive =
      (reallyNeedCopyIcon && !hasCovered && showLeft && showRight) ||
      (_key === 'CV' && !hasCovered);
    return (
      <>
        {_key !== 'CV' ? (
          <Box
            sx={{
              display: isActive ? 'flex' : 'none',
              cursor: 'pointer',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
              setHasCovered(true);
              setIsHovered(false);
              if (editType === 'cover') {
                setData(_key, item1.value);
              } else if (editType === 'union') {
                setData(_key, mergeString(item2.value, item1.value));
              }
            }}
          >
            <Copied />
          </Box>
        ) : _key === 'CV' && item1?.value === null && item2?.value === null ? (
          <></>
        ) : (
          <Box
            sx={{
              display: isActive ? 'flex' : 'none',
              cursor: 'pointer',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
              setHasCovered(true);
              setIsHovered(false);
              if (editType === 'cover') {
                setData(_key, item1.value);
              } else if (editType === 'union') {
                setData(_key, mergeString(item2.value, item1.value));
              }
            }}
          >
            <Copied />
          </Box>
        )}
      </>
    );
  };
  const editButton = () => {
    return (
      <Box
        sx={{
          color: 'var(--primary-600, #113D95)',
          textAlign: 'center',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '16px',
          textTransform: 'capitalize',
          cursor: 'pointer',
        }}
        onClick={() => {
          setNodeEdit(true);
        }}
      >
        Edit
      </Box>
    );
  };
  if (isTitleLine) {
    return (
      <ListItem
        alignItems='baseline'
        sx={{
          // border: '1px solid #E3E8EE',
          width: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          padding: '0px',
          backgroundColor: isHovered && !hasCovered ? '#E8EDFB' : 'white',
        }}
      >
        <Box sx={{ width: '526px' }}>
          <Box
            sx={{
              color: 'var(--gray-500, #596A7C)',
              fontFamily: 'Open Sans',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 'normal',
              width: '526px',
              display: showLeft ? 'flex' : 'none',
            }}
            textAlign='left'
          >
            {title}
          </Box>
        </Box>
        <Box
          sx={{
            width: '34px',
            height: '32px',
          }}
        />
        <Box sx={{ width: '526px' }}>
          <Box
            sx={{
              color: 'var(--gray-500, #596A7C)',
              fontFamily: 'Open Sans',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 'normal',
              display: showRight ? 'flex' : 'none',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            textAlign='left'
          >
            {title}
          </Box>
        </Box>
      </ListItem>
    );
  }

  const dispatch = useDispatch();
  const LeftTypeField = () => {
    if (_key === 'CV' && item1.value) {
      const match = item1.value.match(/(\d{5,13})_/); // 查找匹配的部分
      return (
        <GetLabel
          sx={{
            marginTop: '0px',
            marginLeft: '0px',
            width: '286px!important',
            height: '32px!important',
          }}
          FileIcon={ChooseIconByType(
            item1.value?.split(';')[0].split('.').slice(-1)[0]
          )}
          download={() => {
            dispatch(
              downloadCVFile(item1.value.split(';size')[0].split('name:')[1])
            );
          }}
          fileName={item1.value
            ?.split(';')[0]
            .substring(match.index + match[0].length)
            .replace(/(.*)(?=\.)/, '$1')}
          fileSize={formatFileSize(
            Number(item1.value?.split(';')[1]?.split(':')[1])
          )}
          fileMIMEType={item1.value?.split(';')[0].split('.').slice(-1)[0]}
          isDownLoad={true}
        />
      );
    }
    return (
      <Box
        sx={{
          width: '286px',
          wordBreak: 'break-all',
          color: '#262E35',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {item1.value}
      </Box>
    );
  };

  const judgeEmailError = (type) =>
    (value) => {
      if (type === 'Secondary Email') {
        if (priEmailSametoSecEmailError)
          return true;
        if (value === '' || value === null) {
          return false;
        }
        if (!patternEmail.test(value)) {
          return true;
        }
      }
      if (type === 'Primary Email') {
        if (emailDuplicatedError)
          return true;
        if (priEmailSametoSecEmailError)
          return true;
        if (value === '' || value === null || !patternEmail.test(value)) {
          return true;
        }
      }
      return false;
    };

  const RightTypeField = () => {
    if (_key === 'CV' && item2.value) {
      const match = item2.value.match(/(\d{5,13})_/); // 查找匹配的部分
      return (
        <>
          <GetLabel
            sx={{
              marginTop: '0px',
              marginLeft: '0px',
              width: '286px!important',
              height: '32px!important',
            }}
            FileIcon={ChooseIconByType(
              item2.value?.split(';')[0].split('.').slice(-1)[0]
            )}
            download={() => {
              dispatch(
                downloadCVFile(item2.value.split(';size')[0].split('name:')[1])
              );
            }}
            fileName={item2.value
              ?.split(';')[0]
              .substring(match.index + match[0].length)
              .replace(/(.*)(?=\.)/, '$1')}
            fileSize={formatFileSize(
              Number(item2.value?.split(';')[1]?.split(':')[1])
            )}
            fileMIMEType={item2.value?.split(';')[0].split('.').slice(-1)[0]}
            isDownLoad={true}
          />
          {hasCovered && (
            <div style={{ marginLeft: '4px' }}>{undoButton()}</div>
          )}
        </>
      );
    }
    if (editType === 'edit') {
      return (
        <>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <OnlyTextWithCheck
              id={'GE_MERGE_INPUT' + _key}
              key={_key}
              width='286px'
              height='40px'
              value={item2.value}
              setValue={value => {
                setData(_key, value);
              }}
              allowEmptyCheck={_key === 'Secondary Email'}
              onCheck={() => { }}
              isError={judgeEmailError(_key)}
              errorMessage={value => {
                if (_key === 'Secondary Email') {
                  if (priEmailSametoSecEmailError)
                    return 'Your secondary email should be different from your primary email.';
                  if (value === '' || value === null) {
                    return '';
                  }
                  if (!patternEmail.test(value)) {
                    return 'Please input correct email format.';
                  }
                }
                if (_key === 'Primary Email') {
                  if (emailDuplicatedError)
                    return 'The Guest Editor has been added in database.';
                  if (priEmailSametoSecEmailError)
                    return 'Your primary email should be different from your secondary email.';
                  if (value === '' || value === null) {
                    return 'The primary email is mandatory to input';
                  }
                  if (!patternEmail.test(value)) {
                    return 'Please input correct email format.';
                  }
                }
                return '';
              }}
            />
          </Box>
          {hasEdited(_key) && (
            <div style={{ marginLeft: '4px' }}>{undoButton()}</div>
          )}
        </>
      );
    }
    //若点击editButton 则nodeEdit = true;
    //以下是GE2 Notes点击edit后显示的文本输入框
    if (_key === 'Notes' && nodeEdit) {
      return (
        <>
          <Box>
            {/* <OnlyTextForGEDetailPage
              multiline={false}
              value={item2.value}
              setValue={value => {
                setData('Notes', value);
              }}
              width='286px'
              needEndAdornment={true}
              handleIconButtonClick={() => {
                setData('Notes', '');
              }}
              placeholder={'Notes'}
              isError={
                typeof item2.value === 'string' && item2.value.length > 5000
              }
              errorMessage={`This field should not exceed ${5000} characters in length`}
            /> */}
            <OnlyTextWithCheck
              width='286px'
              height='112px'
              value={item2.value}
              setValue={value => {
                setData('Notes', value);
              }}
              multiline={true}
              maxRows={5}
              onCheck={() => {
                setNodeEdit(false);
              }}
              needLimit={true}
              limit={5000}
              realTimeErrorShow={true}
              allowEmptyCheck={true}
            />
          </Box>
          <div
            style={{ marginLeft: '4px', display: 'flex', flexDirection: 'row' }}
          >
            <div
              style={{
                marginRight: '5px',
                width: '30px',
                display: 'flex',
                alignItems: 'center',
                flexShrink: 0,
              }}
            >
              {undoButton()}
            </div>
            {/* <div
              style={{
                width: '30px',
                display: 'flex',
                alignItems: 'center',
                flexShrink: 0,
              }}
            >
              {saveButton()}
            </div> */}
          </div>
        </>
      );
    }
    return (
      <Box
        sx={{
          width: '286px',
          wordBreak: 'break-all',
          color: '#262E35',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          maxWidth: 'calc(100% - 28px)', // 28px 是编辑按钮的宽度
        }}
      >
        {item2.value}
        {hasCovered && (
          <div
            style={{
              marginLeft: '4px',
              height: '20px',
              display: 'flex',
              alignItems: 'center',
              flexShrink: 0,
            }}
          >
            {/* {undoButton()} */}
            {_key !== 'Notes' ? (
              undoButton()
            ) : (
              <div
                style={{
                  marginLeft: '4px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    marginRight: '5px',
                    width: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 0,
                  }}
                >
                  {undoButton()}
                </div>
                <div
                  style={{
                    width: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 0,
                  }}
                >
                  {editButton()}
                </div>
              </div>
            )}
          </div>
        )}
      </Box>
    );
  };

  return (
    <ListItem
      alignItems='baseline'
      // key={index}
      sx={{
        // border: '1px solid #E3E8EE',
        padding: 0,
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        backgroundColor: isHovered ? '#E8EDFB' : 'white',
      }}
    >
      {/* item key */}
      <Box sx={{ width: '526px' }}>
        <Box sx={{ display: showLeft ? 'flex' : 'none', padding: '6px' }}>
          <Box
            width='160px'
            textAlign='left'
            sx={{
              color: '#596A7C',
              fontSize: '14px',
              fontWeight: '400',
              marginRight: '68px',
            }}
          >
            {_key + ':'}
          </Box>
          {LeftTypeField()}
        </Box>
      </Box>
      <Box
        sx={{
          width: '34px',
          height: '32px',
        }}
      >
        {copyIcon()}
      </Box>
      <Box sx={{ width: 'auto' }}>
        <Box
          sx={{
            backgroundColor: isHovered2 ? '#E8EDFB' : 'white',
            display: showRight ? 'flex' : 'none',
            padding: '6px',
          }}
        >
          <Box
            width='160px'
            textAlign='left'
            sx={{
              color: '#596A7C',
              fontSize: '14px',
              fontWeight: '400',
              marginRight: '68px',
            }}
          >
            {_key + ':'}
          </Box>
          {RightTypeField()}
        </Box>
      </Box>
    </ListItem>
  );
};

export const fieldSequence = [
  'BasicInfo',
  'First Name',
  'Last Name',
  'Gender',
  'Primary Email',
  'Secondary Email',
  'ORCID ID',
  'ResearchGate ID',
  'Job Title',
  'Department',
  'Institution',
  'City',
  'Country/Region',
  'Research Keywords',
  'Profile Website',
  'Notes',
  'CV',
];

// const flags = [
//     {
//         Flag:{},
//         Evidence:[],
//         Comments:''
//     },
//     ...
// ]

const FlagItem = ({
  _key = '',
  flag = {},
  evidences = [],
  comment = '',
  index = 1,
  hasCovered = false,
  setFlagData = () => {},
  geCode = 'GE-0000-000000'
}) => {
  const [mode, setMode] = useState('view');

  const dispatch = useDispatch();

  useEffect(() => {
    if (!hasCovered) {
      setMode('view');
    }
  }, [hasCovered]);

  const editButton = () => {
    return (
      <Box
        sx={{
          color: 'var(--primary-600, #113D95)',
          textAlign: 'center',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '16px',
          textTransform: 'capitalize',
          cursor: 'pointer',
        }}
        onClick={() => {
          setMode('edit');
        }}
      >
        Edit
      </Box>
    );
  };

  return (
    <Box
      sx={{
        paddingLeft: '6px',
        paddingRight: '6px',
        // width: '514px',
      }}
    >
      <ListItem
        alignItems='baseline'
        // key={index}
        sx={{
          // border: '1px solid #E3E8EE',
          width: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          padding: '0px',
          marginBottom: '21px',
        }}
      >
        <Box
          width='160px'
          textAlign='left'
          sx={{
            color: '#596A7C',
            fontSize: '14px',
            fontWeight: '400',
            marginRight: '68px',
          }}
        >
          {'Flag' + index + ':'}
        </Box>
        <Box
          sx={{
            width: '286px',
            wordBreak: 'break-all',
            color: '#262E35',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
          }}
        >
          <ShowFlag flagType={flag.type} flagValue={flag.value} />
        </Box>
      </ListItem>
      <ListItem
        alignItems='baseline'
        // key={index}
        sx={{
          width: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          padding: '0px',
          marginBottom: '21px',
        }}
      >
        <Box
          width='160px'
          textAlign='left'
          sx={{
            color: '#596A7C',
            fontSize: '14px',
            fontWeight: '400',
            marginRight: '68px',
          }}
        >
          {'Flag' + index + ' Evidence:'}
        </Box>
        <Box sx={{ gap: '8px', display: 'flex', flexDirection: 'column' }}>
          {evidences?.map((evidence, index) => {
            const file_size = Number(evidence.split('_')[0]);
            const left_part = evidence.slice(evidence.indexOf('_') + 1);
            const fileName = left_part
              ?.slice(left_part.indexOf('_') + 1)
              ?.replace(/(.*)(?=\.)/, '$1');
            return (
              <GetLabel
                sx={{
                  marginTop: '0px',
                  marginLeft: '0px',
                  width: '286px!important',
                  height: '32px!important',
                }}
                FileIcon={ChooseIconByType(left_part?.split('.').slice(-1)[0])}
                del={() => {
                  setFlagData(
                    _key,
                    'evidences',
                    evidences?.filter(item => item !== evidence)
                  );
                }}
                download={() => {
                  dispatch(downloadSingleFile(evidence, fileName, geCode));
                }}
                fileName={fileName}
                fileSize={formatFileSize(file_size)}
                fileMIMEType={left_part?.split('.').slice(-1)[0]}
                needDelete={hasCovered}
              />
            );
          })}
        </Box>
      </ListItem>
      <ListItem
        alignItems='baseline'
        sx={{
          width: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          padding: '0px',
        }}
      >
        <Box
          width='160px'
          textAlign='left'
          sx={{
            color: '#596A7C',
            fontSize: '14px',
            fontWeight: '400',
            marginRight: '68px',
          }}
        >
          {'Flag' + index + ' Comments:'}
        </Box>
        {mode === 'edit' ? (
          <OnlyTextWithCheck
            width='286px'
            height='112px'
            value={comment}
            setValue={value => {
              setFlagData(_key, 'comment', value);
            }}
            multiline={true}
            maxRows={5}
            onCheck={() => {
              // setMode('view');
            }}
            needLimit={true}
            limit={10000}
            realTimeErrorShow={true}
            allowEmptyCheck={true}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
            }}
          >
            <Box
              sx={{
                maxWidth: '280px',
                wordBreak: 'break-all',
                color: '#262E35',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
              }}
            >
              {comment}
            </Box>
            {hasCovered && <Box sx={{ marginLeft: '4px' }}>{editButton()}</Box>}
          </Box>
        )}
      </ListItem>
    </Box>
  );
};

export const FlagPart = ({
  flags,
  mergeFlag,
  unMergeFlag,
  setFlagData,
  hasEdited,
  showLeft = true,
  showRight = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [hasCovered, setHasCovered] = useState(hasEdited());

  const urlParams = new URLSearchParams(window.location.search);
  const code1 = urlParams.get('ge1');
  const code2 = urlParams.get('ge2');

  // console.log("flags:",flags);
  // console.log("flags.one:",flags.One);
  // console.log("flags.one.length:",flags.One?.length);
  // console.log("flags.two:",flags.Two);
  // console.log("flags.two.length:",flags.Two?.length);
  // const handleMerge = () => {

  // }
  const copyIcon = () => {
    const isActive = !hasCovered;
    return (
      <>
        {flags.One &&
          flags.One.length === 0 &&
          flags.Two &&
          flags.Two.length === 0 ? (
          <></>
        ) : (
          <Box
            sx={{
              // display: isActive ? 'flex' : 'none',
              display: 'flex',
              cursor: 'pointer',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
              setHasCovered(true);
              setIsHovered(false);
              mergeFlag();
            }}
          >
            <Copied />
          </Box>
        )}
      </>
    );
  };

  const undoButton = () => {
    return (
      <Box
        sx={{
          color: 'var(--primary-600, #113D95)',
          textAlign: 'center',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '16px',
          textTransform: 'capitalize',
          cursor: 'pointer',
        }}
        onClick={() => {
          setHasCovered(false);
          setIsHovered(false);
          setIsHovered2(false);
          // UNDO MERGE
          unMergeFlag();
        }}
        onMouseEnter={() => setIsHovered2(true)}
        onMouseLeave={() => setIsHovered2(false)}
      >
        Undo
      </Box>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: isHovered ? '#E8EDFB' : 'white',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          // width: '526px',
        }}
      >
        {/* LeftSide */}
        <Box sx={{ width: '526px' }}>
          <Box
            sx={{
              display: showLeft ? 'flex' : 'none',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                marginTop: '6px',
                color: 'var(--gray-500, #596A7C)',
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                width: '526px',
                marginBottom: '16px',
              }}
              textAlign='left'
            >
              Flag
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '21px' }}>
              {flags.One?.map((flag, index) => {
                return (
                  <FlagItem
                    flag={flag.flag}
                    evidences={flag.evidences}
                    comment={flag.comment}
                    index={index + 1}
                    geCode={code1}/>
                );
              })}
            </Box>
          </Box>
        </Box>
        {/* blanket */}
        <Box
          sx={{
            width: '34px',
            height: '32px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {/* <Box sx={{ display: showLeft && showRight && !hasCovered ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }} > */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!hasCovered && copyIcon()}
          </Box>
        </Box>
        {/* RightSide */}
        <Box sx={{ width: 'auto' }}>
          <Box
            sx={{
              display: showRight ? 'flex' : 'none',
              flexDirection: 'column',
              backgroundColor: isHovered2 ? '#E8EDFB' : 'white',
            }}
          >
            <Box
              sx={{
                color: 'var(--gray-500, #596A7C)',
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                width: '526px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '16px',
                marginTop: '6px',
              }}
              textAlign='left'
            >
              Flag
              {hasCovered && (
                <Box
                  style={{
                    marginLeft: '4px',
                    height: '22px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {' '}
                  {undoButton()}
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '21px' }}>
              {flags.Two?.map((flag, index) => {
                return (
                  <FlagItem
                    _key={flag.key}
                    flag={flag.flag}
                    evidences={flag.evidences}
                    comment={flag.comment}
                    hasCovered={hasCovered}
                    index={index + 1}
                    setFlagData={setFlagData}  
                    geCode={code2}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const ArrowButton = props => {
  const { isFolded, setIsFolded } = props;
  const [isHover, setIsHover] = useState(false);
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '2px',
        gap: '12px',
        width: '28px',
        height: '28px',
        background: '#dfe4e8',
        borderRadius: '2px',
        flex: 'none',
        order: '0',
        flexGrow: '0',
      }}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={() => {
        setIsFolded(!isFolded);
      }}
    >
      {!isFolded ? <ArrowUp /> : isHover ? <ArrowHoverSVG /> : <ArrowSVG />}
    </Box>
  );
};

const CenteredModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = styled('div')`
  display: flex;
  width: 600px;
  padding: 24px 32px 0px 32px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 5px;
  background: #fff;
`;

const TypographyContainer = styled('div')`
  color: var(--gray-600, #262e35);
  text-align: center;

  /* Large */
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 22.5px */
`;

const ButtonContainer = styled('div')`
  display: flex;
  width: 536px;
  padding: 12px 0px 20px 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;

import { YesButton } from './NextButton';
import { NoButton } from './BackButton';
import { downloadSingleFile } from '@/actions/SIMT-SI/GuestEditor/GEFlag';
import { downloadCVFile } from '@/actions/SIMT-SI/GuestEditor/SipDetail';
import Item from '@/pages/SIPage/Overview/Filter/component/Framework/Block/Item';

export const CancelConfirmModal = props => {
  const {
    open = false,
    handleCancel = () => { },
    handleConfirm = () => { },
  } = props;

  return (
    <CenteredModal
      open={open}
      onClose={handleCancel}
      components={{
        Backdrop: styled(Backdrop)({
          backgroundColor: 'rgba(17, 61, 149, 0.5)',
          backdropFilter: 'blur(4px)',
          borderRadius: '5px',
          zIndex: -1,
        }),
      }}
    >
      <ContentContainer>
        <TypographyContainer>
          Are you sure you want to cancel your action?
        </TypographyContainer>
        <ButtonContainer>
          <NoButton onClick={handleCancel}>No</NoButton>
          <Box sx={{ width: 10 }} />
          <YesButton onClick={handleConfirm}>Yes</YesButton>
        </ButtonContainer>
      </ContentContainer>
    </CenteredModal>
  );
};

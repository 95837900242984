// @ts-check
import TextOverflow from '@/components/TextOverflow';
import { Box, Collapse, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ItemBlock } from '../../SIPDetailPage/EditComponents';
import HideText from '../../SIPDetailPage/Submodule/HideText';
import { SectionTitleBlock } from '../CustomComponents/CustomBlock';
import { SectionContainer } from '../CustomComponents/CustomContainer';
import { ItemBlockShowText } from '../CustomComponents/CustomTypography';

/**
 *
 * @param {import("./CommentSection").CommentSectionProps} props
 * @returns
 */
const CommentSection = props => {
  const {
    title = 'Comments to the Proposal',
    fromJournal = '',
    commentsContent,
    isSSIP = false,
  } = props;

  return ( 
    !isSSIP?
    <SectionContainer
      title={title}
      data-selenium-id='SIP_RevisePage_Section-Comment-Container'
    >
      <Stack
        maxWidth={'1048px'}
        spacing='16px'
        data-selenium-id='SIP_RevisePage_Section-Comment-Stack'
      >
        {fromJournal !== '' && (
          <ItemBlock title='From Journal:'>
            <ItemBlockShowText>{fromJournal}</ItemBlockShowText>
          </ItemBlock>
        )}
        <TextOverflow
          lineNum={5}
          id='CommentSectionTextOverflow'
          data-selenium-id='SIP_RevisePage_Section-Comment-TextOverflow'
        >
          {commentsContent}
        </TextOverflow>
      </Stack>
    </SectionContainer>
    :
    <Stack>
      <Typography sx={{fontFamily:'Open Sans',fontWeight:600,fontSize:'16px',lineHeight:'21.79px',color:'#262E35'}}>{title}</Typography>
      <TextOverflow
          lineNum={5}
          id='CommentSectionTextOverflow'
          data-selenium-id='SIP_RevisePage_Section-Comment-TextOverflow'
        >
          <Typography sx={{fontFamily:'Open Sans',fontWeight:400,fontSize:'14px',lineHeight:'20px',letterSpacing:'1%',color:'#596A7C',paddingTop:'10px'}}>{commentsContent}</Typography>
        </TextOverflow>
    </Stack>
  );
};

export default CommentSection;

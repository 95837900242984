import {
  Box,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Popover,
  Select,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { NewDesignedMain } from '../../../components/Main';
import { DelaySearchTextField } from '../../../components/MyTextField';
import SearchIcon from '@mui/icons-material/Search';
import { useState,useRef } from 'react';
import { SIMTButton } from '../../../components/StyledButton/SIMTButton';
import BatchUpdateIcon from '../../../assets/batchUpdateIcon.svg';
import BatchUpdatePage from './BatchUpdate/BatchUpdatePage';
import CRTable from './CRTable';
import {
  generateCRCInfo,
  handleCRCPageChangeAction,
  setSearchContent,
} from '../../../actions/SIMT-SI/CRContact/CRContactAction';
import { unstable_batchedUpdates } from 'react-dom';
import { IconButtonNewStyle } from '@/pages/GEPage/GEDetailPage/FlagBlockAdd';
import SelectWhiteArrow from '@/assets/SelectWhiteArrow.svg';
import SearchIcon1 from '@/assets/SearchIcon1.svg';
import {SearchBox} from '@/componentsphase2/SearchBox/SearchBox';
export default function CROverviewPage(props) {
  const [searchBy, setSearchBy] = useState('JOURNAL');
  const open = useSelector(state => state.UserManage.siderOpen); //侧边栏开关

  const [anchorEl, setAnchorEl] = useState(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const openMenu = e => {
    setAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };
  const [selectedOption, setSelectedOption] = useState('Journal');
  const handleMenuItemClick = (event, option) => {
    setSelectedOption(option);
    setMenuOpen(false);
    unstable_batchedUpdates(() => {
      // 修改搜索域后页码置为1
      dispatch(handleCRCPageChangeAction(1));
      setSearchBy(option.toUpperCase());
    });
  };

  const [batchUpdateMsgOpen, setBatchUpdateMsgOpen] = useState(false);
  const batchUpdateMenuOpen = !batchUpdateMsgOpen && Boolean(anchorEl);

  const [batchUpdateOpen, setBatchUpdateOpen] = useState(false);
  const [batchUpdateType, setBatchUpdateType] = useState('');

  const selector = state => {
    return {
      selectedList: state.CRContact.selectedCRC,
      selectInfo: state.CRContact.selectedCRCInfo,
      CRCData: state.CRContact.CRCData,
    };
  };

  const { selectedList, selectInfo, CRCData } = useSelector(selector);

  const dispatch = useDispatch();

  const buildCRCInfoList = () => {
    const CRCInfo = [];
    for (const CRCId of selectedList) {
      CRCInfo.push({
        ...selectInfo[CRCId],
        isError: false,
      });
    }
    return CRCInfo;
  };

  const handleBatchUpdateClick = event => {
    setAnchorEl(event.currentTarget);
    if (selectedList.length === 0) {
      setBatchUpdateMsgOpen(true);
    } else {
      dispatch(generateCRCInfo(buildCRCInfoList()));
    }
  };

  const handleBatchUpdate = type => {
    setBatchUpdateType(type);
    setBatchUpdateOpen(true);
  };

  const handlebatchUpdateMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverClose = () => {
    setBatchUpdateMsgOpen(false);
    setAnchorEl(null);
  };

  const handleBatchUpdateClose = () => {
    setBatchUpdateOpen(false);
    handlebatchUpdateMenuClose();
  };

  //搜索函数
  const handleSearchCR = content => {
    unstable_batchedUpdates(() => {
      // 修改搜索框之后把页码置为1
      dispatch(handleCRCPageChangeAction(1));
      dispatch(setSearchContent(content));
    });
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <NewDesignedMain
      id='cr-contact-overview-paper'
      data-selenium-id='CRContact_Overview-PaperNewDesignedMain'
      open={open}
      sx={{
        paddingTop:'24px',
        paddingRight:'26px',
        paddingLeft:'20px',
        paddingBottom:'50px',
      }}
    >
      <Typography
        id='cr-contact-overview-title'
        data-selenium-id='CRContact_Overview-TitleTypography'
        sx={{
          fontWeight: 600,
          fontSize: '22px',
          lineHeight: '125%',
          letterSpacing: '0.02em',
          color: '#262E35',
        }}
      >
        PRP Contact
      </Typography>

      <Box
        id='cr-contact-overview-action-box'
        data-selenium-id='CRContact_Overview-ActionBox'
        mt={2}
        sx={{ display: 'flex' }}
      >
        <Grid container sx={{marginBottom:'16px'}}>
          <Grid item xs={10} data-selenium-id='CRContact_Overview-Grid1' spacing={2} alignItems="center" justifyContent="center">
            <Select
              id='cr-contact-overview-search-select'
              data-selenium-id='CRContact_Overview-SearchSelect'
              defaultValue={searchBy}
              value={searchBy}
              // inputProps={{ readOnly: true }} // 点击输入框时不获取焦点
              onChange={e => {
                unstable_batchedUpdates(() => {
                  // 修改搜索域后页码置为1
                  dispatch(handleCRCPageChangeAction(1));
                  setSearchBy(e.target.value);
                });
              }}
              open={isOpen}
              onClose={handleClose}
              onOpen={handleOpen}
              onClick={()=>{if(isOpen)handleClose();else handleOpen();}}
              IconComponent={() => (
                <div style={{ paddingRight:'14px',paddingBottom:'3px',zIndex:0}} >
                  <IconButtonNewStyle>
                    <SelectWhiteArrow ></SelectWhiteArrow>
                  </IconButtonNewStyle>
                </div>
              )}
              sx={{
                height: '34px',
                width:'113px',
                borderRadius:'5px',
                color: '#FFFFFF',
                backgroundColor:isOpen?'#113D95':'#0052CC',
                mr: 1.5,
                mt:0.3,
                fontSize:'12px',
                fontFamily: 'Inter',
                fontWeight:600,
                lineHeight:'18px',
                alignItems:'center',
                whiteSpace:'nowrap',
                textOverflow:'initial',
                zIndex:1,
                '.MuiOutlinedInput-input.MuiSelect-select':{
                  textOverflow:'initial!important',
                },
                marginRight:'8px',
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: '6px', // 间距设置为6px
                    boxShadow:'0px 6px 12px 0px #262E3514'
                  },
                },
              }}
            >
              <MenuItem
                id='crContactOverviewJournalItem'
                data-selenium-id='CRContact_Overview-JournalItem'
                value={'JOURNAL'}
                sx={{
                  width:'113px',
                  height:'24px',
                  fontFamily:'OpenSans',
                  fontWeight:400,
                  fontSize:'12px',
                  lineHeight:'166.667%!important',  
                  backgroundColor:searchBy === 'JOURNAL' ? '#F1F3F5!important' : '#FFFFFF',
                  color:searchBy === 'JOURNAL' ? '#262E35' : '#596A7C',
                }}
              >
                Journal
              </MenuItem>
              <MenuItem
                id='crContactOverviewSearchSelectPRPLOrPRPMItem'
                data-selenium-id='CRContact_Overview-SearchSelectPRPLOrPRPMItem'
                value={'PRPL/PRPM'}
                sx={{
                  width:'113px',
                  height:'20px',
                  fontFamily:'OpenSans',
                  fontWeight:400,
                  fontSize:'12px',
                  lineHeight:'166.667%!important',  
                  marginTop:'4px',
                  backgroundColor:searchBy === 'PRPL/PRPM' ? '#F1F3F5!important' : '#FFFFFF',
                  color:searchBy === 'PRPL/PRPM' ? '#262E35' : '#596A7C',
                }}

              >
                PRPL/PRPM
              </MenuItem>
            </Select>
            <DelaySearchTextField
              id='cr-contact-overview-search-field'
              data-selenium-id='CRContact_Overview-DelaySearchTextField'
              placeholder='Search Journal (use comma to separate keywords)'
              size='small'
              delay={300}
              handleSearch={handleSearchCR}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon1 />
                  </InputAdornment>
                ),
              }}
              sx={{
                background: '#FFFFFF',
                height: 'fit-content',
                width: '375px',
                
                '[placeholder]': {
                  textOverflow: 'ellipsis',
                  fontFamily:'Open Sans',
                  fontWeight:400,
                  fontSize:'14px',
                  lineHeight:'17.5px',
                  paddingRight:'12px',
                  // color:'#BCC5CF',
                },
                '& .MuiOutlinedInput-root': {
                  paddingLeft: '12px',
                  height:'40px',
                },
                '& .MuiInputBase-input-MuiOutlinedInput-input':{
                  paddingRight:'12px',
                },
                '& .css-1dzio5w-MuiInputBase-root-MuiOutlinedInput-root':{
                  lineHeight:'125%!important',
                }
              }}
            />
            
          </Grid>
          {/* {*<Grid
            item
            xs={2}
            display='flex'
            flexDirection='column'
            alignItems='end'
            data-selenium-id='CRContact_Overview-Grid2'
          >
            <SIMTButton
              id='cr-contact-overview-batch-update-button'
              data-selenium-id='CRContact_Overview-BatchUpdateButton'
              color='wileyBlue2'
              variant='contained'
              aria-controls={batchUpdateMenuOpen ? 'export-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={batchUpdateMenuOpen ? 'true' : undefined}
              onClick={handleBatchUpdateClick}
              startIcon={<BatchUpdateIcon />}
            >
              Batch Update
            </SIMTButton>
            {/*batchUpdate的menu*/}
            {/* <Menu
              id='export-menu'
              data-selenium-id='CRContact_Overview-ExportMenu'
              anchorEl={anchorEl}
              open={batchUpdateMenuOpen}
              MenuListProps={{
                'aria-labelledby': 'export-button',
              }}
              onClose={handlebatchUpdateMenuClose}
            >
              <MenuItem
                id='MenuItem01'
                data-selenium-id='CRContact_Overview-MenuItem01'
                sx={{ width: '142px' }}
                onClick={() => {
                  handleBatchUpdate('AME');
                }}
              >
                AME
              </MenuItem>
              <MenuItem
                id='MenuItem02'
                data-selenium-id='CRContact_Overview-MenuItem02'
                sx={{ width: '142px' }}
                onClick={() => {
                  handleBatchUpdate('ME');
                }}
              >
                ME
              </MenuItem>
            </Menu>
            {/*batchUpdate的消息提示*/}
          {/* <Popover
              open={batchUpdateMsgOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Typography
                id='crContactBatchUpdateHintText'
                data-selenium-id='CRContact_Overview-BatchUpdateHintText'
                sx={{ p: 2 }}
              >
                Please select at least one Journal.
              </Typography>
            </Popover> */} 
          {/* batchUpdate弹窗 
            <BatchUpdatePage
              open={batchUpdateOpen}
              type={batchUpdateType}
              handleCloce={handleBatchUpdateClose}
              searchBy={searchBy}
              data-selenium-id='CRContact_Overview-Grid2-BatchUpdatePage'
            ></BatchUpdatePage>
          </Grid> */}
          {/* <Grid p={1} data-selenium-id='CRContact_Overview-Grid3'>
            <div
              style={{
                color: '#828282',
                'font-weight': 700,
                'font-size': '13px',
                'line-height': '16px',
              }}
            >
              If you would like to change the AME/ME associated with the
              journal, please use the "Update" function.
            </div>
          </Grid> */}
        </Grid>
      </Box>

      <CRTable
        searchBy={searchBy}
        data-selenium-id='CRContact_Overview-CRTable'
      />
    </NewDesignedMain>
  );
}

import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { sipTitleComponents } from './SIPStepTitle';

// origin是Origin: XXX
// currStep是它上面的step（这个时候主要是用于显示颜色）
function SIPStepSubtitle(props) {
  const { origin, color, ...other } = props;
  return (
    <Typography
      data-selenium-id='SIPPage_Components-Step-Subtitle-Typography'
      fontFamily='Open Sans'
      fontSize='12px'
      fontStyle='normal'
      fontWeight={400}
      lineHeight='18px'
      color={color ?? 'Primary100.main'} // here use currStep
      textAlign='center'
      {...other}
    >
      {/* here use originalStep */}
      <FormattedMessage
        id='sipDetail.steps.subtitle'
        data-selenium-id='SIPPage_Components-Step-Subtitle-Detail'
        values={{ origin: sipTitleComponents[origin] ?? origin }}
      />
    </Typography>
  );
}

SIPStepSubtitle.propTypes = {
  origin: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default SIPStepSubtitle;

import { SmallFont } from '@/components/FontStyle';
import {
  Box,
  InputAdornment,
  TextField,
  ThemeProvider,
} from '@mui/material';
import DeleteTag from '../CGTComponents/SVGComponents/DeleteTag.svg';
import { AllCentered } from './CommonStyle';
import { themeTextField } from './CommonTheme';


export default function InputBox(props) {
  const { id,value = '',disableClearable= false,onDelete=()=>{},placeholder='placeholder',...other } = props;
  
  return (
    <ThemeProvider theme={themeTextField}>
      <TextField
        id={id}
        data-selenium-id={id}
        placeholder={placeholder}
        value={value}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border:
            (value && value !== '') || (value === 0) ? '1px solid #98A7B6' : '1px solid #DFE4E8',
            // value !== '' || value === '0' ? '1px solid #98A7B6' : '1px solid #DFE4E8',
            borderRadius: '4px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #262E35',
          },
          //'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          //  border: '2px solid #0052CC',
          //},
          '& .Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: value && value !== '' ? '2px solid #0052CC !important'
                  : '2px solid #154AB6 !important',
            },
          },
          '& .Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: '2px solid #EE5350 !important',
              borderRadius: '4px',
            },
            '& .MuiOutlinedInput-notchedOutline:hover': {
              border: '2px solid #EE5350 !important',
            },
            '& .MuiOutlinedInput-notchedOutline:focus': {
              border: '2px solid #EE5350 !important',
            },
            '& MuiSelect-outlined': {
              padding: '2px !important',
            },
          },
          '& .MuiOutlinedInput-root': {
            background: '#FFFFFF',
            borderRadius: 4,
            boxSizing: 'border-box',
            height: '40px',
            ...SmallFont,
            lineHeight: '125%',
            cursor: 'pointer',
            padding: '11px 12px',
          },
          '& .MuiOutlinedInput-input': {
            padding: '0px 0px',
            '&::placeholder': {
              color: '#BCC5CF',
              opacity: '1',
            },
          },
        }}
        
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Box
                sx={{
                  '& :hover': {
                    backgroundColor: '#FFFFFF',
                    borderRadius: '2px',
                  },
                  '& :focus': {
                    backgroundColor: '#FFFFFF',
                    borderRadius: '2px',
                  },
                  ...AllCentered,
                  display:disableClearable?'none':'flex',
                }}
                onClick={onDelete}
              >
                <DeleteTag></DeleteTag>
              </Box>
            </InputAdornment>
          ),
        }}
        {...other}
      ></TextField>
    </ThemeProvider>
  );
}

import {axios_instance} from "@/utils/axios_instance";
import {
    GET_ARTICLE_ANNUAL_TRENDS_BUSINESS_DIVISION,
    GET_ARTICLE_ANNUAL_TRENDS_SUBJECT_GROUP,
    GET_ARTICLE_LAST_ACTION_DATE,
    GET_ARTICLE_LAST_ACTION_DATE_FY,
    GET_ARTICLE_OAP_BASIC,
    GET_ARTICLE_OAP_BUSINESS,
    GET_ARTICLE_OAP_CE,
    GET_ARTICLE_OAP_SUBJECT,
    GET_ARTICLE_OAWAIVERUSE_BASIC,
    GET_ARTICLE_OAWAIVERUSE_BUSINESS,
    GET_ARTICLE_OAWAIVERUSE_CE,
    GET_ARTICLE_OAWAIVERUSE_SUBJECT,
    GET_ARTICLE_OVERVIEW_BASIC,
    GET_ARTICLE_OVERVIEW_BUSINESS,
    GET_ARTICLE_OVERVIEW_CE,
    GET_ARTICLE_OVERVIEW_SUBJECT,
    GET_ARTICLES_OA_VS_TOTAL_BD_DATA,
    GET_ARTICLES_OA_VS_TOTAL_SG_DATA
} from "@/actions/SIMT-Report/Report/ReportAction";
import getAuth from "@/utils/getAuth";


export function getReportArticleOAPBasicTableData(
    page,
    pageSize,
    order,
    orderBy,
    selectDate,
    filterMap = {}
) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];

    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);

    return async dispatch => {
        try {
            let res = await axios_instance.post(
                '/reports/article/oa-in-hybrid/basic',
                filterMap,
                {
                    params: {
                        pageNo: page,
                        limit: pageSize,
                        sortMethod: '',
                        sortKey: '',
                        selectedDate: date,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );

            if (res.data.code === 200) {
                let returnData;
                if (res.data.data && res.data.data.result) {
                    returnData = res.data;
                } else {
                    returnData = {result: {data: [], month: []}, count: 0};
                }
                dispatch({type: GET_ARTICLE_OAP_BASIC, data: returnData});
            } else {
                dispatch({
                    type: GET_ARTICLE_OAP_BASIC,
                    data: {result: {data: [], month: []}, count: 0},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ARTICLE_OAP_BASIC,
                data: {result: {data: [], month: []}, count: 0},
            });
        }
    };
}

export function GetArticleOAPSubjectTableData(selectDate) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var selectedDate = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/article/oa-in-hybrid/sg/${selectedDate}`,
                {
                    //params: {
                    //  selectedDate: date,
                    //},
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                let returnData;
                if (res.data.data && res.data.data.result) {
                    returnData = res.data;
                } else {
                    returnData = {result: {data: [], month: []}, count: 0};
                }
                dispatch({type: GET_ARTICLE_OAP_SUBJECT, data: returnData});
            } else {
                dispatch({
                    type: GET_ARTICLE_OAP_SUBJECT,
                    data: {result: {data: [], month: []}, count: 0},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ARTICLE_OAP_SUBJECT,
                data: {result: {data: [], month: []}, count: 0},
            });
        }
    };
}

export function GetArticleOAPBusinessTableData(selectDate) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var selectedDate = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/article/oa-in-hybrid/bd/${selectedDate}`,
                {
                    //params: {
                    //  selectedDate: date,
                    //},
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                let returnData;
                if (res.data.data && res.data.data.result) {
                    returnData = res.data;
                } else {
                    returnData = {result: {data: [], month: []}, count: 0};
                }
                dispatch({type: GET_ARTICLE_OAP_BUSINESS, data: returnData});
            } else {
                dispatch({
                    type: GET_ARTICLE_OAP_BUSINESS,
                    data: {result: {data: [], month: []}, count: 0},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ARTICLE_OAP_BUSINESS,
                data: {result: {data: [], month: []}, count: 0},
            });
        }
    };
}

export function GetArticleOAPCETableData(selectDate) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var selectedDate = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/article/oa-in-hybrid/ce/${selectedDate}`,
                {
                    //params: {
                    //  selectedDate: date,
                    //},
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                let returnData;
                if (res.data.data && res.data.data.result) {
                    returnData = res.data;
                } else {
                    returnData = {result: {data: [], month: []}, count: 0};
                }
                dispatch({type: GET_ARTICLE_OAP_CE, data: returnData});
            } else {
                dispatch({
                    type: GET_ARTICLE_OAP_CE,
                    data: {result: {data: [], month: []}, count: 0},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ARTICLE_OAP_CE,
                data: {result: {data: [], month: []}, count: 0},
            });
        }
    };
}

export function getReportArticleOAWaiverUseBasicTableData(
    page,
    pageSize,
    selectDate,
    filterMap = {}
) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];

    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);

    return async dispatch => {
        try {
            let res = await axios_instance.post(
                '/reports/article/oa-waiver-use/basic',
                filterMap,
                {
                    params: {
                        pageNo: page,
                        limit: pageSize,
                        selectedDate: date,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );

            if (res.data.code === 200) {
                let returnData;
                if (res.data.data && res.data.data.result) {
                    returnData = res.data;
                } else {
                    returnData = {result: {data: [], month: []}, count: 0};
                }
                dispatch({type: GET_ARTICLE_OAWAIVERUSE_BASIC, data: returnData});
            } else {
                dispatch({
                    type: GET_ARTICLE_OAWAIVERUSE_BASIC,
                    data: {result: {data: [], month: []}, count: 0},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ARTICLE_OVERVIEW_BASIC,
                data: {result: {data: [], month: []}, count: 0},
            });
        }
    };
}

export function GetArticleOAWaiverUseSubjectTableData(selectDate) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var selectedDate = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/article/oa-waiver-use/sj/${selectedDate}`,
                {
                    //params: {
                    //  selectedDate: date,
                    //},
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: GET_ARTICLE_OAWAIVERUSE_SUBJECT, data: res.data});
            } else {
                dispatch({
                    type: GET_ARTICLE_OAWAIVERUSE_SUBJECT,
                    data: {data: [], month: []},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ARTICLE_OAWAIVERUSE_SUBJECT,
                data: {data: [], month: []},
            });
        }
    };
}

export function GetArticleOAWaiverUseBusinessTableData(selectDate) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var selectedDate = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/article/oa-waiver-use/bd/${selectedDate}`,
                {
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: GET_ARTICLE_OAWAIVERUSE_BUSINESS, data: res.data});
            } else {
                dispatch({
                    type: GET_ARTICLE_OAWAIVERUSE_BUSINESS,
                    data: {data: [], month: []},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ARTICLE_OAWAIVERUSE_BUSINESS,
                data: {data: [], month: []},
            });
        }
    };
}

export function GetArticleOAWaiverUseCETableData(selectDate) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var selectedDate = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/article/oa-waiver-use/ce/${selectedDate}`,
                {
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: GET_ARTICLE_OAWAIVERUSE_CE, data: res.data});
            } else {
                dispatch({
                    type: GET_ARTICLE_OAWAIVERUSE_CE,
                    data: {data: [], month: []},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ARTICLE_OAWAIVERUSE_CE,
                data: {data: [], month: []},
            });
        }
    };
}

/**
 * @author HuangTao
 * @date 2022/5/13
 * @description 获取ArticleOverview页面basictable的展示信息
 * @returns
 * @param page
 * @param pageSize
 * @param order
 * @param orderBy
 * @param selectDate
 * @param filterMap
 */
export function getReportArticleOverviewBasicTableData(
    page,
    pageSize,
    order,
    orderBy,
    selectDate,
    filterMap = {}
) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];

    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);

    return async dispatch => {
        try {
            let res = await axios_instance.post(
                '/reports/article/overview/basic',
                filterMap,
                {
                    params: {
                        pageNo: page,
                        limit: pageSize,
                        sortMethod: order,
                        sortKey: orderBy,
                        selectedDate: date,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );

            if (res.data.code === 200) {
                let returnData;
                if (res.data.data && res.data.data.result) {
                    returnData = res.data;
                } else {
                    returnData = {result: {data: [], month: []}, count: 0};
                }
                dispatch({type: GET_ARTICLE_OVERVIEW_BASIC, data: returnData});
            } else {
                dispatch({
                    type: GET_ARTICLE_OVERVIEW_BASIC,
                    data: {result: {data: [], month: []}, count: 0},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ARTICLE_OVERVIEW_BASIC,
                data: {result: {data: [], month: []}, count: 0},
            });
        }
    };
}

export function GetArticleOverviewSubjectTableData(selectDate, isOA) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/article/overview/sg/${date}`,
                {
                    params: {
                        isOA: isOA,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: GET_ARTICLE_OVERVIEW_SUBJECT, data: res.data});
            } else {
                dispatch({
                    type: GET_ARTICLE_OVERVIEW_SUBJECT,
                    data: {data: [], month: []},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ARTICLE_OVERVIEW_SUBJECT,
                data: {data: [], month: []},
            });
        }
    };
}

export function GetArticleOverviewBusinessTableData(selectDate, isOA) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/article/overview/bd/${date}`,
                {
                    params: {
                        isOA: isOA,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: GET_ARTICLE_OVERVIEW_BUSINESS, data: res.data});
            } else {
                dispatch({
                    type: GET_ARTICLE_OVERVIEW_BUSINESS,
                    data: {data: [], month: []},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ARTICLE_OVERVIEW_BUSINESS,
                data: {data: [], month: []},
            });
        }
    };
}

export function GetArticleOverviewCETableData(selectDate, isOA) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/article/overview/ce/${date}`,
                {
                    params: {
                        isOA: isOA,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: GET_ARTICLE_OVERVIEW_CE, data: res.data});
            } else {
                dispatch({
                    type: GET_ARTICLE_OVERVIEW_CE,
                    data: {data: [], month: []},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ARTICLE_OVERVIEW_CE,
                data: {data: [], month: []},
            });
        }
    };
}

export function GetArticleAnnualTrendsSubjectGroupTableData(selectDate, isOA) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/article/annual-trends/sg/${date}`,
                {
                    params: {
                        isOA: isOA,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({
                    type: GET_ARTICLE_ANNUAL_TRENDS_SUBJECT_GROUP,
                    data: res.data.data,
                });
            } else {
                dispatch({
                    type: GET_ARTICLE_ANNUAL_TRENDS_SUBJECT_GROUP,
                    data: 'error',
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ARTICLE_ANNUAL_TRENDS_SUBJECT_GROUP,
                data: 'error',
            });
        }
    };
}

export function getArticleOAvsTotalSGData(newDate) {
    var selectDateYear = newDate.split('-')[0];
    var selectDateMonth = newDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/article/oa-vs-total/sg/${date}`,
                {
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({
                    type: GET_ARTICLES_OA_VS_TOTAL_SG_DATA,
                    data: res.data.data,
                });
            } else {
                dispatch({type: GET_ARTICLES_OA_VS_TOTAL_SG_DATA, data: []});
            }
        } catch (error) {
            dispatch({type: GET_ARTICLES_OA_VS_TOTAL_SG_DATA, data: []});
        }
    };
}

export function getArticlesOAvsTotalBDData(newDate) {
    var selectDateYear = newDate.split('-')[0];
    var selectDateMonth = newDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/article/oa-vs-total/bd/${date}`,
                {
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({
                    type: GET_ARTICLES_OA_VS_TOTAL_BD_DATA,
                    data: res.data.data,
                });
            } else {
                dispatch({type: GET_ARTICLES_OA_VS_TOTAL_BD_DATA, data: []});
            }
        } catch (error) {
            dispatch({type: GET_ARTICLES_OA_VS_TOTAL_BD_DATA, data: []});
        }
    };
}

export function GetArticleAnnualTrendsBusinessDivisionTableData(
    selectDate,
    isOA
) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/article/annual-trends/bd/${date}`,
                {
                    params: {
                        isOA: isOA,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({
                    type: GET_ARTICLE_ANNUAL_TRENDS_BUSINESS_DIVISION,
                    data: res.data.data,
                });
            } else {
                dispatch({
                    type: GET_ARTICLE_ANNUAL_TRENDS_BUSINESS_DIVISION,
                    data: 'error',
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ARTICLE_ANNUAL_TRENDS_BUSINESS_DIVISION,
                data: 'error',
            });
        }
    };
}

export function GetArticleLastActionDate(selectDate, isOA) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/article/last-action-date/${date}`,
                {
                    params: {
                        isOA: isOA,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: GET_ARTICLE_LAST_ACTION_DATE, data: res.data});
            } else {
                dispatch({
                    type: GET_ARTICLE_LAST_ACTION_DATE,
                    data: {data: []},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ARTICLE_LAST_ACTION_DATE,
                data: {data: []},
            });
        }
    };
}

export function GetArticleLastActionDateFY(fiscalYear, isOA) {
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/article/last-action-date/fy/${fiscalYear}`,
                {
                    params: {
                        isOA: isOA,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: GET_ARTICLE_LAST_ACTION_DATE_FY, data: res.data});
            } else {
                dispatch({
                    type: GET_ARTICLE_LAST_ACTION_DATE_FY,
                    data: {data: []},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_ARTICLE_LAST_ACTION_DATE_FY,
                data: {data: []},
            });
        }
    };
}
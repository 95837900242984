import { Slider } from "@mui/material";

export default function StyledSlider(props) {
  const {
    sx,
    error=false,
    disabled=false,
    displayDisabled=false,//显示为灰色，但是不禁用。甲方的逆天UI是这样的
    ...other
  } = props;
  return <Slider
    sx={{
      width: '100%',
      padding:'6px 0px',
      '.MuiSlider-track':{
        backgroundColor: error?'#EE5350': (disabled||displayDisabled)?'#BCC5CF':'#154AB6',
        border:'0px solid transparent !important',
      },
      '.MuiSlider-rail':{
        backgroundColor: '#E2E8F0',
      },
      '.MuiSlider-thumb':{
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 2px 0px #0000000F , 0px 1px 3px 0px #0000001A',
        height: '16px',
        width: '16px',
      },
      ...sx
    }}
    disabled={disabled}
    {...other} />;
}
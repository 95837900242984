import { styled, TableCell, tableCellClasses } from '@mui/material';
import { MiniBoldFont, SmallFont } from '../../../../components/FontStyle';

export const StyledTableCell = styled(TableCell)((props) => {
  const {VerticalPadding='0px',isDoubleLine=false,maxWidth='260px',minWidth='0px',lineHeight='18px'}=props;
  return ({
    
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F1F3F5',
      ...MiniBoldFont,
      textTransform: 'uppercase',
      letterSpacing: '0.08em',
      lineHeight:lineHeight,
    },
    [`&.${tableCellClasses.body}`]: {
      ...SmallFont,
      letterSpacing: '0.01em',
      background: '#FFFFFF',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    [`&.${tableCellClasses.root}`]: { 
      height:'42px',
      paddingLeft:'24px',
      paddingRight:'24px',
      paddingTop:VerticalPadding,
      paddingBottom:VerticalPadding,
      maxWidth:maxWidth,
      minWidth:minWidth,
      lineHeight:lineHeight,
    },
});}
);

export const LimitWord = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export const AllCentered = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const RowCentered = {
  display: 'flex',
  justifyContent: 'center',
};

export const ColumnCentered = {
  display: 'flex',
  alignItems: 'center',
};

export const SemiBoldFont = {
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '18px',
  letterSpacing: '0em',
};

export const  leftFloat = {
  float:'left'
};

export const rightFloat = {
  float:'right'
};
// Acctive account by email and digitalSignature
import { axios_instance } from "@/utils/axios_instance";
import { ACTIVE_ACCOUNT } from "@/actions/SIMT-User/User/UserAction";

export function activeAccount(digitalsignature, email) {
    return async dispatch => {
        try {
            const res = await axios_instance.post(`/api/v1/user-service/users/activate`, {},  {
                params: {
                    email,
                    signature: digitalsignature
                }
            });

            if (res.data.code === 200) {
                dispatch({ type: ACTIVE_ACCOUNT, data: true });
            } else {
                dispatch({ type: ACTIVE_ACCOUNT, data: false });
            }
        } catch (err) {
            dispatch({ type: ACTIVE_ACCOUNT, data: err.response.data.msg });
        }
    };
}
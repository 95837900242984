import { paramsEncode } from "@/utils/commonUtils";
import { axios_instance } from "@/utils/axios_instance";
import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";
import { GET_SPECIAL_ISSUE, GET_SPECIAL_ISSUE_BY_CODES } from "@/actions/SIMT-SI/SpecialIssue/SIAction";

/**
 * 获得SI
 * @param page
 * @param pageSize
 * @param order
 * @param orderBy
 * @param searchSI
 * @param filters
 * @param requestNo 请求编号，为每个请求增加一个由小到大递增的编号，用于区分请求的先后
 * @return {(function(*): Promise<void>)|*}
 */
export function getSIData(
    page,
    pageSize,
    order,
    orderBy,
    searchSI,
    filters = {},
    requestNo = 0, // 默认为一个极小数
    withCMOnly = true
) {
    let filter2 = filters ?? {};
    // `filters` might be a null value, we will change it to an empty obj instead
    const journalPattern = /([^()]+)\(([^)]+)\)/; 
    // example: AABB Newsletter(AABB)
    if (
        filter2.acquiredDateFrom !== undefined &&
        filter2.acquiredDateFrom !== null &&
        filter2.acquiredDateFrom !== [null] &&
        filter2.acquiredDateTo !== undefined &&
        filter2.acquiredDateTo !== null &&
        filter2.acquiredDateTo !== [null]
    ) {
        if (
            filter2.acquiredDateFrom.length > 0 &&
            filter2.acquiredDateTo.length === 0
        ) {
            filter2.acquiredDateTo = ['2100-01-01T00:00:00.000Z'];
        }
        if (
            filter2.acquiredDateFrom.length === 0 &&
            filter2.acquiredDateTo.length > 0
        ) {
            filter2.acquiredDateFrom = ['1970-01-01T00:00:00.000Z'];
        }
    }
    if (
        filter2.submissionDeadlineFrom !== undefined &&
        filter2.submissionDeadlineFrom !== null &&
        filter2.submissionDeadlineTo !== undefined &&
        filter2.submissionDeadlineTo !== null
    ) {
        if (
            filter2.submissionDeadlineFrom.length > 0 &&
            filter2.submissionDeadlineTo.length === 0
        ) {
            filter2.submissionDeadlineTo = ['2100-01-01T00:00:00.000Z'];
        }
        if (
            filter2.submissionDeadlineFrom.length === 0 &&
            filter2.submissionDeadlineTo.length > 0
        ) {
            filter2.submissionDeadlineFrom = ['1970-01-01T00:00:00.000Z'];
        }
    }
    if (filter2.journalName) {
        const journalNameArray = [];
        for (let journal of filter2.journalName) {
            const match = journal.match(journalPattern);
            if (match) {
                // const journalCode = match[2];
                const journalName = match[1];
                journalNameArray.push(journalName);
                continue;
              }
            journalNameArray.push(journal);
        }
        filter2.journalName = journalNameArray;
    }
    return async dispatch => {
        try {
            let params = paramsEncode({
                pageNum: page,
                pageSize: pageSize,
                orderType: order,
                orderField: orderBy,
                ...(searchSI && { siTitleList: searchSI, }),
                showSisWithChinaMarketingOnly: withCMOnly,
                showSisWithChina: withCMOnly,
            });

            let res = await axios_instance.post('/api/v1/si-service/special-issues/overview',
                {
                    ...filter2,
                    ...params
                }, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            let siCodeListResult = [];

            if (res.data.code === 200) {
                let returnData;

                res.data.data.result.forEach(element => {
                    siCodeListResult.push(element.siCode);
                });

                if (res.data.data && res.data.data.result) {
                    returnData = res.data.data;
                } else {
                    returnData = { result: [], count: 0 };
                }

                dispatch({
                    type: GET_SPECIAL_ISSUE,
                    data: {
                        ...returnData,
                        siRequestNo: requestNo,
                        siCodeList: siCodeListResult,
                    },
                });
                // 在这里将siCodeResult 存在localStorage 就不用在每次排序调用时候都写一遍了
                localStorage.setItem('siCodeList', siCodeListResult);
            } else {
                dispatch({
                    type: GET_SPECIAL_ISSUE,
                    data: {
                        result: [],
                        count: 0,
                        siRequestNo: requestNo,
                        siCodeList: [],
                    },
                });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'common.failToLoad',
                        { value: 'common.sis' },
                        SEVERITIES.error
                    )
                );
                localStorage.setItem('siCodeList', []);
            }
        } catch (err) {
            dispatch({
                type: GET_SPECIAL_ISSUE,
                data: { result: [], count: 0, siRequestNo: requestNo, siCodeList: [] },
            });
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToLoad',
                    { value: 'common.sis' },
                    SEVERITIES.error
                )
            );
            localStorage.setItem('siCodeList', []);
        }
    };
}

export function getSIDataByCodes(siCodeList) {
    return async dispatch => {
        try {
            let params = paramsEncode({
                pageNum: 1,
                pageSize: siCodeList.length,
                siTitleList: '',
                orderType: 'asc',
                orderField: 'siCode',
            });

            let res = await axios_instance.post(
                '/api/v1/si-service/special-issues/overview',
                {
                    siCode: siCodeList,
                    ...params
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );

            if (res.data.code === 200) {
                dispatch({
                    type: GET_SPECIAL_ISSUE_BY_CODES,
                    data: res.data.data.result,
                });
            } else {
                dispatch({
                    type: GET_SPECIAL_ISSUE_BY_CODES,
                    data: [],
                });
            }
        } catch (err) {
            dispatch({
                type: GET_SPECIAL_ISSUE_BY_CODES,
                data: [],
            });
        }
    };
}
import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import { RegularBoldFont1 } from '@/components/FontStyle';

export default function MyDrawer(props) {
  const { defaultOpen, title } = props; //用于设置初始的状态值

  const [isFold, setISFold] = useState(defaultOpen);
  return (
    <Box>
      <Stack spacing={2} direction='column'>
        <Stack spacing={1} direction='row'>
          <Box
            sx={{ display: 'flex',cursor:'pointer' }}
            onClick={() => {
              setISFold(!isFold);
            }}
          >
            {isFold ? <MarketChevronUp /> : <SIDetailArrowDown />}
          </Box>
          <Box sx={{ ...RegularBoldFont1, color: '#113D95' }}>{title}</Box>
        </Stack>
        {isFold ? <Box>{props.children}</Box> : null}
      </Stack>
    </Box>
  );
}

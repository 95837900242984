import {
    extractHtmlId,
    FormComponentProps,
    MapState, SubForm,
    SubFormGroup,
    useFormContext,
    VALIDATE_KEY,
    ValidateState
} from "@/components/Form/form.d";
import React, {ReactElement, useEffect} from "react";
import SectionTitle from "@/pages/SIPPage/SIPCheckListPage/compontents/SectionTitle";
import {Box} from "@mui/material";
import BoxWithShadow from "@/pages/SIPPage/SIPCheckListPage/compontents/BoxWithShadow";
import IconChevron from "@/pages/SIPPage/SIPCheckListPage/assets/chevron.svg";
import styleModule from "@/pages/SIPPage/SIPCheckListPage/compontents/GEChecklistFolder.module.scss";

export interface SubFormGroupWrapperProps extends FormComponentProps {
    title?: string;
    subFormKey: string;
    children?: ReactElement | ReactElement[];
    ['sub-html-id']?: string;
}

export interface SubFormGroupPageProps {
    defaultOpen?: boolean,
    subFormKey: string;
    children?: ReactElement | ReactElement[];
    id?: string;
}

export function SubFormGroupWrapper(props: SubFormGroupWrapperProps): ReactElement{
    const htmlId = extractHtmlId(props);

    const form = useFormContext();
    const formKey = props.name;
    const formGroup = form?.get(formKey) as MapState[] || [];

    return (<Box id={htmlId}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
        }}
    >
        {props.title && <SectionTitle>{props.title}</SectionTitle>}
        <SubFormGroup formKey={props.name}>
            {
                formGroup.map((item, index) => {
                    const pageHtmlId = extractHtmlId({
                       ...props,
                       ['html-id']: props?.['sub-html-id'],
                       ['html-index']: index,
                       ['parent-html-id']: htmlId,
                    });
                    return <SubFormGroupPage
                        id={pageHtmlId}
                        key={index}
                        subFormKey={props.subFormKey}
                        defaultOpen={true}
                    >
                        {
                            React.Children.map(props.children, (child) => {
                                if (child != null) {
                                    return React.cloneElement(child, {
                                        ...child.props,
                                        ['parent-html-id']: pageHtmlId,
                                    });
                                }
                                return null;
                            })
                        }
                    </SubFormGroupPage>;
                })
            }
        </SubFormGroup>
    </Box>);
}

export function SubFormGroupPage(props: SubFormGroupPageProps): ReactElement{
    // the GE form
    const form = useFormContext();
    const geId: number | null = form?.get('geId');
    const geName = form?.get('fullName') ?? 'No Name';

    const [folderOpen, setFolderOpen] = React.useState(props.defaultOpen || false);

    // attach the validation status to the form
    const validateState = () : ValidateState => {
        // validate the form.detail here
        const fromData =  form?.get(props.subFormKey) ?? {};

        let result: ValidateState = "checked";
        for (const key in fromData) {
            if(key.startsWith('__') && fromData.hasOwnProperty(key)){
                const itemState = (fromData[key] ?? {})[VALIDATE_KEY] ?? 'default';
                const isRequired = (fromData[key] ?? {})['required'] ?? false;
                if(!isRequired){
                    continue;
                }
                if(itemState == 'error'){
                    return 'error';
                }

                if(result == 'checked'){
                    result = itemState;
                }
            }
        }
        return result;
    };

    useEffect(() => {
        const validate = validateState();
        form?.set(VALIDATE_KEY, validate);
    }, [form?.get(props.subFormKey)]);

    const statusFolder = form?.get(VALIDATE_KEY) ?? 'default';

    return (
        <BoxWithShadow
            id={props.id}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                overflowX:'auto',
            }}
        >
            {/* 头部 */}
            <Box
                sx={{
                    display: 'flex',
                    height: '75px',
                    padding: '24px 16px 24px 16px',
                    gap: '10px',
                    alignItems: 'center',
                    backgroundColor:
                        statusFolder === 'error'
                            ? '#FFDDDD'
                            : statusFolder === 'checked'
                                ? '#D7F1EE'
                                : '#F1F3F5',
                    color:
                        statusFolder === 'error'
                            ? '#C40000'
                            : statusFolder === 'checked'
                                ? '#0D6A61'
                                : '#596A7C',
                    '& path': {
                        fill:
                            statusFolder === 'error'
                                ? '#C40000 !important'
                                : statusFolder === 'checked'
                                    ? '#0D6A61 !important'
                                    : '#596A7C !important',
                    },
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setFolderOpen(!folderOpen);
                }}
            >
                <IconChevron
                    style={{
                        transform: folderOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                />
                <Box
                    sx={{
                        fontSize: '20px',
                        lineHeight: '27px',
                        fontWeight: 600,
                        fontFamily: 'Open Sans',
                        letterSpacing: '0em',
                    }}
                >
                    {geName}
                </Box>
            </Box>
            {/* 内容 */}
            <Box className={styleModule.GEChecklistFolder}
                 sx={{
                     height: folderOpen ? 'auto' : '0px',
                     overflow: 'hidden',
                 }}
            >
                <SubForm formKey={props.subFormKey}>
                    {props.children}
                </SubForm>
            </Box>
        </BoxWithShadow>
    );
}
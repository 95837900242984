import {
    ChecklistStatus,
    ComputedComponent,
    extractHtmlId,
    FormComponentProps,
    useForm,
    VALIDATE_KEY,
    ValidateState
} from "@/components/Form/form.d";
import {string} from "prop-types";
import ChecklistItemBlock from "@/pages/SIPPage/SIPCheckListPage/compontents/ChecklistItemBlock";
import React, {useEffect} from "react";
import RadioBlock from "@/pages/SIPPage/SIPCheckListPage/compontents/RadioBlock";
import {Box, CardContent, Grid, Stack, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {getFlaglist} from "@/actions/SIMT-SI/GuestEditor/Config";
import type {GeDataType, singleGeData} from "@/componentsphase2/FlaggedGESynchronizationAndVerificationTable";
import MandatoryIcon from "@/assets/MandatoryIcon.svg";
import {
    DropdownSelect,
    TextInputWithLimit,
    FileUpload,
    ShowFlag, ShowComment
} from "@/pages/SIPPage/SIPCheckListPage/compontents/GEFlagSection";
import {GetLabel} from "@/components/FileUploader/LabelFile";
import {ChooseIconByType, formatFileSize} from "@/pages/GEPage/GEDetailPage/FlagBlock";
import {downloadSingleGEFile} from "@/actions/SIMT-SI/GuestEditor/SipProposerFlag";
import {CHECKLIST_PROCEEDING} from "@/pages/SIPPage/SIPCheckListPage/configable/constants";

export interface CheckListGEFlagAdditionProps extends FormComponentProps {
    required?: boolean;
    label?: string;
    info?: string;
    validate_result: (string|number)[];
}

interface FlagData {
    proposerId: number;
    flagId: number;
    comment: string;
    fileNames: string[];
}

interface ConfigFlagData {
    id: number; // flagId
    type: "Flags-Severe Issue" | "Flags-Mild Issue";
    key: string;
    value: string; // flag name
}

interface FlagSectionProps {
    flag: FlagData;
    setFlag: (flag: FlagData) => void;
    editing: boolean;
    allFlags: ConfigFlagData[];
    ignored: ConfigFlagData[];
    proceeding: boolean;
}

function getConfigOfFlag(flagId: number, allFlags: ConfigFlagData[]): ConfigFlagData | null {
    for (const flag of allFlags) {
        if (flag.id === flagId) {
            return flag;
        }
    }
    return null;
}

function validateFlag(flag: FlagData, flagsList: ConfigFlagData[]) {
    if (flag.flagId === 0) {
        return false;
    }

    const config = getConfigOfFlag(flag.flagId, flagsList);
    if (config == null) {
        return false;
    }

    return !(config?.type === 'Flags-Severe Issue' && (flag?.fileNames?.length ?? 0) === 0);
}

function loadIgnoredFlags(geCode: string, siGeInfoList: ReadonlyArray<singleGeData>, flagsList: ConfigFlagData[]): ConfigFlagData[] {
    let geFlags: ConfigFlagData[] = [];
    if (geCode !== null && geCode !== '') {
        const ge = (siGeInfoList || []).find(item => item.geCode === geCode);
        if (ge !== undefined) {
            geFlags = (ge?.flags ?? []).map(flagName => {
                const flag = (flagsList || []).find(item => item.value === flagName);
                if (flag !== undefined) {
                    return flag;
                }
                return null;
            }).filter(x => x !== null) as ConfigFlagData[];
        }
    }
    return geFlags;
}

function CheckListGEFlagAdditionBase(props: CheckListGEFlagAdditionProps) {
    const htmlId = extractHtmlId(props);
    const {
        value: newFlagAddition,
        setValue: setNewFlagAddition,
        form,
        setState,
        getState,
    } = useForm<string|null>({
        name: props.name,
        initValue: null,
        initStates: {
            [VALIDATE_KEY]: 'default',
            required: props.required,
            ['html-id']: htmlId,
        },
        statesPath: props.validate_result,
    });

    const dispatch = useDispatch();
    const flagsList: ConfigFlagData[] = useSelector(state => {
        return (state as {
            GE: {
                flagsList: ConfigFlagData[];
            };
            [key: string]: any;
        })?.GE?.flagsList ?? [];
    });

    useEffect(() => {
        // if (flagsList.length === 0) {
        //     dispatch(getFlaglist());
        // }
        form.regist('flags', [], {});
    }, []);

    const flags: FlagData[] = form.get('flags') || [];
    const setFlags = (flags: FlagData[]) => {
        console.info('setFlags:', flags);
        form.set('flags', flags);
    };

    const geId = form.get('geId');
    const geCode: string = form.get('geCode');
    const siGeInfoList: GeDataType = form.get('geInfoList');

    const ignored = loadIgnoredFlags(geCode, siGeInfoList, flagsList);

    const addFlag = () => {
        const newFlags = [...flags];
        newFlags.push({
            proposerId: geId,
            flagId: 0,
            comment: '',
            fileNames: [],
        });
        setFlags(newFlags);
    };

    const status = form?.root().status;
    const locked = form?.root().locked;
    const editable = status === ChecklistStatus.IN_PROGRESS && !locked;

    const immediateDecision = form?.root().immediateDecision;
    const proceeding = form?.root()[CHECKLIST_PROCEEDING] ?? false;

    useEffect(() => {
        // TODO add props.required test in the validate function
        const validate = (): ValidateState => {
            if (newFlagAddition == null) {
                return proceeding? 'error' : 'default';
            } else if (newFlagAddition === 'No') {
                return 'checked';
            } else {
                let flagsValid = true;
                // check each of flag here
                for (const flag of flags) {
                    flagsValid = flagsValid && validateFlag(flag, flagsList);
                    if (!flagsValid) {
                        break;
                    }
                }
                return flagsValid? 'checked' : proceeding? 'error' : 'default';
            }
        };



        setState(VALIDATE_KEY, validate());
    }, [newFlagAddition, flags, proceeding, flagsList]);


    return (
        <ChecklistItemBlock
            id={htmlId}
            title={props.label}
            info={props.info}
            necessary={props.required && !immediateDecision}
            status={getState(VALIDATE_KEY)}
            // calcItemBlockStatusOfGEFlag(item.required, ge[item.key], ge)
            needClear={false}
            needAdd={ editable && newFlagAddition === 'Yes' }
            handleAdd={addFlag}
            alwaysShow={true}
        >
            <RadioBlock
                editing={editable}
                value={newFlagAddition}
                setValue={(newValue: string|null) => {
                    if (newValue === 'Yes') {
                        if (flags.length === 0) {
                            addFlag();
                        }
                    } else if(newValue === 'No') {
                        setFlags([]);
                    }
                    setNewFlagAddition(newValue);
                }}
                radioGropList={['Yes', 'No']}
                error={
                    props.required && !immediateDecision && proceeding && (newFlagAddition === null)
                }
                noTitle={true}
            />
            <Box sx={{ width: 1092,maxWidth: '100%',marginTop:'10px!important' }}>
                <Box data-selenium-id={`SIPChecklistPage-GEFlagSectiion-${editable? 'Edit': 'Show'}Box`}>
                    <CardContent
                        sx={{paddingLeft: 0, paddingRight: 0, paddingTop: '8.5px!important', paddingBottom: '32px!important',}}
                    >
                    {flags.map((flag, index) => (
                        <FlagSection
                            key={index}
                            flag={flag}
                            setFlag={(flag: any) => {
                                const newFlags = [...flags];
                                newFlags[index] = flag;
                                setFlags(newFlags);
                            }}
                            editing={editable}
                            allFlags={flagsList}
                            ignored={ignored}
                            proceeding={proceeding}
                        />
                    ))}
                    </CardContent>
                </Box>
            </Box>
        </ChecklistItemBlock>
    );
}

function FlagSection({flag, setFlag, editing, allFlags, ignored, proceeding}: FlagSectionProps) {
    allFlags = allFlags || [];
    const config = getConfigOfFlag(flag.flagId, allFlags);
    const isSevere = config?.type === 'Flags-Severe Issue';

    let FlagOptions = [];
    let SevereOptions: string[] = [];

    FlagOptions.push({ value: '', group: 'Category A', icon: 'severe issue' });
    for (const t_flag of allFlags) {
        if (t_flag.type === 'Flags-Severe Issue') {
            FlagOptions.push({ value: t_flag.value, group: 'Category A' });
            SevereOptions.push(t_flag.value);
        }
    }
    FlagOptions.push({ value: '', group: 'Category B', icon: 'mild issue' });
    for (const t_flag of allFlags) {
        if (t_flag.type === 'Flags-Mild Issue') FlagOptions.push({ value: t_flag.value, group: 'Category B' });
    }

    FlagOptions = FlagOptions.filter(t_flag => !ignored.some(ignore => ignore.value === t_flag.value) );

    const handleSelectChange = (newFlagName: string) => {
        const newFlagId = allFlags.find(item => item.value === newFlagName)?.id ?? 0;
        setFlag({
            ...flag,
            flagId: newFlagId,
        });
    };

    const handleTextChange = (text: string) => {
        setFlag({
            ...flag,
            comment: text,
        });
    };

    const handleFilesChange = (_: any, upfileName: string[], isUpload: {[key:number]: any}) =>{
        const fileNames = new Set<string>();
        for (let i = 0; i < Object.keys(isUpload)?.length; i++) {
            if (isUpload[i] === 1){
                fileNames.add(upfileName[i]);
            }
        }
        setFlag({
            ...flag,
            fileNames: [...fileNames],
        });
    };

    return (<Stack spacing={3} data-selenium-id='SIPChecklistPage-GEFlagSectiion-EditBox-Stack' sx={{ paddingTop: "8px", paddingBottom: "0px" }}>
            <Box data-selenium-id='SIPChecklistPage-GEFlagSectiion-EditBox-Left'>
                <Grid container spacing={1} justifyContent='flex-end'>
                    <Grid item xs={6}>
                        <Stack spacing={2.5} data-selenium-id='SIPChecklistPage-GEFlagSectiion-EditBox-LeftStack'>
                            <Box display='flex'>
                                <Box display='flex' sx={{ width: "56.38%",maxWidth: '100%', alignItems: "initial" }}>
                                    <Typography sx={{ color: "#596A7C", fontSize: "14px" }} data-selenium-id='SIPChecklistPage-GEFlagSectiion-Edit-LeftBox-FlagTypography'>
                                        {"Flag\u00A0\u00A0"}
                                    </Typography>
                                    {editing ? (
                                        <Stack sx={{ paddingTop: "4px" }} data-selenium-id='GEPage_GEDetailPage-FlagBlock-Edit-LeftBox-FlagIcon'>
                                            <MandatoryIcon></MandatoryIcon>
                                        </Stack>
                                    ) : null}
                                </Box>
                                {editing ? (
                                    <DropdownSelect
                                        onSelectChange={handleSelectChange}
                                        initialValue={config?.value}
                                        FlagOptions={FlagOptions}
                                        SevereOptions={SevereOptions}
                                        error={proceeding && flag.flagId == 0}
                                    ></DropdownSelect>
                                ) : (
                                    <Box
                                        display='flex'
                                        sx={{ width: '280px',maxWidth: '100%', }}
                                        data-selenium-id='SIPChecklistPage-GEFlagSectiion-ShowBox-LeftBox-Flag'
                                    >
                                        <ShowFlag
                                            flagType={config?.type}
                                            flagValue={config?.value}
                                        ></ShowFlag>
                                    </Box>
                                )}
                            </Box>
                            <Box
                                display='flex'
                                alignItems='center'
                                data-selenium-id='SIPChecklistPage-GEFlagSectiion-Edit-LeftBox-CommentTypography'
                                sx={{ marginTop: "0px!important" }}
                            >
                                <Box
                                    display='flex'
                                    sx={{
                                        width: "41.38%",
                                        maxWidth: '100%',
                                        paddingBottom: "13px",
                                        paddingTop: "0px",
                                    }}
                                >
                                    <Typography sx={{ color: "#596A7C", fontSize: "14px" }}>
                                        {"Comment"}
                                    </Typography>
                                </Box>
                                {editing ? (
                                    <Box display='flex' sx={{ width: "41.38%",maxWidth: '100%', paddingTop: "74px" }}>
                                        <TextInputWithLimit
                                            onTextChange={handleTextChange}
                                            initialValue={flag.comment}
                                        />
                                    </Box>
                                ): (
                                    <Box display='flex' sx={{ width: "41.38%" ,maxWidth: '100%',}}>
                                        <ShowComment comment={flag.comment}></ShowComment>
                                    </Box>
                                )}
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack
                            spacing={2.5}
                            data-selenium-id='SIPChecklistPage-GEFlagSectiion-EditBox-RightStack'
                        >
                            <Box display='flex'>
                                <Box
                                    display='flex'
                                    sx={{ width: "48.38%",maxWidth: '100%', }}
                                    data-selenium-id='SIPChecklistPage-GEFlagSectiion-Edit-RightBox-FileUpload'
                                >
                                    <Typography
                                        sx={{ color: "#596A7C", fontSize: "14px" }}
                                    >
                                        {"\u00A0\u00A0\u00A0\u00A0Evidence\u00A0\u00A0"}
                                    </Typography>
                                    {isSevere ? (<Stack sx={{ paddingTop: "4px" }}>
                                        <MandatoryIcon></MandatoryIcon>
                                    </Stack>) : (<></>)}
                                </Box>
                                {editing ? (
                                    <FileUpload
                                        onFileChange={handleFilesChange}
                                        isSevere={isSevere}
                                        initialValue={flag.fileNames}
                                        proceeding={proceeding}
                                    ></FileUpload>
                                ):(
                                    <Box display='flex' sx={{ width: '286px',maxWidth: '100%', }}>
                                        <ShowFile fileNames={flag.fileNames}/>
                                    </Box>
                                )}
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Stack>);
}

function ShowFile({fileNames}: {fileNames: string[]}) {
    const dispatch = useDispatch();

    const DownloadSingleFile = (filename: string) => {
        let downloadName = filename;
        if (filename.split('_').length > 2) {
            downloadName = filename.split("_").slice(2).join('_');
        }
        dispatch(downloadSingleGEFile(filename, downloadName));
    };

    return (<Stack
        position='relative'
        sx={{
            paddingLeft: '0px',
        }}
    >
        {fileNames !== undefined && fileNames?.length > 0 ? (fileNames.map((file, index) => {
            file = file || '234_xyz_test.xls';
            const fileNameOnly = file.split("_").slice(2).join('_');
            return (<GetLabel
                sx={{
                    marginTop: index === 0 ? "0px" : "8px", zIndex: 500,
                    //   marginLeft: "28px"
                }}
                FileIcon={ChooseIconByType(file.split(".")[file.split(".")?.length - 1])}
                del={() => DownloadSingleFile(file)}
                fileName={fileNameOnly.replace(/(.*)(?=\.)/, "$1")}
                fileSize={formatFileSize(file.split("_")[0])}
                fileMIMEType={file.split(".")[file.split(".")?.length - 1]}
                isDownLoad={true}
            />);
        })) : (<>-</>)}
    </Stack>);
}

export const CheckListGEFlagAddition = ComputedComponent<CheckListGEFlagAdditionProps>(CheckListGEFlagAdditionBase);

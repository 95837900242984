import { axios_instance } from "@/utils/axios_instance";
import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {
    APPROVE_OPT_IN_CODE,
    REJECT_OPT_IN_CODE,
    SET_OPT_IN_DATA,
    SUBMIT_OPTIN_CHECK_JOURNAL,
    SUBMIT_OPTIN_FAIL,
    SUBMIT_OPTIN_SUCCESS,
    ERROR_MESSAGE_COLLAPSE
} from "@/actions/SIMT-SI/CatJournal/CATJournalAction";
import { GetAllManagedJournals } from ".";


export function submitOptIn(data) {
    return async dispatch => {
        try {
            let res = await axios_instance.post('/api/v1/si-service/journals/managed/requests', data, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const { code, message } = res.data;
            if (code === 200) {
                dispatch({ type: SUBMIT_OPTIN_SUCCESS, data: message });
            } else {
                dispatch({ type: SUBMIT_OPTIN_FAIL, data: code });
            }
        } catch (e) {
            dispatch(setSnackbarMessageAndOpen(ERROR_MESSAGE_COLLAPSE, {}, SEVERITIES.error));
        }
    };
}

/**
 * @param {string} journalCode
 */
export function submitOptInCheckJournal(journalCode) {
    /**
     * @param {import("redux").Dispatch} dispatch
     */
    return async dispatch => {
        try {
            const { code } = await GetAllManagedJournals({
                journalCode, type: 'submitOptInRequestCheckJournal',
            }).then(e => e.data);
            if (code === 200) {
                dispatch({ type: SUBMIT_OPTIN_CHECK_JOURNAL, data: null });
            } else {
                dispatch({ type: SUBMIT_OPTIN_CHECK_JOURNAL, data: code });
            }
        } catch (e) {
            console.error(e);
            dispatch(setSnackbarMessageAndOpen(ERROR_MESSAGE_COLLAPSE, {}, SEVERITIES.error));
        }
    };
}

export const getOptInData = (pageNum, pageSize) => {
    return async dispatch => {
        try {
            const url = '/api/v1/si-service/journals/managed/requests';
            const res = await axios_instance.get(url, {
                params: {
                    pageNum: pageNum,
                    pageSize: pageSize,
                    type: 'opt:in'
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.status === 200) {
                if (res.data.code === 200) {
                    dispatch({ type: SET_OPT_IN_DATA, data: res.data.data });
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'catJournal.optInGetError',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            }
        } catch (e) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'catJournal.optInGetError',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
};
export const approveOptIn = (
    reason,
    handlingCE,
    scam,
    journalCode,
    page,
    pageSize
) => {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/journals/managed/${journalCode}/requests`;
            const res = await axios_instance.put(
                url,
                {
                    action: 'approve',
                    reason: reason,
                    handlingCeEmail: handlingCE,
                    scamEmail: scam,
                    journalCode: journalCode,
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.status === 200) {
                if (res.data.code === 200) {
                    dispatch({ type: APPROVE_OPT_IN_CODE, data: res.status });
                    dispatch(getOptInData(page, pageSize));
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'catJournal.optInAppoveError',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            }
        } catch (e) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'catJournal.optInAppoveError',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
};
export const rejectOptIn = (
    reason,
    handlingCE,
    journalCode,
    page,
    pageSize
) => {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/journals/managed/${journalCode}/requests`;
            const res = await axios_instance.put(
                url,
                {
                    action: 'reject',
                    rejectReason: reason,
                    handlingCE: handlingCE,
                    journalCode: journalCode,
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.status === 200) {
                if (res.data.code === 200) {
                    dispatch({ type: REJECT_OPT_IN_CODE, data: res.status });
                    dispatch(getOptInData(page, pageSize));
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'catJournal.optInRejectError',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            }
        } catch (e) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'catJournal.optInRejectError',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
};
import { RegularFont } from '@/components/FontStyle';
import { Box } from '@mui/material';

export default function RadioLabel(props) {
  const { id, label, labelProps } = props;
  return (
    <Box
      id={id}
      data-selenium-id={id}
      sx={{
        ...RegularFont,
        height: '24px',
        lineHeight: '24px',
        color: '#0F172A',
        ...labelProps,
      }}
    >
      {label}
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  IconButton,
  TextField,
  Popover,
  Paper
} from '@mui/material';
import MarketExportMonthIcon from '../../../assets/MarketExportMonth.svg';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateCalendar } from '../../../components/DateRangePicker/DateCalendar';
import * as DateFnsUtils from 'date-fns';
import zIndex from '@mui/material/styles/zIndex';

export const DateRangePicker = (props) => {
  const {
    fromDate, toDate,
    minDate, maxDate,
    handleDateChange, placeholder, minHeight,
    width = '358px', border = '1px solid #98A7B6', popoverIndex = zIndex, innerBorder,
    iconPaddingRight, backgroundColor,
    PopperProps = {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
    },
    autoClose=true,
  } = props;
  const [dateOpen, setDateOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);

  useEffect(() => {
    setStartDate(fromDate);
  }, [fromDate]);

  useEffect(() => {
    setEndDate(toDate);
  }, [toDate]);

  const handleOpenPicker = (event) => {
    setDateOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePicker = () => {
    setDateOpen(false);
    setAnchorEl(null);
    handleDateChange(startDate, endDate);
  };

  const autoClosePicker = (startDate, endDate) => {
    if (autoClose){
      if (startDate && endDate){
        setDateOpen(false);
        setAnchorEl(null);
        handleDateChange(startDate, endDate);
      }
    }
  };

  const handleFromDateChange = (date) => {
    if (!(endDate && DateFnsUtils.isAfter(date, endDate))) {
      setStartDate(date);
      autoClosePicker(date, endDate);
    }
  };

  const handleEndDateChange = (date) => {
    if (!(startDate && DateFnsUtils.isBefore(date, startDate))) {
      setEndDate(date);
      autoClosePicker(startDate, date);
    }
  };

  const emptyDateString = '                    ';
  const formatDateOptional = (dt) => {
    return dt ? DateFnsUtils.format(dt, 'yyyy-MM-dd') : emptyDateString;
  };
  const textFieldString = () => {
    if (fromDate || toDate) {
      return `${formatDateOptional(fromDate)} ${fromDate && toDate ? '  To  ' : '    '} ${formatDateOptional(toDate)}`;
    } else {
      return '';
    }
  };

  const id = dateOpen ? 'date-range-popover' : undefined;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} data-selenium-id={`SIPage_Overview-newFilterDrawer-FilterDataPicker`} >
      <Box sx={{
        width: width,
        padding: 0,
        '& .MuiFormControl-root': {
          marginTop: 0,
          marginBottom: 0,

          '& .MuiInputBase-input': {
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: minHeight,
            height: '40px',
          }
        }
      }}>
        <TextField
          fullWidth={true}
          value={textFieldString()}
          placeholder={placeholder || 'yyyy-mm-dd  To  yyyy-mm-dd'}
          onClick={handleOpenPicker}
          InputProps={{
            endAdornment: (
              <IconButton
                disableRipple
                onClick={handleOpenPicker}
                sx={{
                  paddingRight: iconPaddingRight, '&:hover': {
                    backgroundColor: 'transparent !important',
                  },
                  '&:hover':{
                    'svg path':{
                      fill: '#262E35',
                    }
                  }
                }}>
                <MarketExportMonthIcon />
              </IconButton>
            ),
          }}
          sx={{
            '& input': {
              //width: '196px',
              height: '18px',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '18px',
              display: 'flex',
              alignItems: 'center',
            },
            width: width,
            borderRadius: '4px',
            backgroundColor: backgroundColor,
            '& .MuiOutlinedInput-notchedOutline': {
              border: dateOpen?'2px solid #0052CC !important':
              (startDate===null||endDate===null)?'1px solid #DFE4E8':'1px solid #98A7B6',
            },
            '& :hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#262E35',
              }
            },
            '& .Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#0052CC',
              }
            },
            '& button': {
              boxShadow: 'none',
              filter: 'none',
              '&:hover': {
                '& span:': {
                  filter: 'none',
                  boxShadow: 'none',
                  backgroundColor: 'transparent',
                },
                backgroundColor: 'transparent',
              },
              '&:active span': {
                filter: 'none',
                boxShadow: 'none',
                backgroundColor: 'transparent',
              }
            }
          }}
        />
        <Popover
          id={id}
          open={dateOpen}
          anchorEl={anchorEl}
          onClose={handleClosePicker}
          sx={{
            zIndex: popoverIndex,
            '& .MuiPaper-root': {
              mt: '6px',
              width: 'fit-content',
              height: 'fit-content',
              overflowX: 'hidden',
              overflowY: 'hidden',
            },
            '& .MuiPopover-paper': {
              marginTop: '6px',
              boxShadow: '0px 6px 12px 0px #262E351F',
            },
          }}
          {...PopperProps}
        >
          <Stack direction="row" spacing={2} sx={{
            width: '100%',
            height: '100%',
            gap: '54px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            padding: '16px 24px 20px 24px',
            '& .MuiDateCalendar-root': {
              marginTop: 0,
            },
            '& .DateRange-FromCalendar': {
              // marginLeft: '24px',
            },
            '& .DateRange-ToCalendar': {
              // marginRight: '24px',
            },
            '& .DateRange-Title': {
              width: '37px',
              height: '16px',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '16px',
              textAlign: 'center',
              color: '#596A7C',
              marginBottom: '16px',
            },
          }}>
            <Stack className="DateRange-FromCalendar" spacing={2}>
              <Box className="DateRange-Title">From:</Box>
              <DateCalendar date={startDate} toDay={endDate} minDate={minDate} maxDate={maxDate} onChange={handleFromDateChange} />
            </Stack>
            <Stack className="DateRange-ToCalendar" spacing={2}>
              <Box className="DateRange-Title">To:</Box>
              <DateCalendar date={endDate} fromDay={startDate} minDate={minDate} maxDate={maxDate} onChange={handleEndDateChange} />
            </Stack>
          </Stack>
        </Popover>
      </Box>
    </LocalizationProvider>
  );
};


//好像应该替换为后端返回的数值列表?
export const stageList = [
  'Idea/Proposal',
  'Acquired',
  'Paper Commission',
  'Submission and Review',
  'Production',
  'Published',
  'Shelved',
];

export const activeStageList = [
  'Acquired',
  'Paper Commission',
  'Submission and Review',
  'Production',
];

export const groupList = [
  'CAT LS',
  'CAT HS',
  'CAT PS-SSH',
  'CAT General',
  'JPM',
  'SED',
  'CR',
  'CT',
  'Others',
];

export const alertList = [
  'Submission Deadline Passed',
  'Expected Acceptance Date Passed',
  'Expected Publication Date Passed',
  'horizontal line',
  'Severe Issue',
  'Mild Issue',
];

export const alertListWithoutline = [
  'Submission Deadline Passed',
  'Expected Acceptance Date Passed',
  'Expected Publication Date Passed',
  'Severe Issue',
  'Mild Issue',
];
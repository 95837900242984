import { useState, useEffect, useMemo, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { DiagramSwitch } from '@/components/Chart/Switch/index.js';
import styles from './index.module.scss';
import { MultipleSelect } from '@/components/Chart/ChartSelectors';
import { DiagramTooltip } from '@/components/Chart/Tooltip';
import CloseIcon from '@/assets/CloseIcon.svg';
import InfoIcon from '@/assets/ReportDiagrams/Info.png';
import GapChart from './Chart';
import NoDataPage from '@/components/Chart/NoDataPage';
import LoadingPage from '@/components/Chart/LoadingPage';
import ErrorPage from '@/components/Chart/ErrorPage';
import useChartsDataHook from './hooks/useChartsDataHook';
import useGetDiagramStatusHook from './hooks/useGetDiagramStatusHook';
import ReportSaveMenuButton from '@/components/Chart/chartButton';
import { ReportDownloadMenuButton } from '@/components/Chart/chartButton';
import {
  saveImageAsPng,
  saveImageAsJpg,
  checkEmptyArrayData,
} from '@/pages/Report/chartUtils';
import {getJournalOverviewExcelPost, GetReportExcel} from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import {JOURNAL, ACTIVE_JOURNALS, GET} from '../Constants/index.js';
import {
  LOADING,
  ERROR,
  VALID,
  NO_DATA,
  NO_SELECTION_FROM_DROPDOWN_MENU,
  // NO_DISPLAYED_DATA,
} from '@/pages/Report/chartUtils/constants.js';
import { getDownloadFileName } from '@/pages/Report/utils/getDownloadFileName';

const ChartContainer = ({
  type,
  header,
  subHeader,
  formatdate,
  cancelSelectedChart,
  selectedMonth,
  hasCategoryFilter,
  hasSelectAllOption,
  url,
  tableDownloadTitle,
  tableDownloadUrl,
  reportType,
  downloadMethod = GET,
}) => {
  const formattedHeader = `${header} in ${formatdate}`;

  const reduxTitle = `${JOURNAL}/${header}/${subHeader}/${selectedMonth}`;

  const [selectedCatagories, setSelectedCatagories] = useState([]);

  const [catagoriesFullList, dataForBar, unfilteredDataForBar, loading, error] =
    useChartsDataHook(selectedCatagories, url, reduxTitle, selectedMonth);

  useEffect(() => {
    setSelectedCatagories(catagoriesFullList);
  }, [catagoriesFullList]);

  const alertLabel = subHeader;

  const { valid: digramStatus, info } = useGetDiagramStatusHook(
    unfilteredDataForBar,
    selectedCatagories,
    hasCategoryFilter,
    alertLabel,
    loading,
    error
  );

  console.log('==digramStatus===', digramStatus);
  console.log('==info===', info);

  const [chartWidth, setChartWidth] = useState('100%');

  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);
  const downloadlink = useRef();

  const dispatch = useDispatch();

  // dependencies: Tab1, Tab2, Level, selected time,  
  const fileName = useMemo(() => {
    const rmSpaceSubHeader = subHeader.replaceAll(' ', '');
    const tab1Name = tableDownloadTitle;
    const tab2Name = null;
    const level = rmSpaceSubHeader;
    return getDownloadFileName(tab1Name, tab2Name, level, selectedMonth);
  }, [tableDownloadTitle, header, subHeader, selectedMonth]);

  const downloadChart = () => {
    if (downloadMethod === GET) {
      dispatch(
          GetReportExcel(selectedMonth, tableDownloadUrl, fileName, {
            reportType: reportType,
          })
      );
    }else{
      const requestBody = {
        selectedDate: selectedMonth,
        [reportType === 'sg' ? 'subjectGroup' : 'businessDivision']: selectedCatagories
      };
      dispatch(
          getJournalOverviewExcelPost(tableDownloadUrl, requestBody, fileName)
      );
    }

  };

  const savePng = () => {
    saveImageAsPng(chartRef.current, fileName);
  };

  const saveJpg = () => {
    saveImageAsJpg(chartRef.current, fileName);
  };

  // listen to chart-container size change
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      resizeHandler(entries);
    });
    resizeObserver.observe(chartContainerRef.current);
    return () => {
      if (chartContainerRef.current) {
        resizeObserver.unobserve(chartContainerRef.current);
      }
    };
  }, []);

  function resizeHandler(entries) {
    const chartContainer = entries.find(
      entry => entry.target == chartContainerRef.current
    );
    const width = chartContainer?.target.clientWidth;
    setChartWidth(width);
  }

  // Target Gap switch
  const switchLabel = {
    inputProps: {
      'aria-label': 'diagram-switch',
      checked: false,
      disabled: true,
    },
  };

  return (
    <div className={styles['chart-container']} ref={chartContainerRef}>
      <Box className={styles['chart-header']}>
        <div className={styles['selectors']}>
          {hasCategoryFilter && (
            <span id='category-select'>
              <MultipleSelect
                label={subHeader}
                alertLabel={subHeader}
                options={catagoriesFullList}
                selectedItems={selectedCatagories ?? []}
                setSelectedItems={setSelectedCatagories}
                hasSelectAllOption={hasSelectAllOption}
                disabled={[NO_DATA, LOADING, ERROR].indexOf(digramStatus) >= 0}
              />
            </span>
          )}
          <span className={styles['switch']}>
            <DiagramSwitch {...switchLabel} />
            <h2 className={styles['switch-label']}>Target Gap</h2>
            <DiagramTooltip
              title='This function is currently disabled'
              placement='top'
            >
              <img className={styles['icon']} src={InfoIcon} />
            </DiagramTooltip>
          </span>
        </div>
        <div className={styles['chart-titles']}>
          <h1 className={styles['chart-title']}>{formattedHeader}</h1>
          <h2 className={styles['chart-subtitle']}>{subHeader + ' Level'}</h2>
        </div>
        <div className={styles['buttons']}>
          <ReportDownloadMenuButton
            func={downloadChart}
            disabled={digramStatus !== VALID}
          />
          <ReportSaveMenuButton
            items={[
              {
                label: 'Save as PNG',
                operation: savePng,
              },
              {
                label: 'Save as JPG',
                operation: saveJpg,
              },
            ]}
            disabled={digramStatus !== VALID}
          />
          <button
            onClick={cancelSelectedChart}
            className={styles['button-close']}
          >
            <CloseIcon />
          </button>
        </div>
      </Box>

      {digramStatus === LOADING && <LoadingPage />}

      {digramStatus === ERROR && <ErrorPage />}

      {digramStatus === NO_DATA && (
        <NoDataPage
          title={`There is no data for any ${info}s on the selected date.`}
          subTitle={'Please choose a different date to view diagram.'}
        />
      )}

      {digramStatus === NO_SELECTION_FROM_DROPDOWN_MENU && (
        <NoDataPage title={`Please select at least one ${info}.`} />
      )}

      {digramStatus === VALID && (
        <>
          <Box className={styles['chart']} ref={chartRef}>
            <GapChart
              selectedMonth={selectedMonth}
              width={chartWidth}
              hasZoom={true}
              hasTitle={false}
              title={formattedHeader}
              subTitle={subHeader + ' Level'}
              diagramType={type}
              dataForBar={dataForBar}
              isDownload={false}
            />
          </Box>
          <Box className={styles['chart-download']} ref={chartRef}>
            <GapChart
              selectedMonth={selectedMonth}
              width={chartWidth}
              hasZoom={false}
              hasTitle={true}
              title={formattedHeader}
              subTitle={subHeader + ' Level'}
              diagramType={type}
              dataForBar={dataForBar}
              isDownload={true}
            />
          </Box>
          <a
            href='javascript:void(0)'
            download
            ref={downloadlink}
            className={styles['download-link']}
          >
            hidden download link
          </a>
        </>
      )}
    </div>
  );
};

ChartContainer.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  selectedMonth: PropTypes.string.isRequired,
  cancelSelectedChart: PropTypes.func.isRequired,
  hasCategoryFilter: PropTypes.bool.isRequired,
  hasSelectAllOption: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  tableDownloadUrl: PropTypes.string,
};

export default ChartContainer;

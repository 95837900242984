import { combineReducers } from 'redux';

import UserManageReducers from './UserManageReducers';
import LoginReducer from './LoginReducer';
import AlertReducer from './AlertReducer';
import UserReducer from './UserReducer';
import RoleManageReducer from './RoleManageReducer';
import SystemConfigReducer from './SystemConfigReducer';
import SystemLogReducer from './SystemLogReducer';
import CommonReducer from './CommonReducer';
import PopupReducer from './PopupReducer';
import SIReducer from './SIReducer';
import FilterReducer from './FilterReducer';
import SIPFilterReducer from './SIPFilterReducer';
import GEReducer from './GEReducer';
import BreadcrumbReducer from './BreadcrumbReducer';
import SeleteReducer from './SeleteReducer';
import ReportReducer from './ReportReducer';
import ReportDiagramReducer from './ReportDiagramReducer';
import CRContactReducer from './CRContactReducer';
import ReportFilterReducer from './ReportFilterReducer';
import AnnualTargetReducer from './AnnualTargetReducer';
import SnackbarReducer from './SnackbarReducer';
import CATJournalReducer from './CATJournalReducer';
import CATJournalDetailReducer from './CATJournalDetailReducer';
import ReportJobsReducer from './ReportJobsReducer';
import EmailReducer from './EmailReducer';
import SideBarReducer from './SideBarRuducer';
import SIPReducer from './SIPReducer';
import  GeAddReducer  from './GeAddReducer';
import MarketExportReducer from './MarketExportReducer';
import SIPChecklistReducer from './SIPChecklistReducer';
import FlaggedGEProposerReducer from './FlaggedGEProposerReducer';
import {UiComponentReducer} from "@/reducers/UiComponentReducer";
import {SIPFormReducer} from "@/reducers/SIPFormReducer";


const rootReducer = combineReducers({
  UserManage: UserManageReducers,
  Login: LoginReducer,
  Alert: AlertReducer,
  User: UserReducer,
  Role: RoleManageReducer,
  SystemConfig: SystemConfigReducer,
  SystemLog: SystemLogReducer,
  Common: CommonReducer,
  Pop: PopupReducer,
  SI: SIReducer,
  Filter: FilterReducer,
  SIPFilter: SIPFilterReducer,
  GE: GEReducer,
  Bread: BreadcrumbReducer,
  Selete: SeleteReducer,
  Report: ReportReducer,
  ReportDiagram: ReportDiagramReducer,
  CRContact: CRContactReducer,
  ReportFilter: ReportFilterReducer,
  AnnualTarget: AnnualTargetReducer,
  Snack: SnackbarReducer,
  CATJournal: CATJournalReducer,
  CATJournalDetail:CATJournalDetailReducer,
  ReportJobs: ReportJobsReducer,
  Email: EmailReducer,
  SideBar: SideBarReducer,
  SIP: SIPReducer,
  SIPForm: SIPFormReducer,
  SIPChecklist:SIPChecklistReducer,
  FlaggedGEProposer: FlaggedGEProposerReducer,
  GeAdd :GeAddReducer,
  MarketExport: MarketExportReducer,
  UiComponents: UiComponentReducer,
});

export default rootReducer;

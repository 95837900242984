import {axios_instance_fileDownload,} from '../../../utils/axios_instance';
import store from '../../../index';
import moment from 'moment';
import {setSnackbarMessageAndOpen} from '../../SnackbarAction';
import {SEVERITIES} from '../../../modules/ConfirmSnackBar/CommonSnackBar';
import {isTimeoutError} from '@/utils/commonUtils';

export const GET_CE = 'GET_CE';
export const GET_SCAM = 'GET_SCAM';
export const GET_JOURNALLIST = 'GET_JOURNALLIST';
export const GET_MOREJOURNALLIST = 'GET_MOREJOURNALLIST';
export const GET_GE = 'GET_GE';
export const CHECK_TITLE = 'CHECK_TITLE';
export const CLICK_OUT_OF_DATE = 'CLICK_OUT_OF_DATE';
export const CLICK_INSIDE_DATE = 'CLICK_INSIDE_DATE';

export const GET_SPECIAL_ISSUE = 'GET_SPECIAL_ISSUE';
export const GET_SPECIAL_ISSUE_BY_CODES = 'GET_SPECIAL_ISSUE_BY_CODES';
export const GET_SPECIAL_ISSUE_BY_CODES_LOADING =
  'GET_SPECIAL_ISSUE_BY_CODES_LOADING';
export const GET_SPECIAL_ISSUE_CODES = 'GET_SPECIAL_ISSUE_CODES';
export const SI_CLEAR = 'SI_CLEAR';
export const ORDER_BY = 'ORDER_BY';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_PAGE_SIZE = 'CHANGE_PAGE_SIZE';
export const SET_OPEN = 'SET_OPEN';
export const NUMBER_ERROR = 'NUMBER_ERROR';
export const PROGRESS_NUMBER = 'PROGRESS_NUMBER';
export const SAVEPDNUMBER_ERROR = 'SAVEPDNUMBER_ERROR';
export const EXPORT_SI_STATUS = 'EXPORT_SI_STATUS';
export const EXPORT_SI_LIST = 'EXPORT_SI_LIST';
export const EXPORT_SI_ERROR = 'EXPORT_SI_ERROR';

export const CREATE_GE = 'CREATE_GE';
export const CREATE_SI = 'CREATE_SI';
export const UPDATE_SI = 'UPDATE_SI';
export const TRANSFORM_SI = 'TRANSFORM_SI';

export const SET_DETAILINFO = 'SET_DETAILINFO';

export const GET_SIHISTORY = 'GET_SIHISTORY';
export const GET_LeadGEHISTORY = 'GET_LeadGEHISTORY';
export const GET_COGEHISTORY = 'GET_COGEHISTORY';
export const GET_EMAILHISTORY = 'GET_EMAILHISTORY';

export const Reactive_SI = 'Reactive_SI';

export const EditSIGeneralInfo_Admin = 'EditSIGeneralInfo_Admin';
export const EditSIGeneralInfo_T1 = 'EditSIGeneralInfo_T1';
export const Edit_SubmissionOverview = 'Edit_SubmissionOverview';
export const Edit_PublicationDetails = 'Edit_PublicationDetails';

export const GET_SNOWFLAKE_INFO = 'GET_SNOWFLAKE_INFO';
export const SET_PUBLICATION_ERROR = 'SET_PUBLICATION_ERROR';

export const SAVE_COLUMNS = 'SAVE_COLUMNS';
export const GET_COLUMNS = 'GET_COLUMNS';

export const MANDATORY_FIELD = 'MANDATORY_FIELD';

export const UPDATE_SI_MESSAGE = 'UPDATE_SI_MESSAGE';

export const LINK_ERROR = 'LINK_ERROR';
export const SET_DATECELL = 'SET_DATECELL';
export const SET_SI_INPROGRESS = 'SET_SI_INPROGRESS';

export const SAVELATER_MARKETINFO = 'SAVELATER_MARKETINFO';
export const UPDATE_MARKETINFO = 'UPDATE_MARKETINFO';

export const UPDATE_PROMOTE_STATE = 'UPDATE_PROMOTE_STATE';

export const GET_PROMOTE_LEADGE = 'GET_PROMOTE_LEADGE';
export const SUBMISSION_JUDGE = 'SUBMISSION_JUDGE';

export const SI_TITLE_EDIT = 'SI_TITLE_EDIT';

export const MARKETISSN_CHECK = 'MARKETISSN_CHECK';

export const APPEND_SI_REQ_ID = 'APPEND_SI_REQ_ID';

export const SET_CURRENT_ROW = 'SET_CURRENT_ROW';

export const GET_WORKLISTFLOW = 'GET_WORKLISTFLOW';
export const GET_SIREVENUEMODEL = 'GET_SIREVENUEMODEL';
export const GET_SISCOURCE = 'GET_SISCOURCE';
export const GET_STAGES = 'GET_STAGES';
//  CT2473
export const SET_SICODE_TO_JUMP = 'SET_SICODE_TO_JUMP';
//CT5007 [M2][Marketing improvement] - AI recommended competitors
export const SET_MARKETING_AI_OPTIONS = 'SET_MARKETING_AI_OPTIONS';
export const SET_MARKETING_AI_OPTIONS_LOADING = 'SET_MARKETING_AI_OPTIONS_LOADING';
export const SET_MARKETING_AI_OPTIONS_ERROR = 'SET_MARKETING_AI_OPTIONS_ERROR';



export const default_columns = {
  SICode: 'true',
  JournalCode: 'true',
  JournalName: 'true',
  SIPublicationFormat: 'false',
  SpecialIssueTitle: 'true', //SITitle4
  LeadGE: 'true', //Leader5
  CoGE: 'true', //Co6
  Stage: 'true', //Stage7
  HE: 'true', //HE8
  Group: 'false', //Group9
  JPM: 'true',
  SubjectGroup: 'true',
  SISource: 'false',
  IdeaDate: 'false',
  AcquiredDate: 'false',
  Deadline: 'true',
  'ExpectedAcceptance Date': 'false',
  'ExpectedPublication Date': 'false',
  DeadlinePassed: 'false',
  Closed: 'false',
  PaperCommissioning: 'false',
  Workflow: 'false',
  PreviousHE: 'false',
  LastActionDate: 'true',
  ArticlesRecruited: 'false',
  ArticlesSubmitted: 'true',
  ArticlesAccepted: 'true',
  ArticlesRejected: 'true',
  InProgress: 'true',
  Volume: 'false',
  Issue: 'false',
  ActualPublicationDate: 'false',
  OO: 'false',
  OA: 'false',
  OutputRate: 'false',
};

export function setOrderBy(order, by) {
  return dispatch => {
    dispatch({ type: ORDER_BY, data: { order: order, by: by } });
  };
}

export function createSIClear() {
  return async dispatch => {
    dispatch({ type: CREATE_SI, data: '' });
    dispatch({ type: UPDATE_SI, data: '' });
  };
}

// Create Redux action creators that return an action
export const clear = () => ({
  type: SI_CLEAR,
});

/**
 * 更改表格页码
 * @param {int} page 页码
 * @returns dispatch
 */
export function handlePageChangeAction(page) {
  return dispatch => {
    dispatch({
      type: CHANGE_PAGE,
      data: page,
    });
  };
}

/**
 * 更改表格一页数量
 * @param {int} pageSize 一页的大小
 * @returns dispatch
 */
export function handleChangePageSize(pageSize) {
  return dispatch => {
    dispatch({
      type: CHANGE_PAGE_SIZE,
      data: pageSize,
    });
  };
}

export function beginSIDataLoading() {
  return {
    type: GET_SPECIAL_ISSUE_BY_CODES_LOADING,
    data: true,
  };
}

export function setOpen(which, newState) {
  return dispatch => {
    dispatch({
      type: SET_OPEN,
      data: {
        which: which,
        newState: newState,
      },
    });
  };
}

export function setNumberError(numberError) {
  return dispatch => {
    dispatch({
      type: NUMBER_ERROR,
      data: numberError,
    });
  };
}

export function setSavePDNumberError(savePDnumberError) {
  return dispatch => {
    dispatch({
      type: SAVEPDNUMBER_ERROR,
      data: savePDnumberError,
    });
  };
}

export function setProgressNumber(progressNumber) {
  return dispatch => {
    dispatch({
      type: PROGRESS_NUMBER,
      data: progressNumber,
    });
  };
}

// progressNumber

export function setSICodeToJump(siCode) {
  return dispatch => {
    dispatch({ type: SET_SICODE_TO_JUMP, data: siCode });
  };
}

export function exportSIExcel(projectCodeList, isCNmarketing = false) {
  var filename = `SIList_${moment().utc().format('MMDDYYYY_HHmmss')}.xlsx`;
  return async dispatch => {
    try {
      let res = await axios_instance_fileDownload.post(
        '/api/v1/si-service/special-issues/export',
        {siCodeList:projectCodeList,
          fileType:'excel'},
        {
          responseType: 'blob',

          headers: {
            authorization: localStorage.getItem('authorization'),
          },
        }
      );
      downloadFile(res.data, filename);
      dispatch({ type: EXPORT_SI_STATUS, data: true });
    } catch (err) {
      dispatch({ type: EXPORT_SI_STATUS, data: false });
    }
  };
}

export function exportSICSV(projectCodeList, isCNmarketing = false) {
  var filename = `SIList_${moment().utc().format('MMDDYYYY_HHmmss')}.csv`;
  return async dispatch => {
    try {
      let res = await axios_instance_fileDownload.post(
        '/api/v1/si-service/special-issues/export',{
          siCodeList:projectCodeList,
          fileType:'csv',
        }
        ,
        {
          // params: {
          //   siCodes: arrayToString(projectCodeList),
          // },
          headers: {
            authorization: localStorage.getItem('authorization'),
          },
        }
      );
      downloadFile(res.data, filename);
      dispatch({ type: EXPORT_SI_STATUS, data: true });
    } catch (err) {
      dispatch({ type: EXPORT_SI_STATUS, data: false });
    }
  };
}

//将一个数字数组（比如[1,2,3]）转化为字符串 1,2,3
function arrayToString(array) {
  var str = '';
  for (var i = 0; i < array.length - 1; i++) {
    str += array[i] + ',';
  }
  return str + array[array.length - 1];
}

//第一个参数是下载流，第二个参数是下载的文件名
//将从后端拿到下载流后开始下载文件
export function downloadFile(dataFlow, fileName) {
  if (dataFlow?.code === 500) {
    store.dispatch(
      setSnackbarMessageAndOpen('common.failToDownload', {}, SEVERITIES.error)
    );
  }
  var blob;
  if (fileName.split('.')[1] === 'xls' || fileName.split('.')[1] === 'xlsx') {
    blob = new Blob([dataFlow], {
      type: 'application/vnd.ms-excel;charset=UTF-8',
    });
  } else {
    blob = new Blob(
      [
        new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
        dataFlow,
      ],
      {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
    );
  }
  if ('download' in document.createElement('a')) {
    // 非IE下载
    const elink = document.createElement('a');
    elink.download = fileName;
    elink.style.display = 'none';
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放 URL对象
    document.body.removeChild(elink);
  }
}

export function updatePop(res, dispatch) {
  if (res.data) {
    // common result from backend
    if (res.data?.code === 200) {
      dispatch(
        setSnackbarMessageAndOpen('siPage.updateSucc', {}, SEVERITIES.success)
      );
    } else if (res.data.message === 'invalid stage') {
      // stage not correct/invalid stage (concurrency issues)
      dispatch(
        setSnackbarMessageAndOpen('siPage.invalidStage', {}, SEVERITIES.error)
      );
    } else if (res.data.message === 'invalid date') {
      // stage not correct/invalid stage (concurrency issues)
      dispatch(
        setSnackbarMessageAndOpen('siPage.invalidDate', {}, SEVERITIES.error)
      );
    }
  } else {
    // Failed to get data from the backend, this is an err object
    // General error, including raw return information
    if (isTimeoutError(res)) {
      // timeout error
      dispatch(
        setSnackbarMessageAndOpen(
          'siPage.updateTimeoutError',
          {},
          SEVERITIES.error
        )
      );
      //return directly
      return;
    } else {
      const message = res.message;
      dispatch(
        setSnackbarMessageAndOpen(
          'siPage.updateError',
          { value: message },
          SEVERITIES.error
        )
      );
    }
  }
}

export function SaveForLaterMarketInfo(marketInfo) {
  return async dispatch => {
    dispatch({ type: SAVELATER_MARKETINFO, data: marketInfo });
  };
}

export function clearUserColumnSave() {
  return async dispatch => {
    dispatch({ type: SAVE_COLUMNS, data: null });
  };
}

export function setPublichError(data) {
  return dispatch => {
    dispatch({
      type: SET_PUBLICATION_ERROR,
      data: data,
    });
  };
}

export function appendSIReqId() {
  return dispatch => {
    dispatch({ type: APPEND_SI_REQ_ID, data: null });
  };
}

// the old function 'getNewSiMarketinfo' has been called in sipreference section
// /**
//  * @param {string} sipCode
//  * @param {import("@/pages/SIPage/SIDetailPage/market-section").MarketSectionDetailInfo} saveDetailInfoState
//  * @param {"release" | "debug"} mode
//  * @returns {(dispatch: import("redux").Dispatch) => Promise<void>}
//  */
// export const getNewSiMarketinfo = (
//   sipCode,
//   saveDetailInfoState,
//   mode = 'release'
// ) => {
//   return async dispatch => {
//     try {
//       /** @type {import("@/utils/axios-response").AxiosResponse<import("@/pages/SIPage/SIDetailPage/market-section").NewSiMarketIInfo>} */
//       const res =
//         mode == 'release'
//           ? await axios_instance.get('/sip/getNewSiMarketinfo', {
//               params: {
//                 sipCode: sipCode,
//               },
//               headers: {
//                 authorization: localStorage.getItem('authorization') || '',
//               },
//             })
//           : await fakeAxiosSuccessResponse(
//               /** @type {import("@/pages/SIPage/SIDetailPage/market-section").NewSiMarketIInfo} */ ({
//                 aimsAndScope: 'test test test',
//                 keywords: ['1', '2', '3'],
//                 topics: ['1', '2', '3'],
//                 reference: [],
//               })
//             );
//       if (res.data.code === 200) {
//         const data = res.data.data;
//         dispatch({
//           type: SET_DETAILINFO,
//           /** @type {import("@/pages/SIPage/SIDetailPage/market-section").MarketSectionDetailInfo} */ data: {
//             ...saveDetailInfoState,
//             siMarketing: [
//               {
//                 journalSiPageLink: '',
//                 specialIssueHtmlLink: '',
//                 siAimsAndScope: data.aimsAndScope,
//                 siTopics: data.topics,
//                 siKeywords: data.keywords,
//                 competitiveTitleIssns: [],
//                 references: data.reference,
//                 others: '',
//                 readyForPromotion: '',
//                 submissionInstructions: '',
//                 fromNewSi: true,
//               },
//             ],
//           },
//         });
//       } else {
//         dispatch(setSnackbarMessageAndOpen('Failed to load new market'));
//       }
//     } catch (e) {
//       console.error(e);
//     }
//   };
// };

// Add CM Related Action

export const GET_ALL_CHINA_MARKETING_ACTIVITY =
  'GET_ALL_CHINA_MARKETING_ACTIVITY';

export const ADD_CHINA_MARKETING_ACTIVITY = 'ADD_CHINA_MARKETING_ACTIVITY';

export const UPDATE_CHINA_MARKETING_ACTIVITY =
  'UPDATE_CHINA_MARKETING_ACTIVITY';

export const DELETE_CHINA_MARKETING_ACTIVITY =
  'DELETE_CHINA_MARKETING_ACTIVITY';

;

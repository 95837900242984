import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import CustomInput from './CustomInput';
import { InlineRichTextInput } from '../../../../../componentsphase2/RichText/RichText';

export default function CustomRichInputList(props) {
  const {
    id,
    value,
    setValue,
    isClick,
    limitLength,
    maxItemNumber,
    width,
    setErr,
    NeedEmptyCheck,
    NeedCustomCheck,
    CustomCheckPattern,
    customCheckText,
    maxRows,
    NeedDuplicateCheck,
    NeedNote,
    NeedNote2,
    customCheckPatternErrArr,
    duplicateItemArr,
    emptyListTextHelper,
    noteLinkList,
  } = props;
  const ButtonStyle = {
    height: '18px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#154AB6',
    textAlign: 'right',
    cursor: 'pointer',
    backgroundColor: '#F1F3F5',
    padding: '2px 4px 2px 4px',
    borderRadius: '4px',
  };
  const ChangeStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px 4px',
    gap: '4px',

    width: '101px',
    height: '22px',

    /* Gray/100 */
    background: '#F1F3F5',
    borderRadius: '4px',
    color: '#154AB6',
    fontWeight: '400px',
    /* Inside auto layout */
    flex: 'none',
    order: '1',
    flexGrow: '0',
    fontSize: '12px',
    lineHeight: '18px',
    cursor: 'pointer',
  };
  const NoteStyle = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',

    color: '#596A7C',
  };

  const [customCheckPatternErrList, setCustomCheckPatternErrList] = useState(
    customCheckPatternErrArr
  );
  const [duplicateItemList, setDuplicateItemList] = useState(duplicateItemArr);

  const isEmpty = value => {
    return (
      value === undefined ||
      value === null ||
      value === '' ||
      value === [] ||
      value === {} ||
      (typeof value === 'string' && !value.trim().length)
    );
  };
  const handleChange = (newValue, index) => {

    if (NeedDuplicateCheck) {
      let newduplicateItemList = duplicateItemList.concat();
      for (let i = 0; i < value.length; i++) {
        if (i === index) {
          continue;
        }
        if (newValue === value[i]) {
          if (newduplicateItemList[index].indexOf(i) !== -1) {
            continue;
          } else {
            newduplicateItemList[index].push(i);
          }
        } else {
          if (newduplicateItemList[index].indexOf(i) !== -1) {
            newduplicateItemList[index].splice(
              newduplicateItemList[index].indexOf(i),
              1
            );
          }
          if (newduplicateItemList[i].indexOf(index) !== -1) {
            newduplicateItemList[i].splice(
              newduplicateItemList[i].indexOf(index),
              1
            );
          }
        }
      }
      setDuplicateItemList(newduplicateItemList);
    }
    let newList = value.concat();

    newList.splice(index, 1, newValue);

    setValue(newList);
  };
  const handleClick = () => {
    let newList = value.concat();
    newList.push('');
    setValue(newList);
    if (NeedCustomCheck) {
      let newcustomCheckPatternErrList = customCheckPatternErrList.concat();
      newcustomCheckPatternErrList.push(false);
      setCustomCheckPatternErrList(newcustomCheckPatternErrList);
    }
    if (NeedDuplicateCheck) {
      let newduplicateItemList = duplicateItemList.concat();
      newduplicateItemList.push([]);
      setDuplicateItemList(newduplicateItemList);
    }
  };

  const handleIconButtonClick = index => {
    let newList = value.concat();
    newList.splice(index, 1);
    setValue(newList);
    if (NeedCustomCheck) {
      let newcustomCheckPatternErrList = customCheckPatternErrList.concat();
      newcustomCheckPatternErrList.splice(index, 1);
      setCustomCheckPatternErrList(newcustomCheckPatternErrList);
    }
    if (NeedDuplicateCheck) {
      let newduplicateItemList = duplicateItemList.concat();
      for (let i = 0; i < newduplicateItemList[index].length; i++) {
        let position = newduplicateItemList[index][i];
        newduplicateItemList[position].splice(
          newduplicateItemList[position].indexOf(index),
          1
        );
      }
      newduplicateItemList.splice(index, 1);
      setDuplicateItemList(newduplicateItemList);
    }
  };

  const handleCustomCheckError = (newValue, index) => {
    let newcustomCheckPatternErrList = customCheckPatternErrList.concat();
    newcustomCheckPatternErrList.splice(index, 1, newValue);
    setCustomCheckPatternErrList(newcustomCheckPatternErrList);
  };

  useEffect(() => {
    let error = false;
    if (NeedEmptyCheck) {
      let emptyError = isEmpty(value[0]);
      for (let i = 1; i < maxItemNumber; i++) {
        emptyError = emptyError || isEmpty(value[i]);
      }
      error = error || emptyError;
    }
    if (NeedCustomCheck) {
      let customCheckError = customCheckPatternErrList[0];
      for (let i = 1; i < customCheckPatternErrList.length; i++) {
        customCheckError = customCheckError || customCheckPatternErrList[i];
      }
      error = error || customCheckError;
    }
    if (NeedDuplicateCheck) {
      let duplicateItemErr = duplicateItemList[0].length !== 0;
      for (let i = 1; i < duplicateItemList.length; i++) {
        duplicateItemErr =
          duplicateItemErr || duplicateItemList[i].length !== 0;
      }
      error = error || duplicateItemErr;
    }
    setErr(error);
  });

  return (
    <Box
      sx={{
        width: width,
        '& .InlineRichTextArea-InputRoot':{
          color: '#262E35',
          fontFamily: 'Open Sans',
          fontSize: '14px',
          lineHeight: '20px',
          '& .ck.ck-editor__editable_inline':{
            padding: 0,
          },
          '& .ck.ck-editor__editable_inline>*': {
            marginLeft: '12px',
          },
          '& .ck.ck-editor__editable_inline>:first-child': {
            marginTop: '6.5px',
          },
          '& .ck.ck-editor__editable_inline>:last-child': {
            marginBottom: '6.5px',
          },
        },
      }}
      data-selenium-id='SIDetail_MarketSection-CustomInputList-ContainerBox'
    >
      {value.map((item, index) => (
        <InlineRichTextInput
          //placeholder={'Placeholder'}
          placeholder={''}
          id={`${id}${index}`}
          data-selenium-id={`${id}-${index}`}
          NeedCustomCheck={NeedCustomCheck}
          CustomCheckPattern={CustomCheckPattern}
          setCustomCheckError={newValue =>
            handleCustomCheckError(newValue, index)
          }
          customCheckText={customCheckText}
          emptyError={
            isClick &&
            index >= 0 &&
            index < maxItemNumber &&
            NeedEmptyCheck &&
            isEmpty(item)
          }
          hasText={!!item}
          value={item}
          setValue={newValue => handleChange(newValue, index)}
          needEndAdornment={index >= maxItemNumber ? true : false}
          handleIconButtonClick={() => handleIconButtonClick(index)}
          limitLength={limitLength}
          maxRows={maxRows}
          error={NeedDuplicateCheck && duplicateItemList[index].length !== 0}
          helperText={
            NeedDuplicateCheck && duplicateItemList[index].length !== 0
              ? 'ISSN should not be repetitive.'
              : ''
          }
        />
      ))}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          paddingTop: '1px',
          marginBottom: '8px',
        }}
        data-selenium-id='SIDetail_MarketSection-CustomInputList-TextContainerBox'
      >
        {NeedNote !== null && NeedNote !== undefined && NeedNote ? (
          <span
            style={{
              width: 'calc(100% - 36px)',
              ...NoteStyle,
              marginBottom: '3px',
            }}
            data-selenium-id='SIDetail_MarketSection-CustomInputList-PromptText'
          >
            {' '}
            For each text box, please fill in one reference item only. Click on
            "ADD" to add more reference items.
          </span>
        ) : (
          null
        )}
        {NeedNote2 !== null && NeedNote2 !== undefined && NeedNote2 ? (
          <Box maxWidth='382px'>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 400,
                color: '#596A7C',

                '& a': {
                  color: '#264ab7',
                  fontWeight: 600,
                  textDecoration: 'none',
                },
              }}
            >
              You may refer to{' '}
              <a
                href={noteLinkList.length > 0 ? noteLinkList[0].value : './'}
                target={'_blank'}
                rel='noreferrer'
              >
                this file
              </a>{' '}
              for Competitive Titles.
            </Typography>
          </Box>
        ) : (
          null
        )}

        <Box
          sx={{ ...ChangeStyle, ml: 'auto' }}
          onClick={handleClick}
          data-selenium-id='SIDetail_MarketSection-CustomInputList-AddText'
        >
          {' '}
          + Add new field
        </Box>
      </Box>
    </Box>
  );
}

import { Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import * as React from 'react';
import SystemLogHeaderTagCloseIcon from '@/assets/SystemLogHeaderTagCloseIcon.svg';
import Chip from '@mui/material/Chip';

function Tag(props) {
  const { label, onDelete, maxWidth = '250px', sx, ...other } = props;
  return (
    <Tooltip title={label} disableInteractive>
      <Chip
        label={label}
        variant='outlined'
        onDelete={onDelete}
        deleteIcon={<SystemLogHeaderTagCloseIcon />}
        sx={{
          '& .MuiButtonBase-root.MuiChip-root': {
            maxWidth: maxWidth,
            ...sx,
          },
        }}
        {...other}
      />
    </Tooltip>
  );
}

const StyledTag = styled(Tag)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '24px',
  margin: '2px',
  lineHeight: '22px',
  backgroundColor: '#fafafa',
  border: '1px solid #e8e8e8',
  borderRadius: '2px',
  boxSizing: 'content-box',
  padding: '0 4px 0 6px',
  outline: 0,
  overflow: 'hidden',

  '&:focus': {
    borderColor: '#40a9ff',
    backgroundColor: '#e6f7ff',
  },
  '& span': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  '& svg': {
    fontSize: '12px',
    cursor: 'pointer',
    padding: '4px',
    flexShrink: 0,
  },
}));

export default StyledTag;

import {axios_instance} from "@/utils/axios_instance";
import {
    CREATE_DEPARTMENT_EXITS,
    CREATE_INSTITUTE_EXITS,
    createGeDepartment,
    createGeInstitute,
    GET_DEPARTMENTLIST,
    GET_INSTITUTELIST
} from "@/actions/SIMT-SI/GuestEditor/GEAction";

/**
 * @param {string} institute
 * @returns
 */
export function createInstitute(institute) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/guest-editors/institutes`;
            const res = await axios_instance.post(
                url,
                {
                    institute: institute,
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch(createGeInstitute('success', res.data.data));
            } else {
                if (res.data.message === 'the instituteDto already exits') {
                    dispatch({type: CREATE_INSTITUTE_EXITS, data: true});
                } else {
                    dispatch({type: CREATE_INSTITUTE_EXITS, data: false});
                }
                dispatch(createGeInstitute('error', null));
            }
        } catch {
            dispatch(createGeInstitute('error', null));
        }
    };
}

/**
 * @param {string} department
 * @returns
 */
export function createDepartment(department) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/guest-editors/departments`;
            const res = await axios_instance.post(
                url,
                {
                    department: department,
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch(createGeDepartment('success', res.data.data));
            } else {
                if (res.data.message === 'the departmentDto already exists') {
                    dispatch({type: CREATE_DEPARTMENT_EXITS, data: true});
                } else {
                    dispatch({type: CREATE_DEPARTMENT_EXITS, data: false});
                }
                dispatch(createGeDepartment('error', null));
            }
        } catch {
            dispatch(createGeDepartment('error', null));
        }
    };
}

/**
 * @param {List<Long>} ids
 * @param {string} department
 * @returns
 */
export function getDepartmentList(department) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/guest-editors/departments`;
            const res = await axios_instance.get(url, {
                params: {
                    department: department,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            dispatch({type: GET_DEPARTMENTLIST, data: res.data.data});
        } catch (e) {
            dispatch({type: GET_DEPARTMENTLIST, data: 'error'});
        }
    };
}

/**
 * @param {List<Long>} ids
 * @param {string} institute
 * @returns
 */
export function getInstituteList(institute) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/guest-editors/institutes`;
            const res = await axios_instance.get(url, {
                params: {
                    institute: institute,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            dispatch({type: GET_INSTITUTELIST, data: res.data.data});
        } catch (e) {
            dispatch({type: GET_INSTITUTELIST, data: 'error'});
        }
    };
}
// @ts-check
import { Badge, Box, IconButton, Popover } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
// @ts-ignore
import HeaderAlertIcon from '@/assets/HeaderAlertIcon2.svg';
import NotificationTabs from '@/components/Alert/NotificationTabs';
import {
  clearAlerts,
  setCurrentNotificationItemInfo,
  setNotificationDrawerOpen,
} from '@/actions/SIMT-User/Alert/AlertAction';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from '@/utils/commonUtils';
import { fetchUnreadAlertsCount } from '@/actions/SIMT-User/Alert';
import AlertList from './AlertList';

/**
 * @typedef {import("@/components/CommonSider/NotificationDrawer/AlerPage").Alert} Alert
 */
/**
 *
 * @param {import("./AlertPanel").AlertPopoverProps} props
 * @returns
 */
const AlertPopover = props => {
  const { anchorEl, handleClose, alertButtonRef, ...other } = props;
  const open = Boolean(anchorEl);
  const id = open ? 'header-alert-popover' : undefined;
  const [currentTab, setCurrentTab] = useState(/** @type {0 | 1} */ (0));

  const dispatch = useDispatch();
  const { notificationDrawerOpen } = useSelector(state => ({
    notificationDrawerOpen: state.Alert.notificationDrawerOpen,
  }));
  useEffect(() => {
    if (notificationDrawerOpen || !open) {
      return;
    }
    // when open the popover, clear unread alerts count and get unread count
    dispatch(clearAlerts(null));
    dispatch(clearAlerts(false));
    dispatch(fetchUnreadAlertsCount());
  }, [notificationDrawerOpen, open]);

  const windowSize = useWindowSize();

  const onItemClick = (index, id, item) => {
    handleClose();
    dispatch(
      setCurrentNotificationItemInfo({
        currentNotificationTab: currentTab,
        currentNotificationIndex: index,
        currentNotificationItem: item,
      })
    );
    dispatch(setNotificationDrawerOpen(true));
  };

  return (
    <Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='HeaderAlertPopover-root'
        sx={{
          transition: 'background-color 0.5s',
          // backgroundColor: open ? 'rgba(9, 30, 66, 0.54)' : 'transparent',
          backgroundColor: 'transparent',
          // backgroundColor: open ? "rgba(9, 30, 66, 0.54),
          // animation: 'HeaderAlertPopover-root__fade-in 0.5s',
          zIndex: 1200,
          marginTop: '12px',
          '& .MuiPaper-root': {
            width: '384px',
            overflow: 'visible',
            '&::before': {
              zIndex: '1300',
              content: '""',
              width: '11.32px',
              height: '11.32px',
              position: 'absolute',
              top: 0,
              right: alertButtonRef?.current?.parentElement?.offsetLeft
                ? `${
                    window.innerWidth -
                    alertButtonRef?.current?.parentElement?.offsetLeft -
                    67
                  }px`
                : 0,
              transition: 'right 0.5s',
              transform: 'rotate(45deg) translate(0, -50%)',
              // backgroundColor: '#fff',
              backgroundColor: 'transparent',
            },
            '& .SIMT-NotificationTabs-body': {
              overflowY: 'hidden',
              overflowX: 'hidden',
              height:
                windowSize.height > 550
                  ? '420px'
                  : `${windowSize.height - 130}px`,
            },
          },
          ...other.sx,
        }}
        {...other}
      >
        <NotificationTabs currentTab={currentTab} setCurrentTab={setCurrentTab}>
          <AlertList show={currentTab === 0} onItemClick={onItemClick} />
          <AlertList
            show={currentTab === 1}
            isRead={false}
            onItemClick={onItemClick}
          />
        </NotificationTabs>
      </Popover>
    </Box>
  );
};

const AlertPanel = props => {
  const { headerContainerRef, anchorEl, setAnchorEl } = props;
  // const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const alertButtonRef = useRef(null);

  const handleClick = event => {
    setAnchorEl(anchorEl === null ? headerContainerRef.current : null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { unreadAlertsCount } = useSelector(state => {
    return {
      unreadAlertsCount: state.Alert.unreadAlertsCount,
    };
  });

  useEffect(() => {
    dispatch(fetchUnreadAlertsCount());
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'header-alert-open-button' : undefined;

  return (
    <>
      <Badge
        badgeContent={unreadAlertsCount}
        sx={{
          '& .MuiBadge-badge': {
            top: 0,
            right: 0,
            transform: 'translate(-7px, 7px)',
            padding: 0,
            minWidth: '14px',
            width: '14px',
            height: '14px',
            size: '10px',
            fontSize: '10px',
            backgroundColor: '#e15958',
            lineHeight: '10px',
            textAlign: 'center',
            // zIndex: 9999,
          },
          '&.MuiBadge-root': {
            padding: '6px',
            backgroundColor: open ? '#CEDDF9' : '#154AB6',
            '& svg': {
              width: '32px',
              height: '32px',
              padding: '3px',
              '& path:nth-of-type(1)': {
                fill: open ? '#0052CC' : '#FAFBFC',
                stroke: open ? '#0052CC' : '#FAFBFC',
              },
              '& path:nth-of-type(2)': {
                stroke: open ? '#0052CC' : '#FAFBFC',
              },
            },
          },
          '&:hover.MuiBadge-root': {
            backgroundColor: '#CEDDF9',
            '& svg': {
              '& path:nth-of-type(1)': {
                fill: '#0052CC',
                stroke: '#0052CC',
              },
              '& path:nth-of-type(2)': {
                stroke: '#0052CC',
              },
            },
          },
        }}
      >
        <IconButton
          ref={alertButtonRef}
          aria-describedby={id}
          type='button'
          onClick={handleClick}
          disableRipple
          sx={{
            padding: '6px',
            transition: 'none',
          }}
        >
          <HeaderAlertIcon />
        </IconButton>
      </Badge>
      <AlertPopover
        anchorEl={anchorEl}
        handleClose={handleClose}
        alertButtonRef={alertButtonRef}
      />
    </>
  );
};

export default AlertPanel;

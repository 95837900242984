import * as actions from '../actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import { filterUsed2SelectState } from '../actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import MyLocalStorage from '../utils/MyLocalStorage';

const defaultUseFilters = {
  leadGeFullName: [],
  coGeFullName: [],
  handlingCe: [],
  group: [],
  groupNames: [],
  journalName: [],
  pdPublisher: [],
  ppPublisher: [],
  siCode: [],
  journalRevenueModel: [],
  SubjectGroup: [], // 这是向后端发送请求的大小写格式
  stage: [],
  acquiredDateFrom: [],
  acquiredDateTo: [],
  submissionDeadlineFrom: [],
  submissionDeadlineTo: [],
  state: [],
  closedforSubmission: [],
  createFromDate: [],
  createToDate: [],
  apFromDate: [],
  apToDate: [],
  siTitle: [],
  sisWithAlerts: [],
  siWithChinaGE: []
};

const initState = {
  // ge: ['Smith, John', 'Alice Li'],
  leadGeFullName: [],
  coGeFullName: [],
  handlingCe: [],
  catGroup: [],
  journal: [],
  jpm:[],
  pdPublisher: [],
  ppPublisher: [],
  subjectGroup: [],
  stage: [], // 这个结果较少可能不会用到
  businessDivision: [], //这个值是report basic table的filter属性
  siCode: [],
  journalrevenueModel: [],
  siTitle: [],

  // filters selection and application
  searchSI: '', // search的从SI移动到filter里面了，为了实现搜索和filter先后使用的需求，期望未来全部整合到SI Reducer&Action里面
  firstSIFilterSelected:null,
  siFilterSelected: {
    siTitleSelected: [],
    leadGeFullNameSelected: [],
    coGeFullNameSelected: [],
    handlingCeSelected: [],
    catGroupSelected: [],
    journalSelected: [],
    pdPublisherSelected: [],
    ppPublisherSelected: [],
    subjectGroupSelected: [],
    siCodeSelected: [],
    journalrevenueModelSelected: [],
    siWithChinaGE:[],
    siWithChinaGESelected:[],
    stageSelected: [],
    sisWithAlertsSelected:[],
    stateSelected:[],
    closedforSubmissionSelected:[],
    acquiredFromDate: null,
    acquiredToDate: null,
    deadlineFromDate: null,
    deadlineToDate: null,
    apToDate:null,
    apFromDate:null,
    createToDate:null,
    createFromDate:null
  },
  adaptSIFilterSelected:null,
  favSIFilterSelected:null,
  hasFilter:false,
  acquiredDate:{
    acquiredFromDate: null,
    acquiredToDate: null,
  },
  deadlineDate:{
    deadlineFromDate: null,
    deadlineToDate: null,
  },

  reportJournal: MyLocalStorage.getItem('FilterReducer-reportJournal') ?? [],
  reportJournalStatus: MyLocalStorage.getItem('FilterReducer-reportJournalStatus') ?? [],
  revenueModel: MyLocalStorage.getItem('FilterReducer-revenueModel') ?? false,
  reportJPM: MyLocalStorage.getItem('FilterReducer-reportJPM') ?? [],
  reportPDPublisher: MyLocalStorage.getItem('FilterReducer-reportPDPublisher') ?? [],
  reportPPPublisher: MyLocalStorage.getItem('FilterReducer-reportPPPublisher') ?? [],
  reportSubjectGroup: MyLocalStorage.getItem('FilterReducer-reportSubjectGroup') ?? [],
  reportBusinessDivision: MyLocalStorage.getItem('FilterReducer-reportBusinessDivision') ?? [],
  reportCATGroup: MyLocalStorage.getItem('FilterReducer-reportCATGroup') ?? [],
  reportHandlingCE: MyLocalStorage.getItem('FilterReducer-reportHandlingCE') ?? [],

  useFilters: null, // after applying filters

  basicReportFilters: MyLocalStorage.getItem('FilterReducer-basicReportFilters') ?? {
    journalName: [],
    revenueModel: [],
    jpm: [],
    pdPublisher: [],
    ppPublisher: [],
    subjectGroup: [],
    businessDivision: [],
    catGroup: [],
    handlingCe: [],
  },
};

export default function FilterReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.FIRST_LOAD_FAVOURITE:{
      if (data !== null) {
        // 特殊处理name
        const fav = filterUsed2SelectState(data);
        return {
          ...state,
          ...fav,
          firstSIFilterSelected:fav['siFilterSelected']
        };
      } else {
        return state;
      }
    }
    case actions.SET_ADAPT_SIFILTERSELECTED:{
      return{
        ...state,
        adaptSIFilterSelected:data,
      };
    }
    case actions.SET_FAV_SIFILTERSELECTED:{
      return{
        ...state,
        favSIFilterSelected:data,
      };
    }
    case actions.LOAD_DATA: {
      // 因为没有分页需求了，所以删除了处理分页的代码
      return {
        ...state,
        [data.which]: data.data.result,
      };
    }
    case actions.SET_SELECT: {
      const keyInSIFilterSelected = data.which + 'Selected';
      if (state.siFilterSelected.hasOwnProperty(keyInSIFilterSelected)) {
        // 在siFilter中，可以根据规范节省代码
        return {
          ...state,
          siFilterSelected: {
            ...state.siFilterSelected,
            [keyInSIFilterSelected]: data.data,
          },
        };
      }
      // 其他属性的set
      switch (data.which) {
        case 'reportJournal': {
          MyLocalStorage.setItem('FilterReducer-reportJournal', data.data);
          return {
            ...state,
            reportJournal: data.data,
          };
        }
        case 'revenueModel': {
          MyLocalStorage.setItem('FilterReducer-revenueModel', data.data);
          return {
            ...state,
            revenueModel: data.data,
          };
        }
        case 'reportJPM': {
          MyLocalStorage.setItem('FilterReducer-reportJPM', data.data);
          return {
            ...state,
            reportJPM: data.data,
          };
        }
        case 'reportPDPublisher': {
          MyLocalStorage.setItem('FilterReducer-reportPDPublisher', data.data);
          return {
            ...state,
            reportPDPublisher: data.data,
          };
        }
        case 'reportPPPublisher': {
          MyLocalStorage.setItem('FilterReducer-reportPPPublisher', data.data);
          return {
            ...state,
            reportPPPublisher: data.data,
          };
        }
        case 'reportSubjectGroup': {
          MyLocalStorage.setItem('FilterReducer-reportSubjectGroup', data.data);
          return {
            ...state,
            reportSubjectGroup: data.data,
          };
        }
        case 'reportBusinessDivision': {
          MyLocalStorage.setItem('FilterReducer-reportBusinessDivision', data.data);
          return {
            ...state,
            reportBusinessDivision: data.data,
          };
        }
        case 'reportCATGroup': {
          MyLocalStorage.setItem('FilterReducer-reportCATGroup', data.data);
          return {
            ...state,
            reportCATGroup: data.data,
          };
        }
        case 'reportHandlingCE': {
          MyLocalStorage.setItem('FilterReducer-reportHandlingCE', data.data);
          return {
            ...state,
            reportHandlingCE: data.data,
          };
        }
        case 'reportJournalStatus': {
          MyLocalStorage.setItem('FilterReducer-reportJournalStatus', data.data);
          return {
            ...state,
            reportJournalStatus: data.data,
          };
        }
        case 'siWithChinaGE': {
          return {
            ...state,
            siFilterSelected: {
              ...state.siFilterSelected,
              siWithChinaGESelected: data.data,
            },
          };
        }
        default:
          return {
            ...state,
            [data.which + 'Selected']: data.data,
          };
      }
    }
    case actions.SAVE_FAVOURITE:
      return {
        ...state,
      };
    case actions.LOAD_FAVOURITE: {
      if (data !== null) {
        // 特殊处理name
        const fav = filterUsed2SelectState(data);
        return {
          ...state,
          ...fav,
        };
      } else {
        return state;
      }
    }
    case actions.RESET_FILTER:
      return {
        ...state,
        siFilterSelected: initState.siFilterSelected,
      };
    case actions.RESET_ACQUIRED_DATE_FILTER:
      return {
        ...state,
        siFilterSelected: initState.acquiredDate,
      };
    case actions.RESET_DEADLINE_DATE_FILTER:
      return {
        ...state,
        siFilterSelected: initState.deadlineDate,
      };
    case actions.SEARCH_SI:
      return {
        ...state,
        searchSI: data,
        // useFilters: initState.useFilters    // 重置filter ----> 放到这里好像不是很合理
      };
    case actions.CLEAR_USE_FILTER:
      return {
        ...state,
        useFilters: defaultUseFilters,
      };
    case actions.SET_FILTER: {
      // if data is a string of 'default', then the filter will be set to default
      const filters = data === 'default' ? defaultUseFilters : data;
      return {
        ...state,
        useFilters: filters,
      };
    }
    case actions.SET_DATE: {
      const which = data.which;
      const dateType = data.type;
      const newDate = data.newDate;
      switch(which){
        case 'acquire':
          return {
            ...state,
            siFilterSelected: {
              ...state.siFilterSelected,
              ...dateType === 'from'?{acquiredFromDate: newDate}:{acquiredToDate: newDate,},
            },
          };
        case 'deadline':
          return {
            ...state,
            siFilterSelected: {
              ...state.siFilterSelected,
              ...dateType === 'from'?{deadlineFromDate: newDate}:{deadlineToDate: newDate,},
            },
          };
        case 'apDate':
          return {
            ...state,
            siFilterSelected: {
              ...state.siFilterSelected,
              ...dateType === 'from'?{apFromDate: newDate}:{apToDate: newDate,},
            },
          };
        case 'createDate':
          return {
            ...state,
            siFilterSelected: {
              ...state.siFilterSelected,
              ...dateType === 'from'?{createFromDate: newDate}:{createToDate: newDate,},
            },
          };
        default:
          return state;
      }
    }
    case actions.SET_BASIC_REPORT_FILTE:
      MyLocalStorage.setItem('FilterReducer-basicReportFilters', data);
      return {
        ...state,
        basicReportFilters: data,
      };
    case actions.REPORT_FILTER_RESET: {
      MyLocalStorage.removeItem('FilterReducer-reportJournal');
      MyLocalStorage.removeItem('FilterReducer-revenueModel');
      MyLocalStorage.removeItem('FilterReducer-reportJPM');
      MyLocalStorage.removeItem('FilterReducer-reportPDPublisher');
      MyLocalStorage.removeItem('FilterReducer-reportPPPublisher');
      MyLocalStorage.removeItem('FilterReducer-reportSubjectGroup');
      MyLocalStorage.removeItem('FilterReducer-reportBusinessDivision');
      MyLocalStorage.removeItem('FilterReducer-reportCATGroup');
      MyLocalStorage.removeItem('FilterReducer-reportHandlingCE');
      MyLocalStorage.removeItem('FilterReducer-reportJournalStatus');
      return {
        ...state,
        reportJournal: [],
        revenueModel: false,
        reportJPM: [],
        reportPDPublisher:[],
        reportPPPublisher: [],
        reportSubjectGroup: [],
        reportBusinessDivision: [],
        reportCATGroup: [],
        reportHandlingCE: [],
        reportJournalStatus: [],
        basicReportFilters: {
          journalName: [],
          revenueModel: [],
          jpm: [],
          pdPublisher: [],
          ppPublisher: [],
          subjectGroup: [],
          businessDivision: [],
          catGroup: [],
          handlingCe: [],
        },
      };
    }
    default:
      return state;
  }
}

import { useDispatch } from 'react-redux';
import CVDownload from '@/assets/CVDownload.svg';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import DownloadContainerButton from '@/componentsphase2/Button/ContainerButtons/DownloadContainerButton';
import { ChooseIconByType, formatFileSize } from '@/pages/GEPage/GEDetailPage/FlagBlock';
import { GetLabel } from '@/components/FileUploader/LabelFile';
import {downloadCv} from "@/actions/SIMT-SIP/SIP/SipDetail";

export function DownloadCvBlock(props) {
  const {
    fileName,
    nativeFileName,
    hasName = false,
    fileSize,
  } = props;
  const file = fileName;
  const [isDownloading, setIsDownloading] = useState(false);

  const dispatch = useDispatch();
  const handleDownloadCv = async event => {
    if (event.type === 'click') {
      setIsDownloading(true);
      if(nativeFileName!==undefined) await dispatch(downloadCv(nativeFileName));
      else await dispatch(downloadCv(fileName));
      setIsDownloading(false);
    }
  };
  function getBit(value, bit = 1) {
    let str = Number(value);
    str = str.toFixed(bit);
    return str;
  }
  const formatSize = (size) => {
    if (typeof size !== 'number') {
      return size;
    }
    if (size < 1024) {
      return getBit(size) + 'B';
    } else if (size < 1048576) {
      return getBit(size / 1024) + 'KB';
    } else {
      return getBit(size / (1024 * 1024)) + 'MB';
    }
  };
  return (
    // <IconButton
    //   data-selenium-id='SIP_DetailPage_GE-DownloadCvBlock-IconButton'
    //   disabled={isDownloading}
    //   sx={{ padding: 0 }}
    //   onClick={handleDownloadCv}
    // >
    //   {isDownloading ? (
    //     <Box
    //       data-selenium-id='SIP_DetailPage_GE-DownloadCvBlock-Box'
    //       sx={{
    //         display: 'flex',
    //         boxSizing: 'border-box',
    //         padding: '3px',
    //         backgroundColor: '#f1f3f5',
    //         border: '1px solid #dfe4e8',
    //       }}
    //     >
    //       <CircularProgress
    //         size='19px'
    //         data-selenium-id='SIP_DetailPage_GE-DownloadCvBlock-CircularProgress'
    //       />
    //     </Box>
    //   ) : (
    //     <CVDownload data-selenium-id='SIP_DetailPage_GE-DownloadCvBlock-CV' />
    //   )}
    // </IconButton>

    hasName ?
      (<GetLabel
        title="DownLoadAllTip" aria-label="DownLoadAllTip" arrow position='top'
        FileIcon={ChooseIconByType(file.split('.')[file.split('.').length-1])}
        del={handleDownloadCv}
        fileName={file.substring(0, file.lastIndexOf('.'))}
        fileSize={formatSize(fileSize)}
        fileMIMEType={file.split('.')[file.split('.').length-1]}
        isDownLoad={true}
        // onClick={}
        sx={{
          background: '#F1F3F5',
          width: '350px',
          marginTop: '4px',
          marginLeft: '0',
        }}
      />
      ) : (
        <DownloadContainerButton
          data-selenium-id='SIP_DetailPage_GE-DownloadCvBlock-IconButton'
          disabled={isDownloading}
          sx={{ padding: 0 }}
          onClick={handleDownloadCv}
        ></DownloadContainerButton>
        
        )


  );
}

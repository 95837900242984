import CreateSIInputBlock from '../../CommonComponents/CreateSIInputBlock';
import CreateSISelectBlock from '../../CommonComponents/CreateSISelectBlock';
import CreateSIDisabledBlock from '../../CommonComponents/CreateSIDisabledBlock';
import {
  CreateAndUpdateLittleTitle,
  CreateAndUpdateTitle,
} from '../../CommonComponents/CreateAndUpdateTitle';
import CreateAndUpdateAutoComplete from '../../CommonComponents/CreateAndUpdateAutoComplete';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HandlingCEAutocomplete } from '../../CommonComponents/FuntionalComponents/HandlingCEAutocomplete';
import { scrollToTop } from '../../../../../utils/commonUtils';
import { useUpdateEffect } from 'ahooks';
import { SettingsOutlined } from '@mui/icons-material';
import ItemBlock from '../../CGTComponents/ItemBlock';
import { Stack } from '@mui/material';
import {loadPaperConfig, loadSITypes} from "@/actions/SIMT-User/Config";
import {getCE} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueSystemAdmin";
import {getJournalListWithCodeOptIn, getJournalListWithNameOptIn} from "@/actions/SIMT-SI/SpecialIssue/Journal";
import {getSepcialIssueSource, getSIRevenueModel, getWorkFlowList} from "@/actions/SIMT-SI/SpecialIssue/Config";

//延迟处理函数
export function sleep(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

function GeneralInformation(props) {
  const {
    clickedButton,
    allDateToBack,
    setAllDateToBack,
    StakeholdersAndDatesdata,
    setStakeholdersAndDatesdata,
    setHasError,
    isOA,
    setIsOA,
    clickTime,
  } = props;
  const dispatch = useDispatch();


  const [subjectGroup, setSubjectGroup] = useState([
    allDateToBack.subjectGroup,
  ]);
  //sidetail注入ads
  useEffect(() => {
    dispatch(getJournalListWithNameOptIn('', 1));
    dispatch(loadSITypes()); // 获取SI Publication Format下拉列表的后台数据
    dispatch(loadPaperConfig());
    dispatch(getWorkFlowList());
    dispatch(getSIRevenueModel());
    dispatch(getSepcialIssueSource());
    scrollToTop();
  }, []);

  //判断一个值是否为空或者空串
  const isEmptyValue = value => {
    return (
      value === null ||
      value === '' ||
      value === '()' ||
      value === undefined ||
      value.length === 0
    );
  };

  const selector = state => {
    return {
      CEResult: state.SI.CE, //dropdown Data
      JourListResult: state.SI.JourList,
      originJounralList: state.SI.JourList,
      moreJourListResult: state.SI.moreJourList, //click for more journal but not use now
      SITypeList: state.SystemConfig.SITypeList,
      PapaerComfigList: state.SystemConfig.PapaerComfigList,
      workFlowList: state.SI.workFlowList,
      siRevenueModelList: state.SI.siRevenueModelList,
      siSourceList: state.SI.siSourceList,
    };
  };

  const {
    CEResult,
    JourListResult,
    originJounralList,
    moreJourListResult,
    SITypeList,
    PapaerComfigList,
    workFlowList,
    siRevenueModelList,
    siSourceList,
  } = useSelector(selector);

  // console.log("jounrLIst:", originJounralList)

  /**************** Handling CE 相关方法 ************************/
  const [handleCe, setHandleCe] = useState(); //handleCE输入的数据
  const [CEValue, setCEValue] = useState(
    allDateToBack.handlingCeFullNameToBack +
      ' (' +
      allDateToBack.handlingCeEmailToBack +
      ')'
  );
  const [group, setGroup] = useState([allDateToBack.groupOrTitleToBack]); //CE的group/title
  const [ceId, setCeId] = useState([allDateToBack.handlingCeUidToBack]);
  let [CEData, setCEData1] = useState([
    {
      Id: 1,
      username: '',
      email: '',
    },
  ]);

  useEffect(() => {
    let result = CEResult;
    if (result !== null && result !== undefined && result !== 'error') {
      setCEData1(result);
    }
  }, [CEResult]);

  const handleCeSelect = value => {
    //将新获得的值传入 mainpage
    if (value) {
      setCEValue(value);

      let tmpEmail = value.split('(')[1];
      tmpEmail = tmpEmail.split(')')[0]; // 取出email
      let tmpId = -1;

      for (const element of CEData) {
        if (element.email === tmpEmail) {
          // console.log("element.groupName======" + element.groupName)
          tmpId = element.userId;
          setGroup(element.groupName);
          break;
        }
      }
      if (tmpId > 0) {
        setCeId(tmpId);
      }

      let tmpUsername = value.split('(')[0];
      // 修改handlingCeFullName、handlingCeEmail的值
      setAllDateToBack({
        ...allDateToBack,
        handlingCeFullNameToBack: tmpUsername,
        handlingCeEmailToBack: tmpEmail,
      });
    } else {
      setCEValue(value);
      setAllDateToBack({
        ...allDateToBack,
        handlingCeFullNameToBack: '',
        handlingCeEmailToBack: '',
      });
    }
  };

  useEffect(() => {
    setAllDateToBack({
      ...allDateToBack,
      handlingCeUidToBack: ceId,
      groupOrTitleToBack: group,
    });
  }, [ceId]);

  const [loading, setLoadeing] = useState(true);
  const getCEFromSearch = async value => {
    dispatch(getCE(value));
  };

  function loadCEData(active) {
    return async dispatch => {
      setLoadeing(false);
      await sleep(1e3); // For demo purposes.

      if (active) {
        getCEFromSearch(handleCe);
        // setCEData1(CEData)
      }
      setLoadeing(true);
    };
  }

  // 延迟获取CE值
  useEffect(() => {
    let active = true;
    if (handleCe === '') {
      return;
    } else {
      loadCEData(active);
    }

    return () => {
      active = false;
    };
  }, [handleCe]);

  /**************** SI Publication Format相关方法 ************************/
  const [SITypeData, setSITypeData1] = useState([]);
  useEffect(() => {
    setSITypeData1(SITypeList);
  }, [SITypeList]);

  /**************** Special Issue Title相关方法 ************************/
  const [SITitle, setSITitle] = useState([
    allDateToBack.specialIssueTitleToBack,
  ]);
  const handleSITitleChange = value => {
    var regu = '^[ ]+$';
    if (new RegExp(regu).test(value)) {
      value = '';
    }
    setSITitle(value);
  };

  // 改变mainPage里面对应的值
  useEffect(() => {
    let data = JSON.parse(JSON.stringify(allDateToBack));
    data.specialIssueTitleToBack = SITitle;
    setAllDateToBack(data);
  }, [SITitle]);

  /**************** Journal Code 和 Journal Name相关方法 ************************/

  //选中的journal
  const [journalSelect, setJournalSelect] = useState({
    // journalName: allDateToBack.journalNameToBack == null? "Temporary": allDateToBack.journalNameToBack,
    // journalCode: allDateToBack.journalCodeToBack == null ? "Temporary": allDateToBack.journalCodeToBack,

    journalName:
      allDateToBack.journalNameToBack === null
        ? 'Temporary'
        : allDateToBack.journalNameToBack,
    journalCode:
      allDateToBack.journalCodeToBack === null
        ? 'Temporary'
        : allDateToBack.journalCodeToBack,
  });
  // 输入时的值
  const [journaleName, setJournalName] = useState('');
  const [journaleCode, setJournalCode] = useState('');
  //搜索时的值
  const [journalNameSelect, setJournalNameSelect] = useState([
    allDateToBack.journalNameToBack,
  ]);
  const [journalCodeSelect, setJournalCodeSelect] = useState([
    allDateToBack.journalCodeToBack,
  ]);

  const [siType, setSIType] = useState(allDateToBack.specialIssueTypeToBack);

  // 获得此时的revenueModel
  const [getrevenueModel, setGetRevenueModel] = useState(
    // allDateToBack.revenueModel
    'OA'
  );

  useEffect(() => {
    if (getrevenueModel === 'OA') {
      setAllDateToBack({
        ...allDateToBack,
        siRevenueModel: null,
        revenueModel: 'OA',
      });
    }
  }, [getrevenueModel]);

  const handleJounalSearchWithName = value => {
    if (value !== null) {
      setGetRevenueModel(value.revenueModel);
      setJournalSelect(value);
      setJournalCodeSelect(value.journalCode);
      setJournalNameSelect(value.journalName);
      setJournalCode(value.journalCode);
      if (value.revenueModel === 'OA') {
        setIsOA(true);
      } else {
        setIsOA(false);
      }
    } else {
      setGetRevenueModel(null);
      setJournalSelect(null);
      setJournalNameSelect(null);
      setJournalCodeSelect(null);
      setJournalCode(null);
    }
    // console.log(value.revenueModel, isOA)
  };

  const handleJounalSearchWithCode = value => {
    if (value !== null) {
      setGetRevenueModel(value.revenueModel);
      setJournalSelect(value);
      setJournalCodeSelect(value.journalCode);
      setJournalNameSelect(value.journalName);
      setJournalCode(value.journalCode);
      if (value.revenueModel === 'OA') {
        setIsOA(true);
      } else {
        setIsOA(false);
      }
    } else {
      setGetRevenueModel(null);
      setJournalSelect(null);
      setJournalNameSelect(null);
      setJournalCodeSelect(null);
      setJournalCode(null);
    }
    // console.log(value.revenueModel, isOA)
  };

  const handleJournalNameChange = event => {
    if (event !== null && event._reactName !== 'onClick') {
      setJournalName(event.target.value);
    }
  };

  const handleJournalCodeChange = event => {
    if (event !== null && event._reactName !== 'onClick') {
      setJournalCode(event.target.value);
    }
  };

  let [JournalData, setJournalData1] = useState([
    {
      Id: 1,
      journalName: 'Temporary',
      journalCode: 'Temporary',
      jpm: '',
      subjectGroup: '',
      revenueModel: '',
    },
  ]);

  useEffect(() => {
    let result = JourListResult;
    if (result.length === 0) {
      result.push({
        journalName: 'Temporary',
        journalCode: 'Temporary',
        jpm: '',
        subjectGroup: '',
        revenueModel: '',
      });
    }
    if (result !== null && result !== undefined && result !== 'error') {
      setJournalData1(result);
    }
  }, [JourListResult]);

  useEffect(() => {
    let result = moreJourListResult;
    if (result.length !== 0) {
      setJournalData1(JournalData.concat(result));
    }
  }, [moreJourListResult]);

  const [currentJournalPageC, setCurrentJournalPageC] = useState(1);
  const [currentJournalPageN, setCurrentJournalPageN] = useState(1);
  const getJournalFromCode = async value => {
    // value === 空进不来
    dispatch(getJournalListWithCodeOptIn(value, 1));
    setCurrentJournalPageC(1);
  };
  const getJournalFromName = async value => {
    dispatch(getJournalListWithNameOptIn(value, 1));
    setCurrentJournalPageN(1);
  };
  const getMoreJournalInfoFromCode = async () => {
    if (currentJournalPageN > 1) {
      await getJournalFromCode(journaleCode);
      setCurrentJournalPageN(1);
    }
    dispatch(
      getJournalListWithCodeOptIn(journaleCode, currentJournalPageC + 1)
    );
    setCurrentJournalPageC(currentJournalPageC + 1);
  };

  const getMoreJournalInfoFromName = async () => {
    if (currentJournalPageC > 1) {
      await getJournalFromName(journaleName);
      setCurrentJournalPageC(1);
    }
    dispatch(
      getJournalListWithNameOptIn(journaleName, currentJournalPageN + 1)
    );
    setCurrentJournalPageN(currentJournalPageN + 1);
  };
  // 延迟获取JournalName值
  useEffect(() => {
    let active = true;
    (async () => {
      await sleep(3e2); // For demo purposes.
      if (active) {
        if (!journaleName) getJournalFromName('');
        else getJournalFromName(journaleName);
      }
    })();

    return () => {
      active = false;
    };
  }, [journaleName]);

  //延迟获取JournalCode值
  useEffect(() => {
    let active = true;
    (async () => {
      await sleep(3e2); // For demo purposes.

      if (active) {
        if (!journaleCode) getJournalFromCode('');
        else await getJournalFromCode(journaleCode);
      }
    })();
    setHasError(check());
    return () => {
      active = false;
    };
  }, [journaleCode]);

  /**************** SI Source 的相关方法 ************************/
  const [SISource, setSISource] = useState(allDateToBack.siSourceToBack);
  const handleSISourceChange = (event, newValue) => {
    setSISource(newValue);
    let data = JSON.parse(JSON.stringify(allDateToBack));
    data.siSourceToBack = event.target.value;
    setAllDateToBack(data);
  };

  /**************** PaerComissioningMethod 的相关方法 ************************/
  const [paperCommition, setPaperCommition] = useState(
    allDateToBack.paperCommissioningMethodToBack
  );

  const handlePaperCommitionChange = (event, newValue) => {
    setPaperCommition(newValue);
    let data = JSON.parse(JSON.stringify(allDateToBack));
    data.paperCommissioningMethodToBack = event.target.value;
    setAllDateToBack(data);
  };

  const [PaperCData, setPaperCData1] = useState([]);

  useEffect(() => {
    setPaperCData1(PapaerComfigList);
  }, [PapaerComfigList]);

  /**************** WorkFLow 的相关方法 ************************/
  const [workflow, setWorkflow] = useState(allDateToBack.workFlowToBack);
  const handleWorkflowChange = (event, newValue) => {
    setWorkflow(newValue);
    let data = JSON.parse(JSON.stringify(allDateToBack));
    data.workFlowToBack = event.target.value;
    setAllDateToBack(data);
  };

  //通过获取url进行切片判断当前处于哪一个阶段
  const url = window.location.href.split('/');
  const getStage = () => {
    let stage = url[url.length - 1];
    switch (stage) {
      case 'Acquired':
      case 'Production':
      case 'Published':
        return stage;
      case 'PaperCommission':
        return 'Paper Commission';
      case 'SubmissionAndReview':
        return 'Submission and Review';
      default:
        return '';
    }
  };
  const stage = getStage();

  // 根据url决定大标题显示内容
  const urlDecide = 'Update Stage to ' + stage;

  //统计是否存在空值错误
  const check = () => {
    if (getrevenueModel !== 'OA' && stage === 'Published') {
      return (
        isEmptyValue(allDateToBack.siRevenueModel) ||
        isEmptyValue(CEValue) ||
        isEmptyValue(allDateToBack.specialIssueTypeToBack) ||
        isEmptyValue(SITitle) ||
        isEmptyValue(journalSelect?.journalCode) ||
        isEmptyValue(journalSelect?.journalName) ||
        isEmptyValue(SISource) ||
        isEmptyValue(allDateToBack.paperCommissioningMethodToBack) ||
        isEmptyValue(workflow)
      );
    } else {
      return (
        isEmptyValue(CEValue) ||
        isEmptyValue(allDateToBack.specialIssueTypeToBack) ||
        isEmptyValue(SITitle) ||
        isEmptyValue(journalSelect?.journalCode) ||
        isEmptyValue(journalSelect?.journalName) ||
        isEmptyValue(SISource) ||
        isEmptyValue(allDateToBack.paperCommissioningMethodToBack) ||
        isEmptyValue(workflow)
      );
    }
  };

  const currentPageHasError = check();
  //当前页面是否有错误的设置
  useEffect(() => {
    setHasError(currentPageHasError);
  }, [currentPageHasError]);

  const [WorkFlowDataList, setWorkFlowList] = useState([]);

  useEffect(() => {
    setWorkFlowList(
      workFlowList != []
        ? workFlowList.map(item => {
            return Object.assign({}, { Id: item.id, Name: item.value });
          })
        : []
    );
  }, [workFlowList]);

  const [SIRevenueModelList, setSIRevenueModelList] = useState([]);

  useEffect(() => {
    setSIRevenueModelList(
      siRevenueModelList != []
        ? siRevenueModelList.map(item => {
            return Object.assign({}, { Id: item.id, Name: item.value });
          })
        : []
    );
  }, [siRevenueModelList]);

  const [SISourceData, setSISourceList] = useState([]);

  useEffect(() => {
    setSISourceList(
      siSourceList != []
        ? siSourceList.map(item => {
            return Object.assign({}, { Id: item.id, Name: item.value });
          })
        : []
    );
  }, [siSourceList]);

  // 记录产生的错误 从上到下依次 用于将光标聚焦到错误的地方
  const recoredError = () => {
    if (isEmptyValue(CEValue)) {
      return 'UpdateSIHEInput';
    } else if (isEmptyValue(allDateToBack.specialIssueTypeToBack)) {
      return 'UpdateSITypeSelect';
    }
    // else if (
    //   getrevenueModel !== 'OA' &&
    //   stage === 'Published' &&
    //   isEmptyValue(allDateToBack.siRevenueModel)
    // ) {
    //   return 'UpdateSIRevenueModel';
    // }
    else if (isEmptyValue(SITitle)) {
      return 'UpdateSITitleInput';
    } else if (stage !== 'Published' && isEmptyValue(journalSelect)) {
      return 'UpdateSIJournalCodeInput';
    } else if (stage !== 'Published' && isEmptyValue(journalSelect)) {
      return 'UpdateSIJournalNameInput';
    } else if (isEmptyValue(SISource)) {
      return 'stage';
    } else if (isEmptyValue(allDateToBack.paperCommissioningMethodToBack)) {
      return 'paperCommissioningMethodSelect';
    } else if (isEmptyValue(workflow)) {
      return 'workFlowSelect';
    }
  };

  useUpdateEffect(() => {
    if (check()) {
      let currentDOM = document.getElementById('' + recoredError());
      if (currentDOM) {
        setTimeout(() => {
          window.scrollTo({ left: 0, top: 0, behavior: 'instant' });
          window.scroll({
            left: 0,
            top:
              currentDOM.getBoundingClientRect().top -
              window.screen.availHeight / 2 +
              100,
            behavior: 'instant',
          });
        }, 200);
      }
    }
  }, [clickTime]);

  return (
    <>
      <CreateAndUpdateTitle
        id='createSIGeneralInfotitle'
        data-selenium-id='SI_CreateAndUpdate_UpdatePages-GeneralInformationForm-Title'
        title={urlDecide}
      ></CreateAndUpdateTitle>

      <CreateAndUpdateLittleTitle
        id='createSIGeneralInfolittletitle'
        data-selenium-id='SI_CreateAndUpdate_UpdatePages-GeneralInformationForm-LittleTitle'
        littleTitle='General Information'
      ></CreateAndUpdateLittleTitle>

      <Stack spacing={2} direction='column'>
        <CreateSIDisabledBlock
          id='createSIProductionStageDisabledBlock'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-GeneralInformationForm-UpdateSIStageInput'
          title='SI Code'
          defaultValue={allDateToBack.projectCodeToBack}
        ></CreateSIDisabledBlock>

        {/* Handling CE  ** */}
        <HandlingCEAutocomplete
          id='handlingCEAutocompleteHandlingCE'
          required={true}
          value={CEValue}
          onChange={(event, newValue) => {
            handleCeSelect(newValue);
          }}
          emptyErr={!clickedButton && isEmptyValue(CEValue)}
        ></HandlingCEAutocomplete>

        {/* Group/Title ** */}
        <CreateSIDisabledBlock
          id='UpdateSIGroupInput'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-GeneralInformationForm-UpdateSIGroupInput'
          title='Group/Title'
          value={group}
        ></CreateSIDisabledBlock>

        {/* SI Publication Format ** */}
        <CreateSISelectBlock
          id='createSISelectBlockSIPublicationFormat'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-GeneralInformationForm-UpdateSITypeSelect'
          title='SI Publication Format'
          required={true}
          list={SITypeData}
          placeholder='Select SI Publication Format'
          value={siType}
          attribute='value'
          onChange={(e, newValue) => {
            setSIType(newValue);
            setAllDateToBack({
              ...allDateToBack,
              specialIssueTypeToBack: e.target.value,
            });
          }}
          emptyErr={
            !clickedButton && isEmptyValue(allDateToBack.specialIssueTypeToBack)
          }
        />

        {/* Special Issue Title ** 超过需要警告*/}
        <CreateSIInputBlock
          id='createSIInputBlockSpecialIssueTitle'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-GeneralInformationForm-UpdateSITitleInput'
          title='Special Issue Title'
          placeholder='Type SI Title'
          required={true}
          defaultValue={SITitle}
          value={SITitle}
          limitLength={400}
          setValue={handleSITitleChange}
          emptyErr={!clickedButton && isEmptyValue(SITitle)}
        ></CreateSIInputBlock>

        {/* Journal Code */}
        {stage !== 'Published' ? (
          <CreateAndUpdateAutoComplete
            id='createAndUpdateAutoCompleteJournalCode'
            data-selenium-id='SI_CreateAndUpdate_UpdatePages-GeneralInformationForm-UpdateSIJournalCodeInput'
            title='Journal Code'
            required={true}
            value={journalSelect}
            defaultValue={journalSelect}
            options={
              JournalData.length === 0
                ? JournalData.concat({
                    journalName: 'Temporary',
                    journalCode: 'Temporary',
                  })
                : JournalData
            }
            getOptionLabel={option => option?.journalCode}
            renderOption={(props, option) => {
              return !option.showMore ? (
                <li {...props}>{option?.journalCode}</li>
              ) : (
                <li {...props} onClick={getMoreJournalInfoFromCode}>
                  {option?.journalCode}
                </li>
              );
            }}
            onChange={(event, newValue) => {
              handleJounalSearchWithCode(newValue);
              setSubjectGroup(newValue?.subjectGroup);
              setAllDateToBack({
                ...allDateToBack,
                journalCodeToBack: newValue?.journalCode,
                journalNameToBack: newValue?.journalName,
              });
              setStakeholdersAndDatesdata({
                ...StakeholdersAndDatesdata,
                JPM: newValue?.jpm,
              });
            }}
            getOptionDisabled={option =>
              option.journalCode === journalCodeSelect
            }
            onInputChange={handleJournalCodeChange}
            empty={isEmptyValue(journalSelect?.journalCode)}
            isErr={
              (!clickedButton && isEmptyValue(journalSelect?.journalCode)) ||
              journalCodeSelect === 'Temporary'
            }
            errorText={
              journalCodeSelect === 'Temporary'
                ? 'Please contact your JPM to add the journal information into Product Data Management system (STIBO) as soon as possible!'
                : 'This field is required.'
            }
          ></CreateAndUpdateAutoComplete>
        ) : (
          <CreateSIDisabledBlock
            id='UpdateSIJournalCodeInput'
            data-selenium-id='SI_CreateAndUpdate_UpdatePages-GeneralInformationForm-UpdateSIJournalCodeInput'
            title='Journal Code'
            defaultValue={allDateToBack.journalCodeToBack}
          ></CreateSIDisabledBlock>
        )}

        {/* Journal Name */}
        {stage !== 'Published' ? (
          <CreateAndUpdateAutoComplete
            id='createAndUpdateAutoCompleteJournalName'
            data-selenium-id='SI_CreateAndUpdate_UpdatePages-GeneralInformationForm-UpdateSIJournalNameInput'
            title='Journal Name'
            required={true}
            value={journalSelect}
            defaultValue={journalSelect}
            options={
              JournalData.length === 0
                ? JournalData.concat({
                    journalName: 'Temporary',
                    journalCode: 'Temporary',
                  })
                : JournalData
            }
            getOptionLabel={option => option.journalName}
            renderOption={(props, option) => {
              return !option.showMore ? (
                <li {...props}>{option.journalName}</li>
              ) : (
                <li {...props} onClick={getMoreJournalInfoFromName}>
                  {option.journalName}
                </li>
              );
            }}
            onChange={(event, newValue) => {
              handleJounalSearchWithName(newValue);
              setSubjectGroup(newValue?.subjectGroup);
              setAllDateToBack({
                ...allDateToBack,
                journalCodeToBack: newValue?.journalCode,
                journalNameToBack: newValue?.journalName,
              });
              setStakeholdersAndDatesdata({
                ...StakeholdersAndDatesdata,
                JPM: newValue?.jpm,
              });
            }}
            onInputChange={handleJournalNameChange}
            getOptionDisabled={option =>
              option.journalName === journalNameSelect
            }
            empty={isEmptyValue(journalSelect?.journalName)}
            isErr={
              (!clickedButton && isEmptyValue(journalSelect?.journalName)) ||
              journalCodeSelect === 'Temporary'
            }
            errorText={
              journalCodeSelect === 'Temporary'
                ? 'Please contact your JPM to add the journal information into Product Data Management system (STIBO) as soon as possible!'
                : 'This field is required.'
            }
          ></CreateAndUpdateAutoComplete>
        ) : (
          <CreateSIDisabledBlock
            id='UpdateSIJournalNameInput'
            data-selenium-id='SI_CreateAndUpdate_UpdatePages-GeneralInformationForm-UpdateSIJournalNameInput'
            title='Journal Name'
            defaultValue={allDateToBack.journalNameToBack}
          ></CreateSIDisabledBlock>
        )}

        {/* Subject group */}
        <CreateSIDisabledBlock
          id='UpdateSISubjectGroupInput'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-GeneralInformationForm-UpdateSISubjectGroupInput'
          title='Subject Group'
          defaultValue={allDateToBack.subjectGroupToBack}
          value={subjectGroup}
        ></CreateSIDisabledBlock>

        {/* Special Issue Source */}
        <CreateSISelectBlock
          id='createSIInputBlockSpecialIssueSource'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-GeneralInformationForm-stage'
          title='Special Issue Source'
          required={true}
          value={SISource}
          list={SISourceData}
          onChange={handleSISourceChange}
          emptyErr={!clickedButton && isEmptyValue(SISource)}
          placeholder='Select SI Source'
          attribute='Name'
        />

        {/* Paper Commissioning Method */}
        <CreateSISelectBlock
          id='createSIInputBlockPaperCommissionMethod'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-GeneralInformationForm-paperCommissioningMethodSelect'
          title='Paper Commissioning Method'
          placeholder='Select Paper Commissioning Method'
          required={true}
          list={PaperCData}
          value={paperCommition}
          attribute='value'
          onChange={handlePaperCommitionChange}
          emptyErr={
            !clickedButton &&
            isEmptyValue(allDateToBack.paperCommissioningMethodToBack)
          }
        />

        {/* Workflow */}
        <CreateSISelectBlock
          id='createSIInputBlockWorkflow'
          data-selenium-id='SI_CreateAndUpdate_UpdatePages-GeneralInformationForm-workFlowSelect'
          title='Workflow'
          required={true}
          placeholder='Select Workflow'
          value={workflow}
          onChange={handleWorkflowChange}
          list={WorkFlowDataList}
          attribute='Name'
          emptyErr={!clickedButton && isEmptyValue(workflow)}
          mergeIntoDocumentFlow={true}
        />
      </Stack>
    </>
  );
}
export default GeneralInformation;

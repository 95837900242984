import moment from "moment/moment";
import {axios_instance_fileDownload} from "@/utils/axios_instance";
import {downloadFile} from "@/utils/commonUtils";
import {SET_SIP_EXPORT_ALL_STATE, SET_SIP_EXPORT_STATE} from "@/actions/SIMT-SIP/SIP/SIPAction";

import getAuth from "@/utils/getAuth";

export const exportSIPDataUrl='/api/v1/sip-service/special-issue-proposals/export'; 
/**
 * @param {import("./type").exportSIPDataRequest} data 
 */
const exportSIPDataOfCsvExcel=(data)=>axios_instance_fileDownload.post(
  exportSIPDataUrl,data,{
      responseType: 'blob',
      headers: {
          authorization: getAuth()??'',
      },
  }
).then((/**@type {import("@/utils/axios-response").AxiosResponse<BlobPart>}*/e)=>e);
/**
 *
 * @param {string[]} sipOverviewSeletedData - 需要导出的 SIP Code
 * @param {("xlsx" | "csv")} type - 需要导出的类型
 * @param {boolean} solicited
 * @returns
 */
export const exportSIPData = (sipOverviewSeletedData, type, solicited) => {
    if (type.length === 0) type = 'xlsx';
    let filename = '';
    if (solicited) {
        filename = `SSIPList_${moment()
            .utc()
            .format('MMDDYYYY_HHmmss')}.${type}`;
    } else {
        filename = `USIPList_${moment()
            .utc()
            .format('MMDDYYYY_HHmmss')}.${type}`;
    }

    /**
     * @type {{readonly xlsx:'excel';readonly csv:'csv'}}
     */
    const typeToUrl = {
        xlsx: 'excel',
        csv: 'csv',
    };

    /**
     * @param {import("redux").Dispatch} dispatch
     */
    return async dispatch => {
        try {
            const res=await exportSIPDataOfCsvExcel({
              sipCodes:sipOverviewSeletedData,
              fileType:typeToUrl[type],
                    solicited},
            );
            downloadFile(res.data, filename);
            dispatch({type: SET_SIP_EXPORT_STATE, data: 'finish'});
        } catch (err) {
            dispatch({type: SET_SIP_EXPORT_STATE, data: 'error'});
        }
    };
};
/**
 *
 * @param {(Date | null)} startDate - 需要导出的起始日期
 * @param {(Date | null)} endDate - 需要导出的终止日期
 * @param {("xlsx" | "csv")} type - 需要导出的类型
 * @returns
 */
export const exportAllSIPData = (startDate, endDate, type) => {
    if (startDate === null || endDate === null || type === '') return;

    const filename = `SIPdata-${moment()
        .utc()
        .format('YYYY-MM-DD HH-mm-ss')}.${type}`;

    const typeToFileType = {
        xlsx: 'excel',
        csv: 'csv',
    };

    /**
     * @param {number} value
     * @param {number} maxLength
     * @return {string}
     */
    const addLeadingZero = (value, maxLength = 2) => {
        return `${value}`.padStart(maxLength, '0');
    };

    const startDateString = `${startDate.getFullYear()}${addLeadingZero(
        startDate.getMonth() + 1
    )}${addLeadingZero(startDate.getDate())}`;
    const endDateString = `${endDate.getFullYear()}${addLeadingZero(
        endDate.getMonth() + 1
    )}${addLeadingZero(endDate.getDate())}`;

    return async dispatch => {
        try {
            const res = await axios_instance_fileDownload.get('/api/v1/sip-service/special-issue-proposals/export', {
                responseType: 'blob',
                params: {
                    fileType: typeToFileType[type],
                    startDate: startDateString,
                    endDate: endDateString,
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    authorization: localStorage.getItem('authorization'),
                },
            });
            downloadFile(res.data, filename);
            dispatch({type: SET_SIP_EXPORT_ALL_STATE, data: 'finish'});
        } catch (err) {
            dispatch({type: SET_SIP_EXPORT_ALL_STATE, data: 'error'});
        }
    };
};
import { MiniBoldFont, MiniFont, SmallFont } from '@/components/FontStyle';
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  MenuItem,
  Select,
  Stack,
  Tooltip,
} from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';
import { useEffect, useRef, useState } from 'react';
import ChipClearIcon from '../../../SVGComponents/ChipClearIcon.svg';
import ChipIcon from './ChipIcon';
import IconBox from '../../../CommonComponets/IconBox';
import Arrow from '../../../SVGComponents/Arrow.svg';
import CheckboxIcon from '../../../SVGComponents/CheckboxIcon.svg';
import CheckboxCheckedIcon from '../../../SVGComponents/CheckboxCheckedIcon.svg';
import CheckboxIconTiny from '../../../SVGComponents/CheckboxIconTiny.svg';
import CheckboxCheckedIconTiny from '../../../SVGComponents/CheckboxCheckedIconTiny.svg';
import { ColumnCentered } from '../../../Css';
import { alertListWithoutline } from '../utils';

export default function SelectBox(props) {
  const {
    id,
    dropList = [],
    value,
    setValue,
    placeholder,
    needChipIcon = false,
    dropListHeight = '0px',
    ...others
  } = props;
  const [open, setOpen] = useState(false);

  const endAttchment = props => <Arrow {...props}></Arrow>;

  function isChecked(item, list) {
    for (let i = 0; i < list.length; i++) {
      if (item === list[i]) {
        return true;
      }
    }
    return false;
  }

  function handleOnOpen() {
    setOpen(true);
    setTimeout(() => {
      let select = document.getElementById(id);
      let papper = document.getElementById(`${id}_paper`);
      let padding = needChipIcon ? 12 * 2 : 6 * 2;
      let bottom =
        Number(papper.style.marginTop.split('px')[0]) +
        Number(papper.style.top.split('px')[0]) +
        Number(dropListHeight.split('px')[0]) +
        padding;
      let selectBottom = select.getBoundingClientRect().top + 40;
      let difHeightOrigin =
        selectBottom -
        (Number(papper.style.marginTop.split('px')[0]) +
          Number(papper.style.top.split('px')[0]));
      let outer = document.getElementById('sioverview-filter-scrollbox');
      if (window.innerHeight < bottom + 68 + 26) {
        let difHeight = bottom + 68 + 26 - window.innerHeight;
        let scrollTop = outer.scrollTop;
        outer.scrollTo(0,scrollTop+difHeight+difHeightOrigin);
        setTimeout(()=>{
          papper.style.top =`${Number(papper.style.top.split('px')[0]) - difHeight+6}px`;
        },100);
      }
    }, 100);
  }

  return (
    <Select
      multiple
      id={id}
      data-selenium-id={id}
      value={value}
      open={open}
      onOpen={handleOnOpen}
      onClose={()=>{
        setOpen(false);
      }}
      renderValue={selected => {
        if (selected.length === 0)
          return (
            <Box
              sx={{
                ...SmallFont,
                lineHeight: '22px',
                color: '#BCC5CF',
                height: '22px',
              }}
            >
              {placeholder}
            </Box>
          );
        return (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              rowGap: '7px',
              columnGap: '4px',
            }}
          >
            {selected.map((value, idx) => (
              <Chip
                label={value}
                key={value}
                icon={needChipIcon ? <ChipIcon mode={alertListWithoutline.indexOf(value)} /> : null}
                deleteIcon={
                  <Box sx={{ display: 'flex' }}>
                    <ChipClearIcon />
                  </Box>
                }
                onDelete={e => {
                  let newSelected = selected.concat();
                  newSelected.splice(idx, 1);
                  setValue(newSelected);
                }}
                onMouseDown={e => {
                  e.stopPropagation();
                }}
              />
            ))}
          </Box>
        );
      }}
      MenuProps={{
        PaperProps: {
          id:`${id}_paper`,
          style: {
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 2px 5px 0px #00000040',
            borderRadius: '5px',
            marginTop: '6px',
            paddingBottom: needChipIcon ? '12px' : '6px',
          },
        },
        MenuListProps: {
          style: {
            padding: '0px',
            paddingTop: needChipIcon ? '12px' : '6px',
          },
        },
      }}
      SelectDisplayProps={{
        style: {
          padding: '9px 36px 9px 12px',
          boxSizing: 'border-box',
        },
      }}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          border: value.length > 0 ? '1px solid #98A7B6' : '1px solid #DFE4E8',
          borderRadius: '4px',
        },
        '& .MuiOutlinedInput-notchedOutline:hover': {
          border: '1px solid #262E35',
        },
        '& .MuiOutlinedInput-notchedOutline:focus': {
          border: '2px solid ##154AB6',
        },
        '& .MuiSelect-icon': {
          top: '12px',
          right: '12px',
        },
        // '& .MuiSelect-icon:hover': {
        //   backgroundColor: '#DFE4E8',
        //   borderRadius: '2px',
        // },
        // '& .MuiSelect-icon:focus': {
        //   backgroundColor: '#DFE4E8',
        //   borderRadius: '2px',
        // },
        '& .MuiChip-root': {
          height: '22px',
          padding: '3px 8px 3px 12px',
          boxSizing: 'border-box',
          backgroundColor: '#FFFFFF',
          margin: '0px',
          //layout
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          //border
          border: '0.5px solid #98A7B6',
          borderRadius: '4px',
          cursor: 'default',
          '& .MuiChip-label': {
            padding: '0px',
            //font
            ...MiniFont,
            color: '#596A7C',
            letterSpacing: '0px',
            lineHeight: '20px',
            marginRight: '6px',
            marginLeft: needChipIcon ? '3px' : '0px',
          },
          '& .MuiChip-deleteIcon': {
            margin: '0px',
            cursor: 'pointer',
            '& :hover': {
              background: '#BCC5CF',
              borderRadius: '2px',
            },
          },
          '& .MuiChip-icon': {
            margin: '0px 3px 0px 0px',
          },
          ':hover': {
            backgroundColor: '#DFE4E8',
          },
        },
      }}
      IconComponent={endAttchment}
      displayEmpty
      {...others}
    >
      <Scrollbars
        style={{ height: dropListHeight }}
        renderTrackVertical={({ style, ...props }) => (
          <div
            style={{
              ...style,
              position: 'absolute',
              top: '2px',
              height: 'calc(100% - 2px)',
              right: '2px',
            }}
            {...props}
          ></div>
        )}
        renderTrackHorizontal={({ style, ...props }) => (
          <div
            style={{
              ...style,
              position: 'absolute',
              width: '0px',
              height: '0px',
            }}
            {...props}
          ></div>
        )}
        renderThumbVertical={({ style, ...props }) => (
          <div
            style={{
              ...style,
              backgroundColor: '#DFE4E8',
              opacity: '0.7',
              borderRadius: '10px',
              width: '6px',
            }}
            {...props}
          ></div>
        )}
        renderView={({ style, ...props }) => (
          <div
            style={{
              ...style,
              overflowY: 'auto',
              overflowX: 'hidden',
              height: dropListHeight,
              display: 'flex',
              flexDirection: 'column',
              gap: needChipIcon ? '12px' : '2px',
            }}
            {...props}
          ></div>
        )}
        thumbSize={94}
      >
        {dropList.map((item, idx) => {
          let checked = isChecked(item, value);
          return needChipIcon && item === 'horizontal line' ? (
            <Divider
              sx={{
                margin: '0px !important',
                marginLeft: '12px !important',
                width: '245px',
                borderColor: '#DFE4E8',
                borderWidth: '0.5px',
              }}
            />
          ) : (
            <MenuItem
              value={item}
              key={item}
              sx={{
                padding: '0px 12px !important',
                boxSizing: 'border-box',
                ...(needChipIcon
                  ? {
                      ...MiniBoldFont,
                      lineHeight: '16px',
                      color: '#262E35',
                      height: '16px',
                    }
                  : {
                      ...MiniFont,
                      lineHeight: '20px',
                      color: '#596A7C',
                      height: '24px',
                    }),
                letterSpacing: '0em',
                ...ColumnCentered,
                ':hover': {
                  backgroundColor: '#F1F3F5',
                  '& svg rect': checked
                    ? {fill: '#113D95'}
                    : {stroke: '#596A7C'},
                },
                ':active': {
                  backgroundColor: '#F1F3F5',
                  '& svg rect': checked
                    ? {fill: '#113D95'}
                    : {stroke: '#596A7C'}
                },
                '& .MuiTouchRipple-root': {
                  display: 'none',
                },
              }}
              onClick={() => {
                let newValue = value.concat();
                if (checked) {
                  let index = 0;
                  for (let i = 0; i < value.length; i++) {
                    if (value[i] === item) {
                      index = i;
                      break;
                    }
                  }
                  newValue.splice(index, 1);
                } else {
                  newValue.push(item);
                }
                setValue(newValue);
              }}
            >
              <Stack spacing={1} direction='row'>
                <Checkbox
                  icon={needChipIcon ? <CheckboxIconTiny /> : <CheckboxIcon />}
                  checkedIcon={
                    needChipIcon ? (
                      <CheckboxCheckedIconTiny />
                    ) : (
                      <CheckboxCheckedIcon />
                    )
                  }
                  checked={checked}
                  sx={{
                    padding: '0px',
                    ':hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                />
                <Stack spacing={0.375} direction='row' sx={ColumnCentered}>
                  {needChipIcon ? (
                    <ChipIcon mode={idx > 3 ? idx - 1 : idx} />
                  ) : null}
                  <Box sx={ColumnCentered}>{item}</Box>
                </Stack>
              </Stack>
            </MenuItem>
          );
        })}
      </Scrollbars>
    </Select>
  );
}

import { Box, Fab, Stack, fabClasses } from '@mui/material';
import MyDrawer from './components/MyDrawer';
import ItemBlock from '@/componentsphase2/Itemblock';
import MyItemBlock from './components/MyItemBlock';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InactiveBox from './components/InactiveBox';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import {
  clearCreateSIPState,
  setCreateSuccessAlert,
} from '@/actions/SIMT-SIP/SIP/SIPAction';
import AddIconButton from '@/componentsphase2/Button/AddIconButton';
import { StateButton } from '@/componentsphase2/StateButton';
import MyDialog from './components/MyDialog';
import { AlertBox, UntitledAlertBox } from '@/components/Alert/AlertBox';
import { RowCentered } from '@/componentsphase2/Autocomplete/CommonStyle';
import { useNavigate } from 'react-router-dom';
import TextCancelButton from '@/componentsphase2/Button/TextCancelButton';
import ScrollTop from '@/pages/SIPage/SIDetailPage/ScrollTop';
import BackToTop from '@/assets/backToTop.svg';
import NewSIPBrandIcon from '@/assets/NewSIPBrandIcon.svg';
import {getJournalListWithCode, getJournalListWithName} from "@/actions/SIMT-SI/SpecialIssue/Journal";
import {getSIPSourceOption} from "@/actions/SIMT-SIP/SIP/Config";
import {CreateSIP} from "@/actions/SIMT-SIP/SIP/SipCreate";

const createDialog = (
  <Box>
    <Box sx={RowCentered}>Are you sure to create this new SIP</Box>
    <Box>which will exist in SIMT permanently？</Box>
  </Box>
);
const cancelDialog = (
  <Box>'Are you sure to clear all the input and quit the SIP creation？'</Box>
);

export default function Body(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [journal, setJournal] = useState(null);
  const [sipTitle, setSIPTitle] = useState('');
  const [sipSource, setSIPSource] = useState(null);
  const [doubleConfimCreateOpen, setDoubleConfimCreateOpen] = useState(false);
  const [doubleConfimCancelOpen, setDoubleConfimCancelOpen] = useState(false);
  const [alertCancelOpen, setAlertCancelOpen] = useState(false);
  const [Creating, setCreating] = useState(false);
  const [journalFocused, setJournalFocused] = useState(false);
  const [sourceFocused, setSourceFocused] = useState(false);
  const [titleFocused, setTitleFocused] = useState(false);

  const { JourListResult, sipSourceOptions, createSIPResult } = useSelector(
    state => {
      return {
        JourListResult: state.SI.JourList,
        sipSourceOptions: state.SIP.sipSourceOptions,
        createSIPResult: state.SIP.createSIPResult,
      };
    }
  );

  const isEmptyValue = value => {
    return (
      value === null ||
      value === '' ||
      value === undefined ||
      value?.length === 0
    );
  };

  useEffect(() => {
    dispatch(getSIPSourceOption());
  }, []);
  useEffect(() => {
    if (createSIPResult !== 'Error' && createSIPResult !== 'UNDO') {
      setDoubleConfimCreateOpen(false);
      dispatch(setCreateSuccessAlert(true));
      navigate(`/simt/auth/sipRelated/sip/detail/${createSIPResult}`);
      dispatch(clearCreateSIPState());
    }
  }, [createSIPResult]);
  return (
    <Box
      sx={{
        padding: '30px 180px 32px 32px',
        boxSizing: 'border-box',
        backgroundColor: '#FFFFFF',
      }}
    >
      <Stack spacing={4} direction='column'>
        <MyDrawer defaultOpen={true} title='Object'>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              rowGap: '8px',
              columnGap: '56px',
              flexWrap: 'wrap',
            }}
          >
            <MyItemBlock
              columnAlign={true}
              id='journalCode'
              required={true}
              title='Journal Code:'
              height='33px'
              isError={Creating && !journalFocused && isEmptyValue(journal)}
              errorText='Please provide the required information.'
              sx={{ width: 'calc(50% - 28px)', minWidth: '518px' }}
            >
              <SinpleAutocomplete
                id='journalCode_input'
                value={journal}
                options={JourListResult ?? []}
                loadOptionAction={getJournalListWithCode}
                onChange={(e, newValue) => {
                  setJournal(newValue ?? null);
                }}
                placeholder='Entry Journal Code'
                // isErr={!journalCode && !journalFocused}
                isEmpty={!journal?.journalCode}
                sx={{ minWidth: '286px' }}
                config={{
                  outlinedInputProps: {
                    // inputSX: {
                    //   root: {
                    //     width: '286px',
                    //   },
                    // },
                    outlinedInputSX: {
                      padding: '7.5px 12px !important',
                    },
                  },
                  popupIcon: null,
                }}
                getOptionLabel={option => option?.journalCode ?? null}
                renderOption={(props, option) => (
                  <li {...props}>{option.journalCode}</li>
                )}
                //因为聚焦onFocus时点击按钮，失焦事件的处理会抢占表单弹出的执行，所以延缓一下失焦事件
                isErr={Creating && !journalFocused && isEmptyValue(journal)}

                onFocus={() => {
                  setJournalFocused(true);
                }}
                onBlur={() => {
                  setTimeout(()=>{
                    setJournalFocused(false);
                  }, 500);
                }}
              />
            </MyItemBlock>
            <MyItemBlock
              columnAlign={false}
              id='journalHomepage'
              required={false}
              title='Journal Homepage:'
              sx={{ width: 'calc(50% - 28px)', minWidth: '518px' }}
            >
              <InactiveBox value={journal?.primaryUrl} />
            </MyItemBlock>
            <MyItemBlock
              columnAlign={true}
              id='journalName'
              required={true}
              title='Journal Name:'
              height='33px'
              isError={Creating && !journalFocused && isEmptyValue(journal)}
              errorText='Please provide the required information.'
              sx={{ width: 'calc(50% - 28px)', minWidth: '518px' }}
            >
              <SinpleAutocomplete
                id='journalName_input'
                value={journal}
                options={JourListResult ?? []}
                loadOptionAction={getJournalListWithName}
                onChange={(e, newValue) => {
                  setJournal(newValue ?? null);
                }}
                placeholder='Entry Journal Name'
                // isErr={!journalCode && !journalFocused}
                isEmpty={!journal?.journalName}
                sx={{ minWidth: '286px' }}
                config={{
                  outlinedInputProps: {
                    // inputSX: {
                    //   root: {
                    //     width: '286px',
                    //   },
                    // },
                    outlinedInputSX: {
                      padding: '7.5px 12px !important',
                    },
                  },
                  popupIcon: null,
                }}
                getOptionLabel={option => option?.journalName ?? null}
                renderOption={(props, option) => (
                  <li {...props}>{option.journalName}</li>
                )}
                isErr={Creating && !journalFocused && isEmptyValue(journal)}
                onFocus={() => {
                  setJournalFocused(true);
                }}
                onBlur={() => {
                  setTimeout(()=>{
                    setJournalFocused(false);
                  }, 500);
                }}
              />
            </MyItemBlock>
            <MyItemBlock
              columnAlign={false}
              id='authorGuidelines'
              required={false}
              title='Author Guidelines:'
              sx={{ width: 'calc(50% - 28px)', minWidth: '518px' }}
            >
              <InactiveBox value={journal?.authorGuidelines} />
            </MyItemBlock>
          </Box>
        </MyDrawer>
        <MyDrawer defaultOpen={true} title='SIP Information'>
          <Stack spacing={1} direction='column'>
            <MyItemBlock
              columnAlign={true}
              id='sipSource'
              required={true}
              title='SIP Source:'
              height='33px'
              isError={Creating && !sourceFocused && isEmptyValue(sipSource)}
              errorText='Please provide the required information.'
              sx={{ width: '100%' }}
            >
              <SinpleAutocomplete
                id='sipSource_input'
                value={sipSource ?? ''}
                options={sipSourceOptions}
                defaultQuery={true}
                onChange={(e, newValue) => {
                  setSIPSource(newValue);
                }}
                placeholder='Select SIP Source'
                // isErr={!journalCode && !journalFocused}
                isEmpty={!sipSource}
                config={{
                  outlinedInputProps: {
                    inputSX: {
                      root: {
                        width: '100%',
                      },
                    },
                    outlinedInputSX: {
                      padding: '7.5px 12px !important',
                    },
                  },
                  endAttchmentProps: {
                    endAttchmentSX: {
                      top: '7.5px',
                    },
                  },
                }}
                getOptionLabel={option => option?.value ?? ''}
                renderOption={(props, option) => (
                  <li {...props}>{option.value}</li>
                )}
                isErr={Creating && !sourceFocused && isEmptyValue(sipSource)}
                onFocus={() => {
                  setSourceFocused(true);
                }}
                onBlur={() => {
                  setTimeout(()=>{
                    setSourceFocused(false);
                  }, 500);
                }}
              />
            </MyItemBlock>
            <MyItemBlock
              columnAlign={true}
              id='sipTitle'
              required={true}
              title='SIP Title:'
              isError={Creating && !titleFocused && (!sipTitle || sipTitle?.length > 400)}
              errorText={!sipTitle ?'Please provide the required information.':'The maximum length for title is 400 characters.'}
              sx={{ width: '100%' }}
            >
              <MyOnlyText
                value={sipTitle}
                setValue={setSIPTitle}
                height='33px'
                placeholder='Enter SIP Title'
                isError={Creating && !titleFocused && (!sipTitle || sipTitle?.length > 400)}
                 onFocus={() => {
                  setTitleFocused(true);
                }}
                onBlur={() => {
                  setTimeout(()=>{
                    setTitleFocused(false);
                  }, 500);
                }}
              />
            </MyItemBlock>
          </Stack>
        </MyDrawer>
        <Stack
          spacing={1.5}
          direction='row'
          sx={{
            // width: '1092px',
            p: '16px 32px 16px 24px',
            borderRadius: '5px',
            background: '#4c81eb',
            color: '#fff',
          }}
        >
          <NewSIPBrandIcon style={{ flexShrink: 0 }} />
          <Stack spacing={1.5}>
            <Box sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '17px' }}>
              For Launching a joint SI across multiple journals
            </Box>
            <Box sx={{ fontSize: '12px' }}>
              <Box>
                <Box>
                  You only need to create one SI proposal for the first journal.
                  At the "Acquire" step, you could quickly duplicate the same SI
                  record for the remaining participating journals.
                </Box>
                <Box>
                  If you would like to leverage the "duplication" approach, your
                  joint SI must meet all the following conditions:
                </Box>
              </Box>
              <Box sx={{ mt: '6px', ml: '6px' }}>
                <Box>
                  1. It has the completely same Guest Editor team across all the
                  participating journals, including the same contact details.
                </Box>
                <Box>
                  2. The entire evaluation process for your SI proposal can be
                  skipped.
                </Box>
              </Box>
            </Box>
          </Stack>
        </Stack>
        <Stack direction='row-reverse' spacing={6.375}>
          <StateButton
            titleName='Create'
            sx={{ padding: '12px 34px' }}
            onClick={() => {
              setCreating(true);
              if (!journal || !sipSource || !sipTitle || sipTitle?.length > 400) {
                return;
              }
              setDoubleConfimCreateOpen(true);
            }}
            disabled={Creating && (!journal || !sipSource || !sipTitle || sipTitle?.length > 400)}
          />
          <StateButton
            titleName='Cancel'
            sx={{ padding: '10.5px 12px' }}
            onClick={() => {
              setDoubleConfimCancelOpen(true);
            }}
            isText={true}
          />
        </Stack>
      </Stack>
      <MyDialog
        open={doubleConfimCancelOpen}
        handleClose={() => {
          setDoubleConfimCancelOpen(false);
        }}
        handleAgree={() => {
          setJournal(null);
          setSIPSource(null);
          setSIPTitle('');
          setDoubleConfimCancelOpen(false);
          setAlertCancelOpen(true);
          setCreating(false);
        }}
        dialogContent={cancelDialog}
      />
      <UntitledAlertBox
        open={alertCancelOpen}
        handleClose={() => {
          setAlertCancelOpen(false);
        }}
        message='System didn’t save your most recent action.'
      />
      <MyDialog
        open={doubleConfimCreateOpen}
        handleClose={() => {
          setDoubleConfimCreateOpen(false);
        }}
        handleAgree={() => {
          dispatch(CreateSIP(journal?.journalCode, sipTitle, sipSource?.value));
        }}
        dialogContent={createDialog}
        dialogContentHeight='46px'
      />
      {/* <ScrollTop
          data-selenium-id='SIPPage_SIPCreatePage-ScrollTop'
          sx={
            {bottom:103,
              opacity: '1 !important',
              visibility: 'visible !important',}
          }
        >
          <Fab
            size='medium'
            variant='extended'
            color='primary'
            aria-label='scroll back to top'
            disableFocusRipple
            disableRipple
            id={'BackToTopButton'}
            data-selenium-id={
              'SIPPage_SIPCreatePage-BackToTopButton'
            }
            sx={{
              backgroundColor: '#0052CC',
              borderRadius: '30px',
              [`&.${fabClasses.root}`]: {
                textTransform: 'none',
                boxShadow: 'none',
                fontWeight: 400,
              },
              '&:hover': {
                backgroundColor: '#0052CC',
              },
            }}
          >
            Back To Top &nbsp;
            <BackToTop data-selenium-id='SIPPage_SIPCreatePage-ScrollTop-BackToTop' />
          </Fab>
        </ScrollTop> */}
    </Box>
  );
}

/**
 * This file includes basic eChart options for barchart & piechart diagram type
 * The 1st part has some common constants
 * The 2nd defined an Options class
 */

import { BasicLayoutChartOptions } from './BasicLayoutChartOptions';

/**
 * padding left and right
 */
const BAR_AND_PIE_CHART_PADDING_LEFT = 72;
const BAR_AND_PIE_CHART_PADDING_TOP = 35;
const BAR_AND_PIE_CHART_PADDING_RIGHT = 45;
const BAR_AND_PIE_CHART_PADDING_BOTTOM = 35;

/**
 * title & legend
 */
const TITLE_HEIGHT = 70;
const TITLE_TOP_PADDING = 30;

/**
 * bar chart
 */
const BARCHART_TOP = 70;
const BARCHART_LEFT = 240;
const BARCHART_RIGHT = 32;
const BARCHART_HEIGHT = 413;

/**
 * title & legend
 */
const LEGEND_HEIGHT = 40;
const LEGEND_PADDING_BOTTOM = 25;

/**
 * zoom controller
 */
const ZOOM_CONTROL_PADDING_LEFT = 10;
const ZOOM_CONTROL_RIGHT_OFFSET = 30;

/**
 * Pie chart
 */
const PIE_CHART_PADDING_LEFT = 30;
const PIECHART_RADIUS = 75;
const MAX_LABEL_SIZE = 7;

/**
 * dataZoom
 */
const DATA_ZOOM_INSIDE = {
  type: 'inside',
  yAxisIndex: 0,
  start: 0,
  end: 100,
};

const DATA_ZOOM_SLIDER = {
  type: 'slider',
  yAxisIndex: 0,
  // right
  show: true,
  showDetail: true,
  showDataShadow: false,
  fillerColor: '#4C81EB',
  backgroundColor: '#D9D9D9B3',
  borderRadius: 15,
  width: 4,
  moveHandleSize: 0,
  handleIcon: 'circle',
  handleSize: 12,
  handleStyle: {
    borderColor: '#FFFFFF',
    borderWidth: 3,
    color: '#4C81EB',
  },
  textStyle: {
    fontSize: 8,
    color: '#113D95',
    backgroundColor: '#F1F3F5',
    padding: 5,
    height: 18,
    lineHeight: 18,
    borderRadius: 3,
  },
  filterMode: 'empty',
};

const LINE_STYLE = {
  Color: '#DCDCDC',
  Type: 'solid',
  width: 0.4,
  opacity: 1,
};

const X_AXIS_LABLE_STYLE = {
  color: '#6F6F6F',
  fontStyle: 'normal',
  fontWeight: 400,
  fontFamily: 'Open Sans',
  fontSize: 10,
  lineHeight: 10,
};

const X_AXIS = {
  type: 'value',
  gridIndex: 0,
  nameTextStyle: {
    fontSize: '50px',
    align: 'left',
  },
  splitNumber: 10,
  /**
   * modified start
   *
   * min is integer
   */
  minInterval: 1,
  /**
   * modified end
   *
   * min is integer
   */
  axisLabel: {
    show: true,
    interval: 'auto',
    inside: false,
    rotate: 0,
    margin: 8,
    formatter: function (value, index) {
      return value;
    },
    showMinLabel: null,
    showMaxLabel: null,
    textStyle: X_AXIS_LABLE_STYLE,
  },
  splitLine: {
    show: true,
    interval: 'auto',
    lineStyle: LINE_STYLE,
  },
};

const Y_AXIS_LABLE_STYLE = {
  color: '#6F6F6F',
  fontStyle: 'normal',
  fontWeight: 400,
  fontFamily: 'Open Sans',
  fontSize: 10,
  lineHeight: 8.5,
};

const Y_AXIS = {
  type: 'category',
  inverse: true,
  axisTick: {
    show: false,
  },
  axisLabel: {
    show: true,
    interval: 'auto',
    inside: false,
    rotate: 0,
    margin: 8,
    formatter: function (value, index) {
      return value;
    },
    showMinLabel: null,
    showMaxLabel: null,
    textStyle: Y_AXIS_LABLE_STYLE,
    // width: 75,
    overflow: 'break',
    verticalAlign: 'middle',
  },
  axisLine: {
    show: true,
    onZero: true,
    lineStyle: {
      color: '#979797',
      width: 0.4,
    },
  },
};

const BAR_SERIE_LABEL_STYLE = {
  datasetIndex: 0,
  type: 'bar',
  stack: 'total',
  label: {
    show: true,
  },
  emphasis: {
    disabled: true,
  },
  // itemStyle: {
  //   normal: {
  //     label: {
  //       show: true,
  //       position: 'inside',
  //       textStyle: {
  //         color: '#FFFFFF',
  //         fontSize: 7,
  //       },
  //     },
  //   },
  // },
};

const LEGEND_STYLE = {
  icon: 'circle',
  itemGap: 12,
  width: '415px',
  selectedMode: false,
  inactiveColor: '#ccc',
  textStyle: {
    fontFamily: 'Open Sans',
    fontSize: 10,
    fontWeight: '400',
    lineHeight: 14,
    color: '#596A7C',
  },
  bottom: LEGEND_PADDING_BOTTOM,
};

const TOOLTIP_STYLE = {
  showContent: true,
  trigger: 'item',
  padding: [6, 8, 6, 8], // top, right, bottom, left
  position: function (point, params, dom, rect, size) {
    const boxWidth = size.contentSize[0];
    const boxHeight = size.contentSize[1];

    const x = rect.x + 0.5 * rect.width - 0.5 * boxWidth;
    const y = rect.y - boxHeight - 4; // 4px above the item

    return [x, y];
  },
  // alwaysShowContent: true,
  formatter: params => {
    // console.log(params)
    const stageName = params.dimensionNames[params.seriesIndex + 1];
    let dataStr = `<div style="padding: 0; margin: 0;">
      <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 0 0 2.5px 0;">${params.name}</p>
      <p style="font-size: 10px; font-weight: 400;color: #848484; line-height: 14px; margin: 0;">${params.seriesName}&nbsp;:&nbsp;${params.data[stageName]}</p>
    </div>`;
    return dataStr;
  },
};

const TITLE = {
  textStyle: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 700,
    color: '#113D95',
    lineHeight: 17.5,
  },
  subtextStyle: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 400,
    color: '#113D95',
    lineHeight: 17.5,
  },
  left: 'center',
};

const GRID = {
  containLabel: true,
};

const PIE_COLORS = new Map([
  ['Idea or Proposal', '#71C8FF'],
  ['Acquired', '#4C81EB'],
  ['Paper Commission', '#FFB152'],
  ['Submission and Review', '#FF8180'],
  ['Production', '#9098E2'],
  ['Published', '#42C1B3'],
]);

export class BarAndPieChartOptions extends BasicLayoutChartOptions {
  constructor() {
    super();
    this.chartPaddingLeft = BAR_AND_PIE_CHART_PADDING_LEFT;
    this.chartPaddingRight = BAR_AND_PIE_CHART_PADDING_RIGHT;
    this.chartPaddingTop = BAR_AND_PIE_CHART_PADDING_TOP;
    this.chartPaddingBottom = BAR_AND_PIE_CHART_PADDING_BOTTOM;
    this.barchartTop = BARCHART_TOP;
    this.barchartLeft = BARCHART_LEFT;
    this.barchartRight = BARCHART_RIGHT;
    this.titleHeight = TITLE_HEIGHT;
    this.titleTopPadding = TITLE_TOP_PADDING;
    this.dataZoomInside = Object.assign({}, DATA_ZOOM_INSIDE);
    this.dataZoomSlider = Object.assign({}, DATA_ZOOM_SLIDER);
    this.zoomControlPaddingLeft = ZOOM_CONTROL_PADDING_LEFT;
    this.zoomControlRightOffset = ZOOM_CONTROL_RIGHT_OFFSET;
    this.pieChartPaddingLeft = PIE_CHART_PADDING_LEFT;
    this.pieRadius = PIECHART_RADIUS;
    this.pieColors = [...PIE_COLORS.values()];
    this.barSerieOption = Object.assign({}, BAR_SERIE_LABEL_STYLE);
    this.legendHeight = LEGEND_HEIGHT;
    this.hasTitle = false;
    this.title = '';
    this.subTitle = '';
    this.hasZoom = false;
    this.selectedLegend = [];
    this.dataForBar = null;
    this.dataForPie = null;
    this.width = 0;
  }

  getOption() {
    return {
      grid: this.getGrid(),
      legend: this.getSelectedLegend(),
      xAxis: X_AXIS,
      yAxis: this.getYAxis(),
      tooltip: this.getTooltip(),
      series: this.getSeries(),
      color: this.pieColors,
      title: this.getByTitle(),
      dataZoom: this.getZoom(),
      dataset: this.getDataset(),
    };
  }

  getHeightWithTitle() {
    let totalHeight =
      this.chartPaddingTop +
      this.getBarchartHeight() +
      this.gapBetweenChartAndLegend +
      (this.hasTitle && this.titleHeight) +
      this.legendHeight +
      this.chartPaddingBottom;

    // console.log("this.hasTitle, ", this.hasTitle)
    // console.log("totalHeight, ", totalHeight)
    return totalHeight;
  }

  getBarchartHeight() {
    return BARCHART_HEIGHT;
  }

  getGrid() {
    const grid = {
      ...GRID,
      left: this.barchartLeft + this.chartPaddingLeft,
      right:
        this.barchartRight +
        this.chartPaddingRight +
        (this.hasZoom && this.zoomControlPaddingLeft),
      top: !this.hasTitle
        ? this.chartPaddingTop
        : this.chartPaddingTop + this.titleHeight,
      height: this.getBarchartHeight(),
    };
    return grid;
  }

  getByTitle() {
    if (this.hasTitle) {
      return {
        ...TITLE,
        top: this.titleTopPadding,
        text: this.title,
        subtext: '(' + this.subTitle + ')',
      };
    }
    return undefined;
  }

  // set Title if hasTitle
  setTitle(hasTitle, text, subtext) {
    this.hasTitle = hasTitle;
    this.title = text;
    this.subTitle = subtext;
    return this;
  }

  getZoom() {
    if (this.hasZoom) {
      let slider = {
        ...this.dataZoomSlider,
        right: this.chartPaddingRight,
      };
      return [this.dataZoomInside, slider];
    }
    return undefined;
  }

  setZoom(hasZoom) {
    this.hasZoom = hasZoom;
    return this;
  }

  getSelectedLegend() {
    return {
      ...LEGEND_STYLE,
      selected: this.selectedLegend,
    };
  }

  setSelectedLegend(selectedLegend) {
    this.selectedLegend = selectedLegend;
    return this;
  }

  getYAxis() {
    return { ...Y_AXIS };
  }

  getTooltip() {
    return TOOLTIP_STYLE;
  }

  getDataset() {
    if (Array.isArray(this.dataSource)) {
      return this.dataSource.map(source => ({
        source,
      }));
    } else {
      return [
        {
          source: this.dataForBar,
        },
        {
          source: this.dataForPie,
        },
      ];
    }
  }

  setDataSourceArray(dataSource) {
    this.dataSource = dataSource;
    this.dataForBar = dataSource[0];
    // console.log('==this.dataForBar==', this.dataForBar);
    return this;
  }

  setDataSource(dataForBar, dataForPie) {
    this.dataForBar = dataForBar;
    this.dataForPie = dataForPie;
    return this;
  }

  /**
   * modified start
   *
   * Bar options
   *
   */
  setBarSerieOption({
    datasetIndex = 0,
    attributeName,
    name,
    stackName,
    color,
    borderColor,
    textColor,
    borderRadius,
  }) {
    // console.log('name || attributeName', name || attributeName);
    return {
      datasetIndex,
      name: name || attributeName,
      encode: { x: attributeName },
      stack: stackName,
      type: 'bar',
      label: {
        show: true,
      },
      barMinHeight: 3,
      barMinWidth: 10,
      barMaxWidth: 150,
      emphasis: {
        disabled: true,
      },
      color,
      itemStyle: {
        borderRadius,
        borderWidth: 1,
        borderColor: borderColor ?? color,
      },
      label: {
        show: true,
        position: 'inside',
        color: textColor,
        fontSize: this.getLabelMinFontSize(),
        formatter: params => {
          const labelValue = params.data[name || attributeName];
          if (labelValue === 0) {
            return '';
          } else {
            return labelValue;
          }
        },
      },
    };
  }
  /**
   * modified end
   *
   * Bar options
   *
   */

  // create series of pie and bar charts
  getSeries() {
    const pieSeries = this.getPieSeries();
    const barSeries = this.getBarSeries();

    return [...barSeries, ...pieSeries];
  }

  getBasicPieSerie(
    pieRadius,
    centerX,
    centerY,
    datasetIndex = 1,
    disableEmphasis = true,
    showTooltip = false,
    focus = 'none'
  ) {
    return {
      type: 'pie',
      // id: 'pie',
      radius: pieRadius,
      center: [centerX, centerY],
      label: {
        formatter: params => {
          return params.data[1];
        },
        fontSize: 10,
        fontWeight: 500,
        color: '#596A7C',
      },
      labelLine: {
        show: false,
        length: 5,
        length2: 5,
      },
      emphasis: {
        disabled: disableEmphasis,
        scaleSize: disableEmphasis ? undefined : 5,
        focus,
        itemStyle: {
          color: 'inherit', // no highlight
        },
      },
      tooltip: {
        show: showTooltip,
      },
      datasetIndex,
      encode: {
        itemName: 0,
        value: 2,
      },
    };
  }

  getPieCenter() {
    const centerX =
      this.chartPaddingLeft + this.pieRadius + this.pieChartPaddingLeft;
    const centerY =
      this.chartPaddingTop +
      this.getBarchartHeight() / 2 +
      (this.hasTitle && this.titleHeight);
    return [centerX, centerY];
  }

  getPieSeries() {
    throw new Error("need implement 'getPieSeries' method");
  }

  getBarSeries() {
    throw new Error("need implement 'getBarSeries' method");
  }

  getLabelMinFontSize() {
    return 7;
  }

  getLabelFontSize() {
    return [this.getLabelMinFontSize(), MAX_LABEL_SIZE];
  }

  setWidth(width) {
    this.width = width;
    return this;
  }
}

import { Box } from '@mui/material';

export default function IconBox(props) {
  const { pathFill='', circleFill='',svgSize={},svgBackgroundColor='', show = true,isOverturn=false } = props;
  return show ? (
    <Box
      sx={{
        display: 'flex',
        transform: isOverturn?'rotate(180deg)':'none',
        'svg path': {
          fill: pathFill,
        },
        'svg circle': {
          fill: circleFill,
        },
        'svg':{
          height:svgSize?.height,
          width:svgSize?.width,
          backgroundColor:svgBackgroundColor
        }
      }}
    >
      {props.children}
    </Box>
  ) : null;
}

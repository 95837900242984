import { Box, Stack } from '@mui/material';
import { cssSection } from '../../Css/Framework';
import React from 'react';
import { IDContext } from '../../../Context';

export default function Section(props) {
  const {id,sx={},isCNmarketing=false} = props;
  const prefix = React.useContext(IDContext);
  return (
    <Box
      id={`${prefix}_framework_${id}`}
      data-selenium-id={`${prefix}_framework_${id}`}
      sx={{...cssSection,...sx,...(isCNmarketing&&{maxHeight:0,paddingTop:0,paddingBottom:0,marginTop:'0 !important'})}}
    >
      <Stack spacing={2} direction='column'>
        {props.children}
      </Stack>
    </Box>
  );
}

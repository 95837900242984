import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Alert, Snackbar } from '@mui/material';
import {
  waitingApprovalMessageChange,
  waitingRejectMessageChange,
} from '@/actions/SIMT-Report/ReportJobsSubmission/ReportJobsAction';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { UntitledAlertBox } from '@/components/Alert/AlertBox';
export function ApprovalConfirm(props) {
  const { ApprovalOpen } = useSelector(state => {
    return {
      ApprovalOpen: state.ReportJobs.waitingForApprovalOpen,
    };
  });
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(waitingApprovalMessageChange(false));
  };

  return (
    // <Snackbar
    //   id='systemAdminReportJobsWaitingForApprovalDialogApproveSucessSnackBar'
    //   open={ApprovalOpen}
    //   anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    //   autoHideDuration={3000}
    //   onClose={handleClose}
    // >
    //   <Alert
    //     iconMapping={{
    //       success: <CheckCircleOutlineIcon fontSize='inherit' />,
    //     }}
    //     severity='success'
    //     sx={{ width: '568px' }}
    //   >
    //     <FormattedMessage id='reportJobs.waitingApproved' />
    //   </Alert>
    // </Snackbar>
    <UntitledAlertBox
      id='systemAdminReportJobsWaitingForApprovalDialogApproveSuccessAlert'
      open={ApprovalOpen}
      severity='success'
      message={<FormattedMessage id='reportJobs.waitingApproved' />}
      autoHideDuration={3000}
      handleClose={handleClose}
    />
  );
}

export function RejectConfirm(props) {
  const { RejectOpen } = useSelector(state => {
    return {
      RejectOpen: state.ReportJobs.waitingForRejectOpen,
    };
  });
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(waitingRejectMessageChange(false));
  };

  return (
    // <Snackbar
    //   id='systemAdminReportJobsWaitingForApprovalDialogRejectSucessSnackBar'
    //   data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForSnackBar-DialogRejectSucessSnackBar'
    //   open={RejectOpen}
    //   anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    //   autoHideDuration={3000}
    //   onClose={handleClose}
    // >
    //   <Alert
    //     iconMapping={{
    //       success: <CheckCircleOutlineIcon fontSize='inherit' />,
    //     }}
    //     data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForSnackBar-DialogRejectSucessSnackBar-Alert'
    //     severity='success'
    //     sx={{ width: '568px' }}
    //   >
    //     <FormattedMessage
    //       id='reportJobs.waitingRejected'
    //       data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForSnackBar-DialogRejectSucessSnackBar-Alert-FormattedMessage-WaitingRejected'
    //     />
    //   </Alert>
    // </Snackbar>
    <UntitledAlertBox
      id='systemAdminReportJobsWaitingForApprovalDialogRejectSucessSnackBar'
      open={RejectOpen}
      severity='success'
      message={<FormattedMessage id='reportJobs.waitingRejected' />}
      autoHideDuration={3000}
      handleClose={handleClose}
    />
  );
}

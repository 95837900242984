import GETable from './GETable';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  useMediaQuery,
  styled,
  Tooltip,
  tooltipClasses,
  IconButton,
} from '@mui/material';
import SIOverviewExportIconBlue from '../../../assets/SIOverviewExportIconBlue.svg';
import SIOverviewExportIconWhite from '../../../assets/SIOverviewExportIconWhite.svg';
import AddIconWhite from '../../../assets/AddIconWhite.svg';
import CreateGEPage from './CreateGE';
import { ResponsiveButton } from '../../../components/StyledButton/SIMTButton';
import OnlyTextXIcon from '@/assets/OnlyTextX.svg';
import { setSnackbarMessageAndOpen } from '../../../actions/SnackbarAction';
import {
  clearGEUseFilter,
  handleGEPageChangeAction,
  setGEFilterOpen,
  setGESearchContent,
  unselectBatchGE,
} from '../../../actions/SIMT-SI/GuestEditor/GEAction';
import GEFilterDrawer from './NewFilterDrawer';
import { NewDesignedMain } from '../../../components/Main';
import { FormattedMessage, useIntl } from 'react-intl';
import { unstable_batchedUpdates } from 'react-dom';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@/assets/SearchIcon.svg';
import { DelaySearchTextField } from '../../../components/MyTextField';
import { useEffect, useState } from 'react';
import SIOverviewFilterIcon1 from '../../../assets/SIOverviewFilterIcon1.svg';
import { AlertBox } from '@/components/Alert/AlertBox';
import Number1 from '../../../assets/number1.svg';
import Number2 from '../../../assets/number2.svg';
import Number3 from '../../../assets/number3.svg';
import Number4 from '../../../assets/number4.svg';
import Number5 from '../../../assets/number5.svg';
import Number6 from '../../../assets/number6.svg';
import Number7 from '../../../assets/number7.svg';
import Number8 from '../../../assets/number8.svg';
import Number9 from '../../../assets/number9.svg';
import Number10 from '../../../assets/number10.svg';
import Number11 from '../../../assets/number11.svg';
import { CustomTooltip, CustomThinTooltip } from '@/components/CustomTooltip';
import { ScrollBarX, ScrollBarY } from '../../../componentsphase2/ScrollBar';
import RotatingSVG from '@/componentsphase2/RotatingSVG';
import OverviewButtonIcon from '@/assets/GEMergeAssets/OverviewButtonIcon.svg';

import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import {exportGE} from "@/actions/SIMT-SI/GuestEditor/Export";
import {PERMISSIONS} from "@/constant/permission";

const filterDrawerWidth = 329;

const filterIconMap = {
  1: <Number1 />,
  2: <Number2 />,
  3: <Number3 />,
  4: <Number4 />,
  5: <Number5 />,
  6: <Number6 />,
  7: <Number7 />,
  8: <Number8 />,
  9: <Number9 />,
  10: <Number10 />,
  11: <Number11 />,
};

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    background: '#596A7C',
    fontWeight: 400,
    fontSize: '14px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      width: '6px',
      height: '3px',
      backgroundColor: '#596A7C',
    },
  },
});

export default function GEOverview() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();
  const [isDownloading, setisDownloading] = useState(false);

  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      filterOpen: state.GE.filterOpen,
      selectedGE: state.GE.selectedGE,
      searchGEContent: state.GE.searchGEContent,
      filterSelected: state.GE.filterSelected,
      useFilter: state.GE.useFilter,
    };
  };

  const {
    open,
    filterOpen,
    selectedGE,
    searchGEContent,
    filterSelected,
    useFilter,
  } = useSelector(selector);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [exportMsgOpen, setExportMsgOpen] = React.useState(false);
  const [CreateGEOpen, setCreateGEOpen] = React.useState(false);
  const [exportAllGEOpen, setExportAllGEOpen] = React.useState(false);
  const exportMenuOpen = !exportMsgOpen && Boolean(anchorEl);
  // 媒体查询对界面优化
  // 是否有可能将来把中间容器可用宽度写到redux里，全局复用以修复部分响应式的bug
  const buttonDownBy = open * 266 + filterOpen * filterDrawerWidth + 1070;
  const buttonDown = useMediaQuery(theme.breakpoints.down(buttonDownBy));
  const elementDownBy = open * 266 + filterOpen * filterDrawerWidth + 700;
  const requiresNewLine = useMediaQuery(theme.breakpoints.down(elementDownBy));
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));

  // ---------- Scrollbar -------------------
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  // ---------- export 相关方法 -------------
  const [exportDialogOpen, setExportDialogOpen] = React.useState(false);

  const handleExportMenuClick = event => {
    // setisDownloading(true);
    if (selectedGE.size === 0) {
      setExportMsgOpen(true);
      return;
    } else {
      setExportDialogOpen(true);
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setExportMsgOpen(false);
    setAnchorEl(null);
  };

  const handleExportMenuClose = () => {
    setAnchorEl(null);
  };

  const handleExportAllPageClose = () => {
    setExportAllGEOpen(false);
  };

  const handleExportExcel = () => {
    let length = Array.from(selectedGE).length;
    if (length <= 1000) {
      setisDownloading(true);
      async function fetchData() {
        await dispatch(exportGE(selectedGE, 'xlsx'));
        await delay(500);
        setisDownloading(false);
      }
      fetchData();
      setAnchorEl(null);
    } else {
      dispatch(
        setSnackbarMessageAndOpen(
          'common.largeSizeFail',
          {},
          'warning',
          null,
          null,
          {
            title: 'You can only export a maximum of 1000 items at once.',
          },
          localStorage.getItem('userPermissions').includes('admin')
            ? 'Please visit the ‘Export Data’ page to export all data.'
            : 'You can contact system admin if you want to export all data.'
        )
      );
    }
  };

  const handleExportCsv = () => {
    let length = Array.from(selectedGE).length;
    if (length <= 1000) {
      setisDownloading(true);
      async function fetchData() {
        dispatch(exportGE(selectedGE, 'csv'));
        await delay(500);
        setisDownloading(false);
      }

      fetchData();
      setAnchorEl(null);
    } else {
      dispatch(
        setSnackbarMessageAndOpen(
          'common.largeSizeFail',
          {},
          'warning',
          null,
          null,
          {
            title: 'You can only export a maximum of 1000 items at once.',
          },
          localStorage.getItem('userPermissions').includes('admin')
            ? 'Please visit the ‘Export Data’ page to export all data.'
            : 'You can contact system admin if you want to export all data.'
        )
      );
    }
  };
  // ---------- export 相关方法 -------------

  // ---------- search & filter 相关方法 -------------
  const handleSetSearchGE = content => {
    if (content === searchGEContent) {
      return;
    }
    unstable_batchedUpdates(() => {
      dispatch(setGESearchContent(content));
      dispatch(clearGEUseFilter());
      dispatch(handleGEPageChangeAction(1)); // 修改了filter之后需要把页码置1，否则请求的内容会有问题
      dispatch(unselectBatchGE(selectedGE));
    });
  };

  const toggleFilterOpen = () => {
    dispatch(setGEFilterOpen(!filterOpen));
  };
  // ---------- search & filter 相关方法 -------------

  // ---------- create 相关方法 -------------
  const handleCreateGEPageOpen = () => {
    setCreateGEOpen(true);
  };
  const handleCreateGEPageClose = () => {
    setCreateGEOpen(false);
  };
  // ---------- create 相关方法 -------------

  //-----------merge 相关方法 -------------
  const handleMergeGE = () => {
    // if select less then one
    if (Array.from(selectedGE).length < 2) {
      dispatch(
        setSnackbarMessageAndOpen(
          'common.tooLessGE',
          {},
          'warning',
          null,
          null,
          {}
        )
      );
      return;
    }

    // more than two
    if (Array.from(selectedGE).length > 2) {
      dispatch(
        setSnackbarMessageAndOpen(
          'common.tooManyGE',
          {},
          'warning',
          null,
          null,
          {},
          'Please unselect the excess profiles.'
        )
      );
      return;
    }

    const code1 = Array.from(selectedGE)[0];
    const code2 = Array.from(selectedGE)[1];
    dispatch(unselectBatchGE(selectedGE));
    window.open('/simt/auth/siRelated/ge/merge?ge1=' + code1 + '&ge2=' + code2);
  };

  //-----------merge 相关方法 -------------

  const [filterNum, setFilterNum] = React.useState(0);
  React.useEffect(() => {
    setFilterNum(0);
    for (const key in useFilter) {
      if (useFilter[key].length !== 0) setFilterNum(val => val + 1);
    }
  }, [useFilter]);

  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  const isEmpty = value => {
    let flag =
      value === undefined ||
      value === null ||
      value === '' ||
      value === [] ||
      value === {} ||
      (typeof value === 'string' && !value.trim().length);
    return flag;
  };

  return (
    <Grid container direction='row' justifyContent='space-between'>
      <NewDesignedMain
        open={open}
        sx={{
          p: 2,
          flexGrow: 1,
          padding: '24px 20px 16px 20px',
          // marginRight: '-26px',
        }}
        filterDrawerWidth={filterDrawerWidth}
        filterOpen={filterOpen}
        data-selenium-id={'GEPage_Overview-GEOverview'}
      >
        <Box sx={{ height: '28px' }} className='PageTitleNew'>
          <FormattedMessage
            id='gePage.pageTitle'
            data-selenium-id={'GEPage_Overview-GEOverview-PageTitle'}
          />
        </Box>
        <Box data-selenium-id={'GEPage_Overview-GEOverview-Box1'}>
          {/*所有顶部的按钮都在这里*/}
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            data-selenium-id={'GEPage_Overview-GEOverview-Box1-Grid1'}
          >
            <Grid
              item
              xs={12}
              sm
              {...(buttonDown && {
                sm: 8,
              })}
              {...(requiresNewLine && {
                sm: 12,
              })}
              alignItems='center'
              sx={{ pb: '16px' }}
              data-selenium-id={'GEPage_Overview-GEOverview-Box1-Grid1-Grid1'}
            >
              {/*搜索框、filter按钮*/}
              <Stack
                direction='row'
                width='100%'
                justifyContent={
                  requiresNewLine || isXS ? 'space-between' : 'flex-start'
                }
                alignItems={'center'}
                spacing='10px'
              >
                {/*搜索框*/}
                <DelaySearchTextField
                  placeholder={intl.messages['gePage.searchPlaceholder']}
                  size='small'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={searchGEContent}
                  defaultValue={searchGEContent}
                  delay={300}
                  handleSearch={handleSetSearchGE}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  onFocus={() => {
                    setFocus(true);
                  }}
                  onBlur={() => {
                    setFocus(false);
                  }}
                  sx={{
                    maxWidth: 600,
                    background: '#fff',
                    borderColor: '#DFE4E8',
                    width: '456px',
                    '& .MuiOutlinedInput-root': {
                      height: '40px',
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: !isEmpty(searchGEContent) ? (
                      <InputAdornment
                        position='end'
                        onClick={() => {
                          handleSetSearchGE('');
                          setFocus(false);
                        }}
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        <IconButton
                          disableRipple
                          sx={{
                            padding: '0px',
                            '&:hover': {
                              'svg path': {
                                fill: '#262E35',
                              },
                            },
                          }}
                        >
                          <OnlyTextXIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <></>
                    ),
                    style: {
                      fontSize: '14px',
                    },
                  }}
                  fullWidth
                  data-selenium-id={
                    'GEPage_Overview-GEOverview-Box1-Grid1-DelaySearchTextField'
                  }
                  // needEndAdornment={true}
                />
                {/*Filter按钮*/}
                {/* <ResponsiveButton
                id='btnToggleGEFilterOpen'
                data-selenium-id={`GEPage_Overview-GEOverview-Box1-Grid1-ResponsiveButton`}
                color='indigo'
                downBy={buttonDownBy}
                variant='outlined'
                onClick={toggleFilterOpen}
                startIcon={<FilterListIcon />}
                sx={{
                  border: '1px solid #DFE4E8',
                  height: '32px',
                  minWidth: '48px',
                  '& .MuiButton-startIcon': {
                    marginLeft: 0,
                  },
                  marginRight: requiresNewLine ? 0 : '10px',
                  padding: '6px',
                }}
              >
                <FormattedMessage id='common.filter' />
              </ResponsiveButton> */}
                <Box
                  sx={{
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {filterNum === 0 ? (
                    <ResponsiveButton
                      id='btnToggleGEFilterOpen'
                      data-selenium-id={
                        'GEPage_Overview-GEOverview-Box1-Grid1-ResponsiveButton'
                      }
                      color='white'
                      variant='contained'
                      downBy={buttonDownBy}
                      onClick={toggleFilterOpen}
                      startIcon={<SIOverviewFilterIcon1 />}
                      sx={{
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '8px 12px !important',
                        gap: '6px',
                        // width: '74px',
                        minWidth: '48px',
                        height: '34px',
                        border: '1px solid #0052CC',
                        borderRadius: '5px',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '12px',
                        lineHeight: '18px',
                        textAlign: 'center',
                        color: '#0052CC',
                        flex: 'none',
                        order: 1,
                        flexGrow: 0,
                        '& .MuiButton-startIcon': {
                          margin: 0,
                        },
                        '&:hover': {
                          border: '1px solid #6D98EE',
                          color: '#6D98EE',
                          'svg path': {
                            fill: '#6D98EE',
                          },
                        },
                        '&:active': {
                          background: '#E8EDFB',
                          border: '1px solid #6D98EE',
                          color: '#6D98EE',
                          'svg path': {
                            fill: '#6D98EE',
                          },
                        },
                      }}
                    >
                      <FormattedMessage id='common.filter' />
                    </ResponsiveButton>
                  ) : (
                    <ResponsiveButton
                      id='btnToggleGEFilterOpen'
                      data-selenium-id={
                        'GEPage_Overview-GEOverview-Box1-Grid1-ResponsiveButton'
                      }
                      color='white'
                      variant='contained'
                      downBy={buttonDownBy}
                      onClick={toggleFilterOpen}
                      endIcon={filterIconMap[filterNum]}
                      sx={{
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '8px 12px !important',
                        gap: '6px',
                        width: '74px',
                        height: '34px',
                        borderRadius: '5px',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '12px',
                        lineHeight: '18px',
                        textAlign: 'center',
                        color: '#FFFFFF',
                        background: '#0052CC',
                        flex: 'none',
                        order: 1,
                        flexGrow: 0,
                        '& .MuiButton-endIcon': {
                          margin: 0,
                        },
                        '&:hover': {
                          background: '#6D98EE',
                          'svg path': {
                            fill: '#6D98EE',
                          },
                        },
                        '&:active': {
                          background: '#113D95',
                          'svg path': {
                            fill: '#6D98EE',
                          },
                        },
                      }}
                    >
                      <FormattedMessage id='common.filter' />
                    </ResponsiveButton>
                  )}
                </Box>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              {...(buttonDown && {
                sm: 4,
              })}
              {...(requiresNewLine && {
                sm: 12,
              })}
              sx={{
                pb: '16px',
                paddingRight: '12px',
              }}
              data-selenium-id={'GEPage_Overview-GEOverview-Box1-Grid2'}
            >
              {/*右半部分*/}
              <Stack direction='row' spacing='12px' justifyContent='flex-end'>
                {/*导出ALL GE按钮*/}
                {/* <ResponsiveButton
                id='export-all-button'
                data-selenium-id={`GEPage_Overview-GEOverview-Box1-Grid2-ExportAllButton`}
                color='white'
                downBy={buttonDownBy}
                variant='contained'
                aria-haspopup='true'
                onClick={() => {
                  setExportAllGEOpen(true);
                }}
                startIcon={<ExportAllIcon />}
                sx={{
                  minWidth: 'auto',
                  border: '1px solid #DFE4E8',
                  height: '32px',
                  whiteSpace: 'nowrap',
                }}
              >
                <FormattedMessage id='common.exportOGAll' />
              </ResponsiveButton> */}
                {/* <StyledTooltip
                  title='Export GE in a period of time'
                  placement='top'
                  arrow
                  PopperProps={{
                    modifiers: [
                      { name: 'offset', options: { offset: [0, -10] } },
                    ],
                  }}
                >
                  <Box
                    sx={{height: '40px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    data-selenium-id='GEPage_Overview-GEOverview-holdRefBox'>
                    <ResponsiveButton
                      id='exportall-button'
                      data-selenium-id='GEPage_Overview-GEOverview-ExportAllButton'
                      downBy={buttonDownBy}
                      color='white'
                      variant='contained'
                      aria-haspopup='true'
                      onClick={() => {
                        setExportAllGEOpen(true);
                      }}
                      startIcon={<SIOverviewExportAll sx={{ fontSize: '1px' }} />}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '8px 12px !important',
                        gap: '6px',
                        minWidth: '48px',
                        background: '#0052CC',
                        height: '34px',
                        borderRadius: '5px',
                        whiteSpace: 'nowrap',
                        color: '#FFFFFF',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '12px',
                        lineHeight: '18px',
                        '& .MuiButton-startIcon': {
                          margin: 0,
                        },
                        '&:hover': {
                          background: '#6D98EE',
                        },
                        '&:active': {
                          background: '#113D95',
                        },
                      }}
                    >
                      <FormattedMessage id='common.exportOGAll' />
                    </ResponsiveButton>
                  </Box>
                </StyledTooltip> */}

                {/*导出按钮*/}
                {/* <ResponsiveButton
                id='export-button'
                data-selenium-id={`GEPage_Overview-GEOverview-Box1-Grid2-ExportButton`}
                color='white'
                variant='contained'
                downBy={buttonDownBy}
                aria-controls={exportMenuOpen ? 'export-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={exportMenuOpen ? 'true' : undefined}
                onClick={handleExportMenuClick}
                startIcon={<SIOverviewExportIcon />}
                endIcon={<DropdownIcon />}
                sx={{
                  border: '1px solid #DFE4E8',
                  height: '32px',
                }}
              >
                <FormattedMessage id='common.Export' />
              </ResponsiveButton> */}
                {/* GEMerge Button */}
                {/* TODO Change to Merge Permission */}
                <ShowForPermission permission={`${PERMISSIONS.GE_MERGE}`}>
                  <Box
                    sx={{
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ResponsiveButton
                      id='btnMergeGE'
                      data-selenium-id='GEPage_Overview-GEOverview-MergeButton'
                      downBy={buttonDownBy}
                      color='wileyBlue2'
                      variant='contained'
                      // className="si-button si-button-right"
                      onClick={handleMergeGE}
                      startIcon={<OverviewButtonIcon />}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '8px 12px !important',
                        gap: '6px',
                        // width: '84px',
                        minWidth: '48px',
                        height: '34px',
                        background: '#0052CC',
                        borderRadius: '5px',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '12px',
                        lineHeight: '18px',
                        textAlign: 'center',
                        color: '#FFFFFF',
                        flex: 'none',
                        order: 1,
                        flexGrow: 0,
                        '& .MuiButton-startIcon': {
                          margin: 0,
                        },
                        '&:hover': {
                          background: '#6D98EE',
                        },
                        '&:active': {
                          background: '#113D95',
                        },
                      }}
                    >
                      <CustomThinTooltip
                        placement='top'
                        title='Please select two profiles to merge'
                        data-selenium-id='GE_Overview-Merge-Tooltip'
                        disableInteractive
                        sx={{
                          '& .MuiTooltip-tooltip': {
                            minHeight: '30px!important',
                          },
                        }}
                      >
                        {/* <FormattedMessage id='common.merge' /> */}
                        <div>{formatMessage({ id: 'common.merge' })} </div>
                      </CustomThinTooltip>
                    </ResponsiveButton>
                  </Box>
                </ShowForPermission>

                <ShowForPermission permission={`${PERMISSIONS.GE_EXPORT}`}>
                  <Box
                    sx={{
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {selectedGE.size === 0 ? (
                      <ResponsiveButton
                        id='export-button'
                        data-selenium-id='GEPage_Overview-GEOverview-ExportButton'
                        downBy={buttonDownBy}
                        color='white'
                        variant='contained'
                        disabled={isDownloading}
                        aria-controls={
                          exportMenuOpen ? 'export-menu' : undefined
                        }
                        aria-haspopup='true'
                        aria-expanded={exportMenuOpen ? 'true' : undefined}
                        onClick={handleExportMenuClick}
                        startIcon={
                          isDownloading ? (
                            <RotatingSVG />
                          ) : (
                            <SIOverviewExportIconBlue />
                          )
                        }
                        sx={{
                          boxSizing: 'border-box',
                          /* Auto layout */
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: '8px 12px !important',
                          gap: '6px',
                          // width: '83px',
                          minWidth: '48px',
                          height: '34px',
                          backgroundColor: isDownloading
                            ? '#AEC6F6 !important'
                            : '#FFFFFF',

                          border: '1px solid #0052CC',
                          borderRadius: '5px',
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: '12px',
                          lineHeight: '18px',
                          /* identical to box height, or 150% */
                          textAlign: 'center',
                          /* Primary/500- */
                          color: '#0052CC',
                          /* Inside auto layout */
                          flex: 'none',
                          order: 1,
                          flexGrow: 0,
                          '& .MuiButton-startIcon': {
                            margin: 0,
                          },
                          '&:hover': {
                            border: '1px solid #6D98EE',
                            color: '#6D98EE',
                            'svg path': {
                              fill: '#6D98EE',
                            },
                          },
                          '&:active': {
                            background: '#E8EDF8',
                            border: '1px solid #AEC6F6',
                            color: '#6D98EE',
                            'svg path': {
                              fill: '#6D98EE',
                            },
                          },
                        }}
                      >
                        <CustomThinTooltip
                          placement='top'
                          title='Export Selected Item(s)'
                          data-selenium-id='GE_Overview-ExportButton-Tooltip'
                          disableInteractive
                          //open={true}
                        >
                          <div>{formatMessage({ id: 'common.Export' })}</div>
                        </CustomThinTooltip>
                      </ResponsiveButton>
                    ) : (
                      <ResponsiveButton
                        id='export-button'
                        data-selenium-id='GEPage_Overview-GEOverview-ExportButton'
                        downBy={buttonDownBy}
                        color='white'
                        variant='contained'
                        disabled={isDownloading}
                        aria-controls={
                          exportMenuOpen ? 'export-menu' : undefined
                        }
                        aria-haspopup='true'
                        aria-expanded={exportMenuOpen ? 'true' : undefined}
                        onClick={handleExportMenuClick}
                        startIcon={
                          isDownloading ? (
                            <RotatingSVG />
                          ) : (
                            <SIOverviewExportIconWhite />
                          )
                        }
                        sx={{
                          boxSizing: 'border-box',
                          /* Auto layout */
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: '8px 12px !important',
                          gap: '6px',
                          // width: '83px',
                          minWidth: '48px',
                          height: '34px',
                          // background: '#0052CC',
                          backgroundColor: isDownloading
                            ? '#AEC6F6 !important'
                            : '#0052CC',

                          borderRadius: '5px',
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: '12px',
                          lineHeight: '18px',
                          /* identical to box height, or 150% */
                          textAlign: 'center',
                          /* Primary/500- */
                          color: '#FFFFFF',
                          /* Inside auto layout */
                          flex: 'none',
                          order: 1,
                          flexGrow: 0,
                          '& .MuiButton-startIcon': {
                            margin: 0,
                          },
                          '&:hover': {
                            background: '#6D98EE',
                          },
                          '&:active': {
                            background: '#113D95',
                          },
                        }}
                      >
                        <CustomThinTooltip
                          placement='top'
                          title='Export Selected Item(s)'
                          data-selenium-id='GE_Overview-ExportButton-Tooltip'
                          disableInteractive
                          //open={true}
                        >
                          <div>{formatMessage({ id: 'common.Export' })}</div>
                        </CustomThinTooltip>
                      </ResponsiveButton>
                    )}
                    <Menu
                      id='export-menu'
                      data-selenium-id='CATJournal_Overview-ExportButton-Menu'
                      anchorEl={anchorEl}
                      open={exportMenuOpen}
                      MenuListProps={{
                        'aria-labelledby': 'export-button',
                      }}
                      onClose={handleExportMenuClose}
                      sx={{
                        '& .MuiPopover-paper': {
                          marginTop: '6px',
                          gap: '6px',
                          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
                          padding: '2px 0px 2px 0px',
                          width: '98px',
                        },
                      }}
                    >
                      <MenuItem
                        disableRipple
                        id='exportCSV'
                        data-selenium-id='SIP_Overview-ExportButton-ExportCSVMenuItem1'
                        onClick={handleExportCsv}
                        sx={{
                          fontSize: '12px',
                          lineHeight: '20px',
                          color: '#596A7C',
                          paddingTop: '2px',
                          paddingBottom: '2px',
                          paddingLeft: '12px',
                          '&:hover': {
                            backgroundColor: '#F1F3F5',
                            color: '#262E35',
                          },
                          '&:active': {
                            backgroundColor: '#FFFFFF',
                            color: '#262E35',
                            fontWeight: 600,
                          },
                        }}
                      >
                        Export CSV
                        {/* {intl.messages['common.exportToCSV']} */}
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        id='exportExcel'
                        data-selenium-id='SIP_Overview-ExportButton-ExportExcelMenuItem1'
                        onClick={handleExportExcel}
                        sx={{
                          fontSize: '12px',
                          lineHeight: '20px',
                          color: '#596A7C',
                          paddingTop: '2px',
                          paddingBottom: '2px',
                          paddingLeft: '12px',
                          '&:hover': {
                            backgroundColor: '#F1F3F5',
                            color: '#262E35',
                          },
                          '&:active': {
                            backgroundColor: '#FFFFFF',
                            color: '#262E35',
                            fontWeight: 600,
                          },
                        }}
                      >
                        Export EXCEL
                        {/* {intl.messages['common.exportToEXCEL']} */}
                      </MenuItem>
                    </Menu>
                  </Box>
                </ShowForPermission>

                {/*创建按钮*/}
                {/* <ResponsiveButton
                id='btnCreateGE'
                data-selenium-id={`GEPage_Overview-GEOverview-Box1-Grid2-CreateButton`}
                downBy={buttonDownBy}
                color='wileyBlue2'
                variant='contained'
                startIcon={<AddIcon />}
                onClick={handleCreateGEPageOpen}
                sx={{
                  minWidth: 'auto',
                  height: '32px',
                  padding: '6px',
                }}
              >
                <FormattedMessage id='common.create' />
              </ResponsiveButton> */}

                <ShowForPermission permission={`${PERMISSIONS.GE_WRITE}`}>
                  <Box
                    sx={{
                      height: '40px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ResponsiveButton
                      id='btnCreateGE'
                      data-selenium-id='GEPage_Overview-GEOverview-CreateButton'
                      downBy={buttonDownBy}
                      color='wileyBlue2'
                      variant='contained'
                      // className="si-button si-button-right"
                      onClick={handleCreateGEPageOpen}
                      startIcon={<AddIconWhite />}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '8px 12px !important',
                        gap: '6px',
                        // width: '84px',
                        minWidth: '48px',
                        height: '34px',
                        background: '#0052CC',
                        borderRadius: '5px',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '12px',
                        lineHeight: '18px',
                        textAlign: 'center',
                        color: '#FFFFFF',
                        flex: 'none',
                        order: 1,
                        flexGrow: 0,
                        '& .MuiButton-startIcon': {
                          margin: 0,
                        },
                        '&:hover': {
                          background: '#6D98EE',
                        },
                        '&:active': {
                          background: '#113D95',
                        },
                      }}
                    >
                      <FormattedMessage id='common.create' />
                    </ResponsiveButton>
                  </Box>
                </ShowForPermission>
              </Stack>
            </Grid>
          </Grid>

          {/*Export的menu*/}
          {/* <Menu
          id='export-menu'
          data-selenium-id={`GEPage_Overview-GEOverview-Box1-Grid2-ExportMenu`}
          anchorEl={anchorEl}
          open={exportMenuOpen}
          MenuListProps={{
            'aria-labelledby': 'export-button',
          }}
          onClose={handleExportMenuClose}
          sx={{
            '& .MuiPopover-paper': {
              marginTop: '6px',
              boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
              padding: '2px 0px 2px 0px',
              width: '137px',
            },
          }}
        >
          <MenuItem
            id='exportCSV'
            data-selenium-id={`GEPage_Overview-GEOverview-Box1-Grid2-ExportMenu-ExportCSV`}
            onClick={handleExportCsv}
            sx={{
              fontSize: '12px',
              lineHeight: '20px',
              color: '#000000',
              paddingTop: '2px',
              paddingBottom: '2px',
              paddingLeft: '31px',
            }}
          >
            {intl.messages['common.exportToCSV']}
          </MenuItem>
          <MenuItem
            id='exportExcel'
            data-selenium-id={`GEPage_Overview-GEOverview-Box1-Grid2-ExportMenu-ExportExcel`}
            onClick={handleExportExcel}
            sx={{
              fontSize: '12px',
              lineHeight: '20px',
              color: '#000000',
              paddingTop: '2px',
              paddingBottom: '2px',
              paddingLeft: '24px',
            }}
          >
            {intl.messages['common.exportToEXCEL']}
          </MenuItem>
        </Menu> */}
          {/*Export的消息提示*/}
          {/* <Popover
          open={exportMsgOpen}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          data-selenium-id={`GEPage_Overview-GEOverview-Box1-Grid2-NoSelectionExport`}
        >
          <Typography sx={{ p: 2 }}>
            <FormattedMessage id='gePage.noSelectionExport' />
          </Typography>
        </Popover> */}
          <CreateGEPage
            open={CreateGEOpen}
            handleCreateGEPageClose={handleCreateGEPageClose}
            data-selenium-id={
              'GEPage_Overview-GEOverview-Box1-Grid2-CreateGEPage'
            }
          />
          {/* <ExportAllGEPage
            open={exportAllGEOpen}
            handleClose={handleExportAllPageClose}
            data-selenium-id={'GEPage_Overview-GEOverview-Box1-Grid2-ExportAllGEPage'}
          />
          <ExportGEPage
            open={exportDialogOpen}
            handleExport={t => {
              if (t === 'csv') {
                handleExportCsv();
              } else if (t === 'xls') {
                handleExportExcel();
              }
            }}
            handleClose={() => {
              setExportDialogOpen(false);
            }}
          /> */}
          {/*表格本体*/}
          <GETable />
        </Box>
        <GEFilterDrawer
          drawerWidth={filterDrawerWidth}
          handleClose={toggleFilterOpen}
          data-selenium-id={
            'GEPage_Overview-GEOverview-Box1-Grid2-FilterDrawer'
          }
        />
        <AlertBox
          style={{ top: '76px', right: '36px' }}
          open={exportMsgOpen}
          handleClose={() => {
            setExportMsgOpen(false);
            setAnchorEl(null);
          }}
          severity='warning'
          autoHideDuration={4000}
          title='Warning'
          message='Please select at least one Guest Editor to export.'
        />
      </NewDesignedMain>
      {/*<ScrollBarY contextHolderRef={contextHolderRef} />*/}
    </Grid>
  );
}

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

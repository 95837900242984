import { MyFormHelperText } from '@/components/CreateAndUpdate/MyFormHelperText';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styleModule from './CustomComponent.module.scss';
import VectorUpDown from '@/assets/dropdownIcon3.svg';
import Soild from '@/assets/Soild.svg';
/**
 * @param {import("./Select").BootstrapSelectProps} props
 * @returns
 */
export const BootstrapSelect = props => {
  const {
    options,
    value = '',
    showErrorMessage,
    required,
    placeholder,
    sx,
    index = 0,
    setPaddingBottom,
    setError = () => { },
    ...others
  } = props;

  const distance = [0, -65, 10][index ?? 0];
  const [requiredError, setRequiredError] = useState(false);

  useEffect(() => {
    setRequiredError(value === '');
  }, [value]);

  useEffect(() => {
    setError(requiredError);
  }, [requiredError]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setPaddingBottom(24 + options.length * 24 + (options.length - 1) * 6 + distance + 6);
    setTimeout(() => {
      if (Math.abs(document.documentElement.scrollHeight - document.documentElement.clientHeight - document.documentElement.scrollTop) < 71.2
        && index == 1 || Math.abs(document.documentElement.scrollHeight - document.documentElement.clientHeight - document.documentElement.scrollTop) < 17.8 && index == 2
      ) {
        setOpen(true);
        return;
      }
      document.documentElement.scrollTop = document.documentElement.scrollHeight - document.documentElement.clientHeight - 71.2;
      let isThrottled = false;
      const s = setInterval(() => {
        if (Math.abs(document.documentElement.scrollHeight - document.documentElement.clientHeight - 71.2 - document.documentElement.scrollTop) < 1) {
          setOpen(true);
          clearInterval(s);
        } else {
          if (isThrottled) return;
          document.documentElement.scrollTop = document.documentElement.scrollHeight - document.documentElement.clientHeight - 71.2;
          isThrottled = true;
          setTimeout(() => {
            isThrottled = false;
          }, 1e3);
        }
      }, 100);
    });
  };
  return (
    <Box data-selenium-id='SIPPage_TransformToSl-CustomComponent-Select-Box'>
      <TextField
        data-selenium-id='SIPPage_TransformToSl-CustomComponent-Select-Box-TextField'
        className={styleModule.SelectBoxTextFieldInput}  //modified by HJX
        style={{ '--color': value ? '#98A7B6' : '#DFE4E8', }}
        select
        value={value}
        sx={{
          '& .MuiSelect-select': {
            lineHeight: '125%',
            minHeight: '20px',
            paddingY: '10px',
            fontSize: '14px', //modified by HJX
            '&>p': {
              lineHeight: '125%',
            }
          },
        }}
        SelectProps={{
          displayEmpty: true,
          renderValue: selected => {
            const placeHolderEle = (
              <Typography
                data-selenium-id='SIPPage_TransformToSl-CustomComponent-Select-Box-Typography'
                sx={{
                  color: '#BCC5CF',
                  lineHeight: '20px',
                  fontSize: '16px',
                }}
              >
                {placeholder}
              </Typography>
            );

            if (Array.isArray(selected)) {
              if (selected.length === 0) {
                return placeHolderEle;
              }

              return <>{selected.join(', ')}</>;
            }
            if (selected === '' || selected === undefined) {
              return placeHolderEle;
            }
            return <>{selected}</>;
          },
          MenuProps: {
            PaperProps: {
              style: {
                backgroundColor: '#FFFFFF',
                boxShadow: '0px 6px 12px 0px #262E351F',
                borderRadius: '5px',
                marginTop: '6px',
              },
            },
            MenuListProps: {
              style: {
                paddingTop: '9px',
                paddingBottom: '9px',

              },
            },
          },
          IconComponent: props => (
            <VectorUpDown
              onClick={handleOpen}
              {...props}
            />),  //modified by HJX
          open: open,
          onOpen: handleOpen

          // ,setTimeout(() => {
          // const body = document.querySelector('body[style="background-color: rgb(248, 248, 248); margin: 0px; padding-right: 6px; overflow: hidden;"]');
          // body && (body.style.overflow = "auto");
          // const bodyWithOverflow = document.querySelector('body[style="background-color: rgb(248, 248, 248); margin: 0px; padding-right: 6px; overflow: auto;"]');
          // bodyWithOverflow && (bodyWithOverflow.style.paddingRight = 0);
          // })
          // )
          ,
          onClose: () => {
            setPaddingBottom(60);
            setOpen(false);
          },
        }}
        error={showErrorMessage && requiredError}
        helperText={
          showErrorMessage && requiredError && <><Soild />This field is required.</>
        }
        {...others}
      >
        {options.map((option, index) => (
          <MenuItem
            value={option.value}
            key={index}
            data-selenium-id='SIPPage_TransformToSl-CustomComponent-Select-Box-MenuItem'
            className={styleModule.SelectBoxTextField}  //modified by HJX
          >
            {option.label || option.value}
          </MenuItem>
        ))}
      </TextField>
    </Box >
  );
};

import { axios_instance, axios_instance_fileUpload } from "@/utils/axios_instance";
import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {
    ADD_GEINFOMATION,
    DELETE_CV,
    DELETE_GEINFOMATION,
    DOWNLOAD_CV,
    Edit_GEINFOMATION,
    GET_CONTENT_COMMISSIONING_APPROACHES,
    GET_GE_INFORMATION,
    GET_GEINFOMATION_COUNTRY_DROPLIST,
    GET_GEINFOMATION_JOB_TITLE_DROPLIST,
    GET_OBJECT,
    GET_SIP_INFORMATION,
    LOAD_SIP_STATUS,
    LOAD_SIP_STATUS_COMPLETE,
    GET_SIP_STATUS,
    GET_SIPDETAIL_OBJECT_CHOICE_JOURNAL_DROPLIST,
    SET_UPLOAD_CV_STATUS,
    SIP_VERSION_ERROR,
    UPDATE_CV_UPLOADING_PROGRESS,
    GET_EBM_REVIEW_COMMENTS,
    UPDATE_SIP_SHEVELD,
    UPLOAD_CV
} from "@/actions/SIMT-SIP/SIP/SIPAction";
import { getMessageIdFromResponse } from "@/utils/commonUtils";
import simpleDisplayError from "@/utils/simpleDisplayError";

const ERROR_CODES = {
    SIP_NOT_FOUND: 16006,
    CALL_FOR_PAPER_EDIT_PERMISSION_ERROR: 16029,
    POTENTIAL_AUTHOR_ADD_ERROR: 16015,
    POTENTIAL_AUTHOR_DUPLICATION_ERROR: 16037,
};

export function getSIPStatus(sipCode) {
    const type = 'sipStatus';
    return async dispatch => {
        dispatch({type: LOAD_SIP_STATUS});
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}`;
            const res = await axios_instance.get(url, {
                params: {
                    type: type,

                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            
            dispatch({type: LOAD_SIP_STATUS_COMPLETE});
            const { code, data } = res.data;
            if (res.status === 200) {
                if (code === 200) {
                    dispatch({
                        type: GET_SIP_STATUS, data: {
                            ...data,
                            // creationDate:res.data.data.submissionDate
                        }
                    });
                } else if (code === ERROR_CODES.SIP_NOT_FOUND) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.sipNotFound',
                            {},
                            SEVERITIES.error
                        )
                    );
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.sipStatusGetErrorMessage',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusGetErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.sipStatusGetErrorMessage',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

export function getObject(sipCode) {
    const type = 'objectIncludeName';
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}`; //在SIP Detail和SSIP Detail都改用这个接口
            const res = await axios_instance.get(url, {
                params: {
                    type: type,

                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const { code, data } = res.data;
            if (res.status === 200) {
                if (code === 200) {
                    dispatch({ type: GET_OBJECT, data: data });
                } else if (code === ERROR_CODES.SIP_NOT_FOUND) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.sipNotFound',
                            {},
                            SEVERITIES.error
                        )
                    );
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.objectGetErrorMessage',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusGetErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.objectGetErrorMessage',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

/**
 * @description 在Decision部分，下拉框选择后的判断
 * @param {string} sipCode
 * @param {(e:import('@/componentsphase2/FlaggedGESynchronizationAndVerificationTable').GeDataType)=>void?} resolve
 */
export function getGEInformation(sipCode = '', resolve = null) {
    const type = 'geInformation';
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}`;
            const res = await axios_instance.get(url, {
                params: {
                    type: type,
                    sipCode: sipCode,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.status === 200) {
                const { code, data } = res.data;
                if (code === 200) {
                    if (typeof resolve === 'function') {
                        resolve(data);
                        return;
                    }
                    dispatch({ type: GET_GE_INFORMATION, data: data });
                } else if (code === ERROR_CODES.SIP_NOT_FOUND) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.sipNotFound',
                            {},
                            SEVERITIES.error
                        )
                    );
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.geInfoGetErrorMessage',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusGetErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.geInfoGetErrorMessage',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

export function getSIPInformation(sipCode) {
    const type = 'sipInformation';
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}`;
            const res = await axios_instance.get(url, {
                params: {
                    type: type,

                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const { code, data } = res.data;
            if (res.status === 200) {
                if (code === 200) {
                    dispatch({ type: GET_SIP_INFORMATION, data: data });
                } else if (res.data.code === ERROR_CODES.SIP_NOT_FOUND) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.sipNotFound',
                            {},
                            SEVERITIES.error
                        )
                    );
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.sipInfoGetErrorMessage',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusGetErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.sipInfoGetErrorMessage',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

export function getContentCommissioningApproaches(sipCode) {
    const type = 'commissionApproaches';
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}`;
            const res = await axios_instance.get(url, {
                params: {
                    type: type,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const { code, data } = res.data;
            if (res.status === 200) {
                if (code === 200) {
                    dispatch({
                        type: GET_CONTENT_COMMISSIONING_APPROACHES,
                        data: res.data.data,
                    });
                } else if (code === ERROR_CODES.SIP_NOT_FOUND) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.sipNotFound',
                            {},
                            SEVERITIES.error
                        )
                    );
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.contentCommissioningApproachesGetErrorMessage',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusGetErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.contentCommissioningApproachesGetErrorMessage',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

export function getEbmReviewPanel(sipCode) {
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/ebms`;
            const res = await axios_instance.get(url, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const { code, data } = res.data;
            if (res.status === 200) {
                if (code === 200) {
                    dispatch({
                        type: GET_EBM_REVIEW_COMMENTS,
                        data: res.data.data,
                    });
                }
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusGetErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch(e) {
            console.log(e);
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.ebmReviewCommentsGetErrorMessage',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

/**
 * 下载CV
 * @param {string} fileName 上传文件的名字带时间戳
 * @param {string | undefined} url
 * @param {import("axios").AxiosRequestConfig<FormData>} extraConfig
 * @returns
 */
export function downloadCv(
    fileName,
    url = '/api/v1/sip-service/special-issue-proposals/guest-editor/cv',
    extraConfig = {
        headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded',
            authorization: localStorage.getItem('authorization'),
        },
    },
    isExternalPage = false,
) {
    return async dispatch => {
        var index = fileName.indexOf('_');
        var filename = fileName.substring(index + 1);
        let res = null;
        try {
            if (isExternalPage) {
                res = await axios_instance.post(
                    {},
                    {
                        responseType: 'blob',
                        params: {
                            type: 'download',
                            fileName: fileName,
                        },
                        ...(isExternalPage ? { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } } : extraConfig)
                    });
            } else {
                res = await axios_instance.post(url,
                    {},
                    {
                        responseType: 'blob',
                        params: {
                            type: 'download',
                            fileName: fileName,
                        },
                        ...(isExternalPage ? { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } } : extraConfig)
                    });
            }
            if (res.data.type == 'application/json') {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.downloadCvErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            } else {
                let blob = new Blob(
                    [
                        // new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
                        res.data,
                    ],
                    { type: 'application/octet-stream;charset=utf-8' }
                );
                if ('download' in document.createElement('a')) {
                    // 非IE下载str = str.replace(/^\s*|\s*$/g,"");
                    const elink = document.createElement('a');
                    elink.download = filename;
                    elink.style.display = 'none';
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放 URL对象
                    document.body.removeChild(elink);
                }
                dispatch({ type: DOWNLOAD_CV, data: true });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.downloadCvSuccessMessage',
                        {},
                        SEVERITIES.success
                    )
                );
            }
        } catch (err) {
            dispatch({ type: DOWNLOAD_CV, data: false });
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.downloadCvErrorMessage',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

/**
 * @type {import("../../../pages/SIPPage/RevisePage/Section/ObjectSection").GetSIPDetailObjectChoiceJournalDropListFunction}
 */
export function getSIPDetailObjectChoiceJournalDropList(
    name,
    url = '/api/v1/si-service/journals',
    extraConfig = {
        headers: {
            authorization: localStorage.getItem('authorization'),
        },
    },
    isExternalPage = false,
) {
    return async dispatch => {
        try {
            const res = await axios_instance.get(url, {
                params: isExternalPage ? { name, type: 'journalCode' } : { searchCode: name },
                ...(isExternalPage ? {} : extraConfig),
            });
            if (res.data.code === 200) {
                dispatch({
                    type: GET_SIPDETAIL_OBJECT_CHOICE_JOURNAL_DROPLIST,
                    data: res.data.data,
                });
            } else {
                dispatch({
                    type: GET_SIPDETAIL_OBJECT_CHOICE_JOURNAL_DROPLIST,
                    data: [],
                });
            }
        } catch {
            dispatch({
                type: GET_SIPDETAIL_OBJECT_CHOICE_JOURNAL_DROPLIST,
                data: [],
            });
        }
    };
}

/**
 * @type {import("../../../pages/SIPPage/RevisePage/CustomComponents/CustomButton").UploadCVAction}
 */
export function UploadCV(props) {
    const { cv, cancelToken, url = '/api/v1/sip-service/special-issue-proposals/guest-editor/cv', isExternalPage = false } = props;

    return async dispatch => {
        try {
            const headers = isExternalPage
                ? {}  // Empty headers when isExternalPage is true
                : { authorization: localStorage.getItem('authorization') };  // Include authorization header when isExternalPage is false
            const config = {
                headers: headers,
                onUploadProgress: e => {
                    if (e.lengthComputable) {
                        let percentLoaded = Math.round((e.loaded / e.total) * 100);
                        // 更新进度条长度
                        if (percentLoaded <= 100) {
                            dispatch({
                                type: UPDATE_CV_UPLOADING_PROGRESS,
                                data: percentLoaded,
                            });
                        }
                    }
                },
                params: {
                    type: 'upload',
                },
                cancelToken: cancelToken?.token,
            };

            let formData = new FormData();
            formData.append('file', cv);
            // formData.append('type', 'upload');
            const res = await axios_instance_fileUpload.post(url, formData, config);
            if (res.data.code === 200) {
                dispatch({ type: UPLOAD_CV, data: res.data.data });
                dispatch({
                    type: SET_UPLOAD_CV_STATUS,
                    /** @type {import("../../../pages/SIPPage/RevisePage/CustomComponents/CustomButton").SIPDetailGEInfomationUploadCVIdStatus} */
                    data: 'success',
                });
            } else {
                const mid = getMessageIdFromResponse(res);
                dispatch(setSnackbarMessageAndOpen(mid, {}, SEVERITIES.error));
                dispatch({
                    type: SET_UPLOAD_CV_STATUS,
                    /** @type {import("../../../pages/SIPPage/RevisePage/CustomComponents/CustomButton").SIPDetailGEInfomationUploadCVIdStatus} */
                    data: 'error',
                });
            }
        } catch (err) {
            dispatch({
                type: SET_UPLOAD_CV_STATUS,
                /** @type {import("../../../pages/SIPPage/RevisePage/CustomComponents/CustomButton").SIPDetailGEInfomationUploadCVIdStatus} */
                data: 'error',
            });
        }
    };
}

/**
 * @type {import("../../../pages/SIPPage/RevisePage/CustomComponents/CustomButton").DeleteCVAction}
 */
export function DeleteCV(
    id,
    url = `/api/v1/sip-service/special-issue-proposals/guest-editors/${id}/cv`,
    extraConfig = {
        headers: {
            authorization: localStorage.getItem('authorization'),
        },
    }
) {
    return async dispatch => {
        try {
            const res = await axios_instance.delete(url, {
                ...extraConfig,
            });

            if (res.data.code === 200) {
                dispatch({
                    type: DELETE_CV,
                    /** @type {import("../../../pages/SIPPage/RevisePage/CustomComponents/CustomButton").SIPDetailGEInfomationDeleteCVIdStatus} */
                    data: 'success',
                });
                dispatch({
                    type: SET_UPLOAD_CV_STATUS,
                    /** @type {import("../../../pages/SIPPage/RevisePage/CustomComponents/CustomButton").SIPDetailGEInfomationUploadCVIdStatus} */
                    data: 'idle',
                });
            } else {
                const mid = getMessageIdFromResponse(res);
                dispatch(setSnackbarMessageAndOpen(mid, {}, SEVERITIES.error));
                dispatch({
                    type: DELETE_CV,
                    /** @type {import("../../../pages/SIPPage/RevisePage/CustomComponents/CustomButton").SIPDetailGEInfomationDeleteCVIdStatus} */
                    data: 'error',
                });
            }
        } catch (err) {
        }
    };
}

/** @type {import("../../../pages/SIPPage/RevisePage/CustomComponents/CustomModal").GetGEInformationCountryDroplistFunction} */
export const getGEInformationCountryDroplist = (
    keyword,
    fieldName = 'get-all-ge-country-list',
    url = `/api/v1/si-service/guest-editors/filter-metadata/${fieldName}`,
    extraConfig = {
        headers: {
            authorization: localStorage.getItem('authorization'),
        },
    },
    isExternalPage = false,
) => {
    return async dispatch => {
        try {
            const res = await axios_instance.get(url, {
                params: {
                    searchKey: keyword,
                    ...(isExternalPage ? { type: 'country' } : {})
                },
                ...(isExternalPage ? {} : extraConfig),
            });

            if (res.data.code === 200) {
                dispatch({
                    type: GET_GEINFOMATION_COUNTRY_DROPLIST,
                    data: res.data.data,
                });
            }
        } catch (err) {
        }
    };
};

/** @type {import("../../../pages/SIPPage/RevisePage/CustomComponents/CustomModal").GetGEInformationCountryDroplistFunction} */
export const getExternalGEInformationCountryDroplist = (
    keyword,
    url,
    extraConfig = {
        headers: {
            authorization: localStorage.getItem('authorization'),
        },
    },
    isExternalPage = false,
) => {
    return async dispatch => {
        try {
            const res = await axios_instance.get(url, {
                params: {
                    keyword: keyword,
                    type: 'country',
                },
                ...(isExternalPage ? {} : extraConfig),
            });

            if (res.data.code === 200) {
                dispatch({
                    type: GET_GEINFOMATION_COUNTRY_DROPLIST,
                    data: res.data.data,
                });
            } else {
                dispatch({
                    type: GET_GEINFOMATION_COUNTRY_DROPLIST,
                    data: [],
                });
            }
        } catch (err) {
        }
    };
};

/** @type {import("../../../pages/SIPPage/RevisePage/CustomComponents/CustomModal").GetGEInformationJobTitleDroplistFunction} */
export const getGEInformationJobTitleDroplist = (
    keyword,
    url = '/api/v1/user-service/configs',
    extraConfig = {
        headers: {
            authorization: localStorage.getItem('authorization'),
        },
        params: {
            "type": "GE Title"
        }
    },
    isExternalPage = false,
) => {
    return async dispatch => {
        try {
            const res = await axios_instance.get(url,  {
                params: {
                    type: 'SIP GE Title'
                },
                ...(isExternalPage ? {} : extraConfig),
            });
            if (res.data.code === 200) {
                dispatch({
                    type: GET_GEINFOMATION_JOB_TITLE_DROPLIST,
                    data: (res.data.data || []).map(config => config?.value),
                });
            }
        } catch (err) {
        }
    };
};

/** @type {import("../../../pages/SIPPage/RevisePage/CustomComponents/CustomModal").GetGEInformationJobTitleDroplistFunction} */
export const getExternalGEInformationJobTitleDroplist = (
    keyword,
    url,
    extraConfig = {
        headers: {
            authorization: localStorage.getItem('authorization'),
        },
    },
    isExternalPage = false,
) => {
    return async dispatch => {
        try {
            const res = await axios_instance.get(url, {
                params: {
                    keyword: keyword,
                    type: 'jobTitle',
                },
                ...(isExternalPage ? {} : extraConfig)
            });

            if (res.data.code === 200 && res.data.data !== "invalid type") {
                dispatch({
                    type: GET_GEINFOMATION_JOB_TITLE_DROPLIST,
                    data: res.data.data,
                });
            } else {
                dispatch({
                    type: GET_GEINFOMATION_JOB_TITLE_DROPLIST,
                    data: [],
                });
            }
        } catch (err) {
        }
    };
};

export const AddGEInfomation = (data) => {
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${data.sipCode}/guest-editors`;
            const res = await axios_instance.post(url, data, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            if (res.data.code === 200) {
                dispatch({ type: ADD_GEINFOMATION, data: res.data.message });
                return res.data.sipVersion;
            } else if (res.data.code === 16080) {
                simpleDisplayError(res.data.message);
            } else if (res.data.code === SIP_VERSION_ERROR) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusChanged',
                        {},
                        SEVERITIES.warning
                    )
                );
                getSIPStatus(data.sipCode);
            } else {
                const mid = getMessageIdFromResponse(res);
                dispatch(setSnackbarMessageAndOpen(mid, {}, SEVERITIES.error));
            }
        } catch (err) {
        }
    };
};
export const DeleteGEInfomation = (id, sipCode, sipVersion) => {
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/guest-editors/${id}`;
            const res = await axios_instance.delete(url, {
                params: {
                    sipVersion
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            if (res.data.code === 200) {
                dispatch({ type: DELETE_GEINFOMATION, data: res.data.message });
                return res.data.sipVersion;
            } else if (res.data.code === SIP_VERSION_ERROR) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusChanged',
                        {},
                        SEVERITIES.warning
                    )
                );
                getSIPStatus(sipCode);
            } else {
                const mid = getMessageIdFromResponse(res);
                dispatch(setSnackbarMessageAndOpen(mid, {}, SEVERITIES.error));
                dispatch({ type: DELETE_GEINFOMATION, data: null });
            }
        } catch (err) {
        }
    };
};
export const EditGEInfomation = (data) => {
    return async dispatch => {
        try {
            // const url = '/sip/detail/editGe';
            // /** @type {import('@/utils/axios-response').AxiosResponse<any, number>} */
            // const res = await axios_instance.post(url, data, {
            //     headers: {
            //         authorization: localStorage.getItem('authorization'),
            //     },
            // });
            const url = `/api/v1/sip-service/special-issue-proposals/${data.sipCode}/guest-editors/${data.geId}`;
            /** @type {import('@/utils/axios-response').AxiosResponse<any, number>} */
            const res = await axios_instance.put(url, data, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            if (res.data.code === 200) {
                dispatch({ type: Edit_GEINFOMATION, data: res.data.message });
                return res.data.sipVersion;
            } else if (res.data.code === SIP_VERSION_ERROR) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusChanged',
                        {},
                        SEVERITIES.warning
                    )
                );
                getSIPStatus(data.sipCode);
              
            } else if (res.data.code === 16106 ){
                console.log("checklist");
                dispatch(simpleDisplayError(res.data.message));
            }
            else {
                const mid = getMessageIdFromResponse(res);
                dispatch(setSnackbarMessageAndOpen(mid, {}, SEVERITIES.error));
            }
        } catch (err) {
        }
    };
};
export const updateSIPStageToSheveld = (sipCode, sipVersion, sipStatus) => {
    const action = 'updateStageToShelved';
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}`;
            const res = await axios_instance.put(url, {}, {
                params: {
                    sipVersion: sipVersion,
                    action: action,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
                body: {

                },
            });
            if (res.data.code === 200) {
                dispatch({ type: UPDATE_SIP_SHEVELD, data: 200 });
                let newSipStatus = {
                    ...sipStatus,
                    sipVersion: res.data.sipVersion
                };
                dispatch({
                    type: GET_SIP_STATUS, data: {
                        ...newSipStatus
                    }
                });
            } else if (res.data.code === SIP_VERSION_ERROR) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusChanged',
                        {},
                        SEVERITIES.warning
                    )
                );
                getSIPStatus(sipCode);
            } else {
                // const mid = getMessageIdFromResponse(res);
                // dispatch(setSnackbarMessageAndOpen(mid, {}, SEVERITIES.error));
            }
        } catch (err) {
        }
    };
};

export function downloadCvInCheckGe(fileName, geName, isSIP) {
    return async dispatch => {
        //filename = 时间戳_name.格式
        // let index = fileName.indexOf('_');
        // let newFilename = fileName.substring(index + 1);

        try {
            let res = await axios_instance.post('/api/v1/sip-service/special-issue-proposals/guest-editor/cv',
                {},
                {
                    responseType: 'blob',
                    params: {
                        type: 'downloadInCheckGe',
                        fileName: fileName,
                        geName: geName,
                        isSIP: isSIP,
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        authorization: localStorage.getItem('authorization'),
                    },
                });
            if (res.data.type === 'application/json') {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.downloadCvErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            } else {
                console.log(res);
                let blob = new Blob(
                    [
                        // new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
                        res.data,
                    ],
                    { type: 'application/octet-stream;charset=utf-8' }
                );
                if ('download' in document.createElement('a')) {
                    // 非IE下载str = str.replace(/^\s*|\s*$/g,"");
                    const elink = document.createElement('a');
                    elink.download = res.headers['content-disposition']
                        .split('="')[1]
                        .split('"')[0];
                    elink.style.display = 'none';
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放 URL对象
                    document.body.removeChild(elink);
                }
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.downloadCvSuccessMessage',
                        {},
                        SEVERITIES.success
                    )
                );
            }
        } catch (err) {
            console.log(err);
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.downloadCvErrorMessage',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

export function checkProposerGE(geId, sipCode) {
    return axios_instance.get(`/api/v1/sip-service/special-issue-proposals/${sipCode}`, {
        params: {
            type: 'proposerGe',
            geId: geId,
        },
        headers: {
            authorization: localStorage.getItem('authorization'),
        },
    });
}

export function checkProposerOK(sipCode) {
    return axios_instance.get(`/api/v1/sip-service/special-issue-proposals/${sipCode}`, {
        params: {
            type: 'proposerMatched',
        },
        headers: {
            authorization: localStorage.getItem('authorization'),
        },
    });
}
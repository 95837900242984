import * as actions from '../actions/SIMT-Email/EmailAction';

const initState = {
  currentEmailTemplateTab: 0, //分页用
  emailTemplatesList: {},
  emailCusomizeTemplatesList: [],
  emailSIPTemplatesList: [],
  emailSolicitedSIPTemplatesList: [],
  emailCustomizeSIPTemplatesList: [],
  emailCustomizeSolicitedSIPTemplatesList: [],
  redDots: [],
  editEmailManual: ' ',
  emailShowInfo: {
    data:{},
    copy:{
      filter:()=>{},
    },
  },
  uploadAttachmentResult: '',
  deleteAttachmentResult: '',
  uploadAttachmentMonitor: false,
  sendEmailReturnCode: null,
  editSIPEmailResult: null,
  editSolicitedSIPEmailResult: null,
};

export default function EmailReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.EMAIL_TEMPLATE_TAB_CHANGE: {
      // 因为没有分页需求了，所以删除了处理分页的代码
      return {
        ...state,
        currentEmailTemplateTab: data,
      };
    }
    case actions.GET_EMAIL_RED_DOTS:
      return {
        ...state,
        redDots: data,
      };
    case actions.GET_EMAILTEMPLATES:
      return {
        ...state,
        emailTemplatesList: data,
      };
    case actions.GET_CUSTOMIZE_EMAIL_TEMPLATES:
      return {
        ...state,
        emailCusomizeTemplatesList: data,
      };

    case actions.EDIT_EMAILMANUALDETAIL:
      return {
        ...state,
        editEmailResult: data,
      };

    case actions.GET_SIP_EMAIL_TEMPLATES:
      return {
        ...state,
        emailSIPTemplatesList: data,
      };
    case actions.GET_SIP_CUSTOMIZE_EMAIL_TEMPLATES:
      return {
        ...state,
        emailCustomizeSIPTemplatesList: data,
      };

    case actions.GET_SOLICITED_SIP_EMAIL_TEMPLATES:
      return {
        ...state,
        emailSolicitedSIPTemplatesList: data,
      };
    case actions.GET_SOLICITED_SIP_CUSTOMIZE_EMAIL_TEMPLATES:
      return {
        ...state,
        emailCustomizeSolicitedSIPTemplatesList: data,
      };

    case actions.EDIT_SIP_EMAIL_TEMPLATES:
      return {
        ...state,
        editSIPEmailResult: data,
      };

    case actions.EDIT_SOLICITED_SIP_EMAIL_TEMPLATES:
      return {
        ...state,
        editSolicitedSIPEmailResult: data,
      };

    case actions.SEND_EMAILRETURNCODE:
      return {
        ...state,
        sendEmailReturnCode: data,
      };

    case actions.GET_EMAIL_SHOW_INFO:
      return {
        ...state,
        emailShowInfo: data,
      };
    case actions.UPLOAD_ATTACHMENT:
      return {
        ...state,
        uploadAttachmentResult: data,
        uploadAttachmentMonitor: !state.uploadAttachmentMonitor,
      };
    case actions.DELETE_ATTACHMENT:
      return {
        ...state,
        deleteAttachmentResult: data,
      };
    default:
      return state;
  }
}

import { useContext } from 'react';
import { IDContext } from '../../Context';
import { cssFramework } from '../Css/Framework';
import { Box } from '@mui/material';

export default function Framework(props) {
  const {
    show = false, // control if the filter is displayed (控制 filter 是否显示)
  } = props;
  const id = useContext(IDContext);
  return show ? (
    <Box
      id={`${id}_framework`}
      data-selenium-id={`${id}_framework`}
      sx={cssFramework}
    >
      {props.children}
    </Box>
  ) : null;
}

/*
 * @Author: LiuJingQian 1250366059@qq.com
 * @Date: 2023-07-17 13:01:12
 * @LastEditors: LiuJingQian 1250366059@qq.com
 * @LastEditTime: 2023-07-28 18:02:37
 * @FilePath: \simt_front\src\pages\Report\ReportSpecificComponents\FiscalYearSelector.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import { CustomizedInput } from '../../AnnualTarget/Overview';
import { SelectInput } from '@/componentsphase2/SelectBox/SelectInput';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';

/**
 * 财年选择器，必须传入：
 *    value：已选择的财年，传入null/undefined/""时会将value变更为当前日期所在的财年
 *    onChange 财年变更时回调
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function FiscalYearSelector(props) {
  const { value, handleYearChange, ...other } = props;

  // 财年的初始化任何地方都是一样的，数据也交给组件本身即可
  const [yearsSelectList, setYearSelectList] = useState([]);
  useEffect(() => {
    const currentDate = new Date();
    let year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    let yearLastTwoBit = (year % 100) + 1;
    const temp = [];
    if (month >= 4) {
      temp.push('FY' + (yearLastTwoBit + 2));
      temp.push('FY' + (yearLastTwoBit + 1));
      temp.push('FY' + yearLastTwoBit);
      temp.push('FY' + (yearLastTwoBit - 1));
      temp.push('FY' + (yearLastTwoBit - 2));
    } else {
      temp.push('FY' + (yearLastTwoBit + 1));
      temp.push('FY' + yearLastTwoBit);
      temp.push('FY' + (yearLastTwoBit - 1));
      temp.push('FY' + (yearLastTwoBit - 2));
      temp.push('FY' + (yearLastTwoBit - 3));
    }
    setYearSelectList(temp);
    if (value === undefined || value === null || value === '') {
      if (month >= 4) {
        handleYearChange('FY' + yearLastTwoBit);
      } else {
        handleYearChange('FY' + (yearLastTwoBit - 1));
      }
    }
  }, []);

  return (
    // <Select
    //
    //   className='report-selector'
    //   input={<CustomizedInput />}

    //   {...other}
    // >
    //   {yearsSelectList.map((item, _index) => (
    //     <MenuItem
    //       id={`fiscalYear${item}`}
    //       data-selenium-id={`Report-FiscalYear-${item}`}
    //       key={item}
    //       value={item}
    //     >
    //       {item}
    //     </MenuItem>
    //   ))}
    // </Select>
    // <SelectInput


    //   renderOption={yearsSelectList}

    //   getOptionLabel={item=>item}
    // />
    <SinpleAutocomplete
      id='fiscalYearSelector'
      data-selenium-id='Report-Specific-Components-FiscalYearSelector'
      onChange={e => {
        handleYearChange(e.target.textContent);
      }}
      options={yearsSelectList}
      value={value}
      defaultQuery={true}
      readOnly={true}
      needClearInputValue={true}
    />
  );
}

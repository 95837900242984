export function timeToString(timeObj, type) {
  if (timeObj === null || timeObj === undefined) {
    return '';
  }
  var str = '';
  var year = timeObj.getFullYear();
  var month = timeObj.getMonth() + 1;
  var date = timeObj.getDate();
  var time;
  if (type === 'to') {
    time = '23:59:59';
  } else {
    time = '00:00:00';
  }
  str = year + '-' + month + '-' + date + ' ' + time;
  return str;
}

export const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  let date = year + '-' + month;
  return date;
};

export function getFyYearFromDate(selectMonthDate) {
  let year, month;
  if (selectMonthDate){
    year = selectMonthDate.split('-')[0];
    month = parseInt(selectMonthDate.split('-')[1]);
    if(month >= 5){
      year = parseInt(year)+1;
      year = year.toString();
    }
  } else {
    year = new Date().getFullYear();
  }
  const yearLastTwoBit = `${year}`.slice(-2);
  return `FY${yearLastTwoBit}`;
}

export function afterDays(dateValue, days = 1) {
  const date = dateValue instanceof Date ? dateValue : new Date(dateValue);
  const timestamp = date.getTime() + days * 24 * 60 * 60 * 1000;
  return new Date(timestamp);
}

export function beforeDays(dateValue, days = 1) {
  return afterDays(dateValue, -days);
}

export const formatDate = selectedDate => {
  // secure the empty selectedDate by providing current month as default
  if (selectedDate === null) {
    return moment().utc().format('YYYYMM');
  }
  const selectDateYear = selectedDate.split('-')[0];
  const selectDateMonth = selectedDate.split('-')[1];
  const date = Number(selectDateYear) * 100 + Number(selectDateMonth);
  return date;
};
import * as actions from '../actions/SIMT-User/Config/SystemConfigAction';

// dropdown一共就这四种可选项
export const dropdownType = [
  {
    id: 'groupTitle',
    value: 0,
    type: 'Group/Title',
    lengthLimit: 80,
    api: `/api/v1/user-service/configs`,
  },
  {
    id: 'flagsSevereIssue',
    value: 1,
    type: 'Flags-Severe Issue',
    lengthLimit: 200,
    api: `/api/v1/user-service/configs`,
  },
  {
    id: 'flagsMildIssue',
    value: 2,
    type: 'Flags-Mild Issue',
    lengthLimit: 200,
    api: `/api/v1/user-service/configs`,
  },
  {
    id: 'siPublicationFormat',
    value: 3,
    type: 'SI Publication Format',
    lengthLimit: 80,
    api: `/api/v1/user-service/configs`,
  },
  {
    id: 'paperCommissioningMethod',
    value: 4,
    type: 'Paper Commissioning Method',
    lengthLimit: 80,
    api: '/api/v1/user-service/configs',
  },
];

const initState = {
  systemConfig: null,
  updateConfig: null,

  // dropdown values
  currSelectType: dropdownType[0],
  // 记录原始顺序，
  //    load之后直接设置数据视为初始化，
  //    add之后需要向后添加数据  ---> 已改为刷新列表，但是暂时保留，
  //    edit之后需要修改数据  ---> 已改为刷新列表，但是暂时保留，
  //    resetOrder之后不做操作，
  //    saveOrder时与currData保持一致
  originalData: [],
  currDataList: [],
  addNewInput: '',
  groupList: [],
  userStatusList: [],
  flagList: [],
  SITypeList: [],
  PapaerComfigList: [],
  NoteLinkList: [],
};

export default function SystemConfigReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.GET_SYSTEM_CONFIG:
      return {
        ...state,
        systemConfig: data,
      };
    case actions.UPDATE_SYSTEM_CONFIG:
      return {
        ...state,
        updateConfig: data,
      };
    case actions.SYSTEM_CONFIG_CLEAR:
      return initState;
    case actions.SET_SELECT_DROPDOWN_TYPE:
      return {
        ...state,
        currSelectType: data,
      };
    case actions.SET_ADD_NEW_ITEM_INPUT:
      return {
        ...state,
        addNewInput: data,
      };
    case actions.LOAD_DROPDOWN_VALUES_DATA_WITH_TYPE:
      return {
        ...state,
        currDataList: data,
        originalData: data,
      };
    case actions.SAVE_DROPDOWN_ORDER_SUCCESS:
      return {
        ...state,
        // currDataList: data,
        originalData: state.currDataList,
      };
    case actions.SET_DROPDOWN_DISPLAY:
      return {
        ...state,
        currDataList: data,
      };
    case actions.RESET_DROPDOWN_ORDER:
      return {
        ...state,
        currDataList: state.originalData,
      };
    case actions.ADD_NEW_DROPDOWN_ITEM: {
      if (data) {
        // 添加成功时，将新的数据添加到末尾
        return {
          ...state,
          currDataList: state.currDataList.concat([data]),
          addNewInput: '',
        };
      }
      // data可能为null（添加失败时），此时不做任何修改
      return state;
    }
    case actions.EDIT_DROPDOWN_ITEM: {
      if (data) {
        // 编辑数据成功时，将数据进行修改
        const copied = state.currDataList.concat();
        const itemShouldBeEdited = copied.filter(item => {
          return item.id === data.id;
        })[0];
        itemShouldBeEdited.value = data.value;
        return {
          ...state,
          currDataList: copied,
        };
      }
      return state;
    }
    case actions.DELETE_DROPDOWN_ITEM: {
      if (data) {
        let idx = -1;
        for (const index in state.currDataList) {
          if (state.currDataList[index].id === data.id) {
            idx = index;
            break;
          }
        }
        // assert idx !== -1;
        return {
          ...state,
          currDataList: state.currDataList.filter((_item, index) => {
            return index !== idx;
          }),
        };
      }
      return state;
    }
    case actions.GET_GROUPS:
      return {
        ...state,
        groupList: data,
      };
    case actions.GET_USER_STATUS: {
      return {
        ...state,
        userStatusList: data,
      };
    }
    case actions.GET_FLAGS:
      return {
        ...state,
        flagList: data,
      };
    case actions.GET_SITYPE:
      return {
        ...state,
        SITypeList: data,
      };
    case actions.GET_PAPERCONFIG:
      return {
        ...state,
        PapaerComfigList: data,
      };
    case actions.GET_NOTE_LINK_CONFIG:
      return {
        ...state,
        NoteLinkList: data,
      };
    default:
      return state;
  }
}

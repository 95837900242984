import styles from './index.module.scss';

function TitleLine() {
    return (
      <section className={styles['banner']}>
        <div className={styles['layout']}>
          <h1>Special Issue Proposal Form</h1>
          <p>
          If you are interested in editing a Special Issue in one of our journals, please submit your proposal by filling out the below form. After we receive your Special Issue Proposal, we will get back to you with an evaluation decision in a timely manner. We recommend providing detailed information, as this will enable editors to evaluate your proposal comprehensively and to provide a recommendation promptly. 
          </p>
        </div>
      </section>
    );
}

export default TitleLine;
// @ts-check
import { Box, Collapse, Stack } from '@mui/material';
import React, { useState } from 'react';
import { SectionTitleBlock } from './CustomBlock';

/**
 *
 * @param {import("./CustomContainer").SectionContainerProps} props
 * @returns
 */
export const SectionContainer = props => {
  const {
    title,
    titleBlockProps = {},
    children,
    defaultFold = false,
    ...others
  } = props;
  const [sectionFold, setSectionFold] = useState(defaultFold);
  return (
    <Box
      {...others}
      data-selenium-id='SIP_RevisePage_CustomComponents-Container-Box'
    >
      <SectionTitleBlock
        data-selenium-id='SIP_RevisePage_CustomComponents-Container-SectionTitleBlock'
        sectionTitle={title}
        sectionFold={sectionFold}
        handleToggleSectionFold={() => setSectionFold(current => !current)}
        {...titleBlockProps}
      />
      <Collapse
        in={!sectionFold}
        data-selenium-id='SIP_RevisePage_CustomComponents-Container-Collapses'
      >
        {children}{' '}
      </Collapse>
    </Box>
  );
};

/**
 *
 * @param {import("./CustomContainer").SectionsContainerProps} props
 * @returns
 */
export const SectionsContainer = props => {
  const { children, sx = {}, ...others } = props;
  return (
    <Stack
      data-selenium-id='SIP_RevisePage_CustomComponents-Container-SectionStack'
      sx={{
        backgroundColor: '#fff',
        '& .SectionTitleBlock + .MuiCollapse-root > .MuiCollapse-wrapper': {
          paddingTop: '16px',
        },
        ...sx,
      }}
      paddingX='32px'
      paddingY='32px'
      marginTop='4px'
      spacing='24px'
      {...others}
    >
      {children}
    </Stack>
  );
};

/**
 *
 * @param {import("./CustomContainer").RevisePageBodyContainerProps} props
 */
export const RevisePageBodyContainer = props => {
  const { children, sx = {}, ...others } = props;
  return (
    <Stack
      data-selenium-id='SIP_RevisePage_CustomComponents-Container-BodyStack'
      marginTop={'56px'}
      paddingX='32px'
      paddingTop={'24px'}
      minHeight={'calc(100vh - 56px - 71.2px - 64px)'}
      sx={{ boxSizing: 'border-box', ...sx }}
      {...others}
    >
      {children}
    </Stack>
  );
};

import {SIPFormState} from "@/reducers/SIPFormReducer";
import {useDispatch, useSelector} from "react-redux";
import {
    addSIPFormAuthor, delSIPFormAuthor, delSIPFormGE,
    setSIPFormAuthorListValue,
    setSIPFormGEListValue,
    setSIPFormSectionState
} from "@/actions/SIMT-SIP/SIPForm";
import {validateEmail, validateNotEmpty} from "@/pages/SIPForm/utils/validate_utils";
import {useEffect} from "react";
import {SectionState} from "@/pages/SIPForm/types.d";
import Card from "@/pages/SIPForm/components/Card";
import FormGroup, {FormGroupButton, FormGroupInfo} from "@/pages/SIPForm/components/FormGroup";
import {InputBox} from "@/pages/SIPForm/components/InputBox";
import {Box, Button, Typography} from "@mui/material";
import styles from "@/pages/SIPForm/index.module.scss";
import DeleteIcon from "@/pages/SIPForm/assets/input-list-delete-icon-24_24.svg";
import {useIntl, FormattedMessage} from "react-intl";

export interface PotentialAuthorsSectionProps {

}

export function PotentialAuthorsSection(props: PotentialAuthorsSectionProps) {
    const selector = (state: {
        SIPForm: SIPFormState;
        [key: string]: any;
    }) => state.SIPForm;

    const {
        formData,
        state: formStatus,
    } = useSelector(selector);

    const {
        potentialAuthors,
    } = formData;

    const dispatch = useDispatch();
    const intl = useIntl();

    const proceeding = formStatus === 'proceeding';

    const sectionData = potentialAuthors.data;
    const section = 'potentialAuthors';

    const cannotAddAuthor = sectionData.potentialAuthors.length >= 10;

    const hasItemError = sectionData.potentialAuthors.some(author => {
       return validateNotEmpty(author.email) && !validateEmail(author.email);
    });
    const emails = sectionData.potentialAuthors
        .map(author => author.email)
        .filter(email => validateEmail(email));
    const duplicateEmailCheck = emails
        .some((email, index) => emails.indexOf(email) !== index);
    const hasError = hasItemError || duplicateEmailCheck;

    const allFilled = sectionData.potentialAuthors.every(author => {
        return validateNotEmpty(author.affilication) &&
            validateNotEmpty(author.email) &&
            validateNotEmpty(author.name) &&
            validateNotEmpty(author.topic);
    });

    useEffect(() => {
        let status: SectionState = SectionState.default;
        if (hasError) {
            if (proceeding) {
                status = SectionState.error;
            }
        } else if (allFilled) {
            status = SectionState.completed;
        }
        dispatch(setSIPFormSectionState(section, status));
    }, [hasError, allFilled, proceeding]);

    const handleAddAuthor = () => {
        dispatch(addSIPFormAuthor());
    };

    const deleteAuthor = (index: number) => {
        dispatch(delSIPFormAuthor(index));
    };

    return (
        <Card section={potentialAuthors}>
            {sectionData.potentialAuthors.map((author, index) => {
                const setValue = (key: string, value: any) => dispatch(setSIPFormAuthorListValue(
                    section,
                    index,
                    key,
                    value
                ));
                const valueSetter = (key: string) => (value: any) => setValue(key, value);
                const setName = valueSetter('name');
                const setEmail = valueSetter('email');
                const setAffiliation = valueSetter('affilication');
                const setTopic = valueSetter('topic');

                const errorOfEmail = validateNotEmpty(author.email) && !validateEmail(author.email);
                const emailDuplicated = emails.filter(email => email === author.email).length > 1;

                return (<>
                    {sectionData.potentialAuthors.length > 1 &&
                    <Box className={styles['delete-group-box']} key={`delete-author-${index}`}>
                        <Button
                            className={styles['delete-button']}
                            onClick={() => deleteAuthor(index)}>
                            <Typography><FormattedMessage id={'sipForm.label.authorDeleteButton'}/></Typography>
                            <DeleteIcon/>
                        </Button>
                    </Box>
                    }
                    <FormGroup
                        key={`author-name-${index}`}
                        title="Author Name"
                    >
                        <InputBox
                            value={author.name}
                            onChange={ (value) => setName(value) }
                            placeholder={intl.messages['sipForm.placeholder.typeOnly'] as string}
                        />
                    </FormGroup>

                    <FormGroup
                        key={`affiliation-${index}`}
                        title="Affiliation"
                    >
                        <InputBox
                            value={author.affilication}
                            onChange={ (value) => setAffiliation(value) }
                            placeholder={intl.messages['sipForm.placeholder.typeOnly'] as string}
                        />
                    </FormGroup>

                    <FormGroup
                        key={`author-email-${index}`}
                        title="Email Address"
                        error={proceeding && (errorOfEmail || emailDuplicated)}
                        errorMessage={intl.messages[
                            errorOfEmail ? 'sipForm.errorText.emailInvalid' : 'sipForm.errorText.authorEmailDuplicate'
                            ] as string}
                    >
                        <InputBox
                            value={author.email}
                            onChange={ (value) => setEmail(value) }
                            placeholder={intl.messages['sipForm.placeholder.typeOnly'] as string}
                        />
                    </FormGroup>

                    <FormGroup
                        key={`author-topic-${index}`}
                        title="Article Topic"
                    >
                        <InputBox
                            value={author.topic}
                            onChange={ (value) => setTopic(value) }
                            placeholder={intl.messages['sipForm.placeholder.typeOnly'] as string}
                        />
                    </FormGroup>
                </>);
            })}
            <FormGroupInfo info={intl.messages['sipForm.info.authors'] as string} />
            <FormGroupButton
                title={intl.messages['sipForm.label.authorAddButton'] as string}
                disabled={cannotAddAuthor}
                onClick={handleAddAuthor}
            />
        </Card>
    );
}
//@ts-check
import { JournalScopeCheckOrRecommendationsTypes } from '@/actions/SIPAIScreeningReportAction';
import { StrictMode } from 'react';
import JournalScopeCheckOrRecommendationsMainFrame from '../components/JournalScopeCheckOrRecommendationsMainFrame';
/**
 * @param {{ sipCode: string; }} props
 */
export default function JournalScopeCheck(props) {
  const { sipCode } = props;
  return (
    <StrictMode>
      <JournalScopeCheckOrRecommendationsMainFrame
        sipCode={sipCode}
        title='Journal Scope Check'
        type={JournalScopeCheckOrRecommendationsTypes.JournalScopeCheck}
      />
    </StrictMode>
  );
}

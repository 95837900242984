import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import LabelAndValue from '@/components/LabelAndValue';
import { FormattedMessage } from 'react-intl';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import {get_SIHistory} from "@/actions/SIMT-SI/SpecialIssue/SiEditHistory";
import {get_COGEHistory, get_LeadGEHistory} from "@/actions/SIMT-SI/SpecialIssue/GeEditDetail";

export default function JournalDetailsBlock(props) {
  const leftKeys = [
    'subjectGroup',
    'ownershipStatus',
    'revenueModel',
    'primaryUrl',
    'authorGuidelines',
  ];

  const rightKeys = [
    'businessDivision',
    'society',
    'peerReviewModel',
    'eeoSystemLink',
    'submissionURL',
  ];

  const generateLabels = keys => {
    let labels = [];
    for (const key of keys) {
      labels.push(
        <div>
          <FormattedMessage
            id={'siDetail.' + key}
            data-selenium-id={'SIDetail_JournalDetailsBlock-' + key}
          />
          :
        </div>
      );
    }
    return labels;
  };

  const isUrl = [false, false, false, true, true];

  const { title, infoObj, SIid, open } = props;
  const [isFold, setIsFold] = useState(false);

  const [leftInfo, setLeftInfo] = useState([]);
  const [rightInfo, setRightInfo] = useState([]);

  const dispatch = useDispatch();

  const showBox =  {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    overflow: 'hidden',
  };

  useEffect(() => {
    // console.log(infoObj)
    let tmpLeft = [];
    let tmpRight = [];
    if (infoObj !== undefined && infoObj[0] !== undefined) {
      for (let index in leftKeys) {
        tmpLeft.push(infoObj[0][leftKeys[index]]);
      }
      for (let index in rightKeys) {
        tmpRight.push(infoObj[0][rightKeys[index]]);
      }
    }
    setLeftInfo(tmpLeft);
    setRightInfo(tmpRight);
  }, [infoObj]);

  return (
    <>
      {/* 标题栏 */}
      {/* Zhao Zhenxiang修改 */}
      <Box
        sx={{ mb: '16px', mt: '32px',cursor:'pointer' }}
        id='titleBox'
        data-selenium-id='SIDetail_JournalDetailsBlock-Container'
      >
        <Box
          class='titleFoldButton'
          onClick={() => {
            async function fetchData() {
              await dispatch(get_SIHistory(SIid, 1, 20));
              await dispatch(get_LeadGEHistory(SIid, 1, 20));
              await dispatch(get_COGEHistory(SIid, 1, 20));
            }
            if (title === 'Editing History') {
              fetchData();
            }
            setIsFold(!isFold);
          }}
          data-selenium-id='SIDetail_JournalDetailsBlock-TitleFoldBtn'
        >
          {isFold ? <MarketChevronUp /> : <SIDetailArrowDown />}
        </Box>
        <Box
          id='titleBoxLeft'
          data-selenium-id='SIDetail_JournalDetailsBlock-TitleBoxLeft'
          sx={{cursor:'default'}}
        >
          {title}
        </Box>
      </Box>
      {isFold ? (
        <Grid
          container
          sx={showBox}
          data-selenium-id='SIDetail_JournalDetailsBlock-Grid'
        >
          <LabelAndValue
            id={'siPageJournalDetailsLeftInfo'}
            data-selenium-id='SIDetail_JournalDetailsBlock-LeftInfo'
            labels={generateLabels(leftKeys)}
            value={leftInfo}
            open={open}
            isUrl={isUrl}
            widthList={['170px', '357px', '192px', '410px']}
          />
          <LabelAndValue
            id={'siPageJournalDetailsRightInfo'}
            data-selenium-id='SIDetail_JournalDetailsBlock-RightInfo'
            labels={generateLabels(rightKeys)}
            value={rightInfo}
            open={open}
            isUrl={isUrl}
            widthList={['170px', '357px', '192px', '410px']}
          />
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

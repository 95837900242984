import { SectionItem, SectionState } from "../../types.d";
import styles from '../../index.module.scss';

import Circle1 from '../../assets/circle-1.svg';
import Circle2 from '../../assets/circle-2.svg';
import Circle3 from '../../assets/circle-3.svg';
import Circle4 from '../../assets/circle-4.svg';
import Circle5 from '../../assets/circle-5.svg';
import Circle6 from '../../assets/circle-6.svg';
import CircleChecked from '../../assets/circle-checked.svg';
import {jumpToSection} from "@/pages/SIPForm/components/Card";

export interface ProcessIndicatorPanelProps{
    sections: SectionItem<any>[];

}

export interface ProcessIndicatorProps {
    section: SectionItem<any>;
    index: number;
}

function ProcessIndicatorPanel(props: ProcessIndicatorPanelProps) {
  const {
    sections
  } = props;
  
  return (
    <div className={styles['process-panel']}>
        {
          sections.map((section, index) => 
            <ProcessIndicator index={index} key={index} section={section}/>
            )
        }
    </div>
  );
}

const circleIcons = [Circle1, Circle2, Circle3, Circle4, Circle5, Circle6];

function ProcessIndicator(props: ProcessIndicatorProps) {
  const {
    section,
    index
  } = props;

  const CircleIcon = circleIcons[index];
  
  return (
    <div 
        className={
            styles['process-indicator'] + ' ' +
          (section.state === SectionState.completed ? styles['done'] 
            : section.state === SectionState.error ? styles['error'] : "")
        }
      >
        { index > 0 && <div className={styles['line']}></div>}
        <div className={styles['title']}>
          {section.state === SectionState.completed ? (
            <CircleChecked className={styles['left']}/>
          ) : (
            <CircleIcon className={styles['left']} />
          )}
          <div className={styles['right']} onClick={() => jumpToSection(section)}>
            <p className={styles['section-number']}>Section {index + 1}</p>
            <p className={styles['section-title']}>{section.title}</p>
          </div>
        </div>
      </div>
  );
}

export default ProcessIndicatorPanel;
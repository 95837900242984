import React, { useEffect, useState } from 'react';
import { Box, Stack, IconButton, TextField, Popover } from '@mui/material';
import SIFilterCalendarIcon from '../../assets/SIFilterCalendarIcon.svg';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateCalendar } from './DateCalendar';
import * as DateFnsUtils from 'date-fns';
import zIndex from '@mui/material/styles/zIndex';
import { RadioErrorMessage } from '@/componentsphase2/RadioErrorMessage';
export const DateRangePicker = props => {
  const {
    id = '',
    fromDate,
    toDate,
    minDate,
    maxDate,
    handleDateChange,
    placeholder,
    minHeight,
    width = '358px',
    border = '1px solid #98A7B6',
    popoverIndex = zIndex,
    innerBorder,
    iconPaddingRight,
    backgroundColor,
    PopperProps = {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
    },
    outerId,
    autoClose = true,
    needErrorCheck = false,
    needErrorMessage = false,
    onFocus,
    onBlur,
  } = props;
  const [dateOpen, setDateOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const [height, setHeight] = useState(0);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    setStartDate(fromDate);
  }, [fromDate]);

  useEffect(() => {
    setEndDate(toDate);
  }, [toDate]);

  const handleOpenPicker = event => {
    setDateOpen(true);
    setAnchorEl(event.currentTarget);
    if (outerId) {
      setTimeout(() => {
        let select = document.getElementById(`${id}-range-popover_select`);
        let popper = document.getElementById('date-range-popover');
        let papper = popper.getElementsByClassName('MuiPaper-root')[0];
        let papperTop = Number(papper.style.top.split('px')[0]) + 6;
        let bottom = papperTop + 342;
        let selectBottom = select.getBoundingClientRect().top + 40;
        let difHeightOrigin = 0;
        if (papperTop - 6 <= selectBottom) {
          difHeightOrigin = selectBottom - papperTop + 6;
        }
        let outer = document.getElementById(outerId);
        if (window.innerHeight < bottom + 68 + 32) {
          let difHeight = bottom + 68 + 32 - window.innerHeight;
          let scrollTop = outer.scrollTop;
          let outerClientHeighht = outer.clientHeight;
          let outerScrollHeight = outer.scrollHeight;
          let maxScrollTop = outerScrollHeight - outerClientHeighht;
          let nextScrollTop = scrollTop + difHeight + difHeightOrigin;
          if (nextScrollTop >= maxScrollTop) {
            setHeight(nextScrollTop - maxScrollTop);
          } else {
            outer.scrollTo(0, scrollTop + difHeight + difHeightOrigin);
          }
          setTimeout(() => {
            papper.style.top = `${Number(papperTop - difHeight - 5)}px`;
          }, 100);
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (height) {
      let outer = document.getElementById(outerId);
      let outerClientHeighht = outer.clientHeight;
      let outerScrollHeight = outer.scrollHeight;
      let maxScrollTop = outerScrollHeight - outerClientHeighht;
      outer.scrollTo(0, maxScrollTop);
    }
  }, [height]);

  const handleClosePicker = () => {
    setDateOpen(false);
    setAnchorEl(null);
    handleDateChange(startDate, endDate);
    setHeight(0);
  };

  const autoClosePicker = (startDate, endDate) => {
    if (autoClose) {
      if (startDate && endDate) {
        setDateOpen(false);
        setAnchorEl(null);
        handleDateChange(startDate, endDate);
      }
    }
  };

  const handleFromDateChange = date => {
    if (!(endDate && DateFnsUtils.isAfter(date, endDate))) {
      setStartDate(date);
      autoClosePicker(date, endDate);
    }
  };

  const handleEndDateChange = date => {
    if (!(startDate && DateFnsUtils.isBefore(date, startDate))) {
      setEndDate(date);
      autoClosePicker(startDate, date);
    }
  };

  const emptyDateString = '                    ';
  const formatDateOptional = dt => {
    return dt ? DateFnsUtils.format(dt, 'yyyy-MM-dd') : emptyDateString;
  };
  const textFieldString = () => {
    if (fromDate || toDate) {
      return `${formatDateOptional(fromDate)} ${
        fromDate && toDate ? '  To  ' : '    '
      } ${formatDateOptional(toDate)}`;
    } else {
      return '';
    }
  };
  return (
    <Box>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        data-selenium-id={`SIPage_Overview-newFilterDrawer-FilterDataPicker`}
      >
        <Box
          sx={{
            width: width,
            padding: 0,
            '& .MuiFormControl-root': {
              marginTop: 0,
              marginBottom: 0,
              '& .MuiInputBase-input': {
                paddingTop: 0,
                paddingBottom: 0,
                minHeight: minHeight,
              },
            },
          }}
        >
          <TextField
            id={`${id}-range-popover_select`}
            fullWidth={true}
            value={textFieldString()}
            placeholder={placeholder || 'yyyy-mm-dd  To  yyyy-mm-dd'}
            onClick={handleOpenPicker}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={handleOpenPicker}
                  sx={{
                    paddingRight: iconPaddingRight,
                    '&:hover': {
                      backgroundColor: 'transparent !important',
                    },
                  }}
                >
                  <SIFilterCalendarIcon />
                </IconButton>
              ),
            }}
            sx={{
              '& input': {
                //width: '196px',
                height: '18px',
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '125%',
                display: 'flex',
                alignItems: 'center',
              },
              width: width,
              borderRadius: '4px',
              backgroundColor: backgroundColor,
              border: border,
              ...(innerBorder === undefined
                ? dateOpen
                  ? { borderColor: '#0052CC' }
                  : { borderColor: '#98A7B6' }
                : {
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: needErrorCheck && (!focus && !dateOpen) && ((fromDate == null && toDate != null)||(fromDate != null && toDate == null))? '2px solid #EE5350 !important' : innerBorder,
                    },
                  }),
              '& :empty': {
                borderColor: '#DFE4E8',
              },
              '& :hover': {
                borderColor: '#262E35',
              },
              '& :focus': {
                borderColor: '#0052CC',
              },
              '& button': {
                boxShadow: 'none',
                filter: 'none',
                '&:hover': {
                  '& span:': {
                    filter: 'none',
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                  },
                  backgroundColor: 'transparent',
                },
                '&:active span': {
                  filter: 'none',
                  boxShadow: 'none',
                  backgroundColor: 'transparent',
                },
              },
            }}
            onFocus={() => {
              if (onFocus) {
                onFocus();
              }
              setFocus(true);
            }}
            onBlur={() => {
              if (onBlur) {
                onBlur();
              }
              setFocus(false);
            }}
          />
          <Popover
            id={'date-range-popover'}
            open={dateOpen}
            anchorEl={anchorEl}
            onClose={handleClosePicker}
            sx={{
              zIndex: popoverIndex,
              '& .MuiPaper-root': {
                mt: '6px',
                width: 'fit-content',
                height: 'fit-content',
                overflowX: 'hidden',
                overflowY: 'hidden',
                boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
              },
            }}
            {...PopperProps}
          >
            <Stack
              id='pickers-Stack'
              direction='row'
              spacing={2}
              sx={{
                width: '100%',
                height: '100%',
                gap: '54px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                padding: '16px 24px 20px 24px',
                '& .MuiDateCalendar-root': {
                  marginTop: 0,
                },
                '& .DateRange-FromCalendar': {
                  // marginLeft: '24px',
                },
                '& .DateRange-ToCalendar': {
                  // marginRight: '24px',
                  marginLeft: 0,
                },
                '& .DateRange-Title': {
                  width: '37px',
                  height: '16px',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '16px',
                  textAlign: 'center',
                  color: '#596A7C',
                  marginBottom: '16px',
                },
              }}
            >
              <Stack
                id='fromPicker-Stack'
                className='DateRange-FromCalendar'
                spacing={2}
              >
                <Box id='fromPicker-Label' className='DateRange-Title'>
                  From:
                </Box>
                <DateCalendar
                  id='fromPicker-Calender'
                  date={startDate}
                  toDay={endDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={handleFromDateChange}
                />
              </Stack>
              <Stack
                id='toPicker-Stack'
                className='DateRange-ToCalendar'
                spacing={2}
              >
                <Box id='toPicker-Label' className='DateRange-Title'>
                  To:
                </Box>
                <DateCalendar
                  id='toPicker-Calender'
                  date={endDate}
                  fromDay={startDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={handleEndDateChange}
                />
              </Stack>
            </Stack>
          </Popover>
          <RadioErrorMessage
            isError = {needErrorCheck && needErrorMessage && (!focus && !dateOpen) && ((fromDate == null && toDate != null)||(fromDate != null && toDate == null))}
            errorMessage = 'Please input both start date and end date'
            sx={{
              '& .css-1y32yk4': {
                fontFamily: 'Open Sans',
                fontSize: '12px',
                fontWeight: '600',
                lineHeight: '16px',
                textAlign: 'left',
                letterSpacing: '0em'
              }
            }}
          >
          </RadioErrorMessage>
        </Box>
      </LocalizationProvider>
      {/* 在SIP Filter，关闭日期选择器，只设置height=0无效 */}
      {dateOpen?<Box sx={{ height: `${height}px` }}></Box>:<></>}
    </Box>
  );
};

import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { ChartGroups } from '@/components/Chart/ChartGroups';
import { ChartList } from '@/components/Chart/ChartList';
import { changeSiderOpen } from '@/actions/SIMT-User/User/UserManageAction';
import styles from './index.module.scss';

import subjectGroupImg from '@/assets/ReportDiagrams/SiPipeline/Subject Group.png';
import businessDivisionImg from '@/assets/ReportDiagrams/SiPipeline/Business Division.png';
import ceImg from '@/assets/ReportDiagrams/SiPipeline/CE.png';
import catGroupImg from '@/assets/ReportDiagrams/SiPipeline/CAT group.png';
import subjectGroupOAImg from '@/assets/ReportDiagrams/SiPipeline/Subject Group OA.png';
import businessDivisionOAImg from '@/assets/ReportDiagrams/SiPipeline/Business Division OA.png';

import NormalChart from './NormalChart';
import NormalChartCE from './NormalChartCE';
import OAChart from './OAChart';

import {
  SPECIAL_ISSUE_SI_PIPELINE,
  SPECIAL_ISSUE_SI_PIPELINE_OA,
  SUBJECT_GROUP,
  BUSINESS_DIVISION,
  CE_LEVEL,
  CAT_GROUP_LEVEL,
  POST,
} from './constants';

// basic diagram setting
const DIAGRAM_SETTINGS = [
  // 1st group
  {
    groupName: SPECIAL_ISSUE_SI_PIPELINE,
    chartName: SUBJECT_GROUP,
    hasCategoryFilter: true, // Other than stage dropdown, are there dropdown for selected category?
    hasSelectAllOption: true,
    catagoryAttributeName: 'subjectGroup', // category name from API data
    url: '/reports/pipeline/sg', // url of API data for chart
    tableDownloadMethod: POST, // HTTP request type
    tableDownloadUrl: `/reports/pipeline/sg/download`, // excel file url
    tableDownloadCatagoryName: 'subjectGroups',
    tableDownloadWithOa: false,
  },
  {
    groupName: SPECIAL_ISSUE_SI_PIPELINE,
    chartName: BUSINESS_DIVISION,
    hasCategoryFilter: true,
    hasSelectAllOption: false,
    catagoryAttributeName: 'businessDivision',
    url: '/reports/pipeline/bd',
    tableDownloadMethod: POST,
    tableDownloadCatagoryName: 'businessDivisions',
    tableDownloadUrl: `/reports/pipeline/bd/download`,
    tableDownloadWithOa: false,
  },
  {
    groupName: SPECIAL_ISSUE_SI_PIPELINE,
    chartName: CE_LEVEL,
    hasCategoryFilter: true,
    hasSelectAllOption: true,
    catagoryAttributeName: 'handlingCe',
    url: '/reports/pipeline/cat-ce',
    tableDownloadMethod: POST,
    tableDownloadCatagoryName: 'handlingCes',
    tableDownloadUrl: `/reports/pipeline/basic/download`,
    tableDownloadTitle: 'CE', // level of the download files
  },
  {
    groupName: SPECIAL_ISSUE_SI_PIPELINE,
    chartName: CAT_GROUP_LEVEL,
    hasCategoryFilter: true,
    hasSelectAllOption: false,
    catagoryAttributeName: 'catGroup',
    url: '/reports/pipeline/cat',
    tableDownloadMethod: POST,
    tableDownloadCatagoryName: 'catGroups',
    tableDownloadUrl: `/reports/pipeline/basic/download`,
    tableDownloadTitle: 'CAT',
  },
  // 2nd group
  {
    groupName: SPECIAL_ISSUE_SI_PIPELINE_OA,
    chartName: SUBJECT_GROUP,
    hasCategoryFilter: true,
    hasSelectAllOption: true,
    catagoryAttributeName: 'subjectGroup',
    url: '/reports/pipeline/sg',
    tableDownloadMethod: POST,
    tableDownloadUrl: `/reports/pipeline/sg/download`,
    tableDownloadCatagoryName: 'subjectGroups',
    tableDownloadWithOa: true,
  },
  {
    groupName: SPECIAL_ISSUE_SI_PIPELINE_OA,
    chartName: BUSINESS_DIVISION,
    hasCategoryFilter: true,
    hasSelectAllOption: false,
    catagoryAttributeName: 'businessDivision',
    url: '/reports/pipeline/bd',
    tableDownloadUrl: `/reports/pipeline/bd/download`,
    tableDownloadMethod: POST,
    tableDownloadCatagoryName: 'businessDivisions',
    tableDownloadWithOa: true,
  },
];

const CHART_LIST_TITLES = [
  {
    groupTitle: SPECIAL_ISSUE_SI_PIPELINE,
    levels: [
      {
        chartIndex: 0,
        title: SUBJECT_GROUP,
        img: subjectGroupImg,
      },
      {
        chartIndex: 1,
        title: BUSINESS_DIVISION,
        img: businessDivisionImg,
      },
      {
        chartIndex: 2,
        title: CE_LEVEL,
        img: ceImg,
      },
      {
        chartIndex: 3,
        title: CAT_GROUP_LEVEL,
        img: catGroupImg,
      },
    ],
  },
  {
    groupTitle: SPECIAL_ISSUE_SI_PIPELINE_OA,
    levels: [
      {
        chartIndex: 4,
        title: SUBJECT_GROUP,
        img: subjectGroupOAImg,
      },
      {
        chartIndex: 5,
        title: BUSINESS_DIVISION,
        img: businessDivisionOAImg,
      },
    ],
  },
];

const DiagramContent = () => {
  // get showTable from URL
  let [searchParams, setSearchParams] = useSearchParams();
  let selectedChartIndex = searchParams.get('selectedChartIndex');
  if (selectedChartIndex == null) {
    selectedChartIndex = -1;
  } else {
    selectedChartIndex = parseInt(selectedChartIndex);
  }

  const setShowTableNav = chartIndex => {
    setSearchParams({ showTable: false, selectedChartIndex: chartIndex });
  };

  const siderOpen = useSelector(state => state.UserManage.siderOpen);

  const dispatch = useDispatch();

  const {
    groupName,
    chartName,
    hasCategoryFilter,
    hasSelectAllOption,
    catagoryAttributeName,
    url,
    tableDownloadTitle,
    tableDownloadMethod,
    tableDownloadCatagoryName,
    tableDownloadUrl,
    tableDownloadWithOa,
  } = useMemo(() => {
    if (
      selectedChartIndex >= 0 &&
      selectedChartIndex < DIAGRAM_SETTINGS.length
    ) {
      return DIAGRAM_SETTINGS[selectedChartIndex];
    } else {
      return {
        groupName: '',
        chartName: '',
        hasCategoryFilter: false,
        hasSelectAllOption: false,
        catagoryAttributeName: null,
        url: null,
        tableDownloadTitle: null,
        tableDownloadMethod: null,
        tableDownloadUrl: null,
        tableDownloadCatagoryName: null,
        tableDownloadWithOa: null,
      };
    }
  }, [selectedChartIndex]);

  const { selectMonthDate } = useSelector(state => ({
    selectMonthDate: state.Report.selectDate,
  }));

  // select an index from configuartion array.
  const onSelectChartIndex = index => {
    setShowTableNav(index);

    // if a chart is selected and the side menu is open, close the side menu
    if (index >= 0 && index < DIAGRAM_SETTINGS.length && siderOpen) {
      dispatch(changeSiderOpen(false));
    }
  };

  const cancelSelectedChart = () => onSelectChartIndex(-1);

  return (
    <Box sx={{ marginTop: '32px', paddingBottom: '62px' }}>
      {selectedChartIndex >= 0 && selectedChartIndex <= 5 ? (
        <Box className={styles['card-content-container']}>
          <div
            className={styles['card-content-paper']}
            disableGutters
            maxWidth={false}
          >
            {/* normal charts have bar & pie */}
            {selectedChartIndex <= 3 && selectedChartIndex !== 2 && (
              <NormalChart
                header={groupName}
                subHeader={chartName}
                selectedMonth={selectMonthDate}
                cancelSelectedChart={cancelSelectedChart}
                hasCategoryFilter={hasCategoryFilter}
                hasSelectAllOption={hasSelectAllOption}
                catagoryAttributeName={catagoryAttributeName}
                url={url}
                tableDownloadMethod={tableDownloadMethod}
                tableDownloadUrl={tableDownloadUrl}
                tableDownloadCatagoryName={tableDownloadCatagoryName}
                tableDownloadTitle={tableDownloadTitle}
                tableDownloadWithOa={tableDownloadWithOa}
              />
            )}
            {/* normal charts have bar & pie; CE has grouped selector*/}
            {selectedChartIndex === 2 && (
              <NormalChartCE
                header={groupName}
                subHeader={chartName}
                selectedMonth={selectMonthDate}
                cancelSelectedChart={cancelSelectedChart}
                hasCategoryFilter={hasCategoryFilter}
                hasSelectAllOption={hasSelectAllOption}
                catagoryAttributeName={catagoryAttributeName}
                url={url}
                tableDownloadMethod={tableDownloadMethod}
                tableDownloadUrl={tableDownloadUrl}
                tableDownloadTitle={tableDownloadTitle}
                tableDownloadCatagoryName={tableDownloadCatagoryName}
                tableDownloadWithOa={tableDownloadWithOa}
              />
            )}
            {/* OA charts have bar*/}
            {selectedChartIndex > 3 && (
              <OAChart
                header={groupName}
                subHeader={chartName}
                selectedMonth={selectMonthDate}
                cancelSelectedChart={cancelSelectedChart}
                hasCategoryFilter={hasCategoryFilter}
                hasSelectAllOption={hasSelectAllOption}
                catagoryAttributeName={catagoryAttributeName}
                url={url}
                tableDownloadMethod={tableDownloadMethod}
                tableDownloadUrl={tableDownloadUrl}
                tableDownloadCatagoryName={tableDownloadCatagoryName}
                tableDownloadWithOa={tableDownloadWithOa}
              />
            )}
          </div>
          <ChartList
            chartGroupTitles={CHART_LIST_TITLES}
            onSelectChartIndex={onSelectChartIndex}
            selectedChartIndex={selectedChartIndex}
          />
        </Box>
      ) : (
        <ChartGroups
          chartGroupTitles={CHART_LIST_TITLES}
          onSelectChartIndex={onSelectChartIndex}
          selectedChartIndex={selectedChartIndex}
        />
      )}
    </Box>
  );
};

export default DiagramContent;

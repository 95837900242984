import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { Box, Button, Typography } from '@mui/material';
import EmailSentIcon from '../../../assets/Email Sent.svg';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clear } from '@/actions/SIMT-User/Login/LoginAction';
import { useEffect } from 'react';

export default function ResetEmailSentPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = state => {
    return {
      ifFirst: state.Login.ifFirst,
      emailMsg: state.Login.forgetPassEmail,
    };
  };
  const { emailMsg } = useSelector(selector);

  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, []);

  if (emailMsg === true) {
    return (
      <div>
        <Header data-selenium-id='Login_ResetEmailSent-Header' />
        <Box
          display='flex'
          width='100%'
          alignItems='flex-start'
          justifyContent='center'
          mt={17}
          mb={30}
          data-selenium-id='Login_ResetEmailSent-Box'
        >
          <Box
            width={'960px'}
            sx={{
              background: '#fff',
              border: '1px solid #DDDDDD',
              boxSizing: 'border-box',
              borderRadius: '4px',
              display: 'flex',
            }}
            data-selenium-id='Login_ResetEmailSent-Box-Box'
          >
            <Box
              sx={{ my: '40px', ml: '30px' }}
              data-selenium-id='Login_ResetEmailSent-Box-Box-Box'
            >
              <EmailSentIcon data-selenium-id='Login_ResetEmailSent-EmailSentIcon' />
            </Box>
            <Box data-selenium-id='Login_ResetEmailSent-Box-SecondBox'>
              <Typography
                fontWeight={400}
                fontSize='34px'
                lineHeight={'46px'}
                sx={{ color: '#484848' }}
                mt={8}
                ml={5}
                data-selenium-id='Login_ResetEmailSent-Typography'
              >
                Check your email for password reset instructions
              </Typography>
              <Button
                id='resentEmailSentPageLoginButton'
                data-selenium-id='Login_ResetEmailSent-LoginButton'
                variant='contained'
                color='lightBlue'
                sx={{
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '20px',
                  ml: 5,
                  mt: 3,
                }}
                onClick={() => {
                  navigate('/simt/login');
                }}
              >
                HOME
              </Button>
            </Box>
          </Box>
        </Box>
        <Footer />
      </div>
    );
  } else {
    return <Navigate replace to='/simt/login' />;
  }
}

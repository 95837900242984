/*
 * @Author: jojo 903497174@qq.com
 * @Date: 2023-07-21 16:14:02
 * @LastEditors: jojo 903497174@qq.com
 * @LastEditTime: 2023-09-16 17:20:46
 * @FilePath: \simt_front\src\components\FileUploader\DragHooks.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { useEffect, useState, useRef } from "react";
import {
  Box
} from "@mui/material";
import PropTypes from 'prop-types';

const FilesDragAndDrop = (props) => {
  const { count, onUpload } = props;
  const drop = useRef();
  useEffect(() => {
    drop.current.addEventListener('dragover', handleDragOver);
    drop.current.addEventListener('drop', handleDrop);
    drop.current.addEventListener('dragenter', handleDragEnter);
    drop.current.addEventListener('dragleave', handleDragLeave);
    return () => {
      if (drop.current) {
        drop.current.removeEventListener('dragover', handleDragOver);
        drop.current.removeEventListener('drop', handleDrop);
        drop.current.removeEventListener('dragenter', handleDragEnter);
        drop.current.removeEventListener('dragleave', handleDragLeave);
      }
    };
  });

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = [...e.dataTransfer.files];

    if (count && count < files.length) {
      
      return;
    }

    if (files && files.length) {
      
      onUpload(e);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Box
      sx={{
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '18px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: '#98A7B6'
      }}
      ref={drop}
      className='FilesDragAndDrop'
    >
      {props.children}
    </Box>
  );
};

FilesDragAndDrop.propTypes = {
  onUpload: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  count: PropTypes.number,
  formats: PropTypes.arrayOf(PropTypes.string)
};

export { FilesDragAndDrop };
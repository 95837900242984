import { axios_instance } from "@/utils/axios_instance";
import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";
import { getSIPStatus } from "@/actions/SIMT-SIP/SIP/SipDetail";
import { SIP_VERSION_ERROR } from "@/actions/SIMT-SIP/SIP/SIPAction";

const ERROR_CODES = {
    SIP_NOT_FOUND: 16006,
    CALL_FOR_PAPER_EDIT_PERMISSION_ERROR: 16029,
    POTENTIAL_AUTHOR_ADD_ERROR: 16015,
    POTENTIAL_AUTHOR_DUPLICATION_ERROR: 16037,
};

export const editSipCallForPaper = (sipCode, openCallForPaper, sipVersion) => {
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}`;
            const res = await axios_instance.put(url, {
                editOpenCallForPaperRequests: { openCallForPaper: openCallForPaper }
            }, {
                params: {
                    sipVersion: sipVersion,
                    action: 'editOpenCallForPapers',
                },

                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const { code } = res.data;
            if (res.status === 200) {
                if (code === 200) {
                    return res.data.sipVersion;
                }
                if (code === ERROR_CODES.SIP_NOT_FOUND) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.sipNotFound',
                            {},
                            SEVERITIES.error
                        )
                    );
                } else if (code === ERROR_CODES.CALL_FOR_PAPER_EDIT_PERMISSION_ERROR) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.callForPaperEditPermissionError',
                            {},
                            SEVERITIES.error
                        )
                    );
                } else if (code === SIP_VERSION_ERROR) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.sipStatusChanged',
                            {},
                            SEVERITIES.warning
                        )
                    );
                    getSIPStatus(sipCode);
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.addOCPErrorMessage',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.addOCPErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.addOCPErrorMessage',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
};
export const editPotentialAuthorAction = (potentialAuthor, authorId, sipCode, sipVersion) => {
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/authors/${authorId}`;
            const res = await axios_instance.put(url, {
                affiliation: potentialAuthor.affiliation,
                email: potentialAuthor.email,
                name: potentialAuthor.name,
                topic: potentialAuthor.topic,
                sipVersion: sipVersion,
                authorId,
                sipCode
            }
                , {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                });
            const { code, message } = res.data;
            if (res.status === 200) {
                if (code === 200) {
                    return res.data.sipVersion;
                } else if (code === ERROR_CODES.CALL_FOR_PAPER_EDIT_PERMISSION_ERROR) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.callForPaperEditPermissionError',
                            {},
                            SEVERITIES.error
                        )
                    );
                    return;
                } else if (code === ERROR_CODES.POTENTIAL_AUTHOR_DUPLICATION_ERROR) {
                    dispatch(
                        setSnackbarMessageAndOpen(message, {}, SEVERITIES.error)
                    );
                    return;
                } else if (code === SIP_VERSION_ERROR) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.sipStatusChanged',
                            {},
                            SEVERITIES.warning
                        )
                    );
                    getSIPStatus(sipCode);
                }
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.potentialAuthorEditErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.potentialAuthorEditErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.potentialAuthorEditErrorMessage',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
};
/**
 * @param {object} addedPotentialAuthor
 * @param {string} sipCode
 */
export const addPotentialAuthorAction = (addedPotentialAuthor, sipCode) => {
    /**
     * @param {import("redux").Dispatch} dispatch
     */
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/authors`;
            const res = await axios_instance.post(url, addedPotentialAuthor, {
                headers: {
                    authorization: localStorage.getItem('authorization') ?? '',
                },
            });
            const { code } = res.data;
            if (res.status === 200) {
                if (code === 200) {
                    return res.data.sipVersion;
                } else if (code === ERROR_CODES.POTENTIAL_AUTHOR_ADD_ERROR) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.potentialAuthorAddErrorMessage',
                            {},
                            SEVERITIES.error
                        )
                    );
                } else if (code === ERROR_CODES.CALL_FOR_PAPER_EDIT_PERMISSION_ERROR) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.callForPaperEditPermissionError',
                            {},
                            SEVERITIES.error
                        )
                    );
                } else if (code === ERROR_CODES.POTENTIAL_AUTHOR_DUPLICATION_ERROR) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.potentialAuthorDuplicationError',
                            {},
                            SEVERITIES.error
                        )
                    );
                } else if (code === SIP_VERSION_ERROR) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.sipStatusChanged',
                            {},
                            SEVERITIES.warning
                        )
                    );
                    getSIPStatus(sipCode);
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.potentialAuthorAddErrorMessage',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.potentialAuthorAddErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.potentialAuthorAddErrorMessage',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
};

/**
 * @param {string} sipCode
 * @param {string} authorId
 * @param {number} sipVersion
 * @returns 
 */
export const deletePotentialAuthorAction = (sipCode, authorId, sipVersion) => {
    /**
     * @param {import("redux").Dispatch} dispatch
     */
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/authors/${authorId}`;
            const res = await axios_instance.delete(url, {
                params: {
                    // sipCode: sipCode,
                    // authorId: authorId,
                    sipVersion: sipVersion,
                },
                headers: {
                    authorization: localStorage.getItem('authorization') ?? '',
                },
            });
            const { code } = res.data;
            if (res.status === 200) {
                if (code === 200) {
                    return res.data.sipVersion;
                } else if (code === ERROR_CODES.CALL_FOR_PAPER_EDIT_PERMISSION_ERROR) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.callForPaperEditPermissionError',
                            {},
                            SEVERITIES.error
                        )
                    );
                    return;
                } else if (code === SIP_VERSION_ERROR) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.sipStatusChanged',
                            {},
                            SEVERITIES.warning
                        )
                    );
                    getSIPStatus(sipCode);
                }
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.potentialAuthorDeleteErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.potentialAuthorDeleteErrorMessage',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
};
import { ResponsiveButton } from '@/components/StyledButton/SIMTButton';
import { StrictMode, useState, ReactNode } from 'react';
import PropTypes from 'prop-types';
import RotatingSVG from './RotatingSVG';
import SIOverviewExportIconWhite from '@/assets/SIOverviewExportIconWhite.svg';
import SIOverviewExportIconNew from '@/assets/StreamNew.svg';
interface PropTypes {
  readonly hasSelector?: boolean;
  readonly fetchData?: () => Promise<void>;
  readonly endClick?: () => void;
  readonly downBy?: number;
  readonly id?: string;
  readonly otherSX?: object;
  readonly children?: ReactNode;
}
const delay = (time: number) => new Promise(resolve => setTimeout(resolve, time));
export const delay500 = () => delay(500);
export default function ExportButton (props: PropTypes) {
  const {
    hasSelector = true,
    fetchData = () => Promise.resolve(),
    endClick = () => { },
    downBy = 0,
    id = 'export-button',
    otherSX = {},
    children = null,
    ...others
  } = props;
  const [isDownloading, setisDownloading] = useState(false);
  return (
    <StrictMode>
      <ResponsiveButton
        id={id}
        data-selenium-id={id}
        downBy={downBy}
        color='white'
        variant='contained'
        onClick={async () => {
          setisDownloading(true);
          await fetchData();
          await delay500();
          setisDownloading(false);
          endClick();
        }}
        disabled={isDownloading}
        startIcon={
          isDownloading ? (<RotatingSVG />)
            :
            (hasSelector ? <SIOverviewExportIconWhite /> :
              <SIOverviewExportIconNew />)
        }
        sx={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px 12px !important',
          gap: '6px',
          minWidth: '48px',
          height: '34px',
          border: hasSelector ? 'none' : '1px solid #0052CC',
          borderRadius: '5px',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '18px',
          textAlign: 'center',
          color: hasSelector ? '#FFFFFF' : '#0052CC',
          backgroundColor: isDownloading ? '#AEC6F6 !important' : (hasSelector ? '#0052CC' : '#FFFFFF'),
          flex: 'none',
          order: 1,
          flexGrow: 0,
          '& .MuiButton-startIcon': {
            margin: 0,
          },
          '&:hover': {
            color: hasSelector ? '#FFFFFF' : '#6D98EE',
            background: hasSelector ? '#6d98ee' : '#FFFFFF',
            'svg path': {
              fill: hasSelector ? null : '#6D98EE',
            },
          },
          '&:active': {
            color: hasSelector ? '#FFFFFF' : '#6D98EE',
            background: hasSelector ? '#113d95' : '#e8edfb',
            'svg path': {
              fill: hasSelector ? null : '#6D98EE',
            },
          },
          ...otherSX,
        }}
        {...others}
      >
        {children}
      </ResponsiveButton>
    </StrictMode>
  );
}
ExportButton.prototype = {
  hasSelector: PropTypes.bool,
  fetchData: PropTypes.func,
  endClick: PropTypes.func,
  downBy: PropTypes.number,
  id: PropTypes.string,
  otherSX: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
};
ExportButton.defaultProps = {
  hasSelector: true,
  fetchData: () => { },
  endClick: () => { },
  downBy: 0,
  id: 'export-button',
  otherSX: {},
  children: null,
};
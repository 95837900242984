import { Box, styled, Tab, tabClasses, Tabs } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { NewDesignedMain } from '../../components/Main';
import ManuallySentEmailsShow from './ManuallySentEmailsShow';
import AutoSentEmailByDeadline from './AutoSentEmailByDeadline';
import AutoSentEmailByAction from './AutoSentEmailByAction';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import {
  switchEmailTemplateTab
} from "../../actions/SIMT-Email/EmailAction";
import AutomaticallySendingEmails from './SIPEmailTemplate/AutomaticallySendingEmails';
import ManuallySendingEmails from './SIPEmailTemplate/ManuallySendingEmails';
import { ShowForPermission } from "../../components/PermissionControl/ShowForPermission";
import {getEmailTemplates, getSIPEmailTemplates, getSolicitedSIPEmailTemplates} from "@/actions/SIMT-Email/Template";
import {PERMISSIONS} from "@/constant/permission";

function EmailTemplatesMain() {
  const intl = useIntl(); // 为国际化提供的API支持

  const dispatch = useDispatch();

  const userPermissionArray = localStorage.getItem('userPermissions').split(',');
  const emailSIPermisssion = userPermissionArray?.includes(PERMISSIONS.EMAIL_SI);
  const emailSIPPermisssion = userPermissionArray?.includes(PERMISSIONS.EMAIL_SIP);
  const SIPJPAPermisssion = userPermissionArray?.includes(PERMISSIONS.SIP_WRITE_JPA);

  useEffect(() => {
    if (emailSIPermisssion) {
      dispatch(getEmailTemplates());
    }
    if (emailSIPPermisssion){
      dispatch(getSIPEmailTemplates());
      if (SIPJPAPermisssion){
        dispatch(getSolicitedSIPEmailTemplates());
      }
    }
  }, []);

  const selector = state => {
    return {
      sidebarOpen: state.UserManage.siderOpen,
      emailDetail: state.Email.emailTemplatesList,
      emailSIPTemplatesList: state.Email.emailSIPTemplatesList,
      emailSolicitedSIPTemplatesList: state.Email.emailSolicitedSIPTemplatesList,
      currentEmailTemplateTab: state.Email.currentEmailTemplateTab,
    };
  };
  const {
    sidebarOpen,
    emailDetail,
    emailSIPTemplatesList,
    emailSolicitedSIPTemplatesList,
    currentEmailTemplateTab,
  } = useSelector(selector);

  // useEffect(() => {
  //   console.log('emailSIPTemplatesList', emailSIPTemplatesList);
  // }, [emailSIPTemplatesList]);

  const EmailTemplateTab = styled(Tab)(({ _theme }) => ({
    [`&.${tabClasses.root}`]: {
      padding: '16px 16px 14px 16px',
      fontStyle: 'normal',
      fontSize: '14px',
      fontWeight: 600,
      color: '#596A7C',
      textAlign: 'center',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      lineHeight: '20px',
      fontFamily: 'Open Sans',
    },
    [`&.${tabClasses.selected}`]: {
      color: '#113D95 !important',
    },
  }));

  const handleChange = (_event, newValue) => {
    dispatch(switchEmailTemplateTab(newValue));
  };

  function a11yProps(index) {
    return {
      id: `systemAdminEmailTemplateTab${index}`,
      'aria-controls': `emailTemplateTabPanel${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`emailTemplateTabPanel${index}`}
        data-selenium-id={`EmailTemplate-TabPanel${index}`}
        aria-labelledby={`emailTemplateTab${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  return (
    <NewDesignedMain open={sidebarOpen} sx={{ minWidth: '550px' }}>
      <Box
        p={'21px 26px 0px 20px'}
        data-selenium-id='EmailTemplates-EmailTemplatesMain-Box'
      >
        <Box
          id='detailTitleBox'
          data-selenium-id='EmailTemplates-EmailTemplatesMain-DetailTitleBox'
        >
          <div
            style={{
              width: '100%',
              height: '28px',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '22px',
              lineHeight: '125%',
            }}
          >
            {intl.messages['email.emailTemplates']}
          </div>
          <Box
            data-selenium-id='EmailTemplates-EmailTemplatesMain-Box2'
            sx={{
              borderBottom: '2px solid #DFE4E8',
              width: 'fit-content',
              // marginLeft: '8px',
              marginBottom: '15px',
              marginTop: '12px',
            }}
          >
            <Tabs
              value={currentEmailTemplateTab}
              onChange={handleChange}
              aria-label='basic tabs example'
              sx={{
                marginBottom: '-2px', // 这个属性是为了让底部的蓝色线条跟外部的灰白色线条平齐
              }}
            >
              {emailSIPermisssion ? (<EmailTemplateTab
                id='emailTemplateFirst'
                label='SI Email Templates'
                {...a11yProps(0)}
              />): null}
              {emailSIPPermisssion && SIPJPAPermisssion ? (<EmailTemplateTab
                id='emailTemplateSecond'
                label='Solicited SIP Email Templates'
                {...a11yProps(1)}
              />): null}
              {emailSIPPermisssion ? (<EmailTemplateTab
                id='emailTemplateThird'
                label='Unsolicited SIP Email Templates'
                {...a11yProps(2)}
              />):null}
            </Tabs>
          </Box>
        </Box>
        <Box p={'0px 46px 0px 32px'} style={{ backgroundColor: '#ffffff' }}>
          <TabPanel
            value={currentEmailTemplateTab}
            index={0}
            data-selenium-id='EmailTemplates-EmailTemplatesMain-TabPanel1'
            sx={{ p:0 }}
          >
            {emailDetail.manuallySentEmails !== undefined &&
            emailDetail.manuallySentEmails !== null ? (
              <>
                <ManuallySentEmailsShow
                  manuallySentDetail={emailDetail.manuallySentEmails}
                />
                <AutoSentEmailByDeadline
                  AutoSentDetail={emailDetail.autoSentEmailByDeadline}
                />
                <AutoSentEmailByAction
                  AutoSentByAction={emailDetail.autoSentEmailByAction}
                />
              </>
            ) : (
              <></>
            )}
          </TabPanel>
          <TabPanel
            value={currentEmailTemplateTab}
            index={1}
            data-selenium-id='EmailTemplates-EmailTemplatesMain-TabPanel2'
          >
            {emailSolicitedSIPTemplatesList !== [] &&
            emailSolicitedSIPTemplatesList.manuallySendingEmails !== undefined &&
            emailSolicitedSIPTemplatesList.automaticallySendingEmails !== undefined ? (
              <>
                <ManuallySendingEmails
                  manuallySentDetail={
                    emailSolicitedSIPTemplatesList.manuallySendingEmails
                  }
                  isSolicited={true}
                ></ManuallySendingEmails>
                <AutomaticallySendingEmails
                  AutoSentDetail={
                    emailSolicitedSIPTemplatesList.automaticallySendingEmails
                  }
                  isSolicited={true}
                ></AutomaticallySendingEmails>
              </>
            ) : (
              <></>
            )}
          </TabPanel>
          <TabPanel
            value={currentEmailTemplateTab}
            index={2}
            data-selenium-id='EmailTemplates-EmailTemplatesMain-TabPanel3'
          >
            {emailSIPTemplatesList !== [] &&
            emailSIPTemplatesList.manuallySendingEmails !== undefined &&
            emailSIPTemplatesList.automaticallySendingEmails !== undefined ? (
              <>
                <ManuallySendingEmails
                  manuallySentDetail={
                    emailSIPTemplatesList.manuallySendingEmails
                  }
                ></ManuallySendingEmails>
                <AutomaticallySendingEmails
                  AutoSentDetail={
                    emailSIPTemplatesList.automaticallySendingEmails
                  }
                ></AutomaticallySendingEmails>
              </>
            ) : (
              <></>
            )}
          </TabPanel>
        </Box>
      </Box>
    </NewDesignedMain>
  );
}
export default EmailTemplatesMain;

import SIPStepBase from './SIPStepBase';
import PropTypes from 'prop-types';

/**
 * SIP Process Log的气泡组件
 * 必须传入step
 * original可选。不传入的话只会显示一行文字
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function SIPStep(props) {
  const { id, step, origin, sx } = props;
  return (
    <SIPStepBase
      id={id}
      data-selenium-id={id}
      step={step}
      origin={origin}
      sx={sx}
    />
  );
}

SIPStep.propTypes = {
  id: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  origin: PropTypes.string, // 可选，不传入的话只显示一行
  sx: PropTypes.object,
};

export default SIPStep;

import { BootstrapSwitch } from '../../../../components/CreateAndUpdate/MyOperationModule';
import { BootstrapTitle } from '../../../../components/CreateAndUpdate/MyTitle';
import { Box, Grid } from '@mui/material';
import ItemBlock from '../CGTComponents/ItemBlock';
import SwitchButton from '../CGTComponents/SwitchButton';

function CreateAndUpdateSwitch(props) {
  const { checked, defaultChecked, id, title, required, disabled=false, ...other } = props;

  return (
    <ItemBlock
      id={id}
      title={title}
      required={required}
      stackProps={{
        first: {
          direction: 'row',
        },
        second: {
          stackSX:{
            width:'calc(100% - 51px)'
          }
        }
      }}
    >
      <SwitchButton
        id={id}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        {...other}
      />
    </ItemBlock>
  );
}
export default CreateAndUpdateSwitch;

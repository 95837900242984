const { parse, stringify } = JSON;
export const getUserId = () => {
  try {
    const data = parse(localStorage.getItem('userInfo') ?? stringify({
      data: {
        userId: ''
      }
    }))?.data;
    return String(data?.userId ?? data?.id);
  }
  catch (e) {
    console.error(e);
    return '';
  }
};
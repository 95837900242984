// download as png without background
export const saveImageAsPng = (current, fileName) => {
  if (current) {
    let imagedata;
    if (current.querySelector('canvas')) {
      const canvas = current.querySelector('canvas');
      const ctx = canvas.getContext('2d'); // 获取画布的上下文对象
      imagedata = canvas.toDataURL('image/png');
    }
    if (imagedata) {
      const downloadLink = document.createElement('a');
      downloadLink.href = imagedata;
      downloadLink.download = fileName + '.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }
};

// download as jpg with #FFFFFF background
export const saveImageAsJpg = (current, fileName) => {
  if (current) {
    if (current.querySelector('canvas')) {
      const canvas = current.querySelector('canvas');
      const chartCanvas = document.createElement('canvas');
      chartCanvas.width = canvas.width;
      chartCanvas.height = canvas.height;
      const ctx = chartCanvas.getContext('2d'); // 获取画布的上下文对象

      ctx.fillStyle = '#FFFFFF'; // 设置白色背景
      ctx.fillRect(0, 0, canvas.width, canvas.height); // 绘制白色矩形

      ctx.drawImage(canvas, 0, 0);
      chartCanvas.toBlob(
        blob => {
          const url = URL.createObjectURL(blob);
          const downloadLink = document.createElement('a');
          downloadLink.href = url;
          downloadLink.download = fileName + '.jpg'; // 将文件名设置为带有 .jpg 扩展名的文件名
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(url);
        },
        'image/jpeg',
        1.0
      );
    }
  }
};

import { useDispatch, useSelector } from 'react-redux';
import {useParams} from "react-router-dom";
// Components
import Section from '../../../../../SIPage/Overview/Filter/component/Framework/Block/Section';
import RadioBox from './Componets/RadioBox';
import Item from '../../../../../SIPage/Overview/Filter/component/Framework/Block/Item';
// Actions
import { setSelect } from '@/actions/SIMT-SIP/SIPFilter/SIPFilterAction';
// utils
import { cfsRadioList } from './utils';

export default function fifthSection(props) {
  const dispatch = useDispatch();
  const { sipFilterSelected } = useSelector(state => {
    return {
      sipFilterSelected: state.SIPFilter.sipFilterSelected,
    };
  });
  
  function deleteItemFromArray(item, array) {
    let idx = array.indexOf(item);
    array.splice(idx, 1);
  }
  function addItemToArray(item, array) {
    if (!array.includes(item)) {
      array.push(item);
    }
  }

  function handleSIPTimeSensitiveChange(key) {
    let newValue = sipFilterSelected.timeSensitiveSelected.concat(); //TO DO
    if (newValue.includes(key)) {
      deleteItemFromArray(key, newValue);
    } else {
      newValue = [];
      addItemToArray(key, newValue);
    }
    dispatch(setSelect('timeSensitive', newValue));
  }

  function handlePotentialAuthorsChange(key) {
    let newValue = sipFilterSelected.potentialAuthorsSelected.concat();   //TO DO
    if (newValue.includes(key)) {
      deleteItemFromArray(key, newValue);
    } else {
      newValue = [];
      addItemToArray(key, newValue);
    }
    dispatch(setSelect('potentialAuthors', newValue));
  }
  const { sipOrigin } = useParams();
  return (
    <Section id='fifthSection' sx={{ padding: '16px 17px 24px 13px' }}>

      {sipOrigin ==='solicited'? <></>:
        <Item
          id='fifthSectionSIPTimeSensitive'
          title='SIP Time Sensitive'
          titleProps={{ paddingLeft: '3px' }}
          needResetBtn={true}
          resetLabel={'Clear'}
          handleReset={() => dispatch(setSelect('timeSensitive', []))}
        >
          <RadioBox
            id='SIP Time SensitiveRadioBox'
            data-selenium-id='SIPOverview_Filter-fifthSection-SIPTimeSensitiveRadioBox'
            value={sipFilterSelected.timeSensitiveSelected}
            handleChange={handleSIPTimeSensitiveChange}
            radiolist={cfsRadioList}
            stackProps={{
              first: {
                gap: '56px',
              },
            }}
          />
        </Item>}
      <Item
        id='fifthSectionPotentialAuthors'
        title='Potential Authors'
        titleProps={{ paddingLeft: '3px' }}
        needResetBtn={true}
        resetLabel={'Clear'}
        handleReset={() => dispatch(setSelect('potentialAuthors', []))}
      >
        <RadioBox
          id='PotentialAuthorsRadioBox'
          data-selenium-id='SIPOverview_Filter-fifthSection-PotentialAuthorsRadioBox'
          value={sipFilterSelected.potentialAuthorsSelected}
          handleChange={handlePotentialAuthorsChange}
          radiolist={cfsRadioList}
          stackProps={{
            first: {
              gap: '56px',
            },
          }}
        />
      </Item>
    </Section>
  );
}

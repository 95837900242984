import ReportExportButton from '../../ReportSpecificComponents/ReportExportMenuButton';
// import { InputLabel, Grid, Stack, TextField } from '@mui/material';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDate as selectArticlesAnnualTrendsDate,

} from '@/actions/SIMT-Report/Report/ReportAction';
import { CustomizedInput } from '../../../AnnualTarget/Overview';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DatePickerStack, GenerationDate } from '../../GenerationDate';
import { MonthPicker } from '../../../../components/DateRangePicker/MonthPicker';
import styles from '../../header-switch.module.scss';
import ExportAllDialog from '../../ExportAllDialog';
import ReportExportMenuButton from '../../ReportSpecificComponents/ReportExportMenuButton';
import {
  exportArticlesAnnualTrendsCsv,
  exportArticlesAnnualTrendsCsvWithFilenameFromBackEnd,
  exportArticlesAnnualTrendsExcel,
  exportArticlesAnnualTrendsExcelWithFilenameFromBackEnd
} from "@/actions/SIMT-Report/Report/Export";
import {GetArticleLastActionDate} from "@/actions/SIMT-Report/Report/ReportArticle";

export function PageHeadAnnual({ showTable, setShowTable }) {
  const { selectMonthDate, lastActionDate } = useSelector(state => {
    return {
      selectMonthDate: state.Report.selectDate,
      lastActionDate: state.Report.ArticleReportGenenateDate,
    };
  });
  const [exportAllDialogOpen, setExportAllDialogOpen] = useState(false);
  const GenerationDateAndTime =
    'Below reports are generated on ' + lastActionDate + ' (UTC time). ';
  const [selectMonth, setSelectMonth] = useState(new Date());
  const [months, setMonths] = useState([]);
  const currentDate = new Date();
  const dispatch = useDispatch();

  const getMonthList = () => {
    if (months.length === 0) {
      var year = currentDate.getFullYear();
      var month = currentDate.getMonth() + 1;
      for (var i = 0; i < 4; i++) {
        var tmpyear = year - i;
        if (i === 0) {
          for (var j = month; j > 0; j--) {
            months.push(tmpyear + '-' + j);
            setMonths(months);
          }
        } else {
          for (var k = 12; k > 0; k--) {
            months.push(tmpyear + '-' + k);
            setMonths(months);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (selectMonthDate != null) {
      var Year = selectMonthDate.split('-')[0];
      var Month = selectMonthDate.split('-')[1] - 1;
      setSelectMonth(new Date(Year, Month));
    }
  }, [selectMonthDate]);

  const exportExcelWithFilenameFromBackEnd = () => {
    dispatch(
      exportArticlesAnnualTrendsExcelWithFilenameFromBackEnd(selectMonthDate)
    );
  };

  const exportCsvWithFilenameFromBackEnd = () => {
    dispatch(
      exportArticlesAnnualTrendsCsvWithFilenameFromBackEnd(
        selectMonthDate,
        'subject_group_table'
      )
    );
    dispatch(
      exportArticlesAnnualTrendsCsvWithFilenameFromBackEnd(
        selectMonthDate,
        'business_division_table'
      )
    );
  };

  const handleDateChange = value => {
    setSelectMonth(value);
    var year = value.getFullYear();
    var month = value.getMonth() + 1;
    let date = year + '-' + month;
    dispatch(selectArticlesAnnualTrendsDate(date));
    dispatch(GetArticleLastActionDate(date, false));
  };

  useEffect(() => {
    getMonthList();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth() + 1;
    var newDate = '' + year + '-' + month;
    if (selectMonthDate == null) {
      dispatch(selectArticlesAnnualTrendsDate(newDate));
      dispatch(GetArticleLastActionDate(newDate, false));
    }
  }, []);

  return (
    <>
      <ExportAllDialog
        open={exportAllDialogOpen}
        handleClose={() => setExportAllDialogOpen(false)}
        filterData={[]}
        handleCSV={exportCsvWithFilenameFromBackEnd}
        handleExcel={exportExcelWithFilenameFromBackEnd}
      />
      <Grid
        data-selenium-id='Report_Articles-AunnalTrends-PageHead-Grid'
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        <DatePickerStack
          id='Report_Articles-AunnalTrends-Head-Grid-Grid'
          selectedMonth={selectMonth}
          handleDateChange={handleDateChange}
          lastActionDate={lastActionDate}
          showTable={true}
          GenerationDateAndTime={GenerationDateAndTime}
        />
        <Grid
          className={styles['action-buttons-container']}
          data-selenium-id='Report_Articles-AunnalTrends-PageHead-Grid-Grid2'
        >
          {showTable ? (
            <ReportExportMenuButton
              sx={{ height: '32px', marginRight: '24px' }}
              id='reportSipipelineExportButton'
              mode={1}
              // label='common.Export'
              onClick={() => {
                setExportAllDialogOpen(true);
              }}
            />
          ) : null}

          <span className={styles['switch']}>
            <span
              id='show-table-button'
              className={styles[showTable ? 'selected' : '']}
              onClick={() => setShowTable(true)}
            >
              Table
            </span>

            <span
              id='show-diagram-button'
              className={styles[!showTable ? 'selected' : '']}
              onClick={() => setShowTable(false)}
              style={{ maxHeight: '32px' }}
            >
              Diagram
            </span>
          </span>
        </Grid>
      </Grid>
    </>
  );
}

import {axios_instance} from "@/utils/axios_instance";
import {
    ADD_CHINA_MARKETING_ACTIVITY,
    DELETE_CHINA_MARKETING_ACTIVITY,
    GET_ALL_CHINA_MARKETING_ACTIVITY,
    UPDATE_CHINA_MARKETING_ACTIVITY
} from "@/actions/SIMT-SI/SpecialIssue/SIAction";

export const getAllChinaMarketingActivity = siCode => {
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/api/v1/si-service/special-issues/${siCode}/marketing-data/regional/activities`,
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            const{code,data} = res.data;
            if (code === 200) {
                dispatch({
                    type: GET_ALL_CHINA_MARKETING_ACTIVITY,
                    data: data,
                });
            } else {
                dispatch({type: GET_ALL_CHINA_MARKETING_ACTIVITY, data: 'error'});
            }
        } catch (e) {
            dispatch({type: GET_ALL_CHINA_MARKETING_ACTIVITY, data: 'error'});
        }
    };
};
export const addChinaMarketingActivity = (siCode, param) => {
    return async dispatch => {
        try {
            const res = await axios_instance.post(
                `/api/v1/si-service/special-issues/${siCode}/marketing-data/regional/activities`,
                {
                    siCode: siCode,
                    ...param,
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            const{code,data} = res.data;
            if (code === 200) {
                dispatch({
                    type: ADD_CHINA_MARKETING_ACTIVITY,
                    data: data,
                });
            } else {
                dispatch({type: ADD_CHINA_MARKETING_ACTIVITY, data: 'error'});
            }
        } catch (e) {
            dispatch({type: ADD_CHINA_MARKETING_ACTIVITY, data: 'error'});
        }
    };
};
export const updateChinaMarketingActivity = (siCode, activityId, param) => {
    return async dispatch => {
        try {
            const res = await axios_instance.put(
                `/api/v1/si-service/special-issues/${siCode}/marketing-data/regional/activities/${activityId}`,
                {
                    siCode: siCode,
                    activityId: activityId,
                    ...param,
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            const{code,data} = res.data;
            if (code === 200) {
                dispatch({
                    type: UPDATE_CHINA_MARKETING_ACTIVITY,
                    data: data,
                });
            } else {
                dispatch({type: UPDATE_CHINA_MARKETING_ACTIVITY, data: 'error'});
            }
        } catch (e) {
            dispatch({type: UPDATE_CHINA_MARKETING_ACTIVITY, data: 'error'});
        }
    };
};
export const deleteChinaMarketingActivity = (siCode,activityId) => {
    return async dispatch => {
        try {
            const res = await axios_instance.delete(
                `/api/v1/si-service/special-issues/${siCode}/marketing-data/regional/activities/${activityId}`,
                {
                  headers: {
                    authorization: localStorage.getItem('authorization'),
                  },
                }
              );
            const{code,data} = res.data;
            if (code === 200) {
                dispatch({
                    type: DELETE_CHINA_MARKETING_ACTIVITY,
                    data: data,
                });
            } else {
                dispatch({type: DELETE_CHINA_MARKETING_ACTIVITY, data: 'error'});
            }
        } catch (e) {
            dispatch({type: DELETE_CHINA_MARKETING_ACTIVITY, data: 'error'});
        }
    };
};

import { IconButton } from '@mui/material';
import React from 'react';
/*
example:
<MyIconButton
    sx可以传入其他css属性，以及用传给isDisabled的变量写三元表达式
    控制disabled时的效果。
    利用伪类选择器控制hover和active时的效果，'svg path'的fill属性用于修改
    传入的图标的颜色
    sx={{
        '&:hover': {
            color: ,
            background: ,
            'svg path': {
              fill: ,
            },
        },
        '&:active': {
            color: ,
            background: ,
            'svg path': {
              fill: ,
            },
        },
    }}
    isDisabled={}传入变量控制isDisabled
    basicIcon={}此处传入按钮的图标
    onClick={}传入click函数
/>
*/

function MyIconButton(props) {
    const { onClick, sx, isDisabled, basicIcon } = props;

    return (
        <IconButton
            sx={sx}
            onClick={onClick}
            disabled={isDisabled}
            disableFocusRipple
            disableRipple
        >
            {basicIcon}
        </IconButton>
    );
}

export default MyIconButton;
import { useDispatch } from 'react-redux';
import Modal from '@mui/material/Modal';
import { Box, IconButton } from '@mui/material';
import MarketDialogClose from '../../../../../assets/MarketDialogClose.svg';
import Scrollbars from 'react-custom-scrollbars';
import * as React from 'react';
import {StateButton} from '@/componentsphase2/StateButton';
import CloseIconButton from "@/componentsphase2/Button/CloseIconButton";
import {UpdateMarketInfo} from "@/actions/SIMT-SI/SpecialIssue/SiMarketing";

export default function BasicModal(props) {
  const { title, open, handleClose, siCode } = props;

  const dispatch = useDispatch();

  const ContainerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    padding: '24px 32px 0px',
    background: '#FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '5px',
  };
  const HeaderStyle = {
    width: '100%',
    height: '32px',
    marginBottom: '16px',
  };
  const TitleStyle = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: ' 600',
    fontSize: '18px',
    lineHeight: '125%',
    color: '#262E35',
  };
  const ContentStyle = {
    width: '100%',
    // marginBottom: '28px',
    // height: '330px',
  };
  const ButtonBoxStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
  };
  const BtnStyle = (bgColor, color) => {
    return {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: ' center',
      alignItems: 'center',
      width: '79px',
      height: '34px',
      padding: '8px,18px',
      boxSizing: 'border-box',
      borderRadius: '5px',
      backgroundColor: bgColor,
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.01em',
      color: color,
      cursor: 'pointer',
    };
  };

  function handlePromote() {
    dispatch(UpdateMarketInfo({siCode},false, 'Yes'));
  }

  return (
    <div data-selenium-id='SIDetail_MarketSection-BasicModal-Container'>
      <Modal
        sx={{
          '& .MuiModal-backdrop': {
            background: 'rgba(17, 61, 149, 0.5)',
            backdropFilter: 'blur(4px)'
          }
        }}
        open={open}
        onClose={handleClose}
        data-selenium-id='SIDetail_MarketSection-BasicModal'
      >
        <Box
          sx={ContainerStyle}
          data-selenium-id='SIDetail_MarketSection-BasicModal-ContainerBox'
        >
          <Box
            sx={HeaderStyle}
            data-selenium-id='SIDetail_MarketSection-BasicModal-TitleAndCloseBtnContainerBox'
          >
            <Box
              sx={{ ...TitleStyle, height: '28px', float: 'left' }}
              data-selenium-id='SIDetail_MarketSection-BasicModal-TitleBox'
            >
              {title}
            </Box>
            <CloseIconButton
              sx={{ float: 'right', cursor: 'pointer'
              ,paddingTop: 'unset'  //modified
            }}
              onClick={handleClose}
              data-selenium-id='SIDetail_MarketSection-BasicModal-CloseBtn'
            />
          </Box>
          <Box
            style={ContentStyle}
            renderView={props => (
              <div
                {...props}
                style={{
                  position: 'absolute',
                  inset: ' 0px',
                  overflow: 'hidden',
                  marginRight: '-10px',
                  marginBottom: ' 1px',
                }}
                data-selenium-id='SIDetail_MarketSection-BasicModal-ScrollbarsRenderView'
              ></div>
            )}
            renderThumbVertical={props => (
              <div
                {...props}
                style={{
                  width: '8px',
                  backgroundColor: '#D9D9D9',
                  opacity: '0.7',
                  borderRadius: '10px',
                  right: '0px',
                  transform: 'rotate(-90deg)',
                }}
                data-selenium-id='SIDetail_MarketSection-BasicModal-ScrollbarsRenderThumbVertical'
              ></div>
            )}
            data-selenium-id='SIDetail_MarketSection-BasicModal-Scrollbars'
          >
            {props.children}
          </Box>
          <Box
            sx={ButtonBoxStyle}
            data-selenium-id='SIDetail_MarketSection-BasicModal-BackToEditAndPromoteBtnContainerBox'
          >
            <Box
              sx={{
                // ...BtnStyle('#FFFFFF', '#154AB6'),
                marginRight: '9px',
                // border: '1px solid #0052cc',
              }}
            >
              <StateButton
                data-selenium-id='SIDetail_MarketSection-BasicModal-BackToEditText'
                isPrimary={false}
                titleName='Cancel'
                onClick={handleClose}
              />
            </Box>
            <Box>
              <StateButton
                data-selenium-id='SIDetail_MarketSection-BasicModal-PromoteText'
                isPrimary={true}
                titleName='Confirm'
                onClick={handlePromote}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

import * as React from 'react';
import { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import {
  Box,
  Divider,
  Grid,
  InputBase,
  inputBaseClasses,
  ListItem,
  Stack,
  styled, tooltipClasses,
  Typography
} from "@mui/material";
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import EditIconButton from '@/componentsphase2/Button/EditIconButton';
import DragDefaultIcon from '@/componentsphase2/Button/icon/DragDefaultIcon.svg';
import DragActiveIcon from '@/componentsphase2/Button/icon/DragActiveIcon.svg';
import OnlyTextDefaultX from '@/componentsphase2/Button/icon/OnlyTextDefaultX.svg';
import OnlyTextActiveX from '@/componentsphase2/Button/icon/OnlyTextActiveX.svg';
import OnlyTextDefaultCheck from '@/componentsphase2/Button/icon/OnlyTextDefaultCheck.svg';
import OnlyTextActiveCheck from '@/componentsphase2/Button/icon/OnlyTextActiveCheck.svg';
import InputAdornment from '@mui/material/InputAdornment';
import SimpleIconButton from '@/componentsphase2/Button/SimpleIconButton';
import IconButton from "@mui/material/IconButton";
import { CustomTooltip } from "@/components/CustomTooltip";
import { measureWidth } from "@/utils/StringWidthMeasure";
import TooltipMoveArrow from "@/componentsphase2/CustomTooltip";
import {editItem} from "@/actions/SIMT-User/Config";

const StyledInputForDisplay = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    height: '18px',
    backgroundColor: 'white',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '17.5px',
    padding: '7.5px 64px 7.5px 12px',
    borderRadius: '4px',
    border: '1px solid #98A7B6',
    '&:hover, &:focus': {
      border: '2px solid #0052CC',
      padding: '6.5px 64px 6.5px 11px',
    },
  },
  [`&.${inputBaseClasses.disabled}`]: {
    backgroundColor: 'white',
  },
}));

// 该组件是可拖拽的一个item
// 鼠标悬浮的时候会显示编辑按钮，点击编辑按钮之后会变成一个input输入框
// 输入框右侧可以取消保存或保存，支持回车键触发
function DraggableItem(props) {
  const { item, provided, type, currEdit, setCurrEdit } = props;
  const dispatch = useDispatch();

  const originalValue = item.value;
  // 鼠标是否悬浮的状态
  const [hover, setHover] = useState(false);
  // 是否处于输入状态
  // const [isInput, setIsInput] = useState(false);
  // 临时保存的输入内容
  const [tempInputValue, setTempInputValue] = useState(item.value);
  // 是否处于加载中的状态
  const [loading, setLoading] = useState(false);
  // 是否存在错误的输入。
  // 错误输入定义为：
  //    - 输入的内容与原始的内容一样（去除空格）
  //    - 输入的内容超长
  //    - 输入了空的内容（去除空格）
  const inputError =
    tempInputValue.trim() === originalValue.trim() ||
    tempInputValue.length > type.lengthLimit ||
    tempInputValue.trim() === '';

  // 输入内容变化的响应事件
  const handleInputChange = event => {
    setTempInputValue(event.target.value);
  };

  // 取消保存的响应事件
  const handleCancelInput = () => {
    // setHover(false);    // 悬浮状态也false
    // setIsInput(false);
    setCurrEdit(null);
  };

  useEffect(() => {
    if (currEdit !== item.id) {
      setHover(false);
    }
  }, [currEdit, item]);

  // 保存更改的响应事件
  const handleSaveInput = () => {
    // setHover(false);
    setLoading(true);
    const editedItem = {
      ...item,
      value: tempInputValue.trim(),
    };

    async function save() {
      await dispatch(editItem(editedItem, type));
    }

    // 与后端交互结束时将loading状态和输入状态置为false
    save().then(_r => {
      setLoading(false);
      // setIsInput(false);
      setCurrEdit(null);
    });
  };

  const handleStartEditing = initValue => {
    setTempInputValue(initValue); // 设置初始值为原始的value
    // setIsInput(true);   // 设置编辑状态
    setCurrEdit(item.id);
  };

  const [isHover, setIsHover] = React.useState(false);

  if (currEdit === item.id) {
    // 根据当前编辑的内容改变当前的返回项
    return (
      <Box data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownValues-DraggableComponents-DraggableListItem-Box'>
        <Stack
          data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownValues-DraggableComponents-DraggableListItem-Box-Stack'
          direction='row'
          justifyContent='start'
          alignItems='center'
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {/*input的框框*/}
          <StyledInputForDisplay
            sx={{
              backgroundColor: '#E8EDFB',
              padding: '8px 12px 8px 8px',
            }}
            id={`editInput${item.id}`}
            data-selenium-id={`SystemAdmin_SystemConfiguration-EditInput${item.id}`}
            fullWidth
            // multiline
            value={tempInputValue}
            onChange={handleInputChange}
            onKeyDown={e => {
              // 按下时禁止回车键
              if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            onKeyUp={e => {
              // 抬起时响应回车键
              if (e.key === 'Enter') {
                if (!inputError) {
                  handleSaveInput();
                }
              }
            }}
            // inputProps={{
            //   maxLength: type.lengthLimit,
            // }}
            endAdornment={
              <InputAdornment sx={{margin:'0px'}} position="end">
                <IconButton
                  id={`systemAdminSystemConfigurationItemSaveEditButton${item.id}`}
                  data-selenium-id={`SystemAdminSystem-ConfigurationItemSaveEditButton${item.id}`}
                  disableRipple
                  disabled={inputError}
                  onClick={handleSaveInput}
                  onMouseEnter={()=> setIsHover(true)}
                  onMouseLeave={()=>setIsHover(false)}
                  sx={{
                    position: 'absolute',
                    right: '50px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    padding: '0px',
                  }}
                  DefaultIcon={OnlyTextDefaultCheck}
                  ActiveIcon={OnlyTextActiveCheck}
                  HoverIcon={OnlyTextActiveCheck}
                >
                  {isHover?<OnlyTextActiveCheck/>:<OnlyTextDefaultCheck/>}
                </IconButton>
                <SimpleIconButton
                  id={`systemAdminSystemConfigurationItemCancelEditButton${item.id}`}
                  data-selenium-id={`SystemAdminSystem-ConfigurationItemCancelEditButton${item.id}`}
                  disableRipple
                  onClick={handleCancelInput}
                  sx={{
                    position: 'absolute',
                    right: '24px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    padding: '0px',
                  }}
                  DefaultIcon={OnlyTextDefaultX}
                  ActiveIcon={OnlyTextActiveX}
                  HoverIcon={OnlyTextActiveX}
                />
              </InputAdornment>
            }
          />
        </Stack>
        {/*如果因为某些原因超出了最大长度，则显示以下警告*/}
        <Typography
          variant='caption'
          data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownValues-DraggableComponents-DraggableListItem-Box-Typography'
          color='error'
          hidden={tempInputValue.length <= type.lengthLimit}
          pl='4px'
        >
          <FormattedMessage
            id='sysConfig.maxLengthTip'
            data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownValues-DraggableComponents-DraggableListItem-Box-Typography-FormattedMessage-MaxLengthTip'
            values={{ value: type.lengthLimit }}
          />
        </Typography>
      </Box>
    );
  } else {
    // 这里是返回列表项
    const expectedWidth = measureWidth(item.value,'14px Open Sans');
    return (
      <Box
        data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownValues-DraggableComponents-DraggableListItem-Box2'
        // 鼠标hover时
        // onMouseEnter={() => {
        //   if (!hover) {
        //     setHover(true);
        //   }
        // }}
        // hover移除时
        onMouseLeave={() => {
          setHover(false);
        }}
        onMouseOver={() => {
          setHover(true);
        }}
      >
        <Grid
          data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownValues-DraggableComponents-DraggableListItem-Box2-Grid'
          container
          spacing={0}
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid
            item
            xs={12}
            data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownValues-DraggableComponents-DraggableListItem-Box2-Grid-Grid1'
          >
            <ListItem
              id={`systemAdminSystemConfigurationListItem${item.id}`}
              data-selenium-id={`SystemAdminSystem-ConfigurationListItem-${item.id}`}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={{
                padding: '8px',
                // backgroundColor:  hover? '#E8EDFB': '#FFFFFF',
                backgroundColor: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#E8EDFB',
                },
                '&:active': {
                  backgroundColor: '#AEC6F6',
                },
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {/*item的值*/}
              <Box sx={{
                height: '24px',
                width: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems:'center',
              }}
              >
                {hover? <DragActiveIcon/>: <DragDefaultIcon/>}
              </Box>
              <Box sx={{
                paddingLeft: '12px',
                height: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems:'center',
              }}>
                <CustomTooltip
                  title={item.value}
                  placement='top'
                  disableFocusListener={expectedWidth<540}
                  disableHoverListener={expectedWidth<540}
                  disableInteractive={expectedWidth<540}
                  sx={{
                    [`& .${tooltipClasses.tooltip}`]:{
                      maxWidth: '385px',
                    }
                  }}
                >
                <Typography
                  data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownValues-DraggableComponents-DraggableListItem-Box2-Grid-Grid1-ListItem-Typography'
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: '17.5px',
                    color: '#262E35',
                    fontFamily: 'Open Sans',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    maxWidth: '540px',
                  }}
                >
                  {item.value}
                </Typography>
                </CustomTooltip>
              </Box>
              {hover ? (
              // 鼠标悬浮时显示编辑按钮
                <Box sx={{marginLeft: 'auto', paddingRight:'4px'}}>
                  <EditIconButton
                    sx={{padding:'0px'}}
                    id={`systemAdminSystemConfigurationItemEditButton${item.id}`}
                    data-selenium-id={`SystemAdminSystemConfigurationItemEditButton${item.id}`}
                    disableRipple
                    onClick={() => {
                      // 点击编辑按钮之后
                      handleStartEditing(item.value);
                    }}
                  />
                </Box>
              ): <Box sx={{marginLeft: 'auto'}}></Box>}
            </ListItem>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const DraggableListItem = ({ item, index, type, currEdit, setCurrEdit }) => {
  return (
    <Draggable
      draggableId={item.key}
      index={index}
      data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownValues-DraggableComponents-DraggableListItem-Draggable'
    >
      {provided => (
        <>
          {/*因为每个item需要可编辑，所以多封装一层*/}
          <DraggableItem
            data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownValues-DraggableComponents-DraggableListItem-Draggable-DraggableItem'
            item={item}
            provided={provided}
            type={type}
            currEdit={currEdit}
            setCurrEdit={setCurrEdit}
          />
          <Divider
            data-seleunim-id='SystemAdmin_SystemConfiguration-DropdownValues-DraggableComponents-DraggableListItem-Draggable-Divider'
            component='li'
          />
        </>
      )}
    </Draggable>
  );
};

export default DraggableListItem;

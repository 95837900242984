import { useEffect } from 'react';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function ActiveSuccessPage() {
  const navigator = useNavigate();
  const dispatch = useDispatch();

  const handleToLogin = () => {
    navigator('/simt/login');
  };

  return (
    <div data-selenium-id='Login_ActiveEmailPage-ActiveSuccess-Div'>
      <Header data-selenium-id='Login_ActiveEmailPage-ActiveSuccess-Header' />
      <Box
        display='flex'
        width='100%'
        alignItems='flex-start'
        justifyContent='center'
        mt={25}
        mb={35}
        data-selenium-id='Login_ActiveEmailPage-ActiveSuccess-FirstBox'
      >
        <Box
          sx={{
            width: '630px',
            background: '#ffffff',
            border: '1px solid #dddddd',
            boxSizing: 'border-box',
            borderRadius: '4px',
            p: '46px 40px',
          }}
          data-selenium-id='Login_ActiveEmailPage-ActiveSuccess-FirstBox-FirstBox'
        >
          <Typography
            fontSize='34px'
            lineHeight='46px'
            color='#484848'
            data-selenium-id='Login_ActiveEmailPage-ActiveSuccess-FirstTypography'
          >
            Congratulations!
          </Typography>
          <Typography
            fontSize='16px'
            lineHeight='24px'
            color='#484848'
            mt={'16px'}
            data-selenium-id='Login_ActiveEmailPage-ActiveSuccess-SecondTypography'
          >
            Your account is activated. Please click the button to login the
            system.
          </Typography>
          <Button
            id='activeSuccessToLoginButton'
            data-selenium-id='Login_ActiveEmailPage-ActiveSuccess-ActiveSuccessToLoginButton'
            variant='contained'
            onClick={handleToLogin}
            sx={{
              mt: '16px',
              height: '36px',
              background: '#007eb6',
              fontWeight: 600,
            }}
          >
            TO LOGIN
          </Button>
        </Box>
      </Box>
      <Footer />
    </div>
  );
}

export default ActiveSuccessPage;

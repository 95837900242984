import { AAAnnualTrendsChartOptions } from '@/pages/Report/chartUtils';
import DownArrowIcon from '@/assets/ReportDiagrams/A&A/AnnualTrends/DownArrow.png';
import UpperArrowIcon from '@/assets/ReportDiagrams/A&A/AnnualTrends/UpperArrow.png';
import MinusSignIcon from '@/assets/ReportDiagrams/A&A/AnnualTrends/MinusSign.png';
import { SUBMISSION, ACCEPTANCE, PUBLICATION } from '../../Constants/index';

// chart config
const BAR_COLOR = [
  ['#BCC5CF', '#FF9898', '#C40000'],
  ['#BCC5CF', '#FFCA7B', '#FF8A00'],
  ['#BCC5CF', '#6D98EE', '#113D95'],
];

// Rate related color
const INCREASE_LABEL_COLOR = '#FF8180';
const INCREASE_LABEL_BG_COLOR = '#FFDDDD';
const DECREASE_LABEL_COLOR = '#42C1B3';
const DECREASE_LABEL_BG_COLOR = '#D7F1EE';
const EQUAL_LABEL_COLOR = '#596A7C';
const EQUAL_LABEL_BG_COLOR = '#F1F3F5';
const NA_LABEL_COLOR = '#596A7C';

const BAR_GROUP_GAP = 16;
const BAR_WIDTH_THRESHOLD_FOR_FONT_SIZE = 32;

const SG_BAR_WIDTH_MAX = 40;
const BD_BAR_WIDTH = 42;

const Y_AXIS_LABEL_WIDTH = 30;
const X_AXIS_LABEL_WIDTH = 45;

const RATE_LABEL_WIDTH = 50;
const RATE_LABEL_HEIGHT = 21;

const SG_BAR_LABEL_FONT_SIZE = [10, 14];
const BD_BAR_LABEL_FONT_SIZE = 15;

/**
 * overwrite the default configuration
 */

export class NormalChartOptions extends AAAnnualTrendsChartOptions {
  constructor(type, isSubjectGroup, isDownload, width) {
    super();
    // overwrite the default properties
    this.type = type;
    this.isSubjectGroup = isSubjectGroup;
    this.isDownload = isDownload;
    this.numberOfSG = 0;
    this.rateLabelPositionX = -20;
    this.allBarsWidth = width - this.barchartLeft - this.barchartRight - Y_AXIS_LABEL_WIDTH;
  }

  // annual number bar series
  getNumberBarSerie({ barColor, showLabel = false }) {
    return {
      type: 'bar',
      emphasis: {
        disabled: true,
      },
      barMinHeight: 3,
      itemStyle: {
      },
      label: {
        show: showLabel,
        color: '#FFFFFF',
        verticalAlign: 'middle',
        // fontSize: 7,
        fontSize: this.getLabelFontSize(),
        fontWeight: 500,
      },
      color: barColor,
      barWidth: this.getBarWidth(),
    };
  }

  // current year bar serie
  getHighlightedNumberBarSerie({ barColor }) {
    return {
      type: 'bar',
      emphasis: {
        disabled: true,
      },
      barMinHeight: 3,
      itemStyle: {
      },
      label: {
        show: true,
        color: '#FFFFFF',
        verticalAlign: 'middle',
        // fontSize: 7,
        fontSize: this.getLabelFontSize(),
        fontWeight: 500,
        formatter: params => {
          // console.log('===params===', params);
          if (params.data[3] === 0) {
            return '';
          }
          return params.data[3];
        },
      },
      color: barColor,
      barWidth: this.getBarWidth(),
      barGap: this.getBarGap(),
    };
  }

  // get FY increasing rate bar serie - invisible
  getRateBarSerie() {
    return {
      type: 'bar',
      emphasis: {
        disabled: true,
      },
      tooltip: {
        show: false, // hide tooltip
      },
      color: 'rgba(0,0,0,0)',
      barMaxWidth: 1,
      z: 2, // cover first two bars, but under 3rd bar
      encode: {
        y: 3, // data source for rate
      },
    };
  }

  // get FY increasing rate bar serie - invisible
  getRateBarSerieLabel({ backgroundColor, titleColor, iconImg, formatter }) {
    return {
      show: true,
      verticalAlign: 'top',
      backgroundColor,
      borderRadius: 1,
      height: RATE_LABEL_HEIGHT,
      width: RATE_LABEL_WIDTH,
      padding: [0, 2, 0, 2],
      lineHeight: 15,
      formatter,
      rich: {
        title: {
          color: titleColor,
          padding: [10, 0, 1, 2],
          fontSize: 12,
          lineHeight: 15,
          verticalAlign: 'middle',
        },
        icon: {
          align: 'middle',
          verticalAlign: 'bottom',
          backgroundColor: {
            image: iconImg,
          },
          height: 9.05,
          width: 10.45,
        },
        text: {
          color: titleColor,
          padding: [10, 0, 1, 2],
          fontSize: 12,
          lineHeight: 15,
          verticalAlign: 'middle',
          align: 'center',
        },
      },
      position: [this.getRateLabelPositionX(), -(RATE_LABEL_HEIGHT + 1)]
    };
  }

  getBarColors() {
    const type = this.type;

    let barColors = [...BAR_COLOR[0]]; // submission,
    if (type === ACCEPTANCE.toLowerCase()) {
      barColors = [...BAR_COLOR[1]]; // acceptance
    } else if (type === PUBLICATION.toLowerCase()) {
      barColors = [...BAR_COLOR[2]]; // publication
    }

    return barColors;
  }

  /**
   * overwrite the default getBarSeries method
   */
  getBarSeries() {
    const barColors = this.getBarColors();

    return [
      // 1st
      this.getNumberBarSerie({ barColor: barColors[0] }),
      this.getNumberBarSerie({ barColor: barColors[1] }),
      this.getHighlightedNumberBarSerie({ barColor: barColors[2] }),
      {
        // increase rate bar
        ...this.getRateBarSerie(),
        label: {
          ...this.getRateBarSerieLabel({
            backgroundColor: INCREASE_LABEL_BG_COLOR,
            titleColor: INCREASE_LABEL_COLOR,
            iconImg: UpperArrowIcon,
            formatter: params => {
              const isShow = getNumber(params.data[5]) > 0;
              if (isShow) {
                const displayNumber = params.data[5];
                return `{icon|}{title|${displayNumber}}`;
              } else {
                return '';
              }
            },
          }),
        },
      },
      {
        // decrease rate bar
        ...this.getRateBarSerie(),
        label: {
          ...this.getRateBarSerieLabel({
            backgroundColor: DECREASE_LABEL_BG_COLOR,
            titleColor: DECREASE_LABEL_COLOR,
            iconImg: DownArrowIcon,
            formatter: params => {
              const isShow = getNumber(params.data[5]) < 0;
              if (isShow) {
                const displayNumber = params.data[5];
                return `{icon|}{title|${displayNumber}}`;
              } else {
                return '';
              }
            },
          }),
        },
      },
      {
        // equal rate bar
        ...this.getRateBarSerie(),
        label: {
          ...this.getRateBarSerieLabel({
            backgroundColor: EQUAL_LABEL_BG_COLOR,
            titleColor: EQUAL_LABEL_COLOR,
            iconImg: MinusSignIcon,
            formatter: params => {
              const isShow = getNumber(params.data[5]) === 0;
              if (isShow) {
                return `{icon|}`;
              } else {
                return '';
              }
            },
          }),
        },
      },
      {
        // N/A bar
        ...this.getRateBarSerie(),
        label: {
          ...this.getRateBarSerieLabel({
            backgroundColor: EQUAL_LABEL_BG_COLOR,
            titleColor: NA_LABEL_COLOR,
            formatter: params => {
              if (params.data[5] === null && getNumber(params.data[3]) !== 0) {
                return `{text|N/A}`;
              } else {
                return '';
              }
            },
          }),
        },
      },
    ];
  }

  /**
   * calculate the bar width
   * variants: Subject group/ Business division
   * variants: number of subject groups
   */
  getBarWidth() {
    // is business division
    if (!this.isSubjectGroup) {
      return BD_BAR_WIDTH;
    }

    // use linear function to get the width of bars for subject groups
    const maxWidth = SG_BAR_WIDTH_MAX;
    const groupWidth =
      (this.allBarsWidth - BAR_GROUP_GAP * (this.numberOfSG - 1)) /
      this.numberOfSG;
    let barWidth = (groupWidth + 10 * 2) / 3;
    // apply the max constraints
    barWidth = barWidth > maxWidth ? maxWidth : barWidth;

    return barWidth;
  }


  /**
   * calculate the bar label font size
   * variants: Subject group/ Business division
   * variants: number of subject groups
   */
  // todo: size is according to bar width only
  getLabelFontSize() {
    // is business division
    if (!this.isSubjectGroup) {
      return BD_BAR_LABEL_FONT_SIZE;
    }

    // is subject group
    let fontSize = 0;

    let barWidth = this.getBarWidth();
    fontSize = barWidth > BAR_WIDTH_THRESHOLD_FOR_FONT_SIZE ? SG_BAR_LABEL_FONT_SIZE[1] : SG_BAR_LABEL_FONT_SIZE[0];

    return fontSize;
  }

  /**
   *  get rate label position
   */
  getRateLabelPositionX() {
    let barWidth = this.getBarWidth();
    
    let positionX = this.barOverlapDistance - (RATE_LABEL_WIDTH + barWidth)/2;

    return positionX;
  }

  /**
   * set the number of Subject groups
   */
  setNumOfSG(numOfSG) {
    // console.log('setting numOfSG...', numOfSG);
    this.numberOfSG = numOfSG;
  }

  /**
   * overwrite the default getTooltip method
   */
  getTooltip() {
    const tooltip = super.getTooltip();
    // overwrite formatter
    const formatter = params => {
      // console.log("overwrite tooltip")
      // console.log(params);
      let dataStr = `<div style="padding: 0; margin: 0;">
        <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 0 0 2.5px 0;">${
          params.name
        }</p>
        <p style="font-size: 10px; font-weight: 400;color: #848484; line-height: 14px; margin: 0;">${
          params.seriesName
        }&nbsp;:&nbsp;${params.data[params.seriesIndex + 1]}</p>
      </div>`;
      return dataStr;
    };

    let newTooltip = { ...tooltip, formatter };
    return newTooltip;
  }

  /**
   * overwrite the default method
   */
  getCategoryAxisLabel() {
    const baseAxisLabel = super.getCategoryAxisLabel();

    const newAxisLabel = {
      ...baseAxisLabel,
      width: this.getXAxisLabelWidth(),
      overflow: this.isDownload ? 'break' : 'truncate', // If download, break label text into lines
    };

    return newAxisLabel;
  }

  getXAxisLabelWidth(){
    const barWidth = this.getBarWidth();
    return barWidth * 3 * 0.85;
  }

}

const getNumber = str => {
  return parseFloat(str) / 100;
};


export const stageList = [
  'Idea/Proposal',
  'Acquired',
  'Paper Commission',
  'Submission and Review',
  'Production',
  'Published',
  'Shelved',
];

export const activeStageList = [
  'Acquired',
  'Paper Commission',
  'Submission and Review',
  'Production',
];

export const groupList = [
  'CAT LS',
  'CAT HS',
  'CAT PS-SSH',
  'CAT General',
  'JPM',
  'SED',
  'CR',
  'CT',
  'Others',
];

export const alertList = [
  'Submission Deadline Passed',
  'Expected Acceptance Date Passed',
  'Expected Publication Date Passed',
  'horizontal line',
  'Severe Issue',
  'Mild Issue',
];

export const alertListWithoutline = [
  'Submission Deadline Passed',
  'Expected Acceptance Date Passed',
  'Expected Publication Date Passed',
  'Severe Issue',
  'Mild Issue',
];

export const unsolicitedFieldList = [
  'preferredSubjectArea',
  'preferredJournal',
  'region',
];

export const solicitedFieldList = [
  'preferredJournal',
  'region',
];

export function getLabelByField(field) {
  switch (field) {
    case 'preferredSubjectArea':
      return 'Preferred Subject Area';
    case 'preferredJournal':
      return 'Preferred Journal';
    case 'region':
      return 'GE Country/Region';
  }
};

export function getLabelByFieldSSIP(field) {
  switch (field) {
    case 'preferredSubjectArea':
      return 'Preferred Subject Area';
    case 'preferredJournal':
      return 'Preferred Journal';
    case 'region':
      return 'GE Country/Region';
  }
};

export function getFieldByLabel(label) {
  switch (label) {
    case 'Preferred Subject Area':
      return 'preferredSubjectArea';
    case 'Preferred Journal':
      return 'preferredJournal';
    case 'CE Country/Region':
      return 'region';
    case 'GE Country/Region':
      return 'geRegion';
  }
};

export const getThemeColor = theme => {
  return {
    first: '#DFE4E8',
    second: '#DFE4E8',
    third: '#DFE4E8',
  };
};
export function getThemeByField(field) {
  return 'default';
};
export function calcDropListHeight(len,height,gap,other){
  let num = len < 6 ? len : 6;

  return (height + gap) * (num -1) + height + other;
};

export function getPlaceHolderByLabel(label){
  return `Enter/Select ${label}`;
};
import {
  LOADING,
  ERROR,
  VALID,
  NO_DATA,
  NO_SELECTION_FROM_DROPDOWN_MENU,
  NO_DISPLAYED_DATA,
} from '@/pages/Report/chartUtils/constants.js';

export default function useGetDiagramStatusHook(
  chartData,
  filteredChartData,
  selectedCatagories,
  valueAttributeNames,
  hasCategoryFilter,
  alertLabel,
  loading,
  error
) {
  let valid = VALID;
  let info = null;

  // console.log('==chartData=', chartData);
  // console.log('==valueAttributeNames=', valueAttributeNames);
  if (loading) {
    valid = LOADING;
  } else if (error) {
    valid = ERROR;
  } else if (
    !Array.isArray(chartData) ||
    !isValid(chartData, valueAttributeNames)
  ) {
    valid = NO_DATA;
    info = alertLabel;
  } else if (
    hasCategoryFilter &&
    Array.isArray(selectedCatagories) &&
    selectedCatagories.length === 0
  ) {
    valid = NO_SELECTION_FROM_DROPDOWN_MENU;
    info = alertLabel;
  } else if (!isValid(filteredChartData, valueAttributeNames)) {
    valid = NO_DISPLAYED_DATA;
    info = alertLabel;
  }

  return {
    valid,
    info,
  };
}

/**
 * check if there is any data for selected certeria
 * @param {chartData, attributeNames}
 * @returns boolean
 */
const isValid = (chartData, attributeNames) => {
  // console.log('===chartData===', chartData);
  // console.log('===attributeNames===', attributeNames);
  if (!Array.isArray(chartData) || chartData?.length === 0) {
    return false;
  }

  if (!Array.isArray(attributeNames) || attributeNames?.length === 0) {
    return false;
  }

  let valid = false;

  for (let category of chartData) {
    attributeNames.forEach(attr => {
      const value = parseFloat(category[attr]);
      if (!Number.isNaN(value) && value !== 0) {
        valid = true;
      }
    });
  }

  // console.log('===valid===', valid);

  return valid;
};

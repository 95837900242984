import React, { useState, useEffect } from 'react';
import BasicPage from './BasicPage';
import { useDispatch, useSelector } from 'react-redux';
import { setPermission } from '@/actions/SIMT-User/Role/RoleManageAction';
import {getPermissionList} from "@/actions/SIMT-User/Role";

function CreateRole(props) {
  const dispatch = useDispatch();

  const { permissionList } = useSelector(state => {
    return {
      permissionList: state.Role.permissionList,
    };
  });

  useEffect(() => {
    dispatch(setPermission('left', permissionList, false));
    dispatch(setPermission('right', []));
    dispatch(setPermission('originalLeft', permissionList, false));
    dispatch(setPermission('originalRight', []));
  }, [permissionList]);

  useEffect(() => {
    dispatch(setPermission('left', []));
    dispatch(setPermission('right', []));
    async function fetchData() {
      await dispatch(getPermissionList());
    }
    fetchData();
  }, []);

  // useEffect(() => {
  //   setInitNotAuthList(initNotAuthList);
  // }, [initNotAuthList]);

  return (
    <BasicPage
      id='roleManageCreatRoleBasicPage'
      data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-CreateRolePage-BasicPage'
      PageTitle='Create Role'
      ActionBtn='CREATE'
      RoleName={''}
    />
  );
}

export default CreateRole;

import * as actions from '../actions/SideBarAction';

const initState = {
  navigationState: {
    detailsList: [
      { index: 0, label: 'commonSiderUserManagement', active: true },
      { index: 1, label: 'commonSiderRoleManagement', active: false },
      { index: 2, label: 'commonSiderSystemLog', active: false },
      { index: 3, label: 'commonSiderSystemConfiguration', active: false },
      { index: 4, label: 'commonSiderEmailTemplates', active: false },
      { index: 5, label: 'commonSiderAnnualTarget', active: false },
      { index: 6, label: 'commonSiderReportJobs', active: false },
      { index: 7, label: 'commonSiderExportMarketingData', active: false },
      { index: 8, label: 'commonSiderOverview', active: false },
      { index: 9, label: 'commonSiderGuestEditor', active: false },
      { index: 10, label: 'commonSiderSIPOverview', active: false },
      {
        index: 11,
        label:
          'commonSiderGuestEditor' /*可能有错误，我直接按照sidebar里面的id写的*/,
        active: false,
      },
      { index: 12, label: 'commonSiderSIPipeline', active: false },
      { index: 13, label: 'commonSiderAcquiredAndActive', active: false },
      { index: 14, label: 'commonSiderArticls', active: false },
      { index: 15, label: 'commonSiderJournal', active: false },
      { index: 16, label: 'commonSiderTarget', active: false },
      { index: 17, label: 'commonSiderPRPContacts', active: false },
      { index: 18, label: 'commonSiderCATJournal', active: false },
      { index: 19, label: 'commonSiderCMOverview', active: false},
      { index: 20, label: 'commonSiderCMGuestEditor', active: false},
    ],
    activeIndex: 0,
  },
  reportIndex: 12,
  Updown:{
    commonSiderUserAndSystem:true,
    commonSiderEmail:true,
    commonSiderSpecialIssue:true,
    commonSiderSpecialIssueProposal:true,
    commonSiderReport:true,
    commonSiderJournal:true,
    commonSiderChinaMarketing:true,
  },
  menuHeightReduce: 0,
};

export default function SIReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.SET_NAVIGATIONSTATE:
      return {
        ...state,
        navigationState: {
          ...state.navigationState,
          ...data,
        },
      };
    case actions.SET_MENU_HEIGHT_REDUCE:
      return {
        ...state,
        menuHeightReduce: data,
      };
    case actions.SET_UPDOWN:
      return {
        ...state,
        Updown: data,
      };
    default:
      return state;
  }
}

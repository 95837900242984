import { loadSpecialCharEditing } from "./SpecialCharCommand";
import { SC_PLUGIN_NAME } from "./SpecialCharToolbarUI";
import loadSpecialCharToolbarUI from "./SpecialCharToolbarUI";

export function loadSpecialCharPlugin(EditorModule){
  const { Plugin } = EditorModule.loadCore();
  const Editing = loadSpecialCharEditing(EditorModule);
  const ToolbarUI = loadSpecialCharToolbarUI(EditorModule);

  return class SpecialCharPlugin extends Plugin {
    static get requires() {
      return [ Editing, ToolbarUI ];
    }
    static get pluginName() {
      return SC_PLUGIN_NAME;
    }
  };
}

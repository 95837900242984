import React from 'react';
import { makeStyles } from '@mui/styles';
import ExportDownloading from '../assets/ExportDownloading.svg';

const useStyles = makeStyles({
  rotatingIcon: {
    animation: '$rotate 1s linear infinite', // Apply the rotation animation
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)', // Start from 0 degrees
    },
    '100%': {
      transform: 'rotate(360deg)', // End at 360 degrees (1 full rotation)
    },
  },
});

const RotatingSVG = () => {
  const classes = useStyles();

  return (
    <ExportDownloading className={classes.rotatingIcon}  style={{width: '18px', height:'18px'}}/>
  );
};

export default RotatingSVG;

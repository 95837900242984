import {
  Box,
  Dialog,
  Stack,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { H4Font } from '@/components/FontStyle';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { HandlingCEAutoCompleteWithoutTitle } from '@/pages/SIPPage/SIPDetailPage/DecisionSection/HandlingCEAutoCompleteWithoutTitle';
import { StateButton } from '@/componentsphase2/StateButton';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import DrawerCloseButton from '@/componentsphase2/DrawerCloseButton';
import { set } from 'date-fns';
import {approveOptIn} from "@/actions/SIMT-SI/CatJournal/OptIn";
import {getCE, getScam} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueSystemAdmin";

export const MyHandleCEAutoComplete = styled(HandlingCEAutoCompleteWithoutTitle)(({ theme }) => ({
  '& .MuiInputBase-root.MuiOutlinedInput-root, & .MuiInputBase-root.MuiOutlinedInput-root:hover, & .MuiInputBase-root.MuiOutlinedInput-root:focus': {
    width: '400px',
    height: '33px',
  },
}));

export default function ApprovePopUp(props) {
  const {
    open = true, //控制popup的打开和关闭
    setOpen = () => {
    },
    journalCode,
    handlingCe,
    page,
    pageSize,
  } = props;

  const intl = useIntl();

  const dispatch = useDispatch();

  const [scalEmptyError, setScalEmptyError] = useState(false);
  const [scamEmptyError, setScamEmptyError] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [handlingCEId, setHandlingCEId] = useState(false);
  const [handlingCENameAndEmail, setHandlingCENameAndEmail] = useState(null);
  const [scamNameAndEmail, setScamNameAndEmail]  = useState(null); 
  const [handlingCEEmail, setHandingCEEmail] = useState(null);
  const [scamEmail,setScamEmail] = useState(null);
  const [useCEData, setUseCEData] = useState([]);
  const [useScamData, setUseScamData] = useState([]);

  const {CEData,
  ScamData} = useSelector(state => {
    return {
      CEData: state.SI.CE,
      ScamData: state.SI.Scam,
    };
  });

  useEffect(() => {
    // CEData有时候的值很奇怪，所以
    if (CEData && CEData !== 'error' && CEData !== 'null') {
      // 好像还有可能是null字符串，也要避免
      setUseCEData(CEData); // 正常，设置为返回的值
    } else {
      setUseCEData([]); // 出错，设置为空值
    }
  }, [CEData]);
  useEffect(() => {
    if (ScamData && ScamData !== 'error' && ScamData !== 'null') {
      // 好像还有可能是null字符串，也要避免
      setUseScamData(ScamData); // 正常，设置为返回的值
    } else {
      setUseScamData([]); // 出错，设置为空值
    }
  }, [ScamData]);

  useEffect(() => {
    // 如果打开的不是同一个popup，所以每次open该案的时候都要检测一下
    if (open) {
      // tmpEmail = tmpEmail.split(')')[0]; // 取出email
      setHandlingCENameAndEmail(handlingCe);
      setHandingCEEmail(handlingCe?.email);
    }
  }, [open]);

  useEffect(() => {
    setScalEmptyError(isNext && !hasValue(handlingCENameAndEmail));
  }, [handlingCENameAndEmail]);

  useEffect(() => {
    setScamEmptyError(isNext && !hasValue(scamNameAndEmail));
  }, [scamNameAndEmail]);

  function hasValue(value) {
    if (value && value != 'undefined') {
      if (value instanceof Array) {
        // 数组情况，长度非0， 零号元素非空
        return value.length > 0 && value[0] && value[0] !== '';
      } else return value !== '';
    } else {
      return false;
    }
  }

  const clickCancel = e => {
    setOpen(false);
    setIsNext(false);
    setScalEmptyError(false);
    setScamEmptyError(false);
    setScamNameAndEmail('');
  };

  useEffect(() => {
    setHandingCEEmail(handlingCENameAndEmail?.email ?? null);
  }, [handlingCENameAndEmail]);
  useEffect(() => {
    setScamEmail(scamNameAndEmail?.email ?? null);
  }, [scamNameAndEmail]);

  const clickSave = e => {
    e.preventDefault();
    setIsNext(true);
    setScalEmptyError(!hasValue(handlingCEEmail));
    setScamEmptyError(!hasValue(scamEmail));
    if (!hasValue(handlingCEEmail)||!hasValue(scamEmail)) {
      return;
    } else {
      dispatch(
        approveOptIn(null, handlingCEEmail,scamEmail, journalCode, page, pageSize)
      );
      setOpen(false);//close the window
    }
  };

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            boxShadow: 'none',
          },
        }}
        open={open}
        onClose={clickCancel}
        sx={{
          // background: 'rgba(9, 30, 66, 0.54)',
          '& .MuiDialog-paper': {
            width: '600px',
            minWidth: '524px',
            minHeight: '248px',
            margin: 0,
            padding: '24px 32px 0px 32px',
            boxSizing: 'border-box',
          },
          '.css-hz1bth-MuiDialog-container': {
            // background: 'rgba(9, 30, 66, 0.54)',
          },
          '& .MuiBackdrop-root': {
            background: 'rgba(17, 61,149, 0.5)',
            backdropFilter: 'blur(4px)',

          },
        }}
        data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-Dialog'
      >
        {/* Title and Close Button*/}
        <Box
          sx={{height: '24px', marginBottom: '16px'}}
          data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-Box'
        >
          <Box
            sx={{
              ...H4Font,
              height: '23px',
              float: 'left',
              color: '#262E35',
            }}
            data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-Box-Box'
          >
            {intl.messages['optIn.approvePopUpTitle']}
          </Box>
          <Box sx={{float: 'right', cursor: 'pointer', padding: '0px'}}>
            <DrawerCloseButton
              data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-IconButton'
              onClick={clickCancel}
            />
          </Box>
        </Box>

        <Stack
          direction='row'
          // spacing={0.25}
          // sx={{ padding: '0px', height: '34px' }}
          // alignItems='center'
          data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-Stack1'
        >
          {/* Select Input Box Label */}
          <Stack
            direction='row'
            sx={{width: '135px'}}
            data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-Stack1-RowStack'
          >
            <Box
              style={{
                height: '20px',
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px',
                textTransform: 'capitalize',
                color: '#596A7C',
              }}
              data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-Stack1-Box1'
            >
                            SCA Lead
            </Box>
            <Box
              style={{
                width: '8px',
                height: '18px',
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '18px',
                letterSpacing: '0.08em',
                color: '#C40000',
                marginLeft: '2px',
              }}
              data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-Stack1-Box2'
            >
                            *
            </Box>
          </Stack>
          {/*SCA Lead Input Box */}
          <Stack
            style={{float: 'right'}}
            direction='column'
            width='400px'
            height='56px'
            data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-Stack1-ColumnStack'
          >
            <Box>
              <SinpleAutocomplete
                data-selenium-id='CATJournal_Overview-OptInTable-EditAndSubmitPopUp-handingCESelectBox'
                value={handlingCENameAndEmail}
                onChange={(e, value) => {
                  setHandlingCENameAndEmail(value || '');
                }}
                options={useCEData}
                getOptionLabel={option => `${option?.fullName ?? option.username}` + ' (' + option.email + ')'}
                // config={{popupIcon : null}}
                defaultQuery={false}
                loadOptionAction={getCE}
                isEmpty={!handlingCENameAndEmail}
                isErr={scalEmptyError}
                setError={setScalEmptyError}
                getInputValueLabel={option => option.email}
              />
              
            </Box>
            {/* Error Message */}
            {scalEmptyError ? (
              <Box
                sx={{
                  color: '#EE5350',
                  fontFamily: ' Open Sans',
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '16px',
                  letterSpacing: '-0.08399999886751175px',
                  textAlign: 'left',
                }}
                data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-Stack1-EmptyErrorBox'
              >
                                This field is mandatory to input!
              </Box>
            ) : (
              <></>
            )}
          </Stack>
          
        </Stack>

        <Stack
          direction='row'
          // spacing={0.25}
          // sx={{ padding: '0px', height: '34px' }}
          // alignItems='center'
          data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-Stack1'
        >
          {/* Select Input Box Label */}
          <Stack
            direction='row'
            sx={{width: '135px'}}
            data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-Stack2-RowStack'
          >
            <Box
              style={{
                height: '20px',
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px',
                textTransform: 'capitalize',
                color: '#596A7C',
              }}
              data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-Stack2-Box1'
            >
                            SCA Manager
            </Box>
            <Box
              style={{
                width: '8px',
                height: '18px',
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '18px',
                letterSpacing: '0.08em',
                color: '#C40000',
                marginLeft: '2px',
              }}
              data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-Stack2-Box2'
            >
                            *
            </Box>
          </Stack>
          {/*SCA Manager Input Box */}
        <Stack
            style={{float: 'right'}}
            direction='column'
            width='400px'
            height='56px'
            data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-Stack2-ColumnStack'
          >
            <Box>
              <SinpleAutocomplete
                data-selenium-id='CATJournal_Overview-OptInTable-EditAndSubmitPopUp-ScamSelectBox'
                value={scamNameAndEmail}
                onChange={(e, value) => {
                  setScamNameAndEmail(value || '');
                }}
                options={useScamData}
                getOptionLabel={option => `${option?.fullName ?? option.username}` + ' (' + option.email + ')'}
                // config={{popupIcon : null}}
                defaultQuery={false}
                loadOptionAction={getScam}
                isEmpty={!scamNameAndEmail}
                isErr={scamEmptyError}
                setError={setScamEmptyError}
                getInputValueLabel={option => option.email}
              />
            </Box>
            {/* Error Message */}
            {scamEmptyError ? (
              <Box
                sx={{
                  color: '#EE5350',
                  fontFamily: ' Open Sans',
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '16px',
                  letterSpacing: '-0.08399999886751175px',
                  textAlign: 'left',
                }}
                data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-Stack1-EmptyErrorBox'
              >
                                This field is mandatory to input!
              </Box>
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
        {/* Buttons */}
        <Stack
          direction='row-reverse'
          sx={{
            marginTop: '12px',
            gap: '6px'
          }}
          data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-Stack2'
        >
          <StateButton
            id='ApprovePopUpSaveButton'
            data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-ApprovePopUpSaveButton'
            isPrimary={true}
            onClick={clickSave}
            sx={{
              width: '70px', '&:disabled': {
                backgroundColor: '#BCC5CF',
                color: '#FFFFFF',
              },
            }}
            titleName='Submit'
            disabled={scalEmptyError||scamEmptyError}
          >
          </StateButton>
          <StateButton
            id='ApprovePopUpCancelButton'
            data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp-ApprovePopUpCancelButton'
            isPrimary={false}
            onClick={clickCancel}
            sx={{width: '70px'}}
            titleName='Cancel'
          >
          </StateButton>
        </Stack>
      </Dialog>
    </>
  );
}

export function trimItemFilds(itemFilds) {
  if (itemFilds === '' || itemFilds === null || itemFilds === undefined)
    return '';
  let newItemFilds = itemFilds.trim();
  let regEx = /\s+/g;
  return newItemFilds.replace(regEx, ' ').trim();
}
import React, {ReactElement} from "react";
import {Box} from "@mui/material";
import SectionTitle from "@/pages/SIPPage/SIPCheckListPage/compontents/SectionTitle";
import BoxWithShadow from "@/pages/SIPPage/SIPCheckListPage/compontents/BoxWithShadow";
import {extractHtmlId, FormComponentProps, SubForm} from "@/components/Form/form.d";

export interface SubFormProps extends FormComponentProps {
    title?: string;
    children?: ReactElement | ReactElement[];
}

export function SubFormWrapper(props: SubFormProps): ReactElement{
    const htmlId = extractHtmlId(props);

    return (
        <Box
            id={htmlId}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '17px',
            }}
        >
            <SectionTitle>{props.title}</SectionTitle>
            <BoxWithShadow>
                <SubForm formKey={props.name}>
                    {props.children}
                </SubForm>
            </BoxWithShadow>
        </Box>
    );
}
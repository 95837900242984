import * as React from 'react';
import PropTypes from 'prop-types';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { defaultVars, ErrVars } from './commonStyle';

import { CircularProgress } from '@mui/material';

const Root = styled('div')(
  ({ theme }) => `
  color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
  };
  font-size: 14px;
  width: inherit;
  label + & {
    margin-top: ${theme.spacing(2)} ;
`
);

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  border: 2px solid var(--color);
  background-color:#FFFFFF;
  border-radius: 3px;
  padding: 7px 8px 7px 8px;
  display: flex;
  flex-wrap: wrap;
  box-sizing:border-box;
  &.focused {
    border: var(--focus-border);
  }
  & input {
    background-color: #FFFFFF;
    height: 36px;
    box-sizing: border-box;
    padding: 8px 0px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
  }
`
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 36px;
  margin: 2px;
  background-color: #F1F3F5;
  border: 1px solid #DFE4E8;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 8px 12px;
  outline: 0;
  overflow: hidden;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const Listbox = styled('ul')(
  ({ theme }) => `
  width: inherit;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

export default function AutocompleteMuiltiple(props) {
  const {
    Error,
    id,
    defaultValue,
    options,
    getOptionLabel,
    onInputChange,
    onChange,
    Value,
    onLoading,
  } = props;

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
    popupOpen,
  } = useAutocomplete({
    id: id,
    defaultValue: [defaultValue],
    multiple: true,
    options: options,
    getOptionLabel: getOptionLabel,
    onChange: onChange,
    onInputChange: onInputChange,
    filterSelectedOptions: true,
    value: Value,
  });

  const [vars, setVars] = React.useState(defaultVars);
  React.useEffect(() => {
    setVars(Error ? ErrVars : defaultVars);
  }, [Error]);

  return (
    <Root>
      <div {...getRootProps()}>
        <InputWrapper
          ref={setAnchorEl}
          className={focused ? 'focused' : ''}
          style={vars}
          onFocus={props.onFocus ? props.onFocus : false}
        >
          {value.map((option, index) => (
            <StyledTag
              label={getOptionProps({ option, index }).key}
              {...getTagProps({ index })}
            />
          ))}

          <input {...getInputProps()} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <div style={{ width: '100%' }}>
          <Listbox {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <li {...getOptionProps({ option, index })}>
                <span>{getOptionProps({ option, index }).key}</span>
              </li>
            ))}
          </Listbox>
        </div>
      ) : (
        popupOpen &&
        focused &&
        onLoading && (
          <div style={{ width: '100%' }}>
            <Listbox style={{ height: '35px' }}>
              <CircularProgress size='24px' style={{ marginLeft: '45%' }} />
            </Listbox>
          </div>
        )
      )}
    </Root>
  );
}

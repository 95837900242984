import {
    CardContent,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Link,
    MenuItem,
    Select,
    Stack,
    SvgIcon,
    Typography,
    useMediaQuery
} from "@mui/material";
import MandatoryIcon from "@/assets/MandatoryIcon.svg";
import { Box } from "@mui/system";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MildIssueIcon from "@/assets/MildIssueIcon.svg";
import SevereIssueIcon from "@/assets/SevereIssueIcon.svg";
import UploadIcon2 from "@/assets/uploadIcon2.svg";
import { GetLabel } from "@/components/FileUploader/LabelFile";
import { GetProgress } from "@/components/FileUploader/ProgressFile";
import Warning500_6 from "@/assets/Warning500_6.svg";
import Error500_6 from "@/assets/Error500_6.svg";
import Off from "@/assets/AlertGeOff.svg";
import { setGeItemValue, setIsFlagChanged } from "@/actions/SIMT-SIP/SIPChecklistSubmitted/SIPChecklistAction";
import axios from "axios";
import SelectArrow from "@/assets/SelectArrow.svg";
import SelectedArrow from "@/assets/SelectedArrow.svg";
import { IconButtonNewStyle } from "@/pages/GEPage/GEDetailPage/FlagBlockAdd";
import MyOnlyText from "@/componentsphase2/MyOnlyText";
import TextFieldErrorIcon from "@/assets/TextFieldErrorIcon.svg";
import ViewMoreDownArrow from "@/assets/ViewMoreDownArrow.svg";
import styleModule from "@/pages/SIPage/SIDetailPage/GeneralInfoSection.module.scss";
import { measureWidth } from "@/utils/StringWidthMeasure";
import { formatFileSize, GIFIcon, JPGIcon, PDFIcon, WordIcon } from "@/pages/GEPage/GEDetailPage/FlagBlock";
import { FlaggedGESynchronizationAndVerificationTable } from "../../../../componentsphase2/FlaggedGESynchronizationAndVerificationTable";
import {getFlaglist} from "@/actions/SIMT-SI/GuestEditor/Config";
import {downloadSingleGEFile} from "@/actions/SIMT-SI/GuestEditor/SipProposerFlag";
import {uploadFile} from "@/actions/SIMT-SIP/SIPChecklistSubmitted/SipProposerFlag";

export const ChooseIconByType = fileType => {
    if (fileType === 'application/pdf' || fileType === 'pdf') return PDFIcon; else if (fileType === 'application/msword' || fileType === 'doc' || fileType === 'docx' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') return WordIcon; else if (fileType === 'image/gif' || fileType === 'gif') return GIFIcon; else if (fileType === 'image/jpeg' || fileType === 'jpg') return JPGIcon; else return JPGIcon;
};
export const FileUpload = ({onFileChange, isSevere, initialValue, disable=false, submitProposerVO=null, proceeding}) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [iserrorMessage, setIsErrorMessage] = useState(false);
    const [isSizeError, setIsSizeError] = useState(false);
    const [isDeleteError, setIsDeleteError] = useState(false);
    const [fileProgress, setFileProgress] = useState([]);
    const [progressUpload, setProgressUpload] = useState({});
    const [cancelTokenList, setCancelTokenList] = useState({});
    const [upfileName, setUpfileName] = useState({});
    // -1 delete 0 uploading 1 exist
    const [isUpload, setIsUpload] = useState({});
    const [numerr, setNumerr] = useState(false);

    const fileUploadSize = Object.keys(isUpload).filter(x => isUpload[x] !== -1)?.length ?? 0;
    const hasFileUploaded = fileUploadSize > 0;
    // useEffect(() => {
    //     if (buttonCheck&&iserrorMessage&&((selectedFiles === undefined || selectedFiles?.length === 0) && isSevere[Fileindex] && (initialValue === undefined || initialValue?.length === 0))){
    //          setFlagState('error');}
    // }, [iserrorMessage]);
    //初始化
    useEffect(() => {
        const initialIsUpload = {};
        const initialProgress = {};
        const initialUpfileName = {};
        const initialCancelToken = {};
        const updatedFiles = [];
        const source = CancelToken.source();
        if (initialValue !== undefined) {
            for (let i = 0; i < initialValue?.length; i++) {
                initialIsUpload[i] = 1;
                initialProgress[i] = 100;
                initialUpfileName[i] = initialValue[i];
                initialCancelToken[i] = source;

                const fileNameOnly = initialValue[i].split("_").slice(2).join('_');
                const newFile = {
                    name: fileNameOnly.replace(/(.*)(?=\.)/, '$1'),
                    size: formatFileSize(initialValue[i].split('_')[0]),
                    type: initialValue[i].split('.')[initialValue[i].split('.')?.length - 1],
                };
                updatedFiles.push(newFile);
            }
            setSelectedFiles(prevFiles => [...prevFiles, ...updatedFiles]);
        }
        setProgressUpload(initialProgress);
        setIsUpload(initialIsUpload);
        setUpfileName(initialUpfileName);
        setCancelTokenList(initialCancelToken);
    }, []);

    useEffect(() => {
        if ((initialValue?.length??0) == 0){
            setSelectedFiles([]);
            setUpfileName({});
            setIsUpload({});
            setUpfile([]);
            fileInputRef.current.value = null;
            if (proceeding){
                setIsErrorMessage(true);
            }
        }
    }, [initialValue]);

    useEffect(() => {
        if (Object.keys(upfileName).length == 0){
            return;
        }
        for (let i = 0; i < upfile.length; i++) {
            if (isUpload[i]==0 || !upfileName[i]){
                return;
            }
        }

        onFileChange(upfile, upfileName, isUpload);
    }, [upfileName, isUpload]);

    const handleDragOver = event => {
        event.preventDefault();
        setIsDragging(true);
    };
    const handleDrop = event => {
        setIsDeleteError(false);
        if (fileUploadSize < 20) {
            event.preventDefault();
            const files = Array.from(event.dataTransfer.files);
            const index = Object.keys(isUpload)?.length;
            let length=files.length>=20?20:files.length;
            for (let i = 0; i < length; i++) {
                FileUp([files[i]], index + i);
            }
            setIsDragging(false);
        } else {
            setIsErrorMessage(true);
            setNumerr(true);
            setIsDragging(false);
        }
    };

    const [upfile, setUpfile] = useState([]);
    const handleFileChange = (event, index) => {
        setIsDeleteError(false);
        if (fileUploadSize < 20) {
            const files = Array.from(event.target.files);
            let length=files.length>=20?20:files.length;
            for (let i = 0; i < length; i++) {
                FileUp([files[i]], index + i);
            }
            setIsDragging(false);
        } else {
            setIsErrorMessage(true);
            setNumerr(true);
            setIsDragging(false);
        }
        fileInputRef.current.value = null;
    };
    const FileUp = (files, index) => {
        const allow = handleFileUpload(files);
        if (!allow) {
            fileInputRef.current.value = null;
            return;
        }
        let newupfile = upfile;
        newupfile.push(files[0]);
        setUpfile(newupfile);
        const source = CancelToken.source();
        dispatch(uploadFile(files[0], source, percentage => {
            setProgressUpload(prevProgress => {
                let tmpProgress = { ...prevProgress };
                tmpProgress[index] = percentage;
                return tmpProgress;
            });
        }, upfileBack => {
            setIsUpload(prevIsUpload => {
                let tmpIsUpload = { ...prevIsUpload };
                tmpIsUpload[index] = 1;
                return tmpIsUpload;
            });
            setUpfileName(prevUpfileName => {
                let tmpUpfileName = { ...prevUpfileName };
                tmpUpfileName[index] = upfileBack;
                return tmpUpfileName;
            });

        }));
        setIsUpload(prevIsUpload => {
            let tmpIsUpload = { ...prevIsUpload };
            tmpIsUpload[index] = 0;
            return tmpIsUpload;
        });

        setProgressUpload(prevProgress => {
            let tmpProgress = { ...prevProgress };
            tmpProgress[index] = 0;
            return tmpProgress;
        });
        setCancelTokenList(prevCanceltoken => {
            let tmpCanceltoken = { ...prevCanceltoken };
            tmpCanceltoken[index] = source;
            return tmpCanceltoken;
        });
    };

    const handleFileUpload = files => {
        const allowedTypes = ['application/pdf', 'application/msword', 'image/png', 'image/jpeg', 'image/gif', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'PNGimage/png',];
        const allowedSize = 5 * 1024 * 1024; // 5MB
        const updatedFiles = [];
        for (let i = 0; i < files?.length; i++) {
            const file = files[i];
            if (allowedTypes.includes(file.type)) {
                if (file.size <= allowedSize) {
                    const newFile = {
                        name: file.name, size: formatFileSize(file.size), type: file.type,
                    };
                    updatedFiles.push(newFile);
                    setIsErrorMessage(false);
                    setIsSizeError(false);
                } else {
                    setIsErrorMessage(true);
                    setIsSizeError(true);
                    return false;
                }
            } else {
                setIsErrorMessage(true);
                return false;
            }
        }
        setSelectedFiles(prevFiles => [...prevFiles, ...updatedFiles]);
        return true;
    };

    const handleTextClick = () => {
        fileInputRef.current.click();
    };

    const handleDeleteFile = (index, event) => {
        if (isSevere && fileUploadSize <= 1) {
            setIsDeleteError(true);
            setIsErrorMessage(true);
        } else {
            setIsDeleteError(false);
            setIsErrorMessage(false);
            setNumerr(false);
            let tmpIsUpload = JSON.parse(JSON.stringify(isUpload));
            tmpIsUpload[index] = -1;
            setIsUpload(tmpIsUpload);
        }

        
        // FileChange();
    };
    const handleFileDownload = file => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);
        link.download = file.name;
        link.click();
    };

    const handleDownloadAll = () => {
        selectedFiles.forEach(file => {
            handleFileDownload(file);
        });
    };
    const [hovered, setHovered] = useState(false);
    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    useEffect(() => {
        // console.info(isSevere, submitProposerVO);
        if ((selectedFiles === undefined || selectedFiles?.length === 0) && isSevere && (submitProposerVO || proceeding) && (initialValue === undefined || initialValue?.length === 0)) {
            setIsErrorMessage(true);
        } else {
            // console.info("iserrorMessage", iserrorMessage);
        }
    }, [selectedFiles, isSevere, submitProposerVO, proceeding]);

    useEffect(() => {
        if (!isSevere) {
            setIsErrorMessage(false);
        }
    }, [isSevere]);

    const getTypeName = type => {
        if (type === 'application/msword') return 'doc'; else if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') return 'docx'; else if (type === 'PNGimage/png') return 'png'; else return type;
    };

    const CancelToken = axios.CancelToken;

    function abortRead(cancelToken, index) {
        // cancelToken.cancel('Operation canceled by the user.');
        // document.getElementById('hiddenFilesInput').value = null;
        if (progressUpload[index] !== 100 || (progressUpload[index] === 100 && uploadFile[index] === undefined)) {
            let tmpProgress = JSON.parse(JSON.stringify(progressUpload));
            tmpProgress[index] = 0;
            setProgressUpload(tmpProgress);

            let tmpIsUpload = JSON.parse(JSON.stringify(isUpload));
            tmpIsUpload[index] = -1;
            setIsUpload(tmpIsUpload);

            if (progressUpload[index] !== 100) {
                setCancelToken(CancelToken.source());
            }
        }
    }

    const [boxHeight, setBoxHeight] = useState('100px');
    useEffect(() => {
        let sum1 = selectedFiles.filter((file, index) => isUpload[index] === 0)?.length;
        let sum2 = selectedFiles.filter((file, index) => isUpload[index] !== -1)?.length;
        if (sum1 > 0 || sum2 > 0) {
            let length = 100 + (sum2 - 1) * 40 + sum1 * 14;
            setBoxHeight(length + 'px');
        } else {
            setBoxHeight('100px');
        }
    }, [selectedFiles, isUpload]);
    return (<div style={{ position: 'relative',width: '95.7%',maxWidth: '100%', }}>
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            id='file-input'
            className='file-upload'
            style={{
                textAlign: 'center',
                cursor: 'pointer',
                // width: '360px',
                maxWidth: '100%',
                height: boxHeight,
                borderRadius: '4px',
                border: iserrorMessage ? '2px dashed #ccc' : '1px dashed #ccc',
                borderColor: iserrorMessage ? '#C40000' : isDragging || hovered ? '#0052CC' : '#ccc',
                backgroundColor: !isDragging ? iserrorMessage && hovered ? '#FFEFEF' : '#FFFFFF' : iserrorMessage ? '#FFFFFF' : '#CEDDF9',
                padding: '12px 21px',
                // paddingTop:!hasFileUploaded?'14px':'12px',
                // paddingBottom:!hasFileUploaded?'10px':'12px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '4px',
            }}
            onDragOver={handleDragOver}
            onDragLeave={() => setIsDragging(false)}
            onDrop={handleDrop}
        >
            <input
                id='uploadInput'
                type='file'
                accept='.pdf,.doc,.docx,image/png,image/jpeg,image/gif'
                style={{ display: 'none' }}
                onChange={e => handleFileChange(e, Object.keys(isUpload)?.length)}
                ref={fileInputRef}
                multiple={true}
            />
            <Stack direction='row' sx={{ alignItems: 'center', mb: '0px' }}>
                <SvgIcon viewBox='0 0 18 18' sx={{ width: '18px', height: '18px' }}>
                    {' '}
                    <UploadIcon2 sx={{ width: '18px', height: '18px' }}></UploadIcon2>
                </SvgIcon>
                <p
                    style={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: '#596A7C',
                        marginBottom: '0',
                        marginTop: '0',
                        marginLeft: '5px',
                    }}
                >
                    Drag & Drop or&nbsp;
                    <span
                        style={{
                            color: '#154AB6', fontWeight: 400,
                        }}
                        onMouseEnter={e => {
                            e.target.style.color = '#113D95';
                            e.target.style.fontWeight = 600;
                        }}
                        onMouseLeave={e => {
                            e.target.style.color = '#154AB6';
                            e.target.style.fontWeight = 400;
                        }}
                        onClick={handleTextClick}
                    >
                        Choose File
                    </span>{' '}
                    to upload
                </p>
            </Stack>
            {hasFileUploaded ? (selectedFiles.map((file, index) => isUpload[index] !== -1 ? (<>
                {isUpload[index] === 0 ? (<GetProgress
                    sx={{ marginLeft: '0px' }}
                    FileIcon={ChooseIconByType(file.type)}
                    value={progressUpload[index]}
                    abort={() => abortRead(cancelTokenList[index], index)}
                    fileName={file.name}
                    fileSize={file.size}
                    fileType={getTypeName(file.type)}
                />) : (<GetLabel
                    sx={{ marginLeft: '0px' }}
                    FileIcon={ChooseIconByType(file.type)}
                    del={() => handleDeleteFile(index)}
                    fileName={file.name}
                    fileSize={file.size}
                    fileMIMEType={getTypeName(file.type)}
                    isDownLoad={false}
                />)}
            </>) : (<></>))) : (<div>
                {(selectedFiles === undefined || selectedFiles.length === 0) && isSevere && (submitProposerVO || proceeding) && disable ? (<p
                    style={{
                        fontWeight: 400, fontSize: '12px', lineHeight: '15px', color: '#EE5350', margin: 0,
                    }}
                >
                    <span
                        style={{
                            fontWeight: 600,
                        }}
                        onClick={handleTextClick}
                    >
                        Please upload at least one file!
                    </span>
                    <br></br>
                    Supported format:PDF, Word, PNG, JPEG or GIF.{' '}
                    <br></br>Max &nbsp;
                    <span
                        style={{
                            fontWeight: 600,
                        }}
                        onClick={handleTextClick}
                    >
                        5M
                    </span>{' '}
                    each
                </p>) : (<>
                    {iserrorMessage ? (<p
                        style={{
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '15px',
                            color: '#EE5350',
                            margin: -7,
                        }}
                    >
                        <span style={{fontWeight:600}}>Please upload at least one file!</span><br></br>
                        Supported format:PDF, Word, PNG, JPEG or GIF.<br></br>
                        Max
                        <span style={{ fontWeight: 600 }}>{' '}5M</span> size.{' '}
                    </p>) : (<p
                        style={{
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '15px',
                            color: '#98A7B6',
                            margin: 0,
                        }}
                    >
                        Support PDF, Word, PNG, JPEG or GIF.<br></br>
                        Max{' '}
                        <span style={{ fontWeight: 600 }}>5M</span> each.{' '}
                    </p>)}
                </>)}
            </div>)}
            {/* {} */}
        </div>
        <div style={{ 
            // width: '360px',
            maxWidth: '100%', }}>
            {hasFileUploaded && !iserrorMessage ? (<p
                style={{
                    marginTop: 0,
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#596A7C',
                    // width: '360px',
                    maxWidth: '100%',
                }}
            >
                Support PDF, Word, PNG, JPEG or GIF. Max{' '}
                <span style={{ fontWeight: 600 }}>5M</span> each.
            </p>) : null}

            {hasFileUploaded && iserrorMessage ? (numerr ? (<p
                style={{
                    marginTop: 0,
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#EE5350',
                    // width: '360px',
                    maxWidth: '100%',
                }}
            >
                You cannot upload more than 20 evidences
            </p>) : isDeleteError ? (<p
                style={{
                    fontWeight: 400, fontSize: '12px', lineHeight: '15px', color: '#EE5350', marginTop: '0',
                }}
            >
                Please upload at least one file!
            </p>) : (<p
                style={{
                    marginTop: 0,
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#EE5350',
                    // width: '360px',
                    maxWidth: '100%',
                }}
            >
                Support PDF,Word,PNG,JPEG or GIF Max{' '}
                <span style={{ fontWeight: 600 }}>5M</span> each.
            </p>)) : null}
        </div>
    </div>);
};

export const TextInputWithLimit = ({
    onTextChange, initialValue, setHastip = () => {},
}) => {
    const [text, setText] = useState("");
    useEffect(() => {
        setText(initialValue);
    }, []);

    useEffect(() => {
        if (text !== undefined) setHastip(true);
        // onTextChange(text);
    }, [text]);

    const [hasScrollbar, setHasScrollbar] = useState(false);
    const padding1 = '8px 4px 8px 12px';
    const padding2 = '8px 12px 8px 12px';
    const [isFocus, setIsFocus] = useState(false);
    const overflowError = useMemo(() => (isFocus ? false : text?.length > 10000), [text, isFocus]);

    return (<div>
        <MyOnlyText
            value={text}
            setValue={setText}
            width={'286px'}
            maxWidth={'100%'}
            height={'86px'}
            needEndAdornment={false}
            handleIconButtonClick={() => {
                setText('');
            }}
            minRows={5}
            maxRows={4.5}
            placeholder={'Enter comments here'}
            fontSize={12}
            fontColor={'#596A7C'}
            lineHeight={16.34}
            hasScrollbar={hasScrollbar}
            setHasScrollbar={setHasScrollbar}
            textFieldPaddingWithoutEndAdornment={hasScrollbar ? padding1 : padding2}
            isError={overflowError}
            onFocus={() => setIsFocus(true)}
            onBlur={() => {
                setIsFocus(false);
                onTextChange(text);
            }}
        />
        <p
            style={{
                marginTop: 6,
                marginBottom: 0,
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '16.34px',
                color: overflowError ? '#EE5350' : '#596A7C',
                width: '286px',
                maxWidth: '100%',
                textAlign: 'right',
                display: 'flex',
                justifyContent: overflowError ? 'space-between' : 'flex-end',
            }}
        >
            {overflowError && (<div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
                <TextFieldErrorIcon />
                Should no more than 10000.
            </div>)}
            <span>{text?.length+'/10,000'}</span>
        </p>
    </div>);
};
export const ShowComment = ({ comment }) => {
    const [showFullText, setShowFullText] = useState(false);
    function isElipsis(id) {
        let clientWidth = document.getElementById(id).clientWidth;
        let scrollWidth = document.getElementById(id).scrollWidth;
        return clientWidth < scrollWidth; // 说明文字被省略了
    }

    const [showButton, setShowButtom] = useState(false);
    useEffect(() => {
        setShowButtom(isElipsis('showComment'));
    }, [comment]);
    return (<Box
        data-selenium-id='SIPChecklistPage-GEFlagSectiion-ShowComment-Box'
        position={'relative'}
        marginBottom={'10px'}
    >
        <Typography
            id='showComment'
            data-selenium-id='SIPChecklistPage-GEFlagSectiion-ShowComment-Typography'
            variant='body2'
            sx={showFullText ? {
                maxWidth: 286, width: '286px', fontSize: '12px', wordBreak: 'break-word',maxWidth: '100%',
            } : {
                width: '286px',
                maxWidth: '100%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
                fontSize: '12px',
                wordBreak: 'break-word',
            }}
        >
            {comment !== null && comment !== undefined && comment?.length>0? <> {comment}</> : <>-</>}
        </Typography>

        {comment !== null && comment !== undefined && (measureWidth(comment, '12px Open Sans') > 828 || showButton) && (
            <Box
                fontSize={12}
                data-selenium-id='SIPChecklistPage-GEFlagSectiion-showComment-ContainerBox'
                sx={{ paddingTop: '2px' }}
            >
                <Link
                    component='button'
                    onClick={() => setShowFullText(!showFullText)}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#154AB6',
                        textDecoration: 'none',
                        gap: '2px',
                        cursor: 'pointer',
                    }}
                    className={styleModule.ViewAllBox}
                    data-selenium-id='SIPChecklistPage-GEFlagSectiion-showComment-Link1'
                >
                    <Box>{showFullText ? 'Hide' : 'View All'}</Box>
                    <ViewMoreDownArrow
                        {...(showFullText && {
                            className: styleModule.ViewMoreDownArrow,
                        })}
                    />
                </Link>
            </Box>)}
    </Box>);
};

export const ShowFlag = ({ flagType, flagValue }) => {
    let type = flagType;
    if (type !== undefined) type = flagType.split('-')[1];
    return (<Box
        data-selenium-id='SIPChecklistPage-GEFlagSectiion-ShowFlagBox'
        sx={{
            height: '24px',
            backgroundColor: `${type === 'Severe Issue' ? '#FFEFEF' : '#FFF0DD'}`,
            border: `1px solid ${type === 'Severe Issue' ? '#FFB0AE' : '#FFD495'}`,
            borderRadius: '4px',
        }}
    >
        <p
            style={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px',
                fontFamily: 'Open Sans',
                color: `${type === 'Severe Issue' ? '#EE5350' : '#FF8A00'}`,
                marginRight: '11px',
                marginLeft: '12px',
                marginTop: '1px',
            }}
        >
            {flagValue}
        </p>
    </Box>);
};

export const DropdownSelect = ({
    onSelectChange, initialValue, FlagOptions, SevereOptions, error = false
}) => {
    const [selectedValue, setSelectedValue] = useState('');
    const dispatch = useDispatch();
    useEffect(() => {
        if (initialValue && initialValue !== '') {
            setSelectedValue(initialValue);
        }
    }, [initialValue]);

    const handleSelectChange = event => {
        dispatch(setIsFlagChanged(true));
        const newValue = event.target.value;
        setSelectedValue(newValue);
        onSelectChange(newValue);
    };
    const handleClaerState = () => {
        dispatch(setIsFlagChanged(true));
        setSelectedValue('');
        onSelectChange('');
    };

    const renderOptions = () => {
        let currentGroup = null;
        if (FlagOptions !== undefined) {
            return FlagOptions.map((option, index) => {
                const { group, value, icon } = option;
                const isFirstOptionInGroup = group !== currentGroup;
                let renderedOption;
                if (group === 'Divider') {
                    renderedOption = (<Divider
                        data-selenium-id='SIPChecklistPage-GEFlagSectiion-DropdownSelect-Divider'
                        width='91%'
                        variant='middle'
                        light={true}
                    ></Divider>);
                } else {
                    renderedOption = (<MenuItem
                        data-selenium-id='SIPChecklistPage-GEFlagSectiion-DropdownSelect-MenuItem'
                        key={index}
                        value={value}
                        className={isFirstOptionInGroup ? 'first-option' : ''}
                        disabled={isFirstOptionInGroup}
                        sx={{
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '20px',
                            color: '#596A7C',
                            paddingLeft: '30px',
                            paddingTop: '2px',
                            paddingBottom: '2px',
                            '&.Mui-disabled': {
                                color: 'inherit',
                                background: 'transparent',
                                cursor: 'not-allowed',
                                paddingLeft: '0px',
                                opacity: 1,
                            },
                        }}
                    >
                        {isFirstOptionInGroup && icon && (<IconButton
                            sx={{ height: '16px', paddingTop: '16px' }}
                            className={isFirstOptionInGroup ? 'icon-inherit' : ''}
                            disabled
                            data-selenium-id='SIPChecklistPage-GEFlagSectiion-DropdownSelect-IconButton'
                        >
                            {group === 'Category A' ? (<SevereIssueIcon />) : (<MildIssueIcon />)}
                        </IconButton>)}
                        {value}
                    </MenuItem>);
                }
                if (isFirstOptionInGroup) {
                    currentGroup = group;
                }
                return renderedOption;
            });
        } else return [];
    };

    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = () => {
        setIsOpen(true);
        setTimeout(() => {
            let select = document.getElementById('SIPChecklistPage-GEFlagSectiion-DropdownSelect-FormControl');
            let papper = document.getElementById('SIPChecklistPage-GEFlagSectiion-DropdownSelect-Select-PaperProps');
            let bottom = Number(papper.style.marginTop.split('px')[0]) + Number(papper.style.top.split('px')[0]) + Number(papper.style.height.split('px')[0]);
            let selectBottom = select.getBoundingClientRect().top + 33;
            let difHeightOrigin = selectBottom - (Number(papper.style.marginTop.split('px')[0]) + Number(papper.style.top.split('px')[0]));
            let outer = document.getElementById('outer');
            if (window.innerHeight < bottom + 68 + 20) {
                let difHeight = bottom + 68 + 20 - window.innerHeight;
                let scrollTop = outer.scrollTop;
                outer.scrollTo(0, scrollTop + difHeight + difHeightOrigin);
                setTimeout(() => {
                    papper.style.top = `${Number(papper.style.top.split('px')[0]) - difHeight + 6}px`;
                }, 100);
            }
        }, 100);
    };

    const handleClose = () => {
        setIsOpen(false);
    };
    return (
        <div style={{width:'80%',maxWidth:'100%'}}>
        <FormControl
        sx={{ width: 286, height: 33, padding: 0, backgroundColor: '#FFFFFF',maxWidth: '100%', }}
        onClick={isOpen ? handleClose : handleOpen}
        data-selenium-id='SIPChecklistPage-GEFlagSectiion-DropdownSelect-FormControl'
        id='SIPChecklistPage-GEFlagSectiion-DropdownSelect-FormControl'
    >
        <Select
            open={isOpen}
            onClose={handleClose}
            onOpen={handleOpen}
            inputProps={{ readOnly: true }} // 点击输入框时不获取焦点
            IconComponent={() => (<div style={{ paddingRight: '6px', paddingTop: '0px', marginBottom: '4px' }}>
                <IconButtonNewStyle>
                    {isOpen ? (<SelectedArrow onClick={handleClose}></SelectedArrow>) : (
                        <SelectArrow onClick={handleOpen}></SelectArrow>)}
                </IconButtonNewStyle>
            </div>)}
            data-selenium-id='SIPChecklistPage-GEFlagSectiion-DropdownSelect-Select'
            sx={{
                height: 33,
                backgroundColor: '#FFFFFF',
                border:!error?'':'2px solid #EE5350!important',
                borderRadius:!error?'':'4px!important' ,
                '& .MuiOutlinedInput-notchedOutline':{
                    borderWidth:!error?'1px':"0px!important",
                }
            }}
            className='custom-select'
            value={selectedValue}
            onChange={handleSelectChange}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom', // horizontal: 'left',
                }, getContentAnchorEl: null, PaperProps: {
                    id: 'SIPChecklistPage-GEFlagSectiion-DropdownSelect-Select-PaperProps', style: {
                        // height: '380px', //maxHeight: 400,
                        width: '20ch', marginTop: '6px', // 间距设置为6px
                        marginLeft: '143px', boxShadow: '0px 2px 5px 0px #00000010',
                    },
                },
            }}
            renderValue={() => (<Box
                data-selenium-id='SIPChecklistPage-GEFlagSectiion-DropdownSelect-Select-RenderBox'
                sx={{
                    boxSizing: 'border-box',
                    height: '22px',
                    p: '5px 6px',
                    display: 'flex',
                    width: 'fit-content',
                    maxWidth: '100%',
                    alignItems: 'center',
                    gap: '6px',
                    borderRadius: '4px',
                    border: '1px solid #BCC5CF',
                    color: '#484848',
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    lineHeight: '20px',
                    cursor: 'default',
                    marginLeft: '-8px!important', // marginBottom: '2px',
                }}
                onClick={e => e.stopPropagation()}
            >
                {!SevereOptions.includes(selectedValue) ? (<Warning500_6 />) : (<Error500_6 />)}
                {selectedValue}
                <Off
                    cursor={'pointer'}
                    onClick={handleClaerState}
                    style={{
                        borderRadius: '2px',
                    }}
                />
            </Box>)}
        >
            {renderOptions()}
        </Select>
      </FormControl>

        {!error?<></>
        :<Stack
        direction='row'
        spacing='2px'
        alignItems='flex-Start'
        justifyContent='flex-start'
      >
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{
            marginTop: '2px',
          }}
        >
          <TextFieldErrorIcon />
        </Stack>
        <Box
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
            color: '#EE5350',
            letterSpacing: '-0.084px',
          }}
        >
          Please select a flag.
        </Box>
      </Stack>}
    </div>
    );
};

const getFlagId = (flagName, list) => {
    for (let i = 0; i < list?.length; i++) {
        let flag = list[i];
        if (flag.value === flagName) return flag.id;
    }
};

const getFlagName = (flagId,list) =>{
    for (let i = 0; i < list?.length; i++) {
        let flag = list[i];
        if (flag.id === flagId) return flag.value;
    }
};
const getFlagType = (flagName, list) => {
    for (let i = 0; i < list?.length; i++) {
        let flag = list[i];
        if (flag.value === flagName) return flag.type;
    }
};
const getFlagKey = (flagName, list) => {
    for (let i = 0; i < list?.length; i++) {
        let flag = list[i];
        if (flag.value === flagName) return flag.key;
    }
};

function FlagGroup(props) {
    const {
        flagItem = {},
        FlagOptions,
        SevereOptions,
        handleChange = (newFlagItem) => {},
        submitProposerVO=false,
        proceeding=false,
    } = props;

    const {
        flagId=0,
        comment='',
        fileNames = [],
    } = flagItem;

    const flagsList = useSelector(state => state.GE.flagsList);
    const flagName = getFlagName(flagId, flagsList);

    const [hasTip, setHastip] = useState(false);
    const [isSevere, setIsSevere] = useState(false);

    useEffect(() => {
        // init
        setIsSevere(SevereOptions.includes(flagName));
    }, [flagItem]);

    const handleSelectChange = (newFlagName) => {
        if (newFlagName != ''){
            handleChange({
                ...flagItem,
                flagId: getFlagId(newFlagName, flagsList),
                flagName: newFlagName,
            });
        } else {
            handleChange({
                ...flagItem,
                flagId: 0,
                flagName: '',
                comment: '',
                fileNames: [],
            });
        }
    };

    const handleTextChange = (text) => {
      handleChange({
          ...flagItem,
          comment: text,
      });
    };

    const handleFilesChange = (upfile, upfileName, isUpload) =>{
        const fileNames = new Set();
        for (let i = 0; i < Object.keys(isUpload)?.length; i++) {
            if (isUpload[i] === 1){
                fileNames.add(upfileName[i]);
            }
        }
        handleChange({
            ...flagItem,
            fileNames: [...fileNames],
        });
    };

    return <Stack
      spacing={3}
      data-selenium-id='SIPChecklistPage-GEFlagSectiion-EditBox-Stack'
      sx={{ paddingTop: "8px", paddingBottom: "0px" }}
    >
        <Box data-selenium-id='SIPChecklistPage-GEFlagSectiion-EditBox-Left'>
            <Grid container spacing={1} justifyContent='flex-end'>
                {/* GE Details 信息左半部分  */}
                <Grid item xs={6}>
                    <Stack
                      spacing={2.5}
                      data-selenium-id='SIPChecklistPage-GEFlagSectiion-EditBox-LeftStack'
                    >
                        <Box display='flex'>
                            <Box display='flex' sx={{ width: "56.38%",maxWidth: '100%', alignItems: "initial" }}>
                                <Typography
                                  sx={{ color: "#596A7C", fontSize: "14px" }}
                                  data-selenium-id='SIPChecklistPage-GEFlagSectiion-Edit-LeftBox-FlagTypography'
                                >
                                    {"Flag\u00A0\u00A0"}
                                </Typography>
                                <Stack
                                  sx={{ paddingTop: "4px" }}
                                  data-selenium-id='GEPage_GEDetailPage-FlagBlock-Edit-LeftBox-FlagIcon'
                                >
                                    <MandatoryIcon></MandatoryIcon>
                                </Stack>
                            </Box>
                            <DropdownSelect
                              onSelectChange={handleSelectChange}
                              initialValue={flagName}
                              FlagOptions={FlagOptions}
                              SevereOptions={SevereOptions}
                              error={proceeding && flagId == 0}
                            ></DropdownSelect>
                        </Box>
                        <Box
                          display='flex'
                          alignItems='center'
                          data-selenium-id='SIPChecklistPage-GEFlagSectiion-Edit-LeftBox-CommentTypography'
                          sx={{ marginTop: "0px!important" }}
                        >
                            <Box
                              display='flex'
                              sx={{
                                  width: "41.38%",
                                  maxWidth: '100%',
                                  paddingBottom: hasTip ? "13px" : "0px",
                                  paddingTop: hasTip ? "0px" : "10px"
                              }}
                            >
                                <Typography
                                  sx={{ color: "#596A7C", fontSize: "14px" }}
                                >
                                    {"Comment"}
                                </Typography>
                            </Box>
                            <Box
                              display='flex'
                              sx={{ width: "41.38%",maxWidth: '100%', paddingTop: "74px" }}
                            >
                                <TextInputWithLimit
                                  onTextChange={handleTextChange}
                                  initialValue={comment}
                                  setHastip={setHastip}
                                  sx={{ paddingTop: "10px" }}
                                />
                            </Box>
                        </Box>
                    </Stack>
                </Grid>
                {/* GE Details 信息右半部分  */}
                <Grid item xs={6}>
                    <Stack
                      spacing={2.5}
                      data-selenium-id='SIPChecklistPage-GEFlagSectiion-EditBox-RightStack'
                    >
                        <Box display='flex'>
                            <Box
                              display='flex'
                              sx={{ width: "48.38%",maxWidth: '100%', }}
                              data-selenium-id='SIPChecklistPage-GEFlagSectiion-Edit-RightBox-FileUpload'
                            >
                                <Typography
                                  sx={{ color: "#596A7C", fontSize: "14px" }}
                                >
                                    {"\u00A0\u00A0\u00A0\u00A0Evidence\u00A0\u00A0"}
                                </Typography>
                                {isSevere ? (<Stack sx={{ paddingTop: "4px" }}>
                                    <MandatoryIcon></MandatoryIcon>
                                </Stack>) : (<></>)}
                            </Box>
                            <FileUpload
                              onFileChange={handleFilesChange}
                              isSevere={isSevere}
                              initialValue={fileNames}
                              submitProposerVO={submitProposerVO}
                              proceeding={proceeding}
                            ></FileUpload>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    </Stack>;
}

const GEFlagSection = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const {
        isEdit, setAdd, proposerId, FlagData,FlagIndex,
        buttonDisable,
        editProposerVO, setEditProposerVO, submitProposerVO,setSubmitProposerVO,
        geCode,
    } = props;
    const selector = state => {
        return {
            flagsList: state.GE.flagsList,
            upfileBack: state.GE.upfileBack,
            fileProgressArray: state.GE.fileProgressArray,
            proceeding: state.SIPChecklist.proceeding,
            FlaggedGETable: state.GE.flaggedGESynchronizationAndVerification,
        };
    };


    useEffect(() => {
        if(isEdit){
            setFlagState('default');
            setTimeout(() => {
             dispatch(setIsFlagChanged(false));
            }, 500);
        }
    }, [isEdit]);

    const { flagsList, proceeding, FlaggedGETable, } = useSelector(selector);

    const setFlagState = (flagState) => {
      dispatch(setGeItemValue('flagsEditingState',flagState,FlagIndex));
    };

    const setFlagData = (flagData) => {
        dispatch(setGeItemValue('flags',flagData,FlagIndex));
    };
 
    let FlagOptions = [], SevereOptions = [];
    FlagOptions.push({ value: '', group: 'Category A', icon: 'severe issue' });
    flagsList?.map(flag => {
        if (flag.type === 'Flags-Severe Issue') {
            FlagOptions.push({ value: flag.value, group: 'Category A' });
            SevereOptions.push(flag.value);
        }
    });
    FlagOptions.push({ value: '', group: 'Category B', icon: 'mild issue' });
    flagsList?.map(flag => {
        if (flag.type === 'Flags-Mild Issue') FlagOptions.push({ value: flag.value, group: 'Category B' });
    });

    useEffect(()=>{
        if(submitProposerVO) setSubmitProposerVO(false);
    },[submitProposerVO,editProposerVO]);

    const handleFlagGroupChange = (index) => (flagItem) => {
        dispatch(setIsFlagChanged(true));
        const newFlags = [...FlagData];
        newFlags[index] = {
            ...flagItem,
            proposerId:proposerId,
        };

        // 验证flags数据
        let wrong=false;
        for (let item of newFlags) {
            if (item.flagId == 0){
                wrong = true;
                break;
            }
            const isSevere = SevereOptions.includes(item.flagName);
            if ((isSevere && (item.fileNames.length === 0 || item.fileNames?.length===undefined))
              || item.comment?.length > 10000) {
                wrong=true;
            }
        }
        setHasError(wrong);
        if(wrong && submitProposerVO) setFlagState('error');
        else if(!wrong) setFlagState("checked");
        else setFlagState('default');

        dispatch(setGeItemValue('flags', newFlags, FlagIndex));
    };

    const DownloadSingleFile = (filename, fileIndex, flagIndex) => {
        let downloadName = filename;
        if (filename.split('_').length > 2) {
            downloadName = filename.split("_").slice(2).join('_');
        }
        dispatch(downloadSingleGEFile(filename, downloadName));
    };
    const ShowFile = ({ fileName, index: flagIndex = 0 }) => {
        let file;
        if (typeof fileName === 'string') file = fileName[0]; else if (typeof fileName === 'object') file = Object.values(fileName)[0];
        return (<Stack
            position='relative'
            sx={{
                paddingLeft: '0px',
            }}
        >
            {fileName !== undefined && fileName?.length > 0 ? (fileName.map((file, index) => {
                file = file || '234_xyz_test.xls';
                const fileNameOnly = file.split("_").slice(2).join('_');
                return (<GetLabel
                  sx={{
                      marginTop: index === 0 ? "0px" : "8px", zIndex: 500, 
                    //   marginLeft: "28px"
                  }}
                  FileIcon={ChooseIconByType(file.split(".")[file.split(".")?.length - 1])}
                  del={() => DownloadSingleFile(file, index, flagIndex)}
                  fileName={fileNameOnly.replace(/(.*)(?=\.)/, "$1")}
                  fileSize={formatFileSize(file.split("_")[0])}
                  fileMIMEType={file.split(".")[file.split(".")?.length - 1]}
                  isDownLoad={true}
                />);
            })) : (<>-</>)}
        </Stack>);
    };

    const matches1458 = useMediaQuery('(min-width: 1458px)');

    const renderShowFlag = () => {
        const childFlag = [];
        let hasFlagIdWrong=false;
        if(FlagData !== null && FlagData!==undefined && FlagData?.length>0){
            for(let i=0;i<FlagData.length;i++){
                if(FlagData[i].flagId===0) hasFlagIdWrong=true;
            }
        }
        if (FlagData !== null && FlagData!==undefined && FlagData?.length>0 && !hasFlagIdWrong) {
            childFlag.push(<Box data-selenium-id='SIPChecklistPage-GEFlagSectiion-ShowBox'>
                <CardContent
                    sx={{
                        paddingLeft: 0, paddingRight: 0, paddingTop: '16px!important', paddingBottom: '32px!important',
                    }}
                >
                    {FlagData.map((item, index) => {
                        const flagName = getFlagName(item.flagId, flagsList);
                        return (<Stack
                            spacing={3}
                            data-selenium-id='SIPChecklistPage-GEFlagSectiion-ShowBox-Stack'
                            sx={{ paddingTop: index === 0 ? '0px' : '14px' }}
                        >
                            <Box>
                                <Grid container spacing={1} justifyContent='flex-end' 
                                sx={{flexDirection:'row',flexWrap:'nowrap'}}
                                >
                                    {/* GE Details 信息左半部分  */}
                                    <Grid item xs={matches1458 ? 6 : 12} sx={{paddingTop:'0px!important'}}>
                                        <Stack
                                            spacing={2.5}
                                            data-selenium-id='SIPChecklistPage-GEFlagSectiion-ShowBox-LeftStack'
                                            sx={{maxWidth: '100%', }}
                                        >
                                            <Box
                                                display='flex'
                                                alignItems='center'
                                                data-selenium-id='SIPChecklistPage-GEFlagSectiion-ShowBox-LeftBox'
                                            >
                                                <Box
                                                    display='flex'
                                                    sx={{width: "56.38%",maxWidth: '100%', marginRight: '8px' }}
                                                >
                                                    <Typography
                                                        data-selenium-id='SIPChecklistPage-GEFlagSectiion-ShowBox-LeftBox-FlagTypography'
                                                        sx={{ color: '#596A7C', fontSize: '14px' }}
                                                    >
                                                        {'Flag'}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    display='flex'
                                                    sx={{ width: '280px',maxWidth: '100%', }}
                                                    data-selenium-id='SIPChecklistPage-GEFlagSectiion-ShowBox-LeftBox-Flag'
                                                >
                                                    <ShowFlag
                                                        flagType={getFlagType(flagName, flagsList)}
                                                        flagValue={flagName}
                                                    ></ShowFlag>
                                                </Box>
                                            </Box>
                                            <Box
                                                display='flex'
                                                data-selenium-id='SIPChecklistPage-GEFlagSectiion-ShowBox-LeftBox-Comment'
                                                sx={{ marginTop: '8px!important' }}
                                            >
                                                <Box display='flex' sx={{width: "41.38%",maxWidth: '100%', marginRight: '8px' }}>
                                                    <Typography
                                                        sx={{ color: '#596A7C', fontSize: '14px' }}
                                                    >
                                                        {'Comment'}
                                                    </Typography>
                                                </Box>
                                                <Box display='flex' sx={{ width: "41.38%" ,maxWidth: '100%',}}>
                                                    <ShowComment comment={item.comment}></ShowComment>
                                                </Box>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                    {/* GE Details 信息右半部分  */}
                                    <Grid item xs={matches1458 ? 6 : 12}>
                                        <Stack
                                            spacing={2.5}
                                            data-selenium-id='SIPChecklistPage-GEFlagSectiion-ShowBox-RightStack'
                                            sx={{
                                                paddingLeft: matches1458 ? '20px' : '0px',
                                                // marginTop: matches1458 ? '0px' : '40px',
                                            }}
                                        >
                                            <Box
                                                display='flex'
                                                data-selenium-id='SIPChecklistPage-GEFlagSectiion-ShowBox-Right-EvidenceFile'
                                            >
                                                <Box
                                                    display='flex'
                                                    sx={{
                                                        width: "48.38%",
                                                        maxWidth: '100%',
                                                        marginRight: '0px!important',
                                                        paddingRight: '0px!important',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{ color: '#596A7C', fontSize: '14px' }}
                                                    >
                                                        {'Evidence'}
                                                    </Typography>
                                                </Box>
                                                <Box display='flex' sx={{ width: '286px',maxWidth: '100%', }}>
                                                    <ShowFile
                                                        fileName={item.fileNames}
                                                        index={index}
                                                    ></ShowFile>
                                                </Box>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Stack>);
                    })}
                </CardContent>
            </Box>);
        }else{
            childFlag.push(<Box sx={{height:'22px'}}>-</Box>);
        }


        return childFlag;
    };

    const [hasError, setHasError] = useState(false);

    const renderEditFlag = () => {
        const childFlag = [];
        let matchedSIGEFlags = [];

        const flaggedGes = FlaggedGETable?.[FlagIndex];
        if(flaggedGes){
            if (geCode){
                for (let flaggedGE of flaggedGes){
                    if (flaggedGE.geCode === geCode){
                        matchedSIGEFlags = matchedSIGEFlags.concat(flaggedGE?.flags ?? []);
                    }
                }
            }else {
                const arr=JSON.parse(sessionStorage.getItem(FlaggedGESynchronizationAndVerificationTable)??'[]');
                const checkedArray = arr?.[FlagIndex];

                for (let i = 0; i < flaggedGes.length; i++) {
                    if (checkedArray?.[i] === 'Matched') {
                        matchedSIGEFlags = matchedSIGEFlags.concat(flaggedGes[i]?.flags ?? []);
                        break;
                    }
                }
            }
        }


        if (FlagData !== null && FlagData!==undefined) {
            childFlag.push(<CardContent
                sx={{
                    paddingLeft: 0, paddingRight: 0, paddingTop: '8.5px!important', paddingBottom: '32px!important',
                }}
            >
                {FlagData?.length>0?
                FlagData.map((item, index) => {
                    return <FlagGroup
                      flagItem={item}
                      FlagOptions={FlagOptions.filter(flag => !matchedSIGEFlags.some(ignore=> ignore === flag.value) )}
                      SevereOptions={SevereOptions}
                      handleChange={handleFlagGroupChange(index)}
                      submitProposerVO={submitProposerVO}
                      proceeding={proceeding}
                    />;
                }):<></>
                }
            </CardContent>);
        }
        return childFlag;
    };

    return (<Box
        data-selenium-id='SIPChecklistPage-GEFlagSectiion-Box'
        sx={{ width: 1092,maxWidth: '100%',marginTop:'10px!important' }}
    >  
        {isEdit ? (<Box data-selenium-id='SIPChecklistPage-GEFlagSectiion-EditBox'>
            {renderEditFlag()}
        </Box>) : (<Box data-selenium-id='SIPChecklistPage-GEFlagSectiion-ShowBox'>{renderShowFlag()}</Box>)}
    </Box>);
});

export default GEFlagSection;

import { TextField, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BaseAutocomplete from './BaseAutocomplete';
import { calcHeight } from './Utils';
import AutocompleteDropDownIcon from '@/assets/dropdownIcon_8_457.svg';
import { AllCentered } from './CommonStyle';
import { ColumnCentered } from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/CommonStyle';
import AutocompleteArrowIcon from './SVGComponents/AutocompleteArrowIcon.svg';
import AutocompleteClearIcon from './SVGComponents/AutocompleteClearIcon.svg';
import { useDispatch } from 'react-redux';
import { trimItemFilds } from '@/utils/commonUtils';
import { AddSelectItem } from '../SelectBox/SelectInput';
import { GetPopperBottomContext } from './CommonContext';
export default function SinpleAutocomplete(props) {
  const {
    id,
    value: defaultValue,
    options = [], //下拉数据
    optionLineHeight = 20,
    placeholder,
    isErr = false, //判断是否存在错误
    isEmpty = true, //判断输入框的是否为空
    config = {}, //用于控制BaseAutocompelte的输入，具体什么输入建议查看BaseAutocomplete或者阅读下面的代码,
    //模糊查询的相关参数
    defaultQuery = false,
    // 类型： bool， true：使用Autocomplete的默认的模糊查询行为；
    // false： 不使用AutoComplete的默认查询，而是使用后端查询，必须提供loadOptionAction,
    // 不使用AutoComplete的默认查询，而是使用后端查询，必须提供loadOptionAction,
    // 不使用AutoComplete的默认查询，而是使用后端查询，必须提供loadOptionAction,
    // 不使用AutoComplete的默认查询，而是使用后端查询，必须提供loadOptionAction,
    // 不使用AutoComplete的默认查询，而是使用后端查询，必须提供loadOptionAction,
    // 不使用AutoComplete的默认查询，而是使用后端查询，必须提供loadOptionAction,
    // 不使用AutoComplete的默认查询，而是使用后端查询，必须提供loadOptionAction,
    // 不使用AutoComplete的默认查询，而是使用后端查询，必须提供loadOptionAction;
    //  默认值 ：false
    defaultQueryMode = 'includes',
    // 模糊查询的过滤方式： 包含、以...开始、以...结束
    // 可选值： 'includes', 'startsWith', 'endsWith',
    // 只在defaultQuery == true 时生效
    loadOptionAction, //后端查询的接口函数
    renderOption, //自定义下拉框中的内容，需要注意的是，可选项必须使用 li，否则你无法使用默认的样式，你需要自己提供样式
    getOptionLabel, //显示在输入框内的内容，如果不提供，输入框的内容是 option 本身
    getListOptionLabel, //显示在li中的内容，如果不提供，li的内容取决于getOptionLabel,
    getInputValueLabel, //控制value->inputValue的一个映射，因为getOptionLabel获得的字符串可能无法被后端查询到，所以我们需要重新对inputValue做映射
    onChange, //选中可选项后的回调函数,需要注意，它的函数参数应该是（e，newValue）
    disableNewItem = true, //是否禁用添加新item到数据库的功能，类型：bool，true：禁用；false：不禁用。默认值：true
    addNewItemManager = {},
    setError = () => {}, //将组件的error传递到外面
    needClearInputValue = false, //bool 类型， false: 在onchange之后，下拉框会根据onchange选择的值进行加载数据；true：在onchange后，下拉框会根据''进行加载数据
    readOnly = false, //bool 类型, false:输入框是可以输入的，true：输入框只支持点击，不支持输入，需要注意的是，必须要保证id是在当前页面是独一无二的
    onOpen,
    onClose,
    needKeyboardDelete = true, //用于判断是否启用键盘删除
    deleteValue = () => {
      onChange(null, null);
    }, //清除当前选项，若启用键盘删除则必须要用该方法
    // ...other

    // 此处不得不进行较为拙劣的修改以应付甲方对下拉列表中选项的需求
    //defaultRenderOption使用了非参数的变量，因而与SingleAutocomplete高度绑定，外界需要实现AddNew功能较为困难，很难完全重写，因而此处设计部分重写
    renderOptionPartlyInject,
    isSSIP = false,
    inputHeight, //目前来说。通过下拉框去定位这个bottom存在一些困难，
    //主要是因为Autocomplete在打开下拉框的时候，按照它原来的逻辑，他如果在底部空间不够的情况下，是会在输入框的上方打开
    //但是由于我们把top定死了，所以他只会在下方打开，但是它内部定位逻辑还是没变，只是数值被我们定死了，所以在底部空间不够的时候，可能会导致抖动
    //这个抖动就会使得bottom定位不准确，所以现在希望通过输入框来定位bottom，而使用输入框定位bottom就需要提供输入框的高度(虽然可能都是40px)
    disabled = false, //是否禁用下拉框功能
    freeSolo = undefined, //是否允许输入自定义的值
    createFreeSoloOption = (v) => v, //自定义输入的值, 用于freeSolo
    sx={},
  } = props;

  const dispatch = useDispatch();

  const DELAY_LOAD_TIME = 300;

  const [autocompleteError, setAutocompleteError] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [inputValue, setInputValue] = useState('');
  const [delayLoadTimer, setDelayLoadTimer] = useState(null);
  const [optionsToShow, setOptionsToShow] = useState([]);
  const [isAddSelectItemShow, setIsAddSelectItemShow] = useState(false);

  const delayLoadOptions = value => {
    if (defaultQuery) return;

    // 停止输入 ${DELAY_LOAD_TIME}ms 后开始查询
    if (delayLoadTimer) clearTimeout(delayLoadTimer);
    setDelayLoadTimer(
      setTimeout(() => {
        // 加载
        dispatch(loadOptionAction(trimItemFilds(value)));
      }, DELAY_LOAD_TIME)
    );
  };

  useEffect(() => {
     setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    delayLoadOptions(inputValue);
  }, [inputValue]);

  const {
    newData,
    itemExist,
    dataGet,
    addNewOption,
    afterAddNewOption,
    afterDateGetSucess,
  } = addNewItemManager;

  function In(value, dataList) {
    let isIn = dataList.some(item => {
      if (!item) return false;
      return value === defaultGetListOptionLabel(item);
    });
    return isIn;
  }

  function getNewOptions() {
    let newOptions = options.concat();
    newOptions.push(null);
    return newOptions;
  }

  function defaultGetOptionLabel(option) {
    if (option === null || option === '') return '';
    if (getOptionLabel) {
      return getOptionLabel(option);
    } else {
      return option;
    }
  }

  function defaultGetListOptionLabel(option) {
    if (option === null) return 'qwerty-add-select-item';
    if (getListOptionLabel) {
      return getListOptionLabel(option);
    } else {
      return defaultGetOptionLabel(option);
    }
  }

  function defaultGetInputValueLabel(option) {
    if (option === null) return '';
    if (getInputValueLabel) {
      return getInputValueLabel(option);
    } else {
      return defaultGetOptionLabel(option);
    }
  }

  const defaultRenderOption = (props, option) => {
    if (option === null) {
      if (!disableNewItem && !In(inputValue, options) && inputValue !== '') {
        setIsAddSelectItemShow(true);
        return (
          <AddSelectItem
            handleAdd={addNewOption.bind(this, trimItemFilds(inputValue))}
            value={trimItemFilds(inputValue)}
            key='qwerty-add-select-item'
            sx={{ marginTop: options.length === 0 ? '0px' : '-2px' }}
          />
        );
      }
      setIsAddSelectItemShow(false);
      return null;
    }
    if (defaultGetListOptionLabel(option) === '') return null;
    if (renderOptionPartlyInject) {
      return renderOptionPartlyInject(props, option);
    }
    return <li {...props}>{defaultGetListOptionLabel(option)}</li>;
  };

  useEffect(() => {
    // 当新获得新的有效 item 时，将其添加至 optionsToShow ，
    // 同时存入缓存，等待 option 刷新

    if (dataGet === 'success') {
      onChange(null, newData);

      afterDateGetSucess(newData);

      if (loadOptionAction !== undefined) {
        dispatch(loadOptionAction(trimItemFilds(inputValue)));
      }
    }

    if (dataGet === 'success' || dataGet === 'error') {
      if (afterAddNewOption !== undefined) afterAddNewOption();
    }
  }, [dataGet]);

  useEffect(() => {
    if (!disableNewItem) {
      setOptionsToShow(getNewOptions());
    }
  }, [options]);

  function filterOptions() {
    let newOptions = [];
    for (let i = 0; i < options.length; i++) {
      let option = options[i];
      const optionLabelText = defaultGetListOptionLabel(option)?.toLowerCase();
      const searchString = inputValue?.toLowerCase();
      if (
        defaultQueryMode === 'includes' && optionLabelText.includes(searchString) ||
        defaultQueryMode === 'startsWith' && optionLabelText.startsWith(searchString) ||
        defaultQueryMode === 'endsWith' && optionLabelText.endsWith(searchString)
      ) {
        newOptions.push(option);
      }
    }
    return newOptions;
  }

  function buildFilter(queryMode) {
    return function(options, state) {
      let newOptions = [];
      for (let i = 0; i < options.length; i++) {
        let option = options[i];
        const optionLabelText = defaultGetListOptionLabel(option)?.toLowerCase();
        const searchString = state.inputValue?.toLowerCase();
        if (
          queryMode === 'includes' && optionLabelText.includes(searchString) ||
          queryMode === 'startsWith' && optionLabelText.startsWith(searchString) ||
          queryMode === 'endsWith' && optionLabelText.endsWith(searchString)
        ) {
          newOptions.push(option);
        }
      }
      return newOptions;
    };
  }

  useEffect(() => {
    setAutocompleteError(isErr);
    setError(isErr);
  }, [isErr]);

  useEffect(() => {
    let input = document.getElementById(`${id}_aotucomplete`);
    if (input) {
      input.readOnly = readOnly;
    }
  }, [id]);

  const setPopperBottom = React.useContext(GetPopperBottomContext).setValue;

  return (
    <>
      <BaseAutocomplete
        // {...other}
        id={`${id}_aotucomplete`}
        data-selenium-id={`${id}_aotucomplete`}
        value={value}
        options={disableNewItem ? options : optionsToShow}
        disableClearable={config?.disableClearable ?? true}
        multiple={false}
        isSSIP={isSSIP}
        // {...(getOptionLabel ? {getOptionLabel:defaultGetOptionLabel}: {})}
        getOptionLabel={defaultGetOptionLabel}
        onInputChange={(event, newValue) => {
          setInputValue(newValue);
          if(freeSolo){
            if (inputValue !== defaultGetInputValueLabel(defaultValue)) {
              setValue(createFreeSoloOption(newValue));
            }
          }
          if (needKeyboardDelete && newValue === '') {
            deleteValue();
          }
        }}
        onOpen={(event, reason) => {
          setInputValue(defaultGetInputValueLabel(value));
          if (defaultQuery || needClearInputValue) {
            setInputValue('');
          }
          setTimeout(() => {
            let height = 0;
            let bottom = 0;
            if (config?.scrollProps?.scrollOverviwSX?.height) {
              height = Number(
                config.scrollProps.scrollOverviwSX.height.split('px')[0]
              );
            } else {
              height =
                !disableNewItem && isAddSelectItemShow
                  ? calcHeight(options, 6, 6, optionLineHeight, 6, 48)
                  : calcHeight(
                      defaultQuery ? filterOptions() : options,
                      6,
                      6,
                      optionLineHeight,
                      6,
                      0
                    );
            }
            if (inputHeight) {
              let myInput = document.getElementById(`${id}_aotucomplete`);

              bottom =
                myInput.getBoundingClientRect().top + 12 + height + inputHeight;
            }
            let myPopper = document.getElementById('my-popper');
            if (myPopper) {
              let transformValue = myPopper.style.transform;
              let y = parseFloat(transformValue.split(', ')[1].split('px)')[0]);
              bottom = y + 12 + height;
            }
            setPopperBottom(bottom);
          }, 100);
          onOpen && onOpen(event, reason);
        }}
        onChange={(event, newValue) => {
          onChange(event, newValue);
          //似乎执行完onChange后，Autocomplete会自动修改 inputValue的值
          setInputValue(defaultGetInputValueLabel(newValue));
          if (defaultQuery || needClearInputValue) {
            setInputValue('');
          }
        }}
        onClose={(event, reason) => {
          if (value && !needClearInputValue) {
            delayLoadOptions(trimItemFilds(defaultGetInputValueLabel(value)));
          } else {
            delayLoadOptions('');
          }
          setPopperBottom(null);
          onClose && onClose(event, reason);
        }}
        onFocus={() => {
          setAutocompleteError(false);
          setError(false);
          if (props.onFocus) {
            props.onFocus();
          }
        }}
        onBlur={(e) => {
          setAutocompleteError(isErr);
          setError(isErr);
          if (props.onBlur) {
            props.onBlur();
          }
          if (freeSolo){
            setValue(defaultValue);
          }
        }}
        renderInput={params => (
          <TextField
            id={`${id}_aotucomplete_textfield`}
            data-selenium-id={`${id}_aotucomplete_textfield`}
            placeholder={placeholder}
            error={autocompleteError}
            // InputProps={
            //   value?.length || !showStartIcon
            //     ? { ...props.InputProps }
            //     : {
            //       ...props.InputProps,
            //       startAdornment: <SearchIcon />,
            //     }
            // }
            {...params}
          />
        )}
        renderOption={renderOption ?? defaultRenderOption}
        deleteIcon={config?.clearIcon ?? <AutocompleteClearIcon />}
        popupIcon={
          config.popupIcon === undefined ? (
            <AutocompleteArrowIcon />
          ) : (
            config.popupIcon
          )
        }
        focusPopusIcon={config?.focusPopusIcon ?? null}
        dropListEndAttachment={config?.dropListEndAttachment ?? <></>}
        dropListNoOptions={config?.dropListNoOptions}
        filterOptions={ defaultQuery ? buildFilter(defaultQueryMode) : x => x }
        endAttchmentProps={{
          endAttchmentSX: {
            top: '12px',
            right: '12px !important',
            display: 'flex',
            flexDirection: 'row',
            gap:
              config.popupIcon === null || config.disableClearable
                ? '0px'
                : '4px',
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
            ...(config?.endAttchmentProps?.endAttchmentSX ?? {}),
          },
          popupIconSX: {
            backgroundColor: '#FFFFFF',
            padding: '0px',
            ...(config?.endAttchmentProps?.popupIconSX ?? {}),
          },
          popupIconHoverSX: {
            backgroundColor: '#DFE4E8',
            borderRadius: '2px',
            ...(config?.endAttchmentProps?.popupIconHoverSX ?? {}),
          },
          popupIconActiveSX: {
            backgroundColor: '#DFE4E8',
            borderRadius: '2px',
            ...(config?.endAttchmentProps?.popupIconActiveSX ?? {}),
          },
          deleteIconSX: {
            padding: '0px',
            ...(config?.endAttchmentProps?.deleteIconSX ?? {}),
          },
          deleteIconHoverSX: {
            backgroundColor: '#FFFFFF',
            ...(config?.endAttchmentProps?.deleteIconHoverSX ?? {}),
          },
          deleteIconActiveSX: {
            backgroundColor: '#FFFFFF',
            ...(config?.endAttchmentProps?.deleteIconActiveSX ?? {}),
          },
        }}
        outlinedInputProps={{
          outlinedInputSX: {
            padding: '11px 12px !important',
            cursor: readOnly ? 'pointer' : 'text',
            ...(config?.outlinedInputProps?.outlinedInputSX ?? {}),
          },
          inputSX: {
            root: {
              height: '18px',
              margin: '0px !important',
              marginTop:isSSIP?'-3px!important':null,
              marginLeft:isSSIP?'-3.5px!important':null,
              cursor: readOnly ? 'pointer' : 'text',
              ...(config?.outlinedInputProps?.inputSX?.root ?? {}),
            },
            placeholder: {
              ...(config?.outlinedInputProps?.inputSX?.placeholder ?? {}),
            },
          },
          notchedOutlineSX: {
            border: isEmpty ? '1px solid #DFE4E8' : '1px solid #98A7B6',
            ...(config?.outlinedInputProps?.notchedOutlineSX ?? {}),
          },
          notchedOutlineHoverSX: {
            ...(config?.outlinedInputProps?.notchedOutlineHoverSX ?? {}),
          },
          notchedOutlineFocusSX: {
            border: isEmpty
              ? '2px solid #154AB6 !important'
              : '2px solid #0052CC !important',
            ...(config?.outlinedInputProps?.notchedOutlineFocusSX ?? {}),
          },
          errorSX: {
            notchedOutlineSX: {
              ...(config?.outlinedInputProps?.errorSX?.notchedOutlineSX ?? {}),
            },
            notchedOutlineHoverSX: {
              ...(config?.outlinedInputProps?.errorSX?.notchedOutlineHoverSX ??
                {}),
            },
            notchedOutlineFocusSX: {
              ...(config?.outlinedInputProps?.errorSX?.notchedOutlineFocusSX ??
                {}),
            },
          },
        }}
        dropListProps={{
          listBoxSX: {
            paddingBottom: '6px',
            ...(config?.dropListProps?.listBoxSX ?? {}),
          },
          listBoxUlSX: {
            root: {
              padding: '6px 0px 0px 0px',
              ...(config?.dropListProps?.listBoxUlSX?.root ?? {}),
            },
            li: {
              color: '#596A7C',
              ...(config?.dropListProps?.listBoxUlSX?.li ?? {}),
            },
            liSelected: {
              color: '#596A7C',
              ...(config?.dropListProps?.listBoxUlSX?.liSelected ?? {}),
            },
            liActive: {
              color: '#262E35',
              ...(config?.dropListProps?.listBoxUlSX?.liActive ?? {}),
            },
            liHover: {
              color: '#262E35',
              ...(config?.dropListProps?.listBoxUlSX?.liHover ?? {}),
            },
          },
          listBoxAttachmentSX: {
            ...(config?.dropListProps?.listBoxAttachmentSX ?? {}),
          },
          popperSX: {
            zIndex: 2000,
            ...(config?.dropListProps?.popperSX ?? {}),
          },
          paperSX: {
            boxShadow: '0px 6px 12px 0px #262E351F',
            ...(config?.dropListProps?.paperSX ?? {}),
          },
          noOptionsBoxSX: {
            ...(config?.dropListProps?.noOptionsBoxSX ?? {}),
          },
        }}
        scrollProps={{
          scrollOverviwSX: {
            height: `${
              !disableNewItem && isAddSelectItemShow
                ? calcHeight(options, 6, 6, optionLineHeight, 6, 48)
                : calcHeight(
                    defaultQuery ? filterOptions() : options,
                    6,
                    6,
                    optionLineHeight,
                    6,
                    0
                  )
            }px`,
            ...(config?.scrollProps?.scrollOverviwSX ?? {}),
          },
          scrollTrackVerticalSX: {
            ...(config?.scrollProps?.scrollTrackVerticalSX ?? {}),
          },
          scrollThumbVerticalSX: {
            ...(config?.scrollProps?.scrollThumbVerticalSX ?? {}),
          },
          scrollThumbSizeSX: config?.scrollProps?.scrollThumbSizeSX,
        }}
        disabled={disabled}
        freeSolo={freeSolo}
        sx={sx}
      />
    </>
  );
}

import { InputLabel, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import { MonthPicker } from '../../components/DateRangePicker/MonthPicker';
import React, { useMemo } from 'react';
import FiscalYearSelector from './ReportSpecificComponents/FiscalYearSelector';
import { useSelector } from 'react-redux';
import { getFormattedMonthYear } from '@/utils/commonUtils';
import  GreyDot from '@/assets/ReportDiagrams/greyDot.svg';
export function GenerationDate(props) {
  const { GenerationDateAndTime } = props;
  const { cutOffDate } = useSelector(state => {
    return {
      cutOffDate: state.Report.cutOffDate,
    };
  });
  const cutOffFy = useMemo(() => {
    const withSpace = getFormattedMonthYear(cutOffDate); //Dec2023
    return withSpace.slice(0, 3) + ' ' + withSpace.slice(3);
  }, [cutOffDate]);
  return (
    <Box
      sx={{ marginTop: '0px', paddingLeft: '30px', display: 'flex',flexDirection:'column' }}
      data-selenium-id='Report-GenerationDate'
    >
      <Typography
        variant='caption'
        color='#98A7B6'
        fontSize={16}
        lineHeight='20px'
        fontFamily='Open Sans'
        data-selenium-id='Report-GenerationDate-Typography'
        sx={{display:'flex',gap:'4px', alignItems:'center'}}
      >
        <GreyDot />
        {GenerationDateAndTime}
      </Typography>
      <Typography
        variant='caption'
        color='#98A7B6'
        fontSize={16}
        lineHeight='20px'
        fontFamily='Open Sans'
        data-selenium-id='Report-CutOffFy'
        sx={{display:'flex',gap:'4px', alignItems:'center'}}
      >
        <GreyDot />
        PD Publisher, PP Publisher doesn't exist in database before {cutOffFy}.
      </Typography>
    </Box>
  );
}

export function DatePickerStack(props) {
  const {
    id,
    selectedMonth,
    handleDateChange,
    lastActionDate,
    showTable,
    GenerationDateAndTime,
  } = props;
  return (
    <Stack
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      id={id}
      data-selenium-id={`${id}-root`}
    >
      <InputLabel
        style={{
          'font-family': 'Open Sans',
          'font-weight': '600',
          'font-size': '16px',
          'line-height': '24px',
          color: '#828282',
        }}
        id={`${id}-MonthLabel`}
        data-selenium-id='Report_Sipipeline-MonthLabel'
      >
        <FormattedMessage
          id={'report.month'}
          data-selenium-id={`${id}-MonthLabelValue`}
        />
      </InputLabel>
      <MonthPicker
        id={`${id}-MonthPicker`}
        data-selenium-id={`${id}-MonthPicker`}
        className='report-selector'
        maxDate={new Date()}
        value={selectedMonth}
        handleDateChange={handleDateChange}
        innerBorder='1px solid #DFE4E8'
        inputTextColor='#BCC5CF'
      />
      {/*<span>{lastActionDate}</span>*/}
      {lastActionDate && showTable ? (
        <GenerationDate
          GenerationDateAndTime={GenerationDateAndTime}
          data-selenium-id={`${id}-GenerationDate`}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
}

export function FYSelectStack(props) {
  const {
    id,
    fiscalYear,
    handleYearChange,
    lastActionDate,
    showTable,
    GenerationDateAndTime,
  } = props;
  return (
    <Stack
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      id={id}
      data-selenium-id={`${id}-root`}
    >
      <InputLabel
        style={{
          fontFamily: 'Open Sans',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#828282',
          paddingRight: '12px',
          textTransform: 'capitalize',
        }}
        id={`${id}-MonthLabel`}
        data-selenium-id='Report_Sipipeline-MonthLabel'
      >
        <FormattedMessage id='report.fiscalYear' />:
      </InputLabel>
      <Box width='108px'>
        <FiscalYearSelector
          id='FiscalYearSelector'
          data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Stack1-Stack1-FiscalYearSelector'
          value={fiscalYear}
          handleYearChange={handleYearChange}
        />
      </Box>
      {lastActionDate && showTable ? (
        <GenerationDate
          GenerationDateAndTime={GenerationDateAndTime}
          data-selenium-id={`${id}-GenerationDate`}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
}

import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearEditOptInCode,
  clearSubmitOptInCode,
} from '../../../../actions/SIMT-SI/CatJournal/CATJournalAction';
import { testInputFont } from '../../../../components/FontStyle';
import {
  ItemBlock,
  OptInAutoComplete,
  OptInSelect,
  OptInTextArea,
} from './Components';
import TextFieldErrorIcon from '@/assets/TextFieldErrorIcon.svg';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import {submitOptInCheckJournal} from "@/actions/SIMT-SI/CatJournal/OptIn";
import {getCE} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueSystemAdmin";
import {getJournalListWithCode, getJournalListWithName} from "@/actions/SIMT-SI/SpecialIssue/Journal";

export default function EditContent(props) {
  const { errorMonitor = false, setValue = () => {}, defaultValue, open } = props;
  const [journal, setJournal] = useState({
    journalCode: '',
    journalName: '',
  });
  const [journalCodeInputValue, setJournalCodeInputValue] = useState('');
  const [journalNameInputValue, setJournalNameInputValue] = useState('');
  const [developmentModel, setDevelomentModel] = useState('');
  const [handleCe, setHandleCe] = useState({
    username: '',
    email: '',
  });
  const [handleCeInputValue, setHandleCEInputValue] = useState('');
  const [reasonForOptIn, setReasonForOptIn] = useState('');
  const [pipelineandFuture, setPipelineandFuture] = useState('');
  const [requestor, setRequestor] = useState('');

  const dispatch = useDispatch();

  const { JourListResult, CEResult, editOptInCode, } = useSelector(state => {
    return {
      JourListResult: state.SI.JourList,
      CEResult: state.SI.CE,
      editOptInCode: state.CATJournal.submitOptInCode,
    };
  });

  useEffect(() => {
    if (
      defaultValue !== null &&
      defaultValue !== undefined &&
      defaultValue.journalCode !== undefined
    ) {
      setJournal({
        journalCode: defaultValue.journalCode ? defaultValue.journalCode : '',
        journalName: defaultValue.journalName ? defaultValue.journalName : '',
      });
      setJournalCodeInputValue(
        defaultValue.journalCode ? defaultValue.journalCode : ''
      );
      setJournalNameInputValue(
        defaultValue.journalName ? defaultValue.journalName : ''
      );
      setDevelomentModel(
        defaultValue.developmentModel === 'SI Commissioning' ||
          defaultValue.developmentModel === 'Basic Management'
          ? defaultValue.developmentModel
          : ''
      );
      setHandleCe(
        defaultValue.handlingCe
          ? {
              username: defaultValue.handlingCe.fullName,
              email: defaultValue.handlingCe.email,
            }
          : null
      );
      setHandleCEInputValue(
        defaultValue.handlingCe ? defaultValue.handlingCe.fullName : ''
      );
      setReasonForOptIn(defaultValue.reason ? defaultValue.reason : '');
      setPipelineandFuture(
        defaultValue.currentSIPipelineAndFutureSIPlan
          ? defaultValue.currentSIPipelineAndFutureSIPlan
          : ''
      );
      setRequestor(
        defaultValue.requestUser ? defaultValue.requestUser.fullName : ''
      );
    }
  }, [defaultValue]);

  useEffect(() => {
    if (open) {
      if (journal.journalCode !== defaultValue.journalCode) {
        dispatch(submitOptInCheckJournal(journal.journalCode));
      }
    }else {
      setJournal({
        journalCode: '',
        journalName: '',
      });
      setDevelomentModel('');
      setHandleCe({
        username: '',
        email: '',
      });
      setHandleCEInputValue('');
      setReasonForOptIn('');
      setPipelineandFuture('');
      setRequestor('');
    }
  },[open]);

  useEffect(() => {
    setValue({
      id: defaultValue ? defaultValue.optInId : '',
      journalCode: journal ? journal.journalCode : '',
      journalName: journal ? journal.journalName : '',
      developmentModel: developmentModel ? developmentModel : '',
      handlingCeEmail: handleCe ? handleCe.email : '',
      reason: reasonForOptIn,
      currentSIPipelineAndFutureSIPlan: pipelineandFuture,
    });
  }, [journal, developmentModel, handleCe, reasonForOptIn, pipelineandFuture]);

  const journalErrorText =
    editOptInCode === 14119
      ? 'This Journal is in the SCA Journal list already!'
      : editOptInCode === 14120
      ? 'This Journal has been requested already!'
      : journal.journalCode === ''
      ? 'This field is mandatory to input!'
      : '';

  const [journalFocused, setJournalFocused] = useState(false);
  const journalCodeError =
    (errorMonitor && journal.journalCode === '') ||
    editOptInCode === 14119 ||
    editOptInCode === 14120;
  useEffect(() => {
    dispatch(getJournalListWithName(''));
  }, []);

  useEffect(() => {
    dispatch(clearSubmitOptInCode());
    if (journal.journalCode !== '' && journal.journalCode !== defaultValue.journalCode) {
      dispatch(submitOptInCheckJournal(journal.journalCode));
    }
  }, [journal]);

  if(!Array.isArray(JourListResult))
    return <></>;
  return (
    <Stack
      direction='column'
      spacing={3}
      sx={{
        p: '20px 36px 25px 36px',
        // mt: '20px',
        // mb: '25px',
        overflowY: 'auto',
        scrollbarWidth: 'thin', // 设置滚动条宽度
        scrollbarColor: 'transparent', // 设置滚动条颜色为透明
        '&::-webkit-scrollbar': {
          width: '0px', // 设置滚动条宽度
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'transparent', // 设置滚动条背景为透明
        },
      }}
      data-selenium-id='CATJournal_Overview-OptInOperation-EditContent-Stack'
    >
      {/* Journal Code: */}

      <ItemBlock
        title='Journal Code'
        mandatory={true}
        error={journalCodeError && !journalFocused}
        errorText={journalErrorText}
        data-selenium-id='CATJournal_Overview-OptInOperation-EditContent-ItemBlock1'
      >
        <SinpleAutocomplete
          id='sinpleAutocompleteJournalCode'
          data-selenium-id='CATJournal_Overview-OptInOperation-EditContent-CodeSelectAutoComplete'
          loadOptionAction={getJournalListWithCode}
          isEmpty={journal?.journalCode === ''}
          value={journal}
          isErr={journalCodeError && !journalFocused}
          options={JourListResult}
          getOptionLabel={option => option?.journalCode ?? ''}
          placeholder='Choose journal code'
          onChange={(e, newValue) => {
            setJournal({
              journalCode: newValue ? newValue.journalCode : '',
              journalName: newValue ? newValue.journalName : '',
            });
          }}
          onFocus={() => {
            setJournalFocused(true);
          }}
          onBlur={() => {
            setJournalFocused(false);
          }}
          deleteValue={() => {
            setJournal({
              journalCode: '',
              journalName: '',
            });
          }}
        />
      </ItemBlock>

      {/* Journal Name: */}
      <ItemBlock
        title='Journal Name'
        mandatory={true}
        error={journalCodeError && !journalFocused}
        errorText={journalErrorText}
        data-selenium-id='CATJournal_Overview-OptInOperation-EditContent-ItemBlock2'
      >
        <SinpleAutocomplete
          id='sinpleAutocompleteJournalName'
          data-selenium-id='CATJournal_Overview-OptInOperation-EditContent-NameSelectAutoComplete'
          loadOptionAction={getJournalListWithName}
          isErr={journalCodeError && !journalFocused}
          // setError={setJournalCodeError}
          isEmpty={journal?.journalName === ''}
          value={journal}
          options={JourListResult}
          getOptionLabel={option => option?.journalName ?? ''}
          renderOption={(props, option) => (
            <li {...props}>{option?.journalName ?? ''}</li>
          )}
          placeholder='Choose journal name'
          onChange={(e, newValue) => {
            setJournal({
              journalCode: newValue
                ? newValue.journalCode
                : journal.journalCode,
              journalName: newValue ? newValue.journalName : '',
            });
          }}
          onFocus={() => {
            setJournalFocused(true);
          }}
          onBlur={() => {
            setJournalFocused(false);
          }}
          deleteValue={() => {
            setJournal({
              journalCode: '',
              journalName: '',
            });
          }}
        ></SinpleAutocomplete>
      </ItemBlock>
      {/* Development Model: */}
      <ItemBlock
        title='Development Model'
        mandatory={true}
        error={
          errorMonitor && (developmentModel === '' || developmentModel === null)
        }
        errorText='This field is mandatory to input!'
        data-selenium-id='CATJournal_Overview-OptInOperation-EditContent-ItemBlock3'
      >
        <SinpleAutocomplete
          id='sinpleAutocompleteDevelopmentModel'
          data-selenium-id='CATJournal_Overview-OptInOperation-EditContent-DevelopmentModelSelect'
          options={['SI Commissioning', 'Basic Management']}
          value={developmentModel}
          placeholder='Choose development model'
          defaultQuery={true}
          onChange={(e, value) => {
            setDevelomentModel(value);
          }}
          isEmpty={developmentModel === ''}
          isErr={
            errorMonitor &&
            (developmentModel === '' || developmentModel === null)
          }
        />
      </ItemBlock>
      {/* Requested by: */}
      <ItemBlock
        title='Requested by'
        mandatory={false}
        data-selenium-id='CATJournal_Overview-OptInOperation-EditContent-ItemBlock4'
      >
        <Box
          sx={{
            ...testInputFont,
            letterSpacing: '-0.011em',
            color: '#98A7B6',
            lineHeight: '16.8px',
            fontWeight: 400,
          }}
        >
          {requestor}
        </Box>
      </ItemBlock>
      {/* Handling CE : */}
      <ItemBlock
        title='Handling CE'
        mandatory={false}
        data-selenium-id='CATJournal_Overview-OptInOperation-EditContent-ItemBlock5'
      >
        <SinpleAutocomplete
          id='sinpleAutocompleteHandlingCE'
          data-selenium-id='CATJournal_Overview-OptInOperation-EditContent-HandlingCESelect'
          loadOptionAction={getCE}
          inputValue={handleCeInputValue}
          needEmptyCheck={false}
          value={handleCe}
          options={CEResult}
          isEmpty={handleCe===null}
          disableClearable={true}
          getOptionLabel={option => {
            if (option === null) {
              return option;
            } else {
              return option.username + ' (' + option.email + ')';
            }
          }}
          renderOption={(props, option) => (
            <li {...props}>
              {option.username} ({option.email})
            </li>
          )}
          onChange={(e, newValue) => {
            setHandleCEInputValue(newValue ? newValue.username : '');
            setHandleCe(newValue ? newValue : null);
          }}
          needKeyboardDelete={true}
          deleteValue={() => {
            setHandleCEInputValue('');
            setHandleCe(null);
          }}
        ></SinpleAutocomplete>
      </ItemBlock>
      {/* Reason for Opt-in: */}
      <ItemBlock
        title='Reason for Opt-in'
        mandatory={true}
        error={
          (errorMonitor && reasonForOptIn === '') ||
          reasonForOptIn.length > 10000
        }
        errorText={
          reasonForOptIn.length > 10000
            ? 'The character count should be no more than 10,000.'
            : 'This field is mandatory to input!'
        }
        data-selenium-id='CATJournal_Overview-OptInOperation-EditContent-ItemBlock6'
      >
        <OptInTextArea
          id='OptInReasonInput'
          data-selenium-id='CATJournal_Overview-OptInOperation-EditContent-ReasonInput'
          value={reasonForOptIn}
          limitLength={10000}
          setValue={setReasonForOptIn}
          errorMonitor={
            (errorMonitor && reasonForOptIn === '') ||
            reasonForOptIn.length > 10000
          }
        />
        <Box
          sx={{
            fontFamily: 'Open Sans',
            height: 0,
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '18px',

            color: (
              reasonForOptIn && reasonForOptIn.length
                ? reasonForOptIn.length > 10000
                : false
            )
              ? '#EE5350'
              : '#596A7C',
            float: 'right',
            ml: 'auto',
          }}
          data-selenium-id='CATJournal_Overview-OptInTable-RejectTextArea-Box-Box'
        >
          {reasonForOptIn.length + '/10,000'}
        </Box>
      </ItemBlock>
      {/* Current SI Pipeline and Future SI Plan: */}
      <ItemBlock
        title='Current SI Pipeline and Future SI Plan'
        mandatory={true}
        error={
          (errorMonitor && pipelineandFuture === '') ||
          pipelineandFuture.length > 100000
        }
        errorText={
          pipelineandFuture.length > 100000
            ? // ? 'more charcter can not be inputted if 100000 characters has already been inputted'
              'The character count should be no more than 100,000.'
            : 'This field is mandatory to input!'
        }
        data-selenium-id='CATJournal_Overview-OptInOperation-EditContent-ItemBlock7'
      >
        <OptInTextArea
          id='OptInPipelineandFutureInput'
          data-selenium-id='CATJournal_Overview-OptInOperation-EditContent-PipelineandFutureInput'
          value={pipelineandFuture}
          limitLength={100000}
          setValue={setPipelineandFuture}
          errorMonitor={errorMonitor}
        />
        <Box
          sx={{
            fontFamily: 'Open Sans',
            height: 0,
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '18px',

            color: (
              pipelineandFuture && pipelineandFuture.length
                ? pipelineandFuture.length > 100000
                : false
            )
              ? '#EE5350'
              : '#596A7C',
            float: 'right',
            ml: 'auto',
          }}
          data-selenium-id='CATJournal_Overview-OptInTable-RejectTextArea-Box-Box'
        >
          {pipelineandFuture.length + '/100,000'}
        </Box>
      </ItemBlock>
    </Stack>
  );
}

import styled from '@mui/material/styles/styled';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import DdlPassedClock from '@/assets/DdlPassedClock.svg';
import AcceptDatePassedClock from '@/assets/AcceptDatePassedClock.svg';
import PublicationDatePassedClock from '@/assets/PublicationDatePassedClock.svg';


const MyTooltip = styled(
  ({ className, ...props }) => 
    (<Tooltip {...props} arrow classes={{ popper: className }} />)
)
(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#596A7C',
    width: '6px',
    height: '3px',
    top: '28px',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#596A7C',

    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#FFFFFF',

    padding: '4px 8px 4px 8px',
    // width: '203px',
    height: '28px',

    borderRadius: '5px',
    // maxWidth: '385px',
    boxSizing: 'border-box',

    marginBottom: '3px !important',
  },
}));


// Submission Deadline Passed
export function SubmissionDeadlinePassedAlert(){
  return(
    <MyTooltip 
      title = 'Submission Deadline passed' 
      placement = 'top'
      data-selenium-id = 'SIPage-PublicationDatePassedAlert'
    >
      <div style={{padding:'2px 3px'}}>
        <DdlPassedClock />
      </div>
    </MyTooltip>
  );
}
//Expected Acceptance Date Passed
export function ExpectedAcceptanceDatePassedAlert() {
  return (
    <MyTooltip 
      title='Expected Acceptance Date passed' 
      placement='top'
      data-selenium-id = 'SIPage-SubmissionDeadlinePassedAlert'
    >
      <div style={{padding:'2px 3px'}}>
        <AcceptDatePassedClock />
      </div>
    </MyTooltip>
  );
}

//Expected Publication Date Passed

export function PublicationDatePassedAlert() {
  return (
    <MyTooltip 
      title='Expected Publication Date Passed' 
      placement='top'
      data-selenium-id = 'SIPage-SubmissionDeadlinePassedAlert'
    >
      <div style={{padding:'2px 3px'}}>
        <PublicationDatePassedClock />
      </div>
    </MyTooltip>
  );
}
import {axios_instance} from '@/utils/axios_instance';
import {buildParamsForFilter, getAPIPath} from '@/utils/sipFilterUtils';
import {parseISO} from 'date-fns';

export const LOAD_DATA = 'SIP_FILTER_LOAD_DATA';
export const SET_SELECT = 'SIP_FILTER_SET_SELECT';
export const LOAD_FAVOURITE = 'SIP_FILTER_LOAD_FAVOURITE';
export const SAVE_FAVOURITE = 'SIP_FILTER_SAVE_FAVOURITE';
export const RESET_FILTER = 'SIP_FILTER_RESET_FILTER';
export const SET_FILTER = 'SIP_FILTER_SET_FILTER';
export const SET_DATE = 'SIP_FILTER_SET_DATE';
export const CLEAR_USE_FILTER = 'SIP_FILTER_CLEAR_USE_FILTER';
export const SEARCH_SI = 'SIP_FILTER_SEARCH_SI';

export const RESET_ACQUIRED_DATE_FILTER = 'SIP_FILTER_RESET_ACQUIRED_DATE_FILTER';
export const RESET_DEADLINE_DATE_FILTER = 'SIP_FILTER_RESET_DEADLINE_DATE_FILTER';
export const SET_ADAPT_SIPFILTERSELECTED = 'SIP_FILTER_SET_ADAPT_SIPFILTERSELECTED';
export const SET_FAV_SIPFILTERSELECTED = 'SIP_FILTER_SET_FAV_SIPFILTERSELECTED';
export const FIRST_LOAD_FAVOURITE = 'SIP_FILTER_FIRST_LOAD_FAVOURITE';
export const CHANGE_LIST = 'SIP_FILTER_CHANGE_LIST';

export function setSearchSI(searchSI) {
    return dispatch => {
        dispatch({
            type: SEARCH_SI,
            data: searchSI,
        });
    };
}

export function clearUseFilter() {
    return dispatch => {
        dispatch({ type: CLEAR_USE_FILTER });
    };
}

export function clearReportFilter() {
    return dispatch => {
        dispatch({ type: REPORT_FILTER_RESET });
    };
}

export function loadData(which, searchContent = '', page = 1, pageSize = 5000, solicited = false) {
    let apiPath = getAPIPath(which);
    return async dispatch => {
        try {
            // let res, type;
            let res;
            // let changeApi=which==='sipOrigin' || which==='stageList';
            let total=0, dataArray=[];
            if(which==='sipOrigin'){
                const type = solicited ? 'SSIP Origin' : 'SIP Origin';
                res=await axios_instance.get('/api/v1/user-service/configs',{
                    params:{
                        type,
                        fuzzy_type:false
                    },
                    headers:{
                        authorization: localStorage.getItem('authorization'),
                    },
                });
                for(let i=0;i<res.data.data.length;i++){
                    dataArray.push(res.data.data[i].value);
                }
                total=res.data.data.length;
            } else if(which==='stageList'){
                const businessType = solicited ? 'SSIP' : 'USIP';
                res=await axios_instance.get('/api/v1/user-service/flow/stages/' + businessType,{
                    headers:{
                        authorization: localStorage.getItem('authorization'),
                    },
                });
                for(let i=0;i<res.data.data.length;i++){
                    dataArray.push(res.data.data[i].stageCode);
                }
                total=res.data.data.length;
            } else{
                res = await axios_instance.get(apiPath, {
                    params: {
                        ...buildParamsForFilter(which, searchContent, page, pageSize, solicited),
                    },
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                });
            }
            if (res.data.code === 200) {
                let result;
                if(which==='sipOrigin' || which==='stageList'){
                    result = dataArray;
                }else if(which === 'handlingCe' || which === 'operationsLead' || which === 'screener'){
                    result = res.data?.data?.result;
                    total = res.data?.data?.count;
                }else{
                    result = res.data?.data;
                    total = res.data?.total;
                }

                dispatch({
                    type: LOAD_DATA,
                    data: {
                        which: which,
                        page: page,
                        data: {
                            result,
                            total
                        },
                    },
                });
            } else {
                dispatch({
                    type: LOAD_DATA,
                    data: { which: which, page: page, data: { result: [], total: 0 } },
                });
            }
        } catch (err) {
            dispatch({
                type: LOAD_DATA,
                data: { which: which, page: page, data: { result: [], total: 0 } },
            });
        }
    };
}

export function setSelect(which, data) {
    return dispatch => {
        dispatch({ type: SET_SELECT, data: { which: which, data: data } });
    };
}

export function batchSelectSIFilter(data) {
    const converted = handleFavData(data);
    return dispatch => {
        dispatch({ type: LOAD_FAVOURITE, data: converted });
    };
}

export const resetFilter = () => ({
    type: RESET_FILTER,
});

export const resetAcquiredDateFilter = () => ({
    type: RESET_ACQUIRED_DATE_FILTER,
});

export const resetDeadlineDateFilter = () => ({
    type: RESET_DEADLINE_DATE_FILTER,
});

/**
 * input: userIds array[number]
 *        userNameEmail: array[object]
 * output: users: array[object]
 * aim: combine userId and users' name and email
 */
const combineUsersData = (userIds, userNameEmails) => {
    if(typeof userIds === undefined || typeof userNameEmails === undefined ){
        console.warn('userIds or userNameEmails is NOT defined');
        return [];
    }else if(!Array.isArray(userIds) || !Array.isArray(userNameEmails)){
        console.warn('userIds or userNameEmails is NOT array');
        return [];
    }else if(userIds.length !== userNameEmails.length){
        console.warn('Length of "userIds" or "userNameEmails" are not equal');
        return [];
    }
    let newUser = [];
    for (let i = 0; i < userIds.length; i++) {
        const userId = parseInt(userIds[i]);
        const fullName = userNameEmails[i];
        const parts = fullName.split(/\s*\(/);
        const username = parts[0];
        const email = parts[1].substring(0, parts[1].length - 1);
        newUser.push({
            userId,
            username,
            email,
        });
    }
    return newUser;
};

// special handling data for "handling ce", "operations Lead", and "screener".
// Handle the saved filter (from backend) to the type that frontend accepts
export function handleFavData(data) {
    const handlingCe = combineUsersData(data?.handlingCe, data?.handlingCeName);
    const operationsLead = combineUsersData(data?.operationsLead, data?.operationsLeadName);
    const screener = combineUsersData(data?.screener, data?.screenerName);
    return {
        ...data,
        handlingCe,
        operationsLead,
        screener,
    };
}

// 将use的格式变为select的格式
export const filterUsed2SelectState = data => {
    return {
        sipFilterSelected: {
            sipCodeSelected: data.sipCode ?? [],
            sipTitleSelected: data.sipTitle ?? [],
            geNameSelected: data.geName ?? [],
            geEmailSelected: data.geEmail ?? [],
            aimsAndScopeSelected: data.aimsAndScope ?? [],
            topicsSelected: data.topics ?? [],
            keywordsSelected: data.keywords ?? [],
            sipOriginSelected: data.sipOrigin ?? [],
            sipChecklistStatusSelected: data.sipChecklistStatus ?? [],
            preferredSubjectAreaSelected: data.preferredSubjectArea ?? [],
            preferredJournalSelected: data.preferredJournal ?? [],
            regionSelected: data.region ?? [],
            geRegionSelected: data.geRegion ?? [],
            stageSelected: data.stage ?? [],
            timeSensitiveSelected: data.timeSensitive ?? [],
            potentialAuthorsSelected: data.potentialAuthors ?? [],

            handlingCeSelected: data.handlingCe ?? [],
            operationsLeadSelected: data.operationsLead ?? [],
            screenerSelected: data.screener ?? [],

            catGroupSelected: data.catGroup ?? [],

            journalSelected: data.journalName ?? [],

            journalRevenueModelSelected: data.journalRevenueModel ?? [],
            subjectGroupSelected: data.SubjectGroup ?? [],

            submissionFromDate:
                data.submissionFromDate && data.submissionFromDate[0]
                    ? parseISO(data.submissionFromDate[0])
                    : null,
            submissionToDate:
                data.submissionToDate && data.submissionToDate[0]
                    ? parseISO(data.submissionToDate[0])
                    : null,
            decisionFromDate:
                data.decisionFromDate && data.decisionFromDate[0]
                    ? parseISO(data.decisionFromDate[0])
                    : null,
            decisionToDate:
                data.decisionToDate && data.decisionToDate[0]
                    ? parseISO(data.decisionToDate[0])
                    : null,
            creationFromDate:
              data.creationFromDate && data.creationFromDate[0]
                ? parseISO(data.creationFromDate[0])
                : null,
            creationToDate:
              data.creationToDate && data.creationToDate[0]
                ? parseISO(data.creationToDate[0])
                : null,
        },
    };
};

export function ChangeListValue(bool){
    return dispatch=>{
        dispatch({ type :CHANGE_LIST,data:bool});
    };
}
export function setFilters(filters) {
    return dispatch => {
        dispatch({ type: SET_FILTER, data: filters });
    };
}

export function setDate(which, type, newDate) {
    return dispatch => {
        dispatch({
            type: SET_DATE,
            data: { which: which, type: type, newDate: newDate },
        });
    };
}


export function setAdaptSIPFilterSelected(adaptSIPFilterSelected){
    return dispatch => {
        dispatch({ type: SET_ADAPT_SIPFILTERSELECTED, data: adaptSIPFilterSelected });
    };
}

export function setFavSIPFilterSelected(favSIPFilterSelected){
    return dispatch => {
        dispatch({ type: SET_FAV_SIPFILTERSELECTED, data: favSIPFilterSelected });
    };
}

import React from 'react';
import SimpleIconButton from '../SimpleIconButton';
// import IconActive from '../icon/ContainerDeleteIconActive.svg';
import IconDefault from '../icon/AddNewFieldIconDefault.svg';
// import IconDisable from '../icon//ContainerDeleteIconDisable.svg';
export default function AddNewFieldButton(props) {
  return (
    <SimpleIconButton
      DefaultIcon={IconDefault}
    //   HoverIcon={IconActive}
    //   ActiveIcon={IconActive}
    //   DisabledIcon={IconDisable}
      {...props}
    ></SimpleIconButton>
  );
};

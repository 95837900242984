import React, { useEffect, useState } from 'react';
import { Box, Stack, IconButton, TextField, Popover } from '@mui/material';
import SIFilterCalendarIcon from '../../assets/SIFilterCalendarIcon.svg';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MonthCalendar } from './MonthCalendar';
import * as DateFnsUtils from 'date-fns';
import zIndex from '@mui/material/styles/zIndex';

export const MonthPicker = props => {
  const {
    value,
    minDate,
    maxDate,
    handleDateChange,
    placeholder,
    minHeight,
    width = 110,
    border = '2px solid transparent',
    autoClose = true,
    popoverIndex = zIndex,
    innerBorder,
    iconPaddingRight,
    backgroundColor,
    inputTextColor,
    boxSX,
    PopperProps = {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    },
  } = props;
  const [dateOpen, setDateOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [date, setDate] = useState(value);

  useEffect(() => {
    setDate(value);
  }, [value]);

  const handleOpenPicker = event => {
    setDateOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePicker = () => {
    setDateOpen(false);
    setAnchorEl(null);
    handleDateChange(date);
  };

  const handleChange = date => {
    setDate(date);
    if (autoClose){
      setDateOpen(false);
      setAnchorEl(null);
      handleDateChange(date);
    }
  };

  const emptyDateString = '              ';
  const formatDateOptional = dt => {
    return dt ? DateFnsUtils.format(dt, 'yyyy-MM') : emptyDateString;
  };
  const textFieldString = () => {
    if (date) {
      return `${formatDateOptional(date)}`;
    } else {
      return '';
    }
  };

  const id = dateOpen ? 'date-range-popover' : undefined;
  return (
    <LocalizationProvider
    id='MonthPicker-LocalizationProvider'
      dateAdapter={AdapterDateFns}
      data-selenium-id={`SIPage_Overview-newFilterDrawer-FilterDataPicker`}
    >
      <Box
      id='MonthPicker-Container'
        sx={{
          width: width,
          padding: 0,
          paddingLeft: '12px',
          paddingRight: '12px',
          '& .MuiFormControl-root': {
            marginTop: 0,
            marginBottom: 0,
            '& .MuiInputBase-input': {
              paddingTop: 0,
              paddingBottom: 0,
              minHeight: minHeight,
            },
          },
          '& .MuiButtonBase-root.MuiIconButton-root': {
            paddingLeft: '0',
          },
          ...boxSX,
        }}
      >
        <TextField

      id={`MonthPicker-TextField`}
          fullWidth={true}
          value={textFieldString()}
          placeholder={placeholder || 'yyyy-mm'}
          onClick={handleOpenPicker}
          InputProps={{
            endAdornment: (
              <IconButton
              id='TextField-CalendarIcon'
                disableRipple
                onClick={handleOpenPicker}
                sx={{
                  paddingRight: iconPaddingRight,
                  transform: 'translateX(12px)',
                  '&:hover': {
                    backgroundColor: 'transparent !important',
                  },
                }}
              >
                <SIFilterCalendarIcon />
              </IconButton>
            ),
          }}
          sx={{
            '& input': {
              width: width - 20,
              height: '18px',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '125%',
              display: 'flex',
              alignItems: 'center',
              color: inputTextColor,
            },
            width: width,
            borderRadius: '4px',
            backgroundColor: backgroundColor,
            border: border,
            ...(innerBorder === undefined
              ? {
                  '&>div >fieldset': {
                    border: dateOpen && '2px solid #0052CC !important',
                  },
                }
              : // (dateOpen ? {borderColor: '#0052CC' } : {borderColor: '#98A7B6' })
                {
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: innerBorder,
                  },
                }),
            '& :empty': {
              borderColor: '#DFE4E8',
            },
            '& :hover': {
              borderColor: '#262E35',
            },
            '& :focus': {
              borderColor: '#0052CC',
            },
          }}
        />
        <Popover
          id={id}
          open={dateOpen}
          anchorEl={anchorEl}
          onClose={handleClosePicker}
          sx={{
            zIndex: popoverIndex,
            '& .MuiPaper-root': {
              width: '284px',
              height: '274px',
              overflowX: 'hidden',
              overflowY: 'hidden',
              marginTop: '6px',
            },
            '&>div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper>div':
              {
                transform: 'translateY(10px)',
                margin: '0 auto',
              },
          }}
          {...PopperProps}
        >
          {/* <Stack className="DateRange-FromCalendar" spacing={2}> */}
          {/* <Box className="DateRange-Title">From:</Box> */}
          <MonthCalendar
          id='MonthCalendar'
            date={date}
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleChange}
          />
          {/* </Stack> */}
        </Popover>
      </Box>
    </LocalizationProvider>
  );
};

// update password
import {axios_instance} from "@/utils/axios_instance";
import {RESETPASS, UPDATE_PASSWORD} from "@/actions/SIMT-User/Login/LoginAction";
// 原API：Post /user/updatePassword
// export function updatePass(userId) {
//     return async dispatch => {
//         try {
//             const result = await axios_instance.put(
//                 `/api/v1/user-service/users/${userId}/password`,
//                 {
//                     currentPassword: oldPassword,
//                     newPassword: newPassword,
//                 },
//                 {
//                     headers: {
//                         authorization: localStorage.getItem('authorization'),
//                     },
//                 }
//             );

//             if (result.data.code === 200) {
//                 dispatch({type: UPDATE_PASSWORD, data: true});
//             } else {
//                 dispatch({type: UPDATE_PASSWORD, data: false});
//             }
//         } catch (err) {
//             dispatch({type: UPDATE_PASSWORD, data: err.response.data.msg});
//         }
//     };
// } 

// first time login reset password
// 原API：Post /user/resetPassword
export function resetPassFirstTime(userId, currentPassword, newPassword) {
	return async dispatch => {
		try {
		  const url=`/api/v1/user-service/users/${userId}/password`;
		const result = await axios_instance.put(
		  url,
		  {},
		  {
			headers: {
			  authorization: localStorage.getItem('authorization'),
			  },
			  params: {
				  userId:userId,
				  currentPassword: currentPassword,
				  newPassword:newPassword
			  }
		  }
		);
		if (result.data.code === 200) {
		  dispatch({ type: RESETPASS, data: true });
		} else if (
		  result.data.code === 500 &&
		  result.data.message === 'improper password'
		) {
		  dispatch({ type: RESETPASS, data: 'Email Exit' });
		} else {
		  dispatch({ type: RESETPASS, data: false });
		}
	  } catch (err) {
		dispatch({ type: RESETPASS, data: err.response.data.msg });
	  }
	};
  }
  
// @ts-check
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  SIMTButton,
  SIMTLoadingButton,
} from '../../../../components/StyledButton/SIMTButton';
import EndButtonArrowUnfold from '@/assets/EndButtonArrowUnfold.svg';
import {
  CancelBtn,
  DecisionUnfoldBtn,
  DecisionUnfoldSavedBtn,
  defaultVars,
  ErrVars,
  NextBtn,
  SubmitBtn,
} from '@/pages/SIPPage/SIPDetailPage/DecisionSection/DecisionMyStyled';
import {
  Box,
  Collapse,
  Container,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  FormControl,
  FormHelperText,
} from '@mui/material';
// @ts-ignore
import SIPReviseSaveButtonIcon from '../../../../assets/SIPReviseSaveButtonIcon.svg';
import { MulSelectInput } from '@/componentsphase2/SelectBox/SelectInput';
// @ts-ignore
import SIPViewAllArrow from '@/assets/SIPViewAllArrow.svg';
import SIModal from '../../../../modules/Modal';
import {
  CustomTextArea,
  CustomTextFieldTypeOne,
  ItemBlock,
} from '../../SIPDetailPage/EditComponents';
import { StickyBarWithAccordionPanelButton } from './CustomButton';
import { DecisionTitle } from '../../SIPDetailPage/DecisionSection/DecisionTitle';
import { DecisionSelectBlock } from '../../SIPDetailPage/DecisionSection/DecisionSelectBlock';
import { DatePicker } from '../../SIPDetailPage/EditComponents';
import moment from 'moment';
import { SubmitConfirmModal } from './CustomModal';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import { useDispatch, useSelector } from 'react-redux';
import { GetPopperBottomContext } from '@/componentsphase2/Autocomplete/CommonContext';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import {
  commonButtonContainerStyle,
  commonPopContainerStyle,
} from '../../commonStyle';
import CheckListButton from '../../SIPDetailPage/DecisionSection/Component/CheckListButton';
/**
 *
 * @param {import("./CustomStickyBar").StickyBarContainerProps} props
 * @returns
 */
export const StickyBarContainer = props => {
  const { children, sx, ...others } = props;
  return (
    <Box
      data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-Box'
      sx={{
        position: 'sticky',
        bottom: 0,
        zIndex: 10,
        boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.1)',
        paddingX: '32px',
        backgroundColor: '#fff',
        ...sx,
      }}
      {...others}
    >
      {children}
    </Box>
  );
};

/**
 *
 * @param {import("./CustomStickyBar").SaveOrSubmitStickyBarProps} props
 * @returns
 */
export const SaveOrSubmitStickyBar = props => {
  const {
    confirmText = `Are you sure to submit the revision? After you click on "OK", you
    will not be able to access this page anymore.`,
    savingDraft = false,
    handleSaveDraft,
    submitting = false,
    handleSubmit,
    handleErrorCheck,
    ...others
  } = props;
  const [confirmSubmitModalOpen, setConfirmSubmitModalOpen] = useState(false);
  return (
    <StickyBarContainer
      {...others}
      data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-SaveOfSubmit-Container'
    >
      <Stack
        data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-SaveOfSubmit-Stack'
        direction={'row'}
        alignItems='center'
        justifyContent={'flex-end'}
        spacing={'24px'}
        height='64px'
      >
        <SIMTLoadingButton
          data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-SaveOfSubmit-LoadingButton'
          loading={savingDraft}
          variant='outlined'
          sx={{ border: '1px solid #DFE4E8', color: '#596A7C' }}
          startIcon={<SIPReviseSaveButtonIcon />}
          onClick={() => {
            if (handleErrorCheck()) {
              handleSaveDraft();
            }
          }}
        >
          Save Draft
        </SIMTLoadingButton>
        <SIMTButton
          data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-SaveOfSubmit-Button'
          variant='contained'
          sx={{ '&.MuiButton-root': { fontWeight: 400 } }}
          onClick={() => {
            if (handleErrorCheck()) {
              setConfirmSubmitModalOpen(true);
            }
          }}
        >
          Submit My Revision
        </SIMTButton>
        <SubmitConfirmModal
          data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-SaveOfSubmit-SubmitConfirmModal'
          open={confirmSubmitModalOpen}
          handleClose={() => setConfirmSubmitModalOpen(false)}
          confirmText={confirmText}
          handleSubmit={() => {
            handleSubmit();
            setConfirmSubmitModalOpen(false);
          }}
        />
      </Stack>
    </StickyBarContainer>
  );
};

/**
 *
 * @param {import("./CustomStickyBar").StickyBarWithAccordionPanelContainerProps} props
 * @returns
 */
export const StickyBarWithAccordionPanelContainer = props => {
  const {
    sx = {},
    children,
    panelElement,
    accordionPanelOpen,
    stickyBarProps = {},
    panelProps = {},
    ...others
  } = props;

  const { sx: stickyBarSx, ...stickyBarOthers } = stickyBarProps;
  const { sx: panelSx, ...panelOthers } = panelProps;

  return (
    <StickyBarContainer
      data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-AccordionPanel-Container'
      sx={{
        paddingX: 0,
        ...sx,
      }}
      {...others}
    >
      <Box
        data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-AccordionPanel-Box'
        sx={{
          position: 'relative',
        }}
      >
        <Box
          data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-AccordionPanel-InnerBox1'
          sx={{
            position: 'relative',
            zIndex: 10,
            paddingX: '32px',
            backgroundColor: '#fff',
            ...(stickyBarSx ? stickyBarSx : {}),
          }}
          {...stickyBarOthers}
        >
          {children}
        </Box>
        <Box
          data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-AccordionPanel-InnerBox2'
          sx={{
            position: 'absolute',
            bottom: '100%',
            right: 0,
            backgroundColor: '#F6F7F8',
            width: '100%',
            zIndex: 9,
            boxShadow: '0px 13px 37px rgba(0, 0, 0, 0.21)',
            ...(panelSx ? panelSx : {}),
          }}
          {...panelOthers}
        >
          <Collapse
            in={accordionPanelOpen}
            data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-AccordionPanel-Collapse'
          >
            <Box
              sx={{
                padding: '20px 32px 8px 64px',
              }}
              className='AccordionPanel-container'
            >
              {panelElement}
            </Box>
          </Collapse>
        </Box>
      </Box>
    </StickyBarContainer>
  );
};

/**
 *
 * @param {import("./CustomStickyBar").AddCommentsStickyBarProps} props
 * @returns
 */
export const AddCommentsStickyBar = props => {
  const { submitComment } = props;
  const [commentPanelOpen, setCommentPanelOpen] = useState(false);
  const [comments, setComments] = useState('');
  const [commentError, setCommentError] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const checkValidation = () => {
    setShowErrorMessage(commentError);
    return !commentError;
  };

  const handleSubmit = () => {
    submitComment(comments);
  };

  useEffect(() => {
    if (commentPanelOpen) setShowErrorMessage(false);
  }, [commentPanelOpen]);

  const [confirmSubmitModalOpen, setConfirmSubmitModalOpen] = useState(false);

  return (
    <StickyBarWithAccordionPanelContainer
      accordionPanelOpen={commentPanelOpen}
      panelElement={
        <>
          <ItemBlock
            title='Comments'
            mandatory={true}
            sx={{
              marginBottom: '4px',
              width: '100%',
              '& .SIP-Detail-ItemBlock-TitleTextBox': {
                width: '120px',
              },
            }}
          >
            <CustomTextArea
              id='sipReviseAimsandScopeInput'
              data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-AimsandScopeInput'
              value={comments}
              setValue={newValue => setComments(newValue)}
              minRows={3}
              maxRows={3}
              needEmptyCheck={true}
              errorMonitor={showErrorMessage}
              setError={setCommentError}
              setIsShowHelperText={true}
              error={showErrorMessage}
              helperText={'Please input the mandatory field!'}
            />
          </ItemBlock>
          <Stack
            data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-AddComments-Container-Stack'
            direction={'row'}
            alignItems='center'
            justifyContent={'flex-end'}
            spacing='16px'
          >
            <SIMTButton
              onClick={() => setCommentPanelOpen(false)}
              data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-AddComments-CancelSIMTButton'
            >
              Cancel
            </SIMTButton>
            <SIMTButton
              data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-AddComments-SubmitSIMTButton'
              variant='contained'
              onClick={() => {
                if (!checkValidation()) return;
                setConfirmSubmitModalOpen(true);
              }}
            >
              Submit
            </SIMTButton>
          </Stack>
          <SubmitConfirmModal
            open={confirmSubmitModalOpen}
            handleClose={() => setConfirmSubmitModalOpen(false)}
            confirmText={`Are you sure to submit the comments? After you click on "OK", you will not be able to access this page anymore.`}
            handleSubmit={() => {
              handleSubmit();
              setConfirmSubmitModalOpen(false);
            }}
          />
        </>
      }
      {...props}
    >
      <Stack
        data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-AddComments-Stack'
        direction={'row'}
        alignItems='center'
        justifyContent={'flex-end'}
        height='64px'
      >
        <StickyBarWithAccordionPanelButton
          onClick={() => setCommentPanelOpen(current => !current)}
        >
          Comments
        </StickyBarWithAccordionPanelButton>
      </Stack>
    </StickyBarWithAccordionPanelContainer>
  );
};

/**
 *
 * @param {import("./CustomStickyBar").MakeDecisionStickyBarProps} props
 * @returns
 */
export const MakeDecisionStickyBar = props => {
  const {
    decisionConfigList,
    sipCode = '',
    // SIPChecklistStatus = 'Editable',
  } = useSelector(state => {
    return {
      decisionConfigList: state.SIP.decisionConfig,
      sipCode: state?.SIP?.sipReviseData?.sipCode,
      // SIPChecklistStatus: state?.SIPChecklist?.SIPChecklistDetail?.status,
    };
  });
  // const [isDecisionButtonDisabled, setIsDecisionButtonDisabled] = useState(false);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   // if (isSubmitted(sipStage)) {
  //     dispatch(getSIPChecklistDetail(sipCode));
  //   // }
  // },[]);
  // useEffect(() => setIsDecisionButtonDisabled(!isProceeded(SIPChecklistStatus)), [SIPChecklistStatus]);
  const { handleSubmitDecision, setDecisionIsFold, setIsSave } = props;
  /**
   * @typedef {import("../EditorReviewPage").DecisionType} DecisionType
   */
  const [commentPanelOpen, setCommentPanelOpen] = useState(false);

  const [decisionType, setDecisionType] = useState(
    /** @type {DecisionType | ""} */ ('')
  );
  const [decisionTypeError, setDecisionTypeError] = useState(false);

  const [revisionDeadline, setRevisionDeadline] = useState(
    /** @type {Date | null} */ (moment().add(2, 'weeks').toDate())
  );
  const [revisionDeadlineError, setRevisionDeadlineError] = useState(false);

  const [decidedBy, setDecidedBy] = useState('');
  const [decidedByError, setDecidedByError] = useState(false);

  const COMMENT_LIMIT_LENGTH = 100000;
  const [comments, setComments] = useState('');
  const [commentError, setCommentError] = useState(false);
  const [commentErrorText, setCommentErrorText] = useState('');
  const [rejectReason, setRejectReason] = useState([]);
  setIsSave(comments !== '' || decisionType !== '');
  useEffect(() => {
    setCommentError(() => {
      const emptyError =
        decisionType !== '' && decisionType !== 'Accept' && comments === '';
      const overpassError = comments.length > COMMENT_LIMIT_LENGTH;
      const error = emptyError || overpassError;
      if (error) {
        if (emptyError) {
          setCommentErrorText('Please input the mandatory field!');
        } else if (overpassError) {
          setCommentErrorText(
            'The character count should be no more than 100,000.'
          );
        }
      }
      return error;
    });
  }, [comments, decisionType]);

  const [formError, setFormError] = useState(false);
  const [panelErrorMessage, setPanelErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [focusComments, setFoucusComments] = useState(false);
  const [scaleToUse, setScaleToUse] = useState(1);
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      // const screenHeight = window.innerHeight;
      if (screenWidth - 10 < 1300.0) {
        setScaleToUse((screenWidth - 10) / 1300.0);
      } else {
        setScaleToUse(1);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const valid = checkValidation();
    if (valid) {
      setShowErrorMessage(false);
    }
  }, [
    decisionTypeError,
    revisionDeadlineError,
    decidedByError,
    commentError,
    commentErrorText,
  ]);

  const checkValidation = () => {
    const res =
      decisionTypeError ||
      revisionDeadlineError ||
      decidedByError ||
      commentError;

    if (decisionTypeError || revisionDeadlineError || decidedByError)
      setPanelErrorMessage('Please input the mandatory field!');
    else if (commentError) {
      setPanelErrorMessage(commentErrorText);
    }

    setFormError(res);
    return !res;
  };

  const [selectedReasons, setSelectedReasons] = useState([]);
  const handleSubmit = () => {
    if (decisionType === '') return;
    handleSubmitDecision({
      decisionType: decisionType,
      decideBy: decidedBy,
      revisionDeadline:  revisionDeadline && revisionDeadline instanceof Date ? revisionDeadline.toISOString() : revisionDeadline,
      comment: comments,
      reasons: selectedReasons,
    });
  };

  useEffect(() => {
    if (commentPanelOpen) {
      setFormError(false);
      setShowErrorMessage(false);
    }
  }, [commentPanelOpen]);

  const matches960 = useMediaQuery('(min-width:960px)');
  const [popperBottom, setPopperBottom] = useState(0);

  useEffect(() => {
    setDecisionTypeError(false);
    setRevisionDeadlineError(false);
    setDecidedByError(false);

    setDecisionTypeError(decisionType === '');

    if (decisionType === 'Revise')
      setRevisionDeadlineError(revisionDeadline === null);

    setDecidedByError(decidedBy === '');
  }, [decisionType, revisionDeadline, decidedBy]);

  const [confirmSubmitModalOpen, setConfirmSubmitModalOpen] = useState(false);
  const editorRejectDropList = [
    'Mismatching Content Scope',
    'Ineligible Guest Editors',
    'Others',
  ];
  const [isOthersSelected, setIsOthersSelected] = useState(false);
  const [hasEmptyError, setHasEmptyError] = useState(false);
  const handleDecisionTypeChange = (event, value) => {
    setDecisionType(value);
  };
  const decisionTypeList = ['Accept', 'Reject', 'Revise'];
  function hasValue(value) {
    if (value) {
      if (value instanceof Array) {
        // 数组情况，长度非0， 零号元素非空
        return value.length > 0 && value[0] && value[0] !== '';
      } else return value !== '';
    } else {
      return false;
    }
  }
  const DecisionContainer = {
    minWidth: '1300px',
    minHeight: '256px',
    position: 'sticky', // 外部需要有一个容器让他贴靠底部
    bottom: 0,
    boxSizing: 'border-box',
    // marginLeft: openPop ? '240px' : '83px',
    transition: 'all 0.15s linear',
    zIndex: '1200',
    // overflow: 'hidden'
  };
  const popContainer = commonPopContainerStyle;
  const buttonContainer = commonButtonContainerStyle;
  const [temBoxHeight, setTemBoxHeight] = useState(0);

  const Checklist = () => {
    return (
      <span
        style={{
          color: '#154AB6',
          cursor: 'pointer',
          marginLeft: decisionType === 'Reject' ? '805px' : '1072px',
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
        }}
      >
        Checklist
      </span>
    );
  };
  const RetractionDatabase = () => {
    if (decisionType === 'Reject') {
      return (
        <span
          style={{
            color: '#154AB6',
            cursor: 'pointer',
            marginLeft: '24px',
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            whiteSpace: 'nowrap',
          }}
        >
          Retraction Database
        </span>
      );
    }
  };
  return (
    // <StickyBarWithAccordionPanelContainer
    //   accordionPanelOpen={commentPanelOpen}
    //   panelElement={
    // <Box data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-MakeDicision-Box'>
    //   <Typography
    //     data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-MakeDicision-Typography'
    //     sx={{
    //       font: 'Open Sans',
    //       fontWeight: 600,
    //       fontSize: '16px',
    //       color: '#262E35',
    //       lineHeight: '24px',
    //       '& span': {
    //         color: '#EE5350',
    //         fontSize: '12px',
    //         fontWeight: 400,
    //         marginLeft: '8px',
    //       },
    //     }}
    //   >
    //     Decision
    //     {formError && showErrorMessage && <span>{panelErrorMessage}</span>}
    //   </Typography>
    <div
      style={{
        ...DecisionContainer,
        ...props.style,
        ...(scaleToUse < 1 && {
          transform: `scale(${scaleToUse}, ${scaleToUse})`,
          transformOrigin: 'left bottom',
        }),
      }}
    >
      <div style={popContainer}>
        {/* 下部一直在的Decision按钮 */}
        <div style={buttonContainer}>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              height: '100%',
            }}
          >
            <Stack
              direction='row'
              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-DownStack'
              gap={3}
            >
              {/* <CheckListButton sipCode={sipCode} /> */}
              {comments || decisionType ? (
                <DecisionUnfoldSavedBtn
                  onClick={() => {
                    setDecisionIsFold(true);
                  }}
                  id='DecisionButton'
                  data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-Button'
                  variant='contained'
                  endIcon={
                    <EndButtonArrowUnfold data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-EndButtonArrowUnfold'></EndButtonArrowUnfold>
                  }
                >
                  <FormattedMessage
                    id='siDetail.decision'
                    data-selenium-id='SIP_DetailPage_Section-Unfole-DownStack-Decision'
                  />
                </DecisionUnfoldSavedBtn>
              ) : (
                <DecisionUnfoldBtn
                  onClick={() => {
                    setDecisionIsFold(true);
                  }}
                  id='DecisionButton'
                  data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-Button'
                  variant='contained'
                  endIcon={
                    <EndButtonArrowUnfold data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-EndButtonArrowUnfold'></EndButtonArrowUnfold>
                  }
                >
                  <FormattedMessage
                    id='siDetail.decision'
                    data-selenium-id='SIP_DetailPage_Section-Unfole-DownStack-Decision'
                  />
                </DecisionUnfoldBtn>
              )}
            </Stack>
          </div>
        </div>
        <Stack
          data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-InnerStack'
          direction='column'
          justifyContent='flex-start'
          sx={{ height: '100%', maxWidth: '1092px' }}
        >
          {/* Decision头 */}
          <Stack
            data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-HeadStack'
            direction='row'
            justifyContent='space-between'
            sx={{ height: '38.6px', mt: '5.9px' }}
          >
            {/* 头部左边 */}
            <Stack
              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-LeftStack1'
              direction='row'
              justifyContent='flex-start'
              spacing={1}
              sx={{
                width: '301px',
                height: '24px',
                alignItems: 'center',
                padding: '0px',
              }}
            >
              <Box
                data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-Box1'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '66px',
                  height: '24px',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#113D95',
                  textTransform: 'capitalize',
                }}
              >
                {' '}
                <FormattedMessage
                  id='siDetail.decision'
                  data-selenium-id='SIP_DetailPage_Section-Unfole-LeftStack-Decision'
                />
                {/* <Checklist  /> */}
                {/* <RetractionDatabase/> */}
              </Box>
            </Stack>
            {/* 头部右边 */}
            <Stack
              direction={'row'}
              justifyContent='center'
              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-HeadRightStack'
            >
              {decisionConfigList.map((item, index) => (
                <Box
                  id={item.type + index}
                  data-selenium-id={`SIP_DetailPage_DecisionSection-Unfole-Box1-${
                    item.type + index
                  }`}
                  onClick={() => {
                    window.open(item.value);
                  }}
                  sx={{
                    color: '#154AB6',
                    cursor: 'pointer',
                    marginLeft: decisionType === 'Reject' ? '800px' : '950px',
                    fontFamily: 'Open Sans',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                  }}
                >
                  {' '}
                  {item.key}
                </Box>
              ))}
            </Stack>
          </Stack>

          {/* Decision表中间 */}
          <Stack
            data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-CenterStack'
            direction='row'
            // flexWrap='wrap'
            sx={{ justifyContent: 'space-between' }}
          >
            <GetPopperBottomContext.Provider
              value={{ setValue: setPopperBottom }}
            >
              {/* 左边 */}
              <Stack
                data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-CenterStack-Left'
                direction='column'
                justifyContent='flex-start'
                spacing={2}
                height={'120px'}
              >
                <Stack
                  data-selenium-id='SIP_DetailPage_DecisionSection-TypeBlock-Stack'
                  direction={'row'}
                  justifyContent='left'
                  alignItems='flex-start'
                  flexWrap='wrap'
                >
                  <DecisionTitle
                    id='DecisionType'
                    data-selenium-id='SIP_DetailPage_DecisionSection-TypeBlock-DecisionType'
                    title='Decision Type'
                    width='123px'
                    // paddingRight='23px'
                  ></DecisionTitle>

                  <Box
                    sx={{
                      width: '286px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      backgroundColor: '#FFFFFF',
                      '&>div>div>div>fieldset':{
                        mt:'.8q'
                      }
                    }}
                  >
                    <SinpleAutocomplete
                      data-selenium-id='SIP_DetailPage_DecisionSection-TypeBlock-Value'
                      id='sinpleAutocompleteDecisionType'
                      placeholder='Select Decision Type'
                      options={decisionTypeList}
                      defaultValue=''
                      attribute='value'
                      onChange={handleDecisionTypeChange}
                      value={decisionType}
                      defaultQuery={true}
                      needClearInputValue={false}
                      readOnly={true}
                    />
                  </Box>
                </Stack>
                {decisionType === 'Revise' && (
                  <ItemBlock
                    title='Revision Deadline'
                    mandatory={true}
                    sx={{
                      '& .SIP-Detail-ItemBlock-TitleTextBox': {
                        width: '123px',
                      },
                      '& .css-zvt7gg': {
                        width: '100%',
                      },
                      '& .MuiFormControl-root': {
                        width: '286px',
                        '& fieldset': {
                          border:
                            showErrorMessage && revisionDeadlineError
                              ? '1px solid #EE5350'
                              : '1px solid #DFE4E8',
                        },
                        '& .Mui-focused fieldset': {
                          borderColor:
                            showErrorMessage && revisionDeadlineError
                              ? '#EE5350'
                              : '#154AB6',
                        },

                        marginLeft: '0px',
                      },
                    }}
                  >
                    <DatePicker
                      id='epDate'
                      data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-MakeDicision-EpDate'
                      value={revisionDeadline}
                      setValue={newValue => {
                        setRevisionDeadline(newValue);
                      }}
                    />
                  </ItemBlock>
                )}
                <Stack
                  direction='row'
                  data-selenium-id='SIP_DetailPage_DecisionSection-Unfold-FormControl5-Stack'
                >
                  <DecisionTitle
                    id='rejectTitle'
                    data-selenium-id='SIP_DetailPage_DecisionSection-Unfold-rejectTitle'
                    title='Decided by'
                    width='123px'
                    white-space='no-warp'
                  />
                  <Box
                    sx={{
                      width: '286px',
                      height: '40px',
                      backgroundColor: '#FFFFFF',
                      '& .MuiOutlinedInput-root': {
                        height: '40px',
                      },
                    }}
                  >
                    <CustomTextFieldTypeOne
                      id='sipReviseDecisionDecidedBy'
                      data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-MakeDicision-DecidedBy'
                      value={decidedBy}
                      onChange={e => {
                        setDecidedBy(e.target.value);
                      }}
                      placeholder='Please input your name here'
                      error={decidedByError && showErrorMessage}
                      sx={{
                        height: '40px',
                      }}
                    />
                  </Box>
                </Stack>

                {decisionType === 'Reject' && (
                  <Stack
                    direction='row'
                    height='50px'
                    data-selenium-id='SIP_DetailPage_DecisionSection-Unfold-FormControl5-Stack'
                  >
                    <DecisionTitle
                      id='rejectTitle'
                      data-selenium-id='SIP_DetailPage_DecisionSection-Unfold-rejectTitle'
                      title='Reject Reason(s)'
                      width='123px'
                      white-space='no-warp'
                    />
                    <Box
                      sx={{
                        width: '286px',
                        height: '40px',
                        backgroundColor: '#FFFFFF',
                      }}
                    >
                      <MulSelectInput
                        id='JournalMutiSelect-AutoComplete'
                        width='286px'
                        value={rejectReason}
                        options={editorRejectDropList}
                        placeholder={'Select Reject Reason'}
                        showStartIcon={false}
                        disableNewItem={true}
                        disableCheckBox={false}
                        showPopupIcon={true}
                        onValueChange={newValue => {
                          setRejectReason(newValue);
                          setSelectedReasons(newValue);
                          if (newValue.includes('Others'))
                            setIsOthersSelected(true);
                          else setIsOthersSelected(false);
                          if (newValue.length === 0) setHasEmptyError(true);
                          else setHasEmptyError(false);
                        }}
                        sx={{
                          backgroundColor: '#ffffff', // 设置背景颜色为白色
                        }}
                      />
                    </Box>
                  </Stack>
                )}
              </Stack>
            </GetPopperBottomContext.Provider>
            <Stack
              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-RightStack'
              direction={'row'}
              justifyContent='space-between'
              // flexWrap='wrap'
              spacing={1}
              // sx={{ marginLeft: '171px'}}
            >
              <DecisionTitle
                id='Comments'
                data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-Comments'
                title='Comments'
                width='82px'
                paddingRight='8'
                isOthersSelected={isOthersSelected}
                decisionType={decisionType}
              ></DecisionTitle>
              <FormControl>
                <MyOnlyText
                  id='CommentsText'
                  data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-CommentsText'
                  multiline='true'
                  width='420px'
                  height='101px'
                  alwaysHaveEndAdornment={true}
                  value={comments}
                  setValue={setComments}
                  isError={comments.length > 100000 && !focusComments}
                  errorMessage={'Please input no more than 100,000 characters.'}
                  onFocus={() => {
                    setFoucusComments(true);
                  }}
                  onBlur={() => {
                    setFoucusComments(false);
                  }}
                  minRows={5}
                  maxRows={5}
                />
                <FormHelperText
                  data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormHelperText'
                  sx={{
                    textAlign: 'right',
                    margin: '0px',
                    marginTop:
                      comments.length > 100000 && !focusComments
                        ? '-14px'
                        : '4px',
                    fontSize: '12px',
                    lineHeight: '18px',
                    height: '15px',
                    color: comments.length > 100000 ? '#DE350B' : '#596A7C',
                    marginBottom: '3px',
                  }}
                >
                  {comments.length}/100,000
                </FormHelperText>
              </FormControl>
            </Stack>
          </Stack>

          {/* cancle and submit button */}
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems='center'
            spacing='16px'
            marginTop={'91px'}
          >
            <SIMTButton
              onClick={() => {
                setCommentPanelOpen(false);
                setDecisionIsFold(true);
              }}
              data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-MakeDicision-CancelSIMTButton'
            >
              Cancel
            </SIMTButton>
            <SIMTButton
              data-selenium-id='SIP_RevisePage_CustomComponents-StickyBar-MakeDicision-SubmitSIMTButton'
              variant='contained'
              onClick={() => {
                setConfirmSubmitModalOpen(true);
                // if (!checkValidation()) {
                //   setShowErrorMessage(true);
                //   return;
                // }
              }}
              disabled={
                (decisionType === 'Reject' &&
                  (hasEmptyError ||
                    (isOthersSelected && !hasValue(comments)))) ||
                (decisionType === 'Revise' && !hasValue(comments)) ||
                !hasValue(decidedBy) ||
                comments.length > 100000
              }
            >
              Submit
            </SIMTButton>
          </Stack>
        </Stack>

        <SubmitConfirmModal
          open={confirmSubmitModalOpen}
          handleClose={() => {
            setConfirmSubmitModalOpen(false);
            setDecisionIsFold(true);
          }}
          confirmText={`Are you sure to submit the decision? After you click on "OK", you will not be able to access this page.`}
          handleSubmit={() => {
            handleSubmit();
            setConfirmSubmitModalOpen(false);
            setDecisionIsFold(true);
          }}
        />
      </div>
      {/* 下部一直在的Decision按钮 */}
      <div style={{ ...buttonContainer, marginRight: '0' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            height: '100%',
          }}
        ></div>
      </div>
      <Box
        id='temBox'
        sx={{
          height: `${temBoxHeight}px`,
          width: '100%',
          backgroundColor: '#FAFAFA',
        }}
      />
    </div>
  );
};

//自定义的选择框
import { Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DropdownValuesStyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    padding: '4px 12px',
    border: `1px solid ${theme.palette.wileyBlue2.main}`,
    borderRadius: '3px',
  },
}));

import styles from './index.module.scss';
import React, {ChangeEvent, useRef, useState} from 'react';
import {
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Typography,
    TableBody,
    OutlinedInput, InputAdornment, Popover
} from "@mui/material";
import DropdownClearIcon from "@/pages/SIPForm/assets/dropdown-clear-16_16.svg";
import moment from "moment";
import LeftArrow from "@/pages/SIPForm/assets/date-picker-left-arrow-8_14.svg";
import CalenderIcon from "@/pages/SIPForm/assets/calendar-20_20.svg";

export interface DateInputProps {
    id?: string;
    value: string;
    valueFormat?: string;
    displayFormat?: string;
    minDate?: string, // following the format of valueFormat
    maxDate?: string, // following the format of valueFormat
    onChange?: (oldValue: string, newValue: string) => void;
    placeholder?: string;
    error?: boolean;
    enableClear?: boolean;
}

export function formatWeekday(weekday: string) {
    switch (weekday) {
        case 'Su':
            return 'SUN';
        case 'Mo':
            return 'MON';
        case 'Tu':
            return 'TUE';
        case 'We':
            return 'WED';
        case 'Th':
            return 'THU';
        case 'Fr':
            return 'FRI';
        case 'Sa':
            return 'SAT';
        default:
            return '';
    }
}

export function DateInput(props: DateInputProps) {
    const {
        value,
        valueFormat = 'YYYY-MM-DD',
        displayFormat = 'MM/DD/YYYY',
        onChange = (oldValue, newValue) => {},
        placeholder = 'MM/DD/YYYY',
        error,
    } = props;

    const [date, setDate] = useState<Date | null>(moment(value, valueFormat).toDate());
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

    const ref = useRef<HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<Element>) => {
        if (ref.current){
            setAnchorEl(ref.current);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value) {
            setDate(null);
        } else {
            const date = moment(e.target.value, displayFormat);
            if (date.isValid()) {
                setDate(date.toDate());
            }
        }
    };

    const handleDateChoose = (date: Date | null) => {
        setDate(date);
        if (date) {
            onChange(value, moment(date).format(valueFormat));
        }
        setAnchorEl(null);
    };

    const showDate = React.useMemo(() => {
        const dateString = moment(date).format(displayFormat);
        if (dateString === 'Invalid date') {
            return '';
        }
        return dateString;
    }, [date]);

    const open = Boolean(anchorEl);

    return (<>
        <OutlinedInput
            ref={ref}
            className={styles['date-input']
                + ' ' + (error ? styles['error'] : '')
                + ' ' + (open  ? 'Mui-focused' : '')
            }
            value={showDate}
            readOnly={true}
            // onChange={handleInputChange}
            onClick={handleClick}
            placeholder={placeholder}
            endAdornment={
                <InputAdornment position="end">
                    { props.enableClear && date &&
                        (<DropdownClearIcon className={styles['clear-icon']} onClick={_ => setDate(null)} />)
                    }
                    <CalenderIcon className={styles['calender-icon']} onClick={handleClick} />
                </InputAdornment>
            }
            fullWidth={true}
        />
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <DatePanel
                date={date}
                setDate={handleDateChoose}
                minDate={props.minDate ? moment(props.minDate, valueFormat).toDate() : undefined}
                maxDate={props.maxDate ? moment(props.maxDate, valueFormat).toDate() : undefined}
            />
        </Popover>
    </>);
}

interface DayInstance {
    date: string;
    dateNumber: number;
    isCurrentMonth: boolean;
    isCurrentDay: boolean;
    isSelected: boolean;
    isDisabled: boolean;
}

function getFirstValidDate(minDate: Date|undefined, maxDate: Date|undefined,
        ...dates: (Date|null|undefined)[]): Date | null {

    for (let date of dates) {
        if (date && moment(date).isValid()) {
            if (minDate && moment(date).isBefore(minDate)) {
                continue;
            }
            if (maxDate && moment(date).isAfter(maxDate)) {
                continue;
            }
            return date as Date;
        }
    }
    if (minDate) {
        return minDate;
    }
    return null;
}

function DatePanel(props: {
    date: Date | null,
    minDate?: Date,
    maxDate?: Date,
    startMonth?: Date,
    setDate: (date: Date | null) => void
}) {
    const currentDay = new Date();
    const startMonth = getFirstValidDate(props.minDate, props.maxDate, props.startMonth, props.date, currentDay);
    const [month, setMonth] = useState<Date>(startMonth as Date);
    const [selectedDate, setSelectedDate] = useState<Date | null>(props.date);

    const daysInMonth = React.useMemo(() => {
        const currentDay = moment().startOf('day');
        const firstDay = moment(month).clone().startOf('month').startOf('day');
        const firstDayOfWeek = firstDay.add(0 - firstDay.day(), 'days');
        const lastDay = moment(month).clone().endOf('month').startOf('day');
        const lastDayOfWeek = lastDay.add(6 - lastDay.day(), 'days');

        let count: number = 0;
        let days: DayInstance[] = [];
        let weeks: DayInstance[][] = [];

        let startDate = firstDayOfWeek.clone();
        while (startDate.isSameOrBefore(lastDayOfWeek)) {
            days.push({
                date: startDate.format('YYYY-MM-DD'),
                dateNumber: startDate.date(),
                isCurrentMonth: startDate.isSame(month, 'month'),
                isCurrentDay: startDate.isSame(currentDay, 'day'),
                isSelected: selectedDate ? startDate.isSame(selectedDate, 'day') : false,
                isDisabled: (props.minDate && startDate.isBefore(moment(props.minDate))) ||
                    (props.maxDate && startDate.isAfter(moment(props.maxDate))) || false,
            });
            startDate.add(1, 'days');
            count++;
            if (count === 7) {
                weeks.push(days);
                days = [];
                count = 0;
            }
        }

        return weeks;
    }, [month, selectedDate, props.minDate, props.maxDate]);
    const enablePrevMonth = !props.minDate || moment(month).subtract(1, 'months').endOf('month').isSameOrAfter(moment(props.minDate), 'day');
    const enableNextMonth = !props.maxDate || moment(month).add(1, 'months').startOf('month').isSameOrBefore(moment(props.maxDate), 'day');

    return (
        <Box className={styles['date-panel']}>
            <Box className={styles['header-line']}>
                <Box className={styles['month-year']}>
                    <span>{month.toLocaleString('en', { month: 'long' })}</span>
                    <span>{month.getFullYear()}</span>
                </Box>
                <Box className={styles['nav-buttons']}>
                    <Box className={styles['nav-button'] + ' ' + styles['prev-arrow'] + (enablePrevMonth ? '' : ' ' + styles['disabled']) }
                         onClick={_ => {
                             enablePrevMonth &&
                             setMonth(new Date(month.getFullYear(), month.getMonth() - 1));
                         }}
                    >
                        <LeftArrow />
                    </Box>
                    <Box className={styles['nav-button'] + ' ' + styles['next-arrow'] + (enableNextMonth ? '' : ' ' + styles['disabled']) }
                         onClick={_ => {
                             enableNextMonth &&
                             setMonth(new Date(month.getFullYear(), month.getMonth() + 1));
                         }}
                    >
                        <LeftArrow />
                    </Box>
                </Box>
            </Box>
            <Table className={styles['date-table']}>
                <TableHead>
                    <TableRow className={styles['day-names']}>
                        <TableCell><Typography>Sun</Typography></TableCell>
                        <TableCell><Typography>Mon</Typography></TableCell>
                        <TableCell><Typography>Tue</Typography></TableCell>
                        <TableCell><Typography>Wed</Typography></TableCell>
                        <TableCell><Typography>Thu</Typography></TableCell>
                        <TableCell><Typography>Fri</Typography></TableCell>
                        <TableCell><Typography>Sat</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={styles['days']}>
                    {daysInMonth.map((week, index) => (
                        <TableRow key={index} className={styles['week']}>
                            {week.map((day, index) => (
                                <TableCell key={index} className={
                                    styles['day'] + ' ' + (day.isCurrentDay ? styles['current-day'] : '')
                                    + ' ' + (day.isSelected ? styles['selected'] : '')
                                    + ' ' + (day.isDisabled ? styles['disabled'] : '')
                                    + ' ' + (day.isCurrentMonth ? '' : styles['not-current-month'])
                                }
                                           onClick={_ => {
                                               if (day.isDisabled) return;
                                               setSelectedDate(new Date(day.date));
                                               props.setDate(new Date(day.date));
                                           }}
                                >
                                    <Typography>{day.dateNumber}</Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
}
import { Box, Stack } from '@mui/material';
import IconBox from '@/pages/SIPage/Overview/Filter/component/CommonComponets/IconBox';
import SearchIcon from '@/pages/SIPage/Overview/Filter/component/SVGComponents/SearchIcon.svg';
import { ColumnCentered } from '@/pages/SIPage/Overview/Filter/component/Css';
// Syltes
import { testInputFont } from '@/components/FontStyle';

export default function DefaultSelectBox() {
  return (
    <Box
      sx={{
        wiDth: '100%',
        height: '40px',
        boxSizing: 'border-box',
        padding: '11px 12px',
        backgroundColor:'#DFE4E8',
        borderRadius:'4px'
      }}
    >
      <Stack spacing={1} direction='row' sx={ColumnCentered}>
        <IconBox svgBackgroundColor='#DFE4E8' pathFill='#98A7B6'>
          <SearchIcon />
        </IconBox>
        <Box sx={{ ...testInputFont, lineHeight: '17.5px', color: '#98A7B6' }}>
          Select a category to refine your search results
        </Box>
      </Stack>
    </Box>
  );
}

import { Box } from '@mui/material';

function CustomText(props) {
  const { index } = props;
  const CssLabel = {
    display: 'inline-block',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2px 8px',
    height: '18px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '120%',
    color: '#262E35',
  };
  return (
    <Box sx={CssLabel} key={index}>
      {props.children}
    </Box>
  );
}
export default CustomText;

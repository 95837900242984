import React from 'react';
import { SIMTButton } from '../../../components/StyledButton/SIMTButton';
import SaveAltIcon from '@/assets/ReportOAPOAWExportAll.svg';
import { FormattedMessage } from 'react-intl';
import { Menu, MenuItem } from '@mui/material';
import SIMTAsyncButton from '../../../components/AsyncButton/SIMTAsyncButton';

export function ReportExportButton(props) {
  const { onClick, startIcon, ...other } = props;
  return (
    <SIMTAsyncButton
      id='export-button'
      color='wileyBlue2'
      variant='contained'
      onClick={onClick}
      startIcon={startIcon ? startIcon : <SaveAltIcon />}
      sx={{
        textTransform: 'none !important',
      }}
      {...other}
    >
      <FormattedMessage id='common.exportAll' />
    </SIMTAsyncButton>
  );
}

/**
 * 在Report 几个页面中可以复用的导出按钮
 * @param props   需要包括至少`items`参数
 *                items是一个列表，每个元素是一个Object，例如：
 *                {
 *                  label: "CSV",
 *                  operation: handleExportCSV
 *                }
 *                ---------------------
 *                于是items的结构为：
 *                [
 *                  {
 *                    label: "CSV",
 *                    operation: handleExportCSV
 *                  },
 *                  {
 *                    label: "Excel",
 *                    operation: handleExportExcel
 *                  },
 *                ]
 * @returns {JSX.Element} 导出按钮
 * @constructor
 */
export default function ReportExportMenuButton(props) {
  const { label,items, mode = 0, ...other } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const exportMenuOpen = Boolean(anchorEl);

  const handleExportMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SIMTButton
        id='export-button'
        data-selenium-id='Report-Specific-Components-Export-button'
        color='wileyBlue2'
        variant='contained'
        aria-controls={exportMenuOpen ? 'export-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={exportMenuOpen ? 'true' : undefined}
        onClick={handleExportMenuClick}
        startIcon={<SaveAltIcon />}
        className={'ReportExportMenuButton'}
        sx={{
          // textTransform: 'none !important',
        }}
        {...other}
      >
        <FormattedMessage
          id={label ?? 'common.exportAll'}
          data-selenium-id='Report-Specific-Components-CommonExportAll'
        />
      </SIMTButton>
      {mode === 0 ? (
        <Menu
          id='export-menu'
          data-selenium-id='Report-Specific-Components-ExportMenu'
          anchorEl={anchorEl}
          open={exportMenuOpen}
          MenuListProps={{
            'aria-labelledby': 'export-button',
          }}
          onClose={handleExportMenuClose}
        >
          {items.map((item, index) => (
            <MenuItem
              id={`menuItem${index}`}
              data-selenium-id={`Report-Specific-Components-MenuItem-${index}`}
              key={`export-menu-item-${index}`}
              onClick={() => {
                item.operation();
                handleExportMenuClose();
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      ) : null}
    </>
  );
}

import { ThemeProvider } from '@emotion/react';
import {
  Box,
  FormControl,
  TextField,
  useFormControl,
  FormHelperText,
  createTheme,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { styled } from '@mui/styles';
import { SmallFont } from '../../../../components/FontStyle';
import { useUpdateEffect } from 'ahooks';
import { trimItemFilds } from '../../../../utils/commonUtils';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
const theme = createTheme({
  palette: {
    error: {
      main: '#EE5350',
    },
  },
});
export const CustomTextFieldTypeTwo = styled(TextField)({
  // '& .MuiOutlinedInput-root': {
  //   background: '#FFFFFF',
  //   borderRadius: '4px',
  //   boxSizing: 'border-box',
  //   width: '400px',
  //   minWidth: '400px',
  //   position: 'relative', // Add this line
  //   padding: '9px 12px', // Set padding to 0
  //   '& .MuiOutlinedInput-root::-webkit-scrollbar': {
  //     width: '8px',
  //     background: '#DFE4E8',
  //     borderRadius: '4px',
  //     position: 'absolute',
  //     top: '-9px', // Adjust the top value to align with the top padding
  //     right: '-12px', // Adjust the right value to align with the right edge
  //     bottom: '-9px', // Adjust the bottom value to align with the bottom padding
  //   },

  //   '& .MuiOutlinedInput-root::-webkit-scrollbar-thumb': {
  //     background: '#BCC5CF',
  //     borderRadius: '4px',
  //   },
  //   ...SmallFont,
  //   lineHeight:'17px',
  //   //styleName: text input;

  //   letterSpacing: '-0.011em',
  //   textAlign: 'left',
  //   height: '72px',
  // },
  // '& .MuiOutlinedInput-input': {
  //   // marginTop: '4px',
  //   '&::placeholder': {
  //     ...SmallFont,
  //     color: '#BCC5CF',
  //     lineHeight:'16.8px',
  //     opacity: '1',
  //   },
  // },
  // '& .MuiOutlinedInput-notchedOutline': {
  //   border: '1px solid #DFE4E8',
  // },
  '& .MuiOutlinedInput-root': {
    position: 'relative',
    width: '400px',
    minWidth: '400px',
    padding: '0px 0px 0px 0px',
    overflow: 'hidden',
    ...SmallFont,
    lineHeight:'17px',
    letterSpacing: '-0.011em',
    textAlign: 'left',
    height: '72px',
  },

  '& .MuiOutlinedInput-input': {
    // height: '72px',
    overflowY: 'scroll',
    padding: '9px 12px 9px 12px',
    '&::placeholder': {
      ...SmallFont,
      color: '#BCC5CF',
      lineHeight:'16.8px',
      opacity: '1',
    },
  },

  // '& .MuiOutlinedInput-root::-webkit-scrollbar': {
  //   width: '8px',
  //   background: '#DFE4E8',
  //   borderRadius: '4px',
  //   position: 'absolute',
  //   top: '0px',
  //   right: '0',
  //   bottom: '0px',
  // },
});

export const ReasonForPopOutTextArea = props => {
  const {
    id = 'test',
    value = '',
    placeholder = '',
    limitLength = 10000,
    needWordCount = false, //是否需要单词计数
    setValue = () => {},
    excessError,
    emptyError,
    setExcessError = () => {},
    setEmptyError = () => {},
    width='400px',
    rows=3,
    CustomTextFieldInject=CustomTextFieldTypeTwo,
  } = props;

  //用于textarea 下面那行 统计字符 的样式
  const textAreaNumberFont = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    height: '16px',
    letterSpacing: '0px',
    //styleName: text input;
    textAlign: 'right',
  };

  const { formatNumber } = useIntl();

  // useUpdateEffect(() => {
  //   setExcessError(value ? value.length > limitLength : false);
  //   setEmptyError(!value);
  // }, [value, needWordCount])

  return (
    <ThemeProvider
      theme={theme}
      data-selenium-id='CATJournal_Overview-OptOutTable-ReasonForPopTextArea-ThemeProvider'
    >
      <FormControl variant='outlined' sx={{width: '400px'}} fullWidth error={emptyError || excessError}>

        {/* <CustomTextFieldInject
          id={id}
          data-selenium-id={id}
          value={value}
          multiline
          rows={rows}
          placeholder={placeholder}
          onChange={e => {
            setValue(e.target.value);
          }}
          error={hasError}
        /> */}
        <MyOnlyText
          id={id}
          data-selenium-id={id}
          value={value}
          multiline
          width='100%'
          isError={emptyError || excessError}
          setValue={setValue}
          placeholder={placeholder}
          maxRows={rows}
          minRows={rows}
          onFocus={() => {
            setEmptyError(false);
            setExcessError(false);
          }}
          onBlur={() => {
            if (value === '') {
              setEmptyError(true);
            }else{
              setEmptyError(false);
            }
            if (value.length > limitLength) {
              setExcessError(true);
            }else{
              setExcessError(false);
            }
          }}
        />
        <Box
          sx={{ height: '24px', marginTop: '4px', width: width }}
          data-selenium-id='CATJournal_Overview-OptOutTable-ReasonForPopTextArea-Box'
        >
          {needWordCount ? (
            <Box
              sx={{ float: 'left', ...textAreaNumberFont, color: '#596A7C' }}
              data-selenium-id='CATJournal_Overview-OptOutTable-ReasonForPopTextArea-NeedWordCountBox'
            >
              words: {value === '' ? 0 : trimItemFilds(value).split(' ').length}
            </Box>
          ) : (
            <></>
          )}
          <Box
            sx={{
              ...textAreaNumberFont,
              color: (
                excessError
              )
                ? '#EE5350'
                : '#596A7C',
              float: 'right',
            }}
            data-selenium-id='CATJournal_Overview-OptOutTable-ReasonForPopTextArea-Box-Box'
          >
            {formatNumber(value.length)}/{formatNumber(limitLength)}
          </Box>
        </Box>
      </FormControl>
    </ThemeProvider>
  );
};

import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { ChartGroups } from '@/components/Chart/ChartGroups';
import { ChartList } from '@/components/Chart/ChartList';
import styles from './index.module.scss';
import { changeSiderOpen } from '@/actions/SIMT-User/User/UserManageAction';

import SubjectGroupAcquiredImg from '@/assets/ReportDiagrams/A&A/AnnualTrends/SubjectGroupAcquired.png';
import BusinessDivisionAcquiredImg from '@/assets/ReportDiagrams/A&A/AnnualTrends/BusinessDivisionAcquired.png';
import SubjectGroupActiveImg from '@/assets/ReportDiagrams/A&A/AnnualTrends/SubjectGroupActive.png';
import BusinessDivisionActiveImg from '@/assets/ReportDiagrams/A&A/AnnualTrends/BusinessDivisionActive.png';

import NormalChart from './NormalChart';

import {
  SI_ACQUISITION_ANNUAL_TRENDS,
  ACTIVE_SI_ANNUAL_TRENDS,
  SUBJECT_GROUP,
  BUSINESS_DIVISION,
} from './constants';
import {POST} from "@/pages/Report/SIPipeline/SIPipelineDiagrams/constants";

// basic diagram setting
const DIAGRAM_SETTINGS = [
  // 1st group
  {
    groupName: SI_ACQUISITION_ANNUAL_TRENDS,
    chartName: SUBJECT_GROUP,
    hasCategoryFilter: true, // is there dropdown for selected category?
    hasSelectAllOption: true,
    type: 'acquisition',
    url: '/reports/acquired-active/diagram/annual-trends/sg/', // url of API data for chart
    tableDownloadUrl: `reports/acquired-active/annual-trends/acquired/sg/download`,
    downloadMethod: POST,
    reportType: 'sg',
    tableDownloadTitle: 'SIAcquisition'
  },
  {
    groupName: SI_ACQUISITION_ANNUAL_TRENDS,
    chartName: BUSINESS_DIVISION,
    hasCategoryFilter: true,
    hasSelectAllOption: false,
    type: 'acquisition',
    url: '/reports/acquired-active/diagram/annual-trends/bd/',
    tableDownloadUrl: `reports/acquired-active/annual-trends/acquired/bd/download`,
    downloadMethod: POST,
    reportType: 'bd',
    tableDownloadTitle: 'SIAcquisition'
  },
  // 2nd group
  {
    groupName: ACTIVE_SI_ANNUAL_TRENDS,
    chartName: SUBJECT_GROUP,
    hasCategoryFilter: true,
    hasSelectAllOption: true,
    type: 'active',
    url: '/reports/acquired-active/diagram/annual-trends/sg/',
    tableDownloadUrl: `reports/acquired-active/annual-trends/active/sg/download`,
    downloadMethod: POST,
    reportType: 'sg',
    tableDownloadTitle: 'ActiveSI'
  },
  {
    groupName: ACTIVE_SI_ANNUAL_TRENDS,
    chartName: BUSINESS_DIVISION,
    hasCategoryFilter: true,
    hasSelectAllOption: false,
    type: 'active',
    url: '/reports/acquired-active/diagram/annual-trends/bd/',
    tableDownloadUrl: `reports/acquired-active/annual-trends/active/bd/download`,
    downloadMethod: POST,
    reportType: 'bd',
    tableDownloadTitle: 'ActiveSI'
  },
];

const CHART_LIST_TITLES = [
  {
    groupTitle: SI_ACQUISITION_ANNUAL_TRENDS,
    levels: [
      {
        chartIndex: 0,
        title: SUBJECT_GROUP,
        img: SubjectGroupAcquiredImg,
      },
      {
        chartIndex: 1,
        title: BUSINESS_DIVISION,
        img: BusinessDivisionAcquiredImg,
      },
    ],
  },
  {
    groupTitle: ACTIVE_SI_ANNUAL_TRENDS,
    levels: [
      {
        chartIndex: 2,
        title: SUBJECT_GROUP,
        img: SubjectGroupActiveImg,
      },
      {
        chartIndex: 3,
        title: BUSINESS_DIVISION,
        img: BusinessDivisionActiveImg,
      },
    ],
  },
];

const DiagramContent = () => {
  // get showTable from URL
  let [searchParams, setSearchParams] = useSearchParams();
  let selectedChartIndex = searchParams.get('selectedChartIndex');
  if (selectedChartIndex == null) {
    selectedChartIndex = -1;
  } else {
    selectedChartIndex = parseInt(selectedChartIndex);
  }

  const setShowTableNav = chartIndex => {
    setSearchParams({ showTable: false, selectedChartIndex: chartIndex });
  };

  const siderOpen = useSelector(state => state.UserManage.siderOpen);
  const dispatch = useDispatch();

  const {
    groupName,
    chartName,
    hasCategoryFilter,
    hasSelectAllOption,
    type,
    url,
    tableDownloadTitle,
    tableDownloadUrl,
    downloadMethod,
    reportType,
  } = useMemo(() => {
    if (
      selectedChartIndex >= 0 &&
      selectedChartIndex < DIAGRAM_SETTINGS.length
    ) {
      return DIAGRAM_SETTINGS[selectedChartIndex];
    } else {
      return {
        groupName: '',
        chartName: '',
        hasCategoryFilter: false,
        hasSelectAllOption: false,
        type: null,
        url: null,
        tableDownloadTitle: null,
        tableDownloadUrl: null,
        downloadMethod: null,
        reportType: null,
      };
    }
  }, [selectedChartIndex]);

  const { selectMonthDate } = useSelector(state => ({
    // selectMonthDate: state.Report.AnnualTrendsDate,
    selectMonthDate: state.Report.selectDate,
  }));

  console.log('selectMonthDate = ' + selectMonthDate);

  // select an index from configuartion array.
  const onSelectChartIndex = index => {
    setShowTableNav(index);

    // if a chart is selected and the side menu is open, close the side menu
    if (index >= 0 && index < DIAGRAM_SETTINGS.length && siderOpen) {
      dispatch(changeSiderOpen(false));
    }
  };

  const cancelSelectedChart = () => onSelectChartIndex(-1);

  return (
    <Box sx={{ marginTop: '32px', paddingBottom: '62px' }}>
      {selectedChartIndex >= 0 ? (
        <Box className={styles['card-content-container']}>
          <div
            className={styles['card-content-paper']}
            disableGutters
            maxWidth={false}
          >
            <NormalChart
              header={groupName}
              subHeader={chartName}
              selectedMonth={selectMonthDate}
              cancelSelectedChart={cancelSelectedChart}
              hasCategoryFilter={hasCategoryFilter}
              hasSelectAllOption={hasSelectAllOption}
              type={type}
              url={url}
              tableDownloadTitle={tableDownloadTitle}
              tableDownloadUrl={tableDownloadUrl}
              downloadMethod={downloadMethod}
              reportType={reportType}
            />
          </div>
          <ChartList
            chartGroupTitles={CHART_LIST_TITLES}
            onSelectChartIndex={onSelectChartIndex}
            selectedChartIndex={selectedChartIndex}
          />
        </Box>
      ) : (
        <ChartGroups
          chartGroupTitles={CHART_LIST_TITLES}
          onSelectChartIndex={onSelectChartIndex}
          selectedChartIndex={selectedChartIndex}
        />
      )}
    </Box>
  );
};

export default DiagramContent;

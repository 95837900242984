import { TableFooter2 } from '@/components/DataTable';
import { StyledTableCell } from '@/components/DataTable';
import NoDataMessage from '@/componentsphase2/NoDataMessage';
import {
  Box,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import ShowDataTable from './showDataTable';
import { axios_instance } from '@/utils/axios_instance';
import { useDispatch, useSelector } from 'react-redux';
import {getFlaglist} from "@/actions/SIMT-SI/GuestEditor/Config";


export default function GeProposerTable(props) {
  const dispatch = useDispatch();
  // const { tableData = [] , callbackGeFlag} = props;
  const { tableData = [], title, flagIssue} = props;
  // issue

  // const sendGeFlagToParent = (flag)=> {
  //   callbackGeFlag(flag);
  // };

  // React.useEffect(() => {
  //   // const url = `/sip/proposer/flag/getFlag?proposerId=${proposerId}`; // 在URL中添加proposerId
  //   // const fetchData = async () => {
  //   //   try {
  //   //     const response = await axios_instance.get(url, {
  //   //       headers: { authorization: localStorage.getItem('authorization') },
  //   //     });
  //   //     const responseData = response.data;
  //   //     setData(responseData.data); // 更新状态变量
  //   //   } catch (error) {

  //   //     setData(null);
  //   //   }
  //   //   // sendGeFlagToParent(data);
  //   // };

  //   // fetchData(); // 调用异步操作函数
  //   setData(null);
  // },[]);


  // setData(null);


  const titleList = [
    // ['title','width','title text-align','info text-align,]
    ['', '154px', ''],
    ['FULL NAME', '184px', ''],
    ['SIP CODE', '160px', '', '', 'url'],
    ['SIP TITLE', '239px', '', '', 'url'],
    // ['INSTITUTION', '600px', '600px'],
    ['INSTITUTION', null, '600px'],
    ['EMAIL', '184px', ''],
  ];

  // const formedTableData = [
  //   ['', ['info', "Flags-Severe Issue"], 'info', 'info', 'info', 'info'],
  // ];
  // 根据接口文档修改这里

  const formedTableData = [
    [[null],
    [tableData['fullName']],
    [tableData['sipCode']],
    [title],
    [
      tableData['guestEditorInSip'] !== null
        ? (tableData['guestEditorInSip']['institution'] === null ? 'no Data' : tableData['guestEditorInSip']['institution'])
        : 'no Data'
    ],
    [
      tableData['guestEditorInSip'] !== null
        ? (tableData['guestEditorInSip']['email'] === null ? 'no Data' : tableData['guestEditorInSip']['email'])
        : 'no Data'
    ]],
  ];
  return <ShowDataTable titleList={titleList} tableData={formedTableData} flag={flagIssue}/>;
}

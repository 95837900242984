import { MulSelectInput } from '@/componentsphase2/SelectBox/SelectInput';
import { judgeCondition } from '../utils';
import {useEffect, useState} from "react";
import {axios_instance} from "@/utils/axios_instance";
import {Box} from "@mui/material";
import {useParams} from "react-router-dom";


export interface DynamicOptions<T> {
    initial?: T[]; // initial options
    queryWhenLoading?: boolean; // if true, query when loading
    queryWhenInput?: boolean; // if true, query when input, this will ignore queryWhenLoading is false
    url?: string; // url to fetch options
    label?: string; // display and filter by this field
    subLabel?: string; // sub label to display
    labelReverse?: boolean; // reverse label and sub label
    queryKey?: string; // key to send to server
    params?: { // static params to send to server
        [key: string]: any;
    };
}

export interface DecisionFormItemProperties {
    position?: string;
    required?: boolean;
    searchable?: boolean;
    placeholder?: string;
    title?: string;
    events?: {
        change?: {
            condition: string;
            command: string;
            [key: string]: string;
        }[];
    };
    submitValue?: string;
}

export interface MultiSelectProps<T> {
    value?: T[];
    update: (value: T[]) => void;
    properties?: {
        options: DynamicOptions<T>;
    } & DecisionFormItemProperties,
    onCommand?: (command: string, params: any) => void;
    onOpen?: () => void;
    onClose?: () => void;
}

export default function MultiSelect<T>({
  value = [],
  update,
  properties = {
    options: {},
  },
  onCommand,
  onOpen = () => {},
  onClose = () => {},
} : MultiSelectProps<T>) {
    const { sipCode } = useParams();

    const [options, setOptions] = useState<T[]>(properties?.options?.initial || [] as T[]);
    const [filteredOptions, setFilteredOptions] = useState<T[]>(options);

    const loadOptions = async (text: string) => {
        const { url, params, queryKey } = properties.options;
        if (url){
            // to support dynamic url with sipCode
            const queryUrl = url.replace('${sipCode}', sipCode as string);
            const res = await axios_instance.get(queryUrl, {
                params: {
                    ...params,
                    [queryKey ?? 'text']: text,
                }
            });
            const optionsToBeSet = Array.isArray(res.data?.data)
                ? res.data.data
                : Array.isArray(res.data?.data?.result)
                    ? res.data.data.result
                    : [];
            setOptions(optionsToBeSet);
        }
    };

    useEffect(() => {
       if (properties?.options?.queryWhenLoading) {
          loadOptions('');
       }
    }, [properties.options]);

    // @ts-ignore
    const getValueFromObject = (option: T) => option?.[properties.options?.label ?? 'value'] ?? ' ';
    // @ts-ignore
    const getOptionLabel: ((t: T) => string) = properties.options.label ?
        getValueFromObject:
        option => option?.toString() ?? '';

    // @ts-ignore
    const getOptionSubLabel: ((t: T) => string) = option => option?.[properties.options?.subLabel] ?? ' ';

    const filterOptions = (text: string) => {
        if (properties.options?.queryWhenInput) {
            return;
        }
        setFilteredOptions(options.filter(option => {
            const value = getOptionLabel(option);
            return value.toLowerCase().includes(text.toLowerCase());
        }));
    };

    const loadOptionsAction = properties?.options?.queryWhenLoading ?
        (properties?.options?.queryWhenInput ? loadOptions : filterOptions)
        : undefined;

  return (
    <MulSelectInput
      id='SIP_DetailPage_DecisionPanel-MultiSelect'
      value={value || []}
      loadOptionAction={loadOptionsAction}
      getOptionLabel={getOptionLabel}
      // InputProps={{disabled: true}}
      options={properties?.options?.queryWhenInput ? options : filteredOptions}
      placeholder={properties.placeholder || `Choose ${properties.title}`}
      showStartIcon={false}
      disableNewItem={true}
      noAddSelectItem={true}
      disableCheckBox={false}
      showPopupIcon={true}
      onOpen={onOpen}
      onClose={onClose}
      onValueChange={ (newValue: T[]) => {
        update(newValue);
        if (properties?.events?.change) {
          properties.events.change.forEach(conf => {
            if (judgeCondition(conf.condition, newValue)) {
                onCommand?.(conf.command, conf[conf.command]);
            }
          });
        }
      }}
      renderOptionLabel={properties.options.subLabel ? (option: T) => (
        <SelectLabel
          label={getOptionLabel(option)}
          subLabel={getOptionSubLabel(option)}
          reverse={properties.options.labelReverse}
        />
      ) : undefined}
    />
  );
}

export function SelectLabel({label, subLabel, reverse = false } : {
    label: string;
    subLabel: string;
    reverse?: boolean;
}){
    return (
        <Box sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: '4px',
            height: '30px',
        }}>
            <Box sx={{
                fontSize: '12px',
                lineHeight: '12px',
            }}>{reverse ? subLabel : label}</Box>
            <Box sx={{
                fontSize: '9px',
                lineHeight: '9px',
            }}>{reverse ? label : subLabel}</Box>
        </Box>
    );
}

import {
  Table,
  TableContainer,
  TableBody,
  styled,
  TableCell,
  tableCellClasses,
} from '@mui/material';
import { Box } from '@mui/system';
import { MyTableHeader } from '../../../../components/DataTable';
import ReportTableRow from './ReportTableRow';
import { turnDateToUTC, formatDatetimeString } from '@/utils/commonUtils';
import { BorderColor } from '@mui/icons-material';

function NewSubmissionTable(props) {
  const { sortKey, setSortKey, orderType, setOrderType, newSubmissionList } =
    props;
  const tableHeadObj = [
    {
      id: 'reportName',
      label: 'Report Name',
      minWidth: '120px',
      maxWidth: '120px',
      isOrder: false,
    },
    {
      id: 'selectedMonth',
      label: 'Selected Month',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'submittedBy',
      label: 'Submitted By',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'submittedTime',
      label: 'Submitted AT',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'action',
      label: 'Action',
      minWidth: '80px',
      maxWidth: '80px',
      isOrder: false,
    },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = sortKey === property && orderType === 'asc';
    setOrderType(isAsc ? 'desc' : 'asc');
    setSortKey(property);
  };

  const handleInfoShowByWho = (name, list) => {
    if (list === undefined || list === null || list.length === 0) {
      return '-';
    }
    for (let item of list) {
      if (item.reportName === name) {
        return item.submittedBy === null ? '-' : item.submittedBy;
      }
    }
    return '-';
  };

  const handleInfoShowTime = (name, list) => {
    if (list === undefined || list === null || list.length === 0) {
      return '-';
    }
    for (let item of list) {
      if (item.reportName === name) {
        let time = item.submittedTime;
        if (time === null) return '-';
        let timelist1 = time.split('T');
        let timelist2 = timelist1[1].split('.');
        time = formatDatetimeString(time);
        return time;
      }
    }
    return '-';
  };

  const StyledTableHeader = styled(TableCell)(({ _theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F1F3F5',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '18px',
      letterSpacing: '0.08em',
      textTransform: 'uppercase',
      color: '#596A7C',
    },
  }));

  return (
    <Box
      id='tableBox'
      data-selenium-id='SystemAdmin_ReportJobs-NewSubmission-NewSubmissionTable-Box'
    >
      <TableContainer
        data-selenium-id='SystemAdmin_ReportJobs-NewSubmission-NewSubmissionTable-Box-TableContainer'
        sx={{
          '&.MuiTableContainer-root': {
            border: '1px solid #DFE4E8',
            borderRadius: '8px',
            BorderColor: '#DFE4E8',
          },
        }}
      >
        <Table
          stickyHeader
          aria-label='simple table'
          size='small'
          data-selenium-id='SystemAdmin_ReportJobs-NewSubmission-NewSubmissionTable-Box-TableContainer-Table'
        >
          <MyTableHeader
            id='report-jobs-submission-table-header'
            data-selenium-id='SystemAdmin_ReportJobs-NewSubmission-NewSubmissionTable-Box-TableContainer-Table-MyTableHeader'
            order={orderType}
            orderBy={sortKey}
            onRequestSort={handleRequestSort}
            items={tableHeadObj}
          />
          <TableBody data-selenium-id='SystemAdmin_ReportJobs-NewSubmission-NewSubmissionTable-Box-TableContainer-Table-TableBody'>
            <ReportTableRow
              id='report-jobs-submission-table-row-1'
              data-selenium-id='SystemAdmin_ReportJobs-NewSubmission-NewSubmissionTable-Box-TableContainer-Table-TableBody-ReportTableRow1'
              index={1}
              reportName='siPipeline'
              submittedBy={handleInfoShowByWho(
                'SI Pipeline',
                newSubmissionList
              )}
              submittedTime={handleInfoShowTime(
                'SI Pipeline',
                newSubmissionList
              )}
              showName='SIPipeline'
            />
            <ReportTableRow
              id='report-jobs-submission-table-row-2'
              data-selenium-id='SystemAdmin_ReportJobs-NewSubmission-NewSubmissionTable-Box-TableContainer-Table-TableBody-ReportTableRow2'
              index={2}
              reportName='acquiredAndActiveSI'
              submittedBy={handleInfoShowByWho(
                'Acquired & Active SI',
                newSubmissionList
              )}
              submittedTime={handleInfoShowTime(
                'Acquired & Active SI',
                newSubmissionList
              )}
              showName='AcquiredAndActiveSI'
            />
            <ReportTableRow
              id='report-jobs-submission-table-row-3'
              data-selenium-id='SystemAdmin_ReportJobs-NewSubmission-NewSubmissionTable-Box-TableContainer-Table-TableBody-ReportTableRow3'
              index={3}
              reportName='articles'
              submittedBy={handleInfoShowByWho('Articles', newSubmissionList)}
              submittedTime={handleInfoShowTime('Articles', newSubmissionList)}
              showName='Articles'
            />
            <ReportTableRow
              id='report-jobs-submission-table-row-4'
              data-selenium-id='SystemAdmin_ReportJobs-NewSubmission-NewSubmissionTable-Box-TableContainer-Table-TableBody-ReportTableRow4'
              index={4}
              reportName='catJournal'
              submittedBy={handleInfoShowByWho(
                'CAT Journal',
                newSubmissionList
              )}
              submittedTime={handleInfoShowTime(
                'CAT Journal',
                newSubmissionList
              )}
              showName='CATJournal'
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default NewSubmissionTable;

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const Main = styled(Box, { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open, drawerWidth }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: '83px',
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth ? drawerWidth : '240px',
    }),
    marginTop: '50px',
  })
);

export const NewDesignedMain = styled(Box, {
  shouldForwardProp: prop => prop !== 'open' || prop !== 'filterDrawerWidth'|| prop !== 'filterOpen'|| prop !== 'drawerWidth',
})(({ theme, open, drawerWidth, filterOpen, filterDrawerWidth }) => ({
  flexGrow: 1,
  paddingTop: 3,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: '80px',
  ...(filterOpen && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: filterDrawerWidth ? filterDrawerWidth : 0,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth ? drawerWidth : '240px',
  }),
  // marginTop: "50px",
}));

export default Main;

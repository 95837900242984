/*
 * @Author: Huang Tao
 * @Date:2022/1/19
 * @Description:Update User props
 */

import React from 'react';
import EditUser from './EditUser';
import '../../../App.css';

export default function UpdateUser(props) {
  const {
    updateOpen,
    setUpdateOpen,
    Email,
    FirstName,
    LastName,
    Group,
    Role,
    Status,
    Lock,
    updateData,
  } = props;

  const isCreate = false;

  return (
    <React.Fragment>
      <EditUser
        data-seleunim-id='SystemAdmin_UserManagement-UpdateUser-Fragment-EditUser'
        open={updateOpen}
        setOpen={setUpdateOpen}
        Email={Email}
        isCreate={isCreate}
        FirstName={FirstName}
        LastName={LastName}
        Group={Group}
        Role={Role}
        Status={Status}
        Lock={Lock}
        updateData={updateData}
      ></EditUser>
    </React.Fragment>
  );
}

import { useState, useEffect } from 'react';
// Styles
import { MiniFont, RegularBoldFont } from '@/components/FontStyle';
import {
  AllCentered,
  ColumnCentered,
  leftFloat,
  rightFloat,
} from '@/pages/SIPage/Overview/Filter/component/Css';
// Components
import Popover from '@mui/material/Popover';
import { StyledBox } from '@/pages/SIPage/Overview/Filter/component/StyledComponents/styledBox';
import {
  Box,
  Paper,
  Popper,
  Stack,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import NumberIcon from '@/pages/SIPage/Overview/Filter/component/CommonComponets/NumberIcon';
import IconBox from '@/pages/SIPage/Overview/Filter/component/CommonComponets/IconBox';
// Assets
import Arrow from '@/pages/SIPage/Overview/Filter/component/SVGComponents/Arrow.svg';
// Utils
import { getLabelByField } from '../utils';

const id = 'firstSectionOtherCategoriesSelect';

export default function OtherCategoriesSelect(props) {
  const {
    fieldList = [],
    dataDict = {},
    currentTab = '',
    handleSwitchTab = () => { },
    topThreeSum = 0
  } = props;
  const defaultLabel = topThreeSum > 1 ? 'Others' : 'Other Categories';
  const defaultSelectingLabel = topThreeSum > 1 ? 'Others' : 'Select another category';
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElTop, setAnchorElTop] = useState(null);
  const [state, setState] = useState('default');
  const [label, setLabel] = useState(defaultLabel);
  const [selectBoxWidth, setSelectBoxWidth] = useState(0);
  const [preSelectingState, setPreSelectingState] = useState('default');
  const [boxSX, setBoxSX] = useState({});


  useEffect(() => {
    switch (state) {
      case 'hover':
        setBoxSX(getBoxSX('0px', '#596A7C', '#FFFFFF', '#6D98EE', '7px 11px'));
        break;
      case 'selecting':
        setBoxSX(getBoxSX('2px', '#154AB6', '#BCC5CF', '#FFFFFF', '6px 10px'));
        break;
      case 'selected':
        setBoxSX(getBoxSX('0px', '#154AB6', '#FFFFFF', '#154AB6', '8px 12px'));
        break;
      default:
        setBoxSX(getBoxSX('1px', '#DFE4E8', '#BCC5CF', '#FFFFFF', '7px 11px'));
        break;
    }
  }, [state]);

  const getTotalNumber = () => { // sum of other categories
    let totalNumber = 0;
    fieldList
      .concat()
      .forEach(field => {
        let label = getLabelByField(field);
        totalNumber += dataDict[label]?.number ?? 0;
      });
    return totalNumber;
  };

  const getCurrentNumber = () => {
    if (dataDict[label]) {
      return dataDict[label].number;
    }
    return 0;
  };

  // sum other than current selected category
  function getTotalNumberWithoutCurrent() {
    let totalNumber = 0;
    fieldList
      .concat()
      .forEach(field => {
        let label = getLabelByField(field);
        if (label !== currentTab) {
          totalNumber += dataDict[label]?.number ?? 0;
        }
      });
    return totalNumber;
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setSelectBoxWidth(event.currentTarget.offsetWidth);
    setState('selecting');
    setLabel(defaultSelectingLabel);
    setPreSelectingState({
      state: state === 'hover' ? 'default' : state,
      label: label
    });
    setAnchorElTop(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setState(preSelectingState.state);
    setLabel(preSelectingState.label);
    handleSwitchTab({ label: currentTab });
  };

  const open = Boolean(anchorEl);
  const openTop = Boolean(anchorElTop);

  const themeCss = createTheme({
    boxSX: boxSX,
  });

  useEffect(() => {
    if (
      currentTab === '' ||
      currentTab === 'Handling CE' ||
      currentTab === 'SIP Code' ||
      currentTab === 'SIP Title'
    ) {
      setState('default');
      setLabel(defaultLabel);
    }
  }, [currentTab]);

  useEffect(() => {
    let selectDOM = document.getElementById(id);
    setSelectBoxWidth(selectDOM.offsetWidth);
    if (topThreeSum > 1) {
      setLabel('Others');
    } else {
      setLabel('Other Categories');
    }
  }, [topThreeSum]);

  return (
    <Box sx={{ flex: 1 }}>
      <ThemeProvider theme={themeCss}>
        <StyledBox
          id={id}
          onClick={handleClick}
          onMouseEnter={event => {
            if (state === 'default') {
              setState('hover');
            }
            if (state !== 'selecting' && getTotalNumber() > 0) {
              setAnchorElTop(event.currentTarget);
            }
          }}
          onMouseLeave={() => {
            if (state === 'hover') {
              setState('default');
            }
            setAnchorElTop(null);
          }}
        >
          <Box sx={leftFloat}>
            <Stack spacing={0.75} direction='row' sx={AllCentered}>
              <Box>{label}</Box>
              <IconBox
                pathFill='#154AB6'
                circleFill='#FFFFFF'
                svgSize={{ height: '14px', width: '14px' }}
                show={state === 'selected'}
              >
                <NumberIcon number={getCurrentNumber()} />
              </IconBox>
              <IconBox
                pathFill={'#FFFFFF'}
                circleFill={'#154AB6'}
                svgSize={{ height: '14px', width: '14px' }}
                show={state === 'default' || state === 'hover' || state === 'selecting'}
              >
                <NumberIcon number={getTotalNumber()} />
              </IconBox>
            </Stack>
          </Box>
          <Box sx={rightFloat}>
            <Stack
              spacing={0.375}
              direction='row'
              sx={{ ...AllCentered, height: '18px' }}
            >
              <IconBox
                pathFill='#154AB6'
                circleFill='#6D98EE'
                svgSize={{ height: '14px', width: '14px' }}
                show={state === 'selected'}
              >
                <NumberIcon number={getTotalNumberWithoutCurrent()} />
              </IconBox>
              <IconBox
                pathFill={state === 'selected' ? '#FFFFFF' : '#596A7C'}
                circleFill={state === 'selected' ? '#154AB6' : '#FFFFFF'}
                isOverturn={state === 'selecting'}
              >
                <Arrow></Arrow>
              </IconBox>
            </Stack>
          </Box>
        </StyledBox>
      </ThemeProvider>

      {/* Popup shown if Hover*/}
      <Popper
        id={`${id}_popover_top`}
        open={openTop}
        anchorEl={anchorElTop}
        placement='top-start'
        sx={{
          bottom: '6px !important',
          zIndex: '2001',
          '& .MuiPaper-root': {
            width: selectBoxWidth + 'px',
            boxShadow: '0px 6px 12px 0px #262E351F',
            borderRadius: '5px',
            minWidth: '148px'
          },
        }}
      >
        <Paper>
          <Stack spacing={0} direction='row'>
            <Box
              sx={{
                width: '8px',
                backgroundColor: '#154AB6',
                borderRadius: '5px 0px 0px 5px',
              }}
            />
            <Stack spacing={1.5} direction='column' sx={{ width: '100%', padding: '12px 0px' }}>
              {fieldList
                .concat()
                .map(field => {
                  let label = getLabelByField(field);
                  return (
                    <DropListItemTop
                      id={`${field}_top`}
                      key={field}
                      label={label}
                      number={dataDict[label]?.number ?? 0}
                    />
                  );
                })}
            </Stack>
          </Stack>
        </Paper>
      </Popper>

      <Popover
        id={`${id}_popover`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          inset: '6px 0px',
          '& .MuiPaper-root': {
            width: selectBoxWidth + 'px',
            boxShadow: '0px 6px 12px 0px #262E351F',
            padding: '12px 0px',
            borderRadius: '5px',
          },
        }}
      >
        <Stack spacing={0.75} direction='column'>
          {fieldList
            .concat()
            .map(field => {
              let label = getLabelByField(field);
              return (
                <DropListItem
                  id={field}
                  key={field}
                  label={label}
                  number={dataDict[label]?.number ?? 0}
                  onClick={() => {
                    setState('selected');
                    setAnchorEl(null);
                    setLabel(label);
                    handleSwitchTab(dataDict[label]);
                  }}
                  onMouseLeave={() => {
                    if (state === 'selecting') {
                      setLabel(defaultSelectingLabel);
                    }
                  }}
                />
              );
            })}
        </Stack>
      </Popover>
    </Box>
  );
}

export function DropListItem(props) {
  const { label, number, ...other } = props;
  const [state, setState] = useState('default');
  useEffect(() => {
    if (number > 0) {
      setState('hasFilters');
    }
  }, [number]);
  return (
    <Box
      {...other}
      sx={{
        width: '100%',
        height: state === 'default' ? '20px' : '24px',
        boxSizing: 'border-box',
        padding:
          state === 'default' ? '0px 14.33px 0px 12px' : '2px 14.33px 2px 12px',
        backgroundColor:
          state === 'default'
            ? '#FFFFFF'
            : state === 'hover'
              ? '#F1F3F5'
              : '#E8EDFB',
        ...MiniFont,
        lineHeight: '20px',
        color: '#596A7C',
      }}
      onMouseEnter={() => {
        if (state === 'default') {
          setState('hover');
        }
        if (props.onMouseEnter) {
          props.onMouseEnter();
        }
      }}
      onMouseLeave={() => {
        if (state === 'hover') {
          setState('default');
        }
        if (props.onMouseLeave) {
          props.onMouseLeave();
        }
      }}
    >
      <Box sx={leftFloat}>{label}</Box>
      <Box sx={{ ...rightFloat, height: '100%', ...AllCentered }}>
        <IconBox
          pathFill='#FFFFFF'
          circleFill='#154AB6'
          svgSize={{ height: '14px', width: '14px' }}
        >
          <NumberIcon number={number} />
        </IconBox>
      </Box>
    </Box>
  );
}

export function DropListItemTop(props) {
  const { id, label, number, sx } = props;
  if (number === 0) return null;
  return (
    <Box
      id={id}
      sx={{
        height: '20px',
        paddingRight: '12px',
        paddingLeft: '12px',
        ...ColumnCentered,
        ...sx
      }}
    >
      <Box
        sx={{
          ...RegularBoldFont,
          letterSpace: '0em',
          color: '#154AB6',
          height: '20px',
          width: 'calc(100% - 14px)',
        }}
      >
        {label}
      </Box>
      <Box sx={{ height: '14px' }}>
        <IconBox
          pathFill='#FFFFFF'
          circleFill='#154AB6'
          svgSize={{ height: '14px', width: '14px' }}
        >
          <NumberIcon number={number} />
        </IconBox>
      </Box>
    </Box>
  );
}

function getBoxSX(borderWidth, borderColor, color, backgroundColor, padding) {
  return {
    borderWidth: borderWidth,
    borderColor: borderColor,
    color: color,
    backgroundColor: backgroundColor,
    padding: padding,
  };
}
import {axios_instance} from "@/utils/axios_instance";
import {
    Edit_PublicationDetails,
    Edit_SubmissionOverview,
    EditSIGeneralInfo_Admin,
    EditSIGeneralInfo_T1,
    SET_SICODE_TO_JUMP,
    SI_TITLE_EDIT,
    updatePop
} from "@/actions/SIMT-SI/SpecialIssue/SIAction";

export function EditSIGeneralInfoAdmin(SIGeneralInfo) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/special-issues/${SIGeneralInfo.projectCode}`;
            const res = await axios_instance.put(url, 
                {
                    editGeneralDto: SIGeneralInfo
                }, 
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                },
                params: {
                    editGeneralType: "admin"
                }
            });
            if (res.data.code === 200) {
                dispatch({type: EditSIGeneralInfo_Admin, data: 'Ok'});
                return true;
            } else {
                dispatch({type: EditSIGeneralInfo_Admin, data: 'error'});
                updatePop(res, dispatch);
                return false;
            }
        } catch (err) {
            dispatch({type: EditSIGeneralInfo_Admin, data: 'error'});
            updatePop(err, dispatch);
            return false;
        }
    };
}

export function EditSIGeneralInfoT1(SIGeneralInfo) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/special-issues/${SIGeneralInfo.projectCode}`;
            const res = await axios_instance.put(url, 
            {
                editGeneralDto: SIGeneralInfo,      
            }, 
            {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
                params: {
                    editGeneralType: "Tier1"
                }
            });
            if (res.data.code === 200) {
                dispatch({type: EditSIGeneralInfo_T1, data: 'Ok'});
                return true;
            } else {
                dispatch({type: EditSIGeneralInfo_T1, data: 'error'});
                updatePop(res, dispatch);
                return false;
            }
        } catch (err) {
            dispatch({type: EditSIGeneralInfo_T1, data: 'error'});
            updatePop(err, dispatch);
            return false;
        }
    };
}

export function EditSubmissionOverview(SISubmissionOverview) {
    return async dispatch => {
        try {
            const url = '/api/v1/si-service/special-issues';
            const res = await axios_instance.put(url, 
                {
                    siEditSubmissionOverviewDto: SISubmissionOverview  
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                 },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: Edit_SubmissionOverview, data: 'OK'});
            } else {
                dispatch({type: Edit_SubmissionOverview, data: 'error'});
                updatePop(res, dispatch);
            }
        } catch (err) {
            dispatch({type: Edit_SubmissionOverview, data: 'error'});
            updatePop(err, dispatch);
        }
    };
}

export function EditPublicationDetail(SIPublicationDetail) {
    return async dispatch => {
        try {
            const url = '/api/v1/si-service/special-issues';
            const res = await axios_instance.put(url, 
                {
                    publicationDto: SIPublicationDetail
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: Edit_PublicationDetails, data: 'Ok'});
                dispatch({type: SET_SICODE_TO_JUMP, data: [res.data.data]});
            } else {
                dispatch({type: Edit_PublicationDetails, data: 'error'});
                updatePop(res, dispatch);
                // return {siCode: null, type: "error"};
            }
        } catch (err) {
            dispatch({type: Edit_PublicationDetails, data: 'error'});
            updatePop(err, dispatch);
        }
    };
}

export function SITitleEdit(siCode, siTitle) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/special-issues/${siCode}`;
            const res = await axios_instance.put(
                url,
                {
                    editTitleDto:{
                        siCode:siCode,
                        siTitle: siTitle,
                    }
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
                
            if (res.data.code === 200) {
                dispatch({type: SI_TITLE_EDIT, data: res.data.message});
            } else {
                dispatch({type: SI_TITLE_EDIT, data: false});
            }
        } catch (e) {
            dispatch({type: SI_TITLE_EDIT, data: false});
        }
    };
}

import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  Paper,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import LabelAndValue from '@/components/LabelAndValue';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import ViewLogIcon from '@/assets/ViewLogIcon.svg';
import SIPTimeline from '../components/sip-process-log/SIPTimeline';
import SIModalPro from '../../../modules/Modal';
import TimelineComponent from '../components/sip-process-log/TimelineComponent';
import { clearProcessLog } from '../../../actions/SIMT-SIP/SIP/SIPAction';
import { formatDateString } from '../../../utils/commonUtils';
import ProcessIconButton from '@/componentsphase2/Button/ProcessIconButton';
import styleModule from './GEInformationBlock.module.scss';
import BottomDrawer from '../components/BottomDrawer';
import { MyTableHeader } from '@/components/DataTable';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getSIPStatus } from '@/actions/SIMT-SIP/SIP/SipDetail';
import { loadSIPProcessLog } from '@/actions/SIMT-SIP/SIP/SipProcessLog';

export default function SIPStatusBlock(props) {
  const { listInOneColumn } = props;

  const { sipCode } = useParams();
  const dispatch = useDispatch();
  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      sipStatus: state.SIP.sipStatus,
      sipProcessLog: state.SIP.sipProcessLog,
      sipType: state.SIP.sipInformation?.sipType,
    };
  };
  const { open, sipStatus, sipProcessLog, sipType } = useSelector(selector);
  /**
   * sipType meaning:
   * 'Yes' = solicited SIP
   * 'No'  = unsolicited SIP
   */

  const [isFold, setIsFold] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [processLogIsLoading, setProcessLogIsLoading] = useState(false);
  const [leftInfo, setLeftInfo] = useState([]);
  const [rightInfo, setRightInfo] = useState([]);

  const leftKeys = [
    'sipCode',
    'sipStage',
    'sipOrigin',
    sipType === 'Yes' ? 'creationDate' : 'submissionDate',
    'lastDecisionDate',
  ];
  const rightKeys = ['handlingCE', 'operationsLead', 'screener'];

  const [processLogOpen, setProcessLogOpen] = useState(false);
  useEffect(() => {
    async function fetchData() {
      await dispatch(getSIPStatus(sipCode));
      setIsLoading(false);
    }
    fetchData();
    return () => {
      dispatch(clearProcessLog());
    };
  }, []);

  useEffect(() => {
    if (sipStatus) {
      let tmpLeft = [];
      let tmpRight = [];
      for (const leftKey of leftKeys) {
        tmpLeft.push(formatSIPStatusInfo(leftKey, sipStatus));
      }
      for (const rightKey of rightKeys) {
        tmpRight.push(formatSIPStatusInfo(rightKey, sipStatus));
      }
      setLeftInfo(tmpLeft);
      setRightInfo(tmpRight);
    }
  }, [sipStatus]);

  const leftIsUrl = [false, false, false, false];

  const rightIsUrl = [false, false, false];

  const generateLabels = keys => {
    let labels = [];
    for (const key of keys) {
      labels.push(
        <div>
          <FormattedMessage
            id={'sipDetail.' + key}
            data-selenium-id={'SIPPage_SIPDetailPage-StatusBlock-Detail.' + key}
          />
          :
        </div>
      );
    }
    return labels;
  };

  const handleProcessLogOpen = () => {
    // open the modal
    setProcessLogOpen(true);
    // load data
    async function loadSIPLog() {
      setProcessLogIsLoading(true);
      await dispatch(loadSIPProcessLog(sipCode));
    }

    loadSIPLog().then(_r => {
      setProcessLogIsLoading(false);
    });
  };

  const [newFlagAdditionOpen, setNewFlagAdditionOpen] = useState(false);
  const [newFlagAdditionData, setNewFlagAdditionData] = useState([]);

  return (
    <Box data-selenium-id='SIPPage_SIPDetailPage-StatusBlock-Box'>
      <Box
        sx={{ mb: '16px', mt: '18px', cursor: 'pointer' }}
        className='sipBlockTitleBox'
        data-selenium-id='SIPPage_SIPDetailPage-StatusBlock-TitleBox'
      >
        <Box
          data-selenium-id='SIP_DetailPage-StatusBlock-TitleFoldBtnBox'
          className='titleFoldButton'
          onClick={async () => {
            await dispatch(getSIPStatus(sipCode));
            setIsFold(!isFold);
          }}
        >
          {isFold ? (
            <MarketChevronUp data-selenium-id='SIP_DetailPage-StatusBlock-MarketChevronUp' />
          ) : (
            <SIDetailArrowDown />
          )}
        </Box>
        <Stack
          id='titleBoxLeft'
          data-selenium-id='SIP_DetailPage-StatusBlock-TtitleBoxLeft'
          spacing={1}
          direction='row'
          sx={{ cursor: 'default' }}
        >
          <Box>
            <FormattedMessage
              id='sipDetail.sipStatus'
              data-selenium-id='SIP_DetailPage-StatusBlock-Status'
            />
          </Box>
          {/* <IconButton
            id='sipDetailViewLogButton'
            data-selenium-id='SIP_DetailPage-StatusBlock-ViewLogButton'
            onClick={handleProcessLogOpen}
          >
            <ViewLogIcon />
          </IconButton> */}
          <ProcessIconButton
            id='sipDetailViewLogButton'
            data-selenium-id='SIP_DetailPage-StatusBlock-ViewLogButton'
            onClick={handleProcessLogOpen}
            className={styleModule.ButtonMarginLeft0}
          ></ProcessIconButton>
        </Stack>
        <SIModalPro
          data-selenium-id='SIP_DetailPage-StatusBlock-SIModalPro'
          open={processLogOpen}
          isRightModel={true}
          handleClose={() => setProcessLogOpen(false)}
          title={
            <FormattedMessage
              id='sipDetail.steps.sipProcessLog'
              data-selenium-id='SIP_DetailPage-StatusBlock-StepsProcessLog'
            />
          }
          // boxSx={{
          //   maxHeight: '100vh',//calc(100vh - 56px - 64px)
          //   width: '924px',
          //   maxWidth: '80%',
          //   height: '100vh',
          //   left: '',
          //   right: '0',
          //   transform: 'translate(0, -50%)'
          // }}
          // sx={{
          //   zIndex: '1500',
          //   background: 'rgba(17, 61,149, 0.5)',
          //   backdropFilter: 'blur(4px)',
          // }}
        >
          <Box
            data-selenium-id='SIP_DetailPage-StatusBlock-SIModalPro-Box'
            sx={{
              // height:'100vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              marginTop: '28px',
              fontFamily: 'Open Sans',
            }}
          >
            <ProcessLogMainContent
              processLogData={sipProcessLog}
              isLoading={processLogIsLoading}
              handleClose={() => setProcessLogOpen(false)}
              openNewFlagAddition={data => {
                setNewFlagAdditionOpen(true);
                setNewFlagAdditionData(data);
              }}
            />
          </Box>
        </SIModalPro>
        <BottomDrawer
          open={newFlagAdditionOpen}
          onClose={() => setNewFlagAdditionOpen(false)}
        >
          <NewFlagAddition data={newFlagAdditionData} />
        </BottomDrawer>
      </Box>
      {isFold ? (
        isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress
              size={60}
              data-selenium-id='SIP_DetailPage-StatusBlock-Box-CircularProgress'
            />
          </Box>
        ) : (
          <Grid
            data-selenium-id='SIP_DetailPage-StatusBlock-Gird'
            container
            columnSpacing={listInOneColumn ? 6 : 0}
            rowSpacing={listInOneColumn ? 0 : 1}
            sx={{ width: '100%' }}
          >
            <Grid item xs={listInOneColumn ? 6 : 12}>
              <LabelAndValue
                id={'sipStatusLeftInfo'}
                data-selenium-id={'SIP_DetailPage-StatusBlock-Gird-LeftInfo'}
                labels={generateLabels(leftKeys)}
                value={leftInfo}
                open={open}
                isUrl={leftIsUrl}
                widthList={['190px', '357px', '220px', '410px']}
              />
            </Grid>
            <Grid item xs={listInOneColumn ? 6 : 12}>
              <LabelAndValue
                id={'sipStatusRightInfo'}
                data-selenium-id={'SIP_DetailPage-StatusBlock-Gird-RightInfo'}
                labels={generateLabels(rightKeys)}
                value={rightInfo}
                open={open}
                isUrl={rightIsUrl}
                widthList={['190px', '357px', '220px', '410px']}
              />
            </Grid>
          </Grid>
        )
      ) : (
        <></>
      )}
    </Box>
  );
}

function ProcessLogMainContent(props) {
  const { processLogData, isLoading, handleClose, openNewFlagAddition } = props;
  if (isLoading) {
    // 在loading
    return (
      <Box
        data-selenium-id='SIP_DetailPage-StatusBlock-ProcessLogMainContent-LoadBox'
        display='flex'
        justifyContent='center'
        alignItems='center'
        width='100%'
        height='100px'
      >
        <CircularProgress
          size='36px'
          data-selenium-id='SIP_DetailPage-StatusBlock-ProcessLogMainContent-CircularProgress'
        />
      </Box>
    );
  } else if (processLogData == null || processLogData.length === 0) {
    // 如果是null，或者长度为0
    return (
      <Box
        data-selenium-id='SIP_DetailPage-StatusBlock-ProcessLogMainContent-NullBox'
        display='flex'
        justifyContent='center'
        alignItems='center'
        width='100%'
        height='100%'
      >
        No data to show
      </Box>
    );
  } else {
    return (
      <SIPTimeline>
        {processLogData.map(item => (
          <TimelineComponent
            key={`step${item.id}`}
            id={item.id}
            data-selenium-id={item.id}
            step={item.step}
            origin={item.origin}
            title={item.title}
            date={formatDateString(item.date)}
            user={item.user}
            comments={item.comments}
            handleClose={handleClose}
            openNewFlagAddition={openNewFlagAddition}
            history={
              item.detail !== null
                ? {
                    ...JSON.parse(item.detail).Checklist,
                    ...JSON.parse(item.detail)?.overallComment,
                  }
                : null
            }
          />
        ))}
      </SIPTimeline>
    );
  }
}

function NewFlagAddition({ data }) {
  const gt1440 = useMediaQuery('(min-width:1441px)');
  const gt1080 = useMediaQuery('(min-width:1081px)');
  const navigate = useNavigate();

  const createRow = (
    id,
    label,
    minWidth,
    maxWidth,
    isOrder = true,
    align = 'left'
  ) => {
    return { id, label, minWidth, maxWidth, isOrder, align };
  };

  const tableHead1 = [
    createRow('fullName', 'FULL Name', '184px', '184px', false),
    createRow(
      'sipTitle',
      'SIP TITLE',
      gt1440 ? '350px' : '300px',
      gt1440 ? '350px' : '300px',
      false
    ),
    createRow('sipCode', 'SIP CODE', '136px', '136px', false),
    createRow(
      'institution',
      'INSTITUTION',
      gt1440 ? '448px' : '156px',
      gt1440 ? '448px' : '156px',
      false
    ),
    createRow('email', 'EMAIL', '184px', '184px', false),
  ];

  const tableHead2 = [
    createRow(
      'fullName',
      'FULL Name',
      gt1440 ? '537px' : '487px',
      gt1440 ? '537px' : '487px',
      false
    ),
    createRow('geCode', 'GE CODE', '133px', '133px', false),
    createRow(
      'institution',
      'INSTITUTION',
      gt1440 ? '448px' : '156px',
      gt1440 ? '448px' : '156px',
      false
    ),
    createRow('email', 'EMAIL', '184px', '184px', false),
  ];

  const renderData = data.map(item => ({
    flag: item.flagLevel,
    tableHead1,
    tableHead2,
    tableData1: [
      {
        fullName: item.fullname,
        sipTitle: item.sipTitle,
        sipCode: item.sipCode,
        institution: item.institution,
        email: item.emailAddress,
      },
    ],
    tableData2: [
      {
        fullName: item.sIGE.fullname,
        geCode: {
          code: item.sIGE.geCode,
          flag: item.sIGE.flagLevel,
        },
        institution: item.sIGE.institution,
        email: item.sIGE.email,
      },
    ],
  }));

  const StyledTable = ({ tableHead, tableData }) => {
    const StyledTableCell = styled(TableCell)(() => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#E3E8F2',
        color: '#262e35',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.14px',
      },
      [`&.${tableCellClasses.root}`]: {
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    }));
    const SevereIssue = () => (
      <Box
        sx={{
          fontWeight: 600,
          fontSize: '12px',
          color: '#fff',
          lineHeight: 'normal',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '0 0 4px 4px',
          py: '2px',
          background: '#ee5350',
          px: '12px',
        }}
      >
        SEVERE ISSUE
      </Box>
    );
    const MildIssue = () => (
      <Box
        sx={{
          fontWeight: 600,
          fontSize: '12px',
          color: '#fff',
          lineHeight: 'normal',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '0 0 4px 4px',
          py: '2px',
          background: '#ffb152',
          pl: '18.5px',
          pr: '17.5px',
        }}
      >
        MILD ISSUE
      </Box>
    );
    const renderCellData = (row, id) => {
      if (id === 'sipTitle')
        return (
          <StyledTableCell
            sx={{
              px: '12px !important',
              paddingLeft: '12px!important',
            }}
          >
            <Box
              sx={{ fontWeight: 600, color: '#154ab6', cursor: 'pointer' }}
              onClick={() =>
                (window.location = `/simt/auth/sipRelated/sip/detail/${row.sipCode}`)
              }
            >
              {row[id]}
            </Box>
          </StyledTableCell>
        );
      if (id === 'sipCode')
        return (
          <StyledTableCell sx={{ px: '12px !important' }}>
            <Box
              sx={{ fontWeight: 600, color: '#154ab6', cursor: 'pointer' }}
              onClick={() =>
                (window.location = `/simt/auth/sipRelated/sip/detail/${row[id]}`)
              }
            >
              {row[id]}
            </Box>
          </StyledTableCell>
        );
      if (id === 'institution')
        return (
          <StyledTableCell
            sx={{
              px: '12px !important',
              paddingLeft: '24px!important',
            }}
          >
            {row[id]}
          </StyledTableCell>
        );
      if (id === 'geCode')
        return (
          <StyledTableCell
            sx={{
              px: '12px !important',
              pt: '0 !important',
              position: 'relative',
            }}
          >
            <Box height='42px' />
            <Stack
              spacing='2px'
              sx={{
                position: 'absolute',
                top: 0,
                height: 'calc(100% - 12px)',
              }}
            >
              {row[id].flag === 'Flags-Severe Issue' ? (
                <SevereIssue />
              ) : row[id].flag === 'Flags-Mild Issue' ? (
                <MildIssue />
              ) : (
                <></>
              )}
              <Box
                sx={{
                  color: '#154ab6',
                  fontWeight: 600,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexGrow: 1,
                  cursor: 'pointer',
                }}
                onClick={() =>
                  navigate(`/simt/auth/siRelated/ge/detail/${row[id].code}`)
                }
              >
                {row[id].code}
              </Box>
            </Stack>
          </StyledTableCell>
        );
      return <StyledTableCell>{row[id]}</StyledTableCell>;
    };
    return (
      <TableContainer
        sx={{
          borderRadius: '4px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: '#DFE4E8',
          boxShadow: 'none',
          overflow: 'auto',
          '::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            border: '3px solid transparent',
            '&:hover': {
              borderColor: '#fff',
            },
            '&:active': {
              borderColor: '#fff',
            },
          },
          '::-webkit-scrollbar-track': {
            marginX: '3px',
          },
        }}
        component={Paper}
      >
        <Table>
          <MyTableHeader
            items={tableHead}
            backgroundColor='#F1F3F5'
            color='#596A7C'
            CheckboxColor='wileyBlue2'
          />
          <TableBody>
            {tableData.map((row, index, arr) => (
              <TableRow
                sx={{
                  '&:hover': {
                    background: (function () {
                      let res = '#e8edfb';
                      if (row.geCode?.flag === 'Flags-Severe Issue')
                        res = '#ffefef';
                      if (row.geCode?.flag === 'Flags-Mild Issue')
                        res = '#FFF0DD';
                      return res;
                    })(),
                  },
                  td: {
                    borderBottom: index === arr.length - 1 ? 'none' : '',
                  },
                }}
              >
                {tableHead.map(item => renderCellData(row, item.id))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const TableCard = ({
    tableHead1,
    tableData1,
    tableHead2,
    tableData2,
    flag,
  }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          borderRadius: '4px',
          boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
          overflow: 'hidden',
          pr: '12px',
        }}
      >
        <Box
          sx={{
            width: '8px',
            background:
              flag === 'Flags-Severe Issue'
                ? '#ff8180'
                : flag === 'Flags-Mild Issue'
                ? '#ffb152'
                : '#fff',
            height: '100%',
            flexShrink: 0,
          }}
        />
        <Stack
          sx={{
            height: '100%',
            py: '12px',
            width: 'calc(100% - 28px)',
          }}
          spacing={1.5}
        >
          <Stack spacing={1.25}>
            <Box sx={{ color: '#98a7b6', fontSize: '16px', fontWeight: 600 }}>
              GE-Proposer Information
            </Box>
            <StyledTable tableHead={tableHead1} tableData={tableData1} />
          </Stack>
          <Stack spacing={1.25}>
            <Box sx={{ color: '#98a7b6', fontSize: '16px', fontWeight: 600 }}>
              SIMT GE Database Information
            </Box>
            <StyledTable tableHead={tableHead2} tableData={tableData2} />
          </Stack>
        </Stack>
      </Box>
    );
  };

  return (
    <Stack
      spacing={2}
      sx={{
        pt: '17px',
        px: gt1440 ? '49px' : gt1080 ? '40px' : '20px',
        height: '626px',
        width: '100vw',
      }}
    >
      <Box sx={{ color: '#262e35', fontSize: '18px', fontWeight: 600 }}>
        New Flag Addition
      </Box>
      <Box
        sx={{
          color: '#262e35',
          fontSize: '16px',
          lineHeight: '120%',
          letterSpacing: '-0.176px',
        }}
      >
        The following GE(s) is newly flagged with some issues after the last
        "initial Review" stage, due to the update of the GE databse.
      </Box>
      <Stack spacing={2}>
        {renderData.map((item, i) => (
          <TableCard key={i} {...item} />
        ))}
        <Box />
      </Stack>
    </Stack>
  );
}

function formatSIPStatusInfo(key, sipStatus) {
  if (!sipStatus[key]) {
    switch(key) {
      case 'lastDecisionDate': return 'No Decision Available';
      default: return '';
    }
  }

  if (/\w*Date$/i.test(key)) {
    return moment(sipStatus[key]).utc().format('YYYY-MM-DD');
  }

  return sipStatus[key];
}

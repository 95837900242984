import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import MyTable from './Table/MyTable';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import {get_EmailHistory, get_SIHistory} from "@/actions/SIMT-SI/SpecialIssue/SiEditHistory";
import {get_COGEHistory, get_LeadGEHistory} from "@/actions/SIMT-SI/SpecialIssue/GeEditDetail";

function TabPanel(props) {
  const { SIid, children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      data-selenium-id={`SIDetail_Simple-Tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 1 }} data-selenium-id='SIDetail_SimpleTabPanelBox'>
          <Typography data-selenium-id='SIDetail_SimpleTabPanelTypography'>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

  // EH Editing History
export const EHTabs = styled(({ lineWidth = '129px', ...props }) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className='MuiTabs-indicatorSpan' />,
    }}
    data-selenium-id='SIDetail_EHTabs'
  />
))(({ lineWidth }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    alignItems: 'center',
    maxWidth: lineWidth, 
    width: '100%',
    backgroundColor: '#1B5BA0',
  },
}));

export const EHTab = styled(Tab)({
  // paddingLeft: '6px',
  // paddingBottom: '9.45px',
  paddingLeft: '0',
  paddingBottom: '16px',
  paddingRight: '0',
  paddingTop: '16px',
  alignItems: 'self-start',
  width: '136px',
  height: '26px',
  minHeight: '20px',

  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '18px',
  textTransform: 'none',
  color: '#596A7C',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  flex: 'none',
  order: 0,
  flexGrow: 0,
  '&.Mui-selected': {
    color: '#113D95',
    '&.Mui-selected::after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '0',
      height: '2px',
      backgroundColor: '#1B5BA0', // 修改为你想要的下划线颜色
    },
    
  },
  '&:hover': {
    color: '#113D95', //字体颜色
    backgroundColor: '#CEDDF9',
  },

  '&:active': {
    color: '#113D95', //字体颜色
    backgroundColor: '#CEDDF9',
    
  },
});

export default function EditingHistoryBlock(props) {
  //SIHistory表
  const [SIHistoryPage, setSIHistoryPage] = useState(1);
  const [LeadGEHistoryPage, setLeadGEHistoryPage] = useState(1);
  const [COGEHistoryPage, setCOGEHistoryPage] = useState(1);
  const [EmailHistoryPage, setEmailHistoryPage] = useState(1);
  const [SIHistoryRowsPerPage, setSIHistoryRowsPerPage] = useState(20);
  const [LeadGEHistoryRowsPerPage, setLeadGEHistoryRowsPerPage] = useState(20);
  const [COGEHistoryRowsPerPage, setCOGEHistoryRowsPerPage] = useState(20);
  const [EmailHistoryRowsPerPage, setEmailHistoryRowsPerPage] = useState(20);

  const [SIHistoryTableData, setSIHistoryTableData] = useState([]);
  const [LeadGEHistoryTableData, setLeadGEHistoryTableData] = useState([]);
  const [COGEHistoryTableData, setCOGEHistoryTableData] = useState([]);
  const [EmailHistoryTableData, setEmailHistoryTableData] = useState([]);
  const [SIHistoryConut, setSIHistoryCount] = useState(0);
  const [LeadGEHistoryCount, setLeadGEHistoryCount] = useState(0);
  const [COGEHistoryCount, setCOGEHistoryCount] = useState(0);
  const [EmailHistoryCount, setEmailHistoryCount] = useState(0);
  const rowsPerPageOptions = [5, 10, 20];

  const dispatch = useDispatch();

  const selector = state => {
    return {
      SIHistory: state.SI.SIHistory,
      LeadGEHistory: state.SI.LeadGEHistory,
      COGEHistory: state.SI.COGEHistory,
      EmailHistory: state.SI.EmailHistory,
    };
  };

  const { SIHistory, LeadGEHistory, COGEHistory, EmailHistory } =
    useSelector(selector);

  useEffect(() => {
    if (SIHistory.total !== undefined) {
      setSIHistoryCount(SIHistory.total);
      SIHistory.list === null
        ? setSIHistoryTableData([])
        : setSIHistoryTableData(SIHistory.list);
    }
  }, [SIHistory]);

  useEffect(() => {
    if (LeadGEHistory.total !== undefined) {
      setLeadGEHistoryCount(LeadGEHistory.total);
      LeadGEHistory.list === null
        ? setLeadGEHistoryTableData([])
        : setLeadGEHistoryTableData(LeadGEHistory.list);
    }
  }, [LeadGEHistory]);

  useEffect(() => {
    if (COGEHistory.total !== undefined) {
      setCOGEHistoryCount(COGEHistory.total);
      COGEHistory.list === null
        ? setCOGEHistoryTableData([])
        : setCOGEHistoryTableData(COGEHistory.list);
    }
  }, [COGEHistory]);

  useEffect(() => {
    if (EmailHistory.total !== undefined) {
      setEmailHistoryCount(EmailHistory.total);
      EmailHistory.list === null
        ? setEmailHistoryTableData([])
        : setEmailHistoryTableData(EmailHistory.list);
    }
  }, [EmailHistory]);

  const handleSIPageChange = (e, value) => {
    setSIHistoryPage(value);
    dispatch(get_SIHistory(SIid, value, SIHistoryRowsPerPage));
  };

  const handleLeadGEPageChange = (e, value) => {
    setLeadGEHistoryPage(value);
    dispatch(get_LeadGEHistory(SIid, value, LeadGEHistoryRowsPerPage));
  };
  const handleCOGEPageChange = (e, value) => {
    setCOGEHistoryPage(value);
    dispatch(get_COGEHistory(SIid, value, COGEHistoryRowsPerPage));
  };
  const handleEmailPageChange = (e, value) => {
    setEmailHistoryPage(value);
    dispatch(get_EmailHistory(SIid, value, EmailHistoryRowsPerPage));
  };

  const handleSIRowsPerPageChange = e => {
    setSIHistoryPage(1);
    setSIHistoryRowsPerPage(Number(e.target.value));
    dispatch(get_SIHistory(SIid, SIHistoryPage, Number(e.target.value)));
  };
  const handleLeadGERowsPerPageChange = e => {
    setLeadGEHistoryPage(1);
    setLeadGEHistoryRowsPerPage(Number(e.target.value));
    dispatch(
      get_LeadGEHistory(SIid, LeadGEHistoryPage, Number(e.target.value))
    );
  };
  const handleCOGERowsPerPageChange = e => {
    setCOGEHistoryPage(1);
    setCOGEHistoryRowsPerPage(Number(e.target.value));
    dispatch(get_COGEHistory(SIid, COGEHistoryPage, Number(e.target.value)));
  };
  const handleEmailRowsPerPageChange = e => {
    setEmailHistoryPage(1);
    setEmailHistoryRowsPerPage(Number(e.target.value));
    dispatch(get_EmailHistory(SIid, EmailHistoryPage, Number(e.target.value)));
  };

  const createRow = (
    id,
    label,
    minWidth,
    maxWidth,
    isOrder = true,
    align = 'left'
  ) => {
    return { id, label, minWidth, maxWidth, isOrder, align };
  };

  const { SIid } = props;

  const [isFold, setIsFold] = useState(false);

  const [TabValue, setTabValue] = useState(0);

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const SIHistoryTableHeads = [
    createRow('editTime', 'EDIT TIME', '170px', '220px', false),
    createRow('item', 'ITEM', '180px', '200px', false),
    createRow('oldValue', 'OLD VALUE', '170px', '180px', false),
    createRow('newValue', 'NEW VALUE', '170px', '180px', false),
    createRow('editUserName', 'EDIT USER', '170px', '340px', false),
  ];

  const GEHistoryTableHeads = [
    createRow('editTime', 'EDIT TIME', '170px', '220px', false),
    createRow('geName', 'GUEST EDITOR NAME', '170px', '220px', false),
    createRow('item', 'ITEM', '180px', '200px', false),
    createRow('oldValue', 'OLD VALUE', '170px', '180px', false),
    createRow('newValue', 'NEW VALUE', '170px', '180px', false),
    createRow('editUser', 'EDIT USER', '170px', '340px', false),
  ];

  const EmailHistoryTableHeads = [
    createRow('time', 'Sending Time', '100px', '100px', false),
    createRow('emailSubject', 'Email Subject', '150px', '150px', false),
    createRow('from', 'From', '80px', '80px', false),
    createRow('to', 'To', '80px', '80px', false),
    createRow('copy', 'Cc', '80px', '80px', false),
    createRow('status', 'Status', '139px', '139px', false),
  ];

  return (
    <Box 
    sx={{
      paddingBottom: '32px',
    }}
      data-selenium-id='SIDetail_EditingHistoryBlock-ContainerBox'
    >
      <Box
        sx={{ mb: '10px', mt: '32px',cursor:'pointer' }}
        id='titleBox'
        data-selenium-id='SIDetail_EditingHistoryBlock-TitleBox'
      >
        <Box
          class='titleFoldButton'
          onClick={() => {
            async function fetchData() {
              await dispatch(get_SIHistory(SIid, 1, 20));
              await dispatch(get_LeadGEHistory(SIid, 1, 20));
              await dispatch(get_COGEHistory(SIid, 1, 20));
              await dispatch(get_EmailHistory(SIid, 1, 20));
            }
            fetchData();
            setIsFold(!isFold);
          }}
          data-selenium-id='SIDetail_EditingHistoryBlock-TitleFoldBtn'
        >
          {isFold ? <MarketChevronUp /> : <SIDetailArrowDown />}
        </Box>
        <Box
          id='titleBoxLeft'
          data-selenium-id='SIDetail_EditingHistoryBlock-TitleBoxLeft'
          sx={{cursor:'default'}}
        >
          <FormattedMessage
            id='siDetail.editingHistory'
            data-selenium-id='SIDetail_EditingHistoryBlock-EditingHistory'
          />
        </Box>
      </Box>
      {isFold ? (
        <Box
          // sx={{ width: '1092px' }}
          data-selenium-id='SIDetail_EditingHistoryBlock-Box1'
        >
          <Box data-selenium-id='SIDetail_EditingHistoryBlock-Box2'>
            <EHTabs
              value={TabValue}
              onChange={handleTabChange}
              lineWidth={'129px'}
              aria-label='basic tabs example'
              style={{ 'min-height': '29px' }}
              data-selenium-id='SIDetail_EditingHistoryBlock-EHTabs1'
            >
              <EHTab
                label='SI&nbsp;Editing&nbsp;History'
                textLength="99px"
                {...a11yProps(0)}
                wrapped
                data-selenium-id='SIDetail_EditingHistoryBlock-EhTabs2'
              />
              <EHTab
                label='GE&nbsp;Editing&nbsp;History'
                {...a11yProps(1)}
                wrapped
                data-selenium-id='SIDetail_EditingHistoryBlock-EHTabs3'
              />
              <EHTab
                label='Co-GE&nbsp;Editing&nbsp;History'
                {...a11yProps(2)}
                wrapped
                data-selenium-id='SIDetail_EditingHistoryBlock-EHTabs4'
              />
              <EHTab
                label='Email&nbsp;History'
                {...a11yProps(3)}
                wrapped
                data-selenium-id='SIDetail_EditingHistoryBlock-EHTabs5'
              />
            </EHTabs>
          </Box>
          <TabPanel
            value={TabValue}
            index={0}
            data-selenium-id='SIDetail_EditingHistoryBlock-TabPanel1'
          >
            <MyTable
              id='siDetailPageDSBSIEditingHistoryTable'
              data-selenium-id='SIDetail_EditingHistoryBlock-SIEditingHinstoryTable'
              TableHeads={SIHistoryTableHeads}
              page={SIHistoryPage}
              rowsPerPage={SIHistoryRowsPerPage}
              TableData={SIHistoryTableData}
              rowsPerPageOptions={rowsPerPageOptions}
              count={SIHistoryConut}
              handlePageChange={handleSIPageChange}
              handleRowsPerPageChange={handleSIRowsPerPageChange}
            ></MyTable>
          </TabPanel>
          <TabPanel
            value={TabValue}
            index={1}
            data-selenium-id='SIDetail_EditingHistoryBlock-TabPanel2'
          >
            <MyTable
              id='siDetailPageDSBLeadGEEditingHistoryTable'
              data-selenium-id='SIDetail_EditingHistoryBlock-LeadGEEditingHistoryTable'
              TableHeads={GEHistoryTableHeads}
              page={LeadGEHistoryPage}
              rowsPerPage={LeadGEHistoryRowsPerPage}
              TableData={LeadGEHistoryTableData}
              rowsPerPageOptions={rowsPerPageOptions}
              count={LeadGEHistoryCount}
              handlePageChange={handleLeadGEPageChange}
              handleRowsPerPageChange={handleLeadGERowsPerPageChange}
            ></MyTable>
          </TabPanel>
          <TabPanel
            value={TabValue}
            index={2}
            data-selenium-id='SIDetail_EditingHistoryBlock-TabPanel3'
          >
            <MyTable
              id='siDetailPageDSBCOGEEditingHistoryTable'
              data-selenium-id='SIDetail_EditingHistoryBlock-COGEEditingHistroyTable'
              TableHeads={GEHistoryTableHeads}
              page={COGEHistoryPage}
              rowsPerPage={COGEHistoryRowsPerPage}
              TableData={COGEHistoryTableData}
              rowsPerPageOptions={rowsPerPageOptions}
              count={COGEHistoryCount}
              handlePageChange={handleCOGEPageChange}
              handleRowsPerPageChange={handleCOGERowsPerPageChange}
            ></MyTable>
          </TabPanel>
          <TabPanel
            value={TabValue}
            index={3}
            data-selenium-id='SIDetail_EditingHistoryBlock-TabPanel4'
          >
            <MyTable
              id='siDetailPageDSBEmailEditingHistoryTable'
              data-selenium-id='SIDetail_EditingHistoryBlock-EmailEditingHistoryTable'
              TableHeads={EmailHistoryTableHeads}
              page={EmailHistoryPage}
              rowsPerPage={EmailHistoryRowsPerPage}
              TableData={EmailHistoryTableData}
              rowsPerPageOptions={rowsPerPageOptions}
              count={EmailHistoryCount}
              handlePageChange={handleEmailPageChange}
              handleRowsPerPageChange={handleEmailRowsPerPageChange}
            ></MyTable>
          </TabPanel>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}

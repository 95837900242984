export const CAT_ORDER_BY = 'CAT_ORDER_BY'; //Set CAT list sorting
export const CHANGE_CAT_PAGE = 'CHANGE_CAT_PAGE';
export const CHANGE_CAT_PAGE_SIZE = 'CHANGE_CAT_PAGE_SIZE';
export const GET_JOURNAL = 'GET_JOURNAL';
export const GET_DEVELOPMENTMODE = 'GET_DEVELOPMENTMODE';
export const CREATE_JOURNAL = 'CREATE_JOURNAL';
export const LOAD_CAT_DATA = 'LOAD_CAT_DATA';
export const LOAD_ALL_CAT_DATA = 'LOAD_ALL_CAT_DATA';
export const SET_CAT_SEARCH_CONTENT = 'SET_CAT_SEARCH_CONTENT';
export const CLEAR_JOURNAL = 'CLEAR_JOURNAL';
export const CATCH_SELECT = 'CATCH_SELECT'; //Cache when create
export const DELETE_CAT_JOURNAL = 'DELETE_CAT_JOURNAL';
export const CLEAR_JOURNAL_RESULT = 'CLEAR_JOURNAL_RESULT';
export const CHANGE_OPT_IN_PAGE = 'CHANGE_OPT_IN_PAGE';
export const CHANGE_OPT_IN_PAGE_SIZE = 'CHANGE_OPT_IN_PAGE_SIZE';
export const SET_OPT_IN_DATA = 'SET_OPT_IN_DATA';
export const CHANGE_OPT_OUT_PAGE = 'CHANGE_OPT_OUT_PAGE';
export const CHANGE_OPT_OUT_PAGE_SIZE = 'CHANGE_OPT_OUT_PAGE_SIZE';
export const SET_OPT_OUT_DATA = 'SET_OPT_OUT_DATA';
export const REJECT_OPT_IN_CODE = 'REJECT_OPT_IN_CODE';
export const APPROVE_OPT_IN_CODE = 'APPROVE_OPT_IN_CODE';
export const REJECT_OPT_OUT_CODE = 'REJECT_OPT_OUT_CODE';
export const APPROVE_OPT_OUT_CODE = 'APPROVE_OPT_OUT_CODE';
export const CHECK_JOURNAl_OPT_OUT_CODE = 'CHECK_JOURNAl_OPT_OUT_CODE';
export const RESET_CHECK_JOURNAl_OPT_OUT_CODE = 'RESET_CHECK_JOURNAl_OPT_OUT_CODE';
export const SUBMIT_JOURNAl_OPT_OUT_CODE = 'SUBMIT_JOURNAl_OPT_OUT_CODE';
export const WITHDRAW_JOURNAl_OPT_OUT_CODE = 'WITHDRAW_JOURNAl_OPT_OUT_CODE';
export const EDIT_JOURNAl_OPT_OUT_CODE = 'EDIT_JOURNAl_OPT_OUT_CODE';

//选择字段
export const ADD_SELECTED = 'CAT_JOURNAL_ADD_SELECTED';
export const REMOVE_SELECTED = 'CAT_JOURNAL_REMOVE_SELECTED';
export const SELECT_ALL = 'CAT_JOURNAL_SELECT_ALL';
export const CANCEL_SELECT_ALL = 'CAT_JOURNAL_CANCEL_SELECT_ALL';
export const SET_IS_ALL_SELECTED = 'CAT_JOURNAL_SET_IS_ALL_SELECTED';
export const REMOVE_SELECTED_INFO = 'CAT_JOURNAL_REMOVE_SELECTED_INFO';
export const ADD_USER_SELECTED = 'CAT_JOURNAL_ADD_USER_SELECTED';
export const REMOVE_USER_SELECTED = 'CAT_JOURNAL_REMOVE_USER_SELECTED';
export const SELECT_ALL_USER = 'CAT_JOURNAL_SELECT_ALL_USER';
export const SET_USER_IS_ALL_SELECTED = 'CAT_JOURNAL_SET_USER_IS_ALL_SELECTED';
export const CANCEL_SELECT_ALL_USER = 'CAT_JOURNAL_CANCEL_SELECT_ALL_USER';
export const REMOVE_USER_SELECTED_INFO =
  'CAT_JOURNAL_REMOVE_USER_SELECTED_INFO';

export const WITHDRAW_OPTIN = 'WITHDRAW_OPTIN';
export const Edit_OPTIN_SUCCESS = 'Edit_OPTIN_SUCCESS';
export const Edit_OPTIN_FAIL = 'Edit_OPTIN_FAIL';
export const CLEAR_EDIT_OPTIN_CODE = 'CLEAR_EDIT_OPTIN_CODE';
export const BATCH_UPDATE_SUCCESS = 'BATCH_UPDATE_SUCCESS';
export const BATCH_UPDATE_ERROR = 'BATCH_UPDATE_ERROR';
export const CLEAR_BATCH_UPDATE_CODE = 'CLEAR_BATCH_UPDATE_CODE';
export const EXPORT_CAT_JOURNAL_STATUS = 'EXPORT_CAT_JOURNAL_STATUS';
export const HANDLE_REQUEST_HISTOYE_TYPE = 'HANDLE_REQUEST_HISTOYE_TYPE';
export const HANDLE_REQUEST_HISTOYE_Status = 'HANDLE_REQUEST_HISTOYE_Status';
export const REQUEST_HISTORY_ORDER_BY = 'REQUEST_HISTORY_ORDER_BY';
export const CHANGE_REQUEST_HISTORY_PAGE_SIZE =
  'CHANGE_REQUEST_HISTORY_PAGE_SIZE';
export const CHANGE_REQUEST_HISTORY_PAGE = 'CHANGE_REQUEST_HISTORY_PAGE';
export const LOAD_REQUEST_HISTORY_LIST = 'LOAD_REQUEST_HISTORY_LIST';
export const LOAD_CAT_JOURNAL_FILTER = 'LOAD_CAT_JOURNAL_FILTER';
export const SELECT_CAT_JOURNAL_FILTER_ITEM = 'SELECT_CAT_JOURNAL_FILTER_ITEM';
export const SUBMIT_OPTIN_CHECK_JOURNAL = 'SUBMIT_OPTIN_CHECK_JOURNAL';

export const GET_SUBJECT_GROUP_LIST = 'GET_SUBJECT_GROUP_LIST';
export const ERROR_MESSAGE_COLLAPSE = '崩溃';

// Create Redux action creators that return an action
export const addSelected = selected => ({
  type: ADD_SELECTED,
  data: selected,
});

export const removeSelected = selected => ({
  type: REMOVE_SELECTED,
  data: selected,
});

export const removeSelectedInfo = selected => ({
  type: REMOVE_SELECTED_INFO,
  data: selected,
});

export const selectAll = (data) => ({
  type: SELECT_ALL,
  data: data
});

export const setIsAllSelected = isAllSelected => ({
  type: SET_IS_ALL_SELECTED,
  data: isAllSelected,
});

export const cancelSelectAll = () => ({
  type: CANCEL_SELECT_ALL,
});
export const addUserSelected = selected => ({
  type: ADD_USER_SELECTED,
  data: selected,
});

export const removeUserSelected = selected => ({
  type: REMOVE_USER_SELECTED,
  data: selected,
});

export const removeUserSelectedInfo = selected => ({
  type: REMOVE_USER_SELECTED_INFO,
  data: selected,
});

export const selectAllUser = () => ({
  type: SELECT_ALL_USER,
});

export const setUserIsAllSelected = isAllSelected => ({
  type: SET_USER_IS_ALL_SELECTED,
  data: isAllSelected,
});

export const cancelSelectAllUser = () => ({
  type: CANCEL_SELECT_ALL_USER,
});

export const SUBMIT_OPTIN_SUCCESS = 'SUBMIT_OPTIN_SUCCESS';
export const SUBMIT_OPTIN_FAIL = 'SUBMIT_OPTIN_FAIL';
export const CLEAR_SUBMIT_OPTIN_CODE = 'CLEAR_SUBMIT_OPTIN_CODE';

/**
 * 更改排序顺序和字段
 * @param {*} order
 * @param {*} by
 * @returns
 */
export function setCATOrderBy(order, by) {
  return dispatch => {
    dispatch({ type: CAT_ORDER_BY, data: { order: order, orderBy: by } });
  };
}

/**
 * 更改表格页码
 * @param {int} page 页码
 * @returns dispatch
 */
export function handleCATPageChangeAction(page) {
  return dispatch => {
    dispatch({
      type: CHANGE_CAT_PAGE,
      data: page,
    });
  };
}

/**
 * 更改表格一页数量
 * @param {int} pageSize 一页的大小
 * @returns dispatch
 */
export function handleChangeCATPageSize(pageSize) {
  return dispatch => {
    dispatch({
      type: CHANGE_CAT_PAGE_SIZE,
      data: pageSize,
    });
  };
}

/**
 * 设置搜索内容
 * @param {*} content 搜索关键词
 */
export function setSearchContent(content) {
  return dispatch => {
    dispatch({
      type: SET_CAT_SEARCH_CONTENT,
      data: content,
    });
  };
}

export function createCATClear() {
  return dispatch => {
    dispatch({
      type: CLEAR_JOURNAL,
    });
  };
}

export function createCATClearResult() {
  return dispatch => {
    dispatch({
      type: CLEAR_JOURNAL_RESULT,
    });
  };
}

export function catchSelect(which, data) {
  return dispatch => {
    dispatch({
      type: CATCH_SELECT,
      data: data,
      which: which,
    });
  };
}

export function clearSubmitOptInCode() {
  return dispatch => {
    dispatch({ type: CLEAR_SUBMIT_OPTIN_CODE, data: null });
  };
}

export function clearEditOptInCode() {
  return dispatch => {
    dispatch({ type: CLEAR_EDIT_OPTIN_CODE, data: null });
  };
}

export const resetJournalOptOutCheck = () => ({
  type: RESET_CHECK_JOURNAl_OPT_OUT_CODE,
});

export const clearBatchUpdateCode = () => ({
  type: CLEAR_BATCH_UPDATE_CODE,
});

//将一个数字数组（比如[1,2,3]）转化为字符串 1,2,3
function arrayToString(array) {
  var str = '';
  for (var i = 0; i < array.length - 1; i++) {
    str += array[i] + ',';
  }
  return str + array[array.length - 1];
}

export const handleRequestHistoryType = type => ({
  type: HANDLE_REQUEST_HISTOYE_TYPE,
  data: type,
});

export const handleRequestHistoryStatus = status => ({
  type: HANDLE_REQUEST_HISTOYE_Status,
  data: status,
});

export function setRequestHistoryOrderBy(order, by) {
  return dispatch => {
    dispatch({
      type: REQUEST_HISTORY_ORDER_BY,
      data: { order: order, orderBy: by },
    });
  };
}

export function handleRequestHistoryPageSize(pageSize) {
  return dispatch => {
    dispatch({
      type: CHANGE_REQUEST_HISTORY_PAGE_SIZE,
      data: pageSize,
    });
  };
}

export function handleRequestHistoryPage(page) {
  return dispatch => {
    dispatch({
      type: CHANGE_REQUEST_HISTORY_PAGE,
      data: page,
    });
  };
}

/**
 *
 * @return {(dispatch: import("redux").Dispatch) => Promise<void>}
 */
export const loadCatJournalConstFilterData = () => {
  return async dispatch => {
    try {
      /** @type {(keyof import("@/pages/CATJournal/Overview/CATTable/CATSearchRow/journal-filter-components/JournalFilterPopup").CatFilterDataState)[]} */
      const stateKeys = [
        'journalCode',
        'journalName',
        'status',
        'developmentModel',
        'journalHandlingCe',
        // 'catGroup',no longer constant
        'jpm',
        'editorialDirector',
        'subjectGroup',
        'businessDivision',
        'revenueModel',
      ];

      const getValues = (key) => {
        switch (key) {
          case 'status':
            return ['opt-in', 'opt-out'];
          case 'developmentModel':
            return ['Basic Management', 'SI Commissioning', 'On Hold'];
          case 'catGroup':
            return ['CAT HS', 'CAT LS', 'CAT PS-SSH'];
          case 'businessDivision':
            return [
              'STMS Health Sciences',
              'STMS Life Sciences',
              'STMS Physical Sciences & Engg',
              'STMS Social Sci & Humanities',
            ];
          case 'revenueModel':
            return ['Hybrid Model', 'OA'];
          case 'subjectGroup':
            return [
              'Immune System, Oncology, Reproduction & Dentistry',
              'Metabolic System, Brain & Nursing',
              'Vascular System, Medical Technologies & Vet Sci',
              'Cell & Molecular Biology',
              'Food, Earth & Environmental Sciences',
              'Whole Organism Biology',
              'Chemistry',
              'Engineering, Mathematics & Statistics',
              'Materials Science & Physics',
              'Economics, Business & Finance',
              'General Social Sciences & Humanities',
              'Psychology & Human Behavior',
              'APAC-China',
              'APAC-JPN/Aus/NZ',
              'WIRES / Current Protocols',
            ];
        }
        return [];
      };

      stateKeys.forEach(key => {
        dispatch({
          type: LOAD_CAT_JOURNAL_FILTER,
          data: {
            name: key,
            data: getValues(key),
          },
        });
      });
    } catch (e) {}
  };
};

/**
 * 选择或者取消选择某一项
 * @param {keyof import("@/pages/CATJournal/Overview/CATTable/CATSearchRow/journal-filter-components/JournalFilterPopup").CatFilterSelectedState} name
 * @param {string | Date | null} item
 * @param {"select" | "unselect" | "replace"} operation
 * @return {(dispatch: import("redux").Dispatch) => Promise<void>}
 */
export function selectCatJournalFilterItem(name, item, operation) {
  return async dispatch => {
    try {
      dispatch({
        type: SELECT_CAT_JOURNAL_FILTER_ITEM,
        data: {
          name: name,
          item: item,
          operation: operation,
        },
      });
    } catch (e) {}
  };
}

export const GET_CAT_JOURNALS_BY_CODE = 'GET_CAT_JOURNALS_BY_CODE';

export const GET_CAT_JOURNALS_BY_NAME = 'GET_CAT_JOURNALS_BY_NAME';


import { calcBarGapPercentage } from '@/pages/Report/chartUtils';
import { StackedVerticalBarChartOptions } from '@/pages/Report/chartUtils/chartOptions';

/**
 * overwrite the default configuration
 */
const MAX_LABEL_SIZE = 18;
const X_AXIS_LABEL_WIDTH = 42;
const LEGEND_POS = ['32%', '43%', '54%'];
const BD_WIDTH = 35;
const BAR_GAP_PX_WIDTH = 1;  
const SG_BAR_WIDTH_MAX = 34;
const Y_AXIS_WIDTH = 30;
const NUM_IN_GROUP = 3;
const CATEGORY_GROUP_GAP = 12;
export class ArticlesOAvsTotalOptions extends StackedVerticalBarChartOptions {
  constructor(catagoryAttributeName, barSeriesValue, barSeriesColor, width) {
    super();
    // overwrite the default properties
    this.catagoryAttributeName = catagoryAttributeName;
    this.barSeriesValue = barSeriesValue;
    this.barSeriesColor = barSeriesColor;
    this.allBarsWidth =
      width - this.chartPaddingLeft - this.chartPaddingRight - Y_AXIS_WIDTH;
    this.pos = LEGEND_POS;
  }

  getBarSeries() {
    return [
      {
        ...this.barSerieOption,
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'inside',
              color: '#FFFFFF',
              //fontSize: 9,
              verticalAlign: 'left',
              lineHeight: 2,
              fontSize: this.getLabelMinFontSize(),
              formatter: this.customLabelFormatter,
            },
          },
        },
        label: {
          show: true,
        },
        name: this.barSeriesValue[0],
        barCategoryGap: CATEGORY_GROUP_GAP,
        stack: 'sub',
        encode: {
          x: 0,
          y: 2,
        },
        color: this.barSeriesColor[1],
      },
      {
        ...this.barSerieOption,
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'inside',
              color: '#596A7C',
              //fontSize: 9,
              verticalAlign: 'left',
              lineHeight: 2,
              fontSize: this.getLabelMinFontSize(),
              formatter: this.customLabelFormatter,
            },
          },
        },
        label: {
          show: true,
        },
        barWidth: this.getBarWidth(),
        name: this.barSeriesValue[1],
        barCategoryGap: CATEGORY_GROUP_GAP,
        stack: 'sub',
        encode: {
          x: 0,
          y: 1,
        },
        color: this.barSeriesColor[0],
      },
      {
        ...this.barSerieOption,
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'inside',
              color: '#FFFFFF',
              //fontSize: 9,
              verticalAlign: 'left',
              lineHeight: 2,
              fontSize: this.getLabelMinFontSize(),
              formatter: this.customLabelFormatter,
            },
          },
        },
        label: {
          show: true,
        },
        name: this.barSeriesValue[2],
        barCategoryGap: CATEGORY_GROUP_GAP,
        stack: 'acc',
        encode: {
          x: 0,
          y: 4,
        },
        color: this.barSeriesColor[3],
      },
      {
        ...this.barSerieOption,
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'inside',
              color: '#596A7C',
              //fontSize: 9,
              verticalAlign: 'left',
              lineHeight: 2,
              fontSize: this.getLabelMinFontSize(),
              formatter: this.customLabelFormatter,
            },
          },
        },
        label: {
          show: true,
        },
        barWidth: this.getBarWidth(),
        name: this.barSeriesValue[3],
        barCategoryGap: CATEGORY_GROUP_GAP,
        stack: 'acc',
        encode: {
          x: 0,
          y: 3,
        },
        color: this.barSeriesColor[2],
      },
      {
        ...this.barSerieOption,
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'inside',
              color: '#FFFFFF',
              //fontSize: 9,
              verticalAlign: 'left',
              lineHeight: 2,
              fontSize: this.getLabelMinFontSize(),
              formatter: this.customLabelFormatter,
            },
          },
        },
        label: {
          show: true,
        },
        name: this.barSeriesValue[4],
        barCategoryGap: CATEGORY_GROUP_GAP,
        stack: 'pub',
        encode: {
          x: 0,
          y: 6,
        },
        color: this.barSeriesColor[5],
      },
      {
        ...this.barSerieOption,
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'inside',
              color: '#596A7C',
              //fontSize: 9,
              verticalAlign: 'left',
              lineHeight: 2,
              fontSize: this.getLabelMinFontSize(),
              formatter: this.customLabelFormatter,
            },
          },
        },
        label: {
          show: true,
        },
        barWidth: this.getBarWidth(),
        barGap: this.getBarGap(),
        name: this.barSeriesValue[5],
        barCategoryGap: CATEGORY_GROUP_GAP,
        stack: 'pub',
        encode: {
          x: 0,
          y: 5,
        },
        color: this.barSeriesColor[4],
      },
    ];
  }

  getTooltip() {
    return [
      {
        ...this.tooltip,
        formatter: params => {
          let dataStr = `<div style="padding: 0; margin: 0;">
            <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 0 0 2.5px 0;">${
              params.name
            }</p>
            <p style="font-size: 10px; font-weight: 400;color: #848484; line-height: 14px; margin: 0;">${
              params.seriesName
            }&nbsp;:&nbsp;${params.data[params.encode.y[0]]}</p>
          </div>`;
          return dataStr;
        },
      },
    ];
  }

  getLabelMinFontSize() {
    const windowWidth = window.innerWidth;
    const numberOfBarGroups = this.dataForBar && this.dataForBar.length;
    // console.log('===width===!', windowWidth);
    // console.log('==numberOfBarGroups==', numberOfBarGroups);

    if (this.catagoryAttributeName === 'businessDivision') {
      // console.log('===font size===!', 14);
      return 14;
    } else {
      // subject groups
      if (numberOfBarGroups > 7 && windowWidth < 1900) {
        // console.log('===font size===!', 7.2);
        return 7;
      } else if (numberOfBarGroups > 7 && windowWidth >= 1900) {
        // console.log('===font size===!', 10);
        return 10;
      } else {
        // console.log('===font size===!', 14);
        return 14;
      }
    }
  }

  getBarWidth() {
    if (this.catagoryAttributeName === 'businessDivision') {
      return BD_WIDTH;
    } else {
      const maxWidth = SG_BAR_WIDTH_MAX;
      const numberOfBarGroups = this.dataForBar && this.dataForBar.length;
      const groupWidth =
        (this.allBarsWidth -
          Math.abs(CATEGORY_GROUP_GAP) * (numberOfBarGroups - 1)) / numberOfBarGroups;
      let barWidth = groupWidth / NUM_IN_GROUP;
      // apply the max constraints
      barWidth = barWidth > maxWidth ? maxWidth : barWidth;
      console.log('barWidth',barWidth);
      return barWidth;
    }
  }
  getBarGap() {
    const barWidth = this.getBarWidth();
    return calcBarGapPercentage(BAR_GAP_PX_WIDTH, barWidth);
  }

  getLabelFontSize() {
    return [this.getLabelMinFontSize(), MAX_LABEL_SIZE];
  }

  getXAxisLabel() {
    const baseAxisLabel = super.getXAxisLabel();
    const width =
      this.catagoryAttributeName != 'businessDivision'
        ? X_AXIS_LABEL_WIDTH
        : undefined;

    const newAxisLabel = {
      ...baseAxisLabel,
      width: width,
    };
    return newAxisLabel;
  }

  getSelectedLegend() {
    const baseLegend = super.getSelectedLegend();
    const legendCol1 = {
      ...baseLegend,
      orient: 'vertical',
      left: this.pos[0],
      data: [this.barSeriesValue[1], this.barSeriesValue[0]],
    };
    const legendCol2 = {
      ...baseLegend,
      orient: 'vertical',
      left: this.pos[1],
      data: [this.barSeriesValue[3], this.barSeriesValue[2]],
    };
    const legendCol3 = {
      ...baseLegend,
      orient: 'vertical',
      left: this.pos[2],
      data: [this.barSeriesValue[5], this.barSeriesValue[4]],
    };

    const legends = [legendCol1, legendCol2, legendCol3];

    return legends;
  }

  customLabelFormatter = params => {
    return params.data[params.encode.y[0]] === 0
      ? ''
      : params.data[params.encode.y[0]];
  };

  setWidth(width) {
    this.width = width;
    if (width !== '100%') {
      const half_width = width / 2;
      this.pos = [half_width - 200, half_width - 60, half_width + 80];
    }
    return this;
  }
}

import {axios_instance} from "@/utils/axios_instance";

export const LOAD_COMPONENTS_BY_AREA = 'LOAD_COMPONENTS_BY_AREA';

export const loadComponentsByArea = (area: string, businessType:string) => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = await axios_instance.get('/api/v1/user-service/ui-component',{
                headers: {
                    authorization: localStorage?.getItem('authorization') ?? '',
                },
                params: {
                    area,
                    businessType
                }
            });

            if (res.data.code !== 200) {
                dispatch({
                    type: LOAD_COMPONENTS_BY_AREA,
                    data: {
                        area: area,
                        components: []
                    }
                });
            }
            const data = res.data.data;
            dispatch({ type: LOAD_COMPONENTS_BY_AREA, data });
        } catch (err) {
            dispatch({
                type: LOAD_COMPONENTS_BY_AREA,
                data: {
                    area: area,
                    components: []
                }
            });
            console.error(err);
        }
    };
};
import {ReactElement, useEffect} from "react";
import {ChecklistStatus, extractHtmlId, FormAction, FormComponentProps, useForm} from "@/components/Form/form.d";
import {Box} from "@mui/material";
import StyledLink from "@/componentsphase2/StyledLink";
import EditButton from "@/pages/SIPPage/SIPCheckListPage/compontents/Buttons/EditButton";
import UndoButton from "@/pages/SIPPage/SIPCheckListPage/compontents/Buttons/UndoButton";
import ArchivedButton from "@/pages/SIPPage/SIPCheckListPage/compontents/Buttons/ArchivedButton";
import { excuteEdit, excuteUndo } from "./actions";

export interface SIPChecklistHeaderProps extends FormComponentProps{
    tips: string,
}

export default function SIPChecklistHeader(props: SIPChecklistHeaderProps): ReactElement{
    const htmlId = extractHtmlId(props);
    const {
        value: status,
        setValue: setStatus,
        form
    } = useForm({
        name: props.name,
        initValue: ChecklistStatus.ARCHIVED,
        initStates: {
            ['html-id']: htmlId
        }
    });

    const sipCode = form?.root().sipCode;
    const version = form?.root().version;
    const locked = form?.root().locked;

    function handleEdit() {
        excuteEdit(form.dispatch, sipCode, version);
    }

    function handleUndo(){
        excuteUndo(form.dispatch, sipCode, version);
    }

    function actionButton() {
        if (locked){
            return  null;
        }
        switch(status){
            case ChecklistStatus.NEW:
                return <EditButton  onClick={handleEdit} />;
            // case ChecklistStatus.IN_PROGRESS:
            //     return locked && <EditButton/>;
            case ChecklistStatus.COMPLETED:
                return <UndoButton onClick={handleUndo}/>;
            case ChecklistStatus.ARCHIVED:
                return <ArchivedButton disabled={true}/>;
            default:
                return null;
        }
    }

    return (
        <Box sx={{display: 'flex',}} id={htmlId}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                }}
            >
                <Box
                    sx={{
                        'font-size': ' 22px',
                        'font-weight': ' 600',
                        'line-height': ' 28px',
                        'letter-spacing': ' 0px',
                        'text-align': ' left',
                    }}
                >
                    Checklist for{' '}
                    <StyledLink
                        to={`/simt/auth/sipRelated/sip/detail/${sipCode}`}
                        target='_blank'
                    >
                        {sipCode}
                    </StyledLink>
                </Box>
                <Box
                    sx={{
                        'font-size': '14px',
                        'font-weight': '400',
                        'line-height': '24px',
                        'letter-spacing': '0em',
                        'text-align': 'left',
                        color: '#596A7C',
                    }}
                >
                    {props.tips}
                </Box>
            </Box>
            <Box
                sx={{
                    marginLeft: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                }}
            >
                {
                    actionButton()
                }
            </Box>
        </Box>
    );
}


import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Paper,
  Popper,
  Stack,
  TextField,
  tooltipClasses,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { InputAdornment } from '@mui/material';
import Off from '../../assets/Off.svg';
import AutocompleteDropDownIcon from '../../assets/dropdownIcon_8_457.svg';
import { trimItemFilds } from '../../utils/commonUtils';
import CheckBoxIcon from '@/assets/checkbox_checked_default.svg';
import CheckBoxHoveredIcon from '@/assets/checkbox_checked_hovered.svg';
import CheckBoxOutlineBlankIcon from '@/assets/checkbox_unchecked.svg';
import CheckBoxOutlineBlankHoveredIcon from '@/assets/checkbox_unchecked_hovered.svg';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import ListItem from '@mui/material/ListItem';
import CleartagIcon from '@/assets/CleartagIcon.svg';
import {
  measureLongestWidth,
  measureWidth,
} from '../../utils/StringWidthMeasure';
import { ScrollBarContainerY } from '../ScrollBar/ScrollBarY';
import {createDepartment, getDepartmentList} from "@/actions/SIMT-SI/GuestEditor/GuestEditorCreate";

export const AddSelectItem = props => {
  const { value, handleAdd, sx = {} } = props;

  return (
    <Stack alignItems={'center'} mb='8px' mt='4px' sx={sx}>
      <Divider
        sx={{ width: 'calc(100% - 24px)', border: '0.5px solid #DFE4E8' }}
      />
      <Stack alignItems={'center'}>
        <Typography
          sx={{
            height: '9px',
            color: '#98A7B6',
            mt: '8px',
            fontFamily: 'Open Sans',
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '9px',
            letterSpacing: '0em',
          }}
        >
          No Results you want?
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#596A7C',
            fontWeight: 400,
            lineHeight: '9px',
            mt: '8px',
            letterSpacing: '0em',
            fontFamily: 'Open Sans',
          }}
        >
          <span
            style={{
              color: '#154AB6',
              cursor: 'pointer',
              fontWeight: 600,
              lineHeight: '9px',
            }}
            onClick={handleAdd}
          >
            Add
          </span>{' '}
          "{value}" to database
        </Typography>
      </Stack>
    </Stack>
  );
};

const ADD_ITEM_ID = -100;
const DELAY_LOAD_TIME = 300; // 0.3s
const LOAD_TIME_OUT = 10000; // 10s

export function deepEqual(val1, val2) {
  if (val1 === val2) {
    return true;
  }

  if (typeof val1 !== typeof val2) {
    return false;
  }

  if (typeof val1 !== 'object' || val1 === null || val2 === null) {
    return false;
  }

  if (Array.isArray(val1) !== Array.isArray(val2)) {
    return false;
  }

  if (Array.isArray(val1)) {
    if (val1.length !== val2.length) {
      return false;
    }

    for (let i = 0; i < val1.length; i++) {
      if (!deepEqual(val1[i], val2[i])) {
        return false;
      }
    }
    return true;
  }

  const keys1 = Object.keys(val1);
  const keys2 = Object.keys(val2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!val2.hasOwnProperty(key) || !deepEqual(val1[key], val2[key])) {
      return false;
    }
  }

  return true;
}

const defaultRenderOptionLabel = (option, getOptionLabel) => {
  return <>{getOptionLabel(option)}</>;
};

// @ts-ignore
export const SelectInput = React.forwardRef((props, tref) => {
  useImperativeHandle(tref, () => ({
    getAutoCompleteRef: () => ref,
    popup: () => {
      setIsOpen(true);
      onOpen && onOpen();
    },
  }));
  const ref = useRef(null);
  const {
    multiple,
    value,
    placeholder = 'Select...',
    options,
    loadOptionAction,
    getOptionLabel = option => option,
    getOptionId,
    isOptionEqualToValue = deepEqual,
    defaultOption = key => key,
    renderOption,
    optionsLineHeight = 20,
    renderInput,
    onInputChange,
    onChange,
    onValueChange = newValue => { },
    onOpen,
    onClose,
    PopperComponent,
    PaperComponent,
    error = false,
    setError = () => {},
    sx,
    disableNewItem = false,
    addNewItemManager = {},
    disableClearIcon = false,
    clearIcon,
    popupIcon,
    showPopupIcon,
    showStartIcon,
    InputProps,
    inputProps,
    dropDownListNumber = 6,
    clearText = '', // the lable of the clear icon button.
    closeText = '',
    openText = '',
    addNewItemBackGroundColorHoverChange = false,
    width = '100%',
    onClickOpen = () => { },
    renderOptionLabel = defaultRenderOptionLabel,
    ...other
  } = props;

  const {
    newData,
    itemExist,
    dataGet,
    addNewOption,
    afterAddNewOption,
    afterDateGetSucess,
  } = addNewItemManager;

  const [h, setH] = useState(25);
  const [hChanging, setHChanging] = useState(false);
  const [isEmpty, setIsEmpty] = useState(!props.value);
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  /* ------------------------ 多选框高度问题 ------------------------ */
  // useEffect(() => {
  //   const element = ref.current;
  //   if (props.multiple){
  //     const observer = new ResizeObserver(() => {
  //       setH(element.offsetHeight);
  //     });
  //     if (element) {
  //       observer.observe(element);
  //     }
  //     return () => {
  //       if (element) {
  //         observer.unobserve(element);
  //       }
  //     };
  //   }
  // }, []);

  useEffect(() => {
    // console.info(value);
    if (multiple) {
      if (value && value.length && value.length > 0) {
        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }
    } else if (value && getOptionLabel(value) !== null) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  }, [value]);

  /* ------------------------ 后端搜索 ------------------------ */

  const [delayLoadTimer, setDelayLoadTimer] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [optionsToShow, setOptionsToShow] = useState(options);
  const [newDataCache, setNewDataCache] = useState(value);
  const [loading, setLoading] = useState(false);
  const [loadingStateTimer, setLoadingStateTimer] = useState(null);

  useEffect(() => {
    delayLoadOptions(inputValue);
    updateHeight();
  }, []);

  const delayLoadOptions = value => {
    if (!loadOptionAction) return;

    // 停止输入 ${DELAY_LOAD_TIME}ms 后开始查询
    if (delayLoadTimer) clearTimeout(delayLoadTimer);
    setDelayLoadTimer(
      setTimeout(async () => {
        // 加载
        loadOptionAction(trimItemFilds(value));
      }, DELAY_LOAD_TIME)
    );
  };

  const configTimeoutLoading = () => {
    // 如果 ${LOAD_TIME_OUT} 后计时器没有取消，说明超时，取消 loading 状态
    // 显示 add item 选项
    setLoading(true);
    setOptionsToShow([]);
    if (loadingStateTimer) clearTimeout(loadingStateTimer);
    setLoadingStateTimer(
      setTimeout(() => {
        setOptionsToShow(current => {
          // console.info('current',current);
          if (current.length === 0)
            return [
              {
                ...defaultOption(inputValue),
                id: ADD_ITEM_ID,
              },
            ];
          return current;
        });
        setLoading(false);
      }, LOAD_TIME_OUT)
    );
  };

  const checkIfInputValueExistingInOptions = () => {
    return (
      options &&
      Array.isArray(options) &&
      options.some(
        item =>
          trimItemFilds(`${getOptionLabel(item)}`) === trimItemFilds(inputValue)
      )
    );
  };

  const getOptionsToShowByOptionsAndInputValue = () => {
    return !checkIfInputValueExistingInOptions()
      ? [
          ...options,
          {
            ...defaultOption(inputValue),
            id: ADD_ITEM_ID,
          },
        ]
      : options;
  };

  useEffect(() => {
    // options 变更时，重新计算需要展示的 options
    const newOptionsToShow = getOptionsToShowByOptionsAndInputValue();

    setOptionsToShow(newOptionsToShow);

    // options 更新时，需要取消 loading 状态
    // 同时清空检测 loading 超时的计时器
    // 注意清空 loadingStateTimer 前一定要将 loading 状态设为 false
    setLoading(false);
    if (loadingStateTimer) clearTimeout(loadingStateTimer);
    setLoadingStateTimer(null);

    // options 更新时，若发现 newDataCache 不是 undefine，说明此次更新是添加新 item 导致的，
    // 可以直接把新 item 设为值，并清空缓存
    if (newDataCache) {
      onValueChange(newDataCache);
      setNewDataCache(undefined);
    }
  }, [options]);

  /* ------------------------ Add 功能 ------------------------ */
  const handleAddNewItem = () => {
    if (addNewOption === undefined) return;
    addNewOption(trimItemFilds(inputValue));
  };

  const defaultRenderOptions = (props, option) => {
    if (`${getOptionLabel(option)}` === '') return <></>;
    if (option.id === ADD_ITEM_ID) {
      if (trimItemFilds(inputValue) === '' || options.some(item =>
          getOptionLabel(item).startsWith(inputValue))) return <></>;
      if (disableNewItem)
        return (
          <li>
            <Stack alignItems={'center'} mb='8px' mt='4px'>
              No Options!
            </Stack>
          </li>
        );
      return (
        <AddSelectItem
          handleAdd={handleAddNewItem}
          value={trimItemFilds(inputValue)}
          key='qwerty-add-select-item'
        />
      );
    }
    return (
      <ListItem
        key={option.id}
        sx={{
          // '&.MuiAutocomplete-option':{
          backgroundColor: '#FFFFFF !important',
          // },
        }}
        {...props}
      // onMouseEnter={e => (e.target.style.background = '#F1F3F5')}
      // onMouseLeave={e => (e.target.style.background = '#fff')}
      >
        <Box
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '100%',
            backgroundColor: '#FFFFFF',
            '&:hover': {
              backgroundColor: addNewItemBackGroundColorHoverChange
                ? '#F5F5F5'
                : '#FFFFFF !important',
            },
          }}
        >
          {renderOptionLabel(option, getOptionLabel)}
        </Box>
      </ListItem>
    );
  };

  const defaultRenderInput = props => {
    const { className, InputProps, ...others } = props;
    let classNameX = className + ' SelectInput-Input ';
    if (isEmpty) classNameX += ' SelectInput-isEmpty ';
    if (isHovered) classNameX += ' SelectInput-isHovered ';
    if (isActive || isOpen) classNameX += ' SelectInput-isActive ';
    if (error && !(isActive || isOpen)) classNameX += ' SelectInput-isError ';
    return (
      <TextField
        className={classNameX}
        placeholder={isEmpty ? placeholder : ''}
        InputProps={
          value?.length || !showStartIcon
            ? { ...props.InputProps }
            : {
                ...props.InputProps,
                startAdornment: <SearchIcon />,
              }
        }
        // isEmpty={isEmpty}
        {...others}
        inputProps={{
          ...props.inputProps,
          ...inputProps
        }}
      />
    );
  };

  const overRenderInput = props => {
    const { className, InputProps, ...others } = props;
    let classNameX = className + ' SelectInput-Input ';
    if (isEmpty) classNameX += ' SelectInput-isEmpty ';
    if (isHovered) classNameX += ' SelectInput-isHovered ';
    if (isActive || isOpen) classNameX += ' SelectInput-isActive ';
    if (error && !(isActive || isOpen)) classNameX += ' SelectInput-isError ';
    const overProps = {
      className: classNameX,
      placeholder: placeholder,
      InputProps,
      ...others,
    };
    return renderInput(overProps);
  };
  setError(error && !(isActive || isOpen));
  const defaultPopperComponent = props => {
    const { children, sx = {}, ...others } = props;

    return (
      <Popper
        {...others}
        sx={{
          zIndex: '1500 !important',
          '&.MuiAutocomplete-popper': {
            inset: '6px auto !important',
          },
          '& .MuiAutocomplete-paper': {
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 6px 12px rgba(38, 46, 53, 0.12)',
            borderRadius: '5px',
          },
          '& .MuiAutocomplete-noOptions': {
            padding: '0px',
          },
          ...sx,
        }}
      >
        {children}
      </Popper>
    );
  };
  useEffect(() => {
    // 当新获得新的有效 item 时，将其添加至 optionsToShow ，
    // 同时存入缓存，等待 option 刷新

    if (dataGet === 'success') {
      if (multiple) {
        // 理论上 multiple = true 时， newDataCache 应该是 T[] 的父类，可以兼容
        // 但此处类型推导失效，若产生 bug 应留意
        if (value !== null && value !== undefined && Array.isArray(value))
          setNewDataCache([...value, newData]);
      } else {
        setNewDataCache(newData);
      }

      afterDateGetSucess(newData);

      if (loadOptionAction) {
        const newInputValue = trimItemFilds(inputValue);
        // console.log('newInputValue = ', newInputValue);
        loadOptionAction(newInputValue);
      }
    }

    if (dataGet === 'success' || dataGet === 'error') {
      if (afterAddNewOption) afterAddNewOption();
    }
  }, [dataGet]);

  function updateHeight() {
    // setHChanging(true);
    setTimeout(() => {
      if (ref.current.offsetHeight !== null) setH(ref.current.offsetHeight);
      // setHChanging(false);
    }, 300);
  }

  function defaultPaperComponent(props) {
    const { sx, children, ...rest } = props;
    const paperRef = useRef(null);
    const ulRef = useRef(null);
    const scrollBoxRef = useRef(null);
    const scrollBoxInnerRef = useRef(null);
    const [transPercent, setTransPercent] = useState(1);

    const isFirefox = navigator.userAgent.includes('Firefox');
    const isChrome = /Mozilla/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent);
  
    let foundTableContainer = false;

    useEffect(() => {
      const ulElement = ulRef.current;
      const liElements = ulElement.querySelectorAll('li');
      if (liElements) {
        setTimeout(() => {
          const contextHeight = Array.from(liElements)
            .map(li => li.offsetHeight)
            .reduce((x, y) => x + y, 0);
          const boxHeight = ulElement.offsetHeight;
          const scrollBoxElement = scrollBoxRef.current;
          const scrollBoxInnerElement = scrollBoxInnerRef.current;
          if (scrollBoxElement && scrollBoxInnerElement) {
            setTransPercent(boxHeight / scrollBoxElement.offsetHeight);
            scrollBoxInnerElement.style.height =
              (contextHeight / boxHeight) * scrollBoxElement.offsetHeight +
              'px';
          }
        }, 100);
      }
    }, [isOpen, loading, optionsToShow]);

    const [isScrolling, setScrolling] = useState(false);
    const handleScroll = event => {
      if (isScrolling) return;
      setScrolling(true);
      const scrollBoxElement = scrollBoxRef.current;
      const offset = scrollBoxElement.scrollTop;

      const containerBoxElement = ulRef.current;
      containerBoxElement.scrollTop = offset * transPercent;
      setScrolling(false);
    };

    const scrollAsContext = () => {
      if (isScrolling) return;
      setScrolling(true);
      const containerBoxElement = ulRef.current;
      const scrollBoxElement = scrollBoxRef.current;
      const contextOffset = containerBoxElement.scrollTop;
      scrollBoxElement.scrollTop = contextOffset / transPercent;
      setScrolling(false);
    };

    return (
      <Paper
        ref={paperRef}
        sx={{
          mb: '6px',
          // pr: '3px',
          position: 'relative',
          '& .MuiAutocomplete-listbox': {
            p: '6px 0px 6px 0px',
            maxHeight: (optionsLineHeight + 12) * 6 + 12 + 'px', // (optionsLineHeight + option_pt=6 + option_pb=6 ) * 6 + popper_pt=6 + popper_pb=6
            overflowY: 'scroll',
            '&::-webkit-scrollbar:vertical': {
              width: 0,
            },
            '&::-webkit-scrollbar-thumb:vertical': {
              backgroundColor: 'transparent',
            },
            '& .MuiAutocomplete-option': {
              lineHeight: `${optionsLineHeight}px`,
            },
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            ... (!isFirefox && { 
              '&': {
              scrollbarWidth: 'none',
            }}),
           
          },
          ...sx,
        }}
        {...rest}
      >
        {React.Children.map(children, child => {
          if (!foundTableContainer && React.isValidElement(child)) {
            foundTableContainer = true;
            return React.cloneElement(child, {
              ref: ulRef,
              onScroll: scrollAsContext,
            });
          }
          return child;
        })}
        <ScrollBarContainerY
          ref={scrollBoxRef}
          sx={{
            ... (isFirefox && {display: 'none'}),
            right: '3px',
            zIndex: 2000,
          }}
          onScroll={handleScroll}
        >
          <Box className='scroll-bar-inner-box' ref={scrollBoxInnerRef} />
        </ScrollBarContainerY>
      </Paper>
    );
  }

  return (
    <div
      style={{ position: 'relative', width: width }}
    >
      <Autocomplete
        onMouseEnter={() => {
          setIsHovered(true);
          updateHeight();
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          updateHeight();
        }}
        onMouseDown={() => setIsActive(true)}
        ref={ref}
        sx={{
          // '& .MuiAutocomplete-popper' : {
          //   boxShadow: '0px 6px 12px rgba(38, 46, 53, 0.12)',
          //   borderRadius: '5px',
          // },
          '& .MuiTouchRipple-root': {
            display: 'none'
          },
          '& .MuiFormControl-root .MuiOutlinedInput-notchedOutline': {
            borderRadius: '4px',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#98A7B6',
          },
          '& .SelectInput-isEmpty .MuiOutlinedInput-notchedOutline': {
            borderColor: '#DFE4E8',
          },
          '& .SelectInput-isHovered .MuiOutlinedInput-notchedOutline': {
            borderColor: '#262E35',
          },
          '& .SelectInput-isActive .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #0052CC',
          },
          '& .SelectInput-isError .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #EE5350',
          },
          '& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiInputBase-root': {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '17.5px',
            letterSpacing: '-0.011em',
            color: '#262E35',
            padding: isEmpty ? '11px 0 11px 12px' : (multiple ? '4px 7px 4px 5px' : '11px 7px 11px 5px'),//right:65
            // padding: isEmpty? '11px 0 11px 12px': '4px 7px 4px 5px',//right:65
            // width: '286px',
            boxSizing: 'border-box',
            flexWrap: 'wrap',
          },
          // Allows the input box to automatically expand within the available space and fill the remaining space.
          // maybe we can add a param in the future
          // '& .MuiAutocomplete-input': {
          //   minWidth: 0,
          //   flexGrow: 1,
          //   width: 'auto !important',
          // },
          '& .SelectInput-Tags-Container': {
            paddingRight: showPopupIcon || isActive || isOpen || isHovered ? '20px !important' : 0,
          },
          '& .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall, & .MuiOutlinedInput-input.MuiInputBase-input': {
            padding: '0px 41px 0px 8px',
            // padding: 0,
            paddingLeft: showStartIcon && isEmpty ? '8px' : '0',
            height: '18px',
            // width: '0px',
            minWidth: 'unset',
            ...(props.multiple && !(isActive || isOpen || isHovered || isEmpty) && {
              // visibility: 'hidden',
              padding: 0,
              paddingLeft: 0,
              flexGrow: 0,
              height: 0,
            }),
            ...(props.multiple && !isEmpty && (h < 45) && {
              transform: 'translateX(-14px)',
            }),
            // ...(props.multiple && (isActive || isOpen) && {
            //   width: '0',
            // // padding: '0px 41px 0px 8px',
            // }),
            ...(!props.multiple && {
              padding: '0px 41px 0px 8px',
            }),

          },
          '& .MuiButtonBase-root.MuiIconButton-root path': {
            fill: '#596A7C',
          },
          '& .MuiChip-label': {
            // si filter need to display all contents; maybe we can add a param in the future
            // maxWidth: '180px',
          },
          '& .MuiAutocomplete-endAdornment': {
            paddingRight: '6px',
            paddingLeft: '6px',
            paddingTop: '11px',
            paddingBottom: '11px',
            ...((h >= 45) && {
              flexDirection: !showPopupIcon ? 'row' : 'column-reverse',
              flexWrap: 'nowrap',
              justifyContent: 'space-between',
              // height: (h*0.8)+'px',
              height: '100%',
              top: 'auto',
            }),
            ...((h < 45) && {
              flexDirection: 'row',
              flexWrap: 'nowrap',
              // height: (h*0.8)+'px',
              height: '100%',
              top: 'auto',
              alignItems: 'center',
            }),
            display: hChanging ? 'none' : 'flex',
            '& button': {
              height: '16px',
              width: '16px',
              padding: 0,
              borderRadius: '2px',
              boxShadow: 'none',
              filter: 'none',
              '&:hover': {
                '& span:': {
                  filter: 'none',
                  boxShadow: 'none'
                },
                backgroundColor: 'transparent',
              },
              '&:active span': {
                filter: 'none',
                boxShadow: 'none'
              }
            }
          },
          '& .MuiAutocomplete-clearIndicator': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            ...(isEmpty && {
              visibility: 'hidden',
              ':hover': {
                visibility: 'hidden',
              }
            }),
            // ...(!isEmpty && {
            //   visibility: 'visible',
            // }),
            '& svg': {
              height: '100%',
              width: '100%',
            }
          },
          '& .MuiAutocomplete-popupIndicator': {
            display: showPopupIcon ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            '& svg': {
              width: '10px',
              height: '7px',
            }
          },
          '& svg': {
            minWidth: 'fit-content',
            minHeight: 'fit-content',

          },
          '& .MuiAutocomplete-tag': {
            marginLeft: '1px',
            marginRight: '1px',
            // 多选标签间上下间距
            marginTop: '2px',
            marginBottom: '2px',
            // paddingRight:'8px',
            '&:hover': {
              backgroundColor: '#DFE4E8',
            },
          },
          ...sx
        }}
        value={value}
        options={optionsToShow}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        loading={loading}
        multiple={multiple}
        // disableClearable={isEmpty}
        clearIcon={disableClearIcon ? (<></>) : (clearIcon || <Off />)}
        popupIcon={popupIcon || <AutocompleteDropDownIcon />}

        onBlur={() => {
          setIsActive(false);
          setIsHovered(false);
          setInputValue('');
        }}
        open={isOpen}
        onOpen={(event, reason) => {
          onClickOpen();
          loadOptionAction && loadOptionAction(trimItemFilds(inputValue));
          document.body.classList.add('hide-scroll');
          setIsOpen(true);
          onOpen && onOpen(event, reason);
        }}
        onClose={(event, reason) => {
          document.body.classList.remove('hide-scroll');
          setIsOpen(false);
          onClose && onClose(event, reason);
        }}
        onInputChange={(event, value) => {
          // Autocomplete 的 inputValue 变更时 需要
          // 1. 同步存储的 inputValue
          setInputValue(value);
          onInputChange && onInputChange(event, value);
          // 2. 设置 loading 状态 ，若超过 ${LOAD_TIME_OUT} ms 后 loading 计时器还没有取消
          // 视作加载超时，取消 loading 状态
          configTimeoutLoading();
          // 3. 延迟加载 option ，停止输入 ${DELAY_LOAD_TIME} ms 才从后端加载 options
          delayLoadOptions(value);

        }}
        onChange={(e, v) => {
          updateHeight();
          // console.info('SelectInput:', value, v);
          onValueChange(v);
          onChange && onChange(e, v);
          if(!multiple) setIsOpen(false);
        }}
        PopperComponent={PopperComponent || defaultPopperComponent}
        PaperComponent={PaperComponent || defaultPaperComponent}
        renderOption={renderOption || defaultRenderOptions}
        filterOptions={x => x}
        renderInput={renderInput ? overRenderInput : defaultRenderInput}
        clearText={clearText}
        closeText={closeText}
        openText={openText}
        {...other}
      >
      </Autocomplete>
      {
        isOpen ? (<div style={{
          position: 'absolute',
          // bottom: dropDownListNumber == 0?'-245px':'-95px',
          bottom: `-${(optionsLineHeight + 12) * dropDownListNumber + h - 20}px`,
          left: 0,
          right: 0,
          // height: dropDownListNumber == 0?'245px':'95px',
          height: `${(optionsLineHeight + 12) * dropDownListNumber + h - 20}px`,
          backgroundColor: 'rgba(0, 0, 0, 0)'
        }} />) : null
      }
    </div>);
});

export const CssChip = styled(Chip)({
  '& .MuiChip-root': {
    borderRadius: '4px',
    height: '26px',
  },
  '& .MuiChip-label': {
    // paddingLeft: 12,
    // paddingRight: 8,
    padding: '5px 6px 5px 12px',
    color: '#596A7C',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    // height:'9px',
  },
  '& .MuiChip-deleteIcon': {
    fontSize: 12,
    '&:hover': {
      backgroundColor: '#BCC5CF',
    },
  },
  '& .MuiSvgIcon-root path': {
    fill: '#272e35',
  },
});

export const GETooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement='top' />
))(({ theme, tooltipWidth }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#596A7C',
    width: '12px',
    height: '6px',
    // top: '28px',
    // need to fix it later
    marginBottom: '-0.44em !important',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    // title
    backgroundColor: '#596A7C',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#FFFFFF',
    // padding: '4px 8px 4px 8px',
    // height: '28px',
    padding: '8px 16px 8px 16px',
    borderRadius: '5px',
    maxWidth: tooltipWidth === null ? '385px' : tooltipWidth,
    boxSizing: 'border-box',
    marginBottom: '3px !important',
  },
}));

export const GETooltipIf = props => {
  const { disabled = false, children, tooltipWidth = null, ...other } = props;
  return disabled ? children : <GETooltip tooltipWidth={tooltipWidth} {...other}>{children}</GETooltip>;
};

const defaultCheckedIcon = <CheckBoxIcon fontSize='small' />;
const defaultEmptyCheckIcon = <CheckBoxOutlineBlankIcon fontSize='small' />;

export const HoverableListItem = props => {
  const {
    disableCheckBox = false,
    checked = false,
    checkedIcon = defaultCheckedIcon,
    checkedHoverIcon = <CheckBoxHoveredIcon fontSize='small' />,
    emptyCheckIcon = defaultEmptyCheckIcon,
    emptyHoverIcon = <CheckBoxOutlineBlankHoveredIcon fontSize='small' />,
    children,
    ...others
  } = props;
  const [isHover, setHovered] = useState(false);

  return (
    <Box
      {...others}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        marginBottom: 0,
        fontSize: '12px',
        height: '20px',
        lineHeight: '20px',
        color: '#596A7C',
        paddingLeft: '12px'
      }}
    >
      <Stack direction='row' alignItems='center'>
        {!disableCheckBox ? (
          <Checkbox
            icon={isHover ? emptyHoverIcon : emptyCheckIcon}
            checkedIcon={isHover ? checkedHoverIcon : checkedIcon}
            sx={{
              marginRight: '8px',
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 0,
              '&:hover': {
                backgroundColor: 'transparent',
              },
              boxShadow: 'none',
            }}
            checked={checked}
            disableTouchRipple={true}
          />
        ) : null}
        {children}
      </Stack>
    </Box>
  );
};

export const MulSelectInput = props => {
  const {
    id,
    value,
    limitTags = -1,
    loadOptionAction,
    getOptionLabel = option => option,
    getOptionId,
    disableCheckBox = false,
    emptyCheckIcon,
    emptyHoverIcon,
    checkedIcon,
    checkedHoveredIcon,
    renderOption,
    renderTags,
    maxTagWidth = 368,
    disableNewItem = false,
    addNewItemManager = {},
    Cutwidth = 40,
    onClickOpen = () => { },
    noAddSelectItem = false,
    ...others
  } = props;

  const getOptionIdDefault = getOptionId || getOptionLabel;

  const {
    newData,
    itemExist,
    dataGet,
    addNewOption,
    afterAddNewOption,
    afterDateGetSucess,
  } = addNewItemManager;

  const [inputValue, setInputValue] = useState('');
  const ref = useRef(null);

  const handleAddNewItem = () => {
    if (addNewOption === undefined) return;
    addNewOption(trimItemFilds(inputValue));
  };

  const defaultMulOptionRender = (optionProps, option, { selected }) => {
    if (`${getOptionLabel(option)}` === '') return null;
    if (option.id === ADD_ITEM_ID) {
      if (trimItemFilds(inputValue) === '') return null;
      if (disableNewItem) {
        if (
          props.options &&
          Array.isArray(props.options) &&
          !props.options.some(op =>
            getOptionLabel(op).toLowerCase().includes(inputValue.toLowerCase())
          )
        ) {

          return (
            <ListItem>
              <Stack alignItems={'center'} mb='8px' mt='4px'>
                No Options!
              </Stack>
            </ListItem>
          );
        }
        return null;
      }
      if (!noAddSelectItem) {
        return (
          <AddSelectItem
            handleAdd={handleAddNewItem}
            value={trimItemFilds(inputValue)}
            key='qwerty-add-select-item'
          />
        );
      }
      return <></>;
    }

    const choosed = selected;
    // || value.some(op => getOptionIdDefault(op) === getOptionIdDefault(option));
    return (
      <ListItem
        sx={{
          paddingLeft: '0 !important',
          ...(choosed && {
            backgroundColor: '#ffffff !important',
          }),
          '&.MuiAutocomplete-option:hover': {
            backgroundColor: 'rgba(0,0,0,0.04) !important',
          },
        }}
        {...optionProps}
      >
        <HoverableListItem
          disableCheckBox={disableCheckBox}
          checked={choosed}
          checkedIcon={checkedIcon}
          checkedHoverIcon={checkedHoveredIcon}
          emptyCheckIcon={emptyCheckIcon}
          emptyHoverIcon={emptyHoverIcon}
        >
          {getOptionLabel(option)}
        </HoverableListItem>
      </ListItem>
    );
  };

  const defaultRenderTags = (tagValue, getTagProps) => {
    return (
      <div
        className='SelectInput-Tags-Container'
        onClick={ref.current && ref.current.popup}
        style={{ paddingRight: '2px' }}
      >
        {tagValue.map((option, index) => {
          const expectWidth =
            measureWidth(getOptionLabel(option), '12px Open Sans') + 33;
          return (
            <GETooltipIf
              title={getOptionLabel(option)}
              key={index}
              disabled={expectWidth < maxTagWidth}
            >
              <CssChip
                variant='outlined'
                label={getOptionLabel(option)}
                {...getTagProps({ index })}
                sx={{
                  borderRadius: '4px',
                  height: '26px',
                  borderColor: '#BCC5CF !important',
                  '& .MuiChip-deleteIcon': {
                    ml: 0,
                    mr: '8px',
                  },
                  maxWidth: `${maxTagWidth - Cutwidth}px !important`, // Consider
                }}
                deleteIcon={<CleartagIcon />}
              />
            </GETooltipIf>
          );
        })}
      </div>
    );
  };

  return (
    <SelectInput
      ref={ref}
      id={`${id}-select`}
      value={value}
      multiple={true}
      limitTags={limitTags}
      includeInputInList={true}
      disableCloseOnSelect={true}
      renderOption={renderOption || defaultMulOptionRender}
      renderTags={renderTags || defaultRenderTags}
      loadOptionAction={loadOptionAction}
      getOptionLabel={getOptionLabel}
      getOptionId={getOptionIdDefault}
      onInputChange={(event, value) => setInputValue(value)}
      disableNewItem={disableNewItem}
      addNewItemManager={{
        newData,
        itemExist,
        dataGet,
        addNewOption,
        afterAddNewOption: (...params) => {
          loadOptionAction && loadOptionAction(inputValue);
          afterAddNewOption && afterAddNewOption(...params);
        },
        afterDateGetSucess,
      }}
      onClickOpen={onClickOpen}
      {...others}
    />
  );
};

export const TestSelectInput = props => {
  const [department, setDepartment] = useState(null);
  const [departments, setDepartments] = useState([]);
  const departmentList = useSelector(state => state.GE.departmentList);

  const dispatch = useDispatch();

  const addNewDepartmentManager = useSelector(state => ({
    newData: state.GE.newDepartment,
    itemExist: state.GE.newDepartmentExits,
    dataGet: state.GE.isNewDepartmentGet,
    addNewOption: value => {
      dispatch(createDepartment(value));
    },
    afterAddNewOption: newValue => { },
    afterDateGetSucess: (label, newData, stringLabelKey) => { },
  }));

  useEffect(() => {
    dispatch(getDepartmentList(''));
  }, []);

  return (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', width: '286px' }}
        data-selenium-id={'DepartmentBox'}
      >
        <SelectInput
          id='CreateGeDepartmentInput'
          multiple={false}
          value={department}
          defaultOption={key => ({
            id: 0,
            department: key,
          })}
          getOptionLabel={option => option.department}
          options={departmentList}
          loadOptionAction={(...props) => dispatch(getDepartmentList(...props))}
          onValueChange={value => {
            const newValue = value;
            newValue &&
              (newValue.department = trimItemFilds(newValue.department));
            setDepartment(value);
          }}
          addNewItemManager={addNewDepartmentManager}
        />
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', width: '286px' }}
        data-selenium-id={'DepartmentBox2'}
      >
        <SelectInput
          id='CreateGeDepartmentInput2'
          multiple={false}
          error={true}
          value={department}
          defaultOption={key => ({
            id: 0,
            department: key,
          })}
          getOptionLabel={option => option.department}
          options={departmentList}
          loadOptionAction={(...props) => dispatch(getDepartmentList(...props))}
          onValueChange={value => {
            const newValue = value;
            newValue &&
              (newValue.department = trimItemFilds(newValue.department));
            setDepartment(value);
          }}
          disableNewItem={true}
          showPopupIcon={true}
        />
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', width: '286px' }}
        data-selenium-id={'DepartmentBox'}
      >
        <MulSelectInput
          id='CreateGeDepartmentInput3'
          value={departments}
          defaultOption={key => ({
            id: 0,
            department: key,
          })}
          getOptionLabel={option => option.department}
          options={departmentList}
          loadOptionAction={(...props) => dispatch(getDepartmentList(...props))}
          onValueChange={value => {
            setDepartments(value);
          }}
          addNewItemManager={addNewDepartmentManager}
          showPopupIcon={true}
          clearText={''}
        />
      </Box>
    </>
  );
};

function SearchIcon() {
  return (
    <>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.33398 2.66671C4.75666 2.66671 2.66732 4.75605 2.66732 7.33337C2.66732 9.9107 4.75666 12 7.33398 12C9.91131 12 12.0007 9.9107 12.0007 7.33337C12.0007 4.75605 9.91131 2.66671 7.33398 2.66671ZM1.33398 7.33337C1.33398 4.01967 4.02028 1.33337 7.33398 1.33337C10.6477 1.33337 13.334 4.01967 13.334 7.33337C13.334 10.6471 10.6477 13.3334 7.33398 13.3334C4.02028 13.3334 1.33398 10.6471 1.33398 7.33337Z'
          fill='#596A7C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.6289 10.6286C10.8892 10.3683 11.3113 10.3683 11.5717 10.6286L14.4717 13.5286C14.732 13.789 14.732 14.2111 14.4717 14.4714C14.2113 14.7318 13.7892 14.7318 13.5289 14.4714L10.6289 11.5714C10.3685 11.3111 10.3685 10.889 10.6289 10.6286Z'
          fill='#596A7C'
        />
      </svg>
    </>
  );
}

// function CleartagIcon(){
//   return (
//     <>
//       <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <path fill-rule="evenodd" clip-rule="evenodd" d="M9.35355 3.35355C9.54882 3.15829 9.54882 2.84171 9.35355 2.64645C9.15829 2.45118 8.84171 2.45118 8.64645 2.64645L6 5.29289L3.35355 2.64645C3.15829 2.45118 2.84171 2.45118 2.64645 2.64645C2.45118 2.84171 2.45118 3.15829 2.64645 3.35355L5.29289 6L2.64645 8.64645C2.45118 8.84171 2.45118 9.15829 2.64645 9.35355C2.84171 9.54882 3.15829 9.54882 3.35355 9.35355L6 6.70711L8.64645 9.35355C8.84171 9.54882 9.15829 9.54882 9.35355 9.35355C9.54882 9.15829 9.54882 8.84171 9.35355 8.64645L6.70711 6L9.35355 3.35355Z" fill="#596A7C"/>
//       </svg>

//     </>
//   );
// }

import {
  MiniBoldFont,
  SmallBoldFont,
  SmallFont,
  H4Font,
} from '@/components/FontStyle';

export const drawer = {
  width: '318px',
  backgroundColor: '#F6F7F8',
  boxSizing: 'border-box',
  padding: '16px',
};

export const drawer_head = {
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
};

export const drawer_head_title = {
  width: 'calc(100% - 24px)',
  ...H4Font,
  color: '#262E35',
};

export const drawer_head_icon = {
  width: '24px',
  height: '24px',
  padding: '0px',
};

export const drawer_top_buttonList = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  marginTop: '4px',
};

export const drawer_top_button = {
  ...SmallFont,
  color: '#0855A5',
  textTransform: 'none',
};

export const drawer_main = {
  margin: '0px 1.5px 0px 1.5px',
};

export const drawer_main_block = {
  boxSizing: 'border-box',
  padding: '7px 12px 8px 12px',
  marginBottom: '5px',
};

export const drawer_main_block_title = {
  ...MiniBoldFont,
  color: '#596A7C',
};

export const drawer_main_block_operation = {
  marginTop: '4px',
};
export const drawer_main_stage_select_checkbox = {
  width: '16px',
  height: '16px',
  marginRight: '4px',
  padding: '0px',
};

export const drawer_main_stage_select_checkbox_lable = {
  ...MiniBoldFont,
  color: '#828282',
};

export const drawer_bottom_button_list = {
  width: '100%',
  height: '56px',
  background: '#FFFFFF',
  borderTop: '1px solid #DFE4E8',
  position: 'sticky',
  bottom: 0,
};

export const drawer_bottom_button_reset = {
  ...SmallFont,
  color: '#0855A5',
  cursor: 'pointer',
  padding: '18px 16px',
  float: 'left',
};
export const drawer_bottom_button_adapt = {
  ...SmallBoldFont,
  color: '#FFFFFF',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#154AB6',
  width: '110px',
  height: '32px',
  boxSizing: 'border-box',
  margin: '12px 16px 12px 0px',
  borderRadius: '4px',
  float: 'right',
};

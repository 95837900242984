import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { StyledTableCell, TableFooter2 } from '@/components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { visuallyHidden } from '@mui/utils';
import {
  StyledTableCellPurple,
  StyledTableCellDisPurple,
  StyledTableCellGreen,
  StyledTableCellDisGreen,
  StyledTableRow, TableBox, ReportNoData, TableBox2
} from "../../ReportSpecificComponents/SpecialColorTableHeader";

import { getReportarticleOAPBasicTableData } from '@/actions/SIMT-Report/Report/ReportAction';
import { isReportOverCutOffDate } from '@/utils/commonUtils';
import { findLongestLineSubstring, measureWidth } from '../../../../utils/StringWidthMeasure';
import { TextLabel } from "../../../../componentsphase2/CustomTooltip";
import { useShowReportRolesByFyHook } from '@/pages/Report/utils/useShowReportRolesByFyHook';
import {getReportArticleOAPBasicTableData} from "@/actions/SIMT-Report/Report/ReportArticle";

export default function ArticleOverviewBasicTable(props) {
  const { setIfNoResult } = props;
  const [showJPM, showNewJournalRoles] = useShowReportRolesByFyHook();
  const [loading, setLoading] = useState(false);

  const createRow = (
    id,
    label,
    minWidth,
    maxWidth,
    isOrder = false,
    align = 'left',
    show = true
  ) => {
    return { id, label, minWidth, maxWidth, isOrder, align, show };
  };


  const itemsBeforeJPM = useMemo(() => {
    return [
      createRow(
        'journalName',
        'JOURNAL INFORMATION',
        '260px',
        '260px',
        false,
        'left'
      ),
      createRow(
        'journalStatus',
        'JOURNAL STATUS',
        '165px',
        '165px',
        false,
        'left'
      ),
    ];
  }, []);
  
  const itemsAfterJPM = useMemo(() => {
    return [
      createRow(
        'subjectGroup',
        'SUBJECT GROUP',
        '187px',
        '187px',
        false,
        'left'
      ),
      createRow(
        'businessDivision',
        'BUSINESS DIVISION',
        '262px',
        '262px',
        false,
        'left'
      ),
      createRow('catGroup', 'CAT GROUP', '130px', '130px', false, 'left'),
      createRow('handlingCe', 'HANDLING CE', '161px', '161px', false, 'left'),
    ];
  }, []);

  const JPMItem = useMemo(() => {
    return createRow('jpm', 'JPM', '161px', '161px', false, 'left');
  }, []);

  const pdpItem = useMemo(() => {
    return createRow(
      'pdPublisher',
      'PD Publisher',
      '105px',
      '180px',
      false,
      'left'
    );
  }, []);

  const pppItem = useMemo(() => {
    return createRow(
      'ppPublisher',
      'PP Publisher',
      '105px',
      '180px',
      false,
      'left'
    );
  }, []);

  const Subitems = [
    {
      id: 'Sub-MMM',
      label: 'Total Publication',
      maxWidth: '113px',
      minWidth: '113px',
      post: 'submittedArt',
      isOrder: false,
    },
    {
      id: 'Sub-YTD',
      label: 'OA Publication',
      maxWidth: '113px',
      minWidth: '113px',
      post: 'submittedArtYtd',
      isOrder: false,
    },
    {
      id: '%Against YTD Target',
      label: 'OA%',
      maxWidth: '113px',
      minWidth: '113px',
      isOrder: false,
      post: 'submittedArtYtdTarget',
    },
  ];
  const Accitems = [
    {
      id: 'Acc-MMM',
      label: 'Total Publication',
      maxWidth: '113px',
      minWidth: '113px',
      isOrder: false,
      post: 'acceptedArt',
    },
    {
      id: 'Acc-YTD',
      label: 'OA Publication',
      maxWidth: '113px',
      minWidth: '113px',
      isOrder: false,
      post: 'acceptedArtYtd',
    },
    {
      id: '%Against YTD Target',
      label: 'OA%',
      maxWidth: '113px',
      minWidth: '113px',
      isOrder: false,
      post: 'acceptedArtYtdTarget',
    },
  ];


  const [page, setPage] = useState(1);
  const [perPageNumber, setPerPageNumber] = useState(20);

  const [orderType, setOrderType] = useState('journalName'); //排序的表头
  const [orderAs, setOrderAs] = useState('asc'); //排序的顺序
  const [flashLock, setFlashLock] = useState(true);

  const dispatch = useDispatch();
  const {
    articleOAPBasicTableData,
    tableMonth,
    selectedArticleDate,
    cutOffDate,
    articleOAPFilters,
  } = useSelector(state => {
    return {
      // filter display
      articleOAPBasicTableData: state.Report.articleOAPBasicTableData,
      tableMonth: state.Report.articleOAPBasicTableMonth,
      // selectedArticleDate: state.Report.selectedArticleDate,
      selectedArticleDate: state.Report.selectDate,
      cutOffDate: state.Report.cutOffDate,
      articleOAPFilters: state.ReportFilter.articleOAPFilters,
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    for (let item of items){
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '18px Open Sans');
    }

    const CUR_LEN = measureWidth(findLongestLineSubstring('Total Publication'), '18px Open Sans');
    const YTD_LEN = measureWidth(findLongestLineSubstring('OA Publication'), '18px Open Sans');
    const OAP_LEN = measureWidth(findLongestLineSubstring('OA%'), '18px Open Sans');

    for (let type of ['sub','acc']) {
      widthMap[`${type}-0`] = CUR_LEN;
      widthMap[`${type}-1`] = YTD_LEN;
      widthMap[`${type}-2`] = OAP_LEN;
    }

    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (articleOAPBasicTableData && articleOAPBasicTableData.count > 0){
      const maxMap = {};
      for (let row of articleOAPBasicTableData['result']) {
        let {
          journalCode,
          journalName,
          revenueModel,
          journalStatus,
          jpm,
          fullNameOfPdp,
          fullNameOfPpp,
          businessDivision,
          sgAbbr, //use abbravation to represent subject group
          catGroup,
          handlingCe,
        } = row;
        let dataObj = {
          journalCode,
          journalName,
          revenueModel,
          journalStatus,
          jpm,
          pdPublisher: fullNameOfPdp,
          ppPublisher: fullNameOfPpp,
          businessDivision,
          catGroup,
          handlingCe,
          sgAbbr,

          'sub-0':  row.totalPublishedArt ?? '0',
          'sub-1':  row.oaPublishedArt ?? '0',
          'sub-2':  row.oaPublishedArtPercentage ?? '0',

          'acc-0':  row.totalPublishedArtYtd ?? '0',
          'acc-1':  row.oaPublishedArtYtd ?? '0',
          'acc-2':  row.oaPublishedArtYtdPercentage ?? '0',
        };
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      // console.info(maxMap);

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      widthMap['journalName'] = Math.max(widthMap['journalCode'],widthMap['journalName']);
      // console.info(widthMap);
      setColumnWidthMap(widthMap);
    }
  }, [articleOAPBasicTableData]);

  function calcGroupWidth(type){
    let sum = 0;
    for (let i of [0,1,2]) {
      sum += Math.max((columnWidthMap[`${type}-${i}`]??0), columnMinWidthMap[`${type}-${i}`]) + 50;
    }

    return sum;
  }

  const handlePerPageChange = e => {
    setPerPageNumber(e.target.value);
  };

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  const handleChangeOrder = orderBy => {
    let newOrder;
    if (orderType === orderBy) {
      // 如果当前的排序字段没有变化，则切换正反序
      newOrder = orderAs === 'desc' ? 'asc' : 'desc';
    } else {
      // 如果当前排序字段发生变化，则直接按照新的字段升序
      newOrder = 'asc';
    }
    setOrderAs(newOrder);
    setOrderType(orderBy);
  };

  const ifEmptyFilter = () => {
    try {
      if (
        articleOAPFilters.journalCode.length === 0 &&
        articleOAPFilters.revenueModel.length === 0 &&
        articleOAPFilters.jpm.length === 0 &&
        articleOAPFilters.subjectGroup.length === 0 &&
        articleOAPFilters.businessDivision.length === 0 &&
        articleOAPFilters.catGroup.length === 0 &&
        articleOAPFilters.handlingCe.length === 0
      ) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  function handleNull(num) {
    if (num === null || num === undefined) {
      return '-';
    } else {
      return num;
    }
  }

  useEffect(() => {
    async function fetchData() {
      if (!flashLock) {
        await dispatch(
          getReportArticleOAPBasicTableData(
            page,
            perPageNumber,
            orderAs,
            orderType,
            selectedArticleDate,
            articleOAPFilters
          )
        );
      }
      setLoading(false);
      setFlashLock(false);
    }
    setLoading(true);
    if (page === 0) {
      if (!flashLock) {
        setTimeout(() => {
          setPage(1);
        }, 2000);
      }
    } else {
      fetchData();
    }
  }, [page]); // 表格本身属性触发的加载，一切按照默认

  useEffect(() => {
    setFlashLock(false);
    setPage(0);
  }, [
    articleOAPFilters,
    perPageNumber,
    orderType,
    orderAs,
    selectedArticleDate,
  ]); // 表格本身属性触发的加载，一切按照默认

  useEffect(() => {
    var filterEmpty = ifEmptyFilter();
    if (
      (articleOAPBasicTableData === null ||
        articleOAPBasicTableData === undefined ||
        articleOAPBasicTableData.count === 0) &&
      filterEmpty
    ) {
      setIfNoResult(true);
    } else {
      setIfNoResult(false);
    }
  }, [articleOAPBasicTableData]);


  const items = useMemo(() => {
    let flexItem = [];
    if (showJPM) {
      flexItem = [...flexItem, JPMItem];
    }
    if (showNewJournalRoles) {
      flexItem = [...flexItem, pdpItem, pppItem];
    }
    return [...itemsBeforeJPM, ...flexItem, ...itemsAfterJPM];
  }, [showJPM, showNewJournalRoles]);


  const [hover, setHover] = useState(false);
  const [indexs, setIndexs] = useState(0);
  return (
    <TableBox2
      id='BasicTableBox'
      data-selenium-id='Report_Articles_OAP-BasicTableBox'
      dataList={[articleOAPBasicTableData]}
      tableKey={'Report_Articles-OAP-BasicTable'}
      footerHeight={40}
    >
      <TableContainer
        id='reportArticlesOAPBasicTable'
        data-selenium-id='Report_Articles_OAP-BasicTableContainer'
        sx={{
          minHeight: '300px',
          maxHeight: '680px',
          border: '1px solid #DFE4E8',
          borderRadius: '8px 8px 0px 0px',
        }}
      >
        <Table stickyHeader aria-label='sticky table' size='small'
          sx={{
            tableLayout: 'fixed',
            width: 'max-content',
            maxWidth: 'none',
          }}
        >
          <TableHead
            data-selenium-id='Report_Articles_OAP-BasicTableHead'
          >
            <TableRow data-selenium-id='Report_Articles_OAP-BasicTableRow'>
              {items.map(item => (
                <TableCell
                  key={item.label}
                  align={item.align}
                  rowSpan={2}
                  sx={{
                    // minWidth: item.minWidth,
                    // maxWidth: item.maxWidth,
                    // height: '102px',
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    fontWeight: 600,
                    backgroundColor: '#F1F3F5',
                    ...(item.id==='journalName' && {
                      position: 'sticky',
                      left: 0,
                      top: 0,
                      zIndex: 8,
                      backgroundColor: '#DFE4E8',
                    }),
                    width: `${Math.max((columnWidthMap[item.id]??0), columnMinWidthMap[item.id]) + 50 }px`,
                    lineHeight: '18px',
                    letterSpacing: '0.08em',
                    padding: '12px 24px',
                    color: '#596A7C',
                    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                  }}
                  sortDirection={orderType === item.id ? orderType : false}
                /*className={
                  item.id === 'handlingCe'
                    ? 'RightBottomBorder'
                    : 'BottomBorder'
                }*/
                >
                  {/*{item.isOrder ? (
                    <TableSortLabel
                      id={`articlesOverviewSortColumn-${item.id}`}
                      data-selenium-id={`Report_Articles_Overview-SortColumn-${item.id}`}
                      active={item.isOrder && orderType === item.id}
                      direction={orderType === item.id ? orderAs : 'asc'}
                      onClick={() => {
                        if (item.isOrder) {
                          handleChangeOrder(item.id);
                        }
                      }}
                    >
                      {item.id === 'acquiredSi'
                        ? item.label + tableMonth
                        : item.label}
                      {orderType === item.id ? (
                        <Box component='span' sx={visuallyHidden}>
                          {orderAs === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  ) : item.id === 'acquiredSi' ? (
                    item.label + tableMonth
                  ) : (
                    item.label
                  )}*/}
                  {item.label}
                </TableCell>
              ))}
              <TableCell
                align='left'
                colSpan={3}
                className='ReportHeaderFirstLine'
                //className='RightBottomBorder'
                sx={{
                  padding: '12px 0px 12px 24px',
                  height: '42px',
                  fontFamily: 'Open Sans',
                  fontSize: '12px',
                  fontWeight: 600,
                  lineHeight: '18px',
                  letterSpacing: '0.08em',
                  color: '#FFFFFF',
                  background: '#98A7B6',
                  borderLeftWidth: '2px',
                  borderLeftStyle: 'solid',
                  borderLeftColor: '#FFFFFF',
                  width: `${calcGroupWidth('sub')}px`,
                  boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                }}
                data-selenium-id='Report_Articles_OAP-BasicTable-Submission'
              >
                PUBLICATION IN {tableMonth}
              </TableCell>
              <TableCell
                align='left'
                colSpan={3}
                className='ReportHeaderFirstLine'
                //className='RightBottomBorder'
                sx={{
                  padding: '12px 0px 12px 24px',
                  height: '42px',
                  fontFamily: 'Open Sans',
                  fontSize: '12px',
                  fontWeight: 600,
                  lineHeight: '18px',
                  letterSpacing: '0.08em',
                  color: '#FFFFFF',
                  background: '#98A7B6',
                  borderLeftWidth: '2px',
                  borderLeftStyle: 'solid',
                  borderLeftColor: '#FFFFFF',
                  width: `${calcGroupWidth('acc')}px`,
                  boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                }}
                data-selenium-id='Report_Articles_Overview-BasicTable-Acceptance'
              >
                PUBLICATION YTD
              </TableCell>
              {/*<StyledTableCellPurple
                align='left'
                colSpan={3}
                className='RightBottomBorder'
                data-selenium-id='Report_Articles_Overview-BasicTable-Publication'
              >
                Publication
                  </StyledTableCellPurple>*/}
            </TableRow>
            <TableRow>
              {Subitems.map((item, index) => (
                <TableCell
                  align='left'
                  key={item.id}
                  sx={{
                    top: 42.8,
                    height: '60px',
                    padding: '12px 24px',
                    // maxWidth: item.maxWidth,
                    // minWidth: item.minWidth,
                    width: `${Math.max((columnWidthMap[`sub-${index}`]??0), columnMinWidthMap[`sub-${index}`]) + 50 }px`,
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '18px',
                    letterSpacing: '0px',
                    color: '#596A7C',
                    background: '#F1F3F5',
                    borderLeftWidth: item.id==='Sub-MMM'?'2px':null,
                    borderLeftStyle: item.id==='Sub-MMM'?'solid':null,
                    borderLeftColor: item.id==='Sub-MMM'?'#FFFFFF':null,
                    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                  }}
                /*className={
                  item.id === '%Against YTD Target'
                    ? 'RightBottomBorder'
                    : 'BottomBorder'
                }*/
                >
                  {item.label}
                  {/*<TableSortLabel
                    id={`${item.id}SortLabel`}
                    data-selenium-id={`Report_Articles_Overview-BasicTable-${item.id}SortLabel`}
                    active={orderType === item.post}
                    direction={orderType === item.post ? orderAs : 'asc'}
                    onClick={() => {
                      handleChangeOrder(item.post);
                    }}
                  >

                  </TableSortLabel>*/}
                </TableCell>
              ))}
              {Accitems.map((item, index) => (
                <TableCell
                  align='left'
                  key={item.id}
                  sx={{
                    top: 42.8,
                    height: '60px',
                    padding: '12px 24px',
                    // maxWidth: item.maxWidth,
                    // minWidth: item.minWidth,
                    width: `${Math.max((columnWidthMap[`acc-${index}`]??0), columnMinWidthMap[`acc-${index}`]) + 50 }px`,
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '18px',
                    letterSpacing: '0px',
                    color: '#596A7C',
                    background: '#F1F3F5',
                    borderLeftWidth: item.id==='Acc-MMM'?'2px':null,
                    borderLeftStyle: item.id==='Acc-MMM'?'solid':null,
                    borderLeftColor: item.id==='Acc-MMM'?'#FFFFFF':null,
                    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                  }}
                  /*className={
                    item.id === '%Against YTD Target'
                      ? 'RightBottomBorder'
                      : 'BottomBorder'
                  }*/
                >
                  {item.label}
                  {/*<TableSortLabel
                    id={`${item.id}SortLabel`}
                    data-selenium-id={`Report_Articles_Overview-BasicTable-${item.id}SortLabel`}
                    active={orderType === item.post}
                    direction={orderType === item.post ? orderAs : 'asc'}
                    onClick={() => {
                      handleChangeOrder(item.post);
                    }}
                  >
                    {item.id === 'Acc-MMM'
                      ? item.label + tableMonth
                      : item.label}
                  </TableSortLabel>*/}
                </TableCell>
              ))}
              {/*{Pubitems.map(item => (
                <StyledTableCellDisPurple
                  align='right'
                  key={item.id}
                  sx={{
                    top: 32.5,
                    maxWidth: item.maxWidth,
                    minWidth: item.minWidth,
                  }}
                  className={
                    item.id === '%Against YTD Target'
                      ? 'RightBottomBorder'
                      : 'BottomBorder'
                  }
                >
                  <TableSortLabel
                    id={`${item.id}SortLabel`}
                    data-selenium-id={`Report_Articles_Overview-BasicTable-${item.id}SortLabel`}
                    active={orderType === item.post}
                    direction={orderType === item.post ? orderAs : 'asc'}
                    onClick={() => {
                      handleChangeOrder(item.post);
                    }}
                  >
                    {item.id === 'Pub-MMM'
                      ? item.label + tableMonth
                      : item.label}
                  </TableSortLabel>
                </StyledTableCellDisPurple>
                  ))}*/}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              articleOAPBasicTableData != null &&
              articleOAPBasicTableData.count > 0 ? (
              articleOAPBasicTableData['result']?.map((row, index) => {
                if (row !== null) {
                  return (
                    <TableRow
                      data-selenium-id={`Report_Articles_Overview-BasicTable-Row-${index}`}
                      key={row.id}
                      aria-checked={false}
                      sx={{
                        height: '64px',
                        background: '#FFFFFF',
                        '&:hover':{
                          background: '#E8EDFB',
                        }
                      }}
                      onMouseEnter={() => {
                        setHover(true);
                        setIndexs(index);
                      }}
                      onMouseLeave={() => {
                        setHover(false);
                        setIndexs(index);
                      }}
                      selected={false}
                    >
                      <TableCell
                        sx={{
                          background: hover && indexs === index ?'#E8EDFB':'#F6F7F8',
                          padding: '12px 24px',
                          position: 'sticky',
                          left: 0,
                          zIndex: 6,
                        }}
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell1-${index}`}
                      >
                        <Box
                          sx={{
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '20px',
                            color: '#262E35',
                          }}
                        >
                          {row.journalCode}
                        </Box>
                        <Box
                          sx={{
                            fontSize: '14px',
                            color: '#596A7C',
                            fontWeight: 400,
                            lineHeight: '20px',
                          }}
                        >
                          {row.journalName}
                        </Box>
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell2-${index}`}
                      >
                        {row.journalStatus}
                      </TableCell>
                      
                      {/* JPM */}
                      {showJPM && (
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell3-A-${index}`}
                      >
                        <TextLabel
                          text={row.jpm}
                          TooltipText={row.emailOfJpm}
                        />
                      </TableCell>)}

                      {/* PD Publisher */}
                      {showNewJournalRoles && (
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell3-B-${index}`}
                      >
                        <TextLabel
                          text={row.fullNameOfPdp}
                          TooltipText={row.emailOfPdp}
                        />
                      </TableCell>)}
                      
                      {/* PP Publisher */}
                      {showNewJournalRoles && (
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell3-B-${index}`}
                      >
                        <TextLabel
                          text={row.fullNameOfPpp}
                          TooltipText={row.emailOfPpp}
                        />
                      </TableCell>)}
                      
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell3-${index}`}
                      >
                          <TextLabel
                          text={row.sgAbbr}
                          TooltipText={row.subjectGroup}
                        />
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell4-${index}`}
                        >
                          {row.businessDivision}
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell5-${index}`}
                        >
                          {row.catGroup}
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell6-${index}`}
                        >
                          <TextLabel
                            text={row.handlingCe}
                            TooltipText={row.emailOfHandlingCe}
                          />
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell7-${index}`}
                        >
                          {row.totalPublishedArt}
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          //className='ReportNumberTextColor'
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell8-${index}`}
                        >
                          {/*<TextLabel text={handleNull(row.submittedArt)} />*/}
                          {row.oaPublishedArt}
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell9-${index}`}
                        >
                          {row.oaPublishedArtPercentage}
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          //className='RightBorder ReportNumberTextColor'
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell10-${index}`}
                        >
                          {/*<TextLabel
                          text={handleNull(row.submittedArtYtdTarget)}
                      />*/}
                          {row.totalPublishedArtYtd}
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell11-${index}`}
                        >
                          {row.oaPublishedArtYtd}
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          data-selenium-id={`Report_Articles_Overview-BasicTable-Cell12-${index}`}
                        >
                          {row.oaPublishedArtYtdPercentage}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })
              ) : !loading ? (
                <ReportNoData top='50px' id={'Report-Article-OAPublication-NoData'}/>
              ) : (
                <div></div>
              )}
          </TableBody>
        </Table>
        {loading && (
          <Box
            sx={{
              width: 'auto',
              marginTop: '150px',
              marginLeft: '50%',
            }}
            data-selenium-id='Report_Articles_Overview-BasicTable-Box'
          >
            <CircularProgress />
          </Box>
        )}
      </TableContainer>
      <Box>
        <Divider />
        {articleOAPBasicTableData != null &&
          articleOAPBasicTableData['count'] > 0 ? (
            <TableFooter2
              numSelected={0}
              data-selenium-id='Report_Articles_Overview-BasicTable-TableFooter2'
              Page={page}
              perPageNumber={perPageNumber}
              perPageList={[20, 50, 100]}
              count={
                articleOAPBasicTableData != null
                  ? articleOAPBasicTableData['count']
                  : 0
              }
              handlePerPageChange={handlePerPageChange}
              handlePageChange={handlePageChange}
              backgroundColor='#f1f3f5'
            />
          ) : (
            <div></div>
          )}
      </Box>
    </TableBox2 >
  );
}

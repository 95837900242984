/**
 * get & save selected SG/BD
 */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserSelectedItem } from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';

export function useSaveUserSelectionHook(
  reduxTitle,
  itemList
) {
  const dispatch = useDispatch();

  const selectedItem = useSelector(state => {
    return state.ReportDiagram[reduxTitle];
  });

  /**
   * when itemList changes, need to check if we need update selected SG/BD
   */
  useEffect(() => {
    if (Array.isArray(itemList) && itemList.length > 0) {
      const isSelectedSGInItemList =
        itemList.findIndex(x => x === selectedItem) >= 0;
      if (!isSelectedSGInItemList) {
        dispatch(saveUserSelectedItem(reduxTitle, itemList[0]));
      }
    }
    // after this, selected SG/BD may no long exist in the itemList, but that's fine
  }, [itemList, selectedItem]);

  return selectedItem;
}

/*
 * @Author: LiuJingQian 1250366059@qq.com
 * @Date: 2024-03-01 14:01:43
 * @LastEditors: LiuJingQian 1250366059@qq.com
 * @LastEditTime: 2024-03-03 13:52:51
 * @FilePath: \simt_front-1\src\actions\SIMT-SI\SpecialIssue\SpecialIssueColumns.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {axios_instance} from "@/utils/axios_instance";
import {default_columns, GET_COLUMNS, SAVE_COLUMNS} from "@/actions/SIMT-SI/SpecialIssue/SIAction";
import { getUserId } from "@/utils/getUserId";

/**
 * 保存Colume相关信息
 */
export function saveUserColumns(data) {
    const userId = getUserId();
    const type = 'SI';
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/users/${userId}/columns/${type}`;
            const res = await axios_instance.post(
                url,
                {...data},
                {
                    params: {
                        solicited: false
                    },
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: SAVE_COLUMNS, data: true});
            } else {
                dispatch({type: SAVE_COLUMNS, data: null});
            }
        } catch (e) {
            dispatch({type: SAVE_COLUMNS, data: null});
        }
    };
}

/**
 * 获取Colume相关信息
 */
export function getUserColumns() {
    const userId = getUserId();
    const type = 'SI';
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/users/${userId}/columns/${type}`;
            const res = await axios_instance.get(url, {
                params: {
                    solicited: false
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: GET_COLUMNS, data: res.data.data});
            } else {
                dispatch({type: GET_COLUMNS, data: default_columns});
            }
        } catch (e) {
            dispatch({type: GET_COLUMNS, data: default_columns});
        }
    };
}
import * as actions from '../actions/PopupAction';

const initState = {
  open: false,
  superAdminPop: [],
  superAdminData: [],
};

export default function PopupReducer(state = initState, action) {
  const { type, data } = action;

  switch (type) {
    case actions.POPUP_OPEN:
      return {
        ...state,
        open: true,
      };
    case actions.POPUP_CLOSE:
      return {
        ...state,
        open: false,
      };
    case actions.POPUP_CLEAR:
      return initState;
    case actions.SUPER_POPUP_CONTROL:
      return {
        ...state,
        superAdminPop: data,
      };
    case actions.SET_SUPER_POPUP_DATA:
      return {
        ...state,
        superAdminData: data,
      };
    default:
      return state;
  }
}

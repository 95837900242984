import { Box, IconButton, Stack, Typography } from '@mui/material';
import { hiddenFilenameTransform, getFileType } from './utils';
import { useRef, useState, useEffect } from 'react';
import DownLoadFIle from '@/assets/DownLoadFIle.svg';
const DefaultDeleteButton = () => {
    return (
        <svg
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M5.19543 1.72313C5.32045 1.60947 5.49002 1.54562 5.66683 1.54562H8.3335C8.51031 1.54562 8.67988 1.60947 8.8049 1.72313C8.92992 1.83679 9.00016 1.99094 9.00016 2.15168V3.00016H5.00016V2.15168C5.00016 1.99094 5.0704 1.83679 5.19543 1.72313ZM3.66683 3.00016V2.15168C3.66683 1.66947 3.87754 1.207 4.25262 0.866029C4.62769 0.525054 5.1364 0.333496 5.66683 0.333496H8.3335C8.86393 0.333496 9.37264 0.525054 9.74771 0.866029C10.1228 1.207 10.3335 1.66947 10.3335 2.15168V3.00016H13.0002C13.3684 3.00016 13.6668 3.29864 13.6668 3.66683C13.6668 4.03502 13.3684 4.3335 13.0002 4.3335H12.3335V11.8486C12.3335 12.3309 12.1228 12.7933 11.7477 13.1343C11.3726 13.4753 10.8639 13.6668 10.3335 13.6668H3.66683C3.1364 13.6668 2.62769 13.4753 2.25262 13.1343C1.87754 12.7933 1.66683 12.3309 1.66683 11.8486V4.3335H1.00016C0.631973 4.3335 0.333496 4.03502 0.333496 3.66683C0.333496 3.29864 0.631973 3.00016 1.00016 3.00016H3.66683ZM3.00016 4.3335V11.8486C3.00016 12.0094 3.0704 12.1635 3.19543 12.2772C3.32045 12.3909 3.49002 12.4547 3.66683 12.4547H10.3335C10.5103 12.4547 10.6799 12.3909 10.8049 12.2772C10.9299 12.1635 11.0002 12.0094 11.0002 11.8486V4.3335H3.00016ZM9.00016 6.8335V9.8335C9.00016 10.1096 8.70169 10.3335 8.3335 10.3335C7.96531 10.3335 7.66683 10.1096 7.66683 9.8335V6.8335C7.66683 6.55735 7.96531 6.3335 8.3335 6.3335C8.70169 6.3335 9.00016 6.55735 9.00016 6.8335ZM6.3335 6.8335C6.3335 6.55735 6.03502 6.3335 5.66683 6.3335C5.29864 6.3335 5.00016 6.55735 5.00016 6.8335V9.8335C5.00016 10.1096 5.29864 10.3335 5.66683 10.3335C6.03502 10.3335 6.3335 10.1096 6.3335 9.8335V6.8335Z'
                fill='#596A7C'
            />
        </svg>
    );
};

const DefaultHoverDeleteButton = () => {
    return (
        <svg
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M5.19543 1.72313C5.32045 1.60947 5.49002 1.54562 5.66683 1.54562H8.3335C8.51031 1.54562 8.67988 1.60947 8.8049 1.72313C8.92992 1.83679 9.00016 1.99094 9.00016 2.15168V3.00016H5.00016V2.15168C5.00016 1.99094 5.0704 1.83679 5.19543 1.72313ZM3.66683 3.00016V2.15168C3.66683 1.66947 3.87754 1.207 4.25262 0.866029C4.62769 0.525054 5.1364 0.333496 5.66683 0.333496H8.3335C8.86393 0.333496 9.37264 0.525054 9.74771 0.866029C10.1228 1.207 10.3335 1.66947 10.3335 2.15168V3.00016H13.0002C13.3684 3.00016 13.6668 3.29864 13.6668 3.66683C13.6668 4.03502 13.3684 4.3335 13.0002 4.3335H12.3335V11.8486C12.3335 12.3309 12.1228 12.7933 11.7477 13.1343C11.3726 13.4753 10.8639 13.6668 10.3335 13.6668H3.66683C3.1364 13.6668 2.62769 13.4753 2.25262 13.1343C1.87754 12.7933 1.66683 12.3309 1.66683 11.8486V4.3335H1.00016C0.631973 4.3335 0.333496 4.03502 0.333496 3.66683C0.333496 3.29864 0.631973 3.00016 1.00016 3.00016H3.66683ZM3.00016 4.3335V11.8486C3.00016 12.0094 3.0704 12.1635 3.19543 12.2772C3.32045 12.3909 3.49002 12.4547 3.66683 12.4547H10.3335C10.5103 12.4547 10.6799 12.3909 10.8049 12.2772C10.9299 12.1635 11.0002 12.0094 11.0002 11.8486V4.3335H3.00016ZM9.00016 6.8335V9.8335C9.00016 10.1096 8.70169 10.3335 8.3335 10.3335C7.96531 10.3335 7.66683 10.1096 7.66683 9.8335V6.8335C7.66683 6.55735 7.96531 6.3335 8.3335 6.3335C8.70169 6.3335 9.00016 6.55735 9.00016 6.8335ZM6.3335 6.8335C6.3335 6.55735 6.03502 6.3335 5.66683 6.3335C5.29864 6.3335 5.00016 6.55735 5.00016 6.8335V9.8335C5.00016 10.1096 5.29864 10.3335 5.66683 10.3335C6.03502 10.3335 6.3335 10.1096 6.3335 9.8335V6.8335Z'
                fill='#262E35'
            />
        </svg>
    );
};

export function LabelFile(props) {
    const { FileIcon, del, fileName, fileSize, fileType } = props;

    const sizeEl = useRef();
    const typeEl = useRef();
    const [maxWidth, setMaxWidth] = useState('249px');
    const [deleteHover, setDeleteHover] = useState(false);
    let FileName = fileName;
    if (fileName.split('_').length > 1) FileName = fileName.split('_')[1];
    else FileName = fileName;
    useEffect(() => {
        setMaxWidth(
            321 - sizeEl.current.offsetWidth - typeEl.current.offsetWidth + 'px'
        );
    }, []);

    return (
        <Box
            sx={{
                height: '50px',
                p: '8px 6px 8px 6px',
                border: '1px solid #98A7B6',
                borderRadius: '4px',
                bgcolor: '#FFFFFF',
            }}
        >
            <Box
                sx={{
                    height: '32px',
                    bgcolor: '#F1F3F5',
                    borderRadius: '4px',
                    p: '6px',
                    '&:hover': {
                        bgcolor: '#CEDDF9',
                    },
                }}
            >
                <Box
                    sx={{
                        float: 'left',
                    }}
                >
                    <FileIcon />
                </Box>

                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                        pl: '9px',
                    }}
                >
                    <Stack
                        direction='row'
                        spacing={0.75}
                        sx={{
                            height: '20px',
                            pt: '1px',
                        }}
                    >
                        <Stack direction='row' spacing={0}>
                            <Typography
                                sx={{
                                    color: '#262E35',
                                    fontSize: '14px',
                                    fontFamily: 'Open Sans',
                                    lineHeight: '18px',
                                    maxWidth: maxWidth,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {hiddenFilenameTransform(FileName)}
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#262E35',
                                    fontSize: '14px',
                                    fontFamily: 'Open Sans',
                                    lineHeight: '18px',
                                }}
                                ref={typeEl}
                            >
                                {fileType === '' ? ' ' : ` .${fileType}`}
                            </Typography>
                        </Stack>

                        <Box
                            sx={{
                                color: '#596A7C',
                                fontSize: '12px',
                                fontFamily: 'Open Sans',
                                whiteSpace: 'nowrap',
                            }}
                            ref={sizeEl}
                        >
                            {`(${fileSize.size} ${fileSize.unit})`}
                        </Box>
                    </Stack>
                    <span
                        style={{
                            cursor: 'pointer',
                            marginTop: '-1px',
                            marginLeft: '4px',
                        }}
                        onClick={() => {
                            del();
                        }}
                        onMouseEnter={() => {
                            setDeleteHover(true);
                        }}
                        onMouseLeave={() => {
                            setDeleteHover(false);
                        }}
                    >
                        {deleteHover ? (
                            <DefaultHoverDeleteButton />
                        ) : (
                            <DefaultDeleteButton />
                        )}
                    </span>
                </Stack>
            </Box>
        </Box>
    );
}

export function GetLabel(props) {
    const {
        sx,
        FileIcon,
        del,
        download,
        fileName,
        fileSize,
        fileMIMEType,
        titleMaxWidth = 218,
        needDelete = false,
    } = props;

    const sizeEl = useRef();
    const typeEl = useRef();
    const [maxWidth, setMaxWidth] = useState('146x');
    const [deleteHover, setDeleteHover] = useState(false);
    useEffect(() => {
        setMaxWidth(
            titleMaxWidth -
            sizeEl.current.offsetWidth -
            typeEl.current.offsetWidth -
            20 +
            'px'
        );
    }, []);
    return (
        <Box
            sx={{
                width: '286px',
                height: '32px',
                bgcolor: '#F1F3F5',
                borderRadius: '4px',
                p: '6px',
                marginLeft: '0px',
                '&:hover': {
                    bgcolor: '#CEDDF9',
                },
                ...sx,
            }}
        >
            <Box
                sx={{
                    float: 'left',
                }}
            >
                <FileIcon />
            </Box>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{
                    pl: '9px',
                }}
            >
                <Stack
                    direction='row'
                    spacing={0.75}
                    sx={{
                        height: '20px',
                        pt: '1px',
                    }}
                >
                    <Stack direction='row' spacing={0}>
                        <Typography
                            sx={{
                                color: '#262E35 !important',
                                fontSize: '14px',
                                fontFamily: 'Open Sans',
                                lineHeight: '18px',
                                maxWidth: maxWidth,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                fontWeight: '400 !important',
                            }}
                        >
                            {fileName}
                        </Typography>
                        <Typography
                            sx={{
                                color: '#262E35',
                                fontSize: '14px',
                                fontFamily: 'Open Sans',
                                lineHeight: '18px',
                            }}
                            ref={typeEl}
                        >
                            {/* {'.'+fileMIMEType} */}
                        </Typography>
                    </Stack>
                    <Box
                        sx={{
                            color: '#596A7C',
                            fontSize: '12px',
                            fontFamily: 'Open Sans',
                            whiteSpace: 'nowrap',
                            fontWeight: 400,
                            lineHeight: '16.34px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        ref={sizeEl}
                    >
                        {'(' + fileSize + ')'}
                    </Box>
                </Stack>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }} >
                    <span
                        style={{
                            cursor: 'pointer',
                            marginTop: '-1px',
                            marginLeft: '4px',
                        }}
                        onClick={download}
                        onMouseEnter={() => {
                            setDeleteHover(true);
                        }}
                        onMouseLeave={() => {
                            setDeleteHover(false);
                        }}
                    >
                        <>
                            <Stack>
                                <DownLoadFIle />
                            </Stack>
                        </>
                    </span>
                    <span
                        style={{
                            cursor: 'pointer',
                            marginTop: '-1px',
                            marginLeft: '4px',
                            display: needDelete ? 'block' : 'none',
                        }}
                        onClick={del}
                        onMouseEnter={() => {
                            setDeleteHover(true);
                        }}
                        onMouseLeave={() => {
                            setDeleteHover(false);
                        }}
                    >
                        <>
                            {deleteHover ? (
                                <DefaultHoverDeleteButton />
                            ) : (
                                <DefaultDeleteButton />
                            )}
                        </>
                    </span>
                </Box>
            </Stack>
        </Box>
    );
}

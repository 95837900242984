import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import DrawerCloseButton from '@/componentsphase2/DrawerCloseButton';
import {
  Box,
  Backdrop,
  backdropClasses,
  styled,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import { refreshData } from '@/actions/CommonAction';
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import {updateCATJournalComments} from "@/actions/SIMT-SI/CatJournal";
import {PERMISSIONS} from "@/constant/permission";

export function CatCommentsDialog(props) {
  const {
    commentsOpen,
    setCommentsOpen,
    value,
    setValue,
    edit,
    setEdit,
    editButtonDisabled,
    code,
    title = 'Comments',
  } = props;
  const [wordCount, setWordCount] = useState(0);
  const [ButtonDisable, setButtonDisable] = useState(true);
  const dispatch = useDispatch();
  const handleClose = () => {
    setCommentsOpen(false);
    setValue('');
  };

  const handleChange = e => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (value === null) {
      setValue('');
    } else if (value !== '' && value.length <= 10000) {
      setButtonDisable(false);
      setWordCount(value.length);
    } else if (value.length >= 10000) {
      setValue(value.substring(0, 10000));
    } else {
      setButtonDisable(true);
      setWordCount(value.length);
    }
  }, [value]);

  const handleSaveEdit = () => {

    dispatch(updateCATJournalComments(code, value));
    setValue('');
    setCommentsOpen(false);
    setTimeout(() => {
      dispatch(refreshData());
    }, 1000);
  };

  const handleEditOpen = () => {
    setEdit(true);

  };

  const BlueBackdrop = styled(Backdrop)(() => ({
    [`&.${backdropClasses.root}`]: {
      background: 'none',
    },
  }));

  return (
    <>
      <Dialog
        id='CatOverviewEditCommentsDialog'
        data-selenium-id='CATJournal_Overview-Table-CommentsDialog-EditDialog'
        maxWidth={false}
        open={commentsOpen}
        onClose={handleClose}
        aria-labelledby='draggable-dialog-title'
        components={{
          Backdrop: BlueBackdrop,
        }}
        slotProps={{
          backdrop: {
            onClick: null, // zhaoyicheng禁用点击背景关闭 Modal
          },
        }}
        sx={{
          background: ' rgba(17, 61,149, 0.5)',
          backdropFilter: 'blur(4px)',
          zIndex: 1401,
          '& .MuiPaper-root': {
            margin: '10px',
            borderRadius: '8px',
            width: '600px',
            boxShadow: 'none'
            //height: "330px",
          },
        }}
      >
        <Stack
          id='frame-2438'
          data-selenium-id='CATJournal_Overview-Table-CommentsDialog-FrameStack2438'
          justifyContent='space-between'
          direction='row'
          sx={{
            width: '536px',
            padding: '24px 0px 16px 0px',
            margin: '0px auto'
          }}
        >
          <DialogTitle
            style={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22.5px',
              backgroundColor: '#ffffff',
              color: '#262E35',
              padding: 0,
            }}
            id='draggable-dialog-title'
            data-selenium-id='CATJournal_Overview-Table-CommentsDialog-DraggableDialogTitle'
          >
            {title}
          </DialogTitle>
          <DrawerCloseButton
            onClick={handleClose}
            data-selenium-id='CATJournal_Overview-Table-CommentsDialog-IconButton'
          />
        </Stack>
        <Stack
          id='Textarea-Base-Default'
          data-selenium-id='CATJournal_Overview-Table-CommentsDialog-Textarea-Base-Default'
          sx={{
            margin: '0px auto',
            width: '536px',
          }}
        >
          <DialogContent
            sx={{
              padding: 0,
              lineHeight: '20px',
              '&. MuiInputBase-root': {
                lineHeight: '20px',
              },
            }}
          >
            <DialogContentText
              id='CATJournalOverviewDialogContentText'
              data-selenium-id='CATJournal_Overview-Table-CommentsDialog-DialogContentText'
              style={{
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#262E35',
              }}
            >
              {edit ? (
                <Box
                  style={{ marginTop: '0px' }}
                  data-selenium-id='CATJournal_Overview-Table-CommentsDialog-EditBox'
                >
                  {/* <TextField
                    id='outlined-multiline-flexible'
                    data-selenium-id='CATJournal_Overview-Table-CommentsDialog-OutlinedMultilineFlexibleTextField'
                    fullWidth
                    multiline
                    rows={10}
                    value={value}
                    onChange={handleChange}
                    sx={{
                      '& .MuiInputBase-root.MuiOutlinedInput-root': {
                        padding: '8px 4px 12px 12px',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#262E35',
                      },
                      '& .MuiOutlinedInput-root': {
                      },
                      //lineSpacing: 1,
                    }}
                  /> */}
                  <MyOnlyText
                    // sx={{
                    //   '& .MuiOutlinedInput-root':{
                    //     padding: '0px 0px 0px 0px',

                    //   },
                    // }}
                    id='outlined-multiline-flexible'
                    data-selenium-id='CATJournal_Overview-Table-CommentsDialog-OutlinedMultilineFlexibleTextField'
                    width={'536px'}
                    height={'220px'}
                    minRows={10}
                    maxRows={10}
                    value={value}
                    setValue={setValue}
                    onChange={handleChange}
                  >
                  </MyOnlyText>
                  <div
                    style={{
                      fontFamily: 'Open Sans',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '12px',
                      lineHeight: '18px',
                      textAlign: 'right',
                      color: '#596A7C',
                      marginTop: '8px',
                      width: '536px',
                    }}
                    data-selenium-id='CATJournal_Overview-Table-CommentsDialog-Div'
                  >
                    {wordCount}/10,000
                  </div>
                </Box>
              ) : (
                <Box
                  data-selenium-id='CATJournal_Overview-Table-CommentsDialog-NotEditBox'
                  sx={{
                    paddingRight: '6px'
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Open Sans',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '20px',
                      letterSpacing: '0em',
                      overflowWrap: 'anywhere',
                      overflowY: 'auto',
                      maxHeight: '220px',
                      color: '#262E35',
                    }}
                  >{value}
                  </Typography>
                </Box>
              )}
            </DialogContentText>
          </DialogContent>
        </Stack>

        {edit ? (
          <DialogActions
            sx={{ padding: '12px 32px 20px 8px', marginTop: '12px' }}
            data-selenium-id='CATJournal_Overview-Table-CommentsDialog-EditDialogActions'
          >
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='flex-end'
              spacing='9px'
            >
              <Button
                disableRipple
                disableElevation
                autoFocus
                onClick={handleClose}
                id='catOverviewCommentsEditDialogCancelButton'
                data-selenium-id='CATJournal_Overview-Table-CommentsDialog-EditDialogCancelButton'
                sx={{
                  alignItems: 'center',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '18px',
                  letterSpacing: '0.01em',
                  color: '#0052CC',
                  textTransform: 'capitalize',
                  padding: '8px, 12px, 8px, 12px',
                  height: '34px',
                  width: '70px',
                  border: '1px solid #0052CC',
                  borderRadius: '5px',
                  '&:hover': {
                    color: '#6D98EE',
                    borderColor: '#6D98EE',
                    background: '#FFFFFF',
                  },
                  '&:active': {
                    background: '#E8EDFB',
                    color: '#6D98EE',
                    borderColor: '#AEC6F6',
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                disableRipple
                disableElevation
                id='catOverviewCommentsEditDialogSaveButton'
                data-selenium-id='CATJournal_Overview-Table-CommentsDialog-EditDialogSaveButton'
                onClick={handleSaveEdit}
                variant='contained'
                disabled={ButtonDisable}
                sx={{
                  padding: '8px 12px 8px 12px',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '18px',
                  letterSpacing: '1px',
                  background: '#0052CC',
                  textTransform: 'capitalize',
                  height: '34px',
                  width: '70px',
                  marginLeft: '10px',
                  color: 'white',
                  '&:hover': {
                    background: '#6D98EE',
                  },
                  '&:active': {
                    background: '#113D95',
                  },
                }}
              >
                Save
              </Button>
            </Stack>
          </DialogActions>
        ) : (
          <DialogActions
            sx={{ padding: '12px 32px 20px 8px', marginTop: '12px' }}
            data-selenium-id='CATJournal_Overview-Table-CommentsDialog-NotEditDialogActions'
          >
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='flex-end'
              spacing='9px'
            >
              <Button
                disableRipple
                disableElevation
                autoFocus
                onClick={handleClose}
                id='catOverviewViewMoreDialogCloseButton'
                data-selenium-id='CATJournal_Overview-Table-CommentsDialog-MoreDialogCloseButton'
                sx={{
                  alignItems: 'center',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '18px',
                  letterSpacing: '0.01em',
                  color: '#0052CC',
                  textTransform: 'capitalize',
                  padding: '8px 12px 8px 12px',
                  height: '34px',
                  width: '70px',
                  border: '1px solid #0052CC',
                  borderRadius: '5px',
                  '&:hover': {
                    color: '#6D98EE',
                    borderColor: '#6D98EE',
                    background: '#FFFFFF',
                  },
                  '&:active': {
                    background: '#E8EDFB',
                    color: '#6D98EE',
                    borderColor: '#AEC6F6',
                  },
                }}
              >
                Close
              </Button>
              <ShowForPermission permission={`${PERMISSIONS.JOURNAL_CAT_WRITE}`}>
                <Button
                  disableRipple
                  disableElevation
                  id='catOverviewViewMoreDialogEditButton'
                  data-selenium-id='CATJournal_Overview-Table-CommentsDialog-MoreDialogEditButton'
                  onClick={handleEditOpen}
                  variant='contained'
                  disabled={editButtonDisabled}
                  sx={{
                    padding: '8px 12px 8px 12px',
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    background: '#0052CC',
                    textTransform: 'capitalize',
                    height: '34px',
                    width: '70px',
                    color: 'white',
                    '&:hover': {
                      background: '#6D98EE',
                    },
                    '&:active': {
                      background: '#113D95',
                    },
                  }}
                >
                  Edit
                </Button>
              </ShowForPermission>
            </Stack>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}

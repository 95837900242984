import {
  Box,
  Button,
} from '@mui/material';
import styleModule from './SaveAndCancelButton.module.scss';
import { LoadingButton } from '@mui/lab';
export default function ({ handleSubmit, handleCancel, handleSaveLater, isDisabled, error , isSaving = false}) {
  return (<Box
    className={styleModule.SaveAndCancelButtonBox}
  >
    <Button
      disableElevation
      disableRipple
      className={styleModule.CancelButton}
      onClick={() => handleCancel()}
    >Cancel</Button>
    {error?
    <Button
      disableElevation
      disableRipple
      onClick={() => handleSaveLater()}
      className={styleModule.SaveLaterButton}
    >
      Save for later
    </Button>
    :<LoadingButton
    sx={{
      fontWeight: 600,
      fontSize: '14px',
      height: '41px',
      padding: '12px 18px',
      background: isSaving?'#6D98EE': isDisabled ?'#DFE4E8':'#0052CC',
      borderRadius: '5px',
      color: isDisabled ? '#98A7B6' : '#FFFFFF',
      width: '114px',
      textTransform: 'none',
      '&:hover':{
          background: '#6D98EE',
      },
      '&:active':{
          background: '#113D95',
      }
  }}
      disableElevation
      disableRipple
      onClick={() => handleSubmit()}
      disabled={isDisabled}
      loading={isSaving}
      // className={styleModule.SaveButton}
    >Save</LoadingButton>}
  </Box >);
}
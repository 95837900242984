/*
 * @Author: Huang Tao
 * @Date:2022/1/19
 * @Description:New User props
 */

import React from 'react';
import '../../../App.css';
import EditUser from './EditUser';

export default function CreateNewUser(props) {
  const { open, setOpen, updateData } = props;

  return (
    <React.Fragment>
      <EditUser
        data-seleunim-id='SystemAdmin_UserManagement-CreateUser-Fragment-EditUser'
        open={open}
        setOpen={setOpen}
        isCreate={true}
        Email=''
        FirstName=''
        LastName=''
        Group=''
        Role=''
        Status='Inactive'
        Lock='unLock'
        updateData={updateData}
      />
    </React.Fragment>
  );
}

import styled from '@emotion/styled';
import { Chip } from '@mui/material';
import { LimitWord } from '../Css';
import { MiniFont } from '@/components/FontStyle';

export const StyledChip = styled(Chip)(({ theme }) => ({
  maxWidth: '387px',
  height: '22px',
  padding: '5px 8px 5px 12px',
  boxSizing: 'border-box',
  backgroundColor: '#FFFFFF',
  margin: '0px',
  //layout
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '6px',
  //border
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '4px',
  borderColor: theme.themeColor?.first ?? '#154AB6',
  '& .MuiChip-label': {
    padding: '0px',
    ...LimitWord,
    //font
    ...MiniFont,
    color: '#596A7C',
    letterSpacing: '0px',
    maxWidth:'347px'
  },
  '& .MuiChip-deleteIcon': {
    margin: '0px',
    '& :hover': {
      background: '#BCC5CF',
      borderRadius: '2px',
    },
  },
  ':hover': {
    backgroundColor: '#DFE4E8',
  },
}));

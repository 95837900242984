import {axios_instance, axios_instance_fileDownload,} from '../../../utils/axios_instance';
import {setSnackbarMessageAndOpen} from '../../SnackbarAction';
import {downloadFile} from '../../SIMT-SI/SpecialIssue/SIAction';
import {SEVERITIES} from '../../../modules/ConfirmSnackBar/CommonSnackBar';
import moment from 'moment';
import getAuth from '@/utils/getAuth';
import { formatDate } from '@/utils/date_string';

export const SELECT_DATE = 'SELECT_DATE';

// new
export const REQUEST_CHART_DATA = 'REQUEST_CHART_DATA';
export const REQUEST_CHART_DATA_SUCCESS = 'REQUEST_CHART_DATA_SUCCESS';
export const REQUEST_CHART_DATA_FAIL = 'REQUEST_CHART_DATA_FAIL';
// new end

export const GET_CHART_DATA = 'GET_CHART_DATA';
export const GET_SI_PIPELINE_CAT_GROUP_CHARTDATA =
  'GET_SI_PIPELINE_CAT_GROUP_CHARTDATA';
export const GET_SI_PIPELINE_CAT_GROUP_CHARTDATA_SUCCESS =
  'GET_SI_PIPELINE_CAT_GROUP_CHARTDATA_SUCCESS';
export const GET_SI_PIPELINE_CAT_GROUP_CHARTDATA_FAIL =
  'GET_SI_PIPELINE_CAT_GROUP_CHARTDATA_FAIL';

export const GET_SI_PIPELINE_CE_CHARTDATA = 'GET_SI_PIPELINE_CE_CHARTDATA';
export const GET_SI_PIPELINE_CE_CHARTDATA_SUCCESS =
  'GET_SI_PIPELINE_CE_CHARTDATA_SUCCESS';
export const GET_SI_PIPELINE_CE_CHARTDATA_FAIL =
  'GET_SI_PIPELINE_CE_CHARTDATA_FAIL';
export const GET_A_AND_A_OVERVIEW_EXCEL = 'GET_A_AND_A_OVERVIEW_EXCEL';

export const GET_ARTICLE_OVERVIEW_EXCEL = 'GET_ARTICLE_OVERVIEW_EXCEL';
export const GET_A_AND_A_ANNUAL_TRENDS = 'GET_A_AND_A_ANNUAL_TRENDS';
export const GET_JOURNAL_OVERVIEW_EXCEL = 'GET_JOURNAL_OVERVIEW_EXCEL';
export const GET_A_AND_A_OA_VS_TOTAL_EXCEL = 'GET_A_AND_A_OA_VS_TOTAL_EXCEL';
export const GET_ARTICLES_OA_VS_TOTAL_EXCEL = 'GET_ARTICLES_OA_VS_TOTAL_EXCEL';
export const GET_A_AND_A_OA_VS_TOTAL_CHART_DATA =
  'GET_A_AND_A_OA_VS_TOTAL_CHART_DATA';
export const GET_ARTICLES_OA_VS_TOTAL_CHART_DATA =
  'GET_ARTICLES_OA_VS_TOTAL_CHART_DATA';
export const GET_A_AND_A_YTD_AGAINST_CHART_DATA =
  'GET_A_AND_A_YTD_AGAINST_CHART_DATA';
export const GET_A_AND_A_FY_TRENDS_CHART_DATA =
  'GET_A_AND_A_FY_TRENDS_CHART_DATA';
export const GET_A_AND_A_FY_TRENDS_SINGLE_SELECT_DATA =
  'GET_A_AND_A_FY_TRENDS_SINGLE_SELECT_DATA';
export const GET_A_AND_A_FY_TRENDS_EXCEL = 'GET_A_AND_A_FY_TRENDS_EXCEL';
export const GET_USER_SELECTED_SG = 'GET_USER_SELECTED_SG';
export const SET_HOVER_CHART_TITLE = 'SET_HOVER_CHART_TITLE';
export const GET_ARTICLE_SUBJECT_GROUPS = 'GET_ARTICLE_SUBJECT_GROUPS';

export const SHOW_TABLE = 'SHOW_TABLE';
const EXCEL_FILE_EXTENSION_NAME = '.xlsx';
export function selectDate(newDate) {
  return async dispatch => {
    dispatch({ type: SELECT_DATE, data: newDate });
  };
}
export const selectedReportView = selectedReportView => ({
  type: SHOW_TABLE,
  data: selectedReportView,
});


export function GetSiPipelineCATGroupChartData(selectedDate) {
  const date = formatDate(selectedDate);

  return async dispatch => {
    // get data
    dispatch({
      type: GET_SI_PIPELINE_CAT_GROUP_CHARTDATA,
    });
    try {
      const res = await axios_instance.get(`/reports/pipeline/cat`, {
        params: {
          selectedDate: date,
          // selectedDate1: date
        },
        headers: {
          authorization: localStorage.getItem('authorization'),
        },
      });
      if (res.data.code === 200) {
        dispatch({
          type: GET_SI_PIPELINE_CAT_GROUP_CHARTDATA_SUCCESS,
          data: res.data.data,
        });
      } else {
        dispatch({
          type: GET_SI_PIPELINE_CAT_GROUP_CHARTDATA_FAIL,
          data: res.data.message,
        });
        dispatch(
          setSnackbarMessageAndOpen(
            'common.failToLoad',
            {
              value: ' due to ' + res.data.message,
            },
            SEVERITIES.error
          )
        );
      }
    } catch (err) {
      dispatch({
        type: GET_SI_PIPELINE_CAT_GROUP_CHARTDATA_FAIL,
        data: err.message,
      });
      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToLoad',
          {
            value: ' due to ' + err.message,
          },
          SEVERITIES.error
        )
      );
    }
  };
}


/**
 * format report table API data (temp solution)
 * better to make new API for formatted data in the future
 *
 * for example,
 * numAcquired => acquired
 * */

export const formatTableAPIData = (records, catagoryAttributeName) => {
  let newRecords = records
    .filter(record => {
      // remove record of which the key is either 'total' or ''
      return (
        // catagoryAttributeName e.g. "businessDivision"
        record[catagoryAttributeName] !== null &&
        record[catagoryAttributeName].toLowerCase() !== 'total' &&
        record[catagoryAttributeName].trim() !== ''
      );
    })
    .map(record => formatObject(record));
  return newRecords;
};

export const formatObject = obj => {
  const keys = Object.keys(obj);
  let newObj = {};

  // format API data to update to the label in chart
  keys.forEach(key => {
    let newKey = key;

    newObj[newKey] = obj[key];
  });

  return newObj;
};

export function GetSiPipelineCEChartData(
  url,
  title,
  selectedDate,
  catagoryAttributeName
) {
  const date = formatDate(selectedDate);

  return async dispatch => {
    try {
      const res = await axios_instance.get(url, {
        params: {
          selectedDate: date,
        },
        headers: {
          authorization: getAuth(),
        },
      });

      if (res.data.code === 200) {
        const formattedData = formatTableAPIData(
          res.data.data,
          catagoryAttributeName
        );
        dispatch({
          type: GET_SI_PIPELINE_CE_CHARTDATA,
          data: { [title]: formattedData },
        });
      } else {
        dispatch({
          type: GET_SI_PIPELINE_CE_CHARTDATA,
          // data: []
          data: { [title]: null },
        });
      }
    } catch (err) {
      dispatch({
        type: GET_SI_PIPELINE_CE_CHARTDATA,
        // data: []
        data: { [title]: null },
      });
    }
  };
}

// new
export function getChartDataWithStatus(
  url,
  title,
  selectedDate,
  catagoryAttributeName
) {
  const date = formatDate(selectedDate);

  return async dispatch => {
    // console.log('===REQUEST_CHART_DATA===');
    dispatch({
      type: REQUEST_CHART_DATA,
      data: title,
    });
    try {
      const res = await axios_instance.get(url, {
        params: {
          selectedDate: date,
          // _selectedDate: date, // test error
        },
        headers: {
          authorization: getAuth(),
        },
      });
      // console.log('===status===', res);
      if (res.data.code === 200) {
        const formattedData = formatTableAPIData(
          res.data.data,
          catagoryAttributeName
        );
        // console.log('===REQUEST_CHART_DATA_SUCCESS===');
        dispatch({
          type: REQUEST_CHART_DATA_SUCCESS,
          data: { title, value: formattedData },
        });
      } else {
        // console.log('===REQUEST_CHART_DATA_FAIL===');
        throw new Error(res.data.message);
      }
    } catch (err) {
      // console.error('==getChartDataWithStatus==', err);

      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToLoad',
          {
            value: ' due to ' + err.message,
          },
          SEVERITIES.error
        )
      );

      dispatch({
        type: REQUEST_CHART_DATA_FAIL,
        data: { title, error: err.message },
      });
    }
  };
}

export function getAAChartDataWithStatus(
  url,
  title,
  selectedDate,
  catagoryAttributeName
) {
  const date = formatDate(selectedDate);

  return async dispatch => {
    // console.log('===REQUEST_CHART_DATA===');
    dispatch({
      type: REQUEST_CHART_DATA,
      data: title,
    });

    try {
      // const res = await axios_instance.get(url + '_' + date, {  // test Error
      const res = await axios_instance.get(url + date, {
        headers: {
          authorization: getAuth(),
        },
      });

      // console.log('===status===', res);

      if (res.data.code === 200) {
        const formattedData = formatTableAPIData(
          res.data.data,
          catagoryAttributeName
        );
        // console.log('===REQUEST_CHART_DATA_SUCCESS===');
        dispatch({
          type: REQUEST_CHART_DATA_SUCCESS,
          data: { title, value: formattedData },
        });
      } else {
        // console.log('===REQUEST_CHART_DATA_FAIL===');
        throw new Error(res.data.message);
      }
    } catch (err) {
      // console.error('==getChartDataWithStatus==', err);

      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToLoad',
          {
            value: ' due to ' + err.message,
          },
          SEVERITIES.error
        )
      );

      dispatch({
        type: REQUEST_CHART_DATA_FAIL,
        data: { title, error: err.message },
      });
    }
  };
}

/**
 * rename GetSiPipelineCEChartData
 * @param {*} selectedDate
 * @param {*} url
 * @param {*} filename
 * @returns
 */
export const getChartData = GetSiPipelineCEChartData;


export function GetAAAnualTrendsChartData(
  url = `/reports/acquired-active/diagram/annual-trends/sg/`,
  title,
  selectedDate,
  type = 'acquisition'
) {
  const date = formatDate(selectedDate);

  return async dispatch => {
    try {
      const res = await axios_instance.get(`${url}${date}`, {
        params: {
          type: type,
        },
        headers: {
          authorization: localStorage.getItem('authorization'),
        },
      });

      if (res.data.code === 200) {
        dispatch({
          type: GET_SI_PIPELINE_CE_CHARTDATA,
          data: { [title]: res.data.data },
        });
      } else {
        dispatch({
          type: GET_SI_PIPELINE_CE_CHARTDATA,
          data: { [title]: null },
        });
      }
    } catch (err) {
      dispatch({
        type: GET_SI_PIPELINE_CE_CHARTDATA,
        data: { [title]: null },
      });
    }
  };
}

// new
export function GetAAAnualTrendsChartDataWithStatus(
  url = `/reports/acquired-active/diagram/annual-trends/sg/`,
  title,
  selectedDate,
  type = 'acquisition'
) {
  const date = formatDate(selectedDate);

  return async dispatch => {
    dispatch({
      type: REQUEST_CHART_DATA,
      data: title,
    });

    try {
      const res = await axios_instance.get(`${url}${date}`, {
        params: {
          type: type,
        },
        headers: {
          authorization: localStorage.getItem('authorization'),
        },
      });

      if (res.data.code === 200) {
        // console.log('===REQUEST_CHART_DATA_SUCCESS===');
        const formattedData = res.data.data;
        dispatch({
          type: REQUEST_CHART_DATA_SUCCESS,
          data: { title, value: formattedData },
        });
      } else {
        throw new Error(res.data.message);
      }
    } catch (err) {
      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToLoad',
          {
            value: ' due to ' + err.message,
          },
          SEVERITIES.error
        )
      );

      dispatch({
        type: REQUEST_CHART_DATA_FAIL,
        data: { title, error: err.message },
      });
    }
  };
}

// >>>>>>> 9d0c2a6a5322820bf49f39ce1f865eb5bb9397cc:src/actions/ReportDiagramAction.js
export function GetReportExcelSimple(downloadUrl, filename, params) {
  // console.log('===downloadUrl===', downloadUrl);
  // console.log('===filename===', filename);
  // console.log('===params===', params);

  return async dispatch => {
    try {
      const res = await axios_instance.get(downloadUrl, {
        responseType: 'blob',
        params,
        headers: {
          authorization: getAuth(),
        },
      });
      downloadFile(res.data, filename);
    } catch (err) {
      console.error('===err===', err);
    }
  };
}

export function GetReportExcel(selectedDate, url, filename, params) {
  // if (!url) return
  var selectDateYear = selectedDate.split('-')[0];
  var selectDateMonth = selectedDate.split('-')[1];
  var date = Number(selectDateYear) * 100 + Number(selectDateMonth);

  return async dispatch => {
    try {
      const res = await axios_instance.get(url, {
        responseType: 'blob',
        params: {
          ...params,
          selectedDate: date,
        },
        headers: {
          authorization: getAuth(),
        },
      });
      downloadFile(res.data, filename);
    } catch (err) {
      console.error('===err===', err);
      /**
       * todo
       *
       * open alert?
       */
    }
  };
}

export function getReportExcelParam(selectedDate, url, filename, params) {
  const date = formatDate(selectedDate);

  // console.group('in getReportExcelParam');
  // console.log('url', url);
  // console.log('date', date);
  // console.log('params', params);
  // console.groupEnd();

  return async dispatch => {
    try {
      const res = await axios_instance_fileDownload.post(
        // * axios_instance_fileDownload
        url,
        {
          ...params,
          selectedDate: date,
        },
        {
          responseType: 'blob',
          headers: {
            authorization: getAuth(),
          },
        }
      );
      downloadFile(res.data, filename + EXCEL_FILE_EXTENSION_NAME);
    } catch (err) {
      // improvement on error?
      // show error message.
      console.error('===err===', err);
    }
  };

  // future improvement?
  // return async dispatch => {
  //   try {
  //     let res = await axios_instance_fileDownload.post(
  //       '/export/getExcel',
  //       projectCodeList,
  //       {
  //         responseType: 'blob',
  //         // params: {
  //         //   siCodes: arrayToString(projectCodeList),
  //         // },
  //         headers: {
  //           authorization: getAuth(),
  //         },
  //       }
  //     );
  //     downloadFile(res.data, filename);
  //     dispatch({ type: EXPORT_SI_STATUS, data: true });
  //   } catch (err) {
  //     dispatch({ type: EXPORT_SI_STATUS, data: false });
  //   }
  // };
}

export function GetAAndAYTDAgainstChartData(
  url,
  title,
  selectedDate,
  catagoryAttributeName
) {
  const date = formatDate(selectedDate);
  return async dispatch => {
    try {
      const res = await axios_instance.get(url + date, {
        headers: {
          authorization: getAuth(),
        },
      });
      if (res.data.code === 200) {
        const formattedData = formatTableAPIData(
          res.data.data,
          catagoryAttributeName
        );

        dispatch({
          type: GET_A_AND_A_YTD_AGAINST_CHART_DATA,
          data: { [title]: formattedData },
        });
      } else {
        dispatch({
          type: GET_A_AND_A_YTD_AGAINST_CHART_DATA,
          data: { [title]: null },
        });
      }
    } catch (err) {
      dispatch({
        type: GET_A_AND_A_YTD_AGAINST_CHART_DATA,
        data: { [title]: null },
      });
    }
  };
}
/**
 * remove this function after changed all the report export function in A&A table
 */
export function GetAAndAOverviewExcel(downloadUrl, selectedDate, filename) {
  const date = formatDate(selectedDate);
  return async dispatch => {
    try {
      const res = await axios_instance.get(downloadUrl, {
        responseType: 'blob',
        params: {
          selectedDate: date,
        },
        headers: {
          authorization: getAuth(),
        },
      });
      downloadFile(res.data, filename + EXCEL_FILE_EXTENSION_NAME);
      dispatch({
        type: GET_A_AND_A_OVERVIEW_EXCEL,
        data: true,
      });
    } catch (err) {
      dispatch({
        type: GET_A_AND_A_OVERVIEW_EXCEL,
        data: false,
      });
    }
  };
}

export function getActAcqOverviewExcelByPost(
  downloadUrl,
  requestBody,
  filename
) {
  requestBody = {
    ...requestBody,
    selectedDate: formatDate(requestBody.selectedDate), //format selected Date.
  };
  return async dispatch => {
    try {
      const res = await axios_instance_fileDownload.post(
        // * axios_instance_fileDownload
        downloadUrl,
        requestBody,
        {
          responseType: 'blob',
          headers: {
            authorization: localStorage.getItem('authorization'),
          },
        }
      );
      downloadFile(res.data, filename + EXCEL_FILE_EXTENSION_NAME);
      dispatch({
        type: GET_A_AND_A_OVERVIEW_EXCEL,
        data: true,
      });
    } catch (err) {
      dispatch({
        type: GET_A_AND_A_OVERVIEW_EXCEL,
        data: false,
      });
    }
  };
}

export function getActAcqAnnualTrendsExcelByPost(downloadUrl, requestBody, filename) {
  requestBody = {
    ...requestBody,
    selectedDate:formatDate(requestBody.selectedDate), //format selected Date.
  };
  return async dispatch => {
    try {
      const res = await axios_instance_fileDownload.post(
          // * axios_instance_fileDownload
          downloadUrl,
          requestBody,
          {
            responseType: 'blob',
            headers: {
              authorization: localStorage.getItem('authorization'),
            },
          }
      );
      downloadFile(res.data, filename+EXCEL_FILE_EXTENSION_NAME);
      dispatch({
        type: GET_A_AND_A_ANNUAL_TRENDS,
        data: true,
      });
    } catch (err) {
      dispatch({
        type: GET_A_AND_A_ANNUAL_TRENDS,
        data: false,
      });
    }
  };
}

export function getArticleOaVsTotalExcelByPost(downloadUrl, requestBody, filename) {
  requestBody = {
    ...requestBody,
    selectedDate: formatDate(requestBody.selectedDate), //format selected Date.
  };
  return async dispatch => {
    try {
      const res = await axios_instance_fileDownload.post(
          // * axios_instance_fileDownload
          downloadUrl,
          requestBody,
          {
            responseType: 'blob',
            headers: {
              authorization: localStorage.getItem('authorization'),
            },
          }
      );
      downloadFile(res.data, filename + EXCEL_FILE_EXTENSION_NAME);
      dispatch({
        type: GET_ARTICLES_OA_VS_TOTAL_EXCEL,
        data: true,
      });
    } catch (err) {
      dispatch({
        type: GET_ARTICLES_OA_VS_TOTAL_EXCEL,
        data: false,
      });
    }
  };
}

export function getArticleOverviewExcelPost(downloadUrl, requestBody, filename) {
  requestBody = {
    ...requestBody,
    selectedDate: formatDate(requestBody.selectedDate), //format selected Date.
  };
  return async dispatch => {
    try {
      const res = await axios_instance_fileDownload.post(
          // * axios_instance_fileDownload
          downloadUrl,
          requestBody,
          {
            responseType: 'blob',
            headers: {
              authorization: localStorage.getItem('authorization'),
            },
          }
      );
      downloadFile(res.data, filename + EXCEL_FILE_EXTENSION_NAME);
      dispatch({
        type: GET_ARTICLE_OVERVIEW_EXCEL,
        data: true,
      });
    } catch (err) {
      dispatch({
        type: GET_ARTICLE_OVERVIEW_EXCEL,
        data: false,
      });
    }
  };
}

export function getJournalOverviewExcelPost(downloadUrl, requestBody, filename) {
  requestBody = {
    ...requestBody,
    selectedDate: formatDate(requestBody.selectedDate), //format selected Date.
  };
  return async dispatch => {
    try {
      const res = await axios_instance_fileDownload.post(
          // * axios_instance_fileDownload
          downloadUrl,
          requestBody,
          {
            responseType: 'blob',
            headers: {
              authorization: localStorage.getItem('authorization'),
            },
          }
      );
      downloadFile(res.data, filename + EXCEL_FILE_EXTENSION_NAME);
      dispatch({
        type: GET_JOURNAL_OVERVIEW_EXCEL,
        data: true,
      });
    } catch (err) {
      dispatch({
        type: GET_JOURNAL_OVERVIEW_EXCEL,
        data: false,
      });
    }
  };
}

export function GetAAndAOAvsTotalExcel(
  downloadUrl,
  header,
  selectedDate,
  filename
) {
  const date = formatDate(selectedDate);
  const reportType = header == 'Subject Group' ? 'sg' : 'bd';
  return async dispatch => {
    try {
      const res = await axios_instance.get(downloadUrl, {
        responseType: 'blob',
        params: {
          reportType: reportType,
          selectedDate: date,
        },
        headers: {
          authorization: getAuth(),
        },
      });
      downloadFile(res.data, filename);
      dispatch({
        type: GET_A_AND_A_OA_VS_TOTAL_EXCEL,
        data: true,
      });
    } catch (err) {
      dispatch({
        type: GET_A_AND_A_OA_VS_TOTAL_EXCEL,
        data: false,
      });
    }
  };
}

export function GetAAndAOAvsTotalChartData(
  url,
  title,
  selectedDate,
  catagoryAttributeName,
  valueAttributeNames
) {
  const date = formatDate(selectedDate);
  return async dispatch => {
    try {
      const res = await axios_instance.get(url + date, {
        headers: {
          authorization: getAuth(),
        },
      });
      if (res.data.code === 200) {
        const formattedData = formatTableAPIData(
          res.data.data,
          catagoryAttributeName
        ).map(item => {
          if (
            item[valueAttributeNames[0]] != null &&
            item[valueAttributeNames[0]] != '-'
          ) {
          }
          item[valueAttributeNames[0]] = parseInt(item[valueAttributeNames[0]]);
          if (
            item[valueAttributeNames[1]] != null &&
            item[valueAttributeNames[1]] != '-'
          )
            item[valueAttributeNames[1]] = parseInt(
              item[valueAttributeNames[1]]
            );
          item[valueAttributeNames[1]] =
            item[valueAttributeNames[1]] - item[valueAttributeNames[0]];
          return item;
        });

        dispatch({
          type: GET_A_AND_A_OA_VS_TOTAL_CHART_DATA,
          data: { [title]: formattedData },
        });
      } else {
        dispatch({
          type: GET_A_AND_A_OA_VS_TOTAL_CHART_DATA,
          data: { [title]: null },
        });
      }
    } catch (err) {
      dispatch({
        type: GET_A_AND_A_OA_VS_TOTAL_CHART_DATA,
        data: { [title]: null },
      });
    }
  };
}

export function GetAAndAOAvsTotalChartDataWithStatus(
  url,
  title,
  selectedDate,
  catagoryAttributeName,
  valueAttributeNames
) {
  const date = formatDate(selectedDate);
  return async dispatch => {
    dispatch({
      type: REQUEST_CHART_DATA,
      data: title,
    });

    try {
      const res = await axios_instance.get(url + date, {
        headers: {
          authorization: getAuth(),
        },
      });

      if (res.data.code === 200) {
        const formattedData = formatTableAPIData(
          res.data.data,
          catagoryAttributeName
        ).map(item => {
          if (
            item[valueAttributeNames[0]] != null &&
            item[valueAttributeNames[0]] != '-'
          ) {
          }
          item[valueAttributeNames[0]] = parseInt(item[valueAttributeNames[0]]);
          if (
            item[valueAttributeNames[1]] != null &&
            item[valueAttributeNames[1]] != '-'
          )
            item[valueAttributeNames[1]] = parseInt(
              item[valueAttributeNames[1]]
            );
          item[valueAttributeNames[1]] =
            item[valueAttributeNames[1]] - item[valueAttributeNames[0]];
          return item;
        });

        // dispatch({
        //   type: GET_A_AND_A_OA_VS_TOTAL_CHART_DATA,
        //   data: { [title]: formattedData },
        // });
        dispatch({
          type: REQUEST_CHART_DATA_SUCCESS,
          data: { title, value: formattedData },
        });
      } else {
        // dispatch({
        //   type: GET_A_AND_A_OA_VS_TOTAL_CHART_DATA,
        //   data: { [title]: null },
        // });
        throw new Error(res.data.message);
      }
    } catch (err) {
      // dispatch({
      //   type: GET_A_AND_A_OA_VS_TOTAL_CHART_DATA,
      //   data: { [title]: null },
      // });
      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToLoad',
          {
            value: ' due to ' + err.message,
          },
          SEVERITIES.error
        )
      );

      dispatch({
        type: REQUEST_CHART_DATA_FAIL,
        data: { title, error: err.message },
      });
    }
  };
}

export function GetAAndAFYTrendsChartData(
  url,
  title,
  selectedFY,
  catagoryAttributeName,
  selectedItem
) {
  return async dispatch => {
    try {
      const res = await axios_instance.get(url + selectedFY, {
        params: {
          [catagoryAttributeName]: selectedItem,
        },
        headers: {
          authorization: getAuth(),
        },
      });

      if (res.data.code === 200) {
        const formattedData = res.data.data;
        dispatch({
          type: GET_A_AND_A_FY_TRENDS_CHART_DATA,
          data: { [title]: formattedData },
        });
      } else {
        dispatch({
          type: GET_A_AND_A_FY_TRENDS_CHART_DATA,
          data: { [title]: null },
        });
      }
    } catch (err) {
      dispatch({
        type: GET_A_AND_A_FY_TRENDS_CHART_DATA,
        data: { [title]: null },
      });
    }
  };
}

// new
export function GetAAndAFYTrendsChartDataWithStatus(
  url,
  title,
  selectedFY,
  catagoryAttributeName,
  selectedItem
) {
  return async dispatch => {
    console.log('!==selectedFY==', selectedFY);

    dispatch({
      type: REQUEST_CHART_DATA,
      data: title,
    });

    try {
      const res = await axios_instance.get(url + selectedFY, {
        params: {
          [catagoryAttributeName]: selectedItem,
        },
        headers: {
          authorization: getAuth(),
        },
      });

      if (res.data.code === 200) {
        const formattedData = res.data.data;
        // dispatch({
        //   type: GET_A_AND_A_FY_TRENDS_CHART_DATA,
        //   data: { [title]: formattedData },
        // });
        dispatch({
          type: REQUEST_CHART_DATA_SUCCESS,
          data: { title, value: formattedData },
        });
      } else {
        // dispatch({
        //   type: GET_A_AND_A_FY_TRENDS_CHART_DATA,
        //   data: { [title]: null },
        // });
        throw new Error(res.data.message);
      }
    } catch (err) {
      // dispatch({
      //   type: GET_A_AND_A_FY_TRENDS_CHART_DATA,
      //   data: { [title]: null },
      // });

      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToLoad',
          {
            value: ' due to ' + err.message,
          },
          SEVERITIES.error
        )
      );

      dispatch({
        type: REQUEST_CHART_DATA_FAIL,
        data: { title, error: err.message },
      });
    }
  };
}

export function GetAAndAFYTrendsSingleSelectData(url, title, selectedFY) {
  // console.log(
  //   '===GetAAndAFYTrendsSingleSelectData===',
  //   GetAAndAFYTrendsSingleSelectData
  // );
  // console.log('===url===', url);
  // console.log('===title===', title);
  // console.log('===selectedFY===', selectedFY);
  // tset
  // selectedFY = '202302';

  return async dispatch => {
    try {
      const res = await axios_instance.get(url + selectedFY, {
        headers: {
          authorization: getAuth(),
        },
      });
      if (res.data.code === 200) {
        const formattedData = res.data.data;
        dispatch({
          type: GET_A_AND_A_FY_TRENDS_SINGLE_SELECT_DATA,
          data: { [title]: formattedData },
        });
      } else {
        dispatch({
          type: GET_A_AND_A_FY_TRENDS_SINGLE_SELECT_DATA,
          data: { [title]: null },
        });
      }
    } catch (err) {
      dispatch({
        type: GET_A_AND_A_FY_TRENDS_SINGLE_SELECT_DATA,
        data: { [title]: null },
      });
    }
  };
}

// new
export function GetAAndAFYTrendsSingleSelectDataWithStatus(
  url,
  title,
  selectedFY
) {
  return async dispatch => {
    dispatch({
      type: REQUEST_CHART_DATA,
      data: title,
    });

    try {
      const res = await axios_instance.get(url + selectedFY, {
        headers: {
          authorization: getAuth(),
        },
      });

      if (res.data.code === 200) {
        const formattedData = res.data.data;

        // dispatch({
        //   type: GET_A_AND_A_FY_TRENDS_SINGLE_SELECT_DATA,
        //   data: { [title]: formattedData },
        // });
        dispatch({
          type: REQUEST_CHART_DATA_SUCCESS,
          data: { title, value: formattedData },
        });
      } else {
        // dispatch({
        //   type: GET_A_AND_A_FY_TRENDS_SINGLE_SELECT_DATA,
        //   data: { [title]: null },
        // });
        // console.log('===REQUEST_CHART_DATA_FAIL===');
        throw new Error(res.data.message);
      }
    } catch (err) {
      // dispatch({
      //   type: GET_A_AND_A_FY_TRENDS_SINGLE_SELECT_DATA,
      //   data: { [title]: null },
      // });

      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToLoad',
          {
            value: ' due to ' + err.message,
          },
          SEVERITIES.error
        )
      );

      dispatch({
        type: REQUEST_CHART_DATA_FAIL,
        data: { title, error: err.message },
      });
    }
  };
}

export function GetBusinessDivisionsForArticle(url, title) {
  return async dispatch => {
    try {
      const res = await axios_instance.get(url, {
        headers: {
          authorization: getAuth(),
        },
      });
      if (res.data.code === 200) {
        const formattedData = res.data.data;
        dispatch({
          type: GET_A_AND_A_FY_TRENDS_SINGLE_SELECT_DATA,
          data: { [title]: formattedData },
        });
      } else {
        dispatch({
          type: GET_A_AND_A_FY_TRENDS_SINGLE_SELECT_DATA,
          data: { [title]: null },
        });
      }
    } catch (err) {
      dispatch({
        type: GET_A_AND_A_FY_TRENDS_SINGLE_SELECT_DATA,
        data: { [title]: null },
      });
    }
  };
}

// new
export function GetBusinessDivisionsForArticleWithStatus(url, title) {
  return async dispatch => {
    dispatch({
      type: REQUEST_CHART_DATA,
      data: title,
    });
    try {
      const res = await axios_instance.get(url, {
        headers: {
          authorization: getAuth(),
        },
      });

      if (res.data.code === 200) {
        const formattedData = res.data.data;

        dispatch({
          type: REQUEST_CHART_DATA_SUCCESS,
          data: { title, value: formattedData },
        });
      } else {
        throw new Error(res.data.message);
      }
    } catch (err) {
      const message = err.message ? err.message : 'an unknown error';

      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToLoad',
          {
            value: ' due to ' + message,
          },
          SEVERITIES.error
        )
      );

      dispatch({
        type: REQUEST_CHART_DATA_FAIL,
        data: { title, error: message },
      });
    }
  };
}

/** Get article subject groups */
export function GetSubjectGroupsForArticle(url, title, selectedFY) {
  // console.log('===GetSubjectGroupsForArticle===', GetSubjectGroupsForArticle);
  // console.log('===url===', url);
  // console.log('===title===', title);
  // console.log('===selectedFY===', selectedFY);
  // tset
  // selectedFY = '202302';

  return async dispatch => {
    try {
      const res = await axios_instance.get(url + selectedFY, {
        headers: {
          authorization: getAuth(),
        },
      });
      if (res.data.code === 200) {
        const formattedData = res.data.data;
        dispatch({
          type: GET_ARTICLE_SUBJECT_GROUPS,
          data: { [title]: formattedData },
        });
      } else {
        dispatch({
          type: GET_ARTICLE_SUBJECT_GROUPS,
          data: { [title]: null },
        });
      }
    } catch (err) {
      dispatch({
        type: GET_ARTICLE_SUBJECT_GROUPS,
        data: { [title]: null },
      });
    }
  };
}

export function GetAAndAFYTrendsExcel(
  downloadUrl,
  header,
  selectedFY,
  filename
) {
  const reportType = header == 'Subject Group' ? 'sg' : 'bd';
  return async dispatch => {
    try {
      const res = await axios_instance.get(downloadUrl, {
        responseType: 'blob',
        params: {
          type: reportType,
          fyYear: selectedFY,
        },
        headers: {
          authorization: getAuth(),
        },
      });
      downloadFile(res.data, filename);
      dispatch({
        type: GET_A_AND_A_FY_TRENDS_EXCEL,
        data: true,
      });
    } catch (err) {
      dispatch({
        type: GET_A_AND_A_FY_TRENDS_EXCEL,
        data: false,
      });
    }
  };
}

export function GetArticleChartsExcel(
  downloadUrl,
  header,
  selectedFY,
  filename
) {
  return async dispatch => {
    try {
      const res = await axios_instance.get(downloadUrl, {
        responseType: 'blob',
        params: {
          reportType: header,
          selectedDate: selectedFY,
        },
        headers: {
          authorization: getAuth(),
        },
      });
      downloadFile(res.data, filename);
      dispatch({
        type: GET_A_AND_A_FY_TRENDS_EXCEL,
        data: true,
      });
    } catch (err) {
      dispatch({
        type: GET_A_AND_A_FY_TRENDS_EXCEL,
        data: false,
      });
    }
  };
}

export function saveUserSelectedItem(title, selected) {
  return dispatch => {
    dispatch({
      type: GET_USER_SELECTED_SG,
      data: { [title]: selected },
    });
  };
}

export function SetHoverChartTitle(title, hoverChartTitle) {
  return dispatch => {
    dispatch({
      type: SET_HOVER_CHART_TITLE,
      data: { [title]: hoverChartTitle },
    });
  };
}

export function GetArticleOAvsTotalExcel(
  downloadUrl,
  header,
  selectedDate,
  filename
) {
  const date = formatDate(selectedDate);
  const reportType = header == 'Subject Group' ? 'sg' : 'bd';
  return async dispatch => {
    try {
      const res = await axios_instance.get(downloadUrl, {
        responseType: 'blob',
        params: {
          reportType: reportType,
          selectedDate: date,
        },
        headers: {
          authorization: getAuth(),
        },
      });
      downloadFile(res.data, filename);
      dispatch({
        type: GET_ARTICLES_OA_VS_TOTAL_EXCEL,
        data: true,
      });
    } catch (err) {
      dispatch({
        type: GET_ARTICLES_OA_VS_TOTAL_EXCEL,
        data: false,
      });
    }
  };
}

export function GetArticleOAvsTotalChartData(url, title, selectedDate) {
  const date = formatDate(selectedDate);
  return async dispatch => {
    try {
      const res = await axios_instance.get(url + date, {
        headers: {
          authorization: getAuth(),
        },
      });

      if (res.data.code === 200) {
        const formattedData = res.data.data;
        dispatch({
          type: GET_ARTICLES_OA_VS_TOTAL_CHART_DATA,
          data: { [title]: formattedData },
        });
      } else {
        dispatch({
          type: GET_ARTICLES_OA_VS_TOTAL_CHART_DATA,
          data: { [title]: null },
        });
      }
    } catch (err) {
      dispatch({
        type: GET_ARTICLES_OA_VS_TOTAL_CHART_DATA,
        data: { [title]: null },
      });
    }
  };
}
// new
export function GetArticleOAvsTotalChartDataWithStatus(
  url,
  title,
  selectedDate
) {
  const date = formatDate(selectedDate);
  return async dispatch => {
    dispatch({
      type: REQUEST_CHART_DATA,
      data: title,
    });
    try {
      const res = await axios_instance.get(url + date, {
        headers: {
          authorization: getAuth(),
        },
      });

      if (res.data.code === 200) {
        const formattedData = res.data.data;
        // dispatch({
        //   type: GET_ARTICLES_OA_VS_TOTAL_CHART_DATA,
        //   data: { [title]: formattedData },
        // });
        dispatch({
          type: REQUEST_CHART_DATA_SUCCESS,
          data: { title, value: formattedData },
        });
      } else {
        // dispatch({
        //   type: GET_ARTICLES_OA_VS_TOTAL_CHART_DATA,
        //   data: { [title]: null },
        // });
        throw new Error(res.data.message);
      }
    } catch (err) {
      // dispatch({
      //   type: GET_ARTICLES_OA_VS_TOTAL_CHART_DATA,
      //   data: { [title]: null },
      // });
      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToLoad',
          {
            value: ' due to ' + err.message,
          },
          SEVERITIES.error
        )
      );

      dispatch({
        type: REQUEST_CHART_DATA_FAIL,
        data: { title, error: err.message },
      });
    }
  };
}

export function GetArticalAnualTrendsChartData(
  url, // `/reports/article/diagram/annual-trends/sg/submission/202306`
  title,
  selectedDate,
  type = 'submission'
) {
  // console.log('==selectedDate==', selectedDate);
  // console.log('==type==', type);
  // console.log('==title==', title);
  // console.log('==url==', url);

  const date = formatDate(selectedDate);
  // console.log('==date==', date);

  return async dispatch => {
    try {
      const res = await axios_instance.get(`${url}/${type}/${date}`, {
        headers: {
          authorization: getAuth(),
        },
      });

      if (res.data.code === 200) {
        dispatch({
          type: GET_CHART_DATA,
          data: { [title]: res.data.data },
        });
      } else {
        dispatch({
          type: GET_CHART_DATA,
          data: { [title]: null },
        });
      }
    } catch (err) {
      dispatch({
        type: GET_CHART_DATA,
        data: { [title]: null },
      });
    }
  };
}
// new
export function GetArticalAnualTrendsChartDataWithStatus(
  url,
  title,
  selectedDate,
  type = 'submission'
) {
  // console.log('==selectedDate==', selectedDate);
  // console.log('==type==', type);
  // console.log('==title==', title);
  // console.log('==url==', url);

  const date = formatDate(selectedDate);

  return async dispatch => {
    dispatch({
      type: REQUEST_CHART_DATA,
      data: title,
    });

    try {
      const res = await axios_instance.get(`${url}/${type}/${date}`, {
        headers: {
          authorization: getAuth(),
        },
      });

      if (res.data.code === 200) {
        //   dispatch({
        //     type: GET_CHART_DATA,
        //     data: { [title]: res.data.data },
        //   });
        const formattedData = res.data.data;
        dispatch({
          type: REQUEST_CHART_DATA_SUCCESS,
          data: { title, value: formattedData },
        });
      } else {
        // dispatch({
        //   type: GET_CHART_DATA,
        //   data: { [title]: null },
        // });
        throw new Error(res.data.message);
      }
    } catch (err) {
      // dispatch({
      //   type: GET_CHART_DATA,
      //   data: { [title]: null },
      // });
      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToLoad',
          {
            value: ' due to ' + err.message,
          },
          SEVERITIES.error
        )
      );

      dispatch({
        type: REQUEST_CHART_DATA_FAIL,
        data: { title, error: err.message },
      });
    }
  };
}

// A&A => Annual Trends -- Get one Sg or Bd chart data.
export function GetArticleAnualTrendsAllInOneChartDataWithStatus(
  url,
  title,
  selectedItem
) {
  return async dispatch => {
    dispatch({
      type: REQUEST_CHART_DATA,
      data: title,
    });

    try {
      const res = await axios_instance.get(url, {
        params: {
          selectedItem,
        },
        headers: {
          authorization: localStorage.getItem('authorization'),
        },
      });

      if (res.data.code === 200) {
        const data = res.data.data;
        dispatch({
          type: REQUEST_CHART_DATA_SUCCESS,
          data: { title, value: data },
        });
      } else {
        throw new Error(res.data.message);
      }
    } catch (err) {
      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToLoad',
          {
            value: ' due to ' + err.message,
          },
          SEVERITIES.error
        )
      );

      dispatch({
        type: REQUEST_CHART_DATA_FAIL,
        data: { title, error: err.message },
      });
    }
  };
}

export function GetJournalOverviewChartData(url, title, selectedDate) {
  const date = formatDate(selectedDate);

  return async dispatch => {
    try {
      const res = await axios_instance.get(`${url}/${date}`, {
        headers: {
          authorization: getAuth(),
        },
      });

      if (res.data.code === 200) {
        dispatch({
          type: GET_CHART_DATA,
          data: { [title]: res.data.data },
        });
      } else {
        dispatch({
          type: GET_CHART_DATA,
          data: { [title]: null },
        });
      }
    } catch (err) {
      dispatch({
        type: GET_CHART_DATA,
        data: { [title]: null },
      });
    }
  };
}

// new
export const getChartDataWithStatusURL = (url, title, selectedDate) => {
  const date = formatDate(selectedDate);
  console.log('**url:', url);

  return async dispatch => {
    dispatch({
      type: REQUEST_CHART_DATA,
      data: title,
    });

    try {
      const res = await axios_instance.get(`${url}/${date}`, {
        headers: {
          authorization: getAuth(),
        },
      });

      if (res.data.code === 200) {
        const formattedData = res.data.data;
        dispatch({
          type: REQUEST_CHART_DATA_SUCCESS,
          data: { title, value: formattedData },
        });
      } else {
        throw new Error(res.data.message);
      }
    } catch (err) {
      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToLoad',
          {
            value: ' due to ' + err.message,
          },
          SEVERITIES.error
        )
      );

      dispatch({
        type: REQUEST_CHART_DATA_FAIL,
        data: { title, error: err.message },
      });
    }
  };
};

export const GetJournalOverviewChartDataWithStatus = getChartDataWithStatusURL;

import moment from "moment/moment";
import {axios_instance_fileDownload} from "@/utils/axios_instance";
import {downloadFile} from "@/actions/SIMT-SI/SpecialIssue/SIAction";
import {EXPORT_GE} from "@/actions/SIMT-SI/GuestEditor/GEAction";

import getAuth from "@/utils/getAuth";
/**
 * 
 * @param {import("./type").GuestEditorsExportRequestData} data 
 * @param {import("./type").GuestEditorsExportRequest['requestType']} requestType 
 */
export const GuestEditorsExport=(data,requestType)=>axios_instance_fileDownload.post(
  '/api/v1/si-service/guest-editors/export',
  data,
  {
      responseType: 'blob',
      headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded',
          authorization: getAuth()??'',
      },
      params: {requestType}
  }
);
/**
 * @param {ReadonlyArray<string>} selectedGEIds
 * @param {'csv'|string} fileType
 */
export function exportGE(selectedGEIds, fileType) {
    // GEList_MMDDYYYY_HHMMSS
    const filename = `GEList_${moment()
        .utc()
        .format('MMDDYYYY_HHmmss')}.${fileType}`;
    /**
     * @param {import("redux").Dispatch} dispatch
     */
    return async dispatch => {
        try {
            const res=await GuestEditorsExport({geCodeList:Array.from(selectedGEIds)},fileType==='csv'?'export-csv-by-ge-code':'export-excel-by-ge-code',
            );
            downloadFile(res.data, filename);
            dispatch({type: EXPORT_GE, data: true});
        } catch (err) {
            dispatch({type: EXPORT_GE, data: false});
        }
    };
}

/**
 * @description CT-259导出所有的GE
 * @param {string} fromDate 导出的开始时间
 * @param {string} toDate 导出的结束时间
 * @param {string} fileType 导出文件类型 csv/excel
 * @returns
 */
export function getAllGEExport(fromDate, toDate, fileType) {
    // 冒号被替换成什么交给操作系统即可
    const filename = `GEdata-${moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')}.${fileType}`;
    let url = '';
    if (fileType === 'xls') {
        url = '/export/getAllGeExcel';
    } else {
        url = '/export/getAllGeCsv';
    }
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(url, {
                responseType: 'blob',
                params: {
                    startDate: fromDate,
                    endDate: toDate,
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    authorization: localStorage.getItem('authorization'),
                },
            });
            downloadFile(res.data, filename);
            dispatch({type: EXPORT_GE, data: true});
        } catch (err) {
            dispatch({type: EXPORT_GE, data: false});
        }
    };
}
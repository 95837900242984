import { SIModal } from '@/modules/Modal';
import { handleLinkWithoutProtocol } from '@/utils/commonUtils';
import { Box, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { DialogItemBlock } from '../../SIPDetailPage/EditComponents';
import styleModule from './CustomComponent.module.scss';
/**
 *
 * @param {import("./Modal").GEDetailModalForTransformProps} props
 * @returns
 */
export const GEDetailModalForTransform = props => {
  const { geInformation, open, handleClose } = props;

  return (
    <SIModal
      data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-SI'
      open={open}
      title='GE Detail'
      boxSx={{
        '&.SIModalPro-container': {
          borderRadius: '8px',
        },
      }}
      handleClose={handleClose}
      className={styleModule.geDetailModal}
    >
      <Stack
        sx={{ flexGrow: 1 }}
        justifyContent='flex-end'
        data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-SI-EndStack'
      >
        <Box
          sx={{ flexGrow: 1, position: 'relative' }}
          data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-SI-Box'
        >
          <Box
            data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-SI-InnerBox'
            sx={{
              paddingTop: '24px',
              boxSizing: 'border-box',
              overflow: 'auto',
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
            }}
          >
            <Stack
              data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-SI-InnerStack'
              spacing={2}
              sx={{
                '& .MuiTypography-root': {
                  fontFamily: 'Open Sans',
                  fontWeight: 400,
                  fontSize: '14px',
                  color: '#262E35',
                  '& a': {
                    wordBreak: 'break-all',
                    textDecoration: 'none',
                    color: geInformation.profileWebsite ? '#154AB6' : '#262E35',
                  },
                },
                '& .DialogItemBlock-title-box': {
                  width: '160px',
                },
              }}
            >
              {/* First Name */}
              <DialogItemBlock title='First Name:'>
                <Typography data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-FirstNameTypography'>
                  {geInformation.firstName || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* Last Name */}
              <DialogItemBlock title='Last Name:'>
                <Typography data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-LastNameTypography'>
                  {geInformation.lastName || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* Job Title */}
              <DialogItemBlock title='Title:'>
                <Typography data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-TitleTypography'>
                  {geInformation.jobTitle || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* Email Address: */}
              <DialogItemBlock title='Primary Email:'>
                <Typography data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-EmailTypography'>
                  {geInformation.emailAddress || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* Department: */}
              <DialogItemBlock title='Department:'>
                <Typography data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-DepartmentTypography'>
                  {geInformation.department || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* Institution: */}
              <DialogItemBlock title='Institution:'>
                <Typography data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-InstitutionTypography'>
                  {geInformation.institution || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* City : */}
              <DialogItemBlock title='City :'>
                <Typography data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-CityTypography'>
                  {' '}
                  {geInformation.city || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* Country/Region: */}
              <DialogItemBlock title='Country/Region:'>
                <Typography data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-CountryOrRegionTypography'>
                  {' '}
                  {geInformation.country || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* ORCID ID: */}
              <DialogItemBlock title='ORCID ID:'>
                <Typography data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-ORCIDTypography'>
                  {' '}
                  {geInformation.orcidId || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* ResearchGate ID: */}
              <DialogItemBlock title='ResearchGate ID:'>
                <Typography data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-FirstNameTypography'>
                  {geInformation.researchGateId || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* Research Keywords: */}
              <DialogItemBlock title='Research Keywords:'>
                <Typography data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-ResearchKeywordsTypography'>
                  {geInformation.researchKeywords?.join(', ') || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* Profile: */}
              <DialogItemBlock title='Profile Website(s):'>
                <Typography data-selenium-id='SIPPage_TransformToSl-CustomComponent-Modal-ProfileWebsitesTypography'>
                  <a
                    href={
                      handleLinkWithoutProtocol(geInformation.profileWebsite) ||
                      undefined
                    }
                    target='_blank'
                    className={styleModule.ModalProfileWebsitesTypography}  //modified by HJX
                  >
                    {geInformation.profileWebsite || 'N/A'}
                  </a>
                </Typography>
              </DialogItemBlock>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </SIModal>
  );
};

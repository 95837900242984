"use strict";
exports.__esModule = true;
var MyLocalStorage = /** @class */ (function () {
    function MyLocalStorage() {
    }
    MyLocalStorage.setItem = function (key, value, expireAt) {
        localStorage.setItem(key, JSON.stringify({
            data: value,
            expireAt: expireAt
        }));
    };
    MyLocalStorage.getItem = function (key, defaultValue) {
        var value = localStorage.getItem(key);
        if (value) {
            try {
                var expireableData = JSON.parse(value);
                if (expireableData.expireAt && expireableData.expireAt < new Date()) {
                    localStorage.removeItem(key);
                    return defaultValue;
                }
                return expireableData.data;
            }
            catch (e) {
                return defaultValue;
            }
        }
        return defaultValue;
    };
    MyLocalStorage.removeItem = function (key) {
        localStorage.removeItem(key);
    };
    MyLocalStorage.removeAllItemStartsWith = function (keyPrefix) {
        for (var i = localStorage.length - 1; i >= 0; i--) {
            var key = localStorage.key(i);
            if (key === null || key === void 0 ? void 0 : key.startsWith(keyPrefix)) {
                localStorage.removeItem(key);
            }
        }
    };
    MyLocalStorage.addGroupItem = function (groupKey, key, value, expireAt) {
        var groupItems = MyLocalStorage.getItem(groupKey, {});
        groupItems[key] = { data: value, expireAt: expireAt };
        for (var _i = 0, _a = Object.keys(groupItems); _i < _a.length; _i++) {
            var k = _a[_i];
            var groupItem = groupItems[k];
            if (groupItem.expireAt && groupItem.expireAt < new Date()) {
                delete groupItems[k];
            }
        }
        MyLocalStorage.setItem(groupKey, groupItems);
    };
    MyLocalStorage.getGroupItem = function (groupKey, key, defaultValue) {
        var groupItems = MyLocalStorage.getItem(groupKey, {});
        var groupItem = groupItems[key];
        if (groupItem) {
            if (groupItem.expireAt && groupItem.expireAt < new Date()) {
                delete groupItems[key];
                MyLocalStorage.setItem(groupKey, groupItems);
                return defaultValue;
            }
            return groupItem.data;
        }
        return defaultValue;
    };
    MyLocalStorage.removeGroupItem = function (groupKey, key) {
        var groupItems = MyLocalStorage.getItem(groupKey, {});
        delete groupItems[key];
        MyLocalStorage.setItem(groupKey, groupItems);
    };
    return MyLocalStorage;
}());
exports["default"] = MyLocalStorage;

// @ts-check
import React, { useEffect, useState } from 'react';
import { Box, Stack, Collapse, Typography } from '@mui/material';
import { SectionTitleBlock } from '../CustomComponents/CustomBlock';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import LabelAndValue from '../../../../components/LabelAndValue';
import {
  ItemBlock,
  SIPDetailSelectBox,
} from '../../SIPDetailPage/EditComponents';
import { useParams } from 'react-router-dom';
import { MiniFont } from '@/components/FontStyle';
import { SectionContainer } from '../CustomComponents/CustomContainer';
import { ItemBlockShowText } from '../CustomComponents/CustomTypography';
import { getSIPDetailObjectChoiceJournalDropList } from "@/actions/SIMT-SIP/SIP/SipDetail";
import { getSIPDetailObjectPreferredSubjectAreaDropList } from "@/actions/SIMT-SIP/SIP/SipEditDetail";
import {axios_instance} from "@/utils/axios_instance";
import {
  GET_SIPDETAIL_OBJECT_CHOICE_JOURNAL_DROPLIST,
  GET_SIPDETAIL_OBJECT_CHOICE_JOURNAL_DROPLIST_FOR_REVISE
} from "@/actions/SIMT-SIP/SIP/SIPAction";
import {useMount} from "ahooks";

/**
 *
 * @param {import("./ObjectSection").ObjectEditSectionProps} props
 * @returns
 */
export const ObjectEditSection = props => {
  const {
    value: {
      preferredSubjectArea,
      choiceJournal1st,
      choiceJournal2nd,
      choiceJournal3rd,
    },
    setValue: setSipObject,
    showErrorMessage = false,
    setError = () => { },
  } = props;

  const selector = state => {
    return {
      sipDetailObjectChoiceJournalResult:
        state.SIP.sipDetailObjectChoiceJournalResultForRevise,
      sipDetailObjectPreferredSubjectAreaResult:
        state.SIP.sipDetailObjectPreferredSubjectAreaResult,
      sipDetailObjectEditResult: state.SIP.sipDetailObjectEditResult,
      sipDetailObjectEditMonitor: state.SIP.sipDetailObjectEditMonitor,
    };
  };
  const {
    sipDetailObjectChoiceJournalResult,
    sipDetailObjectPreferredSubjectAreaResult,
    sipDetailObjectEditResult,
    sipDetailObjectEditMonitor,
  } = useSelector(selector);

  /**
   * @typedef {import("../ProposerRevisePage").SIPProposerReviseData} SIPReviseData
   */

  const [preferredSubjectAreaError, setPreferredSubjectAreaError] =
    useState(false);

  const dispatch = useDispatch();

  useMount(() => {
    dispatch(getSIPDetailObjectChoiceJournalDropListByToken(''));
  });

  useEffect(() => {
    setPreferredSubjectAreaError(
      preferredSubjectArea === '' || preferredSubjectArea === null
    );
  }, [preferredSubjectArea]);

  useEffect(() => {
    setError(preferredSubjectAreaError);
  }, [preferredSubjectAreaError]);

  const [preferredSubjectAreaDropList, setPreferredSubjectAreaDropList] =
    useState([]);
  const [choiceJournalDropList, setChoiceJournalDropList] = useState([]);

  useEffect(() => {
    setChoiceJournalDropList(
      sipDetailObjectChoiceJournalResult
        ? sipDetailObjectChoiceJournalResult
        : []
    );
  }, [sipDetailObjectChoiceJournalResult]);

  useEffect(() => {
    setPreferredSubjectAreaDropList(
      sipDetailObjectPreferredSubjectAreaResult
        ? sipDetailObjectPreferredSubjectAreaResult
        : []
    );
  }, [sipDetailObjectPreferredSubjectAreaResult]);

  const { token } = useParams();

  /**
   * @type {import("./ObjectSection").HandleObjectChange}
   */
  const handleObjectChange = newValue => {
    setSipObject(current => ({
      ...current,
      [newValue.key]: newValue.value,
    }));
  };

  /**
   *
   * @param {string} name
   * @returns
   */
  const getSIPDetailObjectPreferredSubjectAreaDropListByToken = name => {
    return getSIPDetailObjectPreferredSubjectAreaDropList(
      name,
      `/api/v1/sip-service/special-issue-proposals/revise/${token}/field-metadata`,
      {},
      true
    );
  };

  /**
   *
   * @param {string} name
   * @returns
   */
  const getSIPDetailObjectChoiceJournalDropListByToken = name => {

    // return getSIPDetailObjectChoiceJournalDropList(
    //   name,
    //   `/api/v1/sip-service/special-issue-proposals/revise/${token}/field-metadata`,
    //   {},
    //   true,
    // );

    // @ts-ignore
    return async dispatch => {
      try {
        const res = await axios_instance.get(`/api/v1/sip-service/special-issue-proposals/revise/${token}/field-metadata`, {
          params:  {
            name,
            type: 'journalAndUrl',
            pageNum: 1,
            pageSize: 5000,
          }
        });
        if (res.data.code === 200) {
          dispatch({
            type: GET_SIPDETAIL_OBJECT_CHOICE_JOURNAL_DROPLIST_FOR_REVISE,
            data: res.data.data,
          });
        } else {
          dispatch({
            type: GET_SIPDETAIL_OBJECT_CHOICE_JOURNAL_DROPLIST_FOR_REVISE,
            data: [],
          });
        }
      } catch {
        dispatch({
          type: GET_SIPDETAIL_OBJECT_CHOICE_JOURNAL_DROPLIST_FOR_REVISE,
          data: [],
        });
      }
    };
  };

  return (
    <SectionContainer
      title='Object'
      data-selenium-id='SIP_RevisePage_Section-Object-Edit-Container'
    >
      <Stack
        data-selenium-id='SIP_RevisePage_Section-Object-Edit-Stack'
        spacing={2}
        className='ObjectSection-container'
        sx={{
          '&.ObjectSection-container .MuiOutlinedInput-root .MuiAutocomplete-input':
          {
            paddingY: 0,
          },
        }}
      >
        <ItemBlock
          title='Preferred Subject Area:'
          mandatory={true}
          sx={{ width: '513px' }}
        >
          <SIPDetailSelectBox
            id='SIPRevisePreferredSubjectArea'
            data-selenium-id='SIP_RevisePage_Section-Object-Edit-PreferredSubjectArea'
            value={preferredSubjectArea || ''}
            options={preferredSubjectAreaDropList}
            onChange={(_event, newValue) => {
              if (Array.isArray(newValue)) return;
              handleObjectChange({
                key: 'preferredSubjectArea',
                value: newValue || '',
              });
            }}
            inputValue={preferredSubjectArea ? preferredSubjectArea : ''}
            loadDropList={getSIPDetailObjectPreferredSubjectAreaDropListByToken}
            error={preferredSubjectAreaError && showErrorMessage}
          />
          {preferredSubjectAreaError && showErrorMessage && (
            <Box
              sx={{ ...MiniFont, color: '#EE5350' }}
              data-selenium-id='SIP_RevisePage_Section-Object-Edit-TipBox'
            >
              This field is required.
            </Box>
          )}
        </ItemBlock>
        <ItemBlock title='1st Choice Journal:' sx={{ width: '513px' }}>
          <SIPDetailSelectBox
            id='SIPReviseChoiceJournal1'
            data-selenium-id='SIP_RevisePage_Section-Object-Edit-ChoiceJournal1'
            value={choiceJournalDropList.find(option => option?.journalCode == choiceJournal1st)}
            options={choiceJournalDropList}
            getOptionLabel={option => option ? `${option?.journalName}(${option?.journalCode})` : ''}
            onChange={(_event, newValue) => {
              if (Array.isArray(newValue)) return;
              handleObjectChange({
                key: 'choiceJournal1st',
                value: newValue?.journalCode || '',
              });
            }}
            inputValue={choiceJournal1st || ''}
            filterOptions={(options, state) => {
              return options.filter(option => {
                return option.journalCode.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                    option.journalName.toLowerCase().includes(state.inputValue.toLowerCase());
              });
            }}
            // loadDropList={getSIPDetailObjectChoiceJournalDropListByToken}
          ></SIPDetailSelectBox>
        </ItemBlock>
        <ItemBlock title='2nd Choice Journal:' sx={{ width: '513px' }}>
          <SIPDetailSelectBox
            id='SIPReviseChoiceJournal2'
            data-selenium-id='SIP_RevisePage_Section-Object-Edit-ChoiceJournal2'
            value={choiceJournalDropList.find(option => option?.journalCode == choiceJournal2nd)}
            getOptionLabel={option => option ? `${option?.journalName}(${option?.journalCode})` : ''}
            options={choiceJournalDropList}
            onChange={(_event, newValue) => {
              if (Array.isArray(newValue)) return;
              handleObjectChange({
                key: 'choiceJournal2nd',
                value: newValue?.journalCode || '',
              });
            }}
            inputValue={choiceJournal2nd || ''}
            filterOptions={(options, state) => {
              return options.filter(option => {
                return option.journalCode.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                    option.journalName.toLowerCase().includes(state.inputValue.toLowerCase());
              });
            }}
            // loadDropList={getSIPDetailObjectChoiceJournalDropListByToken}
          ></SIPDetailSelectBox>
        </ItemBlock>
        <ItemBlock title='3rd Choice Journal:' sx={{ width: '513px' }}>
          <SIPDetailSelectBox
            id='SIPReviseChoiceJournal3'
            data-selenium-id='SIP_RevisePage_Section-Object-Edit-ChoiceJournal3'
            value={choiceJournalDropList.find(option => option?.journalCode == choiceJournal3rd)}
            getOptionLabel={option => option ? `${option?.journalName}(${option?.journalCode})` : ''}
            options={choiceJournalDropList}
            onChange={(_event, newValue) => {
              if (Array.isArray(newValue)) return;
              handleObjectChange({
                key: 'choiceJournal3rd',
                value: newValue?.journalCode || '',
              });
            }}
            inputValue={choiceJournal3rd || ''}
            filterOptions={(options, state) => {
              return options.filter(option => {
                return option.journalCode.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                  option.journalName.toLowerCase().includes(state.inputValue.toLowerCase());
              });
            }}
            // loadDropList={getSIPDetailObjectChoiceJournalDropListByToken}
          ></SIPDetailSelectBox>
        </ItemBlock>
      </Stack>
    </SectionContainer>
  );
};

/**
 *
 * @param {import("./ObjectSection").ObjectShowSectionProps} props
 * @returns
 */
export const ObjectShowSection = props => {
  const { value } = props;

  const getObjectSectionSurplusItems = () => {
    switch (value.type) {
      case 'ProposerRevise':
        return (
          <>
            <ItemBlock title={`1st Choice Journal:`}>
              <ItemBlockShowText data-selenium-id='SIP_RevisePage_Section-Object-Show-ChoiceJournalText1'>
                {value?.sipObject?.solicitedSipObjectInfo.choiceJournal1st}
              </ItemBlockShowText>
            </ItemBlock>
            <ItemBlock title={`2nd Choice Journal:`}>
              <ItemBlockShowText data-selenium-id='SIP_RevisePage_Section-Object-Show-ChoiceJournalText2'>
                {value?.sipObject?.solicitedSipObjectInfo.choiceJournal2nd}
              </ItemBlockShowText>
            </ItemBlock>
            <ItemBlock title={`3rd Choice Journal:`}>
              <ItemBlockShowText data-selenium-id='SIP_RevisePage_Section-Object-Show-ChoiceJournalText3'>
                {value?.sipObject?.solicitedSipObjectInfo.choiceJournal3rd}
              </ItemBlockShowText>
            </ItemBlock>
          </>
        );
      case 'JPMReview':
        return value?.sipObject?.recommendedJournals.map(
          (recommendedJournal, index) => (
            <ItemBlock title={`Recommended Journal ${index + 1}:`} key={index}>
              <ItemBlockShowText data-selenium-id='SIP_RevisePage_Section-Object-Show-JPMItemBlockText'>
                {recommendedJournal || 'N/A'}
              </ItemBlockShowText>
            </ItemBlock>
          )
        );
      case 'EditorReview':
        return <></>;
      case 'ProposalEditorReview':
        return (
          <ItemBlock title={`Accepted Journal Name:`}>
            <ItemBlockShowText data-selenium-id='SIP_RevisePage_Section-Object-Show-ProposalEditorItemText'>
              {value?.sipObject?.acceptedJournalName}
            </ItemBlockShowText>
          </ItemBlock>
        );
    }

    return <></>;
  };

  return (
    <SectionContainer
      title='Object'
      data-selenium-id='SIP_RevisePage_Section-Object-Show-Container'
    >
      <Stack
        spacing='8px'
        data-selenium-id='SIP_RevisePage_Section-Object-Show-Stack'
      >
        <ItemBlock title='Preferred Subject Area:'>
          <ItemBlockShowText data-selenium-id='SIP_RevisePage_Section-Object-Show-PreferredSubjectAreaItemText'>
            {value?.sipObject?.preferredSubjectArea}
          </ItemBlockShowText>
        </ItemBlock>
        {getObjectSectionSurplusItems()}
      </Stack>
    </SectionContainer>
  );
};

import {axios_instance} from "@/utils/axios_instance";
import {
    GET_JOURNAL_BUSINESS_DIVISION,
    GET_JOURNAL_JPM,
    GET_JOURNAL_LAST_ACTION_DATE
} from "@/actions/SIMT-Report/Report/ReportAction";
import getAuth from "@/utils/getAuth";

export function GetJournalJPMData(selectDate, isOA) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(`/reports/journal/jpm/${date}`, {
                params: {
                    isOA: isOA,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.data.code === 200) {
                dispatch({
                    type: GET_JOURNAL_JPM,
                    data: res.data,
                });
            } else {
                dispatch({
                    type: GET_JOURNAL_JPM,
                    data: 'error',
                });
            }
        } catch (err) {
            dispatch({
                type: GET_JOURNAL_JPM,
                data: 'error',
            });
        }
    };
}

export function GetJournalBDData(selectDate, isOA) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(`/reports/journal/bd/${date}`, {
                params: {
                    isOA: isOA,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.data.code === 200) {
                dispatch({
                    type: GET_JOURNAL_BUSINESS_DIVISION,
                    data: res.data,
                });
            } else {
                dispatch({
                    type: GET_JOURNAL_BUSINESS_DIVISION,
                    data: 'error',
                });
            }
        } catch (err) {
            dispatch({
                type: GET_JOURNAL_BUSINESS_DIVISION,
                data: 'error',
            });
        }
    };
}

export function GetJournalLastActionDate(selectDate, isOA) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/reports/journal/last-action-date/${date}`,
                {
                    params: {
                        isOA: isOA,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: GET_JOURNAL_LAST_ACTION_DATE, data: res.data});
            } else {
                dispatch({
                    type: GET_JOURNAL_LAST_ACTION_DATE,
                    data: {data: []},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_JOURNAL_LAST_ACTION_DATE,
                data: {data: []},
            });
        }
    };
}
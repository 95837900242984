/**
 *  Purpose: to get filter counter according to cut-off date for Fiscal years
 */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFyDistanceByDates } from './reportUtils';

// filter only the selected records
export function useFilterCounterFyHook(
  filter,
) {
  const { selectDate, cutOffDate } = useSelector(state => {
    return {
      selectDate: state.Report.selectDate,
      cutOffDate: state.Report.cutOffDate,
    };
  });

  const counter = useMemo(() => {
    let counter = 0;
    const fyDistanceToCutOff = getFyDistanceByDates(selectDate, cutOffDate);
    // iterate the filter object and count valid items
    for (const key in filter) {
      if(fyDistanceToCutOff > 0 && key === 'jpm'){
        // console.log(key);
      }else if(fyDistanceToCutOff < 0 && (key === 'pdPublisher' || key === 'ppPublisher')){
        // console.log(key);
      }else if ((filter[key]?.length ?? 0) !== 0){
        counter++;
      }
    }
    // console.log('counterHookFy', counter);
    return counter;
  }, [selectDate, cutOffDate, filter]);

  return counter;
}
export const TAB_CHANGE = 'TAB_CHANGE';
//New Submission
export const GET_NEWSUBMISSION_OVERVIEW = 'GET_NEWSUBMISSION_OVERVIEW';
export const SUBMITNEWSUBMISSION = 'SUBMITNEWSUBMISSION';
//waitForApproval相关
export const GET_WAITINGFORAPPROVAL_DATA = 'GET_WAITINGFORAPPROVAL_DATA';
export const APPROVAL_WAITINGFORAPPROVAL = 'APPROVAL_WAITINGFORAPPROVAL';
export const REJECT_WAITINGFORAPPROVAL = 'REJECT_WAITINGFORAPPROVAL';
// History Request 相关
export const LOAD_HISTORY_REQUEST_DATA = 'LOAD_HISTORY_REQUEST_DATA';
export const CHANGE_HR_PAGE_SIZE = 'CHANGE_HR_PAGE_SIZE';
export const CHANGE_HR_PAGE = 'CHANGE_HR_PAGE';

export const CHANGE_WAITING_APPROVAL_MESSAGE =
  'CHANGE_WAITING_APPROVAL_MESSAGE';
export const CHANGE_WAITING_REJECT_MESSAGE = 'CHANGE_WAITING_REJECT_MESSAGE';

export function switchJobsTab(data) {
  return async dispatch => {
    dispatch({ type: TAB_CHANGE, data: data });
  };
}

export function resetNewSubmission() {
  return dispatch => {
    dispatch({
      type: SUBMITNEWSUBMISSION,
      data: { flag: 0, result: '' },
    });
  };
}

export function handleHRPageChangeAction(page) {
  return dispatch => {
    dispatch({
      type: CHANGE_HR_PAGE,
      data: page,
    });
  };
}

export function handleChangeHRPageSize(pageSize) {
  return dispatch => {
    dispatch({
      type: CHANGE_HR_PAGE_SIZE,
      data: pageSize,
    });
  };
}

/**
 * WaitingForApproval的操作
 */
export function resetWaitingForApprovalInfo() {
  return async dispatch => {
    dispatch({
      type: APPROVAL_WAITINGFORAPPROVAL,
      data: { Approve: '', Reject: '' },
    });
  };
}

export function waitingApprovalMessageChange(ifOpen) {
  return async dispatch => {
    dispatch({
      type: CHANGE_WAITING_APPROVAL_MESSAGE,
      data: ifOpen,
    });
  };
}

export function waitingRejectMessageChange(ifOpen) {
  return async dispatch => {
    dispatch({
      type: CHANGE_WAITING_REJECT_MESSAGE,
      data: ifOpen,
    });
  };
}

import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { ChartGroups } from '@/components/Chart/ChartGroups';
import { ChartList } from '@/components/Chart/ChartList';
import styles from './index.module.scss';
import { changeSiderOpen } from '@/actions/SIMT-User/User/UserManageAction';
import moment from 'moment';

import ActiveJournalSubjectGroups from '@/assets/ReportDiagrams/Journal/Overview/Active Journal Subject Groups.png';
import ActiveJournalBusinessDivisions from '@/assets/ReportDiagrams/Journal/Overview/Active Journal Business Divisions.png';
import ManagementModelsSubjectGroups from '@/assets/ReportDiagrams/Journal/Overview/Management Models Subject Groups.png';
import ManagementModelsBusinessDivisions from '@/assets/ReportDiagrams/Journal/Overview/Management Models Business Divisions.png';

import {MONTH, POST} from './Constants/index.js';
import ActiveChart from './ActiveChart';
import ModelChart from './ModelChart';

import {
  SUBJECT_GROUP,
  BUSINESS_DIVISION,
  ACTIVE_JOURNALS,
  JOURNAL,
  ALL_JOURNALS_WITH_DIFFERENT_MANAGEMENT_MODELS,
} from './Constants/index.js';

const DIAGRAM_SETTINGS = [
  // ACTIVE_JOURNALS
  {
    groupName: ACTIVE_JOURNALS,
    chartName: SUBJECT_GROUP,
    hasCategoryFilter: true,
    hasSelectAllOption: true,
    type: SUBJECT_GROUP.toLowerCase(),
    url: '/reports/journal/diagram/active/sg',
    tableDownloadUrl: '/reports/export/journal/sg/active/download',
    downloadMethod: POST,
    reportType: 'sg',
    tableDownloadTitle: ACTIVE_JOURNALS
  },
  {
    groupName: ACTIVE_JOURNALS,
    chartName: BUSINESS_DIVISION,
    hasCategoryFilter: true,
    hasSelectAllOption: false,
    type: BUSINESS_DIVISION.toLowerCase(),
    url: '/reports/journal/diagram/active/bd',
    tableDownloadUrl: '/reports/export/journal/bd/active/download',
    downloadMethod: POST,
    reportType: 'bd',
    tableDownloadTitle: ACTIVE_JOURNALS
  },
  // ALL_JOURNALS_WITH_DIFFERENT_MANAGEMENT_MODELS
  {
    groupName: ALL_JOURNALS_WITH_DIFFERENT_MANAGEMENT_MODELS,
    chartName: SUBJECT_GROUP,
    hasCategoryFilter: true,
    hasSelectAllOption: true,
    url: '/reports/journal/diagram/model/sg',
    type: SUBJECT_GROUP.toLowerCase(),
    tableDownloadUrl: '/reports/export/journal/sg/all/download',
    downloadMethod: POST,
    reportType: 'sg',
    tableDownloadTitle: JOURNAL
  },
  {
    groupName: ALL_JOURNALS_WITH_DIFFERENT_MANAGEMENT_MODELS,
    chartName: BUSINESS_DIVISION,
    hasCategoryFilter: true,
    hasSelectAllOption: false,
    url: '/reports/journal/diagram/model/bd',
    type: BUSINESS_DIVISION.toLowerCase(),
    tableDownloadUrl: '/reports/export/journal/bd/all/download',
    downloadMethod: POST,
    reportType: 'bd',
    tableDownloadTitle: JOURNAL
  },
];

// settings for ChartGroups
const CHART_GROUP_TITLES = [
  {
    groupTitle: ACTIVE_JOURNALS,
    levels: [
      {
        chartIndex: 0,
        title: SUBJECT_GROUP,
        img: ActiveJournalSubjectGroups,
      },
      {
        chartIndex: 1,
        title: BUSINESS_DIVISION,
        img: ActiveJournalBusinessDivisions,
      },
    ],
  },
  {
    groupTitle: ALL_JOURNALS_WITH_DIFFERENT_MANAGEMENT_MODELS,
    levels: [
      {
        chartIndex: 2,
        title: SUBJECT_GROUP,
        img: ManagementModelsSubjectGroups,
      },
      {
        chartIndex: 3,
        title: BUSINESS_DIVISION,
        img: ManagementModelsBusinessDivisions,
      },
    ],
  },
];

const DiagramContent = () => {
  // get selectedChartIndex from URL
  let [searchParams, setSearchParams] = useSearchParams();
  let selectedChartIndex = searchParams.get('selectedChartIndex');
  if (selectedChartIndex == null) {
    selectedChartIndex = -1;
  } else {
    selectedChartIndex = parseInt(selectedChartIndex);
  }

  const setShowTableNav = chartIndex => {
    setSearchParams({ showTable: false, selectedChartIndex: chartIndex });
  };

  const siderOpen = useSelector(state => state.UserManage.siderOpen);
  const dispatch = useDispatch();

  const { selectMonthDate } = useSelector(state => ({
    selectMonthDate: state.Report.selectDate,
  }));

  const formatdate = useMemo(() => {
    let date = selectMonthDate;
    // default is today
    if (date === null) {
      date = moment().utc().format('YYYY-M');
    }
    let [year, month] = date.split('-');
    month = MONTH[month];
    return month + ' ' + year;
  }, [selectMonthDate]);

  // update group titles with selected month year
  const formattedChartGroupTitles = CHART_GROUP_TITLES.map(chart => ({
    ...chart,
    groupTitle: chart.groupTitle + ' in ' + formatdate,
  }));

  const {
    groupName,
    chartName,
    hasCategoryFilter,
    hasSelectAllOption,
    type,
    url,
    tableDownloadUrl,
    downloadMethod,
    reportType,
    tableDownloadTitle
  } = useMemo(() => {
    if (
      selectedChartIndex >= 0 &&
      selectedChartIndex < DIAGRAM_SETTINGS.length
    ) {
      return DIAGRAM_SETTINGS[selectedChartIndex];
    } else {
      return {
        groupName: '',
        chartName: '',
        hasCategoryFilter: false,
        hasSelectAllOption: false,
        type: null,
        url: null,
        tableDownloadUrl: null,
        downloadMethod: null,
        reportType: null,
        tableDownloadTitle: null,
      };
    }
  }, [selectedChartIndex]);

  const onSelectChartIndex = index => {
    setShowTableNav(index);

    // if a chart is selected and the side menu is open, close the side menu
    if (index >= 0 && index < DIAGRAM_SETTINGS.length && siderOpen) {
      dispatch(changeSiderOpen(false));
    }
  };

  const cancelSelectedChart = () => onSelectChartIndex(-1);

  return (
    <Box sx={{ marginTop: '32px', paddingBottom: '62px' }}>
      {selectedChartIndex >= 0 ? (
        <Box className={styles['card-content-container']}>
          <div
            className={styles['card-content-paper']}
            disableGutters
            maxWidth={false}
          >
            {(selectedChartIndex === 0 || selectedChartIndex === 1) && (
              <ActiveChart
                header={groupName}
                subHeader={chartName}
                selectedMonth={selectMonthDate}
                formatdate={formatdate}
                cancelSelectedChart={cancelSelectedChart}
                hasCategoryFilter={hasCategoryFilter}
                hasSelectAllOption={hasSelectAllOption}
                type={type}
                url={url}
                tableDownloadUrl={tableDownloadUrl}
                reportType={reportType}
                tableDownloadTitle={tableDownloadTitle}
                downloadMethod={downloadMethod}
              />
            )}
            {(selectedChartIndex === 2 || selectedChartIndex === 3) && (
              <ModelChart
                header={groupName}
                subHeader={chartName}
                selectedMonth={selectMonthDate}
                formatdate={formatdate}
                cancelSelectedChart={cancelSelectedChart}
                hasCategoryFilter={hasCategoryFilter}
                hasSelectAllOption={hasSelectAllOption}
                url={url}
                tableDownloadUrl={tableDownloadUrl}
                reportType={reportType}
                tableDownloadTitle={tableDownloadTitle}
                downloadMethod={downloadMethod}
              />
            )}
          </div>
          <ChartList
            // chartGroupTitles={formattedChartGroupTitles}
            chartGroupTitles={CHART_GROUP_TITLES}
            onSelectChartIndex={onSelectChartIndex}
            selectedChartIndex={selectedChartIndex}
          />
        </Box>
      ) : (
        <ChartGroups
          chartGroupTitles={formattedChartGroupTitles}
          onSelectChartIndex={onSelectChartIndex}
          selectedChartIndex={selectedChartIndex}
        />
      )}
    </Box>
  );
};

export default DiagramContent;

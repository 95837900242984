import { Box, MenuItem, Menu, Popover, Typography } from '@mui/material';
import {
  disable_search_button,
  start_endAttachment,
  disable_button_value,
  disable_filter_button,
  disable_columns_button,
  responsive_button,
} from './css';
import React from 'react';
import SIPOverviewSearchDisable from '../../../assets/SIPOverviewSearchDisable.svg';
import SIPOverviewFilterDisable from '../../../assets/SIPOverviewFilterDisable.svg';
import SIPOverviewColumnDisable from '../../../assets/SIPOverviewColumnDisable.svg';
import SIOverviewExportIconNew from '../../../assets/StreamNew.svg';
import ExportDownloading from '../../../assets/ExportDownloading.svg';
import SIOverviewExportIconNewHover from '../../../assets/SIOverviewExportIconNewHover.svg';
import SIOverviewExportAll from '../../../assets/SIOverviewExportAll.svg';
import SIOverviewExportIconWhite from '@/assets/SIOverviewExportIconWhite.svg';
import DropdownIcon from '../../../assets/dropdownIcon.svg';
import SIOverviewExportIcon from '../../../assets/SIOverviewExportIcon.svg';
import { ResponsiveButton } from '../../../components/StyledButton/SIMTButton';
import ExportAllIcon from '../../../assets/exportAllIcon.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExportAllSIPModal, ExportSIPModal } from './SIPOverviewModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertBox } from '@/components/Alert/AlertBox';
import SIOverviewExportIconBlue from '../../../assets/SIOverviewExportIconBlue.svg';
import { CustomTooltip, CustomThinTooltip } from '@/components/CustomTooltip';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import {
  SET_SIP_EXPORT_ALL_STATE,
  SET_SIP_EXPORT_STATE,
} from '../../../actions/SIMT-SIP/SIP/SIPAction';
import RotatingSVG from '@/componentsphase2/RotatingSVG';
import { useParams } from 'react-router-dom';
import {exportSIPData} from "@/actions/SIMT-SIP/SIP/SipExport";

/**
 * simplePattern 用于判定按钮是否是简略模式（就是按钮只显示 图标）
 */
export function SearchButton(props) {
  const { disable, simplePattern } = props;
  return (
    <Box data-selenium-id='SIPPage_Overview-Button-Box1'>
      {disable ? (
        <Box
          sx={disable_search_button}
          data-selenium-id='SIPPage_Overview-Button-SearchBtnBox'
        >
          <Box sx={start_endAttachment}>
            <SIPOverviewSearchDisable data-selenium-id='SIPPage_Overview-Button-EndAttachmentBox1' />
          </Box>
          <Box
            data-selenium-id='SIPPage_Overview-Button-SearchBox'
            sx={{
              ...disable_button_value('#FFFFFF'),
              display: simplePattern ? 'none' : 'block',
            }}
          >
            Search
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}

export function FilterButton(props) {
  const { disable, simplePattern } = props;
  return (
    <Box data-selenium-id='SIPPage_Overview-Button-Box2'>
      {disable ? (
        <Box
          sx={disable_filter_button}
          data-selenium-id='SIPPage_Overview-Button-FilterBtnBox'
        >
          <Box sx={start_endAttachment}>
            <SIPOverviewFilterDisable data-selenium-id='SIPPage_Overview-Button-EndAttachmentBox2' />
          </Box>
          <Box
            data-selenium-id='SIPPage_Overview-Button-FilterBox'
            sx={{
              ...disable_button_value('#BCC5CF'),
              display: simplePattern ? 'none' : 'block',
            }}
          >
            Filter
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}

export function ColumnsButton(props) {
  const { disable, simplePattern } = props;
  return (
    <Box data-selenium-id='SIPPage_Overview-Button-ColumnsBox'>
      {disable ? (
        <Box
          sx={disable_columns_button}
          data-selenium-id='SIPPage_Overview-Button-ColumnsBtnBox'
        >
          <Box sx={start_endAttachment}>
            <SIPOverviewColumnDisable data-selenium-id='SIPPage_Overview-Button-EndAttachementBox3' />
          </Box>
          <Box
            data-selenium-id='SIPPage_Overview-Button-ColumnsBox'
            sx={{
              ...disable_button_value('#BCC5CF'),
              display: simplePattern ? 'none' : 'block',
            }}
          >
            Columns
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}

export const ExportAllButton = props => {
  const { downBy } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const { exportAllState } = useSelector(state => ({
    /**
     * @type {"idle" | "openModal" | "exporting" | "finish" | "error"} */
    exportAllState: state.SIP.exportAllState,
  }));

  const [exportAllModalOpen, setExportAllModalOpen] = useState(false);

  useEffect(() => {
    // 根据 exportAllState 做出相应动作，动画、消息等待完善
    if (exportAllState === 'finish') {
      setExportAllModalOpen(false);
    }
  }, [exportAllState]);

  useEffect(() => {
    if (exportAllModalOpen) {
      dispatch({ type: SET_SIP_EXPORT_ALL_STATE, data: 'openModal' });
    } else {
      dispatch({ type: SET_SIP_EXPORT_ALL_STATE, data: 'idle' });
    }
  }, [exportAllModalOpen]);

  return (
    <>
      <ResponsiveButton
        id='exportall-button'
        data-selenium-id='SIPPage_Overview-SIOverview-ExportAllButton'
        downBy={downBy}
        color='white'
        variant='contained'
        aria-haspopup='true'
        onClick={() => {
          setExportAllModalOpen(true);
        }}
        startIcon={<SIOverviewExportAll sx={{ fontSize: '1px' }} />}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px 12px !important',
          gap: '6px',
          //position: 'absolute',
          // width: '121px',
          minWidth: '48px',
          // marginLeft: '60px',
          background: '#0052CC',
          height: '34px',
          /* Primary/500- */
          borderRadius: '5px',
          whiteSpace: 'nowrap',
          color: '#FFFFFF',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '18px',
          '& .MuiButton-startIcon': {
            margin: 0,
          },
          '&:hover': {
            background: '#6D98EE',
          },
          '&:active': {
            background: '#113D95',
          },
        }}
      >
        <FormattedMessage id='common.exportOGAll' />
      </ResponsiveButton>
      <ExportAllSIPModal
        data-selenium-id='SIPPage_Overview-Button-ExportAllModal'
        title='Export Special Issues Proposal'
        open={exportAllModalOpen}
        handleClose={() => setExportAllModalOpen(false)}
        height='auto'
      />
    </>
  );
};

export const ExportButton = props => {
  const { downBy } = props;
  const { formatMessage } = useIntl();
  const [isDownloading, setisDownloading] = useState(false);

  const [exportAllModalOpen, setExportAllModalOpen] = useState(false);
  const [exportMsgOpen, setExportMsgOpen] = useState(false);

  const { sipOverviewSeletedData, exportState } = useSelector(state => {
    return {
      /** @type {string[]} */
      sipOverviewSeletedData: state.SIP.sipOverviewSeletedData,
      /** @type {"idle" | "openMenu" | "exporting" | "finish" | "error"} */
      exportState: state.SIP.exportState,
    };
  });
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const exportMenuOpen = Boolean(anchorEl);
  const [hasSelector, setHasSelector] = useState(false);

  const handleButtonClick = event => {
    if (sipOverviewSeletedData.length === 0) {
      setExportMsgOpen(true);
      return;
    } else {
      setExportAllModalOpen(true);
    }
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setHasSelector(sipOverviewSeletedData.length > 0);
  }, [sipOverviewSeletedData]);

  /**
   * 处理导出事件
   * @param {("xlsx" | "csv")} exportDataType - 需要导出的类型
   */
  const handleExport = exportDataType => {
    dispatch({ type: SET_SIP_EXPORT_STATE, data: 'exporting' });
    dispatch(exportSIPData(sipOverviewSeletedData, exportDataType));
  };

  const handleExportMenuClose = () => {
    setAnchorEl(null);
  };

  const { sipOrigin } = useParams();
  const handleExportExcel = () => {
    setisDownloading(true);
    async function fetchData() {
      dispatch({ type: SET_SIP_EXPORT_STATE, data: 'exporting' });
      if (sipOverviewSeletedData.length <= 1000) {
        await dispatch(
          exportSIPData(
            sipOverviewSeletedData,
            'xlsx',
            sipOrigin === 'solicited'
          )
        );
        await delay(500);
      } else {
        dispatch(
          setSnackbarMessageAndOpen(
            'common.largeSizeFail',
            {},
            'warning',
            null,
            null,
            {
              title: 'You can only export a maximum of 1000 items at once.',
            },
            localStorage.getItem('userPermissions').includes('admin')
              ? 'Please visit the ‘Export Data’ page to export all data.'
              : 'You can contact system admin if you want to export all data.'
          )
        );
      }

      setisDownloading(false);
      // await dispatch(exportCatJournalExcel(selectedList));
    }

    fetchData();
    setAnchorEl(null);
  };

  const handleExportCsv = () => {
    setisDownloading(true);
    async function fetchData() {
      dispatch({ type: SET_SIP_EXPORT_STATE, data: 'exporting' });
      if (sipOverviewSeletedData.length <= 1000) {
        await dispatch(
          exportSIPData(
            sipOverviewSeletedData,
            'csv',
            sipOrigin === 'solicited'
          )
        );
        await delay(500);
      } else {
        dispatch(
          setSnackbarMessageAndOpen(
            'common.largeSizeFail',
            {},
            'warning',
            null,
            null,
            {
              title: 'You can only export a maximum of 1000 items at once.',
            },
            localStorage.getItem('userPermissions').includes('admin')
              ? 'Please visit the ‘Export Data’ page to export all data.'
              : 'You can contact system admin if you want to export all data.'
          )
        );
      }

      setisDownloading(false);
    }

    fetchData();
    setAnchorEl(null);
  };

  return (
    <>
      <ResponsiveButton
        id='export-button'
        data-selenium-id='SIPPage_Overview-SIOverview-ExportButton'
        downBy={downBy}
        color='white'
        variant='contained'
        onClick={handleButtonClick}
        disabled={isDownloading}
        //
        startIcon={
          isDownloading ? (
            <RotatingSVG />
          ) : hasSelector ? (
            <SIOverviewExportIconWhite />
          ) : (
            <SIOverviewExportIconNew />
          )
        }
        sx={{
          boxSizing: 'border-box',
          /* Auto layout */
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px 12px !important',
          gap: '6px',
          // width: '83px',
          minWidth: '48px',
          height: '34px',
          border: hasSelector ? 'none' : '1px solid #0052CC',
          borderRadius: '5px',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '18px',
          /* identical to box height, or 150% */
          textAlign: 'center',
          /* Primary/500- */
          color: hasSelector ? '#FFFFFF' : '#0052CC',
          backgroundColor: isDownloading
            ? '#AEC6F6 !important'
            : hasSelector
            ? '#0052CC'
            : '#FFFFFF',
          /* Inside auto layout */
          flex: 'none',
          order: 1,
          flexGrow: 0,
          '& .MuiButton-startIcon': {
            margin: 0,
          },
          // '&:hover': {
          //   border: '1px solid #6D98EE',
          //   color: '#6D98EE',
          //   'svg path': {
          //     fill: '#6D98EE',
          //   },
          // },
          // '&:active': {
          //   background: '#E8EDF8',
          //   border: '1px solid #AEC6F6',
          //   color: '#6D98EE',
          //   'svg path': {
          //     fill: '#6D98EE',
          //   },
          // },
          '&:hover': {
            color: hasSelector ? '#FFFFFF' : '#6D98EE',
            background: hasSelector ? '#6d98ee' : '#FFFFFF',
            'svg path': {
              fill: hasSelector ? null : '#6D98EE',
            },
          },
          '&:active': {
            color: hasSelector ? '#FFFFFF' : '#6D98EE',
            background: hasSelector ? '#113d95' : '#e8edfb',
            'svg path': {
              fill: hasSelector ? null : '#6D98EE',
            },
          },
        }}
      >
        <CustomThinTooltip
          placement='top'
          title='Export Selected Item(s)'
          data-selenium-id='SIP_Overview-ExportButton-Tooltip'
          disableInteractive
          //open={true}
        >
          <div>{formatMessage({ id: 'common.Export' })}</div>
        </CustomThinTooltip>
      </ResponsiveButton>
      <Menu
        id='export-menu'
        data-selenium-id='CATJournal_Overview-ExportButton-Menu'
        anchorEl={anchorEl}
        open={exportMenuOpen}
        MenuListProps={{
          'aria-labelledby': 'export-button',
        }}
        onClose={handleExportMenuClose}
        sx={{
          '& .MuiPopover-paper': {
            marginTop: '6px',
            gap: '6px',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
            padding: '2px 0px 2px 0px',
            width: '98px',
          },
        }}
      >
        <MenuItem
          disableRipple
          id='exportCSV'
          data-selenium-id='SIP_Overview-ExportButton-ExportCSVMenuItem1'
          onClick={handleExportCsv}
          sx={{
            fontSize: '12px',
            lineHeight: '20px',
            color: '#596A7C',
            paddingTop: '2px',
            paddingBottom: '2px',
            paddingLeft: '12px',
            '&:hover': {
              backgroundColor: '#F1F3F5',
              color: '#262E35',
            },
            '&:active': {
              backgroundColor: '#FFFFFF',
              color: '#262E35',
              fontWeight: 600,
            },
          }}
        >
          Export CSV
          {/* {intl.messages['common.exportToCSV']} */}
        </MenuItem>
        <MenuItem
          disableRipple
          id='exportExcel'
          data-selenium-id='SIP_Overview-ExportButton-ExportExcelMenuItem1'
          onClick={handleExportExcel}
          sx={{
            fontSize: '12px',
            lineHeight: '20px',
            color: '#596A7C',
            paddingTop: '2px',
            paddingBottom: '2px',
            paddingLeft: '12px',
            '&:hover': {
              backgroundColor: '#F1F3F5',
              color: '#262E35',
            },
            '&:active': {
              backgroundColor: '#FFFFFF',
              color: '#262E35',
              fontWeight: 600,
            },
          }}
        >
          Export EXCEL
          {/* {intl.messages['common.exportToEXCEL']} */}
        </MenuItem>
      </Menu>

      {/* <ExportSIPModal
        data-selenium-id='SIPPage_Overview-Button-ExportAllModal'
        title='Export Special Issues Proposal'
        open={exportAllModalOpen}
        handleClose={() => setExportAllModalOpen(false)}
        handleExport={handleExport}
      /> */}
      <AlertBox
        open={exportMsgOpen}
        handleClose={() => {
          setExportMsgOpen(false);
        }}
        severity='warning'
        autoHideDuration={4000}
        title='Warning'
        // message='Please select at least journal to export.'
        message='Please select at least one Special Issue Proposal to export.'
      />
    </>
  );
};
function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BasicPage, { sortPermissions } from './BasicPage';
import { setPermission } from '@/actions/SIMT-User/Role/RoleManageAction';
import { getRoleDetail } from '@/actions/SIMT-User/Role';

function UpdateRole(props) {
  const dispatch = useDispatch();
  const { roleDetail } = useSelector(state => {
    return {
      roleDetail: state.Role.roleDetail,
    };
  });
  let { RoleName } = useParams();
  useEffect(() => {
    if (roleDetail) {
      dispatch(
        setPermission('left', roleDetail.notAddPermissionList.sort(sortPermissions), false)
      );
      dispatch(
        setPermission('right', roleDetail.addedPermissionList.sort(sortPermissions), false)
      );
      dispatch(
        setPermission(
          'originalLeft',
          roleDetail.notAddPermissionList.sort(sortPermissions),
          false
        )
      );
      dispatch(
        setPermission(
          'originalRight',
          roleDetail.addedPermissionList.sort(sortPermissions),
          false
        )
      );
    }
  }, [roleDetail]);

  useEffect(() => {
    async function fetchData() {
      dispatch(setPermission('left', []));
      dispatch(setPermission('right', []));
      dispatch(setPermission('originalLeft', []));
      dispatch(setPermission('originalRight', []));
      await dispatch(getRoleDetail(RoleName));
    }
    fetchData();
  }, []);

  return (
    <BasicPage
      id='roleManagementUpdateBasicPage'
      data-seleunim-id='SystemAdmin_RoleManagement-ActionsToRolePage-UpdateBasicPage'
      PageTitle={props.readonly ? 'Role Details' : 'Update Role'}
      ActionBtn='UPDATE'
      RoleName={RoleName}
      readonly={props.readonly}
      // initNotAuthList={initNotAuthList}
      // selectedAuthList={selectedAuthList}
    />
  );
}

export default UpdateRole;

import {axios_instance} from "@/utils/axios_instance";
import {forEmail} from "@/modules/RichTextEditor/EditorUtils";
import {extractAttachmentId} from "@/utils/commonUtils";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {GET_EMAIL_SHOW_INFO, SEND_EMAILRETURNCODE} from "@/actions/SIMT-Email/EmailAction";
import getAuth from "@/utils/getAuth";

export function sendManualJournalEmail(
    emailFrom,
    to,
    copy,
    bcc,
    subject,
    content,
    files,
    journalCode
) {
    return async dispatch => {
        try {
            const url = '/journal/email/sendManualEmail';
            const res = await axios_instance.post(
                url,
                {
                    journalCode: journalCode,
                    'from': emailFrom,
                    to: to,
                    bcc: bcc,
                    copy: copy,
                    subject: subject,
                    content: forEmail(content),
                    attachment: extractAttachmentId(files),
                    contentWithCss: null, // 后端期望获得null
                },
                {
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );

            // 获取发送邮件后的状态码
            dispatch({type: SEND_EMAILRETURNCODE, data: res.data.code});
            if (res.data.code == 200) {
                dispatch(
                    setSnackbarMessageAndOpen('email.sendSuccess', {}, SEVERITIES.success)
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen('email.sendError', {}, SEVERITIES.error)
                );
            }
        } catch (err) {
            // 发送邮件错误时给前端反馈
            dispatch({type: SEND_EMAILRETURNCODE, data: err.data.timestamp});
            dispatch(
                setSnackbarMessageAndOpen(
                    'email.updateError',
                    {
                        value: err.data.error,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
}

export function getEmailShowJournalSharing(journalCode) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/journals/${journalCode}/emails`;
            const res = await axios_instance.get(url, {
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: GET_EMAIL_SHOW_INFO, data: res.data.data});
            } else {
                dispatch({type: GET_EMAIL_SHOW_INFO, data: null});
                dispatch(
                    setSnackbarMessageAndOpen('email.showError', {}, SEVERITIES.error)
                );
            }
        } catch (err) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'email.updateError',
                    {
                        value: err.data.error,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
}
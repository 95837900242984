import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  IconButton,
  TextField,
  Popover
} from '@mui/material';
import MarketExportMonthIcon from '../../../assets/MarketExportMonth.svg';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MonthCalendar } from '../../../components/DateRangePicker/MonthCalendar';
import * as DateFnsUtils from 'date-fns';
import zIndex from '@mui/material/styles/zIndex';

export const MyMonthPicker = (props) => {
  const {
    value,
    minDate, maxDate,
    handleDateChange, placeholder, minHeight,
    width = 110, border = '2px solid transparent', popoverIndex = zIndex, innerBorder,
    iconPaddingRight, backgroundColor,
    autoClose = true,
    ...others
  } = props;
  const [dateOpen, setDateOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [date, setDate] = useState(value);

  useEffect(() => {
    setDate(value);
  }, [value]);

  const handleOpenPicker = (event) => {
    setDateOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePicker = () => {
    setDateOpen(false);
    setAnchorEl(null);
    handleDateChange(date);
  };

  const handleChange = (date) => {
    setDate(date);
    if (autoClose){
      setDateOpen(false);
      setAnchorEl(null);
      handleDateChange(date);
    }
  };

  const emptyDateString = '              ';
  const formatDateOptional = (dt) => {
    return dt ? DateFnsUtils.format(dt, 'yyyy-MM') : emptyDateString;
  };
  const textFieldString = () => {
    if (date) {
      return `${formatDateOptional(date)}`;
    } else {
      return '';
    }
  };

  const id = dateOpen ? 'date-range-popover' : undefined;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} data-selenium-id={`SIPage_Overview-newFilterDrawer-FilterDataPicker`} >
      <Box sx={{
        width: width,
        paddingRight: '12px',
        '& .MuiFormControl-root': {
          marginTop: 0,
          marginBottom: 0,
          '& .MuiInputBase-input': {
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: minHeight
          }
        },
        '& .MuiButtonBase-root.MuiIconButton-root': {
          paddingLeft: '0',
        },
      }}
      {...others}
      >
        <TextField
          fullWidth={true}
          value={textFieldString()}
          placeholder={placeholder || 'yyyy-mm'}
          onClick={handleOpenPicker}
          InputProps={{
            endAdornment: (
              <IconButton
                disableRipple
                onClick={handleOpenPicker}
                sx={{
                  height: '40px',
                  paddingRight: iconPaddingRight,
                  transform: 'translateX(12px)',
                  '&:hover': {
                    backgroundColor: 'transparent !important',
                  }
                }}>
                <MarketExportMonthIcon />
              </IconButton>
            ),
          }}
          sx={{
            '& input': {
              width: width-20,
              height: '18px',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '18px',
              display: 'flex',
              alignItems: 'center',
              color: '#262E35',
            },
            width: width,
            borderRadius: '4px',
            backgroundColor: backgroundColor,
            '& .MuiOutlinedInput-notchedOutline': {
              border: dateOpen?'2px solid #0052CC !important':'1px solid #98A7B6',
            },
            '& :hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#262E35 !important',
              }
            },
            '& .Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: '2px solid #0052CC !important',
              }
            },
          }}
        />
        <Popover
          id={id}
          open={dateOpen}
          anchorEl={anchorEl}
          onClose={handleClosePicker}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            zIndex: popoverIndex,
            '& .MuiPaper-root': {
              width: '284px',
              height: '274px',
              overflowX: 'hidden',
              overflowY: 'hidden',
              marginTop: '6px'
            },
            '&>div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper>div': {
              transform: 'translateY(10px)',
              margin: '0 auto',
            },
            '& .MuiPopover-paper': {
              marginTop: '6px',
              boxShadow: '0px 6px 12px 0px #262E351F',
            },
          }}
        >
          {/* <Stack className="DateRange-FromCalendar" spacing={2}> */}
          {/* <Box className="DateRange-Title">From:</Box> */}
          <MonthCalendar date={date} minDate={minDate} maxDate={maxDate} onChange={handleChange} />
          {/* </Stack> */}
        </Popover>
      </Box>
    </LocalizationProvider>
  );
};

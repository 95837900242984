import styles from './index.module.scss';

const currentDate = new Date();
const year = currentDate.getFullYear().toString();

function Footer() {
  return (
    <>
      <footer className={styles['footer']}>
        <div className={styles['layout']}>
          <div className={styles['copy-links']}>
            <a
              href='https://www.wiley.com/permissions'
              target='_blank'
            >
              Rights & Permissions
            </a>
            <i>|</i>
            <a
              href='https://www.wiley.com/privacy'
              target='_blank'
            >
              Privacy Policy
            </a>
            <i>|</i>
            <a
              href='https://www.wiley.com/terms-of-use'
              target='_blank'
            >
              Terms of Use
            </a>
          </div>

          <div className={styles['copy-rights']}>
            <a
              href='https://www.wiley.com/copyright'
              target='_blank'
            >
              Copyright @ 2000-{year}
            </a>
            <span
              style={{ fontSize: '12px', fontWeight: 600, color: '#0056B6' }}
            >
              &nbsp;by&nbsp;
            </span>
            <a
              href='https://www.wiley.com/en-us'
              target='_blank'
            >
              John Wiley & Sons, Inc.
            </a>

          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { getYearList } from './Utils';
import { Box, Button, ThemeProvider } from '@mui/material';
import { themeButton } from '../CommonTheme';
import { SmallFont } from '@/components/FontStyle';
import { Scrollbars } from 'react-custom-scrollbars';
import { AllCentered } from '../CommonStyle';

const YearPage = (props, ref) => {
  const { id, visible, active, change, temYear, setTemYear, maxDate, minDate } =
    props;

  const list = getYearList(maxDate, minDate);
  const maxYear = new Date().getFullYear() + 4;

  const IconButton = props => {
    const { selected, label, ...other } = props;
    return (
      <ThemeProvider theme={themeButton(selected ? '#154AB6' : '#AEC6F6')}>
        <Button
          sx={{
            display: 'flex',
            width: '72px',
            height: '30px',
            minWidth: '0px',
            borderRadius: '4px',
            padding: '0px',
            ...SmallFont,
            lineHeight: '19px',
            color: selected ? '#FFFFFF' : '#596A7C',
            backgroundColor: selected ? '#154AB6' : '#FFFFFF',
            leadingTrim: 'both',
            textEdge: 'cap',
          }}
          {...other}
        >
          {label}
        </Button>
      </ThemeProvider>
    );
  };
  const DisabledIconButton = props => {
    const { label, ...other } = props;
    return (
      <Box
        sx={{
          width: '72px',
          height: '30px',
          ...AllCentered,
          ...SmallFont,
          lineHeight: '19px',
          leadingTrim: 'both',
          textEdge: 'cap',
          color: '#BCC5CF',
        }}
        {...other}
      >
        {label}
      </Box>
    );
  };

  function activeChange(params) {
    change(params);
  }
  // 减少
  function reduceFn() {
    let newTemYear = temYear - 1 < 1970 ? maxYear : temYear - 1;
    setTemYear(newTemYear);
  }
  // 增加
  function addFn() {
    let newTemYear = temYear + 1 > maxYear ? 1970 : temYear + 1;
    setTemYear(newTemYear);
  }
  // render List
  function renderList() {
    return list
      .concat()
      .reverse()
      .map(item => {
        return item.disabled ? (
          <DisabledIconButton
            key={item.label}
            label={item.label}
            id={`${id}_content_disabled`}
            data-selenium-id={`${id}_content_disabled_${item.label}`}
          />
        ) : (
          <IconButton
            id={`${id}_content`}
            data-selenium-id={`${id}_content_${item.label}`}
            key={item.label}
            selected={active === item.value}
            label={item.label}
            onClick={activeChange.bind(this, item)}
          />
        );
      });
  }
  useImperativeHandle(ref, () => ({
    reduceFn: reduceFn,
    addFn: addFn,
  }));
  return visible ? (
    <Scrollbars
      id={`${id}_scrollbars`}
      data-selenium-id={`${id}_scrollbars`}
      style={{ height: '250px', display: 'block' }}
      renderTrackVertical={({ style, ...props }) => (
        <div
          style={{
            ...style,
            position: 'absolute',
            top: '0px',
            height: 'calc(100% - 6px)',
            right: '3px',
          }}
          {...props}
        ></div>
      )}
      renderTrackHorizontal={({ style, ...props }) => (
        <div
          style={{
            ...style,
            position: 'absolute',
            width: '0px',
            height: '0px',
          }}
          {...props}
        ></div>
      )}
      renderThumbVertical={({ style, ...props }) => (
        <div
          style={{
            ...style,
            backgroundColor: '#DFE4E8',
            opacity: '0.7',
            borderRadius: '10px',
            width: '6px',
          }}
          {...props}
        ></div>
      )}
      renderView={({ style, ...props }) => (
        <div
          style={{
            ...style,
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '250px',
          }}
          {...props}
        ></div>
      )}
      thumbSize={69}
    >
      <Box
        id={`${id}_content_box`}
        data-selenium-id={`${id}_content_box`}
        sx={{
          display: 'flex',
          width: '268px',
          flexDirection: 'row',
          flexWrap: 'wrap',
          rowGap: '14px',
          columnGap: '18px',
        }}
      >
        {renderList()}
      </Box>
    </Scrollbars>
  ) : null;
};
export default forwardRef(YearPage);

/**
 * yueying
 */
import React, { useEffect, useState } from 'react';
import './index.css';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '../../../components/DataTable';
import {
  StyledTableCellPurple,
  StyledTableCellDisPurple,
  StyledTableCellDisGreen,
  StyledTableCellGreen,
  StyledTableRow, StyledTableCellNormal, StyledBodyCellPurple, StyledBodyCellGreen, TableBox, ReportNoData, TableBox2
} from "../ReportSpecificComponents/SpecialColorTableHeader";
import { findLongestLineSubstring, measureWidth } from "../../../utils/StringWidthMeasure";
import {GetTable3Data} from "@/actions/SIMT-Report/Report/ReportSiPipeline";

export function BusinessDivisionTable(props) {
  const { formatYear = 'FY**' } = props;
  const [loading, setLoading] = useState(false);
  const items = [
    {
      id: 'solicitedSipCount',
      label: 'Solicited Proposal',
      minWidth: '100px',
      maxWidth: '100px',
      isOrder: false,
    },
    {
      id: 'unsolicitedSipCount',
      label: 'Unsolicited Proposal',
      minWidth: '100px',
      maxWidth: '100px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewBdIdeaProposal',
      label: 'Idea / Proposal',
      minWidth: '100px',
      maxWidth: '100px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewBdAcquired',
      label: 'Acquired',
      minWidth: '50px',
      maxWidth: '100px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewBdPaperCommission',
      label: 'Paper Commission',
      minWidth: '125px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewBdSubmissionReview',
      label: 'Submission & Review',
      minWidth: '130px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewBdProduction',
      label: 'Production',
      minWidth: '70px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewBdPublishedFY',
      label: `Published in ${formatYear}`,
      minWidth: '65px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewBdPublished',
      label: 'Published',
      minWidth: '65px',
      maxWidth: '150px',
      isOrder: false,
    },
  ];
  const dispatch = useDispatch();
  const { currentDate, table3Data } = useSelector(state => {
    return {
      currentDate: state.Report.selectDate,
      table3Data: state.Report.table3Data,
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    for (let item of items) {
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '12px Open Sans');
    }
    widthMap['businessDivision'] = measureWidth(findLongestLineSubstring('Business Division'), '12px Open Sans');
    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (table3Data && table3Data.length > 0) {
      const maxMap = {};
      for (let i = 0; i < table3Data.length; i++) {
        let row = table3Data[i];
        let dataObj = {
          businessDivision: row.businessDivision,

          reportPipelineOverviewBdIdeaProposal: row.numIdeaOrProposal ?? '0',
          reportPipelineOverviewBdAcquired: row.numAcquired ?? '0',
          reportPipelineOverviewBdPaperCommission: row.numPaperCommission ?? '0',
          reportPipelineOverviewBdSubmissionReview: row.numSubmissionAndReview ?? '0',
          reportPipelineOverviewBdProduction: row.numProduction ?? '0',
          reportPipelineOverviewBdPublishedFY: row.numPublishedFy ?? '0',
          reportPipelineOverviewBdPublished: row.numPublished ?? '0',

          OAreportPipelineOverviewBdIdeaProposal: row.numOA_IdeaOrProposal ?? '0',
          OAreportPipelineOverviewBdAcquired: row.numOA_Acquired ?? '0',
          OAreportPipelineOverviewBdPaperCommission: row.numOA_PaperCommission ?? '0',
          OAreportPipelineOverviewBdSubmissionReview: row.numOA_SubmissionAndReview ?? '0',
          OAreportPipelineOverviewBdProduction: row.numOA_Production ?? '0',
          OAreportPipelineOverviewBdPublishedFY: row.numOA_Published_Fy ?? '0',
          OAreportPipelineOverviewBdPublished: row.numOA_Published ?? '0',
        };
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength) {
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '16px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [table3Data]);

  function calcAllJournalWidth() {
    let sum = 0;
    for (let item of items) {
      sum += Math.max((columnWidthMap[item.id] ?? 0), columnMinWidthMap[item.id]) + 50;
    }
    return sum;
  }

  function calcOAJournalWidth() {
    let sum = 0;
    for (let item of items) {
      sum += Math.max((columnWidthMap['OA' + item.id] ?? 0), columnMinWidthMap[item.id]) + 50;
    }
    return sum;
  }

  function calcTableWidth() {
    let baseWidth = Math.max((columnWidthMap['businessDivision'] ?? 0), columnMinWidthMap['businessDivision']) + 50 + 2;
    return baseWidth + calcAllJournalWidth() + calcOAJournalWidth();
  }

  useEffect(() => {
    async function getData() {
      if (currentDate != null) {
        await dispatch(GetTable3Data(currentDate));
        setLoading(false);
      }
    }
    setLoading(true);
    getData();
  }, [dispatch, currentDate]);

  const showDataTableSG = () => {
    if (table3Data === undefined || table3Data === null) {
      return [];
    } else return table3Data;
  };

  //total字体加深
  function DisplayTooltip(props) {
    const { ifTotal } = props;
    if (ifTotal === 'Total')
      return (
        <div align='right'>
          <  >{ifTotal}</  >
        </div>
      );
    else {
      return <div align='left'>{ifTotal}</div>;
    }
  }
  return (
    <TableBox2
      id='BoxTable2'
      data-selenium-id='Report-SIPipeline-BusinessTable-Box'
      dataList={[table3Data]}
      tableKey={'Report-SIPipeline-BusinessTable'}
      footerHeight={0}
    >
      <TableContainer sx={{ maxHeight: '600px' }}>
        <Table
          stickyHeader aria-label='sticky table'
          sx={{
            tableLayout: 'fixed',
            width: 'max-content',
          }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCellNormal
                id='reportPipelineOverviewBdStyledTableCellPurple'
                data-selenium-id='Report_PipelineOverview-wBdStyledTableCellPurple'
                className='RightBottomBorder'
                align='left'
                rowSpan={2}
                sx={{
                  // maxWidth: '500px',
                  // minWidth: '140px' ,
                  width: `${Math.max((columnWidthMap['businessDivision'] ?? 0), columnMinWidthMap['businessDivision']) + 50 + 2}px`,
                  position: 'sticky',
                  left: 0,
                  top: 0,
                  zIndex: 8,
                  backgroundColor: '#DFE4E8',
                }}
              >
                Business Division
              </StyledTableCellNormal>
              <StyledTableCellPurple
                id='reportPipelineOverviewBdAllStyledTableCellPurple'
                data-selenium-id='Report_PipelineOverview-BdAllStyledTableCellPurple'
                className='RightBottomBorder'
                align='left'
                colSpan={9}
                sx={{
                  width: `${calcAllJournalWidth()}px`,
                }}
              >
                All journals
              </StyledTableCellPurple>
              <StyledTableCellGreen
                id='reportPipelineOverviewBdOaStyledTableCellGreen'
                data-selenium-id='Report_PipelineOverview-BdOaStyledTableCellGreen'
                className='RightBottomBorder'
                align='left'
                colSpan={9}
                sx={{
                  width: `${calcOAJournalWidth()}px`,
                }}
              >
                OA journals
              </StyledTableCellGreen>
            </TableRow>
            <TableRow>
              {items.map((item, index) =>
                item.id === 'reportPipelineOverviewBdPublished' ? (
                  <StyledTableCellDisPurple
                    id={`reportPipelineOverviewBdOaStyledTableCellPurple${index}`}
                    data-selenium-id={`Report-PipelineSubjectTable-BdOaStyledTableCellPurple-${index}`}
                    className='RightBottomBorder'
                    align='left'
                    sx={{
                      top: 42,
                      // minWidth: item.minWidth,
                      // maxWidth: item.maxWidth,
                      ...({
                        width: `${Math.max((columnWidthMap[item.id] ?? 0), columnMinWidthMap[item.id]) + 50}px`,
                      }),
                    }}
                    key={item.id}
                    style={{
                      'text-transform': 'capitalize',
                    }}
                  >
                    {item.label}
                  </StyledTableCellDisPurple>
                ) : (
                  <StyledTableCellDisPurple
                    id={`reportPipelineOverviewBdOaStyledTableCellPurple${index}`}
                    data-selenium-id={`Report-PipelineSubjectTable-BdOaStyledTableCellPurple-${index}`}
                    align='left'
                    sx={{
                      top: 42,
                      // minWidth: item.minWidth,
                      // maxWidth: item.maxWidth,
                      ...({
                        width: `${Math.max((columnWidthMap[item.id] ?? 0), columnMinWidthMap[item.id]) + 50}px`,
                      }),
                    }}
                    key={item.id}
                    style={{ 'text-transform': 'capitalize' }}
                    className='BottomBorder'
                  >
                    {item.label}
                  </StyledTableCellDisPurple>
                )
              )}
              {items.map((item, index) =>
                item.id === 'reportPipelineOverviewBdPublished' ? (
                  <StyledTableCellDisGreen
                    id={`reportPipelineOverviewBdOaStyledTableCellGreen${index}`}
                    data-selenium-id={`Report-PipelineSubjectTable-BdOaStyledTableCellGreen-${index}`}
                    className='RightBottomBorder'
                    align='left'
                    sx={{
                      top: 42,
                      // minWidth: item.minWidth,
                      // maxWidth: item.maxWidth,
                      ...({
                        width: `${Math.max((columnWidthMap['OA' + item.id] ?? 0), columnMinWidthMap[item.id]) + 50}px`,
                      }),
                    }}
                    key={item.id}
                    style={{
                      'text-transform': 'capitalize',
                    }}
                  >
                    {item.label}
                  </StyledTableCellDisGreen>
                ) : (
                  <StyledTableCellDisGreen
                    id={`reportPipelineOverviewBdOaStyledTableCellGreen${index}`}
                    data-selenium-id={`Report-PipelineSubjectTable-BdOaStyledTableCellGreen-${index}`}
                    align='left'
                    sx={{
                      top: 42,
                      // minWidth: item.minWidth,
                      // maxWidth: item.maxWidth,
                      ...({
                        width: `${Math.max((columnWidthMap['OA' + item.id] ?? 0), columnMinWidthMap[item.id]) + 50}px`,
                      }),
                    }}
                    key={item.id}
                    style={{ 'text-transform': 'capitalize' }}
                    className='BottomBorder'
                  >
                    {item.label}
                  </StyledTableCellDisGreen>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              table3Data != null &&
              table3Data != undefined &&
              table3Data.length != 0
              ? table3Data.map((row, index) => {
                if (row.businessDivision == 'Total') {
                  return (
                    <StyledTableRow
                      sx={{
                        height: '64px'
                      }}
                    >
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewBdBusinessDivision${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdBusinessDivision-${index}`}
                        align='right'
                        sx={{
                          maxWidth: '150px',
                          position: 'sticky',
                          left: 0,
                          zIndex: 6,
                          backgroundColor: '#F1F3F5',
                        }}
                        className='RightBorder ReportTotalTextSize'
                      >
                        <DisplayTooltip ifTotal={row.businessDivision} />
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewBdNumIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdNumIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.solicitedSipCount === null
                            ? '0'
                            : row.solicitedSipCount}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewBdNumIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdNumIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.unsolicitedSipCount === null
                            ? '0'
                            : row.unsolicitedSipCount}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewBdNumIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdNumIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numIdeaOrProposal === null
                            ? '0'
                            : row.numIdeaOrProposal}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewBdNumAcquired${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdNumAcquired-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numAcquired === null ? '0' : row.numAcquired}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewBdNumPaperCommission${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdNumPaperCommission-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {' '}
                          {row.numPaperCommission === null
                            ? '0'
                            : row.numPaperCommission}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewBdNumSubmissionAndReview${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdNumSubmissionAndReview-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numSubmissionAndReview === null
                            ? '0'
                            : row.numSubmissionAndReview}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewBdNumProduction${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdNumProduction-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numProduction === null
                            ? '0'
                            : row.numProduction}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewBdNumPublishedFY${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdNumPublishedFY-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numPublishedFy === null
                            ? '0'
                            : row.numPublishedFy}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewBdNumPublished${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdNumPublished-${index}`}
                        align='left'
                        className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numPublished === null ? '0' : row.numPublished}
                        </  >
                      </StyledBodyCellPurple>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewBdOAIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdOAIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.oaSolicitedSipCount === null
                            ? '0'
                            : row.oaSolicitedSipCount}
                        </  >
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewBdOAIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdOAIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.oaUnsolicitedSipCount === null
                            ? '0'
                            : row.oaUnsolicitedSipCount}
                        </  >
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewBdOAIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdOAIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numOA_IdeaOrProposal === null
                            ? '0'
                            : row.numOA_IdeaOrProposal}
                        </  >
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewBdOAAcquired${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdOAAcquired-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numOA_Acquired === null
                            ? '0'
                            : row.numOA_Acquired}
                        </  >
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewBdOAPaperCommission${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdOAPaperCommission-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numOA_PaperCommission === null
                            ? '0'
                            : row.numOA_PaperCommission}
                        </  >
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewBdOASubmissionAndReview${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdOASubmissionAndReview-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numOA_SubmissionAndReview === null
                            ? '0'
                            : row.numOA_SubmissionAndReview}
                        </  >
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewBdOAProduction${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdOAProduction-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numOA_Production === null
                            ? '0'
                            : row.numOA_Production}
                        </  >
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewBdOAPublishedFY${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdOAPublishedFY-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numOA_Published_Fy === null
                            ? '0'
                            : row.numOA_Published_Fy}
                        </  >
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewBdOAPublished${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-BdOAPublished-${index}`}
                        align='left'
                        className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                      >
                        <  >
                          {row.numOA_Published === null
                            ? '0'
                            : row.numOA_Published}
                        </  >
                      </StyledBodyCellGreen>
                    </StyledTableRow>
                  );
                }
                return (
                  <StyledTableRow
                    sx={{
                      height: '64px'
                    }}
                  >
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewBdBusinessDivision${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdBusinessDivision-${index}`}
                      align='left'
                      sx={{
                        maxWidth: '150px',
                        position: 'sticky',
                        left: 0,
                        zIndex: 6,
                        backgroundColor: '#F1F3F5',
                      }}
                      className='RightBorder'
                    >
                      <DisplayTooltip ifTotal={row.businessDivision} />
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewBdNumIdeaOrProposal${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdNumIdeaOrProposal-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.solicitedSipCount === null
                        ? '0'
                        : row.solicitedSipCount}
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewBdNumIdeaOrProposal${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdNumIdeaOrProposal-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.unsolicitedSipCount === null
                        ? '0'
                        : row.unsolicitedSipCount}
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewBdNumIdeaOrProposal${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdNumIdeaOrProposal-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numIdeaOrProposal === null
                        ? '0'
                        : row.numIdeaOrProposal}
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewBdNumAcquired${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdNumAcquired-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numAcquired === null ? '0' : row.numAcquired}
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewBdNumPaperCommission${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdNumPaperCommission-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numPaperCommission === null
                        ? '0'
                        : row.numPaperCommission}
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewBdNumSubmissionAndReview${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdNumSubmissionAndReview-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numSubmissionAndReview === null
                        ? '0'
                        : row.numSubmissionAndReview}
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewBdNumProduction${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdNumProduction-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numProduction === null ? '0' : row.numProduction}
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewBdNumPublishedFY${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdNumPublishedFY-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numPublishedFy === null ? '0' : row.numPublishedFy}
                    </StyledBodyCellPurple>
                    <StyledBodyCellPurple
                      id={`reportPipelineOverviewBdNumPublished${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdNumPublished-${index}`}
                      align='left'
                      className='RightBorder ReportNumberTextColor'
                    >
                      {row.numPublished === null ? '0' : row.numPublished}
                    </StyledBodyCellPurple>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewBdOAIdeaOrProposal${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdOAIdeaOrProposal-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.oaSolicitedSipCount === null
                        ? '0'
                        : row.oaSolicitedSipCount}
                    </StyledBodyCellGreen>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewBdOAIdeaOrProposal${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdOAIdeaOrProposal-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.oaUnsolicitedSipCount === null
                        ? '0'
                        : row.oaUnsolicitedSipCount}
                    </StyledBodyCellGreen>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewBdOAIdeaOrProposal${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdOAIdeaOrProposal-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numOA_IdeaOrProposal === null
                        ? '0'
                        : row.numOA_IdeaOrProposal}
                    </StyledBodyCellGreen>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewBdOAAcquired${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdOAAcquired-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numOA_Acquired === null ? '0' : row.numOA_Acquired}
                    </StyledBodyCellGreen>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewBdOAPaperCommission${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdOAPaperCommission-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numOA_PaperCommission === null
                        ? '0'
                        : row.numOA_PaperCommission}
                    </StyledBodyCellGreen>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewBdOASubmmissionAndReview${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdOASubmmissionAndReview-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numOA_SubmissionAndReview === null
                        ? '0'
                        : row.numOA_SubmissionAndReview}
                    </StyledBodyCellGreen>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewBdOAProduction${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdOAProduction-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numOA_Production === null
                        ? '0'
                        : row.numOA_Production}
                    </StyledBodyCellGreen>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewBdOAPublishedFY${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdOAPublishedFY-${index}`}
                      align='left'
                      className='NoBorder ReportNumberTextColor'
                    >
                      {row.numOA_Published_Fy === null
                        ? '0'
                        : row.numOA_Published_Fy}
                    </StyledBodyCellGreen>
                    <StyledBodyCellGreen
                      id={`reportPipelineOverviewBdOAPublished${index}`}
                      data-selenium-id={`Report-PipelineSubjectTable-BdOAPublished-${index}`}
                      align='left'
                      className='RightBorder ReportNumberTextColor'
                    >
                      {row.numOA_Published === null
                        ? '0'
                        : row.numOA_Published}
                    </StyledBodyCellGreen>
                  </StyledTableRow>
                );
              })
              : !loading && (
                <ReportNoData id='reportSiOverviewSGNoResultTableCell' />
              )}
            <TableRow></TableRow>
          </TableBody>
        </Table>
        {loading && (
          <Box
            data-selenium-id='Report_Si_Overview-LoadingBox'
            sx={{
              width: 'auto',
              marginTop: '150px',
              marginLeft: '50%',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </TableContainer>
    </TableBox2>
  );
}

//@ts-check
import {axios_instance} from "@/utils/axios_instance";
import {backendCode2FrontendMsgId} from "@/utils/commonUtils";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {
    GET_PROMOTE_LEADGE,
    UPDATE_MARKETINFO,
    UPDATE_PROMOTE_STATE
} from "@/actions/SIMT-SI/SpecialIssue/SIAction";

/**
 * @param {import("../../../pages/SIPage/SIDetailPage/market-section").SiMarketingDtoRequest} marketInfo
 * @param {boolean} firstOpenMarketSection
 * @param {'Yes'|'No'} [readyForPromotion]
 */
export function UpdateMarketInfo(marketInfo, firstOpenMarketSection=false,readyForPromotion=undefined) {
  /**
    * @param {import("redux").Dispatch} dispatch
    */
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/special-issues/${marketInfo.siCode}/marketing-data`;
            const commonAxiosRequestConfig={
                ...(readyForPromotion?{params:{readyForPromotion}}:{}),
                headers: {
                    authorization: localStorage.getItem('authorization')??'',
                },
            };
            const/**@type {[url: string, data?: unknown, config?: import("axios").AxiosRequestConfig<unknown> | undefined]}*/commonProps=[url, marketInfo, commonAxiosRequestConfig];
            const res = firstOpenMarketSection
            ? (await axios_instance.post(...commonProps))
            : (await axios_instance.put(...commonProps));
            if (res.data.code === 200) {
              if(readyForPromotion)
                dispatch({type: UPDATE_PROMOTE_STATE, data: 'success'});
              else
                dispatch({type: UPDATE_MARKETINFO, data: 'success'});
            } else {
              if(readyForPromotion)
                dispatch({type: UPDATE_PROMOTE_STATE, data: 'error'});
              else{
                dispatch({type: UPDATE_MARKETINFO, data: 'error'});
                const errMsgId = backendCode2FrontendMsgId(res.data.code);
                if (errMsgId) {
                    dispatch(setSnackbarMessageAndOpen(errMsgId, {}, SEVERITIES.error));
                }
              }
            }
        } catch (e) {
            if(readyForPromotion)
              dispatch({type: UPDATE_PROMOTE_STATE, data: 'error'});
            else
              dispatch({type: UPDATE_MARKETINFO, data: 'error'});
        }
    };
}

// export function UpdatePromoteState(siCode, state) {
//     return async dispatch => {
//         try {
//             const url = '/api/v1/marketing/promotions/ready';
//             const res = await axios_instance.post(url, null, {
//                 params: {
//                     siCode: siCode,
//                     readyForPromotion: state,
//                 },
//                 headers: {
//                     authorization: localStorage.getItem('authorization'),
//                 },
//             });

//             if (res.data.code === 200) {
//                 dispatch({type: UPDATE_PROMOTE_STATE, data: 'success'});
//             } else {
//                 dispatch({type: UPDATE_PROMOTE_STATE, data: 'error'});
//             }
//         } catch (e) {
//             dispatch({type: UPDATE_PROMOTE_STATE, data: 'error'});
//         }
//     };
// }

/**
 * @param {string} siCode
 */
export function getPromoteLeadGE(siCode) {
    /**
    * @param {import("redux").Dispatch} dispatch
    */
    return async dispatch => {
        try {
            const url = '/api/v1/si-service/special-issues';
            const res = await axios_instance.get(url, {
                params: {
                    siCode: siCode,
                },
                headers: {
                    authorization: localStorage.getItem('authorization')??'',
                },
            });

            if (res.data.code === 200) {
                dispatch({type: GET_PROMOTE_LEADGE, data: res.data.data});
            } else {
                dispatch({type: GET_PROMOTE_LEADGE, data: 'error'});
            }
        } catch (e) {
            dispatch({type: GET_PROMOTE_LEADGE, data: 'error'});
        }
    };
}

// export function marketIssnCheck(ISSN) {
//     return async dispatch => {
//         try {
//             const url = '/marketing/checkISSN?ISSN=' + ISSN;
//             const res = await axios_instance.get(url, {
//                 headers: {
//                     authorization: localStorage.getItem('authorization'),
//                 },
//             });

//             if (res.data.code === 200) {
//                 dispatch({type: MARKETISSN_CHECK, data: true});
//             } else {
//                 dispatch({type: MARKETISSN_CHECK, data: false});
//             }
//         } catch (e) {
//             dispatch({type: MARKETISSN_CHECK, data: false});
//         }
//     };
// }
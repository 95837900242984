import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChartDataWithStatus } from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import { APIKeyMap } from '../config';
import { formatAPIData2 } from '@/pages/Report/chartUtils';
import { SampleCEData } from './d.js';
import { getAllGroups } from './helper.js';

export default function useGetOriginDataCEHook(
  // isCEChart,
  catagoryAttributeName,
  url,
  reduxTitle,
  selectedDate
) {
  const dispatch = useDispatch();

  let { reportDataAPI } = useSelector(state => {
    return {
      reportDataAPI: state.ReportDiagram[reduxTitle],
    };
  });

  // reportDataAPI = SampleCEData;
  // console.log('==reportDataAPI==', reportDataAPI);

  let loading = null,
    error = null,
    data = null;

  if (typeof reportDataAPI !== 'undefined') {
    ({ loading, error, data } = reportDataAPI);
  }

  const dataForChart = useMemo(() => {
    if (data !== null) {
      return formatAPIData2(data, APIKeyMap, catagoryAttributeName);
    }
    return [];
  }, [data]);

  // console.log('==dataForChart==', dataForChart);

  const allGroups = useMemo(() => {
    return getAllGroups(dataForChart);
  }, [dataForChart]);

  // console.log('==allGroups==', allGroups);

  useEffect(() => {
    if (typeof reportDataAPI === 'undefined' && selectedDate !== null) {
      dispatch(
        getChartDataWithStatus(
          url,
          reduxTitle,
          selectedDate,
          catagoryAttributeName
        )
      );
    }
  }, [reportDataAPI, selectedDate, url, reduxTitle]);

  const catagoriesFullList = useMemo(() => {
    if (dataForChart) {
      return dataForChart.map(record => record[catagoryAttributeName]);
    }
    return [];
  }, [dataForChart, catagoryAttributeName]);

  return [dataForChart, catagoriesFullList, allGroups, loading, error];
}

/* eslint-disable indent */
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useRef, useState} from 'react';
import {getUTCToDate,} from '@/utils/commonUtils';
import {Box, Button, Collapse, Dialog, Popover, Stack,} from '@mui/material';
import SIdetailMarketing from '@/assets/SIdetailmarketing.svg';
import {
  CustomLink,
  CustomList,
  MarketInfoBlock,
  MarketPromoteBlock,
  OneCustomList,
} from './components/components';
import moment from 'moment/moment';
import CustomInput from './components/CustomInput';
import CustomDiv from './components/CustomDiv';
import BasicModal from './components/BasicModal';
import SaveAndCancelButton from '@/componentsphase2/SIDetail/SaveAndCancelButton';
import {InlineRichTextArea, richToPlain} from '@/componentsphase2/RichText/RichText';
import CustomRichInputList from './components/CustomRichInputList';
import OneCustomRichInputList from './components/OneCustomRichInputList';
import MyLocalStorage from '@/utils/MyLocalStorage';
import ModelInputList from './CompetitiveJournals/ModalInputList';
import {setSnackbarMessageAndOpen} from '@/actions/SnackbarAction';
import {SEVERITIES} from '@/modules/ConfirmSnackBar/CommonSnackBar';
import {loadNoteLinkConfig} from "@/actions/SIMT-User/Config";
import {getSIDetail} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueDetail";
import {UpdateMarketInfo} from "@/actions/SIMT-SI/SpecialIssue/SiMarketing";
import MarketTitle from "@/pages/SIPage/SIDetailPage/market-section/components/MarketTitle";
import {RichInputList} from "@/componentsphase2/InputList";

const othersPlaceholder =
    'You can input free texts including but not limited to the following if there are any: \n1. Abstract Submission Deadline \n2. Specific Manuscript Requirements for this SI  \n3. Pre-submission Inquiry please contact: XXX (Title, Name & affiliation) \n4. Overseeing Editor [if there is any]: Title, Name & affiliation';
const submissionInstructionsPlaceholder =
    'Please refer to the Author Guidelines to prepare your manuscript. When submitting your manuscript, please answer the question: "Is this submission for a special issue?" by selecting the special issue title from the drop-down list.';

const isEmpty = value => {
  return (
      value === undefined ||
      value === null ||
      value === '' ||
      value === [] ||
      value === {} ||
      (typeof value === 'string' && !value.trim().length)
  );
};
const removeGapFromArr = arr => {
  let newArr = arr;
  while (newArr.indexOf('') !== -1) {
    newArr.splice(newArr.indexOf(''), 1);
  }
  return newArr;
};
const myTrimItemFields = item => {
  if (typeof item === 'string') {
    let tempItem = item.trim();
    return tempItem.replaceAll(/\n+/g, '\n').replaceAll(/[ ]+/g, ' ');
  } else return '';
};
const myTrimStrArr = strArr => {
  let newStrArr = [];
  for (const element of strArr) {
    newStrArr.push(myTrimItemFields(element));
  }
  return newStrArr;
};

function encode64(text) {
  return btoa(String.fromCharCode(...new TextEncoder().encode(text)));
}

function encoder64(arr) {
  return arr.map((item, index) => {
    return encode64(item);
  });
}

const isSaveLater = localSave => {
  if (localSave == null) {
    return false;
  } else {
    return localSave.saveLaterRedux;
  }
};

function removePTags(str) {
  if (str?.startsWith('<p>') && str?.endsWith('</p>')) {
    return str.slice(3, -4);
  }
  return str;
}

const ArrToStr = arr => {
  let str = '';
  for (let i = 0; i < arr.length - 1; i++) {
    str = str + removePTags(arr[i]) + '; ';
  }
  str += removePTags(arr[arr.length - 1]);
  return str;
};

const ChangeNullToE = value => {
  return value === null ? '' : value;
};

const GEArrToGEStrArr = GEArr => {
  const GEStrArr = [];
  for (let i = 0; i < GEArr.length; i++) {
    if (i >= 3) {
      break;
    }

    let GEStr = `${ChangeNullToE(GEArr[i].title)} ${ChangeNullToE(
        GEArr[i].firstName
    )} ${ChangeNullToE(GEArr[i].lastName)}`;
    if (ChangeNullToE(GEArr[i].institute) !== '')
      GEStr = `${GEStr}, ${ChangeNullToE(GEArr[i].institute)}`;
    if (ChangeNullToE(GEArr[i].country) !== '')
      GEStr = `${GEStr}, ${ChangeNullToE(GEArr[i].country)}`;

    GEStrArr.push(GEStr);
  }
  return GEStrArr;
};

const ValueTypeChangeOtoS = (object) =>{
  let string = '';
  for(let i=0;i<object.length;i++){
    string+=object[i];
    if(i!==object.length-1) string+='; ';
  }
  return string;
};

const TextAreaLimitLength = 10000;
const referencesInputLimit = 100000;
const siAimsAndScopeMinLength = 500;
const siTopicsInputLimit = 1000;
const siKeywordsInputLimit = 500;
const siKeywordsMaxItem = 3;
const siTopicsMaxItem = 3;
/**
 * @param {import(".").MarketSectionProps} props
 * @returns
 */
export default function MarketSection(props) {
  const {
    addMarketSection,
    detailInfo,
    firstOpenMarketSection,
    setAddMarketSection,
  } = props;
  const dispatch = useDispatch();
  const selector = state => {
    return {
      updateMarketInfoResult: state.SI.updateMarketInfoResult,
      updateMarketInfoMonitor: state.SI.updateMarketInfoMonitor,
      updatePromoteState: state.SI.updatePromoteState,
      updatePromoteStateMonitor: state.SI.updatePromoteStateMonitor,
      getPromoteLeadGEResult: state.SI.getPromoteLeadGEResult,
      getPromoteLeadGEResultMonitor: state.SI.getPromoteLeadGEResultMonitor,
      noteLinkList: state.SystemConfig.NoteLinkList,
      userId: state.User.userId,
    };
  };
  const {
    updateMarketInfoResult,
    updateMarketInfoMonitor,
    updatePromoteState,
    updatePromoteStateMonitor,
    getPromoteLeadGEResult,
    getPromoteLeadGEResultMonitor,
    noteLinkList,
    userId,
  } = useSelector(selector);
  
  const [readyForPromotionTurnOff,setReadyForPromotionTurnOff] = useState(false);
  const [clickSave, setClickSave] = useState(false);
  const [journalSIPageLink, setJournalSIPageLink] = useState('');
  const [specialIssueHTMLLink, setSpecialIssueHTMLLink] = useState('');
  const [siHTMLLink, setsiHTMLLink] = useState('');
  const [siAimsAndScope, setSIAimsAndScope] = useState('');
  const [siTopics, setSITopics] = useState(['', '', '']);
  const [siKeywords, setSIKeywords] = useState(['', '', '']);
  const [competitiveTitleISSNs, setCompetitiveTitleISSNs] = useState(['']);
  const [references, setReferences] = useState(['']);
  const [others, setOthers] = useState('');
  const [journalSIPageLinkFormatError, setJournalSIPageLinkFormatError] =
    useState(false);
  const [specialIssueHTMLLinkFormatError, setSpecialIssueHTMLLinkFormatError] =
    useState(false);
  const [siAimsAndScopeExceedError, setSIAimsAndScopeExceedError] =
    useState(false);
  const [siTopicsError, setSITopicsError] = useState(false);
  const [siKeywordsError, setSIKeywordsError] = useState(false);
  const [competitiveTitleISSNsError, setCompetitiveTitleISSNsError] =
    useState(false);
  const [referencesError, setReferencesError] = useState(false);
  const [othersExceedError, setOthersExceedError] = useState(false);
  const [error, setError] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false); // 初始值必须是false
  const [state, setState] = useState('read-operate');

  const [marketData, setMarketData] = useState({
    journalSIPageLink: '',
    specialIssueHTMLLink: '',
    siAimsAndScope: '',
    siTopics: ['', '', ''],
    siKeywords: '',
    competitiveTitleISSNs: '',
    references: [''],
    others: '',
  });
  const [isFold, setIsFold] = useState(true);
  const [promoteData, setPromoteData] = useState({
    siName: '',
    LeadGE: ['', '', ''],
    submissionDeadLine: '',
    specialIssueHtmlLink: '',
    siTopics: ['', '', ''],
  });
  const [readyForPromotion, setReadyForPromotion] = useState(false);
  const [promoteDialogOpen, setPromoteDialogOpen] = useState(false);
  const [submissionInstructions, setSubmissionInstructions] = useState(
    submissionInstructionsPlaceholder
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);

  const [
    submissionInstructionsExceedError,
    setSubmissionInstructionsExceedError,
  ] = useState(false);
  const projectCodeKey = `market-${userId}-${detailInfo.generalInformation[0].projectCode}`;
  const [saveLater, setSaveLater] = useState(
      MyLocalStorage.getItem(projectCodeKey)
  );
  const [isUpdate, setIsUpdate] = useState(false);
  const [first, setFirst] = useState(true);
  const [customCheckPatternErrList, setCustomCheckPatternErrList] = useState([
    false,
  ]);
  const [duplicateItemList, setDuplicateItemList] = useState([[]]);

  const isError = () => {
    return (
      journalSIPageLinkFormatError ||
      specialIssueHTMLLinkFormatError ||
      siAimsAndScopeExceedError ||
      siTopicsError ||
      siKeywordsError ||
      competitiveTitleISSNsError ||
      referencesError ||
      othersExceedError ||
      isEmpty(journalSIPageLink) ||
      isEmpty(siAimsAndScope) || richToPlain(siAimsAndScope).length < siAimsAndScopeMinLength ||
      isEmpty(submissionInstructions) ||
      submissionInstructionsExceedError
    );
  };



  const handleSaveClick = () => {
    setIsClick(true);
    setClickSave(true);
    const localSave = MyLocalStorage.getItem(projectCodeKey);
    if (localSave) {
      MyLocalStorage.removeItem(projectCodeKey);
      MyLocalStorage.removeItem(`${projectCodeKey}Local`);
    }

    if (!error) {
      setsiHTMLLink(specialIssueHTMLLink.trim());
      const marketInfo = {
        siCode: detailInfo.generalInformation[0].projectCode,
        journalSiPageLink: journalSIPageLink.trim(),
        specialIssueHtmlLink: specialIssueHTMLLink.trim(),
        siAimsAndScope: encode64(myTrimItemFields(siAimsAndScope)),
        siTopics: encoder64(myTrimStrArr(removeGapFromArr(siTopics))),
        siKeyWords: encoder64(myTrimStrArr(removeGapFromArr(siKeywords))),
        competitiveTitleIssns: removeGapFromArr(competitiveTitleISSNs),
        submissionInstructions: encode64(
          myTrimItemFields(submissionInstructions)
        ),
        references: encoder64(removeGapFromArr(references)),
        others: encode64(myTrimItemFields(others)),
      };
      if(/^[^a-zA-Z]+$/g.test(marketInfo.competitiveTitleIssns)){
        dispatch(setSnackbarMessageAndOpen('Please update the value of Competitive Title & ISSNs', {}, SEVERITIES.error));
        return;
      }
      setIsLoading(true);
      async function req() {
        await dispatch(UpdateMarketInfo(marketInfo, firstOpenMarketSection));
      }

      req().then(_r => {
        setTimeout(() => {
          setIsLoading(false);
          setIsClick(false);
        }, 2000);
      });
      setIsEdit(false);
    }
  };

  const handleSaveLater = () => {
    setSaveLater(true);
    setIsEdit(false);
    const marketInfo = {
      journalSiPageLink: journalSIPageLink.trim(),
      specialIssueHtmlLink: specialIssueHTMLLink.trim(),
      siAimsAndScope: myTrimItemFields(siAimsAndScope),
      siTopics: myTrimStrArr(siTopics),
      siKeywords: myTrimStrArr(siKeywords),
      competitiveTitleIssns: competitiveTitleISSNs,
      submissionInstructions: myTrimItemFields(submissionInstructions),
      references: references,
      others: myTrimItemFields(others),
      saveLaterRedux: true,
    };
    MyLocalStorage.setItem(projectCodeKey, marketInfo);
    MyLocalStorage.setItem(`${projectCodeKey}Local`, detailInfo.siMarketing[0]);
  };

  const handleCancelClick = () => {
    setIsEdit(false);
    const localSave = MyLocalStorage.getItem(projectCodeKey);
    if (
      detailInfo.siMarketing !== undefined &&
      detailInfo.siMarketing.length > 0
    ) {
      setCompetitiveTitleISSNs(detailInfo.siMarketing[0].competitiveTitleIssns);
      setJournalSIPageLink(detailInfo.siMarketing[0].journalSiPageLink);
      setOthers(detailInfo.siMarketing[0].others);
      setReferences(
        detailInfo.siMarketing[0].references.length > 0
          ? detailInfo.siMarketing[0].references
          : ['']
      );
      setSIAimsAndScope(detailInfo.siMarketing[0].siAimsAndScope);
      setSIKeywords(detailInfo.siMarketing[0].siKeywords);
      setSITopics(detailInfo.siMarketing[0].siTopics);
      setSpecialIssueHTMLLink(detailInfo.siMarketing[0].specialIssueHtmlLink);
      setsiHTMLLink(detailInfo.siMarketing[0].specialIssueHtmlLink);
      setSubmissionInstructions(
        detailInfo.siMarketing[0].submissionInstructions
      );
      setReadyForPromotion(
        detailInfo.siMarketing[0].readyForPromotion === 'Yes'
      );
    } else {
      setAddMarketSection(false);
    }
    if (localSave) {
      MyLocalStorage.removeItem(projectCodeKey);
      MyLocalStorage.removeItem(`${projectCodeKey}Local`);
    }
  };

  const isEmptySIHtmlLink3 = () => {
    return siHTMLLink === '';
  };

  useEffect(() => {
    setError(isError());
  });

  useEffect(() => {
    if (!firstOpenMarketSection) {
      setIsFold(false);
      setIsEdit(false);
    }
    if (firstOpenMarketSection && !MyLocalStorage.getItem(projectCodeKey)) {
      // 是第一次加入market且没有暂存自动变为编辑模式
      setIsEdit(addMarketSection);
    }
  }, [addMarketSection]);

  const update = () => {
    if (MyLocalStorage.getItem(`${projectCodeKey}Local`)) {
      const localSave = MyLocalStorage.getItem(`${projectCodeKey}Local`);
      let flag =
        localSave.journalSiPageLink ===
        detailInfo.siMarketing[0].journalSiPageLink &&
        localSave.specialIssueHtmlLink ===
        detailInfo.siMarketing[0].specialIssueHtmlLink &&
        localSave.siAimsAndScope === detailInfo.siMarketing[0].siAimsAndScope &&
        localSave.siTopics.toString() ===
        detailInfo.siMarketing[0].siTopics.toString() &&
        localSave.siKeywords.toString() ===
        detailInfo.siMarketing[0].siKeywords.toString() &&
        localSave.competitiveTitleIssns.toString() ===
        detailInfo.siMarketing[0].competitiveTitleIssns.toString() &&
        localSave.references.toString() ===
        detailInfo.siMarketing[0].references.toString() &&
        localSave.submissionInstructions ===
        detailInfo.siMarketing[0].submissionInstructions &&
        localSave.others === detailInfo.siMarketing[0].others;
      setIsUpdate(!flag);
    } else {
      if (detailInfo.siMarketing.length > 0) {
        setIsUpdate(true);
      }
    }
  };

  useEffect(() => {
    const localSave = MyLocalStorage.getItem(projectCodeKey);
    if (
      detailInfo.siMarketing !== undefined &&
      detailInfo.siMarketing.length > 0 &&
      !isSaveLater(localSave)
    ) {
      setCompetitiveTitleISSNs(detailInfo.siMarketing[0].competitiveTitleIssns);
      setJournalSIPageLink(detailInfo.siMarketing[0].journalSiPageLink);
      setOthers(detailInfo.siMarketing[0].others);
      setReferences(
        detailInfo.siMarketing[0].references.length > 0
          ? detailInfo.siMarketing[0].references
          : ['']
      );
      setSIAimsAndScope(detailInfo.siMarketing[0].siAimsAndScope);
      setSIKeywords(detailInfo.siMarketing[0].siKeywords);
      setSITopics(detailInfo.siMarketing[0].siTopics);
      setSpecialIssueHTMLLink(detailInfo.siMarketing[0].specialIssueHtmlLink);
      if (detailInfo.siMarketing[0].submissionInstructions) {
        setSubmissionInstructions(
          detailInfo.siMarketing[0].submissionInstructions
        );
      }
      
      setReadyForPromotion(detailInfo.siMarketing[0].readyForPromotion === 'Yes');
      
      setsiHTMLLink(detailInfo.siMarketing[0].specialIssueHtmlLink);
      setMarketData({
        journalSIPageLink: detailInfo.siMarketing[0].journalSiPageLink,
        specialIssueHTMLLink: detailInfo.siMarketing[0].specialIssueHtmlLink,
        siAimsAndScope: detailInfo.siMarketing[0].siAimsAndScope,
        siTopics: detailInfo.siMarketing[0].siTopics,
        siKeywords: ArrToStr(detailInfo.siMarketing[0].siKeywords),
        competitiveTitleISSNs: ArrToStr(
          detailInfo.siMarketing[0].competitiveTitleIssns
        ),
        references: detailInfo.siMarketing[0].references,
        submissionInstructions:
          detailInfo.siMarketing[0].submissionInstructions,
        others: detailInfo.siMarketing[0].others,
      });
    }
    
    if (isSaveLater(localSave)) {
      update();
      setCompetitiveTitleISSNs(localSave?.competitiveTitleIssns);
      setJournalSIPageLink(localSave?.journalSiPageLink);
      setOthers(localSave?.others);
      setReferences(
        localSave?.references.length > 0 ? localSave?.references : ['']
      );
      setSIAimsAndScope(localSave?.siAimsAndScope);
      setSIKeywords(localSave?.siKeywords);
      setSITopics(localSave?.siTopics);
      setSpecialIssueHTMLLink(localSave?.specialIssueHtmlLink);
      setsiHTMLLink(localSave?.specialIssueHtmlLink);
      setSubmissionInstructions(localSave?.submissionInstructions);
      if (
        detailInfo.siMarketing !== undefined &&
        detailInfo.siMarketing.length > 0
      ) {
        setReadyForPromotion(
          detailInfo.siMarketing[0].readyForPromotion === 'Yes'
        );
        setMarketData({
          journalSIPageLink: detailInfo.siMarketing[0].journalSiPageLink,
          specialIssueHTMLLink: detailInfo.siMarketing[0].specialIssueHtmlLink,
          siAimsAndScope: detailInfo.siMarketing[0].siAimsAndScope,
          siTopics: detailInfo.siMarketing[0].siTopics,
          siKeywords: ArrToStr(detailInfo.siMarketing[0].siKeywords),
          competitiveTitleISSNs: ArrToStr(
            detailInfo.siMarketing[0].competitiveTitleIssns
          ),
          references: detailInfo.siMarketing[0].references,
          submissionInstructions:
            detailInfo.siMarketing[0].submissionInstructions,
          others: detailInfo.siMarketing[0].others,
        });
      }
    }
    if (
      detailInfo.generalInformation[0] !== undefined &&
      detailInfo.generalInformation[0] !== null
    ) {
      if (
        detailInfo.generalInformation[0].paperCommissionMethod !== 'Hybrid' &&
        detailInfo.generalInformation[0].paperCommissionMethod !== 'Open Call'
      ) {
        setState('read-only');
      } else {
        setState('read-operate');
      }
    }
  }, [detailInfo]);

  useEffect(() => {
    if (
      !first &&
      getPromoteLeadGEResult !== [] &&
      getPromoteLeadGEResult !== 'error' &&
      getPromoteLeadGEResult !== null &&
      detailInfo.siMarketing !== undefined &&
      detailInfo.siMarketing.length > 0
    ) {
      setPromoteData({
        LeadGE: GEArrToGEStrArr(getPromoteLeadGEResult),
        siName: detailInfo.generalInformation[0].specialIssueTitle,
        submissionDeadLine:
          detailInfo.generalInformation[0].submissionDeadline === null ||
            detailInfo.generalInformation[0].submissionDeadline === undefined ||
            detailInfo.generalInformation[0].submissionDeadline === ''
            ? ''
            : moment(
              getUTCToDate(
                detailInfo.generalInformation[0].submissionDeadline
              )
            ).format('dddd, DD MMMM YYYY'),
        specialIssueHtmlLink: detailInfo.siMarketing[0].specialIssueHtmlLink,
        siTopics: detailInfo.siMarketing[0].siTopics,
      });
      setPromoteDialogOpen(true);
    }
    if (first) {
      setFirst(false);
    }
  }, [getPromoteLeadGEResultMonitor]);

  useEffect(() => {
    if (updateMarketInfoResult === 'success') {
      async function fetchData() {
        setTimeout(() => {
          dispatch(getSIDetail(detailInfo.generalInformation[0].projectCode));
        }, 2000);
      }
      fetchData();
    }
  }, [updateMarketInfoMonitor]);

  useEffect(() => {
    if (updatePromoteState === 'success') {
      setPromoteDialogOpen(false);
      // setReadyForPromotion(!readyForPromotion);
      async function fetchData() {
        setTimeout(() => {
          dispatch(getSIDetail(detailInfo.generalInformation[0].projectCode));
          setIsEdit(false);
        }, 2000);
      }
      fetchData();
    }
  }, [updatePromoteStateMonitor]);

  useEffect(() => {
    let CustomCheckArr = [];
    for (const element of competitiveTitleISSNs) {
      CustomCheckArr.push(false);
    }
    setCustomCheckPatternErrList(CustomCheckArr);
    let DuplicateCheckArr = [];
    for (const element of competitiveTitleISSNs) {
      DuplicateCheckArr.push([]);
    }
    setDuplicateItemList(DuplicateCheckArr);
  }, [competitiveTitleISSNs]);

  useEffect(() => {
    if (
      detailInfo.siMarketing !== undefined &&
      detailInfo.siMarketing.length > 0 &&
      isEdit &&
      !firstOpenMarketSection
    ) {
      setIsEdit(false);
    }
  }, [marketData]);

  useEffect(() => {
    dispatch(loadNoteLinkConfig());
  }, []);

  return (
    <Box
      sx={{ background: '#FFFFFF', marginTop: '33px' }}
      minWidth='500px'
      data-selenium-id='SIDetail_MarketSection-ContainerBox3'
    >
      <Box data-selenium-id='SIDetail_MarketSection-TitleBox'>
        <MarketTitle
            isEdit={isEdit}
        setIsEdit={setIsEdit}
        isFold={isFold}
        setIsFold={setIsFold}
        clickSave={clickSave}
        setClickSave={setClickSave}
        readyForPromotion={readyForPromotion}
        setReadyForPromotion={setReadyForPromotion}
        readyForPromotionTurnOff={readyForPromotionTurnOff}
        setReadyForPromotionTurnOff={setReadyForPromotionTurnOff}
        saveLater={saveLater}
        setSaveLater={setSaveLater}
        handleCancelClick={handleCancelClick}
        detailInfo={detailInfo}
        firstOpenMarketSection={firstOpenMarketSection}
        state={state}
        isEmptySIHtmlLink3={isEmptySIHtmlLink3}
        />
      </Box>
      <Collapse in={isFold} data-selenium-id=''>
        <Box
          sx={{ marginTop: '16px' }}
          data-selenium-id='SIDetail_MarketSection-ContainerBox4'
        >
          {/*savelater时展示*/}
          {!isEdit && saveLater && !isUpdate ? (
            <Box
              data-selenium-id='SIDetail_MarketSection-Zancun'
              sx={{
                width: '1090px',
                height: '135px',
                padding: '16px 32px 16px 24px',
                borderRadius: '5px',
                background: '#4C81EB',
                marginTop: '16px',
                marginBottom: '16px',
              }}
            >
              <Stack
                direction='row'
                justifyContent='flex-start'
                alignItems='flex-start'
                spacing='12px'
              >
                <Box>
                  <SIdetailMarketing />
                </Box>
                <Stack
                  direction='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  spacing='12px'
                >
                  <Box
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '17px',
                      letterSpacing: '0em',
                      color: '#FFFFFF',
                    }}
                  >
                    {'Some information need you to complete'}
                  </Box>
                  <Box
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '17px',
                      letterSpacing: '0em',
                      color: '#FFFFFF',
                    }}
                  >
                    {
                      'There are mandatory fields unfilled in this section, so the information cannot be uploaded.'
                    }
                    <br />
                    {
                      'Please complete all the mandatory fields before clicking “save” button to upload.'
                    }
                  </Box>
                  <Stack
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                    spacing='2px'
                  >
                    <Button
                      disableElevation
                      disableRipple
                      sx={{
                        color: '#FFFFFF',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '20px',
                        textTransform: 'none',
                        padding: '8px 8px 8px 0px',
                        '&:hover': {
                          background: '#4C81EB',
                        },
                      }}
                      onClick={() => {
                        setSaveLater(false);
                        setIsEdit(true);
                      }}
                    >
                      Keep editing
                    </Button>
                    <Button
                      disableElevation
                      disableRipple
                      onClick={() => setOpenDialog(true)}
                      sx={{
                        color: '#FFFFFF',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '20px',
                        textTransform: 'none',
                        padding: '8px 8px',
                        '&:hover': {
                          background: '#4C81EB',
                        },
                      }}
                    >
                      Delete
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
              <Dialog
                open={openDialog}
                sx={{
                  '& .MuiDialog-paper': {
                    boxShadow: 'none',
                  },
                  '& .MuiBackdrop-root': {
                    background: ' rgba(17, 61,149, 0.5)',
                    backdropFilter: 'blur(4px)',
                  },
                }}
              >
                <Box
                  sx={{
                    width: '600px',
                    height: '130px',
                    background: '#FFFFFF',
                    padding: '24px 32px 20px 32px',
                  }}
                >
                  <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    spacing='28px'
                  >
                    <Box
                      sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '18px',
                        fontWeight: 600,
                        lineHeight: '23px',
                        letterSpacing: '0em',
                        color: '#262E35',
                      }}
                    >
                      Are you sure to delete?
                    </Box>
                    <Stack
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      spacing='10px'
                    >
                      <Button
                        disableRipple
                        sx={{
                          width: '70px',
                          height: '34px',
                          padding: '8px 12px',
                          background: '#FFFFFF',
                          border: '1px solid #0052CC',
                          color: '#0052CC',
                          fontFamily: 'Open Sans',
                          fontSize: '14px',
                          fontWeight: 600,
                          lineHeight: '18px',
                          letterSpacing: '0em',
                          '&:hover': {
                            color: '#6D98EE',
                            background: '#FFFFFF',
                          },
                          '&:active': {
                            color: '#6D98EE',
                            background: '#e8edfb',
                          },
                        }}
                        onClick={() => {
                          setOpenDialog(false);
                          setSaveLater(false);
                          handleCancelClick();
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        disableRipple
                        sx={{
                          width: '70px',
                          height: '34px',
                          padding: '8px 12px',
                          background: '#0052CC',
                          textTransform: 'none',
                          fontFamily: 'Open Sans',
                          fontSize: '14px',
                          fontWeight: 600,
                          lineHeight: '18px',
                          letterSpacing: '0em',
                          color: '#FFFFFF',
                          '&:hover': {
                            background: '#6d98ee',
                          },
                          '&:active': {
                            background: '#113d95',
                          },
                        }}
                        onClick={() => setOpenDialog(false)}
                      >
                        No
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              </Dialog>
            </Box>
          ) : null}
          {/*有更新时展示*/}
          {!isEdit && isUpdate && saveLater ? (
            <Box
              data-selenium-id='SIDetail_MarketSection-UpdateConflict'
              sx={{
                width: '1090px',
                height: '135px',
                padding: '16px 32px 16px 24px',
                borderRadius: '5px',
                background: '#4C81EB',
                marginTop: '16px',
                marginBottom: '16px',
              }}
            >
              <Stack
                direction='row'
                justifyContent='flex-start'
                alignItems='flex-start'
                spacing='12px'
              >
                <Box>
                  <SIdetailMarketing />
                </Box>
                <Stack
                  direction='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  spacing='12px'
                >
                  <Box
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '17px',
                      letterSpacing: '0em',
                      color: '#FFFFFF',
                    }}
                  >
                    {'What would you like to do with your draft?'}
                  </Box>
                  <Box
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '17px',
                      letterSpacing: '0em',
                      color: '#FFFFFF',
                    }}
                  >
                    {
                      'Below information in this section has been added by another user. Do you want to keep editing or delete your own draft?'
                    }
                    <br />
                    {
                      '(Please note that if you choose to keep editing and click “save” button afterwards, you will replace the current information shown here.)'
                    }
                  </Box>
                  <Stack
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                    spacing='2px'
                  >
                    <Button
                      disableElevation
                      disableRipple
                      sx={{
                        color: '#FFFFFF',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '20px',
                        textTransform: 'none',
                        padding: '8px 8px 8px 0px',
                        '&:hover': {
                          background: '#4C81EB',
                        },
                      }}
                      onClick={() => {
                        setSaveLater(false);
                        setIsEdit(true);
                      }}
                    >
                      Keep editing
                    </Button>
                    <Button
                      disableElevation
                      disableRipple
                      onClick={() => setOpenDialog1(true)}
                      sx={{
                        color: '#FFFFFF',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '20px',
                        textTransform: 'none',
                        padding: '8px 8px',
                        '&:hover': {
                          background: '#4C81EB',
                        },
                      }}
                    >
                      Delete
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
              <Dialog
                open={openDialog1}
                sx={{
                  '& .MuiDialog-paper': {
                    boxShadow: 'none',
                  },
                  '& .MuiBackdrop-root': {
                    background: ' rgba(17, 61,149, 0.5)',
                    backdropFilter: 'blur(4px)',
                  },
                }}
              >
                <Box
                  sx={{
                    width: '600px',
                    height: '130px',
                    background: '#FFFFFF',
                    padding: '24px 32px 20px 32px',
                  }}
                >
                  <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    spacing='28px'
                  >
                    <Box
                      sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '18px',
                        fontWeight: 600,
                        lineHeight: '23px',
                        letterSpacing: '0em',
                        color: '#262E35',
                      }}
                    >
                      Are you sure to delete?
                    </Box>
                    <Stack
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      spacing='10px'
                    >
                      <Button
                        disableRipple
                        sx={{
                          width: '70px',
                          height: '34px',
                          padding: '8px 12px',
                          background: '#FFFFFF',
                          border: '1px solid #0052CC',
                          color: '#0052CC',
                          fontFamily: 'Open Sans',
                          fontSize: '14px',
                          fontWeight: 600,
                          lineHeight: '18px',
                          letterSpacing: '0em',
                          '&:hover': {
                            color: '#6D98EE',
                            background: '#FFFFFF',
                          },
                          '&:active': {
                            color: '#6D98EE',
                            background: '#e8edfb',
                          },
                        }}
                        onClick={() => {
                          setOpenDialog1(false);
                          setSaveLater(false);
                          handleCancelClick();
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        disableRipple
                        sx={{
                          width: '70px',
                          height: '34px',
                          padding: '8px 12px',
                          background: '#0052CC',
                          textTransform: 'none',
                          fontFamily: 'Open Sans',
                          fontSize: '14px',
                          fontWeight: 600,
                          lineHeight: '18px',
                          letterSpacing: '0em',
                          color: '#FFFFFF',
                          '&:hover': {
                            background: '#6d98ee',
                          },
                          '&:active': {
                            background: '#113d95',
                          },
                        }}
                        onClick={() => setOpenDialog1(false)}
                      >
                        No
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              </Dialog>
            </Box>
          ) : null}
          {/* CfP Summary Page */}
          {saveLater &&
            firstOpenMarketSection &&
            !isEdit &&
            !isUpdate ? null : (
            <Box>
              <MarketInfoBlock
                title={`CfP Summary Page${isEdit ? '' : ':'}`}
                require={isEdit}
                isEdit={isEdit}
                data-selenium-id='SIDetail_MarketSection-MarketInfoBlock1'
              >
                {isEdit ? (
                  <CustomInput
                    id='JournalSIPageLinkInput'
                    data-selenium-id='SIDetail_MarketSection-JournalSiPageLinkInput'
                    placeholder=''
                    NeedCustomCheck={true}
                    CustomCheckPattern={
                      /(https?:\/\/)?(([0-9a-z.]+\.[a-z]+)|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[0-9a-z%/.\-_]*)?(\?[0-9a-z=&%_\-]*)?(\#[0-9a-z=&%_\-]*)?/
                    }
                    setCustomCheckError={setJournalSIPageLinkFormatError}
                    customCheckText='Should be a URL'
                    emptyError={isClick && isEmpty(journalSIPageLink)}
                    value={journalSIPageLink}
                    setValue={setJournalSIPageLink}
                    needEndAdornment={false}
                    maxRows={1}
                    autoFocus={firstOpenMarketSection}
                  />
                ) : (
                  <CustomLink
                    id='JournalSIPageLink'
                    data-selenium-id='SIDetail_MarketSection-JournalSIPageLink'
                    href={marketData.journalSIPageLink}
                    state={state}
                  />
                )}
              </MarketInfoBlock>

              <MarketInfoBlock
                title={`Special Issue HTML Link${isEdit ? '' : ':'}`}
                require={false}
                isEdit={isEdit}
                data-selenium-id='SIDetail_MarketSection-MarketInfoBlock2'
              >
                {isEdit ? (
                  <CustomInput
                    id='SpecialIssueHTMLLinkInput'
                    data-selenium-id='SIDetail_MarketSection-HTMLLinkInput'
                    placeholder=''
                    NeedCustomCheck={true}
                    CustomCheckPattern={
                      /(https?:\/\/)?(([0-9a-z.]+\.[a-z]+)|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[0-9a-z%/.\-_]*)?(\?[0-9a-z=&%_\-]*)?(\#[0-9a-z=&%_\-]*)?/
                    }
                    setCustomCheckError={setSpecialIssueHTMLLinkFormatError}
                    customCheckText='Should be a URL'
                    emptyError={false}
                    value={specialIssueHTMLLink}
                    setValue={setSpecialIssueHTMLLink}
                    needEndAdornment={false}
                    maxRows={1}
                  />
                ) : (
                  <CustomLink
                    id='SpecialIssueHTMLLink'
                    data-selenium-id='SIDetail_MarketSection-HTMLLink'
                    href={marketData.specialIssueHTMLLink}
                    state={state}
                  />
                )}
              </MarketInfoBlock>

              <MarketInfoBlock
                title={`SI Aims and Scope${isEdit ? '' : ':'}`}
                require={isEdit}
                isEdit={isEdit}
                data-selenium-id='SIDetail_MarketSection-MarketInfoBlock3'
                btnSx={{
                  width: 'calc(100% - 233px)',
                }}
              >
                {isEdit ? (
                  <InlineRichTextArea
                    id='SIAimsandScopeInput'
                    data-selenium-id='SIDetail_MarketSection-AimsandScopeInput'
                    emptyError={
                      isClick &&
                      (isEmpty(siAimsAndScope) || richToPlain(siAimsAndScope).length < siAimsAndScopeMinLength)
                    }
                    limitLength={TextAreaLimitLength}
                    value={siAimsAndScope}
                    setValue={setSIAimsAndScope}
                    setExceedError={setSIAimsAndScopeExceedError}
                    minRows={4}
                    maxRows={4}
                  />
                ) : (
                  <CustomDiv
                    id='SIAimsAndScope'
                    data-selenium-id='SIDetail_MarketSection-AimsandScope'
                    value={siAimsAndScope}
                    maxHeight='40px'
                    state={state}
                  ></CustomDiv>
                )}
              </MarketInfoBlock>

              <MarketInfoBlock
                title={`SI Topics${isEdit ? '' : ':'}`}
                require={isEdit}
                isEdit={isEdit}
                data-selenium-id='SIDetail_MarketSection-MarketInfoBlock4'
                btnSx={{
                  width: 'calc(100% - 233px)',
                }}
              >
                {isEdit ? (
                  <CustomRichInputList
                    id='SITopicsInput'
                    data-selenium-id='SIDetail_MarketSection-TopicsInput'
                    value={siTopics}
                    setValue={setSITopics}
                    isClick={isClick}
                    limitLength={siTopicsInputLimit}
                    maxItemNumber={siTopicsMaxItem}
                    width='100%'
                    setErr={setSITopicsError}
                    NeedCustomCheck={false}
                    NeedDuplicateCheck={false}
                    maxRows={2}
                    NeedEmptyCheck={true}
                    emptyListTextHelper='Please input at least 3 topics'
                  />
                ) : (
                  <CustomList
                    id='SITopics'
                    data-selenium-id='SIDetail_MarketSection-Topics'
                    value={siTopics}
                    needMore={true}
                    maxLength={siTopicsMaxItem}
                    maxHeight='60px'
                    state={state}
                  />
                )}
              </MarketInfoBlock>

              <MarketInfoBlock
                title={`SI Keywords${isEdit ? '' : ':'}`}
                require={isEdit}
                isEdit={isEdit}
                data-selenium-id='SIDetail_MarketSection-MarketInfoBlock5'
              >
                {isEdit ? (
                    <RichInputList
                        id='SIKeywordsInput'
                        data-selenium-id='SIDetail_MarketSection-KeywordsInput'
                        value={siKeywords}
                        setValue={setSIKeywords}
                        minItems={3}
                        maxItems={48}
                        setError={setSIKeywordsError}
                        placeholder={''}
                        onItemExceed={() => {
                          dispatch(
                              setSnackbarMessageAndOpen('sipDetail.edit.sipInformationKeywordsExceedErr', {}, SEVERITIES.error)
                          );
                        }}
                        errorCheckers={[
                          {
                            enable: true,
                            check: (item) => item.length > 500,
                            message: 'The character count per keyword should be no more than 500.',
                          },
                          {
                            enable: true,
                            check: (item,valueList) => {
                              return item !== '' && valueList.indexOf(item) !== valueList.lastIndexOf(item);
                            },
                            message: 'Keywords should not be duplicated.',
                          },
                          {
                            enable: isClick,
                            check: (item,valueList) => valueList.filter(v => v !== '').length < 3,
                            message: 'Please input at least 3 keywords.'
                          }
                        ]}
                        sx={{
                          width: '382px',
                        }}
                    />
                ) : (
                  <CustomDiv
                    id='SIKeywords'
                    data-selenium-id='SIDetail_MarketSection-Keywords'
                    value={ValueTypeChangeOtoS(siKeywords)?.replace(/<\/?p>/g, '')} //CT-5891
                    maxHeight='40px'
                    state={state}
                  ></CustomDiv>
                )}
              </MarketInfoBlock>

              <MarketInfoBlock
                title={`Competitive Title & ISSNs${isEdit ? '' : ':'}`}
                require={isEdit}
                isEdit={isEdit}
                data-selenium-id='SIDetail_MarketSection-MarketInfoBlock6'
              >
                {isEdit ? (
                  <ModelInputList
                    id='CompetitiveTitleISSNsInput'
                    data-selenium-id='SIDetail_MarketSection-CompetitiveTitleISSNsInput'
                    value={competitiveTitleISSNs}
                    setValue={setCompetitiveTitleISSNs}
                    isClick={isClick}
                    limitLength={9}
                    maxItemNumber={0}
                    width='382px'
                    setErr={setCompetitiveTitleISSNsError}
                    NeedCustomCheck={true}
                    NeedNote2={false}
                    CustomCheckPattern={/.*/}
                    customCheckText='Should be eight-digit code, separated by a hyphen in the middle.'
                    NeedDuplicateCheck={true}
                    maxRows={1}
                    customCheckPatternErrArr={customCheckPatternErrList}
                    duplicateItemArr={duplicateItemList}
                    NeedEmptyCheck={true}
                    emptyListTextHelper='Please input at least 1 Competitive Title ISSNs.'
                    noteLinkList={noteLinkList}
                    enableButton={
                      (siAimsAndScope!=='')&&
                      (siTopics.length >= siTopicsMaxItem && siTopics.slice(0, 3).every((item) => item !== ''))
                    }
                    optionsSearchData={{
                      aimsAndScope:siAimsAndScope,
                      topicList:siTopics,
                      siTitle:detailInfo.generalInformation[0].specialIssueTitle,
                    }}
                    oldValues={competitiveTitleISSNs}
                  />
                ) : (
                  <CustomDiv
                    id='CompetitiveTitleISSNs'
                    data-selenium-id='SIDetail_MarketSection-CompetitiveTitleISSNs'
                    value={ValueTypeChangeOtoS(competitiveTitleISSNs)}
                    maxHeight='40px'
                    state={state}
                  ></CustomDiv>
                )}
              </MarketInfoBlock>

              <MarketInfoBlock
                title={`Submission Instructions${isEdit ? '' : ':'}`}
                require={isEdit}
                isEdit={isEdit}
                data-selenium-id='SIDetail_MarketSection-MarketInfoBlock7'
                btnSx={{
                  width: 'calc(100% - 233px)',
                }}
              >
                {isEdit ? (
                  <InlineRichTextArea
                    id='SubmissionInstructionsInput'
                    data-selenium-id='SIDetail_MarketSection-SubmissionInstructionsInput'
                    emptyError={isClick && isEmpty(submissionInstructions)}
                    limitLength={TextAreaLimitLength}
                    value={submissionInstructions}
                    setValue={setSubmissionInstructions}
                    setExceedError={setSubmissionInstructionsExceedError}
                    minRows={4}
                    maxRows={4}
                    placeholder={submissionInstructionsPlaceholder}
                  />
                ) : (
                  <CustomDiv
                    id='SubmissionInstructions'
                    data-selenium-id='SIDetail_MarketSection-SubmissionInstructions'
                    value={submissionInstructions}
                    state={state}
                  ></CustomDiv>
                )}
              </MarketInfoBlock>

              <MarketInfoBlock
                title={`Others${isEdit ? '' : ':'}`}
                require={false}
                isEdit={isEdit}
                data-selenium-id='SIDetail_MarketSection-MarketInfoBlock8'
                btnSx={{
                  width: 'calc(100% - 233px)',
                }}
              >
                {isEdit ? (
                  <InlineRichTextArea
                    id='OthersInput'
                    data-selenium-id='SIDetail_MarketSection-OthersInput'
                    emptyError={false}
                    limitLength={TextAreaLimitLength}
                    value={others}
                    setValue={setOthers}
                    setExceedError={setOthersExceedError}
                    minRows={5}
                    maxRows={5}
                    placeholder={othersPlaceholder}
                    sx={{whiteSpace: 'pre-line',}}
                  />
                ) : (
                  <CustomDiv
                    id='Others'
                    data-selenium-id='SIDetail_MarketSection-Others'
                    value={others}
                    maxHeight='40px'
                    state={state}
                  ></CustomDiv>
                )}
              </MarketInfoBlock>

              <MarketInfoBlock
                title={`References${isEdit ? '' : ':'}`}
                require={false}
                isEdit={isEdit}
                data-selenium-id='SIDetail_MarketSection-MarketInfoBlock9'
                btnSx={{
                  width: 'calc(100% - 233px)',
                }}
              >
                {isEdit ? (
                  <OneCustomRichInputList
                    id='ReferencesInput'
                    data-selenium-id='SIDetail_MarketSection-ReferencesInput'
                    value={references}
                    setValue={setReferences}
                    isClick={isClick}
                    limitLength={referencesInputLimit}
                    maxItemNumber={1}
                    width='100%'
                    setErr={setReferencesError}
                    NeedCustomCheck={false}
                    NeedDuplicateCheck={false}
                    maxRows={5}
                    NeedNote={true}
                    NeedEmptyCheck={false}
                  />
                ) : (
                  <OneCustomList
                    id='References'
                    data-selenium-id='SIDetail_MarketSection-References'
                    value={references}
                    needMore={false}
                    maxHeight='60px'
                    state={state}
                  />
                )}
              </MarketInfoBlock>
              <Box
                sx={{
                  paddingTop: '4px',
                }}
              >
                {isEdit ? (
                  <SaveAndCancelButton
                    data-selenium-id='SIDetail_MarketSection-SaveAndCancelButton'
                    handleCancel={handleCancelClick}
                    handleSubmit={handleSaveClick}
                    handleSaveLater={handleSaveLater}
                    error={error}
                    isSaving={isLoading}
                  ></SaveAndCancelButton>
                ) : null}
              </Box>
            </Box>
          )}
          <BasicModal
            title='Do you confirm this special issue is ready for promotion?'
            open={promoteDialogOpen}
            handleClose={() => setPromoteDialogOpen(false)}
            siCode={detailInfo.generalInformation[0].projectCode}
            data-selenium-id='SIDetail_MarketSection-BasicModal'
          >
            <Box
              sx={{
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '18px',
                color: '#596A7C',
                marginBottom: '17px',
                width: 'calc(100% - 8px)',
              }}
              data-selenium-id='SIDetail_MarketSection-TextBox'
            >
              Please check SI information below is well formatted,up-to-date and
              matched with
              <br />
              respective call for paper information on WOL.
            </Box>
            <Box
              sx={{ marginBottom: '17px' }}
              data-selenium-id='SIDetail_MarketSection-MarketPromoteBlocksBox'
            >
              <MarketPromoteBlock
                title='Special Issue Name:'
                data-selenium-id='SIDetail_MarketSection-MarketPromoteBlock1'
              >
                {promoteData.siName}
              </MarketPromoteBlock>
              <MarketPromoteBlock
                title='Lead Guest Editor:'
                data-selenium-id='SIDetail_MarketSection-MarketPromoteBlock2'
                special={true}
              >
                {promoteData.LeadGE.map(item => (
                  <div>{item}</div>
                ))}
              </MarketPromoteBlock>
              <MarketPromoteBlock
                title='Submission Deadline:'
                data-selenium-id='SIDetail_MarketSection-MarketPromoteBlock3'
              >
                {promoteData.submissionDeadLine}
              </MarketPromoteBlock>
              <MarketPromoteBlock
                title='Full Call for Paper:'
                data-selenium-id='SIDetail_MarketSection-MarketPromoteBlock4'
              >
                <a
                  id='fullCallForPaperALabel'
                  data-selenium-id='SIDetail_MarketSection-FullCallForPaperALabel'
                  href={
                    promoteData.specialIssueHtmlLink.indexOf('http') !== -1
                      ? promoteData.specialIssueHtmlLink
                      : 'https://' + promoteData.specialIssueHtmlLink
                  }
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#262E35',
                  }}
                  onMouseOver={() => {
                    document.getElementById(
                      'fullCallForPaperALabel'
                    ).style.color = '#154AB6';
                    document.getElementById(
                      'fullCallForPaperALabel'
                    ).style.fontWeight = '600';
                  }}
                  onMouseLeave={() => {
                    document.getElementById(
                      'fullCallForPaperALabel'
                    ).style.color = '#262E35';
                    document.getElementById(
                      'fullCallForPaperALabel'
                    ).style.fontWeight = '400';
                  }}
                >
                  {promoteData.specialIssueHtmlLink}
                </a>
              </MarketPromoteBlock>
              <MarketPromoteBlock
                title='SI Topics:'
                data-selenium-id='SIDetail_MarketSection-MarketPromoteBlock5'
              >
                <CustomList
                  id='PromoteSITopics'
                  data-selenium-id='SIDetail_MarketSection-PromoteSITopcis'
                  value={promoteData.siTopics}
                  needMore={true}
                  maxLength={3}
                  maxHeight='60px'
                  state={'read-operate'}
                  sx={{
                    transform: 'translateX(-9px)',
                  }}
                />
              </MarketPromoteBlock>
            </Box>
          </BasicModal>
        </Box>
      </Collapse>
    </Box>
  );
}

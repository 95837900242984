import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import OneCustomInput from './OneCustomInput';
import { InlineRichTextArea } from "../../../../../componentsphase2/RichText/RichText";

export default function OneCustomRichInputList(props) {
    const {
        id,
        value,
        setValue,
        isClick,
        limitLength,
        maxItemNumber,
        width,
        setErr,
        NeedEmptyCheck,
        NeedCustomCheck,
        CustomCheckPattern,
        customCheckText,
        maxRows,
        NeedDuplicateCheck,
        NeedNote,
        NeedNote2,
        customCheckPatternErrArr,
        duplicateItemArr,
        emptyListTextHelper,
        noteLinkList,
    } = props;
    const ButtonStyle = {
        height: '18px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
        color: '#154AB6',
        textAlign: 'right',
        cursor: 'pointer',
        backgroundColor: '#F1F3F5',
        padding: '2px 4px 2px 4px',
        borderRadius: '4px',
    };
    const ChangeStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2px 4px',
        gap: '4px',

        width: '101px',
        height: '22px',

        /* Gray/100 */
        background: '#F1F3F5',
        borderRadius: '4px',
        color: '#154AB6',
        fontWeight: '400px',
        /* Inside auto layout */
        flex: 'none',
        order: '1',
        flexGrow: '0',
        fontSize: '12px',
        lineHeight: '18px',
        cursor: 'pointer',
    };
    const NoteStyle = {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '16px',

        color: '#596A7C',
    };

    const [customCheckPatternErrList, setCustomCheckPatternErrList] = useState(
        customCheckPatternErrArr
    );
    const [duplicateItemList, setDuplicateItemList] = useState(duplicateItemArr);

    const isEmpty = value => {
        return (
            value === undefined ||
            value === null ||
            value === '' ||
            value === [] ||
            value === {} ||
            (typeof value === 'string' && !value.trim().length)
        );
    };
    const handleChange = (newValue, index) => {
        if (NeedDuplicateCheck) {
            let newduplicateItemList = duplicateItemList.concat();
            for (let i = 0; i < value.length; i++) {
                if (i === index) {
                    continue;
                }
                if (newValue === value[i]) {
                    if (newduplicateItemList[index].indexOf(i) !== -1) {
                        continue;
                    } else {
                        newduplicateItemList[index].push(i);
                    }
                } else {
                    if (newduplicateItemList[index].indexOf(i) !== -1) {
                        newduplicateItemList[index].splice(
                            newduplicateItemList[index].indexOf(i),
                            1
                        );
                    }
                    if (newduplicateItemList[i].indexOf(index) !== -1) {
                        newduplicateItemList[i].splice(
                            newduplicateItemList[i].indexOf(index),
                            1
                        );
                    }
                }
            }
            setDuplicateItemList(newduplicateItemList);
        }
        let newList = value.concat();
        newList.splice(index, 1, newValue);
        setValue(newList);
    };
    const handleClick = () => {
        let newList = value.concat();
        newList.push('');
        setValue(newList);
        if (NeedCustomCheck) {
            let newcustomCheckPatternErrList = customCheckPatternErrList.concat();
            newcustomCheckPatternErrList.push(false);
            setCustomCheckPatternErrList(newcustomCheckPatternErrList);
        }
        if (NeedDuplicateCheck) {
            let newduplicateItemList = duplicateItemList.concat();
            newduplicateItemList.push([]);
            setDuplicateItemList(newduplicateItemList);
        }
    };

    const handleIconButtonClick = index => {
        let newList = value.concat();
        newList.splice(index, 1);
        setValue(newList);
        if (NeedCustomCheck) {
            let newcustomCheckPatternErrList = customCheckPatternErrList.concat();
            newcustomCheckPatternErrList.splice(index, 1);
            setCustomCheckPatternErrList(newcustomCheckPatternErrList);
        }
        if (NeedDuplicateCheck) {
            let newduplicateItemList = duplicateItemList.concat();
            for (let i = 0; i < newduplicateItemList[index].length; i++) {
                let position = newduplicateItemList[index][i];
                newduplicateItemList[position].splice(
                    newduplicateItemList[position].indexOf(index),
                    1
                );
            }
            newduplicateItemList.splice(index, 1);
            setDuplicateItemList(newduplicateItemList);
        }
    };

    const handleCustomCheckError = (newValue, index) => {
        let newcustomCheckPatternErrList = customCheckPatternErrList.concat();
        newcustomCheckPatternErrList.splice(index, 1, newValue);
        setCustomCheckPatternErrList(newcustomCheckPatternErrList);
    };

    useEffect(() => {
        let error = false;
        if (NeedEmptyCheck) {
            let emptyError = isEmpty(value[0]);
            for (let i = 1; i < maxItemNumber; i++) {
                emptyError = emptyError || isEmpty(value[i]);
            }
            error = error || emptyError;
        }
        if (NeedCustomCheck) {
            let customCheckError = customCheckPatternErrList[0];
            for (let i = 1; i < customCheckPatternErrList.length; i++) {
                customCheckError = customCheckError || customCheckPatternErrList[i];
            }
            error = error || customCheckError;
        }
        if (NeedDuplicateCheck) {
            let duplicateItemErr = duplicateItemList[0].length !== 0;
            for (let i = 1; i < duplicateItemList.length; i++) {
                duplicateItemErr =
                    duplicateItemErr || duplicateItemList[i].length !== 0;
            }
            error = error || duplicateItemErr;
        }
        setErr(error);
    });

    function CustomInputAreaFooter(props) {
        const { currentLength, maxLength, exceedError } = props;
        const [color, setColor] = useState('#596A7C');
        const style = () => {
            return {
                width: '100%',
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '20px',
                textAlign: 'right',
                color: color,
            };
        };
        useEffect(() => {
            exceedError ? setColor('#DE350B') : setColor('#596A7C');
        }, [exceedError]);
        return (
            <Box
                sx={style()}
                data-selenium-id='SIDetail_MarketSection-OneCustomInputAreaFooterBox'
            >
                {currentLength}/{'100,000'}
            </Box>
        );
    }

    const currentLength = (item) => {
        return item === null || item === undefined ? 0 : item.length;
    };
    const [exceedError,setExceedError] = useState(false);
    return (
        <Box
            sx={{ width: width }}
            data-selenium-id='SIDetail_MarketSection-OneCustomInputList-ContainerBox'
        >
            {value.map((item, index) => (
                <>
                    <InlineRichTextArea
                        //placeholder={'Placeholder'}
                        placeholder={'Please input your reference list here.'}
                        id={`${id}${index}`}
                        data-selenium-id={`${id}-${index}`}
                        NeedCustomCheck={NeedCustomCheck}
                        CustomCheckPattern={CustomCheckPattern}
                        setCustomCheckError={newValue =>
                            handleCustomCheckError(newValue, index)
                        }
                        customCheckText={customCheckText}
                        emptyError={
                            isClick &&
                            index >= 0 &&
                            index < maxItemNumber &&
                            NeedEmptyCheck &&
                            isEmpty(item)
                        }
                        hasText={!!item}
                        value={item}
                        setValue={newValue => handleChange(newValue, index)}
                        needEndAdornment={index >= maxItemNumber ? true : false}
                        handleIconButtonClick={() => handleIconButtonClick(index)}
                        limitLength={limitLength}
                        maxRows={maxRows}
                        error={NeedDuplicateCheck && duplicateItemList[index].length !== 0}
                        helperText={
                            NeedDuplicateCheck && duplicateItemList[index].length !== 0
                                ? 'ISSN should not be repetitive.'
                                : ''
                        }
                        exceedError={exceedError}
                        setExceedError={setExceedError}
                    />
                    {/*<CustomInputAreaFooter*/}
                    {/*currentLength={currentLength(item)}*/}
                    {/*maxLength={limitLength}*/}
                    {/*exceedError={currentLength(item)>100000}*/}
                    {/*data-selenium-id='SIDetail_MarketSection-OnrCsutomInputAreaFooter'*/}
                    {/*/>*/}
                </>
            ))}
        </Box>
    );
}

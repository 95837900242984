import { useState, useEffect } from 'react';

import { Box } from '@mui/material';

import MainPageFramework from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/MainPageFramework';
import ActionBarFooter from './GEMergeComponents/ActionbarFooter.js';
import StepHeader from './GEMergeComponents/StepHeader';
import {
  GEDetailItem,
  fieldSequence,
  FlagPart,
  ArrowButton,
  CancelConfirmModal,
} from './GEMergeComponents';
import { useDataControl, useFlagControl } from './GEMergeComponents/dataHook';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { mergeStart, mergeReset } from '@/actions/SIMT-SI/GuestEditor/GEAction';
import { useDispatch, useSelector } from 'react-redux';
import {
  PreviewGEDetailItem,
  PreviewGEFlagItem,
} from './GEMergeComponents/PreviewPageRelated';
import { useNavigate } from 'react-router-dom';
import { checkEmail, getBasicInformation, mergeGE } from '@/actions/SIMT-SI/GuestEditor';
import { getFlaglist } from '@/actions/SIMT-SI/GuestEditor/Config';
import { getFlagByGECode } from '@/actions/SIMT-SI/GuestEditor/GEFlag';

const steps = ['Merge Profile', 'Preview Result'];

const getFlagId = (flagName, list) => {
  for (let i = 0; i < list.length; i++) {
    let flag = list[i];
    if (flag.value === flagName) return flag.id;
  }
};

const getFlagKey = (flagName, list) => {
  for (let i = 0; i < list.length; i++) {
    let flag = list[i];
    if (flag.value === flagName) return flag.key;
  }
};

const GEMergePage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code1 = urlParams.get('ge1');
  const code2 = urlParams.get('ge2');

  const selector = state => {
    return {
      mergeData: state.GE.GEMergeData,
      isEmailMessage: state.GE.isEmailMessage,
      isEmailMessageGet: state.GE.isEmailMessageGet
    };
  };
  const {mergeData, isEmailMessage, isEmailMessageGet} = useSelector(selector);


  useEffect(() => {
    dispatch(getFlaglist());
  }, []);

  const totalFlagList = useSelector(state => {
    return state.GE.flagsList;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (code1 !== null && code1 !== undefined) {
      dispatch(getBasicInformation(code1, true, true));
      dispatch(getFlagByGECode(code1, true, true));
    }
    if (code2 !== null && code2 !== undefined) {
      dispatch(getBasicInformation(code2, true, false));
      dispatch(getFlagByGECode(code2, true, false));
    }
  }, [code1, code2]);

  const [dataToShow, setData, unSetData, hasEdited] = useDataControl(mergeData);
  const [flagsForReturn, mergeFlag, unMergeFlag, setFlagData, flagHasEdited] =
    useFlagControl(mergeData);

  const [activeStep, setActiveStep] = useState(0);
  const initialGE1PriEmail = mergeData?.One?.BasicInformation['primaryEmail'] || '';
  const initialGE2PriEmail = mergeData?.Two?.BasicInformation['primaryEmail'] || '';

  const stepper = (
    <StepHeader
      id='createSI_acquiredPage_mainPage_header'
      activeStep={activeStep}
      steps={steps}
    />
  );
  const patternEmail =
    /^\w+((-\w+)|(\.\w+)|(_\w+))*@[A-Za-z0-9]+(([.\-])[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

  const PrimaryEmailError = !patternEmail.test(
    dataToShow['Primary Email'].item2.value
  );

  const priEmailSametoSecEmailError = (dataToShow['Primary Email'].item2.value === dataToShow['Secondary Email'].item2.value) && dataToShow['Primary Email'].item2.value;
  const [emailDuplicatedError, setEmailDuplicatedError] = useState(false);
  const SecondaryEmailError =
    !patternEmail.test(dataToShow['Secondary Email'].item2.value) &&
    dataToShow['Secondary Email'].item2.value !== '' &&
    dataToShow['Secondary Email'].item2.value !== null;
  const CommentError = flagsForReturn.Two.map(flag => {
    if (flag.comment.length > 10000) return true;
    return false;
  }).reduce((a, b) => a || b, false);

  const navigate = useNavigate();

  const handleMerge = () => {
    const basicInformation = {
      geCode: code2,
      firstName: dataToShow['First Name'].item2.value,
      lastName: dataToShow['Last Name'].item2.value,
      primaryEmail: dataToShow['Primary Email'].item2.value,
      secondaryEmail: dataToShow['Secondary Email'].item2.value,
      department: dataToShow['Department'].item2.value,
      institute: dataToShow['Institution'].item2.value,
      city: dataToShow['City'].item2.value,
      country: dataToShow['Country/Region'].item2.value,
      gender: dataToShow['Gender'].item2.value,
      notes: dataToShow['Notes'].item2.value,
      researchKeywords: dataToShow['Research Keywords'].item2.value.split('; '),
      orcidId: dataToShow['ORCID ID'].item2.value,
      title: dataToShow['Job Title'].item2.value,
      profileWebsite: dataToShow['Profile Website'].item2.value,
      researchGateId: dataToShow['ResearchGate ID'].item2.value,
      cvFileName: dataToShow['CV'].item2.value,
    };
    const flagList = flagsForReturn.Two.map(flag => {
      return {
        geCode: code2,
        geFlagId: null,
        flagInfo: {
          id: getFlagId(flag.flag.value, totalFlagList),
          type: flag.flag.type,
          key: getFlagKey(flag.flag.value, totalFlagList),
          value: flag.flag.value,
        },
        comment: flag.comment,
        files: flag.evidences,
      };
    });
    dispatch(mergeGE(code1, code2, basicInformation, flagList));
  };

  const mergeState = useSelector(state => {
    return state.GE.GEMergeState;
  });

  useEffect(() => {
    if (mergeState === 'init') {
      dispatch(mergeStart());
      return;
    }
    if (mergeState === 'start') {
      return;
    }

    if (mergeState === 'finished-success') {
      dispatch(
        setSnackbarMessageAndOpen(
          'common.mergeSuccess',
          {},
          'success',
          null,
          null,
          {},
          'You can view kept results and edit it in this page.'
        )
      );
    } else if (mergeState === 'finished-failed') {
      dispatch(
        setSnackbarMessageAndOpen(
          'common.mergeFailed',
          {},
          'error',
          null,
          null,
          {},
          'Something went wrong, please try again.'
        )
      );
    }
    navigate(`/simt/auth/siRelated/ge/detail/${code2}`, { replace: true });
    dispatch(mergeReset());
  }, [mergeState]);

  useEffect(() => {
    const value = dataToShow['Primary Email'].item2.value;
    // 每次点击清空邮件重复检查的状态
    setEmailDuplicatedError(false);
    if (typeof value !== 'string' || value === initialGE1PriEmail || value === initialGE2PriEmail) 
      return;
    dispatch(checkEmail(value));
  }, [dataToShow['Primary Email'].item2.value]);

  useEffect(() => {
    if (
      isEmailMessage && isEmailMessage.message === 'the email address has been registered'
    ) {
      setEmailDuplicatedError(true);
    } else {
      setEmailDuplicatedError(false);
    }
  }, [isEmailMessageGet]);

  const actionBar = (
    <ActionBarFooter
      id='createSI_acquiredPage_mainPage_actionBar'
      activeStep={activeStep}
      lastStep={steps.length - 1}
      handleBack={() => {
        if (activeStep > 0) {
          setActiveStep(prevActiveStep => prevActiveStep - 1);
          return;
        }
        setIsModalOpen(true);
      }}
      handleNextOrCreate={() => {
        if (activeStep < steps.length - 1) {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          return;
        }
        handleMerge();
      }}
      Err={PrimaryEmailError || SecondaryEmailError || CommentError || priEmailSametoSecEmailError || emailDuplicatedError}
    />
  );

  const [isLeftFolded, setIsLeftFolded] = useState(false);
  const [isRightFolded, setIsRightFolded] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (activeStep === 1) {
    return (
      <MainPageFramework id='Merge' stepper={stepper} actionBar={actionBar}>
        <Box
          sx={{
            width: '1068px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            paddingLeft: '8px',
            // border: '1px solid red'
          }}
        >
          {/* slogan */}
          <Box
            sx={{
              width: '400px',
              color: 'var(--gray-500, #596A7C)',
              fontFamily: 'Open Sans',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px' /* 100% */,
            }}
          >
            You can preview final version in this step.
          </Box>
          {/* Detail Container */}
          <Box sx={{ marginTop: '32px', marginBottom: '16px', height: '22px' }}>
            <PreviewGEDetailItem
              isTitleLine={true}
              title={'Basic Information'}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            {/* Left Side */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
              {/* get 9 item */}
              {fieldSequence.slice(1, 10).map((item, index) => {
                return (
                  <PreviewGEDetailItem
                    _key={dataToShow[item].key}
                    value={dataToShow[item].item2.value}
                    isTitleLine={false}
                  />
                );
              })}
            </Box>
            <Box sx={{ width: '16px' }} />
            {/* Right Side */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
              {/* get 9 item */}
              {fieldSequence.slice(10).map((item, index) => {
                return (
                  <PreviewGEDetailItem
                    _key={dataToShow[item].key}
                    value={dataToShow[item].item2.value}
                    isTitleLine={false}
                  />
                );
              })}
            </Box>
          </Box>

          <Box sx={{ marginTop: '32px', marginBottom: '16px', height: '22px' }}>
            <PreviewGEDetailItem isTitleLine={true} title={'Flag'} />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              gap: '21px',
            }}
          >
            {flagsForReturn.Two.map((flag, index) => {
              return (
                <PreviewGEFlagItem
                  _key={flag.key}
                  flag={flag.flag}
                  evidences={flag.evidences}
                  comment={flag.comment}
                  index={index + 1}
                />
              );
            })}
          </Box>
        </Box>
      </MainPageFramework>
    );
  }

  return (
    <MainPageFramework id='Merge' stepper={stepper} actionBar={actionBar}>
      <Box
        sx={{
          width: '1111px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          paddingLeft: '8px',
          // border: '1px solid red'
        }}
      >
        {/* slogan */}
        <Box
          sx={{
            width: '1081px',
            color: 'var(--gray-500, #596A7C)',
            fontFamily: 'Open Sans',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px' /* 100% */,
          }}
        >
          In this step, you need to merge the information from GE1 into GE2's
          profile, which will constitute the final version.
        </Box>
        {/* Arrow Button */}
        <Box
          sx={{
            marginTop: '32px',
            marginBottom: '22px',
            height: '28px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginRight: '481px',
            }}
          >
            <Box sx={{ marginRight: '10px' }}>
              <ArrowButton
                isFolded={isLeftFolded}
                setIsFolded={setIsLeftFolded}
              />
            </Box>
            <Box
              sx={{
                color: 'var(--primary-600, #113D95)',
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '24px',
              }}
            >
              GE 1
            </Box>
          </Box>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <Box sx={{ marginRight: '10px' }}>
              <ArrowButton
                isFolded={isRightFolded}
                setIsFolded={setIsRightFolded}
              />
            </Box>
            <Box
              sx={{
                color: 'var(--primary-600, #113D95)',
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '24px',
              }}
            >
              GE 2
            </Box>
          </Box>
        </Box>
        {/* Detail Container */}
        <Box
          sx={{
            // marginTop: '22px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '12px',
            '& > :first-child + :nth-child(2)': {
              marginTop: '4px',
            },
          }}
        >
          {fieldSequence.map((item, index) => {
            return (
              <GEDetailItem
                key={item}
                _key={dataToShow[item].key}
                item1={dataToShow[item].item1}
                item2={dataToShow[item].item2}
                isTitleLine={dataToShow[item]['isTitleLine']}
                title={dataToShow[item]['title']}
                setData={setData}
                unSetData={unSetData}
                editType={dataToShow[item]['editType']}
                hasEdited={hasEdited}
                showLeft={!isLeftFolded}
                showRight={!isRightFolded}
                priEmailSametoSecEmailError={priEmailSametoSecEmailError}
                emailDuplicatedError={emailDuplicatedError}
              />
            );
          })}
        </Box>
        <Box sx={{ marginTop: '16px' }}>
          <FlagPart
            flags={flagsForReturn}
            mergeFlag={mergeFlag}
            unMergeFlag={unMergeFlag}
            setFlagData={setFlagData}
            hasEdited={flagHasEdited}
            showLeft={!isLeftFolded}
            showRight={!isRightFolded}
          />
        </Box>
      </Box>
      <CancelConfirmModal
        open={isModalOpen}
        handleCancel={() => {
          setIsModalOpen(false);
        }}
        handleConfirm={() => {
          setIsModalOpen(false);
          navigate(`/simt/auth/siRelated/ge/overview`);
        }}
      />
    </MainPageFramework>
  );
};

export default GEMergePage;

import { axios_instance } from "@/utils/axios_instance";
import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {
    ADD_NEW_DROPDOWN_ITEM,
    DELETE_DROPDOWN_ITEM,
    EDIT_DROPDOWN_ITEM,
    GET_FLAGS,
    GET_GROUPS,
    GET_NOTE_LINK_CONFIG,
    GET_PAPERCONFIG,
    GET_SITYPE,
    GET_SYSTEM_CONFIG,
    GET_USER_STATUS,
    isDuplicationError,
    loadDropdown,
    SAVE_DROPDOWN_ORDER_FAIL,
    SAVE_DROPDOWN_ORDER_SUCCESS,
    UPDATE_SYSTEM_CONFIG,
    CHECK_ACCOUNT_STATUE
} from "@/actions/SIMT-User/Config/SystemConfigAction";
import { isTimeoutError } from "@/utils/commonUtils";

import { getSIDetail } from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueDetail";

export function saveDropdownOrder(itemList, currentTypeObj) {
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/configs`;
            let res = await axios_instance.put(url, {
                configVOList: itemList,
            }, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({ type: SAVE_DROPDOWN_ORDER_SUCCESS, data: null });
                dispatch(loadDropdown(currentTypeObj));
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sysConfig.orderSaveSuccess',
                        {},
                        SEVERITIES.success
                    )
                );
            } else {
                dispatch({ type: SAVE_DROPDOWN_ORDER_FAIL, data: null });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sysConfig.orderSaveFail',
                        {
                            reason: 'common.errorundefined', // 这个以后再修改
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({ type: SAVE_DROPDOWN_ORDER_FAIL, data: null });
            dispatch(
                setSnackbarMessageAndOpen(
                    'sysConfig.orderSaveFail',
                    {
                        reason: `common.errorundefined`,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
}

/**
 * 添加一个新项目
 * @param {Item} item 待添加的item
 * @returns dispatch
 */
export function addNewItem(item) {
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/configs`;
            let res = await axios_instance.post(
                url,
                {
                    id: item?.id,
                    type: item.type,
                    key: item?.key,
                    value: item.value.trim(),
                    displayName: item?.displayName,
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sysConfig.addItemSuccess',
                        {},
                        SEVERITIES.success
                    )
                );
            } else {
                dispatch({ type: ADD_NEW_DROPDOWN_ITEM, data: null });
                if (isDuplicationError(res.data.message)) {
                    // 重复名称
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sysConfig.itemDuplicated',
                            {
                                value: item.value,
                            },
                            SEVERITIES.error
                        )
                    );
                } else {
                    // 其他错误
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sysConfig.addItemFail',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            }
            // 不管成功与否，只要是请求发送成功且返回了可控的message，都重新加载一次
            dispatch(loadDropdown(item.typeObj));
        } catch (err) {
            dispatch({ type: ADD_NEW_DROPDOWN_ITEM, data: null });
            dispatch(
                setSnackbarMessageAndOpen('sysConfig.addItemFail', {}, SEVERITIES.error)
            );
        }
    };
}

/**
 * 编辑一个item
 * @param item
 * @param typeObj
 * @returns {(function(*): Promise<void>)|*}
 */
export function editItem(item, typeObj) {
    let itemList = [];
    itemList.push(item);
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/configs`;
            let res = await axios_instance.put(
                url,
                { updateConfigDto: item },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: EDIT_DROPDOWN_ITEM, data: item });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sysConfig.updateItemSuccess',
                        {},
                        SEVERITIES.success
                    )
                );
            } else {
                dispatch({ type: EDIT_DROPDOWN_ITEM, data: null });
                if (isDuplicationError(res.data.message)) {
                    // 重复名称
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sysConfig.itemDuplicated',
                            {
                                value: item.value,
                            },
                            SEVERITIES.error
                        )
                    );
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sysConfig.updateItemFail',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            }
            dispatch(loadDropdown(typeObj)); // 不论成功与否，都刷新一遍
        } catch (err) {
            // 如果是服务端报错的话，则不刷新
            dispatch({ type: EDIT_DROPDOWN_ITEM, data: null });
            dispatch(
                setSnackbarMessageAndOpen(
                    'sysConfig.updateItemFail',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

/**
 * CT 198似乎没有删除功能，确认无用后会删除
 * @param item
 * @returns {(function(*): Promise<void>)|*}
 */
export function deleteItem(item) {
    return async dispatch => {
        try {
            const url = `/config/deleteConfig`;
            let res = await axios_instance.post(
                url,
                {
                    config: item.id,
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: DELETE_DROPDOWN_ITEM, data: item });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sysConfig.deleteItemSuccess',
                        {},
                        SEVERITIES.success
                    )
                );
            } else {
                dispatch({ type: DELETE_DROPDOWN_ITEM, data: null });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sysConfig.deleteItemFail',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({ type: DELETE_DROPDOWN_ITEM, data: null });
            dispatch(
                setSnackbarMessageAndOpen(
                    'sysConfig.deleteItemFail',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
} // 获取系统设置过期时间
export function getSystemConfig() {
    return async dispatch => {
        try {
            let res = await axios_instance.get('/api/v1/user-service/configs', {
                params: {
                    requestType: 'view-system-config'
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            // console.log("sysconfig", res);
            if (res.data.code === 200) {
                dispatch({ type: GET_SYSTEM_CONFIG, data: res.data.data });
            } else {
                dispatch({ type: GET_SYSTEM_CONFIG, data: null });
            }
        } catch (err) {
            dispatch({ type: GET_SYSTEM_CONFIG, data: err.response.data.msg });
        }
    };
} //更新系统过期时间
export function updateSystemConfig(
    resetTimeout,
    activeTimeout,
    tokenTimeout,
    maxGuestEditors,
    maxArticles,
    maxJournals
) {
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/configs`;
            let res = await axios_instance.put(
                url,
                {
                    systemConfigDto: {
                        jwtTimeout: tokenTimeout,
                        passwordResetUrlTimeout: resetTimeout,
                        userActivateUrlTimeout: activeTimeout,
                        eachSiGuestEditorMaxNumber: maxGuestEditors,
                        eachSiArticlesMaxNumber: maxArticles,
                        eachJointSIMaxAddedJournalsNumber: maxJournals
                    }

                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: UPDATE_SYSTEM_CONFIG, data: res.data });
            } else if (res.data.code === 400) {
                dispatch({ type: UPDATE_SYSTEM_CONFIG, data: res.data });
            } else {
                dispatch({ type: UPDATE_SYSTEM_CONFIG, data: false });
            }
        } catch (err) {
            dispatch({ type: UPDATE_SYSTEM_CONFIG, data: err.response.data.msg });
        }
    };
}

export function loadGroups() {
    let typeObj = {
        id: 'groupTitle',
        value: 0,
        type: 'Group/Title',
        lengthLimit: 80,
        api: `/api/v1/user-service/configs`,
    };
    return async dispatch => {
        try {
            let res = await axios_instance.get(typeObj.api, {
                params: {
                    type: typeObj.type,
                    fuzzy_type: false,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({ type: GET_GROUPS, data: res.data.data });
            } else {
                dispatch({ type: GET_GROUPS, data: [] });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'common.failToLoad',
                        {
                            value: `sysConfig.${typeObj.id}`,
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({ type: GET_GROUPS, data: [] });
            let reasonId;
            if (isTimeoutError(err)) {
                reasonId = 'common.errorTimeout';
            } else {
                reasonId = `common.error${err.status}`;
            }
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToLoadWithReason',
                    {
                        value: `sysConfig.${typeObj.id}`,
                        reason: reasonId,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
}

export const loadUserStatus = (props) => {
    let typeObj = {
        type: 'Account Status',
        fuzzy_type: false,
    };
    let actionType = {
        success: CHECK_ACCOUNT_STATUE,
        defeat: CHECK_ACCOUNT_STATUE,
        error: CHECK_ACCOUNT_STATUE,
    };

    return async dispatch => {
        dispatch(ConfigAction(typeObj, actionType));
    };
};


export function loadFlags() {
    let typeObj = {
        id: 'flags',
        value: 1,
        type: 'Flags',
        lengthLimit: 200,
        api: `/api/v1/user-service/configs`,
    };
    return async dispatch => {
        try {
            let flagData = [];
            let res_Severe = await axios_instance.get(typeObj.api, {
                params: {
                    type: 'Flags-Severe Issue',
                    fuzzy_type: false,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            flagData.push(res_Severe.data.data);
            let res_Mild = await axios_instance.get(typeObj.api, {
                params: {
                    type: 'Flags-Mild Issue',
                    fuzzy_type: false,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            flagData.push(res_Mild.data.data);
            if (res_Severe.data.code === 200 && res_Mild.data.code === 200) {
                dispatch({ type: GET_FLAGS, data: flagData });
            } else {
                dispatch({ type: GET_FLAGS, data: [] });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'common.failToLoad',
                        {
                            value: `sysConfig.${typeObj.id}`,
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({ type: GET_FLAGS, data: [] });
            let reasonId;
            if (isTimeoutError(err)) {
                reasonId = 'common.errorTimeout';
            } else {
                reasonId = `common.error${err.status}`;
            }
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToLoadWithReason',
                    {
                        value: `sysConfig.${typeObj.id}`,
                        reason: reasonId,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
}

export function loadSITypes() {
    let typeObj = {
        id: 'siPublicationFormat',
        value: 2,
        type: 'SI Publication Format',
        lengthLimit: 80,
        api: `/api/v1/user-service/configs`,
    };
    return async dispatch => {
        try {
            let res = await axios_instance.get(typeObj.api, {
                params: {
                    type: typeObj.type,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({ type: GET_SITYPE, data: res.data.data });
            } else {
                dispatch({ type: GET_SITYPE, data: [] });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'common.failToLoad',
                        {
                            value: `sysConfig.${typeObj.id}`,
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({ type: GET_SITYPE, data: [] });
            let reasonId;
            if (isTimeoutError(err)) {
                reasonId = 'common.errorTimeout';
            } else {
                reasonId = `common.error${err.status}`;
            }
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToLoadWithReason',
                    {
                        value: `sysConfig.${typeObj.id}`,
                        reason: reasonId,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
}

/**
 * @returns {(dispatch: import('redux').Dispatch) => Promise<void>}
 */
export function loadPaperConfig() {
    let typeObj = {
        id: 'paperCommissioningMethod',
        value: 3,
        type: 'Paper Commissioning Method',
        lengthLimit: 80,
        api: '/api/v1/user-service/configs',
    };
    return async dispatch => {
        try {
            let res = await axios_instance.get(typeObj.api, {
                params: {
                    type: typeObj.type,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                const { pathname } = window.location;
                const sipCode = /(?<=\/simt\/auth\/siRelated\/si\/detail\/)SI-\d+-\d+(?=\/update\/\w+)/.exec(pathname)?.[0];

                if (/\/simt\/auth\/siRelated\/si\/detail\/SI-\d+-\d+\/update\/\w+/.test(pathname) && sipCode) {
                    const siDetail = await getSIDetail(sipCode)(dispatch);
                    const readyForPromotion = siDetail?.siMarketing?.[0]?.readyForPromotion;

                    if (readyForPromotion === 'Yes') {
                        const filteredData = res?.data?.data?.filter(
                            /**
                             * @param {{readonly value:string}} data
                             */
                            data => ['open call', 'hybrid'].includes(data?.value?.trim()?.toLowerCase())
                        );
                        dispatch({ type: GET_PAPERCONFIG, data: filteredData || [] });
                        return;
                    }
                }

                dispatch({ type: GET_PAPERCONFIG, data: res?.data?.data || [] });
            } else {
                dispatch({ type: GET_PAPERCONFIG, data: [] });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'common.failToLoad',
                        {
                            value: `sysConfig.${typeObj.id}`,
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({ type: GET_PAPERCONFIG, data: [] });

            let reasonId;
            if (isTimeoutError(err)) {
                reasonId = 'common.errorTimeout';
            } else {
                reasonId = `common.error${err.status}`;
            }

            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToLoadWithReason',
                    {
                        value: `sysConfig.${typeObj.id}`,
                        reason: reasonId,
                    },
                    SEVERITIES.error
                )
            );
        }

    };
}

export const loadNoteLinkConfig = () => {
    return async dispatch => {
        try {
            const url = '/api/v1/user-service/configs';
            let res = await axios_instance.get(url, {
                params: {
                    type: 'Note link',
                    fuzzy_type: false,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({ type: GET_NOTE_LINK_CONFIG, data: res.data.data });
            }
        } catch (err) {
        }
    };
};


export const ConfigAction = (typeObj, actionType) => {
    return async dispatch => {
        try {
            let res = await axios_instance.get('/api/v1/user-service/configs', {
                params: {
                    ids: typeObj.ids,
                    apiName: typeObj.apiName,
                    key: typeObj.key,
                    type: typeObj.type,
                    value: typeObj.value,
                    fuzzy_type: typeObj.fuzzy_type,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({ type: actionType, data: res.data.data });
            } else {
                dispatch({ type: actionType, data: [] });
                //TO DO
            }
        } catch (err) {
            dispatch({ type: actionType, data: [] });
            //TO DO
        }
    };
};

import { axios_instance } from "@/utils/axios_instance";
import {
    BATCH_UPDATE,
    EDIT_AMEDETAIL,
    EDIT_EOCONTACT,
    EDIT_MEDETAIL,
    GET_CRDETAIL,
    JUDGEEMAIL,
    LOAD_CR,
    SEARCHAMEORME
} from "@/actions/SIMT-SI/CRContact/CRContactAction";
import { openConfirm, setEditType, setTextParam } from "@/actions/SIMT-User/Alert/AlertAction";

/**
 * 获取PRP Contact列表
 */
export function loadCRData(
    page,
    pageSize,
    order,
    orderBy,
    type = 'ALL',
    name = ''
) {
    return async dispatch => {
        try {
            let res = await axios_instance.get('/api/v1/si-service/journals', {
                params: {
                    journalName: name,
                    type: type,
                    pageNum: page,
                    pageSize: pageSize,
                    order: order,
                    orderBy: orderBy
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            if (res.data.code === 200) {
                //将返回数据添加独一无二的ID，以适应表格
                const returnData = {
                    result: [],
                    count: res.data.total,
                };
                for (const resultEle of res.data.data) {
                    const temp = {
                        ...resultEle,
                    };
                    temp['id'] = temp.journalCode;
                    returnData.result.push(temp);
                }

                dispatch({ type: LOAD_CR, data: returnData });
            } else {
                dispatch({
                    type: LOAD_CR,
                    data: {
                        result: [],
                        count: 0,
                    },
                });
            }
        } catch (e) {
            dispatch({
                type: LOAD_CR,
                data: {
                    result: [],
                    count: 0,
                },
            });
        }
    };
}

/**
 * @author LiuXin
 * @date 2022/4/29
 * @description 获得CR detail页面详细的信息展示
 * @param {string} journalCode 要获得细节展示的journal code
 * @returns journalCode的详细信息
 */
export function getCRDetailInfomationList(journalCode) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/journals`;
            const res = await axios_instance.get(url, {
                params: {
                    journalCodes: journalCode,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const { code, data } = res.data;
            if (code === 200) {
                dispatch({ type: GET_CRDETAIL, data: data });
            } else {
                dispatch({ type: GET_CRDETAIL, data: {} });
            }
        } catch (e) {
            dispatch({ type: GET_CRDETAIL, data: {} });
        }
    };
}

export function editAMEDetail(email, firstName, lastName, flag, journalName) {
    return async dispatch => {
        const url = `/journal/update/AME`;
        const res = await axios_instance.post(
            url,
            {
                email: email,
                lastName: lastName,
                firstName: firstName,
            },
            {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            }
        );
        if (res.data.code === 200) {
            dispatch({ type: EDIT_AMEDETAIL, data: !flag });
            dispatch(setEditType('editCRContactDetail'));
            dispatch(setTextParam(journalName));
            dispatch(openConfirm());
        }
    };
}

export function editEOContact(journalCode, email, flag, journalName) {
    return async dispatch => {
        const url = `/api/v1/si-service/journals/${journalCode}/editorial-office-contact`;
        const res = await axios_instance.put(
            url,
            {
                code: journalCode,
                email: email,
            },
            {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            }
        );
        if (res.data.code === 200) {
            dispatch({ type: EDIT_EOCONTACT, data: !flag });
            dispatch(setEditType('editCRContactDetail'));
            dispatch(setTextParam(journalName));
            dispatch(openConfirm());
        }
    };
}

export function editMEDetail(email, firstName, lastName, flag, journalName) {
    return async dispatch => {
        const url = `/journal/update/ME`;
        const res = await axios_instance.post(
            url,
            {
                email: email,
                lastName: lastName,
                firstName: firstName,
            },
            {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            }
        );
        if (res.data.code === 200) {
            dispatch({ type: EDIT_MEDETAIL, data: !flag });
            dispatch(setEditType('editCRContactDetail'));
            dispatch(setTextParam(journalName));
            dispatch(openConfirm());
        }
    };
}

export function judgeEmail(email) {
    return async dispatch => {
        try {
            const url = `/journal/checkMEEmail`;
            const res = await axios_instance.get(url, {
                params: {
                    email: email,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({ type: JUDGEEMAIL, data: true });
            } else {
                if (res.data.message === 'the email address has been registered') {
                    dispatch({ type: JUDGEEMAIL, data: false });
                }
            }
        } catch (e) {
            dispatch({ type: JUDGEEMAIL, data: 'error' });
        }
    };
}

export function searchAmeOrMe(key, pageNum, pageSize) {
    return async dispatch => {
        try {
            const url = '/api/v1/si-service/journals/filter-metadata';
            const res = await axios_instance.get(url,
                {
                    params: {
                        type: "meOrAme",
                        searchKey: key,
                        pageNum: pageNum,
                        pageSize: pageSize,
                    },
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                });
            const { code, data } = res.data;
            if (code === 200) {
                dispatch({ type: SEARCHAMEORME, data: data });
            } else {
                dispatch({ type: SEARCHAMEORME, data: 'error' });
            }
        } catch (e) {
            dispatch({ type: SEARCHAMEORME, data: 'error' });
        }
    };
}

export function batchUpdate(value, batchCode, type, hasAmeOrMe) {
    return async dispatch => {
        const url = `/journal/batchUpdate`;
        let body;
        if (hasAmeOrMe) {
            body = {
                email: value['email'],
                lastName: null,
                firstName: null,
                batchCode: batchCode,
            };
        } else {
            body = {
                email: value['email'],
                lastName: value['lastName'],
                firstName: value['firstName'],
                batchCode: batchCode,
            };
        }
        const res = await axios_instance.post(url, body, {
            params: {
                type: type,
            },
            headers: {
                authorization: localStorage.getItem('authorization'),
            },
        });
        const { code, message } = res.data;
        if (code === 200) {
            dispatch({ type: BATCH_UPDATE, data: true });
            dispatch(setEditType('batchUpdateSucceed'));
            dispatch(setTextParam(batchCode));
            dispatch(openConfirm());
        } else {
            if (
                code === 500 &&
                message === 'This email already exists.'
            ) {
                dispatch({ type: BATCH_UPDATE, data: 'EmailExit' });
            }
            dispatch({ type: BATCH_UPDATE, data: false });
        }
    };
}
import { Outlet } from 'react-router-dom';
import { Box, Grid } from '@mui/material';

export default function CommonMainFrame() {
  return (
    <Box display='flex'>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
}

/**
 * 用户列表
 */
import React, { createContext, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import UserRemoveIcon from '@/assets/UserRemoveIcon.svg';
import UserRemoveIconDisable from '@/assets/UserRemoveIconDisable.svg';
import UserLockIcon from '@/assets/UserLockIcon.svg';
import UserLockIconDisable from '@/assets/UserLockIconDisable.svg';
import UserEditIcon from '@/assets/UserEditIcon.svg';
import CheckboxIcon from '@/assets/CheckBoxIcon.svg';
import CheckboxCheckedIcon from '@/assets/CheckBoxIconClick.svg';
import UpdateUser from './UpdateUser';
import { CommonDialog } from '@/modules/AlertDialog';
import { MyTableHeader, TableFooter2 } from '@/components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  addUserSelected,
  addUserSelectedBatch,
  removeAllUserSelected,
  removeUserSelected,
  removeUserSelectedInfo,
  selectAllUser,
  setUserIsAllSelected,
} from '../../../actions/SeleteAction';
import { EDIT_USER, clear, lockClear } from '@/actions/SIMT-User/User/UserAction';
import {
  openConfirm,
  setEditType,
  setPosition,
  setTextParam,
} from '@/actions/SIMT-User/Alert/AlertAction';
import { StyledTableCell } from '../../../components/DataTable';
import TableSkeleton from '../../../components/TableSkeleton';
import { setSnackbarMessageAndOpen } from '../../../actions/SnackbarAction';
import { SEVERITIES } from '../../../modules/ConfirmSnackBar/CommonSnackBar';
import SimpleCheckBox from '@/componentsphase2/Input/SimpleCheckBox';
import { editUser, inactiveUser, unlockUser, sendActiveEmail, deleteUser } from "@/actions/SIMT-User/User/SystemAdmin";
import { unstable_batchedUpdates } from 'react-dom';

//用于显示用户状态的组件
export function StatusBox(props) {
  const {
    index,
    active,
    locked,
    handleActive,
    handleUnlock,
    handleInactive,
    hrefStr,
  } = props;
  var url = window.location.pathname;
  // console.log(url.search("superAdmin"))
  const chipLabel = () => {
    if (locked !== 'locked') {
      return active;
    } else {
      return 'Locked';
    }
  };

  const chipColor = () => {
    if (locked === 'locked') {
      return 'LockedChip';
    } else {
      if (active === 'Active') {
        return 'ActiveChip';
      } else if (active === 'Inactive') {
        return 'InactiveChip';
      }
    }
  };

  const linkLabel = () => {
    if (locked === 'locked') {
      return 'Unlock';
    } else {
      if (active === 'Inactive') {
        return 'Activate';
      } else {
        return '';
      }
    }
  };

  const handleLink = () => {
    if (locked === 'locked') {
      return handleUnlock;
    } else {
      if (active === 'Inactive') {
        return handleActive;
      } else {
        return null;
      }
    }
  };
  const handleInactiveUser = () => {
    return handleInactive;
  };

  return (
    <Stack
      direction='row'
      spacing={1}
      alignItems='center'
      data-selenium-id={`SystemAdmin_UserManagement-UserTable-Stack-${index}`}
    >
      <Chip
        id={`systemAdminUserManagementUserTableStatus${index}`}
        data-selenium-id={`SystemAdmin-UserManagementUserTableStatus-${index}`}
        sx={{
          fontWeight: 400,
          fontSize: '14px',
          padding: '4px 12px',
          height: '28px',
          '& .MuiChip-label': {
            paddingX: 0,
          },
        }}
        label={chipLabel()}
        color={chipColor()}
        size='small'
      // icon={<Brightness1Icon style={{ fontSize: 10 }} />}
      />
      {locked !== 'locked' && active === 'Active' ? (
        <Tooltip
          title='Inactive Link'
          data-selenium-id={`SystemAdmin_UserManagement-UserTable-Stack-Tooltip1-${index}`}
        >
          <Link
            id={`systemAdminUserManagementUserTableInactivateUserLink${index}`}
            data-selenium-id={`SystemAdmin-UserManagementUserTableInactivateUserLink-${index}`}
            className='SuperManageLink'
            sx={{
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '16px',
              letterSpacing: '0.1px',
              maxWidth: '90px',
            }}
            onClick={handleInactiveUser()}
            href={hrefStr}
            mt
          >
            Deactivate
          </Link>
        </Tooltip>
      ) : (
        <Tooltip
          data-selenium-id={`SystemAdmin_UserManagement-UserTable-Stack-Tooltip2-${index}`}
          title={
            active !== 'Active'
              ? 'Resend activation link'
              : url.indexOf('superAdmin') !== -1
                ? 'Unlock system admin'
                : 'Unlock normal user'
          }
        >
          <Link
            id={`systemAdminUserManagementUserTableUnlockAndResendLink${index}`}
            data-selenium-id={`SystemAdmin-UserManagementUserTableUnlockAndResendLink-${index}`}
            className='SuperManageLink'
            sx={{
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '16px',
              letterSpacing: '0.1px',
              maxWidth: '100px',
            }}
            onClick={handleLink()}
            href={hrefStr}
            mt
          >
            {linkLabel()}
          </Link>
        </Tooltip>
      )}
    </Stack>
  );
}

//显示文字的Box，超出长度省略号显示，鼠标悬浮出现tip
function TextLabel(props) {
  const { text, ...other } = props;

  return (
    <Box {...other}>
      <Tooltip title={text}>
        <Box
          component='div'
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {text}
        </Box>
      </Tooltip>
    </Box>
  );
}

export const UserIdContext = createContext(0);
function UserTable(props) {
  const {
    userData,
    Page,
    perPageNumber,
    count,
    handlePerPageChange,
    handlePageChange,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    setPage,
    loadingUserData,
  } = props;

  const [updateEmail, setUpdateEmail] = useState('');
  const [updateFirstName, setUpdateFirstName] = useState('');
  const [updateLastName, setUpdateLastName] = useState('');
  const [updateGroup, setUpdateGroup] = useState('');
  const [updateRole, setUpdateRole] = useState('');
  const [updateStatus, setUpdateStatus] = useState('Inactive');
  const [updateLock, setUpdateLock] = useState('unLocked');
  const [updateOpen, setUpdateOpen] = useState(false);
  const [email, setEmail] = useState();
  const [userId, setUserId] = useState();
  const [type, setType] = useState();
  const [alertOpen, setAlertOpen] = useState(false);
  const [fullName, setfullName] = useState();
  const [updateData, setUpdateData] = useState(false);
  const [userRole, setUserRole] = useState();

  const [lockDialogOpen, setLockDialogOpen] = useState(false);
  const selector = state => {
    return {
      selected: state.Selete.selectedUserList,
      unlock: state.User.unlockUser,
      userResult: state.User.editUserResult,
      InactiveUserResult: state.User.inactiveUser,
      userIds: state.User.userIds,
    };
  };
  const { selected, unlock, userResult, InactiveUserResult, userIds } =
    useSelector(selector);
  const dispatch = useDispatch();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (userResult) {
      if (userResult === 'The user has already been activated') {
        dispatch(setPosition('bottom', 'center'));
        dispatch(setEditType('alreadySentEmail'));
        dispatch(openConfirm());
        handleClose();
        dispatch(clear());
      } else if (userResult === 1 || userResult === true) {
        dispatch(setPosition('bottom', 'center'));
        dispatch(setTextParam(fullName));
        dispatch(setEditType(type));

        switch (type) {
          case 'deleteUser': //remove specified user
            dispatch(
              setSnackbarMessageAndOpen(
                'user.deleteMsg',
                { fullName: fullName },
                SEVERITIES.success
              )
            );
            // clear flag
            dispatch({ type: EDIT_USER, data: false });
            break;
          case 'resendInvitationToUser': //Send activation emails to specified users
            dispatch(
              setSnackbarMessageAndOpen(
                'user.resendMsg',
                { email: email },
                SEVERITIES.success
              )
            );
            break;
          case 'resetUserPass':
            dispatch(
              setSnackbarMessageAndOpen(
                'user.resetMsg',
                { fullName: fullName },
                SEVERITIES.success
              )
            );
            break;
          default:
            dispatch(
              setSnackbarMessageAndOpen(
                'user.success',
                { fullName: fullName },
                SEVERITIES.success
              )
            );
            break;
        }
        // dispatch(openConfirm())
        // dispatch(setSnackbarMessageAndOpen("user.createSucc", {fullname: fullName}, SEVERITIES.success));
        handleClose();
        dispatch(clear());
      } else if (
        userResult === 'SIAssociated' ||
        userResult === 'PreSIAssociated'
      ) {
        dispatch(setPosition('bottom', 'center'));
        dispatch(setTextParam('error,' + fullName));
        dispatch(setEditType(type));
        dispatch(openConfirm());
        handleClose();
        dispatch(clear());
      }
    }
  }, [dispatch, userResult]);

  useEffect(() => {
    if (InactiveUserResult === 'OK') {
      handleClose();
      dispatch(clear());
      setOrder('desc');
      setOrderBy('updateTime');
      setPage(0);
      dispatch(
        setSnackbarMessageAndOpen('user.deactivateSucc', {}, SEVERITIES.success)
      );
    } else if (InactiveUserResult === 'HEInactive') {
      handleClose();
      dispatch(setPosition('bottom', 'center'));
      dispatch(setTextParam('error,' + fullName));
      dispatch(
        setSnackbarMessageAndOpen('user.userHasSI', {}, SEVERITIES.error)
      );
      // dispatch(setEditType("HEUserInactive"))
      // dispatch(openConfirm())
      dispatch(clear());
    }
  }, [dispatch, InactiveUserResult]);

  useEffect(() => {
    if (
      (userData.result === null || userData.result.length === 0) &&
      Page !== 1
    ) {
      // just a workaround when exceeding the max page
      handlePageChange(null, 1);
      return;
    }
    if (selected.length === 0) {
      dispatch(setUserIsAllSelected(false));
      return;
    }
    let isAllSelectedTemp = true;
    for (const userItem of userData.result) {
      if (!isSelected(userItem.userId)) {
        isAllSelectedTemp = false;
        break;
      }
    }

    dispatch(setUserIsAllSelected(isAllSelectedTemp));
    // dispatch(setUserIsAllSelected(userIds.length == selected.length));
  }, [userData, selected]);

  const isSelected = (name, index) => {
    return selected.indexOf(name) !== -1;
  };

  const items = [
    { id: 'fullName', label: 'Full Name', minWidth: '218px', isOrder: true },
    { id: 'email', label: 'Primary Email', minWidth: '280px', isOrder: true },
    { id: 'group', label: 'Group/Title', minWidth: '176px', isOrder: true },
    { id: 'role', label: 'Role', minWidth: '150px', isOrder: true },
    {
      id: 'status',
      label: 'Account Status',
      minWidth: '226px',
      isOrder: false,
    },
    { id: 'actions', label: 'Actions', minWidth: 'auto', isOrder: false },
  ];

  //当解锁用户成功后
  useEffect(() => {
    if (unlock) {
      setOrder('desc');
      setOrderBy('updateTime');
      setPage(0);
      dispatch(
        setSnackbarMessageAndOpen(
          'user.unlockMsg',
          { fullName },
          SEVERITIES.success
        )
      );
    }

    return () => {
      dispatch(lockClear());
    };
  }, [unlock]);

  const handleUpdateUser = (
    email,
    firstName,
    lastName,
    group,
    role,
    status,
    lock
  ) => {
    return () => {
      setUpdateData(!updateData);
      setUpdateEmail(email);
      setUpdateFirstName(firstName);
      setUpdateLastName(lastName);
      setUpdateGroup(group);
      setUpdateRole(role);
      setUpdateStatus(status);
      setUpdateLock(lock);
      setUpdateOpen(true);
    };
  };

  const handleResend = (email, fullName, userId) => {
    return () => {
      setfullName(fullName);
      setEmail(email);
      setType('resendInvitationToUser');
      setAlertOpen(true);
      setUserId(userId);
    };
  };

  const handleInactive = (email, fullName, userId) => {
    return () => {
      setfullName(fullName);
      setEmail(email);
      setType('InactiveUser');
      setAlertOpen(true);
      setUserId(userId);
    };
  };

  const handleReset = (email, fullName, userId) => {
    return () => {
      setfullName(fullName);
      setEmail(email);
      setType('resetUserPass');
      setAlertOpen(true);
      setUserId(userId);
    };
  };

  const handleDelete = (email, fullName, role, userId) => {
    return () => {
      setfullName(fullName);
      setUserRole(role);
      setEmail(email);
      setUserId(userId);
      setType('deleteUser');
      setAlertOpen(true);

    };
  };

  const handleClose = () => {
    setAlertOpen(false);
  };

  //处理单击unlock链接
  const handleUnlock = (email, fullName) => {
    return () => {
      setEmail(email);
      setfullName(fullName);
      setLockDialogOpen(true);
    };
  };

  //处理单击复选框事件
  const handleCheck = user => {
    if (user.userId !== undefined) {
      const selectedIndex = selected.indexOf(user.userId);
      if (selectedIndex > -1) {
        dispatch(removeUserSelected(user.userId));
        dispatch(removeUserSelectedInfo(user.userId));
      } else {
        if (selected.length === count - 1) {
          dispatch(selectAllUser());
        }
        dispatch(addUserSelected(user));
      }
    }
  };


  //处理单击确认解锁用户
  const handleUnlockConfirm = async () => {
    await dispatch(unlockUser(email));
    setLockDialogOpen(false);
  };

  const handleSelectAll = () => {
    // 选中当前展示的所有数据
    // for (const userElement of props.rows) {
    //   if (userElement.userId !== undefined) {
    //     dispatch(addUserSelected(userElement));
    //   }
    // }
    // dispatch(setUserIsAllSelected(true));
    dispatch(addUserSelectedBatch(userIds));
  };

  const handleUnselectAll = () => {
    // 取消选中当前的所有数据
    // for (const userElement of props.rows) {
    //   dispatch(removeUserSelected(userElement.userId));
    //   dispatch(removeUserSelectedInfo(userElement.userId));
    // }
    // dispatch(setUserIsAllSelected(false));
    dispatch(removeAllUserSelected());
  };

  //根据类型返回对话框标题
  const handleDialogTitle = () => {
    switch (type) {
      case 'resendInvitationToUser':
        return 'Resend invitation link to ' + email;
      case 'resetUserPass':
        return 'Reset user ' + fullName + "'s password";
      case 'deleteUser':
        return 'Remove ' + userRole + ' user ' + fullName;
      case 'InactiveUser':
        return 'Inactive user ' + fullName;
      default:
        return '';
    }
  };

  //处理对话框点击Confirm逻辑
  const handleDialogConfirm = (userId) => {
    return async () => {
      if (type === 'InactiveUser') {
        console.log('userId',userId);
        await dispatch(inactiveUser(userId));
      } else {
        if (type === 'deleteUser') {
          await dispatch(deleteUser(userId));
          setAlertOpen(false);
        } else if (type === 'resendInvitationToUser' || type === 'resetUserPass') {
          await dispatch(sendActiveEmail(userId));
          setAlertOpen(false);
        }
      }

    };
  };

  return (
    <Box data-seleunim-id='SystemAdmin_UserManagement-UserTable-Box'>
      <UserIdContext.Provider value={userId ?? 0}>
        <UpdateUser
          data-seleunim-id='SystemAdmin_UserManagement-UserTable-Box-UpdateUser'
          updateOpen={updateOpen}
          setUpdateOpen={setUpdateOpen}
          Email={updateEmail}
          FirstName={updateFirstName}
          LastName={updateLastName}
          Group={updateGroup}
          Role={updateRole}
          Status={updateStatus}
          Lock={updateLock}
          updateData={updateData}
        />
      </UserIdContext.Provider>
      {/*<p>User Ids:{userIds?.length}</p>*/}
      <TableContainer
        sx={{
          minHeight: '397px',
          backgroundColor: '#fff',
          '& .MuiTableCell-root, & .MuiTable-root .MuiTableHead-root .MuiTableCell-root':
          {
            paddingX: '24px !important',
            boxSizing: 'border-box',
          },
          '& .UserManagement-UserTable-Row-Checkbox': {
            width: 0,
          },
        }}
        data-seleunim-id='SystemAdmin_UserManagement-UserTable-Box-TableContainer'
      >
        <Table
          stickyHeader
          aria-label='simple table'
          size='small'
          data-seleunim-id='SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table'
        >
          <MyTableHeader
            data-seleunim-id='SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-MyTableHeader'
            hasCheckbox
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            items={items}
            forUserTable={true}
            handleSelectAll={handleSelectAll}
            handleUnselectAll={handleUnselectAll}
            CheckboxColor='Grey600'
            checkboxIcon={<CheckboxIcon />}
            checkboxCheckedIcon={<CheckboxCheckedIcon />}
            ifIndeterminate={true}
            selected={selected}
            allNum={userIds.length}
          />
          {loadingUserData ? (
            <TableBody data-seleunim-id='SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody1'>
              <TableSkeleton
                num={9}
                colSpan={36}
                data-seleunim-id='SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody-TableSkeleton'
              />
            </TableBody>
          ) : (
            <TableBody data-seleunim-id='SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2'>
              {props.rows?.map((row, index) => {
                const isItemSelected = isSelected(row.userId, index);
                return (
                  <TableRow
                    data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-${index}`}
                    key={row.userId}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '& .MuiTableCell-root': {
                        fontSize: '14px',
                        fontWeight: '400',
                        color: '#262E35',
                      },
                    }}
                  >
                    {/* 复选框按钮 */}
                    <StyledTableCell
                      className='UserManagement-UserTable-Row-Checkbox'
                      data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell1-${index}`}
                    >
                      {/* <Checkbox
                        id={`systemAdminUserManagementUserTableCheckbox${index}`}
                        data-selenium-id={`SystemAdmin-UserManagementUserTableCheckbox-${index}`}
                        color='Grey600'
                        onClick={handleCheck.bind(this, row)}
                        checked={isItemSelected}
                        icon={<CheckboxIcon />}
                        checkedIcon={<CheckboxCheckedIcon />}
                      /> */}
                      <SimpleCheckBox
                        id={`systemAdminUserManagementUserTableCheckbox${index}`}
                        data-selenium-id={`SystemAdmin-UserManagementUserTableCheckbox-${index}`}
                        // color='Grey600'
                        onClick={handleCheck.bind(this, row)}
                        checked={isItemSelected}
                      // icon={<CheckboxIcon />}
                      // checkedIcon={<CheckboxCheckedIcon />}
                      />
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{ fontWeight: 600, maxWidth: '110px' }}
                      id={`systemAdminUserManagementUserTableUserName${index}`}
                      data-selenium-id={`SystemAdmin-UserManagementUserTableUserName-${index}`}
                    >
                      <TextLabel
                        text={row.lastName + ', ' + row.firstName}
                        data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell2-TextLabel1-${index}`}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      id={`systemAdminUserManagementUserTableUserEmail${index}`}
                      data-selenium-id={`SystemAdmin-UserManagementUserTableUserEmail-${index}`}
                      align='left'
                      sx={{ maxWidth: '160px' }}
                    >
                      <TextLabel
                        text={row.email}
                        data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell2-TextLabel2-${index}`}
                      />
                    </StyledTableCell>

                    <StyledTableCell
                      id={`systemAdminUserManagementUserTableUserGroup${index}`}
                      data-selenium-id={`SystemAdmin-UserManagementUserTableUserGroup-${index}`}
                      align='left'
                    >
                      {row.group}
                    </StyledTableCell>
                    <StyledTableCell
                      id={`systemAdminUserManagementUserTableUserRole${index}`}
                      data-selenium-id={`SystemAdmin-UserManagementUserTableUserRole-${index}`}
                      align='left'
                      sx={{ maxWidth: '50px' }}
                    >
                      <TextLabel
                        text={row.role}
                        data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell2-TextLabel3-${index}`}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell3-${index}`}
                    >
                      <StatusBox
                        data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell3-StatusBox-${index}`}
                        index={index}
                        active={row.status}
                        locked={row.userStatus === '1'? 'locked':'unlocked'}
                        hrefStr={'#!' + row.email + row.status}
                        handleActive={handleResend(
                          row.email,
                          row.lastName + ', ' + row.firstName,
                          row.userId
                        )}
                        handleUnlock={handleUnlock(
                          row.email,
                          row.lastName + ', ' + row.firstName
                        )}
                        handleInactive={handleInactive(
                          row.email,
                          row.lastName + ', ' + row.firstName,
                          row.userId
                        )}
                      />
                    </StyledTableCell>

                    {/* Actions 操作按钮组 */}
                    <StyledTableCell
                      data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell4-${index}`}
                    >
                      <Stack
                        data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell4-Stack-${index}`}
                        direction='row'
                        alignItems='center'
                        spacing='12px'
                        sx={{
                          '& .UserTable__ActionButton': {
                            height: '28px',
                            width: '28px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#F6F7F8',
                            border: '1px solid #DFE4E8',
                            '& .MuiButtonBase-root.MuiIconButton-root': {
                              padding: 0,
                              width: '24px',
                              height: '24px',
                            },
                          },
                        }}
                      >
                        <Box
                          className={'UserTable__ActionButton'}
                          data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell4-Stack-Box1-${index}`}
                        >
                          {/* Reset user password */}
                          {
                            <Tooltip
                              title='Reset user password'
                              data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell4-Stack-Box1-Tooltip-${index}`}
                            >
                              <span>
                                <IconButton
                                  id={`systemAdminUserManagementUserTableResetPwd${index}`}
                                  data-selenium-id={`SystemAdminUserManagement_UserTableResetPwd-${index}`}
                                  onClick={handleReset(
                                    row.email,
                                    row.lastName + ', ' + row.firstName, row.userId
                                  )}
                                  disabled={row.status !== 'Active'}
                                >
                                  {row.status === 'Active' ? (
                                    <UserLockIcon
                                      data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell4-Stack-Box1-Tooltip-IconButton-UserLockIcon-${index}`}
                                    />
                                  ) : (
                                    <UserLockIconDisable
                                      data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell4-Stack-Box1-Tooltip-IconButton-UserLockIconDisable-${index}`}
                                    />
                                  )}
                                </IconButton>
                              </span>
                            </Tooltip>
                          }
                        </Box>
                        <Box
                          className={'UserTable__ActionButton'}
                          data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell4-Stack-Box2-${index}`}
                        >
                          {/* Remove user */}
                          {
                            <Tooltip
                              title={
                                row.hasSi === 0 &&
                                  row.hasOptInJournal === 0 &&
                                  row.hasSip === 0 &&
                                  row.hasJournal === 0
                                  ? 'Remove User'
                                  : 'User linked with journal or SI or SIP or Cat journal'
                              }
                              data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell4-Stack-Box2-Tooltip-${index}`}
                            >
                              <span>
                                <IconButton
                                  id={`systemAdminUserManagementUserTableRemoveUser${index}`}
                                  data-selenium-id={`SystemAdmin-UserManagementUserTableRemoveUser-${index}`}
                                  onClick={handleDelete(
                                    row.email,
                                    row.lastName + ', ' + row.firstName,
                                    row.role,
                                    row.userId
                                  )}
                                  disabled={
                                    !(
                                      row.hasSi === 0 &&
                                      row.hasOptInJournal === 0 &&
                                      row.hasSip === 0 &&
                                      row.hasJournal === 0
                                    )
                                  }
                                >
                                  {row.hasSi === 0 &&
                                    row.hasOptInJournal === 0 &&
                                    row.hasSip === 0 &&
                                    row.hasJournal === 0 ? (
                                    <UserRemoveIcon
                                      data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell4-Stack-Box2-Tooltip-IconButton-UserRemoveIcon-${index}`}
                                    />
                                  ) : (
                                    <UserRemoveIconDisable
                                      data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell4-Stack-Box2-Tooltip-IconButton-UserRemoveIconDisable-${index}`}
                                    />
                                  )}
                                </IconButton>
                              </span>
                            </Tooltip>
                          }
                        </Box>
                        <Box
                          className='UserTable__ActionButton'
                          data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell4-Stack-Box3-${index}`}
                        >
                          {/* Edit user information */}
                          <Tooltip
                            title='Edit user information'
                            data-selenium-id={`SystemAdmin_UserManagement-UserTable-Box-TableContainer-Table-TableBody2-TableRow-StyledTableCell4-Stack-Box3-Tooltip-${index}`}
                          >
                            <IconButton
                              id={`systemAdminUserManagementUserTableEditUser${index}`}
                              data-selenium-id={`SystemAdmin_UserManagement-UserTableEditUser-${index}`}
                              onClick={() => unstable_batchedUpdates(() => {
                                setUserId(row.userId);
                                handleUpdateUser(
                                  row.email,
                                  row.firstName,
                                  row.lastName,
                                  row.group,
                                  row.role,
                                  row.status,
                                  row.lock
                                )();
                              })}
                            >
                              <UserEditIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Stack>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Divider data-seleunim-id='SystemAdmin_UserManagement-UserTable-Box-Divider' />
      <TableFooter2
        data-seleunim-id='SystemAdmin_UserManagement-UserTable-Box-TableFooter2'
        numSelected={selected.length}
        perPageList={[10, 20, 50]}
        Page={Page}
        perPageNumber={perPageNumber}
        count={count}
        handlePerPageChange={handlePerPageChange}
        handlePageChange={handlePageChange}
        className='UserTable__Footer'
      />
      <CommonDialog
        id='systemAdminUserManagementActions'
        data-selenium-id='SystemAdmin_UserManagement-UserTable-Box-TableFooter2-CommonDialog1-SystemAdminUserManagementActions'
        title={handleDialogTitle()}
        content='Are you sure you want to proceed ?'
        alertOpen={alertOpen}
        handleClose={handleClose}
        handleConfirm={handleDialogConfirm(userId)}
      />
      <CommonDialog
        id='systemAdminUserManagementUnlock'
        data-selenium-id='SystemAdmin_UserManagement-UserTable-Box-TableFooter2-CommonDialog2-SystemAdminUserManagementUnlock'
        title={`Unlock normal user ${fullName}`}
        content={`Are you sure you want to unlock ${fullName} ?`}
        alertOpen={lockDialogOpen}
        handleClose={() => {
          setLockDialogOpen(false);
        }}
        handleConfirm={handleUnlockConfirm}
      />
    </Box>
  );
}

export default UserTable;

import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import {
  ReportNoData,
  StyledTableCellNormal,
  StyledTableRow,
  TableBox, TableBox2
} from "../../ReportSpecificComponents/SpecialColorTableHeader";
import { StyledTableCell, TableFooter2 } from '@/components/DataTable';
import { StyledTableCellPurple } from '../../ReportSpecificComponents/SpecialColorTableHeader';
import { useDispatch, useSelector } from 'react-redux';
import { visuallyHidden } from '@mui/utils';

import { isReportOverCutOffDate } from '@/utils/commonUtils';
import { findLongestLineSubstring, measureWidth } from '../../../../utils/StringWidthMeasure';
import { TextLabel } from '../../../../componentsphase2/CustomTooltip';
import { useShowReportRolesByFyHook } from '@/pages/Report/utils/useShowReportRolesByFyHook';
import {getReportAcquireOverviewBasicTableData} from "@/actions/SIMT-Report/Report/ReportAcquiredAndActive";

export default function AcquireOverviewBasicTable(props) {
  const { setIfNoResult } = props;
  const [loading, setLoading] = useState(false);
  const [showJPM, showNewJournalRoles] = useShowReportRolesByFyHook();

  const createRow = (
    id,
    label,
    minWidth,
    maxWidth,
    isOrder = false,
    align = 'left',
    show = true
  ) => {
    return { id, label, minWidth, maxWidth, isOrder, align, show };
  };

  const itemsBeforeJPM = useMemo(() => {
    return [
      createRow(
        'journalName',
        'Journal Information',
        '180px',
        '220px',
        false,
        'left'
      ),
      createRow('revenueModel', 'Revenue Model', '180px', '180px', false, 'left'),
      createRow(
        'journalStatus',
        'Journal Status',
        '180px',
        '180px',
        false,
        'left'
      ),
    ];
  },[]);

  const itemsAfterJPM = useMemo(() => {
    return [
      createRow('subjectGroup', 'Subject Group', '180px', '180px', false, 'left'),
      createRow(
        'businessDivision',
        'Business Division',
        '180px',
        '340px',
        false,
        'left'
      ),
      createRow('catGroup', 'CAT Group', '180px', '340px', false, 'left'),
      createRow('handlingCe', 'Handling CE', '180px', '340px', false, 'left'),
      createRow('acquiredSi', 'Acquired SI - ', '180px', '340px', false, 'left'),
      createRow(
        'acquiredYtd',
        'Acquired SI - YTD',
        '180px',
        '340px',
        false,
        'left'
      ),
      createRow(
        'ytdTarget',
        '% Against YTD target',
        '180px',
        '340px',
        false,
        'left'
      ),
      createRow('activeSi', 'Active SI', '180px', '340px', false, 'left')
    ];
  },[]);

  const JPMItem = useMemo(() => {
    return createRow('jpm', 'JPM', '105px', '180px', false, 'left');
  }, []);

  const pdpItem = useMemo(() => {
    return createRow(
      'pdPublisher',
      'PD Publisher',
      '105px',
      '180px',
      false,
      'left'
    );
  }, []);

  const pppItem = useMemo(() => {
    return createRow(
      'ppPublisher',
      'PP Publisher',
      '105px',
      '180px',
      false,
      'left'
    );
  }, []);

  const [page, setPage] = useState(1);
  const [perPageNumber, setPerPageNumber] = useState(20);

  const [orderType, setOrderType] = useState('journalName'); //排序的表头
  const [orderAs, setOrderAs] = useState('asc'); //排序的顺序
  const [flashLock, setFlashLock] = useState(true);

  const dispatch = useDispatch();
  const {
    requireOverviewBasicTableData,
    tableMonth,
    reportAcquireOverviewFilters,
    selectedAcquireDate,
    cutOffDate
  } = useSelector(state => {
    return {
      // filter display
      requireOverviewBasicTableData: state.Report.requireOverviewBasicTableData,
      tableMonth: state.Report.requireOverviewBasicTableMonth,
      selectedAcquireDate: state.Report.selectDate,
      reportAcquireOverviewFilters:
        state.ReportFilter.reportAcquireOverviewFilters,
      cutOffDate: state.Report.cutOffDate,
    };
  });

  const items = useMemo(() => {
    let flexItem = [];
    if (showJPM) {
      flexItem = [...flexItem, JPMItem];
    }
    if (showNewJournalRoles) {
      flexItem = [...flexItem, pdpItem, pppItem];
    }
    return [...itemsBeforeJPM, ...flexItem, ...itemsAfterJPM];
  }, [showJPM, showNewJournalRoles]);

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    for (let item of items){
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '18px Open Sans');
    }
    setColumnMinWidthMap(widthMap);
  }, [items]);

  useEffect(() => {
    if (requireOverviewBasicTableData && requireOverviewBasicTableData.count > 0){
      const maxMap = {};
      for (let dataObj of requireOverviewBasicTableData['result']) {
        dataObj['jpm'] = dataObj.lastNameOfJpm === null
          ? ''
          : (dataObj.lastNameOfJpm + ', ' + dataObj.firstNameOfJpm);
        // PD Publisher
        dataObj['pdPublisher'] = dataObj.fullNameOfPdp;

        // PP Publisher
        dataObj['ppPublisher'] = dataObj.fullNameOfPpp;
        dataObj['handlingCe'] = dataObj.lastNameOfHandlingCe === null
          ? ''
          : (dataObj.lastNameOfHandlingCe + ', ' + dataObj.firstNameOfHandlingCe);
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      widthMap['journalName'] = Math.max(widthMap['journalCode'],widthMap['journalName']);
      widthMap['subjectGroup'] = widthMap['sgAbbr'];

      setColumnWidthMap(widthMap);
    }
  }, [requireOverviewBasicTableData]);


  const handlePerPageChange = e => {
    setPerPageNumber(e.target.value);
  };

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  const handleChangeOrder = orderBy => {
    let newOrder;
    if (orderType === orderBy) {
      // 如果当前的排序字段没有变化，则切换正反序
      newOrder = orderAs === 'desc' ? 'asc' : 'desc';
    } else {
      // 如果当前排序字段发生变化，则直接按照新的字段升序
      newOrder = 'asc';
    }
    setOrderAs(newOrder);
    setOrderType(orderBy);
  };

  function handleNull(num) {
    if (num === null || num === undefined) {
      return '-';
    } else {
      return num;
    }
  }

  const ifEmptyFilter = () => {
    try {
      if (
        reportAcquireOverviewFilters.journalName.length === 0 &&
        reportAcquireOverviewFilters.revenueModel.length === 0 &&
        reportAcquireOverviewFilters.jpm.length === 0 &&
        reportAcquireOverviewFilters.subjectGroup.length === 0 &&
        reportAcquireOverviewFilters.businessDivision.length === 0 &&
        reportAcquireOverviewFilters.catGroup.length === 0 &&
        reportAcquireOverviewFilters.handlingCe.length === 0
      ) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (!flashLock) {
        await dispatch(
          getReportAcquireOverviewBasicTableData(
            page,
            perPageNumber,
            orderAs,
            orderType,
            selectedAcquireDate,
            reportAcquireOverviewFilters
          )
        );
      }
      setLoading(false);
      setFlashLock(false);
    }
    setLoading(true);
    if (page === 0) {
      if (!flashLock) {
        setTimeout(() => {
          setPage(1);
        }, 2000);
      }
    } else {
      fetchData();
    }
  }, [page]); // 表格本身属性触发的加载，一切按照默认

  useEffect(() => {
    setFlashLock(false);
    setPage(0);
  }, [
    reportAcquireOverviewFilters,
    perPageNumber,
    orderType,
    orderAs,
    selectedAcquireDate,
  ]); // 表格本身属性触发的加载，一切按照默认

  useEffect(() => {
    var filterEmpty = ifEmptyFilter();
    if (
      (requireOverviewBasicTableData === null ||
        requireOverviewBasicTableData === undefined ||
        requireOverviewBasicTableData.count === 0) &&
      filterEmpty
    ) {
      setIfNoResult(true);
    } else {
      setIfNoResult(false);
    }
  }, [requireOverviewBasicTableData]);

  return (
    <TableBox2
      dataList={[requireOverviewBasicTableData,loading,flashLock]}
      tableKey={'Report_AcquireOverview-Overview-BasicTable-Box'}
      footerHeight={40}
      id='BasicTableBox'
      data-selenium-id='Report_AcquireOverview-Overview-BasicTable-Box'
    >
      <TableContainer
        sx={{ minHeight: '300px', maxHeight: '680px' }}
        data-selenium-id='Report_AcquireOverview-Overview-BasicTable-TableContainer'
      >
        <Table
          stickyHeader
          aria-label='simple table'
          size='small'
          data-selenium-id='Report_AcquireOverview-Overview-BasicTable-Table'
          sx={{
            tableLayout: 'fixed',
            width: 'max-content',
          }}
        >
          <TableHead data-selenium-id='Report_AcquireOverview-Overview-BasicTable-TableHead'>
            <TableRow data-selenium-id='Report_AcquireOverview-Overview-BasicTable-TableHead-TableRow'>
              {items.map((item, index) => (
                <StyledTableCellNormal
                  id={`reportAAASOverviewBTHead${index}`}
                  data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableHead-StyledTableCellPurple-${index}`}
                  key={item.label}
                  align={item.align}
                  rowSpan={2}
                  sx={{
                    height: '50px',
                    backgroundColor: '#F1F3F5',
                    ...(item.id==='journalName' && {
                      position: 'sticky',
                      left: 0,
                      top: 0,
                      zIndex: 8,
                      backgroundColor: '#DFE4E8 !important',
                    }),
                    ...({
                      width: `${Math.max((columnWidthMap[item.id]??0), columnMinWidthMap[item.id]) + 50 + 18 }px`,
                    }),

                  }}
                  sortDirection={orderType === item.id ? orderType : false}
                >
                  {
                    <TableSortLabel
                      id={`column-${item.id}`}
                      data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableHead-StyledTableCellPurple${index}-TableSortLabel${item.id}`}
                      active={item.isOrder && orderType === item.id}
                      direction={orderType === item.id ? orderAs : 'asc'}
                      onClick={() => {
                        // if (item.isOrder) {
                        //   handleChangeOrder(item.id);
                        // }

                        //当前所有字段都可以排序 所以注释上面三行
                        handleChangeOrder(item.id);
                        dispatch(
                          getReportAcquireOverviewBasicTableData(
                            page,
                            perPageNumber,
                            orderAs,
                            item?.id,
                            selectedAcquireDate,
                            reportAcquireOverviewFilters
                          )
                        );
                      }}
                    >
                      {item.id === 'acquiredSi'
                        ? item.label + tableMonth
                        : item.label}
                      {orderType === item.id ? (
                        <Box component='span' sx={visuallyHidden}>
                          {orderAs === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  }
                </StyledTableCellNormal>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            data-selenium-id='Report_AcquireOverview-Overview-BasicTable-TableBody'
            sx={{
              '& td': {
                backgroundColor: '#FFFFFF',
              }
            }}
          >
            {!loading &&
            requireOverviewBasicTableData != null &&
            requireOverviewBasicTableData.count > 0 ? (
                requireOverviewBasicTableData['result']?.map((row, index) => {
                  return (
                    <StyledTableRow
                      key={row.id}
                      aria-checked={false}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      selected={false}
                      data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}`}
                    >
                      <StyledTableCell
                        sx={{
                          fontWeight: 600,
                          // maxWidth: '110px',
                          position: 'sticky',
                          left: 0,
                          zIndex: 6,
                          backgroundColor: '#F1F3F5',
                        }}
                        data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell1`}
                      >
                        <Box
                          sx={{
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '14px',
                            color: '#484848',
                          }}
                          data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell1-Box1`}
                        >
                          {row.journalCode}
                        </Box>
                        <Box
                          sx={{
                            // fontSize: '14px',
                            color: '#747474',
                            fontWeight: 400,
                          }}
                          data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell1-Box2`}
                        >
                          {row.journalName}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell2`}
                      >
                        {row.revenueModel}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell3`}
                      >
                        {row.journalStatus}
                      </StyledTableCell>

                      {/* JPM */}
                      {showJPM && (
                        <StyledTableCell
                          align='left'
                          sx={{ maxWidth: '50px' }}
                          data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell4-JPM`}
                        >
                          <TextLabel
                            text={
                              row.lastNameOfJpm === null
                                ? ''
                                : row.lastNameOfJpm + ', ' + row.firstNameOfJpm
                            }
                            TooltipText={row.emailOfJpm}
                          />
                        </StyledTableCell>
                      )}

                      {/* PD Publisher */}
                      {showNewJournalRoles && (
                        <StyledTableCell
                          align='left'
                          sx={{ maxWidth: '50px' }}
                          data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell4-PDP`}
                        >
                          <TextLabel
                            text={row.fullNameOfPdp}
                            TooltipText={row.emailOfPdp}
                          />
                        </StyledTableCell>
                      )}

                      {/* PP Publisher */}
                      {showNewJournalRoles && (
                        <StyledTableCell
                          align='left'
                          sx={{ maxWidth: '50px' }}
                          data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell4-PPP`}
                        >
                          <TextLabel
                            text={row.fullNameOfPpp}
                            TooltipText={row.emailOfPpp}
                          />
                        </StyledTableCell>
                      )}

                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell5`}
                      >
                        <TextLabel
                          TooltipText={row.subjectGroup}
                          text={row.sgAbbr}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell6`}
                      >
                        <TextLabel text={row.businessDivision} />
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell7`}
                      >
                        <TextLabel text={row.catGroup} />
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell8`}
                      >
                        <TextLabel
                          text={
                            row.lastNameOfHandlingCe === null
                              ? ''
                              : row.lastNameOfHandlingCe +
                                ', ' +
                                row.firstNameOfHandlingCe
                          }
                          TooltipText={row.emailOfHandlingCe}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell9`}
                      >
                        <TextLabel text={row.acquiredSi} />
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell10`}
                      >
                        <TextLabel text={row.acquiredYtd} />
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell11`}
                      >
                        <TextLabel text={handleNull(row.ytdTarget)} />
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell12`}
                      >
                        <TextLabel text={row.activeSi} />
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : !loading ? (
                <ReportNoData top='50px' id={'Report-Acquire-Overview-BasicTable-NoData'}/>
              ) : (
                <div></div>
              )}
          </TableBody>
        </Table>
        {loading && (
          <Box
            sx={{
              width: 'auto',
              marginTop: '150px',
              marginLeft: '50%',
            }}
            data-selenium-id='Report_AcquireOverview-Overview-BasicTable-LoadingCircularProgress'
          >
            <CircularProgress />
          </Box>
        )}
      </TableContainer>
      <Box data-selenium-id='Report_AcquireOverview-Overview-BasicTable-TableFooterBox'>
        <Divider />
        {requireOverviewBasicTableData != null &&
        requireOverviewBasicTableData['count'] > 0 ? (
            <TableFooter2
              numSelected={0}
              Page={page}
              perPageNumber={perPageNumber}
              perPageList={[20, 50, 100]}
              count={
                requireOverviewBasicTableData != null
                  ? requireOverviewBasicTableData['count']
                  : 0
              }
              handlePerPageChange={handlePerPageChange}
              handlePageChange={handlePageChange}
              // backgroundColor='#CCD4FF'
              backgroundColor='#F1F3F5'
              data-selenium-id='Report_AcquireOverview-Overview-BasicTable-TableFooter2'
            />
          ) : (
            <div></div>
          )}
      </Box>
    </TableBox2>
  );
}

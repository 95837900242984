import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Styles
import { MiniFont, TinyFont } from '@/components/FontStyle';
import { AllCentered, LimitWord } from '@/pages/SIPage/Overview/Filter/component/Css';
// Components
import {
  Box,
  Checkbox,
  TextField,
  Chip,
  Stack,
  Popper,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';
import { GETooltipIf } from '@/componentsphase2/SelectBox/SelectInput';
import { StyledFirstSectionAutoComplete } from '@/pages/SIPage/Overview/Filter/component/StyledComponents/styledAutocomplete';
// Assets
import ChipClearIcon from '@/pages/SIPage/Overview/Filter/component/SVGComponents/ChipClearIcon.svg';
import CheckboxCheckedIcon from '@/pages/SIPage/Overview/Filter/component/SVGComponents/CheckboxCheckedIcon.svg';
import CheckboxIcon from '@/pages/SIPage/Overview/Filter/component/SVGComponents/CheckboxIcon.svg';
import SearchIcon from '@/pages/SIPage/Overview/Filter/component/SVGComponents/SearchIcon.svg';
import Off from '@/pages/SIPage/Overview/Filter/component/SVGComponents/Off.svg';
// Actions
import { setSelect } from '@/actions/SIMT-SIP/SIPFilter/SIPFilterAction';
// Utils
import {
  calcDropListHeight,
  getFieldByLabel,
  getPlaceHolderByLabel,
  getThemeByField,
  getThemeColor,
  checkIsObjectArray
} from '../utils';
import { measureWidth } from '@/utils/StringWidthMeasure';

export default function SelectBox(props) {
  const {
    data = [],
    labelName, //used to generate Id
    value: selectedList,
    setSelected,
    loadMore,
    handleSwitchTab,
    ...other
  } = props;
  const dataIsObjectArray = checkIsObjectArray(data);
  const [open, setOpen] = useState(false);
  const identifier = labelName.replace(' ', '-');
  const [inputText, setInputText] = useState('');
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(null);
  const [stateHover, setStateHover] = useState(false);
  const [stateFocus, setStateFocus] = useState(false);
  const [isPopIconShow, setISPopIconShow] = useState(false);

  const { sipFilterSelected } = useSelector(state => {
    return {
      sipFilterSelected: state.SIPFilter.sipFilterSelected,
    };
  });
  const cssTheme = createTheme({
    themeColor: getThemeColor(getThemeByField(getFieldByLabel(labelName))),
  });

  const dispatch = useDispatch();

  async function loadMoreOptions() {
    await loadMore(inputText, 1, 20);
  }

  useEffect(() => {
    if (loadMore) {
      setLoading(true);
      clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          loadMoreOptions().then(() => {
            setLoading(false);
          });
        }, 300)
      );
    }
  }, [inputText]);

  const handleInputTextChange = e => {
    setInputText(e.target.value);
  };

  const getOptionName = option => {
    return dataIsObjectArray
      ? `${option.username} (${option.email})`
      : option;
  };
  const getOptionId = option => {
    return dataIsObjectArray ? option.userId : getOptionName(option);
  };

  const popupIcon = (
    <Box
      sx={{
        ...TinyFont,
        color: '#154AB6',
        letterSpacing: '0em',
        textAlign: 'center',
      }}
      onClick={e => {
        e.stopPropagation();
        setOpen(false);
        setInputText('');
        handleSwitchTab({ label: labelName, data: [inputText] });
      }}
    >
      Add
    </Box>
  );


  useEffect(() => {
    if (labelName === 'SIP Title' ||
      labelName === 'Topics' ||
      labelName === 'Keywords' ||
      labelName === 'Aims and Scope'
    ) {
      if (inputText === '') {
        setISPopIconShow(true);
      } else {
        if (stateFocus) {
          setISPopIconShow(true);
        } else {
          setISPopIconShow(false);
        }
      }
    } else {
      if (selectedList.length === 0) {
        setISPopIconShow(true);
      } else {
        if (stateFocus) {
          setISPopIconShow(true);
        } else {
          setISPopIconShow(false);
        }
      }
    }
  }, []);

  function isValueInSipfilterSelected(value) {
    let selectData = sipFilterSelected[getFieldByLabel(labelName) + 'Selected'];
    if (dataIsObjectArray) {
      for (let i = 0; i < selectData.length; i++) {
        if (selectData[i].userId === value.userId) {
          return i;
        }
      }
      return -1;
    } else {
      return selectData.indexOf(value);
    }
  }

  return (
    <ThemeProvider theme={cssTheme}>
      <Box
        sx={{ position: 'relative' }}
        data-selenium-id={`SIPPageOverview-Filter-SelectBox-${identifier}-Box`}
      >
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            top: '12px',
            left: '12px',
            zIndex: '2',
          }}
        >
          <SearchIcon />
        </Box>
        {inputText !== '' && (stateHover || stateFocus) && (
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              top: '12px',
              right: isPopIconShow ? '38.28px' : '12px',
              zIndex: '2',
              cursor: 'pointer',
              ':hover': {
                '& svg path': {
                  fill: '#262E35',
                },
              },
            }}
            onClick={() => {
              setInputText('');
            }}
            onMouseEnter={() => {
              setStateHover(true);
            }}
          >
            <Off />
          </Box>
        )}
        <StyledFirstSectionAutoComplete
          disableCloseOnSelect
          disableListWrap
          multiple
          disablePortal
          limitTags={-1}
          filterOptions={x => x}
          id={`SIPPageOverview-Filter-SelectBox-${identifier}`}
          data-selenium-id={`SIPPageOverview-Filter-SelectBox-${identifier}`}
          value={selectedList}
          options={data ?? []}
          loading={loading}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => {
            setOpen(false);
            handleSwitchTab({ label: labelName, data: [inputText] });
          }}
          getOptionLabel={option => getOptionName(option)}
          onChange={(_event, value) => {
            let field = getFieldByLabel(labelName);
            let selectedData = sipFilterSelected[field + 'Selected'].concat();
            let hasValueInsipFilterSelected = false;
            for (let i = 0; i < value.length; i++) {
              let idx = isValueInSipfilterSelected(value[i]);
              if (idx !== -1) {
                hasValueInsipFilterSelected = true;
                selectedData.splice(idx, 1);
                dispatch(setSelect(field, selectedData));
              }
            }
            if (!hasValueInsipFilterSelected) {
              setSelected(value);
            }
          }}
          isOptionEqualToValue={(option, value) =>
            getOptionId(option) === getOptionId(value)
          }
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setInputText('');
              setOpen(false);
              handleSwitchTab({ label: labelName, data: [inputText] });
            }
          }}
          onMouseEnter={() => {
            setStateHover(true);
          }}
          onMouseLeave={() => {
            setStateHover(false);
          }}
          onFocus={() => {
            setStateFocus(true);
          }}
          onBlur={() => {
            setStateFocus(false);
            if (!stateHover) {
              setInputText('');
              handleSwitchTab({ label: labelName, data: [inputText] });
            }
          }}
          renderOption={(optionProps, option, { selected }) => (
            <li {...optionProps}
              aria-selected={
                selected || isValueInSipfilterSelected(option) !== -1
              }>
              <Stack spacing={1} direction='row' sx={{ ...AllCentered, width: '100%' }}>
                <Checkbox
                  key={identifier + '_' + getOptionId(option)}
                  icon={<CheckboxIcon />}
                  checkedIcon={<CheckboxCheckedIcon />}
                  checked={selected || isValueInSipfilterSelected(option) !== -1}
                  sx={{
                    height: '24px',
                    width: '24px',
                    padding: '0px',
                    ':hover': {
                      backgroundColor: 'transparent',
                    },
                    '& .MuiTouchRipple-root': {
                      display: 'none',
                    },
                  }}
                />

                <GETooltipIf
                  title={getOptionName(option)}
                  key={getOptionName(option)}
                  disabled={measureWidth(
                    getOptionName(option), '12px Open Sans') < 468}
                  tooltipWidth={'486px'}
                >
                  <Box sx={{ ...LimitWord, width: 'calc(100% - 32px)' }}>
                    {getOptionName(option)}
                  </Box>
                </GETooltipIf>

              </Stack>
            </li>
          )}
          renderInput={params => {
            return (
              <TextField
                {...params}
                placeholder={
                  selectedList?.length ? '' : getPlaceHolderByLabel(labelName)
                }
                data-selenium-id={`SIPPageOverview-Filter-SelectBox-${identifier}-Input`}
                onChange={e => {
                  handleInputTextChange(e);
                }}
              />
            );
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              const expectWidth = measureWidth(
                getOptionName(option),
                '12px Open Sans'
              );
              return (
                <GETooltipIf
                  title={getOptionName(option)}
                  key={getOptionName(option)}
                  disabled={expectWidth < 347}
                >
                  <Chip
                    {...getTagProps({ index })}
                    label={getOptionName(option)}
                    deleteIcon={
                      <Box sx={{ display: 'flex' }}>
                        <ChipClearIcon />
                      </Box>
                    }
                  />
                </GETooltipIf>
              );
            })
          }
          ListboxProps={{
            sx: {
              overflow: 'visible',
              position: 'absolute',
              width: 'calc(100% - 0px)',
              display: 'grid',
              gap: '2px',
              padding: '8px 0px 0px 0px',
              '& li': {
                padding: '0px 12px !important',
                boxSizing: 'border-box',
                ...MiniFont,
                lineHeight: '20px',
                color: '#596A7C',
              },
              '& li[aria-selected="true"]': {
                backgroundColor: '#F1F3F5 !important',
              },
              '& li[aria-selected="true"]:hover': {
                backgroundColor: '#F1F3F5 !important',
                '& svg rect': {
                  fill: '#113D95',
                  stroke: '#113D95',
                  'stroke-width': '0px',
                },
              },
              '& li:active': {
                backgroundColor: '#F1F3F5',
                '& svg rect': { stroke: '#596A7C' },
              },
              '& li:hover': {
                backgroundColor: '#F1F3F5',
                '& svg rect': { stroke: '#596A7C' },
              },
            },
          }}
          ListboxComponent={props => (
            <Box sx={{ paddingBottom: '8px' }}>
              <Scrollbars
                style={{
                  height: calcDropListHeight(data.length, 24, 2, 8) + 'px',
                }}
                renderTrackVertical={({ style, ...props }) => (
                  <div
                    style={{
                      ...style,
                      position: 'absolute',
                      top: '3px',
                      height: 'calc(100% - 3px)',
                      right: '3px',
                    }}
                    {...props}
                  ></div>
                )}
                renderTrackHorizontal={({ style, ...props }) => (
                  <div
                    style={{
                      ...style,
                      position: 'absolute',
                      width: '0px',
                      height: '0px',
                    }}
                    {...props}
                  ></div>
                )}
                renderThumbVertical={({ style, ...props }) => (
                  <div
                    style={{
                      ...style,
                      backgroundColor: '#DFE4E8',
                      opacity: '0.7',
                      borderRadius: '10px',
                      width: '6px',
                    }}
                    {...props}
                  ></div>
                )}
                renderView={({ style, ...props }) => (
                  <div
                    style={{
                      ...style,
                      overflow: 'auto',
                      height: calcDropListHeight(data.length, 24, 2, 8) + 'px',
                    }}
                    {...props}
                  ></div>
                )}
                thumbSize={53}
              >
                <ul {...props} />
              </Scrollbars>
            </Box>
          )}
          PopperComponent={props => (
            <Popper
              {...props}
              sx={{
                '&.MuiAutocomplete-popper': {
                  top: '6px !important',
                },
                '& .MuiAutocomplete-paper': {
                  backgroundColor: '#FFFFFF',
                  boxShadow: ' 0px 6px 12px 0px #262E351F',
                  borderRadius: '4px',
                },
                '& .MuiAutocomplete-noOptions': {},
              }}
            />
          )}
          clearText=''
          closeText=''
          openText=''
          popupIcon={stateHover || open ? popupIcon : null}
          clearIcon={<Off />}
          {...other}
        />
      </Box>
    </ThemeProvider>
  );
}

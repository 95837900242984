import NoDataArrow from '@/assets/ReportDiagrams/NoDataArrow.png';
import NoDataBooksImg from '@/assets/ReportDiagrams/NoDataBooksImg.png';
import styles from './index.module.scss';

const NoDataPage = ({ title, subTitle }) => {
  if (subTitle) {
    return (
      <div className={styles['layout']}>
        <img className={styles['image']} src={NoDataBooksImg} />
        <h1 className={styles['title']}>{title}</h1>
        <h2 className={styles['subtitle']}>
          <img className={styles['icon']} src={NoDataArrow} />
          {subTitle}
        </h2>
      </div>
    );
  } else {
    return (
      <div className={styles['layout']}>
        <img className={styles['image']} src={NoDataBooksImg} />
        <h1 className={styles['title']}>
          <img className={styles['icon']} src={NoDataArrow} />
          {title}
        </h1>
      </div>
    );
  }
};

export default NoDataPage;

import { MiniFont, SmallFont } from '@/components/FontStyle';
import styled from '@emotion/styled';
import { Autocomplete } from '@mui/material';
import { AllCentered, LimitWord } from '../Css';

export const StyledFirstSectionAutoComplete = styled(Autocomplete)(
  ({ theme,paddingLeft='36px' }) => ({
    '& .MuiAutocomplete-tag ': {
      
      height: '22px',
      padding: '5px 8px 5px 12px',
      boxSizing: 'border-box',
      backgroundColor: '#FFFFFF',
      margin:'0px',
      //layout
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '6px',
      //border
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '4px',
      borderColor: theme.themeColor?.first ?? '#154AB6',
      '& .MuiChip-label': {
        padding: '0px',
        maxWidth: '347px',
        ...LimitWord,
        //font
        ...MiniFont,
        color: '#596A7C',
        letterSpacing: '0px',
      },
      '& .MuiChip-deleteIcon': {
        margin: '0px',
        '& :hover': {
          backgroundColor: '#BCC5CF',
          borderRadius: '2px',
        },
      },
      '& .MuiChip-deleteIcon :hover': {
        backgroundColor: '#BCC5CF',
        borderRadius: '2px',
      },
    },
    '& .MuiAutocomplete-tag:hover': {
      backgroundColor: '#DFE4E8',
    },

    '& .MuiAutocomplete-endAdornment': {
      right: '12px !important',
      top: '12px',
      display: 'flex',
      flexDirection: 'row',
      gap:'4px'
    },

    '& .MuiAutocomplete-popupIndicator': {
      ...AllCentered,
      padding: '0px',
      margin: '0px',
      backgroundColor: '#FFFFFF',
      transform: 'none',
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },
    '& .MuiAutocomplete-popupIndicator:hover': {
      backgroundColor: '#FFFFFF',
    },
    '& .MuiAutocomplete-popupIndicator:active': {
      backgroundColor: '#FFFFFF',
    },

    '& .MuiAutocomplete-clearIndicator': {
      ...AllCentered,
      margin: '0px',
      borderRadius: '0px',
      padding:'0px',
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },
    '& .MuiAutocomplete-clearIndicator:hover': {
      backgroundColor: '#FFFFFF',
      '& svg path':{
        fill:'#262E35'
      }
    },
    '& .MuiAutocomplete-clearIndicator:active': {
      backgroundColor: '#FFFFFF',
      '& svg path':{
        fill:'#262E35'
      }
    },

    '& .MuiOutlinedInput-root': {
      width: '100%',
      minHeight: '40px',
      background: '#FFFFFF',
      borderRadius: '4px',
      boxSizing: 'border-box',

      padding: '9px',
      paddingRight: '46px !important',
      paddingLeft: paddingLeft,

      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      rowGap: '4px',
      columnGap: '2px',
    },
    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
      padding: '0px',
      height:'22px',
      ...SmallFont,
      lineHeight: '17.5px',
      color: '#262E35',
      '&::placeholder': {
        ...SmallFont,
        lineHeight: '17.5px',
        color: '#BCC5CF',
        opacity: '1',
      },
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '4px',
      borderColor: theme.themeColor?.first ?? '#98A7B6',
    },
    '& .MuiOutlinedInput-notchedOutline:hover': {
      borderColor: '#262E35',
    },
    '& .MuiOutlinedInput-notchedOutline:focus': {
      borderWidth: '2px',
      borderColor: '#0052CC'
    },
  })
);

import { MiniFont } from '@/components/FontStyle';
import { Switch } from '@mui/material';
import { AllCentered } from './CommonStyle';

export default function SwitchButton(props) {
  const { id,beforeContent, afterContent, disabled, ...other } = props;
  return (
    <Switch
      id={`${id}_switch_button`}
      data-selenium-id={`${id}_switch_button`}
      sx={{
        width: '48px',
        height: '20px',
        padding: '0px',
        borderRadius: '16px',
        '& .MuiSwitch-switchBase': {
          padding: '0px',
          top: '2px',
          left: '2px',
          '&.Mui-checked': {
            transform: 'translateX(15px)',
            left: '0px',
            right: '2px',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: '#154AB6',
              '&:before': {
                content: beforeContent ?? '"Yes"',
              },
              '&:after': {
                content: '""',
              },
            },
          },
        },
        '& .MuiSwitch-thumb': {
          width: '16px',
          height: '16px',
          borderRadius: '50%',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
        },
        '& .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#DFE4E8',
          '&:before': {
            position: 'absolute',
            top: '0px',
            left: '7px',
            width: 20,
            height: 20,
            ...MiniFont,
            lineHeight: '20px',
            color: '#FFFFFF',
            textTransform: 'capitalize',
            content: '""',
            ...AllCentered,
          },
          '&:after': {
            position: 'absolute',
            top: '0px',
            right: '6px',
            width: 20,
            height: 20,
            ...MiniFont,
            lineHeight: '20px',
            color: '#596A7C',
            textTransform: 'capitalize',
            content: afterContent ?? '"No"',
            ...AllCentered,
          },
        },
      }}
      disabled={disabled}
      {...other}
    ></Switch>
  );
}

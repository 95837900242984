import '@/modules/ResetPassword/index.css';
import { Box } from '@mui/material';

import A from '@/assets/error_outline.svg';

function Alert0(props) {
  const type = 'samePass';

  const info = () => {
    switch (type) {
      case 'oldIncorrect':
        return 'Old password is incorrect.';
      case 'samePass':
        return 'The new password is same with old password.';
      default:
        return '';
    }
  };

  return (
    <Box className='alert0Box'>
      <div className='IconAlert0'>
        <A />
      </div>
      <div className='alert0'>{info()}</div>
    </Box>
  );
}

export default Alert0;

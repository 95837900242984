import {
  FormControl,
  InputAdornment,
  TextField,
  Autocomplete,
  Box
} from '@mui/material';
import { CustomTooltip } from '@/components/CustomTooltip';
import { useState, useEffect } from 'react';

const defaultTransformFunc = (option) => {
  return option;
};

const defaultSearchIcon = () => {
  return (
    <>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M7.33398 2.66671C4.75666 2.66671 2.66732 4.75605 2.66732 7.33337C2.66732 9.9107 4.75666 12 7.33398 12C9.91131 12 12.0007 9.9107 12.0007 7.33337C12.0007 4.75605 9.91131 2.66671 7.33398 2.66671ZM1.33398 7.33337C1.33398 4.01967 4.02028 1.33337 7.33398 1.33337C10.6477 1.33337 13.334 4.01967 13.334 7.33337C13.334 10.6471 10.6477 13.3334 7.33398 13.3334C4.02028 13.3334 1.33398 10.6471 1.33398 7.33337Z'
          fill='#596A7C'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M10.6289 10.6286C10.8892 10.3683 11.3113 10.3683 11.5717 10.6286L14.4717 13.5286C14.732 13.789 14.732 14.2111 14.4717 14.4714C14.2113 14.7318 13.7892 14.7318 13.5289 14.4714L10.6289 11.5714C10.3685 11.3111 10.3685 10.889 10.6289 10.6286Z'
          fill='#596A7C'
        />
      </svg>
    </>
  );
};

export const liStyle={
  lineHeight: '16px',
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Open Sans',
  height: "16px",
  padding: '0px 12px',
  color: "#596A7C",
  marginTop: "4px",
  "&:hover": {
    color: "#262E35",
    backgroundColor: "#596A7C"
  }
};

export function SelectedInput(props) {
  /**
   * coded by 赵蓁翔
   */

  const { 
    sx,
    id, 
    value, 
    options, 
    filterOptions, 
    maxOptionLength=50, 
    onChange,
    inputLabel,
    inputPlaceholder,
    emptyCheck= xx => (xx && xx != ""),
    searchIcon=defaultSearchIcon, 
    innerInputOnChange=() => {},
    errorMessage="", 
    transformOptionTooltipFormat=defaultTransformFunc,
    error=false,
    innerInputSx={},
    ...others
  } = props;

  /**
   * 0. default
   * 1. hover
   * 2. focus
   */
  const [status, setStatus] = useState(0);

  const getBorderStyle = () => {
    if(status == 0) {
      if(emptyCheck(value)) {
        return "1px solid #98A7B6";
      }
      return "1px solid #DFE4E8";
    } else if(status == 1) {
      return "1px solid #596A7C";
    } else {
      return "2px solid #154AB6";
    }
  };

  return (
    <FormControl
      variant='filled'
      sx={{
        width: '380px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '2px',
        ...sx,
      }}
    >
      <Autocomplete
        disablePortal
        id={`selectedInput-${id}`}
        data-selenium-id={`selectedInput-${id}`}
        options={options}
        value={value}
        componentsProps={{
          paper: {
            style: {
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
              borderRadius: '5px', 
              marginTop: '6px',
              marginBottom: '6px'
            },
          },
        }}
        filterOptions={filterOptions}
        renderOption={(props, option, state) => {
          if (option.length > maxOptionLength) {
            return (
              <CustomTooltip
                title={transformOptionTooltipFormat(option)}
                placement='top'
              >
                <Box
                  {...props}
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    ...liStyle
                  }}
                >
                  {option.slice(0, maxOptionLength) + '...'}
                </Box>
              </CustomTooltip>
            );
          }
          return (
            <Box
              {...props}
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                ...liStyle
              }}
            >
              {option}
            </Box>
          );
        }}
        endAttchmentProps={{
          popupIconSX: {
            padding: '0px',
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
          },
        }}
        onChange={onChange}
        ListboxProps={{
          sx: {
            pt: "8px",
            pb: "0px",
            mb: "12px",
            maxHeight: '128px',
            gap: "0px 4px"
          },
        }}
        classes={{
          option: {
            height: '16px'
          },
        }}
        popupIcon={<></>}
        renderInput={params => (
          <TextField
            onChange={innerInputOnChange}
            label={inputLabel}
            placeholder={inputPlaceholder}
            {...params}
            InputLabelProps={{ shrink: 'true' }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  {searchIcon()}
                </InputAdornment>
              ),
            }}
            onMouseEnter={() => {
              setStatus(1);
            }}
            onMouseLeave={() => {
              setStatus(0);
            }}
            onFocus={() => {
              setStatus(2);
            }}
            onBlur={() => {
              setStatus(0);
            }}
            error={error}
            sx={{
              fontSize: '14px',
              backgroundColor: '#FFFFFF',
              width: '286px',
              height: '40px',
              padding: 0,
              '.MuiInputLabel-root': {
                position: 'absolute',
                transform: 'none',
                display: 'none',
              },
              '.MuiOutlinedInput-input.MuiInputBase-input': {
                paddingTop: 0,
                paddingBottom: 0,
              },
              '& fieldset.MuiOutlinedInput-notchedOutline': {
                top: 0,
                border: getBorderStyle()
              },
              legend: {
                display: 'none',
              },
              ...innerInputSx
            }}
          />
        )}
        {...others}
      />
      {error && (
        <span
          style={{
            color: '#EE5350',
            fontSize: '12px',
            fontWeight: '400',
            marginTop: 2,
          }}
        >
          <svg
            width='10'
            height='10'
            viewBox='0 0 10 10'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M5.46895 0.0933113C5.61762 0.154915 5.75269 0.245207 5.86645 0.35903L9.64066 4.13263C9.75458 4.2464 9.84495 4.38152 9.90661 4.53024C9.96826 4.67897 10 4.83839 10 4.99939C10 5.16039 9.96826 5.31981 9.90661 5.46853C9.84495 5.61726 9.75458 5.75237 9.64066 5.86615L5.86645 9.63974C5.75269 9.75357 5.61762 9.84386 5.46895 9.90546C5.32028 9.96707 5.16093 9.99877 5 9.99877C4.83907 9.99877 4.67972 9.96707 4.53105 9.90546C4.38238 9.84386 4.24731 9.75357 4.13355 9.63974L0.359336 5.86615C0.245422 5.75237 0.155052 5.61726 0.0933942 5.46853C0.0317363 5.31981 0 5.16039 0 4.99939C0 4.83839 0.0317363 4.67897 0.0933942 4.53024C0.155052 4.38152 0.245422 4.2464 0.359336 4.13263L4.13355 0.35903C4.24731 0.245207 4.38238 0.154915 4.53105 0.0933113C4.67972 0.0317076 4.83907 0 5 0C5.16093 0 5.32028 0.0317076 5.46895 0.0933113ZM4.56732 6.04366C4.68207 6.15841 4.83771 6.22288 5 6.22288C5.16228 6.22288 5.31792 6.15841 5.43268 6.04366C5.54743 5.92891 5.6119 5.77327 5.6119 5.61098V2.55147C5.6119 2.38918 5.54743 2.23354 5.43268 2.11879C5.31792 2.00404 5.16228 1.93957 5 1.93957C4.83771 1.93957 4.68207 2.00404 4.56732 2.11879C4.45256 2.23354 4.3881 2.38918 4.3881 2.55147V5.61098C4.3881 5.77327 4.45256 5.92891 4.56732 6.04366ZM4.56732 7.87937C4.68207 7.99412 4.83771 8.05859 5 8.05859C5.16228 8.05859 5.31792 7.99412 5.43268 7.87937C5.54743 7.76461 5.6119 7.60897 5.6119 7.44669C5.6119 7.2844 5.54743 7.12876 5.43268 7.01401C5.31792 6.89925 5.16228 6.83478 5 6.83478C4.83771 6.83478 4.68207 6.89925 4.56732 7.01401C4.45256 7.12876 4.3881 7.2844 4.3881 7.44669C4.3881 7.60897 4.45256 7.76461 4.56732 7.87937Z'
              fill='#EE5350'
            />
          </svg>
          <span>
            &nbsp;{errorMessage}
          </span>
        </span>
      )}
      {!error && (
        <span
          style={{
            color: '#EE5350',
            fontSize: '12px',
            fontWeight: '400',
            marginTop: 2,
          }}
        ></span>
      )}
    </FormControl>
  );
}
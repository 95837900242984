import { Box } from '@mui/material';
import { ColumnCentered } from '../Css';

export default function TextButton(props) {
  const { id, dataSeleniumId, label, handleClick, disabled = false } = props;
  return (
    <Box
      id={id}
      data-selenium-id={dataSeleniumId}
      sx={{
        height: '34px',
        ...ColumnCentered,
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
        color: disabled ? '#BCC5CF' : '#154AB6',
        textTransform: 'none',
        cursor: disabled ? 'default' : 'pointer',
        ':hover': !disabled && {
          fontWeight: '600',
          color: '#113D95',
        },
      }}
      onClick={() => {
        if (!disabled) {
          handleClick();
        }
      }}
    >
      {label}
    </Box>
  );
}

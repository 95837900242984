import {axios_instance,} from '@/utils/axios_instance';
import {setSnackbarMessageAndOpen} from '../../SnackbarAction';
import {SEVERITIES} from '../../../modules/ConfirmSnackBar/CommonSnackBar';
import {buildParamsForFilter, getAPIPath} from '../../../utils/filterUtils';
import {
  FlaggedGESynchronizationAndVerificationTable
} from '@/componentsphase2/FlaggedGESynchronizationAndVerificationTable';
import {getGeByFullname} from "@/actions/SIMT-SI/GuestEditor/index";
// geTable
export const LOAD_GE = 'LOAD_GE'; // load GE List
export const LOAD_GE_CODES = 'LOAD_GE_CODES'; // load GE List
export const GE_ORDER_BY = 'GE_ORDER_BY'; // Set GE list sorting
export const CHANGE_GE_PAGE = 'CHANGE_GE_PAGE'; // Set GE Table Page Num
export const CHANGE_GE_PAGE_SIZE = 'CHANGE_GE_PAGE_SIZE'; // GE list size per page
export const CV_UPLOAD = 'CV_UPLOAD';
export const DOWNLOAD_CV = 'DOWNLOAD_CV';

// action key about filter
export const LOAD_GE_FILTER_OPTIONS = 'LOAD_GE_FILTER_OPTIONS'; // load ge filter
export const SET_GE_FILTER_OPEN = 'SET_GE_FILTER_OPEN'; // GE filter
export const SET_GE_FILTERS = 'SET_GE_FILTERS'; // GE filter
export const SET_GE_FILTER_SELECTED = 'SET_GE_FILTER_SELECTED';
export const RESET_GE_FILTER = 'RESET_GE_FILTER';
export const CLEAR_GE_USE_FILTER = 'CLEAR_GE_USE_FILTER';
export const EDIT_GEBASIC = 'EDIT_GEBASIC';

//action key about flag
export const ADD_FLAG = 'ADD_FLAG';
export const EDIT_FLAG = 'EDIT_FLAG';
export const UP_EVIDENTFILE = 'UP_EVIDENTFILE';
export const DELETE_FLAG = 'DELETE_FLAG';
export const DOWNLOAD_ALLFILE = 'DOWNLOAD_ALLFILE';
export const DOWNLOAD_SINGLEFILE = 'DOWNLOAD_SINGLEFILE';
export const DOWNLOAD_PROPOSER_ALLFILE = 'DOWNLOAD_PROPOSER_ALLFILE';
export const DOWNLOAD_PROPOSER_SINGLEFILE = 'DOWNLOAD_PROPOSER_SINGLEFILE';
export const UPDATE_EVIDENCE_UPLOADING_PROGRESS =
  ' UPDATE_EVIDENCE_UPLOADING_PROGRESS';

// Ge search
export const SET_GE_SEARCH = 'SET_GE_SEARCH'; // GE search

export const LOAD_ASSOCIATEDSI = 'LOAD_ASSOCIATEDSI'; //GE detail page get Associated SI table
export const GET_BASICINFORMATION = 'GET_BASICINFORMATION'; //GE detail page get basic information
export const GET_FLAG = 'GET_FLAG'; //GE detail page get flag infomation
export const GET_COUNTRYLIST = 'GET_COUNTRYLIST'; ////GE detail page get countries
export const GET_DEPARTMENTLIST = 'GET_DEPARTMENTLIST';
export const GET_INSTITUTELIST = 'GET_INSTITUTELIST';
export const GET_FLAGLIST = 'GET_FLAGLIST'; //GE detail page get flags
// GE Select and deselect, export
export const SELECT_BATCH_GE = 'SELECT_BATCH_GE';
export const UNSELECT_BATCH_GE = 'UNSELECT_BATCH_GE';
export const EXPORT_GE = 'EXPORT_GE';
export const GET_GE_CODE = 'GET_GE_CODE';

// Create GE

export const SHOW_VIEW_MORE = 'SHOW_VIEW_MORE';

// Merge GE

export const MERGE_BASIC_INFORMATION_ONE = 'MERGE_BASIC_INFORMATION_ONE';
export const MERGE_BASIC_INFORMATION_TWO = 'MERGE_BASIC_INFORMATION_TWO';
export const GET_MERGE_FLAG_ONE = 'GET_MERGE_FLAG_ONE';
export const GET_MERGE_FLAG_TWO = 'GET_MERGE_FLAG_TWO';

// History
export const GET_GEBASICINFORMATION_HISTORY = 'GET_GEBASICINFORMATION_HISTORY';
export const GET_FLAG_HISTORY = 'GET_FLAG_HISTORY';

/**
 * @typedef {"CREATE_DEPARTMENT"} CREATE_DEPARTMENT
 * @typedef {"CREATE_INSTITUTE"} CREATE_INSTITUTE
 * @typedef {"CREATE_KEYWORD"} CREATE_KEYWORD
 * @typedef {import("@/pages/GEPage/Overview/CreateGE/CreateGEContainer").Department} Department
 * @typedef {import("@/pages/GEPage/Overview/CreateGE/CreateGEContainer").Institute} Institute
 * @typedef {import("@/pages/GEPage/Overview/CreateGE/CreateGEContainer").ResearchKeyword} ResearchKeyword
 */

/** @type {import("@/pages/GEPage/Overview/CreateGE/CreateGEContainer").CreateNewItemActionCreator} */
const createNewItemActionCreator = (actionType, defaultValue) => {
  return (status, value) => {
    return {
      type: actionType,
      data: {
        status: status,
        value: value === null ? defaultValue : value,
      },
    };
  };
};
export const CREATE_KEYWORD = 'CREATE_KEYWORD';
export const CREATE_KEYWORD_EXITS = 'CREATE_KEYWORD_EXITS';
export const GET_KEYWORDS = 'GET_KEYWORDS';
export const GET_ALL_KEYWORDS = 'GET_ALL_KEYWORDS';
export const CREATE_INSTITUTE = 'CREATE_INSTITUTE';
export const CREATE_INSTITUTE_EXITS = 'CREATE_INSTITUTE_EXITS';
export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENT';
export const CREATE_DEPARTMENT_EXITS = 'CREATE_DEPARTMENT_EXITS';

export const createGeDepartment = createNewItemActionCreator(
  /** @type {CREATE_DEPARTMENT} */ (CREATE_DEPARTMENT),
  /** @type {Department} */ ({
    id: 0,
    department: '',
  })
);
export const createGeInstitute = createNewItemActionCreator(
  /** @type {CREATE_INSTITUTE} */ (CREATE_INSTITUTE),
  /** @type {Institute} */ ({
    id: 0,
    institute: '',
  })
);
export const createGeKeyword = createNewItemActionCreator(
  /** @type {CREATE_KEYWORD} */ (CREATE_KEYWORD),
  /** @type {ResearchKeyword} */ ({
    id: 0,
    keyword: '',
  })
);

export const CHECK_EMAIL = 'CHECK_EMAIL';
export const CREATE_GE = 'CREATE_GE';

export const GET_GE_Gender_CONFIG = 'GET_GE_Gender_CONFIG';
export const GET_GE_TITLE_CONFIG = 'GET_GE_TITLE_CONFIG';

/**
 * 批量选择GE列表中的GE
 * @param shouldSelectGeList  需要被选择的GE列表
 * @returns {(function(*): void)|*}
 */
export function selectBatchGE(shouldSelectGeList) {
  return dispatch => {
    dispatch({
      type: SELECT_BATCH_GE,
      data: shouldSelectGeList,
    });
  };
}

/**
 * 批量取消选择GE列表中的GE
 * @param shouldUnselectGeList  需要被取消选中的GE列表
 * @returns {(function(*): void)|*}
 */
export function unselectBatchGE(shouldUnselectGeList) {
  return dispatch => {
    dispatch({
      type: UNSELECT_BATCH_GE,
      data: shouldUnselectGeList,
    });
  };
}

export function setGESearchContent(content) {
  return dispatch => {
    dispatch({
      type: SET_GE_SEARCH,
      data: content,
    });
  };
}

/**
 * 更改表格页码
 * @param {int} page 页码
 * @returns dispatch
 */
export function handleGEPageChangeAction(page) {
  return dispatch => {
    dispatch({
      type: CHANGE_GE_PAGE,
      data: page,
    });
  };
}

/**
 * 更改表格一页数量
 * @param {int} pageSize 一页的大小
 * @returns dispatch
 */
export function handleChangeGEPageSize(pageSize) {
  return dispatch => {
    dispatch({
      type: CHANGE_GE_PAGE_SIZE,
      data: pageSize,
    });
  };
}

/**
 * 更改排序顺序和字段
 * @param order
 * @param by
 * @returns {(function(*): void)|*}
 */
export function setGEOrderBy(order, by) {
  return dispatch => {
    dispatch({ type: GE_ORDER_BY, data: { order: order, orderBy: by } });
  };
}

export function clearCreateGEResult() {
  return async dispatch => {
    dispatch({ type: CREATE_GE, data: '' });
  };
}

/**
 * 加载GE filter的options
 * @param which
 * @param searchContent
 * @param page
 * @param pageSize
 * @returns {(function(*): Promise<*|undefined>)|*}
 */
export function loadGEFilterOptions(
  which,
  searchContent,
  page = 1,
  pageSize = 20
) {
  return async dispatch => {
    if (which === 'gender') {
      // 常量数据直接返回
      dispatch({
        type: LOAD_GE_FILTER_OPTIONS,
        data: {
          which: 'gender',
          page: 1,
          data: { result: ['Male', 'Female', 'Unknown'], total: 3 },
        },
      });
      return;
    }
    let apiPath = getAPIPath(which);
    try {
      let res = await axios_instance.get(apiPath, {
        params: {
          ...buildParamsForFilter(which, searchContent, page, pageSize),
        },
        headers: {
          authorization: localStorage.getItem('authorization'),
        },
      });
      if (res.data.code === 200) {
        if (which === 'handlingCe') {
          dispatch({
            type: LOAD_GE_FILTER_OPTIONS,
            data: {
              which: which,
              page: page,
              data: { result: res.data?.data?.result, total: res.data?.data?.total },
            },
          });
        } 
        else if (which == 'associateJournal') {
          const associateJournalList = res.data?.data?.map(journal => 
             `${journal.journalName}(${journal.journalCode})`);
          dispatch({
            type: LOAD_GE_FILTER_OPTIONS,
            data: {
              which: which,
              page: page,
              data: { result: associateJournalList, total: res.data?.data?.total },
            },
          });
        }
        else {
          dispatch({
            type: LOAD_GE_FILTER_OPTIONS,
            data: {
              which: which,
              page: page,
              data: { result: res.data.data, total: res.data.total },
            },
          });
        }
      } else {
        dispatch({
          type: LOAD_GE_FILTER_OPTIONS,
          data: { which: which, page: page, data: { result: [], total: 0 } },
        });
      }
    } catch (err) {
      dispatch({
        type: LOAD_GE_FILTER_OPTIONS,
        data: { which: which, page: page, data: { result: [], total: 0 } },
      });
    }
  };
}

export function clearGEUseFilter() {
  return dispatch => {
    dispatch({ type: CLEAR_GE_USE_FILTER });
  };
}

export function setGEFilterOpen(newState) {
  return dispatch => {
    dispatch({
      type: SET_GE_FILTER_OPEN,
      data: newState,
    });
  };
}

export function setGEFilterSelect(which, data) {
  return dispatch => {
    dispatch({
      type: SET_GE_FILTER_SELECTED,
      data: { which: which, data: data },
    });
  };
}

/**
 * 设置GE使用的Filter
 * @param filters
 * @returns {(function(*): void)|*}
 */
export function setGEFilters(filters) {
  return dispatch => {
    dispatch({ type: SET_GE_FILTERS, data: filters });
  };
}

/**
 * reset filter。清空
 * @returns {(function(*): void)|*}
 */
export function resetGEFilter() {
  return dispatch => {
    dispatch({ type: RESET_GE_FILTER });
  };
}

export function setShowViewMore(isShow) {
  return dispatch => {
    dispatch({
      type: SHOW_VIEW_MORE,
      data: isShow,
    });
  };
}

export function downloadFileOfNewStyle(dataFlow, fileName) {
  if (dataFlow?.code === 500) {
    store.dispatch(
      setSnackbarMessageAndOpen('common.failToDownload', {}, SEVERITIES.error)
    );
  }
  var blob;
  blob = new Blob([dataFlow], { type: 'application/octet-stream' });
  if ('download' in document.createElement('a')) {
    // 非IE下载
    const elink = document.createElement('a');
    elink.download = fileName;
    elink.style.display = 'none';
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放 URL对象
    document.body.removeChild(elink);
  }
}

export function downloadFileOfZip(dataFlow, fileName) {
  if (dataFlow?.code === 500) {
    store.dispatch(
      setSnackbarMessageAndOpen('common.failToDownload', {}, SEVERITIES.error)
    );
  }

  // 使用 TextEncoder 将字符串编码为 Uint8Array
  // var encoder = new TextEncoder();
  // var uint8Array = encoder.encode(dataFlow);

  const blob = new Blob([dataFlow], { type: 'application/zip' });
  if ('download' in document.createElement('a')) {
    // 非IE下载
    const elink = document.createElement('a');
    elink.download = fileName;
    elink.style.display = 'none';
    elink.href = window.URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放 URL 对象
    document.body.removeChild(elink);
  }
}

export const MERGE_START = 'MERGE_START';
export const MERGE_FINISH = 'MERGE_FINISH';
export const MERGE_RESET = 'MERGE_RESET';

export const mergeStart = () => {
  return dispatch => {
    dispatch({
      type: MERGE_START,
    });
  };
};

export const mergeFinish = () => {
  return dispatch => {
    dispatch({
      type: MERGE_FINISH,
    });
  };
};

export const mergeReset = () => {
  return dispatch => {
    dispatch({
      type: MERGE_RESET,
    });
  };
};





export const SET_FLAGGEDGESYNCHRONIZATIONANDVERIFICATIONTABLE=`SET_${FlaggedGESynchronizationAndVerificationTable?.toUpperCase()}`;

export async function clearGeCache( ge = {}) {
  // const { fullName = '', email = '' } = ge;
  const cacheStorage = window.caches;
  const cache = await cacheStorage?.open(FlaggedGESynchronizationAndVerificationTable);
  cache.keys().then(cacheNames => {
    cacheNames.forEach(cacheName => {
      // if ((fullName=='' && email == '') || cacheName.includes(fullName) || cacheName.includes(email)){
        cache.delete(cacheName);
      // }
    });
  }).then(() => {
    console.log('Cache cleared!');
  });
}

/**
 * @description 用于根据fullName和email获得ge detail的信息
 * @param {string} fullname
 * @param {string?} email
 * @param {number?} index
 * @param {(e:GeDataType)=>void?} resolve
 */
export const getGeByFullnameWithCache = (fullname = '', email = null, index = 0,hasFlag=false, resolve = _e => {}) =>
    /**
     * @param {import('redux').Dispatch} dispatch
     */
    async (dispatch) => {
      const cacheKey = `${FlaggedGESynchronizationAndVerificationTable}_${fullname}_${email}`;
      const cacheStorage = window.caches;
      if(!cacheStorage) return getGeByFullname(fullname,email,index,hasFlag,resolve)(dispatch);
      const cache = await cacheStorage?.open(FlaggedGESynchronizationAndVerificationTable);
      const cachedResponse = await cache?.match(cacheKey);
      if (cachedResponse) {
        const value = await cachedResponse?.json();
        dispatch({ type: SET_FLAGGEDGESYNCHRONIZATIONANDVERIFICATIONTABLE, data: { value, index } });
        resolve(value);
        return value;
      }
      const value = await getGeByFullname(fullname, email, index,hasFlag, resolve)(dispatch);
      await cache?.put(cacheKey, new Response(JSON.stringify(value)));
      return value;
    };

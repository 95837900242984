import { FormControl, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  defaultVars,
  ErrVars,
} from '../../../../components/CreateAndUpdate/commonStyle';
import { TextAreaHelperText } from '../../../../components/CreateAndUpdate/MyFormHelperText';
import { BootstrapTextArea } from '../../../../components/CreateAndUpdate/MyOperationModule';
import { BootstrapTitle } from '../../../../components/CreateAndUpdate/MyTitle';
import ItemBlock from '../CGTComponents/ItemBlock';
import Textarea from '../CGTComponents/Textarea';
function CreateAndUpdateTextAreaFooter(props) {
  const { currentLength, maxLength, exceedsLimitErr } = props;
  const [color, setColor] = useState('#596A7C');
  const getStyle = color => {
    return {
      width: '100%',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '20px',
      textAlign: 'right',
      color: color,
    };
  };
  useEffect(() => {
    exceedsLimitErr ? setColor('#EE5350') : setColor('#596A7C');
  }, [exceedsLimitErr]);
  return (
    <Box
      data-selenium-id='SIPage_CreateAndUpdateSI-TextAreaFooterBox'
      sx={getStyle(color)}
    >
      {currentLength}/{maxLength}
    </Box>
  );
}

function CreateAndUpdateTextArea(props) {
  const {
    id,
    title,
    required,
    emptyErr,
    max,
    value,
    height,
    setValue,
    setlimitErr,
    exceedsLimitHelperText,
  } = props;
  const [vars, setVars] = useState(defaultVars);
  const [exceedsLimitErr, setExceedsLimitErr] = useState(false);

  useEffect(() => {
    setVars(emptyErr || exceedsLimitErr ? ErrVars : defaultVars);
  }, [emptyErr, exceedsLimitErr]);

  const handleChange = event => {
    if (event.target.value.length > max) {
      setExceedsLimitErr(true);
      // 因为上限是10000字 如果继续输入也不会增加字数 但是可以提交 所以注释下面这行
      // setlimitErr(true);
    }
    if (
      event.target.value.length !== undefined &&
      event.target.value.length <= max
    ) {
      if (exceedsLimitErr) {
        setExceedsLimitErr(false);
        setlimitErr(false);
      }
      setValue(event.target.value);
    }
  };
  // const [totalError, setTotalError] = useState(false);
  // const getErrorText = () => {
  //   let helperText = '';
  //   if (exceedsLimitErr) {
  //     helperText = exceedsLimitHelperText;
  //   } else if (props.error) {
  //     helperText = Props.helperText;
  //   } else {
  //     helperText = 'This field is required.';
  //   }
  //   return helperText;
  // };

  return (
    <Box
      data-selenium-id='SIPage_CreateAndUpdateSI-TextArea-Box'
      sx={{ width: '100%' }}
    >
      <FormControl
        data-selenium-id='SIPage_CreateAndUpdateSI-TextArea-FormControl'
        variant='standard'
        sx={{ width: '100%' }}
        error={emptyErr || exceedsLimitErr}
      >
        <BootstrapTitle
          id={id}
          data-selenium-id={id}
          title={title}
          required={required}
        ></BootstrapTitle>
        <BootstrapTextArea
          data-selenium-id='SIPage_CreateAndUpdateSI-TextArea-BootstrapTextArea'
          {...props}
          style={{ ...vars, '--height': height, fontSize: '14px' }}
          value={value}
          onChange={handleChange}
          onBlur={() => {
            setExceedsLimitErr(false);
          }}
        />
        <CreateAndUpdateTextAreaFooter
          data-selenium-id='SIPage_CreateAndUpdateSI-TextAreaFooter'
          currentLength={
            value === null || value === undefined ? 0 : value.length
          }
          maxLength={max}
          exceedsLimitErr={exceedsLimitErr}
        />
        <TextAreaHelperText
          data-selenium-id='SIPage_CreateAndUpdateSI-TextAreaHelperText'
          exceedsLimitErr={exceedsLimitErr}
          exceedsLimitHelperText={exceedsLimitHelperText}
          propsError={props.error}
          PropsHelperText={props.helperText}
        ></TextAreaHelperText>
      </FormControl>
      {/* <ItemBlock
        id={id}
        title={title}
        required={required}
        isError={emptyErr || exceedsLimitErr}
        errorText={getErrorText()}
      >
        <Textarea
          id={`${id}_textarea`}
          value={value}
          onChange={handleChange}
          onFocus={() => {
            setTotalError(false);
          }}
          onBlur={() => {
            setTotalError(emptyErr || exceedsLimitErr);
          }}
          sx={{ height: height }}
        ></Textarea>
      </ItemBlock> */}
    </Box>
  );
}
export default CreateAndUpdateTextArea;

import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';

const ScrollBarContainerX = styled(Box)({
  width: 'calc(100vw - 140px)',
  height: '12px',
  overflowX: 'auto',
  '& .scroll-bar-inner-box': {
    height: '12px',
  },
  '& ::-webkit-scrollbar-track,::-webkit-scrollbar-track:hover,::-webkit-scrollbar-track:active':{
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  '& ::-webkit-scrollbar,& ::-webkit-scrollbar-thumb,& ::-webkit-scrollbar:hover,::-webkit-scrollbar-thumb:hover':{
    backgroundColor: '#DFE4E8',
    borderRadius: '12px',
  },
});

const ScrollBarX = React.forwardRef((props, ref) => {
  const selector = state => {
    return {
      scrollContext: state.Common.scrollContext,
    };
  };

  const {
    scrollContext,
  } = useSelector(selector);

  const {contextHolderRef} = props;

  const scrollBoxRef = useRef(null);
  const scrollBoxInnerRef = useRef(null);

  const [transPercent, setTransPercent] = useState(1);

  useEffect(() => {
    const scrollBoxElement = scrollBoxRef.current;
    const scrollBoxInnerElement = scrollBoxInnerRef.current;

    setTransPercent(scrollContext.boxWidth / scrollBoxElement.offsetWidth);
    scrollBoxInnerElement.style.width = (scrollContext.contextWidth / scrollContext.boxWidth * scrollBoxElement.offsetWidth) + 'px';
  }, [scrollContext.contextWidth]);

  const handleScroll =  event => {
    const scrollBoxElement = scrollBoxRef.current;
    const offset = scrollBoxElement.scrollLeft;

    const containerBoxRef = contextHolderRef.current.getContextRef?contextHolderRef.current.getContextRef():contextHolderRef;
    const containerBoxElement = containerBoxRef.current;
    containerBoxElement.scrollLeft = offset * transPercent;
  };

  return <ScrollBarContainerX className="scroll-bar-container-box" ref={scrollBoxRef} onScroll={handleScroll}>
    <Box className="scroll-bar-inner-box" ref={scrollBoxInnerRef}/>
  </ScrollBarContainerX>;
});

export default ScrollBarX;

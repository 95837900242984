import {
  Box,
  Stack,
  Backdrop,
  Typography,
  Drawer,
  Divider,
} from '@mui/material';
import {
  SmallFont,
} from '@/components/FontStyle';
import { SIMTButton } from '@/components/StyledButton/SIMTButton';
import { loadData } from '@/actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import { StateButton } from '@/componentsphase2/StateButton';
import {
  clearArticleFilter,
  clearArticleOAWFilter,
  setArticleFilterSelect,
  setArticleOAWFilterSelect,
  setArticleOverviewFilters,
  setArticleOAWaiverUseFilters
} from '@/actions/SIMT-Report/ReportFilter/ReportFilterAction';

import React, { useRef } from "react";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectBoxWithFixedDropList from '@/modules/SelectBox/SelectBoxWithFixedDropList';
import FilterFrame from '../../Filter/FilterFrame';
import Block from '../../Filter/Block';
import SelectBox from '../../Filter/Phase2Component';
import SIDrawerAutocompletePupIconTypeSecond from '@/assets/SIDrawerAutocompletePupIconTypeSecond.svg';
import { handleFileterJournalName } from '@/utils/commonUtils';
import CATJournalCloseButton from '@/assets/CATJournalCloseButton.svg';
import ReportFilterSwitchIconOn from '@/assets/ReportFilterSwitchIconOn.svg';
import ReportFilterSwitchIconOff from '@/assets/ReportFilterSwitchIconOff.svg';
import { Checkbox } from '@mui/material';
import { MulSelectInput } from '@/componentsphase2/SelectBox/SelectInput';
import CloseIconButton from '@/componentsphase2/Button/CloseIconButton';
import { useShowReportRolesByFyHook } from '@/pages/Report/utils/useShowReportRolesByFyHook';
import {PERMISSIONS} from "@/constant/permission";

export default function ArticlesOverviewFilter(props) {
  const { filterOpen, drawerWidth, handleClose } = props;
  const dispatch = useDispatch();
  const [showJPM, showNewJournalRoles] = useShowReportRolesByFyHook();
  const {
    selectDate,
    cutOffDate,
    handlingCeData,
    catGroupData,
    journalData,
    jpmData,
    pdPublisherData,
    ppPublisherData,
    subjectGroupData,
    businessDivisionData,
    articleOverviewJournal,
    revenueModel,
    articleOverviewJPM,
    articleOverviewPDPublisher,
    articleOverviewPPPublisher,
    articleOverviewSubjectGroup,
    articleOverviewBusinessDivision,
    articleOverviewCATGroup,
    articleOverviewHandlingCE,
    articleOverviewJournalStatus,
    filterData = {},
  } = useSelector(state => {
    return {
      // filter display
      selectDate: state.Report.selectDate,
      cutOffDate: state.Report.cutOffDate,
      handlingCeData: state.Filter.handlingCe,
      catGroupData: state.Filter.catGroup,
      journalData: state.Filter.journal,
      jpmData: state.Filter.jpm,
      pdPublisherData: state.Filter.pdPublisher,
      ppPublisherData: state.Filter.ppPublisher,
      subjectGroupData: state.Filter.subjectGroup,
      businessDivisionData: state.Filter.businessDivision,
      articleOverviewJournal: state.ReportFilter.articleOAWaiverUseJournal,
      revenueModel: state.ReportFilter.articleOAWaiverUserevenueModel,
      articleOverviewJPM: state.ReportFilter.articleOAWaiverUseJPM,
      articleOverviewPDPublisher: state.ReportFilter.articleOAWaiverUsePDPublisher,
      articleOverviewPPPublisher: state.ReportFilter.articleOAWaiverUsePPPublisher,
      articleOverviewSubjectGroup:
        state.ReportFilter.articleOAWaiverUseSubjectGroup,
      articleOverviewBusinessDivision:
        state.ReportFilter.articleOAWaiverUseBusinessDivision,
      articleOverviewCATGroup: state.ReportFilter.articleOAWaiverUseCATGroup,
      articleOverviewHandlingCE: state.ReportFilter.articleOAWaiverUseHandlingCE,
      articleOverviewJournalStatus:
        state.ReportFilter.articleOAWaiverUseJournalStatus,
      filterData: state.ReportFilter.articleOAWaiverUseFilters,
    };
  });

  const [apply, setApply] = useState(false);
  useEffect(() => {
    if (!apply) {
      document.body.classList.add('hide-scroll');
    }
  },[apply]);

  useEffect(()=>{
    if (filterOpen){
      const {
        journalCode = [],
        revenueModel = [],
        jpm = [],
        pdPublisher = [],
        ppPublisher = [],
        subjectGroup = [],
        businessDivision = [],
        catGroup = [],
        handlingCe = [],
        journalStatus = [],
      } = filterData;

      const journalList = [];
      for (let code of journalCode){
        for (let journal of journalData){
          if (journal.includes(`(${code})`)){
            journalList.push(journal);
          }
        }
      }

      const handlingCes = [];
      for (let ceId of handlingCe){
        for (let ce of handlingCeData) {
          if (ce.userId === ceId) {
            handlingCes.push(ce);
          }
        }
      }

      const groups = [];
      for (let group of catGroup){
        for (let cat of catGroupData){
          if (cat.value === group){
            groups.push(cat);
          }
        }
      }

      dispatch(setArticleOAWFilterSelect('reportJournal', journalList));
      dispatch(setArticleOAWFilterSelect('reportJPM', jpm));
      dispatch(setArticleOAWFilterSelect('reportPDPublisher', pdPublisher));
      dispatch(setArticleOAWFilterSelect('reportPPPublisher', ppPublisher));
      dispatch(setArticleOAWFilterSelect('reportSubjectGroup', subjectGroup));
      dispatch(setArticleOAWFilterSelect('reportCATGroup', groups));
      dispatch(setArticleOAWFilterSelect('reportBusinessDivision', businessDivision));
      dispatch(setArticleOAWFilterSelect('reportHandlingCE', handlingCes));
      dispatch(setArticleOAWFilterSelect('revenueModel', revenueModel.length !== 0));
      dispatch(setArticleOAWFilterSelect('reportJournalStatus', journalStatus));
    }
  }, [filterOpen]);


  const permssion = localStorage
    .getItem('userPermissions')
    .includes(PERMISSIONS.SI_READ_LIMITED); //权限判断 修复403错误

  const boxStyle = {
    bgcolor: '#F3F4F8',
    mt: 1,
    p: 0,
  };

  const selectFilter = (which, data) => {
    dispatch(setArticleOAWFilterSelect(which, data));
  };

  const onLoad = async (which, searchContent, page = 1, pageSize = 5000) => {
    if (!permssion) {
      await dispatch(loadData(which, searchContent, page, pageSize));
    }
  };

  const handleSelectedChange = (which, data) => {
    selectFilter(which, data);
  };

  const handleReset = () => {
    dispatch(clearArticleOAWFilter());
    dispatch(setArticleOAWaiverUseFilters(initFilter()));
  };

  const handlerevenueModelChange = e => {
    dispatch(setArticleOAWFilterSelect('revenueModel', !revenueModel));
    //console.log(!revenueModel);
  };

  const prepareFilters = () => {
    // 处理journal code----感觉不规范，以后要跟后端协商
    var journalCode = [];
    for (const journalSelectedElement of articleOverviewJournal) {
      var code = handleFileterJournalName(journalSelectedElement)[
        'journalCode'
      ];
      journalCode.push(code);
    }

    // 处理handling ce
    var handlingCeIds = [];
    for (const element of articleOverviewHandlingCE) {
      handlingCeIds.push(element.userId);
    }

    // 处理group
    const groups = [];
    for (const element of articleOverviewCATGroup) {
      groups.push(element.value);
    }

    return {
      handlingCe: handlingCeIds,
      catGroup: groups,
      journalCode: journalCode,
      jpm: articleOverviewJPM,
      pdPublisher: articleOverviewPDPublisher,
      ppPublisher: articleOverviewPPPublisher,
      subjectGroup: articleOverviewSubjectGroup,
      businessDivision: articleOverviewBusinessDivision,
      revenueModel: revenueModel ? ['OA'] : [],
      journalStatus: articleOverviewJournalStatus,
    };
  };

  const initFilter  = () => {
    return {
      handlingCe: [],
      catGroup: [],
      journalCode: [],
      jpm: [],
      subjectGroup: [],
      businessDivision: [],
      revenueModel: [],
      journalStatus: [],
    };
  };

  const handleApply = async () => {
    const filters = prepareFilters();
    setApply(true);
    await dispatch(setArticleOAWaiverUseFilters(filters));
    handleCloseFilter();
  };

  const handleCloseFilter = () => {
    document.body.classList.remove('hide-scroll');
    handleClose();
  };

  const [open1, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
    dispatch(setArticleOAWFilterSelect('revenueModel', !newOpen));
    // console.log(revenueModel);
  };

  const filterBody = useRef(null);

  function goBottom() {
    setTimeout(()=>{
      const box = filterBody.current;
      if (box){
        box.scrollTop = box.scrollHeight - box.offsetHeight;
      }
    },301);
  }


  return (
    <Box
      sx={{
        width: '100%',
      }}
      data-selenium-id='Report_Articles_OAWaiverUse_Overview-anotherNewFilterBox'
    >
      <Backdrop
        id='reportArticlesOAWaiverUseAnotherNewFilterDrawerBackdrop'
        data-selenium-id={'Report_Articles_OAWaiverUse_Overview-anotherNewFilterDrawerBackdrop'}
        sx={{
          zIndex: 1400,
          backdropFilter: 'blur(4px)',
          background: '#113D9580',
        }}
        open={filterOpen}
      ></Backdrop>
      <Drawer
        id='reportArticlesOAWaiverUseAnotherNewFilterDrawer'
        variant='persistent'
        anchor='right'
        open={filterOpen}
        sx={{
          flexShrink: 0,
          ['& .MuiDrawer-paper']: {
            width: '472px',
            zIndex: 1400,
            backgroundColor: '#FFFFFF',
          },
        }}
        data-selenium-id={'Report_Articles_OAWaiverUse_Overview-anotherNewFilterDrawer'}
      >
        <Box
          // direction= 'row'
          sx={{
            m: '20px 36px',
            height: '34px',
          }}
        >
          <CloseIconButton
            sx={{ float: 'left', padding: '0px', marginRight: '12px', pt: '5px' }}
            onClick={() => {
              handleCloseFilter();
            }}
            data-selenium-id='ReportRelated-ReportOverview-OAWaiverUseCloseButton'
          />
          <Box
            // display="flex" justifyContent="center"
            sx={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22.5px',
              height: '13px',
              float: 'left',
              color: '#262E35',
              mt: '5px',
              // mb: '10px',
            }}
            data-selenium-id='Report_Articles_OAWaiverUse_Overview-Box-Box'
          >
            Filters
          </Box>
          <StateButton
            onClick={handleApply}
            data-selenium-id='Report_Articles_OAWaiverUse_Overview-Box2-Filter'
            titleName='Filter'
            isPrimary={true}
            sx={{
              lineHeight: '18px',
              marginLeft: '10px',
              float: 'right',
              // '&:disabled': {
              //   backgroundColor: '#BCC5CF',
              //   boxShadow: 'none',
              //   color: '#FFFFFF',
              // },
            }}
          // disabled={isError()}
          ></StateButton>

          <StateButton
            onClick={handleReset}
            data-selenium-id='Report_Articles_OAWaiverUse_Overview-Box1'
            titleName='Clear'
            isPrimary={false}
            sx={{ lineHeight: '18px', float: 'right' }}
          ></StateButton>
        </Box>

        <Divider></Divider>
        <Stack
          ref={filterBody}
          direction="column"
          spacing={3}
          sx={{
            p: '20px 36px 0px 36px',
            overflowY: 'auto',
            scrollbarWidth: 'thin',  // 设置滚动条宽度
            // scrollbarColor: 'transparent',  // 设置滚动条颜色为透明
            // '&::-webkit-scrollbar': {
            //   width: '0px',  // 设置滚动条宽度
            // },
            // '&::-webkit-scrollbar-thumb': {
            //   background: 'transparent',  // 设置滚动条背景为透明
            // },
          }}
        >
          {/* Journal */}
          <Box>

            <Box
              sx={{ ...SmallFont, color: '#596A7C', pb: '4px', fontWeight: '600', letterSpacing: '0.01em' }}
              data-selenium-id='Report_Articles_OAWaiverUse_Overview-Filter-Block-Title'
            >
              Journal
            </Box>
            <MulSelectInput
              id='reportArticlesOAWaiverUseFilterjournalSelect'
              data-selenium-id='Report_Articles_OAWaiverUse_Overview_Filter-journalSelect'
              value={articleOverviewJournal}
              placeholder={'Search Journal Name or Code'}
              defaultOption={key => (key)}
              getOptionLabel={option => option}
              options={journalData}
              loadOptionAction={onLoad.bind(this, 'journal')}
              onValueChange={
                handleSelectedChange.bind(this, 'reportJournal')
              }
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
            />
          </Box>
          {/* JPM */}
          {showJPM && <Box>
            <Box
              sx={{ ...SmallFont, color: '#596A7C', pb: '4px', fontWeight: '600', letterSpacing: '0.01em' }}
              data-selenium-id='Report_Articles_OAWaiverUse_Overview-Filter-Block-Title'
            >
              JPM
            </Box>
            <MulSelectInput
              id='reportArticlesOAWaiverUseFilterJpmSelect'
              data-selenium-id='Report_Articles_OAWaiverUse_Overview_Filter-JpmSelect'
              value={articleOverviewJPM}
              placeholder={'Search JPM'}
              defaultOption={key => (key)}
              getOptionLabel={option => option}
              options={jpmData}
              loadOptionAction={onLoad.bind(this, 'jpm')}
              onValueChange={
                handleSelectedChange.bind(this, 'reportJPM')
              }
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
            />
          </Box>}

          {/* PD Publisher */}
          { showNewJournalRoles && <Box>
            <Box
              sx={{ ...SmallFont, color: '#596A7C', pb: '4px', fontWeight: '600', letterSpacing: '0.01em' }}
              data-selenium-id='Report_Articles_OAWaiverUse_Overview-Filter-Block-Title'
            >
              PD Publisher
            </Box>
            <MulSelectInput
              id='reportArticlesOAWaiverUseFilterPDPublisherSelect'
              data-selenium-id='Report_Articles_OAWaiverUse_Overview_Filter-PDPublisherSelect'
              value={articleOverviewPDPublisher}
              placeholder={'Search PDPublisher'}
              defaultOption={key => (key)}
              getOptionLabel={option => option}
              options={pdPublisherData}
              loadOptionAction={onLoad.bind(this, 'pdPublisher')}
              onValueChange={
                handleSelectedChange.bind(this, 'reportPDPublisher')
              }
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
            />
          </Box>}
           
          {/* PP Publisher */}
          { showNewJournalRoles && <Box>
            <Box
              sx={{ ...SmallFont, color: '#596A7C', pb: '4px', fontWeight: '600', letterSpacing: '0.01em' }}
              data-selenium-id='Report_Articles_OAWaiverUse_Overview-Filter-Block-Title'
            >
              PP Publisher
            </Box>
            <MulSelectInput
              id='reportArticlesOAWaiverUseFilterPPPublisherSelect'
              data-selenium-id='Report_Articles_OAWaiverUse_Overview_Filter-PPPublisherSelect'
              value={articleOverviewPPPublisher}
              placeholder={'Search PP Publisher'}
              defaultOption={key => (key)}
              getOptionLabel={option => option}
              options={ppPublisherData}
              loadOptionAction={onLoad.bind(this, 'ppPublisher')}
              onValueChange={
                handleSelectedChange.bind(this, 'reportPPPublisher')
              }
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
            />
           
          </Box>}

          {/* Subject Group */}
          <Box>
            <Box
              sx={{ ...SmallFont, color: '#596A7C', pb: '4px', fontWeight: '600', letterSpacing: '0.01em' }}
              data-selenium-id='Report_Articles_OAWaiverUse_Overview-Filter-Block-Title'
            >
              Subject Group
            </Box>
            <MulSelectInput
              id='reportArticlesOAWaiverUseFilterSubjectgroupSelect'
              data-selenium-id='Report_Articles_OAWaiverUse_Overview_Filter-SubjectgroupSelect'
              value={articleOverviewSubjectGroup}
              placeholder={'Search Subject Group'}
              defaultOption={key => (key)}
              getOptionLabel={option => option}
              options={subjectGroupData}
              loadOptionAction={onLoad.bind(this, 'subjectGroup')}
              onValueChange={
                handleSelectedChange.bind(this, 'reportSubjectGroup')
              }
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
            />
          </Box>
          {/* CAT Group */}
          <Box>
            <Box
              sx={{ ...SmallFont, color: '#596A7C', pb: '4px', fontWeight: '600', letterSpacing: '0.01em' }}
              data-selenium-id='Report_Articles_OAWaiverUse_Overview-Filter-Block-Title'
            >
              CAT Group
            </Box>
            <MulSelectInput
              id='reportArticlesOAWaiverUseFilterCatgroupSelect'
              data-selenium-id='Report_Articles_OAWaiverUse_Overview_Filter-CatgroupSelect'
              value={articleOverviewCATGroup}
              placeholder='Choose Group Title'
              defaultOption={ key =>(key)}
              getOptionLabel={option => option.value}
              options={catGroupData}
              loadOptionAction={(...props) => onLoad.bind(this, 'catGroup')(...props)}
              onValueChange={(value) => {
                handleSelectedChange.bind(this, 'reportCATGroup')(value);
              }}
              disableNewItem={true}
              showPopupIcon={true}
              showStartIcon={false}
            />
          </Box>
          {/* Business Division */}
          <Box>
            <Box
              sx={{ ...SmallFont, color: '#596A7C', pb: '4px', fontWeight: '600', letterSpacing: '0.01em' }}
              data-selenium-id='Report_Articles_OAWaiverUse_Overview-Filter-Block-Title'
            >
              Business Division
            </Box>
             <MulSelectInput
              id='reportArticlesOAWaiverUseFilterBusinessdivisionSelect'
              data-selenium-id='Report_Articles_OAWaiverUse_Overview_Filter-BusinessdivisionSelect'
              value={articleOverviewBusinessDivision}
              placeholder='Choose Business Division'
              defaultOption={key => key}
              getOptionLabel={option => option}
              options={businessDivisionData}
              loadOptionAction={(...props) =>
                onLoad.bind(this, 'businessDivision')(...props)
              }
              onValueChange={value => {
                handleSelectedChange.bind(
                  this,
                  'reportBusinessDivision'
                )(value);
              }}
              disableNewItem={true}
              showPopupIcon={true}
              showStartIcon={false}
            />
          </Box>
          {/* Handling CE */}
          <Box>
            <Box
              sx={{ ...SmallFont, color: '#596A7C', pb: '4px', fontWeight: '600', letterSpacing: '0.01em' }}
              data-selenium-id='Report_Articles_OAWaiverUse_Overview-Filter-Block-Title'
            >
              Handling CE
            </Box>
            <MulSelectInput
              id='reportArticlesOAWaiverUseFilterHeSelect'
              data-selenium-id='Report_Articles_OAWaiverUse_Overview_Filter-HeSelect'
              value={articleOverviewHandlingCE}
              placeholder={'Search Handling CE'}
              defaultOption={key => (key)}
              getOptionLabel={item => {
                return `${item.username} (${item.email})`;
              }}
              options={handlingCeData}
              loadOptionAction={onLoad.bind(this, 'handlingCe')}
              onValueChange={
                handleSelectedChange.bind(this, 'reportHandlingCE')
              }
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
              onOpen={goBottom}
            />
          </Box>
          {/* Journal Status */}
          <Box>

            <Box
              sx={{ ...SmallFont, color: '#596A7C', pb: '4px', fontWeight: '600', letterSpacing: '0.01em' }}
              data-selenium-id='Report_Articles_OAWaiverUse_Overview-Filter-Block-Title'
            >
              Journal Status
            </Box>
            <MulSelectInput
              id='journalStatus'
              data-selenium-id='Report_Articles_OAWaiverUse_Overview_Filter-journalStatus'
              value={articleOverviewJournalStatus}
              placeholder='Choose Journal Status'
              defaultOption={key => key}
              getOptionLabel={option => option}
              options={['opt-in', 'opt-out']}
              onValueChange={value => {
                handleSelectedChange.bind(this, 'reportJournalStatus')(value);
              }}
              disableNewItem={true}
              showPopupIcon={true}
              showStartIcon={false}
              dropDownListNumber={2}
              onOpen={goBottom}
            />
          </Box>
          <Stack direction='row' alignItems="center" spacing='50px'>
            <Stack direction='row' alignItems="center" spacing='8px'>
              <Checkbox
                disableRipple
                disabled={!revenueModel}
                icon={<ReportFilterSwitchIconOff />}
                checkedIcon={<ReportFilterSwitchIconOn />}
                checked={!revenueModel}
                onChange={handlerevenueModelChange}
                sx={{
                  padding: '0px',
                }}
              />
              <Box
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '18px',
                  letterSpacing: '0em',
                }}
              >
                All
              </Box>
            </Stack>
            <Stack direction='row' alignItems="center" spacing='8px'>
              <Checkbox
                disableRipple
                disabled={revenueModel}
                icon={<ReportFilterSwitchIconOff />}
                checkedIcon={<ReportFilterSwitchIconOn />}
                checked={revenueModel}
                onChange={handlerevenueModelChange}
                sx={{
                  padding: '0px',
                }}
              />
              <Box
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '18px',
                  letterSpacing: '0em',
                }}
              >
                OA
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Drawer>
    </Box>
  );
}

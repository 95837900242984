import { useDispatch, useSelector } from 'react-redux';
import Item from '../../Framework/Block/Item';
import Section from '../../Framework/Block/Section';
import SelectBox from './CommonComponents/SelectBox';
import { activeStageList, alertList, groupList, stageList } from './utils';
import { setSelect } from '@/actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import { Box, Checkbox, Stack } from '@mui/material';
import ActiveSICheckboxIcon from '../../SVGComponents/ActiveSICheckboxIcon.svg';
import ActiveSICheckboxCheckedIcon from '../../SVGComponents/ActiveSICheckboxCheckedIcon.svg';
import ActiveSITipIcon from '../../SVGComponents/ActiveSITipIcon.svg';
import { useState, useEffect } from 'react';
import { CustomTooltip } from '@/components/CustomTooltip';
import { MiniBoldFont } from '@/components/FontStyle';
import { ColumnCentered } from '../../Css';
import { StyledTooltip } from '../../StyledComponents/styledToolTip';
import React, { useContext } from 'react';
import { isCNmarketingContext } from '@/Route';
import IconBox from '../../CommonComponets/IconBox';

import {loadGroups} from "@/actions/SIMT-User/Config";

export default function SecondSection(props) {
  const activeSITootipTitle = (
    <React.Fragment>
      Active SI includes Acquired, Paper Commission, Submission
      <br />& Review and Production stage
    </React.Fragment>
  );
  const [activeSIChecked, setActiveSIChecked] = useState(false);
  const dispatch = useDispatch();
  const { siFilterSelected } = useSelector(state => {
    return {
      siFilterSelected: state.Filter.siFilterSelected,
    };
  });

  const titleGroupList = useSelector(state => {
    return state.SystemConfig.groupList.map(item => item.value);
  });
  const handleSelectedChange = (which, data) => {
    dispatch(setSelect(which, data));
  };

  useEffect(() => {
    dispatch(loadGroups());
  }, []);

  useEffect(() => {
    if (activeStageList.length !== siFilterSelected.stageSelected.length) {
      setActiveSIChecked(false);
      return;
    }
    for (let i = 0; i < activeStageList.length; i++) {
      if (!siFilterSelected.stageSelected.includes(activeStageList[i])) {
        setActiveSIChecked(false);
        return;
      }
    }
    setActiveSIChecked(true);
  }, [siFilterSelected.stageSelected]);
  const isCNmarketing = useContext(isCNmarketingContext) ?? false;
  return (
    <Section id='second_section'>
      <Item
        id='Stage'
        title='Stage'
        needResetBtn={true}
        handleReset={handleSelectedChange.bind(this, 'stage', [])}
        isCNmarketing={isCNmarketing}
      >
        <Stack spacing={0.375} direction='column'>
          <SelectBox
            id='stage_select_box'
            dropList={stageList}
            value={siFilterSelected.stageSelected}
            setValue={handleSelectedChange.bind(this, 'stage')}
            placeholder='Choose Stage'
            dropListHeight='180px'
          />
          <Stack spacing={0.75} direction='row' sx={ColumnCentered}>
            <Checkbox
              icon={<ActiveSICheckboxIcon />}
              checkedIcon={<ActiveSICheckboxCheckedIcon />}
              onChange={e => {
                let checked = e.target.checked;
                if (checked) {
                  handleSelectedChange('stage', activeStageList);
                } else {
                  handleSelectedChange('stage', []);
                }
              }}
              checked={activeSIChecked}
              sx={{
                padding: '0px',
                ':hover': {
                  backgroundColor: 'transparent',
                  '& svg rect': activeSIChecked
                    ? { fill: '#113D95' }
                    : { stroke: '#596A7C' },
                },
                '& .MuiTouchRipple-root': {
                  display: 'none',
                },
              }}
            />
            <StyledTooltip
              title={activeSITootipTitle}
              placement='top'
              sx={{
                zIndex: '12006',
              }}
            >
              <Stack spacing={0.75} direction='row' sx={ColumnCentered}>
                <Box
                  sx={{
                    ...MiniBoldFont,
                    lineHeight: '20px',
                    letterSpacing: '0.01em',
                    color: '#596A7C',
                  }}
                >
                  Active SI
                </Box>
                <ActiveSITipIcon />
              </Stack>
            </StyledTooltip>
          </Stack>
        </Stack>
      </Item>
      {!isCNmarketing && (
        <>
          <Item
            id='Group/Title'
            title='Group/Title'
            needResetBtn={true}
            handleReset={handleSelectedChange.bind(this, 'catGroup', [])}
          >
            <SelectBox
              id='group/titleSelectBox'
              dropList={titleGroupList}
              value={siFilterSelected.catGroupSelected}
              setValue={handleSelectedChange.bind(this, 'catGroup')}
              placeholder='Choose Group Title'
              dropListHeight='154px'
            />
          </Item>
          <Item
            id='SIwithAlerts'
            title='Special Issues with Alerts'
            needResetBtn={true}
            handleReset={handleSelectedChange.bind(this, 'sisWithAlerts', [])}
          >
            <SelectBox
              id='SIwithAlertsSelectBox'
              dropList={alertList}
              value={siFilterSelected.sisWithAlertsSelected}
              setValue={handleSelectedChange.bind(this, 'sisWithAlerts')}
              placeholder='Choose SIs with alerts'
              dropListHeight='140.5px'
              needChipIcon={true}
            />
          </Item>
        </>
      )}
    </Section>
  );
}

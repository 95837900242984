import { useEffect, useRef } from 'react';
import ChartGroup from './ChartGroup';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';

export const ChartList = ({
  chartGroupTitles,
  showGroupTitle = false,
  onSelectChart,
  onSelectChartIndex,
  selectedChartIndex,
}) => {
  const listRef = useRef(null);

  // scroll selected icon into view
  useEffect(() => {
    const distanceToTop = 180 * selectedChartIndex;

    // console.log('listRef', listRef.current);

    listRef.current?.scrollTo({
      top: distanceToTop,
      left: 0,
      behavior: 'smooth',
    });
  }, [selectedChartIndex]);

  return (
    <div className={styles['card-list']} ref={listRef}>
      {chartGroupTitles &&
        chartGroupTitles.map(group => {
          const { groupTitle, levels } = group;
          return (
            <ChartGroup
              groupTitle={groupTitle}
              levels={levels}
              showGroupTitle={showGroupTitle}
              onSelectChart={onSelectChart}
              onSelectChartIndex={onSelectChartIndex}
              selectedChartIndex={selectedChartIndex}
            />
          );
        })}
    </div>
  );
};

ChartList.propTypes = {
  chartGroupTitles: PropTypes.array.isRequired,
  showGroupTitle: PropTypes.bool,
  onSelectChart: PropTypes.func,
  selectedChartIndex: PropTypes.number,
  onSelectChartIndex: PropTypes.func,
};

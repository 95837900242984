import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Stack,
} from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  initCV,
  UPDATE_CV_UPLOADING_PROGRESS,
} from '@/actions/SIMT-SIP/SIP/SIPAction';
import {
  RegularBoldFont1,
  SmallBoldFont,
  TinyFont,
} from '../../../../components/FontStyle';
import {
  LeadGEFormControlLabel,
  DialogItemBlock,
  GEInfomationEditAutoComplete,
  GEInfomationEditInput,
  GEInfomationEditRadio,
  GEInfomationEditSelect,
  UplaodCVNoFile,
  UplaodCVHasFile,
  ConfilctFormControlLabel,
} from '../EditComponents';
import { FileUploader } from '@/components/FileUploader';
import SimpleRadio from '@/componentsphase2/Input/SimpleRadio';
import SimpleLabel from '@/componentsphase2/Input/SimpleLabel';
import {
  CreateAndUpdateInput,
  OnlyTextWithX,
} from '@/componentsphase2/OnlyText';
import CreateSIInputBlock from '@/pages/SIPage/CreateAndUpdateSI/CommonComponents/CreateSIInputBlock';
import CreateAndUpdateAutoComplete from '@/pages/SIPage/CreateAndUpdateSI/CommonComponents/CreateAndUpdateAutoComplete';
import { SearchBox } from '@/componentsphase2/SearchBox/SearchBox';
import {
  MulSelectInput,
  SelectInput,
} from '@/componentsphase2/SelectBox/SelectInput';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import ErrorTag from './SVG/Soild.svg';
import EmailInput from './EmailInput';
import { useIntl } from 'react-intl';
import {
  DeleteCV,
  getGEInformationCountryDroplist,
  getGEInformationJobTitleDroplist,
  UploadCV
} from "@/actions/SIMT-SIP/SIP/SipDetail";
import { PATTERNORCID, PATTERNWEBSITE } from '@/constant/common';

export function RadioErrorBox(props) {
  const { isError, errorMessage = 'Please fill in the required field below:' } =
    props;
  return (
    isError && (
      <Stack
        direction='row'
        spacing={0.25}
        sx={{
          height: '16px',
          display: 'flex',
          alignItems: 'center',
          marginTop: '8px',
        }}
      >
        <ErrorTag />
        <Box
          sx={{
            ...SmallBoldFont,
            lineHeight: '16px',
            letterSpacing: '0em',
            color: '#EE5350',
          }}
        >
          {errorMessage}
        </Box>
      </Stack>
    )
  );
}

export default function EditContent(props) {
  const {
    value = {
      leadGe: null,
      jobTitle: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      department: '',
      institution: '',
      city: '',
      country: '',
      orcidId: '',
      researchGateId: '',
      profileWebsite: '',
      cv: null,
      cvSize: null,
      hasConflict: null,
      conflictDetail: '',
    },
    setValue = newValue => {},
    isClick = false,
    setEmailAddressFormatErr = () => {},
    setWebFormatErr = () => {},
    webFormatErr = false,
    orcIdFormatErr = false,
    setOrcIdFormatErr = () => {},
  } = props;
  const EmailPattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  const webPattern = PATTERNWEBSITE;
  const patternORCID = PATTERNORCID;
  const [leadGE, setLeadGE] = useState(true);
  const [jobTitle, setJobTitle] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [department, setDepartment] = useState('');
  const [institution, setInstitution] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [orcidId, setOrcidId] = useState('');
  const [researchGateId, setResearchGateId] = useState('');
  const [profileWebsite, setProfileWebsite] = useState('');
  const [cv, setCV] = useState(null);
  const [hasConflict, setHasConflict] = useState(true);
  const [conflictDetail, setConflictDetail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isShowConflictInput, setIsShowConflictInput] = useState(true);
  const [isCreateGE, setIsCreateGE] = useState(false);
  const [webFormatErrInner, setWebFormatErrInner] = useState(webFormatErr);

  const [orcidFocused, setOrcidFocused] = useState(false);
  const [profileFocused, setProfileFocused] = useState(false);

  const selector = state => {
    return {
      sipDetailGEInfomationCVIdResult:
        state.SIP.sipDetailGEInfomationCVIdResult,
      geInfomationCountryDroplistResult:
        state.SIP.geInfomationCountryDroplistResult,
      geInfomationJobTitleDroplistResult:
        state.SIP.geInfomationJobTitleDroplistResult,
      sipDetailGEInfomationCVIdMonitor:
        state.SIP.sipDetailGEInfomationCVIdMonitor,
      cvUploadingProgress: state.SIP.cvUploadingProgress,
      sipDetailGEInfomationUploadCVIdStatus:
        state.SIP.sipDetailGEInfomationUploadCVIdStatus,
      sipType: state.SIP.sipInformation?.sipType,
      sipStage: state.SIP.sipStatus?.sipStage,
      sipDetailAllMandatorySubmitOrNextPressed: state.SIP.sipDetailAllMandatorySubmitOrNextPressed,
      currentDecisionType: state.SIP.currentDecisionType,
    };
  };
  const {
    sipDetailGEInfomationCVIdResult,
    geInfomationCountryDroplistResult,
    geInfomationJobTitleDroplistResult,
    sipDetailGEInfomationCVIdMonitor,
    cvUploadingProgress,
    sipDetailGEInfomationUploadCVIdStatus,
    sipType,
    sipStage,
    sipDetailAllMandatorySubmitOrNextPressed,
    currentDecisionType,
  } = useSelector(selector);

  const dispatch = useDispatch();
  const intl = useIntl();

  const [hasDefaultFile, setHasDefaulFile] = useState(true);

  /**************************初始化数据*********************************** */
  const initData = () => {
    if (value && value.leadGe !== undefined) {
      setLeadGE(value.leadGe);
      setJobTitle(value.jobTitle ? value.jobTitle : '');
      setFirstName(value.firstName ? value.firstName : '');
      setLastName(value.lastName ? value.lastName : '');
      setEmailAddress(value.emailAddress ? value.emailAddress : '');
      setDepartment(value.department ? value.department : '');
      setInstitution(value.institution ? value.institution : '');
      setCity(value.city ? value.city : '');
      setCountry(value.country ? value.country : '');
      setOrcidId(value.orcidId ? value.orcidId : '');
      setResearchGateId(value.researchGateId ? value.researchGateId : '');
      setProfileWebsite(value.profileWebsite ? value.profileWebsite : '');
      setCV(value.cv);
      setHasConflict(value.hasConflict);
      setConflictDetail(value.conflictDetail ? value.conflictDetail : '');

      dispatch(initCV(value.cv));
      setIsCreateGE(value.leadGe === null);
    }
  };

  function containsNewline(str) {
    // 使用正则表达式检查是否包含换行符
    const newlinePattern = /[\r\n]/;
    return newlinePattern.test(str);
  }

  useEffect(() => {
    initData();
    dispatch(getGEInformationJobTitleDroplist());
  }, []);
  /********************************************************************** */

  /**************************CV 的相关处理************************************ */
  useEffect(() => {
    setLoading(false);
  }, [sipDetailGEInfomationCVIdMonitor]);
  /*************************************************************************** */

  /**************************处理结果************************************ */

  const buildData = () => {
    return {
      leadGe: leadGE,
      jobTitle: jobTitle,
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
      department: department,
      institution: institution,
      city: city,
      country: country,
      orcidId: orcidId,
      researchGateId: researchGateId,
      profileWebsite: profileWebsite,
      cv: sipDetailGEInfomationCVIdResult,
      hasConflict: hasConflict,
      conflictDetail: conflictDetail,
    };
  };

  useEffect(() => {
    setValue(buildData());
    //这里不知道为啥 如果不传第二个参数 就是一直调用
  }, [
    leadGE,
    jobTitle,
    firstName,
    lastName,
    emailAddress,
    department,
    institution,
    city,
    country,
    orcidId,
    researchGateId,
    profileWebsite,
    sipDetailGEInfomationCVIdResult,
    hasConflict,
    conflictDetail,
  ]);

  /************************************************************************** */
  // conflictInput的显示时机
  useEffect(() => {
    if (hasConflict) {
      setIsShowConflictInput(true);
    } else {
      setIsShowConflictInput(false);
    }
  }, [hasConflict]);

  useEffect(() => {
    // if (isClick){
      setWebFormatErr((webFormatErrInner || containsNewline(profileWebsite)) && profileWebsite !== '');
    // }
  },[isClick, webFormatErrInner, profileWebsite]);
  const isValueMandatory = sipStage != intl.messages['sipDetail.sipStageType.ideation'] || currentDecisionType === intl.messages['sipDetail.decisionPanelElements.moveToSubmitted'];
  const websiteOrCVCommonError=isClick && isValueMandatory;
  const onlyWebsiteError=profileWebsite&&(webFormatErrInner  || containsNewline(profileWebsite))&&isClick;
  const websiteOrCVError=!profileWebsite&&!sipDetailGEInfomationCVIdResult&&websiteOrCVCommonError; //CT-6662 
  return (
    <Box
      sx={{ width: '100%', overflow: 'visible' }}
      data-selenium-id='SIP_DetailPage_GE-EditContent-Box'
    >
      {/* 这个box的作用：因为这个select在选中的时候会高亮并且border会变大，又因为在顶部所以变大的时候 会超过父元素的顶部，从而导致顶部的border被隐藏了一部分*/}
      <Box
        sx={{ height: '1px', width: '100%', overflow: 'visible' }}
        data-selenium-id='SIP_DetailPage_GE-EditContent-BorderBox'
      ></Box>
      <Stack
        spacing={2}
        data-selenium-id='SIP_DetailPage_GE-EditContent-Stack'
        flexDirection={'row'}
        flexWrap={'wrap'}
        width={'852px'}
        sx={{ overflow: 'visible' }}
      >
        {/* Email Address: */}
        <DialogItemBlock
          title='Email Address'
          mandatory={true}
          sx={{ width: '400px', overflow: 'visible' }}
        >
          {/* <MyOnlyText
            id='emailAddress'
            height='40px'
            data-selenium-id='SIP_DetailPage_GE-EditContent-EmailAddress'
            placeholder=''
            value={emailAddress}
            setValue={setEmailAddress}
            // required={true}
            pattern={EmailPattern}
            setFormatError={setEmailAddressFormatErr}
            width='400px'
            isError={isClick && !emailAddress}
            errorMessage={'Please input all the mandatory fields!'}
          /> */}
          <EmailInput
            setEmailErr={setEmailAddressFormatErr}
            setValue={setEmailAddress}
            value={emailAddress}
            clickMonitor={isClick}
          />
        </DialogItemBlock>
        {/* <CreateSIInputBlock
          title='Email Address'
          required='true'
          value={emailAddress}
          setValue={setEmailAddress}
          id='emailAddress'
          emptyErr={isClick}
          // defaultValue,
          placeholder='Institutional email address is preferred'
          // limitLength,
          // exSpecialChar,
          // setSCErr,
          setEmailErr={setEmailAddressFormatErr}
        checkEmail={true}
        // moreSpecialCheck,
        // HandleChange,
        sx={{width:'400px'}}
        /> */}
        {/* Institution: */}
        <DialogItemBlock
          title='Institution'
          mandatory={isValueMandatory}
          sx={{
            width: '400px',
            marginTop: '0px !important',
            marginLeft: '52px !important',
          }}
        >
          <MyOnlyText
            id='institution'
            data-selenium-id='SIP_DetailPage_GE-EditContent-Institution'
            value={institution}
            setValue={setInstitution}
            width='400px'
            height='40px'
            isError={(isClick || (!isCreateGE && sipDetailAllMandatorySubmitOrNextPressed && sipStage === intl.messages['sipDetail.sipStageType.ideation'])) &&
              !institution && isValueMandatory }
          />
        </DialogItemBlock>
        {/* First Name */}
        <DialogItemBlock
          title='First Name'
          mandatory={true}
          sx={{
            width: '400px',
          }}
        >
          <MyOnlyText
            id='firstName'
            data-selenium-id='SIP_DetailPage_GE-EditContent-FirstName'
            value={firstName}
            setValue={setFirstName}
            width='400px'
            height='40px'
            isError={isClick && !firstName}
          />
        </DialogItemBlock>
        {/* City : */}
        <DialogItemBlock
          title='City '
          sx={{
            width: '400px',
            marginLeft: '52px !important',
          }}
        >
          {/* <GEInfomationEditInput
            id='city'
            data-selenium-id='SIP_DetailPage_GE-EditContent-City'
            value={city}
            setValue={setCity}
          /> */}
          <MyOnlyText
            id='city'
            data-selenium-id='SIP_DetailPage_GE-EditContent-City'
            value={city}
            setValue={setCity}
            width='400px'
            height='40px'
          />
        </DialogItemBlock>

        {/* Last Name */}
        <DialogItemBlock
          title='Last Name'
          mandatory={true}
          sx={{ width: '400px' }}
        >
          <MyOnlyText
            id='lastName'
            data-selenium-id='SIP_DetailPage_GE-EditContent-LastName'
            value={lastName}
            setValue={setLastName}
            width='400px'
            height='40px'
            isError={isClick && !lastName}
          />
        </DialogItemBlock>

        {/* Country/Region: */}
        <DialogItemBlock
          title='Country/Region'
          sx={{
            width: '400px',
            marginLeft: '52px !important',
          }}
          mandatory={isValueMandatory}
        >
          <SinpleAutocomplete
            id='Country/Region'
            data-selenium-id='SIP_DetailPage_GE-EditContent-Country/Region'
            placeholder='Choose/Enter Country/Region '
            value={country}
            multiple={false}
            onChange={(e, newValue) => {
              setCountry(newValue ? newValue : '');
            }}
            options={geInfomationCountryDroplistResult}
            loadOptionAction={getGEInformationCountryDroplist}
            needClearInputValue={true}
            //
            needKeyboardDelete={false}
            isErr={(isClick || (!isCreateGE && sipDetailAllMandatorySubmitOrNextPressed && sipStage === intl.messages['sipDetail.sipStageType.ideation'])) &&
              !country && isValueMandatory}
            isEmpty={!country}
          />
        </DialogItemBlock>
        {/* Job Title */}
        <DialogItemBlock
          title='Title'
          placeholder=''
          mandatory={true}
          sx={{ width: '400px' }}
        >
          <SinpleAutocomplete
            id='jobTitle'
            data-selenium-id='SIP_DetailPage_GE-EditContent-JobTitle'
            value={jobTitle}
            options={geInfomationJobTitleDroplistResult}
            isErr={isClick && jobTitle === ''}
            isEmpty={!jobTitle}
            disableNewItem={true}
            showPopupIcon={true}
            multiple={false}
            onChange={(e, v) => {
              setJobTitle(v);
            }}
            needKeyboardDelete={false}
            defaultQuery={true}
            needClearInputValue={true}
          />
        </DialogItemBlock>
        {/* ORCID ID: */}
        <DialogItemBlock
          title='ORCID ID'
          sx={{
            width: '400px',
            marginLeft: '52px !important',
          }}
        >
          <MyOnlyText
            id='orcidId'
            data-selenium-id='SIP_DetailPage_GE-EditContent-OrcidId'
            value={orcidId}
            setValue={setOrcidId}
            placeholder='0000-0000-0000-0000'
            needFormatCheck={true}
            pattern={patternORCID}
            setFormatError={setOrcIdFormatErr}
            isError={orcIdFormatErr && orcidId && !orcidFocused}
            errorMessage='Incorrect OCRID, please input like this way 0000-0000-0000-0000.'
            width='400px'
            height='40px'
            onFocus={()=>{setOrcidFocused(true);}}
            onBlur={()=>{setOrcidFocused(false);}}
          />
        </DialogItemBlock>
        {/* Lead GE: */}
        <DialogItemBlock
          title='Lead GE'
          mandatory={true}
          sx={{ width: '400px' }}
        >
          <RadioErrorBox
            isError={
              isClick &&
              (leadGE === undefined || leadGE === null || leadGE === '')
            }
          />
          <FormControl data-selenium-id='SIP_DetailPage_GE-EditContent-FormControl'>
            <RadioGroup
              data-selenium-id='SIP_DetailPage_GE-EditContent-FormControl-RadioGroup'
              row
              value={leadGE == null ? '' : leadGE ? 'yes' : 'no'}
              onChange={e => {
                setLeadGE(e.target.value === 'yes' ? true : false);
              }}
              sx={{ marginTop: '12px' }}
            >
              <SimpleLabel
                data-selenium-id='SIP_DetailPage_GE-EditContent-LeadGEFormControlLabel1'
                value='yes'
                control={
                  <SimpleRadio
                    sx={{
                      '& span': {
                        paddingTop: 0,
                        paddingButtom: 0,
                      },
                    }}
                    isError={
                      isClick &&
                      (leadGE === undefined || leadGE === null || leadGE === '')
                    }
                  />
                }
                label='Yes'
              />
              <SimpleLabel
                data-selenium-id='SIP_DetailPage_GE-EditContent-LeadGEFormControlLabel2'
                value='no'
                control={<SimpleRadio />}
                label='No'
              />
            </RadioGroup>
          </FormControl>
        </DialogItemBlock>
        {/* ResearchGate ID: */}
        <DialogItemBlock
          title='ResearchGate ID'
          sx={{
            width: '400px',
            marginLeft: '52px !important',
          }}
        >
          <MyOnlyText
            id='researchGateId'
            data-selenium-id='SIP_DetailPage_GE-EditContent-ResearchGateId'
            value={researchGateId}
            setValue={setResearchGateId}
            width='400px'
            height='40px'
          />
        </DialogItemBlock>
        {/* Department: */}
        <DialogItemBlock title='Department' sx={{ width: '400px' }}>
          <MyOnlyText
            id='department'
            data-selenium-id='SIP_DetailPage_GE-EditContent-Department'
            value={department}
            setValue={setDepartment}
            width='400px'
          />
        </DialogItemBlock>

        {/* Profile: */}
        <DialogItemBlock
          title='Profile Website(s)'
          mandatory={isValueMandatory}
          sx={{
            width: '400px',
            marginLeft: '52px !important',
            overflow: 'visible',
          }}
        >
          <MyOnlyText
            id='profile'
            data-selenium-id='SIP_DetailPage_GE-EditContent-Profile'
            placeholder='' //The URL to ResearchGate Profile is preferred
            value={profileWebsite}
            setValue={setProfileWebsite}
            // url的格式暂时不做要求
            needFormatCheck={true}
            pattern={webPattern}
            setFormatError={setWebFormatErrInner}
            width='400px'
            height='40px'
            isError={!profileFocused && (onlyWebsiteError || websiteOrCVError)}
            errorMessage={onlyWebsiteError? 'The profile website must be a valid URL.': ''}
            onFocus={()=>{setProfileFocused(true);}}
            onBlur={()=>{setProfileFocused(false);}}
            
          />
          <Divider
            data-selenium-id='SIP_DetailPage_GE-EditContent-AndOrDivider'
            textAlign='center'
            sx={{
              borderColor: '#F1F3F5',
              margin: '8px 0px 8px 0px',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '125%',
              color: '#BCC5CF',
              width: '400px',
              ...(websiteOrCVError&&{'&+div>div':{border:'2px solid #EE5350'},}), //CT-6662
            }}
          >
            and/or
          </Divider>
          <FileUploader
            progress={cvUploadingProgress}
            setProgress={x => {
              dispatch({ type: UPDATE_CV_UPLOADING_PROGRESS, data: x });
            }}
            uploadFlag={sipDetailGEInfomationUploadCVIdStatus}
            handleUpload={(targetFile, cancelToken) => {
              dispatch(UploadCV({ cv: targetFile, cancelToken: cancelToken }));
              setLoading(true);
            }}
            handleDelete={() => {
              dispatch(DeleteCV(sipDetailGEInfomationCVIdResult));
            }}
            setLoading={setLoading}
            loading={loading}
            defaultFile={value.cv}
            hasDefaultFile={hasDefaultFile}
            setHasDefaulFile={setHasDefaulFile}
            inifileSize={value.cvSize}
          />
        </DialogItemBlock>
        <Divider
          data-selenium-id='SIP_DetailPage_GE-EditContent-Divider'
          sx={{
            borderColor: '#F1F3F5',
            width: '852px',
            marginTop:'32px !important',
            marginBottom:'16px !important'
          }}
        ></Divider>
        {/* Conflict of Interest Declaration */}
        <DialogItemBlock
          title='Conflict of Interest Declaration'
          mandatory={isValueMandatory}
          // sx={{ width: '400px' }}
          subTitle='Please declare whether the current Guest Editor has any conflicts of interest or not:'

          direction='column'
          titleSX={{
            ...RegularBoldFont1,
            lineHeight: '22px',
            color: '#262E35',
          }}
        >
          <RadioErrorBox
            isError={
              (isClick || (!isCreateGE && sipDetailAllMandatorySubmitOrNextPressed && sipStage === intl.messages['sipDetail.sipStageType.ideation'])) &&
              (hasConflict === undefined || hasConflict === null ||  hasConflict === '') && isValueMandatory
            }
          />
          <FormControl
            sx={{ margin: '8px 0px' }}
            data-selenium-id='SIP_DetailPage_GE-EditContent-FromControl'
            sx={{
              '.MuiFormControlLabel-root':{
                marginRight:'56px'
              }
            }}
          >
            <RadioGroup
              data-selenium-id='SIP_DetailPage_GE-EditContent-RadioGroup'
              row
              value={hasConflict === null ? '' : hasConflict ? 'yes' : 'no'}
              onChange={e => {
                setHasConflict(e.target.value === 'yes' ? true : false);
              }}
            >
              <SimpleLabel
                id='hasConfilct-Yes'
                data-selenium-id='SIP_DetailPage_GE-EditContent-HasConfilctYes'
                value='yes'
                control={
                  <SimpleRadio
                    sx={{ width: '14px', height: '14px', padding: '0px' }}
                    isError={
                      isClick &&
                      (hasConflict === undefined ||
                        hasConflict === null ||
                        hasConflict === '') && isValueMandatory
                    }
                  />
                }
                label='Yes'
                sx={{
                  color: '#0F172A',
                }}
              />
              <SimpleLabel
                id='hasConfilct-No'
                data-selenium-id='SIP_DetailPage_GE-EditConte-HasConfilctNo'
                value='no'
                control={
                  <SimpleRadio
                    sx={{
                      width: '14px',
                      height: '14px',
                      padding: '0px',
                    }}
                  />
                }
                label='No'
                sx={{ color: '#0F172A' }}
              />
            </RadioGroup>
          </FormControl>
          {isShowConflictInput && (
            <DialogItemBlock title='Comments' mandatory={true} direction='column'>
            <Box sx={{ position: 'relative', height: '88px' }}>
              <MyOnlyText
                id='conflictInput'
                data-selenium-id='SIP_DetailPage_GE-EditContent-ConflictInput'
                value={conflictDetail}
                setValue={setConflictDetail}
                placeholder='Please provide more details on the conflict(s) of interest'
                needEmptyCheck={hasConflict}
                isError={(!conflictDetail && isClick) || conflictDetail?.length >10000}
                errorMessage={conflictDetail?.length >10000?'Word count needs to be less than 10000':' Please provide the required information.'}
                minRows={3}
                maxRows={3}
                width='852px'
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  ...TinyFont,
                  color: '#596A7C',
                }}
              >
                {new Intl.NumberFormat('en-US').format(
                  conflictDetail?.length ?? 0
                )}
                /10,000
              </Box>
            </Box>
            </DialogItemBlock>
          )}
        </DialogItemBlock>
      </Stack>
    </Box>
  );
}

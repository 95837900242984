import styles from './index.module.scss';

const ErrorPage = () => {
  return (
    <div className={styles['error-container']}>
      <div className={styles['error-img']}></div>
    </div>
  );
};

export default ErrorPage;

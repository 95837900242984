import React from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  Stack,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelSelectAll,
  cancelSelectAllUser,
  selectAll,
  selectAllUser,
} from '../../actions/SeleteAction';
import {
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import '../../App.css';
import { FormattedMessage } from 'react-intl';
import { GlobalPagination } from '../GlobalPagination';
import SimpleCheckBox from '@/componentsphase2/Input/SimpleCheckBox';


// // 改变标题行的颜色
export const StyledTableCell = styled(TableCell)(({ sx }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f1f3f5',
    color: '#505050',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    textTransform: 'uppercase',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '43px',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '24px',
    ...sx,
  },
}));

/**
 * 列表头部
 * @param {import("./index").MyTableHeaderProps} props
 * @returns
 */
export function MyTableHeader(props) {
  //order:排序顺序，asc、desc、none
  //orderBy：根据某一字段排序
  //items：表头元素
  //hasCheckbox:表头是否有复选框
  //isAllCheck：是否全选
  //onSelectAllClick：全选后的逻辑处理
  //CheckboxColor：CheckBox的颜色，默认是MUI的Primary
  //ifLinefeed:判断是否根据items.label进行换行
  //ifIndeterminate:判断是否启用不确定状态
  //select:选中的内容
  const {
    order = 'none',
    orderBy,
    items,
    onRequestSort = () => { },
    hasCheckbox = false,
    hasDatePassedFlags = false,
    showColumns,
    handleSelectAll = () => { },
    handleUnselectAll = () => { },
    CheckboxColor,
    checkboxIcon,
    checkboxCheckedIcon,
    checkboxindeterminateIcon,
    forUserTable,
    color = '#596A7C',
    backgroundColor = '#f1f3f5',
    ifLinefeed = false,
    ifIndeterminate = false,
    selected = [],
    PaddingLeft = '24px',
    Header0PaddingLeft = '24px',
    allNum,
    hasCheckboxStick,
    children=null,
  } = props;
  const dispatch = useDispatch();
  // const selector = state => {
  //   return {
  //     isAllSeleted: state.Selete.isAllSeleted,
  //     isAllUserSeleted: state.Selete.isAllUserSeleted,
  //   };
  // };
  // const { isAllSeleted, isAllUserSeleted } = useSelector(selector);
  // const { isAllUserSeleted } = useSelector(selector);

  const isAllSeleted = (allNum===selected || allNum === selected?.length) && allNum !== 0;
  const isAllUserSeleted = isAllSeleted;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
    // console.log(`property: ${property}`);
    // console.log(`orderBy: ${orderBy}`)
  };
  let paddingVertical = '12px';
  const doubleLine = [
    'JOURNAL CODE',
    'SI PUBLICATION FORMAT',
    'OPEN FOR SUBMISSION DATE',
    'SUBMISSION DEADLINE',
    'EXPECTED ACCEPTANCE DATE',
    'EXPECTED PUBLICATION DATE',
    'CLOSED FOR SUBMISSION',
    'PAPER COMMISSIONING METHOD',
    'ACTUAL PUBLICATION DATE',
    'NUMBER OF OO ARTICLE',
    'NUMBER OF OA ARTICLE',
    'LAST ACTION DATE',
  ];
  const ChooseHeadFormatByLabel = (label, index) => {
    if (!ifLinefeed || !showColumns[index]) return label;
    if (doubleLine.includes(label)) {
      paddingVertical = '3px';
      switch (label) {
        case 'JOURNAL CODE':
          return 'JOURNAL\nCODE';
        case 'SI PUBLICATION FORMAT':
          return 'SI PUBLICATION\nFORMAT';
        case 'SUBMISSION DEADLINE':
          return 'SUBMISSION\nDEADLINE';
        case 'NUMBER OF OA ARTICLE':
          return 'NUMBER\nOF OA ARTICLE';
        case 'NUMBER OF OO ARTICLE':
          return 'NUMBER\nOF OO ARTICLE';
        case 'PAPER COMMISSIONING METHOD':
          return 'PAPER\nCOMMISSIONING METHOD';
        case 'ACTUAL PUBLICATION DATE':
          return 'ACTUAL\nPUBLICATION DATE';
        case 'LAST ACTION DATE':
          return 'LAST\nACTION DATE';
        case 'CLOSED FOR SUBMISSION':
          return 'CLOSED FOR\nSUBMISSION';
        case 'EXPECTED ACCEPTANCE DATE':
          return 'EXPECTED\nACCEPTANCE DATE';
        case 'EXPECTED PUBLICATION DATE':
          return 'EXPECTED\nPUBLICATION DATE';
        case 'OPEN FOR SUBMISSION DATE':
          return 'OPEN FOR\nSUBMISSION DATE';
        default:
          return label;
      }
    } else {
      return label;
    }
  };
  // 改变标题行的颜色
  const NewStyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: backgroundColor,
      color: color,
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '18px',
      textTransform: 'uppercase',
      letterSpacing: '0.08em',
      borderBottom: 'none',
      boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
    },
    [`&.${tableCellClasses.root}`]: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: PaddingLeft,
      paddingRight: '24px',
    },
  }));

  const handleCheckBox = () => {
    if (forUserTable !== undefined && forUserTable === true) {
      if (handleUnselectAll !== undefined) {
        if (isAllUserSeleted) {
          handleUnselectAll();
        } else {
          handleSelectAll();
        }
        return;
      }
      if (isAllUserSeleted) {
        dispatch(cancelSelectAllUser());
      } else {
        dispatch(selectAllUser());
      }
    } else {
      if (handleUnselectAll !== undefined) {
        if (isAllSeleted) {
          handleUnselectAll();
        } else {
          handleSelectAll();
        }
        return;
      }
      if (isAllSeleted) {
        dispatch(cancelSelectAll());
      } else {
        dispatch(selectAll());
      }
    }
  };

  return (
    <TableHead>
      <TableRow
        sx={{
          height: '42px',
        }}
      >
        {hasCheckbox ? (
          <NewStyledTableCell
            style={{
              position: props.hasCheckboxStick ? 'sticky' : '',
              left: props.hasCheckboxStick ? 0 : '',
              zIndex: 6,
              paddingLeft: '15px',
              paddingRight: '15px',
            }}
          >
            <SimpleCheckBox
              id='myTableHeaderSelectAllCheckBox'
              color={CheckboxColor ? CheckboxColor : 'primary'}
              checked={forUserTable ? isAllUserSeleted : isAllSeleted}
              indeterminate={
                ifIndeterminate && (selected > 0 || selected.length > 0) && !(forUserTable ? isAllUserSeleted : isAllSeleted)
              }
              onChange={handleCheckBox}
              sx={{
                width: '42px',
              }}
            />
          </NewStyledTableCell>
        ) : children}
        {hasDatePassedFlags ? (
          <NewStyledTableCell
            style={{
              position: props.hasCheckboxStick ? 'sticky' : '',
              left: props.hasCheckboxStick ? '66px' : '',
              zIndex: 6,
              width: '64px',
            }}
          >&nbsp;</NewStyledTableCell>
        ) : null}
        {items.map((item, index) => (
          <NewStyledTableCell
            key={item.id}
            align={item.align}
            sx={{
              maxWidth: item.maxWidth,
              minWidth: item.minWidth,
              display:
                showColumns && !showColumns[index] ? 'none' : 'table-cell',
              ...(item.sx ?? {}),
            }}
            sortDirection={orderBy === item.id ? order : false}
            style={{
              position: item.stick ? 'sticky' : '',
              left: item.stick ? item.stickPX : '',
              zIndex: item.stick ? 6 : '',
              ...(item.stick&&item.stickPX==='0px'&&{textAlign: 'center'}),
            }}
          >
            {!item.isOrder ? (
              //<Box id={`myTableHeaderItem${index}`}>{item.label}</Box>

              <Box
                id={`myTableHeaderItem${index}`}
                style={{
                  // whiteSpace: 'nowrap',
                  paddingTop: paddingVertical,
                  paddingBottom: paddingVertical,
                  textAlign: 'left',
                }}
              >
                {ChooseHeadFormatByLabel(item.label, index)}
              </Box>
            ) : (
              <TableSortLabel
                id={`myTableHeaderItem${index}`}
                active={orderBy === item.id}
                direction={orderBy === item.id ? order : 'asc'}
                onClick={item.isOrder ? createSortHandler(item.id) : null}
                // hideSortIcon
                style={{
                  //index===0? is the header "SI CODE", beacuse of the timePassedClock(defined in IssueTable) so it need more 24px
                  // paddingLeft:
                  //   item.stick && !hasCheckbox
                  //     ? index === 0
                  //       ? '64px'
                  //       : '40px'
                  //     : index === 0
                  //       ? Header0PaddingLeft
                  //       : '0px',
                  textAlign: 'left',
                  whiteSpace: 'pre-line',
                }}
              >
                {ChooseHeadFormatByLabel(item.label, index)}
                {orderBy === item.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </NewStyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

/**
 * 列表尾部分页组件
 * @param {import("./index").TableFoooterProps} props
 * @returns
 */
export function TableFoooter(props) {
  const { Page, perPageNumber, count, handlePerPageChange, handlePageChange } =
    props;

  return (
    <div className='TableFooter'>
      <div className='LeftFooter'>
        <div className='showItemId'>
          {count !== '0' ? (Page - 1) * perPageNumber + 1 : 0}-
          {perPageNumber * Page > count ? count : perPageNumber * Page} of{' '}
          {count} · Display
        </div>
        <div className='selectButton'>
          <FormControl sx={{ minWidth: 63, bottom: '6px' }}>
            <Select
              id='selectDisplay'
              value={perPageNumber}
              onChange={handlePerPageChange}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className='showItemId'>rows</div>
      </div>
      <div className='RightFooter'>
        <Pagination
          count={Math.ceil(count / perPageNumber)}
          shape='rounded'
          color='lightBlue'
          onChange={handlePageChange}
          page={Page}
        />
      </div>
    </div>
  );
}

/**
 *
 * @param {import("./index").TableFooter2Props} props
 * @returns
 */
export function TableFooter2(props) {
  const {
    numSelected,
    Page,
    perPageNumber,
    perPageList,
    count,
    handlePerPageChange,
    handlePageChange,
    width = '100%',
    backgroundColor = '#f1f3f5',
    showSelected = false,
    sx,
    ...others
  } = props;

  return (
    <Box
      display='flex'
      sx={{
        background: backgroundColor,
        pl: 0,
        pr: 0,
        width: width,
        borderRadius: '0px 0px 8px 8px',
        border: '1px solid #DFE4E8',
        borderTop: 'none',
        ...sx,
      }}
      paddingY='3px'
      {...others}
    >
      <Stack
        // container
        direction='row'
        spacing={2}
        sx={{ width: '100%' }}
        justifyContent='center'
        alignItems='center'
      >
        <Grid item md={3} alignItems='center' justifyContent='center'>
          {/* <span id="tableFooterSelectedText" style={{ fontSize: "small", textAlign: 'center' }}> */}
          <Stack
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            spacing={1}
          >
            {showSelected? (
              <div>
                <span
                  style={{
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: 'small',
                    lineHeight: '18px',
                    color: '#262E35',
                  }}
                >
                  <FormattedMessage
                    id='table.selectedText'
                    values={{ num: 1 }}
                  />
                </span>


                <span
                  style={{
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: 800,
                    fontSize: 'small',
                    lineHeight: '18px',
                    color: '#262E35',
                  }}
                >
                  {' '+numSelected}
                </span>
              </div>
            ) : <Box> </Box>
            }
          </Stack>
          {/* </span> */}
        </Grid>

        <Grid item container md={6} justifyContent='center' alignItems='center'>
          <GlobalPagination
            id='tableFooterPagination'
            count={Math.ceil(count / perPageNumber)}
            handlePageChange={handlePageChange}
            page={Page}
          />
          {/*<Pagination
            id='tableFooterPagination'
            count={Math.ceil(count / perPageNumber)}
            shape='rounded'
            onChange={handlePageChange}
            page={Page}
            variant='text'
            renderItem={item => (
              <PaginationItem
                {...item}
                id={`tableFooterPage${item.page}`}
                selected={false}
                sx={{
                  backgroundColor:
                    item.selected && item.type === 'page'
                      ? '#113D95'
                      : 'transparent',
                  color:
                    item.selected && item.type === 'page'
                      ? '#F4F5F7'
                      : '#596A7C',
                  fontWeight: item.type !== 'page' ? 'bold' : 'regular',
                  '&:hover': {
                    color: '#596A7C',
                  },
                }}
              />
            )}
              />*/}
        </Grid>
        <Grid item md={3} alignItems='center' justifyContent='center'>

          {
            perPageList ? (
              <Stack
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                spacing={1}
              >
                <span
                  id='tableFooterRowsPerPageText'
                  style={{ fontSize: 'small', fontWeight: '600', color: '#262E35' }}
                >
                  <FormattedMessage id='table.rowsPerPage' />
                </span>
                <FormControl
                  id='tableFooterPageSelectFormControl'
                  sx={{ minWidth: 50 }}
                >
                  <Select
                    id='tableFooterRowsPerPageSelect'
                    sx={{ fontSize: 'small', fontWeight: '600', color: '#262E35' }}
                    value={perPageNumber}
                    onChange={handlePerPageChange}
                    input={<Input disableUnderline={true} />}
                  >
                    {perPageList.map(num => (
                      <MenuItem
                        id={`tableFooterRowsPerPagePageSize${num}`}
                        key={'pageSize-' + num}
                        value={num}
                      >
                        {num}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            )
              : <Box> </Box>
          }
        </Grid>
      </Stack>
    </Box>
  );
}

/**
 *
 * @param {import("./index").CustomPaginationProps} props
 * @returns
 */
export function CustomPagination(props) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const perPageList = [20, 50, 100];

  return (
    <Box sx={{ p: 1, background: '#f1f3f5' }}>
      <Grid
        container
        direction='row'
        justifyContent='center'
        spacing={2}
        alignItems='center'
      >
        <Grid item md={3}></Grid>
        <Grid item container md={6} justifyContent='center'>
          <Pagination
            count={pageCount}
            shape='rounded'
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
            page={page + 1}
            variant='text'
          />
        </Grid>
        <Grid
          item
          container
          md={3}
          alignItems='center'
          justifyContent='flex-end'
          spacing={2}
        >
          <Grid item container xs={8} justifyContent='flex-end'>
            <span style={{ fontSize: 'small' }}>Rows per page: </span>
          </Grid>
          <Grid item xs={4}>
            <FormControl>
              <Select
                sx={{ height: 32, fontSize: 'small' }}
                value={pageSize}
                onChange={(event, value) => apiRef.current.setPageSize(value)}
                variant='standard'
              >
                {perPageList.map(num => (
                  <MenuItem key={'pageSize-' + num} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    // <Pagination
    //   color="primary"
    //   count={pageCount}
    //   page={page + 1}
    //   onChange={(event, value) => apiRef.current.setPage(value - 1)}
    // />
  );
}

/**
 *
 * @param {import("./index").NewTableHeaderProps} props
 * @returns
 */
export function NewTableHeader(props) {
  //order:排序顺序，asc、desc、none
  //orderBy：根据某一字段排序
  //items：表头元素
  //hasCheckbox:表头是否有复选框
  //isAllCheck：是否全选
  //onSelectAllClick：全选后的逻辑处理
  //CheckboxColor：CheckBox的颜色，默认是MUI的Primary
  const { items, color = '#505050', backgroundColor = '#E3E8F2' } = props;

  // 改变标题行的颜色
  const NewStyledTableCell = styled(TableCell)(props => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: backgroundColor,
      color: color,
      // backgroundColor: "green",
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '18px',
      textTransform: 'uppercase',
      letterSpacing: '0.08em',
      borderBottom: 'none',
      boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
    },
    [`&.${tableCellClasses.root}`]: {
      height: '43px',
      padding: 0,
      paddingLeft: lastCell(props.index, props.length),
      paddingRight: props.index == props.length - 1 ? '24px' : 0,
    },
  }));

  const lastCell = (index, length) => {
    if (index == length - 1) {
      return '24px';
    } else {
      return '24px';
    }
  };

  return (
    <TableHead>
      <TableRow>
        {items.map((item, index) => (
          <NewStyledTableCell
            index={index}
            length={items.length}
            style={{ 'text-transform': 'capitalize' }}
            key={item.id}
            align={item.align ? item.align : 'left'}
            sx={{
              maxWidth: item.maxWidth,
              minWidth: item.minWidth,
            }}
          >
            <Box id={`myTableHeaderItem${index}`}>{item.label}</Box>
          </NewStyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

import { CardContent, Stack, Grid, ListItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import SaveAndCancelButton from '@/componentsphase2/SIDetail/SaveAndCancelButtonForGeneralInfoSection.jsx';

import PlusIcon from '@/assets/CMAssets/PlusIcon.svg';
import DeleteConfirmModal from './ChinaMarketing/DeleteConfirmModal';

import { CustomTooltip } from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/CustomTooltip';

import CustomMonthPicker from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/CustomDatePicker/CustomMonthPicker';
import CustomMonthRangePicker from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/CustomDatePicker/CustomMonthRangePicker';
import { UntitledAlertBox } from '@/components/Alert/AlertBox';
import {
  EditButton,
  DeleteButton,
  AddMoreButton,
  OnlyTextForCMPage,
  SingleAutocompleteForCMPage,
  activityTypeList,
  ContentTypography,
  TitleTypography,
  ErrorMessage,
  CustomTextFieldForCMDescription,
  TextAreaWithLimit,
  MultiContentTypography,
  ViewAllAndHideButton,
  CustomTypographyButton,
} from './ChinaMarketingBlockRelatedComponents';

import {
  addChinaMarketingActivity, deleteChinaMarketingActivity,
  getAllChinaMarketingActivity,
  updateChinaMarketingActivity
} from "@/actions/SIMT-SI/SpecialIssue/ChinaMarket";

const descriptionLimit = 10000;

const checkActivityError = item => {
  return (
    item.ActivityType === 'Others-' ||
    item.ActivityType === 'nonOthers-' ||
    costCheck(item.CostUSD) ||
    typeCheck(item.ActivityType) ||
    item.ActivityDescription.length > descriptionLimit
  );
};

const Label = props => {
  const { isMandatory = false, title } = props;

  return (
    <>
      <span> {title} </span>
      {isMandatory ? <span style={{ color: '#C40000' }}> * </span> : <></>}
    </>
  );
};

const CustomStack = props => {
  const height = props.height ?? '33px';

  return (
    <Stack
      width='224px'
      textAlign='left'
      marginRight={1}
      direction='row'
      spacing='10px'
      // center the content
      alignItems='center'
      sx={{
        color: '#596A7C',
        fontSize: '14px',
        fontWeight: '400',
      }}
      height={height}
    >
      {props.children}
    </Stack>
  );
};

const ListItemStyle = {
  fontWeight: 600,
  fontSize: '14px',
  alignItems: 'center',
  padding: '0px 0px',
};

const costCheck = cost => {

  if (cost === '') {
    return false;
  }
  const costNum = Number(cost);
  if (isNaN(costNum) || costNum < 0) {
    return true;
  }
  return false;
};

const typeCheck = CMType => {
  if (CMType.split('-')[0] !== 'Others') {
    return false;
  }
  const CMTypeWithoutPrefix = CMType.split('-').slice(1).join('-');
  if (CMTypeWithoutPrefix.length > 500) {
    return true;
  }
  return false;
};

// 编辑模式
const EditModeContent = props => {
  const { removeSelfFromEditing, handleChange, errorShow } = props;
  const {
    originActivityName,
    originActivityType,
    originDeliveryMonth,
    originCostUSD,
    originDescription,
  } = props;
  const typeEqualOthers = originActivityType.split('-')[0] === 'Others';
  // Others-xxx || nonOthers-xxx => xxx
  // get the string after first -, notice that in the string may exist -
  const originActivityTypeWithoutPrefix = originActivityType
    .split('-')
    .slice(1)
    .join('-');

  const flagType1 = originActivityType === 'nonOthers-';
  const flagType2 = originActivityType === 'Others-';
  // start with Others and length > 507
  const flagType3 = typeCheck(originActivityType);

  const [typeFocus, setTypeFocus] = useState(false);

  const [textFocus, setTextFocus] = useState(false);

  const [excessError, setExcessError] = useState(false);

  const [DatePickerMode, setDatePickerMode] = useState('Default');

  useEffect(() => {
    if (DatePickerMode !== 'Default') {
      return;
    }
    // originDeliveryMonth contains 'To'
    if (originDeliveryMonth?.includes('To')) {
      setDatePickerMode('Period');
    } else {
      setDatePickerMode('Month');
    }
  }, [originDeliveryMonth]);

  const flagCost = costCheck(originCostUSD);



  return (
    <CardContent
      sx={{
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <OnlyTextForCMPage
          width='518px'
          placeholder='Marketing Activity'
          value={originActivityName}
          setValue={value => {
            handleChange('ActivityTitle', value);
          }}
        />
        <div
          style={{
            width: '8px',
          }}
        />
        <DeleteButton
          onClick={() => {
            removeSelfFromEditing();
          }}
        />
      </Box>

      <Stack spacing={3}>
        <Box
          sx={{
            marginTop: '16px',
            width: '1092px',
          }}
        >
          <Grid container spacing={0} justifyContent='flex-end'>
            {/* CM 左半部分  */}
            <Grid item xs={6}>
              <Stack
                spacing='8px'
                sx={{
                  paddingRight: '28px',
                }}
              >
                <ListItem key={'Marketing Activity Type'} sx={ListItemStyle}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <CustomStack>
                      <Label
                        title='Marketing Activity Type'
                        isMandatory={true}
                      />
                    </CustomStack>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '286px',
                      }}
                    >
                      <SingleAutocompleteForCMPage
                        placeholder='Select a Marketing Activity Type'
                        value={
                          typeEqualOthers
                            ? {
                              label: 'Others',
                              value: 'Others',
                            }
                            : {
                              // Frontend ensure that the value is in the list
                              label: originActivityTypeWithoutPrefix,
                              value: originActivityTypeWithoutPrefix,
                            }
                        }
                        isEmpty={originActivityTypeWithoutPrefix === ''}
                        onChange={(event, newValue) => {
                          if (newValue.value === 'Others') {
                            handleChange('ActivityType', 'Others-' + '');
                          } else {
                            handleChange(
                              'ActivityType',
                              'nonOthers-' + newValue.value
                            );
                          }
                        }}
                        getOptionLabel={option => {
                          return option.label;
                        }}
                        options={activityTypeList}
                        isErr={!typeFocus && flagType1 && errorShow}
                        onFocus={() => {
                          setTypeFocus(true);
                        }}
                        onBlur={() => {
                          setTypeFocus(false);
                        }}
                        renderOptionPartlyInject={(props, option) => {
                          if (option.value === 'Wiley-led event') {
                            return (
                              <CustomTooltip
                                title={'workshop/symposium/reception'}
                                placement='top'
                                arrow
                                sx={{
                                  zIndex: 10000,
                                }}
                              >
                                <li {...props}>{option.label}</li>
                              </CustomTooltip>
                            );
                          }
                          return <li {...props}>{option.label}</li>;
                        }}
                      />
                      {!typeFocus && flagType1 && errorShow && (
                        <ErrorMessage
                          message={'This field is mandatory to input!'}
                        />
                      )}
                    </Box>
                  </Box>
                </ListItem>
                {typeEqualOthers && (
                  <ListItem key={'Marketing Activity Type'} sx={ListItemStyle}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <CustomStack>
                        <Label title='' />
                      </CustomStack>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '286px',
                        }}
                      >
                        <OnlyTextForCMPage
                          value={originActivityTypeWithoutPrefix}
                          setValue={value => {
                            handleChange('ActivityType', 'Others-' + value);
                          }}
                          needEndAdornment={true}
                          handleIconButtonClick={() => {
                            handleChange('ActivityType', 'Others-' + '');
                          }}
                          placeholder='Specify the activity type here'
                          width='286px'
                          height='40px'
                          errorMessage={
                            flagType2
                              ? 'Please enter this field.'
                              : 'Please limit your input to 500 characters'
                          }
                          onFocus={() => {
                            setTextFocus(true);
                          }}
                          onBlur={() => {
                            setTextFocus(false);
                          }}
                          isError={
                            (!typeFocus && flagType2 && !textFocus) ||
                            (flagType3  && !textFocus)
                          }
                        />
                      </Box>
                    </Box>
                  </ListItem>
                )}
                {/* <ListItem sx={ListItemStyle} key={'DateClearButton'}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      height: '18px',
                      width: '100%'
                    }}
                  >
                    Clear
                  </Box>
                </ListItem> */}
                <ListItem sx={ListItemStyle} key={'Delivery Month'}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-end',
                    }}
                  >
                    <CustomStack>
                      <Label title='Delivery Month' />
                    </CustomStack>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '286px',
                        height: '55px',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Box
                        sx={{
                          width: '90px',
                        }}
                      >
                        <SingleAutocompleteForCMPage
                          value={{
                            label: DatePickerMode,
                            value: DatePickerMode,
                          }}
                          onChange={(event, newValue) => {
                            setDatePickerMode(newValue.value);
                            // Reset the value
                            handleChange('ActivityDate', '');
                          }}
                          getOptionLabel={option => {
                            return option.label;
                          }}
                          options={[
                            { label: 'Month', value: 'Month' },
                            { label: 'Period', value: 'Period' },
                          ]}
                          isErr={false}
                          readOnly={true}
                          config={{
                            dropListProps: {
                              popperSX: {
                                width: '137px',
                              },
                              paperSX: {
                                width: '137px',
                              },
                            },
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          marginLeft: '8px',
                          width: '188px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Box
                          sx={{
                            marginBottom: '4px',
                            height: '18px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <CustomTypographyButton
                            label='Clear'
                            disabled={
                              originDeliveryMonth === null ||
                              originDeliveryMonth === ''
                            }
                            onClick={() => {
                              handleChange('ActivityDate', '');
                            }}
                          />
                        </Box>

                        {DatePickerMode === 'Period' ? (
                          <CustomMonthRangePicker
                            value={originDeliveryMonth}
                            placeholder={'yyyy-mm-dd:yyyy-mm-dd'}
                            onChange={f => {
                              handleChange('ActivityDate', f);
                            }}
                          />
                        ) : (
                          <CustomMonthPicker
                            value={originDeliveryMonth}
                            placeholder={'yyyy-mm'}
                            onChange={f => {
                              handleChange('ActivityDate', f);
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem sx={ListItemStyle} key={'Cost-USD'}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <CustomStack>
                      <Label title='Cost-USD' />
                    </CustomStack>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '286px',
                      }}
                    >
                      <OnlyTextForCMPage
                        placeholder='Enter Cost in USD'
                        width='286px'
                        value={originCostUSD}
                        setValue={value => handleChange('CostUSD', value)}
                        needEndAdornment={true}
                        handleIconButtonClick={() =>
                          handleChange('CostUSD', '')
                        }
                        errorMessage='Please enter numbers only.'
                        isError={flagCost && errorShow}
                      />
                    </Box>
                  </Box>
                </ListItem>
              </Stack>
            </Grid>
            {/* CM 信息右半部分  */}
            <Grid item xs={6}>
              <Stack
                spacing={1}
                sx={{
                  paddingLeft: '28px',
                }}
              >
                <ListItem sx={ListItemStyle} key={'Description'}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <CustomStack>
                      <Label title='Description' />
                    </CustomStack>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '286px',
                      }}
                    >
                      <TextAreaWithLimit
                        id='Description'
                        needEmptyCheck={false}
                        setValue={value => {
                          handleChange('ActivityDescription', value);
                        }}
                        value={originDescription}
                        limitLength={descriptionLimit}
                        excessError={excessError}
                        setExcessError={setExcessError}
                        placeholder='Placeholder'
                        width='287px'
                        rows={5}
                        CustomTextFieldInject={CustomTextFieldForCMDescription}
                        realTimeCheck={true}
                      />
                    </Box>
                  </Box>
                </ListItem>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </CardContent>
  );
};

// 非编辑模式
const ViewModeContent = props => {
  const {
    activityName,
    activityType,
    deliveryMonth,
    costUSD,
    description,
    mode = 'write',
  } = props;

  const typePrefix = activityType.split('-')[0] === 'Others' ? 'Others-' : '';
  const activityTypeWithoutPrefix = activityType.split('-').slice(1).join('-');

  const viewListItemStyle = {
    ...ListItemStyle,
    marginTop: '8px !important',
  };

  const [showAllDescription, setShowAllDescription] = useState(false);
  const contentRef = useRef(null);

  const [heightExcess, setHeightExcess] = useState(false);

  useEffect(() => {
    if (contentRef.current) {
      const { scrollHeight } = contentRef.current;
      if (scrollHeight > 87.5) {
        setHeightExcess(true);
      } else {
        setHeightExcess(false);
      }
    }
  }, [contentRef]);

  return (
    <CardContent
      sx={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingTop: '16px',
        '&:last-child': {
          paddingBottom: '0px',
        },
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TitleTypography>{activityName}</TitleTypography>
      </Box>
      <Stack spacing={3}>
        <Box
          sx={{
            marginTop: '8px',
            width: '1092px',
          }}
        >
          <Grid container spacing={0} justifyContent='flex-end'>
            {/* CM 左半部分  */}
            <Grid item xs={'8px'}>
              <Stack
                spacing={2.5}
                sx={{
                  paddingRight: '28px',
                }}
              >
                <ListItem
                  key={'Marketing Activity Type'}
                  sx={viewListItemStyle}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <CustomStack height='20px'>
                      <Label title='Marketing Activity Type' />
                    </CustomStack>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '286px',
                        justifyContent: 'center',
                      }}
                    >
                      <ContentTypography>
                        {typePrefix + activityTypeWithoutPrefix}
                      </ContentTypography>
                    </Box>
                  </Box>
                </ListItem>
                <ListItem sx={viewListItemStyle} key={'Delivery Month'}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <CustomStack height='20px'>
                      <Label title='Delivery Month' />
                    </CustomStack>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '286px',
                        justifyContent: 'center',
                      }}
                    >
                      <ContentTypography>
                        {deliveryMonth?.includes('To')
                          ? `From ${deliveryMonth.split('To')[0]} To ${
                            deliveryMonth.split('To')[1]
                          }`
                          : deliveryMonth}
                      </ContentTypography>
                    </Box>
                  </Box>
                </ListItem>
                {mode === 'write' && (
                  <ListItem sx={viewListItemStyle} key={'Cost-USD'}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <CustomStack height='20px'>
                        <Label title='Cost-USD' />
                      </CustomStack>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '286px',
                          justifyContent: 'center',
                        }}
                      >
                        <ContentTypography>{costUSD}</ContentTypography>
                      </Box>
                    </Box>
                  </ListItem>
                )}
              </Stack>
            </Grid>
            {/* CM 信息右半部分  */}
            <Grid item xs={6}>
              <Stack
                spacing={1}
                sx={{
                  paddingLeft: '28px',
                }}
              >
                <ListItem sx={viewListItemStyle} key={'Description'}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexDirection: 'row',
                      // transform: 'translateY(40px)',
                    }}
                  >
                    <CustomStack height='20px'>
                      <Label title='Description' />
                    </CustomStack>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div ref={contentRef}>
                        {showAllDescription ? (
                          <ContentTypography
                            style={{
                              lineHeight: '1.25',
                            }}
                          >
                            {description}
                          </ContentTypography>
                        ) : (
                          <MultiContentTypography>
                            {description}
                          </MultiContentTypography>
                        )}
                      </div>
                      <div
                        style={{
                          marginTop: '8px',
                          display: heightExcess ? 'block' : 'none',
                        }}
                      >
                        <ViewAllAndHideButton
                          isHidden={!showAllDescription}
                          setIsHidden={v => setShowAllDescription(!v)}
                        />
                      </div>
                    </Box>
                  </Box>
                </ListItem>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </CardContent>
  );
};

const ChinaMarketingBlock = props => {
  // 数据注入 OR 自动获取
  const {
    title = 'China Marketing',
    siCode,
    AddCMActivityFlag = false,
    setAddCMActivityButtonDisabled = () => {},
    setAddCMActivityFlag = () => {},
    mode = 'write',
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (AddCMActivityFlag) {
      handleAddCMActivityInEditing();
      setAddCMActivityFlag(false);
    }
  }, [AddCMActivityFlag]);

  const [CMActivities, setCMActivities] = useState([]);

  const [CMActivitiesInEditing, setCMActivitiesInEditing] = useState([]);

  const [editingIdCount, setEditingIdCount] = useState(0);

  const [buttonDisable,setButtonDisable] = useState(false);

  const hasContent = CMActivitiesInEditing.length + CMActivities.length > 0;

  useEffect(() => {
    if (hasContent) {
      setAddCMActivityButtonDisabled(true);
    } else {
      setAddCMActivityButtonDisabled(false);
    }
  }, [hasContent]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [afterDelete, setAfterDelete] = useState(false);
  const handleAddCMActivityInEditing = () => {
    setIsEditMode(!isEditMode);
    setCMActivitiesInEditing([
      ...CMActivitiesInEditing,
      {
        ActivityTitle: '',
        ActivityType: 'nonOthers-',
        ActivityDate: null,
        CostUSD: '',
        ActivityDescription: '',
        editingId: editingIdCount,
      },
    ]);
    setEditingIdCount(editingIdCount + 1);
  };

  const handleModifyCMActivityInEditing = EditingID => (key, value) => {
    const newCMActivitiesInEditing = CMActivitiesInEditing.map(item => {
      if (item.editingId === EditingID) {
        return {
          ...item,
          [key]: value,
        };
      }
      return item;
    });
    setCMActivitiesInEditing(newCMActivitiesInEditing);
  };

  const handleRemoveCMActivityInEditing = EditingID => {
    const newCMActivitiesInEditing = CMActivitiesInEditing.filter(
      item => item.editingId !== EditingID
    );
    setCMActivitiesInEditing(newCMActivitiesInEditing);
    setAfterDelete(true);
  };

  useEffect(()=>{
    console.info(CMActivities,CMActivitiesInEditing);
    if (afterDelete && CMActivitiesInEditing.length == 0 && CMActivities.filter(item=>item.modifyTag!='delete').length == 0){
      setAfterDelete(false);
      handleSubmit();
    }
  },[CMActivities,CMActivitiesInEditing]);

  // Local CMActivities Change

  useEffect(() => {
    dispatch(getAllChinaMarketingActivity(siCode));
  }, []);

  const selector = state => {
    return {
      remoteCMActivities: state.SI.CMActivities.content,
      CMFlag: state.SI.CMFlag,
    };
  };
  const { remoteCMActivities, CMFlag } = useSelector(selector);

  // get Newest CM Activities when edit operation returned
  useEffect(() => {
    if (CMFlag) {
      dispatch(getAllChinaMarketingActivity(siCode));
    }
  }, [CMFlag]);

  useEffect(() => {
    if (remoteCMActivities) {
      setCMActivities(
        remoteCMActivities.map(item => {
          return {
            ...item,
            modifyTag: 'noChange',
          };
        })
      );
    }
  }, [remoteCMActivities]);

  const handleModifyLocalCMActivity = activityId => (key, value) => {
    const newCMActivities = CMActivities.map(item => {
      if (item.ActivityId === activityId) {
        return {
          ...item,
          [key]: value,
          modifyTag: 'update',
        };
      }
      return item;
    });
    setCMActivities(newCMActivities);
  };

  const handleRemoveLocalCMActivity = activityId => {
    const newCMActivities = CMActivities.map(item => {
      if (item.ActivityId === activityId) {
        return {
          ...item,
          modifyTag: 'delete',
        };
      }
      return item;
    });
    setCMActivities(newCMActivities);
    setAfterDelete(true);
  };

  const [isFold, setIsFold] = useState(true);
  //const [isEditMode, setIsEditMode] = useState(false);  This code has been moved to line 806 above
  const [showError, setShowError] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const ErrorCheck=(button,setButton)=>{
    let flag = false;
    CMActivitiesInEditing.forEach(item => {
      flag = flag || checkActivityError(item);
    });
    CMActivities.forEach(item => {
      if (item.modifyTag === 'delete') {
        return;
      }
      flag = flag || checkActivityError(item);
    });
    setButtonDisable(flag);
    if(!flag) setIsClick(false);
    return flag;
  };

  useEffect(()=>{
    ErrorCheck();
  },[CMActivitiesInEditing,CMActivities]);

  const handleSubmit = () => {
    setShowError(true);
    // ErrorCheck
    let flag = ErrorCheck();

    // Check All Error
    if (flag) {
      return;
    }

    // For InEditing : Add
    CMActivitiesInEditing.forEach(item => {
      const data = {
        name:
          item.ActivityTitle === '' ? 'Marketing Activity' : item.ActivityTitle,
        type: item.ActivityType,
        cost: item.CostUSD,
        deliveryMonth: item.ActivityDate,
        description: item.ActivityDescription,
      };
      dispatch(addChinaMarketingActivity(siCode, data));
    });

    setCMActivitiesInEditing([]);

    // For Local: Check Tag and Update/delete
    CMActivities.forEach(item => {
      const { modifyTag, ActivityId } = item;
      const data = {
        name:
          item.ActivityTitle === '' ? 'Marketing Activity' : item.ActivityTitle,
        type: item.ActivityType,
        cost: item.CostUSD,
        deliveryMonth: item.ActivityDate,
        description: item.ActivityDescription,
      };
      if (modifyTag === 'update') {
        dispatch(updateChinaMarketingActivity(siCode, ActivityId, data));
      }
      if (modifyTag === 'delete') {
        dispatch(deleteChinaMarketingActivity(siCode,ActivityId));
      }
    });
    // Sync Local to Remote
    dispatch(getAllChinaMarketingActivity(siCode));
    //Close Edit Mode
    setIsEditMode(false);
    setShowError(false);
  };

  const handleCancel = () => {
    // Remove all editing content
    setCMActivitiesInEditing([]);
    // Reset Local to Remote
    setCMActivities(
      remoteCMActivities.map(item => {
        return {
          ...item,
          modifyTag: 'noChange',
        };
      })
    );
    // Close Edit Mode
    setIsEditMode(false);
    setShowError(false);
  };

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteParams, setDeleteParams] = useState({});

  const [deleteInNextLoopFlag, setDeleteInNextLoopFlag] = useState(false);

  useEffect(() => {
    handleSubmit();
  },[deleteInNextLoopFlag]);

  const handleDelete = () => {
    // if cause there are no CMActivityInEditing and all CMACTIVITY has delete tag, pop the modal
    // if (
    //   CMActivitiesInEditing.length +
    //     CMActivities.filter(item => item.modifyTag !== 'delete').length ===
    //   1
    // ) {
    //   setDeleteModalOpen(true);
    //   return;
    // }
    // deleteModalHandler(deleteParams);
    // Always show the modal
    setDeleteModalOpen(true);
  };
  const deleteModalHandler = ({
    EditingID = null,
    ActivityID = null,
    SICode = null,
  }) => {
    if (EditingID !== null) {
      handleRemoveCMActivityInEditing(EditingID);
      return;
    }
    if (ActivityID !== null && SICode !== null) {
      handleRemoveLocalCMActivity(ActivityID);
    }
  };

  const [EditWhileAdding, setEditWhileAdding] = useState(false);

  if (mode === 'read' && CMActivities.length === 0) {
    return <></>;
  }

  if (mode === 'read') {
    return (
      <Box>
        <Box sx={{ width: 1092 }}>
          <Stack
            sx={{
              marginTop: '32px',
              marginBottom: '16px',
              paddingBottom: '0px',
              cursor:'pointer',
            }}
            direction='row'
            alignItems='center'
            spacing={1}
          >
            <Box
              onClick={() => {
                setIsFold(!isFold);
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '0px',
                gap: '12px',
                width: '24px',
                height: '24px',
                background: '#dfe4e8',
                borderRadius: '4px',
                flex: 'none',
                order: '0',
                flexGrow: '0',
              }}
            >
              {!isFold ? <MarketChevronUp /> : <SIDetailArrowDown />}
            </Box>
            <Box id='titleBoxLeft' sx={{cursor:'default'}}>China Marketing</Box>
          </Stack>
          {!isFold && (
            <Box>
              {CMActivities.map((item, index) => {
                return (
                  <ViewModeContent
                    key={200 + index}
                    activityId={item.ActivityId}
                    activityName={item.ActivityTitle}
                    activityType={item.ActivityType}
                    deliveryMonth={item.ActivityDate}
                    costUSD={item.CostUSD}
                    description={item.ActivityDescription}
                    mode={mode}
                  />
                );
              })}
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ width: 1092 }}>
        <Stack
          sx={{ marginTop: '32px', paddingBottom: '0px' }}
          direction='row'
          alignItems='center'
          spacing={1}
        >
          <Box
            onClick={() => {
              if (hasContent) {
                setIsFold(!isFold);
              } else {
                handleAddCMActivityInEditing();
              }
            }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '0px',
              gap: '12px',
              width: '24px',
              height: '24px',
              background: '#dfe4e8',
              borderRadius: '4px',
              flex: 'none',
              order: '0',
              flexGrow: '0',
            }}
          >
            {!hasContent ? (
              <PlusIcon />
            ) : isFold ? (
              <MarketChevronUp />
            ) : (
              <SIDetailArrowDown />
            )}
          </Box>
          <Box id='titleBoxLeft'>China Marketing</Box>
          <EditButton
            display={isFold && hasContent&&!isEditMode}
            onClick={() => {
              if (CMActivitiesInEditing.length > 0) {
                setEditWhileAdding(true);
                return;
              }
              if (CMActivities.length > 0) {
                setIsEditMode(true);
              }
            }}
          />
        </Stack>
        {isFold && (
          <Box>
            {CMActivities.filter(item => item.modifyTag !== 'delete').map(
              (item, index) => {
                if (!isEditMode) {
                  return (
                    <ViewModeContent
                      key={200 + index}
                      activityId={item.ActivityId}
                      activityName={item.ActivityTitle}
                      activityType={item.ActivityType}
                      deliveryMonth={item.ActivityDate}
                      costUSD={item.CostUSD}
                      description={item.ActivityDescription}
                      mode={mode}
                    />
                  );
                } else {
                  return (
                    <EditModeContent
                      key={200 + index}
                      siCode={siCode}
                      activityId={item.ActivityId}
                      originActivityName={item.ActivityTitle}
                      originActivityType={item.ActivityType}
                      originDeliveryMonth={item.ActivityDate}
                      originCostUSD={item.CostUSD}
                      originDescription={item.ActivityDescription}
                      removeSelfFromEditing={() => {
                        setDeleteParams({
                          ActivityID: item.ActivityId,
                          SICode: siCode,
                        });
                        handleDelete();
                      }}
                      handleChange={handleModifyLocalCMActivity(
                        item.ActivityId
                      )}
                      handleDelete={handleRemoveLocalCMActivity}
                      errorShow={showError}
                    />
                  );
                }
              }
            )}
            {CMActivitiesInEditing.map((item, index) => (
              // 与数据库中内容区分开来
              <EditModeContent
                key={100 + index}
                editingId={item.editingId}
                removeSelfFromEditing={() => {
                  setDeleteParams({
                    EditingID: item.editingId,
                  });
                  handleDelete();
                }}
                originActivityName={item.ActivityTitle}
                originActivityType={item.ActivityType}
                originDeliveryMonth={item.ActivityDate}
                originCostUSD={item.CostUSD}
                originDescription={item.ActivityDescription}
                handleChange={handleModifyCMActivityInEditing(item.editingId)}
                handleDelete={handleRemoveCMActivityInEditing}
                errorShow={showError}
              />
            ))}
            {hasContent && (
              <div
                style={{
                  marginTop: '16px',
                }}
              >
                <AddMoreButton onClick={handleAddCMActivityInEditing} />
              </div>
            )}
            <Box
              style={{
                width: 'auto',
                height: '62px',
                background: '#FFFFFF',
                position: 'sticky',
                bottom: 0,
                margin: '12px 32px',
                marginRight: '0',
                zIndex: '10',
                display:
                  isEditMode || CMActivitiesInEditing.length > 0
                    ? 'block'
                    : 'none',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  height: '100%',
                }}
              >
                <Stack spacing={'10px'} direction='row'>
                  <SaveAndCancelButton
                    handleSubmit={()=>{
                      handleSubmit();
                      setIsClick(true);
                    }}
                    handleCancel={handleCancel}
                    isDisabled={buttonDisable&&isClick}
                  />
                </Stack>
              </div>
            </Box>
          </Box>
        )}
      </Box>
      <DeleteConfirmModal
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        handleDelete={() => {
          deleteModalHandler(deleteParams);
          setDeleteModalOpen(false);
          // Cause deleteModalHandler will use setState to change some value, it's change will be update in next loop,
          // submit here can not deal with newest data
          // handleSubmit();
          setDeleteInNextLoopFlag(true);
        }}
      />
      <UntitledAlertBox
        open={EditWhileAdding}
        autoHideDuration={3000}
        handleClose={() => setEditWhileAdding(false)}
        message='Please ensure that you complete the addition of the new Marketing Activity before making any further edits to the entire section.'
        severity='warning'
        style={{
          top: '20px',
          right: '20px',
        }}
      />
    </Box>
  );
};

export default ChinaMarketingBlock;

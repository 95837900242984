import { Stepper, Step, Box, Stack } from '@mui/material';
import React from 'react';

import {
  stepClasses,
  StepLabel,
  stepLabelClasses,
  ThemeProvider,
} from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import PropTypes from 'prop-types';
// @ts-ignore
import Completed from '@/assets/Completed2.svg';
import styleModule from './CustomComponent.module.scss';
/** @type {import('@mui/material').SxProps} */
export const stepLabel = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.08em',
  textTransform: 'capitalize',
  color: '#262E35',
  '& .MuiStepLabel-label': {
    color: '#4C81EB',
    fontWeight: '600',
    fontSize: '14px',
    letterSpacing: .7,
  },  //modified by HJX
  '& .MuiStepLabel-label.Mui-active': {
    color: '#0052CC',
    fontWeight: '600',
  },
  '& .MuiStepLabel-iconContainer': {
    fontWeight: 400,
    width: 32,
    minHeight: 32,
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 0,
    marginRight: '6px',
    '& div': {
      paddingLeft: '.75px',
    },
  },
  '& .MuiStepLabel-label.Mui-disabled': {
    color: '#596A7C',
  },
};

export const stepTheme = createTheme({
  palette: {
    text: {
      primary: '#4C81EB',
      secondary: '#262E35',
    },
    error: {
      main: 'rgb(238, 83, 80)',
    },
  },
});

const BootstrapConnector = styled(StepConnector)({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#DFE4E8',
    borderTopWidth: 2,
    borderRadius: 1,
  },
});

/**
 *
 * @param {import("./CustomStepper").BootstrapStepIconProps} props
 * @returns
 */
function BootstrapStepIcon (props) {
  const { active, completed, sx, error, icon, ...others } = props;
  const Icon1Completed = completed && icon == 1;
  const Icon2Active = active && icon == 2;  //modified by HJX
  return (
    <Stack
      data-selenium-id='SIPPage_TransformToSl-CustomComponent-Stepper-BootstrapIcon-Stack'
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        backgroundColor: '#BCC5CF',
        zIndex: 1,
        color: '#FFF',
        // width: 24,
        // height: 24,
        minWidth: '26.67px',
        minHeight: '26.67px',
        borderRadius: '50%',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16.3955px',
        lineHeight: '125%',
        backgroundColor: '#98A7B6', //modified by HJX
        ...(active && {
          backgroundColor: '#154AB6',
        }),
        ...(completed && {
          color: '#fff',
          backgroundColor: '#4C81EB',
        }),
        ...(error && {
          backgroundColor: 'rgb(238, 83, 80)',
        }),
        ...sx,
      }}
      {...Icon1Completed && ({ className: styleModule.completedIcon1 })}
      {...Icon2Active && ({ className: styleModule.activeIcon2 })}
      {...others}
    >
      {Icon1Completed ? (
        <Completed data-selenium-id='SIPPage_TransformToSl-CustomComponent-Stepper-BootstrapIcon-Completed'
        ></Completed>
      ) : (
        <>{icon}</>
      )}
    </Stack>
  );
}

const BootstrapStepLable = styled(StepLabel)({
  '& .MuiStepLabel-label': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.08em',
    textTransform: 'capitalize',
    color: '#262E35',
  },
});

/**
 * @param {import("./CustomStepper").TranformToSiStepProps} props
 * @returns
 */
export const TranformToSiStep = props => {
  const { activeStep, children, ...others } = props;
  return (
    <Step
      completed={activeStep}  //modified by HJX
      {...others}
      data-selenium-id='SIPPage_TransformToSl-CustomComponent-Stepper-Si-Step'
      className={styleModule.Stepper}
    >
      <ThemeProvider
        theme={stepTheme}
        data-selenium-id='SIPPage_TransformToSl-CustomComponent-Stepper-Si-ThemeProvider'
      >
        <BootstrapStepLable
          data-selenium-id='SIPPage_TransformToSl-CustomComponent-Stepper-Si-BootstrapStepLable'
          sx={stepLabel}
          StepIconComponent={BootstrapStepIcon}
        >
          {children}
        </BootstrapStepLable>
      </ThemeProvider>
    </Step>
  );
};

/**
 *
 * @param {import("./CustomStepper").TranformToSiStepperProps} props
 * @returns
 */
export const TranformToSiStepper = props => {
  const { children, activeStep } = props;
  return (
    <Stepper
      connector={
        <BootstrapConnector data-selenium-id='SIPPage_TransformToSl-CustomComponent-Stepper-BootstrapConnector'
          {...activeStep && ({ className: styleModule.BootstrapConnector })}  //modified by HJX
        />
      }
      {...props}
      data-selenium-id='SIPPage_TransformToSl-CustomComponent-Stepper-Stepper'
    >
      {children}
    </Stepper>
  );
};

// @ts-check
import React from 'react';
import {
  Table,
  TableContainer,
  TableRow,
  TableBody,
  Box,
  tableCellClasses,
  Paper,
  TableCell,
  Tooltip,
} from '@mui/material';
import { MyTableHeader, TableFooter2 } from '../../../../components/DataTable';
import { styled } from '@mui/system';
import { formatDatetimeString } from '../../../../utils/commonUtils';
import { useEffect } from 'react';
// @ts-ignore
import ProfileWebsite from '@/assets/ProfileWebsite.svg';
import { CustomTooltip } from '../../../../components/CustomTooltip';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E8F2',
    color: '#505050',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '0.01em',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '44px',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '24px',
  },
}));

/**
 *
 * @param {import("./ReadTable").ReadTableProps} props
 * @returns
 */
function ReadTable(props) {
  //从父组件获取的属性
  const { id, TableHeads, TableData } = props;

  return (
    <Box
      sx={{ width: '100%' }}
      data-selenium-id='SIP_DetailPage_Submodule-ReadTable-Box'
    >
      <TableContainer
        id={id}
        data-selenium-id={`SIP_DetailPage_Submodule-ReadTable-${id}`}
        sx={{
          borderRadius: '8px 8px 8px 8px',
          borderWidth: '1px 1px 0px 1px',
          borderStyle: 'solid',
          borderColor: '#DFE4E8',
        }}
        component={Paper}
      >
        <Table
          data-selenium-id='SIP_DetailPage_Submodule-Read-Table'
          stickyHeader
          aria-label='custom pagination table'
          sx={{
            minWidth: 500,
            letterSpacing: '0.01em',
            color: '#262E35',
          }}
        >
          <MyTableHeader
            data-selenium-id='SIP_DetailPage_Submodule-ReadTable-Header'
            items={TableHeads}
            backgroundColor='#F1F3F5'
            color='#596A7C'
            CheckboxColor='wileyBlue2'
          />
          <TableBody data-selenium-id='SIP_DetailPage_Submodule-ReadTable-Body'>
            {TableData.map((row, index) => (
              <TableRow
                key={index}
                data-selenium-id='SIP_DetailPage_Submodule-ReadTable-Row1'
              >
                {TableHeads.map((item, index) => (
                  <StyledTableCell
                    data-selenium-id='SIP_DetailPage_Submodule-ReadTable-StyledCell'
                    sx={{
                      minWidth: item.minWidth,
                      maxWidth: item.maxWidth,
                      wordWrap: 'break-word',
                      color: '#262E35',
                      paddingX: '24px',
                    }}
                    key={index}
                    align={item.align}
                  >
                    {(item.id === 'fullName' || item.id === 'emailAddress')?(
                      <CustomTooltip
                        title={item.id === 'fullName'?row.emailAddress:row.fullName}
                        placement='top'
                        data-selenium-id='SIP_DetailPage_Submodule-ReadTable-CustomTooltip'
                      >
                        <Box
                          sx={{ display: 'inline' }}
                          data-selenium-id='SIP_DetailPage_Submodule-ReadTable-ItemBox'
                        >
                          {row[item.id]}
                        </Box>
                      </CustomTooltip>
                    ) : (
                      <Box
                          sx={{ display: 'inherit',paddingLeft:item.id==='profileWebsite'?'34px':'' }}
                          data-selenium-id='SIP_DetailPage_Submodule-ReadTable-ItemBox'
                        >
                          {row[item.id]}
                      </Box>
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}

            {TableData.length <= 0 ? (
              <TableRow
                id='siDetailPageNoDataText'
                data-selenium-id='SIP_DetailPage_Submodule-ReadTable-NoDataText'
                style={{ height: '44px' }}
              >
                <TableCell
                  data-selenium-id='SIP_DetailPage_Submodule-ReadTable-NoDataCell'
                  colSpan={TableHeads.length}
                  sx={{ textAlign: 'center', fontSize: '20px',fontSize:'14px',lineHeight:'20px',color:'#98A7B6' }}
                >
                  Add GE Information
                </TableCell>
              </TableRow>
            ) : (
              <div></div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
export default ReadTable;

export const MONTH = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const ANNUAL_TRENDS = 'Annual Trends';
export const ANNUAL_TRENDS_IN_SUBJECT_GROUP_LEVEL =
  'Annual Trends in Subject Group Level';
export const ANNUAL_TRENDS_IN_BUSINESS_DIVISION_LEVEL =
  'Annual Trends in Business Division Level';

export const SI_ACQUISITION_ANNUAL_TRENDS = 'SI Acquisition Annual Trends';
export const ACTIVE_SI_ANNUAL_TRENDS = 'Active SI Annual Trends';

export const ARTICLE = 'Article';
export const FY_TRENDS_OF_SI_ACQUISITION = 'FY Trends of SI Acquisition';
export const FY_TRENDS_OF_ACTIVE_SI = 'FY Trends of Active SI';
export const SUBJECT_GROUP = 'Subject Group';
export const BUSINESS_DIVISION = 'Business Division';

export const SUBMISSION = 'Submission';
export const ACCEPTANCE = 'Acceptance';
export const PUBLICATION = 'Publication';

export const All_IN_ONE = 'All in One';
export const SI_ARTICLES_PERFORMANCE_ANNUAL_TRENDS =
  'SI Articles Performance Annual Trends';

export function getCurrentTime() {
  // format: _MMDDYYYY_HHMMSS
  let yy = new Date().getFullYear();
  let mm =
    new Date().getMonth() + 1 < 10
      ? '0' + new Date().getMonth()
      : new Date().getMonth();
  let dd =
    new Date().getDate() < 10
      ? '0' + new Date().getDate()
      : new Date().getDate();
  let hh =
    new Date().getHours() < 10
      ? '0' + new Date().getHours()
      : new Date().getHours();
  let mf =
    new Date().getMinutes() < 10
      ? '0' + new Date().getMinutes()
      : new Date().getMinutes();
  let ss =
    new Date().getSeconds() < 10
      ? '0' + new Date().getSeconds()
      : new Date().getSeconds();
  return '_' + mm + dd + yy + '_' + hh + mf + ss;
}

export const BAR_COLORS = {
  subjectGroup: {
    submission: {
      oa: '#FFDDDD',
      oo: '#FFF0DD',
      borderOA: '#FF9898',
      borderOO: '#FFCA7B',
      lineOA: '#FF8180',
      lineOO: '#FFB152',
      lineIconOA:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAOCAYAAADwikbvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFYSURBVHgBpVMhT8NQEL67CpItBIIYFkaC4wcwi8dNgCJhZmqzBNE2CCxYUBgQM5AQHMmCx1BPEAiCAELSoPaOr++9rS1sQ3DN5bXv7vve169Xph+hUbSEpUVCi1grdtPoJwXyQgM94yh6GvZyCXgQd0h1ldTckUhaZqUasoG8BsFlCQxgCOA7dh5pWhjaBsEJCPqcS9V9CvjKn1IHbwtkax5yC9Jz5KuvN0HSEV9sl4BKxwVgFhvYO7TSsxC+QX3HgVlX8nfj7gTBGXDT9WgKgrpA8jzQb46Eq6gs06RQWi88VYT+EQLXPmDEzEgOcTKlPymo+HInG33O64OLsTCF05nj7r6GIbr3srkH650ZIgmadnMFnNoThfYKn6qB/n4+JGHYhWGGgj+GRGkLwF42ZSPDOI6PsCzY4rgwporZbg6BFvOL2P0YbeQcpM46IC7mBxh6ag328Q08YYHv7zYA4wAAAABJRU5ErkJggg==',
      lineIconOO:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFnSURBVHgBhVKxSgNBEH2zly4IVnZCCFiKoPkC0wWLxGCthbYmhTZ+g0UQu1joB2gURSvxCwyCnUXQ1koC12V3fLfLnRf0zBTL7sy+mbdvnyAXOmjOw8geIKsQuJCUMVcLZ4+ldf2e3pUMdNfehtV1aHQPozGmOuoClxpbvUnrqpcB9bbdYbECmEf8G65ORi+ycXkhpFdBZA4Jegg1W4WYXbar8lRm71dE6MHJZ5jutuBcR/SmfcrjEMKCISWrJwEwFTEZHcFEI94rQyeLhomSB8FL0P0DBJ/zLPx7Y06vGRjM5VRYRmF46iEMrPlFqTimmJAqcmAZFeMoUjZcxxyqQ3IO3dSdFUxNcr0wigKqIVXRPsQ2QpKqQfZz3WO/TxRNBXS6BqWL/KBBcwdRaYXbGQZAHdZ9JO75sdxgs0sjLJHKc/bZ2fP4FDdpMP9E0LlP5eveRSY68H+bfpNw5xIdtE+Tf6V3vwH1XY6ABP9SgwAAAABJRU5ErkJggg==',
      legendIconOA:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAOCAYAAAChHnWMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGWSURBVHgBxVWxSgNBEH2zly4IVqmjlU0gnZ0I+QDFQlAUBANiZydY6NkIdrZBBBGJYCFBsQwINpaBVFYGy1Q2drld3+2dQYnROVHyYPZ2797NvJvZnRP8Ai4MZ2GwymkutV5q97IbniIjJAuZwcd5qUFsC8a0BwjWluCkTLfbEoYdKKEW4QWIu+KsRgGvwz1KHtYtwmKdQl6ggIEWYjcZ4LwvwGGJgU5oN/4ar/19Fz+/pVW1rlUimIUiJJhm3rrJDaky6DJnhZRS8GuLrUQweQYTafn+RgRRZpCmn/m6u7khvAqslBJe9MhxHgoI1YZUvodRwWFfvyf+EarTwWytcVOOwbgnXw6Yg29c7pDXRhRNAsEzT0jjJ//aTDRYuKnkDd8fml/Hd9degOcFFY4tKKAS4c97z3ZYv0L61hHHC1o3pXT9WuTYr2Kekwdtw8pBC2PiAOwL9tL3CoM61/UBnrV5lmGFJ2gBSmRt20UGP+RXtvpp/6yghMiwbWND2y0zi/ggJt6oM9wDAd5/YA4R7YzB75ARbyHbjSCaFnpdAAAAAElFTkSuQmCC',
      legendIconOO:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAOCAYAAAChHnWMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGXSURBVHgBxVWxSgNBEH27l04EK2u1shHS2YngB5ikEBSFgAGxsxNs1Eaws5UQEJEIFklQLAXBxjJgZWWwtLKxu9vx3W6IgaiZE8VX3O7cDjPvZufNGfwA0izMIxetIZEcLHJwiBGZGLHcmWLjBBlhsjgz+RhsdMxdG8Y+DDq4GT7ycLJtiq2OMqyeRCBgGxCSsPL2dUQzQjJLSJJ1EnmFAhZaWLvJDGcfBGQZztWY8Mqvqe1f81xwzeupqENrnFiFCRKY5Ve+dN9UmGyFdRz3ZrqmtpOtYKd+ZtJX77dIAFEeRm5Cft67yOKnbkYWQl9445HhC1DASLO0Ryq7+C847Ot74g+hUgfvtozIjrK8T91yH3zjvhPkK1NUyDMV0hoWX1uJNNC036UJpNsfg7jszQ9hfwBtKKAi4fUeS4fyDGqw0RGznFOKQS1+pW1sNZx7v3vtwMpBCyNVNlENzlz4WWGiOi+zHs76/TisYrfKayspI2ce2xMcQocQM2xsb2inZWYSfWTKJDHHbdT7gQEJSZwy+S0y4h0yRaMeTEnAogAAAABJRU5ErkJggg==',
    },
    acceptance: {
      oa: '#E8E9F9',
      oo: '#F0F9FF',
      borderOA: '#A9AFE8',
      borderOO: '#92D4FF',
      lineOA: '#9098E2',
      lineOO: '#71C8FF',
      lineIconOA:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGFSURBVHgBhVI9S0NBENzdi4gEwcpK/AhYiqD+Au3srVWIiI0xoI0/wCqQxEZDBPVPaCX+ggQhnUVi0MpKAkHE3I77jrxHIhK3ece7mbnd2WEaqOJ1awqfvCcutUIEDT/BHRA8oVfIHyy8xFiOD+XK2zag6865Ow90BwWFeNp7v0aC5/z+XCkhli9fc2DME8sDjSrVDWZ5yu3P3HLxojUvPHYCoftw6X2GRLIAMqaaJuaGMEpK8h46V92icZ8TEXfMwrW4JTCfEbAUSAGJJVU6tzEzoUUbRb5dVqBIKSGoeehRQhiutJmU7Qt1VbEm1tZk4pSpj5gwk+BYvPy67I4gDnUiTEjIIGqOIDaSE9AReK3b2+l+q1d/vWqCXTMx7C8y0Hzwgi9Uyfc2A8K5pgEO0VePCNGZwaexgfZdVfhCCIDtcofFLf8bAOiGxa8dpSeJXLHSPhK4RRatxcuOy9koFrlNgB7zB7M3YaxBQJQiktQxk6ZsmrAmZojvaZ0nUM3vLnzE2B+wQq8jPv2XMwAAAABJRU5ErkJggg==',
      lineIconOO:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFmSURBVHgBjVKxSgNBEH2zCYgEwSqdEAKWImpAbbWzt9ZCW3MRbfINAQ+xi4X+hFZiaRSMQjqLoG0qCRwimB3fbe6OVeLhwB2zu+/Nvp15Ai+CJ52VD+zDYBkCm2wPRTGyila4Lq8pVtKkca87qtgwBldQRH5BFirbL9SIfgnXJMyIjY7WmVS4ukFeKDZV8HyyKpcS3GlFCjimnGt3aFHl4R6LVAkscafHoiHXA3es2MYU6nL4oGfc7JIwILBM0mlC8CNi4SYl94ktWYs5w1/RkZwSBBNIcZScijEo4lczIpjxAAv4O6ppIgYj81tSDvGHEsNrfXI/h9jz8mH8xm78YCdBcT7xVmFzCnDzcw2kIYx8ok3yVnJ/n4CDrLq4Ij32oZk10GCF5JYzAGe5awSL/zEAffgWuyezXNDRgN2aF4vHdNhZWM5OqEpxS79ejMV4EbuIO0dMi9mY4uaxDzqNdrgk7yn2Gz7ggPSo+Ty6AAAAAElFTkSuQmCC',
      legendIconOA:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAOCAYAAAChHnWMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHLSURBVHgBxVU9SyRBEH1VPafBcXDRhsfNRZcIm112HPgDTgwERUFRkDVyIsFETQSjNVtEFkVEwUBEMRQEE8MFI6P1IzMyEVyc7rJ6hgUVxR5RfDBM1XRN9ZuqrjeEN6Bau/wHxhCJRCCKIJIKUQonR0nlxyoKgooEV1ea39EySwA3iHHydF0cugBXhtippBKfBaYNJ+EJUIu3jYmWrMjNyxnpK5zrk450NBmJrxEARihaXyrM0XqbgLaiX5zUdcM9cbbu/SxO15l4n+/MWGjqIBLVWvMns/xxkKuMAGRMRAb0Xsp9Knkfzk5638eJ4zhr33uRgJiyhh5kprNdWoH/zwdSt1/PTXuKW9ODAFC1dj5LRDP4JGgF58LPxAciaDr0TAyz6fgmkKYvt56B+ZdiBTydja/YX2LdRTIR77yWP6wSt9hRIfqdsWaj+kAHz8YRdtv6IUC3+o2Q9EEkkiS+FpeeMVwpf4sWdZNN7Wc2Lf7ufWW4nCelkrI4DhWsCIGQliw7pjoMbXktIOYNfeyvRz01KlbWpoPodL2BqQvKtuqFfu2Cmo28LU+IZuNJZXTa8VC1LEziAZlhIvNXTdP+gWkmqyTWtAWHKIh7HJfLC82FcLkAAAAASUVORK5CYII=',
      legendIconOO:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAOCAYAAAChHnWMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGsSURBVHgBxVWxSsNQFD03jaiI4JRZnVwKOokOovgBioOgKAgWxK2b4KIuglvWUgoi0oKDFMWxIDjoptDJyeLYqUuhhfqeJ2kKQmu9EcQDL3mPnNx7ct99J4JfIP1oFx0H28bCFYFrLVqOoGUMHvw5OUdMSBxy+tmOSQMZJn7hm+UugkWSgqYtcEAxFWVYvYhIwLUkkGGyep+IIxSxbgew689IDQo4UMJpYl9cXHYEMNEGvzrHcRveuUb7QZ2VukMTKXVsDYk9MG4FszCoRgJSvGxy6kUUL1hbg3S4Io8lngiqp4mvqwT3mcRSNE9yrPTkCZbD58HU4hUNrEIB4Vces3xH+CdwK0/UPfGXUJ0OVmsn4WCUvfAWHkPgtE/Aw/D4GkzSR979eSn+FF9XiQaKRjAVZSlzlHrRKO6m4x8m6I/ATxRQifCXpGZaqDCL19YBn6PAaTWiBKehQNfMRmo8Bn7SGpYLJaSJrB1Gjk18FXqFIM/E+S6ioVkJtuwg1tSxEQOBX/CFs+9smz2QlMC2h7CndcvYIr6I2aGQBfZAovMDY6AP3i+4BfeIiU93oZxNK8XRNgAAAABJRU5ErkJggg==',
    },
    publication: {
      oa: '#E7F6F8',
      oo: '#CEDDF9',
      borderOA: '#73CDD7',
      borderOO: '#6D98EE',
      lineOA: '#45BCCA',
      lineOO: '#4C81EB',
      lineIconOA:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF+SURBVHgBhVK/LwRREJ5vdm8bJCqdRCRKEVyETnCCTqGmoEVB429QXERHwV+gIzjnuhM/juQ6xYVWJRfX3O2b8dbatSfufM2+2ZnvezNvPlACkyeP3V5nfY0YIzaU4J8SqqRqGg2zW5ifeIlqER1mc/fLomaK4Z7asJYUJEUPUSMN0ufzzHg2JmZytxtQ7SO4V9QOKtMEPF3MpI8xeVbsc1OpbSacfWf7lXQ1+Nqgw2qXoU6WoG9h2l+qu94GMvm7fRin9JWwLSn8vZDQhJptbYfIqQQ5gellEXEjNYXZ/IMUoMM+0mokQiJpdoCuxBCD1HrA/ujEzIZ/t9Sa2NwJqyJBRqUNsRxXCaqsRkqRmt3TYYtba1A3G3aMHoEa9j3vQFQWwrxTsQXrCfVApAzinXgdjow61kWhAS6KK4A7ROB/DSBkXnPWPbHlZi5vNq1zBlj5IVb/gR3FX/AF1/nZsaNwrATmrItMytkSUjdak90fq0jJ//AOCovD71HtJ85dnCNfwgr4AAAAAElFTkSuQmCC',
      lineIconOO:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGUSURBVHgBfZK7SiRREIbrr+7ZYGeFDXaH3cwVNhwWVh9ANJERzIw1GFM10EQEWxQjg8FUA30FxUsi+gKKYGbgJRK8gIhj4pz6Pd09PRdwLDhwOFX/V3WqCtJi/dHV92/4OkHqf6hY8mjyLICr8W31IPp9ncUiu4wsP4yZ2UDAYM/Uqq1AUAvGWh+NF7uLvyoN4cjS4xTpuj35UD4zJ4MIcbY9/2MLQ9Ftd05zsx67H/tI9ohomcIeUPKefa6wCoG7un/0xXJTGiKYgeAkLYkFCla8t5iKktCiEWt0MdCbyl6X1MrqCSFhCc1Rp5uCNssLtJzCUfXV9ClUu5qdYrHzB+sZk6xw2uaDVDsL2yvR+kmZxGVnHc+bIjyr1OyUpgkNsI2PslKkqgjS+fkGGsVpqF/Wha6UCnGpopOs02NBfAc4lzWQor3xFiULUIruxgPBPwnk8wWgDJqTm93Fn5XGyg0v3E9rgL8QO8mG3fiTMe8oJR98tBMVNpPqWgPiLQolmPHlhS1j8jo7feXr+nH05ymLfQdA8LHeNUCL4wAAAABJRU5ErkJggg==',
      legendIconOA:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAOCAYAAAChHnWMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHbSURBVHgBxVU9SBxBFP7ezO4RMAdWVyep0gQuTcQmXNDLT5WfIpCQgBAhpEsXSJOkCaRLK3IQgihYqCiieB4eWJxioWBlpVhaCWpzuzPPt7enIP69FcQPluHbebz3MW/eN4QroLe6UjKEj54RGMOB9xQbi5hjXqw+ffQPGUFZgkvjq53h7WiACGuAXT8d4R4woRg13bf6i+4tZVq9iERAkG+OGQQDQg8uCO1g8m8jE3yqP3m4CwUMlMjloy+Gw6FjAcTvmF2F4aaY40rC26EH5O104KN+bW6ViNJM445U7ZJCO+kf7mf27+UcC6kgKiRcRH1tC9wxjLulhdVOTX6VCBuaohSqpUz6Dv/yzEBCT7LfTr1hougVFKDe6vJPIvqBGwIz/1LfieuEajrKtUYfIcyDaTNtB35fkPB7Or58D85tzz3rmrgsv+okInNrQozpfsqkAKF2ZiBj8sg/5KL2GO/XoIBKRGvenduSk2hNA7H9K1VG5EunJVmFE9nBlFNBDG1pVmlYAZSIc7nB0McVYjOKxAsoGJZ1uLV5sqkd0rIPzb3wDZTIZNvPxS9czv4hPt+2pW3FeD/8XH+tc8vMIo5Qnmv0MdnHBmQhDxjkAfNgJyL+z5e768iIQwfoutzNnx+wAAAAAElFTkSuQmCC',
      legendIconOO:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAOCAYAAAChHnWMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHWSURBVHgBxVU9aBRBFP6+mU0K7w4i6IGdsbIRrrOTgzQiAcVCUBQEA2JnJ1jo2Ah2tiEERCSChRz+N4EDG8uAlVWCjXgWCvGq25nnm12TQnL4VpB8y8DMvG/ffDsz71viH7AYvvXF8QqRCoDapAR8mRDfv7nTfYSGYBNyP2zOtdBeJmWDjh//jEuSExT2JihvvQtHtqx5zSKygDZbzz38cnJpPI0nlBaTu7Cd/LVhOPjDkBoORrTZueHon+wKEF6UhFVtL3UHVvM4T+tOjAm+7qBcsuY2iTgdvhwlcVKYRr8FLInIJe11awa7eayCbtZh5TmZ74fvc5b8JhEFZnoQrFcL6LnrgmenUBdyvOalTwcwOQcDuBi+BtDdxX5B0j3znfifMFXHmTC66uk6es6b9Xbz/nS23M7lKzEeiyg+vw2HBn/Lb9qJWcwMEtPxSnXtD+t7EokXO/6hZrYgmGzAAJOIgdY7S9miSLcWgoeC9FS7dbVARnmsFbRSxZVH8R+shlXAiMLNrkRE9QU+o3qFc25Np9fqKKun+qokrQhe/inb5625G9l29gvv/AMK9rZtaPlG9MYYXx+GeZNbNhaxg3xRHXFK3/a7PzBBVJd6/CocHqIhfgEqo8DYBqUHKwAAAABJRU5ErkJggg==',
    },
  },
  businessDivision: {
    submission: {
      oa: '#FF9898',
      oo: '#FFCA7B',
    },
    acceptance: {
      oa: '#92D4FF',
      oo: '#A9AFE8',
    },
    publication: {
      oa: '#73CDD7',
      oo: '#6D98EE',
    },
  },
};

export const EXCEL_HEADER = {
  [SUBMISSION]: {
    exportHeader: [
      '@{sub}FY[LTY]TD',
      '@{sub}FY[LY]TD',
      '@{sub}FY[NOW]TD',
      '@{sub}FY[LY]/FY[LTY] TD (%)',
      '@{sub}FY[NOW]/FY[LY] TD (%)',
    ],
    partHeader: ['Submission-All Journals'],
  },
  [ACCEPTANCE]: {
    exportHeader: [
      '@{acc}FY[LTY]TD',
      '@{acc}FY[LY]TD',
      '@{acc}FY[NOW]TD',
      '@{acc}FY[LY]/FY[LTY] TD (%)',
      '@{acc}FY[NOW]/FY[LY] TD (%)',
    ],
    partHeader: ['Acceptance-All Journals'],
  },
  [PUBLICATION]: {
    exportHeader: [
      '@{pub}FY[LTY]TD',
      '@{pub}FY[LY]TD',
      '@{pub}FY[NOW]TD',
      '@{pub}FY[LY]/FY[LTY] TD (%)',
      '@{pub}FY[NOW]/FY[LY] TD (%)',
    ],
    partHeader: ['Publication-All Journals'],
  },
};
export function toLowerCaseFirstLetter(str) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}


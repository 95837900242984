import { Box, Collapse, IconButton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import SingleKV from '../SIPDetailPage/Submodule/SingleKV';
import CustomRadio from '../SIPDetailPage/Submodule/CustomRadio';

import SIPReviseRadioSelectedIcon from '@/assets/SIPReviseRadioSelectedIcon.svg';

import SIPReviseRadioUnselectedIcon from '@/assets/SIPReviseRadioUnselectedIcon.svg';

import GEInfoAdd from '@/assets/GEInfoAdd.svg';
import NumberRadio from '@/components/NumberRadio';
import { useSelector } from 'react-redux';
import { SectionContainer } from '../RevisePage/CustomComponents/CustomContainer';
import { ItemBlock } from './submodule/ItemBlock';

export const ContentCommissioningApproachesEditSection = props => {
  const {
    value,
    setValue: setCommissioningApproaches,
    showErrorMessage = false,
    error = false,
    setError = () => {},
  } = props;

  useEffect(() => {
    if (value === '0' || value === '1') {
      setError(false);
    } else {
      setError(true);
    }
  }, [value]);

  return (
    <SectionContainer
      title='Content Commissioning Approaches'
      data-selenium-id='SIP_AcceptPage_ContentCommissioningApproachesEditSection-Container'
    >
      <ItemBlock
        title='Open Call for Papers:'
        mandatory={true}
        sx={{ marginBottom: '16px', maxWidth: '495px' }}
        error={showErrorMessage && error}
      >
        <CustomRadio
          data-selenium-id='SIP_AcceptPage_ContentCommissioningApproachesEditSection-CustomRadio'
          value={Number(value !== null ? value : -1)}
          setValue={newValue =>
            setCommissioningApproaches(current => ({
              ...current,
              openCallForPapers: `${newValue}`,
            }))
          }
          selectedIcon={
            <SIPReviseRadioSelectedIcon data-selenium-id='SIP_AcceptPage_ContentCommissioningApproachesEditSection-SelectedIcon' />
          }
          unSelectedIcon={
            <SIPReviseRadioUnselectedIcon data-selenium-id='SIP_AcceptPage_ContentCommissioningApproachesEditSection-UnSelectedIcon' />
          }
          labelStyle={{
            fontWeight: 400,
            marginLeft: '16px',
          }}
        />
      </ItemBlock>
    </SectionContainer>
  );
};

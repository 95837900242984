import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '../../../../components/DataTable';
import {
  NoBorderTableRow,
  PurpleGreenCell, PurpleGreenDataCell, PurpleGreenDisCell, ReportNoData, StyledTableCellNormal, TableBox2
} from "../../ReportSpecificComponents/SpecialColorTableHeader";
import { findLongestLineSubstring, measureWidth } from "../../../../utils/StringWidthMeasure";
import React from "react";
import {getTargetsSG} from "@/actions/SIMT-Report/Report/Target";

/**
 * @author LiuXin
 * @date 2022/5/11
 * @param {*} props
 * @returns 返回Subject Group table
 */
function TargetSubjectGroupTable(props) {
  const { fiscalYear } = props;
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const selector = state => {
    return {
      targetSubjectGroupInfo: state.Report.targetSubjectGroupInfo,
    };
  };

  const { targetSubjectGroupInfo } = useSelector(selector);

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    const items = [
      {
        id: 'subjectGroup',
        label: intl.messages['report.subjectGroup']
      },
      ...(AllJournalsHeaderList.map(item => ({
        id: item.key,
        label: intl.messages[`targets.${item.id}`],
      }))),
      ...(OAJournalsHeaderList.map(item => ({
        id: item.key,
        label: intl.messages[`targets.${item.id}`],
      }))),
    ];
    for (let item of items){
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '18px Open Sans');
    }
    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (targetSubjectGroupInfo && targetSubjectGroupInfo.length > 0){
      const maxMap = {};
      for (let i = 0; i < targetSubjectGroupInfo.length; i++) {
        let dataObj = targetSubjectGroupInfo[i];

        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [targetSubjectGroupInfo]);

  function calcAllJournalWidth(){
    let sum = 0;
    for (let item of AllJournalsHeaderList) {
      sum += Math.max((columnWidthMap[item.key]??0), columnMinWidthMap[item.key]) + 50;
    }
    return sum;
  }

  function calcOAJournalWidth(){
    let sum = 0;
    for (let item of OAJournalsHeaderList) {
      sum += Math.max((columnWidthMap[item.key]??0), columnMinWidthMap[item.key]) + 50;
    }
    return sum;
  }

  useEffect(() => {
    async function getData() {
      if (
        fiscalYear !== null &&
        fiscalYear !== undefined &&
        fiscalYear !== ''
      ) {
        unstable_batchedUpdates(() => {
          dispatch(getTargetsSG(fiscalYear));
        });
      }
      setLoading(false);
    }
    setLoading(true);
    getData();
  }, [fiscalYear]);

  const AllJournalsHeaderList = [
    {
      id: 'acquired',
      key: 'targetAllAcquiredSi',
      minWidth: '100px',
      maxWidth: '100px',
      isOrder: false,
    },
    {
      id: 'submission',
      key: 'targetAllSubmission',
      minWidth: '125px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'acceptance',
      key: 'targetAllAcceptance',
      minWidth: '100px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'publication',
      key: 'targetAllPublication',
      minWidth: '100px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'journalsIncluded',
      key: 'targetAllJournalsIncluded',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: false,
    },
  ];

  const OAJournalsHeaderList = [
    {
      id: 'acquired',
      key: 'targetOaAcquiredSi',
      minWidth: '100px',
      maxWidth: '100px',
      isOrder: false,
    },
    {
      id: 'submission',
      key: 'targetOaSubmission',
      minWidth: '100px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'acceptance',
      key: 'targetOaAcceptance',
      minWidth: '100px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'publication',
      key: 'targetOaPublication',
      minWidth: '100px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'journalsIncluded',
      key: 'targetOaJournalsIncluded',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'stretchSubmission',
      key: 'targetOaStretchSubmission',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'stretchAcceptance',
      key: 'targetOaStretchAcceptance',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'stretchPublication',
      key: 'targetOaStretchPublication',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: false,
    },
  ];

  const showValueList = [
    'subjectGroup',
    'targetAllAcquiredSi',
    'targetAllSubmission',
    'targetAllAcceptance',
    'targetAllPublication',
    'targetAllJournalsIncluded',
    'targetOaAcquiredSi',
    'targetOaSubmission',
    'targetOaAcceptance',
    'targetOaPublication',
    'targetOaJournalsIncluded',
    'targetOaStretchSubmission',
    'targetOaStretchAcceptance',
    'targetOaStretchPublication',
  ];

  //如果表为空，返回一个空的列表
  const showDataTable = targetSubjectGroupInfo => {
    if (
      targetSubjectGroupInfo === undefined ||
      targetSubjectGroupInfo === null
    ) {
      return [];
    } else return targetSubjectGroupInfo;
  };

  const judgeColor = (row, col) => {
    if (row % 2 === 0) {
      if (col < 6) {
        return '#F5F7FB';
      } else {
        return '#EEFAE9';
      }
    }
    return '#ffffff';
  };

  return (
    <TableBox2
      data-selenium-id={'Report_Targets_Overview_TargetSubjectGroupTable'}
      dataList={[targetSubjectGroupInfo]}
      tableKey={'Report_Targets_Overview_SGTable'}
      footerHeight={0}
    >
      <TableContainer
        id='reportTargetsTOSGTable'
        data-selenium-id='Report_Targets_Overview_TargetSubjectGroupTable-TableContainer'
        sx={{ maxHeight: '628.2px', maxWidth: '100%' }}
      >
        <Table
          stickyHeader
          data-selenium-id='Report_Targets_Overview_TargetSubjectGroupTable-Table'
          sx={{
            tableLayout: 'fixed',
            width: 'max-content',
            maxWidth: 'none',
          }}
        >
          <TableHead data-selenium-id='Report_Targets_Overview_TargetSubjectGroupTable-TableHeader'>
            <TableRow data-selenium-id='Report_Targets_Overview_TargetSubjectGroupTable-TableHeader-Row'>
              <StyledTableCellNormal
                data-selenium-id='Report_Targets_Overview_TargetSubjectGroupTable-TableHeader-Row-Cell-1'
                align='left'
                isOA={false}
                rowSpan={2}
                className='RightBottomBorder'
                sx={{
                  // minWidth: 200,
                  width: `${Math.max((columnWidthMap['subjectGroup']??0), columnMinWidthMap['subjectGroup']) + 50 }px`,
                  position: 'sticky',
                  left: 0,
                  top: 0,
                  zIndex: 8,
                  backgroundColor: '#DFE4E8',
                }}
              >
                <FormattedMessage
                  id='report.subjectGroup'
                  data-selenium-id='Report_Targets_Overview_TargetSubjectGroupTable-TableHeader-SubjectGroup'
                />
              </StyledTableCellNormal>
              <PurpleGreenCell
                data-selenium-id='Report_Targets_Overview_TargetSubjectGroupTable-TableHeader-Row-Cell-2'
                className='RightBottomBorder'
                align='left'
                isOA={false}
                colSpan={5}
                sx={{
                  width: `${calcAllJournalWidth()}px`,
                }}
              >
                <FormattedMessage
                  id='targets.allJournals'
                  data-selenium-id='Report_Targets_Overview_TargetSubjectGroupTable-TableHeader-AllJournals'
                />
              </PurpleGreenCell>
              <PurpleGreenCell
                data-selenium-id='Report_Targets_Overview_TargetSubjectGroupTable-TableHeader-Row-Cell-3'
                className='BottomBorder'
                align='left'
                isOA={true}
                colSpan={8}
                sx={{
                  width: `${calcOAJournalWidth()}px`,
                }}
              >
                <FormattedMessage
                  id='targets.oaJournals'
                  data-selenium-id='Report_Targets_Overview_TargetSubjectGroupTable-TableHeader-OaJournals'
                />
              </PurpleGreenCell>
            </TableRow>
            <TableRow data-selenium-id='Report_Targets_Overview_TargetSubjectGroupTable-TableHeader-Row-2'>
              {AllJournalsHeaderList.map((item, index) => (
                <PurpleGreenDisCell
                  data-selenium-id={
                    'Report_Targets_Overview_TargetSubjectGroupTable-TableHeader-Row-2-Cell-' +
                    index
                  }
                  isOA={false}
                  align='left'
                  key={item.id + 'acquire'}
                  className={
                    index === AllJournalsHeaderList.length - 1
                      ? 'RightBorder BottomBorder'
                      : 'BottomBorder'
                  }
                  sx={{
                    // top: 52.5,
                    top: 42,
                    fontSize: '12px',
                    // minWidth: item.minWidth,
                    // maxWidth: item.maxWidth,
                    width: `${Math.max((columnWidthMap[item.key]??0), columnMinWidthMap[item.key]) + 50 }px`,
                  }}
                >
                  {intl.messages[`targets.${item.id}`]}
                </PurpleGreenDisCell>
              ))}
              {OAJournalsHeaderList.map((item, index) => (
                <PurpleGreenDisCell
                  data-selenium-id={
                    'Report_Targets_Overview_TargetSubjectGroupTable-TableHeader-Row-2-Cell-' +
                    index
                  }
                  isOA={true}
                  align='left'
                  className='BottomBorder'
                  key={item.id}
                  sx={{
                    // top: 52.5,
                    top: 42,
                    fontSize: '12px',
                    // minWidth: item.minWidth,
                    // maxWidth: item.maxWidth,
                    width: `${Math.max((columnWidthMap[item.key]??0), columnMinWidthMap[item.key]) + 50 }px`,
                  }}
                >
                  {intl.messages[`targets.${item.id}`]}
                </PurpleGreenDisCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            data-selenium-id={
              'Report_Targets_Overview_TargetSubjectGroupTable-TableBody'
            }
          >
            {!loading &&
            targetSubjectGroupInfo != null &&
            targetSubjectGroupInfo != undefined &&
            targetSubjectGroupInfo.length > 0 ? (
              showDataTable(targetSubjectGroupInfo).map((item, rowIndex) => {
                if (item.subjectGroup !== 'Total') {
                  return (
                    <NoBorderTableRow
                      data-selenium-id={
                        'Report_Targets_Overview_TargetSubjectGroupTable-TableBody-Row-' +
                        rowIndex
                      }
                    >
                      {showValueList.map((column, colIndex) => {
                        if (column === 'subjectGroup') {
                          return (
                            <StyledTableCell
                              data-selenium-id={
                                'Report_Targets_Overview_TargetSubjectGroupTable-TableBody-Row-' +
                                rowIndex +
                                '-Cell-' +
                                colIndex
                              }
                              sx={{
                                // top: 90,
                                // backgroundColor: judgeColor(rowIndex, colIndex),
                                position: 'sticky',
                                left: 0,
                                zIndex: 6,
                                backgroundColor: '#F1F3F5',
                              }}
                              className='RightBorder'
                            >
                              <Tooltip
                                data-selenium-id={
                                  'Report_Targets_Overview_TargetSubjectGroupTable-TableBody-Row-' +
                                  rowIndex +
                                  '-Cell-' +
                                  colIndex +
                                  'Tooltip'
                                }
                                title={item.subjectGroup}
                                disableInteractive
                              >
                                <div
                                  style={{
                                    textOverflow: 'ellipsis',
                                    // width: '140px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {item[column]}
                                </div>
                              </Tooltip>
                            </StyledTableCell>
                          );
                        } else {
                          if (column === 'targetAllJournalsIncluded') {
                            return (
                              <PurpleGreenDataCell
                                isOA={colIndex>5}
                                data-selenium-id={
                                  'Report_Targets_Overview_TargetSubjectGroupTable-TableBody-Row-' +
                                  rowIndex +
                                  '-Cell-' +
                                  colIndex
                                }
                                sx={{
                                  // top: 90,
                                  backgroundColor: judgeColor(
                                    rowIndex,
                                    colIndex
                                  ),
                                }}
                                align='left'
                                className='RightBorder ReportNumberTextColor'
                              >
                                {item[column]}
                              </PurpleGreenDataCell>
                            );
                          } else {
                            return (
                              <PurpleGreenDataCell
                                isOA={colIndex>5}
                                data-selenium-id={
                                  'Report_Targets_Overview_TargetSubjectGroupTable-TableBody-Row-' +
                                  rowIndex +
                                  '-Cell-' +
                                  colIndex
                                }
                                sx={{
                                  // top: 90,
                                  backgroundColor: judgeColor(
                                    rowIndex,
                                    colIndex
                                  ),
                                }}
                                align='left'
                                className='ReportNumberTextColor'
                              >
                                {item[column]}
                              </PurpleGreenDataCell>
                            );
                          }
                        }
                      })}
                    </NoBorderTableRow>
                  );
                } else {
                  return (
                    <NoBorderTableRow
                      data-selenium-id={
                        'Report_Targets_Overview_TargetSubjectGroupTable-TableBody-Row-' +
                        rowIndex
                      }
                    >
                      {showValueList.map((column, colIndex) => {
                        if (column === 'subjectGroup') {
                          return (
                            <StyledTableCell
                              data-selenium-id={
                                'Report_Targets_Overview_TargetSubjectGroupTable-TableBody-Row-' +
                                rowIndex +
                                '-Cell-' +
                                colIndex
                              }
                              sx={{
                                // top: 90,
                                color: '#434242',
                                fontSize: '16px',
                                lineHeight: '14px',
                                // backgroundColor: judgeColor(rowIndex, colIndex),
                                position: 'sticky',
                                left: 0,
                                zIndex: 6,
                                backgroundColor: '#F1F3F5',
                              }}
                              align='right'
                              className='RightBorder'
                            >
                              <strong>{item[column]}</strong>
                            </StyledTableCell>
                          );
                        } else {
                          if (column === 'targetAllJournalsIncluded') {
                            return (
                              <PurpleGreenDataCell
                                isOA={colIndex>5}
                                data-selenium-id={
                                  'Report_Targets_Overview_TargetSubjectGroupTable-TableBody-Row-' +
                                  rowIndex +
                                  '-Cell-' +
                                  colIndex
                                }
                                sx={{
                                  // top: 90,
                                  fontWeight: '600',
                                  backgroundColor: judgeColor(
                                    rowIndex,
                                    colIndex
                                  ),
                                }}
                                align='left'
                                className='RightBorder ReportNumberTextColor'
                              >
                                {item[column]}
                              </PurpleGreenDataCell>
                            );
                          } else {
                            return (
                              <PurpleGreenDataCell
                                isOA={colIndex>5}
                                data-selenium-id={
                                  'Report_Targets_Overview_TargetSubjectGroupTable-TableBody-Row-' +
                                  rowIndex +
                                  '-Cell-' +
                                  colIndex
                                }
                                sx={{
                                  // top: 90,
                                  fontWeight: '600',
                                  backgroundColor: judgeColor(
                                    rowIndex,
                                    colIndex
                                  ),
                                }}
                                align='left'
                                className='ReportNumberTextColor'
                              >
                                {item[column]}
                              </PurpleGreenDataCell>
                            );
                          }
                        }
                      })}
                    </NoBorderTableRow>
                  );
                }
              })
            ) : !loading ? (
              <ReportNoData id={'Report-Targets-SubjectGroupTable-NoData'}/>
            ) : (
              <div
                data-selenium-id={
                  'Report_Targets_Overview_TargetSubjectGroupTable-TableBody-LoadingDiv'
                }
              ></div>
            )}
          </TableBody>
        </Table>
        {loading && (
          <Box
            data-selenium-id={
              'Report_Targets_Overview_TargetSubjectGroupTable-TableBody-LoadingBox'
            }
            sx={{
              width: 'auto',
              marginTop: '100px',
              marginLeft: '50%',
            }}
          >
            <CircularProgress
              data-selenium-id={
                'Report_Targets_Overview_TargetSubjectGroupTable-TableBody-LoadingCircularProgress'
              }
            />
          </Box>
        )}
      </TableContainer>
    </TableBox2>
  );
}

export default TargetSubjectGroupTable;

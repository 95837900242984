import {PERMISSIONS} from "@/constant/permission";

const userFilter = {
    apiPath: '/api/v1/user-service/users',
    firstParamName: 'fullName',
};

const filters = {
    // normal
    fullName: {
        apiPath: `/api/v1/si-service/guest-editors/filter-metadata/${'get-ge-name-list'}`,
        firstParamName: 'searchKey',
    },
    leadGeFullName: {
        apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
        firstParamName: 'name',
        action:'getGeListFuzzily',
    },
    gender: {
        apiPath: '',
        firstParamName: '',
    },
    primaryEmail: {
        apiPath: `/api/v1/si-service/guest-editors/filter-metadata/${'get-ge-email-list'}`,
        firstParamName: 'searchKey',
    },
    institute: {
        apiPath: `/api/v1/si-service/guest-editors/filter-metadata/${'get-ge-institute-list'}`,
        firstParamName: 'searchKey',
    },
    country: {
        apiPath: `/api/v1/si-service/guest-editors/filter-metadata/${'get-ge-country-list'}`,
        firstParamName: 'searchKey',
    },
    associateJournal: {
        apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
        firstParamName: 'name',
    },
    journal: {
        apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
        firstParamName: 'name',
    },
    jpm: {
        apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
        firstParamName: 'name',
        action:'getJpm',
    },
    subjectGroup: {
        apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
        firstParamName: 'name',
        action:'getSubjectGroup',
    },
    stage: {
        apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
        firstParamName: 'name',
        action:'getStage',
    },
    businessDivision: {
        apiPath: '/reports/pipeline/filter/getBusinessDivision',
        firstParamName: 'name',
    },

    // system config
    group: {
        type: 'config',
        typeValue: 'Group/Title',
        apiPath: `/api/v1/user-service/configs`,
        firstParamName: 'value',
        secParamName: 'type',
    },
    catGroup: {
        // 和group实际上是同一个，以后需要合并掉
        type: 'config',
        typeValue: 'Group/Title',
        apiPath: `/api/v1/user-service/configs`,
        firstParamName: 'value',
        secParamName: 'type',
    },
    flags: {
        type: 'config',
        typeValue: '%Flags%',//这里加%是因为要模糊搜索。虽然按理来说这个%应该是后端去加
        apiPath: `/api/v1/user-service/configs`,
        firstParamName: 'value',
        secParamName: 'type',
        fuzzy_type: true,
    },

    sipCode: {
        apiPath: '/api/v1/sip-service/special-issue-proposals/filter-metadata',
        firstParamName: 'sipCode',
    },
    sipTitle: {
        apiPath: '/api/v1/sip-service/special-issue-proposals/filter-metadata',
        firstParamName: 'sipTitle'
    },
    handlingCe: userFilter,
    operationsLead: userFilter,
    screener: userFilter,
    geName: {
        apiPath: '/api/v1/sip-service/special-issue-proposals/filter-metadata',
        firstParamName: 'geName',
    },
    geEmail: {
        apiPath: '/api/v1/sip-service/special-issue-proposals/filter-metadata',
        firstParamName: 'geEmail'
    },
    keywords: {
        apiPath: '/api/v1/sip-service/special-issue-proposals/filter-metadata',
        firstParamName: 'keyword'
    },
    aimsAndScope: {
        apiPath: '/api/v1/sip-service/special-issue-proposals/filter-metadata',
        firstParamName: 'aimsAndScope'
    },
    topics: {
        apiPath: '/api/v1/sip-service/special-issue-proposals/filter-metadata',
        firstParamName: 'topic'
    },

    journalRevenueModel: {
        apiPath: '/api/v1/user-service/journals/managed/codes-and-names',
        firstParamName: 'name',
        action:'getJournalRevenueModel',
    },
    geCode:{
        apiPath: `/api/v1/si-service/guest-editors/filter-metadata/${'get-ge-code-list'}`,
        firstParamName:'searchKey'
    },
    sipOrigin: {
        // apiPath:'/sip/filter/getAllSIPOrigins' 
    },
    preferredSubjectArea: {
        apiPath: '/api/v1/sip-service/special-issue-proposals/filter-metadata',
        firstParamName: 'preferredSubjectArea'
    },
    preferredJournal: {
        apiPath: '/api/v1/sip-service/special-issue-proposals/filter-metadata',
        firstParamName: 'preferredJournal'
    },
    region: {
        apiPath: `/api/v1/si-service/guest-editors/filter-metadata/${'get-ge-country-list'}`,
        firstParamName: 'searchKey'
    },
    stageList: {
        // apiPath:'/sip/filter/getAllSIPStages',
    },
};

export function getAPIPath(which) {
    return filters[which].apiPath;
}

/**
 * 给load options方法构造请求参数
 * 主要区分旧的数据和新的config数据
 * @param which
 * @param searchContent
 * @param page
 * @param pageSize
 * @returns {{}|{type: *, value}|{pageSize, pageNum}}
 */
export function buildParamsForFilter(which, searchContent, page, pageSize, solicited) {
    const isUserObject = (which === 'handlingCe' || which === 'operationsLead' || which === 'screener');
    if (filters[which].type === 'config') {
        return {
            type: filters[which].typeValue,
            value: searchContent,
            solicited
        };
    } 
    else {
        if(which === 'title'){
            return {
                [filters[which].firstParamName]: searchContent,
                solicited,
            };
        } else if (isUserObject) {
            let permissionCode;
            switch (which) {
                case "operationsLead": // check point
                    permissionCode = PERMISSIONS.SIP_WRITE_OPS;
                    break;
                case "screener": // check point
                    permissionCode = PERMISSIONS.SIP_WRITE_SCREENER;
                    break;
                default:
                    // permissionCode = undefined;
                    permissionCode = PERMISSIONS.SIP_WRITE_HANDLING_CE;
            }
            return {
                [filters[which].firstParamName]: searchContent,
                permissionCode,
                fuzzy: true,
                pageNum: page,
                pageSize: pageSize,
            };
        }

        return {
            [filters[which].firstParamName]: searchContent,
            pageNum: page,
            pageSize: pageSize,
            solicited,
        };
    }
}

export function getSIPGEFlagState(isEditing, flags, flagsList, isNoNeed = false, isFirstTime = false) {
    if (isNoNeed) return 'checked';

    let wrong = false;
    let hasEmpty = false;
    for (let item of flags) {
        if (item.flagId == 0) {
            hasEmpty = true;
        } else {
            const isSevere = flagsList.some(serverFlag =>
                serverFlag.type === 'Flags-Severe Issue' && serverFlag.id === item.flagId);
            if ((isSevere && (item.fileNames.length === 0 || item.fileNames?.length === undefined))
                || item.comment?.length > 10000) {
                wrong = true;
            }
        }
    }
    if (isEditing) {
        if (wrong || hasEmpty) {
            if (isFirstTime) {
                return 'default';
            }
            return "error";
        }
    }

    if (hasEmpty || !isEditing && wrong) return 'default';

    return 'checked';
}

export function checkSIPGEFlagError(flags, flagsList, isNoNeed = false) {
    if (isNoNeed) return false;
    let wrong = false;
    let hasEmpty = false;
    if (!flags) {
        return false;
    } else {
        for (let item of flags) {
            if (item.flagId == 0) {
                hasEmpty = true;
                break;
            } else {
                const isSevere = flagsList.some(serverFlag =>
                    serverFlag.type === 'Flags-Severe Issue' && serverFlag.id === item.flagId);
                if ((isSevere && (item.fileNames.length === 0 || item.fileNames?.length === undefined))
                    || item.comment?.length > 10000) {
                    wrong = true;
                    break;
                }
            }
        }
        return wrong || hasEmpty;

    }

}

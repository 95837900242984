/**
 * Huang Tao
 */
import React, { useEffect, useState } from 'react';
import './index.css';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '@/components/DataTable';
import {
  StyledTableCellPurple,
  StyledTableCellDisPurple,
  StyledTableCellGreen,
  StyledTableCellDisGreen,
  StyledTableRow, StyledTableCellNormal, StyledBodyCellGreen, StyledBodyCellPurple, TableBox, ReportNoData, TableBox2
} from "../../ReportSpecificComponents/SpecialColorTableHeader";
import { findLongestLineSubstring, measureWidth } from "../../../../utils/StringWidthMeasure";
import {GetAcquireOverviewBusinessTableData} from "@/actions/SIMT-Report/Report/ReportAcquiredAndActive";
export function AcquireBusinessDivisionTable(props) {
  const { ifNoResult } = props;

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { currentDate, table3Data, tableMonth } = useSelector(state => {
    return {
      // currentDate: state.Report.selectedAcquireDate,
      currentDate: state.Report.selectDate,
      tableMonth: state.Report.requireOverviewBusinessTableMonth,
      table3Data: state.Report.requireOverviewBusinessTableData,
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    for (let item of items){
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '18px Open Sans');
    }
    widthMap['businessDivision'] = measureWidth(findLongestLineSubstring('Business Division'), '12px Open Sans');
    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (table3Data && table3Data.length > 0){
      const maxMap = {};
      for (let i = 0; i < table3Data.length; i++) {
        let row = table3Data[i];
        let dataObj = {
          businessDivision: row.businessDivision,

          'Acquired-MMM': row.acquiredSi ?? '0',
          'Acquired-YTD': row.acquiredYtd ?? '0',
          '%Against YTD Target': row.againstYTDTarget ?? '0',
          'Active SIs': row.activeSi ?? '0',

          'OAAcquired-MMM': row.acquiredSiOa ?? '0',
          'OAAcquired-YTD': row.acquiredYtdOa ?? '0',
          'OA%Against YTD Target': row.againstYTDTargetOA ?? '0',
          'OAActive SIs': row.againstYTDTargetOA ?? '0',
        };
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [table3Data]);

  function calcAllJournalWidth(){
    let sum = 0;
    for (let item of items) {
      sum += Math.max((columnWidthMap[item.id]??0), columnMinWidthMap[item.id]) + 50;
    }
    return sum;
  }

  function calcOAJournalWidth(){
    let sum = 0;
    for (let item of items) {
      sum += Math.max((columnWidthMap['OA'+item.id]??0), columnMinWidthMap[item.id]) + 50;
    }
    return sum;
  }

  useEffect(() => {
    async function getData(currentDate) {
      if (currentDate != null) {
        setIsLoading(true);
        setTimeout(() => {
          dispatch(GetAcquireOverviewBusinessTableData(currentDate));
        }, 2000);
        // getMonth(currentDate.split("-")[1]);
      }
    }
    getData(currentDate);
  }, [currentDate]);

  useEffect(() => {
    setIsLoading(false);
  }, [table3Data]);

  const items = [
    {
      id: 'Acquired-MMM',
      label: 'Acquired SI - ',
      minWidth: '100px',
      maxWidth: '120px',
      isOrder: false,
    },
    {
      id: 'Acquired-YTD',
      label: 'Acquired SI - YTD',
      minWidth: '120px',
      maxWidth: '120px',
      isOrder: false,
    },
    {
      id: '%Against YTD Target',
      label: '% Against YTD Target',
      minWidth: '125px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'Active SIs',
      label: 'Active SI',
      minWidth: '130px',
      maxWidth: '150px',
      isOrder: false,
    },
  ];

  const showDataTableSG = table3Data => {
    //  console.log(tabls)
    if (table3Data === undefined || table3Data === null) {
      return [];
    } else return table3Data;
  };
  //total字体加深
  function DisplayTooltip(props) {
    const { ifTotal } = props;
    if (ifTotal === 'Total')
      return (
        <div align='right'>
          < >{ifTotal}</ >
        </div>
      );
    else {
      return <div align='left'>{ifTotal}</div>;
    }
  }
  return (
    <TableBox2
      dataList={[table3Data]}
      tableKey={'Report_AcquireOverview-Overview-BusinessTable-Box'}
      footerHeight={0}
      id='BoxTable2'
      data-selenium-id='Report_AcquiredAndActiveSi-Overview-BusinessTable-Box'
    >
      <TableContainer
        sx={{ maxHeight: '600px' }}
        data-selenium-id='Report_AcquiredAndActiveSi-Overview-BusinessTable-TableContainer'
      >
        <Table
          stickyHeader
          aria-label='sticky table'
          data-selenium-id='Report_AcquiredAndActiveSi-Overview-BusinessTable-Table'
          sx={{
            tableLayout: 'fixed',
            width: 'max-content',
            maxWidth: 'none',
          }}
        >
          <TableHead data-selenium-id='Report_AcquiredAndActiveSi-Overview-BusinessTable-TableHead'>
            <TableRow data-selenium-id='Report_AcquiredAndActiveSi-Overview-BusinessTable-TableHead-TableRow1'>
              <StyledTableCellNormal
                id='reportAAASOverviewBDBusinessDivisionHead'
                data-selenium-id='Report_AcquiredAndActiveSi-Overview-BusinessTable-TableHead-StyledTableCellPurple1'
                align='left'
                rowSpan={2}
                sx={{
                  // maxWidth: '500px',
                  // minWidth: '140px',
                  width: `${Math.max((columnWidthMap['businessDivision']??0), columnMinWidthMap['businessDivision']) + 50 }px`,
                  position: 'sticky',
                  left: 0,
                  top: 0,
                  zIndex: 8,
                  backgroundColor: '#DFE4E8',
                }}
                className='RightBottomBorder'
              >
                Business Division
              </StyledTableCellNormal>
              <StyledTableCellPurple
                id='reportAAASOverviewBDAllJournalsHead'
                data-selenium-id='Report_AcquiredAndActiveSi-Overview-BusinessTable-TableHead-StyledTableCellPurple2'
                align='left'
                colSpan={4}
                className='RightBottomBorder ReportHeaderFirstLine'
                sx={{
                  width: `${calcAllJournalWidth()}px`,
                }}
              >
                All journals
              </StyledTableCellPurple>
              <StyledTableCellGreen
                id='reportAAASOverviewBDOAJournalsHead'
                align='left'
                colSpan={4}
                className='RightBottomBorder ReportHeaderFirstLine'
                data-selenium-id='Report_AcquiredAndActiveSi-Overview-BusinessTable-TableHead-StyledTableCellGreen1'
                sx={{
                  width: `${calcOAJournalWidth()}px`,
                }}
              >
                OA journals
              </StyledTableCellGreen>
            </TableRow>
            <TableRow data-selenium-id='Report_AcquiredAndActiveSi-Overview-BusinessTable-TableHead-TableRow2'>
              {items.map((item, index) => {
                if (item.id === 'Active SIs') {
                  return (
                    <StyledTableCellDisPurple
                      id={`reportAAASOverviewBDAllHead${index}`}
                      data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableHead-TableRow2-BDAllHead-${index}`}
                      className='RightBottomBorder'
                      align='left'
                      sx={{
                        top: 42,
                        // minWidth: item.minWidth,
                        // maxWidth: item.maxWidth,
                        ...({
                          width: `${Math.max((columnWidthMap[item.id]??0), columnMinWidthMap[item.id]) + 50 }px`,
                        }),
                      }}
                      key={item.id}
                      style={{ 'padding-top': '5px', 'padding-bottom': '5px' }}
                    >
                      {item.label}
                    </StyledTableCellDisPurple>
                  );
                } else {
                  return (
                    <StyledTableCellDisPurple
                      id={`reportAAASOverviewBDAllHead${index}`}
                      data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableHead-TableRow2-BDAllHead-${index}`}
                      align='left'
                      sx={{
                        top: 42,
                        // minWidth: item.minWidth,
                        // maxWidth: item.maxWidth,
                        ...({
                          width: `${Math.max((columnWidthMap[item.id]??0), columnMinWidthMap[item.id]) + 50 }px`,
                        }),
                      }}
                      key={item.id}
                      className='BottomBorder'
                    >
                      {item.id === 'Acquired-MMM'
                        ? item.label + tableMonth
                        : item.label}
                    </StyledTableCellDisPurple>
                  );
                }
              })}
              {items.map((item, index) => {
                if (item.id === 'Active SIs') {
                  return (
                    <StyledTableCellDisGreen
                      id={`reportAAASOverviewBDOAHead${index}`}
                      data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableHead-TableRow2-BDOAHead-${index}`}
                      className='RightBottomBorder'
                      align='left'
                      sx={{
                        top: 42,
                        // minWidth: item.minWidth,
                        // maxWidth: item.maxWidth,
                        ...( {
                          width: `${Math.max((columnWidthMap['OA'+item.id]??0), columnMinWidthMap[item.id]) + 50 }px`,
                        }),
                      }}
                      key={item.id}
                      style={{ 'padding-top': '5px', 'padding-bottom': '5px' }}
                    >
                      {item.label}
                    </StyledTableCellDisGreen>
                  );
                } else {
                  return (
                    <StyledTableCellDisGreen
                      id={`reportAAASOverviewBDOAHead${index}`}
                      data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableHead-TableRow2-BDOAHead-${index}`}
                      align='left'
                      sx={{
                        top: 42,
                        // minWidth: item.minWidth,
                        // maxWidth: item.maxWidth,
                        ...({
                          width: `${Math.max((columnWidthMap['OA'+item.id]??0), columnMinWidthMap[item.id]) + 50 }px`,
                        }),
                      }}
                      key={item.id}
                      className='BottomBorder'
                    >
                      {item.id === 'Acquired-MMM'
                        ? item.label + tableMonth
                        : item.label}
                    </StyledTableCellDisGreen>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody data-selenium-id='Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody'>
            {!isLoading &&
            !ifNoResult &&
            table3Data != null &&
            table3Data != undefined &&
            table3Data.length != 0
              ? table3Data.map((row, index) => {
                  if (row.businessDivision === 'Total') {
                    return (
                      <StyledTableRow
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableRow-${index}`}
                      >
                        <StyledTableCell
                          className='RightBorder ReportTotalTextSize'
                          align='left'
                          sx={{
                            maxWidth: '150px',
                            position: 'sticky',
                            left: 0,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell1-${index}`}
                        >
                          <DisplayTooltip ifTotal={row.businessDivision} />
                        </StyledTableCell>
                        <StyledBodyCellPurple
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell2-${index}`}
                        >
                          < >
                            {row.acquiredSi === null ? '0' : row.acquiredSi}
                          </ >
                        </StyledBodyCellPurple>
                        <StyledBodyCellPurple
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell3-${index}`}
                        >
                          < >
                            {row.acquiredYtd === null ? '0' : row.acquiredYtd}
                          </ >
                        </StyledBodyCellPurple>
                        <StyledBodyCellPurple
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell4-${index}`}
                        >
                          < >
                            {' '}
                            {row.againstYTDTarget === null
                              ? '0'
                              : row.againstYTDTarget}
                          </ >
                        </StyledBodyCellPurple>
                        <StyledBodyCellPurple
                          className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell5-${index}`}
                          align='left'
                          sx={{ maxWidth: '150px' }}
                        >
                          < >
                            {' '}
                            {row.activeSi === null ? '0' : row.activeSi}
                          </ >
                        </StyledBodyCellPurple>
                        <StyledBodyCellGreen
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell6-${index}`}
                        >
                          < >
                            {' '}
                            {row.acquiredSiOa === null ? '0' : row.acquiredSiOa}
                          </ >
                        </StyledBodyCellGreen>
                        <StyledBodyCellGreen
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell7-${index}`}
                        >
                          < >
                            {' '}
                            {row.acquiredYtdOa === null
                              ? '0'
                              : row.acquiredYtdOa}
                          </ >
                        </StyledBodyCellGreen>
                        <StyledBodyCellGreen
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell8-${index}`}
                        >
                          < >
                            {' '}
                            {row.againstYTDTargetOA === null
                              ? '0'
                              : row.againstYTDTargetOA}
                          </ >
                        </StyledBodyCellGreen>
                        <StyledBodyCellGreen
                          className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell9-${index}`}
                          align='left'
                          sx={{ maxWidth: '150px' }}
                        >
                          < >
                            {' '}
                            {row.activeSiOa === null ? '0' : row.activeSiOa}
                          </ >
                        </StyledBodyCellGreen>
                      </StyledTableRow>
                    );
                  }
                  return (
                    <StyledTableRow
                      data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableRow-${index}`}
                    >
                      <StyledTableCell
                        className='RightBorder'
                        align='left'
                        sx={{
                          maxWidth: '150px',
                          position: 'sticky',
                          left: 0,
                          zIndex: 6,
                          backgroundColor: '#F1F3F5',
                        }}
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell1-${index}`}
                      >
                        {row.businessDivision}
                      </StyledTableCell>
                      <StyledBodyCellPurple
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell2-${index}`}
                      >
                        {row.acquiredSi === null ? '0' : row.acquiredSi}
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell3-${index}`}
                      >
                        {row.acquiredYtd === null ? '0' : row.acquiredYtd}
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell4-${index}`}
                      >
                        {row.againstYTDTarget === null
                          ? '0'
                          : row.againstYTDTarget}
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        className='RightBorder ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell5-${index}`}
                        align='left'
                        sx={{ maxWidth: '150px' }}
                      >
                        {row.activeSi === null ? '0' : row.activeSi}
                      </StyledBodyCellPurple>
                      <StyledBodyCellGreen
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell6-${index}`}
                      >
                        {row.acquiredSiOa === null ? '0' : row.acquiredSiOa}
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell7-${index}`}
                      >
                        {row.acquiredYtdOa === null ? '0' : row.acquiredYtdOa}
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell8-${index}`}
                      >
                        {row.againstYTDTargetOA === null
                          ? '0'
                          : row.againstYTDTargetOA}
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        className='RightBorder ReportNumberTextColor'
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-StyledTableCell9-${index}`}
                        align='left'
                        sx={{ maxWidth: '150px' }}
                      >
                        {row.activeSiOa === null ? '0' : row.activeSiOa}
                      </StyledBodyCellGreen>
                    </StyledTableRow>
                  );
                })
              : !isLoading && (
                  <ReportNoData id='reportJournalSGNoResultTableCell' />
                )}
            {isLoading && (
              <Box
                sx={{
                  width: 'auto',
                  marginTop: '150px',
                  marginLeft: '50%',
                }}
                data-selenium-id='Report_AcquiredAndActiveSi-Overview-BusinessTable-TableBody-LoadingBox'
              >
                <CircularProgress />
              </Box>
            )}
            <TableRow></TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </TableBox2>
  );
}
// export default SubjectGroupTable

import { useEffect } from 'react';
import moment from 'moment';
import CustomDatePicker from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/CustomDatePicker';

export default function DatePicker({value, update = () => {}}) {
  const currentDate = new Date();
  const twoWeeksLater = new Date();
  twoWeeksLater.setDate(currentDate.getDate() + 14);
  const newYear = twoWeeksLater.getFullYear();
  const newMonth = (twoWeeksLater.getMonth() + 1).toString().padStart(2, '0');
  const newDay = twoWeeksLater.getDate().toString().padStart(2, '0');

  useEffect(() => {
    if(value === undefined || value === null){
      update(`${newYear}-${newMonth}-${newDay}`);
    }
  }, [value, update]);
  
  return (
      <CustomDatePicker
        data-selenium-id='SIP_DetailPage_DecisionSection-DatePicker'
        value={codeValue(value)}
        onChange={newValue => {
          update(newValue);
        }}
        placement='top'
        handleFocus={() => {}}
        handleBlur={() => {}}
        ifdataOpen={true}
        setDateOpen={() => {}}
      />
  );
}

function codeValue(input) {
  if (input === null || input === undefined) {
    return '';
  }
  const date = new Date(input);
  if (isNaN(date.valueOf())) {
    return 'Invalid Date';
  }
  return moment(date).utc().format('YYYY-MM-DD');
}

import { IconButton, InputBase, MenuItem, Select, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import DecisionChoiceDown from '../../../../assets/DecisionChoiceDown.svg';
import { defaultVars, ErrVars } from './DecisionMyStyled';

const Arrow = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.52925 5.52876C3.7896 5.26841 4.21171 5.26841 4.47206 5.52876L8.00065 9.05735L11.5292 5.52876C11.7896 5.26841 12.2117 5.26841 12.4721 5.52876C12.7324 5.78911 12.7324 6.21122 12.4721 6.47157L8.47206 10.4716C8.21171 10.7319 7.7896 10.7319 7.52925 10.4716L3.52925 6.47157C3.2689 6.21122 3.2689 5.78911 3.52925 5.52876Z" fill="#596A7C" />
    </svg>
  );
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '92px',
      marginTop: '4px',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
    },
  },
};

const getBorderStyle = (value, open) => {
  if(open === true) {
    return "2px solid #154AB6";
    
  } else {
    if(value && value !== "") {
      return "1px solid #98A7B6";
    }
    return "1px solid #DFE4E8";
  }
};

export const CssSelect = styled(Select)(props => ({
  '& .MuiSelect-select': {
    border: getBorderStyle(props.value, props.open),
    '&:not(:focus):hover': {
      border: "1px solid #596A7C"
    },
    paddingRight: '0px',
    minWidth: '281px',
    boxSizing: 'border-box',
  },
  
}));
export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2),
  },
  '& .MuiInputBase-input': {
    background: '#FFFFFF',
    borderRadius: 4,
    border: 'var(--border)',
    boxSizing: 'border-box',
    padding: '11px 0px 11px 12px',
    width: '100%',
    height: '42px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '125%',
    cursor: 'pointer',
    '&:focus': {
      border: 'var(--focus-border)',
      padding: '11px 0px 11px 12px',
      borderRadius: 4,
    },
    '&::placeholder': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '120%',
      color: '#BCC5CF',
      opacity: '1',
      letterSpacing: "-0.154px"
    },
  },
}));

export function DecisionSelectBlock(props) {
  const {
    id,
    list,
    emptyError,
    value,
    attribute,
    defaultValue,
    placeholder,
    ...others
  } = props;
  const [open, setOpen] = useState(false);
  const [vars, setVars] = useState(defaultVars);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getIcon = () => {
    if (open) {
      return (
        <IconButton
          disableFocusRipple
          disableRipple
          data-selenium-id='SIP_DetailPage_DecisionSection-Block-IconButton1'
          sx={{
            transform: 'rotate(180deg)',
            position: 'absolute',
            right: '7px',
            top: '6px',
          }}
          onClick={handleClose}
        >
          <Arrow data-selenium-id='SIP_DetailPage_DecisionSection-Block-DecisionChoiceDown1'></Arrow>
        </IconButton>
      );
    } else {
      return (
        <IconButton
          disableFocusRipple
          disableRipple
          data-selenium-id='SIP_DetailPage_DecisionSection-Block-IconButton2'
          onClick={handleOpen}
          sx={{
            position: 'absolute',
            right: '7px',
            top: '6px',
          }}
        >
          <Arrow data-selenium-id='SIP_DetailPage_DecisionSection-Block-DecisionChoiceDown2'></Arrow>
        </IconButton>
      );
    }
  };
  useEffect(() => {
    if (emptyError) {
      setVars(ErrVars);
    } else {
      setVars(defaultVars);
    }
  }, [emptyError]);

  return (
    <CssSelect
      {...others}
      id={id}
      data-selenium-id={`SIP_DetailPage_DecisionSection-Block-DecisionChoiceDown1-${id}`}
      displayEmpty={true}
      value={value}
      // onChange={handleChange}
      input={
        <BootstrapInput
          style={vars}
          data-selenium-id='SIP_DetailPage_DecisionSection-Block-BootstrapInput'
        />
      }
      IconComponent={getIcon}
      // defaultValue={defaultValue}
      attribute={attribute}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      renderValue={value => {
        return value ? value : placeholder;
      }}
      MenuProps={MenuProps}
      sx={{
        ...vars,
        width: '286px',
        color: value ? '#262E35' : '#BCC5CF',
        alignItems: 'flex-start',
      }}
    >
      {/* todo 加上之后item点击会失效 */}
      {/* <div style={{ maxHeight: "82px" }}> */}
      {list.length === 0 ? (
        <MenuItem
          disabled
          data-selenium-id='SIP_DetailPage_DecisionSection-Block-MenuItem1'
        >
          Loading...
        </MenuItem>
      ) : (
        list.map((item, index) => (
          <MenuItem
            data-selenium-id='SIP_DetailPage_DecisionSection-Block-MenuItem2'
            value={item}
            key={`${id}${index}`}
            sx={{
              height: '20px',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontSize: '14px',
              lineHeight: '20px',
              color: '#596A7C !important',
              padding: '5px 0px 5px 12px',
              boxSizing: 'content-box',
              marginTop: index === 0 ? '-7px' : '0px',
              marginBottom: index === list.length - 1 ? '-7px' : '0px',
            }}
          >
            {item}
          </MenuItem>
        ))
      )}
      {/* </div> */}
    </CssSelect>
  );
}

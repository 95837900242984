import styles from './index.module.scss';
import {FormControlLabel, Radio, RadioGroup as MuiRadioGroup} from "@mui/material";

export interface RadioGroupProps {
    value: string | null;
    options: string[];
    onChange: (value: string) => void;
    error?: boolean;
    row?: boolean;
}

export function RadioGroup(props: RadioGroupProps) {
    const {
        value,
        options,
        onChange,
        error
    } = props;

    return (
        <MuiRadioGroup
            row={props.row}
            className={styles['radio-group'] + ' ' + (error ? styles['error'] : '')}
            value={value}
            onChange={ event => onChange(event.target.value)}
        >
            {options.map((option, index) => (
                <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
            ))}
        </MuiRadioGroup>
    );
}
import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Paper } from '@mui/material';
import styles from './index.module.scss';

const ChartCard = ({ title, subtitle, chartImg }) => {
  const [showCover, setShowCover] = useState(false);
  return (
    <Paper className={styles['card']} elevation={0}>
      <div
        onMouseEnter={() => setShowCover(true)}
        onMouseLeave={() => setShowCover(false)}
      >
        <img className={styles['image']} src={chartImg} />
        {showCover && (
          <>
            <div className={styles['cover']}></div>
            <div className={styles['titles']}>
              <h1 className={styles['title']}>{title}</h1>
              <h2 className={styles['subtitle']}>{subtitle}</h2>
            </div>
          </>
        )}
      </div>
    </Paper>
  );
};

ChartCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  chartImg: PropTypes.string.isRequired,
};

export default ChartCard;

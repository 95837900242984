import React from 'react';
import SimpleIconButton from '../Button/SimpleIconButton';
import IconActive from './icon/EmailIconActive.svg';
import IconHover from './icon/EmailIconHover.svg';
import IconDefault from './icon/EmailIconDefault.svg';
// import IconDisable from './icon/EmailIconDisable.svg';
export default function EmailIconButton(props) {
  return (
    <SimpleIconButton
      DefaultIcon={IconDefault}
      HoverIcon={IconHover}
      ActiveIcon={IconActive}
      // DisabledIcon={IconDisable}
      {...props}
    ></SimpleIconButton>
  );
}
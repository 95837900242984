import * as actions from '../actions/SIMT-Report/Report/ReportAction';
import * as actionsArticle from '../actions/SIMT-Report/ReportArticle/ReportArticleAction';
import * as actionsJournal from '../actions/SIMT-Report/ReportJournal/ReportJournalAction';
import MyLocalStorage from '../utils/MyLocalStorage';
import { getCurrentDate } from '@/utils/date_string.js';

const initState = {
  currentReportTab: 0, //分页用
  currentReportTarget: '',
  cutOffDate: null, // '2023-11',// before this date, use JPM fitler ,else use PD publisher filter. Use the same format as the selectDate
  selectDate: getCurrentDate(), // format is "YYYY-MM"
  basicTableData: {
    result: [],
    count: 0,
    lastActionDate: '',
  },
  table2Data: null,
  table3Data: null,
  table4Data: null,
  selectedAcquireDate: null,
  OAvsTotalSelectDate: null,
  AnnualTrendsDate: null, //Annual Trends 的日期
  targetJournalInfo: {}, //target页面的journal表
  targetSubjectGroupInfo: [], //target页面的SG表
  targetBusinessDivisionInfo: [],
  targetCECATInfo: [],
  //acquireOverivew 数据
  requireOverviewBasicTableData: {
    result: [],
    count: 0,
    lastActionDate: '',
  },
  requireOverviewSubjectTableData: null,
  requireOverviewBusinessTableData: null,
  requireOverviewCETableData: null,
  requireOverviewBasicTableMonth: 'MMM',
  requireOverviewSubjectTableMonth: 'MMM',
  requireOverviewBusinessTableMonth: 'MMM',
  requireOverviewCETableMonth: 'MMM',

  OAvsTotalSubjectGroupResult: [],
  OAvsTotalSubjectGroupMonth: 'MMM',
  OAvsTotalBusinessDivisionResult: [],
  OAvsTotalBusinessDivisionMonth: 'MMM',

  AnnualTrendsSubjectGroupData: null,
  AnnualTrendsBusinessDivisionData: null,
  exportReportResult: null,

  ArticleReportGenenateDate: '', //Article子页面ReportGenenateDate
  ArticleReportGenenateDateFy: '',
  AcquiredActiveReportGenenateDate: '', //AcquiredActive子页面ReportGenenateDate
  AcquiredActiveReportGenenateDateFy: '',
  JournalReportGenenateDate: '', //Journal子页面ReportGenenateDate
  ArticlesOAvsTotalDate: null,
  ArticleAnnualTrendsDate: null,
  ArticlesOAvsTotalSGData: [],
  ArticlesOAvsTotalBDData: [],
  fyTrends: {
    // table & data
    fiscalYear: null,
    page: 1,
    pageSize: 20,
    orderByType: {
      orderBy: 'journalInformation',
      order: 'asc',
    },
    basicTableData: {
      result: [],
      count: 0,
    },
    filterOptions: {
      journal: [],
      jpm: [],
      subjectGroup: [],
      businessDivision: [],
      catGroup: [],
      handlingCe: [],
    },
    filterSelected: {
      journal:
        MyLocalStorage.getItem(
          `ReportReducer-fyTrends-filterSelected-journal`
        ) ?? [],
      jpm:
        MyLocalStorage.getItem(`ReportReducer-fyTrends-filterSelected-jpm`) ??
        [],
      pdPublisher:
      MyLocalStorage.getItem(`ReportReducer-fyTrends-filterSelected-pdPublisher`) ??
      [],
      ppPublisher:
      MyLocalStorage.getItem(`ReportReducer-fyTrends-filterSelected-ppPublisher`) ??
      [],
      subjectGroup:
        MyLocalStorage.getItem(
          `ReportReducer-fyTrends-filterSelected-subjectGroup`
        ) ?? [],
      businessDivision:
        MyLocalStorage.getItem(
          `ReportReducer-fyTrends-filterSelected-businessDivision`
        ) ?? [],
      catGroup:
        MyLocalStorage.getItem(
          `ReportReducer-fyTrends-filterSelected-catGroup`
        ) ?? [],
      handlingCe:
        MyLocalStorage.getItem(
          `ReportReducer-fyTrends-filterSelected-handlingCe`
        ) ?? [],
      oa: MyLocalStorage.getItem(
        `ReportReducer-fyTrends-filterSelected-oa`
      ) ?? [false],
      journalStatus:
        MyLocalStorage.getItem(
          `ReportReducer-fyTrends-filterSelected-journalStatus`
        ) ?? [],
    },
    useFilter: MyLocalStorage.getItem(`ReportReducer-fyTrends-useFilter`) ?? {
      journalCode: [],
      revenueModel: [],
      jpm: [],
      pdPublisher: [],
      ppPublisher: [],
      subjectGroup: [],
      businessDivision: [],
      catGroup: [],
      handlingCe: [],
      journalStatus: [],
    },
    subjectGroupTableData: [],
    businessDivisionTableData: [],
    subjectGroupShowAll: true,
    businessDivisionShowAll: true,
  },

  fyTrendsArticle: {
    // table & data
    fiscalYear: null,
    page: 1,
    pageSize: 20,
    orderByType: {
      orderBy: 'journalInformation',
      order: 'asc',
    },
    basicTableData: {
      result: [],
      count: 0,
    },
    // filterOptions: {} 不要了，直接使用fyTrends的结果
    filterSelected: {
      journal:
        MyLocalStorage.getItem(
          'ReportReducer-fyTrendsArticle-filterSelected-journal'
        ) ?? [],
      jpm:
        MyLocalStorage.getItem(
          'ReportReducer-fyTrendsArticle-filterSelected-jpm'
        ) ?? [],
      pdPublisher:
        MyLocalStorage.getItem(
          'ReportReducer-fyTrendsArticle-filterSelected-pdPublisher'
        ) ?? [],
      ppPublisher:
      MyLocalStorage.getItem(
        'ReportReducer-fyTrendsArticle-filterSelected-ppPublisher'
      ) ?? [],
      subjectGroup:
        MyLocalStorage.getItem(
          'ReportReducer-fyTrendsArticle-filterSelected-subjectGroup'
        ) ?? [],
      businessDivision:
        MyLocalStorage.getItem(
          'ReportReducer-fyTrendsArticle-filterSelected-businessDivision'
        ) ?? [],
      catGroup:
        MyLocalStorage.getItem(
          'ReportReducer-fyTrendsArticle-filterSelected-catGroup'
        ) ?? [],
      handlingCe:
        MyLocalStorage.getItem(
          'ReportReducer-fyTrendsArticle-filterSelected-handlingCe'
        ) ?? [],
      oa: MyLocalStorage.getItem(
        'ReportReducer-fyTrendsArticle-filterSelected-oa'
      ) || [false],
      journalStatus:
        MyLocalStorage.getItem(
          'ReportReducer-fyTrendsArticle-filterSelected-journalStatus'
        ) ?? [],
    },
    useFilter: MyLocalStorage.getItem(
      'ReportReducer-fyTrendsArticle-useFilter'
    ) ?? {
      // 与fyTrends做区别
      journalCode: [],
      revenueModel: [],
      jpm: [],
      pdPublisher:[],
      ppPublisher:[],
      subjectGroup: [],
      businessDivision: [],
      catGroup: [],
      handlingCe: [],
      journalStatus: [],
    },
    subjectGroupTableData: [],
    businessDivisionTableData: [],
    subjectGroupShowAll: true,
    businessDivisionShowAll: true,
  },

  //article的部分
  selectedArticleDate: null,
  //articlesOAP
  articleOAPBasicTableData: {
    result: [],
    count: 0,
    lastActionDate: '',
  },
  articleOAPSubjectTableData: {
    result: [],
    count: 0,
    lastActionDate: '',
  },
  articleOAPBusinessTableData: {
    result: [],
    count: 0,
    lastActionDate: '',
  },
  articleOAPCETableData: {
    result: [],
    count: 0,
    lastActionDate: '',
  },
  articleOAPBasicTableMonth: 'MMM',
  articleOAPSubjectTableMonth: 'MMM',
  articleOAPBusinessTableMonth: 'MMM',
  articleOAPCETableMonth: 'MMM',
  //articlesOAW
  articleOAWaiverUseBasicTableData: {
    result: [],
    count: 0,
    lastActionDate: '',
  },
  articleOAWaiverUseSubjectTableData: null,
  articleOAWaiverUseBusinessTableData: null,
  articleOAWaiverUseCETableData: null,
  articleOAWaiverUseBasicTableMonth: 'MMM',
  articleOAWaiverUseSubjectTableMonth: 'MMM',
  articleOAWaiverUseBusinessTableMonth: 'MMM',
  articleOAWaiverUseCETableMonth: 'MMM',
  //articlesOverivew 数据
  articleOverviewBasicTableData: {
    result: [],
    count: 0,
    lastActionDate: '',
  },
  articleOverviewSubjectTableData: null,
  articleOverviewBusinessTableData: null,
  articleOverviewCETableData: null,
  articleOverviewBasicTableMonth: 'MMM',
  articleOverviewSubjectTableMonth: 'MMM',
  articleOverviewBusinessTableMonth: 'MMM',
  articleOverviewCETableMonth: 'MMM',
  ArticleAnnualTrendsSubjectGroupData: null,
  ArticleAnnualTrendsBusinessDivisionData: null,
  JournalJPMDate: null,
  JournalBDDate: null,
  //Reportjournal的CE/CAT
  ReportJournalCeCatDate: null,
  ReportJournalCeCatTableData: [],
  ReportJournalCeCatMonth: null,
  ReportJournalBDData: [],
  ReportJournalBDMonth: null,
  ReportJournalJPMData: [],
  ReportJournalJPMMonth: null,
  //Reportjournal的subject group
  ReportJournalSGDate: null,
  ReportJournalSGTableData: [],
  ReportJournalSGMonth: null,
  ReportJournalOverviewMonth: null,
};

export default function ReportReducer(state = initState, action) {
  const { type, data } = action;

  switch (type) {
    case actions.GET_CUT_OFF_DATE:
      return {
        ...state,
        cutOffDate: data
      };
    case actions.JOURNAL_OVERVIEW_GET_DATE:
      return {
        ...state,
        ReportJournalOverviewMonth: data,
      };
    case actions.TAB_CHANGE: {
      // 因为没有分页需求了，所以删除了处理分页的代码
      return {
        ...state,
        currentReportTab: data,
      };
    }
    case actions.TARGET_CHANGE: {
      // 因为没有分页需求了，所以删除了处理分页的代码
      return {
        ...state,
        currentReportTarget: data,
      };
    }
    case actions.SELECT_DATE:
      return {
        ...state,
        selectDate: data,
      };
    case actions.GET_TARGETS_JOURNAL:
      return {
        ...state,
        targetJournalInfo: data,
      };
    case actions.GET_TARGETS_SG:
      return {
        ...state,
        targetSubjectGroupInfo: data,
      };
    case actions.GET_TARGETS_BD:
      return {
        ...state,
        targetBusinessDivisionInfo: data,
      };
    case actions.GET_TARGETS_CECAT:
      return {
        ...state,
        targetCECATInfo: data,
      };
    case actions.SELECT_ACQUIRED_DATE:
      return {
        ...state,
        selectedAcquireDate: data,
      };
    case actions.GET_REPORT_BASIC:
      return {
        ...state,
        basicTableData: data,
      };
    case actions.TABLE2_DATA:
      return {
        ...state,
        table2Data: data,
      };
    case actions.TABLE3_DATA:
      return {
        ...state,
        table3Data: data,
      };
      case actions.TABLE4_DATA:
      return {
        ...state,
        table4Data: data,
      };
    case actions.SELECT_OAVSTOTAL_DATE:
      return {
        ...state,
        OAvsTotalSelectDate: data,
      };
    case actions.GET_ACQUIRE_OVERVIEW_BASIC:
      return {
        ...state,
        requireOverviewBasicTableData: data.data,
        requireOverviewBasicTableMonth: data.month,
      };
    case actions.GET_ACQUIRE_OVERVIEW_SUBJECT:
      return {
        ...state,
        requireOverviewSubjectTableData: data.data,
        requireOverviewSubjectTableMonth: data.month,
      };
    case actions.GET_ACQUIRE_OVERVIEW_BUSINESS:
      return {
        ...state,
        requireOverviewBusinessTableData: data.data,
        requireOverviewBusinessTableMonth: data.month,
      };
    case actions.GET_ACQUIRE_OVERVIEW_CE:
      return {
        ...state,
        requireOverviewCETableData: data.data,
        requireOverviewCETableMonth: data.month,
      };
    case actions.FY_CHANGE_FISCAL_YEAR:
      return {
        ...state,
        fyTrends: {
          ...state.fyTrends,
          fiscalYear: data,
        },
      };
    case actions.FY_GET_BASIC_DATA:
      return {
        ...state,
        fyTrends: {
          ...state.fyTrends,
          basicTableData: data,
        },
      };
    case actions.FY_GET_SUBJECT_GROUP_DATA:
      return {
        ...state,
        fyTrends: {
          ...state.fyTrends,
          subjectGroupTableData: data,
        },
      };
    case actions.FY_GET_BUSINESS_DATA:
      return {
        ...state,
        fyTrends: {
          ...state.fyTrends,
          businessDivisionTableData: data,
        },
      };
    case actions.FY_CHANGE_PAGE:
      return {
        ...state,
        fyTrends: {
          ...state.fyTrends,
          page: data,
        },
      };
    case actions.FY_ORDER_BY:
      return {
        ...state,
        fyTrends: {
          ...state.fyTrends,
          orderByType: data,
        },
      };
    case actions.FY_CHANGE_PAGE_SIZE:
      return {
        ...state,
        fyTrends: {
          ...state.fyTrends,
          pageSize: data,
        },
      };
    case actions.FY_CHANGE_SUBJECT_SHOW_ALL:
      return {
        ...state,
        fyTrends: {
          ...state.fyTrends,
          subjectGroupShowAll: data,
        },
      };
    case actions.FY_CHANGE_BUSINESS_SHOW_ALL:
      return {
        ...state,
        fyTrends: {
          ...state.fyTrends,
          businessDivisionShowAll: data,
        },
      };
    case actions.FY_FILTER_SELECT:
      MyLocalStorage.setItem(
        `ReportReducer-fyTrends-filterSelected-${data.targetId}`,
        data.data
      );
      return {
        ...state,
        fyTrends: {
          ...state.fyTrends,
          filterSelected: {
            ...state.fyTrends.filterSelected,
            [data.targetId]: data.data,
          },
        },
      };
    case actions.FY_FILTER_APPLY:
      MyLocalStorage.setItem(`ReportReducer-fyTrends-useFilter`, data);
      return {
        ...state,
        fyTrends: {
          ...state.fyTrends,
          useFilter: data,
        },
      };
    case actions.FY_FILTER_CLEAR:
      MyLocalStorage.removeItem(
        `ReportReducer-fyTrends-filterSelected-journal`
      );
      MyLocalStorage.removeItem(`ReportReducer-fyTrends-filterSelected-jpm`);
      MyLocalStorage.removeItem(`ReportReducer-fyTrends-filterSelected-pdPublisher`);
      MyLocalStorage.removeItem(`ReportReducer-fyTrends-filterSelected-ppPublisher`);
      MyLocalStorage.removeItem(
        `ReportReducer-fyTrends-filterSelected-subjectGroup`
      );
      MyLocalStorage.removeItem(
        `ReportReducer-fyTrends-filterSelected-businessDivision`
      );
      MyLocalStorage.removeItem(
        `ReportReducer-fyTrends-filterSelected-catGroup`
      );
      MyLocalStorage.removeItem(
        `ReportReducer-fyTrends-filterSelected-handlingCe`
      );
      MyLocalStorage.removeItem(`ReportReducer-fyTrends-filterSelected-oa`);
      MyLocalStorage.removeItem(
        `ReportReducer-fyTrends-filterSelected-journalStatus`
      );

      return {
        ...state,
        fyTrends: {
          ...state.fyTrends,
          filterSelected: {
            journal: [],
            jpm: [],
            pdPublisher: [],
            ppPublisher: [],
            subjectGroup: [],
            businessDivision: [],
            catGroup: [],
            handlingCe: [],
            journalStatus: [],
            oa: [false],
          },
          useFilter: {
            journalCode: [],
            revenueModel: [],
            jpm: [],
            pdPublisher: [],
            ppPublisher: [],
            subjectGroup: [],
            businessDivision: [],
            catGroup: [],
            handlingCe: [],
            journalStatus: [],
          },
        },
      };
    // FY Trends in Articles
    case actionsArticle.FY_ARTICLE_CHANGE_FISCAL_YEAR:
      return {
        ...state,
        fyTrendsArticle: {
          ...state.fyTrendsArticle,
          fiscalYear: data,
        },
      };
    case actionsArticle.FY_ARTICLE_GET_BASIC_DATA:
      return {
        ...state,
        fyTrendsArticle: {
          ...state.fyTrendsArticle,
          basicTableData: data,
        },
      };
    case actionsArticle.FY_ARTICLE_GET_SUBJECT_GROUP_DATA:
      return {
        ...state,
        fyTrendsArticle: {
          ...state.fyTrendsArticle,
          subjectGroupTableData: data,
        },
      };
    case actionsArticle.FY_ARTICLE_GET_BUSINESS_DATA:
      return {
        ...state,
        fyTrendsArticle: {
          ...state.fyTrendsArticle,
          businessDivisionTableData: data,
        },
      };
    case actionsArticle.FY_ARTICLE_CHANGE_PAGE:
      return {
        ...state,
        fyTrendsArticle: {
          ...state.fyTrendsArticle,
          page: data,
        },
      };
    case actionsArticle.FY_ARTICLE_ORDER_BY:
      return {
        ...state,
        fyTrendsArticle: {
          ...state.fyTrendsArticle,
          orderByType: data,
        },
      };
    case actionsArticle.FY_ARTICLE_CHANGE_PAGE_SIZE:
      return {
        ...state,
        fyTrendsArticle: {
          ...state.fyTrendsArticle,
          pageSize: data,
        },
      };
    case actionsArticle.FY_ARTICLE_CHANGE_SUBJECT_SHOW_ALL:
      return {
        ...state,
        fyTrendsArticle: {
          ...state.fyTrendsArticle,
          subjectGroupShowAll: data,
        },
      };
    case actionsArticle.FY_ARTICLE_CHANGE_BUSINESS_SHOW_ALL:
      return {
        ...state,
        fyTrendsArticle: {
          ...state.fyTrendsArticle,
          businessDivisionShowAll: data,
        },
      };
    case actionsArticle.FY_ARTICLE_FILTER_SELECT:
      MyLocalStorage.setItem(
        `ReportReducer-fyTrendsArticle-filterSelected-${data.targetId}`,
        data.data
      );
      return {
        ...state,
        fyTrendsArticle: {
          ...state.fyTrendsArticle,
          filterSelected: {
            ...state.fyTrendsArticle.filterSelected,
            [data.targetId]: data.data,
          },
        },
      };
    case actionsArticle.FY_ARTICLE_FILTER_APPLY:
      MyLocalStorage.setItem('ReportReducer-fyTrendsArticle-useFilter', data);
      return {
        ...state,
        fyTrendsArticle: {
          ...state.fyTrendsArticle,
          useFilter: data,
        },
      };
    case actionsArticle.FY_ARTICLE_FILTER_CLEAR:
      MyLocalStorage.removeItem(
        'ReportReducer-fyTrendsArticle-filterSelected-journal'
      );
      MyLocalStorage.removeItem(
        'ReportReducer-fyTrendsArticle-filterSelected-jpm'
      );
      MyLocalStorage.removeItem(
        'ReportReducer-fyTrendsArticle-filterSelected-pdPublisher'
      );
      MyLocalStorage.removeItem(
        'ReportReducer-fyTrendsArticle-filterSelected-ppPublisher'
      );
      MyLocalStorage.removeItem(
        'ReportReducer-fyTrendsArticle-filterSelected-subjectGroup'
      );
      MyLocalStorage.removeItem(
        'ReportReducer-fyTrendsArticle-filterSelected-businessDivision'
      );
      MyLocalStorage.removeItem(
        'ReportReducer-fyTrendsArticle-filterSelected-catGroup'
      );
      MyLocalStorage.removeItem(
        'ReportReducer-fyTrendsArticle-filterSelected-handlingCe'
      );
      MyLocalStorage.removeItem(
        'ReportReducer-fyTrendsArticle-filterSelected-oa'
      );
      MyLocalStorage.removeItem(
        'ReportReducer-fyTrendsArticle-filterSelected-journalStatus'
      );

      return {
        ...state,
        fyTrendsArticle: {
          ...state.fyTrendsArticle,
          filterSelected: {
            journal: [],
            jpm: [],
            pdPublisher: [],
            ppPublisher: [],
            subjectGroup: [],
            businessDivision: [],
            catGroup: [],
            handlingCe: [],
            oa: [false],
            journalStatus: [],
          },
          useFilter: {
            // 与fyTrends做区别
            journalCode: [],
            revenueModel: [],
            jpm: [],
            pdPublisher:[],
            ppPublisher:[],
            subjectGroup: [],
            businessDivision: [],
            catGroup: [],
            handlingCe: [],
            journalStatus: [],
          },
        },
      };
    case actions.GET_OAVSTOTAL_ALL_SUBJECTGROUP:
      return {
        ...state,
        OAvsTotalSubjectGroupResult: data.data,
        OAvsTotalSubjectGroupMonth: data.month,
      };
    case actions.GET_OAVSTOTAL_ALL_BUSINESSDIVISION:
      return {
        ...state,
        OAvsTotalBusinessDivisionResult: data.data,
        OAvsTotalBusinessDivisionMonth: data.month,
      };
    case actions.SELECT_ANNUALTRENDS_DATE:
      return {
        ...state,
        AnnualTrendsDate: data,
      };
    case actions.ANNUALTRENDS_SUBJECTGRUOP_DATA:
      return {
        ...state,
        AnnualTrendsSubjectGroupData: data,
      };
    case actions.ANNUALTRENDS_BUSINESS_DATA:
      return {
        ...state,
        AnnualTrendsBusinessDivisionData: data,
      };
    case actions.EXPORT_REPORT:
      return {
        ...state,
        exportReportResult: data,
      };
    case actions.GET_ARTICLE_LAST_ACTION_DATE:
      return {
        ...state,
        ArticleReportGenenateDate: data.data,
      };
    case actions.GET_ARTICLE_LAST_ACTION_DATE_FY:
      return {
        ...state,
        ArticleReportGenenateDateFy: data.data,
      };
    case actions.GET_ACQUIRED_ACTIVE_LAST_ACTION_DATE:
      return {
        ...state,
        AcquiredActiveReportGenenateDate: data.data,
      };
    case actions.GET_ACQUIRED_ACTIVE_LAST_ACTION_DATE_FY:
      return {
        ...state,
        AcquiredActiveReportGenenateDateFy: data.data,
      };
    case actions.GET_JOURNAL_LAST_ACTION_DATE:
      return {
        ...state,
        JournalReportGenenateDate: data.data,
      };
    //OAP
    case actions.GET_ARTICLE_OAP_BASIC:
      return {
        ...state,
        articleOAPBasicTableData: data.data,
        articleOAPBasicTableMonth: data.month,
      };
    case actions.GET_ARTICLE_OAP_SUBJECT:
      return {
        ...state,
        articleOAPSubjectTableData: data.data,
        articleOAPSubjectTableMonth: data.month,
      };
    case actions.GET_ARTICLE_OAP_BUSINESS:
      return {
        ...state,
        articleOAPBusinessTableData: data.data,
        articleOAPBusinessTableMonth: data.month,
      };
    case actions.GET_ARTICLE_OAP_CE:
      return {
        ...state,
        articleOAPCETableData: data.data,
        articleOAPCETableMonth: data.month,
      };
    //OAW
    case actions.GET_ARTICLE_OAWAIVERUSE_BASIC:
      return {
        ...state,
        articleOAWaiverUseBasicTableData: data.data,
        articleOAWaiverUseBasicTableMonth: data.month,
      };
    case actions.GET_ARTICLE_OAWAIVERUSE_SUBJECT:
      return {
        ...state,
        articleOAWaiverUseSubjectTableData: data.data,
        articleOAWaiverUseSubjectTableMonth: data.month,
      };
    case actions.GET_ARTICLE_OAWAIVERUSE_BUSINESS:
      return {
        ...state,
        articleOAWaiverUseBusinessTableData: data.data,
        articleOAWaiverUseBusinessTableMonth: data.month,
      };
    case actions.GET_ARTICLE_OAWAIVERUSE_CE:
      return {
        ...state,
        articleOAWaiverUseCETableData: data.data,
        articleOAWaiverUseCETableMonth: data.month,
      };
    case actions.GET_ARTICLE_OVERVIEW_BASIC:
      return {
        ...state,
        articleOverviewBasicTableData: data.data,
        articleOverviewBasicTableMonth: data.month,
      };

    case actions.GET_ARTICLE_OVERVIEW_SUBJECT:
      return {
        ...state,
        articleOverviewSubjectTableData: data.data,
        articleOverviewSubjectTableMonth: data.month,
      };
    case actions.GET_ARTICLE_OVERVIEW_BUSINESS:
      return {
        ...state,
        articleOverviewBusinessTableData: data.data,
        articleOverviewBusinessTableMonth: data.month,
      };
    case actions.GET_ARTICLE_OVERVIEW_CE:
      return {
        ...state,
        articleOverviewCETableData: data.data,
        articleOverviewCETableMonth: data.month,
      };
    case actions.SELECT_ARTICLE_OVERVIEW_DATE:
      return {
        ...state,
        selectedArticleDate: data,
      };

    case actions.ARTICLES_OA_VS_TOTAL_DATE:
      return {
        ...state,
        ArticlesOAvsTotalDate: data,
      };
    case actions.ARTICLES_ANNUAL_TRENDS_DATE:
      return {
        ...state,
        ArticleAnnualTrendsDate: data,
      };
    case actions.GET_ARTICLE_ANNUAL_TRENDS_SUBJECT_GROUP:
      return {
        ...state,
        ArticleAnnualTrendsSubjectGroupData: data,
      };
    case actions.GET_ARTICLE_ANNUAL_TRENDS_BUSINESS_DIVISION:
      return {
        ...state,
        ArticleAnnualTrendsBusinessDivisionData: data,
      };
    case actions.GET_ARTICLES_OA_VS_TOTAL_SG_DATA:
      return {
        ...state,
        ArticlesOAvsTotalSGData: data,
      };
    case actions.GET_ARTICLES_OA_VS_TOTAL_BD_DATA:
      return {
        ...state,
        ArticlesOAvsTotalBDData: data,
      };
    case actions.SELECT_JOURNAL_JPM_DATE:
      return {
        ...state,
        JournalJPMDate: data,
      };
    case actions.SELECT_JOURNAL_BD_DATE:
      return {
        ...state,
        JournalBDDate: data,
      };
    case actionsJournal.JOURNAL_CE_CAT_GET_DATE:
      return {
        ...state,
        ReportJournalCeCatDate: data,
      };
    case actionsJournal.JOURNAL_CE_CAT_DATA:
      return {
        ...state,
        ReportJournalCeCatTableData: data.data,
        ReportJournalCeCatMonth: data.month,
      };
    case actions.GET_JOURNAL_BUSINESS_DIVISION:
      return {
        ...state,
        ReportJournalBDData: data === 'error' ? [] : data.data,
        ReportJournalBDMonth: data === 'error' ? [] : data.month,
      };
    case actions.GET_JOURNAL_JPM:
      return {
        ...state,
        ReportJournalJPMData: data === 'error' ? [] : data.data,
        ReportJournalJPMMonth: data === 'error' ? '' : data.month,
      };
    case actionsJournal.JOURNAL_SUBJECT_GROUP_GET_DATE:
      return {
        ...state,
        ReportJournalSGDate: data,
      };
    case actionsJournal.JOURNAL_SUBJECT_GROUP_DATA:
      return {
        ...state,
        ReportJournalSGTableData: data.data,
        ReportJournalSGMonth: data.month,
      };
    default:
      return state;
  }
}

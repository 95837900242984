import {axios_instance} from "@/utils/axios_instance";
import {
    GET_SI_PIPELINE_CE_CHARTDATA,
    REQUEST_CHART_DATA,
    REQUEST_CHART_DATA_FAIL,
    REQUEST_CHART_DATA_SUCCESS
} from "@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import getAuth from "@/utils/getAuth";
import { formatDate } from "@/utils/date_string";

export function GetAAAnualTrendsChartData(
    url = `/reports/acquired-active/diagram/annual-trends/sg/`,
    title,
    selectedDate,
    type = 'acquisition'
) {


    const date = formatDate(selectedDate);

    return async dispatch => {
        try {
            const res = await axios_instance.get(`${url}${date}`, {
                params: {
                    type: type,
                },
                headers: {
                    authorization: getAuth(),
                },
            });

            if (res.data.code === 200) {
                dispatch({
                    type: GET_SI_PIPELINE_CE_CHARTDATA,
                    data: {[title]: res.data.data},
                });
            } else {
                dispatch({
                    type: GET_SI_PIPELINE_CE_CHARTDATA,
                    data: {[title]: null},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_SI_PIPELINE_CE_CHARTDATA,
                data: {[title]: null},
            });
        }
    };
} // new
export function GetAAAnualTrendsChartDataWithStatus(
    url = `/reports/acquired-active/diagram/annual-trends/sg/`,
    title,
    selectedDate,
    type = 'acquisition'
) {
    const date = formatDate(selectedDate);

    return async dispatch => {
        dispatch({
            type: REQUEST_CHART_DATA,
            data: title,
        });

        try {
            const res = await axios_instance.get(`${url}${date}`, {
                params: {
                    type: type,
                },
                headers: {
                    authorization: getAuth(),
                },
            });

            if (res.data.code === 200) {
                // console.log('===REQUEST_CHART_DATA_SUCCESS===');
                const formattedData = res.data.data;
                dispatch({
                    type: REQUEST_CHART_DATA_SUCCESS,
                    data: {title, value: formattedData},
                });
            } else {
                throw new Error(res.data.message);
            }
        } catch (err) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToLoad',
                    {
                        value: ' due to ' + err.message,
                    },
                    SEVERITIES.error
                )
            );

            dispatch({
                type: REQUEST_CHART_DATA_FAIL,
                data: {title, error: err.message},
            });
        }
    };
}
import {axios_instance, axios_instance_fileDownload} from "@/utils/axios_instance";
import {
    downloadFile,
    EXPORT_SI_ERROR,
    EXPORT_SI_LIST,
    EXPORT_SI_STATUS,
    GET_SPECIAL_ISSUE_CODES
} from "@/actions/SIMT-SI/SpecialIssue/SIAction";
import moment from "moment/moment";

const DEFAULT_DATE_FROM = ['1970-01-01T00:00:00.000Z'];
const DEFAULT_DATE_TO = ['2100-01-01T00:00:00.000Z'];


export function getSIDataIds(filters = {}) {
    const pageSize = 100;
    let filter2 = filters ?? {}; // `filters` might be a null value, we will change it to an empty obj instead
    if (
        filter2.acquiredDateFrom !== undefined &&
        filter2.acquiredDateFrom !== null &&
        filter2.acquiredDateFrom !== [null] &&
        filter2.acquiredDateTo !== undefined &&
        filter2.acquiredDateTo !== null &&
        filter2.acquiredDateTo !== [null]
    ) {
        if (
            filter2.acquiredDateFrom.length > 0 &&
            filter2.acquiredDateTo.length === 0
        ) {
            filter2.acquiredDateTo = [DEFAULT_DATE_TO];
        }
        if (
            filter2.acquiredDateFrom.length === 0 &&
            filter2.acquiredDateTo.length > 0
        ) {
            filter2.acquiredDateFrom = [DEFAULT_DATE_FROM];
        }
    }
    if (
        filter2.submissionDeadlineFrom !== undefined &&
        filter2.submissionDeadlineFrom !== null &&
        filter2.submissionDeadlineTo !== undefined &&
        filter2.submissionDeadlineTo !== null
    ) {
        if (
            filter2.submissionDeadlineFrom.length > 0 &&
            filter2.submissionDeadlineTo.length === 0
        ) {
            filter2.submissionDeadlineTo = [DEFAULT_DATE_TO];
        }
        if (
            filter2.submissionDeadlineFrom.length === 0 &&
            filter2.submissionDeadlineTo.length > 0
        ) {
            filter2.submissionDeadlineFrom = [DEFAULT_DATE_FROM];
        }
    }
    return async dispatch => {
        try {
            let res = await axios_instance.post(
                '/api/v1/si-service/special-issues/codes',
                filter2,
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            const {code,data} = res.data;
            if (code === 200) {
                const siCodeListResult = data;
                dispatch({
                    type: GET_SPECIAL_ISSUE_CODES,
                    data: siCodeListResult,
                });
            } else {
                dispatch({
                    type: GET_SPECIAL_ISSUE_CODES,
                    data: [],
                });
            }
        } catch (err) {
            dispatch({
                type: GET_SPECIAL_ISSUE_CODES,
                data: [],
            });
        }
    };
}

/**
 * @description CT-259导出所有的SI
 * @param {string} fromDate 导出的开始时间
 * @param {string} toDate 导出的结束时间
 * @param {string} fileType 导出文件类型 csv/excel
 * @returns
 */
export function getAllSIExport(
    fromDate,
    toDate,
    start,
    end,
    fileType,
    filename = ''
) {
    // The specific replacement of the colon can be changed by the operating system
    if (filename == '') {
        filename = `SIdata-${moment()
            .utc()
            .format('YYYY-MM-DD HH:mm:ss')}.${fileType}`;
    }
    let url = '/api/v1/si-service/special-issues/export';
    if (fileType === 'xls') {
        fileType = 'excel';
    } else {
        fileType = 'csv';
    }
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.post(url
                , 
                {
                    startDate: fromDate,
                    endDate: toDate,
                    start: start,
                    end: end,
                    fileType: fileType,
                }
                ,{
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    authorization: localStorage.getItem('authorization'),
                },
            });
            downloadFile(res.data, filename);
            dispatch({type: EXPORT_SI_STATUS, data: true});
        } catch (err) {
            dispatch({type: EXPORT_SI_STATUS, data: false});
        }
    };
}

export function getSIExportRange(fromDate, toDate) {
    let url = '/api/v1/si-service/special-issues/export';
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.post(url,
                {
                        startDate: fromDate,
                        endDate: toDate,
            },  {
                
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const {code,data} = res.data;
            if (code === 200) {
                dispatch({type: EXPORT_SI_LIST, data: data});
            } else {
                dispatch({type: EXPORT_SI_ERROR, data: code});
                dispatch({type: EXPORT_SI_LIST, data: false});
            }
        } catch (err) {
            dispatch({type: EXPORT_SI_LIST, data: false});
        }
    };
}

export function exportCFPDataExcel(siCode) {
    var filename =
        siCode + `_CfP Data_${moment().utc().format('YYMMDD_HHmmss')}.xlsx`;
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.post(`/api/v1/si-service/special-issues/${siCode}/marketing-data/export`, null,{
                headers: {
                    authorization: localStorage.getItem('authorization')
                },
                responseType: 'blob'
            });
            downloadFile(res.data, filename);
            dispatch({type: EXPORT_SI_STATUS, data: true});
        } catch (err) {
            dispatch({type: EXPORT_SI_STATUS, data: false});
        }
    };
}
import { useEffect, useState } from 'react';
import { Button, Box, Stack } from '@mui/material';
import ReactivatedContainer from './ReactivatedContainer';
import SIModal from '../../../../modules/Modal/Deprecation/SIModal';
import {
  createSIClear,
} from '../../../../actions/SIMT-SI/SpecialIssue/SIAction';
import EditAdminInputLabel from '../../../SuperAdminManage/edit-admin/EditAdminInputLabel';
import { useDispatch, useSelector } from 'react-redux';
import { openConfirm, setTextParam, setEditType } from '@/actions/SIMT-User/Alert/AlertAction';
import SIModalPro from '../../../../modules/Modal/SIModal';
import { useNavigate } from 'react-router-dom';
import {getSIDetail} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueDetail";

function ReactivatedBox(props) {
  const { open, handlereactivatedBoxClose, siId } = props;
  const [isUpdata, setIsUpdata] = useState(false);
  const [isError, setIsError] = useState(false);
  const [reactiveReason, setReactiveReason] = useState('');
  const dispatch = useDispatch();
  const nevigate = useNavigate();

  const selector = state => {
    return {
      SIInfoResult: state.SI.detailInfo,
      SIUpdateResult: state.SI.updateSIResult,
      siCodeToSkip: state.SI.siCodeToSkip,
    };
  };
  const { SIInfoResult, SIUpdateResult, siCodeToSkip } = useSelector(selector);

  useEffect(() => {
    if (SIUpdateResult !== '') {
      if (SIUpdateResult !== 'error') {
        handlereactivatedBoxClose();
        dispatch(createSIClear());
        dispatch(
          setTextParam(SIInfoResult.generalInformation[0].specialIssueTitle)
        );
        dispatch(setEditType('updateSI'));
        dispatch(openConfirm());
      } else {
        handlereactivatedBoxClose();
        dispatch(createSIClear());
        dispatch(setTextParam());
        dispatch(setEditType('error'));
        dispatch(openConfirm());
      }
    }
  }, [
    SIInfoResult.generalInformation,
    SIUpdateResult,
    dispatch,
    handlereactivatedBoxClose,
  ]);

  const fetchData = async siCode => {
    setTimeout(() => {
      dispatch(getSIDetail(siCode));
    }, 1000);
  };

  //跳转逻辑，可能需要优化
  useEffect(() => {
    if (siCodeToSkip) {
      setTimeout(() => {
        nevigate(`/simt/auth/siRelated/si/detail/${siCodeToSkip}`);
      }, 2000);
    }
  }, [siCodeToSkip]);

  const upDataSI = async () => {
    // remove Reactive Button(which show when stage = 'Shelved' & the user with 'admin:write' permission) in ct-6353
    // await dispatch(ReactiveSI(siId, reactiveReason));
    await fetchData(siId);
  };
  const handleUpdataReactivatedBox = () => {
    setIsUpdata(true);
    if (!isError) {
      upDataSI();
      handlereactivatedBoxClose();
    }
  };

  const UpdateButton = (
    <Button
      id='reactiveSIbutton'
      data-selenium-id='SIDetail_ReactivateBox-UpdateBtn'
      onClick={handleUpdataReactivatedBox}
      style={{ textTransform: 'capitalize' }}
      variant='contained'
      disabled={false}
      sx={{
        padding: '8px 18px',
        fontWeight: 600,
        lineHeight: '20px',
        color: '#f1f3f5',
        boxShadow: 'none',
      }}
    >
      Create
    </Button>
  );

  const container = (
    <Stack
      sx={{ mt: '20px' }}
      data-selenium-id='SIDetail_ReactivateBox-ContainerStack'
    >
      <EditAdminInputLabel
        text='Reason'
        starSx={{
          ml: '10px',
          fontSize: '12px',
          lineHeight: '18px',
          fontWeight: 600,
        }}
        textSx={{ fontSize: '14px', lineHeight: '18px', fontWeight: 400 }}
        data-selenium-id='SIDetail_ReactivateBox-EditAdminInputLabel'
      />
      <ReactivatedContainer
        isUpdata={isUpdata}
        setIsUpdata={setIsUpdata}
        setValue={setReactiveReason}
        setError={setIsError}
        data-selenium-id='SIDetail_ReactivedContainer'
      ></ReactivatedContainer>
      <Stack
        className='SIContrainBlock'
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-end'
        data-selenium-id='SIDetail_ReactivateBox-CancelBtnContainerStack'
      >
        <Button
          style={{
            textTransform: 'capitalize',
            color: '#154AB6',
            width: '82px',
            marginRight: '10px',
            fontWeight: 600,
            letterSpacing: '0.01em',
            lineHeight: '20px',
            padding: '8px 18px',
          }}
          id='CancelButton1'
          data-selenium-id='SIDetail_ReactivateBox-CancelBtn1'
          onClick={handlereactivatedBoxClose}
        >
          Cancel
        </Button>
        {UpdateButton}
      </Stack>
    </Stack>
  );

  return (
    <div data-selenium-id='SIDetail_ReactivateBox-Container'>
      <SIModalPro
        title='Reactivate Reason'
        open={open}
        handleClose={handlereactivatedBoxClose}
        handleConfirmOpen={handleUpdataReactivatedBox}
        buttonTxt='Update'
        children={container}
        showButton={false}
        boxSx={{ height: 520, width: 700, padding: '32px 35px 20px 35px' }}
        data-selenium-id='SI_SizableModal-Modal'
      ></SIModalPro>
    </div>
  );
}
export default ReactivatedBox;

export const MONTH = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const ARTICLE = 'Article';
export const FY_TRENDS_OF_SI_ACQUISITION = 'FY Trends of SI Acquisition';
export const FY_TRENDS_OF_ACTIVE_SI = 'FY Trends of Active SI';
export const SUBJECT_GROUP = 'Subject Group';
export const BUSINESS_DIVISION = 'Business Division';

export const FY_TRENDS_IN_SUBJECT_GROUP_LEVEL =
  'FY Trends in Subject Group Level';
export const FY_TRENDS_IN_BUSINESS_DIVISION_LEVEL =
  'FY Trends in Business Division Level';
export const SUBMISSION = 'Submission';
export const ACCEPTANCE = 'Acceptance';
export const PUBLICATION = 'Publication';
import lineIconSubOa from '@/assets/ReportDiagrams/Articles/FYTrends/lineIcon/subOa.png';
import lineIconAccOa from '@/assets/ReportDiagrams/Articles/FYTrends/lineIcon/accOa.png';
import lineIconPubOa from '@/assets/ReportDiagrams/Articles/FYTrends/lineIcon/pubOa.png';
import lineIconOo from '@/assets/ReportDiagrams/Articles/FYTrends/lineIcon/oo.png';
import legendIconSubOa from '@/assets/ReportDiagrams/Articles/FYTrends/legendIcon/subOa.png';
import legendIconAccOa from '@/assets/ReportDiagrams/Articles/FYTrends/legendIcon/accOa.png';
import legendIconPubOa from '@/assets/ReportDiagrams/Articles/FYTrends/legendIcon/pubOa.png';
import legendIconOo from '@/assets/ReportDiagrams/Articles/FYTrends/legendIcon/oo.png';

export const EXCEL_HEADER = {
  [SUBMISSION]: {
    exportHeader: [
      '@{sub}MAY',
      '@{sub}JUN',
      '@{sub}JUL',
      '@{sub}AUG',
      '@{sub}SEP',
      '@{sub}OCT',
      '@{sub}NOV',
      '@{sub}DEC',
      '@{sub}JAN',
      '@{sub}FEB',
      '@{sub}MAR',
      '@{sub}APR',
      '@{sub}Total',
      '@{sub}MAY@OA',
      '@{sub}JUN@OA',
      '@{sub}JUL@OA',
      '@{sub}AUG@OA',
      '@{sub}SEP@OA',
      '@{sub}OCT@OA',
      '@{sub}NOV@OA',
      '@{sub}DEC@OA',
      '@{sub}JAN@OA',
      '@{sub}FEB@OA',
      '@{sub}MAR@OA',
      '@{sub}APR@OA',
      '@{sub}Total@OA',
    ],
    partHeader: ['Submission (All Journals)', 'Submission (OA Journals)'],
  },
  [ACCEPTANCE]: {
    exportHeader: [
      '@{acc}MAY',
      '@{acc}JUN',
      '@{acc}JUL',
      '@{acc}AUG',
      '@{acc}SEP',
      '@{acc}OCT',
      '@{acc}NOV',
      '@{acc}DEC',
      '@{acc}JAN',
      '@{acc}FEB',
      '@{acc}MAR',
      '@{acc}APR',
      '@{acc}Total',
      '@{acc}MAY@OA',
      '@{acc}JUN@OA',
      '@{acc}JUL@OA',
      '@{acc}AUG@OA',
      '@{acc}SEP@OA',
      '@{acc}OCT@OA',
      '@{acc}NOV@OA',
      '@{acc}DEC@OA',
      '@{acc}JAN@OA',
      '@{acc}FEB@OA',
      '@{acc}MAR@OA',
      '@{acc}APR@OA',
      '@{acc}Total@OA',
    ],
    partHeader: ['Acceptance (All Journals)', 'Acceptance (OA Journals)'],
  },
  [PUBLICATION]: {
    exportHeader: [
      '@{pub}MAY',
      '@{pub}JUN',
      '@{pub}JUL',
      '@{pub}AUG',
      '@{pub}SEP',
      '@{pub}OCT',
      '@{pub}NOV',
      '@{pub}DEC',
      '@{pub}JAN',
      '@{pub}FEB',
      '@{pub}MAR',
      '@{pub}APR',
      '@{pub}Total',
      '@{pub}MAY@OA',
      '@{pub}JUN@OA',
      '@{pub}JUL@OA',
      '@{pub}AUG@OA',
      '@{pub}SEP@OA',
      '@{pub}OCT@OA',
      '@{pub}NOV@OA',
      '@{pub}DEC@OA',
      '@{pub}JAN@OA',
      '@{pub}FEB@OA',
      '@{pub}MAR@OA',
      '@{pub}APR@OA',
      '@{pub}Total@OA',
    ],
    partHeader: ['Publication (All Journals)', 'Publication (OA Journals)'],
  },
};

export const BAR_COLORS = {
  subjectGroup: {
    submission: {
      oa: '#FFDDDD',
      borderOA: '#FF8180',
      lineOA: '#FF8180',
      lineIconOA: lineIconSubOa,
      legendIconOA: legendIconSubOa,
      oo: '#DFE4E8',
      borderOO: '#98A7B6',
      lineOO: '#596A7C',
      lineIconOO: lineIconOo,
      legendIconOO: legendIconOo,
    },
    acceptance: {
      oa: '#FFE8C5',
      borderOA: '#FFB152',
      lineOA: '#FFB152',
      lineIconOA: lineIconAccOa,
      legendIconOA: legendIconAccOa,
      oo: '#DFE4E8',
      borderOO: '#98A7B6',
      lineOO: '#596A7C',
      lineIconOO: lineIconOo,
      legendIconOO: legendIconOo,
    },
    publication: {
      oa: '#CEDDF9',
      borderOA: '#4C81EB',
      lineOA: '#4C81EB',
      lineIconOA: lineIconPubOa,
      legendIconOA: legendIconPubOa,
      oo: '#DFE4E8',
      borderOO: '#98A7B6',
      lineOO: '#596A7C',
      lineIconOO: lineIconOo,
      legendIconOO: legendIconOo,
    },
  },
  businessDivision: {
    submission: {
      oa: '#FF8180',
      oo: '#98A7B6',
    },
    acceptance: {
      oa: '#FFB152',
      oo: '#98A7B6',
    },
    publication: {
      oa: '#4C81EB',
      oo: '#98A7B6',
    },
  },
};

import Footer from '@/components/Footer';
import Header from '@/components/Header';
import { SectionTitleBlock } from '@/pages/SIPPage/RevisePage/CustomComponents/CustomBlock';
import {
  Box,
  CircularProgress,
  Collapse,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  GEInformationCheckItemBlock,
  TranformToSiItemBlock,
} from './CustomBlock';
// @ts-ignore
import DiffRedCircle from '@/assets/SIPPage/TransformToSI/DiffRedCircle2.svg';
import { AcquiredSiCreationHeading } from './Typography';

/**
 *
 * @param {import("./CustomContainer").TransformToSiPageContainerProps} props
 * @returns
 */
export const TransformToSiPageContainer = props => {
  const { bottomStickyEle, children } = props;

  return (
    <>
      <Header
        data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-Header'
        sx={{
          '& .MuiToolbar-root': {
            paddingX: '32px',
          },
        }}
      />
      <Stack
        marginTop={'56px'}
        minHeight={'calc(100vh - 56px)'}
        data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-Stack'
      >
        <Stack
          sx={{ flexGrow: 1, backgroundColor: '#fff' }}
          data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-ChildrenStack'
        >
          {children}
        </Stack>
        {bottomStickyEle}
        <Footer
          sx={{ borderColor: '#1e48b4' }}
          data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-Footer'
        />
      </Stack>
    </>
  );
};

/**
 *
 * @param {import("./CustomContainer").GEDiffContainerProps} props
 * @returns
 */
export const GEDiffContainer = props => {
  const { index, children, isDifferent } = props;
  const [geSectionFold, setGeSectionFold] = useState(true);
  const handleToggleSectionFold = () => {
    setGeSectionFold(current => !current);
  };

  useEffect(() => {
    if (isDifferent) return;

    const timer = setTimeout(() => {
      setGeSectionFold(false);
    }, 500);

    return () => {
      if (isDifferent) return;
      clearTimeout(timer);
    };
  }, [isDifferent]);

  return (
    <Box data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-GEDiff-Box'>
      <Grid
        container
        rowSpacing={'39px'} //modified by HJX
        
        data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-GEDiff-Grid'
      >
        <Grid item sx={{width:'514px'}}>
          <SectionTitleBlock
            data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-GEDiff-Grid-SectionTitleBlock1'
            sectionTitle={`SIP GE ${index}`}
            sectionFold={geSectionFold}
            handleToggleSectionFold={handleToggleSectionFold}
          />
        </Grid>
        <Grid item sx={{marginLeft:'44px',width:'514px'}}>
          <SectionTitleBlock
            data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-GEDiff-Grid-SectionTitleBlock2'
            sectionTitle={`SIMT GE ${index}`}
            sectionFold={geSectionFold}
            handleToggleSectionFold={handleToggleSectionFold}
            endAdornment={
              <span
                style={{
                  opacity: isDifferent ? 1 : 0,
                  marginLeft: '8px',
                  transition: 'all 1s',
                  transform: 'translateY(2px)', //modified by HJX
                }}
              >
                <DiffRedCircle />
              </span>
            }
          />
        </Grid>
      </Grid>
      <Collapse
        in={geSectionFold}
        timeout={500}
        data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-GEDiff-Collapse'
      >
        <Box
          paddingTop={'24px'}
          data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-GEDiff-Collapse-Box'
        >
          <Grid
            container
            rowSpacing={'21px'} //modified by HJX
            // columnSpacing={'44px'}
            data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-GEDiff-Collapse-Grid'
          >
            {children &&
              children.map((item, index) => {
                return (
                  <Grid
                    item
                    key={index}
                    sx={{marginLeft:index % 2===0 ?'0px':'44px'}}
                    data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-GEDiff-Collapse-ItemGrid'
                  >
                    {item}
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
};

/**
 *
 * @param {import("./CustomContainer").TransformToSiStepperContainerProps} props
 * @returns
 */
export const TransformToSiStepperContainer = props => {
  const { children } = props;
  const minWidth = '988'; //modified by HJX
  const matches750 = useMediaQuery(`(min-width:${minWidth}px)`);
  return (
    <Stack
      data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-Stepper-Stack'
      direction='row'
      sx={{
        backgroundColor: '#F1F3F5', //modified by HJX
        boxShadow: 'inset 0px -1px 0px #E3E8EE',
      }}
      paddingY='18px'
      justifyContent={'center'}
    >
      <Box
        minWidth={matches750 ? `${minWidth}px` : '100%'}
        sx={{ flexShrink: 0 }}
        data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-Stepper-Box'
      >
        {children}
      </Box>
    </Stack>
  );
};

/**
 * @param {import("./CustomContainer").AcquiredSiCreationSectionContainerProps} props
 * @returns
 */
export const AcquiredSiCreationSectionContainer = props => {
  const { sectionTitle = '', type, children, ...others } = props;

  const renderChildren = () => {
    switch (type) {
      case 'itemList':
        return (
          <Stack
            spacing='8px'
            data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-CreationSection-Stack'
          >
            {children.map((item, index) => (
              <TranformToSiItemBlock
                title={item.title}
                content={item.content}
                key={index}
                titleBoxProps={{
                  minWidth: '200px',  //modified by HJX
                  maxWidth: '200px',
                }}
                contentBoxProps={{
                  sx: {
                    display: 'flex',
                    alignItems: 'flex-end',
                  },
                }}
                spacing={'20px'}
              />
            ))}
          </Stack>
        );
      case 'commonBlock':
        return children;
      default:
        return children;
    }
  };

  return (
    <Box
      {...others}
      data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-CreationSection-Box'
    >
      <AcquiredSiCreationHeading variant='secondary' marginBottom={'16px'}>
        {sectionTitle}
      </AcquiredSiCreationHeading>
      {renderChildren()}
    </Box>
  );
};

/**
 *
 * @param {import("./CustomContainer").SiOperationEditContainerProps} props
 * @returns
 */
export const SiOperationEditContainer = props => {
  const { required = false, children, title, ...others } = props;
  return (
    <Box data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-OperationEdit-Box'>
      <AcquiredSiCreationHeading
        variant='tertiary'
        marginBottom={'8px'}
        sx={{
          '& .AcquiredSiCreationHeading-asterisk': {
            marginLeft: '10px',
            visibility: required ? 'visible' : 'hidden',
            color: '#C40000',
          },
        }}
      >
        {title} <span className='AcquiredSiCreationHeading-asterisk'>*</span>
      </AcquiredSiCreationHeading>
      {children}
    </Box>
  );
};

/**
 *
 * @param {import("./CustomContainer").LoadingContainerProps} props
 * @returns
 */
export const LoadingContainer = props => {
  const { loading, children, sx, ...others } = props;
  return (
    <>
      {loading ? (
        <Stack
          data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-OperationEdit-Loading-Stack'
          sx={{
            position: 'relative',
            ...sx,
          }}
          alignItems='center'
          justifyContent='center'
          {...others}
        >
          <CircularProgress data-selenium-id='SIPPage_TransformToSl-CustomComponent-Container-OperationEdit-Loading-Stack-CircularProgress' />
        </Stack>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

import React, { useEffect, useState,useMemo } from 'react';
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Tooltip,
} from '@mui/material';
import {
  ReportNoData,
  StyledTableRow,
  TableBox,
  TableBox2,
  TwoColorTableRowGreen,
  TwoColorTableRowPurple,
} from '../../ReportSpecificComponents/SpecialColorTableHeader';
import { useIntl } from 'react-intl';
import {
  StyledTableCell,
  TableFooter2,
} from '../../../../components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  fyChangePage,
  fyChangePageSize,
  fyOrderBy,

} from '../../../../actions/SIMT-Report/ReportArticle/ReportArticleAction';
import { unstable_batchedUpdates } from 'react-dom';
import {
  CommonContentCells,
  CommonHeaderFirstRow,
  CommonHeaderSecondRow,
  BasicTableSecondRow,
} from './FYTrendsPageHead';
import TableSkeleton from '../../../../components/TableSkeleton';
import { getFyYearFromDate } from '../../../../utils/date_string';
import {
  findLongestLineSubstring,
  measureWidth,
} from '../../../../utils/StringWidthMeasure';
import monthIds from '../../monthIds';
import { SimpleTooltip } from '../../../../componentsphase2/CustomTooltip';
import { useShowReportRolesByFyHook } from '@/pages/Report/utils/useShowReportRolesByFyHook';
import {GetArticleLastActionDateFY} from "@/actions/SIMT-Report/Report/ReportArticle";
import {getFYBasicData} from "@/actions/SIMT-Report/ReportArticle";

function FYRow(props) {
  const { row, showJPM, showNewJournalRoles } = props;
  return (
    <>
      {/*left*/}
      <StyledTableCell
        sx={{
          position: 'sticky',
          left: 0,
          zIndex: 6,
          backgroundColor: '#F1F3F5',
        }}
      >
        <Box sx={{ fontSize: '16px' }}>{row.journalCode}</Box>
        <Box
          sx={{
            // fontSize: '14px',
            color: '#747474',
          }}
        >
          {row.journal}
        </Box>
      </StyledTableCell>
      <StyledTableCell>{row.revenueModel}</StyledTableCell>
      <StyledTableCell>{row.journalStatus}</StyledTableCell>
      {showJPM && (
          <StyledTableCell>{row.jpm}</StyledTableCell>
      )}
      {showNewJournalRoles && (
          <StyledTableCell>{row.pdp}</StyledTableCell>
      )}
      {showNewJournalRoles && (
          <StyledTableCell>{row.ppp}</StyledTableCell>
      )}
      <StyledTableCell>
        <SimpleTooltip title={row.subjectGroup}>{row.sgAbbr}</SimpleTooltip>
      </StyledTableCell>
      <StyledTableCell>{row.businessDivision}</StyledTableCell>
      <StyledTableCell>{row.catGroup}</StyledTableCell>
      <StyledTableCell className='RightBorder'>
        {row.handlingCe}
      </StyledTableCell>
      <CommonContentCells row={row} />
    </>
  );
}

export default function FYBasicTable() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showJPM, showNewJournalRoles] = useShowReportRolesByFyHook();
  const {
    page,
    pageSize,
    orderByType,
    basicTableData,
    useFilter,
    // fiscalYear,
    isOA,
  } = useSelector(state => {
    return {
      page: state.Report.fyTrendsArticle.page,
      pageSize: state.Report.fyTrendsArticle.pageSize,
      orderByType: state.Report.fyTrendsArticle.orderByType,
      basicTableData: state.Report.fyTrendsArticle.basicTableData,
      useFilter: state.Report.fyTrendsArticle.useFilter,
      // fiscalYear: state.Report.fyTrendsArticle.fiscalYear,
      // isOA表示是否打开了OA开关并应用，从`useFilter`获取,
      isOA:
        state.Report.fyTrendsArticle.useFilter.revenueModel?.length > 0
          ? state.Report.fyTrendsArticle.useFilter.revenueModel[0] === 'OA'
          : false,
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});



  useEffect(() => {
    if (basicTableData && basicTableData.count > 0) {
      const maxMap = {};
      for (let dataObj of basicTableData['result']) {
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength) {
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      widthMap['journalInformation'] = Math.max(
        widthMap['journalCode'],
        widthMap['journal']
      );
      widthMap['subjectGroup'] = widthMap['sgAbbr'];

      setColumnWidthMap(widthMap);
    }
  }, [basicTableData]);

  const selectMonthDate = useSelector(state => state.Report.selectDate);
  const [fiscalYear, setSelectFY__] = useState(
    getFyYearFromDate(selectMonthDate)
  );
  useEffect(() => {
    if (selectMonthDate != null) {
      const fyYear = getFyYearFromDate(selectMonthDate);
      setSelectFY__(fyYear);
    }
  }, [selectMonthDate]);

  const [loading, setLoading] = useState(false);

  const createRow = (
    id,
    minWidth,
    maxWidth,
    isOrder = false,
    align = 'left',
    show = true
  ) => {
    return {
      id,
      label: intl.messages[`report.fyTrendsTable.${id}`],
      minWidth,
      maxWidth,
      isOrder,
      align,
      show,
    };
  };

  const jpmItem = useMemo(() => {
    return  createRow('jpm', '180px', '340px', false, 'left');
  }, []);

  const pdpItem = useMemo(() => {
    return  createRow('pdp','105px','180px',false,'left');
  },[]);


  const pppItem = useMemo(() => {
    return  createRow('ppp','105px','180px',false,'left');
  },[]);

  const itemsBeforeJPM = useMemo(() => {
    return [
      createRow('journalInformation', '180px', '340px', false, 'left'),
      createRow('revenueModel', '180px', '340px', false, 'left'),
      createRow('journalStatus', '180px', '340px', false, 'left'),
    ];
  },[]);

  const itemsAfterJPM = useMemo(() => {
    return [
      createRow('subjectGroup', '180px', '340px', false, 'left'),
      createRow('businessDivision', '180px', '340px', false, 'left'),
      createRow('catGroup', '180px', '340px', false, 'left'),
      createRow('handlingCe', '180px', '340px', false, 'left'),
    ];
  },[]);

  const columnsLeft = useMemo(() => {
    let flexItem = [];
    if (showJPM) {
      flexItem = [...flexItem, jpmItem];
    }
    if (showNewJournalRoles) {
      flexItem = [...flexItem, pdpItem, pppItem];
    }
    return [...itemsBeforeJPM, ...flexItem, ...itemsAfterJPM];
  }, [showJPM, showNewJournalRoles]);

  useEffect(() => {
    const widthMap = {};
    for (let item of columnsLeft) {
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '18px Open Sans');
    }

    for (let item of monthIds) {
      let monthString = intl.messages[`month.${item.id}`];
      let len = measureWidth(monthString, '18px Open Sans');
      widthMap['sub' + item.post] = len;
      widthMap['acc' + item.post] = len;
      widthMap['pub' + item.post] = len;
    }

    const totalWidth = measureWidth('Total', '18px Open Sans');
    widthMap['subTotal'] = totalWidth;
    widthMap['accTotal'] = totalWidth;
    widthMap['pubTotal'] = totalWidth;

    setColumnMinWidthMap(widthMap);
  }, [columnsLeft]);

  const handlePerPageChange = e => {
    unstable_batchedUpdates(() => {
      // 修改分页的时候回到第一页
      dispatch(fyChangePageSize(e.target.value));
      dispatch(fyChangePage(1));
    });
  };

  const handlePageChange = (_event, value) => {
    dispatch(fyChangePage(value));
  };

  useEffect(() => {
    setLoading(true);
    // 表格数据刷新入口全部放在这个Hook里
    if (fiscalYear !== null) {
      async function fetchData() {
        await dispatch(
          getFYBasicData(
            page,
            pageSize,
            orderByType.order,
            orderByType.orderBy,
            fiscalYear,
            useFilter
          )
        );
      }

      fetchData().then(_r => {
        setLoading(false);
      });
      dispatch(GetArticleLastActionDateFY(fiscalYear, false));
    }
  }, [dispatch, page, pageSize, orderByType, useFilter, fiscalYear]);

  const handleChangeOrder = orderBy => {
    let newOrder;
    if (orderByType.orderBy === orderBy) {
      // 如果当前的排序字段没有变化，则切换正反序
      newOrder = orderByType.order === 'desc' ? 'asc' : 'desc';
    } else {
      // 如果当前排序字段发生变化，则直接按照新的字段升序
      newOrder = 'asc';
    }
    dispatch(fyOrderBy(newOrder, orderBy));
  };

  return (
    <TableBox2
      id='BasicTableBox'
      data-selenium-id='BasicTableBox'
      dataList={[basicTableData]}
      tableKey={'Report_Articles-FYTrends-BasicTable'}
      footerHeight={40}
    >
      <TableContainer
        data-selenium-id='Report_Articles-FYTrends-TableContainer'
        id='reportArticlesFYTrendsTable'
        sx={{ minHeight: '400px', maxHeight: '580px' }}
      >
        <Table
          stickyHeader
          aria-label='fy basic table'
          data-selenium-id='Report_Articles-FYTrends-Table'
          sx={{
            tableLayout: 'fixed',
            width: 'max-content',
            maxWidth: 'none',
          }}
        >
          <TableHead data-selenium-id='Report_Articles-FYTrends-TableHead'>
            {/*表头的几个部分*/}
            <CommonHeaderFirstRow
              isBasic={true}
              tableName='BasicTable'
              left={columnsLeft}
              orderByType={orderByType}
              handleChangeOrder={handleChangeOrder}
              isOA={isOA}
              data-selenium-id='Report_Articles-FYTrends-Table-FirstRow'
              columnWidthMap={columnWidthMap}
              columnMinWidthMap={columnMinWidthMap}
            />
            {/* <CommonHeaderSecondRow
              isOA={isOA}
              orderByType={orderByType}
              handleChangeOrder={handleChangeOrder}
            /> */}
            <BasicTableSecondRow
              isBasic={true}
              isOA={isOA}
              orderByType={orderByType}
              handleChangeOrder={handleChangeOrder}
              data-selenium-id='Report_Articles-FYTrends-Table-SecondRow'
              columnWidthMap={columnWidthMap}
              columnMinWidthMap={columnMinWidthMap}
            />
          </TableHead>
          {loading ? (
            <TableBody data-selenium-id='Report_Articles-FYTrends-TableBody'>
              <TableSkeleton num={9} colSpan={43} />
            </TableBody>
          ) : (
            <TableBody data-selenium-id='Report_Articles-FYTrends-TableBody'>
              {basicTableData.count === 0 && isOA && (
                <ReportNoData
                  top='50px'
                  id={'Report-Articles-FYTrends-NoData'}
                />
              )}
              {basicTableData.count === 0 && !isOA && (
                <ReportNoData
                  top='50px'
                  id={'Report-Articles-FYTrends-NoData'}
                />
              )}
              {
                // 表格body，根据OA变化颜色
                basicTableData.result.map((item, rowIndex) => {
                  if (isOA) {
                    return (
                      <StyledTableRow
                        key={'row-' + rowIndex}
                        data-selenium-id='Report_Articles-FYTrends-TableBody-TwoColorTableRowGreen'
                      >
                        {
                          <FYRow
                            row={item} showJPM = {showJPM} showNewJournalRoles = {showNewJournalRoles}
                            data-selenium-id='Report_Articles-FYTrends-FYRow'
                          />
                        }
                      </StyledTableRow>
                    );
                  } else {
                    return (
                      <StyledTableRow
                        key={'row-' + rowIndex}
                        data-selenium-id='Report_Articles-FYTrends-TableBody-TwoColorTableRowPurple'
                      >
                        {
                          <FYRow
                            row={item} showJPM = {showJPM} showNewJournalRoles = {showNewJournalRoles}
                            data-selenium-id='Report_Articles-FYTrends-FYRow'
                          />
                        }
                      </StyledTableRow>
                    );
                  }
                })
              }
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box>
        <Divider />
        <TableFooter2
          Page={page}
          perPageNumber={pageSize}
          perPageList={[20, 50, 100]}
          count={basicTableData.count}
          handlePerPageChange={handlePerPageChange}
          handlePageChange={handlePageChange}
          // backgroundColor={isOA ? '#96DCA9' : '#CCD4FF'}
          backgroundColor='#F1F3F5'
          data-selenium-id='Report_Articles-FYTrends-TableFooter2'
        />
      </Box>
    </TableBox2>
  );
}

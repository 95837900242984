import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { getMonthList } from './Utils';
import { Box, Button, ThemeProvider } from '@mui/material';
import { themeButton } from '../CommonTheme';
import { SmallFont } from '@/components/FontStyle';
import { Scrollbars } from 'react-custom-scrollbars';
import { AllCentered } from '../CommonStyle';

const MonthPage = (props, ref) => {
  const {
    id,
    visible,
    active,
    change,
    year,
    limit,
    temYear,
    setTemYear,
    temMonth,
    setTemMonth,
    maxDate,
    minDate,
    rangeModeFormat = false,
  } = props;
  const [list, setList] = useState([]);

  const maxYear = new Date().getFullYear() + 4;

  const IconButton = props => {
    const { selected, label, ...other } = props;
    return (
      <ThemeProvider theme={themeButton(selected ? '#154AB6' : '#AEC6F6')}>
        <Button
          sx={{
            display: 'flex',
            width: rangeModeFormat ? '56px' : '72px',
            height: '30px',
            minWidth: '0px',
            borderRadius: '4px',
            padding: '0px',
            ...SmallFont,
            lineHeight: '19px',
            color: selected ? '#FFFFFF' : '#596A7C',
            backgroundColor: selected ? '#154AB6' : '#FFFFFF',
            leadingTrim: 'both',
            textEdge: 'cap',
            textTransform: 'none',
          }}
          {...other}
        >
          {label}
        </Button>
      </ThemeProvider>
    );
  };
  const DisabledIconButton = props => {
    const { label, ...other } = props;
    return (
      <Box
        sx={{
          width: rangeModeFormat ? '56px' : '72px',
          height: '30px',
          ...AllCentered,
          ...SmallFont,
          lineHeight: '19px',
          leadingTrim: 'both',
          textEdge: 'cap',
          color: '#BCC5CF',
        }}
        {...other}
      >
        {label}
      </Box>
    );
  };

  // change
  function activeChange(params, temYear) {
    change(params, temYear);
  }
  // 减少
  function reduceFn() {
    if (temMonth - 1 === 0) {
      setTemMonth(12);
      temYear === 1970 ? setTemYear(maxYear) : setTemYear(temYear - 1);
    } else {
      setTemMonth(temMonth - 1);
    }
  }

  // 增加
  function addFn() {
    if (temMonth === 12) {
      setTemMonth(1);
      temYear === maxYear ? setTemYear(1970) : setTemYear(temYear + 1);
    } else {
      setTemMonth(temMonth + 1);
    }
  }

  function renderList() {
    return list.map(item => {
      return item.disabled ? (
        <DisabledIconButton
          key={item.label}
          label={item.label}
          id={`${id}_content_disabled`}
          data-selenium-id={`${id}_content_disabled_${item.label}`}
        />
      ) : (
        <IconButton
          id={`${id}_content`}
          data-selenium-id={`${id}_content_${item.label}`}
          key={item.label}
          selected={active === item.value && temYear === year}
          label={item.label}
          onClick={activeChange.bind(this, item, temYear)}
        />
      );
    });
  }

  useImperativeHandle(ref, () => ({
    reduceFn: reduceFn,
    addFn: addFn,
  }));

  // 时间变化监听
  useEffect(() => {
    let newList = getMonthList(temYear, limit, maxDate, minDate);
    setList(newList);
  }, [temYear, limit, maxDate, minDate]);

  return visible ? (
    <Box
      id={`${id}_content_box`}
      data-selenium-id={`${id}_content_box`}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        rowGap: rangeModeFormat ? '18px' : '12.33px',
        columnGap: rangeModeFormat ? '42px' :'18px',
      }}
    >
      {renderList()}
    </Box>
  ) : null;
};

export default forwardRef(MonthPage);

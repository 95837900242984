import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import ChipDeleteTag from './SVGComponents/ChipDeleteTag.svg';
import {
  Box,
  Divider,
  Paper,
  Popper,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import { MiniFont, MiniBoldFont, SmallFont } from '@/components/FontStyle';
import { AllCentered, ColumnCentered, LimitWord } from './CommonStyle';
import AddTag from './SVGComponents/AddTag.svg';
import DeleteTag from './SVGComponents/DeleteTag.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import { styled } from '@mui/system';
import CreateSIClearIcon from '../../../../assets/CreateSIClearIcon.svg';


export default function MultipleAutocomplete(props) {
  const {
    //控制Autocomplete里每个chip的样式，目前只集成了下面四个参数。
    //chipRootSX 控制chip的一个整体的样式
    //chipLabelSX 控制chip里文字的样式
    //chipDeleteIconSX 控制chip里删除按钮的样式
    //chipRootHoverSX 控制当chip被hover时的样式
    chipProps = {},
    //控制Autocomplete里 尾部按钮的样式,目前只集成了以下七个参数
    //在Autocomplete里，endAttchment是一个box，里面有两个Icon，一个是deleteIcon，一个popupIcon
    //endAttchmentSX 控制endAttchment的样式，
    //popupIconSX 控制popupIcon 的样式，
    //这是一个Box，注意不要设置这个box的宽高，因为我们popupIcon是有两类的,下面提供的popupIcon和focusPopupIcon只是这个box里的图标
    //popupIconHoverSX 控制popupIcon hover时 的样式
    //popupIconFocusSX 控制popupIcon focus时 的样式
    //deleteIconSX 控制deleteIcon 的样式，
    //这是一个Box，这里就没有限制
    //deleteIconHoverSX 控制deleteIcon hover时 的样式
    //deleteIconFocusSX 控制deleteIcon focus时 的样式
    endAttchmentProps = {},
    //以下这些Icon的值 null和undefined使用的都是默认值
    popupIcon,
    focusPopusIcon,
    chipDeleteIcon,
    deleteIcon,
    //是否禁用删除按钮
    disableClearable = false,
    //控制Autocomplete的样式
    //outlinedInputSX 控制Autocomplete的样式，这是一个box，多选的Autocomplete是由n个tag和一个input组成
    //inputSX 是一个对象，里面有两个参数，分别是root，用来控制整个input的样式，包括字体样式，padding，高度等等，placeholder用来控制input里的占位符的样式
    //outlinedInputSx在设置border上存在问题，这里使用notchedOutlineSX进行border的设置，注意只用来设置border
    //notchedOutlineHoverSX 控制Autocomplete hover时 border的样式
    //notchedOutlineFocusSX 控制Autocomplete focus时 border的样式
    //errorSX 控制Autocomplete为error状态的样式,可选参数有notchedOutlineSX，notchedOutlineSX，notchedOutlineFocusSX，这是因为目前输入框在错误的情况下一般只控制边框
    outlinedInputProps = {},
    //接下来我们要自定义下拉框的样式，我们需要先了解一下下拉框的一个结构
    //popper 是最外层的box，它的主要功能是控制下拉框相对于输入框的位置，它的实现是绝对布局，修改其样式要注意加上!important，这是因为Mui把样式写在了style里！！！！
    //paper 是第二层box，设置下拉框的样式，包括边框，阴影，背景颜色等，但是要注意，千万不要设置padding，或者margin，这种可以影响内容布局的属性
    //第三层有两种情况：
    //第一种：有下拉选项。这个时候第三层的是一个ListBox，ListBox里有一个ul,由于需求我又在ul下面设置了Box,这个box是用来放置 添加操作的组件或者其他功能组件的
    //设置样式需要注意，对ListBox的设置会影响ul和box的的布局的，所以需要根据需求进行设置
    //第二种：没有下拉选项，这个时候第三层就是一个简单的Box，目前已经清掉这个Box的所有布局样式（当然我也提供了接口），并提供其内容接口，显示什么完全取决于接口传进来什么
    //popperSX 设置popper的样式
    //paperSX 设置paper的样式
    //listBoxSX 设置listBox的样式
    //listBoxUlSX 设置listBoxUl相关的样式,其中包含如下属性
    //root：控制Ul的样式，li：控制ul里每个li的样式（option），liHover：控制li hover的样式， liFocus：控制li focus的样式,liSelected: 控制li 被选择时的样式，需要在添加的样式后面加上！important
    //由于甲方总会给出一个比较好看的滚动条，而原始的滚动条并不能能好的满足要求
    //scrollProps 这个参数是用来设置滚动条的样式的，它是一个对象，其中包含以下几个参数
    //scrollOverviwSX：这是用来控制套在ul外面的那个div的样式，一般来说，用来设置最大高度，这个最大高度要把ul的padding什么的算进去的，都注意因为我们把滚动交给其他组件来处理，所以在listBoxUlSX不要设置高度！！
    //scrollTrackVerticalSX: 这是用来控制侧边滚动条的一个绝对位置，一般来说设置top和right就可以满足要求
    //scrollThumbVerticalSX: 这是用来设置滚动条的样式的，除了高度不能设置其他都可以，包括宽度，背景色，透明度等等
    //scrollThumbSizeSX : 这是用来设置滚动条的长度的，参数类型是数字！！！！！！
    //listBoxAttachmentSX 设置listBoxAttachment的样式
    //noOptionsBoxSX 设置noOptionsBoxSX的样式
    dropListProps = {},
    scrollProps = {},
    //设置ul后面的那个box里的内容，参数可以是任意类型，最好的是组件，因为这个内容是要点击的
    dropListEndAttachment,
    //设置没有下拉内容时显示的内容，参数可以是任意类型，最好的是组件，因为这个内容是要点击的
    dropListNoOptions,
    //控制Autocomplete是否多选，默认多选
    multiple = true,
    getListOptionLabel = () => {},
    ...other
  } = props;

  // open = true 表示 focus，open = false 表示除focus以外的状态
  const [open, setOpen] = React.useState(false);

  const defaultChipDeleteIcon = (
    <Box sx={AllCentered}>
      <ChipDeleteTag></ChipDeleteTag>
    </Box>
  );

  const GETooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} placement='top' />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#596A7C',
      width: '6px',
      height: '3px',
      top: '28px',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      // title
      backgroundColor: '#596A7C',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#FFFFFF',
      padding: '4px 8px 4px 8px',
      height: '28px',
      borderRadius: '5px',
      maxWidth: '385px',
      boxSizing: 'border-box',
      marginBottom: '5px !important',
    },
  }));

  const defaultPopupIcon = (
    <Stack direction='row' spacing={0.5}>
      <Box sx={AllCentered}>
        <AddTag></AddTag>
      </Box>
      <Box
        sx={{
          ...MiniFont,
          ...ColumnCentered,
          color: '#154Ab6',
        }}
      >
        Add new GE
      </Box>
    </Stack>
  );

  const defaultClearIcon = (
    <Box sx={AllCentered}>
      <CreateSIClearIcon/>
    </Box>
  );

  const defaultDropListEndAttachment = (
    <Box
      sx={{
        ...AllCentered,
        flexDirection: 'column',
        gap: '8px',
        paddingBottom: '12px',
      }}
    >
      <Divider
        sx={{ width: 'calc(100% - 24px)', border: ' 0.5px solid #DFE4E8' }}
      />
      <Box sx={{ ...MiniBoldFont, lineHeight: '12px', color: '#98A7B6' }}>
        No Results you want?
      </Box>
      <Box sx={{ ...MiniBoldFont, lineHeight: '12px', color: '#98A7B6' }}>
        You can click here to add a new one.{' '}
      </Box>
    </Box>
  );

  const defaultDropListNoOptions = (
    <Box
      sx={{
        ...AllCentered,
        flexDirection: 'column',
        gap: '8px',
        padding: '12px 0px',
      }}
    >
      <Box sx={{ ...MiniBoldFont, lineHeight: '12x', color: '#98A7B6' }}>
        No Results you want?
      </Box>
      <Box sx={{ ...MiniBoldFont, lineHeight: '12px', color: '#98A7B6' }}>
        You can click here to add a new one.{' '}
      </Box>
    </Box>
  );

  const autocompleteChipDeleteIcon = chipDeleteIcon ?? defaultChipDeleteIcon;

  const autocompletePopupIcon = open
    ? focusPopusIcon ?? popupIcon ?? null
    : popupIcon ?? null;

  const autocompleteClearIcon = deleteIcon ?? defaultClearIcon;

  const autocompleteDropListEndAttachment =
    dropListEndAttachment !== undefined
      ? dropListEndAttachment
      : defaultDropListEndAttachment;

  const autocompleteDropListNoOptions =
    dropListNoOptions ?? defaultDropListNoOptions;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      multiple={multiple}
      filterOptions={x => x}
      disableClearable={disableClearable}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      sx={{
        '& .MuiAutocomplete-tag ': {
          margin: '4px',
          height: '28px',
          padding: '2px 8px 2px 12px',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: '#FFFFFF',
          border: '1px solid #BCC5CF',
          borderRadius: '4px',
          gap: '6px',
          maxWidth: '386px',
          ...(chipProps?.chipRootSX ?? {}),
          '& .MuiChip-label': {
            color: '#596A7C',
            ...MiniFont,
            padding: '0px',
            ...LimitWord,
            ...(chipProps?.chipLabelSX ?? {}),
          },
          '& .MuiChip-deleteIcon': {
            margin: '0px',
            '& :hover': {
              backgroundColor: '#BCC5CF',
            },
            ...(chipProps?.chipDeleteIconSX ?? {}),
          },
        },
        '& .MuiAutocomplete-tag:hover': {
          backgroundColor: '#DFE4E8',
          border: '1px solid #98A7B6',
          color: '#596A7C',
          ...(chipProps?.chipRootHoverSX ?? {}),
        },
        '& .MuiAutocomplete-endAdornment': {
          right: '6px',
          top: '6px',
          display: 'flex',
          flexDirection: 'row',
          ...(endAttchmentProps?.endAttchmentSX ?? {}),
        },
        '& .MuiAutocomplete-popupIndicator': {
          ...AllCentered,
          padding: '2px 4px',
          backgroundColor: '#F1F3F5',
          borderRadius: '4px',
          boxSizing: 'border-box',
          margin: '0px',
          transform: 'none',
          ...(endAttchmentProps?.popupIconSX ?? {}),
        },
        '& .MuiAutocomplete-popupIndicator:hover': {
          backgroundColor: '#DFE4E8',
          ...(endAttchmentProps?.popupIconHoverSX ?? {}),
        },
        '& .MuiAutocomplete-popupIndicator:focus': {
          backgroundColor: '#DFE4E8',
          ...(endAttchmentProps?.popupIconFocusSX ?? {}),
        },
        '& .MuiAutocomplete-popupIndicator:active': {
          backgroundColor: '#DFE4E8',
          ...(endAttchmentProps?.popupIconFocusSX ?? {}),
        },
        '& .MuiAutocomplete-clearIndicator': {
          ...AllCentered,
          margin: '0px',
          width: '14px',
          height: '14px',
          borderRadius: '0px',
          ...(endAttchmentProps?.deleteIconSX ?? {}),
        },
        '& .MuiAutocomplete-clearIndicator:hover': {
          backgroundColor: '#DFE4E8',
          ...(endAttchmentProps?.deleteIconHoverSX ?? {}),
        },
        '& .MuiAutocomplete-clearIndicator:focus': {
          backgroundColor: '#DFE4E8',
          ...(endAttchmentProps?.deleteIconFocusSX ?? {}),
        },
        '& .MuiOutlinedInput-root': {
          padding: '2px',
          width: '100%',
          boxSizing: 'border-box',
          ...(outlinedInputProps?.outlinedInputSX ?? {}),
        },
        '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
          padding: '0px',
          ...SmallFont,
          lineHeight: '125%',
          color: '#262E35',
          minWidth: '1px !important',
          '&::placeholder': {
            ...SmallFont,
            lineHeight: '125%',
            color: '#BCC5CF',
            opacity: '1',
            ...(outlinedInputProps?.inputSX?.placeholder ?? {}),
          },
          ...(outlinedInputProps?.inputSX?.root ?? {}),
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #D8D9DA',
          borderRadius: '4px',
          ...(outlinedInputProps?.notchedOutlineSX ?? {}),
        },
        '& .MuiOutlinedInput-notchedOutline:hover': {
          border: '1px solid #262E35',
          ...(outlinedInputProps?.notchedOutlineHoverSX ?? {}),
        },
        '& .MuiOutlinedInput-notchedOutline:focus': {
          border: '2px solid #0052CC',
          ...(outlinedInputProps?.notchedOutlineFocusSX ?? {}),
        },
        '& .Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #EE5350 !important',
            borderRadius: '4px',
            ...(outlinedInputProps?.errorSX?.notchedOutlineSX ?? {}),
          },
          '& .MuiOutlinedInput-notchedOutline:hover': {
            border: '2px solid #EE5350 !important',
            ...(outlinedInputProps?.errorSX?.notchedOutlineHoverSX ?? {}),
          },
          '& .MuiOutlinedInput-notchedOutline:focus': {
            border: '2px solid #EE5350 !important',
            ...(outlinedInputProps?.errorSX?.notchedOutlineFocusSX ?? {}),
          },
        },
        '& .MuiTouchRipple-root':{
          display: 'none'
        }
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <GETooltip title={option.primaryEmail} placement='top'>
            <Chip
              {...getTagProps({ index })}
              label={props.getOptionLabel(option)}
              deleteIcon={autocompleteChipDeleteIcon}
            />
          </GETooltip>
        ))
      }
      popupIcon={autocompletePopupIcon}
      clearIcon={autocompleteClearIcon}
      ListboxProps={{
        sx: {
          overflow: 'visible',
          position: 'absolute',
          width: 'calc(100% - 0px)',
          display: 'grid',
          gap: '6px',
          '& li': {
            padding: '0px 12px !important',
            boxSizing: 'border-box',
            ...MiniFont,
            lineHeight: '20px',
            color: '#596A7C',
            ...LimitWord,
            ...(dropListProps?.listBoxUlSX?.li ?? {}),
          },
          '& li[aria-selected="true"]': {
            backgroundColor: '#FFFFFF !important',
            ...(dropListProps?.listBoxUlSX?.liSelected ?? {}),
          },
          '& li[aria-selected="true"]:hover': {
            backgroundColor: '#F1F3F5 !important',
            ...(dropListProps?.listBoxUlSX?.liSelectedHover ?? {}),
          },
          '& li:focus': {
            backgroundColor: '#F1F3F5',
            ...(dropListProps?.listBoxUlSX?.liFocus ?? {}),
          },
          '& li:hover': {
            backgroundColor: '#F1F3F5',
            ...(dropListProps?.listBoxUlSX?.liHover ?? {}),
          },
          ...(dropListProps?.listBoxUlSX?.root ?? {}),
        },
      }}
      ListboxComponent={props => (
        <Box sx={dropListProps?.listBoxSX ?? {}}>
          <Scrollbars
            style={{ height: '224px', ...(scrollProps?.scrollOverviwSX ?? {}) }}
            renderTrackVertical={({ style, ...props }) => (
              <div
                style={{
                  ...style,
                  position: 'absolute',
                  top: '6px',
                  height: 'calc(100% - 6px)',
                  right: '3px',
                  ...(scrollProps?.scrollTrackVerticalSX ?? {}),
                }}
                {...props}
              ></div>
            )}
            renderTrackHorizontal={({ style, ...props }) => (
              <div
                style={{
                  ...style,
                  position: 'absolute',
                  width: '0px',
                  height: '0px',
                }}
                {...props}
              ></div>
            )}
            renderThumbVertical={({ style, ...props }) => (
              <div
                style={{
                  ...style,
                  backgroundColor: '#DFE4E8',
                  opacity: '0.7',
                  borderRadius: '10px',
                  width: '6px',
                  ...(scrollProps?.scrollThumbVerticalSX ?? {}),
                }}
                {...props}
              ></div>
            )}
            renderView={({ style, ...props }) => (
              <div
                style={{
                  ...style,
                  overflow: 'auto',
                  ...(scrollProps?.scrollOverviwSX ?? {}),
                }}
                {...props}
              ></div>
            )}
            thumbSize={scrollProps?.scrollThumbSizeSX ?? 69}
          >
            <ul {...props} />
          </Scrollbars>
          <Box sx={dropListProps?.listBoxAttachmentSX ?? {}}>
            {autocompleteDropListEndAttachment}
          </Box>
        </Box>
      )}
      noOptionsText={<Box>{autocompleteDropListNoOptions}</Box>}
      PaperComponent={props => <Paper {...props} />}
      PopperComponent={props => (
        <Popper
          {...props}
          sx={{
            '&.MuiAutocomplete-popper': {
              top:'6px !important',
              ...(dropListProps?.popperSX ?? {}),
            },
            '& .MuiAutocomplete-paper': {
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)', //zhaoyichengsidetail GE
              borderRadius: '5px',
              ...(dropListProps?.paperSX ?? {}),
            },
            '& .MuiAutocomplete-noOptions': {
              padding: '0px',
              ...(dropListProps?.noOptionsBoxSX ?? {}),
            },
          }}
        />
      )}
      clearIconProps={{ title: '' }}
      popupIconProps={{ title: '' }}
      clearText=''
      closeText=''
      openText=''
      {...other}
    />
  );
}

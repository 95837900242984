// @ts-check
import React, { useCallback } from 'react';
import { Box, Collapse, IconButton } from '@mui/material';
import {
  H4Font,
  LargeBoldFont,
  MiniFont,
  SmallFont,
} from '../../../components/FontStyle';
// @ts-ignore
import NotificationDelete from '../../../assets/NotificationDelete.svg';
import NotificationTabs from '../../../components/Alert/NotificationTabs';
import {
  formatBold,
  StrArrToObjArr,
  formatSpaceAfterComma,
} from '../../../components/Alert/Util';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentNotificationItemInfo,
  setNotificationDrawerOpen,
} from '../../../actions/SIMT-User/Alert/AlertAction';
import {
  JournalTable,
  SITable,
  ProblematicGETable,
} from '../../../components/Alert/Table';
import Grid from '@mui/material/Grid';
import { formatDateString } from '../../../utils/commonUtils';
import { TransitionGroup } from 'react-transition-group';
import {
  formatSystemEmailTemplateUpdate,
  SYS_EMAIL_UPDATE_SUBJECT,
} from '../../Alert/Util';
import { ReadAlert } from '@/actions/SIMT-User/Alert';
import AlertList from '@/components/AuthHeader/CustomComponents/AlertList';

/** @typedef {import("react").CSSProperties} CSSProperties*/

/** @type {CSSProperties} */
const FlexLayout = {
  display: 'flex',
  justifyContent: 'flex-start',
};

/** @type {CSSProperties} */
const CssHead = {
  height: '28px',
  width: '100%',
  ...FlexLayout,
  marginBottom: '13px',
  position: 'relative',
};

/** @type {CSSProperties} */
const CssTitle = {
  ...H4Font,
  color: '#262E35',
  width: 'calc(100% - 24px)',
};

/** @type {CSSProperties} */
const ContentBox = {
  ...FlexLayout,
  width: '100%',
};

/** @type {CSSProperties} */
const ContentBox_aside = {
  width: '100%',
};

/** @type {CSSProperties} */
const ContentBox_main = {
  width: '100%',
};

/** @type {CSSProperties} */
const ContentBox_main_head = {
  background: '#FFFFFF',
  padding: '15px 34px 16px 34px',
  boxSizing: 'border-box',
  width: '100%',
  height: 'auto',
  marginBottom: '8px',
};

/** @type {CSSProperties} */
const ContentBox_main_head_subject = {
  ...LargeBoldFont,
  color: '#262E35',
  height: 'auto',
  marginBottom: '8px',
};

/** @type {CSSProperties} */
const ContentBox_main_head_date = {
  ...MiniFont,
  color: '#98A7B6',
  height: '18px',
};

/** @type {CSSProperties} */
const ContentBox_main_content = {
  background: '#FFFFFF',
  padding: '32px 34px',
};

/** @type {CSSProperties} */
const ContentBox_main_content_Text = {
  ...SmallFont,
  color: '#000000',
};

const ID_PREFIX = 'notificationItem_';

/**
 * @typedef {import("./AlerPage").Alert} Alert
 * @typedef {import("./AlerPage").AlertDetail} AlertDetail
 */
export default function AlertPage() {
  const dispatch = useDispatch();

  const {
    allAlerts: notificationListAll,
    unreadAlerts: notificationListUnread,
    currentNotificationTab,
    currentNotificationItem,
    currentNotificationIndex,
    // notificationDrawerOpen,
  } = useSelector(state => {
    return {
      allAlerts: state.Alert.allAlerts,
      unreadAlerts: state.Alert.unreadAlerts,
      /** @type {0 | 1} */
      // @ts-ignore
      currentNotificationTab: state.Alert.currentNotificationTab,
      /** @type {number} */
      // @ts-ignore
      currentNotificationItem: state.Alert.currentNotificationItem,
      currentNotificationIndex: state.Alert.currentNotificationIndex,

      /** @type {boolean} */
      // @ts-ignore
      notificationDrawerOpen: state.Alert.notificationDrawerOpen,
    };
  });

  /**
   * @param {number} index
   * @param {number} id
   * @param {object} item
   */
  const handleSubjectClick = (index, id, item) => {
    dispatch(
      setCurrentNotificationItemInfo({
        currentNotificationIndex: index,
        currentNotificationItem: item,
      })
    );
  };

  useEffect(() => {
    if (currentNotificationItem?.read === 'No') {
      dispatch(ReadAlert(currentNotificationItem?.id));
    }
  }, [currentNotificationItem]);
  
  /**
   * @param {0 | 1} newValue
   */
  const handleSwitchTab = newValue => {
    const list = newValue === 0 ? notificationListAll : notificationListUnread;
    const index = list.findIndex(
      item => item.id === currentNotificationItem?.id
    );
    
    dispatch(
      setCurrentNotificationItemInfo({
        currentNotificationTab: newValue,
        currentNotificationIndex: index,
      })
    );
  };

  useEffect(() => {
    if (
      currentNotificationTab === 1 &&
      notificationListUnread.findIndex(
        item => item.id === currentNotificationItem?.id
      ) === -1
    ) {
      dispatch(
        setCurrentNotificationItemInfo({
          currentNotificationItem: null,
        })
      );
    }
  }, [currentNotificationTab, notificationListUnread, currentNotificationItem]);

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        padding: '14px 32px 0px 32px',
        marginTop: '48px',
        backgroundColor: '#F6F7F8',
        '& .SIMT-NotificationTabs-body': {
          height: 'calc(100vh - 220px)',
          overflowY: 'auto',
          overflowX: 'hidden',
          scrollBehavior: 'smooth',
        },
      }}
    >
      <Box sx={CssHead}>
        <Box sx={CssTitle}>Notifications</Box>
        <IconButton
          id='NotificationClose'
          disableRipple
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
          onClick={() => {
            dispatch(setNotificationDrawerOpen(false));
          }}
        >
          <NotificationDelete />
        </IconButton>
      </Box>

      <Box sx={ContentBox}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <Box sx={ContentBox_aside}>
              <NotificationTabs
                id='NotificationTabs'
                setCurrentTab={handleSwitchTab}
                currentTab={currentNotificationTab}
              >
                <AlertList
                  show={currentNotificationTab === 0}
                  onItemClick={handleSubjectClick}
                  activeID={currentNotificationItem?.id}
                  idPrefix={ID_PREFIX + 'all_'}
                  scrollToIndex={currentNotificationIndex}
                  />

                <AlertList
                  show={currentNotificationTab === 1}
                  isRead={false}
                  onItemClick={handleSubjectClick}
                  activeID={currentNotificationItem?.id}
                  idPrefix={ID_PREFIX + 'unread_'}
                  scrollToIndex={currentNotificationIndex}
                />
              </NotificationTabs>
            </Box>
          </Grid>
          <Grid item xs={6} md={8}>
            <TransitionGroup>
              {currentNotificationItem && (
                <Collapse
                  key={`notification-detail-${currentNotificationItem?.id}`}
                  timeout={500}
                >
                  <AlertDetailBlock alert={currentNotificationItem} />
                </Collapse>
              )}
            </TransitionGroup>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

/**
 *
 * @param {import("./AlerPage").AlertDetailBlockProps} props
 * @returns
 */
export const AlertDetailBlock = props => {
  const { alert } = props;

  const getSubject = () => alert.subject;
  const getFormatDate = () => formatDateString(alert.createTime);
  /**
   *
   * @returns {React.ReactNode}
   */
  const getContent = () => {
    switch (alert.subject) {
      case 'Your SIMT User Group Has Been Changed':
      case 'Your SIMT User Role Has Been Changed':
        return (
          <Box
            dangerouslySetInnerHTML={{
              __html: formatBold(alert.textList[0]),
            }}
            sx={ContentBox_main_content_Text}
          ></Box>
        );
      case 'Journal key stakeholders information is changed':
        return (
          <Box>
            <Box
              dangerouslySetInnerHTML={{
                __html: formatBold(formatSpaceAfterComma(alert.textList[0])),
              }}
              sx={ContentBox_main_content_Text}
            ></Box>
            <Box
              sx={{
                display: 'inline-block',
                border: '1px solid #DFE4E8',
                borderRadius: '3px',
                margin: '24px 0px',
              }}
            >
              <JournalTable journalList={alert.tableList}></JournalTable>
            </Box>
          </Box>
        );
      case 'Your SI Information Has Been Edited in SIMT':
        return (
          <Box>
            <Box sx={ContentBox_main_content_Text}>{alert.textList[0]}</Box>
            <Box
              sx={{
                width: '100%',
                overflow: 'auto',
                margin: '24px 0px 24px 0px',
              }}
            >
              <SITable SIList={StrArrToObjArr(alert.tableList)}></SITable>
            </Box>

            <Box sx={ContentBox_main_content_Text}>{alert.textList[1]}</Box>
          </Box>
        );
      case 'A problematic GE in your SI！':
        return (
          <Box>
            <Box sx={ContentBox_main_content_Text}>{alert.textList[0]}</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>{alert.textList[4]}</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>{alert.textList[10]}</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box
              sx={{
                width: '100%',
                overflow: 'auto',
                margin: '24px 0px 24px 0px',
              }}
            >
              <ProblematicGETable
                list={StrArrToObjArr(alert.tableList)}
              ></ProblematicGETable>
            </Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>{alert.textList[14]}</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>{alert.textList[18]}</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>{alert.textList[20]}</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
            <Box sx={ContentBox_main_content_Text}>&nbsp;</Box>
          </Box>
        );
      case SYS_EMAIL_UPDATE_SUBJECT:
        return (
          <Box sx={ContentBox_main_content_Text}>
            {formatSystemEmailTemplateUpdate(alert.textList[0])}
          </Box>
        );
      default:
        return (
          <Box
            dangerouslySetInnerHTML={{
              __html: formatBold(alert.textList[0]),
            }}
            sx={ContentBox_main_content_Text}
          ></Box>
        );
    }
  };

  /** @type {AlertDetail} */
  const alertDetail = {
    subject: getSubject(),
    formatDate: getFormatDate(),
    content: getContent(),
  };

  return (
    <Box sx={ContentBox_main}>
      <Box sx={ContentBox_main_head}>
        <Box sx={ContentBox_main_head_subject}>{alertDetail.subject}</Box>
        <Box sx={ContentBox_main_head_date}>{alertDetail.formatDate}</Box>
      </Box>
      <Box sx={ContentBox_main_content}>{alertDetail.content}</Box>
    </Box>
  );
};

import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { validClear } from '../../../../actions/SIMT-User/Login/LoginAction';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {checkLinkValid} from "@/actions/SIMT-User/Login";

const LinkPage = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const digitalSignature = searchParams.get('digitalSignature');
  const email = searchParams.get('email');

  const [valid, setValid] = useState(true);
  const selector = state => {
    return {
      linkValid: state.Login.linkValid,
    };
  };
  const { linkValid } = useSelector(selector);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(checkLinkValid(digitalSignature, email));
    };
    fetchData();
  }, []);

  useEffect(() => {
    setValid(linkValid);

    if (linkValid === true) {
      navigate('/simt/CreateNewPassword', {
        state: { email: email, digitalSignature: digitalSignature },
      });
    } else if (linkValid === false) {
      navigate('/simt/LinkExpire', {
        state: { email: email, digitalSignature: digitalSignature },
      });
    }
  }, [linkValid]);

  useEffect(() => {
    return () => {
      dispatch(validClear());
    };
  }, []);

  const navigate = useNavigate();

  // return valid ? (
  //   <CreateNewPassword digitalSignature={digitalSignature} email={email} />
  // ) : (
  //   <LinkExpire digitalSignature={digitalSignature} email={email} />
  // );
  return <div>Hello</div>;
};

export default LinkPage;

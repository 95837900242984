import * as React from 'react';
import {
  Box,
  Divider,
  FormControl,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { BootstrapTitle } from '../../../../components/CreateAndUpdate/MyTitle';
import AutocompleteMuiltiple from '../../../../components/CreateAndUpdate/AutoCompleteMultiple';
import { MyFormHelperText } from '../../../../components/CreateAndUpdate/MyFormHelperText';
import CreateAndUpdateAddNew from './CreateAndUpdateAddNew';
import ItemBlock from '../CGTComponents/ItemBlock';
import MultipleAutocomplete from '../CGTComponents/ColorFulTagMultipleAutocomplete';
import AddTag from '../CGTComponents/SVGComponents/AddTag.svg';
import AddTagWhite from '../CGTComponents/SVGComponents/AddTagWhite.svg';
import {
  AllCentered,
  ColumnCentered,
  LimitWord,
} from '../CGTComponents/CommonStyle';
import { MiniBoldFont, MiniFont } from '@/components/FontStyle';
import { useDispatch } from 'react-redux';

import {getGE} from "@/actions/SIMT-SI/SpecialIssue/GuestEditor";

class AutoCompleteBox extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const { setHeight } = this.props;
    const observer = new ResizeObserver(entries => {
      const { width, height } = entries[0].contentRect;
      // onResize({ width, height });
      setHeight(height);
    });
    observer.observe(this.ref.current);
  }

  render() {
    return (
      <div ref={this.ref} style={{position: 'relative'}}>
        {this.props.children}
      </div>
    );
  }
}

export default function CreateSIAutocompleteMultiple(props) {
  const {
    emptyErr,
    id,
    title,
    required,
    defaultValue,
    options,
    getOptionLabel,
    onChange,
    onInputChange,
    Value,
    maxGeNum,
    handleAddNewClick,
    ...other
  } = props;
  const dispatch = useDispatch();
  const [totalError, setTotalError] = React.useState(false);
  const [height,setHeight] = React.useState('0px');
  React.useEffect(() => {
    setTotalError(emptyErr || props.error);
  }, [props.error, emptyErr]);
  const getErrorText = () => {
    let helperText = '';
    if (props.error) {
      helperText = props.helperText;
    } else {
      helperText = 'This field is required.';
    }
    return helperText;
  };
  const focusPopusIcon = (
    <Box
      sx={{ width: '20px', height: '22px', ...AllCentered }}
      onClick={event => {
        event.stopPropagation();
        handleAddNewClick();
      }}
    >
      <AddTag></AddTag>
    </Box>
  );
  const focusPopusIconEnterDown = (
    <Stack
      direction='row'
      spacing={0.5}
      onClick={event => {
        event.stopPropagation();
        handleAddNewClick();
        setEnterDown(false);
      }}
      sx={{ width: '92px', height: '22px', ...AllCentered }}
    >
      <AddTagWhite></AddTagWhite>
      <Box
        sx={{
          ...MiniFont,
          ...ColumnCentered,
          color: '#FFFFFF',
        }}
      >
        Add new GE
      </Box>
    </Stack>
  );
  const popupIcon = (
    <Stack
      direction='row'
      spacing={0.5}
      onClick={event => {
        event.stopPropagation();
        handleAddNewClick();
      }}
      sx={{ width: '92px', height: '22px', ...AllCentered }}
    >
      <Box sx={AllCentered}>
        <AddTag></AddTag>
      </Box>
      <Box
        sx={{
          ...MiniFont,
          ...ColumnCentered,
          color: '#154AB6',
        }}
        id = 'buttonAddNewGe'
      >
        Add new GE
      </Box>
    </Stack>
  );

  const [inputValue, setInputValue] = React.useState('');
  React.useEffect(() => {
    dispatch(getGE(inputValue));
  }, [inputValue]);

  const dropListEndAttachment = (
    <Box
      sx={{
        ...AllCentered,
        flexDirection: 'column',
        paddingBottom: '4px',
      }}
    >
      <Divider
        sx={{ width: 'calc(100% - 24px)', border: ' 0.5px solid #DFE4E8' }}
      />
      <Box sx={{ ...MiniBoldFont, lineHeight: '20px', color: '#98A7B6',fontWeight:'600', paddingTop: '8px'}}>
        No Results you want?
      </Box>
      <Box sx={{ ...MiniBoldFont, lineHeight: '20px', color: '#98A7B6',fontWeight:'400',paddingTop: '2px'}}>
        You can{' '}
        <span
          style={{ color: '#154AB6', cursor: 'pointer',fontWeight:600 }}
          onClick={handleAddNewClick}
        >
          click here
        </span>{' '}
        to add a new one.{' '}
      </Box>
    </Box>
  );

  const dropListNoOptions = (
    <Box
      sx={{
        ...AllCentered,
        flexDirection: 'column',
        padding: '12px 0px',
      }}
    >
      <Box sx={{ ...MiniBoldFont, lineHeight: '20px', color: '#98A7B6',fontWeight:'600', paddingTop: '8px'}}>
        No Results you want?
      </Box>
      <Box sx={{...MiniBoldFont, lineHeight: '20px', color: '#98A7B6',fontWeight:'400',paddingTop: '2px'}}>
        You can{' '}
        <span
          style={{ color: '#154AB6', cursor: 'pointer',fontWeight:600 }}
          onClick={handleAddNewClick}
        >
          click here
        </span>{' '}
        to add a new one.{' '}
      </Box>
    </Box>
  );
  const calcHeight = (options, maxNum, gap, liHeight, other, other1) => {
    let numOptions = options?.length ?? 0;
    let currentHeight = numOptions * (gap + liHeight) + other + other1;
    let maxHeight = (maxNum - 1) * (gap + liHeight) + liHeight + other;
    return currentHeight <= maxHeight ? currentHeight : maxHeight;
  };
  const lastOption = {
    fistNmae: 'End',
    lastName: 'Attachment',
    primaryEmail: 'null',
  };
  const getNewOptions = dropList => {
    let newDropList = [];
    if (dropList && dropList.length > 0) {
      newDropList = dropList.concat();
      newDropList.push({
        fistNmae: 'End',
        lastName: 'Attachment',
        primaryEmail: 'null',
      });
    }
    return newDropList;
  };
  const getListOptionLabel = option => {
    return (
      option.lastName +
      ', ' +
      option.firstName +
      ' (' +
      option.primaryEmail +
      ')'
    );
  };
  const [enterDown, setEnterDown] = React.useState(false);
  const deleteIcon = (
    <Box
      sx={{
        ...MiniFont,
        width: '29px',
        height: '9px',
        color: '#154AB6',
        leadingTrim: ' both',
        textEdge: 'cap',
        lineHeight: '9px',
      }}
    >
      Clear
    </Box>
  );
  const [stateHover, setStateHover] = React.useState(false);
  const [stateFocus, setStateFocus] = React.useState(false);

  return (
    <ItemBlock
      id={id}
      title={title}
      required={required}
      isError={totalError}
      errorText={getErrorText()}
    >
      <AutoCompleteBox setHeight={setHeight}>
        {Value?.length === 0 && inputValue &&
        inputValue !== ''  ? (
          <Box
            sx={{
              position: 'absolute',
              top: '-13px',
              right: '0px',
              cursor: 'pointer',
            }}
          >
            {deleteIcon}
          </Box>
        ) : null}
        <MultipleAutocomplete
          // id={`${id}_multi_autocomplete`}
          // data-selenium-id={`${id}_multi_autocomplete`}
          id={id}
          data-selenium-id={id}
          defaultValue={defaultValue}
          value={Value}
          options={getNewOptions(options)}
          getOptionLabel={getOptionLabel}
          onChange={onChange}
          onInputChange={e => {
            setInputValue(e.target.value);
          }}
          isOptionEqualToValue={(option, value) => {
            return getListOptionLabel(option) === getListOptionLabel(value);
          }}
          onFocus={() => {
            setTotalError(false);
            setStateFocus(true);
            props.onFocus();
          }}
          onBlur={() => {
            setTotalError(emptyErr || props.error);
            setEnterDown(false);
            setStateFocus(false);
            setInputValue('');
          }}
          // onMouseEnter={() => {
          //   setStateHover(true);
          // }}
          // onMouseLeave={() => {
          //   setStateHover(false);
          // }}
          onLoading={props?.onLoading ?? false}
          popupIcon={stateFocus ? focusPopusIcon : popupIcon}
          focusPopusIcon={enterDown ? focusPopusIconEnterDown : focusPopusIcon}
          renderInput={params => (
            <TextField
              id={`${id}_multi_autocomplete_textfield`}
              data-selenium-id={`${id}_multi_autocomplete_textfield`}
              value={inputValue}
              placeholder={''}
              error={totalError}
              {...params}
            />
          )}
          chipProps={{
            chipRootSX: {
              margin: '0px',
            },
          }}
          endAttchmentProps={{
            endAttchmentSX: {
              right: '6px !important',
              top: height <=40 ? '9px' : '6px',
            },
            popupIconSX: {
              padding: '0px',
              backgroundColor: enterDown ? '#0052CC' : '#F1F3F5',
              '& .MuiTouchRipple-root':{
                display:'none'
              }
            },
            popupIconHoverSX: {
              backgroundColor: enterDown ? '#6D98EE' : '#DFE4E8',
            },
            popupIconFocusSX: {
              backgroundColor: enterDown ? '#113D95' : '#DFE4E8',
            },
            deleteIconSX: {
              position: 'absolute',
              top:height <=40? '-22px':'-19px',
              right: '-6px',
              width: '29px',
              height: '9px',
              padding: '0px',
              '& .MuiTouchRipple-root':{
                display:'none'
              }
            },
            deleteIconHoverSX: {
              backgroundColor: '#FFFFFF',
            },
            deleteIconFocusSX: {
              backgroundColor: '#FFFFFF',
            },
          }}
          outlinedInputProps={{
            outlinedInputSX: {
              padding: '6px',
              paddingRight: '104px !important',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              rowGap: '4px',
              columnGap: '2px',
            },
            inputSX: {
              root: {
                height: '28px',
                padding: '4px 0px',
                boxSizing: 'border-box',
              },
            },
            notchedOutlineSX: {
              border:
                Value?.length === 0
                  ? '1px solid #DFE4E8'
                  : '1px solid #98A7B6',
            },
          }}
          renderOption={(props, option) => {
            return getListOptionLabel(option) ===
              getListOptionLabel(lastOption) ? (
              dropListEndAttachment
            ) : (
              <li
                {...props}
                style={{
                  height: '20px',
                }}
                onMouseEnter={e => {
                  e.currentTarget.style.color = '#262E35';
                }}
                onMouseLeave={e => {
                  e.currentTarget.style.color = '#596A7C';
                }}
              >
                <Box sx={{ ...LimitWord, width: '100%' }}>
                  {getListOptionLabel(option)}
                </Box>
              </li>
            );
          }}
          dropListEndAttachment={null}
          scrollProps={{
            scrollOverviwSX: {
              height: `${calcHeight(options, 6, 6, 20, 12, 46)}px`,
            },
          }}
          dropListProps={{
            listBoxSX: {
              paddingBottom: '12px',
            },
            listBoxUlSX: {
              root: {
                padding: '12px 0px 0px 0px',
              },
            },
          }}
          getListOptionLabel={getListOptionLabel}
          dropListNoOptions={dropListNoOptions}
          onKeyDown={e => {
            if (e.key === 'Enter' && options.length === 0) {
              setEnterDown(true);
            }
          }}
          deleteIcon={deleteIcon}
        ></MultipleAutocomplete>
      </AutoCompleteBox>
    </ItemBlock>
  );
}

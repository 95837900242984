import { axios_instance } from "@/utils/axios_instance";
import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {
    ASSIGN_TO_CE_SUBMITION_DECISION,
    ASSIGN_TO_REJECT_SUBMISSION_DECISION,
    GET_DECISION_CONFIG,
    GET_DECISION_TYPE,
    GET_EMAIL_REVISION_BODY,
    GET_SIP_STATUS,
    GET_USER_REJECT_REASON_DROPLIST,
    SEND_EMAIREVISIONCODE,
    SET_EMAIL_ALL,
    SET_GET_EMAIL_CODE,
    SET_REVISE_BCC,
    SET_REVISE_CC,
    SET_REVISE_FROM,
    SET_REVISE_SUBJECT,
    SET_REVISE_TO,
    SET_SEND_EMAIL_CODE,
    SIP_CHECKLIST_ERROR_CODE_ARR,
    SIP_CHECKLIST_NOT_PROCEEDED_CODE,
    SIP_VERSION_ERROR,
    EMAIL_DOMAIN_ERROR,
} from "@/actions/SIMT-SIP/SIP/SIPAction";
import {
    getContentCommissioningApproaches,
    getGEInformation,
    getObject,
    getSIPInformation,
    getSIPStatus,
    updateSIPStageToSheveld
} from "@/actions/SIMT-SIP/SIP/SipDetail";
import isSubmitted from "@/pages/SIPPage/SIPDetailPage/utils/isSubmitted";
import { forEmail } from "@/modules/RichTextEditor/EditorUtils";
import { extractAttachmentId } from "@/utils/commonUtils";
import simpleDisplayError from "@/utils/simpleDisplayError";
import { CheckSipNonCatJournal } from "@/actions/SIMT-SIP/SIP/SiptoSi";

export function getDecisionTypeList(sipCode) {
    return async dispatch => {
        dispatch({ type: GET_DECISION_TYPE, data: [] });
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?type=sipDecision`;
            const res = await axios_instance.get(url, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const { code, data } = res.data;
            if (code === 200) {
                dispatch({ type: GET_DECISION_TYPE, data });
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.decisonTypeGetErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.decisonTypeGetErrorMessage',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

export function getDecisionConfigList() {
    return async dispatch => {
        try {
            const url = '/api/v1/user-service/configs';
            const res = await axios_instance.get(url, {
                params: {
                    type: 'Decision Link',
                    fuzzy_type: false
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const { code, data } = res.data;
            if (code === 200) {
                dispatch({ type: GET_DECISION_CONFIG, data: data });
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.decisonConfigGetErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.decisonConfigGetErrorMessage',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}


export function assignToCESubmitDecision(
    sipCode,
    handingCEID,
    comments,
    setButtonAlertButtonsSipChecklistNotProceeded = () => {
    },
    resolve = e => e,
    sipVersion,
    sipStatus
) {
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=assignToCe`;
            const res = await axios_instance.put(
                url,
                {
                    assignToCeRequests: {
                        comments: comments,
                        handlingCE: handingCEID,
                    }
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            const { code } = res.data;
            if (code === 200) {
                dispatch({ type: ASSIGN_TO_CE_SUBMITION_DECISION, data: Date.now() });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.assignToCESubmitDecisionSuccess',
                        {},
                        SEVERITIES.success
                    )
                );
                let newSipStatus = {
                    ...sipStatus,
                    sipVersion: res.data.sipVersion,
                };
                dispatch({
                    type: GET_SIP_STATUS, data: {
                        ...newSipStatus
                    }
                });

            } else if (res.data.code === SIP_VERSION_ERROR) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusChanged',
                        {},
                        SEVERITIES.warning
                    )
                );
                getSIPStatus(sipCode);
            } else if (SIP_CHECKLIST_ERROR_CODE_ARR.includes(code)) {
                setTimeout(() => setButtonAlertButtonsSipChecklistNotProceeded());
                resolve(SIP_CHECKLIST_NOT_PROCEEDED_CODE);
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.assignToCESubmitDecisionErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.assignToCESubmitDecisionErrorMessage',
                    {},
                    SEVERITIES.error
                )
            );
        }
        resolve(200);
    };
}

/**
 * ct2816传送邮件接口
 * @param sipCode
 * @param comments
 * @returns
 */
export function assignToRejectSubmitDecisionSuccess(
    sipCode,
    comments,
    sipVersion,
    setButtonAlertButtonsSipChecklistNotProceeded = () => {
    },
    resolve = e => e,
    sipStatus,
    rejectReason,
) {
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=rejectSubmit`;
            const res = await axios_instance.put(url,
                { updateDecisionRequests: { comments: comments, reasons: rejectReason } },
                { headers: { authorization: localStorage.getItem('authorization') } }
            );
            dispatch({
                type: ASSIGN_TO_REJECT_SUBMISSION_DECISION,
                data: Date.now(),
            });
            const { code, data } = res.data;
            if (code === 200) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.assignToRejectSubmitDecisionSuccess',
                        {},
                        SEVERITIES.success
                    )
                );
                let newSipStatus = {
                    ...sipStatus,
                    sipVersion: res.data.sipVersion,
                };
                dispatch({
                    type: GET_SIP_STATUS, data: {
                        ...newSipStatus
                    }
                });
            } else if (code === SIP_VERSION_ERROR) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusChanged',
                        {},
                        SEVERITIES.warning
                    )
                );
                getSIPStatus(sipCode);
            } else if (SIP_CHECKLIST_ERROR_CODE_ARR.includes(code)) {
                setTimeout(() => setButtonAlertButtonsSipChecklistNotProceeded());
                resolve(SIP_CHECKLIST_NOT_PROCEEDED_CODE);
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.assignToRejectSubmitDecisionErrorMessage',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.assignToRejectSubmitDecisionErrorMessage',
                    {},
                    SEVERITIES.error
                )
            );
        }
        resolve(200);
    };
} 


/**
 *  get email templates [获取email模板的通用方法]
 *  addition 用来传递不同email下特殊的参数 比如revision的 deadline 等
 */
export const getEmailAllInformation = (
    sipStage,
    decisionType,
    sipCode,
    comments,
    addition,
    setButtonAlertButtonsSipChecklistNotProceeded = () => {
    },
    resolve = e => e,
    sipVersion,
    sipStatus,
) => {
    return async dispatch => {
        try {
            let url, postBody, reqParam;
            if (sipStage === 'Submitted') {
                switch (decisionType) {
                    case 'Revise':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'ReviseSipTemplate'
                        };
                        postBody = {
                            sipCode,
                            comments,
                            deadline: addition.deadline,
                            emailcontents: null,
                            sipVersion,
                        };
                        break;
                    case 'Withdraw':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'WithdrawEmailTemplate'
                        };
                        postBody = {
                            sipCode,
                            comments,
                            sipVersion,
                        };
                        break;
                    case 'Reject':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'RejectSipTemplate',
                        };
                        postBody = {
                            sipCode,
                            comments,
                            sipVersion,
                        };
                        break;
                }
            } else if (sipStage === 'Editor Review') {
                switch (decisionType) {
                    case 'Cancel Editor Review':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'CancellationEmailTemplate'
                        };
                        postBody = {
                            sipCode,
                            comments,
                            sipVersion,
                        };
                        break;
                    default:
                }
            } else if (sipStage === 'Publisher Inquiry') {
                switch (decisionType) {
                    case 'Cancel Publisher Inquiry':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'CancelJPMInquiryEmailTemplate'
                        };
                        postBody = {
                            sipCode,
                            comments,
                            sipVersion,

                        };
                        break;
                    default:
                }
            } else if (sipStage === 'Initial Review') {
                switch (decisionType) {
                    case 'Revise':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'ReviseSipTemplate',
                        };
                        postBody = {
                            sipCode,
                            comments,
                            deadline: addition.deadline,
                            emailcontents: null,
                            sipVersion,
                        };
                        break;
                    case 'Withdraw':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'WithdrawEmailTemplate'
                        };
                        postBody = {
                            sipCode,
                            comments: comments,
                            sipVersion: sipVersion,
                        };
                        break;
                    case 'Reject':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'RejectSipTemplate'
                        };
                        postBody = {
                            sipCode,
                            comments,
                            sipVersion: sipVersion,
                        };
                        break;
                    case 'Publisher Inquiry':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'DecisionJpmInquiry'
                        };
                        postBody = {
                            sipCode,
                            comments,
                            assignee: addition.assignee,
                            journalCode: addition.journalCodes,
                            sipVersion,
                        };
                        break;
                    // case "Cancel JPM Inquiry":
                    //   url = "/sip/decision/getCancelJPMInquiryEmailTemplate"
                    //   postBody = {
                    //     sipCode: sipCode,
                    //     comments: comments
                    //   }
                    //   break;
                    case 'Transfer back to JPA':
                        url = 'todo';
                        postBody = {
                            //todo
                            sipCode: sipCode,
                            comment: comments,
                        };
                        break;
                    case 'Transfer to Another CE':
                        url = 'todo';
                        // url = '/sip/decision/transferToAnotherCE';

                        postBody = {
                            sipCode: sipCode,
                            handlingCe: addition.handlingCe,
                            comments: comments,
                        };
                        break;
                    case 'Assign to Editor':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'AssignToEditorEmailTemplate'
                        };
                        postBody = {
                            sipCode,
                            comments,
                            journalEditors: addition.journalEditors,
                            sipVersion,
                        };
                        if (addition.journal) postBody.journal = addition.journal;
                        break;
                    case 'Move to Commissioning Editor Review':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'MoveToCommissioningEditorReview'
                        };
                        postBody = {
                            sipCode,
                            comments,
                            ceId: addition.handlingCe,
                            sipVersion,
                        };
                        break;
                    case 'Direct Acquire':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'DirectAcquire'
                        };
                        postBody = {
                            sipCode,
                            comments,
                            sipVersion,
                        };
                    default:
                        break;
                }
            } else if (sipStage === 'Revision') {
                switch (decisionType) {
                    case 'Initial Review - Cancel Revision':  // get the email template
                    case 'Cancel Revision':  // get the email template
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'GetCancelRevisionToInitialReviewEmailTemplate' // defined in NewSipController on server
                        };
                        postBody = {
                            sipCode: sipCode,
                            comments: comments,
                            sipVersion: sipVersion,
                        };
                        break;
                    case 'Withdraw':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'WithdrawEmailTemplate'
                        };
                        postBody = {
                            sipCode,
                            comments: comments,
                            sipVersion: sipVersion,
                        };
                        break;
                    default:
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'ReviseSipTemplate'
                        };
                        postBody = {
                            sipCode,
                            comments: comments,
                            sipVersion: sipVersion,
                        };
                }
            } else if (sipStage === 'Ideation') {
                switch (decisionType) {
                    case 'Move to Submitted Stage':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'MoveToSubmitted'
                        };
                        postBody = {
                            sipCode,
                            comments,
                            sipVersion,
                            flow: addition.flow
                        };
                        break;
                    case 'Revise':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'ReviseSipTemplate'
                        };
                        postBody = {
                            sipCode,
                            type: 'reviseSiptemplate',
                            comments,
                            deadline: addition.deadline,
                            emailcontents: null,
                            sipVersion,
                        };
                        break;
                    case 'Withdraw':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'IdeationWithdrawEmailTemplate'
                        };
                        postBody = {
                            sipCode,
                            comments,
                            sipVersion,
                        };
                        break;
                    case 'Reject':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
                        reqParam = {
                            requestType: 'RejectSipTemplate'
                        };
                        postBody = {
                            sipCode,
                            comments,
                            sipVersion,
                        };
                        break;
                }
            }

            const res = await axios_instance.post(url, postBody, {
                params: reqParam,
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            // 修改 获取邮件模板的状态码
            dispatch({ type: SET_GET_EMAIL_CODE, data: res.status });

            if (res.status === 200) {
                if (
                    (decisionType === 'Withdraw' ||
                        decisionType === 'Assign to Editor' ||
                        sipStage === 'Editor Review') &&
                    res?.data?.code === 200
                ) {
                    dispatch({ type: SET_EMAIL_ALL, data: res?.data?.data });
                    let newSipStatus = {
                        ...sipStatus,
                        sipVersion: res.data.sipVersion,
                    };
                    dispatch({
                        type: GET_SIP_STATUS, 
                        data: {...newSipStatus}
                    });
                } else if (res.data.code === SIP_VERSION_ERROR) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.sipStatusChanged',
                            {},
                            SEVERITIES.warning
                        )
                    );
                    getSIPStatus(sipCode);
                } else if (
                    SIP_CHECKLIST_ERROR_CODE_ARR.includes(res?.data?.code) &&
                    isSubmitted(sipStage)
                ) {
                    //M2S7
                    setTimeout(() => setButtonAlertButtonsSipChecklistNotProceeded());
                    resolve(SIP_CHECKLIST_NOT_PROCEEDED_CODE);
                } else {
                    dispatch({ type: SET_EMAIL_ALL, data: res?.data?.data });
                }
            }
        } catch (err) {
        }
        resolve(200);
    };
}; // 发送邮件的通用方法 TODO
// addition 用来传递不同email下特殊的参数
export const sendEmailBody = (
    sipStage,
    decisionType,
    sipCode,
    comments,
    emailBody,
    addition,
    attachments,
    setButtonAlertButtonsSipChecklistNotProceeded = () => {
    },
    resolve = e => e,
    sipVersion,
    sipStatus,
    flow = null,
    subject,
    to,
    cc,
) => {
    console.log('enter');
    return async dispatch => {
        try {
            let url, postBody;
            if (sipStage === 'Submitted') {
                switch (decisionType) {
                    case 'Revise':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=reviseSubmit`;
                        postBody = {
                            updateDecisionRequests: {
                                comments: comments,
                                deadline: addition.deadline,
                                emailContent: forEmail(emailBody),
                                attachment: extractAttachmentId(attachments),
                            },
                        };
                        break;
                    case 'Withdraw':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=sendWithdrawEmail`;
                        postBody = {
                            updateDecisionRequests: {
                                emailContent: forEmail(emailBody),
                                comments: comments,
                                attachment: extractAttachmentId(attachments),
                            }

                        };
                        break;
                }
            }
            else if (sipStage === 'Editor Review') {
                switch (decisionType) {
                    case 'Cancel Editor Review':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=sendCancelEmail`;
                        postBody = {
                            updateDecisionRequests: {
                                emailContent: forEmail(emailBody),
                                comments: comments,
                                attachment: extractAttachmentId(attachments),
                            }

                        };
                        break;
                    default:
                }
            } else if (sipStage === 'Publisher Inquiry') {
                switch (decisionType) {
                    case 'Cancel Publisher Inquiry':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=cancelJpmInquiryEmail`;
                        postBody = {
                            cancelJpmInquiryEmailRequests: {
                                attachment: extractAttachmentId(attachments),
                                comments: comments,
                                mailSend: addition.mailSend,
                            },
                        };
                        break;
                    default:
                }
            } else if (sipStage === 'Initial Review') {
                switch (decisionType) {
                    case 'Revise':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=reviseSubmit`;
                        postBody = {
                            updateDecisionRequests: {
                                comments: comments,
                                deadline: addition.deadline,
                                emailContent: forEmail(emailBody),
                                attachment: extractAttachmentId(attachments),
                                emailSubject: subject,
                                emailTo: to,
                                emailCc: cc,
                            },
                        };
                        break;
                    case 'Reject':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=rejectInitial`;
                        postBody = {
                            updateDecisionRequests: {
                                reasons: addition.selectedReasons,
                                comments: comments,
                                emailContent: forEmail(emailBody),
                                attachment: extractAttachmentId(attachments)
                            },
                        };
                        break;
                    case 'Publisher Inquiry':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=jpmInquirySendEmail`;
                        postBody = {
                            updateDecisionRequests: {
                                comments: comments,
                                assignee: addition.assigneeEmail,
                                journalCode: addition.journalCodes,
                                mailSend: addition.mailSend,
                                attachment: extractAttachmentId(attachments),
                            },
                        };
                        break;
                    case 'Transfer back to JPA':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=transferBackToJpa`;
                        postBody = {
                            updateDecisionRequests: {
                                comments: comments,
                            },
                        };
                        break;
                    case 'Transfer to Another CE':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=transferToAnotherCe`;
                        postBody = {
                            updateDecisionRequests: {
                                comments: comments,
                                handlingCe: addition.handlingCe,
                            },
                        };
                        break;
                    case 'Assign to Editor':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=assignToEditorEmail`;
                        postBody = {
                            updateDecisionRequests: {
                                emailContent: forEmail(emailBody),
                                comments: comments,
                                attachment: extractAttachmentId(attachments),
                                emailSubject: sipCode.indexOf("SSIP") >= 0 ? subject : null,
                                emailTo: sipCode.indexOf("SSIP") >= 0 ? to : null,
                                emailCc: sipCode.indexOf("SSIP") >= 0 ? cc : null,
                            },
                        };
                        break;
                    case 'Withdraw':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=sendWithdrawEmail`;
                        postBody = {
                            updateDecisionRequests: {
                                emailContent: forEmail(emailBody),
                                comments: comments,
                                attachment: extractAttachmentId(attachments),
                            }

                        };
                        break;
                    case 'Move to Commissioning Editor Review':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=moveToCommissioningEditorReview`;
                        postBody = {
                            updateDecisionRequests: {
                                emailContent: forEmail(emailBody),
                                comments: comments,
                                attachment: extractAttachmentId(attachments),
                                handlingCe: addition.handlingCe,
                            }
                        };
                        break;
                    case 'Direct Acquire':
                        console.log('enter direact acquire');
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=directAccept`;
                        postBody = {
                            updateDecisionRequests: {
                                emailContent: forEmail(emailBody),
                                comments: comments,
                                attachment: extractAttachmentId(attachments),
                                directAcquisitionReason: addition.directAcquireReason,
                            }
                        };
                    default:
                        break;
                }
            } else if (sipStage === 'Revision') {
                switch (decisionType) {
                    case 'Initial Review - Cancel Revision':
                    case 'Cancel Revision':
                        // to fix: the following line is a bug
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=initialReviewCancelRevision`;
                        postBody = {
                            updateDecisionRequests: {
                                emailContent: forEmail(emailBody),
                                comments,
                                attachment: extractAttachmentId(attachments),
                            }
                        };
                        break;
                    default:
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=sendWithdrawEmail`;
                        postBody = {
                            updateDecisionRequests: {
                                emailContent: forEmail(emailBody),
                                comments,
                                attachment: extractAttachmentId(attachments),
                            }

                        };
                }
            } else if (sipStage === 'Ideation') {

                switch (decisionType) {
                    case 'Move to Submitted Stage':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=moveToSubmitted`;
                        postBody = {
                            updateDecisionRequests: {
                                comments: comments,
                                flow: flow,
                                emailContent: forEmail(emailBody),
                                attachment: extractAttachmentId(attachments),
                            },
                        };
                        break;
                    case 'Revise':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=reviseSubmit`;
                        postBody = {
                            updateDecisionRequests: {
                                comments: comments,
                                deadline: addition.deadline,
                                emailContent: forEmail(emailBody),
                                attachment: extractAttachmentId(attachments),
                            },
                        };
                        break;
                    case 'Withdraw':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=ideationWithdraw`;
                        postBody = {
                            updateDecisionRequests: {
                                comments: comments,
                                mailSend: emailBody ? emailBody : null,
                                attachment: extractAttachmentId(attachments),
                            },
                        };
                        break;
                    case 'Reject':
                        url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=ideationReject`;
                        postBody = {
                            updateDecisionRequests: {
                                reasons: addition.selectedReasons,
                                mailSend: emailBody ? emailBody : null,
                                comments: comments,
                                attachment: extractAttachmentId(attachments),
                            },
                        };
                        break;
                }
            }

            const res = await axios_instance.put(url, postBody, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const { code } = res.data;

            if (code === 200) {
                dispatch(getSIPStatus(sipCode));
                let newSipStatus = {
                    ...sipStatus,
                    sipVersion: res.data.sipVersion,
                };
                dispatch({
                    type: GET_SIP_STATUS, data: {
                        ...newSipStatus
                    }
                });

                // TODO for new api, there are no url starts with '/sip/decision/ideation'
                if (decisionType === 'Withdraw' && !url.includes('/sip/decision/ideation')) {
                    await dispatch(updateSIPStageToSheveld(sipCode, res.data.sipVersion, sipStatus));
                } else if ((url.includes('/sip/decision/ideation') || url.includes(`/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${res.data.sipVersion}&action=updateDecision&decisionType=sendWithdrawEmail`)) && emailBody != null) {
                    // use in ideation stage withdraw or reject decision and have no leadge
                    dispatch(
                        setSnackbarMessageAndOpen('email.sendSuccess', {}, SEVERITIES.success)
                    );
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(res?.data?.message, {}, SEVERITIES.success)
                    );
                }

            } else if (code === SIP_VERSION_ERROR) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusChanged',
                        {},
                        SEVERITIES.warning
                    )
                );
                getSIPStatus(sipCode);
            } else if (
                SIP_CHECKLIST_ERROR_CODE_ARR.includes(res?.data?.code) &&
                isSubmitted(sipStage)
            ) {
                //M2S7
                setTimeout(() => setButtonAlertButtonsSipChecklistNotProceeded());
                resolve(SIP_CHECKLIST_NOT_PROCEEDED_CODE);
            } else {
                dispatch(
                    setSnackbarMessageAndOpen('email.sendError', {}, SEVERITIES.error)
                );
            }
            dispatch({ type: SET_SEND_EMAIL_CODE, data: code });
        } catch (err) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'email.updateError',
                    {},
                    SEVERITIES.error
                )
            );

        }
        resolve(200);
    };
};

/**
 *  unused code below:
 * */
// export const getEmailRevisionBody = (sipCode, comments, deadline) => {
//     return async dispatch => {
//         try {
//             const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails`;
//             const res = await axios_instance.post(
//                 url,
//                 {
//                     sipCode,
//                     comments,
//                     deadline: deadline,
//                     emailcontents: null,
//                 },
//                 {
//                     params: {
//                         requestType: 'ReviseSipTemplate',

//                     },
//                     headers: {
//                         authorization: localStorage.getItem('authorization'),
//                     },
//                 }
//             );
//             if (res.status !== 200) {
//                 dispatch({ type: GET_EMAIL_REVISION_BODY, data: null });
//             } else {
//                 dispatch({ type: GET_EMAIL_REVISION_BODY, data: res.data.content });
//                 dispatch({ type: SET_REVISE_SUBJECT, data: res.data.title });
//                 dispatch({ type: SET_REVISE_FROM, data: res.data.from });
//                 dispatch({ type: SET_REVISE_TO, data: res.data.to });
//                 dispatch({ type: SET_REVISE_CC, data: res.data.copy });
//                 dispatch({ type: SET_REVISE_BCC, data: res.data.from });
//                 dispatch({ type: SET_EMAIL_ALL, data: res });
//             }
//         } catch (err) {
//             dispatch({ type: GET_EMAIL_REVISION_BODY, data: null });
//         }
//     };
// };

export const sendEmailRevisionBody = (
    sipCode,
    comments,
    deadline,
    emailBody
) => {
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=reviseSubmit`;
            const res = await axios_instance.put(
                url,
                {
                    updateDecisionRequests: {
                        comments: comments,
                        deadline: addition.deadline,
                        emailContent: forEmail(emailBody),
                    },
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            const { code } = res.data;
            dispatch({ type: SEND_EMAIREVISIONCODE, data: code });
            if (code == 200) {
                dispatch(
                    setSnackbarMessageAndOpen('email.sendSuccess', {}, SEVERITIES.success)
                );
            } else if (code === SIP_VERSION_ERROR) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusChanged',
                        {},
                        SEVERITIES.warning
                    )
                );
                getSIPStatus(sipCode);
            } else {
                dispatch(
                    setSnackbarMessageAndOpen('email.sendError', {}, SEVERITIES.error)
                );
            }
        } catch (err) {
            dispatch({ type: SEND_EMAIREVISIONCODE, data: err.data.timestamp });
            dispatch(
                setSnackbarMessageAndOpen(
                    'email.updateError',
                    {
                        value: err.data.error,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
};
export const getUserRejectReasonDropList = () => {
    return async dispatch => {
        try {
            const res = await axios_instance.get(`/api/v1/sip-service/special-issue-proposals/EMPTY`, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
                params: {
                    type: 'rejectReasons'
                }
            });
            const { code } = res.data;
            if (code === 200) {
                dispatch({
                    type: GET_USER_REJECT_REASON_DROPLIST,
                    data: res.data.data,
                });
            } else {
                dispatch({ type: GET_USER_REJECT_REASON_DROPLIST, data: [] });
            }
        } catch (err) {
            dispatch({ type: GET_USER_REJECT_REASON_DROPLIST, data: [] });
        }
    };
};

export function SubmitDirectAccept({ sipCode, directAcquisitionReason, comments, sipVersion }) {
    return async dispatch => {
        try {
            let res = await axios_instance.put(
                `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=directAccept`,
                {
                    updateDecisionRequests: {
                        comments: comments,
                        reason: directAcquisitionReason,
                    }
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                simpleDisplayError(res.data, 'success');
                dispatch(getSIPStatus(sipCode));
                dispatch(getObject(sipCode));
                dispatch(getSIPInformation(sipCode));
                dispatch(getGEInformation(sipCode));
                dispatch(getContentCommissioningApproaches(sipCode));
                dispatch(CheckSipNonCatJournal(sipCode));
                return res.data.sipVersion;
            } else if (res.data.code === SIP_VERSION_ERROR) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusChanged',
                        {},
                        SEVERITIES.warning
                    )
                );
                getSIPStatus(data.sipCode);
            } else {
                simpleDisplayError(res.data);
            }
        } catch (e) {
            simpleDisplayError(e);
        }
    };
}

export function SubmitMoveToInitialReview({ sipCode, comments, sipVersion, operationsLead }) {
    return async dispatch => {
        try {
            let res = await axios_instance.put(
                `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=moveToInitialReview`,
                {
                    updateDecisionRequests: {
                        comments,
                        operationsLead,
                    }
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                simpleDisplayError(res.data, 'success');
                dispatch(getSIPStatus(sipCode));
                dispatch(getObject(sipCode));
                dispatch(getSIPInformation(sipCode));
                dispatch(getGEInformation(sipCode));
                dispatch(getContentCommissioningApproaches(sipCode));
                dispatch(CheckSipNonCatJournal(sipCode));
                dispatch(setSnackbarMessageAndOpen('sipDetail.moveToInitialReviewSuccess', {}, SEVERITIES.success));
                return res.data.sipVersion;
            } else if (res.data.code === SIP_VERSION_ERROR) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusChanged',
                        {},
                        SEVERITIES.warning
                    )
                );
                getSIPStatus(sipCode);
            } else if (res.data.code === EMAIL_DOMAIN_ERROR) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.moveToInitialReviewOpsLeadEmailError',
                        {},
                        SEVERITIES.warning
                    )
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        res.data.message,
                        {},
                        SEVERITIES.warning
                    )
                );
            }
        } catch (e) {
            simpleDisplayError(e);
        }
    };
}

export function intialReviewMoveToEBMReview({ sipCode, comments, sipVersion }) {
    return async dispatch => {
        try {
            let res = await axios_instance.put(
                `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=moveToEBMReview`,
                {
                    updateDecisionRequests: {
                        comments,
                    }
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                simpleDisplayError(res.data, 'success');
                dispatch(getSIPStatus(sipCode));
                dispatch(getObject(sipCode));
                dispatch(getSIPInformation(sipCode));
                dispatch(getGEInformation(sipCode));
                dispatch(getContentCommissioningApproaches(sipCode));
                dispatch(CheckSipNonCatJournal(sipCode));
                dispatch(setSnackbarMessageAndOpen('sipDetail.moveToEBMReviewSuccess', {}, SEVERITIES.success));
                return res.data.sipVersion;
            } else if (res.data.code === SIP_VERSION_ERROR) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusChanged',
                        {},
                        SEVERITIES.warning
                    )
                );
                getSIPStatus(sipCode);
            } else {
                simpleDisplayError(res.data);
            }
        } catch (e) {
            simpleDisplayError(e);
        }
    };
}

export function assignToScreener({ sipCode, comments, sipVersion, screener, isReassignScreener = false }) {
    const Assign_To_Screener = 'assignToScreener';
    return async dispatch => {
        try {
            let res = await axios_instance.put(
                `/api/v1/sip-service/special-issue-proposals/${sipCode}?sipVersion=${sipVersion}&action=updateDecision&decisionType=${Assign_To_Screener}`,
                {
                    updateDecisionRequests: {
                        comments,
                        screener,
                    }
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                simpleDisplayError(res.data, 'success');
                dispatch(getSIPStatus(sipCode));
                dispatch(getObject(sipCode));
                dispatch(getSIPInformation(sipCode));
                dispatch(getGEInformation(sipCode));
                dispatch(getContentCommissioningApproaches(sipCode));
                dispatch(CheckSipNonCatJournal(sipCode));
                if (isReassignScreener) {
                    dispatch(setSnackbarMessageAndOpen('sipDetail.reassignToScreenerSuccess', {}, SEVERITIES.success));
                } else {
                    dispatch(setSnackbarMessageAndOpen('sipDetail.assignToScreenerSuccess', {}, SEVERITIES.success));
                }
                return res.data.sipVersion;
            } else if (res.data.code === SIP_VERSION_ERROR) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusChanged',
                        {},
                        SEVERITIES.warning
                    )
                );
                getSIPStatus(sipCode);
            } else if (res.data.code === EMAIL_DOMAIN_ERROR) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.assignToScreenerEmailError',
                        {},
                        SEVERITIES.warning
                    )
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        res.data.message,
                        {},
                        SEVERITIES.warning,
                        null,
                        {
                            actionLink: '/simt/auth/sipRelated/sip/detail/' + sipCode,
                            actionTitle: 'Refresh',
                        },
                        null,
                        "You cannot Ressign Screener",
                    )
                );
            }
        } catch (e) {
            simpleDisplayError(e);
        }
    };
}

/*
 * @Author: Lone Yu
 * @Date: 2022-11-28 09:26:34
 * @Last Modified by: Lone Yu
 * @Last Modified time: 2022-12-01 22:47:30
 */
import { Box, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SiDetailToNext from '../../assets/SiDetailToNext.svg';
import SiDetailToPreview from '../../assets/SiDetailToPreview.svg';

export function SiDetailToPreviewAndNext(props) {
  // 传入的siList是存储所有sicode的列表
  let { siListIndex, siList, mode='SI' } = props;
  const navigate = useNavigate();

  const previewSiCode = siListIndex > 0 ? siList[siListIndex - 1] : null;
  const nextSiCode =
    siListIndex < siList.length ? siList[siListIndex + 1] : null;
  const previewSIIndex = siListIndex > 0 ? siListIndex - 1 : 0;
  const nextSIIndex = siListIndex < siList.length ? siListIndex + 1 : 0;

  const navigateToPreviewSI = () => {
    if(mode==='CM'){
      navigate(`/simt/auth/marketing/si/detail/${previewSiCode}?from=overview`);
      return;
    }
    navigate(`/simt/auth/siRelated/si/detail/${previewSiCode}?from=overview`);
  };

  const navigateToNextSI = () => {
    if(mode==='CM'){
      navigate(`/simt/auth/marketing/si/detail/${nextSiCode}?from=overview`);
      return;
    }
    navigate(`/simt/auth/siRelated/si/detail/${nextSiCode}?from=overview`);
  };
  return (
    <>
      <Grid container sx={{height: '38px'}}>
        <Grid xs={6}
        sx={{height: '38px'}}
        >
          {siList && siListIndex !== 0 ? (
            <Box>
              <Button onClick={navigateToPreviewSI} sx={{height: '38px'}}>
                <Box
                  sx={{
                    display: 'inline-block',
                    margin: '12px 12px 12px 0px',
                  }}
                >
                  <SiDetailToPreview />
                </Box>
                <Box
                  sx={{
                    width: 'auto',
                    height: '20px',
                    display: 'inline',
                    fontFamily: 'Open Sans',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.01em',
                    color: '#154AB6',
                  }}
                >
                  {previewSiCode}
                </Box>
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </Grid>
        <Grid xs={6}
                sx={{height: '38px'}}
                >
          {siList && siListIndex + 1 < siList.length ? (
            <Button
              sx={{
                float: 'right',
                height: '38px'
              }}
              onClick={navigateToNextSI}
            >
              <Box
                sx={{
                  width: 'auto',
                  height: '20px',
                  display: 'inline',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '20px',
                  letterSpacing: '0.01em',
                  color: '#154AB6',
                }}
              >
                {nextSiCode}
              </Box>
              <Box
                sx={{
                  display: 'inline-block',
                  margin: '12px 0px 12px 12px',
                }}
              >
                <SiDetailToNext />
              </Box>
            </Button>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
}

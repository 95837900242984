/* eslint-disable indent */
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SectionTitle from './compontents/SectionTitle';
import GEChecklistFolder from './compontents/GEChecklistFolder';
import ChecklistItemBlock from './compontents/ChecklistItemBlock';
import RadioBlock from './compontents/RadioBlock';
import OnlyTextBlock from './compontents/OnlyTextBlock';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { calcStatus } from './utils';
import { CANCEL_EDIT, setGeItemValue } from '@/actions/SIMT-SIP/SIPChecklistSubmitted/SIPChecklistAction';
import StyledLink from '@/componentsphase2/StyledLink';
import FlaggedGESynchronizationAndVerification from './compontents/FlaggedGESynchronizationAndVerification';
import {
  calcFlaggedGESynchronizationAndVerificationStatus,
  FlaggedGESynchronizationAndVerificationTable
} from '@/componentsphase2/FlaggedGESynchronizationAndVerificationTable';
import GEFlagSection from './compontents/GEFlagSection';
import SliderBlock from '@/componentsphase2/Slider/SilderBlock';
import { isSubmitted } from '../SIPDetailPage/utils/isSubmitted';
import { getSIPGEFlagState } from "../../../utils/sipFilterUtils";
import {getFlaglist} from "@/actions/SIMT-SI/GuestEditor/Config";
const { parse, stringify } = JSON;
export class GEChecklistItem {
  constructor(key, title, info, required, type) {
    this.key = key;
    this.title = title;
    this.info = info;
    this.required = required;
    this.type = type;
  }
}
//USIP submittedStage的GEChecklistItems
export const GEChecklistItems = [
  //Normal Contact Details
  //To check whether the detailed information on SIP is complete and valid, including SIP Title, Aims and Scope as well as Topics.
  new GEChecklistItem(
    'normalContact',
    'Normal Contact Details',
    'To check whether this GE’s first name, last name and affiliation information are normal.',
    true
  ),
  //Institutional Email Address
  //To check whether the institutional email address of this GE has been provided. Please note that IEEE is not an institution.
  new GEChecklistItem(
    'institutionalEmail',
    'Institutional Email Address',
    'To check whether the institutional email address of this GE has been provided. Please note that IEEE is not an institution.',
    true
  ),
  //Email Address and Name Matching
  //To check whether the email address matches with this GE’s name. Please note that there could be slight variation in the name.
  new GEChecklistItem(
    'emailNameMatching',
    'Email Address and Name Matching',
    'To check whether the email address matches with this GE’s name. Please note that there could be slight variation in the name.',
    true
  ),
  //Valid Profile URL or CV
  //To check whether the valid URL to this GE’s profile webpage has been provided or the valid CV file has been uploaded. The profile webpages include but are not limited to personal webpage, Google Scholar page, ResearchGate page, institutional faculty information page and ORCID profile. For ORCID profile, if it’s empty or recently created one, then should consider raising a flag. Especially if the profile is empty, then we need to combine faculty pages to confirm whether the file is fake or not.
  new GEChecklistItem(
    'validProfileUrlCv',
    'Valid Profile URL or CV',
    'To check whether the valid URL to this GE’s profile webpage has been provided or the valid CV file has been uploaded. The profile webpages include but are not limited to personal webpage, Google Scholar page, ResearchGate page, institutional faculty information page and ORCID profile. For ORCID profile, if it’s empty or recently created one, then should consider raising a flag. Especially if the profile is empty, then we need to combine faculty pages to confirm whether the file is fake or not.',
    true
  ),
  //No Retraction History in Retraction Watch Database
  //To check whether this GE has any retraction history in Retraction Watch Database, searching by his/her first name and last name separately.
  new GEChecklistItem(
    'noRetractionHistory',
    (
      <span>
        No Retraction History in&nbsp;
        <StyledLink to='http://retractiondatabase.org/RetractionSearch.aspx' target='_blank'>
          Retraction Watch Database
        </StyledLink>
      </span>
    ),
    (
      <span>
        To check whether this GE has any retraction history in&nbsp;
        <StyledLink to='http://retractiondatabase.org/RetractionSearch.aspx' target='_blank'>
          Retraction Watch Database
        </StyledLink>
        , searching by his/her first name and last name separately.
      </span>
    ),
    true
  ),
  //Self-Citation Rate ≤ 25%
  //To check whether this GE’s self-citation rate is not above 25%.
  new GEChecklistItem(
    'selfCitationRate',
    'Self-Citation Rate ≤ 25%',
    'To check whether this GE’s self-citation rate is not above 25%.',
    true
  ),
  //Active Scholar in Recent 3-5 Years
  // To check whether this GE is active in recent 3-5 years.
  new GEChecklistItem(
    'activeScholarRecent',
    'Active Scholar in Recent 3-5 Years',
    'To check whether this GE is active in recent 3-5 years.',
    true
  ),
  //Publication History in Target or Competitor Journal(s)
  //To check whether this GE has published any articles in the target journal(s) or its competitor journal(s).
  new GEChecklistItem(
    'publicationHistory',
    'Publication History in Target or Competitor Journal(s)',
    'To check whether this GE has published any articles in the target journal(s) or its competitor journal(s).',
    false
  ),
  // new GEChecklistItem(
  //   FlaggedGESynchronizationAndVerificationTable,
  //   'Flagged GE Synchronization and Verification',
  //   '',
  //   true,
  // ),
  new GEChecklistItem(
    'newFlagAddition',
    'New Flag Addition to GE',
    'Should GE be flagged to draw attention?',
    true,
  ),
];
//USIP initialReviewStage的GEChecklistItems
export const initialReviewStageGEChecklistItems = [
  new GEChecklistItem(
    'normalPublicationRecords',
    'Normal Publication Records',
    'To check whether the output number is dramatically large and whether should be flagged. Depending on the subject area.',
    true,
  ),
  new GEChecklistItem(
    'relevantPublicationHistory',
    'Relevant Publication History',
    'To check how many articles this researcher has published relating to the selected SI topic. Please input the number of his/her publication records.',
    true,
    'numberRange',
  ),
  new GEChecklistItem(
    'eligibleHIndexCitationRecords',
    'Eligible H-index and citation performance',
    'To check whether H-index and citation records of the researcher meet your portfolio’s or journal’s criteria',
    true,
  ),
  new GEChecklistItem(
    'adequateEditorialExperiences',
    'Adequate Editorial Experiences',
    'To check whether the researcher has adequate editorial experiences as expected (i.e., Reviewer, editorial board member, guest editor experience). If the researcher has acted as Guest Editor, to check the performance of previously edited special issues (i.e., number of published articles, citation information, predatory publishing)',
    false,
  ),
  new GEChecklistItem(
    'diverseGeTeam',
    'Diverse GE Team',
    'To check the co-GE list if available and see whether the co-GE(s) represent regional/topical diversity.',
    false,
  ),
  new GEChecklistItem(
    'appropriateAuthorRecommendation',
    'Appropriate Author Recommendation',
    'To check the potential author list if available and see whether the proposed authors are good scholars and have regional diversity.',
    false,
  ),
  new GEChecklistItem(
    'noSanctionsCompliance',
    'No Sanctions Compliance',
    <span>
      To check whether the researcher has any sanctions compliance. Please refer to&nbsp;
      <StyledLink to='https://wiley.sharepoint.com/:w:/r/teams/legal-dept/_layouts/15/Doc.aspx?sourcedoc=%7BF5EC5A36-C891-4527-BFAD-F736479852A3%7D&file=Sanctions%20Guidance.docx&action=default&mobileredirect=true&DefaultItemOpen=1' target='_blank'>
        Sanctions Guidance.docx (sharepoint.com)
      </StyledLink>
      &nbsp;and&nbsp;
      <StyledLink to='https://wiley.sharepoint.com/teams/legal-dept/Policies/Forms/AllItems.aspx?id=%2Fteams%2Flegal%2Ddept%2FPolicies%2FExternal%20Sanctions%20Compliance%20Workflow%202023%2Epdf&parent=%2Fteams%2Flegal%2Ddept%2FPolicies' target='_blank'>
        Wiley Legal & Government Partnerships and Policy - External Sanctions Compliance Workflow 2023.pdf - All Documents (sharepoint.com)
      </StyledLink>
      . If so, please pay attention to not paying GE, and to the future sanction compliance rules at Wiley
    </span>,
    false,
  ),
  new GEChecklistItem(
    'noConcernsFromResearchCommunity',
    'No Concerns from Research Community',
    'To seek comments/opinions from your network, if possible.',
    false,
  ),
];
//SSIP initialReviewStage的GEChecklistItems
export const SSIPInitialReviewStageGEChecklistItems = [
  //Normal Contact Details
  new GEChecklistItem(
    'normalContact',
    'Normal Contact Details',
    'To check whether this GE’s first name, last name and affiliation information are normal.',
    true,
  ),
  //Institutional Email Address
  new GEChecklistItem(
    'institutionalEmail',
    'Institutional Email Address',
    'To check whether the institutional email address of this GE has been provided. Please note that IEEE is not an institution.',
    true,
  ),
  //Email Address and Name Matching
  new GEChecklistItem(
    'emailNameMatching',
    'Email Address and Name Matching',
    'To check whether the email address matches with this GE’s name. Please note that there could be slight variation in the name.',
    true,
  ),
  //Valid Profile URL or CV
  new GEChecklistItem(
    'validProfileUrlCv',
    'Valid Profile URL or CV',
    'To check whether the valid URL to this GE’s profile webpage has been provided or the valid CV file has been uploaded. The profile webpages include but are not limited to personal webpage, Google Scholar page, ResearchGate page, institutional faculty information page and ORCID profile. For ORCID profile, if it’s empty or recently created one, then should consider raising a flag. Especially if the profile is empty, then we need to combine faculty pages to confirm whether the file is fake or not.',
    true,
  ),
  //Eligible H-index and citation performance
  new GEChecklistItem(
    'eligibleHIndexCitationRecords',
    'Eligible H-index and citation performance',
    'To check whether H-index and citation records of the researcher meet your portfolio’s or journal’s criteria',
    true,
  ),
  //Self-Citation Rate ≤ 25%
  new GEChecklistItem(
    'selfCitationRate',
    'Self-Citation Rate ≤ 25%',
    'To check whether this GE’s self-citation rate is not above 25%.',
    true,
  ),
  //No Retraction History in Retraction Watch Database
  new GEChecklistItem(
    'noRetractionHistory',
    (
      <span>
        No Retraction History in&nbsp;
        <StyledLink to='http://retractiondatabase.org/RetractionSearch.aspx' target='_blank'>
          Retraction Watch Database
        </StyledLink>
      </span>
    ),
    (
      <span>
        To check whether this GE has any retraction history in&nbsp;
        <StyledLink to='http://retractiondatabase.org/RetractionSearch.aspx' target='_blank'>
          Retraction Watch Database
        </StyledLink>
        , searching by his/her first name and last name separately.
      </span>
    ),
    true,
  ),
  //Normal Publication Records
  new GEChecklistItem(
    'normalPublicationRecords',
    'Normal Publication Records',
    'To check whether the output number is dramatically large and whether should be flagged. Depending on the subject area.',
    true,
  ),
  //Relevant Publication History
  new GEChecklistItem(
    'relevantPublicationHistory',
    'Relevant Publication History',
    'To check how many articles this researcher has published relating to the selected SI topic. Please input the number of his/her publication records.',
    true,
    'numberRange',
  ),
  //Active Scholar in Recent 3-5 Years
  new GEChecklistItem(
    'activeScholarRecent',
    'Active Scholar in Recent 3-5 Years',
    'To check whether this GE is active in recent 3-5 years.',
    true,
  ),
  //Publication History in Target or Competitor Journal(s)
  new GEChecklistItem(
    'publicationHistory',
    'Publication History in Target or Competitor Journal(s)',
    'To check whether this GE has published any articles in the target journal(s) or its competitor journal(s).',
    false,
  ),
  //Adequate Editorial Experiences
  new GEChecklistItem(
    'adequateEditorialExperiences',
    'Adequate Editorial Experiences',
    'To check whether the researcher has adequate editorial experiences as expected (i.e., Reviewer, editorial board member, guest editor experience). If the researcher has acted as Guest Editor, to check the performance of previously edited special issues (i.e., number of published articles, citation information, predatory publishing)',
    false,
  ),
  //Diverse GE Team
  new GEChecklistItem(
    'diverseGeTeam',
    'Diverse GE Team',
    'To check the co-GE list if available and see whether the co-GE(s) represent regional/topical diversity.',
    false,
  ),
  //Appropriate Author Recommendation
  new GEChecklistItem(
    'appropriateAuthorRecommendation',
    'Appropriate Author Recommendation',
    'To check the potential author list if available and see whether the proposed authors are good scholars and have regional diversity.',
    false,
  ),
  //No Sanctions Compliance
  new GEChecklistItem(
    'noSanctionsCompliance',
    'No Sanctions Compliance',
    <span>
      To check whether the researcher has any sanctions compliance. Please refer to&nbsp;
      <StyledLink to='https://wiley.sharepoint.com/:w:/r/teams/legal-dept/_layouts/15/Doc.aspx?sourcedoc=%7BF5EC5A36-C891-4527-BFAD-F736479852A3%7D&file=Sanctions%20Guidance.docx&action=default&mobileredirect=true&DefaultItemOpen=1' target='_blank'>
        Sanctions Guidance.docx (sharepoint.com)
      </StyledLink>
      &nbsp;and&nbsp;
      <StyledLink to='https://wiley.sharepoint.com/teams/legal-dept/Policies/Forms/AllItems.aspx?id=%2Fteams%2Flegal%2Ddept%2FPolicies%2FExternal%20Sanctions%20Compliance%20Workflow%202023%2Epdf&parent=%2Fteams%2Flegal%2Ddept%2FPolicies' target='_blank'>
        Wiley Legal & Government Partnerships and Policy - External Sanctions Compliance Workflow 2023.pdf - All Documents (sharepoint.com)
      </StyledLink>
      . If so, please pay attention to not paying GE, and to the future sanction compliance rules at Wiley
    </span>,
    false,
  ),
  //No Concerns from Research Community
  new GEChecklistItem(
    'noConcernsFromResearchCommunity',
    'No Concerns from Research Community',
    'To seek comments/opinions from your network, if possible.',
    false,
  ),
  new GEChecklistItem(
    'newFlagAddition',
    'New Flag Addition to GE',
    'Should GE be flagged to draw attention?',
    true,
  ),
];
export const getGEChecklistItems = (sipStage, sipCode) => {
  switch (sipStage) {
    case 'initial':
      return sipCode.startsWith('SSIP') ?
        SSIPInitialReviewStageGEChecklistItems
        : initialReviewStageGEChecklistItems;
    case 'submitted':
    default:
      return GEChecklistItems;
  }
};
export default function ChecklistForGESection(props) {
  const {
    sipCode,
    stage,
    editProposerVO,
    setEditProposerVO,
    submitProposerVO,
    setSubmitProposerVO,
    AddGEError,
    setAddGEError,
    mustTransferBack = false
  } = props;
  // const { sipStage = stage } = useParams();
  const { sipStage: paramSipStage } = useParams();
  const { stage: sipStage = paramSipStage } = props;
  const dispatch = useDispatch();
  const selector = state => {
    return {
      SIPChecklistDetail: state.SIPChecklist.SIPChecklistDetail,
      proceeding: state.SIPChecklist.proceeding,
      proposer: state.SIPChecklist.proposer,
    };
  };
  const { SIPChecklistDetail, proceeding, proposer } = useSelector(selector);
  const flagsList = useSelector(state => state.GE.flagsList);
  const { status, geList, immediateDecision, locked, ...other } = SIPChecklistDetail;

  function handleValueChange(witch, newValue, idx) {
    if (witch === 'newFlagAddition') {
      if (newValue === 'No') {
        // dispatch(setGeItemValue('flags', [...(geList[idx]['flagsBackup'] ?? [])], idx));
        dispatch(setGeItemValue('flags', [], idx));
      } else {
        // if ((geList[idx]['flags']?.length ?? 0) == 0){
        dispatch(setGeItemValue('flags', [{
          flagId: 0,
          flagName: '',
          comment: '',
          fileNames: []
        }], idx));
        dispatch(setGeItemValue('flagsEditingState', 'error', idx));
        // }
      }
    }
    dispatch(setGeItemValue(witch, newValue, idx));
  }

  function calcCommentsError(required, radioValue, comments) {
    if (!(required && radioValue === 'No')) return false;
    return proceeding && !comments;
  }

  function calcItemBlockStatus(required, radioValue, comments, sipStage) {
    let status = calcStatus(required, radioValue, comments, sipStage);
    if (required && proceeding && status === 'default') {
      status = 'error';
    }
    if (comments?.length > 100000) {
      return 'error';
    }
    return status;
  }
  function calcItemBlockStatusOfGEFlag(required, radioValue, ge) {
    if (required && radioValue === 'No') return 'checked';
    if (radioValue !== 'Yes' && radioValue !== 'No') return 'default';
    return getSIPGEFlagState(status === 'Editing', ge['flags'], flagsList, radioValue === 'No', !(submitProposerVO || proceeding));
  }
  function getRequired(required, sipCode = 'SIP') {
    switch (sipStage) {
      case 'initial':
        return required && !immediateDecision && (SIPChecklistDetail['sipTopicAndAssignedPortfolioMatching'] === 'Yes' || sipCode.includes('SSIP'));
      default:
        return required && !immediateDecision;
    }
  }

  useEffect(() => {
    startGEFlag();
  }, []);

  function handleAddClick(ge, index) {
    const flags = ge['flags'];
    const newFlags = [...flags, {
      flagId: 0,
      flagName: '',
      comment: '',
      fileNames: []
    }];
    dispatch(setGeItemValue('flags', newFlags, index));
  }

  const startGEFlag = async () => {
    dispatch(getFlaglist());
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <SectionTitle>Checklist for GE</SectionTitle>
      {(geList ?? []).map((ge, index) => {
        /**@typedef {import('@/componentsphase2/FlaggedGESynchronizationAndVerificationTable').radioGropListArray} radioGropListArray*/
        const isFlaggedGESynchronizationAndVerificationError = !calcFlaggedGESynchronizationAndVerificationStatus(SIPChecklistDetail?.geList?.[index]?.[FlaggedGESynchronizationAndVerificationTable] ?? parse(sessionStorage.getItem(FlaggedGESynchronizationAndVerificationTable) ?? '[]')?.[index]);
        const email = ge?.guestEditorInSip?.email;
        return (
          <GEChecklistFolder
            key={ge.geId}
            title={ge?.fullname ?? 'No Name'}
            geId={ge.geId}
            sipCode={sipCode}
            submitProposerVO={submitProposerVO || proceeding}
            mustTransferBack={SIPChecklistDetail.mustTransferBack}
          >
            {getGEChecklistItems(sipStage, sipCode).map(item => {

              return (
                <Box sx={{ opacity: mustTransferBack ? '0.5' : '1', }}>
                  <ChecklistItemBlock
                    id={`ge_${ge.geId}-checklist-${item.key}-itemBlock`} //注意：修改这个id要慎重！！！！！！
                    key={item.key}
                    title={item.title}
                    info={item.info}
                    necessary={getRequired(item.required, sipCode)}
                    mustTransferBack={mustTransferBack}
                    status={mustTransferBack === true ? 'default' : item.key !== 'newFlagAddition' ?
                      calcItemBlockStatus(
                        getRequired(item.required, sipCode),
                        ge[item.key],
                        ge[item.key + 'Comment'],
                        sipStage) :
                      calcItemBlockStatusOfGEFlag(item.required, ge[item.key], ge)
                    }
                    needClear={status === 'Editing' && !locked && !getRequired(item.required, sipCode) && item.key !== 'newFlagAddition'}
                    handleClear={() => {
                      handleValueChange(item.key, null, index);
                      handleValueChange(item.key + 'Comment', null, index);
                    }}
                    needAdd={item.key === 'newFlagAddition' && item.required && ge[item.key] === 'Yes' && status === 'Editing' && !locked}
                    handleAdd={item.key === 'newFlagAddition' && item.required && ge[item.key] === 'Yes' ?
                      () => { handleAddClick(ge, index); } : () => { }}
                  >
                    {//警告：需要新增其他类型时，请写成类似numberRange这样的单层分支形式，而不能嵌套分支！
                      item.key === 'relevantPublicationHistory'
                        ?

                        <SliderBlock
                          id={`ge-${ge.geId}-${item.key}-SliderBlock`}
                          disabled={
                            !(status === 'Editing' && !locked) || mustTransferBack
                          }
                          // displayDisabled={!(ge[item.key] instanceof Array && ge[item.key][0] !== null && ge[item.key][1] !== null)}
                          value={(ge[item.key] instanceof Array && ge[item.key][0] !== null && ge[item.key][1] !== null) ? [ge[item.key][0], ge[item.key][0] + 200] : [0, 200]}
                          needAsyncSetValue={true}
                          onChange={(e, newValue) => {
                            let start = 0;
                            if (ge[item.key] instanceof Array && start !== null && ge[item.key][1] !== null) {
                              start = ge[item.key][0];
                            }
                            let change = 0;
                            if (newValue[0] != start) {
                              change = newValue[0] - start;
                            } else {
                              change = newValue[1] - (start + 200);
                            }
                            const tempNewValue = [start + change,start + change +10];
                            if(tempNewValue[0]<0){
                              tempNewValue[0]=0;
                              tempNewValue[1]=10;
                            }
                            if(tempNewValue[0]>990){
                              tempNewValue[0]=990;
                              tempNewValue[1]=1000;
                            }

                            let oldValue=(ge[item.key] instanceof Array && ge[item.key][0] !== null && ge[item.key][1] !== null) ? [ge[item.key][0], ge[item.key][0] + 200] : [0, 200];
                            handleValueChange(item.key, tempNewValue, index);
                            //因为saveButton通过监听change事件来判断是否变动，该组件的onChange触发的是mouseEvent，所以再手动发一个event的change
                            if(!(item.key==='relevantPublicationHistory' && oldValue[0]===newValue[0] && newValue[1]===oldValue[1])){
                              const changeEvent =new Event('change', { bubbles: true});
                              const Element = document.getElementById(`ge-${ge.geId}-${item.key}-SliderBlock`);
                              Element.dispatchEvent(changeEvent);
                            }
                            
                          }
                          }
                          error={
                            getRequired(item.required, sipCode) && proceeding && !ge[item.key]
                          }
                          getSpecialLabel={(value) => {
                            return value[0] + ' - ' + (value[0] + 10);
                          }}
                          min={0}
                          max={1200}//特殊显示
                        />
                        : <></>}
                    {
                      item.key !== 'relevantPublicationHistory' ?
                        <RadioBlock
                          id={`ge-${ge.geId}-${item.key}-RadioBlock`}
                          title='Qualified:'
                          editing={status === 'Editing' && !locked}
                          value={ge[item.key]}
                          setValue={newValue => {
                            handleValueChange(item.key, newValue, index);
                          }}
                          radioGropList={['Yes', 'No']}
                          error={
                            getRequired(item.required, sipCode) && proceeding && !ge[item.key]
                          }
                          noTitle={item.key === 'newFlagAddition'}
                          disabled={mustTransferBack}
                        // disabled={immediateDecision}
                        /> : <></>
                    }
                    {item.key === 'newFlagAddition' ?
                      ((ge['flags'] !== undefined && ge['flags']?.length > 0 && ge[item.key] !== 'No') || ge[item.key] === 'Yes') ?  //TO DO
                        <GEFlagSection
                          proposerId={ge.geId}
                          geCode={ge?.geCode}
                          GEBasicInformation={null}
                          FlagData={ge['flags']}
                          FlagIndex={index}
                          isEdit={status === 'Editing' && !locked}
                          setAdd={() => { }}
                          data-selenium-id='SIPPage_CheckList-GEFlagSection'
                          buttonDisable={false}
                          // addGE={addGE}
                          // setAddGE={setAddGE}
                          editProposerVO={editProposerVO}
                          setEditProposerVO={setEditProposerVO}
                          submitProposerVO={submitProposerVO}
                          setSubmitProposerVO={setSubmitProposerVO}
                        ></GEFlagSection>
                        : <></>
                      : <></>}
                    {['newFlagAddition', 'relevantPublicationHistory'].includes(item.key) ?
                      null : <OnlyTextBlock
                        id={`ge-${ge.geId}-${item.key}`}
                        title={status === 'Editing' && !locked ? 'Comments' : 'Comments:'}
                        required={getRequired(item.required, sipCode) && ge[item.key] === 'No'}
                        value={ge[item.key + 'Comment']}
                        radioValue={ge[item.key]}
                        setValue={newValue => {
                          handleValueChange(item.key + 'Comment', newValue, index);
                        }}
                        // disabled={immediateDecision}
                        editing={status === 'Editing' && !locked}
                        {...(getRequired(item.required, sipCode)
                          ? {
                            isError: calcCommentsError(
                              getRequired(item.required, sipCode),
                              ge[item.key],
                              ge[item.key + 'Comment']
                            ),
                            errorMessage:
                              ' Please provide the required information.',
                          }
                          : {})}
                      />}

                  </ChecklistItemBlock>
                </Box>
              );
            })}
            {(isSubmitted(sipStage ?? '')
              || (sipStage === 'initial' && sipCode?.startsWith('SSIP'))
            ) && <ChecklistItemBlock
              id={`ge_${ge.geId}-checklist-${FlaggedGESynchronizationAndVerificationTable}-itemBlock`} //注意：修改这个id重！！！！！！
              title='Flagged GE Synchronization and Verification'
              info=''
              necessary={!immediateDecision}
              status={mustTransferBack === true ? 'default' : (isFlaggedGESynchronizationAndVerificationError ? (proceeding ? 'error' : 'default') : 'checked')}
              needClear={status === 'Editing' && !locked && immediateDecision}
              handleClear={() => {
                handleValueChange(FlaggedGESynchronizationAndVerificationTable, [], index);
                dispatch({ type: CANCEL_EDIT });
                // setTimeout(() =>
                // );
              }}
            >
                <FlaggedGESynchronizationAndVerification
                  setValue={(/**@type {radioGropListArray}*/newValue) => {
                    handleValueChange(FlaggedGESynchronizationAndVerificationTable, newValue, index);
                    const/**@type {Array<radioGropListArray>}*/arr = parse(sessionStorage.getItem(FlaggedGESynchronizationAndVerificationTable) ?? '[]');
                    arr[index] = newValue;
                    sessionStorage.setItem(FlaggedGESynchronizationAndVerificationTable, stringify(arr));
                  }}
                  editing={status === 'Editing' && !locked}
                  error={
                    proceeding && isFlaggedGESynchronizationAndVerificationError
                  }
                  value={{ fullname: ge?.fullname, email, geCode: ge?.geCode, index }}
                  processlogValue={ge?.siGeInfoList??ge?.geInfoList??undefined}
                />
              </ChecklistItemBlock>}
          </GEChecklistFolder>
        );
      })}
    </Box>
  );
}

import { Box } from '@mui/material';
import MarketEdit from '@/assets/MarketEdit2.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomEmailBlock from '../CommonModule/CustomEmailBlock';
import CustomText from '../CommonModule/CustomText';
import CustomLabel from '../CommonModule/CustomLabel';
import CustomContent, { commonCss } from '../CommonModule/CustomContent';
import CustomCKEditor from '../CommonModule/CustomCKEditor';
import RichTextEditor from '../../../modules/RichTextEditor/RichTextEditor';
import {
  replaceVariablesSymbolsForFrontend,
  replaceVariablesSymbolsForBackend,
  commonForSIP,
  emailVariables,
  replaceVariablesForFrontShow,
} from '@/modules/RichTextEditor/EditorUtils';
import { useIntl } from 'react-intl';
import MarketEditReset from '@/assets/MarketEditReset.svg';
import * as React from 'react';
import Chip from '@mui/material/Chip';
import { ButtonAlertBox } from '@/components/Alert/AlertBox';
import Button from '@mui/material/Button';
import styleModule from '@/componentsphase2/SIDetail/SaveAndCancelButtonForGeneralInfoSection.module.scss';
import ViewControlClosed from '@/assets/ViewControlClosed.svg';
import ViewControlOpened from '@/assets/ViewControlOpened.svg';
import ViewControlHovered from '@/assets/ViewControlHovered.svg';
import RedAlertCircleIcon from '@/assets/RedAlertCircleIcon.svg';
import MyLocalStorage from '@/utils/MyLocalStorage';
import {
  editSIPEmailTemplate, editSolicitedSIPEmailTemplate,
  editUserSIPEmailTemplate, editUserSolicitedSIPEmailTemplate,
  removeEmailRedDot,
  removeUserEmailManual
} from "@/actions/SIMT-Email/Template";

// 小标题
const CustomPartTitle = props => {
  const { children } = props;
  return (
    <Box
      data-selenium-id='EmailTemplates-SIPEmailTemplates-SIPEmailTemplateBlock-CustomPartTitle-Box'
      sx={{
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: ' 24px',
        display: 'flex',
        height: '24px',
        alignItems: 'center',
        color: '#262E35',
        marginRight: '4px',
      }}
    >
      {children}
    </Box>
  );
};

// 文本替换，将${} 变为 []
const textReplace = (props) => {
  if (props === null || props === undefined) {
    return '';
  }
  if (props.split){
    const arr = props.split('|');
    return arr.map(item => textReplaceSingle(item))
        .join(', ');
  }
  console.info('props: ',props);
  return textReplaceSingle(props);
};

const textReplaceSingle = (text) => {
  switch (text) {
    case '${user_email}':
      return '[System Admin making this decision]';
    case '${SIP_To_Lead_ge}':
      return "[Lead GE]";
    case '${SIP_Copy_Co_ge}':
      return "[Co-GE]";
    case 'JE_Typed_By_Handling_CE':
      return "[JE typed by Handling CE]";
    case '${Tier2_PD_Publisher}':
      return '[PD Publisher selected by Handling CE]';
    case 'Assignees_Selected_At_Initial_Review_Stage':
      return '[Assignee Selected at the Initial Review Stage]';
    case 'External_User':
      return '[External User]';
    case 'SIMT system':
      return 'simt_super@wiley.com';
    case '${SIP_To_JE}':
      return '[Journal Editor]';
    case '${PD_Publisher_email_address}':
      return '[PD Publisher]';
    case '${PRPL_email_address}':
      return '[PRPL]';
    case '${EiC_email_address}':
      return '[EIC]';
    case '${SIP_Operations_Lead_Name_Email}':
      return 'Operations Lead';
  }
  let testDiv = document.createElement('div');
  testDiv.innerHTML = text;
  let value = testDiv.innerText;
  if (value.indexOf('$') !== -1) {
    return value.replace(/\${/g, '[').replace(/}/g, ']').replace(/_/g, ' ');
  } else {
    return value;
  }
};

// 文本替换，将${} 去掉
const discardBracket = props => {
  let value = props;
  if (value.indexOf('$') !== -1) {
    return value.replace(/\${/g, '').replace(/}/g, '').replace(/_/g, ' ');
  } else {
    return value;
  }
};

const HardCodeFromShowPart = props => {
  const { text } = props;
  return <CustomText>{text}</CustomText>;
};

const HardCodeFromEditPart = props => {
  const { text } = props;
  if (text === 'Co-GE, Relevant Handling CE, PD Publisher, PRPL') {
    return (
      <>
        <CustomLabel>Co-GE</CustomLabel>,{' '}
        <CustomLabel>Relevant Handling CE</CustomLabel>,{' '}
        <CustomLabel>PD Publisher</CustomLabel>,
        <CustomLabel>PRPL</CustomLabel>
      </>
    );
  } else if (text === 'Co-GE, Relevant Handling CE') {
    return (
      <>
        <CustomLabel>Co-GE</CustomLabel>,{' '}
        <CustomLabel>Relevant Handling CE</CustomLabel>
      </>
    );
  } else if (text === 'PD Publisher, PRPL, EiC, All GE') {
    return (
      <>
        <CustomLabel>PD Publisher</CustomLabel>,
        <CustomLabel>PRPL</CustomLabel>, <CustomLabel>EiC</CustomLabel>,{' '}
        <CustomLabel>All GE</CustomLabel>
      </>
    );
  } else if (text === "GE(s), Selected Journal's PD Publisher") {
    return (
      <>
        <CustomLabel>GE(s)</CustomLabel>,{' '}
        <CustomLabel>Selected Journal's PD Publisher</CustomLabel>
      </>
    );
  } else if (text === 'Handling CE or Handling System Admin') {
    return (
      <>
        <CustomLabel>Handling CE</CustomLabel> or{' '}
        <CustomLabel>Handling System Admin</CustomLabel>
      </>
    );
  }
  return <CustomLabel>{text}</CustomLabel>;
};

const IconButtonStyle = {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const compareStyles = {
  margin: '16px 0px 8px 0px',
};

function SIPEmailTemplateBlock(props) {
  const {
    partTitle,
    contentDetail,
    block,
    type,
    nextKey,
    userCustomize = false,
    customize = null,
    isSolicited = false,
  } = props;
  const { content, subject, from, to, cc, bcc } = contentDetail;
  const [moreOrLess, setMoreOrLess] = useState(
    MyLocalStorage.getItem(`email-moreOrLess-${contentDetail.id}`, true)
  ); // true为more， false为less
  const [isEdit, setIsEdit] = useState(false); //展示和编辑状态切换的关键字
  const [isCompare, setIsCompare] = useState(
    MyLocalStorage.getItem(`email-isCompare-${contentDetail.id}`, false)
  );
  const [isCompareHover, setIsCompareHover] = useState(false);
  const redDots = useSelector(state => state.Email.redDots);
  const isDirty =
    userCustomize && redDots.some(dot => dot.templateId === contentDetail.id);
  const [fromFront, setFromFront] = useState('');
  const [toFront, setToFront] = useState('');
  const [ccFront, setCcFront] = useState('');
  const [bccFront, setBccFront] = useState('');
  const [emailContent, setEmailContent] = useState(content); // 修改email内容
  const [tempEmailContent, setTempEmailContent] = useState(emailContent); // 暂存email内容 在放弃修改时候使用

  const dispatch = useDispatch();
  const intl = useIntl(); // 为国际化提供的API支持

  useEffect(() => {
    if (isCompare && isDirty) {
      MyLocalStorage.setItem(`email-isCompare-${contentDetail.id}`, isCompare);
      MyLocalStorage.setItem(
        `email-moreOrLess-${contentDetail.id}`,
        moreOrLess
      );
      dispatch(removeEmailRedDot(contentDetail.id));
      setTimeout(() => {
        MyLocalStorage.removeItem(`email-isCompare-${contentDetail.id}`);
        MyLocalStorage.removeItem(`email-moreOrLess-${contentDetail.id}`);
      }, 5000);
    }
  }, [isCompare]);

  useEffect(() => {
    if (isDirty && !moreOrLess && !contentDetail.customize) {
      MyLocalStorage.setItem(
        `email-moreOrLess-${contentDetail.id}`,
        moreOrLess
      );
      dispatch(removeEmailRedDot(contentDetail.id));
      setTimeout(() => {
        MyLocalStorage.removeItem(`email-moreOrLess-${contentDetail.id}`);
      }, 5000);
    }
  }, [moreOrLess]);


  useEffect(() => {
    setFromFront(textReplace(from));
    setToFront(textReplace(to));
    setCcFront(textReplace(cc));
    setBccFront(textReplace(bcc));
  }, [from, to, cc, bcc]);

  // 原始subject为了在show和edit链接绑定 temp是用来cancel时候取消绑定 还原数据
  const [origSubjectDetail, setOrigSubjectDetail] = useState(subject);
  const [tempSubjectDetail, setTempSubjectDetail] = useState(origSubjectDetail); // 保存edit编辑时原始数据 如果放弃修改使用这个

  // 编辑状态时，点击取消按钮的操作
  const handleCancelClick = () => {
    setOrigSubjectDetail(
      replaceVariablesSymbolsForBackend(tempSubjectDetail, commonForSIP)
    );
    setEmailContent(
      replaceVariablesSymbolsForBackend(tempEmailContent, commonForSIP)
    );
    setIsEdit(false);
  };

  // 编辑状态时，点击保存按钮的操作
  const handleSaveClick = async () => {
    // 每次点击确定后 要更新暂存的内容
    setTempSubjectDetail(origSubjectDetail);
    setTempEmailContent(emailContent);
    // 点击确定后 要把当前更新的数据传到后台
    contentDetail.content = replaceVariablesSymbolsForBackend(
      emailContent,
      commonForSIP
    );
    contentDetail.subject = replaceVariablesSymbolsForBackend(
      origSubjectDetail,
      commonForSIP
    );
    let sumbmitter;
    if (userCustomize) {
      if (isSolicited) {
        sumbmitter = editUserSolicitedSIPEmailTemplate;
      } else {
        sumbmitter = editUserSIPEmailTemplate;
      }
    } else {
      if (isSolicited) {
        sumbmitter = editSolicitedSIPEmailTemplate;
      } else {
        sumbmitter = editSIPEmailTemplate;
      }
    }
    await dispatch(
      sumbmitter(
        contentDetail.id,
        contentDetail.block,
        type,
        replaceVariablesSymbolsForBackend(origSubjectDetail, commonForSIP),
        replaceVariablesSymbolsForBackend(emailContent, commonForSIP),
        contentDetail.name
      )
    );

    setOrigSubjectDetail(
      replaceVariablesSymbolsForBackend(origSubjectDetail, commonForSIP)
    );
    setEmailContent(
      replaceVariablesSymbolsForBackend(emailContent, commonForSIP)
    );
    setIsEdit(false);
  };

  const handleReset = () => {
    setButtonAlertOpen(true);
  };

  // 标题和编辑按钮
  // 这个组件是可以提出去的，不过需要修改一下props的内容
  const TopBlock = props => {
    const { title } = props;
    const containerStyle = (justifyContent, width) => {
      return {
        display: 'flex',
        justifyContent: justifyContent,
      };
    };
    {
      return (
        <Box
          sx={{
            ...containerStyle('flex-start', '100%'),
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <CustomPartTitle>{title}</CustomPartTitle>
          <Box
            sx={{
              ...IconButtonStyle,
              marginRight: '16px',
              '&:hover svg path': {
                fill: '#113D95',
              },
            }}
            onClick={() => setIsEdit(true)}
            id='SIPEmailTemplateEdit'
            data-selenium-id='EmailTemplates-SIPEmailTemplates-SIPEmailTemplateBlock-TopBlock-SIPEmailTemplateEdit'
          >
            <MarketEdit></MarketEdit>
          </Box>
          {userCustomize && customize ? (
            <Box
              sx={{
                ...IconButtonStyle,
                marginRight: '16px',
                '&:hover svg path': {
                  stroke: '#113D95',
                },
              }}
              onClick={() => handleReset()}
              id='MarketEditReset'
              data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
                .split(' ')
                .join('')}-TopBlock-MarketEditReset`}
            >
              <MarketEditReset></MarketEditReset>
            </Box>
          ) : null}
          {userCustomize && customize ? (
            <Box
              onClick={() => {
                setIsCompare(!isCompare);
                setMoreOrLess(false);
              }}
              onMouseEnter={e => {
                setIsCompareHover(true);
              }}
              onMouseLeave={() => {
                setIsCompareHover(false);
              }}
              sx={{
                ...IconButtonStyle,
                position: 'absolute',
                right: 0,
                backgroundColor: isCompare ? '#E8EDFB' : '#FFFFFF',
                borderRadius: '3.75px',
                border: isCompare
                  ? '0.75px solid #AEC6F6'
                  : isCompareHover
                    ? '1px solid #6D98EE'
                    : '0.75px solid #0052CC',
                padding: '3.75px',
                width: '24px',
                height: '24px',
              }}
              data-selenium-id={`EmailTemplates-CommonModule-ShowAndEdit-${partTitle
                .split(' ')
                .join('')}-TopBlock-ViewControl`}
            >
              {isCompare ? (
                <ViewControlOpened />
              ) : isCompareHover ? (
                <ViewControlHovered />
              ) : (
                <ViewControlClosed />
              )}
            </Box>
          ) : null}
        </Box>
      );
    }
  };

  const emailSubjectForShow = replaceVariablesForFrontShow(
    origSubjectDetail,
    emailVariables.commonForSIP
  );

  const [buttonAlertOpen, setButtonAlertOpen] = useState(false);
  const resetAlertButtons = [
    {
      text: 'Cancel',
      onClick: () => {
        setButtonAlertOpen(false);
        setIsEdit(false);
      },
    },
    {
      text: 'Ok',
      onClick: () => {
        dispatch(
          removeUserEmailManual(contentDetail.id, isSolicited ? 'SSIP' : 'USIP')
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        padding: '12px 20px 8px 20px !important',
        marginLeft: '-32px !important',
        marginTop: '16px !important',
        position: 'relative',
        borderRadius: '0px 4px 4px 0px',
        background: '#FFF',
        boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
      }}
      data-selenium-id='EmailTemplates-SIPEmailTemplates-SIPEmailTemplateBlock-Box'
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: '-8px',
          width: '8px',
          background: type === 'Manual' ? '#FFB152' : '#0052CC',
          borderRadius: '4px 0px 0px 4px',
        }}
      ></div>

      {isDirty ? (
        <RedAlertCircleIcon
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            marginRight: '-10px',
            marginTop: '-10px',
          }}
        />
      ) : null}

      <TopBlock title={partTitle}></TopBlock>

      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row',
          marginTop: '0px',
          '& .ShowAndEdit-LeftBox': {
            width: isCompare ? 'calc(50% + 74px)' : '100%',
            paddingTop: '0px',
            paddingBottom: '6px',
            paddingRight: '6px',
          },
          '& .ShowAndEdit-RightBox': {
            borderRadius: '4px',
            background: '#E8EDFB',
            width: 'calc(50% - 74px)',
            padding: '0px 6px 6px 6px',
            marginLeft: '16px',
          },
        }}
      >
        <Box className={'ShowAndEdit-LeftBox'}>
          {userCustomize ? (
            <CustomEmailBlock
              title={'Template Type:'}
              margin='16px 0px 8px 0px'
              sx={{
                lineHeight: '20px',
              }}
            >
              {customize ? (
                <Chip
                  label={'Customized'}
                  sx={{
                    borderRadius: '4px',
                    border: '1px solid var(--secondary-300, #A2DDE4)',
                    background: 'var(--secondary-100, #E7F6F8)',
                    color: '#1A7A85',
                    fontFamily: 'Open Sans',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    height: '24px',
                  }}
                />
              ) : (
                <Chip
                  label={'System Template'}
                  sx={{
                    borderRadius: '4px',
                    border: '1px solid #6D98EE',
                    background: '#CEDDF9',
                    color: '#113D95',
                    fontFamily: 'Open Sans',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    height: '24px',
                  }}
                />
              )}
            </CustomEmailBlock>
          ) : null}

          {isEdit ? (
            <>
              {isCompare ? null : (
                <Box
                  sx={{
                    marginTop: '12px',
                    color: '#EE5350',
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    lineHeight: '16px',
                    fontWeight: '400',
                    letterSpacing: '-0.084px',
                  }}
                  data-selenium-id='EmailTemplates-SIPEmailTemplates-SIPEmailTemplateBlock-Box-Box'
                >
                  {intl.messages['email.TagTip']}
                </Box>
              )}
              <CustomEmailBlock
                title='Subject:'
                margin={`${userCustomize ? 8 : 16}px 0px 8px 0px`}
              >
                <CustomCKEditor
                  content={replaceVariablesSymbolsForFrontend(
                    origSubjectDetail,
                    emailVariables.commonForSIP
                  )}
                  setContent={e => {
                    setOrigSubjectDetail(e);
                  }}
                  variables={emailVariables.commonForSIP}
                ></CustomCKEditor>
              </CustomEmailBlock>
              {!fromFront ? (
                <></>
              ) : (
                <CustomEmailBlock title='From:' margin='8px 0px'>
                  <HardCodeFromEditPart text={discardBracket(fromFront)} />
                </CustomEmailBlock>
              )}
              {!toFront ? (
                <></>
              ) : (
                <CustomEmailBlock title='To:' margin='8px 0px'>
                  <HardCodeFromEditPart text={discardBracket(toFront)} />
                </CustomEmailBlock>
              )}
              {!ccFront ? (
                <></>
              ) : (
                <CustomEmailBlock title='Cc:' margin='8px 0px'>
                  <HardCodeFromEditPart text={discardBracket(ccFront)} />
                </CustomEmailBlock>
              )}
              {!bccFront ? (
                <></>
              ) : (
                <CustomEmailBlock title='Bcc:' margin='8px 0px'>
                  <HardCodeFromEditPart text={discardBracket(bccFront)} />
                </CustomEmailBlock>
              )}

              <CustomEmailBlock title='Email Content:' margin='8px 0px'>
                <RichTextEditor
                  content={replaceVariablesSymbolsForFrontend(
                    emailContent,
                    emailVariables.commonForSIP
                  )}
                  setContent={e => {
                    setEmailContent(e);
                  }}
                  variables={emailVariables.commonForSIP}
                ></RichTextEditor>
              </CustomEmailBlock>

              <CustomEmailBlock title={''} margin='8px 0px'>
                <Box
                  className={styleModule.SaveAndCancelButtonBox}
                  sx={{
                    justifyContent: 'flex-start',
                    gap: '12px',
                  }}
                >
                  <Button
                    disableRipple
                    onClick={() => handleSaveClick()}
                    className={styleModule.SaveButton}
                    sx={{ width: '70px !important' }}
                  >
                    Save
                  </Button>
                  <Button
                    disableRipple
                    className={styleModule.CancelButton}
                    onClick={() => handleCancelClick()}
                    sx={{ width: '70px !important' }}
                  >
                    Cancel
                  </Button>
                </Box>
              </CustomEmailBlock>
            </>
          ) : (
            <>
              <CustomEmailBlock
                title='Subject:'
                margin={`${userCustomize ? 8 : 16}px 0px 8px 0px`}
              >
                <CustomText>{emailSubjectForShow.innerText} </CustomText>
              </CustomEmailBlock>

              {!moreOrLess ? (
                <>
                  {!fromFront ? null : (
                    <CustomEmailBlock title='From:' margin='8px 0px'>
                      <HardCodeFromShowPart text={textReplace(fromFront)} />
                    </CustomEmailBlock>
                  )}
                  {!toFront ? null : (
                    <CustomEmailBlock title='To:' margin='8px 0px'>
                      <HardCodeFromShowPart text={textReplace(toFront)} />
                    </CustomEmailBlock>
                  )}
                  {!ccFront ? null : (
                    <CustomEmailBlock title='Cc:' margin='8px 0px'>
                      <HardCodeFromShowPart text={textReplace(ccFront)} />
                    </CustomEmailBlock>
                  )}
                  {!bccFront ? null : (
                    <CustomEmailBlock title='Bcc:' margin='8px 0px'>
                      <HardCodeFromShowPart text={textReplace(bccFront)} />
                    </CustomEmailBlock>
                  )}
                </>
              ) : null}

              {/* Email Content*/}
              <CustomEmailBlock title='Email Content:' margin='8px 0px 0px 0px'>
                <CustomContent
                  id={nextKey}
                  data-selenium-id={nextKey}
                  value={emailContent}
                  maxHeight='68px'
                  moreOrLess={moreOrLess}
                  setMoreOrLess={val => {
                    if (val) {
                      setIsCompare(false);
                    }
                    setMoreOrLess(val);
                  }}
                  variables={emailVariables.commonForSIP}
                ></CustomContent>
              </CustomEmailBlock>
            </>
          )}
        </Box>
        {isCompare ? (
          <Box className={'ShowAndEdit-RightBox'}>
            <Box
              sx={{ ...compareStyles, lineHeight: '20px', marginTop: '16px' }}
            >
              <Chip
                label={'System Template'}
                sx={{
                  borderRadius: '4px',
                  border: '1px solid #6D98EE',
                  background: '#CEDDF9',
                  color: '#113D95',
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                  height: '24px',
                }}
              />
            </Box>

            <Box sx={{ ...compareStyles, margin: `8px 0px 8px 0px` }}>
              <CustomText>
                {replaceVariablesForFrontShow(
                  customize.subject,
                  emailVariables.full
                )?.innerText ?? ''}{' '}
              </CustomText>
            </Box>

            {!moreOrLess ? (
              <>
                {!fromFront ? null : (
                  <Box sx={{ margin: '8px 0px' }}>
                    <HardCodeFromShowPart text={textReplace(fromFront)} />
                  </Box>
                )}
                {!toFront ? null : (
                  <Box sx={{ margin: '8px 0px' }}>
                    <HardCodeFromShowPart text={textReplace(toFront)} />
                  </Box>
                )}
                {!ccFront ? null : (
                  <Box sx={{ margin: '8px 0px' }}>
                    <HardCodeFromShowPart text={textReplace(ccFront)} />
                  </Box>
                )}
                {!bccFront ? null : (
                  <Box sx={{ margin: '8px 0px' }}>
                    <HardCodeFromShowPart text={textReplace(bccFront)} />
                  </Box>
                )}
              </>
            ) : null}

            {/* Email Content*/}
            <Box
              sx={{
                margin: '8px 0px 0px 0px',
                wordBreak: 'break-word',
                '&>div': {
                  ...commonCss,
                  marginTop: '-14px',
                },
              }}
            >
              {React.createElement('div', {
                dangerouslySetInnerHTML: {
                  __html: replaceVariablesForFrontShow(
                    customize.content,
                    emailVariables.full
                  ).innerHTML,
                },
              })}
            </Box>
          </Box>
        ) : null}
      </Box>

      <ButtonAlertBox
        open={buttonAlertOpen}
        handleClose={() => {
          setButtonAlertOpen(false);
        }}
        title='Replace your customized template with the system template?'
        message='This action will overwrite changes you have made to your current template.'
        buttons={resetAlertButtons}
        severity='info'
        position={{ vertical: 'center', horizontal: 'center' }}
        autoHideDuration={-1}
        style={{
          width: '600px',
          height: '163px',
          top: 'calc(50vh - 80px) !important',
          padding: '24px 32px 20px 32px',
        }}
      />
    </Box>
  );
}
export default SIPEmailTemplateBlock;

import { SmallFont } from '@/components/FontStyle';
import { Box } from '@mui/material';

export default function InactiveBox(props) {
  const { value } = props;
  return (
    <Box
      sx={{
        ...SmallFont,

        // minWidth: '286px',
        // width:'calc(100% - 228px)',
        width: '286px',

        ...(value
          ? {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textDecoration: 'underline',
              color: '#262E35',
            }
          : { wordBreak: 'break-word', color: '#BCC5CF' }),
      }}
    >
      {value ?? 'Automatically filled after entering Journal Code/Name'}
    </Box>
  );
}

const MIMEMapping = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" : "docx",
  "application/msword" : "doc",
  "application/pdf" : "pdf"
};

export function hiddenFilenameTransform(filename) {
  if (!filename || !filename.includes(".")) {
    return filename;
  }
  let filenameArr = filename.split(".");
  filenameArr.pop();
  return filenameArr.join(".");
}

export function hiddenFiletypeTransform(filename) {
  if (!filename || !filename.includes(".")) {
    return "";
  }
  let filenameArr = filename.split(".");
  return filenameArr.pop();
}

export function getFileType(fileMIMEType) {
  if(fileMIMEType in MIMEMapping) {
    return MIMEMapping[fileMIMEType];
  }
  return null;
}

import { Box, Button } from '@mui/material';
import {
  drawer_main_block,
  drawer_main_block_operation,
  drawer_main_block_title,
  drawer_reset_date_button,
  drawer_main_block_fixed,
} from './style';

export function Block(props) {
  const { title } = props;

  return (
    <Box
      sx={drawer_main_block}
      data-selenium-id='GEPage_Overview-anotherNewFilterDrawer-Block-Box'
    >
      <Box
        sx={drawer_main_block_title}
        data-selenium-id='GEPage_Overview-anotherNewFilterDrawer-Block-Box-Title'
      >
        {title}
      </Box>
      <Box
        sx={drawer_main_block_operation}
        data-selenium-id='GEPage_Overview-anotherNewFilterDrawer-Block-Box-Operation'
      >
        {props.children}
      </Box>
    </Box>
  );
}

export function Block_Top(props) {
  const { title } = props;

  return (
    <Box
      sx={drawer_main_block_fixed}
      data-selenium-id='GEPage_Overview-anotherNewFilterDrawer-Block-TopContent-Box'
    >
      <Box
        sx={drawer_main_block_title}
        data-selenium-id='GEPage_Overview-anotherNewFilterDrawer-Block-Box-TopContent-Title'
      >
        {title}
      </Box>
      <Box
        sx={drawer_main_block_operation}
        data-selenium-id='GEPage_Overview-anotherNewFilterDrawer-Block-Box-TopContent-Operation'
      >
        {props.children}
      </Box>
    </Box>
  );
}
// export function  Block_For_Acquired_Date(props) {
//   const dispatch = useDispatch();
//   const handleAcquiredDateReset = () => {
//     dispatch(resetAcquiredDateFilter());
//   };
//   const { title } = props;
//   return (
//     <Box sx={drawer_main_block} data-selenium-id='GEPage_Overview-anotherNewFilterDrawer-Block-Box'>
//       <Box>
//         <Box sx={drawer_main_block_title} data-selenium-id='GEPage_Overview-anotherNewFilterDrawer-Block-Box-Title' >{title}</Box>
//         <Box
//           id='btnResetAcquiredDate'
//           data-selenium-id={`GEPage_Overview-anotherNewFilterDrawer-Overview-btnResetAcquiredDate`}
//           sx={drawer_reset_date_button}
//           onClick={handleAcquiredDateReset}
//         >
//               Reset
//         </Box>
//       </Box>
//       <Box sx={drawer_main_block_operation} data-selenium-id='GEPage_Overview-anotherNewFilterDrawer-Block-Box-Operation' >{props.children}</Box>
//     </Box>
//   );
// };

// export function Block_For_Deadline_Date(props) {
//   const dispatch = useDispatch();
//   const handleDeadlineDateReset = () => {
//     dispatch(resetDeadlineDateFilter());
//   };
//   const { title } = props;
//   return (
//     <Box sx={drawer_main_block} data-selenium-id='GEPage_Overview-anotherNewFilterDrawer-Block-Box'>
//       <Box>
//         <Box sx={drawer_main_block_title} data-selenium-id='GEPage_Overview-anotherNewFilterDrawer-Block-Box-Title' >{title}</Box>
//         <Box
//           id='btnResetDeadlineDate'
//           data-selenium-id={`GEPage_Overview-anotherNewFilterDrawer-Overview-btnResetDeadlineDate`}
//           sx={drawer_reset_date_button}
//           onClick={handleDeadlineDateReset}
//         >
//               Reset
//         </Box>
//       </Box>
//       <Box sx={drawer_main_block_operation} data-selenium-id='GEPage_Overview-anotherNewFilterDrawer-Block-Box-Operation' >{props.children}</Box>
//     </Box>
//   );
// };

import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  GetAAndAOAvsTotalChartData,
  GetAAndAOAvsTotalChartDataWithStatus,
} from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';

export default function useChartsDataHook(
  selectedDate = '2023-1',
  selectedSg,
  catagoryAttributeName,
  valueAttributeNames,
  url,
  title
) {
  const dispatch = useDispatch();
  const { reportDataAPI } = useSelector(state => {
    return {
      reportDataAPI: state.ReportDiagram[title],
    };
  });

  const { YTDChartDataAPI } = useSelector(state => {
    return {
      YTDChartDataAPI: state.ReportDiagram[title],
    };
  });

  let loading = null,
    error = null,
    data = null;

  if (typeof reportDataAPI !== 'undefined') {
    ({ loading, error, data } = reportDataAPI);
  }

  const dataForChart = useMemo(() => {
    return data;
  }, [data, title]);
  let YTDChartData = YTDChartDataAPI;


  useEffect(() => {
    // if (!reportDataAPI && selectedDate !== null) {
    if (typeof reportDataAPI === 'undefined' && selectedDate !== null) {
      dispatch(
        // GetAAndAOAvsTotalChartData(
        //   url,
        //   title,
        //   selectedDate,
        //   catagoryAttributeName,
        //   valueAttributeNames
        // )
        GetAAndAOAvsTotalChartDataWithStatus(
          url,
          title,
          selectedDate,
          catagoryAttributeName,
          valueAttributeNames
        )
      );
    }
  }, [url, selectedDate, title, reportDataAPI]);

  const catagoriesFullList = useMemo(() => {
    if (
      dataForChart != undefined &&
      dataForChart != null &&
      dataForChart.length != 0
    ) {
      return dataForChart
        .filter(record => {
          return (
            record[catagoryAttributeName] != '' &&
            record[catagoryAttributeName] != 'Total' &&
            record[catagoryAttributeName] != null
          );
        })
        .map(record => {
          return record[catagoryAttributeName];
        });
    }
    return [];
  }, [dataForChart]);

  const selectedDataForBar = useMemo(() => {
    if (dataForChart != undefined && dataForChart != null) {
      // if (selectedSg == undefined || selectedSg.length == 0)
      //   selectedSg = catagoriesFullList;
      return filterSelectedCEData(
        dataForChart,
        selectedSg,
        catagoryAttributeName
      );
    }
    return [];
  }, [dataForChart, selectedSg]);


  selectedDataForBar.forEach(item => {
    valueAttributeNames.forEach(prop => {
      item[prop] = item[prop] === 0 ? null : item[prop];
    });
    return item;
  });

  return [catagoriesFullList, selectedDataForBar, dataForChart, loading, error];
}

function filterSelectedCEData(
  subjectGroupAllData,
  selectedSg,
  catagoryAttributeName
) {
  subjectGroupAllData = subjectGroupAllData.filter(item => {
    return selectedSg.indexOf(item[catagoryAttributeName]) >= 0;
  });

  return subjectGroupAllData;
}

import { MapState, pureData } from "@/components/Form/form.d";
import { FlaggedGESynchronizationAndVerificationErrorType } from "@/componentsphase2/FlaggedGESynchronizationAndVerificationTable";
import { SNACKBAR_ID, SNACKBAR_SHOW } from "@/pages/SIPPage/SIPCheckListPage/configable/constants";
import { axios_instance } from "@/utils/axios_instance";
import React from "react";
import { Navigate } from "react-router-dom";
import { NavigateFunction } from "react-router/dist/lib/hooks";

export async function excuteCancelEdit(dispatch: React.Dispatch<any>, SIPChecklistDetail: MapState, sipCode?: string) {
    try {
        const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/checkLists`;
        const res = await axios_instance.put(url, SIPChecklistDetail, {
            params: {
                stage: 'initial',
                action: 'cancel-edit',
                version: SIPChecklistDetail.version,
            },
            headers: {
                authorization: localStorage.getItem('authorization') ?? '',
            },
        });

        if (res.data.code === 200) {
            dispatch({
                type: 'set',
                payload: {
                    value: res.data.data,
                    path: [],
                },
            });
            fireMessage(dispatch, INFO_NOT_SAVED);
        } else if (res.data.code === 14172) {
            fireMessage(dispatch, NO_AUTHORIZED);
        } else {
            fireMessage(dispatch, DEFAULT_WARNING, res.data?.message as string);
        }
    } catch (err: any) {
        fireMessage(dispatch, DEFAULT_ERROR, err?.message ?? err?.error ?? err ?? 'Request Failed, please check your check the request info.');
    }
}

export async function excuteEdit(dispatch: React.Dispatch<any>, sipCode: string, version: any, sipStage?: string) {
    try {
        const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/checkLists`;
        const res = await axios_instance.put(url, {
            version,
        }, {
            params: {
                version,
                stage: sipStage ?? 'initial',
                action: 'start-edit'
            },
            headers: {
                authorization: localStorage.getItem('authorization') ?? '',
            },
        });

        const { code, data } = res.data;
        if (code === 200) {
            dispatch({
                type: 'set',
                payload: {
                    path: [],
                    value: {
                        ...data,
                    }
                },
            });
        } else if (code === 14172) {
            fireMessage(dispatch, NO_AUTHORIZED);
        } else {
            fireMessage(dispatch, DEFAULT_WARNING, res.data?.message as string);
        }
    } catch (err: any) {
        fireMessage(dispatch, DEFAULT_ERROR, err?.message ?? err?.error ?? err ?? 'Request Failed, please check your check the request info.');
    }
}

export async function excuteUndo(dispatch: React.Dispatch<any>, sipCode: string, version: any, sipStage?: string) {
    try {
        const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/checkLists`;
        const res = await axios_instance.put(url, {
            version,
        }, {
            params: {
                version,
                stage: sipStage,
                action: 'undo'
            },
            headers: {
                authorization: localStorage.getItem('authorization') ?? '',
            },
        });

        const { code, data } = res.data;
        if (code === 200) {
            dispatch({
                type: 'set',
                payload: {
                    path: [],
                    value: {
                        ...res.data.data,
                    }
                },
            });
        } else if (code === 16079) {
            fireMessage(dispatch, WARNING_UNDO_REFRESH);
        } /*else if (code === 16067) {
            fireMessage(dispatch, WARNING_UNDO_ACTION);
        } */else if (code === 16081) {
            fireMessage(dispatch, WARNING_PROCEED_REFRESH);
        } else if (code === 14172) {
            fireMessage(dispatch, NO_AUTHORIZED);
        } else {
            fireMessage(dispatch, DEFAULT_WARNING, res.data?.message as string);
        }
    } catch (err: any) {
        fireMessage(dispatch, DEFAULT_ERROR, err?.message ?? err?.error ?? err ?? 'Request Failed, please check your check the request info.');
    }
}

export async function excuteSave(dispatch: React.Dispatch<any>, SIPChecklistDetail: MapState, sipCode: string | undefined) {
    SIPChecklistDetail.geList.forEach((ge: { flags: any[]; }) => {
        if (JSON.stringify(ge.flags) ===
            JSON.stringify([{ flagId: 0, flagName: '', comment: '', fileNames: [] }])) {
            ge.flags = [];
        }
    });

    const checklist = pureData(SIPChecklistDetail);

    try {
        const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/checkLists`;
        // @ts-ignore
        const res = await axios_instance.put(url, checklist, {
            headers: {
                authorization: localStorage?.getItem('authorization') ?? '',
            },
            params: {
                version: SIPChecklistDetail.version,
                stage: 'initial',
                action: 'save'
            },
        });

        if (res.data.code === 200) {
            const detail = res.data.data;
            dispatch({
                type: 'set',
                payload: {
                    value: detail,
                    path: [],
                },
            });
            fireMessage(dispatch, SUCCESS_SAVE);
        } else if (res.data.code === 14172) {
            fireMessage(dispatch, NO_AUTHORIZED);
        } else {
            fireMessage(dispatch, DEFAULT_WARNING, res.data?.message as string);
        }
    } catch (err: any) {
        fireMessage(dispatch, DEFAULT_ERROR, err?.message ?? err?.error ?? err ?? 'Request Failed, please check your check the request info.');
    }
}

export async function excuteProceed(dispatch: React.Dispatch<any>, SIPChecklistDetail: MapState, sipCode?: string) {
    SIPChecklistDetail.geList.forEach((ge: { flags: any[]; }) => {
        ge.flags = ge.flags.filter(flag => flag.flagId !== 0)
            .map(flag => {
                return {
                    flagId: flag.flagId,
                    flagName: flag.flagName,
                    comment: flag.comment,
                    fileNames: flag.fileNames || [],
                };
            });
    });

    const checklist = pureData(SIPChecklistDetail);

    try {
        const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/checkLists`;
        const res = await axios_instance.put(url, checklist, {
            params: {
                stage: 'initial',
                action: 'proceed',
                version: SIPChecklistDetail.version,
            },
            headers: {
                authorization: localStorage.getItem('authorization') ?? '',
            },
        });

        if (res.data.code === 200) {
            dispatch({
                type: 'set',
                payload: {
                    value: res.data.data,
                    path: ['_proceedResult'],
                },
            });
        } else if (res.data.code === 16081) {
            fireMessage(dispatch, WARNING_PROCEED_REFRESH);
        } else if (res.data.code === 14172) {
            fireMessage(dispatch, NO_AUTHORIZED);
        } else {
            fireMessage(dispatch, DEFAULT_WARNING, res.data?.message as string);
        }
    } catch (err: any) {
        fireMessage(dispatch, DEFAULT_ERROR, err?.message ?? err?.error ?? err ?? 'Request Failed, please check your check the request info.');
    }
}

export const WARNING_CHECKPOINTS = 0;
export const SUCCESS_SAVE = 1;
export const INFO_NOT_SAVED = 2;
export const WARNING_UNDO_REFRESH = 3;
// export const WARNING_UNDO_ACTION = 4;
export const WARNING_PROCEED_REFRESH = 31;
export const WARNING_MORE_THAN_ONE_MATCHED_GE = FlaggedGESynchronizationAndVerificationErrorType.moreThanOneMatchedGE;
export const WARNING_NOT_MATCHED_GE = FlaggedGESynchronizationAndVerificationErrorType.notMatchedGE;
export const NO_AUTHORIZED = 7;

export const DEFAULT_INFO = 10;
export const DEFAULT_WARNING = 11;
export const DEFAULT_ERROR = 12;

export function fireMessage(dispatch: React.Dispatch<any>, messageId: number, title?: string, message?: string) {
    dispatch({
        type: 'set',
        payload: {
            path: [SNACKBAR_SHOW],
            value: true
        },
    });

    dispatch({
        type: 'set',
        payload: {
            path: [SNACKBAR_ID],
            value: messageId
        },
    });

    dispatch({
        type: 'set',
        payload: {
            path: ['_snackbarMessage'],
            value: {
                title,
                message,
            }
        }
    });
}

export function getMessageInfo(snackbarId: number, navigateToSipDetail: () => void, title?: string, message?: string) {
    switch (snackbarId) {
        case WARNING_CHECKPOINTS:
            return {
                type: 'warning',
                title: 'You cannot proceed yet!',
                message:
                    'Please accomplish all the checkpoints before proceeding with submitting a decision.',
                actions: [],
            };
        case SUCCESS_SAVE:
            return {
                type: 'success',
                title: 'Your recent inputs have been saved successfully!',
                actions: [],
            };
        case INFO_NOT_SAVED:
            return {
                type: 'info',
                title: 'System didn’t save your most recent action.',
                actions: [],
            };
        case WARNING_UNDO_REFRESH:
            return {
                type: 'warning',
                title: 'Not able to undo.',
                message: 'Refresh the page to check most recent status.',
                needRefresh: true,
                actions: [
                    {
                        name: 'Refresh',
                        exe: () => {
                            location.reload();
                        },
                    },
                ],
            };
        // case WARNING_UNDO_ACTION:
        //     return {
        //         type: 'warning',
        //         title: 'Another user has already performed the undo action.',
        //         message: 'Refresh the page to check most recent status.',
        //         needRefresh: true,
        //         actions: [
        //             {
        //                 name: 'Refresh',
        //                 exe: () => {
        //                     location.reload();
        //                 },
        //             },
        //         ],
        //     };
        case WARNING_PROCEED_REFRESH:
            return {
                type: 'warning',
                title: 'You cannot complete checklist at this moment.',
                message: 'Because the screener has been assigned to another person.Refresh the page to check most recent status.',
                needRefresh: true,
                actions: [
                    {
                        name: 'Refresh',
                        exe: navigateToSipDetail,
                    },
                ],
            };
        case WARNING_MORE_THAN_ONE_MATCHED_GE: {
            return {
                type: 'warning',
                title: 'You cannot match more than one SIMT GE to a proposer',
            };
        }
        case WARNING_NOT_MATCHED_GE: {
            return {
                type: 'warning',
                title: 'Please check Flagged GE section before proceeding',
                message: 'You can choose one SIMT GE as matched or all SIMT GE as mismatched.',
            };
        }
        case NO_AUTHORIZED: {
            return {
                type: 'warning',
                title: 'You are not authorized to perform this action',
                message: 'Please contact Operation Leader to assign you as the Screener.',
                needRefresh: true,
                actions: [
                    {
                        name: 'Refresh',
                        exe: navigateToSipDetail,
                    },
                ],
            };
        }
        case DEFAULT_INFO:
            return {
                type: 'info',
                title: title ?? '',
                message: message ?? '',
            };
        case DEFAULT_WARNING:
            return {
                type: 'warning',
                title: title ?? '',
                message: message ?? '',
            };
        case DEFAULT_ERROR:
            return {
                type: 'error',
                title: title ?? '',
                message: message ?? '',
            };
        default:
            return {
                type: '',
                title: '',
            };
    }
}
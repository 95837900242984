
import MyIconButton from '@/componentsphase2/MyIconButton';
import { StateButton } from '@/componentsphase2/StateButton';
import React from 'react';
import LockIcon from '../../assets/lock.svg';

export default function EditButton(props) {
  const {
    disabled=false,
    onClick=()=>{},
    ...other
  }=props;

  return (
    <StateButton
      titleName={disabled?'Checklist in editing':'Edit'}
      startIcon={disabled?<LockIcon />:null}
      disabled={disabled}
      disabledThemeColor='#fff'
      sx={{
        height: '41px',
        width: disabled?'202px':'144px',
        fontSize:'14px !important',
        backgroundColor: disabled?'#98A7B6 !important':null,
        textTransform:'none',
        '& *':{
          color:'#fff !important'
        },
      }}
      onClick={onClick}
      {...other}
    />
  );
}

import {
  BarAndLineChartOptions,
  LEGEND_STYLE,
} from '@/pages/Report/chartUtils/chartOptions/BarAndLineChartOptions.js';
import {BUS_DIV} from '../../Constants';

/**
 * overwrite the default configuration
 */
const BARCHART_THRESHOLD = 7;
const BARCHART_TOP = 65;
const BAR_LEGEND_HEIGHT = 70;
const BARCHART_HEIGHT = [350, 350];

const BAR_COLOUR_MAP = new Map([
  ['STMS Health Sciences', '#7882DC'],
  ['STMS Life Sciences', '#EE5350'],
  ['STMS Physical Sciences & Engg', '#00A4B7'],
  ['STMS Social Sci & Humanities', '#4DBBFF'],
]);

const BAR_COLOUR = ['#4C81EB', '#45BCCA', '#FFB152', '#71C8FF', '#FF8180', '#9098E2', '#48A79A', '#FF8A00', '#0052CC', '#C40000'];

export class ArticleOverviewOption extends BarAndLineChartOptions {
  constructor(catagoryAttributeName) {
    super();
    // overwrite the default properties
    this.barchartHeight = BARCHART_HEIGHT[1];
    this.showSubjectGroups = catagoryAttributeName === BUS_DIV ?? true;
  }

  getTooltip() {
    return [
      {
        ...this.tooltip,
        formatter: params => {
          let position = params.seriesIndex + 1;
          let dataStr = `<div style="padding: 0; margin: 0;">
            <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 0 0 2.5px 0;">${params.name}</p>
            <p style="font-size: 10px; font-weight: 400;color: #848484; line-height: 14px; margin: 0;">${params.seriesName}&nbsp;:&nbsp;${params.data[position]}</p>
          </div>`;
          return dataStr;
        },
      },
    ];

    //    return {};
  }

  getBarchartHeight() {
    let height = BARCHART_HEIGHT[0];
    if (this.dataForBar && this.dataForBar.length > BARCHART_THRESHOLD) {
      height = BARCHART_HEIGHT[1];
    }
    return height;
  }

  getBarChartTop() {
    return BARCHART_TOP;
  }

  getLegendHeight() {
    return BAR_LEGEND_HEIGHT;
  }

  setLegendSG(legendSG) {
    this.legendSG = legendSG;
    return this;
  }

  setLegendBD(legendBD) {
    this.legendBD = legendBD;
    return this;
  }

  getSelectedLegend() {
    return [LEGEND_STYLE];
  }

  getSeries() {
    let dataSet = this.getDataset();
    const series = [];
    if (dataSet && dataSet[0] && dataSet[0].source) {
      dataSet[0].source.forEach((graphDataItem, i) => {
        if (i !== 0) { // the 1st row is ['STMS Health Sciences', 'Submission-Nov', 'Submission-YTD', 'Acceptance-Nov', 'Acceptance-YTD', 'Publication-Nov', 'Publication-YTD']
          let barColour;
          if(this.showSubjectGroups){
            barColour = BAR_COLOUR[i - 1];  
          }else{
            barColour = BAR_COLOUR_MAP.get(graphDataItem[0]);
          }
          series.push({
            type: 'bar',
            seriesLayoutBy: 'row',
            color: barColour,
            barMinHeight: 12,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'inside',
                  verticalAlign: 'left',
                  lineHeight: 2,
                  textStyle: {
                    color: '#FFFFFF',
                    fontSize: 10,
                    fontWeight: 600,
                  },
                  formatter: params => {
                    return params.value[i] === '0' ? '' : params.value[i];
                  },
                },
              },
            },
          });
        }
      });
    }
    return series;
  }
}

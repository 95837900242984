import * as TYPES from '../actions/SIMT-SIP/FlaggedGEProposer/FlaggedGEProposerAction.js';
const initState = [
  {
    geList: [
      {
        activeScholarRecent: 'string',
        activeScholarRecentComment: 'string',
        emailNameMatching: 'string',
        emailNameMatchingComment: 'string',
        fullname: 'string',
        geCode: 'string',
        geId: 0,
        guestEditorInSip: {
          city: 'string',
          conflictDetail: 'string',
          country: 'string',
          cv: 'string',
          department: 'string',
          email: 'string',
          firstName: 'string',
          hasConflict: true,
          id: 0,
          institution: 'string',
          jobTitle: 'string',
          lastName: 'string',
          orcidId: 'string',
          researchGateId: 'string',
          updateBy: 'string',
          updateTime: '2023-11-01T12:39:04.586Z',
          website: 'string',
        },
        institutionalEmail: 'string',
        institutionalEmailComment: 'string',
        noRetractionHistory: 'string',
        noRetractionHistoryComment: 'string',
        normalContact: 'string',
        normalContactComment: 'string',
        publicationHistory: 'string',
        publicationHistoryComment: 'string',
        readyToVerify: 0,
        selfCitationRate: 'string',
        selfCitationRateComment: 'string',
        sipCode: 'string',
        validProfileUrlCv: 'string',
        validProfileUrlCvComment: 'string',
      },
    ],
    immediateDecision: 'string',
    lastModifyTime: '2023-11-01T12:39:04.586Z',
    lastModifyUser: 0,
    locked: true,
    overallComment: 'string',
    sipCode: 'string',
    sipTitle: 'string',
    status: 'string',
    validSipDetails: 'string',
    validSipDetailsComments: 'string',
    version: 0,
  },
];
export default function FlaggedGEProposerReducer(state = initState, action) {
  const { type, data } = action;
  // console.log('FlaggedGEProposer: data', data);
  switch (type) {
    case TYPES.SET_FLAGGED_GE_PROPOSER_DETAIL:
      return {
        ...state,
        FlaggedGEProposer: data.data,
      };
    case TYPES.DO_FLAGGED_GE_VERIFY:
      const { sipCode } = data ?? {};
      let newProposers = state?.FlaggedGEProposer ?? [];
      const sipIndex = newProposers.findIndex(sip => sip.sipCode === sipCode);
      newProposers = newProposers.splice(sipIndex,1,data);
      return {
        ...state,
        FlaggedGEProposer: newProposers,
      };
    default:
      return state;
  }
}

// @ts-check
import { Typography } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/styles';

/**
 *
 * @param {import("./CustomTypography").ItemBlockShowTextProps} props
 * @returns
 */
export const ItemBlockShowText = props => {
  const { sx, children, ...others } = props;
  return (
    <Typography
      data-selenium-id='SIP_RevisePage_CustomComponents-Typography-ItemBlockShowText'
      sx={{
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        textAlign: 'left',
        color: '#596A7C',
        ...sx,
      }}
      {...others}
    >
      {children}
    </Typography>
  );
};

/** @type {import("react").CSSProperties} */
const PoliticalAuthorsTableTextStyle = {
  fontFamily: 'Open Sans',
  fontSize: '14px',
  lineHeight: '20px',
  paddingTop: '12px',
  paddingBottom: '12px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export const PoliticalAuthorsTableNameText = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    ...PoliticalAuthorsTableTextStyle,
    fontWeight: 600,
    color: '#262E35',
  },
}));

export const PoliticalAuthorsTableAffiliationText = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    ...PoliticalAuthorsTableTextStyle,
    fontWeight: 400,
    color: '#262E35',
  },
}));

export const PoliticalAuthorsTableEmailText = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    ...PoliticalAuthorsTableTextStyle,
    fontWeight: 600,
    color: '#154AB6',
  },
}));

export const PoliticalAuthorsTableTopicText = styled(
  PoliticalAuthorsTableAffiliationText
)(() => ({}));

import {
  Box,
  Stack,
  IconButton,
  Popover,
} from '@mui/material';
import SendEmailIcon from '@/assets/SendEmailIcon2.svg';
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import { FormattedMessage } from 'react-intl';
import styleModule from './GeneralInfoSection.module.scss';
import { useState,useEffect } from 'react';
import {PERMISSIONS} from "@/constant/permission";
export default function (props) {
  const {
    stageId,
    handleClickSendEmailIcon,
    emailPopoverOpen,
    emailAnchorEl,
    handleClose,
    StyledTypography,
    siDetail = {}
  }
    = props;
  const randonId = useState(Math.floor(Math.random() * 1000000))[0];
  useEffect(() => {
    setTimeout(() => {
      try {
        document.querySelector(`[randombuttonId="${randonId}"]`).addEventListener('click', handleClickSendEmailIcon);
      } catch (e) { };
    }, 100);
    return () => {
      try {
        document.querySelector(`[randombuttonId="${randonId}"]`).removeEventListener('click', handleClickSendEmailIcon);
      } catch (e) { };
    };
  }, []);
  return (
    <>
      {
        <ShowForPermission
          permission={`${PERMISSIONS.SI_WRITE}`}
          data-selenium-id='SIDetail_GeneralInfoSection-ShowForPermission'
        >
          <IconButton
            id='sendEmailButton'
            randombuttonId={randonId}
            data-selenium-id='SIDetail_GeneralInfoSection-SendEmailBtn'
            className={
              stageId == 0 || stageId == 6
                ? 'disabledEmailButton'
                : null
            }
            sx={{ mb: '3px', ml: '8px' }}
            // onClick={handleClickSendEmailIcon}
            // onclick is not working, so use addEventListener
            disabled={stageId == 0 || stageId == 6}
          >
            <SendEmailIcon data-selenium-id='SIDetail_GeneralInfoSection-SendEmailBtnIcon'
              className={styleModule.SendEmailIcon}
            />
          </IconButton>
        </ShowForPermission>
      }
      <Popover
        id={emailPopoverOpen ? 'emailTemplatesDropdownList' : undefined}
        data-selenium-id={
          emailPopoverOpen
            ? 'SIDetail_GeneralInfoSection-EmailTemplatesDropdownList'
            : undefined
        }
        open={emailPopoverOpen}
        anchorEl={emailAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={styleModule.emailTemplatesDropdownList}
      >
        <Stack
          spacing={1.25}
          sx={{ pt: '6px', pb: '6px', pl: '12px', pr: '12px' }}
          data-selenium-id='SIDetail_GeneralInfoSection-ContainerStack3'

        >
          <Box
            id='SIDetail_GeneralInfoSection-ContainerStack3-TitleBox'
            data-selenium-id='SIDetail_GeneralInfoSection-ContainerStack3-TitleBox'
          >
            Select Email Template
          </Box>
          {stageId == 1 || stageId == 2 ? (
            <StyledTypography
              type='1'
              data-selenium-id='SIDetail_GeneralInfoSection-StyledTypography1'
            >
              <FormattedMessage
                id='siDetail.emailSetUp'
                data-selenium-id='SIDetail_GeneralInfoSection-EmailSetup'
              />
            </StyledTypography>
          ) : null}
          {stageId == 1 || stageId == 2 ? (
            <StyledTypography
              type='3'
              data-selenium-id='SIDetail_GeneralInfoSection-StyledTypography2'
            >
              <FormattedMessage
                id='siDetail.emailGEGuidelineSharing'
                data-selenium-id='SIDetail_GeneralInfoSection-EmailGuidelineSharing'
              />
            </StyledTypography>
          ) : null}
          {stageId == 2 || stageId == 3 || stageId == 4 ? (
            <StyledTypography
              type='4'
              data-selenium-id='SIDetail_GeneralInfoSection-StyledTypography3'
            >
              <FormattedMessage
                id='siDetail.emailMonthlySubmissionReportFull'
                data-selenium-id='SIDetail_GeneralInfoSection-EmailMonthlySubmissionReportFull'
              />
            </StyledTypography>
          ) : null}
          {stageId == 3 || stageId == 4 ? (
            <StyledTypography
              type='5'
              data-selenium-id='SIDetail_GeneralInfoSection-StyledTypography4'
            >
              <FormattedMessage
                id='siDetail.emailEditorialWritingReminder'
                data-selenium-id='SIDetail_GeneralInfoSection-EmailEditorialWritingReminder'
              />
            </StyledTypography>
          ) : null}
          {stageId == 3 || stageId == 4 ? (
            <StyledTypography
              type='2'
              data-selenium-id='SIDetail_GeneralInfoSection-StyledTypograpy5'
            >
              <FormattedMessage
                id='siDetail.emailRemove'
                data-selenium-id='SIDetail_GeneralInfoSection-EmailRemove'
              />
            </StyledTypography>
          ) : null}
          {stageId == 5 ? (
            <StyledTypography
              type='6'
              data-selenium-id='SIDetail_GeneralInfoSection-StyledTypography6'
            >
              <FormattedMessage
                id='siDetail.emailThankyouLetter'
                data-selenium-id='SIDetail_GeneralInfoSection-EmailThankyouLetter'
              />
            </StyledTypography>
          ) : null}
          {((siDetail?.submissionDeadlineExtendedNumber??0)>0 && (stageId == 2 || stageId == 3)) ? (
            <StyledTypography
              type='13'
              data-selenium-id='SIDetail_GeneralInfoSection-StyledTypography13'
            >
              <FormattedMessage
                id='email.BannerPromotionAdjustmentWhenSubmissionDeadlineIsChanged'
                data-selenium-id='SIDetail_GeneralInfoSection-BannerPromotionAdjustmentWhenSubmissionDeadlineIsChanged'
              />
            </StyledTypography>
          ) : null}
        </Stack>
      </Popover>
    </>
  );
}

import React from 'react';

import CloseIcon from '../assets/SIOverviewColumnCloseFigma.svg';
import CloseHoverIcon from '../assets/SIOverviewColumnCloseHoverFigma.svg';

function CloseButton(props) {
  const { onClick } = props;

  const [hover, setHover] = React.useState(false);

  return (
    <div
      sx={{ width: 14, height: 14, padding: 0, minWidth: 0 }}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover ? <CloseHoverIcon /> : <CloseIcon />}
    </div>
  );
}

export default CloseButton;
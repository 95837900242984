import CreateAndUpdateAutoComplete from '../CreateAndUpdateAutoComplete';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ItemBlock from '../../CGTComponents/ItemBlock';
import MultipleAutocomplete from '../../CGTComponents/MultipleAutocomplete';
import { Box, TextField } from '@mui/material';
import ArrowTag from '../../CGTComponents/SVGComponents/ArrowTag.svg';
import { MiniFont } from '@/components/FontStyle';
import { calcHeight, calcMarginBottom } from '../../CGTComponents/Utils';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import {getCE} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueSystemAdmin";

export function HandlingCEAutocomplete(props) {
  const { id,required, value, onChange, emptyErr, helperText,placeholder } = props;
  const dispatch = useDispatch();

  // 获得必要数据
  const { CEData } = useSelector(state => {
    return {
      CEData: state.SI.CE,
    };
  });

  const [useCEData, setUseCEData] = useState([]); // 真正展示在屏幕上的data

  const [inputContent, setInputContent] = useState(''); // 输入的内容

  const [loading, setLoading] = useState(false); // loading未来会用到，暂时不删掉

  // 以下两个状态用于辅助延迟发送请求
  const [timer, setTimer] = useState(null);

  const [loadTrigger, setLoadTrigger] = useState(0);

  // 搜索逻辑，返回后会更新CEData
  const getCEFromSearch = async searchContent => {
    await dispatch(getCE(searchContent));
  };

  // 输入内容变化时设置timer，倒计时0.3秒后发送请求
  // 如果期间输入内容在此变化，之前的timer计划会作废
  useEffect(() => {
    setLoading(true);
    clearTimeout(timer);
    setTimer(
      setTimeout(async () => {
        setLoadTrigger(loadTrigger + 1); // 通过这个触发加载
      }, 300)
    );
  }, [inputContent]);

  const handleSetInputHandlingCE = e => {
    // if (e !== null && e._reactName !== "onClick") {    // 刚进入页面的时候e是null，尚不清楚原因
    if (e) {
      // 刚进入页面的时候e是null，尚不清楚原因
      setInputContent(e.target.value);
    }
  };

  // 这里增加一个由loadTrigger触发的useEffect
  // 是为了模仿SelectBox的实现（因为当前不清楚去掉这一层是否会正常）
  useEffect(() => {
    if (loadTrigger === 0) {
      return;
    }

    async function load() {
      await getCEFromSearch(inputContent ?? '');
    }

    load().then(_r => {
      setLoading(false);
    });
  }, [loadTrigger]);

  // CE Data变化时，为了防止error等问题，
  // 最终展现给用户的是本页面中的state，而非直接使用redux的
  useEffect(() => {
    // CEData有时候的值很奇怪，所以
    if (CEData && CEData !== 'error' && CEData !== 'null') {
      // 好像还有可能是null字符串，也要避免
      setUseCEData(CEData); // 正常，设置为返回的值
    } else {
      setUseCEData([]); // 出错，设置为空值
    }
  }, [CEData]);

  const [totalError,setTotalError] = useState(emptyErr);

  useEffect(()=>{
    setTotalError(emptyErr);
  },[emptyErr]);

  return (
    <>
      <ItemBlock
        id={id}
        isError={totalError}
        required={true}
        title='Handling CE'
        errorText='This field is required.'
      >
        {/*<SinpleAutocomplete
          id={`${id}_autocomplete`}
          value={value}
          options={useCEData.map(
            option => option.username + ' (' + option.email + ')'
          )}
          onChange={onChange}
          defaultQuery={true}
          needClearInputValue={true}
          />*/}
        <MultipleAutocomplete
          id={`${id}_autocomplete`}
          value={value}
          options={useCEData.map(
            option => option.username + ' (' + option.email + ')'
          )}
          onChange={onChange}
          onInputChange={handleSetInputHandlingCE}
          renderInput={params => (
            <TextField
              error={totalError}
              placeholder={placeholder}
              data-selenium-id='SIPage_CreateAndUpdateSI-AutoComplete-TextField'
              {...params}
            />
          )}
          multiple={false}
          // popupIcon={<ArrowTag></ArrowTag>}
          endAttchmentProps={{
            endAttchmentSX: {
              top: '12px',
              right: '12px !important',
            },
            popupIconSX: {
              backgroundColor: '#FFFFFF',
              padding: '0px',
              borderRadius: '2px',
            },
            deleteIconHoverSX :{
              backgroundColor:'#FFFFFF'
            },
            deleteIconFocusSX:  {
              backgroundColor:'#FFFFFF'
            }
          }}
          // disableClearable={true}
          outlinedInputProps={{
            outlinedInputSX: {
              padding: '11px 12px',
            },
            inputSX: {
              root: {
                height: '18px',
              },
            },
            notchedOutlineSX: {
              border:
                value && value !== ''
                  ? '1px solid #98A7B6'
                  : '1px solid #DFE4E8',
            },
          }}
          dropListEndAttachment={<></>}
          dropListNoOptions={<Box
            sx={{
              padding: '12px 12px',
              fontFamily: 'Open Sans',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '20px',
              color: '#262E35',
            }}
          >
            {value}
          </Box>}
          dropListProps={{
            listBoxSX: {
              paddingBottom: '12px',
            },
            listBoxUlSX: {
              root:{
                padding: '12px 0px 0px 0px',
              }
              
            },
          }}
          scrollProps={{
            scrollOverviwSX:{
              height:`${calcHeight(useCEData,6,6,20,12)}px`
            }
          }}
          onFocus={()=>{
            setTotalError(false);
          }}
          onBlur={()=>{
            setTotalError(emptyErr);
          }}
        />
      </ItemBlock>
    </>
  );
}

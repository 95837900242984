//Get user information
import { axios_instance } from "@/utils/axios_instance";
import MyLocalStorage from "@/utils/MyLocalStorage";
import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";
import { GET_GROUP_INFO, GET_USER_INFO } from "@/actions/SIMT-User/User/UserAction";
import { getUserId } from "@/utils/getUserId";
import { EDIT_EMAILMANUALDETAIL } from "@/actions/SIMT-Email/EmailAction";

//Get user information
// 原API：Get /user/profile
export function getUserInfo() {
	return async dispatch => {
		try {
			const url = `/api/v1/user-service/users/current`;
			const result = await axios_instance.get(url, {
				headers: {
					authorization: localStorage.getItem('authorization'),
				},
			});
			MyLocalStorage.setItem('userInfo', result.data.data); // 储存到local里面减少网络请求
			await dispatch({ type: GET_USER_INFO, data: result.data.data });
			if (result.data.code === 200) {
				await dispatch({ type: EDIT_EMAILMANUALDETAIL, data: 'Ok' });
			} else {
				dispatch({ type: EDIT_EMAILMANUALDETAIL, data: 'error' });
			}
		} catch (err) {
			dispatch(
				setSnackbarMessageAndOpen(
					'common.failToLoadOcc',
					{
						value: 'account detail', // TODO 以后再改为id的形式
					},
					SEVERITIES.error
				)
			);
		}
	};
}

export function getGroupInfo() {
	return async dispatch => {
		try {
			let url = '/user/group';
			let res = await axios_instance.get(url, {
				headers: {
					authorization: localStorage.getItem('authorization'),
				},
			});
			if (res.data.code === 200) {
				dispatch({
					type: GET_GROUP_INFO,
					data: res.data.data,
				});
			} else {
				dispatch({ type: GET_GROUP_INFO, data: [] });
			}
		} catch (err) {
			// dispatch({ type: GET_ADMIN_INFO, data: err.response.data.msg });
		}
	};
}
//@ts-check
import { GET_SIP_USERS,  } from "@/actions/SIMT-SIP/SIP/SIPAction"; 
import { getHandlingCeUserService as getUsersService } from "@/actions/SIMT-User/User/SystemAdmin";

/**@typedef {import("redux").Dispatch} Dispatch */
/**@typedef {string | null | undefined} value */

/**
 * @param {value} value
 * @param {import("@/actions/SIMT-User/User/SystemAdmin").SystemAdminRequestRedux} [otherParam]
 */
// use permission code for user type
export function getSIPUser(value, otherParam, type = GET_SIP_USERS) {
    if (value === undefined || value === null) {
        return;
    }
    /**
      * @param {Dispatch} dispatch 
      */
    return async dispatch => {
      const {permissionCode, scope} = otherParam || {};
        try {
            const res = await getUsersService({
                fullName: value,
                pageNum: 1,
                pageSize: 10,
                fuzzy: true,
                permissionCode,
            })(dispatch);
            if (!res) {
                dispatch({ type, data: 'error' });
                return;
            }
            const payload = {
              users: res.result,
              permissionCode,
              scope,
            };
            dispatch({ type, data: payload });
        } catch {
            return dispatch({ type, data: 'error' });
        }
    };
}
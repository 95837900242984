import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateSIDisabledBlock from '../../../CommonComponents/CreateSIDisabledBlock';
import CreateSISelectBlock from '../../../CommonComponents/CreateSISelectBlock';
import {
  CreateAndUpdateLittleTitle,
  CreateAndUpdateTitle,
} from '../../../CommonComponents/CreateAndUpdateTitle';
import CreateSIInputBlock from '../../../CommonComponents/CreateSIInputBlock';
import CreateSIDatePickerBlock from '../../../CommonComponents/CreateSIDatePickerBlock';
import CreateAndUpdateAutoComplete from '../../../CommonComponents/CreateAndUpdateAutoComplete';
import { scrollToTop } from '../../../../../../utils/commonUtils';
import { HandlingCEAutocomplete } from '../../../CommonComponents/FuntionalComponents/HandlingCEAutocomplete';
import { useLocation } from 'react-router-dom';
import { useUpdateEffect } from 'ahooks';
import { Stack } from '@mui/material';
import {getCE} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueSystemAdmin";
import {getJournalListWithCodeOptIn, getJournalListWithNameOptIn} from "@/actions/SIMT-SI/SpecialIssue/Journal";
import {getSepcialIssueSource, getSIRevenueModel, getWorkFlowList} from "@/actions/SIMT-SI/SpecialIssue/Config";


function GeneralInformationForm(props) {
  const location = useLocation();
  const { state } = location;
  const {
    MandatoryList,
    clickedButton,
    GeneralInformation,
    setGeneralInformation,
    setJump,
    clickTime,
    setHasError = e => { },
  } = props;
  const [text, setText] = useState('');
  const [CEValue, setCEValue] = useState(
    GeneralInformation.HandlingCeSelectValue
  );
  const [CeId, setCeId] = useState(GeneralInformation.HandlingCE);
  const dispatch = useDispatch();
  const [JournalData, setJournalData1] = useState([]);
  const [SIType, setSIType] = useState(GeneralInformation.SIPublicationFormat);
  const [longTitle, setLongTitle] = useState(false); //标题过长报错
  const [SITitle, setSITitle] = useState(GeneralInformation.SpecialIssueTitle);
  const [reTitle, setReTitle] = useState(false); //标题的重复检查
  const [SISource, setSISource] = useState(
    GeneralInformation.SpecialIssueSource
  );
  const [IdeaDate, setIdeaDate] = useState(GeneralInformation.IdeaDate);
  const [siRevenueModel, setSIRevenueModel] = useState(
    GeneralInformation.siRevenueModel
  );
  const [CEData, setCEData1] = useState([
    {
      Id: 1,
      username: '',
      email: '',
    },
  ]);

  const SITypeData = [
    {
      Id: 1,
      Name: 'Regular Issue Replacement',
    },
    {
      Id: 2,
      Name: 'Special Section',
    },
    {
      Id: 3,
      Name: 'Supplement',
    },
    {
      Id: 5,
      Name: 'Virtual Issue',
    },
  ];

  const [journalSelect, setJournalSelect] = useState(null);
  //搜索时的值
  const [journalNameSelect, setJournalNameSelect] = useState(
    GeneralInformation.JournalName == null ? '' : GeneralInformation.JournalName
  );
  const [journalCodeSelect, setJournalCodeSelect] = useState(
    GeneralInformation.JournalCode == null ? '' : GeneralInformation.JournalCode
  );

  const [journaleName, setJournalName] = useState('');
  const [journaleCode, setJournalCode] = useState('');

  const [currentJournalPageC, setCurrentJournalPageC] = useState(1); //当前选中journlCode
  const [currentJournalPageN, setCurrentJournalPageN] = useState(1); //当前选中journalName

  // 判断HandlingCE是不是第一次加载，当CE还没有加载到值的时候，不进行空值报错
  const [isCEFirstLoad, setIsCEFirstLoad] = useState(true);

  const MandatoryJudge = (value, list) => {
    return list.indexOf(value) > -1;
  };

  const selector = state => {
    return {
      CEResult: state.SI.CE, //CE下拉框数据
      JourListResult: state.SI.JourList, //journal下拉框数据
      CreateGEResult: state.SI.createGEResult, //创建GE提示
      UserFirstNameInfo: state.User.firstName, //登录的用户信息
      UserIDInfo: state.User.userId,
      UserLastNameInfo: state.User.lastName,
      UserEmailInfo: state.User.email,
      moreJourListResult: state.SI.moreJourList, //用于加载更多journal （可能还会用，暂时没用）
      checkTitleResult: state.SI.checkTitle, //title不可重复
      workFlowList: state.SI.workFlowList,
      siRevenueModelList: state.SI.siRevenueModelList,
      siSourceList: state.SI.siSourceList,
    };
  };

  const {
    CEResult,
    JourListResult,
    UserFirstNameInfo,
    UserIDInfo,
    UserLastNameInfo,
    UserEmailInfo,
    moreJourListResult,
    checkTitleResult,
    workFlowList,
    siRevenueModelList,
    siSourceList,
  } = useSelector(selector);

  const handIdeaDateChange = value => {
    setIdeaDate(value);
    setGeneralInformation({
      ...GeneralInformation,
      IdeaDate: value,
    });
  };
  const [getrevenueModel, setGetRevenueModel] = useState(
    // GeneralInformation.revenueModel
    'OA'
  );
  const handleCeSelect = value => {
    setCEValue(value);
    //选中CE时的变化
    if (value) {
      let tmpEmail = value.split('(')[1];
      tmpEmail = tmpEmail.split(')')[0];
      let tmpId = -1;
      for (const element of CEData) {
        if (element.email === tmpEmail) {
          tmpId = element.userId;
          break;
        }
      }
      if (tmpId > 0) {
        setCeId(tmpId);
      }
    }

    setGeneralInformation({
      ...GeneralInformation,
      HandlingCeSelectValue: value,
    });
  };

  const handleSITypeChange = (event, newValue) => {
    setSIType(newValue);
    setGeneralInformation({
      ...GeneralInformation,
      SIPublicationFormat: event.target.value,
    });
  };

  const handleSITitleChange = value => {
    var regu = '^[ ]+$';
    if (new RegExp(regu).test(value)) {
      value = '';
    }
    if (value.length > 400) {
      setLongTitle(true);
      setText('The length of Title should less than or equal to 400!');
    } else {
      setLongTitle(false);
      setSITitle(value);
      setGeneralInformation({
        ...GeneralInformation,
        SpecialIssueTitle: value,
      });
    }
  };

  const handleSISourceChange = (event, newValue) => {
    setSISource(newValue);
    setGeneralInformation({
      ...GeneralInformation,
      SpecialIssueSource: event.target.value,
    });
  };

  const getCEFromSearch = async value => {
    if (value !== undefined) {
      await dispatch(getCE(value));
    }
  };

  const getJournalFromName = async value => {
    await dispatch(getJournalListWithNameOptIn(value, 1));
    setCurrentJournalPageN(1);
  };

  const getJournalFromCode = async value => {
    await dispatch(getJournalListWithCodeOptIn(value, 1));
    setCurrentJournalPageC(1);
  };

  const getMoreJournalInfoFromName = async () => {
    if (currentJournalPageC > 1) {
      await getJournalFromName(journaleName);
      setCurrentJournalPageC(1);
    }
    await dispatch(
      getJournalListWithNameOptIn(journaleName, currentJournalPageN + 1)
    );
    setCurrentJournalPageN(currentJournalPageN + 1);
  };

  const getMoreJournalInfoFromCode = async () => {
    if (currentJournalPageN > 1) {
      await getJournalFromCode(journaleCode);
      setCurrentJournalPageN(1);
    }
    await dispatch(
      getJournalListWithCodeOptIn(journaleCode, currentJournalPageC + 1)
    );
    setCurrentJournalPageC(currentJournalPageC + 1);
  };

  const handleJounalSearchWithName = value => {
    if (value !== null) {
      // setGetRevenueModel(value.revenueModel);
      setJournalSelect(value);
      setJournalNameSelect(value.journalName);
      setJournalCodeSelect(value.journalCode);
    } else {
      // setGetRevenueModel(null);
      setJournalSelect(null);
      setJournalNameSelect(null);
      setJournalCodeSelect(null);
      setGeneralInformation({
        ...GeneralInformation,
        JournalCode: null,
        JournalName: null,
        siRevenueModel: null,
        revenueModel: null,
      });
    }
  };

  const handleJounalSearchWithCode = value => {
    if (value !== null) {
      // setGetRevenueModel(value.revenueModel);
      setJournalSelect(value);
      setJournalNameSelect(value.journalName);
      setJournalCodeSelect(value.journalCode);
    } else {
      // setGetRevenueModel(null);
      setJournalSelect(null);
      setJournalNameSelect(null);
      setJournalCodeSelect(null);
      setGeneralInformation({
        ...GeneralInformation,
        JournalCode: null,
        JournalName: null,
        siRevenueModel: null,
        revenueModel: null,
      });
    }
  };

  const handleJournalNameChange = event => {
    if (event !== null && event._reactName !== 'onClick') {
      setJournalName(event.target.value);
    }
  };

  const handleJournalCodeChange = event => {
    if (event !== null && event._reactName !== 'onClick') {
      setJournalCode(event.target.value);
    }
  };

  function sleep(delay = 0) {
    return new Promise(resolve => {
      setTimeout(resolve, delay);
    });
  }

  //HandlingCE stores Id
  useEffect(() => {
    let tmpName = CEValue?.split('(')[0] ?? '';
    setGeneralInformation({
      ...GeneralInformation,
      HandlingCE: CeId,
      HandlingCeSelectValue: CEValue,
      HandlingCEReviewValue: tmpName,
    });
  }, [CeId]);

  //check title
  useEffect(() => {
    if (checkTitleResult === 'false') {
      setReTitle(true);
      setText('The Title has been used!');
    } else {
      setReTitle(false);
    }
  }, [checkTitleResult]);

  useEffect(() => {
    if (isCEFirstLoad){
      if (UserFirstNameInfo !== '' && CEValue === '') {
        setCEValue(
          UserLastNameInfo + ', ' + UserFirstNameInfo + ' (' + UserEmailInfo + ')'
        );
        setGeneralInformation({
          ...GeneralInformation,
          HandlingCeSelectValue:
            UserLastNameInfo +
            ', ' +
            UserFirstNameInfo +
            '(' +
            UserEmailInfo +
            ')',
          HandlingCE: UserIDInfo,
        });
        setCeId(UserIDInfo);
        setIsCEFirstLoad(false); //此时CE已经获取到初始值，不再是第一次加载的状态了
      }
    }
  }, [UserFirstNameInfo, isCEFirstLoad]);

  useEffect(() => {
    let result = CEResult;
    if (result != null && result !== 'error') {
      setCEData1(result);
    }
  }, [CEResult]);

  useEffect(() => {
    let result = JourListResult;

    if (result != null && result !== 'error') {
      setJournalData1(result);
    }
  }, [JourListResult]);

  useEffect(() => {
    let result = moreJourListResult;
    if (result.length !== 0) {
      setJournalData1(JournalData.concat(result));
    }
  }, [moreJourListResult]);

  //延迟获取JournalName值
  useEffect(() => {
    let active = true;
    (async () => {
      await sleep(3e2); // For demo purposes.
      if (active) {
        if (!journaleName)
          getJournalFromName('');
        else
          getJournalFromName(journaleName);
      }
    })();
    return () => {
      active = false;
    };
  }, [journaleName]);

  //延迟获取JournalCode值
  useEffect(() => {
    let active = true;
    (async () => {
      await sleep(3e2); // For demo purposes.

      if (active) {
        if (!journaleCode)
          getJournalFromCode('');
        else
          getJournalFromCode(journaleCode);
      }
    })();

    return () => {
      active = false;
    };
  }, [journaleCode]);

  //下拉框初始化
  useEffect(() => {
    dispatch(getCE(''));
    dispatch(getJournalListWithNameOptIn('', 1));
    dispatch(getWorkFlowList());
    dispatch(getSIRevenueModel());
    dispatch(getSepcialIssueSource());
    scrollToTop();
  }, []);

  //判断一个值是否为空或者空串
  const isEmptyValue = value => {
    return (
      value === null ||
      value === '' ||
      value === undefined ||
      value.length === 0
    );
  };

  //判断所有必填项是否已填
  const isEmpty = () => {
    return (
      (MandatoryJudge('Handling CE', MandatoryList) && isEmptyValue(CEValue)) ||
      (MandatoryJudge('Journal Code', MandatoryList) &&
        isEmptyValue(GeneralInformation.JournalCode)) ||
      (MandatoryJudge('Journal Name', MandatoryList) &&
        isEmptyValue(GeneralInformation.JournalName)) ||
      (MandatoryJudge('SI Publication Format', MandatoryList) &&
        isEmptyValue(SIType)) ||
      (MandatoryJudge('SI Source', MandatoryList) && isEmptyValue(SISource)) ||
      (MandatoryJudge('Idea Date', MandatoryList) && isEmptyValue(IdeaDate)) ||
      (MandatoryJudge('Special Issue Title', MandatoryList) &&
        isEmptyValue(SITitle))
    );
  };
  //如果有必填项未填则不能跳转
  useEffect(() => {
    setJump(!isEmpty());
  });

  const [WorkFlowData, setWorkFlowList] = useState([]);

  useEffect(() => {
    setWorkFlowList(
      workFlowList != []
        ? workFlowList.map(item => {
          return Object.assign({}, { Id: item.id, Name: item.value });
        })
        : []
    );
  }, [workFlowList]);

  const [SIRevenueModelList, setSIRevenueModelList] = useState([]);

  useEffect(() => {
    setSIRevenueModelList(
      siRevenueModelList != []
        ? siRevenueModelList.map(item => {
          return Object.assign({}, { Id: item.id, Name: item.value });
        })
        : []
    );
  }, [siRevenueModelList]);

  const [SISourceData, setSISourceList] = useState([]);

  useEffect(() => {
    setSISourceList(
      siSourceList != []
        ? siSourceList.map(item => {
          return Object.assign({}, { Id: item.id, Name: item.value });
        })
        : []
    );
  }, [siSourceList]);

  // create ide aand proposal genernal
  const recoredError = () => {
    if (MandatoryJudge('Handling CE', MandatoryList) && isEmptyValue(CEValue)) {
      return 'handlingCEAutocompleteHandlingCE';
    } else if (
      MandatoryJudge('Journal Code', MandatoryList) &&
      isEmptyValue(GeneralInformation.JournalCode)
    ) {
      return 'createAndUpdateAutoCompleteJournalCode';
    } else if (
      MandatoryJudge('Journal Name', MandatoryList) &&
      (isEmptyValue(GeneralInformation.JournalName) ||
        journalNameSelect === 'Temporary')
    ) {
      return 'createAndUpdateAutoCompleteJournalName';
    } else if (
      MandatoryJudge('SI Publication Format', MandatoryList) &&
      isEmptyValue(SIType)
    ) {
      return 'createSISelectBlockSIPublicationFormat';
    } else if (
      MandatoryJudge('Special Issue Title', MandatoryList) &&
      isEmptyValue(SITitle)
    ) {
      return 'createSIInputBlockSpecialIssueTitle';
    } else if (
      MandatoryJudge('Idea Date', MandatoryList) &&
      isEmptyValue(IdeaDate)
    ) {
      return 'DatePickerIdeaDateBlockInput';
    } else if (
      MandatoryJudge('SI Source', MandatoryList) &&
      isEmptyValue(SISource)
    ) {
      return 'createSIInputBlockSpecialIssueSource';
    }
  };
  // useUpdateEffect 初次进入页面不会渲染 ， 出错的话再进行定位，先定位到顶部，在定位到屏幕中间
  useUpdateEffect(() => {
    if (isEmpty()) {
      let currentDOM = document.getElementById('' + recoredError());
      if (currentDOM) {
        setTimeout(() => {
          window.scrollTo({ left: 0, top: 0, behavior: 'instant' });
          window.scroll({
            left: 0,
            top:
              currentDOM.getBoundingClientRect().top -
              window.screen.availHeight / 2 +
              100,
            behavior: 'instant',
          });
        }, 200);
      }
    }
  }, [clickTime]);

  const currentPageHasError = isEmpty();
  //当前页面是否有错误的设置
  useEffect(() => {
    if (!clickedButton) {
      return;
    }
    setHasError(currentPageHasError);
  }, [currentPageHasError, clickedButton]);

  return (
    <>
      <CreateAndUpdateTitle
        title='Create New Special Issue'
        id='createSIGeneralInfotitle'
        data-selenium-id='SIPage_CreateAndUpdateSI_IdeaAndProposalPage-GeneralInformationForm-CreateAndUpdateTitle'
      />
      <CreateAndUpdateLittleTitle
        littleTitle='General Information'
        id='createSIGeneralInfolittletitle'
        data-selenium-id='SIPage_CreateAndUpdateSI_IdeaAndProposalPage-GeneralInformationForm-CreateAndUpdatelittletitle'
      ></CreateAndUpdateLittleTitle>
      {/* a little change */}
      <Stack spacing={2} direction='column'>
        {/* Stage */}
        <CreateSIDisabledBlock
          title='Stage'
          defaultValue={state}
          id='createSIIdeaAndProposalStageDisabledBlock'
          data-selenium-id='SIPage_CreateAndUpdateSI_IdeaAndProposalPage-GeneralInformationForm-StageDisabledBlock'
        />
        {/* Handling CE */}
        <HandlingCEAutocomplete
          id='handlingCEAutocompleteHandlingCE'
          data-selenium-id='SIPage_CreateAndUpdateSI_IdeaAndProposalPage-GeneralInformationForm-HandlingCEAutocomplete'
          required={MandatoryJudge('Handling CE', MandatoryList)}
          value={CEValue}
          placeholder='Select Handling CE'
          onChange={(event, newValue) => {
            handleCeSelect(newValue);
          }}
          emptyErr={clickedButton && !isCEFirstLoad && isEmptyValue(CEValue)}
        ></HandlingCEAutocomplete>
        {/* Journal Code */}
        <CreateAndUpdateAutoComplete
          id='createAndUpdateAutoCompleteJournalCode'
          data-selenium-id='SIPage_CreateAndUpdateSI_IdeaAndProposalPage-GeneralInformationForm-JournalCodeSelect'
          title='Journal Code'
          placeholder='Select Journal Code'
          required={MandatoryJudge('Journal Code', MandatoryList)}
          value={
            GeneralInformation.JournalCode ? GeneralInformation : journalSelect
          }
          options={
            JournalData.length === 0
              ? JournalData.concat({
                journalName: 'Temporary',
                journalCode: 'Temporary',
              })
              : JournalData
          }
          getOptionLabel={option =>
            option.JournalCode || option.journalCode || ''
          }
          onChange={(event, newValue) => {
            handleJounalSearchWithCode(newValue);
            if (newValue !== null) {
              setGeneralInformation({
                ...GeneralInformation,
                JournalCode: newValue.journalCode,
                JournalName: newValue.journalName,
                revenueModel: newValue.revenueModel,
              });
            } else {
              setGeneralInformation({
                ...GeneralInformation,
                JournalCode: null,
                JournalName: null,
                revenueModel: null,
              });
            }
          }}
          onInputChange={handleJournalCodeChange} //输入框改变时候 触发在前
          getOptionDisabled={option => option.journalCode === journalCodeSelect}
          renderOption={(props, option) => {
            return !option.showMore ? (
              <li {...props}>{option.journalCode}</li>
            ) : (
              <li {...props} onClick={getMoreJournalInfoFromCode}>
                {option.journalCode}
              </li>
            );
          }}
          empty={isEmptyValue(journalSelect?.journalCode)}
          isErr={
            (clickedButton && isEmptyValue(journalSelect?.journalCode)
              && isEmptyValue(GeneralInformation?.JournalCode)) ||
            journalCodeSelect === 'Temporary'
          }
          errorText={
            journalCodeSelect === 'Temporary'
              ? 'Please contact your JPM to add the journal information into Product Data Management system (STIBO) as soon as possible!'
              : 'This field is required.'
          }
        ></CreateAndUpdateAutoComplete>

        {/* Journal Name */}
        <CreateAndUpdateAutoComplete
          id='createAndUpdateAutoCompleteJournalName'
          data-selenium-id='SIPage_CreateAndUpdateSI_IdeaAndProposalPage-GeneralInformationForm-JournalNameSelect'
          title='Journal Name'
          required={MandatoryJudge('Journal Name', MandatoryList)}
          placeholder='Select Journal Name'
          value={
            GeneralInformation.JournalName ? GeneralInformation : journalSelect
          }
          options={
            JournalData.length === 0
              ? JournalData.concat({
                journalName: 'Temporary',
                journalCode: 'Temporary',
              })
              : JournalData
          }
          getOptionLabel={option =>
            option.JournalName || option.journalName || ''
          }
          onChange={(event, newValue) => {
            handleJounalSearchWithName(newValue);
            if (newValue !== null) {
              setGeneralInformation({
                ...GeneralInformation,
                JournalCode: newValue.journalCode,
                JournalName: newValue.journalName,
                revenueModel: newValue.revenueModel,
              });
            } else {
              setGeneralInformation({
                ...GeneralInformation,
                JournalCode: null,
                JournalName: null,
                revenueModel: null,
              });
            }
          }}
          onInputChange={handleJournalNameChange}
          getOptionDisabled={option => option.journalName === journalNameSelect}
          renderOption={(props, option) => {
            return !option.showMore ? (
              <li {...props}>{option.journalName}</li>
            ) : (
              <li {...props} onClick={getMoreJournalInfoFromName}>
                {option.journalName}
              </li>
            );
          }}
          empty={isEmptyValue(journalSelect?.journalName)}
          isErr={
            (clickedButton && isEmptyValue(journalSelect?.journalName)
              && isEmptyValue(GeneralInformation?.JournalName)) ||
            journalCodeSelect === 'Temporary'
          }
          errorText={
            journalCodeSelect === 'Temporary'
              ? 'Please contact your JPM to add the journal information into Product Data Management system (STIBO) as soon as possible!'
              : 'This field is required.'
          }
        ></CreateAndUpdateAutoComplete>

        {/* SI Publication Format */}
        <CreateSISelectBlock
          title='SI Publication Format'
          required={MandatoryJudge('SI Publication Format', MandatoryList)}
          placeholder='Select SI Publication Format'
          id='createSISelectBlockSIPublicationFormat'
          data-selenium-id='SIPage_CreateAndUpdateSI_IdeaAndProposalPage-GeneralInformationForm-TypeSelect'
          list={SITypeData}
          attribute='Name'
          value={SIType}
          onChange={handleSITypeChange}
          emptyErr={clickedButton && isEmptyValue(SIType)}
        ></CreateSISelectBlock>
        {/* "SI Revenue Model" */}
        {/* {getrevenueModel === 'OA' ? null : (
          <CreateSISelectBlock
            id='createSIRevenueModel'
            data-selenium-id='SIPage_CreateAndUpdateSI_IdeaAndProposalPage-GeneralInformationForm-RevenueModelSelectBlock'
            title='SI Revenue Model'
            required={false}
            list={SIRevenueModelList} // TODO
            placeholder='Select SI Revenue Model'
            value={siRevenueModel}
            attribute='Name'
            onChange={(event, newValue) => {
              setSIRevenueModel(newValue);
              setGeneralInformation({
                ...GeneralInformation,
                siRevenueModel: e.target.value,
              });
            }}
          ></CreateSISelectBlock>
        )} */}
        {/* SI title */}
        <CreateSIInputBlock
          title='Special Issue Title'
          required={MandatoryJudge('Special Issue Title', MandatoryList)}
          id='createSIInputBlockSpecialIssueTitle'
          data-selenium-id='SIPage_CreateAndUpdateSI_IdeaAndProposalPage-GeneralInformationForm-Titleinput'
          placeholder='Type SI Title'
          limitLength={401}
          value={SITitle}
          defaultValue={GeneralInformation.SpecialIssueTitle}
          setValue={handleSITitleChange}
          emptyErr={clickedButton && isEmptyValue(SITitle)}
          error={longTitle === true || reTitle === true}
          helperText={text}
        ></CreateSIInputBlock>

        {/* idea date */}
        <CreateSIDatePickerBlock
          title='Idea Date'
          required={MandatoryJudge('Idea Date', MandatoryList)}
          value={IdeaDate}
          handleChange={handIdeaDateChange}
          placeholder='YYYY-MM-DD'
          emptyErr={clickedButton && isEmptyValue(IdeaDate)}
          id='createSIDatePickerBlockIdeaDate'
          data-selenium-id='SIPage_CreateAndUpdateSI_IdeaAndProposalPage-GeneralInformationForm-IdeaDateDatePicker'
        ></CreateSIDatePickerBlock>

        {/* Special Issue Source */}
        <CreateSISelectBlock
          id='createSIInputBlockSpecialIssueSource'
          data-selenium-id='SIPage_CreateAndUpdateSI_IdeaAndProposalPage-GeneralInformationForm-SIProductionSourceSelect'
          title='Special Issue Source'
          placeholder='Select SI Source'
          required={MandatoryJudge('SI Source', MandatoryList)}
          list={SISourceData}
          value={SISource}
          onChange={handleSISourceChange}
          emptyErr={clickedButton && isEmptyValue(SISource)}
          attribute='Name'
          mergeIntoDocumentFlow={true}
        ></CreateSISelectBlock>
      </Stack>
    </>
  );
}

export default GeneralInformationForm;

// Components
import IconBox from '@/pages/SIPage/Overview/Filter/component/CommonComponets/IconBox';
// Assets
import TimeSolid from '@/pages/SIPage/Overview/Filter/component/SVGComponents/TimeSolid.svg';
import Solid from '@/pages/SIPage/Overview/Filter/component/SVGComponents/Solid.svg';

export default function ChipIcon({ mode = 0 }) {
  switch (mode) {
    case 0:
      return (
        <IconBox pathFill='#EE5350'>
          <TimeSolid />
        </IconBox>
      );
    case 1:
      return (
        <IconBox pathFill='#FFB152'>
          <TimeSolid />
        </IconBox>
      );
    case 2:
      return (
        <IconBox pathFill='#4C81EB'>
          <TimeSolid />
        </IconBox>
      );
    case 3:
      return (
        <IconBox circleFill='#FF8180'>
          <Solid />
        </IconBox>
      );
    case 4:
      return (
        <IconBox circleFill='#FFCA7B'>
          <Solid />
        </IconBox>
      );
    default:
      return null;
  }
}

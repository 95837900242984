import { Chip, styled } from '@mui/material';

const CustomDefaultChip = styled(Chip)(theme => ({
  '&.MuiChip-root': {
    backgroundColor: '#F1F3F5',
    border: '1px solid #DFE4E8',
    borderRadius: '6px',
    marginRight: '8px',
    height: '28px',
  },
}));

const CustomAttachmentChip = styled(Chip)(theme => ({
  '&.MuiChip-root': {
    backgroundColor: 'white',
    border: '1px solid #DFE4E8',
    borderRadius: '6px',
    height: '28px',
    color: '#154AB6',
  },
}));

export { CustomDefaultChip, CustomAttachmentChip };

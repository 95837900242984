// create Redux action types
export const GET_ALL_ADMIN_INFO = 'GET_ALL_ADMIN_INFO';
export const EDIT_ADMIN = 'EDIT_ADMIN';
export const EDIT_USER = 'EDIT_USER';
export const GET_ALL_USER_INFO = 'GET_ALL_USER_INFO';
export const GET_ALL_USER_IDS = 'GET_ALL_USER_IDS';
export const ACTIVE_ACCOUNT = 'ACTIVE_ACCOUNT';
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const GET_USER_INFO = 'GET_USER_INFO';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const INACTIVE_ADMIN = 'INACTIVE_ADMIN';
export const INACTIVE_USER = 'INACTIVE_USER';
export const UNLOCK_ADMIN = 'UNLOCK_ADMIN';
export const UNLOCK_USER = 'UNLOCK_USER';
export const GET_ADMIN_INFO = 'GET_ADMIN_INFO';
export const UPGRADE_USER = 'UPGRADE_USER';
export const DOWNGRADE_ADMIN = 'DOWNGRADE_ADMIN';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const CLEAR_USER_ACTION = 'CLEAR_USER_ACTION';
export const CLEAR_USER_LOCK = 'CLEAR_USER_LOCK';
export const BTACH_UPDATE_RESULT = 'BTACH_UPDATE_RESULT';
export const EDIT_CLEAR = 'EDIT_CLEAR';
export const ACTIVE_CLEAR = 'ACTIVE_CLEAR';
export const CHECK_USER_EMAIL = 'CHECK_USER_EMAIL';
export const GET_GROUP_INFO = 'GET_GROUP_INFO';
export const ADMIN_EMAIL_CHECK = 'ADMIN_EMAIL_CHECK';

export const ADMIN_ORDER_BY = 'ADMIN_ORDER_BY'; // Set Admin list sorting
export const LOAD_ADMIN_DATA = 'LOAD_ADMIN_DATA';
export const CHANGE_ADMIN_PAGE = 'CHANGE_ADMIN_PAGE';
export const CHANGE_ADMIN_PAGE_SIZE = 'CHANGE_ADMIN_PAGE_SIZE';
export const CHANGE_UPDATE_ADMIN = 'CHANGE_UPDATE_ADMIN';

// Create Redux action creators that return an action
export const clear = () => ({
  type: CLEAR_USER_ACTION,
});

export const lockClear = () => ({
  type: CLEAR_USER_LOCK,
});

export const activeEmailClear = () => ({
  type: ACTIVE_CLEAR,
});

export function updateClear() {
  return dispatch => {
    dispatch({ type: UPDATE_USER, data: '' });
    dispatch({ type: CREATE_USER, data: '' });
    dispatch({ type: BTACH_UPDATE_RESULT, data: '' });
  };
}

//对所有的更新操作初始化
export function editClear() {
  return dispatch => {
    dispatch({ type: EDIT_CLEAR });
  };
}

/**
 * 更改排序顺序和字段
 * @param order
 * @param by
 * @returns {(function(*): void)|*}
 */
export function setAdminOrderBy(order, by) {
  return dispatch => {
    dispatch({ type: ADMIN_ORDER_BY, data: { order: order, orderBy: by } });
  };
}

/**
 * 更改表格页码
 * @param {int} page 页码
 * @returns dispatch
 */
export function handleAdminPageChangeAction(page) {
  return dispatch => {
    dispatch({
      type: CHANGE_ADMIN_PAGE,
      data: page,
    });
  };
}

/**
 * 更改表格一页数量
 * @param {int} pageSize 一页的大小
 * @returns dispatch
 */
export function handleChangeAdminPageSize(pageSize) {
  return dispatch => {
    dispatch({
      type: CHANGE_ADMIN_PAGE_SIZE,
      data: pageSize,
    });
  };
}

export function handleChangeAdminUpdateName(fullName) {
  return dispatch => {
    dispatch({
      type: CHANGE_UPDATE_ADMIN,
      data: fullName,
    });
  };
}

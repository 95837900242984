import { Box, CircularProgress } from '@mui/material';
import MarketEditCancel from '@/assets/MarketEditCancel.svg';
import MarketEditSave from '@/assets/MarketEditSave.svg';
import MarketEdit from '@/assets/MarketEdit2.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomEmailBlock from './CustomEmailBlock';
import CustomText from './CustomText';
import CustomLabel from './CustomLabel';
import CustomContent from './CustomContent';
import CustomCKEditor from './CustomCKEditor';
import RichTextEditor from '../../../modules/RichTextEditor/RichTextEditor';
import {
  prepareBulkTransferData,
  replaceVariablesSymbolsForBackend,
  emailVariables,
  replaceVariablesForFrontShow,
  replaceVariablesSymbolsForFrontendInTransfer,
  forEmail,
} from '../../../modules/RichTextEditor/EditorUtils';
import Button from "@mui/material/Button";
import styleModule from "../../../componentsphase2/SIDetail/SaveAndCancelButtonForGeneralInfoSection.module.scss";
import {editEmailTemplate} from "@/actions/SIMT-Email/Template";

function ShowAndEditForAction(props) {
  const { partTitle, contentDetail, block } = props;
  var copy =
    '${Last Previous Handling CE1}, ${Last Previous Handling CE2},..., ${Last Previous Handling CEn}';
  const [moreOrLess, setMoreOrLess] = useState(true); // true为more， false为less
  const [isEdit, setIsEdit] = useState(false); //展示和编辑状态切换的关键字
  const [isLoading, setIsLoading] = useState(false);

  const [subjectDetail, setSubjectDetail] = useState(contentDetail.subject);
  const [emailContent, setEmailContent] = useState(contentDetail.content); // 修改email内容
  const [tempEmailContent, setTempEmailContent] = useState(
    replaceVariablesSymbolsForFrontendInTransfer(
      emailContent,
      emailVariables.action
    )
  ); // 暂存email内容 在放弃修改时候使用

  const dispatch = useDispatch();

  useEffect(() => {
    setTempEmailContent(
      replaceVariablesSymbolsForFrontendInTransfer(
        emailContent,
        emailVariables.action
      )
    );
  }, [emailContent]);

  // 小标题
  const CustomPartTitle = props => {
    const { children } = props;
    return (
      <Box
        data-selenium-id={`EmailTemplates-CommonModule-ShowAndEditForAction-${partTitle
          .split(' ')
          .join('')}-CustomPartTitle`}
        sx={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: ' 24px',
          display: 'flex',
          alignItems: 'center',
          color: '#262E35',
          marginRight: '12px',
        }}
      >
        {children}
      </Box>
    );
  };

  // 文本替换，将${} 变为 []
  const textReplace = props => {
    let testDiv = document.createElement('div');
    testDiv.innerHTML = props;
    let value = testDiv.innerText;
    if (value) {
      return value
        .replace(/\${/g, '[')
        .replace(/}/g, ']')
        .replace(/_/g, ' ')
        .replace(/{/g, '[');
    } else {
      return value;
    }
  };

  // subjectReplace中内容文本替换，将<span>标签中的 ${} 变为 []
  const subjectReplace = props => {
    let testDiv = document.createElement('div');
    testDiv.innerHTML = props;

    let spanValue = testDiv.getElementsByTagName('span');
    //循环遍历这个数组
    for (const element of spanValue) {
      element.innerHTML = element.innerHTML
        .replace(/\${/g, '[')
        .replace(/}/g, ']')
        .replace(/_/g, ' ');
    }
    return testDiv.innerText;
  };

  // 文本替换，将${} 去掉
  const discardBracket = props => {
    let value = props;
    if (value) {
      return value
        .replace(/\${/g, '')
        .replace(/}/g, '')
        .replace(/_/g, ' ')
        .replace(/{/g, '');
    } else {
      return value;
    }
  };

  // 将后端传来的copy数组 进行分割
  const listOfCopy = () => {
    if (copy != null) {
      return discardBracket(copy).split(',');
    } else {
      return null;
    }
  };

  // 为了符合CopyPart 组件的三种状态编写的组件 避免三元表达式的嵌套
  const SubCopyPart = props => {
    const { item, index, selfArray } = props;

    if (index in selfArray) {
      if (index === 0) {
        return <CustomLabel>{item}</CustomLabel>;
      }
      if (index === selfArray.length - 1) {
        return (
          <>
            <CustomText>,</CustomText>
            <CustomLabel>{item}</CustomLabel>
          </>
        );
      }
      if (index > 1) {
        return (
          <>
            <CustomText>,...</CustomText>
          </>
        );
      }
      return (
        <>
          <CustomText>,</CustomText>
          <CustomLabel>{item}</CustomLabel>
        </>
      );
    }
  };
  // 因为Copy字段中会有多个字段显示，编写动态显示的新组件
  const CopyPart = () => {
    let spiltedList = listOfCopy();
    if (spiltedList != null) {
      return (
        // 如果是数组有多个元素 在后续的元素块前面加上逗号
        spiltedList.map((item, index, selfArray) => (
          <SubCopyPart
            key={index + item}
            item={item}
            index={index}
            selfArray={selfArray}
          />
        ))
      );
    } else {
      return <></>;
    }
  };

  // 编辑状态时，点击取消按钮的操作
  const handleCancelClick = () => {
    setSubjectDetail(contentDetail.subject);
    setEmailContent(contentDetail.content);
    setIsEdit(false);
  };

  const selector = state => {
    return {
      emailDetail: state.Email.emailTemplatesList,
    };
  };
  const { emailDetail } = useSelector(selector);
  // 编辑状态时，点击保存按钮的操作
  const handleSaveClick = () => {
    // 在这里获取所有的数据源 然后更改对应block的数据 再整体传递
    // for of可以修改原数组
    for (const iterator of emailDetail.autoSentEmailByAction) {
      if (iterator.block === block) {
        const emailContentConverted = prepareBulkTransferData(
          tempEmailContent,
          emailVariables.action
        );
        const emailObject = {
          ...iterator,
          content: emailContentConverted,
          subject: replaceVariablesSymbolsForBackend(
            subjectDetail,
            emailVariables.common
          ),
          contentWithCss: prepareBulkTransferData(
            forEmail(tempEmailContent),
            emailVariables.action
          ), // 先forEmail再prepare
        };

        setSubjectDetail(
          replaceVariablesSymbolsForBackend(
            subjectDetail,
            emailVariables.common
          )
        );
        dispatch(editEmailTemplate(emailObject));
      }
    }
    setIsEdit(false);
  };

  // 标题和编辑按钮
  const TopBlock = props => {
    const { title } = props;
    const containerStyle = (justifyContent, width) => {
      return {
        display: 'flex',
        justifyContent: justifyContent,
        // width: width,
      };
    };
    const IconButtonStyle = {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    };
    // if (isEdit) {
    //   return (
    //     <Box
    //       data-selenium-id={`EmailTemplates-CommonModule-ShowAndEditForAction-${partTitle
    //         .split(' ')
    //         .join('')}-TopBlock-Box`}
    //       sx={{
    //         ...containerStyle('flex-start', '100%'),
    //         minWidth: '550px',
    //         height: '24px',
    //       }}
    //     >
    //       <CustomPartTitle>{title}</CustomPartTitle>
    //       <Box
    //         onClick={handleCancelClick}
    //         sx={{ marginRight: '16px', ...IconButtonStyle }}
    //         id='MarketCancel'
    //         data-selenium-id={`EmailTemplates-CommonModule-ShowAndEditForAction-${partTitle
    //           .split(' ')
    //           .join('')}-TopBlock-MarketCancel`}
    //       >
    //         <MarketEditCancel></MarketEditCancel>
    //       </Box>
    //       <Box
    //         onClick={() => handleSaveClick()}
    //         sx={IconButtonStyle}
    //         id='MarketSave'
    //         data-selenium-id={`EmailTemplates-CommonModule-ShowAndEditForAction-${partTitle
    //           .split(' ')
    //           .join('')}-TopBlock-MarketSave`}
    //       >
    //         {isLoading ? <CircularProgress size='16px' /> : <MarketEditSave />}
    //       </Box>
    //     </Box>
    //   );
    // } else
      {
      return (
        <Box sx={{
          ...containerStyle('flex-start', '100%'),
          height: '24px',
        }}>
          <CustomPartTitle>{title}</CustomPartTitle>
          <Box
            onClick={() => setIsEdit(true)}
            sx={{
              marginRight: '16px',
              ...IconButtonStyle,
              '&:hover svg path': {
                fill: '#113D95',
              }
            }}
            id='MarketEdit'
            data-selenium-id='MarketEdit'
          >
            <MarketEdit></MarketEdit>
          </Box>
        </Box>
      );
    }
  };
  const emailSubjectForShow = replaceVariablesForFrontShow(
    subjectDetail,
    emailVariables.action
  );

  if (isEdit === false) {
    return (
      <Box
        style={{
          padding: '12px 20px 8px 20px',
          marginLeft: '-32px',marginTop: '16px',
          position: 'relative',
          borderRadius: '0px 4px 4px 0px',
          background: '#FFF',
          boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
        }}
        data-selenium-id={`EmailTemplates-CommonModule-ShowAndEditForActionForAction-${partTitle
          .split(' ')
          .join('')}-Box`}
      >
        <div style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: '-8px',
          width: '8px',
          background: '#0052CC',
          borderRadius: '4px 0px 0px 4px',
        }}></div>
        {/* 标题部分 */}
        <TopBlock title={partTitle}></TopBlock>

        <CustomEmailBlock title='Subject:' margin='16px 0px 8px 0px'>
          {/* 因为要绑定修改后的数据 contentDetail.subject 修改成subjectDetail */}
          <CustomText>{emailSubjectForShow.innerText} </CustomText>
        </CustomEmailBlock>

        {!moreOrLess ? (
          <>
            <CustomEmailBlock title='From:' margin='8px 0px'>
              <CustomText>SIMT system</CustomText>
            </CustomEmailBlock>
            <CustomEmailBlock title='To:' margin='8px 0px'>
              <CustomText>{textReplace(contentDetail.to)}</CustomText>
            </CustomEmailBlock>
            <CustomEmailBlock title='Copy:' margin='8px 0px'>
              <CustomText>{textReplace(copy)}</CustomText>
            </CustomEmailBlock>
          </>
        ) : (
          <></>
        )}

        {/* Email Content*/}
        <CustomEmailBlock title='Email Content:' margin='8px 0px 6px 0px'>
          <CustomContent
            id={block}
            data-selenium-id={block}
            value={contentDetail.content}
            // value={emailContent}
            maxHeight='60px'
            moreOrLess={moreOrLess}
            setMoreOrLess={setMoreOrLess}
          ></CustomContent>
        </CustomEmailBlock>
      </Box>
    );
  } else {
    return (
      <Box
        style={{
          padding: '12px 20px 8px 20px',
          marginLeft: '-32px',marginTop: '16px',
          position: 'relative',
          borderRadius: '0px 4px 4px 0px',
          background: '#FFF',
          boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
        }}
        data-selenium-id={`EmailTemplates-CommonModule-ShowAndEditForActionForAction-${partTitle
          .split(' ')
          .join('')}-Box`}
      >
        <div style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: '-8px',
          width: '8px',
          background: '#0052CC',
          borderRadius: '4px 0px 0px 4px',
        }}></div>

        <TopBlock title={partTitle}></TopBlock>

        <CustomEmailBlock title='Subject:' margin='16px 0px 8px 0px'>
          <CustomCKEditor
            content={replaceVariablesSymbolsForFrontendInTransfer(
              subjectDetail
            )}
            setContent={e => {
              setSubjectDetail(e);
            }}
          ></CustomCKEditor>
        </CustomEmailBlock>

        <CustomEmailBlock title='From:' margin='8px 0px'>
          <CustomLabel>SIMT system</CustomLabel>
        </CustomEmailBlock>

        <CustomEmailBlock title='To:' margin='8px 0px'>
          <CustomLabel>{discardBracket(contentDetail.to)}</CustomLabel>
        </CustomEmailBlock>

        <CustomEmailBlock title='Copy:' margin='8px 0px'>
          <CopyPart></CopyPart>
        </CustomEmailBlock>

        <CustomEmailBlock title='Email Content:' margin='8px 0px'>
          <RichTextEditor
            content={tempEmailContent}
            setContent={e => {
              setEmailContent(e);
            }}
            variables={emailVariables.action}
          ></RichTextEditor>
        </CustomEmailBlock>

        <CustomEmailBlock title={''} margin='8px 0px'>
          <Box
            className={styleModule.SaveAndCancelButtonBox}
            sx={{
              justifyContent: 'flex-start',
              gap: '12px',
            }}
          >
            <Button
              disableRipple
              onClick={() => handleSaveClick()}
              // disabled={isDisabled}
              className={styleModule.SaveButton}
              sx={{ width: '70px !important', }}
            >Save</Button>
            <Button
              disableRipple
              className={styleModule.CancelButton}
              onClick={() => handleCancelClick()}
              sx={{ width: '70px !important', }}
            >Cancel</Button>
          </Box>
        </CustomEmailBlock>
      </Box>
    );
  }
}
export default ShowAndEditForAction;

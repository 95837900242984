import { useMemo } from 'react';
import Chart from '@/components/Chart/Chart';
import { ArticlesOverviewTargetOptions } from '../options';

const ArticlesChart = ({
  barchartData,
  width,
  hasZoom = false,
  hasTitle = false,
  title,
  subTitle,
}) => {

  const chartOption = useMemo(() => {
    return new ArticlesOverviewTargetOptions(width);
  }, []);

  const options = useMemo(() => {
    chartOption
      .setZoom(hasZoom)
      .setTitle(hasTitle, title, subTitle)
      .setDataSource(barchartData)
      .setWidth(width)
      .setNumOfGroups(barchartData.length - 1 ?? 0);

    return chartOption.getOption();
  }, [hasZoom, hasTitle, title, subTitle, barchartData, width]);

  const chartHeight = useMemo(() => {
    return chartOption.getHeightWithTitle();
  }, [hasTitle, title, subTitle, barchartData]);

  return (
    <Chart
      options={options}
      height={chartHeight}
      width={width}
      isZoomLabelFontSize={false}
    />
  );
};

export default ArticlesChart;

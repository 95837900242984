import { useMemo } from 'react';
import Chart from '@/components/Chart/Chart';
import { NormalChartOptions } from '../options';
import { SUBJECT_GROUP } from '../../Constants/index';
import { getFYs } from '@/pages/Report/chartUtils';

const GapChart = ({
  // isEmptyData,
  // selectedCatagories,
  // alertLabel,
  width,
  hasZoom = false,
  hasTitle = false,
  selectedMonth,
  title,
  subTitle,
  diagramType,
  dataForBar,
  // loading = false,
  // error = false,
  isDownload = false,
}) => {
  // if (isEmptyData) {
  //   return (
  //     <NoDataPage
  //       title={`There is no data for any ${alertLabel.toLowerCase()}s on the selected date.`}
  //       subTitle={'Please choose a different date to view diagram.'}
  //     />
  //   );
  // } else if (
  //   Array.isArray(selectedCatagories) &&
  //   selectedCatagories.length === 0
  // ) {
  //   return (
  //     <NoDataPage
  //       title={`Please select at least one ${alertLabel.toLowerCase()}.`}
  //     />
  //   );
  // }

  const level = subTitle;
  const isSubjectGroup = level && level.indexOf(SUBJECT_GROUP) >= 0;

  const chartOption = useMemo(() => {
    // console.log('===dataForBar===', dataForBar);

    const legendText = dataForBar[0];

    return new NormalChartOptions(
      diagramType,
      isSubjectGroup,
      isDownload,
      legendText
    );
  }, [diagramType, isSubjectGroup, isDownload]);

  const legendData = getFYs(selectedMonth);

  let options = useMemo(() => {
    // if loading or error skip drawing the chart
    // if (loading || error) {
    //   return null;
    // }

    if (isSubjectGroup) {
      // data include a line of title
      chartOption.setNumOfSG(dataForBar.length - 1 ?? 0);
    }

    chartOption
      .setZoom(hasZoom)
      .setTitle(hasTitle, title, subTitle)
      .setLegendData(legendData)
      .setDataSource(dataForBar)
      .setWidth(width);

    return chartOption.getOption();
  }, [hasZoom, width, hasTitle, title, subTitle, dataForBar]);

  console.log('======options=====, ', options);

  const chartHeight = useMemo(() => {
    // if loading or error set chart height to 0
    // if (loading || error) {
    //   return 0;
    // }

    return chartOption.getHeightWithTitle();
  }, [hasTitle, title, subTitle, dataForBar]);

  // console.log("======chartHeight=====, ", chartHeight)

  return (
    <Chart
      options={options}
      height={chartHeight}
      width={width}
      isZoomLabelFontSize={false}
    />
  );
};

export default GapChart;

import  { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
// Styles
// Components
import { Box, Stack, ThemeProvider, createTheme } from '@mui/material';
import Item from '../../../../../SIPage/Overview/Filter/component/Framework/Block/Item';
import Section from '../../../../../SIPage/Overview/Filter/component/Framework/Block/Section';
import ThemeButton from './CommonComponents/ThemeButton';
import OtherCategoriesSelect from './CommonComponents/OtherCategoriesSelect';
import DefaultSelectBox from './CommonComponents/DefaultSelectBox';
import SelectBox from './CommonComponents/SelectBox';
import { StyledChip } from '@/pages/SIPage/Overview/Filter/component/StyledComponents/styledChip';
import { GETooltipIf } from '@/componentsphase2/SelectBox/SelectInput';
// Assets
import Separator from '../../../../../SIPage/Overview/Filter/component/SVGComponents/Separator.svg';
import ChipClearIcon from '../../../../../SIPage/Overview/Filter/component/SVGComponents/ChipClearIcon.svg';
// Redux
import { loadData, setSelect } from '@/actions/SIMT-SIP/SIPFilter/SIPFilterAction';
// Utils
import { measureWidth } from '@/utils/StringWidthMeasure';
import { getPermissionList } from '@/utils/authTokenHandler';
import {
  getFieldListByPermission,
  getFieldByLabel,
  getLabelByField,
  getThemeByField,
  getThemeColor,
} from './utils';

export default function FirstSection({ resetSignal }) {
  const [currentTab, setCurrentTab] = useState('');
  const [currentData, setCurrentData] = useState([]);
  const [dataDict, setDataDict] = useState({});  // the 1st search box selection
  const [topThreeSum, setTopThreeSum] = useState(0);
  
  const dispatch = useDispatch();

  const {
    sipCode,
    sipTitle,
    handlingCe,
    operationsLead,
    screener,
    geName,
    geEmail,
    aimsAndScope,
    topics,
    keywords,
    sipFilterSelected, // all selected items | type: object of arrays
  } = useSelector(state => {
    return {
      // filter display
      sipCode: state.SIPFilter.sipCode,
      sipTitle: state.SIPFilter.sipTitle,
      handlingCe: state.SIPFilter.handlingCe,
      operationsLead: state.SIPFilter.operationsLead,
      screener: state.SIPFilter.screener,
      geName: state.SIPFilter.geName,
      geEmail: state.SIPFilter.geEmail,
      aimsAndScope: state.SIPFilter.aimsAndScope,
      topics: state.SIPFilter.topics,
      keywords: state.SIPFilter.keywords,
      // filter selected
      sipFilterSelected: state.SIPFilter.sipFilterSelected,
    };
  });

  const permissions = getPermissionList(localStorage.getItem('authorization'));

  const { quickBtnFields, dropdownFields } = getFieldListByPermission(permissions);
  const fieldList = [...quickBtnFields, ...dropdownFields];

  const handleSelectedChange = (which, data) => {
    dispatch(setSelect(which, data));
  };

  function handleSwitchTab(data) {
    // console.log(`function(handleSwitchTab) is called with data (${data})\n`, data );
    if (currentTab === '') {
      setCurrentTab(data?.label ?? '');
      return 0;
    }
    let currentField = getFieldByLabel(currentTab);
    let selectedData = sipFilterSelected[currentField + 'Selected']?.concat() || [];
    let newCurrentData = composeData(currentTab, selectedData, currentData);
    handleSelectedChange(currentField, newCurrentData);
    setCurrentData([]); // clear search box
    setCurrentTab(data?.label ?? ''); // set new tab
  }

  const { sipOrigin } = useParams();

  // refactor mark:
  // check if this run every time the component loaded
  const onLoad = async (which, searchContent, page = 1, pageSize = 5000) => {
    // This list is constant, no more load
    if (which === 'stage') {
      return;
    }
    await dispatch(loadData(which, searchContent, page, pageSize, sipOrigin === 'solicited'));
  };

  useEffect(() => {
    if (currentTab !== '') {
      let field = getFieldByLabel(currentTab);
      onLoad(field, '', 1, 20);
    }
  }, [currentTab]);

  useEffect(() => {
    setCurrentTab('');
    setCurrentData([]);
    setDataDict({});
  }, [resetSignal]);

  const getDataByLabel = label => {
    switch (label) {
      case 'SIP Code':
        return sipCode;
      case 'SIP Title':
        return sipTitle;
      case 'CE':
        return handlingCe;
      case 'Operations Lead':
        return operationsLead;
      case 'Screener':
        return screener;
      case 'GE Name':
        return geName;
      case 'GE Email Address':
        return geEmail;
      case 'Aims and Scope':
        return aimsAndScope;
      case 'Topics':
        return topics;
      case 'Keywords':
        return keywords;
      default:
        return [];
    }
  };


  useEffect(() => {
    let newDict = {};
    fieldList.forEach(field => {
      let label = getLabelByField(field);
      newDict[label] = {
        label: label,
        field: field,
        number: sipFilterSelected[field + 'Selected']?.length ?? 0,
      };
    });
    setDataDict(newDict);
    setTopThreeSum(getQuickBtnSelectSum(newDict, quickBtnFields));
  }, [sipFilterSelected]);

  return (
    <Section id='first_section'>
      <Item
        id='refine_search_results_by_categories'
        title='Refine Search Results by Categories'
        stackProps={{ spacing: 1 }}
      >
        <Stack spacing={2} direction='column'>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              flexWrap: 'wrap',
            }}
          >
            {quickBtnFields
              .concat()
              .map(field => {
                let label = getLabelByField(field);
                return (
                  <ThemeButton
                    id={field}
                    key={field}
                    label={label}
                    number={dataDict[label]?.number ?? 0}
                    theme={getThemeByField(field)}
                    currentTab={currentTab}
                    onClick={() => {
                      handleSwitchTab(dataDict[label]);
                    }}
                    sx={{
                      minWidth: '41px',
                    }}
                  />
                );
              })}
            <OtherCategoriesSelect
              currentTab={currentTab}
              fieldList={dropdownFields}
              dataDict={dataDict}
              handleSwitchTab={handleSwitchTab}
              topThreeSum={topThreeSum}
            />
          </Box>
          <Stack spacing={0.75} direction='column'>
            {currentTab === '' ? (
              <DefaultSelectBox />
            ) : (
              <SelectBox
                data={getDataByLabel(currentTab)}
                labelName={currentTab}
                value={currentData}
                setSelected={setCurrentData}
                loadMore={onLoad.bind(this, getFieldByLabel(currentTab))}
                handleSwitchTab={handleSwitchTab}
              />
            )}
            {/* above is the search input */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '4px',
              }}
            >
              {/* selected items for top search box */}
              {fieldList.map((field, idx) => {
                let temSIPFilterSelected = sipFilterSelected[field + 'Selected']?.concat();
                if (temSIPFilterSelected === undefined || temSIPFilterSelected.length === 0) {
                  return null;
                }
                let hasFollow = false;
                for (let i = idx + 1; i < fieldList.length; i++) {
                  let label = getLabelByField(fieldList[i]);
                  // what is the data format of "dataDict"
                  // contains key : {label, field, number}
                  if (dataDict[label]?.number > 0) {
                    hasFollow = true;
                    break;
                  }
                }
                let cssTheme = createTheme({
                  themeColor: getThemeColor(getThemeByField(field)),
                });
                temSIPFilterSelected.push(null);
                let label = getLabelByField(field);
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: '4px',
                    }}
                    key={`FirstSection-${label}`}
                  >
                    {temSIPFilterSelected.map((item, idx) => {
                      if (idx === temSIPFilterSelected.length - 1) {
                        return hasFollow ? (
                          <Separator key={`FirstSection-${label}-Separator`} />
                        ) : null;
                      }
                      // seperated list of items from the 1st search input
                      let optionName =
                        label === 'CE' || label === 'Operations Lead' || label === 'Screener'
                          ? `${item.username} (${item.email})`
                          : item;
                      return (
                        <ThemeProvider
                          theme={cssTheme}
                          key={`FirstSection-${label}-${optionName}`}
                        >
                          <GETooltipIf
                            title={optionName}
                            disabled={
                              measureWidth(optionName, '12px Open Sans') < 347
                            }
                          >
                            <StyledChip
                              label={optionName}
                              deleteIcon={
                                <Box sx={{ display: 'flex' }}>
                                  <ChipClearIcon />
                                </Box>
                              }
                              onDelete={() => {
                                let selectedData =
                                  sipFilterSelected[field + 'Selected'].concat();
                                selectedData.splice(idx, 1);
                                handleSelectedChange(field, selectedData);
                                // above line update the current filter selection
                              }}
                            />
                          </GETooltipIf>
                        </ThemeProvider>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          </Stack>
        </Stack>
      </Item>
    </Section>
  );
}

function getQuickBtnSelectSum(dataDict, quickBtnFields) {
  let count = 0;
  for (let field of quickBtnFields) {
    let label = getLabelByField(field);
    let number = dataDict[label].number;
    count += number > 0 ? 1 : 0;
  }
  return count;
}

function composeData(label, arr1, arr2) {
  let noRepeatArr = [];
  for (let i = 0; i < arr2.length; i++) {
    let isRepeat = false;
    for (let j = 0; j < arr1.length; j++) {
      isRepeat =
        label === 'CE' || label === 'Operations Lead' || label === 'Screener'
          ? arr1[j].userId === arr2[i].userId
          : arr1[j] === arr2[i];
      if (isRepeat) {
        break;
      }
    }
    if (!isRepeat) {
      noRepeatArr.push(arr2[i]);
    }
  }
  return arr1.concat(noRepeatArr);
}

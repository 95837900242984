import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { CloseOutlined } from '@mui/icons-material';

/**
 * 复制自 https://mui.com/zh/material-ui/react-dialog/#scrolling-long-content
 * 用于展示view more，传入content即可
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ViewMoreDialog(props) {
  const { open, title, setOpen, content, date } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      data-selenium-id='SIDetail_ViewMoreDialog'
    >
      <DialogTitle
        id='scroll-dialog-title'
        data-selenium-id='SIDetail_ViewMoreDialog-Title'
        sx={{ padding: '24px 24px 0px 24px' }}
      >
        <Box
          sx={{ display: 'flex' }}
          data-selenium-id='SIDetail_ViewMoreDialog-Box'
        >
          <Typography
            fontSize={'20px'}
            lineHeight={'20px'}
            fontWeight={600}
            sx={{ color: '#706E6B', flexGrow: 1 }}
            alignItems='center'
            display={'flex'}
            data-selenium-id='SIDetail_ViewMoreDialog-TitleText'
          >
            {title}
          </Typography>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
            data-selenium-id='SIDetail_ViewMoreDialog-Btn'
          >
            <CloseOutlined data-selenium-id='SIDetail_ViewMoreDialog-BtnIcon' />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
            color: '#577C9E',
          }}
          data-selenium-id='SIDetail_ViewMoreDialog-DateText'
        >
          {date}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{ mb: '20px' }}
        data-selenium-id='SIDetail_ViewMoreDialog-Content'
      >
        <DialogContentText
          id='scroll-dialog-description'
          data-selenium-id='SIDetail_ViewMoreDialog-Description'
          tabIndex={-1}
        >
          <Typography
            variant='body2'
            mt={2}
            lineHeight='20px'
            sx={{ overflowWrap: 'break-word', maxHeight: '200px' }}
            data-selenium-id='SIDetail_ViewMoreDialog-DescriptionText'
          >
            {content}
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

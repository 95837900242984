import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';
import {
  emailVariables,
  replaceVariablesForFrontShow,
} from '../../../modules/RichTextEditor/EditorUtils';

export const commonCss = {
  padding: '2px 8px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  color: '#262E35',
};

const ArrowUp = () => <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.46967 6.21967C8.76256 5.92678 9.23744 5.92678 9.53033 6.21967L14.0303 10.7197C14.3232 11.0126 14.3232 11.4874 14.0303 11.7803C13.7374 12.0732 13.2626 12.0732 12.9697 11.7803L9 7.81066L5.03033 11.7803C4.73744 12.0732 4.26256 12.0732 3.96967 11.7803C3.67678 11.4874 3.67678 11.0126 3.96967 10.7197L8.46967 6.21967Z" fill="#154AB6"/>
</svg>;

const ArrowDown = () => <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.96967 6.21967C4.26256 5.92678 4.73744 5.92678 5.03033 6.21967L9 10.1893L12.9697 6.21967C13.2626 5.92678 13.7374 5.92678 14.0303 6.21967C14.3232 6.51256 14.3232 6.98744 14.0303 7.28033L9.53033 11.7803C9.23744 12.0732 8.76256 12.0732 8.46967 11.7803L3.96967 7.28033C3.67678 6.98744 3.67678 6.51256 3.96967 6.21967Z" fill="#154AB6"/>
</svg>;

function CustomContent(props) {
  const {
    value,
    id,
    maxHeight,
    moreOrLess,
    setMoreOrLess,
    variables = emailVariables.full, // 变量（输入#之后的提示框）
    sx = {},
  } = props;
  // 减少计算增设的变量
  const emailContentForShow = replaceVariablesForFrontShow(value, variables);

  const contentMore = emailContentForShow.innerHTML;

  const trCount = emailContentForShow.querySelectorAll('tr').length;
  const tdCount = emailContentForShow.querySelectorAll('td').length;
  const containsLargeTable = tdCount / trCount >= 5; // 行数:列数>=5认为有大表格

  const handleTroggleClick = () => {
    setMoreOrLess(!moreOrLess);
  };

  return (
    <Box
      data-selenium-id='EmailTemplates-CommonModule-CustomContent-Box'
      display='flex'
      alignItems='flex-start'
      sx={{
        ...commonCss,
        marginTop: '-14px',
        position: 'relative',
        overflowX: 'hidden',
        overflowY : 'hidden',
        flexDirection: 'column',

        '& div.lessOrMore': {
          display: 'flex',
          alignItems: 'center',
          color: 'var(--primary-500, #154AB6)',
          textAlign: 'center',
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '18px',
          marginTop: '8px',
        },
        '& div.lessOrMore:hover': {
          color: 'var(--primary-600, #113D95)',
          fontWeight: 600,
          '& svg path': {
            stroke: '#113D95',
          },
        },
        ...sx,
      }}
    >
      {
        React.createElement('div', {
          dangerouslySetInnerHTML: { __html: contentMore },
          id: id,
          'data-selenium-id': 'EmailTemplates-CommonModule-CustomContent-EmailTemplateShow' + id,
          key: id,
          style: moreOrLess ? {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: '3px',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
          } : {
            width:  'auto',
            maxWidth: containsLargeTable ? '1800px' : '800px',
            minHeight: '10px',
            maxHeight: moreOrLess ? maxHeight: 'none',
            wordBreak: 'break-word',
          },
        })
      }
      {/*</div>*/}
      <Box className='lessOrMore'
        onClick={handleTroggleClick}
      >
        {moreOrLess? (
          <>
              View More <ArrowDown/>
          </>):  (<>
              View Less <ArrowUp/>
        </>) }
      </Box>
    </Box>
  );
}
export default CustomContent;

import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormControlLabel,
  FormGroup,
  InputLabel,
  Stack,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import ReportExportMenuButton from '../../ReportSpecificComponents/ReportExportMenuButton';
import FYSubjectGroupTable from './FYSubjectGroupTable';
import FYBasicTable, { handleNull } from './FYBasicTable';
import FYBusinessDivisionTable from './FYBusinessDivisionTable';
import { StyledSwitchTwoColor } from '../../ReportSpecificComponents/SpecialColorSwitch';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectYear as fyChangeFiscalYear,
  fySetBusinessShowAll,
  fySetSubjectShowAll,
  fyExportFilter,
  fyExportCSVFilter,
} from '../../../../actions/SIMT-Report/Report/ReportAction';
import FYFilterDrawer from './NewFYFilterDrawer';
import {
  PurpleGreenCell,
  PurpleGreenDisCell,
  StyledTableCellDisGray,
  StyledTableCellGray,
  TableGroupBox,
  TableLabel,
} from '../../ReportSpecificComponents/SpecialColorTableHeader';
import monthIds from '../../monthIds';
import { StyledTableCell } from '../../../../components/DataTable';
import { FYSelectStack } from '../../GenerationDate';
import styles from '../../header-switch.module.scss';
import DiagramContent from './FYTrendsDiagrams';
import { getFyYearFromDate } from '../../../../utils/date_string';
import ExportAllDialog from '../../ExportAllDialog';
import { StateButton } from '@/componentsphase2/StateButton';
import {useFilterCounterFyHook} from '@/pages/Report/utils/useFilterCounterFyHook';
import {fyExportCSVWithFilenameFromBackEnd, fyExportWithFilenameFromBackEnd} from "@/actions/SIMT-Report/Report/Export";
import {GetAcquiredActiveLastActionDateFY} from "@/actions/SIMT-Report/Report/ReportAcquiredAndActive";

export function CommonHeaderFirstRow(props) {
  const {
    left,
    orderByType = { orderBy: null, order: null },
    handleChangeOrder = null,
    isOA,
    tableName = '',
    columnWidthMap,
    columnMinWidthMap,
  } = props;

  function calcAllJournalWidth() {
    let sum = 0;
    for (let item of monthIds) {
      sum +=
        Math.max(
          columnWidthMap[`acq${item.post}`] ?? 0,
          columnMinWidthMap['acq-' + item.id]
        ) + 50;
    }
    sum +=
      Math.max(columnWidthMap['total'] ?? 0, columnMinWidthMap['acq-total']) +
      50;
    return sum;
  }

  function calcOAJournalWidth() {
    let sum = 0;
    for (let item of monthIds) {
      sum +=
        Math.max(
          columnWidthMap[`act${item.post}`] ?? 0,
          columnMinWidthMap['acc-' + item.id]
        ) + 50;
    }
    return sum;
  }

  return (
    <>
      {/*表头的几个部分*/}
      <TableRow
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonHeaderFirstRow${tableName}-TableRow`}
      >
        {left.map((item, index) => (
          <StyledTableCellDisGray
            id={`${item.id}${tableName}Header`}
            isOA={isOA}
            key={item.label}
            align={item.align}
            rowSpan={2}
            className={
              index === left.length - 1 ? 'RightBottomBorder' : 'BottomBorder'
            }
            sx={{
              // minWidth: item.minWidth,
              // maxWidth: item.maxWidth,
              ...(item.id === 'journalInformation' && {
                position: 'sticky',
                left: 0,
                top: 0,
                zIndex: 8,
                backgroundColor: '#DFE4E8',
                width: `${
                  Math.max(
                    columnWidthMap[item.id] ?? 0,
                    columnMinWidthMap[item.id]
                  ) + 50
                }px`,
              }),
              width: `${
                Math.max(
                  columnWidthMap[item.id] ?? 0,
                  columnMinWidthMap[item.id]
                ) + 50
              }px`,
              textTransform: 'uppercase',
            }}
            sortDirection={
              orderByType.orderBy === item.id ? orderByType.order : false
            }
            data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonHeaderFirstRow${tableName}-PurpleGreenCell${item.id}`}
          >
            {item.isOrder ? (
              <TableSortLabel
                id={`${item.id}${tableName}SortLabel`}
                active={orderByType.orderBy === item.id}
                direction={
                  orderByType.orderBy === item.id ? orderByType.order : 'asc'
                }
                onClick={() => {
                  if (item.isOrder) {
                    handleChangeOrder(item.id);
                  }
                }}
                data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonHeaderFirstRow${tableName}-TableSortLabel${item.id}`}
              >
                {item.label}
              </TableSortLabel>
            ) : (
              <>{item.label}</>
            )}
          </StyledTableCellDisGray>
        ))}
        {/*右侧是两列，各合并12个列*/}
        <StyledTableCellGray
          isOA={isOA}
          colSpan={13}
          align={'left'}
          className='RightBottomBorder ReportHeaderFirstLine'
          data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonHeaderFirstRow${tableName}-AcquiredSIPurpleGreenCell`}
          sx={{
            width: `${calcAllJournalWidth()}px`,
          }}
        >
          <FormattedMessage id='report.fyTrendsTable.acquiredSI' />
        </StyledTableCellGray>
        <StyledTableCellGray
          isOA={isOA}
          colSpan={12}
          align={'left'}
          className='BottomBorder ReportHeaderFirstLine'
          data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonHeaderFirstRow${tableName}-ActiveSIPurpleGreenCell`}
          sx={{
            width: `${calcOAJournalWidth()}px`,
          }}
        >
          <FormattedMessage id='report.fyTrendsTable.activeSI' />
        </StyledTableCellGray>
      </TableRow>
    </>
  );
}

export function CommonHeaderSecondRow(props) {
  const intl = useIntl();
  const { isOA, isBasic, columnWidthMap, columnMinWidthMap } = props;

  return (
    <TableRow
      data-selenium-id={
        'Report_AcquiredAndActiveSi-FYTrends-CommonHeaderSecondRow'
      }
    >
      {/*月份，在这个表中重复2次*/}
      {/*前1次是右下border加宽，最后一个是下border加宽*/}
      {monthIds.map((item, index) => (
        <PurpleGreenDisCell
          isBasic={isBasic}
          isOA={isOA}
          key={'acq-' + item.id}
          className={
            'BottomBorder'
            // index === monthIds.length - 1
            //   ? 'RightBorder BottomBorder'
            //   : 'BottomBorder'
          }
          sx={{
            // top: 52.5,
            top: 42,
            width: `${
              Math.max(
                columnWidthMap['acq' + item.post] ?? 0,
                columnMinWidthMap['acq-' + item.id]
              ) + 50
            }px`,
            fontSize: '12px',
          }}
          data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonHeaderSecondRow-FirstPurpleGreenCell${item.id}`}
        >
          {intl.messages[`month.${item.id}`]}
        </PurpleGreenDisCell>
      ))}
      <PurpleGreenDisCell
        isBasic={isBasic}
        isOA={isOA}
        key={'acq-total'}
        className={'RightBottomBorder BottomBorder'}
        sx={{
          // top: 52.5,
          top: 42,
          width: `${
            Math.max(
              columnWidthMap['total'] ?? 0,
              columnMinWidthMap['acq-total']
            ) + 50
          }px`,
          fontSize: '12px',
        }}
        data-selenium-id={
          'Report_AcquiredAndActiveSi-FYTrends-CommonHeaderSecondRow-TotalPurpleGreenCell'
        }
      >
        <Box>
          <b>Total</b>
        </Box>
      </PurpleGreenDisCell>
      {monthIds.map(item => (
        <PurpleGreenDisCell
          isBasic={isBasic}
          isOA={isOA}
          key={'acc-' + item.id}
          className='BottomBorder'
          sx={{
            // top: 52.5,
            top: 42,
            width: `${
              Math.max(
                columnWidthMap['act' + item.post] ?? 0,
                columnMinWidthMap['acc-' + item.id]
              ) + 50
            }px`,
            fontSize: '12px',
          }}
          data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonHeaderSecondRow-SecondPurpleGreenCell${item.id}`}
        >
          {intl.messages[`month.${item.id}`]}
        </PurpleGreenDisCell>
      ))}
    </TableRow>
  );
}

export function CommonContentCells(props) {
  const { row, isTotal } = props;
  return (
    <>
      {/*Acquired*/}
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell1`}
      >
        {handleNull(row.acqMay)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell2`}
      >
        {handleNull(row.acqJun)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell3`}
      >
        {handleNull(row.acqJul)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell4`}
      >
        {handleNull(row.acqAug)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell5`}
      >
        {handleNull(row.acqSep)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell6`}
      >
        {handleNull(row.acqOct)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell7`}
      >
        {handleNull(row.acqNov)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell8`}
      >
        {handleNull(row.acqDec)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell9`}
      >
        {handleNull(row.acqJan)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell10`}
      >
        {handleNull(row.acqFeb)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell11`}
      >
        {handleNull(row.acqMar)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? ' ReportNumberTextColor ReportTotalTextSize'
            : ' ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell12`}
      >
        {handleNull(row.acqApr)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          'RightBottomBorder  ReportNumberTextColor ReportTotalTextSize'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell13`}
      >
        {handleNull(row.total)}
      </StyledTableCell>
      {/*Active*/}
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell14`}
      >
        {handleNull(row.actMay)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell15`}
      >
        {handleNull(row.actJun)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell16`}
      >
        {handleNull(row.actJul)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell17`}
      >
        {handleNull(row.actAug)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell18`}
      >
        {handleNull(row.actSep)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell19`}
      >
        {handleNull(row.actOct)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell20`}
      >
        {handleNull(row.actNov)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell21`}
      >
        {handleNull(row.actDec)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell22`}
      >
        {handleNull(row.actJan)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell23`}
      >
        {handleNull(row.actFeb)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell24`}
      >
        {handleNull(row.actMar)}
      </StyledTableCell>
      <StyledTableCell
        align='left'
        className={
          isTotal
            ? 'ReportNumberTextColor ReportTotalTextSize'
            : 'ReportNumberTextColor'
        }
        data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-CommonContentCells-${row.id}StyledTableCell25`}
      >
        {handleNull(row.actApr)}
      </StyledTableCell>
    </>
  );
}

export default function FYTrends({ showTable, setShowTable }) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const {
    // fiscalYear,
    selectMonthDate,
    subjectGroupShowAll,
    businessDivisionShowAll,
    AcquiredActiveReportGenenateDateFy,
    useFilter,
  } = useSelector(state => {
    return {
      // fiscalYear: state.Report.fyTrends.fiscalYear,
      selectMonthDate: state.Report.selectDate,
      subjectGroupShowAll: state.Report.fyTrends.subjectGroupShowAll,
      businessDivisionShowAll: state.Report.fyTrends.businessDivisionShowAll,
      AcquiredActiveReportGenenateDateFy:
        state.Report.AcquiredActiveReportGenenateDateFy,
      useFilter: state.Report.fyTrends.useFilter,
    };
  });

  const [fiscalYear, setFiscalYear] = useState(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    let date = year + '-' + month;
    return getFyYearFromDate(date);
  });
  useEffect(() => {
    if (selectMonthDate != null) {
      const fyYear = getFyYearFromDate(selectMonthDate);
      setFiscalYear(fyYear);
    }
  }, [selectMonthDate]);

  const GenerationDateAndTime =
    'Below reports are generated on ' +
    AcquiredActiveReportGenenateDateFy +
    ' (UTC time). ';

  const [filterOpen, setFilterOpen] = useState(false);

  const handleExportExcelWithFilenameFromBackEnd = () => {
    dispatch(fyExportWithFilenameFromBackEnd(fiscalYear));
  };

  const handleExportCSVWithFilenameFromBackEnd = () => {
    dispatch(fyExportCSVWithFilenameFromBackEnd(fiscalYear, 'basic_table'));
    dispatch(
      fyExportCSVWithFilenameFromBackEnd(fiscalYear, 'subject_group_table')
    );
    dispatch(
      fyExportCSVWithFilenameFromBackEnd(fiscalYear, 'business_division_table')
    );
  };

  const handleFilter = () => {
    setFilterOpen(true);
  };

  const [exportOpen, setExportOpen] = useState(false);
  const exportExcelFilter = () => {
    let url = '/reports/export/acquired-active/fy-trends/excelWithFilter';
    dispatch(fyExportFilter(fiscalYear, url, useFilter));
  };

  const handleExportExcel = type => {
    if (type === 'filter') {
      exportExcelFilter();
    } else {
      handleExportExcelWithFilenameFromBackEnd();
    }
  };

  const exportCsvFilterd = () => {
    let url = '/reports/export/acquired-active/fy-trends/csvWithFilter';
    dispatch(fyExportCSVFilter(fiscalYear, 'basic_table', url, useFilter));
    dispatch(
      fyExportCSVFilter(fiscalYear, 'subject_group_table', url, useFilter)
    );
    dispatch(
      fyExportCSVFilter(fiscalYear, 'business_division_table', url, useFilter)
    );
  };
  const handleExportCSV = type => {
    if (type === 'filter') {
      exportCsvFilterd();
    } else {
      handleExportCSVWithFilenameFromBackEnd();
    }
  };
  
  const filterNumber = useFilterCounterFyHook(useFilter);

  return (
    <Box
      sx={{ width: 'auto' }}
      data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Box'
    >
      <ExportAllDialog
        open={exportOpen}
        handleClose={() => {
          setExportOpen(false);
        }}
        filterData={useFilter}
        handleCSV={handleExportCSV}
        handleExcel={handleExportExcel}
      />
      <Stack
        justifyContent='flex-start'
        alignItems='flex-start'
        width='100%'
        data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack'
      >
        {/*顶部几个操作*/}
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          width='100%'
          sx={{ p: '16px 0px 16px 0px' }}
          data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Stack1'
        >
          <FYSelectStack
            id='Report_AcquiredAndActiveSi-FYTrends-Stack-Stack1-Stack'
            fiscalYear={fiscalYear}
            handleYearChange={year => {
              const fyYear = getFyYearFromDate(selectMonthDate);
              if (year != fyYear) dispatch(fyChangeFiscalYear(year));
              dispatch(GetAcquiredActiveLastActionDateFY(year, false));
            }}
            lastActionDate={AcquiredActiveReportGenenateDateFy}
            showTable={showTable}
            GenerationDateAndTime={GenerationDateAndTime}
          />
          <div className={styles['action-buttons-container']}>
            {/*导出按钮*/}
            {/* {showTable == 'true' && ( */}
            {showTable && (
              <ReportExportMenuButton
                sx={{ height: '32px', marginRight: '24px' }}
                id='reportSipipelineExportButton'
                data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Stack1-Stack2'
                mode={1}
                // label='common.Export'
                onClick={() => {
                  setExportOpen(true);
                }}
                data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Stack1-Stack2'
              />
            )}
            <span className={styles['switch']}>
              <span
                id='show-table-button'
                className={styles[showTable ? 'selected' : '']}
                onClick={() => {
                  setShowTable(true);
                }}
              >
                Table
              </span>
              <span
                id='show-diagram-button'
                className={styles[!showTable ? 'selected' : '']}
                onClick={() => {
                  setShowTable(false);
                }}
              >
                Diagram
              </span>
            </span>
          </div>
        </Stack>
        {/* {AcquiredActiveReportGenenateDateFy ? (
          <Stack
            container
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <GenerationDate
              GenerationDateAndTime={GenerationDateAndTime}
            ></GenerationDate>
          </Stack>
        ) : (
          <></>
        )} */}
        {showTable ? (
          <>
            {/*basic table*/}
            <TableGroupBox
              maxWidth='100%'
              mt={1}
              id='drawer-container'
              sx={{ position: 'relative' }}
              data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Box1'
            >
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Box1-Stack'
                gap= '20px'
                sx={{
                  paddingTop: '1px',
                  paddingRight: '30px',
                }}
              >
                <TableLabel>
                  Basic Table
                  <Typography
                    variant='caption'
                    color='#FF8180'
                    fontSize={14}
                    sx={{ marginLeft: '18px' }}
                  >
                    <FormattedMessage id='report.tip' />
                  </Typography>
                </TableLabel>
                <StateButton
                  onClick={handleFilter}
                  isPrimary={filterNumber != 0}
                  filterNumber={filterNumber}
                  titleName='Filter'
                  sx={{ height: '34px', width: '74px', fontSize: '12px' }}
                />
              </Stack>
              <FYBasicTable data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Box1-FYBasicTable' />
              <FYFilterDrawer
                filterOpen={filterOpen}
                drawerWidth={360}
                handleClose={() => {
                  setFilterOpen(false);
                }}
                data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Box1-FYFilterDrawer'
              />
            </TableGroupBox>

            {/*subject group table*/}
            <TableGroupBox
              width='100%'
              data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Box2'
            >
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Box2-Stack'
                sx={{
                  paddingTop: '1px',
                  paddingRight: '30px',
                }}
              >
                <TableLabel sx={{ textTransform: 'capitalize' }}>
                  <FormattedMessage id='report.subjectGroup' />
                </TableLabel>
                {/*控制subject group table 的All*/}
                <FormGroup data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Box2-FormGroup'>
                  <FormControlLabel
                    control={
                      <>
                        <StyledSwitchTwoColor
                          id='SGSwitchTwoColor'
                          data-selenium-id='SGSwitchTwoColor'
                          checked={subjectGroupShowAll}
                          onChange={e => {
                            dispatch(fySetSubjectShowAll(e.target.checked));
                          }}
                        />
                      </>
                    }
                    label={''}
                    data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Box2-FormControlLabel'
                  />
                </FormGroup>
              </Stack>

              <FYSubjectGroupTable sx={{ mt: '33px' }} />
            </TableGroupBox>

            <TableGroupBox
              width='100%'
              data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Box3'
            >
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Box3-Stack'
                sx={{
                  paddingTop: '1px',
                  paddingRight: '30px',
                }}
              >
                <TableLabel sx={{ textTransform: 'capitalize' }}>
                  <FormattedMessage id='report.businessDivision' />
                </TableLabel>
                {/*控制business division table 的All*/}
                <FormControlLabel
                  control={
                    <>
                      <StyledSwitchTwoColor
                        id='BDSwitchTwoColor'
                        data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Box3-FormControlLabel-BDSwitchTwoColor'
                        checked={businessDivisionShowAll}
                        onChange={e => {
                          dispatch(fySetBusinessShowAll(e.target.checked));
                        }}
                      />
                    </>
                  }
                  label={''}
                  data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Box3-FormControlLabel'
                />
              </Stack>
              <FYBusinessDivisionTable />
            </TableGroupBox>
          </>
        ) : (
          <DiagramContent />
        )}
      </Stack>
    </Box>
  );
}

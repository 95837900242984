import * as React from 'react';
import CreateAndUpdateHeader from '../CommonComponents/CreateAndUpdateHeader';
import CreateAndUpdateFooter from '../CommonComponents/CreateAndUpdateFooter';
import CreateAndUpdateMain from '../CommonComponents/CreateAndUpdateMain';
import GeneralInformation from '../Update/UpdatePages/GeneralInformationForm';
import { useLocation, useNavigate } from 'react-router-dom';
import StakeholdersAndDates from '../Update/UpdatePages/StakeholdersAndDatesForm';
import SubmissionOverviewPage from '../SubmissionOverviewPage';
import PublicationDeatilsPage from '../PublicationDetails';
import ReviewPage from '../ReviewPage';
import moment from 'moment';
import { getUTCToDate } from '@/utils/commonUtils';
import {
  SIDateChangeFunction2 as SIDateChangeFunction,
  SISubmissionDeadlineDateChangeFunction2 as SISubmissionDeadlineDateChangeFunction,
} from '@/utils/commonUtils';
import { useDispatch, useSelector } from 'react-redux';
import { openConfirm, setEditType, setTextParam } from '@/actions/SIMT-User/Alert/AlertAction';
import CommonSnackBar from '../../../../modules/ConfirmSnackBar/CommonSnackBar';
import {
  scrollToTop,
  trimItemFilds,
  handleGEName,
} from '../../../../utils/commonUtils';
import MainPageFramework from '../CGTComponents/MainPageFramework';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';
import {
  updateToAcquired,
  updateToPaperCommission, updateToProduction,
  updateToPublished, updateToShelved,
  updateToSubmissionAndReview
} from "@/actions/SIMT-SI/SpecialIssue/UpdateSpecialIssue";

function UpdateMainPage() {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isBottom, setIsBottom] = React.useState(false);
  const [createAndUpdateFooterBottom, setCreateAndUpdateFooterBottom] =
    React.useState('0px');
  const location = useLocation(); // 使用useLocation跳过来的值
  const { state } = location;
  //获取当前页面的url
  const url = window.location.pathname.split('/');
  //获得 stage
  const getStage = () => {
    let stage = url[url.length - 1];
    switch (stage) {
      case 'Acquired':
      case 'Production':
      case 'Published':
        return stage;
      case 'PaperCommission':
        return 'Paper Commission';
      case 'Shelved':
        return 'Shelved';
      case 'SubmissionAndReview':
        return 'Submission and Review';
      default:
        return '';
    }
  };
  const stage = getStage();

  //根据stage 获取当前stage的步骤
  const getSteps = () => {
    switch (stage) {
      case 'Acquired':
        return [
          'General Information',
          'Stakeholders & Dates',
          'Review & Update',
        ];
      case 'Production':
      case 'Paper Commission':
      case 'Submission and Review':
        return [
          'General Information',
          'Stakeholders & Dates',
          'Submission Overview',
          'Review & Update',
        ];
      default:
        return [
          'General Information',
          'Stakeholders & Dates',
          'Submission Overview',
          // 'Publication Details',
          'Publication', //modified
          'Review & Update',
        ];
    }
  };
  const steps = getSteps();

  const handleStringtoList = value => {
    return value.split(',');
  };

  const AcquiredMandatoryList = handleStringtoList(
    localStorage.getItem('AcquiredMandatoryList')
  );
  const PaperCommissionMandatoryList = handleStringtoList(
    localStorage.getItem('PaperCommissionMandatoryList')
  );
  const SubmissionAndReviewMandatoryList = handleStringtoList(
    localStorage.getItem('SubmissionAndReviewMandatoryList')
  );
  const ProductionMandatoryList = handleStringtoList(
    localStorage.getItem('ProductionMandatoryList')
  );
  const PublishedMandatoryList = handleStringtoList(
    localStorage.getItem('PublishedMandatoryList')
  );
  const ShelvedMandatoryList = handleStringtoList(
    localStorage.getItem('ShelvedMandatoryList')
  );

  const getMandatoryList = () => {
    switch (stage) {
      case 'Acquired':
        return AcquiredMandatoryList;
      case 'Paper Commission':
        return PaperCommissionMandatoryList;
      case 'Submission and Review':
        return SubmissionAndReviewMandatoryList;
      case 'Production':
        return ProductionMandatoryList;
      case 'Published':
        return PublishedMandatoryList;
      case 'Shelved':
        return ShelvedMandatoryList;
      default:
        return null;
    }
  };

  const MandatoryList = getMandatoryList();

  const SubmissionValueSetFunction = detailInfo => {
    if (
      detailInfo?.generalInformation[0].stage === 'Production' ||
      detailInfo?.generalInformation[0].stage === 'Published' ||
      detailInfo?.generalInformation[0].stage === 'Submission and Review'
    ) {
      return detailInfo.submissionOverview[0];
    } else if (detailInfo?.generalInformation[0].stage === 'Paper Commission') {
      return {
        numberOfArticlesAccepted:
          detailInfo.submissionOverview[0].numberOfArticlesAccepted === 0
            ? null
            : detailInfo.submissionOverview[0].numberOfArticlesAccepted,
        numberOfArticlesInProgress:
          detailInfo.submissionOverview[0].numberOfArticlesInProgress === 0
            ? null
            : detailInfo.submissionOverview[0].numberOfArticlesInProgress,
        numberOfArticlesRecruited:
          detailInfo.submissionOverview[0].numberOfArticlesRecruited,
        numberOfArticlesRejected:
          detailInfo.submissionOverview[0].numberOfArticlesRejected === 0
            ? null
            : detailInfo.submissionOverview[0].numberOfArticlesRejected,
        numberOfArticlesSubmitted:
          detailInfo.submissionOverview[0].numberOfArticlesSubmitted === 0
            ? null
            : detailInfo.submissionOverview[0].numberOfArticlesSubmitted,
      };
    }
    return {
      numberOfArticlesAccepted: null,
      numberOfArticlesInProgress: null,
      numberOfArticlesRecruited: null,
      numberOfArticlesRejected: null,
      numberOfArticlesSubmitted: null,
    };
  };

  const handleGEList = value => {
    let tmpGEList = [];
    if (value === null || value === undefined) {
      return [];
    }
    for (var i in value) {
      tmpGEList.push(handleGEName(value[i]));
    }
    return tmpGEList;
  };

  //获取siCode
  const siCode = url[url.length - 3];
  const [generalInformation, setGeneralInfomation] = React.useState({
    siCodeToBack: siCode,
    projectCodeToBack: state?.generalInformation?.[0].projectCode,
    handlingCeFullNameToBack: state?.generalInformation?.[0].handlingCeFullName,
    handlingCeEmailToBack: state?.generalInformation?.[0].handlingCeEmail,
    groupOrTitleToBack: state?.generalInformation?.[0].groupOrTitle,
    handlingCeUidToBack: state?.generalInformation?.[0].handlingCeUid,
    specialIssueTypeToBack: state?.generalInformation?.[0].specialIssueType,
    journalCodeToBack: state?.generalInformation?.[0].journalCode,
    journalNameToBack: state?.generalInformation?.[0].journalName
      ? state?.generalInformation?.[0].journalName
      : 'Temporary',
    siSourceToBack: state?.generalInformation?.[0].siSource,
    subjectGroupToBack: state?.generalInformation?.[0].subjectGroup,
    siRevenueModel: state?.generalInformation?.[0].siRevenueModel,
    revenueModel: state?.journalDetails?.[0].revenueModel,
    specialIssueTitleToBack: state?.generalInformation?.[0].specialIssueTitle,
    paperCommissioningMethodToBack:
      state?.generalInformation?.[0].paperCommissionMethod,
    workFlowToBack: state?.generalInformation?.[0].workFlow,
  });
  const [stakeholdersAndDates, setStakeholdersAndDates] = React.useState({
    JPM: state?.generalInformation[0].jpm,
    LeadGEValue: handleGEList(state?.generalInformation[0].leadGuestEditors),
    COGEValue: handleGEList(state?.generalInformation[0].coGuestEditors),
    IdeaDate: (state?.generalInformation[0].ideaDate),
    AcquiredDate: (state?.generalInformation[0].acquiredDate),
    OpenForSubmissionDate: (
      state?.generalInformation[0].openForSubmissionDate
    ), // OpenForSubmissionDate下拉框保存的数据
    ExpectedSubmissionDeadline: (
      state?.generalInformation[0].submissionDeadline
    ),
    ExpectedAcceptanceDate: (
      state?.generalInformation[0].expectedAcceptanceDate
    ),
    ExpectedPublicationDate: (
      state?.generalInformation[0].expectedPublicationDate
    ),
    closedSubmit:
      stage === 'Production' || stage === 'Published' || stage === 'Shelved'
        ? 'Yes'
        : state?.generalInformation[0].closedForSubmission || 'No',
  });
  const [submissionOverview, setSubmssionOverview] = React.useState(
    SubmissionValueSetFunction(state)
  );
  const [publicationDetails, setPublicationDetails] = React.useState({
    volume: '',
    issue: '',
    issuePublicationLink: '',
    actualPublicationDate: null,
    ooarticle: null,
    oaarticle: null,
    numberOfWaiversUsed: null,
  });
  const [deadlinePassed, setDeadlinePassed] = React.useState(
    state?.generalInformation[0].deadlinePassed
  );
  const [generalInformationErr, setGeneralInfomationErr] =
    React.useState(false);
  const [stakeholdersAndDatesErr, setStakeholdersAndDatesErr] =
    React.useState(false);
  const [submssionOverviewErr, setSubmssionOverviewErr] = React.useState(false);
  const [publicationDetailsErr, setPublicationDetailsErr] =
    React.useState(false);
  // generalInformation 页面有问题为false,没问题为true
  const [generalInformationClick, setGeneralInfomationClick] =
    React.useState(true);
  const [stakeholdersAndDatesMonitor, setStakeholdersAndDatesMonitor] =
    React.useState(false);
  // stakeholdersAndDates 页面有问题为false,没问题为true
  const [stakeholdersAndDatesClick, setStakeholdersAndDatesClick] =
    React.useState(true);

  // submissionOverview 页面有问题为false,没问题为true
  const [submssionOverviewClick, setSubmssionOverviewClick] =
    React.useState(true);
  const [submssionOverviewMonitor, setSubmssionOverviewMonitor] =
    React.useState(false);
  const [publicationDetailsMonitor, setPublicationDetailsMonitor] =
    React.useState(false);

  const [reviewData, setReviewData] = React.useState({});

  const [remarks, setRemarks] = React.useState(
    state?.generalInformation[0].remarks
  );

  const selector = state => {
    return {
      UpdateSIResult: state.SI.updateSIResult,
      updateSIMessageResult: state.SI.updateSIMessageResult,
    };
  };
  const { UpdateSIResult, updateSIMessageResult } = useSelector(selector);

  const ArrToStr = Arr => {
    let ans = '';
    for (let i = 0; i < Arr.length; i++) {
      if (i !== Arr.length - 1) {
        ans += Arr[i].lastName + ', ' + Arr[i].firstName + ';';
      } else {
        ans += Arr[i].lastName + ', ' + Arr[i].firstName;
      }
    }
    return ans;
  };

  const [isOA, setIsOA] = React.useState(
    state?.journalDetails[0].revenueModel === 'OA' ? true : false
  );

  const handleUpdate = async () => {
    let SIData = {};
    SIData.stage = stage;
    SIData.siRevenueModel = generalInformation.siRevenueModel;
    SIData.revenueModel = generalInformation.revenueModel;
    SIData.projectCode = generalInformation.siCodeToBack;
    SIData.ceId = generalInformation.handlingCeUidToBack;
    if (
      (generalInformation.journalNameToBack === 'Temporary' &&
        generalInformation.journalCodeToBack !== 'Temporary') ||
      (generalInformation.journalNameToBack !== 'Temporary' &&
        generalInformation.journalCodeToBack === 'Temporary')
    ) {
      SIData.journalNameSelect = 'Temporary';
      SIData.journalCodeSelect = 'Temporary';
    } else {
      SIData.journalNameSelect = generalInformation.journalNameToBack;
      SIData.journalCodeSelect = generalInformation.journalCodeToBack;
    }
    SIData.subjectGroup = generalInformation.subjectGroupToBack;
    SIData.JPM = stakeholdersAndDates.JPM;
    SIData.SIType = generalInformation.specialIssueTypeToBack;
    SIData.SITitle = trimItemFilds(generalInformation.specialIssueTitleToBack);
    SIData.SISource = generalInformation.siSourceToBack;
    let LeadGES = [];
    let COGES = [];
    for (const element of stakeholdersAndDates.LeadGEValue) {
      LeadGES.push(element.primaryEmail);
    }
    for (const element of stakeholdersAndDates.COGEValue) {
      COGES.push(element.primaryEmail);
    }
    SIData.LeadGEValue = LeadGES;
    SIData.COGEValue = COGES;
    SIData.paperCommition = generalInformation.paperCommissioningMethodToBack;
    SIData.workFlow = generalInformation.workFlowToBack;
    SIData.closedSubmit = stakeholdersAndDates.closedSubmit;
    SIData.recruitedArticle = Number(
      submissionOverview.numberOfArticlesRecruited
    );
    SIData.submissions = Number(submissionOverview.numberOfArticlesSubmitted);
    SIData.acceptArticle = Number(submissionOverview.numberOfArticlesAccepted);
    SIData.rejectArticle = Number(submissionOverview.numberOfArticlesRejected);
    SIData.articleInProgress = Number(
      state.submissionOverview[0].numberOfArticlesInProgress
    );
    SIData.ooarticle = Number(publicationDetails.ooarticle);
    SIData.oaarticle = Number(publicationDetails.oaarticle);
    SIData.numberOfWaiversUsed = Number(publicationDetails.numberOfWaiversUsed);
    let tmpDate = SIDateChangeFunction(
      (stakeholdersAndDates.IdeaDate)
    );
    SIData.IdeaDate = tmpDate === 'Invalid date' ? '' : tmpDate;
    SIData.AcquiredDate = SIDateChangeFunction(
      (stakeholdersAndDates.AcquiredDate)
    );
    SIData.OpenForSubmissionDate = SIDateChangeFunction(
      (stakeholdersAndDates.OpenForSubmissionDate)
    );
    SIData.ExpectedSubmissionDeadline = SISubmissionDeadlineDateChangeFunction(
      (stakeholdersAndDates.ExpectedSubmissionDeadline)
    );
    SIData.ExpectedAcceptanceDate = SIDateChangeFunction(
      (stakeholdersAndDates.ExpectedAcceptanceDate)
    );
    SIData.ExpectedPublicationDate = SIDateChangeFunction(
      (stakeholdersAndDates.ExpectedPublicationDate)
    );
    SIData.actualPublicationDate = SIDateChangeFunction(
      (publicationDetails.actualPublicationDate)
    );
    SIData.reactiveReason = '';
    SIData.shelvedReason = '';
    // 全部输入为空格的话 修正为null
    SIData.remarks = remarks && remarks.trim().length ? remarks : null;
    SIData.volume = publicationDetails.volume;
    SIData.issue = publicationDetails.issue;
    SIData.issuePublicationLink = publicationDetails.issuePublicationLink;
    switch (stage) {
      case 'Acquired':
        await dispatch(updateToAcquired(siCode,SIData));
        break;
      case 'Paper Commission':
        await dispatch(updateToPaperCommission(siCode,SIData));
        break;
      case 'Submission and Review':
        await dispatch(updateToSubmissionAndReview(siCode,SIData));
        break;
      case 'Production':
        await dispatch(updateToProduction(siCode,SIData));
        break;
      case 'Published':
        SIData = {
          ...SIData,
          siRevenueModel: 'OA',
        };
        await dispatch(updateToPublished(siCode,SIData));
        break;
      case 'Shelved':
        SIData = {
          ...SIData,
          siRevenueModel: 'OA',
        };
        SIData.closedSubmit = 'Yes';
        await dispatch(
          updateToShelved(
            SIData.projectCode,
            SIData.shelvedReason,
            SIData.closedSubmit
          )
        );
        break;
      default:
        break;
    }
  };

  const isVirtualSIJudge = generalInfomation => {
    try {
      if (generalInfomation !== null && generalInformation !== undefined) {
        return generalInformation.specialIssueTypeToBack === 'Virtual Issue';
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  const handleNextAndUpdate = async active => {
    switch (active) {
      case 'General Information':
        if (!generalInformationErr) {
          setGeneralInfomationClick(true);
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          setReviewData({
            ...reviewData,
            generalInfomation: {
              'SI Code:': generalInformation.siCodeToBack,
              'Handling CE:': generalInformation.handlingCeFullNameToBack,
              'Group/Title:': generalInformation.groupOrTitleToBack,
              'SI Publication Format:':
                generalInformation.specialIssueTypeToBack,
              // 'SI Revenue Model:': generalInformation.siRevenueModel,
              'SI Revenue Model:': 'OA',
              'Special Issue Title:': trimItemFilds(
                generalInformation.specialIssueTitleToBack
              ),
              'Journal Code:': generalInformation.journalCodeToBack,
              'Journal Name:': generalInformation.journalNameToBack,
              'Subject Group:': generalInformation.subjectGroupToBack,
              'Special Issue Source:': generalInformation.siSourceToBack,
              'Workflow:': generalInformation.workFlowToBack,
              'Paper Commissioning Method:':
                generalInformation.paperCommissioningMethodToBack,
            },
          });
          scrollToTop();
        } else {
          setGeneralInfomationClick(false);
        }
        return;
      case 'Stakeholders & Dates':
        setStakeholdersAndDatesMonitor(!stakeholdersAndDatesMonitor);
        if (!stakeholdersAndDatesErr) {
          setStakeholdersAndDatesClick(true);
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          // 如果当前是“Acquired”阶段，在最后review页面不让显示Open for Submission Date这个字段
          if (stage !== 'Acquired') {
            setReviewData({
              ...reviewData,
              stakeholders: {
                'JPM:': stakeholdersAndDates.JPM,
                'Previous Handling CE:': ArrToStr(
                  handleGEList(state?.generalInformation[0].previousHandlingCe)
                ),
                'Lead GE:': ArrToStr(stakeholdersAndDates.LeadGEValue),
                'Co-GE:': ArrToStr(stakeholdersAndDates.COGEValue),
              },
              dates: {
                'Idea Date:':
                  stakeholdersAndDates.IdeaDate !== null &&
                  stakeholdersAndDates.IdeaDate !== ''
                    ? moment(stakeholdersAndDates.IdeaDate).utc().format('YYYY-MM-DD')
                    : '',
                'Acquired Date:':
                  stakeholdersAndDates.AcquiredDate !== null
                    ? moment(stakeholdersAndDates.AcquiredDate).utc().format(
                        'YYYY-MM-DD'
                      )
                    : '',
                'Open for Submission Date:':
                  stakeholdersAndDates.OpenForSubmissionDate !== null &&
                  stakeholdersAndDates.OpenForSubmissionDate !== ''
                    ? moment(stakeholdersAndDates.OpenForSubmissionDate).utc().format(
                        'YYYY-MM-DD'
                      )
                    : '',
                'Submission Deadline:':
                  stakeholdersAndDates.ExpectedSubmissionDeadline !== null
                    ? new Date(
                      stakeholdersAndDates.ExpectedSubmissionDeadline
                    ).asUTCDateString()
                    : '',
                'Expected Acceptance Date:':
                  stakeholdersAndDates.ExpectedAcceptanceDate !== null
                    ? new Date(
                    stakeholdersAndDates.ExpectedAcceptanceDate
                    ).asUTCDateString()
                    : '',
                'Expected Publication Date:':
                  stakeholdersAndDates.ExpectedPublicationDate !== null
                    ? new Date(
                    stakeholdersAndDates.ExpectedPublicationDate
                    ).asUTCDateString()
                    : '',
                'Deadline Passed:': deadlinePassed ? 'Yes' : 'No',
                'Closed for Submission:': stakeholdersAndDates.closedSubmit,
              },
            });
          } else {
            setReviewData({
              ...reviewData,
              stakeholders: {
                'JPM:': stakeholdersAndDates.JPM,
                'Previous Handling CE:': ArrToStr(
                  handleGEList(state?.generalInformation[0].previousHandlingCe)
                ),
                'Lead GE:': ArrToStr(stakeholdersAndDates.LeadGEValue),
                'Co-GE:': ArrToStr(stakeholdersAndDates.COGEValue),
              },
              dates: {
                'Idea Date:':
                  stakeholdersAndDates.IdeaDate !== null &&
                  stakeholdersAndDates.IdeaDate !== ''
                    ? moment(stakeholdersAndDates.IdeaDate).utc().format('YYYY-MM-DD')
                    : '',
                'Acquired Date:':
                  stakeholdersAndDates.AcquiredDate !== null
                    ? moment(stakeholdersAndDates.AcquiredDate).utc().format(
                        'YYYY-MM-DD'
                      )
                    : '',
                'Submission Deadline:':
                   stakeholdersAndDates.ExpectedSubmissionDeadline !== null
                    ? new Date(
                        stakeholdersAndDates.ExpectedSubmissionDeadline
                      ).asUTCDateString()
                    : '',
                'Expected Acceptance Date:':
                  stakeholdersAndDates.ExpectedAcceptanceDate !== null
                    ? moment(
                        stakeholdersAndDates.ExpectedAcceptanceDate
                      ).utc().format('YYYY-MM-DD')
                    : '',
                'Expected Publication Date:':
                  stakeholdersAndDates.ExpectedPublicationDate !== null
                    ? moment(
                        stakeholdersAndDates.ExpectedPublicationDate
                      ).utc().format('YYYY-MM-DD')
                    : '',
                'Deadline Passed:': deadlinePassed ? 'Yes' : 'No',
                'Closed for Submission:': stakeholdersAndDates.closedSubmit,
              },
            });
          }

          scrollToTop();
        } else {
          setStakeholdersAndDatesClick(false);
        }
        return;
      case 'Submission Overview':
        setSubmssionOverviewMonitor(!submssionOverviewMonitor);
        if (!submssionOverviewErr) {
          setSubmssionOverviewClick(true);
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          setReviewData({
            ...reviewData,
            submissionOverview: {
              'DATE SOURCE': 'EEO',
              recruited:
                submissionOverview.numberOfArticlesRecruited === null
                  ? 0
                  : submissionOverview.numberOfArticlesRecruited,
              submitted:
                submissionOverview.numberOfArticlesSubmitted === null
                  ? 0
                  : submissionOverview.numberOfArticlesSubmitted,
              accepted:
                submissionOverview.numberOfArticlesAccepted === null
                  ? 0
                  : submissionOverview.numberOfArticlesAccepted,
              rejected:
                submissionOverview.numberOfArticlesRejected === null
                  ? 0
                  : submissionOverview.numberOfArticlesRejected,
            },
          });
          scrollToTop();
        } else {
          setSubmssionOverviewClick(false);
        }
        return;
      case 'Publication':
        setPublicationDetailsMonitor(!publicationDetailsMonitor);
        if (!publicationDetailsErr) {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          setReviewData({
            ...reviewData,
            publicationDetails: {
              volume: isVirtualSIJudge(generalInformation)
                ? ''
                : publicationDetails.volume,
              issue: isVirtualSIJudge(generalInformation)
                ? ''
                : publicationDetails.issue,
              actualPublicationDate: moment(
                publicationDetails.actualPublicationDate
              ).format('YYYY-MM-DD'),
              oaarticle: publicationDetails.oaarticle,
              ooarticle: publicationDetails.ooarticle,
              numberOfWaiversUsed: publicationDetails.numberOfWaiversUsed,
              issuePublicationLink: publicationDetails.issuePublicationLink,
            },
          });
          scrollToTop();
        }
        return;
      default:
        await handleUpdate();
    }
  };

  const handleBack = () => {
    if (activeStep !== 0) {
      //stakeholdersAndDatesMonitor
      setStakeholdersAndDatesClick(true);
      setSubmssionOverviewClick(true);
      setActiveStep(prevActiveStep => prevActiveStep - 1);

      setPublicationDetailsErr(false);
      setSubmssionOverviewErr(false);
      setStakeholdersAndDatesErr(false);

      scrollToTop();
    } else {
      window.history.go(-1);
    }
  };

  const getContainer = active => {
    switch (active) {
      case 'General Information':
        return (
          <GeneralInformation
            data-selenium-id='SIPage_CreateAndUpdateSI_UpdateMainPage-GeneralInformation'
            clickedButton={generalInformationClick}
            allDateToBack={generalInformation}
            setAllDateToBack={setGeneralInfomation}
            StakeholdersAndDatesdata={stakeholdersAndDates}
            setStakeholdersAndDatesdata={setStakeholdersAndDates}
            setHasError={setGeneralInfomationErr}
            isOA={isOA}
            clickTime={clickTime}
            setIsOA={setIsOA}
          />
        );
      case 'Stakeholders & Dates':
        return (
          <StakeholdersAndDates
            data-selenium-id='SIPage_CreateAndUpdateSI_UpdateMainPage-StakeholdersAndDates'
            siCode={siCode}
            detailInfo={state}
            clickedButton={stakeholdersAndDatesClick}
            StakeholdersAndDatesdata={stakeholdersAndDates}
            setStakeholdersAndDatesdata={setStakeholdersAndDates}
            setHasError={setStakeholdersAndDatesErr}
            everyClick={stakeholdersAndDatesMonitor}
            setDeadlinePassed={setDeadlinePassed}
            MandatoryList={MandatoryList}
            clickTime={clickTime}
          />
        );
      case 'Submission Overview':
        return (
          <SubmissionOverviewPage
            data-selenium-id='SIPage_CreateAndUpdateSI_UpdateMainPage-SubmissionOverviewPage'
            value={submissionOverview}
            setValue={setSubmssionOverview}
            step3NextMonitor={submssionOverviewMonitor}
            clickedButton={!submssionOverviewClick}
            setErr={setSubmssionOverviewErr}
            clickTime={clickTime}
          />
        );
      case 'Publication':
        return (
          <PublicationDeatilsPage
            data-selenium-id='SIPage_CreateAndUpdateSI_UpdateMainPage-PublicationDeatilsPage'
            isNotVirtual={!isVirtualSIJudge(generalInformation)}
            value={publicationDetails}
            setValue={setPublicationDetails}
            acceptArticle={submissionOverview.numberOfArticlesAccepted}
            NextMonitor={publicationDetailsMonitor}
            setErr={setPublicationDetailsErr}
            isOA={isOA}
            clickTime={clickTime}
          />
        );
      default:
        return (
          <>
            <ReviewPage
              data-selenium-id='SIPage_CreateAndUpdateSI_UpdateMainPage-ReviewPage'
              reviewData={reviewData}
              setValue={setRemarks}
              value={remarks}
              isOA={isOA}
            />
          </>
        );
    }
  };

  React.useEffect(() => {
    if (state?.publicationDetails.value !== undefined) {
      setPublicationDetails(state.publicationDetails);
    }
  }, [state]);

  React.useEffect(() => {
    isBottom
      ? setCreateAndUpdateFooterBottom('75px')
      : setCreateAndUpdateFooterBottom('0px');
  }, [isBottom]);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (UpdateSIResult !== '') {
      if (UpdateSIResult !== 'error') {
        // 当updateSIResult更新时，说明update接口已经返回，意味着更新工作已经完成，无需限制两秒跳转
        navigate(`/simt/auth/siRelated/si/detail/${siCode}`);
      }
    }
    if (UpdateSIResult !== '') {
      if (UpdateSIResult === 'OK') {
        navigate(`/simt/auth/siRelated/si/detail/${siCode}`);
        dispatch(setTextParam(generalInformation.specialIssueTitle));
        dispatch(setEditType('updateSI'));
        dispatch(openConfirm());
      }
    }
  }, [UpdateSIResult]);

  React.useEffect(() => {
    if (updateSIMessageResult !== null) {
      if (
        updateSIMessageResult ===
        "This paper commission method is not allowed because it's ready for promotion"
      ) {
        dispatch(setTextParam(updateSIMessageResult));
      } else {
        dispatch(setTextParam());
      }
      dispatch(setEditType('error'));
      dispatch(
        setSnackbarMessageAndOpen(
          'siPage.updateErrorDueToCommissionError',
          {},
          SEVERITIES.warning
        )
      );
      // dispatch(openConfirm());
      dispatch({ type: 'UPDATE_SI_MESSAGE', data: null });
    }
  }, [updateSIMessageResult]);

  // 用于触发每次button 都重新定位到有问题的位置
  const [clickTime, setClickTime] = React.useState(0);

  const stepper = (
    <CreateAndUpdateHeader
      id={`updateSI_${url[url.length - 1]}_mainPage_header`}
      activeStep={activeStep}
      steps={steps}
    />
  );

  const actionBar = (
    <CreateAndUpdateFooter
      id={`updateSI_${url[url.length - 1]}_mainPage_actionBar`}
      activeStep={activeStep}
      lastStep={steps.length - 1}
      handleBack={handleBack}
      Err={
        !generalInformationClick && generalInformationErr||
        !stakeholdersAndDatesClick && stakeholdersAndDatesErr||
        !submssionOverviewClick && submssionOverviewErr||
        publicationDetailsErr
      }
      handleNextOrCreate={e => {
        // setGeneralInfomationErr(false);
        // setStakeholdersAndDatesErr(false);
        // setSubmssionOverviewErr(false);
        // setPublicationDetailsErr(false);
        handleNextAndUpdate(steps[activeStep]);
        setClickTime(clickTime + 1);
      }}
    />
  );

  return (
    <MainPageFramework
      id={`updateSI_${url[url.length - 1]}_mainPage`}
      actionBar={actionBar}
      stepper={stepper}
    >
      <CreateAndUpdateMain
        id={`updateSI_${url[url.length - 1]}_mainPage_content`}
        container={getContainer(steps[activeStep])}
      />
      <CommonSnackBar data-selenium-id='SIPage_CreateAndUpdateSI_UpdateMainPage-CommonSnackBar' />
    </MainPageFramework>
  );
}

export default UpdateMainPage;

import styles from './index.module.scss';
import {Input, InputAdornment, OutlinedInput, TextField} from "@mui/material";
import DropdownClearIcon from '../../assets/dropdown-clear-16_16.svg';
import {ChangeEvent} from "react";

export interface InputBoxProps {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    error?: boolean;
    disabled?: boolean;
    size?: 'small' | 'default';
}

export interface MultiLineInputBoxProps extends InputBoxProps {
    maxChars?: number;
}

export function InputBox(props: InputBoxProps) {
    const {
        value,
        onChange,
        placeholder,
        error,
        disabled,
        size = 'default'
    } = props;

    return (
        <OutlinedInput
            className={styles['input-box'] + ' ' + (size === 'small' ? styles['small'] : '') + ' ' + (error ? styles['error'] : '')}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            disabled={disabled}
            endAdornment={
                (value.length > 0) &&
                <InputAdornment position="end">
                    <DropdownClearIcon className={styles['clear-icon']} onClick={_ => onChange('')} />
                </InputAdornment>
            }
            fullWidth={true}
        />
    );
}

export function MultiLineInputBox(props: MultiLineInputBoxProps) {
    const {
        value,
        onChange,
        placeholder,
        error,
        disabled,
        maxChars,
    } = props;

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement|HTMLInputElement>) => {
      if (maxChars && e.target.value.length > maxChars) {
          onChange(e.target.value.slice(0, maxChars));
      } else {
          onChange(e.target.value);
      }
    };

    return (
        <TextField
            className={styles['input-box'] + ' ' + styles['multiline'] + ' ' + (error ? styles['error'] : '')}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            disabled={disabled}
            multiline
            minRows={5}
            fullWidth={true}
        />
    );
}
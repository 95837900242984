import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import EmailContainer from '@/components/EmailModal/EmailContainer';
import { axios_instance } from '@/utils/axios_instance';
import { useParams } from 'react-router-dom';
import { forEmail } from '@/modules/RichTextEditor/EditorUtils';
import { extractAttachmentId } from '@/utils/commonUtils';

export default function Email({
  value,
  properties,
  form = {},
  getPreSubmitForm = () => form,
  update,
  onCommand,
}) {
  const { sipVersion } = useSelector(state => {
    return {
      sipVersion: state.SIP.sipStatus?.sipVersion,
    };
  });
  const { sipCode } = useParams();

  const [loading, setLoading] = useState(true);
  const [emailBody, setEmailBody] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [emailSend, setEmailSend] = useState({});

  const { url, params, body, showBcc, showCc, showFrom } = properties;

  useEffect(() => {
    const emailUrl = url?.replace('${sipCode}', sipCode);
    const submitBody = {
      ...body,
      sipCode,
      sipVersion,
      ...getPreSubmitForm(),
    };

    axios_instance
      .post(emailUrl, submitBody, { params })
      .then(res => {
        if (res.data.code !== 200) {
          if (res.data.message) {
            onCommand('alert', {
              type: 'warning',
              title: res.data.message,
              buttons: [{ text: 'OK', command: 'closeAlert' }],
            });
          }
          return;
        }
        const { title, content, from, copy, to, bcc } = res.data.data;
        setEmailBody(content);
        setEmailSend({
          title,
          content: forEmail(content),
          from,
          copy,
          to,
          bcc,
          mailAttachments: [],
          attachment: [],
          requireAttachment: false,
        });
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    update({
      ...emailSend,
      content: forEmail(emailBody),
      attachment: extractAttachmentId(attachments),
    });
  }, [emailSend, emailBody, attachments]);

  return (
    <EmailContainer
      data-selenium-id='SIP_DetailPage_DecisionPanel-Email'
      emailLoading={loading}
      emailTemplate={emailBody}
      setEmailTemplate={setEmailBody}
      attachments={attachments}
      setAttachments={setAttachments}
      subject={emailSend.title || 'N/A'}
      from={emailSend.from || 'N/A'}
      to={emailSend.to || []}
      cc={emailSend.copy || []}
      bcc={emailSend.bcc || []}
      isShowBcc={showBcc}
      isShowCc={showCc}
      isShowFrom={showFrom}

      // CT-7517
      setSubject={(subject) => setEmailSend({ ...emailSend, title: subject })}
      isSubjectEditable={properties.isSubjectEditable}
      setTo={(to) => setEmailSend({ ...emailSend, to: to })}
      isToEditable={properties.isToEditable}
      setCc={(cc) => setEmailSend({ ...emailSend, copy: cc })}
      isCcEditable={properties.isCcEditable}
    />
  );
}

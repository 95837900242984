import { PropTypes } from 'prop-types';
import styles from './index.module.scss';

const ChartCard = ({ chartImg }) => {
  return (
    <div className={styles['card']}>
      <img className={styles['image']} src={chartImg} />
    </div>
  );
};

ChartCard.propTypes = {
  chartImg: PropTypes.string.isRequired,
};

export default ChartCard;

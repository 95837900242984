import { Box, IconButton, Stack } from '@mui/material';
import {
  MiniBoldFont,
  RegularBoldFont,
} from '../../../../components/FontStyle';
import DecisionAssigneeAddIcon from '@/assets/DecisionAssigneeAddIcon.svg';
import AssigneeAddPopUp from './AssigneeAddPopUp';
import { AssigneeLitterBox } from './AssigneeLitterBox';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbarMessageAndOpen } from '../../../../actions/SnackbarAction';
import { SEVERITIES } from '../../../../modules/ConfirmSnackBar/CommonSnackBar';
import AddIconButton from '@/componentsphase2/Button/AddIconButton';

export default function Assignee(props) {
  const { assignees = [], setAssignees = () => { }, label = 'Assignee' } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [assignee, setAssignee] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [number, setNumber] = useState(-1); //用来区别add还是edit

  const handleSave = () => {
    let newAssignees = assignees.concat();
    if (number === -1) {
      newAssignees.push(assignee);
    } else {
      newAssignees.splice(number, 1, assignee);
    }
    setAssignees(newAssignees);
    setOpen(false);
  };

  return (
    <Box sx={{
      mb: (assignees && assignees.length && assignees.length === 0) ? "0px" : "8px"
    }} data-selenium-id='SIP_DetailPage_DecisionSection-Assignee-Box'>
      <Stack
        direction='row'
        spacing={1}
        data-selenium-id='SIP_DetailPage_DecisionSection-Assignee-Stack'
      >
        <Stack
          direction='row'
          spacing={0}
          data-selenium-id='SIP_DetailPage_DecisionSection-Assignee-Stack'
        >
          <Box
            sx={{ ...RegularBoldFont, color: '#262E35' }}
            data-selenium-id='SIP_DetailPage_DecisionSection-Assignee-ShowBox1'
          >
            {label}
          </Box>
          <Box
            sx={{ ...MiniBoldFont, color: '#C40000' }}
            data-selenium-id='SIP_DetailPage_DecisionSection-Assignee-ShowBox2'
          >
            {label ? '*' : ''}
          </Box>
        </Stack>
        {/* <IconButton

        >
          <DecisionAssigneeAddIcon data-selenium-id='SIP_DetailPage_DecisionSection-Assignee-AddIcon'></DecisionAssigneeAddIcon>
        </IconButton> */}
        <AddIconButton
          data-selenium-id='SIP_DetailPage_DecisionSection-Assignee-IconButton'
          id='SIP_DetailPage_DecisionSection-Assignee-IconButton'
          // disabled={assignees.length >= 10}
          sx={{ padding: '0px' }}
          onClick={() => {
            if (assignees.length >= 10) {
              dispatch(
                setSnackbarMessageAndOpen(
                  'sipDetail.assigneeExceedRemind',
                  {},
                  SEVERITIES.warning
                )
              );
            } else {
              setOpen(true);
              setAssignee({
                firstName: '',
                lastName: '',
                email: '',
              });
              setNumber(-1);
            }
          }}
        />
      </Stack>
      {(assignees && assignees.length && assignees.length < 6)
        ?
        <Box
          sx={{ marginTop: '16px' }}
          data-selenium-id='SIP_DetailPage_DecisionSection-Assignee-InnerBox'
        >
          {assignees.map((item, index) => (
            <AssigneeLitterBox
              assignee={item}
              key={`assignee-${index}`}
              id={`assignee-${index}`}
              data-selenium-id={`SIP_DetailPage_DecisionSection-Assignee-${index}`}
              handleEdit={() => {
                setNumber(index);
                setAssignee(item);
                setOpen(true);
              }}
              handleDelete={() => {
                let newAssignees = assignees.concat();
                newAssignees.splice(index, 1);
                setAssignees(newAssignees);
              }}
            />
          ))}
        </Box>
        :
        <Stack spacing={0} sx={{ mt: "16px" }}>
          <Box
            data-selenium-id='SIP_DetailPage_DecisionSection-Assignee-InnerBox-Left'
          >
            {assignees.filter((item, index) => index < 5).map((item, index) => (
              <AssigneeLitterBox
                assignee={item}
                key={`assignee-${index}`}
                id={`assignee-${index}`}
                data-selenium-id={`SIP_DetailPage_DecisionSection-Assignee-${index}`}
                handleEdit={() => {
                  setNumber(index);
                  setAssignee(item);
                  setOpen(true);
                }}
                handleDelete={() => {
                  let newAssignees = assignees.concat();
                  newAssignees.splice(index, 1);
                  setAssignees(newAssignees);
                }}
              />
            ))}
          </Box>
          <Box
            data-selenium-id='SIP_DetailPage_DecisionSection-Assignee-InnerBox-Right'
          >
            {assignees.filter((item, index) => index >= 5).map((item, index) => (
              <AssigneeLitterBox
                assignee={item}
                key={`assignee-${index}`}
                id={`assignee-${index}`}
                data-selenium-id={`SIP_DetailPage_DecisionSection-Assignee-${index}`}
                handleEdit={() => {
                  setNumber(index);
                  setAssignee(item);
                  setOpen(true);
                }}
                handleDelete={() => {
                  let newAssignees = assignees.concat();
                  newAssignees.splice(index + 5, 1);
                  setAssignees(newAssignees);
                }}
              />
            ))}
          </Box>
        </Stack>
      }

      <AssigneeAddPopUp
        data-selenium-id='SIP_DetailPage_DecisionSection-Assignee-AddPopUp'
        open={open}
        assignee={assignee}
        handleClose={() => {
          setOpen(false);
        }}
        handleSave={handleSave}
        setAssignee={setAssignee}
        assignees={assignees}
        index={number}
      ></AssigneeAddPopUp>
    </Box>
  );
}

import styles from './index.module.scss';
import {Box, Button, Tooltip, Typography} from "@mui/material";
import ErrorIcon from '../../assets/error-message-icon.svg';
import HelpIcon from '../../assets/help-icon.svg';
import InfoIcon from '../../assets/form-group-info-18_18.svg';

export interface FormGroupProps {
    title?: string;
    isRequired?: boolean;
    error?: boolean;
    errorMessage?: string;
    children: React.ReactNode;
    tooltip?: string;
    textAreaMaxLength?: number;
    textAreaCurrentLength?: number;
    withoutTitle?: boolean;
}

export interface FormGroupInfoProps {
    info: string;
}

export interface FormGroupButtonProps {
    title: string;
    onClick: () => void;
    disabled?: boolean;
}

export function FormGroup(props: FormGroupProps) {
    return (
        <Box className={styles['form-group']}>
            {!props.withoutTitle && (
                <Box className={styles['form-group-header']}>
                    <Typography className={styles['form-group-title']}>{props.title}</Typography>
                    {props.isRequired && <Typography className={styles['form-group-required']}>&nbsp;*</Typography>}
                    {props.tooltip &&
                    <>
                        &nbsp;
                        <Tooltip
                            title={props.tooltip}
                            placement="bottom-start"
                            arrow={true}
                            classes={{
                                tooltip: styles['tooltip'],
                                arrow: styles['tooltip-arrow']
                            }}
                        >
                            <HelpIcon className={styles['help-icon']} />
                        </Tooltip>
                    </>}
                </Box>
            ) }

            <Box className={styles['form-group-content']}>
                {props.children}
            </Box>
            {(props.error || props.textAreaMaxLength) &&
                <Box className={styles['form-group-error']}>
                    {props.error &&
                        <>
                        <ErrorIcon className={styles['error-icon']}/>
                        <Typography>{props.errorMessage}</Typography>
                        </>
                    }
                    {props.textAreaMaxLength &&
                        <Typography className={styles['text-area-max-length']}>
                            {props.textAreaCurrentLength ?? 0}/{props.textAreaMaxLength}                        </Typography>
                    }
                </Box>
            }
        </Box>
    );
}

export function FormGroupInfo(props: FormGroupInfoProps) {
    return (
        <Box className={styles['form-group-info']}>
            <InfoIcon className={styles['info-icon']} />
            <Typography>{props.info}</Typography>
        </Box>
    );
}

export function FormGroupButton(props: FormGroupButtonProps) {
    return (
        <Box className={styles['form-group-button'] + ' ' + (props.disabled ? styles['disabled']: '')}>
            <Button onClick={props.onClick} disabled={props.disabled}>{props.title}</Button>
        </Box>
    );
}

export default FormGroup;
import { axios_instance } from '../../../utils/axios_instance';
import { setSnackbarMessageAndOpen } from '../../SnackbarAction';
import { SEVERITIES } from '../../../modules/ConfirmSnackBar/CommonSnackBar';
import { isTimeoutError } from '../../../utils/commonUtils';
import { ConfigAction } from '.';

// general settings
export const GET_SYSTEM_CONFIG = 'GET_SYSTEM_CONFIG';
export const UPDATE_SYSTEM_CONFIG = 'UPDATE_SYSTEM_CONFIG';
export const SYSTEM_CONFIG_CLEAR = 'SYSTEM_CONFIG_CLEAR';

// dropdown values
// 设置当前选择框的类型
export const SET_SELECT_DROPDOWN_TYPE = 'SET_SELECT_DROPDOWN_TYPE';
// 设置add new的输入内容
export const SET_ADD_NEW_ITEM_INPUT = 'SET_ADD_NEW_ITEM_INPUT';
// 加载dropdown的某一项的所有内容
export const LOAD_DROPDOWN_VALUES_DATA_WITH_TYPE =
  'LOAD_DROPDOWN_VALUES_DATA_WITH_TYPE';
// 设置展示出来的内容
export const SET_DROPDOWN_DISPLAY = 'SET_DROPDOWN_DISPLAY';
// 保存顺序修改成功
export const SAVE_DROPDOWN_ORDER_SUCCESS = 'SAVE_DROPDOWN_ORDER';
// 保存顺序修改失败
export const SAVE_DROPDOWN_ORDER_FAIL = 'SAVE_DROPDOWN_ORDER_FAIL';
// 重置顺序
export const RESET_DROPDOWN_ORDER = 'RESET_DROPDOWN_ORDER';
// 添加新的item并发送请求
export const ADD_NEW_DROPDOWN_ITEM = 'ADD_NEW_DROPDOWN_ITEM';
// 修改item并发送请求
export const EDIT_DROPDOWN_ITEM = 'EDIT_DROPDOWN_ITEM';
// 删除item并发送请求
export const DELETE_DROPDOWN_ITEM = 'DELETE_DROPDOWN_ITEM';

export const GET_GROUPS = 'GET_GROUPS';
export const GET_USER_STATUS = 'GET_USER_STATUS';
export const GET_FLAGS = 'GET_FLAGS';
export const GET_SITYPE = 'GET_SITYPE';
export const GET_PAPERCONFIG = 'GET_PAPERCONFIG';
export const GET_NOTE_LINK_CONFIG = 'GET_NOTE_LINK_CONFIG';

export const CHECK_CONFIG = 'CHECK_CONFIG';
export const GET_FOOTERLINK_CONFIG = 'GET_FOOTERLINK_CONFIG';
export const GET_SUPEREMAIL = 'GET_SUPEREMAIL';
export const CONFIG_GET_VALUE = 'CONFIG_GET_VALUE';
export const CHECK_ACCOUNT_STATUE = 'CHECK_ACCOUNT_STATUE';

/**
 * 设置下拉框的选项
 * @param newType 新的选项，具体使用int还是string取决于是用value还是id，
 *                本方法只负责修改，不负责检查
 * @returns {(function(*): void)|*}
 */
export function setSelectDropdownType(newType) {
  return dispatch => {
    dispatch({ type: SET_SELECT_DROPDOWN_TYPE, data: newType });
  };
}

export function setNewItemInput(input) {
  return dispatch => {
    dispatch({ type: SET_ADD_NEW_ITEM_INPUT, data: input });
  };
}

/**
 * 通过typeObj加载某一项数据
 * @param typeObj
 * @returns {(function(*): Promise<void>)|*}
 */
export function loadDropdown(typeObj) {
  return async dispatch => {
    try {
      let res = await axios_instance.get(typeObj.api, {
        params: {
          type: typeObj.type,
        },
        headers: {
          authorization: localStorage.getItem('authorization'),
        },
      });
      if (res.data.code === 200) {
        dispatch({
          type: LOAD_DROPDOWN_VALUES_DATA_WITH_TYPE,
          data: res.data.data,
        });
      } else {
        dispatch({ type: LOAD_DROPDOWN_VALUES_DATA_WITH_TYPE, data: [] });
        dispatch(
          setSnackbarMessageAndOpen(
            'common.failToLoad',
            {
              value: `sysConfig.${typeObj.id}`,
            },
            SEVERITIES.error
          )
        );
      }
    } catch (err) {
      dispatch({ type: LOAD_DROPDOWN_VALUES_DATA_WITH_TYPE, data: [] });
      let reasonId;
      if (isTimeoutError(err)) {
        reasonId = 'common.errorTimeout';
      } else {
        reasonId = `common.error${err.status}`;
      }
      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToLoadWithReason',
          {
            value: `sysConfig.${typeObj.id}`,
            reason: reasonId,
          },
          SEVERITIES.error
        )
      );
    }
  };
}

/**
 * 直接设置列表的数据而不通过后端
 * @param newList
 * @returns {(function(*): void)|*}
 */
export function setDropdownDisplay(newList) {
  return dispatch => {
    dispatch({ type: SET_DROPDOWN_DISPLAY, data: newList });
  };
}

/**
 * 辅助函数。用于在add和edit后端返回500时，判断是名称重复，还是其他错误
 * 当是重复名称的时候，返回true；如果不是，以返回false结束。
 * @param message
 * @return 如果是重复，则返回true，否则返回false
 */
export function isDuplicationError(message) {
  return message.includes('already exist');
}

// 以下是general setting的action
export function resetOrder() {
  return dispatch => {
    dispatch({ type: RESET_DROPDOWN_ORDER, data: null });
  };
}

export const clear = () => ({
  type: SYSTEM_CONFIG_CLEAR,
});

export const CheckConfig = (type, values) => {
  return async dispatch => {
    try {
      let res = await axios_instance.get(
        `/api/v1/user-service/configs`,
        {
          params: {
            type: type,
            values: values,
          },
          headers: {
            authorization: localStorage.getItem('authorization'),
            fullName: 'Tao, HUang',
          },
        }
      );
      if (res.data.code === 200) {
        dispatch({ type: CHECK_CONFIG, data: res.data.data===[]?false:true });
      } else {
        dispatch({ type: CHECK_CONFIG, data: false });
      }
    } catch (err) {
      dispatch({ type: CHECK_CONFIG, data: false });
    }
  };
};

export const GetFooterLinkConfig = props => {
  const { key} = props;
  let typeObj = {
    key: key,
    type: 'footerLink',
    fuzzy_type: false,
  };
  return dispatch => {
    dispatch(ConfigAction(typeObj, GET_FOOTERLINK_CONFIG));
  };
};

export const GetSuperEmail = props => {
  let typeObj = {
    key: 'SUPER_EMAIL',
    fuzzy_type: false,
  };
  return dispatch => {
    dispatch(ConfigAction(typeObj, GET_SUPEREMAIL));
  };
};

export const GetConfigValue  = (type, values) => {
  return async dispatch => {
    try {
      let res = await axios_instance.get(
        `/api/v1/user-service/configs`,
        {
          params: {
            type: type,
            values: values,
          },
          headers: {
            authorization: localStorage.getItem('authorization'),
            fullName: 'Tao, HUang',
          },
        }
      );
      if (res.data.code === 200) {
        dispatch({ type: CONFIG_GET_VALUE, data: res.data.data});
      } else {
        dispatch({ type: CONFIG_GET_VALUE, data: false });
      }
    } catch (err) {
      dispatch({ type: CONFIG_GET_VALUE, data: false });
    }
  };
};

import React, {useEffect, useMemo, useState} from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { StyledTableCell, TableFooter2 } from '@/components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import {isReportOverCutOffDate} from "@/utils/commonUtils";
import { visuallyHidden } from '@mui/utils';

import {
  StyledTableCellPurple,
  StyledTableCellDisPurple,
  StyledTableCellGreen,
  StyledTableCellDisGreen,
  StyledTableRow, TableBox, ReportNoData, TableBox2
} from "../../ReportSpecificComponents/SpecialColorTableHeader";

import { findLongestLineSubstring, measureWidth } from "../../../../utils/StringWidthMeasure";
import { TextLabel } from "../../../../componentsphase2/CustomTooltip";
import { useShowReportRolesByFyHook } from '@/pages/Report/utils/useShowReportRolesByFyHook';
import {
  getReportArticleOAWaiverUseBasicTableData,
  getReportArticleOverviewBasicTableData
} from "@/actions/SIMT-Report/Report/ReportArticle";

export default function ArticleOverviewBasicTable(props) {
  const { setIfNoResult } = props;
  const [showJPM, showNewJournalRoles] = useShowReportRolesByFyHook();
  const [loading, setLoading] = useState(false);

  const createRow = (
    id,
    label,
    minWidth,
    maxWidth,
    isOrder = false,
    align = 'left',
    show = true
  ) => {
    return { id, label, minWidth, maxWidth, isOrder, align, show };
  };


  const itemsBeforeJPM = useMemo(() => {
    return [
      createRow(
          'journalName',
          'JOURNAL INFORMATION',
          '260px',
          '260px',
          false,
          'left'
      ),
      createRow(
          'journalStatus',
          'JOURNAL STATUS',
          '165px',
          '165px',
          false,
          'left'
      ),
    ];
  },[]);


  const itemsAfterJPM = useMemo(() => {
    return [
      createRow('subjectGroup', 'SUBJECT GROUP', '187px', '187px', false, 'left'),
      createRow(
          'businessDivision',
          'BUSINESS DIVISION',
          '262px',
          '262px',
          false,
          'left'
      ),
      createRow('catGroup', 'CAT GROUP', '130px', '130px', false, 'left'),
      createRow('handlingCe', 'HANDLING CE', '161px', '161px', false, 'left'),
    ];
  },[]);


  const JPMItem = useMemo(() => {
    return createRow('jpm', 'JPM', '161px', '161px', false, 'left');
  }, []);


  const pdpItem = useMemo(() => {
    return createRow(
        'pdPublisher',
        'PD Publisher',
        '105px',
        '180px',
        false,
        'left'
    );
  }, []);

  const pppItem = useMemo(() => {
    return createRow(
        'ppPublisher',
        'PP Publisher',
        '105px',
        '180px',
        false,
        'left'
    );
  }, []);

  const Subitems = [
    {
      id: 'Sub-MMM',
      label: 'Published SI',
      maxWidth: '112px',
      minWidth: '112px',
      post: 'submittedArt',
      isOrder: false,
    },
    {
      id: 'Sub-YTD',
      label: 'Waiver Used SI',
      maxWidth: '112px',
      minWidth: '112px',
      post: 'submittedArtYtd',
      isOrder: false,
    },
    {
      id: '%Against YTD Target',
      label: 'Published OA Articles',
      maxWidth: '112px',
      minWidth: '112px',
      isOrder: false,
      post: 'submittedArtYtdTarget',
    },
    {
      id: '%Against YTD Target1',
      label: 'Used OA Waiver',
      maxWidth: '112px',
      minWidth: '112px',
      isOrder: false,
      post: 'submittedArtYtdTarget',
    },
  ];
  const Accitems = [
    {
      id: 'Acc-MMM',
      label: 'Published SI',
      maxWidth: '112px',
      minWidth: '112px',
      isOrder: false,
      post: 'acceptedArt',
    },
    {
      id: 'Acc-YTD',
      label: 'Waiver Used SI',
      maxWidth: '112px',
      minWidth: '112px',
      isOrder: false,
      post: 'acceptedArtYtd',
    },
    {
      id: '%Against YTD Target',
      label: 'Published OA Articles',
      maxWidth: '112px',
      minWidth: '112px',
      isOrder: false,
      post: 'acceptedArtYtdTarget',
    },
    {
      id: '%Against YTD Target1',
      label: 'Used OA Waiver',
      maxWidth: '112px',
      minWidth: '112px',
      isOrder: false,
      post: 'submittedArtYtdTarget',
    },
  ];
  {/*const Pubitems = [
    {
      id: 'Pub-MMM',
      label: 'Pub - ',
      maxWidth: '150px',
      minWidth: '100px',
      isOrder: false,
      post: 'publishedArt',
    },
    {
      id: 'Pub-YTD',
      label: 'Pub - YTD',
      maxWidth: '150px',
      minWidth: '100px',
      isOrder: false,
      post: 'publishedArtYtd',
    },
    {
      id: '%Against YTD Target',
      label: '% Against YTD Target',
      maxWidth: '150px',
      minWidth: '100px',
      isOrder: false,
      post: 'publishedArtYtdTarget',
    },
  ];*/}

  const [page, setPage] = useState(1);
  const [perPageNumber, setPerPageNumber] = useState(20);

  const [orderType, setOrderType] = useState('journalName'); //排序的表头
  const [orderAs, setOrderAs] = useState('asc'); //排序的顺序
  const [flashLock, setFlashLock] = useState(true);

  const dispatch = useDispatch();
  const {
    articleOAWaiverUseBasicTableData,
    tableMonth,
    selectedArticleDate,
    articleOAWaiverUseFilters,
    cutOffDate,
  } = useSelector(state => {
    return {
      // filter display
      articleOAWaiverUseBasicTableData: state.Report.articleOAWaiverUseBasicTableData,
      tableMonth: state.Report.articleOAWaiverUseBasicTableMonth,
      // selectedArticleDate: state.Report.selectedArticleDate,
      selectedArticleDate: state.Report.selectDate,
      //articleOverviewFilters: state.ReportFilter.articleOverviewFilters,
      articleOAWaiverUseFilters: state.ReportFilter.articleOAWaiverUseFilters,
      cutOffDate: state.Report.cutOffDate,
    };
  });

  const items = useMemo(() => {
    let flexItem = [];
    if (showJPM) {
      flexItem = [...flexItem, JPMItem];
    }
    if (showNewJournalRoles) {
      flexItem = [...flexItem, pdpItem, pppItem];
    }
    return [...itemsBeforeJPM, ...flexItem, ...itemsAfterJPM];
  }, [showJPM, showNewJournalRoles]);

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    for (let item of items){
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '18px Open Sans');
    }

    // const CUR_LEN = measureWidth(findLongestLineSubstring('Published SI'), '18px Open Sans');
    // const YTD_LEN = measureWidth(findLongestLineSubstring('Waiver Used SI'), '18px Open Sans');
    // const OAP_LEN = measureWidth(findLongestLineSubstring('Published OA Articles'), '18px Open Sans');
    // const OAP_LEN2 = measureWidth(findLongestLineSubstring('Used OA Waiver'), '18px Open Sans');

    for (let type of ['sub','acc']) {
      widthMap[`${type}-0`] = 63;
      widthMap[`${type}-1`] = 63;
      widthMap[`${type}-2`] = 63;
      widthMap[`${type}-3`] = 63;
    }

    setColumnMinWidthMap(widthMap);
  }, [items]);

  useEffect(() => {
    if (articleOAWaiverUseBasicTableData && articleOAWaiverUseBasicTableData.count > 0){
      const maxMap = {};
      for (let row of articleOAWaiverUseBasicTableData['result']) {
        let {
          journalCode,
          journalName,
          revenueModel,
          journalStatus,
          jpm,
          businessDivision,
          abbreviation, // use abbreviation to represent subject group
          catGroup,
          handlingCe,
        } = row;
        let dataObj = {
          journalCode,
          journalName,
          revenueModel,
          journalStatus,
          jpm,
          businessDivision,
          catGroup,
          handlingCe,
          abbreviation,

          'sub-0':  row.publishedSiInMonth ?? '0',
          'sub-1':  row.waiverUsedSiInMonth ?? '0',
          'sub-2':  row.publishedOaArticlesInMonth ?? '0',
          'sub-3':  row.usedOaWaiverInMonth ?? '0',

          'acc-0':  row.publishedSiInYear ?? '0',
          'acc-1':  row.waiverUsedSiInYear ?? '0',
          'acc-2':  row.publishedOaArticlesInYear ?? '0',
          'acc-3':  row.usedOaWaiverInYear ?? '0',
        };
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      widthMap['journalName'] = Math.max(widthMap['journalCode'],widthMap['journalName']);
      // console.info(widthMap);
      setColumnWidthMap(widthMap);
    }
  }, [articleOAWaiverUseBasicTableData]);

  function calcGroupWidth(type){
    let sum = 0;
    for (let i of [0,1,2,3]) {
      sum += Math.max((columnWidthMap[`${type}-${i}`]??0), columnMinWidthMap[`${type}-${i}`]) + 50;
    }
    return sum;
  }

  const handlePerPageChange = e => {
    setPerPageNumber(e.target.value);
  };

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  const handleChangeOrder = orderBy => {
    let newOrder;
    if (orderType === orderBy) {
      // 如果当前的排序字段没有变化，则切换正反序
      newOrder = orderAs === 'desc' ? 'asc' : 'desc';
    } else {
      // 如果当前排序字段发生变化，则直接按照新的字段升序
      newOrder = 'asc';
    }
    setOrderAs(newOrder);
    setOrderType(orderBy);
  };

  const ifEmptyFilter = () => {
    try {
      if (
        articleOAWaiverUseFilters.journalCode.length === 0 &&
        articleOAWaiverUseFilters.revenueModel.length === 0 &&
        articleOAWaiverUseFilters.jpm.length === 0 &&
        articleOAWaiverUseFilters.subjectGroup.length === 0 &&
        articleOAWaiverUseFilters.businessDivision.length === 0 &&
        articleOAWaiverUseFilters.catGroup.length === 0 &&
        articleOAWaiverUseFilters.handlingCe.length === 0 &&
        articleOAWaiverUseFilters.journalStatus.length === 0
      ) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  function handleNull(num) {
    if (num === null || num === undefined) {
      return '-';
    } else {
      return num;
    }
  }

  {/*useEffect(() => {
    async function fetchData() {
      if (!flashLock) {
        await dispatch(
          getReportArticleOverviewBasicTableData(
            page,
            perPageNumber,
            orderAs,
            orderType,
            selectedArticleDate,
            articleOverviewFilters
          )
        );
      }
      setLoading(false);
      setFlashLock(false);
    }
    setLoading(true);
    if (page === 0) {
      if (!flashLock) {
        setTimeout(() => {
          setPage(1);
        }, 2000);
      }
    } else {
      fetchData();
    }
  }, [page]); // 表格本身属性触发的加载，一切按照默认*/}
  useEffect(() => {
    async function fetchData() {
      if (!flashLock) {
        await dispatch(
          getReportArticleOAWaiverUseBasicTableData(
            page,
            perPageNumber,
            selectedArticleDate,
            articleOAWaiverUseFilters,
          )
        );
      }
      setLoading(false);
      setFlashLock(false);
    }
    setLoading(true);
    if (page === 0) {
      if (!flashLock) {
        setTimeout(() => {
          setPage(1);
        }, 2000);
      }
    } else {
      fetchData();
    }
  }, [page]);
  {/*useEffect(() => {
    setFlashLock(false);
    setPage(0);
  }, [
    articleOverviewFilters,
    perPageNumber,
    orderType,
    orderAs,
    selectedArticleDate,
  ]); // 表格本身属性触发的加载，一切按照默认*/}
  useEffect(() => {
    setFlashLock(false);
    setPage(0);
  }, [
    articleOAWaiverUseFilters,
    perPageNumber,
    selectedArticleDate,
  ]); // 表格本身属性触发的加载，一切按照默认

  useEffect(() => {
    var filterEmpty = ifEmptyFilter();
    if (
      (articleOAWaiverUseBasicTableData === null ||
        articleOAWaiverUseBasicTableData === undefined ||
        articleOAWaiverUseBasicTableData.count === 0) &&
      filterEmpty
    ) {
      setIfNoResult(true);
    } else {
      setIfNoResult(false);
    }
  }, [articleOAWaiverUseBasicTableData]);

  const [hover, setHover] = useState(false);
  const [indexs, setIndexs] = useState(0);

  return (
    <TableBox2
      id='BasicTableBox'
      data-selenium-id='Report_Articles_OAW-BasicTableBox'
      dataList={[articleOAWaiverUseBasicTableData,loading]}
      tableKey={'Report_Articles-OAW-BasicTable'}
      footerHeight={40}
    >
      <TableContainer
        id='reportArticlesOAWBasicTable'
        data-selenium-id='Report_Articles_OAW-BasicTable'
        sx={{
          minHeight: '300px',
          maxHeight: '680px',
          border: '1px solid #DFE4E8',
          borderRadius: '8px 8px 0px 0px',
          // width: 'max-content',
        }}
      >
        <Table stickyHeader aria-label='sticky table' size='small'
               sx={{
                 tableLayout: 'fixed',
                 width: 'max-content',
                 maxWidth: 'none',
               }}
        >
          <TableHead
            data-selenium-id='Report_Articles_OAW-BasicTableHead'
          >
            <TableRow data-selenium-id='Report_Articles_OAW-BasicTableRow'>
              {items.map(item => (
                <TableCell
                  key={item.label}
                  align={item.align}
                  rowSpan={2}
                  sx={{
                    // minWidth: item.minWidth,
                    // maxWidth: item.maxWidth,
                    // height: '102px',
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '18px',
                    letterSpacing: '0.08em',
                    padding: '12px 24px',
                    color: '#596A7C',
                    backgroundColor: '#F1F3F5',
                    width: `${Math.max((columnWidthMap[item.id]??0), columnMinWidthMap[item.id]) + 50 }px`,
                    ...(item.id==='journalName' && {
                      position: 'sticky',
                      left: 0,
                      top: 0,
                      zIndex: 8,
                      backgroundColor: '#DFE4E8',
                      width: `${Math.max((columnWidthMap[item.id]??0), columnMinWidthMap[item.id]) + 50 }px`,
                    }),
                    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                  }}
                  sortDirection={orderType === item.id ? orderType : false}
                /*className={
                  item.id === 'handlingCe'
                    ? 'RightBottomBorder'
                    : 'BottomBorder'
                }*/
                >
                  {/*{item.isOrder ? (
                    <TableSortLabel
                      id={`articlesOverviewSortColumn-${item.id}`}
                      data-selenium-id={`Report_Articles_Overview-SortColumn-${item.id}`}
                      active={item.isOrder && orderType === item.id}
                      direction={orderType === item.id ? orderAs : 'asc'}
                      onClick={() => {
                        if (item.isOrder) {
                          handleChangeOrder(item.id);
                        }
                      }}
                    >
                      {item.id === 'acquiredSi'
                        ? item.label + tableMonth
                        : item.label}
                      {orderType === item.id ? (
                        <Box component='span' sx={visuallyHidden}>
                          {orderAs === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  ) : item.id === 'acquiredSi' ? (
                    item.label + tableMonth
                  ) : (
                    item.label
                  )}*/}
                  {item.label}
                </TableCell>
              ))}
              <TableCell
                align='left'
                className='ReportHeaderFirstLine'
                colSpan={4}
                //className='RightBottomBorder'
                sx={{
                  padding: '12px 0px 12px 24px',
                  height: '42px',
                  fontFamily: 'Open Sans',
                  fontSize: '12px',
                  fontWeight: 600,
                  lineHeight: '18px',
                  letterSpacing: '0.08em',
                  color: '#FFFFFF',
                  background: '#98A7B6',
                  borderLeftWidth: '2px',
                  borderLeftStyle: 'solid',
                  borderLeftColor: '#FFFFFF',
                  width: `${calcGroupWidth('sub')}px`,
                  boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                }}
                data-selenium-id='Report_Articles_OAW-BasicTable-Submission'
              >
                PUBLICATION IN {tableMonth}
              </TableCell>
              <TableCell
                align='left'
                className='ReportHeaderFirstLine'
                colSpan={4}
                //className='RightBottomBorder'
                sx={{
                  padding: '12px 0px 12px 24px',
                  height: '42px',
                  fontFamily: 'Open Sans',
                  fontSize: '12px',
                  fontWeight: 600,
                  lineHeight: '18px',
                  letterSpacing: '0.08em',
                  color: '#FFFFFF',
                  background: '#98A7B6',
                  borderLeftWidth: '2px',
                  borderLeftStyle: 'solid',
                  borderLeftColor: '#FFFFFF',
                  width: `${calcGroupWidth('acc')}px`,
                  boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                }}
                data-selenium-id='Report_Articles_Overview-BasicTable-Acceptance'
              >
                PUBLICATION YTD
              </TableCell>
              {/*<StyledTableCellPurple
                align='left'
                colSpan={3}
                className='RightBottomBorder'
                data-selenium-id='Report_Articles_Overview-BasicTable-Publication'
              >
                Publication
                  </StyledTableCellPurple>*/}
            </TableRow>
            <TableRow>
              {Subitems.map((item, index) => (
                <TableCell
                  align='left'
                  key={item.id}
                  sx={{
                    top: 42.8,
                    height: '60px',
                    padding: '12px 24px',
                    // maxWidth: item.maxWidth,
                    // minWidth: item.minWidth,
                    width: `${Math.max((columnWidthMap[`sub-${index}`]??0), columnMinWidthMap[`sub-${index}`]) + 50 }px`,
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '18px',
                    letterSpacing: '0px',
                    color: '#596A7C',
                    background: '#F1F3F5',
                    borderLeftWidth: item.id==='Sub-MMM'?'2px':null,
                    borderLeftStyle: item.id==='Sub-MMM'?'solid':null,
                    borderLeftColor: item.id==='Sub-MMM'?'#FFFFFF':null,
                    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                    textTransform: 'none',
                    fontFamily: 'Inter',
                  }}
                /*className={
                  item.id === '%Against YTD Target'
                    ? 'RightBottomBorder'
                    : 'BottomBorder'
                }*/
                >
                  {item.label}
                  {/*<TableSortLabel
                    id={`${item.id}SortLabel`}
                    data-selenium-id={`Report_Articles_Overview-BasicTable-${item.id}SortLabel`}
                    active={orderType === item.post}
                    direction={orderType === item.post ? orderAs : 'asc'}
                    onClick={() => {
                      handleChangeOrder(item.post);
                    }}
                  >

                  </TableSortLabel>*/}
                </TableCell>
              ))}
              {Accitems.map((item, index) => (
                <TableCell
                  align='left'
                  key={item.id}
                  sx={{
                    top: 42.8,
                    height: '60px',
                    padding: '12px 24px',
                    // maxWidth: item.maxWidth,
                    // minWidth: item.minWidth,
                    width: `${Math.max((columnWidthMap[`acc-${index}`]??0), columnMinWidthMap[`acc-${index}`]) + 50 }px`,
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '18px',
                    letterSpacing: '0px',
                    color: '#596A7C',
                    background: '#F1F3F5',
                    borderLeftWidth: item.id==='Acc-MMM'?'2px':null,
                    borderLeftStyle: item.id==='Acc-MMM'?'solid':null,
                    borderLeftColor: item.id==='Acc-MMM'?'#FFFFFF':null,
                    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                    textTransform: 'none',
                      fontFamily: 'Inter',
                  }}
                  /*className={
                    item.id === '%Against YTD Target'
                      ? 'RightBottomBorder'
                      : 'BottomBorder'
                  }*/
                >
                  {item.label}
                  {/*<TableSortLabel
                    id={`${item.id}SortLabel`}
                    data-selenium-id={`Report_Articles_Overview-BasicTable-${item.id}SortLabel`}
                    active={orderType === item.post}
                    direction={orderType === item.post ? orderAs : 'asc'}
                    onClick={() => {
                      handleChangeOrder(item.post);
                    }}
                  >
                    {item.id === 'Acc-MMM'
                      ? item.label + tableMonth
                      : item.label}
                  </TableSortLabel>*/}
                </TableCell>
              ))}
              {/*{Pubitems.map(item => (
                <StyledTableCellDisPurple
                  align='right'
                  key={item.id}
                  sx={{
                    top: 32.5,
                    maxWidth: item.maxWidth,
                    minWidth: item.minWidth,
                  }}
                  className={
                    item.id === '%Against YTD Target'
                      ? 'RightBottomBorder'
                      : 'BottomBorder'
                  }
                >
                  <TableSortLabel
                    id={`${item.id}SortLabel`}
                    data-selenium-id={`Report_Articles_Overview-BasicTable-${item.id}SortLabel`}
                    active={orderType === item.post}
                    direction={orderType === item.post ? orderAs : 'asc'}
                    onClick={() => {
                      handleChangeOrder(item.post);
                    }}
                  >
                    {item.id === 'Pub-MMM'
                      ? item.label + tableMonth
                      : item.label}
                  </TableSortLabel>
                </StyledTableCellDisPurple>
                  ))}*/}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              articleOAWaiverUseBasicTableData != null &&
              articleOAWaiverUseBasicTableData.count > 0 ? (
              articleOAWaiverUseBasicTableData['result']?.map((row, index) => {
                if (row !== null) {
                  return (
                    <TableRow
                      data-selenium-id={`Report_Articles_Overview-BasicTable-Row-${index}`}
                      key={row.id}
                      aria-checked={false}
                      sx={{
                        height: '64px',
                        background: '#FFFFFF',
                        '&:hover':{
                          background: '#E8EDFB',
                        }
                      }}
                      onMouseEnter={() => {
                        setHover(true);
                        setIndexs(index);
                      }}
                      onMouseLeave={() => {
                        setHover(false);
                        setIndexs(index);
                      }}
                      selected={false}
                    >
                      {/*journal information1*/}
                      <TableCell
                        sx={{
                          // background: hover && indexs === index ?'#E8EDFB':'#F6F7F8',
                          padding: '12px 24px',
                          position: 'sticky',
                          left: 0,
                          zIndex: 6,
                          background: hover && indexs === index ?'#E8EDFB':'#F6F7F8',                        }}
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell1-${index}`}
                      >
                        <Box
                          sx={{
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '20px',
                            color: '#262E35',
                          }}
                        >
                          {row.journalCode}
                        </Box>
                        <Box
                          sx={{
                            fontSize: '14px',
                            color: '#596A7C',
                            fontWeight: 400,
                            lineHeight: '20px',
                          }}
                        >
                          {row.journalName}
                        </Box>
                      </TableCell>
                      {/*journalStatus2*/}
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell2-${index}`}
                      >
                        {row.journalStatus}
                      </TableCell>
                      {/*JPM3*/}
                      {showJPM && (
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell3-${index}`}
                      >
                        <TextLabel
                          text={row.jpm}
                          TooltipText={row.emailOfJpm}
                        />

                      </TableCell>
                      )}

                      {/* PD Publisher */}
                      {showNewJournalRoles && (
                          <StyledTableCell
                              align='left'
                              data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell4-PDP`}
                          >
                            <TextLabel
                                text={row.pdp}
                                TooltipText={row.emailOfPdp}
                            />
                          </StyledTableCell>
                      )}

                      {/* PP Publisher */}
                      {showNewJournalRoles && (
                          <StyledTableCell
                              align='left'
                              data-selenium-id={`Report_AcquireOverview-Overview-BasicTable-TableBody-StyledTableRow${row.id}-StyledTableCell4-PPP`}
                          >
                            <TextLabel
                                text={row.ppp}
                                TooltipText={row.emailOfPpp}
                            />
                          </StyledTableCell>
                      )}

                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell3-${index}`}
                      >
                        <TextLabel
                                text={row.abbreviation}
                                TooltipText={row.subjectGroup}
                        />
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell4-${index}`}
                      >
                        {/*<TextLabel text={row.businessDivision} />*/}
                        {row.businessDivision}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell5-${index}`}
                      >
                        {row.catGroup}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell6-${index}`}
                      >
                        <TextLabel
                          text={row.handlingCe}
                          TooltipText={row.emailOfHandlingCe}
                        />
                        {/*{row.handlingCe}*/}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='RightBorder'
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell7-${index}`}
                      >
                        {row.publishedSiInMonth}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell8-${index}`}
                      >
                        {/*<TextLabel text={handleNull(row.submittedArtYtd)} />*/}
                        {row.waiverUsedSiInMonth}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell9-${index}`}
                      >
                        {row.publishedOaArticlesInMonth}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='RightBorder ReportNumberTextColor'
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell10-${index}`}
                      >
                        {/*<TextLabel text={handleNull(row.acceptedArt)} />*/}
                        {row.usedOaWaiverInMonth}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell11-${index}`}
                      >
                        {row.publishedSiInYear}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell12-${index}`}
                      >
                        {/*<TextLabel
                          text={handleNull(row.acceptedArtYtdTarget)}
                      />*/}
                        {row.waiverUsedSiInYear}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell12-${index}`}
                      >
                        {row.publishedOaArticlesInYear}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles_Overview-BasicTable-Cell12-${index}`}
                      >
                        {/*<TextLabel
                          text={'pos2'}
                      />*/}
                        {row.usedOaWaiverInYear}
                      </TableCell>
                    </TableRow>
                  );
                }
              })
            ) : !loading ? (
              <ReportNoData top='40px' id={'Report-Articles-Overview-BasicTable-NoData'}/>
            ) : (
              <div></div>
            )}
          </TableBody>
        </Table>
        {loading && (
          <Box
            sx={{
              width: 'auto',
              marginTop: '150px',
              marginLeft: '50%',
            }}
            data-selenium-id='Report_Articles_Overview-BasicTable-Box'
          >
            <CircularProgress />
          </Box>
        )}
      </TableContainer>
      <Box>
        <Divider />
        {articleOAWaiverUseBasicTableData != null &&
          articleOAWaiverUseBasicTableData['count'] > 0 ? (
          <TableFooter2
            numSelected={0}
            data-selenium-id='Report_Articles_Overview-BasicTable-TableFooter2'
            Page={page}
            perPageNumber={perPageNumber}
            perPageList={[20, 50, 100]}
            count={
              articleOAWaiverUseBasicTableData != null
                ? articleOAWaiverUseBasicTableData['count']
                : 0
            }
            handlePerPageChange={handlePerPageChange}
            handlePageChange={handlePageChange}
            backgroundColor='#f1f3f5'
          />
        ) : (
          <div></div>
        )}
      </Box>
    </TableBox2 >
  );
}

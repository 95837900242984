import { useState } from "react";
import InfoList from "../compontents/InfoList";
import SectionFolder from "../compontents/SectionFolder";
import { useDispatch, useSelector } from "react-redux";

export default function JournalDetailSection(props) {
  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      CATJournalDetail: state.CATJournalDetail
    };
  };
  const { open, CATJournalDetail } = useSelector(selector);
  //   const CATJournalDetail={"journalCode": "string",
  //   "journalName": "string",
  //   "subjectGroup": "string",
  //   "ownershipStatus": "string",
  //   "revenueModel": "string",
  //   "primaryURL": "string",
  //   "authorGuideline": "string",
  //   "vchCode": "string",
  //   "businessDivision": "string",
  //   "society": "string",
  //   "peerReviewModel": "string",
  //   "eeoSystemLink": "string",
  //   "submissionUrl": "string",
  //   "onlineISSN": "string",
  //   "journalPublishingManager": "string",
  //   "editorInChief": "string",
  //   "associateManagingEditor": null,
  //   "productionEditor": "string",
  //   "marketingManager": "string",
  //   "editorialDirector": "string",
  //   "eOContact": null,
  //   "managingEditor": null,
  //   "productionManager": "string",
  //   "handlingCe": null,
  //   "catJournalActiveSiList": [
  //       {
  //           "siCode": "string",
  //           "siPageLink": "string",
  //           "siTitle": "string",
  //           "siStage": "string",
  //           "numberOfSubmittedArticles": 0,
  //           "numberOfAcceptedArticles": 0,
  //           "numberOfRejectedArticles": 0,
  //           "numberOfInProgressArticles": 0
  //       }
  //   ],
  //   "catJournalPublishedSiList": [
  //       {
  //           "id": 0,
  //           "siCode": "string",
  //           "siPageLink": "string",
  //           "siTitle": "string",
  //           "volume": "string",
  //           "issue": "string",
  //           "actualPublicationDate": "string",
  //           "numberOfOaArticles": 0,
  //           "numberOfOoArticles": 0
  //       }
  //   ],
  //   "catJournalShelvedSiList": [
  //       {
  //           "siCode": "string",
  //           "siPageLink": "string",
  //           "siTitle": "string",
  //           "shelvedReason": "string",
  //           "shelvedDate": "string"
  //       }
  //   ]
  // };
  const list = [
    ['Subject Group', CATJournalDetail.subjectGroup, 'text'],
    ['Business', CATJournalDetail.businessDivision, 'text'],
    ['Ownership Status', CATJournalDetail.ownershipStatus, 'text'],
    ['Society', CATJournalDetail.society, 'text'],
    ['Revenue Model', CATJournalDetail.revenueModel, 'text'],
    ['Peer Review Model', CATJournalDetail.peerReviewModel, 'text'],
    ['Primary URL', CATJournalDetail.primaryURL, 'url'],
    ['EEO System Link', CATJournalDetail.eeoSystemLink, 'url'],
    ['Author Guideline', CATJournalDetail.authorGuideline, 'url'],
    ['Submission URL', CATJournalDetail.submissionUrl, 'url'],
    ['VCH CODE', CATJournalDetail.vchCode, 'text'],
    ['ISSN', CATJournalDetail.onlineISSN, 'text'],
  ];
  return (
    <SectionFolder title='Journal Details'>
      <InfoList
        list={list} />
    </SectionFolder>
  );
}
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const INTL_ALERT_PREFIX = 'sipDetail.DecisionPanelAlerts.sendMailAlert';

export default function useSendEmailAlertHook(sipStage, decisionType, originStage = 'new') {
  const { messages } = useIntl();

  // set alert message and buttons text
  let alertMessage;
  // Improvement: provide a fallback method to the "Else" block code automatically
  if (sipStage === 'Revision' && decisionType === 'Cancel Revision') {
    // special for "revision" stage, because it need the original stage
    alertMessage = <FormattedMessage
      id={`${INTL_ALERT_PREFIX}.${sipStage}.${decisionType}.message`}
      values={{
        originStage,
      }}
    />;
  } else {
    alertMessage = messages[`${INTL_ALERT_PREFIX}.${sipStage}.${decisionType}.message`]
      ?? messages[`${INTL_ALERT_PREFIX}.default.${decisionType}.message`]
      ?? messages[`${INTL_ALERT_PREFIX}.default.message`]
      ?? '';
  }

  let buttonText1 = messages[`${INTL_ALERT_PREFIX}.${sipStage}.${decisionType}.buttonText1`]
    ?? messages[`${INTL_ALERT_PREFIX}.default.${decisionType}.buttonText1`]
    ?? messages[`${INTL_ALERT_PREFIX}.default.buttonText1`];

  let buttonText2 = messages[`${INTL_ALERT_PREFIX}.${sipStage}.${decisionType}.buttonText2`]
    ?? messages[`${INTL_ALERT_PREFIX}.default.${decisionType}.buttonText2`]
    ?? messages[`${INTL_ALERT_PREFIX}.default.buttonText2`];

  return { alertMessage, buttonText1, buttonText2 };
};
import { Box, Tabs, Tab, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  cat_overview_container,
  cat_overview_head,
  cat_overview_head_btnBox,
  cat_overview_head_btnBox_right,
  cat_overview_head_title,
  cat_overview_main,
} from './css';
import { createRef, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CATJournalBatchUpdate from './CATJournalBatchUpdate';
import {
  CATJournalExport
} from './CATJournalExportButton';
import CATTable from './CATTable';
import OptInTable from './OptInTable';
import OptInSubmit from './OptInOperation/OptInSubmit';
import OptOutTable from './OptOutTable';
import EditAndSubmitPopUp from './OptOutTable/EditAndSubmitPopUp';
import RequestHistoryTable from './RequestHistoryTable';
import { StateButton } from '@/componentsphase2/StateButton';
import OptInIconWhite from '@/assets/OptInIconWhite.svg';
import OptOutIconWhite from '@/assets/OptOutIconWhite.svg';
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import {PERMISSIONS} from "@/constant/permission";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ paddingTop: "12px" }}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CJTabs = styled(props => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    // marginRight: "35px",
    // maxWidth: 129,
    width: '100%',
    backgroundColor: '#113D95',
  },
});

const CJTab = styled(Tab)({
  alignItems: 'self-start',
  height: '52px',

  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '20px',
  color: '#596A7C',

  letterSpacing: '1px',

  flex: 'none',
  order: 0,
  flexGrow: 0,
  '&.Mui-selected': {
    color: '#113D95',
  },
});

export default function CATJournalOverview(props) {
  const userPermissions = localStorage.getItem('userPermissions');

  const [simplePattern, setSimplePattern] = useState(false); // 判定顶部按钮是否是简略模式（就是按钮只显示图标） true 为简略模式

  const [TabValue, setTabValue] = useState(0);

  const [OptInSubmitOpen, setOptInSubmitOpen] = useState(false);
  const [optOutSubmitOpen, setOptOutSubmitOpen] = useState(false);


  const selector = state => {
    // console.log(state.UserManage);
    // console.log("state.UserManage");
    // console.log(state.CATJournal);
    return {
      userManageSiderOpen: state.UserManage.siderOpen,
      selectedList: state.Filter.selectedList,
    };
  };


  const { selectedList } = useSelector(selector);
  const [hasSelector, setHasSelector] = useState(false);



  const { userManageSiderOpen } = useSelector(selector);

  /***************  监听 顶部按钮box 的宽度变化 *********************************/
  const cat_overview_head_btnBox_monitor = createRef();
  const simplePatternThreshold = 1110;
  const responsiveButtonSimplePatternThreshold = 800;
  const sideBarOpenWidth = 240;

  const handleWindowResize = useCallback(() => {
    if (!cat_overview_head_btnBox_monitor.current) {
      return;
    }
    setSimplePattern(
      cat_overview_head_btnBox_monitor.current.clientWidth <=
      simplePatternThreshold
    );
  }, [cat_overview_head_btnBox_monitor]);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);
  /****************************************************************************/
  // useEffect(() => {
  //   setHasSelector(selectedList && selectedList.length > 0);
  // }, [selectedList]);

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={{
        ...cat_overview_container,
        marginLeft: userManageSiderOpen ? `${sideBarOpenWidth}px` : '83px',
        padding: '24px 26px 16px 20px'
      }}
    >
      {/* 标题 和 顶部按钮box */}
      <Box sx={cat_overview_head}>
        {/* 标题 */}
        <Box sx={cat_overview_head_title}>
          <FormattedMessage
            id='catJournal.pageTitle'
            data-selenium-id='CATJournal_Overview-PageTitleFormattedMessage'
          />
        </Box>
        <Box sx={{ mt: '4px' }}>
          <Box>
            <CJTabs
              value={TabValue}
              onChange={handleTabChange}
              aria-label='basic tabs example'
              style={{ minHeight: '29px' }}
              data-selenium-id='CATJournal_Overview-PageTitleCJTabs'
            >
              <CJTab
                label='SCA&nbsp;JOURNAL'
                {...a11yProps(0)}
                wrapped
                data-selenium-id='SCAJournal_Overview-CJTab'
              />
              <CJTab
                label='OPT-IN&nbsp;REQUEST'
                {...a11yProps(1)}
                wrapped
                data-selenium-id='CATJournal_Overview-CJTab2'
              />
              <CJTab
                label='OPT-OUT&nbsp;REQUEST'
                {...a11yProps(2)}
                wrapped
                data-selenium-id='CATJournal_Overview-CJTab3'
              />
              <CJTab
                label='REQUEST&nbsp;HISTORY'
                {...a11yProps(3)}
                wrapped
                data-selenium-id='CATJournal_Overview-CJTab4'
              />
            </CJTabs>
          </Box>

          {/* CAT JOURNAL */}
          <TabPanel
            value={TabValue}
            index={0}
            data-selenium-id='CATJournal_Overview-TabPanel1'
          >
            {/* 顶部按钮box */}
            <Box
              sx={cat_overview_head_btnBox}
              ref={cat_overview_head_btnBox_monitor}
              data-selenium-id='CATJournal_Overview-TabPanel1-HeadBtnBox'
            >
              <Box sx={cat_overview_head_btnBox_right}>
                <ShowForPermission
                  permission={`${PERMISSIONS.JOURNAL_CAT_BATCH_UPDATE}`}>
                  <CATJournalBatchUpdate
                    downBy={
                      responsiveButtonSimplePatternThreshold +
                      (userManageSiderOpen ? sideBarOpenWidth : 0)
                    }
                    data-selenium-id='CATJournal_Overview-TabPanel1-CATJournalBatchUpdate1'
                  ></CATJournalBatchUpdate>
                </ShowForPermission>
                <CATJournalExport
                  downBy={
                    responsiveButtonSimplePatternThreshold +
                    (userManageSiderOpen ? sideBarOpenWidth : 0)
                  }
                  data-selenium-id='CATJournal_Overview-TabPanel1-CATJournalBatchUpdate2'
                ></CATJournalExport>
                {/* <CATJournalExportAll
                  downBy={
                    responsiveButtonSimplePatternThreshold +
                    (userManageSiderOpen ? sideBarOpenWidth : 0)
                  }
                  data-selenium-id='CATJournal_Overview-TabPanel1-CATJournalBatchUpdate3'
                ></CATJournalExportAll> */}
              </Box>
            </Box>

            {/* 主体内容部分 */}
            <Box
              sx={cat_overview_main}
              data-selenium-id='CATJournal_Overview-TabPanel1-MainBox'
            >
              <CATTable></CATTable>
            </Box>
          </TabPanel>

          {/* OPT-IN REQUEST */}
          <TabPanel
            value={TabValue}
            index={1}
            data-selenium-id='CATJournal_Overview-TabPanel2'
          >
            {userPermissions && userPermissions.includes(PERMISSIONS.OPT_IN) ? (
              <Box
                sx={cat_overview_head_btnBox}
                ref={cat_overview_head_btnBox_monitor}
                data-selenium-id='CATJournal_Overview-TabPanel2-HeadBtnBox'
              >
                <Box sx={cat_overview_head_btnBox_right}>


                  <ShowForPermission permission={`${PERMISSIONS.OPT_IN}`}>
                    <StateButton
                      float='right'
                      display='flex'
                      justifyContent='flex-start' z
                      titleName='Opt-in'
                      // onlyDefault = {true}
                      // startIcon = {selectedList && selectedList.length > 0? <OptInIconWhite></OptInIconWhite>: <OptInIcon></OptInIcon>}
                      startIcon={<OptInIconWhite></OptInIconWhite>}

                      data-selenium-id='CATJournal_Overview-TabPanel2-RightBtnBox'
                      // isPrimary = {selectedList && selectedList.length > 0? true: false}
                      onClick={() => {
                        setOptInSubmitOpen(true);
                      }}
                    >

                    </StateButton>
                  </ShowForPermission>



                  {/* 
                  <StateButton
                    float = 'right'
                    display= 'flex'
                    justifyContent= 'flex-start'z
                    titleName = 'Opt-in'
                    onlyDefault = {true}
                    startIcon = {
                      <OptInIcon></OptInIcon>}
                    data-selenium-id='CATJournal_Overview-TabPanel2-RightBtnBox'
                    isPrimary = {false}
                    onClick={() => {
                      setOptInSubmitOpen(true);
                    }}
                  > */}
                  {/* 
                  </StateButton> */}
                  {/* <Box
                    sx={{ pb: '14px' }}
                    onClick={() => {
                      setOptInSubmitOpen(true);
                    }}
                    data-selenium-id='CATJournal_Overview-TabPanel2-RightBtnBox'
                  >
                    <CATJournalOptIn />
                  </Box> */}



                </Box>
                <OptInSubmit
                  open={OptInSubmitOpen}
                  handleClose={() => {
                    setOptInSubmitOpen(false);
                  }}
                  data-selenium-id='CATJournal_Overview-TabPanel2-OptInSubmit'
                />
              </Box>
            ) : (
              <></>
            )}

            <Box
              sx={cat_overview_main}
              data-selenium-id='CATJournal_Overview-TabPanel2-MainBox'
            >
              <OptInTable></OptInTable>
            </Box>
          </TabPanel>

          {/* OPT-OUT REQUEST */}
          <TabPanel
            value={TabValue}
            index={2}
            data-selenium-id='CATJournal_Overview-TabPanel3'
          >
            {userPermissions && userPermissions.includes(PERMISSIONS.OPT_OUT) ? (
              <Box
                sx={cat_overview_head_btnBox}
                ref={cat_overview_head_btnBox_monitor}
                data-selenium-id='CATJournal_Overview-TabPanel3-HeadBtnBox'
              >
                <Box sx={cat_overview_head_btnBox_right}>

                  <ShowForPermission permission={`${PERMISSIONS.OPT_OUT}`}>
                    <StateButton
                      // sx={{ mb: '16px' }}
                      onClick={() => {
                        setOptOutSubmitOpen(true);
                      }}
                      data-selenium-id='CATJournal_Overview-TabPanel3-RightBtnBox'
                      // startIcon = {selectedList && selectedList.length > 0? <OptOutIconWhite></OptOutIconWhite>: <OptOutIcon></OptOutIcon>}
                      startIcon={<OptOutIconWhite></OptOutIconWhite>}
                      titleName="Opt-out"
                    // isPrimary = {selectedList && selectedList.length > 0? true: false}
                    // onlyDefault = {true}
                    >
                    </StateButton>
                  </ShowForPermission>

                </Box>
                <EditAndSubmitPopUp
                  open={optOutSubmitOpen}
                  setOpen={setOptOutSubmitOpen}
                  type='submit'
                  data-selenium-id='CATJournal_Overview-TabPanel3-EditAndSubmitPopUp'
                />
              </Box>
            ) : (
              <></>
            )}
            <Box
              sx={cat_overview_main}
              data-selenium-id='CATJournal_Overview-TabPanel3-MainBox'
            >
              <OptOutTable></OptOutTable>
            </Box>
          </TabPanel>

          {/* REQUEST HISTORY */}
          <TabPanel
            value={TabValue}
            index={3}
            data-selenium-id='CATJournal_Overview-TabPanel4'
          >
            <RequestHistoryTable></RequestHistoryTable>
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
}

// Componenets
import { Box } from '@mui/material';
// styles
import { SmallBoldFont } from '@/components/FontStyle';
import { AllCentered } from '../Css';

export default function AdaptFilterButton({ disabled = false, handleClick }) {
  return (
    <Box
      id='btnApplyFilter'
      data-selenium-id={'SIPage_Overview-Filter-Overview-btnApplyFilter'}
      sx={{
        width: '110px',
        height: '34px',
        ...SmallBoldFont,
        color: disabled ? '#98A7B6' : '#FFFFFF',
        borderRadius: '5px',
        cursor: disabled ? 'default' : 'pointer',
        backgroundColor: disabled ? '#DFE4E8' : '#0052CC',
        ':hover': !disabled && {
          backgroundColor: '#6D98EE'
        },
        ':active': !disabled && {
          backgroundColor: '#113D95',
        },
        ...AllCentered
      }}
      onClick={() => {
        if (!disabled) {
          handleClick();
        }
      }}
    >
      Adapt Filters
    </Box>
  );
}

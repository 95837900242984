import { useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import styles from '../../header-switch.module.scss';
import {
  exportReportCSV,
  exportReportExcel,
  selectDate as selectArticleOverviewDate,
  exportReportCSVWithFilenameFromBackEnd,
  exportReportExcelWithFilenameFromBackEnd,
  exportReportCSVFilter,
  exportReportExcelFilter,
} from '@/actions/SIMT-Report/Report/ReportAction';
import moment from 'moment';
import { selectDate } from '@/actions/SIMT-Report/Report/ReportAction';
import { useDispatch, useSelector } from 'react-redux';
import ReportExportMenuButton from '../../ReportSpecificComponents/ReportExportMenuButton';
import { StateButton } from '@/componentsphase2/StateButton';
import { DatePickerStack } from '../../GenerationDate';
import ExportAllDialog from '../../ExportAllDialog';
import { TableLabel } from '../../ReportSpecificComponents/SpecialColorTableHeader';
import {useFilterCounterFyHook} from '@/pages/Report/utils/useFilterCounterFyHook';
import {PERMISSIONS} from "@/constant/permission";

const EXPORT_SIPIPELINE_EXCEL_URL = '/reports/export/getSiPipelineReportExcelWithFilter';
const EXPORT_SIPIPELINE_CSV_URL = '/reports/export/getSiPipelineReportCsvWithFilter';
export function PageOverview({ showTable, setShowTable }) {
  const { selectMonthDate, basicTableData, basicReportFilters } = useSelector(
    state => {
      return {
        // filter display
        selectMonthDate: state.Report.selectDate,
        basicTableData: state.Report.basicTableData,
        basicReportFilters: state.Filter.basicReportFilters,
      };
    }
  );
  const lastActionDate = basicTableData.lastActionDate;
  const GenerationDateAndTime =
    'Below reports are generated on ' + lastActionDate + ' (UTC time). ';
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [months, setMonths] = useState([]);
  const dispatch = useDispatch();
  const currentDate = new Date();

  useEffect(() => {
    if (selectMonthDate != null) {
      var Year = selectMonthDate.split('-')[0];
      var Month = selectMonthDate.split('-')[1] - 1;
      setSelectedMonth(new Date(Year, Month));
    }
  }, [selectMonthDate]);

  const exportExcelFilter = () => {
    let selectDate =
      Number(selectMonthDate.split('-')[0]) * 100 +
      Number(selectMonthDate.split('-')[1]);
    dispatch(exportReportExcelFilter(selectDate, EXPORT_SIPIPELINE_EXCEL_URL, basicReportFilters));
  };

  const exportExcelWithFilenameFromBackEnd = type => {
    let selectDate =
      Number(selectMonthDate.split('-')[0]) * 100 +
      Number(selectMonthDate.split('-')[1]);
    dispatch(exportReportExcelFilter(selectDate, EXPORT_SIPIPELINE_EXCEL_URL, {}));
  };

  const handleExportExcel = type => {
    if (type === 'filter') {
      exportExcelFilter();
    } else {
      exportExcelWithFilenameFromBackEnd();
    }
  };

  const exportCsvFilterd = () => {
    let selectDate =
      Number(selectMonthDate.split('-')[0]) * 100 +
      Number(selectMonthDate.split('-')[1]);
    // console.log(selectDate);
    dispatch(
      exportReportCSVFilter(selectDate, 
        EXPORT_SIPIPELINE_CSV_URL, 
        'basic_table', 
        basicReportFilters)
    );
    dispatch(
      exportReportCSVFilter(
        selectDate,
        EXPORT_SIPIPELINE_CSV_URL,
        'subject_group_table',
        basicReportFilters
      )
    );
    dispatch(
      exportReportCSVFilter(
        selectDate,
        EXPORT_SIPIPELINE_CSV_URL,
        'business_division_table',
        basicReportFilters
      )
    );
    //当用户具有'report:read-ce'可导出CE/CAT表格
    if (localStorage.getItem('userPermissions').includes(PERMISSIONS.REPORT_READ_CE))
      dispatch(
        exportReportCSVWithFilenameFromBackEnd(
          selectDate,
          '/reports/export/getSiPipelineReportCsv',
          `SIPipeline_${moment()
            .utc()
            .format('MMDDYYYY_HHmmss')}_CeCat_table.csv`,
          'ce_table'
        )
      );
  };

  const exportCsvWithFilenameFromBackEnd = () => {
    let selectDate =
      Number(selectMonthDate.split('-')[0]) * 100 +
      Number(selectMonthDate.split('-')[1]);
    // console.log(selectDate);
    dispatch(
      exportReportCSVFilter(selectDate, 
        EXPORT_SIPIPELINE_CSV_URL, 
        'basic_table', 
        {})
    );
    dispatch(
      exportReportCSVFilter(
        selectDate,
        EXPORT_SIPIPELINE_CSV_URL,
        'subject_group_table',
        {}
      )
    );
    dispatch(
      exportReportCSVFilter(
        selectDate,
        EXPORT_SIPIPELINE_CSV_URL,
        'business_division_table',
        {}
      )
    );
    //当用户具有'report:read-ce'可导出CE/CAT表格
    if (localStorage.getItem('userPermissions').includes(PERMISSIONS.REPORT_READ_CE))
      dispatch(
        exportReportCSVWithFilenameFromBackEnd(
          selectDate,
          '/reports/export/getSiPipelineReportCsv',
          `SIPipeline_${moment()
            .utc()
            .format('MMDDYYYY_HHmmss')}_CeCat_table.csv`,
          'ce_table'
        )
      );
  };

  const handleExportCSV = type => {
    if (type === 'filter') {
      exportCsvFilterd();
    } else {
      exportCsvWithFilenameFromBackEnd();
    }
  };

  const getMonthList = () => {
    if (months.length === 0) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      for (let i = 0; i < 4; i++) {
        const tmpYear = year - i;
        const endMonth = i === 0 ? month : 12;
        for (let j = endMonth; j > 0; j--) {
          const tmpMonth = j;
          // months.push("" + tmpYear + "-" + tmpMonth);
          months.push(`${tmpYear}-${tmpMonth}`);
          setMonths(months);
        }
      }
      // console.log(months);
    }
  };

  const handleDateChange = value => {
    setSelectedMonth(value);
    var year = value.getFullYear();
    var month = value.getMonth() + 1;
    let date = year + '-' + month;
    dispatch(selectDate(date));
  };

  useEffect(() => {
    getMonthList();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth() + 1;
    var newDate = '' + year + '-' + month;
    if (selectMonthDate === null) {
      dispatch(selectDate(newDate));
    }
  }, [dispatch]);

  const [exportOpen, setExportOpen] = useState(false);

  return (
    <div
      style={{
        marginTop: '16px',
        marginBottom: '16px',
      }}
    >
      <ExportAllDialog
        open={exportOpen}
        handleClose={() => {
          setExportOpen(false);
        }}
        filterData={basicReportFilters}
        handleCSV={handleExportCSV}
        handleExcel={handleExportExcel}
      />
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        data-selenium-id='Report-HeaderOverview-SelectBox'
      // sx={{ marginTop: '36px' }}
      >
        <DatePickerStack
          id='reportSipipelineStack'
          selectedMonth={selectedMonth}
          handleDateChange={handleDateChange}
          lastActionDate={lastActionDate}
          showTable={showTable}
          GenerationDateAndTime={GenerationDateAndTime}
        />
        <Grid className={styles['action-buttons-container']}>
          {/* {showTable == 'true' && ( */}
          {showTable && (
            <ReportExportMenuButton
              sx={{ height: '32px', marginRight: '24px' }}
              id='reportSipipelineExportButton'
              data-selenium-id='Report_Sipipeline-ExportButton'
              mode={1}
              // label = 'common.Export'
              onClick={() => {
                setExportOpen(true);
              }}
            />
          )}
          <span className={styles['switch']}>
            <span
              id='show-table-button'
              className={styles[showTable ? 'selected' : '']}
              onClick={() => {
                // setShowTableNav(true);
                setShowTable(true);
              }}
            >
              Table
            </span>

            <span
              id='show-diagram-button'
              className={styles[!showTable ? 'selected' : '']}
              onClick={() => setShowTable(false)}
              style={{ maxHeight: '32px' }}
            >
              {/* <FormattedMessage id="common.exportAll" /> */}
              Diagram
            </span>
          </span>
        </Grid>
      </Grid>
    </div>
  );
}

export default function HeadOverview(props) {
  const { filterOpen, filterOpenChange } = props;

  const { basicReportFilters } = useSelector(state => {
    return {
      basicReportFilters: state.Filter.basicReportFilters,
    };
  });

  const filterNumber = useFilterCounterFyHook(basicReportFilters);

  return (
    <div>
      <Box>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-Stack-Box1-Stack'
          gap= '20px'
          sx={{
            paddingTop: '1px',
            paddingRight: '30px',
          }}
        >
          <TableLabel>Basic Table</TableLabel>
          <Grid data-selenium-id='Report_Sipipeline-Grid2'>
            {filterOpen ? (
              <div></div>
            ) : (
              <Box>
                <StateButton
                  id='btnToggleGEFilterOpen'
                  data-selenium-id='Report_Sipipeline-BtnToggleGEFilterOpen'
                  onClick={filterOpenChange}
                  isPrimary={filterNumber != 0}
                  filterNumber={filterNumber}
                  titleName='Filter'
                  sx={{
                    height: '34px',
                    width: '74px',
                    fontSize: '12px',
                  }}
                />
              </Box>
            )}
          </Grid>
        </Stack>
      </Box>
    </div>
  );
}

import { Box, Stack, useMediaQuery } from '@mui/material';
import { AcquiredSiCreationHeading } from '../../CustomComponent/Typography';
import { SiOperationEditContainer } from '../../CustomComponent/CustomContainer';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import { useEffect, useRef, useState } from "react";
import { SmallFont } from '@/components/FontStyle';
import { MulSelectInput } from '@/componentsphase2/SelectBox/SelectInput';
import { useDispatch, useSelector } from 'react-redux';
import ItemBlock, { defaultErrorSX } from '@/componentsphase2/Itemblock';
import { TextBlock } from '@/componentsphase2/Itemblock/TextBlock';
import ErrorTag from '@/componentsphase2/Itemblock/SVGComponents/ErrorTag.svg';
import {getSystemConfig} from "@/actions/SIMT-User/Config";
import {getJournalListWithNameOptIn} from "@/actions/SIMT-SI/SpecialIssue/Journal";

export const CategoryType0 = 'Single SI';
export const CategoryType1 = 'Joint SI with Same GE Team';
export default function CreateSIPage(props) {
  const { acquiredSiDetail,setValue } = props;
  const dispatch = useDispatch();
  const matches744 = useMediaQuery('(min-width:744px)');
  const [siCategory, setSICategory] = useState(null);
  const [scaJournal, setSCAJournal] = useState([]);
  const [oriJournals, setOriJournals] = useState([]);
  const [journals, setJournals] = useState([]);
  const [scaIsOpen, setScaIsOpen] = useState(false);

  const ref = useRef(null);

  const selector = state => {
    return {
      journalOptions: state.SI.JourList,
      systemConfig: state.SystemConfig.systemConfig,
    };
  };
  const { journalOptions, systemConfig } = useSelector(selector);

  function scrollToBottom(element, bottom = 0) {
    const container = element ? element : window;

    setTimeout(function () {
      container.scrollTo({ bottom, left: 0, behavior: 'smooth' });
    }, 20);
  }

  useEffect(() => {
    if (acquiredSiDetail?.journalCode) {
      setOriJournals([{
        journalCode: acquiredSiDetail.journalCode,
        journalName: acquiredSiDetail.journalName,
      }]);
    }
  }, [acquiredSiDetail]);

  useEffect(() => {
    console.info(oriJournals,scaJournal);
    let newArr = [...oriJournals, ...scaJournal];
    setJournals(newArr);
  }, [oriJournals, scaJournal]);

  useEffect(()=>{
    let newValue = {
        siCategory:siCategory,
        journals:journals,
        scaJournal:scaJournal
    };
    setValue(newValue);
  },[siCategory,journals,scaJournal]);

  useEffect(() => {
    dispatch(getSystemConfig());
  }, []);
  return (
    <Box
      data-selenium-id='SIPPage_TransformToSl-Submodule-CreateSIPage-Box'
      sx={{ paddingY: '59px', margin: '0 auto' }} //modified by HJX
      minWidth={matches744 ? '744px' : 'auto'}
      paddingX='32px'
    >
      <Stack spacing={3.5} direction='column'>
        <Stack spacing={3} direction='column'>
          <AcquiredSiCreationHeading variant='primary'>
            Select SI Category
          </AcquiredSiCreationHeading>
          <SiOperationEditContainer title='SI Category' required>
            <SinpleAutocomplete
              id='siCategoryAutoComplete'
              value={siCategory}
              options={['Single SI', 'Joint SI with Same GE Team']}
              defaultQuery={true}
              placeholder='Select SI Type'
              onChange={(e, newValue) => {
                if(newValue === CategoryType0){
                    setJournals(oriJournals);
                    setSCAJournal([]);
                }
                setSICategory(newValue);
              }}
            />
          </SiOperationEditContainer>
        </Stack>
        <Stack spacing={3} direction='column'>
          <AcquiredSiCreationHeading variant='primary'>
            Confirm Journal(s)
          </AcquiredSiCreationHeading>
          <Stack spacing={3.75} direction='row'>
            <Box sx={{ width: '200px', ...SmallFont, color: '#596A7C' }}>
              {' '}
              Journal Name (Code):
            </Box>
            <Stack
              sx={{ ...SmallFont, color: '#262E35' }}
              spacing={0.75}
              direction='column'
            >
              {journals.length === 0
                ? 'Accepted Journal Name'
                : journals.map((item, idx) => (
                    <Box
                      key={item.journalCode}
                      sx={{
                        width: '450px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.journalName} ({item.journalCode})
                    </Box>
                  ))}
            </Stack>
          </Stack>
          {siCategory === CategoryType1 && (
            <Stack spacing={3} direction='column'>
              <SiOperationEditContainer title='Add SCA Journal' required>
                <Stack
                  spacing={0.25}
                  direction='column'
                  sx={{
                    minHeight: scaIsOpen? '800px': null,
                  }}
                >
                  <MulSelectInput
                    id='scaJournalMulSelectInput'
                    value={scaJournal}
                    placeholder={'Search GE code'}
                    getOptionLabel={option =>
                      `${option.journalName} (${option.journalCode})`
                    }
                    options={journalOptions.filter(journal => journal?.journalCode !== acquiredSiDetail?.journalCode)}
                    loadOptionAction={name => {
                      dispatch(getJournalListWithNameOptIn(name));
                    }}
                    onValueChange={newValue => {
                      setSCAJournal(newValue);
                    }}
                    onOpen={()=>{
                      setScaIsOpen(true);
                      setTimeout(function () {
                        ref.current?.scrollIntoView(false);
                      }, 20);
                    }}
                    onClose={()=>setScaIsOpen(false)}
                    disableNewItem={true}
                    showPopupIcon={false}
                    showStartIcon={false}
                    width='680px'
                    error={
                      journals.length >
                      systemConfig.eachJointSIMaxAddedJournalsNumber
                    }
                  />
                  <Box sx={{
                    height: scaIsOpen ? '300px': 0,
                  }} ref={ref}>&nbsp;</Box>
                  {journals.length >
                    systemConfig.eachJointSIMaxAddedJournalsNumber && (
                    <TextBlock
                      textSX={defaultErrorSX}
                      stackProps={{ spacing: 0.125, direction: 'row' }}
                      startAttachment={
                        <Box sx={{ width: '12px', height: '16px' }}>
                          <ErrorTag></ErrorTag>
                        </Box>
                      }
                    >
                      {` You can only select maximum ${systemConfig.eachJointSIMaxAddedJournalsNumber} journals for Joint SI.`}
                    </TextBlock>
                  )}
                </Stack>
              </SiOperationEditContainer>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}

import { Tooltip, styled, tooltipClasses } from '@mui/material';

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className || '' }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#596A7C',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#596A7C',
    borderRadius: '5px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '8px 16px',
    marginBottom:'10px !important'
  },
}));

export const calcHeight = (options, maxNum, gap, liHeight, other) => {
  let numOptions = options?.length ?? 0;
  if (numOptions < maxNum) {
    return (numOptions - 1) * (gap + liHeight) + liHeight + other;
  } else {
    return (maxNum - 1) * (gap + liHeight) + liHeight + other;
  }
};

export const calcMarginBottom = (options, maxNum, gap, liHeight, other) => {
  let numOptions = options?.length ?? 0;
  if (numOptions <= maxNum) {
    return 0;
  } else {
    return liHeight - other;
  }
};

import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SIPViewAllArrow from '@/assets/SIPViewAllArrow.svg';
import { useParams } from 'react-router-dom';
import './HideText.css';
import TextViewAllButton from '@/componentsphase2/Button/TextViewAllButton';
import SIModalPro from '@/modules/Modal/SIModal';
import DrawerCloseButton from '@/componentsphase2/DrawerCloseButton';

import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Paper,
  Dialog,
  InputLabel,
  OutlinedInput,
  Button,
  styled,
  Backdrop,
  backdropClasses,
  Stack,
  Typography,
} from '@mui/material';
import CATReasonDialog from '../../Overview/CATTable/CATReasonDialog';
import {getSIPInformation} from "@/actions/SIMT-SIP/SIP/SipDetail";

const BlueBackdrop = styled(Backdrop)(() => ({
  [`&.${backdropClasses.root}`]: {
    background: 'none',
  },
}));
export default function MyHideText(props) {
  /**
   * To modify this document, please contact Zhenxiang Zhao (915123030@qq.com)
   */
  const { itemLength = false, defaultLineNum = 2, label, children } = props;

  const [lineNum, setLineNum] = useState(defaultLineNum);
  const [expanding, setExpanding] = useState(false);
  const { sipCode } = useParams();
  const dispatch = useDispatch();
  const [needHide, setNeedHide] = useState(false);
  const maxHeight = lineNum * 20;
  // const maxHeight = 15;
  const elementRef = useRef(null);
  // const selector = state => {
  //   return {
  //     sipInformation: state.SIP.sipInformation,
  //   };
  // };
  // const { sipInformation } = useSelector(selector);

  // useEffect(() => {
  //   dispatch(getSIPInformation(sipCode));
  // }, []);

  useEffect(() => {
    if (elementRef.current.scrollHeight > maxHeight || itemLength) {
      setNeedHide(true);
    } else {
      setNeedHide(false);
    }
    // window.addEventListener('resize', resizeUpdate);
    // return () => {
    //   window.removeEventListener('resize', resizeUpdate);
    // };
  }, [elementRef, maxHeight, itemLength]);

  const resizeUpdate = e => {
    if (elementRef.current.scrollHeight > maxHeight) {
      setNeedHide(true);
    } else {
      setNeedHide(false);
    }
  };

  const textStyle = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#262E35',
    flex: 'none',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: lineNum,
    WebkitBoxOrient: 'vertical',
    // maxHeight: maxHeight,
  };

  const viewAllStyle = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.01em',
    color: '#154AB6',
    flex: 'none',
    cursor: 'pointer',
  };

  const handleViewAllClick = () => {
    setExpanding(true);
    // setLineNum('inherit');
  };

  const handleHideClick = () => {
    setExpanding(false);
    setLineNum(defaultLineNum);
  };

  return (
    <div
      data-selenium-id='SIP_DetailPage_Submodule-HideText-Div'
      style={{
        position: 'relative',
        // overflowX: "auto",
        padding: '12px 0 10px 0',
      }}
    >
      <div
        style={textStyle}
        ref={elementRef}
        data-selenium-id='SIP_DetailPage_Submodule-HideText-PropsDiv'
      >
        {props.children}
      </div>
      {needHide ? (
        expanding ? (
          // <div
          //   // style={{ marginTop: '2px' }}
          //   data-selenium-id='SIP_DetailPage_Submodule-HideText-DivHandleClickSpan'
          // >
          //   <span style={viewAllStyle} onClick={handleHideClick}>
          //     Hide
          //   </span>
          // </div>
          <></>
        ) : (
          <div
            // style={{ marginTop: '2px' }}
            data-selenium-id='SIP_DetailPage_Submodule-HideText-DivViewAllClick'
          >
            {!label ? (
              <TextViewAllButton
                style={viewAllStyle}
                onClick={handleViewAllClick}
                data-selenium-id='SIP_DetailPage_Submodule-HideText-HandleViewAllClickSpan'
              />
            ) : (
              <Box
                sx={{
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  height: '20px',
                  fontSize: '14px',
                  lineHeight: '20px',
                  letterSpacing: '0.01em',
                  color: '#154AB6',
                  flex: 'none',
                  cursor: 'pointer',
                  width: 'max-content',
                  ':hover': {
                    fontWeight: 700,
                    color: '#113D95',
                  },
                }}
                onClick={handleViewAllClick}
                data-selenium-id='SIP_DetailPage_Submodule-HideText-HandleViewAllClickSpan'
              >
                {label}
              </Box>
            )}
            {/* <span
              style={viewAllStyle}
              onClick={handleViewAllClick}
              data-selenium-id='SIP_DetailPage_Submodule-HideText-HandleViewAllClickSpan'
            >
              <span data-selenium-id='SIP_DetailPage_Submodule-HideText-ViewAllSpan'>
                View All
              </span>{' '}
              <span
                id='viewAllArrow'
                data-selenium-id='SIP_DetailPage_Submodule-HideText-ViewAllArrowSpan'
              >
                <SIPViewAllArrow />
              </span>
            </span> */}
          </div>
        )
      ) : (
        <></>
      )}
      <Dialog
        id='CatOverviewEditCATReasonDialog'
        data-selenium-id='CATJournal_Overview-Table-CATReasonDialog-EditDialog'
        maxWidth={false}
        open={expanding}
        onClose={handleHideClick}
        aria-labelledby='draggable-dialog-title'
        components={{
          Backdrop: BlueBackdrop,
        }}
        slotProps={{
          backdrop: {
            onClick: null, // zhaoyicheng禁用点击背景关闭 Modal
          },
        }}
        sx={{
          background: ' rgba(17, 61,149, 0.5)',
          backdropFilter: 'blur(4px)',
          zIndex: 1401,
          '& .MuiPaper-root': {
            margin: '10px',
            borderRadius: '8px',
            width: '600px',
            boxShadow: 'none',
            //height: "330px",
          },
        }}
      >
        <Stack
          id='frame-2438'
          data-selenium-id='CATJournal_Overview-Table-CATReasonDialog-FrameStack2438'
          justifyContent='space-between'
          direction='row'
          sx={{
            width: '536px',
            padding: '24px 0px 16px 0px',
            margin: '0px auto',
          }}
        >
          <DialogTitle
            style={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22.5px',
              backgroundColor: '#ffffff',
              color: '#262E35',
              padding: 0,
            }}
            id='draggable-dialog-title'
            data-selenium-id='CATJournal_Overview-Table-CATReasonDialog-DraggableDialogTitle'
          >
            Shelved Reason
          </DialogTitle>
          <DrawerCloseButton
            onClick={handleHideClick}
            data-selenium-id='CATJournal_Overview-Table-CATReasonDialog-IconButton'
          />
        </Stack>
        <Stack
          id='Textarea-Base-Default'
          data-selenium-id='CATJournal_Overview-Table-CATReasonDialog-Textarea-Base-Default'
          sx={{
            margin: '0px auto',
            paddingBottom: '20px',
            width: '536px',
          }}
        >
          <DialogContent
            sx={{
              padding: 0,
              lineHeight: '20px',
              '&. MuiInputBase-root': {
                lineHeight: '20px',
              },
            }}
          >
            <DialogContentText
              id='CATJournalOverviewDialogContentText'
              data-selenium-id='CATJournal_Overview-Table-CATReasonDialog-DialogContentText'
              style={{
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#262E35',
              }}
            >
              <Box
                data-selenium-id='CATJournal_Overview-Table-CATReasonDialog-NotEditBox'
                sx={{
                  paddingRight: '6px',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Open Sans',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '0em',
                    overflowWrap: 'anywhere',
                    overflowY: 'auto',
                    maxHeight: '220px',
                    color: '#262E35',
                  }}
                >
                  {children}
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Stack>
      </Dialog>

      {/* <SIModalPro
        open={expanding}
        handleClose={() => {
          setExpanding(false);
        }}
        boxSx={{ height: 230, width: 600, padding: '32px 35px 20px 35px' }}
        title='Shelved Reason'
      >
        {props.children}
      </SIModalPro> */}
    </div>
  );
}

import * as actions from '../actions/SIMT-Report/Target/AnnualTargetAction';

const handleDefaultYear = () => {
  const currentDate = new Date();
  let year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  if (month >= 4) {
    return year + 1;
  } else {
    return year;
  }
};

const initState = {
  uploadFileResult: null,
  uploadFailMessage: null,
  emptyFileError: false,
  fiscalYear: handleDefaultYear(),
  ATInfo: [],
};

export default function AnnualTargetReducer(state = initState, action) {
  const { type, data } = action;

  switch (type) {
    case actions.UPLOAD_AT_XML:
      return {
        ...state,
        uploadFileResult: data,
      };
    case actions.UPLOAD_FAIL_MESSAGE:
      return {
        ...state,
        uploadFailMessage: data,
      };
    case actions.SET_FISCAL_YEAR:
      return {
        ...state,
        fiscalYear: data,
      };
    case actions.CLEAR_ANNUAL_TARGET:
      return { ...state, uploadFileResult: null, ATInfo: [] };
    case actions.GET_AT_INFO:
      return {
        ...state,
        ATInfo: data,
      };
    case actions.EMPTY_FILE_ERROR:
      return {
        ...state,
        emptyFileError: data,
      };
    default:
      return state;
  }
}

import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setCustomizedPath } from '../../../actions/BreadcrumbAction';
import Main from '../../../components/Main';
import CRshowBlock from './CRshowBlock';
import {getCRDetailInfomationList} from "@/actions/SIMT-SI/CRContact/Journal";

function CRContactDetailPage() {
  let { journalcode } = useParams();
  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      filterOpen: state.SI.filterOpen,
      CRdetailInfoObj: state.CRContact.CRdetailInfoObj,
    };
  };
  const { open, filterOpen, CRdetailInfoObj } = useSelector(selector);
  const dispatch = useDispatch();
  const [widthpx, setWidthpx] = useState('1195px');
  let width = document.body.clientWidth;
  /**
   * @author LiuXin
   * @date 2022/5/22
   * @description 实时监控浏览器窗口大小变化，在过小时自适应屏幕，在过大时固定显示尺寸
   */
  window.onresize = function () {
    resetMainBoxWidth();
  };

  useEffect(() => {
    resetMainBoxWidth();
  }, [open]);

  /**
   * @author LiuXin
   * @date 2022/5/22
   * @description 监控当前窗口大小，在过小时自适应屏幕，在过大时固定显示尺寸
   */
  const resetMainBoxWidth = () => {
    width = document.body.clientWidth;
    if (open === true) {
      width = width - 266;
    }
    if (width < 1215) {
      setWidthpx(width - 20 + 'px');
    } else {
      setWidthpx('1195px');
    }
  };

  /**
   * @author LiuXin
   * @date 2022/5/7
   * @description 发送两个dispatch，一个用于获取页面显示信息，一个设置面包屑的显示信息
   */
  useEffect(() => {
    dispatch(setCustomizedPath(journalcode));
    dispatch(getCRDetailInfomationList(journalcode));
  }, [journalcode]);

  //Main主盒子样式
  const maincss = {
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    border: '1px solid #dddddd',
    boxSizing: 'border-box',
    borderRadius: '4px',
    paddingLeft: '50px',
    paddingRight: '50px',
    paddingTop: '40px',
    paddingBottom: '80px',
  };

  //整个页面的title的样式
  const CRContactTitleCss = {
    width: '100%',
    height: '100%',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '36px',
    lineHeight: '125%',
    letterSpacing: '-0.022em',
    color: '#0855A5',
  };

  return (
    <Box
      sx={{
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      data-selenium-id='CRContact_DetailPage-Box'
    >
      <Main
        sx={{ ...maincss, width: widthpx }}
        open={open}
        filterOpen={filterOpen}
      >
        <Box sx={{ background: '#ffffff' }}>
          {/* CRContact title展示 */}
          <Box
            id='crContactDetailPageTitle'
            data-selenium-id='CRContact_DetailPage-TitleBox'
            sx={CRContactTitleCss}
          >
            {CRdetailInfoObj.journalName === undefined
              ? 'PRP Contact Detail'
              : journalcode + ' | ' + CRdetailInfoObj.journalName}
          </Box>
          <CRshowBlock
            title='EO Contact'
            journalcode={journalcode}
            CRdetailInfoObj={CRdetailInfoObj}
            data-selenium-id='CRContact_DetailPage-CRshowBlock1'
          />
          <CRshowBlock
            title='AME Details'
            journalcode={journalcode}
            CRdetailInfoObj={CRdetailInfoObj}
            data-selenium-id='CRContact_DetailPage-CRshowBlock2'
          />
          <CRshowBlock
            title='ME Details'
            journalcode={journalcode}
            CRdetailInfoObj={CRdetailInfoObj}
            data-selenium-id='CRContact_DetailPage-CRshowBlock3'
          />
        </Box>
      </Main>
    </Box>
  );
}

export default CRContactDetailPage;

import React from 'react';
import { Box } from '@mui/material';
import moment from 'moment';

import HideText from './HideText';
import HideUL from './HideUL';
import TextOverflow from '@/components/TextOverflow';
import { formatDateString } from '@/utils/commonUtils';
import { getUTCToDate } from './utils';

/**
 *
 * @param {import("./SingleKV").SingleKVProps} props
 * @returns
 */
export function SingleKV(props) {
  const { id, title, margin, sx, type, ...others } = props;
  /**
   * @type {import("@mui/material").SxProps}
   */
  const CssContainer = {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    margin: margin,
  };
  const CssTitle = {
    width: '20%',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#596A7C',
    wordWrap: 'break-word',
    marginRight: '24px',
  };
  const CssContent = {
    width: 'calc(80% - 24px)',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#262E35',
    // marginRight: '5%',
  };

  const valueElement = () => {
    /**
     * Don't replace "HideText" component directly.
     * If you want to replace it, please contact with Zhenxiang Zhao(915123030@qq.com)
     */
    switch (type) {
      case 'HIDE_TEXT':
        return (
          <HideText id={id} data-selenium-id={id} lineNum={3}>
            {props.children}
          </HideText>
        );
      case 'HIDE_UL':
        if (props.children == 'N/A') {
          return 'N/A';
        }
        return (
          <HideUL id={id} data-selenium-id={id}>
            {props.children}
          </HideUL>
        );
      case 'DATE':
        if (props.children == 'N/A') {
          return 'N/A';
        }
        const utcToDate = new Date(props.children);
        return formatDateString(utcToDate);

      case 'KEYWORDS':
        if (props.children == 'N/A') {
          return 'N/A';
        }
        const keywords = props.children.join('; ');
        return (
          <HideText id={id} data-selenium-id={id} lineNum={3}>
            {keywords}
          </HideText>
        );
      default:
        return props.children;
    }
  };

  return (
    <Box
      className='SingleKV-root'
      sx={{ ...CssContainer, ...sx }}
      {...others}
      data-selenium-id='SIP_DetailPage_Submodule-SingleKV-Box'
    >
      <Box
        className='SingleKV-title'
        sx={CssTitle}
        data-selenium-id='SIP_DetailPage_Submodule-SingleKV-TitleBox'
        style={{marginRight:'12px'}}
      >
        {title}
      </Box>
      <Box
        className='SingleKV-content'
        sx={CssContent}
        data-selenium-id='SIP_DetailPage_Submodule-SingleKV-ContentBox'
      >
        {valueElement()}
      </Box>
    </Box>
  );
}

export function SingleKVOther(props) {
  const { id, title, margin, sx, type, ...others } = props;
  /**
   * @type {import("@mui/material").SxProps}
   */
  const CssContainer = {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    margin: margin,
  };
  const CssTitle = {
    width: '20%',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#596A7C',
    wordWrap: 'break-word',
    marginRight: '24px',
  };
  const CssContent = {
    width: 'calc(80% - 24px)',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#262E35',
    marginRight: '5%',
  };

  const valueElement = () => {
    /**
     * Don't replace "HideText" component directly.
     * If you want to replace it, please contact with Zhenxiang Zhao(915123030@qq.com)
     */
    switch (type) {
      case 'HIDE_TEXT':
        return (
          <TextOverflow
            id={id}
            data-selenium-id={`SIP_DetailPage_Submodule-SingleKV-Other-${id}`}
            lineNum={3}
          >
            {props.children}
          </TextOverflow>
        );
      case 'HIDE_UL':
        if (props.children == 'N/A') {
          return 'N/A';
        }
        return (
          <HideUL id={id} data-selenium-id={id}>
            {props.children}
          </HideUL>
        );
      case 'DATE':
        if (props.children == 'N/A') {
          return 'N/A';
        }
        return moment(props.children).utc().format('YYYY-MM-DD');
      case 'KEYWORDS':
        if (props.children == 'N/A') {
          return 'N/A';
        }
        const keywords = props.children.join('; ');
        return (
          <TextOverflow
            id={id}
            data-selenium-id={`SIP_DetailPage_Submodule-SingleKV-Other-${id}`}
            lineNum={3}
          >
            {keywords}
          </TextOverflow>
        );
      default:
        return props.children;
    }
  };

  return (
    <Box
      className='SingleKV-root'
      sx={{ ...CssContainer, ...sx }}
      {...others}
      data-selenium-id='SIP_DetailPage_Submodule-SingleKV-Other-Box'
    >
      <Box
        className='SingleKV-title'
        sx={CssTitle}
        data-selenium-id='SIP_DetailPage_Submodule-SingleKV-Other-TitleBox'
      >
        {title}
      </Box>
      <Box
        className='SingleKV-content'
        sx={CssContent}
        data-selenium-id='SIP_DetailPage_Submodule-SingleKV-Other-ContentBox'
      >
        {valueElement()}
      </Box>
    </Box>
  );
}

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useParams} from "react-router-dom";
// Components
import Item from '@/pages/SIPage/Overview/Filter/component/Framework/Block/Item';
import Section from '@/pages/SIPage/Overview/Filter/component/Framework/Block/Section';
import { Box,  ThemeProvider, createTheme } from '@mui/material';
import { GETooltipIf } from '@/componentsphase2/SelectBox/SelectInput';
import { StyledChip } from '@/pages/SIPage/Overview/Filter/component/StyledComponents/styledChip';
import SelectBox from './CommonComponents/SelectBox';
// Assets
import ChipClearIcon from '@/pages/SIPage/Overview/Filter/component/SVGComponents/ChipClearIcon.svg';
// Actions
import { setSelect, loadData } from '@/actions/SIMT-SIP/SIPFilter/SIPFilterAction';
// utils
import {activeStageList,  getLabelByFieldSSIP} from './utils';
import { blankTrim } from '@/utils/commonUtils';
import { measureWidth } from '@/utils/StringWidthMeasure';
import {
  unsolicitedFieldList,
  solicitedFieldList,
  getLabelByField,
  getThemeByField,
  getThemeColor,
} from './utils';

export default function ThirdSection(props) {
  
  // Used state variable
  const [activeSIChecked, setActiveSIChecked] = useState(false);
  const [dataDict, setDataDict] = useState({});
  const { sipOrigin } = useParams();
  const dispatch = useDispatch();

  const { preferredSubjectArea, preferredJournal, region, sipFilterSelected } =
    useSelector(state => {
      return {
        preferredSubjectArea: state.SIPFilter.preferredSubjectArea,
        preferredJournal: state.SIPFilter.preferredJournal,
        region: state.SIPFilter.region,
        sipFilterSelected: state.SIPFilter.sipFilterSelected,
      };
    });

  const handleSelectedChange = (which, data) => {
    dispatch(setSelect(which, data));
  };

  useEffect(() => {
    if (activeStageList.length !== sipFilterSelected.stageSelected.length) {
      setActiveSIChecked(false);
      return;
    }
    for (let i = 0; i < activeStageList.length; i++) {
      if (!sipFilterSelected.stageSelected.includes(activeStageList[i])) {
        setActiveSIChecked(false);
        return;
      }
    }
    setActiveSIChecked(true);
  }, [sipFilterSelected.stageSelected]);

  const onLoad = async (which, searchContent, page = 1, pageSize = 5000) => {
    await dispatch(loadData(which, blankTrim(searchContent), page, pageSize, sipOrigin === 'solicited'));
  };

  useEffect(() => {
    onLoad('preferredSubjectArea', '', 1, 5000);
    onLoad('preferredJournal', '', 1, 5000);
    onLoad('region', '', 1, 5000);
  }, []);

  useEffect(() => {
    let newDict = {};
    unsolicitedFieldList.forEach(field => {
      let label = getLabelByField(field);
      newDict[label] = {
        label: label,
        field: field,
        number: sipFilterSelected[field + 'Selected']?.length ?? 0,
      };
    });
    setDataDict(newDict);
  }, [sipFilterSelected]);

  const getDataByField = label => {
    switch (label) {
      case 'preferredSubjectArea':
        return preferredSubjectArea;
      case 'preferredJournal':
        return preferredJournal;
      case 'region':
        return region;
      default:
        return [];
    }
  };
  
  const getValueByField = label => {
    switch (label) {
      case 'preferredSubjectArea':
        return sipFilterSelected.preferredSubjectAreaSelected;
      case 'preferredJournal':
        return sipFilterSelected.preferredJournalSelected;
      case 'region':
        return sipFilterSelected.regionSelected;
      default:
        return [];
    }
  };

  const getFieldList = () => {
    if(sipOrigin === 'solicited') return solicitedFieldList;
    return unsolicitedFieldList;
  };

  const Regien = [''];
  return (
    <Section id='Third_section'>
      {getFieldList().map((field, index) => {
        return (
          <Item
            id={`${getLabelByField(field)}`}
            title={sipOrigin === 'solicited'? `${getLabelByFieldSSIP(field)}`:`${getLabelByField(field)}`}
            needResetBtn={false}
          >
            <SelectBox
              id={`${field}-SelectBox`}
              data={getDataByField(field)}
              labelName={sipOrigin === 'solicited'? `${getLabelByFieldSSIP(field)}`:`${getLabelByField(field)}`}
              value={getValueByField(field)}
              setSelected={(e, newValue) => {
                dispatch(setSelect(field, newValue));
              }}
              loadMore={onLoad.bind(this, field)}
              dropListHeight='154px'
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '4px',
                marginTop: '6px !important',
              }}
            >
              {Regien.map((item, idx) => {
                let temSIPFilterSelected = sipFilterSelected[field + 'Selected'].concat();
                if (temSIPFilterSelected.length === 0) {
                  return null;
                }
                let cssTheme = createTheme({
                  themeColor: getThemeColor(getThemeByField(field)),
                });
                temSIPFilterSelected.push(null);
                let label = getLabelByField(field);
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: '4px',
                    }}
                    key={`ThirdSection-${label}`}
                  >
                    {temSIPFilterSelected.map((item, idx) => {
                      if (idx === temSIPFilterSelected.length - 1) {
                        return null;
                      }
                      let optionName = item;
                      return (
                        <ThemeProvider
                          theme={cssTheme}
                          key={`ThirdSection-${label}-${optionName}`}
                        >
                          <GETooltipIf
                            title={optionName}
                            disabled={
                              measureWidth(optionName, '12px Open Sans') < 347
                            }
                          >
                            <StyledChip
                              label={optionName}
                              deleteIcon={
                                <Box sx={{ display: 'flex' }}>
                                  <ChipClearIcon />
                                </Box>
                              }
                              onDelete={() => {
                                let selectedData =
                                  sipFilterSelected[
                                    field + 'Selected'
                                  ].concat();
                                selectedData.splice(idx, 1);
                                handleSelectedChange(field, selectedData);
                              }}
                            />
                          </GETooltipIf>
                        </ThemeProvider>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          </Item>
        );
      })}
    </Section>
  );
}

import {axios_instance} from "@/utils/axios_instance";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {GET_EXTEND_DDL, GET_REVISION_DDL} from "@/actions/SIMT-SIP/SIP/SIPAction";

export function extendedDeadlineSubmit(sipCode, extendDdl) {
    const action = 'createExtendedDdl';
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals`;
            const res = await axios_instance.put(
                url,
                {'extendedDdlRequests':{
                    sipCode: sipCode,
                    extendDdl: extendDdl,
                }},
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                    params:{
                        action: action,
                    }
                }
            );
            const{code} = res.data;
            if (code === 200) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.submitExtendDeadline',
                        {},
                        SEVERITIES.success
                    )
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.submitExtendDeadlineError',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch (error) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.submitExtendDeadlineError',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

export function extendedDeadlineEdit(sipCode, extendDdl) {
    const action = 'editExtendedDdl';
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals`;
            const res = await axios_instance.put(
                url,
                {'extendedDdlRequests':{
                    sipCode: sipCode,
                    extendDdl: extendDdl,
                }},
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                    params: {
                        action: action,
                    }
                }
            );
            const{code} = res.data;
            if (code === 200) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.editExtendDeadline',
                        {},
                        SEVERITIES.success
                    )
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.submitExtendDeadlineError',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch (error) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.submitExtendDeadlineError',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

export const getRevisionDdl = sipCode => {
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}`;
            const res = await axios_instance.get(url, {
                params: {
                    type: 'reviseDdl',
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const{code,data} = res.data;
            if (code === 200) {
                dispatch({
                    type: GET_REVISION_DDL,
                    data: data,
                });
            }
        } catch (err) {
        }
    };
};
export const getExtendedDdl = sipCode => {
    const type = 'extendedDdl';
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}`;
            const res = await axios_instance.get(url, {
                params: {
                    type: type,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const{code,data} = res.data;
            if (code === 200) {
                dispatch({
                    type: GET_EXTEND_DDL,
                    data:data,
                });
            }
        } catch (err) {
        }
    };
};
export const deleteExtendedDeadline = sipCode => {
    const action = 'deleteExtendedDdl';
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals`;
            const res = await axios_instance.put(url, 
                { "deleteExtendedDdlSipCode" : sipCode }, {
                params: {
                    action: action,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
        } catch (e) {
            dispatch(setSnackbarMessageAndOpen('崩溃', {}, SEVERITIES.error));
        }
    };
};
import { Stack, Box, Divider, Autocomplete } from '@mui/material';
import { CommonDialog } from '@/modules/AlertDialog';
import { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, SvgIcon, Typography } from '@mui/material';
import { AllCentered } from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/CommonStyle';
import {
  createGeDepartment,
  createGeInstitute,
  createGeKeyword,

} from '../../../../actions/SIMT-SI/GuestEditor/GEAction';
import '../../index.css';
import { openConfirm, setTextParam, setEditType } from '@/actions/SIMT-User/Alert/AlertAction';
import {
  CreateGETextField,
  CreateGEOnlineEmailVerifyTextField,
} from './CustomComponent/CustomTextField';
import { trimItemFilds } from '../../../../utils/commonUtils';
import {
  CreateGEGridContainer,
  CreateGEItemContainer,
} from './CustomComponent/CustomContainer';
import { CreateGESelect } from './CustomComponent/CustomSelect';
import {
  CreateGEAddItemAutocomplete,
  CreateGEAutocomplete,
  FlagAutocomplete,
} from './CustomComponent/CustomAutocomplete';
import { CreateGEFooter } from './CustomComponent/CustomFooter';
import { CREATE_GE } from '@/actions/SIMT-SI/SpecialIssue/SIAction';
import CloseIcon from '../../../../assets/SIOverviewColumnCloseFigma.svg';
import CloseHoverIcon from '../../../../assets/SIOverviewColumnCloseHoverFigma.svg';
import {
  drawer,
  drawer_head,
  drawer_head_title,
  drawer_head_icon,
  stack_left,
  stack_right,
  stack_header,
  drawer_top_box,
  drawer_top_button,
  drawer_bottom_button_list,
  drawer_bottom_button_cancel,
  drawer_bottom_button_add,
  drawer_main_block_after_stage,
  drawer_main_block_fixed,
} from './style';
import { StateButton } from '@/componentsphase2/StateButton';
import { SmallBoldFont } from '@/components/FontStyle';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import { calcHeight } from '@/componentsphase2/Autocomplete/Utils';
import { ItemBlockInstence } from './CustomComponent/CustomAutocomplete';
import { MulSelectInput } from '@/componentsphase2/SelectBox/SelectInput';
import SaveForLater from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/SVGComponents/SaveForLater.svg';
import { GetPopperBottomContext } from '@/componentsphase2/Autocomplete/CommonContext';
import { loadGEData } from "@/actions/SIMT-SI/GuestEditor/GuestEditorPage";
import { createKeyword, getKeywords } from "@/actions/SIMT-SI/GuestEditor/KeyWord";
import {
  createDepartment,
  createInstitute,
  getDepartmentList,
  getInstituteList
} from "@/actions/SIMT-SI/GuestEditor/GuestEditorCreate";
import { getCountryList } from "@/actions/SIMT-SI/GuestEditor/Country";
import { getFlaglist, getGEGenderConfig, getGETitleConfig } from "@/actions/SIMT-SI/GuestEditor/Config";
import { checkEmail, CreateGE } from "@/actions/SIMT-SI/GuestEditor";
/**
 *
 * @param {import("./CreateGEContainer").CreateGEContainerProps} props
 * @returns
 */
function CreateGEContainer(props) {
  const { open, handleCreateGEPageClose, isLead } = props;
  const dispatch = useDispatch();
  const [savedInput, setSavedInput] = useState(null);

  // const isSavedInputAvailable = useSelector((state) => state.isSavedInputAvailable);

  const [hover, setHover] = useState(false);
  // const { setsavedForLater ,setIsSavedInputAvailable } = useContext(AppContext);
  //  const { state } = useContext(MyContext);

  const {
    // 各种 dropdown list 数据
    departmentList,
    instituteList,
    keywords,
    countryList,
    geGenderConfigDropdownList,
    geTitleConfigDropdownList,
    // create GE 的响应值
    createGEResult,
    //
    geOverviewData,
    flagsList,
  } = useSelector(state => ({
    /** @type {import("./CreateGEContainer").ResearchKeyword[] | "error"} */
    // @ts-ignore
    keywords: state.GE.keywords,
    /** @type {import("./CreateGEContainer").Department[] | "error"} */
    // @ts-ignore
    departmentList: state.GE.departmentList,
    /** @type {import("./CreateGEContainer").Institute[] | "error"} */
    // @ts-ignore
    instituteList: state.GE.instituteList,
    /** @type {import("./CreateGEContainer").GEGender[] | null} */
    // @ts-ignore
    geGenderConfigDropdownList: state.GE.geGenderConfigDropdownList,
    /** @type {import("./CreateGEContainer").GETitle[] | null} */
    // @ts-ignore
    geTitleConfigDropdownList: state.GE.geTitleConfigDropdownList,
    /** @type {import("./CreateGEContainer").Country[] | "error"} */
    // @ts-ignore
    countryList: state.GE.countryList,
    /** @type {import("./CreateGEContainer").CreateGeResult} */
    // @ts-ignore
    createGEResult: state.SI.createGEResult,
    /** @type {import("./CreateGEContainer").GeOverviewData} */
    // @ts-ignore
    geOverviewData: {
      page: state.GE.page,
      pageSize: state.GE.pageSize,
      orderByType: state.GE.orderByType,
    },
    flagsList: state.GE.flagsList,
  }));

  // 创建 Manager 用于管理 keyword, department, department 的增加与查询
  const addNewKeywordManager = useSelector(
    state =>
      /** @type {import("./CustomComponent/CustomAutocomplete").AddNewItemDataManager<import("./CreateGEContainer").ResearchKeyword>} */({
      newData: state.GE.newKeyword,
      itemExist: state.GE.newKeywordExits,
      dataGet: state.GE.isNewKeywordGet,
      addNewOption: value => {
        dispatch(createGeKeyword('creating', null));
        dispatch(createKeyword(value));
      },
      afterAddNewOption: () => dispatch(createGeKeyword('idle', null)),
      afterDateGetSucess: newData => {
        // dispatch(setTextParam(`${newData['keyword']}`));
        // dispatch(setEditType('Research Keywords'));
        // dispatch(openConfirm());
      },
    })
  );

  const addNewFlagsManager = useSelector(state => ({
    newData: state.GE.newFlags,
    itemExist: state.GE.newFlagsExits,
    dataGet: state.GE.isFlagsGet,
    addNewOption: value => {
      dispatch(createGeKeyword('creating', null));
      dispatch(createKeyword(value));
    },
    afterAddNewOption: () => dispatch(createGeKeyword('idle', null)),
  }));

  const addNewDepartmentManager = useSelector(
    state =>
      /** @type {import("./CustomComponent/CustomAutocomplete").AddNewItemDataManager<import("./CreateGEContainer").Department>} */({
      newData: state.GE.newDepartment,
      itemExist: state.GE.newDepartmentExits,
      dataGet: state.GE.isNewDepartmentGet,
      addNewOption: value => {
        dispatch(createGeDepartment('creating', null));
        dispatch(createDepartment(value));
      },
      afterAddNewOption: () => dispatch(createGeDepartment('idle', null)),
      afterDateGetSucess: newData => {
        // dispatch(setTextParam(`${newData['department']}`));
        // dispatch(setEditType('Department'));
        // dispatch(openConfirm());
      },
    })
  );
  const addNewInstituteManager = useSelector(
    state =>
      /** @type {import("./CustomComponent/CustomAutocomplete").AddNewItemDataManager<import("./CreateGEContainer").Institute>} */({
      newData: state.GE.newInstitute,
      itemExist: state.GE.newInstituteExits,
      dataGet: state.GE.isNewInstituteGet,
      addNewOption: value => {
        dispatch(createGeInstitute('creating', null));
        dispatch(createInstitute(value));
      },
      afterAddNewOption: () => dispatch(createGeInstitute('idle', null)),
      afterDateGetSucess: newData => {
        // dispatch(setTextParam(`${newData['institute']}`));
        // dispatch(setEditType('Institution'));
        // dispatch(openConfirm());
      },
    })
  );

  useEffect(() => {
    if (open) {
      dispatch(getKeywords(''));
      dispatch(getDepartmentList(trimItemFilds('')));
      dispatch(getInstituteList(trimItemFilds('')));
      dispatch(getCountryList(''));
      dispatch(getGETitleConfig());
      dispatch(getGEGenderConfig());
      dispatch(getFlaglist());
    }
    setShowErrorMessage(false);
  }, [open]);

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);

  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState(false);

  const [geTitle, setGeTitle] = useState(
    /** @type {import("./CreateGEContainer").GETitle | ""} */(null)
  );

  const [geGender, setGeGender] = useState(
    /** @type {import("./CreateGEContainer").GEGender | ""} */(null)
  );

  const [department, setDepartment] = useState(
    /** @type {import("./CreateGEContainer").Department | null} */(null)
  );

  const [institute, setInstitute] = useState(
    /** @type {import("./CreateGEContainer").Institute | null} */(null)
  );
  const [instituteError, setInstituteError] = useState(false);

  const [primaryEmail, setPrimaryEmail] = useState('');
  const [primaryEmailError, setPrimaryEmailError] = useState(false);
  const [priEmailSametoSecEmail, setPriEmailSametoSecEmail] = useState(false);

  const [secondaryEmail, setSecondaryEmail] = useState('');
  const [secondaryEmailError, setSecondaryEmailError] = useState(false);
  const [secEmailSametoPriEmail, setSecEmailSametoPriEmail] = useState(false);

  const [researchKeywords, setResearchKeywords] = useState(
    /** @type {import("./CreateGEContainer").ResearchKeyword[]} */[]
  );
  const [researchKeywordsError, setResearchKeywordsError] = useState(false);

  const [profileWebsites, setProfileWebsites] = useState(/**@type {ReadonlyArray<string>}*/([]));
  const [profileWebsitesError, setProfileWebsitesError] = useState(false);

  const [city, setCity] = useState('');

  const [countryOrRegion, setCountryOrRegion] = useState(
    /** @type {import("./CreateGEContainer").Country | null} */(null)
  );
  const [countryOrRegionError, setCountryOrRegionError] = useState(false);

  const [flags, setFlags] = useState([]);

  const [confirmOpen, setConfirmOpen] = useState(false);

  const checkDataValidation = () => {
    const dataError =
      firstNameError ||
      lastNameError ||
      instituteError ||
      primaryEmailError ||
      secondaryEmailError ||
      researchKeywordsError ||
      profileWebsitesError ||
      countryOrRegionError ||
      priEmailSametoSecEmail ||
      secEmailSametoPriEmail;

    // setShowErrorMessage(dataError);

    return !dataError;
  };

  const handleClickSave = () => {
    setShowErrorMessage(true);
    if (!checkDataValidation()) return;
    // setConfirmOpen(true);
    setShowErrorMessage(false);
    handleSave();
    dispatch({ type: 'CLOSE_SAVED_INPUT' });
    document.body.classList.remove('hide-scroll');
  };

  const handleCloseGePage = () => {
    handleCreateGEPageClose();
    document.body.classList.remove('hide-scroll');
  };

  const clearInput = () => {
    setFirstName('');
    setLastName('');
    setGeTitle(null);
    setDepartment(null);
    setInstitute(null);
    setPrimaryEmail('');
    setSecondaryEmail('');
    setResearchKeywords([]);
    setGeGender(null);
    setProfileWebsites([]);
    setCity('');
    setCountryOrRegion(null);
    setFlags([]);
  };

  const handleSave = () => {
    /** @type {import("./CreateGEContainer").GeData} */
    const newGeData = {
      firstName: firstName,
      lastName: lastName,
      title: geTitle?.value ?? '',
      department: department?.department || '',
      institute: institute?.institute || '',
      city: city,
      primaryEmail: primaryEmail,
      secondaryEmail: secondaryEmail,
      country: countryOrRegion?.name || '',
      researchKeywords: researchKeywords,
      gender: geGender?.value ?? '',
      // temporary method to solve server 500 problem
      // profileWebsite: profileWebsites,
      profileWebsite: profileWebsites.join('|'),
      researchGateId: '',
      orcidId: '',
      flags,
    };
    dispatch(CreateGE(newGeData, isLead));
    setConfirmOpen(false);
    // set all state of input to init
    handleCreateGEPageClose();
    clearInput();
  };
  const handleLeadGeSaveForLater = () => {
    const saveData = {
      firstName: firstName,
      lastName: lastName,
      title: geTitle?.value ?? '',
      department: department?.department || '',
      institute: institute?.institute || '',
      city: city,
      primaryEmail: primaryEmail,
      secondaryEmail: secondaryEmail,
      country: countryOrRegion?.name || '',
      researchKeywords: researchKeywords,
      gender: geGender?.value ?? '',
      // temporary method to solve server 500 problem
      // profileWebsite: profileWebsites,
      profileWebsite: profileWebsites,
      researchGateId: '',
      orcidId: '',
      flags,
    };
    dispatch({
      type: 'SET_LEAD_GE_SAVED_INPUT',
      payload: saveData,
    });
    // setsavedForLater(saveData);
    // setIsSavedInputAvailable(true);
    handleCloseGePage();
  };
  const handleCoGeSaveForLater = () => {
    const saveData = {
      firstName: firstName,
      lastName: lastName,
      title: geTitle?.value ?? '',
      department: department?.department || '',
      institute: institute?.institute || '',
      city: city,
      primaryEmail: primaryEmail,
      secondaryEmail: secondaryEmail,
      country: countryOrRegion?.name || '',
      researchKeywords: researchKeywords,
      gender: geGender?.value ?? '',
      // temporary method to solve server 500 problem
      // profileWebsite: profileWebsites,
      profileWebsite: profileWebsites,
      researchGateId: '',
      orcidId: '',
      flags,
    };

    dispatch({
      type: 'SET_CO_GE_SAVED_INPUT',
      payload: saveData,
    });
    // setsavedForLater(saveData);
    // setIsSavedInputAvailable(true);
    handleCloseGePage();
  };

  useEffect(() => {
    // if (!open) return;
    const result = createGEResult;

    /**
     * @param {string} editType
     */
    const makeToast = editType => {
      dispatch(setTextParam());
      dispatch(setEditType(editType));
      dispatch(openConfirm());
    };

    if (result === '') return;

    if (result === 'error') {
      makeToast('error');
    } else if (result === 'EmailExit') {
      makeToast('EmailExits');
    } else {
      const { page, pageSize, orderByType } = geOverviewData;
      dispatch(
        loadGEData(page, pageSize, orderByType.order, orderByType.orderBy, '')
      );
      makeToast('createGE');
      dispatch(openConfirm());
      // handleCreateGEPageClose();
    }

    dispatch({ type: CREATE_GE, data: '' });
  }, [createGEResult]);

  const isLeadGeSavedInputAvailable = useSelector(
    state => state.GeAdd.isLeadGeSavedInputAvailable
  );
  const leadGeSavedInput = useSelector(state => state.GeAdd.leadGeSavedInput);
  const isCoGeSavedInputAvailable = useSelector(
    state => state.GeAdd.isCoGeSavedInputAvailable
  );
  const coGeSavedInput = useSelector(state => state.GeAdd.coGeSavedInput);

  if (isLead === true) {
    useEffect(() => {
      if (isLeadGeSavedInputAvailable) {
        setFirstName(leadGeSavedInput.firstName);
        setLastName(leadGeSavedInput.lastName);
        setGeTitle({ value: leadGeSavedInput.title });
        setDepartment(
          departmentList.find(
            item => item.department === leadGeSavedInput.department
          )
        );
        if (
          instituteList.find(
            item => item.institute === leadGeSavedInput.institute
          ) === undefined
        )
          setInstitute(null);
        else {
          setInstitute(
            instituteList.find(
              item => item.institute === leadGeSavedInput.institute
            )
          );
        }

        setPrimaryEmail(leadGeSavedInput.primaryEmail);
        setSecondaryEmail(leadGeSavedInput.secondaryEmail);
        setResearchKeywords(leadGeSavedInput.researchKeywords);
        if (
          geGenderConfigDropdownList?.find(
            item => item.value === leadGeSavedInput.gender
          ) === undefined
        )
          setGeGender(null);
        else {
          setGeGender(
            geGenderConfigDropdownList?.find(
              item => item.value === leadGeSavedInput.gender
            )
          );
        }
        if (leadGeSavedInput.profileWebsite === undefined)
          setProfileWebsites([]);
        else {
          setProfileWebsites(leadGeSavedInput.profileWebsite);
        }

        setCity(leadGeSavedInput.city);

        if (
          countryList.find(item => item.name === leadGeSavedInput.country) ===
          undefined
        )
          setCountryOrRegion(null);
        else {
          setCountryOrRegion(
            countryList.find(item => item.name === leadGeSavedInput.country)
          );
        }

        setFlags(leadGeSavedInput.flags);
      }
    }, [isLeadGeSavedInputAvailable]);
  } else {
    useEffect(() => {
      if (isCoGeSavedInputAvailable) {
        setFirstName(coGeSavedInput.firstName);
        setLastName(coGeSavedInput.lastName);
        setGeTitle({ value: coGeSavedInput.title });
        setDepartment(
          departmentList.find(
            item => item.department === coGeSavedInput.department
          )
        );
        if (
          instituteList.find(
            item => item.institute === coGeSavedInput.institute
          ) === undefined
        )
          setInstitute(null);
        else {
          setInstitute(
            instituteList.find(
              item => item.institute === coGeSavedInput.institute
            )
          );
        }

        setPrimaryEmail(coGeSavedInput.primaryEmail);
        setSecondaryEmail(coGeSavedInput.secondaryEmail);
        setResearchKeywords(coGeSavedInput.researchKeywords);
        if (
          geGenderConfigDropdownList?.find(
            item => item.value === coGeSavedInput.gender
          ) === undefined
        )
          setGeGender(null);
        else {
          setGeGender(
            geGenderConfigDropdownList?.find(
              item => item.value === coGeSavedInput.gender
            )
          );
        }
        if (coGeSavedInput.profileWebsite === undefined) setProfileWebsites([]);
        else {
          setProfileWebsites(coGeSavedInput.profileWebsite);
        }

        setCity(coGeSavedInput.city);

        if (
          countryList.find(item => item.name === coGeSavedInput.country) ===
          undefined
        )
          setCountryOrRegion(null);
        else {
          setCountryOrRegion(
            countryList.find(item => item.name === coGeSavedInput.country)
          );
        }

        setFlags(coGeSavedInput.flags);
      }
    }, [isCoGeSavedInputAvailable]);
  }
  const [heightDifference, setHeightDefference] = useState(null);
  const [popperBottom, setPopperBottom] = useState(0);

  useEffect(() => {

    if (popperBottom && popperBottom >= window.innerHeight) {

      setHeightDefference(popperBottom - window.innerHeight);
    }
    if (!popperBottom) {
      setHeightDefference(0);
    }
  }, [popperBottom]);


  useEffect(() => {
    setSecEmailSametoPriEmail(false);
    setPriEmailSametoSecEmail(primaryEmail !== '' && secondaryEmail !== '' && primaryEmail === secondaryEmail);
  }, [primaryEmail]);

  useEffect(() => {
    setPriEmailSametoSecEmail(false);
    setSecEmailSametoPriEmail(primaryEmail !== '' && secondaryEmail !== '' && primaryEmail === secondaryEmail);
  }, [secondaryEmail]);

  return (
    <Box
      id='siPageFilterDrawerMainContentBox'
      data-selenium-id={`GEPage_Overview-anotherNewFilterDrawer-Overview-Content`}
      sx={drawer}
    >
      <Stack
        id='siPageFilterDrawerStackHeader'
        data-selenium-id={`GEPage_Overview-anotherNewFilterDrawer-Overview-Stack-Header`}
        direction='row'
        spacing={0}
        sx={stack_header}
      >
        <Box
          id='btnCloseSIFilter'
          data-selenium-id={`GEPage_Overview-anotherNewFilterDrawer-Overview-btnCloseSIFilter`}
        >
          <Button
            id='buttonSaveForlater'
            onClick={() => {
              if (isLead === true) handleLeadGeSaveForLater();
              else {
                handleCoGeSaveForLater();
              }
            }}
            sx={{
              borderRadius: '5px',
              height: '34px',
              border: '1px solid var(--primary-500, #0052CC)',
              color: '#0052CC',
              color: 'var(--primary-500, #0052CC)',
              textAlign: 'center',
              textTransform: 'none !importantintial',
            }}
          >
            <Box
              sx={{
                width: '14px',
                height: '14px',
                marginLeft: '4px',
                ...AllCentered,
              }}
            >
              <SaveForLater></SaveForLater>
            </Box>
            <Typography
              variant='body1'
              style={{
                textTransform: 'none',
                fontFamily: 'Inter',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '18px',
                paddingRight: '12px',
                marginTop: '8px',
                marginBottom: '8px',
                paddingLeft: '6px',
                // margin: '8px 12px 8px -10px',
              }}
            >
              Save for Later
            </Typography>
          </Button>
        </Box>

        <Box
          id='siPageFilterDrawerTitle'
          data-selenium-id={`GEPage_Overview-anotherNewFilterDrawer-Overview-Title`}
          sx={drawer_head_title}
        >
          Create New Guest Editor
        </Box>

        <StateButton
          titleName='Cancel'
          isPrimary={false}
          id='btnClearFilter'
          data-selenium-id={`GEPage_Overview-anotherNewFilterDrawer-Overview-btnClearFilter`}
          // sx={drawer_bottom_button_cancel}
          onClick={() => {
            handleCloseGePage();
            clearInput();
            if (isLead === true)
              dispatch({ type: 'CLOSE_LEAD_GE_SAVED_INPUT' });
            else {
              dispatch({ type: 'CLOSE_CO_GE_SAVED_INPUT' });
            }
          }}
          sx={{
            ...SmallBoldFont,
            color: '#0052CC',
            cursor: 'pointer',
            borderRadius: '4px',
            boxSizing: 'border-box',
            padding: '8px 12px',
            flexDirection: 'row',
            background: '#FFFFFF',
            width: '70px',
            height: '34px',
            border: '1px solid #0052CC',
            marginLeft: 'auto',
            marginRight: '10px',
            textAlign: 'center',
          }}
        ></StateButton>

        <StateButton
          titleName='Add'
          isPrimary={true}
          id='btnApplyFilter'
          data-selenium-id={`GEPage_Overview-anotherNewFilterDrawer-Overview-btnApplyFilter`}
          // sx={drawer_bottom_button_add}
          onClick={() => {
            handleClickSave();
            if (isLead === true)
              dispatch({ type: 'CLOSE_LEAD_GE_SAVED_INPUT' });
            else {
              dispatch({ type: 'CLOSE_CO_GE_SAVED_INPUT' });
            }
          }}
          sx={{
            ...SmallBoldFont,
            color: '#FFFFFF',
            cursor: 'pointer',
            padding: '8px 12px',
            flexDirection: 'row',
            background: '#0052CC',
            width: '70px',
            height: '34px',
            boxSizing: 'border-box',
            textAlign: 'center',
            borderRadius: '5px',
          }}
        ></StateButton>
      </Stack>

      <Divider
        id='siPageFilterDrivider'
        data-selenium-id={`GEPage_Overview-anotherNewFilterDrawer-Overview-Drivider`}
      />
      <GetPopperBottomContext.Provider value={{ setValue: setPopperBottom }}>
        <Stack height={'100%'}>
          <Box
            sx={{
              padding: '20px 36px 0 36px',
            }}
          >
            <Stack
              spacing={6.5}
              direction='row'
              id='CreateGEContainer'
              data-selenium-id='GEPage_Overview-CreateGE-CreateGEContainer-CreateGEGridContainer'
            >
              <Stack
                spacing={3}
                direction='column'
                sx={{ width: 'calc(50% - 26px)' }}
              >
                <ItemBlockInstence
                  id='CreateGePrimaryEmailInput'
                  label='Primary Email'
                  required={true}
                >
                  <CreateGEOnlineEmailVerifyTextField
                    id='CreateGePrimaryEmailInput'
                    data-selenium-id='GEPage_Overview-CreateGE-CreateGEContainer-CreateGEGridContainer-CreateGePrimaryEmailInput'
                    required
                    inputType='Primary Email'
                    value={primaryEmail}
                    clear={() => setPrimaryEmail('')}
                    setValue={setPrimaryEmail}
                    error={priEmailSametoSecEmail}
                    sameEmailError={priEmailSametoSecEmail}
                    showErrorMessage={showErrorMessage}
                    setError={setPrimaryEmailError}
                    checkEmailStateSelector={state => ({
                      isEmailMessage: state.GE.isEmailMessage,
                      isEmailMessageGet: state.GE.isEmailMessageGet,
                    })}
                    checkEmailAction={checkEmail}
                  />
                </ItemBlockInstence>
                <ItemBlockInstence
                  id='CreateGeFirstNameInput'
                  label='First Name'
                  required={true}
                >
                  <CreateGETextField
                    id='CreateGeFirstNameInput'
                    value={firstName}
                    required
                    clear={() => setFirstName('')}
                    setValue={setFirstName}
                    showErrorMessage={showErrorMessage}
                    setError={setFirstNameError}
                    data-selenium-id='GEPage_Overview-CreateGE-CreateGEContainer-CreateGEGridContainer-CreateGeFirstNameInput'
                  />
                </ItemBlockInstence>
                <ItemBlockInstence
                  id='CreateGeLastNameInput'
                  label='Last Name'
                  required={true}
                >
                  <CreateGETextField
                    id='CreateGeLastNameInput'
                    value={lastName}
                    required
                    clear={() => setLastName('')}
                    setValue={setLastName}
                    showErrorMessage={showErrorMessage}
                    setError={setLastNameError}
                    data-selenium-id='GEPage_Overview-CreateGE-CreateGEContainer-CreateGEGridContainer-CreateGeLastNameInput'
                  />
                </ItemBlockInstence>

                <ItemBlockInstence
                  id='CreateGeGenderSelect'
                  label='Gender'
                  required={false}
                >
                  <SinpleAutocomplete
                    id='CreateGeGenderSelect'
                    value={geGender}
                    options={geGenderConfigDropdownList || []}
                    getOptionLabel={option => option.value}
                    onChange={(event, value) => setGeGender(value)}
                    placeholder='Choose Gender'
                    isErr={false}
                    isEmpty={!geGender} //判断输入框的是否为空
                    defaultQuery={true}
                  />
                </ItemBlockInstence>
                <CreateGEAddItemAutocomplete
                  label='Research Keywords'
                  id='CreateGekeywordsForm'
                  data-selenium-id='GEPage_Overview-CreateGE-CreateGEContainer-CreateGEGridContainer-CreateGekeywordsForm'
                  showPopupIcon={false}
                  showClearIcon={true}
                  placeholder='Choose/Enter Keywords'
                  value={researchKeywords}
                  options={keywords === 'error' ? [] : keywords}
                  multiple={true}
                  onChange={value => setResearchKeywords(value)}
                  stringLabelKey='keyword'
                  loadOptionAction={getKeywords}
                  optionPlaceholderValue={{
                    id: 0,
                    keyword: 'qwerty-keyword-placeholder',
                  }}
                  // limitTags={2}
                  addNewItemManager={addNewKeywordManager}
                  required
                  showErrorMessage={showErrorMessage}
                  setError={setResearchKeywordsError}
                />
                <ItemBlockInstence
                  id='CreateGeTitleSelect'
                  label='Title'
                  required={false}
                >
                  <SinpleAutocomplete
                    id='CreateGeTitleSelect'
                    data-selenium-id='GEPage_Overview-CreateGE-CreateGEContainer-CreateGEGridContainer-CreateGeTitleSelect'
                    value={geTitle}
                    options={geTitleConfigDropdownList || []}
                    getOptionLabel={option => option.value}
                    onChange={(event, value) => setGeTitle(value)}
                    placeholder=''
                    isErr={false}
                    isEmpty={!geTitle} //判断输入框的是否为空
                    defaultQuery={true}
                    inputHeight={40}
                  />
                </ItemBlockInstence>
              </Stack>
              <Stack
                spacing={3}
                direction='column'
                sx={{ width: 'calc(50% - 26px)' }}
              >
                <ItemBlockInstence
                  id='CreateGeDepartmentInput'
                  label='Department'
                  required={false}
                >
                  <SinpleAutocomplete
                    id='CreateGeDepartmentInput'
                    value={department}
                    options={departmentList === 'error' ? [] : departmentList}
                    placeholder=''
                    isEmpty={!department}
                    loadOptionAction={getDepartmentList}
                    getOptionLabel={option => option.department}
                    onChange={(event, newValue) => {
                      newValue &&
                        (newValue.department = trimItemFilds(
                          newValue.department
                        ));
                      setDepartment(newValue);
                    }}
                    config={{ popupIcon: null }}
                    disableNewItem={false}
                    addNewItemManager={addNewDepartmentManager}
                  />
                </ItemBlockInstence>
                <ItemBlockInstence
                  id='CreateGeInstituteInput'
                  label='Institution'
                  required={true}
                  error={showErrorMessage && instituteError}
                  helperText={
                    showErrorMessage && instituteError
                      ? 'Please enter this field.'
                      : ''
                  }
                >
                  <SinpleAutocomplete
                    id='CreateGeInstituteInput'
                    value={institute}
                    options={instituteList === 'error' ? [] : instituteList}
                    placeholder=''
                    isErr={showErrorMessage && !institute}
                    isEmpty={!institute}
                    loadOptionAction={getInstituteList}
                    getOptionLabel={option => option.institute}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        newValue.institute = trimItemFilds(newValue.institute);
                      }
                      setInstitute(newValue);
                    }}
                    needKeyboardDelete={false}
                    config={{ popupIcon: null }}
                    disableNewItem={false}
                    addNewItemManager={addNewInstituteManager}
                    setError={setInstituteError}
                  />
                </ItemBlockInstence>
                <ItemBlockInstence
                  id='CreateGeCityInput'
                  label='City'
                  required={false}
                >
                  <CreateGETextField
                    id='CreateGeCityInput'
                    data-selenium-id='GEPage_Overview-CreateGE-CreateGEContainer-CreateGEGridContainer-CreateGeCityInput'
                    value={savedInput?.city || city}
                    clear={() => setCity('')}
                    setValue={setCity}
                  />
                </ItemBlockInstence>
                <ItemBlockInstence
                  id='CreateGeCountryInput'
                  label='Country/Region'
                  required={true}
                  error={showErrorMessage && countryOrRegionError}
                  helperText={
                    showErrorMessage && countryOrRegionError
                      ? 'Please enter this field.'
                      : ''
                  }
                >
                  <SinpleAutocomplete
                    id='CreateGeCountryInput'
                    value={countryOrRegion}
                    options={countryList === 'error' ? [] : countryList}
                    getOptionLabel={option => option.name}
                    onChange={(event, newValue) => setCountryOrRegion(newValue)}
                    loadOptionAction={getCountryList}
                    getOptionLabel={option => option.name}
                    placeholder='Choose/Enter Country/Region'
                    isErr={showErrorMessage && !countryOrRegion}
                    isEmpty={!countryOrRegion} //判断输入框的是否为空
                    defaultQuery={true}
                    defaultQueryMode={'startsWith'}
                    needKeyboardDelete={false}
                    setError={setCountryOrRegionError}
                    config={{ popupIcon: null }}
                  />
                </ItemBlockInstence>
                <ItemBlockInstence
                  id='Secondary Email'
                  label='Secondary Email'
                  required={false}
                >
                  <CreateGETextField
                    id='Secondary Email'
                    data-selenium-id='GEPage_Overview-CreateGE-CreateGEContainer-CreateGEGridContainer-CreateGeSecondaryEmailInput'
                    inputType='Secondary Email'
                    value={secondaryEmail}
                    error={secEmailSametoPriEmail}
                    sameEmailError={secEmailSametoPriEmail}
                    clear={() => setSecondaryEmail('')}
                    setValue={setSecondaryEmail}
                    showErrorMessage={showErrorMessage}
                    setError={setSecondaryEmailError}
                  />
                </ItemBlockInstence>
                <ItemBlockInstence
                  id='CreateGeProfileWebsites'
                  label='Profile Website(s)'
                  required={false}
                >
                  <CreateGETextField
                    id='Profile Website'
                    value={profileWebsites}
                    data-selenium-id='GEPage_Overview-CreateGE-CreateGEContainer-CreateGEGridContainer-CreateGeprofileWebsitesInput'
                    inputType='URL'
                    clear={() => setProfileWebsites([])}
                    setValue={(/**@type {string}*/value) => { setProfileWebsites([value]); }}
                    showErrorMessage={showErrorMessage}
                    setError={setProfileWebsitesError}
                  // options={[]}
                  // getOptionLabel={option => option}
                  // onValueChange={value => setProfileWebsites(value)}
                  // disableNewItem={true}
                  // dropDownListNumber={0}
                  // PopperComponent={() => <></>}
                  // showPopupIcon={false}
                  // freeSolo
                  // placeholder=''
                  />
                </ItemBlockInstence>
              </Stack>
            </Stack>
          </Box>
          <CommonDialog
            id='CreateGeConfirm'
            data-selenium-id='GEPage_Overview-CreateGE-CreateGEContainer-CreateGeConfirm'
            title={`Create guest editor ${lastName}, ${firstName}`}
            content='Are you sure you want to proceed ?'
            alertOpen={confirmOpen}
            handleClose={() => setConfirmOpen(false)}
            handleConfirm={handleSave}
          />
        </Stack>
      </GetPopperBottomContext.Provider>
      <Box sx={{ height: `${heightDifference}px` }} />
    </Box>
  );
}
export default CreateGEContainer;

import * as actions from '../actions/SIMT-SI/SpecialIssue/SIAction';
import * as catActions from '../actions/SIMT-SI/CatJournal/CATJournalAction';


const initState = {
  revenueModel: '',
  CE: [],
  Scam: [],
  JourList: [],
  moreJourList: [],
  GE: [],
  checkTitle: '',
  createGEResult: '',
  createSIResult: '',
  updateSIResult: '',
  transferSIResult: '',
  updateSIMessageResult: null,
  // SI table states
  page: 1,
  pageSize: 50,
  orderByType: {
    by: 'lastActionDate',
    order: 'desc',
  },
  siRequestNo: 0,
  siData: {
    result: [],
    count: 0,
  },
  exportStatus: null,
  // searchSI: "",

  // modal popup states
  searchOpen: false,
  filterOpen: false,
  // firstStageOpen: false
  numberError: false,
  savePDnumberError: false,
  progressNumber: '',
  detailInfo: {},
  tempDetailInfo: {},

  SIHistory: {
    count: 0,
    result: null,
  },
  LeadGEHistory: {
    count: 0,
    result: null,
  },
  COGEHistory: {
    count: 0,
    result: null,
  },
  EmailHistory: {
    count: 0,
    result: null,
  },
  SnowFlake: {
    numberOfArticlesSubmitted: null,
    numberOfArticlesAccepted: null,
    numberOfArticlesRejected: null,
    numberOfArticlesInProgress: null,
  },
  localSIRequestNo: 1, // 用于保存SI请求的id以避免先发送的请求却更晚收到相应

  UserColumnSaveResult: {
    SICode: true,
    JournalCode: true,
    JournalName: true,
    SIPublicationFormat: true, //CT-97 Special Issue Type 已更名为SI Publication Format
    SpecialIssueTitle: true,
    LeadGE: true,
    CoGE: true,
    Stage: true,
    HE: true,
    Group: true,
    JPM: true,
    SubjectGroup: true,
    SISource: true,
    IdeaDate: true,
    AcquiredDate: true,
    Deadline: true,
    'ExpectedAcceptance Date': true,
    'ExpectedPublication Date': true,
    DeadlinePassed: true,
    Closed: true,
    PaperCommissioning: true,
    Workflow: true,
    'PreviousHE ': true,
    LastActionDate: true,
    ArticlesRecruited: true,
    ArticlesSubmitted: true,
    ArticlesAccepted: true,
    ArticlesRejected: true,
    InProgress: true,
    Volume: true,
    Issue: true,
    ActualPublicationDate: true,
    OO: true,
    OA: true,
    OutputRate: true,
    CreateDate: true,
  },
  UserColumns: null,
  PublicationErrorFlag: false,
  MandatoryTable: {},
  clickOutOfDate: true,
  linkErrorType: 1,
  updateMarketInfoResult: '',
  updateMarketInfoMonitor: true,
  updatePromoteState: '',
  updatePromoteStateMonitor: true,
  currentDateCell: -1,
  siInprogress: 0,
  getPromoteLeadGEResult: [],
  getPromoteLeadGEResultMonitor: true,
  submissionJudgeValue: false,
  marketIssnCheck: true,
  currentRow: -1,
  newFilterOpen: false,
  workFlowList: [],
  siRevenueModelList: [],
  siSourceList: [],
  stageList: [],
  siCodeList: [],
  siCodeAllList: [],
  siCodeToSkip: '',
  exportSiList: [],
  selectedInfoMap: {},
  selectedListInfoLoading: false,
  //CM Related
  // ACTIVITY:{
  //  ActivityType:
  //  ActivityTitle:
  //  CostUSD:
  //  ActivityDate:
  //  ActivityDescription
  //  ActivityId
  // SICode
  // }
  CMActivities: {
    count: 0,
    content: [],
  },
  CMFlag: false,
  //CT5007 [M2][Marketing improvement] - AI recommended competitors
  MarketingAIOptions: [],
  MarketingAIOptionsLoading: false,
  MarketingAIOptionsError: false,
};

export default function SIReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.UPDATE_MARKETINFO:
      return {
        ...state,
        updateMarketInfoResult: data,
        updateMarketInfoMonitor: !state.updateMarketInfoMonitor,
      };
    case actions.SAVELATER_MARKETINFO:
      return {
        ...state,
        tempDetailInfo: data,
      };
    case actions.UPDATE_PROMOTE_STATE:
      return {
        ...state,
        updatePromoteState: data,
        updatePromoteStateMonitor: !state.updatePromoteStateMonitor,
      };
    case actions.GET_PROMOTE_LEADGE:
      return {
        ...state,
        getPromoteLeadGEResult: data,
        getPromoteLeadGEResultMonitor: !state.getPromoteLeadGEResultMonitor,
      };
    case actions.GET_CE:
      return {
        ...state,
        CE: data,
      };
    case actions.GET_SCAM:
      return {
        ...state,
        Scam: data,
      };
    case actions.GET_JOURNALLIST:
      return {
        ...state,
        JourList: data,
      };
    case actions.GET_MOREJOURNALLIST:
      return {
        ...state,
        moreJourList: data,
      };
    case actions.GET_GE:
      return {
        ...state,
        GE: data,
      };
    case actions.CREATE_GE:
      return {
        ...state,
        createGEResult: data,
      };
    case actions.CREATE_SI:
      return {
        ...state,
        createSIResult: data,
      };
    case actions.UPDATE_SI:
    case actions.Reactive_SI:
    case actions.SI_TITLE_EDIT:
    case actions.Edit_SubmissionOverview:
    case actions.Edit_PublicationDetails:
      return {
        ...state,
        updateSIResult: data,
      };
    case actions.TRANSFORM_SI:
      return {
        ...state,
        transferSIResult: data,
      };
    case actions.UPDATE_SI_MESSAGE:
      return {
        ...state,
        updateSIMessageResult: data,
      };
    case actions.GET_SPECIAL_ISSUE: {
      // 如果请求编号大于已返回请求的编号，才生效，否则直接忽略
      // console.log("reqNo", data.siRequestNo, state.siRequestNo)
      if (data.siRequestNo > state.siRequestNo) {
        return {
          ...state,
          siData: data,
          siRequestNo: data.siRequestNo,
          siCodeList: data.siCodeList,
        };
      } else {

        return state;
      }
    }
    case actions.GET_SPECIAL_ISSUE_BY_CODES_LOADING:
      return {
        ...state,
        selectedListInfoLoading: data,
      };
    case actions.GET_SPECIAL_ISSUE_BY_CODES:
      const map = {};
      for (let si of data) {
        map[si.siCode] = si;
      }
      return {
        ...state,
        selectedInfoMap: map,
        selectedListInfoLoading: false,
      };
    case actions.GET_SPECIAL_ISSUE_CODES: {
      return {
        ...state,
        siCodeAllList: data,
      };
    }
    case actions.CHANGE_PAGE:
      return {
        ...state,
        page: data,
      };
    case actions.CHANGE_PAGE_SIZE:
      return {
        ...state,
        pageSize: data,
      };
    case actions.NUMBER_ERROR:
      return {
        ...state,
        numberError: data,
      };
    case actions.SAVEPDNUMBER_ERROR:
      return {
        ...state,
        savePDnumberError: data,
      };
    case actions.PROGRESS_NUMBER:
      return {
        ...state,
        progressNumber: data,
      };
    case actions.SET_OPEN: {
      switch (data.which) {
        case 'search':
          return {
            ...state,
            searchOpen: data.newState,
          };
        case 'filter':
          return {
            ...state,
            filterOpen: data.newState,
          };
        case 'newFilter':
          return {
            ...state,
            newFilterOpen: data.newState,
          };
        default:
          return state;
      }
    }
    case actions.ORDER_BY:
      return {
        ...state,
        orderByType: data,
      };
    case actions.SI_CLEAR:
      return initState;
    case actions.SET_DETAILINFO:
      return {
        ...state,
        detailInfo: data,
      };
    case actions.GET_SIHISTORY:
      return {
        ...state,
        SIHistory: data,
      };
    case actions.GET_LeadGEHISTORY:
      return {
        ...state,
        LeadGEHistory: data,
      };
    case actions.GET_COGEHISTORY:
      return {
        ...state,
        COGEHistory: data,
      };
    case actions.GET_EMAILHISTORY:
      return {
        ...state,
        EmailHistory: data,
      };
    case actions.CHECK_TITLE:
      return {
        ...state,
        checkTitle: data,
      };
    case actions.EXPORT_SI_STATUS:
      return {
        ...state,
        exportStatus: data,
      };
    case actions.EXPORT_SI_LIST:
      return {
        ...state,
        exportSiList: data,
      };
    case actions.EXPORT_SI_ERROR:
      return {
        ...state,
        exportErrorCode: data,
      };
    case actions.GET_SNOWFLAKE_INFO: {
      if (data !== null) {
          return {
            ...state,
            SnowFlake: data,
          };
      }
      return state;
    }
    case actions.SAVE_COLUMNS: {
      return {
        ...state,
        UserColumns: data,
      };
    }
    case actions.GET_COLUMNS: {
      return {
        ...state,
        UserColumnSaveResult: data,
      };
    }
    case actions.SET_PUBLICATION_ERROR:
      return {
        ...state,
        PublicationErrorFlag: data,
      };
    case actions.MANDATORY_FIELD:
      return {
        ...state,
        MandatoryTable: data,
      };
    case actions.CLICK_OUT_OF_DATE:
      return {
        ...state,
        clickOutOfDate: true,
      };
    case actions.CLICK_INSIDE_DATE:
      return {
        ...state,
        clickOutOfDate: false,
      };
    case actions.LINK_ERROR:
      return {
        ...state,
        linkErrorType: data,
      };
    case actions.SET_DATECELL:
      return {
        ...state,
        currentDateCell: data,
      };
    case actions.SET_SI_INPROGRESS:
      return {
        ...state,
        siInprogress: data,
      };
    case actions.SET_CURRENT_ROW:
      return {
        ...state,
        currentRow: data,
      };
    case actions.SUBMISSION_JUDGE:
      return {
        ...state,
        submissionJudgeValue: data,
      };
    case actions.APPEND_SI_REQ_ID:
      return {
        ...state,
        localSIRequestNo: state.localSIRequestNo + 1,
      };
    case actions.MARKETISSN_CHECK:
      return {
        ...state,
        marketIssnCheck: data,
      };
    case actions.GET_WORKLISTFLOW:
      return {
        ...state,
        workFlowList: data,
      };
    case actions.GET_SIREVENUEMODEL:
      return {
        ...state,
        siRevenueModelList: data,
      };
    case actions.GET_SISCOURCE:
      return {
        ...state,
        siSourceList: data,
      };
    case actions.GET_STAGES:
      return {
        ...state,
        stageList: data,
      };
    /**
     * 存储要跳转的siCode
     */
    case actions.SET_SICODE_TO_JUMP:
      return {
        ...state,
        siCodeToSkip: data,
      };
    // CM Related Start
    case actions.GET_ALL_CHINA_MARKETING_ACTIVITY:
      return {
        ...state,
        CMActivities: {
          count: data.length,
          content: data.map(item => ({
            ActivityType: item.type,
            ActivityTitle: item.name,
            CostUSD: item.cost,
            ActivityDate: item.deliveryMonth,
            ActivityDescription: item.description,
            ActivityId: item.activityId,
            SICode: item.siCode,
          })),
        },
        CMFlag: false
      };
    case actions.ADD_CHINA_MARKETING_ACTIVITY:
      return {
        ...state,
        //TODO: Analyze request data
        CMFlag: true
      };
    case actions.UPDATE_CHINA_MARKETING_ACTIVITY:
      return {
        ...state,
        //TODO: Analyze request data
        CMFlag: true
      };
    case actions.DELETE_CHINA_MARKETING_ACTIVITY:
      return {
        ...state,
        //TODO: Analyze request data
        CMFlag: true
      };
    case catActions.GET_JOURNAL:
      return {
        ...state,
        JourList: data
      };
    //CT5007 [M2][Marketing improvement] - AI recommended competitors
    case actions.SET_MARKETING_AI_OPTIONS:
      return {
        ...state,
        MarketingAIOptions: data,
      };
    case actions.SET_MARKETING_AI_OPTIONS_LOADING:
      return {
        ...state,
        MarketingAIOptionsLoading: data,
      };
    case actions.SET_MARKETING_AI_OPTIONS_ERROR:
      return {
        ...state,
        MarketingAIOptionsError: data,
      };
    default:
      return state;
  }
}

import {
  LOADING,
  ERROR,
  VALID,
  NO_DATA,
  NO_SELECTION_FROM_DROPDOWN_MENU,
  NO_DISPLAYED_DATA,
} from '@/pages/Report/chartUtils/constants.js';

import { checkEmptyDataWithIndices } from '@/pages/Report/chartUtils';

export default function useGetDiagramStatusHook(
  chartData,
  filteredChartData,
  selectedCatagories,
  hasCategoryFilter,
  alertLabel,
  loading,
  error
) {
  let valid = VALID;
  let info = null;

  // console.log('==chartData=', chartData);
  // console.log('==filteredChartData=', filteredChartData);
  // console.log('==loading=', loading);
  // console.log('==error=', error);

  if (loading) {
    valid = LOADING;
  } else if (error) {
    valid = ERROR;
  } else if (!Array.isArray(chartData)) {
    valid = NO_DATA;
    info = alertLabel;
  } else if (checkEmptyDataWithIndices(chartData, [1, 2, 3, 4])) {
    // contains all 0's for all data rows
    valid = NO_DATA;
    info = alertLabel;
  } else if (
    hasCategoryFilter &&
    Array.isArray(selectedCatagories) &&
    selectedCatagories.length === 0
  ) {
    valid = NO_SELECTION_FROM_DROPDOWN_MENU;
    info = alertLabel;
  } else if (checkEmptyDataWithIndices(filteredChartData, [1, 2, 3, 4])) {
    // contains all 0's for selected data rows
    valid = NO_DISPLAYED_DATA;
    info = alertLabel;
  }

  return {
    valid,
    info,
  };
}

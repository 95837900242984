import { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import ArrowIconDefault from './assets/DefaultIcon.svg';

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const SelectorMenus = ({
  children,
  label,
  disabled,
  highlight,
  style,
  open = false,
  setOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <div>
      <Button
        disabled={disabled}
        id='demo-customized-button'
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={handleClick}
        endIcon={<ArrowIconDefault />}
        sx={{
          textTransform: 'unset',
          borderRadius: '3px',
          lineHeight: '20px',
          [`&, &:hover`]: {
            color: highlight ? '#113D95' : '#596A7C',
            border: `1px solid ${highlight ? '#4C81EB' : '#DFE4E8'}`,
            backgroundColor: highlight ? '#AEC6F6' : '#FFFFFF',
          },
          [`&.Mui-disabled `]: {
            color: '#BCC5CF',
            border: '1px solid #BCC5CF',
            backgroundColor: '#FFFFFF',
          },
          [`& .MuiButton-endIcon `]: {
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
          },
          [`& .MuiButton-endIcon svg path`]: {
            stroke: disabled ? '#32323242' : highlight ? '#113D95' : '#596A7C',
          },
        }}
      >
        {label}
      </Button>
      <StyledMenu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          ...style,
          maxHeight: '500px',
        }}
      >
        {children}
      </StyledMenu>
    </div>
  );
};

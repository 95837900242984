import React from 'react';
import IndeterminateInactiveCheckbox from '@/assets/IndeterminateInactiveCheckbox.svg';
import IndeterminateDefaultCheckbox from '@/assets/IndeterminateDefaultCheckbox.svg';
import IndeterminateHoverCheckbox from '@/assets/IndeterminateHoverCheckbox.svg';
import SelectedInactiveCheckbox from '@/assets/SelectedInactiveCheckbox.svg';
import SelectedDefaultCheckbox from '@/assets/SelectedDefaultCheckbox.svg';
import SelectedHoverCheckbox from '@/assets/SelectedHoverCheckbox.svg';
import UnselectedInactiveCheckbox from '@/assets/UnselectedInactiveCheckbox.svg';
import UnselectedDefaultCheckbox from '@/assets/UnselectedDefaultCheckbox.svg';
import UnselectedHoverCheckbox from '@/assets/UnselectedHoverCheckbox.svg';
import { Checkbox } from '@mui/material';
export default function SimpleCheckBox(props) {
  const { sx, ...other } = props;
  return (
    <Checkbox
      icon={<UnselectedDefaultCheckbox/>}
      checkedIcon={<SelectedDefaultCheckbox/>}
      indeterminateIcon={<IndeterminateDefaultCheckbox/>}
      disableRipple
      sx={{ 

        //NOT disable hover
        '&:not(.Mui-disabled):hover':{
          '& svg >*:nth-child(1)':{
            'stroke':'#596A7C',
          },
        },
        '&.Mui-checked:not(.Mui-disabled):hover,&.MuiCheckbox-indeterminate:not(.Mui-disabled):hover':{
          '& svg >*:nth-child(1)':{
            'stroke':'#113D95',
            'fill':'#113D95',
          },
        },
        //disable
        '&.Mui-disabled':{
          '& svg rect:nth-child(1)':{
            'stroke':'#DFE4E8',
            'fill':'#F6F7F8',
          },
        },
        '&.Mui-disabled.Mui-checked,&.Mui-disabled.MuiCheckbox-indeterminate':{
          '& svg rect:nth-child(1)':{
            'stroke':'#BCC5CF',
            'fill':'#BCC5CF',
          },
        },
        '&.Mui-disabled.MuiCheckbox-indeterminate':{
          '& svg rect:nth-child(1),& svg rect:nth-child(2)':{
            'stroke':'#BCC5CF',
            'fill':'#BCC5CF',
          },
        },

        ...sx }}
      {...other}
    />
  );
};

import { PropTypes } from 'prop-types';
import { Box, Typography } from '@mui/material';
import styles from './index.module.scss';
import ChartCard from './ChartCard';
import {PERMISSIONS} from "@/constant/permission";

const userPermissions = localStorage.getItem('userPermissions') || '';
const ChartGroup = ({
  groupTitle,
  levels,
  showGroupTitle,
  showChartTitle = false,
  onSelectChart,
  onSelectChartIndex,
  selectedChartIndex,
}) => {
  return (
    <>
      {showGroupTitle && (
        <Typography color='#828282' fontSize={24} fontWeight={700}>
          {groupTitle}
        </Typography>
      )}
      <Box className={styles['group-container']}>
        {levels &&
          levels.map(level => {
            const { title, img, chartIndex } = level;

            if (
              (title === 'CE' || title === 'CAT Group') &&
              !userPermissions.includes(PERMISSIONS.REPORT_READ_CE)
            )
              return;

            const isHighlighted = () => {
              if (onSelectChartIndex) {
                return selectedChartIndex === chartIndex;
              }
            };

            return (
              <div
                className={
                  styles['card-container'] +
                  ' ' +
                  (isHighlighted() ? styles['highlighted'] : '')
                }
                key={title}
                onClick={() => {
                  if (onSelectChartIndex) {
                    onSelectChartIndex(chartIndex);
                  } else {
                    onSelectChart(groupTitle, title);
                  }
                }}
              >
                <ChartCard title={groupTitle} subtitle={title} chartImg={img} />
                {showChartTitle && (
                  <Typography className={styles['chart-title']}>
                    {title}
                  </Typography>
                )}
              </div>
            );
          })}
      </Box>
    </>
  );
};

ChartGroup.propTypes = {
  groupTitle: PropTypes.string,
  levels: PropTypes.array.isRequired,
  showGroupTitle: PropTypes.bool,
  onSelectChart: PropTypes.func,
  selectedChartIndex: PropTypes.number,
  onSelectChartIndex: PropTypes.func,
};

export default ChartGroup;

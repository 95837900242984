import { axios_instance } from "@/utils/axios_instance";
import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";
import { fetchUnreadAlertsCount } from "@/actions/SIMT-User/Alert";
import { isTimeoutError } from "@/utils/commonUtils";
import {
    EDIT_EMAILMANUALDETAIL,
    EDIT_SIP_EMAIL_TEMPLATES,
    EDIT_SOLICITED_SIP_EMAIL_TEMPLATES,
    GET_CUSTOMIZE_EMAIL_TEMPLATES,
    GET_EMAIL_RED_DOTS,
    GET_EMAILTEMPLATES,
    GET_SIP_CUSTOMIZE_EMAIL_TEMPLATES,
    GET_SIP_EMAIL_TEMPLATES,
    GET_SOLICITED_SIP_CUSTOMIZE_EMAIL_TEMPLATES,
    GET_SOLICITED_SIP_EMAIL_TEMPLATES
} from "@/actions/SIMT-Email/EmailAction";
import { getUserId } from "@/utils/getUserId";
import getAuth from "@/utils/getAuth";

export function getBtoa(key) {
    return btoa(
        String.fromCharCode(
            ...new TextEncoder().encode(key)
        )
    );
}

export function getEmailRedDotList() {
    return async dispatch => {
        try {
            const res = await axios_instance.get(`/api/v1/user-service/users/${getUserId()}/email-template-red-dots`, {
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.data.code === 200) {
                dispatch({ type: GET_EMAIL_RED_DOTS, data: res.data.data });
            } else {
                dispatch({ type: GET_EMAIL_RED_DOTS, data: [] });
                dispatch(
                    setSnackbarMessageAndOpen('email.redDotLoadError', {}, SEVERITIES.error)
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen('email.redDotLoadError', {}, SEVERITIES.error)
            );
        }
    };
}

export function removeEmailRedDot(templateId) {
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/users/${getUserId()}/email-template-red-dots/${templateId}`;
            const res = await axios_instance.delete(
                url,
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                    params: {
                        templateId,
                    },
                }
            );
            if (res.status === 204) {
                // dispatch({ type: REMOVE_EMAIL_RED_DOT, data: templateId });
                dispatch(getEmailRedDotList());
            }
        } catch (err) {
        }
    };
}

// 获取 Email Templates 的主要内容
export function getEmailTemplates() {
    return async dispatch => {
        try {
            const res = await axios_instance.get('/api/v1/user-service/email-template', {
                headers: {
                    authorization: getAuth(),
                },
                params: {
                    type: "SI email",
                },
            });
            if (res.data.code === 200) {
                dispatch({ type: GET_EMAILTEMPLATES, data: res.data.data });
            } else {
                dispatch({ type: GET_EMAILTEMPLATES, data: null });
                dispatch(
                    setSnackbarMessageAndOpen('email.loadError', {}, SEVERITIES.error)
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen('email.loadError', {}, SEVERITIES.error)
            );
        }
    };
}

export function getCustomizeEmailTemplates() {
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                `/api/v1/user-service/users/${getUserId()}/email-templates`,
                {
                    headers: {
                        authorization: getAuth(),
                    },
                    params: {
                        type: "SI email",
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: GET_CUSTOMIZE_EMAIL_TEMPLATES, data: res.data.data });
            } else {
                dispatch({ type: GET_CUSTOMIZE_EMAIL_TEMPLATES, data: null });
                dispatch(
                    setSnackbarMessageAndOpen('email.loadError', {}, SEVERITIES.error)
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen('email.loadError', {}, SEVERITIES.error)
            );
        }
    };
}

// editManual的 Action
export function editEmailManual(emailManualDetail) {
    //emailManualDetail 是传入整个修改好的具体对象 dispatch 是主动发送 修改结果 reducer是描述dispatch action后 state的修改
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/email-template/${emailManualDetail.id}`;
            const res = await axios_instance.put(
                url,
                {
                    editSiEmailTemplateDto: {
                        ...emailManualDetail,
                        content:
                            getBtoa(emailManualDetail.content) ?? null,
                        subject:
                            getBtoa(emailManualDetail.subject) ?? null,
                        contentWithCss: null,
                        copy: '',
                        from: '',
                        to: '',
                        bcc: '',
                        recipients: '',
                    }
                },
                {
                    headers: {
                        authorization: getAuth(),
                    },
                    params: {
                        type: "SI Email",
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: EDIT_EMAILMANUALDETAIL, data: 'Ok' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'Successfully update system template!',
                        {},
                        SEVERITIES.success,
                        null,
                        null,
                        null,
                        'email.updateSucc',
                        // {
                        //   title: 'Successfully update system template!',
                        // },
                    )
                );
                dispatch(getEmailTemplates());
                dispatch(fetchUnreadAlertsCount());
            } else {
                dispatch({ type: EDIT_EMAILMANUALDETAIL, data: 'error' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateError',
                        {
                            value: res.data.message,
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({ type: EDIT_EMAILMANUALDETAIL, data: 'error' });
            if (isTimeoutError(err)) {
                dispatch(
                    setSnackbarMessageAndOpen('email.updateTimeout', {}, SEVERITIES.error)
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateError',
                        {
                            value: err.data.error,
                        },
                        SEVERITIES.error
                    )
                );
            }
        }
    };
}

export function editUserEmailManual(emailManualDetail) {
    //emailManualDetail 是传入整个修改好的具体对象 dispatch 是主动发送 修改结果 reducer是描述dispatch action后 state的修改
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/users/${getUserId()}/email-templates/${emailManualDetail.id}`;
            const res = await axios_instance.put(
                url,
                {
                    customizedSiEmailTemplateDto: {
                        ...emailManualDetail,
                        content: getBtoa(emailManualDetail.content),
                        subject: getBtoa(emailManualDetail.subject),
                        contentWithCss: null,

                        copy: '',
                        from: '',
                        to: '',
                        bcc: '',
                        recipients: '',
                    }
                },
                {
                    headers: {
                        authorization: getAuth(),
                    },
                    params: {
                        type: "SI email",
                    }
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: EDIT_EMAILMANUALDETAIL, data: 'Ok' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.customUpdateSuccessTitle',
                        {},
                        SEVERITIES.success,
                        null,
                        null,
                        {
                            title: 'Successfully save customized template!',
                        },
                        'email.customUpdateSuccessContent'
                    )
                );
                dispatch(getEmailTemplates());
                dispatch(getCustomizeEmailTemplates());
            } else {
                dispatch({ type: EDIT_EMAILMANUALDETAIL, data: 'error' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateError',
                        {
                            value: res.data.message,
                        },
                        SEVERITIES.error
                    )
                );
                return false;
            }
        } catch (err) {

            dispatch({ type: EDIT_EMAILMANUALDETAIL, data: 'error' });
            if (isTimeoutError(err)) {
                dispatch(
                    setSnackbarMessageAndOpen('email.updateTimeout', {}, SEVERITIES.error)
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateError',
                        {
                            value: err.data.error,
                        },
                        SEVERITIES.error
                    )
                );
            }
        }
    };
}

// 获取SIP Email Templates 的主要内容
export function getSIPEmailTemplates(api = '/api/v1/user-service/email-template?type=UnSolicited SIP email', target = GET_SIP_EMAIL_TEMPLATES) {
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                api,
                {
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: target, data: res.data.data });
            } else {
                dispatch({ type: target, data: null });
                dispatch(
                    setSnackbarMessageAndOpen('email.loadError', {}, SEVERITIES.error)
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen('email.loadError', {}, SEVERITIES.error)
            );
        }
    };
}

export function getSolicitedSIPEmailTemplates() {
    return getSIPEmailTemplates('/api/v1/user-service/email-template?type=Solicited SIP email', GET_SOLICITED_SIP_EMAIL_TEMPLATES);
}

export function getSIPCustomizeEmailTemplates(api = `/api/v1/user-service/users/${getUserId()}/email-templates`, target = GET_SIP_CUSTOMIZE_EMAIL_TEMPLATES, type = "UnSolicited SIP email") {
    return async dispatch => {
        try {
            const res = await axios_instance.get(
                api,
                {
                    headers: {
                        authorization: getAuth(),
                    },
                    params: {
                        type,
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({
                    type: target,
                    data: res.data.data,
                });
            } else {
                dispatch({ type: target, data: null });
                dispatch(
                    setSnackbarMessageAndOpen('email.loadError', {}, SEVERITIES.error)
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen('email.loadError', {}, SEVERITIES.error)
            );
        }
    };
}

export function getSolicitedSIPCustomizeEmailTemplates() {
    return getSIPCustomizeEmailTemplates(`/api/v1/user-service/users/${getUserId()}/email-templates`, GET_SOLICITED_SIP_CUSTOMIZE_EMAIL_TEMPLATES, "Solicited SIP email");
}

// editSIPEmailTemplate Action
export function editSIPEmailTemplate(id, block, type, subject, content) {
    //editSIPEmailTemplate 是传入整个修改好的具体对象 dispatch 是主动发送 修改结果 reducer是描述dispatch action后 state的修改
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/email-template/${id}`;
            const res = await axios_instance.put(
                url,
                {
                    editSipEmailTemplateDto: {
                        block,
                        type,
                        subject: getBtoa(subject),
                        content: getBtoa(content),
                        contentWithCss: null,
                    }
                },
                {
                    headers: {
                        authorization: getAuth(),
                    },
                    params: {
                        type: "UnSolicited SIP email",
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: EDIT_SIP_EMAIL_TEMPLATES, data: 'Ok' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'Successfully update system template!',
                        {},
                        SEVERITIES.success,
                        null,
                        null,
                        null,
                        'email.updateSucc',
                    )
                );
                dispatch(getSIPEmailTemplates());
                dispatch(fetchUnreadAlertsCount());
            } else {
                dispatch({ type: EDIT_SIP_EMAIL_TEMPLATES, data: 'error' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateError',
                        {
                            value: res.data.message,
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({ type: EDIT_SIP_EMAIL_TEMPLATES, data: 'error' });
            if (isTimeoutError(err)) {
                dispatch(
                    setSnackbarMessageAndOpen('email.updateTimeout', {}, SEVERITIES.error)
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateError',
                        {
                            value: err.data.error,
                        },
                        SEVERITIES.error
                    )
                );
            }
        }
    };
}

export function editUserSIPEmailTemplate(id, block, type, subject, content, name) {
    //editSIPEmailTemplate 是传入整个修改好的具体对象 dispatch 是主动发送 修改结果 reducer是描述dispatch action后 state的修改
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/users/${getUserId()}/email-templates/${id}`;
            const res = await axios_instance.put(
                url,
                {
                    editSipEmailTemplateDto: {
                        block,
                        type,
                        subject: getBtoa(subject),
                        content: getBtoa(content),
                        contentWithCss: null,
                        name,
                    }
                },
                {
                    headers: {
                        authorization: getAuth(),
                    },
                    params: {
                        type: "UnSolicited SIP email",
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: EDIT_SIP_EMAIL_TEMPLATES, data: 'Ok' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateSuccCustomize',
                        {},
                        SEVERITIES.success,
                        null,
                        null,
                        {
                            title: 'Successfully save customized template!',
                        }
                    )
                );
                dispatch(getSIPEmailTemplates());
                dispatch(getSIPCustomizeEmailTemplates());
            } else {
                dispatch({ type: EDIT_SIP_EMAIL_TEMPLATES, data: 'error' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateError',
                        {
                            value: res.data.message,
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({ type: EDIT_SIP_EMAIL_TEMPLATES, data: 'error' });
            if (isTimeoutError(err)) {
                dispatch(
                    setSnackbarMessageAndOpen('email.updateTimeout', {}, SEVERITIES.error)
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateError',
                        {
                            value: err.data.error,
                        },
                        SEVERITIES.error
                    )
                );
            }
        }
    };
}

export function editSolicitedSIPEmailTemplate(id, block, type, subject, content) {
    //editSIPEmailTemplate 是传入整个修改好的具体对象 dispatch 是主动发送 修改结果 reducer是描述dispatch action后 state的修改
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/email-template/${id}`;
            const res = await axios_instance.put(
                url,
                {
                    editSipEmailTemplateDto: {
                        block,
                        type,
                        subject: getBtoa(subject),
                        content: getBtoa(content),
                        contentWithCss: null,
                    }
                },
                {
                    headers: {
                        authorization: getAuth(),
                    },
                    params: {
                        type: "Solicited SIP email",
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: EDIT_SOLICITED_SIP_EMAIL_TEMPLATES, data: 'Ok' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'Successfully update system template!',
                        {},
                        SEVERITIES.success,
                        null,
                        null,
                        null,
                        'email.updateSucc',
                    )
                );
                dispatch(getSolicitedSIPEmailTemplates());
                dispatch(fetchUnreadAlertsCount());
            } else {
                dispatch({ type: EDIT_SOLICITED_SIP_EMAIL_TEMPLATES, data: 'error' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateError',
                        {
                            value: res.data.message,
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({ type: EDIT_SOLICITED_SIP_EMAIL_TEMPLATES, data: 'error' });
            if (isTimeoutError(err)) {
                dispatch(
                    setSnackbarMessageAndOpen('email.updateTimeout', {}, SEVERITIES.error)
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateError',
                        {
                            value: err.data.error,
                        },
                        SEVERITIES.error
                    )
                );
            }
        }
    };
}

export function editUserSolicitedSIPEmailTemplate(id, block, type, subject, content, name) {
    //editSIPEmailTemplate 是传入整个修改好的具体对象 dispatch 是主动发送 修改结果 reducer是描述dispatch action后 state的修改
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/users/${getUserId()}/email-templates/${id}`;
            const res = await axios_instance.put(
                url,
                {
                    editSipEmailTemplateDto: {
                        block,
                        type,
                        subject: getBtoa(subject),
                        content: getBtoa(content),
                        contentWithCss: null,
                        name,
                    }
                },
                {
                    headers: {
                        authorization: getAuth(),
                    },
                    params: {
                        type: "Solicited SIP email",
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: EDIT_SOLICITED_SIP_EMAIL_TEMPLATES, data: 'Ok' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateSuccCustomize',
                        {},
                        SEVERITIES.success,
                        null,
                        null,
                        {
                            title: 'Successfully save customized template!',
                        }
                    )
                );
                dispatch(getSolicitedSIPEmailTemplates());
                dispatch(getSolicitedSIPCustomizeEmailTemplates());
            } else {
                dispatch({ type: EDIT_SOLICITED_SIP_EMAIL_TEMPLATES, data: 'error' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateError',
                        {
                            value: res.data.message,
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({ type: EDIT_SOLICITED_SIP_EMAIL_TEMPLATES, data: 'error' });
            if (isTimeoutError(err)) {
                dispatch(
                    setSnackbarMessageAndOpen('email.updateTimeout', {}, SEVERITIES.error)
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateError',
                        {
                            value: err.data.error,
                        },
                        SEVERITIES.error
                    )
                );
            }
        }
    };
}

export function removeUserEmailManual(templateId, templateType = 'SI') {
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/users/${getUserId()}/email-templates/${templateId}`;
            const res = await axios_instance.delete(
                url,
                {
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: EDIT_EMAILMANUALDETAIL, data: 'Ok' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.customResetSuccessTitle',
                        {},
                        SEVERITIES.success,
                        undefined,
                        undefined,
                        undefined,
                        'email.customResetSuccessContent'
                    )
                );
                switch (templateType) {
                    case 'SI':
                        dispatch(getCustomizeEmailTemplates());
                        break;
                    case 'SSIP':
                        dispatch(getSolicitedSIPCustomizeEmailTemplates());
                        break;
                    case 'USIP':
                        dispatch(getSIPCustomizeEmailTemplates());
                        break;
                }
                return true;
            } else {
                dispatch({ type: EDIT_EMAILMANUALDETAIL, data: 'error' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateError',
                        {
                            value: res.data.message,
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({ type: EDIT_EMAILMANUALDETAIL, data: 'error' });
            if (isTimeoutError(err)) {
                dispatch(
                    setSnackbarMessageAndOpen('email.updateTimeout', {}, SEVERITIES.error)
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateError',
                        {
                            value: err.data.error,
                        },
                        SEVERITIES.error
                    )
                );
            }
        }
    };
}

// editEmailTemplate Action
export function editEmailTemplate(editEmailTemplateDetail) {
    //editEmailTemplateDetail 是传入某个修改好的具体对象 dispatch 是主动发送 修改结果 reducer是描述dispatch action后 state的修改
    return async dispatch => {
        try {
            const url = '/system/email/editEmailTemplate';
            const res = await axios_instance.post(
                url,
                {
                    //出于dev环境限制需要，转码为base64
                    ...editEmailTemplateDetail,
                    content:
                        getBtoa(editEmailTemplateDetail.content) ?? null,
                    subject:
                        getBtoa(editEmailTemplateDetail.subject) ?? null,

                    contentWithCss:
                        getBtoa(editEmailTemplateDetail.contentWithCss) ?? null, // 如果没有值，改为null，否则可能缺失一个字段

                    copy: '',
                    from: '',
                    to: '',
                    bcc: '',
                    recipients: '',
                },
                {
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: EDIT_EMAILMANUALDETAIL, data: 'Ok' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'Successfully update system template!',
                        {},
                        SEVERITIES.success,
                        null,
                        null,
                        null,
                        'email.updateSucc',
                    )
                );
            } else {
                dispatch({ type: EDIT_EMAILMANUALDETAIL, data: 'error' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateError',
                        {
                            value: res.data.message,
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({ type: EDIT_EMAILMANUALDETAIL, data: 'error' });
            if (isTimeoutError(err)) {
                dispatch(
                    setSnackbarMessageAndOpen('email.updateTimeout', {}, SEVERITIES.error)
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'email.updateError',
                        {
                            value: err.data.message,
                        },
                        SEVERITIES.error
                    )
                );
            }
        }
    };
}
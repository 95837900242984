const monthList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
// 日期对照月份表
const monthAsDay = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; //月份

// 星期列表
export const weekList = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
export function formatMonth(month) {
  return monthList[month];
}

export function getDateObj(dateStr) {
  let newDate;
  if (dateStr instanceof Date){
    newDate = dateStr;
  } else{
    if (dateStr === '' || dateStr==='Invalid Date') {
      return null;
    }
    dateStr = dateStr.replace(/\-/g, '/');
    newDate = new Date(dateStr);
  }

  return {
    year: newDate.getFullYear(),
    month: newDate.getMonth() + 1,
    day: newDate.getDate(),
    timestamp: newDate.getTime(),
  };
}

export function getDateStr(date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  let yearLabel = year.toString();
  let monthLabel = month < 10 ? `0${month}` : month.toString();
  let dayLabel = day < 10 ? `0${day}` : day.toString();
  return `${yearLabel}-${monthLabel}-${dayLabel}`;
}

export function getNewParams(time) {
  //兼容ios,ios浏览器中格式为2016/05/31 08:00

  time = time === 'Invalid Date' ? '' :time;
  time = time.replace(/\-/g, '/');
  let newDate = time !== '' ? new Date(time) : new Date();

  let year = newDate.getFullYear();
  let month = newDate.getMonth() + 1;
  let day = newDate.getDate();

  let yearLabel = year.toString();
  let monthLabel = month < 10 ? `0${month}` : month.toString();
  let dayLabel = day < 10 ? `0${day}` : day.toString();

  let defaultParam = {
    year: year,
    month: month,
    day: day,

    yearLabel: yearLabel,
    monthLabel: monthLabel,
    dayLabel: dayLabel,

    value: `${yearLabel}-${monthLabel}-${dayLabel}`,
  };
  return defaultParam;
}

const compareMaxDateAndMinDate = (maxDate, minDate) => {
  if (maxDate.year < minDate.year) {
    return true;
  }
  if (maxDate.year === minDate.year) {
    if (maxDate.month < minDate.month) {
      return true;
    }
    if (maxDate.month === minDate.month && maxDate.day < minDate.day) {
      return true;
    }
  }
  return false;
};

export function getYearList(maxDate, minDate) {
  let list = [];

  let newYear = new Date().getFullYear();
  let maxYear = newYear + 4;

  let newMaxDate = {
    year: maxDate?.year ?? maxYear,
    month: maxDate?.month ?? 12,
    day: maxDate?.day ?? 31,
  };

  let newMinDate = {
    year: minDate?.year ?? 1970,
    month: minDate?.month ?? 1,
    day: minDate?.day ?? 1,
  };

  let isErrorData = compareMaxDateAndMinDate(newMaxDate, newMinDate);

  for (let i = 1970; i <= maxYear; i++) {
    list.push({
      value: i,
      label: `${i}`,
      disabled: isErrorData || checkDisabledYear(i, newMaxDate, newMinDate),
    });
  }
  return list;
}

function checkDisabledYear(currentYear, maxDate, minDate) {
  if (currentYear < minDate.year || currentYear > maxDate.year) {
    return true;
  }
  if (maxDate.year === minDate.year) {
    //这说明当前的年份即是最小年也是最大年，此时我们只需要计算最大年和最小年直接的时间差，就可以判断该年是否是被禁用的
    return maxDate.month - minDate.month + (maxDate.day - minDate.day) === 0;
  }
  if (currentYear === maxDate.year) {
    return maxDate.month === 1 && maxDate.day === 1;
  }
  if (currentYear === minDate.year) {
    return minDate.month === 12 && minDate.day === 31;
  }
  return false;
}

// 月份
export function getMonthList(year, limit, maxDate, minDate) {
  let list = [];

  let newYear = new Date().getFullYear();
  let maxYear = newYear + 4;

  let newMaxDate = {
    year: maxDate?.year ?? maxYear + 1,
    month: maxDate?.month ?? 13,
    day: maxDate?.day ?? 32,
  };

  let newMinDate = {
    year: minDate?.year ?? 1969,
    month: minDate?.month ?? 0,
    day: minDate?.day ?? 0,
  };

  let isErrorData = compareMaxDateAndMinDate(newMaxDate, newMinDate);
  let isYearDisabled =
    isErrorData || checkDisabledYear(year, newMaxDate, newMinDate);

  for (let i = 1; i < 13; i++) {
    list.push({
      value: i,
      label: `${formatMonth(i - 1)}`,
      disabled:
        isYearDisabled || checkDisabledMonth(i, year, newMaxDate, newMinDate),
    });
  }
  return list;
}

const calcMaxDay = (year, month) => {
  let max = year % 4 === 0 && month === 2 ? 1 : 0; // 闰年判定
  return monthAsDay[month - 1] + max; // 定位月份
};

// 月-禁用判定
function checkDisabledMonth(currentMonth, currentYear, maxDate, minDate) {
  if (minDate.year === maxDate.year) {
    if (currentMonth < minDate.month || currentMonth > maxDate.month) {
      return true;
    }
    if (minDate.month === maxDate.month) {
      return maxDate.day - minDate.day === 0;
    }
    if (currentMonth === minDate.month) {
      return minDate.day === calcMaxDay(currentYear.currentMonth);
    }
    if (currentMonth === maxDate.month) {
      return maxDate.day === 1;
    }
  }
  if (currentYear === minDate.year) {
    if (currentMonth < minDate.month) {
      return true;
    } else if (currentMonth === minDate.month) {
      return minDate.day === calcMaxDay(currentYear,currentMonth);
    }
  }
  if (currentYear === maxDate.year) {
    if (currentMonth > maxDate.month) {
      return true;
    } else if (currentMonth === maxDate.month) {
      return maxDate.day === 1;
    }
  }
  return false;
}



// 日-数据列表生成type:0当前月份，1上一月，2下一月
export function getDayList(year, month, type, limit, maxDate, minDate) {
  let list = [];

  let max = year % 4 === 0 && month === 2 ? 1 : 0; // 闰年判定
  max = monthAsDay[month - 1] + max; // 定位月份

  let idxMo = checkWeek(year, month, 1).value; //获得本月1号的日期

  for (let i = 0; i < idxMo - 1; i++) {
    list.push({
      label: '',
      value: -1,
      disabled: false,
    });
  }

  for (let day = 1; day <= max; day++) {
    let toDay = new Date(`${year}-${month}-${day}`);
    let toDayTimeStamp = toDay.getTime();
    list.push({
      label: day.toString(),
      value: day,
      disabled:
        (minDate?.timestamp &&
          toDayTimeStamp <=
            minDate?.timestamp) ||
        (maxDate?.timestamp &&
          toDayTimeStamp >= maxDate.timestamp),
    });
  }

  return list;
}
// 日-数据按周补充

// 日-禁用判定
export function checkDisabled(prevYear, prevMonth, prevDay) {
  const prevTime = new Date(`${prevYear}/${prevMonth}/${prevDay}`).getTime();
  const newDate = new Date();
  const year = newDate.getFullYear();
  let month = newDate.getMonth() + 1;
  let day = newDate.getDate();
  const newTime = new Date(`${year}/${month}/${day}`).getTime();
  return prevTime < newTime;
}

// 周期-判定星期几
function checkWeek(year, month, day) {
  let weekday = [
    '星期日',
    '星期一',
    '星期二',
    '星期三',
    '星期四',
    '星期五',
    '星期六',
  ];
  let newDate = new Date(`${year}/${month}/${day}`);
  let mark = newDate.getDay();

  return {
    value: mark === 0 ? 7 : mark,
    label: weekday[mark],
  };
}

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import {NewDesignedMain} from '../../../components/Main';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import {Box} from '@mui/system';
import {useDispatch, useSelector} from 'react-redux';
import SIPipeline from '../SIPipeline';
import AcquiredAndActiveSi from '../AcquiredAndActiveSi';
import Targets from '../Targets';
import Articles from '../Articles';
import {switchTab} from '@/actions/SIMT-Report/Report/ReportAction';
import Journal from '../Journal';
import {ReportTabParent} from '../ReportSpecificComponents/ReportTabComponents';
import {setNavigationState} from '../../../actions/SideBarAction';
import { useEffect } from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import {ShowForPermission} from "@/components/PermissionControl/ShowForPermission";
import {getCutOffDate} from "@/actions/SIMT-Report/Report/Config";
import {PERMISSIONS} from "@/constant/permission";
function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`reportTabpanel${index}`}
      data-selenium-id={`Report-Overview-ReportTabpanel-${index}`}
      aria-labelledby={`reportTab${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            paddingTop: '8px',
            paddingDown: '8px',
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `reportTab${index}`,
    'aria-controls': `reportTabpanel${index}`,
  };
}

const REPORT_SIDEBAR_INDEX_MAPPING = {
  siPipeline: 12,
  acquiredAndActiveSI: 13,
  articles: 14,
  journal: 15,
  targets: 16,
};

const REPORT_BASE_URL = '/simt/auth/reportRelated/report';

export function ReportPage(_props) {
  let { parentTab, childTab } = useParams();

  let [searchParams, setSearchParams] = useSearchParams();

  const sideBarIndex = REPORT_SIDEBAR_INDEX_MAPPING[parentTab];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userPermissions = localStorage.getItem('userPermissions');
  const selector = state => {
    return {
      navigationState: state.SideBar.navigationState,
      open: state.UserManage.siderOpen, //侧边栏开关
      reportIndex: state.SideBar.reportIndex,
      cutOffDate: state.Report.cutOffDate,
    };
  };

  const { navigationState, open, reportIndex, cutOffDate } =
    useSelector(selector);

  // if cutOffDate is null, get it from backend
  useEffect(() => {
    if (cutOffDate === null ) {
      dispatch(getCutOffDate());
    }
  }, [cutOffDate]);

  useEffect(() => {
    const newDetail = navigationState.detailsList.map((item, index1) => {
      item.active = index1 === sideBarIndex;
      item.key = sideBarIndex;
      return item;
    });

    dispatch(
      setNavigationState({
        detailsList: newDetail,
        activeIndex: sideBarIndex,
      })
    );
  }, [sideBarIndex]);

  const handleChange = (_event, tabIndex) => {
    // HIT redux action
    // dispatch(switchTab(newValue));

    // console.log('==tabIndex==', tabIndex);
    // console.log('==reportIndex==', reportIndex);

    const reportName = Object.keys(REPORT_SIDEBAR_INDEX_MAPPING).find(
      key => REPORT_SIDEBAR_INDEX_MAPPING[key] === tabIndex + reportIndex
    );

    // Apply current showTable status when navigate to another 1st level report tab
    let showTable = searchParams.get('showTable');
    if (showTable == null) {
      showTable = 'true';
    }

    navigate(`${REPORT_BASE_URL}/${reportName}?showTable=${showTable}`);
    // what if give it the first childTab name when click on menu? -- improvement
  };

  const tabIndex = sideBarIndex - reportIndex;

  const modifiedTheme = theme =>
    createTheme({
      ...theme,
      components: {
        ...theme.components,
        MuiTableContainer: {
          ...theme.components.MuiTableContainer,
          styleOverrides: {
            root: {
              borderRadius: '8px',
              border: '1px solid var(--gray-200, #DFE4E8)',
              background: ' #FFF',
            },
          },
        },
        MuiTable: {
          ...theme.components.MuiTable,
          styleOverrides: {
            root: {
              minWidth: '100%',
            },
          },
        },
        MuiTableRow: {
          ...theme.components.MuiTableRow,
          styleOverrides: {
            root: {
              '&:hover td': {
                backgroundColor: '#E8EDFB',
              },
            },
          },
        },
        MuiTableCell: {
          ...theme.components.MuiTableCell,
          styleOverrides: {
            root: {
              border: '1px 0px solid #DFE4E8',
            },
            head: {
              padding: '12px 24px',
              fontSize: '12px',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '18px',
              letterSpacing: '0.96px',
              textTransform: 'uppercase',
              '& svg': {
                paddingLeft: 0,
                paddingRight: '-18px',
              },
            },
            body: {
              padding: '12px 24px',
              fontSize: '16px',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '18px',
            },
          },
        },
      },
    });

  return (
    <ThemeProvider theme={modifiedTheme}>
      <NewDesignedMain open={open} sx={{ p: 1, mt: 1, overflow: 'hidden' }}>
        <Box
          sx={{
            paddingTop: '8px',
            paddingLeft: '4px',
            paddingRight: '18px',
            paddingButtom: '16px',
          }}
          data-selenium-id='Report_Overview-Box'
        >
          <Box
            sx={{width: '100%'}}
               data-selenium-id='Report_Overview-Box-Box'
          >
            <ShowForPermission permission={`${PERMISSIONS.REPORT_READ_GENERAL}`}>
              <Box
                sx={{
                  borderColor: 'divider',
                  backgroundColor: '#F5F7FB',
                  height: '110px',
                    }}
                data-selenium-id='Report_Overview-Box-Box-Box'
              >
                <Tabs
                  // Wiley
                value={tabIndex}
                // value={currentReportTab}
                  onChange={handleChange}
                  aria-label='basic tabs example'
                  data-selenium-id='Report_Overview-Box-Box-Box-Tabs'
                >
                  <ReportTabParent
                    id='reportSipipelineTab'
                    data-selenium-id='Report_Overview-SipipelineTab'
                    label='SI PIPELINE'
                    {...a11yProps(0)}
                  />
                  <ReportTabParent
                    id='reportAcquiredAndActiveSiTab'
                    data-selenium-id='Report_Overview-AcquiredAndActiveSiTab'
                    label='ACQUIRED AND ACTIVE SI'
                    {...a11yProps(1)}
                  />
                  <ReportTabParent
                    id='reportArticlesTab'
                    data-selenium-id='Report_Overview-ArticlesTab'
                    label='ARTICLES'
                    {...a11yProps(2)}
                  />
                  <ReportTabParent
                    id='reportJournalTab'
                    data-selenium-id='Report_Overview-JournalTab'
                    label='JOURNAL'
                    {...a11yProps(3)}
                  />
                  {userPermissions && userPermissions.includes(PERMISSIONS.REPORT_READ_TARGET) ? (
                    <ReportTabParent
                      id='reportTargetTab'
                      data-selenium-id='Report_Overview-TargetTab'
                      label='TARGETS'
                      {...a11yProps(4)}
                    />
                  ):(<></>)}
                </Tabs>
              </Box>
            </ShowForPermission>
            <ShowForPermission permission={`${PERMISSIONS.REPORT_READ_GENERAL}`}>
              <TabPanel value={tabIndex} index={0}>
                <SIPipeline/>
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <AcquiredAndActiveSi childTab={childTab} />
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <Articles childTab={childTab} />
              </TabPanel>
              <TabPanel value={tabIndex} index={3}>
                <Journal/>
              </TabPanel>
              <TabPanel value={tabIndex} index={4}>
                <Targets/>
              </TabPanel>
            </ShowForPermission>
          </Box>
        </Box>
      </NewDesignedMain>
    </ThemeProvider>
  )
    ;
}

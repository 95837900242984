import { Alert, Snackbar } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { UntitledAlertBox } from '@/components/Alert/AlertBox';
import React,{useDispatch} from 'react';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
export function SuccessAlert(props) {
  const { open, setOpen } = props;
  const handleClose=(flag)=>{setOpen(!flag);};
  return (
    // <Snackbar
    //   open={open}
    //   autoHideDuration={3000}
    //   anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    //   onClose={() => {
    //     setOpen(false);
    //   }}
    //   data-selenium-id='AnnualTarget_Overview-ATAlert-SuccessSnackbar'
    // >
    //   <Alert
    //     iconMapping={{
    //       success: <CheckCircleOutlineIcon fontSize='inherit' />,
    //     }}
    //     severity='success'
    //     sx={{
    //       width: '568px',
    //       background:
    //         'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3E8453',
    //     }}
    //   >
    //     The annual target file was updated successfully
    //   </Alert>
    // </Snackbar>
    <UntitledAlertBox
      open={open}
      severity='success'
      message='The annual target file was updated successfully'
      autoHideDuration={3000}
      handleClose={handleClose}
    />
  );
}

export function ErrorAlert(props) {
  const { open, setOpen } = props;
  const handleClose=(flag)=>{setOpen(!flag);};
  return (
    // <Snackbar
    //   open={open}
    //   autoHideDuration={3000}
    //   anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    //   onClose={() => {
    //     setOpen(false);
    //   }}
    //   data-selenium-id='AnnualTarget_Overview-ATAlert-ErrorSnackbar'
    // >
    //   <Alert
    //     severity='error'
    //     sx={{
    //       width: '568px',
    //       background:
    //         'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #B92D80',
    //     }}
    //   >
    //     {props.message !== undefined &&
    //     props.message !== null &&
    //     props.message !== ''
    //       ? props.message
    //       : 'Please use the correct template for relevant target file'}
    //   </Alert>
    // </Snackbar>
    <UntitledAlertBox
      open={open}
      severity='error'
      message={props.message !== undefined &&
        props.message !== null &&
        props.message !== ''
        ? props.message
        : 'Please use the correct template for relevant target file'}
      autoHideDuration={3000}
      handleClose={handleClose}
    />
  );
}

//数据为空的警告
export function ErrorAlertForEmpty(props) {
  const { open, setOpen } = props;
  const handleClose=(flag)=>{setOpen(!flag);};
  return (
    // <Snackbar
    //   open={open}
    //   autoHideDuration={3000}
    //   anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    //   onClose={() => {
    //     setOpen(false);
    //   }}
    //   data-selenium-id='AnnualTarget_Overview-ATAlert-ErrorForEmptySnackbar'
    // >
    //   <Alert
    //     severity='error'
    //     sx={{
    //       width: '568px',
    //       background:
    //         'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #B92D80',
    //     }}
    //   >
    //     To view the target file, should upload the file before
    //   </Alert>
    // </Snackbar>
    <UntitledAlertBox
      open={open}
      severity='error'
      message='To view the target file, should upload the file before'
      autoHideDuration={3000}
      handleClose={handleClose}
    />
  );
}

export const FileWarningAlert = (dispatch,type) => {
  switch(type){
    case 0:
      dispatch(
        setSnackbarMessageAndOpen(
          'annualTarget.fileWarning',
          {fileWarning:<span style={{ fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            color:'#596A7C',
            lineHeight: '17px',}}>Please upload files in supported format: Excel<br></br>Maximum size: <span style={{fontWeight:700}}>5M</span></span>},
          'warning',
        )
      );
      break;
    case 1:
      dispatch(
        setSnackbarMessageAndOpen(
          'annualTarget.fileWarning',
          {fileWarning:<span style={{ fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            color:'#596A7C',
            lineHeight: '17px',}}>Please upload files in supported format: Word, PDF, TXT, PPT, Excel, <br></br>CSV, PNG, JPEG, JPG, GIF, Zip, rar<br></br>Maximum size: <span style={{fontWeight:700}}>10M</span></span>},
          'warning',
        )
      );
      break;
    default:
      break;
  }
  
};
import {axios_instance} from "@/utils/axios_instance";
import {GET_COGEHISTORY, GET_LeadGEHISTORY} from "@/actions/SIMT-SI/SpecialIssue/SIAction";

export function get_LeadGEHistory(siId, pageNum, pageSize) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/special-issues/${siId}/ge-editing-history`;
            const res = await axios_instance.get(url, {
                params: {
                    geType: 'lead-GE',
                    pageNum: pageNum,
                    pageSize: pageSize,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const {code,data} = res.data;     
            if (code === 200) {
                dispatch({type: GET_LeadGEHISTORY, data: data});
            } else {
                dispatch({type: GET_LeadGEHISTORY, data: 'error'});
            }
        } catch {
            dispatch({type: GET_LeadGEHISTORY, data: 'error'});
        }
    };
}

export function get_COGEHistory(siId, pageNum, pageSize) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/special-issues/${siId}/ge-editing-history`;
            const res = await axios_instance.get(url, {
                params: {
                    geType: 'co-GE',
                    pageNum: pageNum,
                    pageSize: pageSize,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const {code,data} = res.data;
            if (code === 200) {
                dispatch({type: GET_COGEHISTORY, data: data});
            } else {
                dispatch({type: GET_COGEHISTORY, data: 'error'});
            }
        } catch {
            dispatch({type: GET_COGEHISTORY, data: 'error'});
        }
    };
}
/**
 * Huang Tao
 */
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '@/components/DataTable';
import {
  ReportNoData,
  StyledTableCellDisPurple,
  StyledTableCellPurple,
  StyledTableRow, TableBox, TableBox2, TableLabel
} from "../../ReportSpecificComponents/SpecialColorTableHeader";

import { StyledSwitchTwoColor } from '../../ReportSpecificComponents/SpecialColorSwitch';
import { findLongestLineSubstring, measureWidth } from "../../../../utils/StringWidthMeasure";
import { SimpleTooltip } from "../../../../componentsphase2/CustomTooltip";
import {
    GetArticleOAWaiverUseBusinessTableData,
    GetArticleOverviewBusinessTableData
} from "@/actions/SIMT-Report/Report/ReportArticle";

export function ArticleOverviewBusinessDivisionTable(props) {
  const { ifNoResult } = props;

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { currentDate, table3Data, tableMonth } = useSelector(state => {
    return {
      // currentDate: state.Report.selectedArticleDate,
      currentDate: state.Report.selectDate,
      tableMonth: state.Report.articleOAWaiverUseBasicTableMonth,
      table3Data: state.Report.articleOAWaiverUseBusinessTableData,
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    widthMap['businessDivision'] = measureWidth(findLongestLineSubstring('Business Division'), '18px Open Sans');

    // const CUR_LEN = measureWidth(findLongestLineSubstring('Published SI'), '18px Open Sans');
    // const YTD_LEN = measureWidth(findLongestLineSubstring('Waiver Used SI'), '18px Open Sans');
    // const OAP_LEN = measureWidth(findLongestLineSubstring('Published OA Articles'), '18px Open Sans');
    // const OAP_LEN2 = measureWidth(findLongestLineSubstring('Used OA Waiver'), '18px Open Sans');

    for (let type of ['sub','acc']) {
      widthMap[`${type}-0`] = 63;
      widthMap[`${type}-1`] = 63;
      widthMap[`${type}-2`] = 63;
      widthMap[`${type}-3`] = 63;
    }

    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (table3Data != null && table3Data.length > 0 ){
      const maxMap = {};
      for (let i = 0; i < table3Data.length; i++) {
        let row = table3Data[i];
        let dataObj = {
          businessDivision: row.businessDivision,

          'sub-0':  row.publishedSiInMonth ?? '0',
          'sub-1':  row.waiverUsedSiInMonth ?? '0',
          'sub-2':  row.publishedOaArticlesInMonth ?? '0',
          'sub-3':  row.usedOaWaiverInMonth ?? '0',

          'acc-0':  row.publishedSiInYear ?? '0',
          'acc-1':  row.waiverUsedSiInYear ?? '0',
          'acc-2':  row.publishedOaArticlesInYear ?? '0',
          'acc-3':  row.usedOaWaiverInYear ?? '0',
        };
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [table3Data]);

  function calcGroupWidth(type){
    let sum = 0;
    for (let i of [0,1,2,3]) {
      sum += Math.max((columnWidthMap[`${type}-${i}`]??0), columnMinWidthMap[`${type}-${i}`]) + 50;
    }
    return sum;
  }

  const [OASwitch, setOASwitch] = useState(true);

  const handleChangeSwitch = () => {
    setOASwitch(!OASwitch);
  };

  useEffect(() => {
    async function getData(currentDate) {
      if (currentDate != null) {
        setIsLoading(true);
        setTimeout(() => {
          dispatch(GetArticleOAWaiverUseBusinessTableData(currentDate));
        }, 2000);
        // getMonth(currentDate.split("-")[1]);
      }
    }

    getData(currentDate);
  }, [currentDate]);

  useEffect(() => {
    setIsLoading(false);
  }, [table3Data]);

  const Subitems = [
    {
      id: 'Sub-MMM',
      label: 'Published SI',
      maxWidth: '112px',
      minWidth: '112px',
      post: 'submittedArt',
      isOrder: false,
    },
    {
      id: 'Sub-YTD',
      label: 'Waiver Used SI',
      maxWidth: '112px',
      minWidth: '112px',
      post: 'submittedArtYtd',
      isOrder: false,
    },
    {
      id: '%Against YTD Target',
      label: 'Published OA Articles',
      maxWidth: '112px',
      minWidth: '112px',
      isOrder: false,
      post: 'submittedArtYtdTarget',
    },
    {
      id: '%Against YTD Target1',
      label: 'Used OA Waiver',
      maxWidth: '112px',
      minWidth: '112px',
      isOrder: false,
      post: 'submittedArtYtdTarget',
    },
  ];
  const Accitems = [
    {
      id: 'Acc-MMM',
      label: 'Published SI',
      maxWidth: '112px',
      minWidth: '112px',
      isOrder: false,
      post: 'acceptedArt',
      textTransform: 'none',
      fontFamily: 'Inter',
    },
    {
      id: 'Acc-YTD',
      label: 'Waiver Used SI',
      maxWidth: '112px',
      minWidth: '112px',
      isOrder: false,
      post: 'acceptedArtYtd',
    },
    {
      id: '%Against YTD Target',
      label: 'Published OA Articles',
      maxWidth: '112px',
      minWidth: '112px',
      isOrder: false,
      post: 'acceptedArtYtdTarget',
    },
    {
      id: '%Against YTD Target1',
      label: 'Used OA Waiver',
      maxWidth: '112px',
      minWidth: '112px',
      isOrder: false,
      post: 'submittedArtYtdTarget',
    },
  ];
  {/*const Pubitems = [
    {
      id: 'Pub-MMM',
      label: 'Pub - ',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: 'Pub-YTD',
      label: 'Pub - YTD',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: '%Against YTD Target',
      label: '% Against YTD Target',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: '%Against YTD Stretch target',
      label: '% Against YTD Stretch target',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
  ];*/}

  const showDataTableSG = table3Data => {
    //  console.log(tabls)
    if (table3Data === undefined || table3Data === null) {
      return [];
    } else return table3Data;
  };

  function DisplayTooltip(props) {
    const { ifTotal } = props;
    if (ifTotal === 'Total')
      return (
        <div align='right'>
          {ifTotal}
        </div>
      );
    else {
      return <div align='left'>{ifTotal}</div>;
    }
  }

  const [hover, setHover] = useState(false);
  const [indexs, setIndexs] = useState(0);
  const [hovera, setHovera] = useState(false);
  const [indexsa, setIndexsa] = useState(0);

  const tableContainerRef = useRef(null);
  const leftSideWidth = (tableContainerRef.current?.offsetWidth ?? 0 ) / 2;

  return (
    <>
      <TableLabel>Business Division</TableLabel>
      <TableBox2
        id='BoxTable2'
        data-selenium-id='Report_Articles_OAP-BusinessTable-BoxTable2'
        dataList={[table3Data]}
        tableKey={'Report_Articles-OAW-BDTable'}
        footerHeight={0}
      >
        <TableContainer
          ref={tableContainerRef}
          id='reportArticlesOverviewBDTable'
          data-selenium-id='Report_Articles_OAP-BusinessTableContainer'
          sx={{
            maxHeight: '600px',
            border: '1px solid #DFE4E8',
            borderRadius: '8px',
            width: 'max-content',
            maxWidth: '100%',
          }}
        >
          <Table stickyHeader aria-label='sticky table'
                 sx={{
                   tableLayout: 'fixed',
                   width: 'fit-content',
                   maxWidth: '100%',
                   minWidth: '0'
                 }}
          >
            <TableHead data-selenium-id='Report_Articles_OAP-BusinessTableHead'>
              <TableRow data-selenium-id='Report_Articles_OAP-BusinessTableHead-Row'>
                <TableCell
                  align='left'
                  rowSpan={2}
                  sx={{
                    // width: '277px',
                    // minWidth: '277px',
                    // maxWidth: '277px',
                    // height: '102px',
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    fontWeight: 600,
                    // lineHeight: '18px',
                    // letterSpacing: '0.08em',
                    // padding: '12px 24px',
                    // color: '#596A7C',
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                    width: `${Math.max((columnWidthMap['businessDivision']??0), columnMinWidthMap['businessDivision']) + 50 }px`,
                    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                  }}
                  //className='RightBottomBorder'
                  //style={{ backgroundColor: !OASwitch ? '#96DCA9' : '#CCD4FF' }}
                  data-selenium-id='Report_Articles_OAP-BusinessTableHead-Row-BusinessDivision'
                >
                  BUSINESS DIVISION
                </TableCell>
                <TableCell
                  align='left'
                  //colSpan={!OASwitch ? 4 : 3}
                  className='ReportHeaderFirstLine'
                  colSpan={4}
                  sx={{
                    padding: '12px 0px 12px 24px',
                    height: '42px',
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '18px',
                    letterSpacing: '0.08em',
                    color: '#FFFFFF',
                    background: '#98A7B6',
                    borderLeftWidth: '2px',
                    borderLeftStyle: 'solid',
                    borderLeftColor: '#FFFFFF',
                    width: `${calcGroupWidth('sub')}px`,
                    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                  }}
                  //className='RightBottomBorder'
                  //style={{ backgroundColor: !OASwitch ? '#96DCA9' : '#CCD4FF' }}
                  data-selenium-id='Report_Articles_OAP-BusinessTableHead-Row-Submission'
                >
                  PUBLICATION IN {tableMonth}
                </TableCell>
                <TableCell
                  align='left'
                  //colSpan={!OASwitch ? 4 : 3}
                  className='ReportHeaderFirstLine'
                  colSpan={4}
                  sx={{
                    padding: '12px 0px 12px 24px',
                    height: '42px',
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '18px',
                    letterSpacing: '0.08em',
                    color: '#FFFFFF',
                    background: '#98A7B6',
                    borderLeftWidth: '2px',
                    borderLeftStyle: 'solid',
                    borderLeftColor: '#FFFFFF',
                    width: `${calcGroupWidth('acc')}px`,
                    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                  }}
                  //className='RightBottomBorder'
                  //style={{ backgroundColor: !OASwitch ? '#96DCA9' : '#CCD4FF' }}
                  data-selenium-id='Report_Articles_OAP-BusinessTableHead-Row-Acceptance'
                >
                  PUBLICATION YTD
                </TableCell>
                {/*<StyledTableCellPurple
                  align='left'
                  colSpan={!OASwitch ? 4 : 3}
                  className='RightBottomBorder'
                  style={{ backgroundColor: !OASwitch ? '#96DCA9' : '#CCD4FF' }}
                  data-selenium-id='Report_Articles_Overview-BusinessTableHead-Row-Publication'
                >
                  Publication
                </StyledTableCellPurple>*/}
              </TableRow>
              <TableRow>
                {Subitems.map((item, index) =>
                  <TableCell
                    align='left'
                    key={item.id}
                    sx={{
                      top: 42.8,
                      // maxWidth: item.maxWidth,
                      // minWidth: item.minWidth,
                      // width: '113px',
                      width: `${Math.max((columnWidthMap[`sub-${index}`]??0), columnMinWidthMap[`sub-${index}`]) + 50 }px`,
                      height: '60px',
                      padding: '12px 24px',
                      fontFamily: 'Open Sans',
                      fontSize: '12px',
                      fontWeight: 600,
                      lineHeight: '18px',
                      letterSpacing: '0px',
                      color: '#596A7C',
                      background: '#F1F3F5',
                      borderLeftWidth: item.id === 'Sub-MMM' ? '2px' : null,
                      borderLeftStyle: item.id === 'Sub-MMM' ? 'solid' : null,
                      borderLeftColor: item.id === 'Sub-MMM' ? '#FFFFFF' : null,
                      boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                      textTransform: 'none',
                      fontFamily: 'Inter',
                    }}
                  /*className={
                    (!OASwitch &&
                      item.id === '%Against YTD Stretch target') ||
                    (OASwitch && item.id === '%Against YTD Target')
                      ? 'RightBottomBorder'
                      : 'BottomBorder'
                  }
                  style={{
                    backgroundColor: !OASwitch ? '#abe3ba' : '#d6ddff',
                  }}*/
                  >
                    {item.label}
                  </TableCell>
                )}
                {Accitems.map((item, index) =>
                  <TableCell
                    align='left'
                    key={item.id}
                    sx={{
                      top: 42.8,
                      height: '60px',
                      padding: '12px 24px',
                      // width: '113px',
                      // maxWidth: item.maxWidth,
                      // minWidth: item.minWidth,
                      width: `${Math.max((columnWidthMap[`acc-${index}`]??0), columnMinWidthMap[`acc-${index}`]) + 50 }px`,
                      fontFamily: 'Open Sans',
                      fontSize: '12px',
                      fontWeight: 600,
                      lineHeight: '18px',
                      letterSpacing: '0px',
                      color: '#596A7C',
                      background: '#F1F3F5',
                      borderLeftWidth: item.id === 'Acc-MMM' ? '2px' : null,
                      borderLeftStyle: item.id === 'Acc-MMM' ? 'solid' : null,
                      borderLeftColor: item.id === 'Acc-MMM' ? '#FFFFFF' : null,
                      boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                      textTransform: 'none',
                      fontFamily: 'Inter',
                    }}
                  /*className={
                    (!OASwitch &&
                      item.id === '%Against YTD Stretch target') ||
                    (OASwitch && item.id === '%Against YTD Target')
                      ? 'RightBottomBorder'
                      : 'BottomBorder'
                  }
                  style={{
                    backgroundColor: !OASwitch ? '#abe3ba' : '#d6ddff',
                  }}*/
                  >
                    {item.label}
                  </TableCell>
                )}
                {/*{Subitems.map(item =>
                  OASwitch && item.id === '%Against YTD Stretch target' ? (
                    <></>
                  ) : (
                    <StyledTableCellDisPurple
                      align='right'
                      key={item.id}
                      sx={{
                        top: 52.5,
                        maxWidth: item.maxWidth,
                        minWidth: item.minWidth,
                      }}
                      className={
                        (!OASwitch &&
                          item.id === '%Against YTD Stretch target') ||
                        (OASwitch && item.id === '%Against YTD Target')
                          ? 'RightBottomBorder'
                          : 'BottomBorder'
                      }
                      style={{
                        backgroundColor: !OASwitch ? '#abe3ba' : '#d6ddff',
                      }}
                    >
                      {item.id === 'Sub-MMM' ||
                      item.id === 'Acc-MMM' ||
                      item.id === 'Pub-MMM'
                        ? item.label + tableMonth
                        : item.label}
                    </StyledTableCellDisPurple>
                  )
                )}
                {Accitems.map(item =>
                  OASwitch && item.id === '%Against YTD Stretch target' ? (
                    <></>
                  ) : (
                    <StyledTableCellDisPurple
                      align='right'
                      key={item.id}
                      sx={{
                        top: 52.5,
                        maxWidth: item.maxWidth,
                        minWidth: item.minWidth,
                      }}
                      className={
                        (!OASwitch &&
                          item.id === '%Against YTD Stretch target') ||
                        (OASwitch && item.id === '%Against YTD Target')
                          ? 'RightBottomBorder'
                          : 'BottomBorder'
                      }
                      style={{
                        backgroundColor: !OASwitch ? '#abe3ba' : '#d6ddff',
                      }}
                    >
                      {item.id === 'Sub-MMM' ||
                      item.id === 'Acc-MMM' ||
                      item.id === 'Pub-MMM'
                        ? item.label + tableMonth
                        : item.label}
                    </StyledTableCellDisPurple>
                  )
                )}
                {Pubitems.map(item =>
                  OASwitch && item.id === '%Against YTD Stretch target' ? (
                    <></>
                  ) : (
                    <StyledTableCellDisPurple
                      align='right'
                      key={item.id}
                      sx={{
                        top: 52.5,
                        maxWidth: item.maxWidth,
                        minWidth: item.minWidth,
                      }}
                      className={
                        (!OASwitch &&
                          item.id === '%Against YTD Stretch target') ||
                        (OASwitch && item.id === '%Against YTD Target')
                          ? 'RightBottomBorder'
                          : 'BottomBorder'
                      }
                      style={{
                        backgroundColor: !OASwitch ? '#abe3ba' : '#d6ddff',
                      }}
                    >
                      {item.id === 'Sub-MMM' ||
                      item.id === 'Acc-MMM' ||
                      item.id === 'Pub-MMM'
                        ? item.label + tableMonth
                        : item.label}
                    </StyledTableCellDisPurple>
                  )
                    )}*/}
              </TableRow>
            </TableHead>
            <TableBody data-selenium-id='Report_Articles_OAP-BusinessTableBody'>
              {!isLoading &&
                !ifNoResult &&
                table3Data != null &&
                table3Data != undefined &&
                table3Data.length != 0
                ? table3Data.map((row, index) => {
                  if (row.businessDivision === 'Total') {
                    return (
                      <TableRow
                        sx={{
                          height: '64px',
                          background: '#FFFFFF',
                          '&:hover':{
                            background: '#E8EDFB',
                          }
                        }}
                        onMouseEnter={() => {
                          setHover(true);
                          setIndexs(index);
                        }}
                        onMouseLeave={() => {
                          setHover(false);
                          setIndexs(index);
                        }}
                      >
                        <TableCell
                          align='right'
                          sx={{
                            position: 'sticky',
                            left: 0,
                            zIndex: 6,
                            background: hover && indexs === index ?'#E8EDFB':'#F1F3F5',
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '22.5px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          //className='RightBorder ReportTotalTextSize'
                          data-selenium-id={`Report_Articles_OAP-BusinessTableBody-Cell1-${index}`}
                        >
                          {/*<SimpleTooltip title={row.businessDivision}>
                              <DisplayTooltip
                                ifTotal={row.businessDivision}
                              />
                            </SimpleTooltip>*/}
                          {row.businessDivision}
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '22.5px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          //className='ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles_OAP-BusinessTableBody-Cell2-${index}`}
                        >

                            {row.publishedSiInMonth}

                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '22.5px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          //className='ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles_OAP-BusinessTableBody-Cell3-${index}`}
                        >

                            {row.waiverUsedSiInMonth}

                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '22.5px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          /*className={
                            OASwitch
                              ? 'RightBorder ReportNumberTextColor ReportTotalTextSize'
                              : 'NoBorder ReportNumberTextColor ReportTotalTextSize'
                          }*/
                          data-selenium-id={`Report_Articles_OAP-BusinessTableBody-Cell4-${index}`}
                        >

                            {' '}
                            {row.publishedOaArticlesInMonth}

                        </TableCell>

                        <TableCell
                          align='left'
                          sx={{
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '22.5px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          //className='ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles_Overview-BusinessTableBody-Cell6-${index}`}
                        >

                            {' '}
                            {row.usedOaWaiverInMonth}

                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '22.5px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          //className='ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles_OAP-BusinessTableBody-Cell7-${index}`}
                        >

                            {' '}
                            {row.publishedSiInYear}

                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '22.5px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          data-selenium-id={`Report_Articles_OAP-BusinessTableBody-Cell8-${index}`}
                        /*className={
                          OASwitch
                            ? 'RightBorder ReportNumberTextColor ReportTotalTextSize'
                            : 'NoBorder ReportNumberTextColor ReportTotalTextSize'
                        }*/
                        >

                            {' '}
                            {row.waiverUsedSiInYear}

                        </TableCell>

                        <TableCell
                          align='left'
                          sx={{
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '22.5px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          //className='ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles_OAP-BusinessTableBody-Cell2-${index}`}
                        >
                          {row.publishedOaArticlesInYear}
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '22.5px',
                            color: '#262E35',
                            padding: '12px 24px',
                          }}
                          //className='ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles_OAP-BusinessTableBody-Cell2-${index}`}
                        >
                          {row.usedOaWaiverInYear}
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return (
                    <TableRow
                      sx={{
                        height: '64px',
                        background: '#FFFFFF',
                        '&:hover':{
                          background: '#E8EDFB',
                        }
                      }}
                      onMouseEnter={() => {
                        setHovera(true);
                        setIndexsa(index);
                      }}
                      onMouseLeave={() => {
                        setHovera(false);
                        setIndexsa(index);
                      }}
                    >
                      <TableCell
                        align='left'
                        sx={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 6,
                          background: hovera && indexsa === index ?'#E8EDFB':'#F1F3F5',
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='RightBorder'
                        data-selenium-id={`Report_Articles_OAP-BusinessTableBody-Cell14-${index}`}
                      >
                        <SimpleTooltip title={row.businessDivision}>
                            {row.businessDivision}
                          </SimpleTooltip>
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles_OAP-BusinessTableBody-Cell15-${index}`}
                      >
                        {row.publishedSiInMonth}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='ReportNumberTextColor'
                      >
                        {row.waiverUsedSiInMonth}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        data-selenium-id={`Report_Articles_OAP-BusinessTableBody-Cell16-${index}`}
                        /*className={
                          OASwitch
                            ? 'RightBorder ReportNumberTextColor'
                            : 'NoBorder ReportNumberTextColor'
                        }*/
                      >
                        {' '}
                        {row.publishedOaArticlesInMonth}
                      </TableCell>

                      <TableCell
                        align='left'
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles_OAP-BusinessTableBody-Cell18-${index}`}
                      >
                        {' '}
                        {row.usedOaWaiverInMonth}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles_OAP-BusinessTableBody-Cell19-${index}`}
                      >
                        {' '}
                        {row.publishedSiInYear}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        data-selenium-id={`Report_Articles_OAP-BusinessTableBody-Cell20-${index}`}
                        /*className={
                          OASwitch
                            ? 'RightBorder ReportNumberTextColor'
                            : 'NoBorder ReportNumberTextColor'
                        }*/
                      >
                        {' '}
                        {row.waiverUsedSiInYear}
                      </TableCell>

                      <TableCell
                        align='left'
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles_OAP-BusinessTableBody-Cell15-${index}`}
                      >
                        {row.publishedOaArticlesInYear}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles_OAP-BusinessTableBody-Cell15-${index}`}
                      >
                        {row.usedOaWaiverInYear}
                      </TableCell>
                    </TableRow>
                  );
                })
                : !isLoading && (
                  <ReportNoData id='AcquiredAndActiveSiAnnualTrendsBDNoResultTableCell' left={`${leftSideWidth}px`}/>
                )}
              {isLoading && (
                <Box
                  sx={{
                    width: 'auto',
                    marginTop: '150px',
                    marginLeft: '50%',
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TableBox2>
    </>
  );
}

// export default SubjectGroupTable

import React, { useRef, useState } from 'react';
import Icon1 from './VectorImage/ItemCollapsedButton/1/1.svg';
import Icon2 from './VectorImage/ItemCollapsedButton/1/2.svg';
import Icon3 from './VectorImage/ItemCollapsedButton/1/3.svg';
import Icon4 from './VectorImage/ItemCollapsedButton/1/4.svg';
import Icon5 from './VectorImage/ItemExpandedButton/1/1.svg';
import Icon6 from './VectorImage/ItemExpandedButton/1/2.svg';
import Icon7 from './VectorImage/ItemExpandedButton/1/3.svg';
import Icon8 from './VectorImage/ItemExpandedButton/1/4.svg';
import Box from '@mui/material/Box';

export default function ItemFolderButtonUS(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const ref = useRef(null);

  const LeftRight = props.LeftRight; // true expand，false collapse

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const checkHoveredState = () => {
    // setIsHovered(document.getElementById('ItemFolderButton_US').contains(document.activeElement));
    setIsHovered(ref.current.contains(document.activeElement));
  };

  const handleClick = () => {
    setIsClicked(!isClicked);
    props.ItemButtonClick();
    checkHoveredState();
  };

  return (
    <Box
      ref={ref}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      id="ItemFolderButton_US"
      data-selenium-id="ItemFolderButton_US"
      LeftRight={props.LeftRight}
      className={' ItemFolderButton_US '+ (isHovered? ' ItemFolderButton-isHovered ' : '')+ (props.isClicked? ' ItemFolderButton-isClicked ' : '')}
      sx={props.sx ?? {cursor:'pointer'}}
    >
      <div>
        {!LeftRight ? (
          props.isClicked ? (isHovered ? <Icon4 /> : <Icon2 />) : (isHovered ? <Icon3 /> : <Icon1 />)
        ) : (
          props.isClicked ? (isHovered ? <Icon8 /> : <Icon6 />) : (isHovered ? <Icon7 /> : <Icon5 />)
        )}
      </div>
    </Box>
  );
}

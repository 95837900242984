/*
 * @Author: LiuJingQian 1250366059@qq.com
 * @Date: 2023-07-17 13:03:16
 * @LastEditors: LiuJingQian 1250366059@qq.com
 * @LastEditTime: 2023-07-25 07:05:29
 * @FilePath: \simt_front\src\pages\Report\Articles\OAPublicationInHybridJournals\Template.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Box } from '@mui/system';
import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import {
  ArticleOverviewHeader,
  ArticleOverviewPageOverview,
} from './overHeader';
import ArticleOAPubTable from './BasicTable';
import { ArticleOverviewSubjectGroupTable } from './SubjectGroupTable';
import { ArticleOverviewBusinessDivisionTable } from './BusinseeTable';
import { StyledEngineProvider, useTheme } from '@mui/material/styles';
import { ArticleOverviewCETable } from './CETable';

import ArticlesOverviewFilter from './ArticlesOverviewFilter';
import { Note } from '../Note';
import { TableGroupBox } from '../../ReportSpecificComponents/SpecialColorTableHeader';
import {PERMISSIONS} from "@/constant/permission";



export function OAPublicationInHybridJournals(props) {
  const filterDrawerWidth = 380;
  const theme = useTheme();

  const [filterOpen, setFilterOpen] = useState(false);
  const [ifNoResult, setIfNoResult] = useState(false);
  const userPermissions = localStorage.getItem('userPermissions');

  const filterOpenChange = () => {
    setFilterOpen(!filterOpen);
  };

  const BasicTableBoxCss = {
    width: filterOpen ? 'calc(100% - 308px)' : '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  };

  return (
    <div>
      {/*月份选择器 export*/}
      <ArticleOverviewPageOverview />

      <TableGroupBox
        data-selenium-id='Report_Articles_Overview-Box'
      >
        <StyledEngineProvider injectFirst>
          <div style={BasicTableBoxCss}>
            {/*标题和过滤器*/}
            <ArticleOverviewHeader
              filterOpen={filterOpen}
              filterOpenChange={filterOpenChange}
            />
            <ArticleOAPubTable setIfNoResult={setIfNoResult} />
          </div>
        </StyledEngineProvider>
        {filterOpen ? (
          <ArticlesOverviewFilter
            filterOpen={filterOpen}
            drawerWidth={filterDrawerWidth}
            handleClose={filterOpenChange}
          />
        ) : (
          <div></div>
        )}
      </TableGroupBox>
      {/* <TableLabel>Subject Group</TableLabel> */}
      <TableGroupBox>
        <ArticleOverviewSubjectGroupTable ifNoResult={ifNoResult} />
      </TableGroupBox>
      {/* <TableLabel>Business Division</TableLabel> */}
      <TableGroupBox>
        <ArticleOverviewBusinessDivisionTable ifNoResult={ifNoResult} />
      </TableGroupBox>
      {/* <TableLabel>CE/CAT</TableLabel> */}
      {userPermissions && (userPermissions.includes(PERMISSIONS.CAT_REPORT_READ) || userPermissions.includes(PERMISSIONS.REPORT_READ_CE)) ? (
        <TableGroupBox>
          < ArticleOverviewCETable ifNoResult={ifNoResult} />
        </TableGroupBox>
      ):(<></>)
      }
      

      {/*提示语*/}
      {/*<Note top='20px' />*/}
    </div>
  );
}

export default OAPublicationInHybridJournals;

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetBusinessDivisionsForArticleWithStatus } from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';

export default function useGetCategoryListHook(
  singleSelectListUrl,
  reduxListTitle,
  hasAdditionalFilter
) {
  const dispatch = useDispatch();

  const itemList = useSelector(state => {
    return state.ReportDiagram[reduxListTitle];
  });

  let loading = null,
    error = null,
    data = null;

  if (typeof itemList !== 'undefined') {
    ({ loading, error, data } = itemList);
  }

  // console.log('==itemList===!', itemList);
  // console.log('==error===!', error);

  useEffect(() => {
    if (typeof itemList === 'undefined' && hasAdditionalFilter) {
      // console.log('==singleSelectListUrl==', singleSelectListUrl);
      dispatch(
        GetBusinessDivisionsForArticleWithStatus(
          singleSelectListUrl, // singleSelectListUrl + '_fake_test',
          reduxListTitle
        )
      );
    }
  }, [itemList, reduxListTitle, singleSelectListUrl, hasAdditionalFilter]);

  // console.log('==data==', data);

  return [data, loading, error];
}

import styles from './index.module.scss';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { needToComfirmShow } from '../utils/isSubmitted';
import { axios_instance } from '@/utils/axios_instance';
import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';
import {
  checkProposerOK,
  getContentCommissioningApproaches,
  getGEInformation,
  getObject,
  getSIPInformation,
  getSIPStatus,
} from '@/actions/SIMT-SIP/SIP/SipDetail';
import {
  // clearSendEmailCode,
  // clearGetEmailCode,
  // clearSIPSheveledCode,
  GET_DECISION_TYPE,
  setSipDetailAllMandatorySubmitOrNextPressed,
  setCurrentDecisionType,
} from '../../../../actions/SIMT-SIP/SIP/SIPAction';
import {
  getDecisionConfigList,
  getDecisionTypeList,
} from '@/actions/SIMT-SIP/SIP/StageDecision';
import DecisionTypeSelect from './DecisionTypeSelect';
import FormItem from './FormItem';
import { ButtonAlertBox } from '@/components/Alert/AlertBox';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import {
  validateCommissionApproaches, validateGEHasLeadGE, validateGEInformation, validateGEWithStageAndDecision,
  validateSipInformation
} from "@/pages/SIPPage/SIPDetailPage/DecisionPanel/validation";

const DEFAULT_HEIGHT = 60;
const LOW_HEIGHT = 300;
const HIGH_HEIGHT = 450;
function DecisionPanel({ disabled = false, sipStage, sipCode }) {
  const {
    sipInformation,
    geInformation,
    object,
    contentCommissioningApproaches,
    sipVersion,
  } = useSelector(state => {
    return {
      sipInformation: state.SIP.sipInformation,
      geInformation: state.SIP.geInformation,
      object: state.SIP.object,
      contentCommissioningApproaches: state.SIP.contentCommissioningApproaches,
      sipVersion: state.SIP.sipStatus?.sipVersion,
    };
  });
  const dispatch = useDispatch();
  const intl = useIntl();

  const [opened, setOpened] = useState(false);
  const [decisionType, setDecisionType] = useState('');

  const saved = useMemo(() => {
    return !opened && Boolean(decisionType);
  }, [decisionType, opened]);

  const [group1Left, setGroup1Left] = useState([]);
  const [group1Right, setGroup1Right] = useState([]);
  const [showGroup, setShowGroup] = useState(1);
  const [group2, setGroup2] = useState([]);
  const [height, setHeight] = useState(DEFAULT_HEIGHT);
  const [form, setForm] = useState({});
  const [buttonAlert, setButtonAlert] = useState({
    open: false,
    title: '',
    message: '',
    buttons: [],
    severity: 'warning',
    duration: 3000,
  });

  useEffect(() => {
    if (opened) {
      setHeight(LOW_HEIGHT);
    } else {
      setHeight(DEFAULT_HEIGHT);
    }
  }, [opened]);
  useEffect(() => {
    dispatch(getDecisionTypeList(sipCode));
    // development code, modify the decision type list manually
    // dispatch({
    //   type: GET_DECISION_TYPE,
    //   data: [
    //     'Direct Acquire',
    //     'Reject',
    //     'Assign to Journal Editor',
    //     'Revision Required',
    //     'Publisher Inquiry',
    //     'Transfer to Another CE',
    //     'Withdraw',
    //   ],
    // });

    dispatch(getDecisionConfigList());
  }, [sipCode]);

  useEffect(() => {
    if (!sipInformation?.sipType) {
      return;
    }
    axios_instance
      .get('/api/v1/user-service/ui-component', {
        params: {
          businessType: sipInformation?.sipType === 'Yes' ? 'SSIP' : 'USIP',
          area: 'Decision',
          stage: sipStage,
          componentVersion: '1',
        },
      })
      .then(res => {
        if (res.data?.code !== 200) {
          return Promise.reject(res?.data?.message);
        }
        const components = res.data.data.components || [];
        const group1 = components.filter(c => c.group === 1);
        const group2 = components.filter(c => c.group === 2);
        const group1Left = group1.filter(
          c => c.properties?.position === 'left'
        );
        const group1Right = group1.filter(
          c => c.properties?.position === 'right'
        );
        setGroup1Left(group1Left);
        setGroup1Right(group1Right);
        setGroup2(group2);
      });
  }, [sipInformation,sipVersion]);

  useEffect(() => {
    setForm({});
    dispatch(setCurrentDecisionType(decisionType)); // some components need to know the current decision type to show error message, such as SIPInformationBlock
    dispatch(setSipDetailAllMandatorySubmitOrNextPressed(false)); // this is a switch for the validation tips on the SIP detail page
  }, [decisionType]);

  const formValid = useMemo(() => {
    // check if all required fields are filled
    return group1Left
      .concat(group1Right)
      .filter(c => c.action === decisionType)
      .every(c => {
        if(c.componentType === 'textarea') {
          const textValue = form[c.componentKey] || "";
          const maxLength =  c.properties.max || 100000;
          if (textValue.length > maxLength) {
            return false;
          }
        }

        const result =
          !c.properties.required ||
          !c.componentKey ||
          (Array.isArray(form[c.componentKey])
            ? form[c.componentKey].length > 0
            : form[c.componentKey]);
        return result;
      });
  }, [group1Left, group1Right, form, decisionType]);

  const getPreSubmitForm = () => {
    const submittedForm = {
      ...form,
    };

    const components = group1Left
        .concat(group1Right)
        .filter(c => c.action === decisionType);

    for(let key in form) {
      const component = components.find(c => c.componentKey === key);
      if (component && component.properties?.submitValue) {
        if (Array.isArray(form[key])) {
          submittedForm[key] = form[key].map(item => item?.[component.properties.submitValue]);
        } else {
          submittedForm[key] = form[key]?.[component.properties.submitValue];
        }
      }
    }

    return submittedForm;
  };

  const submit = ({ url = '', params, body = 'updateDecisionRequests' }) => {
    url =
      url?.replace('${sipCode}', sipCode) ||
      `/api/v1/sip-service/special-issue-proposals/${sipCode}`;
    params = {
      ...params,
      sipVersion,
    };

    const submittedForm = getPreSubmitForm();

    // When decision === 'Publisher Inquiry', the 'assignee' is an object which needed to get the email template, but need convert to a string when submit
    // TODO for the backend to fix this issue in the future:
    //  the 'assignee' should be an object when submit in com.wiley.simt.dto.editsip.SipUpdateDecisionDto#assignee
    //  and the type can be com.wiley.simt.dto.decision.JournalPublisherManagerDto
    if (
        'assignee' in submittedForm &&
        typeof submittedForm.assignee === 'object'
    ) {
      submittedForm.assignee = submittedForm.assignee.email;
    }

    const bodyData = {
      [body]: {
        ...submittedForm,
        ...(submittedForm.mailSend
          ? {
              mailSend: submittedForm.mailSend,
              emailContent: submittedForm.mailSend?.content,
              attachment: submittedForm.mailSend?.attachment,
            }
          : {}),
      },
    };

    axios_instance.put(url, bodyData, { params }).then(res => {
      if (res.data.code === 200) {
        const message =
          res.data.message ||
          `Successfully move the SIP from "${sipStage}" to a new stage!`;
        setDecisionType(''); // set the decision type to empty 
        setOpened(false);  // set the decision panel close
        dispatch(getSIPStatus(sipCode));
        dispatch(getObject(sipCode));
        dispatch(getSIPInformation(sipCode));
        dispatch(getGEInformation(sipCode));
        dispatch(getContentCommissioningApproaches(sipCode));
        dispatch(
          setSnackbarMessageAndOpen(
            message,
            {
              value: message,
            },
            SEVERITIES.success
          )
        );
      }
    });
  };
  const validateSIPDetailWithAlert = async () => {
    if (needToComfirmShow(sipStage)) {
      const res = await checkProposerOK(sipCode);
      if (!res?.data?.data) {
        setButtonAlert({
          open: true,
          title: 'You cannot make this decision to this SIP yet',
          message:
            'You need to verify or confirm the associated GE’s new flag before making this decision.',
          buttons: [],
          severity: 'warning',
          duraton: 3000,
        });
        return Promise.reject();
      }
    }

    dispatch(setSipDetailAllMandatorySubmitOrNextPressed(true)); // open it so that the validation tips can be shown if has errors on the detail page

    const valid = validateInfo();

    if (!valid) {
      setButtonAlert({
        open: true,
        title: 'You cannot make this decision yet.',
        message: 'Please fill above mandatory fields firstly.',
        buttons: [],
        severity: 'warning',
        duration: 3000,
      });
      return Promise.reject();
    }

    return true;
  };

  const validateInfo = () => {
    if(sipInformation==null){
      return;
    }
    // now, no matter in which stage, we need to validate all the fields
    const isUSIP = sipCode.startsWith("SIP");
    const openCallForPapersIsYes = contentCommissioningApproaches?.openCallForPapers === '1';

    // for USIP, no need to check the journal1Code and journal1Name
    let valid = isUSIP || (!isUSIP && !!object?.journal1Code && !!object?.journal1Name);
    
    valid &&= validateGEWithStageAndDecision(geInformation, sipStage, decisionType);
    valid &&= validateCommissionApproaches(contentCommissioningApproaches, sipStage, decisionType);
    valid &&= validateSipInformation(sipInformation, sipStage, decisionType, openCallForPapersIsYes);
    return valid;
  };

  const onCommand = useCallback(
    (command, options) => {
      let target;
      switch (command) {
        case 'higher':
          setHeight(HIGH_HEIGHT);
          break;
        case 'lower':
          setHeight(LOW_HEIGHT);
          break;
        case 'closeDecisionPanel':
          setOpened(false);
          break;
        case 'cancel':
          setDecisionType('');
          setOpened(false);
          break;
        case 'next':
          // vaildation
          validateSIPDetailWithAlert()
            .then(() => {
              setShowGroup(prev => prev + 1);
            })
            .catch(console.error);
          break;
        case 'back':
          setShowGroup(prev => prev - 1);
          onCommand('closeAlert');
          break;
        case 'alert':
          setButtonAlert({
            open: true,
            title: options.title,
            message: options.message,
            buttons:
              options.buttons?.map(b => ({
                text: b.text,
                onClick: () => {
                  onCommand(b.command, b[b.command]);
                },
              })) || [],
            severity: options.type,
            duration: options.duration || null,
          });
          break;
        case 'closeAlert':
          setButtonAlert(prev => ({ ...prev, open: false }));
          break;
        case 'markRequired':
          target = group1Left.findIndex(
            c => c.action === decisionType && c.componentKey === options.target
          );
          if (target > -1) {
            group1Left[target].properties.required = true;
            setGroup1Left([...group1Left]);
          } else {
            target = group1Right.findIndex(
              c =>
                c.action === decisionType && c.componentKey === options.target
            );
            if (target > -1) {
              group1Right[target].properties.required = true;
              setGroup1Right([...group1Right]);
            }
          }
          break;
        case 'markNotRequired':
          target = group1Left.findIndex(
            c => c.action === decisionType && c.componentKey === options.target
          );
          if (target > -1) {
            group1Left[target].properties.required = false;
            setGroup1Left([...group1Left]);
          } else {
            target = group1Right.findIndex(
              c =>
                c.action === decisionType && c.componentKey === options.target
            );
            if (target > -1) {
              group1Right[target].properties.required = false;
              setGroup1Right([...group1Right]);
            }
          }
          break;
        case 'submit':
          setButtonAlert(prev => ({ ...prev, open: false })); // close the alert button when submit the result.
          setShowGroup(1);
          validateSIPDetailWithAlert()
            .then(() => {
              submit(options ?? {});
            })
            .catch(console.error);
          break;
        default:
          break;
      }
    },
    [submit, validateSIPDetailWithAlert, group1Left, group1Right, decisionType]
  );

  const [scale, setScale] = useState(1);
  useEffect(() => {
    const handleResize = () => {
      setScale(Math.min((window.innerWidth - 10) / 1300, 1));
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div
      className={[styles.decisionPanel, opened ? styles.opened : ''].join(' ')}
      style={{
        minHeight: `${height}px`,
        transform: `scale(${scale})`,
        transformOrigin: 'left bottom',
      }}
    >
      <div className={styles.header}>
        <div className={styles.headerLeft}></div>
        <div className={styles.headerRight}>
          <Button
            data-selenium-id='SIP_DetailPage_DecisionPanel-DecisionButton'
            color={saved ? 'savedGreen' : 'primary'}
            size='large'
            disabled={disabled}
            onClick={() => setOpened(!opened)}
            variant={opened ? 'outlined' : 'contained'}
            endIcon={opened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            <FormattedMessage id='siDetail.decision' />
          </Button>
        </div>
      </div>
      {showGroup === 1 && (
        <div className={styles.body}>
          <div className={styles.bodyTitle}>Decision</div>
          <div className={styles.content}>
            <div className={styles.contentLeft}>
              <DecisionTypeSelect
                value={decisionType}
                onChange={(_, value) => setDecisionType(value)}
              />
              {decisionType &&
                group1Left
                  .filter(component => component.action === decisionType)
                  .map(component => (
                    <FormItem
                      key={component.id}
                      value={form[component.componentKey]}
                      update={value => {
                        setForm(prev => {
                          prev[component.componentKey] = value;
                          return { ...prev };
                        });
                      }}
                      {...component}
                      onCommand={onCommand}
                      disabled={!formValid}
                      form={form}
                    />
                  ))}
            </div>
            <div className={styles.contentRight}>
              {decisionType &&
                group1Right
                  .filter(component => component.action === decisionType)
                  .map(component => (
                    <FormItem
                      key={component.id}
                      value={form[component.componentKey]}
                      update={value => {
                        setForm(prev => {
                          prev[component.componentKey] = value;
                          return { ...prev };
                        });
                      }}
                      {...component}
                      onCommand={onCommand}
                      disabled={!formValid}
                      form={form}
                      getPreSubmitForm={getPreSubmitForm}
                    />
                  ))}
            </div>
          </div>
        </div>
      )}
      {showGroup === 2 && (
        <div className={styles.body}>
          <div className={styles.content}>
            <div className={styles.contentFull}>
              {group2
                .filter(component => component.action === decisionType)
                .map(component => (
                  <FormItem
                    key={component.id}
                    value={form[component.componentKey]}
                    update={value => {
                      setForm(prev => {
                        prev[component.componentKey] = value;
                        return { ...prev };
                      });
                    }}
                    {...component}
                    onCommand={onCommand}
                    disabled={!formValid}
                    form={form}
                    getPreSubmitForm={getPreSubmitForm}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
      <ButtonAlertBox
        open={buttonAlert.open}
        handleClose={(event, reason) => {
          setButtonAlert(prev => ({ ...prev, open: false }));
        }}
        title={buttonAlert.title}
        message={buttonAlert.message}
        buttons={buttonAlert.buttons || []}
        autoHideDuration={buttonAlert.duration}
        severity={buttonAlert.severity}
        top='9px'
        style={{
          width: '546px',
          position: 'absolute',
          top: '8px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
    </div>
  );
}

export default DecisionPanel;

// Temporary method to judge showing the new decision panel.
export function showNewDecisionPanel(sipStage) {
  const useNewPanelStages = ['Ideation', 'Commissioning Editor Review','EBM Review'];
  return useNewPanelStages.includes(sipStage);
}

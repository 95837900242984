import {
  Box,
  Checkbox,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import SIPOverviewUncheckedIcon from '../../../../assets/SIPOverviewUncheckedIcon.svg';
import SIPOverviewCheckedIcon from '../../../../assets/SIPOverviewCheckedIcon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { SSIPitems, USIPitems, items } from './constantData';
import {
  addSelected,
  addSelectedAll,
  removeSelected,
  removeSelectedAll,
  setIsAllSeleted,
  setOrderBy,
} from '../../../../actions/SIMT-SIP/SIP/SIPAction';
import { visuallyHidden } from '@mui/utils';
import { StyledTableCell } from './cssComponent';
import CATJournalIndeterminate from '../../../../assets/catjournalindeterminate.svg';
import { useParams } from 'react-router-dom';

export default function SIPOverviewTableHead(props) {
  const { sipOrigin } = useParams();
  const dispatch = useDispatch();
  const selector = state => {
    return {
      // isAllSeleted: state.SIP.isAllSeleted,
      orderType: state.SIP.orderType,
      orderField: state.SIP.orderField,
      sipOverviewSeletedData: state.SIP.sipOverviewSeletedData,
      sipOverviewData: state.SIP.sipOverviewData,
      sipOverviewCodes: state.SIP.sipOverviewCodes,
      columnsSelected: state.SIP.columnsSelected,
    };
  };
  const {
    // isAllSeleted,
    orderType,
    orderField,
    sipOverviewSeletedData,
    sipOverviewData,
    selectedNum,
    sipOverviewCodes,
    columnsSelected,
  } = useSelector(selector);
  const isAllSeleted =
    sipOverviewSeletedData.length === sipOverviewCodes.length &&
    sipOverviewCodes.length !== 0;

  const handleSelectAll = e => {
    if (e.target.checked) {
      // console.log("cgt test1:\n",sipOverviewData)
      // for(let i=0;i<sipOverviewData.length;i++){
      //   if(sipOverviewSeletedData.indexOf(sipOverviewData[i].sipCode)===-1){
      //     dispatch(addSelected(sipOverviewData[i].sipCode))
      //   }
      // }
      // for (const sipItem of sipOverviewData) {
      //   dispatch(addSelected(sipItem.sipCode));
      // }
      // for (const sipCode of sipOverviewCodes) {
      //   dispatch(addSelected(sipCode));
      // }
      dispatch(addSelectedAll(sipOverviewCodes));
    } else {
      // for (const sipItem of sipOverviewData) {
      //   dispatch(removeSelected(sipItem.sipCode));
      // }
      // for (const sipCode of sipOverviewCodes) {
      //   dispatch(removeSelected(sipCode));
      // }
      dispatch(removeSelectedAll());
    }
    // if (sipOverviewData.length > 0) {
    //   dispatch(setIsAllSeleted(e.target.checked));
    // }
  };
  const handleSort = property => {
    const isAsc = orderField === property && orderType === 'asc';
    dispatch(setOrderBy(isAsc ? 'desc' : 'asc', property));
  };
  const doubleLine = [
    'Author List',
    'Time Sensitive',
    'Submission Date',
    'Latest Decision Date',
    'Full Manuscript Submission Deadline',
    'Acceptance Deadline',
    'Expected Publication Date',
    'Open Call for Papers',
  ];
  const existDoubleLine = label => {
    if (doubleLine.includes(label)) return true;
    else return false;
  };
  const ChooseHeadFormatByLabel = label => {
    let newLabel = label;
    if (doubleLine.includes(label)) {
      switch (label) {
        case 'Time Sensitive':
          newLabel = 'Time\nSensitive';
          break;
        // case 'Profile Website':
        //   newLabel = 'Profile\nWebsite';
        //   break;
        case 'Author List':
          newLabel = 'Author\nList';
          break;
        case 'Submission Date':
          newLabel = 'Submission\nDate';
          break;
        case 'Latest Decision Date':
          newLabel = 'Latest\nDecision Date';
          break;
        case 'Acceptance Deadline':
          newLabel = 'Acceptance\nDeadline';
          break;
        case 'Expected Publication Date':
          newLabel = 'Expected\nPublication Date';
          break;
        case 'Open Call for Papers':
          newLabel = 'Open Call\nfor Papers';
          break;
        case 'Full Manuscript Submission Deadline':
          newLabel = 'Full Manuscript\nSubmission Deadline';
          break;
        default:
          break;
      }
    }
    return newLabel;
  };
  return (
    <TableHead data-selenium-id='SIPPage_Overview-Table-Header-InnerHeader'>
      <TableRow data-selenium-id='SIPPage_Overview-Table-Header-Row'>
      {sipOrigin === 'solicited' ?<StyledTableCell
          data-selenium-id='SIPPage_Overview-Table-Header-StyledCell1'
          sx={{
            position: 'sticky',
            left: 0,
            minWidth: '72px !important',
            zIndex: 8,
            border: '0px solid #DFE4E8',
          }}
          lineHeight={'14px'}
          VerticalPadding={'0px'}
        >
          <Checkbox
            id='sipOverviewTableSelectAllBtn'
            data-selenium-id='SIPPage_Overview-Table-Header-SelectAllBtn'
            checked={isAllSeleted}
            indeterminate={!isAllSeleted && props.ssipNum > 0}
            onChange={handleSelectAll}
            icon={<SIPOverviewUncheckedIcon></SIPOverviewUncheckedIcon>}
            checkedIcon={<SIPOverviewCheckedIcon></SIPOverviewCheckedIcon>}
            indeterminateIcon={<CATJournalIndeterminate />}
            sx={{
              padding: '0px',
              paddingLeft: '3px',
              '&:hover': {
                bgcolor: 'transparent',
                '& g rect:last-child': {
                  stroke: '#596A7C',
                },
              },
              '& .MuiTouchRipple-root': {
                position: 'unset',
              },
              '&.Mui-checked:hover, &.MuiCheckbox-indeterminate:hover': {
                rect: {
                  fill: '#113D95',
                  // stroke: '#113D95',
                },
              },
            }}
          />
        </StyledTableCell>:<></>}
        {sipOrigin === 'unSolicted' ?<StyledTableCell
          data-selenium-id='SIPPage_Overview-Table-Header-StyledCell1'
          sx={{
            position: 'sticky',
            left: 0,
            minWidth: '72px !important',
            zIndex: 8,
            border: '0px solid #DFE4E8',
          }}
          lineHeight={'14px'}
          VerticalPadding={'0px'}
        >
          <Checkbox
            id='sipOverviewTableSelectAllBtn'
            data-selenium-id='SIPPage_Overview-Table-Header-SelectAllBtn'
            checked={isAllSeleted}
            indeterminate={!isAllSeleted && props.sipNum > 0}
            onChange={handleSelectAll}
            icon={<SIPOverviewUncheckedIcon></SIPOverviewUncheckedIcon>}
            checkedIcon={<SIPOverviewCheckedIcon></SIPOverviewCheckedIcon>}
            indeterminateIcon={<CATJournalIndeterminate />}
            sx={{
              padding: '0px',
              paddingLeft: '3px',
              '&:hover': {
                bgcolor: 'transparent',
                '& g rect:last-child': {
                  stroke: '#596A7C',
                },
              },
              '& .MuiTouchRipple-root': {
                position: 'unset',
              },
              '&.Mui-checked:hover, &.MuiCheckbox-indeterminate:hover': {
                rect: {
                  fill: '#113D95',
                  // stroke: '#113D95',
                },
              },
            }}
          />
        </StyledTableCell>:<></>}
        {/* ct5777也需要显示这个flag，无论是否是solicited sip，待定看甲方是否有异议 */}
        <StyledTableCell>
          <Box></Box>
        </StyledTableCell>
        {/* 新增的flag列 */}
        {/* {sipOrigin !== 'solicited' ?
          <StyledTableCell
            data-selenium-id='SIPPage_Overview-Table-Header-StyledCell1'
            sx={{
              position: 'sticky',
              left: 0,
              minWidth: '63px !important',
              zIndex: 7,
              border: '0px solid #DFE4E8',
            }}
            lineHeight={'14px'}
            VerticalPadding={'0px'}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                gap: "2px",
              }}>

            </Box>

          </StyledTableCell>
          : null
        } */}
        {(sipOrigin === 'solicited' ? SSIPitems : USIPitems).map(
          (item, index) => {
            return columnsSelected[item.id] !== false ? (
              <StyledTableCell
                data-selenium-id={`SIPPage_Overview-Table-Header-StyledCell2-${index}`}
                key={item.id}
                align={item.align}
                sx={{
                  maxWidth: item.maxWidth,
                  minWidth: item.minWidth,
                  position: item.stick ? 'sticky' : '',
                  left: item.stick ? item.stickPX : '',
                  zIndex: item.stick ? 7 : '',
                  color: '#596A7C',
                  border: '0px solid #DFE4E8',
                }}
                sortDirection={orderField === item.id ? orderType : false}
                VerticalPadding={existDoubleLine(item.label) ? '3px' : '0px'}
                maxWidth={item.maxWidth}
                minWidth={item.minWidth}
              >
                {!item.isOrder ? (
                  <Box
                    id={`sipOverviewTableHeadItem_${index}`}
                    data-selenium-id={`SIPPage_Overview-Table-Header-Item_-${index}`}
                    style={{ whiteSpace: 'pre-line' }}
                  >
                    {ChooseHeadFormatByLabel(item.label)}
                  </Box>
                ) : (
                  <TableSortLabel
                    id={`sipOverviewTableHeadItem_${index}`}
                    data-selenium-id={`SIPPage_Overview-Table-TableHeader-Item_-${index}`}
                    active={orderField === item.id}
                    direction={orderField === item.id ? orderType : 'asc'}
                    onClick={() => {
                      handleSort(item.id);
                    }}
                    sx={{
                      '&.Mui-active': {
                        color: '#596A7C',
                      },
                      verticalAlign: 'bottom',
                    }}
                  >
                    <Box style={{ whiteSpace: 'pre-line' }}>
                      {ChooseHeadFormatByLabel(item.label)}
                    </Box>
                    {orderField === item.id ? (
                      <Box
                        component='span'
                        sx={visuallyHidden}
                        data-selenium-id={`SIPPage_Overview-Table-Header-SpanBox-${index}`}
                      >
                        {orderType === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                )}
              </StyledTableCell>
            ) : null;
          }
        )}
      </TableRow>
    </TableHead>
  );
}

import { useEffect, useState } from 'react';
import FYSubjectGroupTable from './FYSubjectGroupTable';
import FYBasicTable from './FYBasicTable';
import FYBusinessDivisionTable from './FYBusinessDivisionTable';
import { Note } from '../Note';
import {
  FormControlLabel,
  Grid,
  InputLabel,
  Stack,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { StateButton } from '@/componentsphase2/StateButton';
import {
  TableGroupBox,
  TableLabel,
} from '../../ReportSpecificComponents/SpecialColorTableHeader';
import { StyledSwitchTwoColor } from '../../ReportSpecificComponents/SpecialColorSwitch';
import FYFilterDrawer from './NewFYFilterDrawer';
import { useDispatch, useSelector } from 'react-redux';
import {
  // fyChangeFiscalYear,
  fySetBusinessShowAll,
  fySetSubjectShowAll,
  fyExportFilter,
  fyExportCSVFilter,
} from '../../../../actions/SIMT-Report/ReportArticle/ReportArticleAction';
import {useFilterCounterFyHook} from '@/pages/Report/utils/useFilterCounterFyHook';
import {
  fyExport,
  fyExportCSV,
  fyExportCSVWithFilenameFromBackEnd,
  fyExportWithFilenameFromBackEnd
} from "@/actions/SIMT-Report/ReportArticle/Export";


export function TableContent() {
  const dispatch = useDispatch();
  const {
    // fiscalYear,
    selectMonthDate,
    subjectGroupShowAll,
    businessDivisionShowAll,
    ArticleReportGenenateDateFy,
    useFilter,
  } = useSelector(state => {
    return {
      // fiscalYear: state.Report.fyTrendsArticle.fiscalYear,
      selectMonthDate: state.Report.selectDate,
      subjectGroupShowAll: state.Report.fyTrendsArticle.subjectGroupShowAll,
      businessDivisionShowAll:
        state.Report.fyTrendsArticle.businessDivisionShowAll,
      ArticleReportGenenateDateFy: state.Report.ArticleReportGenenateDateFy,
      useFilter: state.Report.fyTrendsArticle.useFilter,
    };
  });

  const [filterOpen, setFilterOpen] = useState(false);

  const handleFilter = () => {
    setFilterOpen(true);
  };

  const filterNumber = useFilterCounterFyHook(useFilter);


  return (
    <>
      <>
        {/*basic table*/}
        <TableGroupBox
          maxWidth='100%'
          id='drawer-container'
          data-selenium-id='Report_Articles-FYTrends-Drawer-Container'
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            gap= '20px'
            sx={{
              paddingTop: '1px',
              paddingRight: '30px',
            }}
          >
            <TableLabel>
              Basic Table
              <Typography
                variant='caption'
                color='#FF8180'
                fontSize={14}
                sx={{ marginLeft: '18px' }}
              >
                <FormattedMessage id='report.tip' />
              </Typography>
            </TableLabel>
            <StateButton
              onClick={handleFilter}
              isPrimary={filterNumber != 0}
              filterNumber={filterNumber}
              titleName='Filter'
              sx={{ height: '34px', width: '74px', fontSize: '12px' }}
            />
          </Stack>
          <FYBasicTable />
          <FYFilterDrawer
            filterOpen={filterOpen}
            drawerWidth={328}
            handleClose={() => {
              setFilterOpen(false);
            }}
          />
        </TableGroupBox>

        {/*subject group table*/}
        <TableGroupBox
          width='100%'
          data-selenium-id='Report_Articles-FYTrends-SubjectGroupTable'
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              paddingTop: '1px',
              paddingRight: '30px',
            }}
          >
            <TableLabel sx={{ textTransform: 'capitalize' }}>
              <FormattedMessage id='report.subjectGroup' />
            </TableLabel>
            {/*控制subject group table 的All*/}
            <FormControlLabel
              control={
                <>
                  <StyledSwitchTwoColor
                    id='subjectGroupShowAll'
                    data-selenium-id='Report_Articles-FYTrends-SubjectGroupTable-SubjectGroupShowAll'
                    checked={subjectGroupShowAll}
                    onChange={e => {
                      dispatch(fySetSubjectShowAll(e.target.checked));
                    }}
                  />
                </>
              }
              label={''}
            />
          </Stack>
          <FYSubjectGroupTable sx={{ mt: '33px' }} />
        </TableGroupBox>

        <TableGroupBox width='100%'>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              paddingTop: '1px',
              paddingRight: '30px',
            }}
          >
            <TableLabel sx={{ textTransform: 'capitalize' }}>
              <FormattedMessage id='report.businessDivision' />
            </TableLabel>
            {/*控制business division table 的All*/}
            <FormControlLabel
              control={
                <>
                  <StyledSwitchTwoColor
                    id='businessDivisionShowAll'
                    data-selenium-id='Report_Articles-FYTrends-ReportTable-BusinessDivisionShowAll'
                    checked={businessDivisionShowAll}
                    onChange={e => {
                      dispatch(fySetBusinessShowAll(e.target.checked));
                    }}
                  />
                </>
              }
              label={''}
            />
          </Stack>
          <FYBusinessDivisionTable />
        </TableGroupBox>
        {/*提示语*/}
        <Note top='14px' />
      </>
    </>
  );
}
export default TableContent;

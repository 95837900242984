import styles from "../../index.module.scss";
import radioGroupClasses from "@/pages/SIPForm/components/RadioGroup/index.module.scss";
import formGroupClasses from "@/pages/SIPForm/components/FormGroup/index.module.scss";

import {SIPFormState} from "@/reducers/SIPFormReducer";
import {useDispatch, useSelector} from "react-redux";
import {RadioGroup} from "@/pages/SIPForm/components/RadioGroup";
import {Box, FormControlLabel, Link, Radio, RadioGroup as MuiRadioGroup, Tooltip, Typography} from "@mui/material";
import {useEffect} from "react";
import {setSIPFormSectionState, setSIPFormValue} from "@/actions/SIMT-SIP/SIPForm";
import {SectionState} from "@/pages/SIPForm/types.d";
import Card from "@/pages/SIPForm/components/Card";
import HelpIcon from "@/pages/SIPForm/assets/help-icon.svg";
import {InputBox} from "@/pages/SIPForm/components/InputBox";
import FormGroup from "@/pages/SIPForm/components/FormGroup";
import {useIntl} from "react-intl";

export interface ConflictOfIntersetSectionProps {

}

export const option1 = 'I declare that none of the Guest Editors have a conflict of interest.';
export const option2 = 'One (or more) editors have a conflict of interest.';

export function ConflictOfIntersetSection(props: ConflictOfIntersetSectionProps) {
    const selector = (state: {
        SIPForm: SIPFormState;
        [key: string]: any;
    }) => state.SIPForm;

    const {
        formData,
        state: formStatus,
    } = useSelector(selector);

    const { conflictOfInterest } = formData;
    const dispatch = useDispatch();
    const intl = useIntl();

    const proceeding = formStatus === 'proceeding';

    const sectionData = conflictOfInterest.data;
    const section = 'conflictOfInterest';

    const isCheckboxEmpty = sectionData.conflictOfInterest === null;
    const isAdditionalInformationEmpty = sectionData.conflictOfInterest === option2 && !sectionData.additionalInformation;

    const setValue = (value: string | null) => {
        dispatch(setSIPFormValue(section, 'conflictOfInterest', value));
    };

    const setAdditionalInformation = (value: string) => {
        dispatch(setSIPFormValue(section, 'additionalInformation', value));
    };

    useEffect(() => {
        if (isCheckboxEmpty || isAdditionalInformationEmpty) {
            dispatch(setSIPFormSectionState(section, proceeding ? SectionState.error : SectionState.default));
        } else {
            dispatch(setSIPFormSectionState(section, SectionState.completed));
        }
    }, [proceeding, isCheckboxEmpty, isAdditionalInformationEmpty]);


    return (
        <Card section={conflictOfInterest} withStar={true}>
            <Box className={styles['conflict-info-box']}>
                <Typography className={styles['info-text']} >Please find more information on Conflicts of Interest</Typography>
                <Link target="_blank" className={styles['info-link']} href={"https://authorservices.wiley.com/ethics-guidelines/index.html#8"}>here</Link>
            </Box>
            <MuiRadioGroup
                row={false}
                className={radioGroupClasses['radio-group'] + ' ' + (proceeding && isCheckboxEmpty ? radioGroupClasses['error'] : '')}
                value={sectionData.conflictOfInterest}
                onChange={ event => setValue(event.target.value)}
            >
                <FormControlLabel value={option1} control={<Radio />} label={option1} />
                <FormControlLabel value={option2} control={<Radio />} label={
                    <Typography className={styles['options2-line']}>
                        {option2} <>
                        &nbsp;
                        <Tooltip
                            title={'Please provide more details on the conflict(s) of interest.'}
                            placement="bottom-start"
                            arrow={true}
                            classes={{
                                tooltip: formGroupClasses['tooltip'],
                                arrow: formGroupClasses['tooltip-arrow']
                            }}
                        >
                            <HelpIcon className={formGroupClasses['help-icon']} />
                        </Tooltip>
                    </>
                    </Typography>
                } />
            </MuiRadioGroup>
            {sectionData.conflictOfInterest === option2 && (
                <FormGroup
                    title="Additional Information"
                    withoutTitle={true}
                    error={proceeding && isAdditionalInformationEmpty}
                    errorMessage={intl.messages['sipForm.errorText.required'] as string}
                    >
                    <InputBox
                        value={sectionData.additionalInformation ?? ''}
                        onChange={ (value) => setAdditionalInformation(value) }
                        error={proceeding && isAdditionalInformationEmpty}
                        placeholder={"Please provide more details about the conflict(s) of interest."}
                    />
                </FormGroup>
            )}
        </Card>
    );
}
import {
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import styles from '../../header-switch.module.scss';
import {
  exportReportCSV,
  exportReportExcel,
  selectDate as selectAcquireDate,
  exportReportCSVWithFilenameFromBackEnd,
  exportReportExcelWithFilenameFromBackEnd,
  exportReportExcelFilter,
  exportReportCSVFilter,
} from '@/actions/SIMT-Report/Report/ReportAction';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReportExportMenuButton from '../../ReportSpecificComponents/ReportExportMenuButton';
import { DatePickerStack } from '../../GenerationDate';
import { FormattedMessage } from 'react-intl';
import { TableLabel } from '../../ReportSpecificComponents/SpecialColorTableHeader';
import { StateButton } from '@/componentsphase2/StateButton';
import ExportAllDialog from '../../ExportAllDialog';
import {useFilterCounterFyHook} from '@/pages/Report/utils/useFilterCounterFyHook';
import {PERMISSIONS} from "@/constant/permission";

export function AcquirePageOverview({ showTable, setShowTable }) {
  const {
    selectAcquiredMonthDate,
    requireOverviewBasicTableData,
    reportAcquireOverviewFilters,
  } =
    useSelector(state => {
      return {
        // filter display
        // selectAcquiredMonthDate: state.Report.selectedAcquireDate,
        selectAcquiredMonthDate: state.Report.selectDate,
        requireOverviewBasicTableData:
          state.Report.requireOverviewBasicTableData,
        reportAcquireOverviewFilters:
          state.ReportFilter.reportAcquireOverviewFilters,
      };
    });

  const lastActionDate = requireOverviewBasicTableData?.lastActionDate;
  const GenerationDateAndTime =
    'Below reports are generated on ' + lastActionDate + ' (UTC time). ';
  const [selectMonth, setSelectMonth] = useState(new Date());
  const [months, setMonths] = useState([]);
  const dispatch = useDispatch();
  const currentDate = new Date();

  useEffect(() => {
    if (selectAcquiredMonthDate != null) {
      var Year = selectAcquiredMonthDate.split('-')[0];
      var Month = selectAcquiredMonthDate.split('-')[1] - 1;
      setSelectMonth(new Date(Year, Month));
    }
  }, [selectAcquiredMonthDate]);

  const exportExcelWithFilenameFromBackEnd = () => {
    var filename = `Report_Acquire_Active_${moment()
      .utc()
      .format('MMDDYYYY_HHmmss')}.xls`;
    var selectDate =
      Number(selectAcquiredMonthDate.split('-')[0]) * 100 +
      Number(selectAcquiredMonthDate.split('-')[1]);
    dispatch(
      exportReportExcelWithFilenameFromBackEnd(
        selectDate,
        '/reports/export/acquired-active/overview/excel',
        filename
      )
    );
  };

  const exportCsvWithFilenameFromBackEnd = () => {
    var url = '/reports/export/acquired-active/overview/csv';
    var selectDate =
      Number(selectAcquiredMonthDate.split('-')[0]) * 100 +
      Number(selectAcquiredMonthDate.split('-')[1]);
    dispatch(
      exportReportCSVWithFilenameFromBackEnd(
        selectDate,
        url,
        `Report_Acquire_Active_${moment()
          .utc()
          .format('MMDDYYYY_HHmmss')}_basic_table.csv`,
        'basic_table'
      )
    );
    dispatch(
      exportReportCSVWithFilenameFromBackEnd(
        selectDate,
        url,
        `Report_Acquire_Active_${moment()
          .utc()
          .format('MMDDYYYY_HHmmss')}_subject_group_table.csv`,
        'subject_group_table'
      )
    );
    dispatch(
      exportReportCSVWithFilenameFromBackEnd(
        selectDate,
        url,
        `Report_Acquire_Active_${moment()
          .utc()
          .format('MMDDYYYY_HHmmss')}_business_division_table.csv`,
        'business_division_table'
      )
    );
    if (localStorage.getItem('userPermissions').includes(PERMISSIONS.REPORT_READ_CE)){
      dispatch(
        exportReportCSVWithFilenameFromBackEnd(
          selectDate,
          url,
          `Report_Acquire_Active_${moment()
            .utc()
            .format('MMDDYYYY_HHmmss')}_CE/CAT_group_table.csv`,
          'CE/CAT_group_table'
        )
      );
    }
  };

  const getMonthList = () => {
    if (months.length === 0) {
      var year = currentDate.getFullYear();
      var month = currentDate.getMonth() + 1;
      for (var i = 0; i < 4; i++) {
        var tmpYear = year - i;
        var tmpMonth = 0;
        if (i === 0) {
          for (var j = month; j > 0; j--) {
            tmpMonth = j;
            months.push('' + tmpYear + '-' + tmpMonth);
            setMonths(months);
          }
        } else {
          for (var j = 12; j > 0; j--) {
            tmpMonth = j;
            months.push('' + tmpYear + '-' + tmpMonth);
            setMonths(months);
          }
        }
      }
    }
  };

  const handleDateChange = value => {
    setSelectMonth(value);
    var year = value.getFullYear();
    var month = value.getMonth() + 1;
    let date = year + '-' + month;
    dispatch(selectAcquireDate(date));
  };

  useEffect(() => {
    getMonthList();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth() + 1;
    var newDate = '' + year + '-' + month;
    if (selectAcquiredMonthDate === null) {
      dispatch(selectAcquireDate(newDate));
    }
  }, [dispatch]);

  const [exportOpen, setExportOpen] = useState(false);
  const exportExcelFilter = () => {
    let url = '/reports/export/acquired-active/overview/excelWithFilter';
    let selectDate =
      Number(selectAcquiredMonthDate.split('-')[0]) * 100 +
      Number(selectAcquiredMonthDate.split('-')[1]);
    dispatch(
      exportReportExcelFilter(selectDate, url, reportAcquireOverviewFilters)
    );
  };

  const handleExportExcel = type => {
    if (type === 'filter') {
      exportExcelFilter();
    } else {
      exportExcelWithFilenameFromBackEnd();
    }
  };

  const exportCsvFilterd = () => {
    let url = '/reports/export/acquired-active/overview/csvWithFilter';
    let selectDate =
      Number(selectAcquiredMonthDate.split('-')[0]) * 100 +
      Number(selectAcquiredMonthDate.split('-')[1]);
    // console.log(selectDate);
    dispatch(
      exportReportCSVFilter(
        selectDate,
        url,
        'basic_table',
        reportAcquireOverviewFilters
      )
    );
    dispatch(
      exportReportCSVFilter(
        selectDate,
        url,
        'subject_group_table',
        reportAcquireOverviewFilters
      )
    );
    dispatch(
      exportReportCSVFilter(
        selectDate,
        url,
        'business_division_table',
        reportAcquireOverviewFilters
      )
    );
    if (localStorage.getItem('userPermissions').includes(PERMISSIONS.REPORT_READ_CE)){
      dispatch(
        exportReportCSVFilter(
          selectDate,
          url,
          'CE/CAT_group_table',
          reportAcquireOverviewFilters
        )
      );
    };
  };
  const handleExportCSV = type => {
    if (type === 'filter') {
      exportCsvFilterd();
    } else {
      exportCsvWithFilenameFromBackEnd();
    }
  };
  return (
    <div
      style={{
        marginTop: '16px',
        marginBottom: '16px',
      }}
      data-selenium-id='Report_AcquireAndActiveSi-Overview-AcquirePageOverview-Div'
    >
      <ExportAllDialog
        open={exportOpen}
        handleClose={() => {
          setExportOpen(false);
        }}
        filterData={reportAcquireOverviewFilters}
        handleCSV={handleExportCSV}
        handleExcel={handleExportExcel}
      />
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        data-selenium-id='Report_AcquireAndActiveSi-Overview-AcquirePageOverview-Div-Grid1'
      >
        <DatePickerStack
          id='Report_AcquireAndActiveSi-Overview-AcquirePageOverview-Stack'
          selectedMonth={selectMonth}
          handleDateChange={handleDateChange}
          lastActionDate={lastActionDate}
          showTable={showTable}
          GenerationDateAndTime={GenerationDateAndTime}
        />
        <Grid
          data-selenium-id='Report_AcquireAndActiveSi-Overview-AcquirePageOverview-Div-Grid2'
          className={styles['action-buttons-container']}
        >
          {showTable && (
            <ReportExportMenuButton
              sx={{ height: '32px', marginRight: '24px' }}
              id='reportSipipelineExportButton'
              mode={1}
              // label='common.Export'
              onClick={() => {
                setExportOpen(true);
              }}
            />
          )}
          <span className={styles['switch']}>
            <span
              id='show-table-button'
              className={styles[showTable ? 'selected' : '']}
              onClick={() => {
                setShowTable(true);
              }}
            >
              Table
            </span>
            <span
              id='show-diagram-button'
              className={styles[!showTable ? 'selected' : '']}
              onClick={() => {
                setShowTable(false);
              }}
            >
              Diagram
            </span>
          </span>
        </Grid>
      </Grid>
    </div>
  );
}

export function AcqurireOverviewHeader(props) {
  const { filterOpen, filterOpenChange } = props;
  const { reportAcquireOverviewFilters } = useSelector(state => {
    return {
      reportAcquireOverviewFilters:
        state.ReportFilter.reportAcquireOverviewFilters,
    };
  });

  const filterNumber = useFilterCounterFyHook(reportAcquireOverviewFilters);

  return (
    <div data-selenium-id='Report_AcquireAndActiveSi-Overview-AcquirePageOverview-Div'>
      <Box data-selenium-id='Report_AcquireAndActiveSi-Overview-AcquirePageOverview-Div-Box1'>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          gap= '20px'
          sx={{
            paddingTop: '1px',
            paddingRight: '30px',
          }}
          data-selenium-id='Report_AcquireAndActiveSi-Overview-AcquirePageOverview-Div-Box1-Grid1'
        >
          <TableLabel>
            Basic Table
            <Typography
              variant='caption'
              color='#FF8180'
              fontSize={14}
              sx={{ marginLeft: '18px' }}
            >
              <FormattedMessage id='report.tip' />
            </Typography>
          </TableLabel>

          <Grid data-selenium-id='Report_AcquireAndActiveSi-Overview-AcquirePageOverview-Div-Box1-Grid2'>
            {filterOpen ? (
              <div></div>
            ) : (
              <Box>
                <StateButton
                  id='btnToggleGEFilterOpen'
                  data-selenium-id='Report_Sipipeline-BtnToggleGEFilterOpen'
                  onClick={filterOpenChange}
                  isPrimary={filterNumber != 0}
                  filterNumber={filterNumber}
                  titleName='Filter'
                  sx={{
                    height: '34px',
                    width: '74px',
                    fontSize: '12px',
                  }}
                />
              </Box>
            )}
          </Grid>
        </Stack>
      </Box>
    </div>
  );
}

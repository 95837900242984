// @ts-check
import * as React from 'react';
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Fab, fabClasses } from '@mui/material';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { scrollToTop } from '../../utils/commonUtils';
// @ts-ignore
import BackToTopIcon from '../../assets/backToTop.svg';

/**
 *
 * @param {import("./ScrollToTopButton").ScrollTopProps} props
 * @returns
 */
export function ScrollTop(props) {
  const { children, targetWindow, sx, ...other } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: targetWindow ? window : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <Fade in={trigger}>
      <Box
        onClick={() => scrollToTop()}
        role='presentation'
        sx={{
          position: 'fixed',
          bottom: 80,
          right: 28,
          zIndex: 1101,
          ...sx,
        }}
        {...other}
      >
        {children}
      </Box>
    </Fade>
  );
}

/**
 *
 * @param {import("./ScrollToTopButton").DetailScrollTopButtonProps} props
 * @returns
 */
export const DetailScrollTopButton = props => {
  const {
    targetWindow,
    fabProps = {},
    boxProps = {},
    buttonText = 'Back To Top ',
  } = props;
  const { sx: fabSx, ...fabOthers } = fabProps;
  return (
    <ScrollTop targetWindow={targetWindow} {...boxProps}>
      <Fab
        size='medium'
        variant='extended'
        color='primary'
        aria-label='scroll back to top'
        disableFocusRipple
        disableRipple
        id={'BackToTopButton'}
        sx={{
          borderRadius: '30px',
          [`&.${fabClasses.root}`]: {
            textTransform: 'none',
            boxShadow: 'none',
            fontWeight: 400,
          },
          '&:hover': {
            backgroundColor: 'primary.main',
          },
          ...fabSx,
        }}
        {...fabOthers}
      >
        {buttonText}
        <BackToTopIcon />
      </Fab>
    </ScrollTop>
  );
};

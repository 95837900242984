import React from 'react';
import SimpleIconButton from '../Button/SimpleIconButton';
import IconActive from './icon/AddIconActive.svg';
import IconDefault from './icon/AddIconDefault.svg';
import IconDisable from './icon/AddIconDisable.svg';
export default function AddIconButton(props) {
  return (
    <SimpleIconButton
      DefaultIcon={IconDefault}
      HoverIcon={IconActive}
      ActiveIcon={IconActive}
      DisabledIcon={IconDisable}
      {...props}
    ></SimpleIconButton>
  );
}
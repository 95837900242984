import {useEffect, useMemo, useState} from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
// Components
// Refactor Mark
// update relative pass to absolut path using default root '@'
import {IconButton} from '@mui/material';
import Framework from '../../../SIPage/Overview/Filter/component/Framework';
import FrameworkHeader from '../../../SIPage/Overview/Filter/component/Framework/Header';
import {IDContext} from '../../../SIPage/Overview/Filter/Context/index';
import FrameworkMain from '../../../SIPage/Overview/Filter/component/Framework/Main';
import FirstSection from './FunctionalComponents/FirstSection';
import SecondSection from './FunctionalComponents/SecondSection';
import ThirdSection from './FunctionalComponents/ThirdSection';
import FourthSection from './FunctionalComponents/FourthSection';
import FifthSection from './FunctionalComponents/FifthSection';
import TextButton from '../../../SIPage/Overview/Filter/component/CommonComponets/TextButton';
import {UntitledAlertBox} from '@/components/Alert/AlertBox';
import ResetButton from '../../../SIPage/Overview/Filter/component/CommonComponets/ResetButton';
import AdaptFilterButton from '../../../SIPage/Overview/Filter/component/CommonComponets/AdaptFilterButton';
import {
  loadFavourite,
  loadFavouriteFirst,
  loadFavouriteFromLocalStorage,
  saveFilters
} from "@/actions/SIMT-SIP/SIPFilter";
// Assets
import CloseHoverIcon from '@/assets/SIPFilterCloseIcon.svg';
import FilterCloseIcon from '@/assets/FilterCloseIcon.svg';
// Actions
import {
  resetFilter,
  setAdaptSIPFilterSelected,
  setFavSIPFilterSelected,
  setFilters,
} from '@/actions/SIMT-SIP/SIPFilter/SIPFilterAction';
import {handlePageChangeAction} from '@/actions/SIMT-SI/SpecialIssue/SIAction';
import {cancelSelectAll} from '@/actions/SeleteAction';
// utils
import {compareFilter, getSum, initSIPFilterSelected, prepareFilters, useSingleSIPDateRangePicker} from './utils';
import {deepEqual} from "@/componentsphase2/SelectBox/SelectInput";
import {useMount, useUpdateEffect} from "ahooks";

/**
 * Description of the main component
 * @returns
 */
export default function FilterPage({ id = 'filter', show = false, handleClose, isClear, setIsClear }) {
  const [hover, setHover] = useState(false);
  const [openLoadedFilterOptions, setOpenLoadedFilterOptions] = useState(false);
  const [openSaveSuccess, setOpenSaveSuccess] = useState(false);
  //  Inorder to translate local signal to firstSection and secondSection to reset their state, need some signal
  const [resetSignal, setResetSignal] = useState(false);

  const dispatch = useDispatch();
  const { sipOrigin } = useParams();

  const {
    useFilters,
    sipFilterSelected,
    adaptSIPFilterSelected,
    favSIPFilterSelected,
    userId,
  } = useSelector(state => {
    return {
      useFilters: state.SIPFilter.useFilters,
      sipFilterSelected: state.SIPFilter.sipFilterSelected,
      adaptSIPFilterSelected: state.SIPFilter.adaptSIPFilterSelected,
      favSIPFilterSelected: state.SIPFilter.favSIPFilterSelected,
      userId: state.User.userId,
    };
  });

  // description:
  const handleLoadFavouriteFilter = async () => {
    const isSuccess = await dispatch(loadFavourite(false, false, sipOrigin === 'solicited', userId));
    if (isSuccess) {
      setOpenLoadedFilterOptions(true);
    }
  };

  // Save filter
  const handleSaveFilter = async () => {
    // 从selected构造
    // 它不需要保留CeName，所以第二个参数直接传false
    const favFilters = prepareFilters(sipFilterSelected, true, false);
    const updateSucceeded = await dispatch(saveFilters(favFilters, sipOrigin === 'solicited', userId));
    if(updateSucceeded){
      setOpenSaveSuccess(true);
      dispatch(setFavSIPFilterSelected(favFilters));
    }
  };

  // Reset filter
  const handleReset = () => {
    dispatch(resetFilter());
    let favFilters = prepareFilters(initSIPFilterSelected, true, false);
    dispatch(saveFilters(favFilters, sipOrigin === 'solicited', userId));
    dispatch(setFavSIPFilterSelected(favFilters));
    dispatch(setAdaptSIPFilterSelected(favFilters));
    setResetSignal(!resetSignal);
    setIsClear(!isClear);
  };

  // Apply temperary filter
  const handleApply = async () => {
    const filters = prepareFilters(sipFilterSelected, true, false);
    await dispatch(setFilters(filters));
    dispatch(setAdaptSIPFilterSelected(prepareFilters(sipFilterSelected, true, false)));
    unstable_batchedUpdates(() => {
      dispatch(handlePageChangeAction(1)); // set page to 1 after changing the filters
      dispatch(cancelSelectAll()); // clear all selected items
    });
  };

  // Disable Button for Adapt Filter
  const isAdaptFilterButtonDisabled = useMemo(() => {
    const filters = prepareFilters(sipFilterSelected, true, false);
    return deepEqual(useFilters, filters) || (
        useSingleSIPDateRangePicker(sipFilterSelected)
    );
  }, [
    useFilters,
    sipFilterSelected
  ]);

  const isSaveButtonDisabled = useMemo(() => {
    const filters = prepareFilters(sipFilterSelected, true, false);
    return deepEqual(filters, favSIPFilterSelected);
  }, [sipFilterSelected, favSIPFilterSelected]);

  return (
    <IDContext.Provider value={id}>
      <Framework show={show}>
        <FrameworkHeader padding={true}>
          <ResetButton
            disabled={getSum(prepareFilters(sipFilterSelected, true, true)) === 0}
            handleClick={handleReset}
            Label={'Clear'}
          />
          <AdaptFilterButton
            disabled={isAdaptFilterButtonDisabled}
            handleClick={handleApply}
          />
          <TextButton
            id='btnResetToMyFilter'
            data-selenium-id='SIPPage_Overview-Filter-Overview-btnResetToMyFilter'
            label='Reset to my filter'
            handleClick={handleLoadFavouriteFilter}
            disabled={isSaveButtonDisabled}
          />
          <TextButton
            id='btnSaveAsMyFilter'
            data-selenium-id='SIPPage_Overview-Filter-Overview-btnSaveAsMyFilter'
            label='Save as my filter'
            handleClick={handleSaveFilter}
            disabled={isSaveButtonDisabled}
          />
          <IconButton
            disableRipple
            id='btnCloseSIFilter'
            data-selenium-id='SIPPage_Overview-Filter-Overview-btnCloseSIFilter'
            onClick={() => handleClose()}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            sx={{
              padding: '0px',
              width: '24px',
            }}
          >
            {hover ? <CloseHoverIcon /> : <FilterCloseIcon />}
          </IconButton>
        </FrameworkHeader>
        <FrameworkMain>
          <FirstSection resetSignal={resetSignal} />
          <SecondSection />
          <ThirdSection resetSignal={resetSignal} />
          <FourthSection />
          <FifthSection />
        </FrameworkMain>
      </Framework>
      <UntitledAlertBox
        style={{ top: '123px', left: '103px', right: 'aotu' }}
        id='sipPageFilterDrawerSaveFilterSuccessAlert'
        data-selenium-id='SIPPage_Overview-anotherNewFilterDrawer-Overview-Save-Filter-Success-Alert'
        open={openSaveSuccess}
        severity='success'
        message='Successfully saved your filter.'
        autoHideDuration={3000}
        handleClose={() => setOpenSaveSuccess(false)}
      />
      <UntitledAlertBox
        style={{ top: '123px', left: '103px', right: 'auto' }}
        id='sipPageFilterDrawerLoadFilterInfoAlert'
        data-selenium-id='SIPPage_Overview-anotherNewFilterDrawer-Overview-Load-Filter-Info-Alert'
        open={openLoadedFilterOptions}
        severity='info'
        message='Your saved filter options have been loaded.'
        autoHideDuration={3000}
        handleClose={() => setOpenLoadedFilterOptions(false)}
      />
    </IDContext.Provider>
  );
}

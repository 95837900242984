import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '../../../../components/DataTable';

import {
  PurpleGreenCell,
  NoBorderTableRow, StyledTableCellNormal, PurpleGreenDisCell, PurpleGreenDataCell, ReportNoData, TableBox2
} from "../../ReportSpecificComponents/SpecialColorTableHeader";
import { findLongestLineSubstring, measureWidth } from "../../../../utils/StringWidthMeasure";
import React from "react";
import {getTargetsCECAT} from "@/actions/SIMT-Report/Report/Target";
/**
 * @author LiuXin
 * @date 2022/5/11
 * @param {*} props
 * @returns
 */
function CECATTable(props) {
  const { fiscalYear } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const selector = state => {
    return {
      targetCECATInfo: state.Report.targetCECATInfo,
    };
  };
  const { targetCECATInfo } = useSelector(selector);

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    const items = [
      {
        id: 'groupName',
        label: intl.messages['targets.catGroup']
      },
      {
        id: 'handlingCe',
        label: intl.messages['targets.handlingCe']
      },
      ...(AllJournalsHeaderList.map(item => ({
        id: item.key,
        label: intl.messages[`targets.${item.id}`],
      }))),
      ...(OAJournalsHeaderList.map(item => ({
        id: item.key,
        label: intl.messages[`targets.${item.id}`],
      }))),
    ];
    for (let item of items){
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '18px Open Sans');
    }
    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (targetCECATInfo && targetCECATInfo.length > 0){
      const maxMap = {};
      for (let i = 0; i < targetCECATInfo.length; i++) {
        let dataObj = targetCECATInfo[i];

        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [targetCECATInfo]);

  function calcAllJournalWidth(){
    let sum = 0;
    for (let item of AllJournalsHeaderList) {
      sum += Math.max((columnWidthMap[item.key]??0), columnMinWidthMap[item.key]) + 50;
    }
    return sum;
  }

  function calcOAJournalWidth(){
    let sum = 0;
    for (let item of OAJournalsHeaderList) {
      sum += Math.max((columnWidthMap[item.key]??0), columnMinWidthMap[item.key]) + 50;
    }
    return sum;
  }

  useEffect(() => {
    async function getData() {
      if (
        fiscalYear !== null &&
        fiscalYear !== undefined &&
        fiscalYear !== ''
      ) {
        unstable_batchedUpdates(() => {
          dispatch(getTargetsCECAT(fiscalYear));
        });
      }
      setLoading(false);
    }
    setLoading(true);
    getData();
  }, [fiscalYear]);

  const AllJournalsHeaderList = [
    {
      id: 'acquired',
      key: 'targetAllAcquiredSi',
      minWidth: '100px',
      maxWidth: '100px',
      isOrder: false,
    },
    {
      id: 'submission',
      key: 'targetAllSubmission',
      minWidth: '125px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'acceptance',
      key: 'targetAllAcceptance',
      minWidth: '100px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'publication',
      key: 'targetAllPublication',
      minWidth: '100px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'journalsIncluded',
      key: 'targetAllJournalsIncluded',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: false,
    },
  ];

  const OAJournalsHeaderList = [
    {
      id: 'acquired',
      key: 'targetOaAcquiredSi',
      minWidth: '100px',
      maxWidth: '100px',
      isOrder: false,
    },
    {
      id: 'submission',
      key: 'targetOaSubmission',
      minWidth: '100px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'acceptance',
      key: 'targetOaAcceptance',
      minWidth: '100px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'publication',
      key: 'targetOaPublication',
      minWidth: '100px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'journalsIncluded',
      key: 'targetOaJournalsIncluded',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'stretchSubmission',
      key: 'targetOaStretchSubmission',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'stretchAcceptance',
      key: 'targetOaStretchAcceptance',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'stretchPublication',
      key: 'targetOaStretchPublication',
      minWidth: '150px',
      maxWidth: '150px',
      isOrder: false,
    },
  ];

  const showValueList = [
    'groupName',
    'handlingCe',
    'targetAllAcquiredSi',
    'targetAllSubmission',
    'targetAllAcceptance',
    'targetAllPublication',
    'targetAllJournalsIncluded',
    'targetOaAcquiredSi',
    'targetOaSubmission',
    'targetOaAcceptance',
    'targetOaPublication',
    'targetOaJournalsIncluded',
    'targetOaStretchSubmission',
    'targetOaStretchAcceptance',
    'targetOaStretchPublication',
  ];

  //如果表为空，返回一个空的列表
  const showDataTable = () => {
    if (targetCECATInfo === undefined || targetCECATInfo === null) {
      return [];
    } else return targetCECATInfo;
  };

  /**
   * @author LiuXin
   * @date 2022/5/12
   * @description 判断输入的属性是否为空，如果为空则返回0，否则返回原来的值
   * @param {*} showValue 需要展示的属性值
   * @returns
   */
  const handleNullProperty = showValue => {
    return showValue === null ? 0 : showValue;
  };

  const judgeColor = (row, col) => {
    if (row % 2 === 0) {
      if (col < 7) {
        return '#F5F7FB';
      } else {
        return '#EEFAE9';
      }
    }
    return '#ffffff';
  };

  return (
    <TableBox2
      data-selenium-id='Report_Targets_Overview-CECATTable-RootBox'
      dataList={[targetCECATInfo]}
      tableKey={'Report_Targets_Overview_CETable'}
      footerHeight={0}
    >
      <TableContainer
        id='reportTargetsTOCECATTable'
        data-selenium-id='Report_Targets_Overview-CECATTable-TableContainer'
        sx={{ maxHeight: '600px' }}
      >
        <Table
          stickyHeader
          aria--label='sticky table'
          data-selenium-id='Report_Targets_Overview-CECATTable-Table'
          sx={{
            tableLayout: 'fixed',
            width: 'max-content',
            maxWidth: 'none',
          }}
        >
          <TableHead data-selenium-id='Report_Targets_Overview-CECATTable-TableHead'>
            <TableRow data-selenium-id='Report_Targets_Overview-CECATTable-TableRow-1'>
              <StyledTableCellNormal
                data-selenium-id='Report_Targets_Overview-CECATTable-PurpleGreenCell-1'
                align='left'
                isOA={false}
                rowSpan={2}
                className='BottomBorder'
                sx={{
                  // minWidth: 140,
                  width: `${Math.max((columnWidthMap['groupName']??0), columnMinWidthMap['groupName']) + 50 }px`,
                  position: 'sticky',
                  left: 0,
                  top: 0,
                  zIndex: 8,
                  backgroundColor: '#DFE4E8',
                }}
              >
                <FormattedMessage
                  id='targets.catGroup'
                  data-selenium-id='Report_Targets_Overview-CECATTable-CatGroup'
                />
              </StyledTableCellNormal>
              <StyledTableCellNormal
                data-selenium-id='Report_Targets_Overview-CECATTable-PurpleGreenCell-2'
                align='left'
                isOA={false}
                rowSpan={2}
                className='RightBottomBorder'
                sx={{
                  // minWidth: 180,
                  width: `${Math.max((columnWidthMap['handlingCe']??0), columnMinWidthMap['handlingCe']) + 50 }px`,
                  position: 'sticky',
                  left: `${Math.max((columnWidthMap['groupName']??0), columnMinWidthMap['groupName']) + 50 }px`,
                  top: 0,
                  zIndex: 8,
                  backgroundColor: '#DFE4E8',
                }}
              >
                <FormattedMessage
                  id='targets.handlingCe'
                  data-selenium-id='Report_Targets_Overview-CECATTable-HandlingCe'
                />
              </StyledTableCellNormal>

              <PurpleGreenCell
                data-selenium-id='Report_Targets_Overview-CECATTable-PurpleGreenCell-3'
                className='RightBottomBorder'
                align='left'
                isOA={false}
                colSpan={5}
                sx={{
                  width: `${calcAllJournalWidth()}px`,
                }}
              >
                <FormattedMessage
                  id='targets.allJournals'
                  data-selenium-id='Report_Targets_Overview-CECATTable-AllJournals'
                />
              </PurpleGreenCell>
              <PurpleGreenCell
                data-selenium-id='Report_Targets_Overview-CECATTable-PurpleGreenCell-4'
                className='BottomBorder'
                align='left'
                isOA={true}
                colSpan={8}
                sx={{
                  width: `${calcOAJournalWidth()}px`,
                }}
              >
                <FormattedMessage
                  id='targets.oaJournals'
                  data-selenium-id='Report_Targets_Overview-CECATTable-OaJournals'
                />
              </PurpleGreenCell>
            </TableRow>
            <TableRow data-selenium-id='Report_Targets_Overview-CECATTable-TableRow-2'>
              {AllJournalsHeaderList.map((item, index) => (
                <PurpleGreenDisCell
                  data-selenium-id={
                    'Report_Targets_Overview-CECATTable-AllJournalsHeaderListItem' +
                    index
                  }
                  isOA={false}
                  align='left'
                  key={item.id + 'acquire'}
                  className={
                    index === AllJournalsHeaderList.length - 1
                      ? 'RightBorder BottomBorder'
                      : 'BottomBorder'
                  }
                  sx={{
                    // top: 52.5,
                    top: 42,
                    fontSize: '12px',
                    // minWidth: item.minWidth,
                    // maxWidth: item.maxWidth,
                    width: `${Math.max((columnWidthMap[item.key]??0), columnMinWidthMap[item.key]) + 50 }px`,
                  }}
                >
                  {intl.messages[`targets.${item.id}`]}
                </PurpleGreenDisCell>
              ))}
              {OAJournalsHeaderList.map((item, index) => (
                <PurpleGreenDisCell
                  data-selenium-id={
                    'Report_Targets_Overview-CECATTable-AllJournalsHeaderListItem' +
                    index
                  }
                  isOA={true}
                  align='left'
                  className='BottomBorder'
                  key={item.id}
                  sx={{
                    // top: 52.5,
                    top: 42,
                    fontSize: '12px',
                    // minWidth: item.minWidth,
                    // maxWidth: item.maxWidth,
                    width: `${Math.max((columnWidthMap[item.key]??0), columnMinWidthMap[item.key]) + 50 }px`,
                  }}
                >
                  {intl.messages[`targets.${item.id}`]}
                </PurpleGreenDisCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
            targetCECATInfo != null &&
            targetCECATInfo != undefined &&
            targetCECATInfo.length > 0 ? (
              showDataTable().map((item, rowIndex) => {
                if (
                  item.groupName !== 'Total' &&
                  item['groupName'].indexOf('Subtotal') === -1
                ) {
                  return (
                    <NoBorderTableRow
                      data-selenium-id={
                        'Report_Targets_Overview-CECATTable-BodyRow-' + rowIndex
                      }
                    >
                      {showValueList.map((column, colIndex) => {
                        if (column === 'groupName') {
                          return (
                            <StyledTableCell
                              data-selenium-id={
                                'Report_Targets_Overview-CECATTable-BodyRow-' +
                                rowIndex +
                                '-Col-' +
                                colIndex
                              }
                              sx={{
                                // top: 90,
                                // backgroundColor: judgeColor(rowIndex, colIndex),
                                position: 'sticky',
                                left: 0,
                                zIndex: 6,
                                backgroundColor: '#F1F3F5',
                              }}
                            >
                              <Tooltip
                                data-selenium-id={
                                  'Report_Targets_Overview-CECATTable-BodyRow-' +
                                  rowIndex +
                                  '-Col-' +
                                  colIndex +
                                  '-Tooltip'
                                }
                                title={item[column]}
                                disableInteractive
                              >
                                <div
                                  style={{
                                    textOverflow: 'ellipsis',
                                    // width: '140px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {handleNullProperty(item[column])}
                                </div>
                              </Tooltip>
                            </StyledTableCell>
                          );
                        }
                        else if (column === 'handlingCe') {
                          return (
                            <StyledTableCell
                              data-selenium-id={
                                'Report_Targets_Overview-CECATTable-BodyRow-' +
                                rowIndex +
                                '-Col-' +
                                colIndex
                              }
                              sx={{
                                // top: 90,
                                // backgroundColor: judgeColor(rowIndex, colIndex),
                                position: 'sticky',
                                left: `${Math.max((columnWidthMap['groupName']??0), columnMinWidthMap['groupName']) + 50 }px`,
                                zIndex: 6,
                                backgroundColor: '#F1F3F5',
                              }}
                              className='RightBorder'
                            >
                              <Tooltip
                                data-selenium-id={
                                  'Report_Targets_Overview-CECATTable-BodyRow-' +
                                  rowIndex +
                                  '-Col-' +
                                  colIndex +
                                  '-Tooltip'
                                }
                                title={item[column]}
                                disableInteractive
                              >
                                <div
                                  style={{
                                    textOverflow: 'ellipsis',
                                    // width: '170px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {handleNullProperty(item[column])}
                                </div>
                              </Tooltip>
                            </StyledTableCell>
                          );
                        } else if (column === 'targetAllJournalsIncluded') {
                          return (
                            <PurpleGreenDataCell
                              isOA={colIndex>6}
                              data-selenium-id={
                                'Report_Targets_Overview-CECATTable-BodyRow-' +
                                rowIndex +
                                '-Col-' +
                                colIndex
                              }
                              sx={{
                                // top: 90,
                                backgroundColor: judgeColor(rowIndex, colIndex),
                              }}
                              align='left'
                              className='RightBorder ReportNumberTextColor'
                            >
                              {item[column]}
                            </PurpleGreenDataCell>
                          );
                        } else {
                          return (
                            <PurpleGreenDataCell
                              isOA={colIndex>6}
                              data-selenium-id={
                                'Report_Targets_Overview-CECATTable-BodyRow-' +
                                rowIndex +
                                '-Col-' +
                                colIndex
                              }
                              sx={{
                                // top: 90,
                                backgroundColor: judgeColor(rowIndex, colIndex),
                              }}
                              align='left'
                              className='ReportNumberTextColor'
                            >
                              {handleNullProperty(item[column])}
                            </PurpleGreenDataCell>
                          );
                        }
                      })}
                    </NoBorderTableRow>
                  );
                } else {
                  return (
                    <NoBorderTableRow
                      data-selenium-id={
                        'Report_Targets_Overview-CECATTable-BodyRow-' + rowIndex
                      }
                    >
                      {showValueList.map((column, colIndex) => {
                        if (column === 'groupName') {
                          return (
                            <StyledTableCell
                              data-selenium-id={
                                'Report_Targets_Overview-CECATTable-BodyRow-' +
                                rowIndex +
                                '-Col-' +
                                colIndex
                              }
                              sx={{
                                // backgroundColor: judgeColor(rowIndex, colIndex),
                                position: 'sticky',
                                left: 0,
                                zIndex: 6,
                                backgroundColor: '#F1F3F5',
                              }}
                            ></StyledTableCell>
                          );
                        }
                        else if (column === 'handlingCe') {
                          return (
                            <StyledTableCell
                              data-selenium-id={
                                'Report_Targets_Overview-CECATTable-BodyRow-' +
                                rowIndex +
                                '-Col-' +
                                colIndex
                              }
                              sx={{
                                // top: 90,
                                color: '#434242',
                                fontSize: '16px',
                                lineHeight: '14px',
                                // backgroundColor: judgeColor(rowIndex, colIndex),
                                position: 'sticky',
                                left: `${Math.max((columnWidthMap['groupName']??0), columnMinWidthMap['groupName']) + 50 }px`,
                                zIndex: 6,
                                backgroundColor: '#F1F3F5',
                              }}
                              className='RightBorder'
                              align='right'
                            >
                              <strong>{item['groupName']}</strong>
                            </StyledTableCell>
                          );
                        } else if (column === 'targetAllJournalsIncluded') {
                          return (
                            <PurpleGreenDataCell
                              isOA={colIndex>6}
                              data-selenium-id={
                                'Report_Targets_Overview-CECATTable-BodyRow-' +
                                rowIndex +
                                '-Col-' +
                                colIndex
                              }
                              sx={{
                                // top: 90,
                                fontWeight: '600',
                                backgroundColor: judgeColor(rowIndex, colIndex),
                              }}
                              align='left'
                              className='RightBorder ReportNumberTextColor'
                            >
                              {item[column]}
                            </PurpleGreenDataCell>
                          );
                        } else {
                          return (
                            <PurpleGreenDataCell
                              isOA={colIndex>6}
                              data-selenium-id={
                                'Report_Targets_Overview-CECATTable-BodyRow-' +
                                rowIndex +
                                '-Col-' +
                                colIndex
                              }
                              sx={{
                                // top: 90,
                                fontWeight: '600',
                                backgroundColor: judgeColor(rowIndex, colIndex),
                              }}
                              align='left'
                              className='ReportNumberTextColor'
                            >
                              {handleNullProperty(item[column])}
                            </PurpleGreenDataCell>
                          );
                        }
                      })}
                    </NoBorderTableRow>
                  );
                }
              })
            ) : !loading ? (
              <ReportNoData id={'Report-Targets-CECATTable-NoData'}/>
            ) : (
              <div></div>
            )}
          </TableBody>
        </Table>
        {loading && (
          <Box
            data-selenium-id='Report_Targets_Overview-CECATTable-LoadingBox'
            sx={{
              width: 'auto',
              marginTop: '100px',
              marginLeft: '50%',
            }}
          >
            <CircularProgress data-selenium-id='Report_Targets_Overview-CECATTable-LoadingCircularProgress' />
          </Box>
        )}
      </TableContainer>
    </TableBox2>
  );
}

export default CECATTable;

import styles from './index.module.scss';

function Label({ name, required = false, children }) {
  return (
    <div className={styles.label}>
      <div className={styles.left}>
        <span>
          {name}
          {required && <i>*</i>}
        </span>
      </div>
      <div className={styles.right}>{children}</div>
    </div>
  );
}

export default Label;

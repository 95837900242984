import { TableFooter2 } from '@/components/DataTable';
import { StyledTableCell } from '@/components/DataTable';
import NoDataMessage from '@/componentsphase2/NoDataMessage';
import {
  Box,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import ShowDataTable from '../../compontents/showDataTable';

export default function activeSI(props) {
  const { tableData } = props;
  const titleList = [
    // ['title','width','title text-align','info text-align,]
    ['SI CODE', '151px', '', '', 'url'],
    ['SI TITLE', '254px'],
    ['STAGE', '205px'],
    ['SUBMITTED', '123px'],
    ['ACCEPTED', '115px'],
    ['REJECTED', '110px'],
    ['IN PROGRESS', '135px'],
  ];
  // const formedTableData = [
  //   ['info', 'info info info info info info info info info info info info info info info info info info info info info info info info info info info info info info ', 'infoinfo info info info info info info info info info info info info info info info info info info info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info', 'info', 'info', 'info'],
  // ];
  const formedTableData = tableData.map(item => [
    { code: item['siCode'], type: item['alertGeFlagType'] },
    item['siTitle'],
    item['siStage'],
    item['numberOfSubmittedArticles'],
    item['numberOfAcceptedArticles'],
    item['numberOfRejectedArticles'],
    item['numberOfInProgressArticles'],
  ]);
  return <ShowDataTable titleList={titleList} tableData={formedTableData} />;
}

import {
  IDEA_OR_PROPOSAL,
  ACQUIRED,
  PAPER_COMMISSION,
  SUBMISSION_AND_REVIEW,
  PRODUCTION,
  PUBLISHED_IN_SELECTED_FY,
  IDEA_OR_PROPOSAL_OA,
  ACQUIRED_OA,
  PAPER_COMMISSION_OA,
  SUBMISSION_AND_REVIEW_OA,
  PRODUCTION_OA,
  PUBLISHED_OA,
  getTopBarForOa,
  SI_STAGES_COLOR,
} from '../../constants.js';

import { OABarPieChartOptions } from '@/pages/Report/chartUtils';

const LEGEND_POS = 200;
const LABLE_SIZE = [15, 10, 7];
const MAX_LABEL_SIZE = 18;

export class OAChartOptions extends OABarPieChartOptions {
  constructor() {
    super();
    // overwrite the default properties
    this.pieColors = SI_STAGES_COLOR;
    this.legend_pos = LEGEND_POS;
  }

  // overwrite the default methods
  getBarSeries() {
    return [
      // all
      this.setBarSerieOption({
        datasetIndex: 0,
        attributeName: IDEA_OR_PROPOSAL,
        stackName: 'total',
        color: '#71C8FF',
        borderColor: '#71C8FF',
        textColor: '#FFFFFF',
      }),
      this.setBarSerieOption({
        datasetIndex: 0,
        attributeName: ACQUIRED,
        stackName: 'total',
        color: '#4C81EB',
        borderColor: '#4C81EB',
        textColor: '#FFFFFF',
      }),
      this.setBarSerieOption({
        datasetIndex: 0,
        attributeName: PAPER_COMMISSION,
        stackName: 'total',
        color: '#9098E2',
        borderColor: '#9098E2',
        textColor: '#FFFFFF',
      }),
      this.setBarSerieOption({
        datasetIndex: 0,
        attributeName: SUBMISSION_AND_REVIEW,
        stackName: 'total',
        color: '#FFB152',
        borderColor: '#FFB152',
        textColor: '#FFFFFF',
      }),
      this.setBarSerieOption({
        datasetIndex: 0,
        attributeName: PRODUCTION,
        stackName: 'total',
        color: '#42C1B3',
        borderColor: '#42C1B3',
        textColor: '#FFFFFF',
      }),
      this.setBarSerieOption({
        datasetIndex: 0,
        attributeName: PUBLISHED_IN_SELECTED_FY,
        stackName: 'total',
        color: '#FF8180',
        borderColor: '#FF8180',
        textColor: '#FFFFFF',
      }),
      // OA
      this.setBarSerieOption({
        datasetIndex: 0,
        attributeName: IDEA_OR_PROPOSAL_OA,
        stackName: 'OA',
        color: '#F0F9FF',
        borderColor: '#92D4FF',
        textColor: '#596A7C',
      }),
      this.setBarSerieOption({
        datasetIndex: 0,
        attributeName: ACQUIRED_OA,
        stackName: 'OA',
        color: '#CEDDF9',
        borderColor: '#6D98EE',
        textColor: '#596A7C',
      }),
      this.setBarSerieOption({
        datasetIndex: 0,
        attributeName: PAPER_COMMISSION_OA,
        stackName: 'OA',
        color: '#E8E9F9',
        borderColor: '#A9AFE8',
        textColor: '#596A7C',
      }),
      this.setBarSerieOption({
        datasetIndex: 0,
        attributeName: SUBMISSION_AND_REVIEW_OA,
        stackName: 'OA',
        color: '#FFF0DD',
        borderColor: '#FFCA7B',
        textColor: '#596A7C',
      }),
      this.setBarSerieOption({
        datasetIndex: 0,
        attributeName: PRODUCTION_OA,
        stackName: 'OA',
        color: '#E7F6F8',
        borderColor: '#73CDD7',
        textColor: '#596A7C',
      }),
      this.setBarSerieOption({
        datasetIndex: 0,
        attributeName: PUBLISHED_OA,
        stackName: 'OA',
        color: '#FFDDDD',
        borderColor: '#FFB0AE',
        textColor: '#596A7C',
      }),
    ];
  }

  /**
   * overwrite default setSelectedLegend method to select both all and OA legends
   * @param {*} selectedLegend
   * @returns this
   */
  setSelectedLegend(selectedLegend) {
    let oaAndAllSelected = Object.assign({}, selectedLegend);
    // get keys
    const keysForAll = Object.keys(selectedLegend);
    // insert OA counter part
    keysForAll.forEach(keyForAll => {
      const keyForOA = keyForAll + ' OA';
      oaAndAllSelected[keyForOA] = oaAndAllSelected[keyForAll];
    });

    // console.log("===oaAndAllSelected===", oaAndAllSelected)
    this.selectedLegend = oaAndAllSelected;
    return this;
  }

  getLabelMinFontSize() {
    // console.log('=== this.dataForBar===', this.dataForBar);
    const numberOfBars = this.dataForBar && this.dataForBar.length;

    let fontSize;

    if (numberOfBars < 7) {
      fontSize = LABLE_SIZE[0];
    } else if (numberOfBars < 12) {
      fontSize = LABLE_SIZE[1];
    } else {
      fontSize = LABLE_SIZE[2];
    }

    return fontSize;
  }

  getLabelFontSize() {
    // console.log()
    return [this.getLabelMinFontSize(), MAX_LABEL_SIZE];
  }

  setWidth(width) {
    this.width = width;

    if (width !== '100%') {
      const half_width = width / 2;
      this.legend_pos = half_width - 325;
    }

    return this;
  }

  getLegendStyle() {
    let legendStyle = super.getLegendStyle();
    legendStyle = {
      ...legendStyle,
      left: this.legend_pos,
    };
    return legendStyle;
  }

  getLegendLabelStyle() {
    let legendLabelStyle = super.getLegendLabelStyle();
    legendLabelStyle = {
      ...legendLabelStyle,
      left: this.legend_pos - 60,
    };
    return legendLabelStyle;
  }
}

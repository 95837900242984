import {axios_instance} from "@/utils/axios_instance";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import simpleDisplayError from "@/utils/simpleDisplayError";
import {GET_PROPOSER, UPLOAD_GEEVIENCE} from "@/actions/SIMT-SIP/SIPChecklistSubmitted/SIPChecklistAction";

export function uploadFile(file, cancelToken, updateFunc, finishFunc) {
    return async dispatch => {
        try {
            const url = '/api/v1/sip-service/special-issue-proposals/EMPTY/checkLists/uploadFile';
            let param = new FormData();
            param.append('file', file);
            const config = {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
                onUploadProgress: e => {
                    if (e.lengthComputable) {
                        let percentLoaded = Math.round((e.loaded / e.total) * 100);
                        // 更新进度条长度
                        if (percentLoaded <= 100) {
                            updateFunc(percentLoaded);
                        }
                    }
                },
                cancelToken: cancelToken.token,
                timeout: 100000,
            };
            const res = await axios_instance.post(url, param, config);
            const {code,message,data} = res.data;
            if (code === 200) {
                dispatch({type: UPLOAD_GEEVIENCE, data: res.data.data});
                finishFunc(data);
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'gePage.updateError',
                        {
                            value: message,
                        },
                        SEVERITIES.error
                    )
                );
                dispatch({type: UPLOAD_GEEVIENCE, data: ''});
            }
        } catch (e) {
            simpleDisplayError(e);
        }
    };
}
import { useIntl } from 'react-intl';

function createRow(
  id,
  label,
  isOrder = true,
  minWidth = '110px',
  maxWidth = '220px',
  show = true,
  stick = undefined,
  stickPX = '72px'
) {
  return { id, label, isOrder, minWidth, maxWidth, show, stick, stickPX };
}

export const HeadItems = () => {
  const Intl = useIntl();
  return [
    createRow(
      'requestType',
      Intl.messages['catJournal.requestHistory.requestType'],
      false,
      '174px',
      '174px'
    ),
    createRow(
      'requestDate',
      Intl.messages['catJournal.requestHistory.requestDate'],
      true,
      '174px',
      '174px'
    ),
    createRow(
      'journalCode',
      Intl.messages['catJournal.requestHistory.journalCode'],
      false,
      '148px',
      '148px'
    ),
    createRow(
      'journalTitle',
      Intl.messages['catJournal.requestHistory.journalTitle'],
      false,
      '257px',
      '257px'
    ),
    createRow(
      'requestBy',
      Intl.messages['catJournal.requestHistory.requestBy'],
      false,
      '162px',
      '162px'
    ),
    createRow(
      'requestReason',
      Intl.messages['catJournal.requestHistory.requestReason'],
      false,
      '523px',
      '523px'
    ),
    createRow(
      'status',
      Intl.messages['catJournal.requestHistory.status'],
      false,
      '148px',
      '148px'
    ),
    createRow(
      'decisionBy',
      Intl.messages['catJournal.requestHistory.decisionBy'],
      false,
      '162px',
      '162px'
    ),
    createRow(
      'decisionReason',
      Intl.messages['catJournal.requestHistory.decisionReason'],
      false,
      '523px',
      '523px'
    ),
    createRow(
      'statusDate',
      Intl.messages['catJournal.requestHistory.statusDate'],
      true,
      '164px',
      '164px'
    ),
  ];
};

import * as actions from '../actions/BreadcrumbAction';

const initState = {
  customizedPath: [],
};

export default function BreadcrumbReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.SET_CUSTOMIZED_PATH:
      return {
        ...state,
        customizedPath: data,
      };
    case actions.CLEAR_CUSTOMIZED_PATH:
      return initState;
    default:
      return state;
  }
}

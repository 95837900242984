// @ts-check
import React from 'react';
import Header from '../../../../components/Header';

/**
 *
 * @param {import("../../../../components/Header").HeaderProps} props
 * @returns
 */
export const SIPRevisePageHeader = props => {
  const { sx, ...others } = props;
  return (
    <Header
      data-selenium-id='SIP_RevisePage_CustomComponents-Header'
      sx={{
        '& .MuiToolbar-root': {
          paddingX: '128px',
        },
        ...sx,
      }}
      {...others}
    />
  );
};

import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useMount } from "ahooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ShowForPermission } from "../../../components/PermissionControl/ShowForPermission";
import {ChangeListValue} from "@/actions/SIMT-SIP/SIPFilter/SIPFilterAction";
import {useDispatch,useSelector} from "react-redux";
import {loadFavouriteFirst} from "@/actions/SIMT-SIP/SIPFilter";
import {
  removeSelectedAll,
  removeSelectedAllAndPageNumber, setIsSIPOverviewDataByPermission,
  setPageNum,
  setPageSize
} from "@/actions/SIMT-SIP/SIP/SIPAction";
import {unstable_batchedUpdates} from "react-dom";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    value: index
  };
}
export default function SIPOverviewTabs(props) {
  const { 
    ...others
  } = props;
  const navigate = useNavigate();
  const { sipOrigin } = useParams();
  const dispatch = useDispatch();
  const value = sipOrigin === 'solicited' ? 1 : 0;
  const { changeList,userId } = useSelector(state => {
    return {
      changeList:state.SIPFilter.changeList,
      userId: state.User.userId,
    };
  });

  useMount(() => {
    dispatch(removeSelectedAllAndPageNumber());
  });

  return (
    <Box sx={{
      fontWeight:'600',
      marginTop:'19px'
    }}>
      <Tabs value={value} onChange={(e, v) => {
        unstable_batchedUpdates(() => {
          dispatch(ChangeListValue(!changeList));
          // this is 'Only show SIP to be assigned' checkbox, it is not used in the new design
          // keep it here for reference, and remove it if it is not needed
          // dispatch(setIsSIPOverviewDataByPermission(false));
          dispatch(removeSelectedAllAndPageNumber());
        });
        navigate(`/simt/auth/sipRelated/sip/overview/${v === 1 ? 'solicited' : 'unSolicted'}`);
      }}>
        <Tab label="SOLICITED SIPS" {...a11yProps(1)} sx={{height:'52px'}}/>
        {/*<ShowForPermission permission={`sip:write-jpa`}>*/}
          <Tab label="UNSOLICITED SIPS" {...a11yProps(0)} />
        {/*</ShowForPermission>*/}
      </Tabs>
    </Box>
  );
}

import {axios_instance} from '@/utils/axios_instance';
import {buildParamsForFilter, getAPIPath} from '../../../utils/filterUtils';
import {parseISO} from 'date-fns';

export const LOAD_DATA = 'LOAD_DATA';
export const SET_SELECT = 'SET_SELECT';
export const LOAD_FAVOURITE = 'LOAD_FAVOURITE';
export const SAVE_FAVOURITE = 'SAVE_FAVOURITE';
export const RESET_FILTER = 'RESET_FILTER';
export const SET_FILTER = 'SET_FILTER';
export const SET_DATE = 'SET_DATE';
export const CLEAR_USE_FILTER = 'CLEAR_USE_FILTER';
export const SEARCH_SI = 'SEARCH_SI';
export const REPORT_FILTER_RESET = 'REPORT_FILTER_RESET';

export const SET_BASIC_REPORT_FILTE = 'SET_BASIC_REPORT_FILTE';
export const RESET_ACQUIRED_DATE_FILTER = 'RESET_ACQUIRED_DATE_FILTER';
export const RESET_DEADLINE_DATE_FILTER = 'RESET_DEADLINE_DATE_FILTER';
export const SET_ADAPT_SIFILTERSELECTED = 'SET_ADAPT_SIFILTERSELECTED';
export const SET_FAV_SIFILTERSELECTED = 'SET_FAV_SIFILTERSELECTED';
export const FIRST_LOAD_FAVOURITE = 'FIRST_LOAD_FAVOURITE';


export function setSearchSI(searchSI) {
  return dispatch => {
    dispatch({
      type: SEARCH_SI,
      data: searchSI,
    });
  };
}

export function clearUseFilter() {
  return dispatch => {
    dispatch({ type: CLEAR_USE_FILTER });
  };
}

export function clearReportFilter() {
  return dispatch => {
    dispatch({ type: REPORT_FILTER_RESET });
  };
}

export function loadData(which, searchContent = '', page = 1, pageSize = 5000) {
  let apiPath = getAPIPath(which);
  return async dispatch => {
    try {
      let res;
      res = await axios_instance.get(apiPath, {
        params: {
          ...buildParamsForFilter(which, searchContent, page, pageSize),
        },
        headers: {
          authorization: localStorage.getItem('authorization'),
        },
      });
      if (res.data.code === 200) {
        if (which === 'handlingCe') {
          dispatch({
            type: LOAD_DATA,
            data: {
              which: which,
              page: page,
              data: { result: res.data?.data?.result, total: res.data?.data?.total },
            },
          });
        } else {
          dispatch({
            type: LOAD_DATA,
            data: {
              which: which,
              page: page,
              data: { result: res.data.data, total: res.data?.total },
            },
          });
        }
      } else {
        dispatch({
          type: LOAD_DATA,
          data: { which: which, page: page, data: { result: [], total: 0 } },
        });
      }
    } catch (err) {
      dispatch({
        type: LOAD_DATA,
        data: { which: which, page: page, data: { result: [], total: 0 } },
      });
    }
  };
}

export function setSelect(which, data) {
  return dispatch => {
    dispatch({ type: SET_SELECT, data: { which: which, data: data } });
  };
}

export function batchSelectSIFilter(data) {
  const converted = handleFavData(data);
  return dispatch => {
    dispatch({ type: LOAD_FAVOURITE, data: converted });
  };
}

export const resetFilter = () => ({
  type: RESET_FILTER,
});

export const resetAcquiredDateFilter = () => ({
  type: RESET_ACQUIRED_DATE_FILTER,
});

export const resetDeadlineDateFilter = () => ({
  type: RESET_DEADLINE_DATE_FILTER,
});

// convert the saved filter from backend to the type that frontend accepts
export function handleFavData(data) {
  // 特殊处理handling ce
  const handlingCeTemp = [];
  for (let i = 0; i < data?.handlingCe?.length; i++) {
    const userId = data.handlingCe[i];
    const fullName = data.handlingCeName[i];
    const parts = fullName.split(/\s*\(/);
    const username = parts[0];
    const email = parts[1].substring(0, parts[1].length - 1);
    handlingCeTemp.push({
      userId: parseInt(userId),
      username: username,
      email: email,
    });
  }
  // 特殊处理group
  // const groupTemp = [];
  // for (let i = 0; i < data?.group?.length; i++) {
  //   const id = data.group[i];
  //   const value = data.groupNames[i];
  //   groupTemp.push({
  //     id: parseInt(id),
  //     value: value,
  //   });
  // }
  return {
    ...data,
    handlingCe: handlingCeTemp,
    // group: data.groupNames,
  };
}

// 将use的格式变为select的格式
export const filterUsed2SelectState = data => {
  return {
    siFilterSelected: {
      leadGeFullNameSelected: data.leadGeFullName ?? [],
      coGeFullNameSelected: data.coGeFullName ?? [],
      handlingCeSelected: data.handlingCe ?? [],
      catGroupSelected: data.groupNames ?? [],
      journalSelected: data.journalName ?? [],
      siCodeSelected: data.siCode ?? [],
      pdPublisherSelected: data.pdPublisher ?? [],
      journalrevenueModelSelected: data.journalRevenueModel ?? [],
      subjectGroupSelected: data.SubjectGroup ?? [],
      stageSelected: data.stage ?? [],
      sisWithAlertsSelected: data?.sisWithAlerts ?? [],
      siTitleSelected: data?.siTitle ?? [],
      acquiredFromDate:
        data.acquiredDateFrom && data.acquiredDateFrom[0]
          ? parseISO(data.acquiredDateFrom[0])
          : null,
      acquiredToDate:
        data.acquiredDateTo && data.acquiredDateTo[0]
          ? parseISO(data.acquiredDateTo[0])
          : null,
      deadlineFromDate:
        data.submissionDeadlineFrom && data.submissionDeadlineFrom[0]
          ? parseISO(data.submissionDeadlineFrom[0])
          : null,
      deadlineToDate:
        data.submissionDeadlineTo && data.submissionDeadlineTo[0]
          ? parseISO(data.submissionDeadlineTo[0])
          : null,
      apToDate:
        data.apToDate && data.apToDate[0] ? parseISO(data.apToDate[0]) : null,
      apFromDate:
        data.apFromDate && data.apFromDate[0] ? parseISO(data.apFromDate[0]) : null,
      createToDate:
        data.createToDate && data.createToDate[0]
          ? parseISO(data.createToDate[0])
          : null,
      createFromDate:
        data.createFromDate && data.createFromDate[0]
          ? parseISO(data.createFromDate[0])
          : null,
      stateSelected: data?.state ?? [],
      closedforSubmissionSelected: data?.closedforSubmission ?? [],
      siWithChinaGESelected: data?.siWithChinaGE ?? [],
    },
  };
};

export function setFilters(filters) {
  return dispatch => {
    dispatch({ type: SET_FILTER, data: filters });
  };
}

export function setDate(which, type, newDate) {
  return dispatch => {
    dispatch({
      type: SET_DATE,
      data: { which: which, type: type, newDate: newDate },
    });
  };
}

export function setBasicReportFilters(filters) {
  return dispatch => {
    dispatch({ type: SET_BASIC_REPORT_FILTE, data: filters });
  };
}

export function setHasFilter(hasFilter){
  return dispatch => {
    dispatch({ type: SET_HAS_FILTER, data: hasFilter });
  };
}

export function setAdaptSIFilterSelected(adaptSIFilterSelected){
  return dispatch => {
    dispatch({ type: SET_ADAPT_SIFILTERSELECTED, data: adaptSIFilterSelected });
  };
}

export function setFavSIFilterSelected(favSIFilterSelected){
  return dispatch => {
    dispatch({ type: SET_FAV_SIFILTERSELECTED, data: favSIFilterSelected });
  };
}

import {
  Box,
  Grid,
} from '@mui/material';
import { createSelector } from 'reselect';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import GEproposerAndVerificationTable from './GEproposerAndVerificationTable';

import {getGeByFullname} from "@/actions/SIMT-SI/GuestEditor";
import {getFlaggedGEProposerDetail} from "@/actions/SIMT-SIP/FlaggedGEProposer/SIPChecklistSubmitted";
import { ButtonAlertBox } from "../../../components/Alert/AlertBox";

export default function FlagGEVerification() {

  // const [snackBarShow, setSnackbarShow] = useState(false);
  // const [snackBarIdToShow, setSnackbarIdToShow] = useState(0);
  const [buttonAlertButtons, setButtonAlertButtons] = useState([]);
  const [geCode, setGECode] = useState('');
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getFlaggedGEProposerDetail());
  }, []);

  const [isAllVerificationFinshedArray, setIsAllVerificationFinshedArray] =
    useState([]);
  const [isAllVerificationFinshed, setIsAllVerificationFinshed] =
    useState(true);

  const [verifiedSnackBarShow, setVerifiedSnackBarShow] = useState(false);
  const [verifiedAlertButtons, setVerifiedAlertButtons] = useState([]);
  const navigate = useNavigate();

  const selectUserManageSiderOpen = state => state.UserManage.siderOpen;
  const selectFlaggedGEProposerDetail = state => state.FlaggedGEProposer;

  const selectorProposer = createSelector(
    selectUserManageSiderOpen,
    selectFlaggedGEProposerDetail,
    (userManageSiderOpen, FlaggedGEProposerDetail) => ({
      userManageSiderOpen,
      FlaggedGEProposerDetail,
    })
  );

  const { userManageSiderOpen, FlaggedGEProposerDetail } =
    useSelector(selectorProposer);

  // 这里从FlaggedGEProposerDetail 中拿到想要的数据sipcode和存在表里的数据
  // 根据sipCode来查找SIPChecklist，进而获得gelist，从而获得simt database table内的数据
  const FlaggedGEProposerList = FlaggedGEProposerDetail.FlaggedGEProposer;

  const geListArray = [];
  const sipTitleMap = {};
  const sipCodeMap = {};

  (FlaggedGEProposerList ?? []).map((geproposer, index) => {
    (geproposer['geList'] ?? []).map((geList, index) => {
      if (geList['geCode']=== null||geList['geCode']===''){
        sipTitleMap[geList.geId] = geproposer['title'];
        sipCodeMap[geList.geId] = geproposer['sipCode'];
        geListArray.push(geList);
      }
    });
  });
  React.useEffect(()=>{
    if(geListArray.length>0){
      setIsAllVerificationFinshed(false);
    }
  },[geListArray.length]);

  React.useEffect(() => {
    (geListArray ?? []).map((geList, index) => {
      dispatch(getGeByFullname(geList['fullName'], null, index));
    });
    if (geListArray.length===0){
      setIsAllVerificationFinshed(true);
    }else{
      // setIsAllVerificationFinshedArray(geListArray.map(()=>false));
    }
  }, [geListArray.length]);

  const handleChildData = useCallback(finishedIndex => {
    let tempArray = isAllVerificationFinshedArray;
    tempArray[finishedIndex] = true;
    setIsAllVerificationFinshedArray(tempArray);
  }, []);

  // const handleChildSnackBarShowData = useCallback((id, ifShow, gecode) =>{
  //   console.log('sanckbarshow');
  //   setSnackbarIdToShow(id);
  //   setSnackbarShow(ifShow);
  //   setGECode(gecode);
  //   console.log('snackbarisshow', snackBarShow);
  //   console.log('gecode', geCode);
  //   console.log('id', snackBarIdToShow);
  // },[]);

  React.useEffect(() => {
    const verificationFinishedCount = isAllVerificationFinshedArray.filter(
      value => value === true
    ).length;
    console.log('isAllVerificationFinshedArray_main', isAllVerificationFinshedArray);
    if (verificationFinishedCount === isAllVerificationFinshedArray.length) {
    // if (verificationFinishedCount === geListArray.length) {
      setIsAllVerificationFinshed(true);
    }
  }, [isAllVerificationFinshedArray]);

  // React.useEffect(() => {
  //   setButtonAlertButtons([
  //     {
  //       text: 'Go to GE detail page',
  //       onClick: ()=>{
  //         navigate(
  //           `/simt/auth/siRelated/ge/detail/${geCode}`
  //         );
  //       },
  //     },
  //     {
  //       text: 'Dismiss',
  //       onClick: () => {
  //         setSnackbarShow(false);
  //       },
  //     },
  //   ]);
  // }, [snackBarIdToShow]);



  const sideBarOpenWidth = 240;
return (
  <>
    {/* <WindowHeightContext.Provider value={visibleHeight + 19}>
      <StyledEngineProvider
        injectFirst
        data-selenium-id='GEVerificationPage_Overview'
      > */}
        <Grid container direction='column' justifyContent='space-between'>
          <Box
            data-selenium-id='GEVerificationPage_Overview-Index-Box'

            sx={{
              marginLeft: userManageSiderOpen
                ? `${sideBarOpenWidth}px`
                : '70px',
              flexGrow: 1,
              paddingLeft: '32px',
              paddingRight: '25px',
              paddingBottom: '16px',
              paddingTop: '24px',
              minHeight: 'calc(100vh - 56px - 68px)',
              height: '88px',
              width: 'full',
              overflowY: 'auto',
            }}
          >
            {/* 标题 和 指示栏 */}
            <Box data-selenium-id='SIPPage_Overview-Index-HeadBox'>
              {/* 标题 */}
              <Box
                sx={{
                  width: '340px',
                  height: '26px',
                  fontFamily: 'opensans, sans-serif',
                  fontWeight: 600,
                  fontSize: '22px',
                  lineHeight: '27.5px',
                  color: '#262E35',
                }}
              >
                <FormattedMessage
                  id='flaggedGEVerificationPage.pageTitle'
                  data-selenium-id='SIPPage_flaggedGEVerification-Box-Title'
                />
              </Box>
              <Box
                sx={{
                  marginTop: '20px',
                  color: '#596A7C',
                  width: '816px',
                  height: '42px',
                  fontFamily: 'opensans, sans-serif',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '21px',
                }}
              >
                <FormattedMessage
                  id='flaggedGEVerificationPage.instrucutionMessage'
                  data-selenium-id='SIPPage_flaggedGEVerification-Box-instructionMessage'
                />
              </Box>
            </Box>
            {(geListArray ?? []).map((geList, index) => {
              if (geList?.flags?.length > 0) {
                return (
                  <GEproposerAndVerificationTable
                    geList={geList}
                    geId={geList.geId}
                    index={index}
                    onData={handleChildData}
                    sipTitle={sipTitleMap[geList.geId]}
                    sipCode={sipCodeMap[geList.geId]}
                    onVerified={(siGeCode) => {
                      console.info('siGeCode', siGeCode);
                      setVerifiedAlertButtons([
                        {
                          text: 'Go to GE detail page',
                          onClick: ()=>{
                            navigate(
                              `/simt/auth/siRelated/ge/detail/${siGeCode}`
                            );
                          },
                        },
                        {
                          text: 'Dismiss',
                          onClick: () => {
                            setVerifiedSnackBarShow(false);
                          },
                        },
                      ]);
                      setVerifiedSnackBarShow(true);
                    }}
                  />
                );
              } else {
                return <></>;
              }
            })}
            <Box
              sx={{
                paddingTop: '20px',
                width: '463px',
                height: '18px',
                fontFamily: 'opensans, sans-serif',
                fontWeight: 600,
                fontSize: '24px',
                lineHeight: '18px',
                color: '#596A7C',
                display: !isAllVerificationFinshed ? 'none' : 'block',
              }}
            >
              No GE-proposer need to be verified now
            </Box>
          </Box>
          <ButtonAlertBox
            open={ verifiedSnackBarShow }
            severity='success'
            title='System has synced the proposer flag information to GE Database'
            message='You can check more details on GE detail page.'
            buttons={verifiedAlertButtons}
          />
        </Grid>
      {/* </StyledEngineProvider>
    </WindowHeightContext.Provider> */}
  </>
);
}

import {axios_instance} from "@/utils/axios_instance";
import {GET_COUNTRYLIST} from "@/actions/SIMT-SI/GuestEditor/GEAction";
const countryString='get-all-ge-country-list';
export function getCountryList(country) {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/guest-editors/filter-metadata/${'get-ge-country-list'}`;
            const res = await axios_instance.get(url, {
              params:{
                pageNum:1,
                pageSize:99999,
                searchKey:country??'',
              },
              headers: {
                  authorization: localStorage.getItem('authorization'),
              },
            });
            const{code,data} = res.data;
            let countryList=[];
            for(let i=0;i<data.length;i++) countryList.push({id:i,name:data[i]});
            if (code === 200) {
                dispatch({type: GET_COUNTRYLIST, data: countryList});
            } else {
                dispatch({type: GET_COUNTRYLIST, data: []});
            }
        } catch (e) {
            dispatch({type: GET_COUNTRYLIST, data: 'error'});
        }
    };
}

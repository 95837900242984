import { StyledSwitchTwoColor } from '../../ReportSpecificComponents/SpecialColorSwitch';
import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import SubjectGroupTable from './SubjectGroupTable';
import BusinessDivisionTable from './BusinessDivisionTable';
import { TableBox, TableGroupBox, TableLabel } from "../../ReportSpecificComponents/SpecialColorTableHeader";

function Mytable(props) {
  const { title } = props;
  const [checked, setChecked] = useState(true);
  const handleChangeSwitch = e => {
    setChecked(e.target.checked);
  };
  return (
    <TableGroupBox>
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        data-selenium-id='Report_Articles-AunnalTrends-MyTable-Grid'
      >
        <TableLabel>{title}</TableLabel>
        <Grid
          id={`reportArticlesSwitch${title?.replace(new RegExp(' ', 'g'), '')}`}
          sx={{
            p: '25px 0px 0px 6px',
            marginTop: '25px',
            marginRight: '30px',
          }}
        >
          <>
            <StyledSwitchTwoColor
              checked={checked}
              onChange={handleChangeSwitch}
              data-selenium-id='Report_Articles-AunnalTrends-MyTable-SwitchTwoColor'
            ></StyledSwitchTwoColor>
          </>
        </Grid>
      </Grid>
      <>
        {title === 'Subject Group' ? (
          <SubjectGroupTable isOA={checked}></SubjectGroupTable>
        ) : (
          <BusinessDivisionTable isOA={checked} />
        )}
      </>
    </TableGroupBox>
  );
}

export default Mytable;

import SimpleCheckBox from "@/componentsphase2/Input/SimpleCheckBox";
import SimpleLabel from "@/componentsphase2/Input/SimpleLabel";
import { Box } from "@mui/material";
import { useState } from "react";
import IconChevron from './assets/chevron.svg';

export default function JournalFolder(props) {
  const {
    title = '',
    number = '',
    issn = '',
    info = [],
    checked = false,
    handleChange = () => { },
    disabled = false,
  } = props;
  const [open, setOpen] = useState(false);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 2px 8px 0 #262E3510',
        width: '472px',
      }}>
      {/* 折叠时可见内容 */}
      <Box
        sx={{
          display: 'flex',
          padding: ' 16px 12px 8px 24px',
          height: '75px',
        }}>
        {/* 左侧内容 */}
        <Box
          sx={{
            display: 'flex',
            height: '43px',
            flexDirection: 'column',
            gap: '4px'
          }}>
          {/* 第一行 */}
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              height: '21px',
            }}>
            <SimpleCheckBox
              sx={{
                width: '18px',
                height: '18px',
                padding: '0px',
              }}
              checked={checked}
              onChange={handleChange}
              disabled={disabled}
            />

            <Box sx={{
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '21.79px',
              maxWidth: '340px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>{title}</Box>
            <Box
              sx={{
                width: '37px',
                height: '17px',
                // padding: '4px 6px 4px 6px',
                'border-radius': '2px',
                gap: '10px',
                backgroundColor: '#CEDDF9',
                color: '#154AB6',
                'font-size': '12px',
                'font-weight': '600',
                // 'line-height': '18px',
                'letter-spacing': '0em',
                'text-align': 'center',
              }}>{number}</Box>
          </Box>
          {/* 第二行 */}
          <Box
            sx={{
              height: '17px',
              padding: '0 26px',
              color: '#596A7C',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '16.8px',
              letterSpacing: '-1.1%',
            }}>
            {issn}
          </Box>
        </Box>
        {/* 右侧的箭头 */}
        <Box
          sx={{
            width: '28px',
            height: '28px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
          }}>
          <IconChevron
            style={{
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s',
            }}
            onClick={() => {
              setOpen(!open);
            }}
          />
        </Box>
      </Box>
      {/* 展开时的内容 */}
      {open && <Box
        sx={{
          backgroundColor: '#F1F3F5',
          margin: '0 12px 16px 50px',
          padding: '8px 12px 8px 12px',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <Box sx={{
          color: '#154AB6',
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '19.07px',
        }}>Relevant Publications</Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
        }}>
          {info.map((item, index) => {
            return (
              <Box
                sx={{
                  color: '#262E35',
                  fontSize: '12px',
                  lineHeight: '16.34px',
                  fontWeight: '600',
                }}>
                {item}
              </Box>
            );
          })}
        </Box>
      </Box>}
    </Box>
  );
}

import SIModalPro from '@/modules/Modal/SIModal';
import { Box, FormControl } from '@mui/material';
import { StateButton } from '@/componentsphase2/StateButton';
import SimpleLabel from '@/componentsphase2/Input/SimpleLabel';
import SimpleCheckBox from '@/componentsphase2/Input/SimpleCheckBox';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { set } from 'date-fns';
import {changeSIPOverviewColumn, loadSIPOverviewColumn} from "@/actions/SIMT-SIP/SIP/SipColumn";
function InfoItem(id, label) {
  this.id = id;
  this.label = label;
}
const sipStatusList = [
  new InfoItem('sipCode', 'SIP Code'),
  new InfoItem('sipStage', 'SIP Stage'),
  new InfoItem('handlingCe', 'Commissioning Editor'),
  
  new InfoItem('submissionDate', 'Submission Date'),
  new InfoItem('sipOrigin', 'SIP Origin'),
  new InfoItem('screener', 'Screener'),
  
  new InfoItem('latestDecisionDate', 'Latest Decision Date'),
  new InfoItem('sipChecklistStatus', 'SIP Checklist Status'),
  new InfoItem('operationsLead', 'Operations Lead'),
];

const objectList = [
  new InfoItem('firstChoiceJournal', '1st Choice Journal'),
  new InfoItem('secondChoiceJournal', '2nd Choice Journal'),
  new InfoItem('thirdChoiceJournal', '3rd Choice Journal'),
];
const sipInformationList = [
  new InfoItem('sipTitle', 'SIP Title'),
  new InfoItem('keywords', 'Keywords'),
  new InfoItem(
    'fullManuscriptSubmissionDeadline',
    'Full Manuscript Submission Deadline'
  ),
  new InfoItem('acceptanceDeadline', 'Acceptance Deadline'),
  new InfoItem('expectedPublicationDate', 'Expected Publication Date'),
];
const contentList = [
  new InfoItem('openCallForPapers', 'Open Call for Papers'),
  new InfoItem('authorList', 'Potential Authors'),
];
const geInformation = [
  new InfoItem('leadGeName', 'Lead GE'),
  new InfoItem('leadGeEmail', 'Lead GE Email'),
  new InfoItem('coGe', 'Co-GE'),
  new InfoItem('coGeEmail', 'Co-GE Email'),
];
const InfoList = new Object({
  'SIP Status': sipStatusList,
  Object: objectList,
  'SIP Information': sipInformationList,
  'Content Commissioning Approaches': contentList,
  'GE Information': geInformation,
});
const InfoListArr = [
  ...sipStatusList,
  ...objectList,
  ...sipInformationList,
  ...contentList,
  ...geInformation,
];
const keepSelectedID = ['sipCode', 'submissionDate'];
function isAllSeleted(selected) {
  let res = true;
  Object.keys(selected).forEach((item, index) => {
    if (selected[item] === false) {
      res = false;
    }
  });
  return res;
}
function isAllUnSeleted(selected) {
  let res = true;
  InfoListArr.forEach((item, index) => {
    if (keepSelectedID.indexOf(item.id) === -1 && selected[item.id] !== false) {
      res = false;
    }
  });
  return res;
}

function UnSeleteAll(setColumnsSelected) {
  const newSelectedObj = {};
  InfoListArr.forEach((item, index) => {
    if (keepSelectedID.indexOf(item.id) === -1) {
      newSelectedObj[item.id] = false;
    }
  });
  setColumnsSelected(newSelectedObj);
}
function selectAll(setColumnsSelected) {
  const newSelectedObj = {};
  setColumnsSelected(newSelectedObj);
}
function handleClickAll(setColumnsSelected, columnsSelected) {
  if (isAllSeleted(columnsSelected)) {
    UnSeleteAll(setColumnsSelected);
  } else {
    selectAll(setColumnsSelected);
  }
}
export default function ColumnsModel(props) {
  const {
    handleClose, //关闭column
    open,
    columnsSelectedStore, //后端存储的数据
    ...other
  } = props;
  const [columnsSelected, setColumnsSelected] =
    React.useState(columnsSelectedStore);
  const dispatch = useDispatch();

  useEffect(() => {
    setColumnsSelected(columnsSelectedStore);
  }, [columnsSelectedStore]);

  return (
    <SIModalPro
      id='SIPOverview-ColumnsModel'
      isRightModel={true}
      open={open}
      title='Columns'
      handleClose={(...other) => {
        handleClose(...other);
        setColumnsSelected(columnsSelectedStore);
      }}
      titleElseElement={
        <Box id='Button-Container'>
          <StateButton
            id='Reset-Button'
            titleName='Reset'
            isPrimary={false}
            onClick={() => {
              dispatch(changeSIPOverviewColumn({}, false));
            }}
          ></StateButton>
          <StateButton
            id='Save-Button'
            titleName='Save'
            isPrimary={true}
            sx={{ marginLeft: '10px' }}
            onClick={() => {
              dispatch(changeSIPOverviewColumn(columnsSelected, false));
              handleClose();
            }}
          ></StateButton>
        </Box>
      }
      {...other}
    >
      <Box id='CheckboxsList-Conatiner'>
        <SimpleLabel
          id='AllCheckBoxItem'
          label={'Displaying All Columns'}
          control={
            <SimpleCheckBox
              id='AllCheckBox'
              checked={isAllSeleted(columnsSelected)}
              indeterminate={
                !isAllSeleted(columnsSelected) &&
                !isAllUnSeleted(columnsSelected)
              }
              onClick={() => {
                handleClickAll(setColumnsSelected, columnsSelected);
              }}
            />
          }
          sx={{
            'font-family': 'Open Sans',
            'font-size': '14px',
            'font-weight': '400',
            'line-height': '18px',
            'letter-spacing': '0em',
            'text-align': 'left',
            color: '#596A7C',
            width: '270px',
            height: '18px',
            flex: '0 0 auto', //不可伸缩
            Overflow: 'visible',
            whiteSpace: 'nowrap',
            marginBottom: '12px',
          }}
        />
        {Object.keys(InfoList).map((item, index) => {
          return (
            <Box id='Checkboxs-List'>
              <Box
                id='Checkboxs-List-Title'
                sx={{
                  'font-family': 'Open Sans',
                  'font-size': '14px',
                  'font-weight': '600',
                  'line-height': '18px',
                  'letter-spacing': '0em',
                  'text-align': 'left',
                  color: '#596A7C',
                  marginBottom: '12px',
                }}
              >
                {item}
              </Box>
              <Box
                id='CheckBoxItem-Container'
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '12px 4px',
                  flexWrap: 'wrap',
                  width: '848px',
                  marginBottom: '20px',
                }}
              >
                {InfoList[item].map((o, i) => {
                  return (
                    <SimpleLabel
                      id='CheckBoxItem'
                      label={o.label}
                      control={
                        <SimpleCheckBox
                          id='CheckBox'
                          checked={columnsSelected[o.id] !== false}
                          onChange={(e, v) => {
                            setColumnsSelected({
                              ...columnsSelected,
                              [o.id]: v,
                            });
                          }}
                          disabled={keepSelectedID.indexOf(o.id) !== -1}
                        />
                      }
                      sx={{
                        'font-family': 'Open Sans',
                        'font-size': '14px',
                        'font-weight': '400',
                        'line-height': '18px',
                        'letter-spacing': '0em',
                        'text-align': 'left',
                        color: '#596A7C',
                        width: '270px',
                        height: '18px',
                        flex: '0 0 auto', //不可伸缩
                        Overflow: 'visible',
                        whiteSpace: 'nowrap',
                      }}
                    ></SimpleLabel>
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    </SIModalPro>
  );
}

import {
  Table,
  TableContainer,
  TableRow,
  TableBody,
  Box,
  tableCellClasses,
  Paper,
  TableCell,
  Divider,
  TableHead,
  IconButton,
  Stack,
  CircularProgress,
  Link,
} from '@mui/material';
import { TableFooter2 } from '../../../../components/DataTable';
import { styled } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTooltipWithoutArrow } from '../../../../components/CustomTooltip';
import OptInEditIcon from '@/assets/OptInEditIcon.svg';
import OptInWithdrawIcon from '@/assets/OptInWithdrawIcon.svg';
import OptInApproveIcon from '@/assets/OptInApproveIcon.svg';
import OptInRejectIcon from '@/assets/OptInRejectIcon.svg';
import ViewMoreText from './ViewMoreText';
import { handleLinkWithoutProtocol } from '@/utils/commonUtils';
import ApprovePopUp from './ApprovePopUp';
import RejectPopUp from './RejectPopUp';
import OptInWithdraw from '../OptInOperation/OptInWithdraw';
import OptInEdit from '../OptInOperation/OptInEdit';
import CATReasonDialog from '../CATTable/CATReasonDialog';
import { contextHeightChanged } from '../../../../actions/CommonAction';
import * as React from 'react';
import ScrollBarY from '../../../../componentsphase2/ScrollBar/ScrollBarY';
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import {getUserInfo} from "@/actions/SIMT-User/User";
import {editOptIn} from "@/actions/SIMT-SI/CatJournal";
import {approveOptIn, getOptInData} from "@/actions/SIMT-SI/CatJournal/OptIn";
import {PERMISSIONS} from "@/constant/permission";


const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E8F2',
    color: '#505050',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '0.01em',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '44px',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '24px',
  },
}));

const NewStyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F1F3F5',
    color: '#596A7C',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    letterSpacing: '0.08em',
    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '43px',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
}));

const NewStyledTableCellDown = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F1F3F5',
    color: '#596A7C',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    letterSpacing: '0.0756em', // it is 0.08em
    boxShadow: '0px -1px 0px 0px #BCC5CF inset',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '43px',
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
}));

const createRow = (
  id, //the object field id from back-end
  label,
  minWidth,
  maxWidth,
  isOrder = true,
  colSpan = 1,
  rowSpan = 2,
  align = 'left'
) => {
  return { id, label, minWidth, maxWidth, isOrder, colSpan, rowSpan, align };
};

const tableHeads = [ //to show the content
  createRow('actions', 'ACTIONS', '170px', '170px', false),
  createRow('requestDate', 'REQUEST DATE', '162.87px', '162.87px', false),
  createRow('journalCode', 'JOURNAL CODE', '147.79px', '147.79px', false),
  createRow('journalName', 'JOURNAL NAME', '270px', '270px', false),
  createRow('developmentModel', 'DEVELOPMENT MODEL', '197.05px', '197.05px', false),
  createRow('requestUser', 'REQUESTED BY', '146.78px', '146.78px', false),
  createRow('handlingCe', 'SCA LEAD', '229px', '229px', false),
  createRow('scam','SCA MANAGER', '229px','229px', false),
  createRow('activeJournal', 'ACTIVE JOURNAL', '165px', '165px', false),

  createRow('commission', 'COMMISSIONING MODEL', '185px', '185px', false, 1),
  createRow('basic', 'BASIC MANAGEMENT MODEL', '185px', '185px', false, 1),
  createRow('onHold', 'ON-HOLD', '185px', '185px', false, 1),
  createRow('total', 'TOTAL', '185px', '185px', false, 1),

  createRow('reason', 'REASON FOR OPT-IN', '528px', '528px', false),
  createRow(
    'currentSIPipelineAndFutureSIPlan',
    'CURRENT SI PIPELINE AND FUTURE SI PLAN',
    '528px',
    '528px',
    false
  ),
  createRow('pdpFullName','PD Publisher', '179px','179px', false),
  createRow('pdmFullName','PD Manager', '179px','179px', false),
  createRow('pddFullName','PD Director', '179px','179px', false),
  createRow('pppFullName','PP Publisher', '179px','179px', false),
  createRow('ppmFullName','PP Manager', '179px','179px', false),
  createRow('ppdFullName','PP Director', '179px','179px', false),
  createRow('subjectGroup', 'SUBJECT GROUP', '229px', '229px', false),
  createRow('businessDivision', 'BUSINESS DIVISION', '229px', '229px', false),
  createRow('revenueModel', 'REVENUE MODEL', '229px', '229px', false),
  createRow(
    'editorialOfficeSystem',
    'EDITORIAL OFFICE SYSTEM',
    '229px',
    '229px',
    false
  ),
  createRow('ownershipStatus', 'OWNERSHIP', '229px', '229px', false),
  createRow('society', 'SOCIETY NAME', '229px', '229px', false),
];

const tableHeadsUp = [ // to show the header
  createRow('actions', 'ACTIONS', '170px', '170px', false),
  createRow('requestDate', 'REQUEST DATE', '162.87px', '162.87px', false),
  createRow('journalCode', 'JOURNAL CODE', '147.79px', '147.79px', false),
  createRow('journalName', 'JOURNAL NAME', '270px', '270px', false),
  createRow('developmentModel', 'DEVELOPMENT MODEL', '197.05px', '197.05px', false),
  createRow('requestUser', 'REQUESTED BY', '146.78px', '146.78px', false),
  createRow('handlingCe', 'SCA LEAD', '229px', '229px', false),
  createRow('scam','SCA MANAGER', '229px','229px', false),
  createRow('activeJournal', 'ACTIVE JOURNAL', '165px', '165px', false),
  createRow(
    'journalIncluded',
    'JOURNAL INCLUDED',
    '740px',
    '740px',
    false,
    4,
    1
  ),

  createRow('reason', 'REASON FOR OPT-IN', '528px', '528px', false),
  createRow(
    'currentSIPipelineAndFutureSIPlan',
    'CURRENT SI PIPELINE AND FUTURE SI PLAN',
    '528px',
    '528px',
    false
  ),
  createRow('pdpFullName','PD Publisher', '179px','179px', false),
  createRow('pdmFullName','PD Manager', '179px','179px', false),
  createRow('pddFullName','PD Director', '179px','179px', false),
  createRow('pppFullName','PP Publisher', '179px','179px', false),
  createRow('ppmFullName','PP Manager', '179px','179px', false),
  createRow('ppdFullName','PP Director', '179px','179px', false),
  createRow('subjectGroup', 'SUBJECT GROUP', '229px', '229px', false),
  createRow('businessDivision', 'BUSINESS DIVISION', '229px', '229px', false),
  createRow('revenueModel', 'REVENUE MODEL', '229px', '229px', false),
  createRow(
    'editorialOfficeSystem',
    'EDITORIAL OFFICE SYSTEM',
    '229px',
    '229px',
    false
  ),
  createRow('ownershipStatus', 'OWNERSHIP', '229px', '229px', false),
  createRow('society', 'SOCIETY NAME', '229px', '229px', false),
];

const tableHeadsDown = [
  createRow('commission', 'COMMISSIONING MODEL', '185px', '185px', false, 1),
  createRow('basic', 'BASIC MANAGEMENT MODEL', '185px', '185px', false, 1),
  createRow('onHold', 'ON-HOLD', '185px', '185px', false, 1),
  createRow('total', 'TOTAL', '185px', '185px', false, 1),
];

function OptInTable(props) {
  //从父组件获取的属性
  const { id } = props;
  const userPermissions = localStorage.getItem('userPermissions');

  const [openApprovePopUp, setOpenApprovePopUp] = useState(false);
  const [openRejectPopUp, setOpenRejectPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [withdrawOpen, setWithdrawOpen] = useState(false);
  const [withdrawJournalCode, setWithdrawJournalCode] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [approveJournalCode, setApproveJournalCode] = useState('');
  const [approveHandlingCe, setApproveHandlingCe] = useState(null);
  const [rejectJournalCode, setRejectJournalCode] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  const [textValue1, setTextValue1] = useState('');
  const [textValue2, setTextValue2] = useState('');
  const [textValue3, setTextValue3] = useState('');
  const [currentTarget, setCurrentTarget] = useState(null);
  const [commentsOpen1, setCommentsOpen1] = useState(false);
  const [commentsOpen2, setCommentsOpen2] = useState(false);
  const [commentsOpen3, setCommentsOpen3] = useState(false);
  const dispatch = useDispatch();

  const [comments3Title, setComments3Title] = useState('');


  const selector = state => {
    return {
      page: state.CATJournal.optInPage,
      pageSize: state.CATJournal.optInPageSize,
      optInData: state.CATJournal.optInData,
      email: state.User.email,
      siderOpen: state.UserManage.siderOpen,
    };
  };

  const { page, pageSize, optInData, email, siderOpen } = useSelector(selector);

  const containerRef = useRef(null);
  const containerBoxRef = useRef(null);
  const scrollYRef = useRef(null);

  useEffect(() => {
    const containerElement = containerRef.current;
    const containerBoxElement = containerBoxRef.current;
    setTimeout(() => {
      dispatch(
        contextHeightChanged({
          contextWidth: containerElement.offsetWidth,
          contextHeight: containerElement.offsetHeight,
          boxWidth: containerBoxElement.offsetWidth,
          boxHeight: containerBoxElement.offsetHeight,
        })
      );
    }, 100);
  }, [optInData]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      await dispatch(getOptInData(page, pageSize));
      setIsLoading(false);
      await dispatch(getUserInfo());
    }
    fetchData();
  }, [page, pageSize]);

  const handlePerPageChange = e => {
    dispatch({ type: 'CHANGE_OPT_IN_PAGE_SIZE', data: e.target.value });
  };

  const handlePageChange = (e, newPage) => {
    dispatch({ type: 'CHANGE_OPT_IN_PAGE', data: newPage });
  };

  const handlePropUpdate = (prop, newValue) => {
    const {
      optInId,
      journalCode,
      developmentModel,
      currentSIPipelineAndFutureSIPlan,
      handlingCe,
      reason,
    } = currentTarget;
    const newData = {
      id: optInId,
      journalCode,
      developmentModel,
      currentSIPipelineAndFutureSIPlan,
      handlingCeEmail: handlingCe?.email,
      reason,
      [prop]: newValue,
    };
    dispatch(
      editOptIn(newData)
    );
  };

  const trimAndCheck = fullName => {
    if (fullName) {
      fullName = fullName.trim();
      if (fullName === ',') {
        return '';
      }
    }
    return fullName;
  };

  const getCorrespondingCell = (row, item, index) => {
    const fireCommentsOpen1 = (comments) => {
      setCurrentTarget(row);
      setCommentsOpen1(comments);
    };
    const fireCommentsOpen2 = (comments) => {
      setCurrentTarget(row);
      setCommentsOpen2(comments);
    };
    if (!row.requestUser) return (<></>);
    switch (item.id) {
      case 'actions':
        return (
          <StyledTableCell
            sx={{
              minWidth: item.minWidth,
              maxWidth: item.maxWidth,
              wordWrap: 'break-word',
              color: '#262E35',
              paddingX: '24px',
              paddingY: '9px',
            }}
            key={index}
            align={item.align}
            data-selenium-id={`CATJournal_Overview-OptInTable-ActionsStyledTableCell-${row.journalCode}-${index}`}
          >
            <Stack direction='row' spacing={0} sx={{ height: 26 }}>
              <ShowForPermission permission={`${PERMISSIONS.OPT_IN}`}>
                {email === row.requestUser.email ? (
                  <CustomTooltipWithoutArrow arrow placement='top' title='Withdraw'>
                    <IconButton
                      disableRipple
                      sx={{
                        p: '4px',
                        marginRight: '6px',
                        borderRadius: '2px',
                        background: '#F6F7F8',
                        '&:hover': {
                          background: '#DFE4E8',
                        },
                      }}
                    >
                      <OptInWithdrawIcon
                        onClick={() => {
                          setWithdrawOpen(true);
                          setWithdrawJournalCode(row.journalCode);
                        }}
                      />

                    </IconButton>
                  </CustomTooltipWithoutArrow>
                ) : (
                  null
                )}

              </ShowForPermission>
              <ShowForPermission permission={`${PERMISSIONS.OPT_IN}`}>
                {row.requestUser.email !== null && email === row.requestUser.email ? (
                  <CustomTooltipWithoutArrow arrow placement='top' title='Edit'>
                    <IconButton
                      disableRipple
                      disableRipple
                      sx={{
                        p: '4px',
                        marginRight: '6px',
                        borderRadius: '2px',
                        background: '#F6F7F8',
                        '&:hover': {
                          background: '#DFE4E8',
                        },
                      }}
                    >
                      <OptInEditIcon
                        onClick={() => {
                          setEditOpen(true);
                          setEditData(row);
                        }}
                      />
                    </IconButton>
                  </CustomTooltipWithoutArrow>
                ) : (
                  null
                )}
              </ShowForPermission>
              {userPermissions && userPermissions.includes(PERMISSIONS.OPT_APPROVAL) ? (
                <CustomTooltipWithoutArrow arrow placement='top' title='Approve'>
                  <IconButton
                    disableRipple
                    sx={{
                      p: '4px',
                      marginRight: '6px',
                      borderRadius: '2px',
                      background: '#F6F7F8',
                      '&:hover': {
                        background: '#DFE4E8',
                      },
                    }}>
                    <OptInApproveIcon
                      onClick={() => {
                        setApproveJournalCode(row.journalCode);
                        setApproveHandlingCe(row.handlingCe);
                        setOpenApprovePopUp(true);
                      }}
                    />
                  </IconButton>
                </CustomTooltipWithoutArrow>
              ) : (
                null
              )}

              {userPermissions && userPermissions.includes(PERMISSIONS.OPT_APPROVAL) ? (
                <CustomTooltipWithoutArrow arrow placement='top' title='Reject'>
                  <IconButton
                    disableRipple
                    sx={{
                      p: '4px',
                      borderRadius: '2px',
                      background: '#F6F7F8',
                      '&:hover': {
                        background: '#DFE4E8',
                      },
                    }}
                  >
                    <OptInRejectIcon
                      onClick={() => {
                        setRejectJournalCode(row.journalCode);
                        setOpenRejectPopUp(true);
                      }}
                    />
                  </IconButton>
                </CustomTooltipWithoutArrow>
              ) : (
                null
              )}
            </Stack>
          </StyledTableCell>
        );

      case 'requestUser':
        if (row.requestUser?.email) {
          return (
            <StyledTableCell
              sx={{
                minWidth: item.minWidth,
                maxWidth: item.maxWidth,
                wordWrap: 'break-word',
                color: '#262E35',
                paddingX: '24px',
              }}
              data-selenium-id={`CATJournal_Overview-OptInTable-RequestUserStyledTableCell1-${row.journalCode}-${index}`}
              key={index}
              align={item.align}
            >
              <CustomTooltipWithoutArrow
                // title={row.requestUser?.email}
                placement='top'
              >
                <Box>{row.requestUser?.fullName}</Box>
              </CustomTooltipWithoutArrow>
            </StyledTableCell>
          );
        } else {
          return (
            <StyledTableCell
              sx={{
                minWidth: item.minWidth,
                maxWidth: item.maxWidth,
                wordWrap: 'break-word',
                color: '#262E35',
                paddingX: '24px',
              }}
              data-selenium-id={`CATJournal_Overview-OptInTable-RequestUserStyledTableCell2-${row.journalCode}-${index}`}
              key={index}
              align={item.align}
            >
              {row.requestUser?.fullName}
            </StyledTableCell>
          );
        }

      case 'handlingCe':
        if (row.handlingCe?.email) {
          return (
            <StyledTableCell
              sx={{
                minWidth: item.minWidth,
                maxWidth: item.maxWidth,
                wordWrap: 'break-word',
                color: '#262E35',
                paddingX: '24px',
              }}
              key={index}
              align={item.align}
              data-selenium-id={`CATJournal_Overview-OptInTable-HandlingCeStyledTableCell1-${row.journalCode}-${index}`}
            >
              <CustomTooltipWithoutArrow
                // title={row.handlingCe?.email}
                placement='top'
              >
                <Box>{row.handlingCe?.fullName}</Box>
              </CustomTooltipWithoutArrow>
            </StyledTableCell>
          );
        } else {
          return (
            <StyledTableCell
              sx={{
                minWidth: item.minWidth,
                maxWidth: item.maxWidth,
                wordWrap: 'break-word',
                color: '#262E35',
                paddingX: '24px',
              }}
              data-selenium-id={`CATJournal_Overview-OptInTable-HandlingCeStyledTableCell2-${row.journalCode}-${index}`}
              key={index}
              align={item.align}
            >
              {row.handlingCe?.fullName}
            </StyledTableCell>
          );
        }

      case 'reason':
        return (
          <StyledTableCell
            sx={{
              minWidth: item.minWidth,
              maxWidth: item.maxWidth,
              wordWrap: 'break-word',
              color: '#262E35',
              paddingX: '24px',
            }}
            key={index}
            align={item.align}
            data-selenium-id={`CATJournal_Overview-OptInTable-ReasonStyledTableCell-${row.journalCode}-${index}`}
          >
            <ViewMoreText
              lineNum={1}
              id={`optIn-${item.id}-${index}`}
              monitor={optInData}
              setCommentsOpen={fireCommentsOpen1}
              setTextValue={setTextValue1}
              text={row[item.id]}
            >
              {row[item.id]}
            </ViewMoreText>
          </StyledTableCell>
        );
      case 'currentSIPipelineAndFutureSIPlan':
        return (
          <StyledTableCell
            sx={{
              minWidth: item.minWidth,
              maxWidth: item.maxWidth,
              wordWrap: 'break-word',
              color: '#262E35',
              paddingX: '24px',
            }}
            key={index}
            align={item.align}
            data-selenium-id={`CATJournal_Overview-OptInTable-ReasonStyledTableCell-${row.journalCode}-${index}`}
          >
            <ViewMoreText
              lineNum={1}
              id={`optIn-${item.id}-${index}`}
              monitor={optInData}
              setCommentsOpen={fireCommentsOpen2}
              setTextValue={setTextValue2}
              text={row[item.id]}
            >
              {row[item.id]}
            </ViewMoreText>
          </StyledTableCell>
        );

      case 'editorialOfficeSystem':
        return (
          <StyledTableCell
            sx={{
              minWidth: item.minWidth,
              maxWidth: item.maxWidth,
              wordWrap: 'break-word',
              color: '#262E35',
              paddingX: '24px',
            }}
            key={index}
            align={item.align}
            data-selenium-id={`CATJournal_Overview-OptInTable-EditorialOfficeSystemStyledTableCell-${row.journalCode}-${index}`}
          >
            {row[item.id]}
          </StyledTableCell>
        );
      case 'editorialDirectorFullName':
        return (
          <StyledTableCell
            sx={{
              minWidth: item.minWidth,
              maxWidth: item.maxWidth,
              wordWrap: 'break-word',
              color: '#262E35',
              paddingX: '24px',
            }}
            key={index}
            align={item.align}
            data-selenium-id={`CATJournal_Overview-OptInTable-EditorialDirectorFullNameStyledTableCell--${row.journalCode}-${index}`}
          >
            {trimAndCheck(row[item.id])}
          </StyledTableCell>
        );
      default:
        return (
          <StyledTableCell
            sx={{
              minWidth: item.minWidth,
              maxWidth: item.maxWidth,
              wordWrap: 'break-word',
              color: '#262E35',
              paddingX: '24px',
            }}
            key={index}
            align={item.align}
            data-selenium-id={`CATJournal_Overview-OptInTable-DefaultStyledTableCell-${row.journalCode}-${index}`}
          >
            <ViewMoreText
              lineNum={2}
              id={`optIn-${item.id}-${index}`}
              monitor={optInData}
              setCommentsOpen={
                (isOpen) => {
                  setCommentsOpen3(isOpen);
                  setComments3Title(item.label);
                }
              }
              setTextValue={setTextValue3}
              text={row[item.id]}
            >
              {row[item.id]}
            </ViewMoreText>
          </StyledTableCell>
        );
    }
  };

  return (
    <Box
      sx={{ width: '100%' }}
      data-selenium-id='CATJournal_Overview-OptInTable-Box'
    >
      <OptInWithdraw
        open={withdrawOpen}
        handleClose={() => {
          setWithdrawOpen(false);
        }}
        journalCode={withdrawJournalCode}
        data-selenium-id='CATJournal_Overview-OptInTable-OptInWithdraw'
      ></OptInWithdraw>
      <OptInEdit
        open={editOpen}
        handleClose={() => {
          setEditOpen(false);
          setEditData(null);
        }}
        defaultValue={editData}
        data-selenium-id='CATJournal_Overview-OptInTable-OptInEdit'
      ></OptInEdit>
      <CATReasonDialog
        value={textValue1}
        setValue={setTextValue1}
        commentsOpen={commentsOpen1}
        setCommentsOpen={setCommentsOpen1}
        title='Opt-In Reason'
        editable={true}
        onSubmit={(newReason) => handlePropUpdate('reason', newReason)}
        target={currentTarget}
      />
      <CATReasonDialog
        value={textValue2}
        setValue={setTextValue2}
        commentsOpen={commentsOpen2}
        setCommentsOpen={setCommentsOpen2}
        title='CURRENT SI PIPELINE AND FUTURE SI PLAN'
        editable={true}
        onSubmit={(plan) => handlePropUpdate('currentSIPipelineAndFutureSIPlan', plan)}
        target={currentTarget}
      />
      <CATReasonDialog
        value={textValue3}
        setValue={setTextValue3}
        commentsOpen={commentsOpen3}
        setCommentsOpen={
          setCommentsOpen3
        }
        title={comments3Title}
      />
      <div
        style={{
          overflowX: 'hidden',
          overflowY: 'hidden',
          position: 'relative',
          width: 'fit-content',
        }}
      >
        <TableContainer
          ref={containerBoxRef}
          id={id}
          data-selenium-id={id}
          sx={{
            borderRadius: '8px 8px 0px 0px',
            borderWidth: '1px 1px 0px 1px',
            borderStyle: 'solid',
            borderColor: '#DFE4E8',
            height: 'calc(100vh - 385px)',
            width: siderOpen ? 'calc(100vw - 129px - 158px)' : 'calc(100vw - 129px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar:vertical': {
              width: 0,
            },
            '&::-webkit-scrollbar-thumb:vertical': {
              backgroundColor: 'transparent',
            },
          }}
          onScroll={scrollYRef.current?.scrollAsContext}
        // component={Paper}
        >
          <Table
            ref={containerRef}
            stickyHeader
            aria-label='custom pagination table'
            sx={{
              minWidth: 500,
              letterSpacing: '0.01em',
              color: '#262E35',
            }}
          >
            <TableHead data-selenium-id='CATJournal_Overview-OptInTable-Head'>

              <TableRow data-selenium-id='CATJournal_Overview-OptInTable-TableRow1'>
                {tableHeadsUp.map((item, index) => (
                  <NewStyledTableCell
                    key={item.id}
                    align={item.align}
                    sx={{
                      maxWidth: item.maxWidth,
                      minWidth: item.minWidth,
                    }}
                    style={{
                      position: item.stick ? 'sticky' : '',
                      left: item.stick ? item.stickPX : '',
                      zIndex: item.stick ? 6 : '',
                    }}
                    rowSpan={item.rowSpan}
                    colSpan={item.colSpan}
                  >
                    <Box
                      id={`myTableHeaderItem${index}`}
                      data-selenium-id={`CATJournal_Overview-OptInTable-TableHeaderItem1-${index}`}
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      {item.label}
                    </Box>
                  </NewStyledTableCell>
                ))}
              </TableRow>
              <TableRow data-selenium-id='CATJournal_Overview-OptInTable-TableRow2'>
                {tableHeadsDown.map((item, index) => (
                  <NewStyledTableCellDown
                    key={item.id}
                    align={item.align}
                    sx={{
                      maxWidth: item.maxWidth,
                      minWidth: item.minWidth,
                      top: '43px',
                      position: item.stick ? 'sticky' : '',
                      left: item.stick ? item.stickPX : '',
                      zIndex: item.stick ? 6 : '',
                    }}
                    rowSpan={item.rowSpan}
                    colSpan={item.colSpan}
                  >
                    <Box
                      id={`myTableHeaderItem${index}`}
                      data-selenium-id={`CATJournal_Overview-OptInTable-TableHeaderItem2-${index}`}
                    // sx={{ whiteSpace: 'nowrap' }}
                    >
                      {item.label}
                    </Box>
                  </NewStyledTableCellDown>
                ))}
              </TableRow>

            </TableHead>

            <TableBody data-selenium-id='CATJournal_Overview-OptInTable-Body' >
              {isLoading && optInData !== null ? (
                <TableRow
                  id='catJournalOptInTableNoDataText'
                  data-selenium-id='CATJournal_Overview-OptInTable-IsLoadingNoDataText'
                  style={{ height: '300px' }}
                >
                  <TableCell
                    colSpan={6}
                    sx={{ textAlign: 'center', fontSize: '20px' }}
                  >
                    <CircularProgress size={80} />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {optInData.result.map((row, index) => (
                    <TableRow sx={{ '&:hover': { background: '#E8EDFB' } }} key={index}>
                      {/* /**hover above */}
                      {tableHeads.map((item, index) =>
                        getCorrespondingCell(row, item, index)
                      )}
                    </TableRow>
                  ))}

                  {optInData.result.length <= 0 ? (
                    <TableRow
                      id='catJournalOptInTableNoDataText'
                      data-selenium-id='CATJournal_Overview-OptInTable-NoDataText'
                      style={{ height: '300px' }}
                    >
                      <TableCell
                        colSpan={6}
                        sx={{ textAlign: 'center', fontSize: '20px' }}
                      >
                        No Data
                      </TableCell>
                    </TableRow>
                  ) : (
                    null
                  )}
                </>
              )}
            </TableBody>


          </Table>
        </TableContainer>
        <ScrollBarY ref={scrollYRef} contextHolderRef={containerBoxRef} />
      </div>

      <Box>
        {/* <Divider /> */}
        <TableFooter2
          Page={page}
          perPageNumber={pageSize}
          perPageList={[20, 50, 100]}
          count={optInData.count ?? 0}
          handlePerPageChange={handlePerPageChange}
          handlePageChange={handlePageChange}
          backgroundColor='#f1f3f5'
          width="calc(100% + 3px)"
        />
      </Box>
      <ApprovePopUp
        open={openApprovePopUp}
        setOpen={setOpenApprovePopUp}
        journalCode={approveJournalCode}
        handlingCe={approveHandlingCe}
        page={page}
        pageSize={pageSize}
        data-selenium-id='CATJournal_Overview-OptInTable-ApprovePopUp'
      />
      {openRejectPopUp ? (
        <>
          <RejectPopUp
            open={openRejectPopUp}
            setOpen={setOpenRejectPopUp}
            journalCode={rejectJournalCode}
            page={page}
            pageSize={pageSize}
            data-selenium-id='CATJournal_Overview-OptInTable-RejectPopUp'
          />
        </>
      ) : (
        <></>
      )}
    </Box>
  );
}
export default OptInTable;

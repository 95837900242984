import {
    ChecklistStatus,
    ComputedComponent,
    extractHtmlId,
    FormComponentProps,
    useForm,
    VALIDATE_KEY,
    ValidateState
} from "@/components/Form/form.d";
import React, {ReactElement, useEffect, useRef, useState} from "react";
import {
    alertGeFlagType,
    createRow,
    email,
    geDataName,
    type GeDataType,
    HoverTableRow,
    NEWStyledTableCell,
    radioGropList,
    verification, WrapWithFlag
} from "@/componentsphase2/FlaggedGESynchronizationAndVerificationTable";
import {CANCEL_EDIT} from "@/actions/SIMT-SIP/SIPChecklistSubmitted/SIPChecklistAction";
import ChecklistItemBlock from "../compontents/ChecklistItemBlock";
import {getGeByFullnameDirectly} from "@/actions/SIMT-SI/GuestEditor";
import {Box, TableCell} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import styleModule from "@/componentsphase2/FlaggedGESynchronizationAndVerificationTable/index.module.scss";
import Table from "@mui/material/Table";
import {MyTableHeader} from "@/components/DataTable";
import TableBody from "@mui/material/TableBody";
import RadioBlock from "@/pages/SIPPage/SIPCheckListPage/compontents/RadioBlock";
import NoDataMessage from "@/componentsphase2/NoDataMessage";
import {string} from "prop-types";
import {MulLineTooltipIfShow} from "@/componentsphase2/CustomTooltip";
import {CHECKLIST_PROCEEDING} from "./constants";

export interface CheckListGEFlagTableProps extends FormComponentProps {
    required?: boolean;
    label?: string;
    info?: string;
    validate_result: (string|number)[];
}

function CheckListGEFlagTableBase(props: CheckListGEFlagTableProps): ReactElement{
    const htmlId = extractHtmlId(props);
    const {
        value: geCode,
        setValue: setGeCode,
        form,
        setState,
        getState,
    } = useForm<string | null>({
        name: props.name,
        initValue: null,
        initStates: {
            [VALIDATE_KEY]: 'default',
            required: props.required,
            ['html-id']: htmlId,
        },
        statesPath: props.validate_result,
    });

    const geInSip = form.get('guestEditorInSip') ?? {};
    const fullname = form.get('fullName') ?? 'No Name';
    const email = geInSip?.email;

    // If from process_log: Array not null;  Otherwise, null
    const siGeInfoList = form.get('geInfoList') ?? null;

    useEffect(() => {
        if (siGeInfoList === null && fullname !== 'No Name'){
            getGeByFullnameDirectly(fullname, email, true).then((res) => {
                form.set('geInfoList', res);
                // email matched!!!
                if(res.length == 1 && (res[0]['primaryEmail'] === email || res[0]['secondaryEmail'] === email)){
                    setGeCode(res[0]['geCode']);
                }
            });
        }
    }, [siGeInfoList, fullname]);

    const label = props.label || "Flagged GE Synchronization and Verification";
    const info = props.info ?? '';

    const status = form?.root().status;
    const locked = form?.root().locked;
    const editable = status === ChecklistStatus.IN_PROGRESS && !locked;

    const immediateDecision = form?.root().immediateDecision;
    const proceeding = form?.root()[CHECKLIST_PROCEEDING] ?? false;

    useEffect(() => {
        const validate = (): ValidateState => {
            if((siGeInfoList?.length ?? 0) == 0){
                return 'checked';
            }
            return (geCode !== null && geCode !== undefined) ? 'checked' : !proceeding ? 'default' : 'error';
        };

        const validateResult = validate();
        setState(VALIDATE_KEY, validateResult);
    }, [geCode, proceeding, props.required, siGeInfoList]);

    return (<ChecklistItemBlock
        id={htmlId}
        title={label}
        info={info}
        necessary={props.required && !immediateDecision}
        status={getState(VALIDATE_KEY)}
        needClear={editable && immediateDecision}
        handleClear={() => {
            setGeCode(null);
            form.dispatch({ type: CANCEL_EDIT });
        }}
        alwaysShow={true}
    >
        <FlaggedGEMatchTable
            fullname={fullname}
            email={email}
            value={geCode}
            setGeCode={setGeCode}
            editing={editable}
            proceeding={proceeding}
            siGeList={siGeInfoList}
        />
    </ChecklistItemBlock>);
}

interface FlaggedGEMatchTableProps {
    fullname: string;
    email?: string;
    value: string | null;
    setGeCode: (geCode: string | null) => void;
    editing: boolean;
    proceeding: boolean;
    siGeList: GeDataType
}

const headerItems =  [
    createRow(geDataName.fullName, 'FULL NAME', false, '184px', '184px'),
    { ...createRow(geDataName.geCode, 'GE CODE', false, '160px', '160px'), hasFlag: true, },
    createRow(geDataName.institute, 'INSTITUTION'),
    createRow(geDataName.email, geDataName.email.toUpperCase(), false, '184px', '224px'),
];

function FlaggedGEMatchTable(props: FlaggedGEMatchTableProps) : ReactElement {

    const tableMaxWidth= 'calc(100vw - 242px)';
    const MismatchedHoverColor = '#E8EDFB';

    const containerBoxRef = useRef<HTMLDivElement>(null);

    const decideLines = (label: string | undefined, index: number, line: number) => {
        return (
            <MulLineTooltipIfShow
                outerRef={containerBoxRef}
                line={line}
                label={label}
                className={styleModule.MulLineTooltipIfShow}
                sx={{
                    '& .MuiTooltip-arrow': {
                        left: '0 !important',
                    },
                }}
            />
        );
    };

    const isVerificationNotNone = (props.siGeList?.length ?? 0) > 0;
    const isEmailMatched = props.siGeList?.length === 1 && 
        (props.siGeList[0]['primaryEmail'] === props.email || props.siGeList[0]['secondaryEmail'] === props.email);

    const widthStyles = (() => {
        const obj: {
            [key: string]: {
                readonly width?: string;
                readonly minWidth?: string;
                readonly maxWidth?: string;
            };
        } = {};
        headerItems.forEach((item, index) => {
            if (item.minWidth === item.maxWidth) {
                obj[`&:nth-of-type(${index + (isVerificationNotNone ? 2 : 1)})`] = {
                    width: item.minWidth.toString(),
                };
            } else {
                obj[`&:nth-of-type(${index + (isVerificationNotNone ? 2 : 1)})`] = {
                    minWidth: item.minWidth.toString(),
                    maxWidth: item.maxWidth.toString(),
                };
            }
        });
        return obj;
    })();

    return (<Box className={styleModule.TableContainerBox}>
        <TableContainer ref={containerBoxRef} component={Paper} sx={{...(tableMaxWidth && { maxWidth: tableMaxWidth }),}} className={styleModule.TableContainer}>
            <Table stickyHeader className={styleModule.Table} aria-label='customized table' size='small'
                   sx={{
                       ...(!isVerificationNotNone && {
                           '#NoDataMessageTypography': {
                                transform: 'translateY(10px)',
                           }
                        }),
                       '& > thead > tr > th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader.MuiTableCell-sizeSmall': widthStyles,
                   }}
            >
                <MyTableHeader
                       items={headerItems}
                       backgroundColor='#F1F3F5'
                       color='#596A7C'
                       letterSpacing='0.08em'
                       PaddingLeft='24px'
                       Header0PaddingLeft='0px'
                >
                   {isVerificationNotNone &&
                   <TableCell className={styleModule['MyTableHeader_Radio']}>
                        <NEWStyledTableCell align='left' className={styleModule.MyTableHeader_Verification}>
                             {verification?.toUpperCase()}
                        </NEWStyledTableCell>
                        {props.editing &&
                         <Box className={styleModule.MyTableHeader_MMatched}>
                             <Box>{radioGropList.Matched}</Box>
                             <Box>{radioGropList.Mismatched}</Box>
                         </Box>
                        }
                   </TableCell>
                    }
                </MyTableHeader>

                <TableBody>
                    {isVerificationNotNone ? (
                        props.siGeList.map((row, index) => {
                            const flags = row.alertGeFlagType;
                            const checkedState=
                                props.value === null ? null :
                                    props.value !== row[geDataName.geCode] ?
                                        radioGropList.Mismatched : radioGropList.Matched;
                            return (
                                <HoverTableRow
                                    key={row[geDataName.geCode]}
                                    sx={{
                                        transition: '.5s',
                                        '&:hover': {
                                            background:
                                                checkedState !== radioGropList.Matched ? MismatchedHoverColor : (
                                                    flags === alertGeFlagType['Flags-Severe Issue']
                                                    ? '#FFEFEF'
                                                    : flags === alertGeFlagType['Flags-Mild Issue']
                                                        ? '#FFF0DD'
                                                        : MismatchedHoverColor),
                                        },
                                    }}
                                >
                                {isVerificationNotNone &&
                                    <NEWStyledTableCell className={styleModule.RadioBlock}>
                                        {((props.editing && !isEmailMatched) ?
                                            <RadioBlock
                                                title=''
                                                value={checkedState}
                                                setValue={(newValue: radioGropList) => {
                                                    if(newValue === radioGropList.Matched){
                                                        props.setGeCode(row[geDataName.geCode] as string);
                                                    } else if (newValue === radioGropList.Mismatched){
                                                        if(row[geDataName.geCode] === props.value){
                                                            props.setGeCode('');
                                                        } else if(props.value === null){
                                                            props.setGeCode('');
                                                        }
                                                    } 
                                                }}
                                                error={props.proceeding && props.value === null}
                                                radioGropList={[radioGropList.Matched, radioGropList.Mismatched]}
                                            /> :
                                            <Box>{checkedState}</Box>
                                        )}
                                    </NEWStyledTableCell>
                                }

                                {headerItems.map((item, headerItemsIndex) => {
                                    const hasFlag: boolean = item.hasFlag ?? false;
                                    const itemId = item.id;
                                    return <NEWStyledTableCell
                                        align='left'
                                        sx={{ maxWidth: '160px' }}
                                        key={itemId}
                                        {...(item.minWidth === item.maxWidth && { width: item.minWidth })}
                                    >
                                        <WrapWithFlag
                                            hasFlag={hasFlag}
                                            geCode={row.geCode}
                                            flags={flags}
                                            isMismatched={checkedState !== null && checkedState !== radioGropList.Matched}
                                        >
                                            {(() => {
                                                return hasFlag ? row[itemId] : decideLines((() => {
                                                    switch (itemId) {
                                                        case geDataName.email:
                                                            return `${row[email]}${(() => { const secondaryEmail = row.secondaryEmail; return secondaryEmail ? `; ${secondaryEmail}` : ''; })()}`;
                                                        case geDataName.fullName:
                                                            const { firstName, lastName } = row;
                                                            return ((firstName && lastName) ? `${lastName ?? ''}, ${firstName ?? ''}` : (row?.fullName ?? ''));
                                                        default:
                                                            return row[itemId] ?? '';
                                                    }
                                                })() as string, headerItemsIndex, itemId===geDataName.email?1:2);
                                            })()}
                                        </WrapWithFlag>
                                    </NEWStyledTableCell>;
                                })}
                                </HoverTableRow>
                            );
                        })
                    ) : (
                        <NoDataMessage
                            MarginTop='10px'
                            message='No results were found.'
                        ></NoDataMessage>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    </Box>);
}

export const CheckListGEFlagTable = ComputedComponent<CheckListGEFlagTableProps>(CheckListGEFlagTableBase);
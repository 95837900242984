import moment from 'moment';
import { getFormattedMonthYear } from '@/utils/commonUtils';

export function getDownloadFileName(
  tab1Name, tab2Name, level, selectedTime  
) {
  // console.log('tab1Name: ',  tab1Name);
  // console.log('tab2Name: ',  tab2Name);
  // console.log('level: ',  level);
  // console.log('selectedTime: ',  selectedTime);

  const monthDate = selectedTime.includes('FY') ? selectedTime : getFormattedMonthYear(selectedTime);

  const currentTime = moment().utc().format('YYYYMMDD');

  return `${tab1Name ? tab1Name + '_' : ''}${tab2Name ? tab2Name + '_' : ''}${monthDate}_${level ? level + '_' : ''}${currentTime}`;
}


import {
  IDEA_OR_PROPOSAL,
  ACQUIRED,
  PAPER_COMMISSION,
  SUBMISSION_AND_REVIEW,
  PRODUCTION,
  PUBLISHED_IN_SELECTED_FY,
  getTopBar,
  siPipelinePieTooltipFormatter,
  SI_STAGES_COLOR,
} from '../../constants.js';
import {
  BasicPieSeries,
  BasicBarSeries,
} from '@/pages/Report/chartUtils/chartSeries';
import { BarAndPieChartOptions } from '@/pages/Report/chartUtils';
/**
 * overwrite the default configuration
 */
const LABLE_SIZE = [15, 10, 7];
const MAX_LABEL_SIZE = 18;
const LEGEND_POS = [200, 400, 520];


const COMMON_STYLE = {
  datasetIndex: 0,
  stackName: 'total',
  textColor: '#FFFFFF',
};
export class NormalChartOptions extends BarAndPieChartOptions {
  constructor(isDownload) {
    super();
    // overwrite the default properties
    this.pos = LEGEND_POS;
    this.pieColors = SI_STAGES_COLOR;
    this.isDownload = isDownload;
  }

  // overwrite the default methods
  getBarSeries() {
    const labelMinFontSize = this.getLabelMinFontSize();
    return [
      new BasicBarSeries({
        ...COMMON_STYLE,
        attributeName: IDEA_OR_PROPOSAL,
        
        labelFontSize: labelMinFontSize,
      }).getSeries(),
      new BasicBarSeries({
        ...COMMON_STYLE,
        attributeName: ACQUIRED,
        labelFontSize: labelMinFontSize,
      }).getSeries(),
      new BasicBarSeries({
        ...COMMON_STYLE,
        attributeName: PAPER_COMMISSION,

        labelFontSize: labelMinFontSize,
      }).getSeries(),
      new BasicBarSeries({
        ...COMMON_STYLE,
        attributeName: SUBMISSION_AND_REVIEW,

        labelFontSize: labelMinFontSize,
      }).getSeries(),
      new BasicBarSeries({
        ...COMMON_STYLE,
        attributeName: PRODUCTION,
        labelFontSize: labelMinFontSize,
      }).getSeries(),
      new BasicBarSeries({
        ...COMMON_STYLE,
        attributeName: PUBLISHED_IN_SELECTED_FY,
        labelFontSize: labelMinFontSize,
      }).getSeries(),
    ];
  }

  getPieSeries() {
    const pieCenter = this.getPieCenter();
    const pieSeries = new BasicPieSeries([64, 104], pieCenter, 1, false, true);
    pieSeries.addTooltipAttr({
      padding: [3, 10, 6, 10],
      formatter: siPipelinePieTooltipFormatter,
    });
    pieSeries.addLabelAttr({
      position: 'inner',
      color: '#fff',
    });
    return [pieSeries.getSeries()];
  }
  /**
   * overwrite the default getBarchartHeight method
   * @returns height
   */
  
  /**
   * overwrite the default getSelectedLegend method
   * to return a list of legend objects for vertical alignment
   */
  getSelectedLegend() {
    const baseLegend = super.getSelectedLegend();

    const legendCol1 = {
      ...baseLegend,
      orient: 'vertical',
      // left: 200,
      left: this.pos[0],
      data: [IDEA_OR_PROPOSAL, SUBMISSION_AND_REVIEW],
    };
    const legendCol2 = {
      ...baseLegend,
      orient: 'vertical',
      // left: 400,
      left: this.pos[1],
      data: [ACQUIRED, PRODUCTION],
    };
    const legendCol3 = {
      ...baseLegend,
      orient: 'vertical',
      // left: 530,
      left: this.pos[2],
      data: [PAPER_COMMISSION, PUBLISHED_IN_SELECTED_FY],
    };

    const legends = [legendCol1, legendCol2, legendCol3];

    return legends;
  }

  getLabelMinFontSize() {
    // console.log('=== this.dataForBar===', this.dataForBar);
    const numberOfBars = this.dataForBar && this.dataForBar.length;

    let fontSize;

    if (numberOfBars < 7) {
      fontSize = LABLE_SIZE[0];
    } else if (numberOfBars < 12) {
      fontSize = LABLE_SIZE[1];
    } else {
      fontSize = LABLE_SIZE[2];
    }

    return fontSize;
  }

  getLabelFontSize() {
    return [this.getLabelMinFontSize(), MAX_LABEL_SIZE];
  }

  /**
   * overwrite the default getSelectedLegend method
   * to return a list of legend objects for vertical alignment
   */
  setWidth(width) {
    // console.log('===this.setWidth (child)===');
    // console.log('===width===', width);
    // super.setWidth();
    this.width = width;

    // if pos not 0 or 100%
    if (width !== '100%') {
      const half_width = width / 2;
      this.pos = [half_width - 240, half_width - 40, half_width + 90];
    }

    // console.log('===this.pos===', this.pos);

    return this;
  }

  /**
   * if on webpage y-axis label should be truncate, and maximum width is 44
   * if for downloading, y-axis label should be shown in full length
   */
  getYAxis() {
    let baseYAxis = super.getYAxis();

    // console.log('===this.isDownload===', this.isDownload);

    if (this.isDownload) {
      baseYAxis = {
        ...baseYAxis,
        axisLabel: {
          ...baseYAxis.axisLabel,
          width: undefined,
          overflow: 'break',
        },
      };
    } else {
      baseYAxis = {
        ...baseYAxis,
        axisLabel: {
          ...baseYAxis.axisLabel,
          width: 48,
          overflow: 'truncate',
        },
      };
    }

    return baseYAxis;
  }
}

import {axios_instance_fileDownload,} from '../../../utils/axios_instance';
import {setSnackbarMessageAndOpen} from '../../SnackbarAction';
import moment from 'moment';
import {downloadFile} from '../../SIMT-SI/SpecialIssue/SIAction';
import {isTimeoutError} from '../../../utils/commonUtils';
import {SEVERITIES} from '../../../modules/ConfirmSnackBar/CommonSnackBar';
import getAuth from '@/utils/getAuth';

export const SELECT_DATE = 'SELECT_DATE';
export const GET_REPORT_BASIC = 'GET_REPORT_BASIC';
export const TABLE2_DATA = 'TABLE2_DATA';
export const TABLE3_DATA = 'TABLE3_DATA';
export const TABLE4_DATA = 'TABLE4_DATA';
export const EXPORT_REPORT = 'EXPORT_REPORT';
export const SELECT_ACQUIRED_DATE = 'SELECT_ACQUIRED_DATE';
export const SELECT_OAVSTOTAL_DATE = 'SELECT_OAVSTOTAL_DATE';
//targets页面的四个表的数据获取
export const GET_TARGETS_JOURNAL = 'GET_TARGETS_JOURNAL';
export const GET_TARGETS_SG = 'GET_TARGETS_SG';
export const GET_TARGETS_BD = 'GET_TARGETS_BD';
export const GET_TARGETS_CECAT = 'GET_TARGETS_CECAT';

//acquire overview 的四个表
export const GET_ACQUIRE_OVERVIEW_BASIC = 'GET_ACQUIRE_OVERVIEW_BASIC';
export const GET_ACQUIRE_OVERVIEW_SUBJECT = 'GET_ACQUIRE_OVERVIEW_SUBJECT';
export const GET_ACQUIRE_OVERVIEW_BUSINESS = 'GET_ACQUIRE_OVERVIEW_BUSINESS';
export const GET_ACQUIRE_OVERVIEW_CE = 'GET_ACQUIRE_OVERVIEW_CE';

// FYTrends 操作
export const FY_GET_BASIC_DATA = 'FY_GET_BASIC_DATA';
export const FY_GET_SUBJECT_GROUP_DATA = 'FY_GET_SUBJECT_GROUP_DATA';
export const FY_GET_BUSINESS_DATA = 'FY_GET_BUSINESS_DATA';
export const FY_ORDER_BY = 'FY_ORDER_BY';
export const FY_CHANGE_PAGE = 'FY_CHANGE_PAGE';
export const FY_CHANGE_PAGE_SIZE = 'FY_CHANGE_PAGE_SIZE';
export const FY_CHANGE_FISCAL_YEAR = 'FY_CHANGE_FISCAL_YEAR';
export const FY_CHANGE_SUBJECT_SHOW_ALL = 'FY_CHANGE_SUBJECT_SHOW_ALL';
export const FY_CHANGE_BUSINESS_SHOW_ALL = 'FY_CHANGE_BUSINESS_SHOW_ALL';
export const FY_FILTER_SELECT = 'FY_FILTER_SELECT';
// export const FY_FILTER_LOAD = "FY_FILTER_LOAD"     这个不再需要了，使用FilterAction的完成了
export const FY_FILTER_APPLY = 'FY_FILTER_APPLY';
export const FY_FILTER_CLEAR = 'FY_FILTER_CLEAR';

export const GET_OAVSTOTAL_ALL_SUBJECTGROUP = 'GET_OAVSTOTAL_ALL_SUBJECTGROUP';
export const GET_OAVSTOTAL_ALL_BUSINESSDIVISION =
  'GET_OAVSTOTAL_ALL_BUSINESSDIVISION';

//Acquired 中的Annual Trends的操作
export const SELECT_ANNUALTRENDS_DATE = 'SELECT_ANNUALTRENDS_DATE';
export const ANNUALTRENDS_SUBJECTGRUOP_DATA = 'ANNUALTRENDS_SUBJECTGRUOP_DATA';
export const ANNUALTRENDS_BUSINESS_DATA = 'ANNUALTRENDS_BUSINESS_DATA';

export const TAB_CHANGE = 'TAB_CHANGE';
export const TARGET_CHANGE = 'TARGET_CHANGE';

//ARTICLES的OA vs Total中的操作
export const ARTICLES_OA_VS_TOTAL_DATE = 'ARTICLES_OA_VS_TOTAL_DATE';
export const GET_ARTICLES_OA_VS_TOTAL_SG_DATA =
  'GET_ARTICLES_OA_VS_TOTAL_SG_DATA';
export const GET_ARTICLES_OA_VS_TOTAL_BD_DATA =
  'GET_ARTICLES_OA_VS_TOTAL_BD_DATA';

//article overview 的四个表
export const SELECT_ARTICLE_OVERVIEW_DATE = 'SELECT_ARTICLE_OVERVIEW_DATE';
export const GET_ARTICLE_OVERVIEW_BASIC = 'GET_ARTICLE_OVERVIEW_BASIC';
export const GET_ARTICLE_OVERVIEW_SUBJECT = 'GET_ARTICLE_OVERVIEW_SUBJECT';
export const GET_ARTICLE_OVERVIEW_BUSINESS = 'GET_ARTICLE_OVERVIEW_BUSINESS';
export const GET_ARTICLE_OVERVIEW_CE = 'GET_ARTICLE_OVERVIEW_CE';

//article OAWaiverUse
export const GET_ARTICLE_OAWAIVERUSE_BASIC = 'GET_ARTICLE_OAWAIVERUSE_BASIC';
export const GET_ARTICLE_OAWAIVERUSE_SUBJECT = 'GET_ARTICLE_OAWAIVERUSE_SUBJECT';
export const GET_ARTICLE_OAWAIVERUSE_BUSINESS = 'GET_ARTICLE_OAWAIVERUSE_BUSINESS';
export const GET_ARTICLE_OAWAIVERUSE_CE = 'GET_ARTICLE_OAWAIVERUSE_CE';

//article OAP
export const GET_ARTICLE_OAP_BASIC = 'GET_ARTICLE_OAP_BASIC';
export const GET_ARTICLE_OAP_SUBJECT = 'GET_ARTICLE_OAP_SUBJECT';
export const GET_ARTICLE_OAP_BUSINESS = 'GET_ARTICLE_OAP_BUSINESS';
export const GET_ARTICLE_OAP_CE = 'GET_ARTICLE_OAP_CE';

//ARTICLES的ANNUALTRENDS中的操作
export const ARTICLES_ANNUAL_TRENDS_DATE = 'ARTICLES_ANNUAL_TRENDS_DATE';
export const GET_ARTICLE_ANNUAL_TRENDS_SUBJECT_GROUP =
  'GET_ARTICLE_ANNUAL_TRENDS_SUBJECT_GROUP';
export const GET_ARTICLE_ANNUAL_TRENDS_BUSINESS_DIVISION =
  'GET_ARTICLE_ANNUAL_TRENDS_BUSINESS_DIVISION';

export const SELECT_JOURNAL_JPM_DATE = 'SELECT_JOURNAL_JPM_DATE';
export const SELECT_JOURNAL_BD_DATE = 'SELECT_JOURNAL_BD_DATE';

export const GET_JOURNAL_BUSINESS_DIVISION = 'GET_JOURNAL_BUSINESS_DIVISION';
export const GET_JOURNAL_JPM = 'GET_JOURNAL_JPM';

export const GET_ARTICLE_LAST_ACTION_DATE = 'GET_ARTICLE_LAST_ACTION_DATE';
export const GET_ACQUIRED_ACTIVE_LAST_ACTION_DATE =
  'GET_ACQUIRED_ACTIVE_LAST_ACTION_DATE';
export const GET_JOURNAL_LAST_ACTION_DATE = 'GET_JOURNAL_LAST_ACTION_DATE';
export const GET_ARTICLE_LAST_ACTION_DATE_FY =
  'GET_ARTICLE_LAST_ACTION_DATE_FY';
export const GET_ACQUIRED_ACTIVE_LAST_ACTION_DATE_FY =
  'GET_ACQUIRED_ACTIVE_LAST_ACTION_DATE_FY';

export const JOURNAL_OVERVIEW_GET_DATE = 'JOURNAL_OVERVIEW_GET_DATE';
export function selectJournalOverviewDate(selectedDate) {
  return async dispatch => {
    dispatch({ type: JOURNAL_OVERVIEW_GET_DATE, data: selectedDate });
  };
}

export function selectDate(newDate) {
  return async dispatch => {
    dispatch({ type: SELECT_DATE, data: newDate });
  };
}

export function selectYear(fyYear) {
  if (!fyYear) return  dispatch => {};
  const year = 2000 + parseInt(fyYear.substring(2));
  const currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  let currentMonth = currentDate.getMonth() + 1;
  const isCurrentYear = (currentYear === year && currentMonth < 5) || (currentYear === year-1 && currentMonth >= 5);
  return async dispatch => {
    dispatch({ type: SELECT_DATE, data: isCurrentYear ?
      `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}` :
      `${year}-4` });
  };
}

export function switchTab(data) {
  return async dispatch => {
    dispatch({ type: TAB_CHANGE, data: data });
  };
}

export function switchTarget(data) {
  return async dispatch => {
    dispatch({ type: TARGET_CHANGE, data: data });
  };
}

export function selectOAvsTotalDate(newDate) {
  return async dispatch => {
    dispatch({ type: SELECT_OAVSTOTAL_DATE, data: newDate });
  };
}

export function selectAcquireDate(newDate) {
  return async dispatch => {
    dispatch({ type: SELECT_ACQUIRED_DATE, data: newDate });
  };
}

export function selectAnnualTrendsDate(newDate) {
  return async dispatch => {
    dispatch({ type: SELECT_ANNUALTRENDS_DATE, data: newDate });
  };
}

export function exportPipelineExcel(
  selectDate,
  url,
  fileName,
  mode,
  type = ''
) {
  if (mode === 'excel') {
    exportReportExcel(selectDate, url, fileName);
  } else {
    exportReportCSV(selectDate, url, fileName, type);
  }
}

export function exportReportExcel(selectDate, url, filename) {
  return async dispatch => {
    try {
      let res = await axios_instance_fileDownload.get(url, {
        responseType: 'blob',
        params: {
          selectedDate: selectDate,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          authorization: getAuth(),
        },
      });
      downloadFile(res.data, filename);
      dispatch({ type: EXPORT_REPORT, data: true });
    } catch (err) {
      dispatch({ type: EXPORT_REPORT, data: false });
    }
  };
}

export function exportReportExcelFilter(selectDate, url,filterData){
  return async dispatch => {
    try {
      let res = await axios_instance_fileDownload.post(
        url,
        filterData,
        {
          responseType: 'blob',
          params: {
            selectedDate: selectDate,
          },
          headers: {
            authorization: getAuth(),
          },
        }
      );
      if(res.headers['content-disposition']&&res.headers['content-disposition'].includes('filename')){
        downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
      }else{
        downloadFile(res.data, filename);
      }
      dispatch({ type: EXPORT_REPORT, data: true });
    }catch (err) {
      dispatch({ type: EXPORT_REPORT, data: false });
    }
  };
};

export function exportReportExcelWithFilenameFromBackEnd(selectData, url, filename) {
  return async dispatch => {
    try {
      let res = await axios_instance_fileDownload.get(url, {
        responseType: 'blob',
        params: {
          selectedDate: selectData,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          authorization: getAuth(),
        },
      });

      if(res.headers['content-disposition']&&res.headers['content-disposition'].includes('filename')){
        downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
      }else{
        downloadFile(res.data, filename);
      }
      dispatch({ type: EXPORT_REPORT, data: true });
    } catch (err) {
      dispatch({ type: EXPORT_REPORT, data: false });
    }
  };
}

export function exportReportCSV(selectDate, url, filename, type) {
  return async dispatch => {
    try {
      let res = await axios_instance_fileDownload.get(url, {
        params: {
          selectedDate: selectDate,
          type: type,
        },
        headers: {
          authorization: getAuth(),
        },
      });
      downloadFile(res.data, filename);
      dispatch({ type: EXPORT_REPORT, data: true });
    } catch (err) {
      dispatch({ type: EXPORT_REPORT, data: false });
    }
  };
}

export function exportReportCSVFilter(selectDate, url, type,filterData){
  return async dispatch => {
    try {
      let res = await axios_instance_fileDownload.post(
        url,
        filterData,
        {
          params: {
            selectedDate: selectDate,
            type: type,
          },
          headers: {
            authorization: getAuth(),
          },
        }
      );
      if(res.headers['content-disposition']&&res.headers['content-disposition'].includes('filename')){
        downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
      }else{
        downloadFile(res.data, filename);
      }
      dispatch({ type: EXPORT_REPORT, data: true });
    }catch (err) {
      dispatch({ type: EXPORT_REPORT, data: false });
    }
  };
};

export function exportReportCSVWithFilenameFromBackEnd(selectData, url, filename='filename', type) {
  return async dispatch => {
    try {
      let res = await axios_instance_fileDownload.get(url, {
        params: {
          selectedDate: selectData,
          type: type,
        },
        headers: {
          authorization: getAuth(),
        },
      });
      // if headers contains content-disposition field, read it as filename, in othercase set filenam as default

      if (res.headers['content-disposition']&&res.headers['content-disposition'].includes('filename'))
      {

        downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, '').replace(/["']/g, ''));
      }else{
        downloadFile(res.data, filename);
      }
      dispatch({ type: EXPORT_REPORT, data: true });
    } catch (err) {
      dispatch({ type: EXPORT_REPORT, data: false });
    }
  };
}

/**
 * basic table排序方式变更
 * @param order asc or desc
 * @param orderBy 依据什么排序
 * @returns {(function(*): void)|*}
 */
export function fyOrderBy(order, orderBy) {
  return dispatch => {
    dispatch({ type: FY_ORDER_BY, data: { order: order, orderBy: orderBy } });
  };
}

/**
 * 更改basic table页码
 * @param page  新的页码
 * @returns {(function(*): void)|*}
 */
export function fyChangePage(page) {
  return dispatch => {
    dispatch({ type: FY_CHANGE_PAGE, data: page });
  };
}

/**
 * 更改basic table页面大小
 * @param pageSize  一页显示多少
 * @returns {(function(*): void)|*}
 */
export function fyChangePageSize(pageSize) {
  return dispatch => {
    dispatch({ type: FY_CHANGE_PAGE_SIZE, data: pageSize });
  };
}

/**
 * 修改财年
 * @param year
 * @returns {(function(*): void)|*}
 */
export function fyChangeFiscalYear(year) {
  return dispatch => {
    dispatch({ type: FY_CHANGE_FISCAL_YEAR, data: year });
  };
}

export function fyExportFilter(fiscalYear,url,filterData) {
  const filename = `Report_Acquire_Active_FYTrends_${moment()
    .utc()
    .format('MMDDYYYY_HHmmss')}.xls`;
  return async dispatch => {
    try {
      let res = await axios_instance_fileDownload.post(url,filterData, {
        responseType: 'blob',
        params: {
          fyYear: fiscalYear,
        },
        headers: {
          authorization: getAuth(),
        },
      });
      if(res.headers['content-disposition']&&res.headers['content-disposition'].includes('filename')){
        downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
      }else{
        downloadFile(res.data, filename);
      }
    } catch (err) {
      let reasonId;
      if (isTimeoutError(err)) {
        reasonId = 'common.errorTimeout';
      } else {
        reasonId = `common.error${err.status}`;
      }
      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToExport',
          {
            value: filename,
            reason: reasonId,
          },
          SEVERITIES.error
        )
      );
    }
  };
}

export function fyExportCSVFilter(fiscalYear, tableType,url,filterData) {
  return async dispatch => {
    const filename = `Report_Acquire_Active_FYTrends_${tableType}_${moment()
      .utc()
      .format('MMDDYYYY_HHmmss')}.csv`;
    try {
      let res = await axios_instance_fileDownload.post(url,filterData, {
        params: {
          fyYear: fiscalYear,
          type: tableType,
        },
        headers: {
          authorization: getAuth(),
        },
      });
      if(res.headers['content-disposition']&&res.headers['content-disposition'].includes('filename')){
        downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
      }else{
        downloadFile(res.data, filename);
      }
    } catch (err) {
      let reasonId;
      if (isTimeoutError(err)) {
        reasonId = 'common.errorTimeout';
      } else {
        reasonId = `common.error${err.status}`;
      }
      dispatch(
        setSnackbarMessageAndOpen(
          'common.failToExport',
          {
            value: filename,
            reason: reasonId,
          },
          SEVERITIES.error
        )
      );
    }
  };
}


/**
 * 控制subject group上面的All状态变化
 * @param newState  开启或关闭All
 * @returns {(function(*): void)|*}
 */
export function fySetSubjectShowAll(newState) {
  return dispatch => {
    dispatch({ type: FY_CHANGE_SUBJECT_SHOW_ALL, data: newState });
  };
}

/**
 * 控制business division上面的All状态变化
 * @param newState  开启或关闭All
 * @returns {(function(*): void)|*}
 */
export function fySetBusinessShowAll(newState) {
  return dispatch => {
    dispatch({ type: FY_CHANGE_BUSINESS_SHOW_ALL, data: newState });
  };
}

/**
 * 选择FY filter的一项
 * @param targetId
 * @param data
 * @returns {(function(*): void)|*}
 */
export function fyFilterSelect(targetId, data) {
  return dispatch => {
    dispatch({ type: FY_FILTER_SELECT, data: { targetId, data } });
  };
}

/**
 * filter应用
 * @param useFilters  需要被应用的filter，直接替换掉state中的`useFilters`
 * @returns {(function(*): void)|*}
 */
export function fyFilterApply(useFilters) {
  return dispatch => {
    dispatch({ type: FY_FILTER_APPLY, data: useFilters });
  };
}

/**
 * filter清空，会将`filterSelected`恢复为什么也没选择的状态
 * @returns {(function(*): void)|*}
 */
export function fyFilterClear() {
  return dispatch => {
    dispatch({ type: FY_FILTER_CLEAR });
  };
}

export function selectArticleOverviewDate(newDate) {
  return async dispatch => {
    dispatch({ type: SELECT_ARTICLE_OVERVIEW_DATE, data: newDate });
  };
}

//Articles中OA vs Total 操作
export function selectArticlesOAvsTotalDate(newDate) {
  return async dispatch => {
    dispatch({ type: ARTICLES_OA_VS_TOTAL_DATE, data: newDate });
  };
}

//Articles中的annual trends的操作
export function selectArticlesAnnualTrendsDate(newDate) {
  return async dispatch => {
    dispatch({ type: ARTICLES_ANNUAL_TRENDS_DATE, data: newDate });
  };
}

//Journal JPM
export function selectJournalJPMDate(newDate) {
  return async dispatch => {
    dispatch({ type: SELECT_JOURNAL_JPM_DATE, data: newDate });
  };
}

//Journal business division
export function selectJournalBDDate(newDate) {
  return async dispatch => {
    dispatch({ type: SELECT_JOURNAL_BD_DATE, data: newDate });
  };
}

export const GET_SI_PIPELINE_CAT_GROUP_CHARTDATA =
  'GET_SI_PIPELINE_CAT_GROUP_CHARTDATA';

const sampleData = {
  code: 200,
  message: 'OK',
  data: [
    {
      catGroup: 'CAT HS',
      ideaOrProposal: 52,
      acquired: 14,
      paperCommission: 33,
      submissionAndReview: 80,
      production: 26,
      published: 100,
    },
    {
      catGroup: 'CAT LS',
      ideaOrProposal: 106,
      acquired: 24,
      paperCommission: 42,
      submissionAndReview: 198,
      production: 22,
      published: 146,
    },
    {
      catGroup: 'CAT PS-SSH',
      ideaOrProposal: 73,
      acquired: 47,
      paperCommission: 30,
      submissionAndReview: 276,
      production: 58,
      published: 230,
    },
  ],
};

export function ReplaceGetSiPipelineCATGroupChartData(selectedDate) {
  return async dispatch => {
    dispatch({
      type: GET_SI_PIPELINE_CAT_GROUP_CHARTDATA,
      data: sampleData.data,
    });
  };
}
// declare const cut off date;
export const GET_CUT_OFF_DATE = 'GET_CUT_OFF_DATE';

import React, {forwardRef, useState} from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import WileyVisibilityIcon from '../../../../assets/LoginAsset/WileyVisibilityIcon.svg';
import WileyVisibilityOffIcon from '../../../../assets/LoginAsset/WileyVisibilityOffIcon.svg';
import WileyVisibilityIconHover from '../../../../assets/LoginAsset/WileyVisibilityIconHover.svg';
import WileyVisibilityOffIconHover from '../../../../assets/LoginAsset/WileyVisibilityOffIconHover.svg';

import styled from '@emotion/styled';

import MyOnlyText from '@/componentsphase2/MyOnlyText';


export const StyledTextField = styled(TextField)(({ error }) => ({
  '.MuiInputBase-root': {
    height: '100%',
    borderRadius: '4px',
    boxShadow: 'none',
    paddingRight: '12px',
    paddingLeft: '12px',
  },
  '.MuiInputBase-input': {
    height: '100%',
    padding: '0px',
    border: '0px',
    paddingRight: '0px',
    backgroundColor: 'transparent',
  },
  '.MuiOutlinedInput-root': {
    height: '100%',
    paddingRight: '12px',
    paddingLeft: '12px',
    left: '12',
    '.MuiOutlinedInput-notchedOutline': {
      border: ` ${
        error
          ? '2px solid var(--error-600, #EE5350)'
          : '1px solid var(--gray-200, #DFE4E8)'
      } `,
    },
    '&:hover': {
      '.MuiOutlinedInput-notchedOutline': {
        border: ` ${
          error
            ? '2px solid var(--error-600, #EE5350)'
            : '1px solid var(--gray-600, #262E35)'
        }`,
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${
        error ? 'var(--error-600, #EE5350)' : 'var(--primary-500, #154AB6)'
      } `,
    },
  },
}));


const EncryptedTextField = forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleIconHover = () => {
    setIsHovered(true);
  };

  const handleIconOut = () => {
    setIsHovered(false);
  };

  const {InputProps, ...rest} = props;

  return (
      <MyOnlyText
          ref={ref}
          type={showPassword ? 'text' : 'password'}
          multiline={false}
          TextFieldInputProps={{
            endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                      onClick={handleShowPassword}
                      onMouseEnter={handleIconHover}
                      onMouseLeave={handleIconOut}
                      style={{ paddingRight: '12px', paddingTop: '8px', paddingBottom: '8px' }}
                      disableRipple
                  >
                    {/* <p>{showPassword===true?'True':'False'}</p> */}
                    {!showPassword ? (
                        isHovered ? (
                            <WileyVisibilityOffIconHover />
                        ) : (
                            <WileyVisibilityOffIcon />
                        )
                    ) : isHovered ? (
                        <WileyVisibilityIconHover />
                    ) : (
                        <WileyVisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
            ),
            ...InputProps,
          }}
          {...rest}
      />
  );
}
);

export default EncryptedTextField;

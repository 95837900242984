import {
  Box,
  Fab,
  fabClasses,
  Stack,
  useMediaQuery,
  Link,
  Grid,
  Dialog,
  Typography,
  getListSubheaderUtilityClass,
} from '@mui/material';
import React from 'react';
import { useWhyDidYouUpdate } from 'ahooks';
import { FormattedMessage } from 'react-intl';
import { SIMTButton } from '@/components/StyledButton/SIMTButton';
import { StateButton } from '@/componentsphase2/StateButton';
import { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { WindowHeightContext } from '../../SIPage/Overview/Filter/Context';
import { StyledEngineProvider, useTheme } from '@mui/material/styles';
import { NewDesignedMain } from '../../../components/Main';
import { useDispatch, useSelector } from 'react-redux';
import GeProposerTable from './geProposer';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';
// import { GeproposerAndVerification } from './GEproposerAndVerification';
import GEproposerAndVerification from './GEproposerAndVerification';
import { setGeItemValue } from '@/actions/SIMT-SIP/SIPChecklistSubmitted/SIPChecklistAction';
import FlaggedGESynchronizationAndVerification from '../../SIPPage/SIPCheckListPage/compontents/FlaggedGESynchronizationAndVerification';
import {
  FlaggedGESynchronizationAndVerificationTable,
  calcFlaggedGESynchronizationAndVerificationStatus,
  queryFlaggedGESynchronizationAndVerificationErrorType,
} from '@/componentsphase2/FlaggedGESynchronizationAndVerificationTable';
import {
  radioGropListArray,
  radioGropList,
  FlaggedGESynchronizationAndVerificationTable as GETableString,
  email as emailString,
  geCode as geCodeString,
  verification,
} from '@/componentsphase2/FlaggedGESynchronizationAndVerificationTable';
import { useNavigate } from 'react-router';
import { AlertBox, UntitledAlertBox, ButtonAlertBox } from '@/components/Alert/AlertBox';
import FlaggedGEVerificationWarning from '@/assets/FlaggedGEVerificationWarning.svg';
import {getFlaglist} from "@/actions/SIMT-SI/GuestEditor/Config";
import {
    doFlaggedGEVerify,
    getFlaggedGEProposerDetail
} from "@/actions/SIMT-SIP/FlaggedGEProposer/SIPChecklistSubmitted";

export default function GEproposerAndVerificationTable(props) {
  // 记录完成验证的数组的数量
  const isAllVerificationFinished = [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    index,
    geList,
    geId,
    onData,
    sipTitle,
    sipCode,
    onVerified = (siGeCode) => {},
  } = props;

  const [MatchedLen, setMatchedLen] = useState(0);
  const [verifyButtonClickable, setVerifyButtonClickable] = useState(false);
  const [simtDatabaseTableIsNull, setSimtDatabaseTableIsNull] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [matchedIndices, setMatchedIndices] = useState(0);
  // const [geFlag, setGeFlag] = useState('');

  const [snackBarShow, setSnackbarShow] = useState(false);
  const [snackBarIdToShow, setSnackbarIdToShow] = useState(0);

  const [flagGEVerificationFinished, setFlagGEVerificationFinished] =
    useState(false);

  const [flagIssue, setFlagIssue] = useState('');

  // React.useEffect(() => {
  //   dispatch(getGeByFullname(geList['fullname']));
  // }, [dispatch, geList['fullname']]);

  const showIndex = index;

  const FlaggedGETable = useSelector(
    state => state?.GE?.[GETableString]
  );

  const flagIdList = geList?.['flags']?.map(item => item['flagId']);
  React.useEffect(() => {
    dispatch(getFlaglist());
  }, []);
  const flagsList = useSelector(state => state.GE.flagsList);

  React.useEffect(()=>{
    if (flagsList){
      const isSevere = flagsList.some(serverFlag => serverFlag.type==='Flags-Severe Issue' && flagIdList.includes(serverFlag.id));
      const isMild = flagsList.some(serverFlag => serverFlag.type==='Flags-Mild Issue' && flagIdList.includes(serverFlag.id));
      if (isSevere){
        setFlagIssue('Flags-Severe Issue');
      } else if (isMild){
        setFlagIssue('Flags-Mild Issue');
      }
    }else{

    }
  }, [flagsList]);

  useEffect(() => {
    if (geList['geCode'] === null || geList['geCode'] === '') {
      setFlagGEVerificationFinished(false);
    } else {
      setFlagGEVerificationFinished(true);
    }
  }, [geList['geCode']]);

  const [radioArray, setRadioArray] = React.useState([]);
  React.useEffect(() => {
    if (!FlaggedGETable) {
      setSimtDatabaseTableIsNull(true);
      isAllVerificationFinished.push(null);
      return;
    }
    setSimtDatabaseTableIsNull(false);
    // for (let i = 0; i < FlaggedGETable.length; i++) {
    //   let ge = FlaggedGETable[i];
    //   const FlaggedGESynchronizationAndVerificationArr =
    //     ge?.[FlaggedGESynchronizationAndVerificationTable];
    //   if (
    //     !calcFlaggedGESynchronizationAndVerificationStatus(
    //       FlaggedGESynchronizationAndVerificationArr
    //     )
    //   ) {
    //     setVerifyButtonClickable(false);
    //     return;
    //   } else {
    //     setVerifyButtonClickable(true);
    //   }
    // }
    const MatchedLen = radioArray?.filter(i => i === 'Matched')?.length ?? 0;
    const misMatchedLen = radioArray?.filter(i => i === 'Mismatched')?.length ?? 0;
    const totalLen = radioArray?.length ?? 0;
    setMatchedLen(MatchedLen);
    if (MatchedLen == 1) {
      const matchedIndices = radioArray
        .map((value, index) => (value === 'Matched' ? index : -1))
        .filter(index => index !== -1);
      setMatchedIndices(matchedIndices);
      if (MatchedLen + misMatchedLen === totalLen){
        setVerifyButtonClickable(true);
      } else {
        setSnackbarIdToShow(6);
      }
    }else if(radioArray.every(item => item === 'Mismatched')){
      setVerifyButtonClickable(true);
    }
    else if (MatchedLen > 1) {
      setSnackbarIdToShow(5);
      setVerifyButtonClickable(false);
    }else if (MatchedLen == 0 && (radioArray.every(item => item === undefined))){
      setSnackbarIdToShow(6);
      setVerifyButtonClickable(false);
    }
  }, [FlaggedGETable, radioArray]);

  const popWindowString =
    MatchedLen === 1
      ? ['This action is permanent and cannot be undone.', 'Are you sure you want to verify?']
      : MatchedLen === 0
      ? ['No matching SIMT GE here?', 'System will create a profile for proposer automatically.']
      : null;

  // const sendSnackBarShowInfoToParent = useCallback(() => {
  //   onSnackBarShowData(snackBarIdToShow, snackBarShow, geList['geCode']);
  //   console.log(snackBarIdToShow, snackBarShow, geList['geCode']);
  // },[]);

  function handleVerify() {
    if (verifyButtonClickable) {
      setIsDialogOpen(true);
    } else {
      // setSnackbarIdToShow(snackBarIdToShow);
      setSnackbarShow(true);
      setIsDialogOpen(false);
      // sendSnackBarShowInfoToParent();
    }
  }

  const PopWindow = ({ isDialogOpen, popWindowString = ['',''] }) => {
    return (
      <Dialog
        PaperProps={{
          style: {
            boxShadow: 'none',
          },
        }}
        slotProps={{
          backdrop: {
            invisible: true,
          },
        }}
        open={isDialogOpen}
        sx={{
          background: 'rgba(17, 61, 149, 0.5)',
          backdropFilter: 'blur(4px)',
          '& .MuiDialog-paper': {
            width: '600px',
            height: 'auto',
            minHeight: '152px',
            pt: '24px',
            px: '32px',
            m: 0,
            borderRadius: '5px',
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Open Sans',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '22.5px',
            textAlign: 'center',
          }}
        >
          {popWindowString?.[0] ?? ''}
        </Typography>

        <Typography
          sx={{
            fontFamily: 'Open Sans',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '22.5px',
            textAlign: 'center',
          }}
        >
          {popWindowString?.[1]?? ''}
        </Typography>

        <Box
          justifyContent={'center'}
          marginLeft={'42px'}
          width={'393px'}
          height={'20px'}
          alignItems={'center'}
          textAlign={'center'}
          display={(MatchedLen) === 1 ? 'none' : 'block'}
          marginTop={'16px'}
          marginBottom={'16px'}
          sx={{
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20.3px',
            textAlign: 'center',
            display: 'flex',
          }}
        >
          <FlaggedGEVerificationWarning/>
          {'This action is permanent and cannot be undone.'}
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '16px!important',
            paddingBottom: '20px',
            paddingTop: '12px',
            height: '66px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <StateButton
            titleName= {(MatchedLen) === 0 ?'Go Back': 'No'}
            isText={true}
            sx={{
              height: '34px',
              width: '70px',
              padding: '8px 0',
              border: '1px solid #0052CC!important',
              borderRadius: '5px!important',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '18px',
              fontStyle: 'normal',
              textAlign: 'center',
            }}
            onClick={() => {
              setFlagGEVerificationFinished(false);
              setSnackbarShow(false);
              setIsDialogOpen(false);
            }}
          />
          <StateButton
            titleName= {(MatchedLen) === 0 ?'Create': 'Yes'}
            sx={{
              height: '34px',
              width: '70px',
              borderRadius: '5px!important',
              fontWeight: 600,
              lineHeight: '18px',
            }}
            onClick={async () => {
              if (MatchedLen==0){
                setIsDialogOpen(false);
                setFlagGEVerificationFinished(true);
                isAllVerificationFinished.push(true);
                onData(index);
                const sipResult = await dispatch(
                  doFlaggedGEVerify(
                    '',
                    geId,
                    sipCode,
                  )
                );
                const newGeList = sipResult.geList;
                const ge = newGeList.find(g => g.geId === geId);
                onVerified(ge?.geCode);
              }
              else{
                setFlagGEVerificationFinished(true);
                setIsDialogOpen(false);
                isAllVerificationFinished.push(true);
                onData(index);
                // sendSnackBarShowInfoToParent();
                await dispatch(
                  doFlaggedGEVerify(
                    FlaggedGETable[index][matchedIndices[0]].geCode,
                    geId,
                    sipCode
                  )
                );
                onVerified(FlaggedGETable[index][matchedIndices[0]].geCode);
              }

              // setSnackbarShow(true);
              // setSnackbarIdToShow(1);

              setTimeout(() => {
                // onVerified(siGeCode);
                dispatch(getFlaggedGEProposerDetail());
              }, 100);
            }}
          />
        </Box>
      </Dialog>
    );
  };

  // const verticalBarStyle = {
  //   position: 'absolute',
  //   left: 0,
  //   top: 0,
  //   width: '2px', // 设置竖直横条的宽度
  //   height: '100%', // 设置竖直横条的高度与盒子高度一致
  //   backgroundColor: '#background: #FF8180',
  // };

  const isFlaggedGESynchronizationAndVerificationError =
    !calcFlaggedGESynchronizationAndVerificationStatus(
      FlaggedGETable[index]?.[FlaggedGESynchronizationAndVerificationTable]
    );

  // const handleGEProposerFlag = (flag) =>{
  //   setGeFlag(flag);
  // };

  return (
    <>
      {/* 表格*/}
      <Box
        data-selenium-id='SIPPage_flaggedGEVerification-Table-Box1'
        sx={{
          flexGrow: 1,
          paddingLeft: '20px',
          paddingRight: '12px',
          paddingTop: '12px',
          paddingBottom: '12px',
          borderRadius: '4px',
          marginTop: '24px',
          boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
          width: '100%',
          display:
            simtDatabaseTableIsNull || flagGEVerificationFinished
              ? 'none'
              : 'block',
          borderLeft: (flagIssue === 'Flags-Severe Issue')?'#FF8180 solid 8px':'#FFCA7B solid 8px',
        }}
      >
        {/* <div style={verticalBarStyle}></div> */}

        <Box
          sx={{
            paddingTop: '12px',
            paddingRight: '20px',
            paddingBottom: '20px',
            paddingLeft: '0px',
            width: '100%',
          }}
          data-selenium-id='SIPPage_flaggedGEVerification-Table-Box2'
        >
          <Box>
            <Box data-selenium-id='ge_proposer_Box-Box3'>
              {/* geProposerTableTitle */}
              <Box
                data-selenium-id='ge_proposer_Box_title'
                sx={{
                  width: '96px',
                  height: '24px',
                  fontFamily: 'opensans, sans-serif',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#262E35',
                }}
              >
                <FormattedMessage
                  id='flaggedGEVerificationPage.geProposerTableTitle'
                  data-selenium-id='SIPPage_flaggedGEVerification-Box-geProposerTableTitle'
                />
              </Box>
              {/* geProposerTable */}
              <Box data-selenium-id='geProposerTable-Table-Box'>
                {/* <GeProposerTable tableData={geList} callbackGeFlag={handleGEProposerFlag}></GeProposerTable> */}
                <GeProposerTable tableData={geList} title={sipTitle} flagIssue={flagIssue}></GeProposerTable>
              </Box>
            </Box>
            {/* simt ge database */}
            <Box data-selenium-id='simt_ge_databse_Box'>
              {/* simt ge database title */}
              <Box
                data-selenium-id='simt_ge_databse_Box_title'
                sx={{
                  width: '140px',
                  height: '24px',
                  fontFamily: 'opensans, sans-serif',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#262E35',
                }}
              >
                <FormattedMessage
                  id='flaggedGEVerificationPage.simtGEDatabaseTableTitle'
                  data-selenium-id='SIPPage_flaggedGEVerification-Box-simtGEDatabaseTableTitle'
                />
              </Box>
              {/* simt ge database Table */}

              <Box data-selenium-id='simt_ge_databse_Box_table'>
                {
                  <GEproposerAndVerification
                    setValue={newValue => {
                      setRadioArray(newValue);
                    }}
                    editing={true}
                    error={isFlaggedGESynchronizationAndVerificationError}
                    value={{
                      fullname: FlaggedGETable[index]?.fullname,
                      email: null,
                      geCode: FlaggedGETable[index]?.geCode,
                      index: index,
                    }}
                    FlaggedGETable={FlaggedGETable[index]}
                  />
                }
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          data-selenium-id='SIPPage_flaggedGEVerification-Table-Box2'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <StateButton
            id='verifyButton'
            data-selenium-id='flaggedGEVerification_verifyButton'
            titleName='Verify'
            sx={{
              padding: '12px, 18px, 12px, 18px',
              width: '400px',
              height: '41px',
              lineHeight: '17px',
            }}
            onClick={handleVerify}
            isPrimary={verifyButtonClickable}
          ></StateButton>
        </Box>
        {/* <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '3px',
          backgroundColor: (geFlag === 'Flags-Severe Issue') ? '#FF8180' : ((geFlag === 'Flags-Mild Issue')? '#FFCA7B' : '#ffffff'),
          content: "''",
        }}
      /> */}
      </Box>
      <PopWindow
        isDialogOpen={isDialogOpen}
        popWindowString={popWindowString}
      />
      <AlertBox
          open={(snackBarIdToShow==6)&& snackBarShow}
          title={'Please check SIMT GE Database section before verifying'}
          message={'You can choose one SIMT GE as matched or all SIMT GE as mismatched.'}
          severity='warning'
          autoHideDuration={3000}
          handleClose={()=>{setSnackbarShow(false);}}
        ></AlertBox>
        <UntitledAlertBox
          open={(snackBarIdToShow==5)&& snackBarShow}
          severity='warning'
          message='You cannot match more than one SIMT GE to a proposer'
          autoHideDuration={3000}
          handleClose={()=>{setSnackbarShow(false);}}
        ></UntitledAlertBox>
    </>
  );
}

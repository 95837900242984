import { RegularFont } from '@/components/FontStyle';
import { FormControlLabel } from '@mui/material';
import React from 'react';

export default function SimpleLabel(props) {
  const {sx,...other}=props;
  return (
    <FormControlLabel
      sx={{
        '& span': {
          ...RegularFont,
          color:'#262E35',   
        },
        ...sx
      }}
      {...other}
    />
  );
}
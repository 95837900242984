import { Box, Stack } from '@mui/material';
import RadioIcon from '../SVGComponents/RadioIcon.svg';
import RadioCheckedIcon from '../SVGComponents/RadioCheckedIcon.svg';
import RadioDisabledIcon from '../SVGComponents/RadioDisabledIcon.svg';
import { SmallFont } from '@/components/FontStyle';
export default function RadioBox(props) {
  const { checked, disabled, label, setChecked, mode } = props;

  return (
    <Stack spacing={1} direction='row'>
      <Box
        sx={{ display: 'flex', cursor: !checked && !disabled ? 'pointer' : ' default' }}
        onClick={() => {
          if (disabled) return;
          mode === 0 ? setChecked(false) : setChecked(true);
        }}
      >
        {disabled ? (
          <RadioDisabledIcon />
        ) : checked ? (
          <RadioCheckedIcon />
        ) : (
          <RadioIcon />
        )}
      </Box>
      <Box
        sx={{
          ...SmallFont,
          lineHeight: '17.5px',
          color: !disabled ? '#596A7C' : '#BCC5CF',
          padding: '3px 0px',
        }}
      >
        {label}
      </Box>
    </Stack>
  );
}

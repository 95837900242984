/**
 * @author:Liu Xin
 * @date:2022.1.25
 * @description:生成系统设置页面，可以设置邮件过期时间以及登录后token有效时间
 *
 * 2022.6.7修改：Yeqi Zhu
 *
 */
import {
  Box,
  Button,
  buttonClasses,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  typographyClasses,
  useMediaQuery,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { openConfirm, setEditType } from '@/actions/SIMT-User/Alert/AlertAction';
import React, { useEffect, useState } from 'react';
import {
  clear,

} from '@/actions/SIMT-User/Config/SystemConfigAction';
import { CommonDialog } from '@/modules/AlertDialog';
import { useTheme } from '@mui/material/styles';
import { SIMTButton } from '../../../components/StyledButton/SIMTButton';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material/styles';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import styleModule from '@/componentsphase2/SIDetail/SaveAndCancelButtonForGeneralInfoSection.module.scss';
import {getSystemConfig, updateSystemConfig} from "@/actions/SIMT-User/Config";

// 加起来是12格就行
const LEFT_GRID = 7;
const RIGHT_GRID = 5;

// 后端响应数据的常量名与显示名称ID对应
const settingsNamesID = {
  RESET_URL_LIFETIME: 'sysConfig.pwdTimeout',
  ACTIVATE_URL_LIFETIME: 'sysConfig.actTimeout',
  TOKEN_EXPIRATION_LIFETIME: 'sysConfig.tokenTimeout',
  GUEST_EDITOR_MAX_NUMBER: 'sysConfig.maxGE',
  ARTICLE_MAX_NUMBER: 'sysConfig.maxArticle',
};

const settingsErrorTypeID = {
  LESS_THAN_BEFORE: 'sysConfig.numberLessErr',
  OUT_OF_RANGE: 'sysConfig.numberOutOfRangeErr',
};

export const GeneralSettingsTypography = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.root}`]: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '17px',
    color: '#596A7C',
    fontFamily: 'Open Sans',
    letterSpacing: '0.01em',
  },
}));

function ItemBlock(props) {
  return (
    <Stack spacing={1.5} direction='column'>
      {props.children}
    </Stack>
  );
}

function GeneralSettings() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const underMD = useMediaQuery(theme.breakpoints.down('md'));

  const [dialogOpen, setDialogOpen] = useState(false);
  const [resetTimeout, setResetTimeout] = useState(null);
  const [oldResetTimeout, setResetTokenTimeout] = useState();
  const [activeTimeout, setActiveTimeout] = useState(null);
  const [maxJournals, setMaxJournals] = useState(null);
  const [oldMaxJournals, setOldMaxJournals] = useState(null);
  const [oldActiveTimeout, setOldActiveTimeout] = useState();
  const [tokenTimeout, setTokenTimeout] = useState(null);
  const [oldTokenTimeout, setOldTokenTimeout] = useState();
  const [maxGuestEditors, setMaxGuestEditors] = useState(null);
  const [oldMaxGuestEditors, setOldMaxGuestEditors] = useState();
  const [maxArticles, setMaxArticles] = useState(null);
  const [oldMaxArticles, setOldMaxArticles] = useState();
  const [cannotCancel, setCannotCancel] = useState(true);
  const [cannotUpdate, setCannotUpdate] = useState(true);
  const [alertResetTime, setAlertResetTime] = useState(false);
  const [alertActiveTime, setAlertActiveTime] = useState(false);
  const [alertTokeTime, setAlertTokenTime] = useState(false);
  const [alertMaxJournals, setAlertMaxJournals] = useState(false);
  const [alertMaxGuestEditors, setAlertMaxGuestEditors] = useState(false);
  const [alertMaxArticles, setAlertMaxArticles] = useState(false);
  const selector = state => {
    return {
      systemConfig: state.SystemConfig.systemConfig,
      updateConfig: state.SystemConfig.updateConfig,
    };
  };
  const { systemConfig, updateConfig } = useSelector(selector);

  useEffect(() => {
    if (updateConfig !== null) {
      // console.log("updateConfig", updateConfig);
      if (updateConfig.code === 200)
        dispatch(
          setSnackbarMessageAndOpen(
            'sysConfig.updateConfiguration',
            {},
            SEVERITIES.success
          )
        );
      else if (updateConfig.code === 400) {
        /**
         * 前端发送非法数据时，后端响应的数据 updateConfig 形如：
         * {
         *   code: 400,
         *   data: [
         *   "RESET_URL_LIFETIME UPDATE_SUCCESS",
         *   "ARTICLE_MAX_NUMBER UPDATE_FAILED OUT_OF_RANGE",
         *   "GUEST_EDITOR_MAX_NUMBER UPDATE_FAILED LESS_THAN_BEFORE",
         *   "ACTIVATE_URL_LIFETIME UPDATE_FAILED LESS_THAN_BEFORE",
         *   ],
         * }
         */
        const firstUpdateFailedItem = updateConfig.data
          .map(item => item.split(' '))
          .find(item => item[1] == 'UPDATE_FAILED');
        if (firstUpdateFailedItem) {
          dispatch(
            setSnackbarMessageAndOpen(
              'sysConfig.updateConfigurationError',
              {
                name: settingsNamesID[firstUpdateFailedItem[0]],
                type: settingsErrorTypeID[firstUpdateFailedItem[2]],
              },
              SEVERITIES.error
            )
          );
        }
      }
    }
    setDialogOpen(false);
    setCannotCancel(true);
    handleReset();
    setCannotUpdate(true);
    return () => {
      dispatch(clear());
    };
  }, [updateConfig]);

  useEffect(() => {
    if (systemConfig !== null) {
      const {
        jwtTimeout,
        passwordResetUrlTimeout,
        userActivateUrlTimeout,
        eachSiGuestEditorMaxNumber,
        eachSiArticlesMaxNumber,
        eachJointSIMaxAddedJournalsNumber
      } = systemConfig;
      setResetTimeout(passwordResetUrlTimeout);
      setResetTokenTimeout(passwordResetUrlTimeout);
      setActiveTimeout(userActivateUrlTimeout);
      setOldActiveTimeout(userActivateUrlTimeout);
      setTokenTimeout(jwtTimeout);
      setOldTokenTimeout(jwtTimeout);
      setMaxGuestEditors(eachSiGuestEditorMaxNumber);
      setOldMaxGuestEditors(eachSiGuestEditorMaxNumber);
      setMaxArticles(eachSiArticlesMaxNumber);
      setOldMaxArticles(eachSiArticlesMaxNumber);
      setCannotCancel(true);
      setCannotUpdate(true);
      setAlertActiveTime(false);
      setAlertResetTime(false);
      setAlertTokenTime(false);
      setMaxJournals(eachJointSIMaxAddedJournalsNumber);
      setOldMaxJournals(eachJointSIMaxAddedJournalsNumber);
    }
  }, [systemConfig]);

  const handleReset = async () => {
    await dispatch(getSystemConfig());
  };

  useEffect(() => {
    if (
      alertTokeTime === false &&
      alertActiveTime === false &&
      alertResetTime === false &&
      alertMaxGuestEditors === false &&
      alertMaxArticles === false &&
      alertMaxJournals === false
    ) {
      if (
        tokenTimeout !== oldTokenTimeout ||
        resetTimeout !== oldResetTimeout ||
        activeTimeout !== oldActiveTimeout ||
        maxGuestEditors !== oldMaxGuestEditors ||
        maxArticles !== oldMaxArticles ||
        maxJournals !== oldMaxJournals
      ) {
        setCannotUpdate(false);
      } else {
        setCannotUpdate(true);
      }
    } else {
      setCannotUpdate(true);
    }
  });

  const handleUpdate = () => {
    setDialogOpen(true);
  };

  const handleAlertCancel = () => {
    setDialogOpen(false);
    handleReset();
  };

  const handleAlertOK = async () => {
    await dispatch(
      updateSystemConfig(
        Number(resetTimeout),
        Number(activeTimeout),
        Number(tokenTimeout),
        Number(maxGuestEditors),
        Number(maxArticles),
        Number(maxJournals)
      )
    );
  };

  const handleResetTimeBlur = () => {
    if (
      !(resetTimeout >= 10 && resetTimeout <= 2000 && resetTimeout % 1 === 0)
    ) {
      setAlertResetTime(true);
      setCannotUpdate(true);
    } else {
      setAlertResetTime(false);
    }
  };

  const handleActiveTimeBlur = () => {
    if (
      !(activeTimeout >= 10 && activeTimeout <= 2000 && activeTimeout % 1 === 0)
    ) {
      setAlertActiveTime(true);
      setCannotUpdate(true);
    } else {
      setAlertActiveTime(false);
    }
  };

  const handleTokenTimeBlur = () => {
    if (
      !(tokenTimeout >= 15 && tokenTimeout <= 120 && tokenTimeout % 1 === 0)
    ) {
      setAlertTokenTime(true);
      setCannotUpdate(true);
    } else {
      setAlertTokenTime(false);
    }
  };

  const handleMaxGuestEditorsBlur = () => {
    if (
      !(
        maxGuestEditors >= 20 &&
        maxGuestEditors <= 2000 &&
        maxGuestEditors % 1 === 0 &&
        maxGuestEditors >= oldMaxGuestEditors
      )
    ) {
      setAlertMaxGuestEditors(true);
      setCannotUpdate(true);
    } else {
      setAlertMaxGuestEditors(false);
    }
  };

  const handleSetMaxArticlesBlur = () => {
    if (
      !(
        maxArticles >= 1000 &&
        maxArticles <= 100000 &&
        maxArticles % 1 === 0 &&
        maxArticles >= oldMaxArticles
      )
    ) {
      setAlertMaxArticles(true);
      setCannotUpdate(true);
    } else {
      setAlertMaxArticles(false);
    }
  };

  const handleMaxJournalsBlur = () => {
    if (!(maxJournals >= 20 && maxJournals <= 2000 && maxJournals % 1 === 0)) {
      setAlertMaxJournals(true);
      setCannotUpdate(true);
    } else {
      setAlertMaxJournals(false);
    }
  };

  useEffect(handleResetTimeBlur, [resetTimeout]);
  useEffect(handleActiveTimeBlur, [activeTimeout]);
  useEffect(handleTokenTimeBlur, [tokenTimeout]);
  useEffect(handleMaxGuestEditorsBlur, [maxGuestEditors]);
  useEffect(handleSetMaxArticlesBlur, [maxArticles]);
  useEffect(handleMaxJournalsBlur, [maxJournals]);

  // 抽取成单个常量以减少复杂度
  const commonJustifyContent = underMD ? 'flex-start' : 'flex-end';
  const commonTextAlign = 'start';

  return (
    <Box data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box'>
      <Stack
        data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Stack'
        spacing={1.125}
        direction='column'
      >
        <ItemBlock>
          <GeneralSettingsTypography
            id='systemAdminSystemConfigurationPasswordTimeoutLabel'
            data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid-Grid1-GeneralSettingsTypography-PasswordTimeoutLabel'
            textAlign={commonTextAlign}
          >
            <FormattedMessage
              id='sysConfig.pwdTimeout'
              data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid-Grid1-GeneralSettingsTypography-PasswordTimeoutLabel-FormattedMessage-PwdTimeout'
            />
          </GeneralSettingsTypography>
          <MyOnlyText
            id='systemAdminSystemConfigurationPasswordTimeoutInput'
            data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid-Grid2-nPasswordTimeoutInput'
            value={resetTimeout}
            setValue={newValue => {
              setResetTimeout(newValue);
              setCannotCancel(false);
            }}
            onBlur={handleResetTimeBlur}
            height='40px'
            isError={!(!alertResetTime || resetTimeout === null)}
            errorMessage={
              <FormattedMessage
                id='sysConfig.pwdTimeoutErr'
                data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid-Grid4-Typography-PasswordTimeoutHintText-FormattedMessage-PwdTimeoutErr'
              />
            }
          />
        </ItemBlock>
        <ItemBlock>
          <GeneralSettingsTypography
            id='systemAdminSystemConfigurationActivationTimeoutLabel'
            data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid2-Grid1-GeneralSettingsTypography-ActivationTimeoutLabel'
            textAlign={commonTextAlign}
          >
            <FormattedMessage
              id='sysConfig.actTimeout'
              data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid2-Grid1-GeneralSettingsTypography-ActivationTimeoutLabel-FormattedMessage-actTimeout'
            />
          </GeneralSettingsTypography>
          <MyOnlyText
            id='systemAdminSystemConfigurationActivationTimeoutInput'
            data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid2-Grid2-TextField-ActivationTimeoutInput'
            value={activeTimeout}
            setValue={newValue => {
              setActiveTimeout(newValue);
              setCannotCancel(false);
            }}
            onBlur={handleActiveTimeBlur}
            height='40px'
            isError={!(!alertActiveTime || activeTimeout === null)}
            errorMessage={
              <FormattedMessage
                id={'sysConfig.actTimeoutErr'}
                data-selenium-id={'sysConfig.actTimeoutErr'}
              />
            }
          />
        </ItemBlock>
        <ItemBlock>
          <GeneralSettingsTypography
            id='systemAdminSystemConfigurationTokenExpirationLabel'
            data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid3-Grid1-GeneralSettingsTypography-TokenExpirationLabel'
            textAlign={commonTextAlign}
          >
            <FormattedMessage
              id='sysConfig.tokenTimeout'
              data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid3-Grid1-GeneralSettingsTypography-TokenExpirationLabel-FormattedMessage-TokenTimeout'
            />
          </GeneralSettingsTypography>
          <MyOnlyText
            id='systemAdminSystemConfigurationTokenExpirationInput'
            data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid3-Grid2-TextField-TokenExpirationInput'
            value={tokenTimeout}
            setValue={newValue => {
              setTokenTimeout(newValue);
              setCannotCancel(false);
            }}
            onBlur={handleTokenTimeBlur}
            height='40px'
            isError={!(!alertTokeTime || tokenTimeout === null)}
            errorMessage={
              <FormattedMessage
                id='sysConfig.tokenTimeoutErr'
                data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid3-Grid4-Typography-TokenExpirationHintText-TokenTimeoutErr'
              />
            }
          />
        </ItemBlock>
        <ItemBlock>
          <GeneralSettingsTypography
            id='systemAdminSystemConfigurationMaxEditorLabel'
            data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid4-Grid1-GeneralSettingsTypography-MaxEditorLabel'
            textAlign={commonTextAlign}
          >
            <FormattedMessage
              id='sysConfig.maxGE'
              data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid4-Grid1-GeneralSettingsTypography-MaxEditorLabel-MaxGE'
            />
          </GeneralSettingsTypography>
          <MyOnlyText
            id='systemAdminSystemConfigurationMaxEditorInput'
            data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid4-Grid2-TextField'
            value={maxGuestEditors}
            setValue={newValue => {
              setMaxGuestEditors(newValue);
              setCannotCancel(false);
            }}
            onBlur={handleMaxGuestEditorsBlur}
            height='40px'
            isError={!(!alertMaxGuestEditors || maxGuestEditors === null)}
            errorMessage={
              <FormattedMessage
                id={
                  maxGuestEditors < oldMaxGuestEditors
                    ? 'sysConfig.numberLessErr'
                    : 'sysConfig.maxGEErr'
                }
                data-selenium-id={
                  maxGuestEditors < oldMaxGuestEditors
                    ? 'sysConfig.numberLessErr'
                    : 'sysConfig.maxGEErr'
                }
              />
            }
          />
        </ItemBlock>
        <ItemBlock>
          <GeneralSettingsTypography
            id='systemAdminSystemConfigurationMaxArticleLabel'
            data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid5-Grid1-GeneralSettingsTypography-MaxArticleLabel'
            textAlign={commonTextAlign}
          >
            <FormattedMessage
              id='sysConfig.maxArticle'
              data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid5-Grid1-GeneralSettingsTypography-MaxArticleLabel-FormattedMessage-MaxArticle'
            />
          </GeneralSettingsTypography>
          <MyOnlyText
            id='systemAdminSystemConfigurationMaxArticleInput'
            data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid5-Grid2-TextField-MaxArticleInput'
            value={maxArticles}
            setValue={newValue => {
              setMaxArticles(newValue);
              setCannotCancel(false);
            }}
            onBlur={handleSetMaxArticlesBlur}
            height='40px'
            isError={!(!alertMaxArticles || maxArticles === null)}
            errorMessage={
              <FormattedMessage
                id={
                  maxArticles < oldMaxArticles
                    ? 'sysConfig.numberLessErr'
                    : 'sysConfig.maxArticleErr'
                }
                data-selenium-id={
                  maxArticles < oldMaxArticles
                    ? 'sysConfig.numberLessErr'
                    : 'sysConfig.maxArticleErr'
                }
              />
            }
          />
        </ItemBlock>
        <ItemBlock>
          <GeneralSettingsTypography
            id='systemAdminSystemConfigurationmaxJournalsLabel'
            data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid3-Grid1-GeneralSettingsTypography-maxJournalsLabel'
            textAlign={commonTextAlign}
          >
            <FormattedMessage
              id='sysConfig.maxJournals'
              data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid3-Grid1-GeneralSettingsTypography-maxJournalsLabel-FormattedMessage-maxJournals'
            />
          </GeneralSettingsTypography>
          <MyOnlyText
            id='systemAdminSystemConfigurationmaxJournalsInput'
            data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid3-Grid2-TextField-maxJournalsInput'
            value={maxJournals}
            setValue={newValue => {
              setMaxJournals(newValue);
              setCannotCancel(false);
            }}
            onBlur={handleMaxJournalsBlur}
            height='40px'
            isError={!(!alertMaxJournals || maxJournals === null)}
            errorMessage={
              <FormattedMessage
                id='sysConfig.maxJournalsErr'
                data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Box-Grid-Grid-Grid3-Grid4-Typography-maxJournalsErrHintText-maxJournalsErr'
              />
            }
          />
        </ItemBlock>
      </Stack>
      {/* 输入框finish */}

      {/* <Divider data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Divider' /> */}

      {/* 按钮 */}
      <Grid
        data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Grid'
        container
        justifyContent='flex-start'
        wrap='nowrap'
        mt={3}
        spacing={2}
      >
        <Grid
          item
          data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Grid-Grid2'
        >
          <SIMTButton
            id='systemAdminSystemConfigurationSaveGeneralButton'
            data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Grid-Grid2-SIMTButton-SaveGeneral'
            variant='contained'
            color='grayBlue'
            onClick={handleUpdate}
            disabled={cannotUpdate}
            sx={{
              padding: '12px 40.5px',
              height: '41px',
              // [`&.${buttonClasses.root}`]: {
              //   textTransform: 'uppercase !important', // 这个与一般的SIMT button又不一样了。。。
              // },
            }}
          >
            Save
          </SIMTButton>
        </Grid>
        <Grid
          item
          data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Grid-Grid1'
        >
          <SIMTButton
            id='systemAdminSystemConfigurationResetGeneralButton'
            data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Grid-Grid1-SIMTButton-ResetGeneral'
            variant='contained'
            color='grayBlue'
            onClick={handleReset}
            disabled={cannotCancel}
            sx={{
              padding: '12px 40.5px',
              height: '41px',
              // [`&.${buttonClasses.root}`]: {
              //   textTransform: 'uppercase !important', // 这个与一般的SIMT button又不一样了。。。
              // },
            }}
          >
            Reset
          </SIMTButton>
        </Grid>
        <Grid
          item
          data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Grid-Grid1'
          sx={{
           display: 'none'
          }}
        >
          <Button
            id='systemAdminSystemConfigurationCancelGeneralButton'
            data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Grid-Grid1-SIMTButton-CancelGeneral'
            // onClick={handleResetOrder}
            disabled={cannotCancel}
            className={styleModule.CancelButton}
            sx={{
              textTransform: 'none',
              width: '68px',
              height: '41px',
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      <CommonDialog
        id='systemAdminSystemConfiguration'
        data-seleunim-id='SystemAdmin_SystemConfiguration-GeneralSettings-Box-Grid-CommonDialog'
        title='Update system configuration'
        content='Are you sure you want to proceed ?'
        handleClose={handleAlertCancel}
        handleConfirm={handleAlertOK}
        alertOpen={dialogOpen}
      />
    </Box>
  );
}

export default GeneralSettings;

import { Box, FormControl } from '@mui/material';

import React, { useState, useEffect } from 'react';
import { BootstrapInput } from '../../../../components/CreateAndUpdate/MyOperationModule';
import { BootstrapTitle } from '../../../../components/CreateAndUpdate/MyTitle';
import { NumberHelperText } from '../../../../components/CreateAndUpdate/MyFormHelperText';
import {
  defaultVars,
  ErrVars,
} from '../../../../components/CreateAndUpdate/commonStyle';
import ItemBlock from '../CGTComponents/ItemBlock';
import InputBox from '../CGTComponents/InputBox';
import { useEffectDebugger } from '@/utils/commonUtils';
import { set } from 'date-fns';

export default function CreateAndUpdateNumber(props) {
  //maxNumber:最大值(number)
  //minNumber:最小值(number)
  //exSpecialChar：排除特殊字符
  //setValue：设置文本框的值(必须设置value值)
  //setSCErr:传递包含特殊字符的Err信息给父组件
  const {
    maxNumber,
    minNumber,
    exSpecialChar,
    setValue,
    setSCErr,
    id,
    title,
    required,
    emptyErr,
    placeholder,
    HandleChange,
    setOutRangeErr,
    outRangeErr,
    value,
    setHasError = e => {},
    titleSXTextTransform,
    ...other
  } = props;

  const [specialChar, setSpecialChar] = useState(false);

  const [outRangeError, setOutRangeError] = useState(false);

  /**
   * 检测是否为数字
   * @param text
   * @return {boolean}  是纯数字则返回true，否则返回false
   */
  const isNumber = text => {
    let pattern = /^[+]?(\d+)$|^[+]?(\d+\.\d+)$/g;
    return pattern.test(text);
  };

  const isInteger = text => {
    let pattern = /^[+]?(\d+)$/g;
    return pattern.test(text);
  };

  const handleTextChange = e => {
    const inputContent = e.target.value;
    // console.log("inputContent", inputContent, isNumber(inputContent));
    let valueToBeSet;
    if (inputContent === '') {
      // input empty
      valueToBeSet = null; // fallback to default: null
      setSpecialChar(false);
    } else if (isInteger(inputContent)) {
      setSpecialChar(false);
      // number input
      valueToBeSet = Number(inputContent);
      if (valueToBeSet > maxNumber) {
        setOutRangeError(true);
        setOutRangeErr(true);
        valueToBeSet = maxNumber;
      } else if (valueToBeSet < minNumber) {
        valueToBeSet = minNumber===1?valueToBeSet:minNumber;
        setOutRangeError(true);
        setOutRangeErr(true);
      } else {
        setOutRangeError(false);
        setOutRangeErr(false);
      }
    } else {
      // error input not change but prompt
      // valueToBeSet = props.value;
      valueToBeSet = inputContent;
      setSpecialChar(true);
    }
    if (setValue) {
      setValue(valueToBeSet);
    }
    if (HandleChange) {
      HandleChange(valueToBeSet);
    }
  };

  const [Vars, setVars] = useState(defaultVars);

  React.useEffect(() => {
    setVars(
      emptyErr || (exSpecialChar && specialChar) || props.error || outRangeError
        ? ErrVars
        : defaultVars
    );
  }, [exSpecialChar, specialChar, props.error, outRangeError, emptyErr]);

  React.useEffect(() => {
    if (value > maxNumber || value < minNumber) {
      setOutRangeError(true);
      setOutRangeErr(true);
    } else {
      setOutRangeError(false);
      setOutRangeErr(false);
    }
  },[minNumber, maxNumber]);

  const [totalError, setTotalError] = useState(false);

  useEffect(() => {
    setHasError(totalError);
  }, [totalError]);

  React.useEffect(() => {
    setTotalError(
      emptyErr || (exSpecialChar && specialChar) || props.error || outRangeError
    );
  }, [exSpecialChar, specialChar, props.error, outRangeError, emptyErr]);

  const getErrorText = () => {
    let helperText = '';
    if (exSpecialChar && specialChar) {
      helperText = 'Please input positive integer number!';
    } else if (outRangeError) {
      helperText = `Number must between ${minNumber} and ${maxNumber}`;
    } else if (props.error) {

      helperText = props.helperText;
    } else {
      helperText = 'This field is required.';
    }
    return helperText;
  };

  const [stateHover, setStateHover] = useState(false);
  const [stateFocus, setStateFocus] = useState(false);

  return (
    <ItemBlock
      id={id}
      title={title}
      required={required}
      isError={totalError}
      errorText={getErrorText()}
      titleSXTextTransform={titleSXTextTransform}
    >
      <InputBox
        id={`${id}_number_input`}
        value={value}
        placeholder={placeholder ? placeholder : '0'}
        onChange={handleTextChange}
        onMouseEnter={() => {
          setStateHover(true);
        }}
        onMouseLeave={() => {
          setStateHover(false);
        }}
        onFocus={() => {
          setStateFocus(true);
          setTotalError(false);
        }}
        onBlur={() => {
          setStateFocus(false);
          setTotalError(
            emptyErr ||
              (exSpecialChar && specialChar) ||
              props.error ||
              outRangeError
          );
        }}
        disableClearable={
          !(value && value !== '' && (stateFocus || stateHover))
        }
        onDelete={() => {
          if (setValue) {
            setValue('');
          }

          if (HandleChange) {
            HandleChange('');
          }
        }}
        error={totalError}
      />
    </ItemBlock>
  );
}

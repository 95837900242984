// @ts-check
import React from 'react';
import {
  Backdrop,
  backdropClasses,
  Box,
  Fade,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// @ts-ignore
import ExportAllCloseIcon from '@/assets/ExportAllCloseIcon.svg';
import { styled } from '@mui/material/styles';
import CloseIconButton from '@/componentsphase2/Button/CloseIconButton';
import { StateButton } from '@/componentsphase2/StateButton';

export const BlueBackdrop = styled(Backdrop)(() => ({
  [`&.${backdropClasses.root}`]: {
    background: 'rgba(17, 61,149, 0.5)',
    backdropFilter: 'blur(4px)',
  },
}));
// const SmallBoldFont = {
//   fontFamily: 'Open Sans',
//   fontStyle: 'normal',
//   fontWeight: '600',
//   fontSize: '14px',
//   lineHeight: '18px',
// };
// export const drawer_bottom_button_cancel = {
//   ...SmallBoldFont,
//   color: '#0052CC',
//   cursor: 'pointer',
//   borderRadius: '4px',
//   boxSizing: 'border-box',
//   padding: '8px 12px',
//   flexDirection: 'row',
//   background: '#FFFFFF',
//   width: '70px',
//   height: '34px',
//   border: '1px solid #0052CC',
//   // marginLeft: '340px',
//   marginLeft: 'auto',
//   marginRight: '10px',
//   textAlign: 'center',
//   // marginTop : '8px',
//   // marginLeft: '292px',
// };
// export const drawer_bottom_button_add = {
//   ...SmallBoldFont,
//   color: '#FFFFFF',
//   cursor: 'pointer',
//   padding: '8px 12px',
//   flexDirection: 'row',
//   background: '#0052CC',
//   width: '70px',
//   height: '34px',
//   boxSizing: 'border-box',
//   textAlign: 'center',
//   // marginLeft: '22px',
//   borderRadius: '5px',
// };
/**
 *
 * @param {import("./index").SIModalProProps} props
 * @returns
 */
const SIModalPro = props => {
  const {
    open = false, // 是否打开模态框
    handleClose = () => { }, // 关闭模态框的回调函数

    title = '', // 模态框标题

    haveCloseButton = true,
    closeButtonSx = {},
    isRightModel = false,//是否是靠右、与屏幕等高的模态框
    needTitle = true,
    width = isRightModel ? '924px' : '600px', // 模态框宽度
    height = isRightModel ? '100vh' : '600px', // 模态框高度
    backgroundColor = '#fff', // 背景颜色

    children, // 模态框的主体内容
    containerSx = {},
    titleElseElement,

    sx = {}, // 这个 sx 将被应用到 MUI 提供的 Modal 的 sx 中
    boxSx = {}, // 模态框最外层是一个 Box，boxSx 和其他非 sx 的 props 将被添加到这个 Box 中

    ...others
  } = props;

  /**
   * @type {import("@mui/material").SxProps}
   */
  const boxStyle = {
    margin: 0,
    // padding: '24px 36px 24px 36px',

    position: 'absolute',
    top: '50%',
    left: isRightModel ? '' : '50%',
    right: isRightModel ? '0' : '',
    transform: isRightModel ? 'translate(0, -50%)' : 'translate(-50%, -50%)',

    width: width,
    height: height,
    maxHeight: '100%',
    backgroundColor: backgroundColor,
    boxSizing: 'border-box',
    ...boxSx,
  };

  /**
   * @type {import("@mui/material").SxProps}
   */
  const titleStyle = {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '22.5px',
    color: '#262E35',
    paddingLeft: '72px',
    //           paddingTop: '27px',
  };

  // 关闭按钮
  const closeButton = (
    // <IconButton
    //   sx={{
    //     position: 'absolute',
    //     padding: 0,
    //     top: 0,
    //     left: 0,
    //     zIndex: 1301,
    //   }}
    //   onClick={handleClose}
    // >
    //   <ExportAllCloseIcon />
    // </IconButton>
    <CloseIconButton
      sx={{
        position: 'absolute',
        padding: '0',
        top: 25,
        left: 36,
        zIndex: 1301,
        ...closeButtonSx
      }}
      onClick={handleClose}
    ></CloseIconButton>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      components={{
        Backdrop: BlueBackdrop,
      }}
      sx={{
        zIndex: '1300',
        ...sx,
      }}
    >
      <Fade in={open}>
        <Box
          className='SIModalPro-container'
          sx={{ width: '100%', ...boxStyle }}
          {...others}
        >
          <Stack sx={{ position: 'relative', height: '100%' }}>
            {haveCloseButton ? (closeButton) : (<></>)}
            {needTitle ?
              <>
                <Box sx={{
                  zIndex: '1300',

                  minHeight: '74px',
                  borderBottom: '1px solid #DFE4E8'
                }}>
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} minHeight='74px'>
                    <Typography variant='h4' component='h2' sx={{ ...titleStyle, flex: '1' }}>
                      {title}
                    </Typography>
                    <Box marginRight={'36px'}>{titleElseElement}</Box>

                  </Stack>
                </Box>
              </>
              : <></>}
            <Box
              sx={{
                padding: '20px 16px 20px 36px',
                overflow: 'scroll',
                ...containerSx,
              }}>
              {children}
            </Box>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SIModalPro;

/** @type {import("./axios-response").AxiosResponseEmptyInstance} */
export const axiosResponseEmptyInstance = {
  data: {
    code: 200,
    message: '',
    data: {},
  },
  status: 200,
  statusText: '',
  headers: {},
  config: {},
};

import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSubmitOptInCode,

} from '@/actions/SIMT-SI/CatJournal/CATJournalAction';
import { testInputFont } from '@/components/FontStyle';
import {
  ItemBlock,
  OptInAutoComplete,
  OptInSelect,
  OptInTextArea,
} from './Components';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import { set } from 'date-fns';
import {submitOptInCheckJournal} from "@/actions/SIMT-SI/CatJournal/OptIn";
import {getActiveCE, getCE} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueSystemAdmin";
import {getJournalListWithCode, getJournalListWithName} from "@/actions/SIMT-SI/SpecialIssue/Journal";
import {getATEJournalDropList} from "@/actions/SIMT-SIP/SIP/Journal";
import {PERMISSIONS} from "@/constant/permission";

export default function SubmitContent(props) {
  const { errorMonitor = false, setValue = () => {}, open } = props;
  const [journal, setJournal] = useState({
    journalCode: '',
    journalName: '',
  });
  const [developmentModel, setDevelomentModel] = useState('');
  const [handleCe, setHandleCe] = useState(null);
  const [handleCeInputValue, setHandleCEInputValue] = useState('');
  const [reasonForOptIn, setReasonForOptIn] = useState('');
  const [pipelineandFuture, setPipelineandFuture] = useState('');

  const userPermissions = localStorage.getItem('userPermissions'); //权限管理

  const dispatch = useDispatch();

  const {
    JourListResult,
    UserFirstNameInfo,
    UserLastNameInfo,
    UserEmailInfo,
    CEResult,
    submitOptInCode,
  } = useSelector(state => {
    return {
      JourListResult: state.SI.JourList,
      UserFirstNameInfo: state.User.firstName, //登录的用户信息
      UserLastNameInfo: state.User.lastName,
      UserEmailInfo: state.User.email,
      CEResult: state.SI.CE,
      submitOptInCode: state.CATJournal.submitOptInCode,
    };
  });

  useEffect(() => {
    if (open){
      dispatch(submitOptInCheckJournal(journal.journalCode));
    }else {
      setJournal({
        journalCode: '',
        journalName: '',
      });
      setDevelomentModel('');
      setHandleCe(null);
      setHandleCEInputValue('');
      setReasonForOptIn('');
      setPipelineandFuture('');
    }
  },[open]);

  useEffect(() => {
    if (UserFirstNameInfo !== '' && userPermissions.includes(PERMISSIONS.SI_WRITE)) {
      setHandleCe({
        username: UserLastNameInfo + ', ' + UserFirstNameInfo,
        email: UserEmailInfo,
      });
      setHandleCEInputValue(UserLastNameInfo + ', ' + UserFirstNameInfo);
    }
  }, [UserFirstNameInfo, open]);

  useEffect(() => {
    setValue({
      journalCode: journal ? journal.journalCode : '',
      developmentModel: developmentModel ? developmentModel : '',
      handlingCEEmail: handleCe ? handleCe.email : '',
      reason: reasonForOptIn,
      currentSIPipelineAndFutureSIPlan: pipelineandFuture,
      requestUserEmail: UserEmailInfo,
      type:'opt:in'
    });
  }, [journal, developmentModel, handleCe, reasonForOptIn, pipelineandFuture]);

  const journalErrorText =
    submitOptInCode === 14119
      ? 'This Journal is in the SCA Journal list already!'
      : submitOptInCode === 14120
      ? 'This Journal has been requested already!'
      :  journal.journalCode === ''
      ? 'This field is mandatory to input!'
      : '';

  const [journalFocused, setJournalFocused] = useState(false);
  const journalCodeError =
    (errorMonitor && journal.journalCode === '') ||
    submitOptInCode === 14119 ||
    submitOptInCode === 14120;
  useEffect(() => {
    dispatch(getJournalListWithName(''));
  }, []);

  useEffect(() => {
    dispatch(clearSubmitOptInCode());
    if (journal.journalCode !== '') {
      dispatch(submitOptInCheckJournal(journal.journalCode));
    }
  }, [journal]);
  if(!Array.isArray(JourListResult)){
    return <></>;
  }

  return (
    <Stack
      direction='column'
      spacing={3}
      sx={{
        p: '20px 36px 25px 36px',
        // mt: '20px',
        // mb: '25px',
        overflowY: 'auto',
        scrollbarWidth: 'thin', // 设置滚动条宽度
        scrollbarColor: 'transparent', // 设置滚动条颜色为透明
        '&::-webkit-scrollbar': {
          width: '0px', // 设置滚动条宽度
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'transparent', // 设置滚动条背景为透明
        },
      }}
      data-selenium-id='CATJournal_Overview-OptInOperation-SubmitContent-Stack'
    >
      {/* Journal Code: */}
      <ItemBlock
        title='Journal Code'
        mandatory={true}
        error={journalCodeError && !journalFocused}
        errorText={journalErrorText}
      >
        <SinpleAutocomplete
          id='sinpleAutocompleteJournalCode'
          data-selenium-id='CATJournal_Overview-OptInOperation-SubmitContent-CodeSelectAutoComplete'
          loadOptionAction={getJournalListWithCode}
          isEmpty={journal?.journalCode === ''}
          value={journal}
          isErr={journalCodeError && !journalFocused}
          options={JourListResult}
          getOptionLabel={option => option.journalCode}
          placeholder='Choose journal code'
          onChange={(e, newValue) => {
            setJournal({
              journalCode: newValue ? newValue.journalCode : '',
              journalName: newValue ? newValue.journalName : '',
            });
          }}
          onFocus={() => {
            setJournalFocused(true);
          }}
          onBlur={() => {
            setJournalFocused(false);
          }}
          deleteValue={() => {
            setJournal({
              journalCode: '',
              journalName: '',
            });
          }}
        />
      </ItemBlock>
      {/* Journal Name: */}
      <ItemBlock
        title='Journal Name'
        mandatory={true}
        error={journalCodeError && !journalFocused}
        errorText={journalErrorText}
      >
        <SinpleAutocomplete
          id='sinpleAutocompleteJournalName'
          data-selenium-id='CATJournal_Overview-OptInOperation-SubmitContent-NameSelectAutoComplete'
          loadOptionAction={getJournalListWithName}
          isErr={journalCodeError && !journalFocused}
          // setError={setJournalCodeError}
          isEmpty={journal?.journalName === ''}
          value={journal}
          options={JourListResult}
          getOptionLabel={option => option.journalName}
          renderOption={(props, option) => (
            <li {...props}>{option.journalName}</li>
          )}
          placeholder='Choose journal name'
          onChange={(e, newValue) => {
            setJournal({
              journalCode: newValue
                ? newValue.journalCode
                : journal.journalCode,
              journalName: newValue ? newValue.journalName : '',
            });
          }}
          onFocus={() => {
            setJournalFocused(true);
          }}
          onBlur={() => {
            setJournalFocused(false);
          }}
          deleteValue={() => {
            setJournal({
              journalCode: '',
              journalName: '',
            });
          }}
        ></SinpleAutocomplete>
      </ItemBlock>
      {/* Development Model: */}
      <ItemBlock
        title='Development Model'
        mandatory={true}
        error={
          errorMonitor && (developmentModel === '' || developmentModel === null)
        }
        errorText='This field is mandatory to input!'
      >
        <SinpleAutocomplete
          id='sinpleAutocompleteDevelopmentModel'
          data-selenium-id='CATJournal_Overview-OptInOperation-SubmitContent-DevelopmentModelSelect'
          options={['SI Commissioning', 'Basic Management']}
          value={developmentModel}
          defaultQuery={true}
          placeholder='Choose development model'
          onChange={(e, value) => {
            setDevelomentModel(value);
          }}
          isErr={
            errorMonitor &&
            (developmentModel === '' || developmentModel === null)
          }
          isEmpty={developmentModel === ''}
        />
      </ItemBlock>
      {/* Requested by: */}
      <ItemBlock title='Requested by' mandatory={false}>
        <Box
          sx={{
            ...testInputFont,
            letterSpacing: '-0.011em',
            color: '#98A7B6',
            lineHeight: '16.8px',
            fontWeight: 400,
          }}
          data-selenium-id='CATJournal_Overview-OptInOperation-SubmitContent-Box'
        >
          {UserLastNameInfo}, {UserFirstNameInfo}
        </Box>
      </ItemBlock>
      {/* SCA Lead : */}
      <ItemBlock title='SCA Lead :' mandatory={false}>
        <SinpleAutocomplete
          limitTags={6}
          id='sinpleAutocompleteHandlingCE'
          data-selenium-id='CATJournal_Overview-OptInOperation-SubmitContent-HandlingCESelect'
          loadOptionAction={getActiveCE}
          placeholder='Choose SCA Lead'
          inputValue={handleCeInputValue}
          needEmptyCheck={false}
          value={handleCe}
          options={CEResult}
          isEmpty={handleCe===null}
          getOptionLabel={option => {
            if (option === null) {
              return option;
            } else {
              return option.username + ' (' + option.email + ')';
            }
          }}
          renderOption={(props, option) => (
            <li {...props}>
              {option.username} ({option.email})
            </li>
          )}
          // disableClearable={false}
          onChange={(e, newValue) => {
            setHandleCEInputValue(newValue ? newValue.username : '');
            setHandleCe(newValue ? newValue : null);
          }}
          needKeyboardDelete={true}
          deleteValue={() => {
            setHandleCEInputValue('');
            setHandleCe(null);
          }}
        ></SinpleAutocomplete>
      </ItemBlock>
      {/* Reason for Opt-in: */}
      <ItemBlock
        title='Reason for Opt-in:'
        mandatory={true}
        error={
          (errorMonitor && reasonForOptIn === '') ||
          reasonForOptIn.length > 10000
        }
        errorText={
          reasonForOptIn.length > 10000
            ? 'The character count should be no more than 10,000.'
            : 'This field is mandatory to input!'
        }
      >
        <OptInTextArea
          id='OptInReasonInput'
          data-selenium-id='CATJournal_Overview-OptInOperation-SubmitContent-ReasonInput'
          value={reasonForOptIn}
          limitLength={10000}
          setValue={setReasonForOptIn}
          errorMonitor={
            (errorMonitor && reasonForOptIn === '') ||
            reasonForOptIn.length > 10000
          }
        />
        <Box
          sx={{
            fontFamily: 'Open Sans',
            height: 0,
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '18px',

            color: (
              reasonForOptIn && reasonForOptIn.length
                ? reasonForOptIn.length > 10000
                : false
            )
              ? '#EE5350'
              : '#596A7C',
            float: 'right',
            ml: 'auto',
          }}
          data-selenium-id='CATJournal_Overview-OptInTable-RejectTextArea-Box-Box'
        >
          {reasonForOptIn.length + '/10,000'}
        </Box>
      </ItemBlock>
      {/* Current SI Pipeline and Future SI Plan: */}
      <ItemBlock
        title='Current SI Pipeline and Future SI Plan:'
        mandatory={true}
        error={
          (errorMonitor && pipelineandFuture === '') ||
          pipelineandFuture.length > 100000
        }
        errorText={
          pipelineandFuture.length > 100000
            ? // ? 'more charcter can not be inputted if 100000 characters has already been inputted'
              'The character count should be no more than 100,000.'
            : 'This field is mandatory to input!'
        }
      >
        <OptInTextArea
          id='OptInPipelineandFutureInput'
          data-selenium-id='CATJournal_Overview-OptInOperation-SubmitContent-PipelineandFutureInput'
          value={pipelineandFuture}
          limitLength={100000}
          setValue={setPipelineandFuture}
          errorMonitor={errorMonitor}
        />
        <Box
          sx={{
            fontFamily: 'Open Sans',
            height: 0,
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '18px',

            color: (
              pipelineandFuture && pipelineandFuture.length
                ? pipelineandFuture.length > 100000
                : false
            )
              ? '#EE5350'
              : '#596A7C',
            float: 'right',
            ml: 'auto',
          }}
          data-selenium-id='CATJournal_Overview-OptInTable-RejectTextArea-Box-Box'
        >
          {pipelineandFuture.length + '/100,000'}
        </Box>
      </ItemBlock>
    </Stack>
  );
}

import WileyLogo from '../../../../assets/LoginAsset/WileyLogo.svg';
import styled from '@emotion/styled';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VerifySlide from '@/modules/Captcha';
import { clear, setForgetPassEmailMessage } from '@/actions/SIMT-User/Login/LoginAction';
import { useDispatch, useSelector } from 'react-redux';
import ForgotPageInvalidEmail from '@/assets/LoginAsset/InvalidAddress.svg';

import {
  Button,
  IconButton,
  Typography,
  Dialog,
} from '@mui/material';
import EncryptedTextField, {
  StyledTextField,
} from '../Components/EncryptedTextField';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import { set } from 'date-fns';
import MyCaptcha from '../Components/MyCaptcha';
import {forgetPassEmail} from "@/actions/SIMT-User/Login";
import { resetPassFirstTime } from '@/actions/SIMT-User/Login/User';

const ForgotTitleTypography = styled(Typography)({
  color: 'var(--gray-500, #596A7C)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '48px',
  fontFamily: 'Open Sans',
  fontWeight: '800',
  lineHeight: '125%',
  letterSpacing: '0.48px',
});

const InfoTypography = styled(Typography)({
  color: 'var(--gray-300, #BCC5CF)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  lineHeight: '140%',
});

const LabelTypography = styled(Typography)({
  color: 'var(--gray-500, #596A7C)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  lineHeight: '125%',
});


const BackLoginTypography = styled(Typography)({
  color: 'var(--primary-500, #154AB6)',
  textAlign: 'center',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  fontWeight: 700,
  lineHeight: '18px',
  '&:hover': {
    fontWeight: 900,
  },
});

const SendButton = styled(Button)({
  borderRadius: '5px',
  background: 'var(--primary-500, #0052CC)',
  color: 'var(--shades-white, #FFF)',
  textAlign: 'center',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  lineHeight: '18px',
  width: '400px',
  height: '40px',
  padding: '8px 12px',
  gap: '6px',
  '&:hover': {
    background: 'var(--primary-300, #6D98EE)',
  },
  '&:active': {
    background: 'var(--primary-600, #113D95)',
  },
  '&:disabled': {
    background: 'var(--gray-200, #DFE4E8)',
  },
});

const RememberAskTypography = styled(Typography)({
  color: 'var(--gray-300, #BCC5CF)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  lineHeight: '125%',
});

const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const [captcha, setCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');
  const [helperTextShow, setHelperTextShow] = useState(false);
  const [verifySlideKey, setVerifySlideKey] = useState(0);
  const [captchaError, setCaptchaError] = useState(false);

  const [emailAddress, setEmailAddress] = useState('');

  const [firstClick, setFirstClick] = useState(false);

  const dispatch = useDispatch();

  const checkEmail = email => {
    const pattern =
      /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
      // /^[A-Za-z0-9]+([-._][A-Za-z0-9]+)*@[A-Za-z0-9]+(-[A-Za-z0-9]+)*(\.[A-Za-z]{2,6}|[A-Za-z]{2,4}\.[A-Za-z]{2,3})$/;
      // /^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$/;
    return pattern.test(email);
  };

  const selector = state => {
    return { forgetPassEmailMessage: state.Login.forgetPassEmail };
  };
  const { forgetPassEmailMessage } = useSelector(selector);

  const handleSendEmail = () => {
    setVerifySlideKey(verifySlideKey + 1);
    let flag = false;
    if (!checkEmail(emailAddress)) {
      setHelperTextShow(true);
      flag = true;
    }
    if (!captcha) {
      setCaptchaError(true);
      flag = true;
    }
    setCaptcha(false);
    if (flag) {
      return;
    }
    setHelperTextShow(false);
	  dispatch(
      // sendActiveEmail(getUserId())
		  forgetPassEmail(emailAddress, captchaToken)
	  );
    setFirstClick(true);
  };

  useEffect(() => {
    if (forgetPassEmailMessage === true) {
      dispatch(setForgetPassEmailMessage());
      navigate('/simt/EmailSend', {
        state: { email: emailAddress },
      });
    } else {
      if (!firstClick) {
        return;
      }
      setHelperTextShow(true);
    }
  }, [forgetPassEmailMessage]);

  return (
    <div
      style={{
        display: 'flex',
        background: '#FFF',
        height: '100vh',
        width: '100vw',
      }}
      data-selenium-id='Login_NewLogin_ForgotPasswordPage'
    >
      <div
        style={{ width: '311px', height: '100%', background: '#0052CC' }}
        data-selenium-id='Login_NewLogin_ForgotPasswordPage-LeftPart'
      >
        <WileyLogo
          style={{
            marginLeft: '16px',
            marginTop: '16px',
          }}
        />
      </div>
      <div
        style={{
          width: 'calc(100% - 311px)',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
        data-selenium-id='Login_NewLogin_ForgotPasswordPage-RightPart'
      >
        <div
          data-selenium-id='Login_NewLogin_ForgotPasswordPage-RightPart-Content'
          style={{
            marginTop: '148px',
          }}
        >
          <div
            style={{}}
            data-selenium-id='Login_NewLogin_ForgotPasswordPage-ForgotTitle'
          >
            <ForgotTitleTypography>Forgot your password?</ForgotTitleTypography>
          </div>
          <div
            style={{ marginTop: '20px', height: '58px' }}
            data-selenium-id='Login_NewLogin_ForgotPasswordPage-ForgotInfoOrInvalidInfo'
          >
            <div
              style={{
                marginTop: '0px',
                width: '553px',
                display: helperTextShow ? 'none' : 'block',
              }}
            >
              <InfoTypography>
                Don’t worry! Just type in the email address you used to register
                for SIMT, and we will send you a link to reset your password.
              </InfoTypography>
            </div>
            <div
              style={{
                marginTop: '6px',
                display: helperTextShow ? 'block' : 'none',
              }}
            >
              <ForgotPageInvalidEmail />
            </div>
          </div>
          <div
            style={{ marginTop: '20px' }}
            data-selenium-id='Login_NewLogin_ForgotPasswordPage-EmailLabel'
          >
            <LabelTypography>Email</LabelTypography>
          </div>
          <div
            style={{ marginTop: '12px' }}
            data-selenium-id='Login_NewLogin_ForgotPasswordPage-EmailInput'
          >
            <MyOnlyText
              value={emailAddress}
              setValue={setEmailAddress}
              multiline={false}
              width='400px'
              height='40px'
              needEndAdornment={true}
              handleIconButtonClick={() => {
                setEmailAddress('');
              }}
              placeholder='Your email address'
              isError={helperTextShow}
              onFocus={() => {
                setHelperTextShow(false);
              }}
            />
          </div>
          <div
            style={{
              marginLeft: '71px',
              marginTop: '18px',
              // transform: 'scale(0.8)',
              // transformOrigin: 'top left',
              // border: !captcha? '1px solid var(--error-600, #EE5350)' : '',
            }}
            data-selenium-id='Login_NewLogin_ForgotPasswordPage-VerifySlide'
          >
            <div
              style={{
                width: '268px',
                border: captchaError
                  ? '2px solid var(--error-600, #EE5350)'
                  : '',
                borderRadius: '5px',
              }}
            >
              {/* <VerifySlide
                key={verifySlideKey}
                setCaptcha={v => {
                  setCaptcha(v);
                  setCaptchaError(false);
                }}
                imgHeight={163}
                imgWidth={262}
                barHeight={30}
                barWidth={262}
              /> */}
              <MyCaptcha
                Captcha={captcha}
                setCaptcha={v => {
                  setCaptcha(v);
                  setCaptchaError(false);
                }}
                hCaptchaToken={captchaToken}
                setHcaptchaToken={setCaptchaToken}
              />
            </div>
          </div>
          <div
            style={{ marginTop: '22px' }}
            data-selenium-id='Login_NewLogin_ForgotPasswordPage-SendButton'
          >
            <SendButton
              onClick={handleSendEmail}
              disableRipple={true}
              disabled={helperTextShow || !captcha}
            >
              SEND
            </SendButton>
          </div>
          <div
            style={{
              marginTop: '17px',
              marginLeft: '6px',
              display: 'flex',
              flexDirection: 'row',
            }}
            data-selenium-id='Login_NewLogin_ForgotPasswordPage-RememberAsk'
          >
            <RememberAskTypography>
              Remembered your password?
            </RememberAskTypography>
            <Button
              sx={{
                marginLeft: '17px',
                padding: '0',
                textTransform: 'none',
                '&:hover': {
                  background: 'transparent',
                },
              }}
              disableRipple
              disableTouchRipple
              onClick={() => {
                navigate('/simt/login');
              }}
            >
              <BackLoginTypography>Back to Login</BackLoginTypography>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;

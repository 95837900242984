import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { getDescriptionsStyle } from '../styles';
import { Box } from '@mui/material';

export default function CustomDiv(props) {
  const {
    value,
    state,
    id,
    maxHeight,
    handleMore,
    handleLess,
    ListMoreOrLess,
  } = props;
  const [moreOrLess, setMoreOrLess] = useState(true);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [width, setWidth] = useState(0);

  const resizeUpdate = e => {
    // 通过事件对象获取浏览器窗口的高度
    let w = e.target.innerWidth;
    setWidth(w);
  };

  useEffect(() => {
    // 页面刚加载完成后获取浏览器窗口的大小
    let w = window.innerWidth;
    setWidth(w);
    // 页面变化时获取浏览器窗口的大小
    window.addEventListener('resize', resizeUpdate);

    return () => {
      // 组件销毁时移除监听事件
      window.removeEventListener('resize', resizeUpdate);
    };
  }, []);
  const widthRef = useRef(null);

  useEffect(() => {
    if (moreOrLess) {
      const el = widthRef.current;
      let innerText = value === null || value === undefined ? ' ' : value;
      let innerTextArray = innerText.split(' ');
      let correctText = '';
      for (let i = 0; i < innerTextArray.length; i++) {
        // 出现滚动就截取
        if (i === 0) {
          correctText += innerTextArray[i];
        } else {
          correctText += ' ' + innerTextArray[i];
        }

        if (handleMore !== undefined) {
          if (ListMoreOrLess === 'more') {
            el.innerHTML = correctText + "...<span id='testList'>More</span>";
          } else if (ListMoreOrLess === 'less') {
            el.innerHTML = correctText + "<span id='testList1'> Less</span>";
          } else {
            el.innerHTML = correctText;
          }
        } else {
          el.innerHTML = correctText;
        }

        if (el.offsetHeight < el.scrollHeight) {
          correctText.slice(0, correctText.length - 1);
          let tmpflag = 50;
          while (el.offsetHeight < el.scrollHeight) {
            tmpflag -= 1;
            if (tmpflag < 1) break;
            let tmpText = correctText.substring(
              0,
              correctText.lastIndexOf(' ')
            );
            let tmpText2 = correctText.substring(
              0,
              correctText.lastIndexOf('\n')
            );
            if (
              tmpText.length === 0 ||
              (tmpText.length > tmpText2.length && tmpText2.length > 0)
            ) {
              tmpText = tmpText2;
            }
            if (tmpText.length < 3) {
              tmpText = correctText.slice(0, tmpflag);
            }
            correctText = tmpText;
            if (handleMore !== undefined) {
              if (ListMoreOrLess === 'more') {
                el.innerHTML =
                  correctText +
                  "...<span id='test'>More</span> ...<span id='testList'>More</span>";
              } else if (ListMoreOrLess === 'less') {
                el.innerHTML =
                  correctText +
                  "...<span id='test'>More</span> <span id='testList1'> Less</span>";
              } else {
                el.innerHTML = correctText + "...<span id='test'>More</span>";
              }
            } else {
              el.innerHTML = correctText + "...<span id='test'>More</span>";
            }
          }
          if (document.getElementById('test') != null) {
            document.getElementById('test').id = `${id}-More`;
            document.getElementById(`${id}-More`).style.color = '#154AB6';
            document.getElementById(`${id}-More`).style.cursor = 'pointer';
            document
              .getElementById(`${id}-More`)
              .addEventListener('click', handleMoreClick);
            setIsOverflowing(true);
          }
          break;
        }
      }
      if (handleMore !== undefined) {
        if (ListMoreOrLess === 'more') {
          document.getElementById('testList').id = `${id}List-More`;
          document.getElementById(`${id}List-More`).style.color = '#154AB6';
          document.getElementById(`${id}List-More`).style.cursor = 'pointer';
          document
            .getElementById(`${id}List-More`)
            .addEventListener('click', handleListMore);
        } else if (ListMoreOrLess === 'less') {
          document.getElementById('testList1').id = `${id}List-Less`;
          document.getElementById(`${id}List-Less`).style.color = '#154AB6';
          document.getElementById(`${id}List-Less`).style.cursor = 'pointer';
          document
            .getElementById(`${id}List-Less`)
            .addEventListener('click', handleListLess);
        }
      }
    }
  }, [width, moreOrLess, ListMoreOrLess]);

  const handleListMore = () => {
    handleMore();
  };

  const handleListLess = () => {
    handleLess();
  };

  const handleMoreClick = () => {
    if (handleMore !== undefined) {
      if (ListMoreOrLess === 'more') {
        document.getElementById(id).innerHTML =
          value +
          "<span id='test1'> Less</span> ...<span id='testList'>More</span>";
        document.getElementById('testList').id = `${id}List-More`;
        document.getElementById(`${id}List-More`).style.color = '#154AB6';
        document.getElementById(`${id}List-More`).style.cursor = 'pointer';
        document
          .getElementById(`${id}List-More`)
          .addEventListener('click', handleListMore);
      } else if (ListMoreOrLess === 'less') {
        document.getElementById(id).innerHTML =
          value +
          "<span id='test1''> Less</span> <span id='testList1'> Less</span>";
        document.getElementById('testList1').id = `${id}List-Less`;
        document.getElementById(`${id}List-Less`).style.color = '#154AB6';
        document.getElementById(`${id}List-Less`).style.cursor = 'pointer';
        document
          .getElementById(`${id}List-Less`)
          .addEventListener('click', handleListLess);
      } else {
        document.getElementById(id).innerHTML =
          value + "<span id='test1'> Less</span>";
      }
    } else {
      document.getElementById(id).innerHTML =
        value + "<span id='test1'> Less</span>";
    }
    document.getElementById('test1').style.color = '#154AB6';
    document.getElementById('test1').style.cursor = 'pointer';
    document.getElementById('test1').id = `${id}-Less`;
    document
      .getElementById(`${id}-Less`)
      .addEventListener('click', handleLessClick);
    document.getElementById(id).style.maxHeight = 'none';
    setIsOverflowing(false);
    setMoreOrLess(false);
  };

  const handleLessClick = () => {
    setMoreOrLess(true);
    document.getElementById(id).style.maxHeight = maxHeight;
  };

  return (
    <Box
      key={id}
      data-selenium-id='SIDetail_MarketSection-CustomDiv-Container'
    >
      <Box
        id={id}
        data-selenium-id={'id'}
        key={id}
        ref={widthRef}
        sx={{
          ...getDescriptionsStyle(state),
          // width: '860px !important',`//modified
          minHeight: '20px',
          maxHeight: maxHeight,
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          '& p':{
            margin:'0px !important'
          }
        }}
      ></Box>
    </Box>
  );
}

// @ts-check
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SIPRevisePageHeader } from './CustomComponents/CustomHeader';
import Footer from '../../../components/Footer';
import ThankYouPage from '../SubmitCompletedPage/ThankYouPage';
import InitialReviewRevisionCancelPage from '../SubmitCompletedPage/InitialReviewRevisionCancelPage';
import ProposalShelvedPage from '../SubmitCompletedPage/ProposalShelvedPage';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  loadSIPReviseData,
  SET_DOWNLOAD_REVISE_DATA_STATUS,
  SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR,
} from '../../../actions/SIMT-SIP/SIP/SIPAction';
import { useIntl } from 'react-intl';
import CommonSnackBar from '../../../modules/ConfirmSnackBar/CommonSnackBar';
import ProposerRevisePage from './ProposerRevisePage';
import EditorReviewPage from './EditorReviewPage';
import JPMReviewPage from './JPMReviewPage';
import EbmReviewPage from './EbmReviewPage';
import { axios_instance } from '@/utils/axios_instance';
import ExpiredPage from "@/pages/SIPPage/RevisePage/ExpiredPage";
import {RevisePageBodyContainer} from "@/pages/SIPPage/RevisePage/CustomComponents/CustomContainer";

/**
 * @typedef {typeof import("./RevisePage")} RevisePage
 * @typedef {import("./RevisePage").GEInformationItem} GEInformationItem
 * @typedef {import("./RevisePage").SipInformation} SipInformation
 * @typedef {import("./RevisePage").CommissionApproaches} CommissionApproaches
 * @typedef {import("./RevisePage").SIPReviseData} SIPReviseData
 * @typedef {import("./ProposerRevisePage").DownloadReviseDataStatus} DownloadReviseDataStatus
 * @typedef {import("./ProposerRevisePage").DownloadReviseDataErrorType} DownloadReviseDataErrorType
 */

/**
 *
 * @param {import("./RevisePage").RevisePage} props
 * @returns
 */
const RevisePage = props => {
  const { type } = props;
  const intl = useIntl();
  const {
    sipReviseData,
    downloadReviseDataStatus,
    downloadReviseDataErrorType,
  } = useSelector(state => ({
    /** @type {SIPReviseData} */
    // @ts-ignore
    sipReviseData: state.SIP.sipReviseData,
    /** @type {DownloadReviseDataStatus} */
    // @ts-ignore
    downloadReviseDataStatus: state.SIP.downloadReviseDataStatus,
    /** @type {DownloadReviseDataErrorType} */
    // @ts-ignore
    downloadReviseDataErrorType: state.SIP.downloadReviseDataErrorType,
  }));

  const { token } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch({
        type: SET_DOWNLOAD_REVISE_DATA_STATUS,
        /** @type {DownloadReviseDataStatus} */ data: 'downloading',
      });
      dispatch(loadSIPReviseData(token, type));
    }
  }, []);

  const [isShevled, setIsShevled] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [sipSubmited, setSipSubmited] = useState(false);
  const [isInitialReviewRevisionCancelled, setIsInitialReviewRevisionCancelled] = useState(false);
  const [otherErrorMessage, setOtherErrorMessage] = useState('');

  useEffect(() => {
    setLoadingData(downloadReviseDataStatus === 'downloading');

    if (
      downloadReviseDataStatus !== 'idle' &&
      downloadReviseDataStatus !== 'downloading'
    ) {
      setOtherErrorMessage('');
      setSipSubmited(false);
      setIsShevled(false);
      setIsInitialReviewRevisionCancelled(false);
      if (downloadReviseDataStatus === 'error') {
        if (downloadReviseDataErrorType === 'has been submitted') {
          setSipSubmited(true);
        } else if (downloadReviseDataErrorType === 'deadline pass') {
          setOtherErrorMessage('Revision deadline has passed!');
        } else if (downloadReviseDataErrorType === 'shelved') {
          setIsShevled(true);
        } else if(downloadReviseDataErrorType === 'initial review revision cancelled'){
          setIsInitialReviewRevisionCancelled(true);
        } else {
          setOtherErrorMessage(
            'Path not find! This revise url is unreachable.'
          );
        }
      }

      dispatch({
        type: SET_DOWNLOAD_REVISE_DATA_STATUS,
        /** @type {DownloadReviseDataStatus} */ data: 'idle',
      });
    }
  }, [downloadReviseDataStatus, downloadReviseDataErrorType]);

  const getRevisePage = () => {
    if (sipReviseData.type === 'None') return <></>;

    if (sipReviseData.type !== type) {
      return <></>;
    }

    switch (sipReviseData.type) {
      case 'ProposerRevise':
        return (
          <ProposerRevisePage
            data-selenium-id='SIP_RevisePage-Proposer1'
            type={sipReviseData.type}
            sipProposerReviseData={sipReviseData}
          />
        );
      case 'ProposerReviseFromJE':
        return (
          <ProposerRevisePage
            data-selenium-id='SIP_RevisePage-Proposer2'
            type={sipReviseData.type}
            sipProposerReviseData={sipReviseData}
          />
        );
      case 'EditorReview':
        return (
          <EditorReviewPage
            sipEditorReviewData={sipReviseData}
            data-selenium-id='SIP_RevisePage-Editor'
          />
        );
      case 'JPMReview':
        return (
          <JPMReviewPage
            jpmReviewData={sipReviseData}
            data-selenium-id='SIP_RevisePage-JPM'
          />
        );
      case 'EbmReview':
        return (
          <EbmReviewPage
            sipEditorReviewData={sipReviseData}
            data-selenium-id='SIP_ReviewPage-EBM'
          />
        );
    }
    return <></>;
  };

  const getThankYouDetailText = () => {
    switch (type) {
      case 'ProposerRevise':
      case 'ProposerReviseFromJE':
        return (
          <>
            We are pleased to confirm that your revised proposal has been
            submitted successfully. We appreciate you considering Wiley to edit
            the Special Issue and will get back to you soon. In case you have
            any questions regarding the proposal, please contact{' '}
            <a href='mailto:specialcontent@wiley.com'>
              specialcontent@wiley.com
            </a>{' '}
            with SIP Code and SIP Title in subject line. Thank you.
          </>
        );
      case 'EditorReview':
        return (
          <>
            We are pleased to confirm that your decision has been submitted
            successfully. We appreciate your efforts and will get back to you
            soon. In case you have any questions regarding the proposal, please
            contact{' '}
            <a href='mailto:specialcontent@wiley.com'>
              specialcontent@wiley.com
            </a>{' '}
            with SIP Code and SIP Title in subject line. Thank you.
          </>
        );
      case 'JPMReview':
        return (
          <>
            We are pleased to confirm that your comments on this Special Issue
            proposal have been submitted successfully.
          </>
        );
    }
    return <></>;
  };

  const renderExpiredPanel = () =>
    (<Box sx={{
      marginTop: '56px',
      paddingX: '32px',
      paddingTop: '24px',
      height: 'calc(100vh - 56px - 68px)',
      boxSizing: 'border-box',
      '&>:first-child': {
        paddingTop: '72px',
        '&>:first-child': {
          paddingBottom: '32px',
        },
        '&>:nth-child(2)': {
          paddingBottom: '22px',
        },
      }
    }}>
      <ExpiredPage
          subTitle={"This may be because the revision deadline has expired or the \n revision is no longer needed."}
      />
      <Stack sx={{
        // modern browsers only allow scripts to close windows that were opened by them using window.open().
        // This page is opened by link, so it cannot be closed by script.
        // currently, I just hide the button, but it should be removed in the future.
        // display: 'flex',
        display: 'none',
        width: '100%',
        alignItems: 'center',
      }}>
        <Button
            onClick={() => {
              window.close();
            }}
            sx={{
              display: 'flex',
              width: '400px',
              height: '40px',
              padding: '8px 12px',
              justifyContent: 'center',
              alignSelf: 'center',
              gap: '6px',
              borderRadius: '5px',
              background: 'var(--Primary-500-, #0052CC)',

              color: 'var(--Shades-White, var(--primitives-colors-greys-white, #FFF))',
              textAlign: 'center',
              fontFamily: "Open Sans",
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '18px',
              '&:hover': {
                background: 'var(--Primary-600-, #0747A6)',
              }
            }}>CLOSE THIS PAGE</Button>
      </Stack>
    </Box>);


  return (
    sipReviseData?.sipCode?.split('-')[0]==='SSIP'?
    <>
      <Box data-selenium-id='SSIP_RevisePage-GetThankYouDetailText-Box'>
        <SIPRevisePageHeader
          sx={{
            zIndex: 1301,
            '& .MuiToolbar-root': {
              paddingX: sipSubmited ? '128px' : '32px',
              transition: 'all 0.5s',
            },
          }}
        />
        {loadingData ? (
          // {false ? (
          <Stack
            data-selenium-id='SSIP_RevisePage-GetThankYouDetailText-Stack1'
            alignItems={'center'}
            justifyContent='center'
            marginTop={'56px'}
            minHeight={'calc(100vh - 56px - 71.2px)'}
          >
            <CircularProgress
              disableShrink
              data-selenium-id='SSIP_RevisePage-GetThankYouDetailText-CircularProgress'
            />
          </Stack>
        ) : // ) : false ? (
        isShevled ? (
          <ProposalShelvedPage theme='blue' />
        ) : isInitialReviewRevisionCancelled ? (
          <InitialReviewRevisionCancelPage />
        ) : otherErrorMessage ? (
            renderExpiredPanel()
        ) : !sipSubmited ? (
          getRevisePage()
        ) : (
          <>
            <ThankYouPage
              showCreateProposalButton={
                type === 'ProposerRevise' || type === 'ProposerReviseFromJE'
              }
              handleCreateProposal={() => {}}
              detailText={getThankYouDetailText()}
              theme='blue'
              isSSIP={true}
            />
          </>
        )}
        <Footer
          sx={{ borderColor: '#1e48b4' }}
          data-selenium-id='SSIP_RevisePage-GetThankYouDetailText-Footer'
        />
        {/* 由于在路由表最外层，所以这里需要一个独立的 CommonSnackBar */}
        <CommonSnackBar />
      </Box>
    </>:
    <>
      <Box data-selenium-id='SIP_RevisePage-GetThankYouDetailText-Box'>
        <SIPRevisePageHeader
          sx={{
            zIndex: 1301,
            '& .MuiToolbar-root': {
              paddingX: sipSubmited ? '128px' : '32px',
              transition: 'all 0.5s',
            },
          }}
        />
        {loadingData ? (
          // {false ? (
          <Stack
            data-selenium-id='SIP_RevisePage-GetThankYouDetailText-Stack1'
            alignItems={'center'}
            justifyContent='center'
            marginTop={'56px'}
            minHeight={'calc(100vh - 56px - 71.2px)'}
          >
            <CircularProgress
              disableShrink
              data-selenium-id='SIP_RevisePage-GetThankYouDetailText-CircularProgress'
            />
          </Stack>
        ) : // ) : false ? (
        isShevled ? (
          <ProposalShelvedPage theme='blue' />
        ) : isInitialReviewRevisionCancelled ? (
          <InitialReviewRevisionCancelPage/>
        ) : otherErrorMessage ? (
            renderExpiredPanel()
        ) : !sipSubmited ? (
          getRevisePage()
        ) : (
          <>
            <ThankYouPage
              showCreateProposalButton={
                type === 'ProposerRevise' || type === 'ProposerReviseFromJE'
              }
              handleCreateProposal={() => {}}
              detailText={getThankYouDetailText()}
              theme='blue'
              isSSIP={false}
            />
          </>
        )}
        <Footer
          sx={{ borderColor: '#1e48b4' }}
          data-selenium-id='SIP_RevisePage-GetThankYouDetailText-Footer'
        />
        {/* 由于在路由表最外层，所以这里需要一个独立的 CommonSnackBar */}
        <CommonSnackBar />
      </Box>
    </>
  );
};

export default RevisePage;

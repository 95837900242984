import {
  Table,
  TableContainer,
  TableRow,
  TableBody,
  Box,
  tableCellClasses,
  Paper,
  TableCell,
  Divider,
  TableHead,
  IconButton,
  Stack,
  CircularProgress,
  Link,
} from '@mui/material';
import { TableFooter2 } from '../../../../components/DataTable';
import { styled } from '@mui/system';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { CustomTooltipWithoutArrow } from '../../../../components/CustomTooltip';
import OptInEditIcon from '@/assets/OptInEditIcon.svg';
import OptInWithdrawIcon from '@/assets/OptInWithdrawIcon.svg';
import OptInApproveIcon from '@/assets/OptInApproveIcon.svg';
import OptInRejectIcon from '@/assets/OptInRejectIcon.svg';
import ViewMoreText from '../OptInTable/ViewMoreText';
import { handleLinkWithoutProtocol } from '@/utils/commonUtils';
import EditAndSubmitPopUp from './EditAndSubmitPopUp';
import ApprovePopUp from './ApprovePopUp';
import WithdrawPopUp from './WithdrawPopUp';
import RejectPopUp from './RejectPopUp';
import CATReasonDialog from '@/pages/CATJournal/Overview/CATTable/CATReasonDialog';
import ScrollBarY from "../../../../componentsphase2/ScrollBar/ScrollBarY";
import { contextHeightChanged } from "../../../../actions/CommonAction";
import * as React from "react";
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import {getUserInfo} from "@/actions/SIMT-User/User";
import {editOptIn} from "@/actions/SIMT-SI/CatJournal";
import {editJournalOptOut, getOptOutData} from "@/actions/SIMT-SI/CatJournal/OptOut";
import {PERMISSIONS} from "@/constant/permission";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E8F2',
    color: '#505050',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '0.01em',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '44px',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '24px',
  },
}));

const NewStyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F1F3F5',
    color: '#596A7C',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '0.08em',
    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '42px',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
}));

const NewStyledTableCellDown = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F1F3F5',
    color: '#596A7C',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    letterSpacing: '0.0756em', // it is 0.08em
    boxShadow: '0px -1px 0px 0px #BCC5CF inset',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '43px',
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
}));

const createRow = (
  id,
  label,
  minWidth,
  maxWidth,
  isOrder = true,
  colSpan = 1,
  rowSpan = 2,
  align = 'left'
) => {
  return { id, label, minWidth, maxWidth, isOrder, colSpan, rowSpan, align };
};

const tableHeads = [ //to show the content
  createRow('actions', 'ACTIONS', '170px', '170px', false),
  createRow('requestDate', 'REQUEST DATE', '162.87px', '162.87px', false),
  createRow('journalCode', 'JOURNAL CODE', '147.79px', '147.79px', false),
  createRow('journalName', 'JOURNAL NAME', '270px', '270px', false),
  createRow('developmentModel', 'DEVELOPMENT MODEL', '197.05px', '197.05px', false),
  createRow('requestUser', 'REQUESTED BY', '146.78px', '146.78px', false),
  createRow('handlingCe', 'SCA LEAD', '229px', '229px', false),
  createRow('scam', 'SCA MANAGER', '229px', '229px', false),
  createRow('activeJournal', 'ACTIVE JOURNAL', '165px', '165px', false),

  createRow('commission', 'COMMISSIONING MODEL', '185px', '185px', false, 1),
  createRow('basic', 'BASIC MANAGEMENT MODEL', '185px', '185px', false, 1),
  createRow('onHold', 'ON-HOLD', '185px', '185px', false, 1),
  createRow('total', 'TOTAL', '185px', '185px', false, 1),

  createRow('reason', 'REASON FOR OPT-OUT', '528px', '528px', false),
  createRow(
    'currentSIPipelineAndFutureSIPlan',
    'CURRENT SI PIPELINE AND FUTURE SI PLAN',
    '528px',
    '528px',
    false
  ),
  createRow('pdpFullName','PD Publisher', '179px','179px', false),
  createRow('pdmFullName','PD Manager', '179px','179px', false),
  createRow('pddFullName','PD Director', '179px','179px', false),
  createRow('pppFullName','PP Publisher', '179px','179px', false),
  createRow('ppmFullName','PP Manager', '179px','179px', false),
  createRow('ppdFullName','PP Director', '179px','179px', false),
  createRow('subjectGroup', 'SUBJECT GROUP', '229px', '229px', false),
  createRow('businessDivision', 'BUSINESS DIVISION', '229px', '229px', false),
  createRow('revenueModel', 'REVENUE MODEL', '229px', '229px', false),
  createRow(
    'editorialOfficeSystem',
    'EDITORIAL OFFICE SYSTEM',
    '229px',
    '229px',
    false
  ),
  createRow('ownershipStatus', 'OWNERSHIP', '229px', '229px', false),
  createRow('society', 'SOCIETY NAME', '229px', '229px', false),
];

const tableHeadsUp = [ // to show the header
  createRow('actions', 'ACTIONS', '170px', '170px', false),
  createRow('requestDate', 'REQUEST DATE', '162.87px', '162.87px', false),
  createRow('journalCode', 'JOURNAL CODE', '147.79px', '147.79px', false),
  createRow('journalName', 'JOURNAL NAME', '270px', '270px', false),
  createRow('developmentModel', 'DEVELOPMENT MODEL', '197.05px', '197.05px', false),
  createRow('requestUser', 'REQUESTED BY', '146.78px', '146.78px', false),
  createRow('handlingCe', 'SCA LEAD', '229px', '229px', false),
  createRow('scam', 'SCA MANAGER', '229px', '229px', false),
  createRow('activeJournal', 'ACTIVE JOURNAL', '165px', '165px', false),
  createRow(
    'journalIncluded',
    'JOURNAL INCLUDED',
    '740px',
    '740px',
    false,
    4,
    1
  ),

  createRow('reason', 'REASON FOR OPT-OUT', '528px', '528px', false),
  createRow(
    'currentSIPipelineAndFutureSIPlan',
    'CURRENT SI PIPELINE AND FUTURE SI PLAN',
    '528px',
    '528px',
    false
  ),
  createRow('pdpFullName','PD Publisher', '179px','179px', false),
  createRow('pdmFullName','PD Manager', '179px','179px', false),
  createRow('pddFullName','PD Director', '179px','179px', false),
  createRow('pppFullName','PP Publisher', '179px','179px', false),
  createRow('ppmFullName','PP Manager', '179px','179px', false),
  createRow('ppdFullName','PP Director', '179px','179px', false),
  createRow('subjectGroup', 'SUBJECT GROUP', '229px', '229px', false), 
  createRow('businessDivision', 'BUSINESS DIVISION', '229px', '229px', false),
  createRow('revenueModel', 'REVENUE MODEL', '229px', '229px', false),
  createRow(
    'editorialOfficeSystem',
    'EDITORIAL OFFICE SYSTEM',
    '229px',
    '229px',
    false
  ),
  createRow('ownershipStatus', 'OWNERSHIP', '229px', '229px', false),
  createRow('society', 'SOCIETY NAME', '229px', '229px', false),
];

const tableHeadsDown = [
  createRow('commission', 'COMMISSIONING MODEL', '185px', '185px', false, 1),
  createRow('basic', 'BASIC MANAGEMENT MODEL', '185px', '185px', false, 1),
  createRow('onHold', 'ON-HOLD', '185px', '185px', false, 1),
  createRow('total', 'TOTAL', '185px', '185px', false, 1),
];

function OptOutTable(props) {
  //从父组件获取的属性
  const { id } = props;
  const userPermissions = localStorage.getItem('userPermissions');

  const [openApprovePopUp, setOpenApprovePopUp] = useState(false);
  const [openRejectPopUp, setOpenRejectPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [withdrawOpen, setWithdrawOpen] = useState(false);
  const [withdrawJournalCode, setWithdrawJournalCode] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [editOptOutId, setEditOptOutId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [approveJournalCode, setApproveJournalCode] = useState('');
  const [approveHandlingCe, setApproveHandlingCe] = useState(null);
  const [rejectJournalCode, setRejectJournalCode] = useState('');
  const [editJournalCode, setEditJournalCode] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  const [textValue1, setTextValue1] = useState('');
  const [textValue2, setTextValue2] = useState('');
  const [currentTarget, setCurrentTarget] = useState(null);
  const [commentsOpen1, setCommentsOpen1] = useState(false);
  const [commentsOpen2, setCommentsOpen2] = useState(false);

  const dispatch = useDispatch();

  const selector = state => {
    return {
      page: state.CATJournal.optOutPage,
      pageSize: state.CATJournal.optOutPageSize,
      optOutData: state.CATJournal.optOutData,
      email: state.User.email,
      siderOpen: state.UserManage.siderOpen,
    };
  };

  const { page, pageSize, optOutData, email, siderOpen } = useSelector(selector);

  const containerRef = useRef(null);
  const containerBoxRef = useRef(null);
  const scrollYRef = useRef(null);

  const [comments2Title,setComments2Title] = useState('');

  useEffect(() => {
    const containerElement = containerRef.current;
    const containerBoxElement = containerBoxRef.current;
    setTimeout(() => {
      dispatch(
        contextHeightChanged({
          contextWidth: containerElement.offsetWidth,
          contextHeight: containerElement.offsetHeight,
          boxWidth: containerBoxElement.offsetWidth,
          boxHeight: containerBoxElement.offsetHeight,
        })
      );
    }, 100);
  }, [optOutData]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      await dispatch(getOptOutData(page, pageSize));
      setIsLoading(false);
      await dispatch(getUserInfo());
    }
    fetchData();
  }, [page, pageSize]);

  const handlePerPageChange = e => {
    dispatch({ type: 'CHANGE_OPT_OUT_PAGE_SIZE', data: e.target.value });
  };

  const handlePageChange = (e, newPage) => {
    dispatch({ type: 'CHANGE_OPT_OUT_PAGE', data: newPage });
  };

  const handleReasonUpdate = (newValue) => {
    const {
      optOutId,
      journalCode,
    } = currentTarget;

    dispatch(
      editJournalOptOut(optOutId, journalCode, newValue, page, pageSize)
    );
  };

  const trimAndCheck = fullName => {
    if (fullName) {
      fullName = fullName.trim();
      if (fullName === ',') {
        return '';
      }
    }
    return fullName;
  };

  const getCorrespondingCell = (row, item, index) => {
    const fireCommentsOpen1 = (comments) => {
      setCurrentTarget(row);
      setCommentsOpen1(comments);
    };
    if (!row.requestUser) return (<></>);
    switch (item.id) {
      case 'actions':
        return (
          <StyledTableCell
            sx={{
              minWidth: item.minWidth,
              maxWidth: item.maxWidth,
              wordWrap: 'break-word',
              color: '#262E35',
              paddingX: '24px',
              paddingY: '9px',
            }}
            key={index}
            align={item.align}
            data-selenium-id={`CATJournal_Overview-OptOutTable-ActionsStyledTableCell-${row.journalCode}-${index}`}
          >
            <Stack direction='row' spacing={0}>
              <ShowForPermission
                permission={`${PERMISSIONS.OPT_OUT}`}>
                {email === row.requestUser.email ? (
                  <CustomTooltipWithoutArrow arrow title='Withdraw' placement='top'>
                    <IconButton
                      disableRipple
                      sx={{
                        p: '4px',
                        marginRight: '6px',
                        borderRadius: '2px',
                        background: '#F6F7F8',
                        '&:hover': {
                          background: '#DFE4E8',
                        },
                      }}
                      onClick={() => {
                        setWithdrawOpen(true);
                        setWithdrawJournalCode(row.journalCode);
                      }}
                    >
                      <OptInWithdrawIcon />
                    </IconButton>
                  </CustomTooltipWithoutArrow>
                ) : (
                  null
                )}
              </ShowForPermission>

              <ShowForPermission
                permission={`${PERMISSIONS.OPT_OUT}`}>
                {email === row.requestUser.email ? (
                  <CustomTooltipWithoutArrow arrow title='Edit' placement='top'>
                    <IconButton
                      disableRipple
                      sx={{
                        p: '4px',
                        marginRight: '6px',
                        borderRadius: '2px',
                        background: '#F6F7F8',
                        '&:hover': {
                          background: '#DFE4E8',
                        },
                      }}
                      onClick={() => {
                        setEditOpen(true);
                        setEditData(row);
                        setEditOptOutId(row.optOutId);
                        setEditJournalCode(row.journalCode);
                      }}
                    >
                      <OptInEditIcon />
                    </IconButton>
                  </CustomTooltipWithoutArrow>
                ) : (
                  null
                )}
              </ShowForPermission>

              {userPermissions && userPermissions.includes(PERMISSIONS.OPT_APPROVAL) ? (
                <CustomTooltipWithoutArrow arrow title='Approve' placement='top'>
                  <IconButton
                    disableRipple
                    sx={{
                      p: '4px',
                      marginRight: '6px',
                      borderRadius: '2px',
                      zIndex: 1,
                      background: '#F6F7F8',
                      '&:hover': {
                        background: '#DFE4E8',
                      },
                    }}
                  >
                    <OptInApproveIcon
                      onClick={() => {
                        setApproveJournalCode(row.journalCode);
                        setApproveHandlingCe(
                          row.handlingCe?.fullName +
                          ' (' +
                          row.handlingCe?.email +
                          ')'
                        );
                        setOpenApprovePopUp(true);
                      }}
                    />
                  </IconButton>
                </CustomTooltipWithoutArrow>
              ) : (
                null
              )}

              {userPermissions && userPermissions.includes(PERMISSIONS.OPT_APPROVAL) ? (
                <CustomTooltipWithoutArrow arrow title='Reject' placement='top'>
                  <IconButton
                    didableRipple
                    sx={{
                      p: '4px',
                      borderRadius: '2px',
                      color: '#FAFAFA',
                      background: '#F6F7F8',
                      '&:hover': {
                        background: '#DFE4E8',
                      },
                    }}

                  >
                    <OptInRejectIcon
                      onClick={() => {
                        setRejectJournalCode(row.journalCode);
                        setOpenRejectPopUp(true);
                      }} />
                  </IconButton>
                </CustomTooltipWithoutArrow>
              ) : (
                null
              )}
            </Stack>
          </StyledTableCell>
        );

      case 'requestUser':
        if (row.requestUser?.email) {
          return (
            <StyledTableCell
              sx={{
                minWidth: item.minWidth,
                maxWidth: item.maxWidth,
                wordWrap: 'break-word',
                color: '#262E35',
                paddingX: '24px',
              }}
              data-selenium-id={`CATJournal_Overview-OptOutTable-RequestUserStyledTableCell1-${row.journalCode}-${index}`}
              key={index}
              align={item.align}
            >
              <CustomTooltipWithoutArrow
                // title={row.requestUser?.email}
                placement='top'
              >
                <Box>{row.requestUser?.fullName}</Box>
              </CustomTooltipWithoutArrow>
            </StyledTableCell>
          );
        } else {
          return (
            <StyledTableCell
              sx={{
                minWidth: item.minWidth,
                maxWidth: item.maxWidth,
                wordWrap: 'break-word',
                color: '#262E35',
                paddingX: '24px',
              }}
              data-selenium-id={`CATJournal_Overview-OptOutTable-RequestUserStyledTableCell2-${row.journalCode}-${index}`}
              key={index}
              align={item.align}
            >
              {row.requestUser?.fullName}
            </StyledTableCell>
          );
        }

      case 'handlingCe':
        if (row.handlingCe?.email) {
          return (
            <StyledTableCell
              sx={{
                minWidth: item.minWidth,
                maxWidth: item.maxWidth,
                wordWrap: 'break-word',
                color: '#262E35',
                paddingX: '24px',
              }}
              key={index}
              align={item.align}
              data-selenium-id={`CATJournal_Overview-OptOutTable-HandlingCeStyledTableCell-${row.journalCode}-${index}`}
            >
              <CustomTooltipWithoutArrow
                // title={row.handlingCe?.email}
                placement='top'
              >
                <Box>{row.handlingCe?.fullName}</Box>
              </CustomTooltipWithoutArrow>
            </StyledTableCell>
          );
        } else {
          return (
            <StyledTableCell
              sx={{
                minWidth: item.minWidth,
                maxWidth: item.maxWidth,
                wordWrap: 'break-word',
                color: '#262E35',
                paddingX: '24px',
              }}
              data-selenium-id={`CATJournal_Overview-OptOutTable-HandlingCeStyledTableCell2-${row.journalCode}-${index}`}
              key={index}
              align={item.align}
            >
              {row.handlingCe?.fullName}
            </StyledTableCell>
          );
        }
        case 'scam':
          if (row.scam?.email) {
            return (
              <StyledTableCell
                sx={{
                  minWidth: item.minWidth,
                  maxWidth: item.maxWidth,
                  wordWrap: 'break-word',
                  color: '#262E35',
                  paddingX: '24px',
                }}
                key={index}
                align={item.align}
                data-selenium-id={`CATJournal_Overview-OptOutTable-SCAMStyledTableCell-${row.journalCode}-${index}`}
              >
                <CustomTooltipWithoutArrow
                  title={row.scam?.email}
                  placement='top'
                >
                  <Box>{row.scam?.fullName}</Box>
                </CustomTooltipWithoutArrow>
              </StyledTableCell>
            );
          } else {
            return (
              <StyledTableCell
                sx={{
                  minWidth: item.minWidth,
                  maxWidth: item.maxWidth,
                  wordWrap: 'break-word',
                  color: '#262E35',
                  paddingX: '24px',
                }}
                data-selenium-id={`CATJournal_Overview-OptOutTable-SCAMStyledTableCell2-${row.journalCode}-${index}`}
                key={index}
                align={item.align}
              >
                {row.scam?.fullName}
              </StyledTableCell>
            );
          }
      case 'reason':
        return (
          <StyledTableCell
            sx={{
              minWidth: item.minWidth,
              maxWidth: item.maxWidth,
              wordWrap: 'break-word',
              color: '#262E35',
              paddingX: '24px',
            }}
            key={index}
            align={item.align}
            data-selenium-id={`CATJournal_Overview-OptOutTable-ReasonStyledTableCell-${row.journalCode}-${index}`}
          >
            <ViewMoreText
              lineNum={1}
              id={`optOut-${item.id}-${index}`}
              monitor={optOutData}
              setCommentsOpen={fireCommentsOpen1}
              setTextValue={setTextValue1}
              text={row[item.id]}
            >
              {row[item.id]}
            </ViewMoreText>
          </StyledTableCell>
        );

      case 'editorialOfficeSystem':
        return (
          <StyledTableCell
            sx={{
              minWidth: item.minWidth,
              maxWidth: item.maxWidth,
              wordWrap: 'break-word',
              color: '#262E35',
              paddingX: '24px',
            }}
            key={index}
            align={item.align}
            data-selenium-id={`CATJournal_Overview-OptOutTable-EditorialOfficeSystemStyledTableCell-${row.journalCode}-${index}`}
          >
            {row[item.id]}
          </StyledTableCell>
        );
      case 'editorialDirectorFullName':
        return (
          <StyledTableCell
            sx={{
              minWidth: item.minWidth,
              maxWidth: item.maxWidth,
              wordWrap: 'break-word',
              color: '#262E35',
              paddingX: '24px',
            }}
            key={index}
            align={item.align}
            data-selenium-id={`CATJournal_Overview-OptOutTable-EditorialDirectorFullNameStyledTableCell-${row.journalCode}-${index}`}
          >
            {trimAndCheck(row[item.id])}
          </StyledTableCell>
        );
      default:
        return (
          <StyledTableCell
            sx={{
              minWidth: item.minWidth,
              maxWidth: item.maxWidth,
              wordWrap: 'break-word',
              color: '#262E35',
              paddingX: '24px',
            }}
            key={index}
            align={item.align}
            data-selenium-id={`CATJournal_Overview-OptOutTable-DefaultStyledTableCell-${row.journalCode}-${index}`}
          >
            <ViewMoreText
              lineNum={2}
              id={`optOut-${item.id}-${index}`}
              monitor={optOutData}
              setCommentsOpen={
                (isOpen) => {
                  setCommentsOpen2(isOpen);
                  setComments2Title(item.label);
                }
              }
              setTextValue={setTextValue2}
              text={row[item.id]}
            >
              {row[item.id]}
            </ViewMoreText>
          </StyledTableCell>
        );
    }
  };

  return (
    <Box
      sx={{ width: '100%' }}
      data-selenium-id='CATJournal_Overview-OptOutTable-Box'
    >
      <CATReasonDialog
        value={textValue1}
        setValue={setTextValue1}
        commentsOpen={commentsOpen1}
        setCommentsOpen={setCommentsOpen1}
        title='Opt-out Reason'
        editable={true}
        onSubmit={(newReason) => handleReasonUpdate(newReason)}
        target={currentTarget}
      />
      <CATReasonDialog
        value={textValue2}
        setValue={setTextValue2}
        commentsOpen={commentsOpen2}
        setCommentsOpen={setCommentsOpen2}
        title={comments2Title}
      />
      <div
        style={{
          overflowX: 'hidden',
          overflowY: 'hidden',
          position: 'relative',
          width: 'fit-content',
        }}
      >
        <TableContainer
          ref={containerBoxRef}
          id={id}
          data-selenium-id={id}
          sx={{
            borderRadius: '8px 8px 0px 0px',
            borderWidth: '1px 1px 0px 1px',
            borderStyle: 'solid',
            borderColor: '#DFE4E8',
            height: 'calc(100vh - 385px)',
            width: siderOpen ? 'calc(100vw - 129px - 158px)' : 'calc(100vw - 129px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar:vertical': {
              width: 0,
            },
            '&::-webkit-scrollbar-thumb:vertical': {
              backgroundColor: 'transparent',
            },
          }}
          onScroll={scrollYRef.current?.scrollAsContext}
        >
          <Table
            ref={containerRef}
            stickyHeader
            aria-label='custom pagination table'
            sx={{
              minWidth: 500,
              // minHeight: '20vw', // Set the minimum height to 100% of the viewport height
              letterSpacing: '0.01em',
              color: '#262E35',
            }}
          >
            <TableHead data-selenium-id='CATJournal_Overview-OptOutTable-Head'>
              <TableRow data-selenium-id='CATJournal_Overview-OptOutTable-TableRow1'>
                {tableHeadsUp.map((item, index) => (
                  <NewStyledTableCell
                    key={item.id}
                    align={item.align}
                    sx={{
                      maxWidth: item.maxWidth,
                      minWidth: item.minWidth,
                    }}
                    style={{
                      position: item.stick ? 'sticky' : '',
                      left: item.stick ? item.stickPX : '',
                      zIndex: item.stick ? 6 : '',
                    }}
                    rowSpan={item.rowSpan}
                    colSpan={item.colSpan}
                  >
                    <Box
                      id={`myTableHeaderItem${index}`}
                      data-selenium-id={`CATJournal_Overview-OptOutTable-TableHeaderItem${index}`}
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      {item.label}
                    </Box>
                  </NewStyledTableCell>
                ))}
              </TableRow>
              <TableRow data-selenium-id='CATJournal_Overview-OptOutTable-TableRow2'>
                {tableHeadsDown.map((item, index) => (
                  <NewStyledTableCellDown
                    key={item.id}
                    align={item.align}
                    sx={{
                      maxWidth: item.maxWidth,
                      minWidth: item.minWidth,
                      top: '42px',
                      position: item.stick ? 'sticky' : '',
                      left: item.stick ? item.stickPX : '',
                      zIndex: item.stick ? 6 : '',
                    }}
                    rowSpan={item.rowSpan}
                    colSpan={item.colSpan}
                  >
                    <Box
                      id={`myTableHeaderItem${index}`}
                      data-selenium-id={`CATJournal_Overview-OptOutTable-TableHeaderItem${index}`}
                    >
                      {item.label}
                    </Box>
                  </NewStyledTableCellDown>
                ))}
              </TableRow>
            </TableHead>
            <TableBody data-selenium-id='CATJournal_Overview-OptOutTable-Body'>
              {isLoading ? (
                <TableRow
                  id='catJournalOptOutTableNoDataText'
                  data-selenium-id='CATJournal_Overview-OptOutTable-IsLoadingNoDataText'
                  style={{ height: '300px' }}
                >
                  <TableCell
                    colSpan={6}
                    sx={{ textAlign: 'center', fontSize: '20px' }}
                  >
                    <CircularProgress size={80} />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {optOutData.result.map((row, index) => (
                    <TableRow key={index} sx={{
                      zIndex: 2,
                      '&:hover': {
                        background: '#E8EDFB',
                      }
                    }}>
                      {tableHeads.map((item, index) =>
                        getCorrespondingCell(row, item, index)
                      )}
                    </TableRow>
                  ))}

                  {optOutData.result.length <= 0 ? (
                    <TableRow
                      id='catJournalOptOutTableNoDataText'
                      data-selenium-id='CATJournal_Overview-OptOutTable-NoDataText'
                      style={{ height: '300px' }}
                    >
                      <TableCell
                        colSpan={6}
                        sx={{ textAlign: 'center', fontSize: '20px' }}
                      >
                        No Data
                      </TableCell>
                    </TableRow>
                  ) : (
                    <div></div>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <ScrollBarY ref={scrollYRef} contextHolderRef={containerBoxRef} />
      </div>
      <Box data-selenium-id='CATJournal_Overview-OptOutTable-Box-Box'>
        {/* <Divider /> */}
        <TableFooter2
          Page={page}
          perPageNumber={pageSize}
          perPageList={[20, 50, 100]}
          count={optOutData.count ?? 0}
          handlePerPageChange={handlePerPageChange}
          handlePageChange={handlePageChange}
          backgroundColor='#f1f3f5'
          data-selenium-id='CATJournal_Overview-OptOutTable-Box-TableFooter2'
          width="calc(100% + 3px)"
        />
      </Box>

      <EditAndSubmitPopUp
        open={editOpen}
        setOpen={val => {
          setEditOpen(val);
          if (!val){
            setEditData(null);
            setEditOptOutId(null);
            setEditJournalCode(null);
          }
        }}
        type='edit'
        editId={editOptOutId}
        rowData={editData}
        page={page}
        pageSize={pageSize}
        data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp'
      />
      <WithdrawPopUp
        open={withdrawOpen}
        setOpen={setWithdrawOpen}
        journalCode={withdrawJournalCode}
        page={page}
        pageSize={pageSize}
        data-selenium-id='CATJournal_Overview-OptOutTable-WithdrawPopUp'
      />

      <ApprovePopUp
        open={openApprovePopUp}
        setOpen={setOpenApprovePopUp}
        journalCode={approveJournalCode}
        page={page}
        pageSize={pageSize}
        data-selenium-id='CATJournal_Overview-OptOutTable-ApprovePopUp'
      />

      <RejectPopUp
        open={openRejectPopUp}
        setOpen={setOpenRejectPopUp}
        journalCode={rejectJournalCode}
        page={page}
        pageSize={pageSize}
        data-selenium-id='CATJournal_Overview-OptOutTable-RejectPopUp'
      />
    </Box>
  );
}
export default OptOutTable;

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { ApprovalConfirm } from './WaitingForSnackBar';

export function ApprovalDialog(props) {
  const {
    propOpen,
    setOpenApproval,
    reportName,
    selectMonth,
    handleApprovalDialogPop,
  } = props;
  const [ApprovalSnackBarOpen, setApprovalSnackBarOpen] = useState(false);

  const handleClose = () => {
    setOpenApproval(false);
  };

  return (
    <>
      <Dialog
        id='waitingForApproveRejectDialog'
        data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForApprovalDialog-ApproveRejectDialog'
        maxWidth={false}
        open={propOpen}
        onClose={handleClose}
      >
        <DialogTitle
          style={{
            width: '552px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '24px',
            lineHeight: '36px',
          }}
          id='draggable-dialog-title'
          data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForApprovalDialog-ApproveRejectDialog-DialogTitle'
        >
          Approve Request
        </DialogTitle>
        <DialogContent data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForApprovalDialog-ApproveRejectDialog-DialogContent'>
          <DialogContentText
            id={`systemAdminReportJobsWaitingForApprovalDialogContentText`}
            data-selenium-id={`SystemAdmin-ReportJobsWaitingForApproval-DialogContentText`}
            style={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            Are you sure to approve the report job?
          </DialogContentText>
        </DialogContent>
        <DialogActions data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForApprovalDialog-ApproveRejectDialog-DialogActions'>
          <Button
            autoFocus
            onClick={handleClose}
            id='systemAdminReportJobsWaitingForApprovalDialogCancelButton'
            data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForApprovalDialog-ApproveRejectDialog-DialogActions-DialogCancelButton'
            style={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '20px',
              letterSpacing: '1px',
              color: '#007EB6',
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              handleApprovalDialogPop('', reportName, selectMonth, 'Approve');
            }}
            id='systemAdminReportJobsWaitingForApprovalDialogRejectButton'
            data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForApprovalDialog-ApproveRejectDialog-DialogActions-DialogRejectButton'
            style={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '20px',
              letterSpacing: '1px',
              color: '#007EB6',
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <ApprovalConfirm
        data-selenium-id='SystemAdmin_ReportJobs-WaitingForApproval-WaitingForApprovalDialog-ApprovalConfirm'
        open={ApprovalSnackBarOpen}
        setOpen={setApprovalSnackBarOpen}
      />
    </>
  );
}

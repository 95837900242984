import {
  Autocomplete,
  createTheme,
  IconButton,
  styled,
  TextField,
  ThemeProvider,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { testInputFont } from '../../../../components/FontStyle';
import SIPDetailGEInfomationEditChevronDown from '@/assets/SIPDetailGEInfomationEditChevronDown.svg';
import { useDispatch } from 'react-redux';

const theme = createTheme({
  palette: {
    error: {
      main: '#FC5555',
    },
  },
});

export const OptInInputBase = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    borderRadius: '4px',
    boxSizing: 'border-box',
    padding: '8px 12px',
    height: '40px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '0px 0px',
    ...testInputFont,
    color: '#505050',
    height: '17px',
    '&::placeholder': {
      ...testInputFont, //lineheight 不满足ui 要求
      color: '#C5C4C4',
      // lineHeight: '120%',
      opacity: '1',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #D8D9DA',
  },
});

export default function SelectBoxWithSearch(props) {
  const {
    id,
    loadDropList,
    needEmptyCheck = false,
    errorMonitor = false,
    value = '',
    secondParameter = null,
    placeholder = '',
    inputSX = {},
    attribute = null, //因为value可能是一个object， 而我们的大部分操作都是字符串，如果value是一个obj的话，那么就是处理value的某个attribute
    ...other
  } = props;

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(null);
  const [inputText, setInputText] = useState('');

  const valueIsObj = attribute === null ? false : true;

  const getInputValue = () => {
    if (valueIsObj) {
      return value ? value[attribute] : '';
    } else {
      return value;
    }
  };

  useEffect(() => {
    if (loadDropList !== undefined) {
      setLoading(true);
      clearTimeout(timer);
      setTimer(
        setTimeout(async () => {
          async function loadOptions() {
            if (secondParameter === null) {
              await dispatch(loadDropList(inputText));
            } else {
              await dispatch(loadDropList(inputText, secondParameter));
            }
          }
          loadOptions().then(_r => {
            setLoading(false);
          });
        }, 300)
      );
    }
  }, [inputText]);

  useEffect(() => {
    if (!open || loadDropList === undefined) {
      return;
    }
    async function loadOptions() {
      if (secondParameter === null) {
        await dispatch(loadDropList(inputText));
      } else {
        await dispatch(loadDropList(inputText, secondParameter));
      }
    }

    loadOptions().then(_r => {
      setLoading(false);
    });
  }, [open]);

  useEffect(() => {
    setInputText(getInputValue());
  }, [value]);

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        id={'autocomplete-' + id}
        data-selenium-id={'autocomplete-' + id}
        value={value}
        ListboxProps={{
          sx: {
            pt: "8px",
            pb: "8px",
            marginRight: '3px',
            maxHeight: '205px',
            '& li:hover': {
              backgroundColor: '#F1F3F5 !important',
              '& div': {
                color: '#262E35 !important',
              }
            },
          },
        }}
        componentsProps={{
          paper: {
            style: {
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
              borderRadius: '5px',
              marginTop: '6px',
              marginBottom: '6px'
            },
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '0px',
            color: '#262E35',
            fontSize: '14px',
            lineHeight: '17.5px',
          },
          '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
            right: '12px',
          },
          '& .MuiTouchRipple-root': {
            display: 'none',
          },
          '& .MuiAutocomplete-popupIndicator:hover': {
            background: '#FFFFFF'
          },
        }}
        disableClearable
        filterOptions={x => x} //去掉组件默认的模糊查询
        popupIcon={
          <IconButton
            disableRipple
            sx={{ padding: '0px' }}
            data-selenium-id='CATJournal_Overview-BatchUpdatePop-SelectBoxWithSearch-IconButton'
          >
            <SIPDetailGEInfomationEditChevronDown />
          </IconButton>
        }
        renderInput={params => (
          <OptInInputBase
            {...params}
            onChange={e => {
              setInputText(e.target.value);
            }}
            placeholder={placeholder}
            sx={{
              ...inputSX
            }}
            error={
              (errorMonitor && needEmptyCheck && getInputValue() === '') ||
              props.error
            }
            data-selenium-id='CATJournal_Overview-BatchUpdatePop-SelectBoxWithSearch-OptInInputBase'
          />
        )}
        loading={loading}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onFocus={() => {
          setInputText(getInputValue());
        }}
        {...other}
      />
    </ThemeProvider>
  );
}

import {axios_instance, axios_instance_fileUpload} from "@/utils/axios_instance";
import {DELETE_ATTACHMENT, UPLOAD_ATTACHMENT} from "@/actions/SIMT-Email/EmailAction";
import getAuth from "@/utils/getAuth";

export function UploadSIPAttachment(attachment, fileIdentifier) {
    return async dispatch => {
        try {
            // let formData = new FormData();
            // formData.append("file", attachment);
            const url = '/api/v1/mail-service/emails/attachments';
            const res = await axios_instance_fileUpload.post(url, attachment, {
                headers: {
                    authorization: getAuth(),
                },
            });

            if (res.data.code === 200) {
                dispatch({
                    type: UPLOAD_ATTACHMENT,
                    data: {
                        state: 'success',
                        fileIdentifier: fileIdentifier,
                        fileID: res.data.data,
                    },
                });
            } else if (res.data.code === 14105) {
                dispatch({
                    type: UPLOAD_ATTACHMENT,
                    data: {state: 'emptyError', fileIdentifier: fileIdentifier},
                });
            } else {
                dispatch({
                    type: UPLOAD_ATTACHMENT,
                    data: {state: 'error', fileIdentifier: fileIdentifier},
                });
            }
        } catch (err) {
            dispatch({
                type: UPLOAD_ATTACHMENT,
                data: {state: 'error', fileIdentifier: fileIdentifier},
            });
        }
    };
}

/**
 * @param {number} id
 */
export function DeleteSIPAttachment(id) {
  /**
   * @param {import("redux").Dispatch} dispatch
   */
    return async dispatch => {
        try {
            const url = '/api/v1/mail-service/emails/attachments';
            const res = await axios_instance.delete(url, {
                params: {
                    id: id,
                },
                headers: {
                    authorization: getAuth()??'',
                },
            });

            if (res.data.code === 200) {
                dispatch({type: DELETE_ATTACHMENT, data: res.data.data});
            } else {
                dispatch({type: DELETE_ATTACHMENT, data: 'error'});
            }
        } catch (err) {
            dispatch({type: DELETE_ATTACHMENT, data: 'error'});
        }
    };
}
import { Box } from '@mui/system';
import { Stack } from '@mui/material';
import { MiniFont } from '../../../../components/FontStyle';
import { CustomTooltip } from '../../../../components/CustomTooltip';
import LabelEditIcon from '@/assets/LabelEditIcon.svg';
import LabelDeleteIcon from '@/assets/LabelDeleteIcon.svg';
import LabelDeleteHoverIcon from '@/assets/LabelDeleteHoverIcon.svg';
import { useState } from 'react';

export function AssigneeLitterBox(props) {
  const {
    id,
    assignee = {
      firstName: '',
      lastName: '',
      email: '',
    },
    handleEdit = () => {
      
    },
    handleDelete = () => {
      
    },
  } = props;

  const [deleteHover, setDeleteHover] = useState(false);

  return (
    <Box
      data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeLitter-Box'
      sx={{
        display: 'inline-block',
        height: '36px',
        border: '1px solid #BCC5CF',
        borderRadius: '4px',
        padding: '8px 12px 8px 12px',
        boxSizing: 'border-box',
        backgroundColor: '#FFFFFF',
        margin: '0px 12px 3px 0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        "&:hover": {
          borderRadius: '4px',
          border: '1px solid #98A7B6',
          background: '#DFE4E8'
        },

      }}
    >
      <Stack
        direction='row'
        spacing={1}
        data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeLitterBox-Stack'
        maxWidth= "228px"//254-26=228
      >
        {/* <CustomTooltip
          title={assignee.email}
          placement='top'
          data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeLitterBox-CustonTooltip'
        >

        </CustomTooltip> */}
        <CustomTooltip
          title={assignee.email}
          placement='top'
          data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeLitterBox-CustonTooltip'
        >
          <Box
            sx={{ ...MiniFont, color: '#333333', maxWidth: "254px", overflow: "hidden", textOverflow: 'ellipsis' }}
            data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeLitter-InnerBox'
          >
            {assignee.lastName}, {assignee.firstName}
          </Box>
        </CustomTooltip>
        <Box
          id={`${id}-edit`}
          data-selenium-id={`SIP_DetailPage_DecisionSection-AssigneeLitterBox-${id}-edit`}
          onClick={handleEdit}
          sx={{ 
            cursor: 'pointer', 
            "& svg": {
              float: "left",
              mt: '4px'
            }
          }}
        >
          <LabelEditIcon data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeLitterBox-Pencil'></LabelEditIcon>
        </Box>
        <Box
          id={`${id}-cancel`}
          data-selenium-id={`SIP_DetailPage_DecisionSection-AssigneeLitterBox-${id}-cancel`}
          onClick={handleDelete}
          onMouseEnter={() => {
            setDeleteHover(true);
          }}
          onMouseLeave={() => {
            setDeleteHover(false);
          }}
          sx={{ 
            cursor: 'pointer', 
            "& svg": {
              float: "left",
              mt: '3px'
            }
          }}
        >
          {
            deleteHover ?
              <LabelDeleteHoverIcon></LabelDeleteHoverIcon>
              :
              <LabelDeleteIcon data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeLitterBox-CancelIcon'></LabelDeleteIcon>
          }

        </Box>
      </Stack>
    </Box>
  );
}

import * as actions from '@/actions/SIMT-SIP/SIPFilter/SIPFilterAction';

const { filterUsed2SelectState } = actions;

const defaultUseFilters = {
    sipCode: [],
    sipTitle: [],
    handlingCe: [],
    operationsLead: [],
    screener: [],
    geName: [],
    geEmail: [],
    aimsAndScope: [],
    topics: [],
    keywords: [],

    stage: [],
    stageList: [],
    sipOrigin: [],
    sipChecklistStatus: [],
    catGroup: [],

    preferredSubjectArea: [],
    preferredJournal: [],
    region: [],

    leadGeFullName: [],
    coGeFullName: [],
    group: [],
    journalName: [],
    jpm: [],
    journalrevenueModel: [],
    SubjectGroup: [], // Format for Server (这是向后端发送请求的大小写格式)

    submissionFromDate: [],
    submissionToDate: [],
    decisionFromDate: [],
    decisionToDate: [],
};

export const initState = {
    // 1
    sipCode: [],
    sipTitle: [],
    handlingCe: [],
    operationsLead: [],
    screener: [],
    geName: [],
    geEmail: [],
    aimsAndScope: [],
    topics: [],
    keywords: [],
    // 2
    stage: [],
    sipOrigin: [],
    sipChecklistStatus: [],
    catGroup: [],
    // 3
    preferredSubjectArea: [],
    preferredJournal: [],
    region: [],
    // 4

    // 5
    searchSI: '', // search的从SI移动到filter里面了，为了实现搜索和filter先后使用的需求，期望未来全部整合到SI Reducer&Action里面
    firstSIPFilterSelected: null,
    sipFilterSelected: {
        sipCodeSelected: [],
        sipTitleSelected: [],
        handlingCeSelected: [],
        operationsLeadSelected: [],
        screenerSelected: [],
        geNameSelected: [],
        geEmailSelected: [],
        aimsAndScopeSelected: [],
        topicsSelected: [],
        keywordsSelected: [],

        stageSelected: [],
        sipOriginSelected: [],
        sipChecklistStatusSelected: [],
        catGroupSelected: [],

        preferredSubjectAreaSelected: [],
        preferredJournalSelected: [],
        regionSelected: [],
        geRegionSelected: [],

        timeSensitiveSelected: [],
        potentialAuthorsSelected: [],

        journalSelected: [],
        submissionFromDate: null,
        submissionToDate: null,
        decisionFromDate: null,
        decisionToDate: null,
        creationFromDate: null,
        creationToDate: null,
    },
    adaptSIPFilterSelected: null,
    favSIPFilterSelected: null,
    useFilters: null, // after applying filters
    hasFilter: false,
    acquiredDate: {
        acquiredFromDate: null,
        acquiredToDate: null,
    },
    deadlineDate: {
        deadlineFromDate: null,
        deadlineToDate: null,
    },
    changeList:false,
};

export default function SIPFilterReducer(state = initState, action) {
    const { type, data } = action;
    switch (type) {
        case actions.FIRST_LOAD_FAVOURITE: {
            if (data !== null) {
                // special with name (特殊处理name)
                const fav = data;
                return {
                    ...state,
                    ...fav,
                    firstSIPFilterSelected: fav['sipFilterSelected']
                };
            } else {
                return state;
            }
        }
        case actions.SET_ADAPT_SIPFILTERSELECTED: {
            return {
                ...state,
                adaptSIPFilterSelected: data,
            };
        }
        case actions.SET_FAV_SIPFILTERSELECTED: {
            return {
                ...state,
                favSIPFilterSelected: data,
            };
        }
        case actions.LOAD_DATA: {
            return {
                ...state,
                [data.which]: data.data.result,
            };
        }
        case actions.SET_SELECT: {
            const keyInSIPFilterSelected = data.which + 'Selected';
            if (state.sipFilterSelected.hasOwnProperty(keyInSIPFilterSelected)) {
                // 在sipFilter中，可以根据规范节省代码
                return {
                    ...state,
                    sipFilterSelected: {
                        ...state.sipFilterSelected,
                        [keyInSIPFilterSelected]: data.data,
                    },
                };
            }
            // set other properties (其他属性的set)
            switch (data.which) {
                case 'siWithChinaGE': {
                    return {
                        ...state,
                        siFilterSelected: {
                            ...state.siFilterSelected,
                            siWithChinaGESelected: data.data,
                        },
                    };
                }
                default:
                    return {
                        ...state,
                        [data.which + 'Selected']: data.data,
                    };
            }
        }
        case actions.SAVE_FAVOURITE:
            return {
                ...state,
            };
        case actions.LOAD_FAVOURITE: {
            if (data !== null) {
                // 特殊处理name
                const fav = filterUsed2SelectState(data);
                return {
                    ...state,
                    ...fav,
                };
            } else {
                return state;
            }
        }
        case actions.RESET_FILTER:
            return {
                ...state,
                sipFilterSelected: initState.sipFilterSelected,
            };
        case actions.RESET_ACQUIRED_DATE_FILTER:
            return {
                ...state,
                siFilterSelected: initState.acquiredDate,
            };
        case actions.RESET_DEADLINE_DATE_FILTER:
            return {
                ...state,
                siFilterSelected: initState.deadlineDate,
            };
        case actions.SEARCH_SI:
            return {
                ...state,
                searchSI: data,
            };
        case actions.CLEAR_USE_FILTER:
            return {
                ...state,
                useFilters: defaultUseFilters,
            };
        case actions.CHANGE_LIST:
            return {
                ...state,
                changeList:data,
            };
        case actions.SET_FILTER: {
            // if data is a string of 'default', then the filter will be set to default
            const filters = data === 'default' ? defaultUseFilters : data;
            return {
                ...state,
                useFilters: filters,
            };
        }
        case actions.SET_DATE: {
            const which = data.which;
            const dateType = data.type;
            const newDate = data.newDate;
            switch (which) {
                case 'submission':
                    return {
                        ...state,
                        sipFilterSelected: {
                            ...state.sipFilterSelected,
                            ...dateType === 'from' ? { submissionFromDate: newDate } : { submissionToDate: newDate, },
                        },
                    };
                case 'decision':
                    return {
                        ...state,
                        sipFilterSelected: {
                            ...state.sipFilterSelected,
                            ...dateType === 'from' ? { decisionFromDate: newDate } : { decisionToDate: newDate, },
                        },
                    };
                case 'creation':
                    return {
                        ...state,
                        sipFilterSelected: {
                            ...state.sipFilterSelected,
                            ...dateType === 'from' ? { creationFromDate: newDate } : { creationToDate: newDate, },
                        },
                    };
                default:
                    return state;
            }
        }
        default:
            return state;
    }
}

import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { ChartGroups } from '@/components/Chart/ChartGroups';
import { ChartList } from '@/components/Chart/ChartList';
import styles from './index.module.scss';
import { changeSiderOpen } from '@/actions/SIMT-User/User/UserManageAction';
import subjectGroupYTDImg from '@/assets/ReportDiagrams/A&A/Overview/YTD/SubjectGroup.png';
import businessDivisionYTDImg from '@/assets/ReportDiagrams/A&A/Overview/YTD/BusinessDivision.png';
import ceYTDImg from '@/assets/ReportDiagrams/A&A/Overview/YTD/CE.png';
import catGroupYTDImg from '@/assets/ReportDiagrams/A&A/Overview/YTD/CAT.png';

import subjectGroupAcqImg from '@/assets/ReportDiagrams/A&A/Overview/Acquisition/SubjectGroup.png';
import businessDivisionAcqImg from '@/assets/ReportDiagrams/A&A/Overview/Acquisition/BusinessDivision.png';
import ceAcqImg from '@/assets/ReportDiagrams/A&A/Overview/Acquisition/CE.png';
import catGroupAcqImg from '@/assets/ReportDiagrams/A&A/Overview/Acquisition/CAT.png';

import moment from 'moment';
import { MONTH } from './Constants/index.js';
import AAndAOverviewChart from './Diagrams/';

import {
  A_AND_A_SI_ACQUISITION_YTD_TARGET,
  A_AND_A_SI_ACQUISITION,
  SUBJECT_GROUP,
  BUSINESS_DIVISION,
  CE_LEVEL,
  CAT_GROUP_LEVEL,
} from './Constants/index.js';
import { POST } from '@/pages/Report/SIPipeline/SIPipelineDiagrams/constants';

const valueAttributeAllNames = [
  'againstYTDTarget',
  'againstYTDTargetOA',
  'acquiredYtd',
  'acquiredSi',
];

const DIAGRAM_SETTINGS = [
  // 1st group
  {
    groupName: A_AND_A_SI_ACQUISITION_YTD_TARGET,
    chartName: SUBJECT_GROUP,
    hasAdditionalFilter: true,
    hasSelectAllOption: true,
    catagoryAttributeName: 'subjectGroup',
    url: '/reports/acquired-active/overview/sg/chart/',
    downloadUrl: '/reports/acquired-active/overview/sg/download',
    donwloadMethod: POST,
  },
  {
    groupName: A_AND_A_SI_ACQUISITION_YTD_TARGET,
    chartName: BUSINESS_DIVISION,
    hasAdditionalFilter: true,
    hasSelectAllOption: false,
    catagoryAttributeName: 'businessDivision',
    url: '/reports/acquired-active/overview/bd/chart/',
    downloadUrl: '/reports/acquired-active/overview/bd/download',
    donwloadMethod: POST,
  },
  {
    groupName: A_AND_A_SI_ACQUISITION_YTD_TARGET,
    chartName: CE_LEVEL,
    hasAdditionalFilter: true,
    hasSelectAllOption: true,
    catagoryAttributeName: 'handlingCeFullName',
    url: '/reports/acquired-active/overview/ce-completion/',
    downloadUrl: '/reports/acquired-active/overview/ce/download',
    donwloadMethod: POST,
  },
  {
    groupName: A_AND_A_SI_ACQUISITION_YTD_TARGET,
    chartName: CAT_GROUP_LEVEL,
    hasAdditionalFilter: true,
    hasSelectAllOption: false,
    catagoryAttributeName: 'catGroup',
    url: '/reports/acquired-active/overview/cat-completion/',
    downloadUrl: '/reports/acquired-active/overview/cat/download',
    donwloadMethod: POST,
    tableDownloadTitle: 'CAT',
  },
  // 2nd group
  {
    groupName: A_AND_A_SI_ACQUISITION,
    chartName: SUBJECT_GROUP,
    hasAdditionalFilter: true,
    hasSelectAllOption: true,
    catagoryAttributeName: 'subjectGroup',
    url: '/reports/acquired-active/overview/sg/chart/',
    downloadUrl: '/reports/acquired-active/overview/sg/download',
    donwloadMethod: POST,
    tableDownloadWithAcquiredSi: true,
  },
  {
    groupName: A_AND_A_SI_ACQUISITION,
    chartName: BUSINESS_DIVISION,
    hasAdditionalFilter: true,
    hasSelectAllOption: false,
    catagoryAttributeName: 'businessDivision',
    url: '/reports/acquired-active/overview/bd/chart/',
    downloadUrl: '/reports/acquired-active/overview/bd/download',
    donwloadMethod: POST,
    tableDownloadWithAcquiredSi: true,
  },
  {
    groupName: A_AND_A_SI_ACQUISITION,
    chartName: CE_LEVEL,
    hasAdditionalFilter: true,
    hasSelectAllOption: true,
    catagoryAttributeName: 'handlingCeFullName',
    url: '/reports/acquired-active/overview/ce-acquisition/',
    downloadUrl: '/reports/acquired-active/overview/ce/download',
    donwloadMethod: POST,
    tableDownloadWithAcquiredSi: true,
  },
  {
    groupName: A_AND_A_SI_ACQUISITION,
    chartName: CAT_GROUP_LEVEL,
    hasAdditionalFilter: true,
    hasSelectAllOption: false,
    catagoryAttributeName: 'catGroup',
    url: '/reports/acquired-active/overview/cat-acquisition/',
    downloadUrl: '/reports/acquired-active/overview/cat/download',
    tableDownloadTitle: 'CAT',
    donwloadMethod: POST,
    tableDownloadWithAcquiredSi: true,
  },
];

const CHART_GROUP_TITLES = [
  {
    groupTitle: A_AND_A_SI_ACQUISITION_YTD_TARGET,
    levels: [
      {
        chartIndex: 0,
        title: SUBJECT_GROUP,
        img: subjectGroupYTDImg,
      },
      {
        chartIndex: 1,
        title: BUSINESS_DIVISION,
        img: businessDivisionYTDImg,
      },
      {
        chartIndex: 2,
        title: CE_LEVEL,
        img: ceYTDImg,
      },
      {
        chartIndex: 3,
        title: CAT_GROUP_LEVEL,
        img: catGroupYTDImg,
      },
    ],
  },
  {
    groupTitle: A_AND_A_SI_ACQUISITION,
    levels: [
      {
        chartIndex: 4,
        title: SUBJECT_GROUP,
        img: subjectGroupAcqImg,
      },
      {
        chartIndex: 5,
        title: BUSINESS_DIVISION,
        img: businessDivisionAcqImg,
      },
      {
        chartIndex: 6,
        title: CE_LEVEL,
        img: ceAcqImg,
      },
      {
        chartIndex: 7,
        title: CAT_GROUP_LEVEL,
        img: catGroupAcqImg,
      },
    ],
  },
];

const DiagramContent = () => {
  // get selectedChartIndex from URL
  let [searchParams, setSearchParams] = useSearchParams();
  let selectedChartIndex = searchParams.get('selectedChartIndex');
  if (selectedChartIndex == null) {
    selectedChartIndex = -1;
  } else {
    selectedChartIndex = parseInt(selectedChartIndex);
  }

  const setShowTableNav = chartIndex => {
    setSearchParams({ showTable: false, selectedChartIndex: chartIndex });
  };

  const siderOpen = useSelector(state => state.UserManage.siderOpen);
  const dispatch = useDispatch();

  let { selectDate } = useSelector(state => {
    return {
      // selectDate: state.Report.selectedAcquireDate,
      selectDate: state.Report.selectDate,
    };
  });

  const formatMonthYear = useMemo(() => {
    let date = selectDate;
    // default is today
    if (date === null) {
      date = moment().utc().format('YYYY-M');
    }
    let [year, month] = date.split('-');
    month = MONTH[month];
    return month + ' ' + year;
  }, [selectDate]);

  // update group titles with selected month year
  const formattedChartGroupTitles = CHART_GROUP_TITLES.map(chart => ({
    ...chart,
    groupTitle: chart.groupTitle + ' ' + formatMonthYear,
  }));

  const {
    groupName,
    chartName,
    hasAdditionalFilter,
    hasSelectAllOption,
    catagoryAttributeName,
    url,
    downloadUrl,
    donwloadMethod,
    tableDownloadTitle,
    tableDownloadWithAcquiredSi
  } = useMemo(() => {
    if (
      selectedChartIndex >= 0 &&
      selectedChartIndex < DIAGRAM_SETTINGS.length
    ) {
      return DIAGRAM_SETTINGS[selectedChartIndex];
    } else {
      return {
        groupName: '',
        chartName: '',
        hasAdditionalFilter: false,
        hasSelectAllOption: false,
        catagoryAttributeName: null,
        url: null,
        downloadUrl: null,
        tableDownloadTitle: null,
        tableDownloadWithAcquiredSi: false
      };
    }
  }, [selectedChartIndex]);


  const valueAttributeNames = useMemo(() => {
    if (selectedChartIndex >= 0 && selectedChartIndex <= 3)
      return valueAttributeAllNames.slice(0, 2);
    else return valueAttributeAllNames.slice(2, 4);
  }, [selectedChartIndex]);

  const onSelectChartIndex = index => {
    setShowTableNav(index);

    // if a chart is selected and the side menu is open, close the side menu
    if (index >= 0 && index < DIAGRAM_SETTINGS.length && siderOpen) {
      dispatch(changeSiderOpen(false));
    }
  };

  const cancelSelectedChart = () => onSelectChartIndex(-1);

  return (
    <Box sx={{ marginTop: '32px', paddingBottom: '62px' }}>
      {selectedChartIndex >= 0 ? (
        <Box className={styles['card-content-container']}>
          <div
            className={styles['card-content-paper']}
            disableGutters
            maxWidth={false}
          >
            <AAndAOverviewChart
              header={groupName}
              subHeader={chartName}
              selectedDate={selectDate}
              cancelSelectedChart={cancelSelectedChart}
              showSubjectGroupsSelector={false}
              valueAttributeNames={valueAttributeNames}
              hasAdditionalFilter={hasAdditionalFilter}
              hasSelectAllOption={hasSelectAllOption}
              catagoryAttributeName={catagoryAttributeName}
              url={url}
              downloadUrl={downloadUrl}
              donwloadMethod={donwloadMethod}
              tableDownloadTitle={tableDownloadTitle}
              tableDownloadWithAcquiredSi={tableDownloadWithAcquiredSi}
              formatMonthYear={formatMonthYear}
            />
          </div>
          <ChartList
            chartGroupTitles={formattedChartGroupTitles}
            onSelectChartIndex={onSelectChartIndex}
            selectedChartIndex={selectedChartIndex}
          />
        </Box>
      ) : (
        <ChartGroups
          chartGroupTitles={formattedChartGroupTitles}
          onSelectChartIndex={onSelectChartIndex}
          selectedChartIndex={selectedChartIndex}
        />
      )}
    </Box>
  );
};

export default DiagramContent;

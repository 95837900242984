import * as React from 'react';
import { useFormControl } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextErrorIcon from '../../assets/Texterror.svg';
import SiDetaialTextErrorIcon from '@/assets/SiDetaialTextError.svg';
import { textErrorStyle, siCreateTextErrorStyle } from './commonStyle';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import styled from '@mui/material/styles/styled';
const CssFormHelperText = styled(FormHelperText)({
  '&.Mui-error': {
    color: '#DE350B',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '-0.084px',
  },
});

export function MyFormHelperText(props) {
  const { propsError, PropsHelperText } = props;
  const { error } = useFormControl() || {};
  const helperText = React.useMemo(() => {
    if (error) {
      let helperText = '';
      if (propsError) {
        helperText = PropsHelperText;
      } else {
        helperText = 'This field is required.';
      }
      return (
        <Box sx={{ height: 'auto', width: 'auto' }}>
          <Grid container spacing={0.5}>
            <Grid item xs={0.35}>
              <TextErrorIcon></TextErrorIcon>
            </Grid>
            <Grid item xs={11}>
              <span style={textErrorStyle}> {helperText} </span>
            </Grid>
          </Grid>
        </Box>
      );
    }
    return '';
  }, [error, propsError]);

  return <CssFormHelperText>{helperText}</CssFormHelperText>;
}

export function InputHelperText(props) {
  const {
    exSpecialChar,
    specialChar,
    checkEmail,
    isEmail,
    propsError,
    PropsHelperText,
  } = props;
  const { error } = useFormControl() || {};
  const helperText = React.useMemo(() => {
    if (error) {
      let helperText = '';
      if (exSpecialChar && specialChar) {
        helperText = 'Please input positive integer number!';
      } else if (checkEmail && !isEmail) {
        helperText = `The email is error`;
      } else if (propsError) {
        helperText = PropsHelperText;
      } else {
        helperText = 'This field is required.';
      }
      return (
        <Box sx={{ height: '16px', width: 'auto' }}>
          <Grid container spacing={0.5}>
            <Grid item xs={0.35}>
              <TextErrorIcon></TextErrorIcon>
            </Grid>
            <Grid item xs={11}>
              <span style={textErrorStyle}> {helperText}</span>
            </Grid>
          </Grid>
        </Box>
      );
    }
    return '';
  }, [error, exSpecialChar, specialChar, checkEmail, isEmail, propsError]);

  return <CssFormHelperText>{helperText}</CssFormHelperText>;
}

export function NumberHelperText(props) {
  const {
    exSpecialChar,
    specialChar,
    outRangeError,
    minNumber,
    maxNumber,
    propsError,
    PropsHelperText,
  } = props;
  const { error } = useFormControl() || {};
  const helperText = React.useMemo(() => {
    if (error) {
      let helperText = '';
      if (exSpecialChar && specialChar) {
        helperText = 'Please input positive integer number!';
      } else if (outRangeError) {
        helperText = `Number must between ${minNumber} and ${maxNumber}`;
      } else if (propsError) {
        helperText = PropsHelperText;
      } else {
        helperText = 'This field is required.';
      }
      return (
        <Box sx={{ height: '16px', width: 'auto' }}>
          <Grid container spacing={0.5}>
            <Grid item xs={0.35}>
              {helperText !== '' ? (
                <TextErrorIcon></TextErrorIcon>
              ) : (
                <Box></Box>
              )}
            </Grid>
            <Grid item xs={11}>
              <span style={textErrorStyle}> {helperText}</span>
            </Grid>
          </Grid>
        </Box>
      );
    }
    return '';
  }, [error, outRangeError, propsError, exSpecialChar, specialChar]);

  return <CssFormHelperText>{helperText}</CssFormHelperText>;
}

export function TextAreaHelperText(props) {
  const {
    exceedsLimitErr,
    exceedsLimitHelperText,
    propsError,
    PropsHelperText,
  } = props;
  const { error } = useFormControl() || {};
  const helperText = React.useMemo(() => {
    if (error) {
      let helperText = '';
      if (exceedsLimitErr) {
        helperText = exceedsLimitHelperText;
      } else if (propsError) {
        helperText = PropsHelperText;
      } else {
        helperText = 'This field is required.';
      }
      return (
        <Box sx={{ height: '16px', width: 'auto' }}>
          <Grid container spacing={0.5}>
            <Grid item xs={0.35}>
              <TextErrorIcon></TextErrorIcon>
            </Grid>
            <Grid item xs={11}>
              <span style={{...textErrorStyle,color:'#EE5350'}}> {helperText}</span>
            </Grid>
          </Grid>
        </Box>
      );
    }
    return '';
  }, [error, exceedsLimitErr, propsError]);

  return <CssFormHelperText>{helperText}</CssFormHelperText>;
}


export function SiCreateInputHelperText(props) {
  const {
    exSpecialChar,
    specialChar,
    checkEmail,
    isEmail,
    propsError,
    PropsHelperText,
  } = props;
  const { error } = useFormControl() || {};
  const helperText = React.useMemo(() => {
    if (error) {
      let helperText = '';
      if (exSpecialChar && specialChar) {
        helperText = 'Please input positive integer number!';
      } else if (checkEmail && !isEmail) {
        helperText = `The email is error`;
      } else if (propsError) {
        helperText = PropsHelperText;
      } else {
        helperText = 'This field is required.';
      }
      return (
        <Box sx={{ height: '16px', width: 'auto' }}>
          <Grid container spacing={0.5}>
              <SiDetaialTextErrorIcon style={{marginTop:'2px',marginLeft:'5px'}}></SiDetaialTextErrorIcon>
              <span style={siCreateTextErrorStyle}> {helperText}</span>
          </Grid>
        </Box>
      );
    }
    return '';
  }, [error, exSpecialChar, specialChar, checkEmail, isEmail, propsError]);

  return <CssFormHelperText>{helperText}</CssFormHelperText>;
}
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import HeadOverview, { PageOverview } from './HeadOverview';
import BasicTable from './BasicTable/basicTable';
import DiagramContent from './SIPipelineDiagrams';
import { SubjectGroupTable } from './pipelineSubjectTable';
import { BusinessDivisionTable } from './pipelineBusinessTable';
import { StyledEngineProvider, useTheme } from '@mui/material/styles';
import PipelineFilter from './HeadOverview/pipelineFilter';
import { ReportTabChildren } from '../ReportSpecificComponents/ReportTabComponents';
import { useSelector } from 'react-redux';
import { TableBox, TableGroupBox, TableLabel } from '../ReportSpecificComponents/SpecialColorTableHeader';
import { ShowForPermission } from "@/components/PermissionControl/ShowForPermission";
import {
  useSearchParams,
} from 'react-router-dom';
import { CECATTable } from './pipelineCESCATable';
import {PERMISSIONS} from "@/constant/permission";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`sipipe-tabpanel-${index}`}
      aria-labelledby={`sipipe-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: '14px', paddingLeft: '5px', paddingRight: '5px', }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `sipipe-tab-${index}`,
    'aria-controls': `sipipe-tabpanel-${index}`,
  };
}

/**
 * SI Pipeline table
 */
const TableContent = () => {
  const { selectMonthDate } = useSelector(state => {
    return {
      selectMonthDate: state.Report.selectDate,
    };
  });

  const [selectDate, setSelectDate] = useState(new Date());

  const [formatYear, setFormatYear] = useState('FY**');

  const filterDrawerWidth = 380;
  const theme = useTheme();

  const [filterOpen, setFilterOpen] = useState(false);

  const filterOpenChange = () => {
    setFilterOpen(!filterOpen);
  };

  const BasicTableBoxCss = {
    width: filterOpen ? 'calc(100% - 308px)' : '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  };

  useEffect(() => {
    if (selectMonthDate != null) {
      let Year = selectMonthDate.split('-')[0];
      let Month = selectMonthDate.split('-')[1] - 1;
      setSelectDate(new Date(Year, Month));
    }
  }, [selectMonthDate]);

  useEffect(() => {
    let year = selectDate.getFullYear();
    const month = selectDate.getMonth();
    let yearLastTwoBit = (year % 100) + 1;
    setFormatYear(
      month >= 4 ? 'FY' + yearLastTwoBit : 'FY' + (yearLastTwoBit - 1)
    );
  }, [selectDate]);

  useEffect(() => {
    let userPermissionArray = localStorage.getItem('userPermissions');
    console.log('yzq', userPermissionArray);
  }, []);
  // console.log();
  return (
    <>
      <ShowForPermission permission={`${PERMISSIONS.REPORT_READ_GENERAL}`}>
        <TableGroupBox
          sx={{
            display: 'flex',
            flex: 'row',
            width: '100%',
            padding: '0px',
          }}
        >
          <StyledEngineProvider injectFirst>
            <div style={BasicTableBoxCss}>
              <HeadOverview   //标题和Filter按钮
                filterOpen={filterOpen}
                filterOpenChange={filterOpenChange}
              />
              <BasicTable formatYear={formatYear} />
            </div>
          </StyledEngineProvider>
          {filterOpen ? (
            <PipelineFilter
              filterOpen={filterOpen}
              drawerWidth={filterDrawerWidth}
              handleClose={filterOpenChange}
            />
          ) : (
            <div></div>
          )}
        </TableGroupBox>
        <TableGroupBox>
          <TableLabel>Subject Group</TableLabel>
          <SubjectGroupTable formatYear={formatYear} />
        </TableGroupBox>
        <TableGroupBox>
          <TableLabel>Business Division</TableLabel>
          <BusinessDivisionTable formatYear={formatYear} />
        </TableGroupBox>
        {/* Only users with "report:read-ce" permission can see the "CE/CAT" table */}
        <ShowForPermission permission={`${PERMISSIONS.REPORT_READ_CE}`}>
          <TableGroupBox sx={{ marginBottom: '50px' }}>
            <TableLabel>CE/SCA</TableLabel>
            <CECATTable formatYear={formatYear} />
          </TableGroupBox>
        </ShowForPermission>
      </ShowForPermission>
    </>
  );
};

/**
 * SI Pipeline page
 */
function SIPipeline() {
  // get showTable from URL
  let [searchParams, setSearchParams] = useSearchParams();
  let showTable = searchParams.get('showTable');
  showTable = showTable === 'false' ? false : true;

  const setShowTable = showTable => {
    // no need to convert from boolean to boolean value,
    // will pass showTable down to child as boolean value
    setSearchParams({ showTable });
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      data-selenium-id='Report_SiPipeline-Box'
      sx={{ width: '100%', padding: 0 }}
    >
      <ShowForPermission permission={`${PERMISSIONS.REPORT_READ_GENERAL}`}>
        <Box
          data-selenium-id='Report_SiPipeline-Box-Box'
          sx={{ mt: '-70px', borderColor: 'divider' }}
        >
          <Tabs
            data-selenium-id='Report_SiPipeline-Box-Box-Tabs'
            value={value}
            onChange={handleChange}
            aria-label='pipeline Overview'
          >
            <ReportTabChildren
              id='reportPipelineOverviewTab'
              label='Overview'
              {...a11yProps(0)}
            />
          </Tabs>
        </Box>

        <TabPanel
          sx={{
            marginTop: '-20px',
            backgroundColor: '#FAFAFA',
          }}
          data-selenium-id='Report_SiPipeline-TabPanel'
          value={value}
          index={0}>
          <PageOverview showTable={showTable} setShowTable={setShowTable} />
          {showTable ? <TableContent /> : <DiagramContent />}
        </TabPanel>
      </ShowForPermission>
    </Box>
  );
}

export default SIPipeline;

import { Box, Stack, Typography, Button } from '@mui/material';
import ExpireIcon from '@/assets/SIPPage/ExpirePage/ExpireIcon.svg';
export default function ExpiredPage({
    mainTitle = 'Oops! It looks like this link has expired.',
    subTitle = 'This may be because the invitation has been cancelled.',
}) {
  return (
    <Stack direction='column' alignItems='center' >
      <Typography
        sx={{
          width: '573px',
          fontSize: '48px',
          fontWeight: '800',
          lineHeight: '60px',
          textAlign: 'center',
          color:'#596A7C'
        }}
      >
          {mainTitle}
      </Typography>
      <Typography
      sx={{
        width: '573px',
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '25.2px',
        textAlign: 'center',
        color:'#BCC5CF'
      }}>
          {subTitle}
      </Typography>
      <ExpireIcon />
      {/* <Button sx={{ width: '400px' }} variant='contained'>
        CLOSE THIS PAGE
      </Button> */}
    </Stack>
  );
}

import './LoadingIcon.css';
import Icon from './LoadingIcon.svg';
export default function LoadingIcon() {
  return (
    <div
      style={{
        height: '24px',
        width: '24px',
      }}>
      <Icon className='SimpleLoadingIconCompontent' />
    </div>
  );
}

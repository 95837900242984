/* eslint-disable indent */
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useMount, useUpdateEffect } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
// Components
import {
  Box,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material';
import {
  CancelBtn,
  DecisionUnfoldBtnWithSave,
  defaultVars,
  ErrVars,
  NextBtn,
  SubmitBtn,
} from './DecisionMyStyled';
import EndButtonArrowUpBlue from '@/assets/EndButtonArrowUpBlue.svg';
import EndButtonArrowDownBlue from '@/assets/EndButtonArrowDownBlue.svg';
import DecisionTypeBlock from './DecisionTypeBlock';
import { DecisionTitle } from './DecisionTitle';
import SimpleIconButton from '@/componentsphase2/Button/SimpleIconButton';
import HeaderTitle from './HeaderTitle';
import JournalMutiSelect from './JournalMutiSelect';
import Assignee from './Assignee';
import EmailContainer from '@/components/EmailModal/EmailContainer';
import { ButtonAlertBox } from '@/components/Alert/AlertBox';
import UserSelectInput from './Component/UserSelectInput';
import { AssigneeSelectedInput } from './Component/AssigneeSelectedInput';
import {
  SelectInput,
  MulSelectInput,
} from '@/componentsphase2/SelectBox/SelectInput';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import { GetPopperBottomContext } from '@/componentsphase2/Autocomplete/CommonContext';
import CustomDatePicker from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/CustomDatePicker';
import CheckListButton from './Component/CheckListButton';
// svg..
import FlowWarnDefault from '@/componentsphase2/Button/icon/FlowWarnDefault.svg';
// style
import {
  commonButtonContainerStyle,
  commonPopContainerStyle,
} from '../../commonStyle';
// Redux
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import {
  clearSendEmailCode,
  clearGetEmailCode,
  clearSIPSheveledCode,
  setSipDetailAllMandatorySubmitOrNextPressed,
  setCurrentDecisionType,
  SIP_CHECKLIST_NOT_PROCEEDED_CODE,
  SET_EMAIL_ALL,
} from '@/actions/SIMT-SIP/SIP/SIPAction';
import { GET_SIP_STATUS } from '@/actions/SIMT-SIP/SIP/SIPAction';
import {
  CANCEL_EDIT,
  changeCheckListStatus,
} from '@/actions/SIMT-SIP/SIPChecklistSubmitted/SIPChecklistAction';
// utils & CONSTANTS
import { forEmail } from '@/modules/RichTextEditor/EditorUtils';
import { needToComfirmShow, isInitialReview } from '../utils/isSubmitted';
import { getURLSearch } from '@/utils/commonUtils';
import { PERMISSIONS } from '@/constant/permission';
import { PANEL } from '../index.js';
import useSendEmailAlertHook from './useSendEmailAlertHook';
// services
import { getJournalListWithName } from '@/actions/SIMT-SI/SpecialIssue/Journal';
import {  // get SIP details
  checkProposerOK,
  getContentCommissioningApproaches,
  getGEInformation,
  getObject,
  getSIPInformation,
  getSIPStatus,
} from '@/actions/SIMT-SIP/SIP/SipDetail';
import {  // SIP stage decison
  assignToCESubmitDecision,
  assignToRejectSubmitDecisionSuccess,
  getEmailAllInformation,
  getUserRejectReasonDropList,
  sendEmailBody,
  SubmitDirectAccept,
  SubmitMoveToInitialReview,
  intialReviewMoveToEBMReview,
  assignToScreener,
} from '@/actions/SIMT-SIP/SIP/StageDecision';
import { getApproveJournalList } from '@/actions/SIMT-SIP/SIP/Journal';
import { CheckSipNonCatJournal } from '@/actions/SIMT-SIP/SIP/SiptoSi';
import {
  validateCommissionApproaches, validateGEHasLeadGE,
  validateGEInformation, validateGEWithStageAndDecision,
  validateSipInformation
} from "@/pages/SIPPage/SIPDetailPage/DecisionPanel/validation";


/**
 * Constants
 */
const TO_BE_ASSIGNED = 'To Be Assigned';
export const exportBtnCss = {
  boxShadow: 'none',
  width: '70px',
  height: '34px',
  backgroundColor: '#0052CC',
  borderRadius: '5px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '18px',
  color: '#FFFFFF',
  textTransform: 'capitalize',
};

export const CancelBtnCss = {
  width: '70px',
  height: '34px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '18px',
  color: '#0052CC',
  textTransform: 'capitalize',
  border: '1px solid #0052CC',
  borderRadius: '5px',
};


const formatEmailReceiptants = emails => emails.length === 0 ? 'N/A' : emails.join(', ');

/**
 * DecisionUnfold
 * @param {*} props
 * @returns ReactComponent
 */
export default function DecisionUnfold(props) {
  const {
    sipStage,
    panelDisplayed,
    setPanelDisplayed,
    sipCode,
    openPop,
    isAssignScreenerSaved,
    setIsDecisionSaved,
    setIsAssignScreenerSaved,
    isDecisionButtonDisabled = false,
    setIsDecisionButtonDisabled = () => { },
    showCheckListBtn = false,
    showAssignToScreenerBtn = false,
    sipFlow,
  } = props;

  const togglePanelDisplay = (panelBtnClicked) => {
    if (panelDisplayed === panelBtnClicked) {
      setPanelDisplayed(PANEL.NULL);
    } else {
      setPanelDisplayed(panelBtnClicked);
    }
  };

  const dispatch = useDispatch();
  const intl = useIntl();
  const [decisionType, setDecisionType] = useState('');
  const [comments, setComments] = useState('');
  const [commentsAssignScreener, setCommentsAssignScreener] = useState('');
  const [handlingCE, setHandingCE] = useState(null);
  const [operationsLead, setOperationsLead] = useState(null);
  const [flow, setFlow] = useState(null);
  const [screener, setScreener] = useState(null);
  const [revisionDeadlineData, setRevisionDeadlineData] = useState();
  const [assigneeEmail, setAssigneeEmail] = useState(null);
  const [isShowHandlingCE, setIsShowHandlingCE] = useState(false);
  const [isShowRevisionDeadline, setIsShowRevisionDeadline] = useState(false);
  const [isShowAssigneeChoice, setIsShowAssigneeChoice] = useState(false);
  const [isShowOperationsLead, setIsShowOperationsLead] = useState(false);
  const [isShowJounrnalMultiSelect, setIsShowJounrnalMultiSelect] =
    useState(false);
  const [isSelectedAssignToCE, setIsSelectedAssignToCE] = useState(false);
  const [isSelectedRevise, setIsSelectedRevise] = useState(false);
  const [isInitialReviewCancelRevision, setIsInitialReviewCancelRevision] = useState(false);
  const [isSelectedReject, setIsSelectedReject] = useState(false);
  const [isSelectedWithdraw, setIsSelectedWithdraw] = useState(false);
  const [isSelectedCancelEditorReview, setIsSelectedCancelEditorReview] =
    useState(false);
  const [isSelectedTransferToAnother, setIsSelectedTransferToAnother] =
    useState(false);
  const [isSelectedJPMInquery, setIsSelectedJPMInquery] = useState(false);
  const [isSelectedTransferBackToJPA, setIsSelectedTransferBackToJPA] =
    useState(false);
  const [isSelectedCancelJPMInquery, setIsSelectedCancelJPMInquery] =
    useState(false);
  // email
  const [isShowFrom, setIsShowFrom] = useState(false);
  const [isShowCc, setIsShowCc] = useState(false);
  const [isShowBcc, setIsShowBcc] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [hasEmptyError, setHasEmptyError] = useState(false);
  const [vars, setVars] = useState(defaultVars);
  const [focusComments, setFoucusComments] = useState(false);
  const [focusCommentsAssignScreener, setFoucusCommentsAssignScreener] = useState(false);
  const [emailPopUpOpen, setEmailPopUpOpen] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [journalMutiSelected, setJournalMutiSelected] = useState([]);
  const [additionCondition, setAdditionCondition] = useState({});
  const [assignees, setAssignees] = useState([]);
  const [ateJournalInputValue, setATEJournalInputValue] = useState('');
  const [ateJournal, setATEJournal] = useState({ journalName: '' });
  const [attachments, setAttachments] = useState([]);
  const [buttonAlertTitle, setButtonAlertTitle] = useState('');
  const [buttonAlertMessage, setButtonAlertMessage] = useState('');
  const [buttonAlertButtons, setButtonAlertButtons] = useState([]);
  const [buttonAlertOpen, setButtonAlertOpen] = useState(false);
  const [strictClose, setStrictClose] = useState(false);

  const [buttonAlertAutoHideDuration, setButtonAlertAutoHideDuration] =
    useState(4000);
  const [severity, setSeverity] = useState('info');
  const [revisionDateOpen, setRevisionDateOpen] = useState(false);
  const [handlingCEOpen, setHandlingCEOpen] = useState(false);
  const [assigneeOpen, setAssigneeOpen] = useState(false);
  const [journalOpen, setJournalOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState([]);
  const [rejectSelect, setRejectSelect] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [directAcquireReason, setDirectAcquireReason] = useState([]);
  const [isShowFlow, setIsShowFlow] = useState(false);
  const [directAcquireReasonSelect, setDirectAcquireReasonSelect] =
    useState(false);
  const [sipDetailManatoryAllFilled, setSipDetailManatoryAllFilled] =
    useState(true);
  const [ssipIdeationhasLeadGE, setSSIPIdeationhasLeadGE] = useState(false);
  const handlingCEId = useMemo(() => {
    return handlingCE?.userId;
  }, [handlingCE]);
  const screenerId = useMemo(() => {
    return screener?.userId;
  }, [screener]);
  const screenerName = useMemo(() => {
    return screener?.username;
  }, [screener]);

  useEffect(() => {
    getSIPStatus(sipCode);
  }, []);

  // Get data from Redux
  const {
    sipInformation,
    geInformation,
    object,
    contentCommissioningApproaches,
    emailAllInformation,
    emailReturnCode,
    getEmailCode,
    updateSipShevelCode,
    JourListResult,
    assignToCESubmitionDecisionResult,
    assignToSubmittedResult,
    decisionTypeList = [],
    sipDetailAllMandatorySubmitOrNextPressed,
    sipVersion,
    sipStatus,
  } = useSelector(state => {
    return {
      sipInformation: state.SIP.sipInformation,
      geInformation: state.SIP.geInformation,
      object: state.SIP.object,
      contentCommissioningApproaches: state.SIP.contentCommissioningApproaches,
      JourListResult: state.SI.JourList,
      emailAllInformation: state.SIP.emailAllInformation,
      emailReturnCode: state.SIP.emailReturnCode,
      getEmailCode: state.SIP.getEmailCode,
      ateJournalDropList: state.SIP.ateJournalDropList,
      userRejectReasonDropList: state.SIP.userRejectReasonDropList,
      updateSipShevelCode: state.SIP.updateSipShevelCode,
      assignToCESubmitionDecisionResult:
        state.SIP.assignToCESubmitionDecisionResult,
      assignToSubmittedResult: state.SIP.assignToSubmittedResult,
      decisionTypeList: state?.SIP?.decisionType,
      sipDetailAllMandatorySubmitOrNextPressed:
        state.SIP.sipDetailAllMandatorySubmitOrNextPressed,
      sipVersion: state.SIP.sipStatus?.sipVersion,
      sipStatus: state.SIP.sipStatus,
    };
  });

  const isDecisionTypeListLength1 = decisionTypeList?.length === 1;
  const [scaleToUse, setScaleToUse] = useState(1);

  const currentCE = useMemo(() => {
    return {
      userId: sipStatus?.ceId ?? null,
      username: sipStatus?.handlingCE ?? null,
      email: sipStatus?.emailOfHandlingCe ?? null,
    };
  }, [sipStatus]);

  // when current CE changed, set the input of CE to null
  useEffect(() => {
    setHandingCE(null);
  }, [currentCE]);

  const currentSreener = useMemo(() => {
    return {
      userId: sipStatus?.screenerId ?? null,
      username: sipStatus?.screener ?? "To Be Assigned",
      email: sipStatus?.emailOfScreener ?? null,
    };
  }, [sipStatus]);

  // when current Screener changed, set the input of screener to null
  useEffect(() => {
    setScreener(null);
  }, [currentSreener]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth - 10 < 1300.0) {
        setScaleToUse((screenWidth - 10) / 1300.0);
      } else {
        setScaleToUse(1);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const DecisionContainer = {
    minWidth: '1300px',
    // minHeight: '256px',
    minHeight: '256px',
    backgroundColor: 'rgb(250, 250, 250)',
    position: 'sticky', // 外部需要有一个容器让他贴靠底部
    bottom: 0,
    boxSizing: 'border-box',
    transition: openPop ? 'all 0.15s linear ' : 'all 0.15s linear',
    zIndex: '1200',
  };
  const popContainer = {
    ...commonPopContainerStyle,
  };
  const buttonContainer = {
    ...commonButtonContainerStyle,
    height: emailPopUpOpen ? '48px' : '60px',
    padding: emailPopUpOpen ? '12px 0px 0px 0px' : '9px 0', //'12px 32px'
  };

  // cache emailObject to prevent array object reference changes (e.g. toOfEmailArray)
  const emailObject = useMemo(() => {
    return emailAllInformation ?? {
      title: 'N/A',
      content: 'N/A',
      from: 'N/A',
      fromNameAndEmail: 'N/A',
      to: [],
      toNameAndEmail: [],
      copy: [],
      copyNameAndEmail: [],
      bcc: [],
      bccNameAndEmail: [],
      mailAttachments: [],
      mailAttachmentIds: null,
      requireAttachment: false,
    };
  }, [emailAllInformation]);

  const {
    title: subjectOfEmailStr = 'N/A',
    from: fromOfEmail = 'N/A',
    to: toOfEmailArray = [],
    copy: ccOfEmailArray = [],
    bcc: bccOfEmailArray = [],
    content: emailBody = 'N/A',
  } = emailObject;

  const [emailBodyTmp, setEmailBodyTmp] = useState(null);
  const [subjectOfEmail, setSubjectOfEmail] = useState('');
  const [toOfEmail, setToOfEmail] = useState([]);
  const [ccOfEmail, setCcOfEmail] = useState([]);
  const [bccOfEmail, setBccOfEmail] = useState([]);

  useEffect(() => {
    if (emailBody != 'N/A') {
      setEmailBodyTmp(emailBody);
    }
  }, [emailBody]);
  useEffect(() => {
    setSubjectOfEmail(subjectOfEmailStr);
  }, [subjectOfEmailStr]);
  useEffect(() => {
    setToOfEmail(toOfEmailArray);
  }, [toOfEmailArray]);
  useEffect(() => {
    setCcOfEmail(ccOfEmailArray);
  }, [ccOfEmailArray]);
  useEffect(() => {
    setBccOfEmail(bccOfEmailArray);
  }, [bccOfEmailArray]);

  // Not sure if the following line are still used
  useEffect(() => {
    if (
      emailAllInformation &&
      getEmailCode === 200 &&
      emailAllInformation.data !== null
    ) {
      // 以下是由于JPM和cancel JPM的后端设计问题 多附带以下参数
      setAdditionCondition({
        ...additionCondition,
        mailSend: {
          title: subjectOfEmailStr,
          content: forEmail(emailBody),
          from: fromOfEmail,
          copy: ccOfEmailArray,
          to: toOfEmailArray,
          bcc: bccOfEmailArray,
          mailAttachments: [],
          requireAttachment: false,
        },
      });
    }
  }, [emailAllInformation]);
  // Not sure if the above line are still used

  const handleDecisionTypeChange = async (_event, value) => {
    setDecisionType(value);
    setIsNext(false);
    //  https://jira.wiley.com/browse/CT-7989
    if (needToComfirmShow(sipStage)) {
      const /**@type {import('axios').AxiosResponse<{readonly data:boolean;}>|void}*/ res =
        await checkProposerOK(sipCode).catch(console.error);
      if (!res?.data?.data) {
        setButtonAlertButtonsSipChecklistNotProceeded(
          'You cannot make this decision to this SIP yet',
          'You need to verify or confirm the associated GE’s new flag before making this decision.'
        );
        return;
      }
    }
  };

  const location = useLocation();

  useEffect(() => {
    let params = getURLSearch();
    if (params) {
      let checkListResult = params.checkListResult;
      if (checkListResult === 'Withdraw' || checkListResult === 'Reject') {
        handleDecisionTypeChange(null, checkListResult);
      }
    }
  }, [location]);

  function hasValue(value) {
    if (value) {
      if (value instanceof Array) {
        // 数组情况，长度非0， 零号元素非空
        return value.length > 0 && value[0] && value[0] !== '';
      } else return value !== '';
    } else {
      return false;
    }
  }

  const setButtonAlertButtonsSipChecklistNotProceeded = useCallback(
    (
      title = 'You cannot make decision to this SIP',
      // message = 'The Checklist status has been changed by you or another user.'
      message = 'The Checklist status has been changed by screener.'
    ) => {
      setSeverity('warning');
      setButtonAlertTitle(title);
      setButtonAlertMessage(message);
      setButtonAlertButtons([
        {
          text: 'Close',
          onClick: () => {
            setButtonAlertOpen(false);
            setPanelDisplayed(PANEL.NULL);
            dispatch({ type: CANCEL_EDIT });
          },
        },
      ]);
      setButtonAlertAutoHideDuration(null);
      setButtonAlertOpen(true);
      setIsDecisionButtonDisabled(true); //M2S7
      setStrictClose(true);  // this will trigger decision panel close action, after the "handleClose" event of <ButtonAlertBox />
      dispatch(changeCheckListStatus('Editable'));
    }, []
  );

  const handleMoveToSubmitted = async () => {
    dispatch(
      sendEmailBody(
        sipStage,
        'Move to Submitted Stage',
        sipCode,
        comments,
        emailBodyTmp,
        additionCondition,
        attachments,
        setButtonAlertButtonsSipChecklistNotProceeded,
        e => e,
        sipVersion,
        sipStatus,
        flow
      )
    );
  };

  const handleAssignToCESubmit = e => {
    setSeverity('warning');
    setButtonAlertTitle(
      intl.messages['sipDetail.transferBackToJPASubmitTitle']
    );
    setButtonAlertMessage(
      intl.messages['sipDetail.transferBackToJPASubmitMessage']
    );
    setButtonAlertAutoHideDuration(null);
    setButtonAlertButtons([
      {
        text: intl.messages['sipDetail.transferBackToJPASubmitKeepButton'],
        onClick: () => {
          setButtonAlertOpen(false);
          setButtonAlertAutoHideDuration(null);
        },
      },
      {
        text: intl.messages['sipDetail.transferBackToJPASubmitSubmitButton'],
        onClick: async () => {
          const p = await new Promise(resolve =>
            dispatch(
              assignToCESubmitDecision(
                sipCode,
                handlingCEId,
                comments,
                setButtonAlertButtonsSipChecklistNotProceeded,
                resolve,
                sipVersion,
                sipStatus
              )
            )
          ).catch(console.error);
          if (p === SIP_CHECKLIST_NOT_PROCEEDED_CODE) return;
          setButtonAlertOpen(false);
          setButtonAlertAutoHideDuration(null);
          setIsNext(false);
          initDecisionPanel();
        },
      },
    ]);
    setButtonAlertOpen(true);
  };

  // Assign to screener (@ initial review stage)
  const handleAssignToScreener = _e => {
    if (Number.isNaN(screenerId)) {
      console.warn("screener id is not valid: ", screenerId);
      return;
    }
    const isReassignScreener = sipStatus?.screener !== TO_BE_ASSIGNED;
    const title = isReassignScreener
      ? `Do you confirm assigning ${screenerName} to the new Screener?`
      : `Do you confirm assigning ${screenerName} as Screener?`;
    const message = isReassignScreener
      ? `The notification email will send to new screener automatically.`
      : `The notification email will be sent to assigned screener automatically.`;
    openButtonAlert({
      title,
      message,
      buttons: [
        {
          text: 'No',
        },
        {
          text: 'Yes',
        },
      ],
    })
      .then(() => {
        dispatch(
          assignToScreener({
            sipCode,
            comments: commentsAssignScreener,
            sipVersion,
            screener: screenerId,
            isReassignScreener,
          })
        ).then(sipNewVersion => {
          if (sipNewVersion) {
            const newSipStatus = {
              ...sipStatus,
              sipVersion: sipNewVersion,
            };
            dispatch({
              type: GET_SIP_STATUS,
              data: {
                ...newSipStatus,
              },
            });
          }
        });
        setPanelDisplayed(PANEL.NULL);
        initAssignScreenerPanel();
      })
      .catch(() => { });
  };

  const handleEmailSubmit = async e => {
    e.preventDefault();
    setIsNext(true);
    let isButtonAlertButtonsSipChecklistNotProceeded = false;
    if (!hasEmptyError) {
      if (
        sipStage === intl.messages['sipDetail.sipStageType.submitted'] ||
        sipStage === 'Ideation'
      ) {
        switch (decisionType) {
          case intl.messages['sipDetail.decisionPanelElements.moveToSubmitted']:
            handleMoveToSubmitted();
            return;
          case intl.messages['sipDetail.decisionPanelElements.assignToCE']:
            // Need a Modal
            handleAssignToCESubmit();
            return;
          case intl.messages['sipDetail.decisionPanelElements.reject']:
            if (
              (await new Promise(resolve =>
                dispatch(
                  assignToRejectSubmitDecisionSuccess(
                    sipCode,
                    comments,
                    sipVersion,
                    setButtonAlertButtonsSipChecklistNotProceeded,
                    resolve,
                    sipStatus,
                    rejectReason
                  )
                )
              ).catch(console.error)) === SIP_CHECKLIST_NOT_PROCEEDED_CODE
            )
              isButtonAlertButtonsSipChecklistNotProceeded = true;
            break;
          case intl.messages['sipDetail.decisionPanelElements.revise']:
          case intl.messages['sipDetail.decisionPanelElements.withdraw']:
          case intl.messages['sipDetail.decisionPanelElements.isInitialReviewCancelRevision']:
          case intl.messages['sipDetail.decisionPanelElements.isCancelRevision']:
            console.log("enter this switch627");
            if (
              (await new Promise(resolve =>
                dispatch(
                  sendEmailBody(
                    sipStage,
                    decisionType,
                    sipCode,
                    comments,
                    emailBodyTmp,
                    additionCondition,
                    attachments,
                    setButtonAlertButtonsSipChecklistNotProceeded,
                    resolve,
                    sipVersion,
                    sipStatus
                  )
                )
              ).catch(console.error)) === SIP_CHECKLIST_NOT_PROCEEDED_CODE
            )
              isButtonAlertButtonsSipChecklistNotProceeded = true;
            break;
        }
      } else if (
        sipStage === intl.messages['sipDetail.sipStageType.initialReview'] ||
        sipStage === intl.messages['sipDetail.sipStageType.editorReview'] ||
        sipStage === intl.messages['sipDetail.sipStageType.publisherInquiry'] ||
        sipStage === intl.messages['sipDetail.sipStageType.revision']
      ) {
        if (
          (await new Promise(resolve => {
            dispatch(
              sendEmailBody(
                sipStage,
                decisionType,
                sipCode,
                comments,
                emailBodyTmp,
                additionCondition,
                attachments,
                setButtonAlertButtonsSipChecklistNotProceeded,
                resolve,
                sipVersion,
                sipStatus,
                null,
                subjectOfEmail,
                (toOfEmail),
                (ccOfEmail),
              )
            );
          }).catch(console.error)) === SIP_CHECKLIST_NOT_PROCEEDED_CODE
        )
          isButtonAlertButtonsSipChecklistNotProceeded = true;
      }
      if (isButtonAlertButtonsSipChecklistNotProceeded) return;
      dispatch(getSIPStatus(sipCode));
      dispatch(getObject(sipCode));
      dispatch(getSIPInformation(sipCode));
      dispatch(getGEInformation(sipCode));
      dispatch(getContentCommissioningApproaches(sipCode));
      dispatch(CheckSipNonCatJournal(sipCode));
      setPanelDisplayed(PANEL.NULL);
    } else {
      var text = document.getElementById('CommentsText');
      if (text?.value !== null) {
        text.focus();
      }
    }
    initDecisionPanel();
  };

  const openButtonAlert = ({ title, message, buttons, duration = null }) =>
    new Promise((resolve, reject) => {
      setSeverity('warning');
      setButtonAlertTitle(title);
      setButtonAlertMessage(message);
      setButtonAlertAutoHideDuration(duration);
      setButtonAlertOpen(true);
      if (buttons.length === 2) {
        const originalOnClicks = [buttons[0].onClick, buttons[1].onClick];
        buttons[0].onClick = () => {
          setButtonAlertOpen(false);
          typeof originalOnClicks[0] === 'function' && originalOnClicks[0]();
          reject('cancel');
        };
        buttons[1].onClick = () => {
          let p;
          if (typeof originalOnClicks[1] === 'function') {
            p = originalOnClicks[1]();
            if (p instanceof Promise) {
              p.then(() => {
                setButtonAlertOpen(false);
                resolve();
              });
            } else {
              setButtonAlertOpen(false);
              resolve();
            }
          } else {
            setButtonAlertOpen(false);
            resolve();
          }
        };
      }

      setButtonAlertButtons(buttons);
    });

  const handleMoveToInitialReview = e => {
    openButtonAlert({
      title: intl.messages['sipDetail.alertMoveToInitialReview.title'],
      message: intl.messages['sipDetail.alertMoveToInitialReview.message'],
      buttons: [
        {
          text: intl.messages['sipDetail.alertMoveToInitialReview.buttons.0'],
        },
        {
          text: intl.messages['sipDetail.alertMoveToInitialReview.buttons.1'],
        },
      ],
    })
      .then(() => {
        const operationsLeadId = operationsLead?.userId;
        if (Number.isNaN(operationsLeadId)) {
          console.warn("operations Lead id is not valid: ", operationsLeadId);
          return;
        }
        dispatch(
          SubmitMoveToInitialReview({
            sipCode,
            comments,
            sipVersion,
            operationsLead: operationsLeadId,
          })
        ).then(sipNewVersion => {
          if (sipNewVersion) {
            const newSipStatus = {
              ...sipStatus,
              sipVersion: sipNewVersion,
            };
            dispatch({
              type: GET_SIP_STATUS,
              data: {
                ...newSipStatus,
              },
            });
          }
        });
        setPanelDisplayed(PANEL.NULL);
        initDecisionPanel();
      })
      .catch(() => { });
  };

  const handleMoveToEBMReview = e => {
    openButtonAlert({
      title: 'Are you sure you want to move this SIP to EBM review stage?',
      message:
        'The notification email will be sent out automatically and you cannot revoke it after submitting.',
      buttons: [
        {
          text: 'No',
        },
        {
          text: 'Yes',
        },
      ],
    }).then(() => {
      dispatch(
        intialReviewMoveToEBMReview({
          sipCode,
          comments,
          sipVersion,
        })
      ).then(sipNewVersion => {
        if (sipNewVersion) {
          const newSipStatus = {
            ...sipStatus,
            sipVersion: sipNewVersion,
          };
          dispatch({
            type: GET_SIP_STATUS,
            data: {
              ...newSipStatus,
            },
          });
        }
      });
      setPanelDisplayed(PANEL.NULL);
      initDecisionPanel();
    })
      .catch(() => { });
  };

  useEffect(() => {
    dispatch(getSIPStatus(sipCode));
    dispatch(getObject(sipCode));
    dispatch(getSIPInformation(sipCode));
    dispatch(getGEInformation(sipCode));
    dispatch(getContentCommissioningApproaches(sipCode));
    dispatch(CheckSipNonCatJournal(sipCode));
  }, [assignToCESubmitionDecisionResult, assignToSubmittedResult]);

  // Empty Error Display
  useEffect(() => {
    if (
      !hasValue(decisionType) ||
      (!hasValue(comments) &&
        decisionType !==
        intl.messages['sipDetail.decisionPanelElements.reject'] &&
        decisionType !==
        intl.messages['sipDetail.decisionPanelElements.directAccept'] &&
        decisionType !==
        intl.messages[
        'sipDetail.decisionPanelElements.moveToInitialReview'
        ] &&
        decisionType !==
        intl.messages['sipDetail.decisionPanelElements.moveToSubmitted'] &&
        decisionType !== 'Move to EBM Review') ||
      comments.length > 100000 ||
      (isShowHandlingCE && !hasValue(handlingCEId)) ||
      (isShowJounrnalMultiSelect &&
        !hasValue(journalMutiSelected) &&
        sipInformation?.sipType !== 'Yes') ||
      (isShowAssigneeChoice && !hasValue(assigneeEmail)) ||
      (decisionType ===
        intl.messages['sipDetail.decisionPanelElements.assignToEditor'] &&
        (ateJournal === null || assignees.length === 0)) ||
      (decisionType ===
        intl.messages['sipDetail.decisionPanelElements.reject'] &&
        rejectReason.length === 0) ||
      (isShowRevisionDeadline && !revisionDeadlineData) ||
      (decisionType ===
        intl.messages['sipDetail.decisionPanelElements.directAccept'] &&
        !directAcquireReasonSelect) ||
      (decisionType ===
        intl.messages['sipDetail.decisionPanelElements.moveToInitialReview'] &&
        !operationsLead)
    ) {
      setHasEmptyError(true);
    } else {
      setHasEmptyError(false);
    }
  }, [
    decisionType,
    comments,
    commentsAssignScreener,
    isShowHandlingCE,
    handlingCEId,
    journalMutiSelected,
    assigneeEmail,
    assignees,
    ateJournal,
    revisionDeadlineData,
    directAcquireReasonSelect,
    operationsLead,
  ]);

  useEffect(() => {
    if (isNext && !hasValue(comments)) {
      setVars(ErrVars);
    } else {
      setVars(defaultVars);
    }
  }, [comments, isNext]);

  // move to configuration file or DB?
  useEffect(() => {
    // need improvement:
    // the following isSelectedxxx may cause setState unstable
    setIsShowHandlingCE(false);
    setIsShowRevisionDeadline(false);
    setIsShowFlow(false);
    setIsShowAssigneeChoice(false);
    setIsShowOperationsLead(false);
    setIsShowJounrnalMultiSelect(false);
    setIsSelectedAssignToCE(false);
    setIsSelectedRevise(false);
    setIsSelectedReject(false);
    setIsSelectedWithdraw(false);
    setIsSelectedCancelEditorReview(false);
    setIsSelectedTransferToAnother(false);
    setIsSelectedJPMInquery(false);
    setIsSelectedCancelJPMInquery(false);
    setIsSelectedTransferBackToJPA(false);
    setIsShowFrom(false);
    setIsShowBcc(false);
    setIsShowCc(false);
    switch (decisionType) {
      case intl.messages['sipDetail.decisionPanelElements.moveToInitialReview']:
        setIsShowOperationsLead(true);
        break;
      case intl.messages['sipDetail.decisionPanelElements.assignToCE']:
        setIsShowHandlingCE(true);
        setIsSelectedAssignToCE(true);
        break;
      case intl.messages['sipDetail.decisionPanelElements.revise']:
        setIsShowRevisionDeadline(true);
        setIsSelectedRevise(true);
        setIsShowFrom(true);
        setIsShowBcc(true);
        setIsShowCc(true);
        break;
      case intl.messages['sipDetail.decisionPanelElements.reject']:
        setIsSelectedReject(true);
        setIsShowFrom(true);
        setIsShowBcc(true);
        setIsShowCc(true);
        break;
      case intl.messages['sipDetail.decisionPanelElements.withdraw']:
        setIsSelectedWithdraw(true);
        setIsShowFrom(true);
        setIsShowBcc(true);
        setIsShowCc(true);
        break;
      case intl.messages['sipDetail.decisionPanelElements.isInitialReviewCancelRevision']:
      case intl.messages['sipDetail.decisionPanelElements.cancelRevision']:
        setIsShowFrom(true);
        setIsShowBcc(true);
        setIsShowCc(true);
        break;
      case intl.messages['sipDetail.decisionPanelElements.cancelEditorReview']:
        setIsSelectedCancelEditorReview(true);
        setIsShowFrom(true);
        setIsShowBcc(true);
        break;
      case intl.messages['sipDetail.decisionPanelElements.transferToAnotherCE']:
        setIsShowHandlingCE(true);
        setIsSelectedTransferToAnother(true);
        break;
      case intl.messages['sipDetail.decisionPanelElements.publisherInquiry']:
        setIsShowAssigneeChoice(true);
        setIsShowJounrnalMultiSelect(true);
        setIsSelectedJPMInquery(true);
        setIsShowFrom(true);
        setIsShowBcc(true);
        break;
      case intl.messages[
        'sipDetail.decisionPanelElements.cancelPublisherInquiry'
      ]:
        setIsSelectedCancelJPMInquery(true);
        setIsShowFrom(true);
        setIsShowBcc(true);
        break;
      case intl.messages['sipDetail.decisionPanelElements.transferBackToJPA']:
        setIsSelectedTransferBackToJPA(true);
        break;
      case intl.messages['sipDetail.decisionPanelElements.moveToSubmitted']:
        setIsShowFrom(true);
        setIsShowHandlingCE(false);
        setIsShowRevisionDeadline(false);
        setIsShowAssigneeChoice(false);
        setIsShowJounrnalMultiSelect(false);
        setIsSelectedAssignToCE(false);
        setIsSelectedRevise(false);
        setIsSelectedReject(false);
        setIsSelectedWithdraw(false);
        setIsSelectedCancelEditorReview(false);
        setIsSelectedTransferToAnother(false);
        setIsSelectedJPMInquery(false);
        setIsSelectedCancelJPMInquery(false);
        setIsSelectedTransferBackToJPA(false);
        setIsShowFlow(true);
        setIsShowBcc(false);
        setIsShowCc(false);
        break;
      case intl.messages[
        'sipDetail.decisionPanelElements.moveToCommissioningEditorReview'
      ]:
        setIsShowHandlingCE(true);
        // setHandingCEId(sipStatus?.ceId);
        setIsShowFrom(true);
        break;
      case intl.messages[
        'sipDetail.decisionPanelElements.directAccept'
      ]:
        setIsShowCc(true);
        setIsShowFrom(true);
        break;
      case intl.messages['sipDetail.decisionPanelElements.assignToEditor']:
        setIsShowFrom(true);
        setIsShowBcc(true);
        break;
    }
    let time = new Date();
    time.setDate(time.getDate() + 14);
    setRevisionDeadlineData(time);
    setAdditionCondition({
      ...setAdditionCondition,
      deadline: time,
    });
    dispatch(setSipDetailAllMandatorySubmitOrNextPressed(false));
    dispatch(setCurrentDecisionType(decisionType));
    if (
      sipStatus.sipStage == intl.messages['sipDetail.sipStageType.ideation']
    ) {
      geInformationManatoryValid(decisionType);
    }
  }, [decisionType]);

  const geInformationManatoryValid = (
    type = intl.messages['sipDetail.decisionPanelElements.moveToInitialReview']
  ) => {
    let allABCValid = true; // 用于标记a、b、c是否为空的条件是否对所有元素都成立
    let atLeastOneDTrue = false; // 用于标记是否至少有一个元素的d为true

    // 遍历数组中的每个对象
    for (let i = 0; i < geInformation?.length; i++) {
      const obj = geInformation[i];
      // 检查对象的属性a、b、c的值是否不为空
      if (
        type ==
        intl.messages['sipDetail.decisionPanelElements.moveToInitialReview'] &&
        (obj.institution == '' ||
          obj.country == '' ||
          obj.hasConflict == null ||
          (obj.profileWebsite == '' && obj.cv == null))
      ) {
        allABCValid = false; // 只要有一个元素的a、b、c为空，就标记为false
      }
      // 检查对象的属性d是否为true
      if (obj.leadGe === true) {
        atLeastOneDTrue = true; // 只要有一个元素的d为true，就标记为true
      }
    }

    // 如果a、b、c的条件对所有元素成立且d的条件对至少一个元素成立，则返回true，否则返回false
    setSSIPIdeationhasLeadGE(atLeastOneDTrue);
    return allABCValid && atLeastOneDTrue;
  };

  useEffect(() => {
    if(sipInformation==null){
      return;
    }
    // now, no matter in which stage, we need to validate all the fields
    const isUSIP = sipCode.startsWith("SIP");
    const openCallForPapersIsYes = contentCommissioningApproaches?.openCallForPapers === '1';

    // for USIP, no need to check the journal1Code and journal1Name
    let valid = isUSIP || (!isUSIP && !!object?.journal1Code && !!object?.journal1Name);
    
    valid &&= validateGEWithStageAndDecision(geInformation, sipStage, decisionType);
    valid &&= validateCommissionApproaches(contentCommissioningApproaches, sipStage, decisionType);
    valid &&= validateSipInformation(sipInformation, sipStage, decisionType, openCallForPapersIsYes);
    
    setSipDetailManatoryAllFilled(valid);
  }, [
    object,
    sipInformation,
    geInformation,
    contentCommissioningApproaches,
    decisionType,
  ]);

  // 获取Journal下拉的列表
  useEffect(() => {
    dispatch(getApproveJournalList(journalMutiSelected));
  }, []);

  const initDecisionPanel = () => {
    setComments('');
    setOperationsLead(null);
    setFlow(null);
    setDecisionType('');
    setRevisionDeadlineData(null);
    setAssignees([]);
    setATEJournal({ journalName: '' });
    setAssigneeEmail(null);
    setJournalMutiSelected([]);
  };

  const initAssignScreenerPanel = () => {
    setScreener(null);
    setCommentsAssignScreener('');
  };

  useMount(() => {
    initDecisionPanel();
    initAssignScreenerPanel();
  });

  // 点击Next的通用方法
  const handleNext = async () => {
    setIsNext(true);  // go to the next page
    dispatch(setSipDetailAllMandatorySubmitOrNextPressed(true));
    if (hasEmptyError) {
      return;
    }
    if (!sipDetailManatoryAllFilled) {
      handleManatoryAlert();
      return;
    }

    // setRevisionEmailOpen(true)
    setEmailPopUpOpen(true);
    setEmailLoading(true);
    const p = await new Promise(resolve =>
      dispatch(
        getEmailAllInformation(
          sipStage,
          decisionType,
          sipCode,
          comments,
          additionCondition,
          setButtonAlertButtonsSipChecklistNotProceeded,
          resolve,
          sipVersion,
          sipStatus
        )
      )
    ).catch(console.error);
    if (p === SIP_CHECKLIST_NOT_PROCEEDED_CODE) setEmailPopUpOpen(false);
    setEmailLoading(false);
  };

  // 关闭窗口要重新设置邮件内容， 以防用户切换decision打开 有上次decision邮件的内容
  // 并且清空邮件获取码
  const handleEmailClose = () => {
    setEmailPopUpOpen(false);
    setEmailBodyTmp(null);
    dispatch(clearGetEmailCode());
  };

  // 发送邮件成功后总是会进行的操作
  useUpdateEffect(() => {
    if (emailReturnCode == 200) {
      handleEmailClose();
      setPanelDisplayed(PANEL.NULL);
      setEmailBodyTmp(null);
      // 因为全局使用的是一个邮件状态 所以使用完之后 要清空邮件状态code
      dispatch(clearSendEmailCode());
      // 获取邮件的返回码也应该清空
      dispatch(clearGetEmailCode());
      dispatch(getSIPStatus(sipCode));
    }
  }, [emailReturnCode]);

  // 下面的两个useUpdateEffect 是用来监听 publisherInquiry(原JPMInquiry)阶段 journal和 Assignee的改变
  useUpdateEffect(() => {
    let journalCodes = journalMutiSelected.map((item, index, arr) => {
      return item.journalCode;
    });
    setAdditionCondition({
      ...additionCondition,
      journalCodes: journalCodes,
    });
  }, [journalMutiSelected]);

  // for api change, here not only need to add the assigneeEmail, but total assignee info
  // so get all assignees using selector, and get corresponding assignee by email
  const { tier2JPMInfo } = useSelector(state => {
    return {
      tier2JPMInfo: state.SIP.tier2JPMInfo,
    };
  });

  useUpdateEffect(() => {
    setAdditionCondition({
      ...additionCondition,
      assigneeEmail: assigneeEmail,
      assignee: tier2JPMInfo
        ?.filter(assignee => assignee.email === assigneeEmail)
        .at(0),
    });
  }, [assigneeEmail]);

  useUpdateEffect(() => {
    setAdditionCondition({
      ...additionCondition,
      handlingCe: handlingCEId,
    });
  }, [handlingCEId]);

  useUpdateEffect(() => {
    setAdditionCondition({
      ...additionCondition,
      flow: flow,
    });
  }, [flow]);
  useUpdateEffect(() => {
    setAdditionCondition({
      ...additionCondition,
      directAcquireReason: directAcquireReason,
    });
  }, [directAcquireReason]);
  useUpdateEffect(() => {
    setAdditionCondition({
      ...additionCondition,
      selectedReasons: selectedReasons,
    });
  }, [selectedReasons]);

  useEffect(() => {
    if (
      decisionType ===
      intl.messages['sipDetail.decisionPanelElements.assignToEditor']
    ) {
      setAdditionCondition({
        journalEditors: assignees,
        journal: ateJournal ? ateJournal.journalCode : '',
      });
    }
  }, [assignees, ateJournal]);

  useEffect(() => {
    if (updateSipShevelCode === 200) {
      dispatch(getSIPStatus(sipCode));
      dispatch(clearSIPSheveledCode());
    }
  }, [updateSipShevelCode]);

  // set Decision panel status as "Saved"
  useEffect(() => {
    setIsDecisionSaved(
      comments !== '' || (decisionType !== '' && !isDecisionTypeListLength1) //
    );
  }, [comments, decisionType, isDecisionTypeListLength1]);

  // set "Assign Screener" panel status as "Saved"
  useEffect(() => {
    let isEdited = false;
    if (commentsAssignScreener !== '') {
      isEdited = true;
    }
    if (screenerId !== undefined && screenerId !== currentSreener?.userId) {
      isEdited = true;
    }
    setIsAssignScreenerSaved(isEdited);
  }, [commentsAssignScreener, screenerId, currentSreener?.userId]);

  const isValidUser = target => {
    const targetToMatch = target ? target : fromOfEmail;
    let pattern = /.*@wiley.com$/;
    return pattern.test(targetToMatch);
  };

  const handleEmailBack = () => {
    setSeverity('warning');
    setButtonAlertTitle(intl.messages['sipDetail.emailBackWarningTitle']);
    setButtonAlertMessage(intl.messages['sipDetail.emailBackWarningMessage']);
    setButtonAlertAutoHideDuration(null);
    setButtonAlertButtons([
      {
        text: intl.messages['sipDetail.emailBackWarningKeepButton'],
        onClick: () => {
          setButtonAlertOpen(false);
          setButtonAlertAutoHideDuration(null);
        },
      },
      {
        text: intl.messages['sipDetail.emailBackWarningBackButton'],
        onClick: () => {
          dispatch({ type: SET_EMAIL_ALL, data: null });
          setButtonAlertOpen(false);
          setIsNext(false);
          setButtonAlertAutoHideDuration(null);
          handleEmailClose();
        },
      },
    ]);
    setButtonAlertOpen(true);
  };

  const { alertMessage, buttonText1, buttonText2 } = useMemo(() => {
    return useSendEmailAlertHook(sipStage, decisionType, sipStatus?.sipOrigin);
  }, [sipStage, decisionType, sipStatus?.sipOrigin]);

  const handleEmailCreate = e => {
    setSeverity('warning');
    setButtonAlertTitle('Send this email?');
    setButtonAlertMessage(alertMessage);
    setButtonAlertAutoHideDuration(null);
    setButtonAlertButtons([
      {
        text: buttonText1,
        onClick: () => {
          setButtonAlertAutoHideDuration(null);
          setButtonAlertOpen(false);
        },
      },
      {
        text: buttonText2,
        onClick: () => {
          handleEmailSubmit(e);
          setButtonAlertAutoHideDuration(null);
          setIsNext(false);
          setButtonAlertOpen(false);
        },
      },
    ]);
    setButtonAlertOpen(true);
  };

  const handleManatoryAlert = e => {
    setSeverity('warning');
    setButtonAlertTitle('You cannot make this decision yet.');
    setButtonAlertMessage('Please fill above mandatory fields firstly.');
    setButtonAlertAutoHideDuration(3000);
    setButtonAlertOpen(true);
    setButtonAlertButtons([]);
  };

  const handleCancel = () => {
    if (isSelectedTransferBackToJPA) {
      handleTransferBackToJPABack();
      return;
    }
    if (panelDisplayed === PANEL.DECISION_PANEL) {
      initDecisionPanel();
    } else if (panelDisplayed === PANEL.ASSIGN_SCREENER_PANEL) {
      initAssignScreenerPanel();
    }
    setPanelDisplayed(PANEL.NULL);
  };

  const handleTransferBackToJPABack = () => {
    setSeverity('warning');
    setButtonAlertTitle(
      intl.messages['sipDetail.transferBackToJPACancelTitle']
    );
    setButtonAlertMessage(
      intl.messages['sipDetail.transferBackToJPACancelMessage']
    );
    setButtonAlertAutoHideDuration(null);
    setButtonAlertButtons([
      {
        text: intl.messages['sipDetail.transferBackToJPACancelKeepButton'],
        onClick: () => {
          setButtonAlertOpen(false);
          setButtonAlertAutoHideDuration(null);
        },
      },
      {
        text: intl.messages['sipDetail.transferBackToJPACancelCancelButton'],
        onClick: () => {
          setButtonAlertOpen(false);
          setPanelDisplayed(PANEL.NULL);
          setDecisionType('');
          setComments('');
          setIsNext(false);
          setButtonAlertAutoHideDuration(null);
        },
      },
    ]);
    setButtonAlertOpen(true);
  };

  const handleTransferBackToJPASubmit = e => {
    setSeverity('warning');
    setButtonAlertTitle(
      intl.messages['sipDetail.transferBackToJPASubmitTitle']
    );
    setButtonAlertMessage(
      intl.messages['sipDetail.transferBackToJPASubmitMessage']
    );
    setButtonAlertAutoHideDuration(null);
    setButtonAlertButtons([
      {
        text: intl.messages['sipDetail.transferBackToJPASubmitKeepButton'],
        onClick: () => {
          setButtonAlertOpen(false);
          setButtonAlertAutoHideDuration(null);
        },
      },
      {
        text: intl.messages['sipDetail.transferBackToJPASubmitSubmitButton'],
        onClick: () => {
          handleEmailSubmit(e);
          setButtonAlertOpen(false);
          setButtonAlertAutoHideDuration(null);
          setIsNext(false);
          initDecisionPanel();
        },
      },
    ]);
    setButtonAlertOpen(true);
  };

  const handleTransferToAnotherSubmit = e => {
    setSeverity('warning');
    setButtonAlertTitle(
      intl.messages['sipDetail.transferBackToJPASubmitTitle']
    );
    setButtonAlertMessage(
      intl.messages['sipDetail.transferBackToJPASubmitMessage']
    );
    setButtonAlertAutoHideDuration(null);
    setButtonAlertButtons([
      {
        text: intl.messages['sipDetail.transferBackToJPASubmitKeepButton'],
        onClick: () => {
          setButtonAlertOpen(false);
          setButtonAlertAutoHideDuration(null);
        },
      },
      {
        text: intl.messages['sipDetail.transferBackToJPASubmitSubmitButton'],
        onClick: async () => {
          const p = await new Promise(resolve =>
            dispatch(
              sendEmailBody(
                sipStage,
                decisionType,
                sipCode,
                comments,
                emailBodyTmp,
                additionCondition,
                attachments,
                setButtonAlertButtonsSipChecklistNotProceeded,
                resolve,
                sipVersion,
                sipStatus,
                null,
                subjectOfEmail,
                (toOfEmail),
                (ccOfEmail),
              )
            )
          ).catch(console.error);
          if (p === SIP_CHECKLIST_NOT_PROCEEDED_CODE) return;
          setButtonAlertOpen(false);
          setButtonAlertAutoHideDuration(null);
          initDecisionPanel();
          setIsNext(false);
        },
      },
    ]);
    setButtonAlertOpen(true);
  };

  const getLeftHeight = () => {
    if (revisionDateOpen) {
      return '400px';
    }
    if (handlingCEOpen) {
      return '342px';
    }
    if (rejectSelect) {
      return '400px';
    }
    if (
      isShowRevisionDeadline ||
      isShowHandlingCE ||
      isShowAssigneeChoice ||
      decisionType ===
      intl.messages['sipDetail.decisionPanelElements.assignToEditor']
    ) {
      return '120px';
    }
    if (
      decisionType === intl.messages['sipDetail.decisionPanelElements.reject']
    ) {
      return '0px';
    }
    return '41px';
  };
  function codeValue(input) {
    if (input === null || input === undefined) {
      return '';
    }
    const date = new Date(input);
    if (isNaN(date.valueOf())) {
      return 'Invalid Date';
    }
    return moment(date).utc().format('YYYY-MM-DD');
  }

  const [popperBottom, setPopperBottom] = useState(0);

  const [temBoxHeight, setTemBoxHeight] = useState(0);

  const currentDate = new Date();

  const twoWeeksLater = new Date();
  twoWeeksLater.setDate(currentDate.getDate() + 14);
  const newYear = twoWeeksLater.getFullYear();
  const newMonth = (twoWeeksLater.getMonth() + 1).toString().padStart(2, '0');
  const newDay = twoWeeksLater.getDate().toString().padStart(2, '0');
  const systemAdminOrTierOneUserRejectDropList = [
    'Incomplete Information',
    'False Information',
    'Unethical Behaviors',
    'AI-generated SIP',
    'Duplicated SI/SIP Records',
    'Others',
  ];
  const sipFlowDropList = [
    'Direct Acquire',
    'Commissioning Editor Review',
    'EBM Review',
  ];
  const [isOthersSelected, setIsOthersSelected] = useState(false);

  const canSubmit = useMemo(() => {
    if (panelDisplayed === PANEL.ASSIGN_SCREENER_PANEL) {
      const screenerValid = screenerId !== undefined && screenerId !== null;
      const commentsValid = commentsAssignScreener.length <= 100000;
      return screenerValid && commentsValid;
    }
    if (hasEmptyError) return false;
    if (isOthersSelected && !hasValue(comments)) return false;
    if (emailPopUpOpen && !isValidUser()) return false;
    if (sipDetailAllMandatorySubmitOrNextPressed && !sipDetailManatoryAllFilled)
      return false;
    if (isDecisionButtonDisabled) return false;
    return true;
  }, [
    hasEmptyError,
    isOthersSelected,
    comments,
    emailPopUpOpen,
    hasValue,
    isValidUser,
    panelDisplayed,
    sipDetailAllMandatorySubmitOrNextPressed,
    sipDetailManatoryAllFilled,
    isDecisionButtonDisabled,
    screenerId,
  ]);

  useEffect(() => {
    if (!popperBottom) {
      setTemBoxHeight(0);
    }
    let docHeight = document.documentElement.scrollHeight;
    if (popperBottom + 68 > docHeight) {
      setTemBoxHeight(popperBottom + 68 - docHeight);
    }
  }, [popperBottom]);

  const ShowDecisionAllMandatory = useMemo(() => {
    return ((!hasValue(decisionType) && (panelDisplayed === PANEL.ASSIGN_SCREENER_PANEL)) ||
      (isShowJounrnalMultiSelect &&
        !hasValue(journalMutiSelected)) ||
      (isShowHandlingCE && !hasValue(handlingCEId)) ||
      (isShowAssigneeChoice && !hasValue(assigneeEmail)) ||
      (decisionType === intl.messages['sipDetail.decisionPanelElements.assignToEditor'] &&
        (ateJournal === null || assignees.length === 0)));
  }, [decisionType, comments, isShowJounrnalMultiSelect, journalMutiSelected, isShowHandlingCE, handlingCEId,
    isShowAssigneeChoice, assigneeEmail, ateJournal, assignees, panelDisplayed]);

  const showNextBtn = useMemo(() => {
    if (panelDisplayed === PANEL.ASSIGN_SCREENER_PANEL) return false;
    return (
      isSelectedWithdraw ||
      isSelectedCancelJPMInquery ||
      isSelectedCancelEditorReview ||
      isSelectedJPMInquery ||
      (isSelectedReject && sipStage === 'Ideation') ||
      decisionType ===
      intl.messages[
      'sipDetail.decisionPanelElements.revise'
      ] ||
      decisionType ===
      intl.messages[
      'sipDetail.decisionPanelElements.isInitialReviewCancelRevision'
      ] ||
      decisionType ===
      intl.messages[
      'sipDetail.decisionPanelElements.cancelRevision'
      ] ||
      decisionType ===
      intl.messages[
      'sipDetail.decisionPanelElements.assignToEditor'
      ] ||
      decisionType ===
      intl.messages[
      'sipDetail.decisionPanelElements.moveToSubmitted'
      ] ||
      decisionType ===
      intl.messages[
      'sipDetail.decisionPanelElements.moveToCommissioningEditorReview'
      ] ||
      decisionType ===
      intl.messages[
      'sipDetail.decisionPanelElements.directAccept'
      ]
    );
  }, [panelDisplayed,
     isSelectedRevise,
     isSelectedWithdraw,
     isSelectedCancelJPMInquery,
     isSelectedCancelEditorReview,
     isSelectedJPMInquery,
     isSelectedReject,
     sipStage,
    decisionType
  ]);

  const showCancelSubmitBtn = useMemo(() => {
    // assign to the original screener is protected from backend.
    if (panelDisplayed === PANEL.ASSIGN_SCREENER_PANEL) {
      if (screenerId !== undefined && screenerId !== null) {
        return currentSreener?.userId !== screenerId;
      } else {
        return false;
      }
    }
    if (sipStage === intl.messages['sipDetail.sipStageType.submitted'] && isSelectedReject) {
      return true;
    }
    if (isSelectedAssignToCE) return true;
    if (isSelectedTransferToAnother) return true;
    if ((isSelectedReject && sipStage !== 'Ideation')) return true;
    if (isSelectedTransferBackToJPA) return true;
    if (decisionType === intl.messages['sipDetail.decisionPanelElements.moveToInitialReview']) {
      return true;
    }
    if (decisionType === 'Move to EBM Review') {
      return true;
    }
    return false;
  }, [sipStage, decisionType, isSelectedReject, isSelectedAssignToCE, isSelectedTransferToAnother, isSelectedTransferBackToJPA,
    panelDisplayed, screenerId]);

  const commentsPlaceholder = useMemo(() => {
    switch (decisionType) {
      case intl.messages['sipDetail.decisionPanelElements.moveToCommissioningEditorReview']:
        return 'Please make a recommendation based on the integrity screening results.';
    }
    return '';
  }, [decisionType]);

  const isEmailREditable = useMemo(() => {
    return (sipStage === "Initial Review" && decisionType === "Revise") ||
      (sipStage === "EBM Review" && decisionType === "Revise");
  }, [sipStage, decisionType]);


  return (
    <div
      style={{
        ...DecisionContainer,
        ...props.style,
        ...(scaleToUse < 1 && {
          transform: `scale(${scaleToUse}, ${scaleToUse})`,
          transformOrigin: 'left bottom',
        }),
      }}
    >
      <div style={popContainer}>

        {/* Decision Btns when Unfolded */}
        <div style={buttonContainer}>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              height: '100%',
            }}
          >
            <Stack
              direction='row'
              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-DownStack'
              spacing={3}
            >
              {/* Assign to screener button */}
              {showAssignToScreenerBtn && (
                <DecisionUnfoldBtnWithSave
                  endIcon={
                    (panelDisplayed === PANEL.ASSIGN_SCREENER_PANEL)
                      // should be up icon
                      ? <EndButtonArrowUpBlue data-selenium-id='SIP_DetailPage_DecisionSection-Unfold-Assign_Screener-EndButtonArrowUnfold-Up'></EndButtonArrowUpBlue>
                      // should be down icon
                      : <EndButtonArrowDownBlue data-selenium-id='SIP_DetailPage_DecisionSection-Unfold-Assign_Screener-EndButtonArrowUnfold-Down'></EndButtonArrowDownBlue>
                  }
                  onClick={() => {
                    togglePanelDisplay(PANEL.ASSIGN_SCREENER_PANEL);
                  }}
                  saving={isAssignScreenerSaved}
                >
                  <FormattedMessage
                    id='sipDetail.decisionPanelElements.assignToScreener'
                    data-selenium-id='SIP_DetailPage_DecisionSection-Unfold-assignToScreener'
                  />
                </DecisionUnfoldBtnWithSave>
              )}

              {/* Checklist button */}
              {showCheckListBtn && (
                <CheckListButton {...{ sipCode, sipStage }} />
              )}

              {/* Decision button */}
              <DecisionUnfoldBtnWithSave
                onClick={() => {
                  togglePanelDisplay(PANEL.DECISION_PANEL);
                }}
                saving={comments || (decisionType && !isDecisionTypeListLength1)}
                id='DecisionButton'
                data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-Button'
                variant='contained'
                endIcon={
                  (panelDisplayed === PANEL.DECISION_PANEL)
                    // should be up icon
                    ? <EndButtonArrowUpBlue data-selenium-id='SIP_DetailPage_DecisionSection-Unfold-Decision-EndButtonArrowUnfold-up'></EndButtonArrowUpBlue>
                    // should be down icon
                    : <EndButtonArrowDownBlue data-selenium-id='SIP_DetailPage_DecisionSection-Unfold-Decision-EndButtonArrowUnfold-down'></EndButtonArrowDownBlue>
                }
                disabled={isDecisionButtonDisabled}
              >
                <FormattedMessage
                  id='siDetail.decision'
                  data-selenium-id='SIP_DetailPage_Section-Unfole-DownStack-Decision'
                />
              </DecisionUnfoldBtnWithSave>
            </Stack>
          </div>
        </div>

        {/* Email PopUp */}
        <Stack
          spacing={0}
          data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-Stack'
          maxWidth={'1092px'}
        >
          {emailPopUpOpen ? (
            <>
              <EmailContainer
                data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-EmailModal'
                emailLoading={emailLoading}
                emailTemplate={emailBodyTmp}
                setEmailTemplate={setEmailBodyTmp}
                attachments={attachments}
                setAttachments={setAttachments}
                changeMonitor={emailBodyTmp || emailBody}
                subject={subjectOfEmail}
                setSubject={setSubjectOfEmail}
                // CT-7517
                isSubjectEditable={isEmailREditable}
                from={fromOfEmail}
                to={isEmailREditable ? toOfEmail : formatEmailReceiptants(toOfEmail)}
                setTo={setToOfEmail}
                // CT-7517
                isToEditable={isEmailREditable}
                cc={isEmailREditable ? ccOfEmail : formatEmailReceiptants(ccOfEmail)}
                setCc={setCcOfEmail}
                // CT-7517
                isCcEditable={isEmailREditable}
                bcc={isEmailREditable ? bccOfEmail : formatEmailReceiptants(bccOfEmail)}
                isShowBcc={isShowBcc}
                isShowCc={isShowCc}
                isShowFrom={isShowFrom}
              />
              <Stack
                sx={{
                  display: 'flex',
                  marginTop: '24px',
                  marginBottom: '15px',
                }}
                direction='row'
                justifyContent='flex-end'
                spacing={1.125}
              >
                <CancelBtn
                  id='decisionEmailCancelButton'
                  sx={CancelBtnCss}
                  onClick={handleEmailBack}
                  disabled={isDecisionButtonDisabled}
                >
                  Back
                </CancelBtn>

                {/* Ideation is moved to /SIPDetailPage/DecisionPanel !! */}
                {/* {sipStage === 'Ideation' &&
                  (decisionType == 'Reject' || decisionType == 'Withdraw') ? (
                  <SubmitBtn
                    id='decisionEmailSubmitButton'
                    sx={exportBtnCss}
                    variant='contained'
                    onClick={() => {
                      setAdditionCondition({
                        ...setAdditionCondition,
                        selectedReasons: rejectReason,
                      });
                      dispatch(
                        sendEmailBody(
                          sipStage,
                          decisionType,
                          sipCode,
                          comments,
                          ssipIdeationhasLeadGE
                            ? {
                              title: emailAllInformation.data.title,
                              content: emailAllInformation.data.content,
                              from: emailAllInformation.data.from,
                              fromNameAndEmail:
                                emailAllInformation.data.fromNameAndEmail,
                              to: emailAllInformation.data.to,
                              toNameAndEmail:
                                emailAllInformation.data.toNameAndEmail,
                              copy: emailAllInformation.data.copy,
                              copyNameAndEmail:
                                emailAllInformation.data.copyNameAndEmail,
                              bcc: emailAllInformation.data.bcc,
                              bccNameAndEmail:
                                emailAllInformation.data.bccNameAndEmail,
                              mailAttachments:
                                emailAllInformation.data.mailAttachments,
                              mailAttachmentIds:
                                emailAllInformation.data.mailAttachmentIds,
                              requireAttachment:
                                emailAllInformation.data.requireAttachment,
                            }
                            : null,
                          additionCondition,
                          attachments,
                          setButtonAlertButtonsSipChecklistNotProceeded,
                          () => { },
                          sipVersion,
                          sipStatus,
                          subject = subjectOfEmail,
                          to = toOfEmail,
                          cc = ccOfEmail,
                        )
                      );
                    }}
                    disabled={
                      !isValidUser() ? true : false || isDecisionButtonDisabled
                    }
                  >
                    Submit
                  </SubmitBtn>
                ) : ( */}
                <SubmitBtn
                  id='decisionEmailSubmitButton'
                  sx={exportBtnCss}
                  variant='contained'
                  onClick={handleEmailCreate}
                  disabled={
                    toOfEmail === null ||
                      toOfEmail === [] ||
                      toOfEmail === '' ||
                      formatEmailReceiptants(toOfEmail) === 'N/A' ||
                      !isValidUser()
                      ? true
                      : false || isDecisionButtonDisabled
                  }
                >
                  Create
                </SubmitBtn>
                {/* )} */}
              </Stack>
            </>
          ) : (
            <>
              {/* 用来区别decision 和 JournalSelect的模块 */}
              <Stack
                data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-InnerStack'
                direction='column'
                justifyContent='flex-start'
                sx={{ height: '100%' }}
              >
                {/* Decision头 */}
                <Stack
                  data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-HeadStack'
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='center'
                  sx={{ height: '38.6px', mt: '5.9px' }}
                >
                  {/* 头部左边 */}
                  <Stack
                    data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-LeftStack1'
                    direction='row'
                    justifyContent='flex-start'
                    spacing={1}
                    sx={{
                      height: '24px',
                      alignItems: 'center',
                      padding: '0px',
                    }}
                  >
                    {/* Decision Title */}
                    <Box
                      data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-Box1'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '66px',
                        height: '24px',
                        fontFamily: 'Open Sans',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: '#113D95',
                        textTransform: 'capitalize',
                      }}
                    >
                      {' '}
                      {panelDisplayed === PANEL.ASSIGN_SCREENER_PANEL ? (
                        'Assign'
                      ) : (
                        <FormattedMessage
                          id='siDetail.decision'
                          data-selenium-id='SIP_DetailPage_Section-Unfole-LeftStack-Decision'
                        />
                      )}
                    </Box>

                    {/* Show decisionAllMandatory */}
                    {isNext && ShowDecisionAllMandatory && (
                      <Box
                        data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-Box2'
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          height: '16px',
                          fontFamily: 'Open Sans',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '16px',
                          letterSpacing: '-0.084px',
                          color: '#EE5350',
                          textTransform: 'none',
                        }}
                      >
                        {intl.messages['sipDetail.decisionAllMandatory']}
                      </Box>
                    )}
                  </Stack>

                  {/* Flow selection Message */}
                  <Stack>
                    {sipStage === 'Submitted' &&
                      sipInformation?.sipType === 'Yes' && (
                        <Box
                          sx={{
                            display: 'flex',
                            height: '16px',
                          }}
                        >
                          <SimpleIconButton
                            DefaultIcon={FlowWarnDefault}
                            isDisabled={true}
                          />
                          <Typography
                            sx={{
                              fontSize: '12px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: '16px',
                            }}
                          >
                            {`CE has selected '${sipFlow}' as Flow Attribute at Ideation stage.`}
                          </Typography>
                        </Box>
                      )}
                  </Stack>
                </Stack>

                {/* Decision Panel Form */}
                <Stack
                  data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-CenterStack'
                  direction='row'
                  justifyContent='space-between'
                  flexWrap='wrap'
                >
                  {/* Pancel Left side */}
                  {/* to find out */}
                  <GetPopperBottomContext.Provider  // ?
                    value={{ setValue: setPopperBottom }}
                  >
                    <Stack
                      data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-CenterStack-Left'
                      direction='column'
                      justifyContent='flex-start'
                      spacing={2}
                      height={getLeftHeight()}
                    >
                      {/* this is decision type */}
                      {(panelDisplayed === PANEL.DECISION_PANEL) &&
                        (<DecisionTypeBlock
                          decisionType={decisionType}
                          handleDecisionTypeChange={handleDecisionTypeChange}
                          emptyError={isNext && !hasValue(decisionType)}
                        ></DecisionTypeBlock>
                        )}

                      {/* this is HandlingCE ? */}
                      {(panelDisplayed === PANEL.DECISION_PANEL) && isShowHandlingCE && (
                        <FormControl data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl1'>
                          <Stack
                            direction='row'
                            data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl1-Stack'
                          >
                            <DecisionTitle
                              id='HandingCETitle'
                              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-HandingCETitle'
                              title='Commissioning Editor'
                              width='130px'
                            ></DecisionTitle>

                            <Box
                              sx={{
                                width: '286px',
                                height: '40px',
                                backgroundColor: '#FFFFFF',
                              }}
                            >
                              <UserSelectInput
                                disabled={
                                  sipInformation?.sipType === 'Yes' &&
                                  decisionType === intl.messages['sipDetail.decisionPanelElements.moveToCommissioningEditorReview'] &&
                                  sipStage === intl.messages['sipDetail.sipStageType.initialReview']
                                }
                                permissionCode={PERMISSIONS.SIP_WRITE_HANDLING_CE}
                                user={handlingCE ?? currentCE} // prefill the current CE if has one
                                update={setHandingCE}
                              />
                            </Box>
                          </Stack>
                        </FormControl>
                      )}

                      {/* this is assignToEditor - Assignee */}
                      {!isShowAssigneeChoice ? (
                        <></>
                      ) : (
                        <FormControl data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl2'>
                          <Stack
                            direction='row'
                            justifyContent='space-between'
                            data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl2-Stack'
                            spacing={0}
                          >
                            <DecisionTitle
                              id='AssigneeTitle'
                              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-AssigneeTitle'
                              title='Assignee'
                              width='123px'
                            ></DecisionTitle>
                            <Stack
                              width='286px'
                              height='36px'
                              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl2-InnerStack'
                            >
                              <AssigneeSelectedInput
                                value={assigneeEmail}
                                setSelectedJPM={setAssigneeEmail}
                                open={assigneeOpen}
                                setOpen={setAssigneeOpen}
                                emptyError={isNext && !hasValue(assigneeEmail)}
                              />
                            </Stack>
                          </Stack>
                        </FormControl>
                      )}

                      {/* this is assignToEditor - Revision Deadline Date Picker */}
                      {!isShowRevisionDeadline ? (
                        <></>
                      ) : (
                        <FormControl data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl3'>
                          <Stack
                            direction='row'
                            justifyContent='space-between'
                            data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl3-Stack'
                          >
                            <DecisionTitle
                              id='RevisionDeadlineTitle'
                              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-RevisionDeadlineTitle'
                              title='Revision Deadline'
                              width='101px'
                            ></DecisionTitle>
                            <Stack
                              width='286px'
                              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl3-InnerStack'
                            >
                              <CustomDatePicker
                                id={'Decision-DatePicker'}
                                placeholder={`${newYear}-${newMonth}-${newDay}`}
                                value={codeValue(revisionDeadlineData)}
                                onChange={newValue => {
                                  setRevisionDeadlineData(newValue);
                                  setAdditionCondition({
                                    ...setAdditionCondition,
                                    deadline: newValue,
                                  });
                                }}
                                handleFocus={() => { }}
                                handleBlur={() => { }}
                                ifdataOpen={true}
                                dateOpen={revisionDateOpen}
                                setDateOpen={setRevisionDateOpen}
                              />
                            </Stack>
                          </Stack>
                        </FormControl>
                      )}

                      {/* this is assignToEditor - Decision Type */}
                      {decisionType !==
                        intl.messages[
                        'sipDetail.decisionPanelElements.assignToEditor'
                        ] || sipInformation?.sipType !== 'Yes' ? (
                        <></>
                      ) : (
                        <Box data-selenium-id='SSIP_DetailPage_DecisionSection-Unfole-AssigneeBox'>
                          <Assignee
                            assignees={assignees}
                            setAssignees={setAssignees}
                          ></Assignee>
                        </Box>
                      )}

                      {/* this is assignToEditor - CE selection*/}
                      {decisionType !==
                        intl.messages[
                        'sipDetail.decisionPanelElements.assignToEditor'
                        ] || sipInformation?.sipType === 'Yes' ? (
                        <></>
                      ) : (
                        <FormControl data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl4'>
                          <Stack
                            direction='row'
                            height='50px'
                            data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl4-Stack'
                          >
                            <DecisionTitle
                              id='AssigntoEditorTitle'
                              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-AssigntoEditorTitle'
                              title='Journal'
                              width='123px'
                            />
                            <Box
                              sx={{
                                width: '286px',
                                height: '40px',
                                backgroundColor: '#FFFFFF',
                              }}
                            >
                              <SinpleAutocomplete
                                id='AssigntoEditorSelect'
                                data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-AssigntoEditorSelect'
                                loadOptionAction={getJournalListWithName}
                                inputValue={ateJournalInputValue}
                                isErr={isNext && !hasValue(ateJournal)}
                                value={ateJournal}
                                placeholder='Select Journal'
                                options={JourListResult}
                                getOptionLabel={option => option.journalName}
                                onChange={(e, newValue) => {
                                  setATEJournal(newValue);
                                  setATEJournalInputValue(
                                    newValue ? newValue.journalName : ''
                                  );
                                }}
                                isEmpty={!hasValue(ateJournal)}
                                needClearInputValue={true}
                                //
                                needKeyboardDelete={false}
                              />
                            </Box>
                          </Stack>
                        </FormControl>
                      )}

                      {/* this is directAccept */}
                      {decisionType === intl.messages['sipDetail.decisionPanelElements.directAccept'] &&
                        (panelDisplayed === PANEL.DECISION_PANEL) && (
                          <FormControl data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl6'>
                            <Stack
                              direction='row'
                              height='50px'
                              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl6-Stack'
                            >
                              <DecisionTitle
                                id='DirectAcquireTitle'
                                data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-directAcquireTitle'
                                title='Direct Acquire Reason'
                                width='123px'
                              />
                              <Box
                                sx={{
                                  width: '286px',
                                  height: '40px',
                                  backgroundColor: '#FFFFFF',
                                }}
                              >
                                <SinpleAutocomplete
                                  id='DirectAcquireSelect'
                                  data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-DirectAcquireSelect'
                                  isErr={isNext && !hasValue(directAcquireReason)}
                                  value={directAcquireReason}
                                  sx={vars}
                                  placeholder='Select Direct Acquire Reason'
                                  options={[
                                    'Accepted by Journal Editor already',
                                    'Others',
                                  ]}
                                  onChange={(e, value) => {
                                    setDirectAcquireReason(value);
                                    setDirectAcquireReasonSelect(true);
                                    if (value.includes('Others'))
                                      setIsOthersSelected(true);
                                    else setIsOthersSelected(false);
                                  }}
                                  defaultQuery={true}
                                  isEmpty={!hasValue(directAcquireReason)}
                                  needClearInputValue={true}
                                  readOnly={true}
                                  needKeyboardDelete={false}
                                />
                              </Box>
                            </Stack>
                          </FormControl>
                        )}

                      {/* this is Reject */}
                      {decisionType !==
                        intl.messages[
                        'sipDetail.decisionPanelElements.reject'
                        ] ? (
                        <></>
                      ) : (
                        <FormControl data-selenium-id='SIP_DetailPage_DecisionSection-Unfold-FormControl5'>
                          <Stack
                            direction='row'
                            height='50px'
                            data-selenium-id='SIP_DetailPage_DecisionSection-Unfold-FormControl5-Stack'
                          >
                            <DecisionTitle
                              id='rejectTitle'
                              data-selenium-id='SIP_DetailPage_DecisionSection-Unfold-rejectTitle'
                              title='Reject Reason(s)'
                              width='123px'
                              white-space='no-warp'
                            />
                            <Box
                              sx={{
                                width: '286px',
                                height: '40px',
                                backgroundColor: '#FFFFFF',
                              }}
                            >
                              <MulSelectInput
                                id='JournalMutiSelect-AutoComplete'
                                width='286px'
                                sx={vars}
                                value={rejectReason}
                                loadOptionAction={getUserRejectReasonDropList}
                                options={systemAdminOrTierOneUserRejectDropList}
                                placeholder={'Select Reject Reason'}
                                showStartIcon={false}
                                disableNewItem={true}
                                disableCheckBox={false}
                                showPopupIcon={true}
                                onOpen={() => {
                                  setRejectSelect(true);
                                  setTimeout(() => {
                                    document.body.classList.add('hide-scroll');
                                    document.querySelector(
                                      '[data-selenium-id="AuthPage-OutletGrid"]'
                                    ).style.overflow = 'hidden';
                                  }, 100);
                                }}
                                onClose={() => {
                                  setRejectSelect(false);
                                  setTimeout(() => {
                                    document.body.classList.remove(
                                      'hide-scroll'
                                    );
                                    document.querySelector(
                                      '[data-selenium-id="AuthPage-OutletGrid"]'
                                    ).style.overflow = 'auto';
                                  }, 100);
                                }}
                                onValueChange={newValue => {
                                  setRejectReason(newValue);
                                  setSelectedReasons(newValue);
                                  if (newValue.includes('Others'))
                                    setIsOthersSelected(true);
                                  else setIsOthersSelected(false);
                                  if (
                                    newValue.length === 0 ||
                                    comments.length > 100000
                                  )
                                    setHasEmptyError(true);
                                  else setHasEmptyError(false);
                                }}
                              />
                            </Box>
                          </Stack>
                        </FormControl>
                      )}

                      {/* this is Operations Lead */}
                      {isShowOperationsLead && (
                        <FormControl data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl7'>
                          <Stack
                            direction='row'
                            justifyContent='space-between'
                            data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl7-Stack'
                          >
                            <DecisionTitle
                              id='OperationsLeadTitle'
                              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-OperationsLeadTitle'
                              title='Operations Lead'
                              width='130px'
                            />

                            <Box
                              sx={{
                                width: '286px',
                                height: '40px',
                                backgroundColor: '#FFFFFF',
                              }}
                            >
                              <UserSelectInput
                                permissionCode={PERMISSIONS.SIP_WRITE_OPS}
                                user={operationsLead}
                                update={setOperationsLead}
                              />
                            </Box>
                          </Stack>
                        </FormControl>
                      )}

                      {/* this is Assign to Screener */}
                      <FormControl data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl8'
                        style={(panelDisplayed === PANEL.ASSIGN_SCREENER_PANEL) ? {} : { display: 'none' }}
                      >
                        <Stack
                          direction='row'
                          justifyContent='space-between'
                          data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl8-Stack'
                        >
                          <DecisionTitle
                            id='AssignToScreener'
                            data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-AssignToScreener'
                            title='Screener'
                            width='130px'
                          />
                          <Box
                            sx={{
                              width: '286px',
                              height: '40px',
                              backgroundColor: '#FFFFFF',
                            }}
                          >
                            <UserSelectInput
                              permissionCode={PERMISSIONS.SIP_WRITE_SCREENER}
                              user={screener ?? currentSreener} // prefill the current screener if has one
                              update={setScreener}
                            />
                          </Box>
                        </Stack>
                      </FormControl>

                      {/* this is Flow Selection */}
                      {isShowFlow && (
                        <FormControl data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormContro18'>
                          <Stack
                            direction='row'
                            justifyContent='space-between'
                            data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormControl8-Stack'
                          >
                            <DecisionTitle
                              id='FlowTitle'
                              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FlowTitle'
                              title='Flow'
                              width='130px'
                            />

                            <Box
                              sx={{
                                width: '286px',
                                height: '40px',
                                backgroundColor: '#FFFFFF',
                              }}
                            >
                              <SelectInput
                                id='JournalMutiSelect-AutoComplete'
                                width='286px'
                                sx={vars}
                                value={flow}
                                options={sipFlowDropList}
                                placeholder={'Select Reject Reason'}
                                onValueChange={setFlow}
                                disableClearIcon={true}
                              />
                            </Box>
                          </Stack>
                        </FormControl>
                      )}

                    </Stack>
                  </GetPopperBottomContext.Provider>

                  {/* Pancel Right side */}
                  <>
                    {/* Decision Panel Comments */}
                    {(panelDisplayed === PANEL.DECISION_PANEL) && (<Stack
                      data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-RightStack'
                      direction={'row'}
                      justifyContent='space-between'
                      flexWrap='wrap'
                      spacing={1}
                    >
                      <DecisionTitle
                        id='Comments'
                        data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-Comments'
                        title='Comments'
                        width='82px'
                        paddingRight='8'
                        isOthersSelected={isOthersSelected}
                        decisionType={decisionType}
                      ></DecisionTitle>
                      <FormControl>
                        <MyOnlyText
                          id='CommentsText'
                          data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-CommentsText'
                          placeholder={commentsPlaceholder}
                          multiline={true}
                          width='420px'
                          height='101px'
                          value={comments}
                          setValue={setComments}
                          isError={comments.length > 100000 && !focusComments}
                          errorMessage={
                            'Please input no more than 100,000 characters.'
                          }
                          onFocus={() => {
                            setFoucusComments(true);
                          }}
                          onBlur={() => {
                            setFoucusComments(false);
                          }}
                          minRows={5}
                          maxRows={5}
                        />
                        <FormHelperText
                          data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormHelperText'
                          sx={{
                            textAlign: 'right',
                            margin: '0px',
                            marginTop:
                              comments.length > 100000 && !focusComments
                                ? '-14px'
                                : '4px',
                            fontSize: '12px',
                            lineHeight: '18px',
                            height: '15px',
                            color:
                              comments.length > 100000 ? '#DE350B' : '#596A7C',
                            marginBottom: '3px',
                          }}
                        >
                          {comments.length}/100,000
                        </FormHelperText>
                      </FormControl>
                    </Stack>)}

                    {/* Assign Screener Panel Comments */}
                    {(panelDisplayed === PANEL.ASSIGN_SCREENER_PANEL) && (<Stack
                      data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-RightStack'
                      direction={'row'}
                      justifyContent='space-between'
                      flexWrap='wrap'
                      spacing={1}
                    >
                      <DecisionTitle
                        id='Comments_Assign_Screener'
                        data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-Comments'
                        title='Comments'
                        width='82px'
                        paddingRight='8'
                        isOthersSelected={isOthersSelected}
                        decisionType={
                          intl.messages[
                          'sipDetail.decisionPanelElements.assignToScreener'
                          ]
                        }
                      ></DecisionTitle>
                      <FormControl>
                        <MyOnlyText
                          id='Comments_Assign_Screener_Text'
                          data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-Comments_Assign_Screener_Text'
                          multiline={true}
                          width='420px'
                          height='101px'
                          value={commentsAssignScreener}
                          setValue={setCommentsAssignScreener}
                          isError={commentsAssignScreener.length > 100000 && !focusCommentsAssignScreener}
                          errorMessage={
                            'Please input no more than 100,000 characters.'
                          }
                          onFocus={() => {
                            setFoucusCommentsAssignScreener(true);
                          }}
                          onBlur={() => {
                            setFoucusCommentsAssignScreener(false);
                          }}
                          minRows={5}
                          maxRows={5}
                        />
                        <FormHelperText
                          data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormHelperText'
                          sx={{
                            textAlign: 'right',
                            margin: '0px',
                            marginTop:
                              commentsAssignScreener.length > 100000 && !focusCommentsAssignScreener
                                ? '-14px'
                                : '4px',
                            fontSize: '12px',
                            lineHeight: '18px',
                            height: '15px',
                            color:
                              commentsAssignScreener.length > 100000 ? '#DE350B' : '#596A7C',
                            marginBottom: '3px',
                          }}
                        >
                          {commentsAssignScreener.length}/100,000
                        </FormHelperText>
                      </FormControl>
                    </Stack>)}
                  </>
                </Stack>

                {/* JournalMutiSelect */}
                {!isShowJounrnalMultiSelect ||
                  sipInformation?.sipType === 'Yes' ? (
                  <></>
                ) : (
                  <>
                    <FormControl data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-JournalFromControl'>
                      <Stack
                        direction='row'
                        data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-JournalStack'
                        sx={{
                          mt: '16px',
                        }}
                      >
                        <HeaderTitle
                          title='Journal'
                          required={true}
                          data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-JournalHeaderTitle'
                        ></HeaderTitle>
                        <JournalMutiSelect
                          setJournalSelected={setJournalMutiSelected}
                          emptyError={isNext && !hasValue(journalMutiSelected)}
                          value={journalMutiSelected}
                        ></JournalMutiSelect>
                      </Stack>
                    </FormControl>
                    <Box
                      sx={{
                        height: assigneeOpen
                          ? '146px'
                          : journalOpen
                            ? '240px'
                            : '0px',
                      }}
                    ></Box>
                  </>
                )}
              </Stack>

              {/* ? */}
              {decisionType !==
                intl.messages[
                'sipDetail.decisionPanelElements.assignToEditor'
                ] || sipInformation?.sipType === 'Yes' ? (
                <></>
              ) : (
                <Box
                  sx={{ mt: '16px' }}
                  data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-AssigneeBox'
                >
                  <Assignee
                    assignees={assignees}
                    setAssignees={setAssignees}
                  ></Assignee>
                </Box>
              )}

              {/* SubmitAndCancel Btn or NEXT Btn */}
              <Stack
                data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-ButtonStack'
                direction='row'
                justifyContent='flex-end'
                spacing={2}
                sx={{ height: '36px', mt: '16px' }}
              >

                {/* Next Btn */}
                {(showNextBtn) ? (
                  <NextBtn
                    variant='contained'
                    onClick={handleNext}
                    data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-NextBtn'
                    disabled={
                      hasEmptyError ||
                      (isOthersSelected && !hasValue(comments)) ||
                      isDecisionButtonDisabled ||
                      (sipDetailAllMandatorySubmitOrNextPressed &&
                        !sipDetailManatoryAllFilled)
                    }
                  >
                    Next
                  </NextBtn>
                ) : (
                  <></>
                )}

                {/* Cancel & Submit Btn */}
                {showCancelSubmitBtn ? (
                  <>
                    {/* Cancel Btn */}
                    <CancelBtn
                      data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-CancelBtn'
                      variant='text'
                      onClick={handleCancel}
                    >
                      Cancel
                    </CancelBtn>
                    {/* Submit Btn */}
                    <SubmitBtn
                      variant='contained'
                      onClick={async e => {
                        // UserValidCheck
                        if (
                          sipStage !==
                          intl.messages['sipDetail.sipStageType.submitted']
                        ) {
                          dispatch(
                            setSipDetailAllMandatorySubmitOrNextPressed(true)
                          );
                        }
                        if (!sipDetailManatoryAllFilled) {
                          handleManatoryAlert();
                          return;
                        }
                        if (
                          isSelectedTransferBackToJPA ||
                          isSelectedTransferToAnother
                        ) {
                          if (!isValidUser(sipStatus.emailOfHandlingCe)) {
                            //非法邮箱，提醒并返回
                            dispatch(
                              setSnackbarMessageAndOpen(
                                'email.wileyEmailError',
                                {},
                                'warning'
                              )
                            );
                            return;
                          }
                        }
                        if (isSelectedTransferBackToJPA) {
                          //转回JPA，跳到对应函数
                          handleTransferBackToJPASubmit(e);
                          return;
                        }
                        if (isSelectedTransferToAnother) {
                          //转给其他人，跳到对应函数
                          handleTransferToAnotherSubmit(e);
                          return;
                        }
                        if (isSelectedReject) {
                          //拒绝，跳到对应函数

                          setEmailPopUpOpen(true);
                          setEmailLoading(true);
                          const p = await new Promise(resolve =>
                            dispatch(
                              getEmailAllInformation(
                                sipStage,
                                decisionType,
                                sipCode,
                                comments,
                                additionCondition,
                                setButtonAlertButtonsSipChecklistNotProceeded,
                                resolve,
                                sipVersion,
                                sipStatus
                              )
                            )
                          );
                          if (p === SIP_CHECKLIST_NOT_PROCEEDED_CODE) {
                            setIsNext(false);
                            handleEmailClose();
                            return;
                          }
                          setEmailLoading(false);
                          if (
                            !isValidUser(sipStatus.emailOfHandlingCe) &&
                            fromOfEmail !== 'N/A'
                          ) {
                            dispatch(
                              setSnackbarMessageAndOpen(
                                'email.wileyEmailError',
                                {},
                                'warning'
                              )
                            );
                          }
                          return;
                        } else if (
                          decisionType ===
                          intl.messages['sipDetail.decisionPanelElements.directAccept']
                        ) {
                          dispatch(
                            SubmitDirectAccept({
                              sipCode,
                              directAcquisitionReason: directAcquireReason,
                              comments,
                              sipVersion,
                            })
                          ).then(sipNewVersion => {
                            if (sipNewVersion) {
                              const newSipStatus = {
                                ...sipStatus,
                                sipVersion: sipNewVersion,
                              };
                              dispatch({
                                type: GET_SIP_STATUS,
                                data: {
                                  ...newSipStatus,
                                },
                              });
                            }
                          });
                          return;
                        } else if (
                          decisionType ===
                          intl.messages[
                          'sipDetail.decisionPanelElements.moveToInitialReview'
                          ]
                        ) {
                          handleMoveToInitialReview();
                          return;
                        } else if (
                          sipStage === intl.messages['sipDetail.sipStageType.initialReview'] &&
                          panelDisplayed === PANEL.ASSIGN_SCREENER_PANEL
                        ) {
                          handleAssignToScreener();
                          return;
                        } else if (decisionType === 'Move to EBM Review') {
                          handleMoveToEBMReview();
                          return;
                        }
                        handleEmailSubmit(e);
                      }}
                      disabled={!canSubmit}
                      data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-SubmitBtn'
                    >
                      Submit
                    </SubmitBtn>
                  </>
                ) : (
                  <></>
                )}
              </Stack>
            </>
          )}
        </Stack>
      </div>

      {/* 下部一直在的Decision按钮 ?*/}
      <div style={{ ...buttonContainer, marginRight: '0' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            height: '100%',
          }}
        ></div>
      </div>

      {/* ? */}
      <Box
        id='temBox'
        sx={{
          height: `${temBoxHeight}px`,
          width: '100%',
          backgroundColor: '#FAFAFA',
        }}
      />

      <ButtonAlertBox
        open={buttonAlertOpen}
        handleClose={(event, reason) => {
          if (strictClose) {
            if (reason === 'clickaway') {
              return;
            }else{
              setButtonAlertOpen(false);
              setPanelDisplayed(PANEL.NULL);
              setStrictClose(false);
            }
          }else{
            setButtonAlertOpen(false);
            setPanelDisplayed(PANEL.NULL);
          }
        }}
        title={buttonAlertTitle}
        message={buttonAlertMessage}
        buttons={buttonAlertButtons}
        autoHideDuration={buttonAlertAutoHideDuration}
        severity={severity}
        top='9px'
        style={{
          width: '546px',
          position: 'absolute',
          top: '8px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
    </div>
  );

  
}

import styles from './index.module.scss';

const LoadingPage = () => {
  return (
    <div className={styles['loading-container']}>
      <div className={styles['loading-img']}></div>
      <h2 className={styles['loading-text']}>Loading…</h2>
    </div>
  );
};

export default LoadingPage;

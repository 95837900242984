// @ts-check
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SIMTButton } from '../../../../components/StyledButton/SIMTButton';
import SIModal from '../../../../modules/Modal';
import {
  DialogItemBlock,
  GEInfomationEditAutoComplete,
  GEInfomationEditInput,
  GEInfomationEditSelect,
  LeadGEFormControlLabel,
} from '../../SIPDetailPage/EditComponents';
import { UpLoadCVButton } from './CustomButton';
// @ts-ignore
import CVDownload from '@/assets/CVDownload.svg';
import styles from './CustomModal.module.scss';
import CheckedRadioIconBlack from '@/assets/CheckedRadioIconBlack.svg';
import CheckedRadioIcon from '@/assets/CheckedRadioIcon.svg';
import DefaultRadioIcon from '@/assets/DefaultRadioIcon.svg';
import { GEInfomationEditRadio } from '../../SIPDetailPage/EditComponents';
import SIModalPro from '@/modules/Modal/SIModal';
import {
  downloadCv,
  getExternalGEInformationCountryDroplist,
  getExternalGEInformationJobTitleDroplist,
  getGEInformationCountryDroplist,
  getGEInformationJobTitleDroplist
} from "@/actions/SIMT-SIP/SIP/SipDetail";
import HideTextConflictDetail from '../../SIPDetailPage/GEDetail/HideTextConflictDetail';
import { EMAIL_PATTERN, PATTERNORCID, PATTERNWEBSITE } from '@/constant/common';

/**
 *
 * @param {import("./CustomModal").GEInformationEditModalProps} props
 * @returns
 */
export const GEInformationEditModal = props => {
  const {
    open,
    handleClose,
    geInformationInitialValue,
    handleSaveGEInformation,
    rowIndex,
  } = props;
  const [geInformationItem, setGeInformationItem] = useState(
    geInformationInitialValue
  );

  const selector = state => {
    return {
      sipDetailGEInfomationCVIdResult:
        state.SIP.sipDetailGEInfomationCVIdResult,
      geInfomationCountryDroplistResult:
        state.SIP.geInfomationCountryDroplistResult,
      geInfomationJobTitleDroplistResult:
        state.SIP.geInfomationJobTitleDroplistResult,
      sipDetailGEInfomationCVIdMonitor:
        state.SIP.sipDetailGEInfomationCVIdMonitor,
    };
  };
  const {
    sipDetailGEInfomationCVIdResult,
    geInfomationCountryDroplistResult,
    geInfomationJobTitleDroplistResult,
    sipDetailGEInfomationCVIdMonitor,
  } = useSelector(selector);

  const { cvToUpdateList } = useSelector(state => ({
    /** @type {import("./CustomButton").CvToUpdate[]} */
    // @ts-ignore
    cvToUpdateList: state.SIP.cvToUpdateList,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getExternalGEInformationJobTitleDroplist(
        '',
        `/api/v1/sip-service/special-issue-proposals/revise/${token}/field-metadata`,
        {},
        true
      )
    );
  }, []);

  /** 表明用户是否点击 Save 按钮 */
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const EmailPattern =EMAIL_PATTERN;
  const webPattern = PATTERNWEBSITE;
  const patternORCID = PATTERNORCID;
  useEffect(() => {
    setShowErrorMessage(false);
  }, [open]);

  useEffect(() => {
    setGeInformationItem(geInformationInitialValue);
  }, [geInformationInitialValue, open]);

  const { token } = useParams();

  /** 用于捕获自检测错误的输入框的错误  */
  const [emailAddressError, setEmailAddresError] = useState(false);
  const [webProfileError, setWebProfileError] = useState(false);
  const [conflictError, setConflictError] = useState(false);
  const [orcIdError, setOrcIdError] = useState(false);
  // const [saveButtonDisable, setSaveButtonDisable] = useState(false);

  /**
   * @returns {boolean}
   */
  const checkDataValidation = () => {
    let res = true;

    const mandatoryError = !(
      geInformationItem.jobTitle &&
      geInformationItem.firstName &&
      geInformationItem.lastName &&
      geInformationItem.emailAddress &&
      geInformationItem.institution &&
      geInformationItem.country
    );
    const profileError = !(
      geInformationItem.profileWebsite || geInformationItem.cv
    );

    if (mandatoryError || profileError) {
      res = false;
    }

    if (emailAddressError || webProfileError || conflictError || orcIdError) {
      res = false;
    }

    if (res === false) {
      if (emailAddressError)
        setErrorMessage('A valid email address must be filled in.');
      else if (webProfileError)
        setErrorMessage('Url in "Profile" is not valid');
      else if (mandatoryError)
        setErrorMessage('Please input all the mandatory fields!');
      else if (profileError) {
        setErrorMessage(
          'At least one of website url or cv must be filled in "Profile".'
        );
      } else if (orcIdError) {
        setErrorMessage(
          'Incorrect OCRID, please input like this way 0000-0000-0000-0000.'
        );
      }
    }

    return res;
  };

  return (
    <SIModal
      data-selenium-id='SIP_RevisePage_CustomComponents-Modal-SI'
      open={open}
      title='GE Detail'
      handleClose={() => {
        if (handleClose) handleClose();
      }}
      sx={{
        marginTop: '56px',
      }}
      boxSx={{
        maxHeight: 'calc(100vh - 56px - 64px)',
        borderRadius: '4px',
      }}
    >
      <Stack
        sx={{ flexGrow: 1, height: '100%' }}
        justifyContent='flex-end'
        data-selenium-id='SIP_RevisePage_CustomComponents-Modal-Stack'
      >
        <Box
          sx={{ flexGrow: 1, position: 'relative', height: '400px' }}
          data-selenium-id='SIP_RevisePage_CustomComponents-Modal-Box'
        >
          <Box
            data-selenium-id='SIP_RevisePage_CustomComponents-Modal-CotainerBox'
            className={`${styles['GEInformationEditModal-scrollContainer']}`}
          >
            <Collapse
              in={showErrorMessage}
              data-selenium-id='SIP_RevisePage_CustomComponents-Modal-Collapse'
            >
              <Typography
                data-selenium-id='SIP_RevisePage_CustomComponents-Modal-Typography'
                sx={{
                  fontSize: '14px',
                  paddingBottom: '8px',
                  color: '#EE5350',
                }}
              >
                {errorMessage}
              </Typography>
            </Collapse>
            <Stack
              spacing={2}
              data-selenium-id='SIP_RevisePage_CustomComponents-Modal-JobTitleStack'
            >
              {/* Job Title */}
              <DialogItemBlock
                direction={'column'}
                title='Title:'
                mandatory={true}
                style={{ width: '50%' }}
              >
                <GEInfomationEditSelect
                  id='jobTitle'
                  data-selenium-id='SIP_RevisePage_CustomComponents-Modal-JobTitle'
                  value={geInformationItem.jobTitle || ''}
                  dropList={geInfomationJobTitleDroplistResult}
                  error={showErrorMessage && geInformationItem.jobTitle === ''}
                  onChange={e => {
                    setGeInformationItem(current => ({
                      ...current,
                      jobTitle: `${e.target.value}`,
                    }));
                  }}
                />
              </DialogItemBlock>
              {/* First Name */}
              <DialogItemBlock
                direction='column'
                title='First Name:'
                mandatory={true}
                style={{ width: '50%' }}
              >
                <GEInfomationEditInput
                  id='firstName'
                  data-selenium-id='SIP_RevisePage_CustomComponents-Modal-FirstNameInput'
                  value={geInformationItem.firstName || ''}
                  setValue={newValue => {
                    setGeInformationItem(current => ({
                      ...current,
                      firstName: newValue,
                    }));
                  }}
                  needEmptyCheck={true}
                  errorMonitor={showErrorMessage}
                />
              </DialogItemBlock>
              {/* Last Name */}
              <DialogItemBlock
                direction='column'
                title='Last Name:'
                mandatory={true}
                style={{ width: '50%' }}
              >
                <GEInfomationEditInput
                  id='lastName'
                  data-selenium-id='SIP_RevisePage_CustomComponents-Modal-LastNameInput'
                  value={geInformationItem.lastName || ''}
                  setValue={newValue => {
                    setGeInformationItem(current => ({
                      ...current,
                      lastName: newValue,
                    }));
                  }}
                  needEmptyCheck={true}
                  errorMonitor={showErrorMessage}
                />
              </DialogItemBlock>
              {/* Lead GE: */}
              <DialogItemBlock
                direction='column'
                title='Lead GE:'
                mandatory={true}
                style={{ width: '50%' }}
              >
                <FormControl data-selenium-id='SIP_RevisePage_CustomComponents-Modal-LeadGEFromControl'>
                  <RadioGroup
                    data-selenium-id='SIP_RevisePage_CustomComponents-Modal-LeadGERadioGroup'
                    row
                    value={geInformationItem.leadGe ? 'yes' : 'no'}
                    onChange={e => {
                      setGeInformationItem(current => ({
                        ...current,
                        leadGe: e.target.value === 'yes' ? true : false,
                      }));
                    }}
                  >
                    <LeadGEFormControlLabel
                      data-selenium-id='SIP_RevisePage_CustomComponents-Modal-LeadGEFormControlYES'
                      value='yes'
                      control={<GEInfomationEditRadio />}
                      label='Yes'
                    />
                    <LeadGEFormControlLabel
                      data-selenium-id='SIP_RevisePage_CustomComponents-Modal-LeadGEFormControlNO'
                      value='no'
                      control={<GEInfomationEditRadio />}
                      label='No'
                    />
                  </RadioGroup>
                </FormControl>
              </DialogItemBlock>
              {/* Email Address: */}
              <DialogItemBlock
                direction='column'
                title='Email Address:'
                mandatory={true}
                style={{ width: '50%' }}
              >
                <GEInfomationEditInput
                  id='emailAddress'
                  data-selenium-id='SIP_RevisePage_CustomComponents-Modal-EmailAddressInput'
                  placeholder=''
                  value={geInformationItem.emailAddress || ''}
                  setValue={newValue =>
                    setGeInformationItem(current => ({
                      ...current,
                      emailAddress: newValue,
                    }))
                  }
                  needEmptyCheck={true}
                  needFormatCheck={true}
                  pattern={EmailPattern}
                  errorMonitor={showErrorMessage}
                  setFormatError={value => {
                    setEmailAddresError(value);
                  }}
                />
              </DialogItemBlock>
              {/* Department: */}
              <DialogItemBlock
                direction='column'
                title='Department:'
                style={{ width: '50%' }}
              >
                <GEInfomationEditInput
                  id='department'
                  data-selenium-id='SIP_RevisePage_CustomComponents-Modal-DepartMentInput'
                  value={geInformationItem.department || ''}
                  setValue={newValue =>
                    setGeInformationItem(current => ({
                      ...current,
                      department: newValue,
                    }))
                  }
                />
              </DialogItemBlock>
              {/* Institution: */}
              <DialogItemBlock
                direction='column'
                title='Institution:'
                mandatory={true}
                style={{ width: '50%' }}
              >
                <GEInfomationEditInput
                  id='institution'
                  data-selenium-id='SIP_RevisePage_CustomComponents-Modal-InstitutionInput'
                  value={geInformationItem.institution || ''}
                  setValue={newValue =>
                    setGeInformationItem(current => ({
                      ...current,
                      institution: newValue,
                    }))
                  }
                  needEmptyCheck={true}
                  errorMonitor={showErrorMessage}
                />
              </DialogItemBlock>
              {/* City : */}
              <DialogItemBlock
                direction='column'
                title='City :'
                style={{ width: '50%' }}
              >
                <GEInfomationEditInput
                  id='city'
                  data-selenium-id='SIP_RevisePage_CustomComponents-Modal-CityInput'
                  value={geInformationItem.city || ''}
                  setValue={newValue =>
                    setGeInformationItem(current => ({
                      ...current,
                      city: newValue,
                    }))
                  }
                />
              </DialogItemBlock>
              {/* Country/Region: */}
              <DialogItemBlock
                direction='column'
                title='Country/Region:'
                style={{ width: '50%' }}
                mandatory={true}
              >
                <GEInfomationEditAutoComplete
                  id='Country/Region'
                  placeholder='Choose/Enter Country/Region'
                  data-selenium-id='SIP_RevisePage_CustomComponents-Modal-CountryOrRegionAutoComplete'
                  value={geInformationItem.country || ''}
                  inputValue={geInformationItem.country || ''}
                  onChange={(_event, newValue) => {
                    if (Array.isArray(newValue)) return;
                    setGeInformationItem(current => ({
                      ...current,
                      country: newValue,
                    }));
                  }}
                  options={geInfomationCountryDroplistResult}
                  loadDropList={keyword => {
                    return getExternalGEInformationCountryDroplist(
                      keyword,
                      `/api/v1/sip-service/special-issue-proposals/revise/${token}/field-metadata`,
                      {},
                      true
                    );
                  }}
                  needEmptyCheck={true}
                  errorMonitor={showErrorMessage}
                  sx={{
                    '&.MuiAutocomplete-root .MuiInputBase-root': {
                      paddingY: 0,
                      '& input': {
                        paddingY: 0,
                      },
                    },
                  }}
                />
              </DialogItemBlock>
              {/* ORCID ID: */}
              <DialogItemBlock
                direction='column'
                title='ORCID ID:'
                style={{ width: '50%' }}
              >
                <GEInfomationEditInput
                  id='orcidId'
                  data-selenium-id='SIP_RevisePage_CustomComponents-Modal-OrcidIdInput'
                  value={geInformationItem.orcidId || ''}
                  placeholder='0000-0000-0000-0000'
                  setValue={newValue =>
                    setGeInformationItem(current => ({
                      ...current,
                      orcidId: newValue,
                    }))
                  }
                  needFormatCheck={true}
                  pattern={patternORCID}
                  errorMonitor={showErrorMessage}
                  setFormatError={value => {
                    setOrcIdError(value);
                  }}
                />
              </DialogItemBlock>
              {/* ResearchGate ID: */}
              <DialogItemBlock
                direction='column'
                title='ResearchGate ID:'
                style={{ width: '50%' }}
              >
                <GEInfomationEditInput
                  id='researchGateId'
                  data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ResearchGateIdInput'
                  value={geInformationItem.researchGateId || ''}
                  setValue={newValue =>
                    setGeInformationItem(current => ({
                      ...current,
                      researchGateId: newValue,
                    }))
                  }
                />
              </DialogItemBlock>
              {/* Profile: */}
              <DialogItemBlock
                direction='column'
                title='Profile:'
                mandatory={true}
                style={{ width: '50%' }}
              >
                <GEInfomationEditInput
                  id='profile'
                  data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ProfileInput'
                  placeholder='The URL to ResearchGate Profile is preferred'
                  value={geInformationItem.profileWebsite || ''}
                  setValue={newValue => {
                    setGeInformationItem(current => ({
                      ...current,
                      profileWebsite: newValue,
                    }));
                  }}
                  // url的格式暂时不做要求
                  needFormatCheck={true}
                  pattern={webPattern}
                  errorMonitor={showErrorMessage}
                  setFormatError={setWebProfileError}
                />
              </DialogItemBlock>
              <Box
                data-selenium-id='SIP_RevisePage_CustomComponents-Modal-CVBtnDivider-Box'
                className={`${styles['GEInformationEditModal-cvButtonDivider-root']}`}
                sx={{ paddingLeft: 0 }}
              >
                <Box
                  data-selenium-id='SIP_RevisePage_CustomComponents-Modal-CVBtnDivider-Container'
                  className={`${styles['GEInformationEditModal-cvButtonDivider-container']}`}
                  sx={{ width: '317px' }}
                >
                  <Divider data-selenium-id='SIP_RevisePage_CustomComponents-Modal-CVBtnDivider' />
                  <Typography
                    data-selenium-id='SIP_RevisePage_CustomComponents-Modal-CVBtnDivider-Typography'
                    className={`${styles['GEInformationEditModal-cvButtonDivider-text']}`}
                  >
                    and/or
                  </Typography>
                </Box>
              </Box>
              <DialogItemBlock
                direction='column'
                title=''
                style={{ width: '60%' }}
              >
                <UpLoadCVButton
                  data-selenium-id='SIP_RevisePage_CustomComponents-Modal-UpLoadCVButton'
                  cvId={geInformationItem.cv}
                  cvName={geInformationItem.cvFileName}
                  setCvName={newValue => {
                    setGeInformationItem(current => ({
                      ...current,
                      cvFileName: newValue,
                    }));
                  }}
                  setCvId={newValue => {
                    setGeInformationItem(current => ({
                      ...current,
                      cv: newValue,
                    }));
                  }}
                />
              </DialogItemBlock>
            </Stack>
            <Box sx={{ paddingRight: '35px', marginY: '16px' }}>
              <Divider data-selenium-id='SIP_RevisePage_CustomComponents-Modal-Box-Divider' />
            </Box>
            <ConflictForm
              mode='edit'
              data={{
                hasConflict: geInformationItem.hasConflict,
                conflictDetail: geInformationItem.conflictDetail,
              }}
              setValue={newValue => {
                setGeInformationItem(current => ({
                  ...current,
                  hasConflict: newValue.hasConflict,
                  conflictDetail: newValue.conflictDetail,
                }));
              }}
              showErrorMessage={showErrorMessage}
              setError={setConflictError}
            />
          </Box>
        </Box>
        <Stack
          data-selenium-id='SIP_RevisePage_CustomComponents-Modal-LayoutStack'
          direction={'row'}
          justifyContent='flex-end'
          spacing={2}
          marginTop='32px'
        >
          <SIMTButton
            data-selenium-id='SIP_RevisePage_CustomComponents-Modal-SIMTButton1'
            onClick={() => {
              if (handleClose) handleClose();
            }}
          >
            Cancel
          </SIMTButton>
          <SIMTButton
            data-selenium-id='SIP_RevisePage_CustomComponents-Modal-SIMTButton2'
            variant='contained'
            sx={{
              '&.MuiButtonBase-root': { padding: '8px 18px', fontWeight: 400 },
            }}
            onClick={() => {
              if (!checkDataValidation()) {
                setShowErrorMessage(true);
                return;
              }
              setShowErrorMessage(false);
              handleSaveGEInformation(rowIndex, geInformationItem);
            }}
          >
            {rowIndex < 0 ? 'Add' : 'Save'}
          </SIMTButton>
        </Stack>
      </Stack>
    </SIModal>
  );
};

/**
 *
 * @param {import("./CustomModal").GEInformationShowModalProps} props
 * @returns
 */
export const GEInformationShowModal = props => {
  const { open, handleClose, geInformationValue } = props;
  const { token } = useParams();
  const dispatch = useDispatch();

  const handleWebLink = geInformationValue => {
    if (geInformationValue.profileWebsite === null) return;
    var prefix = 'https://';
    let url = geInformationValue.profileWebsite;
    if (url.substr(0, prefix.length) !== prefix) {
      url = prefix + url;
    }
    return url;
  };

  return (
    <SIModalPro
      data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-SI'
      open={open}
      title='GE Detail'
      handleClose={() => {
        if (handleClose) handleClose();
      }}
      sx={{
        zIndex: 2000,
      }}
      containerSx={{
        height: '100%',
      }}
      isRightModel={true}
    >
      <Stack
        sx={{ flexGrow: 1, height: '100%' }}
        justifyContent='flex-end'
        data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-Stack'
      >
        <Box
          sx={{ flexGrow: 1, position: 'relative', height: '100%' }}
          data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-Box'
        >
          <Box
            data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-ScrollContainerBox'
            className={`${styles['GEInformationShowModal-scrollContainer']}`}
            sx={{
              flex: 1,
            }}
          >
            <Stack
              data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-ScrollContainerStack'
              // spacing={2}
              className={`${styles['GEInformationShowModal-contentContainer']}`}
              direction={'row'}
              flexWrap={'wrap'}
              sx={{
                rowGap: 2,
              }}
            >
              {/* First Name */}
              <DialogItemBlock
                sx={{ width: '50%' }}
                direction='column'
                title='First Name:'
              >
                <Typography data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-FirstNameTypoGraphy'>
                  {geInformationValue.firstName || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* Last Name */}
              <DialogItemBlock
                sx={{ width: '50%' }}
                direction='column'
                title='Last Name:'
              >
                <Typography data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-LastNameTypoGraphy'>
                  {geInformationValue.lastName || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* Job Title */}
              <DialogItemBlock
                sx={{ width: '50%' }}
                direction='column'
                title='Lead GE:'
              >
                <Typography data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-LeadGETypoGraphy'>
                  {geInformationValue.leadGe ? 'Yes' : 'No'}
                </Typography>
              </DialogItemBlock>
              {/* Job Title */}
              <DialogItemBlock
                sx={{ width: '50%' }}
                direction='column'
                title='Title:'
              >
                <Typography data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-JobTitleTypoGraphy'>
                  {geInformationValue.jobTitle || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* Email Address: */}
              <DialogItemBlock
                sx={{ width: '50%' }}
                direction='column'
                title='Email Address:'
              >
                <Typography data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-EmailAddressTypoGraphy'>
                  {geInformationValue.emailAddress || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* Department: */}
              <DialogItemBlock
                sx={{ width: '50%' }}
                direction='column'
                title='Department:'
              >
                <Typography data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-DepartmentTypoGraphy'>
                  {geInformationValue.department || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* Institution: */}
              <DialogItemBlock
                sx={{ width: '50%' }}
                direction='column'
                title='Institution:'
              >
                <Typography data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-InstitutionTypoGraphy'>
                  {geInformationValue.institution || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* City : */}
              <DialogItemBlock
                sx={{ width: '50%' }}
                direction='column'
                title='City :'
              >
                <Typography data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-CityTypoGraphy'>
                  {' '}
                  {geInformationValue.city || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* Country/Region: */}
              <DialogItemBlock
                sx={{ width: '50%' }}
                direction='column'
                title='Country/Region:'
              >
                <Typography data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-CountryOrRegionTypoGraphy'>
                  {' '}
                  {geInformationValue.country || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* ORCID ID: */}
              <DialogItemBlock
                sx={{ width: '50%' }}
                direction='column'
                title='ORCID ID:'
              >
                <Typography data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-ORCIDTypoGraphy'>
                  {' '}
                  {geInformationValue.orcidId || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* ResearchGate ID: */}
              <DialogItemBlock
                sx={{ width: '50%' }}
                direction='column'
                title='ResearchGate ID:'
              >
                <Typography data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-ReasearchGateIDTypoGraphy'>
                  {geInformationValue.researchGateId || 'N/A'}
                </Typography>
              </DialogItemBlock>
              {/* Profile: */}
              <DialogItemBlock
                sx={{ width: '50%' }}
                direction='column'
                title='Profile:'
              >
                {geInformationValue.profileWebsite ? (
                  <Link
                    data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-ProfileLink'
                    className={`${styles['GEInformationShowModal-profileLink']}`}
                    href={handleWebLink(geInformationValue)}
                    target='_blank'
                  >
                    {geInformationValue.profileWebsite}
                  </Link>
                ) : (
                  'N/A'
                )}
              </DialogItemBlock>
              <DialogItemBlock
                sx={{ width: '50%' }}
                direction='column'
                title='CV:'
              >
                {geInformationValue.cv ? (
                  <Stack
                    direction={'row'}
                    spacing='12px'
                    data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-CVDialogItemBlock-Stack'
                  >
                    {/* <Typography data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-CVTypography'>
                      {geInformationValue.cv.substring(
                        geInformationValue.cv.indexOf('_') + 1
                      )}
                    </Typography> */}
                    <IconButton
                      data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-CVIconButton'
                      onClick={() => {
                        if (geInformationValue.cv === null) return;
                        var index = geInformationValue.cv.indexOf(':');
                        var filename = geInformationValue.cv.substring(index + 1);
                        dispatch(
                          downloadCv(
                            filename.split(';size:')[0],
                            `/api/v1/sip-service/special-issue-proposals/revise/${token}/guest-editors/cv`,
                            {},
                            true
                          )
                        );
                      }}
                      sx={{ padding: 0 }}
                    >
                      <CVDownload data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-CVDownload' />
                    </IconButton>
                  </Stack>
                ) : (
                  <Typography data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-NorATypography'>
                    N/A
                  </Typography>
                )}
              </DialogItemBlock>
              <Box sx={{ paddingRight: '35px' }}>
                <Divider data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-Box-Divider' />
              </Box>
              <Box>
                <ConflictForm
                  data-selenium-id='SIP_RevisePage_CustomComponents-Modal-GEInformationShow-Box-ConflictForm'
                  mode='show'
                  data={{
                    hasConflict: geInformationValue.hasConflict,
                    conflictDetail: geInformationValue.conflictDetail,
                  }}
                />
              </Box>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </SIModalPro>
  );
};

/**
 *
 * @param {import("./CustomModal").SubmitConfirmModalProps} props
 * @returns
 */
export const SubmitConfirmModal = props => {
  const {
    open: confirmSubmitModalOpen,
    handleClose,
    confirmText,
    handleSubmit = () => { },
  } = props;

  return (
    <SIModal
      data-selenium-id='SIP_RevisePage_CustomComponents-Modal-SubmitConfirm-SIModal'
      open={confirmSubmitModalOpen}
      title='Submit My Revision'
      height={'auto'}
      handleClose={handleClose}
    >
      <Stack
        spacing={2}
        marginTop={2}
        data-selenium-id='SIP_RevisePage_CustomComponents-Modal-SubmitConfirm-Stack'
      >
        <Typography
          sx={{ fontSize: '14px' }}
          data-selenium-id='SIP_RevisePage_CustomComponents-Modal-SubmitConfirm-Typography'
        >
          {confirmText}
        </Typography>
        <Stack
          direction={'row'}
          justifyContent='flex-end'
          spacing={2}
          data-selenium-id='SIP_RevisePage_CustomComponents-Modal-SubmitConfirm-JustifyStack'
        >
          <SIMTButton
            onClick={handleClose}
            data-selenium-id='SIP_RevisePage_CustomComponents-Modal-SubmitConfirm-CancelButton'
          >
            Cancel
          </SIMTButton>
          <SIMTButton
            data-selenium-id='SIP_RevisePage_CustomComponents-Modal-SubmitConfirm-OKButton'
            variant='contained'
            sx={{ paddingX: '32px' }}
            onClick={handleSubmit}
          >
            OK
          </SIMTButton>
        </Stack>
      </Stack>
    </SIModal>
  );
};

/**
 *
 * @param {import('./CustomModal').ConflictFormProps} props
 * @returns
 */
const ConflictForm = props => {
  const {
    data,
    mode,
    setValue = () => { },
    showErrorMessage = false,
    setError = () => { },
  } = props;

  useEffect(() => {
    if (mode === 'edit') {
      if (data.hasConflict && data.conflictDetail === '') {
        setError(true);
      } else {
        setError(false);
      }
    } else {
      setError(false);
    }
  }, [data.hasConflict, data.conflictDetail, mode]);

  return (
    <>
      <Typography
        data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-Typography'
        className={`${styles['GEInformationShowModal-headingSecondary']}`}
      >
        Conflict of Interest Declaration
        {mode === 'edit' && (
          <span
            className={`${styles['GEInformationShowModal-headingSecondary-asterisk']}`}
          >
            *
          </span>
        )}
      </Typography>
      <Typography
        data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-Typography-subtitle'
        sx={{
          // height: '20px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '120%',
          color: '#828282 !important',
          letterSpacing: '1%',
        }}
      >
        Please declare whether the current Guest Editor has any conflicts of interest or not:
      </Typography>
      <FormControl
        data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-Control'
        sx={{
          marginY: '8px',
        }}
      >
        <RadioGroup
          data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-RadioGroup'
          defaultValue={data.hasConflict === 'true' ? 'true' : data.hasConflict === 'false' ? 'false' : data.hasConflict}
          row
          onChange={e => {
            setValue({
              hasConflict: e.target.value === 'true' ? true : false,
              conflictDetail: data.conflictDetail,
            });
          }}
        >
          <FormControlLabel
            data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-ControlLabel2'
            value={'true'}
            sx={{ height: '32px', alignContent: 'center', marginRight: '56px' }}
            control={
              <Radio
                data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-Radio2'
                disableRipple
                disabled={mode === 'show'}
                sx={{ width: '40px', height: '32px' }}
                icon={
                  <DefaultRadioIcon data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-DefaultRadioIcon2' />
                }
                checkedIcon={
                  mode === 'edit' ? (
                    <CheckedRadioIconBlack data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-CheckedRadioIconBlack2' />
                  ) : (
                    <CheckedRadioIcon data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-CheckedRadioIcon2' />
                  )
                }
              />
            }
            label={
              <Typography
                data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-LabelTextTypography2'
                className={`${styles['GEInformationShowModal-confictLabelText']}`}
              >
                Yes
              </Typography>
            }
          />
          <FormControlLabel
            data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-ControlLabel1'
            value={'false'}
            sx={{ height: '32px', alignContent: 'center' }}
            control={
              <Radio
                data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-Radio1'
                disableRipple
                disabled={mode === 'show'}
                sx={{ width: '40px', height: '32px' }}
                icon={
                  <DefaultRadioIcon data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-DefaultRadioIcon1' />
                }
                checkedIcon={
                  mode === 'edit' ? (
                    <CheckedRadioIconBlack data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-CheckedRadioIconBlack1' />
                  ) : (
                    <CheckedRadioIcon data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-CheckedRadioIcon1' />
                  )
                }
              />
            }
            label={
              <Typography
                data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-LabelTextTypography1'
                className={`${styles['GEInformationShowModal-confictLabelText']}`}
              >
                No
              </Typography>
            }
          />
        </RadioGroup>
      </FormControl>
      {data.hasConflict && mode === 'edit' ?
        <GEInfomationEditInput
          placeholder='Please provide more details on the conflict(s) of interest'
          id='conflict-of-interest-declaration-detail-input'
          data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-InterestDeclarationDetailInput'
          value={data.conflictDetail || ''}
          width='488px'
          setValue={newValue => {
            setValue({
              hasConflict: data.hasConflict,
              conflictDetail: newValue,
            });
          }}
          needEmptyCheck={true}
          errorMonitor={showErrorMessage}
        />
        :
        <HideTextConflictDetail data-selenium-id='SIP_RevisePage_CustomComponents-Modal-ConflictForm-HideTextConflictDetail'>
          {data.conflictDetail || ''}
        </HideTextConflictDetail>

      }
    </>
  );
};

import {axios_instance} from "@/utils/axios_instance";
import {
    CHECK_EMAIL,
    CREATE_GE,
    GET_BASICINFORMATION,
    GET_GEBASICINFORMATION_HISTORY,
    LOAD_ASSOCIATEDSI,
    MERGE_BASIC_INFORMATION_ONE,
    MERGE_BASIC_INFORMATION_TWO,
    MERGE_FINISH,
    SET_FLAGGEDGESYNCHRONIZATIONANDVERIFICATIONTABLE
} from "@/actions/SIMT-SI/GuestEditor/GEAction";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import simpleDisplayError from "@/utils/simpleDisplayError";
import getAuth from "@/utils/getAuth";

/**@typedef {import("redux").Dispatch} Dispatch*/
/**
 * @description 用于获得ge detail page 的Associated SI 的表中的信息
 * @param {string} geId
 * @param {number} pageNum
 * @param {number} pageSize
 */
export function getAssociatedSITableInfo(geId, pageNum, pageSize) {
  /**
   * @param {import("redux").Dispatch} dispatch
   */
    return async dispatch => {
        try {
            if(!geId) return;
            const url = `/api/v1/si-service/guest-editors/${geId}/special-issues`;
            const/**@type {import("@/utils/axios-response").AxiosResponse<{readonly count: number;readonly result: ReadonlyArray<{readonly journalName?: string;readonly siCode?: string;readonly siTitle?: string;readonly stage?: string;readonly tag?: string;}>;readonly total: number;}>}*/ res = await axios_instance.get(url, {
                params: {
                    // geCode: geId,
                    pageNum: pageNum,
                    pageSize: pageSize,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const{code,data} = res.data;
            if (code === 200) {
                const dataGet = {
                    result: data?.result,
                    count: data?.count,
                };
                dispatch({type: LOAD_ASSOCIATEDSI, data: dataGet});
            } else {
                dispatch({type: LOAD_ASSOCIATEDSI, data: {result: [], count: 0}});
            }
        } catch (e) {
            console.error(e);
            dispatch({type: LOAD_ASSOCIATEDSI, data: 'error'});
        }
    };
}

/**
 *
 * @param {string} geId 要请求的GE信息的geid
 * @description 用于获取GE基本信息
 */
export function getBasicInformation(
    geId,
    isMergeMode = false,
    isFirstOne = true
) {
    // 这里偷个懒，geId参数不管传ID还是email都能够兼容
    const isEmail = geId.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]+))$/
    );
    // 原始的使用geId（作为默认）
    let url = `/api/v1/si-service/guest-editors/${geId}`;
    const firstParamName = 'email';
    if (isEmail) {
        url = '/guestEditor/getGeByEmail';
    }

    const actionType = isMergeMode
        ? isFirstOne
            ? MERGE_BASIC_INFORMATION_ONE
            : MERGE_BASIC_INFORMATION_TWO
        : GET_BASICINFORMATION;

    /**
     * @param {Dispatch} dispatch
     */
    return async dispatch => {
        try {
            const res = await axios_instance.get(url, {
                params: {
                    [firstParamName]: isEmail ? geId : undefined
                },
                headers: {
                    authorization: getAuth()??'',
                },
            });
            if (res.data.code === 200) {
                let newData = res.data.data;
                // 判断 cvFileName 是否以 'name:' 开头，如果不是，则补充 'name:time_' 前缀
                if (newData.cvFileName && !newData.cvFileName.startsWith('name:')) {
                    const timestamp = Date.now();
                    newData.cvFileName = `name:autofilled${timestamp}_${newData.cvFileName};size:0`;
                }
                dispatch({ type: actionType, data: newData });
                
            } else {
                if (res.data.message === 'Access is denied') {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'access.noAccessError',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
                dispatch({type: actionType, data: res.data.message});
            }
        } catch (e) {
            dispatch({type: actionType, data: 'error'});
        }
    };
}

export function checkEmail(email) {
    return async (/**@type {Dispatch}*/dispatch) => {
        try {
            const url = `/guestEditor/checkEmail`;
            const res = await axios_instance.get(url, {
                params: {
                    email: email,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            dispatch({type: CHECK_EMAIL, data: res.data});
        } catch {
            dispatch({type: CHECK_EMAIL, data: 'error'});
        }
    };
}

/**
 *
 * @param {import("@/pages/SIPPage/TransformToSI").GuestEditorCreateDto} GEData
 * @param {boolean} isLead
 * @returns
 */
export function CreateGE(GEData, isLead) {
    return async dispatch => {
        try {
            const url = '/api/v1/si-service/guest-editors';
            const result = await axios_instance.post(url, {
              ...GEData,
              researchKeywordsList:GEData.researchKeywords.map(i=>i.keyword),
            }, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (result.data.code === 200) {
                dispatch({
                    type: CREATE_GE,
                    data: {
                        firstName: GEData.firstName,
                        lastName: GEData.lastName,
                        primaryEmail: GEData.primaryEmail,
                        isLead: isLead,
                    },
                });
            } else {
                if (result.data.message === 'the email address has been registered')
                    //判断是否是邮箱存在错误
                    dispatch({type: CREATE_GE, data: 'EmailExit'});
                else {
                    dispatch({type: CREATE_GE, data: 'error'});
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'gePage.createError',
                            {
                                value: result.data.message, // TODO 以后再改为id的形式
                            },
                            SEVERITIES.error
                        )
                    );
                }
            }
        } catch {
            dispatch({type: CREATE_GE, data: 'error'});
        }
    };
}

/**
 * @param {string} geCode1 
 * @param {string} geCode2 
 * @param {{readonly geCode:string;readonly firstName:string;readonly lastName:string;readonly primaryEmail:string;readonly secondaryEmail:string;readonly department:string;readonly institute:string;readonly city:string;readonly country:string;readonly gender:string;readonly notes:null|string;readonly researchKeywords:ReadonlyArray<string>;readonly orcidId:string;readonly title:string;readonly profileWebsite:string;readonly researchGateId:string;readonly cvFileName:null|string;}} basicInformation 
 * @param {ReadonlyArray<{readonly geCode: string;readonly geFlagId: null | string;readonly flagInfo: {readonly type: string;readonly value: string;};readonly comment: string;}>} flags 
 */
export const mergeGE = (geCode1, geCode2, basicInformation, flags) => {
    /**
     * @param {Dispatch} dispatch
     */
    return async dispatch => {
        const requestURL = '/api/v1/si-service/guest-editors/merge';
        try {
            let res = await axios_instance.post(
                requestURL,
                {
                    basicInformation: basicInformation,
                    flags: flags,
                },
                {
                    params: {
                        geCode1: geCode1,
                        geCode2: geCode2,
                    },
                    headers: {
                        authorization: getAuth()??'',
                    },
                }
            );

            if (res.data.code === 200) {
                dispatch({type: MERGE_FINISH, data: true});
                return;
            }
            dispatch({type: MERGE_FINISH, data: false});

        } catch (err) {
            dispatch({type: MERGE_FINISH, data: false});
        }
    };
};

/**
 * @param {string} geCode 
 * @param {number} pageNum 
 * @param {number} pageSize 
 */
export function get_GEBasicHistory(geCode, pageNum, pageSize) {
  /**
   * @param {import("redux").Dispatch} dispatch
   */
    return async dispatch => {
        try {
            const url =  `/api/v1/si-service/guest-editors/${geCode}/edit-history`;
            const res = await axios_instance.get(url, {
                params: {
                    // geCode: geCode,
                    pageNum: pageNum,
                    pageSize: pageSize,
                },
                headers: {
                    authorization: getAuth()??'',
                },
            });
            if (res.data.code === 200) {
                dispatch({type: GET_GEBASICINFORMATION_HISTORY, data: {data: res.data.data, count: res.data.count}});
            } else {
                dispatch({type: GET_GEBASICINFORMATION_HISTORY, data: 'error'});
            }
        } catch {
            dispatch({type: GET_GEBASICINFORMATION_HISTORY, data: 'error'});
        }
    };
}
/**@typedef {import('@/componentsphase2/FlaggedGESynchronizationAndVerificationTable').GeDataType} GeDataType*/
/**
 * @description 用于根据fullName和email获得ge detail的信息
 * @param {string} fullname
 * @param {string?} email
 * @param {number?} index
 * @param {boolean?} hasFlag 是否只返回带flag的数据
 * @param {(e:GeDataType)=>void?} resolve
 */
export const getGeByFullname = (fullname = '', email = null, index = 0, hasFlag = false, resolve = _e => {
}) =>
    /**
     * @param {import('redux').Dispatch} dispatch
     */
    async (dispatch) =>
        // eslint-disable-next-line camelcase
        axios_instance.get('/guestEditor/getGeByFullname', {
            params: {fullname, email, hasFlag}, headers: {
                authorization: localStorage.getItem('authorization') ?? '',
            },
        }).then(
            /**
             * @param {import('axios').AxiosResponse<{readonly code?: number;readonly status?: number;readonly message?: string;readonly data:import('@/componentsphase2/FlaggedGESynchronizationAndVerificationTable').GeDataType;}>} res
             */
            res => {
                if (res?.status === 200) {
                    const data = res?.data;
                    if (
                        data?.code === 200 ||
                        data?.status === 200 ||
                        data?.message === 'OK'
                    ) {
                        const value = data?.data;
                        dispatch({type: SET_FLAGGEDGESYNCHRONIZATIONANDVERIFICATIONTABLE, data: {value, index}});
                        resolve(value);
                        return value;
                    }
                }
                // @ts-ignore
                dispatch(simpleDisplayError('error occur'));
            }).catch(console.error);
    
export const getGeByFullnameDirectly = async (fullname = '', email = null, hasFlag = false) => {
    const response = await axios_instance.get('/guestEditor/getGeByFullname', {
        params: {fullname, email, hasFlag}, headers: {
            authorization: localStorage.getItem('authorization') ?? '',
        },
    });
    if (response?.status === 200) {
        const data = response?.data;
        if (
            data?.code === 200 ||
            data?.status === 200 ||
            data?.message === 'OK'
        ) {
            return data?.data;
        }
    }
    return [];
};
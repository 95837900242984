import { CustomLink } from '../../../pages/SIPage/SIDetailPage/market-section/components/components';

export function utc2beijing(utc_datetime) {
  // 转为正常的时间格式 年-月-日 时:分:秒
  let T_pos = utc_datetime.indexOf('T');
  let Z_pos = utc_datetime.indexOf('.');
  let year_month_day = utc_datetime.substr(0, T_pos);
  let hour_minute_second = utc_datetime.substr(T_pos + 1, Z_pos - T_pos - 1);
  let new_datetime = year_month_day + ' ' + hour_minute_second; // 2017-03-31 08:02:06

  // 处理成为时间戳
  let timestamp = new Date(Date.parse(new_datetime));
  timestamp = timestamp.getTime();
  timestamp = timestamp / 1000;

  // 增加8个小时，北京时间比utc时间多八个时区
  timestamp = timestamp + 8 * 60 * 60;

  // 时间戳转为时间
  let beijing_datetime = new Date(parseInt(timestamp) * 1000)
    .toLocaleString()
    .replace(/年|月/g, '-')
    .replace(/日/g, ' ');
  return beijing_datetime; // 2017-03-31 16:02:06
}
//获取两个北京时间之间的天数差 返回类型 Int
export function getDaysDifference(date_1) {
  let today = new Date();
  let last_update_date = new Date(date_1);
  let secondsDifference =
    today.getTime() -
    (today.getTime() % (1000 * 60 * 60 * 24)) -
    (last_update_date.getTime() -
      (last_update_date.getTime() % (1000 * 60 * 60 * 24)));
  return parseInt(secondsDifference / (1000 * 60 * 60 * 24));
}
// 将字符串中的${}转化成<b></b>
export function formatBold(str) {
  let strArr_1 = str.split('${');
  let ansStr = strArr_1[0];
  for (let i = 1; i < strArr_1.length; i++) {
    let strArr_2 = strArr_1[i].split('}');
    ansStr += strArr_2[0].bold() + strArr_2[1];
  }
  return ansStr;
}

// 将字符串中的${}去掉
export function formatEmpty(str) {
  let strArr_1 = str.split('${');
  let ansStr = strArr_1[0];
  for (let i = 1; i < strArr_1.length; i++) {
    let strArr_2 = strArr_1[i].split('}');
    ansStr += strArr_2[0] + strArr_2[1];
  }
  return ansStr;
}

const linkStyles = {
  color: '#154AB6',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  cursor: 'pointer',
  // textTransform: 'capitalize',
  '&:hover': {
    color: '#113D95',
    fontWeight: 600,
  },
};

export function formatSystemEmailTemplateUpdate(str) {
  let index = str.indexOf('click the link:');
  index += 15;
  const context = str.substring(0, index);
  let url = str.substring(index);
  const urlArr = url.split('/');
  let type = urlArr.pop();
  url = urlArr.join('/');
  type = (() => {
    switch (type) {
      case 'email':
        url += '/email';
      case 'si':
        return 0;
      case 'ssip':
        return 1;
      case 'usip':
        return 2;
    }
  })();
  url += '?tabForInit=' + type;
  return (
    <div style={{ position: 'relative' }}>
      <div>{formatEmpty(context)}</div>
      <div
        style={
          {
            // position: 'fixed',
          }
        }
      >
        <CustomLink href={url} state={'read-only'} sx={linkStyles}>
          Check it now
        </CustomLink>
      </div>
    </div>
  );
}

export const SYS_EMAIL_UPDATE_SUBJECT = 'SIMT update the email templates';

/**
 * 对输入的字符串 str ，输出一个字符串，使得 str 中每一个逗号后有且仅有一个空格
 * @param {string} str
 */
export const formatSpaceAfterComma = str => {
  return str
    .split(',')
    .map((item, index) => {
      if (index === 0) return item;
      return item.trim();
    })
    .join(', ');
};

//将数字转化为天数差的文字表示 比如0 就是Today
export const FormatDate = DaysOfDifference => {
  let formatDate = '';
  switch (DaysOfDifference) {
    case 0:
      formatDate = 'Today';
      break;
    case 1:
      formatDate = '1 Day Ago';
      break;
    default:
      formatDate = DaysOfDifference + ' Days Ago';
  }
  return formatDate;
};

export const StrArrToObjArr = strArr => {
  let objArr = [];
  for (let i = 0; i < strArr.length; i++) {
    let newStrArr = strArr[i].split('"');
    let newObj = {};
    for (let i = 1; i < newStrArr.length; i += 4) {
      newObj[newStrArr[i]] = newStrArr[i + 2];
    }
    objArr.push(newObj);
  }
  return objArr;
};

import ArticlesOAvsTotalSG from './articlesOAvsTotalSG';
import ArticlesOAvsTotalBD from './articlesOAvsTotalBD';
import {
  TableGroupBox,
  TableLabel,
} from '../../ReportSpecificComponents/SpecialColorTableHeader';

export function TableContent() {
  return (
    <>
      <TableGroupBox>
        <TableLabel>Subject Group</TableLabel>
        <ArticlesOAvsTotalSG />
      </TableGroupBox>

      <TableGroupBox>
        <TableLabel>Business Division</TableLabel>
        <ArticlesOAvsTotalBD />
      </TableGroupBox>
    </>
  );
}
export default TableContent;

import React from 'react';
import {
  Autocomplete,
  Box,
  CircularProgress,
  createTheme,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Radio,
  Select,
  Stack,
  TextField,
  ThemeProvider,
  useFormControl,
} from '@mui/material';
import { styled } from '@mui/styles';
import {
  H4Font,
  MiniBoldFont,
  SmallFont,
  testInputFont,
  textAreaNumberFont,
  RegularBoldFont,
  SmallBoldFont,
} from '@/components/FontStyle';
import SIPDetailAutocomChevronDown from '@/assets/SIPDetailAutocomChevronDown.svg';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import CanselListItem from '@/assets/CanselListItem.svg';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SIFilterCalendarIcon from '@/assets/SIFilterCalendarIcon.svg';
import GEDetailCloseIcon from '@/assets/GEDetailCloseIcon.svg';
import Scrollbars from 'react-custom-scrollbars';
import SIPDetailGEInfomationEditChevronDown from '@/assets/SIPDetailGEInfomationEditChevronDown.svg';
import SIPDetailGEInfomationRadioIcon from '@/assets/SIPDetailGEInfomationRadioIcon.svg';
import SIPDetailGEInfomationRadioSelectedIcon from '@/assets/SIPDetailGEInfomationRadioSelectedIcon.svg';
import SIPDetailGEInfomationUploadCVIcon from '@/assets/SIPDetailGEInfomationUploadCVIcon.svg';
import SIPDetailGEInfomationDeleteCVIcon from '@/assets/SIPDetailGEInfomationDeleteCVIcon.svg';
import { initCV } from '@/actions/SIMT-SIP/SIP/SIPAction';
import { useIntl } from 'react-intl';
import {replacePunctuationAndWhitespaceWithSpace, trimItemFilds} from '@/utils/commonUtils';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import moment from 'moment';
import AddNewFieldButton from '@/componentsphase2/Button/ContainerButtons/AddNewFieldButton';
import SimpleIconButton from '@/componentsphase2/Button/SimpleIconButton';
import TextFieldErrorIcon from '@/assets/TextFieldErrorIcon.svg';
import {DeleteCV, UploadCV} from "@/actions/SIMT-SIP/SIP/SipDetail";
// !!!important!!! 这个组件被src\pages\SIPPage\TransformToSI\CustomComponent\Modal.js(维护 by 黄俊学 2023-07-05 )复用，如果需要改样式请慎重!!!
const theme = createTheme({
  palette: {
    error: {
      main: '#EE5350',
    },
  },
});

/**
 * 这个ItemBlock 并不适用于弹窗的
 * @param {import("./EditComponents").ItemBlockProps} props
 * @returns
 */
export function ItemBlock(props) {
  const { title = '', mandatory = false, children, ...other } = props;
  return (
    <Box
      className='SIP-Detail-ItemBlock'
      {...other}
      data-selenium-id='SIP_DetailPage-EditComponents-ItemBlock-Box'
    >
      <Stack
        spacing={0}
        direction='row'
        data-selenium-id='SIP_DetailPage-EditComponents-ItemBlock-Stack'
      >
        <Box
          data-selenium-id='SIP_DetailPage-EditComponents-ItemBlock-TitleTextBox'
          className='SIP-Detail-ItemBlock-TitleTextBox'
          sx={{
            ...SmallFont,
            color: '#596A7C',
            width: '232px',
            flexShrink: 0,
            lineHeight: '16px',
          }}
        >
          {title}
          {mandatory ? (
            <span
              style={{
                marginLeft: '10px',
                ...MiniBoldFont,
                color: '#C40000',
                marginBottom: '2px',
              }}
            >
              *
            </span>
          ) : (
            <></>
          )}
        </Box>
        <Box
          sx={{ flexGrow: 1, width: '792px' }}
          data-selenium-id='SIP_DetailPage-EditComponents-ItemBlock-ChildrenBox'
        >
          {children}
        </Box>
      </Stack>
    </Box>
  );
}

/**
 *
 * @param {import("./EditComponents").DialogItemBlockProps} props
 * @returns
 */
export function DialogItemBlock(props) {
  const {
    title = '',
    subTitle,
    mandatory = false,
    direction = 'column',
    titleSX={},
    ...other
  } = props;
  return (
    <Box
      {...other}
      data-selenium-id='SIP_DetailPage-EditComponents-DialogItemBlock-Box'
      // style={{width:'50%'}}
    >
      <Stack
        spacing={0.5}
        direction={direction}
        data-selenium-id='SIP_DetailPage-EditComponents-DialogItemBlock-Stack'
      >
        <Stack spacing={0.5} direction='column'>
          <Box
            data-selenium-id='SIP_DetailPage-EditComponents-DialogItemBlock-TitleBox'
            sx={{
              ...RegularBoldFont,
              color: '#596A7C',
              width: direction === 'row' ? '140px' : '100%',
              marginRight: direction === 'row' ? '20px' : '0px',
              ...titleSX
            }}
            className='DialogItemBlock-title-box'
          >
            {title}
            {mandatory ? (
              <span
                style={{
                  marginLeft: '10px',
                  ...MiniBoldFont,
                  color: '#C40000',
                }}
              >
                *
              </span>
            ) : (
              <></>
            )}
          </Box>
          {subTitle ? (
            <Box
              sx={{
                ...testInputFont,
                color: '#596A7C',
                letterSpacing: '-1.1%',
              }}
            >
              {subTitle}
            </Box>
          ):null}
        </Stack>
        <Box
          data-selenium-id='SIP_DetailPage-EditComponents-DialogItemBlock-ChildrenBox'
          sx={{ width: direction === 'row' ? 'calc(100% - 170px)' : '100%' }}
        >
          {props.children}
        </Box>
      </Stack>
    </Box>
  );
}

export const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiOutlinedInput-root': {
    width: '100%',
    height: '33px',
    background: '#FFFFFF',
    ...testInputFont,
    borderRadius: '4px',
    boxSizing: 'border-box',
    padding: '8px 0px 8px 12px',
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '0px 0px',
    height: '17px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #DFE4E8',
  },
  '& .MuiAutocomplete-endAdornment': {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    top: '0',
    right: '8px',
  },
});

/**
 *
 * @param {import("./EditComponents").SIPDetailSelectBoxProps} props
 * @returns
 */
export const SIPDetailSelectBox = props => {
  //注意 这个inputValue 是value的显示值
  const { id, loadDropList, inputValue, error, helperText, ...other } = props;

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(null);
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    if (loadDropList === undefined) {
      return;
    }
    setLoading(true);
    async function loadOptions() {
      await dispatch(loadDropList(inputValue));
    }
    loadOptions().then(_r => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (loadDropList !== undefined) {
      setLoading(true);
      clearTimeout(timer);
      setTimer(
        setTimeout(async () => {
          async function loadOptions() {
            await dispatch(loadDropList(inputText));
          }

          loadOptions().then(_r => {
            setLoading(false);
          });
        }, 300)
      );
    }
  }, [inputText]);

  useEffect(() => {
    if (!open) {
      return;
    }
    if (loadDropList === undefined) {
      return;
    }
    async function loadOptions() {
      await dispatch(loadDropList(inputValue));
    }
    loadOptions().then(_r => {
      setLoading(false);
    });
  }, [open]);

  // {/* </IconButton> */}
  return (
    <StyledAutocomplete
      id={'autocomplete-' + id}
      data-selenium-id={`SIP_DetailPage-EditComponents-DialogItemBlock-SelectBox-${
        'autocomplete-' + id
      }`}
      filterOptions={x => x} //去掉组件默认的模糊查询
      popupIcon={
        // <IconButton sx={{ padding: "0px" }}>
        <SIPDetailAutocomChevronDown />
      }
      renderInput={params => (
        <TextField
          {...params}
          onChange={e => {
            setInputText(e.target.value);
          }}
          error={error}
          helperText={helperText}
        />
      )}
      loading={loading}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      {...other}
    />
  );
};

export const CustomTextFieldTypeOne = styled(TextField)({
  '&': {
    width: '100%',
  },
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    borderRadius: '4px',
    boxSizing: 'border-box',
    padding: '8px 12px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '0px 0px',
    ...testInputFont,
    color: '#262E35',
    height: '1.2em',
    letterSpacing: '-0.011em',
    '&::placeholder': {
      ...testInputFont,
      color: '#BCC5CF',
      opacity: '1',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #DFE4E8',
  },
});

export const CustomTextFieldTypeTwo = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    borderRadius: '4px',
    boxSizing: 'border-box',
    width: '100%',
    padding: '6.5px 12px',
    ...SmallFont,
  },
  '& .MuiOutlinedInput-input': {
    padding: '0px 0px',
    '&::placeholder': {
      ...SmallFont,
      color: '#BCC5CF',
      opacity: '1',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #DFE4E8',
  },
});

/*********************helperText *************************************** */
/**
 *
 * @param {import("./EditComponents").CustomInputAreaFormHelperTextProps} props
 * @returns
 */
export function CustomInputAreaFormHelperText(props) {
  const { propsError, propsHelperText, exceedErr, emptyErr, wordCountErr } =
    props;
  const { error } = useFormControl() || {};
  const helperText = useMemo(() => {
    if (error) {
      let helperText = '';
      if (propsError) {
        helperText = propsHelperText;
      } else if (exceedErr) {
        helperText = 'The character count should be no more than 10,000.';
      } else if (wordCountErr) {
        helperText = 'At least 500 characters must be filled in.';
      } else {
        helperText = 'This field is required.';
      }
      return helperText;
    }
    return '';
  }, [error, propsError, exceedErr, emptyErr, wordCountErr]);

  return (
    <FormHelperText data-selenium-id='SIP_DetailPage-EditComponents-CustonInputArea-HelperText'>
      {helperText}
    </FormHelperText>
  );
}

/**
 *
 * @param {import("./EditComponents").CustomInputAreaFormHelperTextProps} props
 * @returns
 */
export function CustomInputAreaFormHelperTextforSSIP(props) {
  const { propsError, propsHelperText, exceedErr, emptyErr, wordCountErr } =
    props;
  const { error } = useFormControl() || {};
  const helperText = useMemo(() => {
    if (error) {
      let helperText = '';
      if (propsError) {
        helperText = propsHelperText;
      } else if (exceedErr) {
        helperText = 'The character count should be no more than 10,000.';
      } else if (wordCountErr) {
        helperText = 'At least 100 words must be filled in.';
      } else {
        helperText = 'This field is mandatory for Open Call for Papers.';
      }
      return helperText;
    }
    return '';
  }, [error, propsError, exceedErr, emptyErr, wordCountErr]);

  return (
    <FormHelperText
      data-selenium-id='SIP_DetailPage-EditComponents-CustonInputArea-HelperText'
      sx={{
        marginTop: '-5px',
        width: '768px',
        fontSize: '12px',
        letterSpacing: 0,
        lineHeight: '16px',
      }}
    >
      {helperText}
    </FormHelperText>
  );
}

/**
 *
 * @param {import("./EditComponents").CustomInputFormHelperTextProps} props
 * @returns
 */
export function CustomInputFormHelperText(props) {
  const { propsError, propsHelperText, exceedErr, limitLength, emptyErr } =
    props;
  const { error } = useFormControl() || {};
  const helperText = useMemo(() => {
    if (error) {
      let helperText = '';
      if (propsError) {
        helperText = propsHelperText;
      } else if (exceedErr) {
        helperText = `Please input no more than ${limitLength} characters.`;
      } else {
        helperText = 'This field is required.';
      }
      return helperText;
    }
    return '';
  }, [error, propsError, exceedErr, emptyErr]);

  return (
    <FormHelperText
      sx={{ margin: '3px 0px 0px 0px' }}
      data-selenium-id='SIP_DetailPage-EditComponents-CustonInputForm-HelperText'
    >
      {helperText}
    </FormHelperText>
  );
}

/*********************************************************************************** */
/**
 *
 * @param {import("./EditComponents").CustomTextAreaProps} props
 * @returns
 */
export const CustomTextArea = props => {
  const {
    id = 'test',
    value = '',
    placeholder = '',
    minRows = 1,
    maxRows = 1,
    limitLength = 10000,
    minLength = 0,
    needWordCount = false, //是否需要单词计数
    hideWordCount = false, //是否隐藏单词计数
    needEmptyCheck = false,
    setValue = () => {},
    errorMonitor = false, // 用于监听 某一些错误的触发 true为要显示该错误，false为不显示
    setError = () => {},
    setIsShowHelperText = true,
  } = props;

  //用于textarea 下面那行 统计字符 的样式
  const textAreaNumberFont = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
  };

  const { formatNumber } = useIntl();

  useEffect(() => {
    setError(
      value.length > limitLength ||
        props.error ||
        (needEmptyCheck && (value === '' || value.length < minLength)) ||
        (needWordCount && trimItemFilds(replacePunctuationAndWhitespaceWithSpace(value ?? '')).split(' ').length < 100)
    );
  }, [value, errorMonitor, needEmptyCheck, needWordCount]);

  return (
    <ThemeProvider
      theme={theme}
      data-selenium-id='SIP_DetailPage-EditComponents-CustonTextArea-ThemeProvider'
    >
      <FormControl
        data-selenium-id='SIP_DetailPage-EditComponents-CustonTextArea-FormControl'
        variant='standard'
        sx={{ width: '100%' }}
        error={
          value.length > limitLength ||
          props.error ||
          (errorMonitor &&
            ( (needEmptyCheck && (value === '' || value.length < minLength)) ||
              (needWordCount && trimItemFilds(replacePunctuationAndWhitespaceWithSpace(value ?? '')).split(' ').length < 100)))
        }
      >
        <MyOnlyText
          width='100%'
          id={id}
          data-selenium-id={`SIP_DetailPage-EditComponents-CustonTextArea-ThemeProvider-${id}`}
          value={value}
          multiline
          maxRows={maxRows}
          minRows={minRows}
          placeholder={placeholder}
          // onChange={(e,v) => {
          //   setValue(v);
          // }}
          setValue={setValue}
          error={
            value.length > limitLength ||
            props.error ||
            (errorMonitor &&
              ( (needEmptyCheck && (value === '' || value.length < minLength)) ||
                (needWordCount &&
                    trimItemFilds(replacePunctuationAndWhitespaceWithSpace(value ?? '')).split(' ').length < 100)))
          }
        />
        <Box
          sx={{ height: '15px', marginTop: '4px', width: '100%' }}
          data-selenium-id='SIP_DetailPage-EditComponents-CustonTextArea-Box'
        >
          {needWordCount && !hideWordCount ? (
            <Box
              data-selenium-id='SIP_DetailPage-EditComponents-CustonTextArea-LeftBox'
              sx={{ float: 'left', ...textAreaNumberFont, color: '#596A7C' }}
            >
              words: {value === '' ? 0 : trimItemFilds(replacePunctuationAndWhitespaceWithSpace(value ?? '')).split(' ').length}
            </Box>
          ) : (
            <></>
          )}
          <Box
            data-selenium-id='SIP_DetailPage-EditComponents-CustonTextArea-RightBox'
            sx={{
              ...textAreaNumberFont,
              color: value.length > limitLength ? '#EE5350' : '#596A7C',
              float: 'right',
              width: '50px',
            }}
          >
            {/* {formatNumber(value.length)}/{formatNumber(limitLength)} */}
            {value.length}/{limitLength}
          </Box>
          {setIsShowHelperText && (
            <CustomInputAreaFormHelperText
              propsError={props.error}
              propsHelperText={props.helperText}
              exceedErr={value.length > limitLength}
              emptyErr={errorMonitor && needEmptyCheck && value === ''}
              wordCountErr={
                errorMonitor &&
                ( needEmptyCheck && value.length < minLength ||
                    needWordCount &&
                    trimItemFilds(replacePunctuationAndWhitespaceWithSpace(value ?? '')).split(' ').length < 100)
              }
            />
          )}
        </Box>
      </FormControl>
    </ThemeProvider>
  );
};
/*********************************************************************************** */
/**
 *
 * @param {import("./EditComponents").CustomTextAreaProps} props
 * @returns
 */
export const CustomTextAreaforSSIP = props => {
  const {
    id = 'test',
    value = '',
    placeholder = '',
    minRows = 1,
    maxRows = 1,
    limitLength = 10000,
    needWordCount = false, //是否需要单词计数
    hideWordCount = false, //是否隐藏单词计数
    needEmptyCheck = false,
    setValue = () => {},
    errorMonitor = false, // 用于监听 某一些错误的触发 true为要显示该错误，false为不显示
    setError = () => {},
    setIsShowHelperText = true,
  } = props;

  //用于textarea 下面那行 统计字符 的样式
  const textAreaNumberFont = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
  };

  const { formatNumber } = useIntl();

  useEffect(() => {
    setError(
      value.length > limitLength ||
        props.error ||
        (needEmptyCheck && value === '') ||
        (needWordCount && trimItemFilds(value).split(' ').length < 100)
    );
  }, [value, errorMonitor, needEmptyCheck, needWordCount]);

  return (
    <ThemeProvider
      theme={theme}
      data-selenium-id='SIP_DetailPage-EditComponents-CustonTextArea-ThemeProvider'
    >
      <FormControl
        data-selenium-id='SIP_DetailPage-EditComponents-CustonTextArea-FormControl'
        variant='standard'
        sx={{ width: '100%' }}
        error={
          value.length > limitLength ||
          props.error ||
          (errorMonitor &&
            ((needEmptyCheck && value === '') ||
              (needWordCount && trimItemFilds(value).split(' ').length < 100)))
        }
      >
        <MyOnlyText
          width='100%'
          id={id}
          data-selenium-id={`SIP_DetailPage-EditComponents-CustonTextArea-ThemeProvider-${id}`}
          value={value}
          multiline
          maxRows={maxRows}
          minRows={minRows}
          placeholder={placeholder}
          // onChange={(e,v) => {
          //   setValue(v);
          // }}
          setValue={setValue}
          error={
            value.length > limitLength ||
            props.error ||
            (errorMonitor &&
              ((needEmptyCheck && value === '') ||
                (needWordCount &&
                  trimItemFilds(value).split(' ').length < 100)))
          }
          sx={{ height: '81px' }}
        />
        <Box
          sx={{
            height: '15px',
            marginTop: '4px',
            width: '100%',
            display: 'flex',
          }}
          data-selenium-id='SIP_DetailPage-EditComponents-CustonTextArea-Box'
        >
          {needWordCount && !hideWordCount ? (
            <Box
              data-selenium-id='SIP_DetailPage-EditComponents-CustonTextArea-LeftBox'
              sx={{ float: 'left', ...textAreaNumberFont, color: '#596A7C' }}
            >
              words: {value === '' ? 0 : trimItemFilds(value).split(' ').length}
            </Box>
          ) : (
            <></>
          )}
          {setIsShowHelperText && (
            <>
              {errorMonitor && needEmptyCheck && value === '' ? (
                <IconButton
                  sx={{ padding: 0, paddingRight: '2px', paddingBottom: '6px' }}
                >
                  <TextFieldErrorIcon />
                </IconButton>
              ) : (
                <></>
              )}
              <CustomInputAreaFormHelperTextforSSIP
                propsError={props.error}
                propsHelperText={props.helperText}
                exceedErr={value.length > limitLength}
                emptyErr={errorMonitor && needEmptyCheck && value === ''}
                wordCountErr={
                  errorMonitor &&
                  ( value.length < limitLength ||
                      needWordCount && trimItemFilds(value).split(' ').length < 100)
                }
              />
            </>
          )}
          <Box
            data-selenium-id='SIP_DetailPage-EditComponents-CustonTextArea-RightBox'
            sx={{
              ...textAreaNumberFont,
              color: value.length > limitLength ? '#EE5350' : '#596A7C',
              float: 'right',
              width: '50px',
              marginTop: '-4px',
            }}
          >
            {/* {formatNumber(value.length)}/{formatNumber(limitLength)} */}
            {value.length}/{limitLength}
          </Box>
        </Box>
      </FormControl>
    </ThemeProvider>
  );
};

/**
 *
 * @param {import("./EditComponents").CustomTextFieldProps} props
 * @returns
 */
export const CustomTextField = props => {
  const {
    id = 'test',
    value = '',
    minRows = 1,
    maxRows = 1,
    placeholder = '',
    setValue = () => {},
    handleIconButtonClick = () => {},
    needEndAdornment = false,
    limitLength = 10000,
    errorMonitor = false,
    needEmptyCheck = false,
  } = props;
  return (
    <ThemeProvider
      theme={theme}
      data-selenium-id='SIP_DetailPage-EditComponents-CustonTextField-ThemeProvider'
    >
      <FormControl
        data-selenium-id='SIP_DetailPage-EditComponents-CustonTextField-FormControl'
        error={
          props.error ||
          value.length > limitLength ||
          (errorMonitor && needEmptyCheck && value === '')
        }
        sx={{ width: '100%' }}
      >
        {needEndAdornment ? (
          <CustomTextFieldTypeTwo
            id={id}
            data-selenium-id={`SIP_DetailPage-EditComponents-CustonTextField-ThemeProvider-${id}`}
            value={value}
            multiline
            minRows={minRows}
            maxRows={maxRows}
            placeholder={placeholder}
            onChange={e => {
              setValue(e.target.value);
            }}
            error={
              props.error ||
              value.length > limitLength ||
              (errorMonitor && value === '')
            }
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position='end'
                  data-selenium-id='SIP_DetailPage-EditComponents-CustonTextField-InputAdornment'
                >
                  <SimpleIconButton
                    id={`${id}-endAdornment-botton`}
                    data-selenium-id={`SIP_DetailPage-EditComponents-CustonTextField-${id}-endAdornment-botton`}
                    onClick={() => {
                      handleIconButtonClick();
                    }}
                    sx={{ padding: '0px' }}
                    DefaultIcon={CanselListItem}
                  />
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <CustomTextFieldTypeTwo
            id={id}
            data-selenium-id={`SIP_DetailPage-EditComponents-CustonTextField-ThemeProvider-${id}`}
            value={value}
            multiline
            minRows={minRows}
            maxRows={maxRows}
            placeholder={placeholder}
            onChange={e => {
              setValue(e.target.value);
            }}
            error={
              props.error ||
              value.length > limitLength ||
              (errorMonitor && needEmptyCheck && value === '')
            }
          />
        )}
      </FormControl>
    </ThemeProvider>
  );
};

/**
 *
 * @param {import("./EditComponents").CustomInputListProps} props
 * @returns
 */
export const CustomInputList = props => {
  const {
    id = 'test',
    promptTitle = ' ',
    value = [],
    setValue = value => {},
    maxRows = 1,
    minItems = 1,
    maxItems = 1,
    limitLength = 10000,
    errorMonitor = false,
    needEmptyCheck = false,
    snackbarMessage = '',
    exceedErrText = '',
    setError = () => {},
    bottomHelperText = '',
    isSSIP = false,
  } = props;

  const dispatch = useDispatch();

  //用于检查是否达成n个不为空的要求 仅在needEmptyCheck = true的时候调用
  const isUptostandard = () => {
    let count = 0;
    for (let i = 0; i < value.length; i++) {
      if (value[i] === '') {
        continue;
      }
      count++;
    }
    return count >= minItems;
  };

  //遍历value的所有值判断是否存在某个值超过了限定的字数
  const isExceed = () => {
    for (let i = 0; i < value.length; i++) {
      if (value[i].length > limitLength) {
        return true;
      }
    }
    return false;
  };

  const ErrBox = () => {
    let errText = '';
    if (isExceed()) {
      errText = exceedErrText;
    } else if (errorMonitor && needEmptyCheck && !isUptostandard()) {
      if (id.includes('Keyword')) {
        errText = 'At least 3 keywords must be inputted.';
      } else if (id.includes('Reference')) {
        errText = 'At least one reference must be inputted.';
      } else {
        errText = 'At least 3 topics must be inputted.';
      }
    }
    return (
      <Box
        data-selenium-id='SIP_DetailPage-EditComponents-CustomInputList-ErrorBox'
        sx={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '18px',
          color: '#EE5350',
        }}
      >
        {errText}
      </Box>
    );
  };

  useEffect(() => {
    setError(isExceed() || (needEmptyCheck && !isUptostandard()));
  }, [value, needEmptyCheck]);
  return (
    <Box data-selenium-id='SIP_DetailPage-EditComponents-CustomInputList-Box'>
      <Stack
        spacing={0.5}
        data-selenium-id='SIP_DetailPage-EditComponents-CustomInputList-Stack'
      >
        {value.map((item, index) => (
          // <CustomTextField
          //   id={`${id}-${index}`}
          //   data-selenium-id={`SIP_DetailPage-EditComponents-CustomInputList-${id}-${index}`}
          //   key={`${id}-${index}`}
          //   value={item}
          //   maxRows={maxRows}
          //   setValue={newValue => {
          //     setValue(
          //       value.map((valueItem, valueItemIndex) => {
          //         if (valueItemIndex === index) return newValue;
          //         return valueItem;
          //       })
          //     );
          //   }}
          //   handleIconButtonClick={
          //     index >= minItems
          //       ? () => {
          //         let newArr = value.concat();
          //         newArr.splice(index, 1);
          //         setValue(newArr);
          //       }
          //       : () => { }
          //   }
          //   needEndAdornment={index >= minItems}
          //   limitLength={limitLength}
          //   error={errorMonitor && needEmptyCheck && !isUptostandard()}
          //   helperText={''}
          // />
          <MyOnlyText
            id={`${id}-${index}`}
            width={'100%'}
            data-selenium-id={`SIP_DetailPage-EditComponents-CustomInputList-${id}-${index}`}
            key={`${id}-${index}`}
            value={item}
            width={isSSIP ? '792px' : '100%'}
            height={'33px'}
            alwaysHaveEndAdornment={true}
            maxRows={maxRows}
            setValue={newValue => {
              setValue(
                value.map((valueItem, valueItemIndex) => {
                  if (valueItemIndex === index) return newValue;
                  return valueItem;
                })
              );
            }}
            handleIconButtonClick={
              index >= minItems
                ? () => {
                    let newArr = value.concat();
                    newArr.splice(index, 1);
                    setValue(newArr);
                  }
                : () => {}
            }
            needEndAdornment={index >= minItems}
            limitLength={limitLength}
            isError={errorMonitor && needEmptyCheck && !isUptostandard()}
            helperText={''}
            placeholder={
              id === 'ssipReviseTopicsInput'
                ? 'Enter Topic ' + (index + 1)
                : id === 'ssipReviseKeywordsInput'
                ? 'Enter Keywords'
                : 'Please type the proposed ' + promptTitle + '.'
            }
          />
        ))}
      </Stack>
      <Box
        sx={{ marginTop: '2px', height: '18px', width: '100%' }}
        data-selenium-id='SIP_DetailPage-EditComponents-CustomInputList-InnerBox'
      >
        <Box
          sx={{ float: 'left' }}
          data-selenium-id='SIP_DetailPage-EditComponents-CustomInputList-LeftBox'
        >
          <Box
            data-selenium-id='SIP_DetailPage-EditComponents-CustomInputList-HelperTextBox'
            sx={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '16px',
              color: '#596A7C',
            }}
          >
            {bottomHelperText}
          </Box>
          <ErrBox></ErrBox>
        </Box>
        {/* <Box
          data-selenium-id='SIP_DetailPage-EditComponents-CustomInputList-RightBox'
          sx={{
            ...MiniBoldFont,
            color: '#154AB6',
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={() => {
            if (value.length === maxItems) {
              dispatch(
                setSnackbarMessageAndOpen(snackbarMessage, {}, SEVERITIES.error)
              );
              return;
            }
            let newArr = value.concat();
            newArr.push('');
            setValue(newArr);
          }}
        >
          + Add
        </Box> */}
        <AddNewFieldButton
          data-selenium-id='SIP_DetailPage-EditComponents-CustomInputList-RightBox'
          sx={{
            ...MiniBoldFont,
            color: '#154AB6',
            cursor: 'pointer',
            float: 'right',
            padding: '0',
          }}
          onClick={() => {
            if (value.length === maxItems) {
              dispatch(
                setSnackbarMessageAndOpen(snackbarMessage, {}, SEVERITIES.error)
              );
              return;
            }
            let newArr = value.concat();
            newArr.push('');
            setValue(newArr);
          }}
        />
      </Box>
    </Box>
  );
};

/**
 *
 * @param {import("./EditComponents").CustomInputListProps} props
 * @returns
 */
export const CustomInputListforSSIP = props => {
  const {
    id = 'test',
    promptTitle = ' ',
    value = [],
    setValue = value => {},
    maxRows = 1,
    minItems = 1,
    maxItems = 1,
    limitLength = 10000,
    errorMonitor = false,
    needEmptyCheck = false,
    snackbarMessage = '',
    exceedErrText = '',
    setError = () => {},
    bottomHelperText = '',
  } = props;

  const dispatch = useDispatch();

  //用于检查是否达成n个不为空的要求 仅在needEmptyCheck = true的时候调用
  const isUptostandard = () => {
    let count = 0;
    for (let i = 0; i < value.length; i++) {
      if (value[i] === '') {
        continue;
      }
      count++;
    }
    return count >= minItems;
  };

  //遍历value的所有值判断是否存在某个值超过了限定的字数
  const isExceed = () => {
    for (let i = 0; i < value.length; i++) {
      if (value[i].length > limitLength) {
        return true;
      }
    }
    return false;
  };
  const ErrBox = () => {
    let errText = '';
    if (isExceed()) {
      errText = exceedErrText;
    } else if (errorMonitor && needEmptyCheck && !isUptostandard()) {
      errText = 'This field is mandatory for Open Call for Papers.';
    }
    return (
      <div style={{ display: 'flex' }}>
        {errText !== '' ? (
          <IconButton sx={{ padding: 0, paddingRight: '2px' }}>
            <TextFieldErrorIcon></TextFieldErrorIcon>
          </IconButton>
        ) : (
          <></>
        )}
        <Box
          data-selenium-id='SIP_DetailPage-EditComponents-CustomInputList-ErrorBox'
          sx={{
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '16px',
            color: '#EE5350',
            marginTop: '-3px',
          }}
        >
          {errText}
        </Box>
      </div>
    );
  };

  useEffect(() => {
    setError(isExceed() || (needEmptyCheck && !isUptostandard()));
  }, [value, needEmptyCheck]);
  return (
    <Box data-selenium-id='SIP_DetailPage-EditComponents-CustomInputList-Box'>
      <Stack
        spacing={0.5}
        data-selenium-id='SIP_DetailPage-EditComponents-CustomInputList-Stack'
      >
        {value.map((item, index) => (
          <>
            <MyOnlyText
              id={`${id}-${index}`}
              width={'100%'}
              data-selenium-id={`SIP_DetailPage-EditComponents-CustomInputList-${id}-${index}`}
              key={`${id}-${index}`}
              value={item}
              width={'792px'}
              height={'33px'}
              alwaysHaveEndAdornment={true}
              maxRows={maxRows}
              setValue={newValue => {
                setValue(
                  value.map((valueItem, valueItemIndex) => {
                    if (valueItemIndex === index) return newValue;
                    return valueItem;
                  })
                );
              }}
              handleIconButtonClick={
                index >= minItems
                  ? () => {
                      let newArr = value.concat();
                      newArr.splice(index, 1);
                      setValue(newArr);
                    }
                  : () => {}
              }
              needEndAdornment={index >= minItems}
              limitLength={limitLength}
              isError={errorMonitor && needEmptyCheck && !isUptostandard()}
              helperText={''}
              placeholder={
                id === 'ssipReviseTopicsInput'
                  ? 'Enter Topic ' + (index + 1)
                  : id === 'ssipReviseKeywordsInput'
                  ? 'Enter Keywords'
                  : 'Please type the proposed ' + promptTitle + '.'
              }
            />
            {(errorMonitor && needEmptyCheck && !isUptostandard()) ||
            index === value.length - 1 ? (
              <Box
                sx={{
                  marginTop: '2px!important',
                  height: '18px',
                  width: '100%',
                }}
                data-selenium-id='SIP_DetailPage-EditComponents-CustomInputList-InnerBox'
              >
                <Box
                  sx={{ float: 'left' }}
                  data-selenium-id='SIP_DetailPage-EditComponents-CustomInputList-LeftBox'
                >
                  <Box
                    data-selenium-id='SIP_DetailPage-EditComponents-CustomInputList-HelperTextBox'
                    sx={{
                      fontFamily: 'Open Sans',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '12px',
                      lineHeight: '16px',
                      color: '#596A7C',
                    }}
                  >
                    {bottomHelperText}
                  </Box>
                  <ErrBox></ErrBox>
                </Box>
                {index === value.length - 1 ? (
                  <AddNewFieldButton
                    data-selenium-id='SIP_DetailPage-EditComponents-CustomInputList-RightBox'
                    sx={{
                      ...MiniBoldFont,
                      color: '#154AB6',
                      cursor: 'pointer',
                      float: 'right',
                      padding: '0',
                    }}
                    onClick={() => {
                      if (value.length === maxItems) {
                        dispatch(
                          setSnackbarMessageAndOpen(
                            snackbarMessage,
                            {},
                            SEVERITIES.error
                          )
                        );
                        return;
                      }
                      let newArr = value.concat();
                      newArr.push('');
                      setValue(newArr);
                    }}
                  />
                ) : (
                  <></>
                )}
              </Box>
            ) : (
              <></>
            )}
          </>
        ))}
      </Stack>
    </Box>
  );
};

/**
 *
 * @param {import("./EditComponents").DatePickerProp} props
 * @returns
 */
export function DatePicker(props) {
  const { value, setValue, ...other } = props;

  const [dateOpen, setDateOpen] = useState(false);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      data-selenium-id='SIP_DetailPage-EditComponents-DatePicker-LocalizationProvider'
    >
      <DesktopDatePicker
        data-selenium-id='SIP_DetailPage-EditComponents-DatePicker-Desktop'
        inputFormat='yyyy-MM-dd'
        mask='____-__-__'
        value={value}
        onChange={newValue => {
          setValue(moment(newValue).utc().format('YYYY-MM-DD'));
        }}
        open={dateOpen}
        onOpen={() => {
          setDateOpen(true);
        }}
        onClose={() => {
          setDateOpen(false);
        }}
        renderInput={params => (
          <TextField
            data-selenium-id='SIP_DetailPage-EditComponents-DatePicker-Desktop-TextField'
            sx={{
              '&': {
                // width: '100%',
                width: '790px',
              },
              '& .MuiOutlinedInput-root': {
                background: '#FFFFFF',
                borderRadius: '4px',
                boxSizing: 'border-box',
                height: '33px',
                ...testInputFont,
                color: '#262E35',
                padding: '8px 0px 8px 12px',
              },
              '& .MuiOutlinedInput-input': {
                padding: '0px 0px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #DFE4E8',
              },
            }}
            fullWidth
            variant='outlined'
            {...params}
            InputProps={{
              endAdornment: (
                <IconButton
                  data-selenium-id='SIP_DetailPage-EditComponents-DatePicker-Desktop-IconButton'
                  sx={{ padding: '7px' }}
                  onClick={() => {
                    setDateOpen(!dateOpen);
                  }}
                >
                  <SIFilterCalendarIcon data-selenium-id='SIP_DetailPage-EditComponents-DatePicker-Desktop-SIFilterCalendarIcon ' />
                </IconButton>
              ),
            }}
          />
        )}
        {...other}
      />
    </LocalizationProvider>
  );
}

/**************************GEInfomation edit 相关的组件********************************** */

export const GEInfomationInputBase = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    borderRadius: '4px',
    boxSizing: 'border-box',
    padding: '5px 12px',
    height: '36px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '0px 0px',
    ...testInputFont,
    color: '#262E35',
    lineHeight: '26px',
    height: '26px',
    '&::placeholder': {
      ...testInputFont, //lineheight 不满足ui 要求
      lineHeight: '26px',
      color: '#BCC5CF',
      opacity: '1',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #505050',
  },
});

/**
 *
 * @param {import("./EditComponents").GEInfomationModalProps} props
 * @returns
 */
export function GEInfomationModal(props) {
  const { title = '', open = false, handleClose = () => {} } = props;

  const dispatch = useDispatch();

  const ContainerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '593px',
    padding: '32px 35px 0px',
    background: '#FFFFFF',
    boxSizing: 'border-box',
  };

  const HeaderStyle = {
    width: '100%',
    height: '32px',
    marginBottom: '20px',
  };

  const ContentStyle = {
    width: '100%',
    marginBottom: '28px',
    height: '330px',
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationModal'
      >
        <Box
          sx={ContainerStyle}
          data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationModal-Box'
        >
          <Box
            sx={HeaderStyle}
            data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationModal-HeaderBox'
          >
            <Box
              data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationModal-LeftBox'
              sx={{
                ...H4Font,
                height: '28px',
                float: 'left',
                color: '#262E35',
              }}
            >
              {title}
            </Box>
            <IconButton
              data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationModal-IconButton'
              sx={{ float: 'right', cursor: 'pointer' }}
              onClick={handleClose}
            >
              <GEDetailCloseIcon data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationModal-GEDetailCloseIcon' />
            </IconButton>
          </Box>
          <Scrollbars
            data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationModal-Scrollbars'
            style={ContentStyle}
            renderView={props => (
              <div
                {...props}
                style={{
                  position: 'absolute',
                  inset: ' 0px',
                  overflow: 'auto',
                  marginRight: '-10px',
                  marginBottom: ' 1px',
                }}
              ></div>
            )}
            renderThumbVertical={props => (
              <div
                {...props}
                style={{
                  width: '8px',
                  backgroundColor: '#D9D9D9',
                  opacity: '0.7',
                  borderRadius: '10px',
                  right: '0px',
                  transform: 'rotate(-90deg)',
                }}
              ></div>
            )}
          >
            {props.children}
          </Scrollbars>
        </Box>
      </Modal>
    </div>
  );
}

/**
 *
 * @param {import("./EditComponents").GEInfomationEditInputProps} props
 * @returns
 */
export function GEInfomationEditInput(props) {
  const {
    id = 'test',
    value = '',
    setValue = newValue => {},
    placeholder = '',
    needFormatCheck = false,
    pattern = '',
    setFormatError = value => {},
    needEmptyCheck = false,
    errorMonitor = false,
    width,
  } = props;
  const [formatErr, setFormatErr] = useState(false);
  return (
    <ThemeProvider
      theme={theme}
      data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationEditInput-ThemeProvider'
    >
      <GEInfomationInputBase
        id={id}
        data-selenium-id={`SIP_DetailPage-EditComponents-GEInfomationEditInput-${id}`}
        value={value}
        sx={{ width: width }}
        placeholder={placeholder}
        onChange={e => {
          setValue(e.target.value);
        }}
        onBlur={() => {
          if (needFormatCheck && value !== '') {
            setFormatErr(!pattern.test(value));
            setFormatError(!pattern.test(value));
          }
        }}
        onFocus={() => {
          setFormatErr(false);
          setFormatError(false);
        }}
        error={
          formatErr ||
          props.error ||
          (errorMonitor && needEmptyCheck && value === '')
        }
      />
    </ThemeProvider>
  );
}

/**
 *
 * @param {import("./EditComponents").GEInfomationEditSelectProps} props
 * @returns
 */
export function GEInfomationEditSelect(props) {
  const { id = 'test', dropList = [], value = '', ...others } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const IconComponent = () => {
    if (open) {
      return (
        <IconButton
          data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationEditSelect-Open-IconButton'
          sx={{
            transform: 'rotate(180deg)',
            position: 'absolute',
            right: '7px',
            padding: '0px',
          }}
          onClick={() => {
            setOpen(false);
          }}
        >
          <SIPDetailGEInfomationEditChevronDown data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationEditSelect-Open-ChevronDown' />
        </IconButton>
      );
    } else {
      return (
        <IconButton
          data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationEditSelect-Close-IconButton'
          onClick={() => {
            setOpen(true);
          }}
          sx={{ position: 'absolute', right: '7px', padding: '0px' }}
        >
          <SIPDetailGEInfomationEditChevronDown data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationEditSelect-Close-ChevronDown' />
        </IconButton>
      );
    }
  };
  function hasValue(value) {
    if (value) {
      if (value instanceof Array) {
        // 数组情况，长度非0， 零号元素非空
        return value.length > 0 && value[0] && value[0] !== '';
      } else return value !== '';
    } else {
      return false;
    }
  }
  return (
    <ThemeProvider
      theme={theme}
      data-selenium-id='SIP_DetailPage-EditComponents-HasValue-ThemeProvider'
    >
      <Select
        {...others}
        sx={{
          '&': { width: '398px' },
          '& .MuiSelect-select': {
            background: '#FFFFFF',
            borderRadius: '4px',
            boxSizing: 'border-box',
            padding: '5px 12px',
            ...testInputFont,
            color: hasValue(value) ? '#262E35' : '#BCC5CF',
            lineHeight: '26px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #505050',
          },
        }}
        id={id}
        data-selenium-id={`SIP_DetailPage-EditComponents-HasValue-${id}`}
        value={value}
        IconComponent={IconComponent}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        displayEmpty
        renderValue={value => {
          return hasValue(value) ? value : 'Please select...';
        }}
      >
        {dropList.map((item, index) => (
          <MenuItem
            value={item}
            key={`${id}-${index}`}
            data-selenium-id='SIP_DetailPage-EditComponents-HasValue-ThemeProvider-MenuItem'
          >
            {item}
          </MenuItem>
        ))}
      </Select>
    </ThemeProvider>
  );
}

/**
 *
 * @param {import("./EditComponents").GEInfomationEditAutoCompleteProps} props
 * @returns
 */
export function GEInfomationEditAutoComplete(props) {
  //注意 这个inputValue 是value的显示值
  const {
    id,
    loadDropList,
    inputValue,
    needEmptyCheck = false,
    errorMonitor = false,
    value = '',
    ...other
  } = props;

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(null);
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    if (loadDropList !== undefined) {
      setLoading(true);
      clearTimeout(timer);
      setTimer(
        setTimeout(async () => {
          async function loadOptions() {
            await dispatch(loadDropList(inputText));
          }

          loadOptions().then(_r => {
            setLoading(false);
          });
        }, 300)
      );
    }
  }, [inputText]);

  useEffect(() => {
    if (!open || loadDropList === undefined) {
      return;
    }
    async function loadOptions() {
      await dispatch(loadDropList(inputValue));
    }

    loadOptions().then(_r => {
      setLoading(false);
    });
  }, [open]);

  return (
    <ThemeProvider
      theme={theme}
      data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationEditAutoComplete-ThemeProvider'
    >
      <Autocomplete
        id={'autocomplete-' + id}
        data-selenium-id={`SIP_DetailPage-EditComponents-GEInfomationEditAutoComplete-${
          'autocomplete-' + id
        }`}
        value={value}
        sx={{
          height: '40px',
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '0px',
          },
          '& .MuiInputBase-root': {
            border: '1px solid #DFE4E8',
          },
        }}
        filterOptions={x => x} //去掉组件默认的模糊查询
        popupIcon={
          <IconButton sx={{ padding: '0px' }}>
            <SIPDetailGEInfomationEditChevronDown />
          </IconButton>
        }
        renderInput={params => (
          <GEInfomationInputBase
            {...params}
            onChange={e => {
              setInputText(e.target.value);
            }}
            placeholder='Please select...'
            sx={{ width: '398px' }}
            error={errorMonitor && needEmptyCheck && value === ''}
          />
        )}
        loading={loading}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        {...other}
      />
    </ThemeProvider>
  );
}

/**
 *
 * @param {import("./EditComponents").GEInfomationEditRadioProps} props
 * @returns
 */
export function GEInfomationEditRadio(props) {
  return (
    <Radio
      data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationEditRadio'
      disableRipple
      checkedIcon={
        <SIPDetailGEInfomationRadioSelectedIcon data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationEditRadio-SelectedIcon ' />
      }
      icon={<SIPDetailGEInfomationRadioIcon />}
      {...props}
    />
  );
}
export const LeadGEFormControlLabel = styled(FormControlLabel)({
  '&': {
    margin: '0px 40px 0px 0px',
  },
  '& .MuiFormControlLabel-label': {
    marginLeft: '3px',
    color: '#262E35',
    ...SmallFont,
  },
});

export const ConfilctFormControlLabel = styled(FormControlLabel)({
  '&': {
    margin: '0px 0px 0px 0px',
  },
  '& .MuiFormControlLabel-label': {
    marginLeft: '12px',
    color: '#505050',
    ...testInputFont,
    letterSpacing: '-0.011em',
    height: '32px',
    lineHeight: '32px',
  },
});

/**
 *
 * @param {import("./EditComponents").UplaodCVNoFileProps} props
 * @returns
 */
export const UplaodCVNoFile = props => {
  const { setLoading } = props;
  const dispatch = useDispatch();
  const upload = event => {
    dispatch(UploadCV({ cv: event.target.files[0] }));
    setLoading(true);
  };
  return (
    <Box
      data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationEditRadio-Box'
      sx={{
        width: '398px',
        padding: '8px 12px',
        boxSizing: 'border-box',
        backgroundColor: '#F1F3F5',
        border: '1px solid #DFE4E8',
        borderRadius: '5px',
        height: '36px',
      }}
    >
      <Box
        data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationEditRadio-LeftBox'
        sx={{
          ...SmallBoldFont,
          color: '#596A7C',
          letterSpacing: '0.01em',
          float: 'left',
        }}
      >
        Upload CV
      </Box>
      <IconButton
        data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationEditRadio-Box-IconButton'
        sx={{ padding: '0px', float: 'right' }}
        onClick={() => {
          document.getElementById('UploadCVFileInput').click();
        }}
      >
        <SIPDetailGEInfomationUploadCVIcon data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationEditRadio-UploadCVIcon'></SIPDetailGEInfomationUploadCVIcon>
      </IconButton>
      <input
        id='UploadCVFileInput'
        data-selenium-id='SIP_DetailPage-EditComponents-GEInfomationEditRadio-UploadCVFileInput'
        type='file'
        style={{ display: 'none' }}
        onChange={event => {
          upload(event);
          event.target.value = ''; // 清除这个值，以允许重复选择同一个文件
        }}
      />
    </Box>
  );
};

/**
 *
 * @param {import("./EditComponents").UplaodCVHasFileProps} props
 * @returns
 */
export const UplaodCVHasFile = props => {
  const {
    loading,
    id,
    defaultFile,
    hasDefaultFile = true,
    setHasDefaulFile = () => {},
  } = props;
  const dispatch = useDispatch();
  return (
    <Box
      data-selenium-id='SIP_DetailPage-EditComponents-UploadCVHasFile-Box'
      sx={{
        width: '398px',
        padding: '8px 12px',
        boxSizing: 'border-box',
        backgroundColor: '#F1F3F5',
        border: '1px solid #DFE4E8',
        borderRadius: '5px',
        height: '36px',
      }}
    >
      <Box
        data-selenium-id='SIP_DetailPage-EditComponents-UploadCVHasFile-LeftBox'
        sx={{
          ...SmallBoldFont,
          color: '#154AB6',
          letterSpacing: '0.01em',
          float: 'left',
        }}
      >
        CV
      </Box>
      <Box
        sx={{ float: 'right' }}
        data-selenium-id='SIP_DetailPage-EditComponents-UploadCVHasFile-RightBox'
      >
        {loading ? (
          <CircularProgress
            size={20}
            data-selenium-id='SIP_DetailPage-EditComponents-UploadCVHasFile-CircularProgress'
          />
        ) : (
          <IconButton
            data-selenium-id='SIP_DetailPage-EditComponents-UploadCVHasFile-RightBox-IconButton'
            sx={{ padding: '0px', float: 'right' }}
            onClick={() => {
              if (defaultFile !== null && hasDefaultFile) {
                dispatch(initCV(null));
                setHasDefaulFile(false);
              } else {
                dispatch(DeleteCV(id));
              }
            }}
          >
            <SIPDetailGEInfomationDeleteCVIcon data-selenium-id='SIP_DetailPage-EditComponents-UploadCVHasFile-RightBox-DeleteCVIcon' />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

import { axios_instance } from "@/utils/axios_instance";
import {
    CHECK_TITLE,
    CREATE_SI,
    MANDATORY_FIELD,
    Reactive_SI,
    SET_SICODE_TO_JUMP,
    SUBMISSION_JUDGE,
    updatePop
} from "@/actions/SIMT-SI/SpecialIssue/SIAction";
import { fakeAxiosSuccessResponse, isTimeoutError } from "@/utils/commonUtils";
import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";

const errorMessageKey = 'siPage.createError';
const timeoutErrorMessageKey = 'siPage.createTimeoutError';

export function getMandatoryFields() {
    const storageKeysList = [
        { key: 'AcquiredMandatoryList', value: 'Acquired' },
        { key: 'IdeaProposalMandatoryList', value: 'Idea/Proposal' },
        { key: 'PaperCommissionMandatoryList', value: 'Paper Commission' },
        { key: 'ProductionMandatoryList', value: 'Production' },
        { key: 'PublishedMandatoryList', value: 'Published' },
        { key: 'PublishedVMandatoryList', value: 'PublishedV' },
        { key: 'SubmissionAndReviewMandatoryList', value: 'Submission and Review' },
        { key: 'ShelvedMandatoryList', value: 'Shelved' }
    ];
    return async dispatch => {
        try {
            const url = '/api/v1/si-service/special-issues/SIP-0000-000000';
            const res = await axios_instance.get(url, {
                params: {
                    getContent: 'SnowflakeSubmission',
                    hasMandatoryFields: true
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const { code, data } = res.data;
            if (code === 200) {
                dispatch({ type: MANDATORY_FIELD, data: 'true' });
                storageKeysList.forEach((row) => {
                    localStorage.setItem(row.key, data?.mandatoryFields[row.value]);
                });
            } else {
                dispatch({ type: MANDATORY_FIELD, data: 'error' });
            }
        } catch {
            dispatch({ type: MANDATORY_FIELD, data: 'error' });
        }
    };
}

/**
 *
 * @param {import("@/pages/SIPPage/TransformToSI/Submodule/TransformSteps/AcquiredSiCreation").SiCreateInAcquiredPreDto} value
 * @param {"debug" | "release" | undefined} mode
 * @returns {(dispatch: import("redux").Dispatch) => Promise<void>}
 */
export function createSIInAcquited(value, mode = 'release') {
    return async dispatch => {
        try {
            const url = '/api/v1/si-service/special-issues';
            /** @type {import("@/utils/axios-response").AxiosResponse<string>} */
            const res =
                mode === 'release'
                    ? await axios_instance.post(
                        url,
                        {
                            inAcquiredRequests: {
                                acquiredDate: value.acquiredDate,
                                expectedAcceptanceDate: value.expectedAcceptanceDate,
                                expectedPublicationDate: value.expectedPublicationDate,
                                expectedSubmissionDeadline: value.expectedSubmissionDeadline,
                                leadGuestEditors: value.leadGuestEditors,
                                coGuestEditors: value.coGuestEditors,
                                handlingCe: value.handlingCe,
                                ideaDate:
                                    value.ideaDate === 'Invalid date' ? '' : value.ideaDate,
                                journalCode: value.journalCode,
                                journalName: value.journalName,
                                paperCommissioningMethod: value.paperCommitioningMethod,
                                siSource: value.siSource,
                                specialIssueTitle: value.specialIssueTitle,
                                specialIssueType: value.specialIssueType,
                                stage: value.stage,
                                workflow: value.workflow,
                                siRevenueModel: value.siRevenueModel,
                                sipCode: value.sipCode,
                            }
                        },
                        {
                            headers: {
                                authorization: localStorage.getItem('authorization') || '',
                            },
                        }
                    )
                    : await fakeAxiosSuccessResponse('SI-2022-011161');
            if (res.data.code === 200) {
                dispatch({ type: CREATE_SI, data: res.data });
                // dispatch(
                //   setSnackbarMessageAndOpen('siPage.createSucc', {}, SEVERITIES.success)
                // );
            } else {
                dispatch({ type: CREATE_SI, data: 'error' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        errorMessageKey,
                        {
                            value: res.data.message,
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({ type: CREATE_SI, data: 'error' });
            dispatch(
                setSnackbarMessageAndOpen(
                    errorMessageKey,
                    {
                        value: 'test',
                    },
                    SEVERITIES.error
                )
            );
            if (isTimeoutError(err)) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        timeoutErrorMessageKey,
                        SEVERITIES.error
                    )
                );
            }
        }
    };
}

export function createSIInIdea(value) {
    // console.log(value)
    return async dispatch => {
        try {
            const url = '/api/v1/si-service/special-issues';
            const res = await axios_instance.post(
                url,
                {
                    inIdeaOrProposalRequests:
                    {
                        handlingCe: value.handlingCe,
                        journalCode: value.journalCode,
                        journalName: value.journalName,
                        ideaDate: value.ideaDate === 'Invalid date' ? '' : value.ideaDate,
                        siSource: value.siSource,
                        specialIssueTitle: value.specialIssueTitle,
                        specialIssueType: value.specialIssueType,
                        stage: value.stage,
                        siRevenueModel: value.siRevenueModel,
                    }
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: CREATE_SI, data: res.data });
                // dispatch(
                //   setSnackbarMessageAndOpen('siPage.createSucc', {}, SEVERITIES.success)
                // );
            } else {
                dispatch({ type: CREATE_SI, data: 'error' });
                dispatch(
                    setSnackbarMessageAndOpen(errorMessageKey, {}, SEVERITIES.error)
                );
            }
        } catch (err) {
            dispatch({ type: CREATE_SI, data: 'error' });
            dispatch(
                setSnackbarMessageAndOpen(errorMessageKey, {}, SEVERITIES.error)
            );
            if (isTimeoutError(err)) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        timeoutErrorMessageKey,
                        SEVERITIES.error
                    )
                );
            }
        }
    };
}

export function createSIInPaperCommission(value) {
    return async dispatch => {
        try {
            const url = '/api/v1/si-service/special-issues';
            const res = await axios_instance.post(
                url,
                {
                    inPaperCommissionRequests: {
                        acquiredDate: value.acquiredDate,
                        closedForSubmission: value.closedForSubmission,
                        expectedAcceptanceDate: value.expectedAcceptanceDate,
                        expectedPublicationDate: value.expectedPublicationDate,
                        expectedSubmissionDeadline: value.expectedSubmissionDeadline,
                        leadGuestEditors: value.leadGuestEditors,
                        coGuestEditors: value.coGuestEditors,
                        handlingCe: value.handlingCe,
                        ideaDate: value.ideaDate === 'Invalid date' ? '' : value.ideaDate,
                        journalCode: value.journalCode,
                        journalName: value.journalName,
                        numberOfAcceptedArticles: value.numberOfAcceptedArticles,
                        numberOfRecruitedArticles: value.numberOfRecruitedArticles,
                        numberOfRejectedArticles: value.numberOfRejectedArticles,
                        numberOfSubmittedArticles: value.numberOfSubmittedArticles,
                        paperCommissioningMethod: value.paperCommitioningMethod,
                        siSource: value.siSource,
                        specialIssueTitle: value.specialIssueTitle,
                        specialIssueType: value.specialIssueType,
                        stage: value.stage,
                        workflow: value.workflow,
                        openForSubmissionDate: value.openForSubmissionDate,
                        siRevenueModel: value.siRevenueModel,
                    }
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: CREATE_SI, data: res.data });
                // dispatch(
                //   setSnackbarMessageAndOpen('siPage.createSucc', {}, SEVERITIES.success)
                // );
            } else {
                dispatch({ type: CREATE_SI, data: 'error' });
                dispatch(
                    setSnackbarMessageAndOpen(errorMessageKey, {}, SEVERITIES.error)
                );
            }
        } catch (err) {
            dispatch({ type: CREATE_SI, data: 'error' });
            dispatch(
                setSnackbarMessageAndOpen(errorMessageKey, {}, SEVERITIES.error)
            );
            if (isTimeoutError(err)) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        timeoutErrorMessageKey,
                        SEVERITIES.error
                    )
                );
            }
        }
    };
}

export function createSIInProduction(value) {
    return async dispatch => {
        try {
            const url = '/api/v1/si-service/special-issues';
            const res = await axios_instance.post(
                url,
                {
                    inProductionRequests: {
                        acquiredDate: value.acquiredDate,
                        closedForSubmission: value.closedForSubmission,
                        expectedAcceptanceDate: value.expectedAcceptanceDate,
                        expectedPublicationDate: value.expectedPublicationDate,
                        expectedSubmissionDeadline: value.expectedSubmissionDeadline,
                        leadGuestEditors: value.leadGuestEditors,
                        coGuestEditors: value.coGuestEditors,
                        handlingCe: value.handlingCe,
                        ideaDate: value.ideaDate === 'Invalid date' ? '' : value.ideaDate,
                        journalCode: value.journalCode,
                        journalName: value.journalName,
                        numberOfAcceptedArticles: value.numberOfAcceptedArticles,
                        numberOfRecruitedArticles: value.numberOfRecruitedArticles,
                        numberOfRejectedArticles: value.numberOfRejectedArticles,
                        numberOfSubmittedArticles: value.numberOfSubmittedArticles,
                        paperCommissioningMethod: value.paperCommitioningMethod,
                        siSource: value.siSource,
                        specialIssueTitle: value.specialIssueTitle,
                        specialIssueType: value.specialIssueType,
                        stage: value.stage,
                        workflow: value.workflow,
                        openForSubmissionDate: value.openForSubmissionDate,
                        siRevenueModel: value.siRevenueModel,
                    }
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: CREATE_SI, data: res.data });
                // dispatch(
                //   setSnackbarMessageAndOpen('siPage.createSucc', {}, SEVERITIES.success)
                // );
            } else {
                dispatch({ type: CREATE_SI, data: 'error' });
                dispatch(
                    setSnackbarMessageAndOpen(errorMessageKey, {}, SEVERITIES.error)
                );
            }
        } catch (err) {
            dispatch({ type: CREATE_SI, data: 'error' });
            dispatch(
                setSnackbarMessageAndOpen(errorMessageKey, {}, SEVERITIES.error)
            );
            if (isTimeoutError(err)) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        timeoutErrorMessageKey,
                        SEVERITIES.error
                    )
                );
            }
        }
    };
}

export function createSIInSubmiss(value) {
    return async dispatch => {
        try {
            const url = '/api/v1/si-service/special-issues';
            const res = await axios_instance.post(
                url,
                {
                    inSubmissionAndReviewRequests: {
                        acquiredDate: value.acquiredDate,
                        closedForSubmission: value.closedForSubmission,
                        expectedAcceptanceDate: value.expectedAcceptanceDate,
                        expectedPublicationDate: value.expectedPublicationDate,
                        expectedSubmissionDeadline: value.expectedSubmissionDeadline,
                        leadGuestEditors: value.leadGuestEditors,
                        coGuestEditors: value.coGuestEditors,
                        handlingCe: value.handlingCe,
                        ideaDate: value.ideaDate === 'Invalid date' ? '' : value.ideaDate,
                        journalCode: value.journalCode,
                        journalName: value.journalName,
                        numberOfAcceptedArticles: value.numberOfAcceptedArticles,
                        numberOfRecruitedArticles: value.numberOfRecruitedArticles,
                        numberOfRejectedArticles: value.numberOfRejectedArticles,
                        numberOfSubmittedArticles: value.numberOfSubmittedArticles,
                        paperCommissioningMethod: value.paperCommitioningMethod,
                        siSource: value.siSource,
                        specialIssueTitle: value.specialIssueTitle,
                        specialIssueType: value.specialIssueType,
                        stage: value.stage,
                        workflow: value.workflow,
                        openForSubmissionDate: value.openForSubmissionDate,
                        siRevenueModel: value.siRevenueModel,
                    }
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: CREATE_SI, data: res.data });
                // dispatch(
                //   setSnackbarMessageAndOpen('siPage.createSucc', {}, SEVERITIES.success)
                // );
            } else {
                dispatch({ type: CREATE_SI, data: 'error' });
                dispatch(
                    setSnackbarMessageAndOpen(errorMessageKey, {}, SEVERITIES.error)
                );
            }
        } catch (err) {
            dispatch({ type: CREATE_SI, data: 'error' });
            dispatch(
                setSnackbarMessageAndOpen(errorMessageKey, {}, SEVERITIES.error)
            );
            if (isTimeoutError(err)) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        timeoutErrorMessageKey,
                        SEVERITIES.error
                    )
                );
            }
        }
    };
}

export function judgeSubmissionPass(submissionDate) {
    return async dispatch => {
        if (submissionDate !== null) {
            try {
                if (new Date(submissionDate) < new Date()) {
                    dispatch({ type: SUBMISSION_JUDGE, data: true });
                } else {
                    dispatch({ type: SUBMISSION_JUDGE, data: false });
                }
            } catch (e) {
                dispatch({ type: SUBMISSION_JUDGE, data: false });
            }
        }
    };
}
import { SxProps, Theme } from "@mui/material";

export type BaseFieldProps = {
  testId: string;
  className?: string;
  value?: string | number | boolean;
  hint?: string;
  sx?: SxProps<Theme>;
  origin?:any;
};

export interface Country {
    id: number;
    name: string;
    code?: string | null;
    alpha3Code: string;
}

export interface GE {
  jobTitle: string;
  firstName: string;
  lastName: string;
  isLead: string | null;
  email: string;
  institution: string;
  country: Country | null;
  orcidID: string;
  website: string;
}

export interface Author {
  name: string;
  affilication: string;
  email: string;
  topic: string;
}

export interface SectionItem<T> {
  title: string; // Section title, such as "Special Issue Information"
  sectionIndex: number; // Section index, such as 0, 1, 2, ...
  state: SectionState; // default, active, completed, ...
  data: T; // Section data
}

export enum SectionState {
  default = 'default',
  active = 'active',
  completed = 'completed',
  error = 'error',
}

export interface Journal {
  journalCode: string;
  journalName: string;
  [key: string]: any;
}

export interface SpecialIssueInformation {
  subject: string;
  journals: Journal[];
  proposedTitle: string;
  aimsAndScope: string;
  topics: string[];
}

export interface ProposedDates {
  fullManuscriptSubmissionDeadline: string;
  acceptanceDeadline: string;
  expectedPublicationDate: string;
}

export interface GuestEditorInformation {
  GEList: GE[];
}

export interface PotentialAuthors {
  potentialAuthors: Author[];
}

// Conflict of Interest Declaration
export interface ConflictOfInterest{
    conflictOfInterest: string | null;
    additionalInformation?: string;
}

// Statements
export interface Statements {
  consent1: boolean;
  consent2: boolean;
  consent3: boolean;
}

export interface FormData {
  specialIssueInformation: SectionItem<SpecialIssueInformation>;
  proposedDates: SectionItem<ProposedDates>;
  guestEditorInformation: SectionItem<GuestEditorInformation>;
  potentialAuthors: SectionItem<PotentialAuthors>;
  conflictOfInterest: SectionItem<ConflictOfInterest>;
  statements: SectionItem<Statements>;
}

export interface User {
  firstName: string;
  lastName: string;
  role: {
    name: string;
    permissions: string[];
    [key: string]: any;
  };
  name: string;
  email: string;
  [key: string]: any;
}
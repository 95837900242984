import { Box, useStepContext } from '@mui/material';
import CustomAttachmentBlock from './CustomAttachmentsBlock';
import React,{useState,useEffect} from 'react';
export default function CustomAttachmentsView(props) {
  const { files, setFiles,FileControl=false,flag,setFlag } = props;
  useEffect(()=>{
    if(FileControl && flag){
      
    }
  },[flag]);
  return (
    <div>
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}
      >
        {flag ?
          files.length>0 || !FileControl?
            files.map((file, index) => (
              <CustomAttachmentBlock
                file={file}
                index={index}
                setFiles={setFiles}
                files={files}
                id={'attachmentBlock-' + index}
                data-selenium-id={'attachmentBlock-' + index}
                key={'attachmentBlock-' + index}
                isSIP={props.isSIP}
              />
            )):<><p style={{
              fontFamily:'Open Sans',
              fontWeight:400,
              fontSize:'14px',
              lineHeight:'20px',
              color:'#C40000',
              margin:0,
            }}
            >*Please upload at least one attachment file.</p></>
          :<>
            <p style={{
              fontFamily:'Open Sans',
              fontWeight:400,
              fontSize:'14px',
              lineHeight:'20px',
              color:'#C40000',
              margin:0,
            }}
            >*Please upload an attachment file in supported format: Word,PDF,TXT,PPT,Excel,CSV,PNG,JPEG,JPG,GIF,Zip or rar. Maximum size: <span style={{fontWeight:700}}>10M</span></p>
          </>}
      </Box>
    </div>
  );
}

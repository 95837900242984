import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  TextField,
  Popover
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateCalendar } from './DateCalendar';
import * as DateFnsUtils from 'date-fns';
import zIndex from '@mui/material/styles/zIndex';
import DatePickerHover from '@/assets/DatePickerHover.svg';
import DatePickerDisable from '@/assets/DatePickerDisable.svg';
import DatePickerDefault from '@/assets/DatePickerDefault.svg';
import IconButton from '../../componentsphase2/Button/SimpleIconButton';
export const DatePicker = (props) => {
  const {
    id,
    value,
    minDate, maxDate,
    handleDateChange, placeholder, minHeight,
    width = '286px',
    border = '2px solid transparent',
    innerBorder,
    hoverInputColor = '#262E35',
    focusInputColor = '#0052CC',
    popoverIndex = zIndex,
    iconPaddingRight, backgroundColor,
    InputElement = TextField,
    ifSetDateOpen = false,
    setIsDateOpen,
    PopperProps = {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    },
    autoClose = true,
  } = props;
  const [dateOpen, setDateOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (value instanceof Date) {
      setDate(value);
    } else {
      const dt = DateFnsUtils.parse(value, 'yyyy-MM-dd', new Date());
      if (!isNaN(dt.getDate())) {
        setDate(dt);
      }
    }
  }, [value]);

  const handleOpenPicker = (event) => {
    setDateOpen(true);
    if (ifSetDateOpen) setIsDateOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePicker = () => {
    setDateOpen(false);
    if (ifSetDateOpen) setIsDateOpen(false);
    setAnchorEl(null);
    handleDateChange(formatDateOptional(date), date);
  };

  const autoClosePicker = (date) => {
    if (autoClose){
      setDateOpen(false);
      setAnchorEl(null);
      handleDateChange(formatDateOptional(date), date);
    }
  };

  const handleChange = (date) => {
    setDate(date);
    autoClosePicker(date);
  };

  const emptyDateString = '                    ';
  const formatDateOptional = (dt) => {
    return dt ? DateFnsUtils.format(dt, 'yyyy-MM-dd') : emptyDateString;
  };
  const textFieldString = () => {
    if (date) {
      return `${formatDateOptional(date)}`;
    } else {
      return '';
    }
  };
  // const id = dateOpen ? 'date-range-popover' : undefined;
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      data-selenium-id={'SIPage_Overview-newFilterDrawer-FilterDataPicker'} >
      <Box
        id={id??'datepicker'}
        sx={{
          width: width,
          padding: 0,
          '& .MuiFormControl-root': {
            marginTop: 0,
            marginBottom: 0,
            '& .MuiInputBase-input': {
              paddingTop: 0,
              paddingBottom: 0,
              minHeight: minHeight
            }
          }
        }}>
        <InputElement
          id='datepicker_input'
          className={'DatePicker-TextField'}
          fullWidth={true}
          value={textFieldString()}
          placeholder={placeholder || 'yyyy-mm-dd'}
          onClick={handleOpenPicker}
          InputProps={{
            endAdornment: (
              <IconButton
                id={`datepicker_input_endAdornment`}
                data-selenium-id={`datepicker_input_endAdornment`}
                onClick={handleOpenPicker}
                DefaultIcon={DatePickerDefault}
                HoverIcon={DatePickerHover}
                ActiveIcon={DatePickerHover}
                DisabledIcon={DatePickerDisable}
                sx={{
                  paddingRight: iconPaddingRight,
                  transform: 'translateX(12px)',
                  '&:hover': {
                    backgroundColor: 'transparent !important',
                  }
                }}>
              </IconButton>
            ),
          }}
          sx={{
            '& input': {
              width: `calc(${width} -38px)`,
              height: '18px',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '125%',
              display: 'flex',
              alignItems: 'center',
            },
            width: width,
            borderRadius: '4px',
            backgroundColor: backgroundColor,
            border: border,
            ...(!innerBorder && dateOpen && {
              '&>div >fieldset': {
                border: '2px solid #0052CC',
              }
            }),
            ...(innerBorder && {
              '& .MuiOutlinedInput-notchedOutline': {
                border: innerBorder,
              },
            }),
            ':empty': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#DFE4E8',
              },
            },
            ':hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: hoverInputColor,
              },
            },
            ':focus': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: focusInputColor,
              },
            },
           
          }}
        />
        <Popover
          id={`datepicker_popper`}
          open={dateOpen}
          anchorEl={anchorEl}
          onClose={handleClosePicker}
          sx={{
            zIndex: popoverIndex,
            '& .MuiPaper-root': {
              width: '284px',
              height: 'fit-content',
              overflowX: 'hidden',
              overflowY: 'hidden',
              marginTop: '6px',
              padding: '16px 0px',
            },
            '&>div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper>div': {
              transform: 'translateY(10px)',
              margin: '0 auto',
            }
          }}
          {...PopperProps}
        >
          {/* <Stack className="DateRange-FromCalendar" spacing={2}> */}
          {/* <Box className="DateRange-Title">From:</Box> */}
          <DateCalendar date={date} minDate={minDate} maxDate={maxDate} onChange={handleChange} />
          {/* </Stack> */}
        </Popover>
      </Box>
    </LocalizationProvider>
  );
};

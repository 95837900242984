import {
  Box,
  Stack,
  Backdrop,
  Drawer,
  Divider,
} from '@mui/material';
import {
  SmallFont,
} from '@/components/FontStyle';

import { loadData } from '@/actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import { StateButton } from '@/componentsphase2/StateButton';
import {
  clearArticleFilter,
  clearArticleOAPFilter,
  setArticleFilterSelect,
  setArticleOAPFilterSelect,
  setArticleOverviewFilters,
  setArticleOAPFilters,
} from '@/actions/SIMT-Report/ReportFilter/ReportFilterAction';

import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectBoxWithFixedDropList from '@/modules/SelectBox/SelectBoxWithFixedDropList';
import FilterFrame from '../../Filter/FilterFrame';
import Block from '../../Filter/Block';
import SelectBox from '../../Filter/Phase2Component';
import SIDrawerAutocompletePupIconTypeSecond from '@/assets/SIDrawerAutocompletePupIconTypeSecond.svg';
import { handleFileterJournalName } from '@/utils/commonUtils';
import CATJournalCloseButton from '@/assets/CATJournalCloseButton.svg';
import ReportFilterSwitchIconOn from '@/assets/ReportFilterSwitchIconOn.svg';
import ReportFilterSwitchIconOff from '@/assets/ReportFilterSwitchIconOff.svg';
import { Checkbox } from '@mui/material';
import { SelectInput, MulSelectInput } from '@/componentsphase2/SelectBox/SelectInput';
import CloseIconButton from '@/componentsphase2/Button/CloseIconButton';
import { useShowReportRolesByFyHook } from '@/pages/Report/utils/useShowReportRolesByFyHook';
import {PERMISSIONS} from "@/constant/permission";


export default function ArticlesOverviewFilter(props) {
  const { filterOpen, drawerWidth, handleClose } = props;
  const dispatch = useDispatch();
  const [showJPM, showNewJournalRoles] = useShowReportRolesByFyHook();
  const {
    selectDate,
    cutOffDate,
    handlingCeData,
    catGroupData,
    journalData,
    jpmData,
    pdPublisherData,
    ppPublisherData,
    subjectGroupData,
    businessDivisionData,
    articleOverviewJournal,
    revenueModel,
    articleOverviewJPM,
    articleOverviewPDPublisher,
    articleOverviewPPPublisher,
    articleOverviewSubjectGroup,
    articleOverviewBusinessDivision,
    articleOverviewCATGroup,
    articleOverviewHandlingCE,
    articleOverviewJournalStatus,
    filterData = {},
  } = useSelector(state => {
    return {
      // filter display
      selectDate: state.Report.selectDate,
      cutOffDate: state.Report.cutOffDate,
      handlingCeData: state.Filter.handlingCe,
      catGroupData: state.Filter.catGroup,
      journalData: state.Filter.journal,
      jpmData: state.Filter.jpm,
      pdPublisherData: state.Filter.pdPublisher,
      ppPublisherData: state.Filter.ppPublisher,
      subjectGroupData: state.Filter.subjectGroup,
      businessDivisionData: state.Filter.businessDivision,
      articleOverviewJournal: state.ReportFilter.articleOAPJournal,
      revenueModel: state.ReportFilter.articleOAPrevenueModel,
      articleOverviewJPM: state.ReportFilter.articleOAPJPM,
      articleOverviewPDPublisher: state.ReportFilter.articleOAPPDPublisher,
      articleOverviewPPPublisher: state.ReportFilter.articleOAPPPPublisher,
      articleOverviewSubjectGroup:
        state.ReportFilter.articleOAPSubjectGroup,
      articleOverviewBusinessDivision:
        state.ReportFilter.articleOAPBusinessDivision,
      articleOverviewCATGroup: state.ReportFilter.articleOAPCATGroup,
      articleOverviewHandlingCE: state.ReportFilter.articleOAPHandlingCE,
      articleOverviewJournalStatus:
        state.ReportFilter.articleOAPJournalStatus,
      filterData: state.ReportFilter.articleOAPFilters,
    };
  });

  const [Apply, setApply] = useState(false);

  useEffect(() => {
    if(!Apply){
      document.body.classList.add('hide-scroll');
    }
  }, [Apply]);

  useEffect(()=>{
    if (filterOpen){
      const {
        journalCode = [],
        revenueModel = [],
        jpm = [],
        pdPublisher = [],
        ppPublisher = [],
        subjectGroup = [],
        businessDivision = [],
        catGroup = [],
        handlingCe = [],
        journalStatus = [],
      } = filterData;

      const journalList = [];
      for (let code of journalCode){
        for (let journal of journalData){
          if (journal.includes(`(${code})`)){
            journalList.push(journal);
          }
        }
      }

      const handlingCes = [];
      for (let ceId of handlingCe){
        for (let ce of handlingCeData) {
          if (ce.userId === ceId) {
            handlingCes.push(ce);
          }
        }
      }

      const groups = [];
      for (let group of catGroup){
        for (let cat of catGroupData){
          if (cat.value === group){
            groups.push(cat);
          }
        }
      }

      dispatch(setArticleOAPFilterSelect('reportJournal', journalList));
      dispatch(setArticleOAPFilterSelect('reportJPM', jpm));
      dispatch(setArticleOAPFilterSelect('reportPDPublisher', pdPublisher));
      dispatch(setArticleOAPFilterSelect('reportPPPublisher', ppPublisher));
      dispatch(setArticleOAPFilterSelect('reportSubjectGroup', subjectGroup));
      dispatch(setArticleOAPFilterSelect('reportCATGroup', groups));
      dispatch(setArticleOAPFilterSelect('reportBusinessDivision', businessDivision));
      dispatch(setArticleOAPFilterSelect('reportHandlingCE', handlingCes));
      dispatch(setArticleOAPFilterSelect('revenueModel', revenueModel.length !== 0));
      dispatch(setArticleOAPFilterSelect('reportJournalStatus', journalStatus));
    }
  }, [filterOpen]);

  const permssion = localStorage
    .getItem('userPermissions')
    .includes(PERMISSIONS.SI_READ_LIMITED); //权限判断 修复403错误

  const boxStyle = {
    bgcolor: '#F3F4F8',
    mt: 1,
    p: 0,
  };

  const selectFilter = (which, data) => {
    dispatch(setArticleOAPFilterSelect(which, data));
  };

  const onLoad = async (which, searchContent, page = 1, pageSize = 5000) => {
    if (!permssion) {
      await dispatch(loadData(which, searchContent, page, pageSize));
    }
  };

  const handleSelectedChange = (which, data) => {
    selectFilter(which, data);
  };

  const handleReset = () => {
    dispatch(clearArticleOAPFilter());
    dispatch(setArticleOAPFilters(initFilter()));
  };

  const handlerevenueModelChange = e => {
    dispatch(setArticleOAPFilterSelect('revenueModel', !revenueModel));
  };

  const prepareFilters = () => {
    // 处理journal code----感觉不规范，以后要跟后端协商
    var journalCode = [];
    for (const journalSelectedElement of articleOverviewJournal) {
      var code = handleFileterJournalName(journalSelectedElement)[
        'journalCode'
      ];
      journalCode.push(code);
    }

    // 处理handling ce
    var handlingCeIds = [];
    for (const element of articleOverviewHandlingCE) {
      handlingCeIds.push(element.userId);
    }

    // 处理group
    const groups = [];
    for (const element of articleOverviewCATGroup) {
      groups.push(element.value);
    }

    return {
      handlingCe: handlingCeIds,
      catGroup: groups,
      journalCode: journalCode,
      jpm: articleOverviewJPM,
      pdPublisher: articleOverviewPDPublisher,
      ppPublisher: articleOverviewPPPublisher,
      subjectGroup: articleOverviewSubjectGroup,
      businessDivision: articleOverviewBusinessDivision,
      revenueModel: revenueModel ? ['OA'] : [],
      journalStatus: articleOverviewJournalStatus,
    };
  };

  const initFilter  = () => {
    return {
      handlingCe: [],
      catGroup: [],
      journalCode: [],
      jpm: [],
      subjectGroup: [],
      businessDivision: [],
      revenueModel: [],
      journalStatus: [],
    };
  };

  const handleApply = async () => {
    const filters = prepareFilters();
    await dispatch(setArticleOAPFilters(filters));
    setApply(true);
    handleCloseFilter();
  };

  const handleCloseFilter = () => {
    document.body.classList.remove('hide-scroll');
    handleClose();
  };
  const [open1, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
    dispatch(setArticleOAPFilterSelect('revenueModel', !newOpen));
    // console.log(revenueModel);
  };

  const filterBody = useRef(null);
  function goBottom() {
    setTimeout(()=>{
      const box = filterBody.current;
      if (box){
        box.scrollTop = box.scrollHeight - box.offsetHeight;
      }
    },301);
  }

  return (
    <Box
      sx={{
        width: '100%',
      }}
      data-selenium-id='Report_OAPublicationHybridJournals-anotherNewFilterBox'
    >



      <Backdrop
        id='ReportOAPublicationHybridJournalsAnotherNewFilterDrawerBackdrop'
        data-selenium-id={'Report_OAPublicationHybridJournals-anotherNewFilterDrawerBackdrop'}
        sx={{
          zIndex: 1400,
          backdropFilter: 'blur(4px)',
          background: '#113D9580',
        }}
        open={filterOpen}
      ></Backdrop>
      <Drawer
        id='ReportOAPublicationHybridJournalsAnotherNewFilterDrawer'
        variant='persistent'
        anchor='right'
        open={filterOpen}
        sx={{
          flexShrink: 0,
          ['& .MuiDrawer-paper']: {
            width: '472px',
            zIndex: 1400,
            backgroundColor: '#FFFFFF',
          },
        }}
        data-selenium-id={'Report_OAPublicationHybridJournals-anotherNewFilterDrawer'}
      >
        <Box
          // direction= 'row'
          sx={{
            m: '20px 36px',
            height: '34px',
          }}
        >
          <CloseIconButton
            sx={{float: 'left', padding: '0px', marginRight:'12px', pt:'5px'}}
            onClick={() => {
              handleCloseFilter();
            }}
            data-selenium-id='ReportRelated-OAPublicationinHybridJournals-CloseButton'
          />
          <Box
            sx={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22.5px',
              height: '13px',
              float: 'left',
              color: '#262E35',
              mt: '5px',
              // mb: '10px',
            }}
            data-selenium-id='Report_OAPublicationHybridJournals-Box-Box'
          >
          Filters
          </Box>
          <StateButton
            onClick={handleApply}
            data-selenium-id='Report_OAPublicationHybridJournals-Box2'
            titleName='Filter'
            isPrimary={true}
            sx={{
              lineHeight: '18px',
              marginLeft: '10px',
              float: 'right',
              // '&:disabled': {
              //   backgroundColor: '#BCC5CF',
              //   boxShadow: 'none',
              //   color: '#FFFFFF',
              // },
            }}
            // disabled={isError()}
          ></StateButton>

          <StateButton
            onClick={handleReset}
            data-selenium-id='CATJournal_OverviewReport_OAPublicationHybridJournals-Box1'
            titleName='Clear'
            isPrimary={false}
            sx={{lineHeight: '18px',float: 'right'}}
          ></StateButton>
        </Box>

        <Divider></Divider>
        <Stack
          ref={filterBody}
          direction="column"
          spacing={3}
          sx = {{
            p: '20px 36px 0px 36px',
            overflowY: 'auto',
            scrollbarWidth: 'thin',  // 设置滚动条宽度
            // scrollbarColor: 'transparent',  // 设置滚动条颜色为透明
            // '&::-webkit-scrollbar': {
            //   width: '0px',  // 设置滚动条宽度
            // },
            // '&::-webkit-scrollbar-thumb': {
            //   background: 'transparent',  // 设置滚动条背景为透明
            // },
          }}
        >
          {/* Journal */}
          <Box>

            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_OAPublicationHybridJournals-Filter-Block-Title'
            >
          Journal
            </Box>
            <MulSelectInput
              id='reportArticlesOAPFilterjournalSelect'
              data-selenium-id='Report_Articles_OAP_Overview_Filter-journalSelect'
              value={articleOverviewJournal}
              placeholder={'Search Journal Name or Code'}
              defaultOption={key => (key)}
              getOptionLabel={item => item}
              options={journalData}
              loadOptionAction={onLoad.bind(this, 'journal')}
              onValueChange={
                handleSelectedChange.bind(this, 'reportJournal')
              }
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
            />
          </Box>

          {/* JPM */}
          {showJPM && <Box>
            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_OAPublicationHybridJournals-Filter-Block-Title'
            >
              JPM
            </Box>
            <MulSelectInput
              id='reportArticlesOAPFilterJPMSelect'
              data-selenium-id='Report_Articles_OAP_Overview_Filter-JPMSelect'
              value={articleOverviewJPM}
              placeholder={'Search JPM'}
              defaultOption={key => (key)}
              getOptionLabel={item => item}
              options={jpmData}
              loadOptionAction={onLoad.bind(this, 'jpm')}
              onValueChange={
                handleSelectedChange.bind(this, 'reportJPM')
              }
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
            />
          </Box>}

          {/* PD Publisher */}
          { showNewJournalRoles && <Box>
            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_OAPublicationHybridJournals-Filter-Block-Title'
            >
              PD Publisher
            </Box>
            <MulSelectInput
              id='reportArticlesOAPFilterPDPublisherSelect'
              data-selenium-id='Report_Articles_OAP_Overview_Filter-PDPublisherSelect'
              value={articleOverviewPDPublisher}
              placeholder={'Search PDPublisher'}
              defaultOption={key => (key)}
              getOptionLabel={item => item}
              options={pdPublisherData}
              loadOptionAction={onLoad.bind(this, 'pdPublisher')}
              onValueChange={
                handleSelectedChange.bind(this, 'reportPDPublisher')  // load options
              }
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
            />
          </Box>}

          {/* PP Publisher */}
          { showNewJournalRoles && <Box>
            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_OAPublicationHybridJournals-Filter-Block-Title'
            >
              PP Publisher
            </Box>
            <MulSelectInput
              id='reportArticlesOAPFilterPPPublisherSelect'
              data-selenium-id='Report_Articles_OAP_Overview_Filter-PPPublisherSelect'
              value={articleOverviewPPPublisher}
              placeholder={'Search PP Publisher'}
              defaultOption={key => (key)}
              getOptionLabel={item => item}
              options={ppPublisherData}
              loadOptionAction={onLoad.bind(this, 'ppPublisher')}
              onValueChange={
                handleSelectedChange.bind(this, 'reportPPPublisher')  // load options
              }
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
            />
          </Box>}

          {/* Subject Group */}
          <Box>
            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_OAPublicationHybridJournals-Filter-Block-Title'
            >
              Subject Group
            </Box>
            <MulSelectInput
              id='reportArticlesOAPFilterJPMSelect'
              data-selenium-id='Report_Articles_OAP_Overview_Filter-JPMSelect'
              value={articleOverviewSubjectGroup}
              placeholder={'Search Subject Group'}
              defaultOption={key => (key)}
              getOptionLabel={item => item}
              options={subjectGroupData}
              loadOptionAction={onLoad.bind(this, 'subjectGroup')}
              onValueChange={
                handleSelectedChange.bind(this, 'reportSubjectGroup')
              }
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
            />
          </Box>
          {/* CAT Group */}
          <Box>

            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_OAPublicationHybridJournals-Filter-Block-Title'
            >
          CAT Group
            </Box>
              <MulSelectInput
              id='reportArticlesFilterCatgroupSelect'
              data-selenium-id='Report_OAPublicationHybridJournals_Filter-CatgroupSelect'
              value={articleOverviewCATGroup}
              placeholder='Choose Group Title'
              defaultOption={ key =>(key)}
              getOptionLabel={option => option.value}
              options={catGroupData}
              loadOptionAction={(...props) => onLoad.bind(this, 'catGroup')(...props)}
              onValueChange={(value) => {
                handleSelectedChange.bind(this, 'reportCATGroup')(value);
              }}
              disableNewItem={true}
              showPopupIcon={true}
              showStartIcon={false}
            />
          </Box>
          {/* Business Division */}
          <Box>

            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_OAPublicationHybridJournals-Filter-Block-Title'
            >
          Business Division
            </Box>
            <MulSelectInput
              id='reportArticlesFilterBusinessdivisionSelect'
              data-selenium-id='Report_OAPublicationHybridJournals_Filter-BusinessdivisionSelect'
              value={articleOverviewBusinessDivision}
              placeholder='Choose Business Division'
              defaultOption={key => key}
              getOptionLabel={option => option}
              options={businessDivisionData}
              loadOptionAction={(...props) =>
                onLoad.bind(this, 'businessDivision')(...props)
              }
              onValueChange={value => {
                handleSelectedChange.bind(
                  this,
                  'reportBusinessDivision'
                )(value);
              }}
              disableNewItem={true}
              showPopupIcon={true}
              showStartIcon={false}
            />
          </Box>
          {/* Handling CE */}
          <Box>

            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_OAPublicationHybridJournals-Filter-Block-Title'
            >
          Handling CE
            </Box>
            <MulSelectInput
              id='reportArticlesOAPFilterHeSelect'
              data-selenium-id='Report_Articles_OAP_Overview_Filter-HeSelect'
              value={articleOverviewHandlingCE}
              placeholder={'Search Handling CE'}
              defaultOption={key => (key)}
              getOptionLabel={item => {
                return `${item.username} (${item.email})`;
              }}
              options={handlingCeData}
              loadOptionAction={onLoad.bind(this, 'handlingCe')}
              onValueChange={
                handleSelectedChange.bind(this, 'reportHandlingCE')
              }
              disableNewItem={true}
              showPopupIcon={false}
              showStartIcon={true}
              onOpen={goBottom}
            />
          </Box>
          {/* Journal Status */}
          <Box>

            <Box
              sx={ { ...SmallFont, color: '#596A7C', pb:'4px', fontWeight: '600', letterSpacing: '0.01em'}}
              data-selenium-id='Report_OAPublicationHybridJournals-Filter-Block-Title'
            >
          Journal Status
            </Box>
             <MulSelectInput
              id='journalStatus'
              data-selenium-id='Report_OAPublicationHybridJournals_Filter-journalStatus'
              value={articleOverviewJournalStatus}
              placeholder='Choose Journal Status'
              defaultOption={key => key}
              getOptionLabel={option => option}
              options={['opt-in', 'opt-out']}
              onValueChange={value => {
                handleSelectedChange.bind(this, 'reportJournalStatus')(value);
              }}
              disableNewItem={true}
              showPopupIcon={true}
              showStartIcon={false}
              dropDownListNumber={2}
              onOpen={goBottom}
            />
          </Box>
          <Stack direction='row' alignItems="center" spacing='50px'>
            <Stack direction='row' alignItems="center" spacing='8px'>
              <Checkbox
                disableRipple
                disabled={!revenueModel}
                icon={<ReportFilterSwitchIconOff />}
                checkedIcon={<ReportFilterSwitchIconOn />}
                checked={!revenueModel}
                onChange={handlerevenueModelChange}
                sx={{
                  padding: '0px',
                }}
              />
              <Box
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '18px',
                  letterSpacing: '0em',
                }}
              >
                All
              </Box>
            </Stack>
            <Stack direction='row' alignItems="center" spacing='8px'>
              <Checkbox
                disableRipple
                disabled={revenueModel}
                icon={<ReportFilterSwitchIconOff />}
                checkedIcon={<ReportFilterSwitchIconOn />}
                checked={revenueModel}
                onChange={handlerevenueModelChange}
                sx={{
                  padding: '0px',
                }}
              />
              <Box
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '18px',
                  letterSpacing: '0em',
                }}
              >
                OA
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Drawer>
    </Box>
  );
}



import {CommissionApproaches, GEInformationItem, SipInformation} from "@/pages/SIPPage/RevisePage";


export function validateGEInformation(geInformation: GEInformationItem[]): boolean {
    const hasLeadGE = validateGEHasLeadGE(geInformation);
    if (!hasLeadGE) {
        return false;
    }

    return  geInformation!==null && geInformation.every(validateSingleGE);
}

export function validateSingleGE(item: GEInformationItem): boolean {
    return !(item.institution == '' ||
        item.country == '' ||
        item.hasConflict == null ||
        (item.profileWebsite == '' && item.cv == null));
}

export function validateGEHasLeadGE(geInformation: GEInformationItem[]): boolean {
    return geInformation!==null && geInformation.some((ge) => ge.leadGe);
}

export function validateGEWithStageAndDecision(geInformation: GEInformationItem[], sipStage: string, decisionType: string): boolean {
    if (sipStage === 'Ideation') {
        if (decisionType === 'Move to Submitted Stage') {
            return validateGEInformation(geInformation);
        } else if(decisionType === 'Revise') {
            return validateGEHasLeadGE(geInformation);
        }
    } else if (sipStage === 'Revision') {
        return validateGEHasLeadGE(geInformation);
    } else if (sipStage !== 'Submitted'){
        return validateGEInformation(geInformation);
    }
    return true;
}

export function validateSingleGEWithStageAndDecision(ge: GEInformationItem, sipStage: string, decisionType: string): boolean {
    if (sipStage === 'Ideation') {
        if (decisionType === 'Move to Submitted Stage') {
            return validateSingleGE(ge);
        } else if(decisionType === 'Revise') {
            return true;
        }
    } else if (sipStage === 'Revision') {
        return true;
    } else if (sipStage !== 'Submitted'){
        return validateSingleGE(ge);
    }
    return true;
}

export function validateSipInformation(sipInformation: SipInformation, sipStage: string, decisionType: string, openCallForPapersIsYes: boolean): boolean {
    const isUSIP = sipInformation.sipType === "No";
    const isSSIP = sipInformation.sipType === "Yes";

    const isSSIPStrict = isSSIP && 
    ((sipStage !== "Ideation" && sipStage !== "Revision" && sipStage !== "Submitted") ||
    decisionType ===  'Move to Submitted Stage');
    const isTextBlocksMandatory =  (isSSIPStrict && openCallForPapersIsYes ) || 
        (isUSIP && sipStage !== "Submitted");

    let valid = true;
    if (isSSIPStrict || isUSIP) {
        // for Initial Review stage, the validation is different
        valid &&= sipInformation?.fmSubmissionDeadline != null &&
            sipInformation?.sipSource != null &&
            sipInformation?.sipTitle != null;

        if (isTextBlocksMandatory) {
            const validTextFileds = !((sipInformation.aimsAndScope == null || sipInformation.aimsAndScope === '')
                || (sipInformation?.topics?.length === 0 || sipInformation.topics?.[0] === '')
                || (sipInformation?.keywords?.length === 0));
            valid &&= validTextFileds;
        }
    } else {
        valid &&= sipInformation?.sipSource != null
            && sipInformation?.sipTitle != null;
    }

    return valid;
}

export function validateCommissionApproaches(commissionApproaches: CommissionApproaches, sipStage: string, decisionType: string): boolean {
    const openCallForPaperValid = commissionApproaches?.openCallForPapers == '0' ||
                commissionApproaches?.openCallForPapers == '1';

    if (sipStage === 'Ideation') {
        if (decisionType === 'Move to Submitted Stage') {
            return openCallForPaperValid;
        } else if(decisionType === 'Revise') {
            return true;
        }
    } else if (sipStage === 'Revision') {
        return true;
    } else if (sipStage !== 'Submitted'){
        return openCallForPaperValid;
    }
    return true;
    
}
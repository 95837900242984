import { TableFooter2 } from '@/components/DataTable';
import { StyledTableCell } from '@/components/DataTable';
import NoDataMessage from '@/componentsphase2/NoDataMessage';
import {
  Box,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import ShowDataTable from '../../compontents/showDataTable';
import moment from 'moment';
export default function ShelvedSI(props) {
  const { tableData } = props;
  const titleList = [
    // ['title','width','title text-align','info text-align,]
    ['SI CODE', '151px', '', '', 'url'],
    ['SI TITLE', '254px'],
    ['Shelved reason', '543px', '', '', 'withMore'],
    ['shelved date', '144px'],
  ];
  // const formedTableData = [
  //   ['info', 'info', 'iinfo in info info info info infofo info info info info info info info info infoinfo info info info info info info info info info info info info info info info in info info info info info info info info info info info info info info in', 'info'],
  //   ['info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info'],
  //   ['info', 'info', 'info', 'info'],
  // ];
  const formedTableData = tableData.map(item => {
    return [
      { code: item['siCode'], type: item['alertGeFlagType'] },
      item['siTitle'],
      item['shelvedReason'],
      moment(item['shelvedDate']).utc().format('YYYY-MM-DD'),
    ];
  });
  return <ShowDataTable titleList={titleList} tableData={formedTableData} />;
}

import {axios_instance} from "@/utils/axios_instance";
import {CREATE_SIP} from "@/actions/SIMT-SIP/SIP/SIPAction";

export function CreateSIP(journalCode, sipTitle, sipSource) {
    return async dispatch => {
        try {
            let res = await axios_instance.post(
                '/api/v1/sip-service/special-issue-proposals',
                {
                    createRequests:{
                        journalCode: journalCode,
                        sipTitle: sipTitle,
                        sipSource: sipSource
                    },
                    
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                    params: {
                      action: 'create'
                    }
                }
            );
            if (res.data.code === 200) {
                dispatch({type: CREATE_SIP, data: res.data.data});
            } else {
                dispatch({type: CREATE_SIP, data: 'Error'});
            }
        } catch (e) {
            dispatch({type: CREATE_SIP, data: 'Error'});
        }
    };
}
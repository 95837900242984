const monthIds = [
  { id: 'may', post: 'May' },
  { id: 'jun', post: 'Jun' },
  { id: 'jul', post: 'Jul' },
  { id: 'aug', post: 'Aug' },
  { id: 'sep', post: 'Sep' },
  { id: 'oct', post: 'Oct' },
  { id: 'nov', post: 'Nov' },
  { id: 'dec', post: 'Dec' },
  { id: 'jan', post: 'Jac' },
  { id: 'feb', post: 'Feb' },
  { id: 'mar', post: 'Mar' },
  { id: 'apr', post: 'Apr' },
];

export default monthIds;

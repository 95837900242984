import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Box } from '@mui/material';
import { ChartGroups } from '@/components/Chart/ChartGroups';
import { ChartList } from '@/components/Chart/ChartList';
import styles from './index.module.scss';
import { changeSiderOpen } from '@/actions/SIMT-User/User/UserManageAction';

import subjectGroupImg from '@/assets/ReportDiagrams/Articles/OAvsTotal/SubjectGroup.png';
import subjectGroupYTDImg from '@/assets/ReportDiagrams/Articles/OAvsTotal/SubjectGroupYTD.png';
import businessDivisionImg from '@/assets/ReportDiagrams/Articles/OAvsTotal/BusinessDivision.png';
import businessDivisionYTDImg from '@/assets/ReportDiagrams/Articles/OAvsTotal/BusinessDivisionYTD.png';
import {MONTH, POST} from './Constants/index.js';
import ArticlesOAvsTotalChart from './Diagrams';


import {
  OA_PERFORMANCE,
  OA_YTD_PERFORMANCE,
  SUBJECT_GROUP,
  BUSINESS_DIVISION,
  BAR_COLOR_MAP,
  BAR_COLOR_MAP_YTD,
} from './Constants/index.js';

const BAR_SERIES_LIST = [
  'Sub-[month] OA',
  'Sub-[month] OO',
  'Acc-[month] OA',
  'Acc-[month] OO',
  'Pub-[month] OA',
  'Pub-[month] OO',
];

const DIAGRAM_SETTINGS = [
  {
    groupName: OA_PERFORMANCE,
    chartName: SUBJECT_GROUP,
    hasAdditionalFilter: true,
    hasSelectAllOption: true,
    catagoryAttributeName: 'sgAbbr',
    url: '/reports/article/diagram/oa-vs-total/sg/',
    downloadUrl: '/reports/article/oa-vs-total/sg/month/download',
    downloadMethod: POST,
    tableDownloadTitle: 'Articles'
  },
  {
    groupName: OA_PERFORMANCE,
    chartName: BUSINESS_DIVISION,
    hasAdditionalFilter: true,
    hasSelectAllOption: false,
    catagoryAttributeName: 'businessDivision',
    url: '/reports/article/diagram/oa-vs-total/bd/',
    downloadUrl: '/reports/article/oa-vs-total/bd/month/download',
    downloadMethod: POST,
    tableDownloadTitle: 'Articles'
  },
  {
    groupName: OA_YTD_PERFORMANCE,
    chartName: SUBJECT_GROUP,
    hasAdditionalFilter: true,
    hasSelectAllOption: true,
    catagoryAttributeName: 'sgAbbr',
    url: '/reports/article/diagram/oa-vs-total/sg-ytd/',
    downloadUrl: '/reports/article/oa-vs-total/sg/ytd/download',
    downloadMethod: POST,
    tableDownloadTitle: 'ArticlesYTD'
  },
  {
    groupName: OA_YTD_PERFORMANCE,
    chartName: BUSINESS_DIVISION,
    hasAdditionalFilter: true,
    hasSelectAllOption: false,
    catagoryAttributeName: 'businessDivision',
    url: 'reports/article/diagram/oa-vs-total/bd-ytd/',
    downloadUrl: '/reports/article/oa-vs-total/bd/ytd/download',
    downloadMethod: POST,
    tableDownloadTitle: 'ArticlesYTD'
  },
];

// settings for ChartGroups
const CHART_GROUP_TITLES = [
  {
    groupTitle: OA_PERFORMANCE,
    levels: [
      {
        chartIndex: 0,
        title: SUBJECT_GROUP,
        img: subjectGroupImg,
      },
      {
        chartIndex: 1,
        title: BUSINESS_DIVISION,
        img: businessDivisionImg,
      },
    ],
  },
  {
    groupTitle: OA_YTD_PERFORMANCE,
    levels: [
      {
        chartIndex: 2,
        title: SUBJECT_GROUP,
        img: subjectGroupYTDImg,
      },
      {
        chartIndex: 3,
        title: BUSINESS_DIVISION,
        img: businessDivisionYTDImg,
      },
    ],
  },
];

const DiagramContent = () => {
  // get selectedChartIndex from URL
  let [searchParams, setSearchParams] = useSearchParams();
  let selectedChartIndex = searchParams.get('selectedChartIndex');
  if (selectedChartIndex == null) {
    selectedChartIndex = -1;
  } else {
    selectedChartIndex = parseInt(selectedChartIndex);
  }

  const setShowTableNav = chartIndex => {
    setSearchParams({ showTable: false, selectedChartIndex: chartIndex });
  };

  const siderOpen = useSelector(state => state.UserManage.siderOpen);
  const dispatch = useDispatch();

  const { selectDate } = useSelector(state => {
    return {
      // selectDate: state.Report.ArticlesOAvsTotalDate,
      selectDate: state.Report.selectDate,
    };
  });

  const [formatdate, formatMonth] = useMemo(() => {
    let date = selectDate;
    // default is today
    if (date === null) {
      date = moment().utc().format('YYYY-M');
    }
    let [year, month] = date.split('-');
    month = MONTH[month];
    return [month + ' ' + year, month];
  }, [selectDate]);

  // update group titles with selected month year
  const formattedChartGroupTitles = CHART_GROUP_TITLES.map(chart => ({
    ...chart,
    groupTitle: chart.groupTitle + ' ' + formatdate,
  }));

  const {
    groupName,
    chartName,
    hasAdditionalFilter,
    hasSelectAllOption,
    catagoryAttributeName,
    url,
    downloadUrl,
    downloadMethod,
    tableDownloadTitle
  } = useMemo(() => {
    if (
      selectedChartIndex >= 0 &&
      selectedChartIndex < DIAGRAM_SETTINGS.length
    ) {
      return DIAGRAM_SETTINGS[selectedChartIndex];
    } else {
      return {
        groupName: '',
        chartName: '',
        hasAdditionalFilter: false,
        hasSelectAllOption: false,
        catagoryAttributeName: null,
        url: null,
        downloadUrl: null,
        downloadMethod: null,
        tableDownloadTitle: null
      };
    }
  }, [selectedChartIndex]);

  // diagram legend and ..
  const { barSeriesValue, barSeriesColor } = useMemo(() => {
    if (selectedChartIndex >= 0 && selectedChartIndex <= 1) {
      return {
        barSeriesValue: BAR_SERIES_LIST.map(x =>
          x.replace('[month]', formatMonth)
        ),
        barSeriesColor: BAR_COLOR_MAP,
      };
    } else {
      // For YTD charts
      return {
        barSeriesValue: BAR_SERIES_LIST.map(x =>
          x.replace('[month]', formatMonth + ' YTD')
        ),
        barSeriesColor: BAR_COLOR_MAP_YTD,
      };
    }
  }, [selectedChartIndex, formatMonth]);

  const onSelectChartIndex = index => {
    setShowTableNav(index);

    // if a chart is selected and the side menu is open, close the side menu
    if (index >= 0 && index < DIAGRAM_SETTINGS.length && siderOpen) {
      dispatch(changeSiderOpen(false));
    }
  };

  const cancelSelectedChart = () => onSelectChartIndex(-1);

  return (
    <Box sx={{ marginTop: '32px', paddingBottom: '62px' }}>
      {selectedChartIndex >= 0 ? (
        <Box className={styles['card-content-container']}>
          <div
            className={styles['card-content-paper']}
            disableGutters
            maxWidth={false}
          >
            <ArticlesOAvsTotalChart
              header={groupName}
              subHeader={chartName}
              selectedDate={selectDate}
              formatdate={formatdate}
              cancelSelectedChart={cancelSelectedChart}
              showSubjectGroupsSelector={false}
              hasAdditionalFilter={hasAdditionalFilter}
              hasSelectAllOption={hasSelectAllOption}
              catagoryAttributeName={catagoryAttributeName}
              barSeriesValue={barSeriesValue}
              barSeriesColor={barSeriesColor}
              url={url}
              downloadUrl={downloadUrl}
              downloadMethod={downloadMethod}
              tableDownloadTitle={tableDownloadTitle}
            />
          </div>
          <ChartList
            chartGroupTitles={formattedChartGroupTitles}
            onSelectChartIndex={onSelectChartIndex}
            selectedChartIndex={selectedChartIndex}
          />
        </Box>
      ) : (
        <ChartGroups
          chartGroupTitles={formattedChartGroupTitles}
          onSelectChartIndex={onSelectChartIndex}
          selectedChartIndex={selectedChartIndex}
        />
      )}
    </Box>
  );
};

export default DiagramContent;

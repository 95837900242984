import { Box, Stack } from '@mui/material';
import Tool from '../../../SIPage/Overview/Filter/component/SVGComponents/Tools.svg';
import IconBox from '../../../SIPage/Overview/Filter/component/CommonComponets/IconBox';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { compareFilter, getSum, prepareFilters } from './utils';
import NumberIcon from '../../../SIPage/Overview/Filter/component/CommonComponets/NumberIcon';
import { ColumnCentered, SemiBoldFont } from '../../../SIPage/Overview/Filter/component/Css';

export default function FilterButton(props) {
  const { open, setOpen,isClear} = props;
  const [state, setState] = useState('1');
  const [number, setNumber] = useState(0);
  const [boxSX, setBoxSX] = useState({});
  const {
    sipFilterSelected,
    adaptSIPFilterSelected,
    favSIPFilterSelected,
  } = useSelector(state => {
    return {
      sipFilterSelected: state.SIPFilter.sipFilterSelected,
      adaptSIPFilterSelected: state.SIPFilter.adaptSIPFilterSelected,
      favSIPFilterSelected: state.SIPFilter.favSIPFilterSelected,
      hasFilter: state.SIPFilter.hasFilter,
    };
  });
  useEffect(() => {
    let temSIPFilterSelected = prepareFilters(sipFilterSelected, true,true);
    if (compareFilter(temSIPFilterSelected, favSIPFilterSelected) && getSum(favSIPFilterSelected)!==0) {
      let temNum = getSum(adaptSIPFilterSelected);
      setNumber(temNum);
      setState(temNum > 0 ? '4' : '3');
    } else {
      let temNum = getSum(adaptSIPFilterSelected);
      setNumber(temNum);
      setState(temNum > 0 ? '2' : '1');
    }
  }, [sipFilterSelected, adaptSIPFilterSelected, favSIPFilterSelected]);
  useEffect(()=>{
    setState('1');
    setNumber(0);
  },[isClear]);
  useEffect(() => {
    let sx = {};
    switch (state) {
      case '1':
        sx = {
          cursor: 'pointer',
          borderRadius: '5px',
          border: `1px solid ${open ? '#AEC6F6' : '#0052CC'}`,
          backgroundColor: open ? '#E8EDFB' : '#FFFFFF',
          color: open ? '#6D98EE' : '#0052CC',
          ':hover': !open && {
            color: '#6D98EE',
            borderColor: '#6D98EE',
          },
          ':active': !open && {
            color: '#6D98EE',
            borderColor: '#AEC6F6',
            backgroundColor: '#E8EDFB',
          },
        };
        break;
      case '2':
        sx = {
          cursor: 'pointer',
          borderRadius: '5px',
          border: 'none',
          backgroundColor: open ? '#113D95' : '#0052CC',
          color: '#FFFFFF',
          '& svg path': {
            fill: open ? '#113D95' : '#0052CC',
          },
          '& svg circle': {
            fill: '#FFFFFF',
          },
          ':hover': !open && {
            backgroundColor: '#6D98EE',
            '& svg path': {
              fill: '#6D98EE',
            },
          },
          ':active': !open && {
            backgroundColor: '#113D95',
            '& svg path': {
              fill: '#113D95',
            },
          },
        };
        break;
      case '3':
        sx = {
          cursor: 'pointer',
          borderRadius: '5px',
          border: `1px solid ${open ? '#48A79A' : '#0D6A61'}`,
          backgroundColor: '#FFFFFF',
          color: open ? '#48A79A' : '#0D6A61',
          '& svg path': {
            fill: open ? '#48A79A' : '#0D6A61',
          },
          ':hover': !open && {
            color: '#66CABE',
            borderColor: '#66CABE',
            '& svg path': {
              fill: '#66CABE',
            },
          },
          ':active': !open && {
            color: '#48A79A',
            borderColor: '#48A79A',
            '& svg path': {
              fill: '#48A79A',
            },
          },
        };
        break;
      case '4':
        sx = {
          cursor: 'pointer',
          borderRadius: '5px',
          border: 'none',
          backgroundColor: open ? '#0D6A61' : '#A1DDD6',
          color: open ? '#FFFFFF' : '#0D6A61',
          '& svg path': {
            fill: open ? '#0D6A61' : '#A1DDD6',
          },
          '& svg circle': {
            fill: open ? '#FFFFFF' : '#0D6A61',
          },
          ':hover': !open && {
            backgroundColor: '#D7F1EE',
            color: '#0D6A61',
            '& svg path': {
              fill: '#A1DDD6',
            },
            '& svg circle': {
              fill: '#0D6A61',
            },
          },
          ':active': !open && {
            backgroundColor: '#0D6A61',
            color: '#FFFFFF',
            '& svg path': {
              fill: '#0D6A61',
            },
            '& svg circle': {
              fill: '#FFFFFF',
            },
          },
        };
        break;
      default:
        break;
    }
    setBoxSX(sx);
  }, [state, open]);
  return (
    <Box
      sx={boxSX}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <Stack
        spacing={0.75}
        direction='row'
        sx={{
          height: '34px',
          width: '74px',
          boxSizing: 'border-box',
          ...ColumnCentered,
          paddingRight: '12px',
          paddingLeft: '12px',
          ...SemiBoldFont,
        }}
      >
        <IconBox show={number === 0}>
          <Tool />
        </IconBox>
        <Box>Filter</Box>
        <IconBox show={number > 0} svgSize={{ height: '14px', width: '14px' }}>
          <NumberIcon number={number} />
        </IconBox>
      </Stack>
    </Box>
  );
}

/**
 * Huang Tao
 */
import React, { useEffect, useState } from 'react';
import './index.css';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Tooltip,
  TableCell,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '@/components/DataTable';
import {
  StyledTableCellPurple,
  StyledTableCellDisPurple,
  StyledTableCellGreen,
  StyledTableCellDisGreen,
  StyledTableRow, StyledTableCellNormal, StyledBodyCellPurple, StyledBodyCellGreen, TableBox, ReportNoData, TableBox2
} from "../../ReportSpecificComponents/SpecialColorTableHeader";
import { findLongestLineSubstring, measureWidth } from "../../../../utils/StringWidthMeasure";
import { SimpleTooltipChoice } from "../../../../componentsphase2/CustomTooltip";
import {GetAcquireOverviewSubjectTableData} from "@/actions/SIMT-Report/Report/ReportAcquiredAndActive";
export function AcquiredOverviewSubjectGroupTable(props) {
  const { ifNoResult } = props;

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  //获取选择的日期和table2的数据
  const { currentDate, tableMonth, table2Data } = useSelector(state => {
    return {
      table2Data: state.Report.requireOverviewSubjectTableData,
      tableMonth: state.Report.requireOverviewSubjectTableMonth,
      // currentDate: state.Report.selectedAcquireDate,
      currentDate: state.Report.selectDate,
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    for (let item of items){
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '18px Open Sans');
    }
    widthMap['subjectGroup'] = measureWidth(findLongestLineSubstring('Subject Group'), '12px Open Sans');
    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (table2Data && table2Data.length > 0){
      const maxMap = {};
      for (let i = 0; i < table2Data.length; i++) {
        let row = table2Data[i];
        let dataObj = {
          subjectGroup: row.sgAbbr ?? row.subjectGroup,

          'Acquired-MMM': row.acquiredSi ?? '0',
          'Acquired-YTD': row.acquiredYtd ?? '0',
          '%Against YTD Target': row.againstYTDTarget ?? '0',
          'Active SIs': row.activeSi ?? '0',

          'OAAcquired-MMM': row.acquiredSiOa ?? '0',
          'OAAcquired-YTD': row.acquiredYtdOa ?? '0',
          'OA%Against YTD Target': row.againstYTDTargetOA ?? '0',
          'OAActive SIs': row.againstYTDTargetOA ?? '0',
        };
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [table2Data]);

  function calcAllJournalWidth(){
    let sum = 0;
    for (let item of items) {
      sum += Math.max((columnWidthMap[item.id]??0), columnMinWidthMap[item.id]) + 50;
    }
    return sum;
  }

  function calcOAJournalWidth(){
    let sum = 0;
    for (let item of items) {
      sum += Math.max((columnWidthMap['OA'+item.id]??0), columnMinWidthMap[item.id]) + 50;
    }
    return sum;
  }

  useEffect(() => {
    async function getData(currentDate) {
      if (currentDate != null) {
        setIsLoading(true);
        setTimeout(() => {
          dispatch(GetAcquireOverviewSubjectTableData(currentDate));
        }, 2000);
      }
    }
    getData(currentDate);
  }, [currentDate]);

  useEffect(() => {
    setIsLoading(false);
  }, [table2Data]);

  const items = [
    {
      id: 'Acquired-MMM',
      label: 'Acquired SI - ',
      minWidth: '100px',
      maxWidth: '120px',
      isOrder: false,
    },
    {
      id: 'Acquired-YTD',
      label: 'Acquired SI - YTD',
      minWidth: '120px',
      maxWidth: '120px',
      isOrder: false,
    },
    {
      id: '%Against YTD Target',
      label: '% Against YTD Target',
      minWidth: '125px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'Active SIs',
      label: 'Active SI',
      minWidth: '130px',
      maxWidth: '150px',
      isOrder: false,
    },
  ];

  //如果tablle2Data为空或undefined
  const showDataTableSG = table2Data => {
    if (table2Data === undefined || table2Data === null || table2Data === '') {
      return [];
    } else return table2Data;
  };

  //total字体加深
  function DisplayTooltip(props) {
    const { ifTotal } = props;
    if (ifTotal === 'Total')
      return (
        <div
          align='right'
          data-selenium-id='Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-DisplayTooltip-Div'
        >
          < >{ifTotal}</ >
        </div>
      );
    else {
      return (
        <div
          align='left'
          data-selenium-id='Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-DisplayTooltip-Div'
        >
          {ifTotal}
        </div>
      );
    }
  }
  return (
    <TableBox2
      dataList={[table2Data]}
      tableKey={'Report_AcquireOverview-Overview-SubjectGroupTable-Box'}
      footerHeight={0}
      id='BoxTable2'
      data-selenium-id='Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-Box'
    >
      <TableContainer
        sx={{ maxHeight: '600px' }}
        data-selenium-id='Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableContainer'
      >
        <Table
          stickyHeader
          aria-label='sticky table'
          data-selenium-id='Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-Table'
          sx={{
            tableLayout: 'fixed',
            width: 'max-content',
            // maxWidth: 'none',
          }}
        >
          <TableHead data-selenium-id='Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableHead'>
            <TableRow data-selenium-id='Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableHead-TableRow1'>
              <StyledTableCellNormal
                id='reportAAASOverviewSubjectGroupHead'
                align='left'
                rowSpan={2}
                className='RightBottomBorder'
                sx={{
                  // maxWidth: '500px', minWidth: '140px',
                  width: `${Math.max((columnWidthMap['subjectGroup']??0), columnMinWidthMap['subjectGroup']) + 50 }px`,
                  position: 'sticky',
                  left: 0,
                  top: 0,
                  zIndex: 8,
                  backgroundColor: '#DFE4E8',
                }}
                data-selenium-id='Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableHead-TableRow1-SubjectGroupHead'
              >
                Subject Group
              </StyledTableCellNormal>
              <StyledTableCellPurple
                id='reportAAASOverviewAllJournalsHead'
                data-selenium-id='Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableHead-TableRow1-AllJournalsHead'
                align='left'
                colSpan={4}
                className='RightBottomBorder'
                sx={{
                  width: `${calcAllJournalWidth()}px`,
                }}
              >
                All journals
              </StyledTableCellPurple>
              <StyledTableCellGreen
                id='reportAAASOverviewOAJournalsHead'
                data-selenium-id='Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableHead-TableRow1-OAJournalsHead'
                align='left'
                colSpan={4}
                className='RightBottomBorder'
                sx={{
                  width: `${calcOAJournalWidth()}px`,
                }}
              >
                OA journals
              </StyledTableCellGreen>
            </TableRow>
            <TableRow data-selenium-id='Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableHead-TableRow2'>
              {items.map((item, index) => {
                if (item.id === 'Active SIs') {
                  return (
                    <StyledTableCellDisPurple
                      id={`reportAAASOverviewAll${index}`}
                      className='RightBottomBorder'
                      align='left'
                      sx={{
                        top: 42,
                        // minWidth: item.minWidth,
                        // maxWidth: item.maxWidth,
                        ...({
                          width: `${Math.max((columnWidthMap[item.id]??0), columnMinWidthMap[item.id]) + 50 }px`,
                        }),
                      }}
                      key={item.id}
                      style={{ 'padding-top': '5px', 'padding-bottom': '5px' }}
                      data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableHead-TableRow2-All-${index}`}
                    >
                      {item.label}
                    </StyledTableCellDisPurple>
                  );
                } else {
                  return (
                    <StyledTableCellDisPurple
                      id={`reportAAASOverviewAll${index}`}
                      data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableHead-TableRow2-All-${index}`}
                      align='left'
                      sx={{
                        top: 42,
                        // minWidth: item.minWidth,
                        // maxWidth: item.maxWidth,
                        ...({
                          width: `${Math.max((columnWidthMap[item.id]??0), columnMinWidthMap[item.id]) + 50 }px`,
                        }),
                      }}
                      key={item.id}
                      className='BottomBorder'
                    >
                      {item.id === 'Acquired-MMM'
                        ? item.label + tableMonth
                        : item.label}
                    </StyledTableCellDisPurple>
                  );
                }
              })}
              {items.map((item, index) => {
                if (item.id === 'Active SIs') {
                  return (
                    <StyledTableCellDisGreen
                      id={`reportAAASOverviewOA${index}`}
                      data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableHead-TableRow2-OA-${index}`}
                      className='RightBottomBorder'
                      align='left'
                      sx={{
                        top: 42,
                        // minWidth: item.minWidth,
                        // maxWidth: item.maxWidth,
                        ...( {
                          width: `${Math.max((columnWidthMap['OA'+item.id]??0), columnMinWidthMap[item.id]) + 50 }px`,
                        }),
                      }}
                      key={item.id}
                      style={{ 'padding-top': '5px', 'padding-bottom': '5px' }}
                    >
                      {item.label}
                    </StyledTableCellDisGreen>
                  );
                } else {
                  return (
                    <StyledTableCellDisGreen
                      id={`reportAAASOverviewOA${index}`}
                      align='left'
                      sx={{
                        top: 42,
                        // minWidth: item.minWidth,
                        // maxWidth: item.maxWidth,
                        ...({
                          width: `${Math.max((columnWidthMap['OA'+item.id]??0), columnMinWidthMap[item.id]) + 50 }px`,
                        }),
                      }}
                      key={item.id}
                      className='BottomBorder'
                      data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableHead-TableRow2-OA-${index}`}
                    >
                      {item.id === 'Acquired-MMM'
                        ? item.label + tableMonth
                        : item.label}
                    </StyledTableCellDisGreen>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody data-selenium-id='Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody'>
            {!isLoading &&
            !ifNoResult &&
            table2Data != null &&
            table2Data != undefined &&
            table2Data.length != 0
              ? table2Data.map((row, index) => {
                  if (row.subjectGroup === 'Total') {
                    return (
                      <StyledTableRow data-selenium-id='Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow'>
                        <StyledTableCell
                          id={`reportAAASOverviewSGSubjectGroup${index}`}
                          className='RightBorder ReportTotalTextSize'
                          align='left'
                          sx={{
                            maxWidth: '150px',
                            position: 'sticky',
                            left: 0,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGSubjectGroup-${index}`}
                        >
                          <SimpleTooltipChoice
                            id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGSubjectGroup-${index}-SimpleTooltipChoice`}
                            ifTooltip={true}
                            title={row.subjectGroup}
                          >
                            <DisplayTooltip ifTotal={row.subjectGroup} />
                          </SimpleTooltipChoice>
                        </StyledTableCell>
                        <StyledBodyCellPurple
                          id={`reportAAASOverviewSGAcquiredSI${index}`}
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGAcquiredSI-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >
                          < >
                            {row.acquiredSi === null ? '0' : row.acquiredSi}
                          </ >
                        </StyledBodyCellPurple>
                        <StyledBodyCellPurple
                          id={`reportAAASOverviewSGAcquiredYtd${index}`}
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGAcquiredYtd-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >
                          < >
                            {row.acquiredYtd === null ? '0' : row.acquiredYtd}
                          </ >
                        </StyledBodyCellPurple>
                        <StyledBodyCellPurple
                          id={`reportAAASOverviewSGAgainstYTDTarget${index}`}
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGAgainstYTDTarget-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >
                          < >
                            {' '}
                            {row.againstYTDTarget === null
                              ? '0'
                              : row.againstYTDTarget}
                          </ >
                        </StyledBodyCellPurple>
                        <StyledBodyCellPurple
                          id={`reportAAASOverviewSGActiveSI${index}`}
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGActiveSI-${index}`}
                          align='left'
                          className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                        >
                          < >
                            {' '}
                            {row.activeSi === null ? '0' : row.activeSi}
                          </ >
                        </StyledBodyCellPurple>
                        <StyledBodyCellGreen
                          id={`reportAAASOverviewSGAcquiredSIOA${index}`}
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGAcquiredSIOA-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >
                          < >
                            {' '}
                            {row.acquiredSiOa === null ? '0' : row.acquiredSiOa}
                          </ >
                        </StyledBodyCellGreen>
                        <StyledBodyCellGreen
                          id={`reportAAASOverviewSGAcquiredYTDOA${index}`}
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGAcquiredYTDOA-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >
                          < >
                            {' '}
                            {row.acquiredYtdOa === null
                              ? '0'
                              : row.acquiredYtdOa}
                          </ >
                        </StyledBodyCellGreen>
                        <StyledBodyCellGreen
                          id={`reportAAASOverviewSGAgainstYTDTargetOA${index}`}
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGAgainstYTDTargetOA-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >
                          < >
                            {' '}
                            {row.againstYTDTargetOA === null
                              ? '0'
                              : row.againstYTDTargetOA}
                          </ >
                        </StyledBodyCellGreen>
                        <StyledBodyCellGreen
                          id={`reportAAASOverviewSG$ActiveSIOA${index}`}
                          data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGActiveSIOA-${index}`}
                          align='left'
                          className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                        >
                          < >
                            {' '}
                            {row.activeSiOa === null ? '0' : row.activeSiOa}
                          </ >
                        </StyledBodyCellGreen>
                      </StyledTableRow>
                    );
                  }
                  return (
                    <StyledTableRow data-selenium-id='Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow'>
                      <StyledTableCell
                        id={`reportAAASOverviewSGSubjectGroup${index}`}
                        align='left'
                        className='RightBorder'
                        sx={{
                          maxWidth: '150px',
                          position: 'sticky',
                          left: 0,
                          zIndex: 6,
                          backgroundColor: '#F1F3F5',
                        }}
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGSubjectGroup-${index}`}
                      >
                        <SimpleTooltipChoice
                          id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGSubjectGroup-${index}-SimpleTooltipChoice`}
                          ifTooltip={true}
                          title={row.subjectGroup}
                        >
                          <Box component='div'>
                            <DisplayTooltip ifTotal={row.sgAbbr ?? row.subjectGroup} />
                          </Box>
                        </SimpleTooltipChoice>
                      </StyledTableCell>
                      <StyledBodyCellPurple
                        id={`reportAAASOverviewSGAcquiredSI${index}`}
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGAcquiredSI-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.acquiredSi === null ? '0' : row.acquiredSi}
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportAAASOverviewSGAcquiredYtd${index}`}
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGAcquiredYtd-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.acquiredYtd === null ? '0' : row.acquiredYtd}
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportAAASOverviewSGAgainstYTDTarget${index}`}
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGAgainstYTDTarget-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.againstYTDTarget === null
                          ? '0'
                          : row.againstYTDTarget}
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportAAASOverviewSGActiveSI${index}`}
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGActiveSI-${index}`}
                        align='left'
                        className='RightBorder ReportNumberTextColor'
                      >
                        {row.activeSi === null ? '0' : row.activeSi}
                      </StyledBodyCellPurple>
                      <StyledBodyCellGreen
                        id={`reportAAASOverviewSGAcquiredSIOA${index}`}
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGAcquiredSIOA-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.acquiredSiOa === null ? '0' : row.acquiredSiOa}
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportAAASOverviewSGAcquiredYtdOA${index}`}
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGAcquiredYtdOA-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.acquiredYtdOa === null ? '0' : row.acquiredYtdOa}
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportAAASOverviewSGAgainstYTDTargetOA${index}`}
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGAgainstYTDTargetOA-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.againstYTDTargetOA === null
                          ? '0'
                          : row.againstYTDTargetOA}
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportAAASOverviewSGActiveSIOA${index}`}
                        data-selenium-id={`Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-StyledTableRow-SGActiveSIOA-${index}`}
                        align='left'
                        className='RightBorder ReportNumberTextColor'
                      >
                        {row.activeSiOa === null ? '0' : row.activeSiOa}
                      </StyledBodyCellGreen>
                    </StyledTableRow>
                  );
                })
              : !isLoading && (
                  <ReportNoData id='AcquiredAndActiveSiAnnualTrendsBDNoResultTableCell' />
                )}
            {isLoading && (
              <Box
                sx={{
                  width: 'auto',
                  marginTop: '150px',
                  marginLeft: '50%',
                }}
                data-selenium-id='Report_AcquiredAndActiveSi-Overview-SubjectGroupTable-TableBody-LoadingBox'
              >
                <CircularProgress />
              </Box>
            )}
            <TableRow></TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </TableBox2>
  );
}
// export default SubjectGroupTable

import { Typography } from '@mui/material';
import { fontStyle } from '@mui/system';
import React from 'react';

/**
 *
 * @param {import("./Typography").AcquiredSiCreationHeadingProps} props
 * @returns
 */
export const AcquiredSiCreationHeading = props => {
  const { children, variant, sx, ...others } = props;

  /**
   * @type {import("react").CSSProperties}
   */
  const typographyStyleBase = {
    fontFamily: 'Open Sans',
  };

  /**
   * @returns {import("react").CSSProperties}
   */
  const getTypographyStyle = () => {
    switch (variant) {
      case 'primary':
        return {
          ...typographyStyleBase,
          fontWeight: 400,
          fontSize: '20px',
          lineHeight: '28px',
          letterSpacing: '-0.022em',
          color: '#596A7C',
        };
      case 'secondary':
        return {
          ...typographyStyleBase,
          fontStyle: 'normal',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '24px',
          color: '#262E35',
        };
      case 'tertiary':
        return {
          ...typographyStyleBase,
          fontStyle: 'normal',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '18px',
          color: '#596A7C',
          letterSpacing: '0.08em',
        };
      default:
        return {};
    }
  };

  return (
    <Typography
      data-selenium-id='SIPPage_TransformToSl-CustomCompoent-Typography'
      sx={{
        ...getTypographyStyle(),
        ...sx,
      }}
      {...others}
    >
      {children}
    </Typography>
  );
};

/**
 * @author:Liu Xin
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import './index.css';
import {
  Button,
  TextField,
  Icon,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import VisibilityOff from '@/assets/visibility_off.svg';
import VisibilityOn from '@/assets/visibility_on.svg';
import Alert1 from './Alert1';
import Alert2 from './Alert2';
import Alert0 from './Alert0';
import { openConfirm, setEditType, setColor } from '@/actions/SIMT-User/Alert/AlertAction';
import {
  clear,

} from '@/actions/SIMT-User/Login/LoginAction';
import {login, resetPassForget} from "@/actions/SIMT-User/Login";
import {resetPassFirstTime} from "@/actions/SIMT-User/Login/User.js";
import { getUserId } from '@/utils/getUserId';

function ResetPassword(props) {
  const location = useLocation();
  var emailU = location.search.split('=')[2];
  if (emailU === undefined) {
    emailU = location.state.email;
  }
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [password, setPassword] = useState('');
	const [oldpassword, setOldPassword] = useState('');
  const [cfmPassword, setCfmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showCfmPassword, setShowCfmPassword] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [alert0, setAlert0] = useState(false);
  const [alert1, setAlert1] = useState(false);
  const [alert2, setAlert2] = useState(false);

  const selector = state => {
    return {
      resetPass: state.Login.resetPass,
      isAuthenticated: state.Login.isAuthenticated,
    };
  };
  const { resetPass, isAuthenticated } = useSelector(selector);

  useEffect(() => {

    if (resetPass === 'Email Exit') {
      setAlert0(true);
      return () => {};
    } else if (resetPass === true) {
      dispatch(login({ email: emailU, password: password, remember_flag: 'off' }));
      return () => {};
    } else if (resetPass !== 'Email Exit' && resetPass !== null) {
      handleReset();
      return () => {
        dispatch(clear());
      };
    }
  }, [resetPass]);

  useEffect(() => {
    if (isAuthenticated) {
      navigator('/simt/auth/index');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    let cnt = 0;
    if (!judgePassword(password)) {
      if (password !== '') {
        setAlert1(true);
      }
    } else {
      setAlert1(false);
      cnt++;
    }
    if (password === cfmPassword) {
      setAlert2(false);
      cnt++;
    } else {
      if (cfmPassword !== '') {
        setAlert2(true);
      }
    }
    handleIsDisabled(cnt);
  }, [password, cfmPassword]);

  const judgePassword = PasswordInput => {
    var regNumber = new RegExp(/.*[0-9]+.*/);
    var regUpper = new RegExp(/.*[A-Z]+.*/);
    var regLower = new RegExp(/.*[a-z]+.*/);
    var regSymbol = new RegExp(/.*[\x21-\x2f,\x3a-\x40\x5b-\x60\x7b-\x7e]+.*/);
    var count = 0;
    if (regNumber.test(PasswordInput)) {
      count++;
    }
    if (regUpper.test(PasswordInput)) {
      count++;
    }
    if (regLower.test(PasswordInput)) {
      count++;
    }
    if (regSymbol.test(PasswordInput)) {
      count++;
    }
    //判断密码位数必须大于8位,小于32位
    if (password.length < 8 || password.length > 32 || count < 3) {
      return false;
    }
    return true;
  };

  const handleIsDisabled = cnt => {
    if (cnt === 2 && password !== '' && cfmPassword !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleClick = () => {
    //发送axios请求并进行后续路由处理
    resetSuccess();
  };
  /**
   * 发送axios请求
   * 通过redux控制修改密码提示框显示
   * 页面跳转
   */
  const resetSuccess = async () => {
    //发送axios请求
    if (props.firstTime) {
		dispatch(resetPassFirstTime(getUserId(), oldpassword, password));
    } else {
      dispatch(
        resetPassForget(props.digitalSignature, props.email, password)
      );
    }
  };

  const handleReset = () => {
    if (props.firstTime) {
      if (resetPass) {
        navigator('/simt/auth/index');
      }
    } else {
      if (resetPass) {
        dispatch(setColor('Grey700'));
        dispatch(setEditType('resetPassConfirm'));
        dispatch(openConfirm());
        navigator('/simt/login');
      }
    }
  };

  return (
    <Box
      display='flex'
      width='100%'
      alignItems='flex-start'
      justifyContent='center'
      mt={17}
      mb={30}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '632px',
          background: '#ffffff',
          border: '1px solid #dddddd',
          boxSizing: 'border-box',
          borderRadius: '4px',
        }}
      >
        <Typography
          sx={{
            fontSize: '34px',
            lineHeight: '46px',
            color: '#484848',
            m: '16px 0',
          }}
        >
          Reset your password
        </Typography>
        {/* <input type="password" hidden /> */}
        <div>{alert0 ? <Alert0 type={'samePass'} /> : <div></div>}</div>
        <div className='passwordBlock'>
          <TextField
            // data-testid="newPassword"
            inputProps={{ 'data-testid': 'newPassword' }}
            id='PasswordInput1'
            label='New Password'
            variant='filled'
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={e => {
              if (e.target.value.length <= 32) {
                if (alert0) {
                  setAlert0(false);
                }
                setPassword(e.target.value);
              }
            }}
          ></TextField>
          <IconButton
            data-testid='icon1'
            id='icon1'
            name='showPassword'
            aria-label='toggle password visibility'
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            <Icon>{showPassword ? <VisibilityOn /> : <VisibilityOff />}</Icon>
          </IconButton>
        </div>

        <div>{alert1 ? <Alert1 /> : <div></div>}</div>

        <div className='passwordBlock'>
          <TextField
            id='PasswordInput2'
            label='Confirm New Password'
            variant='filled'
            type={showCfmPassword ? 'text' : 'password'}
            value={cfmPassword}
            onChange={e => {
              if (e.target.value.length <= 32) {
                setCfmPassword(e.target.value);
              }
            }}
          ></TextField>
          <IconButton
            data-testid='icon2'
            id='icon2'
            name='showConfirmPassword'
            aria-label='toggle password visibility'
            onClick={() => {
              setShowCfmPassword(!showCfmPassword);
            }}
          >
            <Icon>
              {showCfmPassword ? <VisibilityOn /> : <VisibilityOff />}
            </Icon>
          </IconButton>
        </div>

        <div>{alert2 ? <Alert2 /> : <div></div>}</div>

        <Button
          variant='contained'
          id='btn'
          disabled={disabled}
          onClick={handleClick}
        >
          SET PASSWORD
        </Button>
      </Box>
    </Box>
  );
}

export default ResetPassword;

import {
  Stack,
  Typography,
  styled,
  Box,
  Button,
  TextField,
  createTheme,
  ThemeProvider,
  Dialog,
  IconButton,
  DialogContent,
  DialogActions,
  InputAdornment,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useState, useEffect } from 'react';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import { EMAIL_PATTERN } from '@/constant/common';

export default function InviteEbmItem(props) {
  const { value, canDelete, onChange, isNextClick, isEmailRepeat } = props;
  const [newEbm, setNewEbm] = useState(value);
  const [isClick, setIsClick] = useState(false);
  const [emailFormatErr, setEmailFormatErr] = useState(false);
  const emailPattern = EMAIL_PATTERN;
  const theme = createTheme({
    palette: {
      error: {
        main: '#EE5350',
      },
    },
  });
  useEffect(() => {
    setNewEbm(value);
  }, [value]);

  useEffect(() => {
    setIsClick(isNextClick);
  }, [isNextClick]);

  useEffect(() => {
    console.log('emailFormatErr', emailFormatErr);
  }, [emailFormatErr]);

  useEffect(() => {
    console.log(
      'isClick && newEbm.firstName === ',
      isClick,
      newEbm.firstName && newEbm.firstName === ''
    );
  }, [isClick]);
  useEffect(() => {
    console.log('newEbm', newEbm);
    onChange(newEbm);
  }, [newEbm]);
  const intl = useIntl();
  return (
    <div>
      <DialogContent
        sx={{ padding: '0px' }}
        data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-DialogContent'
      >
        <Stack
          direction='column'
          spacing={1.25}
          data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-DialogContent-Stack'
        >
          <ItemBlock title={intl.messages['sipDetail.firstName']}>
            <ThemeProvider
              theme={theme}
              data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-DialogContent-ThemeProvider1'
            >
              <MyOnlyText
                id='firstNameInput'
                data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-DialogContent-FirstNameInput'
                variant='outlined'
                value={newEbm.firstName}
                onChange={e => {
                  if (e.target.value.length <= 40) {
                    setNewEbm({
                      ...newEbm,
                      firstName: e.target.value,
                    });
                  }
                }}
                setValue={value => {
                  setNewEbm({
                    ...newEbm,
                    firstName: value,
                  });
                }}
                handleIconButtonClick={value => {
                  setNewEbm({
                    ...newEbm,
                    firstName: '',
                  });
                }}
                error={isClick && newEbm.firstName === ''}
                needEndAdornment={true}
                width='358px'
                height='40px'
              />
            </ThemeProvider>
            {isClick && newEbm.firstName === '' ? (
              <ErrBox data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-DialogContent-ErrBox1'>
                This field is required.
              </ErrBox>
            ) : (
              <></>
            )}
          </ItemBlock>

          <ItemBlock title={intl.messages['sipDetail.lastName']}>
            <ThemeProvider
              theme={theme}
              data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-DialogContent-ThemeProvider2'
            >
              <MyOnlyText
                id='lastNameInput'
                data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-DialogContent-LastNameInput'
                variant='outlined'
                value={newEbm.lastName}
                onChange={e => {
                  if (e.target.value.length <= 40) {
                    setNewEbm({
                      ...newEbm,
                      lastName: e.target.value,
                    });
                  }
                }}
                setValue={value => {
                  setNewEbm({
                    ...newEbm,
                    lastName: value,
                  });
                }}
                handleIconButtonClick={() => {
                  setNewEbm({
                    ...newEbm,
                    lastName: '',
                  });
                }}
                error={isClick && newEbm.lastName === ''}
                needEndAdornment={true}
                width='358px'
                height='40px'
              />
            </ThemeProvider>
            {isClick && newEbm.lastName === '' ? (
              <ErrBox data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-DialogContent-ErrBox2'>
                This field is required.
              </ErrBox>
            ) : (
              <></>
            )}
          </ItemBlock>

          <ItemBlock title={intl.messages['sipDetail.emailAddress']}>
            <ThemeProvider
              theme={theme}
              data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-DialogContent-ThemeProvider3'
            >
              <MyOnlyText
                id='emailAddressInput'
                data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-DialogContent-EmailAddressInput'
                variant='outlined'
                value={newEbm.email}
                onChange={e => {
                  if (e.target.value.length <= 100) {
                    setNewEbm({
                      ...newEbm,
                      email: e.target.value,
                    });
                  }
                }}
                setValue={value => {
                  setNewEbm({
                    ...newEbm,
                    email: value,
                  });
                }}
                handleIconButtonClick={() => {
                  setNewEbm({
                    ...newEbm,
                    email: '',
                  });
                }}
                onBlur={() => {
                  console.log('emailCheck');

                  if (newEbm.email !== '') {
                    setEmailFormatErr(!emailPattern.test(newEbm.email));
                  }
                }}
                onFocus={() => {
                  setEmailFormatErr(false);
                }}
                error={
                  (isClick && newEbm.email === '') ||
                  (emailFormatErr && isClick)
                }
                needEndAdornment={true}
                width='358px'
                height='40px'
              />
            </ThemeProvider>
            {isClick && newEbm.email === '' ? (
              <ErrBox data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-DialogContent-CheckEmialRepeatErrBox'>
                {'Please provide mandatory information.'}
              </ErrBox>
            ) : isClick && emailFormatErr ? (
              <ErrBox data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-DialogContent-ErrBox3'>
                {'Please input correct email format.'}
              </ErrBox>
            ) : isClick && isEmailRepeat ? (
              <ErrBox data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-DialogContent-ErrBox3'>
                {`The EBM's email cannot be same`}
              </ErrBox>
            ) : (
              <></>
            )}
          </ItemBlock>
        </Stack>
      </DialogContent>
    </div>
  );
}

const ItemBlock = props => {
  const { title } = props;
  return (
    <Stack
      spacing={0}
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-ItemBlock-Stack'
    >
      <Stack
        sx={{
          width: '160px',
          float: 'left',
        }}
        spacing={0}
        direction='row'
        data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-ItemBlock-InnerStack'
      >
        <Box
          data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-ItemBlock-Box1'
          sx={{
            height: '20px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            color: '#596A7C',
          }}
        >
          {title}
        </Box>
        <Box
          data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-ItemBlock-Box2'
          style={{
            width: '8px',
            height: '18px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '18px',
            letterSpacing: '0.08em',
            color: '#C40000',
            paddingLeft: '2px',
          }}
        >
          *
        </Box>
      </Stack>
      <Box>{props.children}</Box>
    </Stack>
  );
};

const ErrBox = props => {
  return (
    <Box
      data-selenium-id='SIP_DetailPage_DecisionSection-AssigneeAddPopUp-Err-Box'
      sx={{
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '18px',
        color: '#EE5350',
      }}
    >
      {props.children}
    </Box>
  );
};

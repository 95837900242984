import { axios_instance } from '@/utils/axios_instance';
import {
  CREATE_ROLE,
  DELETE_ROLE,
  DETECT_ROLENAME,
  GET_ALL_ROLE,
  GET_PERMISSION_LIST,
  GET_ROLE_DETAIL,
  UPDATE_ROLE,
} from '@/actions/SIMT-User/Role/RoleManageAction';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';

/**
 * 显示数量获取该页码的角色列表
 * @param {int} page 页码
 * @param {int} pageSize 一页的显示数量
 * @returns {(dispatch: import("redux").Dispatch) => Promise<import("../User/SystemAdmin").getRoleInfoType>} 请求成功时返回角色列表，否则返回空列表
 */
export function getRoleInfo(page, pageSize, orderField, orderType) {
  return async dispatch => {
    try {
      let res = await axios_instance.get('/api/v1/user-service/roles', {
        params: {
          pageNum: page,
          pageSize: pageSize,
          orderField: orderField,
          orderType: orderType,
        },
        headers: {
          authorization: localStorage.getItem('authorization'),
        },
      });

            if (res.data.code === 200) {
                dispatch({type: GET_ALL_ROLE, data: res.data.data});
            } else {
                dispatch({type: GET_ALL_ROLE, data: []});
            }
            return res?.data?.data;
        } catch (err) {
            dispatch({type: GET_ALL_ROLE, data: []});
        }
    };
}

/**
 * 获取所有权限列表
 * @returns 请求成功时返回所有权限的列表，否则返回空列表
 */
export function getPermissionList() {
  return async dispatch => {
    try {
      let res = await axios_instance.get('/api/v1/user-service/permissions', {
        headers: {
          authorization: localStorage.getItem('authorization'),
        },
      });

      if (res.data.code === 200) {
        dispatch({ type: GET_PERMISSION_LIST, data: res.data.data });
      } else {
        dispatch({ type: GET_PERMISSION_LIST, data: [] });
      }
    } catch (err) {
      dispatch({ type: GET_PERMISSION_LIST, data: err.response.data.msg });
    }
  };
}

/**
 * 根据角色名称获取其详细信息，包括id、名称、拥有/未拥有权限
 * @param {string} roleName 角色名称
 * @returns 请求成功时返回角色对象，其他情况返回null
 */
export function getRoleDetail(roleName) {
  return async dispatch => {
    try {
      let res = await axios_instance.get(
        `/api/v1/user-service/roles/${roleName}`,
        {
          headers: {
            authorization: localStorage.getItem('authorization'),
          },
        }
      );
      if (res.data.code === 200) {
        dispatch({ type: GET_ROLE_DETAIL, data: res.data.data });
      } else {
        dispatch({ type: GET_ROLE_DETAIL, data: null });
        dispatch(
          setSnackbarMessageAndOpen('role.loadFailed', {}, SEVERITIES.error)
        );
      }
    } catch (err) {
      dispatch({ type: GET_ROLE_DETAIL, data: err.response.data.msg });
      dispatch(
        setSnackbarMessageAndOpen('role.loadFailed', {}, SEVERITIES.error)
      );
    }
  };
}

/**
 * 添加一个角色
 * @param {string} roleName 角色名称
 * @param {list} permissions 赋予权限列表
 * @returns 请求成功返回true，服务器出错返回false，其他情况返回null
 */
export function createRole(roleName, permissions) {
  return async dispatch => {
    try {
      let record = {
        roleName: roleName.trim(),
        permissionList: permissions,
      };
      let res = await axios_instance.post(
        '/api/v1/user-service/roles',
        record,
        {
          headers: {
            authorization: localStorage.getItem('authorization'),
          },
        }
      );
      if (res.data.code === 200) {
        dispatch({ type: CREATE_ROLE, data: true });
      } else if (res.data.message === 'permissionList is null') {
        dispatch({ type: CREATE_ROLE, data: 'permission null' });
      } else if (res.data.message === 'repetitive role name') {
        dispatch(
          setSnackbarMessageAndOpen(
            'role.roleNameDuplicatePop',
            {},
            SEVERITIES.plain,
            { vertical: 'bottom', horizontal: 'center' }
          )
        );
        dispatch({ type: CREATE_ROLE, data: 'duplicated role name' });
      } else if (res.data.message !== null) {
        dispatch(
          setSnackbarMessageAndOpen(res.data.message, {}, SEVERITIES.plain, {
            vertical: 'bottom',
            horizontal: 'center',
          })
        );
      } else {
        dispatch({ type: CREATE_ROLE, data: null });
      }
    } catch (err) {
      dispatch({ type: CREATE_ROLE, data: err.response.data.msg });
    }
  };
} // successfully add a role
/**
 * 删除角色名称为`roleName`的角色
 * @param {string} roleName 角色名称
 * @returns 请求成功时返回true，服务器出错时返回false，其他情况返回null
 */
export function deleteRole(roleName) {
  return async dispatch => {
    try {
      let res = await axios_instance.delete(
        `/api/v1/user-service/roles/${roleName}`,
        {
          headers: {
            authorization: localStorage.getItem('authorization'),
          },
        }
      );

      if (res.status === 204) {
        dispatch({ type: DELETE_ROLE, data: true });
      } else if (res.data.code === 500) {
        dispatch({ type: DELETE_ROLE, data: false });
      } else if (res.data.code === 18021) {
        dispatch({ type: DELETE_ROLE, data: res.data.message });
      } else {
        dispatch({ type: DELETE_ROLE, data: null });
      }
    } catch (err) {
      dispatch({ type: DELETE_ROLE, data: err.response.data.msg });
    }
  };
}

/**
 * 更新角色名称及权限
 * @param {string} formerRoleName 旧的角色名称
 * @param {string} newRoleName 新的角色名称
 * @param {list} newPermissions 新的角色权限
 * @returns 请求成功时返回true，其他情况返回false
 */
export function updateRole(formerRoleName, newRoleName, newPermissions) {
  return async dispatch => {
    try {
      let record = {
        newRoleName: newRoleName.trim(),
        newPermissionList: newPermissions,
      };
      // return record;
      let res = await axios_instance.put(
        `/api/v1/user-service/roles/${formerRoleName}`,
        record,
        {
          headers: {
            authorization: localStorage.getItem('authorization'),
          },
        }
      );
      if (res.data.code === 200) {
        dispatch({ type: UPDATE_ROLE, data: true });
      } else if (res.data.message === 'update permissionList is null') {
        dispatch({ type: UPDATE_ROLE, data: 'permission null' });
      } else if (res.data.message === 'repetitive role name') {
        dispatch({ type: UPDATE_ROLE, data: 'duplicated role name' });
        dispatch(
          setSnackbarMessageAndOpen(
            'role.roleNameDuplicatePop',
            {},
            SEVERITIES.plain,
            { vertical: 'bottom', horizontal: 'center' }
          )
        );
      } else {
        dispatch({ type: UPDATE_ROLE, data: false });
      }
    } catch (err) {
      dispatch({ type: UPDATE_ROLE, data: err.response.data.msg });
    }
  };
}

/**
 * 判断角色名字是否有效
 * @param {string} roleName 角色名称
 * @param {string} stage 操作方式
 * @param {String} formerRoleName 旧的角色名称
 * @returns 请求成功时返回true，其他情况返回false
 */
export function roleNameIsValid(roleName, stage='', formerRoleName = '') {
  return async dispatch => {
    try {
      let params = {};
      if (stage === 'Update') {
        params['newRoleName'] = roleName?.trim();
        params['formerRoleName'] = formerRoleName?.trim();
      } else {
        params['roleName'] = roleName?.trim();
      }
      let res = await axios_instance.get(`/api/v1/user-service/roles/${stage.toLowerCase()}-detect`, {
        params: params,
        headers: {
          authorization: localStorage.getItem('authorization'),
        },
      });

      if (res.data.code === 200) {
        dispatch({ type: DETECT_ROLENAME, data: true });
      } else {
        dispatch({ type: DETECT_ROLENAME, data: false });
      }
    } catch (err) {
      dispatch({ type: DETECT_ROLENAME, data: err.response.data.msg });
    }
  };
}
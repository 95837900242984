import * as actions from '../actions/SIMT-SI/CatJournal/CATJournalDetailAction';

const initState = {
  'journalCode': 'string',
  'journalName': 'string',
  'subjectGroup': 'string',
  'ownershipStatus': 'string',
  'revenueModel': 'string',
  'primaryURL': 'string',
  'authorGuideline': 'string',
  'vchCode': 'string',
  'businessDivision': 'string',
  'society': 'string',
  'peerReviewModel': 'string',
  'eeoSystemLink': 'string',
  'submissionUrl': 'string',
  'onlineISSN': 'string',
  // 'journalPublishingManager': 'string',
  'editorInChief': 'string',
  // 'associateManagingEditor': null,
  'productionEditor': 'string',
  'marketingManager': 'string',
  // 'editorialDirector': 'string',
  'eOContact': null,
  // 'managingEditor': null,
  'productionManager': 'string',
  'handlingCe': null,
  'partnerPublishingDirector': 'string',
  'partnerPublishingDirectorEmail': 'string',
  'partnerPublishingLead': 'string',
  'partnerPublishingLeadEmail': 'string',
  'partnerPublishingManager': 'string',
  'partnerPublishingManagerEmail': 'string',
  'peerReviewModel': 'string',
  'peerReviewPerformanceDirector': 'string',
  'peerReviewPerformanceDirectorEmail': 'string',
  'peerReviewPerformanceLead': 'string',
  'peerReviewPerformanceLeadEmail': 'string',
  'peerReviewPerformanceManager': 'string',
  'peerReviewPerformanceManagerEmail': 'string',
  'primaryURL': 'string',
  'productionEditor': 'string',
  'productionManager': 'string',
  'publishingDevelopmentDirector': 'string',
  'publishingDevelopmentDirectorEmail': 'string',
  'publishingDevelopmentLead': 'string',
  'publishingDevelopmentLeadEmail': 'string',
  'publishingDevelopmentManager': 'string',
  'publishingDevelopmentManagerEmail': 'string',
  'publishingVP': 'string',
  'publishingVPEmail': 'string',
  'catJournalActiveSiList': [
    {
      'siCode': 'string',
      'siPageLink': 'string',
      'siTitle': 'string',
      'siStage': 'string',
      'numberOfSubmittedArticles': 0,
      'numberOfAcceptedArticles': 0,
      'numberOfRejectedArticles': 0,
      'numberOfInProgressArticles': 0
    }
  ],
  'catJournalPublishedSiList': [
    {
      'id': 0,
      'siCode': 'string',
      'siPageLink': 'string',
      'siTitle': 'string',
      'volume': 'string',
      'issue': 'string',
      'actualPublicationDate': 'string',
      'numberOfOaArticles': 0,
      'numberOfOoArticles': 0
    }
  ],
  'catJournalShelvedSiList': [
    {
      'siCode': 'string',
      'siPageLink': 'string',
      'siTitle': 'string',
      'shelvedReason': 'string',
      'shelvedDate': 'string'
    }
  ]
};

export default function CATJournalDetailReducer(state = initState, action) {
  const { type, data, which } = action;
  switch (type) {
    case actions.GET_JOURNAL_DETAIL:
      return {
        ...state,
        ...(data.data[0]),
      };
    case action.INIT_JOURNAL_DETAIL:
      return {
        ...initState
      };
    default:
      return state;
  }
}

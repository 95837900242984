import {
  Box,
  IconButton,
  Stack,
  CircularProgress,
  FormControlLabel,
  FormControl,
  RadioGroup,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SingleKV } from './Submodule/SingleKV';
import NumberRadio from '../../../components/NumberRadio';
import WriteTable from './Submodule/WriteTable';
import CustomDialog from './Submodule/CustomDialog';
import { ADD_TYPE, EDIT_TYPE } from './Submodule/WriteTable';
import CustomErrorTip from './Submodule/CustomErrorTip';
import {
  changeEditAuthor,
  changeAddAuthor,
  changeDeleteAuthor,


} from '@/actions/SIMT-SIP/SIP/SIPAction';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import GEInfoAdd from '@/assets/GEInfoAdd.svg';
import EditSIDetailnewIcon from '@/assets/EditSIDetailnewIcon.svg';
import OCPSave from '@/assets/OCPSave.svg';
import EditSIDetailnewcancel from '@/assets/EditSIDetailnewcancel.svg';
import EditSIDetailnewsave from '@/assets/EditSIDetailnewsave.svg';
import EditIconButton from '@/componentsphase2/Button/EditIconButton';
import AddIconButton from '@/componentsphase2/Button/AddIconButton';
import YesContainerButton from '@/componentsphase2/Button/ContainerButtons/YesContainerButton';
import TextConfirmButton from '@/componentsphase2/Button/TextConfirmButton';
import TextCancelButton from '@/componentsphase2/Button/TextCancelButton';
import SimpleRadio from '@/componentsphase2/Input/SimpleRadio';
import SimpleLabel from '@/componentsphase2/Input/SimpleLabel';
import { RadioErrorMessage } from '@/componentsphase2/RadioErrorMessage';
import { GET_SIP_STATUS } from '@/actions/SIMT-SIP/SIP/SIPAction';
import {getContentCommissioningApproaches, getSIPStatus} from "@/actions/SIMT-SIP/SIP/SipDetail";
import {
  addPotentialAuthorAction, deletePotentialAuthorAction,
  editPotentialAuthorAction,
  editSipCallForPaper
} from "@/actions/SIMT-SIP/SIP/SipContent";

export default function ContentCommissioningApproachesBlock(props) {
  const { isShevled, editable } = props;
  const [isFold, setIsFold] = useState(true);
  const [tableData, setTableData] = useState([]);

  const [callForPapersEdit, setCallForPapersEdit] = useState(false);
  const [callForPapers, setCallForPapers] = useState(-1);
  const callForPapersRef = useRef(callForPapers);
  const [callForPapersTemp, setCallForPapersTemp] = useState(-1);
  const callForPapersTempRef = useRef(callForPapersTemp);
  const [callForPapersSaving, setCallForPapersSaving] = useState(false);

  const { sipCode } = useParams();

  useEffect(() => {
    callForPapersRef.current = callForPapers;
  }, [callForPapers]);

  useEffect(() => {
    callForPapersTempRef.current = callForPapersTemp;
  }, [callForPapersTemp]);

  useEffect(()=>{
    getSIPStatus(sipCode);
  },[]);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [canAdd, setCanAdd] = useState(true);

  const [isAdding, setIsAdding] = useState(false);

  const [firstClick, setFirstClick] = useState(false); // use to get if pressing correct icon
  const dispatch = useDispatch();

  const selector = state => {
    return {
      contentCommissioningApproaches: state.SIP.contentCommissioningApproaches,
      addedPotentialAuthor: state.SIP.addedPotentialAuthor,
      editPotentialAuthor: state.SIP.editPotentialAuthor,
      deleteTargetAuthor: state.SIP.deleteTargetAuthor,
      afterClickSave: state.SIP.afterClickSave,
      authorNameErrorFlag: state.SIP.authorNameErrorFlag,
      affiliationErrorFlag: state.SIP.affiliationErrorFlag,
      emailErrorFlag: state.SIP.emailErrorFlag,
      sipStage: state.SIP.sipStatus?.sipStage,
      currentDecisionType: state.SIP.currentDecisionType,
      sipDetailAllMandatorySubmitOrNextPressed: state.SIP.sipDetailAllMandatorySubmitOrNextPressed,
      sipVersion: state.SIP.sipStatus?.sipVersion,
      sipStatus: state.SIP.sipStatus,
    };
  };

  const {
    contentCommissioningApproaches,
    addedPotentialAuthor,
    editPotentialAuthor,
    deleteTargetAuthor,
    afterClickSave,
    authorNameErrorFlag,
    affiliationErrorFlag,
    emailErrorFlag,
    sipStage,
    currentDecisionType,
    sipDetailAllMandatorySubmitOrNextPressed,
    sipVersion,
    sipStatus,
  } = useSelector(selector);

  useEffect(() => {
    if (
      contentCommissioningApproaches &&
      contentCommissioningApproaches.potentialAuthors
    ) {
      setTableData(
        contentCommissioningApproaches.potentialAuthors.filter(
          val => val != null
        )
      );
      if (contentCommissioningApproaches.openCallForPapers === null) {
        setCallForPapers(-1);
      } else {
        setCallForPapers(contentCommissioningApproaches.openCallForPapers);
      }
    }
  }, [contentCommissioningApproaches]);

  useEffect(() => {
    if (tableData.length < 50) {
      setCanAdd(true);
    }
  }, [tableData]);

  const createRow = (
    id,
    label,
    minWidth,
    maxWidth,
    isOrder = true,
    align = 'left'
  ) => {
    return { id, label, minWidth, maxWidth, isOrder, align };
  };

  const tableHeads = [
    createRow('name', 'AUTHOR NAME', '220px', '220px', false),
    createRow('affiliation', 'AFFILIATION', '246px', '246px', false),
    createRow('email', 'EMAIL', '260px', '260px', false),
    createRow('topic', 'ARTICLE TOPIC', '260px', '260px', false),
    createRow('actions', 'ACTIONS', '106px', '106px', false),
  ];

  const typeList = {
    name: 'BOLD',
    affiliation: 'TEXT',
    email: 'EMAIL',
    topic: 'UL',
    actions: 'ACTIONS',
  };
  const keyStyle = {
    height: '20px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#596A7C',
    flex: 'none',
    marginTop: '4px',
  };

  const handleOpenCallForPapersEdit = () => {
    console.log('123441455555',callForPapersRef.current);
    setCallForPapersTemp(callForPapersRef.current);
    setCallForPapersEdit(true);
  };

  const handleOpenCallForPapersSave = async () => {
    setCallForPapersSaving(true);

    await dispatch(editSipCallForPaper(sipCode, callForPapersTempRef.current, sipVersion)).then((sipNewVersion) => {
      if (sipNewVersion) {
        let newSipStatus = {
          ...sipStatus,
          sipVersion: sipNewVersion
        };
        dispatch({ type: GET_SIP_STATUS, data: {
          ...newSipStatus
        }});
      }
    });
    await dispatch(getContentCommissioningApproaches(sipCode));

    setCallForPapersSaving(false);
    setCallForPapersEdit(false);
  };

  const tranformOpenCallForPapers = val => {
    if (val) {
      if (val === '1' || val === 1) {
        return 'Yes';
      } else if (val === '-1' || val === -1) {
        return 'N/A';
      } else if (val === '0' || val === 0) {
        return 'No';
      }
    }
    return val;
  };

  const handleAuthorAdd = () => {
    if (tableData.length >= 50) {
      setCanAdd(false);
      dispatch(
        setSnackbarMessageAndOpen(
          'sipDetail.potentialAuthorQuantityLimitErrorMessage',
          {},
          SEVERITIES.error
        )
      );
      return;
    }
    setIsAdding(true);
    dispatch({ type: 'SET_POTENTIAL_AUTHOR_SELECTED_ROW', data: -1 });
    dispatch({ type: 'SET_CLICK_AFTER_SAVE', data: false });
    dispatch({ type: 'SET_AUTHOR_NAME_ERROR', data: true });
    dispatch({ type: 'SET_AFFILIATION_ERROR', data: true });
  };

  const handleAddCancel = () => {
    setIsAdding(false);
  };

  const handleAddPotentialAuthor = async setIsSaving => {
    setIsSaving(true);
    let tmpInfo = JSON.parse(JSON.stringify(addedPotentialAuthor));
    tmpInfo['sipCode'] = sipCode;
    await dispatch(addPotentialAuthorAction({...tmpInfo, sipVersion: sipVersion}, sipCode)).then((sipNewVersion) => {
      if (sipNewVersion) {
        let newSipStatus = {
          ...sipStatus,
          sipVersion: sipNewVersion
        };
        dispatch({ type: GET_SIP_STATUS, data: {
          ...newSipStatus
        }});
      }
    });
    await dispatch(getContentCommissioningApproaches(sipCode));
    await dispatch(
      changeAddAuthor({
        sipCode: null,
        name: null,
        affiliation: null,
        email: null,
        topic: null,
      })
    );
    setIsSaving(false);
  };

  const handleEditPotentialAuthor = async (setIsSaving, authorId) => {
    setIsSaving(true);
    let tmpInfo = JSON.parse(JSON.stringify(editPotentialAuthor));
    await dispatch(editPotentialAuthorAction(tmpInfo,authorId, sipCode,sipVersion)).then((sipNewVersion) => {
      if (sipNewVersion) {
        let newSipStatus = {
          ...sipStatus,
          sipVersion: sipNewVersion
        };
        dispatch({ type: GET_SIP_STATUS, data: {
          ...newSipStatus
        }});
      }
    });

    await dispatch(getContentCommissioningApproaches(sipCode));
    await dispatch(
      changeEditAuthor({
        authorId: null,
        sipCode: null,
        name: null,
        affiliation: null,
        email: null,
        topic: null,
      })
    );
    setIsSaving(false);
  };

  const handleChangeEditInfo = (key, value, type = EDIT_TYPE) => {
    if (type === ADD_TYPE) {
      let tmpAddInfo = JSON.parse(JSON.stringify(addedPotentialAuthor));
      tmpAddInfo[key] = value;
      dispatch(changeAddAuthor(tmpAddInfo));
      return;
    }
    let tmpEditInfo = JSON.parse(JSON.stringify(editPotentialAuthor));
    tmpEditInfo[key] = value;
    dispatch(changeEditAuthor(tmpEditInfo));
  };

  const handleInitEditInfo = row => {
    let tmpInfo = JSON.parse(JSON.stringify(editPotentialAuthor));
    tmpInfo['name'] = row['name'];
    tmpInfo['affiliation'] = row['affiliation'];
    tmpInfo['email'] = row['email'];
    tmpInfo['topic'] = row['topic'];
    dispatch(changeEditAuthor(tmpInfo));
  };

  const handleDeleteInfoChange = (authorId, name) => {
    let tmpObj = JSON.parse(JSON.stringify(deleteTargetAuthor));
    tmpObj['authorId'] = authorId;
    tmpObj['name'] = name;
    dispatch(changeDeleteAuthor(tmpObj));
  };

  const handleDeleteAuthor = async () => {
    setIsDeleting(true);
    await dispatch(
      deletePotentialAuthorAction(sipCode, deleteTargetAuthor.authorId, sipVersion)
    ).then((sipNewVersion) => {
      if (sipNewVersion) {
        let newSipStatus = {
          ...sipStatus,
          sipVersion: sipNewVersion
        };
        dispatch({ type: GET_SIP_STATUS, data: {
          ...newSipStatus
        }});
      }
    });
    await dispatch(getContentCommissioningApproaches(sipCode));
    setIsDeleting(false);
    setDeleteDialogOpen(false);
  };

  const isShowErrorMessage = () => {
    return !isAdding || afterClickSave;
  };

  const hasError = () => {
    return authorNameErrorFlag || affiliationErrorFlag || emailErrorFlag;
  };

  const showAction = () => {
    return !isShevled && editable;
  };

  return (
    <Box data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-Box'>
      <Box
        sx={{ mb: '16px', mt: '20px', cursor: 'pointer' }}
        className='sipBlockTitleBox'
        data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-TitleBox'
      >
        <Box
          data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-FoldBtnBox'
          class='titleFoldButton'
          onClick={async () => {
            await dispatch(getContentCommissioningApproaches(sipCode));
            setIsFold(!isFold);
          }}
        >
          {isFold ? <MarketChevronUp /> : <SIDetailArrowDown />}
        </Box>
        <Stack
          id='titleBoxLeft'
          data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-TitleBoxLeft'
          spacing={1}
          direction='row'
          sx={{ cursor: 'default' }}
        >
          <Box>
            <FormattedMessage
              id='sipDetail.contentCommissioningApproaches'
              data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-Message'
            />
          </Box>
        </Stack>
      </Box>

      {isFold ? (
        <Stack
          direction='column'
          spacing={2}
          data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-Stack'
        >
          <Box data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-Stack-Box1'>
            <SingleKV
              id='sipCCA'
              data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-CCA'
              title={
                <FormattedMessage
                  id='sipDetail.openCallForPapers'
                  data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-OpenCallForPapers'
                />
              }
              type='TEXT'
              margin={
                !callForPapersEdit && (isShevled || !editable)
                  ? '0px 0px 12px 0px'
                  : '0px 0px 4px 0px'
              }
            >
              {callForPapersEdit ? (
                <Box
                  style={{ marginTop: '-4px' }}
                  data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-PaperBox1'
                >
                  <Stack
                    direction='row'
                    spacing={5}
                    data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-PaperStack'
                  >
                    <FormControl data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-FormControl'>
                      <RadioGroup
                        row
                        value={callForPapersTemp}
                        onChange={(event, value) => {
                          setCallForPapersTemp(value);
                        }}
                      >
                        <SimpleLabel
                          label='Yes'
                          control={<SimpleRadio />}
                          value='1'
                        />
                        <SimpleLabel
                          label='No'
                          control={<SimpleRadio />}
                          value='0'
                        />
                        <SimpleLabel
                          label='N/A'
                          control={<SimpleRadio />}
                          value='-1'
                          style={{ marginRight: '8px' }}
                        />
                      </RadioGroup>
                    </FormControl>
                    {/* <NumberRadio
                      data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-NumberRadio'
                      value={Number(callForPapers)}
                      setValue={setCallForPapers}
                    /> */}

                    {callForPapersSaving ? (
                      <CircularProgress
                        size={20}
                        data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-CircularProgress'
                      />
                    ) : (
                      <>
                        <TextConfirmButton
                          onClick={handleOpenCallForPapersSave}
                          data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-PaperStack-IconButton'
                          style={{ marginLeft: '0', paddingLeft: '0' }}
                        ></TextConfirmButton>
                        <TextCancelButton
                          onClick={() => {
                            setCallForPapersEdit(false);
                          }}
                          style={{ marginLeft: '0', paddingLeft: '0' }}
                        ></TextCancelButton>
                      </>
                    )}
                  </Stack>
                </Box>
              ) : (
                <>
                  {isShevled || !editable ? (
                    <Box data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-PaperBox2'>
                      {tranformOpenCallForPapers(callForPapers) ?? 'N/A'}
                    </Box>
                  ) : (
                    <Box
                      style={{ marginTop: '-4px' }}
                      data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-PaperBox3'
                    >
                      {tranformOpenCallForPapers(callForPapers) ?? 'N/A'}
                      {/* <IconButton
                        data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-PaperBox3-IconButton'
                        sx={{ ml: '24px' }}
                        onClick={handleOpenCallForPapersEdit}
                      >
                        <EditSIDetailnewIcon data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-PaperBox3-EditSIDetailnewIcon' />
                      </IconButton> */}
                      <EditIconButton
                        id='SIP_DetailPage-ContentCommissioningApproachesBlock-PaperBox3-IconButton'
                        data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-PaperBox3-IconButton'
                        sx={{ ml: '0px' }} //modified
                        onClick={handleOpenCallForPapersEdit}
                      ></EditIconButton>
                    </Box>
                  )}
                </>
              )}
            </SingleKV>
            <RadioErrorMessage
              isError={sipDetailAllMandatorySubmitOrNextPressed &&
                ((sipStage === 'Ideation' && currentDecisionType === "Move to Submitted Stage")
                ||(sipStage === 'Initial Review')
                ||(sipStage === 'Commissioning Editor Review')
                ||(currentDecisionType === 'Direct Acquire'))
                && (contentCommissioningApproaches.openCallForPapers !== '1'&& contentCommissioningApproaches.openCallForPapers !== '0')
              }
              sx={{marginTop:'-12px'}}
              errorMessage='Please provide the information required as above.'
            />
          </Box>
          <Box
            style={{ marginTop: '0px' }}
            data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-Stack-Box2'
          >
            <Stack
              id='sipCCAPotentialAuthorsSubtitle'
              data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-CCAPotentialAuthorsSubtitle'
              direction='row'
              spacing='0'
              sx={{
                height: '20px',
                mb: '8px',
                alignItems: 'center',
                lineHeight: '20px',
              }}
            >
              <Box style={{ ...keyStyle, marginTop: 0 }}>
                <FormattedMessage
                  id='sipDetail.potentialAuthors'
                  data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-Box-PotentialAuthors'
                />
              </Box>
              {isShevled || !editable ? (
                <></>
              ) : (
                // <IconButton
                //   data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-AddIconButton'
                //   sx={{ pt: '7px', pb: '9px' }}
                //   onClick={handleAuthorAdd}
                //   disabled={isAdding || !canAdd}
                // >
                //   <GEInfoAdd />
                // </IconButton>
                <AddIconButton
                  data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-AddIconButton'
                  // sx={{ pt: '7px', pb: '9px' }}
                  onClick={handleAuthorAdd}
                  disabled={isAdding || !canAdd}
                ></AddIconButton>
              )}
              {/* {
                  isAdding ?
                    <Stack direction="row" spacing={1}  data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-AddStack'>
                      <IconButton sx={{ pt: "7px", pb: "9px" }} onClick={handleAddCancel}
                      data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-AddStack-IconButton1'>
                        <EditSIDetailnewcancel />
                      </IconButton>
                      <IconButton sx={{ pt: "7px", pb: "9px" }}
                       data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-AddStack-IconButton2'>
                        <EditSIDetailnewsave /data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-AddStack-IconButton2-EditSIDetailnewsave'>
                      </IconButton>
                    </Stack>
                    :
                    <IconButton sx={{ pt: "7px", pb: "9px" }} onClick={handleAuthorAdd}
                     data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-IconButton'>
                      <GEInfoAdd  data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-IconButton-GEInfoAdd'/>
                    </IconButton>
                } */}
            </Stack>
            {isShowErrorMessage() && firstClick ? (
              <Stack
                spacing={0.5}
                sx={{ mb: '8px' }}
                data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-Stack-Box2-ErrorMessageStack'
              >
                {/* <CustomErrorTip
                  data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-Stack-Box2-CustomErrorTip'
                  error={
                    authorNameErrorFlag ||
                    affiliationErrorFlag ||
                    emailErrorFlag
                  }
                  text={
                    <FormattedMessage
                      id='sipDetail.potentialAuthorErrorMessage'
                      data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-Stack-Box2-PotentialAuthorErrorMessage'
                    />
                  }
                /> */}
                <CustomErrorTip
                  error={authorNameErrorFlag}
                  text={
                    <FormattedMessage
                      id='sipDetail.authorNameNullError'
                      data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-Stack-Box2-AuthorNameNullError'
                    />
                  }
                />
                <CustomErrorTip
                  error={affiliationErrorFlag}
                  text={
                    <FormattedMessage
                      id='sipDetail.affiliationNullError'
                      data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-Stack-Box2-AffiliationNullError'
                    />
                  }
                />
                <CustomErrorTip
                  error={emailErrorFlag}
                  text={
                    <FormattedMessage
                      id='sipDetail.authorEmailFormatError'
                      data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-Stack-Box2-AuthorEmailFormatError'
                    />
                  }
                />
              </Stack>
            ) : (
              <></>
            )}
            <WriteTable
              id='sipPotentialAuthorsTable'
              data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-PotentialAuthorsTable'
              typeList={typeList}
              TableHeads={tableHeads}
              TableData={tableData}
              emptyTip='noAuthors'
              isAdding={isAdding}
              setIsAdding={setIsAdding}
              doEdit={handleEditPotentialAuthor}
              doAdd={handleAddPotentialAuthor}
              handleInfoChange={handleChangeEditInfo}
              handleDeleteInfoChange={handleDeleteInfoChange}
              initEditInfo={handleInitEditInfo}
              setDeleteDialogOpen={setDeleteDialogOpen}
              hasError={hasError}
              showAction={showAction}
              firstClick={firstClick}
              setFirstClick={setFirstClick}
              clearLastLineBottom={true}
            />
          </Box>
        </Stack>
      ) : (
        <></>
      )}
      <CustomDialog
        data-selenium-id='SIP_DetailPage-ContentCommissioningApproachesBlock-CustomDialog'
        title={`Delete potential author '${deleteTargetAuthor?.name}'`}
        info='Are you sure you want to proceed ?'
        open={deleteDialogOpen}
        handleClose={() => {
          setDeleteDialogOpen(false);
        }}
        handleOK={handleDeleteAuthor}
        isSaving={isDeleting}
      />
    </Box>
  );
}

import {
    Box,
    Button,
    IconButton,
    InputBase,
    Paper,
    Stack,
} from '@mui/material';

import FormControl, { useFormControl } from '@mui/material/FormControl';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DateRangePicker } from './MyDateRangePicker';
import SubOneIcon from '../../../assets/SubOneIcon.svg';
import AddOneIcon from '../../../assets/AddOneIcon.svg';
import {
    publishedExportData,

} from '@/actions/SIMT-SI/Export/ExportDataAction';
import { UntitledAlertBox } from '@/components/Alert/AlertBox';
import {publishedExportSize} from "@/actions/SIMT-SI/Export";


var actualPublicationDateStart = null;
var actualPublicationDateEnd = null;
var number = 1;
var dataRangeStart = null;
var dataRangeLength = null;

export default function Published() {

    const {
        publishedSelectSize,
    } = useSelector(
        state => {
            return {
                publishedSelectSize: state.MarketExport.publishedSelectSize,
            };
        }
    );
    const dispatch = useDispatch();
    const [openStart, setOpenStart] = useState(null);
    const [openEnd, setOpenEnd] = useState(null);
    const [isFocus, setIsFocus] = useState(false);
    const [value, setValue] = useState(1);

    const handleChange = (event) => {
        setValue(event.target.value);
        number = event.target.value;
    };

    useEffect(() => {
        actualPublicationDateStart = null;
        actualPublicationDateEnd = null;
        number = 1;
    }, []);

    const getFormattedDateStr = dateObj => {
        if (dateObj === null) return null;
        const momentDate = moment(dateObj);
        if (!momentDate.isValid()) return null;
        return momentDate.format('YYYY-MM-DD');
    };

    const [open,setOpen] = useState(false);

    function exportExcel() {
        let filename = `Export_Marketing_Data_Published_Campaign_${moment()
            .utc()
            .format('MMDDYYYY_HHmmss')}.xlsx`;
        let obj = {
            actualPublicationDate: {
                start: getFormattedDateStr(actualPublicationDateStart),
                end: getFormattedDateStr(actualPublicationDateEnd),
            },
            number,
            dataRangeStart,
            dataRangeLength
        };

        if (publishedSelectSize != null) {
            let nk = Math.floor(publishedSelectSize / 1000);
            for (let i = 0; i < nk; i++) {
                obj.dataRangeStart = i * 1000;
                obj.dataRangeLength = 1000;
                dispatch(
                    publishedExportData(
                        obj,
                        '/api/v1/si-service/special-issues/marketing-data/export',
                        filename,
                        'published'
                    )
                );
            }
            if (publishedSelectSize % 1000 != 0) {
                obj.dataRangeStart = Math.floor(publishedSelectSize / 1000) * 1000;
                obj.dataRangeLength = publishedSelectSize % 1000;
                dispatch(
                    publishedExportData(
                        obj,
                        '/api/v1/si-service/special-issues/marketing-data/export',
                        filename,
                        'published'
                    )
                );
            }
            else setOpen(true);
        }
    };

    useEffect(() => {
        let obj = {
            actualPublicationDate: {
                start: getFormattedDateStr(actualPublicationDateStart),
                end: getFormattedDateStr(actualPublicationDateEnd),
            },
            number,
        };
        dispatch(
            publishedExportSize(obj)
        );
    }, [, number, actualPublicationDateStart, actualPublicationDateEnd]);

    return (
        <Box
            sx={{
                width: '753px',
                height: '452px',
                background: '#FFFFFF',
            }}
        >
            <UntitledAlertBox
                open={open}
                handleClose={()=>setOpen(false)}
                message={'No eligible SI to export'}
                severity={'warning'}
            />
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{
                    height: '452px',
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Box
                        sx={{
                            fontFamily: 'Open Sans',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '22px',
                            letterSpacing: '0.01em',
                            color: '#262E35',
                        }}
                    >
                        Select data
                    </Box>
                    <Stack
                        direction='row'
                        alignItems='center'
                        spacing='10px'
                        sx={{
                            marginTop: '16px',
                            marginBottom: '12px',
                        }}
                    >
                        <Box
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                fontWeight: 600,
                                lineHeight: '16px',
                                letterSpacing: '0.01em',
                                color: '#596A7C',
                            }}
                        >
                            Actual Publication Date
                        </Box>
                        <Box
                            onClick={() => {
                                setOpenStart(null);
                                setOpenEnd(null);
                                actualPublicationDateStart = null;
                                actualPublicationDateEnd = null;
                            }}
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                fontWeight: 400,
                                lineHeight: '16px',
                                letterSpacing: '0em',
                                color: '#154AB6',
                                '&:hover': {
                                    fontWeight: 600,
                                },
                                cursor:'pointer'
                            }}
                        >
                            {`Reset`}
                        </Box>
                    </Stack>
                    <DateRangePicker
                        fromDate={openStart}
                        toDate={openEnd}
                        handleDateChange={(f, t) => {
                            setOpenStart(f);
                            setOpenEnd(t);
                            actualPublicationDateStart = f;
                            actualPublicationDateEnd = t;
                        }}
                        innerBorder={'1px solid #DFE4E8'}
                        border={'1px solid #FFFFFF'}
                        backgroundColor='#FFFFFF'
                        iconPaddingRight={0}
                        width='753px'
                    />
                    <Box
                        sx={{
                            fontFamily: 'Open Sans',
                            fontSize: '12px',
                            fontWeight: 600,
                            lineHeight: '16px',
                            letterSpacing: '0.01em',
                            color: '#596A7C',
                            marginTop: '16px',
                            marginBottom: '12px',
                        }}
                    >
                        Number of Published Articles
                    </Box>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing='32px'
                    >
                        <Box
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                fontWeight: 400,
                                lineHeight: '15px',
                                letterSpacing: '0em',
                                color: '#98A7B6',
                            }}
                        >
                            Larger and equal to
                        </Box>
                        <Stack
                            alignItems='center'
                            justifyContent='center'
                            sx={{
                                height: '33px',
                                width: '160px',
                                padding: '0px 6px',
                                border: isFocus ? '2px #0052CC solid'
                                    : '1px #DFE4E8 solid',
                                borderRadius: '4px',
                                '&:hover': {
                                    borderColor: isFocus ? '#0052CC' : '#262E35',
                                }
                            }}
                        >
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <IconButton
                                    disabled={value <= 1 ? true : false}
                                    onClick={() => {
                                        setValue(Number(value) - 1);
                                        number--;
                                    }}
                                    disableRipple
                                    sx={{
                                        padding: '0px',
                                        '&:hover': {
                                            'svg path:first-child': {
                                                fill: '#DFE4E8',
                                            },
                                        }
                                    }}
                                >
                                    <SubOneIcon />
                                </IconButton>
                                <InputBase
                                    value={value}
                                    onChange={handleChange}
                                    onFocus={() => {
                                        setIsFocus(true);
                                    }}
                                    onBlur={() => {
                                        setIsFocus(false);
                                    }}
                                    sx={{
                                        width: '100%',
                                        height: '33px',
                                    }}
                                    inputProps={{
                                        sx: {
                                            padding: '0px',
                                            textAlign: 'center',
                                            fontFamily: 'Open Sans',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            lineHeight: '18px',
                                            letterSpacing: '0em',
                                            color: '#262E35',
                                        }
                                    }}
                                />
                                <IconButton
                                    onClick={() => {
                                        setValue(Number(value) + 1);
                                        number++;
                                    }}
                                    disableRipple
                                    sx={{
                                        padding: '0px',
                                        '&:hover': {
                                            'svg rect:first-child': {
                                                fill: '#DFE4E8',
                                            },
                                        }
                                    }}
                                >
                                    <AddOneIcon />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack
                    direction='column'
                    alignItems='center'
                    spacing='4px'
                    sx={{width:'753px'}}
                >
                    <Box
                        sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '22px',
                            letterSpacing: '0em',
                            color: '#98A7B6',
                        }}
                        className={!publishedSelectSize && publishedSelectSize!==0?'text-animation-loading':''}
                    >
                      {!publishedSelectSize && publishedSelectSize!==0 ? '' : `${publishedSelectSize} data are selected`}
                    </Box>
                    <Button
                        disableRipple
                        onClick={exportExcel}
                        sx={{
                            width: '617px',
                            height: '50px',
                            padding: '14px 20px 14px 20px',
                            borderRadius: '5px',
                            background: '#0052CC',
                            color: '#FFFFFF',
                            fontFamily: 'Inter',
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '22px',
                            letterSpacing: '0em',
                            textTransform: 'none',
                            '&:hover': {
                                background: '#6D98EE',
                            },
                            '&:active': {
                                background: '#113D95',
                            },
                        }}
                    >
                        Export
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
}

import * as actions from '../actions/SIMT-User/User/UserAction';
import MyLocalStorage from '../utils/MyLocalStorage';

const initState = {
  editAdminResult: false,
  editUserResult: false,
  userInfo: null,
  userIds: [],
  activeResult: null,
  createAdminResult: '',
  firstName: MyLocalStorage.getItem('userInfo')?.firstName ?? '',
  lastName: MyLocalStorage.getItem('userInfo')?.lastName ?? '',
  name: MyLocalStorage.getItem('userInfo')?.name ?? '',
  email: MyLocalStorage.getItem('userInfo')?.email ?? '',
  role: MyLocalStorage.getItem('userInfo')?.role ?? '',
  group: MyLocalStorage.getItem('userInfo')?.group ?? '',
  userId: MyLocalStorage.getItem('userInfo')?.id ?? -1,
  createUserResult: '',
  updateUserResult: '',
  batchUpdateResult: '',
  inactiveAdmin: false,
  inactiveUser: false,
  unlockAdmin: false,
  unlockUser: false,
  singleAdmin: null,
  upgradeUser: null,
  downgardeAdmin: null,
  updateAdmin: null,
  checkEmail: false,
  adminEmailCheck: {},
  adminEmailCheckEmail: '',
  groupInfo: [],
  //超级管理员管理admin相关
  adminOrderByType: {
    // 排序相关
    order: 'asc',
    orderBy: 'fullName',
  },
  adminData: {
    result: [],
    count: 0,
  },
  adminPage: 1,
  adminPageSize: 10,
  adminUpdateName: '',
};

export default function UserReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.EDIT_ADMIN:
      return {
        ...state,
        editAdminResult: data,
      };
    case actions.EDIT_USER:
      return {
        ...state,
        editUserResult: data,
      };
    case actions.GET_ALL_USER_INFO:
      return {
        ...state,
        userInfo: data,
      };
    case actions.GET_ALL_USER_IDS:
      return {
        ...state,
        userIds: data,
      };
    case actions.ACTIVE_ACCOUNT:
      return {
        ...state,
        activeResult: data,
      };
    case actions.ACTIVE_CLEAR:
      return {
        ...state,
        activeResult: null,
      };
    case actions.CREATE_ADMIN:
      return {
        ...state,
        createAdminResult: data,
      };
    case actions.GET_USER_INFO:
      return {
        ...state,
        firstName: data.firstName,
        lastName: data.lastName,
        name: data.name,
        email: data.email,
        role: data.role,
        group: data.group,
        userId: data.id,
      };
    case actions.CREATE_USER:
      return {
        ...state,
        createUserResult: data,
      };
    case actions.UPDATE_USER:
      return {
        ...state,
        updateUserResult: data,
      };
    case actions.INACTIVE_ADMIN:
      return {
        ...state,
        inactiveAdmin: data,
      };
    case actions.INACTIVE_USER:
      return {
        ...state,
        inactiveUser: data,
      };
    case actions.UNLOCK_ADMIN:
      return {
        ...state,
        unlockAdmin: data,
      };
    case actions.UNLOCK_USER:
      return {
        ...state,
        unlockUser: data,
      };
    case actions.GET_ADMIN_INFO:
      return {
        ...state,
        singleAdmin: data.data,
        email: data.email,
      };
    case actions.UPGRADE_USER:
      return {
        ...state,
        upgradeUser: data,
      };
    case actions.DOWNGRADE_ADMIN:
      return {
        ...state,
        downgardeAdmin: data,
      };
    case actions.UPDATE_ADMIN:
      return {
        ...state,
        updateAdmin: data,
      };
    case actions.CLEAR_USER_ACTION:
      return initState;
    case actions.CLEAR_USER_LOCK:
      return {
        ...initState,
        unlockUser: false,
      };
    case actions.BTACH_UPDATE_RESULT:
      return {
        ...state,
        batchUpdateResult: data,
      };
    case actions.EDIT_CLEAR:
      return {
        ...state,
        createAdminResult: '',
        createUserResult: '',
        updateUserResult: '',
        batchUpdateResult: '',
        inactiveAdmin: false,
        unlockAdmin: false,
        singleAdmin: null,
        upgradeUser: null,
        downgardeAdmin: null,
        updateAdmin: null,
        checkEmail: false,
        adminEmailCheck: {},
        adminEmailCheckEmail: '',
        editAdminResult: false,
      };
    case actions.CHECK_USER_EMAIL:
      return {
        ...state,
        checkEmail: data,
      };
    case actions.GET_GROUP_INFO:
      return {
        ...state,
        groupInfo: data,
      };
    case actions.ADMIN_EMAIL_CHECK:
      return {
        ...state,
        adminEmailCheck: data.data,
        adminEmailCheckEmail: data.email,
      };
    //超级管理员admin表格相关
    case actions.ADMIN_ORDER_BY:
      return {
        ...state,
        adminOrderByType: data,
      };
    case actions.LOAD_ADMIN_DATA:
      return {
        ...state,
        adminData: data,
      };
    case actions.CHANGE_ADMIN_PAGE:
      return {
        ...state,
        adminPage: data,
      };
    case actions.CHANGE_ADMIN_PAGE_SIZE:
      return {
        ...state,
        adminPageSize: data,
        adminPage: 1,
      };
    case actions.CHANGE_UPDATE_ADMIN:
      return {
        ...state,
        adminUpdateName: data,
      };
    default:
      return state;
  }
}

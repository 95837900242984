import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  MyTableHeader,
  StyledTableCell,
  TableFooter2,
} from '../../../../components/DataTable';
import { ReportNoData, StyledTableRow, TableBox2 } from "../../ReportSpecificComponents/SpecialColorTableHeader";
import { findLongestLineSubstring, measureWidth } from "../../../../utils/StringWidthMeasure";
import React from "react";
import { TextLabel } from "../../../../componentsphase2/CustomTooltip";
import {getTargetsJournal} from "@/actions/SIMT-Report/Report/Target";

/**
 * @author LiuXin
 * @date 2022/5/11
 * @param {*} props
 * @returns
 */
function JournalTable(props) {
  const { fiscalYear } = props;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [perPageNumber, setPerPageNumber] = useState(20);
  const [loading, setLoading] = useState(false);

  const [orderType, setOrderType] = useState('journalCode'); //排序的表头
  const [orderAs, setOrderAs] = useState('asc'); //排序的顺序

  const selector = state => {
    return {
      targetJournalInfo: state.Report.targetJournalInfo,
    };
  };
  const { targetJournalInfo } = useSelector(selector);

  const createRow = (
    id,
    label,
    minWidth,
    maxWidth,
    isOrder = false,
    align = 'left',
    show = true
  ) => {
    return { id, label, minWidth, maxWidth, isOrder, align, show };
  };

  const initTableHeaderList = [
    createRow('journalCode', 'Journal Code', '150px', '', true, 'left'),
    createRow('targetAcquiredSi', 'Acquired SI', '100px', '', false, 'right'),
    createRow('targetSubmission', 'Submission', '200px', '', false, 'right'),
    createRow('targetAcceptance', 'Acceptance', '200px', '', false, 'right'),
    createRow('targetPublication', 'Publication', '200px', '', false, 'right'),
  ];

  const [tableHeaderList, setHeaderList] = useState(initTableHeaderList);

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    for (let item of initTableHeaderList){
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '18px Open Sans');
    }
    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (targetJournalInfo && targetJournalInfo.count > 0){
      const maxMap = {};
      for (let dataObj of targetJournalInfo['result']) {
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);

      const newHeadList = [... initTableHeaderList];
      for (let item of newHeadList){
        item['sx'] = {
          zIndex: 8,
          // width: `${Math.max((columnWidthMap[item.id]??0), columnMinWidthMap[item.id]) + 50 }px`,
          ...(item.id==='journalCode' &&{
            position: 'sticky',
            left: 0,
            top: 0,
            zIndex: 8,
            backgroundColor: '#DFE4E8 !important',
          }),
        };
      }
      setHeaderList(newHeadList);
    }
  }, [targetJournalInfo]);


  useEffect(() => {
    async function getData() {
      if (
        fiscalYear !== null &&
        fiscalYear !== undefined &&
        fiscalYear !== ''
      ) {
        unstable_batchedUpdates(() => {
          dispatch(getTargetsJournal(fiscalYear, page, perPageNumber, orderAs));
        });
        setLoading(false);
      }
    }
    setLoading(true);
    getData();
  }, [fiscalYear, page, perPageNumber, orderAs]);

  const handlePerPageChange = e => {
    setPerPageNumber(e.target.value);
  };

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderType === property && orderAs === 'asc';
    setOrderAs(isAsc ? 'desc' : 'asc');
    setOrderType(property);
  };

  return (
    <TableBox2
      id='BasicTableBox'
      data-selenium-id='Report_Targets_Overview_JournalTable'
      dataList={[targetJournalInfo]}
      tableKey={'Report_Targets_Overview_JournalTable'}
      footerHeight={40}
    >
      <TableContainer
        id='reportTargetsTOJournalTable'
        data-selenium-id='Report_Targets_Overview_JournalTable-TableContainer'
        sx={{ minHeight: '300px', maxHeight: '680px' }}
      >
        <Table
          data-selenium-id='Report_Targets_Overview_JournalTable-Table'
          stickyHeader
          aria-label='simple table'
          size='small'
          sx={{
            // tableLayout: 'fixed',
            tableLayout: 'auto',
            // width: 'max-content',
            maxWidth: 'none',
          }}
        >
          <MyTableHeader
            data-selenium-id='Report_Targets_Overview_JournalTable-MyTableHeader'
            hasCheckbox={false} // width is defined here
            items={tableHeaderList}
            order={orderAs}
            orderBy={orderType}
            onRequestSort={handleRequestSort}
            // backgroundColor='#d6ddff'
            backgroundColor='#F1F3F5'
          />
          <TableBody data-selenium-id='Report_Targets_Overview_JournalTable-TableBody'>
            {!loading &&
            targetJournalInfo != null &&
            targetJournalInfo.count > 0 ? (
              targetJournalInfo['result'].map((row, index) => {
                return (
                  <StyledTableRow
                    data-selenium-id={
                      'Report_Targets_Overview_JournalTable-Row-' + index
                    }
                    key={row.journalCode + '' + index}
                    aria-checked={false}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0
                      },
                      fontWeight: 600,
                      maxWidth: '110px',
                      position: 'sticky',
                      left: 0,
                      zIndex: 6,
                      backgroundColor: '#F1F3F5',
                    }}
                    selected={false}
                  >
                    <StyledTableCell
                      data-selenium-id={
                        'Report_Targets_Overview_JournalTable-Row-' +
                        index +
                        '-JournalCodeCell'
                      }
                      align='left'
                      sx={{ paddingLeft: '24px !important' }}
                    >
                      {row.journalCode}
                    </StyledTableCell>
                    <StyledTableCell
                      data-selenium-id={
                        'Report_Targets_Overview_JournalTable-Row-' +
                        index +
                        '-TargetAcquiredSiCell'
                      }
                      align='left'
                      className='ReportNumberTextColor'
                      sx={{ paddingRight: '24px !important' }}
                    >
                      <TextLabel
                        data-selenium-id={
                          'Report_Targets_Overview_JournalTable-Row-' +
                          index +
                          '-TargetAcquiredSiCell-TextLabel'
                        }
                        text={row.targetAcquiredSi}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      data-selenium-id={
                        'Report_Targets_Overview_JournalTable-Row-' +
                        index +
                        '-TargetSubmissionCell'
                      }
                      align='left'
                      className='ReportNumberTextColor'
                      sx={{ paddingRight: '24px !important' }}
                    >
                      <TextLabel
                        data-selenium-id={
                          'Report_Targets_Overview_JournalTable-Row-' +
                          index +
                          '-TargetSubmissionCell-TextLabel'
                        }
                        text={row.targetSubmission}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      data-selenium-id={
                        'Report_Targets_Overview_JournalTable-Row-' +
                        index +
                        '-TargetAcceptanceCell'
                      }
                      align='left'
                      className='ReportNumberTextColor'
                      sx={{ paddingRight: '24px !important' }}
                    >
                      <TextLabel
                        data-selenium-id={
                          'Report_Targets_Overview_JournalTable-Row-' +
                          index +
                          '-TargetAcceptanceCell-TextLabel'
                        }
                        text={row.targetAcceptance}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      data-selenium-id={
                        'Report_Targets_Overview_JournalTable-Row-' +
                        index +
                        '-TargetPublicationCell'
                      }
                      align='left'
                      className='ReportNumberTextColor'
                      sx={{ paddingRight: '24px !important' }}
                    >
                      <TextLabel
                        data-selenium-id={
                          'Report_Targets_Overview_JournalTable-Row-' +
                          index +
                          '-TargetPublicationCell-TextLabel'
                        }
                        text={row.targetPublication}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            ) : !loading ? (
              <ReportNoData top='50px' id={'Report-Journal-NoData'}/>
            ) : (
              <div
                data-selenium-id={
                  'Report_Targets_Overview_JournalTable-EmptyDiv'
                }
              ></div>
            )}
          </TableBody>
        </Table>
        {loading && (
          <Box
            data-selenium-id={'Report_Targets_Overview_JournalTable-LoadingBox'}
            sx={{
              width: 'auto',
              marginTop: '150px',
              marginLeft: '50%',
            }}
          >
            <CircularProgress
              data-selenium-id={
                'Report_Targets_Overview_JournalTable-LoadingCircularProgress'
              }
            />
          </Box>
        )}
      </TableContainer>
      <Box
        data-selenium-id={
          'Report_Targets_Overview_JournalTable-TableFooter2-ContainerBox'
        }
      >
        <TableFooter2
          data-selenium-id={'Report_Targets_Overview_JournalTable-TableFooter2'}
          numSelected={0}
          Page={page}
          perPageNumber={perPageNumber}
          perPageList={[20, 50, 100]}
          count={targetJournalInfo != null ? targetJournalInfo['count'] : 0}
          handlePerPageChange={handlePerPageChange}
          handlePageChange={handlePageChange}
          // backgroundColor='#CCD4FF'
          backgroundColor='#F1F3F5'
        />
      </Box>
    </TableBox2>
  );
}

export default JournalTable;

import { IconButton, Popover } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import VectorIcon from '../../assets/Arrow2.svg';
import * as React from 'react';
import { useRef } from 'react';

function MenuIconBase(props) {
  const {
    id,
    popoverMaxHeight = 48 * 6 + 'px',
    blueIsLeft = false,
    anchorRef,
    children,
    RenderIconButton,
    popoverSx,
    outerId,
    height,
    ...others
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const holdRef = useRef(null);

  const handleClick = event => {
    if (!anchorRef) {
      setAnchorEl(holdRef.current);
    } else {
      setAnchorEl(anchorRef);
    }
    if (outerId) {
      setTimeout(() => {
        let select = document.getElementById(`siPageIssueTableGEMenuButton${modifiedId}`);
        let popper = document.getElementById(
          'MenuIcon-Popover'
        );
        let papper = popper.getElementsByClassName('MuiPaper-root')[0];
        let bottom =
          6 +
          Number(papper.style.top.split('px')[0]) +
          height;
        let selectBottom = select.getBoundingClientRect().top + 20;
        let difHeightOrigin =
          selectBottom -
          (Number(papper.style.marginTop.split('px')[0]) +
            Number(papper.style.top.split('px')[0]));
        let outer = document.getElementById(outerId);
        if (window.innerHeight < bottom + 32) {
          let difHeight = bottom + 32 - window.innerHeight;
          let scrollTop = outer.scrollTop;
          outer.scrollTo(0, scrollTop + difHeight + difHeightOrigin);
          setTimeout(() => {
            papper.style.top = `${
              Number(papper.style.top.split('px')[0]) - difHeight + 6
            }px`;
          }, 100);
        }
      }, 100);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!children || children.length === 0) {
    return <></>;
  }

  let modifiedId;
  if (id !== null) {
    modifiedId = id.slice(0, 4) + id.slice(6);
  } // 去掉第四个和第五个字符

  const DefaultRenderIconButton = ({ id, open, handleClick }) => {
    return (
      <IconButton
        aria-label='more'
        id={`siPageIssueTableGEMenuButton${modifiedId}`}
        data-selenium-id={id}
        aria-controls={open ? `long-menu-ge${id}` : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
        sx={{
          padding: '0px 0px 0px 0px',
          transform: open
            ? 'rotate(-90deg) translateX(2px)'
            : 'rotate(0deg) translateY(-2px)',
          transition: 'all 0.3s',
          height: '20px',
          top: '2px',
        }}
      >
        <VectorIcon data-selenium-id='MenuIcon-VectorIcon' />
      </IconButton>
    );
  };

  const IconButtonRender = RenderIconButton || DefaultRenderIconButton;

  return (
    <>
      <div ref={holdRef}>
        <IconButtonRender
          id={id}
          open={open}
          handleClick={handleClick}
          {...others}
        />
      </div>
      <Popover
        id='MenuIcon-Popover'
        data-selenium-id='MenuIcon-Popover'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: blueIsLeft?'left':'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: blueIsLeft?'left':'right',
        }}
        PaperProps={{
          style: {
            borderRadius: '5px', //modify
            ...(blueIsLeft && {
              borderLeft: 'solid', //modify
              borderLeftWidth: '8px',
              borderLeftColor: '#154AB6', //modify
            }),
            ...(!blueIsLeft && {
              borderRight: 'solid', //modify
              borderRightWidth: '8px',
              borderRightColor: '#154AB6', //modify
            }),
            paddingTop:'12px',
            paddingBottom:'12px',
            paddingRight: '3px',
            marginTop: 6,
            boxShadow: 'none',
            filter: 'drop-shadow(0px 6px 12px rgba(38, 46, 53, 0.12))',
            '&>div>div': {
              boxShadow: 'none',
            },
          },
        }}
        sx={popoverSx}
        className='dropdown-popover'
      >
        <Box
          sx={{
            maxHeight: popoverMaxHeight,
            overflowY: 'scroll',
            overflowX: 'hidden',
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-moz-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'var(--gray-200, #DFE4E8)',
              borderRadius: '10px',
              width: '6px',
              opacity: 0.7,
            },
            '&::-webkit-scrollbar': {
              width: '6px',
            },
          }}
        >
          {children}
        </Box>
      </Popover>
    </>
  );
}

export const MenuIcon = MenuIconBase;

export default MenuIcon;

import './index.css';
function Alert1() {
  return (
    <div className='alert1'>
      The length of password should be at least 8 characters, including at least
      3 types of uppercase letter, lower case letter, digit, and special
      character.
    </div>
  );
}

export default Alert1;

import { MiniFont, SmallFont } from '@/components/FontStyle';
import { Box } from '@mui/material';

export default function DisabledBox(props) {
  const { value, defaultValue, id, submissionPass } = props;
  return (
    <Box
      id={`${id}_disabled_input`}
      data-selenium-id={`${id}_disabled_input`}
      sx={{
        boxSizing: 'border-box',
        padding: '11px 12px',
        height: '40px',
        backgroundColor: '#F6F7F8',
        border: '1px solid #DFE4E8',
        borderRadius: '3px',
        ...SmallFont,
        lineHeight: '125%',
        color: submissionPass ? '#d32f2f' : '#BCC5CF',
      }}
    >
      {value ?? defaultValue}
    </Box>
  );
}

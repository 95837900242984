import { Box, IconButton, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RadioUnselected from '@/assets/RadioUnselected.svg';
import RadioSelected from '@/assets/RadioSelected.svg';

/**
 *
 * @param {import("./CustomRadio").CustomRadioProps} props
 * @returns
 */
export default function SSIPCustomRadio(props) {
  const {
    value,
    setValue,
    sx,
    labelStyle = {},
    selectedIcon = (
      <RadioSelected data-selenium-id='SIP_DetailPage_Submodule-CustomRadio-Selected' />
    ),
    unSelectedIcon = (
      <RadioUnselected data-selenium-id='SIP_DetailPage_Submodule-CustomRadio-UnSelected' />
    ),
    ...others
  } = props;

  const handleYesDBClick = () => {
    // setValue(-1);
  };

  const handleNoDBClick = () => {
    // setValue(-1);
  };

  const handleNAClick = () =>{
    setValue(-1);
  };

  const handleYesClick = () => {
    setValue(1);
  };

  const handleNoClick = () => {
    setValue(0);
  };

  const pointerStyle = {
    cursor: 'pointer',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
  };

  /**
   * @type {import("@mui/material").SxProps}
   */
  const defaultLabelStyle = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#505050',
    marginLeft: '20px',
  };

  return (
    <Stack
      direction='row'
      sx={{ WebkitUserSelect: 'none', ...sx }}
      {...others}
      data-selenium-id='SIP_DetailPage_Submodule-CustomRadio-Stack'
    >
      <Stack
        direction='row'
        width={'79px'}
        alignItems='center'
        data-selenium-id='SIP_DetailPage_Submodule-CustomRadio-HandleYesStack'
      >
        {value === 1 ? (
          <div
            style={pointerStyle}
            onClick={handleYesDBClick}
            data-selenium-id='SIP_DetailPage_Submodule-CustomRadio-HandleYesStack-SelectedIcon'
          >
            {selectedIcon}
          </div>
        ) : (
          <div
            style={pointerStyle}
            onClick={handleYesClick}
            data-selenium-id='SIP_DetailPage_Submodule-CustomRadio-HandleYesStack-UnSelected'
          >
            {unSelectedIcon}
          </div>
        )}
        <Box sx={{ ...defaultLabelStyle, ...labelStyle,fontSize:'14px',lineHeight:'20px',marginLeft:'13px' }}>Yes</Box>
      </Stack>
      <Stack
        direction='row'
        width={'79px'}
        alignItems='center'
        data-selenium-id='SIP_DetailPage_Submodule-CustomRadio-HandleNoStack'
      >
        {value === 0 ? (
          <div
            style={pointerStyle}
            onClick={handleNoDBClick}
            data-selenium-id='SIP_DetailPage_Submodule-CustomRadio-HandleNoStack-SelectIcon'
          >
            {selectedIcon}
          </div>
        ) : (
          <div
            style={pointerStyle}
            onClick={handleNoClick}
            data-selenium-id='SIP_DetailPage_Submodule-CustomRadio-HandleNoStack-UnSelectIcon'
          >
            {unSelectedIcon}
          </div>
        )}
        <Box sx={{ ...defaultLabelStyle, ...labelStyle,fontSize:'14px',lineHeight:'20px',marginLeft:'13px' }}>No</Box>
      </Stack>
      <Stack
        direction='row'
        width={'70px'}
        alignItems='center'
        data-selenium-id='SIP_DetailPage_Submodule-CustomRadio-HandleNoStack'
      >
        {value === -1 ? (
          <div
            style={pointerStyle}
            onClick={handleYesDBClick}
            data-selenium-id='SIP_DetailPage_Submodule-CustomRadio-HandleNoStack-SelectIcon'
          >
            {selectedIcon}
          </div>
        ) : (
          <div
            style={pointerStyle}
            onClick={handleNAClick}
            data-selenium-id='SIP_DetailPage_Submodule-CustomRadio-HandleNoStack-UnSelectIcon'
          >
            {unSelectedIcon}
          </div>
        )}
        <Box sx={{ ...defaultLabelStyle, ...labelStyle,fontSize:'14px',lineHeight:'20px',marginLeft:'13px' }}>N/A</Box>
      </Stack>
      <Box
         sx={{
           cursor: 'pointer',
           fontSize: '14px',
           fontWeight: '400',
           lineHeight: '20px',
           letterSpacing: '0em',
           color: '#154AB6',
           padding: '1px 0px',
           pointerEvents: 'auto',
         }}
         onClick={()=>{setValue(-2);}}
       >
         {' '}
         Clear
       </Box>
    </Stack>
  );
}

import {
  Box,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import { useDispatch } from 'react-redux';
import { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import SIModalPro from '@/modules/Modal';
import { useParams } from 'react-router-dom';
import { StateButton } from '@/componentsphase2/StateButton';
import { LoadingButton } from '@mui/lab';
import { useIntl } from 'react-intl';
import { DeleteCV, getSIPStatus } from '@/actions/SIMT-SIP/SIP/SipDetail';
import FormHelperText from '@mui/material/FormHelperText';
import InviteEBMPage from './InviteEBMPage';
import InviteEBMEmailPreview from './InviteEBMEmailPreview';
import { EMAIL_PATTERN } from '@/constant/common';
import CommonSnackBar from '@/modules/ConfirmSnackBar/CommonSnackBar';
import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { axios_instance } from '@/utils/axios_instance';
import { ButtonAlertBox } from '@/components/Alert/AlertBox';
import { getBtoa } from '@/actions/SIMT-Email/Template';

export function CancelEbmModal(props) {
  const { title = '', open = false, handleClose = () => {} } = props;

  const [data, setData] = useState([]);
  const [isClick, setIsClick] = useState(false);
  const [isNextClick, setIsNextClick] = useState(false);

  const [emailAddressFormatErr, setEmailAddressFormatErr] = useState(false);
  const [webFormatErr, setWebFormatErr] = useState(false);
  const [saving, setSaving] = useState(false);
  const [page, setPage] = useState(1);
  const [hasError, setHasError] = useState(false);
  const [comments, setComments] = useState('');
  const [isCommentError, setIsCommentError] = useState(false);
  const [focusComments, setFoucusComments] = useState(false);
  const [buttonAlertOpen, setButtonAlertOpen] = useState(false);
  const [emailBody, setEmailBody] = useState('');

  const { sipCode } = useParams();
  const ref = useRef(null);

  const selector = state => {
    return {
      sipStatus: state.SIP.sipStatus,
    };
  };
  const { sipStatus } = useSelector(selector);

  useEffect(() => {
    dispatch(getSIPStatus(sipCode));
  }, [sipCode]);
  useEffect(() => {
    console.log('sipStatus', sipStatus);
  }, [sipStatus]);
  const dispatch = useDispatch();

  const actionButtonStyle = useMemo(() => {
    return {
      fontWeight: 600,
      fontSize: '14px',
      height: '41px',
      padding: '12px 18px',
      ml: '10px',
      background: saving ? '#6D98EE' : '#0052CC',
      borderRadius: '5px',
      color: '#FFFFFF',
      width: '82px',
      height: '34px',
      textTransform: 'none',
      '&:hover': {
        background: '#6D98EE',
      },
      '&:active': {
        background: '#113D95',
      },
      '&:disabled':{
        background: '#DFE4E8',
        color: '#98A7B6',
      },
    };
  }, [saving]);

  const intl = useIntl();

  const handleModalClose = () => {
    setEmailAddressFormatErr(false);
    setWebFormatErr(false);
    setIsClick(false);
    setIsNextClick(false);
    setPage(1);
    handleClose();
  };
  const alertButton = [
    {
      text: 'Keep Editing',
      onClick: () => {
        setButtonAlertOpen(false);
      },
    },
    {
      text: 'Submit',
      onClick: () => {
        setButtonAlertOpen(false);
        cancelEbmInvitation(sipCode, sipStatus.sipVersion, comments, emailBody);
      },
    },
  ];

  function cancelEbmInvitation(sipCode, version, comments, emailBody) {
    axios_instance
      .put(`/api/v1/sip-service/special-issue-proposals/${sipCode}/ebms`, {
        version: version,
        action: 'cancel',
        comments: comments,
        emailBody: getBtoa(emailBody)
      })
      .then(res => {
        if (res.data.code === 200) {
          dispatch(getSIPStatus(sipCode));
          handleModalClose();
          dispatch(
            setSnackbarMessageAndOpen(
              'sipDetail.ebmReview.cancelSuccess',
              {},
              SEVERITIES.success
            )
          );
          return;
        }
      })
      .catch(e => {
        dispatch(
          setSnackbarMessageAndOpen(
            'sipDetail.ebmReview.cancelError',
            {},
            SEVERITIES.error
          )
        );
      })
      .finally(() => {});
  }

  return (
    <SIModalPro
      isRightModel={true}
      data-selenium-id='SIP_DetailPage_GE-GEInfomationAddModal-Dialog'
      open={open}
      title={title}
      handleClose={handleModalClose}
      titleElseElement={
        <Box>
          <StateButton
            titleName='Cancel'
            isPrimary={false}
            onClick={handleModalClose}
          ></StateButton>
          {page === 1 && (
            <LoadingButton
              sx={actionButtonStyle}
              disableElevation
              disableRipple
              id='StateButtonForPrimaryButtonSave'
              data-selenium-id='State_Button_For_Primary_Button_Save'
              onClick={() => {
                setIsNextClick(true);
                if (!hasError) {
                  setPage(2);
                }
              }}
              disabled={hasError}
              loading={saving}
              data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions-Box-EditBtnAvailable'
            >
              Next
            </LoadingButton>
          )}
          {page === 2 && (
            <LoadingButton
              sx={actionButtonStyle}
              disableElevation
              disableRipple
              id='StateButtonForPrimaryButtonSave'
              data-selenium-id='State_Button_For_Primary_Button_Save'
              onClick={() => {
                setButtonAlertOpen(true);
              }}
              loading={saving}
              data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions-Box-EditBtnAvailable'
            >
              Submit
            </LoadingButton>
          )}
        </Box>
      }
    >
      <ButtonAlertBox
        open={buttonAlertOpen}
        handleClose={() => {
          setButtonAlertOpen(false);
        }}
        title='Send this email?'
        message='After submission, the Invitation will be cancelled, and the email cannot be revoked.'
        buttons={alertButton}
        severity='warning'
        position={{ vertical: 'center', horizontal: 'center' }}
        autoHideDuration={-1}
        style={{
          width: '546px',
          height: '163px',
          top: '20px !important',
        }}
      />
      <CommonSnackBar />
      <div>
        {page === 1 && (
          <Stack gap={'20px'}>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '20px',
              }}
            >
              Are you sure you want to cancel EBM Invitations?
            </Typography>
            <Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  paddingBottom: '8px',
                }}
              >
                Comments
              </Typography>
              <MyOnlyText
                ref={ref}
                placeholder='Add Comment here.'
                value={comments}
                setValue={setComments}
                height='100px'
                multiline={true}
                sx={{ flexGrow: 1 }}
                minRows={5}
                maxRows={5}
                isError={isCommentError && !focusComments}
                focusComments={focusComments}
                onFocus={() => {
                  setFoucusComments(true);
                }}
                onBlur={() => {
                  setFoucusComments(false);
                }}
                needFormatCheck = {true}
                pattern = {/^.{0,1000}$/}
                setFormatError={setHasError}
                errorMessage='If you accept to provide comments, please ensure they are added before submitting.'
              />

              <FormHelperText
                data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormHelperText'
                sx={{
                  textAlign: 'right',
                  marginTop:
                    comments.length > 1000 && !focusComments ? '-14px' : '4px',
                  fontSize: '12px',
                  lineHeight: '18px',
                  height: '15px',
                  color: comments.length > 1000 ? '#DE350B' : '#596A7C',
                  marginBottom: '3px',
                }}
              >
                {comments.length}/1000
              </FormHelperText>
            </Box>
          </Stack>
        )}
        {page === 2 && (
          <InviteEBMEmailPreview
            sipCode={sipCode}
            requestType='EBM Review - Cancel EBM Invitations'
            emailBody={emailBody}
            setEmailBody={setEmailBody}
          />
        )}
      </div>
    </SIModalPro>
  );
}

import { axios_instance } from "@/utils/axios_instance";
import {
    GET_SIPDETAIL_OBJECT_PREFERRED_SUBJECT_AREA_DROPLIST,
    SIPDETAIL_OBJECT_EDIT,
    SIP_VERSION_ERROR
} from "@/actions/SIMT-SIP/SIP/SIPAction";
import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";
import { getSIPStatus } from "@/actions/SIMT-SIP/SIP/SipDetail";
import { getMessageIdFromResponse } from "@/utils/commonUtils";

/**
 * @type {import("../../../pages/SIPPage/RevisePage/Section/ObjectSection").GetSIPDetailObjectPreferredSubjectAreaDropListFunction}
 */
export function getSIPDetailObjectPreferredSubjectAreaDropList(
    name,
    url = `/api/v1/sip-service/special-issue-proposals/filter-metadata`,
    extraConfig = {
        headers: {
            authorization: localStorage.getItem('authorization'),
        },
    },
    isExternalPage = false,
) {
    return async dispatch => {
        try {
            const res = await axios_instance.get(url, {
                params: {
                    name: name,
                    type: 'subject'
                },
                ...(isExternalPage ? {} : extraConfig),
            });
            const { code, data } = res.data;
            if (code === 200 && data !== "invalid type") {
                dispatch({
                    type: GET_SIPDETAIL_OBJECT_PREFERRED_SUBJECT_AREA_DROPLIST,
                    data: data,
                });
            } else {
                dispatch({
                    type: GET_SIPDETAIL_OBJECT_PREFERRED_SUBJECT_AREA_DROPLIST,
                    data: [],
                });
            }
        } catch {
            dispatch({
                type: GET_SIPDETAIL_OBJECT_PREFERRED_SUBJECT_AREA_DROPLIST,
                data: [],
            });
        }
    };
}

export function SIPDetailObjectEdit(sipDetailObject, sipCode) {
    const action = 'editObject';
    return async dispatch => {
        try {
            const url = `api/v1/sip-service/special-issue-proposals/${sipCode}`;
            const res = await axios_instance.put(url, 
                {'editObjectRequests': sipDetailObject}, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
                params: {
                    sipVersion: sipDetailObject.sipVersion,
                    action: action,
                },
            });
            const { code, message, data } = res.data;

            if (code === 200) {
                dispatch({ type: SIPDETAIL_OBJECT_EDIT, data: message });
                return res.data.sipVersion;
            } else if (code === SIP_VERSION_ERROR) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusChanged',
                        {},
                        SEVERITIES.warning
                    )
                );
                getSIPStatus(sipCode);
            } else {
                const mid = getMessageIdFromResponse(res);
                dispatch(setSnackbarMessageAndOpen(mid, {}, SEVERITIES.error));
                dispatch({ type: SIPDETAIL_OBJECT_EDIT, data: 'error' });
            }
        } catch {
            dispatch({ type: SIPDETAIL_OBJECT_EDIT, data: 'error' });
        }
    };
}
export const MONTH = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const OA_AND_OO_SI_ACQUISITION = 'OA and OO SI Acquisition in';
export const OA_AND_OO_SI_ACQUISITION_YTD = 'OA and OO SI Acquisition YTD in';
export const OA_AND_OO_ACTIVE_SI = 'OA and OO Active SI in';

export const SUBJECT_GROUP = 'Subject Group';
export const BUSINESS_DIVISION = 'Business Division';

export function getCurrentTime() {
  // format: _MMDDYYYY_HHMMSS
  let yy = new Date().getFullYear();
  let mm =
    new Date().getMonth() + 1 < 10
      ? '0' + new Date().getMonth()
      : new Date().getMonth();
  let dd =
    new Date().getDate() < 10
      ? '0' + new Date().getDate()
      : new Date().getDate();
  let hh =
    new Date().getHours() < 10
      ? '0' + new Date().getHours()
      : new Date().getHours();
  let mf =
    new Date().getMinutes() < 10
      ? '0' + new Date().getMinutes()
      : new Date().getMinutes();
  let ss =
    new Date().getSeconds() < 10
      ? '0' + new Date().getSeconds()
      : new Date().getSeconds();
  return '_' + mm + dd + yy + '_' + hh + mf + ss;
}

// define the excel export title and fields
export const SI_ACQUISITION = 'SIAcquisition';
export const SI_ACQUISITION_YTD = 'SIAcquisitionYTD';
export const ACTIVE_SI = 'ActiveSI';

export const EXCEL_HEADER = {
  [SI_ACQUISITION]: {
    exportHeader: ['@{acc}All', '@{acc}OA', '@{acc}OA %'],
    partHeader: ['Acquired SI - MMM'],
  },
  [SI_ACQUISITION_YTD]: {
    exportHeader: ['@{acc-YTD}All', '@{acc-YTD}OA', '@{acc-YTD}OA %'],
    partHeader: ['Acquired SI - YTD'],
  },
  [ACTIVE_SI]: {
    exportHeader: ['@{active}All', '@{active}OA', '@{active}OA %'],
    partHeader: ['Active SI'],
  },
};

import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import { tabClasses } from '@mui/material';

export const ReportTabParent = styled(Tab)(({ theme }) => ({
  [`&.${tabClasses.root}`]: {
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: '1px',
  },
  [`&.${tabClasses.selected}`]: {
    color: theme.palette.primary.main,
  },
}));

export const ReportTabChildren = styled(Tab)(({ theme }) => ({
  [`&.${tabClasses.root}`]: {
    textTransform: 'capitalize',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
  },
  [`&.${tabClasses.selected}`]: {
    color: theme.palette.darkBlue.main,
  },
}));

import React from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SingleKV } from '../Submodule/SingleKV';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import EditSIDetailnewIcon from '@/assets/EditSIDetailnewIcon.svg';
import EditIconButton from '@/componentsphase2/Button/EditIconButton';
import styleModule from '../GEInformationBlock.module.scss';
import { RadioErrorMessage } from '@/componentsphase2/RadioErrorMessage';
import { getSIPInformation } from '@/actions/SIMT-SIP/SIP/SipDetail';

export default function SIPInformationBlock(props) {
  const { setIsSIPInfomationEdit, Editable = true, sipStage } = props;
  const intl = useIntl();
  const [isFold, setIsFold] = useState(true);
  const { sipCode } = useParams();
  const dispatch = useDispatch();
  const selector = state => {
    return {
      sipInformation: state.SIP.sipInformation,
      contentCommissioningApproaches: state.SIP.contentCommissioningApproaches,
      currentDecisionType: state.SIP.currentDecisionType,
      sipDetailAllMandatorySubmitOrNextPressed:
        state.SIP.sipDetailAllMandatorySubmitOrNextPressed,
    };
  };
  const {
    sipInformation,
    contentCommissioningApproaches,
    currentDecisionType,
    sipDetailAllMandatorySubmitOrNextPressed,
  } = useSelector(selector);

  const typeList = {
    sipSource: 'HIDE_TEXT',
    sipTitle: 'HIDE_TEXT',
    aimsAndScope: 'HIDE_TEXT',
    topics: 'HIDE_UL',
    keywords: 'KEYWORDS',
    references: 'HIDE_UL',
    fmSubmissionDeadline: 'DATE',
    acceptanceDeadline: 'DATE',
    epDate: 'DATE',
    notesForEditor: 'HIDE_TEXT',
  };

  const fieldList =
    sipInformation?.sipType === 'Yes'
      ? [
          //SSIP与SIP
          'sipSource',
          'sipTitle',
          'aimsAndScope',
          'topics',
          'keywords',
          'references',
          'fmSubmissionDeadline',
          'acceptanceDeadline',
          'epDate',
          'notesForEditor',
        ]
      : [
          'sipSource',
          'sipTitle',
          'aimsAndScope',
          'topics',
          'keywords',
          'references',
          'fmSubmissionDeadline',
          'acceptanceDeadline',
          'epDate',
          'notesForEditor',
        ];

  // const sipInformation = {
  //   sipTitle: "实例规定段落中的文本不进行换行：p 浏览器支持所有浏览器都支持 white-space 属性。注释：任何的版本的 Internet Explorer （包括 IE8）都不支持属性值 。取值normal :默认值。默认处理方式。文本自动处理换行。假如抵达容器边界内容会转到下一行。pre :用等宽字体显示预先格式化的文本。不合并字间的空白距离和进行两端对齐。换行和其他空白字符都将受到保护。这个值需要IE6+或者!DOCTYPE [1]  声明为 standards-compliant mode 支持。如果 !DOCTYPE 声明没有指定为 standards-compliant mode ，此属性可以使用，但是不会发生作用。结果等同于 normal 。参阅 pre 对象。nowrap :强制在同一行内显示所有文本，直到文本结束或者遭遇 br 对象。参阅td，div等对象的nowrap属性（特性）",
  //   aimsAndScope: null,
  //   topics: [
  //     "实例规定段落中的文本不进行换行：实例规定段落中的文本不进行换行：",
  //     "dawd",
  //     "实例规定段落中的]例规定段段落中的文本不进行换行：实例规定段行规定段行换行：实例规定段行：实例规定段落中的]例规定段段落中的文本不进行换行：实例规定段落中的]例规定段段落中的文本不进行落中的]例规定段段落中的文本不进行换行：",
  //     "apple"],
  //   keywords: [],
  //   references: [],
  //   fmSubmissionDeadline: null,
  //   acceptanceDeadline: null,
  //   epDate: null,
  //   notesForEditor: null
  // }

  const handleKeyDiff = (key, val) => {
    switch (key) {
      case 'sipTitle':
        // case 'aimsAndScope':
        // case 'topics':
        return val;
      default:
        if (val && (typeof val === 'number' || val.length > 0)) {
          return val;
        }
        return 'N/A';
    }
  };

  const showErrorMessage = (sipInformationType, sipType) => {
    const isSSIP = sipInformation?.sipType === 'Yes';
    const isUSIP = sipInformation?.sipType === 'No';
    const openCallForPapersIsYes = contentCommissioningApproaches?.openCallForPapers == 1;

    // Case I (strict)
    const isSSIPStrict = isSSIP &&
      ((sipStage !== intl.messages['sipDetail.sipStageType.ideation'] && sipStage !== intl.messages['sipDetail.sipStageType.revision']) ||
        currentDecisionType === intl.messages['sipDetail.decisionPanelElements.moveToSubmitted']);
    const isFullValidation = isSSIPStrict || isUSIP;
    const isTextBlocksMandatory = (isSSIPStrict && openCallForPapersIsYes) || isUSIP;

    switch (sipInformationType) {
      case 'aimsAndScope':
        return (
          (sipInformation.aimsAndScope == null ||
            sipInformation.aimsAndScope == '') &&
          isTextBlocksMandatory
        );
      case 'topics':
        return (
          (sipInformation.topics.length == 0 ||
            sipInformation.topics?.[0] === '') &&
          isTextBlocksMandatory
        );
      case 'keywords':
        return (
          sipInformation.keywords.length == 0 &&
          isTextBlocksMandatory
        );
      case 'fmSubmissionDeadline':
        return isFullValidation && sipInformation?.fmSubmissionDeadline === null;
      case 'acceptanceDeadline':
        return isUSIP && sipInformation?.acceptanceDeadline === null;
      case 'epDate':
        return isUSIP && sipInformation?.epDate === null;
      default:
        return false;
    }
  };

  return (
    <Box data-selenium-id='SIP_DetailPage_InfomationBlock-Box'>
      <Box
        sx={{ mb: '16px', mt: '32px', cursor: 'pointer' }}
        className='sipBlockTitleBox'
        data-selenium-id='SIP_DetailPage_InfomationBlock-TitleBox'
      >
        <Box
          data-selenium-id='SIP_DetailPage_InfomationBlock-TitleFoldButtonBox'
          class='titleFoldButton'
          onClick={async () => {
            await dispatch(getSIPInformation(sipCode));
            setIsFold(!isFold);
          }}
        >
          {isFold ? <MarketChevronUp /> : <SIDetailArrowDown />}
        </Box>
        <Stack
          id='titleBoxLeft'
          data-selenium-id='SIP_DetailPage_InfomationBlock-TitleBoxLeft'
          spacing={1}
          direction='row'
          sx={{ cursor: 'default' }}
        >
          <Box>
            <FormattedMessage
              id='sipDetail.sipInformation'
              data-selenium-id='SIP_DetailPage_InfomationBlock-Box-Detail'
            />
          </Box>
          {Editable ? (
            // <IconButton
            //   id='sipDetailObjectEditButton'
            //   data-selenium-id='SIP_DetailPage_InfomationBlock-ObjectEditButton'
            //   onClick={() => {
            //     setIsSIPInfomationEdit(true);
            //   }}
            // >
            //   <EditSIDetailnewIcon data-selenium-id='SIP_DetailPage_InfomationBlock-EditSInewIcon' />
            // </IconButton>
            <EditIconButton
              id='sipDetailsipInformationEditButton'
              data-selenium-id='SIP_DetailPage_InfomationBlock-sipInformationEditButton'
              onClick={() => {
                setIsSIPInfomationEdit(true);
              }}
              className={styleModule.ButtonMarginLeft0}
            ></EditIconButton>
          ) : (
            <></>
          )}
        </Stack>
      </Box>
      {isFold ? (
        <Stack
          direction='column'
          spacing={1}
          data-selenium-id='SIP_DetailPage_InfomationBlock-Stack'
        >
          {fieldList.map((item, index) => {
            if (sipInformation?.sipType === 'No' && item === 'sipSource')
              return;
            return (
              <Stack
                direction='column'
                spacing={0}
                data-selenium-id='SIP_DetailPage_InfomationBlock-Stack1'
              >
                <SingleKV
                  id={item}
                  data-selenium-id={`SIP_DetailPage_InfomationBlock-Stack-${item}`}
                  title={<FormattedMessage id={`sipDetail.${item}`} />}
                  type={typeList[item]}
                  margin='0px 0px 0px 0px'
                  key={index}
                >
                  {sipInformation == null
                    ? 'N/A'
                    : handleKeyDiff(item, sipInformation[item])}
                </SingleKV>
                <RadioErrorMessage
                  isError={
                    sipDetailAllMandatorySubmitOrNextPressed &&
                    showErrorMessage(item, sipInformation?.sipType)
                  }
                  errorMessage='Please provide the information required as above.'
                />
              </Stack>
            );
          })}
        </Stack>
      ) : (
        <></>
      )}
    </Box>
  );
}

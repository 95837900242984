/**
 * yueying
 * AnnualTrends
 */
import ReportExportButton from '../../ReportSpecificComponents/ReportExportMenuButton';
import { StyledSwitchTwoColor } from '../../ReportSpecificComponents/SpecialColorSwitch';
// <<<<<<< HEAD
import { Box, InputLabel, Grid, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
// =======
// import {
//   Box,
//   Grid,
//   } from '@mui/material';
// import React, { useEffect, useState } from 'react';
// >>>>>>> release-p2m1-20230919
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDate as selectAnnualTrendsDate,

} from '@/actions/SIMT-Report/Report/ReportAction';
import AnnualSubjectTable from './AnnualSubjectTable';
import AnnualBusinessTable from './AnnualBusinessTable';
// <<<<<<< HEAD
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DesktopDatePicker } from '@mui/x-date-pickers';
// import { GenerationDate } from '../../GenerationDate';
// =======
import { DatePickerStack, GenerationDate } from '../../GenerationDate';
// >>>>>>> release-p2m1-20230919
import styles from '../../header-switch.module.scss';
import {
  TableGroupBox,
  TableLabel,
} from '../../ReportSpecificComponents/SpecialColorTableHeader';
import ExportAllDialog from '../../ExportAllDialog';
import ReportExportMenuButton from '../../ReportSpecificComponents/ReportExportMenuButton';
import {
  exportAnnualTrendsCsv, exportAnnualTrendsCsvWithFilenameFromBackEnd,
  exportAnnualTrendsExcel,
  exportAnnualTrendsExcelWithFilenameFromBackEnd
} from "@/actions/SIMT-Report/Report/Export";
import {GetAcquiredActiveLastActionDate} from "@/actions/SIMT-Report/Report/ReportAcquiredAndActive";

export function PageHeadAnnual({ showTable, setShowTable }) {
  const { selectMonthDate, lastActionDate } = useSelector(state => {
    return {
      // selectMonthDate: state.Report.AnnualTrendsDate,
      selectMonthDate: state.Report.selectDate,
      lastActionDate: state.Report.AcquiredActiveReportGenenateDate,
    };
  });
  const GenerationDateAndTime =
    'Below reports are generated on ' + lastActionDate + ' (UTC time). ';
  const [selectMonth, setSelectMonth] = useState(new Date());
  const [months, setMonths] = useState([]);
  const currentDate = new Date();
  const dispatch = useDispatch();

  const getMonthList = () => {
    if (months.length === 0) {
      var year = currentDate.getFullYear();
      var month = currentDate.getMonth() + 1;
      for (var i = 0; i < 4; i++) {
        var tmpyear = year - i;
        if (i === 0) {
          for (var j = month; j > 0; j--) {
            months.push('' + tmpyear + '-' + '' + j);
            setMonths(months);
          }
        } else {
          for (var k = 12; k > 0; k--) {
            months.push('' + tmpyear + '-' + '' + k);
            setMonths(months);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (selectMonthDate != null) {
      var Year = selectMonthDate.split('-')[0];
      var Month = selectMonthDate.split('-')[1] - 1;
      setSelectMonth(new Date(Year, Month));
    }
  }, [selectMonthDate]);
  const exportExcel = () => {
    dispatch(exportAnnualTrendsExcel(selectMonthDate));
  };

  const exportExcelWithFilenameFromBackEnd = () => {
    dispatch(exportAnnualTrendsExcelWithFilenameFromBackEnd(selectMonthDate));
  };

  const exportCsv = () => {
    dispatch(exportAnnualTrendsCsv(selectMonthDate, 'subject_group_table'));
    dispatch(exportAnnualTrendsCsv(selectMonthDate, 'business_division_table'));
  };

  const exportCsvWithFilenameFromBackEnd = () => {
    dispatch(
      exportAnnualTrendsCsvWithFilenameFromBackEnd(
        selectMonthDate,
        'subject_group_table'
      )
    );
    dispatch(
      exportAnnualTrendsCsvWithFilenameFromBackEnd(
        selectMonthDate,
        'business_division_table'
      )
    );
  };

  const handleDateChange = value => {
    setSelectMonth(value);
    var year = value.getFullYear();
    var month = value.getMonth() + 1;
    let date = year + '-' + month;
    dispatch(selectAnnualTrendsDate(date));
    dispatch(GetAcquiredActiveLastActionDate(date, false));
  };

  useEffect(() => {
    getMonthList();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth() + 1;
    var newDate = '' + year + '-' + month;
    if (selectMonthDate == null) {
      dispatch(selectAnnualTrendsDate(newDate));
      dispatch(GetAcquiredActiveLastActionDate(newDate, false));
    }
  }, []);
  const [openExportAllDialog, setOpenExportAllDialog] = useState(false);
  return (
    <>
      <ExportAllDialog
        open={openExportAllDialog}
        handleClose={() => setOpenExportAllDialog(false)}
        filterData={[]}
        handleCSV={() => exportCsvWithFilenameFromBackEnd()}
        handleExcel={() => exportExcelWithFilenameFromBackEnd()}
      />
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-PageHeadAnnual-Grid'
        sx={{
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        <DatePickerStack
          id='Report_AcquiredAndActiveSi-AnnualTrends-PageHeadAnnual-Stack'
          selectedMonth={selectMonth}
          handleDateChange={handleDateChange}
          lastActionDate={lastActionDate}
          showTable={showTable}
          GenerationDateAndTime={GenerationDateAndTime}
        />
        <Grid className={styles['action-buttons-container']}>
          {showTable && (
            //   <ReportExportButton
            //     sx={{ height: '32px', marginRight: '24px' }}
            //   items={[
            //     {
            //       label: 'Export to CSV',
            //       operation: exportCsvWithFilenameFromBackEnd,
            //     },
            //     {
            //       label: 'Export to Excel',
            //       operation: exportExcelWithFilenameFromBackEnd,
            //     },
            //   ]}
            //   data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-PageHeadAnnual-ReportExportButton'
            // ></ReportExportButton>
            <ReportExportMenuButton
              sx={{ height: '32px', marginRight: '24px' }}
              id='reportSipipelineExportButton'
              mode={1}
              // label='common.Export'
              onClick={() => {
                setOpenExportAllDialog(true);
              }}
            />
          )}
          <span className={styles['switch']}>
            <span
              id='show-table-button'
              className={styles[showTable ? 'selected' : '']}
              onClick={() => {
                setShowTable(true);
              }}
            >
              Table
            </span>
            <span
              id='show-diagram-button'
              className={styles[!showTable ? 'selected' : '']}
              onClick={() => {
                setShowTable(false);
              }}
            >
              Diagram
            </span>
          </span>
        </Grid>
      </Grid>
    </>
  );
}
export default function HeadAnnual(props) {
  const { title } = props;
  const [checked, setChecked] = useState(true);
  const handleChangeSwitch = e => {
    setChecked(e.target.checked);
  };
  return (
    <TableGroupBox>
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-HeadAnnual-Grid'
      >
        <TableLabel>{title}</TableLabel>
        <Grid
          sx={{ p: '25px 0px 0px 6px', marginRight: '30px' }}
          data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-HeadAnnual-Grid2'
        >
          <>
            <StyledSwitchTwoColor
              checked={checked}
              onChange={handleChangeSwitch}
              id={
                title === 'Subject Group'
                  ? 'SGSwitchTwoColor'
                  : 'BDSwitchTwoColor'
              }
              data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-HeadAnnual-StyledSwitchTwoColor'
            ></StyledSwitchTwoColor>
          </>
        </Grid>
      </Grid>
      <Box
        // sx={{ p: '33px 6px 16px 6px' }}
        data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-HeadAnnual-Box'
      >
        {title === 'Subject Group' ? (
          <AnnualSubjectTable
            isOA={checked}
            data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-HeadAnnual-AnnualSubjectTable'
          />
        ) : (
          <AnnualBusinessTable
            isOA={checked}
            data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-HeadAnnual-AnnualBusinessTable'
          />
        )}
      </Box>
    </TableGroupBox>
  );
}

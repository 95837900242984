import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Box, Typography } from '@mui/material';
import ErrorIcon from '@/assets/ErrorIcon.svg';
import { max } from 'date-fns';

export const severityConfigurationMap = {
  info: {
    borderColor: '#4C81EB',
    titleColor: '#154AB6',
    contentColor: '#596A7C',
    backgroundColor: '#E8EDFB',
    icon: <InfoIcon />,
  },
  success: {
    borderColor: '#42C1B3',
    titleColor: '#0D6A61',
    contentColor: '#596A7C',
    backgroundColor: '#ECFDF5',
    icon: <SuccessIcon />,
  },
  warning: {
    borderColor: '#FFCA7B',
    titleColor: '#D97706',
    contentColor: '#596A7C',
    backgroundColor: '#FFFBEB',
    hoverColor: '#854300',
    pressedColor: '#854300',
    icon: <WarningIcon />,
  },
  error: {
    borderColor: 'transparent',
    titleColor: '#FFFFFF',
    contentColor: '#596A7C',
    backgroundColor: '#FF8180',
    icon: <ErrorIcon />,
  },
};

export function getConfigName(severity) {
  return severity in severityConfigurationMap ? severity : 'info';
}

export function AlertBox(props) {
  const {
    open,
    handleClose,
    title,
    message,
    autoHideDuration,
    severity = 'info',
    style,
    sx,
    ...others
  } = props;

  const [severityConfig, setSeverityConfig] = React.useState(
    severityConfigurationMap[getConfigName(severity)]
  );

  React.useEffect(() => {
    setSeverityConfig(severityConfigurationMap[getConfigName(severity)]);
  }, [severity]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
      style={{ top: '76px', right: '36px', ...style }}
      sx={{
        background: severityConfig.backgroundColor,
        border: '1px solid ' + severityConfig.borderColor,
        boxShadow: '0px 12px 24px 0px rgba(38, 46, 53, 0.12)',
        borderRadius: '5px',
        ...sx,
      }}
      {...others}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
          padding: '15px 32px 21px 24px',
        }}
      >
        <Box
          sx={{
            width: '20px',
            height: '20px',
            flex: 'none',
            order: 0,
            flexGrow: 0,
          }}
        >
          {severityConfig.icon}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '0px',
            gap: '12px',
            width: '458px',
            flex: 'none',
            order: 1,
            flexGrow: 1,
          }}
        >
          <div
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '17px',
              color: severityConfig.titleColor,
              flex: 'none',
              order: 0,
              alignSelf: 'stretch',
              flexGrow: 0,
            }}
          >
            {title}
          </div>
          <div
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '17px',
              color: severityConfig.contentColor,
              flex: 'none',
              order: 0,
              alignSelf: 'stretch',
              flexGrow: 0,
            }}
          >
            {message}
          </div>
        </Box>
      </Box>
    </Snackbar>
  );
}

export function UntitledAlertBox(props) {
  const {
    open,
    handleClose,
    message,
    autoHideDuration = 4000,
    severity = 'info',
    style,
    ...others
  } = props;

  const [severityConfig, setSeverityConfig] = React.useState(
    severityConfigurationMap[getConfigName(severity)]
  );

  React.useEffect(() => {
    setSeverityConfig(severityConfigurationMap[getConfigName(severity)]);
  }, [severity]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
      style={{ top: '76px', right: '36px', ...style }}
      sx={{
        background: severityConfig.backgroundColor,
        width: '546px',
        border: '1px solid ' + severityConfig.borderColor,
        boxShadow: '0px 12px 24px 0px rgba(38, 46, 53, 0.12)',
        borderRadius: '5px',
        display: 'flex',
      }}
      {...others}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '16px 32px 16px 24px',
          gap: '12px',
          width: '100%',
          alignSelf: 'stretch',
        }}
      >
        <Box
          sx={{
            width: '20px',
            height: '20px',
            flex: 'none',
            order: 0,
            flexGrow: 0,
          }}
        >
          {severityConfig.icon}
        </Box>
        <div
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '17px',
            flex: 'none',
            order: 1,
            flexGrow: 1,
            color: severityConfig.titleColor,
            maxWidth: '100%',
          }}
        >
          {message}
        </div>
      </Box>
    </Snackbar>
  );
}

export function ButtonAlertBox(props) {
  /**
   * buttons为结构体数组，例如：
   *  [
   *      {
   *          text: "View more",
   *          onClick：() => navigate(`/simt/auth/siRelated/ge/detail/${value}`)
   *      },
   *      {
   *          text: "Dismiss",
   *          onClick：() => console.log("Hello world!")
   *      }
   *  ]
   *
   * By 赵蓁翔
   */
  const {
    open,
    handleClose,
    title,
    message,
    buttons,
    style,
    autoHideDuration = 4000,
    severity = 'info',
    position = { vertical: 'top', horizontal: 'right' },
    top = '76px',
  } = props;

  const [severityConfig, setSeverityConfig] = React.useState(
    severityConfigurationMap[getConfigName(severity)]
  );

  React.useEffect(() => {
    setSeverityConfig(severityConfigurationMap[getConfigName(severity)]);
  }, [severity]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: position.vertical,
        horizontal: position.horizontal,
      }}
      open={open}
      onClose={handleClose}
      autoHideDuration={autoHideDuration < 0 ? null : autoHideDuration}
      style={{ ...style }}
      sx={{
        background: severityConfig.backgroundColor,
        border: '1px solid ' + severityConfig.borderColor,
        boxShadow: '0px 12px 24px 0px rgba(38, 46, 53, 0.12)',
        borderRadius: '5px',
        top: `${top} !important`,
        right: '36px !important',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: buttons?.length == 0?'0px':'20px',
          padding: '16px 32px 16px 24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '12px',
          }}
        >
          <Box
            sx={{
              width: '20px',
              height: '20px',
              flex: 'none',
              order: 0,
              flexGrow: 0,
            }}
          >
            {severityConfig.icon}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '0px',
              gap: '12px',
              width: '458px',
              //height: '46px',
              flex: 'none',
              order: 1,
              flexGrow: 1,
            }}
          >
            <div
              style={{
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '17px',
                color: severityConfig.titleColor,
                flex: 'none',
                order: 0,
                alignSelf: 'stretch',
                flexGrow: 0,
              }}
            >
              {title}
            </div>
            <div
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '17px',
                color: severityConfig.contentColor,
                flex: 'none',
                order: 0,
                alignSelf: 'stretch',
                flexGrow: 0,
              }}
            >
              {message}
            </div>
          </Box>
        </Box>
        <Box
          display={'flex'}
          sx={{
            gap: '18px',
            ml: '32px',
          }}
        >
          {buttons.map((button, index) => (
            <Typography
              key={index}
              sx={{
                cursor: 'pointer',
                fontFamily: 'Inter!important',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: '-0.28px',
                color: severityConfig.titleColor,
                '&:hover': {
                  color: severityConfig.hoverColor ?? severityConfig.titleColor,
                },
              }}
              onClick={() => {
                button?.onClick();
              }}
            >
              {button.text}
            </Typography>
          ))}
        </Box>
      </Box>
    </Snackbar>
  );
}

function WarningIcon() {
  return (
    <>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.57465 3.21665L1.51632 15C1.37079 15.252 1.29379 15.5377 1.29298 15.8288C1.29216 16.1198 1.36756 16.4059 1.51167 16.6588C1.65579 16.9116 1.86359 17.1223 2.11441 17.2699C2.36523 17.4174 2.65032 17.4968 2.94132 17.5H17.058C17.349 17.4968 17.6341 17.4174 17.8849 17.2699C18.1357 17.1223 18.3435 16.9116 18.4876 16.6588C18.6317 16.4059 18.7071 16.1198 18.7063 15.8288C18.7055 15.5377 18.6285 15.252 18.483 15L11.4247 3.21665C11.2761 2.97174 11.0669 2.76925 10.8173 2.62872C10.5677 2.48819 10.2861 2.41437 9.99965 2.41437C9.71321 2.41437 9.43159 2.48819 9.18199 2.62872C8.93238 2.76925 8.72321 2.97174 8.57465 3.21665V3.21665Z'
          stroke='#D97706'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10 7.5V10.8333'
          stroke='#D97706'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10 14.1667H10.0083'
          stroke='#D97706'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </>
  );
}

function SuccessIcon() {
  return (
    <>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_1846_6586)'>
          <path
            d='M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833'
            stroke='#0D6A61'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M18.3333 3.33325L10 11.6749L7.5 9.17492'
            stroke='#0D6A61'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_1846_6586'>
            <rect width='20' height='20' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

function InfoIcon() {
  return (
    <>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_1846_18028)'>
          <path
            d='M9.99984 18.3334C14.6022 18.3334 18.3332 14.6025 18.3332 10.0001C18.3332 5.39771 14.6022 1.66675 9.99984 1.66675C5.39746 1.66675 1.6665 5.39771 1.6665 10.0001C1.6665 14.6025 5.39746 18.3334 9.99984 18.3334Z'
            stroke='#154AB6'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M10 13.3333V10'
            stroke='#154AB6'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M10 6.66675H10.0083'
            stroke='#154AB6'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_1846_18028'>
            <rect width='20' height='20' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

import * as React from 'react';
import { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, TableCell, Tooltip } from '@mui/material';
import {
  PurpleGreenCell, ReportNoData, StyledTableCellNormal, TableBox, TableBox2,
  TwoColorTableRowGreen,
  TwoColorTableRowPurple
} from "../../ReportSpecificComponents/SpecialColorTableHeader";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '../../../../components/DataTable';
import { CommonContentCells, CommonHeaderFirstRow, CommonHeaderSecondRow } from "./FYTrends";
import { getFyYearFromDate } from "../../../../utils/date_string";
import { useState } from "react";
import { findLongestLineSubstring, measureWidth } from "../../../../utils/StringWidthMeasure";
import monthIds from "../../monthIds";
import { SimpleTooltip } from "../../../../componentsphase2/CustomTooltip";
import {
    GetAcquiredActiveLastActionDateFY,
    getFYSubjectData
} from "@/actions/SIMT-Report/Report/ReportAcquiredAndActive";

export function SubjectRow(props) {
  const { row, isTotal } = props;
  return (
    <>
      {/*第一个cell不是数字*/}
      <StyledTableCell
        align={isTotal ? 'right' : 'left'}
        className={isTotal ? 'RightBorder ReportTotalTextSize' : 'RightBorder'}
        sx={{
          position: 'sticky',
          left: 0,
          zIndex: 6,
          backgroundColor: '#F1F3F5',
        }}
      >
        {isTotal ? (
          < >{row.sgAbbr ?? row.subjectGroup}</ >
        ) : (
          <SimpleTooltip title={row.subjectGroup}>{row.sgAbbr ?? row.subjectGroup}</SimpleTooltip>
        )}
      </StyledTableCell>
      <CommonContentCells row={row} isTotal={isTotal} />
    </>
  );
}

export default function FYSubjectGroupTable() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { subjectData,
    // fiscalYear,
    isOA } = useSelector(state => {
    return {
      subjectData: state.Report.fyTrends.subjectGroupTableData,
      // fiscalYear: state.Report.fyTrends.fiscalYear,
      isOA: !state.Report.fyTrends.subjectGroupShowAll, // showAll 和isOA是反过来的
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};


    for(let item of monthIds){
      let monthString = intl.messages[`month.${item.id}`];
      let len = measureWidth(monthString, '18px Open Sans');
      widthMap['acq-' + item.id] = len;
      widthMap['acc-' + item.id] = len;
    }

    widthMap['subjectGroup'] = measureWidth(findLongestLineSubstring('Subject Group'), '18px Open Sans');
    widthMap['acq-total'] = measureWidth('Total','18px Open Sans');

    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (subjectData && subjectData.length > 0){
      const maxMap = {};
      for (let row of subjectData) {
        let dataObj = {...row};
        dataObj['subjectGroup'] = dataObj.sgAbbr ?? dataObj.subjectGroup;
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [subjectData]);

  function calcAllJournalWidth(){
    let sum = 0;
    for (let item of monthIds) {
      sum += Math.max((columnWidthMap[`acq${item.post}`]??0), columnMinWidthMap['acq-'+item.id]) + 50;
    }
    sum += Math.max((columnWidthMap['total']??0), columnMinWidthMap['acq-total']) + 50;
    return sum;
  }

  function calcOAJournalWidth(){
    let sum = 0;
    for (let item of monthIds) {
      sum += Math.max((columnWidthMap[`act${item.post}`]??0), columnMinWidthMap['acc-'+item.id]) + 50;
    }
    return sum;
  }

  const selectMonthDate = useSelector(state => state.Report.selectDate);
  const [fiscalYear, setSelectFY__] = useState(getFyYearFromDate(selectMonthDate));
  useEffect(() => {
    if (selectMonthDate != null) {
      const fyYear = getFyYearFromDate(selectMonthDate);
      setSelectFY__(fyYear);
    }
  }, [selectMonthDate]);

  useEffect(() => {
    if (fiscalYear !== null) {
      dispatch(getFYSubjectData(fiscalYear, isOA));
      dispatch(GetAcquiredActiveLastActionDateFY(fiscalYear, false));
    }
  }, [dispatch, fiscalYear, isOA]);

  return (
    <TableBox2
      id='BoxTable2'
      data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-FYSubjectGroupTable-Box'
      dataList={[subjectData]}
      tableKey={'Report_AcquiredAndActiveSi-FYTrends-FYSubjectGroupTable'}
      footerHeight={0}
    >
      {/*530.2px+98px是正好10行*/}
      <TableContainer
        id='reportAAASFYSGTable'
        data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-FYSubjectGroupTable-TableContainer'
        sx={{ maxHeight: '628.2px', maxWidth: '100%' }}
      >
        <Table
          stickyHeader
          data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-FYSubjectGroupTable-Table'
          sx={{
            tableLayout: 'fixed',
            width: 'max-content',
            maxWidth: 'none',
          }}
        >
          <TableHead data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-FYSubjectGroupTable-TableHead'>
            <TableRow data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-FYSubjectGroupTable-TableRow'>
              <StyledTableCellNormal
                align='left'
                isOA={isOA}
                rowSpan={2}
                className='RightBottomBorder'
                sx={{
                  // minWidth: 200,
                  width: `${Math.max((columnWidthMap['subjectGroup']??0), columnMinWidthMap['subjectGroup']) + 50 }px`,
                  position: 'sticky',
                  left: 0,
                  top: 0,
                  zIndex: 8,
                  backgroundColor: '#DFE4E8',
                }}
                data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-FYSubjectGroupTable-PurpleGreenCell1'
              >
                <FormattedMessage id='report.subjectGroup' />
              </StyledTableCellNormal>
              <PurpleGreenCell
                className='RightBottomBorder ReportHeaderFirstLine'
                align='left'
                isOA={isOA}
                colSpan={13}
                data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-FYSubjectGroupTable-PurpleGreenCell2'
                sx={{
                  width: `${calcAllJournalWidth()}px`,
                }}
              >
                <FormattedMessage id='report.fyTrendsTable.acquiredSI' />
              </PurpleGreenCell>
              <PurpleGreenCell
                className='BottomBorder ReportHeaderFirstLine'
                align='left'
                isOA={isOA}
                colSpan={12}
                data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-FYSubjectGroupTable-PurpleGreenCell3'
                sx={{
                  width: `${calcOAJournalWidth()}px`,
                }}
              >
                <FormattedMessage id='report.fyTrendsTable.activeSI' />
              </PurpleGreenCell>
            </TableRow>
            {/*表头的第二行：月份*/}
            <CommonHeaderSecondRow
              isOA={isOA}
              data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-FYSubjectGroupTable-CommonHeaderSecondRow'
              columnWidthMap={columnWidthMap}
              columnMinWidthMap={columnMinWidthMap}
            />
          </TableHead>
          <TableBody data-selenium-id='Report_AcquiredAndActiveSi-FYTrends-FYSubjectGroupTable-TableBody'>
            {subjectData != null &&
            subjectData != undefined &&
            subjectData.length != 0 ? (
              subjectData.map((item, rowIndex) => {
                if (isOA) {
                  return (
                    <TwoColorTableRowGreen
                      key={rowIndex}
                      data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-FYSubjectGroupTable-Row${rowIndex}`}
                    >
                      <SubjectRow
                        row={item}
                        isTotal={item.subjectGroup === 'Total'}
                      />
                    </TwoColorTableRowGreen>
                  );
                } else {
                  return (
                    <TwoColorTableRowPurple
                      key={rowIndex}
                      data-selenium-id={`Report_AcquiredAndActiveSi-FYTrends-FYSubjectGroupTable-Row${rowIndex}`}
                    >
                      <SubjectRow
                        row={item}
                        isTotal={item.subjectGroup === 'Total'}
                      />
                    </TwoColorTableRowPurple>
                  );
                }
              })
            ) : (
              <ReportNoData id='AcquiredAndActiveSiAnnualTrendsBDNoResultTableCell' />
            )}
            <TableRow></TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </TableBox2>
  );
}

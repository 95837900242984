import styles from './index.module.scss';
import {Box, IconButton, Stack} from "@mui/material";
import {InputBox} from "@/pages/SIPForm/components/InputBox";
import DeleteIcon from '../../assets/input-list-delete-icon-24_24.svg';
import {FormGroupButton, FormGroupInfo} from "@/pages/SIPForm/components/FormGroup";

export interface InputBoxProps {
    value: string[];
    onChange: (value: string[]) => void;
    placeholder?: string;
    error?: boolean;
    minRows?: number;
    maxRows?: number;
    info?: string;
    addButtonText?: string;
}

export function InputBoxList(props: InputBoxProps) {
    const {
        value,
        onChange,
        placeholder,
        error,
        minRows,
        maxRows,
        info,
        addButtonText
    } = props;

    const deleteAble = minRows === undefined || value.length > minRows;

    return (
        <Box className={styles['input-box-list']}>
            {value.map((v, i) => (
                <Box key={i} className={styles['input-box-list-item']}>
                    <InputBox
                        value={v}
                        onChange={(nv) => {
                            const newValue = [...value];
                            newValue[i] = nv;
                            onChange(newValue);
                        }}
                        placeholder={placeholder}
                        error={error && v.length === 0}
                    />
                    { deleteAble && (
                    <IconButton
                        className={styles['remove-button']}
                        onClick={() => {
                            const newValue = [...value];
                            newValue.splice(i, 1);
                            onChange(newValue);
                        }
                        }>
                        <DeleteIcon />
                    </IconButton>
                    )}
                </Box>
            ))}

            {info && <FormGroupInfo info={info} /> }
            <FormGroupButton
                onClick={() => onChange([...value, ''])}
                title={addButtonText ?? 'Add'}
                disabled={ maxRows !== undefined && (value.length >= maxRows)}
            />
        </Box>
    );
}
import * as actions from '../actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import {
  REQUEST_CHART_DATA,
  REQUEST_CHART_DATA_SUCCESS,
  REQUEST_CHART_DATA_FAIL,
} from '../actions/SIMT-Report/ReportDiagram/ReportDiagramAction';

const initState = {
  loading: false,
  error: null,
  siPipelineCATGroupChartData: [],
  siPipelineCEChartData: [],
  selectedTableView: true,
  // data: {}
};

export default function ReportDiagramReducer(state = initState, action) {
  const { type, data } = action;

  switch (type) {
    case REQUEST_CHART_DATA: {
      // console.log('===(reducer) REQUEST_CHART_DATA===');
      return {
        ...state,
        [data]: {
          loading: true,
          error: null,
          data: null,
        },
      };
    }
    case REQUEST_CHART_DATA_SUCCESS: {
      // console.log('===(reducer) REQUEST_CHART_DATA_SUCCESS===');
      const { title, value } = data;
      return {
        ...state,
        [title]: {
          loading: false,
          error: null,
          data: value,
        },
      };
    }
    case REQUEST_CHART_DATA_FAIL: {
      // console.log('===(reducer) REQUEST_CHART_DATA_FAIL===');
      const { title, error } = data;
      return {
        ...state,
        [title]: {
          loading: false,
          error,
          data: null,
        },
      };
    }
    case actions.GET_SI_PIPELINE_CAT_GROUP_CHARTDATA: {
      // if error !== null ??
      return {
        ...state,
        loading: true,
        error: null,
        siPipelineCATGroupChartData: [],
      };
    }
    case actions.GET_SI_PIPELINE_CAT_GROUP_CHARTDATA_SUCCESS: {
      // console.log("==============state")
      return {
        ...state,
        loading: false,
        siPipelineCATGroupChartData: data,
      };
    }
    case actions.GET_SI_PIPELINE_CAT_GROUP_CHARTDATA_FAIL: {
      return {
        ...state,
        loading: false,
        error: data,
      };
    }
    case actions.GET_A_AND_A_YTD_AGAINST_CHART_DATA: {
      return {
        ...state,
        ...data,
      };
    }
    case actions.GET_CHART_DATA: {
      return {
        ...state,
        ...data,
      };
    }
    case actions.GET_SI_PIPELINE_CE_CHARTDATA: {
      return {
        ...state,
        ...data,
      };
    }
    case actions.GET_A_AND_A_OVERVIEW_EXCEL: {
      return {
        ...state,
        AAndAOverviewExcel: data,
      };
    }
    case actions.GET_A_AND_A_ANNUAL_TRENDS: {
      return {
        ...state,
        AAndAAnnualTrendsExcel: data,
      };
    }
    case actions.GET_A_AND_A_OA_VS_TOTAL_EXCEL: {
      return {
        ...state,
        AAndAOAvsTotalExcel: data,
      };
    }
    case actions.GET_A_AND_A_OA_VS_TOTAL_CHART_DATA: {
      return {
        ...state,
        ...data,
      };
    }
    case actions.GET_A_AND_A_FY_TRENDS_CHART_DATA: {
      return {
        ...state,
        ...data,
      };
    }
    case actions.GET_A_AND_A_FY_TRENDS_SINGLE_SELECT_DATA: {
      return {
        ...state,
        ...data,
      };
    }
    case actions.GET_A_AND_A_FY_TRENDS_EXCEL: {
      return {
        ...state,
        ...data,
      };
    }
    case actions.GET_USER_SELECTED_SG: {
      return {
        ...state,
        ...data,
      };
    }
    case actions.SET_HOVER_CHART_TITLE: {
      return {
        ...state,
        ...data,
      };
    }
    case actions.GET_ARTICLE_SUBJECT_GROUPS: {
      return {
        ...state,
        ...data,
      };
    }
    case actions.GET_ARTICLES_OA_VS_TOTAL_EXCEL: {
      return {
        ...state,
        ArticlesOAvsTotalExcel: data,
      };
    }
    case actions.GET_ARTICLES_OA_VS_TOTAL_CHART_DATA: {
      return {
        ...state,
        ...data,
      };
    }
    case actions.SHOW_TABLE: {
      return {
        ...state,
        selectedTableView: data,
      };
    }
    default:
      return state;
  }
}

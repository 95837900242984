import { string } from 'prop-types';
import { axios_instance } from '../utils/axios_instance';
import { timeToString } from '../utils/date_string';

// create Redux action types
export const GET_SYSTEM_LOG = 'GET_SYSTEM_LOG';
export const LOG_CLEAR = 'LOG_CLEAR';
export const GET_FLITER_TYPE = 'GET_FLITER_TYPE';
export const GET_FILTER_SUBJECT = 'GET_FILTER_SUBJECT';
export const GET_FILTER_UPDATEBY = 'GET_FILTER_UPDATEBY';
export const GET_FILTER_FIELD = 'GET_FILTER_FIELD';

// Create Redux action creators that return an action
export const clear = () => ({
  type: LOG_CLEAR,
});

//获取type类型
export function getFliterType() {
  const LOG_TYPES = [
      "User",
      "Role",
      "GE",
      "SI",
      "System configuration",
      "SIP",
      "SIP GE",
      "Solicited SIP"
    ];
  return dispatch => dispatch({ type: GET_FLITER_TYPE, data: LOG_TYPES });
}

function getServicePrefixByTypes(typeList) {
  for (let item of typeList) {
    switch (item) {
      case "User":
      case "Role":
      case "System configuration":
        return "/api/v1/user-service/log";
      case "GE":
      case "SI":
        return "/api/v1/si-service/log";
      case "SIP":
      case "SIP GE":
      case "Solicited SIP":
        return "/api/v1/sip-service/log";
    }
  }
  return "/sysLog";
}

export function getFliterSubject(typeList) {
  var typeStr = '';
  for (let item of typeList) {
    typeStr = typeStr + item + ',';
  }
  if (typeStr.length > 0) {
    typeStr = typeStr.substring(0, typeStr.length - 1);
  }
  const url = `${getServicePrefixByTypes(typeList)}/getSubject`;
  return async dispatch => {
    try {
      const res = await axios_instance.get(url, {
        params: {
          types: typeStr,
        },
        headers: {
          authorization: localStorage.getItem('authorization'),
        },
      });
      if (res.data.code === 200) {
        dispatch({ type: GET_FILTER_SUBJECT, data: res.data.data });
        return res.data.data;
      } else {
        dispatch({ type: GET_FILTER_SUBJECT, data: [] });
        return [];
      }
    } catch (e) {
      dispatch({ type: GET_FILTER_SUBJECT, data: [] });
      return [];
    }
  };
}

export function getFliterUpdateBy(typeList) {
  var typeStr = '';
  for (let item of typeList) {
    typeStr = typeStr + item + ',';
  }
  if (typeStr.length > 0) {
    typeStr = typeStr.substring(0, typeStr.length - 1);
  }
  const url = `${getServicePrefixByTypes(typeList)}/getEditUser`;
  return async dispatch => {
    try {
      const res = await axios_instance.get(url, {
        params: {
          types: typeStr,
        },
        headers: {
          authorization: localStorage.getItem('authorization'),
        },
      });
      if (res.data.code === 200) {
        dispatch({ type: GET_FILTER_UPDATEBY, data: res.data.data });
        return res.data.data;
      } else {
        dispatch({ type: GET_FILTER_UPDATEBY, data: [] });
        return [];
      }
    } catch (e) {
      dispatch({ type: GET_FILTER_UPDATEBY, data: [] });
      return [];
    }
  };
}

export function getFliterField(typeList, subjectList) {
  var typeStr = '';
  var subjectStr = '';
  for (let item of typeList) {
    typeStr = typeStr + item + ',';
  }
  if (typeStr.length > 0) {
    typeStr = typeStr.substring(0, typeStr.length - 1);
  }
  for (let item of subjectList) {
    subjectStr = subjectStr + item + ',';
  }
  if (subjectStr.length > 0) {
    subjectStr = subjectStr.substring(0, subjectStr.length - 1);
  }
  const url = `${getServicePrefixByTypes(typeList)}/getFields`;
  return async dispatch => {
    try {
      const res = await axios_instance.get(url, {
        params: {
          types: typeStr,
          subjects: subjectStr,
        },
        headers: {
          authorization: localStorage.getItem('authorization'),
        },
      });
      if (res.data.code === 200) {
        dispatch({ type: GET_FILTER_FIELD, data: res.data.data });
        return res.data.data;
      } else {
        dispatch({ type: GET_FILTER_FIELD, data: [] });
        return [];
      }
    } catch (e) {
      dispatch({ type: GET_FILTER_FIELD, data: [] });
      return [];
    }
  };
}

export function getSystemLog(
  pageNum,
  pageSize,
  orderField,
  orderType,
  typeList,
  subjectList,
  updatedByList,
  fieldList,
  fromDate,
  toDate
) {
  var orderBy = '';
  switch (orderField) {
    case 'editUser':
      orderBy = 'UpdatedBy';
      break;
    case 'editTime':
      orderBy = 'UpdatedTime';
      break;
    case 'subject':
      orderBy = 'Subject';
      break;
    default:
      orderBy = 'UpdatedTime';
  }
  var time1 = timeToString(fromDate, 'from');
  var time2 = timeToString(toDate, 'to');
  const url = `${getServicePrefixByTypes(typeList)}/viewLogs?pageNum=${pageNum}&pageSize=${pageSize}&orderField=${orderBy}&orderType=${orderType}`;
  return async dispatch => {
    try {
      const res = await axios_instance.post(
        url,
        {
          type: typeList,
          subject: subjectList,
          updatedBy: updatedByList,
          fields: fieldList,
          fromDate: time1,
          toDate: time2,
        },
        {
          headers: {
            authorization: localStorage.getItem('authorization'),
          },
        }
      );
      if (res.data.code === 200) {
        dispatch({ type: GET_SYSTEM_LOG, data: res.data.data });
      } else {
        dispatch({ type: GET_SYSTEM_LOG, data: { result: [], count: 0 } });
      }
    } catch (e) {
      dispatch({ type: GET_SYSTEM_LOG, data: { result: [], count: 0 } });
    }
  };
}

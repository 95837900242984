import { useMemo, useCallback } from 'react';
import { MultipleSelect } from '@/components/Chart/ChartSelectors';

export const StageSelector = ({
  label = 'Stage',
  options,
  selectedLegendObject,
  setSelectedLegendObject,
  disabled,
}) => {
  // convert object to array
  const selectedItems = useMemo(() => {
    return Object.keys(selectedLegendObject)
      .map(legendName =>
        selectedLegendObject[legendName] === true ? legendName : null
      )
      .filter(legendName => legendName !== null);
  }, [selectedLegendObject]);

  const setSelectedItems = useCallback(newSelectedArray => {
    /** user can make 0 selection */
    const newSelectedLegend = { ...selectedLegendObject };
    Object.keys(newSelectedLegend).forEach(legend => {
      const selected = newSelectedArray.indexOf(legend) >= 0;
      newSelectedLegend[legend] = selected;
    });
    setSelectedLegendObject(newSelectedLegend);
  }, []);

  return (
    <MultipleSelect
      disabled={disabled}
      label={label}
      options={options}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      hasSelectAllOption={true}
    />
  );
};

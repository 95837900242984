import {
  Box,
  Checkbox,
  Popover,
  Stack,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleRequestHistoryStatus,
  handleRequestHistoryType,
} from '../../../../actions/SIMT-SI/CatJournal/CATJournalAction';

import '../../../../App.css';
import { useState } from 'react';
import OptRequestHistoryDownIcon from '@/assets/OptRequestHistoryDownIcon.svg';
import CheckboxIcon from '@/assets/CatOverviewUnchecked.svg';
import CheckboxCheckedIcon from '@/assets/CatOverviewChecked.svg';
import { MiniBoldFont } from '../../../../components/FontStyle';

const PopoverContent = props => {
  const {
    dropDownList = [],
    selectedList = [],
    handleChecked = () => {},
  } = props;
  return (
    <Box
      sx={{
        width: '281px',
        padding: '3px 24px 3px 11px',
        boxSizing: 'border-box',
       
      }}
      data-selenium-id='CATJournal_Overview-RequestHistoryTable-Header-Box'
    >
      {dropDownList.map((item, index) => (
        <Stack
          direction='row'
          spacing={1.5}
          key={item}
          sx={{ padding: '2px 0px' }}
        >
          <Checkbox
            id={`${item}-checkbox`}
            data-selenium-id={`${item}-checkbox`}
            icon={<CheckboxIcon></CheckboxIcon>}
            checkedIcon={<CheckboxCheckedIcon></CheckboxCheckedIcon>}
            checked={selectedList.indexOf(item) !== -1}
            onChange={() => {
              handleChecked(item);
            }}
            sx={{ padding: '0px' , width:'16px', height:'16px', marginTop:'2px'}}
          />
          <Box
            sx={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '20px',
              color: '#484848',
            }}
          >
            {item}
          </Box>
        </Stack>
      ))}
    </Box>
  );
};

// 改变标题行的颜色
const NewStyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F1F3F5',
    color: '#596A7C',
    ...MiniBoldFont,
    textTransform: 'uppercase',
    letterSpacing: '0.08em',
    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '18px',
    padding: '12px 24px',
  },
}));

export default function TableHeader(props) {
  //order:排序顺序，asc、desc、none
  //orderBy：根据某一字段排序
  //items：表头元素
  //hasCheckbox:表头是否有复选框
  //isAllCheck：是否全选
  //onSelectAllClick：全选后的逻辑处理
  //CheckboxColor：CheckBox的颜色，默认是MUI的Primary
  const { order = 'none', orderBy, items, onRequestSort = () => {} } = props;

  const dispatch = useDispatch();
  const selector = state => {
    return {
      requestHistoryTypeSelectedList:
        state.CATJournal.requestHistoryTypeSelectedList,
      requestHistoryStatusSelectedList:
        state.CATJournal.requestHistoryStatusSelectedList,
    };
  };
  const { requestHistoryTypeSelectedList, requestHistoryStatusSelectedList } =
    useSelector(selector);

  const [requestTypeOpen, setRequestTypeOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [requestTypeAnchorEl, setRequestTypeAnchorEl] = useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead data-selenium-id='CATJournal_Overview-RequestHistoryTable-Header'>
        <TableRow>
          {items.map((item, index) => (
            <NewStyledTableCell
              key={item.id}
              align='left'
              sx={{
                maxWidth: item.maxWidth,
                minWidth: item.minWidth,
              }}
              sortDirection={orderBy === item.id ? order : false}
              style={{
                position: item.stick ? 'sticky' : '',
                left: item.stick ? item.stickPX : '',
                zIndex: item.stick ? 7 : '',
              }}
            >
              {!item.isOrder ? (
                <>
                  <Box
                    id={`RequestHistotyTableHeaderItem-${index}`}
                    data-selenium-id={`RequestHistotyTableHeaderItem-${index}`}
                    onClick={e => {
                      if (item.id === 'requestType') {
                        setRequestTypeOpen(true);
                        setRequestTypeAnchorEl(e.currentTarget);
                      } else if (item.id === 'status') {
                        setStatusOpen(true);
                        setStatusAnchorEl(e.currentTarget);
                      }
                    }}
                    sx={{
                      cursor:
                        item.id === 'requestType' || item.id === 'status'
                          ? 'pointer'
                          : 'default',
                    }}
                  >
                    <Stack direction='row' spacing={1.25}>
                      <Box sx={{width: '100%'}}>
                        <Box 
                          sx={{
                            display:'inline-block',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',}}
                        >
                          {item.label}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display:
                            item.id === 'requestType' || item.id === 'status'
                              ? 'block'
                              : 'none',
                          transform:
                            (item.id === 'requestType' && requestTypeOpen) ||
                            (item.id === 'status' && statusOpen)
                              ? 'rotate(180deg)'
                              : 'none',
                          width: '16px',
                          height: '16px',
                        }}
                      >
                        <OptRequestHistoryDownIcon></OptRequestHistoryDownIcon>
                      </Box>
                    </Stack>
                  </Box>
                </>
              ) : (
                <TableSortLabel
                  id={`RequestHistotyTableHeaderItem-${index}`}
                  data-selenium-id={`RequestHistotyTableHeaderItem-${index}`}
                  active={orderBy === item.id}
                  direction={orderBy === item.id ? order : 'asc'}
                  onClick={item.isOrder ? createSortHandler(item.id) : null}
                  sx={{
                    '&.Mui-active': {
                      color: '#596A7C',
                    },
                  }}
                >
                  {item.label}
                  {orderBy === item.id ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
            </NewStyledTableCell>
          ))}
        </TableRow>
      </TableHead>
      <Popover
        open={Boolean(requestTypeAnchorEl)}
        anchorEl={requestTypeAnchorEl}
        onClose={() => {
          setRequestTypeOpen(false);
          setRequestTypeAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ 
          marginLeft: '-24px', 
          marginTop: '18px',    
        }}
        slotProps={{
          paper: {
            style: {
              boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
            },
          },
        }}
        data-selenium-id='CATJournal_Overview-RequestHistoryTable-Header-Popover1'
      >
        <PopoverContent
          dropDownList={['Opt-in', 'Opt-out']}
          selectedList={requestHistoryTypeSelectedList}
          handleChecked={type => {
            dispatch(handleRequestHistoryType(type));
          }}
        ></PopoverContent>
      </Popover>
      {/* status popover */}
      <Popover
        open={Boolean(statusAnchorEl)}
        anchorEl={statusAnchorEl}
        onClose={() => {
          setStatusOpen(false);
          setStatusAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            style: {
              boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
            },
          },
        }}
        sx={{ marginLeft: '-24px', marginTop: '18px'}}
        data-selenium-id='CATJournal_Overview-RequestHistoryTable-Header-Popover2'
      >
        <PopoverContent
          dropDownList={['Approved', 'Rejected', 'Withdrawn']}
          selectedList={requestHistoryStatusSelectedList}
          handleChecked={status => {
            dispatch(handleRequestHistoryStatus(status));
          }}
          data-selenium-id='CATJournal_Overview-RequestHistoryTable-Header-Popover3'
        ></PopoverContent>
      </Popover>
    </>
  );
}

import React from 'react';
import SimpleIconButton from '../SimpleIconButton';
import IconActive from '../icon/ContainerEditIconActive.svg';
import IconDefault from '../icon/ContainerEditIconDefault.svg';
import IconDisable from '../icon//ContainerEditIconDisable.svg';
export default function EditContainerButton(props) {
  return (
    <SimpleIconButton
      DefaultIcon={IconDefault}
      HoverIcon={IconActive}
      ActiveIcon={IconActive}
      DisabledIcon={IconDisable}
      {...props}
    ></SimpleIconButton>
  );
};

import {axios_instance} from "@/utils/axios_instance";
import {
    GET_TARGETS_BD,
    GET_TARGETS_CECAT,
    GET_TARGETS_JOURNAL,
    GET_TARGETS_SG
} from "@/actions/SIMT-Report/Report/ReportAction";
import getAuth from "@/utils/getAuth";

/**
 * @author LiuXin
 * @date 2022/5/13
 * @description 用于获取Targets页面的journal表信息
 * @param {*} fiscalYear 哪一年的数据
 * @param {*} pageNum 当前是第几页
 * @param {*} pageSize 一页多少条数据
 * @returns
 */
export function getTargetsJournal(fiscalYear, pageNum, pageSize, orderAs) {
    let order = orderAs === 'asc' ? 1 : 0;
    return async dispatch => {
        try {
            const url = 'reports/target/journal';
            const res = await axios_instance.get(url, {
                params: {
                    fiscalYear: fiscalYear,
                    pageNum: pageNum,
                    limit: pageSize,
                    order: order,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: GET_TARGETS_JOURNAL, data: res.data.data});
            } else {
                dispatch({type: GET_TARGETS_JOURNAL, data: []});
            }
        } catch (e) {
            dispatch({type: GET_TARGETS_JOURNAL, data: []});
        }
    };
}

/**
 * @author LiuXin
 * @date 2022/5/13
 * @description 获取Targets页面Subject Group的展示信息
 * @param {string} fiscalYear 获取哪一年的Subject Group
 * @returns
 */
export function getTargetsSG(fiscalYear) {
    return async dispatch => {
        try {
            const url = 'reports/target/sg';
            const res = await axios_instance.get(url, {
                params: {
                    fiscalYear: fiscalYear,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: GET_TARGETS_SG, data: res.data.data});
            } else {
                dispatch({type: GET_TARGETS_SG, data: []});
            }
        } catch (e) {
            dispatch({type: GET_TARGETS_SG, data: []});
        }
    };
}

/**
 * @author LiuXin
 * @date 2022/5/13
 * @description 获取Targets页面Business Division的展示信息
 * @param {string} fiscalYear 获取哪一年的Business Division
 * @returns
 */
export function getTargetsBD(fiscalYear) {
    return async dispatch => {
        try {
            const url = 'reports/target/bd';
            const res = await axios_instance.get(url, {
                params: {
                    fiscalYear: fiscalYear,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: GET_TARGETS_BD, data: res.data.data});
            } else {
                dispatch({type: GET_TARGETS_BD, data: []});
            }
        } catch (e) {
            dispatch({type: GET_TARGETS_BD, data: []});
        }
    };
}

/**
 * @author LiuXin
 * @date 2022/5/13
 * @description 获取Targets页面CECAT的展示信息
 * @param {string} fiscalYear 获取哪一年的CECAT
 * @returns
 */
export function getTargetsCECAT(fiscalYear) {
    return async dispatch => {
        try {
            const url = 'reports/target/cg';
            const res = await axios_instance.get(url, {
                params: {
                    fiscalYear: fiscalYear,
                },
                headers: {
                    authorization: getAuth(),
                },
            });

            if (res.data.code === 200) {
                dispatch({type: GET_TARGETS_CECAT, data: res.data.data});
            } else {
                dispatch({type: GET_TARGETS_CECAT, data: []});
            }
        } catch (e) {
            dispatch({type: GET_TARGETS_CECAT, data: []});
        }
    };
}
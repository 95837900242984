import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export default styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className || '' }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#596A7C',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#596A7C',
    borderRadius: '5px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '8px 16px',
  },
}));

const ThinTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className || '' }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#596A7C',
    width: '6px',
    height: '3px',
    top: '28px',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#596A7C',
    borderRadius: '5px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '4px 8px',
    boxSizing: 'border-box',
    marginBottom: '-4px !important',
    maxWidth: "none"
  },
  '&  .MuiTooltip-tooltip': {
    marginBottom: '0px !important'
  }
}));

 const CustomTooltipWithoutArrow = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#596A7C',
    borderRadius: '5px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '8px 16px',
  },
}));


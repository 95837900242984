import {
  LOADING,
  ERROR,
  VALID,
  NO_SELECTION_FROM_DROPDOWN_MENU,
  NO_DATA,
  NO_DISPLAYED_DATA,
} from '@/pages/Report/chartUtils/constants.js';

export default function useGetDiagramStatusHook(
  itemList,
  chartData,
  alertLabel,
  loadingMenu,
  errorMenu,
  loading,
  error
) {
  // console.log('==itemList==', itemList);
  // console.log('==chartData==', chartData);

  let valid = VALID;
  let info = null;

  if (loadingMenu || loading) {
    valid = LOADING;
  } else if (errorMenu || error) {
    valid = ERROR;
  } else if (itemList === null || itemList?.length === 0) {
    valid = NO_SELECTION_FROM_DROPDOWN_MENU;
    info = alertLabel;
  } else if (!(Array.isArray(chartData) && chartData.length > 1)) {
    valid = NO_DATA;
    info = alertLabel;
  }

  return {
    valid,
    info,
  };
}

import {axios_instance} from "@/utils/axios_instance";
import {GET_REPORT_BASIC, TABLE2_DATA, TABLE3_DATA, TABLE4_DATA} from "@/actions/SIMT-Report/Report/ReportAction";
import getAuth from "@/utils/getAuth";

export function getReportBasicTableData(
    page,
    pageSize,
    order,
    orderBy,
    selectDate,
    filterMap = {}
) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];

    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);

    return async dispatch => {
        try {
            let res = await axios_instance.post(
                '/reports/pipeline/basic',
                filterMap,
                {
                    params: {
                        pageNo: page,
                        limit: pageSize,
                        sortMethod: order,
                        sortKey: orderBy,
                        selectedDate: date,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );

            if (res.data.code === 200) {
                let returnData;
                if (res.data.data && res.data.data.result) {
                    returnData = res.data.data;
                } else {
                    returnData = {result: [], count: 0};
                }
                dispatch({type: GET_REPORT_BASIC, data: returnData});
            } else {
                dispatch({
                    type: GET_REPORT_BASIC,
                    data: {result: [], count: 0},
                });
            }
        } catch (err) {
            dispatch({
                type: GET_REPORT_BASIC,
                data: {result: [], count: 0},
            });
        }
    };
}

export function GetTable2Data(selectDate) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get('/reports/pipeline/sg', {
                params: {
                    selectedDate: date,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: TABLE2_DATA, data: res.data.data});
            } else {
                dispatch({type: TABLE2_DATA, data: []});
            }
        } catch (err) {
            dispatch({type: TABLE2_DATA, data: []});
        }
    };
} //table3获取数据
export function GetTable3Data(selectDate) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get('/reports/pipeline/bd', {
                params: {
                    selectedDate: date,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: TABLE3_DATA, data: res.data.data});
            } else {
                dispatch({type: TABLE3_DATA, data: []});
            }
        } catch (err) {
            dispatch({type: TABLE3_DATA, data: []});
        }
    };
} //table4获取数据
export function GetTable4Data(selectDate) {
    var selectDateYear = selectDate.split('-')[0];
    var selectDateMonth = selectDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            const res = await axios_instance.get('/reports/pipeline/ce', {
                params: {
                    selectedDate: date,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: TABLE4_DATA, data: res.data.data});
            } else {
                dispatch({type: TABLE4_DATA, data: []});
            }
        } catch (err) {
            dispatch({type: TABLE4_DATA, data: []});
        }
    };
}
/*
 * @Author: LiuJingQian 1250366059@qq.com
 * @Date: 2024-03-07 16:10:08
 * @LastEditors: LiuJingQian 1250366059@qq.com
 * @LastEditTime: 2024-03-08 14:35:00
 * @FilePath: \simt_front-1\src\actions\SIMT-SIP\SIP\SipProcessLog.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {axios_instance} from "@/utils/axios_instance";
import {formatDateString} from "@/utils/commonUtils";
import stageMapping from "@/pages/SIPPage/components/sip-step/backend-mapping";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {LOAD_SIP_PROCESS} from "@/actions/SIMT-SIP/SIP/SIPAction";

/**
 * 加载SIP Process log的action
 * @param sipCode
 * @return {(function(*): Promise<void>)|*}
 */
export function loadSIPProcessLog(sipCode) {
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/process-logs`;
            const res = await axios_instance.get(url, {
                params: {
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                // 构造符合前端设计的数据格式
                const processLogData = [];
                const sortedData = res.data.data; // 这里将原先的排序删除了，所以有这一行无用的赋值代码
                for (const obj of sortedData) {
                    const detail = JSON.parse(obj.detail);
                    if (detail?.hasOwnProperty('Revision Deadline')) {
                        // 这是个日期格式的东西
                        detail['Revision Deadline'] = formatDateString(
                            detail['Revision Deadline']
                        );
                    }
                    const comments = [];
                    // 遍历comments
                    let hasComments = false; // 标记这个detail中是否含有Comments，主动把它挪到最后
                    for (const detailKey in detail ?? []) {
                        // 如果detail是null，为避免出错，所以使用这个方案
                        if (detailKey === 'Comments') {
                            hasComments = true;
                        } else {
                            comments.push({
                                name: detailKey,
                                value: detail[detailKey],
                            });
                        }
                    }
                    if (hasComments) {
                        // 当有comments的时候，额外添加这个key-value，它就到了最后了
                        comments.push({
                            name: 'Comments',
                            value: detail['Comments'],
                        });
                    }
                    let realStage; // 存在一个特殊的首个stage，用action term去判断
                    if (obj.actionCode === 'Submit the Proposal') {
                        realStage = 'start';
                    } else {
                        realStage = stageMapping(obj.stageCode);
                    }
                    // 这里通过id去判断是否为当前状态：如果id为null，说明这个step是当前的status
                    // 如果不是null，则是正常的step
                    // 这样其他地方改动较少
                    processLogData.push({
                        id: 'step' + (obj.id ?? '-current'),
                        step: realStage,
                        origin: stageMapping(obj.origin),
                        title: obj.actionCode,
                        date: obj.date,
                        user: obj.user,
                        comments: comments,
                        detail: obj.detail,
                    });
                }
                dispatch({type: LOAD_SIP_PROCESS, data: processLogData});
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.steps.loadError',
                        {},
                        SEVERITIES.error
                    )
                );
                dispatch({type: LOAD_SIP_PROCESS, data: null});
            }
        } catch (e) {
            // console.log(e);
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.steps.loadError',
                    {},
                    SEVERITIES.error
                )
            );
            dispatch({type: LOAD_SIP_PROCESS, data: null});
        }
    };
}
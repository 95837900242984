import { Box, Drawer, Stack, styled, Tab, Tabs, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useNavigate,
  useSearchParams,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';
import './index.css';
import { emptyFileError } from '../../actions/SIMT-Report/Target/AnnualTargetAction';
import { switchTarget } from '../../actions/SIMT-Report/Report/ReportAction';
import { styled as sysStyled } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import SIPIP from '../../assets/SideMenuIcon/modifyIcon/1SIPipeline.svg';
import SIPIPELINE from '../../assets/SideMenuIcon/modifyIcon/2SIPIPELINE.svg';
import JOURNAL from '../../assets/SideMenuIcon/modifyIcon/1Journal.svg';
import Acquire from '../../assets/SideMenuIcon/modifyIcon/1Acquire.svg';
import Annual from '../../assets/SideMenuIcon/modifyIcon/1Annual.svg';
import ARTICLES from '../../assets/SideMenuIcon/modifyIcon/1Articles.svg';
import CAT from '../../assets/SideMenuIcon/modifyIcon/1CAT.svg';
import CR from '../../assets/SideMenuIcon/modifyIcon/1CR.svg';
import Email from '../../assets/SideMenuIcon/modifyIcon/1Email.svg';
import Export from '../../assets/SideMenuIcon/modifyIcon/1Export.svg';
import GuestEdi from '../../assets/SideMenuIcon/modifyIcon/1GuestEdi.svg';
import OVERVIEW from '../../assets/SideMenuIcon/modifyIcon/1Overview.svg';
import Report from '../../assets/SideMenuIcon/modifyIcon/1Report.svg';
import ROLE from '../../assets/SideMenuIcon/modifyIcon/1Role.svg';
import SIOverview from '../../assets/SideMenuIcon/modifyIcon/1SIOverview.svg';
import sip from '../../assets/SideMenuIcon/modifyIcon/1SIP.svg';
import SIPipe from '../../assets/SideMenuIcon/modifyIcon/1SIPipe.svg';
import System from '../../assets/SideMenuIcon/modifyIcon/1System.svg';
import SystemConfig from '../../assets/SideMenuIcon/modifyIcon/1SystemConfig.svg';
import User from '../../assets/SideMenuIcon/modifyIcon/1User.svg';
import TARGETS from '../../assets/SideMenuIcon/modifyIcon/1Targets.svg';
import Overview from '../../assets/SideMenuIcon/defaultIcon/3overview.svg';
import GuestActive from '../../assets/SideMenuIcon/GuestActive.svg';
import Role from '../../assets/SideMenuIcon/defaultIcon/3Role.svg';
import Guest from '../../assets/SideMenuIcon/defaultIcon/3guest.svg';
import Sipipe from '../../assets/SideMenuIcon/sipipe.svg';
import Cat from '../../assets/SideMenuIcon/defaultIcon/3cat.svg';
import Punctuation from '../../assets/SideMenuIcon/defaultIcon/3acquire.svg';
import AcquiredAndActiveSI from '../../assets/SideMenuIcon/AcquiredAndActiveSI.svg';
import AnnualTarget from '../../assets/SideMenuIcon/defaultIcon/3annual.svg';
import EmailTemplates from '../../assets/SideMenuIcon/defaultIcon/3email.svg';
import Email2 from '../../assets/SideMenuIcon/defaultIcon/email-solid.svg';
import EmailTemplates2 from '../../assets/SideMenuIcon/defaultIcon/email-default.svg';
import GuestEditor from '../../assets/SideMenuIcon/Union.svg';
import SIPOverview from '../../assets/SideMenuIcon/defaultIcon/3SIPoverview.svg';
import ReportJobs from '../../assets/SideMenuIcon/defaultIcon/3report.svg';
import RoleManagement from '../../assets/SideMenuIcon/modifyIcon/1Role.svg';
import SystemLog from '../../assets/SideMenuIcon/defaultIcon/3System.svg';
import UserManagement from '../../assets/SideMenuIcon/defaultIcon/User.svg';
import SystemConfiguration from '../../assets/SideMenuIcon/defaultIcon/3systemconfig.svg';
import Articles from '../../assets/SideMenuIcon/defaultIcon/3articles.svg';
import CATJournal from '../../assets/SideMenuIcon/modifyIcon/1CAT.svg';
import CRContact from '../../assets/SideMenuIcon/defaultIcon/3cr.svg';
import Journal from '../../assets/SideMenuIcon/defaultIcon/3journal.svg';
import Targets from '../../assets/SideMenuIcon/defaultIcon/3targets.svg';
import SIP from '../../assets/SideMenuIcon/SIPReportDisable.svg';
import SIPReport from '../../assets/SideMenuIcon/SIP report.svg';
import SIPReportActive from '../../assets/SideMenuIcon/SIP report- solid.svg';
import File from '../../assets/SideMenuIcon/defaultIcon/3export.svg';
import ExportMarketingDataIcon from '../../assets/SideMenuIcon/ExportMarketingDataIcon.svg';
import { setNavigationState } from '../../actions/SideBarAction';
import { setNotificationDrawerOpen } from '../../actions/SIMT-User/Alert/AlertAction';
import SIPReportIcon from '../../assets/SideMenuIcon/SIPReportIcon.svg';
import { SmallFont } from '../FontStyle';
import ItemFolder from './ItemFolder';
import MenuFolderButton from './CollapsedButton/MenuFolderButton';
import { useLocation } from 'react-router-dom';
import SIPipeline from '@/pages/Report/SIPipeline';
import { getFyYearFromDate } from '../../utils/date_string';
import styleModule from './CommonSider.module.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import { TableBox2 } from '../../pages/Report/ReportSpecificComponents/SpecialColorTableHeader';
import {PERMISSIONS} from "@/constant/permission";
/**
 * @type {HTMLElement | null}
 */
let Menu;
let ActiveItemFolderID = '';
let documentScrollY = 0;
let isHandling = false;
function addListener() {
  if (!document.getElementById('CommonSiderScoll')) {

    return;
  }
  Menu = document.getElementById('CommonSiderScoll');
  try {
    document.removeEventListener('scroll', handelDocumentScroll);
  } catch (e) {}
  document.addEventListener('scroll', handelDocumentScroll);
}
function handelDocumentScroll() {
  if (!isHandling && ActiveItemFolderID !== '') {
    isHandling = true;
    setTimeout(() => {
      isHandling = false;
    }, 3);
    let documentScrollChange = window.scrollY - documentScrollY;
    documentScrollY = window.scrollY;
    if (Menu == null || Menu.scrollTop == null) {
      return;
    }
    let ActiveItemFolder = document.getElementById(ActiveItemFolderID);
    if (documentScrollChange > 0) {
      //往下滚
      if (ActiveItemFolder.offsetTop > Menu.scrollTop) {
        Menu.scrollTop += documentScrollChange;
      }
    } else {
      //往上滚
      if (
        Menu.offsetHeight +
          Menu.scrollTop -
          ActiveItemFolder.offsetTop -
          ActiveItemFolder.offsetHeight >
        3
      ) {
        Menu.scrollTop += documentScrollChange;
      }
    }
  }
}

/**
 * @param {string} eleID
 */
function changeActiveItem(eleID) {
  // console.log('eleID = ', eleID);
  if (!document.getElementById(eleID)) {

    return;
  }
  // if (ActiveItemFolderID === eleID + 'RootStack')
  //   return;
  ActiveItemFolderID = eleID + 'RootStack';
  Menu = document.getElementById('CommonSiderScoll');
  Menu.scrollTop = document.getElementById(eleID + 'RootStack').offsetTop - 5;
}

// <<<<<<< HEAD
// =======
const CUSTOMIZE_EMAIL_INDEX = 30;

const StyledTab = styled(props => <Tab disableRipple {...props} />)(
  ({ _theme, index, isExtend }) => {
    let hoverBgColor = '';
    let selectedBgColor = '';

    if (index >= 0 && index <= 7) {
      hoverBgColor = '#6d98ee';
      selectedBgColor = '#0052cc';
    } else if (index >= 8 && index <= 9) {
      hoverBgColor = '#a2dde4';
      selectedBgColor = '#00a4b7';
    } else if (index >= 10 && index <= 11) {
      hoverBgColor = '#ffdeac';
      selectedBgColor = '#ff8a00';
    } else if (index >= 12 && index <= 16) {
      hoverBgColor = '#c1c6ef';
      selectedBgColor = '#7882dc';
    } else if (index == CUSTOMIZE_EMAIL_INDEX) {
      hoverBgColor = '#BCC5CF';
      selectedBgColor = '#596A7C';
    } else {
      hoverBgColor = '#b2e0ff';
      selectedBgColor = '#4dbbff';
    }

    if (isExtend) {
      return {
        fontSize: '14px',
        lineHeight: '20px',
        textTransform: 'capitalize',
        fontWeight: 400,
        fontStyle: 'normal',
        width: '225px',
        maxHeight: '32px',
        color: selectedBgColor,
        opacity: 1,
        margin: 0,
        padding: '0 6px 0 10px !important',
        justifyContent: 'flex-start',
        minHeight: '32px',
        '& .MuiTab-iconWrapper': {
          marginRight: '16px',
        },
        '&:hover': {
          backgroundColor: hoverBgColor,
          opacity: 1,
        },
        '&.Mui-selected': {
          backgroundColor: selectedBgColor,
          fontWeight: 400,
          color: 'white',
          //'svg path': {
          //  stroke: 'white',
          //},
        },
        '&.Mui-focusVisible': {
          backgroundColor: '#d1eaff',
        },
      };
    } else {
      return {
        borderRadius: '4px',
        padding: '6px',
        fontSize: '14px',
        lineHeight: '20px',
        textTransform: 'capitalize',
        fontWeight: 400,
        fontStyle: 'normal',
        width: '20px',
        minHeight: '32px',
        maxHeight: '32px',
        color: selectedBgColor,
        opacity: 1,
        margin: 0,
        justifyContent: 'center',
        '& .MuiTab-iconWrapper': {
          marginRight: '16px',
        },
        svg: {
          width: '20px',
          height: '20px',
          padding: '6px !importent',
          boxSizing: 'content-box',
        },
        '& svg:hover': {
          borderRadius: '4px',
          backgroundColor: hoverBgColor,
          padding: '6px',
          opacity: 1,
        },
        '&.Mui-selected svg': {
          borderRadius: '4px',
          backgroundColor: selectedBgColor,
          padding: '6px',
          fontWeight: 400,
          //path: {
          //  stroke: 'white',
          //},
        },
        '&.Mui-focusVisible': {
          backgroundColor: '#d1eaff',
        },
      };
    }
  }
);

const TARGET_TABS = [
  // const targetLab = [
  // >>>>>>> release-p2m1-20230919
  'JournalTarget',
  'SubjectTarget',
  'BusinessTarget',
  'CETarget',
];
const Autolayout = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: ' center',
  // padding: ' 10px 32px',
  gap: ' 12px',
};

/**
 * Item
 * @param {*} props
 * @returns
 */
function Item(props) {
  const dispatch = useDispatch();
  const {
    label,
    path, // !
    id,
    Icon,
    isSpecial = false,
    page = 0,
    index,
    authList,
    match,
    className = ' ',
  } = props;
  let { isExtend } = props;
  /**说明：reportIndex是为了解决report的问题，因为report的路径是有可能变化的（比如这次的SIP的加入导致其出现问题）
  /* 所以需要一个变量来记录当前的report路径，以便于新加sidebar页面的时候修改
  /* 当新加页面时，请修改reportIndex的值、SiderItem中的索引、SideBarReducer中的初始化值
  */
  let userPermissionArray = localStorage.getItem('userPermissions').split(',');
  let authed =
    authList === undefined ||
    authList.some(item => userPermissionArray.includes(item));
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const selector = state => {
    return {
      // fiscalYear: state.AnnualTarget.fiscalYear,
      navigationState: state.SideBar.navigationState,
      reportIndex: state.SideBar.reportIndex,
    };
  };
  let navPath = match ? match : path;
  let resolved = useResolvedPath(navPath);

  const {
    // fiscalYear,
    navigationState,
    reportIndex,
  } = useSelector(selector);

  const selectMonthDate = useSelector(state => state.Report.selectDate);
  const [fiscalYear, setSelectFY__] = useState(
    getFyYearFromDate(selectMonthDate)
  );
  useEffect(() => {
    if (selectMonthDate != null) {
      const fyYear = getFyYearFromDate(selectMonthDate);
      setSelectFY__(fyYear);
    }
  }, [selectMonthDate]);

  let _match = useMatch({ path: resolved.pathname, end: false });

  // activeIndex is the index of the active item
  let activeIndex = isSpecial ? reportIndex : 0;
  navigationState.detailsList.forEach(element => {
    if (element.active === true) {
      activeIndex = element.index;
    }
  });

  if (
    path === '/simt/auth/reportRelated/report/overview' &&
    activeIndex === 0
  ) {
    activeIndex = reportIndex;
  }
  _match = isSpecial ? index === activeIndex && _match : _match;

  // for report Item need to navigate
  const handleNavigate = path => {
    dispatch(emptyFileError(false));
    dispatch(switchTarget(TARGET_TABS[0]));

    // Apply current showTable status when navigate to another 1st level report tab
    let showTable = searchParams.get('showTable');
    showTable = showTable === 'false' ? false : true;

    navigate(`${path}?showTable=${showTable}`);
  };

  if (authed) {
    return (
      <StyledTab
        sx={{}}
        icon={Icon}
        iconPosition='start'
        id={id}
        index={index}
        isExtend={isExtend}
        className={
          className +
          ' ' +
          (_match ? 'Mui-selected' : '') +
          ' ' +
          (isExtend ? 'Item-isExtend' : '')
        }
        label={isExtend ? label : ''}
        onClick={() => {
          dispatch(setNotificationDrawerOpen(false));

          props.childItemSelected(props.id);
          changeActiveItem(props.parentId);
          // >>>>>>> release-p2m1-20230919
          const nav_tmp = navigationState.detailsList.map((item, index1) => {
            item.active = index1 === index;
            item.key = index;
            return item;
          });
          dispatch(
            setNavigationState({
              activeIndex: index,
              detailsList: nav_tmp,
            })
          );
          if (isSpecial) {
            handleNavigate(path, page); // for report there are more thing to do
          } else {
            navigate(path);
          }
        }}
      />
      /* </Tooltip>*/
    );
  } else {
    return <></>;
  }
}

/**
 * DisableItem Component
 * @param {*} props
 * @returns
 */
function DisableItem(props) {
  const { icon, label } = props;
  return (
    <Box
      sx={{
        height: '32px',
        weight: '100%',
        // background: '#FFFFFF',//modified
        ...Autolayout,
        boxSizing: 'border-box',
        paddingLeft: props.isExtend ? '10px' : '32px',
      }}
    >
      <Box sx={{ paddingLeft: '1px' }}>{icon}</Box>
      <Box
        sx={{
          ...SmallFont,
          color: '#FFD495',
          paddingLeft: '0px',
        }}
      >
        {props.isExtend ? label : ''}
      </Box>
    </Box>
  );
}

/**
 * SiderItem Component
 * @param {*} props
 * @returns
 */
function SiderItem({ handleClick, currentId, isExtend }) {
  /**
   * 说明：这里是管理sidebar是否被选中使用的数据结构
   * 默认是第一个被选中
   * 当新加页面时，请修改reportIndex的值、SiderItem中的索引、SideBarReducer中的初始化值
   */

  const location = useLocation().pathname;
  useEffect(() => {
    if (location.includes('/simt/auth/system/')) {
      changeActiveItem('commonSiderUserAndSystem');
    } else if (location.includes('/simt/auth/siRelated/si/')) {
      changeActiveItem('commonSiderSpecialIssue');
    } else if (location.includes('/simt/auth/sipRelated/sip/')) {
      changeActiveItem('commonSiderSpecialIssueProposal');
    } else if (location.includes('/simt/auth/reportRelated/report/')) {
      changeActiveItem('commonSiderReport');
    } else if (location.includes('/simt/auth/journalRelated/')) {
      changeActiveItem('commonSiderJournal');
    }
  }, []);

  return (
    <>
      <ItemFolder
        id='commonSiderSuperAdmin'
        label='SuperAdmin'
        authList={[]}
        isExtend={isExtend}
      >
        <Item
          id='commonSiderSuperAdminAccountManagerButton'
          label='SuperAdminAccountManager'
          path='/'
          match='/not_impl'
          active={location.includes('/simt/auth/system/user')}
          isExtend={isExtend}
        />
      </ItemFolder>
      <ItemFolder
        id='commonSiderUserAndSystem'
        label={
          <FormattedMessage
            id={
              isExtend ? 'sideBar.userAndSystem' : 'sideBar.userAndSystemFolded'
            }
          />
        }
        authList={[ PERMISSIONS.ADMIN_WRITE]}
        isExtend={isExtend}
        childItemSelect={handleClick}
        currentId={currentId}
      >
        <Item
          id='commonSiderUserManagementButton'
          label={<FormattedMessage id='sideBar.userManagement' />}
          path='/simt/auth/system/user'
          Icon={
            location.includes('/simt/auth/system/user') ? (
              <User />
            ) : (
              <UserManagement />
            )
          }
          index={0}
          isExtend={isExtend}
        />
        <Item
          id='commonSiderRoleManagementButton'
          label={<FormattedMessage id='sideBar.roleManagement' />}
          path='/simt/auth/system/role'
          active={location.includes('/simt/auth/system/role')}
          Icon={
            location.includes('/simt/auth/system/role') ? (
              <RoleManagement />
            ) : (
              <Role />
            )
          }
          index={1}
          isExtend={isExtend}
        />
        <Item
          id='commonSiderSystemConfigurationButton'
          label={<FormattedMessage id='sideBar.systemConfiguration' />}
          path='/simt/auth/system/config'
          active={location.includes('/simt/auth/system/config')}
          Icon={
            location.includes('/simt/auth/system/config') ? (
              <System />
            ) : (
              <SystemLog />
            )
          }
          index={2}
          isExtend={isExtend}
        />
        <Item
          id='commonSiderSystemLogButton'
          label={<FormattedMessage id='sideBar.systemLog' />}
          path='/simt/auth/system/log'
          active={location.includes('/simt/auth/system/log')}
          Icon={
            location.includes('/simt/auth/system/log') ? (
              <SystemConfig />
            ) : (
              <SystemConfiguration />
            )
          }
          index={3}
          isExtend={isExtend}
        />
        <Item
          id='commonSiderEmailTemplatesButton'
          label={<FormattedMessage id='sideBar.emailTemplates' />}
          path='/simt/auth/system/email'
          active={location.includes('/simt/auth/system/email')}
          Icon={
            location.includes('/simt/auth/system/email') ? (
              <Email />
            ) : (
              <EmailTemplates />
            )
          }
          index={4}
          isExtend={isExtend}
        />
        <Item
          id='commonSiderAnnualTargetButton'
          label={<FormattedMessage id='sideBar.annualTarget' />}
          path='/simt/auth/system/target'
          active={location.includes('/simt/auth/system/target')}
          Icon={
            location.includes('/simt/auth/system/target') ? (
              <Annual />
            ) : (
              <AnnualTarget />
            )
          }
          index={5}
          isExtend={isExtend}
        />
        <Item
          id='commonSiderReportJobsButton'
          label={<FormattedMessage id='sideBar.reportJobs' />}
          path='/simt/auth/system/jobs'
          active={location.includes('/simt/auth/system/jobs')}
          Icon={
            location.includes('/simt/auth/system/jobs') ? (
              <Report />
            ) : (
              <ReportJobs />
            )
          }
          index={6}
          isExtend={isExtend}
        />
        <Item
          id='commonSiderExportDataButton'
          label={<FormattedMessage id='sideBar.exportData' />}
          path='/simt/auth/system/export'
          active={location.includes('/simt/auth/system/export')}
          Icon={
            location.includes('/simt/auth/system/export') ? (
              <Export />
            ) : (
              <File />
            )
          }
          index={7}
          isExtend={isExtend}
        />
      </ItemFolder>
      <ItemFolder
        id='commonSiderEmail'
        label={<FormattedMessage id={'sideBar.customizeEmailFolder'} />}
        authList={[PERMISSIONS.EMAIL_SI, PERMISSIONS.EMAIL_SIP]}
        isExtend={isExtend}
        childItemSelect={handleClick}
        currentId={currentId}
      >
        <Item
          id='commonSiderCustomizeEmailTemplatesButton'
          label={
            <>
              Customize Email <br /> Template
            </>
          }
          path='/simt/auth/email/email'
          active={location.includes('/simt/auth/email/email')}
          Icon={
            location.includes('/simt/auth/email/email') ? (
              <Email2 />
            ) : (
              <EmailTemplates2 />
            )
          }
          index={CUSTOMIZE_EMAIL_INDEX}
          isExtend={isExtend}
          className={' CustomizeEmailItem '}
        />
      </ItemFolder>
      <ItemFolder
        id='commonSiderSpecialIssue'
        label={
          <FormattedMessage id={isExtend ? 'sideBar.si' : 'sideBar.siFolded'} />
        }
        authList={[PERMISSIONS.SI_READ, PERMISSIONS.SI_READ_LIMITED, PERMISSIONS.SI_EXPORT, PERMISSIONS.SI_WRITE, PERMISSIONS.GE_READ, PERMISSIONS.GE_EXPORT, PERMISSIONS.GE_WRITE, PERMISSIONS.CHINA_MARKETING_READ]}
        isExtend={isExtend}
        childItemSelect={handleClick}
        currentId={currentId}
      >
        <Item
          id='commonSiderOverviewButton'
          label={<FormattedMessage id='sideBar.overview' />}
          path='/simt/auth/siRelated/si/overview'
          match='/simt/auth/siRelated/si/'
          active={
            location.includes('/simt/auth/siRelated/si/overview') ||
            location.includes('/simt/auth/siRelated/si/detail')
          }
          authList={[PERMISSIONS.ADMIN_WRITE, PERMISSIONS.SI_READ, PERMISSIONS.SI_READ_LIMITED, PERMISSIONS.SI_EXPORT, PERMISSIONS.SI_WRITE, PERMISSIONS.CHINA_MARKETING_READ]}//就算无权限，也算作一个children，所以ItemFolder肯定有2个children
          Icon={
            location.includes('/simt/auth/siRelated/si/overview') ||
            location.includes('/simt/auth/siRelated/si/detail') ? (
              <OVERVIEW />
            ) : (
              <Overview />
            )
          }
          index={8}
          isExtend={isExtend}
        />
        <Item
          id='commonSiderGuestEditorButton'
          label={<FormattedMessage id='sideBar.ge' />}
          path='/simt/auth/siRelated/ge/overview'
          authList={[PERMISSIONS.ADMIN_WRITE, PERMISSIONS.GE_READ, PERMISSIONS.GE_EXPORT, PERMISSIONS.GE_WRITE]}
          match='/simt/auth/siRelated/ge/'
          active={
            location.includes('/simt/auth/siRelated/ge/overview') ||
            location.includes('/simt/auth/siRelated/ge/detail')
          }
          Icon={
            !(
              location.includes('/simt/auth/siRelated/ge/overview') ||
              location.includes('/simt/auth/siRelated/ge/detail/')
            ) ? (
              <Guest />
            ) : (
              <GuestActive />
            )
          }
          index={9}
          isExtend={isExtend}
        />
      </ItemFolder>
      <ItemFolder
        id='commonSiderSpecialIssueProposal'
        label={
          <FormattedMessage
            id={isExtend ? 'sideBar.sipExtended' : 'sideBar.sipFolded'}
          />
        }
        authList={[PERMISSIONS.SIP_READ, PERMISSIONS.SIP_WRITE_JPA, PERMISSIONS.SIP_WRITE_HANDLING_CE]}
        isExtend={isExtend}
        childItemSelect={handleClick}
        currentId={currentId}
      >
        <Item
          id='commonSiderSIPOverviewButton'
          label={<FormattedMessage id='sideBar.overview' />}
          path='/simt/auth/sipRelated/sip/overview'
          match='/simt/auth/sipRelated/sip/'
          Icon={
            location.includes('/simt/auth/sipRelated/sip/overview') ||
            location.includes('/simt/auth/sipRelated/sip/detail') ||
            location.includes('/simt/auth/sipRelated/sip/aiScreeningReport') ||
            location.includes('/simt/auth/sipRelated/sip/createSIPPage')
             ? (
              <SIOverview />
            ) : (
              <SIPOverview />
            )
          }
          active={
            location.includes('/simt/auth/sipRelated/sip/overview') ||
            location.includes('/simt/auth/sipRelated/sip/detail')
          }
          index={10}
          isExtend={isExtend}
        />
        {/* report 暂时是disable的 */}
        <Item
          id="commonSiderFlaggedGEVerificationButton"
          label={<FormattedMessage id='sideBar.sipReport' />}
          path="/simt/auth/sipRelated/geVerification/flagGEVerification"
          authList={[PERMISSIONS.SIP_WRITE_OPS]}
          match="/simt/auth/sipRelated/geVerification/flagGEVerification"
          Icon={location.includes('/simt/auth/sipRelated/geVerification/flagGEVerification')
            ?<SIPReportActive />:<SIPReport />}
          active={location.includes('/simt/auth/sipRelated/geVerification/flagGEVerification')}
          index={11}
          isExtend={isExtend}
        />
        {/* <DisableItem
          label={<FormattedMessage id='sideBar.sipReport' />}
          icon={<SIP />}
          isExtend={isExtend}
        /> */}
      </ItemFolder>
      <ItemFolder
        id='commonSiderReport'
        label={<FormattedMessage id='sideBar.report' />}
        authList={[PERMISSIONS.REPORT_READ_GENERAL]}
        isExtend={isExtend}
        childItemSelect={handleClick}
        currentId={currentId}
      >
        <Item
          id='commonSiderSIPipelineButton'
          label={<FormattedMessage id='sideBar.siPipeline' />}
          path='/simt/auth/reportRelated/report/siPipeline'
          authList={[PERMISSIONS.ADMIN_WRITE, PERMISSIONS.REPORT_READ_GENERAL]}
          Icon={
            location.includes('/simt/auth/reportRelated/report/siPipeline') ? (
              <SIPIP />
            ) : (
              <SIPIPELINE />
            )
          }
          active={location.includes(
            '/simt/auth/reportRelated/report/siPipeline'
          )}
          active={
            location.includes('/simt/auth/reportRelated/report/siPipeline')
          }
          isSpecial={true}
          page={0}
          index={12}
          isExtend={isExtend}
        />
        <Item
          id='commonSiderAcquiredAndActiveSIButton'
          label={<FormattedMessage id='sideBar.acquiredAndActiveSI' />}
          path='/simt/auth/reportRelated/report/acquiredAndActiveSI'
          authList={[PERMISSIONS.ADMIN_WRITE, PERMISSIONS.REPORT_READ_GENERAL]}
          Icon={
            location.includes(
              '/simt/auth/reportRelated/report/acquiredAndActiveSI'
            ) ? (
              <Acquire />
            ) : (
              <Punctuation />
            )
          }
          active={
            location.includes(
              '/simt/auth/reportRelated/report/acquiredAndActiveSI'
            )
          }
          isSpecial={true}
          page={1}
          index={13}
          isExtend={isExtend}
        />
        <Item
          id='commonSiderArticlsButton'
          label={<FormattedMessage id='sideBar.articles' />}
          path='/simt/auth/reportRelated/report/articles'
          authList={[PERMISSIONS.ADMIN_WRITE, PERMISSIONS.REPORT_READ_GENERAL]}
          Icon={
            location.includes('/simt/auth/reportRelated/report/articles') ? (
              <ARTICLES />
            ) : (
              <Articles />
            )
          }
          active={
            location.includes('/simt/auth/reportRelated/report/articles')
          }
          isSpecial={true}
          page={2}
          index={14}
          isExtend={isExtend}
        />
        <Item
          id='commonSiderJournalButton'
          label={<FormattedMessage id='sideBar.journal' />}
          authList={[PERMISSIONS.ADMIN_WRITE, PERMISSIONS.REPORT_READ_GENERAL]}
          path='/simt/auth/reportRelated/report/journal'
          Icon={
            location.includes('/simt/auth/reportRelated/report/journal') ? (
              <JOURNAL />
            ) : (
              <Journal />
            )
          }
          isSpecial={true}
          active={
            location.includes('/simt/auth/reportRelated/report/journal')
          }
          page={3}
          index={15}
          isExtend={isExtend}
        />
        <Item
          id='commonSiderTargetButton'
          label={<FormattedMessage id='sideBar.targets' />}
          authList={[PERMISSIONS.ADMIN_WRITE, PERMISSIONS.REPORT_READ_TARGET]}
          path='/simt/auth/reportRelated/report/targets'
          Icon={
            location.includes('/simt/auth/reportRelated/report/targets') ? (
              <TARGETS />
            ) : (
              <Targets />
            )
          }
          isSpecial={true}
          page={4}
          active={
            location.includes('/simt/auth/reportRelated/report/targets')
          }
          index={16}
          isExtend={isExtend}
        />
      </ItemFolder>
      {/* China Marketing */}
      <ItemFolder
        id='commonSiderChinaMarketing'
        label={
          <FormattedMessage
            id={
              isExtend
                ? 'sideBar.ChinaMarketing'
                : 'sideBar.ChinaMarketingFolded'
            }
          />
        }
        authList={[PERMISSIONS.CHINA_MARKETING_WRITE]}
        isExtend={isExtend}
        childItemSelect={handleClick}
        currentId={currentId}
      >
        <Item
          id='commonSiderChinaMarketingButton'
          label={<FormattedMessage id='sideBar.overview' />}
          path='/simt/auth/marketing/si/overview'
          match='/simt/auth/marketing/si/'
          Icon={
            location.includes('/simt/auth/marketing/si/') ? (
              <OVERVIEW />
            ) : (
              <Overview />
            )
          }
          active={location.includes('/simt/auth/marketing/si/')}
          index={19}
          isExtend={isExtend}
          className={
            isExtend
              ? styleModule.ExtendChinaMarketingButton
              : styleModule.CollapseChinaMarketingButton
          }
        />
      </ItemFolder>
      <ItemFolder
        id='commonSiderJournal'
        label={<FormattedMessage id='sideBar.journal' />}
        authList={[PERMISSIONS.JOURNAL_CR_CONTACT,PERMISSIONS.JOURNAL_CAT_READ,]}
        isExtend={isExtend}
        childItemSelect={handleClick}
        currentId={currentId}
      >
        <Item
          id='commonSiderCRContactsButton'
          label={<FormattedMessage id='sideBar.prpContacts' />}
          path='/simt/auth/journalRelated/prp/overview'
          authList={[PERMISSIONS.ADMIN_WRITE, PERMISSIONS.JOURNAL_CR_CONTACT]}
          match='/simt/auth/journalRelated/prp/'
          Icon={
            location.includes('/simt/auth/journalRelated/prp/') ? (
              <CR />
            ) : (
              <CRContact />
            )
          }
          active={location.includes('/simt/auth/journalRelated/prp/')}
          index={17}
          isExtend={isExtend}
        />
        <Item
          id='commonSiderCATJournalButton'
          label={<FormattedMessage id='sideBar.catJournal' />}
          path='/simt/auth/journalRelated/cat/overview'
          authList={[PERMISSIONS.ADMIN_WRITE, PERMISSIONS.JOURNAL_CAT_READ]}
          match='/simt/auth/journalRelated/cat/'
          Icon={
            location.includes('/simt/auth/journalRelated/cat/') ? (
              <CATJournal />
            ) : (
              <Cat />
            )
          }
          active={location.includes('/simt/auth/journalRelated/cat/')}
          index={18}
          isExtend={isExtend}
        />
      </ItemFolder>
    </>
  );
}

/**
 * CommonSider Component
 * @returns
 */
export default function CommonSider() {
  const selector = state => ({
    siderOpen: state.UserManage.siderOpen,
    Updown: state.SideBar.Updown,
    menuHeightReduce: state.SideBar.menuHeightReduce,
  });
  const {
    siderOpen: isExtend,
    Updown,
    menuHeightReduce,
  } = useSelector(selector);

  const [autoScroll, setAutoScroll] = useState(1);
  const location = useLocation().pathname;

  const scrollTimeoutRef = useRef(null);
  const [footerShowed, setFooterShowed] = useState(false);

  function onScrollEnd() {
    const footer = document.querySelector("#PageFooter");
    if(!footer) return;
    const rect = footer.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    if (rect.top <= windowHeight && rect.bottom >= 0) {
      setFooterShowed(windowHeight - rect.top);
    } else {
      setFooterShowed(0);
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      cancelAnimationFrame(scrollTimeoutRef.current);
      scrollTimeoutRef.current = requestAnimationFrame(onScrollEnd);
    };

    const observer = new MutationObserver(() => {
      setTimeout(onScrollEnd, 1000);
    });

    const outer = document.querySelector('#outer');
    outer.addEventListener('scroll', handleScroll);
    observer.observe(outer, { childList: true, subtree: true });
    setTimeout(onScrollEnd, 1000);

    return () => {
      cancelAnimationFrame(scrollTimeoutRef.current);
      observer.disconnect();
      outer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location !== '/simt/auth/superAdmin') {
      addListener();
    } else {
      try {
        document.removeEventListener('scroll', handelDocumentScroll);
      } catch (e) {}
    }
    return () => {
      try {
        document.removeEventListener('scroll', handelDocumentScroll);
      } catch (e) {}
    };
  }, [1]);

  const StyledTabs = sysStyled(Tabs)({
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent',
    },
  });

  const [currentId, setCurrentId] = useState(
    sessionStorage.currentId || 'commonSiderUserManagementButton'
  );

  const [distance, setDistance] = useState(0);

  /**
   * @param {string} id 
   */
  const setCurrentIdClick = id => {
    sessionStorage.currentId = id;
    setCurrentId(id);
  };

  useEffect(() => {
    const element = document.getElementById(currentId);

    const CommonSiderScoll = document.getElementById('CommonSiderScoll');
    const CommonSiderScollTop = CommonSiderScoll.getBoundingClientRect().top;
    if (element) {
      const rectTop = element.getBoundingClientRect().top;
      setDistance(rectTop + CommonSiderScoll.scrollTop - CommonSiderScollTop);
    }
  }, [currentId]);

  /**
   * @param {Event} event
   */
  function handleScroll(event) {
    const div = event.target;
    const CommonSiderScoll = document.getElementById('CommonSiderScoll');
    const CommonSiderScollTop = CommonSiderScoll.getBoundingClientRect().top;
    const element = document.getElementById(currentId);
    const rectTop = element.getBoundingClientRect().top;
  }

  useEffect(() => {
    if (location.includes('/simt/auth/system/user')) {
      setCurrentId('commonSiderUserManagementButton');
    }
  }, []);

  const isFirefox = navigator.userAgent.includes('Firefox');
  const isChrome = /Mozilla/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent);


  return (
    <Box>
      <Drawer
        variant='persistent'
        anchor='left'
        selenium-data-id='CommonSider'
        open={true}
        sx={{
          transition: 'all .5s',
          width: isExtend ? '237px' : '80px',
          flexShrink: 0,
          ['& .MuiDrawer-paper']: {
            transition: 'all .5s',
            width: isExtend ? '237px' : '80px',
            boxSizing: 'border-box',
            backgroundColor: '#fafafa ',
            overflow: 'hidden',
          },
        }}
      >
        {location !== '/simt/auth/superAdmin' ? (
          <MenuFolderButton></MenuFolderButton>
        ) : (
          ''
        )}
        <TableBox2
          dataList={[isExtend, currentId, location, Updown, menuHeightReduce]}
          tableKey={'CommonSiderScoll-Scrollbar'}
          footerHeight={0}
          containerType={Box}
          containerSelector={'#CommonSiderScoll'}
          viewTag={'div.CommonSider-StyledTabs'}
          delay={300}
          sx={{
            paddingTop: '3px',
            paddingBottom: '2px',
            paddingLeft: '0px',
            paddingRight: '15px',
            top: '80px',
            transition: 'all 0s',

            '& .scroll-bar-container-box': {
              ... (isChrome && {display: 'none'}),
              transition: 'all 0s',
              right: 2,
              width: '15px',
              //for firefox:
              scrollbarWidth: 0,
            },
            '& .scroll-bar-container-box::-webkit-scrollbar': {
              width: '0px',
            },
            '& .scroll-bar-container-box:hover': {
              right: 0,
            },
            '&:hover .scroll-bar-container-box::-webkit-scrollbar': {
              width: '4px',
            },
            '& .scroll-bar-container-box:hover::-webkit-scrollbar': {
              width: '6px',
            },
          }}



        >
          <Box
            id='CommonSiderScoll'
            sx={{
              width: isExtend ? '237px' : '80px',
              height: `calc(100vh - ${84 + menuHeightReduce + footerShowed}px)`,
            }}
            // onScroll={handleScroll}
          >
            <StyledTabs
              className={'CommonSider-StyledTabs'}
              selenium-data-id='CommonSider-StyledTabs'
              orientation='vertical'
              value={0}
              aria-label='basic tabs example'
              sx={{
                mt: '0px',
                // mb: '10px',
                // mb: '80px'
              }}
            >
              <SiderItem
                isExtend={isExtend}
                clickScoll={autoScroll.changeActiveItem}
                handleClick={id => setCurrentIdClick(id)}
                currentId={currentId}
              />
            </StyledTabs>
          </Box>
        </TableBox2>
      </Drawer>
    </Box>
  );
}

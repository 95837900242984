import { Box } from '@mui/material';
import React from 'react';

export default function SectionTitle(props) {
  const { children } = props;
  return (
    <Box
      sx={{
        'font-size': '20px',
        'font-weight': '400',
        'line-height': '27px',
        'letter-spacing': '0em',
        'text-align': 'left',
        'color': '#596A7C',
        // marginBottom: '24px',
      }}>{children}</Box>
  );
}

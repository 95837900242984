import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useContext,
} from 'react';
import './index.css';
import {
  Box,
  Checkbox,
  Divider,
  Link,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow as MuiTableRow,
  Typography as MuiTypography,
  Grid,
  Stack,
} from '@mui/material';
import {
  MyTableHeader,
  StyledTableCell,
  TableFooter2,
} from '../../../components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  appendSIReqId,
  clear,
  handleChangePageSize,
  handlePageChangeAction,
  setOrderBy,
} from '../../../actions/SIMT-SI/SpecialIssue/SIAction';
import {
  addAllSelected,
  addSelected,
  cancelSelectAll,
  removeAllSelected,
  removeSelected,
  setIsAllSelected,
} from '../../../actions/SeleteAction';
import {
  batchSelectSIFilter,
  setAdaptSIFilterSelected,
  setFavSIFilterSelected,
  setFilters,
  setSearchSI,
} from '../../../actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import ChangeList from '../ChangeList';
import { formatDateString, getUTCToDate } from '../../../utils/commonUtils';
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import GeMenuIcon from './GeMenuIcon';
import TableSkeleton from '../../../components/TableSkeleton';
import { unstable_batchedUpdates } from 'react-dom';
import TooltipMoveArrow from '@/componentsphase2/CustomTooltip';

import {
  SubmissionDeadlinePassedAlert,
  ExpectedAcceptanceDatePassedAlert,
  PublicationDatePassedAlert,
} from '@/componentsphase2/PassedClock/PassedClock';

import { useNavigate, Link as ReactLick, useSearchParams } from 'react-router-dom';
import items from './header-rows';
import { getItems } from './header-rows';
import SICodeLink from './SICodeLink';
import NoDataMessage from '@/componentsphase2/NoDataMessage';
import { styled } from '@mui/system';
import { tooltipClasses } from '@mui/material/Tooltip';
import { TableFoooter } from '@/components/DataTable';
// import { CustomTooltip } from '@/components/CustomTooltip';
import {
  contextHeightChanged,
  tableHeightChanged,
} from '../../../actions/CommonAction';
import SIPOverviewUncheckedIcon from '../../../assets/SIPOverviewUncheckedIcon.svg';
import SIPOverviewCheckedIcon from '../../../assets/SIPOverviewCheckedIcon.svg';
import CATJournalIndeterminate from '../../../assets/catjournalindeterminate.svg';
import ScrollBarY from '../../../componentsphase2/ScrollBar/ScrollBarY';
// import { useLocation } from 'react-router-dom';
import {
  marketing,
  // siOverview,
  siDetail,
} from '@/Route';
import { isCNmarketingContext } from '@/Route';
import { MulLineTooltipIfShow } from '../../../componentsphase2/CustomTooltip';
import { WindowHeightContext } from '../Overview/Filter/Context';
import { prepareFilters } from '../Overview/Filter/utils';
import { measureWidth } from '@/utils/StringWidthMeasure';
import SimpleLabel from '../../../componentsphase2/Input/SimpleLabel';
import SimpleCheckBox from '../../../componentsphase2/Input/SimpleCheckBox';
import { TableBox2 } from '../../Report/ReportSpecificComponents/SpecialColorTableHeader';
import { loadFavourite, loadFavouriteFirst } from "@/actions/SIMT-SI/SpecialIssueFilter";
import { getMandatoryFields } from "@/actions/SIMT-SI/SpecialIssue";
import { getSIData } from "@/actions/SIMT-SI/SpecialIssue/SpecialIssuePage";
import { getSIDataIds } from "@/actions/SIMT-SI/SpecialIssue/Export";
import { getUserColumns } from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueColumns";
import { getUserId } from '@/utils/getUserId';
import objectHash from 'object-hash';
import {PERMISSIONS} from "@/constant/permission";

const Typography = styled(MuiTypography)({
  fontWeight: '600',
  color: '#154AB6',
  lineHeight: '20px',
  '&:hover': {
    color: '#113D95',
    fontWeight: '700',
  },
});
const HoverTableRow = styled(MuiTableRow)({
  '& td': {
    background: '#FFFFFF',
  },
});

export function NewStyledTableCell(props) {
  // SI 表格特殊需求的table cell
  const { show, data, align, sx } = props;
  const style = {
    display: show ? 'table-cell' : 'none',
    paddingLeft: '24px', // 为了不影响其他表格的表头，将SI table的表体padding
    position: props.sticky ? 'sticky' : '',
    left: props.sticky ? props.stickyPX : '',
    zIndex: props.sticky ? 5 : '',
    // background: '#FFFFFF',
    maxWidth: '260px',
    textOverflow: 'ellipsis',
    paddingTop: '12px',
    paddingBottom: '12px',
    lineHeight: '20px',
  };
  if (data) {
    return (
      <StyledTableCell style={style} align={align} sx={sx}>
        {data}
      </StyledTableCell>
    );
  }
  return (
    <StyledTableCell style={style} align={align} sx={sx}>
      {props.children}
    </StyledTableCell>
  );
}

export const getGEShowString = (geList, ellipsis = false) => {
  let geString = '',
    isEllipsis = false;
  if (geList) {
    geList.forEach(ge => {
      if (ge !== null) {
        if (
          !ellipsis ||
          measureWidth(geString + ';' + ge.fullName, '14px Open Sans') < 194
        ) {
          if (geString.length > 0) {
            geString += '; ';
          }
          geString += ge.fullName;
        } else {
          if (
            ellipsis &&
            measureWidth(geString + ';' + ge.fullName, '14px Open Sans') > 194
          )
            isEllipsis = true;
        }
      }
    });
  }
  if (isEllipsis) geString += '...';
  return geString;
};

const IssueTable = React.forwardRef((props, ref) => {
  window.x = this;

  const {
    changeListOpen,
    setChangeListOpen,
    showColumns,
    setShowColumns,
    sx,
    withCMOnly,
    showFilter,
    userId = getUserId(),
  } = props;

  const newitems = getItems();

  const leadGeRef = useRef([]);
  const coGeRef = useRef([]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const selector = state => {
    return {
      siData: state.SI.siData ?? { result: [], count: 0 },
      siCodeAllList: state.SI.siCodeAllList ?? [],
      page: state.SI.page,
      perPageNumber: state.SI.pageSize,
      searchSI: state.Filter.searchSI,
      selected: state.Selete.selectedList,
      useFilters: state.Filter.useFilters,
      orderByType: state.SI.orderByType,
      MandatoryTable: state.SI.MandatoryTable,
      localSIRequestNo: state.SI.localSIRequestNo,
      siderOpen: state.UserManage.siderOpen,
      siFilterSelected: state.Filter.siFilterSelected,
      firstSIFilterSelected: state.Filter.firstSIFilterSelected,
    };
  };
  const {
    siData,
    siCodeAllList,
    page,
    perPageNumber,
    selected,
    searchSI,
    useFilters,
    orderByType,
    MandatoryTable,
    localSIRequestNo,
    siderOpen,
    siFilterSelected,
    firstSIFilterSelected,
  } = useSelector(selector);

  const [searhParams, setSearchParams] = useSearchParams();
  const createSiResult = searhParams.getAll('createSiResult');

  useEffect(() => {
    // 定义一个定时器，第10秒执行一次
    const interval = setTimeout(() => {
      // 在每次执行操作之前清除查询参数对象
      setSearchParams({});
    }, 1000);
    // 清除定时器的函数，确保在组件卸载时清除定时器
    return () => clearTimeout(interval);
  }, []); // 传入空数组确保 useEffect 仅在组件挂载时执行一次

  const userPermissions = localStorage.getItem('userPermissions'); //权限管理
  // const isCNmarketing = useLocation().pathname.includes(`simt/auth/${marketing}/${siOverview}`);
  const isCNmarketing = useContext(isCNmarketingContext) ?? false;

  const [loading, setLoading] = useState(true);

  const containerBoxRef = useRef(null);

  useEffect(() => {
    // reset the `useFilters` to null so as to trigger the following steps for loading fav filter
    //      还是恢复成最近一次apply的？
    //      目前为最近一次apply的，否则可能产生歧义
    return () => {
      dispatch(setFilters(null));
    };
  }, []);

  // This hook should be placed at top
  useLayoutEffect(() => {
    dispatch(cancelSelectAll());
    dispatch(clear());
    dispatch(getUserColumns());
  }, [dispatch]);

  // This logic of loading SI has been changed as:
  //    1. check if `useFilters` is null. If so, we call loadFavourite at first.
  //    2. The loadFav function assures the `useFilters` will not be null, so the hook will be called again.
  //        So it will not go into the if segment, and SI data will be loaded.
  useLayoutEffect(() => {
    setLoading(true);
    if (useFilters === null) {
      // load the filters from local or favourite
      // const localFilter = localStorage.getItem('lastFilters');
      // const localSearch = localStorage.getItem('lastSearch') ?? ''; // default empty string
      // if (localFilter) {
      //   const temp = JSON.parse(localFilter);
      //   dispatch(batchSelectSIFilter(temp));
      //   if (localSearch) {
      //     // both not empty
      //     unstable_batchedUpdates(() => {
      //       dispatch(setFilters(temp));
      //       dispatch(setSearchSI(localSearch));
      //     });
      //   } else {
      //     // only local Filter not empty
      //     dispatch(setFilters(temp));
      //   }
      // } else {
      //   if (localSearch) {
      //     // only local search not empty
      //     unstable_batchedUpdates(() => {
      //       dispatch(setSearchSI(localSearch));
      //       dispatch(loadFavourite(true, false, isCNmarketing, userId));
      //     });
      //   } else {
      //     // all empty, load fav filter
      //     // load the fav filters. The function will change the useFilters variable.
      //     dispatch(loadFavourite(true, false, isCNmarketing, userId));
      //   }
      // }
      // 废弃浏览器本地缓存，每次进入系统都重新从后端加载
      return;
    }
    // debugger;
    // save the current filters
    // ！！注意！！因为发送到后端加载SI的时候是不需要handlingCeName这个属性的，
    // 但是我们在从localStorage加载用户上一次使用的Filter数据时，是需要把这个信息加回来的
    // 因此在构造向本地存储保存的数据的时候就需要把这个信息保留下来
    const toSave = useFilters;
    localStorage.setItem('lastFilters', JSON.stringify(toSave));
    localStorage.setItem('lastSearch', searchSI);
    unstable_batchedUpdates(() => {
      dispatch(appendSIReqId());
    });
    async function fetchData() {
      // you can await a dispatch since middleware replace origin dispatch and use origin dispatch as a call back, so the new dispatch will return a promise
      await dispatch(
        getSIData(
          page,
          perPageNumber,
          orderByType.order,
          orderByType.by,
          searchSI,
          { ...useFilters, handlingCeName: undefined },
          localSIRequestNo,
          withCMOnly
        )
      );
    }
    fetchData().then(_r => {
      setLoading(false);
    });
  }, [perPageNumber, page, orderByType, objectHash(useFilters), searchSI, withCMOnly]);

  useEffect(() => {
    if (useFilters === null) return;
    dispatch(getSIDataIds({ ...useFilters, handlingCeName: undefined }));
  }, [objectHash(useFilters), searchSI, withCMOnly]);

  useEffect(() => {
    //获取必填表
    let mandatoryList = localStorage.getItem('AcquiredMandatoryList');
    if (mandatoryList === undefined || mandatoryList === null) {
      dispatch(getMandatoryFields());
    }
    dispatch(loadFavourite(true, false, isCNmarketing, userId));
  }, []);

  useEffect(() => {
    if (firstSIFilterSelected) {
      let temFirstSIFilterSelected = prepareFilters(
        firstSIFilterSelected,
        true,
        true
      );
      dispatch(setFavSIFilterSelected(temFirstSIFilterSelected));
      dispatch(setAdaptSIFilterSelected(temFirstSIFilterSelected));
      dispatch(setFilters(prepareFilters(siFilterSelected, true, false)));
    }
  }, [firstSIFilterSelected]);

  //必填表
  useEffect(() => {
    if (MandatoryTable === 'error') {
      setTimeout(() => {
        let mandatoryList = localStorage.getItem('AcquiredMandatoryList');
        if (mandatoryList === undefined || mandatoryList === null) {
          dispatch(getMandatoryFields());
        }
      }, 1000);
    }
    // console.log(mandatoryList)
  }, [MandatoryTable]);

  const [ct, setCt] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setCt(ct + 1);
    });
  }, [siData, loading]);

  useEffect(() => {
    if ((siData.result === null || siData.result.length === 0) && page !== 1) {
      // just a workaround when exceeding the max page
      dispatch(handlePageChangeAction(1));
      return;
    }
    if (selected.length === 0) {
      dispatch(setIsAllSelected(false));
      return;
    }
    // 判断新的siData是否全部选中，然后更新全选选择框的状态
    // let isAllSelectedTemp = true;
    // for (const siItem of siData.result) {
    //   if (selected.indexOf(siItem.siCode) === -1) {
    //     isAllSelectedTemp = false;
    //     break;
    //   }
    // }
    //
    // dispatch(setIsAllSelected(isAllSelectedTemp));
    dispatch(setIsAllSelected(siCodeAllList.length == selected.length));
  }, [siData, selected]);

  const handleSelectAll = () => {
    // 选中当前展示的所有数据
    // for (const siElement of siData.result) {
    //   // 可参考GEAction
    //   dispatch(addSelected(siElement));
    // }
    // for (const siCode of siCodeAllList){
    //   dispatch(addSelected({
    //     siCode: siCode,
    //   }));
    // }
    dispatch(addAllSelected(siCodeAllList));
    // dispatch(setIsAllSelected(true));
  };

  const handleSelectAllPages = e => {
    console.info('handleSelectAllPages', e);
    console.info(siCodeAllList, selected);
    if (e.target.checked) {
      for (const siCode of siCodeAllList) {
        dispatch(
          addSelected({
            siCode: siCode,
          })
        );
      }
      dispatch(addAllSelected(siCodeAllList));
      dispatch(setIsAllSe1lected(true));
    } else {
      // for (const siCode of siCodeAllList){
      //   dispatch(removeSelected(siCode));
      // }
      dispatch(removeAllSelected());
      dispatch(setIsAllSelected(false));
    }
  };

  const handleUnselectAll = () => {
    // 取消选中当前的所有数据
    // for (const siElement of siData.result) {
    //   dispatch(removeSelected(siElement.siCode));
    // }
    dispatch(removeAllSelected());
    // dispatch(setIsAllSelected(false));
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderByType.by === property && orderByType.order === 'asc';
    dispatch(setOrderBy(isAsc ? 'desc' : 'asc', property));
  };

  const handlePerPageChange = e => {
    dispatch(handleChangePageSize(e.target.value));
  };

  const handlePageChange = (_event, value) => {
    dispatch(handlePageChangeAction(value));
  };

  const handleClick = projectCode => {
    // console.log(projectCode.siCode)
    const selectedIndex = selected.indexOf(projectCode.siCode);
    // console.log(selectedIndex)
    if (selectedIndex > -1) {
      dispatch(removeSelected(projectCode.siCode));
    } else {
      dispatch(addSelected(projectCode));
    }
  };

  const handleNavigateDetail = link => {
    navigate(link);
  };

  const numberHandling = data => {
    if (data === null || data === undefined) {
      return '0';
    } else {
      return '' + data;
    }
  };

  if (page === 0) {
    dispatch(handlePageChangeAction(1)); //防止加载不出来
  }

  const changeLine = data => {
    //让内容过长的元素移至下一行显示
    if (typeof data === 'string' && data.length > 0) {
      let result = data.split(',');
      let newDate = result[0];
      let n = newDate.length;
      for (let i = 1; i < result.length; i++) {
        if (n + result[i].length <= 25) {
          newDate += ',';
          n += result[i].length;
        } else {
          newDate += '\n';
          n = result[i].length;
        }
        newDate += result[i];
      }
      return newDate;
    } else {
      return data;
    }
  };

  const makeSpace = str => {
    let hasSpace =
      str.toLowerCase().includes(' ') ||
      str.toLowerCase().includes('String.fromCharCode(160)');
    if (!hasSpace) {
      const midIndex = Math.floor(str.length / 2);
      // why add the space?
      if (str.length > 46) {
        return (
          str.substring(0, 23) +
          // ' ' +
          str.substring(23, midIndex) +
          // ' ' +
          str.substring(midIndex)
        );
      } else if (str.length > 20) {
        // return str.substring(0, midIndex) + ' ' + str.substring(midIndex);
        return str.substring(0, midIndex) + str.substring(midIndex);
      } else {
        return str;
      }
    } else {
      return str.replaceAll(
        String.fromCharCode(160) /*&nbsp*/,
        ' ' /*normal space*/
      );
    }
  };

  const specialIssueTitleHasTooltipRef = useRef(null);
  const specialIssueTitleNoTooltipRef = useRef(null);

  const getMinActualPublicationDate = publicationList => {
    let minDate = getUTCToDate(publicationList[0].actualPublicationDate);
    for (let i = 1; i < publicationList.length; i++) {
      let newDate = getUTCToDate(publicationList[i].actualPublicationDate);
      if (!newDate) {
        continue;
      }
      if (!minDate || newDate < minDate) {
        minDate = newDate;
      }
    }
    return minDate;
  };

  const windowHeight = React.useContext(WindowHeightContext);
  return (
    <Box
      ref={ref}
      id='TableBox'
      data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-TableBox'
      sx={{
        ...sx,
        // border: '1px solid #DFE4E8',
        // borderRadius: '8px 8px 8px 8px',
        // marginRight: '4px',
      }}
    >
      <ChangeList
        data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-TableBox-ChangeList'
        showColumns={showColumns}
        setShowColumns={setShowColumns}
        changeListOpen={changeListOpen}
        setChangeListOpen={setChangeListOpen}
      />
      <Grid item md={3} alignItems='center' justifyContent='center'>
        {/* <span id="tableFooterSelectedText" style={{ fontSize: "small", textAlign: 'center' }}> */}
        <Stack
          direction='row'
          justifyContent='start'
          alignItems='center'
          spacing={1}
          sx={{ marginBottom: '6px' }}
        >
          <span
            style={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '18px',
              color: '#262E35',
            }}
          >
            {/* <FormattedMessage
                id='table.selectedText'
                values={{ num: selected.length }}
              /> */}
            <ShowForPermission permission={`${PERMISSIONS.SI_WRITE} ${PERMISSIONS.CHINA_MARKETING_WRITE}`}>
              Selected Item(s):
            </ShowForPermission>

          </span>
          <span
            style={{
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '18px',
              color: '#262E35',
            }}
          >
            <ShowForPermission permission={`${PERMISSIONS.SI_WRITE} ${PERMISSIONS.CHINA_MARKETING_WRITE}`}>
              {selected.length}
            </ShowForPermission>
          </span>
          {/*<SimpleLabel*/}
          {/*control={<SimpleCheckBox*/}
          {/*disabled={siCodeAllList==null || siCodeAllList.length==0}*/}
          {/*checked={siCodeAllList.length == selected.length}*/}
          {/*indeterminate={selected.length>0 && selected.length < siCodeAllList.length}*/}
          {/*/>}*/}
          {/*label='Choose All'*/}
          {/*onChange={ handleSelectAllPages }></SimpleLabel>*/}
          {/*<span>Total: {siCodeAllList.length}</span>*/}
        </Stack>
      </Grid>
      <TableBox2
        dataList={[siData, loading]}
        tableKey={'SIOverview'}
        footerHeight={0}
        containerType={TableContainer}
        containerSelector={
          '#SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer'
        }
        viewTag={'Table'}
        delay={300}
        sx={{
          border: '1px solid #DFE4E8',
          borderRadius: '8px 8px 0px 0px',
          overflowX: 'hidden',
          overflowY: 'hidden',
          position: 'relative',
          padding: 0,
          '& .scroll-bar-container-box': {
            right: '3px',
            width: '15px',
          },
        }}
      >
        <TableContainer
          ref={containerBoxRef}
          id='SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer'
          data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer'
          className='table-screen-size'
          sx={{
            overflowX: 'auto',
            overflowY: 'scroll',
            // borderRadius: '8px 8px 0px 0px',
            // width: siderOpen?'calc(100vw - 291px)':'calc(100vw - 134px)',
            width: siderOpen
              ? showFilter
                ? `calc(100vw - ${12 + 25 + 240 + 600}px)`
                : `calc(100vw - ${12 + 25 + 240 + 0}px)`
              : showFilter
                ? `calc(100vw - ${12 + 25 + 80 + 600}px)`
                : `calc(100vw - ${12 + 25 + 80 + 0}px)`,
            ...(siData.count !== 0 && {
              // table scroll bar
              height: `${windowHeight - 362}px`,
            }),
          }}
        >
          <Table
            id='table-issue-table'
            stickyHeader
            data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table'
            aria-label='simple table'
            sx={{
              letterSpacing: '0.01em',
              border: '1px solid #DFE4E8',
              minWidth: '100%',
              tableLayout: 'fixed',
              width: 'max-content',
              maxWidth: 'none',
              minHeight: 'fit-content',
              // color: '#262E35',
            }}
          >
            <MyTableHeader
              data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader'
              hasCheckbox={
                userPermissions.includes(PERMISSIONS.SI_EXPORT) ||
                userPermissions.includes(PERMISSIONS.CHINA_MARKETING_WRITE)
              } // width is defined here
              hasDatePassedFlags={
                userPermissions.includes(PERMISSIONS.SI_EXPORT) ||
                userPermissions.includes(PERMISSIONS.CHINA_MARKETING_WRITE)
              }
              order={orderByType.order}
              orderBy={orderByType.by}
              onRequestSort={handleRequestSort}
              items={newitems}
              showColumns={showColumns}
              handleSelectAll={handleSelectAll}
              handleUnselectAll={handleUnselectAll}
              backgroundColor='#F1F3F5'
              color='#596A7C'
              CheckboxColor='wileyBlue2'
              letterSpacing='0.08em'
              checkboxIcon={
                <SIPOverviewUncheckedIcon></SIPOverviewUncheckedIcon>
              }
              checkboxCheckedIcon={
                <SIPOverviewCheckedIcon></SIPOverviewCheckedIcon>
              }
              checkboxindeterminateIcon={<CATJournalIndeterminate />}
              hasCheckboxStick={
                userPermissions.includes(PERMISSIONS.SI_EXPORT) ||
                userPermissions.includes(PERMISSIONS.CHINA_MARKETING_WRITE)
              }
              ifLinefeed={true}
              ifIndeterminate={true}
              selected={selected.length}
              PaddingLeft={'0px'}
              allNum={siData.count}
            />
            {loading ? (
              <TableBody
                data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody1'
                sx={{ backgroundColor: 'fff' }}
              >
                <TableSkeleton num={9} colSpan={36} />
              </TableBody>
            ) : (
              <TableBody data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2'>
                {!loading &&
                  //(siData.result?.map((row, index) => {
                  siData.result?.length > 0 ? (
                  siData.result.map((row, index) => {
                    const isItemSelected = selected.indexOf(row.siCode) > -1;
                    const jumpDetailUrl = isCNmarketing
                      ? `/simt/auth/${marketing}/${siDetail}/${row.siCode}?from=overview`
                      : `/simt/auth/siRelated/si/detail/${row.siCode}?from=overview`;
                    return (
                      <HoverTableRow
                        key={row.siCode + '-' + index}
                        aria-checked={isItemSelected}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          'td': {
                            background: createSiResult?.includes(row.siCode) && '#ECFDF5'
                          },
                          '&:hover td': {
                            background: (() => {
                              if (createSiResult?.includes(row.siCode)) return '#ECFDF5';
                              if (row.alertGeFlagType === 'Flags-Severe Issue') return '#FFEFEF';
                              if (row.alertGeFlagType === 'Flags-Mild Issue') return '#FFF0DD';
                              return '#E8EDFB';
                            })()
                          },
                        }}
                        data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-${index}`}
                        //hover
                        selected={isItemSelected}
                      >
                        <ShowForPermission
                          permission={`${PERMISSIONS.SI_EXPORT} ${PERMISSIONS.CHINA_MARKETING_WRITE}`}
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-ShowForPermission-${index}`}
                        >
                          <NewStyledTableCell
                            data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-ShowForPermission-NewStyledTableCell-${index}`}
                            align='left'
                            show={true}
                            sticky={true}
                            stickyPX={0}
                            sx={{
                              paddingLeft: '15px !important',
                              paddingRight: '15px !important',
                            }}
                          >
                            <Checkbox
                              id={`siPageIssueTableBodyCheckbox${row.siCode}`}
                              data-selenium-id={`SIPage-IssueTableBodyCheckbox${row.siCode}`}
                              onClick={handleClick.bind(this, row)}
                              color='wileyBlue2'
                              checked={isItemSelected}
                              icon={
                                <SIPOverviewUncheckedIcon></SIPOverviewUncheckedIcon>
                              }
                              checkedIcon={
                                <SIPOverviewCheckedIcon></SIPOverviewCheckedIcon>
                              }
                              sx={{
                                height: '18px',
                                // width: '42px',
                                '&:hover': {
                                  bgcolor: 'transparent',
                                  '& g rect:last-child': {
                                    stroke: '#596A7C',
                                  },
                                },
                                '& .MuiTouchRipple-root': {
                                  position: 'unset',
                                },
                                '&.Mui-checked:hover': {
                                  rect: {
                                    fill: '#113D95',
                                    stroke: '#113D95',
                                  },
                                },
                              }}
                            />
                          </NewStyledTableCell>

                          <NewStyledTableCell
                            data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-Datepass-NewStyledTableCell-${index}`}
                            align='left'
                            show={true}
                            sticky={true}
                            stickyPX={66}
                          >
                            <Box
                              data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell1-Box-Box-${index}`}
                              sx={{
                                position: 'absolute',
                                zIndex: 10,
                                top: 'calc(50% + 4px)',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              {row.submissionDeadlinePassed ? (
                                <SubmissionDeadlinePassedAlert
                                  data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell1-Box-Box-SubmissionDeadlinePassedAlert'
                                  id='siPage-SubmissionDeadlinePassedAlert'
                                  sx={{ margin: 'auto 3.5px' }}
                                />
                              ) : (
                                <></>
                              )}
                              {row.expectedAcceptanceDatePassed ? (
                                <ExpectedAcceptanceDatePassedAlert
                                  data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell1-Box-Box-ExpectedAcceptanceDatePassedAlert'
                                  id='siPage-ExpectedAcceptanceDatePassedAlert'
                                  sx={{ margin: 'auto 3.5px' }}
                                />
                              ) : (
                                <></>
                              )}
                              {row.expectedPublicationDatePassed ? (
                                <PublicationDatePassedAlert
                                  data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell1-Box-Box-PublicationDatePassedAlert'
                                  id='siPage-PublicationDatePassedAlert'
                                  sx={{ margin: 'auto 3.5px' }}
                                />
                              ) : (
                                <></>
                              )}
                            </Box>
                          </NewStyledTableCell>
                        </ShowForPermission>

                        <NewStyledTableCell
                          show={showColumns[0]}
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell1-${index}`}
                          align='left'
                          sticky={true}
                          stickyPX={
                            userPermissions.includes(PERMISSIONS.SI_EXPORT) ||
                              userPermissions.includes(PERMISSIONS.CHINA_MARKETING_WRITE)
                              ? 130
                              : 0
                          }
                          sx={{
                            ...((row.alertGeFlagType === 'Flags-Severe Issue' ||
                              row.alertGeFlagType === 'Flags-Mild Issue') && {
                              pt: '0 !important',
                            }),
                            pl: '12px !important',
                            pr: '12px !important',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100%',
                            }}
                            data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell1-Box-${index}`}
                          >
                            {row.alertGeFlagType === 'Flags-Severe Issue' ||
                              row.alertGeFlagType === 'Flags-Mild Issue' ? (
                              <Box
                                sx={{
                                  boxSizing: 'border-box',
                                  height: '20px',
                                  width: '102px',
                                  p: '2px 12px',
                                  background:
                                    row.alertGeFlagType === 'Flags-Severe Issue'
                                      ? '#EE5350'
                                      : '#FFB152',
                                  textAlign: 'center',
                                  borderRadius: '0 0 4px 4px',
                                  color: '#fff',
                                  fontSize: '12px',
                                  fontFamily: 'Open Sans',
                                  fontWeight: 600,
                                  // ml: '12px',
                                  lineHeight: '16px',
                                  marginLeft: '8px', //modified
                                  marginRight: 'auto',
                                }}
                              >
                                {row.alertGeFlagType ===
                                  'Flags-Severe Issue' ? (
                                  <>SEVERE&nbsp;ISSUE</>
                                ) : (
                                  <>MILD&nbsp;ISSUE</>
                                )}
                              </Box>
                            ) : (
                              <></>
                            )}
                            <ReactLick
                              style={{
                                marginTop:
                                  row.alertGeFlagType ===
                                    'Flags-Severe Issue' ||
                                    row.alertGeFlagType === 'Flags-Mild Issue'
                                    ? '2px'
                                    : 0,
                                display: 'flex',
                                flexGrow: 1,
                                textDecoration: 'none',
                                color: '#154AB6',
                                alignItems: 'center',
                              }}
                              extra
                              id={`siCodeLink${row.siCode}`}
                              to={jumpDetailUrl}
                            >
                              <Box
                                data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell1-Box-Box-ReactLick-Box-${index}`}
                                sx={{
                                  fontWeight: 600,
                                  // paddingLeft: userPermissions.includes(
                                  //   PERMISSIONS.SI_WRITE
                                  // )
                                  //   ? '24px'
                                  //   : '64px',
                                }}
                              >
                                <Box
                                  data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell1-Box-Box-ReactLick-Box-${index}`}
                                  sx={{
                                    fontWeight: 600,
                                    // paddingLeft:
                                    //   userPermissions.includes(PERMISSIONS.SI_WRITE) ||
                                    //   userPermissions.includes(
                                    //     PERMISSIONS.CHINA_MARKETING_WRITE
                                    //   )
                                    //     ? '0px'
                                    //     : '40px',
                                  }}
                                >
                                  <div
                                    style={{
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      display: '-webkit-box',
                                      WebkitBoxOrient: 'vertical',
                                      WebkitLineClamp: 1,
                                    }}
                                  >
                                    <Typography>{row.siCode}</Typography>
                                  </div>
                                </Box>
                              </Box>
                            </ReactLick>
                          </Box>
                        </NewStyledTableCell>

                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell2-${index}`}
                          show={showColumns[1]}
                          align='left'
                        >
                          <div
                            id={`siPageIssueTableBodySIJournalCode${row.siCode}`}
                            data-selenium-id={`SIPage-IssueTableBodySIJournalCode${row.siCode}`}
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                              color: '#262E35',
                            }}
                          >
                            {row.journalInfoVo?.journalCode}
                          </div>
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell3-${index}`}
                          show={showColumns[2]}
                          align='left'
                        >
                          <MulLineTooltipIfShow
                            id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell6-Tooltip-${index}-journalName`}
                            outerRef={containerBoxRef}
                            line={2}
                            label={row.journalInfoVo?.journalName}
                            labelSx={{
                              color: '#262E35',
                            }}
                          />
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell4-${index}`}
                          show={showColumns[3]}
                          align='left'
                        >
                          <MulLineTooltipIfShow
                            id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell6-Tooltip-${index}-onlineISSN`}
                            outerRef={containerBoxRef}
                            line={1}
                            label={row.journalInfoVo?.onlineISSN}
                            labelSx={{
                              color: '#262E35',
                            }}
                          />
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell5-${index}`}
                          show={showColumns[4]}
                          align='left'
                        >
                          <MulLineTooltipIfShow
                            id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell6-Tooltip-${index}-specialIssueType`}
                            outerRef={containerBoxRef}
                            line={1}
                            label={row.specialIssueType}
                            labelSx={{
                              color: '#262E35',
                            }}
                          />
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell6-${index}`}
                          show={showColumns[5]}
                          align='left'
                        >
                          <MulLineTooltipIfShow
                            id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell6-Tooltip-${index}-specialIssueTitle`}
                            outerRef={containerBoxRef}
                            line={2}
                            label={row.specialIssueTitle}
                            labelSx={{
                              fontWeight: 600,
                            }}
                          >
                            <ReactLick
                              style={{
                                textDecoration: 'none',
                                color: '#154AB6',
                              }}
                              extra
                              id={`siCodeLink${row.siCode}`}
                              to={jumpDetailUrl}
                            >
                              <Typography>
                                {makeSpace(row.specialIssueTitle)}
                              </Typography>
                            </ReactLick>
                          </MulLineTooltipIfShow>
                        </NewStyledTableCell>

                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell7-${index}`}
                          show={showColumns[6]}
                          align='left'
                        >
                          <Box
                            id={`siPageIssueTableBodyLeadGE${row.siCode}`}
                            data-selenium-id={`SIPage-IssueTableBodyLeadGE${row.siCode}`}
                            sx={{ display: 'flex' }}
                            ref={ref => (leadGeRef.current[index] = ref)}
                          >
                            <Tooltip
                              placement='top-start'
                              open={false}
                              data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell7-Box-Tooltip-${index}`}
                              title={getGEShowString(row.leadGeLiteVoList)}
                              disableInteractive
                            >
                              <div
                                data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell7-Box-Tooltip-ContainerDiv-${index}`}
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  WebkitLineClamp: 1,
                                  color: '#262E35',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* {getGEShowString(row.leadGeLiteVoList,true)} */}
                                {row.leadGeLiteVoList
                                  .map(ge => ge?.fullName)
                                  .join(';')}
                              </div>
                            </Tooltip>
                            {row.leadGeLiteVoList !== null &&
                              row.leadGeLiteVoList !== undefined &&
                              row.leadGeLiteVoList.length > 0 && (
                                <GeMenuIcon
                                  id={'lead' + row.siCode}
                                  blueIsLeft={false}
                                  data-selenium-id={'lead' + row.siCode}
                                  geList={row.leadGeLiteVoList}
                                  anchorRef={leadGeRef.current[index]}
                                  NoHref={!userPermissions.includes(PERMISSIONS.GE_READ)}
                                />
                              )}
                          </Box>
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell8-${index}`}
                          show={showColumns[7]}
                          align='left'
                        >
                          <Box
                            id={`siPageIssueTableBodyCoGE${row.siCode}`}
                            data-selenium-id={`SIPage-IssueTableBodyCoGE${row.siCode}`}
                            sx={{ display: 'flex' }}
                            ref={ref => (coGeRef.current[index] = ref)}
                          >
                            <Tooltip
                              open={false}
                              placement='top-start'
                              data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell8-Box-Tooltip-${index}`}
                              title={getGEShowString(row.coGeLiteVoList)}
                              disableInteractive
                            >
                              <div
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  display: '-webkit-box',
                                  WebkitBoxOrient: 'vertical',
                                  WebkitLineClamp: 1,
                                  color: '#262E35',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {/* {getGEShowString(row.coGeLiteVoList)} */}
                                {row.coGeLiteVoList
                                  .map(ge => ge?.fullName)
                                  .join(';')}
                              </div>
                            </Tooltip>
                            {row.coGeLiteVoList !== null &&
                              row.coGeLiteVoList !== undefined &&
                              row.coGeLiteVoList.length > 0 && (
                                <GeMenuIcon
                                  id={'co' + row.siCode}
                                  blueIsLeft={false}
                                  data-selenium-id={'co' + row.siCode}
                                  geList={row.coGeLiteVoList}
                                  anchorRef={coGeRef.current[index]}
                                  NoHref={!userPermissions.includes(PERMISSIONS.GE_READ)}
                                />
                              )}
                          </Box>
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell9-${index}`}
                          show={showColumns[8]}
                          align='left'
                        >
                          <MulLineTooltipIfShow
                            id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell6-Tooltip-${index}-stage`}
                            outerRef={containerBoxRef}
                            line={1}
                            label={row.stage}
                            labelSx={{
                              color: '#262E35',
                            }}
                          />
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell10-${index}`}
                          show={showColumns[9]}
                          align='left'
                        >
                          <MulLineTooltipIfShow
                            id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell6-Tooltip-${index}-siUserName`}
                            outerRef={containerBoxRef}
                            line={1}
                            label={row.handlingCe?.email}
                            alwaysShow={true}
                            labelSx={{
                              color: '#262E35',
                            }}
                          >
                            {row.handlingCe?.siUserName}
                          </MulLineTooltipIfShow>
                        </NewStyledTableCell>

                        <NewStyledTableCell
                          show={showColumns[10]}
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell11-${index}`}
                          align='left'
                        >
                          <MulLineTooltipIfShow
                            id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell6-Tooltip-${index}-group`}
                            outerRef={containerBoxRef}
                            line={1}
                            label={row.handlingCe?.group}
                            labelSx={{
                              color: '#262E35',
                            }}
                          />
                        </NewStyledTableCell>

                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell12-${index}`}
                          show={showColumns[11]}
                          align='left'
                        >
                          <MulLineTooltipIfShow
                            id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell6-Tooltip-${index}-emailOfPdp`}
                            outerRef={containerBoxRef}
                            line={1}
                            label={row.journalInfoVo?.emailOfPdp}
                            alwaysShow={true}
                            labelSx={{
                              color: '#262E35',
                            }}
                          >
                            {row.journalInfoVo?.pdp}
                          </MulLineTooltipIfShow>
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell12-${index}`}
                          show={showColumns[12]}
                          align='left'
                        >
                          <MulLineTooltipIfShow
                            id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell6-Tooltip-${index}-emailOfPpp`}
                            outerRef={containerBoxRef}
                            line={1}
                            label={row.journalInfoVo?.emailOfPpp}
                            alwaysShow={true}
                            labelSx={{
                              color: '#262E35',
                            }}
                          >
                            {row.journalInfoVo?.ppp}
                          </MulLineTooltipIfShow>
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          show={showColumns[13]}
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell13-${index}`}
                          align='left'
                        >
                          <MulLineTooltipIfShow
                            id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell6-Tooltip-${index}-subjectGroup`}
                            outerRef={containerBoxRef}
                            line={2}
                            label={row.journalInfoVo?.subjectGroup}
                            labelSx={{
                              color: '#262E35',
                            }}
                          >
                            {changeLine(row.journalInfoVo?.subjectGroup)}
                          </MulLineTooltipIfShow>
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell14-${index}`}
                          show={showColumns[14]}
                          align='left'
                        >
                          <MulLineTooltipIfShow
                            id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell6-Tooltip-${index}-siSource`}
                            outerRef={containerBoxRef}
                            line={2}
                            label={row.siSource}
                            labelSx={{
                              color: '#262E35',
                            }}
                          />
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell15-${index}`}
                          show={showColumns[15]}
                          align='left'
                        >
                          <div
                            id={`siPageIssueTableBodyIdeaDate${row.siCode}`}
                            data-selenium-id={`SIPage-IssueTableBodyIdeaDate${row.siCode}`}
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                              color: '#262E35',
                            }}
                          >
                            {formatDateString(getUTCToDate(row.ideaDate))}
                          </div>
                        </NewStyledTableCell>

                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell16-${index}`}
                          show={showColumns[16]}
                          align='left'
                        >
                          <div
                            id={`siPageIssueTableBodyAcquiredDate${row.siCode}`}
                            data-selenium-id={`SIPage-IssueTableBodyAcquiredDate${row.siCode}`}
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                              color: '#262E35',
                            }}
                          >
                            {formatDateString(getUTCToDate(row.acquiredDate))}
                          </div>
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell17-${index}`}
                          show={showColumns[17]}
                          align='left'
                        >
                          <div
                            id={`siPageIssueTableBodyOpenForSubmissionDate${row.siCode}`}
                            data-selenium-id={`SIPage-IssueTableBodyOpenForSubmissionDate${row.siCode}`}
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                              color: '#262E35',
                            }}
                          >
                            {formatDateString(
                              getUTCToDate(row.openForSubmissionDate)
                            )}
                          </div>
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell18-${index}`}
                          show={showColumns[18]}
                          align='left'
                        >
                          <div
                            id={`siPageIssueTableBodySubmissionDeadline${row.siCode}`}
                            data-selenium-id={`SIPage-IssueTableBodySubmissionDeadline${row.siCode}`}
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                              color: '#262E35',
                            }}
                          >
                            {formatDateString(
                              getUTCToDate(row.expectedSubmissionDeadline)
                            )}
                          </div>
                        </NewStyledTableCell>

                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell19-${index}`}
                          show={showColumns[19]}
                          align='left'
                        >
                          <div
                            id={`siPageIssueTableBodyExpectedAcceptanceDate${row.siCode}`}
                            data-selenium-id={`SIPage-IssueTableBodyExpectedAcceptanceDate${row.siCode}`}
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                              color: '#262E35',
                            }}
                          >
                            {formatDateString(
                              getUTCToDate(row.expectedAcceptanceDate)
                            )}
                          </div>
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell19-${index}`}
                          show={showColumns[20]}
                          align='left'
                        >
                          <div
                            id={`siPageIssueTableBodyExpectedPublicationDate${row.siCode}`}
                            data-selenium-id={`SIPage-IssueTableBodyExpectedPublicationDate${row.siCode}`}
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                              color: '#262E35',
                            }}
                          >
                            {formatDateString(
                              getUTCToDate(row.expectedPublicationDate)
                            )}
                          </div>
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell20-${index}`}
                          show={showColumns[21]}
                          align='left'
                        >
                          <div
                            id={`siPageIssueTableBodyDeadlinePassed${row.siCode}`}
                            data-selenium-id={`SIPage-IssueTableBodyDeadlinePassed${row.siCode}`}
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                              color: '#262E35',
                            }}
                          >
                            {row.deadlinePassed}
                          </div>
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell21-${index}`}
                          show={showColumns[22]}
                          align='left'
                        >
                          <div
                            id={`siPageIssueTableBodyCloseForSubmission${row.siCode}`}
                            data-selenium-id={`SIPage-IssueTableBodyCloseForSubmission${row.siCode}`}
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 2,
                              color: '#262E35',
                            }}
                          >
                            {row.closedForSubmission}
                          </div>
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell22-${index}`}
                          show={showColumns[23]}
                          align='left'
                        >
                          <MulLineTooltipIfShow
                            id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell6-Tooltip-${index}-paperCommissioningMethod`}
                            outerRef={containerBoxRef}
                            line={1}
                            label={row.paperCommissioningMethod}
                            labelSx={{
                              color: '#262E35',
                            }}
                          />
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell23-${index}`}
                          show={showColumns[24]}
                          align='left'
                        >
                          <MulLineTooltipIfShow
                            id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell6-Tooltip-${index}-workflow`}
                            outerRef={containerBoxRef}
                            line={2}
                            label={row.workflow}
                            labelSx={{
                              color: '#262E35',
                            }}
                          />
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell24-${index}`}
                          show={showColumns[25]}
                          align='left'
                        >
                          <MulLineTooltipIfShow
                            id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell6-Tooltip-${index}-previousHandlingCeList`}
                            outerRef={containerBoxRef}
                            line={1}
                            alwaysShow={true}
                            label={row.previousHandlingCeList?.[0]?.email ?? ''}
                            labelSx={{
                              color: '#262E35',
                            }}
                          >
                            {row.previousHandlingCeList?.[0]?.siUserName ?? ''}
                          </MulLineTooltipIfShow>
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          show={showColumns[26]}
                          align='left'
                          id={`siPageIssueTableBodyRecruited${row.siCode}`}
                          data-selenium-id={`SIPage-IssueTableBodyRecruited${row.siCode}`}
                          data={numberHandling(row.numberOfRecruitedArticles)}
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            color: '#262E35',
                          }}
                        />
                        <NewStyledTableCell
                          show={showColumns[27]}
                          align='left'
                          data={numberHandling(row.numberOfSubmittedArticles)}
                          id={`siPageIssueTableBodySubmitted${row.siCode}`}
                          data-selenium-id={`SIPage-IssueTableBodySubmitted${row.siCode}`}
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            color: '#262E35',
                          }}
                        />
                        <NewStyledTableCell
                          id={`siPageIssueTableBodyAccepted${row.siCode}`}
                          data-selenium-id={`SIPage-IssueTableBodyAccepted${row.siCode}`}
                          show={showColumns[28]}
                          align='left'
                          data={numberHandling(row.numberOfAcceptedArticles)}
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            color: '#262E35',
                          }}
                        />
                        <NewStyledTableCell
                          show={showColumns[29]}
                          align='left'
                          data={numberHandling(row.numberOfRejectedArticles)}
                          id={`siPageIssueTableBodyRejected${row.siCode}`}
                          data-selenium-id={`SIPage-IssueTableBodyRejected${row.siCode}`}
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            color: '#262E35',
                          }}
                        />
                        <NewStyledTableCell
                          show={showColumns[30]}
                          align='left'
                          data={numberHandling(row.numberOfArticlesInProgress)}
                          id={`siPageIssueTableBodyInProgress${row.siCode}`}
                          data-selenium-id={`SIPage-IssueTableBodyInProgress${row.siCode}`}
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            color: '#262E35',
                          }}
                        />
                        <NewStyledTableCell
                          show={showColumns[31]}
                          align='left'
                          id={`siPageIssueTableBodyVolume${row.siCode}`}
                          data-selenium-id={`SIPage-IssueTableBodyVolume${row.siCode}`}
                          data={row.publicationDetailVoList[0]?.volume}
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            color: '#262E35',
                          }}
                        ></NewStyledTableCell>
                        <NewStyledTableCell show={showColumns[32]} align='left'>
                          <div
                            id={`siPageIssueTableBodyIssue${row.siCode}`}
                            data-selenium-id={`SIPage-IssueTableBodyIssue${row.siCode}`}
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                              color: '#262E35',
                            }}
                          >
                            {row.publicationDetailVoList[0]?.issue}
                          </div>
                        </NewStyledTableCell>

                        <NewStyledTableCell show={showColumns[33]} align='left'>
                          <div
                            id={`siPageIssueTableBodyActualPublicationDate${row.siCode}`}
                            data-selenium-id={`SIPage-IssueTableBodyActualPublicationDate${row.siCode}`}
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                              color: '#262E35',
                            }}
                          >
                            {row.publicationDetailVoList[0]
                              ? formatDateString(
                                getMinActualPublicationDate(
                                  row.publicationDetailVoList
                                )
                              )
                              : ''}
                          </div>
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell25-${index}`}
                          show={showColumns[34]}
                          align='left'
                        >
                          <div
                            id={`siPageIssueTableBodyNumberOfOOArticle${row.siCode}`}
                            data-selenium-id={`SIPage-IssueTableBodyNumberOfOOArticle${row.siCode}`}
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                              color: '#262E35',
                            }}
                          >
                            {row.publicationDetailVoList[0]
                              ? numberHandling(
                                row.publicationDetailVoList[0]
                                  .numberOfOoArticle
                              )
                              : ''}
                          </div>
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          data-selenium-id={`SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Table-MyTableHeader-TableBody2-TableRow-NewStyledTableCell26-${index}`}
                          show={showColumns[35]}
                          align='left'
                        >
                          <div
                            id={`siPageIssueTableBodyNumberOfOAArticle${row.siCode}`}
                            data-selenium-id={`SIPage-IssueTableBodyNumberOfOAArticle${row.siCode}`}
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 1,
                              color: '#262E35',
                            }}
                          >
                            {row.publicationDetailVoList[0]
                              ? numberHandling(
                                row.publicationDetailVoList[0]
                                  .numberOfOaArticle
                              )
                              : ''}
                          </div>
                        </NewStyledTableCell>
                        <NewStyledTableCell
                          show={showColumns[36]}
                          align='left'
                          id={`siPageIssueTableBodyOutputRate${row.siCode}`}
                          data-selenium-id={`SIPage-IssueTableBodyOutputRate${row.siCode}`}
                          data={numberHandling(row.outputRate)}
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            color: '#262E35',
                          }}
                        />
                        <NewStyledTableCell
                          show={showColumns[37]}
                          align='left'
                          id={`siPageIssueTableBodyCreateDate${row.siCode}`}
                          data-selenium-id={`SIPage-IssueTableBodyCreateDate${row.siCode}`}
                          data={formatDateString(row.createTime)}
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            color: '#262E35',
                          }}
                        />
                        <NewStyledTableCell
                          show={showColumns[38]}
                          align='left'
                          id={`siPageIssueTableBodyLastActionDate${row.siCode}`}
                          data-selenium-id={`SIPage-IssueTableBodyLastActionDate${row.siCode}`}
                          data={formatDateString(row.lastActionDate)}
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            color: '#262E35',
                          }}
                        />
                      </HoverTableRow>
                    );
                  }) // ??
                ) : //'No data to show')}
                  siData.result !== undefined && siData.result !== null ? (
                    <NoDataMessage
                      id='NoDataMessageAfterFiliter'
                      MarginTop={'5px'}
                      data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-NoDataMessageAfterFiliter'
                      message={
                        'No results were found. Please adjust your filters and try again.'
                      }
                    ></NoDataMessage>
                  ) : (
                    <NoDataMessage
                      id='NoDataMessageOfAll'
                      MarginTop={'5px'}
                      data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-NoDataMessageOfAllFiliter'
                      message={'No data to show'}
                    ></NoDataMessage>
                  )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </TableBox2>
      <Box
        data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Box'
        sx={{ borderRadius: '0px 0px 8px 8px' }}
      >
        <Divider
          data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Box-Divider'
        // sx={{ width: 'calc(100% - 3px)', }}
        />
        {siData.count === 0 ? (
          <Box
            display='flex'
            sx={{
              background: '#f1f3f5',
              pl: 0,
              pr: 0,
              width: 'calc(100% - 3px)',
              borderRadius: '0px 0px 8px 8px',
              border: '1px solid #DFE4E8',
              borderTop: 'none',
              height: '32px',
            }}
            paddingY='3px'
          ></Box>
        ) : (
          <TableFooter2
            data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-TableBox-TableContainer-Box-TableFooter2'
            numSelected={selected.length}
            Page={page}
            perPageNumber={perPageNumber}
            perPageList={[20, 50, 100]}
            count={siData.count ?? 0}
            handlePerPageChange={handlePerPageChange}
            handlePageChange={handlePageChange}
            backgroundColor='#f1f3f5'
            width='100%'
          />
        )}
      </Box>
    </Box>
  );
});

export default IssueTable;

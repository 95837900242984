import { axios_instance } from "@/utils/axios_instance";
import { id } from "date-fns/locale";
import { Dispatch } from "react";
import {FormData, GE, SectionState} from "@/pages/SIPForm/types";
import {getConnectPayload} from "@/utils/authTokenHandler";
import MyLocalStorage from "@/utils/MyLocalStorage";
import moment from "moment";

import {option1, option2} from "@/pages/SIPForm/subpages/Proposal/ConflictOfIntersetSection";

export const LOAD_CURRENT_USER = 'LOAD_CURRENT_USER';
export const SET_SIP_FORM_VALUE = 'SET_SIP_FORM_VALUE';
export const SET_SIP_FORM_GELIST_VALUE = 'SET_SIP_FORM_GELIST_VALUE';
export const SET_SIP_FORM_AUTHOR_LIST_VALUE = 'SET_SIP_FORM_AUTHOR_LIST_VALUE';

export const ADD_SIP_FORM_GE = 'ADD_SIP_FORM_GE';
export const DEL_SIP_FORM_GE = 'DEL_SIP_FORM_GE';
export const ADD_SIP_FORM_AUTHOR = 'ADD_SIP_FORM_AUTHOR';
export const DEL_SIP_FORM_AUTHOR = 'DEL_SIP_FORM_AUTHOR';

export const SET_SIP_FORM_PROCEED_STATE = 'SET_SIP_FORM_PROCEED_STATE';
export const SET_SIP_FORM_SECTION_STATE = 'SET_SIP_FORM_SECTION_STATE';
export const SET_SIP_FORM_OPTIONS = 'SET_SIP_FORM_OPTIONS';

export const SET_SIP_FORM_DATA = 'SET_SIP_FORM_DATA';
export const SUBMIT_SIP_FORM = 'SUBMIT_SIP_FORM';

export const SET_SIP_FORM_ERROR = 'SET_SIP_FORM_ERROR';
export const CLEAR_SIP_FORM_ERROR = 'CLEAR_SIP_FORM_ERROR';

const fakeToken = 'eyJraWQiOiJqd3QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJmOTlmNmZiOC00NzE0LTQ4MmEtOWI1Ny00OGVkMDRlY2ZiZmUiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYWRkcmVzcyI6eyJjb3VudHJ5IjoiQ2hpbmEiLCJjb3VudHJ5X2NvZGUiOiJDTiJ9LCJpc1BJUExDb25zZW50ZWQiOnRydWUsImVtYWlsX3R5cGUiOiJFTUFJTCIsImlzTWFya2V0YWJsZSI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly93aWxleS1zdGFnLmNvbm5lY3RzY2llbmNlLmlvIiwidHlwZSI6InJlZ2lzdGVyZWQiLCJnaXZlbl9uYW1lIjoieXV4aW4iLCJsb2NhbGUiOiJlbiIsIm5vbmNlIjoiclBSL0Nob1VNMkNRTDhYVlQ5b1dUanMzWkxGTzdpS1dLOElnUTNFQi8gOD0iLCJzaWQiOiIyNGM0N2ZhYy05MTZkLTRmNmQtYmQ1OS0wNGZiYTgwNmU3YzYiLCJlbWFpbHMiOlt7InZlcmlmaWVkRGF0ZSI6MTcxNzQ5MjEyNjQ0NywidmVyaWZpZWQiOnRydWUsInZhbHVlIjoid2FuZ3l1eGluQG1haWxpbmF0b3IuY29tIn1dLCJhdWQiOiJzaW10IiwibmFtZSI6Inl1eGluIHdhbmciLCJleHAiOjE3MjAwODUwOTcsImZhbWlseV9uYW1lIjoid2FuZyIsImlhdCI6MTcxNzQ5MzA5NywiZW1haWwiOiJ3YW5neXV4aW5AbWFpbGluYXRvci5jb20ifQ.hrO0DcdmvyDvwkXVCwtnUZyOtl0e8SziQ6eAfRIWgVYn-AT1zsR5aDR94oiSz5Ly04zQZFi9sb00y_GzcVhbTanrI4JZN6wTwykn9Y28vzcoAOC9m53f5o4rMSIWfHiG-hCd81qsPJSb68Ai3WC0U7UvvDGLtmyEyqJJWYPSZ_e_TLTKKEL_rgMergiuyUEpk-B10NEhnaMoW3Kju-PD5sGHEWvAAJfHpG9BLCTPhAYBQdL1GQIjUFeqjpK3OURp7F1EIDfyX_2JNoT-ITxy0Yt8ssNgAEBfS89IEIIhX4k4Z1RZEUNQtzbJwlodMAQXuFlAAx0sfyTR7Ek5u2jo3g';
const JWT_SIZE = 3;

export const loadCurrentUser = (id_token?: string, state?: string) => {
    const payload = getConnectPayload(id_token ?? localStorage.getItem('connect-authorization') ?? localStorage.getItem('authorization'));
    const email: string | undefined = payload?.email;
    let submitToken:string;
    if (!email) {
        submitToken = fakeToken;
    } else {
        submitToken = id_token ?? localStorage.getItem('connect-authorization') ?? localStorage.getItem('authorization') ?? fakeToken;
    }

    return async (dispatch: Dispatch<any>) => {
        try {
            const res = await axios_instance.get('/api/v1/user-service/users/current',{
                headers: {
                    'Content-Type': 'application/json',
                    authorization: submitToken,
                    'X-SIMT-From': 'SIP-Form',
                },
            });

            const authorization = res.headers.authorization;
            if (authorization) {
                const jwtArray = authorization.split("\\.");
                if (jwtArray.length == JWT_SIZE) {
                    const jwtHeader = JSON.parse(atob(jwtArray[0]));
                    if (jwtHeader?.alg === 'RS512') {
                        localStorage.setItem('connect-authorization', authorization);
                    } else {
                        const jwtPayload = JSON.parse(atob(jwtArray[1]));
                        if (!(jwtPayload?.authorities ?? []).contains("lead-GE")) {
                            return loadCurrentUser(fakeToken);
                        }
                    }
                }
            }

            if (res.data.code === 200) {
                dispatch({ type: LOAD_CURRENT_USER, data: res.data.data });
            } else {
                dispatch({ type: LOAD_CURRENT_USER, data: null });
            }
        } catch (err) {
            dispatch({ type: LOAD_CURRENT_USER, data: null });
        }
    };
};

const mapGeToObj = (ge: GE, option: string, addtion: string|undefined) => ({
    firstName: ge.firstName,
    lastName: ge.lastName,
    jobTitle: ge.jobTitle,
    email: ge.email,
    isLead: ge.isLead==='Yes'?1:0,
    department: '',
    institution: ge.institution,
    city: '',
    country: ge.country?.alpha3Code,
    orcidId: ge.orcidID,
    researchGateId: '',
    website: ge.website,
    cv: '',
    hasConflict: option===option2 ? 1 : 0,
    conflictDetail: addtion ?? '',
});

export function submitSIPForm(id_token: string, data: FormData) {
    const email: string | undefined = getConnectPayload(id_token)?.email;

    if (!email) {
        return loadCurrentUser(fakeToken);
    }

    MyLocalStorage.addGroupItem("SIP-Form", email, data, moment(new Date()).add(1, 'days').toDate());
    const submitData = {
        subject: data.specialIssueInformation.data.subject,
        journals: data.specialIssueInformation.data.journals.map((journal: any) => journal.journalCode),
        proposedTitle: data.specialIssueInformation.data.proposedTitle,
        aimsAndScope: data.specialIssueInformation.data.aimsAndScope,
        topics: data.specialIssueInformation.data.topics,
        keywords: [],
        references: [],
        isOpenCall: -1,
        fullManuscriptSubmissionDeadline: Date.parse(data.proposedDates.data.fullManuscriptSubmissionDeadline),
        acceptanceDeadline: Date.parse(data.proposedDates.data.acceptanceDeadline),
        expectedPublicationDate: Date.parse(data.proposedDates.data.expectedPublicationDate),

        leadGuestEditors: data.guestEditorInformation.data.GEList.filter(ge => ge.isLead === 'Yes')
            .map(ge => mapGeToObj(ge, data.conflictOfInterest.data.conflictOfInterest || '', data.conflictOfInterest.data.additionalInformation)),
        coGuestEditors: data.guestEditorInformation.data.GEList.filter(ge => ge.isLead === 'No')
            .map(ge => mapGeToObj(ge, data.conflictOfInterest.data.conflictOfInterest || '', data.conflictOfInterest.data.additionalInformation)),

        potentialAuthors: data.potentialAuthors.data.potentialAuthors
            .filter((author) => author.name || author.email || author.affilication || author.topic)
            .map((author) => ({
                name: author.name,
                email: author.email,
                affiliation: author.affilication,
                topic: author.topic,
        })),
        notesForEditors: '',
        consent1: data.statements.data.consent1,
        consent2: data.statements.data.consent2,
        consent3: data.statements.data.consent3,
    };
    return async (dispatch: any) => {
        try {
            const res = await axios_instance.post('/api/v1/sip-service/web-form', submitData, {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: id_token ?? localStorage.getItem('connect-authorization') ?? localStorage.getItem('authorization') ?? fakeToken,
                    'X-SIMT-From': 'SIP-Form',
                },
            });
            if (res.data.code === 200) {
                MyLocalStorage.removeGroupItem("SIP-Form", email);
                location.href = '/sip-web-form/thank-you';
            } else {
                dispatch({type: SET_SIP_FORM_ERROR, data: res.data?.message ?? null});
            }
        } catch (err) {
            dispatch({type: SET_SIP_FORM_ERROR, data: null});
        }
    };
}

export const clearSIPFormError = () => {
    return {
        type: CLEAR_SIP_FORM_ERROR,
    };
};

export const setSIPFormData = (data: FormData) => {
    return {
        type: SET_SIP_FORM_DATA,
        data,
    };
};

export const setSIPFormValue = (section: string, key: string, value: any) => {
    return {
        type: SET_SIP_FORM_VALUE,
        data: {
            section,
            key,
            value,
        },
    };
};

export const setSIPFormGEListValue = (section: string, index: number, key: string, value: any) => {
    return {
        type: SET_SIP_FORM_GELIST_VALUE,
        data: {
            section,
            key,
            index,
            value,
        },
    };
};

export const setSIPFormAuthorListValue = (section: string, index: number, key: string, value: any) => {
    return {
        type: SET_SIP_FORM_AUTHOR_LIST_VALUE,
        data: {
            section,
            key,
            index,
            value,
        },
    };
};

export const setSIPFormProceedState = (state: "initial" | "proceeding") => {
    return {
        type: SET_SIP_FORM_PROCEED_STATE,
        data: state,
    };
};

export const setSIPFormSectionState = (section: string, state: SectionState) => {
    return {
        type: SET_SIP_FORM_SECTION_STATE,
        data: {
            section,
            state,
        },
    };
};

export const addSIPFormGE = () => {
    return {
        type: ADD_SIP_FORM_GE,
    };
};

export const delSIPFormGE = (index: number) => {
    return {
        type: DEL_SIP_FORM_GE,
        data: index,
    };
};

export const addSIPFormAuthor = () => {
    return {
        type: ADD_SIP_FORM_AUTHOR,
    };
};

export const delSIPFormAuthor = (index: number) => {
    return {
        type: DEL_SIP_FORM_AUTHOR,
        data: index
    };
};

export const setSIPFormOptions = (key: string, options: any) => {
    return {
        type: SET_SIP_FORM_OPTIONS,
        data: {
            key,
            options,
        },
    };
};

export const loadSubjectByName = (id_token: string, text: string) => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = await axios_instance.get('/api/v1/sip-service/web-form/subjects',{
                headers: {
                    'Content-Type': 'application/json',
                    authorization: id_token ?? localStorage.getItem('connect-authorization') ?? localStorage.getItem('authorization') ?? fakeToken,
                    'X-SIMT-From': 'SIP-Form',
                },
                params: {
                    name: text,
                },
            });

            if (res.data.code === 200) {
                dispatch(setSIPFormOptions('subjectArea', res.data.data));
            } else {
                // dispatch({ type: LOAD_CURRENT_USER, data: null });
            }
        } catch (err) {
            // dispatch({ type: LOAD_CURRENT_USER, data: null });
        }
    };
};

// Improvement: add debounce
// Problem: the result would be the last responded request result
export const loadJournalByName = (id_token: string, text: string) => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = await axios_instance.get('/api/v1/si-service/journals',{
                headers: {
                    'Content-Type': 'application/json',
                    authorization: id_token ?? localStorage.getItem('connect-authorization') ?? localStorage.getItem('authorization') ?? fakeToken,
                    'X-SIMT-From': 'SIP-Form',
                },
                params: {
                    "type": "JOURNAL",
                    "journalName": text,
                    "pageNum": 1,
                    // "pageSize": 10,
                    "pageSize": 200,
                    "order": "asc",
                    "orderBy": "journalName"
                },
            });

            if (res.data.code === 200) {
                dispatch(setSIPFormOptions('journal', res.data.data));
            } else {
                // dispatch({ type: LOAD_CURRENT_USER, data: null });
            }
        } catch (err) {
            // dispatch({ type: LOAD_CURRENT_USER, data: null });
        }
    };
};

export const loadCountryByName = (id_token: string, text: string) => {
    return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = await axios_instance.get('/api/v1/si-service/country',{
                headers: {
                    'Content-Type': 'application/json',
                    authorization: id_token ?? localStorage.getItem('connect-authorization') ?? localStorage.getItem('authorization') ?? fakeToken,
                    'X-SIMT-From': 'SIP-Form',
                },
                params: {
                    country: text,
                },
            });

            if (res.data.code === 200) {
                dispatch(setSIPFormOptions('country', res.data.data));
            } else {
                // dispatch({ type: LOAD_CURRENT_USER, data: null });
            }
        } catch (err) {
            // dispatch({ type: LOAD_CURRENT_USER, data: null });
        }
    };
};

export const loadJobTitle = (id_token: string) => {
   return async (dispatch: React.Dispatch<any>) => {
        try {
            const res = await axios_instance.get('api/v1/user-service/configs',{
                headers: {
                    'Content-Type': 'application/json',
                    authorization: id_token ?? localStorage.getItem('connect-authorization') ?? localStorage.getItem('authorization') ?? fakeToken,
                    'X-SIMT-From': 'SIP-Form',
                },
                params: {
                    "type": "GE Title",
                }
            });

            if (res.data.code === 200) {
                dispatch(setSIPFormOptions('jobTitle', (res.data.data || []).map((item: any) => item?.value)));
            } else {
                // dispatch({ type: LOAD_CURRENT_USER, data: null });
            }
        } catch (err) {
            // dispatch({ type: LOAD_CURRENT_USER, data: null });
        }
   };
};
// @ts-check
import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect } from "react";
// @ts-ignore
import GlowBackground from '@/assets/SIPPage/ThankYouPage/Background/glow-background.svg';
import SubmitCompletedPage, {textStyle} from './SubmitCompletedPage';

/**
 *
 * @param {import("./InitialReviewRevisionCancelPage").InitialReviewRevisionCancelProps} props
 * @returns
 */
const InitialReviewRevisionCancelPage = props => {
  const {
    showCreateProposalButton = true,
    handleCreateProposal = () => { },
    theme = 'blue', // 三种主题颜色可选：blue, green, light
    detailText = <></>,
    isSSIP = false,
    ...others
  } = props;

  const matches900 = useMediaQuery('(min-width:900px)');
  const matches425 = useMediaQuery('(min-width:425px)');

  useEffect(() => {
    document.body.classList.add('hide-snackbar');
    return () => {
      document.body.classList.remove('hide-snackbar');
    };
  }, []);

  return (
    <SubmitCompletedPage theme={theme} {...others}>
      <Stack
        justifyContent={'center'}
        sx={{ 
          height: '100%', 
          background: "#FAFAFA",
        }}
        data-selenium-id='SIP_SubmitComplete-ProposalShelvedPage-Stack'
        >
        <Stack
          sx={{ 
            ...textStyle[theme],
            textAlign: 'center', 
            display: 'flex',
            justifyContent: "flex-start",
            background: "white",
            margin: "20px 68px auto",
            paddingTop: "72px", 
            height: "calc(100% - 60px)",
            alignItems: 'center', 
            lineHeight: "125%",
            letterSpacing: "0.48px"
          }}
          spacing='24px'
          data-selenium-id='SIP_SubmitComplete-ProposalShelvedPage-TypographyStack'
        >
          <Typography
            data-selenium-id='SIP_SubmitComplete-ProposalShelvedPage-TitleTypography'
            sx={{ 
              fontSize: '48px' ? '56px' : '44px', 
              fontWeight: 800, 
              color: '#596A7C', 
              maxWidth: '700px',
            }}
          >
            Oops! It looks like this link has expired.
          </Typography>
          <Typography
            data-selenium-id='SIP_SubmitComplete-ProposalShelvedPage-ApologizeTypography'
            sx={{
              fontSize: '22px',
              fontWeight: 400,
              color: '#BCC5CF',
              '& span': {
                fontWeight: 600,
              },
            }}
          >
            This may be because the revision deadline has expired or the
            <br />
            revision is no longer needed.
          </Typography>
          {/* glowing background */}
          <GlowBackground />
          <Box
            data-selenium-id='SIP_DetailPage_Log-EmailContent-Dialog-Actions-Box'
            sx={{
              float: 'right',
              background: '#154AB6',
              color: '#FFFFFF',
              width: '400px',
              height: '40px',
              borderRadius: '3px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '18px',
              margin: 'auto',
              padding: '11px 10px',
            }}
            onClick={() => window.close()}
          >
            CLOSE THIS PAGE
          </Box>
        </Stack>
      </Stack>
    </SubmitCompletedPage>
  );
};

export default InitialReviewRevisionCancelPage;

import * as actions from '../actions/CommonAction';

export const defaultScrollContext = {
  contextWidth: 0,
  contextHeight: 0,
  boxWidth: 0,
  boxHeight: 0,
};

const initState = {
  siderValue: 0,
  rightLink: 'https://www.wiley.com/en-us/permissions',
  copyrightLink: 'https://www.wiley.com/en-us/copyright',
  termsOfUseLink: 'https://www.wiley.com/en-us/terms-of-use',
  privacyLink: 'https://www.wiley.com/en-us/privacy',
  ICPLink: 'https://www.wiley.com/en-us',
  hasLinkValue: false,
  refresh: true,
  scrollContext: {
    contextWidth: 0,
    contextHeight: 0,
    boxWidth: 0,
    boxHeight: 0,
  },
  scrollContextMap: {
    'default': {
      contextWidth: 0,
      contextHeight: 0,
      boxWidth: 0,
      boxHeight: 0,
    }
  },
  errorList: [],
  keyMap: {},
};

export default function CommonReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.CHANGE_SIDER_VALUE:
      return {
        ...state,
        siderValue: data,
      };
    case actions.SAVE_FOOTER_LINK:
      return {
        ...state,
        // rightLink: data.rightLink,
        // copyrightLink: data.copyrightLink,
        // termsOfUseLink: data.termsOfUseLink,
        // privacyLink: data.privacyLink,
        // ICPLink: data.ICPLink,
        hasLinkValue: true,
      };
    case actions.REFRESH_DATA:
      return {
        ...state,
        refresh: !state.refresh,
      };
    // case actions.BODY_HEIGHT_ACTION:
    //   return {
    //     ...state,
    //     scrollContext: data,
    //   };
    case actions.BODY_HEIGHT_ACTION_WITH_KEY:
    {
      const { key,context } = data;
      if (key){
        return {
          ...state,
          scrollContextMap:{
            ...state.scrollContextMap,
            [key]: context,
          }
        };
      } else {
        return {
          ...state,
          scrollContext: context,
          scrollContextMap:{
            ...state.scrollContextMap,
            'default': context,
          },
        };
      }
    }
    case actions.RAISE_ERROR:
    {
      const {errorList, ...others} = state;
      errorList.push(data);
      return {
        errorList,
        ...others
      };
    }
    case actions.RESOLVE_ERROR:
    {
      const {errorList, ...others} = state;
      const index = errorList.findIndex((e) => e.code === data);
      let newErrorList = errorList;
      if (index>=0){
        newErrorList = errorList.splice(index,1);
      }
      return {
        errorList: newErrorList,
        ...others
      };
    }
    case actions.SET_KEY:
      return {
        ...state,
        keyMap: {
          ...state.keyMap,
          ...data,
        }
      };
    case actions.DEL_KEY:
      return {
        ...state,
        keyMap: {
          ...state.keyMap,
          [data]: null,
        }
      };
    default:
      return state;
  }
}

import { useDispatch, useSelector } from 'react-redux';
import Section from '../../Framework/Block/Section';
import RadioBox from './Componets/RadioBox';
import { cfsRadioList, rmRadioList, stateRadioList, ChinaGERadioList } from './utils';
import Item from '../../Framework/Block/Item';
import { setSelect } from '@/actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import {useContext} from 'react';
import {isCNmarketingContext} from '@/Route';

export default function FourthSection(props) {
  const dispatch = useDispatch();
  const { siFilterSelected } = useSelector(state => {
    return {
      siFilterSelected: state.Filter.siFilterSelected,
    };
  });
  
  function deleteItemFromArray(item, array) {
    let idx = array.indexOf(item);
    array.splice(idx, 1);
  }
  function addItemToArray(item, array) {
    if (!array.includes(item)) {
      array.push(item);
    }
  }
  function handleStateChange(key) {
    let newState = siFilterSelected.stateSelected.concat();
    if (newState.includes(key)) {
      deleteItemFromArray(key, newState);
      if(key === 'marketingSectionAdded' && newState.includes('readyforPromotion')){
        deleteItemFromArray('readyforPromotion', newState);
      }
    } else {
      newState.push(key);
      if (key === 'readyforPromotion') {
        addItemToArray('marketingSectionAdded', newState);
      }
    }
    dispatch(setSelect('state', newState));
  }

  function handleClosedforSubmissionChange(key) {
    let newValue = siFilterSelected.closedforSubmissionSelected.concat();
    // 这种实现 yes和no无法共存
    // if(newValue.length === 0 || newValue[0] !== key){
    //   dispatch(setSelect('closedforSubmission', [key]));
    // }
    // 这种实现 yes和no 可以共存
    if (newValue.includes(key)) {
      deleteItemFromArray(key, newValue);
    } else {
      addItemToArray(key, newValue);
    }
    dispatch(setSelect('closedforSubmission', newValue));
  }

  function handleRevenueModelChange(key) {
    let newValue = siFilterSelected.journalrevenueModelSelected.concat();
    if (newValue.includes(key)) {
      deleteItemFromArray(key, newValue);
    } else {
      addItemToArray(key, newValue);
    }
    dispatch(setSelect('journalrevenueModel', newValue));
  }
  function handleChinaGEChange(key) {
    let newValue = siFilterSelected.siWithChinaGESelected.concat();
    if (newValue.includes(key)) {
      deleteItemFromArray(key, newValue);
    } else {
      addItemToArray(key, newValue);
    }
    dispatch(setSelect('siWithChinaGE', newValue));
  }
  const isCNmarketing = useContext(isCNmarketingContext) ?? false;
  function CNmarketingSelector() {
    if(siFilterSelected.siWithChinaGESelected)
      return siFilterSelected.siWithChinaGESelected;
    else{
      dispatch(setSelect('siWithChinaGE', []));
      return [];}}
  return (
    <Section id='fourthSection' sx={{ padding: '16px 17px 24px 13px' }}>
      {isCNmarketing?<Item
        id='fourthSectionChinaGE'
        title='China GE'
        titleProps={{ paddingLeft: '3px' }}
        needResetBtn={true}
        handleReset = {()=>{
          dispatch(setSelect('siWithChinaGE', []));
        }}
      >
        <RadioBox
          id='fourthSectionChinaGE'
          dataSeleniumId='SIOverview_Filter-FourthSection-ChinaGERaioBox'
          value={CNmarketingSelector()}
          handleChange={handleChinaGEChange}
          radiolist={ChinaGERadioList}
          stackProps={{
            first: {
              gap: '50px',
            },
          }}
          isCNmarketing={isCNmarketing}
        />
      </Item>:<><Item
        id='fourthSectionState'
        title='State'
        titleProps={{ paddingLeft: '3px' }}
        needResetBtn={true}
        handleReset = {()=>{
          dispatch(setSelect('state', []));
        }}
      >
        <RadioBox
          id='stateRadioBox'
          dataSeleniumId='SIOverview_Filter-FourthSection-StateRaioBox'
          value={siFilterSelected.stateSelected}
          handleChange={handleStateChange}
          radiolist={stateRadioList}
          stackProps={{
            first: {
              gap: '81px',
            },
          }}
        />
      </Item>
      <Item
        id='fourthSectionClosedforSubmission'
        title='Closed for Submission'
        titleProps={{ paddingLeft: '3px' }}
        needResetBtn={true}
        handleReset = {()=>{
          dispatch(setSelect('closedforSubmission', []));
        }}
      >
        <RadioBox
          id='closedforSubmissionRadioBox'
          dataSeleniumId='SIOverview_Filter-FourthSection-closedforSubmissionRadioBox'
          value={siFilterSelected.closedforSubmissionSelected}
          handleChange={handleClosedforSubmissionChange}
          radiolist={cfsRadioList}
          stackProps={{
            first: {
              gap: '56px',
            },
          }}
        />
      </Item></>}
      <Item
        id='fourthSectionRevenueModel'
        title='Revenue Model'
        titleProps={{ paddingLeft: '3px' }}
        needResetBtn={true}
        handleReset = {()=>{
          dispatch(setSelect('journalrevenueModel', []));
        }}
      >
        <RadioBox
          id='revenueModelRadioBox'
          dataSeleniumId='SIOverview_Filter-FourthSection-revenueModelRaioBox'
          value={siFilterSelected.journalrevenueModelSelected}
          handleChange={handleRevenueModelChange}
          radiolist={rmRadioList}
          stackProps={{
            first: {
              gap: '50px',
            },
          }}
        />
      </Item>
    </Section>
  );
}

//       const res = await axios_instance.get('/sip/decision/getRejectReasons', {
//         params: params,
//         headers: {
//           authorization: localStorage.getItem('authorization'),
//         },
//       });
//       if (res.data.code === 200) {
//         if (pageNum > 1) {
//           dispatch({ type: GET_MOREJOURNALLIST, data: res.data.data });
//         } else {
//           dispatch({ type: GET_JOURNALLIST, data: res.data.data });
//           dispatch({ type: GET_MOREJOURNALLIST, data: [] });
//         }
//       }
//     } catch {}
//   };
// }
import {fakeAxiosSuccessResponse, paramsEncode} from "@/utils/commonUtils";
import {geDiffVOTestData} from "@/pages/SIPPage/TransformToSI/Submodule/TransformSteps/DuplicateGeCheck";
import {axios_instance} from "@/utils/axios_instance";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {
    CHECK_SIP_NON_CAT_JOURANL,
    setTransformToSiAcquiredSiInfo,
    setTransformToSiGeCheckInfo
} from "@/actions/SIMT-SIP/SIP/SIPAction";
import {acquiredSiDetailTestData} from "@/pages/SIPPage/TransformToSI/Submodule/TransformSteps/AcquiredSiCreation";

/**
 * @param {string} sipCode
 * @param {"test" | "run" | undefined} mode
 * @returns {(dispatch: import("redux").Dispatch)=>Promise<void>}
 */
export const getDuplicatedGeInfo = (sipCode, mode = 'run') => {
    /**
     * @typedef {import("@/pages/SIPPage/TransformToSI/Submodule/TransformSteps/DuplicateGeCheck").GeDiffDataVO} GeDiffOriginalData
     */

    return async dispatch => {
        try {
            /** @type {import("@/utils/axios-response").AxiosResponse<GeDiffOriginalData>} */
            const res =
                mode === 'test'
                    ? await fakeAxiosSuccessResponse(geDiffVOTestData)
                    : await axios_instance.get(`/api/v1/sip-service/special-issue-proposals/${sipCode}`, {
                        params: {
                            type: 'checkGE',
                        },
                        headers: {
                            authorization: localStorage.getItem('authorization') || '',
                        },
                    });
            const data = res.data;
            if (data.code === 200) {
                dispatch(setTransformToSiGeCheckInfo('success', data.data));
            } else {
                dispatch(setTransformToSiGeCheckInfo('error'));
                dispatch(setSnackbarMessageAndOpen(data.message, {}, SEVERITIES.error));
            }
        } catch (err) {
            dispatch(setTransformToSiGeCheckInfo('error'));
            console.error(err);
        }
    };
};
/**
 * @param {string} sipCode
 * @param {"test" | "run" | undefined} mode
 * @returns {(dispatch: import("redux").Dispatch)=>Promise<void>}
 */
export const getAutoAcquiredSiData = (sipCode, mode = 'run') => {
    /**
     * @typedef {import("@/pages/SIPPage/TransformToSI/Submodule/TransformSteps/AcquiredSiCreation").AcquiredSiDetailVO} AcquiredSiDetailVO
     */

    return async dispatch => {
        try {
            /** @type {import("@/utils/axios-response").AxiosResponse<AcquiredSiDetailVO>} */
            const res =
                mode === 'test'
                    ? await fakeAxiosSuccessResponse(acquiredSiDetailTestData)
                    : await axios_instance.get(`/api/v1/sip-service/special-issue-proposals/${sipCode}`, {
                        params: {
                            type: 'autoAcquiredSi',
                        },
                        headers: {
                            authorization: localStorage.getItem('authorization') || '',
                        },
                    });
            const data = res.data;
            if (data.code === 200) {
                dispatch(setTransformToSiAcquiredSiInfo('success', data.data));
            } else {
                dispatch(setTransformToSiAcquiredSiInfo('error'));
                dispatch(setSnackbarMessageAndOpen(data.message, {}, SEVERITIES.error));
            }
        } catch (err) {
            dispatch(setTransformToSiAcquiredSiInfo('error'));
            dispatch(setSnackbarMessageAndOpen(`${err}`, {}, SEVERITIES.error));
            console.error(err);
        }
    };
};

export function CheckSipNonCatJournal(sipCode) {
    return async dispatch => {
        try {
            let params = paramsEncode({
                type: 'nonCatJournal',
            });

            const res = await axios_instance.get(`/api/v1/sip-service/special-issue-proposals/${sipCode}`, {
                params: params,
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: CHECK_SIP_NON_CAT_JOURANL, data: res.data.data});
            }
        } catch {
        }
    };
}

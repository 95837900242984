import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
} from '@mui/material';
import { H4Font, SmallBoldFont } from '@/components/FontStyle';
import GEDetailCloseIcon from '@/assets/GEDetailCloseIcon.svg';
import EditContent from './SubmitContent';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSubmitOptInCode,

} from '../../../../actions/SIMT-SI/CatJournal/CATJournalAction';
import { StateButton } from '@/componentsphase2/StateButton';
import {getOptInData, submitOptIn} from "@/actions/SIMT-SI/CatJournal/OptIn";
import {withDrawOptIn} from "@/actions/SIMT-SI/CatJournal";

export default function OptInWithdraw(props) {
  const { open = false, handleClose = () => {}, journalCode = '' } = props;

  const dispatch = useDispatch();

  const {
    withdrawOptInResult,
    withdrawOptInMonitor,
    page,
    pageSize,
    optInData,
  } = useSelector(state => {
    return {
      withdrawOptInResult: state.CATJournal.withdrawOptInResult,
      withdrawOptInMonitor: state.CATJournal.withdrawOptInMonitor,
      page: state.CATJournal.optInPage,
      pageSize: state.CATJournal.optInPageSize,
      optInData: state.CATJournal.optInData,
    };
  });

  const handleWithDraw = () => {
    dispatch(withDrawOptIn(journalCode));
  };

  useEffect(() => {
    if (withdrawOptInResult !== null) {
      if (page !== 1 && optInData.length === 1) {
        dispatch(getOptInData(page - 1, pageSize));
        dispatch({ type: 'CHANGE_OPT_IN_PAGE', data: page - 1 });
      } else {
        dispatch(getOptInData(page, pageSize));
      }
      setTimeout(() => {
        handleClose();
      }, 300);
    }
  }, [withdrawOptInMonitor]);

  return (
    <Dialog
      PaperProps={{
        style: {
          boxShadow: 'none',
        },
      }}
      open={open}
      onClose={handleClose}
      sx={{
        zIndex: 1401,
        '& .MuiDialog-paper': {
          minWidth: '600px',
          margin: 0,
          paddingTop: '24px',
          paddingBottom:'0px',
          paddingLeft: '32px',
          paddingRight: '32px',
          gap: '16px',
          boxSizing: 'border-box',
        },
        '& .MuiBackdrop-root': {
          background: 'rgba(17, 61,149, 0.5)',
          backdropFilter: 'blur(4px)',

        },
      }}
      data-selenium-id='CATJournal_Overview-OptInOperation-Withdraw-Dialog'
    >
      <Box
        sx={{ }}
        data-selenium-id='CATJournal_Overview-OptInOperation-Withdraw-Box'
      >
        <Box
          sx={{
            ...H4Font,
            height: '23px',
            // float: 'left',
            textAlign: 'center',
            color: '#262E35',
          }}
          data-selenium-id='CATJournal_Overview-OptInOperation-Withdraw-Box-Box'
        >
          Are you sure to withdraw the opt-in request?
        </Box>
        {/* <IconButton
          sx={{ float: 'right', cursor: 'pointer', padding: '0px' }}
          onClick={handleClose}
          data-selenium-id='CATJournal_Overview-OptInOperation-Withdraw-IconButton'
        >
          <GEDetailCloseIcon />
        </IconButton> */}
      </Box>
      {/* <DialogActions
        sx={{ padding: '0px' }}
        data-selenium-id='CATJournal_Overview-OptInOperation-Withdraw-DialogActions'
      > */}
        <Stack direction='row' spacing={1.125} justifyContent="center" sx={{paddingTop: '12px', paddingBottom:'20px'} }>
          <StateButton
            isPrimary = {false}
            titleName = 'Cancel'
            onClick={handleClose}
            data-selenium-id='CATJournal_Overview-OptInOperation-Withdraw-Box1'
            sx ={{
              fontSize : '14px',
              lineHeight: '18px',
              width: '70px'
            }}
          >
          </StateButton>
          {/* <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '32px',
              width: '82px',
            }}
            onClick={handleClose}
            data-selenium-id='CATJournal_Overview-OptInOperation-Withdraw-Box1'
          >
            <span
              style={{ color: '#154AB6', cursor: 'pointer', ...SmallBoldFont }}
            >
              Cancel
            </span>
          </Box> */}
          <StateButton
            isPrimary = {true}
            titleName = 'OK'
            data-selenium-id='CATJournal_Overview-OptInOperation-Withdraw-Box1'
            sx ={{
              fontSize : '14px',
              width: '70px'
            }}
            onClick={handleWithDraw}
            data-selenium-id='CATJournal_Overview-OptInOperation-Withdraw-Box2'
          >
          </StateButton>
          {/* <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '32px',
              width: '44px',
              background: '#154AB6',
              color: '#F1F3F5',
              borderRadius: '5px',

              cursor: 'pointer',
              ...SmallBoldFont,
            }}
            onClick={handleWithDraw}
            data-selenium-id='CATJournal_Overview-OptInOperation-Withdraw-Box2'
          >
            OK
          </Box> */}
        </Stack>
      {/* </DialogActions> */}
    </Dialog>
  );
}

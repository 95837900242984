import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import MyBaseInput from '@/components/CreateAndUpdate/MyBaseInput';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@/assets/CreateAndUpdateInputDelete.svg';
import { FormControl } from '@mui/material';
import { defaultVars, ErrVars } from '@/components/CreateAndUpdate/commonStyle';
import { BootstrapTitle } from '@/components/CreateAndUpdate/MyTitle';
import * as React from 'react';
import { SiCreateInputHelperText } from '@/components/CreateAndUpdate/MyFormHelperText';

function CreateAndUpdateItemBlock(props) {
  const {
    title,
    required = true,
    emptyErr,
    defaultValue,
    placeholder = 'example placeholder',
    limitLength,
    exSpecialChar,
    setSCErr,
    setEmailErr,
    checkEmail,
    moreSpecialCheck,
    setValue,
    value,
    HandleChange,
    helperText = '',
    needErrorMessage = true,
    needX = true,
    needTitle,
    children,
  } = props;

  const [error, setError] = useState(false);

  const [Vars, setVars] = useState(defaultVars);
  const [specialChar, setSpecialChar] = useState(false);
  const [isEmail, setIsEmail] = useState(true);

  const specialCharPattern1 = new RegExp(
    '[`~!@#$^&*()=|{}\':;,\\[\\].<>/?！￥…（）—【】‘；：”“。，、？]'
  );
  const specialCharPattern2 = new RegExp('\\\\');
  const emailPattern =
    /^\w+((-\w+)|(\.\w+)|(_\w+))*@[A-Za-z0-9]+(([.\-])[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

  const ifSpecialChar = text => {
    setSpecialChar(
      specialCharPattern1.test(text) || specialCharPattern2.test(text)
    );
    if (moreSpecialCheck !== undefined && moreSpecialCheck !== false) {
      setSpecialChar(
        specialCharPattern1.test(text) ||
        specialCharPattern2.test(text) ||
        moreSpecialCheck.test(text)
      );
    }
    if (setSCErr !== undefined) {
      setSCErr(
        specialCharPattern1.test(text) || specialCharPattern2.test(text)
      );
      if (moreSpecialCheck !== undefined && moreSpecialCheck !== false) {
        setSCErr(
          specialCharPattern1.test(text) ||
          specialCharPattern2.test(text) ||
          moreSpecialCheck.test(text)
        );
      }
    }
  };

  const checkIsEmail = text => {
    setIsEmail(emailPattern.test(text));
    setEmailErr(!emailPattern.test(text));
  };

  const handleChange = e => {
    if (limitLength !== undefined || limitLength !== null) {
      if (e.target.value.length <= limitLength) {
        if (setValue !== undefined) {
          setValue(e.target.value);
        }

        if (HandleChange !== undefined) {
          HandleChange(e.target.value);
        }
      }
    } else {
      if (setValue !== undefined) {
        setValue(e.target.value);
      }
      if (HandleChange !== undefined) {
        HandleChange(e.target.value);
      }
    }
  };

  useEffect(() => {
    setVars(
      emptyErr ||
        error ||
        (exSpecialChar && specialChar) ||
        (checkEmail && !isEmail)
        ? ErrVars
        : defaultVars
    );
  }, [emptyErr, error, specialChar, isEmail, exSpecialChar, checkEmail]);

  return (
    <Box>
      <FormControl
        data-selenium-id='SIPage_CreateAndUpdateSI-InputBlock-FormControl'
        variant='standard'
        error={
          emptyErr ||
          error ||
          (exSpecialChar && specialChar) ||
          (checkEmail && !isEmail)
        }
        sx={{ width: '100%' }}
      >
        {needTitle ? (<BootstrapTitle
          id={id}
          data-selenium-id={id}
          title={title}
          required={required}
          lineHeight='18px'
        ></BootstrapTitle>): null}
        
        {children &&
          React.cloneElement(children, {
            error,
            setError,
            required,
          })}
        {needErrorMessage === true ? (
          <SiCreateInputHelperText
            data-selenium-id='SIPage_CreateAndUpdateSI-InputBlock-InputHelperText'
            exSpecialChar={exSpecialChar}
            specialChar={specialChar}
            checkEmail={checkEmail}
            isEmail={isEmail}
            propsError={error}
            PropsHelperText={props.helperText}
          ></SiCreateInputHelperText>
        ) : null}
      </FormControl>
    </Box>
  );
}

/* Determine whether a value is empty or an empty string */
const isEmptyValue = value => {
  return (
    value === null ||
    value === '' ||
    value === undefined ||
    value.length === 0
  );
};

export function CreateAndUpdateInputBase(props) {
  const {
    id,
    needX = true,
    inputWidth = '400px',
    inputHeight = '40px',
    inputPadding = '7.5px 12px',
    numberInput = false,
    isDisabled = false,
    required = false,
    patterns = [],  // Array of pattern and errorType, eg [{ pattern: /^.*$/, errorType:'any'}]
    placeholder = '',
    value = '',
    setValue,
    error,
    setError = (hasError) => { },
    onError = (errorType, errorExist) => { },
    onBlur,
    onMouseLeave,
  } = props;

  /* Default With Content Border Style */
  const defaultBorder = '1px solid #98A7B6';
  /* Default Without Content Border Style */
  const emptyBorder = '1px solid #DFE4E8';
  /* Hover Border Style */
  const hoverBorder = '1px solid #262E35';
  /* Focus Border Style */
  const focusBorder = '2px solid #154AB6';
  /* Error Border Style */
  const errorBorder = '2px solid #EE5350';
  /* Disabled Border Style */
  const disabledBorder = '1px solid #DFE4E8';

  const getBorderStyle = () => {
    if (isDisabled) return disabledBorder;
    if (isEmptyValue(value)) return emptyBorder;
    return defaultBorder;
  };

  /* Is it a hover state */
  const [isHover, setIsHover] = useState(false);
  /* Is it a focus state */
  const [isFocus, setIsFocus] = useState(false);
  /* Border Style */
  const [borderStyle, setBorderStyle] = useState(getBorderStyle());
  /* The padding size of the input box */
  const [paddingSize, setPaddingSize] = useState('7.5px');

  /* Determine whether a value is number */
  const isNumber = text => {
    let pattern = /^[+]?(\d+)$|^[+]?(\d+\.\d+)$/g;
    return pattern.test(text);
  };

  const checkError = (value) => {
    let hasError = false;
    console.info(value);
    const isEmpty = isEmptyValue(value);
    if (required) {
      if (isEmpty) {
        onError('required', true);
        hasError = true;
      } else {
        onError('required', false);
      }
    }
    if (!isEmpty) {
      if ((numberInput && !isNumber(value))) {
        onError('number', false);
        hasError = true;
      } else {
        onError('number', false);
      }

      for (let patternObj of patterns) {
        const { pattern, errorType } = patternObj;
        if (pattern && !pattern.test(value)) {
          onError(errorType, false);
          hasError = true;
        } else {
          onError(errorType, false);
        }
      }
    }
    return hasError;
  };

  useEffect(() => {
    const hasError = checkError(value);
    setError(hasError);
    if (hasError) {
      setBorderStyle(errorBorder);
    } else {
      setBorderStyle(getBorderStyle());
    }
  }, [value]);

  const handleChange = event => {
    const newValue = event.target.value;
    setValue(newValue);
    // setBorderStyle(getBorderStyle());
  };

  /* When the mouse is moved into the input box, it will execute */
  const handleMouseEnter = () => {
    if (!isFocus) {
      setIsHover(true);
      setBorderStyle(hoverBorder);
    }
  };

  /* When the mouse leaves the input box, it will execute */
  const handleMouseLeave = (event) => {
    if (!isFocus) {
      setIsHover(false);
    }

    if (error) {
      setBorderStyle(errorBorder);
    }
    else if (isEmptyValue(value)) {
      setBorderStyle(emptyBorder);
    } else {
      setBorderStyle(defaultBorder);
    }

    // setBorderStyle(getBorderStyle());

    if (onMouseLeave) {
      onMouseLeave(event);
    }
  };

  /* When the input box obtains the focus, it will execute */
  const handleFocus = () => {
    setIsFocus(true);
    setBorderStyle(focusBorder);
    setPaddingSize('6.5px');
  };

  /* When the input box loses the focus, it will execute */
  const handleBlur = (event) => {
    setTimeout(() => {
      setIsFocus(false);
      setIsHover(false);
      setPaddingSize('7.5px');

      if (error) {
        setBorderStyle(errorBorder);
      }
      else if (isEmptyValue(value)) {
        setBorderStyle(emptyBorder);
      } else {
        setBorderStyle(defaultBorder);
      }

      // setBorderStyle(getBorderStyle());
    }, 100);
    if (onBlur) {
      onBlur(event);
    }
  };

  /* Clear input box content */
  const handleClearClick = () => {
    setValue('');
  };

  return (
    <>
      <FormControl
        sx={{
          boxSizing: 'border-box',
          width: inputWidth,
          height: inputHeight,
          background: isDisabled ? '#F6F7F8' : '#FFFFFF',
          border: isDisabled ? disabledBorder : borderStyle,
          borderRadius: '4px',
        }}
      >
        <MyBaseInput
          sx={{
            '& .MuiInputBase-input': {
              pt: paddingSize,
              pb: paddingSize,
              color: isDisabled ? '#BCC5CF' : '#262E35',
            },
          }}
          id={id}
          inputHeight={inputHeight}
          inputPadding={inputPadding}
          placeholder={placeholder}
          disabled={isDisabled}
          value={value}
          onChange={handleChange}
          endAdornment={
            value &&
            (isHover || isFocus) &&
            !isDisabled && (
              <InputAdornment
                position='end'
                sx={{
                  ml: '1.33px',
                  mr: '15.33px',
                  cursor: 'pointer',
                }}
              >
                {needX === true ? (
                  <CloseIcon onClick={handleClearClick} />
                ) : null}
              </InputAdornment>
            )
          }
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onFocus={handleFocus}
          onBlur={handleBlur}
        ></MyBaseInput>
      </FormControl>
    </>
  );
}

export const CreateAndUpdateInput = React.memo(CreateAndUpdateInputBase);

export function OnlyTextWithX(props) {
  let strValue, setStrValue;
  const {
    id,
    value,
    setValue,
    title = '',
    required = false,
    needErrorMessage = false,
    errorMessage = '',
    placeholder = '',
    needX = true,
    needTitle = true,
  } = props;
  if (value && setValue) {
    [strValue, setStrValue] = [value, setValue];
  } else {
    [strValue, setStrValue] = useState('');
  }
  const [error, setError] = useState(false);

  return (
    <Box sx={{ width: '500px' }}>
      <CreateAndUpdateItemBlock
        title={title}
        required={required}
        needErrorMessage={needErrorMessage}
        helperText={errorMessage}
        needTitle={needTitle}
      >
        <CreateAndUpdateInput
          inputWidth='400px'
          placeholder={placeholder}
          needX={needX}
          value={strValue}
          setValue={setStrValue}
        ></CreateAndUpdateInput>
      </CreateAndUpdateItemBlock>
    </Box>
  );
}

import WileyLogo from '../../../../assets/LoginAsset/WileyLogo.svg';
import SuccessLogo from '../../../../assets/LoginAsset/SuccessLogo.svg';
import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import { StyledTextField } from '../Components/EncryptedTextField';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  removeFailedLogin,
} from '@/utils/localStorageHandler';
import {login} from "@/actions/SIMT-User/Login";

const SuccessTypography = styled(Typography)({
  color: 'var(--gray-500, #596A7C)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '48px',
  fontFamily: 'Open Sans',
  fontWeight: '800',
  lineHeight: '125%',
  letterSpacing: '0.48px',
});

const InfoTypography = styled(Typography)({
  color: 'var(--gray-300, #BCC5CF)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  lineHeight: '140%',
});

const CloseButton = styled(Button)({
  borderRadius: '5px',
  background: 'var(--primary-500, #0052CC)',
  color: 'var(--shades-white, #FFF)',
  textAlign: 'center',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  lineHeight: '18px',
  width: '400px',
  height: '40px',
  padding: '8px 12px',
  gap: '6px',
  '&:hover': {
    background: 'var(--primary-300, #6D98EE)',
  },

  '&:active': {
    background: 'var(--primary-600, #113D95)',
  },
});

const ResetSuccessPage = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;

  const selector = state => {
    return {
      ifFirst: state.Login.ifFirst,
      errMsg: state.Login.errorMsg,
      isAuthenticated: state.Login.isAuthenticated,
      count: state.Login.loginFailCount,
    };
  };

  const { ifFirst, isAuthenticated } = useSelector(selector);


  const handleClick = () => {
    dispatch(
      login({
        email: state.email,
        password: state.password,
        remember_flag: 'on',
      })
    );
  };

  useEffect(() => {
    if (isAuthenticated) {
      removeFailedLogin();
      if (ifFirst === true) {
        navigate('/simt/firstReset', { state: { email: state.email } });
      } else {
        navigate('/simt/auth/index');
      }
    }
  }, [isAuthenticated]);

  return (
    <div
      style={{
        display: 'flex',
        background: '#FFF',
        width: '100vw',
        height: '100vh',
      }}
      data-selenium-id='Login_NewLogin_ResetSuccess'
    >
      <div
        style={{ width: '311px', height: '100%', background: '#0052CC' }}
        data-selenium-id='Login_NewLogin_ResetSuccess-LeftPart'
      >
        <WileyLogo
          style={{
            marginLeft: '16px',
            marginTop: '16px',
          }}
        />
      </div>
      <div
        style={{
          width: 'calc(100% - 311px)',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
        data-selenium-id='Login_NewLogin_ResetSuccess-RightPart'
      >
        <div
          data-selenium-id='Login_NewLogin_ResetSuccess-RightPart-Content'
          style={{
            marginTop: '148px',
          }}
        >
          <div data-selenium-id='Login_NewLogin_ResetSuccess-SuccessTitle'>
            <SuccessTypography>Success!</SuccessTypography>
          </div>
          <div
            style={{ marginTop: '20px', width: '553px' }}
            data-selenium-id='Login_NewLogin_ResetSuccess-SuccessInfo'
          >
            <InfoTypography>
              Congratulations! Your password has been successfully reset. Please
              remember to use your new password when logging in.
            </InfoTypography>
          </div>
          <div
            style={{ marginLeft: '80px', marginTop: '59px' }}
            data-selenium-id='Login_NewLogin_ResetSuccess-SuccessLogo'
          >
            <SuccessLogo />
          </div>
          <div
            style={{ marginTop: '-27px' }}
            data-selenium-id='Login_NewLogin_ResetSuccess-BackLogin'
          >
            <CloseButton onClick={handleClick}
              disableRipple={true}
            >ClOSE THIS PAGE</CloseButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetSuccessPage;

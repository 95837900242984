import {
  Box,
  CircularProgress,
  Fab,
  fabClasses,
  IconButton,
  Stack,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SIPViewAllArrow from '@/assets/SIPViewAllArrow.svg';

export default function HideUL(props) {
  const { id } = props;

  const liNum = 3;
  const [expanding, setExpanding] = useState(false);

  const elementRef = useRef(null);

  const textStyle = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#262E35',
    flex: 'none',
    wordBreak: 'break-word',
    overflow: 'hidden',
    // textOverflow: "ellipsis",
    // display: "-webkit-box",
    // WebkitLineClamp: lineNum,
    // WebkitBoxOrient: "vertical",
  };

  const moreStyle = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.01em',
    color: '#154AB6',
    flex: 'none',
    cursor: 'pointer',
  };

  const handleViewAllClick = () => {
    setExpanding(true);
  };

  const handleHideClick = () => {
    setExpanding(false);
  };

  const ulStyle = {
    paddingLeft: '20px',
    paddingRight: '20px',
    marginTop: '0px',
    marginBottom: '0px',
  };

  return (
    <div
      data-selenium-id='SIP_DetailPage_Submodule-HideUL-Div'
      style={{
        position: 'relative',
        // overflowX: "auto",
      }}
    >
      <div
        style={textStyle}
        data-selenium-id='SIP_DetailPage_Submodule-HideUL-TextStyleDiv'
      >
        <ul
          style={ulStyle}
          ref={elementRef}
          data-selenium-id='SIP_DetailPage_Submodule-HideUL-Ul'
        >
          {props.children
            .filter((item, index) => expanding || index < liNum)
            .map((item, index) => {
              return (
                <li
                  key={index}
                  id={`${id}-hideul-${index}`}
                  data-selenium-id={`SIP_DetailPage_Submodule-HideUL-${id}-hideul-${index}`}
                >
                  {item}
                  {props.children.length > liNum && index == 2 && !expanding ? (
                    <span data-selenium-id='SIP_DetailPage_Submodule-HideUL-HandleViewAllClickSpan'>
                      &nbsp;...&nbsp;
                      <span style={moreStyle} onClick={handleViewAllClick}>
                        More
                      </span>
                    </span>
                  ) : (
                    <></>
                  )}
                </li>
              );
            })}
        </ul>
        {props.children.length > 3 && expanding ? (
          <div
            data-selenium-id='SIP_DetailPage_Submodule-HideUL-LessDiv'
            style={{ ...moreStyle, marginTop: '4px' }}
            onClick={handleHideClick}
          >
            Less
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

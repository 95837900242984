import { useMemo } from 'react';
import Chart from '@/components/Chart/Chart';
import { AllInOneChartOptions } from '../options';
import { SUBJECT_GROUP } from '../../Constants';

export const ChartAllInOne = ({
  width,
  hasZoom = false,
  hasTitle = false,
  title,
  subTitle,
  barchartData,
  isDownload = false,
}) => {
  const level = subTitle;
  const isSubjectGroup = level && level.indexOf(SUBJECT_GROUP) >= 0;

  const legendData = barchartData[0];

  const chartOption = useMemo(() => {
    return new AllInOneChartOptions(isSubjectGroup, isDownload, width, legendData);
  }, [isSubjectGroup, isDownload, width, legendData]);


  let options = useMemo(() => {
    if (isSubjectGroup) {
      // data include a line of title
      chartOption.setNumOfSG(barchartData.length - 1 ?? 0);
    }

    chartOption
      .setZoom(hasZoom)
      .setTitle(hasTitle, title, subTitle)
      .setLegendData(legendData)
      .setSelectedLegend(undefined)
      .setDataSource(barchartData)
      .setWidth(width);

    return chartOption.getOption();
  }, [chartOption, hasZoom, hasTitle, title, subTitle, barchartData]);

  console.log('======options=====, ', options);

  const chartHeight = useMemo(() => {
    return chartOption.getHeightWithTitle();
  }, [hasTitle, title, subTitle, barchartData]);

  // console.log("======chartHeight=====, ", chartHeight)

  return (
    <Chart
      options={options}
      height={chartHeight}
      width={width}
      isZoomLabelFontSize={false}
    />
  );
};
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import MyTable from '@/pages/SIPage/SIDetailPage/Table/MyTable';
import { EHTab,EHTabs } from '@/pages/SIPage/SIDetailPage/EditingHistoryBlock';
import {get_GEBasicHistory} from "@/actions/SIMT-SI/GuestEditor";
import {get_FlagHistory} from "@/actions/SIMT-SI/GuestEditor/GEFlag";


function TabPanel(props) {
  const { geCode, isFold,setIsFold,children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      data-selenium-id={`GEPage_DetailPage-ShowBlockBox-Simple-Tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop:'12px' }} data-selenium-id='GEPage_DetailPage-ShowBlockBox-SimpleTabPanelBox'>
          <Typography data-selenium-id='GEPage_DetailPage-ShowBlockBox-SimpleTabPanelTypography'>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EditingHistoryBlock(props) {
  const {
    geCode,
    isFold,
    geFullName,
  } = props;


  const [GEBasicHistoryPage, setGEBasicHistoryPage] = useState(1);
  const [FlagHistoryPage, setFlagHistoryPage] = useState(1);
  const [GEHistoryRowsPerPage, setGEBasicHistoryRowsPerPage] = useState(20);
  const [FlagHistoryRowsPerPage, setFlagHistoryRowsPerPage] = useState(20);

  const [GEBasicHistoryTableData, setGEBasicHistoryTableData] = useState([]);
  const [FlagHistoryTableData, setFlagHistoryTableData] = useState([]);
  const [GEBasicHistoryCount, setGEBasicHistoryCount] = useState(0);
  const [FlagHistoryCount, setFlagHistoryCount] = useState(0);
  const rowsPerPageOptions = [5, 10, 20];

  const dispatch = useDispatch();
  const selector = state => {
    return {
      GEBasicInformationHistory: state.GE.GEBasicInformationHistory,
      FlagHistory: state.GE.FlagHistory,
    };
  };

  const { GEBasicInformationHistory, FlagHistory} = useSelector(selector);


  useEffect(() => {
    if (GEBasicInformationHistory.count !== undefined) {
      setGEBasicHistoryCount(GEBasicInformationHistory.count);
      GEBasicInformationHistory.data === null || GEBasicInformationHistory.data === undefined
        ? setGEBasicHistoryTableData([])
        : setGEBasicHistoryTableData(GEBasicInformationHistory.data);
    }
  }, [GEBasicInformationHistory]);

  useEffect(() => {
    if (FlagHistory.total !== undefined) {
      setFlagHistoryCount(FlagHistory.total);
      FlagHistory.list === null
        ? setFlagHistoryTableData([])
        : setFlagHistoryTableData(FlagHistory.list);
    }
  }, [FlagHistory]);

  const handleGEBasicPageChange = (e, value) => {
    setGEBasicHistoryPage(value);
    dispatch(get_GEBasicHistory(geCode, value, GEHistoryRowsPerPage));
  };

  const handleFlagPageChange = (e, value) => {
    setFlagHistoryPage(value);
    dispatch(get_FlagHistory(geCode, value, FlagHistoryRowsPerPage));
  };

  const handleGEBasicRowsPerPageChange = e => {
    setGEBasicHistoryPage(1);
    setGEBasicHistoryRowsPerPage(Number(e.target.value));
    dispatch(get_GEBasicHistory(geCode, GEBasicHistoryPage, Number(e.target.value)));
  };
  const handleFlagRowsPerPageChange = e => {
    setFlagHistoryPage(1);
    setFlagHistoryRowsPerPage(Number(e.target.value));
    dispatch(
      get_FlagHistory(geCode, FlagHistoryPage, Number(e.target.value))
    );
  };
  const createRow = (
    id,
    label,
    minWidth,
    maxWidth,
    isOrder = true,
    align = 'left'
  ) => {
    return { id, label, minWidth, maxWidth, isOrder, align };
  };

  const [TabValue, setTabValue] = useState(0);

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const GEBasicHistoryHeads = [
    createRow('editTime', 'EDIT TIME', '170px', '372px', false),
    createRow('item', 'ITEM', '180px', '372px', false),
    createRow('GEBasic-oldValue', 'OLD VALUE', '170px', '372px', false),
    createRow('GEBasic-newValue', 'NEW VALUE', '170px', '372px', false),
    createRow('editUser', 'EDIT USER', '170px', '372px', false),
  ];

  const FlagHistoryTableHeads = [
    createRow('editTime', 'EDIT TIME', '178px', '372px', false),
    createRow('action','ACTION','113px', '372px',false),
    createRow('flagName','FLAG NAME','166px','200px',false),
    createRow('item', 'ITEM', '113px', '372px', false),
    createRow('Flag-oldValue', 'OLD VALUE', '146px','180px', false),
    createRow('Flag-newValue', 'NEW VALUE','146px','180px', false),
    createRow('editUser', 'EDIT USER', '131px', '372px', false),
  ];
  return (
    <Box 
    sx={{
      paddingBottom: '32px',
    }}
      data-selenium-id='GEPage_DetailPage-ShowBlockBox-EditingHistoryBlock-ContainerBox'
    >
      {isFold ? (
        <Box
          // sx={{ width: '1092px' }}
          data-selenium-id='GEPage_DetailPage-ShowBlockBox-EditingHistoryBlock-Box1'
        >
          <Box data-selenium-id='GEPage_DetailPage-ShowBlockBox-EditingHistoryBlock-Box2'>
            <EHTabs
              value={TabValue}
              onChange={handleTabChange}
              lineWidth={TabValue===0?'210px':'118px'}
              aria-label='basic tabs example'
              style={{ 'min-height': '29px',marginTop:'16px',height:'31px' }}
              data-selenium-id='GEPage_DetailPage-ShowBlockBox-EditingHistoryBlock-EHTabs1'
            >
              <EHTab
                label='GE&nbsp;Basic&nbsp;Information&nbsp;Editing&nbsp;History'
                textLength="210px"
                {...a11yProps(0)}
                wrapped
                sx={{width:'242px',lineHeight:'16px'}}
                data-selenium-id='GEPage_DetailPage-ShowBlockBox-EditingHistoryBlock-EhTabs2'
              />
              <EHTab
                label='Flag&nbsp;Editing&nbsp;History'
                {...a11yProps(1)}
                wrapped
                sx={{width:'150px',lineHeight:'16px'}}
                data-selenium-id='GEPage_DetailPage-ShowBlockBox-EditingHistoryBlock-EHTabs3'
              />
            </EHTabs>
          </Box>
          <TabPanel
            value={TabValue}
            index={0}
            data-selenium-id='GEPage_DetailPage-ShowBlockBox-EditingHistoryBlock-TabPanel1'
          >
            <MyTable
              id='geDetailPageGEBasicHistoryTable'
              data-selenium-id='GEPage_DetailPage-ShowBlockBox-EditingHistoryBlock-GEBasicHistoryTable'
              TableHeads={GEBasicHistoryHeads}
              page={GEBasicHistoryPage}
              rowsPerPage={GEHistoryRowsPerPage}
              TableData={GEBasicHistoryTableData}
              rowsPerPageOptions={rowsPerPageOptions}
              count={GEBasicHistoryCount}
              handlePageChange={handleGEBasicPageChange}
              handleRowsPerPageChange={handleGEBasicRowsPerPageChange}
            ></MyTable>
          </TabPanel>
          <TabPanel
            value={TabValue}
            index={1}
            data-selenium-id='GEPage_DetailPage-ShowBlockBox-EditingHistoryBlock-TabPanel2'
          >
            <MyTable
              id='geDetailPageFlagHistoryTable'
              data-selenium-id='GEPage_DetailPage-ShowBlockBox-EditingHistoryBlock-FlagHistoryTable'
              TableHeads={FlagHistoryTableHeads}
              page={FlagHistoryPage}
              rowsPerPage={FlagHistoryRowsPerPage}
              TableData={FlagHistoryTableData}
              rowsPerPageOptions={rowsPerPageOptions}
              count={FlagHistoryCount}
              handlePageChange={handleFlagPageChange}
              handleRowsPerPageChange={handleFlagRowsPerPageChange}
              extraInfo={geFullName}
              geCode={geCode}
            ></MyTable>
          </TabPanel>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}

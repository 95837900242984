/**
 * yueying
 */
import React, { useEffect, useState } from 'react';
import './index.css';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '../../../components/DataTable';
import {
  StyledTableCellPurple,
  StyledTableCellDisPurple,
  StyledTableCellGreen,
  StyledTableCellDisGreen,
  StyledTableRow, StyledBodyCellPurple, StyledTableCellNormal, StyledBodyCellGreen, TableBox, ReportNoData, TableBox2
} from "../ReportSpecificComponents/SpecialColorTableHeader";
import { findLongestLineSubstring, measureWidth } from "../../../utils/StringWidthMeasure";
import { TextLabel } from "../../../componentsphase2/CustomTooltip";
import {GetTable2Data} from "@/actions/SIMT-Report/Report/ReportSiPipeline";

export function SubjectGroupTable(props) {
  const { formatYear = 'FY**' } = props;
  const [loading, setLoading] = useState(false);
  const items = [
    {
      id: 'reportPipelineOverviewSgIdeaProposal',
      label: 'Solicited Proposal',
      minWidth: '90px',
      maxWidth: '100px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewSgIdeaProposal',
      label: 'Unsolicited Proposal',
      minWidth: '90px',
      maxWidth: '100px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewSgIdeaProposal',
      label: 'Idea / Proposal',
      minWidth: '90px',
      maxWidth: '100px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewSgAcquired',
      label: 'Acquired',
      minWidth: '50px',
      maxWidth: '100px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewSgPaperCommission',
      label: 'Paper Commission',
      minWidth: '125px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewSgSubmissionReview',
      label: 'Submission & Review',
      minWidth: '130px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewSgProduction',
      label: 'Production',
      minWidth: '70px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewSgPublishedFY',
      label: `Published in ${formatYear}`,
      minWidth: '65px',
      maxWidth: '150px',
      isOrder: false,
    },
    {
      id: 'reportPipelineOverviewSgPublished',
      label: 'Published',
      minWidth: '65px',
      maxWidth: '150px',
      isOrder: false,
    },
  ];
  const dispatch = useDispatch();
  //获取选择的日期和table2的数据
  const { currentDate, table2Data } = useSelector(state => {
    return {
      table2Data: state.Report.table2Data,
      currentDate: state.Report.selectDate,
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    for (let item of items){
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '12px Open Sans');
    }
    widthMap['subjectGroup'] = measureWidth(findLongestLineSubstring('Subject Group'), '12px Open Sans');
    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (table2Data && table2Data.length > 0){
      const maxMap = {};
      for (let i = 0; i < table2Data.length; i++) {
        let row = table2Data[i];
        let dataObj = {
          subjectGroup: row.subjectGroup ?? row.subjectGroupFullName,

          reportPipelineOverviewSgIdeaProposal: row.numIdeaOrProposal ?? '0',
          reportPipelineOverviewSgAcquired: row.numAcquired ?? '0',
          reportPipelineOverviewSgPaperCommission: row.numPaperCommission ?? '0',
          reportPipelineOverviewSgSubmissionReview: row.numSubmissionAndReview ?? '0',
          reportPipelineOverviewSgProduction: row.numProduction ?? '0',
          reportPipelineOverviewSgPublishedFY: row.numPublishedFy ?? '0',
          reportPipelineOverviewSgPublished: row.numPublished ?? '0',

          OAreportPipelineOverviewSgIdeaProposal: row.numOA_IdeaOrProposal ?? '0',
          OAreportPipelineOverviewSgAcquired: row.numOA_Acquired ?? '0',
          OAreportPipelineOverviewSgPaperCommission: row.numOA_PaperCommission ?? '0',
          OAreportPipelineOverviewSgSubmissionReview: row.numOA_SubmissionAndReview ?? '0',
          OAreportPipelineOverviewSgProduction: row.numOA_Production ?? '0',
          OAreportPipelineOverviewSgPublishedFY: row.numOA_Published_Fy ?? '0',
          OAreportPipelineOverviewSgPublished: row.numOA_Published ?? '0',
        };
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '16px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [table2Data]);

  function calcAllJournalWidth(){
    let sum = 0;
    for (let item of items) {
      sum += Math.max((columnWidthMap[item.id]??0), columnMinWidthMap[item.id]) + 50;
    }
    return sum;
  }

  function calcOAJournalWidth(){
    let sum = 0;
    for (let item of items) {
      sum += Math.max((columnWidthMap['OA'+item.id]??0), columnMinWidthMap[item.id]) + 50;
    }
    return sum;
  }

  useEffect(() => {
    async function getData() {
      // console.log(currentDate)
      if (currentDate != null) {
        await dispatch(GetTable2Data(currentDate));
        setLoading(false);
      }
    }

    getData();
    setLoading(true);
  }, [currentDate]);

  //total字体加深
  function DisplayTooltip(props) {
    const { ifTotal } = props;
    if (ifTotal == 'Total')
      return (
        <div align='right'>
          {ifTotal}
        </div>
      );
    else {
      return <div align='left'>{ifTotal}</div>;
    }
  }
  return (
    <TableBox2
      id='BoxTable2'
      data-selenium-id='Report-PipelineSubjectTable-Box'
      dataList={[table2Data]}
      tableKey={'Report-SIPipeline-SubjectTable'}
      footerHeight={0}
    >
      <TableContainer sx={{ maxHeight: '600px' }}>
        <Table
          stickyHeader
          aria--label='sticky table'
          data-selenium-id='Report-PipelineSubjectTable'
          sx={{
            tableLayout: 'fixed',
            width: 'max-content',
          }}
        >
          <TableHead data-selenium-id='Report-PipelineSubjectTableHead'>
            <TableRow data-selenium-id='Report-PipelineSubjectTableHead-Row'>
              <StyledTableCellNormal
                id='reportPipelineOverviewSgStyledTableCellPurple'
                data-selenium-id='Report-PipelineSubjectTable-SgStyledTableCellPurple'
                className='RightBottomBorder'
                align='left'
                rowSpan={2}
                sx={{
                  // maxWidth: '500px',
                  // minWidth: '140px'
                  width: `${Math.max((columnWidthMap['subjectGroup']??0) + 12, columnMinWidthMap['subjectGroup']) + 50 }px`,
                  position: 'sticky',
                  left: 0,
                  top: 0,
                  zIndex: 8,
                  backgroundColor: '#DFE4E8',
                }}
              >
                Subject Group
              </StyledTableCellNormal>
              <StyledTableCellPurple
                id='reportPipelineOverviewSgAllStyledTableCellPurple'
                data-selenium-id='Report-PipelineSubjectTable-SgAllStyledTableCellPurple'
                className='RightBottomBorder ReportHeaderFirstLine'
                align='left'
                colSpan={9}
                sx={{
                  width: `${calcAllJournalWidth()}px`,
                }}
              >
                All journals
              </StyledTableCellPurple>
              <StyledTableCellGreen
                id='reportPipelineOverviewSgOaStyledTableCellGreen'
                data-selenium-id='Report-PipelineSubjectTable-SgOaStyledTableCellGreen'
                className='RightBottomBorder ReportHeaderFirstLine'
                align='left'
                colSpan={9}
                sx={{
                  width: `${calcOAJournalWidth()}px`,
                }}
              >
                OA journals
              </StyledTableCellGreen>
            </TableRow>
            <TableRow>
              {items.map((item, index) => (
                <StyledTableCellDisPurple
                  id={`reportPipelineOverviewSgAllJournal${index}`}
                  data-selenium-id={`Report-PipelineSubjectTable-SgAllJournal-${index}`}
                  className={item.id === 'reportPipelineOverviewSgPublished' ? 'RightBottomBorder': 'BottomBorder'}
                  align='left'
                  sx={{
                    top: 42,
                    // minWidth: item.minWidth,
                    // maxWidth: item.maxWidth,
                    ...({
                      width: `${Math.max((columnWidthMap[item.id]??0), columnMinWidthMap[item.id]) + 50 }px`,
                    }),
                  }}
                  key={item.id}
                  style={{
                    'text-transform': 'capitalize',
                  }}
                >
                  {item.label}
                </StyledTableCellDisPurple>
              ))}
              {items.map((item, index) => (
                <StyledTableCellDisGreen
                  id={`reportPipelineOverviewSgOAJournal${index}`}
                  data-selenium-id={`Report-PipelineSubjectTable-SgOAJournal-${index}`}
                  className={item.id === 'reportPipelineOverviewSgPublished' ? 'RightBottomBorder': 'BottomBorder'}
                  align='left'
                  sx={{
                    top: 42,
                    // minWidth: item.minWidth,
                    // maxWidth: item.maxWidth,
                    ...({
                      width: `${Math.max((columnWidthMap['OA'+item.id]??0), columnMinWidthMap[item.id]) + 50 }px`,
                    }),
                  }}
                  key={item.id}
                  style={{
                    'text-transform': 'capitalize',
                  }}
                >
                  {item.label}
                </StyledTableCellDisGreen>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
            table2Data != null &&
            table2Data != undefined &&
            table2Data.length != 0
              ? table2Data.map((row, index) => {
                  if (row.subjectGroup == 'Total') {
                    return (
                      <StyledTableRow
                        sx={{
                          height: '64px'
                        }}
                      >
                        <StyledBodyCellPurple
                          id={`reportPipelineOverviewTableSubjectGroup${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-TableSubjectGroup-${index}`}
                          align='right'
                          sx={{
                            maxWidth: '150px',
                            position: 'sticky',
                            left: 0,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                          className='RightBorder ReportTotalTextSize'
                        >
                          <DisplayTooltip
                            ifTotal={
                              row.subjectGroup ?? row.subjectGroupFullName
                            }
                          />
                        </StyledBodyCellPurple>
                        <StyledBodyCellPurple
                          id={`reportPipelineOverviewTableIdeaOrProposal${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-TableIdeaOrProposal-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >

                          {row.solicitedSipCount === null
                            ? '0'
                            : row.solicitedSipCount}

                        </StyledBodyCellPurple>
                        <StyledBodyCellPurple
                          id={`reportPipelineOverviewTableIdeaOrProposal${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-TableIdeaOrProposal-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >

                          {row.unsolicitedSipCount === null
                            ? '0'
                            : row.unsolicitedSipCount}

                        </StyledBodyCellPurple>
                        <StyledBodyCellPurple
                          id={`reportPipelineOverviewTableIdeaOrProposal${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-TableIdeaOrProposal-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >

                            {row.numIdeaOrProposal === null
                              ? '0'
                              : row.numIdeaOrProposal}

                        </StyledBodyCellPurple>
                        <StyledBodyCellPurple
                          id={`reportPipelineOverviewTableNumAcquired${index}`}
                          data-selenium-id={`Report-PipelineSubject-TableNumAcquired-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >

                            {row.numAcquired === null ? '0' : row.numAcquired}

                        </StyledBodyCellPurple>
                        <StyledBodyCellPurple
                          id={`reportPipelineOverviewTableNumPaperCommission${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-NumPaperCommission-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >

                            {' '}
                            {row.numPaperCommission === null
                              ? '0'
                              : row.numPaperCommission}

                        </StyledBodyCellPurple>
                        <StyledBodyCellPurple
                          id={`reportPipelineOverviewTableNumSubmissionAndReview${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-NumSubmissionAndReview-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >

                            {' '}
                            {row.numSubmissionAndReview === null
                              ? '0'
                              : row.numSubmissionAndReview}

                        </StyledBodyCellPurple>
                        <StyledBodyCellPurple
                          id={`reportPipelineOverviewTableNumProduction${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-NumProduction-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >

                            {' '}
                            {row.numProduction === null
                              ? '0'
                              : row.numProduction}

                        </StyledBodyCellPurple>
                        <StyledBodyCellPurple
                          id={`reportPipelineOverviewTableNumPublishedFY${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-NumPublishedFY-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >

                            {' '}
                            {row.numPublishedFy === null
                              ? '0'
                              : row.numPublishedFy}

                        </StyledBodyCellPurple>
                        <StyledBodyCellPurple
                          id={`reportPipelineOverviewTableNumPublished${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-NumPublished-${index}`}
                          align='left'
                          className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                        >

                            {' '}
                            {row.numPublished === null ? '0' : row.numPublished}

                        </StyledBodyCellPurple>
                        <StyledBodyCellGreen
                          id={`reportPipelineOverviewTableOAIdeaOrProposal${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-OAIdeaOrProposal-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >

                          {' '}
                          {row.oaSolicitedSipCount === null
                            ? '0'
                            : row.oaSolicitedSipCount}

                        </StyledBodyCellGreen>
                        <StyledBodyCellGreen
                          id={`reportPipelineOverviewTableOAIdeaOrProposal${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-OAIdeaOrProposal-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >

                          {' '}
                          {row.oaUnsolicitedSipCount === null
                            ? '0'
                            : row.oaUnsolicitedSipCount}

                        </StyledBodyCellGreen>
                        <StyledBodyCellGreen
                          id={`reportPipelineOverviewTableOAIdeaOrProposal${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-OAIdeaOrProposal-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >

                            {' '}
                            {row.numOA_IdeaOrProposal === null
                              ? '0'
                              : row.numOA_IdeaOrProposal}

                        </StyledBodyCellGreen>
                        <StyledBodyCellGreen
                          id={`reportPipelineOverviewTableOAAcquired${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-OAAcquired-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >
                          < >
                            {' '}
                            {row.numOA_Acquired === null
                              ? '0'
                              : row.numOA_Acquired}
                          </ >
                        </StyledBodyCellGreen>
                        <StyledBodyCellGreen
                          id={`reportPipelineOverviewTableOAPaperCommission${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-OAPaperCommission-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >
                          < >
                            {' '}
                            {row.numOA_PaperCommission === null
                              ? '0'
                              : row.numOA_PaperCommission}
                          </ >
                        </StyledBodyCellGreen>
                        <StyledBodyCellGreen
                          id={`reportPipelineOverviewTableOASubmissionAndReview${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-OASubmissionAndReview-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >
                          < >
                            {row.numOA_SubmissionAndReview === null
                              ? '0'
                              : row.numOA_SubmissionAndReview}
                          </ >
                        </StyledBodyCellGreen>
                        <StyledBodyCellGreen
                          id={`reportPipelineOverviewTableOAProduction${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-OAProduction-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >
                          < >
                            {row.numOA_Production === null
                              ? '0'
                              : row.numOA_Production}
                          </ >
                        </StyledBodyCellGreen>
                        <StyledBodyCellGreen
                          id={`reportPipelineOverviewTableOAPublishedFy${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-OAPublishedFy-${index}`}
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                        >
                          < >
                            {row.numOA_Published_Fy === null
                              ? '0'
                              : row.numOA_Published_Fy}
                          </ >
                        </StyledBodyCellGreen>
                        <StyledBodyCellGreen
                          id={`reportPipelineOverviewTableOAPublished${index}`}
                          data-selenium-id={`Report-PipelineSubjectTable-OAPublished-${index}`}
                          align='left'
                          className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                        >
                          < >
                            {row.numOA_Published === null
                              ? '0'
                              : row.numOA_Published}
                          </ >
                        </StyledBodyCellGreen>
                      </StyledTableRow>
                    );
                  }
                  return (
                    <StyledTableRow
                      sx={{
                        height: '64px'
                      }}
                    >
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewTableSubjectGroup${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-SubjectGroup-${index}`}
                        align='left'
                        sx={{
                          maxWidth: '150px',
                          position: 'sticky',
                          left: 0,
                          zIndex: 6,
                          backgroundColor: '#F1F3F5',
                        }}
                        className='RightBorder'
                      >
                        <TextLabel
                          id='reportSiOverviewBtSgTextLabel'
                          data-selenium-id={`Report-SiOverview-BtSgTextLabel-${index}`}
                          TooltipText={row.subjectGroupFullName}
                          text={row.subjectGroup ?? row.subjectGroupFullName}
                          ifTooltip={ row.subjectGroup != 'Total'}
                        />
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewTableNumIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-NumIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.solicitedSipCount === null
                          ? '0'
                          : row.solicitedSipCount}
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewTableNumAcquired${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-NumAcquired-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.unsolicitedSipCount === null ? '0' : row.unsolicitedSipCount}
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewTableNumIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-NumIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.numIdeaOrProposal === null
                          ? '0'
                          : row.numIdeaOrProposal}
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewTableNumAcquired${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-NumAcquired-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.numAcquired === null ? '0' : row.numAcquired}
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewTableNumPaperCommission${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-NumPaperCommission-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.numPaperCommission === null
                          ? '0'
                          : row.numPaperCommission}
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewTableNumSubmission${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-NumSubmission-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.numSubmissionAndReview === null
                          ? '0'
                          : row.numSubmissionAndReview}
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewTableNumProduction${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-NumProduction-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.numProduction === null ? '0' : row.numProduction}
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewTableNumPublishedFY${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-NumPublishedFY-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.numPublishedFy === null ? '0' : row.numPublishedFy}
                      </StyledBodyCellPurple>
                      <StyledBodyCellPurple
                        id={`reportPipelineOverviewTableNumPublished${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-NumPublished-${index}`}
                        align='left'
                        className='RightBorder ReportNumberTextColor'
                      >
                        {row.numPublished === null ? '0' : row.numPublished}
                      </StyledBodyCellPurple>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewTableOAIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-OAIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.oaSolicitedSipCount === null
                          ? '0'
                          : row.oaSolicitedSipCount}
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewTableOAIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-OAIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.oaUnsolicitedSipCount === null ? '0' : row.oaUnsolicitedSipCount}
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewTableOAIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-OAIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.numOA_IdeaOrProposal === null
                          ? '0'
                          : row.numOA_IdeaOrProposal}
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewTableOAIdeaOrProposal${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-OAIdeaOrProposal-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.numOA_Acquired === null ? '0' : row.numOA_Acquired}
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewTableOAPaperCommission${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-OAPaperCommission-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.numOA_PaperCommission === null
                          ? '0'
                          : row.numOA_PaperCommission}
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewTableOASubmissionAndReview${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-OASubmissionAndReview-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.numOA_SubmissionAndReview === null
                          ? '0'
                          : row.numOA_SubmissionAndReview}
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewTableOAProduction${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-OAProduction-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.numOA_Production === null
                          ? '0'
                          : row.numOA_Production}
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewTableOAPublishedFY${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-OAPublishedFY-${index}`}
                        align='left'
                        className='NoBorder ReportNumberTextColor'
                      >
                        {row.numOA_Published_Fy === null
                          ? '0'
                          : row.numOA_Published_Fy}
                      </StyledBodyCellGreen>
                      <StyledBodyCellGreen
                        id={`reportPipelineOverviewTableOAPublished${index}`}
                        data-selenium-id={`Report-PipelineSubjectTable-OAPublished-${index}`}
                        align='left'
                        className='RightBorder ReportNumberTextColor'
                      >
                        {row.numOA_Published === null
                          ? '0'
                          : row.numOA_Published}
                      </StyledBodyCellGreen>
                    </StyledTableRow>
                  );
                })
              : !loading && (
                  <ReportNoData id='reportSiOverviewSGNoResultTableCell' />
                )}
            <TableRow></TableRow>
          </TableBody>
        </Table>
        {loading && (
          <Box
            data-selenium-id='Report-PipelineSubjectTable-LoadingBox'
            sx={{
              width: 'auto',
              marginTop: '150px',
              marginLeft: '50%',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </TableContainer>
    </TableBox2>
  );
}

import EncryptedTextField, {
  StyledTextField,
} from '../Components/EncryptedTextField';
import styled from '@emotion/styled';
import React, { useState, useEffect, useRef} from 'react';
import { Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  loginFailedClear,

} from '@/actions/SIMT-User/Login/LoginAction';
import {
  ifCaptcha,
  removeFailedLogin,
  setFailedLoginNum,
} from '@/utils/localStorageHandler';
import { cookieSet } from '@/utils/axios_instance';
import StyledCheckbox from '../Components/StyledCheckBox';
import MyCaptcha from '../Components/MyCaptcha';
import InvalidEmailOrPassword from '@/assets/LoginAsset/ForgotPageInvalidEmail.svg';
import Lock from '@/assets/LoginAsset/Lock.svg';
import LockCountDown from '@/assets/LoginAsset/LockCountDown.svg';
import LockCountDown2 from '@/assets/LoginAsset/LockCountDown2.svg';
import LockCountDown3 from '@/assets/LoginAsset/LockCountDown3.svg';
import LoginWelcome from '@/assets/LoginAsset/LoginWelcome.svg';
import WileyLogoBlack from '@/assets/LoginAsset/WileyLogoBlack.svg';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import Footer from "../../../../components/Footer";
import {getLoginFailCount, login} from "@/actions/SIMT-User/Login";
import styles from './index.module.scss';

const LoginButton = styled(Button)({
  borderRadius: '5px',
  background: 'var(--primary-500, #0052CC)',
  color: 'var(--shades-white, #FFF)',
  textAlign: 'center',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  lineHeight: '18px',
  width: '400px',
  height: '40px',
  padding: '8px 12px',
  gap: '6px',
  '&:hover': {
    background: 'var(--primary-300, #6D98EE)',
  },
  '&:active':{
    background: 'var(--primary-600, #113D95)',
  },
  '&:disabled': {
    background: 'var(--gray-200, #DFE4E8)',
  },
});

const LoginTitleTypography = styled(Typography)({
  color: 'var(--gray-500, #596A7C)',
  lineHeight: '125%',
  letterSpacing: '0.48px',
  fontSize: '48px',
  fontFamily: '"Open Sans", sans-serif',
  fontWeight: 800,
  leadingTrim: 'both',
  textEdge: 'cap',
});

const InfoTypography = styled(Typography)({
  color: 'var(--gray-300, #BCC5CF)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  lineHeight: '125%',
});

const LabelTypography = styled(Typography)({
  color: 'var(--gray-500, #596A7C)',
  leadingTrim: 'both',
  textEdge: 'cap',
  fontSize: '18px',
  fontFamily: 'Open Sans',
  fontWeight: '600',
  lineHeight: '125%',
});

const ForgetPasswordTypography = styled(Typography)({
  color: 'var(--primary-500, #154AB6)',
  textAlign: 'center',
  fontSize: '14px',
  fontFamily: 'Open Sans',
  lineHeight: '20px',
  textTransform: 'capitalize',
  fontWeight: 400,
  '&:hover': {
    fontWeight: 600,
  },
});

const BasicLogin = props => {
  const dispatch = useDispatch();
  const selector = state => {
    return {
      ifFirst: state.Login.ifFirst,
      errMsg: state.Login.errorMsg,
      isAuthenticated: state.Login.isAuthenticated,
      count: state.Login.loginFailCount,
    };
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [hCaptchaToken, setHCaptchaToken] = useState('');

  const { ifFirst, errMsg, isAuthenticated, count } = useSelector(selector);
  //Lock Logic
  // count === 5 ? <Lock /> : null

  // ifCaptcha(); removeFailedLogin(); setFailedLoginNum();
  // const [needRequestCaptcha, setNeedRequestCaptcha] = useState(false); ifCaptcha();
  const [needCaptcha, setNeedCaptcha] = useState(ifCaptcha());
  const [Captcha, setCaptcha] = useState(false);
  const [helperTextShow, setHelperTextShow] = useState(false);

  useEffect(() => {
    handleLogin();
    return () => {
      dispatch(loginFailedClear());
    };
  }, [isAuthenticated, errMsg]);

  const navigate = useNavigate();

  const handleForgotPassword = () => {
    navigate('/simt/ForgotPassword');
  };

  const handleLogin = () => {
    if (isAuthenticated) {
      removeFailedLogin();
      setNeedCaptcha(ifCaptcha());
      if (ifFirst === true) {
        navigate('/simt/firstReset', { state: { email: email } });
      } else {
        setTimeout(() => {
        navigate('/simt/auth/index');
        });
      }
    } else {
      if (errMsg !== null) {
        setCaptcha(false);
        setHelperTextShow(true);
        setFailedLoginNum();
        setNeedCaptcha(ifCaptcha());
        dispatch(getLoginFailCount({ email: email }));
      }
    }
  };

  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const [scaleToUse, setScaleToUse] = useState(1);
  const originWidth = 1440;
  const originHeight = 800;

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const newScaleX = screenWidth / originWidth;
      const newScaleY = screenHeight / originHeight;
      setScaleX(Math.max(newScaleX, 1));
      setScaleY(Math.max(newScaleY, 1));
      setScaleToUse(Math.min(newScaleX, newScaleY));
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [CaptchaAlertShow, setCaptchaAlertShow] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    if (needCaptcha === true && Captcha === false) {
      setCaptchaAlertShow(true);
      return;
    }
    var rememberFlag = rememberMe && cookieSet() ? 'on' : 'off';
    await login({
        email: email,
        password: password,
        remember_flag: rememberFlag,
        hCaptchaToken: hCaptchaToken,
      })(dispatch);
  };

  const countDownComponents = [
    { value: 3, component: <LockCountDown3 /> },
    { value: 2, component: <LockCountDown2 /> },
    { value: 1, component: <LockCountDown /> },
  ];

  const handleEmailCheck = (email) => {
    dispatch(getLoginFailCount({ email: email }));
  };

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        // transform: `scale(${scaleX}, ${scaleY})`,
        // transformOrigin: 'top left',
        background: '#FFF',
      }}
      data-selenium-id='Login_NewLogin_BasicLogin'
    >
      <div
        className={styles.basicLoginLeft}
        data-selenium-id='Login_NewLogin_BasicLogion-LeftBackground'
      >
        <div
          style={{
            paddingTop: '13px',
            marginLeft: '16px', // as same as header
          }}
        >
          <WileyLogoBlack/>
        </div>
        <div
          style={{
            marginTop: '164.5px',
            // marginLeft: '74px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoginWelcome />
        </div>
      </div>
      <div
        style={{
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          position: 'relative',
        }}
        data-selenium-id='Login_NewLogin_BasicLogion-RightPart'
      >
        <div
          style={{
            // marginTop: `${scaleToUse*148 - 250 * (1-scaleToUse)}px`,
            // height: '500px',
            // transform: `scale(${scaleToUse}, ${scaleToUse})`,

            //这里新换组件后，原来内容在小屏幕内会溢出，改成内容居中
            display: 'flex',
            flexDirection: 'column',
            margin:'auto ',
          }}
          data-selenium-id='Login_NewLogin_BasicLogion-RightPart-Content'
        >
          <div data-selenium-id='Login_NewLogin_BasicLogion-RightPart-Title'>
            <div>
              <LoginTitleTypography>Log in</LoginTitleTypography>
            </div>
          </div>
          <div style={{ marginTop: '20px' }}>
            <div style={{ display: count === 5 ? 'block' : 'none' }}>
              <Lock />
            </div>
            <div
              style={{
                display: count !== 5 && !helperTextShow ? 'block' : 'none',
                height: '51px',
              }}
              data-selenium-id='Login_NewLogin_BasicLogion-RightPart-Info'
            >
              <InfoTypography>Enter your account details below</InfoTypography>
            </div>
            <div
              style={{
                display: count !== 5 && helperTextShow ? 'block' : 'none',
              }}
              data-selenium-id='Login_NewLogin_BasicLogion-RightPart-InvalidEmailOrPassword'
            >
              <InvalidEmailOrPassword />
              <div style={{ height: '8px' }} />
              <div>
                {
                  countDownComponents.find(item => item.value === 5 - count)
                    ?.component
                }
              </div>
            </div>
          </div>
          <div
            style={{ marginTop: '26px' }}
            data-selenium-id='Login_NewLogin_BasicLogion-RightPart-EmailLabel'
          >
            <LabelTypography>Email</LabelTypography>
          </div>
          <div
            style={{ marginTop: '8px' }}
            data-selenium-id='Login_NewLogin_BasicLogion-RightPart-EmailInput'
          >
            <MyOnlyText
              ref={emailRef}
              placeholder='Your email address'
              multiline={false}
              value={email}
              setValue={
                (v)=>{
                  setEmail(v);
                  handleEmailCheck(v);
                }}
              isError={helperTextShow}
              height='40px'
              width='400px'
              needEndAdornment={true}
              handleIconButtonClick={() => {
                setEmail('');
              }}
              onFocus={() => {
                setHelperTextShow(false);
              }}
              onKeyPress={(e)=>{
                if (e.key === 'Enter'){
                  handleSubmit(e);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Tab') {
                  e.preventDefault();
                  passwordRef.current.focus();
                }
              }}
            />
          </div>
          <div
            style={{
              marginTop: '29px',
              display: 'flex',
              flexDirection: 'row',
            }}
            data-selenium-id='Login_NewLogin_BasicLogion-RightPart-PasswordLabel'
          >
            <LabelTypography>Password</LabelTypography>
            <Button
              sx={{
                marginLeft: '201px',
                padding: '0px',
                '&:hover': {
                  background: 'transparent',
                  marginLeft: '198px',
                },
              }}
              disableRipple={true}
              disableTouchRipple={true}
              onClick={handleForgotPassword}
              data-selenium-id='Login_NewLogin_BasicLogion-RightPart-ForgotPassword'
            >
              <ForgetPasswordTypography>
                Forget Password?
              </ForgetPasswordTypography>
            </Button>
          </div>
          <div
            style={{ marginTop: '8px' }}
            data-selenium-id='Login_NewLogin_BasicLogion-RightPart-PasswordInput'
          >
            <EncryptedTextField
              ref={passwordRef}
              height='40px'
              width='400px'
              isError={helperTextShow}
              value={password}
              setValue={v => setPassword(v.replace(/\s/g, '').slice(0, 32))}
              onFocus={() => {
                setHelperTextShow(false);
              }}
              onKeyPress={(e)=>{
                if (e.key === 'Enter'){
                  handleSubmit(e);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Tab') {
                  e.preventDefault();
                  emailRef.current.focus();
                }
              }}
            />
          </div>
          <div
            style={{
              marginTop: '11px',
              display: 'flex',
              flexDirection: 'row',
            }}
            data-selenium-id='Login_NewLogin_BasicLogion-RightPart-RememberMe'
          >
            <StyledCheckbox
              style={{
                width: '18px',
                height: '18px',
              }}
              onClick={() => {
                setRememberMe(!rememberMe);
              }}
              text='Remember me'
            />
          </div>
          <div
            style={{
              marginTop: '30px',
              // marginLeft: '100px',
              // height: count===5? '10px':'40px',
              // width: '200px',
              display: (count>=2 && count !== 5 && helperTextShow && !needCaptcha ) ? 'none': 'flex',
              flexDirection:'column',
              alignItems: 'center',
              
            }}
            data-selenium-id='Login_NewLogin_BasicLogion-RightPart-Captcha'
          >
            {(needCaptcha && count!==5) ? (
              <MyCaptcha
                Captcha={Captcha}
                setCaptcha={() => {
                  setCaptcha(true);
                  setCaptchaAlertShow(false);
                }}
                alert={CaptchaAlertShow}
                hCaptchaToken={hCaptchaToken}
                setHcaptchaToken={setHCaptchaToken}
              />
            ) : (
              <div></div>
            )}
          </div>
          <div
            style={{ marginTop: '30px' }}
            data-selenium-id='Login_NewLogin_BasicLogion-RightPart-LoginButton'
          >
            <LoginButton
              onClick={handleSubmit}
              disabled={helperTextShow || CaptchaAlertShow}
              disableRipple={true}
            >
              Login
            </LoginButton>
          </div>
        </div>
        <Footer sx={{
          // position: 'absolute',
          // bottom: 0,
          background: '#FFF',
          color: '#596A7C !important',
          borderWidth: 0,
          '& a,span': {
            color: '#596A7C !important',
          },
        }}/>
      </div>
    </div>
  );
};

export default BasicLogin;

import { useState, useEffect, useRef } from 'react';
import RobotIcon from '@/assets/LoginAsset/RobotIcon.svg';
import CheckBoxDefaultSelected from '../../../../assets/LoginAsset/CheckBoxDefaultSelected.svg';
import CheckBoxDefaultUnselected from '../../../../assets/LoginAsset/CheckBoxDefaultUnselected.svg';
import { Typography, Dialog } from '@mui/material';
import VerifySlide from '@/modules/Captcha';
import styled from '@emotion/styled';
// import HCaptcha from '@hcaptcha/react-hcaptcha';
import axios from 'axios';
function getSiteKey() {
  if (window.location.hostname.includes('dev')) {
    return '0d209820-759e-475c-8758-6ffaaf656388';
  } else if (window.location.hostname.includes('qa')) {
    return 'c5b482a5-3601-48d2-a509-5abdeb57db96';
  } else if (window.location.hostname.includes('uat')) {
    return '68a1a1ef-b778-4862-a7ff-9329fb4d3a49';
  } else if (
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('127.0.0.1') ||
    window.location.hostname.includes('47.103.142.157')) {
    //开发环境、aliyun环境暂且使用dev的sitekey
    return '0d209820-759e-475c-8758-6ffaaf656388';
  }
  //其他主机名认定为Product环境 
  return '5c79273c-356c-4bef-be20-cf3d57057ba3';
}

const HCAPTCHA_DEFAULT_HOST = "https://js.hcaptcha.com/1/api.js";
const HCAPTCHA_CHINA_HOST = "https://cn1.hcaptcha.com/1/api.js?endpoint=https://cn1.hcaptcha.com&assethost=https://assets-cn1.hcaptcha.com&imghost=https://imgs-cn1.hcaptcha.com&reportapi=https://reportapi-cn1.hcaptcha.com";
const IAmNotRobotTypography = styled(Typography)({
  color: 'var(--gray-500, #596A7C)',
  fontSize: '12px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
});
function loadScript(url, timeout = 3000) {
  const head = document.getElementsByTagName("head")[0];
  const js = document.createElement("script");
  js.setAttribute("type", "text/javascript");
  js.setAttribute("defer", true);//需要取消阻塞，因为在国内加载不出来会导致其他内容不执行
  js.setAttribute("src", url);
  head.appendChild(js);
  return new Promise((resolve, reject) => {
    js.onload = function () {
      resolve();
      try { head.removeChild(js); } catch (e) { }
    };
    js.onerror = function () {
      reject();
      try { head.removeChild(js); } catch (e) { }
    };
    setTimeout(() => {
      reject();
      try { head.removeChild(js); } catch (e) { }
    }, timeout);
  });
}
const isChina = localStorage.getItem('isChina') === 'true';
function MyCaptcha(props) {
  const {
    Captcha,//这个props，感觉像是表示验证码通过的布尔值
    setCaptcha,
    hCaptchaToken = '',
    setHcaptchaToken = () => { },
  } = props;
  const hcaptchaRef = useRef(null);  //发现重复加载组件需要手动调用render，所以把ref又加回来了
  useEffect(() => {
    if (!hasLoadedHcaptchaApi) {
      hasLoadedHcaptchaApi = true;
      //加载API，解决国内访问hcaptcha的问题
      if (isChina) {
        loadScript(HCAPTCHA_CHINA_HOST).catch(() => {
          localStorage.setItem('isChina', false);
          loadScript(HCAPTCHA_DEFAULT_HOST, 15000).catch(() => {
            console.error("net error");
            hasLoadedHcaptchaApi = false;
          });
        });
      } else {
        loadScript(HCAPTCHA_DEFAULT_HOST).catch(() => {
          localStorage.setItem('isChina', true);
          loadScript(HCAPTCHA_CHINA_HOST, 15000).catch(() => {
            console.error("net error");
            hasLoadedHcaptchaApi = false;
          });
        });
      }
    } else {
      //如果已经加载过script标签且再次渲染了此组件，则需要手动调用render
      setTimeout(() => {
        try {
          hcaptcha.render(hcaptchaRef.current);
        } catch (e) { };
      }, 100);
    }

    //回调函数（在全局定义）
    hCaptchaCallback = (token) => {
      setHcaptchaToken(token);
      setCaptcha(true);
    };

  }, []);
  useEffect(() => {
    if (Captcha !== true) {
      try {
        hcaptcha.reset();
      } catch (e) {
        console.log(e);
      };
    }
  }, [Captcha]);


  const height = props.height ? props.height : '80px';
  const width = props.width ? props.width : '306px';
  const alert = props.alert ? props.alert : false;

  return (
    <div
      style={{
        height: height,
        width: width,
        border: !alert
          ? '2px solid var(--gray-200, #DFE4E8)'
          : '2px solid var(--error-600, #EE5350)',
        boxSizing: 'border-box',
        borderRadius: '4px',
        background: '#FFF',
      }}
    >
      <form id="form">
        <div
          ref={hcaptchaRef}//发现重复加载组件需要手动调用render，所以把ref又加回来了
          className="h-captcha"
          data-sitekey={getSiteKey()}
          data-callback={"hCaptchaCallback"}
        ></div>
      </form>
    </div>
  );
}

export default MyCaptcha;

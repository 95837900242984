import {
  Table,
  TableRow,
  TableBody,
  tableCellClasses,
  TableCell,
} from '@mui/material';
import { useState } from 'react';
import { MyTableHeader } from '@/components/DataTable';
import { styled } from '@mui/system';
import { FormattedMessage, useIntl } from 'react-intl';
import HideText from '../Submodule/HideText';
import { SingleKV } from '../Submodule/SingleKV';
const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E8F2',
    color: '#505050',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '0.01em',
    width: '100px !important',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '54px',
    paddingTop: 0,
    paddingBottom: 0,
    maxWidth: '100px !important',
    // paddingLeft: '24px',
  },
}));

export default function EbmTable(props) {
  const {ebmList} = props;
  const createRow = (
    id,
    label,
    minWidth,
    maxWidth,
    isOrder = true,
    align = 'left'
  ) => {
    return { id, label, minWidth, maxWidth, isOrder, align };
  };
  const tableHeads = [
    createRow('ebmName', 'EBM NAME', '230px', '260px', false),
    createRow('email', 'EMAIL', '230px', '260px', false),
    createRow('status', 'STATUS', '193px', '133px', false),
    createRow('comments', 'COMMENTS', '439px', '439px', false),
  ];
  const tableData = ebmList;

  return (
    <Table
      data-selenium-id='SIP_DetailPage_GE-Read-Box-Table'
      stickyHeader
      aria-label='custom pagination table'
      sx={{
        minWidth: 500,
        letterSpacing: '0.01em',
        color: '#262E35',
        boxShadow: 'none',
      }}
    >
      <MyTableHeader
        data-selenium-id='SIP_DetailPage_GE-ReadTable-MyHeader'
        items={tableHeads}
        backgroundColor='#F1F3F5'
        color='#596A7C'
        CheckboxColor='wileyBlue2'
      />
      <TableBody data-selenium-id='SIP_DetailPage_GE-ReadTable-Body'>
        {!tableData||tableData.length === 0 ? (
          <TableRow
            id='ebmPanelNoData'
            data-selenium-id='SIP_DetailPage_ebmPanelNoData'
            // style={{ height: '300px' }} //modified
          >
            <TableCell
              data-selenium-id='SIP_DetailPage_GE-ReadTable-Cell'
              colSpan={tableHeads.length}
              sx={{
                textAlign: 'center',
                fontSize: '14px',
                color: '#98A7B6',
                letterSpacing: '.01em',
                paddingY: '12px',
                borderBottomStyle: 'none',
              }}
            >
              No comments received
            </TableCell>
          </TableRow>
        ) : (
          tableData.map((row, rowindex) => {
            return (
              <TableRow
                key={rowindex}
                data-selenium-id={'SIP_DetailPage_GE-ReadTable-Row' + rowindex}
                sx={{
                  '&:hover': {
                    background: '#E8EDFB',
                    maxWidth: '200px',
                  },
                  maxWidth: '200px',
                  background: '#FFFFFF',
                }}
              >
                {tableHeads.map((item, index) => (
                  <StyledTableCell
                    data-selenium-id='SIP_DetailPage_GE-ReadTable-StyledCell'
                    sx={{
                      color: '#262E35',
                      paddingX: '24px',
                      maxWidth: '200px',
                      borderBottom:
                        rowindex === tableData.length - 1 ? 'none' : '',
                      '&:last-child': {
                        maxWidth: 'unset !important',
                        '&>div:only-child': {
                          width: 'max-content',
                        },
                      },
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'warp',
                    }}
                    key={index}
                    align={item.align}
                  >
                    {row[item.id]}
                  </StyledTableCell>
                ))}
              </TableRow>
            );
          })
        )}
      </TableBody>
    </Table>
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import GeneralSettings from './GeneralSettings';
import { FormattedMessage } from 'react-intl';
import { NewDesignedMain } from '../../../components/Main';
import { useSelector } from 'react-redux';
import { tabClasses, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DropdownValues from './DropdownValues/DropdownValues';

const SystemTab = styled(Tab)(({ theme }) => ({
  [`&.${tabClasses.root}`]: {
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: 600,
    color: '#596A7C',
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: '1px',
  },
  [`&.${tabClasses.selected}`]: {
    color: theme.palette.wileyBlue2.main,
  },
}));

export default function SystemConfiguration() {
  const selector = state => {
    return {
      sidebarOpen: state.UserManage.siderOpen,
    };
  };
  const { sidebarOpen } = useSelector(selector);
  // tab选择的state
  const [value, setValue] = React.useState('general settings');

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <NewDesignedMain
      open={sidebarOpen}
      data-seleunim-id='SystemAdmin_SystemConfiguration-NewDesignedMain'
      sx={{paddingTop: '24px', paddingLeft: '20px'}}
    >
      <Box
        sx={{
          background: 'white',
          display: 'flex',
          width: '681px',
          padding: '32px',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
        data-seleunim-id='SystemAdmin_SystemConfiguration-NewDesignedMain-Box'
      >
        {/*大标题*/}
        <Typography
          data-seleunim-id='SystemAdmin_SystemConfiguration-NewDesignedMain-Box-Typography'
          color='Grey600.main'
          textTransform='capitalize'
          fontWeight={600}
          fontSize={22}
          lineHeight='125%'
          fontStyle='normal'
        >
          <FormattedMessage
            id='sysConfig.sysConfig'
            data-seleunim-id='SystemAdmin_SystemConfiguration-NewDesignedMain-Box-Typography-FormattedMessage-SysConfig'
          />
        </Typography>
        {/*tab组件和选择后显示的内容*/}
        <Box
          data-seleunim-id='SystemAdmin_SystemConfiguration-NewDesignedMain-Box-Box'
        >
          <TabContext
            value={value}
            data-seleunim-id='SystemAdmin_SystemConfiguration-NewDesignedMain-Box-Box-TabContext'
          >
            <Box
              sx={{paddingTop: '10px'}}
              data-seleunim-id='SystemAdmin_SystemConfiguration-NewDesignedMain-Box-Box-TabContext-Box'
            >
              <TabList
                id='systemAdminSystemConfigurationTabList'
                data-seleunim-id='SystemAdmin_SystemConfiguration-NewDesignedMain-Box-Box-TabContext-Box-TabList'
                onChange={handleChange}
                aria-label='system configuration tabs'
              >
                <SystemTab
                  sx={{paddingTop: '16px', paddingBottom: '16px'}}
                  id='generalSettingsTab'
                  data-seleunim-id='SystemAdmin_SystemConfiguration-NewDesignedMain-Box-Box-TabContext-Box-TabList-SystemTab1-GeneralSettingsTab'
                  label={<FormattedMessage id='sysConfig.gs' />}
                  value='general settings'
                />
                <SystemTab
                  sx={{paddingTop: '16px', paddingBottom: '16px'}}
                  id='dropdownValuesTab'
                  data-seleunim-id='SystemAdmin_SystemConfiguration-NewDesignedMain-Box-Box-TabContext-Box-TabList-2-DropdownValuesTab'
                  label={<FormattedMessage id='sysConfig.dv' />}
                  value='dropdown values'
                />
              </TabList>
            </Box>
            <TabPanel
              sx={{padding: '10px 0px',width:'617px'}}
              value='general settings'
              data-seleunim-id='SystemAdmin_SystemConfiguration-NewDesignedMain-Box-Box-TabContext-TabPanel1'
            >
              <GeneralSettings />
            </TabPanel>
            <TabPanel
              sx={{padding: '10px 0px'}}
              value='dropdown values'
              data-seleunim-id='SystemAdmin_SystemConfiguration-NewDesignedMain-Box-Box-TabContext-TabPanel2'
            >
              <DropdownValues />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </NewDesignedMain>
  );
}

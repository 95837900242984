import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const WiderMain = styled(Box, { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open, drawerWidth, marginAddPx, widthpx, height }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: marginAddPx ? marginAddPx : '0px',
    marginRight: '33px',
    minHeight: height ?? window.screen.height,
    width: widthpx ? widthpx : '100%',
    background: '#ffffff',
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth ? drawerWidth : '240px',
    }),
    marginTop: '50px',
    paddingLeft: '33px',
    paddingRight: '78px',
    paddingTop: '30px',
    paddingBottom: '74px',
  })
);

export default WiderMain;

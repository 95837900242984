import { Box, Stack, Typography } from '@mui/material';
import Texterror from '@/assets/Texterror.svg';

export default function CustomErrorTip(props) {
  const { error, text } = props;

  return error ? (
    <Stack
      direction='row'
      alignItems='center'
      style={{ color: '#DE350B', fontSize: '14px', marginTop: '4px' }}
      data-selenium-id='SIP_DetailPage_Submodule-CustomErrorTip-Stack'
    >
      <Texterror data-selenium-id='SIP_DetailPage_Submodule-CustomErrorTip-Text' />
      {text}
    </Stack>
  ) : (
    <></>
  );
}

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { SmallBoldFont, SmallFont } from '../../FontStyle';

export const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#4C81EB',
  },
});

export const StyledTab = styled(props => <Tab disableRipple {...props} />)({
  textTransform: 'none',
  width: '50%',
  height: '40px',
  borderBottom: '2px solid #DFE4E8',
  ...SmallFont,
  color: '#596A7C',
  '&:hover': {
    ...SmallFont,
    color: '#596A7C',
  },
  '&.Mui-selected': {
    color: '#161616',
    letterSpacing: '0.01em',
    ...SmallBoldFont,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
    ...SmallBoldFont,
  },
});

import CheckBoxDefaultSelected from '../../../../assets/LoginAsset/CheckBoxDefaultSelected.svg';
import CheckBoxDefaultUnselected from '../../../../assets/LoginAsset/CheckBoxDefaultUnselected.svg';
import CheckBoxHoverSelected from '../../../../assets/LoginAsset/CheckBoxHoverSelected.svg';
import CheckBoxHoverUnselected from '../../../../assets/LoginAsset/CheckBoxHoverUnselected.svg';
import styled from '@emotion/styled';
import { Checkbox, Typography } from '@mui/material';
import { useState } from 'react';

const StyledCheckbox = styled(Checkbox)({

});

const StyledTypography = styled(Typography)({
  color: 'var(--gray-600, #262E35)',
  textAlign: 'center',
  fontSize: '14px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
});

const StyledTypographyHover = styled(Typography)({
  color: 'var(--gray-600, #262E35)',
  textAlign: 'center',
  fontSize: '14px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '18px',
});


const CustomCheckbox = props => {
  const [isHovered, setIsHovered] = useState(false);
  const text = props.text;
  const TextExist = text.length > 0;

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', margin:'0px', padding:'0px' }}
    >
      <StyledCheckbox
        icon={
          !isHovered ? (
            <CheckBoxDefaultUnselected />
          ) : (
            <CheckBoxHoverUnselected />
          )
        }
        checkedIcon={
          !isHovered ? <CheckBoxDefaultSelected /> : <CheckBoxHoverSelected />
        }
        style={{
          padding: '0px',
          margin: '0px',
        }}
        onClick = {props.onClick}
        // {...props}
      />
      <div style={{marginLeft:'12px', display: TextExist? 'block': 'none'}}>
        {isHovered ? (
          <StyledTypographyHover>{text}</StyledTypographyHover>
        ) : (
          <StyledTypography>{text}</StyledTypography>
        )}
      </div>
    </div>
  );
};

export default CustomCheckbox;

/*
  1. 将所有字符串常量放到下面的messages中，当前建议按照【页面】或【功能】进行嵌套，
     虽然可能会产生重复的字符串，但是能给日后的维护带来很大便利；
  2. value建议【尽量】直接使用小写（不强制，根据实际变化），首字母大写或全部大写交给text-transform完成，
     这样能尽可能减少重复的字符串，但是不排除部分特殊情况需要有大小写变化的，也可以直接写在messages里面；
  3. key使用驼峰式编码规范
  4. 不知如何归类的，或者不必新开一个类别来嵌套的，直接放到根下，或者common里
 */
export const messages = {
  month: {
    // 简写
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may: 'May', // 没有"."
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dec',
    // 全称
    january: 'January',
    february: 'February',
    // ...
  },
  sideBar: {
    si: 'special issue',
    ge: 'Guest Editor',
    overview: 'Overview',
    userAndSystem: 'User & System',
    systemLog: 'System Log',
    userManagement: 'User Management',
    roleManagement: 'Role Management',
    systemConfiguration: 'System Configuration',
    emailTemplates: 'Email Templates',
    annualTarget: 'Annual Target',
    reportJobs: 'Report Jobs',
    exportData: 'Export Data',
    report: 'report',
    journal: 'Journal',
    prpContacts: 'PRP Contact',
    catJournal: 'SCA Journal',
    siPipeline: 'SI Pipepline',
    acquiredAndActiveSI: 'Acquired & Active SI',
    articles: 'Articles',
    targets: 'Targets',
    sip: 'Special Issue Proposal',
    sipReport: 'SIP Report',
    userAndSystemFolded: 'U&S',
    customizeEmail: 'Customize Email Template',
    customizeEmailFolder: 'Email',
    siFolded: 'SI',
    sipFolded: 'SIP',
    sipExtended: 'SI Proposal',
    ChinaMarketing: 'China Marketing',
    ChinaMarketingFolded: 'CM',
    ChinaMarketingGE: 'China Marketing (Guest Editor)',
    ChinaMarketingSI: 'China Marketing (Special Issue)',
  },
  bread: {
    userManagement: 'User Management',
    roleManagement: 'Role Management',
    systemConfiguration: 'system configuration',
    emailTemplates: 'Email Templates',
    annualTarget: 'annual target',
    reportJobs: 'report jobs',
    report: 'report',
    journal: 'Journal',
    prpContacts: 'PRP Contacts',
    catJournal: 'SCA Journal',
  },
  table: {
    // ref https://formatjs.io/docs/react-intl/components#message-syntax
    // `selectedText`能够自动区分0选择(不显示)、1个选项(item)、多个选项(items)
    selectedText:
      '{num, plural,' +
      '=0 {}' +
      'one {Selected Item:}' +
      'other {Selected Items:}' +
      '}',
    rowsPerPage: 'Rows per page: ',
  },
  filter: {},
  role: {
    newRole: 'new role',
    roleNameDuplicatePop:
      'The role name you entered is a duplicate value, please revise before saving.',
    loadFailed: 'Failed to load the role detail. Please try again later.',
  },
  // 通用名动词放到这里
  common: {
    mergeFailed: 'Merge failed!',
    mergeSuccess: 'You merged successfully!',
    merge: 'Merge Profile',
    tooLessGE: 'Please select two GE profiles to merge',
    tooManyGE: 'You cannot merge more than two GE profiles',
    si: 'special issue',
    sis: 'special issues',
    ge: 'guest editor',
    ges: 'guest editors',
    export: 'export',
    exportOGAll: 'Export All',
    Export: 'Export',
    exportAll: 'Export all', // 这个特殊，不要动大小写
    exportCSV: 'Export to CSV',
    exportExcel: 'Export to Excel',
    exportCFPData: 'Export CFP data',
    exportToEXCEL: 'Export to EXCEL',
    exportToCSV: 'Export to CSV',
    csv: 'CSV',
    excel: 'Excel',
    filter: 'Filter',
    search: 'search',
    clear: 'clear',
    reset: 'reset',
    create: 'Create',
    failToLoadOcc: 'Failed to load {value}.',
    noResult: 'There is no result for {value}',
    // error 句子
    error404Occ: '404 HTTP NOT FOUND error occurred.',
    error500Occ: 'Internal server error occurred.',
    error400Occ: '400 error occurred.',
    errorTimeoutOcc: 'Timeout error occurred. Please try again.',
    errorundefinedOcc:
      'Network issue or other error occurred. Please try again',
    errWithRawMsg:
      'Network issue or other error occurred. The raw response is `{value}`',

    // error 短语
    errorundefined: 'network issue or other error',
    error404: '404 HTTP NOT FOUND error',
    error500: 'Internal server error',
    error400: '400 error',
    errorTimeout: 'timeout error',

    failToLoad: 'Failed to load {value}.',
    failToLoadWithReason: 'Failed to load {value} due to {reason}.',
    failToExport: 'Failed to export {value} due to {reason}.',

    failToDownload:
      'Error occurred when downloading file. Please try again later.',
    // 按钮通用
    cancel: 'CANCEL',
    save: 'SAVE',

    largeSizeFail:'You can only export a maximum of 1000 items at once.',
  },
  user: {
    createSucc: 'Normal user {fullname} has been created.',
    updateSucc: 'Normal user {fullname} has been updated.',
    activateSucc: 'The user has already been activated.',
    deactivateSucc: 'Successfully deactivated this user.',
    userHasSI:
      'Cannot inactivate this user, as there are associated SIs, please transfer the SIs firstly!',
    success: 'Operation success',
    resendMsg: 'Invitation link has been resent to {email}.',
    resetMsg: 'Normal user {fullName}\'s password has been reset.',
    deleteMsg: 'Normal user {fullName} has been removed.',
    unlockMsg: 'Normal user {fullName} has been unlocked.',
    inactiveMsg: 'Normal user {fullName} has been inactived.',
  },
  account: {
    userProfile: 'user profile',
    group: 'Group',
    role: 'Role',
    updatePassword: 'Update Password',
    logout: 'Log out',
    username: '{lastName}, {firstName}',
  },

  // Email Templates 相关通用
  email: {
    emailTemplates: 'Email Templates',
    emailCustomizeTemplates: 'Customize Email Template',

    manuallySentEmail: 'Manually Sent Emails',
    SISetInSubmission: 'SI Set Up in Submission System',
    SITitleAndGERemove: 'SI Title and GE Access Remove from Submission System',
    GEGuidelineShare: 'Guest Editor Guideline Sharing',
    monthlyReportOfSI:
      'Monthly Submission Report of Special Issue Status for GE and EiC',
    finalTaskForGE:
      'Final Tasks for GE: Draft Editorial and Provide Running Order',
    thankLetter: 'Thank-you Letter to GE',
    JournalLevelSIStatusSharing: 'Journal level SI status sharing',
    BannerPromotionAdjustmentWhenSubmissionDeadlineIsChanged:
      'Banner Promotion Adjustment when Submission Deadline is changed',

    autoSentEmailByDeadline: 'Automatically Sent Emails Triggered by Deadline',
    XDayBeforeDeadline: 'X Days before Submission Deadline',
    XDayAfterDeadline: 'X Days after Submission Deadline',
    publishedIssue:
      'Published Issue Promotion - X days after Actual Publication Date',

    autoSentEmailByAction: 'Automatically Sent Emails Triggered by Action',
    bulkSITransfer: 'Bulk SI transfer',
    singleSITransfer: 'Single SI transfer',

    subject: 'Subject',
    from: 'From',
    to: 'To',
    copy: 'Copy',
    bcc: 'Bcc',
    requireAttachment: 'Require Attachment',
    emailContent: 'Email Content',
    updateSucc: 'This template will now be used throughout the SIMT.',
    updateSuccCustomize: 'This template will now be used in next action.',
    customUpdateSuccessTitle: 'Successfully save customized template!',
    customUpdateSuccessContent:
      'This template will now be used in next action.',
    updateError:
      'An unexpected error has occurred. The raw error message is: {value}. \n You can provide the information to the dev team for further support.',
    dropSucc: 'Drop your customized template successfully.',
    customResetSuccessTitle: 'Successfully reset customized template!',
    customResetSuccessContent:
      'This template has been reset to system template.',
    updateTimeout:
      'Update email template timeout. You content was kept but not saved. Please try again.',
    loadError:
      'Failed to load email templates. Please refresh the page and try again.',
    redDotLoadError:
      "Failed to load email templates' red dots. Please refresh the page and try again.",
    showError: 'Failed to load email. Please refresh the page and try again.',
    send: 'Send',
    errorStage: 'There is no email template for SI at this stage.',
    patternError: 'Please enter the email address with correct format.',
    nullError: 'Please add at least one valid email address!',
    lengthError: 'The length of email should be less than 100.',
    repeatError: 'Please do not enter the same email address!',
    attachmentSizeError: 'The size of the attachment should be less than 50M!',
    attachmentFormatError: 'This file format is not supported!',
    attachmentUploadError:
      'Failed to upload one or more attachments dur to network issue. Please try again.',
    attachmentUploadEmptyError:
      'The Attachment is empty, please check the file completeness',
    sendError: 'Failed to send email. Please refresh the page and try again.',
    sendSuccess: 'Send the email successfully.',

    ManuallySendingEmails: 'Manually sending emails',
    AutomaticallySendingEmails: 'Automatically sending emails',
    TagTip: "Please don't change any tag inside the templates.",
    wileyEmailError:
      'Only Wiley email can be used in the "from" field(sender).',
  },

  siPage: {
    pageTitle: 'special issue overview',
    searchPlaceholder: 'Search SI title (use comma to separate keywords)',
    noSelectionExport: 'Please select at least one SI to transfer/export.',
    exportCSV: 'Export to CSV',
    exportExcel: 'Export to Excel',
    columns: 'Columns',
    transfer: 'Bulk Transfer',
    filterLoadSuccess: 'Successfully loaded your favourite filters.',
    newFilterLoadSuccess: 'Your saved filter options have been loaded.',
    noFilter: 'You have not saved your favourite filters.',
    filterLoadFail: 'Failed to load your favourite filters.',
    filterSaveSuccess: 'Successfully saved your filters.',
    filterSaveFail: 'Failed to save your filters.',
    bulkTransferTitle: 'Bulk Transfer',
    nullSIDetailError: "Can't find the SI info, please check the project code",
    geRepeatErr:
      'There are duplicated GEs between Lead GE and Co-GE, please have a check and remove the duplicates.',
    createError:
      'Failed to create the special issue. Please revise the dates you entered and check your network status. Please try again.',
    updateError:
      'Failed to update this special issue due to network issue or other reasons. ' +
      'Please try again. The raw response is `{value}`. You can report it to the dev team.',
    updateErrorDueToCommissionError:
      "This paper commission method is not allowed because it's ready for promotion",
    createSucc: 'Successfully created a special issue.',
    updateSucc: 'Successfully updated this special issue.',
    invalidStage:
      'The stage of this SI is invalid. Please verify the target stage,' +
      ' or check if there is another user who have updated this SI.',
    invalidDate: 'One or more dates you selected are invalid. Please revise.',
    createTimeoutError:
      'Failed to create due to network timeout. Please try again.',
    updateTimeoutError:
      'Failed to update due to network timeout. Please try again.',
    loadError: 'Failed to load special issue proposal.',
    exportAllError: {
      CODE_14142:
        'The date range you entered is invalid. Please enter a valid date range.',
      CODE_14143: 'There are no SI records in the current time interval.',
      CODE_OTHER: 'Fail to export special issue records.',
    },
  },
  siDetail: {
    journalDetails: 'Journal Details',
    subjectGroup: 'Subject Group',
    ownershipStatus: 'Ownership Status',
    revenueModel: 'Revenue Model',
    primaryUrl: 'Primary URL',
    businessDivision: 'Business Division',
    society: 'Society',
    peerReviewModel: 'Peer Review Model',
    eeoSystemLink: 'EEO System Link',
    editingHistory: 'Editing History',
    publicationDetail: 'Publication Detail',
    submissionOverview: 'Submission Overview',
    authorGuidelines: 'Author Guidelines',
    submissionURL: 'Submission URL',
    message: '{message}',
    issueError: 'The attribute "ISSUE" is mandatory to input.',
    volumeError: 'The attribute "VOLUME" is mandatory to input.',
    linkNullError: 'The attribute "LINK" is mandatory to input.',
    linkFormatError: 'The format of "LINK" is invalid.',
    linkLengthError: 'The length of "LINK" is too long.',
    publicationDateError: 'Please enter a valid actual publication date.',
    articleNumberError:
      'Number of articles published shall not be greater than number of articles accepted.',
    outputRate: 'Output Rate: ',

    submissionBoundaryValueError:
      'Please input a positive integer number and smaller than ',

    submissionError:
      'Please note that the number of accepted articles plus number of rejected articles should not be larger than number of submitted articles. And the accepted articles should larger than the sum of OO articles and OA articles',

    generalInfo: 'General Information',
    journalLinkErr: 'SI Marketing Journal Page Link is illegal.',
    siHTMLLinkErr: 'Special Issue HTML Link is illegal.',

    emailSetUp: 'SI Set Up in Submission System',
    emailGEGuidelineSharing: 'Guest Editor Guideline Sharing',
    emailMonthlySubmissionReport:
      'Monthly Submission Report of SI for GE and EiC',
    emailMonthlySubmissionReportFull:
      'Monthly Submission report of Special Issue Status for GE and EiC',
    emailEditorialWritingReminder:
      'Final Tasks for GE: Draft Editorial and Provide Running Order',
    emailRemove: 'SI Title and GE Access Remove from Submission System',
    emailThankyouLetter: 'Thank-you Letter to GE',
    oaArticleNullError: 'The attribute "OA ARTICLES" is mandatory to input.',
    ooArticleNullError: 'The attribute "OO ARTICLES" is mandatory to input.',
    waiversUsedNullError:
      'The attribute "WAIVER(S) USED" is mandatory to input.',
    waiversusedNumberError:
      'Number of Waiver(s) Used should be ≤ number of OA Articles.',
    decision: 'Decision',
    reactivate: {
      notFound: 'SI not found.',
      hasBeenReactivatedOnce: 'This SI has been reactivated once.',
      notShelvedSI: 'The SI is not in shelved stage.',
      reactivateReasonEmpty: 'Reactivate reason should not be empty!',
      reactivateReasonTooLong:
        'Reactivate reason should not exceed 10,000 characters.',
    },
  },

  sipDetail: {
    sipStatus: 'SIP Status',
    object: 'Object',
    geInformation: 'GE Information',
    ebmReviewPanel: 'EBM Review Panel',
    sipInformation: 'SIP Information',
    contentCommissioningApproaches: 'Content Commissioning Approaches',
    sipCode: 'SIP Code',
    sipStage: 'SIP Stage',
    sipOrigin: 'SIP Origin',
    sipSource: 'SIP Source:',
    timeSensitive: 'Time Sensitive',
    handlingCE: 'Commissioning Editor',
    submissionDate: 'Submission Date',
    creationDate: 'Creation Date',
    lastDecisionDate: 'Latest Decision Date',
    operationsLead: 'Operations Lead',
    screener: 'Screener',
    preferredSubjectArea: 'Preferred Subject Area',
    choiceJournal1st: '1st Choice Journal',
    choiceJournal2nd: '2nd Choice Journal',
    choiceJournal3rd: '3rd Choice Journal',
    journal1Code:'Journal Code',
    journal1Name:'Journal Name',
    journal1HomePage:'Journal Homepage',
    journal1AuthorGuidelines:'Author Guidelines',
    sipTitle: 'SIP Title:',
    aimsAndScope: 'Aims and Scope:',
    topics: 'Topics:',
    keywords: 'Keywords:',
    references: 'References:',
    fmSubmissionDeadline: 'Full Manuscript Submission Deadline:',
    acceptanceDeadline: 'Acceptance Deadline:',
    epDate: 'Expected Publication Date:',
    notesForEditor: 'Notes for Editor:',
    openCallForPapers: 'Open Call for Papers:',
    potentialAuthors: 'Potential Authors',
    noData: 'No data',
    noAuthors: 'No authors were recommended',
    sipStatusGetErrorMessage:
      "Unable to obtain 'SIP Status', please try again.",
    objectGetErrorMessage: "Unable to obtain 'Object', please try again.",
    geInfoGetErrorMessage:
      "Unable to obtain 'GE Information', please try again.",
    sipInfoGetErrorMessage:
      "Unable to obtain 'SIP Information', please try again.",
    contentCommissioningApproachesGetErrorMessage:
      "Unable to obtain 'Content Commissioning Approaches', please try again.",
    ebmReviewCommentsGetErrorMessage:
      "Unable to obtain 'EBM Review Comments', please try again.",
    sipNotFound: 'SIP not found, please check it carefully.',
    assignToCESubmitDecisionSuccess:
      'Successfully deliver this SIP to the selected CE',
    assignToCESubmitDecisionErrorMessage:'Failed to deliver this SIP to the selected CE, please try again.',
    moveToInitialReviewSuccess: 'Successfully move the SIP from "Submitted" to "Initial Review"!',
    moveToInitialReviewOpsLeadEmailError: 'Operations Lead must use a Wiley email!',
    moveToCommisioningEditorReviewSuccess: 'Successfully move the SIP from "Initial Review" to "Commissioning Editor Review"!',
    moveToEBMReviewSuccess: 'Successfully move the SIP from "Initial Review" to "EBM Review"!',
    assignToScreenerSuccess: 'The SIP has been successfully assigned to a screener.',
    reassignToScreenerSuccess: 'The SIP has been successfully reassigned to a new screener.',
    assignToScreenerEmailError: 'Screener must use a Wiley email!',
    decisonTypeGetErrorMessage:'Unable to obtain decision type, please try again.',
    decisonConfigGetErrorMessage:'Unable to obtain decision config, please try again.',
    revisionSubject: 'Subject: ',
    revisionFrom: 'From: ',
    revisionTo: 'To: ',
    revisionCc: 'Cc: ',
    revisionBcc: 'Bcc: ',
    withdrawSubject: 'Subject: ',
    withdrawTo: 'To: ',
    withdrawCc: 'Cc: ',
    withdrawBcc: 'Bcc: ',
    edit: {
      objectEditError: 'All the mandatory items must be filled in.',
      sipInformationTopicExceedErr:
        'The number of inputted topics should be no more than 50.',
      sipInformationKeywordsExceedErr:
        'The number of inputted keywords should be no more than 48.',
      sipInformationReferencesExceedErr:
        'The number of inputted references should be no more than 50.',
      uploadCv: {
        error1: 'data insert failed',
        error2: 'cv name or content is empty',
      },
      deleteCvErr: 'sip ge cv delete error',
      upDateGEErr: 'update sip ge error',
      leadGEExceedErr:
        'The number of added lead GEs should be no more than 20.',
      geExceedErr: 'The number of added GEs should be no more than 40.',
      deleteGENumberErr:
        'There must be at least one Lead Guest Editor per SIP.',
      deleteGEErr: 'sip ge delete error',
      editGeErr:
        'Failed to edit GE. The raw message is: {value}. Please try again.',
      leadGeNumberErr: 'There must be at least one Lead GE per SIP',
      sipGeEmailConflictErr:
        'Email of this GE is the same as another existing GE.',
    },
    addOCPErrorMessage:
      "Failed to update 'Open Call for Papers', please try again.",
    potentialAuthorEditErrorMessage:
      'Failed to edit the potential author, please try again.',
    potentialAuthorAddErrorMessage:
      'Failed to add the potential author, please try again.',
    potentialAuthorDeleteErrorMessage:
      'Failed to delete the potential author, please try again.',
    authorNameNullError: 'AUTHOR NAME: Please enter required field.',
    affiliationNullError: 'AFFILIATION: Please enter required field.',
    authorEmailFormatError: 'EMAIL: The format is incorrect.',  //CT-6648
    potentialAuthorErrorMessage:
      'Please ensure that 1) both names and affiliations of all the added authors are filled; and 2) all their email addresses are valid upon being filled.',
    potentialAuthorDuplicationError:
      'The email of the new potential author is repeat with the email of the existing SIP potential author!',
    potentialAuthorQuantityLimitErrorMessage:
      'The upper limit of the number of potential author is 50.',
    assignToRejectSubmitDecisionSuccess: 'Successfully reject this SIP',
    assignToRejectSubmitDecisionErrorMessage:
      'Failed to reject this SIP, please try again.',
    submitExtendDeadline:'The Extended Deadline has been successfully established.',
    editExtendDeadline:'The Extended Deadline has been successfully revised.',
    submitExtendDeadlineError:
    'Failed to reject this SIP, please try again.',
    downloadCvSuccessMessage: 'Successfully download CV',
    downloadCvErrorMessage: 'Failed to download CV, please try again.',
    decisionAllMandatory: 'All the mandatory items must be filled in.',
    sipStageType: {
      submitted: 'Submitted',
      initialReview: 'Initial Review',
      editorReview: 'Editor Review',
      revision: 'Revision',
      //------------------The following are non decision panels------------------
      accept: 'Journal Accept',
      completeAccept: 'Mutual Accept',
      completeReject: 'Complete - Reject',
      //------------------The above is without a decision panel------------------
      completeDecline: 'Complete - Decline',
      //------------------The following are non decision panels------------------
      shelved: 'Shelved',
      //------------------The above is without a decision panel------------------
      publisherInquiry: 'Publisher Inquiry',
      jpmInquiry: 'JPM Inquiry',
      ideation: 'Ideation',
      CEReview: 'Commissioning Editor Review',
      EbmReview: 'EBM Review',
    },
    decisionPanelElements: {
      assignToScreener: 'Assign to Screener',
      assignToCE: 'Assign to CE',
      assignToEditor: 'Assign to Editor',
      accept: 'Accept',
      revise: 'Revise',
      reject: 'Reject',
      transferBackToJPA: 'Transfer back to JPA',
      transferToAnotherCE: 'Transfer to Another CE',
      cancelEditorReview: 'Cancel Editor Review',
      withdraw: 'Withdraw',
      isInitialReviewCancelRevision: 'Initial Review - Cancel Revision',
      agree: 'Agree',
      decline: 'Decline',
      publisherInquiry: 'Publisher Inquiry',
      cancelPublisherInquiry: 'Cancel Publisher Inquiry',
      jpmInquiry: 'JPM Inquiry',
      cancelJPMInquiry: 'Cancel JPM Inquiry',
      directAccept: 'Direct Acquire',
      moveToSubmitted: 'Move to Submitted Stage',
      moveToCommissioningEditorReview: 'Move to Commissioning Editor Review',
      moveToEBMReview: 'Move to EBM Review',
      moveToInitialReview: 'Move to Initial Review',
      cancelRevision: 'Cancel Revision',
    },
    revise: {
      draftSaveSuccess: 'The draft has been saved.',
      draftSaveDataInvalidError:
        'Failed to save draft. Please check the error or retry.',
      draftSaveError:
        'Failed to save draft. Please check the error or retry.\n The raw error message is: {value}',
      submitReviseSucc: 'Revise successfully!',
      submitReviseError:
        'Failed to submit revise. Please retry.\n The raw error message is: {value}',
      submitReviseGEError:
        'Failed to submit revise as GE information is incomplete, please improve GE information.',
      submitReviseInvalidData:
        'Failed to submit revise. Please check the error.',
      submitDecisionError: 'Failed to submit decision. Please retry.',
      submitCommentError: 'Failed to submit comment. Please retry.',
    },
    ebmReview:{
      inviteSuccess: 'Successfully invite EBM Reviewer',
      inviteError: 'Failed to invite EBM Reviewer. Please retry again.',
      cancelSuccess: 'EBM Review has been cancelled',
      cancelError: 'Failed to cancel EBM Reviewer. Please retry again.',
      submitReviewError:
        'Failed to submit ebm review comment. Please retry.',
    },
    callForPaperEditPermissionError:
      'Sorry, you do not have relevant permissions.',
    assignee: 'Assignee',
    editAssignee: 'Edit Assignee',
    addNewAssignee: 'Add New Assignee',
    firstName: 'First Name',
    lastName: 'Last Name',
    emailAddress: 'Email Address',

    acceptInitialFail:
      'Failed to load information about the special issue proposal, please try again.',
    acceptSubmitFail: 'Failed to submit the revision, please try again.',
    acceptErrorMessage: '{msg}',
    acceptDraftSaveSuccess: 'The draft has been saved.',
    acceptDraftSaveError: 'Failed to save draft. Please check the error.',
    steps: {
      header: {
        accept: 'Accept',
        editorReview: 'Editor Review',
        initialReview: 'Initial Review',
        submit: 'Submitted',
        revision: 'Revision',
        completeAccept: 'Complete - Accept',
        completeReject: 'Complete - Reject',
        shelved: 'Shelved',
        publisherInquiry: 'Publisher Inquiry',
        start: 'Start',
        ideation: 'Ideation',
      },
      sipProcessLog: 'SIP Process Log',
      subtitle: 'Origin: {origin}',
      noData: 'No process log to show',
      loadError:
        'Fail to load process log. Please reopen the panel and try again.',
    },
    agreeMessage:
      'More information in the Special Issue Proposal need to be completed before we can officially kick it off. Please check and complete it, thanks!',
    declineHintMessage: 'Please input your reason to decline this offer.',
    declineError:
      'Failed to decline the special issue proposal, please try again.',
    declineTokenError: 'Path not find! This revise url is unreachable.',
    assigneeExceedRemind: 'No more than 10 assignees could be added.',
    emailBackWarningTitle: 'Return to previous page?',
    emailBackWarningMessage: 'Changes you made so far will no be saved.',
    emailBackWarningKeepButton: 'Keep editing',
    emailBackWarningBackButton: 'Go back',
    emailCreateWarningTitle: 'Proceed with Confirmation?',
    emailCreateWarningMessage: 'Once created, changes cannot be undone.',
    emailCreateWarningKeepButton: 'Keep editing',
    emailCreateWarningCreateButton: 'Confirm and create',
    transferBackToJPACancelTitle: 'Cancel editing?',
    transferBackToJPACancelMessage:
      'Changes you made so far will not be saved.',
    transferBackToJPACancelKeepButton: 'Keep editing',
    transferBackToJPACancelCancelButton: 'Cancel',
    transferBackToJPASubmitTitle: 'Submit this decision?',
    transferBackToJPASubmitMessage: 'Once submitted, changes cannot be undone.',
    assignToScreenerSubmitMessage: 'The notification email will be sent to assigned screener automatically.',
    transferBackToJPASubmitKeepButton: 'Keep editing',
    transferBackToJPASubmitSubmitButton: 'Confirm and submit',
    sipStatusChanged: 'The status of this page has changed. Please refresh the page to get the latest info.',
    alertMoveToInitialReview: {
      title: 'Are you sure you want to move this SIP to Initial Review stage?',
      message: 'The notification email will be sent out automatically and you cannot revoke it after submitting.',
      buttons: ['No' , 'Yes'],
    },
    /**
     * DecisionPanelAlerts is used to config the text of alert message and buttons text
     * 
     * To use it: need to provide `sipDetail.DecisionPanelAlerts.sendMailAlert${sipStage}.${decisionType}`
     *            the fallback text is in `sipDetail.DecisionPanelAlerts.sendMailAlert.default`
     * Be careful: when the sipStage or decision name changes, update is required to continue to perform
     */
    DecisionPanelAlerts: {
      sendMailAlert: {
        default: {
          ['Revise']: {
            message: "After submission, the SIP will be moved to the Revision stage, and the email cannot be revoked.",
          },
          ['Reject']: {
            message: "After submission, the SIP will be rejected, and the email cannot be revoked.",
          },
          ['Withdraw']: {
            message: "After submission, the SIP will be withdrawn, and the email cannot be revoked.",
          },
          ['Direct Acquire']: {
            message: "After submission, the SIP will be moved to the Mutual Accept stage, and the email cannot be revoked.",
          },
          ['Move to Initial Review']: {
            message: "After submission, the SIP will be moved to the Initial Review stage, and the email cannot be revoked.",
          },
          ['Move to Commissioning Editor Review']: {
            message: "After submission, the SIP will be moved to the Commissioning Editor Review stage, and the email cannot be revoked.",
          },
          ['Cancel Publisher Inquiry']: {
            message: "After submission, the SIP will be moved to the Commissioning Editor Review stage, and the email cannot be revoked.",
          },
          message: "After submission, the SIP will be moved to the new stage, and the email cannot be revoked.",
          buttonText1: "Keep Editing",
          buttonText2: "Submit",
        },
        ['Revision']: {
          ['Cancel Revision']: {
            message: "After submission, the SIP will be moved to the {originStage} stage, and the email cannot be revoked.",
          },
        },
      }
    }
  },

  createSI: {
    openForSub: 'Open for Submission Date',
    openForSubErrorText:
      'Open for Submission Date should be earlier than Submission Deadline.',
    geRepeatErr:
      'There are duplicated GEs between Lead GE and Co-GE, please have a check and remove the duplicates.',
    emptyErrMsg: 'This attribute is mandatory to input',
  },

  gePage: {
    pageTitle: 'Guest Editor Overview',
    noSelectionExport: 'Please select at least one GE to export.',
    searchPlaceholder:
      'Search research keywords (use comma to separate keywords)',
    geCode: 'ge code',
    title: 'Title',
    city: 'City',
    country: 'Country/Region',
    primaryEmail: 'Primary Email',
    researchKeywords: 'research keywords',
    flags: 'flags',
    fullName: 'Full Name',
    gender: 'Gender',
    institute: 'Institution',
    associateJournal: 'Associate Journal',
    jpm: 'JPM',
    subjectGroup: 'Subject Group',
    handlingCe: 'Handling CE',
    updateError: 'Failed to update as {value}.',
    createError: 'Failed to create as {value}.',
    createGEProfileWebsiteValidError:
      'The profile website must be a valid URL.',
    GEEmailAlreadlyInDatabaseError:
      'The Guest Editor has been added in database',
    primaryEmailSameToSecondaryEmailError:
      'Your primary email should be different from your secondary email.',
    secondaryEmailSameToPrimaryEmailError:
      'Your secondary email should be different from your primary email.',

  },

  sipPage: {
    pageTitle: 'Special Issue Proposal Overview',
    orderTypeErr: 'Bad orderType',
    queryFailedErr: 'query failed, please try again',
  },
  sipForm: {
    errorText: {
      required: "Please provide the required information.",
      journalMoreThanThree: "Please select up to three journals.",
      emailInvalid: "This isn’t an email address. Try again.",
      orcidInvalid: "Please input correct ORCID ID format.",
      linkInvalid: "Please input correct link format.",
      titleLengthOutOfRange: "Title length should be between 1 and 400 characters.",
      aimsAndScopeLengthLessThan100: "Please introduce the background and significance of your proposed special issue in 500 characters as a minimum.",
      topicsHasEmpty: "Topic cannot be empty.",
      dateOfAcceptanceDeadline: "Please select a date after ‘Manuscript Submission Deadline’.",
      dateOfPublication: "Please select a date after ‘Acceptance Deadline’.",
      leadGeNumberErr: "Please nominate at least one Lead Guest Editor.",
      geEmailDuplicate: "Please use a unique email for each Guest Editor.",
      authorEmailDuplicate: "Please use a unique email for each Potential Author.",
    },
    noOptions: "No result, Please try to search again.",
    tooltip: {
      journal: "If desired, you may indicate up to three journals to submit your Special Issue proposal to.",
      aimsAndScope: "Please introduce the background and significance of your proposed special issue. We strongly recommend that you use at least 500 characters to describe the aims & scope.",
      leadGuestEditor: "Please nominate at least one Lead Guest Editor. Lead Guest Editors will be regarded as the corresponding editor(s) during the proposal evaluation process.",
      institutionalEmail: "If you do not have one, please provide professional email address instead.",
      publicationRecordLink: "Your institutional profile could be provided here.",

    },
    info: {
      topics: "You can add up to 15 topics.",
      authors: "You can add up to 10 authors.",
    },
    placeholder: {
      typeOnly: "Type here",
      typeOrSelect: "Type/Select here",
      selectOnly: "Select here",
      orcidID: "e.g. 0000-0000-0000-0000",
    },
    label: {
      topicAddButton: "Add a Topic",
      geAddButton: "Add a GE",
      geDeleteButton: "Delete GE",
      authorAddButton: "Add an Author",
      authorDeleteButton: "Delete Author",
    }
  },
  flaggedGEVerificationPage: {
    pageTitle: 'Flagged GE Verification',
    instrucutionMessage: 'The following GE-proposer(s) have been flagged with one or more issues, during the SIP evaluation process. There is possibility that they have also existed in the GE database. Please accomplish verification as well as synchronization.',
    geProposerTableTitle: 'GE-Proposer',
    simtGEDatabaseTableTitle: 'SIMT GE Database',
  },
  // report 中涉及到的常量名称
  report: {
    // 注：表格上面的header可以使用`className="report-table-header"`
    siPipeline: 'SI pipeline',
    acquiredAndActiveSI: 'acquired and active SI',
    articles: 'articles',
    journal: 'journal',
    targets: 'targets',
    overview: 'overview',
    oaVsTotal: 'OA VS total',
    fyTrends: 'FY trends',
    annualTrends: 'annual trends',
    OAPublicationInHybridJournals: 'OA Publication in Hybrid Journals',
    OAWaiverUse: 'OA Waiver Use',
    filter: 'filter',
    fiscalYear: 'fiscal year',
    basicTable: 'Basic Table',
    subjectGroup: 'subject group',
    businessDivision: 'business division',
    numSub: 'Submission', // review后井号去除了
    numAcc: 'Acceptance',
    numPub: 'Publication',
    ceCat: 'CE/CAT',
    jpm: 'JPM',
    month: 'Month:',
    tip: 'This table only shows journals that have SI in pipeline',
    tipOAP:
      'As OA publication data prior to September 2023 is not available, the YTD OA publication numbers for FY24 reflect a four-month reduction.',
    tipTwo:
      'Discrepancy in SI reports or input error may result in negative performance numbers in above reports.',
    GenerationDateAndTimeOne: 'Below report is generated on {time} (UTC time).',
    GenerationDateAndTimeTwo:
      'Below reports are generated on {time} (UTC time).',
    fyTrendsTable: {
      acquiredSI: 'Acquired SI',
      activeSI: 'Active SI',
      journalInformation: 'journal information',
      revenueModel: 'revenue model',
      jpm: 'JPM',
      pdp: 'pd publisher',
      ppp: 'pp publisher',
      subjectGroup: 'subject group',
      businessDivision: 'business division',
      catGroup: 'CAT group',
      handlingCe: 'handling CE',
      journalStatus: 'Journal Status',
    },
    all: 'All', // All 按钮
    oa: 'OA', // OA 按钮
    filters: {
      // filter项目
      journal: 'journal',
      journalCode: 'journal code',
      revenueModel: 'revenue model',
      jpm: 'JPM',
      pdPublisher: 'PD Publisher',
      ppPublisher: 'PP Publisher',
      subjectGroup: 'subject group',
      businessDivision: 'business division',
      catGroup: 'CAT group',
      handlingCe: 'handling CE',
      // placeholder
      searchJournal: 'Search journal name or code',
      searchJPM: 'Search JPM',
      searchPDPublisher: 'Search PD Publisher',
      searchPPPublisher: 'Search PP Publisher',
      searchSubjectGroup: 'Search subject group',
      searchBD: 'Search business division',
      searchHCE: 'Search handling CE',
    },
    //report下的journal字符串
    Journal: {
      catGroup: 'CAT group',
      subjectGroup: 'Subject Group',
      handlingCe: 'Handling CE',
      activeJournals: 'Active Journals',
      journalsIncluded: 'Journals Included',
      active: 'Active %',
      targetGap: 'Target Gap',
      optInJournals: 'Opt-In Journals_',
      optOutJournals: 'Opt-Out Journals_',
      commissioningModel: 'SI Commissioning',
      basicManagementModel: 'Basic Management',
      onHold: 'On-Hold',
      total: 'Total',
    },
    diagram: {
      selectAtLeastOne: 'Please select at least one {category}.',
    },
  },

  //CAT Journal页面涉及的常量名称
  catJournal: {
    pageTitle: 'SCA Journal',
    noSelectionExport: 'Please select at least one journal to export.',
    searchPlaceholder:
      'Search Journal by code or name (use comma to separate keywords)',
    createJournalError: 'System error. Please try again.',
    createJournalSuccess: 'The CAT journal has been saved.',
    journalCode: 'Journal Code',
    journalName: 'Journal Name',
    revenueModel: 'Revenue Model',
    optInDate: 'Opt-In Date',
    optOutDate: 'Opt-Out Date',
    devModel: 'Development Model',
    scal: 'SCA Lead',
    scam: 'SCA Manager',
    catGroup: 'SCA Group',
    pdPublisher: 'PD Publisher',
    pdManager: 'PD Manager',
    pdDirector: 'PD Director',
    ppPublisher: 'PP Publisher',
    ppManager: 'PP Manager',
    ppDirector: 'PP Director',
    editorialDirector: 'Editorial Director',
    subjectGroup: 'Subject Group',
    businessDivision: 'Business Division',
    lastUpdateTime: 'Last Update Time',
    deleteSuccessMsg:
      '{journalName}({journalCode}) has been deleted from CAT journals.',
    deleteSFailMsg: 'System error. Please try again.',
    updateSuccessMsg: '{journalCode} has been updated from CAT journals.',
    optInRejectError: 'opt In Reject Error',
    optInAppoveError: 'opt In Appove Error',
    batchUpdate: 'Batch Update',
    status: 'STATUS',
    optInReason: 'Opt-in Reason',
    optOutReason: 'Opt-out Reason',
    comments: 'Comments',
    filters: {
      journalCode: 'Search Code',
      journalName: 'Search Name',
      status: 'Select Status',
      devModel: 'Select Model',
      handlingCe: 'Search SCA Lead',
      scam: 'Search SCA Manager',
      // group: 'Select Group',
      group:'Search Group',
      jpm: 'Search JPM',
      pdPublisher: 'Search PD Publisher',
      pdManager: 'Search PD Manager',
      pdDirector: 'Search PD Director',
      ppPublisher: 'Search PP Publisher',
      ppManager: 'Search PP Manager',
      ppDirector: 'Search PP Director',
      editorialDirector: 'Search Director',
      subGroup: 'Select Group',
      busDivision: 'Select Division',
      revenueModel: 'Select Model',
    },
    optIn: 'Opt-in',
    optOut: 'Opt-out',
    optInGetError:
      'Failed to load Opt-in Request, please refresh the page and try again.',
    optOutGetError:
      'Failed to load Opt-out Request, please refresh the page and try again.',
    batchUpdateNoSelectorErr:
      'Please select at least one journal to batch update.',
    requestHistory: {
      requestType: 'REQUEST TYPE',
      requestDate: 'REQUEST DATE',
      journalCode: 'JOURNAL CODE',
      journalTitle: 'JOURNAL TITLE',
      requestBy: 'REQUESTED BY',
      requestReason: 'REQUESTED REASON',
      status: 'STATUS',
      decisionBy: 'DECISION BY',
      decisionReason: 'DECISION REASON',
      statusDate: 'STATUS DATE',
    },
  },
  targets: {
    allJournals: 'All Journals',
    oaJournals: 'OA Journals',
    acquired: 'Acquired',
    submission: 'Submission',
    acceptance: 'Acceptance',
    publication: 'Publication',
    journalsIncluded: 'Journals Included',
    stretchSubmission: 'Stretch Submission',
    stretchAcceptance: 'Stretch Acceptance',
    stretchPublication: 'Stretch Publication',
    businessDivision: 'Business Division',
    catGroup: 'CAT Group',
    handlingCe: 'Handling CE',
  },

  superAdminManmage: {
    createAdminTitle: 'Create System Admin User',
    updateAdminTitle: 'Update System Admin User {lastName}, {firstName}',
    editAdminRoleLabel: 'Role',
    adminOverviewTitle: 'System Admin Users',
    adminNewAdminButtonText: 'Create',
    fullName: 'Full Name',
    primaryEmail: 'Primary Email',
    groupTitle: 'Group/Title',
    role: 'Role',
    accountStatus: 'Account Status',
    actions: 'Actions',
    resendMsg: 'Invitation link has been resent to {email}.',
    resetMsg: 'System admin {fullName}\'s password has been reset.',
    deleteMsg: 'System admin {fullName} has been removed.',
    unlockMsg: 'System admin {fullName} has been unlocked.',
    inactiveMsg: 'System admin {fullName} has been inactived.',
  },
  sysConfig: {
    sysConfig: 'system configuration',
    gs: 'GENERAL SETTINGS',
    dv: 'DROPDOWN VALUES',
    reset: 'reset',
    save: 'save',
    pwdTimeout: 'Password Reset Link Timeout (Minute):',
    actTimeout: 'User Activation Link Timeout (Minute):',
    tokenTimeout: 'Token Expiration Time (Minute):',
    maxGE: 'Maximum Number Of Guest Editors In Each SI :',
    maxArticle:
      'Maximum Number Of Recruited, Submitted, Accepted, And Rejected Articles In Each SI:',
    maxJournals:
      'Maximum Number Of Journals For A Joint SI: ',
    pwdTimeoutErr: 'The expiration time should be between 10 and 2000 mins.',
    actTimeoutErr: 'The expiration time should be between 10 and 2000 mins.',
    tokenTimeoutErr: 'The expiration time should be between 15 and 120 mins.',
    maxGEErr: 'The number should be between 20 and 2000.',
    maxArticleErr: 'The number should be between 1000 and 100000',
    maxJournalsErr: 'The number should be between 20 and 2000.',
    numberLessErr:
      'The number should be greater than or equal to the previous one.',
    numberOutOfRangeErr: 'The number is out of range.',
    updateConfiguration: 'System configurations have been updated.',
    updateConfigurationError: 'Failed to update "{name}". {type} ',
    groupTitle: 'Group/Title',
    flags: 'Flags',
    flagsSevereIssue: 'Flags-Server Issue',
    flagsMildIssue: 'Flags-Mild Issue',
    siPublicationFormat: 'SI Publication Format',
    paperCommissioningMethod: 'Paper Commissioning Method',
    addNewItemHere: 'add new item here',
    categoriesLabel: 'Category',
    dvLabel: 'Value',
    reorderLabel: 'Reorder',
    resetOrder: 'reset order',
    saveOrder: 'save order',
    maxLengthTip: 'This content should not be more than {value} characters.',
    addItemSuccess: 'Successfully added a new item.',
    addItemFail: 'Failed to add this item.',
    deleteItemSuccess: 'Successfully deleted a new item.',
    deleteItemFail: 'Failed to delete this item.',
    updateItemSuccess: 'Successfully updated an item.',
    updateItemFail: 'Failed to update this item.',
    orderChangedTip:
      'The order of following category has been modified. Please save the change.',
    orderSaveSuccess: 'Successfully saved the new dropdown list.',
    orderSaveFail: 'Failed to save this order due to {reason}.',
    // itemDuplicated: 'Failed to save. "{value}" is already in the database.',
    itemDuplicated:
      '"{value}" is a duplicate value. Please revise before saving.',
    addDialogTitle: 'Add dropdown values',
    addDialogContent:
      'New value will be added to {value} dropdown.{br}You will not be able to remove the option after saving.',
  },
  annualTarget: {
    success: 'Upload successfully.',
    wrongTemplate:
      'Please update the template with correct data before uploading.',
    emptyFile: 'To view the target file, you should upload the file first.',
    incorrectTemplateJournal:
      'Incorrect template! Please use and upload Journal Target Template.',
    somethingWrong: 'There is something wrong when parsing *.xlsx.',
    incorrectTemplateSubject:
      'Incorrect template! Please use and upload Subject Group Target Template.',
    incorrectTemplateBusiness:
      'Incorrect template! Please use and upload Business Division Target Template.',
    incorrectTemplateCat:
      'Incorrect template! Please use and upload CE Target Template.',
    fileWarning:
      '{fileWarning}',
  },
  reportJobs: {
    reportJobs: 'Report Jobs',
    submitBtn: 'Submit',
    siPipeline: 'SI Pipeline',
    acquiredAndActiveSI: 'Acquired & Active SI',
    articles: 'Articles',
    catJournal: 'SCA Journal',
    failToApproveOrReject:
      'Failed to approve or reject this request. Please try again.',
    waitingApproved: 'The report job request has been approved.',
    waitingRejected: 'The report job request has been rejected.',
  },
  exportData: {
    title: 'Export Data',
    date: 'Date',
    export: 'export',
  },
  access: {
    noAccessError: 'access not allowed',
  },
  crcount: {
    updateError: 'please check the email.',
  },
  updatePassword: {
    update: 'Update',
    updateYourPassword: 'Update your password',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    oldIncorrect: 'Old password is incorrect.',
    samePassword: 'The new password is same with old password.',
    passwordNotMeet:
      'The length of password should be at least 8 characters, including at least 3 types of uppercase letter, lower case letter, digit, and special character.',
    twiceNotMatch: 'Password are not matching.',
  },
  optIn: {
    approvePopUpTitle: 'Confirm/Choose New SCA Lead & Manager',
    rejectPopUpTitle: 'Reject Reason',
  },
  optOut: {
    approvePopUpTitle: 'Are you sure to submit the decision?',
    rejectPopUpTitle: 'Reject Reason',
    withdrawPopUpTitle: 'Are you sure to withdraw the opt-out request?',
    submitPopUpTitle: 'Opt-out Request',
    editPopUpTitle: 'Edit Opt-out Request',
  },
};

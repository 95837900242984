import {Box, Tabs, Typography} from '@mui/material';
import {useState} from 'react';
import TargetsOverview from './TargetsOverview';
import {ReportTabChildren} from '../ReportSpecificComponents/ReportTabComponents';
import {ShowForPermission} from "@/components/PermissionControl/ShowForPermission";
import {PERMISSIONS} from "@/constant/permission";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`targetsTabPanel${index}`}
      data-selenium-id={`Report_Targets_index-targetsTabPanel-${index}`}
      aria-labelledby={`targetsTab${index}`}
      {...other}
    >
      {value === index && (
        <Box data-selenium-id='Report_Targets_index-TabPanel'
             sx={{paddingTop: '14px', paddingLeft: '5px', paddingRight: '5px',}}>
          <Typography data-selenium-id='Report_Targets_index-TabPanel-Typography'>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `targetsTab${index}`,
    'aria-controls': `targetsTabPanel${index}`,
  };
}

/**
 * @author LiuXin
 * @date 2022/5/10
 * @description report 中的targets部分页面展示
 * @param {*} props
 * @returns
 */
function Targets(props) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      data-selenium-id='Report_Targets_index-RootBox'
      sx={{ width: '100%', padding: 0 }}
    >
      <Box
        data-selenium-id='Report_Targets_index-TabsBox'
        sx={{ borderColor: 'divider', mt: '-70px' }}
      >
        <Tabs
          data-selenium-id='Report_Targets_index-Tabs'
          value={value}
          onChange={handleChange}
          aria-label='pipeline Overview'
        >
          <ReportTabChildren
            data-selenium-id='Report_Targets_index-Tabs-ReportTabChildren'
            label='Overview'
            {...a11yProps(0)}
          />
        </Tabs>
      </Box>
      <ShowForPermission permission={`${PERMISSIONS.REPORT_READ_TARGET}`}>
        <TabPanel
          sx={{ marginTop: '-20px' }}
          data-selenium-id='Report_Targets_index-TabPanel'
          value={value}
          index={0}
        >
          <TargetsOverview data-selenium-id='Report_Targets_index-TargetsOverview'/>
        </TabPanel>
      </ShowForPermission>
    </Box>
  );
}

export default Targets;

import {
  Box,
  Button,
  Dialog,
  IconButton,
  Drawer,
  Backdrop,
  Divider,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { Stack } from '@mui/system';
import GEDetailCloseIcon from '@/assets/GEDetailCloseIcon.svg';
import { H4Font } from '@/components/FontStyle';
import { style, styled, width } from '@mui/system';
import { ReasonForPopOutTextArea } from './ReasonForPopOutTextArea';
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { StateButton } from '@/componentsphase2/StateButton';
import TextFieldErrorIcon from '@/assets/TextFieldErrorIcon.svg';
import DrawerCloseIcon from '../../../../assets/DrawerCloseIcon.svg';

// import {
//   getJournalListWithCode,
//   getJournalListWithName,
//   getJournalListWithCodeOptIn,
//   getJournalListWithNameOptIn,
// } from '@/actions/SIAction';
import {
  resetJournalOptOutCheck
} from "../../../../actions/SIMT-SI/CatJournal/CATJournalAction";
import { useIntl } from 'react-intl';
import { useUpdateEffect } from 'ahooks';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import { set } from 'date-fns';
import {checkJournalOptOut, editJournalOptOut, submitJournalOptOut} from "@/actions/SIMT-SI/CatJournal/OptOut";
import {getCATJournalsByCode, getCATJournalsByName} from "@/actions/SIMT-SI/CatJournal";

export default function EditAndSubmitPopUp(props) {
  const {
    open = true,
    setOpen = () => {},
    type = 'edit', // 用来判断是edit还是submit的框
    editId, // 用于edit时候 点击的对应行的journalCode
    rowData, // 用于获取对应行的所有数据
    page,
    pageSize,
  } = props;

  const theme = createTheme({
    palette: {
      error: {
        main: '#FC5555',
      },
    },
  });
  // console.log("rowData");
  const { JourListResult, checkJournalOptOutCode } = useSelector(state => {
    return {
      JourListResult: state.CATJournal.JournalListResult,
      checkJournalOptOutCode: state.CATJournal.checkJournalOptOutCode,
    };
  });

  const dispatch = useDispatch();
  const intl = useIntl();
  const limitLength = 10000;
  const emptyJournal = {
    journalCode: '',
    journalName: '',
  };
  // 点击chose的判断
  const [firstClick, setFirstClick] = useState(false);
  const [reason, setReason] = useState('');
  const [reasonEmptyError, setReasonEmptyError] = useState(false);
  const [journalCodeEmptyError, setJournalCodeEmptyError] = useState(false);
  const [journalNameEmptyError, setJournalNameEmptyError] = useState(false);
  const [excessError, setExcessError] = useState(false);
  const [repeatError, setRepeatError] = useState(false);
  const [ongoingError, setOngoingError] = useState(false);
  const [alreadyInJournalError, setAlreadyInJournalError] = useState(false);
  const [choseJournal, setChoseJournal] = useState({
    journalCode: '',
    journalName: '',
  });
  // 组件初始化操作
  useEffect(() => {
    if (open) {
      document.body.classList.add('hide-scroll');
    } else {
      document.body.classList.remove('hide-scroll');
    }
  }, [open]);

  useUpdateEffect(() => {
    if (
      type === 'edit' &&
      rowData &&
      rowData.journalCode !== choseJournal.journalCode
    ) {
      if (choseJournal && choseJournal.journalCode && choseJournal.journalCode.length>0)
        dispatch(checkJournalOptOut(choseJournal.journalCode));
      else
        dispatch(resetJournalOptOutCheck());
    }
    if (type !== 'edit') {
      if (choseJournal && choseJournal.journalCode && choseJournal.journalCode.length>0)
        dispatch(checkJournalOptOut(choseJournal.journalCode));
      else dispatch(resetJournalOptOutCheck());
    }
  }, [choseJournal]);

  useEffect(() => {
    // 因为清空发请求还需要时间 会出现提示信息重叠的情况
    // console.log('checkJournalOptOutCode', checkJournalOptOutCode);
    if (journalCodeEmptyError || journalNameEmptyError) {
      setOngoingError(false);
      setRepeatError(false);
      setAlreadyInJournalError(false);
      return;
    }
    // 在edit条件下需要保留本来的journalCode不报错
    if (
      type === 'edit' &&
      rowData &&
      choseJournal.journalCode === rowData.journalCode
    ) {
      setOngoingError(false);
      setRepeatError(false);
      setAlreadyInJournalError(false);
      return;
    } else {
      // 14126，"on going si in the pipeline"
      // 14128 the Journal Code is in the CAT Journal list with "Opt out" status already
      // 14129 the Journal Code is in the CAT Opt-out Request list already

      if (checkJournalOptOutCode === 14126) {
        setOngoingError(true);
        setRepeatError(false);
        setAlreadyInJournalError(false);
      } else if (checkJournalOptOutCode === 14129) {
        setRepeatError(true);
        setOngoingError(false);
        setAlreadyInJournalError(false);
      } else if (checkJournalOptOutCode === 14128) {
        setRepeatError(false);
        setOngoingError(false);
        setAlreadyInJournalError(true);
      } else {
        setRepeatError(false);
        setOngoingError(false);
        setAlreadyInJournalError(false);
      }
    }
  });

  useEffect(() => {
    if (firstClick) {
      if (!choseJournal.journalCode) setJournalCodeEmptyError(true);
      else setJournalCodeEmptyError(false);
      if (!choseJournal.journalName) setJournalNameEmptyError(true);
      else setJournalNameEmptyError(false);
    }
  }, [choseJournal, firstClick]);

  useEffect(() => {
    if (open) {
      dispatch(getCATJournalsByName('', 1));
      if (type === 'edit') {
        setChoseJournal({
          journalCode: rowData ? rowData.journalCode : emptyJournal,
          journalName: rowData ? rowData.journalName : emptyJournal,
        });
        setReason(rowData ? rowData.reason : '');
      } else {
        setChoseJournal({
          journalCode: '',
          journalName: '',
        });
        setReason('');
      }
      // setReason('');
      setExcessError(false);
      setOngoingError(false);
      setRepeatError(false);
      setAlreadyInJournalError(false);
      setFirstClick(false);
      setReasonEmptyError(false);
      setJournalCodeEmptyError(false);
      setJournalNameEmptyError(false);
    }else {
      dispatch(resetJournalOptOutCheck());
      setFirstClick(false);
      setReason('');
      setJournalCodeEmptyError(false);
      setJournalNameEmptyError(false);
      setExcessError(false);
      setRepeatError(false);
      setOngoingError(false);
      setAlreadyInJournalError(false);
      setChoseJournal({
        journalCode: '',
        journalName: '',
      });
    }
  }, [open]);

  const clickCancel = e => {
    document.body.classList.remove('hide-scroll');
    setOpen(false);
  };

  const [journalFocus, setJournalFocus] = useState(false);

  const clickSave = () => {
    setFirstClick(true);
    setExcessError(reason ? reason.length > limitLength : false);
    setReasonEmptyError(reason === '');
    if (
      (reason ? reason.length > limitLength : false) ||
      reason === '' ||
      repeatError ||
      ongoingError ||
      alreadyInJournalError ||
      journalCodeEmptyError
    ) {
      return;
    } else {
      if (type === 'edit') {
        dispatch(
          editJournalOptOut(
            editId,
            choseJournal.journalCode,
            reason,
            page,
            pageSize
          )
        );
      } else if (type === 'submit') {
        dispatch(submitJournalOptOut(choseJournal.journalCode, reason));
      }
      document.body.classList.remove('hide-scroll');
      setOpen(false);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
      data-selenium-id='CATJournal_Overview-OptOut-EditandSubmit-anotherNewFilterBox'
    >
      <Backdrop
        id='CATJournalOverviewOptOutEditandSubmitAnotherNewFilterDrawerBackdrop'
        data-selenium-id={`CATJournal_Overview-OptOut-EditandSubmit-anotherNewFilterDrawerBackdrop`}
        sx={{
          zIndex: 1400,
          backdropFilter: 'blur(4px)',
          background: '#113D9580',
        }}
        open={open}
      ></Backdrop>
      <Drawer
        id='CATJournalOverviewOptOutEditandSubmitAnotherNewFilterDrawer'
        variant='persistent'
        anchor='right'
        open={open}
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: '472px',
            zIndex: 1400,
            backgroundColor: '#FFFFFF',
          },
        }}
        data-selenium-id={`CATJournal_Overview-OptOut-EditandSubmit-anotherNewFilterDrawer`}
      >
        <Box
          sx={{
            m: '20px 36px',
            height: '34px',
          }}
          data-selenium-id='CATJournal_Overview-OptOut-EditandSubmit-Box'
        >
          <IconButton
            disableRipple
            sx={{
              float: 'left',
              cursor: 'pointer',
              padding: '0px',
              marginRight: '12px',
              paddingTop: '5px',
              '&:hover': {
                'svg path': {
                  fill: '#596A7C',
                },
              },
            }}
            id='closeButtonOpt'
            onClick={() => {
              document.body.classList.remove('hide-scroll');
              clickCancel();
            }}
            data-selenium-id='CATJournal_Overview-OptOut-EditandSubmit-IconButton'
          >
            <DrawerCloseIcon />
          </IconButton>

          <Box
            sx={{
              ...H4Font,
              height: '13px',
              float: 'left',
              color: '#262E35',
              pt: '5px',
            }}
            data-selenium-id='CATJournal_Overview-OptOut-EditandSubmit-Box-Box'
          >
            {type === 'submit'
              ? intl.messages['optOut.submitPopUpTitle']
              : type === 'edit'
              ? intl.messages['optOut.editPopUpTitle']
              : null}
          </Box>

          <StateButton
            onClick={clickSave}
            data-selenium-id='CATJournal_Overview-OptOut-EditandSubmit-Box2'
            titleName='Submit'
            isPrimary={true}
            sx={{
              lineHeight: '18px',
              marginLeft: '10px',
              float: 'right',
              '&:disabled': {
                backgroundColor: '#BCC5CF',
                boxShadow: 'none',
                color: '#FFFFFF',
              },
            }}
            disabled={
              ((reasonEmptyError || excessError) && firstClick) ||
              ((journalCodeEmptyError ||
                repeatError ||
                ongoingError ||
                alreadyInJournalError) &&
                !journalFocus)
            }
          ></StateButton>

          <StateButton
            onClick={clickCancel}
            data-selenium-id='CATJournal_Overview-OptOut-EditandSubmit-Box1'
            titleName='Cancel'
            isPrimary={false}
            sx={{ lineHeight: '18px', float: 'right' }}
          ></StateButton>
        </Box>
        <Divider></Divider>

        <Stack
          direction='column'
          spacing={3}
          sx={{
            p: '0px 36px 0px 36px',
            mt: '20px',
            height: '100%',
            overflowY: 'auto',
            scrollbarWidth: 'thin', // 设置滚动条宽度
            scrollbarColor: 'transparent', // 设置滚动条颜色为透明
            '&::-webkit-scrollbar': {
              width: '0px', // 设置滚动条宽度
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'transparent', // 设置滚动条背景为透明
            },
          }}
          data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-Stack'
        >
          <Stack
            direction='column'
            justifyContent='space-between'
            data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-Stack2'
          >
            <Stack
              direction='row'
              data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-Stack2-Stack'
              sx={{ mb: '4px' }}
            >
              <Box
                style={{
                  height: '20px',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '20px',
                  textTransform: 'capitalize',
                  color: '#596A7C',
                  letterSpacing: '0.01em',
                  textAlign: 'left',
                }}
                data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-Stack2-Box1'
              >
                Journal Code
              </Box>
              <Box
                style={{
                  width: '8px',
                  height: '18px',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '18px',
                  letterSpacing: '0.08em',
                  color: '#C40000',
                }}
                sx={{ ml: '10px' }}
                data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-Stack2-Box2'
              >
                *
              </Box>
            </Stack>

            <Box data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-Stack2-Box'>
              <SinpleAutocomplete
                id='sinpleAutocompleteJournalCode'
                config={{ disableClearable: true }}
                data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-OutCodeAutoComplete'
                options={JourListResult}
                getOptionLabel={option => {
                  return option.journalCode;
                }}
                loadOptionAction={getCATJournalsByCode}
                value={choseJournal}
                onChange={(e, newValue) => {
                  setChoseJournal({
                    journalCode: newValue ? newValue.journalCode : '',
                    journalName: newValue
                      ? newValue.journalName
                      : choseJournal.journalName,
                  });
                }}
                onFocus={() => {
                  setJournalFocus(true);
                }}
                onBlur={() => {
                  setJournalFocus(false);
                }}
                placeholder='Choose journal code'
                isErr={
                  (alreadyInJournalError ||
                    ongoingError ||
                    repeatError ||
                    journalCodeEmptyError) &&
                  !journalFocus
                }
                isEmpty={choseJournal?.journalCode === ''}
                needKeyboardDelete = {true}
                deleteValue={
                  ()=>{
                    setChoseJournal({
                      journalCode: '',
                      journalName:  ''
                    });
                  }
                }
              />
            </Box>
            <Box
              sx={{
                width: '400px',
                height: '0px',
                color: '#EE5350',
                fontFamily: ' Open Sans',
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '16px',
                letterSpacing: '-0.08399999886751175px',
                textAlign: 'left',
              }}
              data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-Stack2-Stack-Box'
            >
              {alreadyInJournalError && !journalFocus && (
                <>
                  <TextFieldErrorIcon />
                  <span>This journal is already an Opt-out journal.</span>
                </>
              )}
              {ongoingError && !journalFocus && (
                <>
                  <TextFieldErrorIcon />
                  <span>
                    Please clear up the SI pipeline in this journal before
                    opt-out request!
                  </span>
                </>
              )}
              {repeatError && !journalFocus && (
                <>
                  <TextFieldErrorIcon />
                  <span>This Opt-out request has been submitted!</span>
                </>
              )}
              {journalCodeEmptyError && !journalFocus && (
                <>
                  <TextFieldErrorIcon />
                  <span>This field is mandatory to input!</span>
                </>
              )}
            </Box>
          </Stack>

          <Stack direction='column'>
            <Stack direction='row' sx={{ mb: '4px' }}>
              <Box
                style={{
                  height: '20px',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '20px',
                  textTransform: 'capitalize',
                  color: '#596A7C',
                  //styleName: small bold;
                  letterSpacing: '0.01em',
                  textAlign: 'left',
                }}
                data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-Stack1-Box1'
              >
                Journal Name
              </Box>
              <Box
                style={{
                  width: '8px',
                  height: '18px',
                  fontFamily: 'Open Sans',
                  ontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '18px',
                  letterSpacing: '0.08em',
                  color: '#C40000',
                }}
                sx={{ ml: '10px' }}
                data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-Stack1-Box2'
              >
                *
              </Box>
            </Stack>

            <Box data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-Stack1-Box'>
              <SinpleAutocomplete
                id='sinpleAutocompleteJournalName'
                config={{ disableClearable: true }}
                data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-OutNameBox'
                options={JourListResult}
                loadOptionAction={getCATJournalsByName}
                getOptionLabel={option => {
                  return option.journalName;
                }}
                value={choseJournal}
                onChange={(e, newValue) => {
                  setChoseJournal({
                    journalName: newValue ? newValue.journalName : '',
                    journalCode: newValue
                      ? newValue.journalCode
                      : choseJournal.journalCode,
                  });
                }}
                onFocus={() => {
                  setJournalFocus(true);
                }}
                onBlur={() => {
                  setJournalFocus(false);
                }}
                placeholder='Choose journal name'
                isErr={
                  (alreadyInJournalError ||
                    ongoingError ||
                    repeatError ||
                    journalCodeEmptyError) &&
                  !journalFocus
                }
                isEmpty={choseJournal.journalName === ''} //判断输入框的是否为空
                needKeyboardDelete = {true}
                deleteValue={
                  ()=>{
                    setChoseJournal({
                      journalCode: '',
                      journalName:  ''
                    });
                  }
                }
              />
            </Box>
            <Box
              sx={{
                width: '400px',
                height: '0px',
                color: '#EE5350',
                fontFamily: ' Open Sans',
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '16px',
                letterSpacing: '-0.08399999886751175px',
                textAlign: 'left',
              }}
              data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-Stack2-Stack-Box'
            >
              {alreadyInJournalError && !journalFocus && (
                <>
                  <TextFieldErrorIcon />
                  <span>This journal is already an Opt-out journal.</span>
                </>
              )}
              {ongoingError && !journalFocus && (
                <>
                  <TextFieldErrorIcon />
                  <span>
                    Please clear up the SI pipeline in this journal before
                    opt-out request!
                  </span>
                </>
              )}
              {repeatError && !journalFocus && (
                <>
                  <TextFieldErrorIcon />
                  <span>This Opt-out request has been submitted!</span>
                </>
              )}
              {journalCodeEmptyError && !journalFocus && (
                <>
                  <TextFieldErrorIcon />
                  <span>This field is mandatory to input!</span>
                </>
              )}
            </Box>
          </Stack>
          <Stack
            direction='column'
            justifyContent='space-between'
            data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-Stack3'
          >
            <Stack
              direction='row'
              data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-Stack3-RowStack'
              sx={{ mb: '4px' }}
            >
              <Box
                style={{
                  height: '20px',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '20px',
                  // textTransform: 'capitalize',
                  color: '#596A7C',
                  //styleName: small bold;
                  letterSpacing: '0.01em',
                  textAlign: 'left',
                }}
                data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-Stack3-Box1'
              >
                Reason for Opt-out
              </Box>
              <Box
                style={{
                  width: '8px',
                  height: '18px',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '18px',
                  letterSpacing: '0.08em',
                  color: '#C40000',
                }}
                sx={{ ml: '10px' }}
                data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-Stack3-Box2'
              >
                *
              </Box>
            </Stack>
            <ReasonForPopOutTextArea
              id='rejectPopUp'
              data-selenium-id='CATJournal_Overview-OptOutTable-EditAndSubmitPopUp-RejectReasonForTextArea'
              needEmptyCheck={true}
              setValue={setReason}
              value={reason}
              limitLength={limitLength}
              emptyError={reasonEmptyError && firstClick}
              excessError={excessError && firstClick}
              setEmptyError={setReasonEmptyError}
              setExcessError={setExcessError}
              placeholder='Input reason for opt-out'
            ></ReasonForPopOutTextArea>
            <Box
              sx={{
                width: '400px',
                color: '#EE5350',
                fontFamily: ' Open Sans',
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '16px',
                letterSpacing: '-0.08399999886751175px',
                textAlign: 'left',
                mt: '-25px',
              }}
            >
              {excessError && firstClick && (
                <>
                  <TextFieldErrorIcon />
                  <span>
                    The character count should be no more than 10,000.
                  </span>
                </>
              )}
              {reasonEmptyError && firstClick && (
                <>
                  <TextFieldErrorIcon />
                  <span>This field is mandatory to input!</span>
                </>
              )}
            </Box>
          </Stack>
        </Stack>
      </Drawer>
    </Box>
  );
}

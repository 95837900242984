import { Box } from '@mui/material';
function CreateAndUpdateAddNew(props) {
  const { GeNum, maxGeNum, handleClick } = props;
  return (
    <Box
      sx={{ height: '20px', marginTop: '10px' }}
      data-selenium-id='SIPage_CreateAndUpdateSI-CreateAndUpdateAddNewBox'
    >
      <a
        onClick={() => {
          handleClick(); //数量判断交由父组件执行
        }}
        style={{
          color: GeNum > maxGeNum - 1 ? 'black' : '#154AB6',
          cursor: 'pointer',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '1px',
          textTransform: 'uppercase',
        }}
      >
        + Add New
      </a>
    </Box>
  );
}
export default CreateAndUpdateAddNew;

import { Box, Stack,IconButton } from '@mui/material';
import React from 'react';
import {useEffect} from 'react';
import IsCheckedIcon from './IsCheckedIcon';
import NecessaryIcon from './NecessaryIcon';
import AddIcon from '@/assets/AddMinus.svg';
export default function ChecklistItemBlock(props) {
  const {
    id = 'checklist',
    title = 'title',
    necessary = false,
    needClear = false,
    needAdd = false,
    handleClear = () => {},
    info = 'info',
    status = 'default',
    children = <></>,
    handleAdd = () =>{},
    mustTransferBack=false,
    alwaysShow=false,
    ...other
  } = props; 
  function hasChildren() {
    let newChildren = children?.length >= 2 ? children : [children];
    for (let i = 0; i < newChildren.length; i++) {
      let props = newChildren[i]?.props;
      if (props?.editing || props?.value || alwaysShow) {
        return true;
      }
    }
    return false;
  }

  return (
    <Box
      id={id}
      sx={{
        padding: '24px 16px',
      }}
    >
      {/* 头部 */}
      <Stack direction='column' spacing={0.5}>
        <Stack id={`itemBlock-Title`} direction='row' spacing={1}>
          <IsCheckedIcon status={status} show={true} />
          <Box
            sx={{
              fontFamily: 'Open Sans',
              letterSpacing: '0em',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '21.79px',
              color: '#262E35', }}
          >
            {title}
          </Box>
          {necessary && <NecessaryIcon />}
          {needAdd && <IconButton sx={{padding:0}} onClick={handleAdd}><AddIcon/></IconButton>}
          {needClear && (
            <Box
              sx={{
                cursor: 'pointer',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
                letterSpacing: '0em',
                color: '#154AB6',
                padding: '1px 0px',
                pointerEvents: mustTransferBack?'none':'auto',
              }}
              onClick={handleClear}
            >
              {' '}
              Clear
            </Box>
          )}
        </Stack>
        <Box
          id={`itemBlock-Info`}
          sx={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
            letterSpacing: '-1.1%',
            color: '#596A7C',
            marginLeft: '26px !important',
            pointerEvents: mustTransferBack ? 'none' : 'auto',
          }}
        >
          {
            info instanceof Array ? info.map((item,index) => (<p style={{margin:'0'}}>{item}</p>)):info
          }
        </Box>
      </Stack>
      {/* 内容 */}
      {hasChildren() && (
        <Stack
          direction='column'
          spacing={1}
          sx={{
            borderTop: '1px solid #F1F3F5',
            padding: '8px 26px 0 26px',
            marginTop:'10px',
          }}
        >
          {children}
        </Stack>
      )}
    </Box>
  );
}

import { useMemo } from 'react';
import Chart from '@/components/Chart/Chart';
import NoDataPage from '@/components/Chart/NoDataPage';
import { ArticleOverviewOption } from '../options';
import styles from './index.module.scss';
import {BUS_DIV} from '../../Constants';

const ChartArticleOverview = ({
  isEmptyList,
  isEmptyData,
  alertLabel,
  barchartData,
  width,
  hasZoom = false,
  hasTitle = false,
  title,
  subTitle,
  loading = false,
  error = false,
  catagoryAttributeName,
}) => {
  if (isEmptyData) {
    if (catagoryAttributeName === BUS_DIV) {
      return (
        <NoDataPage
          title={`There is no data available for the selected ${alertLabel.toLowerCase()}.`}
          subTitle={`Please choose a different ${alertLabel.toLowerCase()} to view diagram.`}
        />
      );
    } else {
      return (
        <NoDataPage
          title={`There is no data available for the selected date.`}
          subTitle={`Please choose a different date to view the diagram.`}
        />
      );
    }
  }

  const chartOption = useMemo(() => {
    return new ArticleOverviewOption(catagoryAttributeName);
    }, [catagoryAttributeName]);

  const options = useMemo(() => {
    // if loading or error skip drawing the chart
    if (loading || error) {
      return null;
    }
    chartOption
      .setHasZoom(hasZoom)
      .setTitle(hasTitle, title, subTitle)
      .setDataSource(barchartData);
    return chartOption.getOption();
  }, [hasZoom, hasTitle, title, subTitle, barchartData, loading, error]);
  console.log("======options=====, ", options);

  const chartHeight = useMemo(() => {
    // if loading or error set chart height to 0
    if (loading || error) {
      return 0;
    }
    return chartOption.getHeightWithTitle();
  }, [hasTitle, title, subTitle, barchartData, loading, error]);

  return (
    <Chart
      className={styles['inner-chart']}
      options={options}
      height={chartHeight}
      width={width}
      isZoomLabelFontSize={false}
    />
  );
};

export default ChartArticleOverview;

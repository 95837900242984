import { setSnackbarShow } from '@/actions/SIMT-SIP/SIPChecklistSubmitted/SIPChecklistAction';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SnackbarWarningIcon from '../../assets/SnackbarWarningIcon.svg';
import SnackbarSuccessIcon from '../../assets/SnackbarSuccessIcon.svg';
import SnackbarInfoIcon from '@/assets/SnackbarInfoIcon.svg';
import React from 'react';
import AlertBody from './AlertBody';
import AlertActions from './AlertActions';

export const ThemeContext = React.createContext();

export default function SIPChecklistSnackbar(props) {
  const { messageInfo = {} } = props;
  const { type, title, message, actions, needRefresh = false } = messageInfo;
  const dispatch = useDispatch();
  const selector = state => {
    return {
      SnackbarShow: state.SIPChecklist.SnackbarShow,
    };
  };
  const { SnackbarShow } = useSelector(selector);

  function handleClose(event, reason) { //https://mui.com/material-ui/react-snackbar
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setSnackbarShow(false));
    if(needRefresh) location.reload();
  }

  function getTheme(bgcolor, borderColor, titleColor, actionColor) {
    return {
      bgcolor: bgcolor,
      borderColor: borderColor,
      titleColor: titleColor,
      actionColor: actionColor,
    };
  }

  function setTheme() {
    switch (type) {
      case 'warning':
        return getTheme('#FFFBEB', '#FFCA7B', '#D97706', '#D97706');
      case 'error':
        return {};
      case 'info':
        return getTheme('#E8EDFB', '#4C81EB', '#154AB6', '#154AB6');
      case 'success':
        return getTheme('#ECFDF5', '#42C1B3', '#0D6A61', null);
      default:
        return {};
    }
  }
  const theme = setTheme();

  return (
    <ThemeContext.Provider value={theme}>
      <Snackbar
        open={SnackbarShow}
        autoHideDuration={needRefresh?null:3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          top: '76px !important',
          right:'36px !important'
        }}
      >
        <Alert
          onClose={handleClose}
          severity={type}
          action=''
          sx={{
            width: '546px',
            bgcolor: theme.bgcolor,
            p: '16px 32px 16px 24px',
            border: `1px solid ${theme.borderColor}`,
            boxShadow: '0px 12px 24px rgba(38,46,53,0.12)',
            borderRadius: '5px',
            boxSizing: 'border-box',
            '& .MuiAlert-icon': {
              marginRight: '12px',
              p: 0,
            },
            '& .MuiAlert-message': {
              p: 0,
              overflow:'hidden'
            },
            '& .MuiAlertTitle-root': {
              height: '17px',
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: '700',
              lineHeight: '17px',
              letterSpacing: '0em',
              textAlign: 'left',
              margin:'0px',
              color: theme.titleColor,
            },
          }}
          iconMapping={{
            warning: <SnackbarWarningIcon />,
            success: <SnackbarSuccessIcon />,
            info:<SnackbarInfoIcon/>,
          }}
        >
          <AlertTitle>{title}</AlertTitle>
          <AlertBody>{message}</AlertBody>
          <AlertActions actions={actions} />
        </Alert>
      </Snackbar>
    </ThemeContext.Provider>
  );
}

import { useState, useEffect } from 'react';
import AutocompleteArrowIcon from '@/componentsphase2/Autocomplete/SVGComponents/AutocompleteArrowIcon.svg';
import Autocomplete from '@mui/material/Autocomplete';
import { axios_instance } from '@/utils/axios_instance';
import TextField from '@mui/material/TextField';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { judgeCondition } from '../utils';

export default function Select({ value, update, properties, onCommand }) {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (properties?.options?.url) {
      const { url, params } = properties.options;
      axios_instance.get(url, { params }).then(res => {
        if (res.data.code !== 200) {
          return Promise.reject(res.data);
        }
        const optionsToBeSet = Array.isArray(res.data?.data)
          ? res.data.data
          : Array.isArray(res.data?.data?.result)
          ? res.data.data.result
          : [];
        setOptions(optionsToBeSet);
      });
    } else if (Array.isArray(properties.options)) {
      setOptions(properties.options);
    }
  }, [properties.options]);

  useEffect(()=>{
    console.log("options.label",options);
    console.log("options.label",options.label);
    let returnlabel = '';
    for(let key in properties.options.label){
      console.log(key);
    }
  },[options]);

  return (
    <Autocomplete
      value={value}
      onChange={(_, newValue) => {
        if (Array.isArray(properties.options)) {
          update(newValue);
        } else {
          const key = properties.options?.value;
          if (key) {
            update(newValue[properties.options?.value]);
          } else {
            update(newValue);
          }
        }
        if (Array.isArray(properties?.events?.change)) {
          properties.events.change.forEach(conf => {
            if (judgeCondition(conf.condition, newValue)) {
              onCommand(conf.command, conf[conf.command]);
            }
          });
        }
      }}
      popupIcon={<AutocompleteArrowIcon />}
      options={options}
      disableClearable
      getOptionLabel={option =>
        Array.isArray(properties.options)
          ? option
          : option[properties.options?.label[0]]
      }
      filterOptions={createFilterOptions({
        limit:10,
        stringify: option =>{
          if(Array.isArray(properties.options)){
            return option;
          }else{
            let returnlabel = '';
            for(let la of properties.options?.label){
                returnlabel += option[la];
            }
            return returnlabel;
          }
        }
      })}
      renderInput={params => (
        <TextField
          {...params}
          // error
          helperText=' '
          hiddenLabel
          readonly
          placeholder={properties.placeholder || `Choose ${properties.title}`}
          size='small'
          inputProps={{
            ...params.inputProps,
            readOnly: !properties.searchable,
            // tabIndex: -1, // disable focus
            onClick: () => {
              if (Array.isArray(properties?.events?.click)) {
                properties.events.click.forEach(conf => {
                  if (judgeCondition(conf.condition, params.inputProps.value)) {
                    onCommand(conf.command, conf[conf.command]);
                  }
                });
              }
            },
          }}
        />
      )}
      renderOption={(props, option, state) => (
        <li
          {...props}
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: '4px',
            height: '30px',
            fontSize: '12px',
            lineHeight: '9px',
            marginBottom: '4px',
          }}
        >
          {Array.isArray(properties.options) ? (
            <div>{option}</div>
          ) : (
            properties.options?.label.map(key => <div>{option[key]}</div>)
          )}
        </li>
      )}
    />
  );
}

import { FormattedMessage } from 'react-intl';
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
function HeaderTitle(props) {
  // title 标题需要使用国际化
  // require:(bool) 表示是否是必填的
  // add:(bool) 表示是否需要加号
  // Handadd:(function) 点击加号的方法
  const { title, required = false, add = false, handleAdd = () => {} } = props;
  return (
    <>
      <Box
        data-selenium-id='SIP_DetailPage_DecisionSection-HanderTitle-Box'
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '108px',
          height: '24px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#113D95',
          textTransform: 'capitalize',
        }}
      >
        <FormattedMessage
          id={title}
          data-selenium-id={`SIP_DetailPage_DecisionSection-HanderTitle-Box-${title}`}
        />
        {required ? (
          <Box
            data-selenium-id='SIP_DetailPage_DecisionSection-HanderTitle-InnerBox'
            style={{
              width: '8px',
              height: '18px',
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '18px',
              letterSpacing: '0.08em',
              color: '#C40000',
              paddingLeft: '2px',
            }}
          >
            *
          </Box>
        ) : (
          <></>
        )}

        {add ? (
          <AddIcon
            onClick={handleAdd}
            data-selenium-id='SIP_DetailPage_DecisionSection-HanderTitle-AddIcon'
          ></AddIcon>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

export default HeaderTitle;

import { Drawer, Box } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AlertPage from './AlerPage';

export const NotificationDrawer = props => {
  const selector = state => {
    return {
      notificationDrawerOpen: state.Alert.notificationDrawerOpen,
      userAuthDrawerOpen: state.UserManage.siderOpen,
    };
  };
  const { notificationDrawerOpen, userAuthDrawerOpen } = useSelector(selector);
  return (
    <Drawer
      variant='persistent'
      anchor='right'
      open={notificationDrawerOpen}
      sx={{
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          zIndex: 1200,
          width: '100%',
          boxSizing: 'border-box',
          // backgroundColor: '#F6F7F8',
          backgroundColor: 'transparent',
        },
      }}
    >
      <Box
        sx={{
          marginLeft: userAuthDrawerOpen ? '240px' : '83px',
          transition: 'all 225ms',
        }}
      >
        <AlertPage></AlertPage>
      </Box>
    </Drawer>
  );
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getUTCToDate } from '@/utils/commonUtils';
import {
  CreateAndUpdateTitle,
  CreateAndUpdateLittleTitle,
} from '../../../CommonComponents/CreateAndUpdateTitle';
import CreateSIDatePickerBlock from '../../../CommonComponents/CreateSIDatePickerBlock';
import CreateAndUpdateSwitch from '../../../CommonComponents/CreateAndUpdateSwitch';
import CreateSIAutocompleteMultiple from '../../../CommonComponents/CreateSIAutocompleteMultiple';
import { clearCreateGEResult } from '../../../../../../actions/SIMT-SI/GuestEditor/GEAction';
import { setEditType } from '@/actions/SIMT-User/Alert/AlertAction';
import CreateGEPage from '../../../../../GEPage/Overview/CreateGE';
import { Box, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { formatGENoGECode } from '../../utils';
import { useUpdateEffect } from 'ahooks';

import {getSystemConfig} from "@/actions/SIMT-User/Config";
import {getJournalListWithCodeOptIn, getJournalListWithNameOptIn} from "@/actions/SIMT-SI/SpecialIssue/Journal";
import {getGE} from "@/actions/SIMT-SI/SpecialIssue/GuestEditor";
import {getSIData} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssuePage";

function GEAndDatesForm(props) {
  const {
    MandatoryList,
    clickedButton,
    GuestEditors,
    setGuestEditors,
    Dates,
    setDates,
    setJump,
    GERepeatErr,
    setGERepeatErr,
    clickTime,
    setHasError = e => {},
  } = props;
  const intl = useIntl(); // 为国际化提供的API支持
  const openForSubTitle = intl.messages['createSI.openForSub'];
  const openForSubErrorText = intl.messages['createSI.openForSubErrorText'];
  const [OpenSubDate, setOpenSubDate] = React.useState(null);
  const [isBottom, setIsBottom] = React.useState(false);
  const [createAndUpdateFooterBottom, setCreateAndUpdateFooterBottom] =
    React.useState('0px');
  const [first, setfirst] = useState(true);
  const [IdeaDate, setIdeaDate] = React.useState(null);
  const [AcquiredDate, setAcquiredDate] = React.useState(null);
  const [ExpectedSubmissionDeadline, setExpectedSubmissionDeadline] =
    React.useState(null);
  const [ExpectedAcceptanceDate, setExpectedAcceptanceDate] =
    React.useState(null);
  const [ExpectedPublicationDate, setExpectedPublicationDate] =
    React.useState(null);
  const [openForSubGreaterDateOfGet, setOpenForSubGreaterDateOfGet] =
    useState(false);
  const [ideaDateGreaterDateOfGet, setIdeaDateGreaterDateOfGet] =
    useState(false);
  const [submissionDeadlineGreaterOfGet,setSubmissionDeadlineGreaterOfGet] = useState(false);
  const [publicationDateGreaterOfGet, setPublicationDateGreaterOfGet] =
    useState(false);
  const [acceptanceDateGreaterOfGet, setAcceptanceDateGreaterOfGet] =
    useState(false);
  const [closedSubmit, setClosedSubmit] = useState('');
  const [GE, setGE] = useState('');
  const [GeOpen, setGeOpen] = useState(false);
  const [LeadGEValue, setLeadGEValue] = useState([]);
  const [COGEValue, setCOGEValue] = useState([]);
  const [GEData, setGEData1] = useState([
    {
      Id: 1,
      firstName: 'GEA',
      primaryEmail: '111@11.com',
      lastName: '111',
    },
  ]);

  const [CoGEData, setCoGEData] = useState([]);
  const [leadGEData, setLeadGEData] = useState([]);
  //Create GE open
  const [createOpen, setCreateOpen] = useState(false);
  const [EmailError, setEmailError] = useState(false);
  const dispatch = useDispatch();
  const [loadLock, setLoadLock] = useState(true);
  const [isLead, setIsLead] = useState();
  const [checked, setChecked] = useState(
    Dates.ClosedforSubmission === 'Yes' ? true : false
  );
  const [geDataLoading, setGeDataLoading] = useState(true);

  const selector = state => {
    return {
      CEResult: state.SI.CE, //CE下拉框数据
      JourListResult: state.SI.JourList, //journal下拉框数据
      GEResult: state.SI.GE, //GE下拉框数据
      CreateGEResult: state.SI.createGEResult, //创建GE提示
      UserFirstNameInfo: state.User.firstName, //登录的用户信息
      UserIDInfo: state.User.userId,
      UserLastNameInfo: state.User.lastName,
      UserEmailInfo: state.User.email,
      moreJourListResult: state.SI.moreJourList, //用于加载更多journal （可能还会用，暂时没用）
      checkTitleResult: state.SI.checkTitle, //title不可重复
      SITypeList: state.SystemConfig.SITypeList,
      PapaerComfigList: state.SystemConfig.PapaerComfigList,
      configInfo: state.SystemConfig.systemConfig,
    };
  };
  const { GEResult, CreateGEResult, configInfo } = useSelector(selector);
  const [maxGeNumber, setMaxGeNumber] = useState(20);

  useEffect(() => {
    dispatch(getSystemConfig());
  }, []);

  useEffect(() => {
    setMaxGeNumber(
      configInfo === null ? 20 : configInfo.eachSiGuestEditorMaxNumber
    );
  }, [configInfo]);

  //create ge callback
  useEffect(() => {
    let result1 = CreateGEResult;
    if (CreateGEResult != 'error' && CreateGEResult != '') {
      if (CreateGEResult != 'EmailExit') {
        setCreateOpen(false);
        setGeOpen(false);
        let GES = [result1];

        if (result1.isLead) {
          GES = LeadGEValue.concat(GES);
          setLeadGEValue(GES);
        } else {
          GES = COGEValue.concat(GES);
          setCOGEValue(GES);
        }
        dispatch(getGE('')); //更新GEdropdown 否则拿不到新数据
        dispatch(clearCreateGEResult());
      } else {
        setCreateOpen(true);
        setGeOpen(true);
        setEmailError(true);
      }
    }
  }, [CreateGEResult]);

  //传递选项值
  const handleIdeaDate = newValue => {
    setIdeaDate(newValue);
    let data = JSON.parse(JSON.stringify(Dates));
    data.IdeaDate = newValue;
    setDates(data);
  };
  const handleSubmissionDeadLine = newValue => {
    setExpectedSubmissionDeadline(newValue);
    let data = JSON.parse(JSON.stringify(Dates));
    data.SubmissionDeadLine = newValue;
    setDates(data);
  };

  const handleExpectedAcceptanceDate = newValue => {
    setExpectedAcceptanceDate(newValue);
    let data = JSON.parse(JSON.stringify(Dates));
    data.ExpectedAcceptanceDate = newValue;
    setDates(data);
  };

  const handleExpectedPublicationDate = newValue => {
    setExpectedPublicationDate(newValue);
    let data = JSON.parse(JSON.stringify(Dates));
    data.ExpectedPublicationDate = newValue;
    setDates(data);
  };

  const handleAcquiredDate = newValue => {
    setAcquiredDate(newValue);
    let data = JSON.parse(JSON.stringify(Dates));
    data.AcquiredDate = newValue;
    setDates(data);
    // moment(Dates.OpenForSubmissionDate).format("YYYY-MM-DD")
  };

  const handleOpenSubdDate = newValue => {
    setOpenSubDate(newValue);
    let data = JSON.parse(JSON.stringify(Dates));
    data.OpenForSubmissionDate = newValue;
    setDates(data);
  };

  const handleClosedSubmitChange = event => {
    let data = JSON.parse(JSON.stringify(Dates));
    data.ClosedforSubmission = event.target.checked === true ? 'Yes' : 'No';
    setDates(data);
    setChecked(event.target.checked);
    setClosedSubmit(event.target.checked === true ? 'Yes' : 'No');
  };

  useEffect(() => {
    let LeadGES = LeadGEValue.concat();
    let data = JSON.parse(JSON.stringify(GuestEditors));
    data.LeadGe = LeadGES;
    setGuestEditors(data);
  }, [LeadGEValue]);

  useEffect(() => {
    let COGES = COGEValue.concat();
    let data = JSON.parse(JSON.stringify(GuestEditors));
    data.CoGe = COGES;
    setGuestEditors(data);
  }, [COGEValue]);

  const handleLeadGESelect = value => {
    setLeadGEValue(value);
    setGE('');
  };

  const handleCOGESelect = value => {
    setCOGEValue(value);
    setGE('');
  };

  const getGEFromEmail = async value => {
    await dispatch(getGE(value));
  };

  const handleCreateOpen = isLead => {
    if (
      (isLead && LeadGEValue.length < maxGeNumber) ||
      (!isLead && COGEValue.length < maxGeNumber)
    ) {
      setCreateOpen(true);
      setGeOpen(true);
    }
  };

  const handleCreateClose = () => {
    setCreateOpen(false);
    setGeOpen(false);
  };

  const isGERepeatErr = (array1, array2) => {
    for (let i = 0; i < array1.length; i++) {
      for (let j = 0; j < array2.length; j++) {
        if (array1[i].primaryEmail === array2[j].primaryEmail) {
          return true;
        }
      }
    }
    return false;
  };

  React.useEffect(() => {
    isBottom
      ? setCreateAndUpdateFooterBottom('75px')
      : setCreateAndUpdateFooterBottom('0px');
  }, [isBottom]);

  useEffect(() => {
    setTimeout(() => {
      setGeDataLoading(false);
    }, 10000);
    let result = GEResult;
    if (result != null && result !== undefined && result !== 'error') {
      setGEData1(result);
      setCoGEData(result);
      setLeadGEData(result);
    }
  }, [GEResult]);

  //延迟获取GE值
  useEffect(() => {
    setGeDataLoading(true);
    if (GE === ' ') {
      getGEFromEmail(GE);
    } else {
      let active = true;
      (async () => {
        if (GE !== undefined && GE !== '') {
          await sleep(1e3); // For demo purposes.
        }
        if (active) {
          if (GE !== undefined) {
            getGEFromEmail(GE);
          }
        }
      })();

      return () => {
        active = false;
      };
    }
  }, [GE]);

  useEffect(() => {
    if (isGERepeatErr(COGEValue, LeadGEValue)) {
      setGERepeatErr(true);
    } else {
      setGERepeatErr(false);
    }
    if (!loadLock) {
      getGEFromEmail('');
    }
    setTimeout(() => {
      setLoadLock(false);
    }, 2000);
  }, [LeadGEValue, COGEValue]);

  //下拉框初始化
  useEffect(() => {
    dispatch(getGE(''));
    //GE的获取
    handleLeadGESelect(formatGENoGECode(GuestEditors.LeadGe));

    handleCOGESelect(formatGENoGECode(GuestEditors.CoGe));

    //日期选择
    if (Dates.IdeaDate === null) {
      setIdeaDate(null);
    } else {
      setIdeaDate(
        // getUTCToDate(moment(Dates.IdeaDate))
        moment(Dates.IdeaDate).utc().format('YYYY-MM-DD')
      );
    }

    if (Dates.AcquiredDate === null) {
      setAcquiredDate(null);
    } else {
      setAcquiredDate(moment(Dates.AcquiredDate).utc().format('YYYY-MM-DD'));
    }

    if (Dates.OpenForSubmissionDate === null) {
      setOpenSubDate(null);
    } else {
      setOpenSubDate(moment(Dates.OpenForSubmissionDate).utc().format('YYYY-MM-DD'));
    }

    if (Dates.SubmissionDeadLine === null) {
      setExpectedSubmissionDeadline(null);
    } else {
      setExpectedSubmissionDeadline(
        moment(Dates.SubmissionDeadLine).utc().format('YYYY-MM-DD')
      );
    }

    if (Dates.ExpectedAcceptanceDate === null) {
      setExpectedAcceptanceDate(null);
    } else {
      setExpectedAcceptanceDate(
        moment(Dates.ExpectedAcceptanceDate).utc().format('YYYY-MM-DD')
      );
    }

    if (Dates.ExpectedPublicationDate === null) {
      setExpectedPublicationDate(null);
    } else {
      setExpectedPublicationDate(
        moment(Dates.ExpectedPublicationDate).utc().format('YYYY-MM-DD')
      );
    }
  }, [first]);

  //idea和acquired日期比较结果,idea大于acquired为true
  useEffect(() => {
    if (IdeaDate !== null && AcquiredDate !== null) {
      setIdeaDateGreaterDateOfGet(
        moment(IdeaDate).isAfter(moment(AcquiredDate))
      );
    } else {
      setIdeaDateGreaterDateOfGet(false);
    }
  }, [IdeaDate, AcquiredDate]);

  //OpenSubDate和ExpectedSubmissionDeadline日期比较结果,OpenSubDate大于或者等于ExpectedSubmissionDeadline为true
  useEffect(() => {
    if (!isEmptyValue(OpenSubDate) && ExpectedSubmissionDeadline !== null) {
      setSubmissionDeadlineGreaterOfGet(
        !moment(OpenSubDate).isBefore(moment(ExpectedSubmissionDeadline))
      );
    } else {
      setSubmissionDeadlineGreaterOfGet(false);
    }
  }, [OpenSubDate, ExpectedSubmissionDeadline]);

  //PublicationDate和SubmissionDeadline,ExceptAcceptance日期比较结果,Publication小于任何一个日期时为true
  useEffect(() => {
    let newPublicationDateGreaterOfGet = false;
    if (ExpectedPublicationDate !== null) {
      newPublicationDateGreaterOfGet =
        (ExpectedSubmissionDeadline &&
          !moment(ExpectedSubmissionDeadline).isBefore(
            moment(ExpectedPublicationDate)
          )) ||
        (ExpectedAcceptanceDate &&
          !moment(ExpectedAcceptanceDate).isBefore(
            moment(ExpectedPublicationDate)
          ));
    }
    setPublicationDateGreaterOfGet(newPublicationDateGreaterOfGet);
  }, [
    ExpectedAcceptanceDate,
    ExpectedSubmissionDeadline,
    ExpectedPublicationDate,
  ]);

  //AcceptanceDate和SubmissionDeadline日期比较结果,AcceptanceDate小于SubmissionDeadline为true
  useEffect(() => {
    if (ExpectedAcceptanceDate != null && ExpectedSubmissionDeadline != null) {
      setAcceptanceDateGreaterOfGet(
        !moment(ExpectedSubmissionDeadline).isBefore(
          moment(ExpectedAcceptanceDate)
        )
      );
    } else {
      setAcceptanceDateGreaterOfGet(false);
    }
  }, [ExpectedAcceptanceDate, ExpectedSubmissionDeadline]);

  function sleep(delay = 0) {
    return new Promise(resolve => {
      setTimeout(resolve, delay);
    });
  }

  //判断一个值是否为空或者空串
  const isEmptyValue = value => {
    return (
      value === null ||
      value === '' ||
      value === undefined ||
      value.length === 0
    );
  };
  //error信息
  const [emptyErrMsg, setEmptyErrMsg] = useState(
    'This attribute is mandatory to input'
  );

  const MandatoryJudge = (value, list) => {
    return list.indexOf(value) > -1;
  };

  //判断所有必填项是否已填
  const isEmpty = () => {
    return (
      (MandatoryJudge('Lead-GE', MandatoryList) && isEmptyValue(LeadGEValue)) ||
      (MandatoryJudge('Co-GE', MandatoryList) && isEmptyValue(COGEValue)) ||
      (MandatoryJudge('Acquired Date', MandatoryList) &&
        isEmptyValue(AcquiredDate)) ||
      (MandatoryJudge(openForSubTitle, MandatoryList) &&
        isEmptyValue(OpenSubDate)) ||
      (MandatoryJudge('Submission Deadline', MandatoryList) &&
        isEmptyValue(ExpectedSubmissionDeadline)) ||
      (MandatoryJudge('Expected Acceptance Date', MandatoryList) &&
        isEmptyValue(ExpectedAcceptanceDate)) ||
      (MandatoryJudge('Expected Publication Date', MandatoryList) &&
        isEmptyValue(ExpectedPublicationDate))
    );
  };
  //判断是否存在错误
  const isWrong = () => {
    return (
      openForSubGreaterDateOfGet ||
      ideaDateGreaterDateOfGet ||
      publicationDateGreaterOfGet ||
      acceptanceDateGreaterOfGet ||
      GERepeatErr || submissionDeadlineGreaterOfGet
    );
  };

  const pageHasError = isWrong() || isEmpty();

  useEffect(() => {
    if (!clickedButton) return;
    setHasError(pageHasError);
  },[pageHasError, clickedButton]);

  //如果有必填项未填或者错误则不能跳转
  useEffect(() => {
    setJump(!isEmpty() && !isWrong());
  }, [isWrong, isEmpty]);

  // create submission and review GEs
  const recoredError = () => {
    if (MandatoryJudge('Lead-GE', MandatoryList) && isEmptyValue(LeadGEValue)) {
      return 'createSIProductionLeadGE';
    } else if (GERepeatErr) {
      return 'createSIProductionCoGE';
    } else if (
      MandatoryJudge('Acquired Date', MandatoryList) &&
      (ideaDateGreaterDateOfGet || isEmptyValue(AcquiredDate))
    ) {
      return 'createSIProductionAcquiredDateDatePicker';
    } else if (
      MandatoryJudge(openForSubTitle, MandatoryList) &&
      (openForSubGreaterDateOfGet || isEmptyValue(OpenSubDate))
    ) {
      return 'createSIProductionOpenForSubmissionDate';
    } else if (
      MandatoryJudge('Submission Deadline', MandatoryList) &&
      (isEmptyValue(ExpectedSubmissionDeadline) || submissionDeadlineGreaterOfGet)
    ) {
      return 'createSIProductionSubmissionDeadlineDatePicker';
    } else if (
      MandatoryJudge('Expected Acceptance Date', MandatoryList) &&
      (acceptanceDateGreaterOfGet || isEmptyValue(ExpectedAcceptanceDate))
    ) {
      return 'createSIProductionAcceptanceDateDatePicker';
    } else if (
      MandatoryJudge('Expected Publication Date', MandatoryList) &&
      (isEmptyValue(ExpectedPublicationDate) || publicationDateGreaterOfGet)
    ) {
      return 'createSIProductionPublicationDatePicker';
    }
  };

  // useUpdateEffect 初次进入页面不会渲染 ， 出错的话再进行定位，先定位到顶部，在定位到屏幕中间
  useUpdateEffect(() => {
    if (isEmpty() || isWrong()) {
      let currentDOM = document.getElementById('' + recoredError());
      if (currentDOM) {
        setTimeout(() => {
          window.scrollTo({ left: 0, top: 0, behavior: 'instant' });
          window.scroll({
            left: 0,
            top:
              currentDOM.getBoundingClientRect().top -
              window.screen.availHeight / 2 +
              100,
            behavior: 'instant',
          });
        }, 200);
      }
    }
  }, [clickTime]);

  function yestoday(date){
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 1);
    return newDate;
  }

  function tomorrow(date){
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    return newDate;
  }

  return (
    <Box
      data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_SubmissionAndReview-GEsAndDatesForm-RootBox'
      sx={{ width: '100%' }}
    >
      <>
        <CreateAndUpdateTitle
          title='Create New Special Issue'
          id='createSIGe&Datetitle'
          data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_SubmissionAndReview-GEsAndDatesForm-CreateSIGe&Datetitle'
        ></CreateAndUpdateTitle>
        <CreateAndUpdateLittleTitle
          littleTitle='Guest Editors'
          id='createSIGeAndDateGEtitle'
          data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_SubmissionAndReview-GEsAndDatesForm-CreateSIGeAndDateGEtitle'
        ></CreateAndUpdateLittleTitle>
        <Stack spacing={2} direction='column' sx={{ marginBottom: '20px' }}>
          {/* Lead GE */}
          <CreateSIAutocompleteMultiple
            emptyErr={clickedButton && isEmptyValue(LeadGEValue)}
            error={false}
            id='createSIAutocompleteMultipleLeadGE'
            data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_SubmissionAndReview-GEsAndDatesForm-CreateSIGeAndDateGEtitle'
            title='Lead GE'
            required={MandatoryJudge('Lead-GE', MandatoryList)}
            Value={LeadGEValue}
            options={leadGEData}
            getOptionLabel={option =>
              option.lastName +
              ',' +
              ' ' +
              option.firstName
            }
            maxGeNum={maxGeNumber}
            onFocus={() => {
              setGeDataLoading(true);
              dispatch(getGE(''));
            }}
            onChange={(event, newValue) => {
              //判断GE是否有重复(使用邮箱比对进行判断，如果重复不能进入 handleGeSelect 进行填充)
              function judgeRepetition() {
                //GE为空或只有一个元素时不做判断
                if (newValue.length === 0 || newValue.length === 1) {
                  return true;
                } else {
                  for (var i = 1; i < newValue.length; i++) {
                    if (
                      newValue[newValue.length - 1].primaryEmail ===
                      newValue[i - 1].primaryEmail
                    ) {
                      return false;
                    }
                  }
                  return true;
                }
              }
              // GE数量小于20，以及不重复时记录填充给操作
              if (newValue.length < maxGeNumber + 1 && judgeRepetition()) {
                handleLeadGESelect(newValue);
              }
            }}
            helperText={
              <FormattedMessage
                id='createSI.geRepeatErr'
                data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_SubmissionAndReview-GEsAndDatesForm-CreateSI.geRepeatErr'
              />
            }
            handleAddNewClick={() => {
              setIsLead(true);
              handleCreateOpen(true);
            }}
            onLoading={geDataLoading}
          ></CreateSIAutocompleteMultiple>

          {/* CO-GE */}
          <CreateSIAutocompleteMultiple
            emptyErr={
              clickedButton &&
              MandatoryJudge('Co-GE', MandatoryList) &&
              isEmptyValue(COGEValue)
            }
            error={GERepeatErr}
            id='createSIAutocompleteMultipleCoGE'
            data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_SubmissionAndReview-GEsAndDatesForm-CoGEInput'
            title='Co-GE'
            required={MandatoryJudge('Co-GE', MandatoryList)}
            Value={COGEValue}
            options={CoGEData}
            getOptionLabel={option =>
              option.lastName +
              ',' +
              ' ' +
              option.firstName
            }
            maxGeNum={maxGeNumber}
            handleAddNewClick={() => {
              setIsLead(false);
              handleCreateOpen(false);
            }}
            onFocus={() => {
              setGeDataLoading(true);
              dispatch(getGE(''));
            }}
            onChange={(event, newValue) => {
              //判断GE是否有重复(使用邮箱比对进行判断，如果重复不能进入 handleGeSelect 进行填充)
              function judgeRepetition() {
                //GE为空或只有一个元素时不做判断
                if (newValue.length === 0 || newValue.length === 1) {
                  return true;
                } else {
                  for (var i = 1; i < newValue.length; i++) {
                    if (
                      newValue[newValue.length - 1].primaryEmail ===
                      newValue[i - 1].primaryEmail
                    ) {
                      return false;
                    }
                  }
                  return true;
                }
              }
              // GE数量小于20，以及不重复时记录填充给操作
              if (newValue.length < maxGeNumber + 1 && judgeRepetition()) {
                handleCOGESelect(newValue);
              }
              if (GERepeatErr) {
                dispatch(setEditType('GERepeatErr'));
                // dispatch(openConfirm())
                return;
              }
              setGuestEditors({
                ...GuestEditors,
                CoGe: newValue,
              });
            }}
            helperText={
              <FormattedMessage
                id='createSI.geRepeatErr'
                data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_SubmissionAndReview-GEsAndDatesForm-CreateSI.geRepeatErr'
              />
            }
            onLoading={geDataLoading}
          ></CreateSIAutocompleteMultiple>
        </Stack>

        <CreateAndUpdateLittleTitle
          littleTitle='Dates'
          id='createSIGe&DateDatestitle'
          data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_SubmissionAndReview-GEsAndDatesForm-CreateSIGe&DateDatestitle'
        ></CreateAndUpdateLittleTitle>
        <Stack spacing={2} direction='column'>
          {/* idea date */}
          <CreateSIDatePickerBlock
            title='Idea Date'
            required={MandatoryJudge('Idea Date', MandatoryList)}
            value={IdeaDate}
            setValue={setIdeaDate}
            placeholder='YYYY-MM-DD'
            maxDate={AcquiredDate ? tomorrow(AcquiredDate) : undefined}
            emptyErr={false}
            handleChange={handleIdeaDate}
            id='createSIDatePickerBlockIdeaDate'
            data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_SubmissionAndReview-GEsAndDatesForm-IdeaDateDatePicker'
          ></CreateSIDatePickerBlock>

          {/* AcquiredDate */}
          <CreateSIDatePickerBlock
            title='Acquired Date'
            required={MandatoryJudge('Acquired Date', MandatoryList)}
            value={AcquiredDate}
            setValue={setAcquiredDate}
            placeholder='YYYY-MM-DD'
            emptyErr={clickedButton && isEmptyValue(AcquiredDate)}
            minDate={IdeaDate ? yestoday(IdeaDate) : undefined}
            // maxDate={ExpectedSubmissionDeadline ? new Date(ExpectedSubmissionDeadline) : undefined}
            handleChange={handleAcquiredDate}
            error={clickedButton && ideaDateGreaterDateOfGet}
            helperText='[Acquired Date] should be after or equal to [Idea Date]!'
            id='createSIDatePickerBlockAcquiredDate'
            data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_SubmissionAndReview-GEsAndDatesForm-AcquiredDateDatePicker'
          ></CreateSIDatePickerBlock>

          {/* Open for Submission Date */}
          <CreateSIDatePickerBlock
            title={openForSubTitle}
            required={MandatoryJudge(openForSubTitle, MandatoryList)}
            value={OpenSubDate}
            placeholder='YYYY-MM-DD'
            emptyErr={
              clickedButton &&
              MandatoryJudge(openForSubTitle, MandatoryList) &&
              isEmptyValue(OpenSubDate)
            }
            handleChange={handleOpenSubdDate}
            error={clickedButton && openForSubGreaterDateOfGet}
            helperText={openForSubErrorText}
            // maxDate={ExpectedSubmissionDeadline ? new Date(ExpectedSubmissionDeadline) : undefined}
            id='createSIDatePickerBlockOpenForSubmissionDate'
            data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_SubmissionAndReview-GEsAndDatesForm-OpenForSubmissionDate'
          ></CreateSIDatePickerBlock>

          {/* SubmissionDeadline */}
          <CreateSIDatePickerBlock
            title='Submission Deadline'
            required={MandatoryJudge('Submission Deadline', MandatoryList)}
            value={ExpectedSubmissionDeadline}
            setValue={setExpectedSubmissionDeadline}
            placeholder='YYYY-MM-DD'
            emptyErr={clickedButton && isEmptyValue(ExpectedSubmissionDeadline)}
            minDate={
              OpenSubDate === null
                ? undefined
                : new Date(moment(OpenSubDate, 'YYYY-MM-DD'))
            }
            // maxDate={ExpectedAcceptanceDate ? new Date(ExpectedAcceptanceDate) : undefined}
            handleChange={handleSubmissionDeadLine}
            id='createSIDatePickerBlockSubmissionDeadline'
            data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_SubmissionAndReview-GEsAndDatesForm-SubmissionDeadlineDatePicker'
            error={clickedButton && submissionDeadlineGreaterOfGet}
            helperText='[Submission Deadline] should be after [Open for Submission Date]!'
          ></CreateSIDatePickerBlock>

          {/* Expected Acceptance Date */}
          <CreateSIDatePickerBlock
            title='Expected Acceptance Date'
            required={MandatoryJudge('Expected Acceptance Date', MandatoryList)}
            value={ExpectedAcceptanceDate}
            setValue={setExpectedAcceptanceDate}
            placeholder='YYYY-MM-DD'
            emptyErr={clickedButton && isEmptyValue(ExpectedAcceptanceDate)}
            //minDate里写的是最小时间是上个日期加一天
            minDate={
              ExpectedSubmissionDeadline === null
                ? undefined
                : new Date(moment(ExpectedSubmissionDeadline, 'YYYY-MM-DD'))
            }
            // maxDate={ExpectedPublicationDate ? new Date(ExpectedPublicationDate) : undefined}
            error={clickedButton && acceptanceDateGreaterOfGet}
            helperText='[Acceptance Date] should be after [Submission Deadline]!'
            handleChange={handleExpectedAcceptanceDate}
            id='createSIDatePickerBlockAcceptanceDate'
            data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_SubmissionAndReview-GEsAndDatesForm-AcceptanceDateDatePicker'
          ></CreateSIDatePickerBlock>

          {/* Expected Publication Date */}
          <CreateSIDatePickerBlock
            title='Expected Publication Date'
            required={MandatoryJudge(
              'Expected Publication Date',
              MandatoryList
            )}
            value={ExpectedPublicationDate}
            setValue={setExpectedPublicationDate}
            placeholder='YYYY-MM-DD'
            emptyErr={clickedButton && isEmptyValue(ExpectedPublicationDate)}
            //minDate里写的是最小时间是上个日期加一天
            minDate={
              ExpectedAcceptanceDate === null
                ? undefined
                : new Date(moment(ExpectedAcceptanceDate, 'YYYY-MM-DD'))
            }
            error={clickedButton && publicationDateGreaterOfGet}
            helperText='[Publication Date] should be after [Acceptance Date] and [Submission Deadline]!'
            handleChange={handleExpectedPublicationDate}
            id='createSIDatePickerBlockPublicationDate'
            data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_SubmissionAndReview-GEsAndDatesForm-PublicationDatePicker'
          ></CreateSIDatePickerBlock>

          {/* Closed for Submission */}
          <CreateAndUpdateSwitch
            setValue={setChecked}
            checked={checked}
            defaultChecked={true}
            id='createAndUpdateSwitchClosedforSubmission'
            data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_SubmissionAndReview-GEsAndDatesForm-ClosedforSubmissionSwitch'
            title='Closed for Submission'
            onChange={handleClosedSubmitChange}
            required={MandatoryJudge('Closed for submission?', MandatoryList)}
          ></CreateAndUpdateSwitch>
        </Stack>

        {/* GE Pop-up */}
        <CreateGEPage
          data-selenium-id='SIPage_CreateAndUpdateSI_ProductionPage_SubmissionAndReview-GEsAndDatesForm-CreateGEPage'
          isLead={isLead}
          open={GeOpen}
          handleCreateGEPageClose={handleCreateClose}
        ></CreateGEPage>
      </>
    </Box>
  );
}
export default GEAndDatesForm;

const DEFAULT_BAR_SHAPE = {
  barMinHeight: 3,
  barMinWidth: 8,
  barMaxWidth: 150,
};
export class BasicBarSeries {
  constructor({
    datasetIndex = 0,
    attributeName,
    name,
    stackName,
    color,
    borderColor,
    textColor,
    borderRadius,
    labelFontSize,
  }) {
    this.datasetIndex = datasetIndex;
    this.attributeName = attributeName;
    this.name = name;
    this.stackName = stackName;
    this.color = color;
    this.borderColor = borderColor;
    this.textColor = textColor;
    this.borderRadius = borderRadius;
    this.labelFontSize = labelFontSize;
    this.barShape = DEFAULT_BAR_SHAPE;
  }

  getSeries() {
    return {
      type: 'bar',
      datasetIndex: this.datasetIndex,
      name: this.name || this.attributeName,
      encode: { x: this.attributeName },
      stack: this.stackName,
      label: {
        show: true,
      },
      ...this.barShape,
      emphasis: {
        disabled: true,
      },
      color: this.color,
      itemStyle: {
        borderWidth: 1,
        borderColor: this.borderColor ?? this.color,
        borderRadius: this.borderRadius,
      },
      label: {
        show: true,
        position: 'inside',
        color: this.textColor,
        fontSize: this.labelFontSize,
        formatter: params => {
          const labelValue = params.data[this.name || this.attributeName];
          if (labelValue === 0) {
            return '';
          } else {
            return labelValue;
          }
        },
      },
    };
  }
}

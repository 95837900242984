import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Editable from './Table/SubmissionOverviewEditable';
import EditSIDetailnewIcon from '@/assets/EditSIDetailnewIcon.svg';
import EditSIDetailnewHover from '@/assets/EditSIDetailnewHover.svg';

import { Box, Stack } from '@mui/material';
import {
  SET_SI_INPROGRESS,
} from '@/actions/SIMT-SI/SpecialIssue/SIAction';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import SaveAndCancelButton from '@/componentsphase2/SIDetail/SaveAndCancelButton';
import ErrorIcon from '@/assets/Error.svg';
import {getSIDetail, getSnowFlakeInfo} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueDetail";
import {EditSubmissionOverview} from "@/actions/SIMT-SI/SpecialIssue/SiEditDetail";
import {PERMISSIONS} from "@/constant/permission";

export default function SubmissionOverviewBlock(props) {
  const {
    title,
    stage,
    data,
    typeList,
    snowFlakeInfo,
    extraInfo,
    siCode,
    loadingSIDetails,
    handleSubmit,
    handleCancel,
    isDisabled,
    needSnowFlake = true,
  } = props;

  const [isFold, setIsFold] = useState(
    stage === 'Paper Commission' ||
      stage === 'Submission and Review' ||
      stage === 'Production'
  );
  useEffect(() => {
    setIsFold(
      stage === 'Paper Commission' ||
        stage === 'Submission and Review' ||
        stage === 'Production'
    );
  }, [stage, setIsFold]);
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [  SIDetailIsSaving,setSIDetailIsSaving] = useState(false);


  const [recruitedArticles, setRecruitedArticles] = useState(0);
  const [submittedArticles, setSubmittedArticles] = useState(0);
  const [acceptedArticles, setAcceptedArticles] = useState(0);
  const [rejectedArticles, setRejectedArticles] = useState(0);

  const [error, setError] = useState(false);
  const [editIsSaving, setEditIsSaving] = useState(false);
  const [valueError, setValueError] = useState(false);
  const [hover, setHover] = useState(false);
  const [firstTimeClick, setFirstTimeClick] = useState(false);

  const [submissionOverviewData, setSubmissionOverviewData] = useState([
    {
      id: '0',
      dataSource: 'EEO',
      recruitedNumber: 0,
      submittedNumber: 0,
      acceptedNumber: 0,
      rejectedNumber: 0,
      inProgress: 0,
    },
    {
      id: '1',
      dataSource: 'Snowflake',
      submittedNumber: 0,
      rejectedNumber: 0,
      inProgress: 0,
      acceptedNumber: 0,
    },
  ]);

  const selector = state => {
    return {
      UpdateSIResult: state.SI.updateSIResult,
      configInfo: state.SystemConfig.systemConfig,
      inProgressArticles: state.SI.siInprogress,
    };
  };

  const { UpdateSIResult, configInfo, inProgressArticles } =
    useSelector(selector);

  useEffect(() => {
    if (data[0]) {
      setRecruitedArticles(data[0].numberOfArticlesRecruited);
      setSubmittedArticles(data[0].numberOfArticlesSubmitted);
      setAcceptedArticles(data[0].numberOfArticlesAccepted);
      setRejectedArticles(data[0].numberOfArticlesRejected);
      dispatch({
        type: SET_SI_INPROGRESS,
        data: data[0].numberOfArticlesInProgress,
      });

      let obj = [
        {
          id: '0',
          dataSource: 'EEO',
          recruitedNumber: data[0].numberOfArticlesRecruited,
          submittedNumber: data[0].numberOfArticlesSubmitted,
          acceptedNumber: data[0].numberOfArticlesAccepted,
          rejectedNumber: data[0].numberOfArticlesRejected,
          inProgress: data[0].numberOfArticlesInProgress,
        },
        {
          id: '1',
          dataSource: 'Snowflake',
          recruitedNumber: null,
          submittedNumber: snowFlakeInfo.numberOfArticlesSubmitted,
          rejectedNumber: snowFlakeInfo.numberOfArticlesRejected,
          inProgress: snowFlakeInfo.numberOfArticlesInProgress,
          acceptedNumber: snowFlakeInfo.numberOfArticlesAccepted,
        },
      ];
      setSubmissionOverviewData(obj);
    }
  }, [data]);

  useEffect(() => {
    if (submittedArticles !== undefined) {
      if (
        submittedArticles < acceptedArticles + rejectedArticles ||
        (extraInfo !== null && acceptedArticles < extraInfo)
      ) {
        setError(true);
      } else {
        dispatch({
          type: SET_SI_INPROGRESS,
          data: submittedArticles - acceptedArticles - rejectedArticles,
        });
        setError(false);
      }
    }
  }, [
    recruitedArticles,
    submittedArticles,
    acceptedArticles,
    rejectedArticles,
  ]);

  const maxSIArticles =
    configInfo === null ? 1000 : configInfo.eachSiArticlesMaxNumber;

  const createRow = (
    id,
    label,
    minWidth,
    maxWidth,
    isOrder = true,
    align = 'left'
  ) => {
    return { id, label, minWidth, maxWidth, isOrder, align };
  };

  const tableHeads = [
    createRow('dataSource', 'DATA SOURCE', '248px', '248px', false),
    createRow(
      'recruitedNumber',
      'RECRUITED',
      '140px',
      '140px',
      false,
      'center'
    ),
    createRow(
      'submittedNumber',
      'SUBMITTED',
      '140px',
      '140px',
      false,
      'center'
    ),
    createRow('acceptedNumber', 'ACCEPTED', '140px', '140px', false, 'center'),
    createRow('rejectedNumber', 'REJECTED', '140px', '140px', false, 'center'),
    createRow('inProgress', 'IN PROGRESS', '140px', '140px', false, 'center'),
  ];

  const clickWhiteList = ['dataSource', 'inProgress'];

  const handleInfoChange = (id, key, value) => {
    //此处需要深拷贝。浅拷贝会出问题，不知原因
    if (typeof value === 'string') {
      value = value.replace(/[^\d]/g, '');
    }
    if (value !== '') {
      value = parseInt(value);
    }
    // console.log('---------------');
    // console.log('error:', error);
    // console.log('firstTimeClick:', firstTimeClick);
    // console.log('isEditing:', isEditing);
    // console.log('valueError:', valueError);
    if (value > -1 && value < maxSIArticles + 1) {
      if (key === 'recruitedNumber') {
        setRecruitedArticles(parseInt(value));
      } else if (key === 'submittedNumber') {
        setSubmittedArticles(parseInt(value));
      } else if (key === 'acceptedNumber') {
        setAcceptedArticles(parseInt(value));
      } else if (key === 'rejectedNumber') {
        setRejectedArticles(parseInt(value));
      } else if (key === 'inProgress') {
        dispatch({ type: SET_SI_INPROGRESS, data: parseInt(value) });
      }
      setValueError(false);
    } else {
      setValueError(true);
    }
  };

  const isEditableShow = () => {
    //该Block打开状态 且不在编辑中时
    return isFold && !isEditing;
  };

  const isEditingShow = () => {
    //该Block打开状态 且正在编辑时
    return isFold && isEditing;
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  /**
   * 点击保存按 √ 时，执行的操作
   * @returns
   */
  const handleCheckButtonClick = async () => {
    setHover(false);
    // console.log('error:', error);
    // console.log('firstTimeClick:', firstTimeClick);
    // console.log('valueError:', valueError);
    if (error) {
      setFirstTimeClick(true);
      return;
    }
    const params = {
      numberOfArticlesRecruited: parseInt(recruitedArticles),
      numberOfArticlesSubmitted: parseInt(submittedArticles),
      numberOfArticlesAccepted: parseInt(acceptedArticles),
      numberOfArticlesRejected: parseInt(rejectedArticles),
      numberOfArticlesInProgress: parseInt(inProgressArticles),
      siCode: siCode,
    };
    setEditIsSaving(true);
    setSIDetailIsSaving(true);
    await dispatch(EditSubmissionOverview(params));
    await dispatch(getSIDetail(siCode));
    setEditIsSaving(false);
    setIsEditing(false);
    setSIDetailIsSaving(false);
    await dispatch(getSnowFlakeInfo(siCode));
    // setEditIsSaving(false);
  };

  /**
   * 点击叉号×时，关闭 编辑/新增 状态；与Editable.js中的checkClose搭配使用
   */
  const handleCloseButtonClick = () => {
    setHover(false);
    setRecruitedArticles(data[0].numberOfArticlesRecruited);
    setSubmittedArticles(data[0].numberOfArticlesSubmitted);
    setAcceptedArticles(data[0].numberOfArticlesAccepted);
    setRejectedArticles(data[0].numberOfArticlesRejected);
    dispatch({
      type: SET_SI_INPROGRESS,
      data: data[0].numberOfArticlesInProgress,
    });
    setError(false);
    if (isEditingShow()) {
      setIsEditing(false);
    }
  };
  // const matches1458 = useMediaQuery('(min-width: 1458px)');

  const cellStyle = {
    fontWeight: 400,
  };

  const userPermissions = localStorage.getItem('userPermissions');

  return (
    <Box data-selenium-id='SIDetail_SubmissionOverviewBlock-ContainerBox'>
      <Box
        sx={{ mb: '16px', mt: '32px',cursor:'pointer' }}
        id='titleBox'
        data-selenium-id='SIDetail_SubmissionOverviewBlock-TitleBox'
      >
        <Box
          class='titleFoldButton'
          onClick={() => {
            async function fetchData() {
              //fetch data
              await dispatch(getSIDetail(siCode));
            }
            fetchData();
            setIsFold(!isFold);
          }}
          data-selenium-id='SIDetail_SubmissionOverviewBlock-TitleFoldBtn'
        >
          {isFold ? <MarketChevronUp /> : <SIDetailArrowDown />}
        </Box>
        <Stack
          id='titleBoxLeft'
          data-selenium-id='SIDetail_SubmissionOverviewBlock-TitleBoxLeft'
          spacing={1}
          direction='row'
          alignItems='center'
        >
          {/* 标题 */}
          <Box
            id='siDetialPageSubmissionOverviewSectionTitle'
            data-selenium-id='SIDetail_SubmissionOverviewBlock-SectionTitle'
            sx={{cursor:'default'}}
          >
            <FormattedMessage
              id='siDetail.submissionOverview'
              data-selenium-id='SI-Detail-SubmissionOverviewBlcok-OverviewText'
            />
          </Box>
          {/* 编辑按钮 */}
          {isEditableShow() &&
          !loadingSIDetails &&
          userPermissions.includes(PERMISSIONS.SI_WRITE) &&
          stage !== 'Shelved' &&
          stage !== 'Idea/Proposal' &&
          stage !== 'Acquired' ? (
            <Box
              className='editableButton'
              data-selenium-id='SIDetail_SubmissionOverviewBlock-BtnBox1'
              sx={{ paddingTop: '2px' }}
            >
              <div
                onClick={handleEditClick}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                {hover ? (
                  <EditSIDetailnewHover data-selenium-id='SIDetail_SubmissionOverviewBlock-DefaultEditBtnIcon' />
                ) : (
                  <EditSIDetailnewIcon id='editSIDetailnewIcon' data-selenium-id='SIDetail_SubmissionOverviewBlock-HoverEditBtnIcon' />
                )}
              </div>
            </Box>
          ) : null}
        </Stack>
      </Box>
      {isFold ? (
        <Box
          // sx={{ width: '1092px' }}
          data-selenium-id='SIDetail_SubmissionOverviewBlock-FoldBox'
        >
          {/* 错误信息 */}
          <Box
            id='sidetail-submission-error-message'
            data-selenium-id='SIDetail_SubmissionOverviewBlock-ErrMsgBox'
            sx={{
              color: '#d32f2f',
              fontSize: '12px',
              fontWeight: 600,
            }}
            display={error && isEditing && firstTimeClick ? 'block' : 'none'}
          >
            <ErrorIcon />
            &ensp;
            <FormattedMessage
              id='siDetail.submissionError'
              data-selenium-id='SIDetail_SubmissionOverviewBlock-ErrText'
            />
          </Box>
          <Editable
            title={title}
            id='siDetailPageSubmissionOverviewTable'
            data-selenium-id='SIDetail_SubmissionOverviewBlock-Table'
            //表头
            TableHeads={tableHeads}
            //表格
            TableData={
              needSnowFlake
                ? submissionOverviewData
                : submissionOverviewData.filter(
                    item => item.dataSource !== 'Snowflake'
                  )
            }
            //编辑状态指示
            isEditing={isEditing}
            //修改信息
            handleInfoChange={handleInfoChange}
            //表格内容类型，标识了表格中每一列的类型，是日期？数字？链接？
            typeList={typeList}
            //控制一些错误提示的函数，例如setVolumeErrorFlag在Volume字段填写出错时会调用，将volumeErrorFlag置为true
            hasError={error}
            cellStyle={cellStyle}
            clickWhiteList={clickWhiteList}
          ></Editable>

          {isEditingShow() ? (
            <Box
              sx={{
                position: 'relative',
                top: '16px',
              }}
            >
              <SaveAndCancelButton
                id='SIDetail_SubmissionOverviewBlock-TwoBtn'
                data-selenium-id='SIDetail_SubmissionOverviewBlock-TwoBtn'
                handleCancel={handleCloseButtonClick}
                handleSubmit={handleCheckButtonClick}
                isDisabled={firstTimeClick && error}
                isSaving={SIDetailIsSaving}
              ></SaveAndCancelButton>
            </Box>
          ) : null}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}

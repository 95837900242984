import {axios_instance} from "@/utils/axios_instance";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {getSIPStatus} from "@/actions/SIMT-SIP/SIP/SipDetail";
import {getMessageIdFromResponse} from "@/utils/commonUtils";
import {Edit_SIP_INFOMATION, SIP_VERSION_ERROR} from "@/actions/SIMT-SIP/SIP/SIPAction";
import { da } from "date-fns/locale";

export const EditSIPInfomation = (data) => {
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${data.sipCode}`;
            const res = await axios_instance.put(url, 
                {
                    
                    updateRequests: {
                        acceptanceDeadline: data.acceptanceDeadline,
                        aimsAndScope: data.aimsAndScope,
                        epDate: data.epDate,
                        fmSubmissionDeadline:data.fmSubmissionDeadline,
                        keywords:data.keywords,
                        notesForEditor:data.notesForEditor,
                        references:data.references,
                        sipSourceId:data.sipSourceId,
                        sipTitle:data.sipTitle,
                        topics:data.topics,
                    }
                }, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
                params:{
                    sipVersion:data.sipVersion,
                    action:'update',
                }
            });
            const{code,message} = res.data;
            if (code === 200) {
                dispatch({type: Edit_SIP_INFOMATION, data:message});
                return res.data.sipVersion;
            } else if (code === SIP_VERSION_ERROR) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.sipStatusChanged',
                        {},
                        SEVERITIES.warning
                    )
                );
                getSIPStatus(data.sipCode);
            } else {
                const mid = getMessageIdFromResponse(res);
                dispatch(setSnackbarMessageAndOpen(mid, {}, SEVERITIES.error));
            }
        } catch (err) {
        }
    };
};
import moment from "moment/moment";
import { axios_instance_fileDownload } from "@/utils/axios_instance";
import { downloadFile } from "@/actions/SIMT-SI/SpecialIssue/SIAction";
import { EXPORT_UM } from "@/actions/SIMT-User/User/UserManageAction";

export function exportUM(selectedUMIds, fileType) {
    // USERList_MMDDYYYY_HHMMSS
    const filename = `USERList_${moment()
        .utc()
        .format('MMDDYYYY_HHmmss')}.${fileType}`;
    return async dispatch => {
        const requestURL =
            '/api/v1/user-service/users/export';
        try {
            let res = await axios_instance_fileDownload.post(requestURL,
                {
                    includeUsers: selectedUMIds,
                    excludeUsers: [],
                    filter: {}
                },
                {
                    responseType: 'blob',
                    params: {
                        format: fileType === 'xls' ? 'excel' : fileType
                    },
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                });
            downloadFile(res.data, filename);
            dispatch({ type: EXPORT_UM, data: true });
        } catch (err) {
            dispatch({ type: EXPORT_UM, data: false });
        }
    };
}
import { Box, Button, Dialog, Popover, Stack } from '@mui/material';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import { useEffect, useRef, useState } from 'react';
import DrawerCloseButton from '@/componentsphase2/DrawerCloseButton';
import {
  getPromoteLeadGE,
  UpdateMarketInfo,
} from '@/actions/SIMT-SI/SpecialIssue/SiMarketing';
import { useDispatch } from 'react-redux';
import { permissionIsAdmin, permissionSiWrite } from '@/utils/authTokenHandler';
import MarketEdit from '@/assets/MarketEdit.svg';
import { CustomTooltip } from '@/pages/SIPage/SIDetailPage/market-section/components/components';
import MarketExport from '@/assets/MarketExport.svg';
import PromoteQuestionMark from '@/assets/PromoteQuestionMark.svg';
import { ButtonAlertBox } from '@/components/Alert/AlertBox';
import On from '@/assets/On.svg';
import Off from '@/assets/SIdetailOff.svg';
import CustomSwitch from '@/pages/SIPage/SIDetailPage/market-section/components/CustomSwitch';
import { exportCFPDataExcel } from '@/actions/SIMT-SI/SpecialIssue/Export';
import { useIntl } from 'react-intl';

const containerStyle = (justifyContent, width) => {
  return {
    display: 'flex',
    justifyContent: justifyContent,
    width: width,
  };
};

const IconButtonStyle = {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const alertMessage =
  'Since the eligible criteria are no longer met, this SI is not eligible for submission campaign anymore. The "Ready for Promotion" button is turned off.';

const HTML_LINK_PATTERN =
  /(https?:\/\/)?(([0-9a-z.]+\.[a-z]+)|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[0-9a-z%/.\-_]*)?(\?[0-9a-z=&%_\-]*)?(\#[0-9a-z=&%_\-]*)?/;
const ISSN_PATTERN = /[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}/;

const isEmpty = value => {
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    value === [] ||
    value === {} ||
    (typeof value === 'string' && !value.trim().length)
  );
};

const EditDialog = props => {
  const {
    editDialog,
    setEditDialog,
    setSaveLater,
    handleCancelClick,
    setIsEdit,
  } = props;
  return (
    <Dialog
      open={editDialog}
      sx={{
        '& .MuiDialog-paper': {
          boxShadow: 'none',
        },
        '& .MuiBackdrop-root': {
          background: ' rgba(17, 61,149, 0.5)',
          backdropFilter: 'blur(4px)',
        },
      }}
    >
      <Box
        sx={{
          width: '600px',
          height: '204px',
          background: '#FFFFFF',
          padding: '24px 32px 20px 32px',
        }}
      >
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing='18px'
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              width: '100%',
            }}
          >
            <Box
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '22.5px',
                letterSpacing: '0em',
                color: '#262E35',
              }}
            >
              Proceed to edit by deleting local draft?
            </Box>
            <DrawerCloseButton
              onClick={() => {
                setEditDialog(false);
              }}
            />
          </Stack>
          <Box
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
              letterSpacing: '0em',
              color: '#262E35',
              width: '100%',
            }}
          >
            {
              "You have a draft saved locally, In order to continue editing the content, you'll"
            }
            <br />
            {
              "need to delete the draft. This action can't be undone. Are you sure you want to"
            }
            <br />
            {'proceed?'}
          </Box>
          <Stack
            sx={{
              paddingTop: '6px',
              width: '100%',
            }}
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            spacing='9px'
          >
            <Button
              disableRipple
              sx={{
                width: '70px',
                height: '34px',
                padding: '8px 12px',
                background: '#FFFFFF',
                border: '1px solid #0052CC',
                color: '#0052CC',
                fontFamily: 'Open Sans',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '18px',
                letterSpacing: '0em',
                textTransform: 'none',
                '&:hover': {
                  color: '#6D98EE',
                  background: '#FFFFFF',
                },
                '&:active': {
                  color: '#6D98EE',
                  background: '#e8edfb',
                },
              }}
              onClick={() => {
                setEditDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              disableRipple
              sx={{
                width: '175px',
                height: '34px',
                padding: '8px 12px',
                background: '#0052CC',
                textTransform: 'none',
                fontFamily: 'Open Sans',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '17px',
                letterSpacing: '0em',
                color: '#FFFFFF',
                '&:hover': {
                  background: '#6d98ee',
                },
                '&:active': {
                  background: '#113d95',
                },
              }}
              onClick={() => {
                setEditDialog(false);
                setSaveLater(false);
                handleCancelClick();
                setIsEdit(true);
              }}
            >
              Delete draft and edit
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
};

const CommonPart = props => {
  const { isFold, setIsFold, setClickSave, setReadyForPromotionTurnOff } =
    props;
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'flex-start' }}
      data-selenium-id='SIDetail_MarketSection-CommonPartContainerBox'
    >
      <Box
        onClick={() => {
          setIsFold(!isFold);
          setClickSave(false);
          setReadyForPromotionTurnOff(false);
        }}
        sx={{
          marginRight: '8px',
          cursor: 'pointer',
          transform: isFold ? 'rotate(0deg)' : 'rotate(180deg)',
        }}
        id={isFold ? 'MarketFoldUp' : 'MarketFoldDown'}
        data-selenium-id={
          isFold
            ? 'SIDetail_MarketSection-CommonPart-FoldUp'
            : 'SIDetail_MarketSection-CommonPart-FoldDown'
        }
      >
        <MarketChevronUp
          data-selenium-id={
            isFold
              ? 'SIDetail_MarketSection-CommonPart-FoldUpMarketChevronUp'
              : 'SIDetail_MarketSection-CommonPart-FlodDownMarketChevronUp'
          }
        />
      </Box>

      <Box
        sx={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: ' 24px',
          display: 'flex',
          alignItems: 'center',
          color: '#113D95',
          marginRight: '21px',
        }}
        data-selenium-id='SIDetail_MarketSection-CommonPart-MarketingText'
      >
        Marketing
      </Box>
    </Box>
  );
};

export default function MarketTitle(props) {
  const {
    isEdit,
    setIsEdit,
    isFold,
    setIsFold,
    clickSave,
    setClickSave,
    readyForPromotion,
    setReadyForPromotion,
    readyForPromotionTurnOff,
    setReadyForPromotionTurnOff,
    saveLater,
    setSaveLater,
    handleCancelClick,
    detailInfo,
    firstOpenMarketSection,
    state,
    isEmptySIHtmlLink3,
  } = props;

  const dispatch = useDispatch();
  const intl = useIntl();

  const BoxRef = useRef(null);
  const buttonRefB = useRef(null);

  const [alertOpen, setAlertOpen] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    const handleClick = () => {
      setAnchorEl(null);
    };

    if (open) {
      window.addEventListener('click', handleClick);
    } else {
      window.removeEventListener('click', handleClick);
    }
  }, [open]);

  const buttons = [
    {
      text: 'View more',
      onClick: () => {
        setAlertOpen(false);
        if (BoxRef.current) {
          document.getElementById('outer').scrollTo({
            top: BoxRef.current.offsetTop - 350,
            behavior: 'smooth',
          });
        }
        setTimeout(() => {
          setAnchorEl(buttonRefB.current);
        }, 600);
      },
    },
    {
      text: 'Dismiss',
      onClick: () => setAlertOpen(false),
    },
  ];

  const CommissionMethodIsHybridOrOpenCall = ['Hybrid', 'Open Call'].includes(
    detailInfo.generalInformation[0].paperCommissionMethod
  );

  const handleExportClick = () => {
    async function fetchData() {
      await dispatch(
        exportCFPDataExcel(detailInfo.generalInformation[0].projectCode)
      );
    }
    fetchData();
  };

  const isEmptySIHtmlLink = () => {
    return (
      detailInfo.siMarketing !== undefined &&
      detailInfo.siMarketing.length > 0 &&
      (detailInfo.siMarketing[0].specialIssueHtmlLink === '' ||
        detailInfo.siMarketing[0].specialIssueHtmlLink === null) &&
      state !== 'read-only'
    );
  };

  const isEmptySIHtmlLink2 = () => {
    return (
      !detailInfo.siMarketing ||
      detailInfo.siMarketing.length === 0 ||
      !detailInfo.siMarketing[0].specialIssueHtmlLink ||
      !HTML_LINK_PATTERN.test(detailInfo.siMarketing[0].specialIssueHtmlLink)
    );
  };

  const isAllFilled = () => {
    return (
      !detailInfo.siMarketing ||
      detailInfo.siMarketing.length === 0 ||
      !detailInfo.siMarketing[0].journalSiPageLink ||
      !HTML_LINK_PATTERN.test(detailInfo.siMarketing[0].journalSiPageLink) ||
      !detailInfo.siMarketing[0].siAimsAndScope ||
      !detailInfo.siMarketing[0].siTopics ||
      detailInfo.siMarketing[0].siTopics.length < 3 ||
      detailInfo.siMarketing[0].siTopics.some(x => isEmpty(x)) ||
      !detailInfo.siMarketing[0].siKeywords ||
      detailInfo.siMarketing[0].siKeywords.length < 3 ||
      detailInfo.siMarketing[0].siKeywords.some(x => isEmpty(x)) ||
      !detailInfo.siMarketing[0].competitiveTitleIssns ||
      detailInfo.siMarketing[0].competitiveTitleIssns.length === 0 ||
      detailInfo.siMarketing[0].competitiveTitleIssns.some(
        x => !ISSN_PATTERN.test(x)
      ) ||
      !detailInfo.siMarketing[0].submissionInstructions
    );
  };

  const isStageRight = () => {
    return (
      detailInfo.generalInformation[0].stage === 'Paper Commission' ||
      detailInfo.generalInformation[0].stage === 'Submission and Review'
    );
  };

  const handlePromoteOffToOn = () => {
    if (
      detailInfo !== null &&
      detailInfo !== undefined &&
      detailInfo.generalInformation !== null &&
      detailInfo.generalInformation !== undefined
    ) {
      dispatch(getPromoteLeadGE(detailInfo.generalInformation[0].projectCode));
    }
  };

  const handlePromoteOnToOff = () => {
    dispatch(
      UpdateMarketInfo(
        { siCode: detailInfo.generalInformation[0].projectCode },
        false,
        'No'
      )
    );
  };

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    //避免初次加载页面时，当前SI的market-section的readyForPromotion为true时致使readyForRromotionTurnOff为true
    if (clickSave && !readyForPromotion) {
      setReadyForPromotionTurnOff(true);
    }
  }, [readyForPromotion]);

  useEffect(() => {
    if (
      readyForPromotionTurnOff &&
      clickSave &&
      !readyForPromotion &&
      (isEmptySIHtmlLink3() || !isStageRight())
    ) {
      handlePromoteOnToOff();
      setReadyForPromotion(false);
      setAlertOpen(true);
    } else {
      setAlertOpen(false);
    }
  }, [readyForPromotionTurnOff]);

  if (!isFold) {
    return (
      <Box
        sx={{ ...containerStyle('flex-start', '100%'), height: '24px' }}
        data-selenium-id='SIDetail_MarketSection-UnFoldBox'
      >
        <CommonPart
          isFold={isFold}
          setIsFold={setIsFold}
          setClickSave={setClickSave}
          setReadyForPromotionTurnOff={setReadyForPromotionTurnOff}
        />
      </Box>
    );
  }
  if (isEdit) {
    return (
      <Box
        sx={{ ...containerStyle('flex-start', '100%'), height: '24px' }}
        data-selenium-id='SIDetail_MarketSection-EditBox'
      >
        <CommonPart
          isFold={isFold}
          setIsFold={setIsFold}
          setClickSave={setClickSave}
          setReadyForPromotionTurnOff={setReadyForPromotionTurnOff}
        />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          ...containerStyle('flex-start', '100%'),
          height: '24px',
        }}
        data-selenium-id='SIDetail_MarketSection-NotEditBox'
      >
        <Box
          sx={containerStyle('flex-start', '40%')}
          data-selenium-id='SIDetail_MarketSection-ContainerBox1'
        >
          <CommonPart
            isFold={isFold}
            setIsFold={setIsFold}
            setClickSave={setClickSave}
            setReadyForPromotionTurnOff={setReadyForPromotionTurnOff}
          />

          {isFold &&
          state !== 'read-only' &&
          (!firstOpenMarketSection || !saveLater) ? (
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <EditDialog
                editDialog={editDialog}
                setEditDialog={setEditDialog}
                setSaveLater={setSaveLater}
                handleCancelClick={handleCancelClick}
                setIsEdit={setIsEdit}
              />
              {permissionIsAdmin() || permissionSiWrite() ? (
                <>
                  {detailInfo.generalInformation[0].stage !== 'Shelved' ? (
                    <Box
                      onClick={() => {
                        if (saveLater) {
                          setEditDialog(true);
                        } else {
                          setIsEdit(true);
                        }
                      }}
                      sx={{
                        marginRight: '16px',
                        ...IconButtonStyle,
                        '&:hover svg': { fill: '#0052CC' },
                      }}
                      id='MarketEdit'
                      data-selenium-id='SIDetail_MarketSection-MarketEditBox'
                    >
                      <MarketEdit data-selenium-id='SIDetail_MarketSection-MarketEdit' />
                    </Box>
                  ) : null}
                </>
              ) : null}
              {permissionIsAdmin() || permissionSiWrite() ? (
                detailInfo.generalInformation[0].paperCommissionMethod ===
                  'Open Call' ||
                detailInfo.generalInformation[0].paperCommissionMethod ===
                  'Hybrid' ? (
                  <Box
                    sx={IconButtonStyle}
                    id='MarketExport'
                    data-selenium-id='SIDetail_MarketSection-MarketExportBox'
                  >
                    <CustomTooltip
                      title={intl.messages['common.exportCFPData']}
                      arrow
                      placement='top'
                      data-selenium-id='SIDetail_MarketSection-MarketExportTooltip'
                    >
                      <MarketExport
                        onClick={() => handleExportClick()}
                        data-selenium-id='SIDetail_MarketSection-MarketExport'
                      />
                    </CustomTooltip>
                  </Box>
                ) : (
                  []
                )
              ) : null}
            </Box>
          ) : null}
        </Box>
        {isFold && (!firstOpenMarketSection || !saveLater) ? (
          <Box
            sx={containerStyle('flex-end', '60%')}
            data-selenium-id='SIDetail_MarketSection-ContainerBox2'
          >
            <Box ref={BoxRef}></Box>
            {isEmptySIHtmlLink() || state === 'read-only' || !isStageRight() ? (
              <Box
                ref={buttonRefB}
                sx={{
                  marginRight: '4px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup='true'
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                data-selenium-id='SIDetail_MarketSection-PromoteQuestionMarkBox'
              >
                <PromoteQuestionMark data-selenium-id='SIDetail_MarketSection-PromoteQuestionMark' />
              </Box>
            ) : null}
            <ButtonAlertBox
              open={alertOpen}
              handleClose={() => {
                setAlertOpen(false);
              }}
              title={'"Ready for Promotion" button is turned off'}
              message={alertMessage}
              buttons={buttons}
              severity='warning'
              autoHideDuration={40000}
            />
            <div onClick={handlePopoverClose}>
              <Popover
                id='mouse-over-popover'
                sx={{
                  pointerEvents: 'none',
                }}
                slotProps={{
                  paper: {
                    style: {
                      boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
                    },
                  },
                }}
                open={open}
                anchorEl={buttonRefB.current}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Box
                  data-selenuim-id={'SIDetail_MarketSection-Popover-Box'}
                  sx={{
                    background: '#FFFFFF',
                    border: 'solid #154AB6',
                    borderWidth: '0px 0px 0px 8px',
                    width: '394px',
                    height: '218px',
                    overflow: 'hidden',
                  }}
                >
                  <Stack
                    sx={{ padding: '16px' }}
                    direction='column'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                    spacing='4px'
                  >
                    <Box
                      sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '18px',
                        letterSpacing: '0em',
                        color: '#596A7C',
                        marginBottom: '8px',
                      }}
                    >
                      Please note that all criteria below need to be met before
                      you can turn on "Ready for Promotion" button:
                    </Box>
                    <Stack
                      direction='row'
                      justifyContent='flex-start'
                      alignItems='flex-start'
                      spacing='6px'
                    >
                      <div>{isStageRight() ? <On /> : <Off />}</div>
                      <Box
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '18px',
                          letterSpacing: '0em',
                          color: '#262E35',
                        }}
                      >
                        {
                          'SI stage is at "Paper Commission" or "Submission and Review" stage.'
                        }
                      </Box>
                    </Stack>
                    <Stack
                      direction='row'
                      justifyContent='flex-start'
                      alignItems='flex-start'
                      spacing='6px'
                    >
                      <div>
                        {CommissionMethodIsHybridOrOpenCall ? <On /> : <Off />}
                      </div>
                      <Box
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '18px',
                          letterSpacing: '0em',
                          color: '#262E35',
                        }}
                      >
                        Paper Commissioning Method is "Hybrid" or "Open Call".
                      </Box>
                    </Stack>
                    <Stack
                      direction='row'
                      justifyContent='flex-start'
                      alignItems='flex-start'
                      spacing='6px'
                    >
                      <div>
                        <On />
                      </div>
                      <Box
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '18px',
                          letterSpacing: '0em',
                          color: '#262E35',
                        }}
                      >
                        All mandatory fields in "Marketing" section are filled
                        in.
                      </Box>
                    </Stack>
                    <Stack
                      direction='row'
                      justifyContent='flex-start'
                      alignItems='flex-start'
                      spacing='6px'
                    >
                      <div>{!isEmptySIHtmlLink() ? <On /> : <Off />}</div>
                      <Box
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '18px',
                          letterSpacing: '0em',
                          color: '#262E35',
                        }}
                      >
                        "Special Issue HTML Link" is not empty.
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              </Popover>
            </div>
            <Box
              sx={{
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#596A7C',
                marginRight: '8px',
                display: 'flex',
                alignItems: 'center',
              }}
              data-selenium-id='SIDetail_MarketSection-ReadyForPromotionBox'
            >
              Ready for Promotion
            </Box>
            <Box
              sx={{
                '& > span': {
                  background: 'transparent !important',
                },
              }}
              data-selenium-id='SIDetail_MarketSection-SwitchContainerBox'
            >
              <CustomSwitch
                id='MarketPromote'
                data-selenium-id='SIDetail_MarketSection-MarketPromote'
                checked={
                  readyForPromotion && !isEmptySIHtmlLink() && isStageRight()
                }
                sx={{
                  background: '#DFE4E8',
                  borderRadius: '16px',
                  '& .MuiSwitch-track': {
                    '&:after': {
                      content: readyForPromotion ? '""' : '"Off"',
                      right: 4,
                      top: '12px',
                      color: '#596A7C',
                    },
                  },
                  opacity:
                    isEmptySIHtmlLink() ||
                    state === 'read-only' ||
                    !isStageRight()
                      ? 0.5
                      : 1,
                }}
                disabled={
                  isEmptySIHtmlLink2() ||
                  isAllFilled() ||
                  state === 'read-only' ||
                  !isStageRight()
                }
                onChange={e => {
                  e.target.checked = readyForPromotion;
                  if (readyForPromotion) {
                    handlePromoteOnToOff();
                    setReadyForPromotionTurnOff(false);
                    setClickSave(false);
                  } else {
                    handlePromoteOffToOn();
                    setReadyForPromotionTurnOff(false);
                    setClickSave(false);
                  }
                }}
              />
            </Box>
          </Box>
        ) : null}
      </Box>
    );
  }
}

import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from '@mui/material/styles/styled';
import { useFormControl } from '@mui/material/FormControl';
import Marketerror from '../../../../../assets/Marketerror.svg';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import CanselListItem from '../../../../../assets/CanselListItem.svg';
import {
  defaultVars,
  errorVars,
  getDescriptionsStyle,
  helperTextStyle,
} from '../styles';
import ListDot from '../../../../../assets/ListDot.svg';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CustomDiv from './CustomDiv';
import MyOnlyText from '@/componentsphase2/MyOnlyText';

export function MarketInfoBlock(props) {
  const { title, contentBlowTitle = <></>, require, btnSx, isEdit } = props;

  const titleStyle = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#596A7C',
  };

  const asteriskStyle = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
    color: '#C40000',
  };
  return (
    <Stack
      container
      direction='row'
      sx={{
        marginBottom: '12px',
        width:'100%'
        // width: '1092px'
      }}
      data-selenium-id='SIDetail_MarketSection-MarketInfoBlock-ContainerStack'
    >
      <Box
        sx={{
          width: '232px',
        }}
        data-selenium-id='SIDetail_MarketSection-MarketInfoBlock-ContainerGridItem'
      >
        <Stack
          data-selenium-id='SIDetail_MarketSection-MarketInfoBlock-ContainerStack'
        >
          <Box
            sx={{
              height: 'auto',
              wordBreak: 'break-word',
              display: 'flex',
              alignItems: 'center',
              paddingTop: isEdit ? '6.5px' : null,
            }}
            data-selenium-id='SIDetail_MarketSection-MarketInfoBlock-ContainerBox'
          >
            <span
              style={titleStyle}
              data-selenium-id='SIDetail_MarketSection-MarketInfoBlock-TitleText'
            >
              {title}&nbsp;&nbsp;
            </span>

            {require ? (
              <span
                style={asteriskStyle}
                data-selenium-id='SIDetail_MarketSection-MarketInfoBlock-RequireText'
              >
                *
              </span>
            ) : (
              null
            )}
          </Box>
          <Box data-selenium-id='SIDetail_MarketSection-MarketInfoBlock-ContentBlowTitleText'>
            {contentBlowTitle}
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{ width: 'calc(100% - 232px)' }}
        data-selenium-id='SIDetail_MarketSection-MarketInfoBlock-PropsChildrenGrid'
      >
        {props.children}
      </Box>
    </Stack>
  );
}

export function CustomFormHelperText(props) {
  const {
    propsError,
    propsHelperText,
    needCustomCheck,
    customCheckError,
    customCheckText,
  } = props;
  const { error } = useFormControl() || {};
  const helperText = React.useMemo(() => {
    if (error) {
      let helperText = '';
      if (propsError) {
        helperText = propsHelperText;
      } else if (needCustomCheck && customCheckError) {
        helperText = customCheckText;
      } else {
        helperText = 'This field is required.';
      }
      if (helperText === '') {
        return '';
      } else {
        return (
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-start' }}
            data-selenium-id='SIDetail_MarketSection-CustomFormHelperTextContainerBox'
          >
            <Box data-selenium-id='SIDetail_MarketSection-CustomFormHelperText-MarketErrContainerBox'>
              <Marketerror data-selenium-id='SIDetail_MarketSection-CsutomFormHelperText-MarketErr'></Marketerror>
            </Box>
            <Box
              sx={helperTextStyle}
              data-selenium-id='SIDetail_MarketSection-CustomFormHelperTextBox'
            >
              {helperText}
            </Box>
          </Box>
        );
      }
    }
    return '';
  }, [error, propsError, customCheckError]);

  return (
    <FormHelperText data-selenium-id='SIDetail_MarketSection-PlainFormHelperText'>
      {helperText}
    </FormHelperText>
  );
}

export const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    borderRadius: '4px',
    boxSizing: 'border-box',
    width: '100%',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    padding: '8px 12px',
    color: '#262E35',
  },
  '& .MuiOutlinedInput-input': {
    padding: '0px 0px',
    '&::placeholder': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#BCC5CF',
      opacity: '1',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #98A7B6',
  },
  '& :hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #262E35',
    },
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #0052CC',
    },
  },
  '& .ForwardRef(TextField)-root-13 .MuiOutlinedInput-input::placeholder': {
    color: 'blue',
  },
}));

export function CustomInputAreaFormHelperText(props) {
  const { propsError, propsHelperText, exceedErr } = props;
  const { error } = useFormControl() || {};
  const helperText = React.useMemo(() => {
    if (error) {
      let helperText = '';
      if (propsError) {
        helperText = propsHelperText;
      } else if (exceedErr) {
        helperText = 'Please input no more than 100,000 characters.';
      } else {
        helperText = 'This field is required.';
      }
      if (helperText === '') {
        return '';
      } else {
        return (
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-start' }}
            data-selenium-id='SIDetail_MarketSection-CustomInputAreaFormHelperText-ContainerBox'
          >
            <Box data-selenium-id='SIDetail_MarketSection-CustomInputAreaFormHelperText-MarketErrContainerBox'>
              <Marketerror></Marketerror>
            </Box>
            <Box
              sx={helperTextStyle}
              data-selenium-id='SIDetail_MarketSection-CustomInputAreaFormHelperText'
            >
              {helperText}
            </Box>
          </Box>
        );
      }
    }
    return '';
  }, [error, propsError, exceedErr]);

  return (
    <FormHelperText data-selenium-id='SIDetail_MarketSection-PlainInputFromHelperText'>
      {helperText}
    </FormHelperText>
  );
}

export function CustomInputAreaFooter(props) {
  const { currentLength, maxLength, exceedError } = props;
  const [color, setColor] = useState('#596A7C');
  const style = () => {
    return {
      width: '100%',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '20px',
      textAlign: 'right',
      color: color,
    };
  };
  useEffect(() => {
    exceedError ? setColor('#DE350B') : setColor('#596A7C');
  }, [exceedError]);
  return (
    <Box
      sx={style()}
      data-selenium-id='SIDetail_MarketSection-CustomInputAreaFooterBox'
    >
      {currentLength}/{addCommasToNumber(maxLength)}
    </Box>
  );
}
function addCommasToNumber(number) {
  // 将数字转换为字符串
  const numberStr = number.toString();
  // 使用正则表达式将逗号插入每三位
  const numberWithCommas = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return numberWithCommas;
}

export function CustomInputArea(props) {
  const {
    id,
    placeholder,
    emptyError,
    limitLength,
    value,
    setValue,
    setExceedError,
    minRows,
    maxRows,
    sx,
  } = props;

  const [vars, setVars] = useState(defaultVars);
  const [exceedErr, setExceedErr] = useState(false);
  const handleChange = e => {
    if (e.target.value.length > limitLength) {
      setExceedErr(true);
      setExceedError(true);
    } else {
      setExceedErr(false);
      setExceedError(false);
    }
    setValue(e.target.value);
  };

  useEffect(() => {
    setVars(emptyError || props.error || exceedErr ? errorVars : defaultVars);
  }, [emptyError, props.error, exceedErr]);

  const isEmpty = value => {
    return (
      value === undefined ||
      value === null ||
      value === '' ||
      value === [] ||
      value === {} ||
      (typeof value === 'string' && !value.trim().length)
    );
  };

  return (
    <FormControl
      variant='standard'
      error={emptyError || props.error || exceedErr}
      sx={{ width: '100%', borderRadius: '0px' }}
      data-selenium-id='SIDetail_MarketSection-CustomInputArea-FormControl'
    >
      <MyOnlyText
      id={id}
      data-selenium-id={id}
      isError={emptyError || props.error || exceedErr}
      value={value}
        multiline
        maxRows={maxRows}
        minRows={minRows}
        placeholder={
          placeholder !== null && placeholder !== undefined ? placeholder : ''
        }
        setValue={setValue}
        width={'860px'}
      sx={sx}
      />
      {/* <TextField
        id={id}
        data-selenium-id={id}
        error={emptyError || props.error || exceedErr}
        sx={{
          '& .MuiOutlinedInput-root': {
            background: '#FFFFFF',
            borderRadius: '4px',
            boxSizing: 'border-box',
            width: '100%',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '17.5px',
            padding: '8px 12px',
            color: '#262E35',
          },
          '& .MuiOutlinedInput-input': {
            padding: '0px 0px',
            '&::placeholder': {
              fontFamily: 'Open Sans',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '17.5px',
              color: '#BCC5CF',
              opacity: '1',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: isEmpty(value) ? '1px solid #DFE4E8' : '1px solid #98A7B6',
          },
          '& :hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #262E35 !important',
            },
          },
          '& .Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: isEmpty(value)
                ? '2px solid #154AB6!important'
                : '2px solid #0052CC !important',
            },
          },
          '& .ForwardRef(TextField)-root-13 .MuiOutlinedInput-input::placeholder':
          {
            color: 'blue',
          },
        }}
        value={value}
        multiline
        maxRows={maxRows}
        minRows={minRows}
        placeholder={
          placeholder !== null && placeholder !== undefined ? placeholder : ''
        }
        onChange={handleChange}
      /> */}
      <CustomInputAreaFooter
        currentLength={value === null || value === undefined ? 0 : value.length}
        maxLength={limitLength}
        exceedError={exceedErr}
        data-selenium-id='SIDetail_MarketSection-CsutomInputAreaFooter'
      />
      <CustomInputAreaFormHelperText
        propsError={props.error}
        propsHelperText={props.helperText}
        exceedErr={exceedErr}
        data-selenium-id='SIDetail_MarketSection-CustomInputAreaFormHelperText'
      />
    </FormControl>
  );
}

export function CustomLink(props) {
  const { href,children, state, sx } = props;
  return (
    <Link
      href={href.indexOf('http') !== -1 ? href : 'https://' + href}
      sx={{
        ...getDescriptionsStyle(state),
        color: '#154AB6',
        fontWeight: '600',
        '&:hover': {
          color: '#113D95',
        },
        ...sx,
      }}
      target='_blank'
      rel='noreferrer'
      data-selenium-id='SIDetail_MarketSection-CustomCustomLink'
      underline='none'
    >
      {children ?? href}
    </Link>
  );
}

export function CustomListBlock(props) {
  const { state, id, maxHeight, last, setMoreOrLess, moreOrLess } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        // width: '860px',  //modified
      }}
      data-selenium-id='SIDetail_MarketSection-CustomListBlock-ContainerBox'
    >
      <Box
        sx={{ marginRight: '9px', width: '12px' }}
        data-selenium-id='SIDetail_MarketSection-CustomListBlock-ListDotContainerBox'
      >
        <ListDot></ListDot>
      </Box>
      <Box
        sx={{ width: 'calc(100% - 21px)' }}
        data-selenium-id='SIDetail_MarketSection-CustomListBlock-CustomDivContainerBox'
      >
        {last ? (
          <CustomDiv
            value={props.children}
            state={state}
            id={id}
            data-selenium-id={id}
            maxHeight={maxHeight}
            handleMore={() => setMoreOrLess('less')}
            handleLess={() => setMoreOrLess('more')}
            ListMoreOrLess={moreOrLess}
          ></CustomDiv>
        ) : (
          <CustomDiv
            value={props.children}
            state={state}
            id={id}
            data-selenium-id={id}
            maxHeight={maxHeight}
          ></CustomDiv>
        )}
      </Box>
    </Box>
  );
}

export function CustomList(props) {
  const { id, value, needMore, maxLength, maxHeight, state, sx } = props;
  const [moreOrLess, setMoreOrLess] = useState('none');
  useEffect(() => {
    if (value.length > maxLength) {
      setMoreOrLess('more');
    }
  }, [value]);
  const getValueList = () => {
    if (needMore) {
      if (value.length <= maxLength) {
        return value;
      } else {
        if (moreOrLess === 'more') {
          return [value[0], value[1], value[2]];
        } else {
          return value;
        }
      }
    } else {
      return value;
    }
  };
  return (
    <Box sx={sx}>
      {getValueList().map((item, index) => (
        <CustomListBlock
          state={state}
          id={`${id}_${index}`}
          data-selenium-id={`${id}-${index}`}
          maxHeight={maxHeight}
          last={index === getValueList().length - 1}
          setMoreOrLess={setMoreOrLess}
          moreOrLess={moreOrLess}
        >
          {item}
        </CustomListBlock>
      ))}
    </Box>
  );
}

export function OneCustomListBlock(props) {
  const { state, id, maxHeight, last, setMoreOrLess, moreOrLess } = props;
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'flex-start' }}
      data-selenium-id='SIDetail_MarketSection-OneCustomListBlock-ContainerBox'
    >
      <Box
        sx={{ width: 'calc(100% - 21px)' }}
        data-selenium-id='SIDetail_MarketSection-OneCustomListBlock-CustomDivContainerBox'
      >
        {last ? (
          <CustomDiv
            value={props.children}
            state={state}
            id={id}
            data-selenium-id={id}
            maxHeight={maxHeight}
            handleMore={() => setMoreOrLess('less')}
            handleLess={() => setMoreOrLess('more')}
            ListMoreOrLess={moreOrLess}
          ></CustomDiv>
        ) : (
          <CustomDiv
            value={props.children}
            state={state}
            id={id}
            data-selenium-id={id}
            maxHeight={maxHeight}
          ></CustomDiv>
        )}
      </Box>
    </Box>
  );
}

export function OneCustomList(props) {
  const { id, value, needMore, maxLength, maxHeight, state, sx } = props;
  const [moreOrLess, setMoreOrLess] = useState('none');
  useEffect(() => {
    if (value.length > maxLength) {
      setMoreOrLess('more');
    }
  }, [value]);
  const getValueList = () => {
    if (needMore) {
      if (value.length <= maxLength) {
        return value;
      } else {
        if (moreOrLess === 'more') {
          return [value[0], value[1], value[2]];
        } else {
          return value;
        }
      }
    } else {
      return value;
    }
  };
  return (
    <Box sx={sx}>
      {getValueList().map((item, index) => (
        <OneCustomListBlock
          state={state}
          id={`${id}_${index}`}
          data-selenium-id={`${id}-${index}`}
          maxHeight={maxHeight}
          last={index === getValueList().length - 1}
          setMoreOrLess={setMoreOrLess}
          moreOrLess={moreOrLess}
        >
          {item}
        </OneCustomListBlock>
      ))}
    </Box>
  );
}

export function MarketPromoteBlock(props) {
  const { title, special } = props;
  const fontStyle = color => {
    return {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: color,
    };
  };
  return (
    <Box
      sx={{
        width: '100%',
        marginBottom: '8px',
        display: 'flex',
        justifyContent: 'flex-start',
      }}
      data-selenium-id='SIDetail_MarketSection-MarketPromoteBlock-Box'
    >
      <div
        style={{
          width: '160px',
          marginRight: '12px',
          ...fontStyle('#596A7C'),
        }}
        data-selenium-id='SIDetail_MarketSection-MarketPromoteBlock-TitleText'
      >
        <div>{title}</div>
        {special && (
          <div
            style={{
              color: '#98A7B6',
              fontSize: '12px',
            }}
          >
            (Name,Institution,Country)
          </div>
        )}
      </div>
      <div
        style={{ ...fontStyle('#262E35'), width: 'calc(100% - 180px)' }}
        data-selenium-id='SIDetail_MarketSection-MarketPromoteBlock-PropsChildrenText'
      >
        {props.children}
      </div>
    </Box>
  );
}

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#596A7C',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#596A7C',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    padding: '8px 16px',
    borderRadius: '5px',
    maxWidth: '385px',
    height: '36px',
    boxSizing: 'border-box',
  },
}));

import { Box, CircularProgress, List } from '@mui/material';
import JournalFilterListItem from './JournalFilterListItem';
import React from 'react';
import PropTypes from 'prop-types';
import { getValueStringByObjectKey } from './JournalFilterConstantListDisplay';

// 辅助用：获得"已选择"部分的显示内容
/**
 *
 * @param {import("./JournalFilterDisplayContent").SelectedDisplayContentProps} props
 * @returns
 */
export function SelectedDisplayContent(props) {
  const {
    name,
    handleClick,
    checkedList,
    determineIsSelected,
    displayFunction,
  } = props;
  if (checkedList?.length > 0) {
    return (
      <List
        id={`${name}SelectedList`}
        data-selenium-id={`${name}SelectedList`}
        sx={{ padding: 0 }}
      >
        {checkedList.map(item => {
          const currIsSelected = determineIsSelected(item);
          return (
            <JournalFilterListItem
              key={name + getValueStringByObjectKey('code', item)}
              id={
                name +
                getValueStringByObjectKey('code', item) +
                'SelectedListItem-' +
                currIsSelected
              }
              data-selenium-id={
                name +
                getValueStringByObjectKey('code', item) +
                'SelectedListItem-' +
                currIsSelected
              }
              content={displayFunction(item)}
              handleClick={() => {
                handleClick(item);
              }}
              checked={currIsSelected}
            />
          );
        })}
      </List>
    );
  } else {
    return <></>;
  }
}

SelectedDisplayContent.propTypes = {
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  checkedList: PropTypes.array.isRequired,
  determineIsSelected: PropTypes.func.isRequired,
  displayFunction: PropTypes.func.isRequired,
};

// 辅助用：获得搜索结果的展示组件
/** @type {import("./JournalFilterDisplayContent").getSearchResultDisplayContentType} */
export function getSearchResultDisplayContent(
  name,
  data,
  isLoading,
  handleClick,
  checkedList,
  determineIsSelected,
  displayFunction,
  selectObjectKey
) {
  if (isLoading) {
    return (
      <Box
        display='flex'
        alignItems='center'
        p='2px 0px 2px 3px'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-filterComponents-DisplayContent-IsLoadingBox'
      >
        <CircularProgress size={12} style={{ marginLeft: '2px' }} />
        <span style={{ marginLeft: '12px' }}>Now loading</span>
      </Box>
    );
  } else if (data == null) {
    return (
      <Box data-selenium-id='CATJournal_Overview-Table-SearchRow-filterComponents-DisplayContent-NullDataBox'>
        Load failed or server returns empty data
      </Box>
    );
  } else if (data.length === 0) {
    return (
      <Box
        width='100%'
        data-selenium-id='CATJournal_Overview-Table-SearchRow-filterComponents-DisplayContent-NoResultBox'
      >
        No result
      </Box>
    );
  } else {
    
    return (
      <List sx={{ padding: 0 }}>
        {data
          .filter(item => {
            const selectedContent =
              selectObjectKey === 'self' ? item : item[selectObjectKey];
            return checkedList.indexOf(selectedContent) === -1;
          })
          .map((item, index) => {
            const currIsSelected = determineIsSelected(item);
            return (
              <JournalFilterListItem
                key={name + getValueStringByObjectKey('code', item)}
                id={name + getValueStringByObjectKey('code', item)}
                data-selenium-id={
                  name + getValueStringByObjectKey('code', item)
                }
                content={displayFunction(item)}
                handleClick={() => {
                  
                  handleClick(item);
                }}
                checked={currIsSelected} // 这里需要动态更新，但是列表不需要
              />
            );
          })}
      </List>
    );
  }
}

import styles from "../../index.module.scss";
import {SIPFormState} from "@/reducers/SIPFormReducer";
import {useDispatch, useSelector} from "react-redux";
import {
    addSIPFormGE,
    delSIPFormGE,
    setSIPFormGEListValue,
    setSIPFormSectionState,
    setSIPFormValue
} from "@/actions/SIMT-SIP/SIPForm";
import Card from "@/pages/SIPForm/components/Card";
import {SelectableInputBox} from "@/pages/SIPForm/components/SelectableInputBox";
import {FormGroup, FormGroupButton} from "@/pages/SIPForm/components/FormGroup";
import {validateEmail, validateNotEmpty, validateOrcidID, validateURL} from "@/pages/SIPForm/utils/validate_utils";
import {InputBox} from "@/pages/SIPForm/components/InputBox";
import {RadioGroup} from "@/pages/SIPForm/components/RadioGroup";
import {useEffect} from "react";
import {SectionState} from "@/pages/SIPForm/types.d";
import {Box, Button, Typography} from "@mui/material";

import DeleteIcon from '../../assets/input-list-delete-icon-24_24.svg';
import {useIntl, FormattedMessage} from "react-intl";

export interface GuestEditorInfoSectionProps {

}

export function GuestEditorInfoSection(props: GuestEditorInfoSectionProps) {
    const selector = (state: {
        SIPForm: SIPFormState;
        [key: string]: any;
    }) => state.SIPForm;

    const {
        formData,
        options: allOptions,
        state: formStatus,
    } = useSelector(selector);

    const {
        guestEditorInformation,
    } = formData;

    const dispatch = useDispatch();
    const intl = useIntl();

    const proceeding = formStatus === 'proceeding';

    const sectionData = guestEditorInformation.data;
    const section = 'guestEditorInformation';

    const hasItemError = sectionData.GEList.some(GE => {
        const errorOfCountry = GE.country === null;
        const errorOfEmail = !validateEmail(GE.email);
        const errorOfFirstName = !validateNotEmpty(GE.firstName);
        const errorOfInstitution = !validateNotEmpty(GE.institution);
        const errorOfJobTitle = !validateNotEmpty(GE.jobTitle);
        const errorOfLastName = !validateNotEmpty(GE.lastName);
        const errorOfOrcidID = GE.orcidID.length !==0 && !validateOrcidID(GE.orcidID);
        const errorOfWebsite = !validateURL(GE.website);
        const errorOfLead = GE.isLead === null;
        return errorOfCountry || errorOfEmail || errorOfFirstName || errorOfInstitution || errorOfJobTitle || errorOfLastName || errorOfOrcidID || errorOfWebsite || errorOfLead;
    });

    const leadGECheck = sectionData.GEList.filter(GE => GE.isLead === 'Yes').length === 0;
    const emails = sectionData.GEList.map(GE => GE.email).filter(email => validateEmail(email));
    const duplicateEmailCheck = emails.some((email, index) => emails.indexOf(email) !== index);

    const hasError = hasItemError || leadGECheck || duplicateEmailCheck;

    useEffect(() => {
        dispatch(setSIPFormSectionState(section, hasError ? (proceeding ? SectionState.error: SectionState.default) : SectionState.completed));
    }, [hasError, proceeding]);

    const handleAddGE = () => {
        dispatch(addSIPFormGE());
    };

    const deleteGE = (index: number) => {
        dispatch(delSIPFormGE(index));
    };

    return (
        <Card section={guestEditorInformation}>
            {sectionData.GEList.map((GE, index) => {
                const setValue = (key: string, value: any) => dispatch(setSIPFormGEListValue(
                    section,
                    index,
                    key,
                    value
                ));
                const valueSetter = (key: string) => (value: any) => setValue(key, value);

                const setCountry = valueSetter('country');
                const setEmail = valueSetter('email');
                const setFirstName = valueSetter('firstName');
                const setInstitution = valueSetter('institution');
                const setIsLead = valueSetter('isLead');
                const setJobTitle = valueSetter('jobTitle');
                const setLastName = valueSetter('lastName');
                const setOrcidID = valueSetter('orcidID');
                const setWebsite = valueSetter('website');

                const errorOfCountry = GE.country === null;
                const errorOfEmail = !validateEmail(GE.email);
                const errorOfFirstName = !validateNotEmpty(GE.firstName);
                const errorOfInstitution = !validateNotEmpty(GE.institution);
                const errorOfJobTitle = !validateNotEmpty(GE.jobTitle);
                const errorOfLastName = !validateNotEmpty(GE.lastName);
                const errorOfOrcidID = GE.orcidID.length !==0 && !validateOrcidID(GE.orcidID);
                const errorOfWebsite = !validateURL(GE.website);
                const errorOfLead = GE.isLead === null;

                const emailDuplicated = emails.filter(email => email === GE.email).length > 1;

                return (
                   <>
                       {sectionData.GEList.length > 1 &&
                           <Box key={`delete-group-${index}`} className={styles['delete-group-box']}>
                               <Button
                                   className={styles['delete-button']}
                                   onClick={() => deleteGE(index)}>
                                    <Typography><FormattedMessage id={"sipForm.label.geDeleteButton"} /></Typography>
                                    <DeleteIcon/>
                               </Button>
                           </Box>
                       }
                       <FormGroup
                           key={`title-${index}`}
                           title="Title"
                           isRequired={true}
                           error={proceeding && errorOfJobTitle}
                           errorMessage={intl.messages['sipForm.errorText.required'] as string}
                       >
                           <SelectableInputBox
                               options={allOptions.jobTitle ?? []}
                               value={GE.jobTitle}
                               getOptionLabel={ option => option === null ? "" : option }
                               filterOptions={ options => options }
                               onChange={ setJobTitle }
                               onClear={ () => setJobTitle(null) }
                               error={proceeding && errorOfJobTitle}
                               placeholder={intl.messages['sipForm.placeholder.selectOnly'] as string}
                               noOptionsText={intl.messages['sipForm.noOptions'] as string}
                               readonly={true}
                           />
                       </FormGroup>

                       <FormGroup
                            key={`first-name-${index}`}
                            title="First Name"
                            isRequired={true}
                            error={proceeding && errorOfFirstName}
                            errorMessage={intl.messages['sipForm.errorText.required'] as string}
                       >
                           <InputBox
                               value={GE.firstName}
                               onChange={ (value) => setFirstName(value) }
                               error={proceeding && errorOfFirstName}
                               placeholder={intl.messages['sipForm.placeholder.typeOnly'] as string}
                           />
                       </FormGroup>

                       <FormGroup
                            key={`last-name-${index}`}
                            title="Last Name"
                            isRequired={true}
                            error={proceeding && errorOfLastName}
                            errorMessage={intl.messages['sipForm.errorText.required'] as string}
                       >
                            <InputBox
                                 value={GE.lastName}
                                 onChange={ (value) => setLastName(value) }
                                 error={proceeding && errorOfLastName}
                                 placeholder={intl.messages['sipForm.placeholder.typeOnly'] as string}
                            />
                       </FormGroup>

                       <FormGroup
                           key={`lead-ge-${index}`}
                           title={"Lead Guest Editor"}
                           isRequired={true}
                           error={proceeding && (errorOfLead || leadGECheck)}
                           errorMessage={intl.messages[
                               errorOfLead ? 'sipForm.errorText.required' : 'sipForm.errorText.leadGeNumberErr'
                               ] as string}
                           tooltip={intl.messages['sipForm.tooltip.leadGuestEditor'] as string}
                       >
                           <RadioGroup
                               value={GE.isLead}
                               options={['Yes', 'No']}
                               onChange={setIsLead}
                               error={proceeding && errorOfLead}
                               row={true}
                            />
                       </FormGroup>

                       <FormGroup
                            key={`email-${index}`}
                            title="Institutional Email Address"
                            isRequired={true}
                            error={proceeding && (errorOfEmail || emailDuplicated)}
                            errorMessage={intl.messages[
                                errorOfEmail ? 'sipForm.errorText.emailInvalid' : 'sipForm.errorText.geEmailDuplicate'
                                ] as string}
                            tooltip={intl.messages['sipForm.tooltip.institutionalEmail'] as string}
                       >
                            <InputBox
                                 value={GE.email}
                                 onChange={ (value) => setEmail(value) }
                                 error={proceeding && errorOfEmail}
                                 placeholder={intl.messages['sipForm.placeholder.typeOnly'] as string}
                            />
                       </FormGroup>

                       <FormGroup
                            key={`institution-${index}`}
                            title="Institution"
                            isRequired={true}
                            error={proceeding && errorOfInstitution}
                            errorMessage={intl.messages['sipForm.errorText.required'] as string}
                          >
                            <InputBox
                                value={GE.institution}
                                onChange={ (value) => setInstitution(value) }
                                error={proceeding && errorOfInstitution}
                                placeholder={intl.messages['sipForm.placeholder.typeOnly'] as string}
                            />
                       </FormGroup>

                       <FormGroup
                            key={`country-${index}`}
                            title="Country"
                            isRequired={true}
                            error={proceeding && errorOfCountry}
                            errorMessage={intl.messages['sipForm.errorText.required'] as string}
                          >
                           <SelectableInputBox
                               options={allOptions.country ?? []}
                               value={GE.country}
                               getOptionLabel={ option => option === null ? "" : option.name }
                               filterOptions={ (options, state) =>
                                   options.filter(option =>
                                       option.name.toLowerCase()
                                           .includes(state.inputValue.toLowerCase())) }
                               onChange={ setCountry }
                               onClear={ () => setCountry(null) }
                               error={proceeding && errorOfCountry}
                               placeholder={intl.messages['sipForm.placeholder.typeOrSelect'] as string}
                               noOptionsText={intl.messages['sipForm.noOptions'] as string}
                           />
                       </FormGroup>

                       <FormGroup
                            key={`orcid-id-${index}`}
                            title="ORCID ID"
                            isRequired={false}
                            error={proceeding && errorOfOrcidID}
                            errorMessage={intl.messages['sipForm.errorText.orcidInvalid'] as string}
                          >
                            <InputBox
                                value={GE.orcidID}
                                onChange={ (value) => setOrcidID(value) }
                                error={proceeding && errorOfOrcidID}
                                placeholder={intl.messages['sipForm.placeholder.orcidID'] as string}
                            />
                       </FormGroup>

                       <FormGroup
                            key={`website-${index}`}
                            title="Publication Record Link"
                            isRequired={true}
                            error={proceeding && errorOfWebsite}
                            errorMessage={intl.messages['sipForm.errorText.linkInvalid'] as string}
                            tooltip={intl.messages['sipForm.tooltip.publicationRecordLink'] as string}
                          >
                            <InputBox
                                value={GE.website}
                                onChange={ (value) => setWebsite(value) }
                                error={proceeding && errorOfWebsite}
                                placeholder={intl.messages['sipForm.placeholder.typeOnly'] as string}
                            />
                       </FormGroup>
                   </>
                );
            })}

            <FormGroupButton
                title={intl.messages['sipForm.label.geAddButton'] as string}
                onClick={handleAddGE}
            />
        </Card>
    );
}
const responseErrorCodeToMessageId = {
  14094: 'siDetail.journalLinkErr',
  14095: 'siDetail.siHTMLLinkErr',

  // annual target
  13005: 'annualTarget.somethingWrong',
  13008: 'annualTarget.incorrectTemplateJournal',
  13009: 'annualTarget.incorrectTemplateSubject',
  13010: 'annualTarget.incorrectTemplateBusiness',
  13011: 'annualTarget.incorrectTemplateCat',
  13012: 'annualTarget.wrongTemplate',

  //sip overview table
  16004: 'sipPage.orderTypeErr',
  16005: 'sipPage.queryFailedErr',

  // si reactivate
  14045: 'siDetail.reactivate.notFound',
  14115: 'siDetail.reactivate.hasBeenReactivatedOnce',
  14116: 'siDetail.reactivate.notShelvedSI',
  10480: 'siDetail.reactivate.reactivateReasonEmpty',
  10479: 'siDetail.reactivate.reactivateReasonTooLong',

  //sip detail edit
  16009: 'sipDetail.edit.objectEditError',
  16001: 'sipDetail.edit.uploadCv.error1',
  16013: 'sipDetail.edit.uploadCv.error2',
  16018: 'sipDetail.edit.deleteCvErr',
  16010: 'sipDetail.edit.upDateGEErr',
  16011: 'sipDetail.edit.geExceedErr',
  16012: 'sipDetail.edit.leadGEExceedErr',
  16028: 'sipDetail.edit.deleteGEErr',
  14036: 'sipDetail.edit.deleteGENumberErr',
  16036: 'sipDetail.edit.leadGeNumberErr',
  14048: 'sipDetail.edit.sipGeEmailConflictErr',

  14125: 'Batch value is mandatory to input!',
  14124: 'Batch type is mandatory to input!',
};

const responseErrorMessageToPopupMessageId = {
  'invalid stage': 'siPage.invalidStage',
  'invalid date': 'siPage.invalidDate',
};

// 整合二者
const responseToMessageIdConfig = {
  ...responseErrorCodeToMessageId,
  ...responseErrorMessageToPopupMessageId,
};

export default responseToMessageIdConfig;

import { useState, useEffect, useMemo, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Box } from '@mui/material';
import styles from './index.module.scss';
import CloseIcon from '@/assets/CloseIcon.svg';
import {
  MultipleSelect,
  GroupedSelect,
} from '@/components/Chart/ChartSelectors';
import ReportSaveMenuButton from '@/components//Chart/chartButton';
import { ReportDownloadMenuButton } from '@/components/Chart/chartButton';
import ChartAAndA from './Chart';
import NoDataPage from '@/components/Chart/NoDataPage';
import LoadingPage from '@/components/Chart/LoadingPage';
import ErrorPage from '@/components/Chart/ErrorPage';
import useGetChartsDataHook from './hooks/index';
import useGetDiagramStatusHook from './hooks/useGetDiagramStatusHook';
import {
  GetAAndAOverviewExcel,
  getActAcqOverviewExcelByPost,
} from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { MONTH } from '../Constants/index.js';
import { saveImageAsPng, saveImageAsJpg } from '@/pages/Report/chartUtils';
import {
  LOADING,
  ERROR,
  VALID,
  NO_DATA,
  NO_SELECTION_FROM_DROPDOWN_MENU,
  NO_DISPLAYED_DATA,
} from '@/pages/Report/chartUtils/constants.js';
import { getDownloadFileName } from '@/pages/Report/utils/getDownloadFileName';
import { GET } from '@/pages/Report/SIPipeline/SIPipelineDiagrams/constants';

const ChartContainer = ({
  header,
  subHeader,
  cancelSelectedChart,
  selectedDate,
  hasAdditionalFilter,
  hasSelectAllOption,
  catagoryAttributeName,
  valueAttributeNames,
  url,
  downloadUrl,
  formatMonthYear,
  tableDownloadTitle = null,
  donwloadMethod = GET,
  tableDownloadWithAcquiredSi,
}) => {
  const isCEChart = subHeader === 'CE';
  const [selectedCatagories, setSelectedCatagories] = useState([]);

  const reduxTitle = useMemo(() => {
    return header + '_' + subHeader + '_' + selectedDate;
  }, [header, subHeader, selectedDate]);

  const [
    catagoriesFullList,
    barchartData,
    unfilteredDataForBar,
    allGroups,
    loading,
    error,
  ] = useGetChartsDataHook(
    selectedDate,
    selectedCatagories,
    catagoryAttributeName,
    valueAttributeNames,
    url,
    reduxTitle
  );

  /**
   * check if there is no data for diagram
   *
   * will disable download and export buttons
   * dataForBar is filtered data for bar, however we need to check unfiltered data for bar
   */

  const alertLabel = isCEChart
    ? 'Handling CE'
    : subHeader === 'CAT Group'
    ? 'CAT group'
    : subHeader;

  useEffect(() => {
    setSelectedCatagories(catagoriesFullList);
  }, [catagoriesFullList]);

  const { valid: digramStatus, info } = useGetDiagramStatusHook(
    unfilteredDataForBar,
    barchartData,
    selectedCatagories,
    valueAttributeNames,
    hasAdditionalFilter,
    alertLabel,
    loading,
    error
  );

  // console.log('==selectedCatagories==', selectedCatagories);
  // console.log('==digramStatus==', digramStatus);
  // console.log('==info==', info);

  const [chartWidth, setChartWidth] = useState('100%');
  // for chart download
  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);
  const downloadlink = useRef(); // ADDED

  const dispatch = useDispatch();

  // dependencies: Tab1, Tab2, Level, selected time,
  const fileName = useMemo(() => {
    const rmSpaceSubHeader = subHeader.replaceAll(' ', '');
    const tab2Name =
      valueAttributeNames && valueAttributeNames[0] === 'againstYTDTarget'
        ? 'ProgressTowardsTarget'
        : null;
    const level = tableDownloadTitle ? tableDownloadTitle : rmSpaceSubHeader;
    return getDownloadFileName('SIAcquisition', tab2Name, level, selectedDate);
  }, [tableDownloadTitle, subHeader, selectedDate, valueAttributeNames]);

  const downloadChart = () => {
    console.log(catagoryAttributeName);
    console.log(selectedCatagories);
    if (selectedDate !== null) {
      if (donwloadMethod === GET) {
        dispatch(GetAAndAOverviewExcel(downloadUrl, selectedDate, fileName));
      } else {
        const showAcquiredSIColumns = tableDownloadWithAcquiredSi; // to show Acquired SI value
        const requestBody = {
          selectedDate: selectedDate,
          [catagoryAttributeName]: selectedCatagories,
          showAcquiredSIColumns
        };
        dispatch(
          getActAcqOverviewExcelByPost(downloadUrl, requestBody, fileName)
        );
      }
    }
  };

  const savePng = () => {
    saveImageAsPng(chartRef.current, fileName);
  };
  const saveJpg = () => {
    saveImageAsJpg(chartRef.current, fileName);
  };

  // listen to chart-container size change
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      resizeHandler(entries);
    });
    resizeObserver.observe(chartContainerRef.current);
    return () => {
      if (chartContainerRef.current) {
        resizeObserver.unobserve(chartContainerRef.current);
      }
    };
  }, []);

  function resizeHandler(entries) {
    const chartContainer = entries.find(
      entry => entry.target == chartContainerRef.current
    );
    const width = chartContainer?.target.clientWidth;
    setChartWidth(width);
  }

  const formatMonth = useMemo(() => {
    let date = selectedDate;
    // default is today
    if (date === null) {
      date = moment().utc().format('YYYY-M');
    }
    let [_year, month] = date.split('-');
    month = MONTH[month];
    return month;
  }, [selectedDate]);

  return (
    <div className={styles['chart-container']} ref={chartContainerRef}>
      <Box className={styles['chart-header']}>
        <div className={styles['selectors']}>
          {hasAdditionalFilter && (
            <span id='SG-select'>
              {isCEChart && (
                <GroupedSelect
                  label={'Handling CE'}
                  alertLabel={'Handling CE'}
                  options={allGroups}
                  selectedItems={selectedCatagories ?? []}
                  setSelectedItems={setSelectedCatagories}
                  disabled={
                    [NO_DATA, LOADING, ERROR].indexOf(digramStatus) >= 0
                  }
                />
              )}
              {!isCEChart && (
                <MultipleSelect
                  label={subHeader}
                  alertLabel={subHeader}
                  options={catagoriesFullList}
                  selectedItems={selectedCatagories ?? []}
                  setSelectedItems={setSelectedCatagories}
                  hasSelectAllOption={hasSelectAllOption}
                  disabled={
                    [NO_DATA, LOADING, ERROR].indexOf(digramStatus) >= 0
                  }
                />
              )}
            </span>
          )}
        </div>
        <div className={styles['chart-titles']}>
          <h1 className={styles['chart-title']}>
            {header + ' ' + formatMonthYear}
          </h1>
          <h2 className={styles['chart-subtitle']}>{subHeader + ' Level'}</h2>
        </div>
        <div className={styles['buttons']}>
          <ReportDownloadMenuButton
            func={downloadChart}
            disabled={digramStatus !== VALID}
          />
          <ReportSaveMenuButton
            items={[
              {
                label: 'Save as PNG',
                operation: savePng,
              },
              {
                label: 'Save as JPG',
                operation: saveJpg,
              },
            ]}
            disabled={digramStatus !== VALID}
          />
          <button
            onClick={cancelSelectedChart}
            className={styles['button-close']}
          >
            <CloseIcon />
          </button>
        </div>
      </Box>

      {digramStatus === LOADING && <LoadingPage />}

      {digramStatus === ERROR && <ErrorPage />}

      {digramStatus === NO_DATA && (
        <NoDataPage
          title={`There is no data for any ${info}s on the selected date.`}
          subTitle={'Please choose a different date to view diagram.'}
        />
      )}

      {digramStatus === NO_SELECTION_FROM_DROPDOWN_MENU && (
        <NoDataPage title={`Please select at least one ${info}.`} />
      )}

      {digramStatus === NO_DISPLAYED_DATA && hasAdditionalFilter && (
        <NoDataPage
          title={`There is no data available for selected ${info} on selected date`}
          subTitle={`Please choose a different ${info} or date to view the diagram.`}
        />
      )}

      {digramStatus === VALID && (
        <>
          <Box className={styles['chart']} ref={chartRef}>
            <ChartAAndA
              selectedCatagories={selectedCatagories}
              alertLabel={alertLabel}
              barchartData={barchartData}
              width={chartWidth}
              hasZoom={true}
              hasTitle={false}
              catagoryAttributeName={catagoryAttributeName}
              valueAttributeNames={valueAttributeNames}
              formatMonth={formatMonth}
            />
          </Box>
          <Box className={styles['chart-download']} ref={chartRef}>
            <ChartAAndA
              selectedCatagories={selectedCatagories}
              alertLabel={alertLabel}
              barchartData={barchartData}
              width={chartWidth}
              hasZoom={false}
              hasTitle={true}
              title={header + ' ' + formatMonthYear}
              subTitle={subHeader + ' Level'}
              catagoryAttributeName={catagoryAttributeName}
              valueAttributeNames={valueAttributeNames}
              formatMonth={formatMonth}
            />
          </Box>
          <a
            href='javascript:void(0)'
            download
            ref={downloadlink}
            className={styles['download-link']}
          >
            hidden download link
          </a>
        </>
      )}
    </div>
  );
};

ChartContainer.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  selectedMonth: PropTypes.string.isRequired,
  cancelSelectedChart: PropTypes.func.isRequired,
  hasAdditionalFilter: PropTypes.bool.isRequired,
  hasSelectAllOption: PropTypes.bool.isRequired,
  showSubjectGroupsSelector: PropTypes.bool,
};

export default ChartContainer;

import { useMemo } from 'react';
import Chart from '@/components/Chart/Chart';
import { NormalChartOptions } from '../options';

const ChartSiPipelineNormal = ({
  selectedLegend,
  width,
  hasZoom = false,
  hasTitle = false,
  title,
  subTitle,
  dataForBar,
  dataForPie1,
  dataForPie2,
  loading = false,
  error = false,
  isDownload = false,
}) => {
  // get legend text
  const legendText = dataForBar[0];

  // console.log('==legendText==', legendText);
  // console.log('==width==', width);

  const chartOption = useMemo(() => {
    return new NormalChartOptions(isDownload, legendText);
  }, []);

  const options = useMemo(() => {
    // if loading or error skip drawing the chart
    if (loading || error) {
      return null;
    }

    chartOption
      .setZoom(hasZoom)
      .setTitle(hasTitle, title, subTitle)
      .setDataSourceArray([dataForBar, dataForPie1, dataForPie2])
      .setWidth(width);

    return chartOption.getOption();
  }, [
    hasZoom,
    width,
    hasTitle,
    title,
    subTitle,
    dataForBar,
    dataForPie1,
    dataForPie2,
    loading,
    error,
  ]);

  console.log('======options=====, ', options);

  const chartHeight = useMemo(() => {
    // if loading or error set chart height to 0
    if (loading || error) {
      return 0;
    }

    return chartOption.getHeightWithTitle();
  }, [hasTitle, title, subTitle, dataForBar, loading, error]);

  // console.log("======chartHeight=====, ", chartHeight)

  const [minFontSize, maxFontSize] = useMemo(() => {
    // if loading or error set chart height to 0
    if (loading || error) {
      return 0;
    }
    return chartOption.getLabelFontSize();
  }, [hasTitle, title, subTitle, dataForBar, loading, error]);

  return (
    <Chart
      options={options}
      height={chartHeight}
      width={width}
      isZoomLabelFontSize={true}
      minFontSize={minFontSize}
      maxFontSize={maxFontSize}
    />
  );
};

export default ChartSiPipelineNormal;

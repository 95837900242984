/**
 * @author Liu Xin
 * @date 2022.1.25
 * @description 系统日志查看界面
 */
import './index.css';
import {
  TextField,
  Box,
  StyledEngineProvider,
  Grid,
  Stack,
  Button,
  Tooltip,
} from '@mui/material';
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect, useMount } from 'ahooks';
import React, { useState, useEffect } from 'react';
import LogTable from './LogTable';
import { getSystemLog } from '@/actions/SystemLogAction';
import { NewDesignedMain } from '../../../components/Main';
import {
  getFliterSubject,
  getFliterType,
  getFliterUpdateBy,
  getFliterField,
} from '../../../actions/SystemLogAction';
import Autocomplete from '@mui/material/Autocomplete';
import StyledTag from '../../../components/StyledTag/StyledTag';
import { SIMTButton } from '@/components/StyledButton/SIMTButton';
import ResetIcon from '@/assets/UserManagementResetIcon.svg';
import CalendarIcon from '@/assets/SystemLogCalendarIcon.svg';
import DropdownIconBlack from '@/assets/dropdownIcon.svg';
import { styled } from '@mui/material/styles';
import { unstable_batchedUpdates } from "react-dom";

function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

function replaceItemByList(currentItemList, filterItems, comparor = (a,b) => a==b ) {
  let hasChanged = false;
  let results = [];

  for (let sub of currentItemList){
    for (let item of filterItems){
      if (comparor(sub, item)){
        results.push(sub);
      } else {
        hasChanged = true;
      }
    }
  }

  if (hasChanged){
    return results;
  }

  return currentItemList;
}

function SysLogAutocomplete(props) {
  const { onChange, value, renderTags,  ...others } = props;
  const [localValue, setLocalValue] = useState(value);
  const [timeoutSignal, setTimeSignal] = useState(null);

  useEffect(() => {
    setLocalValue(value);
  }, [ value ]);

  const newRenderTags = (value, getTagProps) => {
    const tags = renderTags(value, getTagProps);
    const newTags = [];
    for (let i = 0; i < value.length; i++) {
      let val = value[i];
      newTags.push(React.cloneElement(tags[i], {
        onDelete: (e) => {
          const newLoaclValue = [];
          for (let v of localValue){
            if (!deepEqual(v, val)) {
              newLoaclValue.push(v);
            }
          }
          setLocalValue(newLoaclValue);
          onChange(e, newLoaclValue);
        },
      }));
    }
    return newTags;
  };

  return (
    <Autocomplete
      sx={{
        '& .MuiInputBase-root.MuiOutlinedInput-root': {
          width: props.maxWidth ? props.maxWidth : '169px',
        },
      }}
      value={localValue}
      onChange={(e, newValue) => {
        setLocalValue(newValue);
        setTimeSignal(setTimeout(() => {
          onChange(e, newValue);
        }, 3000));
      }}
      onBlur={(e) => {
        clearTimeout(timeoutSignal);
        onChange(e, localValue);
      }}
      onOpen={() => {
        clearTimeout(timeoutSignal);
      }}
      renderTags={renderTags!=null ? newRenderTags: null }
      {...others}
    />
  );
}

function SysLogTag(props) {
  return <StyledTag sx={{ width: 'auto', height: '22px' }} {...props} />;
}

/**
 * 顶部控件集样式
 * @type {import("@mui/material").SxProps}
 */
const topWidgetSx = {
  '& .MuiAutocomplete-root, & .MuiFormControl-root.MuiTextField-root': {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      paddingY: 0,
      // paddingRight: "24px",
      minHeight: '40px',
      backgroundColor: '#ffffff',
      paddingLeft: '8px',
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      padding: 0,
      minWidth: '0px',
    },
    '& .MuiOutlinedInput-root:not([aria-invalid="true"]).MuiOutlinedInput-notchedOutline':
      {
        border: '1px solid #DFE4E8',
      },
    '& .MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium:not(:last-of-type)': {
      fontSize: '12px',
      color: '#596A7C',
      fontWeight: '600',
      margin: 0,
      marginRight: '4px',
      backgroundColor: '#F1F3F5',
      border: 'none',
      borderRadius: '5px',
    },
    '& .MuiAutocomplete-clearIndicator': {
      marginRight: '6px',
      backgroundColor: '#9aa7b6',
      color: '#fff',
      padding: '2px',
      '& .MuiSvgIcon-root': {
        height: '12px',
        width: '12px',
      },
    },
  },
};

/**
 * 顶部控件集样式
 * @type {import("@mui/material").SxProps}
 */
const topWidgetItemSx = {
  '& .SystemLog__HeaderTitle': {
    fontSize: '14px',
    color: '#596A7C',
    fontWeight: '600',
    letterSpacing: '1px',
  },
  '& .MuiButtonBase-root.MuiChip-root': {
    maxWidth: '50%',
    '& .MuiChip-label.MuiChip-labelMedium': {
      padding: 0,
    },
    '& .MuiSvgIcon-root': {
      margin: 0,
      padding: 0,
    },
    '& .MuiChip-deleteIcon': {
      margin: 0,
      padding: 0,
      minWidth: '16px',
      minHeight: '16px',
    },
  },
};

function SystemLog() {
  const [nowSelectedTypeList, setNowSelectedTypeList] = useState(["User"]);
  const [nowSelectedSubjectList, setNowSelectedSubjectList] = useState([]);
  const [nowSelectedSybjectNameList, setNowSelectedSubjectNameList] = useState(
    []
  );
  const [nowSelectedSybjectIDList, setNowSelectedSubjectIDList] = useState([]);
  const [nowSelectedUpdatedByList, setNowSelectedUpdatedByList] = useState([]);
  const [nowSelectedFieldList, setNowSelectedFieldList] = useState([]);
  const [subjectSearch, setSubjectSearch] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const dispatch = useDispatch();
  const [Page, setPage] = useState(1);
  const [sortKey, setSortKey] = useState('UpdatedTime');
  const [orderType, setOrderType] = useState('desc');
  const [loadingLogData, setLoadingLogData] = useState(true);
  const filterDrawerWidth = 380;

  const [perPageNumber, setPerPageNumber] = useState(50);
  const [version, setVersion] = useState(0);

  const open = useSelector(state => state.UserManage.siderOpen);
  const selector = state => {
    return {
      systemLog: state.SystemLog.systemLog,
      filterTypeList: state.SystemLog.filterTypeList,
      filterSubjectList: state.SystemLog.filterSubjectList,
      filterUpdateByList: state.SystemLog.filterUpdateByList,
      filterFieldList: state.SystemLog.filterFieldList,
    };
  };
  const {
    systemLog,
    filterTypeList,
    filterSubjectList,
    filterUpdateByList,
    filterFieldList,
  } = useSelector(selector);

  useMount(() => {
    unstable_batchedUpdates(() => {
      dispatch(getFliterType());
      dispatch(getFliterSubject(nowSelectedTypeList));
      dispatch(getFliterUpdateBy(nowSelectedTypeList));
      dispatch(getFliterField(nowSelectedTypeList, nowSelectedSybjectIDList));
    });
  });

  function compareSubject(a, b){
    return a?.subjectVo == b?.subjectVo;
  }

  function compareUpdateBy(a, b){
    return a == b;
  }

  function compareField(a, b){
    return a == b;
  }

  useUpdateEffect(() => {
    const updateFilter = async () => {
      const filterSubjects = await dispatch(getFliterSubject(nowSelectedTypeList));
      const filterUpdateBys = await dispatch(getFliterUpdateBy(nowSelectedTypeList));
      const newSubjects = replaceItemByList(nowSelectedSubjectList, filterSubjects, compareSubject);
      const newUpdateBys = replaceItemByList(nowSelectedUpdatedByList, filterUpdateBys, compareUpdateBy);

      const tmpSelectSubject = [];
      let tmpSelectSubjectId = [];

      for (let i = 0; i < newSubjects.length; i++) {
        tmpSelectSubject.push(newSubjects[i].subjectVo);
        tmpSelectSubjectId = [...tmpSelectSubjectId, ...newSubjects[i].ids];
      }

      const filterFields = await dispatch(getFliterField(nowSelectedTypeList, nowSelectedSybjectIDList));
      const newFields = replaceItemByList(nowSelectedFieldList, filterFields, compareField);

      unstable_batchedUpdates(() => {
        setNowSelectedSubjectList(newSubjects);
        setNowSelectedSubjectNameList(tmpSelectSubject);
        setNowSelectedSubjectIDList(tmpSelectSubjectId);

        setNowSelectedUpdatedByList(newUpdateBys);
        setNowSelectedFieldList(newFields);
      });
    };

    updateFilter();

  }, [nowSelectedTypeList]);

  useUpdateEffect(() => {
    const updateFilter = async () => {
      const filterFields = await dispatch(getFliterField(nowSelectedTypeList, nowSelectedSybjectIDList));
      const newFields = replaceItemByList(nowSelectedFieldList, filterFields, compareField);

      // unstable_batchedUpdates(() => {
        setNowSelectedFieldList(newFields);
      // });
    };

    updateFilter();
  }, [nowSelectedSybjectIDList]);

  const loadData = async () => {
    setLoadingLogData(true);
    await dispatch(
      getSystemLog(
        Page,
        perPageNumber,
        sortKey,
        orderType,
        nowSelectedTypeList,
        nowSelectedSybjectNameList,
        nowSelectedUpdatedByList,
        nowSelectedFieldList,
        fromDate,
        toDate
      )
    );
    setLoadingLogData(false);
  };

  useMount(() => {
    loadData();
  });

  useUpdateEffect(() => {
    loadData();
  }, [
    sortKey,
    orderType,
    fromDate,
    toDate,
    perPageNumber,
    nowSelectedTypeList,
    nowSelectedSubjectList,
    nowSelectedUpdatedByList,
    nowSelectedFieldList,Page
  ]);

  const showValueProcess = systemLog => {
    if (systemLog.result === undefined || systemLog.result === null) {
      return { result: [], total: 0 };
    }
    return systemLog;
  };

  const handlePerPageChange = e => {
    setPerPageNumber(e.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleClearFilter = () => {
    setNowSelectedTypeList(["User"]);
    setNowSelectedSubjectList([]);
    setNowSelectedSubjectNameList([]);
    setNowSelectedSubjectIDList([]);
    setNowSelectedUpdatedByList([]);
    setNowSelectedFieldList([]);
    setFromDate(null);
    setToDate(null);
  };

  const handleSubjectChange = newValue => {
    var tmpSelectSubject = [];
    var tmpSelectSubjectId = [];

    for (var i = 0; i < newValue.length; i++) {
      tmpSelectSubject.push(newValue[i].subjectVo);
      tmpSelectSubjectId = [...tmpSelectSubjectId, ...newValue[i].ids];
    }
    unstable_batchedUpdates(() => {
      setNowSelectedSubjectList(newValue);
      setNowSelectedSubjectNameList(tmpSelectSubject);
      setNowSelectedSubjectIDList(tmpSelectSubjectId);
    });
  };

  const getMinDate = value => {
    var DateValue = new Date(value);
    DateValue.setDate(DateValue.getDate() + 1);
    return DateValue;
  };

  const getMaxDate = value => {
    var DateValue = new Date(value);
    DateValue.setDate(DateValue.getDate() - 1);
    return DateValue;
  };

  const SubjectFilte = items => {
    let filterList = [];
    if (subjectSearch === '' || subjectSearch === undefined) {
      return items;
    }
    for (let itemIndex in items) {
      let tmpItem = items[itemIndex];
      if (tmpItem.subjectVo.indexOf(subjectSearch) > -1) {
        filterList.push(tmpItem);
      }
    }
    return filterList;
  };

  const subjectSearchChange = e => {
    setSubjectSearch(e.target.value);
  };

  // const selectCss = {
  //   textTransform: "uppercase",
  //   fontSize: "14px",
  //   fontWeight: 600,
  //   lineHeight: "20px",
  //   letterSpacing:"1px",
  // }

  // const MuiAutocompleteTagCss = {
  //   fontWeight: 600,
  //   fontSize: "16px",
  //   lineHeight:"24px",
  //   color:"#596A7C"
  // }

  const siderOpen = useSelector(state => state.UserManage.siderOpen);

  const maxWidthI = (document.body.clientWidth - 60 - siderOpen * 240) / 6 - 10;

  /**
   * 顶部控件集 Date Picker 样式
   * @type {import("@mui/material").SxProps}
   */
  const systemLogDatePickerSx = {
    '& .SystemLog__DatePicker.MuiFormControl-root.MuiTextField-root': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        flexDirection: 'row-reverse',
        padding: 0,
        '& .MuiInputBase-input.MuiOutlinedInput-input': {
          width: maxWidthI < 160 ? maxWidthI - 40 + 'px' : '120px',
          paddingRight: '16px',
          boxSizing: 'border-box',
          fontSize: '14px',
          color: '#596A7C',
          textAlign: 'right',
          fontWeight: '600',
          letterSpacing: '0.01em',
          '&::placeholder': {
            fontSize: '14px',
            textAlign: 'right',
            letterSpacing: '0.01em',
          },
        },
        '& .MuiInputAdornment-root': {
          height: '40px',
          margin: 0,
          width: '39px',
          display: 'block',
          position: 'relative',
          borderRight: '1px solid #f6f7f8',
          '& .MuiButtonBase-root.MuiIconButton-root': {
            padding: 0,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          },
        },
      },
    },
  };

  return (
    <>
      <StyledEngineProvider
        injectFirst
        data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider'
      >
        <NewDesignedMain
          data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain'
          sx={{ paddingX: 3, paddingTop: '12px' }}
          open={open}
          filterOpen={false}
          drawerWidth='240px'
          filterDrawerWidth={filterDrawerWidth}
        >
          <p className='PageTitleNew'>System Log</p>
          <Box data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box'>
            <Stack
              data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack'
              direction='row'
              columnGap={1}
              justifyContent='space-between'
              flexWrap='wrap'
              alignItems='center'
              spacing={1}
              sx={{
                marginY: 2,
              }}
            >
              <Stack
                data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack'
                direction='row'
                columnGap={1}
                rowGap={1}
                alignItems='center'
                justifyContent='flex-start'
                flexWrap='wrap'
                sx={{
                  ...topWidgetSx,
                  ...topWidgetItemSx,
                  ...systemLogDatePickerSx,
                }}
              >
                {/* Type */}
                <Stack
                  spacing={1}
                  data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack1'
                >
                  <Box
                    className='SystemLog__HeaderTitle'
                    data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack1-Box'
                  >
                    Type
                  </Box>
                  <SysLogAutocomplete
                    maxWidth={230 < maxWidthI ? '240px' : maxWidthI + 'px'}
                    limitTags={1}
                    id='systemAdminSystemLogTypeInput'
                    data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack1-SysLogAutocomplete-TypeInput'
                    options={filterTypeList ?? []}
                    value={nowSelectedTypeList}
                    disableClearable={true}
                    onChange={(e, newValue) => {
                      setNowSelectedTypeList([newValue]);
                    }}
                    popupIcon={<DropdownIconBlack />}
                    renderOption={(props, option) => (
                      <Box
                        {...props}
                        data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack1-SysLogAutocomplete-TypeInput-Box'
                      >
                        <Tooltip
                          title={option}
                          disableInteractive
                          data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack1-SysLogAutocomplete-TypeInput-Box-Tooltip'
                        >
                          <Box
                            data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack1-SysLogAutocomplete-TypeInput-Box-Tooltip-Box'
                            sx={{
                              textOverflow: 'ellipsis',
                              width: '100%',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {option}
                          </Box>
                        </Tooltip>
                      </Box>
                    )}
                    renderInput={params => <TextField {...params} />}
                    // renderTags={(value, getTagProps) =>
                    //   value.map((option, index) => {
                    //     return (
                    //       <SysLogTag
                    //         data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack1-SysLogAutocomplete-TypeInput-SysLogTag'
                    //         className='SystemLog__HeaderAutocompleteTag'
                    //         key={index}
                    //         label={option}
                    //         {...getTagProps({ index })}
                    //       />
                    //     );
                    //   })
                    // }
                  />
                </Stack>
                <Stack
                  spacing={1}
                  data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack2'
                >
                  <Box
                    className='SystemLog__HeaderTitle'
                    data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack2-Box'
                  >
                    Subject
                  </Box>
                  {/* Subject */}
                  <SysLogAutocomplete
                    multiple
                    limitTags={1}
                    id='systemAdminSystemLogSubjectInput'
                    data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack2-SysLogAutocomplete-SubjectInput'
                    maxWidth={169 < maxWidthI ? '169px' : maxWidthI + 'px'}
                    options={filterSubjectList ?? []}
                    value={nowSelectedSubjectList}
                    onChange={(e, newValue) => {
                      handleSubjectChange(newValue);
                    }}
                    popupIcon={<DropdownIconBlack />}
                    renderOption={(props, option) => (
                      <Box
                        {...props}
                        data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack2-SysLogAutocomplete-SubjectInput-Box'
                      >
                        <Tooltip
                          title={option.subjectVo}
                          disableInteractive
                          data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack2-SysLogAutocomplete-SubjectInput-Box-Tooltip'
                        >
                          <Box
                            data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack2-SysLogAutocomplete-SubjectInput-Box-Tooltip-Box'
                            sx={{
                              wordBreak: 'break-word',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: 'vertical',
                              whiteSpace: 'pre-wrap',
                              // textOverflow: 'ellipsis',
                              // width: '100%',
                              // overflow: 'hidden',
                              // whiteSpace: 'nowrap',
                            }}
                          >
                            {option.subjectVo}
                          </Box>
                        </Tooltip>
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        onChange={subjectSearchChange}
                        data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack2-SysLogAutocomplete-SubjectInput-TextField'
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <SysLogTag
                            data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack2-SysLogAutocomplete-SubjectInput-SysLogTag'
                            key={index}
                            label={option.subjectVo}
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                    filterOptions={SubjectFilte}
                  />
                </Stack>
                <Stack
                  spacing={1}
                  data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack3'
                >
                  <Box
                    className='SystemLog__HeaderTitle'
                    data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack3-Box'
                  >
                    Field
                  </Box>
                  {/* Field */}
                  <SysLogAutocomplete
                    multiple
                    limitTags={1}
                    id='systemAdminSystemLogFieldInput'
                    data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack3-SysLogAutocomplete-SystemLogFieldInput'
                    maxWidth={169 < maxWidthI ? '169px' : maxWidthI + 'px'}
                    options={filterFieldList ?? []}
                    value={nowSelectedFieldList}
                    onChange={(e, newValue) => {
                      setNowSelectedFieldList(newValue);
                    }}
                    popupIcon={<DropdownIconBlack />}
                    renderOption={(props, option) => (
                      <Box
                        {...props}
                        data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack3-SysLogAutocomplete-SystemLogFieldInput-Box'
                      >
                        <Tooltip
                          title={option}
                          disableInteractive
                          data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack3-SysLogAutocomplete-SystemLogFieldInput-Box-Tooltip'
                        >
                          <Box
                            data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack3-SysLogAutocomplete-SystemLogFieldInput-Box-Tooltip-Box'
                            sx={{
                              textOverflow: 'ellipsis',
                              width: '100%',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {option}
                          </Box>
                        </Tooltip>
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack3-SysLogAutocomplete-SystemLogFieldInput-TextField'
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <SysLogTag
                            data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack3-SysLogAutocomplete-SystemLogFieldInput-SysLogTag'
                            key={index}
                            label={option}
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                  />
                </Stack>
                <Stack
                  spacing={1}
                  data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack4'
                >
                  <Box
                    className='SystemLog__HeaderTitle'
                    data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack4-Box'
                  >
                    Update By
                  </Box>
                  {/* Updated By */}
                  <SysLogAutocomplete
                    multiple
                    limitTags={1}
                    id='systemAdminSystemLogUpdatedByInput'
                    data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack4-SysLogAutocomplete-SystemLogUpdatedByInput'
                    maxWidth={169 < maxWidthI ? '169px' : maxWidthI + 'px'}
                    options={filterUpdateByList ?? []}
                    value={nowSelectedUpdatedByList}
                    onChange={(e, newValue) => {
                      setNowSelectedUpdatedByList(newValue);
                    }}
                    popupIcon={<DropdownIconBlack />}
                    renderOption={(props, option) => (
                      <Box
                        {...props}
                        data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack4-SysLogAutocomplete-SystemLogUpdatedByInput-Box'
                      >
                        <Tooltip
                          title={option}
                          disableInteractive
                          data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack4-SysLogAutocomplete-SystemLogUpdatedByInput-Box-Tooltip'
                        >
                          <Box
                            data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack4-SysLogAutocomplete-SystemLogUpdatedByInput-Box-Tooltip-Box'
                            sx={{
                              textOverflow: 'ellipsis',
                              width: '100%',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {option}
                          </Box>
                        </Tooltip>
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack4-SysLogAutocomplete-SystemLogUpdatedByInput-TextField'
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <SysLogTag
                            data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-Stack4-SysLogAutocomplete-SystemLogUpdatedByInput-SysLogTag'
                            key={index}
                            label={option}
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                  />
                </Stack>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-LocalizationProvider'
                >
                  <Stack
                    spacing={1}
                    data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-LocalizationProvider-Stack1'
                  >
                    <Box
                      className='SystemLog__HeaderTitle'
                      data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-LocalizationProvider-Stack1-Box'
                    >
                      From
                    </Box>
                    {/* From */}
                    <DesktopDatePicker
                      data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-LocalizationProvider-Stack1-DesktopDatePicker'
                      inputFormat='dd/MM/yyyy'
                      mask='__/__/____'
                      value={fromDate}
                      maxDate={toDate ? toDate : undefined}
                      onChange={newDate => {
                        setFromDate(newDate);
                      }}
                      className='SystemLog__DatePicker'
                      components={{
                        OpenPickerIcon: CalendarIcon,
                      }}
                      renderInput={params => (
                        <TextField
                          id='systeAdminSystemLogFromDate'
                          data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-LocalizationProvider-Stack1-DesktopDatePicker-TextField-SystemLogFromDate'
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                  <Stack
                    spacing={1}
                    data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-LocalizationProvider-Stack2'
                  >
                    <Box
                      className='SystemLog__HeaderTitle'
                      data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-LocalizationProvider-Stack2-Box'
                    >
                      To
                    </Box>
                    {/* To */}
                    <DesktopDatePicker
                      data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-LocalizationProvider-Stack2-DesktopDatePicker'
                      inputFormat='dd/MM/yyyy'
                      mask='__/__/____'
                      value={toDate}
                      minDate={fromDate ? fromDate : undefined}
                      onChange={newDate => {
                        setToDate(newDate);
                      }}
                      className='SystemLog__DatePicker'
                      components={{
                        OpenPickerIcon: CalendarIcon,
                      }}
                      renderInput={params => (
                        <TextField
                          id='systeAdminSystemLogToDate'
                          {...params}
                          data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack-LocalizationProvider-Stack2-DesktopDatePicker-TextField-SystemLogToDate'
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Stack>
              <Stack
                alignItems='end'
                sx={{ alignSelf: 'end' }}
                data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack2'
              >
                <Box
                  sx={{ marginTop: '16px' }}
                  data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack2-Box'
                >
                  <SIMTButton
                    sx={{
                      borderRadius: '4px',
                      fontFamily: 'Open Sans',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#596A7C',
                      border: '1px solid #DFE4E8',
                      backgroundColor: '#fff',
                      height: '40px',
                      alignItems: 'center',
                    }}
                    variant='outlined'
                    id='systeAdminSystemLogSelectClearButton'
                    data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-Stack-Stack2-Box-SIMTButton-SelectClearButton'
                    onClick={handleClearFilter}
                    startIcon={<ResetIcon />}
                  >
                    Reset
                  </SIMTButton>
                </Box>
              </Stack>
            </Stack>
            <LogTable
              data-seleunim-id='SystemAdmin_SystemLog-Index-StyledEngineProvider-NewDesignedMain-Box-LogTable'
              sortKey={sortKey}
              setSortKey={setSortKey}
              orderType={orderType}
              setOrderType={setOrderType}
              showValue={showValueProcess(systemLog)}
              Page={Page}
              perPageNumber={perPageNumber}
              handlePerPageChange={handlePerPageChange}
              handlePageChange={handlePageChange}
              loadingLogData={loadingLogData}
            />
          </Box>
        </NewDesignedMain>
      </StyledEngineProvider>
    </>
  );
}

export default SystemLog;

import React, { useEffect, useState } from 'react';
import { Box, Stack, Collapse } from '@mui/material';
import { SectionTitleBlock } from '../RevisePage/CustomComponents/CustomBlock';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import LabelAndValue from '@/components/LabelAndValue';
import { CustomInputList } from './submodule/CustomInputList';
import { DatePicker } from './submodule/DatePicker';
import { ItemBlock } from './submodule/ItemBlock';
import { MiniFont } from '@/components/FontStyle';
import { SectionContainer } from '../RevisePage/CustomComponents/CustomContainer';
import SingleKV from '../SIPDetailPage/Submodule/SingleKV';

export const SIPInformationEditSection = props => {
  const {
    value: { keywords, fmSubmissionDeadline, acceptanceDeadline, epDate },
    sipInformation,
    setValue: setSipInformation,
    showErrorMessage = false,
    setError = () => {},
  } = props;

  const [keywordsError, setKeywordsError] = useState(false);

  const [fmSubmissionDeadlineError, setFmSubmissionDeadlineError] =
    useState(false);

  const [acceptanceDeadlineError, setAcceptanceDeadlineError] = useState(false);

  const [epDateError, setEpDateError] = useState(false);

  useEffect(() => {
    setFmSubmissionDeadlineError(
      fmSubmissionDeadline === '' || fmSubmissionDeadline === null
    );
  }, [fmSubmissionDeadline]);

  useEffect(() => {
    setAcceptanceDeadlineError(
      acceptanceDeadline === '' || acceptanceDeadline === null
    );
  }, [acceptanceDeadline]);

  useEffect(() => {
    setEpDateError(epDate === '' || epDate === null);
  }, [epDate]);

  useEffect(() => {
    setError(
      keywordsError ||
        fmSubmissionDeadlineError ||
        acceptanceDeadlineError ||
        epDateError
    );
  }, [
    keywordsError,
    fmSubmissionDeadlineError,
    acceptanceDeadlineError,
    epDateError,
  ]);

  const handleSipInformationChange = newValue => {
    setSipInformation(current => ({
      ...current,
      [newValue.key]: newValue.value,
    }));
  };

  return (
    <SectionContainer title='SIP Information'>
      <Stack
        direction='column'
        maxWidth={'1024px'}
        data-selenium-id='SIP_AcceptPage_InformationEditSection-Stack'
      >
        {sipInformation.keywords === null ||
        sipInformation.keywords?.length === 0 ? (
          <ItemBlock
            title='Keywords'
            mandatory={true}
            sx={{ marginBottom: '16px', maxWidth: '495px' }}
            error={showErrorMessage && keywordsError}
          >
            <CustomInputList
              id='sipReviseKeywordsInput'
              data-selenium-id='SIP_AcceptPage_InformationEditSection-ReviseKeywordsInput'
              value={keywords && keywords.length != 0 ? keywords : ['']}
              setValue={newValue => {
                handleSipInformationChange({
                  key: 'keywords',
                  value: newValue,
                });
              }}
              setError={setKeywordsError}
              maxRows={2}
              minItems={1}
              maxItems={48}
              limitLength={500}
              errorMonitor={showErrorMessage}
              needEmptyCheck={true}
            />
          </ItemBlock>
        ) : null}
        {sipInformation.fmSubmissionDeadline === null ||
        sipInformation.fmSubmissionDeadline === '' ? (
          <ItemBlock
            title='Submission Deadline:'
            mandatory={true}
            sx={{ marginBottom: '16px', maxWidth: '495px' }}
            error={showErrorMessage && fmSubmissionDeadlineError}
          >
            <DatePicker
              id='fmSubmissionDeadline'
              data-selenium-id='SIP_AcceptPage_InformationEditSection-FmSubmissionDeadline'
              value={fmSubmissionDeadline}
              setValue={newValue =>
                handleSipInformationChange({
                  key: 'fmSubmissionDeadline',
                  value: newValue,
                })
              }
              maxDate={acceptanceDeadline}
              errorMonitor={showErrorMessage}
            />
          </ItemBlock>
        ) : null}

        {sipInformation.acceptanceDeadline === null ||
        sipInformation.acceptanceDeadline === '' ? (
          <ItemBlock
            title='Acceptance Deadline:'
            mandatory={true}
            sx={{ marginBottom: '16px', maxWidth: '495px' }}
            error={showErrorMessage && acceptanceDeadlineError}
          >
            <DatePicker
              id='acceptanceDeadline'
              data-selenium-id='SIP_AcceptPage_InformationEditSection-AcceptanceDeadline'
              value={acceptanceDeadline}
              setValue={newValue =>
                handleSipInformationChange({
                  key: 'acceptanceDeadline',
                  value: newValue,
                })
              }
              minDate={fmSubmissionDeadline}
              maxDate={epDate}
              errorMonitor={showErrorMessage}
            />
          </ItemBlock>
        ) : null}

        {sipInformation.epDate === null || sipInformation.epDate === '' ? (
          <ItemBlock
            title='Expected Publication Date:'
            mandatory={true}
            sx={{ marginBottom: '16px', maxWidth: '495px' }}
            // error={true}
            error={showErrorMessage && epDateError}
          >
            <DatePicker
              id='epDate'
              data-selenium-id='SIP_AcceptPage_InformationEditSection-EpDate'
              value={epDate}
              setValue={newValue =>
                handleSipInformationChange({
                  key: 'epDate',
                  value: newValue,
                })
              }
              minDate={acceptanceDeadline}
              errorMonitor={showErrorMessage}
            />
          </ItemBlock>
        ) : null}
      </Stack>
    </SectionContainer>
  );
};

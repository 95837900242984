import {
  Box,
  Fab,
  fabClasses,
  Stack,
  useMediaQuery,
  Link,
  Typography,
} from '@mui/material';
import WiderMain from '@/components/WiderMain';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SIPStatusBlock from './SIPStatusBlock';
import SIPInformationBlock from './SIPInfomationBlock/SIPInformationBlock';
import GEInformationBlock from './GEInformationBlock';
import ContentCommissioningApproachesBlock from './ContentCommissioningApproachesBlock';
import EBMReviewPanel from './EBMReviewPanel/EBMReviewPanel';
import AIScreeningRobot from '@/assets/AIScreeningRobot.svg';

import {
  setCreateSuccessAlert,
  updateExtendDdlValue,
} from '@/actions/SIMT-SIP/SIP/SIPAction';
import './index.css';
import DecisionFold from './DecisionSection/DecisionFold';
import DecisionUnfold from './DecisionSection/DecisionUnfold';
import BackToTop from '../../../assets/backToTop.svg';
import ScrollTop from '../../SIPage/SIDetailPage/ScrollTop';
import AddExtendedDeadline from '@/assets/AddExtendedDeadline.svg';
import Confirm from '@/assets/Confirm.svg';
import UnConfirm from '@/assets/UnConfirm.svg';
import Cancel from '@/assets/Cancel.svg';
import ObjectBlock from './ObjectBlock/ObjectBlock';
import ObjectEditBlock from './ObjectBlock/ObjectEditBlock';
import SIPInformationEditBlock from './SIPInfomationBlock/SIPInfomationEditBlock';
import SIPLog from './SIPLog';
import { SIMTButton } from '../../../components/StyledButton/SIMTButton';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { CustomTooltip } from '@/components/CustomTooltip';
import isHandlingCEthisLoginUser from './utils/isHandlingCEthisLoginUser';
import ErrorDate from '@/assets/ErrorDate.svg';
import { DatePicker } from '@/components/DateRangePicker/DatePicker';
import EditIconDefault from '@/componentsphase2/Button/icon/EditIconDefault.svg';
import Trash from '@/componentsphase2/Button/icon/Trash.svg';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {
  AlertBox,
  ButtonAlertBox,
  UntitledAlertBox,
} from '@/components/Alert/AlertBox';
import { setMenuReduceHeight } from '../../../actions/SideBarAction';
import SIPChecklistSnackbar from '../SIPCheckListPage/compontents/Snackbar';
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import { StateButton } from '@/componentsphase2/StateButton';
import isSubmittedOrInitialReview, {
  isCompleteAcquire,
  isInitialReview,
  isProceeded,
  isArchived,
  isWithdrawOrReject,
  isTransferBackToJPA,
} from './utils/isSubmitted';
import { unstable_batchedUpdates } from 'react-dom';
import MyLocalStorage from '@/utils/MyLocalStorage';
import {
  and,
  or,
  Case,
  contains,
  containsAll,
  PermissionListSwitch,
} from '../../../components/PermissionControl/SwitchCase';
import { getUserInfo } from '@/actions/SIMT-User/User';
import {
  checkProposerOK,
  getContentCommissioningApproaches,
  getGEInformation,
  getObject,
  getSIPInformation,
} from '@/actions/SIMT-SIP/SIP/SipDetail';
import {
  getDecisionTypeList,
} from '@/actions/SIMT-SIP/SIP/StageDecision';
import {
  deleteExtendedDeadline,
  extendedDeadlineEdit,
  extendedDeadlineSubmit,
  getExtendedDdl,
  getRevisionDdl,
} from '@/actions/SIMT-SIP/SIP/SipReviseExtendDdl';
import { loadSIPProcessLog } from '@/actions/SIMT-SIP/SIP/SipProcessLog';
import { CheckSipNonCatJournal } from '@/actions/SIMT-SIP/SIP/SiptoSi';
import { getSIPSourceOption } from '@/actions/SIMT-SIP/SIP/Config';
import { getSIPChecklistDetail } from '@/actions/SIMT-SIP/SIPChecklistSubmitted';
import { PERMISSIONS } from '@/constant/permission';
import { getURLSearch } from '@/utils/commonUtils';
import DecisionPanel, { showNewDecisionPanel } from './DecisionPanel';
import { COMMISIONING_EDITOR_REVIEW } from '@/constant/sipStage';
import { ChecklistStatus } from '@/components/Form/form.d';
import { needToComfirmShow } from '@/pages/SIPPage/SIPDetailPage/utils/isSubmitted';

const DECISION_PANEL = 'DECISION_PANEL';
const ASSIGN_SCREENER_PANEL = 'ASSIGN_SCREENER_PANEL';
const NULL = 'NULL';

export const PANEL = {
  DECISION_PANEL,
  ASSIGN_SCREENER_PANEL,
  NULL,
};

export default function SIPDetailPage() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { sipCode } = useParams();
  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      sipInformation: state.SIP.sipInformation,
      sipStatus: state.SIP.sipStatus,
      role: state.User.role,
      checkIsNonCatJournal: state.SIP.checkIsNonCatJournal,
      SIPChecklistStatus: state?.SIPChecklist?.SIPChecklistDetail?.status,
      revisionDdl: state.SIP.revisionDdl,
      extendDdl: state.SIP.extendDdl,
      sIPChecklistDetail: state.SIPChecklist.SIPChecklistDetail,
      assignToCESubmitionDecisionResult:
        state.SIP.assignToCESubmitionDecisionResult,
      createSuccessAlert: state.SIP.createSuccessAlert,
      decisionType: state.SIP.decisionType,
      geInformation: state.SIP.geInformation,
      sipStatusLoading: state.SIP.sipStatusLoading,
    };
  };

  const {
    open,
    sipInformation,
    sipStatus = { sipStage: '', sipOrigin: '' },
    role,
    checkIsNonCatJournal,
    SIPChecklistStatus,
    revisionDdl,
    extendDdl,
    assignToCESubmitionDecisionResult = 0,
    createSuccessAlert,
    decisionType,
    geInformation,
    sipStatusLoading,
  } = useSelector(selector);

  const User = useSelector(state => state.User);
  const firstName = User?.firstName;
  const lastName = User?.lastName;

  const [panelDisplayed, setPanelDisplayed] = useState(PANEL.NULL);
  const [isDecisionSaved, setIsDecisionSaved] = useState(false);
  const [isAssignScreenerSaved, setIsAssignScreenerSaved] = useState(false);
  const [isObjectEdit, setIsObjectEdit] = useState(false);
  const [isSIPInfomationEdit, setIsSIPInfomationEdit] = useState(false);
  const [editable, setEditable] = useState(true);
  const [isShevled, setIsShevled] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [errorDeadline, setErrorDeadline] = useState(false);
  const matches1168 = useMediaQuery('(min-width:1168px)');
  const matches1368 = useMediaQuery('(min-width:1368px)');
  const matches1238 = useMediaQuery('(min-width:1238px)');
  const [listInOneColumn, setListInOneColumn] = useState(false);
  const [listInOneColumnEdit, setListInOneColumnEdit] = useState(false);
  const [isDecisionButtonDisabled, setIsDecisionButtonDisabled] =
    useState(true);
  const [isGetSIPChecklistDetailLoading, setIsGetSIPChecklistDetailLoading] =
    useState(true);
  const /**@type {string}*/ sipStage = sipStatus?.sipStage;

  const userPermissions = localStorage.getItem('userPermissions');
  let userPermissionArray = localStorage.getItem('userPermissions').split(',');
  const userInfo = MyLocalStorage.getItem('userInfo');
  const userEmail = userInfo?.email;
  const sipHandlingCeEmail = sipStatus?.emailOfHandlingCe;
  const hasSipWriteHandlingCE =
    userPermissionArray.includes(PERMISSIONS.SIP_WRITE_HANDLING_CE) &&
    userEmail === sipHandlingCeEmail;
  const hasSipWriteOpsLead =
    userPermissionArray.includes(PERMISSIONS.SIP_WRITE_OPS) &&
    userEmail === sipStatus?.emailOfOperationsLead;
  const hasSipWriteScreener =
    userPermissionArray.includes(PERMISSIONS.SIP_WRITE_SCREENER) &&
    userEmail === sipStatus?.emailOfScreener;

  const readOnly =
    !userPermissionArray.includes(PERMISSIONS.SIP_WRITE_JPA) &&
    !hasSipWriteHandlingCE &&
    !userPermissionArray.includes(PERMISSIONS.SIP_WRITE_OPS) &&
    userPermissionArray.includes(PERMISSIONS.SIP_READ);

  useEffect(()=>{
    console.log('readOnly',readOnly);
  },[readOnly]);
  useEffect(() => {
    if (isInitialReview(sipStage)) {
      new Promise(resolve =>
        dispatch(
          getSIPChecklistDetail(
            sipCode,
            sipStage?.trim()?.split(' ')?.[0]?.toLowerCase(),
            resolve
          )
        )
      )
        .then(e => {
          if (e) {
            setIsGetSIPChecklistDetailLoading(false);
          }
        })
        .catch(console.error);
    }
  }, [sipStage]);

  // get the decision panel options per the current user and stage.
  useEffect(() => {
    dispatch(getDecisionTypeList(sipCode));
  }, [getDecisionTypeList, sipCode, sipStage]);

  // Panel is folded (currently there are Decison panel, Assign Screener panel)
  const decisionIsFold = useMemo(() => {
    return panelDisplayed === PANEL.NULL;
  }, [panelDisplayed]);

  /**
   * Below code is used in Initial Review stage
   * to identify the user permission and operation buttons on panel
   */
  const isSipOpsLead = useMemo(() => {
    return sipStatus?.emailOfOperationsLead === User?.email;
  }, [User, sipStatus]);

  const isSipScreener = useMemo(() => {
    return sipStatus?.emailOfScreener === User?.email;
  }, [User, sipStatus]);

  const isChecklistCompleted = useMemo(() => {
    return (
      SIPChecklistStatus === ChecklistStatus.COMPLETED ||
      SIPChecklistStatus === ChecklistStatus.ARCHIVED
    );
  }, [SIPChecklistStatus, ChecklistStatus]);

  const showAssignToScreenerBtn = useMemo(() => {
    if (SIPChecklistStatus === null) return false;
    return isInitialReview(sipStage) && isSipOpsLead && !isChecklistCompleted;
  }, [sipStage, isSipOpsLead, isChecklistCompleted, SIPChecklistStatus]);

  const showCheckListBtn = useMemo(() => {
    return isInitialReview(sipStage) && (isSipScreener || (isSipOpsLead && sipStatus.screenerId !== null));
  }, [sipStage, isSipScreener, isSipOpsLead, sipStatus]);

  const showDecisionBtn = useMemo(() => {
    return Boolean(sipStatus?.isShowDecisionPanel);
  }, [sipStatus?.isShowDecisionPanel]);

  const showAIScreeningBtn = useMemo(() => {
    const sipType = sipInformation?.sipType;
    const sipStage = sipStatus?.sipStage;
    if (!sipType || !sipStage) return false;

    let canShow = false;
    if (sipType === 'No') {
      canShow = true;
    }
    if (sipType === 'Yes') {
      canShow =
        isSubmittedOrInitialReview(sipStage) ||
        sipStage === COMMISIONING_EDITOR_REVIEW;
    }
    return canShow;
  }, [sipInformation?.sipType, sipStatus?.sipStage]);

  const hasGEInfo = useMemo(() => {
    return (
      geInformation && Array.isArray(geInformation) && geInformation.length > 0
    );
  }, [geInformation]);

  useEffect(() => {
    // must has GE info in SIP detail
    if (!hasGEInfo) {
      setIsDecisionButtonDisabled(true);
    } else if (isInitialReview(sipStage) && isSipOpsLead) {
      setIsDecisionButtonDisabled(!isChecklistCompleted);
    } else {
      setIsDecisionButtonDisabled(false);
    }
  }, [
    sipCode,
    sipStage,
    isSipOpsLead,
    isChecklistCompleted,
    hasGEInfo,
  ]);

  useEffect(() => {
    setTimeout(() =>
      unstable_batchedUpdates(() => {
        if (
          isDecisionButtonDisabled &&
          isInitialReview(sipStage) &&
          Date.now() - assignToCESubmitionDecisionResult < 3e3
        )
          setPanelDisplayed(PANEL.NULL);
      })
    );
  }, [sipStage, assignToCESubmitionDecisionResult, isDecisionButtonDisabled]);

  const revisionDdlExsit = revisionDdl.length === 0 ? false : true;

  const isShowDecisionPanel = useMemo(() => {
    return showDecisionBtn || showCheckListBtn || showAssignToScreenerBtn;
  }, [showDecisionBtn, showCheckListBtn, showAssignToScreenerBtn]);

  useEffect(() => {
    dispatch(getRevisionDdl(sipCode));
  }, [sipStatus]);

  useEffect(() => {
    const onFoldUnFold = () => {
      const screenWidth = window.innerWidth;
      let scaleToUse = 1;
      if (screenWidth - 10 < 1300) {
        scaleToUse = (screenWidth - 10) / 1300.0;
      }
      dispatch(setMenuReduceHeight(256 * scaleToUse));
    };

    if (sipStatus && isShowDecisionPanel && !decisionIsFold) {
      window.addEventListener('resize', onFoldUnFold);
    } else {
      window.removeEventListener('resize', onFoldUnFold);
    }
    return () => {
      window.removeEventListener('resize', onFoldUnFold);
    };
  }, [decisionIsFold, isShowDecisionPanel]);

  useEffect(() => {
    if (sipStatus && isShowDecisionPanel) {
      if (decisionIsFold) {
        dispatch(setMenuReduceHeight(62));
      } else {
        const screenWidth = window.innerWidth;
        let scaleToUse = 1;
        if (screenWidth - 10 < 1300) {
          scaleToUse = (screenWidth - 10) / 1300.0;
        }
        dispatch(setMenuReduceHeight(256 * scaleToUse));
      }
    }
    return () => {
      dispatch(setMenuReduceHeight(0));
    };
  }, [sipStatus, isShowDecisionPanel, decisionIsFold]);

  useEffect(() => {
    if ((!open && matches1238) || (open && matches1368))
      setListInOneColumnEdit(true);
    else setListInOneColumnEdit(false);
  }, [open, matches1368, matches1238]);

  useEffect(() => {
    if ((!open && matches1168) || (open && matches1368))
      setListInOneColumn(true);
    else setListInOneColumn(false);
  }, [open, matches1368, matches1168]);

  const isEditablePermission = () => {
    if (hasSipWriteHandlingCE) {
      if (
        sipStatus.sipStage ===
          intl.messages['sipDetail.sipStageType.ideation'] ||
        sipStatus.sipStage ===
          intl.messages['sipDetail.sipStageType.CEReview'] ||
        sipStatus.sipStage ===
          intl.messages['sipDetail.sipStageType.editorReview']
      ){
        return true;
      }
    }

    if (userPermissions.includes(PERMISSIONS.SIP_WRITE_JPA)) {
      if (
        sipStatus.sipStage === intl.messages['sipDetail.sipStageType.CEReview']||
        sipStatus.sipStage === intl.messages['sipDetail.sipStageType.ideation']
      ) {
        return true;
      }
    }

    if (hasSipWriteOpsLead) {
      console.log("hasSipWriteOpsLead",hasSipWriteOpsLead);
      return (
        sipStatus.sipStage ===
          intl.messages['sipDetail.sipStageType.initialReview'] ||
        sipStatus.sipStage === intl.messages['sipDetail.sipStageType.EbmReview']
      );
    }
    return false;
  };

  useEffect(() => {
    if (sipStatus && Boolean(role)) {
      setEditable(isEditablePermission());
    }
  }, [sipStatus, role]);

  useEffect(() => {
    dispatch(getObject(sipCode));
    dispatch(getSIPInformation(sipCode));
    dispatch(getGEInformation(sipCode));
    dispatch(getContentCommissioningApproaches(sipCode));
    dispatch(CheckSipNonCatJournal(sipCode));
    dispatch(loadSIPProcessLog(sipCode));
    dispatch(getRevisionDdl(sipCode));
    dispatch(getExtendedDdl(sipCode));
    dispatch(getSIPSourceOption());
  }, []);

  useEffect(() => {
    if (!Boolean(role)) {
      async function loadUserInfo() {
        await dispatch(getUserInfo());
      }
      loadUserInfo();
    }
  }, [dispatch, role]);

  const titleStyle = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '24px',
    color: '#262E35',
    paddingTop: '28px',
    // paddingBottom: '22px',
    height: '74px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
  };

  const handleAddClick = () => {
    if (
      userPermissionArray.includes(PERMISSIONS.SIP_WRITE_JPA) ||
      hasSipWriteHandlingCE||
      userPermissionArray.includes(PERMISSIONS.SIP_WRITE_OPS)
    ) {
      setShowAdd(false);
      setShowDatePicker(true);
    }
  };

  const [showDialog, setShowDialog] = useState(false);

  const [severity, setSeverity] = useState('info');
  const [buttonAlertTitle, setButtonAlertTitle] = useState('');
  const [buttonAlertMessage, setButtonAlertMessage] = useState('');
  const [buttonAlertButtons, setButtonAlertButtons] = useState([]);
  const [buttonAlertOpen, setButtonAlertOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [extendedDeadline, setExtendedDeadline] = useState(extendDdl);
  const [confirmDate, setConfirmDate] = useState(false);
  const [showAdd, setShowAdd] = useState(true);

  useEffect(() => {
    setExtendedDeadline(extendDdl);
    setConfirmDate(extendDdl === null ? false : true);
    setShowAdd(extendDdl === null ? true : false);
  }, [extendDdl]);

  const handleConfirmClick = () => {
    setConfirmDate(true);
    setShowAdd(false);
    if (edit) {
      dispatch(extendedDeadlineEdit(sipCode, extendedDeadline));
      dispatch(updateExtendDdlValue(extendedDeadline));
    } else {
      dispatch(extendedDeadlineSubmit(sipCode, extendedDeadline));
      dispatch(updateExtendDdlValue(extendedDeadline));
    }
  };
  const handleCancelClick = () => {
    handleTransferBackToJPABack();
  };

  const handleDeleteClick = () => {
    if (
      userPermissionArray.includes(PERMISSIONS.SIP_WRITE_JPA) ||
      userPermissionArray.includes(PERMISSIONS.SIP_WRITE_OPS)||
      hasSipWriteHandlingCE
    ) {
      setShowDialog(true);
    }
  };

  const handleEditClick = () => {
    if (
      userPermissionArray.includes(PERMISSIONS.SIP_WRITE_JPA) ||
      userPermissionArray.includes(PERMISSIONS.SIP_WRITE_OPS)||
      hasSipWriteHandlingCE
    ) {
      setEdit(true);
      setShowDatePicker(true);
      setConfirmDate(false);
    }
  };

  const handleDialogClose = () => {
    setShowDialog(true);
  };

  const navigate = useNavigate();

  const handleTransferBackToJPABack = () => {
    setErrorDeadline(false);
    setSeverity('warning');
    setButtonAlertTitle(
      intl.messages['sipDetail.transferBackToJPACancelTitle']
    );
    setButtonAlertMessage(
      intl.messages['sipDetail.transferBackToJPACancelMessage']
    );
    setButtonAlertButtons([
      {
        text: intl.messages['sipDetail.transferBackToJPACancelKeepButton'],
        onClick: () => {
          setButtonAlertOpen(false);
        },
      },
      {
        text: intl.messages['sipDetail.transferBackToJPACancelCancelButton'],
        onClick: () => {
          setButtonAlertOpen(false);
          setShowDatePicker(false);
          setShowDialog(false);
          if (extendDdl != null) {
            setShowAdd(false);
            setConfirmDate(true);
            setExtendedDeadline(extendDdl);
          } else {
            setShowAdd(true);
            setConfirmDate(false);
            setExtendedDeadline(null);
          }
        },
      },
    ]);
    setButtonAlertOpen(true);
  };

  useEffect(() => {
    if (sipStatus) {
      if (sipStatus.sipStage !== 'Revision') {
        localStorage.setItem('lastStage', sipStatus.sipStage);
      }
      if (
        localStorage.getItem('lastStage') !== 'Revision' &&
        sipStatus.sipStage === 'Revision' &&
        localStorage.getItem('lastStage') !== null
      ) {
        setConfirmDate(false);
        setShowAdd(true);
        localStorage.setItem('lastStage', 'Revision');
      }
    }
  }, [sipStatus]);

  function MyBreadCrums() {
    return (
      <Breadcrumbs
        aria-label='breadcrumb'
        sx={{
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Link
          underline='hover'
          color='inherit'
          href='/simt/auth/sipRelated/sip/overview'
          sx={{
            color: '#0052CC',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            width: '146px',
            height: '20px',
            '&:hover': {
              // textDecoration: 'underline',
              color: 'var(--primary-600, #113D95)',
              fontWeight: '600',
              textDecoration: 'none !important',
            },
            '&:active': {
              fontWeight: '600',
              color: '#113D95',
              textDecoration: 'none !important',
            },
          }}
        >
          Special Issue Proposal
        </Link>
        <Typography
          sx={{
            height: '20px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#596A7C',
          }}
        >
          Special Issue Proposal Detail
        </Typography>
      </Breadcrumbs>
    );
  }

  const location = useLocation();

  const showDecisionPanel = () => {
    setPanelDisplayed(PANEL.DECISION_PANEL);
    dispatch(getDecisionTypeList(sipCode));
  };

  useEffect(() => {
    const params = getURLSearch(); //Search parameters for the current page
    if (
      params &&
      isProceeded(SIPChecklistStatus) &&
      isWithdrawOrReject(params?.checkListResult ?? '')
    ) {
      showDecisionPanel();
    }
    if (
      params &&
      isProceeded(SIPChecklistStatus) &&
      isTransferBackToJPA(params?.checkListResult ?? '')
    ) {
      showDecisionPanel();
    }
  }, [location, SIPChecklistStatus]); //https://zqh0ovipcne.feishu.cn/record/ARedrukybekiTIcnUEycbgwJnXc、https://jira.wiley.com/browse/CT-6605

  const [siAcquisitionAlertBoxVisiable, setSIAcquisitionAlertBoxVisiable] =
    useState(false);

  const [siAcquisitionDisabled, setSIAcquisitionDisabled] = useState(false);

  return (
    <Box
      id='sipDetailPageBody'
      data-selenium-id='SIPPage_SIPDetailPage-Index-Body'
      sx={{
        // overflowX: "scroll",
        width: '100%',
      }}
    >
      {/* Title */}
      <SIPChecklistSnackbar
        messageInfo={{
          type: 'success',
          title: 'You have successfully completed the checklist!',
          message: 'You can continue to make  decision now.',
          actions: [],
        }}
      />
      <WiderMain
        data-selenium-id='SIPPage_SIPDetailPage-Index-WiderMain1'
        sx={{
          paddingX: '24px',
          paddingY: 0,
          margin: 0,
          marginLeft: open ? '240px' : '83px',
          boxSizing: 'border-box',
          backgroundColor: 'transparent',
          width: 'auto',
          '& *': {
            boxSizing: 'border-box',
          },
        }}
        height='74px'
        marginTop='4px'
      >
        <MyBreadCrums data-selenium-id='SIPPage_SIPDetailPage-Index-WiderMain1-MyBreadCrums' />
        <Box
          data-selenium-id='SIPPage_SIPDetailPage-Index-WiderMain1-Box'
          paddingX='32px'
          sx={{ backgroundColor: '#fff' }}
          style={titleStyle}
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            id='sipDetailTitleBox'
            data-selenium-id='SIPPage_SIPDetailPage-Index-WiderMain1-TitleBox'
          >
            <CustomTooltip
              data-selenium-id='SIPPage_SIPDetailPage-Index-WiderMain1-Tooltip'
              title={
                sipInformation && sipInformation.sipTitle
                  ? sipInformation.sipTitle
                  : null
              }
              sx={{ translate: '0 10px' }}
              placement='top'
            >
              <Box
                data-selenium-id='SIPPage_SIPDetailPage-Index-WiderMain1-InnerBox'
                sx={{
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '24px',
                  lineHeight: '24px',
                  height: '46px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {sipInformation && sipInformation.sipTitle
                  ? sipInformation.sipTitle
                  : null}
              </Box>
            </CustomTooltip>
            {showAIScreeningBtn && (
              <ShowForPermission permission={`${PERMISSIONS.SIP_READ}`}>
                <StateButton
                  titleName='AI Screening'
                  themeSize={true}
                  startIcon={<AIScreeningRobot />}
                  sx={{
                    '&:hover': {
                      'svg path': {
                        fill: 'white',
                      },
                    },
                    '&:active': {
                      'svg path': {
                        fill: 'white',
                      },
                    },
                    height: '41px',
                    minWidth: '146px',
                  }}
                  onClick={() => {
                    window.open(
                      `/simt/auth/sipRelated/sip/aiScreeningReport/${sipCode}`,
                      '_blank'
                    );
                  }}
                ></StateButton>
              </ShowForPermission>
            )}
          </Stack>
        </Box>
      </WiderMain>
      {/* Main Sections Container */}
      <WiderMain
        data-selenium-id='SIPPage_SIPDetailPage-Index-WiderMain2'
        sx={{
          paddingX: '24px',
          paddingTop: '44px',
          paddingBottom: '24px',
          margin: 0,
          marginLeft: open ? '240px' : '83px',
          boxSizing: 'border-box',
          backgroundColor: 'transparent',
          width: 'auto',
          '& *': {
            boxSizing: 'border-box',
          },
          minHeight: '100vh',
        }}
      >
        {/* Main Sections Container */}
        <Box
          paddingX='32px'
          paddingY='12px'
          sx={{
            backgroundColor: '#fff',
            '& > *': {
              width: '1094px',
              maxWidth: '100%',
              overflowX: 'auto',
            },
            '@media (min-width: 600px)': {
              '& > *': {
                overflowX: 'hidden',
              },
            },
          }}
          data-selenium-id='SIPPage_SIPDetailPage-Index-WiderMain2-Box'
        >
          <ButtonAlertBox
            open={buttonAlertOpen}
            handleClose={() => {
              setButtonAlertOpen(false);
            }}
            title={buttonAlertTitle}
            message={buttonAlertMessage}
            buttons={buttonAlertButtons}
            autoHideDuration={4000}
            severity={severity}
            style={{
              width: '546px',
              position: 'absolute',
              top: '8px',
            }}
            position={{
              vertical: 'top',
              horizontal: 'right',
            }}
          />
          {(userPermissionArray.includes(PERMISSIONS.SIP_WRITE_JPA) ||
            hasSipWriteHandlingCE ||
            userPermissionArray.includes(PERMISSIONS.SIP_READ)) &&
            sipStatus &&
            revisionDdlExsit &&
            sipStatus.sipStage === 'Revision' && (
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    data-selenium-id='SIPPage_SIPDetailPage-Index-WiderMain1-InnerBox'
                    sx={{
                      fontFamily: 'Open Sans',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '16px',
                      lineHeight: '24px',
                      height: '20px',
                      width: '224px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      color: '#596A7C',
                    }}
                  >
                    Revision Deadline:
                  </Box>

                  <span
                    style={{
                      fontFamily: 'Open Sans',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '24px',
                      height: '20px',
                      width: '224px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      color: 'var(--gray-600, #262E35)',
                    }}
                  >
                    {revisionDdl}
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                    marginBottom: '32px',
                  }}
                >
                  <Box
                    data-selenium-id='SIPPage_SIPDetailPage-Index-WiderMain1-InnerBox'
                    sx={{
                      fontFamily: 'Open Sans',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '16px',
                      lineHeight: '24px',
                      height: '20px',
                      width: showAdd ? '146px' : '220px',
                      color: 'var(--primary-500, #0052CC)',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {showAdd ? 'Extended Deadline' : 'Extended Deadline:'}
                  </Box>
                  {showAdd && !readOnly && (
                    <div style={{ marginLeft: '11px', marginTop: '10px' }}>
                      <AddExtendedDeadline onClick={handleAddClick} />
                    </div>
                  )}
                  {!confirmDate && (
                    <div>
                      {showDatePicker && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            borderColor: '#EE5350',
                          }}
                        >
                          <div style={{ position: 'relative' }}>
                            <DatePicker
                              inputFormat='yyyy-MM-dd'
                              value={extendedDeadline}
                              onChange={setExtendedDeadline}
                              handleDateChange={f => {
                                setExtendedDeadline(f);
                                if (
                                  new Date(f) > new Date(revisionDdl) &&
                                  revisionDdl !== null
                                ) {
                                  setConfirm(true);
                                  setErrorDeadline(false);
                                } else {
                                  setConfirm(false);
                                  setErrorDeadline(true);
                                }
                              }}
                              innerBorder={
                                errorDeadline ? '2px solid #EE5350' : null
                              }
                            />
                            {!confirm && extendedDeadline !== null && (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '100%',
                                  left: 0,
                                  color: 'var(--error-600, #EE5350)',
                                  fontFamily: 'Open Sans',
                                  fontSize: '12px',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  lineHeight: 'normal',
                                  letterSpacing: '-0.084px',
                                }}
                              >
                                <ErrorDate />
                                This date should fall after the Revision
                                Deadline.
                              </div>
                            )}
                          </div>

                          <Box sx={{ marginLeft: '16px' }}>
                            {(!confirm || extendedDeadline === null) &&
                              !readOnly && <UnConfirm />}
                            {confirm &&
                              extendedDeadline !== null &&
                              !readOnly && (
                                <Confirm onClick={handleConfirmClick} />
                              )}
                          </Box>
                          <Box sx={{ marginLeft: '10px' }}>
                            {!readOnly && (
                              <Cancel onClick={handleCancelClick} />
                            )}
                          </Box>
                        </div>
                      )}
                    </div>
                  )}
                  {confirmDate && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span
                        style={{
                          fontFamily: 'Open Sans',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '16px',
                          lineHeight: '24px',
                          height: '20px',
                          width: '84px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          color: 'var(--primary-600, #113D95)',
                          marginLeft: '4px',
                        }}
                      >
                        {extendedDeadline}
                      </span>
                      <Box sx={{ marginLeft: '16px', marginTop: '10px' }}>
                        {!readOnly && (
                          <EditIconDefault onClick={handleEditClick} />
                        )}
                      </Box>
                      <Box
                        sx={{
                          marginLeft: '12px',
                          marginTop: '10px',
                          '& .CancelButton': {
                            color: 'var(--primary-500, #0052CC)',
                            '&:hover': {
                              background: '#DFE4E8',
                            },
                            '&:active': {
                              background: '#DFE4E8',
                            },
                          },
                          '& .SaveButton': {
                            background: '#0052CC',
                            color: '#FFF',
                            '&:hover': {
                              background: '#6D98EE',
                            },
                            '&:active': {
                              background: '#113D95',
                            },
                            '&:disabled': {
                              background: '#DFE4E8',
                              color: '#B3BAC5',
                              cursor: 'not-allowed',
                            },
                          },
                        }}
                      >
                        {!readOnly && <Trash onClick={handleDeleteClick} />}
                        <Dialog
                          open={showDialog}
                          onClose={handleDialogClose}
                          aria-labelledby='alert-dialog-title'
                          aria-describedby='alert-dialog-description'
                          sx={{
                            background: ' rgba(17, 61,149, 0.5)',
                            backdropFilter: 'blur(4px)',
                            '& .MuiPaper-root': {
                              margin: '10px',
                              width: '600px',
                              boxShadow: 'none',
                              //height: "330px",
                            },
                          }}
                        >
                          <DialogTitle
                            id='alert-dialog-title'
                            style={{
                              color: 'var(--gray-600, #262E35)',
                              textAlign: 'center',
                              fontFamily: 'Open Sans',
                              fontSize: '18px',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              lineHeight: '125%' /* 22.5px */,
                            }}
                          >
                            {'Remove the extended deadline?'}
                          </DialogTitle>
                          <Box
                            // className={styleModule.SaveAndCancelButtonBox}
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: '536px',
                              padding: '12px 150px 20px 8px',
                              alignItems: 'flex-end',
                              justifyContent: 'flex-end',
                              gap: '16px',
                              '& > button': {
                                textTransform: 'none',
                                borderRadius: '5px',
                                border: '1px solid #0052CC',
                                width: '70px',
                                padding: '8px 12px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                fontFamily: 'Open Sans',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '18px',
                              },
                              '& .CancelButton': {
                                color: 'var(--primary-500, #0052CC)',
                                '&:hover': {
                                  background: '#DFE4E8',
                                },
                                '&:active': {
                                  background: '#DFE4E8',
                                },
                              },
                              '& .SaveButton': {
                                background: '#0052CC',
                                color: '#FFF',
                                '&:hover': {
                                  background: '#6D98EE',
                                },
                                '&:active': {
                                  background: '#113D95',
                                },
                                '&:disabled': {
                                  background: '#DFE4E8',
                                  color: '#B3BAC5',
                                  cursor: 'not-allowed',
                                },
                              },
                            }}
                          >
                            <Button
                              disableElevation
                              disableRipple
                              // className={styleModule.CancelButton}
                              className={'CancelButton'}
                              onClick={() => {
                                setShowDialog(false);
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              disableElevation
                              disableRipple
                              onClick={() => {
                                setShowDatePicker(false);
                                setShowDialog(false);
                                setShowAdd(true);
                                setConfirmDate(false);
                                setExtendedDeadline(null);
                                setEdit(false);
                                dispatch(updateExtendDdlValue(null));
                                dispatch(deleteExtendedDeadline(sipCode));
                              }}
                              // className={styleModule.SaveButton}
                              className={'SaveButton'}
                            >
                              Save
                            </Button>
                          </Box>
                        </Dialog>
                      </Box>
                    </div>
                  )}
                </div>
              </div>
            )}

          <SIPStatusBlock listInOneColumn={listInOneColumn} />

          {isObjectEdit ? (
            <ObjectEditBlock
              setIsObjectEdit={setIsObjectEdit}
              listInOneColumn={listInOneColumnEdit}
            />
          ) : (
            <ObjectBlock
              setIsObjectEdit={setIsObjectEdit}
              Editable={editable}
              listInOneColumn={listInOneColumn}
              sipType={sipInformation?.sipType}
            />
          )}

          <GEInformationBlock
            data-selenium-id='SIPPage_SIPDetailPage-GEInformationBlock'
            Editable={editable}
            sipStage={sipStatus?.sipStage}
            setDecisionIsFold={() => setPanelDisplayed(PANEL.DECISION_PANEL)}
            setIsDecisionButtonDisabled={setIsDecisionButtonDisabled}
          />

          {isSIPInfomationEdit ? (
            <SIPInformationEditBlock
              setIsSIPInfomationEdit={setIsSIPInfomationEdit}
              sipStage={sipStatus?.sipStage}
            />
          ) : (
            <SIPInformationBlock
              setIsSIPInfomationEdit={setIsSIPInfomationEdit}
              sipStage={sipStatus?.sipStage}
              Editable={editable}
            />
          )}
          <EBMReviewPanel />
          <ContentCommissioningApproachesBlock
            isShevled={isShevled}
            editable={editable}
          />
          <SIPLog SIPCode={sipCode}></SIPLog>
          {sipStatus &&
          sipStatus.sipStage ===
            intl.messages['sipDetail.sipStageType.completeAccept'] &&
          (!sipStatus.siCode || sipStatus?.siCode?.length === 0) &&
          checkIsNonCatJournal !== '' &&
          !checkIsNonCatJournal ? (
            <Stack
              data-selenium-id='SIPPage_SIPDetailPage-Index-WiderMain2-Stack'
              justifyContent='flex-end'
              direction='row'
              sx={{ mt: '66px', mb: '28px' }}
            >
              <PermissionListSwitch>
                <Case
                  prediction={and(
                    containsAll(PERMISSIONS.SI_WRITE, PERMISSIONS.GE_WRITE),
                    or(
                      contains(PERMISSIONS.SIP_WRITE_JPA),
                      hasSipWriteHandlingCE
                    )
                  )}
                >
                  <SIMTButton
                    id='siAcquisitionButton'
                    data-selenium-id='SIPPage_SIPDetailPage-Index-WiderMain2-AcquisitionButton'
                    variant='contained'
                    disabled={siAcquisitionDisabled}
                    onClick={() => {
                      checkProposerOK(sipCode).then(res => {
                        if (!res.data.data) {
                          setSIAcquisitionAlertBoxVisiable(true);
                          setSIAcquisitionDisabled(true);
                          setTimeout(() => {
                            setSIAcquisitionDisabled(false);
                          }, 2000);
                        } else {
                          if (sipCode)
                            navigate(
                              `/simt/auth/sipRelated/sip/transformToSI/${sipCode}`
                            );
                        }
                      });
                    }}
                    sx={{
                      width: '148px',
                      height: '38px',
                    }}
                  >
                    SI Acquisition
                  </SIMTButton>
                </Case>
              </PermissionListSwitch>

              <AlertBox
                open={siAcquisitionAlertBoxVisiable}
                handleClose={() => setSIAcquisitionAlertBoxVisiable(false)}
                title='You cannot do SI Acquisition yet'
                message="You need to verify and/or confirm the associated GE's new flag before making this decision"
                severity='warning'
                autoHideDuration={4000}
                sx={{
                  '&>div': {
                    pt: '16px',
                    pb: '16px',
                    '&>div:last-child': {
                      gap: '6px',
                    },
                  },
                }}
              />
            </Stack>
          ) : null}
        </Box>
      </WiderMain>
      {isShowDecisionPanel &&
        (showNewDecisionPanel(sipStatus.sipStage) ? (
          // Configurable Components designed by Sun, Elwin
          <DecisionPanel sipStage={sipStatus.sipStage} sipCode={sipCode} />
        ) : (
          // Old DecisionFold and DecisionUnfold will be deprecated soon
          <>
            {!sipStatusLoading && decisionIsFold && (
              <DecisionFold
                id='SIP-DecisionFold'
                data-selenium-id='SIP-DecisionFold'
                sipStage={sipStatus?.sipStage}
                panelDisplayed={panelDisplayed}
                setPanelDisplayed={setPanelDisplayed}
                isDecisionSaved={isDecisionSaved}
                isAssignScreenerSaved={isAssignScreenerSaved}
                isDecisionButtonDisabled={isDecisionButtonDisabled}
                isProceeded={isProceeded(SIPChecklistStatus)}
                isGetSIPChecklistDetailLoading={isGetSIPChecklistDetailLoading}
                showAssignToScreenerBtn={showAssignToScreenerBtn}
                showCheckListBtn={showCheckListBtn}
                showDecisionBtn={showDecisionBtn}
              ></DecisionFold>
            )}
            {/* To keep the data,I have to keep the component 'DecisionUnfold' */}
            <DecisionUnfold
              style={(sipStatusLoading || decisionIsFold) ? { display: 'none' } : {}}
              id='SIP-DecisionUnfold'
              data-selenium-id='SIP-DecisionUnfold'
              decisionIsFold={decisionIsFold}
              panelDisplayed={panelDisplayed}
              setPanelDisplayed={setPanelDisplayed}
              setDecisionIsFold={() => setPanelDisplayed(PANEL.DECISION_PANEL)}
              sipStage={sipStatus?.sipStage}
              sipFlow={sipStatus?.flow}
              sipCode={sipCode}
              openPop={open}
              isDecisionSaved={isDecisionSaved}
              isAssignScreenerSaved={isAssignScreenerSaved}
              setIsDecisionSaved={setIsDecisionSaved}
              setIsAssignScreenerSaved={setIsAssignScreenerSaved}
              isDecisionButtonDisabled={isDecisionButtonDisabled}
              setIsDecisionButtonDisabled={setIsDecisionButtonDisabled}
              showAssignToScreenerBtn={showAssignToScreenerBtn}
              showCheckListBtn={showCheckListBtn}
              showDecisionBtn={showDecisionBtn}
            ></DecisionUnfold>
          </>
        ))}

      {decisionIsFold && (
        <ScrollTop
          data-selenium-id='SIPPage_SIPDetailPage-Index-WiderMain2-ScrollTop'
          getWindow={() => document.getElementById('outer')}
          sx={
            isShowDecisionPanel ||
            (sipStatus &&
              sipStatus.sipStage ===
                intl.messages['sipDetail.sipStageType.completeAccept'] &&
              !sipStatus.siCode)
              ? { bottom: 200 }
              : { bottom: 120 }
          }
        >
          <Fab
            size='medium'
            variant='extended'
            color='primary'
            aria-label='scroll back to top'
            disableFocusRipple
            disableRipple
            id={'BackToTopButton'}
            data-selenium-id={
              'SIPPage_SIPDetailPage-Index-WiderMain2-BackToTopButton'
            }
            sx={{
              backgroundColor: '#0052CC',
              borderRadius: '30px',
              [`&.${fabClasses.root}`]: {
                textTransform: 'none',
                boxShadow: 'none',
                fontWeight: 400,
              },
              '&:hover': {
                backgroundColor: '#0052CC',
              },
            }}
          >
            Back To Top &nbsp;
            <BackToTop data-selenium-id='SIPPage_SIPDetailPage-Index-WiderMain2-ScrollTop-BackToTop' />
          </Fab>
        </ScrollTop>
      )}
      <UntitledAlertBox
        open={createSuccessAlert}
        handleClose={() => {
          dispatch(setCreateSuccessAlert(false));
        }}
        message='Successfully create a new Special Issue Proposal!'
        severity='success'
      />
    </Box>
  );
}

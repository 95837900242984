import { Box, IconButton, Stack, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import LabelAndValue from '@/components/LabelAndValue';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import EditSIDetailnewIcon from '@/assets/EditSIDetailnewIcon.svg';
import EditIconButton from '@/componentsphase2/Button/EditIconButton';
import styleModule from '../GEInformationBlock.module.scss';
import {getObject} from "@/actions/SIMT-SIP/SIP/SipDetail";

export default function ObjectBlock(props) {
  const { setIsObjectEdit, Editable = true, listInOneColumn,sipType } = props;

  const [isFold, setIsFold] = useState(true);
  const [leftInfo, setLeftInfo] = useState([]);
  const [rightInfo, setRightInfo] = useState([]);

  const leftKeys = sipType==='Yes'?['journal1Code','journal1Name']: ['preferredSubjectArea', 'choiceJournal1st'];

  const rightKeys = sipType==='Yes'?['journal1HomePage','journal1AuthorGuidelines']: ['choiceJournal2nd', 'choiceJournal3rd'];
  const dispatch = useDispatch();
  const { sipCode } = useParams();
  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      object: state.SIP.object,
    };
  };
  const { open, object } = useSelector(selector);

  useEffect(() => {
    if (object) {
      let tmpLeft = [];
      let tmpRight = [];
      for (const leftKey of leftKeys) {
        const val = handleFieldsReplacement(leftKey, object[leftKey]);
        tmpLeft.push(val);
      }
      for (const rightKey of rightKeys) {
        const val = handleFieldsReplacement(rightKey, object[rightKey]);
        tmpRight.push(val);
      }
      setLeftInfo(tmpLeft);
      setRightInfo(tmpRight);
    }
  }, [object, sipType]);

  const leftIsUrl = [false, false];

  const rightIsUrl = [false, false];

  const showBox = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    overflow: 'hidden',
  };

  const handleFieldsReplacement = (key, val) => {
    if (key === 'preferredSubjectArea') {
      return val;
    } else {
      if (val) {
        return val;
      } else {
        return 'N/A';
      }
    }
  };

  const generateLabels = keys => {
    let labels = [];
    for (const key of keys) {
      labels.push(
        <div>
          <FormattedMessage
            id={'sipDetail.' + key}
            data-selenium-id={`SIP_DetailPage_ObjectBlock-${
              'sipDetail.' + key
            }`}
          />
          :
        </div>
      );
    }
    return labels;
  };

  return (
    <Box data-selenium-id='SIP_DetailPage_ObjectBlock-Box'>
      <Box
        sx={{ mb: '16px', mt: '20px',cursor:'pointer' }}
        className='sipBlockTitleBox'
        data-selenium-id='SIP_DetailPage_ObjectBlock-TitleBox'
      >
        <Box
          data-selenium-id='SIP_DetailPage_ObjectBlock-TitleFoldBtnBox'
          class='titleFoldButton'
          onClick={async () => {
            await dispatch(getObject(sipCode));
            setIsFold(!isFold);
          }}
        >
          {isFold ? (
            <MarketChevronUp data-selenium-id='SIP_DetailPage_ObjectBlock-MarketChevronUp' />
          ) : (
            <SIDetailArrowDown />
          )}
        </Box>
        <Stack
          id='titleBoxLeft'
          data-selenium-id='SIP_DetailPage_ObjectBlock-TitleBoxLeft'
          spacing={1}
          direction='row'
          sx={{cursor:'default'}}
        >
          <Box>
            <FormattedMessage
              id='sipDetail.object'
              data-selenium-id='SIP_DetailPage_ObjectBlock-Box-Object'
            />
          </Box>
          {Editable ? (
            // <IconButton
            //   id='sipDetailObjectEditButton'
            //   data-selenium-id='SIP_DetailPage_ObjectBlock-ObjectEditButton'
            //   onClick={() => {
            //     setIsObjectEdit(true);
            //   }}
            // >
            //   <EditSIDetailnewIcon data-selenium-id='SIP_DetailPage_ObjectBlock-EditSInewIcon' />
            // </IconButton>
            <EditIconButton
              id='sipDetailObjectEditButton'
              data-selenium-id='SIP_DetailPage_ObjectBlock-ObjectEditButton'
              onClick={() => {
                setIsObjectEdit(true);
              }}
              className={styleModule.ButtonMarginLeft0}
            ></EditIconButton>
          ) : (
            <></>
          )}
        </Stack>
      </Box>
      {isFold ? (
        <Grid
          data-selenium-id='SIP_DetailPage_ObjectBlock-Grid'
          container
          columnSpacing={listInOneColumn ? 6 : 0}
          rowSpacing={listInOneColumn ? 0 : 1}
          sx={{ width: '100%', }}
        >
          <Grid
            item
            xs={listInOneColumn ? 6 : 12}
            data-selenium-id='SIP_DetailPage_ObjectBlock-InnerGrid1'
          >
            <LabelAndValue
              id={'sipObjectLeftInfo'}
              data-selenium-id={'SIP_DetailPage_ObjectBlock-LeftInfo'}
              labels={generateLabels(leftKeys)}
              value={leftInfo}
              open={open}
              isUrl={leftIsUrl}
              widthList={['190px', '357px', '220px', '410px']}
            />
          </Grid>
          <Grid
            item
            xs={listInOneColumn ? 6 : 12}
            data-selenium-id='SIP_DetailPage_ObjectBlock-InnerGrid2'
          >
            <LabelAndValue
              id={'sipObjectRightInfo'}
              data-selenium-id={'SIP_DetailPage_ObjectBlock-RightInfo'}
              labels={generateLabels(rightKeys)}
              value={rightInfo}
              open={open}
              isUrl={rightIsUrl}
              widthList={['190px', '357px', '220px', '410px']}
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </Box>
  );
}

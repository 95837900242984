import { exportAIScreeningReport } from '@/actions/SIPAIScreeningReportAction';
import { Box } from '@mui/material';
import { StrictMode, useEffect, useMemo, type HTMLAttributes } from 'react';
import ExportButton, { delay500 } from '@/componentsphase2/ExportButton';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { UntitledAlertBox } from '@/components/Alert/AlertBox';
import styleModule from './AIScreeningReportPage.module.scss';
import PropTypes from 'prop-types';

import {getSIPInformation} from "@/actions/SIMT-SIP/SIP/SipDetail";
interface AIScreeningReportTitleProps extends HTMLAttributes<HTMLElement> {
  readonly sipCode: string;
}
export default function AIScreeningReportTitle (props: AIScreeningReportTitleProps) {
  const { sipCode, ...others } = props;
  const lineHeight = '41px';
  const dispatch = useDispatch();
  const initAlertProps = {
    open: false,
  };
  const [alertProps, setAlertProps] = useState<{
    readonly open: boolean;
    readonly severity?: 'success' | 'info' | 'warning' | 'error';
    readonly message?: string;
  }>(initAlertProps);
  const id = useMemo(() => 'SIPPage_AIScreeningReportPage-', []);
  const sipTitle = useSelector<{
    readonly SIP: {
      readonly sipInformation: {
        readonly sipTitle: string;
      };
    };
  }, string>(state => state?.SIP?.sipInformation?.sipTitle);
  useEffect(() => {
    dispatch(getSIPInformation(sipCode));
  }, []);
  return (
    <StrictMode>
      <Box
        id={`${id}Index-WiderMain1-InnerBox`}
        data-selenium-id={`${id}Index-WiderMain1-InnerBox`}
        sx={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '24px',
          lineHeight: lineHeight,
          // maxWidth: 'calc(100% - 288px )',
          width: 'auto',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
          wordBreak: 'keep-all'
        }}
        {...others}
      >
        {sipTitle}
      </Box>
      <ExportButton
        fetchData={() => 
          new Promise<void>((resolve, reject) => {
            dispatch(exportAIScreeningReport(sipCode, resolve, reject));
          }).then(async () => {
            await delay500();
            setAlertProps({
              open: true,
              severity: 'success',
              message: 'The report download is now complete!',
            });
          }).catch(async (err) => {
            await delay500();
            setAlertProps({
              open: true,
              severity: 'error',
              message: 'The report download failed!',
            });
            console.error(err);
          })}
        otherSX={{
          height: lineHeight,
          minWidth: '260px',
          lineHeight: '17px',
          fontSize: '14px',
          '&.Mui-disabled': {
            color: '#FFF'
          }
        }}
        id={`${id}exportAIScreeningReportButton`}
        data-selenium-id={`${id}exportAIScreeningReportButton`}
      >
        Download Report (Word Doc.)
      </ExportButton>
      <UntitledAlertBox
        id={`${id}UntitledAlertBox`}
        data-selenium-id={`${id}UntitledAlertBox`}
        handleClose={() => setAlertProps({ ...initAlertProps, severity: alertProps.severity })}
        className={styleModule.UntitledAlertBox}
        {...alertProps}
      ></UntitledAlertBox>
    </StrictMode>
  );
}
const { freeze } = Object;
AIScreeningReportTitle.propTypes = freeze({
  sipCode: PropTypes.string.isRequired,
});
AIScreeningReportTitle.defaultProps = freeze({
  sipCode: '',
});
import { GET_CONTENT_COMMISSIONING_APPROACHES } from '@/actions/SIMT-SIP/SIP/SIPAction';
import { SectionContainer } from '@/pages/SIPPage/RevisePage/CustomComponents/CustomContainer';
import { ContentCommissioningApproachesShowSectionCore } from '@/pages/SIPPage/RevisePage/Section/ContentCommissioningApproachesSection';
import { ItemBlock } from '@/pages/SIPPage/SIPDetailPage/EditComponents';
import { AcquiredSiCreationHeading } from '@/pages/SIPPage/TransformToSI/CustomComponent/Typography';
import { scrollToTop } from '@/utils/commonUtils';
import { Collapse, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom';
import {getNewSiMarketinfo, getSipReferenceData} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueDetail";

/**
 * @param {import("./SipReferenceSection").SipReferenceSectionProps} props
 * @returns
 */
const SipReferenceSection = props => {
  const { siCode } = props;

  const { contentCommissioningApproaches, sipStatus, detailInfo } = useSelector(
    state => ({
      /** @type {import("@/pages/SIPPage/RevisePage").CommissionApproaches | null | "pending" | "error"} */
      // @ts-ignore
      contentCommissioningApproaches: state.SIP.contentCommissioningApproaches,
      /** @type {{sipCode: string | null}} */
      // @ts-ignore
      sipStatus: state.SIP.sipStatus,
      /** @type {import("../market-section").MarketSectionDetailInfo} */
      // @ts-ignore
      detailInfo: state.SI.detailInfo,
    })
  );

  const dispatch = useDispatch();

  const [hasSipReference, setHasSipReference] = useState(false);

  const [
    localContentCommissioningApproaches,
    setLocalContentCommissioningApproaches,
  ] = useState(
    /** @type {import("@/pages/SIPPage/RevisePage").CommissionApproaches} */ ({
      openCallForPapers: '0',
      potentialAuthors: [],
    })
  );
  const [sipCode, setSipCode] = useState('');
  const [siDetailInfo, setSiDetailInfo] = useState(
    /** @type {import("../market-section").MarketSectionDetailInfo | null} */ (
      null
    )
  );

  useEffect(() => {
    dispatch({
      type: GET_CONTENT_COMMISSIONING_APPROACHES,
      data: 'pending',
    });
    dispatch(getSipReferenceData(siCode));
  }, []);

  useEffect(() => {
    if (
      contentCommissioningApproaches === 'pending' ||
      contentCommissioningApproaches === null
    )
      return;

    if (contentCommissioningApproaches !== 'error') {
      setLocalContentCommissioningApproaches(contentCommissioningApproaches);
      if (sipStatus.sipCode) setSipCode(sipStatus.sipCode);
      setHasSipReference(true);
    }
    dispatch({
      type: GET_CONTENT_COMMISSIONING_APPROACHES,
      data: null,
    });
  }, [contentCommissioningApproaches]);

  // useEffect(() => {
  //   console.log("[SipReferenceSection] detailInfo = ", detailInfo);
  //   if (detailInfo.siMarketing !== undefined) {
  //     if (detailInfo.siMarketing.length === 0) {
  //       setSiDetailInfo(detailInfo);
  //     }
  //   }
  // }, [detailInfo]);

  useEffect(() => {
    if (siDetailInfo === null || sipCode === '') return;
    if (siDetailInfo.siMarketing.length !== 0) return;
    // dispatch(getNewSiMarketinfo(sipCode, siDetailInfo));
  }, [siDetailInfo, sipCode]);

  const navigate = useNavigate();

  return (
    <Collapse
      in={hasSipReference}
      data-selenium-id='SIDetail_SipReferenceSection-Collapse'
    >
      <SectionContainer
        title='SIP Reference'
        paddingTop={'32px'}
        defaultFold={true}
        data-selenium-id='SIDetail_SipReferenceSection-Container'
      >
        <ItemBlock
          title='SIP code'
          marginY={'16px'}
          data-selenium-id='SIDetail_SipReferenceSection-ItemBlock'
        >
          <Link
            sx={{
              fontSize: '14px',
              color: '#154AB6',
              textDecoration: 'none',
            }}
            component={ReactRouterLink}
            to={`/simt/auth/sipRelated/sip/detail/${sipCode}`}
            onClick={() => {
              scrollToTop();
            }}
            data-selenium-id='SIDetail_SipReferenceSection-Link'
          >
            {sipCode}
          </Link>
        </ItemBlock>
        <AcquiredSiCreationHeading
          variant='secondary'
          marginBottom={'16px'}
          data-selenium-id='SIDetail_SipReferenceSection-AcquiredSiCreationHeading'
        >
          Content Commissioning Approaches
        </AcquiredSiCreationHeading>
        <ContentCommissioningApproachesShowSectionCore
          value={localContentCommissioningApproaches}
          emptyTip={true}
          data-selenium-id='SIDetail_SipReferenceSection-ContentCommissioningApproachesShowSectionCore'
        />
      </SectionContainer>
    </Collapse>
  );
};

export default SipReferenceSection;

// @ts-check
import React, { useEffect, useState } from 'react';
import { Box, Stack, Collapse, IconButton, Typography } from '@mui/material';
import ReadTable from '../../SIPDetailPage/Submodule/ReadTable';
import { SectionTitleBlock } from '../CustomComponents/CustomBlock';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import GEInfoAdd from '@/assets/GEInfoAdd.svg';
// @ts-ignore
import ProfileWebsite from '@/assets/ProfileWebsite.svg';
// @ts-ignore
import CVDownload from '@/assets/CVDownload.svg';
// @ts-ignore
import SIPActionsView from '@/assets/SIPActionsView.svg';
// @ts-ignore
import SIPActionsDelete from '@/assets/SIPActionsDelete.svg';
// @ts-ignore
import SIPAuthorEdit from '@/assets/SIPAuthorEdit.svg';
import {
  GEInformationEditModal,
  GEInformationShowModal,
} from '../CustomComponents/CustomModal';
import { geInformationInitialValue } from '../CommonInitialValue';
import {
  ADD_CV_TO_UPDATE,


} from '../../../../actions/SIMT-SIP/SIP/SIPAction';
import { useParams } from 'react-router-dom';
import { sleep } from '../../../../utils/commonUtils';
import { SectionContainer } from '../CustomComponents/CustomContainer';
import {DeleteCV, downloadCv} from "@/actions/SIMT-SIP/SIP/SipDetail";

/** @type {import("./GEInformationSection").CreateRowFunction} */
export const createRow = (
  id,
  label,
  minWidth,
  maxWidth,
  isOrder = true,
  align = 'left'
) => {
  return { id, label, minWidth, maxWidth, isOrder, align };
};

const tableHeads = [
  createRow('leadGe', 'LEAD GE', '108px', '108px', false, 'center'),
  createRow('jobTitle', 'JOB TITLE', '128px', '248px', false),
  createRow('fullName', 'FULL Name', '128px', '248px', false),
  createRow(
    'emailAddress',
    'EMAIL ADDRESS',
    '128px',
    '400px',
    false,
    'center'
  ),
  createRow('country', 'COUNTRY/REGION', '128px', '248px', false),
  createRow(
    'profileWebsite',
    'PROFILE WEBSITE',
    '168px',
    '400px',
    false,
    'center'
  ),
  createRow('cv', 'CV', '120px', '120px', false, 'center'),
  createRow('actions', 'ACTIONS', '160px', 'auto', false, 'center'),
];
/**
 *
 * @param {import("./GEInformationSection").GEInformationEditSectionProps} props
 * @returns
 */
export const GEInformationEditSection = props => {
  const {
    value: geInformationList,
    setValue: setGeInformationList,
    showErrorMessage,
    setError = () => {},
    isSSIP=false,
  } = props;

  const [geInformationSectionError, setGeInformationSectionError] =
    useState(false);
  const [geInformationSectionErrorText, setGeInformationSectionErrorText] =
    useState('');

  useEffect(() => {
    setError(geInformationSectionError);
  }, [geInformationSectionError]);

  const dispatch = useDispatch();
  const { token } = useParams();

  // geInformationList 和 tableData 的区别在于
  // geInformationList 需要同步将内容转换成真正需要渲染的 ReactNode 节点
  // 任何数据的更新都在 geInformationList 进行
  const [tableData, setTableData] = useState(
    /** @type {import("../../SIPDetailPage/Submodule/ReadTable").TableDataItem[]} */ ([])
  );

  useEffect(() => {
    const numberOfLeadGeError =
      geInformationList.filter(item => item.leadGe).length < 1;
    const arr = geInformationList.map(item => item.emailAddress);
    const emailRepeatError =
      arr.filter((item, index) => arr.indexOf(item) != index).length > 0;
    setGeInformationSectionError((numberOfLeadGeError && !isSSIP) || emailRepeatError);
    if (numberOfLeadGeError && !isSSIP) {
      setGeInformationSectionErrorText(
        'There must be at least one Lead Guest Editor per SIP.'
      );
    } else if (emailRepeatError) {
      setGeInformationSectionErrorText('There are repeat email addresses.');
    } else {
      setGeInformationSectionErrorText('');
    }

    setTableData(
      geInformationList.map((item, index) => {
        return {
          leadGe: item.leadGe ? 'Yes' : 'No',
          jobTitle: item.jobTitle,
          fullName: (
            <Box
              data-selenium-id='SIP_RevisePage_Section-GEInformation-Edit-Box'
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >{`${item.firstName}, ${item.lastName}`}</Box>
          ),
          emailAddress: item.emailAddress,
          country: item.country,
          profileWebsite: item.profileWebsite ? (
            <IconButton
              data-selenium-id='SIP_RevisePage_Section-GEInformation-Edit-IconButton1'
              onClick={() => {
                if (item.profileWebsite === null) return;
                var prefix = 'https://';
                let url = item.profileWebsite;
                if (url.substr(0, prefix.length) !== prefix) {
                  url = prefix + url;
                }
                window.open(url, '_blank');
              }}
              disableFocusRipple={isSSIP}
              disableRipple={isSSIP}
            >
              <ProfileWebsite data-selenium-id='SIP_RevisePage_Section-GEInformation-Edit-ProfileWebsite' />
            </IconButton>
          ) : (
            'N/A'
          ),
          cv: item.cv ? (
            <IconButton
              data-selenium-id='SIP_RevisePage_Section-GEInformation-Edit-IconButton2'
              onClick={() => {
                if (item.cv === null) return;
                var index = item.cv.indexOf(':');
                var filename = item.cv.substring(index + 1);
                dispatch(
                  downloadCv(filename.split(';size:')[0], `/api/v1/sip-service/special-issue-proposals/revise/${token}/guest-editors/cv`, {}, true)
                );
              }}
            >
              <CVDownload data-selenium-id='SIP_RevisePage_Section-GEInformation-Edit-CVDownload' />
            </IconButton>
          ) : (
            'N/A'
          ),
          actions: (
            <Stack
              direction='row'
              justifyContent={'center'}
              data-selenium-id='SIP_RevisePage_Section-GEInformation-Edit-Stack'
            >
              <IconButton
                data-selenium-id='SIP_RevisePage_Section-GEInformation-Edit-Stack-IconButton1'
                onClick={() => {
                  setCurrentEditRowIndex(index);
                  setGeInformationModalInitialValue(item);
                  setGeInformationModalOpen(true);
                }}
                disableFocusRipple={isSSIP}
                disableRipple={isSSIP}
                sx={{paddingLeft:'0px'}}
              >
                <SIPAuthorEdit data-selenium-id='SIP_RevisePage_Section-GEInformation-Edit-Stack-Author' />
              </IconButton>
              <IconButton
                onClick={() => handleDeleteGE(index)}
                disableFocusRipple={isSSIP}
                disableRipple={isSSIP}
                data-selenium-id='SIP_RevisePage_Section-GEInformation-Edit-Stack-IconButton2'
              >
                <SIPActionsDelete data-selenium-id='SIP_RevisePage_Section-GEInformation-Edit-Stack-ActionDelete' />
              </IconButton>
            </Stack>
          ),
        };
      })
    );
    if (geInformationModalOpen) {
      setGeInformationModalInitialValue(geInformationList[currentEditRowIndex]);
    }
  }, [geInformationList]);

  /**
   *
   * @param {number} rowIndex
   */
  const handleDeleteGE = async rowIndex => {
    setGeInformationList(current =>
      current.filter((_, index) => index !== rowIndex)
    );
  };

  const [geInformationModalOpen, setGeInformationModalOpen] = useState(false);
  const [geInformationModalInitialValue, setGeInformationModalInitialValue] =
    useState(geInformationInitialValue);
  const [currentEditRowIndex, setCurrentEditRowIndex] = useState(-1);

  return (
    <Box data-selenium-id='SIP_RevisePage_Section-GEInformation-HandleDeleteGE-Box'>
      <SectionContainer
        data-selenium-id='SIP_RevisePage_Section-GEInformation-HandleDeleteGE-Container'
        title='GE Information'
        titleBlockProps={{
          endAdornment: (
            <IconButton
              data-selenium-id='SIP_RevisePage_Section-GEInformation-HandleDeleteGE-IconButton'
              onClick={() => {
                setCurrentEditRowIndex(-1);
                setGeInformationModalInitialValue(geInformationInitialValue);
                setGeInformationModalOpen(true);
              }}
            >
              <GEInfoAdd data-selenium-id='SIP_RevisePage_Section-GEInformation-HandleDeleteGE-GEInfoAdd' />
            </IconButton>
          ),
        }}
      >
        <Collapse
          in={geInformationSectionError && showErrorMessage}
          data-selenium-id='SIP_RevisePage_Section-GEInformation-HandleDeleteGE-Collapse'
        >
          <Typography
            data-selenium-id='SIP_RevisePage_Section-GEInformation-HandleDeleteGE-Typography'
            sx={{
              color: '#C40000',
              paddingBottom: '16px',
              fontSize: '14px',
            }}
          >
            {geInformationSectionErrorText}
          </Typography>
        </Collapse>
        <Box
          maxWidth={'1243px'}
          data-selenium-id='SIP_RevisePage_Section-GEInformation-HandleDeleteGE-Container-InnerBox'
        >
          <ReadTable
            id='SIPReviseGEInformationTable'
            data-selenium-id='SIP_RevisePage_Section-GEInformation-HandleDeleteGE-Table'
            TableHeads={tableHeads}
            TableData={tableData}
          />
        </Box>
      </SectionContainer>
      <GEInformationEditModal
        data-selenium-id='SIP_RevisePage_Section-GEInformation-HandleDeleteGE-EditModal'
        open={geInformationModalOpen}
        handleClose={() => {
          setGeInformationModalOpen(false);
        }}
        geInformationInitialValue={geInformationModalInitialValue}
        handleSaveGEInformation={(rowIndex, newValue) => {
          if (rowIndex < 0) {
            setGeInformationList(current => [...current, newValue]);
          } else {
            setGeInformationList(current =>
              current.map((item, index) => {
                if (index !== rowIndex) return item;
                return newValue;
              })
            );
          }
          setGeInformationModalOpen(false);
        }}
        rowIndex={currentEditRowIndex}
      />
    </Box>
  );
};

/**
 *
 * @param {import("./GEInformationSection").GEInformationShowSectionProps} props
 * @returns
 */
export const GEInformationShowSection = props => {
  const { value: geInformationList } = props;

  const dispatch = useDispatch();
  const { token } = useParams();

  // 这里的 tableData 与 edit 的作用类似
  const [tableData, setTableData] = useState(
    /** @type {import("../../SIPDetailPage/Submodule/ReadTable").TableDataItem[]} */ ([])
  );
  const [geInformationModalOpen, setGeInformationModalOpen] = useState(false);
  const [geInformationModalValue, setGeInformationModalValue] = useState(
    geInformationInitialValue
  );

  useEffect(() => {
    setTableData(
      geInformationList.map((item, index) => {
        return {
          leadGe: item.leadGe ? 'Yes' : 'No',
          jobTitle: item.jobTitle,
          fullName: `${item.firstName}, ${item.lastName}`,
          emailAddress: item.emailAddress,
          country: item.country,
          profileWebsite: item.profileWebsite ? (
            <IconButton
              data-selenium-id='SIP_RevisePage_Section-GEInformation-Show-IconButton1'
              onClick={() => {
                if (item.profileWebsite === null) return;
                var prefix = 'http://';
                let url = item.profileWebsite;
                if (url.substr(0, prefix.length) !== prefix) {
                  url = prefix + url;
                }
                window.open(url, '_blank');
              }}
            >
              <ProfileWebsite data-selenium-id='SIP_RevisePage_Section-GEInformation-Show-ProfileWebsite' />
            </IconButton>
          ) : (
            'N/A'
          ),
          cv: item.cv ? (
            <IconButton
              data-selenium-id='SIP_RevisePage_Section-GEInformation-Show-IconButton2'
              onClick={() => {
                if (item.cv === null) return;
                var index = item.cv.indexOf(':');
                var filename = item.cv.substring(index + 1);
                dispatch(
                  downloadCv(filename.split(';size:')[0], `/api/v1/sip-service/special-issue-proposals/revise/${token}/guest-editors/cv`, {}, true)
                );
              }}
            >
              <CVDownload data-selenium-id='SIP_RevisePage_Section-GEInformation-Show-CVDownload' />
            </IconButton>
          ) : (
            'N/A'
          ),
          actions: (
            <Stack
              direction='row'
              justifyContent={'center'}
              data-selenium-id='SIP_RevisePage_Section-GEInformation-Show-ActionsStack'
            >
              <IconButton
                data-selenium-id='SIP_RevisePage_Section-GEInformation-Show-ActionsIconButton'
                onClick={() => {
                  setGeInformationModalValue(geInformationList[index]);
                  setGeInformationModalOpen(true);
                }}
              >
                <SIPActionsView data-selenium-id='SIP_RevisePage_Section-GEInformation-Show-SIPActionsView' />
              </IconButton>
            </Stack>
          ),
        };
      })
    );
  }, [geInformationList]);

  return (
    <SectionContainer
      title='GE Information'
      data-selenium-id='SIP_RevisePage_Section-GEInformation-Show-Container'
    >
      <Box
        maxWidth={'1243px'}
        data-selenium-id='SIP_RevisePage_Section-GEInformation-Show-Box'
      >
        <ReadTable
          id='SIPReviseGEInformationTable'
          data-selenium-id='SIP_RevisePage_Section-GEInformation-Show-Table'
          TableHeads={tableHeads}
          TableData={tableData}
        />
        <GEInformationShowModal
          data-selenium-id='SIP_RevisePage_Section-GEInformation-Show-Modal'
          open={geInformationModalOpen}
          handleClose={() => setGeInformationModalOpen(false)}
          geInformationValue={geInformationModalValue}
        />
      </Box>
    </SectionContainer>
  );
};

// create Redux action types
export const POPUP_OPEN = 'POPUP_OPEN';
export const POPUP_CLOSE = 'POPUP_CLOSE';
export const POPUP_CLEAR = 'POPUP_CLEAR';
export const SUPER_POPUP_CONTROL = 'SUPER_POPUP_CONTROL';
export const SET_SUPER_POPUP_DATA = 'SET_SUPER_POPUP_DATA';

// Create Redux action creators that return an action
export const openPop = () => ({
  type: POPUP_OPEN,
});

export const closePop = () => ({
  type: POPUP_CLOSE,
});

export const popClear = () => ({
  type: POPUP_CLEAR,
});

export const setSuperAdminPop = type => ({
  type: SUPER_POPUP_CONTROL,
  data: type,
});

export const setSuperAdminPopData = data => ({
  type: SET_SUPER_POPUP_DATA,
  data: data,
});

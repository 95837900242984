import './index.css';
import {
  Box,
  Button,
  Grid,
  Checkbox,
  List,
  Paper,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Main from '@/components/Main';
import { setPermission } from '@/actions/SIMT-User/Role/RoleManageAction';
import AlertDialog from '@/modules/AlertDialog';
import MyTextField from '@/components/MyTextField';
import { createHashHistory } from 'history';
import {roleNameIsValid} from "@/actions/SIMT-User/Role";

function not (a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection (a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

export function sortPermissions(perm1, perm2) {
  return perm1.permissionName < perm2.permissionName ? -1 : 1;
}

function BasicPage (props) {
  const {
    open,
    roleNameDuplicated,
    left,
    right,
    checked,
    originalLeft,
    originalRight,
  } = useSelector(state => {
    return {
      open: state.UserManage.siderOpen,
      roleNameDuplicated: !state.Role.roleNameValid,
      left: state.Role.left,
      right: state.Role.right,
      checked: state.Role.checked,
      originalLeft: state.Role.originalLeft,
      originalRight: state.Role.originalRight,
    };
  });
  const [roleName, setRoleName] = useState(props.RoleName);
  const readonly = props.readonly;
  const originalRoleName = props.RoleName;
  const history = createHashHistory();

  const dispatch = useDispatch();
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const [dialogOpen, setDialogOpen] = useState(false); // confirmation dialog
  const [dialogType, setDialogType] = useState();
  const [roleErr, setRoleErr] = useState(false);
  const [roleNameInvalid, setRoleNameInvalid] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const [widthpx, setWidthpx] = useState('1195px');

  let pattern = new RegExp('[%_《》+-]');

  let width = document.body.clientWidth;
  /**
   * @author LiuXin
   * @date 2022/5/24
   * @description 实时监控浏览器窗口大小变化，在过小时自适应屏幕，在过大时固定显示尺寸
   */
  window.onresize = function () {
    resetMainBoxWidth();
  };

  useEffect(() => {
    resetMainBoxWidth();
  }, [open]);

  /**
   * @author LiuXin
   * @date 2022/5/24
   * @description 监控当前窗口大小，在过小时自适应屏幕，在过大时固定显示尺寸
   */
  const resetMainBoxWidth = () => {
    width = document.body.clientWidth;
    if (open === true) {
      width = width - 266;
    }
    if (width < 1215) {
      setWidthpx(width - 20 + 'px');
    } else {
      setWidthpx('1195px');
    }
  };

  useEffect(() => {
    async function fetchData () {
      if (props.ActionBtn === 'UPDATE') {
        await dispatch(roleNameIsValid(roleName, 'Update', originalRoleName));
      } else {
        await dispatch(roleNameIsValid(roleName, 'Add'));
      }
    }
    fetchData();
    if (roleName.match('管理员')) {
      setRoleNameInvalid(true);
    } else {
      setRoleNameInvalid(false);
    }
  }, [roleName]);

  const handleRoleNameChange = value => {
    setRoleName(value);
    setIsUpdated(true);
  };

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    dispatch(setPermission('checked', newChecked));
  };

  const handleAllRight = () => {
    dispatch(setPermission('right', right.concat(left)));
    dispatch(setPermission('left', []));
  };

  const handleAllLeft = () => {
    dispatch(setPermission('left', right.concat(left)));
    dispatch(setPermission('right', []));
  };

  const handleCheckedRight = () => {
    dispatch(setPermission('right', right.concat(leftChecked)));
    dispatch(setPermission('left', not(left, leftChecked)));
    dispatch(setPermission('checked', not(checked, leftChecked)));
  };

  const handleCheckedLeft = () => {
    dispatch(setPermission('left', left.concat(rightChecked)));
    dispatch(setPermission('right', not(right, rightChecked)));
    dispatch(setPermission('checked', not(checked, rightChecked)));
  };

  //处理RESET按钮的点击逻辑
  const handleReset = () => {
    dispatch(setPermission('left', originalLeft));
    dispatch(setPermission('right', originalRight));
    setRoleName(originalRoleName);
    dispatch(setPermission('checked', []));
  };

  //处理BACK按钮的逻辑
  const handleBack = () => {
    dispatch(setPermission('checked', []));
    if (props.ActionBtn === 'UPDATE') {
      if (
        JSON.stringify(originalLeft.sort(sortPermissions)) !== JSON.stringify(left.sort(sortPermissions)) ||
        originalRoleName !== roleName
      ) {
        setDialogType('unsavedUpdateRole');
        setDialogOpen(true);
      } else {
        history.back();
      }
    } else {
      history.back();
    }
  };

  const handleDialogOpen = () => {
    if (props.ActionBtn === 'UPDATE') {
      setDialogType('updateRole');
    } else if (props.ActionBtn === 'CREATE') {
      setDialogType('createRole');
    }
    setRoleName(roleName.replace(/(^\s*)|(\s*$)/g, ''));
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const helperText = () => {
    if (roleNameDuplicated) {
      return 'Role name duplicates. Please change to another one.';
    } else if (roleNameInvalid) {
      return 'Contains keywords. Please change to another one.';
    } else {
      return '';
    }
  };

  const customList = permissions => (
    <Paper
      id='AuthPaper'
      data-selenium-id='AuthPaper'
      sx={{
        width: '100%',
        height: 355,
        overflow: 'auto',
        border: 0,
      }}
    >
      <List dense component='div' role='list'>
        {permissions.map((permission, index) => {
          const labelId = `transfer-list-item-${permission.permissionCode}-label`;
          return (
            <ListItem
              key={permission.permissionCode}
              role='listitem'
              button
              onClick={handleToggle(permission)}
              disabled={readonly}
            >
              <ListItemIcon>
                <Checkbox
                  id={`systemAdminActionsToRolePageCheckbox${index}`}
                  data-selenium-id={`SystemAdmin-ActionsToRolePageCheckbox-${index}`}
                  checked={checked.indexOf(permission) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                data-selenium-id={labelId}
                primary={
                  <Typography
                    sx={{
                      fontSize: '20px',
                      lineHeight: '27px',
                      color: '#000000',
                    }}
                  >
                    {permission.permissionName}
                  </Typography>
                }
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Box
      id='roleManagementBox'
      data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox'
      sx={{
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Main
        id='roleManagementMain'
        data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain'
        sx={{
          width: widthpx,
          background: '#ffffff',
          border: '1px solid #dddddd',
          boxSizing: 'border-box',
          borderRadius: '4px',
        }}
        open={open}
      >
        <Box
          id='roleManagementTitleBox'
          data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-TitleBox'
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '1.5rem',
            fontWeight: '600',
            fontStyle: 'normal',
            lineHeight: '3rem',
            color: '#007EB6',
          }}
        >
          {props.PageTitle}
        </Box>
        <Box
          id='roleManagementInputBox'
          data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox'
          sx={{
            marginTop: '40px',
            marginLeft: '5%',
            height: '81px',
          }}
        >
          <MyTextField
            id='systemAdminActionsToRolePageRoleInput'
            data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-MyTextField-RoleInput'
            label='Role Name'
            variant='filled'
            value={roleName}
            setValue={handleRoleNameChange}
            limitLength={40}
            error={
              ((isUpdated && roleNameDuplicated) || roleNameInvalid) &&
              !readonly
            }
            helperText={helperText()}
            exSpecialChar
            setSCErr={setRoleErr}
            disabled={readonly}
            moreSpecialCheck={pattern}
          />
        </Box>
        <Grid
          container
          spacing={3}
          direction='row'
          justifyContent='center'
          alignItems='center'
          id='roleManagementGrid'
          data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid1'
        >
          <Grid
            item
            xs={4}
            id='roleManagementLeftSortGrid'
            data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid1-Grid1'
          >
            {customList(left.sort(sortPermissions))}
          </Grid>
          <Grid
            item
            xs={2}
            id='roleManagementGrid'
            data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid1-Grid2'
          >
            <Grid
              container
              data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid1-Grid2-Grid'
              direction='column'
              alignItems='center'
            >
              <Button
                id='systemAdminActionsToRolePageMoveAllToRightButton'
                data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid1-Grid2-Grid-MoveAllToRightButton'
                color='lightBlue'
                variant='outlined'
                size='small'
                onClick={handleAllRight}
                disabled={left.length === 0 || readonly}
                aria-label='move all right'
              >
                ≫
              </Button>
              <Button
                id='systemAdminActionsToRolePageMoveSelectedToRightButton'
                data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid1-Grid2-Grid-SelectedToRightButton'
                color='lightBlue'
                variant='outlined'
                size='small'
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0 || readonly}
                aria-label='move selected right'
              >
                &gt;
              </Button>
              <Button
                id='systemAdminActionsToRolePageMoveSelectedToLeftButton'
                data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid1-Grid2-Grid-SelectedToLeftButton'
                color='lightBlue'
                variant='outlined'
                size='small'
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0 || readonly}
                aria-label='move selected left'
              >
                &lt;
              </Button>
              <Button
                id='systemAdminActionsToRolePageMoveAllToLeftButton'
                data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid1-Grid2-Grid-MoveAllToLeftButton'
                color='lightBlue'
                variant='outlined'
                size='small'
                onClick={handleAllLeft}
                disabled={right.length === 0 || readonly}
                aria-label='move all left'
              >
                ≪
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            id='roleManagementRightSortGrid'
            data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid1-Grid3'
          >
            {customList(right.sort(sortPermissions))}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          direction='row'
          justifyContent='space-evenly'
          alignItems='center'
          sx={{
            marginBottom: '50px',
            marginTop: '30px',
            display: 'flex',
            alignContent: 'flex-start',
          }}
          id='roleManagementGrid'
          data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid2'
        >
          <Grid
            item
            xs={1}
            data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid2-Grid1'
          >
            <Tooltip
              data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid2-Grid1-Tooltip'
              title={
                roleName === ''
                  ? 'You have to input the role name '
                  : props.RoleName === ''
                    ? 'Create role'
                    : 'Update role'
              }
            >
              <div>
                <Button
                  id='systemAdminActionsToRolePageCreateButton'
                  data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid2-Grid1-Tooltip-CreateButton'
                  variant='contained'
                  color='lightBlue'
                  disabled={
                    roleName.trim() === '' ||
                    right.length === 0 ||
                    (isUpdated && roleNameDuplicated) ||
                    roleErr ||
                    readonly ||
                    (JSON.stringify(originalRight) === JSON.stringify(right) &&
                      originalRoleName === roleName.trim())
                  }
                  onClick={handleDialogOpen}
                  sx={{ display: readonly ? 'none !important' : 'block' }}
                >
                  {props.ActionBtn}
                </Button>
              </div>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={1}
            data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid2-Grid2'
          >
            <Button
              id='systemAdminActionsToRolePageResetButton'
              data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid2-Grid2-ResetButton'
              variant='contained'
              color='lightBlue'
              onClick={handleReset}
              disabled={readonly}
              sx={{ display: readonly ? 'none !important' : 'block' }}
            >
              RESET
            </Button>
          </Grid>
          <Grid
            item
            xs={1}
            data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid2-Grid3'
          >
            <Button
              id='systemAdminActionsToRolePageBackButton'
              data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-Grid2-Grid3-BackButton'
              variant='contained'
              color='lightBlue'
              onClick={handleBack}
            >
              BACK
            </Button>
          </Grid>
        </Grid>
        <AlertDialog
          id='roleManagementPopupAlertDialog'
          data-selenium-id='SystemAdmin_RoleManagement-ActionsToRolePage-BasicPage-RoleManagementBox-RoleManagementMain-InputBox-PopupAlertDialog'
          type={dialogType}
          open={dialogOpen}
          handleClose={handleDialogClose}
          data={{
            roleName: roleName,
            right: right.map(permission => permission.permissionCode),
            originalRoleName: originalRoleName,
          }}
          fullName={roleName}
        />
      </Main>
    </Box>
  );
}

export default BasicPage;

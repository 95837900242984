import { useDispatch, useSelector } from 'react-redux';
import {useParams} from "react-router-dom";
// Actions
import { setDate } from '@/actions/SIMT-SIP/SIPFilter/SIPFilterAction';
// Components
import Item from '@/pages/SIPage/Overview/Filter/component/Framework/Block/Item';
import Section from '@/pages/SIPage/Overview/Filter/component/Framework/Block/Section';
import DateRangePicker from '@/components/DateRangePicker';
// utils
import {
  clearTime,
  formatDateString,
  getUTCZeroTimeOffset,
} from '@/utils/commonUtils';

export default function ThirdSection(props) {
  const dispatch = useDispatch();
  const { sipOrigin } = useParams();
  const { sipFilterSelected } = useSelector(state => {
    return {
      sipFilterSelected: state.SIPFilter.sipFilterSelected,
    };
  });

  const handleSetSubmissionDateNull = () => {
    dispatch(setDate('submission', 'to', null));
    dispatch(setDate('submission', 'from', null));
  };

  const handleSetDecisionDateNull = () => {
    dispatch(setDate('decision', 'to', null));
    dispatch(setDate('decision', 'from', null));
  };

  const handleSetCreationDateNull = () => {
    dispatch(setDate('creation', 'to', null));
    dispatch(setDate('creation', 'from', null));
  };

  const handleSetSubmissionDateFrom = newDate => {
    const newDateWithoutTime = getUTCZeroTimeOffset(clearTime(newDate));
    if (formatDateString(newDateWithoutTime) > formatDateString(sipFilterSelected.submissionToDate)) {
      // If the From date is greater than the to date, the to date is null
      dispatch(setDate('submission', 'to', null));
    }
    dispatch(setDate('submission', 'from', newDateWithoutTime)); // Set the date of the current click
  };

  const handleSetSubmissionDateTo = newDate => {
    const newDateWithoutTime = getUTCZeroTimeOffset(clearTime(newDate));
    if (
      newDate === null ||
      formatDateString(newDateWithoutTime) >=
        formatDateString(sipFilterSelected.submissionFromDate)
    ) {
      dispatch(setDate('submission', 'to', newDateWithoutTime));
    }
  };

  const handleSetDecisionDateFrom = newDate => {
    const newDateWithoutTime = getUTCZeroTimeOffset(clearTime(newDate));
    if (
      formatDateString(newDateWithoutTime) >
      formatDateString(sipFilterSelected.decisionToDate)
    ) {
      // If the From date is greater than the to date, the to date is null
      dispatch(setDate('decision', 'to', null));
    }
    dispatch(setDate('decision', 'from', newDateWithoutTime)); // Set the date of the current click
  };

  const handleSetDecisionDateTo = newDate => {
    if (newDate === null) {
      dispatch(setDate('decision', 'to', null));
    } else {
      const newDateWithoutTime = getUTCZeroTimeOffset(clearTime(newDate));
      if (
        formatDateString(newDateWithoutTime) >=
        formatDateString(sipFilterSelected.decisionFromDate)
      ) {
        dispatch(setDate('decision', 'to', newDateWithoutTime));
      }
    }
  };

  const handleSetCreationDateFrom = newDate => {
    const newDateWithoutTime = getUTCZeroTimeOffset(clearTime(newDate));
    if (formatDateString(newDateWithoutTime) > formatDateString(sipFilterSelected.creationToDate)) {
      dispatch(setDate('creation', 'to', null));
    }
    dispatch(setDate('creation', 'from', newDateWithoutTime));
  };

  const handleSetCreationDateTo = newDate => {
    const newDateWithoutTime = getUTCZeroTimeOffset(clearTime(newDate));
    if (
      newDate === null ||
      formatDateString(newDateWithoutTime) >=
      formatDateString(sipFilterSelected.creationFromDate)
    ) {
      dispatch(setDate('creation', 'to', newDateWithoutTime));
    }
  };

  return (
    <Section id='thirdSection'>
      {sipOrigin ==='solicited'?
        <Item
          id='creationDate'
          title='Creation Date'
          needResetBtn={true}
          handleReset={handleSetCreationDateNull}
          resetLabel={'Clear'}
        >
          <DateRangePicker
            id='sipPageFilterDrawerCreationDateTimeRangeInput'
            data-selenium-id='SIPPage_Overview-newFilterDrawer-Overview-CreationDate-TimeRangeInput'
            fromDate={sipFilterSelected.creationFromDate}
            toDate={sipFilterSelected.creationToDate}
            handleDateChange={(f, t) => {
              handleSetCreationDateFrom(f);
              handleSetCreationDateTo(t);
            }}
            popoverIndex={1400}
            innerBorder={'1px solid #DFE4E8'}
            border={'1px solid #FFFFFF'}
            backgroundColor='#FFFFFF'
            iconPaddingRight={0}
            minHeight={40}
            width='100%'
            outerId='sioverview-filter-scrollbox'
            needErrorCheck = {true}
            needErrorMessage = {true}
          />
        </Item>:
      <Item
        id='submissionDate'
        title='Submission Date'
        needResetBtn={true}
        handleReset={handleSetSubmissionDateNull}
        resetLabel={'Clear'}
      >
        <DateRangePicker
          id='siPageFilterDrawerAcquiredDateTimeRangeInput'
          data-selenium-id='SIPage_Overview-newFilterDrawer-Overview-AcquiredDate-TimeRangeInput'
          fromDate={sipFilterSelected.submissionFromDate}
          toDate={sipFilterSelected.submissionToDate}
          handleDateChange={(f, t) => {
            handleSetSubmissionDateFrom(f);
            handleSetSubmissionDateTo(t);
          }}
          popoverIndex={1400}
          innerBorder={'1px solid #DFE4E8'}
          border={'1px solid #FFFFFF'}
          backgroundColor='#FFFFFF'
          iconPaddingRight={0}
          minHeight={40}
          width='100%'
          outerId='sioverview-filter-scrollbox'
          needErrorCheck = {true}
          needErrorMessage = {true}
        />
      </Item>}
      <Item
        id='decisionDate'
        title='Latest Decision Date'
        needResetBtn={true}
        handleReset={handleSetDecisionDateNull}
        resetLabel={'Clear'}
      >
        <DateRangePicker
          id='siPageFilterDrawerSubmissionDeadlineTimeRangeInput'
          data-selenium-id='SIPage_Overview-newFilterDrawer-Overview-Submission-Deadline-TimeRangeInput'
          fromDate={sipFilterSelected.decisionFromDate}
          toDate={sipFilterSelected.decisionToDate}
          handleDateChange={(f, t) => {
            handleSetDecisionDateFrom(f);
            handleSetDecisionDateTo(t);
          }}
          popoverIndex={1400}
          innerBorder={'1px solid #DFE4E8'}
          border={'1px solid #FFFFFF'}
          backgroundColor='#FFFFFF'
          iconPaddingRight={0}
          minHeight={40}
          width='100%'
          outerId='sioverview-filter-scrollbox'
          needErrorCheck = {true}
          needErrorMessage = {true}
        />
      </Item>
    </Section>
  );
}

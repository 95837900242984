import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { ChartGroups } from '@/components/Chart/ChartGroups';
import { ChartList } from '@/components/Chart/ChartList';
import styles from './index.module.scss';
import { changeSiderOpen } from '@/actions/SIMT-User/User/UserManageAction';
import moment from 'moment';

// Icons
import AllBusinessDivisions from '@/assets/ReportDiagrams/Articles/Overview/All Business Divisions.png';
import SingleBusinessDivisions from '@/assets/ReportDiagrams/Articles/Overview/Single Business Divison.png';
import TargetSubjectGroups from '@/assets/ReportDiagrams/Articles/Overview/YTD Against FY Target Subject Group.png';
import TargetBusinessDivisions from '@/assets/ReportDiagrams/Articles/Overview/YTD Against FY Target Business Divison.png';

// Constants
import {
  SUBMISSION_AND_ACCEPTANCE_YTD_AGAINST_FY_TARGET,
  SUBMISSION_ACCEPTANCE_AND_PUBLICATION_PERFORMANCE_OF_INDIVIDUAL_BUSINESS_DIVISION,
  SUBMISSION_ACCEPTANCE_AND_PUBLICATION_PERFORMANCE_IN,
  SUBJECT_GROUP,
  BUSINESS_DIVISION,
  BUS_DIV,
  MONTH, POST
} from './Constants/index.js';

// Charts
import PerformanceChart from './Diagrams';
import TargetChart from './TargetDiagrams';

const DIAGRAM_SETTINGS = [
  {
    // 1st
    groupName: SUBMISSION_AND_ACCEPTANCE_YTD_AGAINST_FY_TARGET,
    chartName: SUBJECT_GROUP,
    hasAdditionalFilter: true,
    catagoryAttributeName: null, // used?
    singleSelectListUrl: null,
    url: '/reports/article/diagram/overview/target/sg/',
    downloadUrl: '/reports/article/overview/sg/progress/download', // todo ??
    downloadMethod: POST,
    tableDownloadTitle: 'Articles', // check excel file name
  },
  {
    // 2nd
    groupName: SUBMISSION_AND_ACCEPTANCE_YTD_AGAINST_FY_TARGET,
    chartName: BUSINESS_DIVISION,
    hasAdditionalFilter: true,
    catagoryAttributeName: BUS_DIV,
    singleSelectListUrl: null,
    url: '/reports/article/diagram/overview/target/bd/',
    downloadUrl: '/reports/article/overview/bd/progress/download', // todo ??
    downloadMethod: POST,
    tableDownloadTitle: 'Articles',
  },
  {
    groupName:
      SUBMISSION_ACCEPTANCE_AND_PUBLICATION_PERFORMANCE_OF_INDIVIDUAL_BUSINESS_DIVISION,
    chartName: BUSINESS_DIVISION,
    hasAdditionalFilter: true,
    catagoryAttributeName: BUS_DIV,
    singleSelectListUrl: '/reports/article/get-busdiv',
    url: '/reports/article/diagram/overview/monthly/bd/',
    downloadUrl: '/reports/article/overview/sg/monthly/download',
    downloadMethod: POST,
    tableDownloadTitle: 'Articles Performance',
  },
  {
    groupName: SUBMISSION_ACCEPTANCE_AND_PUBLICATION_PERFORMANCE_IN,
    chartName: BUSINESS_DIVISION,
    hasAdditionalFilter: false,
    catagoryAttributeName: null,
    singleSelectListUrl: null,
    url: '/reports/article/diagram/overview/overall/bd/',
    downloadUrl: '/reports/article/overview/bd/monthly/download',
    downloadMethod: POST,
    tableDownloadTitle: 'Articles Performance',
  },
];

// settings for ChartGroups
const CHART_GROUP_TITLES = [
  {
    groupTitle: SUBMISSION_AND_ACCEPTANCE_YTD_AGAINST_FY_TARGET,
    levels: [
      {
        chartIndex: 0,
        title: SUBJECT_GROUP,
        img: TargetSubjectGroups,
      },
      {
        chartIndex: 1,
        title: BUSINESS_DIVISION,
        img: TargetBusinessDivisions,
      },
    ],
  },
  {
    groupTitle:
      SUBMISSION_ACCEPTANCE_AND_PUBLICATION_PERFORMANCE_OF_INDIVIDUAL_BUSINESS_DIVISION,
    levels: [
      {
        chartIndex: 2,
        title: '',
        img: SingleBusinessDivisions,
      },
    ],
  },
  {
    groupTitle: SUBMISSION_ACCEPTANCE_AND_PUBLICATION_PERFORMANCE_IN,
    levels: [
      {
        chartIndex: 3,
        title: '',
        img: AllBusinessDivisions,
      },
    ],
  },
];

const DiagramContent = () => {
  // get selectedChartIndex from URL
  let [searchParams, setSearchParams] = useSearchParams();
  let selectedChartIndex = searchParams.get('selectedChartIndex');
  if (selectedChartIndex == null) {
    selectedChartIndex = -1;
  } else {
    selectedChartIndex = parseInt(selectedChartIndex);
  }

  const setShowTableNav = chartIndex => {
    setSearchParams({ showTable: false, selectedChartIndex: chartIndex });
  };

  const siderOpen = useSelector(state => state.UserManage.siderOpen);
  const dispatch = useDispatch();

  const { selectDate } = useSelector(state => ({
    selectDate: state.Report.selectDate,
  }));

  const formatdate = useMemo(() => {
    let date = selectDate;
    // default is today
    if (date === null) {
      date = moment().utc().format('YYYY-M');
    }
    let [year, month] = date.split('-');
    month = MONTH[month];
    return month + ' ' + year;
  }, [selectDate]);

  // update group titles with selected month year
  const formattedChartGroupTitles = CHART_GROUP_TITLES.map(chart => ({
    ...chart,
    groupTitle: chart.groupTitle + ' ' + formatdate,
  }));

  const {
    groupName,
    chartName,
    hasAdditionalFilter,
    catagoryAttributeName,
    url,
    singleSelectListUrl,
    downloadUrl,
    downloadMethod,
    tableDownloadTitle
  } = useMemo(() => {
    if (
      selectedChartIndex >= 0 &&
      selectedChartIndex < DIAGRAM_SETTINGS.length
    ) {
      return DIAGRAM_SETTINGS[selectedChartIndex];
    } else {
      return {
        groupName: '',
        chartName: '',
        hasAdditionalFilter: null,
        catagoryAttributeName: null,
        url: null,
        singleSelectListUrl: null,
        downloadUrl: null,
        downloadMethod: null,
        tableDownloadTitle: null
      };
    }
  }, [selectedChartIndex]);

  const onSelectChartIndex = index => {
    setShowTableNav(index);

    // if a chart is selected and the side menu is open, close the side menu
    if (index >= 0 && index < DIAGRAM_SETTINGS.length && siderOpen) {
      dispatch(changeSiderOpen(false));
    }
  };

  const cancelSelectedChart = () => onSelectChartIndex(-1);

  return (
    <Box sx={{ marginTop: '32px', paddingBottom: '62px' }}>
      {selectedChartIndex >= 0 ? (
        <Box className={styles['card-content-container']}>
          <div
            className={styles['card-content-paper']}
            disableGutters
            maxWidth={false}
          >
            {/* Target charts have multiple selector*/}
            {selectedChartIndex <= 1 && (
              <TargetChart
                header={groupName}
                subHeader={chartName}
                selectedDate={selectDate}
                formatdate={formatdate}
                cancelSelectedChart={cancelSelectedChart}
                hasAdditionalFilter={hasAdditionalFilter}
                hasSelectAllOption={true}
                catagoryAttributeName={catagoryAttributeName}
                barSeriesColor={[]} // todo
                url={url}
                downloadUrl={downloadUrl}
                downloadMethod={downloadMethod}
                tableDownloadTitle={tableDownloadTitle}
              />
            )}
            {/* Performance charts have single selector or none*/}
            {selectedChartIndex > 1 && (
              <PerformanceChart
                header={groupName}
                subHeader={chartName}
                selectDate={selectDate}
                formatdate={formatdate}
                cancelSelectedChart={cancelSelectedChart}
                showSubjectGroupsSelector={false}
                hasAdditionalFilter={hasAdditionalFilter}
                catagoryAttributeName={catagoryAttributeName}
                url={url}
                singleSelectListUrl={singleSelectListUrl}
                downloadUrl={downloadUrl}
                downloadMethod={downloadMethod}
                tableDownloadTitle={tableDownloadTitle}
              />
            )}
          </div>
          <ChartList
            chartGroupTitles={formattedChartGroupTitles}
            onSelectChartIndex={onSelectChartIndex}
            selectedChartIndex={selectedChartIndex}
          />
        </Box>
      ) : (
        <ChartGroups
          chartGroupTitles={formattedChartGroupTitles}
          onSelectChartIndex={onSelectChartIndex}
          selectedChartIndex={selectedChartIndex}
        />
      )}
    </Box>
  );
};

export default DiagramContent;

/*
 * @Author: LiuJingQian 1250366059@qq.com
 * @Date: 2023-08-13 19:42:35
 * @LastEditors: LiuJingQian 1250366059@qq.com
 * @LastEditTime: 2023-08-13 20:30:07
 * @FilePath: \simt_front\src\componentsphase2\RichText\EditorContext.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { useEffect, useState } from "react";

function realseResources(editor){
  const windowOrGlobal = typeof window === 'object' ? window : global;
  delete windowOrGlobal.CKEDITOR_VERSION;
}

export function useCustomEditor() {
  const [editor, setEditor] = useState(null);
  useEffect(() => {
    let importPromise = import('ckeditor5-custom-build/build/ckeditor');
    importPromise.then(ClassicEditor => {
      setEditor(ClassicEditor);
    });
    return () => {
      // importPromise.cancel();
      realseResources(editor);
    };
  }, []);
  return editor?.default;
}

export function useInlineEditor() {
  const [editor, setEditor] = useState(null);
  useEffect(() => {
    let importPromise = import('ckeditor5-inline-build/build/ckeditor');
    importPromise.then(InlineEditor => {
      // console.info('InlineEditor', InlineEditor);
      setEditor(InlineEditor);
    });
    return () => {
      // importPromise.cancel();
      realseResources(editor);
    };
  }, []);
  return editor;
}


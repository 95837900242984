// @ts-check
import { DetailScrollTopButton } from '@/components/StyledButton/ScrollToTopButton';
import { Box, Stack, Button, Snackbar,CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TitleBlock } from './CustomComponents/CustomBlock';
import EndButtonArrowFold from '@/assets/EndButtonArrowFold.svg';
import { SIPRevisePageHeader } from './CustomComponents/CustomHeader';
import { FormattedMessage } from 'react-intl';
import ExpiredPage from './ExpiredPage';
import {
  RevisePageBodyContainer,
  SectionsContainer,
} from './CustomComponents/CustomContainer';
import { MakeDecisionStickyBar } from './CustomComponents/CustomStickyBar';
import {
  commissionApproachesInitialValue,
  geInformationListIntialValue,
  sipInformationInitialValue,
} from './CommonInitialValue';
import ThankYouPage from '../SubmitCompletedPage/ThankYouPage';

import EditorialBoardMemberReviewSection from './Section/EditorialBoardMemberReviewSection';
import CommentSection from './Section/CommentSection';
import { ContentCommissioningApproachesShowSection } from './Section/ContentCommissioningApproachesSection';
import { GEInformationShowSection } from './Section/GEInformationSection';
import { ObjectShowSection } from './Section/ObjectSection';
import { SIPInformationShowSection } from './Section/SIPInformationSection';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../../components/Footer';
import {
  loadSIPReviseData,
  SET_DOWNLOAD_REVISE_DATA_STATUS,
  SET_UPLOAD_REVISE_DATA_STATUS,
} from '@/actions/SIMT-SIP/SIP/SIPAction';
import { useParams } from 'react-router-dom';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';
import {
  DecisionFoldBtn,
  SavedDecisionFoldBtn,
} from '@/pages/SIPPage/SIPDetailPage/DecisionSection/DecisionMyStyled';
import { SSIPObjectShowSection } from './Section/SSIPObjectSection';
import { SSIPInformationShowSection } from './Section/SSIPInformationSection';
import {
  loadSIPEbmReviewData,
  submitEbmReviewData,
} from '@/actions/SIMT-SIP/SIP/SIPEbmReview';
import CommonSnackBar from '@/modules/ConfirmSnackBar/CommonSnackBar';

/**
 *
 * @param {import("./EbmReviewPage").EbmReviewPage} props
 * @returns
 */

const EbmReviewPage = props => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [ebmReviewDto, setEbmReviewDto] = useState({
    status: '',
    comments: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isBtnEnable, setIsBtnEnable] = useState(true);

  const { sipEbmReviewData, submitStatus, sipEbmReviewLoading, downloadReviseDataErrorType } = useSelector(
    state => ({
      /** @type {import("./EbmReviewPage").EbmReviewData} */
      // @ts-ignore
      sipEbmReviewData: state.SIP.sipEbmReviewData,
      submitStatus: state.SIP.uploadReviseDataStatus,
      sipEbmReviewLoading: state.SIP.sipEbmReviewLoading,
      downloadReviseDataErrorType: state.SIP.downloadReviseDataErrorType,
    })
  );

  useEffect(() => {
    if (token) {
      dispatch(loadSIPEbmReviewData(token));
    }
  }, [token]);

  /**
   *
   * @param {import("./EbmReviewPage").EditorReviewDecision} decision
   */
  const handleSubmitDecision = async decision => {
    if (token === undefined) return;
    console.log(ebmReviewDto);
    dispatch(submitEbmReviewData(token, ebmReviewDto));
  };

  useEffect(() => {
    if (submitStatus === 'success') {
      setIsSubmitted(true);
    } else {
      console.log('error'); // TODO open a window
    }
  }, [submitStatus]);

  const [sipSubmited, setSipSubmited] = useState(false);

  return (
    <Box data-selenium-id='SIP_Revise_EditorPage-Box'>
      <SIPRevisePageHeader
        sx={{
          zIndex: 1301,
          '& .MuiToolbar-root': {
            paddingX: sipSubmited ? '128px' : '32px',
            transition: 'all 0.5s',
          },
        }}
      />
      <CommonSnackBar />
      {/* Has submitted */}
      {isSubmitted && <ThankYouPage />}
      {/* not submitted and has data */}
      {sipEbmReviewLoading ? (
        <RevisePageBodyContainer>
          {' '}
          <Stack
            data-selenium-id='SSIP_RevisePage-GetThankYouDetailText-Stack1'
            alignItems={'center'}
            justifyContent='center'
            marginTop={'56px'}
            minHeight={'calc(100vh - 56px - 71.2px)'}
          >
            <CircularProgress
              disableShrink
              data-selenium-id='SSIP_RevisePage-GetThankYouDetailText-CircularProgress'
            />
          </Stack>
        </RevisePageBodyContainer>
      ) : !isSubmitted && sipEbmReviewData ? (
        <RevisePageBodyContainer>
          <TitleBlock
            title={sipEbmReviewData.sipInformation.sipTitle || ''}
            SIPCode={sipEbmReviewData.sipStatus.sipCode}
          />
          <SectionsContainer>
            <EditorialBoardMemberReviewSection
              onChange={setEbmReviewDto}
              setBtnEnable={setIsBtnEnable}
            />

            {sipEbmReviewData?.sipInformation?.sipType === 'Yes' ? (
              <SSIPObjectShowSection
                value={{
                  type: 'EditorReview',
                  sipObject: sipEbmReviewData.object,
                }}
              />
            ) : (
              <ObjectShowSection
                value={{
                  type: 'EditorReview',
                  sipObject: sipEbmReviewData.object,
                }}
              />
            )}

            <GEInformationShowSection value={sipEbmReviewData.geInformation} />
            {sipEbmReviewData?.sipInformation?.sipType === 'Yes' ? (
              <SSIPInformationShowSection
                value={sipEbmReviewData.sipInformation}
              />
            ) : (
              <SIPInformationShowSection
                value={sipEbmReviewData.sipInformation}
              />
            )}

            <ContentCommissioningApproachesShowSection
              value={sipEbmReviewData.commissionApproaches}
              emptyTip={true}
            />
            <Stack flexDirection='row-reverse'>
              <Button
                disabled={!isBtnEnable}
                variant='contained'
                onClick={handleSubmitDecision}
              >
                Submit
              </Button>
            </Stack>
          </SectionsContainer>
          <DetailScrollTopButton
            boxProps={{ sx: { bottom: 300 } }}
            data-selenium-id='SIP_Revise_EditorPage-DetailScrollTopButton'
          />
        </RevisePageBodyContainer>
      ) : downloadReviseDataErrorType === 'has been submitted' ? (
          <ThankYouPage
              showCreateProposalButton={false}
              handleCreateProposal={() => {}}
              detailText={
                  <>
                      We are pleased to confirm that your comments on this Special Issue
                      proposal have been submitted successfully.
                  </>
              }
              theme='blue'
              isSSIP={true}
          />
      ):(
        <RevisePageBodyContainer>
          <ExpiredPage />
        </RevisePageBodyContainer>
      )}
      <Footer
        sx={{ borderColor: '#1e48b4' }}
        data-selenium-id='SSIP_RevisePage-GetThankYouDetailText-Footer'
      />
    </Box>
  );
};

export default EbmReviewPage;

/* eslint-disable indent */
//@ts-check
import { useEffect, useState, forwardRef, useRef, type HTMLAttributes, memo, type ReactElement } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link as ReactLick } from 'react-router-dom';
import { styled } from '@mui/system';
import {
  Box,
  tableCellClasses,
  Typography as MuiTypography,
  TableCell,
} from '@mui/material';
import {
  MyTableHeader,
  StyledTableCell,
  type TableHeaderProps,
} from '@/components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import TableSkeleton from '@/components/TableSkeleton';
// import TooltipMoveArrow from '@/componentsphase2/CustomTooltip';
import NoDataMessage from '@/componentsphase2/NoDataMessage';
import {
  MulLineTooltipIfShow,
} from '@/componentsphase2/CustomTooltip';
import RadioBlock from '@/pages/SIPPage/SIPCheckListPage/compontents/RadioBlock';
import styleModule from './index.module.scss';
import { useUpdateEffect } from 'ahooks';
import { SET_SCROLLINTOVIEW } from '@/actions/SIMT-SIP/SIPChecklistSubmitted/SIPChecklistAction';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import { func, bool, string, oneOf } from 'prop-types';
import { type Dispatch } from 'redux';
export const geCode = 'geCode';
export const verification = 'verification';
export const email = 'primaryEmail';
const { freeze } = Object;
const { isArray } = Array;
export enum geDataName {
  geCode = 'geCode',
  verification = 'verification',
  email = 'email',
  primaryEmail = 'primaryEmail',
  firstName = 'firstName',
  lastName = 'lastName',
  fullName = 'fullName',
  secondaryEmail = 'secondaryEmail',
  institute = 'institute',
  alertGeFlagType = 'alertGeFlagType',
  flags = 'flags',
}
export const createRow: (id: geDataName, label: string, isOrder?: boolean, minWidth?: string, maxWidth?: string, show?: boolean, stick?: boolean, stickPX?: string) => TableHeaderProps<geDataName> = (
  id = geDataName.geCode,
  label = '',
  isOrder = true,
  minWidth = '110px',
  maxWidth = '220px',
  show = true,
  stick = false,
  stickPX = '0px'
) => {
  return { id, label, isOrder, minWidth, maxWidth, show, stick, stickPX };
};
export const FlaggedGESynchronizationAndVerificationTable = 'flaggedGESynchronizationAndVerification';
export const scrollIntoViewId = 'scrollIntoViewId';
export enum radioGropList {
  // eslint-disable-next-line no-unused-vars
  Matched = 'Matched',
  // eslint-disable-next-line no-unused-vars
  Mismatched = 'Mismatched',
}
export type radioGropListOrUndefined = radioGropList | undefined | null;
export type radioGropListArray = ReadonlyArray<radioGropListOrUndefined>;
export const calcFlaggedGESynchronizationAndVerificationStatus = (arr: radioGropListArray) => {
  // arr?.every(i => i !== null) &&
  const MatchedLen = arr?.filter(i => i === radioGropList.Matched)?.length;
  // const MismatchedLen = arr?.filter(i => i === radioGropList.Mismatched)?.length;
  // const notHasUndefined = !arr?.includes(undefined);
  if (MatchedLen > 1)
    return false;
  // return (arr?.filter(i => i === radioGropList.Matched)?.length <= 1) && !arr?.includes(undefined);
  return MatchedLen === 1 && arr?.filter(i => i === radioGropList.Mismatched)?.length === 0 || (!arr?.includes(undefined) && !arr?.includes(null));
  // return (MatchedLen === 1 && (arr?.filter(i => i === radioGropList.Mismatched)?.length === 0 || notHasUndefined)) || (MatchedLen === 0 && notHasUndefined);
};
// export const calcFlaggedGESynchronizationAndVerificationStatusAndNotUndefined = (arr: radioGropListArray | undefined) => arr && calcFlaggedGESynchronizationAndVerificationStatus(arr);
export enum FlaggedGESynchronizationAndVerificationErrorType {
  // eslint-disable-next-line no-unused-vars
  moreThanOneMatchedGE = 5,
  // eslint-disable-next-line no-unused-vars
  notMatchedGE = 6,
}
export const queryFlaggedGESynchronizationAndVerificationErrorType = (arr: radioGropListArray) => {
  // const MatchedLen = arr?.filter(i => i === radioGropList.Matched)?.length;
  if (arr?.filter(i => i === radioGropList.Matched)?.length > 1)
    return FlaggedGESynchronizationAndVerificationErrorType.moreThanOneMatchedGE;
  if (!calcFlaggedGESynchronizationAndVerificationStatus(arr))
    return FlaggedGESynchronizationAndVerificationErrorType.notMatchedGE;
  return null;
};
// export const queryFlaggedGESynchronizationAndVerificationErrorTypeIncludeUndefined = (arr: radioGropListArray | undefined) => {
//   if (!arr || !arr.length)
//     return FlaggedGESynchronizationAndVerificationErrorType.notMatchedGE;
//   return queryFlaggedGESynchronizationAndVerificationErrorType(arr);
// };
const Typography = styled(MuiTypography)({
  lineHeight: '20px',
  fontWeight: '600',
  color: '#154AB6',
  transition: '.5s',
  '&:hover': {
    color: '#113D95',
    fontWeight: '700',
  },
  fontSize: '14px',
});
export const HoverTableRow = styled(TableRow)({
  height: '64px',
  background: '#FFFFFF',
});
export const NEWStyledTableCell = styled(StyledTableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    paddingLeft: '24px',
    paddingTop: '12px',
    paddingBottom: '12px',
    lineHeight: '20px',
    maxWidth: '240px',
    '& a>div>p>div>div>div': {
      fontSize: '14px',
    },
    '&>div>p': {
      fontSize: '14px',
    },
  },
  '&.GE-Code-Column>div.MuiBox-root:only-child>div.MuiBox-root:first-child': {
    marginLeft: 'auto',
    marginRight: 'auto',
    transform: 'translateX(-6px)',
  },
}));
export enum alertGeFlagType {
  'Flags-Severe Issue' = 'Flags-Severe Issue',
  'Flags-Mild Issue' = 'Flags-Mild Issue'
}
export interface singleGeData {
  readonly [geDataName.geCode]?: string;
  // readonly geId: number;
  readonly [geDataName.firstName]?: string;
  readonly [geDataName.lastName]?: string;
  readonly [geDataName.fullName]?: string;
  readonly [geDataName.email]?: string;
  readonly [geDataName.primaryEmail]?: string;
  readonly [geDataName.secondaryEmail]?: string;
  // readonly department: string;
  readonly [geDataName.institute]?: string;
  readonly matchedEmailGeCode?: string;
  readonly matchedNameGeCode?: string;
  // readonly city: string;
  // readonly country: string;
  // readonly gender: string;
  // readonly notes: string;
  // readonly flags: ReadonlyArray<string>;
  // readonly orcidId: string;
  // readonly title: string;
  // readonly profileWebsite: string;
  // readonly researchGateId: string;
  readonly [geDataName.alertGeFlagType]?: alertGeFlagType;
  readonly [geDataName.flags]?: string[];
  // readonly createTime: string;
  // readonly cvFileName: string;
  // readonly researchKeywordsList: ReadonlyArray<string>;
  // readonly id: string;
  readonly [geDataName.verification]?: radioGropListOrUndefined;
}
export type GeDataType = ReadonlyArray<singleGeData>;
export enum VerificationStyleEnum {
  show = 'show',
  hidden = 'hidden',
  none = 'none'
}
interface newTableHeaderProps extends TableHeaderProps<geDataName> {
  hasFlag?: boolean;
  withLink?: boolean;
}
// type newTableHeaderProps = TableHeaderProps<geDataName> & { hasFlag?: boolean; withLink?: boolean; };
interface Props extends HTMLAttributes<HTMLElement> {
  readonly geDataResult?: GeDataType;
  // eslint-disable-next-line no-unused-vars
  readonly setValue?: (value?: radioGropListArray) => void;
  readonly fetchData?: () => Promise<void | GeDataType>;
  readonly editing?: boolean;
  readonly error?: boolean;
  readonly headerItems?: newTableHeaderProps[];
  readonly VerificationStyle?: VerificationStyleEnum;
  readonly singleError?: boolean;
  readonly tableMaxWidth?: string;
}
// interface GETableTooltipProps extends HTMLAttributes<HTMLElement> {
//   readonly title?: string;
// }
// const GETableTooltip = ({ title = '', children }: GETableTooltipProps) => (
//   <TooltipMoveArrow
//     placement='top'
//     title={title}
//     disableInteractive
//     sx={{
//       '& .MuiTooltip-arrow': {
//         left: '0 !important',
//       },
//     }}
//   >
//     {children}
//   </TooltipMoveArrow>
// );
export const clickCancelButtonString = 'clickCancelButton';
const alertGeFlagObject = freeze({
  [alertGeFlagType['Flags-Severe Issue']]: {
    color: '#EE5350',
    text: 'SEVERE ISSUE',
  },
  [alertGeFlagType['Flags-Mild Issue']]: {
    color: '#FFB152',
    text: 'MILD ISSUE',
  },
});
interface WithLinkType extends HTMLAttributes<HTMLElement> {
  readonly geCode?: string;
  readonly children?: React.ReactNode;
  readonly withLink?: boolean;
}
const WithLink = memo((props: WithLinkType): ReactElement => {
  const { geCode = '', children = null, withLink = false, ...others } = props;
  if (!withLink) return <>{children}</>;
  return <ReactLick
    style={{
      marginTop: '2px',
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: '#154AB6',
    }}
    id={`gePageTableGeCodeLink${geCode}`}
    to={`/simt/auth/siRelated/ge/detail/${geCode}`}
    target='_blank'
    {...others}
  >
    <Typography>{children}</Typography>
  </ReactLick>;
});
export interface wrapWithFlagType extends HTMLAttributes<HTMLElement> {
  readonly hasFlag?: boolean;
  readonly withLink?: boolean;
  readonly isMismatched?: boolean;
  readonly geCode?: string;
  readonly flags?: alertGeFlagType;
}
export const WrapWithFlag = memo((props: wrapWithFlagType): ReactElement => {
  const { hasFlag = false, withLink = hasFlag, geCode = '', flags, children = null, isMismatched = true, ...others } = props;
  const flagsIsString = hasFlag && typeof flags === 'string';
  if (!flagsIsString && !withLink) return <>{children}</>;
  return <Box
    {...(
      flagsIsString && {
        sx: {
          pt: '0 !important',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          minHeight: hasFlag?'64px':undefined,
        }
      })}
    {...others}
  >
    {flagsIsString && <Box
      sx={{
        boxSizing: 'border-box',
        height: '20px',
        width: '102px',
        p: '2px 11px',
        background: isMismatched ? '#BCC5CF' : alertGeFlagObject?.[flags]?.color,
        textAlign: 'center',
        borderRadius: '0 0 4px 4px',
        color: '#fff',
        fontSize: '12px',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        ml: '3px',
        lineHeight: '16px',
        transition: '.5s',
        '& + a': {
          transform: 'translateY(-8px)',
        },
      }}
    >
      {alertGeFlagObject?.[flags]?.text}
    </Box>}
    <WithLink
      geCode={geCode}
      withLink={withLink}
    >{children}</WithLink>
  </Box>;
});
const GETable = forwardRef<unknown, Props>((props, ref) => {
  const {
    setValue = () => { },
    geDataResult = [
      //   {
      //   geCode: '',
      //   // geId: 0,
      //   // firstName: '',
      //   // lastName: '',
      //   fullName: '',
      //   primaryEmail: '',
      //   // secondaryEmail: '',
      //   // department: '',
      //   institute: '',
      //   alertGeFlagType: '',
      //   verification: radioGropList.Mismatched,
      // }
    ],
    fetchData = () => Promise.resolve(),
    editing = true,
    error = false,
    headerItems = [
      createRow(geDataName.fullName, 'FULL NAME', false, '184px', '184px'),
      { ...createRow(geDataName.geCode, 'GE CODE', false, '160px', '160px'), hasFlag: true, },
      createRow(geDataName.institute, 'INSTITUTION'),
      createRow(geDataName.email, geDataName.email.toUpperCase(), false, '184px', '184px'),
    ],
    VerificationStyle = VerificationStyleEnum.show,
    singleError = false,
    tableMaxWidth = undefined,
    id = 'SIPPage_CheckList-ForGESection',
    ...others
  } = props;

  const geDataResultLength = geDataResult?.length;
  const geDataResultString = (() => {
    try {
      return JSON.stringify(geDataResult);
    }
    catch {
      return '';
    }
  })();
  const scrollIntoViewId = useSelector((state: {
    readonly SIPChecklist: {
      readonly scrollIntoViewId: string;
    };
  }) => state?.SIPChecklist?.scrollIntoViewId);
  const BoxRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<Dispatch<{ readonly type: string; readonly data: string | null; }>>();
  useUpdateEffect(() => {
    scrollIntoViewId === FlaggedGESynchronizationAndVerificationTable && (BoxRef.current?.parentElement?.parentElement?.scrollIntoView({ behavior: 'smooth' }), setTimeout(() => dispatch({ type: SET_SCROLLINTOVIEW, data: null })));
  }, [scrollIntoViewId]);
  const [loading, setLoading] = useState(true);
  const [radiosData, setRadiosData] = useState<radioGropListOrUndefined[]>([]);
  const clickCancelButton = useSelector((state: {
    readonly SIPChecklist: {
      readonly [clickCancelButtonString]: number;
    };
  }) => state?.SIPChecklist?.[clickCancelButtonString]);
  const init = () => {
    setTimeout(() => {
      unstable_batchedUpdates(() => {
        if (!geDataResultLength) return;
        // const newEmptyArr = new Array(len).fill(undefined);
        const newEmptyArr = geDataResult?.map(i => i?.[verification] ?? undefined);
        if (isArray(newEmptyArr)) {
          setRadiosData(newEmptyArr);
          try {
            setValue(newEmptyArr);
          }
          catch (e) {
            console.error(e);
          }
        }
      });
    });
  };
  useEffect(() => {
    if (Date.now() - clickCancelButton < 3e3) init();
  }, [clickCancelButton]);
  useEffect(() => {
    setLoading(true);
    fetchData().then(() => {
      setLoading(false);
    }).catch(console.error);
  }, []);
  useEffect(() => {
    init();
  }, [geDataResultLength, geDataResultString]);
  let typeId = 0;
  const decideLines = (label: string | undefined, index: number, line: number, type?: number | undefined) => {
    if (!type) {
      type = typeId;
      typeId = typeId + 1;
    }
    return (
      <MulLineTooltipIfShow
        id={`${id}-GETable-TableContainer-Table-TableBody-TableRow${index}-${type}`}
        outerRef={containerBoxRef}
        line={line}
        label={label}
        className={styleModule.MulLineTooltipIfShow}
        sx={{
          '& .MuiTooltip-arrow': {
            left: '0 !important',
          },
        }}
      />
    );
  };
  const containerBoxRef = useRef<HTMLDivElement>(null);
  // const scrollYRef = useRef(null);
  // const { createElement } = document;
  // const containerRef = useRef<HTMLDivElement>(null);
  // const getContextRef = () => {
  //   return containerBoxRef;
  // };
  // useImperativeHandle(ref, () => ({
  //   getContextRef,
  // }));
  // useEffect(() => {
  //   const containerElement = containerRef.current ?? createElement('div');
  //   const containerBoxElement = containerBoxRef.current ?? createElement('div');
  //   setTimeout(() => {
  //     dispatch(
  //       //@ts-expect-error
  //       tableHeightChanged(
  //         {
  //           contextWidth: containerElement.offsetWidth,
  //           contextHeight: containerElement.offsetHeight,
  //           boxWidth: containerBoxElement.offsetWidth,
  //           boxHeight: containerBoxElement.offsetHeight,
  //         },
  //         'GEOverview'
  //       )
  //     );
  //   }, 100);
  // }, [geDataResult.length, loading]);
  const borderStyle = '1px solid #DFE4E8';
  const MismatchedHoverColor = '#E8EDFB';
  const isVerificationShow = VerificationStyle === VerificationStyleEnum.show;
  const isVerificationNotNone = VerificationStyle !== VerificationStyleEnum.none;
  return (
    <Box ref={BoxRef}
      id={`${id}-GETable`}
      data-selenium-id={`${id}-GETable`}
      {...others}
    >
      <Box
        id={`${id}-GETable-TableBox`}
        data-selenium-id={`${id}-GETable-TableBox`}
        // ref={containerRef}
        ref={ref}
      >
        <Box
          sx={{
            overflowX: 'hidden',
            overflowY: 'hidden',
            position: 'relative',
            borderRadius: '4px',
            border: borderStyle,
            borderBottomWidth: 0,
            width: 'fit-content',
            padding: 0,
            '& .scroll-bar-container-box': {
              right: '3px',
              width: '15px',
              display: 'none',
            },
            // mx: '16px',
          }}
        >
          <TableContainer
            ref={containerBoxRef}
            component={Paper}
            sx={{
              // minWidth: (((): string => {
              //   let num = 0;
              //   headerItems.forEach(item => {
              //     if (item.minWidth === item.maxWidth) {
              //       num += parseInt(item.minWidth.toString());
              //     }
              //   });
              //   if (VerificationStyle !== VerificationStyleEnum.none)
              //     num += 154;
              //   return `${num}px`;
              // })()),
              borderBottom: borderStyle,
              ...(tableMaxWidth && { maxWidth: tableMaxWidth }),
            }}
            className={styleModule.TableContainer}
            id={`${id}-GETable-TableContainer`}
            data-selenium-id={`${id}-GETable-TableContainer`}
          >
            <Table
              stickyHeader
              sx={{
                letterSpacing: '0.01em',
                minWidth: 'fit-content',
                minHeight: 'fit-content',
                ...(geDataResultLength === 0 && {
                  // borderBottom: borderStyle,
                  '#NoDataMessageTypography': {
                    transform: 'translateY(10px)',
                  }
                }),
                '& > thead > tr > th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader.MuiTableCell-sizeSmall': ((() => {
                  const obj: {
                    [key: string]: {
                      readonly width: string;
                    };
                  } = {};
                  headerItems.forEach((item, index) => {
                    if (item.minWidth === item.maxWidth) {
                      obj[`&:nth-of-type(${index + (isVerificationNotNone ? 2 : 1)})`] = {
                        width: item.minWidth.toString(),
                      };
                    }
                  });
                  return obj;
                })()),
              }}
              aria-label='customized table'
              size='small'
              id={`${id}-GETable-TableContainer-Table`}
              data-selenium-id={`${id}-GETable-TableContainer-Table`}
              className={styleModule.Table}
            >
              <MyTableHeader
                items={headerItems}
                backgroundColor='#F1F3F5'
                color='#596A7C'
                letterSpacing='0.08em'
                id={`${id}-GETable-TableContainer-Table-MyTableHeader`}
                data-selenium-id={`${id}-GETable-TableContainer-Table-MyTableHeader`}
                PaddingLeft='24px'
                Header0PaddingLeft='0px'
              >
                {isVerificationNotNone && <TableCell className={styleModule['MyTableHeader_Radio']}>
                  <NEWStyledTableCell
                    align='left'
                    className={styleModule.MyTableHeader_Verification}
                  >
                    {isVerificationShow ? verification?.toUpperCase() : ''}
                  </NEWStyledTableCell>
                  {editing && isVerificationShow && <Box
                    className={styleModule.MyTableHeader_MMatched}
                  >
                    <Box>{radioGropList.Matched}</Box><Box>{radioGropList.Mismatched}</Box>
                  </Box>}</TableCell>}
              </MyTableHeader>
              {loading ? (
                <TableBody
                  sx={{ backgroundColor: 'fff' }}
                  id={`${id}-GETable-TableContainer-Table-TableBody`}
                  data-selenium-id={`${id}-GETable-TableContainer-Table-TableBody`}
                >
                  <TableSkeleton num={9} colSpan={36} />
                </TableBody>
              ) : (
                <TableBody
                    id={`${id}-GETable-TableContainer-Table-TableBody`}
                    data-selenium-id={`${id}-GETable-TableContainer-Table-TableBody`}
                >
                  {!loading &&
                      geDataResultLength > 0 ? (
                    geDataResult.map((row, index) => {
                      const flags = row.alertGeFlagType;
                      const isMismatched = radiosData[index] === radioGropList.Mismatched;
                      return (
                        <HoverTableRow
                          key={row[geCode]}
                          id={`${id}-GETable-TableContainer-Table-TableBody-TableRow-${index}`}
                          data-selenium-id={`${id}-GETable-TableContainer-Table-TableBody-TableRow-${index}`}
                          sx={{
                            transition: '.5s',
                            '&:hover': {
                              background:
                                isMismatched ? MismatchedHoverColor : (flags === alertGeFlagType['Flags-Severe Issue']
                                  ? '#FFEFEF'
                                  : flags === alertGeFlagType['Flags-Mild Issue']
                                    ? '#FFF0DD'
                                    : MismatchedHoverColor),
                            },
                          }}
                        >
                          {isVerificationNotNone && <NEWStyledTableCell
                            id={`${id}-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell`}
                            data-selenium-id={`${id}-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell`}
                            className={styleModule.RadioBlock}
                          >
                            {isVerificationShow && (editing ? <RadioBlock
                              id={`${id}-FlaggedGESynchronizationAndVerificationTableDecisionRadioBlock${index}`}
                              data-selenium-id={`${id}-FlaggedGESynchronizationAndVerificationTableDecisionRadioBlock${index}`}
                              title=''
                              value={radiosData[index]}
                              setValue={(newValue: radioGropList) => {
                                // const newRadiosData = [...radiosData];
                                // newRadiosData[index] = newValue;
                                // setRadiosData(newRadiosData);
                                setRadiosData(preData => {
                                  const newRadiosData = [...preData];
                                  newRadiosData[index] = newValue;
                                  setValue(newRadiosData);
                                  return newRadiosData;
                                });
                              }}
                              error={error && (singleError ? !radiosData[index] : !radiosData?.some(i => Boolean(i)))}
                              radioGropList={[radioGropList.Matched, radioGropList.Mismatched]}
                            /> : <Box>{radiosData[index]}</Box>)}
                          </NEWStyledTableCell>}
                          {headerItems.map((item, headerItemsIndex) => {
                            //@ts-expect-error
                            const hasFlag: boolean = item.hasFlag;
                            const itemId = item.id;
                            return <NEWStyledTableCell
                            align='left'
                            sx={{ maxWidth: '160px' }}
                            id={`${id}-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell${headerItemsIndex}`}
                            data-selenium-id={`${id}-GETable-TableContainer-Table-TableBody-TableRow${index}-StyledTableCell${headerItemsIndex}`}
                              key={itemId}
                            {...(item.minWidth === item.maxWidth && { width: item.minWidth })}
                          >
                            <WrapWithFlag
                                //@ts-expect-error
                                hasFlag={hasFlag} withLink={item.withLink}
                              geCode={row.geCode}
                              flags={flags}
                              isMismatched={isMismatched}
                            >
                                {(() => {
                                  return hasFlag ? row[itemId] : decideLines((() => {
                                    switch (itemId) {
                                      case geDataName.email:
                                        return `${row[email]}${(() => { const secondaryEmail = row.secondaryEmail; return secondaryEmail ? `; ${secondaryEmail}` : ''; })()}`;
                                      case geDataName.fullName:
                                        // return `${row?.fullName ?? `${row?.lastName}, ${row?.firstName}`}`;  //CT-6690
                                        const { firstName, lastName } = row;
                                        return (firstName && lastName) ? `${lastName ?? ''}, ${firstName ?? ''}` : (row?.fullName ?? '');
                                      default:
                                        return row[itemId] ?? '';
                                    }
                                  })() as string, headerItemsIndex, itemId===geDataName.email?1:2);
                                })()}
                            </WrapWithFlag>
                            </NEWStyledTableCell>;
                          })
                          }
                        </HoverTableRow>
                      );
                    })
                  ) : (
                    <NoDataMessage
                          MarginTop='10px'
                          data-selenium-id={`${id}-SpecialIssueManagement-IssueTable-NoDataMessageAfterFiliter`}
                          message='No results were found.'
                    ></NoDataMessage>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
        {/* <ScrollBarY
          ref={scrollYRef}
          //@ts-expect-error
          contextHolderRef={ref}
          tableKey={'GEOverview'} /> */}
      </Box>
    </Box >
  );
});
export default GETable;
GETable.propTypes = freeze({
  // geDataResult: arrayOf(shape({
  //   geCode: string,
  //   fullName: string,
  //   primaryEmail: string,
  //   institute: string,
  //   alertGeFlagType: string,
  // })),
  // geDataResult:object,
  setValue: func,
  fetchData: func,
  editing: bool,
  error: bool,
  // headerItems: arrayOf(shape({
  //   id: string,
  //   label: string,
  //   isOrder: bool,
  //   minWidth: string,
  //   maxWidth: string,
  //   show: bool,
  //   stick: bool,
  //   stickPX: string,
  //   hasFlag: bool,
  //   withLink: bool,
  // })),
  VerificationStyle: oneOf([VerificationStyleEnum.show, VerificationStyleEnum.hidden, VerificationStyleEnum.none]),
  singleError: bool,
  tableMaxWidth: string,
});
GETable.defaultProps = freeze({
  geDataResult: [],
  setValue: () => { },
  fetchData: () => Promise.resolve(),
  editing: true,
  error: false,
  headerItems: [
    { ...createRow(geDataName.fullName, 'FULL NAME', false, '184px', '184px') },
    { ...createRow(geDataName.geCode, 'GE CODE', false, '160px', '160px'), hasFlag: true, },
    createRow(geDataName.institute, 'INSTITUTION'),
    createRow(geDataName.email, geDataName.email.toUpperCase(), false, '184px', '184px'),
  ],
  VerificationStyle: VerificationStyleEnum.show,
  singleError: false,
  tableMaxWidth: undefined,
});
// WithLink.propTypes = freeze({
//   geCode: string,
//   children: node,
//   withLink: bool,
// });
// WithLink.defaultProps = freeze({
//   geCode: '',
//   children: null,
//   withLink: false,
// });
// WrapWithFlag.propTypes = freeze({
//   hasFlag: bool,
//   // item: singleGeData,
//   withLink: bool,
//   children: node,
//   isMatched: bool,
// });
// WrapWithFlag.defaultProps = freeze({
//   hasFlag: false,
//   // item: null,
//   withLink: false,
//   children: null,
//   isMatched: true,
// });

import { Box, Stack } from "@mui/material";

export function TextBlock(props) {
  const {
    id = '',
    //该参数是设置title是否是disabled的
    disabled = false,
    // 用于设置文字的样式的
    // 这里你可以在textSX中设置CSS的任何属性
    // 也可以添加非CSS属性，如果添加不是CSS的属性，是不会有实际的变化的
    // 但是这种添加还是很有用的，比如 --disabled-color 该属性可以设置标题在disable下的颜色
    // 可集成其他属性,具体方法可以参考--disabled-color 的实现
    textSX = {},
    // 该参数是用于设置Stack组件的参数的，该stack是用来调整文字和svg图片的排列的
    // 目前只允许stack组件的参数spacing和direction
    // 可集成其他参数，具体方法可以参考spacing和direction的实现
    stackProps = {},
    // 下面两个参数分别表示 文字前后的 svg图片
    startAttachment = null,
    endAttachment = null,
    //该参数用于设置最外层盒子的样式
    boxSX = {},
  } = props;

  const color = textSX['color'] ?? '#596A7C';
  const disabledColor = textSX['--disabled-color'] ?? '#BCC5CF';

  return (
    <Box id={id} data-selenium-id={id} sx={boxSX}>
      <Stack
        spacing={stackProps?.spacing ?? 1.25}
        direction={stackProps?.direction ?? 'row'}
      >
        {startAttachment}
        <Box
          sx={{
            ...textSX,
            color: disabled ? disabledColor : color,
          }}
        >
          {props.children}
        </Box>
        {endAttachment}
      </Stack>
    </Box>
  );
}

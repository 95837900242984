import React, { useEffect, useState } from 'react';
import { SIMTButton } from '@/components/StyledButton/SIMTButton';
import { Stack, Typography } from '@mui/material';
import SIPReviseSaveButtonIcon from '@/assets/SIPReviseSaveButtonIcon.svg';
import { StickyBarContainer } from '../../RevisePage/CustomComponents/CustomStickyBar';
import SIModal from '../../../../modules/Modal';

export const DraftStickyBar = props => {
  const { handleSaveDraft, handleSubmit, handleCheck, ...others } = props;
  const confirmText = `Are you sure to submit the revision? After you click on "OK", you
    will not be able to access this page anymore.`;

  const [confirmSubmitModalOpen, setConfirmSubmitModalOpen] = useState(false);
  return (
    <StickyBarContainer {...others}>
      <Stack
        data-selenium-id='SIP_AcceptPage_Submodule-DraftStickyBar-Stack'
        direction={'row'}
        alignItems='center'
        justifyContent={'flex-end'}
        spacing={'24px'}
        height='64px'
      >
        <SIMTButton
          data-selenium-id='SIP_AcceptPage_Submodule-DraftStickyBar-SIMTButton1'
          variant='outlined'
          sx={{ border: '1px solid #DFE4E8', color: '#596A7C' }}
          startIcon={<SIPReviseSaveButtonIcon />}
          onClick={handleSaveDraft}
        >
          Save Draft
        </SIMTButton>
        <SIMTButton
          data-selenium-id='SIP_AcceptPage_Submodule-DraftStickyBar-SIMTButton2'
          variant='contained'
          sx={{ '&.MuiButton-root': { fontWeight: 400 } }}
          onClick={() => {
            if (handleCheck()) {
              return;
            }
            setConfirmSubmitModalOpen(true);
          }}
        >
          Submit My Decision
        </SIMTButton>
        <SIModal
          data-selenium-id='SIP_AcceptPage_Submodule-DraftStickyBar-SIModal'
          open={confirmSubmitModalOpen}
          title='Submit My Revision'
          height={'auto'}
          handleClose={() => setConfirmSubmitModalOpen(false)}
        >
          <Stack
            spacing={2}
            marginTop={2}
            data-selenium-id='SIP_AcceptPage_Submodule-DraftStickyBar-InnerStack'
          >
            <Typography
              sx={{ fontSize: '14px' }}
              data-selenium-id='SIP_AcceptPage_Submodule-DraftStickyBar-ConfirmTextTypography'
            >
              {confirmText}
            </Typography>
            <Stack
              direction={'row'}
              justifyContent='flex-end'
              spacing={2}
              data-selenium-id='SIP_AcceptPage_Submodule-DraftStickyBar-InnermostStack'
            >
              <SIMTButton
                onClick={() => setConfirmSubmitModalOpen(false)}
                data-selenium-id='SIP_AcceptPage_Submodule-DraftStickyBar-InnerButton1'
              >
                Cancel
              </SIMTButton>
              <SIMTButton
                data-selenium-id='SIP_AcceptPage_Submodule-DraftStickyBar-InnerButton2'
                variant='contained'
                sx={{ paddingX: '32px' }}
                onClick={() => {
                  handleSubmit();
                  setConfirmSubmitModalOpen(false);
                }}
              >
                OK
              </SIMTButton>
            </Stack>
          </Stack>
        </SIModal>
      </Stack>
    </StickyBarContainer>
  );
};

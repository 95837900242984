import { DecisionFoldBtn } from '../DecisionMyStyled';
import PropTypes from 'prop-types';
interface type {
  readonly sipCode: string;
  readonly sipStage: string;
}
export default function CheckListButton (props: type) {
  const { sipCode = '', sipStage = '', ...others } = props;
  return (
    <DecisionFoldBtn
      variant='contained'
      onClick={() => {
        window.open(`/simt/auth/sipRelated/sip/checklist/${sipCode?.trim()}/${sipStage?.trim()?.split(' ')?.[0]?.toLowerCase()}`, '_blank');
      }}
      // style={{ visibility: 'hidden' }}//M2S7
      {...others}
    >
      Checklist
    </DecisionFoldBtn>
  );
}

const { freeze } = Object;
CheckListButton.prototype = freeze({
  sipCode: PropTypes.string.isRequired,
  sipStage: PropTypes.string.isRequired,
});
CheckListButton.defaultProps = freeze({
  sipCode: '',
  sipStage: '',
});
export const GET_EMAILTEMPLATES = 'GET_EMAILTEMPLATES';
export const GET_CUSTOMIZE_EMAIL_TEMPLATES = 'GET_CUSTOMIZE_EMAIL_TEMPLATES';
export const EDIT_EMAILMANUALDETAIL = 'EDIT_EMAILMANUALDETAIL';
export const GET_EMAIL_SHOW_INFO = 'GET_EMAIL_SHOW_INFO';

export const GET_EMAIL_RED_DOTS = 'GET_EMAIL_RED_DOTS';
export const REMOVE_EMAIL_RED_DOT = 'REMOVE_EMAIL_RED_DOT';

export const UPLOAD_ATTACHMENT = 'UPLOAD_ATTACHMENT';
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';

export const SEND_EMAILRETURNCODE = 'SEND_EMAILRETURNCODE';

export const EMAIL_TEMPLATE_TAB_CHANGE = 'EMAIL_TEMPLATE_TAB_CHANGE';
export const GET_SIP_EMAIL_TEMPLATES = 'GET_SIP_EMAIL_TEMPLATES';
export const GET_SOLICITED_SIP_EMAIL_TEMPLATES = 'GET_SOLICITED_SIP_EMAIL_TEMPLATES';
export const GET_SOLICITED_SIP_CUSTOMIZE_EMAIL_TEMPLATES = 'GET_SOLICITED_SIP_CUSTOMIZE_EMAIL_TEMPLATES';
export const GET_SIP_CUSTOMIZE_EMAIL_TEMPLATES = 'GET_SIP_CUSTOMIZE_EMAIL_TEMPLATES';
export const EDIT_SIP_EMAIL_TEMPLATES = 'EDIT_SIP_EMAIL_TEMPLATES';
export const EDIT_SOLICITED_SIP_EMAIL_TEMPLATES = 'EDIT_SOLICITED_SIP_EMAIL_TEMPLATES';

export function switchEmailTemplateTab(data) {
  return async dispatch => {
    dispatch({ type: EMAIL_TEMPLATE_TAB_CHANGE, data: data });
  };
}


import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { handleLinkWithoutProtocol } from '@/utils/commonUtils';
import { Box, Link, TableContainer, Tooltip } from '@mui/material';
import { LimitWord } from '../CGTComponents/CommonStyle';
import { Scrollbars } from 'react-custom-scrollbars';

const StyledTableCell = styled(TableCell)(props => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F1F3F5',

    color: '#596A7C',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    padding: '0px 24px',
    borderBottom:'none',
    boxShadow: '0px -1px 0px 0px #BCC5CF inset'

  },
  [`&.${tableCellClasses.root}`]: {
    height: '42px',
    boxSizing: 'content-box',
  },
  [`&.${tableCellClasses.body}`]: {
    padding: '0px 24px',
    ...LimitWord,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function CreateAndUpdateTable(props) {
  const { head, rows, identifier } = props;
  
  const [stateHover,setStateHover] = React.useState(false);
  return (
    <Scrollbars
      data-selenium-id='SIPage_CreateAndUpdateSI-Table-box'
      style={{ height: '87px' }}
      renderTrackVertical={({ style, ...props }) => (
        <div
          style={{
            ...style,
            position: 'absolute',
            height: '0px',
            width: '0px',
          }}
          {...props}
        ></div>
      )}
      renderTrackHorizontal={({ style, ...props }) => (
        <div
          style={{
            ...style,
            position: 'absolute',
            width: '100%',
            bottom: '0',
            height: stateHover?'6px':'0px'
          }}
          {...props}
        ></div>
      )}
      renderThumbHorizontal={({ style, ...props }) => (
        <div
          style={{
            ...style,
            backgroundColor: '#DFE4E8',
            opacity: '0.7',
            borderRadius: '10px',
            height: '6px',
          }}
          {...props}
        ></div>
      )}
      renderView={({ style, ...props }) => (
        <div
          style={{
            ...style,
            overflowY: 'hidden',
            overflowX: 'hidden',
            height: '87px',
            width:'100%'
          }}
          {...props}
        ></div>
      )}
      thumbSize={250}
      onMouseEnter={()=>{
        setStateHover(true);
      }}
      onMouseLeave={()=>{
        setStateHover(false);
      }}
    >
      <Table
        data-selenium-id='SIPage_CreateAndUpdateSI-Table'
        sx={{
          borderCollapse: 'separate',
          borderRadius: '8px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: '#DFE4E8',
          width:'calc(100% - 5px)'
        }}
        aria-label='customized table'
      >
        <TableHead data-selenium-id='SIPage_CreateAndUpdateSI-TableHead'>
          <TableRow data-selenium-id='SIPage_CreateAndUpdateSI-TableHeadRow'>
            {head.map((item, index) => {
              return item.value === '' ? null : index === 0 ? (
                <StyledTableCell
                  data-selenium-id='SIPage_CreateAndUpdateSI-TableHeadCell'
                  align={item.align}
                  index={index}
                  length={head.length}
                  sx={{
                    borderRadius:'8px 0px 0px 0px'
                  }}
                >
                  {item.value}
                </StyledTableCell>
              ) : (
                <StyledTableCell
                  data-selenium-id='SIPage_CreateAndUpdateSI-TableCell'
                  align={item.align}
                  sx={{
                    borderRadius:index === head.length -1 ?'0px 8px 0px 0px':'0px'
                  }}
                >
                  {item.value}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody data-selenium-id='SIPage_CreateAndUpdateSI-TableBody'>
          {rows.map(row => (
            <StyledTableRow data-selenium-id='SIPage_CreateAndUpdateSI-TableBodyRow'>
              {head.map((item, index) => {
                return item.value === '' ? null : index === 0 || index === 1 ? (
                  <Tooltip
                    data-selenium-id='SIPage_CreateAndUpdateSI-TableBody-Tooltip'
                    title={
                      row[item.property] !== null && row[item.property] !== ''
                        ? row[item.property]
                        : '--'
                    }
                  >
                    <StyledTableCell
                      data-selenium-id='SIPage_CreateAndUpdateSI-TableBodyCell'
                      component='th'
                      scope='row'
                      align={item.align}
                      sx={{
                        maxWidth: item.maxWidth,
                        minWidth: item.minWidth,
                        width: item.minWidth,
                      }}
                      index={index}
                      length={head.length}
                    >
                      {row[item.property] !== null && row[item.property] !== ''
                        ? row[item.property]
                        : '--'}
                    </StyledTableCell>
                  </Tooltip>
                ) : (
                  <StyledTableCell
                    data-selenium-id='SIPage_CreateAndUpdateSI-TableBodyCell'
                    align={item.align}
                    sx={{
                      maxWidth: item.maxWidth,
                      minWidth: item.minWidth,
                      width: item.minWidth,
                    }}
                    index={index}
                    length={head.length}
                  >
                    {item.property === 'issuePublicationLink' ? (
                      <Tooltip
                        title={row[item.property]}
                        data-selenium-id='SIPage_CreateAndUpdateSI-TableBody-Tooltip'
                      >
                        <Link
                          data-selenium-id='SIPage_CreateAndUpdateSI-TableBody-Tooltip-Link'
                          href={handleLinkWithoutProtocol(
                            row[item.property] && row[item.property] !== ''
                              ? row[item.property]
                              : '--'
                          )}
                          target='_blank'
                          color='#154AB6'
                          sx={{
                            textDecoration: 'none !important',
                          }}
                        >
                          <Box
                            data-selenium-id='SIPage_CreateAndUpdateSI-TableBody-Tooltip-Box'
                            sx={{
                              fontWeight: '600',
                              letterSpacing: '0.01em',
                              textDecoration: 'none',
                            }}
                          >
                            {row[item.property] && row[item.property] !== ''
                              ? row[item.property]
                              : '--'}
                          </Box>
                        </Link>
                      </Tooltip>
                    ) : row[item.property] !== null &&
                      row[item.property] !== '' ? (
                      row[item.property]
                    ) : (
                      '--'
                    )}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Scrollbars>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { defaultScrollContext } from "../../reducers/CommonReducer";

export const ScrollBarContainerY = styled(Box)({
  position: 'absolute',
  top: '6px',
  bottom: '6px',
  right: '3px',
  width: '26px',
  zIndex: 1000,
  // backgroundColor: 'rgba(0,0,0,0.2)',
  // height: 'calc(100vh - 142px)',
  overflowY: 'auto',
  '& .scroll-bar-inner-box': {
    width: '100%',
  },
  '& ::-webkit-scrollbar-track,::-webkit-scrollbar-track:hover,::-webkit-scrollbar-track:active':{
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  '& ::-webkit-scrollbar,& ::-webkit-scrollbar-thumb,& ::-webkit-scrollbar:hover,::-webkit-scrollbar-thumb:hover':{
    backgroundColor: '#BCC5CF',
    borderRadius: '6px',
  },
});

const ScrollBarY = React.forwardRef((props, ref) => {
  const isFirefox = navigator.userAgent.includes('Firefox');
  const isChrome = /Mozilla/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent);
  // if (isFirefox || isChrome){
  if (isFirefox){
    return <></>;
  }

  const selector = state => {
    return {
      // scrollContext: state.Common.scrollContext,
      scrollContextMap: state.Common.scrollContextMap,
    };
  };

  const {
    // scrollContext,
    scrollContextMap,
  } = useSelector(selector);

  const {contextHolderRef,tableKey, ...others} = props;

  const scrollBoxRef = useRef(null);
  const scrollBoxInnerRef = useRef(null);

  const [transPercent, setTransPercent] = useState(1);

  useEffect(() => {

    const tableContext = scrollContextMap[tableKey || 'default'] ?? defaultScrollContext;
    const scrollBoxElement = scrollBoxRef.current;
    const scrollBoxInnerElement = scrollBoxInnerRef.current;

    setTransPercent(tableContext.boxHeight / scrollBoxElement.offsetHeight);
    scrollBoxInnerElement.style.height = (tableContext.contextHeight / tableContext.boxHeight * scrollBoxElement.offsetHeight) + 'px';
  }, [scrollContextMap, tableKey]);

  const [isScrolling, setScrolling] = useState(false);

  const handleScroll =  event => {
    if (isScrolling) return;
    setScrolling(true);
    const scrollBoxElement = scrollBoxRef.current;
    if (!contextHolderRef) return;
    const offset = scrollBoxElement.scrollTop;

    const containerBoxRef = contextHolderRef.current?.getContextRef?contextHolderRef.current.getContextRef():contextHolderRef;
    const containerBoxElement = containerBoxRef.current;
    containerBoxElement.scrollTop = offset * transPercent;
    setTimeout( () => {
      setScrolling(false);
    }, 100);
  };

  const scrollAsContext = () => {
    if (isScrolling) return;
    if (!contextHolderRef) return;

    const containerBoxRef = contextHolderRef.current?.getContextRef ? contextHolderRef.current.getContextRef() : contextHolderRef;
    const containerBoxElement = containerBoxRef.current;
    const scrollBoxElement = scrollBoxRef.current;
    if (!containerBoxElement) return;

    setScrolling(true);
    const contextOffset = containerBoxElement.scrollTop;
    scrollBoxElement.scrollTop = contextOffset / transPercent;
    setTimeout( () => {
      setScrolling(false);
    }, 100);
  };

  React.useImperativeHandle(ref, () => ({
    scrollAsContext,
    scrollBoxRef,
    scrollBoxInnerRef
  }));

  return <ScrollBarContainerY
    className="scroll-bar-container-box"
    ref={scrollBoxRef}
    onScroll={handleScroll}
    {...others}
  >
    <Box className="scroll-bar-inner-box" ref={scrollBoxInnerRef}/>
  </ScrollBarContainerY>;
});

export default ScrollBarY;

import { Box, Stack } from '@mui/material';
import RequiredTag from './SVGComponents/requiredTag.svg';
import { MiniBoldFont, MiniFont } from '@/components/FontStyle';
import ErrorTag from './SVGComponents/ErrorTag.svg';
import { TextBlock } from './TextBlock';

export const defaultTitleSX = {
  ...MiniBoldFont,
  letterSpacing: '0.08em',
  textTransform: 'capitalize',
  color: '#596A7C',
  '--disabled-color': '#BCC5CF',
};

export const defaultErrorSX = {
  ...MiniFont,
  lineHeight: '16px',
  color: '#EE5350',
  display: 'flex',
  alignItems: 'center',
  letterSpacing: ' -0.084px',
};

export const defualtTitleStackProps = {
  spacing: 1.25,
  direction: 'row',
};

export const defualtErrorStackProps = {
  spacing: 0.125,
  direction: 'row',
};

export default function ItemBlock(props) {
  const {
    id = '', // id
    mode = 0, // 控制错误信息是显示在标题下方还是 操作框下方
    isError = false, // 是否存在错误
    required = false, // 是否是必填项
    disabled = false, // 是否禁用
    title = '', // 标题的内容
    errorText = '', // 错误信息
    stackProps = {},
    // direction 控制排列方式，可选row和column ；spacing控制距离 1:8px
    // first, // first 控制标题和操作框
    // second, // second控制标题/操作和error信息，

    // 自定义标题,只需要设置需要的参数即可
    titleProps = {},
    // idTitle , //自定义title的id，默认情况是根据id生成的
    // titleSX , //自定义title的样式，默认值为defaultTitleSX
    // stackProps , //自定义标题文字和必填标志“*"的排列方式和距离，默认值为defaultTitleStackProps
    // requiredTag, //自定义必填标记，组件

    //自定义error,只需要设置需要的参数即可
    errorProps = {},
    // idError, //自定义error信息的id，默认情况根据id生成
    // errorSX, //自定义error的样式，默认值为defaultErrorSX，默认值为defaultErrorStackProps
    // stackProps, //自定义错误信息和错误标志的排列方式和距离
    // errorTag, //由于目前只见过前置的错误标志，所以这个参数只能设置前置标志，组件
    requiredTag=<RequiredTag></RequiredTag>
  } = props;

  const TitleBlock = (
    <TextBlock
      id={titleProps?.idTitle ?? `${id}_title`}
      disabled={disabled ?? false}
      textSX={titleProps?.titleSX ?? defaultTitleSX}
      stackProps={titleProps?.stackProps ?? defualtTitleStackProps}
      endAttachment={
        required ? (
          requiredTag === undefined ? (
            <RequiredTag></RequiredTag>
          ) : (
            requiredTag
          )
        ) : null
      }
      boxSX={titleProps?.boxSX ?? {}}
    >
      {title ? title : ''}
    </TextBlock>
  );

  const ErrorBlock = (
    <TextBlock
      id={errorProps?.idError ?? `${id}_error`}
      textSX={errorProps?.errorSX ?? defaultErrorSX}
      stackProps={errorProps?.stackProps ?? defualtErrorStackProps}
      startAttachment={
        //这里errorTag = null 是一个正确的值，所以这里 空位合并操作符
        errorText === '' ? null : errorProps?.errorTag === undefined ? (
          <Box sx={{ width: '12px', height: '16px' }}>
            <ErrorTag></ErrorTag>
          </Box>
        ) : (
          errorProps.errorTag
        )
      }
    >
      {errorText ? errorText : ''}
    </TextBlock>
  );

  return (
    <Box id={id} data-selenium-id={id}>
      <Stack
        spacing={stackProps?.first?.spacing ?? 1}
        direction={stackProps?.first?.direction ?? 'column'}
      >
        {/* Title */}
        <Stack
          spacing={stackProps?.second?.spacing ?? '0'}
          direction={stackProps?.second?.direction ?? 'column'}
          sx={stackProps?.second?.stackSX ?? {}}
        >
          {TitleBlock}
          {mode === 1 && isError ? ErrorBlock : null}
        </Stack>
        {/* Input Components */}
        <Stack
          spacing={stackProps?.second?.spacing ?? '0'}
          direction={stackProps?.second?.direction ?? 'column'}
          sx={{width:'100%'}}
        >
          {props.children}
          {mode === 0 && isError ? ErrorBlock : null}
        </Stack>
      </Stack>
    </Box>
  );
}

import {axios_instance} from "@/utils/axios_instance";
import {
    APPROVAL_WAITINGFORAPPROVAL,
    GET_NEWSUBMISSION_OVERVIEW,
    GET_WAITINGFORAPPROVAL_DATA,
    LOAD_HISTORY_REQUEST_DATA,
    REJECT_WAITINGFORAPPROVAL,
    SUBMITNEWSUBMISSION
} from "@/actions/SIMT-Report/ReportJobsSubmission/ReportJobsAction";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import getAuth from "@/utils/getAuth";

export function loadHistoryRequsetData(page, pageSize, orderType) {
    let params = {
        pageNum: page,
        pageSize: pageSize,
        orderType: orderType,
    };
    return async dispatch => {
        try {
            let res = await axios_instance.get(
                '/reports/jobs/history-request/overview',
                {
                    params: params,
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );

            if (res.data.code === 200) {
                dispatch({type: LOAD_HISTORY_REQUEST_DATA, data: res.data.data});
            } else {
                dispatch({type: LOAD_HISTORY_REQUEST_DATA, data: []});
            }
        } catch (e) {
            dispatch({type: LOAD_HISTORY_REQUEST_DATA, data: []});
        }
    };
}

/**
 * @author LiuXin
 * @date 2022/6/22
 * @description 获取Report Jobs New Submission表展示的信息
 */
export function getNewSubmissionInfo() {
    return async dispatch => {
        try {
            const url = '/reports/jobs/new-submission/overview';
            const res = await axios_instance.get(url, {
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: GET_NEWSUBMISSION_OVERVIEW, data: res.data.data});
            } else {
                dispatch({type: GET_NEWSUBMISSION_OVERVIEW, data: []});
            }
        } catch (e) {
            dispatch({type: GET_NEWSUBMISSION_OVERVIEW, data: []});
        }
    };
}

/**
 * @author LiuXin
 * @date 2022/6/22
 * @description new submission页面的report name提交
 * @param {string} reportName 提交的report name
 * @param {date} selectedMonth 选择的月份
 */
export function submitNewSubmission(reportName, selectedMonth, flag) {
    let year = selectedMonth.getFullYear();
    let month = selectedMonth.getMonth() + 1;
    let selectedMonthFormat = month < 10 ? year + '0' + month : year + '' + month;
    return async dispatch => {
        try {
            const url = '/reports/jobs/new-submission/submit';
            const res = await axios_instance.get(url, {
                params: {
                    reportName: reportName,
                    selectMonth: selectedMonthFormat,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.data.code === 200) {
                dispatch({
                    type: SUBMITNEWSUBMISSION,
                    data: {flag: flag, result: 'OK'},
                });
                dispatch(getNewSubmissionInfo());
            } else {
                dispatch({
                    type: SUBMITNEWSUBMISSION,
                    data: {flag: flag, result: 'ERROR'},
                });
            }
        } catch (e) {
            dispatch({
                type: SUBMITNEWSUBMISSION,
                data: {flag: flag, result: 'ERROR'},
            });
        }
    };
}

export function getWaitingForApprovalInfo(orderType) {
    return async dispatch => {
        try {
            const url =
                '/reports/jobs/waiting-for-approve/overview?orderType=' + orderType;
            const res = await axios_instance.get(url, {
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: GET_WAITINGFORAPPROVAL_DATA, data: res.data.data});
            } else {
                dispatch({type: GET_WAITINGFORAPPROVAL_DATA, data: []});
            }
        } catch (e) {
            dispatch({type: GET_WAITINGFORAPPROVAL_DATA, data: []});
        }
    };
}

export function handleWaitingForApprovalApproveOrReject(
    rejectReason,
    reportName,
    selectMonth,
    status,
    everyClick
) {
    return async dispatch => {
        try {
            let res = await axios_instance.post(
                '/reports/jobs/waiting-for-approve/approve?reportName=' +
                reportName +
                '&selectMonth=' +
                selectMonth +
                '&status=' +
                status,
                {
                    rejectReason,
                },
                {
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                if (status === 'Approve') {
                    dispatch({
                        type: APPROVAL_WAITINGFORAPPROVAL,
                        data: {Approve: 'ApprovalOK', Reject: '', Every: everyClick},
                    });
                } else if (status === 'Reject') {
                    dispatch({
                        type: REJECT_WAITINGFORAPPROVAL,
                        data: {Approve: '', Reject: 'RejectOK', Every: everyClick},
                    });
                }
                dispatch(getWaitingForApprovalInfo());
            } else {
                if (status === 'Approve') {
                    dispatch({
                        type: APPROVAL_WAITINGFORAPPROVAL,
                        data: {Approve: 'ApprovalErr', Reject: '', Every: everyClick},
                    });
                } else if (status === 'Reject') {
                    dispatch({
                        type: REJECT_WAITINGFORAPPROVAL,
                        data: {Approve: '', Reject: 'RejectErr', Every: everyClick},
                    });
                }
                dispatch(getWaitingForApprovalInfo());
                dispatch(
                    setSnackbarMessageAndOpen(
                        'reportJobs.failToApproveOrReject',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch {
            if (status === 'Approve') {
                dispatch({
                    type: APPROVAL_WAITINGFORAPPROVAL,
                    data: {Approve: 'ApprovalErr', Reject: '', Every: everyClick},
                });
            } else if (status === 'Reject') {
                dispatch({
                    type: REJECT_WAITINGFORAPPROVAL,
                    data: {Approve: '', Reject: 'RejectErr', Every: everyClick},
                });
            }
            dispatch(getWaitingForApprovalInfo());
            dispatch(
                setSnackbarMessageAndOpen(
                    'reportJobs.failToApproveOrReject',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}
import React from 'react';
import { Box, Stack } from '@mui/material';
import { cssFrameworkHeader, cssFrameworkHeaderLeftBox, cssFrameworkHeaderRightBox } from '../../Css/Framework';

export default function FrameworkHeader({ children, padding = false }) {
  const childrenArray = React.Children.toArray(children);
  const leftContent = childrenArray.slice(0, 2);
  const rightContent = childrenArray.slice(2, 5);

  return (
    <Box sx={padding ?
      { ...cssFrameworkHeader, paddingTop: '20px', zIndex: 9 }
      : { ...cssFrameworkHeader, zIndex: 9 }}
    >
      {/* 包含Reset & Adapt Filters 两个按钮 */}
      <Box sx={cssFrameworkHeaderLeftBox}>
        <Stack spacing={1.5} direction='row'>
          {leftContent}
        </Stack>
      </Box>
      {/* 包含Reset my filter & save as my filter & × 三个按钮 */}
      <Box sx={cssFrameworkHeaderRightBox}>
        <Stack spacing={2} direction='row'>
          {rightContent}
        </Stack>
      </Box>
    </Box>
  );
}

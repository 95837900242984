import {
  Box,
  InputBase,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewDesignedMain } from '../../../components/Main';
import ATTable from './ATTable';
import SelectIcon from '../../../assets/selectIcon.svg';
import {
  clear,
  setFiscalYear,
} from '../../../actions/SIMT-Report/Target/AnnualTargetAction';
import { getFyYearFromDate } from "../../../utils/date_string";
import {getATInfo} from "@/actions/SIMT-Report/Target";

//自定义的选择输入InputBase
export const CustomizedInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    height: '20px',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#E3E8F2',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    padding: '6px 30px 6px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
}));

export default function AnnualTarget(_props) {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const selector = state => {
    return {
      fiscalYear: state.AnnualTarget.fiscalYear,
      open: state.UserManage.siderOpen,
      uploadFileResult: state.AnnualTarget.uploadFileResult,
      uploadFailMessage: state.AnnualTarget.uploadFailMessage,
      ATInfo: state.AnnualTarget.ATInfo,
      emptyFileError: state.AnnualTarget.emptyFileError,
    };
  };

  const {
    fiscalYear,
    open,
    uploadFileResult,
    uploadFailMessage,
    ATInfo,
    emptyFileError,
  } = useSelector(selector);

  // const selectMonthDate = useSelector(state => state.Report.selectDate);
  // const [fiscalYear, setSelectFY__] = useState(getFyYearFromDate(selectMonthDate));
  // useEffect(() => {
  //   if (selectMonthDate != null) {
  //     const fyYear = getFyYearFromDate(selectMonthDate);
  //     setSelectFY__(fyYear);
  //   }
  // }, [selectMonthDate]);

  useEffect(() => {
    setLoading(false);
    if (uploadFileResult === true) {
      setSuccess(true);
      dispatch(clear());
      let fy = 'FY' + (fiscalYear % 1000);
      dispatch(getATInfo(fy));
    } else if (uploadFileResult === false) {
      setError(true);
      dispatch(clear());
      let fy = 'FY' + (fiscalYear % 1000);
      dispatch(getATInfo(fy));
    }
  }, [uploadFileResult]);

  useEffect(() => {
    setLoading(false);
  });

  useEffect(() => {
    let fy = 'FY' + (fiscalYear % 1000);
    dispatch(getATInfo(fy));
  }, [fiscalYear]);

  let date = new Date();
  //今年、前两年和未来两年年份列表
  const yearList =
    date.getMonth() + 1 > 4
      ? [
          {
            value: date.getFullYear() - 1,
            text: 'FY' + ((date.getFullYear() - 1) % 1000),
          },
          {
            value: date.getFullYear(),
            text: 'FY' + (date.getFullYear() % 1000),
          },
          {
            value: date.getFullYear() + 1,
            text: 'FY' + ((date.getFullYear() + 1) % 1000),
          },
          {
            value: date.getFullYear() + 2,
            text: 'FY' + ((date.getFullYear() + 2) % 1000),
          },
          {
            value: date.getFullYear() + 3,
            text: 'FY' + ((date.getFullYear() + 3) % 1000),
          },
        ]
      : [
          {
            value: date.getFullYear() - 2,
            text: 'FY' + ((date.getFullYear() - 2) % 1000),
          },
          {
            value: date.getFullYear() - 1,
            text: 'FY' + ((date.getFullYear() - 1) % 1000),
          },
          {
            value: date.getFullYear(),
            text: 'FY' + (date.getFullYear() % 1000),
          },
          {
            value: date.getFullYear() + 1,
            text: 'FY' + ((date.getFullYear() + 1) % 1000),
          },
          {
            value: date.getFullYear() + 2,
            text: 'FY' + ((date.getFullYear() + 2) % 1000),
          },
        ];
  //处理选择框选择事件
  const handleFYChange = e => {
    dispatch(setFiscalYear(e.target.value));
    setLoading(true);
  };

  const handleEmptyClose = () => {
    dispatch(emptyFileError(false));
  };

  const CustomizedInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      height: '20px',
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#FFFFFF',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '20px',
      padding: '6px 30px 6px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
  }));
  return (
    <Box
      p={'0px 24px 26px 24px'}
      style={{ backgroundColor: '#fff', height: '85vh' }}
    >
      <NewDesignedMain
        id='annual-target-overview-paper'
        data-selenium-id='AnnualTarget_Overview-PaperNewDesignedMain'
        open={open}
        sx={{ p: '12px 0px' }}
      >
        <Typography
          id='annual-target-overview-title'
          data-selenium-id='AnnualTarget_Overview-TitleTypography'
          sx={{
            fontWeight: 600,
            fontSize: '22px',
            lineHeight: '125%',
            letterSpacing: '0.02em',
            color: '#262E35',
          }}
        >
          Annual Target
        </Typography>
        <Box
          id='annualTargetOverviewSelectBox'
          data-selenium-id='AnnualTarget_Overview-SelectBox'
          display={'flex'}
          mt={'16px'}
          mb={'16px'}
          sx={{ flexDirection: 'column', justifyContent: 'flex-start' }}
        >
          <Typography
            id='annualTargetOverviewSelectTitle'
            data-selenium-id='AnnualTarget_Overview-SelectTitleTypography'
            fontWeight={600}
            fontSize={'14px'}
            lineHeight={'20px'}
            sx={{ color: '#596A7C', letterSpacing: '1px', marginBottom: '4px' }}
          >
            Fiscal Year
          </Typography>
          <Select
            id='annualTargetOverviewSelect'
            data-selenium-id='AnnualTarget_Overview-Select'
            value={fiscalYear}
            onChange={handleFYChange}
            input={<CustomizedInput sx={{ backgroundColor: '#FFFFFF' }} />}
            sx={{
              color: '#596A7C',
              width: '240px',
              height: '36px',
              '&.MuiSelect-select': {},
              border: '1px solid #DFE4E8',
            }}
            IconComponent={SelectIcon}
          >
            {yearList.map((year, index) => (
              <MenuItem
                id={`annualTargetOverviewSelectItem${index}`}
                data-selenium-id={`AnnualTarget-OverviewSelectItem-${index}`}
                value={year.value}
              >
                {year.text}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <ATTable data={ATInfo} loading={loading} setLoading={setLoading} />
      </NewDesignedMain>
      {/* <ErrorAlert open={error} setOpen={setError} message={uploadFailMessage}/> */}
      {/* <SuccessAlert open={success} setOpen={setSuccess} /> */}
      {/*<ErrorAlertForEmpty open = {emptyFileError} setOpen={handleEmptyClose}/>*/}
    </Box>
  );
}

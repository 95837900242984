// @ts-check
import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect } from "react";
import { SIMTButton } from '../../../components/StyledButton/SIMTButton';
// @ts-ignore
import PeopleImage from '../../../assets/SIPPage/ThankYouPage/People.webp';
import SubmitCompletedPage, {
  buttonStyle,
  textStyle,
} from './SubmitCompletedPage';

/**
 *
 * @param {import("./ThankYouPage").ThankYouPageProps} props
 * @returns
 */
const ThankYouPage = props => {
  const {
    showCreateProposalButton = true,
    handleCreateProposal = () => {},
    theme = 'blue', // 三种主题颜色可选：blue, green, light
    detailText = <></>,
    isSSIP=false,
    ...others
  } = props;

  const matches900 = useMediaQuery('(min-width:900px)');
  const matches425 = useMediaQuery('(min-width:425px)');

  useEffect(()=>{
    document.body.classList.add('hide-snackbar');
    return () => {
      document.body.classList.remove('hide-snackbar');
    };
  },[]);

  return (
    <SubmitCompletedPage theme={theme} {...others}>
      <Stack
        data-selenium-id='SIP_SubmitComplete-ThankYouPage-Stack'
        direction={matches900 ? 'row' : 'column'}
        sx={{
          position: 'relative',
          zIndex: 0,
          minHeight: '100%',
          paddingX: matches900 ? '128px' : '32px',
        }}
        alignItems='center'
        spacing={matches900 ? '32px' : '64px'}
        paddingY={matches900 ? 0 : '128px'}
      >
        <Stack
          data-selenium-id='SIP_SubmitComplete-ThankYouPage-StartStack'
          sx={{
            width: matches900 ? '54%' : '100%',
            color: '#fff',
            flexShrink: 0,
            ...textStyle[theme],
          }}
          alignItems='flex-start'
          spacing='32px'
        >
          <Typography
            data-selenium-id='SIP_SubmitComplete-ThankYouPage-StartStack-Typography1'
            sx={{ fontSize: matches425 ? '56px' : '44px', fontWeight: 800 }}
          >
            Thank you!
          </Typography>
          <Typography
            data-selenium-id='SIP_SubmitComplete-ThankYouPage-StartStack-Typography2'
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              '& a': {
                fontWeight: 600,
                textDecoration: 'underline',
                color: 'inherit',
              },
            }}
          >
            {detailText}
          </Typography>
          {/* Create a new proposal Button Removed in CT-7165*/}
          {/* {!isSSIP?<SIMTButton
            data-selenium-id='SIP_SubmitComplete-ThankYouPage-StartStack-SIMTButton'
            className='Create-button'
            sx={{
              backgroundColor: '#fff',
              padding: '16px 32px',
              fontSize: '16px',
              fontWeight: 700,
              visibility: showCreateProposalButton ? 'visible' : 'hidden',
              '&:hover': {
                color: '#fff',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
              ...buttonStyle[theme],
            }}
            onClick={handleCreateProposal}
          >
            Create a new proposal
          </SIMTButton>:<></>} */}
        </Stack>
        <Stack
          sx={{ flexGrow: 1 }}
          direction='row'
          justifyContent='flex-end'
          data-selenium-id='SIP_SubmitComplete-ThankYouPage-EndStack'
        >
          <Box
            data-selenium-id='SIP_SubmitComplete-ThankYouPage-EndStack-Box'
            maxWidth={'460px'}
            marginRight='8px'
            sx={{ boxSizing: 'border-box', position: 'relative' }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '-16px',
                right: '-8px',
                width: '224px',
                height: '241px',
                zIndex: 2,
                backgroundColor:
                  theme === 'light' ? '#DFE4E8' : 'rgba(255, 255, 255, 0.5)',
                borderRadius: '6px',
              }}
            />
            <img
              data-selenium-id='SIP_SubmitComplete-ThankYouPage-EndStack-InnerBox-Img'
              style={{
                objectFit: 'cover',
                width: '100%',
                position: 'relative',
                zIndex: 3,
              }}
              src={PeopleImage}
              alt='people'
            />
          </Box>
        </Stack>
      </Stack>
    </SubmitCompletedPage>
  );
};

export default ThankYouPage;

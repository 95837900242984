import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className || '' }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    width: '24px', // 修改箭头的宽度
    height: '18px', // 修改箭头的高度
    '&::before':{
      backgroundColor: '#596A7C',
    }
    
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#596A7C',
    borderRadius: '5px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '8px 16px',
    // marginBottom: '3px !important',
  },
}));

//该自定义tooltip用于，调整SIP-detail页面GE-Information处删除GE按钮的tooltip与按钮的距离 
export const GeInformationBlockDeleteTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className || '' }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginBottom: '2px !important',
  },
}));

export const CustomThinTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className || '' }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    width: '24px', // 修改箭头的宽度
    height: '18px', // 修改箭头的高度
    // left: '-4px !important',
    '&::before':{
      backgroundColor: '#596A7C',
    }
    
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#596A7C',
    borderRadius: '5px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '4px 8px',
    left: '-8px !important',
    // marginBottom: '3px !important',
  },
}));

export const ThinTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className || '' }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#596A7C',
    width: '6px',
    height: '3px',
    top: '28px',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#596A7C',
    borderRadius: '5px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '4px 8px',
    boxSizing: 'border-box',
    marginBottom: '-4px !important',
    maxWidth: "none"
  },
  // '& .MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltip': {
  //   marginBottom: "0px"
  // }
}));

export const CustomTooltipWithoutArrow = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`&[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
    marginBottom: '9px',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#596A7C',
    borderRadius: '5px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '8px 16px',

  },
}));


import { Button, buttonClasses, styled } from '@mui/material';

export const buttonTextStyleEdit = color => {
  return {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    color: color,
  };
};
export const EditBtnAvailable = styled(Button)({
  ...buttonTextStyleEdit('#F1F3F5'),
  [`&.${buttonClasses.root}`]: {
    textTransform: 'capitalize',
    boxShadow: 'none',
    borderRadius: '5px',
    fontWeight: 600,
    backgroundColor: '#154AB6',
    '&:hover': {
      backgroundColor: '#113b92',
    },
  },
  width: '82px',
  height: '36px',
  variant: 'contained',
  padding: '8px 18px',
  alignItems: 'center',
  opacity: 1,
});

export const EditBtnDisabled = styled(Button)({
  ...buttonTextStyleEdit('#F1F3F5 !important'),
  [`&.${buttonClasses.root}`]: {
    textTransform: 'capitalize',
    boxShadow: 'none',
    borderRadius: '5px',
    fontWeight: 600,
  },
  width: '82px',
  height: '36px',
  variant: 'contained',
  backgroundColor: '#BCC5CF',
  '&:hover': {
    backgroundColor: '#BCC5CF',
  },
  padding: '8px 18px',
  alignItems: 'center',
  opacity: 1,
});

export const CancelButton = styled(Button)(() => ({
  [`&.${buttonClasses.root}`]: {
    boxShadow: 'none',
    borderRadius: '5px',
    fontWeight: 600,
    padding: '8px 18px',
    textTransform: 'none',
    fontSize: '14px',
    marginRight: '10px',
    color: '#154AB6',
    lineHeight: '20px',
  },
  width: '46px',
  height: '20px',
}));

import {SIPFormState} from "@/reducers/SIPFormReducer";
import {useDispatch, useSelector} from "react-redux";
import Card from "@/pages/SIPForm/components/Card";
import {MultiSelectableInputBox, SelectableInputBox} from "@/pages/SIPForm/components/SelectableInputBox";
import {FormGroup} from "@/pages/SIPForm/components/FormGroup";
import {validateNotEmpty} from "@/pages/SIPForm/utils/validate_utils";
import {Journal, SectionState, SpecialIssueInformation} from "@/pages/SIPForm/types.d";
import {
    loadJournalByName,
    loadSubjectByName,
    setSIPFormSectionState,
    setSIPFormValue
} from "@/actions/SIMT-SIP/SIPForm";
import {InputBox, MultiLineInputBox} from "@/pages/SIPForm/components/InputBox";
import {InputBoxList} from "@/pages/SIPForm/components/InputBoxList";
import {useEffect} from "react";
import {useIntl} from "react-intl";

export interface SpecialIssueInfoSectionProps {

}

export function SpecialIssueInfoSection(props: SpecialIssueInfoSectionProps) {
    const params = new URLSearchParams(location.search);
    const id_token = params.get('id_token');

    const selector = (state: {
        SIPForm: SIPFormState;
        [key: string]: any;
    }) => state.SIPForm;

    const {
        formData,
        options: allOptions,
        state: formStatus,
    } = useSelector(selector);

    const {
        specialIssueInformation,
    } = formData;

    const dispatch = useDispatch();
    const intl = useIntl();

    const proceeding = formStatus === 'proceeding';

    const sectionData: SpecialIssueInformation = specialIssueInformation.data;
    const section = 'specialIssueInformation';
    const setValue = (key: string, value: any) => dispatch(setSIPFormValue(
        section,
        key,
        value
    ));

    const valueSetter = (key: string) => (value: any) => setValue(key, value);

    const setSubject = valueSetter('subject');
    const setJournals = valueSetter('journals');
    const setProposedTitle = valueSetter('proposedTitle');
    const setAimsAndScope = valueSetter('aimsAndScope');
    const setTopics = valueSetter('topics');

    const errorOfSubject = !validateNotEmpty(sectionData.subject);
    const errorOfJournals = sectionData.journals.length === 0 || sectionData.journals.length > 3;
    const errorOfProposedTitle_length = (sectionData.proposedTitle?.length ?? 0) > 400;
    const errorOfProposedTitle_required = !validateNotEmpty(sectionData.proposedTitle);
    const errorOfProposedTitle = errorOfProposedTitle_required || errorOfProposedTitle_length;
    const errorOfAimsAndScope_NotEmpty = !validateNotEmpty(sectionData.aimsAndScope);
    const errorOfAimsAndScope_Length = sectionData.aimsAndScope.length > 5000 || sectionData.aimsAndScope.length < 500;
    const errorOfAimsAndScope = errorOfAimsAndScope_NotEmpty || errorOfAimsAndScope_Length;
    const errorOfTopics = sectionData.topics.some(topic => !validateNotEmpty(topic));

    const hasError = errorOfSubject || errorOfJournals || errorOfProposedTitle || errorOfAimsAndScope || errorOfTopics;

    useEffect(() => {
        dispatch(setSIPFormSectionState(section, hasError ? (proceeding ? SectionState.error: SectionState.default) : SectionState.completed));
    }, [hasError, proceeding]);

    const handleSubjectInputChange = (text: string) => {
        dispatch(loadSubjectByName(id_token ?? '', text));
    };

    const handleJournalInputChange = (text: string) => {
        dispatch(loadJournalByName(id_token ?? '', text));
    };

    return (
        <Card section={specialIssueInformation}>
            <FormGroup
                title="Subject Area"
                isRequired={true}
                error={proceeding && errorOfSubject}
                errorMessage={intl.messages['sipForm.errorText.required'] as string}
            >
                <SelectableInputBox
                    options={allOptions.subjectArea ?? []}
                    value={sectionData.subject}
                    getOptionLabel={ option => option === null ? "" : option }
                    filterOptions={ (options, state) =>
                        options.filter(option =>
                            option?.toLowerCase()
                                .includes(state.inputValue.toLowerCase())) }
                    // filterOptions={ options => options}
                    // onInputChange={handleSubjectInputChange}
                    onChange={ setSubject }
                    onClear={ () => setSubject(null) }
                    error={proceeding && errorOfSubject}
                    placeholder={intl.messages['sipForm.placeholder.typeOrSelect'] as string}
                    noOptionsText={intl.messages['sipForm.noOptions'] as string}
                />
            </FormGroup>

            <FormGroup
                title={"Journal"}
                isRequired={true}
                tooltip={intl.messages['sipForm.tooltip.journal'] as string}
                error={proceeding && errorOfJournals}
                errorMessage={intl.messages[
                    sectionData.journals.length === 0 ? 'sipForm.errorText.journalMoreThanThree' : ''
                ] as string}
            >
                <MultiSelectableInputBox<Journal>
                    options={allOptions.journal ?? []}
                    value={sectionData.journals}
                    getOptionLabel={ option => option?.journalName ?? '' }
                    // filterOptions={ (options, state) =>
                    //     options.filter(option =>
                    //         option.journalName?.toLowerCase()
                    //             .includes(state.inputValue.toLowerCase())) }
                    filterOptions={ options => options}
                    onInputChange={handleJournalInputChange}
                    onChange={ (value) => setJournals(value) }
                    onClear={ () => setJournals([]) }
                    error={proceeding && errorOfJournals}
                    placeholder={intl.messages['sipForm.placeholder.typeOrSelect'] as string}
                    noOptionsText={intl.messages['sipForm.noOptions'] as string}
                    maxSize={3}
                />
            </FormGroup>

            <FormGroup
                title="Proposed Special Issue Title"
                isRequired={true}
                error={proceeding && errorOfProposedTitle}
                errorMessage={
                    intl.messages[ errorOfProposedTitle_required ? 'sipForm.errorText.required' : 'sipForm.errorText.titleLengthOutOfRange'] as string
                }
            >
                <InputBox
                    value={sectionData.proposedTitle}
                    onChange={ (value) => setProposedTitle(value) }
                    error={proceeding && errorOfProposedTitle}
                    placeholder={intl.messages['sipForm.placeholder.typeOnly'] as string}
                />
            </FormGroup>

            <FormGroup
                title="Aims and Scope"
                isRequired={true}
                error={proceeding && errorOfAimsAndScope}
                errorMessage={intl.messages[
                    errorOfAimsAndScope_NotEmpty ? 'sipForm.errorText.required' : 'sipForm.errorText.aimsAndScopeLengthLessThan100'
                    ] as string}
                textAreaCurrentLength={sectionData.aimsAndScope.length}
                textAreaMaxLength={5000}
                tooltip={intl.messages['sipForm.tooltip.aimsAndScope'] as string}
            >
                <MultiLineInputBox
                    value={sectionData.aimsAndScope}
                    onChange={ value => setAimsAndScope(value) }
                    error={proceeding && errorOfAimsAndScope}
                    placeholder={intl.messages['sipForm.placeholder.typeOnly'] as string}
                    maxChars={5000}
                />
            </FormGroup>

            <FormGroup
                title="Topics"
                isRequired={true}
                error={proceeding && errorOfTopics}
                errorMessage={intl.messages['sipForm.errorText.topicsHasEmpty'] as string}
            >
                <InputBoxList
                    value={sectionData.topics}
                    onChange={setTopics}
                    placeholder={intl.messages['sipForm.placeholder.typeOnly'] as string}
                    minRows={3}
                    maxRows={15}
                    info={intl.messages['sipForm.info.topics'] as string}
                    addButtonText={intl.messages['sipForm.label.topicAddButton'] as string}
                    error={proceeding && errorOfTopics}
                />
            </FormGroup>
        </Card>
    );
}
export const EXPORT_REPORT = 'EXPORT_REPORT';
export const JOURNAL_CE_CAT_GET_DATE = 'JOURNAL_CE_CAT_GET_DATE';
export const JOURNAL_CE_CAT_DATA = 'JOURNAL_CE_CAT_DATA';
export const JOURNAL_SUBJECT_GROUP_GET_DATE = 'JOURNAL_SUBJECT_GROUP_GET_DATE';
export const JOURNAL_SUBJECT_GROUP_DATA = 'JOURNAL_SUBJECT_GROUP_DATA';

export function selectJournalCeCatDate(selectedDate) {
  return async dispatch => {
    dispatch({ type: JOURNAL_CE_CAT_GET_DATE, data: selectedDate });
  };
}


export function selectJournalSGDate(selectedDate) {
  return async dispatch => {
    dispatch({ type: JOURNAL_SUBJECT_GROUP_GET_DATE, data: selectedDate });
  };
}


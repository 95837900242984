import React from 'react';
import { Box, Stack } from '@mui/material';
import { MiniBoldFont, SmallFont } from '../../../../components/FontStyle';

const hintStyle = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '-0.084px',
  color: '#EE5350',
};

export function ItemBlock(props) {
  const { title = '', error, mandatory = false, children, ...other } = props;
  return (
    <Box
      className='SIP-Detail-ItemBlock'
      {...other}
      data-selenium-id='SIP_AcceptPage_Submodule-ItemBlock-DetailBox'
    >
      <Stack
        spacing={0}
        direction='row'
        data-selenium-id='SIP_AcceptPage_Submodule-ItemBlock-Stack'
      >
        <Stack
          spacing={0}
          data-selenium-id='SIP_AcceptPage_Submodule-ItemBlock-InnerStack'
        >
          <Box
            className='SIP-Detail-ItemBlock-TitleTextBox'
            data-selenium-id='SIP_AcceptPage_Submodule-ItemBlock-TitleTextBox'
            sx={{
              ...SmallFont,
              color: '#596A7C',
              width: '232px',
              flexShrink: 0,
            }}
          >
            {title}
            {mandatory ? (
              <span
                style={{
                  marginLeft: '10px',
                  ...MiniBoldFont,
                  color: '#C40000',
                }}
              >
                *
              </span>
            ) : (
              <></>
            )}
          </Box>
          {error ? (
            <Box
              style={hintStyle}
              data-selenium-id='SIP_AcceptPage_Submodule-ItemBlock-TipBox'
            >
              This field is mandatory to input!
            </Box>
          ) : null}
        </Stack>
        <Box
          sx={{ flexGrow: 1 }}
          data-selenium-id='SIP_AcceptPage_Submodule-ItemBlock-Box'
        >
          {children}
        </Box>
      </Stack>
    </Box>
  );
}

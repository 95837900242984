import EmailContainer from '@/components/EmailModal/EmailContainer';
import { useState, useEffect } from 'react';
import { axios_instance } from '@/utils/axios_instance';
import { forEmail } from '@/modules/RichTextEditor/EditorUtils';
import {
  replaceVariablesSymbolsForFrontend,
  emailVariables,
  replaceVariablesForFrontShow
} from '@/modules/RichTextEditor/EditorUtils';
export default function InviteEBMEmailPreview(props) {
  const { sipCode, requestType, emailBody, setEmailBody } = props;
  const [loading, setLoading] = useState(true);
  // const [emailBody, setEmailBody] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [emailSend, setEmailSend] = useState({});

  useEffect(() => {
    axios_instance
      .post(
        `/api/v1/sip-service/special-issue-proposals/${sipCode}/emails?requestType=${requestType}`,
        {
          sipCode: sipCode,
        }
      )
      .then(res => {
        if (res.data.code !== 200) {
          if (res.data.message) {
            onCommand('alert', {
              type: 'warning',
              title: res.data.message,
              buttons: [{ text: 'OK', command: 'closeAlert' }],
            });
          }
          return;
        }
        const { title, content, from, copy, to, bcc } = res.data.data;
        setEmailBody(content);
        setEmailSend({
          title,
          content: forEmail(content),
          from,
          copy,
          to,
          bcc,
          mailAttachments: [],
          attachment: [],
          requireAttachment: false,
        });
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <EmailContainer
        data-selenium-id='SIP_DetailPage_EBM-EmailPreview'
        emailLoading={loading}
        emailTemplate={emailBody}
        // emailTemplate={replaceVariablesSymbolsForFrontend(
        //   emailBody,
        //   emailVariables.commonForSIP
        // )}
        setEmailTemplate={setEmailBody}
        attachments={attachments}
        setAttachments={setAttachments}
        subject={
          replaceVariablesForFrontShow(
            emailSend.title,
            emailVariables.commonForSIP
          ).innerText || 'N/A'
        }
        from={emailSend.from || 'N/A'}
        to={emailSend.to?.join(', ') || 'N/A'}
        cc={emailSend.cc?.join(', ') || 'N/A'}
        bcc={emailSend.bcc?.join(', ') || 'N/A'}
      ></EmailContainer>
    </div>
  );
}

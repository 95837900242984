import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
} from '@mui/material';
import XIcon from '../../../../assets/x - Feather icon.svg';
import { MyTableHeader, TableFooter2 } from '../../../../components/DataTable';
import {
  waitingApprovalMessageChange,
  waitingRejectMessageChange,
} from '@/actions/SIMT-Report/ReportJobsSubmission/ReportJobsAction';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unstable_batchedUpdates } from 'react-dom';
import {
  handleChangeHRPageSize,
  handleHRPageChangeAction,

} from '../../../../actions/SIMT-Report/ReportJobsSubmission/ReportJobsAction';
import { formatDatetimeString } from '../../../../utils/commonUtils';
import { BlueBackdrop } from '../../../../modules/Modal/SIModal';
import {loadHistoryRequsetData} from "@/actions/SIMT-Report/ReportJobsSubmission";

/**
 * 用于显示状态的Chip
 * @param {*} backgroundColor 设置背景颜色
 * @param {*} textColor 设置小圆点和文字颜色
 * @param {*} label 设置文字
 * @returns
 */

const StyledChip = styled(Chip)(({ _theme }) => ({
  [`&.MuiChip-labelSmall`]: {
    overflow: 'unset',
  },
}));

function StatusChip(props) {
  const { backgroundColor, textColor, label } = props;
  return (
    <StyledChip
      data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-StyledChip1'
      label={label}
      size='small'
      sx={{
        background: backgroundColor,
        color: textColor,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        width: '88px',
        height: '28px',
        overflow: 'unset',
      }}
    />
  );
}

/**
 * 显示reject Reason 信息的对话框
 * @param {*} open 设置对话框的打开
 * @param {*} handleClose 处理对话框的关闭
 * @param {*} rejectReason 需要显示的信息
 * @returns
 */
function RejectReasonDialog(props) {
  const { open, handleClose, content } = props;
  return (
    <Dialog
      data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Dialog'
      open={open}
      onClose={handleClose}
      components={{
        Backdrop: BlueBackdrop,
      }}
      sx={{
        fontFamily: 'Open Sans',
      }}
    >
      <Stack
        id='frame-2438'
        data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Dialog-Stack1'
        justifyContent='space-between'
        direction='row'
        sx={{
          width: '530px',
          height: '32px',
          padding: '32px 35px 17px 35px',
        }}
      >
        <DialogTitle
          data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Dialog-Stack1-DialogTitle'
          sx={{
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '22px',
            lineHeight: '125%',
            backgroundColor: '#ffffff',
            color: '#262E35',
            padding: 0,
          }}
          id='draggable-dialog-title'
        >
          Reject Reason
        </DialogTitle>
        <IconButton
          data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Dialog-Stack1-IconButton'
          onClick={handleClose}
        >
          <XIcon />
        </IconButton>
      </Stack>
      <Stack
        id='frame-2448'
        data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Dialog-Stack2'
        sx={{
          width: '530px',
          height: '292px',
          padding: '0px 35px 32px 35px',
        }}
      >
        <DialogTitle
          data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Dialog-Stack2-DialogTitle'
          sx={{
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '18px',
            letterSpacing: '0.08em',
            textTransform: 'capitalize',
            color: '#596A7C',
            padding: 0,
            paddingBottom: '12px',
          }}
        >
          {/* Reason:{' '} */}
        </DialogTitle>

        <DialogContent
          id='systemAdminReportJobsHistoryRequestRejectReasonDialogContent'
          data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Dialog-Stack2-DialogContent'
          sx={{
            minWidth: '523px',
            maxHeight: '280px',
            mb: 2.5,
            lineHeight: '20px',
            color: '#596A7C',
            wordWrap: 'break-word',
            padding: '0',
            margin: 0,
            fontWeight: 400,
            fontSize: '14px',
          }}
        >
          {content}
        </DialogContent>
      </Stack>
    </Dialog>
  );
}

const createRow = (
  id,
  label,
  minWidth,
  maxWidth,
  isOrder = false,
  align = 'left',
  show = true
) => {
  return { id, label, minWidth, maxWidth, isOrder, align, show };
};
const tableHeadObj = [
  createRow('reportName', 'Report Name', '213px', '213px', false, 'left', true),
  createRow(
    'selectedMonth',
    'Selected Month',
    '188px',
    '188px',
    false,
    'left',
    true
  ),
  createRow(
    'submittedBy',
    'Submitted By',
    '178px',
    '178px',
    false,
    'left',
    true
  ),
  createRow(
    'submitTime',
    'Submitted AT',
    '214px',
    '214px',
    false,
    'left',
    true
  ),
  createRow('status', 'Status', '190px', '190px', false, 'left', true),
  createRow('reviewedBy', 'Reviewed By', '214px', '214px', false, 'left', true),
  createRow(
    'reviewedTime',
    'Reviewed at',
    '179px',
    '179px',
    true,
    'left',
    true
  ),
];

export default function HistoryRequestTable(props) {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState('');

  const dispatch = useDispatch();

  const selector = state => {
    return {
      page: state.ReportJobs.historyRequestPage,
      pageSize: state.ReportJobs.historyRequestPageSize,
      HistoryRequestData: state.ReportJobs.historyRequestData,
    };
  };

  const { page, pageSize, HistoryRequestData } = useSelector(selector);
  const [orderType, setOrderType] = useState('desc');
  const [sortKey, setSortKey] = useState('reviewedTime');
  // 获取数据, 在点击排序图标之后重新加载对应顺序的数据
  useEffect(() => {
    if (page === 0) {
      dispatch(handleHRPageChangeAction(1));
    } else {
      setLoading(true);
      dispatch(loadHistoryRequsetData(page, pageSize, orderType));
      setLoading(false);
    }
  }, [dispatch, page, pageSize, orderType, sortKey]);

  useEffect(() => {
    dispatch(waitingRejectMessageChange(false));
    dispatch(waitingApprovalMessageChange(false));
  }, []);

  // 处理对话框的打开
  const handleClickOpen = text => {
    setRejectReason(text);
    setOpen(true);
  };

  // 处理对话框的关闭
  const handleClose = value => {
    setOpen(false);
  };

  //改变每页的数量
  const handlePerPageChange = e => {
    unstable_batchedUpdates(() => {
      // 修改页面大小后，页码置为1
      dispatch(handleHRPageChangeAction(1));
      dispatch(handleChangeHRPageSize(Number(e.target.value)));
    });
  };

  //改变页码
  const handlePageChange = (_event, newPage) => {
    dispatch(handleHRPageChangeAction(newPage));
  };

  // 将SelectedMonth数值进行格式化
  const handleSelectedMonth = prevMonth => {
    return Math.floor(prevMonth / 100) + '-' + (prevMonth % 100);
  };

  // 判断返回哪种status的信息
  const StatusBlock = row => {
    if (row.status === 'Approve') {
      return (
        <StatusChip
          data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-StatusChip'
          backgroundColor={'#D7F1EE'}
          textColor={'#0D6A61'}
          label='Approved'
        />
      );
    } else {
      return (
        <Box data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Box2'>
          <StatusChip
            data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Box2-StatusChip'
            backgroundColor={'#FFF0DD'}
            textColor={'#854300'}
            label='Rejected'
          />
          <Link
            data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Box2-Link'
            component='button'
            sx={{
              fontSize: '11px',
              color: '#3E92EA',
              lineHeight: '18px',
              letterSpacing: '0.2px',
              textDecorationLine: 'underline',
              marginLeft: '8px',
              fontFamily: 'Open Sans',
            }}
            onClick={() => {
              handleClickOpen(row.rejectReason);
            }}
          >
            Reason
          </Link>
        </Box>
      );
    }
  };
  const handleRequestSort = (_event, property) => {
    const isAsc = sortKey === property && orderType === 'asc';
    setOrderType(isAsc ? 'desc' : 'asc');
    setSortKey(property);
  };

  const StyledTableCell = styled(TableCell)(({ _theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: '14px',
      fontWeight: '400px',
      letterSpacing: '0.01em',
      lineHeight: '20px',
      color: '#262E35',
      background: '#FFFFFF',
      padding: '8px 16px 8px 24px',
    },
  }));

  const [mypage, setmyPage] = useState(page);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  return (
    <Box
      id='tableBox'
      data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Box3'
    >
      <TableContainer
        data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Box3-TableContainer'
        sx={{
          '&.MuiTableContainer-root': {
            border: '1px solid #DFE4E8',
            borderRadius: '8px',
            BorderColor: '#DFE4E8',
          },
        }}
      >
        <Table
          stickyHeader
          aria-label='simple table'
          size='small'
          id='history-request-table'
          data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Box3-TableContainer-Table'
        >
          <MyTableHeader
            id='history-request-table-header'
            data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Box3-TableContainer-Table-MyTableHeader'
            items={tableHeadObj}
            order={orderType}
            orderBy={sortKey}
            onRequestSort={handleRequestSort}
          />
          <TableBody
            id='history-request-table-body'
            data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Box3-TableContainer-Table-TableBody'
          >
            {!loading &&
              HistoryRequestData.result !== undefined &&
              (HistoryRequestData.result.map((row, index) => (
                <TableRow key={row.id}>
                  <StyledTableCell
                    id={`systemAdminReportJobsHistoryRequestRejectTableReportName${index}`}
                    data-selenium-id={`SystemAdmin-ReportJobsHistoryRequestRejectTableReportName-${index}`}
                  >
                    {row.reportName}
                  </StyledTableCell>
                  <StyledTableCell
                    id={`systemAdminReportJobsHistoryRequestRejectTableSelectMonth${index}`}
                    data-selenium-id={`SystemAdmin-ReportJobsHistoryRequestRejectTableSelectMonth-${index}`}
                  >
                    {handleSelectedMonth(row.selectedMonth)}
                  </StyledTableCell>
                  <StyledTableCell
                    id={`systemAdminReportJobsHistoryRequestRejectTableSubmittedBy${index}`}
                    data-selenium-id={`SystemAdmin-ReportJobsHistoryRequestRejectTableSubmittedBy-${index}`}
                  >
                    {row.submittedBy}
                  </StyledTableCell>
                  <StyledTableCell
                    id={`systemAdminReportJobsHistoryRequestRejectTableSubmittedTime${index}`}
                    data-selenium-id={`SystemAdmin-ReportJobsHistoryRequestRejectTableSubmittedTime-${index}`}
                  >
                    {formatDatetimeString(row.submittedTime)}
                  </StyledTableCell>
                  <StyledTableCell
                    id={`systemAdminReportJobsHistoryRequestRejectTableStatus${index}`}
                    data-selenium-id={`SystemAdmin-ReportJobsHistoryRequestRejectTableStatus-${index}`}
                  >
                    {StatusBlock(row)}
                  </StyledTableCell>
                  <StyledTableCell
                    id={`systemAdminReportJobsHistoryRequestRejectTableReviewedBy${index}`}
                    data-selenium-id={`SystemAdmin-ReportJobsHistoryRequestRejectTableReviewedBy-${index}`}
                  >
                    {row.reviewedBy}
                  </StyledTableCell>
                  <StyledTableCell
                    id={`systemAdminReportJobsHistoryRequestRejectTableReviewTime${index}`}
                    data-selenium-id={`SystemAdmin-ReportJobsHistoryRequestRejectTableReviewTime-${index}`}
                  >
                    {formatDatetimeString(row.reviewTime)}
                  </StyledTableCell>
                </TableRow>
              )) ??
                'No data to show')}
          </TableBody>
        </Table>
        {loading && (
          <Box
            id='cr-contact-table-loading-box'
            data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Box3-TableContainer-Box'
            sx={{
              width: 'auto',
              marginTop: '150px',
              marginLeft: '50%',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </TableContainer>
      <Box
        id='history-request-table-footer-box'
        data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Box3-TableContainer-Box'
      >
        <Divider data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Box3-TableContainer-Box-Divider' />
        <TableFooter2
          id='history-request-table-footer'
          data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Box3-TableContainer-Box-TableFooter2'
          Page={page}
          perPageNumber={pageSize}
          perPageList={[10, 20, 50]}
          count={HistoryRequestData.count}
          handlePerPageChange={handlePerPageChange}
          handlePageChange={handlePageChange}
          backgroundColor='#F6F7F8'
        />
      </Box>
      <RejectReasonDialog
        data-selenium-id='SystemAdmin_ReportJobs-HistoryRequest-HistoryRequestTable-Box3-TableContainer-RejectReasonDialog'
        open={open}
        handleClose={handleClose}
        content={rejectReason}
      />
    </Box>
  );
}


import { Box } from "@mui/material";
import {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SectionFolder from "./compontents/SectionFolder";
import JournalDetailSection from "./sections/JournalDetailSection";
import StakeholdersSection from "./sections/StakeholdersSection";
import AssociatedSISection from "./sections/AssociatedSISection.js";
import { initJournalDetail } from "@/actions/SIMT-SI/CatJournal/CATJournalDetailAction";
import {getJournalDetail} from "@/actions/SIMT-SI/CatJournal";

export default function CATJournalDetail(props) {
  let { journalcode } = useParams();
  const dispatch=useDispatch();
  useEffect(()=>{
    dispatch(getJournalDetail(journalcode));
    return ()=>{
      dispatch(initJournalDetail());
    };
  },[]);
  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      CATJournalDetail:state.CATJournalDetail
    };
  };
  const { open,CATJournalDetail } = useSelector(selector);

  return (
    <Box
      sx={{
        width: `calc(100vw - 32px - ${open ? '248px' : '88px'})`,
        marginLeft: open ? '248px' : '88px'
      }}
      id='CATJournalDetailPage'
    >
      <Box
        id='CATJournalDetail-TitleBox'
        sx={{
          height: '74px',
          width: '100%',
          padding: '10px 32px 10px 32px',
          background: '#ffffff'
        }}
      >
        <Box
          id='Title-1'
          sx={{
            'font-family': 'Open Sans',
            'font-size': '24px',
            'font-weight': '400',
            'line-height': '24px',
            'letter-spacing': '0em',
            'text-align': 'left',
            'color': '#262E35'
          }}
        >{CATJournalDetail.journalName}</Box>
        <Box
          id='title-2'
          sx={{
            'font-family': 'Open Sans',
            'font-size': '16px',
            'font-weight': '600',
            'line-height': '24px',
            'letter-spacing': '0em',
            'text-align': 'left',
            'color': '#596A7C',
            height: '22px',
            marginTop: '8px'
          }}
        >{CATJournalDetail.journalCode}</Box>
      </Box>
      <Box
        sx={{
          marginTop: '4px',
          width: '100%',
          padding: '30px 0px 32px 32px',
          display: 'flex',
          gap: '32px',
          flexDirection:'column',
          background: '#ffffff'
        }}
      >
        {/* <SectionFolder
          id='JournalDetailSection'
          title='section'
        ></SectionFolder> */}
        <JournalDetailSection/>
        <StakeholdersSection/>
        <AssociatedSISection/>
        
      </Box>
    </Box>);
}
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetJournalOverviewChartData,
  GetJournalOverviewChartDataWithStatus,
} from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
// import { SampleData } from './d.js';

// Remember to start the name of your custom hook with "use"
export default function useGetChartDataHook(
  selectedCatagories,
  url,
  title,
  selectedDate
) {
  // console.log('=============url==========', url);
  // console.log('=============title==========', title);
  // console.log('=============selectedDate==========', selectedDate);

  const dispatch = useDispatch();

  const { reportDataAPI } = useSelector(state => {
    return {
      reportDataAPI: state.ReportDiagram[title],
    };
  });

  // console.log('=============reportDataAPI==========', reportDataAPI);

  let loading = null,
    error = null,
    data = null;

  if (typeof reportDataAPI !== 'undefined') {
    ({ loading, error, data } = reportDataAPI);
  }

  // get data from API
  const dataForChart = useMemo(() => {
    return data;
    // use sample data
    // return SampleData;
  }, [data]);

  useEffect(() => {
    // if (!reportDataAPI && selectedDate !== null) {
    if (typeof reportDataAPI === 'undefined' && selectedDate !== null) {
      dispatch(
        // GetJournalOverviewChartData(url, title, selectedDate)
        GetJournalOverviewChartDataWithStatus(url, title, selectedDate)
      );
    }
  }, [reportDataAPI, selectedDate, url, title]);

  // console.log('=============dataForChart==========', dataForChart);

  // get a full list of categories (e.g. subject groups)
  const catagoriesFullList = useMemo(() => {
    // valid if has at least 1 category,
    if (Array.isArray(dataForChart) && dataForChart.length > 1) {
      // index 0 is 'subject group'
      const categories = dataForChart
        .map(categoryEntry => categoryEntry[0])
        .slice(1);
      return categories;
    }
    return [];
  }, [dataForChart]);

  // console.log('=============catagoriesFullList==========', catagoriesFullList);

  // filter only the selected records
  const selectedDataForBar = useMemo(() => {
    if (dataForChart) {
      return filterSelectedData(dataForChart, selectedCatagories);
    }
    return [];
  }, [dataForChart, selectedCatagories]);

  // console.log('=============selectedDataForBar==========', selectedDataForBar);

  // data for piecharts
  const selectedDataForPie1 = useMemo(() => {
    const selectedDataAll = SplitDataForPie(selectedDataForBar);
    return getDataForPieChart(selectedDataAll);
  }, [selectedDataForBar]);

  const selectedDataForPie2 = useMemo(() => {
    const selectedDataAll = SplitDataForPieOA(selectedDataForBar);
    return getDataForPieChart(selectedDataAll);
  }, [selectedDataForBar]);

  // console.log('selectedDataForPie1', selectedDataForPie1);
  // console.log('selectedDataForPie2', selectedDataForPie2);

  return [
    catagoriesFullList,
    selectedDataForBar,
    selectedDataForPie1,
    selectedDataForPie2,
    dataForChart,
    loading,
    error,
  ];
}

function filterSelectedData(catagoryEntries, selectedCatagories) {
  const selectedCatagoryEntries = catagoryEntries.filter(
    (catagoryEntry, index) => {
      if (index === 0) return true; // labels
      return selectedCatagories.indexOf(catagoryEntry[0]) >= 0;
    }
  );
  return selectedCatagoryEntries;
}

// for All
const SplitDataForPie = records => {
  try {
    if (Array.isArray(records)) {
      const slicedRecord = records.map(record => record.slice(0, 4)); // for all
      return slicedRecord;
    }
    return records;
  } catch (e) {
    return [];
  }
};

// for OA
const SplitDataForPieOA = records => {
  try {
    if (Array.isArray(records)) {
      const slicedRecord = records.map(record =>
        record.slice(0, 1).concat(record.slice(4))
      );
      return slicedRecord;
    }
    return records;
  } catch (e) {
    return [];
  }
};

const getDataForPieChart = selectedData => {
  // console.log('==selectedData==', selectedData);
  if (Array.isArray(selectedData) && selectedData.length > 1) {
    const modelNames = selectedData[0].slice(1);
    const valueRecords = selectedData.slice(1);
    // console.log('==modelNames==', modelNames);
    // console.log('==valueRecords==', valueRecords);

    let modelSum = {}; // {stage: sumOfModel}
    let total = 0;

    modelNames.forEach(model => {
      // calculate the sum for every model
      const modelIndex = modelNames.indexOf(model) + 1;
      const sumOfModel = valueRecords.reduce((sum, record) => {
        return sum + record[modelIndex];
      }, 0);

      modelSum[model] = sumOfModel;

      // calculate the sum of all stages
      total += sumOfModel;
    });

    // console.log('==total==', total);

    // corner case: total is 0, return []
    if (Number.isNaN(total) || total === 0) {
      return [];
    }

    const pieChartData = modelNames.map(model => {
      const value = modelSum[model];
      // calculate the percentage of every model
      const percentage = getPercentage(value, total);
      return [model, percentage, value];
    });

    // console.log('pieChartData========================', pieChartData);

    return pieChartData;
  }
  return [];
};

const getPercentage = (value, sum) => {
  // if (Number.isNaN(sum) || sum === 0) {
  //   return 'N/A';
  // } else {
  return ((value * 100) / sum).toFixed(0) + '%';
  // }
};

import ShowAndEditForAuto from './CommonModule/ShowAndEditForAuto';
import { Box, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import MarketChevronDown from '@/assets/MarketChevronDown.svg';
import { useIntl } from 'react-intl';

function AutoSentEmailByDeadline(props) {
  const { AutoSentDetail } = props;

  const intl = useIntl(); // 为国际化提供的API支持
  const [isFold, setIsFold] = useState(true);
  //蓝色标题
  const BlueTitle = props => {
    return (
      <div
        style={{
          width: '100%',
          height: '24px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#113D95',
        }}
      >
        {props.children}
      </div>
    );
  };

  const deadlineSent = {
    XDayBeforeDeadline: {},
    XDayAfterDeadline: {},
    publishedIssue: {},
  };
  // 根据block判断将要赋值的是哪一个ShowAndEditForAuto
  for (const element of AutoSentDetail) {
    switch (element.block) {
      case 7:
        deadlineSent.XDayBeforeDeadline = element;
        break;
      case 8:
        deadlineSent.XDayAfterDeadline = element;
        break;
      case 9:
        deadlineSent.publishedIssue = element;
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Grid
        container
        style={{
          gridGap: '16px',
          marginTop: '32px',
          paddingBottom: '14px',
        }}
        data-selenium-id='EmailTemplates-AutoSentEmailByDeadline-Grid'
      >
        <Grid
          item
          data-selenium-id='EmailTemplates-AutoSentEmailByDeadline-Grid-Grid1'
        >
          {isFold ? (
            <Box
              onClick={() => setIsFold(false)}
              sx={{ cursor: 'pointer' }}
              data-selenium-id='EmailTemplates-AutoSentEmailByDeadline-Grid-Grid1-MarketChevronUp'
            >
              <MarketChevronUp />
            </Box>
          ) : (
            <Box
              onClick={() => setIsFold(true)}
              id='MarketFoldDown'
              sx={{ cursor: 'pointer' }}
              data-selenium-id='EmailTemplates-AutoSentEmailByDeadline-Grid-Grid1-MarketFoldDown'
            >
              <MarketChevronDown />
            </Box>
          )}
        </Grid>
        <Grid
          item
          md={11}
          sm={11}
          xs={11}
          data-selenium-id='EmailTemplates-AutoSentEmailByDeadline-Grid-Grid2'
        >
          <Stack
            direction='column'
            spacing={1}
            data-selenium-id='EmailTemplates-AutoSentEmailByDeadline-Grid-Grid2-Stack'
          >
            <Box data-selenium-id='EmailTemplates-AutoSentEmailByDeadline-Grid-Grid2-Stack-BlueTitle'>
              <BlueTitle>
                {intl.messages['email.autoSentEmailByDeadline']}
              </BlueTitle>
            </Box>
            {isFold ? (
              <>
                <ShowAndEditForAuto
                  partTitle={intl.messages['email.XDayBeforeDeadline']}
                  block={deadlineSent.XDayBeforeDeadline.block}
                  contentDetail={deadlineSent.XDayBeforeDeadline}
                />
                <ShowAndEditForAuto
                  partTitle={intl.messages['email.XDayAfterDeadline']}
                  block={deadlineSent.XDayAfterDeadline.block}
                  contentDetail={deadlineSent.XDayAfterDeadline}
                />
                <ShowAndEditForAuto
                  partTitle={intl.messages['email.publishedIssue']}
                  block={deadlineSent.publishedIssue.block}
                  contentDetail={deadlineSent.publishedIssue}
                />
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
export default AutoSentEmailByDeadline;

import {axios_instance, axios_instance_l} from "@/utils/axios_instance";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {
    GET_SNOWFLAKE_INFO,
    SET_DETAILINFO,
    SET_MARKETING_AI_OPTIONS,
    SET_MARKETING_AI_OPTIONS_ERROR,
    SET_MARKETING_AI_OPTIONS_LOADING
} from "@/actions/SIMT-SI/SpecialIssue/SIAction";
import {fakeAxiosSuccessResponse} from "@/utils/commonUtils";
import {GET_CONTENT_COMMISSIONING_APPROACHES, GET_SIP_STATUS} from "@/actions/SIMT-SIP/SIP/SIPAction";
import {commissionApproachesTestValue} from "@/pages/SIPPage/RevisePage/CommonInitialValue";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";

/**
 * @param {string} siCode
 * @param {(e:boolean)=>{}} [successCallback]
 * @returns {(dispatch: import('redux').Dispatch) => Promise<{readonly siMarketing:ReadonlyArray<{readonly readyForPromotion:"Yes"|"No"}>}>}
 */
export function getSIDetail(siCode, successCallback) {
    return async dispatch => {
        const res = await axios_instance.get( `/api/v1/si-service/special-issues/${siCode}`, {
            params: {
                getContent: 'SiDetail',
                hasMandatoryFields: true
            },
            headers: {
                authorization: localStorage.getItem('authorization'),
            },
        });
        if (res.data.code === 200) {
            dispatch({type: SET_DETAILINFO, data: res.data.data});
            if (successCallback) successCallback(true);
            return res?.data?.data;
        } else {
            dispatch(setSnackbarMessageAndOpen('siPage.nullSIDetailError'));
        }
    };
}

/**
 * 根据Si-Code获取SnowFlake相关信息
 */
export function getSnowFlakeInfo(siCode) {
    return async dispatch => {
        try {
            const url =  `/api/v1/si-service/special-issues/${siCode}`;
            const res = await axios_instance.get(url, {
                params: {
                    getContent: 'SnowflakeSubmission',
                    hasMandatoryFields: false
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            if (res.data.code === 200) {
                dispatch({type: GET_SNOWFLAKE_INFO, data: res.data.data});
            } else {
                dispatch({type: GET_SNOWFLAKE_INFO, data: null});
            }
        } catch (e) {
            dispatch({type: GET_SNOWFLAKE_INFO, data: null});
        }
    };
}

/**
 *
 * @param {string} siCode
 * @param {"release" | "debug"} mode
 * @returns {(dispatch: import("redux").Dispatch) => Promise<void>}
 */
export const getSipReferenceData = (siCode, mode = 'release') => {
    /**
     * @returns {Promise<string | null>}
     */
    const getSipCode = async () => {
        /** @type {import("@/utils/axios-response").AxiosResponse<string | null>} */
        const res =
            mode === 'release'
                ? await axios_instance.get('/api/v1/sip-service/special-issue-proposals', {
                    params: {
                        siCode: siCode,
                    },
                    headers: {
                        authorization: localStorage.getItem('authorization') || '',
                    },
                })
                : await fakeAxiosSuccessResponse('SIP-2023-000036');

        const data = res.data;

        return data.code === 200 ? data.data : null;
    };

    return async dispatch => {
        try {
            const sipCode = await getSipCode();

            if (sipCode) {
                dispatch({
                    type: GET_SIP_STATUS,
                    data: {
                        sipCode: sipCode,
                    },
                });
                /** @type {import("@/utils/axios-response").AxiosResponse<import("@/pages/SIPPage/RevisePage").CommissionApproaches>} */
                const res =
                    mode === 'release'
                        ? await axios_instance.get(`/api/v1/sip-service/special-issue-proposals/${sipCode}`, {
                            params: {
                                type: "commissionApproaches",
                            },
                            headers: {
                                authorization: localStorage.getItem('authorization') || '',
                            },
                        })
                        : await fakeAxiosSuccessResponse(commissionApproachesTestValue);

                const data = res.data;
                if (data.code === 200) {
                    dispatch({
                        type: GET_CONTENT_COMMISSIONING_APPROACHES,
                        data: data.data,
                    });
                } else {
                    dispatch({
                        type: GET_CONTENT_COMMISSIONING_APPROACHES,
                        data: 'error',
                    });
                    dispatch(
                        setSnackbarMessageAndOpen(data.message, {}, SEVERITIES.error)
                    );
                    // console.log("[get sipreference data] data = ", data);
                }
            } else {
                dispatch({
                    type: GET_CONTENT_COMMISSIONING_APPROACHES,
                    data: 'error',
                });
            }
        } catch (e) {
            dispatch({
                type: GET_CONTENT_COMMISSIONING_APPROACHES,
                data: 'error',
            });
        }
    };
}; // the new function 'getNewSiMarketinfo'，only be called when click the 'add marketing section' button
export const getNewSiMarketinfo = (siCode, detailInfo, setAddMarketSection) => {
    const getSipCode = async siCode => {
        const res = await axios_instance.get('/api/v1/sip-service/special-issue-proposals', {
            params: {
                siCode: siCode,
            },
            headers: {
                authorization: localStorage.getItem('authorization') || '',
            },
        });
        const data = res.data;

        return data.code === 200 ? data.data : null;
    };

    return async dispatch => {
        try {
            let sipCode;
            try {
                sipCode = await getSipCode(siCode);
            } catch (e) {
            }
            if (!sipCode) {
                setAddMarketSection(true);
                return;
            }
            const res = await axios_instance.get(`/api/v1/sip-service/special-issue-proposals/${sipCode}`, {
                params: {
                    type: 'siMarketInfo',
                },
                headers: {
                    authorization: localStorage.getItem('authorization') || '',
                },
            });
            if (res.data.code === 200) {
                const data = res.data.data;
                dispatch({
                    type: SET_DETAILINFO,
                    data: {
                        ...detailInfo,
                        siMarketing: [
                            {
                                journalSiPageLink: '',
                                specialIssueHtmlLink: '',
                                siAimsAndScope: data.aimsAndScope,
                                siTopics:
                                    data.topics?.length >= 3
                                        ? data.topics
                                        : ['', '', ''].map((val, i) => data.topics[i] ?? ''),
                                siKeywords:
                                    data.keywords?.length >= 3
                                        ? data.keywords
                                        : ['', '', ''].map((val, i) => data.keywords[i] ?? ''),
                                competitiveTitleIssns: [''],
                                references: data.reference,
                                others: '',
                                readyForPromotion: '',
                                submissionInstructions: '',
                                fromNewSi: true,
                            },
                        ],
                    },
                });
            } else {
                dispatch(setSnackbarMessageAndOpen('Failed to load new market'));
            }
        } catch (e) {
            console.error(e);
        }
    };
}; //CT5007 [M2][Marketing improvement] - AI recommended competitors
export function getMarkingAIOption(data) {
    return async dispatch => {
        try {
            dispatch({type: SET_MARKETING_AI_OPTIONS_ERROR, data: false});
            dispatch({type: SET_MARKETING_AI_OPTIONS_LOADING, data: true});
            dispatch({type: SET_MARKETING_AI_OPTIONS, data: []});
            const url = '/api/v1/si-service/recommended-journals';
            const res = await axios_instance_l.post(url,
                {
                    siTitle: data.siTitle,
                    aimsAndScope: data.aimsAndScope,
                    topicList: data.topicList
                }, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            dispatch({type: SET_MARKETING_AI_OPTIONS_LOADING, data: false});
            if (res.data.code === 200) {
                dispatch({
                    type: SET_MARKETING_AI_OPTIONS, data: res.data.data.map(
                        item => {
                            return {
                                ...item,
                                value: `${item.journalName}(${item.issn})`
                            };
                        }
                    )
                });
            } else {
                dispatch({type: SET_MARKETING_AI_OPTIONS_ERROR, data: true});
            }
        } catch (e) {
            dispatch({type: SET_MARKETING_AI_OPTIONS_ERROR, data: true});
            dispatch({type: SET_MARKETING_AI_OPTIONS_LOADING, data: false});
        }
    };
}
import HeadAnnual, { PageHeadAnnual } from './headAnnual';
import DiagramContent from './AnnualTrendsDiagrams';

// export default function AnnualTrends({ showTable, setShowTableNav }) {
export default function AnnualTrends({ showTable, setShowTable }) {
  return (
    <>
      <PageHeadAnnual
        data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-PageHeadAnnual'
        showTable={showTable}
        setShowTable={setShowTable}
      />
      {showTable ? (
        <>
          <HeadAnnual
            data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-HeadAnnualSubjectGroup'
            title='Subject Group'
          />
          <HeadAnnual
            data-selenium-id='Report_AcquiredAndActiveSi-AnnualTrends-HeadAnnualBusinessDivision'
            title='Business Division'
          />
        </>
      ) : (
        <DiagramContent />
      )}
    </>
  );
}

/**Snackbar
 * 确认信息弹窗
 */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.css';
import { closeConfirm } from '../../actions/SIMT-User/Alert/AlertAction';
import {AlertBox, UntitledAlertBox} from '@/components/Alert/AlertBox';

export default function ConfirmSnackBar() {
  const dispatch = useDispatch();
  const [Open, setOpen] = useState(false);
  const selector = state => {
    return {
      confirmOpen: state.Alert.open,
      editType: state.Alert.editType,
      textParam: state.Alert.textParam,
      vertical: state.Alert.vertical,
      horizontal: state.Alert.horizontal,
      color: state.Alert.color,
    };
  };
  const { confirmOpen, editType, textParam, vertical, horizontal, color } =
    useSelector(selector);

  useEffect(() => {
    setOpen(confirmOpen);
  }, [confirmOpen]);

  const handleClose = () => {
    setOpen(false);
    dispatch(closeConfirm());
  };

  const message = () => {
    switch (editType) {
      case 'deleteAdmin':
        return textParam.split(',')[0] === 'error'
          ? `Some SI is associated with the System admin ${textParam.split(',')[1]
          }, cann't be removed.`
          : `System admin ${textParam} has been removed.`;
      case 'resendInvitationToAdmin':
        return `An invitation link has been sent to ${textParam} successfully.`;
      case 'resetAdminPass':
        return `${textParam}'s password has been reset.`;
      case 'unlockAdmin':
        return `System admin ${textParam} has been unlocked.`;
      case 'InactiveAdmin':
        return `System admin ${textParam} has been inactived.`;
      case 'deleteUser':
        return textParam.split(',')[0] === 'error'
          ? `Some SI is associated with the Normal user ${textParam.split(',')[1]
          }, cann't be removed.`
          : `Normal user ${textParam} has been removed.`;
      case 'resendInvitationToUser':
        return `Invitation link has been resent to ${textParam}.`;
      case 'resetUserPass':
        return `${textParam}'s password has been reset.`;
      case 'updateConfiguration':
        return 'System configurations have been updated.';
      case 'updateUser':
        return `Normal user ${textParam} has been updated.`;
      case 'createUser':
        return `Normal user ${textParam} has been created.`;
      case 'createAdmin':
        return `System admin ${textParam} has been created.`;
      case 'upgradeToAdmin':
        return `User ${textParam} has been updated to system admin.`;
      case 'sendEmailSuccess':
        return 'Check your email for password reset instructions.';
      case 'alreadySentEmail':
        return 'The email has already been sent. Please check your mailbox.';
      case 'emailInvalid':
        return 'The email address you entered is incorrect. Please contact the administrator.';
      ///////////////
      case 'firstPass':
        return 'Please login with the default password in your mailbox.';
      //////////////
      case 'emptyPermissionList':
        return 'Permission list should not be empty.';
      case 'duplicatedRoleName':
        return `Role ${textParam} has already existed.`;
      case 'error':
        return textParam||'Some errors have occurred. Please contact the administrator.';
      case 'deleteRole':
        return `Role ${textParam} has been removed.`;
      case 'createRole':
        return `Role ${textParam} has been created.`;
      case 'updateRole':
        return `Role ${textParam} has been updated.`;
      case 'resetPassConfirm':
        return 'Your password has been reset';
      case 'updateAdmin':
        return `System admin ${textParam} has been updated.`;
      case 'createSI':
        return 'This Special Issue has been created.';
      case 'updateSI':
        // return `Special Issue ${textParam} has been updated`;
        return 'This Special Issue has been updated';
      case 'unlockUser':
        return `Normal user ${textParam} has been unlocked.`;
      case 'newKeyword':
      case 'Research Keywords':
        return 'Successfully add this Research Keyword in database.';
      case 'Institution':
        return 'Successfully add this Institution in database.';
      case 'Department':
        return 'Successfully add this Department in database.';
      case 'SITransfer':
        return `${textParam}`;
      case 'editCRContactDetail':
        return `${textParam} has been updated.`;
      case 'batchUpdateSucceed':
        return `[${textParam}] has been updated.`;
      case 'reactiveSIWarning':
        return `[${textParam}] shelved from the published stage, can't be reactive.`;
      case 'createGE':
        return 'The new GE has been added to the database.';
      case 'noAccess':
        return 'access not allowed';
      case 'GEDetailError':
        return `${textParam}`;
      case 'HEUserInactive':
        return 'Cannot inactivate this user, as there are associated SIs, please transfer the SIs firstly!';
      case 'EmailExits':
        return 'The email address has been registered';
      case 'GERepeatErr':
        return 'There are duplicated GEs between Lead GE and Co-GE, please have a check and remove the duplicated ones.';
      default:
        return '';
    }
  };

  return (
    <>
      {editType === 'updateSI' || editType === 'createSI'?
        (<AlertBox
          open={Open}
          autoHideDuration={3000}
          handleClose={handleClose}
          severity={editType === 'error'? 'error':'success'}
          title= {editType === 'error'? 'Error!':'Success!'}
          message={message()}
        />):(
          <UntitledAlertBox
            open={Open}
            autoHideDuration={3000}
            handleClose={handleClose}
            severity='success'
            message={message()}
          />)
      }
    </>
  );
}

import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Tooltip,
  TableCell,
  CircularProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '@/components/DataTable';
import {
  StyledTableCellPurple,
  StyledTableCellDisPurple,
  StyledTableCellGreen,
  StyledTableCellDisGreen,
  StyledTableRow,
  StyledTableCellNormal,
  ReportNoData,
  TableBox2,
} from '@/pages/Report/ReportSpecificComponents/SpecialColorTableHeader';
import '@/App.css';
import {
  findLongestLineSubstring,
  measureWidth,
} from '@/utils/StringWidthMeasure';
import { useIntl } from 'react-intl';
import { SimpleTooltip } from '@/componentsphase2/CustomTooltip';
import {GetJournalBDData, GetJournalLastActionDate} from "@/actions/SIMT-Report/Report/ReportJournal";

export default function BusinessDivisionTable(props) {
  const intl = useIntl();
  const { isOA } = props;
  const [loading, setLoading] = useState(false);
  const { selectMonthDate, ReportJournalBDData, ReportJournalBDMonth } =
    useSelector(state => {
      return {
        // selectMonthDate: state.Report.ReportJournalOverviewMonth,
        selectMonthDate: state.Report.selectDate,
        ReportJournalBDData: state.Report.ReportJournalBDData,
        ReportJournalBDMonth: state.Report.ReportJournalBDMonth,
      };
    });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const items = [
      {
        id: 'businessDivision',
        label: 'Business Division',
      },
      {
        id: 'activeJournal',
        label: intl.messages['report.Journal.activeJournals'],
      },
      {
        id: 'activePercentage',
        // label: intl.messages['report.Journal.active']
        label: 'ACTIVE_%',
      },
      {
        id: 'targetGap',
        label: intl.messages['report.Journal.targetGap'],
      },
      {
        id: 'optInJournals',
        label:
          intl.messages['report.Journal.optInJournals'] +
          { ReportJournalBDMonth },
      },
      {
        id: 'optOutJournals',
        label:
          intl.messages['report.Journal.optOutJournals'] +
          { ReportJournalBDMonth },
      },
      {
        id: 'commission',
        label: intl.messages['report.Journal.commissioningModel'],
      },
      {
        id: 'basic',
        label: intl.messages['report.Journal.basicManagementModel'],
      },
      {
        id: 'onHold',
        label: intl.messages['report.Journal.onHold'],
      },
      {
        id: 'total',
        label: intl.messages['report.Journal.total'],
      },
    ];
    const widthMap = {};
    for (let item of items) {
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '12px Open Sans');
    }
    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (ReportJournalBDData && ReportJournalBDData.length > 0) {
      const maxMap = {};
      for (let i = 0; i < ReportJournalBDData.length; i++) {
        let dataObj = ReportJournalBDData[i];

        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength) {
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '16px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [ReportJournalBDData]);

  function calcAllJournalWidth() {
    let sum = 0;
    for (let id of ['commission', 'basic', 'onHold', 'total']) {
      sum += Math.max(columnWidthMap[id] ?? 0, columnMinWidthMap[id]) + 50;
    }
    return sum;
  }

  const dispatch = useDispatch();

  const getData = async () => {
    await dispatch(GetJournalBDData(selectMonthDate, !isOA));
    dispatch(GetJournalLastActionDate(selectMonthDate, false));
    setLoading(false);
  };

  useEffect(() => {
    if (selectMonthDate !== null && selectMonthDate !== undefined) {
      getData();
      setLoading(true);
    }
  }, [selectMonthDate, isOA]);

  const showDataTableBD = () => {
    if (ReportJournalBDData === null || ReportJournalBDData === undefined) {
      return [];
    } else return ReportJournalBDData;
  };

  const ifGapIsNegative = value => {
    if (value !== '-' && value !== null && value < 0) {
      return true;
    } else {
      return false;
    }
  };

  if (isOA === false) {
    return (
      <TableBox2
        data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeBox'
        dataList={[ReportJournalBDData]}
        tableKey={'Report_Journal-BDTable'}
        footerHeight={0}
      >
        <TableContainer
          id='reportJournalBDTable'
          data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeTableContainer'
          sx={{ maxHeight: '560px' }}
        >
          <Table
            stickyHeader
            aria-label='sticky table'
            data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeTable'
            sx={{
              tableLayout: 'fixed',
              width: 'max-content',
              maxWidth: 'none',
            }}
          >
            <TableHead data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeHead'>
              <TableRow data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeHeadRow1'>
                <StyledTableCellNormal
                  rowSpan={2}
                  className='RightBottomBorder'
                  data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeHeadRow1Cell1'
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['businessDivision'] ?? 0,
                        columnMinWidthMap['businessDivision']
                      ) + 50
                    }px`,
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Business Division
                </StyledTableCellNormal>
                <StyledTableCellGreen
                  rowSpan={2}
                  className='RightBottomBorder'
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['activeJournal'] ?? 0,
                        columnMinWidthMap['activeJournal']
                      ) + 50
                    }px`,
                    whiteSpace: 'nowrap',
                  }}
                  data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeHeadRow1Cell2'
                >
                  Active Journals
                </StyledTableCellGreen>
                <StyledTableCellGreen
                  colSpan={4}
                  align='left'
                  className='RightBottomBorder'
                  data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeHeadRow1Cell3'
                  sx={{
                    width: `${calcAllJournalWidth()}px`,
                  }}
                >
                  Journals Included
                </StyledTableCellGreen>
                <StyledTableCellGreen
                  rowSpan={2}
                  align='left'
                  // sx={{ minWidth: 70, maxWidth: 70 }}
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['activePercentage'] ?? 0,
                        columnMinWidthMap['activePercentage']
                      ) + 50
                    }px`,
                    whiteSpace: 'nowrap',
                  }}
                  className='BottomBorder'
                  data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeHeadRow1Cell4'
                >
                  Active %
                </StyledTableCellGreen>
                <StyledTableCellGreen
                  rowSpan={2}
                  align='left'
                  // sx={{ minWidth: 95, maxWidth: 95 }}
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['targetGap'] ?? 0,
                        columnMinWidthMap['targetGap']
                      ) + 50
                    }px`,
                    whiteSpace: 'nowrap',
                  }}
                  className='BottomBorder'
                  data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeHeadRow1Cell5'
                >
                  Target Gap
                </StyledTableCellGreen>
                <StyledTableCellGreen
                  rowSpan={2}
                  // sx={{ minWidth: 130, maxWidth: 130 }}
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['optInJournals'] ?? 0,
                        columnMinWidthMap['optInJournals']
                      ) + 50
                    }px`,
                  }}
                  className='BottomBorder'
                  data-selenium-id='Report_Journal-BusinessDivsion-Table-AllModeHeadRow1Cell6'
                >
                  <div
                    style={{
                      textAlign:
                        // 'right'
                        'left',
                    }}
                  >
                    Opt-In
                  </div>
                  <div
                    style={{
                      textAlign:
                        // 'right'
                        'left',
                    }}
                  >{`Journals_${ReportJournalBDMonth}`}</div>
                </StyledTableCellGreen>
                <StyledTableCellGreen
                  rowSpan={2}
                  // sx={{ minWidth: 130, maxWidth: 130 }}
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['optOutJournals'] ?? 0,
                        columnMinWidthMap['optOutJournals']
                      ) + 50
                    }px`,
                  }}
                  className='RightBottomBorder'
                  data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeHeadRow1Cell7'
                >
                  <div
                    style={{
                      textAlign:
                        // 'right'
                        'left',
                    }}
                  >
                    Opt-Out
                  </div>
                  <div
                    style={{
                      textAlign:
                        // 'right'
                        'left',
                    }}
                  >{`Journals_${ReportJournalBDMonth}`}</div>
                </StyledTableCellGreen>
              </TableRow>
              <TableRow data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeHeadRow2'>
                <StyledTableCellDisGreen
                  className='BottomBorder'
                  align='left'
                  // sx={{ top: 52.5, minWidth: 165, maxWidth: 165 }}
                  sx={{
                    top: 42,
                    width: `${
                      Math.max(
                        columnWidthMap['commission'] ?? 0,
                        columnMinWidthMap['commission']
                      ) + 50
                    }px`,
                  }}
                  key='1'
                  data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeHeadRow2Cell1'
                >
                  SI Commissioning
                </StyledTableCellDisGreen>
                <StyledTableCellDisGreen
                  className='BottomBorder'
                  align='left'
                  // sx={{ top: 52.5, minWidth: 190, maxWidth: 190 }}
                  sx={{
                    top: 42,
                    width: `${
                      Math.max(
                        columnWidthMap['basic'] ?? 0,
                        columnMinWidthMap['basic']
                      ) + 50
                    }px`,
                  }}
                  key='2'
                  data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeHeadRow2Cell2'
                >
                  Basic Management
                </StyledTableCellDisGreen>
                <StyledTableCellDisGreen
                  className='BottomBorder'
                  align='left'
                  // sx={{ top: 52.5, minWidth: 80, maxWidth: 80 }}
                  sx={{
                    top: 42,
                    width: `${
                      Math.max(
                        columnWidthMap['onHold'] ?? 0,
                        columnMinWidthMap['onHold']
                      ) + 50
                    }px`,
                    whiteSpace: 'nowrap',
                  }}
                  key='3'
                  data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeHeadRow2Cell3'
                >
                  On-Hold
                </StyledTableCellDisGreen>
                <StyledTableCellDisGreen
                  className='RightBottomBorder'
                  align='left'
                  // sx={{ top: 52.5, minWidth: 80, maxWidth: 80 }}
                  sx={{
                    top: 42,
                    width: `${
                      Math.max(
                        columnWidthMap['total'] ?? 0,
                        columnMinWidthMap['total']
                      ) + 50
                    }px`,
                  }}
                  key='3'
                  data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeHeadRow2Cell4'
                >
                  Total
                </StyledTableCellDisGreen>
              </TableRow>
            </TableHead>
            <TableBody data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeBody'>
              {!loading &&
              ReportJournalBDData != null &&
              ReportJournalBDData != undefined &&
              ReportJournalBDData.length != 0
                ? ReportJournalBDData.map(row => {
                    if (row.businessDivision === 'Total') {
                      return (
                        <StyledTableRow data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeBodyRow2'>
                          <StyledTableCell
                            className='RightBorder ReportTotalTextSize'
                            align='right'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeBodyRow2Cell1'
                            sx={{
                              position: 'sticky',
                              left: 0,
                              zIndex: 6,
                              backgroundColor: '#F1F3F5',
                            }}
                          >
                            <SimpleTooltip title={row.businessDivision}>
                              <Box>{row.businessDivision}</Box>
                            </SimpleTooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            className='RightBorder ReportTotalTextSize ReportNumberTextColor'
                            align='left'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeBodyRow2Cell2'
                          >
                            <SimpleTooltip title={row.activeJournal}>
                              <Box>{row.activeJournal}</Box>
                            </SimpleTooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize ReportNumberTextColor NoBorder'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeBodyRow2Cell3'
                          >
                            <Box> {row.commission}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize ReportNumberTextColor NoBorder'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeBodyRow2Cell4'
                          >
                            <Box>{row.basic}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportNumberTextColor'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeBodyRow2Cell5'
                          >
                            <Box>{row.onHold}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeBodyRow2Cell6'
                          >
                            <Box>{row.total}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize ReportNumberTextColor NoBorder'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeBodyRow2Cell7'
                          >
                            <Box>{row.activePercentage}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize NoBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeBodyRow2Cell8'
                          >
                            <Box>
                              {!ifGapIsNegative(row.targetGap) ? (
                                <div className='ReportNumberTextColor'>
                                  {row.targetGap}
                                </div>
                              ) : (
                                <div className='ReportNumberTextColorRed'>
                                  {row.targetGap}
                                </div>
                              )}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='NoBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeBodyRow2Cell9'
                          >
                            <Box>{row.optInJournals}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeBodyRow2Cell10'
                          >
                            <Box>
                              {row.optOutJournals ? row.optOutJournals : 0}
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    }
                    return (
                      <StyledTableRow data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeBodyRow1'>
                        <StyledTableCell
                          className='RightBorder'
                          align='left'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeBodyRow1Cell1'
                          sx={{
                            position: 'sticky',
                            left: 0,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                        >
                          <SimpleTooltip title={row.businessDivision}>
                            {row.businessDivision}
                          </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          className='RightBorder ReportNumberTextColor'
                          align='left'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeBodyRow1Cell2'
                        >
                          <SimpleTooltip title={row.activeJournal}>
                            {row.activeJournal}
                          </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeRow1Cell3'
                        >
                          {row.commission}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeRow1Cell4'
                        >
                          {row.basic}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeRow1Cell5'
                        >
                          {row.onHold}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportNumberTextColor'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeRow1Cell6'
                        >
                          {row.total}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeRow1Cell7'
                        >
                          {row.activePercentage}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeRow1Cell8'
                        >
                          {!ifGapIsNegative(row.targetGap) ? (
                            <div className='ReportNumberTextColor'>
                              {row.targetGap}
                            </div>
                          ) : (
                            <div className='ReportNumberTextColorRed'>
                              {row.targetGap}
                            </div>
                          )}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeRow1Cell9'
                        >
                          {row.optInJournals}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportNumberTextColor'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeRow1Cell10'
                        >
                          {row.optOutJournals ? row.optOutJournals : 0}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                : !loading && (
                    <ReportNoData id='reportJournalBDNoResultTableCell' />
                  )}
            </TableBody>
          </Table>
          {loading && (
            <Box
              sx={{
                width: 'auto',
                marginTop: '45px',
                marginLeft: '50%',
              }}
              data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeLoadingBox'
            >
              <CircularProgress data-selenium-id='Report_Journal-BusinessDivision-Table-AllModeCircularProgress' />
            </Box>
          )}
        </TableContainer>
      </TableBox2>
    );
  } else {
    return (
      <TableBox2
        data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBox'
        dataList={[ReportJournalBDData]}
        tableKey={'Report_Journal-BDTable'}
        footerHeight={0}
      >
        <TableContainer
          id='reportJournalBDTable'
          data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeTableContainer'
          sx={{ maxHeight: '560px' }}
        >
          <Table
            stickyHeader
            aria-label='sticky table'
            data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeTable'
            sx={{
              tableLayout: 'fixed',
              width: 'max-content',
              maxWidth: 'none',
            }}
          >
            <TableHead data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeTableHead'>
              <TableRow data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeHeadRow1'>
                <StyledTableCellNormal
                  rowSpan={2}
                  className='RightBottomBorder'
                  data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeHeadRow1Cell1'
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['businessDivision'] ?? 0,
                        columnMinWidthMap['businessDivision']
                      ) + 50
                    }px`,
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Business Division
                </StyledTableCellNormal>
                <StyledTableCellPurple
                  rowSpan={2}
                  className='RightBottomBorder'
                  data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeHeadRow1Cell2'
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['activeJournal'] ?? 0,
                        columnMinWidthMap['activeJournal']
                      ) + 50
                    }px`,
                    whiteSpace: 'nowrap',
                  }}
                >
                  Active Journals
                </StyledTableCellPurple>
                <StyledTableCellPurple
                  colSpan={4}
                  align='left'
                  className='RightBottomBorder'
                  data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeHeadRow1Cell3'
                  sx={{
                    width: `${calcAllJournalWidth()}px`,
                  }}
                >
                  Journals Included
                </StyledTableCellPurple>
                <StyledTableCellPurple
                  rowSpan={2}
                  align='left'
                  className='BottomBorder'
                  // sx={{ minWidth: 70, maxWidth: 70 }}
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['activePercentage'] ?? 0,
                        columnMinWidthMap['activePercentage']
                      ) + 50
                    }px`,
                    whiteSpace: 'nowrap',
                  }}
                  data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeHeadRow1Cell4'
                >
                  Active %
                </StyledTableCellPurple>
                <StyledTableCellPurple
                  rowSpan={2}
                  align='left'
                  className='BottomBorder'
                  // sx={{ minWidth: 95, maxWidth: 95 }}
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['targetGap'] ?? 0,
                        columnMinWidthMap['targetGap']
                      ) + 50
                    }px`,
                    whiteSpace: 'nowrap',
                  }}
                  data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeHeadRow1Cell5'
                >
                  Target Gap
                </StyledTableCellPurple>
                <StyledTableCellPurple
                  rowSpan={2}
                  className='BottomBorder'
                  // sx={{ minWidth: 130, maxWidth: 130 }}
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['optInJournals'] ?? 0,
                        columnMinWidthMap['optInJournals']
                      ) + 50
                    }px`,
                  }}
                  data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeHeadRow1Cell6'
                >
                  <div style={{ textAlign: 'left' }}>Opt-In</div>
                  <div
                    style={{ textAlign: 'left' }}
                  >{`Journals_${ReportJournalBDMonth}`}</div>
                </StyledTableCellPurple>
                <StyledTableCellPurple
                  rowSpan={2}
                  className='RightBottomBorder'
                  // sx={{ minWidth: 130, maxWidth: 130 }}
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['optOutJournals'] ?? 0,
                        columnMinWidthMap['optOutJournals']
                      ) + 50
                    }px`,
                  }}
                  data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeHeadRow1Cell7'
                >
                  <div style={{ textAlign: 'left' }}>Opt-Out</div>
                  <div
                    style={{ textAlign: 'left' }}
                  >{`Journals_${ReportJournalBDMonth}`}</div>
                </StyledTableCellPurple>
              </TableRow>
              <TableRow data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeHeadRow2'>
                <StyledTableCellDisPurple
                  className='BottomBorder'
                  align='left'
                  // sx={{ top: 52.5, minWidth: 165, maxWidth: 165 }}
                  sx={{
                    top: 42,
                    width: `${
                      Math.max(
                        columnWidthMap['commission'] ?? 0,
                        columnMinWidthMap['commission']
                      ) + 50
                    }px`,
                  }}
                  key='1'
                  data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeHeadRow2Cell1'
                >
                  SI Commissioning
                </StyledTableCellDisPurple>
                <StyledTableCellDisPurple
                  className='BottomBorder'
                  align='left'
                  // sx={{ top: 52.5, minWidth: 190, maxWidth: 190 }}
                  sx={{
                    top: 42,
                    width: `${
                      Math.max(
                        columnWidthMap['basic'] ?? 0,
                        columnMinWidthMap['basic']
                      ) + 50
                    }px`,
                  }}
                  key='2'
                  data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeHeadRow2Cell2'
                >
                  Basic Management
                </StyledTableCellDisPurple>
                <StyledTableCellDisPurple
                  className='BottomBorder'
                  align='left'
                  // sx={{ top: 52.5, minWidth: 80, maxWidth: 80 }}
                  sx={{
                    top: 42,
                    width: `${
                      Math.max(
                        columnWidthMap['onHold'] ?? 0,
                        columnMinWidthMap['onHold']
                      ) + 50
                    }px`,
                    whiteSpace: 'nowrap',
                  }}
                  key='3'
                  data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeHeadRow2Cell3'
                >
                  On-Hold
                </StyledTableCellDisPurple>
                <StyledTableCellDisPurple
                  className='RightBottomBorder'
                  align='left'
                  // sx={{ top: 52.5, minWidth: 80, maxWidth: 80 }}
                  sx={{
                    top: 42,
                    width: `${
                      Math.max(
                        columnWidthMap['total'] ?? 0,
                        columnMinWidthMap['total']
                      ) + 50
                    }px`,
                  }}
                  key='3'
                  data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeHeadRow2Cell4'
                >
                  Total
                </StyledTableCellDisPurple>
              </TableRow>
            </TableHead>
            <TableBody data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBody'>
              {!loading &&
              ReportJournalBDData != null &&
              ReportJournalBDData != undefined &&
              ReportJournalBDData.length != 0
                ? ReportJournalBDData.map(row => {
                    if (row.businessDivision === 'Total') {
                      return (
                        <StyledTableRow data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow2'>
                          <StyledTableCell
                            className='RightBorder ReportTotalTextSize'
                            align='right'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow2Cell1'
                            sx={{
                              position: 'sticky',
                              left: 0,
                              zIndex: 6,
                              backgroundColor: '#F1F3F5',
                            }}
                          >
                            <SimpleTooltip title={row.businessDivision}>
                              <Box>{row.businessDivision}</Box>
                            </SimpleTooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            className='RightBorder ReportTotalTextSize ReportNumberTextColor'
                            align='left'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow2Cell2'
                          >
                            <SimpleTooltip title={row.activeJournal}>
                              <Box>{row.activeJournal}</Box>
                            </SimpleTooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize NoBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow2Cell3'
                          >
                            <Box> {row.commission}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize NoBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow2Cell4'
                          >
                            <Box>{row.basic}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize ReportNumberTextColor'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow2Cell5'
                          >
                            <Box>{row.onHold}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder ReportTotalTextSize ReportNumberTextColor'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow2Cell6'
                          >
                            <Box>{row.total}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize NoBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow2Cell7'
                          >
                            <Box>{row.activePercentage}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize NoBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow2Cell8'
                          >
                            <Box>
                              {!ifGapIsNegative(row.targetGap) ? (
                                <div className='ReportNumberTextColor'>
                                  {row.targetGap}
                                </div>
                              ) : (
                                <div className='ReportNumberTextColorRed'>
                                  {row.targetGap}
                                </div>
                              )}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='NoBorder ReportTotalTextSize ReportNumberTextColor'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow2Cell9'
                          >
                            <Box>{row.optInJournals}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder ReportTotalTextSize ReportNumberTextColor'
                            data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow2Cell10'
                          >
                            <Box>
                              {row.optOutJournals ? row.optOutJournals : 0}
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    }
                    return (
                      <StyledTableRow data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow1'>
                        <StyledTableCell
                          className='RightBorder'
                          align='left'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow1Cell1'
                          sx={{
                            position: 'sticky',
                            left: 0,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                        >
                          <SimpleTooltip title={row.businessDivision}>
                            {row.businessDivision}
                          </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          className='RightBorder ReportNumberTextColor'
                          align='left'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow1Cell2'
                        >
                          <SimpleTooltip title={row.activeJournal}>
                            {row.activeJournal}
                          </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow1Cell3'
                        >
                          {row.commission}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow1Cell4'
                        >
                          {row.basic}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow1Cell5'
                        >
                          {row.onHold}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportNumberTextColor'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow1Cell6'
                        >
                          {row.total}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow1Cell7'
                        >
                          {row.activePercentage}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow1Cell8'
                        >
                          {!ifGapIsNegative(row.targetGap) ? (
                            <div className='ReportNumberTextColor'>
                              {row.targetGap}
                            </div>
                          ) : (
                            <div className='ReportNumberTextColorRed'>
                              {row.targetGap}
                            </div>
                          )}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow1Cell9'
                        >
                          {row.optInJournals}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportNumberTextColor'
                          data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBodyRow1Cell10'
                        >
                          {row.optOutJournals ? row.optOutJournals : 0}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                : !loading && (
                    <ReportNoData id='reportJournalBDNoResultTableCell' />
                  )}
            </TableBody>
          </Table>
          {loading && (
            <Box
              sx={{
                width: 'auto',
                marginTop: '45px',
                marginLeft: '50%',
              }}
              data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeLoadingBox'
            >
              <CircularProgress data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeCircularProgress' />
            </Box>
          )}
        </TableContainer>
      </TableBox2>
    );
  }
}

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetAAndAFYTrendsChartDataWithStatus,
} from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import {replaceZero} from "@/pages/Report/utils/reportUtils";

export default function useChartsDataHook(
  selectedFY,
  catagoryAttributeName,
  url,
  title,
  selectedItem
) {
  const dispatch = useDispatch();

  const { reportDataAPI } = useSelector(state => {
    return {
      reportDataAPI: state.ReportDiagram[title],
    };
  });

  let loading = null,
    error = null,
    data = null;

  if (typeof reportDataAPI !== 'undefined') {
    ({ loading, error, data } = reportDataAPI);
  }

  replaceZero(data);

  useEffect(() => {
    if (
      typeof reportDataAPI === 'undefined' &&
      selectedFY !== null &&
      selectedItem
    ) {
      dispatch(
        GetAAndAFYTrendsChartDataWithStatus(
          url,
          title,
          selectedFY,
          catagoryAttributeName,
          selectedItem
        )
      );
    }
  }, [selectedFY, title, reportDataAPI]);

  return [data, loading, error];
}

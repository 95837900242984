import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReadTable from './Submodule/ReadTable';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import GEInfoAdd from '@/assets/GEInfoAdd.svg';
import ProfileWebsite from '@/assets/ProfileWebsite.svg';
import SIPActionsView from '@/assets/SIPActionsView.svg';
import SIPActionsDelete from '@/assets/SIPActionsDelete.svg';
import GEDetailDialog from './GEDetail/GEDetailDialog';
import { DownloadCvBlock } from './GEDetail/DownloadCvBlock';
import { GEInfomationAddModal } from './GEDetail/GEInfomationAddModal';
import { setSnackbarMessageAndOpen } from '../../../actions/SnackbarAction';
import { SEVERITIES } from '../../../modules/ConfirmSnackBar/CommonSnackBar';
import { SET_UPLOAD_CV_STATUS } from '../../../actions/SIMT-SIP/SIP/SIPAction';
import GEDetailReadTable from './GEDetail/GEDetailReadTable';
import CustomDialog from './Submodule/CustomDialog';
import AddIconButton from '@/componentsphase2/Button/AddIconButton';
import LinkContainerButton from '@/componentsphase2/Button/ContainerButtons/LinkContainerButton';
import EyeContainerButton from '@/componentsphase2/Button/ContainerButtons/EyeContainerButton';
import DeleteContainerButton from '@/componentsphase2/Button/ContainerButtons/DeleteContainerButton';
import styleModule from './GEInformationBlock.module.scss';
import { GeInformationBlockDeleteTooltip } from '@/components/CustomTooltip';
import { StyledTooltip } from '@/pages/SIPage/Overview/Filter/component/StyledComponents/styledToolTip';
import NewFlagMatchingModal, {
  nameOrEmailChange,
} from './NewFlagMatchingModal';
import { ButtonAlertBox } from '@/components/Alert/AlertBox';
import { unstable_batchedUpdates } from 'react-dom';
import { needToComfirmShow } from '@/pages/SIPPage/SIPDetailPage/utils/isSubmitted';
import { RadioErrorMessage } from '@/componentsphase2/RadioErrorMessage';
import { CommentsDisabledOutlined } from '@mui/icons-material';
import { GET_SIP_STATUS } from '@/actions/SIMT-SIP/SIP/SIPAction';
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import { useUpdateEffect } from 'ahooks';
import simpleDisplayError from '@/utils/simpleDisplayError';
import {
  checkProposerGE,
  checkProposerOK,
  DeleteGEInfomation,
  getGEInformation,
  getSIPStatus,
} from '@/actions/SIMT-SIP/SIP/SipDetail';
import { ChecklistStatus } from '@/components/Form/form.d';
import {
  validateGEInformation,
  validateGEWithStageAndDecision,
} from '@/pages/SIPPage/SIPDetailPage/DecisionPanel/validation';

/**
 * @param {object} props
 * @param {boolean} props.Editable
 * @param {string} props.sipStage
 * @param {(e:boolean)=>void} props.setDecisionIsFold
 */
export default function GEInformationBlock(props) {
  const {
    Editable = true,
    sipStage = '',
    setDecisionIsFold = _e => {},
    setIsDecisionButtonDisabled = () => {},
  } = props;
  const [isFold, setIsFold] = useState(true);
  const [tableData, setTableData] = useState([]);
  const { sipCode } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    getSIPStatus(sipCode);
  }, []);
  /**
   * @param {{readonly UserManage:{readonly siderOpen:boolean};readonly SIP:{readonly geInformation:ReadonlyArray<import('./NewFlagMatchingModal').newSingleGeData>;readonly deleteGEResult:string;readonly deleteGEMonitor:unknown;}}} state
   */
  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      geInformation: state.SIP.geInformation,
      deleteGEResult: state.SIP.deleteGEResult,
      deleteGEMonitor: state.SIP.deleteGEMonitor,
      sipType: state.SIP.sipInformation?.sipType,
      sipVersion: state.SIP.sipStatus?.sipVersion,
      sipStatus: state.SIP.sipStatus,
      sipChecklistStatus: state.SIPChecklist.SIPChecklistDetail.status,
      sipDetailAllMandatorySubmitOrNextPressed:
        state.SIP.sipDetailAllMandatorySubmitOrNextPressed,
      currentDecisionType: state.SIP.currentDecisionType,
      contentCommissioningApproaches: state.SIP.contentCommissioningApproaches,
    };
  };
  const {
    open,
    geInformation,
    deleteGEResult,
    deleteGEMonitor,
    sipType,
    sipVersion,
    sipStatus,
    sipChecklistStatus,
    sipDetailAllMandatorySubmitOrNextPressed,
    currentDecisionType,
    contentCommissioningApproaches,
  } = useSelector(selector);
  const [isOpen, setIsOpen] = useState(false);
  const [theGeInformation, setTheGeInformation] = useState({
    geId: '',
    geCode: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    institution: '',
    jobTitle: '',
    country: '',
    profileWebsite: '',
    cv: '',
    leadGe: '',
    matchedEmailGeCode: '',
    matchedNameGeCode: '',
    id: '',
  });
  const [addGeInfomationOpen, setAddGEInfomationOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteGE, setDeleteGE] = useState();
  const [newFlagMatchingModalOpen, setNewFlagMatchingModalOpen] =
    useState(false);
  const [fullNameChangeError, setFullNameChangeError] = useState(false);

  /**
   * @param {string} id
   * @param {boolean} isLeadge
   */
  const handleDeleteGE = (id, isLeadge) => {
    if (!isLeadge) {
      dispatch(DeleteGEInfomation(id, sipCode, sipVersion)).then(
        sipNewVersion => {
          if (sipNewVersion) {
            let newSipStatus = {
              ...sipStatus,
              sipVersion: sipNewVersion,
            };
            dispatch({
              type: GET_SIP_STATUS,
              data: {
                ...newSipStatus,
              },
            });
          }
        }
      );
    } else {
      let leadGECount = 0,
        coGECount = 0;
      for (const geInfo of geInformation) {
        if (geInfo.leadGe) {
          leadGECount++;
        } else {
          coGECount++;
        }
      }
      if (
        (leadGECount === 1 && sipStage !== 'Ideation') ||
        (leadGECount === 1 && coGECount !== 0 && sipStage === 'Ideation')
      ) {
        dispatch(
          setSnackbarMessageAndOpen(
            'sipDetail.edit.deleteGENumberErr',
            {},
            SEVERITIES.error
          )
        );
      } else {
        dispatch(DeleteGEInfomation(id, sipCode, sipVersion)).then(
          sipNewVersion => {
            if (sipNewVersion) {
              let newSipStatus = {
                ...sipStatus,
                sipVersion: sipNewVersion,
              };
              dispatch({
                type: GET_SIP_STATUS,
                data: {
                  ...newSipStatus,
                },
              });
            }
          }
        );
      }
    }
    setDeleteDialogOpen(false);
  };
  /**
   * @param {string} id
   * @param {string} leadGe
   */
  const DeleteGEOpen = (id, leadGe) => {
    let count = 0;
    let leadGECount = 0,
      coGECount = 0;
    for (const geInfo of geInformation) {
      if (geInfo.leadGe) {
        leadGECount++;
      } else {
        coGECount++;
      }
      if (geInfo.id === id) {
        setDeleteGE(geInfo);
      }
    }
    if (
      (leadGECount === 1 && sipStage !== 'Ideation' && leadGe) ||
      (leadGECount === 1 &&
        coGECount !== 0 &&
        sipStage === 'Ideation' &&
        leadGe)
    ) {
      dispatch(
        setSnackbarMessageAndOpen(
          'sipDetail.edit.deleteGENumberErr',
          {},
          SEVERITIES.error
        )
      );
    } else {
      setDeleteDialogOpen(true);
    }
  };

  useEffect(() => {
    if (deleteGEResult === 'OK') {
      dispatch(getGEInformation(sipCode));
    }
  }, [deleteGEMonitor]);

  useUpdateEffect(() => {
    if (
      needToComfirmShow(sipStage) &&
      geInformation &&
      Array.isArray(geInformation) &&
      geInformation.length > 0
    ) {
      checkProposerOK(sipCode)
        .then(
          /**
           * @param {import('axios').AxiosResponse<{readonly data:boolean;}>} res
           */
          res => {
            if (res?.data?.data) {
              setIsDecisionButtonDisabled(false);
            }
          }
        )
        .catch(console.error);
    }
  }, [JSON.stringify(geInformation)]);

  useEffect(() => {
    if (geInformation) {
      let tmp = [];
      for (const geInfo of geInformation) {
        let tmpObj = {};
        tmpObj['leadGe'] = handleFieldsReplacement('leadGe', geInfo.leadGe);
        tmpObj['jobTitle'] = geInfo.jobTitle;
        tmpObj['fullName'] = geInfo.lastName + ', ' + geInfo.firstName;
        tmpObj['country'] = handleFieldsReplacement('country', geInfo.country);
        tmpObj['profileWebsite'] = handleFieldsReplacement(
          'profileWebsite',
          geInfo.profileWebsite
        );
        tmpObj['cv'] = handleFieldsReplacement('cv', geInfo.cv);
        tmpObj['emailAddress'] = geInfo.emailAddress;
        tmpObj['alertGeFlagType'] = geInfo.alertGeFlagType;
        tmpObj['matchedEmailGeFlagType'] = geInfo.matchedEmailGeFlagType;
        tmpObj['matchedEmailGeCode'] = geInfo.matchedEmailGeCode;
        tmpObj['matchedNameGeCode'] = geInfo.matchedNameGeCode;
        tmpObj['matchedNameGeFlagType'] = geInfo.matchedNameGeFlagType;
        tmpObj['geCode'] =
          geInfo.matchedEmailGeCode ?? geInfo.matchedNameGeCode;
        tmpObj['needToConfirm'] = geInfo.needToConfirm ?? 'No';

        const NeedToConfrimButtonWithLayout = () => {
          if (!needToComfirmShow(sipStage)) return <></>;
          if (tmpObj['needToConfirm'] === 'No') return <></>;
          const onClick = unstable_batchedUpdates(() => async () => {
            const /**@type {import('@/componentsphase2/FlaggedGESynchronizationAndVerificationTable').singleGeData}*/ res =
                (
                  await new Promise(resolve =>
                    dispatch(getGEInformation(sipCode, resolve))
                  )
                )?.[geInformation?.indexOf(geInfo)];
            if (nameOrEmailChange(res, geInfo, true)) {
              setFullNameChangeError(true);
              return;
            }
            setTheGeInformation(geInfo);
            setNewFlagMatchingModalOpen(true);
          });

          const [show, setShow] = useState(false);

          useEffect(() => {
            if (
              Boolean(
                (geInfo?.matchedEmailGeCode !== null &&
                  geInfo?.matchedEmailGeCode !== undefined) ||
                  (geInfo?.matchedNameGeCode !== null &&
                    geInfo?.matchedNameGeCode !== undefined)
              )
            ) {
              setShow(true);
              return;
            }
            checkProposerGE(geInfo.geId, sipCode).then(res => {
              if (!res.data.data) setShow(true);
            });
          }, []);

          if (!show) return <></>;

          return (
            <StyledTooltip
              title='There are one or more GEs newly flagged with some issues after the "Initial Review" stage, due to the update of the GE database'
              placement='top'
              sx={{
                '.MuiTooltip-tooltip': {
                  minWidth: '346px',
                  textAlign: 'center',
                },
              }}
            >
              <Box
                sx={{
                  py: '8px',
                  ml: '3px !important',
                  minWidth: 'fit-content',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '22px',
                    p: '2px 4px',
                    borderRadius: '4px',
                    background: '#FDD',
                    color: '#FF8180',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '18px',
                    cursor: 'pointer',
                  }}
                  onClick={onClick}
                >
                  Need to confirm
                </Box>
              </Box>
            </StyledTooltip>
          );
        };

        if (Editable) {
          tmpObj['actions'] = (
            <Stack
              data-selenium-id='SIP_DetailPage-GEInformationBlock-Editable-Stack'
              direction='row'
              spacing={1}
              sx={{
                display: 'flex',
                justifyContent: 'start', //modified
              }}
            >
              <EyeContainerButton
                onClick={() => handleGEDetailOpen(geInfo)}
                data-selenium-id='SIP_DetailPage-GEInformationBlock-Editable-IconButton1'
                sx={{ padding: '0px' }}
              ></EyeContainerButton>
              {geInfo.leadGe && sipType !== 'Yes' ? (
                <GeInformationBlockDeleteTooltip
                  data-selenium-id='SIP_DetailPage-GEInformationBlock-Editable-GeInformationBlockDeleteTooltip'
                  arrow
                  title='There must be at least one Lead Guest Editor per SIP'
                  placement='top'
                  // open={true}         //该属性用于调试tooltip
                >
                  <div>
                    <DeleteContainerButton
                      data-selenium-id='SIP_DetailPage-GEInformationBlock-Editable-IconButton2'
                      onClick={() => {
                        DeleteGEOpen(geInfo.id, geInfo.leadGe);
                      }}
                    />
                  </div>
                </GeInformationBlockDeleteTooltip>
              ) : (
                <div>
                  <DeleteContainerButton
                    data-selenium-id='SIP_DetailPage-GEInformationBlock-Editable-IconButton2'
                    onClick={() => {
                      DeleteGEOpen(geInfo.id, geInfo.leadGe);
                    }}
                  />
                </div>
              )}
              <NeedToConfrimButtonWithLayout />
            </Stack>
          );
        } else {
          tmpObj['actions'] = (
            <Stack
              data-selenium-id='SIP_DetailPage-GEInformationBlock-UnEditable-Stack'
              direction='row'
              spacing={1}
              sx={{
                display: 'flex',
                justifyContent: 'start', //modified
              }}
            >
              {/* <IconButton data-selenium-id='SIP_DetailPage-GEInformationBlock-UnEditable-IconButton1'>
                <SIPActionsView
                  onClick={() => handleGEDetailOpen(geInfo)}
                  data-selenium-id='SIP_DetailPage-GEInformationBlock-UnEditable-ActionsView'
                />
              </IconButton> */}
              <EyeContainerButton
                onClick={() => handleGEDetailOpen(geInfo)}
                data-selenium-id='SIP_DetailPage-GEInformationBlock-UnEditable-ActionsView'
                className={styleModule.EyeContainerButton}
              ></EyeContainerButton>
              {/* <IconButton
                disabled
                data-selenium-id='SIP_DetailPage-GEInformationBlock-UnEditable-IconButton2'
              >
                <SIPActionsDelete data-selenium-id='SIP_DetailPage-GEInformationBlock-UnEditable-ActionsDelete ' />
              </IconButton> */}
              <DeleteContainerButton
                disabled
                data-selenium-id='SIP_DetailPage-GEInformationBlock-UnEditable-IconButton2'
                className={styleModule.DeleteContainerButton}
              />
              <NeedToConfrimButtonWithLayout />
            </Stack>
          );
        }
        tmp.push(tmpObj);
      }
      setTableData(tmp);
    }
  }, [geInformation, Editable]);
  /**
   * @param {string} id
   * @param {string} label
   * @param {string} minWidth
   * @param {string} maxWidth
   */
  const createRow = (
    id,
    label,
    minWidth,
    maxWidth,
    isOrder = true,
    align = 'left'
  ) => {
    return { id, label, minWidth, maxWidth, isOrder, align };
  };

  const tableHeads =
    sipType === 'Yes'
      ? [
          //SSIP
          {
            ...createRow('leadGe', 'LEAD GE', '103px', '103px', false),
            sx: { whiteSpace: 'nowrap' },
          },
          createRow('jobTitle', 'JOB TITLE', '126px', '126px', false),
          createRow('fullName', 'FULL Name', '165px', '165px', false),
          createRow(
            'emailAddress',
            'EMAIL ADDRESS',
            '165px',
            '165px',
            false
            // 'center'
          ),
          createRow('country', 'COUNTRY/REGION', '169px', '169px', false),
          createRow('profileWebsite', 'PROFILE LINK', '139px', '139px', false),
          createRow('cv', 'CV', '65px', '65px', false, 'center'),
          createRow(
            'matchedEmailGeCode',
            'MATCHED GE',
            '160px',
            '160px',
            false,
            'center'
          ),
          createRow('actions', 'ACTIONS', '150px', '160px', false, 'center'),
        ]
      : [
          //USIP
          {
            ...createRow('leadGe', 'LEAD GE', '103px', '103px', false),
            sx: { whiteSpace: 'nowrap' },
          },
          createRow('jobTitle', 'JOB TITLE', '126px', '126px', false),
          createRow('fullName', 'FULL Name', '200px', '200px', false),
          createRow('country', 'COUNTRY', '126px', '126px', false),
          createRow(
            'profileWebsite',
            'PROFILE LINK',
            '140px',
            '140px',
            false
            // 'center'
          ),
          createRow('cv', 'CV', '65px', '65px', false, 'center'),
          createRow(
            'matchedEmailGeCode',
            'MATCHED GE',
            '160px',
            '160px',
            false,
            'center'
          ),
          createRow('actions', 'ACTIONS', '162px', '162px', false, 'center'),
        ];
  /**
   * @param {string} key
   * @param {*} val
   */
  const handleFieldsReplacement = (key, val) => {
    if (key === 'profileWebsite') {
      if (val) {
        return (
          // <IconButton
          //   data-selenium-id='SIP_DetailPage-GEInformationBlock-HandleFieldReplacement-IconButton'
          //   onClick={() => {
          //     if (
          //       val.substr(0, 7).toLowerCase() === 'http://' ||
          //       val.substr(0, 8).toLowerCase() === 'https://'
          //     ) {
          //       window.open(val);
          //     } else {
          //       window.open('https://' + val);
          //     }
          //   }}
          // >
          //   <ProfileWebsite data-selenium-id='SIP_DetailPage-GEInformationBlock-HandleFieldReplacement-ProfileWebsite' />
          // </IconButton>
          <LinkContainerButton
            data-selenium-id='SIP_DetailPage-GEInformationBlock-HandleFieldReplacement-IconButton'
            onClick={() => {
              if (
                val.substr(0, 7).toLowerCase() === 'http://' ||
                val.substr(0, 8).toLowerCase() === 'https://'
              ) {
                window.open(val);
              } else {
                window.open('https://' + val);
              }
            }}
            sx={{ paddingLeft: '0' }}
          ></LinkContainerButton>
        );
      } else {
        return 'N/A';
      }
    } else if (key === 'cv') {
      if (val) {
        return <DownloadCvBlock fileName={val}></DownloadCvBlock>;
      } else {
        return 'N/A';
      }
    } else if (key === 'leadGe') {
      if (val === true) {
        return 'Yes';
      } else if (val === false) {
        return 'No';
      } else {
        return 'N/A';
      }
    } else if (key === 'country') {
      if (val) {
        return val;
      } else {
        return 'N/A';
      }
    } else {
      return val;
    }
  };

  /**
   * @param {import('./NewFlagMatchingModal').newSingleGeData} geInfo
   */
  function handleGEDetailOpen(geInfo) {
    setIsOpen(true);
    setTheGeInformation(geInfo);
  }
  const handleGEDetailClose = () => {
    setIsOpen(false);
    dispatch({
      type: SET_UPLOAD_CV_STATUS,
      /** @type {import('../RevisePage/CustomComponents/CustomButton').SIPDetailGEInfomationUploadCVIdStatus} */
      data: 'idle',
    });
  };

  const handleGEDelete = () => {
    handleDeleteGE(deleteGE.id, deleteGE.leadGe);
  };

  return (
    <Box data-selenium-id='SIP_DetailPage-GEInformationBlock-Box'>
      {isFold && geInformation && isOpen && (
        <GEDetailDialog
          isOpen={isOpen}
          handleGEDetailClose={handleGEDetailClose}
          theGeInformation={theGeInformation}
          Editable={Editable}
        ></GEDetailDialog>
      )}
      <Box
        sx={{ mb: '18px', mt: '22px', cursor: 'pointer' }}
        className='sipBlockTitleBox'
        data-selenium-id='SIP_DetailPage-GEInformationBlock-TitleBox'
      >
        <Box
          data-selenium-id='SIP_DetailPage-GEInformationBlock-FoldButtonBox'
          class='titleFoldButton'
          onClick={async () => {
            await dispatch(getGEInformation(sipCode));
            setIsFold(!isFold);
          }}
        >
          {isFold ? <MarketChevronUp /> : <SIDetailArrowDown />}
        </Box>
        <Stack
          id='titleBoxLeft'
          data-selenium-id='SIP_DetailPage-GEInformationBlock-TtitleBoxLeft'
          spacing={1}
          direction='row'
          sx={{ cursor: 'default' }}
        >
          <Box>
            <FormattedMessage
              id='sipDetail.geInformation'
              data-selenium-id='SIP_DetailPage-GEInformationBlock-Message'
            />
          </Box>
          {Editable ? (
            <AddIconButton
              id='sipDetailGEInfoEditButton'
              data-selenium-id='SIP_DetailPage-GEInformationBlock-GEInfoEditButton'
              onClick={() => {
                if (
                  sipStage === 'Initial Review'&&
                  (sipChecklistStatus === ChecklistStatus.IN_PROGRESS ||
                  sipChecklistStatus === ChecklistStatus.COMPLETED)
                ) {
                  return dispatch(
                    simpleDisplayError(
                      'Failed to modify SIP because checklist is in editing or completed.'
                    )
                  );
                }

                setAddGEInfomationOpen(true);
                if (sipStage == 'Ideation') {
                  setDecisionIsFold(true);
                }
              }}
              className={styleModule.ButtonMarginLeft0}
            ></AddIconButton>
          ) : null}
          <GEInfomationAddModal
            title='Add Guest Editor'
            open={addGeInfomationOpen}
            handleClose={() => {
              setAddGEInfomationOpen(false);
            }}
          />
        </Stack>
      </Box>
      {/*<RadioErrorMessage*/}
      {/*    isError={sipDetailAllMandatorySubmitOrNextPressed && (*/}
      {/*        (geInformation?.length ?? 0) !== 0 ||*/}
      {/*        !validateGEWithStageAndDecision(geInformation, sipStage, currentDecisionType)*/}
      {/*    )}*/}
      {/*    sx={{marginTop:'-12px'}}*/}
      {/*    errorMessage='Please complete highlighted GE information.'*/}
      {/*/>*/}
      {isFold ? (
        <>
          <GEDetailReadTable
            id='sipGEInformationTable'
            TableHeads={tableHeads}
            TableData={tableData}
            SipStage={sipStage}
            sipType={sipType}
            SipCode={sipCode}
          ></GEDetailReadTable>
          <CustomDialog
            title={`Delete guest editor '${
              deleteGE?.lastName + ', ' + deleteGE?.firstName
            }'`}
            info='Are you sure you want to proceed ?'
            open={deleteDialogOpen}
            handleClose={() => {
              setDeleteDialogOpen(false);
            }}
            handleOK={handleGEDelete}
          />
          <NewFlagMatchingModal
            index={geInformation?.indexOf(theGeInformation)}
            theGeInformation={theGeInformation}
            open={newFlagMatchingModalOpen}
            handleClose={() => {
              setNewFlagMatchingModalOpen(false);
            }}
            sipCode={sipCode}
          />
          <ButtonAlertBox
            open={fullNameChangeError}
            title='The connection between GE-Proposer and GE-in database is currently disrupted.'
            message='Please refresh the page to check  the most recent status update.'
            severity={SEVERITIES.warning}
            buttons={[
              {
                text: 'Refresh',
                onClick: () => location.reload(),
              },
            ]}
          />
        </>
      ) : (
        <></>
      )}
    </Box>
  );
}

import styled from '@emotion/styled';
import {
  Autocomplete,
  Box,
  IconButton,
  Checkbox,
  TextField,
  Tooltip,
  Icon,
} from '@mui/material';
import SIDrawerAutoCompleteTagDeleteBold from '../../../../assets/SIDrawerAutoCompleteTagDeleteBold.svg';
import SIDrawerAutoCompleteTagDelete from '../../../../assets/SIDrawerAutoCompleteTagDelete.svg';
import SIDrawerAutocompleteClearIcon from '../../../../assets/SIDrawerAutocompleteClearIcon.svg';
import SIDrawerAutoCompletePopupIcon from '../../../../assets/SIDrawerAutoCompletePopupIconPhase2.svg';
import SIDrawerAutocompletePupIconTypeOne from '../../../../assets/SIDrawerAutocompletePupIconTypeOne.svg';
import SIDrawerAutocompletePupIconTypeSecond from '../../../../assets/SIDrawerAutocompletePupIconTypeSecond.svg';
import SIDrawerAutocompletePupIconTypeThird from '../../../../assets/SIDrawerAutocompletePupIconTypeThird.svg';
import SIDrawerAutoCompleteClearIcon from '../../../../assets/SIDrawerAutoCompleteClearIconPhase2.svg';
import SIFilterCalendarIcon from '../../../../assets/SIFilterCalendarIcon.svg';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { MiniFont, RegularBoldFont } from '../../../../components/FontStyle';
import { useEffect, useLayoutEffect, useState } from 'react';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {
  drawer_main_stage_select_checkbox,
  drawer_main_stage_select_checkbox_lable,
} from './style';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StyledChipTypeOne, StyledChipTypeSecond } from './style';
import StyledChip from './Phase2Component';
import zIndex from '@mui/material/styles/zIndex';
import { SelectInput, MulSelectInput} from '../../../../componentsphase2/SelectBox/SelectInput';

const ALL_ACTIVE_STAGES_STRING = 'Active Stages';
const ACTIVE_STAGE_LIST = [
  'Acquired',
  'Paper Commission',
  'Submission and Review',
  'Production',
];

export const StyledSIDrawerAutoCompleteTypeOne = styled(Autocomplete)({
  '& .MuiOutlinedInput-root': {
    width: '100%',
    minHeight: '40px',
    background: '#FFFFFF',
    fontSize: '14px',
    borderRadius: 5,
    boxSizing: 'border-box',
    padding: '11px 14px 11px 7px',
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '0px 0px',
    minWidth: '0px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #DFE4E8',
  },
  '& .MuiAutocomplete-tag': {
    margin: '1px 4px 1px 0',

    ...RegularBoldFont,
    color: '#596A7C',
    maxWidth: '130px',
  },
  '& .MuiAutocomplete-endAdornment': {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    top: '0',
    right: '8px',
  },
});

export const StyledSIDrawerAutoCompleteTypeSecond = styled(Autocomplete)({
  '& .MuiOutlinedInput-root': {
    width: '100%',
    minHeight: '40px',
    background: '#FFFFFF',
    fontSize: '14px',
    borderRadius: 4,
    boxSizing: 'border-box',
    padding: '4px 0px 4px 7px',
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '0px 0px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #DFE4E8',
  },
  '& .MuiAutocomplete-tag': {
    margin: '1px 4px 1px 0',

    maxWidth: '130px',
  },
  '& .MuiAutocomplete-endAdornment': {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    top: '0',
    right: '8px',
  },
});

export default function StageSelector(props) {
  // outside parameter
  const { data, value: selectedList, setSelected, ...other } = props;
  const [open, setStageOpen] = useState(false);

  const inactiveSelected = selectedList.some(item => {
    return (
      ACTIVE_STAGE_LIST.indexOf(item) === -1 &&
      item !== ALL_ACTIVE_STAGES_STRING
    );
  });

  // Whether All active is selected, true is exactly the same as all if and only when selected
  const isAllActiveSelected = ACTIVE_STAGE_LIST.every(item => {
    return selectedList.indexOf(item) > -1 || item === ALL_ACTIVE_STAGES_STRING;
  });

  // Whether Active is partially selected is true if the selected subset is a subset of All

  // const isPartialActiveSelected = ACTIVE_STAGE_LIST.some((item) => {
  //   return selectedList.indexOf(item) > -1;
  // })

  //Active SI check logic

  const handleActiveSIChange = e => {
    if (e.target.checked) {
      // setSelected(ACTIVE_STAGE_LIST.concat(ALL_ACTIVE_STAGES_STRING));
      setSelected(ACTIVE_STAGE_LIST);
    } else {
      setSelected([]);
    }
  };

  // Autocomplete onChange logic
  const handleOnChange = (_event, value, reason, details) => {
    // 不是对【All active】操作
    if (
      JSON.stringify(value.sort()) === JSON.stringify(ACTIVE_STAGE_LIST.sort())
    ) {
      // 新操作的使得当前所选变成了All Active
      // 选择【All active】，把All的列表和All本身加入
      setSelected(ACTIVE_STAGE_LIST.concat(ALL_ACTIVE_STAGES_STRING));
    } else {
      // 其余情况直接set成新的value，且该value要把All本身过滤掉
      setSelected(
        value.filter(item => {
          return item !== ALL_ACTIVE_STAGES_STRING;
        })
      );
    }
  };

  /**
   * This Hook is used to handle the problem that the favourite filter cannot be automatically selected by All active after reading it
   */
  useLayoutEffect(() => {
    if (selectedList.indexOf(ALL_ACTIVE_STAGES_STRING) > -1) {
      return;
    }
    if (
      JSON.stringify(selectedList.sort()) ===
      JSON.stringify(ACTIVE_STAGE_LIST.sort())
    ) {
      // setSelected(selectedList.concat(ALL_ACTIVE_STAGES_STRING));
      setSelected(selectedList);
    }
  }, [selectedList]);

  return (
    <Box data-selenium-id={`SIPage_Overview-newFilterDrawer-StageSelector`}>
      {/* <StyledSIDrawerAutoCompleteTypeSecond
        disableCloseOnSelect
        disableListWrap
        forcePopupIcon={selectedList?.length ? false : true}
        // disableClearable
        disablePortal
        limitTags={-1}
        multiple
        value={selectedList}
        id='autocomplete-stage'
        data-selenium-id={`SIPage_Overview-newFilterDrawer-StageSelector-Autocomplete`}
        options={data}
        openText='Choose Stage'
        open={open}
        onOpen={() => {
          setStageOpen(true);
        }}
        onClose={() => {
          setStageOpen(false);
        }}
        onChange={(event, value, reason, details) => {
          handleOnChange(event, value, reason, details);
        }}
        ListboxProps={{
          style: { maxHeight: 'calc(6*36px)' },
        }}
        renderOption={(optionProps, option, { selected }) =>
          option === ALL_ACTIVE_STAGES_STRING ? (
            // Special: The all active option leaves it undisplayed
            <Box key='box-stage-all'></Box>
          ) : (
            // Other common options
            <li
              {...optionProps}
              style={{
                height: '36px',
                lineHeight: '16px',
                fontSize: '14px',
                backgroundColor: '#ffffff',
              }}
            >
              <Checkbox
                id={`stage-checkbox-${option}`}
                data-selenium-id={`SIPage_Overview-newFilterDrawer-StageSelector-Option${option}`}
                key={`stage-checkbox-${option}`}
                icon={
                  <CheckBoxOutlineBlankIcon
                    fontSize='small'
                    sx={{ width: '14px', height: '14px' }}
                  />
                }
                checkedIcon={
                  <CheckBoxIcon
                    fontSize='small'
                    sx={{ width: '14px', height: '14px' }}
                  />
                }
                style={{ padding: 0, marginRight: 10 }}
                checked={selected}
              />
              <span>{option}</span>
            </li>
          )
        }
        // Filter out ALL tags
        renderTags={(value, getTagProps) =>
          value
            .filter(item => {
              return item !== ALL_ACTIVE_STAGES_STRING;
            })
            .map((option, index) => {
              return (
                <Tooltip
                  title={option}
                  key={'tooltip-' + index}
                  disableInteractive
                  followCursor={true}
                  data-selenium-id={`SIPage_Overview-newFilterDrawer-StageSelector-Tooltip${index}`}
                >
                  <StyledChipTypeSecond
                    // style is equal to phase2component.styledchip
                    key={`stage-${index}`}
                    label={option}
                    deleteIcon={
                      <IconButton
                        sx={{ height: '18px', width: '18px', padding: '0px' }}
                      >
                        <SIDrawerAutoCompleteTagDelete></SIDrawerAutoCompleteTagDelete>
                      </IconButton>
                    }
                    {...getTagProps({ index })}
                  />
                </Tooltip>
              );
            })
        }
        renderInput={params => (
          <TextField
            {...params}
            placeholder={selectedList.length ? null : '  Choose Subject Group'}
            variant='outlined'
            InputProps={{
              ...params.InputProps,
            }}
            data-selenium-id={`SIPage_Overview-newFilterDrawer-StageSelector-Input`}
          ></TextField>
        )}
        clearIcon={
          <IconButton sx={{ width: '14px', height: '14px', padding: '0px' }}>
            <SIDrawerAutoCompleteClearIcon />
          </IconButton>
        }
        popupIcon={
          <IconButton
            sx={{
              width: '13.71px',
              height: '13.71px',
              padding: '0px',
              marginRight: '5.5px',
            }}
          >
            <SIDrawerAutoCompletePopupIcon />
          </IconButton>
        }
        // popupIcon={
        //   <IconButton sx={{ width: '13.71px', height: '13.71px', padding: '0px' }}
        //     data-selenium-id={`SIPage_Overview-newFilterDrawer-StageSelector-PopupIcon`}
        //   >
        //     <SIDrawerAutocompletePupIconTypeThird></SIDrawerAutocompletePupIconTypeThird>
        //   </IconButton>
        // }
        {...other}
      /> */}
      <MulSelectInput
        id='siPagenewFilterDrawerStageSelectorAutocomplete'
        data-selenium-id={'SIPage_Overview-newFilterDrawer-StageSelector-Autocomplete'}
        value={selectedList}
        placeholder={'Choose Subject Group'}
        defaultOption={ key =>(key)}
        getOptionLabel={option => option}
        options={data.filter(option => option !== ALL_ACTIVE_STAGES_STRING)}
        // loadOptionAction={(...props) => onLoad.bind(this, 'siCode')(...props)}
        onValueChange={(value) => {
          setSelected(value);
          // console.log(selectedList);
        }}
        disableNewItem={true}
        showPopupIcon={true} 
        showStartIcon={false}
      />
      <Box>
        <Checkbox
          id={`stage-checkbox-all`}
          data-selenium-id={`SIPage_Overview-newFilterDrawer-StageSelector-CheckBoxAll`}
          sx={drawer_main_stage_select_checkbox}
          icon={
            <CheckBoxOutlineBlankIcon
              fontSize='small'
              sx={{
                width: '14px',
                height: '14px',
                paddingRight: '0px',
                marginRight: '0px',
              }}
            />
          }
          checkedIcon={
            <CheckBoxIcon
              fontSize='small'
              sx={{
                width: '14px',
                height: '14px',
                paddingRight: '0px',
                marginRight: '0px',
              }}
            />
          }
          indeterminateIcon={
            <IndeterminateCheckBoxIcon
              fontSize='small'
              sx={{
                width: '14px',
                height: '14px',
                paddingRight: '0px',
                marginRight: '0px',
              }}
            />
          }
          checked={isAllActiveSelected && !inactiveSelected}
          onChange={handleActiveSIChange}
        />
        <span style={drawer_main_stage_select_checkbox_lable}>Active SI</span>
      </Box>
    </Box>
  );
}

import styles from '../../index.module.scss';
import {Box, Typography} from "@mui/material";

import ThankyouIcon from '../../assets/thankyou-icon.svg';

export function ThankYou() {
    return (
        <Box className={styles['thankyou-body']}>
            <Box className={styles['thankyou-context']}>
                <ThankyouIcon className={styles['thankyou-icon']} />
                <Typography className={styles['thank-you-text']}>
                    Thank you
                </Typography>
                <Typography className={styles['thank-you-info']}>
                    Your proposal has been received. Please check your email for confirmation.
                </Typography>
            </Box>
        </Box>
    );
}
import styled from '@mui/material/styles/styled';
import { TextField, Box } from '@mui/material';
import IntervalSubtract from '../../../assets/IntervalSubtract.svg';
import IntervalAdd from '../../../assets/IntervalAdd.svg';
import { useState } from 'react';
import { useEffect } from 'react';

const defaultVars = {
  '--color': '#DFE4E8',
  '--focus-color': '#DFE4E8',
};
const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    boxSizing: 'border-box',
    width: '95px',
    height: '33px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#262E35',
    padding: '0px',
  },
  '& .MuiOutlinedInput-input': {
    boxSizing: 'border-box',
    width: '39px',
    padding: '0px 6px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid var(--color)',
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid var(--focus-color)',
    },
    '& :hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid var(--focus-color)',
      },
    },
  },
  '& :hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid var(--color)',
    },
  },
}));

const CssStartAdornment = {
  boxSizing: 'border-box',
  padding: '8px 6px',
  width: '28px',
  height: '33px',
  borderWidth: '1px 1px 1px 0px',
  borderStyle: 'solid',
  borderColor: '#DFE4E8',
  borderRadius: '4px 0px 0px 4px',
  cursor: 'pointer',
};
const CssEndAdornment = {
  boxSizing: 'border-box',
  padding: '8px 5.5px',
  width: '28px',
  height: '33px',
  borderWidth: '1px 0px 1px 1px',
  borderStyle: 'solid',
  borderColor: '#DFE4E8',
  borderRadius: '0px 4px 4px 0px',
  cursor: 'pointer',
};
const positiveIntegerPattern = /^[1-9]\d*$/;
function CustomDigitInput(props) {
  //value 输入框中的值
  //handleChange 参数 newValue（最新的值） 当你进行输入或者点击按钮时 会调用的函数
  //max 最大值
  //min 最小值
  const { value, handleChange, max, min, setHasErr } = props;
  const [exceedErr, setExceedErr] = useState(false);
  const [notPositiveErr, setNotPositiveErr] = useState(false);
  const [helperText, setHelperText] = useState('');
  const handleSubtract = () => {
    if (positiveIntegerPattern.test(value)) {
      let num = Number(value);
      if (min >= num) {
      } else if (max + 1 >= num) {
        num -= 1;
        handleChange(num + '');
        setExceedErr(false);
        setHasErr(false);
      } else {
        num -= 1;
        handleChange(num + '');
        setHasErr(true);
      }
    }
  };
  const handleAdd = () => {
    if (positiveIntegerPattern.test(value) || Number(value) === 0) {
      let num = Number(value);
      if (num >= max) {
        num += 1;
        handleChange(num + '');
        setExceedErr(true);
        setHasErr(true);
        return;
      } else {
        setExceedErr(false);
      }
      num += 1;
      setNotPositiveErr(false);
      setHasErr(false);
      handleChange(num + '');
    }
  };
  useEffect(() => {
    if (exceedErr) {
      setHelperText(`The days should not exceed ${max}!`);
    } else if (notPositiveErr) {
      setHelperText('the time interval No. should be positive integers!');
    } else {
      setHelperText('');
    }
  }, [exceedErr, notPositiveErr]);
  return (
    <Box data-selenium-id='EmailTemplates-CommonModule-CustomDigitInput-Box'>
      <CssTextField
        value={value}
        style={defaultVars}
        data-selenium-id='EmailTemplates-CommonModule-CustomDigitInput-Box-CssTextField'
        InputProps={{
          startAdornment: (
            <Box
              sx={CssStartAdornment}
              onClick={handleSubtract}
              data-selenium-id='EmailTemplates-CommonModule-CustomDigitInput-Box-InputProps'
            >
              <IntervalSubtract />
            </Box>
          ),
          endAdornment: (
            <Box sx={CssEndAdornment} onClick={handleAdd}>
              <IntervalAdd />
            </Box>
          ),
        }}
        onBlur={e => {
          if (positiveIntegerPattern.test(e.target.value)) {
            let num = Number(e.target.value);
            if (num > max) {
              setExceedErr(true);
              setHasErr(true);
            } else {
              setExceedErr(false);
              setHasErr(false);
            }
            setNotPositiveErr(false);
          } else {
            setNotPositiveErr(true);
            setHasErr(true);
          }
        }}
        onChange={e => {
          handleChange(e.target.value);
        }}
      ></CssTextField>
      <Box
        sx={{ color: 'rgb(238, 83, 80)' }}
        data-selenium-id='EmailTemplates-CommonModule-CustomDigitInput-Box-Box'
      >
        {helperText}
      </Box>
    </Box>
  );
}
export default CustomDigitInput;

//@ts-check
import {SET_UPLOAD_REVISE_DATA_STATUS} from "@/actions/SIMT-SIP/SIP/SIPAction";
import {sipRevise} from "./SipRevise";

/**
 * 通过 token 上传 SIP Editor Review 的数据
 * @param {string} token
 * @param {import("@/pages/SIPPage/RevisePage/EditorReviewPage").EditorReviewDecisionForSubmit} data
 * @returns
 */
export const submitEditorReviewData = (token, data) => {
    /** @typedef {import("../../../pages/SIPPage/RevisePage/ProposerRevisePage").UploadReviseDataStatus} UploadReviseDataStatus */
    /**
     * @param {import("redux").Dispatch} dispatch
     */
    return async dispatch => {
        try {
            const {code} = await sipRevise(data,{
              requestType:'sip-editorReview-submitJE-token'
            },token);
            if (code === 200) {
                dispatch({
                    type: SET_UPLOAD_REVISE_DATA_STATUS,
                    /** @type {UploadReviseDataStatus} */ data: 'success',
                });
            } else {
                dispatch({
                    type: SET_UPLOAD_REVISE_DATA_STATUS,
                    /** @type {UploadReviseDataStatus} */ data: 'error',
                });
            }
        } catch (err) {
            dispatch({
                type: SET_UPLOAD_REVISE_DATA_STATUS,
                /** @type {UploadReviseDataStatus} */ data: 'error',
            });
            console.error(err);
        }
    };
};
import { axios_instance } from "@/utils/axios_instance";
import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {
  SET_ACCEPT_INFORMATION,
  SET_ACCEPT_SHOW_CODE,
  SET_ACCEPT_SUBMIT_CODE,
  SET_DECLINE_CODE
} from "@/actions/SIMT-SIP/SIP/SIPAction";
import {sipRevise} from "./SipRevise";

// 定义常量
const ACCEPT_CODES = {
  SUCCESS: 200,
  ERROR_16033: 16033,
  ERROR_16030: 16030,
  ERROR_16032: 16032,
};

const SUBMIT_CODES = {
  SUCCESS: 200,
  ERROR_16009: 16009,
  ERROR_16034: 16034,
  ERROR_16035: 16035,
  ERROR_16029: 16029,
  ERROR_16045: 16045,
  ERROR_16001: 16001,
};

const DRAFT_SAVE_CODES = {
  SUCCESS: 200,
  ERROR_16001: 16001,
  ERROR_16035: 16035,
};

const DECLINE_CODES = {
  SUCCESS: 200,
  ERROR_16032: 16032,
  ERROR_16001: 16001,
  ERROR_16035: 16035,
  ERROR_14050: 14050,
};

export const getAcceptSIPInformation = token => {
  return async dispatch => {
    try {
      const url = `/api/v1/sip-service/special-issue-proposals/revise/${token}`;
      const res = await axios_instance.get(url, {
        params: {
          category: 'acceptanceDecision',
        },
      });
      if (res.status === 200) {
        const { code, data, message } = res.data;
        dispatch({ type: SET_ACCEPT_SHOW_CODE, data: code });
        if (code === ACCEPT_CODES.SUCCESS) {
          dispatch({ type: SET_ACCEPT_INFORMATION, data });
          return;
        } else if (code === ACCEPT_CODES.ERROR_16033 || code === ACCEPT_CODES.ERROR_16030) {
          dispatch(
            setSnackbarMessageAndOpen('sipDetail.acceptErrorMessage', { msg: message }, SEVERITIES.error)
          );
        } else if (code === ACCEPT_CODES.ERROR_16032) {
          return;
        } else {
          dispatch(
            setSnackbarMessageAndOpen('sipDetail.acceptInitialFail', {}, SEVERITIES.error)
          );
        }
      } else {
        dispatch({ type: SET_ACCEPT_SHOW_CODE, data: -1 });
        dispatch(
          setSnackbarMessageAndOpen('sipDetail.acceptInitialFail', {}, SEVERITIES.error)
        );
      }
    } catch {
      dispatch({ type: SET_ACCEPT_SHOW_CODE, data: -1 });
      dispatch(
        setSnackbarMessageAndOpen('sipDetail.acceptInitialFail', {}, SEVERITIES.error)
      );
    }
  };
};

/**
 * @typedef {import("@/pages/SIPPage/RevisePage").submitAcceptSIPInformation} submitAcceptSIPInformation
 * @param {string} token 
 * @param {submitAcceptSIPInformation['geKeywords']} geKeywords
 * @param {submitAcceptSIPInformation['keywords']} keywords
 * @param {submitAcceptSIPInformation['fmSubmissionDeadline']} fmSubmissionDeadline
 * @param {submitAcceptSIPInformation['acceptanceDeadline']} acceptanceDeadline
 * @param {submitAcceptSIPInformation['epDate']} epDate
 * @param {submitAcceptSIPInformation['openCallForPapers']} openCallForPapers
 */
export const submitAcceptSIPInformation = (
  token,
  geKeywords,
  keywords,
  fmSubmissionDeadline,
  acceptanceDeadline,
  epDate,
  openCallForPapers
) => {
  /**
   * @param {import("redux").Dispatch} dispatch
   */
  return async dispatch => {
    try {
      const res=await sipRevise({
        geKeywords: geKeywords,
        keywords: keywords,
        fmSubmissionDeadline: fmSubmissionDeadline,
        acceptanceDeadline: acceptanceDeadline,
        epDate: epDate,
        openCallForPapers: openCallForPapers,
        },{
        requestType:'acceptanceDecision-submit-token'
      },token).catch((e)=>{
        console.error(e);
        dispatch({ type: SET_ACCEPT_SUBMIT_CODE, data: -1 });
        dispatch(
          setSnackbarMessageAndOpen('sipDetail.acceptSubmitFail', {}, SEVERITIES.error)
        );
      });
        if(!res)
          return;
      // if (res.status === 200) {
        const { code, message } = res;
        dispatch({ type: SET_ACCEPT_SUBMIT_CODE, data: code });
        if (code === SUBMIT_CODES.SUCCESS) {
          dispatch(
            setSnackbarMessageAndOpen('revise.submitReviseSucc', {}, SEVERITIES.success)
          );
          return;
        } else if (
          code === SUBMIT_CODES.ERROR_16009 ||
          code === SUBMIT_CODES.ERROR_16034 ||
          code === SUBMIT_CODES.ERROR_16035 ||
          code === SUBMIT_CODES.ERROR_16029 ||
          code === SUBMIT_CODES.ERROR_16045 ||
          code === SUBMIT_CODES.ERROR_16001
        ) {
          dispatch(
            setSnackbarMessageAndOpen('sipDetail.acceptErrorMessage', { msg: message }, SEVERITIES.error));
      }
    } catch {
      dispatch({ type: SET_ACCEPT_SUBMIT_CODE, data: -1 });
      dispatch(
        setSnackbarMessageAndOpen('sipDetail.acceptSubmitFail', {}, SEVERITIES.error)
      );
    }
  };
};
/**
 * @param {string} token 
 * @param {submitAcceptSIPInformation['geKeywords']} geKeywords
 * @param {submitAcceptSIPInformation['keywords']} keywords
 * @param {submitAcceptSIPInformation['fmSubmissionDeadline']} fmSubmissionDeadline
 * @param {submitAcceptSIPInformation['acceptanceDeadline']} acceptanceDeadline
 * @param {submitAcceptSIPInformation['epDate']} epDate
 * @param {submitAcceptSIPInformation['openCallForPapers']} openCallForPapers
 */
export const acceptSaveDraft = (
    token,
    geKeywords,
    keywords,
    fmSubmissionDeadline,
    acceptanceDeadline,
    epDate,
    openCallForPapers
) => {
  /**
   * @param {import("redux").Dispatch} dispatch
   */
    return async dispatch => {
        try {
            const res=await sipRevise({
                geKeywords: geKeywords,
                keywords: keywords,
                fmSubmissionDeadline: fmSubmissionDeadline,
                acceptanceDeadline: acceptanceDeadline,
                epDate: epDate,
                openCallForPapers: openCallForPapers,
              },{
              requestType:'acceptanceDecision-sip-submit-draft-token'
            },token).catch((e)=>{
              console.error(e);
              dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.acceptDraftSaveError',
                    {},
                    SEVERITIES.error
                )
            );
            });
              if(!res)
                return;
            // if (res.status === 200) {
                const { code} = res;
                if (code === 200) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.acceptDraftSaveSuccess',
                            {},
                            SEVERITIES.success
                        )
                    );

                    return;
                }
                if (code === DRAFT_SAVE_CODES.ERROR_16001 || code === DRAFT_SAVE_CODES.ERROR_16035) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.acceptDraftSaveError',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.acceptDraftSaveError',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
};

/**
 * @typedef {import("@/pages/SIPPage/RevisePage").declineSIP} declineSIP
 * @param {string} token 
 * @param {declineSIP['reasons']} reasons 
 * @param {declineSIP['comments']} comments
 * @returns 
 */
export const declineSIP = (token, reasons, comments) => {
  /**
   * @param {import("redux").Dispatch} dispatch
   */
    return async dispatch => {
        try {
            const res = await sipRevise({
                reasons, comments,
            },{
              requestType:'acceptanceDecision-decline-token'
            },token).catch(e=>{
                console.log(e);
                dispatch({type: SET_DECLINE_CODE, data: -1});
                dispatch(
                    setSnackbarMessageAndOpen(
                        'sipDetail.declineError',
                        {},
                        SEVERITIES.error
                    )
                );
              });
            // if (res.status === 200) {
                if(!res)
                  return;
                const { code,message}=res;
                dispatch({type: SET_DECLINE_CODE, data: code});
                if (code === 200 || code === DECLINE_CODES.ERROR_16032) {
                    return;
                }
                if (code === DECLINE_CODES.ERROR_16001 || code === DECLINE_CODES.ERROR_16035) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.declineTokenError',
                            {},
                            SEVERITIES.error
                        )
                    );
                } else if (code === DECLINE_CODES.ERROR_14050) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.acceptErrorMessage',
                            {msg: message},
                            SEVERITIES.error
                        )
                    );
                    return;
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'sipDetail.declineError',
                            {},
                            SEVERITIES.error
                        )
                    );
            }
        } catch {
            dispatch(
                setSnackbarMessageAndOpen(
                    'sipDetail.declineError',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
};
import {
    Range,
    CheckListItemBlock,
    FormComponentProps,
    useForm,
    VALIDATE_KEY,
    extractHtmlId, ComputedComponent
} from "@/components/Form/form.d";
import React, {ChangeEvent, ChangeEventHandler, ReactElement, useEffect} from "react";
import ChecklistItemBlock from "@/pages/SIPPage/SIPCheckListPage/compontents/ChecklistItemBlock";
import OnlyTextBlock from "@/pages/SIPPage/SIPCheckListPage/compontents/OnlyTextBlock";
import {Box, FormControl, Stack, TextField} from "@mui/material";
import StyledSlider from "@/componentsphase2/Slider/StyledSlider";
import {CHECKLIST_PROCEEDING} from "@/pages/SIPPage/SIPCheckListPage/configable/constants";
import { ChecklistStatus } from "@/components/Form/form.d";
import TextFieldErrorIcon from "@/assets/TextFieldErrorIcon.svg";

export interface CheckListItemSliderProps extends FormComponentProps{
    required?: boolean;
    label: string;
    info: string;
    rangeLabel?: string;
    rangeMin?: number;
    rangeMax?: number;
    rangeLength?: number;
    rangeDisplayLength?: number;
    displayType?: string;
    hasComments?: boolean;
    errorMessage?: string;
}


type InnerSliderProps = {
    rangeLabel: string,
    error: any,
    disabled: boolean,
    value: { min: number | undefined; max: number | undefined; comment?: string | undefined } | null,
    min: number,
    max: number,
    onChange: (lowValue: number) => void,
    onChange1: (highValue: number) => void,
    onChange2: (event: Event, value: number[], activeThumb: number) => void
};

type InnerSliderState = {
    lowValue: string,
    highValue: string
};

class InnerSlider extends React.Component<InnerSliderProps, InnerSliderState> {

    constructor(props: InnerSliderProps) {
        super(props);

        this.state = {
            lowValue: props.value === null ? '' : String(props.value?.min ?? 0),
            highValue: props.value === null ? '' : String(props.value?.max ?? 5),
        };
    }

    componentDidUpdate(prevProps: Readonly<InnerSliderProps>) {
        if (prevProps.value?.min !== this.props.value?.min) {
            this.setState(preState => ({
                ...preState,
                lowValue: this.props.value === null ? '' : String(this.props.value?.min ?? 0)
            }));
        }
        if (prevProps.value?.max !== this.props.value?.max) {
            this.setState(preState => ({
                ...preState,
                highValue: this.props.value === null ? '' : String(this.props.value?.max ?? 5)
            }));
        }
    }

    updateLowValue(event: ChangeEvent<HTMLInputElement>){
        this.setState(preState => ({
            ...preState,
            lowValue: event.target.value
        }));
    }

    updateHighValue(event: ChangeEvent<HTMLInputElement>){
        this.setState(preState => ({
            ...preState,
            highValue: event.target.value,
        }));
    }

    lowValueBlur(){
        try{
            const value = parseInt(this.state.lowValue);
            if (!isNaN(value) && value >= this.props.min && value < this.props.max)
                this.props.onChange(value);
        } catch (ignored){}

        this.setState(preState => ({
            ...preState,
            lowValue: String(this.props.value?.min ?? 0)
        }));
    }

    highValueBlur(){
        try{
            const value = parseInt(this.state.highValue);
            if (!isNaN(value) && value > this.props.min && value <= this.props.max)
                this.props.onChange1(value);
        } catch (ignored){}

        this.setState(preState => ({
            ...preState,
            highValue: String(this.props.value?.max ?? 5)
        }));
    }

    render() {
        return <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "7px",
            width: "311px",
        }}
        >
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
            }}>
                <Box sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "1%",
                    color: "#596A7C",
                }}>{this.props.rangeLabel}</Box>
                <Box sx={{
                    minWidth: "78px",
                    height: "28px",
                    textAlign: "center",
                    borderRadius: "5px",
                    // border: "1px solid #BCC5CF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                    color: (this.props.error || this.props.disabled) ? "#BCC5CF" : "#0F172A",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "1%",
                    lineHeight: "20px",
                }}>
                    {
                        (this.props.disabled) ?
                            (<>{this.props.value?.min ?? 0} - {this.props.value?.max ?? 5}</>)
                            : (<>
                                    <TextField
                                        sx={{
                                            width: "41px",
                                            height: "40px",
                                            '& input': {
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "125%",
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                textAlign: 'center'
                                            },
                                        }}
                                        size="small"
                                        placeholder="0"
                                        value={this.state.lowValue}
                                        onChange={this.updateLowValue.bind(this)}
                                        onBlur={this.lowValueBlur.bind(this)}
                                    />
                                <span> &nbsp;-&nbsp; </span>
                                    <TextField
                                        sx={{
                                            width: "41px",
                                            height: "40px",
                                            '& input': {
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "125%",
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                textAlign: 'center'
                                            },
                                        }}
                                        size="small"
                                        placeholder="5"
                                        value={this.state.highValue}
                                        onChange={this.updateHighValue.bind(this)}
                                        onBlur={this.highValueBlur.bind(this)}
                                    />
                            </>)
                    }

                </Box>
            </Box>
            <StyledSlider
                value={[this.props.value?.min ?? 0, this.props.value?.max ?? 5]}
                error={this.props.error}
                displayDisabled={this.props.value === null}
                disabled={this.props.disabled}
                min={this.props.min}
                max={this.props.max}
                onChange={this.props.onChange2}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#0F172A",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "17px",
                    height: "17px",
                }}>
                <Box>{this.props.min}</Box>
                <Box>{this.props.max}</Box>
            </Box>
            {this.props.error ? ( <Stack
                direction='row'
                spacing='2px'
                alignItems='flex-Start'
                justifyContent='flex-start'
            >
                <Stack
                    alignItems='center'
                    justifyContent='center'
                    sx={{
                        marginTop: '2px',
                    }}
                >
                    <TextFieldErrorIcon />
                </Stack>
                <Box
                    sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        color: '#EE5350',
                        letterSpacing: '-0.084px',
                    }}
                >
                    Drag slider or input number to select a range
                </Box>
            </Stack>) : null}
        </Box>;
    }
}

function CheckListItemSliderBase(props: CheckListItemSliderProps): ReactElement{
    const htmlId = extractHtmlId(props);
    const {
        rangeLabel = 'Years of research experience:',
        rangeMin = 0,
        rangeMax = 50,
        rangeLength = 5,
        rangeDisplayLength = 5,
        hasComments = false,
    } = props;

    const initValue = {
        min: rangeMin,
        max: rangeMin + rangeLength,
        comment: '',
    };

    const errorMessage = props.errorMessage || ' Please provide the required information.';

    const {
        value,
        setValue,
        form,
        setState,
        getState,
    } = useForm<Range|null>({
        name: props.name,
        initValue: null,
        initStates: {
            [VALIDATE_KEY]: 'checked',
            required: props.required,
            ['html-id']: htmlId,
        }
    });

    const status = form?.root().status;
    const locked = form?.root().locked;
    const editable = status === ChecklistStatus.IN_PROGRESS && !locked;

    // const dragable: boolean = getState('dragable') ?? false;
    const disabled: boolean = !editable;

    const proceeding = form?.root()[CHECKLIST_PROCEEDING] ?? false;

    const error = proceeding && props.required && (value===null || (
        value.min < rangeMin || 
        value.max > rangeMax ||
        value.max - value.min <= 0
    ));

    const handleChange: ((event: Event, value: number[], activeThumb: number) => void) =
        (event, values, activeThumb) => {

        if(activeThumb === 0){
            updateLowValue(values[0]);
        } else {
            const left = values[1] - rangeDisplayLength;
            updateLowValue(left);
        }
    };

    useEffect(() => {
        let stateValue;
        if (value===null || value===undefined || (value?.min ?? 0)  <  rangeMin || (value?.max ?? 0) >  rangeMax || (value?.comment?.length ?? 0) > 100000){
            stateValue = proceeding && props.required ? 'error' : 'default';
        } else {
            stateValue = 'checked';
        }
        if(getState(VALIDATE_KEY) !== stateValue){
            setState(VALIDATE_KEY, stateValue);
        }
        if (getState('required') !== props.required){
            setState('required', props.required);
        }
    }, [value, proceeding, props.required]);

    function updateLowValue(lowValue: number) {
        setValue({
            ...value ?? {},
            min: Math.min(Math.max(rangeMin, lowValue), rangeMax - 5),
            // max: lowValue < (value?.max ?? 0) ? (value?.max ?? 0) : Math.min(rangeMax, Math.max(value?.max ?? 0, lowValue + 5)),
            max: Math.min(Math.max(rangeMin, lowValue), rangeMax - 5) + 5,
        } as Range);
    }

    function updateHighValue(highValue: number) {
        setValue({
            ...value ?? {},
            max: Math.max(Math.min(highValue, rangeMax), rangeMin + 5),
            // min: (value?.min ?? 0) < highValue ? (value?.min ?? 0) : Math.max(rangeMin, Math.min( highValue - 5, value?.min ?? 0)),
            min: Math.max(Math.min(highValue, rangeMax), rangeMin + 5) - 5,
        } as Range);
    }

    return (
        <>
        <ChecklistItemBlock
        id={htmlId}
        title={props.label}
        info={props.info}
        necessary={props.required || false}
        status={getState(VALIDATE_KEY) ?? 'default'}
        needClear={!props.required || false}
        handleClear={() => {
            setValue(initValue);
        }}
        editing={editable}
        alwaysShow={true}
    >
        <InnerSlider rangeLabel={rangeLabel} error={error} disabled={disabled} value={value} min={rangeMin}
                     max={rangeMax} onChange={updateLowValue} onChange1={updateHighValue} onChange2={handleChange}/>

        {
            hasComments &&
            <OnlyTextBlock
                title={editable ? 'Comments' : 'Comments:'}
                required={false}
                value={value?.comment}
                setValue={(text: string) => {
                    setValue({
                        ...value ?? initValue,
                        comment: text,
                    } as Range);
                }}
                editing={editable}
                isError={getState(VALIDATE_KEY) == 'error' || false}
                errorMessage={errorMessage}
                radioValue={"Yes"}
            />
        }
    </ChecklistItemBlock>
        </>);
}

export const CheckListItemSlider = ComputedComponent<CheckListItemSliderProps>(CheckListItemSliderBase);
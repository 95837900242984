
import { StateButton } from '@/componentsphase2/StateButton';
import { Box } from '@mui/material';
import React from 'react';
import { SubmitAndNextDisabledBtnStyle } from '@/pages/SIPPage/SIPDetailPage/DecisionSection/DecisionMyStyled';
// import { CustomTooltip } from '../CustomTooltip';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className || '' }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#596A7C',
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderTop: '6px solid #596A7C',
    marginBottom:'-6px !important',
    '::before': {
      display: 'none',
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#596A7C',
    borderRadius: '5px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    width: '284px',
    boxSizing: 'border-box',
    padding: '8px 16px',
    marginBottom: '8.5px !important',
    textAlign:'center',
  },
}));

export default function ArchivedButton(props) {
  const {
    disabled,
    ...other
  } = props;
  return (
    <CustomTooltip
      title={disabled?'This checklist is archived, because a decision \nhas been made to relevant SIP':''}
      placement="top"
    >
      <Box>
        <StateButton
          titleName="Archived"
          sx={{
            height: '41px',
            width: '144px',
            ...SubmitAndNextDisabledBtnStyle
          }}
          disabled={disabled}
          {...other}
        />
      </Box>
    </CustomTooltip>
  );
}

/**
 * 系统管理员列表
 */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import UserRemoveIcon from '@/assets/UserRemoveIcon.svg';
import UserRemoveIconDisable from '@/assets/UserRemoveIconDisable.svg';
import UserLockIcon from '@/assets/UserLockIcon.svg';
import UserLockIconDisable from '@/assets/UserLockIconDisable.svg';
import UserEditIcon from '@/assets/UserEditIcon.svg';
import AlertDialog from '@/modules/AlertDialog';
import { getComparator, stableSort } from '@/utils/orderRowsHandler';
import { MyTableHeader, TableFooter2 } from '@/components/DataTable';
import {
  openPop,
  setSuperAdminPop,
  setSuperAdminPopData,
} from '@/actions/PopupAction';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { StyledTableCell } from '../../components/DataTable';
import { useSelector } from 'react-redux';
import {
  handleAdminPageChangeAction,
  handleChangeAdminPageSize,
  setAdminOrderBy,
  editClear,
  handleChangeAdminUpdateName,
} from '../../actions/SIMT-User/User/UserAction';
import { visuallyHidden } from '@mui/utils';
import { unstable_batchedUpdates } from 'react-dom';
import { StatusBox } from '../SystemAdmin/UserManagement/UserTable';
import ResetIcon from '@/assets/rotate-ccw - Feather icon.svg';
import DeleteButton from '@/assets/x - Feather icon.svg';
import EditIcon from '@/assets/EditIcon.svg';
import { CommonDialog } from '../../modules/AlertDialog';
import { SEVERITIES } from '../../modules/ConfirmSnackBar/CommonSnackBar';
import { setSnackbarMessageAndOpen } from '../../actions/SnackbarAction';
import { refreshData } from '../../actions/CommonAction';

import {
  openConfirm,
  setEditType,
  setPosition,
  setTextParam,
} from '@/actions/SIMT-User/Alert/AlertAction';
import {
  editAdmin,
  inactiveAdmin,
  loadAdminData,
  unlockAdmin,
  updateAdmin
} from "@/actions/SIMT-User/User/SuperAdmin";

function FullTable(props) {
  const Intl = useIntl();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('Are you sure you want to proceed?');
  const [loading, setLoading] = useState(true);
  const [confirmFun, setConfirmFun] = useState();
  const [tmpName, setTmpName] = useState('');
  const [editType, setEditType2] = useState('');
  const dispatch = useDispatch();

  const selector = state => {
    return {
      orderByType: state.User.adminOrderByType,
      adminData: state.User.adminData,
      page: state.User.adminPage,
      pageSize: state.User.adminPageSize,
      alertOpen: state.Alert.open,
      refresh: state.Common.refresh,
      adminResult: state.User.editAdminResult,
      updateAdminResult: state.User.updateAdmin,
      adminUpdateName: state.User.adminUpdateName,
    };
  };

  const {
    orderByType,
    adminData,
    page,
    pageSize,
    alertOpen,
    refresh,
    adminResult,
    updateAdminResult,
    adminUpdateName,
  } = useSelector(selector);

  useEffect(() => {
    // console.log(page, pageSize, orderByType, refresh)
    setLoading(true);
    dispatch(
      loadAdminData(page, pageSize, orderByType.order, orderByType.orderBy)
    );
    setLoading(false);
  }, [dispatch, page, pageSize, orderByType, refresh]);

  function createRow(
    id,
    label,
    isOrder = true,
    minWidth = '110px',
    maxWidth = '220px',
    show = true
  ) {
    return { id, label, isOrder, minWidth, maxWidth, show };
  }

  const headItems = [
    createRow(
      'fullName',
      Intl.messages['superAdminManmage.fullName'],
      true,
      '152px',
      '231px',
      true
    ),
    createRow(
      'email',
      Intl.messages['superAdminManmage.primaryEmail'],
      true,
      '152px',
      '283px',
      true
    ),
    createRow(
      'group',
      Intl.messages['superAdminManmage.groupTitle'],
      true,
      '152px',
      '182px',
      true
    ),
    createRow(
      'role',
      Intl.messages['superAdminManmage.role'],
      true,
      '152px',
      '166px',
      true
    ),
    createRow(
      'accountStatus',
      Intl.messages['superAdminManmage.accountStatus'],
      true,
      '152px',
      '298px',
      true
    ),
    createRow(
      'Actions',
      Intl.messages['superAdminManmage.actions'],
      false,
      '152px',
      '214px',
      true
    ),
  ];

  //用来改变表格的排序顺序
  const handleChangeOrder = orderBy => {
    let newOrder;
    if (orderByType.orderBy === orderBy) {
      // 如果当前的排序字段没有变化，则切换正反序
      newOrder = orderByType.order === 'desc' ? 'asc' : 'desc';
    } else {
      // 如果当前排序字段发生变化，则直接按照新的字段升序
      newOrder = 'asc';
    }
    // console.log("AAIAINA",newOrder, orderBy)
    dispatch(setAdminOrderBy(newOrder, orderBy));
  };

  //改变每页的数量
  const handlePerPageChange = e => {
    unstable_batchedUpdates(() => {
      // 修改页面大小后，页码置为1
      dispatch(handleAdminPageChangeAction(1));
      dispatch(handleChangeAdminPageSize(Number(e.target.value)));
    });
  };

  //改变页码
  const handlePageChange = (_event, newPage) => {
    dispatch(handleAdminPageChangeAction(newPage));
  };

  /**
   * 处理resend点击事件
   * @param {*} userId
   * @param {*} fullName
   * @returns
   */
  const handleResend = (userId, fullName) => {
    return () => {
      setTitle(`Resend invitation link to ${userId}`);
      setContent('Are you sure you want to proceed ?');
      setTmpName(userId);
      setConfirmFun(() => {
        setEditType2('resendInvitationToAdmin');
        return async () => {
          await dispatch(editAdmin(userId, 'resendInvitationToAdmin'));
          handleClose();
          // dispatch(
          //   setSnackbarMessageAndOpen(
          //     "superAdminManmage.resendMsg",
          //     { email: email },
          //     SEVERITIES.plain,
          //     { vertical: "bottom", horizontal: "center" }
          //   )
          // );
          dispatch(handleChangeAdminUpdateName(fullName));
          dispatch(setEditType('resendInvitationToAdmin'));
          // dispatch(refreshData());
          // dispatch(setAdminOrderBy("desc", "updateTime"));
        };
      });
      setOpen(true);
    };
  };

  /**
   * 处理reset点击事件
   * @param {*} userId
   * @param {*} fullName
   * @returns
   */
  const handleReset = (userId, fullName) => {
    return () => {
      setTitle(`Reset system admin ${fullName}’s password`);
      setContent('Are you sure you want to proceed?');
      setTmpName(fullName);
      setConfirmFun(() => {
        setEditType2('resetAdminPass');
        return async () => {
          await dispatch(editAdmin(userId, 'resetAdminPass'));
          handleClose();
          // dispatch(
          //   setSnackbarMessageAndOpen(
          //     "superAdminManmage.resetMsg",
          //     { fullName: fullName },
          //     SEVERITIES.plain,
          //     { vertical: "bottom", horizontal: "center" }
          //   )
          // );
          dispatch(handleChangeAdminUpdateName(fullName));
          dispatch(setEditType('resetAdminPass'));
          // dispatch(refreshData());
          // dispatch(setAdminOrderBy("desc", "updateTime"));
        };
      });
      setOpen(true);
    };
  };

  /**
   * 处理delete点击事件
   * @param {*} userId
   * @param {*} fullName
   * @returns
   */
  const handleDelete = (userId, fullName) => {
    return () => {
      setTitle(`Remove system admin ${fullName}`);
      setTmpName(fullName);
      setContent('Are you sure you want to permanently remove a system admin?');
      setConfirmFun(() => {
        setEditType2('deleteAdmin');
        return async () => {
          await dispatch(editAdmin(userId, 'deleteAdmin'));
          handleClose();
          // dispatch(
          //   setSnackbarMessageAndOpen(
          //     "superAdminManmage.deleteMsg",
          //     { fullName: fullName },
          //     SEVERITIES.plain,
          //     { vertical: "bottom", horizontal: "center" }
          //   )
          // );
          dispatch(handleChangeAdminUpdateName(fullName));
          dispatch(setEditType('deleteAdmin'));
          // dispatch(refreshData());
          // dispatch(setAdminOrderBy("desc", "updateTime"));
        };
      });
      setOpen(true);
    };
  };

  /**
   * 处理unlock点击事件
   * @param {*} userId
   * @param {*} email
   * @param {*} fullName
   * @returns
   */
  const handleUnlock = (userId, email, Group, Role, lock, status, fullName) => {
    return () => {
      setTitle(`Unlock system admin ${fullName}`);
      setContent('Are you sure you want to proceed?');
      setTmpName(fullName);
      setConfirmFun(() => {
        return async () => {
          setEditType2('unlockAdmin');
          // console.log(email,
          //     Group,
          //     Role,
          //     lock,
          //     status)
          await dispatch(
            updateAdmin(
              userId,
              email,
              Group,
              Role,
              lock !== 'locked',
              status === 'Active'
            )
          );
          handleClose();
          // dispatch(
          //   setSnackbarMessageAndOpen(
          //     "superAdminManmage.unlockMsg",
          //     { fullName: fullName },
          //     SEVERITIES.plain,
          //     { vertical: "bottom", horizontal: "center" }
          //   )
          // );
          dispatch(handleChangeAdminUpdateName(fullName));
          dispatch(setEditType('unlockAdmin'));
          // dispatch(refreshData());
          // dispatch(setAdminOrderBy("desc", "updateTime"));
        };
      });
      setOpen(true);
    };
  };

  /**
   * 处理inactive点击事件
   * @param {*} userId
   * @param {*} email
   * @param {*} fullName
   * @returns
   */
  const handleInactive = (userId, email, Group, Role, lock, status, fullName) => {
    return () => {
      setTitle(`Inactive system admin ${fullName}`);
      setContent('Are you sure you want to proceed?');
      setTmpName(fullName);
      setConfirmFun(() => {
        setEditType2('InactiveAdmin');
        dispatch(setEditType('InactiveAdmin'));
        return async () => {
          await dispatch(
            updateAdmin(
              userId,
              email,
              Group,
              Role,
              lock !== 'locked',
              status !== 'Active'
            )
          );
          handleClose();
          // dispatch(
          //   setSnackbarMessageAndOpen(
          //     "superAdminManmage.inactiveMsg",
          //     { fullName: fullName },
          //     SEVERITIES.plain,
          //     { vertical: "bottom", horizontal: "center" }
          //   )
          // );
          dispatch(handleChangeAdminUpdateName(fullName));
        };
      });
      setOpen(true);
    };
  };

  useEffect(() => {
    // console.log(adminResult,tmpName);
    if (adminResult) {
      dispatch(setAdminOrderBy('desc', 'updateTime'));
      dispatch(refreshData());
      if (adminResult === 'SIAssociated') {
        dispatch(setPosition('bottom', 'center'));
        dispatch(setEditType(editType));
        dispatch(setTextParam('error,' + tmpName));
        dispatch(openConfirm());
        handleClose();
        dispatch(editClear());
      } else {
        dispatch(setPosition('bottom', 'center'));
        tmpName === ''
          ? dispatch(setTextParam(adminUpdateName))
          : dispatch(setTextParam(tmpName));
        dispatch(openConfirm());
        handleClose();
        dispatch(editClear());
      }
    }
  }, [dispatch, adminResult]);

  useEffect(() => {
    // console.log(updateAdminResult,tmpName)
    if (updateAdminResult) {
      dispatch(setAdminOrderBy('desc', 'updateTime'));
      dispatch(refreshData());
      if (updateAdminResult === 'SIAssociated') {
        dispatch(setPosition('bottom', 'center'));
        dispatch(setEditType(editType));
        dispatch(setTextParam('error,' + tmpName));
        dispatch(openConfirm());
        handleClose();
        dispatch(editClear());
      } else if (updateAdminResult === 'HEInactive') {
        dispatch(setPosition('bottom', 'center'));
        dispatch(setEditType('HEUserInactive'));
        dispatch(openConfirm());
        handleClose();
        dispatch(editClear());
      } else {
        dispatch(setPosition('bottom', 'center'));
        tmpName === ''
          ? dispatch(setTextParam(adminUpdateName))
          : dispatch(setTextParam(tmpName));
        dispatch(openConfirm());
        handleClose();
        dispatch(editClear());
        setTmpName('');
      }
    }
  }, [dispatch, updateAdminResult]);

  /**
   * 处理对话框的关闭
   */
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateOpen = value => {
    return () => {
      dispatch(
        setSuperAdminPop({
          isCreate: false,
        })
      );
      dispatch(
        setSuperAdminPopData({
          userId: value.userId,
          email: value.email,
          firstName: value.firstName,
          lastName: value.lastName,
          group: value.group,
          status: value.accountStatus,
          lock: value.lock,
        })
      );
      dispatch(openPop());
    };
  };
  // console.log('adminData', adminData);
  return (
    <Box data-selenium-id='SuperAdminManage_FullTable-Box' mt={2}>
      <Box id='TableBox' data-selenium-id='SuperAdminManage_FullTable-TableBox'>
        <TableContainer
          id='admin-manage-table-container'
          data-selenium-id='SuperAdminManage_FullTable-TableContainer'
          className='table-screen-size'
        >
          <Table
            stickyHeader
            id='admin-manage-table'
            data-selenium-id='SuperAdminManage_FullTable-Table'
            aria-label='customized table'
            size='small'
          >
            <TableHead
              id='admin-manage-table-head'
              data-selenium-id='SuperAdminManage_FullTable-TableHead'
            >
              <TableRow
                id='admin-manage-table-head-row'
                data-selenium-id='SuperAdminManage_FullTable-TableHead-Row'
              >
                {headItems.map((item, index) => (
                  <StyledTableCell
                    data-selenium-id={`SuperAdminManage_FullTable-TableHead-Row-StyledTableCell-${index}`}
                    key={item.id}
                    align={'left'}
                    sx={{
                      maxWidth: item.maxWidth,
                      minWidth: item.minWidth,
                      display: item.show ? 'table-cell' : 'none',
                      textTransform: 'none',
                    }}
                    sortDirection={
                      orderByType.orderBy === item.id
                        ? orderByType.order
                        : false
                    }
                  >
                    {item.isOrder ? (
                      <TableSortLabel
                        id={`adminManageTable${item.id}SortLabel`}
                        data-selenium-id={`SuperAdminManage_FullTable-AdminManageTable${item.id}SortLabel`}
                        active={item.isOrder && orderByType.orderBy === item.id}
                        direction={
                          orderByType.orderBy === item.id
                            ? orderByType.order
                            : 'asc'
                        }
                        onClick={() => {
                          if (item.isOrder) {
                            handleChangeOrder(item.id);
                          }
                        }}
                      >
                        {item.label}
                        {orderByType.orderBy === item.id ? (
                          <Box
                            data-selenium-id={`SuperAdminManage_FullTable-TableHead-Row-StyledTableCell-Box-${index}`}
                            component='span'
                            sx={visuallyHidden}
                          >
                            {orderByType.order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      item.label
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              id='admin-manage-table-body'
              data-selenium-id={`SuperAdminManage_FullTable-TableBody`}
            >
              {!loading &&
                adminData.result.map((row, index) => (
                  <TableRow
                    data-selenium-id={`SuperAdminManage_FullTable-TableRow-${index}`}
                    key={row.email}
                  >
                    <StyledTableCell
                      data-selenium-id={`SuperAdminManage_FullTable-TableRow-StyledTableCell1-${index}`}
                      component='th'
                      scope='row'
                      sx={{
                        fontWeight: 600,
                        fontSize: '16px',
                      }}
                    >
                      {row.fullName}
                    </StyledTableCell>
                    <StyledTableCell
                      data-selenium-id={`SuperAdminManage_FullTable-TableRow-StyledTableCell2-${index}`}
                      component='th'
                      scope='row'
                      sx={{
                        fontSize: '16px',
                      }}
                    >
                      {row.email}
                    </StyledTableCell>
                    <StyledTableCell
                      data-selenium-id={`SuperAdminManage_FullTable-TableRow-StyledTableCell3-${index}`}
                      component='th'
                      scope='row'
                      sx={{
                        fontSize: '16px',
                      }}
                    >
                      {row.group}
                    </StyledTableCell>
                    <StyledTableCell
                      data-selenium-id={`SuperAdminManage_FullTable-TableRow-StyledTableCell4-${index}`}
                      component='th'
                      scope='row'
                      sx={{
                        fontSize: '16px',
                      }}
                    >
                      {'System Admin'}
                    </StyledTableCell>
                    <StyledTableCell
                      component='th'
                      scope='row'
                      data-selenium-id={`SuperAdminManage_FullTable-TableRow-StyledTableCell5-${index}`}
                    >
                      <StatusBox
                        data-selenium-id={`SuperAdminManage_FullTable-TableRow-StyledTableCell5-StatusBox-${index}`}
                        active={row.accountStatus}
                        locked={row.lock}
                        hrefStr={'#!' + row.email + row.accountStatus}
                        handleActive={handleResend(row.userId, row.fullName)}
                        handleUnlock={handleUnlock(
                          row.userId,
                          row.email,
                          row.group,
                          row.role,
                          row.lock,
                          row.accountStatus,
                          row.fullName
                        )}
                        handleInactive={handleInactive(
                          row.userId,
                          row.email,
                          row.group,
                          row.role,
                          row.lock,
                          row.accountStatus,
                          row.fullName
                        )}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      data-selenium-id={`SuperAdminManage_FullTable-TableRow-StyledTableCell6-${index}`}
                      component='th'
                      scope='row'
                    >
                      <Stack
                        data-selenium-id={`SuperAdminManage_FullTable-TableRow-StyledTableCell6-Stack-${index}`}
                        direction='row'
                        alignItems='center'
                        spacing='12px'
                        sx={{
                          '& .FullTable__ActionButton': {
                            height: '28px',
                            width: '28px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#F6F7F8',
                            border: '1px solid #DFE4E8',
                            '&--hidden': {
                              backgroundColor: 'transparent',
                              border: 'none',
                            },
                            '& .MuiButtonBase-root.MuiIconButton-root': {
                              padding: 0,
                              width: '24px',
                              height: '24px',
                            },
                          },
                        }}
                      >
                        <Box
                          data-selenium-id={`SuperAdminManage_FullTable-TableRow-StyledTableCell6-Stack-ResetPasswordBox-${index}`}
                          className={'FullTable__ActionButton'}
                        >
                          {/* Reset user password */}
                          {
                            <Tooltip
                              data-selenium-id={`SuperAdminManage_FullTable-TableRow-StyledTableCell6-Stack-ResetPasswordBox-Tooltip-${index}`}
                              title='Reset Password'
                            >
                              <span>
                                <IconButton
                                  id={`reset${row.email}PwdButton`}
                                  data-selenium-id={`SuperAdminManage_FullTable-Reset${row.email}PwdButton`}
                                  onClick={handleReset(row.userId, row.fullName)}
                                  disabled={row.accountStatus !== 'Active'}
                                >
                                  {row.accountStatus === 'Active' ? (
                                    <UserLockIcon />
                                  ) : (
                                    <UserLockIconDisable />
                                  )}
                                </IconButton>
                              </span>
                            </Tooltip>
                          }
                        </Box>
                        <Box
                          data-selenium-id={`SuperAdminManage_FullTable-TableRow-StyledTableCell6-Stack-DeleteBox-${index}`}
                          className={'FullTable__ActionButton'}
                        >
                          {/* Remove user */}
                          {
                            <Tooltip
                              data-selenium-id={`SuperAdminManage_FullTable-TableRow-StyledTableCell6-Stack-DeleteBox-Tooltip-${index}`}
                              title='Delete'
                            >
                              <span>
                                <IconButton
                                  id={`remove${row.email}UserButton`}
                                  data-selenium-id={`SuperAdminManage_FullTable-Remove${row.email}UserButton`}
                                  onClick={handleDelete(
                                    row.userId,
                                    row.fullName
                                  )}
                                  disabled={
                                    !(row.hasSi < 1 && row.hasJournal < 1)
                                  }
                                >
                                  {row.hasSi < 1 && row.hasJournal < 1 ? (
                                    <UserRemoveIcon />
                                  ) : (
                                    <UserRemoveIconDisable />
                                  )}
                                </IconButton>
                              </span>
                            </Tooltip>
                          }
                        </Box>
                        <Box
                          data-selenium-id={`SuperAdminManage_FullTable-TableRow-StyledTableCell6-Stack-EditBox-${index}`}
                          className={'FullTable__ActionButton'}
                        >
                          {/* Edit user information */}
                          <Tooltip
                            data-selenium-id={`SuperAdminManage_FullTable-TableRow-StyledTableCell6-Stack-EditBox-Tooltip-${index}`}
                            title='Edit'
                          >
                            <IconButton
                              id={`edit${row.email}UserButton`}
                              data-selenium-id={`SuperAdminManage_FullTable-Edit${row.email}UserButton`}
                              onClick={handleUpdateOpen(row)}
                            >
                              <UserEditIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Stack>
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {loading && (
            <Box
              id='admin-manage-table-loading-box'
              data-selenium-id='SuperAdminManage_FullTable-Table-LoadingBox'
              sx={{
                width: 'auto',
                marginTop: '150px',
                marginLeft: '50%',
              }}
            >
              <CircularProgress data-selenium-id='SuperAdminManage_FullTable-Table-Loading' />
            </Box>
          )}
        </TableContainer>
        <Box
          id='admin-manage-table-footer-box'
          data-selenium-id='SuperAdminManage_FullTable-Table-FooterBox'
        >
          <Divider data-selenium-id='SuperAdminManage_FullTable-Table-FooterBox-Divider' />
          <TableFooter2
            id='admin-manage-table-footer-box'
            data-selenium-id='SuperAdminManage_FullTable-Table-FooterBox-Footer'
            Page={page}
            perPageNumber={pageSize}
            perPageList={[5, 10, 20]}
            count={adminData.count}
            handlePerPageChange={handlePerPageChange}
            handlePageChange={handlePageChange}
          />
        </Box>
      </Box>
      <CommonDialog
        data-selenium-id='SuperAdminManage_FullTable-Table-CommonDialog'
        alertOpen={open}
        title={title}
        content={content}
        handleClose={handleClose}
        handleConfirm={confirmFun}
      />
    </Box>
  );
}

export default FullTable;

import { Box, Stack, Typography } from '@mui/material';
import Texterror from '@/assets/Texterror.svg';

/**
 *
 * @param {import("./CustomEmailBlock").CustomEmailBlockProps} props
 * @returns
 */
function CustomEmailBlock(props) {
  const { title, margin, sx } = props;
  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{
        margin: margin,
        ...sx,
      }}
      spacing='12px'
      data-selenium-id='Email_Send-CommonComponents-CustomEmailBlock-Stack'
    >
      <Typography
        sx={{
          width: '52px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '32.5px',
          color: '#828282',
          wordWrap: 'break-word',
        }}
        data-selenium-id='Email_Send-CommonComponents-CustomEmailBlock-Typography'
      >
        {title}
      </Typography>
      <Box
        sx={{
          width: '0',
          flexGrow: 1,
        }}
        data-selenium-id='Email_Send-CommonComponents-CustomEmailBlock-Box'
      >
        {props.children}
      </Box>
    </Stack>
  );
}

/**
 *
 * @param {import("./CustomEmailBlock").CustomInputErrorBlockProps} props
 * @returns
 */
const CustomInputErrorBlock = props => {
  const { error, text } = props;

  return error ? (
    <Stack
      direction='row'
      alignItems='center'
      style={{ color: '#DE350B', fontSize: '14px', marginTop: '4px' }}
    >
      <Texterror />
      {text}
    </Stack>
  ) : (
    <></>
  );
};

export { CustomEmailBlock, CustomInputErrorBlock };

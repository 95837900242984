import { Box, Stack, ThemeProvider, Tooltip, createTheme } from '@mui/material';
import Item from '../../Framework/Block/Item';
import Section from '../../Framework/Block/Section';
import ThemeButton from './CommonComponents/ThemeButton';
import OtherCategoriesSelect from './CommonComponents/OtherCategoriesSelect';
import {
  fieldList,
  getFieldByLabel,
  getLabelByField,
  getThemeByField,
  getThemeColor,
} from './utils';
import { useDispatch, useSelector } from 'react-redux';
import DefaultSelectBox from './CommonComponents/DefaultSelectBox';
import React from 'react';
import { loadData, setSelect } from '@/actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import SelectBox from './CommonComponents/SelectBox';
import { blankTrim } from '@/utils/commonUtils';
import Separator from '../../SVGComponents/Separator.svg';
import { StyledChip } from '../../StyledComponents/styledChip';
import ChipClearIcon from '../../SVGComponents/ChipClearIcon.svg';
import { measureWidth } from '@/utils/StringWidthMeasure';
import { GETooltipIf } from '@/componentsphase2/SelectBox/SelectInput';
import {PERMISSIONS} from "@/constant/permission";

export default function FirstSection(props) {
  const { resetSignal } = props;
  const dispatch = useDispatch();

  const permssion = localStorage
    .getItem('userPermissions')
    .includes(PERMISSIONS.SI_READ_LIMITED);

  const [currentTab, setCurrentTab] = React.useState('');
  const [currentData, setCurrentData] = React.useState([]);
  const [dataDict, setDataDict] = React.useState({});

  const {
    leadGeFullName,
    coGeFullName,
    handlingCe,
    journal,
    pdPublisher,
    subjectGroup,
    siCode,
    siFilterSelected,
    siTitle,
  } = useSelector(state => {
    return {
      // filter display
      leadGeFullName: state.Filter.leadGeFullName,
      coGeFullName: state.Filter.coGeFullName,
      handlingCe: state.Filter.handlingCe,
      journal: state.Filter.journal,
      pdPublisher: state.Filter.pdPublisher,
      subjectGroup: state.Filter.subjectGroup,
      siCode: state.Filter.siCode,
      // filter selected
      siFilterSelected: state.Filter.siFilterSelected,
      siTitle: state.Filter.siTitle,
    };
  });

  function concat(label, arr1, arr2) {
    let noRepeatArr = [];
    for (let i = 0; i < arr2.length; i++) {
      let isRepeat = false;
      for (let j = 0; j < arr1.length; j++) {
        isRepeat =
          label === 'Handling CE'
            ? arr1[j].userId === arr2[i].userId
            : arr1[j] === arr2[i];
        if (isRepeat) {
          break;
        }
      }
      if (!isRepeat) {
        noRepeatArr.push(arr2[i]);
      }
    }
    return arr1.concat(noRepeatArr);
  }

  const handleSelectedChange = (which, data) => {
    dispatch(setSelect(which, data));
  };

  function handleSwitchTab(data) {
    if (currentTab === '') {
      setCurrentTab(data?.label ?? '');
      return 0;
    }
    //TO DO
    if (data?.label === 'SI Title') {
      handleSiTitleAdd(data);
    } else {
      let currentField = getFieldByLabel(currentTab);
      let selectedData = siFilterSelected[currentField + 'Selected'].concat();
      let newCurrentData = concat(currentTab, selectedData, currentData);
      handleSelectedChange(currentField, newCurrentData);
      setCurrentData([]);
      setCurrentTab(data?.label ?? '');
    }
  }
  function handleSiTitleAdd(data) {
    if(data?.data && data.data[0] !== ''){
      let selectedData = siFilterSelected['siTitleSelected'].concat();
      let newCurrentData = concat('SI Title', selectedData, data.data);
      handleSelectedChange('siTitle', newCurrentData);
    }
    setCurrentData([]);
    setCurrentTab('SI Title');
  }

  const onLoad = async (which, searchContent, page = 1, pageSize = 5000) => {
    // This list is constant, no more load
    if (which === 'stage') {
      return;
    }
    if (!permssion) {
      await dispatch(loadData(which, blankTrim(searchContent), page, pageSize));
    }
  };


  React.useEffect(() => {
    // console.log(currentTab);
    if (currentTab !== '') {
      let field = getFieldByLabel(currentTab);
      onLoad(field, '', 1, 20);
    }
  }, [currentTab]);

  React.useEffect(() => {
    setCurrentTab('');
    setCurrentData([]);
    setDataDict({});
  }, [resetSignal]);

  const getDataByLabel = label => {
    switch (label) {
      case 'Handling CE':
        return handlingCe;
      case 'Lead GE':
        return leadGeFullName;
      case 'Journal Name/Code':
        return journal;
      case 'SI Title':
        return siTitle;
      case 'SI Code':
        return siCode;
      case 'Subject Group':
        return subjectGroup;
      case 'PD Publisher':
        return pdPublisher;
      case 'CO-GE':
        return coGeFullName;
      default:
        return [];
    }
  };

  const [topThreeSum, setTopThreeSum] = React.useState(0);

  function maxOne(number) {
    return number > 0 ? 1 : 0;
  }

  function getTopThreeSum(dataDict) {
    return (
      maxOne(dataDict['Handling CE'].number) +
      maxOne(dataDict['Lead GE'].number) +
      maxOne(dataDict['Journal Name/Code'].number)
    );
  }

  React.useEffect(() => {
    let newDict = {};
    fieldList.forEach(field => {
      let label = getLabelByField(field);
      newDict[label] = {
        label: label,
        field: field,
        number: siFilterSelected[field + 'Selected']?.length ?? 0,
      };
    });
    setDataDict(newDict);
    setTopThreeSum(getTopThreeSum(newDict));
  }, [siFilterSelected]);

  return (
    <Section id='first_section'>
      <Item
        id='refine_search_results_by_categories'
        title='Refine Search Results by Categories'
        stackProps={{ spacing: 1 }}
      >
        <Stack spacing={2} direction='column'>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              flexWrap: 'wrap',
            }}
          >
            {fieldList
              .concat()
              .slice(0, 3)
              .map(field => {
                let label = getLabelByField(field);
                return (
                  <ThemeButton
                    id={field}
                    key={field}
                    label={label}
                    number={dataDict[label]?.number ?? 0}
                    theme={getThemeByField(field)}
                    currentTab={currentTab}
                    onClick={() => {
                      handleSwitchTab(dataDict[label]);
                    }}
                  />
                );
              })}
            <OtherCategoriesSelect
              currentTab={currentTab}
              fieldList={fieldList}
              dataDict={dataDict}
              handleSwitchTab={handleSwitchTab}
              topThreeSum={topThreeSum}
            />
          </Box>
          <Stack spacing={0.75} direction='column'>
            {currentTab === '' ? (
              <DefaultSelectBox />
            ) : (
              <SelectBox
                data={getDataByLabel(currentTab)}
                labelName={currentTab}
                value={currentData}
                setSelected={setCurrentData}
                loadMore={onLoad.bind(this, getFieldByLabel(currentTab))}
                handleSwitchTab={handleSwitchTab}
              />
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '4px',
              }}
            >
              {fieldList.map((field, idx) => { 
                                let temSIFilterSelected =
                  siFilterSelected[field + 'Selected'].concat();
                if (temSIFilterSelected.length === 0) {
                  return null;
                }
                let hasFollow = false;
                for (let i = idx + 1; i < fieldList.length; i++) {
                  let label = getLabelByField(fieldList[i]);
                  if (dataDict[label]?.number > 0) {
                    hasFollow = true;
                    break;
                  }
                }
                let cssTheme = createTheme({
                  themeColor: getThemeColor(getThemeByField(field)),
                });
                temSIFilterSelected.push(null);
                let label = getLabelByField(field);
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: '4px',
                    }}
                    key={`FirstSection-${label}`}
                  >
                    {temSIFilterSelected.map((item, idx) => {
                      if (idx === temSIFilterSelected.length - 1) {
                        return hasFollow ? (
                          <Separator key={`FirstSection-${label}-Separator`} />
                        ) : null;
                      }
                      let optionName =
                        label === 'Handling CE'
                          ? `${item.username} (${item.email})`
                          : item;
                      return (
                        <ThemeProvider
                          theme={cssTheme}
                          key={`FirstSection-${label}-${optionName}`}
                        >
                          <GETooltipIf
                            title={optionName}
                            disabled={
                              measureWidth(optionName, '12px Open Sans') < 347
                            }
                          >
                            <StyledChip
                              label={optionName}
                              deleteIcon={
                                <Box sx={{ display: 'flex' }}>
                                  <ChipClearIcon />
                                </Box>
                              }
                              onDelete={() => {
                                let selectedData =
                                  siFilterSelected[field + 'Selected'].concat();
                                selectedData.splice(idx, 1);
                                handleSelectedChange(field, selectedData);
                              }}
                            />
                          </GETooltipIf>
                        </ThemeProvider>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          </Stack>
        </Stack>
      </Item>
    </Section>
  );
}

import {axios_instance} from "@/utils/axios_instance";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {
    FIRST_LOAD_FAVOURITE,
    handleFavData,
    LOAD_FAVOURITE,
    SAVE_FAVOURITE,
    SET_ADAPT_SIFILTERSELECTED,
    SET_FAV_SIFILTERSELECTED,
    SET_FILTER
} from "@/actions/SIMT-SI/SpecialIssueFilter/FilterAction";
import { getUserId } from "@/utils/getUserId";

export function saveFilters(userId = getUserId(),filters) {
    return async dispatch => {
        try {
            let res = await axios_instance.post(
                `/api/v1/user-service/users/${getUserId()}/filters/${'SI'}`,
                {
                    ...filters, // 该接口不需要加key
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: SAVE_FAVOURITE, data: true});
                // willy3 sprint1 : save filter snackbar message function is implemented separately
                // dispatch(
                //   setSnackbarMessageAndOpen(
                //     'siPage.filterSaveSuccess',
                //     {},
                //     SEVERITIES.success
                //   )
                // );
                return true;
            } else {
                dispatch({type: SAVE_FAVOURITE, data: false});
                dispatch(
                    setSnackbarMessageAndOpen(
                        'siPage.filterSaveFail',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({type: SAVE_FAVOURITE, data: false});
            dispatch(
                setSnackbarMessageAndOpen('siPage.filterSaveFail', {}, SEVERITIES.error)
            );
        }
        return false;
    };
}

export function loadFavourite(
    setByTheWay = false,
    requiresSnackbarOpen = false,
    isCNmarketing = false,
    userId = getUserId(),
) {
    return async dispatch => {
        try {
            let res = await axios_instance.get(`/api/v1/user-service/users/${getUserId()}/filters/${'SI'}`, {
                params: {},
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                let filterData = res.data.data;
                if (isCNmarketing) {
                    filterData = {
                        ...filterData,
                        group: [],
                        groupNames: [],
                        apFromDate: [],
                        apToDate: [],
                        createFromDate: [],
                        createToDate: [],
                        submissionDeadlineFrom: [],
                        submissionDeadlineTo: [],
                        sisWithAlerts: [],
                        state: [],
                        closedforSubmission: [],
                    };
                }
                const favData = handleFavData(filterData);
                dispatch({type: LOAD_FAVOURITE, data: favData});
                dispatch({type: SET_FAV_SIFILTERSELECTED, data: favData});
                dispatch({type: SET_ADAPT_SIFILTERSELECTED, data: favData});
                if (setByTheWay) {
                    dispatch({type: SET_FILTER, data: filterData}); // Note: not favData
                }
                if (requiresSnackbarOpen) {
                    //willy3 sprint1 : reset to filter snackbar message function is implemented separately
                    // dispatch(
                    //   setSnackbarMessageAndOpen(
                    //     // edited by ljq to upgrate new SI Filter tap
                    //     // 'siPage.filterLoadSuccess',
                    //     // {},
                    //     // SEVERITIES.success
                    //     'siPage.newFilterLoadSuccess',
                    //     {},
                    //     SEVERITIES.info
                    //   )
                    // );
                }
                return true;
            } else {
                dispatch({type: LOAD_FAVOURITE, data: null});
                dispatch({type: SET_FAV_SIFILTERSELECTED, data: null});
                dispatch({type: SET_ADAPT_SIFILTERSELECTED, data: null});
                if (requiresSnackbarOpen) {
                    // dispatch(
                    //   setSnackbarMessageAndOpen('siPage.noFilter', {}, SEVERITIES.warning)
                    // );
                    return false;
                }
                if (setByTheWay) {
                    // load failed, set the init filter
                    dispatch({type: SET_FILTER, data: 'default'});
                }
            }
        } catch (err) {
            dispatch({type: LOAD_FAVOURITE, data: null});
            if (requiresSnackbarOpen) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'siPage.filterLoadFail',
                        {},
                        SEVERITIES.error
                    )
                );
            }
            if (setByTheWay) {
                // load failed, set the init filter
                dispatch({type: SET_FILTER, data: 'default'});
            }
        }
        return false;
    };
}

export function loadFavouriteFirst(requiresSnackbarOpen = false, isCNmarketing = false, userId = getUserId()) {
    return async dispatch => {
        try {
            let res = await axios_instance.get(`/api/v1/user-service/users/${getUserId()}/filters/${'SI'}`, {
                params: {},
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                let filterData = res.data.data;
                if (isCNmarketing) {
                    filterData = {
                        ...filterData,
                        group: [],
                        groupNames: [],
                        apFromDate: [],
                        apToDate: [],
                        createFromDate: [],
                        createToDate: [],
                        submissionDeadlineFrom: [],
                        submissionDeadlineTo: [],
                        sisWithAlerts: [],
                        state: [],
                        closedforSubmission: [],
                    };
                }
                const favData = handleFavData(filterData);
                dispatch({type: FIRST_LOAD_FAVOURITE, data: favData});
            } else {
                dispatch({type: FIRST_LOAD_FAVOURITE, data: null});
            }
        } catch (err) {
            dispatch({type: FIRST_LOAD_FAVOURITE, data: null});
            if (requiresSnackbarOpen) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'siPage.filterLoadFail',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        }
    };
}

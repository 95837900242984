//@ts-check
import {axios_instance} from "@/utils/axios_instance";
import {GET_COGEHISTORY, GET_EMAILHISTORY, GET_SIHISTORY} from "@/actions/SIMT-SI/SpecialIssue/SIAction";

import getAuth from "@/utils/getAuth";
/**@typedef {import("redux").Dispatch} Dispatch*/
/**
 * @param {string} siId 
 * @param {number} pageNum 
 * @param {number} pageSize 
 */
export function get_SIHistory(siId, pageNum, pageSize) {
  /**
   * @param {Dispatch} dispatch
   */
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/special-issues/${siId}/editing-history`;
            const res = await axios_instance.get(url, {
                params: {
                    // siCode: siId,
                    pageNum: pageNum,
                    pageSize: pageSize,
                },
                headers: {
                    authorization: getAuth()??'',
                },
            });
            const {code,data} = res.data;
            if (code === 200) {
                dispatch({type: GET_SIHISTORY, data: data});
            } else {
                dispatch({type: GET_SIHISTORY, data: 'error'});
            }
        } catch {
            dispatch({type: GET_SIHISTORY, data: 'error'});
        }
    };
}

/**
 * @param {string} siId 
 * @param {number} pageNum 
 * @param {number} pageSize 
 */
export function get_EmailHistory(siId, pageNum, pageSize) {
  /**
   * @param {Dispatch} dispatch
   */
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/special-issues/${siId}/emails/sending-history`;
            const res = await axios_instance.get(url, {
                params: {
                    // siCode: siId,
                    pageNum: pageNum,
                    pageSize: pageSize,
                },
                headers: {
                    authorization: getAuth()??'',
                },
            });
            const {code,data} = res.data;
            if (code === 200) {
                dispatch({type: GET_EMAILHISTORY, data: data});
            } else {
                dispatch({type: GET_EMAILHISTORY, data: 'error'});
            }
        } catch {
            dispatch({type: GET_COGEHISTORY, data: 'error'});
        }
    };
}
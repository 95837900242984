// @ts-check
import React, { useEffect, useState } from 'react';
import { Box, Stack, Collapse } from '@mui/material';
import { SectionTitleBlock } from '../CustomComponents/CustomBlock';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import LabelAndValue from '../../../../components/LabelAndValue';
import {
  CustomInputList,
  CustomTextArea,
  CustomTextField,
  CustomTextFieldTypeOne,
  DatePicker,
  ItemBlock,
} from '../../SIPDetailPage/EditComponents';
import { MiniFont } from '@/components/FontStyle';
import { SectionContainer } from '../CustomComponents/CustomContainer';
import SingleKV from '../../SIPDetailPage/Submodule/SingleKV';
import { SingleKVOther } from '../../SIPDetailPage/Submodule/SingleKV';

/**
 *
 * @param {import("./SIPInformationSection").SIPInformationEditSectionProps} props
 * @returns
 */
export const SIPInformationEditSection = props => {
  const {
    value: {
      sipTitle,
      aimsAndScope,
      topics,
      keywords,
      references,
      fmSubmissionDeadline,
      acceptanceDeadline,
      epDate,
      notesForEditor,
    },
    setValue: setSipInformation,
    showErrorMessage = false,
    setError = () => {},
  } = props;

  const [sipTitleError, setSipTitleError] = useState(false);
  const [sipTitleOverflowError, setSipTitleOverflowError] = useState(false);
  const [aimsAndScopeError, setAimsAndScopeError] = useState(false);
  const [topicsError, setTopicsError] = useState(false);
  const [keywordsError, setKeywordsError] = useState(false);
  const [referencesError, setReferencesError] = useState(false);

  useEffect(() => {
    setSipTitleError(sipTitle === '');
    if (sipTitle){
      setSipTitleOverflowError(sipTitle.length > 400);
    }
  }, [sipTitle]);

  useEffect(() => {
    setError(
      sipTitleError ||
      sipTitleOverflowError ||
        aimsAndScopeError ||
        topicsError ||
        keywordsError ||
        referencesError
    );
  }, [
    sipTitleError,
    sipTitleOverflowError,
    aimsAndScopeError,
    topicsError,
    keywordsError,
    referencesError,
  ]);

  /**
   *
   * @type {import("./SIPInformationSection").HandleSipInformationChange}
   */
  const handleSipInformationChange = newValue => {
    setSipInformation(current => ({
      ...current,
      [newValue.key]: newValue.value,
    }));
  };

  return (
    <SectionContainer
      title='SIP Information'
      data-selenium-id='SIP_RevisePage_Section-Information-Edit-Container'
    >
      <Stack
        direction='column'
        maxWidth={'1024px'}
        data-selenium-id='SIP_RevisePage_Section-Information-Edit-Stack'
      >
        <ItemBlock
          title='SIP Title:'
          mandatory={true}
          sx={{ marginBottom: '16px', width: '100%' }}
        >
          <CustomTextFieldTypeOne
            id='sipReviseSIPTitleInput'
            data-selenium-id='SIP_RevisePage_Section-Information-Edit-TitleInput'
            value={sipTitle}
            onChange={e => {
              handleSipInformationChange({
                key: 'sipTitle',
                value: e.target.value,
              });
            }}
            placeholder='Please type the proposed Special Issue title.'
            error={(sipTitleError || sipTitleOverflowError) && showErrorMessage}
            sx={{
              '& .MuiOutlinedInput-root.Mui-error fieldset': {
                borderColor: '#EE5350',
              },
            }}
          />
          {(sipTitleError || sipTitleOverflowError) && showErrorMessage && (
            <Box
              sx={{ ...MiniFont, color: '#EE5350' }}
              data-selenium-id='SIP_RevisePage_Section-Information-Edit-TipBox'
            >
              {sipTitleError ? 'This field is required.' : 'The maximum length for title is 400 characters.'}
            </Box>
          )}
        </ItemBlock>
        <ItemBlock
          title='Aims and Scope:'
          mandatory={true}
          sx={{ marginBottom: '16px', width: '100%' }}
        >
          <CustomTextArea
            id='sipReviseAimsandScopeInput'
            data-selenium-id='SIP_RevisePage_Section-Information-Edit-AimsandScopeInput'
            value={aimsAndScope ? aimsAndScope : ''}
            setValue={newValue =>
              handleSipInformationChange({
                key: 'aimsAndScope',
                value: newValue,
              })
            }
            minRows={4}
            maxRows={7}
            minLength={500}
            placeholder='Please introduce the background and significance of your proposed Special Issue in 500 characters as a minimum.'
            needWordCount={false}
            needEmptyCheck={true}
            errorMonitor={showErrorMessage}
            setError={setAimsAndScopeError}
          />
        </ItemBlock>
        <ItemBlock
          title='Topics'
          mandatory={true}
          sx={{ marginBottom: '16px', width: '100%' }}
        >
          <CustomInputList
            id='sipReviseTopicsInput'
            data-selenium-id='SIP_RevisePage_Section-Information-Edit-TopicsInput'
            value={topics ? topics : ['', '', '']}
            setValue={newValue => {
              handleSipInformationChange({
                key: 'topics',
                value: newValue,
              });
            }}
            maxRows={2}
            minItems={3}
            maxItems={50}
            limitLength={255}
            errorMonitor={showErrorMessage}
            needEmptyCheck={true}
            setError={setTopicsError}
            snackbarMessage='sipDetail.edit.sipInformationTopicExceedErr'
            exceedErrText='The character count per topic should be no more than 255.'
          />
        </ItemBlock>
        <ItemBlock
          title='Keywords'
          sx={{ marginBottom: '16px', maxWidth: '495px' }}
        >
          <CustomInputList
            id='sipReviseKeywordsInput'
            data-selenium-id='SIP_RevisePage_Section-Information-Edit-KeywordsInput'
            value={keywords ? keywords : ['', '', '']}
            setValue={newValue => {
              handleSipInformationChange({
                key: 'keywords',
                value: newValue,
              });
            }}
            maxRows={2}
            minItems={3}
            maxItems={48}
            limitLength={500}
            errorMonitor={showErrorMessage}
            snackbarMessage='sipDetail.edit.sipInformationKeywordsExceedErr'
            exceedErrText='The character count per keyword should be no more than 500.'
            setError={setKeywordsError}
          />
        </ItemBlock>
        <ItemBlock
          title='References'
          sx={{ marginBottom: '16px', width: '100%' }}
        >
          <CustomInputList
            id='sipReviseReferencesInput'
            data-selenium-id='SIP_RevisePage_Section-Information-Edit-ReferencesInput'
            value={references ? references : ['']}
            setValue={newValue => {
              handleSipInformationChange({
                key: 'references',
                value: newValue,
              });
            }}
            maxRows={2}
            minItems={1}
            maxItems={50}
            limitLength={1000}
            errorMonitor={showErrorMessage}
            snackbarMessage='sipDetail.edit.sipInformationReferencesExceedErr'
            exceedErrText='The character count per reference should be no more than 1000.'
            setError={setReferencesError}
            bottomHelperText={`For each text box, please fill in one reference item only. Click on "ADD" to add more reference items.`}
          />
        </ItemBlock>
        <ItemBlock
          title='Full Manuscript Submission Deadline:'
          sx={{ marginBottom: '16px', maxWidth: '495px' }}
        >
          <DatePicker
            id='fmSubmissionDeadline'
            data-selenium-id='SIP_RevisePage_Section-Information-Edit-FmSubmissionDeadline'
            value={fmSubmissionDeadline}
            setValue={newValue =>
              handleSipInformationChange({
                key: 'fmSubmissionDeadline',
                value: newValue,
              })
            }
            maxDate={acceptanceDeadline}
          />
        </ItemBlock>
        <ItemBlock
          title='Acceptance Deadline:'
          sx={{ marginBottom: '16px', maxWidth: '495px' }}
        >
          <DatePicker
            id='acceptanceDeadline'
            data-selenium-id='SIP_RevisePage_Section-Information-Edit-AcceptanceDeadline'
            value={acceptanceDeadline}
            setValue={newValue =>
              handleSipInformationChange({
                key: 'acceptanceDeadline',
                value: newValue,
              })
            }
            minDate={fmSubmissionDeadline}
            maxDate={epDate}
          />
        </ItemBlock>
        <ItemBlock
          title='Expected Publication Date:'
          sx={{ marginBottom: '16px', maxWidth: '495px' }}
        >
          <DatePicker
            id='epDate'
            data-selenium-id='SIP_RevisePage_Section-Information-Edit-EpDate'
            value={epDate}
            setValue={newValue =>
              handleSipInformationChange({
                key: 'epDate',
                value: newValue,
              })
            }
            minDate={acceptanceDeadline}
          />
        </ItemBlock>
        <ItemBlock
          title='Notes for Editor:'
          sx={{ marginBottom: '16px', width: '100%' }}
        >
          <CustomTextArea
            id='sipReviseNotesforEditorInput'
            data-selenium-id='SIP_RevisePage_Section-Information-NotesforEditorInput'
            value={notesForEditor ? notesForEditor : ''}
            setValue={newValue =>
              handleSipInformationChange({
                key: 'notesForEditor',
                value: newValue,
              })
            }
            minRows={4}
            maxRows={7}
            placeholder="Please leave any notes for editors' consideration to evaluate your Special Issue proposal as needed."
          />
        </ItemBlock>
      </Stack>
    </SectionContainer>
  );
};

/**
 *
 * @param {import("./SIPInformationSection").SIPInformationShowSectionProps} props
 * @returns
 */
export const SIPInformationShowSection = props => {
  const { value: sipInformation } = props;

  const { formatMessage } = useIntl();

  /**
   * @type {import("./SIPInformationSection").TypeList}
   */
  const typeList = {
    sipTitle: 'HIDE_TEXT',
    aimsAndScope: 'HIDE_TEXT',
    topics: 'HIDE_UL',
    keywords: 'KEYWORDS',
    references: 'HIDE_UL',
    fmSubmissionDeadline: 'DATE',
    acceptanceDeadline: 'DATE',
    epDate: 'DATE',
    notesForEditor: 'HIDE_TEXT',
  };

  /**
   * @type {import("./SIPInformationSection").FieldList}
   */
  const fieldList = [
    'sipTitle',
    'aimsAndScope',
    'topics',
    'keywords',
    'references',
    'fmSubmissionDeadline',
    'acceptanceDeadline',
    'epDate',
    'notesForEditor',
  ];

  /** @type {import("./SIPInformationSection").HandleKeyDiff} */
  const handleKeyDiff = key => {
    switch (key) {
      case 'sipTitle':
      case 'aimsAndScope':
      case 'topics':
        const value1 = sipInformation?sipInformation[key]:null;
        if (value1 === null || value1 === '') return 'N/A';
        return sipInformation[key];
      default:
        const value =  sipInformation?sipInformation[key]:null;
        if (value === null || value === '') return 'N/A';
        if (Array.isArray(value) && value.length === 0) return 'N/A';
        return value;
    }
  };

  return (
    <SectionContainer
      title='SIP Information'
      data-selenium-id='SIP_RevisePage_Section-Information-Show-Container'
    >
      <Stack
        direction='column'
        spacing={2}
        maxWidth={'1024px'}
        data-selenium-id='SIP_RevisePage_Section-Information-Show-Stack'
      >
        {fieldList.map((item, index) => {
          return (
            <SingleKVOther
              id={item}
              data-selenium-id={`SIP_RevisePage_Section-Information-Show-Container-${item}`}
              title={formatMessage({ id: `sipDetail.${item}` })}
              type={typeList[item]}
              margin='0px 0px 0px 0px'
              key={index}
            >
              {handleKeyDiff(item)}
            </SingleKVOther>
          );
        })}
      </Stack>
    </SectionContainer>
  );
};

import styled from '@mui/material/styles/styled';
import InputBase from '@mui/material/InputBase';

const MyBaseInput = styled(InputBase)(({ theme, inputHeight, inputPadding }) => ({
  '& .MuiInputBase-input': {
    /* Default Input box */
    boxSizing: 'border-box',
    /* Auto layout */
    display: 'flex',
    height: inputHeight || '40px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: inputPadding || '7.5px 12px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    color: '#262E35',
    lineHeight: '125%',
    /* Use ellipses instead of text exceeding */
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&::placeholder': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '125%',
      color: '#BCC5CF'
    }
  }
}));


export default MyBaseInput;

import local from './local.config.json';
import dev from './dev.config.json';
import dev2 from './dev2.config.json';
import dev3 from './dev3.config.json';
import qa from './qa.config.json';
import qa2 from './qa2.config.json';
import aliyun from './aliyun.config.json';
import uat from './uat.config.json';
import preprod from './preprod.config.json';
import prod from './prod.config.json';
import uat2 from './uat2.config.json';
import uat3 from './uat3.config.json';

let config;
const envSet = { local, dev, dev2, dev3, qa, qa2, aliyun, uat, preprod, prod, uat2, uat3 };

switch (window.location.hostname) {
  case 'dev.simt.wiley.com':
    config = dev;
    break;
  case 'simtdev3.wiley.com':
    config = dev3;
    break;
  case 'qa.simt.wiley.com':
    config = qa;
    break;
  case 'uat.simt.wiley.com':
    config = uat;
    break;
  case '47.103.142.157': // localhost and Aliyun env
    config = aliyun;
    break;
  case 'localhost':
  case '127.0.0.1':
    //Default to aliyun, if you want to use other env, please set envKey in localStorage and then fresh the page.YOU ARE NOT ALLOWED TO CHANGE HERE!!!
    //本地地址默认链接aliyun，如果想连其他地址，在浏览器localStorage新增envKey，然后填写对应的环境。不要在这里改！更不要提交！！！
    config = (window?.localStorage?.getItem('envKey') && Object.keys(envSet).includes(window.localStorage.getItem('envKey')))
      ? envSet[window.localStorage.getItem('envKey')] : local;
    break;
  case 'simtpreprod.wiley.com':
    config = preprod;
    break;
  case 'simt.wiley.com':
    config = prod;
    break;
  case 'uat2.simt.wiley.com':
    config = uat2;
    break;
  case 'simtuat3.wiley.com':
    config = uat3;
    break;
  case 'dev2.simt.wiley.com':
    config = dev2;
    break;
  case 'qa2.simt.wiley.com':
    config = qa2;
    break;
  default:
    config = dev;
}

Date.prototype.utc2local = function() {
  return new Date(this.getTime() + this.getTimezoneOffset() * 60000);
};

Date.prototype.local2utc = function() {
  return new Date(this.getTime() - this.getTimezoneOffset() * 60000);
};


Date.prototype.asUTCDateString = function(format = 'yyyy-MM-dd') {
  return`${this.getUTCFullYear()}-${(this.getUTCMonth() + 1).toString().padStart(2, '0')}-${this.getUTCDate().toString().padStart(2, '0')}`;
};

export default config;

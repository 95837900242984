import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { ChartGroups } from '@/components/Chart/ChartGroups';
import { ChartList } from '@/components/Chart/ChartList';
import styles from './index.module.scss';
import { changeSiderOpen } from '@/actions/SIMT-User/User/UserManageAction';

import SiAcquisitionSg from '@/assets/ReportDiagrams/A&A/FYTrends/SiAcquisitionSg.png';
import SiAcquisitionBd from '@/assets/ReportDiagrams/A&A/FYTrends/SiAcquisitionBd.png';
import ActiveSiSg from '@/assets/ReportDiagrams/A&A/FYTrends/ActiveSiSg.png';
import ActiveSiBd from '@/assets/ReportDiagrams/A&A/FYTrends/ActiveSiBd.png';

import AAndAFYTendsChart from './Diagrams';

import {
  FY_TRENDS_OF_SI_ACQUISITION,
  FY_TRENDS_OF_ACTIVE_SI,
  SUBJECT_GROUP,
  BUSINESS_DIVISION,
} from './Constants/index.js';
import { getFyYearFromDate } from '../../../../../utils/date_string';

// basic diagram setting
const TAB_NAME = 'A&A';
const baseDataURL = '/reports/acquired-active/diagram/fy-trends';
const baseExcelDownloadURL = '/reports/export/acquired-active/fy-trends';

const DIAGRAM_SETTINGS = [
  // 1st group
  {
    groupName: FY_TRENDS_OF_SI_ACQUISITION,
    chartName: SUBJECT_GROUP,
    hasAdditionalFilter: true,
    catagoryAttributeName: 'subjectGroup',
    url: `${baseDataURL}/sg/acquisition/`,
    singleSelectListUrl: `${baseDataURL}/sg/`,
    downloadUrl: `${baseExcelDownloadURL}/sg`,
    tableDownloadTitle: 'SIAcquisition'
  },
  {
    groupName: FY_TRENDS_OF_SI_ACQUISITION,
    chartName: BUSINESS_DIVISION,
    hasAdditionalFilter: true,
    catagoryAttributeName: 'businessDivision',
    url: `${baseDataURL}/bd/acquisition/`,
    singleSelectListUrl: `${baseDataURL}/bd/`,
    downloadUrl: `${baseExcelDownloadURL}/bd`,
    tableDownloadTitle: 'SIAcquisition'
  },
  // 2nd group
  {
    groupName: FY_TRENDS_OF_ACTIVE_SI,
    chartName: SUBJECT_GROUP,
    hasAdditionalFilter: true,
    catagoryAttributeName: 'subjectGroup',
    url: `${baseDataURL}/sg/active/`,
    singleSelectListUrl: `${baseDataURL}/sg/`,
    downloadUrl: `${baseExcelDownloadURL}/sg`,
    tableDownloadTitle: 'ActiveSI'
  },
  {
    groupName: FY_TRENDS_OF_ACTIVE_SI,
    chartName: BUSINESS_DIVISION,
    hasAdditionalFilter: true,
    catagoryAttributeName: 'businessDivision',
    url: `${baseDataURL}/bd/active/`,
    singleSelectListUrl: `${baseDataURL}/bd/`,
    downloadUrl: `${baseExcelDownloadURL}/bd`,
    tableDownloadTitle: 'ActiveSI'
  },
];

// settings for ChartGroups on overview page
const CHART_GROUP_TITLES = [
  {
    groupTitle: FY_TRENDS_OF_SI_ACQUISITION,
    levels: [
      {
        chartIndex: 0,
        title: SUBJECT_GROUP,
        img: SiAcquisitionSg,
      },
      {
        chartIndex: 1,
        title: BUSINESS_DIVISION,
        img: SiAcquisitionBd,
      },
    ],
  },
  {
    groupTitle: FY_TRENDS_OF_ACTIVE_SI,
    levels: [
      {
        chartIndex: 2,
        title: SUBJECT_GROUP,
        img: ActiveSiSg,
      },
      {
        chartIndex: 3,
        title: BUSINESS_DIVISION,
        img: ActiveSiBd,
      },
    ],
  },
];

const DiagramContent = () => {
  // get showTable from URL
  let [searchParams, setSearchParams] = useSearchParams();
  let selectedChartIndex = searchParams.get('selectedChartIndex');
  if (selectedChartIndex == null) {
    selectedChartIndex = -1;
  } else {
    selectedChartIndex = parseInt(selectedChartIndex);
  }

  const setShowTableNav = chartIndex => {
    setSearchParams({ showTable: false, selectedChartIndex: chartIndex });
  };

  const siderOpen = useSelector(state => state.UserManage.siderOpen);
  const dispatch = useDispatch();

  const selectMonthDate = useSelector(state => state.Report.selectDate);
  const [selectedFY, setSelectFY__] = useState(
    getFyYearFromDate(selectMonthDate)
  );
  useEffect(() => {
    if (selectMonthDate != null) {
      const fyYear = getFyYearFromDate(selectMonthDate);
      setSelectFY__(fyYear);
    }
  }, [selectMonthDate]);

  const {
    groupName,
    chartName,
    hasAdditionalFilter,
    catagoryAttributeName,
    url,
    singleSelectListUrl,
    downloadUrl,
    tableDownloadTitle
  } = useMemo(() => {
    if (
      selectedChartIndex >= 0 &&
      selectedChartIndex < DIAGRAM_SETTINGS.length
    ) {
      return DIAGRAM_SETTINGS[selectedChartIndex];
    } else {
      return {
        groupName: '',
        chartName: '',
        hasAdditionalFilter: null,
        catagoryAttributeName: null,
        singleSelectListUrl: null,
        url: null,
        downloadUrl: null,
        tableDownloadTitle: null,
      };
    }
  }, [selectedChartIndex]);

  // select an index from configuartion array.
  const onSelectChartIndex = index => {
    setShowTableNav(index);

    // if a chart is selected and the side menu is open, close the side menu
    if (index >= 0 && index < DIAGRAM_SETTINGS.length && siderOpen) {
      dispatch(changeSiderOpen(false));
    }
  };

  const cancelSelectedChart = () => onSelectChartIndex(-1);

  return (
    <Box sx={{ marginTop: '32px', paddingBottom: '62px', width: '100%' }}>
      {selectedChartIndex >= 0 ? (
        <Box className={styles['card-content-container']}>
          <div
            className={styles['card-content-paper']}
            disableGutters
            maxWidth={false}
          >
            <AAndAFYTendsChart
              tabName={TAB_NAME}
              header={groupName}
              subHeader={chartName}
              selectedFY={selectedFY}
              cancelSelectedChart={cancelSelectedChart}
              showSubjectGroupsSelector={false}
              hasAdditionalFilter={hasAdditionalFilter}
              catagoryAttributeName={catagoryAttributeName}
              url={url}
              singleSelectListUrl={singleSelectListUrl}
              downloadUrl={downloadUrl}
              tableDownloadTitle={tableDownloadTitle}
            />
          </div>
          <ChartList
            chartGroupTitles={CHART_GROUP_TITLES}
            onSelectChartIndex={onSelectChartIndex}
            selectedChartIndex={selectedChartIndex}
          />
        </Box>
      ) : (
        <ChartGroups
          chartGroupTitles={CHART_GROUP_TITLES}
          onSelectChartIndex={onSelectChartIndex}
          selectedChartIndex={selectedChartIndex}
        />
      )}
    </Box>
  );
};

export default DiagramContent;

// @ts-check
import { Box, Stack, CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import {
  CustomEmailBlock,
  CustomInputErrorBlock
} from './CommonComponents/CustomEmailBlock';
import {
  CustomTextField,
  CustomSubjectTextField,
  CustomChipTextField,
  CustomDisabledTextField
} from './CommonComponents/CustomTextField';
import CustomHeading from './CommonComponents/CustomHeading';
import RichTextEditor from '@/modules/RichTextEditor/RichTextEditor';
import CustomButton from './CommonComponents/CustomButton';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import CommonSnackBar, {
  SEVERITIES
} from '@/modules/ConfirmSnackBar/CommonSnackBar';
import AttachmentDialog from './CommonComponents/CustomDialog';
import CustomAttachmentsView from './CommonComponents/CustomAttachmentsView';
import { extractEmailFromList } from '@/utils/commonUtils';
import { setSnackbarMessageAndOpen } from '../../../actions/SnackbarAction';
import { FileWarningAlert } from '@/pages/AnnualTarget/Overview/ATAlert';
import {getUserInfo} from "@/actions/SIMT-User/User";
import {
    getEmailShowDeadlineChanged,
    getEmailShowInfoEditorial,
    getEmailShowInfoGeGuideLineSharing,
    getEmailShowInfoMonthlySubmission,
    getEmailShowInfoRemove,
    getEmailShowInfoSetUp,
    getEmailShowInfoThankyouLetter,
    sendManualEmail,
    UploadAttachment
} from "@/actions/SIMT-Email/SiEmail";
import {getEmailShowJournalSharing, sendManualJournalEmail} from "@/actions/SIMT-Email/Journal";
const SendEmailPage = () => {
  const patternEmail =
    /^\w+((-\w+)|(\.\w+)|(_\w+))*@[A-Za-z0-9]+(([.\-])[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
  const intl = useIntl();

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  // 邮件的所有字段
  const [emailFrom, setFrom] = useState('');
  const [subject, setSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [copy, setCopy] = useState([]);
  const [to, setTo] = useState([]);
  const [bcc, setBcc] = useState([]);
  const [requireAttachment, setRequireAttachment] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSendClicked, setIsSendClicked] = useState(false);

  const [toError, setToError] = useState(false);
  const [toErrorType, setToErrorType] = useState(0);

  const [copyError, setCopyError] = useState(false);
  const [copyErrorType, setCopyErrorType] = useState(1);

  const [fromError, setFromError] = useState(false);
  const [fromErrorType, setFromErrorType] = useState(1);

  const [loadInfo, setLoadInfo] = useState(true);

  const selector = state => {
    return {
      emailShowInfo: state.Email.emailShowInfo,
      emailSendCode: state.Email.sendEmailReturnCode,
      uploadAttachmentResult: state.Email.uploadAttachmentResult,
      uploadAttachmentMonitor: state.Email.uploadAttachmentMonitor,
      userEmail: state.User.email,
      userLastName: state.User.lastName,
      userFirstName: state.User.firstName,
      requireAttachment: state.Email.emailShowInfo.requireAttachment,
    };
  };
  const {
    emailShowInfo,
    uploadAttachmentResult,
    uploadAttachmentMonitor,
    emailSendCode,
    userEmail,
    userLastName,
    userFirstName
  } = useSelector(selector);
  const siCode = searchParams.get('siCode');
  const journalCode = searchParams.get('journalCode');
  const type = searchParams.get('type');
  const FileControl= requireAttachment;//从硬编码改为从后端获取
  // type==='2' || type==='3' || type==='4' || type==='5' || type==='6';
  const [fileflag,setFileFlag] = useState(true);
  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch, userEmail]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadInfo(true);
      (async () => {
        switch (type) {
          case '1':
            await dispatch(getEmailShowInfoSetUp(siCode));
            break;
          case '3':
            await dispatch(getEmailShowInfoGeGuideLineSharing(siCode));
            break;
          case '4':
            await dispatch(getEmailShowInfoMonthlySubmission(siCode));
            break;
          case '2':
            await dispatch(getEmailShowInfoRemove(siCode));
            break;
          case '5':
            await dispatch(getEmailShowInfoEditorial(siCode));
            break;
          case '12':
            await dispatch(getEmailShowJournalSharing(journalCode));
            break;
          case '13':
            await dispatch(getEmailShowDeadlineChanged(siCode));
            break;
          default:
            await dispatch(getEmailShowInfoThankyouLetter(siCode));
            break;
        }
        setLoadInfo(false);
      })();
    });

    return () => {
      clearTimeout(timer);
    };
  }, [type]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      if (emailSendCode == 200) {
        window.close();
      } else {
        setIsSendClicked(false);
      }
    }, 1000);
  }, [emailSendCode]);

  useEffect(() => {
    if (!emailShowInfo) {
      return;
    }
    if (
      !emailShowInfo.title &&
      !emailShowInfo.content &&
      !emailShowInfo.fromNameAndEmail &&
      emailShowInfo.copy.length == 0 &&
      emailShowInfo.to.length == 0 &&
      emailShowInfo.mailAttachments.length == 0
    ) {
      dispatch(
        setSnackbarMessageAndOpen('email.errorStage', {}, SEVERITIES.error)
      );
      // setTimeout(() => {
      //   window.history.back();
      // }, 2000);
    } else {
      setSubject(emailShowInfo.title);
      setEmailBody(emailShowInfo.content);
      setBcc(emailShowInfo.bccNameAndEmail);
      setFrom(emailShowInfo.fromNameAndEmail);
      if (typeof emailShowInfo.to === 'object') {
        const toSet = new Set(emailShowInfo.to);
        setTo([... toSet].filter(to => to !== ' ' && to !== 'test'));
      } else {
        setTo(emailShowInfo.to);
      }

      if (typeof emailShowInfo.copy === 'object') {
        setCopy(emailShowInfo.copy.filter(copy => copy !== ' ' && copy !== 'test' && !emailShowInfo.to.some(to => to === copy)));
      } else {
        setCopy(emailShowInfo.copy);
      }

      setRequireAttachment(emailShowInfo.requireAttachment);
    }
  }, [emailShowInfo]);

  const handleSendClick = () => {
    const isLoadingFiles = files.filter(item => item.loading === true);

    if (emailFrom.length == 0) {
      setFromErrorType(2);
      setFromError(true);
      return;
    }
    if (to && to.length == 0) {
      setToErrorType(2);
      setToError(true);
    } else if (requireAttachment && files.length == 0) {
      setOpenDialog(true);
      return;
    } else if (isLoadingFiles.length > 0) {
      dispatch(
        setSnackbarMessageAndOpen(
          isLoadingFiles.length > 1
            ? 'The files are being uploaded, please wait'
            : 'The file is being uploaded, please wait',
          {},
          SEVERITIES.warning
        )
      );
    } else {
      setIsSendClicked(true);
    }
  };

  const sendActual = () => {
    setIsLoading(true);
    const formChange = emailFrom;
    if (type!='12'){
      dispatch(
        sendManualEmail(
          formChange,
          to,
          copy,
          bcc,
          subject,
          emailBody,
          files,
          siCode
        )
      );
    } else {
      dispatch(
        sendManualJournalEmail(
          formChange,
          to,
          copy,
          bcc,
          subject,
          emailBody,
          files,
          journalCode
        )
      );
    }
  };

  useEffect(() => {
    if (isSendClicked) {
      sendActual();
    }
  }, [isSendClicked]);

  const getEmailTypeNameEl = type => {
    switch (type) {
      case '1':
        return intl.formatMessage({ id: 'siDetail.emailSetUp' });
      case '3':
        return intl.formatMessage({ id: 'siDetail.emailGEGuidelineSharing' });
      case '4':
        return intl.formatMessage({
          id: 'siDetail.emailMonthlySubmissionReportFull'
        });
      case '5':
        return intl.formatMessage({
          id: 'siDetail.emailEditorialWritingReminder'
        });
      case '2':
        return intl.formatMessage({ id: 'siDetail.emailRemove' });
      case '12':
        return intl.formatMessage({id: 'email.JournalLevelSIStatusSharing'});
      case '13':
        return intl.formatMessage({id: 'email.BannerPromotionAdjustmentWhenSubmissionDeadlineIsChanged'});
      default:
        return intl.formatMessage({ id: 'siDetail.emailThankyouLetter' });
    }
  };

  const typeNameEl = getEmailTypeNameEl(type);

  const handleBlur = e => {
    const email = e.target.value;
    repeatCheck();
    if (email.length != 0 && !patternEmail.test(email)) {
      setFromErrorType(1);
      setFromError(true);
    } else if (email.length == 0) {
      setFromErrorType(2);
      setFromError(true);
    }
  };

  /**
   *
   * @param {File} file
   * @returns
   */
  const getAttachmentSizeInfo = file => {
    let sizeInfo = {};
    if (file.size < 1024) {
      sizeInfo.size = file.size;
      sizeInfo.unit = 'B';
    } else if (file.size < 1048576) {
      sizeInfo.size = file.size / 1024;
      sizeInfo.unit = 'KB';
    } else {
      sizeInfo.size = file.size / (1024 * 1024);
      sizeInfo.unit = 'MB';
    }
    return sizeInfo;
  };

  const [files, setFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const Max_Size = 10 * 1024 * 1024;

  const pattern =
    /exe|ade|adp|app|application|appref-ms|asp|aspx|asx|bas|bat|bgi|cab|cer|chm|cmd|cnt|com|cpl|crt|csh|der|diagcab|fxp|gadget|grp|hlp|hpj|hta|htc|inf|ins|iso|isp|its|jar|jnlp|js|jse|ksh|lnk|mad|maf|mag|mam|maq|mar|mas|mat|mau|mav|maw|mcf|mda|mdb|mde|mdt|mdw|mdz|msc|msh|msh1|msh2|mshxml|msh1xml|msh2xml|msi|msp|mst|msu|ops|osd|pcd|pif|pl|plg|prf|prg|printerexport|ps1|ps1xml|ps2|ps2xml|psc1|psc2|psd1|psdm1|pst|py|pyc|pyo|pyw|pyz|pyzw|reg|scf|scr|sct|shb|shs|theme|tmp|url|vb|vbe|vbp|vbs|vhd|vhdx|vsmacros|vsw|webpnp|website|ws|wsc|wsf|wsh|xbap|xll|xnk/;

  /**
   *
   * @param {number} size
   * @returns
   */
  const checkSize = size => {
    return size < Max_Size;
  };

  /**
   *
   * @param {File} file
   * @returns
   */
  const getFileFormat = file => {
    let strArr = file.name.split('.');
    if (strArr && strArr?.length > 1){
      let ext = strArr[strArr.length - 1];
      return ext.toLocaleLowerCase();
    }
    return 'NO_EXT';
  };

  /**
   *
   * @param {string} fileFormat
   * @returns
   */
  const checkFileFormat = fileFormat => {
    return !pattern.test(fileFormat);
  };

  /**
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const upload = event => {
    let newFiles = [];
    let newUploadingFiles = [];
    let iniflag=true;
    const allowedTypes = [
      'doc','docx','pdf','txt','ppt','pptx','xls','xlsx',
      'csv','png','jpg','jpeg','gif','zip','rar'
    ];
    for (const element of event.target.files) {
      // if (!checkSize(element.size)) {
      //   dispatch(
      //     setSnackbarMessageAndOpen(
      //       'email.attachmentSizeError',
      //       {},
      //       SEVERITIES.error
      //     )
      //   );
      //   iniflag=false;
      //   // console.log("cgt test :\n有一个附件的size过大", event.target.files[i]);
      //   continue;
      // }
      // if (!checkFileFormat(getFileFormat(element))) {
      //   dispatch(
      //     setSnackbarMessageAndOpen(
      //       'email.attachmentFormatError',
      //       {},
      //       SEVERITIES.error
      //     )
      //   );
      //   iniflag=false;
      //   //console.log("cgt test:\n 附件格式不对", event.target.files[i]);
      //   continue;
      // }
      if(!allowedTypes.includes(getFileFormat(element))|| element.size > 10 * 1024 * 1024){
        FileWarningAlert(dispatch,1);
        iniflag=false;
        continue;
      }
      let newFile = {
        name: element.name,
        size: getAttachmentSizeInfo(element).size.toFixed(2),
        unit: getAttachmentSizeInfo(element).unit,
        loading: true,
        state: 'uploading'
      };
      let formData = new FormData();
      formData.append('file', element);
      newFiles.push(newFile);
      newUploadingFiles.push(formData);
    }
    setFileFlag(iniflag);
    setFiles(files.concat(newFiles));
    setUploadingFiles(uploadingFiles.concat(newUploadingFiles));
  };

  useEffect(() => {
    if (uploadingFiles.length > 0) {
      //console.log("cgt test:\n发送附件",uploadingFiles[0]);
      dispatch(UploadAttachment(uploadingFiles[0],undefined,FileControl));
    } else {
      //console.log("cgt test:\n邮件发送完毕");
    }
  }, [uploadingFiles]);

  useEffect(() => {
    if (uploadAttachmentResult === '') {
      return;
    }
    let newUploadingFiles = uploadingFiles.concat();
    let flag = false;
    let shouldRemove = null;
    for (const element of files) {
      if (element.loading) {
        // 正在上传的文件
        flag = true;
        element.loading = false; // 无论成功与否都结束loading状态
        console.log('STATE',uploadAttachmentResult);
        if (uploadAttachmentResult.state === 'success') {
          // 上传成功

          element.id = uploadAttachmentResult.fileID;
          element.state = uploadAttachmentResult.state;
          newUploadingFiles.shift();
        } else if (uploadAttachmentResult.state === 'emptyError') {
          // 上传失败

          element.state = uploadAttachmentResult.state;
          newUploadingFiles = newUploadingFiles.slice(
            1,
            newUploadingFiles.length
          );
          shouldRemove = element;
          dispatch(
            setSnackbarMessageAndOpen(
              'email.attachmentUploadEmptyError',
              {},
              SEVERITIES.error
            )
          );
        } else {

          element.state = uploadAttachmentResult.state;
          newUploadingFiles = newUploadingFiles.slice(
            1,
            newUploadingFiles.length
          );
          shouldRemove = element;
          dispatch(
            setSnackbarMessageAndOpen(
              'email.attachmentUploadError',
              {},
              SEVERITIES.error
            )
          );
        }
        break;
      }
    }
    if (shouldRemove != null) {
      const newFiles = files.filter(item => {
        return item !== shouldRemove;
      });
      setFiles(newFiles);
    }
    if (!flag) {

      return;
    }
    setUploadingFiles(newUploadingFiles);
  }, [uploadAttachmentMonitor]);

  const onReady = () => {
    document
      .querySelector('button[data-cke-tooltip-text=\'Attachments\']')
      .addEventListener('click', () => {
        document.getElementById('test').click();
      });
  };

  const isDuplicate = (list, item) => {
    const res = extractEmailFromList(list);
    return res.includes(item);
  };

  function handleCheckError() {
    if (to && to.length == 0) {
      setToErrorType(2);
      setToError(true);
    } else if (toErrorType == 1 || toErrorType == 3 || toErrorType == 4) {
    } else {
      setToErrorType(0);
    }
  }
  useEffect(() => {
    repeatCheck();
    handleCheckError();
  }, [to, copy]);
  useEffect(() => {
    if (toErrorType !== 0) {
      setToError(true);
    } else {
      setToError(false);
    }
  }, [toErrorType]);
  /**
   *
   * @param {string[]} arr
   * @returns
   */
  function removeBracket(arr = []) {
    const arrWithoutBracket = arr.map(item => {
      let indexFirst = item.indexOf('(');
      let indexSecond = item.indexOf(')');
      if (indexFirst !== -1 && indexSecond !== -1) {
        item = item.substring(indexFirst + 1, indexSecond);
      }
      return item;
    });
    return arrWithoutBracket;
  }
  function repeatCheck() {
    var toWithoutBracket = removeBracket(to);
    var copyWithoutBracket = removeBracket(copy);
    var toRepeatWithFrom =
      toWithoutBracket.filter(item => item == emailFrom).length > 0 &&
      emailFrom.length !== 0;
    var copyRepeatWithFrom =
      copyWithoutBracket.filter(item => item == emailFrom).length > 0 &&
      emailFrom.length !== 0;
    var toNew = new Set(toWithoutBracket);
    var copyNew = new Set(copyWithoutBracket);
    var copyRepeatWithTo = new Set([...toNew].filter(x => copyNew.has(x)));

    if (toRepeatWithFrom || copyRepeatWithFrom) {
      setFromErrorType(4);
      setFromError(true);
    } else if (fromErrorType == 2 || fromErrorType == 1 || fromErrorType == 3) {
    } else {
      setFromError(false);
    }

    if (copyRepeatWithTo.size > 0 || copyRepeatWithFrom) {
      setCopyErrorType(4);
      setCopyError(true);
    } else if (copyErrorType == 1 || copyErrorType == 3) {
    } else {
      setCopyError(false);
    }
    if (copyRepeatWithTo.size > 0 || toRepeatWithFrom) {
      setToErrorType(4);
      setToError(true);
    } else if (toErrorType == 1 || toErrorType == 3 || toErrorType == 2) {
    } else {
      setToError(false);
    }
  }

  function handleRepeatError() {
    repeatCheck();
    handleCheckError();
  }
  /**
   *
   * @param {number} type
   * @returns
   */
  const getErrorMessage = type => {
    switch (type) {
      case 1:
        return intl.formatMessage({ id: 'email.patternError' });
      case 2:
        return intl.formatMessage({ id: 'email.nullError' });
      case 3:
        return intl.formatMessage({ id: 'email.lengthError' });
      case 4:
        return intl.formatMessage({ id: 'email.repeatError' });
    }
  };

  const isValidUser = () => {
    let pattern = /.*@wiley.com$/;
    return pattern.test(userEmail);
  };

  return (
    <Box data-selenium-id='Email_Send-Box'>
      <Header data-selenium-id='Email_Send-Header' />

      <AttachmentDialog
        open={openDialog}
        setOpen={setOpenDialog}
        setIsSendClicked={setIsSendClicked}
        data-selenium-id='Email_Send-AttachmentDialog'
      />

      {/* {openDialog ?  : null} */}
      <Stack
        minHeight='100vh'
        alignItems='center'
        sx={{
          background: '#fff',
          marginTop: '56px'
        }}
        data-selenium-id='Email_Send-Stack'
      >
        <Box
          width='944px'
          maxWidth='100vw'
          padding={4}
          sx={{
            boxSizing: 'border-box'
          }}
        >
          <Stack spacing={3}>
            <CustomHeading data-selenium-id='Email_Send-Stack-CustomHeading'>
              {typeNameEl}
            </CustomHeading>
            <Stack spacing={1} data-selenium-id='Email_Send-Stack-Stack1'>
              <CustomEmailBlock
                title={intl.formatMessage({ id: 'email.from' })}
              >
                <CustomDisabledTextField value={emailShowInfo?.fromNameAndEmail} disabled fullWidth />
                {loadInfo ? (
                  <></>
                ) : (
                  <CustomInputErrorBlock
                    error={fromError}
                    text={getErrorMessage(fromErrorType)}
                  />
                )}
              </CustomEmailBlock>

              <CustomEmailBlock title={intl.formatMessage({ id: 'email.to' })}>
                <CustomChipTextField
                  contentList={to}
                  setContentList={setTo}
                  pattern={patternEmail}
                  setError={setToError}
                  setErrorType={setToErrorType}
                  fullWidth
                  isDuplicate={isDuplicate}
                  handleRepeatError={handleRepeatError}
                  removeBracket={removeBracket}
                />
                {loadInfo ? (
                  <></>
                ) : (
                  <CustomInputErrorBlock
                    error={toError}
                    text={getErrorMessage(toErrorType)}
                  />
                )}
              </CustomEmailBlock>

              <CustomEmailBlock
                title={intl.formatMessage({ id: 'email.copy' })}
              >
                <CustomChipTextField
                  contentList={copy}
                  setContentList={setCopy}
                  pattern={patternEmail}
                  setError={setCopyError}
                  setErrorType={setCopyErrorType}
                  fullWidth
                  isDuplicate={isDuplicate}
                  handleRepeatError={handleRepeatError}
                  removeBracket={removeBracket}
                />
                {loadInfo ? (
                  <></>
                ) : (
                  <CustomInputErrorBlock
                    error={copyError}
                    text={getErrorMessage(copyErrorType)}
                  />
                )}
              </CustomEmailBlock>
              <CustomEmailBlock title={intl.formatMessage({ id: 'email.bcc' })}>
                <CustomDisabledTextField value={emailShowInfo?.bccNameAndEmail?.join(',')} disabled fullWidth />
              </CustomEmailBlock>
            </Stack>

            <Stack
              spacing={1}
              sx={{
                '& .ck-reset.ck-editor': {
                  width: '100%'
                },
                '& .ck-content.ck-editor__editable': {
                  minWidth: '100%',
                  maxWidth: '100%',
                  boxSizing: 'border-box'
                },
                mt: '8px'
              }}
              data-selenium-id='Email_Send-Stack-Stack2'
            >
              <Box paddingRight={4}>
                <CustomSubjectTextField
                  value={subject}
                  onChange={e => setSubject(e.target.value)}
                  fullWidth
                />
              </Box>
              <input
                id='test'
                data-selenium-id='Email_Send-TestInput'
                type='file'
                style={{ display: 'none' }}
                multiple={true}
                onChange={event => {
                  upload(event);
                  event.target.value = ''; // 清除这个值，以允许重复选择同一个文件
                }}
              />
              <CustomAttachmentsView
                files={files}
                setFiles={setFiles}
                FileControl={FileControl}
                flag={fileflag}
                setFlag={setFileFlag}
              ></CustomAttachmentsView>
              <RichTextEditor
                content={emailBody}
                setContent={e => {
                  setEmailBody(e);
                }}
                removePlugins={['Mention']}
                extraToolbarItems={['attachmentsUpload']}
                onReady={onReady}
              />
            </Stack>
            <Stack
              direction='row'
              justifyContent='flex-end'
              spacing={1}
              data-selenium-id='Email_Send-Stack-Stack3'
            >
              <CustomButton
                sx={{
                  textTransform: 'capitalize'
                }}
                onClick={() => {
                  window.close();
                }}
                disabled={isLoading}
                data-selenium-id='Email_Send-Stack-Stack3-CustomButton'
              >
                {intl.formatMessage({ id: 'common.cancel' }).toLowerCase()}
              </CustomButton>
              <Box data-selenium-id='Email_Send-Stack-Stack3-Box'>
                <CustomButton
                  variant='contained'
                  onClick={handleSendClick}
                  disabled={
                    // !isValidUser() ||
                    toError ||
                    copyError ||
                    isLoading ||
                    fromError ||
                    (FileControl && files.length===0)
                  }
                  data-selenium-id='Email_Send-Stack-Stack3-Box-CustomButton'
                >
                  {intl.formatMessage({ id: 'email.send' })}
                </CustomButton>
                {isLoading && (
                  <CircularProgress
                    size={26}
                    sx={{
                      position: 'absolute',
                      marginLeft: '-75px',
                      marginTop: '5px'
                    }}
                    data-selenium-id='Email_Send-CircularProgress'
                  />
                )}
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <Footer />
      <CommonSnackBar />
    </Box>
  );
};

export default SendEmailPage;

export const LimitWord = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export const AllCentered = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const RowCentered = {
  display: 'flex',
  justifyContent: 'center',
};

export const ColumnCentered = {
  display: 'flex',
  alignItems: 'center',
};

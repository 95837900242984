import {axios_instance, axios_instance_fileDownload} from "@/utils/axios_instance";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {CV_UPLOAD, DOWNLOAD_CV, downloadFileOfNewStyle} from "@/actions/SIMT-SI/GuestEditor/GEAction";

export function UploadCV(file, cancelToken, updateFunc, finishFunc) {
    return async dispatch => {
        const url = '/api/v1/sip-service/special-issue-proposals/guest-editor/cv';
        let param = new FormData();
        param.append('file', file);
        // param.append('type', 'upload');
        const config = {
            headers: {
                authorization: localStorage.getItem('authorization'),
            },
            onUploadProgress: e => {
                if (e.lengthComputable) {
                    let percentLoaded = Math.round((e.loaded / e.total) * 100);
                    // 更新进度条长度
                    if (percentLoaded <= 100) {
                        updateFunc(percentLoaded);
                    }
                }
            },
            params: {
                type: 'upload',
            },
            cancelToken: cancelToken.token,
        };
        const res = await axios_instance.post(url, param, config);
        const{code,message,data} = res.data;
        if (code === 200) {
            dispatch({type: CV_UPLOAD, data: data});
            finishFunc(res.data.data);
        } else {
            dispatch(
                setSnackbarMessageAndOpen(
                    'gePage.updateError',
                    {
                        value: res.data.message,
                    },
                    SEVERITIES.error
                )
            );
            dispatch({type: CV_UPLOAD, data: ''});
        }
    };
}

export function downloadCVFile(filename) {
    let FileName = filename;
    const match = FileName.match(/(\d{5,13})_/); // 查找匹配的部分
    if (FileName.substring(match.index + match[0].length).length > 1) FileName = FileName.substring(match.index + match[0].length);
    return async dispatch => {
        const requestURL = '/api/v1/sip-service/special-issue-proposals/guest-editor/cv';
        try {
            let res = await axios_instance_fileDownload.post(requestURL, {},{
                responseType: 'blob',
                params: {
                    type: 'download',
                    fileName: filename,
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    authorization: localStorage.getItem('authorization'),
                },
            });
            console.log('downloadSignleFile success', res);
            downloadFileOfNewStyle(res.data, FileName);
            dispatch({type: DOWNLOAD_CV, data: true});
        } catch (err) {
            dispatch({type: DOWNLOAD_CV, data: false});
        }
    };
}

/**
 * @description 提交proposer - SI GE匹配结果
 * @param {string} geCode
 * @param {string} geId
 * @param {boolean} isEmail
 * @param {(e:boolean)=>void?} resolve
 */
export const matchProposer = (sipCode='',geCode = '', geId = '', isEmail = false, resolve = _e => {
}) =>
    axios_instance.get(`/api/v1/sip-service/special-issue-proposals/${sipCode}`, {
        params: {
            type: 'matchingProposer',
            geCode,
            geId,
            email: isEmail,
        },
        headers: {
            authorization: localStorage.getItem('authorization'),
        },
    }).then(
        /**
         * @param {import('axios').AxiosResponse<{readonly code?: number;readonly status?: number;readonly message?: string;}>} res
         */
        res => {
            if (res?.status === 200) {
                const data = res?.data;
                if (
                    data?.code === 200 ||
                    data?.status === 200 ||
                    data?.message === 'OK'
                ) {
                    return;
                }
            }
            alert('some error occur');
        }).catch(console.error);
import { Grid, Box } from '@mui/material';
import DeadLinePassed from '../../../../assets/DeadLinePassed.svg';
function CreateAndUpdateInfomationBlock(props) {
  const { property, value, isOA } = props;
  const describeFontStyle = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: 'Gray500.main',
  };

  // (property === 'SI Revenue Model:' && isOA) ||
  return property === 'SI Revenue Model:' ||
    (property === 'Previous Handling CE:' && value === '') ? (
    <>
      {/* revenue model 显示的时候(oa=false)为空 才会显示
          oa = true 的时候 不显示 那么review也不显示
      */}
    </>
  ) : (
    <Box
      data-selenium-id='SIPage_CreateAndUpdateSI-InfomationBlock-Box'
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: '8px',
        width: '100%',
      }}
    >
      <>
        <Box
          data-selenium-id='SIPage_CreateAndUpdateSI-InfomationBlock-PropertyBox'
          sx={{
            height: 'auto',
            width: '200px',
            marginRight: '16px',
            ...describeFontStyle,
          }}
        >
          {property}
        </Box>
        <Box
          data-selenium-id='SIPage_CreateAndUpdateSI-InfomationBlock-FlexContainerBox'
          sx={{
            ...{
              describeFontStyle,
              color: 'Gray600.main',
            },
            wordBreak: 'break-word',
            width: 'calc(100% - 216px)',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          {value === 'Temporary' ? (
            <></>
          ) : (
            <Box data-selenium-id='SIPage_CreateAndUpdateSI-InfomationBlock-valueBox'>
              {value}
            </Box>
          )}
          {property === 'Deadline Passed:' && value === 'Yes' && (
            <Box
              data-selenium-id='SIPage_CreateAndUpdateSI-InfomationBlock-DeadLinePassedBox'
              ml={0.5}
              display='flex'
              alignItems='center'
            >
              <DeadLinePassed data-selenium-id='SIPage_CreateAndUpdateSI-InfomationBlock-DeadLinePassed' />
            </Box>
          )}
        </Box>
      </>
    </Box>
  );
}
export default CreateAndUpdateInfomationBlock;

import {
  Box,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  Tooltip,
  tooltipClasses,
  styled,
} from '@mui/material';
import React, { useEffect, useRef, useState, useContext } from 'react';
import IssueTable from '../SpecialIssueManagement/IssueTable';
import { useDispatch, useSelector } from 'react-redux';
import { StyledEngineProvider, useTheme } from '@mui/material/styles';
import AddIconWhite from '../../../assets/AddIconWhite.svg';
import BulkTransferIcon from '../../../assets/bulkTransfer.svg';
import BulkTransferIconWhite from '../../../assets/bulkTransferWhite.svg';
import ExportAllIconWhite from '../../../assets/exportAllIconWhite.svg';
import SIOverviewExportAll from '../../../assets/SIOverviewExportAll.svg';
import ColumnsIconBlue from '../../../assets/columnsIconBlue.svg';
import ColumnsIconWhite from '../../../assets/columnsIconWhite.svg';
import {
  beginSIDataLoading,
  EXPORT_SI_ERROR,
  exportSICSV,
  exportSIExcel,
  handlePageChangeAction,
  setOpen,
} from '../../../actions/SIMT-SI/SpecialIssue/SIAction';
import Popover from '@mui/material/Popover';
import { ResponsiveButton } from '../../../components/StyledButton/SIMTButton';
import CEBatchUpdate from './CEBatchUpdate/CEBatchUpdate';
import { NewDesignedMain } from '../../../components/Main';
import { ShowForPermission } from '../../../components/PermissionControl/ShowForPermission';
import { DelaySearchTextField } from '../../../components/MyTextField';
import { clearUseFilter, setSearchSI } from '../../../actions/SIMT-SI/SpecialIssueFilter/FilterAction';
import { unstable_batchedUpdates } from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import ExportAllSIPage from './ExportAllSIPage';
import { cancelSelectAll } from '../../../actions/SeleteAction';
import SIOverviewFilterIcon1 from '../../../assets/SIOverviewFilterIcon1.svg';
import SIOverviewExportIconBlue from '../../../assets/SIOverviewExportIconBlue.svg';
import SIOverviewExportIconWhite from '../../../assets/SIOverviewExportIconWhite.svg';
import NewSIFilterDrawer from './anotherNewFilterDrawer';
import SearchIcon from '@/assets/SearchIcon.svg';
import { AlertBox, UntitledAlertBox } from '@/components/Alert/AlertBox';
import ExportSIPage from './ExportSIPage';
import Number1 from '../../../assets/number1.svg';
import Number2 from '../../../assets/number2.svg';
import Number3 from '../../../assets/number3.svg';
import Number4 from '../../../assets/number4.svg';
import Number5 from '../../../assets/number5.svg';
import Number6 from '../../../assets/number6.svg';
import Number7 from '../../../assets/number7.svg';
import Number8 from '../../../assets/number8.svg';
import Number9 from '../../../assets/number9.svg';
import Number10 from '../../../assets/number10.svg';
import Number11 from '../../../assets/number11.svg';
import Number12 from '../../../assets/number12.svg';
import FilterPage from './Filter';
import { ScrollBarX, ScrollBarY } from '../../../componentsphase2/ScrollBar';
import { SEVERITIES } from '../../../modules/ConfirmSnackBar/CommonSnackBar';
import { setSnackbarMessageAndOpen } from '../../../actions/SnackbarAction';
// import { useLocation } from 'react-router-dom';
// import {marketing, siOverview} from '@/Route';
import { isCNmarketingContext } from '@/Route';
import FilterButton from './Filter/component/CommonComponets/FilterButton';
import CustomCheckBox from '@/pages/Login/NewLogin/Components/StyledCheckBox';
import { CustomTooltip, CustomThinTooltip } from '@/components/CustomTooltip';
import ExportDownloading from '../../../assets/ExportDownloading.svg';

import { set } from 'date-fns';
import { WindowHeightContext } from './Filter/Context';
import RotatingSVG from '@/componentsphase2/RotatingSVG';
import MyLocalStorage from '../../../utils/MyLocalStorage';
import { defaultColumns } from '../ChangeList';
import { getSystemConfig, loadPaperConfig, loadSITypes } from "@/actions/SIMT-User/Config";
import { setAIAcquisitionCreateSuccessAlert } from '@/actions/SIMT-SIP/SIP/SIPAction';
import {PERMISSIONS} from "@/constant/permission";

const filterDrawerWidth = 600; //588

const filterIconMap = {
  1: <Number1 />,
  2: <Number2 />,
  3: <Number3 />,
  4: <Number4 />,
  5: <Number5 />,
  6: <Number6 />,
  7: <Number7 />,
  8: <Number8 />,
  9: <Number9 />,
  10: <Number10 />,
  11: <Number11 />,
  12: <Number12 />,
};

const OnlyCMTypography = styled(Typography)({
  color: '#596A7C',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '125%' /* 17.5px */,
});

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    background: '#596A7C',
    fontWeight: 400,
    fontSize: '14px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      width: '6px',
      height: '3px',
      backgroundColor: '#596A7C',
    },
  },
});

function handScoll() {
  const theHeader = document.querySelector('#AuthHeader-AppBar > div');
  const menu = document.querySelector(
    '#root > div > div > div.MuiGrid-root.MuiGrid-item > div > div.MuiBox-root.css-0 > div > div'
  );
  let headerTop = theHeader.getClientRects()[0].y;
  if (headerTop > -48) {
    menu.style.top = headerTop + 'px';
  } else {
    menu.style.top = '-48px';
  }
  // if(headerTop < -96 ){
  //   document.getElementById('SIPage_Overview-SIOverview-TopTools').style.position='fixed';
  //   document.getElementById('SIPage_Overview-SIOverview-TopTools').style.top='0px';

  // }else{
  //   document.getElementById('SIPage_Overview-SIOverview-TopTools').style.position='relative';

  // }
}

function SIOverview() {
  // useEffect(() => {
  //   document.getElementById('AuthHeader-AppBar').style.position = 'absolute';
  //   document.addEventListener('scroll', handScoll);
  // }, []);
  // useEffect(() => {
  //   return () => {
  //     document.getElementById('AuthHeader-AppBar').style.position = 'fixed';
  //     document.removeEventListener('scroll', handScoll);
  //     const menu = document.querySelector(
  //       '#root > div > div > div.MuiGrid-root.MuiGrid-item > div > div.MuiBox-root.css-0 > div > div'
  //     );
  //     menu.style.top = '0px';
  //   };
  // }, []);
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();
  const [changeListOpen, setChangeListOpen] = useState(false);
  const [exportallPageOpen, setExportallPageOpen] = useState(false);
  const [transferOpen, setTransferOpen] = useState(false);
  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      newFilterOpen: state.SI.newFilterOpen,
      selectedList: state.Selete.selectedList,
      searchSI: state.Filter.searchSI,
      UserColumnSaveResult: state.SI.UserColumnSaveResult,
      exportErrorCode: state.SI.exportErrorCode,
      useFilters: state.Filter.useFilters,
      aiAcquisitionCreateSuccessAlert:
        state.SIP.aiAcquisitionCreateSuccessAlert,
      aiAcquisitionCreateSuccessSum:
        state.SIP.aiAcquisitionCreateSuccessSum,
      // scrollContext: state.Common.scrollContext,
    };
  };

  const {
    open,
    newFilterOpen,
    selectedList,
    searchSI,
    UserColumnSaveResult,
    exportErrorCode,
    aiAcquisitionCreateSuccessAlert,
    aiAcquisitionCreateSuccessSum,
    useFilters,
    // scrollContext,
  } = useSelector(selector);

  // const scrollBoxRef = useRef(null);
  // const scrollBoxInnerRef = useRef(null);
  const contextHolderRef = useRef(null);

  const [isDownloading, setisDownloading] = useState(false);

  // const [transPercent, setTransPercent] = useState(1);

  // useEffect(() => {
  //   const scrollBoxElement = scrollBoxRef.current;
  //   const scrollBoxInnerElement = scrollBoxInnerRef.current;
  //
  //   setTransPercent(scrollContext.boxHeight / scrollBoxElement.offsetHeight);
  //   scrollBoxInnerElement.style.height = (scrollContext.contextHeight / scrollContext.boxHeight * scrollBoxElement.offsetHeight) + 'px';
  // }, [scrollContext.contextHeight]);

  // const handleScroll =  event => {
  //   const scrollBoxElement = scrollBoxRef.current;
  //   const offset = scrollBoxElement.scrollTop;
  //
  //   const containerBoxRef = contextHolderRef.current.getContextRef();
  //   const containerBoxElement = containerBoxRef.current;
  //   containerBoxElement.scrollTop = offset * transPercent;
  //
  //   // dispatch(contextScrollTo(offset * transPercent));
  // };

  useEffect(() => {
    if (exportErrorCode) {
      if (exportErrorCode === 14142) {
        setExportAllMsgString(
          intl.messages['siPage.exportAllError.CODE_14142']
        );
      } else if (exportErrorCode === 14143) {
        setExportAllMsgString(
          intl.messages['siPage.exportAllError.CODE_14143']
        );
      } else {
        setExportAllMsgString(
          intl.messages['siPage.exportAllError.CODE_OTHER']
        );
      }
      setExportAllMsgOpen(true);
    } else {
      setExportAllMsgOpen(false);
    }
  }, [exportErrorCode]);

  const downBy = open * 240 + newFilterOpen * filterDrawerWidth + 1130;
  const screenTooSmall = open * 240 + newFilterOpen * filterDrawerWidth + 730;
  const buttonDown = useMediaQuery(theme.breakpoints.down(downBy));
  const requiresNewLine = useMediaQuery(theme.breakpoints.down(screenTooSmall));
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [exportMsgOpen, setExportMsgOpen] = React.useState(false);
  const [exportAllMsgOpen, setExportAllMsgOpen] = React.useState(false);
  const [exportAllMsgString, setExportAllMsgString] = React.useState('');
  const [transferMsgOpen, setTransferMsgOpen] = React.useState(false);
  const [exportDialogOpen, setExportDialogOpen] = React.useState(false);
  const exportMenuOpen = !exportMsgOpen && Boolean(anchorEl);
  const [alertOpen, setAlertOpen] = React.useState(false);

  const userPermissions = localStorage.getItem('userPermissions'); //权限管理

  const isSiReadLimit =
    userPermissions.indexOf(PERMISSIONS.SI_READ) === -1 &&
    userPermissions.indexOf(PERMISSIONS.SI_READ_LIMITED) !== -1;
  //TODO hide some component when si:readlimited

  const handleExportMenuClick = event => {
    if (selectedList.length === 0) {
      //setExportMsgOpen(true);
      dispatch(
        setSnackbarMessageAndOpen(
          'Please select at least one SI to export.',
          {},
          SEVERITIES.warning
        )
      );
      return;
    } else {
      setExportDialogOpen(true);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleCMExportMenuClick = event => {
    if (selectedList.length === 0) {
      //setExportMsgOpen(true);
      setAlertOpen(true);
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setExportMsgOpen(false);
    setAnchorEl(null);
  };

  const handleExportMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTransferOpen = async event => {
    if (selectedList.length > 0) {
      // dispatch(beginSIDataLoading());
      // dispatch(getSIDataByCodes(selectedList));
      setTransferOpen(true);
    } else {
      setTransferMsgOpen(true);
      // setAnchorEl(event.currentTarget);
      //Please select at least journal to Bulk Transfer.
      // dispatch(
      //   setSnackbarMessageAndOpen(
      //     'Please select at least one journal to Bulk Transfer.',
      //     {},
      //     SEVERITIES.warning
      //   )
      // );
    }
  };

  const handleTransferClose = () => {
    setTransferOpen(false);
  };
  // const isCNmarketing = useLocation().pathname.includes(`simt/auth/${marketing}/${siOverview}`);
  const isCNmarketing = useContext(isCNmarketingContext) ?? false;
  const handleExportExcel = () => {
    let length = Object.values(selectedList).length;
    if (length <= 1000) {
      setisDownloading(true);
      async function fetchData() {
        await dispatch(exportSIExcel(selectedList, isCNmarketing));
        await delay(500);
        setisDownloading(false);
      }

      fetchData();
      setAnchorEl(null);
    } else {
      dispatch(
        setSnackbarMessageAndOpen(
          'common.largeSizeFail',
          {},
          'warning',
          null,
          null,
          {
            title: 'You can only export a maximum of 1000 items at once.',
          },
          localStorage.getItem('userPermissions').includes('admin')
            ? 'Please visit the ‘Export Data’ page to export all data.'
            : 'You can contact system admin if you want to export all data.'
        )
      );
    }
  };

  const handleExportCsv = () => {
    let length = Object.values(selectedList).length;
    if (length <= 1000) {
      setisDownloading(true);
      async function fetchData() {
        await dispatch(exportSICSV(selectedList, isCNmarketing));
        await delay(500);
        setisDownloading(false);
      }
      fetchData();
      setAnchorEl(null);
    } else {
      dispatch(
        setSnackbarMessageAndOpen(
          'common.largeSizeFail',
          {},
          'warning',
          null,
          null,
          {
            title: 'You can only export a maximum of 1000 items at once.',
          },
          localStorage.getItem('userPermissions').includes('admin')
            ? 'Please visit the ‘Export Data’ page to export all data.'
            : 'You can contact system admin if you want to export all data.'
        )
      );
    }
  };
  // showColumns 的字段有变化，索引不一定正确
  const [showColumns, setShowColumns] = useState([
    true, //SICode0
    true, //JC1
    true, //JN2
    true, //Online SSN 3
    true, //SIType 已更名为SI Publication Format4
    true, //SITitle5
    true, //Leader6
    true, //Co7
    true, //Stage8
    true, //HE9
    true, //Group10
    true, //PDPublisher11
    true, //PPPublisher 12
    true, //Subject Group13
    true, //SISource14
    true, //IdeaDate15
    true, //AcquireDate16
    true, //Open for submission date17
    true, //Submission18
    true, //Accept19
    true, //Publication20
    true, //deadline21
    true, //close22
    true, //paperCommission23
    true, //workflow24
    true, //PCE25

    true, //Recruited26
    true, //submitted27
    true, //Accept28
    true, //Reject29
    true, //inProgress30

    true, //Volume31
    true, //Issue32
    true, //Actual Date33
    true, //OaNumber34
    true, //OoNumber35
    true, //Output36

    true, //CreateDate37

    true, //lasActions38
    true, //stakeholder1
    true, //stakeholder2
  ]);

  const toggleFilterOpen = () => {
    // dispatch(setOpen('newFilter', !newFilterOpen));
    setShowFilter(!showFilter);
  };

  const handleFirstOpen = () => {
    window.open('/simt/auth/siRelated/si/create');
  };

  const handleChangeListOpen = () => {
    setChangeListOpen(true);
  };

  const handleExportAllClick = () => {
    setExportallPageOpen(true);
  };

  const handleExportAllPageClose = () => {
    setExportallPageOpen(false);
  };

  const handleSearchSI = e => {
    if (e === searchSI) {
      return;
    }
    unstable_batchedUpdates(() => {
      dispatch(handlePageChangeAction(1));
      dispatch(clearUseFilter());
      dispatch(setSearchSI(e));
      dispatch(cancelSelectAll()); // clear all selected items
    });
  };

  useEffect(() => {
    if (userPermissions.includes(PERMISSIONS.SI_WRITE)) {
      dispatch(loadSITypes());
      dispatch(loadPaperConfig());
    }
  }, [dispatch]);

  useEffect(() => {
    if (UserColumnSaveResult !== null) {
      setShowColumns([
        UserColumnSaveResult['SICode'] === 'true',
        UserColumnSaveResult['JournalCode'] === 'true',
        UserColumnSaveResult['JournalName'] === 'true',
        UserColumnSaveResult['OnlineISSN'] === 'true',
        UserColumnSaveResult['SIPublicationFormat'] === 'true',
        UserColumnSaveResult['SpecialIssueTitle'] === 'true',
        UserColumnSaveResult['LeadGE'] === 'true',
        UserColumnSaveResult['CoGE'] === 'true',
        UserColumnSaveResult['Stage'] === 'true',
        UserColumnSaveResult['HE'] === 'true',
        UserColumnSaveResult['Group'] === 'true',
        UserColumnSaveResult['PDPublisher'] === 'true',
        UserColumnSaveResult['PPPublisher'] === 'true',
        UserColumnSaveResult['SubjectGroup'] === 'true',
        UserColumnSaveResult['SISource'] === 'true',
        UserColumnSaveResult['IdeaDate'] === 'true',
        UserColumnSaveResult['AcquiredDate'] === 'true',
        UserColumnSaveResult['OpenForSubmissionDate'] === 'true',
        UserColumnSaveResult['Deadline'] === 'true',
        UserColumnSaveResult['ExpectedAcceptanceDate'] === 'true',
        UserColumnSaveResult['ExpectedPublicationDate'] === 'true',
        UserColumnSaveResult['DeadlinePassed'] === 'true',
        UserColumnSaveResult['Closed'] === 'true',
        UserColumnSaveResult['PaperCommissioning'] === 'true',
        UserColumnSaveResult['Workflow'] === 'true',
        UserColumnSaveResult['PreviousHE'] === 'true',
        UserColumnSaveResult['ArticlesRecruited'] === 'true',
        UserColumnSaveResult['ArticlesSubmitted'] === 'true',
        UserColumnSaveResult['ArticlesAccepted'] === 'true',
        UserColumnSaveResult['ArticlesRejected'] === 'true',
        UserColumnSaveResult['InProgress'] === 'true',
        UserColumnSaveResult['Volume'] === 'true',
        UserColumnSaveResult['Issue'] === 'true',
        UserColumnSaveResult['ActualPublicationDate'] === 'true',
        UserColumnSaveResult['OO'] === 'true',
        UserColumnSaveResult['OA'] === 'true',
        UserColumnSaveResult['OutputRate'] === 'true',
        UserColumnSaveResult['CreateDate'] === 'true',
        UserColumnSaveResult['LastActionDate'] === 'true',
        true,//stakeHolder1
        true,//stakeHolder2
      ]);
    } else {
      // const userRole = MyLocalStorage.getItem("userInfo")?.role;

      setShowColumns(defaultColumns);
    }
  }, [UserColumnSaveResult]);

  const { formatMessage } = useIntl();

  const [showFilter, setShowFilter] = useState(false);

  const [withCMOnly, setWithCMOnly] = useState(false);
  const [visibleHeight, setVisibleHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setVisibleHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {/*
            Referrer to: https://mui.com/zh/guides/interoperability/#css-injection-order
            About CSS injection order.
            If not in <StyledEngineProvider />, the user class style will be covered by MUI.
      */}
      <WindowHeightContext.Provider value={visibleHeight}>
        <StyledEngineProvider
          injectFirst
          data-selenium-id='SIPage_Overview-SIOverview'
        >
          <CEBatchUpdate
            open={transferOpen}
            handleFirstClose={handleTransferClose}
            data-selenium-id='SIPage_Overview-SIOverview-CEBatchUpdate'
          />
          <Grid container direction='row' justifyContent='space-between'>
            <NewDesignedMain
              sx={{
                flexGrow: 1,
                // padding: '16px 0px 0px 20px',
                padding: '24px 25px 50px 12px',
                overflowX: 'hidden',
                '& .MuiTableContainer-root': {
                  '& .MuiTableCell-root.MuiTableCell-head, & .MuiTableCell-root.MuiTableCell-body':
                  {
                    paddingX: '24px',
                  },
                },
              }}
              open={open}
              filterOpen={newFilterOpen}
              drawerWidth='240px'
              filterDrawerWidth={filterDrawerWidth}
              data-selenium-id='SIPage_Overview-SIOverview-NewDesignedMain'
            >
              <Box
                sx={{ height: '27.5px', marginBottom: '3px !important' }}
                className='PageTitleNew'
              >
                <FormattedMessage
                  id='siPage.pageTitle'
                  data-selenium-id='SIPage_Overview-SIOverview-PageTitle'
                />
              </Box>
              <Stack direction='row' spacing={1.5}>
                <FilterPage
                  show={showFilter}
                  handleClose={() => {
                    setShowFilter(false);
                  }}
                  isCNmarketing={isCNmarketing}
                />
                <Box
                  sx={{
                    background: 'transparent',
                    width: showFilter ? 'calc(100% - 600px)' : '100%',
                  }}
                >
                  <Grid
                    id='SIPage_Overview-SIOverview-TopTools'
                    data-selenium-id='SIPage_Overview-SIOverview-TopTools'
                    container
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    sx={{
                      // backgroundColor:'#fff',
                      // alertbox 显示在button之上
                      zIndex: '2000',
                    }}
                  // spacing={1}
                  >
                    {isCNmarketing && (
                      <Grid
                        id='SIPage_Overview-SIOverview-TopTools'
                        data-selenium-id='SIPage_Overview-SIOverview-TopTools'
                        container
                        direction='row'
                        alignItems='center'
                        justifyContent='flex-start'
                        sx={{
                          marginBottom: '19px',
                          marginTop: '19px',
                          // backgroundColor:'#fff',
                          // zIndex: '2000', 这样设置会导致工具栏冒到ColumnDrawer上边
                        }}
                        spacing={1}
                      >
                        <Grid item>
                          {/* <Stack
                          direction='row'
                          alignItems='center'
                          spacing={1}
                          height={34}
                        > */}
                          <FilterButton
                            open={showFilter}
                            setOpen={setShowFilter}
                          />
                          {/* </Stack> */}
                        </Grid>
                        <div
                          style={{
                            width: '4px',
                          }}
                        />
                        {/* Columns */}
                        {showColumns.every(val => val) ? (
                          <Grid item>
                            <ResponsiveButton
                              id='option-button'
                              data-selenium-id='SIPage_Overview-SIOverview-OptionsButton'
                              downBy={downBy}
                              color='white'
                              variant='contained'
                              onClick={handleChangeListOpen}
                              startIcon={<ColumnsIconBlue />}
                              sx={{
                                boxSizing: 'border-box',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '8px 12px !important',
                                gap: '6px',
                                // width: '96px',
                                minWidth: '48px',
                                height: '34px',
                                /* Primary/500- */
                                border: '1px solid #0052CC',
                                borderRadius: '5px',

                                /* Caption / Semi Bold */
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '12px',
                                lineHeight: '18px',
                                /* identical to box height, or 150% */
                                textAlign: 'center',

                                /* Primary/500- */
                                color: '#0052CC',
                                flex: 'none',
                                order: 1,
                                flexGrow: 0,
                                '& .MuiButton-startIcon': {
                                  margin: 0,
                                },
                                '&:hover': {
                                  border: '1px solid #6D98EE',
                                  color: '#6D98EE',
                                  'svg path': {
                                    fill: '#6D98EE',
                                  },
                                },
                                '&:active': {
                                  background: '#E8EDF8',
                                  border: '1px solid #AEC6F6',
                                  color: '#6D98EE',
                                  'svg path': {
                                    fill: '#6D98EE',
                                  },
                                },
                              }}
                            >
                              <FormattedMessage id='siPage.columns' />
                            </ResponsiveButton>
                          </Grid>
                        ) : (
                          <Grid item>
                            <ResponsiveButton
                              id='option-button'
                              data-selenium-id='SIPage_Overview-SIOverview-OptionsButton'
                              downBy={downBy}
                              color='white'
                              variant='contained'
                              onClick={handleChangeListOpen}
                              startIcon={<ColumnsIconWhite />}
                              sx={{
                                boxSizing: 'border-box',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '8px 12px !important',
                                gap: '6px',
                                // width: '96px',
                                minWidth: '48px',
                                height: '34px',
                                /* Primary/500- */
                                borderRadius: '5px',
                                background: '#0052CC',
                                /* Caption / Semi Bold */
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '12px',
                                lineHeight: '18px',
                                /* identical to box height, or 150% */
                                textAlign: 'center',

                                /* Primary/500- */
                                color: '#FFFFFF',
                                flex: 'none',
                                order: 1,
                                flexGrow: 0,
                                '& .MuiButton-startIcon': {
                                  margin: 0,
                                },
                                '&:hover': {
                                  background: '#6D98EE',
                                },
                                '&:active': {
                                  background: '#113D95',
                                },
                              }}
                            >
                              <FormattedMessage id='siPage.columns' />
                            </ResponsiveButton>
                          </Grid>
                        )}
                        <Grid
                          item
                          style={{
                            alignSelf: 'flex-end',
                            paddingLeft: '12px',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          {/* TODO: add method */}
                          <CustomCheckBox
                            text=''
                            onClick={() => {
                              setWithCMOnly(!withCMOnly);
                            }}
                          />
                          <OnlyCMTypography
                            style={{
                              marginLeft: '8px',
                            }}
                          >
                            Show SIs with China Marketing section only
                          </OnlyCMTypography>
                        </Grid>
                        {/* Export */}
                        {selectedList.length === 0 ? (
                          <Grid
                            item
                            style={{
                              marginLeft: 'auto',
                            }}
                          >
                            <ShowForPermission permission={`${PERMISSIONS.CHINA_MARKETING_WRITE} ${PERMISSIONS.CHINA_MARKETING_READ}`}>
                              <ResponsiveButton
                                id='export-button'
                                data-selenium-id='SIPage_Overview-SIOverview-ExportButton'
                                downBy={downBy}
                                color='white'
                                variant='contained'
                                aria-controls={
                                  exportMenuOpen ? 'export-menu' : undefined
                                }
                                aria-haspopup='true'
                                aria-expanded={
                                  exportMenuOpen ? 'true' : undefined
                                }
                                onClick={handleCMExportMenuClick}
                                disabled={isDownloading}
                                startIcon={
                                  isDownloading ? (
                                    <RotatingSVG />
                                  ) : (
                                    <SIOverviewExportIconBlue />
                                  )
                                }
                                sx={{
                                  boxSizing: 'border-box',
                                  /* Auto layout */
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  padding: '8px 12px !important',
                                  gap: '6px',
                                  // width: '83px',
                                  minWidth: '48px',
                                  height: '34px',
                                  zIndex: '1200 !important',
                                  border: '1px solid #0052CC',
                                  borderRadius: '5px',
                                  fontFamily: 'Inter',
                                  fontStyle: 'normal',
                                  fontWeight: 600,
                                  fontSize: '12px',
                                  lineHeight: '18px',
                                  backgroundColor: isDownloading
                                    ? '#AEC6F6 !important'
                                    : '#FFFFFF',

                                  /* identical to box height, or 150% */
                                  textAlign: 'center',
                                  /* Primary/500- */
                                  color: '#0052CC',
                                  /* Inside auto layout */
                                  flex: 'none',
                                  order: 1,
                                  flexGrow: 0,
                                  '& .MuiButton-startIcon': {
                                    margin: 0,
                                  },
                                  '&:hover': {
                                    border: '1px solid #6D98EE',
                                    color: '#6D98EE',
                                    'svg path': {
                                      fill: '#6D98EE',
                                    },
                                  },
                                  '&:active': {
                                    background: '#E8EDF8',
                                    border: '1px solid #AEC6F6',
                                    color: '#6D98EE',
                                    'svg path': {
                                      fill: '#6D98EE',
                                    },
                                  },
                                }}
                              >
                                <CustomThinTooltip
                                  placement='top'
                                  title='Export Selected Item(s)'
                                  data-selenium-id='SI_Overview-ExportButton-Tooltip'
                                  disableInteractive
                                //open={true}
                                >
                                  <div>
                                    {formatMessage({ id: 'common.Export' })}
                                  </div>
                                </CustomThinTooltip>
                              </ResponsiveButton>
                              <Menu
                                id='export-menu'
                                data-selenium-id='CATJournal_Overview-ExportButton-Menu'
                                anchorEl={anchorEl}
                                open={exportMenuOpen}
                                MenuListProps={{
                                  'aria-labelledby': 'export-button',
                                }}
                                onClose={handleExportMenuClose}
                                sx={{
                                  '& .MuiPopover-paper': {
                                    marginTop: '6px',
                                    gap: '6px',
                                    boxShadow:
                                      '0px 2px 5px rgba(0, 0, 0, 0.25)',
                                    padding: '2px 0px 2px 0px',
                                    width: '98px',
                                  },
                                }}
                              >
                                <MenuItem
                                  disableRipple
                                  id='exportCSV'
                                  data-selenium-id='SI_Overview-ExportButton-ExportCSVMenuItem1'
                                  onClick={handleExportCsv}
                                  sx={{
                                    fontSize: '12px',
                                    lineHeight: '20px',
                                    color: '#596A7C',
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                    paddingLeft: '12px',
                                    '&:hover': {
                                      backgroundColor: '#F1F3F5',
                                      color: '#262E35',
                                    },
                                    '&:active': {
                                      backgroundColor: '#FFFFFF',
                                      color: '#262E35',
                                      fontWeight: 600,
                                    },
                                  }}
                                >
                                  Export CSV
                                  {/* {intl.messages['common.exportToCSV']} */}
                                </MenuItem>
                                <MenuItem
                                  disableRipple
                                  id='exportExcel'
                                  data-selenium-id='SI_Overview-ExportButton-ExportExcelMenuItem1'
                                  onClick={handleExportExcel}
                                  sx={{
                                    fontSize: '12px',
                                    lineHeight: '20px',
                                    color: '#596A7C',
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                    paddingLeft: '12px',
                                    '&:hover': {
                                      backgroundColor: '#F1F3F5',
                                      color: '#262E35',
                                    },
                                    '&:active': {
                                      backgroundColor: '#FFFFFF',
                                      color: '#262E35',
                                      fontWeight: 600,
                                    },
                                  }}
                                >
                                  Export EXCEL
                                  {/* {intl.messages['common.exportToEXCEL']} */}
                                </MenuItem>
                              </Menu>
                            </ShowForPermission>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            style={{
                              marginLeft: 'auto',
                            }}
                          >
                            <ShowForPermission permission={`${PERMISSIONS.CHINA_MARKETING_WRITE} ${PERMISSIONS.CHINA_MARKETING_READ}`}>
                              <ResponsiveButton
                                id='export-button'
                                data-selenium-id='SIPage_Overview-SIOverview-ExportButton'
                                downBy={downBy}
                                color='white'
                                variant='contained'
                                aria-controls={
                                  exportMenuOpen ? 'export-menu' : undefined
                                }
                                aria-haspopup='true'
                                aria-expanded={
                                  exportMenuOpen ? 'true' : undefined
                                }
                                onClick={handleExportMenuClick}
                                disabled={isDownloading}
                                startIcon={
                                  isDownloading ? (
                                    <RotatingSVG />
                                  ) : (
                                    <SIOverviewExportIconWhite />
                                  )
                                }
                                sx={{
                                  border: '1px solid #0052CC',
                                  /* Auto layout */
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  padding: '8px 12px !important',
                                  zIndex: '1200 !important',
                                  gap: '6px',
                                  // width: '83px',
                                  minWidth: '48px',
                                  height: '34px',
                                  backgroundColor: isDownloading
                                    ? '#AEC6F6 !important'
                                    : '#0052CC',
                                  borderRadius: '5px',
                                  fontFamily: 'Inter',
                                  fontStyle: 'normal',
                                  fontWeight: 600,
                                  fontSize: '12px',
                                  lineHeight: '18px',
                                  /* identical to box height, or 150% */
                                  textAlign: 'center',
                                  /* Primary/500- */
                                  color: '#FFFFFF',
                                  /* Inside auto layout */
                                  flex: 'none',
                                  order: 1,
                                  flexGrow: 0,
                                  '& .MuiButton-startIcon': {
                                    margin: 0,
                                  },
                                  '&:hover': {
                                    background: '#6D98EE',
                                    border: '1px solid #6D98EE',
                                  },
                                  '&:active': {
                                    background: '#113D95',
                                  },
                                }}
                              >
                                <CustomThinTooltip
                                  placement='top'
                                  title='Export Selected Item(s)'
                                  data-selenium-id='SI_Overview-ExportButton-Tooltip'
                                  disableInteractive
                                //open={true}
                                >
                                  <div>
                                    {formatMessage({ id: 'common.Export' })}
                                  </div>
                                </CustomThinTooltip>
                              </ResponsiveButton>
                              <Menu
                                id='export-menu'
                                data-selenium-id='CATJournal_Overview-ExportButton-Menu'
                                anchorEl={anchorEl}
                                open={exportMenuOpen}
                                MenuListProps={{
                                  'aria-labelledby': 'export-button',
                                }}
                                onClose={handleExportMenuClose}
                                sx={{
                                  '& .MuiPopover-paper': {
                                    marginTop: '6px',
                                    gap: '6px',
                                    boxShadow:
                                      '0px 2px 5px rgba(0, 0, 0, 0.25)',
                                    padding: '2px 0px 2px 0px',
                                    width: '98px',
                                  },
                                }}
                              >
                                <MenuItem
                                  disableRipple
                                  id='exportCSV'
                                  data-selenium-id='SI_Overview-ExportButton-ExportCSVMenuItem1'
                                  onClick={handleExportCsv}
                                  sx={{
                                    fontSize: '12px',
                                    lineHeight: '20px',
                                    color: '#596A7C',
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                    paddingLeft: '12px',
                                    '&:hover': {
                                      backgroundColor: '#F1F3F5',
                                      color: '#262E35',
                                    },
                                    '&:active': {
                                      backgroundColor: '#FFFFFF',
                                      color: '#262E35',
                                      fontWeight: 600,
                                    },
                                  }}
                                >
                                  Export CSV
                                  {/* {intl.messages['common.exportToCSV']} */}
                                </MenuItem>
                                <MenuItem
                                  disableRipple
                                  id='exportExcel'
                                  data-selenium-id='SI_Overview-ExportButton-ExportExcelMenuItem1'
                                  onClick={handleExportExcel}
                                  sx={{
                                    fontSize: '12px',
                                    lineHeight: '20px',
                                    color: '#596A7C',
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                    paddingLeft: '12px',
                                    '&:hover': {
                                      backgroundColor: '#F1F3F5',
                                      color: '#262E35',
                                    },
                                    '&:active': {
                                      backgroundColor: '#FFFFFF',
                                      color: '#262E35',
                                      fontWeight: 600,
                                    },
                                  }}
                                >
                                  Export EXCEL
                                  {/* {intl.messages['common.exportToEXCEL']} */}
                                </MenuItem>
                              </Menu>
                            </ShowForPermission>
                          </Grid>
                        )}
                      </Grid>
                    )}

                    {!isCNmarketing && (
                      <>
                        <Box
                          id='SIPage_Overview-SIOverview-TopTools'
                          data-selenium-id='SIPage_Overview-SIOverview-TopTools'
                          // container
                          // direction='row'
                          // alignItems='center'
                          // justifyContent='space-between'
                          // spacing={1}
                          sx={{
                            padding: '16px 0px 19px 0px',
                            height: '69px',
                            boxSizing: 'border-box',
                            width: '100%',
                            // backgroundColor:'#fff',
                            // zIndex: '1200 !important',
                          }}
                        >
                          <Box
                            sx={{ float: 'left' }}
                          // sx={{ p: "0px 6px 0px 0px" }}
                          >
                            {/* 搜索框filter */}
                            <Stack
                              direction='row'
                              // justifyContent={
                              //   requiresNewLine || isSM
                              //     ? 'space-between'
                              //     : 'flex-start'
                              // }
                              spacing={1.5}
                            >
                              <ShowForPermission
                                permission={`${PERMISSIONS.ADMIN_WRITE} ${PERMISSIONS.SI_READ_LIMITED} ${PERMISSIONS.SI_READ} ${PERMISSIONS.SI_WRITE}`}
                                data-selenium-id='SIPage_Overview-SIOverview-ShowForPermission'
                              >
                                <FilterButton
                                  open={showFilter}
                                  setOpen={setShowFilter}
                                />
                              </ShowForPermission>
                              {/* Columns */}
                              {showColumns.every(val => val) ? (
                                <ResponsiveButton
                                  id='option-button'
                                  data-selenium-id='SIPage_Overview-SIOverview-OptionsButton'
                                  downBy={downBy}
                                  color='white'
                                  variant='contained'
                                  onClick={handleChangeListOpen}
                                  startIcon={<ColumnsIconBlue />}
                                  sx={{
                                    boxSizing: 'border-box',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '8px 12px !important',
                                    gap: '6px',
                                    // width: '96px',
                                    minWidth: '48px',
                                    height: '34px',
                                    /* Primary/500- */
                                    border: '1px solid #0052CC',
                                    borderRadius: '5px',

                                    /* Caption / Semi Bold */
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    /* identical to box height, or 150% */
                                    textAlign: 'center',

                                    /* Primary/500- */
                                    color: '#0052CC',
                                    flex: 'none',
                                    order: 1,
                                    flexGrow: 0,
                                    '& .MuiButton-startIcon': {
                                      margin: 0,
                                    },
                                    '&:hover': {
                                      border: '1px solid #6D98EE',
                                      color: '#6D98EE',
                                      'svg path': {
                                        fill: '#6D98EE',
                                      },
                                    },
                                    '&:active': {
                                      background: '#E8EDF8',
                                      border: '1px solid #AEC6F6',
                                      color: '#6D98EE',
                                      'svg path': {
                                        fill: '#6D98EE',
                                      },
                                    },
                                  }}
                                >
                                  <FormattedMessage id='siPage.columns' />
                                </ResponsiveButton>
                              ) : (
                                <ResponsiveButton
                                  id='option-button'
                                  data-selenium-id='SIPage_Overview-SIOverview-OptionsButton'
                                  downBy={downBy}
                                  color='white'
                                  variant='contained'
                                  onClick={handleChangeListOpen}
                                  startIcon={<ColumnsIconWhite />}
                                  sx={{
                                    boxSizing: 'border-box',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '8px 12px !important',
                                    gap: '6px',
                                    // width: '96px',
                                    minWidth: '48px',
                                    height: '34px',
                                    /* Primary/500- */
                                    borderRadius: '5px',
                                    background: '#0052CC',
                                    /* Caption / Semi Bold */
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    /* identical to box height, or 150% */
                                    textAlign: 'center',

                                    /* Primary/500- */
                                    color: '#FFFFFF',
                                    flex: 'none',
                                    order: 1,
                                    flexGrow: 0,
                                    '& .MuiButton-startIcon': {
                                      margin: 0,
                                    },
                                    '&:hover': {
                                      background: '#6D98EE',
                                    },
                                    '&:active': {
                                      background: '#113D95',
                                    },
                                  }}
                                >
                                  <FormattedMessage id='siPage.columns' />
                                </ResponsiveButton>
                              )}
                            </Stack>
                          </Box>
                          <Box sx={{ float: 'right' }}>
                            <Stack direction='row' spacing={1.5}>
                              {/* Bulk Transfer */}
                              {selectedList.length === 0 ? (
                                <ShowForPermission permission={`${PERMISSIONS.ADMIN_WRITE}`}>
                                  <ResponsiveButton
                                    id='btnTransfer'
                                    data-selenium-id='SIPage_Overview-SIOverview-TransferButton'
                                    color='white'
                                    variant='contained'
                                    downBy={downBy}
                                    startIcon={<BulkTransferIcon />}
                                    onClick={handleTransferOpen}
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      padding: '8px 12px !important',
                                      gap: '6px',
                                      //position: 'absolute',
                                      // width: '121px',
                                      minWidth: '48px',
                                      height: '34px',
                                      // marginLeft: '60px',
                                      /* Primary/500- */
                                      border: '1px solid #0052CC',
                                      borderRadius: '5px',
                                      whiteSpace: 'nowrap',
                                      color: '#0052CC',
                                      fontFamily: 'Inter',
                                      fontStyle: 'normal',
                                      fontWeight: 600,
                                      fontSize: '12px',
                                      lineHeight: '18px',
                                      '& .MuiButton-startIcon': {
                                        margin: 0,
                                      },
                                      '&:hover': {
                                        border: '1px solid #6D98EE',
                                        color: '#6D98EE',
                                        'svg path': {
                                          fill: '#6D98EE',
                                        },
                                      },
                                      '&:active': {
                                        background: '#E8EDFB',
                                        border: '1px solid #AEC6F6',
                                        color: '#6D98EE',
                                        'svg path': {
                                          fill: '#6D98EE',
                                        },
                                      },
                                    }}
                                  >
                                    <FormattedMessage id='siPage.transfer' />
                                  </ResponsiveButton>
                                </ShowForPermission>
                              ) : (
                                <ShowForPermission permission={`${PERMISSIONS.ADMIN_WRITE}`}>
                                  <ResponsiveButton
                                    id='btnTransfer'
                                    data-selenium-id='SIPage_Overview-SIOverview-TransferButton'
                                    color='white'
                                    variant='contained'
                                    downBy={downBy}
                                    startIcon={<BulkTransferIconWhite />}
                                    onClick={handleTransferOpen}
                                    sx={{
                                      border: '1px solid #0052CC',
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      padding: '8px 12px !important',
                                      gap: '6px',
                                      //position: 'absolute',
                                      // width: '121px',
                                      minWidth: '48px',
                                      // marginLeft: '60px',
                                      background: '#0052CC',
                                      height: '34px',
                                      /* Primary/500- */
                                      borderRadius: '5px',
                                      whiteSpace: 'nowrap',
                                      color: '#FFFFFF',
                                      fontFamily: 'Inter',
                                      fontStyle: 'normal',
                                      fontWeight: 600,
                                      fontSize: '12px',
                                      lineHeight: '18px',
                                      '& .MuiButton-startIcon': {
                                        margin: 0,
                                      },
                                      '&:hover': {
                                        background: '#6D98EE',
                                      },
                                      '&:active': {
                                        background: '#113D95',
                                      },
                                    }}
                                  >
                                    <FormattedMessage id='siPage.transfer' />
                                  </ResponsiveButton>
                                </ShowForPermission>
                              )}
                              {/* Export */}
                              {selectedList.length === 0 ? (
                                <ShowForPermission permission={`${PERMISSIONS.ADMIN_WRITE} ${PERMISSIONS.SI_EXPORT}`}>
                                  <ResponsiveButton
                                    id='export-button'
                                    data-selenium-id='SIPage_Overview-SIOverview-ExportButton'
                                    downBy={downBy}
                                    color='white'
                                    variant='contained'
                                    aria-controls={
                                      exportMenuOpen ? 'export-menu' : undefined
                                    }
                                    aria-haspopup='true'
                                    aria-expanded={
                                      exportMenuOpen ? 'true' : undefined
                                    }
                                    onClick={handleExportMenuClick}
                                    disabled={isDownloading}
                                    startIcon={
                                      isDownloading ? (
                                        <RotatingSVG />
                                      ) : (
                                        <SIOverviewExportIconBlue />
                                      )
                                    }
                                    sx={{
                                      boxSizing: 'border-box',
                                      /* Auto layout */
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      padding: '8px 12px !important',

                                      gap: '6px',
                                      // width: '83px',
                                      backgroundColor: isDownloading
                                        ? '#AEC6F6 !important'
                                        : '#FFFFFF',
                                      zIndex: '1200 !important',
                                      minWidth: '48px',
                                      height: '34px',
                                      border: '1px solid #0052CC',
                                      borderRadius: '5px',
                                      fontFamily: 'Inter',
                                      fontStyle: 'normal',
                                      fontWeight: 600,
                                      fontSize: '12px',
                                      lineHeight: '18px',
                                      /* identical to box height, or 150% */
                                      textAlign: 'center',
                                      /* Primary/500- */
                                      color: '#0052CC',
                                      /* Inside auto layout */
                                      flex: 'none',
                                      order: 1,
                                      flexGrow: 0,
                                      '& .MuiButton-startIcon': {
                                        margin: 0,
                                      },
                                      '&:hover': {
                                        border: '1px solid #6D98EE',
                                        color: '#6D98EE',
                                        'svg path': {
                                          fill: '#6D98EE',
                                        },
                                      },
                                      '&:active': {
                                        background: '#E8EDF8',
                                        border: '1px solid #AEC6F6',
                                        color: '#6D98EE',
                                        'svg path': {
                                          fill: '#6D98EE',
                                        },
                                      },
                                    }}
                                  >
                                    <CustomThinTooltip
                                      placement='top'
                                      title='Export Selected Item(s)'
                                      data-selenium-id='SI_Overview-ExportButton-Tooltip'
                                      disableInteractive
                                    //open={true}
                                    >
                                      <div>
                                        {formatMessage({ id: 'common.Export' })}
                                      </div>
                                    </CustomThinTooltip>
                                  </ResponsiveButton>

                                  <Menu
                                    id='export-menu'
                                    data-selenium-id='CATJournal_Overview-ExportButton-Menu'
                                    anchorEl={anchorEl}
                                    open={exportMenuOpen}
                                    MenuListProps={{
                                      'aria-labelledby': 'export-button',
                                    }}
                                    onClose={handleExportMenuClose}
                                    sx={{
                                      '& .MuiPopover-paper': {
                                        marginTop: '6px',
                                        gap: '6px',
                                        boxShadow:
                                          '0px 2px 5px rgba(0, 0, 0, 0.25)',
                                        padding: '2px 0px 2px 0px',
                                        width: '98px',
                                      },
                                    }}
                                  >
                                    <MenuItem
                                      disableRipple
                                      id='exportCSV'
                                      data-selenium-id='SI_Overview-ExportButton-ExportCSVMenuItem1'
                                      onClick={handleExportCsv}
                                      sx={{
                                        fontSize: '12px',
                                        lineHeight: '20px',
                                        color: '#596A7C',
                                        paddingTop: '2px',
                                        paddingBottom: '2px',
                                        paddingLeft: '12px',
                                        '&:hover': {
                                          backgroundColor: '#F1F3F5',
                                          color: '#262E35',
                                        },
                                        '&:active': {
                                          backgroundColor: '#FFFFFF',
                                          color: '#262E35',
                                          fontWeight: 600,
                                        },
                                      }}
                                    >
                                      Export CSV
                                      {/* {intl.messages['common.exportToCSV']} */}
                                    </MenuItem>
                                    <MenuItem
                                      disableRipple
                                      id='exportExcel'
                                      data-selenium-id='SI_Overview-ExportButton-ExportExcelMenuItem1'
                                      onClick={handleExportExcel}
                                      sx={{
                                        fontSize: '12px',
                                        lineHeight: '20px',
                                        color: '#596A7C',
                                        paddingTop: '2px',
                                        paddingBottom: '2px',
                                        paddingLeft: '12px',
                                        '&:hover': {
                                          backgroundColor: '#F1F3F5',
                                          color: '#262E35',
                                        },
                                        '&:active': {
                                          backgroundColor: '#FFFFFF',
                                          color: '#262E35',
                                          fontWeight: 600,
                                        },
                                      }}
                                    >
                                      Export EXCEL
                                      {/* {intl.messages['common.exportToEXCEL']} */}
                                    </MenuItem>
                                  </Menu>
                                </ShowForPermission>
                              ) : (
                                <ShowForPermission permission={`${PERMISSIONS.ADMIN_WRITE} ${PERMISSIONS.SI_EXPORT}`}>
                                  <ResponsiveButton
                                    id='export-button'
                                    data-selenium-id='SIPage_Overview-SIOverview-ExportButton'
                                    downBy={downBy}
                                    color='white'
                                    variant='contained'
                                    aria-controls={
                                      exportMenuOpen ? 'export-menu' : undefined
                                    }
                                    aria-haspopup='true'
                                    aria-expanded={
                                      exportMenuOpen ? 'true' : undefined
                                    }
                                    onClick={handleExportMenuClick}
                                    disabled={isDownloading}
                                    startIcon={
                                      isDownloading ? (
                                        <RotatingSVG />
                                      ) : (
                                        <SIOverviewExportIconWhite />
                                      )
                                    }
                                    sx={{
                                      border: '1px solid #0052CC',
                                      /* Auto layout */
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      padding: '8px 12px !important',
                                      gap: '6px',
                                      // width: '83px',
                                      minWidth: '48px',
                                      height: '34px',
                                      backgroundColor: isDownloading
                                        ? '#AEC6F6 !important'
                                        : '#0052CC',
                                      borderRadius: '5px',
                                      fontFamily: 'Inter',
                                      fontStyle: 'normal',
                                      fontWeight: 600,
                                      fontSize: '12px',
                                      lineHeight: '18px',
                                      zIndex: '1200 !important',
                                      /* identical to box height, or 150% */
                                      textAlign: 'center',
                                      /* Primary/500- */
                                      color: '#FFFFFF',
                                      /* Inside auto layout */
                                      flex: 'none',
                                      order: 1,
                                      flexGrow: 0,
                                      '& .MuiButton-startIcon': {
                                        margin: 0,
                                      },
                                      '&:hover': {
                                        background: '#6D98EE',
                                      },
                                      '&:active': {
                                        background: '#113D95',
                                      },
                                    }}
                                  >
                                    <CustomThinTooltip
                                      placement='top'
                                      title='Export Selected Item(s)'
                                      data-selenium-id='SI_Overview-ExportButton-Tooltip'
                                      disableInteractive
                                    //open={true}
                                    >
                                      <div>
                                        {formatMessage({ id: 'common.Export' })}
                                      </div>
                                    </CustomThinTooltip>
                                  </ResponsiveButton>
                                  <Menu
                                    id='export-menu'
                                    data-selenium-id='CATJournal_Overview-ExportButton-Menu'
                                    anchorEl={anchorEl}
                                    open={exportMenuOpen}
                                    MenuListProps={{
                                      'aria-labelledby': 'export-button',
                                    }}
                                    onClose={handleExportMenuClose}
                                    sx={{
                                      '& .MuiPopover-paper': {
                                        marginTop: '6px',
                                        gap: '6px',
                                        boxShadow:
                                          '0px 2px 5px rgba(0, 0, 0, 0.25)',
                                        padding: '2px 0px 2px 0px',
                                        width: '98px',
                                      },
                                    }}
                                  >
                                    <MenuItem
                                      disableRipple
                                      id='exportCSV'
                                      data-selenium-id='SI_Overview-ExportButton-ExportCSVMenuItem1'
                                      onClick={handleExportCsv}
                                      sx={{
                                        fontSize: '12px',
                                        lineHeight: '20px',
                                        color: '#596A7C',
                                        paddingTop: '2px',
                                        paddingBottom: '2px',
                                        paddingLeft: '12px',
                                        '&:hover': {
                                          backgroundColor: '#F1F3F5',
                                          color: '#262E35',
                                        },
                                        '&:active': {
                                          backgroundColor: '#FFFFFF',
                                          color: '#262E35',
                                          fontWeight: 600,
                                        },
                                      }}
                                    >
                                      Export CSV
                                      {/* {intl.messages['common.exportToCSV']} */}
                                    </MenuItem>
                                    <MenuItem
                                      disableRipple
                                      id='exportExcel'
                                      data-selenium-id='SI_Overview-ExportButton-ExportExcelMenuItem1'
                                      onClick={handleExportExcel}
                                      sx={{
                                        fontSize: '12px',
                                        lineHeight: '20px',
                                        color: '#596A7C',
                                        paddingTop: '2px',
                                        paddingBottom: '2px',
                                        paddingLeft: '12px',
                                        '&:hover': {
                                          backgroundColor: '#F1F3F5',
                                          color: '#262E35',
                                        },
                                        '&:active': {
                                          backgroundColor: '#FFFFFF',
                                          color: '#262E35',
                                          fontWeight: 600,
                                        },
                                      }}
                                    >
                                      Export EXCEL
                                      {/* {intl.messages['common.exportToEXCEL']} */}
                                    </MenuItem>
                                  </Menu>
                                </ShowForPermission>
                              )}
                              {/* Create */}
                              {/* <ShowForPermission permission={`si:write`}>
                                <ResponsiveButton
                                  id='btnCreateSI'
                                  data-selenium-id='SIPage_Overview-SIOverview-CreateButton'
                                  downBy={downBy}
                                  color='wileyBlue2'
                                  variant='contained'
                                  // className="si-button si-button-right"
                                  onClick={handleFirstOpen}
                                  startIcon={<AddIconWhite />}
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '8px 12px !important',
                                    gap: '6px',
                                    // width: '84px',
                                    minWidth: '48px',
                                    height: '34px',
                                    background: '#0052CC',
                                    borderRadius: '5px',
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    textAlign: 'center',
                                    color: '#FFFFFF',
                                    flex: 'none',
                                    order: 1,
                                    flexGrow: 0,
                                    '& .MuiButton-startIcon': {
                                      margin: 0,
                                    },
                                    '&:hover': {
                                      background: '#6D98EE',
                                    },
                                    '&:active': {
                                      background: '#113D95',
                                    },
                                  }}
                                >
                                  <FormattedMessage id='common.create' />
                                </ResponsiveButton>
                              </ShowForPermission> */}
                            </Stack>
                          </Box>
                          {/*
                Zhu:
                这段布局的逻辑比较复杂，留个备注以防忘记：
                  首先是`downBy`，当屏幕宽度减去左右drawer的【实际占用宽度】之后剩余的空间如果不够摆下这些按钮和搜索框的话，
                    就先用这个变量把`ResponsiveButton`给变得只剩下图标。
                    由于变成了图标之后，右侧4-5个按钮的实际占用空间变小了，
                    所以引入变量`buttonDown`，当屏幕小到按钮已经down了的时候，把按钮占用的格子数量从6降到5，
                    然后利用上面230行附近的md不指定格子数量来自动调整左侧搜索框的宽度；
                  然后，如果屏幕进一步小到down之后的按钮也摆不下了，就考虑换行，
                    引入`requiresNewLine`变量，该变量==true的时候，
                    搜索框所在的Grid以及按钮所在的Grid占用格子数量都变成12
                    此后一直维持不变
                  注：需要通过媒体查询实现屏幕宽度减去左右drawer的宽度
              */}
                          {/* <Grid
                        item
                        container
                        wrap='nowrap'
                        xs={12}
                        sm={12}
                        md={6}
                        {...(buttonDown && {
                          md: 5.5,
                        })}
                        {...(requiresNewLine && {
                          md: 12,
                        })}
                        // md={requiresNewLine ? 12 : 6}
                        justifyContent='flex-end'
                        columnSpacing={'12px'}
                        alignItems='center'
                        sx={{
                          p: '0px 0px 0px 16px',
                          marginLeft: '30px',
                          mt: requiresNewLine ? 2 : 0,
                        }}
                      ></Grid> */}
                        </Box>
                      </>
                    )}
                    {/* <ExportSIPage
                      open={exportDialogOpen}
                      handleExport={t => {
                        if (t === 'csv') {
                          handleExportCsv(isCNmarketing);
                        } else if (t === 'xls') {
                          handleExportExcel(isCNmarketing);
                        }
                      }}
                      handleClose={() => {
                        setExportDialogOpen(false);
                      }}
                    /> */}
                    <div
                      className='FormAll'
                      style={{
                        height: `${visibleHeight - 298}px`,
                        width: '100%',
                      }}
                    >
                      <IssueTable
                        ref={contextHolderRef}
                        showFilter={showFilter}
                        showColumns={showColumns}
                        setShowColumns={setShowColumns}
                        changeListOpen={changeListOpen}
                        setChangeListOpen={setChangeListOpen}
                        withCMOnly={withCMOnly}
                        data-selenium-id='SIPage_Overview-SIOverview-IssueTable'
                      />
                    </div>
                    <ExportAllSIPage
                      open={exportallPageOpen}
                      //open={true}
                      handleClose={handleExportAllPageClose}
                      data-selenium-id='SIPage_Overview-SIOverview-ExportAllPage'
                    />
                    <AlertBox
                      open={alertOpen}
                      severity='warning'
                      title='Warning'
                      message='Please select at least one item to export.'
                      autoHideDuration={3000}
                      handleClose={() => {
                        setAlertOpen(false);
                      }}
                    />
                  </Grid>
                </Box>
              </Stack>

              {/*<ScrollBarX contextHolderRef={contextHolderRef}/>*/}
            </NewDesignedMain>
            {/*<ScrollBarY contextHolderRef={contextHolderRef} />*/}
          </Grid>
        </StyledEngineProvider>
      </WindowHeightContext.Provider>
      <AlertBox
        open={transferMsgOpen}
        handleClose={() => {
          setTransferMsgOpen(false);
        }}
        severity='warning'
        autoHideDuration={3000}
        title='Warning'
        message='Please select at least one SI to Bulk Transfer.'
      />
      {/* <NewSIFilterDrawer></NewSIFilterDrawer> */}
      {/*
      <AlertBox
        open={transferMsgOpen}
        handleClose={() => {
          setTransferMsgOpen(false);
          setAnchorEl(null);
        }}
        severity='warning'
        autoHideDuration={4000}
        title='Warning'
        message='Please select at least journal to Bulk Transfer.'
      />
      <AlertBox
        open={exportMsgOpen}
        handleClose={() => {
          setExportMsgOpen(false);
          setAnchorEl(null);
        }}
        severity='warning'
        autoHideDuration={4000}
        title='Warning'
        message='Please select only one journal to export.'
      />
      <AlertBox
        open={exportAllMsgOpen}
        handleClose={() => {
          dispatch({ type: EXPORT_SI_ERROR, data: false });
          setAnchorEl(null);
        }}
        severity='warning'
        autoHideDuration={4000}
        title='Warning'
        message={exportAllMsgString}
      /> */}
      <UntitledAlertBox
        open={aiAcquisitionCreateSuccessAlert}
        handleClose={() => {
          dispatch(setAIAcquisitionCreateSuccessAlert(false, 1));
        }}
        message={`You Created a Joint SI across ${aiAcquisitionCreateSuccessSum} journals successfully`}
        severity='success'
        autoHideDuration={3000}
      />
    </>
  );
}

export default SIOverview;

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

//@ts-check
import { Box, Stack, Typography } from '@mui/material';
import WiderMain from '@/components/WiderMain';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IntergrityChecks from './sections/integrityChecks';
import JournalScopeCheck from './sections/journalScopeCheck';
import JournalRecommendations from './sections/journalRecommendations';
import GEBackgroundCheck from './sections/GEBackgroundCheck';
import AIScreeningReportTitle from './components/AIScreeningReportTitle';
import styleModule from './components/AIScreeningReportPage.module.scss';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import {getSIPInformation} from "@/actions/SIMT-SIP/SIP/SipDetail";

export default function AIScreeningReportPage() {
  const { sipCode } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSIPInformation(sipCode));
  }, []);

  const titleStyle = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '24px',
    color: '#262E35',
    minHeight: '44px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    display: 'flex',
  };

  function MyBreadCrums() {
    return (
      <Breadcrumbs
        aria-label='breadcrumb'
        sx={{
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <Typography
          sx={{
            height: '20px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#596A7C',
          }}
        >
          Integrity Screening Report - {sipCode}
        </Typography>
      </Breadcrumbs>
    );
  }
  const outerQueryMedia = (() => {
    const SeparationWidth = 1440;
    const paddingLeft = 32;
    const commonPaddingRight = { paddingRight: `${paddingLeft - 10}px` }; //滚动条宽度是10px
    return {
      paddingRight: '14px', //滚动条宽度是10px
      [`@media screen and (min-width: 1080px) and (max-width: ${SeparationWidth}px)`]:
        {
          paddingLeft: `${paddingLeft}px`,
          ...commonPaddingRight,
        },
      // paddingX: '32px',
      [`@media screen and (min-width: ${SeparationWidth}px)`]: {
        paddingLeft: '104px',
        ...commonPaddingRight,
      },
      maxWidth: '1430px', //1304+104+(32-10)
      minWidth: '710px', //672+24+14
    };
  })();
  const innerQueryMedia = (p = '', SeparationWidth = 0) => {
    // const SeparationWidth=720;
    return {
      // paddingRight: '24px',
      // [`@media screen and (max-width: ${SeparationWidth}px)`]:{
      //   paddingX: p1, //小于SeparationWidth
      // },
      // and (max-width: ${2440}px)
      '&>div': {
        [`@media screen and (min-width: ${SeparationWidth}px)`]: {
          paddingX: '32px', //大于SeparationWidth
        },
        [`@media screen and (max-width: ${SeparationWidth}px)`]: {
          paddingX: p, //小于等于SeparationWidth
        },
      },
      // [`@media screen and (width: ${SeparationWidth}px)`]:{
      //   paddingX: p3, //等于SeparationWidth
      // },
    };
  };
  return (
    <Box
      id='aiScreeningReportPageBody'
      data-selenium-id='SIPPage_AIScreeningReportPage-Index-Body'
      sx={{
        width: '100%',
        minWidth: '720px',
      }}
    >
      {/* Title */}
      <WiderMain
        data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain1'
        sx={{
          paddingX: '24px',
          paddingY: 0,
          margin: 0,
          boxSizing: 'border-box',
          backgroundColor: 'transparent',
          width: 'auto',
          '& *': {
            boxSizing: 'border-box',
          },
          ...outerQueryMedia,
        }}
        height='100%'
        marginTop='4px'
      >
        <MyBreadCrums data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain1-MyBreadCrums' />
        <Box
          data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain1-Box'
          // paddingX='32px'
          sx={{
            backgroundColor: '#fff',
            flexDirection: 'column',
            ...innerQueryMedia('12px', 1080),
          }}
          style={titleStyle}
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            id='aiScreeningReportPageStack'
            data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain1-TitleBox'
            alignItems='center'
          >
            <AIScreeningReportTitle sipCode={sipCode ?? ''} />
          </Stack>
        </Box>
      </WiderMain>
      {/* Main Sections Container */}
      <WiderMain
        data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2'
        sx={{
          paddingX: '24px',
          paddingTop: '4px',
          paddingBottom: '32px',
          margin: 0,
          boxSizing: 'border-box',
          backgroundColor: 'transparent',
          width: 'auto',
          '& *': {
            boxSizing: 'border-box',
          },
          minHeight: 0,
          ...outerQueryMedia,
        }}
      >
        {/* Main Sections Container */}
        <Box
          // padding='30px 0px 32px 32px'
          gap='32px'
          sx={{
            backgroundColor: '#fff',
            '& > *': {
              // width: '1094px',
              maxWidth: '100%',
              overflowX: 'auto',
            },
            paddingTop: '30px',
            paddingBottom: '32px',
            ...innerQueryMedia('24px', 720),
          }}
          data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Box'
        >
          {/* Need to completed for AI screening report page */}
          <Stack
            gap='32px'
            data-selenium-id='SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack'
            className={
              styleModule[
                'SIPPage_AIScreeningReportPage-Index-WiderMain2-Stack'
              ]
            }
          >
            <IntergrityChecks sipCode={sipCode ?? ''}></IntergrityChecks>
            <JournalScopeCheck sipCode={sipCode ?? ''}></JournalScopeCheck>
            <JournalRecommendations
              sipCode={sipCode ?? ''}
            ></JournalRecommendations>
            <GEBackgroundCheck sipCode={sipCode}></GEBackgroundCheck>
          </Stack>
        </Box>
      </WiderMain>
    </Box>
  );
}

import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  waitingApprovalMessageChange,
  waitingRejectMessageChange,
} from '@/actions/SIMT-Report/ReportJobsSubmission/ReportJobsAction';
import NewSubmissionTable from './NewSubmissionTable';
import {getNewSubmissionInfo} from "@/actions/SIMT-Report/ReportJobsSubmission";

function NewSubmission() {
  const dispatch = useDispatch();
  const selector = state => {
    return {
      newSubmissionList: state.ReportJobs.newSubmissionList,
    };
  };

  const { newSubmissionList } = useSelector(selector);

  useEffect(() => {
    async function getData() {
      unstable_batchedUpdates(() => {
        dispatch(getNewSubmissionInfo());
      });
    }
    getData();
    dispatch(waitingRejectMessageChange(false));
    dispatch(waitingApprovalMessageChange(false));
  }, []);

  const [orderType, setOrderType] = useState('submittedTime');
  const [sortKey, setSortKey] = useState('desc');
  return (
    <Box
      sx={{ marginTop: '10px' }}
      data-selenium-id='SystemAdmin_ReportJobs-NewSubmission-Box'
    >
      <NewSubmissionTable
        data-selenium-id='SystemAdmin_ReportJobs-NewSubmission-Box-NewSubmissionTable'
        sortKey={sortKey}
        setSortKey={setSortKey}
        orderType={orderType}
        setOrderType={setOrderType}
        newSubmissionList={newSubmissionList}
      />
    </Box>
  );
}

export default NewSubmission;

import {
  Button,
  Popover,
  ButtonGroup,
  buttonClasses,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { responsive_button } from './css';
import DropdownIcon from '@/assets/dropdownIcon.svg';
import DropdownIconNew from '../../../assets/DropdownNewIcon.svg';
import DropdownIconNewHover from '../../../assets/DropdownNewIconHover.svg';
import HeaderArrowIcon from '@/assets/HeaderArrowIcon.svg';
import HeaderArrowIconNew from '../../../assets/HeaderArrowIconNew.svg';
import ExportAllIconWhite from '@/assets/exportAllIconWhite.svg';
import SIOverviewExportIcon from '@/assets/SIOverviewExportIcon.svg';
import SIOverviewExportIconNew from '../../../assets/StreamNew.svg';
import ExportDownloading from '../../../assets/ExportDownloading.svg';
import SIOverviewExportIconNewHover from '../../../assets/SIOverviewExportIconNewHover.svg';
import SIOverviewExportAll from '../../../assets/SIOverviewExportAll.svg';
import SIOverviewExportIconWhite from '@/assets/SIOverviewExportIconWhite.svg';
import ExportAllIcon from '@/assets/exportAllIcon.svg';
import ExportAllIconNew from '../../../assets/ExportAllIconNew.svg';
import OptInIcon from '@/assets/OptInIcon.svg';
import OptOutIcon from '@/assets/OptOutIcon.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { ResponsiveButton } from '../../../components/StyledButton/SIMTButton';
import { setSnackbarMessageAndOpen } from '@/actions/SnackbarAction';
import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';
import { CustomTooltip, CustomThinTooltip} from '@/components/CustomTooltip';
import RotatingSVG from '@/componentsphase2/RotatingSVG';
import {downloadCAT, exportCatJournalCSV, exportCatJournalExcel} from "@/actions/SIMT-SI/CatJournal";

export const CATJournalExport = props => {
  const { downBy } = props;
  const intl = useIntl();
  const selector = state => {
    return {
      selectedList: state.CATJournal.selectedList,
    };
  };
  const dispatch = useDispatch();

  const { selectedList } = useSelector(selector);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [exportMsgOpen, setExportMsgOpen] = React.useState(false);
  const exportMenuOpen = Boolean(anchorEl);
  const [hasSelector, setHasSelector] = useState(false);
  const [isDownloading, setisDownloading] = useState(false);
  const { formatMessage } = useIntl();
  const handlePopoverClose = () => {
    setExportMsgOpen(false);
    setAnchorEl(null);
  };
  const handleButtonClick = event => {
    if (selectedList.length === 0) {
      dispatch(
        setSnackbarMessageAndOpen(
          'catJournal.noSelectionExport',
          {},
          SEVERITIES.warning
        )
      );
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleExportMenuClose = () => {
    setAnchorEl(null);
  };
  const handleExportExcel = () => {
    setisDownloading(true);
    async function fetchData() {
      await dispatch(exportCatJournalExcel(selectedList));
      await delay(500);
      setisDownloading(false);
    }

    fetchData();
    setAnchorEl(null);
  };

  const handleExportCsv = () => {
    setisDownloading(true);
    async function fetchData() {
      await dispatch(exportCatJournalCSV(selectedList));
      await delay(500);
      setisDownloading(false);
    }

    fetchData();
    setAnchorEl(null);
  };
  useEffect(() => {
    setHasSelector(selectedList.length > 0);
  }, [selectedList]);
  return (
    <>
      <ResponsiveButton
        downBy={downBy}
        id='CatJournalExport'
        disabled={isDownloading}
        data-selenium-id='CATJournal_Overview-ExportButton-ResponsiveButton'
        color='white'
        variant='contained'
        aria-haspopup='true'
        onClick={handleButtonClick}
        startIcon={
          isDownloading?(<RotatingSVG/>)
            :
            (hasSelector ? <SIOverviewExportIconWhite /> :
              <SIOverviewExportIconNew />)
        }
        // endIcon={hasSelector ? <HeaderArrowIconNew /> : <DropdownIconNew />}
        sx={{
          minWidth: 'auto',
          border: hasSelector ? 'none' : '1px solid #0052CC',
          height: '34px',
          whiteSpace: 'nowrap',
          color: hasSelector ? '#FFFFFF' : '#0052CC',
          backgroundColor: isDownloading? '#AEC6F6 !important' : (hasSelector ? '#0052CC' : '#FFFFFF'),
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '18px',
          '&:hover': {
            color: hasSelector ? '#FFFFFF' : '#6D98EE',
            background: hasSelector ? '#6d98ee' : '#FFFFFF',
            'svg path': {
              fill: hasSelector ? null: '#6D98EE',
            },
          },
          '&:active': {
            color: hasSelector ? '#FFFFFF' : '#6D98EE',
            background: hasSelector ? '#113d95' : '#e8edfb',
            'svg path': {
              fill: hasSelector ? null : '#6D98EE',
            },
          },
        }}
      >
        <CustomThinTooltip
          placement='top'
          title='Export Selected Item(s)'
          data-selenium-id='CATJournal_Overview-ExportButton-Tooltip'
          disableinteractive
        //open={true}
        >
          <div>
            {formatMessage({ id: 'common.Export' })}
          </div>
        </CustomThinTooltip >
        
      </ResponsiveButton>
      {/* <Popover
        open={exportMsgOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>
          <FormattedMessage id='catJournal.noSelectionExport' />
        </Typography>
      </Popover> */}


      
      {/*Export的menu*/}
      <Menu
        id='export-menu'
        data-selenium-id='CATJournal_Overview-ExportButton-Menu'
        anchorEl={anchorEl}
        open={exportMenuOpen}
        MenuListProps={{
          'aria-labelledby': 'export-button',
        }}
        onClose={handleExportMenuClose}
        sx={{
          '& .MuiPopover-paper': {
            marginTop: '6px',
            gap: '6px',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
            padding: '2px 0px 2px 0px',
            width: '98px',
          },
        }}
      >
        <MenuItem disableRipple
          id='exportCSV'
          data-selenium-id='CATJournal_Overview-ExportButton-ExportCSVMenuItem1'
          onClick={handleExportCsv}
          sx={{
            fontSize: '12px',
            lineHeight: '20px',
            color: '#596A7C',
            paddingTop: '2px',
            paddingBottom: '2px',
            paddingLeft: '12px',
            '&:hover': {
              backgroundColor: '#F1F3F5',
              color: '#262E35',
            },
            '&:active': {
              backgroundColor: '#FFFFFF',
              color: '#262E35',
              fontWeight: 600,
            }
          }}
        >
          Export CSV
          {/* {intl.messages['common.exportToCSV']} */}
        </MenuItem>
        <MenuItem disableRipple
          id='exportExcel'
          data-selenium-id='CATJournal_Overview-ExportButton-ExportExcelMenuItem1'
          onClick={handleExportExcel}
          sx={{
            fontSize: '12px',
            lineHeight: '20px',
            color: '#596A7C',
            paddingTop: '2px',
            paddingBottom: '2px',
            paddingLeft: '12px',
            '&:hover': {
              backgroundColor: '#F1F3F5',
              color: '#262E35',
            },
            '&:active': {
              backgroundColor: '#FFFFFF',
              color: '#262E35',
              fontWeight: 600,
            }
          }}
        >
          Export EXCEL
          {/* {intl.messages['common.exportToEXCEL']} */}
        </MenuItem>
      </Menu>
      
    </>
  );
};

export const CATJournalOptIn = () => {
  const handleButtonClick = () => { };
  const { formatMessage } = useIntl();
  return (
    <>
      <ResponsiveButton
        //downBy={downBy}
        id='CatJournalOptInButton'
        data-selenium-id='CATJournal_Overview-ExportButton-OptInResponsiveButton'
        color='white'
        variant='contained'
        aria-haspopup='true'
        onClick={handleButtonClick}
        startIcon={<OptInIcon />}
        sx={{
          ...responsive_button,
          [`&.${buttonClasses.root}`]: {
            textTransform: 'none',
            boxShadow: 'none',
            borderRadius: '5px',
            fontWeight: 600,
            padding: '6px 12px',
          },
        }}
      >
        {formatMessage({ id: 'catJournal.optIn' })}
      </ResponsiveButton>
    </>
  );
};

export const CATJournalOptOut = () => {
  const handleButtonClick = () => { };
  const { formatMessage } = useIntl();
  return (
    <>
      <ResponsiveButton
        //downBy={downBy}
        id='CatJournalOptOutButton'
        data-selenium-id='CATJournal_Overview-ExportButton-OptOutResponsiveButton'
        color='white'
        variant='contained'
        aria-haspopup='true'
        onClick={handleButtonClick}
        startIcon={<OptOutIcon />}
        sx={{
          ...responsive_button,
          [`&.${buttonClasses.root}`]: {
            textTransform: 'none',
            boxShadow: 'none',
            borderRadius: '5px',
            fontWeight: 600,
            padding: '6px 12px',
          },
        }}
      >
        {formatMessage({ id: 'catJournal.optOut' })}
      </ResponsiveButton>
    </>
  );
};

export const CATJournalExportAll = props => {
  const { downBy } = props;
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const [enter, setEnter] = useState(false);
  const selector = state => {
    return {
      open: state.UserManage.siderOpen,
      searchCATContent: state.CATJournal.searchCATContent,
    };
  };

  const { open, searchCATContent } = useSelector(selector);
  /**
   * 用于export popover的打开
   * @param {*} event
   */
  const handlePopClick = event => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * 用于export popover的关闭
   */
  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const exportOpen = Boolean(anchorEl); //检测export popover打开的变量
  const exportId = open ? 'cat-journal-overview-export-popover' : undefined; //popover ID
  return (
    <>
      
      <ResponsiveButton
        downBy={downBy}
        id='CatJournalExportAll'
        data-selenium-id='CATJournal_Overview-ExportButton-CustomTooltip-ExportAllResponsiveButton'
        color='white'
        variant='contained'
        aria-haspopup='true'
        onClick={handlePopClick}
        onMouseEnter={()=>setEnter(true)}
        onMouseLeave={()=>setEnter(false)}
        startIcon={<ExportAllIconNew />}
        /*startIcon={exportOpen ? <ExportAllIconWhite /> : <ExportAllIcon />}*/
        endIcon={<HeaderArrowIconNew />}
        /*sx={{
          minWidth: 'auto',
          border: exportOpen ? 'none' : '1px solid #DFE4E8',
          height: '32px',
          whiteSpace: 'nowrap',
          color: exportOpen ? '#FFFFFF' : '#596A7C',
          backgroundColor: exportOpen ? '#154AB6' : '#FFFFFF',
        }}*/
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          //padding: '8px 12px !important',
          gap: '6px',
          //position: 'absolute',
          // width: '121px',
          minWidth: '48px',
          // marginLeft: '60px',
          background: '#0052CC',
          height: '34px',
          width: '120px',
          /* Primary/500- */
          borderRadius: '5px',
          whiteSpace: 'nowrap',
          color: '#FFFFFF',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '18px',
          '& .MuiButton-startIcon': {
            margin: 0,
          },
          '& .MuiButton-endIcon': {
            margin: 0,
          },
          '&:hover': {
            background: '#6D98EE',
          },
          '&:active': {
            background: '#113D95',
          },
        }}

      >
        <CustomTooltip
        placement='top'
        title='Export journal in a period of time'
        data-selenium-id='CATJournal_Overview-ExportButton-CustomTooltip'
        disableInteractive
        //open={true}
      >
        <div>
        {formatMessage({ id: 'common.exportOGAll' })}
        </div>
        </CustomTooltip >
      </ResponsiveButton>
    
      {/*Export的menu*/ }
      < Menu
  id = { exportId }
  data-selenium-id={ exportId }
  anchorEl = { anchorEl }
  open = { exportOpen }
  MenuListProps = {{
    'aria-labelledby': 'export-button',
        }
}
onClose = { handlePopClose }
sx = {{
  '& .MuiPopover-paper': {
    marginTop: '6px',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
        padding: '2px 0px 2px 0px',
          width: '120px',
          },
}}
      >
        <MenuItem disableRipple
          id='exportCSV'
          data-selenium-id='CATJournal_Overview-ExportButton-ExportCSVMenuItem2'
          onClick={() => {
            dispatch(
              downloadCAT({
                exportType: 'csv',
                keywords: searchCATContent,
              })
            );
            handlePopClose();
          }}
          sx={{
            fontSize: '12px',
            lineHeight: '20px',
            paddingTop: '2px',
            paddingBottom: '2px',
            paddingLeft: '22px',
            color: '596A7C',
            '&:hover': {
              backgroundColor: '#F1F3F5',
              color: '#262E35',
            },
            '&:active': {
              backgroundColor: '#FFFFFF',
              color: '#262E35',
              fontWeight: 600,
            }
          }}
        >
          {formatMessage({ id: 'common.exportToCSV' })}
        </MenuItem>
        <MenuItem disableRipple
          id='exportExcel'
          data-selenium-id='CATJournal_Overview-ExportButton-ExportExcelMenuItem2'
          onClick={() => {
            dispatch(downloadCAT({ keywords: searchCATContent }));
            handlePopClose();
          }}
          sx={{
            fontSize: '12px',
            lineHeight: '20px',
            paddingTop: '2px',
            paddingBottom: '2px',
            paddingLeft: '18px',
            color: '596A7C',
            '&:hover': {
              backgroundColor: '#F1F3F5',
              color: '#262E35',
            },
            '&:active': {
              backgroundColor: '#FFFFFF',
              color: '#262E35',
              fontWeight: 600,
            }
          }}
        >
          {formatMessage({ id: 'common.exportToEXCEL' })}
        </MenuItem>
      </Menu >
    </>
  );
};

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

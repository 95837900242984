/**
 * chart group names & chart level names
 */
export const SPECIAL_ISSUE_SI_PIPELINE = 'Special Issue (SI) Pipeline';
export const SPECIAL_ISSUE_SI_PIPELINE_OA =
  'Special Issue (SI) Pipeline for OA Journals and All Journals';
export const SUBJECT_GROUP = 'Subject Group';
export const BUSINESS_DIVISION = 'Business Division';
export const CE_LEVEL = 'CE';
export const CAT_GROUP_LEVEL = 'CAT Group';

/**
 * Axios method types
 */
export const POST = 'POST';
export const GET = 'GET';

/**
 * Stage name for chart display (including legends), and dropdown options
 */
export const IDEA_OR_PROPOSAL = 'Idea or Proposal';
export const ACQUIRED = 'Acquired';
export const PAPER_COMMISSION = 'Paper Commission';
export const SUBMISSION_AND_REVIEW = 'Submission and Review';
export const PRODUCTION = 'Production';
export const PUBLISHED_IN_SELECTED_FY = 'Published in selected FY';
export const IDEA_OR_PROPOSAL_OA = 'Idea or Proposal OA';
export const ACQUIRED_OA = 'Acquired OA';
export const PAPER_COMMISSION_OA = 'Paper Commission OA';
export const SUBMISSION_AND_REVIEW_OA = 'Submission and Review OA';
export const PRODUCTION_OA = 'Production OA';
export const PUBLISHED_OA = 'Published in selected FY OA';
export const PUBLISHED = 'Published';
/**
 * regular SI Stages
 */
export const SI_STAGES = [
  IDEA_OR_PROPOSAL,
  ACQUIRED,
  PAPER_COMMISSION,
  SUBMISSION_AND_REVIEW,
  PRODUCTION,
  PUBLISHED_IN_SELECTED_FY,
];
/**
 * SI stages colour for chart under sipipeline demesion
 * set this in specific class
 */
export const SI_STAGES_COLOR = [
  '#71C8FF',  //IDEA_OR_PROPOSAL
  '#4C81EB',  //ACQUIRED
  '#FFB152',  //PAPER_COMMISSION
  '#FF8180',  //SUBMISSION_AND_REVIEW
  '#9098E2',  //PRODUCTION
  '#42C1B3',  //PUBLISHED
];

export const getTopBar = selectedLegends => {
  let topStage = null;
  for (let stage in selectedLegends) {
    let hasSelected = selectedLegends[stage];
    if (hasSelected) {
      topStage = stage;
    }
  }
  return topStage;
};

export const getTopBarForOa = selectedLegends => {
  let selectedStages = [];
  for (let stage in selectedLegends) {
    let hasSelected = selectedLegends[stage];
    if (hasSelected) {
      selectedStages.push(stage);
    }
  }
  let halfLength = Math.ceil(selectedStages.length / 2);
  selectedStages = selectedStages.slice(0, halfLength);
  return selectedStages[selectedStages.length - 1];
};

export const recalulatePieValues = (selectedLegend, dataForPie) => {
  let total = 0;
  for (let prop in selectedLegend) {
    if (selectedLegend[prop]) {
      let pieDataItem = dataForPie.find(item => item[0] === prop);
      if(pieDataItem) {
        total = total + pieDataItem[2];
      }
    }
  }
  for (let prop in selectedLegend) {
    // Re-calculate pie charge percentages based on selected stages.
    if (selectedLegend[prop]) {
      let pieDataItem = dataForPie.find(obj => obj[0] === prop);
      if(pieDataItem) {
        pieDataItem[1] = total === 0 ? '0% ' : ((pieDataItem[2] / total) * 100).toFixed(0) + '%';
      }
    }
  }
};
function jsonToCSS(json) {
  let cssString = '';
  for (let prop in json) {
    if (json.hasOwnProperty(prop)) {
      cssString += `${prop}: ${json[prop]}; `;
    }
  }
  return cssString;
}
const stageFontStyle = {
  'font-family': 'Inter',
  'font-size': '12px',
  'font-weight': '800',
  'line-height': '14px',
  color: ' #596A7C',
  'text-align': 'left',
};
const stageCountFontStyle = {
  ...stageFontStyle,
  'font-weight': 400,
};
const percentageFontStyle = {
  padding: '2px 4px',
  'font-family': 'Open Sans',
  'font-size': '12px',
  'font-weight': '700',
  'line-height': '10px',
  'text-align': 'center',
  color: '#FFFFFF',
  'border-radius': '2px',
  background: '#596A7C',
};

export const siPipelinePieTooltipFormatter = params => {
  return `<div style='font-family:'Inter'; padding: 0; margin: 0;display:flex;align-items:center;'>
      <span style='${jsonToCSS(stageFontStyle)}'>${params.data[0]}:</span>
      <span style='${jsonToCSS(stageCountFontStyle)}'>${params.data[2]}</span>
      <span style='${jsonToCSS(percentageFontStyle)}'>${params.data[1]}</span>
    </div>`;
};

import { Button } from '@mui/material';
import { SemiBoldFont } from '../Css';
import styled from '@emotion/styled';

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.active ? theme.themeColor.third : '#FFFFFF',
  boxShadow: 'none',
  padding: '7px 11px',
  boxSizing: 'border-box',
  //font
  ...SemiBoldFont,
  color: theme.active ? '#FFFFFF' : '#262E35',
  textTransform: 'none',
  //border
  borderWidth: '1px',
  borderColor: theme.active ? theme.themeColor.third : theme.themeColor.first,
  borderStyle: 'solid',
  borderRadius: '5px',
  //svg
  svg: {
    height: '14px',
    width: '14px',
  },
  'svg path': {
    fill: theme.active ? theme.themeColor.third : '#FFFFFF',
  },
  'svg circle': {
    fill: theme.active ? '#FFFFFF' : theme.themeColor.first,
  },
  '&:hover': theme.active
    ? {
        borderColor: theme.themeColor.third,
        color: '#FFFFFF',
        backgroundColor: theme.themeColor.third,
        'svg path': {
          fill: theme.themeColor.third,
        },
        'svg circle': {
          fill: '#FFFFFF',
        },
      }
    : {
        borderColor: theme.themeColor.second,
        color: '#FFFFFF',
        backgroundColor: theme.themeColor.second,
        'svg path': {
          fill: theme.themeColor.second,
        },
        'svg circle': {
          fill: '#FFFFFF',
        },
      },
  '&:active': {
    borderColor: theme.themeColor.third,
    color: '#FFFFFF',
    backgroundColor: theme.themeColor.third,
    'svg path': {
      fill: theme.themeColor.third,
    },
    'svg circle': {
      fill: '#FFFFFF',
    },
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
}));

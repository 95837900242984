import {axios_instance} from "@/utils/axios_instance";
import {getMessageIdFromResponse} from "@/utils/commonUtils";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {
    GET_EMAIL_WITHDRAW_BODY,
    LOAD_SIP_CODES,
    LOAD_SIP_CODESBYDATA,
    LOAD_SIP_DATA,
    SEND_EMAIWITHDRAWCODE,
    SET_WITHDRAW_ALLCONTENT,
    SIP_VERSION_ERROR
} from "@/actions/SIMT-SIP/SIP/SIPAction";
import {getSIPStatus} from "@/actions/SIMT-SIP/SIP/SipDetail";

export const loadSIPOverviewData = (
    pageNum,
    pageSize,
    orderType,
    orderField,
    permission = '',
    isSIPOverviewDataByPermission = false,
    filters,
    solicited = false
) => {
    //修改字段名以适应后端接口
    let filter2 = {
        sipCode: filters.sipCode,
        sipTitle: filters.sipTitle,
        handlingCE: filters.handlingCe,
        operationsLead: filters.operationsLead,
        screener: filters.screener,
        geName: filters.geName,
        geEmail: filters.geEmail,
        aimsAndScope: filters.aimsAndScope,
        topics: filters.topics,
        keywords: filters.keywords,
        catGroup: filters.catGroup,
        preferredSubjectArea: filters.preferredSubjectArea,
        preferredJournal: filters.preferredJournal,
        geCountry: filters.region,
        sipStage: filters.stage,
        sipOrigin: filters.sipOrigin,
        sipChecklistStatus: filters.sipChecklistStatus,
        submissionDateFrom:
            filters.submissionFromDate === undefined ||
            filters.submissionFromDate[0] === undefined
                ? []
                : filters.submissionFromDate,
        submissionDateTo:
            filters.submissionToDate === undefined ||
            filters.submissionToDate[0] === undefined
                ? []
                : filters.submissionToDate,
        latestDecisionDateFrom:
            filters.decisionFromDate === undefined ||
            filters.decisionFromDate[0] === undefined
                ? []
                : filters.decisionFromDate,
        latestDecisionDateTo:
            filters.decisionToDate === undefined ||
            filters.decisionToDate[0] === undefined
                ? []
                : filters.decisionToDate,
        creationDateFrom:
            filters.creationFromDate === undefined ||
            filters.creationFromDate[0] === undefined
                ? []
                : filters.creationFromDate,
        creationDateTo:
            filters.creationToDate === undefined ||
            filters.creationToDate[0] === undefined
                ? []
                : filters.creationToDate,
        sipTimeSensitive: filters.timeSensitive,
        potentialAuthors: filters.potentialAuthors,
    };
    return async dispatch => {
        try {
            let url = `/sip/overview/all`;
            let onlyUser = false;
            let toBeAssigned = false;
            let type = 'sipAll';
            if (permission === 'admin' && isSIPOverviewDataByPermission) {
                url = `/sip/overview/toBeAssigned`;
                toBeAssigned = true;
                onlyUser = false;
                type = 'sipToBeAssigned';
            } else if (permission === 'tier1' && isSIPOverviewDataByPermission) {
                url = `/sip/overview/userRelated`;
                toBeAssigned = false;
                onlyUser = true;
                type = 'sipUserRelated';
            }

            axios_instance.post(
                '/api/v1/sip-service/special-issue-proposals/allCodes',
                filter2,
                {
                    params: {
                        onlyUser: onlyUser,
                        toBeAssigned: toBeAssigned,
                        solicited: !!solicited,
                    },
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            ).then(res2 => {
                if (res2.data.code === 200) {
                    dispatch({type: LOAD_SIP_CODESBYDATA, data: res2.data.data});
                } else {
                    // const mid = getMessageIdFromResponse(res2);
                    // dispatch(setSnackbarMessageAndOpen(mid, {}, SEVERITIES.error));
                }
            });

            const res = await axios_instance.post('/api/v1/sip-service/special-issue-proposals/overview', 
            {
                "filterMap": filter2
            }, 
            {
                params: {
                    pageNum: pageNum,
                    pageSize: pageSize,
                    orderType: orderType,
                    orderField: orderField,
                    solicited: !!solicited,
                    type: type,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            if (res.data.code === 200) {
                let result = res.data.data?.result ?? [];
                let data = res.data.data;
                data = {...data, result};
                dispatch({type: LOAD_SIP_DATA, data});
            } else {
                const mid = getMessageIdFromResponse(res);
                dispatch(setSnackbarMessageAndOpen(mid, {}, SEVERITIES.error));
            }

        } catch {
            //do not
        }
    };
};
export const loadSIPCodes = (
    permission = '',
    isSIPOverviewDataByPermission = false
) => {
    return async dispatch => {
        try {
            let url = `/sip/overview/getAllSipCodeByFilter`;
            if (permission === 'admin' && isSIPOverviewDataByPermission) {
                url = `/sip/overview/toBeAssignedCode`;
            } else if (permission === 'tier1' && isSIPOverviewDataByPermission) {
                url = `/sip/overview/userRelatedCode`;
            }
            const res = await axios_instance.get(url, {
                params: {},
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: LOAD_SIP_CODES, data: res.data.data});
            } else {
                dispatch({type: LOAD_SIP_CODES, data: []});
            }
        } catch {
            dispatch({type: LOAD_SIP_CODES, data: []});
        }
    };
};

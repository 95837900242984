export const defaultVars = {
  '--color': '#DFE4E8',
  '--focus-color': '#4C9AFF',
};

export const errorVars = {
  '--color': '#DE350B',
  '--focus-color': '#DE350B',
};

export const helperTextStyle = {
  width: 'auto',
  height: '16px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '-0.084px',
  color: '#DE350B',
};

export function getColor(state) {
  return state === 'read-only' ? '#98A7B6' : '#262E35';
}

export function getDescriptionsStyle(state) {
  return {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: getColor(state),
  };
}

/**
 * Author YZQ
 * 2023.6.28
 */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Editable from './Table/PublicationEditable';
import { Box, Stack } from '@mui/material';
import {
  setSavePDNumberError,
  setPublichError,
} from '@/actions/SIMT-SI/SpecialIssue/SIAction';
import {
  turnDateToUTC,
  backMatchUTCTime,
  patternLink,
} from '@/utils/commonUtils';
import { useParams } from 'react-router-dom';

import EditSIDetailnewIcon from '@/assets/EditSIDetailnewIcon.svg';
import AddPublicationDetail from '@/assets/AddPublicationDetail.svg';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import EditSIDetailnewHover from '@/assets/EditSIDetailnewHover.svg';
import SaveAndCancelButton from '@/componentsphase2/SIDetail/SaveAndCancelButton';
import ErrorIcon from '@/assets/Error.svg';
import {getSIDetail} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueDetail";
import {EditPublicationDetail} from "@/actions/SIMT-SI/SpecialIssue/SiEditDetail";
import {PERMISSIONS} from "@/constant/permission";

export default function PublicationDetailsBlock(props) {
  const {
    title,
    typeList,
    data,
    outputRateInfo,
    extraInfo,
    loadingSIDetails,
    isOA,
    stage,
  } = props;

  const { siCode } = useParams();
  const [isFold, setIsFold] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  //Edit模式的修改记录
  const [editInfo, setEditInfo] = useState({});
  const [afterSave, setAfterSave] = useState(false);
  const [hover, setHover] = useState(false);

  //Add模式的添加记录
  const initialAddInfo = {
    actualPublicationDate: '',
    issue: '',
    issuePublicationLink: '',
    numberOfOaArticle: '',
    numberOfOoArticle: '',
    siCode: siCode,
    volume: '',
    numberOfWaiversUsed: '0',
  };
  const initialOAAddInfo = {
    actualPublicationDate: '',
    issue: '',
    issuePublicationLink: '',
    numberOfOaArticle: '',
    numberOfOoArticle: '',
    siCode: siCode,
    volume: '',
    numberOfWaiversUsed: '',
  };
  // const [addInfo, setAddInfo] = useState(isOA ? initialOAAddInfo : initialAddInfo)
  const [addInfo, setAddInfo] = useState(initialOAAddInfo);

  const [volumeErrorFlag, setVolumeErrorFlag] = useState(false);
  const [issueErrorFlag, setIssueErrorFlag] = useState(false);
  const [dateErrorFlag, setDateErrorFlag] = useState(false);
  const [articleNumErrorFlag, setArticleNumErrorFlag] = useState(false);
  const [waiversNumErrorFlag, setWaiversNumErrorFlag] = useState(false);
  const [linkErrorFlag, setLinkErrorFlag] = useState(false);

  const [volumeFlag, setVolumeFlag] = useState(false);
  const [issueFlag, setIssueFlag] = useState(false);
  const [dateFlag, setDateFlag] = useState(false);
  const [oaArticleFlag, setOaArticleFlag] = useState(false);
  const [ooArticleFlag, setOoArticleFlag] = useState(false);
  const [waiversusedFlag, setWaiversusedFlag] = useState(false);
  const [linkFlag, setLinkFlag] = useState(false);

  const [articleNumFlag, setArticleNumFlag] = useState(false);
  const [waiversNumFlag, setWaiversNumFlag] = useState(false);
  const [linkError, setLinkError] = useState(0);

  const [editSaved, setEditSaved] = useState(true);
  const [messages, setMessages] = useState([]);
  const dispatch = useDispatch();

  const [firstTimeClick, setFirstTimeClick] = useState(false);

  const selector = state => {
    return {
      numberError: state.SI.numberError,
      detailInfo: state.SI.detailInfo,
      linkErrorType: state.SI.linkErrorType,
    };
  };

  const { numberError, detailInfo, linkErrorType } = useSelector(selector);

  useEffect(() => {
    let newInfo = {};
    for (let item of data) {
      newInfo[item.id] = item;
    }
    setEditInfo(newInfo);
  }, [detailInfo]);

  useEffect(() => {
    if (
      !(
        volumeErrorFlag ||
        issueErrorFlag ||
        linkErrorFlag ||
        dateErrorFlag ||
        numberError ||
        waiversusedFlag ||
        waiversNumFlag ||
        waiversNumErrorFlag
      )
    ) {
      dispatch(setPublichError(false));
    } else {
      dispatch(setPublichError(true));
    }
  }, [
    volumeErrorFlag,
    issueErrorFlag,
    linkErrorFlag,
    dateErrorFlag,
    numberError,
    waiversusedFlag,
    waiversNumFlag,
    waiversNumErrorFlag,
  ]);

  const createRow = (
    id,
    label,
    minWidth,
    maxWidth,
    isOrder = true,
    align = 'left'
  ) => {
    return { id, label, minWidth, maxWidth, isOrder, align };
  };

  const hasError = () => {
    return (
      volumeErrorFlag ||
      issueErrorFlag ||
      dateErrorFlag ||
      linkErrorFlag ||
      articleNumErrorFlag ||
      volumeFlag ||
      issueFlag ||
      oaArticleFlag ||
      ooArticleFlag ||
      dateFlag ||
      linkFlag ||
      articleNumFlag ||
      waiversusedFlag ||
      waiversNumFlag ||
      waiversNumErrorFlag
    );
    //  || volumeFlag || issueFlag || dateFlag || linkFlag || articleNumFlag
  };

  const editError = () => {
    return (
      waiversNumErrorFlag ||
      articleNumErrorFlag ||
      linkErrorFlag ||
      volumeErrorFlag ||
      issueErrorFlag
    );
  };

  const parseInt = value => {
    return Number(value);
  };

  const tableHeads = [
    createRow('volume', 'VOLUME', '79px', '79px', false),
    createRow('issue', 'ISSUE', '61px', '61px', false, 'left'),
    createRow(
      'actualPublicationDate',
      'ACTUAL PUBLICATION DATE',
      '207px',
      '207px',
      false,
      'left'
    ),
    createRow(
      'numberOfOaArticle',
      'OA ARTICLES',
      '109px',
      '109px',
      false,
      'center'
    ),
    createRow(
      'numberOfOoArticle',
      'OO ARTICLES',
      '111px',
      '111px',
      false,
      'center'
    ),
    createRow(
      'numberOfWaiversUsed',
      'WAIVER(S) USED',
      '147px',
      '147px',
      false,
      'center'
    ),
    createRow(
      'issuePublicationLink',
      'ISSUE PUBLICATION LINK',
      '210px',
      '210px',
      false
    ),
  ];

  const ootableHeads = [
    createRow('volume', 'VOLUME', '79px', '79px', false),
    createRow('issue', 'ISSUE', '154px', '154px', false, 'left'),
    createRow(
      'actualPublicationDate',
      'ACTUAL PUBLICATION DATE',
      '207px',
      '207px',
      false,
      'left'
    ),
    createRow(
      'numberOfOaArticle',
      'OA ARTICLES',
      '109px',
      '109px',
      false,
      'center'
    ),
    createRow(
      'numberOfOoArticle',
      'OO ARTICLES',
      '111px',
      '111px',
      false,
      'center'
    ),
    createRow(
      'issuePublicationLink',
      'ISSUE PUBLICATION LINK',
      '304px',
      '304px',
      false
    ),
  ];

  const handleInfoChange = (id, key, value) => {
    //A deep copy is required here. Shallow copy will cause problems. I don't know why
    let tmpObject = JSON.parse(JSON.stringify(editInfo));
    tmpObject[id][key] = value;
    // if key in [numberOfOoArticle , numberOfOaArticle ] change its value to int
    if (key === 'numberOfOoArticle' || key === 'numberOfOaArticle') {
      tmpObject[id][key] = parseInt(tmpObject[id][key]);
    }
    let SUM = 0;
    for (let key in tmpObject) {
      SUM = SUM + tmpObject[key]['numberOfOoArticle'] * 1;
      SUM = SUM + tmpObject[key]['numberOfOaArticle'] * 1;
      tmpObject[key]['actualPublicationDate'] = turnDateToUTC(
        backMatchUTCTime(tmpObject[key]['actualPublicationDate'])
      );
    }
    if (SUM > extraInfo) {
      setArticleNumErrorFlag(true);
    } else {
      setArticleNumErrorFlag(false);
    }

    if (
      parseInt(tmpObject[id]['numberOfWaiversUsed']) >
      parseInt(tmpObject[id]['numberOfOaArticle'])
    ) {
      setWaiversNumErrorFlag(true);
    } else {
      setWaiversNumErrorFlag(false);
    }
    setEditInfo(tmpObject);
  };

  const handleInfoAdd = (key, value) => {
    let tmpObject = JSON.parse(JSON.stringify(addInfo));
    tmpObject[key] = value;
    if (key === 'numberOfOoArticle' || key === 'numberOfOaArticle') {
      tmpObject[key] = parseInt(tmpObject[key]);
    }
    // console.log("[addInfo]", tmpObject, isOA)
    if (
      typeList['issue'] === 'ISSUE' &&
      (!tmpObject.issue || tmpObject.issue === '')
    ) {
      setIssueFlag(true);
    } else {
      setIssueFlag(false);
    }
    if (
      typeList['volume'] === 'VOLUME' &&
      (!tmpObject.volume || tmpObject.volume === '')
    ) {
      setVolumeFlag(true);
    } else {
      setVolumeFlag(false);
    }
    if (tmpObject.numberOfOaArticle === '') {
      setOaArticleFlag(true);
    } else {
      setOaArticleFlag(false);
    }
    if (tmpObject.numberOfOoArticle === '') {
      setOoArticleFlag(true);
    } else {
      setOoArticleFlag(false);
    }
    if (tmpObject.numberOfWaiversUsed === '' && isOA) {
      setWaiversusedFlag(true);
    } else {
      setWaiversusedFlag(false);
    }
    if (tmpObject.issuePublicationLink === '') {
      setLinkError(1);
      setLinkFlag(true);
    } else if (!patternLink.test(tmpObject.issuePublicationLink)) {
      setLinkError(2);
      setLinkFlag(true);
    } else if (tmpObject.issuePublicationLink.length > 100) {
      setLinkError(3);
      setLinkFlag(true);
    } else {
      setLinkFlag(false);
    }
    if (
      !tmpObject.actualPublicationDate ||
      tmpObject.actualPublicationDate === ''
    ) {
      setDateFlag(true);
    } else {
      setDateFlag(false);
    }
    let minPlusOaOo = 0;
    let plusNowOAOO = 0;
    for (
      let i = 0;
      i < Object.keys(detailInfo.publicationDetails).length;
      i++
    ) {
      plusNowOAOO +=
        detailInfo.publicationDetails[i].numberOfOaArticle * 1 +
        detailInfo.publicationDetails[i].numberOfOoArticle * 1;
      minPlusOaOo = extraInfo * 1 - plusNowOAOO;
    }
    if (
      tmpObject.numberOfOaArticle * 1 + tmpObject.numberOfOoArticle * 1 >
      minPlusOaOo
    ) {
      setArticleNumFlag(true);
    } else {
      setArticleNumFlag(false);
    }

    if (
      tmpObject.numberOfWaiversUsed > tmpObject.numberOfOaArticle * 1 &&
      isOA
    ) {
      setWaiversNumFlag(true);
    } else {
      setWaiversNumFlag(false);
    }

    setAddInfo(tmpObject);
  };

  const handleEditButtonClick = () => {
    for (const idx in editInfo) {
      if (
        typeList['issue'] === 'ISSUE' &&
        (!editInfo[idx].issue || editInfo[idx].issue === '')
      ) {
        setIssueErrorFlag(true);
      }
      if (
        typeList['volume'] === 'VOLUME' &&
        (!editInfo[idx].volume || editInfo[idx].volume === '')
      ) {
        setVolumeErrorFlag(true);
      }
    }
    if (!isEditing) {
      setIsEditing(true);
      setIsAdding(false);
    } else {
      setIsEditing(false);
    }
    dispatch(setSavePDNumberError(false));
  };

  const handleAddButtonClick = () => {
    if (!isAdding) {
      setIsAdding(true);
    } else {
      setIsAdding(false);
    }
    setVolumeFlag(false);
    setIssueFlag(false);
    setDateFlag(false);
    setLinkFlag(false);
    setArticleNumFlag(false);
    setOaArticleFlag(false);
    setWaiversusedFlag(false);
    setWaiversNumFlag(false);
    setWaiversNumErrorFlag(false);
    setOoArticleFlag(false);
  };

  const SavePublicationDetail = async (newInfoObj, id) => {
    for (let key in newInfoObj) {
      let tmpList = [];
      newInfoObj[key].siCode = siCode;
      tmpList.push(newInfoObj[key]);
      setEditSaved(false);
      console.log(tmpList);
      await dispatch(EditPublicationDetail(tmpList));
      dispatch(getSIDetail(siCode));
      setEditSaved(true);
    }
  };

  /**
   * 依次检查各个Flag，将对应的错误信息加入数组
   * @returns 返回错误信息数组
   */
  const composeErrorMessage = () => {
    let [
      issueFlag,
      volumeFlag,
      dateFlag,
      linkFlag,
      oaArticleNullFlag,
      ooArticleNullFlag,
      waiversFlag,
    ] = [false, false, false, false, false, false, false];
    let linkError = 0;
    let flag = false;
    if (isAdding) {
      if (
        typeList['issue'] === 'ISSUE' &&
        (!addInfo.issue || addInfo.issue === '')
      ) {
        issueFlag = true;
        setIssueFlag(true);
      } else {
        setIssueFlag(false);
      }
      if (
        typeList['volume'] === 'VOLUME' &&
        (!addInfo.volume || addInfo.volume === '')
      ) {
        volumeFlag = true;
        setVolumeFlag(true);
      } else {
        setVolumeFlag(false);
      }
      if (addInfo.numberOfOaArticle === '') {
        oaArticleNullFlag = true;
        setOaArticleFlag(true);
      } else {
        setOaArticleFlag(false);
      }
      if (addInfo.numberOfWaiversUsed === '' && isOA) {
        waiversFlag = true;
        setWaiversusedFlag(true);
      } else {
        setWaiversusedFlag(false);
      }
      if (addInfo.numberOfOoArticle === '') {
        setOoArticleFlag(true);
      } else {
        setOoArticleFlag(false);
      }
      if (addInfo.issuePublicationLink === '') {
        setLinkError(1);
        setLinkFlag(true);
        linkFlag = true;
      } else if (!patternLink.test(addInfo.issuePublicationLink)) {
        setLinkError(2);
        setLinkFlag(true);
        linkFlag = true;
      } else if (addInfo.issuePublicationLink.length > 100) {
        setLinkError(3);
        setLinkFlag(true);
        linkFlag = true;
      } else {
        setLinkFlag(false);
      }
      if (
        !addInfo.actualPublicationDate ||
        addInfo.actualPublicationDate === ''
      ) {
        setDateFlag(true);
        dateFlag = true;
      } else {
        setDateFlag(false);
      }
    }

    if (isEditing) {
      for (const idx in editInfo) {
        if (
          typeList['issue'] === 'ISSUE' &&
          (!editInfo[idx].issue || editInfo[idx].issue === '')
        ) {
          issueFlag = true;
          setIssueErrorFlag(true);
        }
        if (
          typeList['volume'] === 'VOLUME' &&
          (!editInfo[idx].volume || editInfo[idx].volume === '')
        ) {
          volumeFlag = true;
          setVolumeErrorFlag(true);
        }
        let SUM = 0;
        SUM += editInfo[idx]['numberOfOoArticle'];
        SUM += editInfo[idx]['numberOfOaArticle'];
        editInfo[idx]['actualPublicationDate'] = turnDateToUTC(
          backMatchUTCTime(editInfo[idx]['actualPublicationDate'])
        );
        // Problem Why not just computing the editing Info? add part of new editing info and the left part of the original info is not good for Dynamic Fix
        for (let item of detailInfo.publicationDetails) {
          // change idx to String
          if (item['id'] !== parseInt(idx)) {
            SUM = SUM + item['numberOfOoArticle'];
            SUM = SUM + item['numberOfOaArticle'];
          }
        }
        if (SUM > extraInfo) {
          flag = true;
        }
      }
    }
    if (isAdding) {
      let minPlusOaOo = 0;
      let plusNowOAOO = 0;
      for (
        let i = 0;
        i < Object.keys(detailInfo.publicationDetails).length;
        i++
      ) {
        plusNowOAOO +=
          detailInfo.publicationDetails[i].numberOfOaArticle * 1 +
          detailInfo.publicationDetails[i].numberOfOoArticle * 1;
        minPlusOaOo = extraInfo * 1 - plusNowOAOO;
      }
      if (
        addInfo.numberOfOaArticle * 1 + addInfo.numberOfOoArticle * 1 >
        minPlusOaOo
      ) {
        setArticleNumFlag(true);
        flag = true;
      } else {
        setArticleNumFlag(false);
      }
    }
    let messages = [];
    if (volumeErrorFlag || volumeFlag) {
      messages.push(
        <FormattedMessage
          id='siDetail.volumeError'
          data-selenium-id='SIDetail_PublicationDetailsBlock-volumeError'
        />
      );
    }
    if (issueErrorFlag || issueFlag) {
      messages.push(
        <FormattedMessage
          id='siDetail.issueError'
          data-selenium-id='SIDetail_PublicationDetailsBlock-issueError'
        />
      );
    }
    if (dateErrorFlag || dateFlag) {
      messages.push(
        <FormattedMessage
          id='siDetail.publicationDateError'
          data-selenium-id='SIDetail_PublicationDetailsBlock-PubDateError'
        />
      );
    }
    if (flag) {
      messages.push(
        <FormattedMessage
          id='siDetail.articleNumberError'
          data-selenium-id='SIDetail_PublicationDetailsBlock-ArticleNumberError'
        />
      );
    }
    if (oaArticleFlag || oaArticleNullFlag) {
      messages.push(
        <FormattedMessage
          id='siDetail.oaArticleNullError'
          data-selenium-id='SIDetail_PublicationDetailsBlock-OaArticleNullError'
        />
      );
    }
    if (ooArticleFlag || ooArticleNullFlag) {
      messages.push(
        <FormattedMessage
          id='siDetail.ooArticleNullError'
          data-selenium-id='SIDetail_PublicationDetailsBlock-OoArticleNullError'
        />
      );
    }
    if (linkErrorFlag || linkFlag) {
      if (linkErrorType === 1 || linkError === 1) {
        messages.push(
          <FormattedMessage
            id='siDetail.linkNullError'
            data-selenium-id='SIDetail_PublicationDetailsBlock-LinkNullError'
          />
        );
      } else if (linkErrorType === 2 || linkError === 2) {
        messages.push(
          <FormattedMessage
            id='siDetail.linkFormatError'
            data-selenium-id='SIDetail_PublicationDetailsBlock-LinkFormatError'
          />
        );
      } else if (linkErrorType === 3 || linkError === 3) {
        messages.push(
          <FormattedMessage
            id='siDetail.linkLengthError'
            data-selenium-id='SIDetail_PublicationDetailsBlock-LinkLengthError'
          />
        );
      }
    }
    if (waiversNumErrorFlag || waiversNumFlag || waiversFlag) {
      messages.push(
        <FormattedMessage
          id='siDetail.waiversusedNumberError'
          data-selenium-id='SIDetail_PublicationDetailsBlock-WaiversusedNumberError'
        />
      );
    }
    return messages;
  };

  const [ publicationDetailIsSaving,setPublicationDetailIsSaving] = useState(false);
  /**
   * when chick √，do this function
   * @returns
   */
  const handleCheckButtonClick = async id => {
    /**
     *Handle the click event of submitting editing and adding
     *0. Make an error judgment first, give a prompt if an error occurs, and do not continue to perform the following steps
     *1. Determine the type and perform different operations according to the type (for example, request the backend to update information)
     *2. Finally, execute some closing operations (close editing/adding status&reset information)
     */
    setAfterSave(true);
    setHover(false);
    setPublicationDetailIsSaving(true);
    const errorMessages = composeErrorMessage();
    //如果有错误
    if (errorMessages.length !== 0) {
      setFirstTimeClick(true);
      setPublicationDetailIsSaving(false);
      return false;
    }
    if (isAdding) {
      setEditSaved(false);
      if (!isOA) {
        addInfo['numberOfWaiversUsed'] = 0;
      }
      await dispatch(EditPublicationDetail([addInfo]));
      dispatch(getSIDetail(siCode));
      setEditSaved(true);
    } else {
      // console.log("SAVEING");
      await delay(300);
      await SavePublicationDetail(editInfo, id);
    }
    getData();
    if (isEditingShow()) {
      setIsAdding(false);
      setIsEditing(false);
    }
    //清空addInfo, EditInfo在SIDetail更新后会自动重置
    setAddInfo(initialOAAddInfo);
    setVolumeFlag(false);
    setIssueFlag(false);
    setDateFlag(false);
    setLinkFlag(false);
    setArticleNumFlag(false);
    setWaiversusedFlag(false);
    setWaiversNumFlag(false);
    setWaiversNumErrorFlag(false);
    setOaArticleFlag(false);
    setOoArticleFlag(false);

    setAfterSave(false);
    setPublicationDetailIsSaving(false);
    return true;
  };

  /**
   * 点击叉号×时，关闭 编辑/新增 状态；与Editable.js中的checkClose搭配使用
   */
  const handleCloseButtonClick = () => {
    setHover(false);
    setAfterSave(false);
    let newInfo = {};
    for (let item of data) {
      newInfo[item.id] = item;
    }
    setEditInfo(newInfo);
    setAddInfo(initialOAAddInfo);
    if (isEditingShow()) {
      setIsAdding(false);
      setIsEditing(false);
    }
    setMessages([]);

    setVolumeErrorFlag(false);
    setIssueErrorFlag(false);
    setDateErrorFlag(false);
    setLinkErrorFlag(false);
    setArticleNumErrorFlag(false);
    setWaiversNumErrorFlag(false);

    setVolumeFlag(false);
    setIssueFlag(false);
    setDateFlag(false);
    setLinkFlag(false);
    setArticleNumFlag(false);
    setOaArticleFlag(false);
    setOoArticleFlag(false);
    setWaiversusedFlag(false);
    setWaiversNumFlag(false);
  };

  const isEditableShow = () => {
    //该Block打开状态 且不在编辑中时
    return isFold && !isEditing && !isAdding;
  };

  const isEditingShow = () => {
    //该Block打开状态 且正在编辑时
    return isFold && (isEditing || isAdding);
  };

  const cellStyle = {
    fontWeight: 400,
  };

  const canEdit = () => {
    return detailInfo.generalInformation[0].stage === 'Published';
  };

  const userPermissions = localStorage.getItem('userPermissions');
  const getData = () =>{
    async function fetchData() {
      //fetch data
      dispatch(getSIDetail(siCode));
    }
    fetchData();
  };
  return (
    <Box data-selenium-id='SIDetail_PublicationDetailsBlock-ContainerBox'>
      {/* Title Row */}
      <Box
        sx={{ mb: '16px', mt: '32px',cursor:'pointer' }}
        id='titleBox'
        data-selenium-id='SIDetail_PublicationDetailsBlock-TitleBox'
      >
        <Box
          class='titleFoldButton'
          onClick={() => {
            getData();
            setIsFold(!isFold);
          }}
          data-selenium-id='SIDetail_PublicationDetailsBlock-TitleFoldBtn'
        >
          {isFold ? <MarketChevronUp /> : <SIDetailArrowDown />}
        </Box>
        <Stack
          id='titleBoxLeft'
          data-selenium-id='SIDetail_PublicationDetailsBlock-TitleBoxLeft'
          spacing={1}
          direction='row'
          alignItems='center'
          sx={{cursor:'default'}}
        >
          <Box data-selenium-id='SIDetail_PublicationDetailsBlock-TextContainer'>
            <FormattedMessage
              id='siDetail.publicationDetail'
              data-selenium-id='SIDetail_PublicationDetailsBlock-PublicationDetailText'
            />
          </Box>
          <Box sx={{ paddingTop: '2px' }}>
            {/* Edit Button */}
            {isEditableShow() &&
            !loadingSIDetails &&
            canEdit() &&
            userPermissions.includes(PERMISSIONS.SI_WRITE) &&
            stage !== 'Shelved' &&
            stage !== 'Idea/Proposal' &&
            stage !== 'Acquired' ? (
              <div
                id = 'editPublicationDetailIcon'
                data-selenium-id='SIDetail_PublicationDetailsBlock-EditBtnIcon'
                onClick={handleEditButtonClick} //set isEdit true
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                {hover ? (
                  <EditSIDetailnewHover data-selenium-id='SIDetail_SubmissionOverviewBlock-DefaultEditBtnIcon' />
                ) : (
                  <EditSIDetailnewIcon data-selenium-id='SIDetail_SubmissionOverviewBlock-HoverEditBtnIcon' />
                )}
              </div>
            ) : null}
          </Box>
          <Box sx={{ paddingTop: '2px' }}>
            {/* Add Button */}
            {isEditableShow() &&
            !loadingSIDetails &&
            canEdit() &&
            userPermissions.includes(PERMISSIONS.SI_WRITE) ? (
              <div onClick={handleAddButtonClick} id='editSIDetailnewIcon'>
                <AddPublicationDetail data-selenium-id='SIDetail_PublicationDetailsBlock-AddBtnIcon' />
              </div>
            ) : null}
          </Box>
        </Stack>
      </Box>
      {/* Table */}
      {isFold ? (
        <Box
          // sx={{ width: '1092px' }}
          data-selenium-id='SIDetail_PublicationDetailsBlock-FoldBox'
        >
          {/* Error Messages*/}
          {(!isAdding || afterSave) && firstTimeClick ? (
            <Box data-selenium-id='SIDetail_PublicationDetailsBlock-ErrList'>
              {volumeErrorFlag || volumeFlag ? (
                <Box
                  sx={{
                    color: '#d32f2f',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                  data-selenium-id='SIDetail_PublicationDetailsBlock-VolumeErr'
                >
                  <Box>
                    <ErrorIcon />
                    &ensp;VOLUME:&ensp;
                    <FormattedMessage
                      id='siDetail.volumeError'
                      data-selenium-id='SIDetail_PublicationDetailsBlock-VolumeErrText'
                    />
                  </Box>
                </Box>
              ) : null}
              {(issueErrorFlag || issueFlag) && firstTimeClick ? (
                <Box
                  sx={{
                    color: '#d32f2f',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                  data-selenium-id='SIDetail_PublicationDetailsBlock-IssueErr'
                >
                  <ErrorIcon />
                  &ensp;ISSUE:&ensp;
                  <FormattedMessage
                    id='siDetail.issueError'
                    data-selenium-id='SIDetail_PublicationDetailsBlock-IssueErrText'
                  />
                </Box>
              ) : null}
              {(dateErrorFlag || dateFlag) && firstTimeClick ? (
                <Box
                  sx={{
                    color: '#d32f2f',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                  data-selenium-id='SIDetail_PublicationDetailsBlock-pubDateErr'
                >
                  <ErrorIcon />
                  &ensp;ACTUAL PUBLICATION DATE:&ensp;
                  <FormattedMessage
                    id='siDetail.publicationDateError'
                    data-selenium-id='SIDetail_PublicationDetailsBlock-PubDataErrText'
                  />
                </Box>
              ) : null}
              {isAdding && oaArticleFlag && firstTimeClick ? (
                <Box
                  sx={{
                    color: '#d32f2f',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                  data-selenium-id='SIDetail_PublicationDetailsBlock-OaArticleNullErr'
                >
                  <ErrorIcon />
                  &ensp;OA ARTICLES:&ensp;
                  <FormattedMessage
                    id='siDetail.oaArticleNullError'
                    data-selenium-id='SIDetail_PublicationDetailsBlock-OaArticleNullErrText'
                  />
                </Box>
              ) : null}
              {isAdding && ooArticleFlag && firstTimeClick ? (
                <Box
                  sx={{
                    color: '#d32f2f',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                  data-selenium-id='SIDetail_PublicationDetailsBlock-OoArticleNullErr'
                >
                  <ErrorIcon />
                  &ensp;OO ARTICLES:&ensp;
                  <FormattedMessage
                    id='siDetail.ooArticleNullError'
                    data-selenium-id='SIDetail_PublicationDetailsBlock-OoArticleNullErrText'
                  />
                </Box>
              ) : null}
              {isAdding && waiversusedFlag && firstTimeClick ? (
                <Box
                  sx={{
                    color: '#d32f2f',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                  data-selenium-id='SIDetail_PublicationDetailsBlock-WaiversUsedNullErr'
                >
                  <ErrorIcon />
                  &ensp;WAIVER(S) USED:&ensp;
                  <FormattedMessage
                    id='siDetail.waiversUsedNullError'
                    data-selenium-id='SIDetail_PublicationDetailsBlock-WaiversUsedNullErrText'
                  />
                </Box>
              ) : null}
              {(articleNumErrorFlag || articleNumFlag) && firstTimeClick ? (
                <Box
                  sx={{
                    color: '#d32f2f',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                  data-selenium-id='SIDetail_PublicationDetailsBlock-ArticleNumErr'
                >
                  <ErrorIcon />
                  &ensp;ARTICLES:&ensp;
                  <FormattedMessage
                    id='siDetail.articleNumberError'
                    data-selenium-id='SIDetail_PublicationDetailsBlock-ArticleNumErrText'
                  />
                </Box>
              ) : null}
              {(waiversNumErrorFlag || waiversNumFlag) && firstTimeClick ? (
                <Box
                  sx={{
                    color: '#d32f2f',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                  data-selenium-id='SIDetail_PublicationDetailsBlock-WaiversusedNumErr'
                >
                  <ErrorIcon />
                  &ensp;WAIVER(S) USED:&ensp;
                  <FormattedMessage
                    id='siDetail.waiversusedNumberError'
                    data-selenium-id='SIDetail_PublicationDetailsBlock-WaiversusedNumErrText'
                  />
                </Box>
              ) : null}
              {(linkErrorFlag && linkErrorType === 1) ||
              (linkFlag && linkError === 1 && firstTimeClick) ? (
                <Box
                  sx={{
                    color: '#d32f2f',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                  data-selenium-id='SIDetail_PublicationDetailsBlock-LinkNullErr'
                >
                  <ErrorIcon />
                  &ensp;ISSUE PUBLICATION LINK:&ensp;
                  <FormattedMessage
                    id='siDetail.linkNullError'
                    data-selenium-id='SIDetail_PublicationDetailsBlock-LinkNullErrText'
                  />
                </Box>
              ) : null}
              {(linkErrorFlag && linkErrorType === 2) ||
              (linkFlag && linkError === 2 && firstTimeClick) ? (
                <Box
                  sx={{
                    color: '#d32f2f',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                  data-selenium-id='SIDetail_PublicationDetailsBlock-LinkFormatErr'
                >
                  <ErrorIcon />
                  &ensp;ISSUE PUBLICATION LINK:&ensp;
                  <FormattedMessage
                    id='siDetail.linkFormatError'
                    data-selenium-id='SIDetail_PublicationDetailsBlock-LinkFormatErrText'
                  />
                </Box>
              ) : null}
              {(linkErrorFlag && linkErrorType === 3) ||
              (linkFlag && linkError === 3 && firstTimeClick) ? (
                <Box
                  sx={{
                    color: '#d32f2f',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                  data-selenium-id='SIDetail_PublicationDetailsBlock-LinkLenErr'
                >
                  <ErrorIcon />
                  &ensp;ISSUE PUBLICATION LINK:&ensp;
                  <FormattedMessage
                    id='siDetail.linkLengthError'
                    data-selenium-id='SIDetail_PublicationDetailsBlock-LinkLenErrText'
                  />
                </Box>
              ) : null}
            </Box>
          ) : null}
          <Editable
            title={title}
            id='siDetailPagePublicationDetailsTable'
            data-selenium-id='SIDetail_PublicationDetailsBlock-Table'
            //表头
            //IF siRevenueModel === 'OA',show 'WAIVER(S) USED'
            TableHeads={
              detailInfo.journalDetails?.[0].revenueModel === 'OA' && detailInfo.generalInformation[0].stage === 'Published'
                ? tableHeads //with waivers
                : ootableHeads
            }
            //表格
            TableData={data}
            //编辑状态指示
            isEditing={isEditing}
            //新增状态指示
            isAdding={isAdding}
            //修改信息
            handleInfoChange={handleInfoChange}
            //添加信息
            handleInfoAdd={handleInfoAdd}
            //表格内容类型，标识了表格中每一列的类型，是日期？数字？链接？
            typeList={typeList}
            cellStyle={cellStyle}
            //控制一些错误提示的函数，例如setVolumeErrorFlag在Volume字段填写出错时会调用，将volumeErrorFlag置为true
            setVolumeErrorFlag={setVolumeErrorFlag}
            setIssueErrorFlag={setIssueErrorFlag}
            setLinkErrorFlag={setLinkErrorFlag}
            setDateErrorFlag={setDateErrorFlag}
            setArticleNumErrorFlag={setArticleNumErrorFlag}
            setWaiversNumErrorFlag={setWaiversNumErrorFlag}
            volumeErrorFlag={volumeErrorFlag}
            issueErrorFlag={issueErrorFlag}
            linkErrorFlag={linkErrorFlag}
            dateErrorFlag={dateErrorFlag}
            articleNumErrorFlag={articleNumErrorFlag}
            waiversNumErrorFlag={waiversNumErrorFlag}
            editError={editError()}
            handleCheckButtonClick={handleCheckButtonClick}
            setEditInfo={setEditInfo}
            hasError={hasError}
            extraInfo={extraInfo}
            isOA={isOA}
          ></Editable>
          {/*Output Rate*/}
          {isAdding || isEditing ? null : (
            <Box
              sx={{
                height: '20px',
                mt: '16px',
                textAlign: 'right',
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#596A7C',
              }}
            >
              <FormattedMessage
                id='siDetail.outputRate'
                data-selenium-id='SIDetail_PublicationDetailsBlock-OutputRateText'
              />
              <span
                style={{
                  fontWeight: '600',
                }}
                data-selenium-id='SIDetail_PublicationDetailsBlock-OutputRateInfo'
              >
                {outputRateInfo}
              </span>
            </Box>
          )}
          {isEditingShow() ? (
            <Box
              sx={{
                position: 'relative',
                top: '16px',
              }}
            >
              <SaveAndCancelButton
                id='SIDetail_PublicationDetailsBlock-TwoBtn'
                data-selenium-id='SIDetail_PublicationDetailsBlock-TwoBtn'
                isDisabled={editError() && firstTimeClick}
                handleCancel={handleCloseButtonClick}
                handleSubmit={handleCheckButtonClick}
                isSaving={publicationDetailIsSaving}
              ></SaveAndCancelButton>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
}

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

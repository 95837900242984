import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Tooltip,
  TableCell,
  CircularProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '@/components/DataTable';
import {
  StyledTableCellPurple,
  StyledTableCellDisPurple,
  StyledTableCellGreen,
  StyledTableCellDisGreen,
  StyledTableRow,
  StyledTableCellNormal,
  ReportNoData,
  TableBox2,
} from '@/pages/Report/ReportSpecificComponents/SpecialColorTableHeader';
import '@/App.css';
import {
  findLongestLineSubstring,
  measureWidth,
} from '@/utils/StringWidthMeasure';
import { useIntl } from 'react-intl';
import { SimpleTooltip } from '@/componentsphase2/CustomTooltip';
import {GetJournalJPMData, GetJournalLastActionDate} from "@/actions/SIMT-Report/Report/ReportJournal";

export default function JPMTable(props) {
  const intl = useIntl();
  const { isOA } = props;
  const [loading, setLoading] = useState(false);
  const { selectMonthDate, ReportJournalJPMData, ReportJournalJPMMonth } =
    useSelector(state => {
      return {
        // selectMonthDate: state.Report.ReportJournalOverviewMonth,
        selectMonthDate: state.Report.selectDate,
        ReportJournalJPMData: state.Report.ReportJournalJPMData,
        ReportJournalJPMMonth: state.Report.ReportJournalJPMMonth,
      };
    });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const items = [
      {
        id: 'jpm',
        label: 'JPM',
      },
      {
        id: 'subjectGroup',
        label: 'Subject Group',
      },
      {
        id: 'businessDivision',
        label: 'Business Division',
      },
      {
        id: 'activeJournal',
        // label: intl.messages['report.Journal.active']
        label: 'ACTIVE_%',
      },
      {
        id: 'activePercentage',
        label: intl.messages['report.Journal.active'],
      },
      {
        id: 'optInJournals',
        label:
          intl.messages['report.Journal.optInJournals'] +
          { ReportJournalJPMMonth },
      },
      {
        id: 'optOutJournals',
        label:
          intl.messages['report.Journal.optOutJournals'] +
          { ReportJournalJPMMonth },
      },
      {
        id: 'commission',
        label: intl.messages['report.Journal.commissioningModel'],
      },
      {
        id: 'basic',
        label: intl.messages['report.Journal.basicManagementModel'],
      },
      {
        id: 'onHold',
        label: intl.messages['report.Journal.onHold'],
      },
      {
        id: 'total',
        label: intl.messages['report.Journal.total'],
      },
    ];
    const widthMap = {};
    for (let item of items) {
      let maxStr = findLongestLineSubstring(item.label);
      widthMap[item.id] = measureWidth(maxStr, '18px Open Sans');
    }
    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (ReportJournalJPMData && ReportJournalJPMData.length > 0) {
      const maxMap = {};
      for (let i = 0; i < ReportJournalJPMData.length; i++) {
        let dataObj = ReportJournalJPMData[i];

        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength) {
              maxMap[key] = value;
            }
          }
        }
      }
      maxMap['subjectGroup'] = maxMap['sgAbbr'];

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [ReportJournalJPMData]);

  function calcAllJournalWidth() {
    let sum = 0;
    for (let id of ['commission', 'basic', 'onHold', 'total']) {
      sum += Math.max(columnWidthMap[id] ?? 0, columnMinWidthMap[id]) + 50;
    }
    return sum;
  }

  const dispatch = useDispatch();

  const getData = async () => {
    await dispatch(GetJournalJPMData(selectMonthDate, !isOA));
    dispatch(GetJournalLastActionDate(selectMonthDate, false));
    setLoading(false);
  };

  useEffect(() => {
    if (selectMonthDate !== null && selectMonthDate !== undefined) {
      getData();
      setLoading(true);
    }
  }, [selectMonthDate, isOA]);

  const showDataTableJPM = () => {
    if (ReportJournalJPMData === null || ReportJournalJPMData === undefined) {
      return [];
    } else return ReportJournalJPMData;
  };

  if (isOA === false) {
    return (
      <TableBox2
        data-selenium-id='Report_Journal-JPM-Table-AllModeBox'
        dataList={[ReportJournalJPMData]}
        tableKey={'Report_Journal-JPMTable'}
        footerHeight={0}
      >
        <TableContainer
          id='reportJournalJPMTable'
          data-selenium-id='Report_Journal-JPM-Table-AllModeTableContainer'
          sx={{ maxHeight: '560px' }}
        >
          <Table
            stickyHeader
            aria-label='sticky table'
            data-selenium-id='Report_Journal-JPM-Table-AllModeTable'
            sx={{
              tableLayout: 'fixed',
              width: 'max-content',
              maxWidth: 'none',
            }}
          >
            <TableHead data-selenium-id='Report_Journal-JPM-Table-AllModeHead'>
              <TableRow data-selenium-id='Report_Journal-JPM-Table-AllModeHeadRow1'>
                <StyledTableCellNormal
                  rowSpan={2}
                  className='BottomBorder'
                  data-selenium-id='Report_Journal-JPM-Table-AllModeHeadRow1Cell1'
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['jpm'] ?? 0,
                        columnMinWidthMap['jpm']
                      ) + 50
                    }px`,
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                  }}
                >
                  JPM
                </StyledTableCellNormal>
                <StyledTableCellNormal
                  rowSpan={2}
                  className='BottomBorder'
                  data-selenium-id='Report_Journal-JPM-Table-AllModeHeadRow1Cell2'
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['subjectGroup'] ?? 0,
                        columnMinWidthMap['subjectGroup']
                      ) + 50
                    }px`,
                    position: 'sticky',
                    left: `${
                      Math.max(
                        columnWidthMap['jpm'] ?? 0,
                        columnMinWidthMap['jpm']
                      ) + 50
                    }px`,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Subject Group
                </StyledTableCellNormal>
                <StyledTableCellNormal
                  rowSpan={2}
                  className='RightBottomBorder'
                  data-selenium-id='Report_Journal-JPM-Table-AllModeHeadRow1Cell3'
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['businessDivision'] ?? 0,
                        columnMinWidthMap['businessDivision']
                      ) + 50
                    }px`,
                    position: 'sticky',
                    left: `${
                      Math.max(
                        columnWidthMap['jpm'] ?? 0,
                        columnMinWidthMap['jpm']
                      ) +
                      50 +
                      Math.max(
                        columnWidthMap['subjectGroup'] ?? 0,
                        columnMinWidthMap['subjectGroup']
                      ) +
                      50
                    }px`,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Business Division
                </StyledTableCellNormal>
                <StyledTableCellGreen
                  rowSpan={2}
                  className='RightBottomBorder'
                  data-selenium-id='Report_Journal-JPM-Table-AllModeHeadRow1Cell4'
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['activeJournal'] ?? 0,
                        columnMinWidthMap['activeJournal']
                      ) + 50
                    }px`,
                    whiteSpace: 'nowrap',
                  }}
                >
                  Active Journals
                </StyledTableCellGreen>
                <StyledTableCellGreen
                  colSpan={4}
                  align='left'
                  className='RightBottomBorder'
                  data-selenium-id='Report_Journal-JPM-Table-AllModeHeadRow1Cell5'
                  sx={{
                    width: `${calcAllJournalWidth()}px`,
                  }}
                >
                  Journals Included
                </StyledTableCellGreen>
                <StyledTableCellGreen
                  rowSpan={2}
                  align='left'
                  // sx={{ minWidth: 70, maxWidth: 70 }}
                  className='BottomBorder'
                  data-selenium-id='Report_Journal-JPM-Table-AllModeHeadRow1Cell6'
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['activePercentage'] ?? 0,
                        columnMinWidthMap['activePercentage']
                      ) + 50
                    }px`,
                    whiteSpace: 'nowrap',
                  }}
                >
                  Active %
                </StyledTableCellGreen>
                <StyledTableCellGreen
                  rowSpan={2}
                  // sx={{ minWidth: 130, maxWidth: 130 }}
                  className='BottomBorder'
                  data-selenium-id='Report_Journal-JPM-Table-AllModeHeadRow1Cell7'
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['optInJournals'] ?? 0,
                        columnMinWidthMap['optInJournals']
                      ) + 50
                    }px`,
                  }}
                >
                  <div
                  // style={{ textAlign: 'right' }}
                  >
                    Opt-In
                  </div>
                  <div
                  // style={{ textAlign: 'right' }}
                  >{`Journals_${ReportJournalJPMMonth}`}</div>
                </StyledTableCellGreen>
                <StyledTableCellGreen
                  rowSpan={2}
                  // sx={{ minWidth: 130, maxWidth: 130 }}
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['optOutJournals'] ?? 0,
                        columnMinWidthMap['optOutJournals']
                      ) + 50
                    }px`,
                  }}
                  className='RightBottomBorder'
                  data-selenium-id='Report_Journal-JPM-Table-AllModeHeadRow1Cell8'
                >
                  <div
                  // style={{ textAlign: 'right' }}
                  >
                    Opt-Out
                  </div>
                  <div
                  // style={{ textAlign: 'right' }}
                  >{`Journals_${ReportJournalJPMMonth}`}</div>
                </StyledTableCellGreen>
              </TableRow>
              <TableRow data-selenium-id='Report_Journal-JPM-Table-AllModeHeadRow2'>
                <StyledTableCellDisGreen
                  className='BottomBorder'
                  align='left'
                  // sx={{ top: 52.5, minWidth: 165, maxWidth: 165 }}
                  sx={{
                    top: 42,
                    width: `${
                      Math.max(
                        columnWidthMap['commission'] ?? 0,
                        columnMinWidthMap['commission']
                      ) + 50
                    }px`,
                    minWidth: 0,
                    maxWidth: '143px',
                  }}
                  key='1'
                  style={{ 'padding-top': '5px', 'padding-bottom': '5px' }}
                  data-selenium-id='Report_Journal-JPM-Table-AllModeHeadRow2Cell1'
                >
                  SI Commissioning
                </StyledTableCellDisGreen>
                <StyledTableCellDisGreen
                  className='BottomBorder'
                  align='left'
                  // sx={{ top: 52.5, minWidth: 190, maxWidth: 190 }}
                  sx={{
                    top: 42,
                    width: `${
                      Math.max(
                        columnWidthMap['basic'] ?? 0,
                        columnMinWidthMap['basic']
                      ) + 50
                    }px`,
                  }}
                  key='2'
                  style={{ 'padding-top': '5px', 'padding-bottom': '5px' }}
                  data-selenium-id='Report_Journal-JPM-Table-AllModeHeadRow2Cell2'
                >
                  Basic Management
                </StyledTableCellDisGreen>
                <StyledTableCellDisGreen
                  className='BottomBorder'
                  align='left'
                  // sx={{ top: 52.5, minWidth: 80, maxWidth: 80 }}
                  sx={{
                    top: 42,
                    width: `${
                      Math.max(
                        columnWidthMap['onHold'] ?? 0,
                        columnMinWidthMap['onHold']
                      ) + 50
                    }px`,
                  }}
                  key='3'
                  style={{ 'padding-top': '5px', 'padding-bottom': '5px' }}
                  data-selenium-id='Report_Journal-JPM-Table-AllModeHeadRow2Cell3'
                >
                  On-Hold
                </StyledTableCellDisGreen>
                <StyledTableCellDisGreen
                  className='RightBottomBorder'
                  align='left'
                  // sx={{ top: 52.5, minWidth: 80, maxWidth: 80 }}
                  sx={{
                    top: 42,
                    width: `${
                      Math.max(
                        columnWidthMap['total'] ?? 0,
                        columnMinWidthMap['total']
                      ) + 50
                    }px`,
                  }}
                  key='3'
                  style={{ 'padding-top': '5px', 'padding-bottom': '5px' }}
                  data-selenium-id='Report_Journal-JPM-Table-AllModeHeadRow2Cell4'
                >
                  Total
                </StyledTableCellDisGreen>
              </TableRow>
            </TableHead>
            <TableBody data-selenium-id='Report_Journal-JPM-Table-AllModeBody'>
              {!loading &&
              ReportJournalJPMData != null &&
              ReportJournalJPMData != undefined &&
              ReportJournalJPMData.length != 0
                ? ReportJournalJPMData.map(row => {
                    if (row.jpm === 'Total') {
                      return (
                        <StyledTableRow data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow2'>
                          <StyledTableCell
                            className='ReportTotalTextSize NoBorder'
                            align='right'
                            data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow2Cell1'
                            sx={{
                              position: 'sticky',
                              left: 0,
                              zIndex: 6,
                              backgroundColor: '#F1F3F5',
                            }}
                          >
                            <Box>&nbsp;</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            className='ReportTotalTextSize NoBorder'
                            align='left'
                            data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow2Cell2'
                            sx={{
                              position: 'sticky',
                              left: `${
                                Math.max(
                                  columnWidthMap['jpm'] ?? 0,
                                  columnMinWidthMap['jpm']
                                ) + 50
                              }px`,
                              zIndex: 6,
                              backgroundColor: '#F1F3F5',
                            }}
                          >
                            <SimpleTooltip title={row.subjectGroup}>
                              {row.sgAbbr}
                              <Box></Box>
                            </SimpleTooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            className='RightBorder ReportTotalTextSize'
                            align='right'
                            data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow2Cell3'
                            sx={{
                              position: 'sticky',
                              left: `${
                                Math.max(
                                  columnWidthMap['jpm'] ?? 0,
                                  columnMinWidthMap['jpm']
                                ) +
                                50 +
                                Math.max(
                                  columnWidthMap['subjectGroup'] ?? 0,
                                  columnMinWidthMap['subjectGroup']
                                ) +
                                50
                              }px`,
                              zIndex: 6,
                              backgroundColor: '#F1F3F5',
                            }}
                          >
                            <Box>Total</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            className='RightBorder ReportTotalTextSize ReportNumberTextColor'
                            align='left'
                            data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow2Cell4'
                          >
                            <SimpleTooltip title={row.activeJournal}>
                              <Box>{row.activeJournal}</Box>
                            </SimpleTooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize NoBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow2Cell5'
                          >
                            <Box> {row.commission}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize NoBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow2Cell6'
                          >
                            <Box>{row.basic}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize ReportNumberTextColor'
                            data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow2Cell7'
                          >
                            <Box>{row.onHold}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder ReportTotalTextSize ReportNumberTextColor'
                            data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow2Cell8'
                          >
                            <Box>{row.total}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize NoBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow2Cell9'
                          >
                            <Box>{row.activePercentage}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='NoBorder ReportTotalTextSize ReportNumberTextColor'
                            data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow2Cell10'
                          >
                            <Box>{row.optInJournals}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder ReportTotalTextSize ReportNumberTextColor'
                            data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow2Cell11'
                          >
                            <Box>
                              {row.optOutJournals ? row.optOutJournals : 0}
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    }
                    return (
                      <StyledTableRow data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow1'>
                        <StyledTableCell
                          align='left'
                          data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow1Cell1'
                          sx={{
                            position: 'sticky',
                            left: 0,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                        >
                          <SimpleTooltip title={row.jpmEmail}>
                            {row.jpm}
                          </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow1Cell2'
                          sx={{
                            position: 'sticky',
                            left: `${
                              Math.max(
                                columnWidthMap['jpm'] ?? 0,
                                columnMinWidthMap['jpm']
                              ) + 50
                            }px`,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                        >
                          <SimpleTooltip title={row.subjectGroup}>
                            {row.sgAbbr}
                          </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          className='RightBorder'
                          align='left'
                          data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow1Cell3'
                          sx={{
                            position: 'sticky',
                            left: `${
                              Math.max(
                                columnWidthMap['jpm'] ?? 0,
                                columnMinWidthMap['jpm']
                              ) +
                              50 +
                              Math.max(
                                columnWidthMap['subjectGroup'] ?? 0,
                                columnMinWidthMap['subjectGroup']
                              ) +
                              50
                            }px`,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                        >
                          <SimpleTooltip title={row.businessDivision}>
                            {row.businessDivision}
                          </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          className='RightBorder ReportNumberTextColor'
                          align='left'
                          data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow1Cell4'
                        >
                          <SimpleTooltip title={row.activeJournal}>
                            {row.activeJournal}
                          </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow1Cell5'
                        >
                          {row.commission}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow1Cell6'
                        >
                          {row.basic}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow1Cell7'
                        >
                          {row.onHold}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportNumberTextColor'
                          data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow1Cell8'
                        >
                          {row.total}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow1Cell9'
                        >
                          {row.activePercentage}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow1Cell10'
                        >
                          {row.optInJournals}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportNumberTextColor'
                          data-selenium-id='Report_Journal-JPM-Table-AllModeBodyRow1Cell11'
                        >
                          {row.optOutJournals ? row.optOutJournals : 0}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                : !loading && (
                    <ReportNoData id='reportJournalJPMNoResultTableCell' />
                  )}
            </TableBody>
          </Table>
          {loading && (
            <Box
              sx={{
                width: 'auto',
                marginTop: '45px',
                marginLeft: '50%',
              }}
              data-selenium-id='Report_Journal-JPM-Table-AllModeLoadingBox'
            >
              <CircularProgress data-selenium-id='Report_Journal-JPM-Table-AllModeCircularProgress' />
            </Box>
          )}
        </TableContainer>
      </TableBox2>
    );
  } else {
    return (
      <TableBox2
        data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeBox'
        dataList={[ReportJournalJPMData]}
        tableKey={'Report_Journal-JPMTable'}
        footerHeight={0}
      >
        <TableContainer
          id='reportJournalJPMTable'
          data-selenium-id='Report_Journal-JPM-Table-OAModeTableContainer'
          sx={{ maxHeight: '560px' }}
        >
          <Table
            stickyHeader
            aria-label='sticky table'
            data-selenium-id='Report_Journal-JPM-Table-OAModeTable'
            sx={{
              tableLayout: 'fixed',
              width: 'max-content',
              maxWidth: 'none',
            }}
          >
            <TableHead data-selenium-id='Report_Journal-JPM-Table-OAModeTableHead'>
              <TableRow data-selenium-id='Report_Journal-BusinessDivision-Table-OAModeHeadRow1'>
                <StyledTableCellNormal
                  rowSpan={2}
                  className='BottomBorder'
                  data-selenium-id='Report_Journal-JPM-Table-OAModeHeadRow1Cell1'
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['jpm'] ?? 0,
                        columnMinWidthMap['jpm']
                      ) + 50
                    }px`,
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                  }}
                >
                  JPM
                </StyledTableCellNormal>
                <StyledTableCellNormal
                  rowSpan={2}
                  className='BottomBorder'
                  data-selenium-id='Report_Journal-JPM-Table-OAModeHeadRow1Cell2'
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['subjectGroup'] ?? 0,
                        columnMinWidthMap['subjectGroup']
                      ) + 50
                    }px`,
                    position: 'sticky',
                    left: `${
                      Math.max(
                        columnWidthMap['jpm'] ?? 0,
                        columnMinWidthMap['jpm']
                      ) + 50
                    }px`,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Subject Group
                </StyledTableCellNormal>
                <StyledTableCellNormal
                  rowSpan={2}
                  className='RightBottomBorder'
                  data-selenium-id='Report_Journal-JPM-Table-OAModeHeadRow1Cell3'
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['businessDivision'] ?? 0,
                        columnMinWidthMap['businessDivision']
                      ) + 50
                    }px`,
                    position: 'sticky',
                    left: `${
                      Math.max(
                        columnWidthMap['jpm'] ?? 0,
                        columnMinWidthMap['jpm']
                      ) +
                      50 +
                      Math.max(
                        columnWidthMap['subjectGroup'] ?? 0,
                        columnMinWidthMap['subjectGroup']
                      ) +
                      50
                    }px`,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Business Division
                </StyledTableCellNormal>
                <StyledTableCellPurple
                  rowSpan={2}
                  className='RightBottomBorder'
                  data-selenium-id='Report_Journal-JPM-Table-OAModeHeadRow1Cell4'
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['activeJournal'] ?? 0,
                        columnMinWidthMap['activeJournal']
                      ) + 50
                    }px`,
                    whiteSpace: 'nowrap',
                  }}
                >
                  Active Journals
                </StyledTableCellPurple>
                <StyledTableCellPurple
                  colSpan={4}
                  align='left'
                  className='RightBottomBorder'
                  data-selenium-id='Report_Journal-JPM-Table-OAModeHeadRow1Cell5'
                  sx={{
                    width: `${calcAllJournalWidth()}px`,
                  }}
                >
                  Journals Included
                </StyledTableCellPurple>
                <StyledTableCellPurple
                  rowSpan={2}
                  align='left'
                  // sx={{ minWidth: 70, maxWidth: 70 }}
                  className='BottomBorder'
                  data-selenium-id='Report_Journal-JPM-Table-OAModeHeadRow1Cell6'
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['activePercentage'] ?? 0,
                        columnMinWidthMap['activePercentage']
                      ) + 50
                    }px`,
                    whiteSpace: 'nowrap',
                  }}
                >
                  Active %
                </StyledTableCellPurple>
                <StyledTableCellPurple
                  rowSpan={2}
                  // sx={{ minWidth: 130, maxWidth: 130 }}
                  className='BottomBorder'
                  data-selenium-id='Report_Journal-JPM-Table-OAModeHeadRow1Cell7'
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['optInJournals'] ?? 0,
                        columnMinWidthMap['optInJournals']
                      ) + 50
                    }px`,
                  }}
                >
                  <div style={{ textAlign: 'left' }}>Opt-In</div>
                  <div
                    style={{ textAlign: 'left' }}
                  >{`Journals_${ReportJournalJPMMonth}`}</div>
                </StyledTableCellPurple>
                <StyledTableCellPurple
                  rowSpan={2}
                  // sx={{ minWidth: 130, maxWidth: 130 }}
                  sx={{
                    width: `${
                      Math.max(
                        columnWidthMap['optOutJournals'] ?? 0,
                        columnMinWidthMap['optOutJournals']
                      ) + 50
                    }px`,
                  }}
                  className='RightBottomBorder'
                  data-selenium-id='Report_Journal-JPM-Table-OAModeHeadRow1Cell8'
                >
                  <div style={{ textAlign: 'left' }}>Opt-Out</div>
                  <div
                    style={{ textAlign: 'left' }}
                  >{`Journals_${ReportJournalJPMMonth}`}</div>
                </StyledTableCellPurple>
              </TableRow>
              <TableRow data-selenium-id='Report_Journal-JPM-Table-OAModeHeadRow2'>
                <StyledTableCellDisPurple
                  className='BottomBorder'
                  align='left'
                  // sx={{ top: 52.5, minWidth: 165, maxWidth: 165 }}
                  sx={{
                    top: 42,
                    width: `${
                      Math.max(
                        columnWidthMap['commission'] ?? 0,
                        columnMinWidthMap['commission']
                      ) + 50
                    }px`,
                    maxWidth: '143px',
                    minWidth: '0',
                  }}
                  key='1'
                  style={{ 'padding-top': '5px', 'padding-bottom': '5px' }}
                  data-selenium-id='Report_Journal-JPM-Table-OAModeHeadRow2Cell1'
                >
                  SI Commissioning
                </StyledTableCellDisPurple>
                <StyledTableCellDisPurple
                  className='BottomBorder'
                  align='left'
                  // sx={{ top: 52.5, minWidth: 190, maxWidth: 190 }}
                  sx={{
                    top: 42,
                    width: `${
                      Math.max(
                        columnWidthMap['basic'] ?? 0,
                        columnMinWidthMap['basic']
                      ) + 50
                    }px`,
                  }}
                  key='2'
                  style={{ 'padding-top': '5px', 'padding-bottom': '5px' }}
                  data-selenium-id='Report_Journal-JPM-Table-OAModeHeadRow2Cell2'
                >
                  Basic Management
                </StyledTableCellDisPurple>
                <StyledTableCellDisPurple
                  className='BottomBorder'
                  align='left'
                  // sx={{ top: 52.5, minWidth: 80, maxWidth: 80 }}
                  sx={{
                    top: 42,
                    width: `${
                      Math.max(
                        columnWidthMap['onHold'] ?? 0,
                        columnMinWidthMap['onHold']
                      ) + 50
                    }px`,
                  }}
                  key='3'
                  style={{ 'padding-top': '5px', 'padding-bottom': '5px' }}
                  data-selenium-id='Report_Journal-JPM-Table-OAModeHeadRow2Cell3'
                >
                  On-Hold
                </StyledTableCellDisPurple>
                <StyledTableCellDisPurple
                  className='RightBottomBorder'
                  align='left'
                  // sx={{ top: 52.5, minWidth: 80, maxWidth: 80 }}
                  sx={{
                    top: 42,
                    width: `${
                      Math.max(
                        columnWidthMap['total'] ?? 0,
                        columnMinWidthMap['total']
                      ) + 50
                    }px`,
                  }}
                  key='3'
                  style={{ 'padding-top': '5px', 'padding-bottom': '5px' }}
                  data-selenium-id='Report_Journal-JPM-Table-OAModeHeadRow2Cell4'
                >
                  Total
                </StyledTableCellDisPurple>
              </TableRow>
            </TableHead>
            <TableBody data-selenium-id='Report_Journal-JPm-Table-OAModeBody'>
              {!loading &&
              ReportJournalJPMData != null &&
              ReportJournalJPMData != undefined &&
              ReportJournalJPMData.length != 0
                ? ReportJournalJPMData.map(row => {
                    if (row.jpm === 'Total') {
                      return (
                        <StyledTableRow data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow2'>
                          <StyledTableCell
                            className='ReportTotalTextSize NoBorder'
                            align='right'
                            data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow2Cell1'
                            sx={{
                              position: 'sticky',
                              left: 0,
                              zIndex: 6,
                              backgroundColor: '#F1F3F5',
                            }}
                          >
                            <SimpleTooltip title={row.jpm}>
                              <Box></Box>
                            </SimpleTooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            className='ReportTotalTextSize NoBorder'
                            align='left'
                            data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow2Cell2'
                            sx={{
                              position: 'sticky',
                              left: `${
                                Math.max(
                                  columnWidthMap['jpm'] ?? 0,
                                  columnMinWidthMap['jpm']
                                ) + 50
                              }px`,
                              zIndex: 6,
                              backgroundColor: '#F1F3F5',
                            }}
                          >
                            <SimpleTooltip title={row.subjectGroup}>
                              <Box></Box>
                            </SimpleTooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            className='RightBorder ReportTotalTextSize'
                            align='left'
                            data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow2Cell3'
                            sx={{
                              position: 'sticky',

                              left: `${
                                Math.max(
                                  columnWidthMap['jpm'] ?? 0,
                                  columnMinWidthMap['jpm']
                                ) +
                                50 +
                                Math.max(
                                  columnWidthMap['subjectGroup'] ?? 0,
                                  columnMinWidthMap['subjectGroup']
                                ) +
                                50
                              }px`,
                              zIndex: 6,
                              backgroundColor: '#F1F3F5',
                            }}
                          >
                            <SimpleTooltip title={row.businessDivision}>
                              <Box>Total</Box>
                            </SimpleTooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            className='RightBorder ReportTotalTextSize ReportNumberTextColor'
                            align='left'
                            data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow2Cell4'
                          >
                            <SimpleTooltip title={row.activeJournal}>
                              <Box>{row.activeJournal}</Box>
                            </SimpleTooltip>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize NoBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow2Cell5'
                          >
                            <Box> {row.commission}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize NoBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow2Cell6'
                          >
                            <Box>{row.basic}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize ReportNumberTextColor'
                            data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow2Cell7'
                          >
                            <Box>{row.onHold}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder ReportTotalTextSize ReportNumberTextColor'
                            data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow2Cell8'
                          >
                            <Box>{row.total}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='ReportTotalTextSize NoBorder ReportNumberTextColor'
                            data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow2Cell9'
                          >
                            <Box>{row.activePercentage}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='NoBorder ReportTotalTextSize ReportNumberTextColor'
                            data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow2Cell10'
                          >
                            <Box>{row.optInJournals}</Box>
                          </StyledTableCell>
                          <StyledTableCell
                            align='left'
                            className='RightBorder ReportTotalTextSize ReportNumberTextColor'
                            data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow2Cell11'
                          >
                            <Box>
                              {row.optOutJournals ? row.optOutJournals : 0}
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    }
                    return (
                      <StyledTableRow data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow1'>
                        <StyledTableCell
                          align='left'
                          data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow1Cell1'
                          sx={{
                            position: 'sticky',
                            left: 0,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                        >
                          <SimpleTooltip title={row.jpmEmail}>
                            {row.jpm}
                          </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow1Cell2'
                          sx={{
                            position: 'sticky',
                            left: `${
                              Math.max(
                                columnWidthMap['jpm'] ?? 0,
                                columnMinWidthMap['jpm']
                              ) + 50
                            }px`,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                        >
                          <SimpleTooltip title={row.subjectGroup}>
                            {row.sgAbbr}
                          </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          className='RightBorder'
                          align='left'
                          data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow1Cell3'
                          sx={{
                            position: 'sticky',
                            left: `${
                              Math.max(
                                columnWidthMap['jpm'] ?? 0,
                                columnMinWidthMap['jpm']
                              ) +
                              50 +
                              Math.max(
                                columnWidthMap['subjectGroup'] ?? 0,
                                columnMinWidthMap['subjectGroup']
                              ) +
                              50
                            }px`,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                        >
                          <SimpleTooltip title={row.businessDivision}>
                            {row.businessDivision}
                          </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          className='RightBorder ReportNumberTextColor'
                          align='left'
                          data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow1Cell4'
                        >
                          <SimpleTooltip title={row.activeJournal}>
                            {row.activeJournal}
                          </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow1Cell5'
                        >
                          {row.commission}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow1Cell6'
                        >
                          {row.basic}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow1Cell7'
                        >
                          {row.onHold}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportNumberTextColor'
                          data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow1Cell8'
                        >
                          {row.total}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow1Cell9'
                        >
                          {row.activePercentage}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='ReportNumberTextColor'
                          data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow1Cell10'
                        >
                          {row.optInJournals}
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportNumberTextColor'
                          data-selenium-id='Report_Journal-JPM-Table-OAModeBodyRow1Cell11'
                        >
                          {row.optOutJournals ? row.optOutJournals : 0}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                : !loading && (
                    <ReportNoData id='reportJournalJPMNoResultTableCell' />
                  )}
            </TableBody>
          </Table>
          {loading && (
            <Box
              sx={{
                width: 'auto',
                marginTop: '45px',
                marginLeft: '50%',
              }}
              data-selenium-id='Report_Journal-JPM-Table-OAModeLoadingBox'
            >
              <CircularProgress data-selenium-id='Report_Journal-JPM-Table-OAModeCircularProgress' />
            </Box>
          )}
        </TableContainer>
      </TableBox2>
    );
  }
}

import { JournalStackChartOptions } from '@/pages/Report/chartUtils';

// chart config
const BARCHART_TOP = 82;
const BARCHART_HEIGHT = 312;
const BAR_COLOR = ['#DFE4E8', '#D7F1EE', '#98A7B6', '#42C1B3'];
const BAR_LABEL_COLOR = ['#596A7C', '#596A7C', '#FFFFFF', '#FFFFFF'];
const BAR_BORDER_COLOR = ['#98A7B6', '#42C1B3', '#98A7B6', '#42C1B3'];

const NUM_OF_SG_THRESHOLD = 9;

const SG_BAR_WIDTH = [18, 48];
const BD_BAR_WIDTH = 42;

const X_AXIS_LABEL_WIDTH = 45;
const SG_RATE_LABEL_POS_X = [-20, -25];
const BD_RATE_LABEL_POS_X = -31;

const SG_BAR_LABEL_FONT_SIZE = [10, 14];
const BD_BAR_LABEL_FONT_SIZE = 15;

const LEGEND_HEIGHT = 40;
const LEGEND_PADDING_BOTTOM = 0;
const LEGEND_POS = 200;

const LEGEND_LABEL_STYLE = {
  icon: 'none',
  selectedMode: false,
  itemGap: 15,
  width: '50px',
  textStyle: {
    fontFamily: 'Open Sans',
    fontSize: '10px',
    fontWeight: '700',
    color: '#596A7C',
  },
  formatter: label => {
    // use the last two legends to show the labels, and hide or others
    // temp solution to get last two legends
    const allIndex = label.indexOf('Journals without Active SI');
    const oaIndex = label.indexOf('Journals without Active SI OA');
    // console.log(oaIndex);
    if (oaIndex >= 0) {
      return 'OA';
    } else if (allIndex >= 0) {
      return 'All';
    }
  },
};

/**
 * overwrite the default configuration
 */

export class NormalChartOptions extends JournalStackChartOptions {
  // constructor(type, isSubjectGroup, isDownload, barSeriesValue) {
  constructor(type, isSubjectGroup, isDownload, legendText) {
    // console.log('===type===', type);
    // console.log('===isSubjectGroup===', isSubjectGroup);
    super();

    // overwrite the default properties
    this.type = type;
    this.isSubjectGroup = isSubjectGroup;
    this.isDownload = isDownload;
    this.xAxisLabelWidth = isSubjectGroup ? X_AXIS_LABEL_WIDTH : undefined;
    this.numberOfSG = 0;
    this.rateLabelPositionX = -20;
    this.barchartTop = BARCHART_TOP;
    this.legendHeight = LEGEND_HEIGHT;
    this.pos = LEGEND_POS;
    this.legendText = legendText;
    this.barchartHeight = BARCHART_HEIGHT;
  }

  // annual number bar series
  getNumberBarSerie({ barColor, showLabel = false }) {
    return {
      type: 'bar',
      emphasis: {
        disabled: true,
      },
      barMinHeight: 3,
      itemStyle: {
        borderRadius: [5, 5, 0, 0],
      },
      label: {
        show: showLabel,
        color: '#FFFFFF',
        verticalAlign: 'middle',
        // fontSize: 7,
        fontSize: this.getLabelFontSize(),
        fontWeight: 500,
      },
      color: barColor,
      barMaxWidth: this.getBarWidth(),
      barMinWidth: this.getBarWidth(),
    };
  }

  // current year bar serie
  getBarSerie({ index, stack, isOnTop }) {
    const barColor = this.getBarColor(index);
    const labelColor = this.getBarLabelColor(index);
    const borderColor = this.getBarBorderColor(index);

    return {
      type: 'bar',
      emphasis: {
        disabled: true,
      },
      barMinHeight: 12,
      itemStyle: {
        normal: {
          borderWidth: 1,
          borderType: 'solid',
          borderColor: borderColor,
        },
      },
      label: {
        show: true,
        color: labelColor,
        verticalAlign: 'middle',
        // fontSize: 7,
        fontSize: this.getLabelFontSize(),
        fontWeight: 500,
        formatter: params => {
          // console.log('===params===', params);
          if (params.data[index + 1] === 0) {
            return '';
          }
          return params.data[index + 1];
        },
      },
      color: barColor,
      barMaxWidth: this.getBarWidth(),
      barMinWidth: this.getBarWidth(),
      stack,
      // barGap: '-12%',
      // z: 3, // cover first 2 bars
    };
  }

  // get FY increasing rate bar serie - invisible
  getRateBarSerie(stack) {
    return {
      type: 'bar',
      emphasis: {
        disabled: true,
      },
      tooltip: {
        show: false, // hide tooltip
      },
      color: '#FFFFFF',
      // barMaxWidth: 1,
      // z: 2, // cover first two bars, but under 3rd bar
      encode: {
        // y: 3, // data source for rate
        value: 1,
      },
      // data: [0],
      stack,
    };
  }

  // get FY increasing rate bar serie - invisible
  getRateBarSerieLabel({ backgroundColor, titleColor, iconImg, formatter }) {
    return {
      show: true,
      verticalAlign: 'top',
      backgroundColor,
      borderRadius: 1,
      height: 12,
      width: 27,
      padding: [0, 2, 0, 2],
      lineHeight: 15,
      formatter,
      rich: {
        title: {
          color: titleColor, // positive
          padding: [0, 0, 1, 2],
          fontSize: 7,
          lineHeight: 15,
        },
        icon: {
          align: 'center',
          backgroundColor: {
            image: iconImg, // positive
          },
          height: 9,
          width: 6,
        },
        text: {
          color: titleColor,
          align: 'center',
          fontSize: 7,
          lineHeight: 15,
          height: 9,
          width: 6,
        },
      },
    };
  }

  getBarColor(index) {
    return BAR_COLOR[index];
  }

  getBarLabelColor(index) {
    return BAR_LABEL_COLOR[index];
  }

  getBarBorderColor(index) {
    return BAR_BORDER_COLOR[index];
  }

  /**
   * overwrite the default getBarSeries method
   */
  getBarSeries() {
    return [
      // OA
      this.getBarSerie({
        index: 0,
        stack: 'OA',
        isOnTop: false,
      }),
      this.getBarSerie({
        index: 1,
        stack: 'OA',
        isOnTop: true,
      }),
      // All
      this.getBarSerie({
        index: 2,
        stack: 'All',
        isOnTop: false,
      }),
      this.getBarSerie({
        index: 3,
        stack: 'All',
        isOnTop: true,
      }),
    ];
  }

  /**
   * calculate the bar width
   * variants: Subject group/ Business division
   * variants: number of subject groups
   */
  getBarWidth() {
    if (!this.isSubjectGroup) {
      // is business division
      return BD_BAR_WIDTH;
    }

    // is subject group
    let barWidth = 0;
    if (this.numberOfSG > NUM_OF_SG_THRESHOLD) {
      barWidth = SG_BAR_WIDTH[0];
    } else {
      barWidth = SG_BAR_WIDTH[1];
    }

    return barWidth;
  }

  /**
   * calculate the bar label font size
   * variants: Subject group/ Business division
   * variants: number of subject groups
   */
  getLabelFontSize() {
    if (!this.isSubjectGroup) {
      // is business division
      return BD_BAR_LABEL_FONT_SIZE;
    }

    // is subject group
    let fontSize = 0;
    if (this.numberOfSG > NUM_OF_SG_THRESHOLD) {
      fontSize = SG_BAR_LABEL_FONT_SIZE[0];
    } else {
      fontSize = SG_BAR_LABEL_FONT_SIZE[1];
    }

    return fontSize;
  }

  /**
   *  get rate label position
   */
  getRateLabelPositionX() {
    if (!this.isSubjectGroup) {
      // is business division
      return BD_RATE_LABEL_POS_X;
    }

    // is subject group
    let positionX = 0;
    if (this.numberOfSG > NUM_OF_SG_THRESHOLD) {
      positionX = SG_RATE_LABEL_POS_X[0];
    } else {
      positionX = SG_RATE_LABEL_POS_X[1];
    }

    return positionX;
  }

  /**
   * set the number of Subject groups
   */
  setNumOfSG(numOfSG) {
    // console.log('setting numOfSG...', numOfSG);
    this.numberOfSG = numOfSG;
  }

  /**
   * overwrite the default getTooltip method
   */
  getTooltip() {
    const tooltip = super.getTooltip();
    // overwrite formatter
    const formatter = params => {
      // console.log('overwrite tooltip');
      // console.log(params);

      // modify OA lable on 2nd line
      let label = params.seriesName;
      const oaIndex = label.indexOf('OA');
      if (oaIndex >= 0) {
        label = 'OA ' + label.slice(0, oaIndex);
      }

      let dataStr = `<div style="padding: 0; margin: 0;">
        <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 0 0 2.5px 0;">${
          params.name
        }</p>
        <p style="font-size: 10px; font-weight: 400;color: #848484; line-height: 14px; margin: 0;">${label}&nbsp;:&nbsp;${
        params.data[params.seriesIndex + 1]
      }</p>
      </div>`;
      return dataStr;
    };

    return { ...tooltip, formatter };
  }

  /**
   * overwrite the default method
   */
  getCategoryAxisLabel() {
    const baseAxisLabel = super.getCategoryAxisLabel();

    const newAxisLabel = {
      ...baseAxisLabel,
      width: this.xAxisLabelWidth ?? undefined,
      overflow: this.isDownload ? 'break' : 'truncate', // If download, break label text into lines
    };

    return newAxisLabel;
  }

  setWidth(width) {
    this.width = width;

    if (width !== '100%') {
      const half_width = width / 2;
      this.legend_pos = half_width - 225;
    }

    return this;
  }

  /**
   * to get correct alignment and labels of legends
   * @returns
   */
  getSelectedLegend() {
    // remove 'OA' from label
    const labelFormatter = label => {
      const oaIndex = label.indexOf('OA');
      if (oaIndex >= 0) {
        return label.slice(0, oaIndex);
      } else {
        return label;
      }
    };

    const baseLegend = super.getSelectedLegend();

    const legendLabels = {
      // ['OA', 'All']
      ...LEGEND_LABEL_STYLE,
      orient: 'vertical',
      top: undefined,
      bottom: LEGEND_PADDING_BOTTOM,
      left: this.legend_pos,
      data: [this.legendText[3],this.legendText[1]],
    };
    const legendCol1 = {
      ...baseLegend,
      orient: 'vertical',
      itemGap: 11,
      top: undefined,
      bottom: LEGEND_PADDING_BOTTOM,
      left: this.legend_pos + 60,
      data: [this.legendText[3],this.legendText[1]],
      formatter: labelFormatter,
    };
    const legendCol2 = {
      ...baseLegend,
      orient: 'vertical',
      itemGap: 11,
      top: undefined,
      bottom: LEGEND_PADDING_BOTTOM,
      left: this.legend_pos + 250,
      data: [this.legendText[4], this.legendText[2]],
      formatter: labelFormatter,
    };

    const legends = [legendLabels, legendCol1, legendCol2];
    return legends;
  }
}
import { useDispatch, useSelector } from 'react-redux';
import GeneralInformationForm from './GeneralInformationForm';
import ReviewPage from '../../../ReviewPage/index';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import CreateAndUpdateFooter from '../../../../CreateAndUpdateSI/CommonComponents/CreateAndUpdateFooter';
import CreateAndUpdateMain from '../../../../CreateAndUpdateSI/CommonComponents/CreateAndUpdateMain';
import Footer from '../../../../../../components/Footer';
import Header from '../../../../../../components/Header';
import moment from 'moment';
import {
  backMatchUTCTime,
  getUTCZeroTimeOffset,
  scrollToTop,
  trimItemFilds,
  turnDateToUTC,
} from '../../../../../../utils/commonUtils';
import {
  SIDateChangeFunction,
  SISubmissionDeadlineDateChangeFunction,
} from '@/utils/commonUtils';
import { useNavigate } from 'react-router-dom';
import { openConfirm, setEditType, setTextParam } from '@/actions/SIMT-User/Alert/AlertAction';
import MainPageFramework from '../../../CGTComponents/MainPageFramework';
import CreateAndUpdateHeader from '../../../CommonComponents/CreateAndUpdateHeader';
import {createSIInIdea} from "@/actions/SIMT-SI/SpecialIssue";
//MainPage包括footer
function TabPanel(props) {
  const { id, children, value, index, sx } = props;

  if (value < 0) {
    window.history.back();
  }
  return (
    <div style={{ width: '100%' }}>
      {value === index && (
        <Box id={id} data-selenium-id={id} sx={{ p: '60px 24px', ...sx }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function IdeaAndProposalPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { createSIResult } = useSelector(state => {
    return {
      createSIResult: state.SI.createSIResult,
    };
  });
  const [activeStep, setActiveStep] = useState(0);
  const [clickedButton, setclickedButton] = useState(false);
  const [jump, setJump] = useState(true);
  // GeneralInformation用来保存前后跳转的数据
  const [GeneralInformation, setGeneralInformation] = useState({
    Stage: 'Idea/Proposal',
    HandlingCE: -1,
    JournalCode: null,
    JournalName: null,
    SIPublicationFormat: '',
    SpecialIssueTitle: '',
    SpecialIssueSource: '',
    IdeaDate: null,
    siRevenueModel: 'OA',
    // 保存数值使用，创建SI执行的时候，并不往后端传递
    HandlingCeSelectValue: '',
    revenueModel: '',
    HandlingCEReviewValue: '', // 存储HandlingCE的name，不保存email，用在review阶段
  });
  const reviewData = {
    generalInfomation: {
      'Stage:': GeneralInformation.Stage,
      'Handling CE:': GeneralInformation.HandlingCEReviewValue,
      'Journal Code:': GeneralInformation.JournalCode,
      'Journal Name:': GeneralInformation.JournalName,
      'SI Publication Format: ': GeneralInformation.SIPublicationFormat,
      'SI Revenue Model:': GeneralInformation.siRevenueModel,
      'Special Issue Title: ': trimItemFilds(
        GeneralInformation.SpecialIssueTitle
      ),
      'Special Issue Source:': GeneralInformation.SpecialIssueSource,
      'Idea Date:':
        GeneralInformation.IdeaDate !== null
          ? moment(GeneralInformation.IdeaDate).utc().format('YYYY-MM-DD')
          : '',
    },
  };

  const [isOA, setIsOA] = React.useState(
    GeneralInformation.revenueModel == 'OA' ? true : false
  );
  const steps = ['General information', 'Review & Create'];
  useEffect(() => {
    setIsOA(GeneralInformation.revenueModel === 'OA' ? true : false);
  }, [GeneralInformation.revenueModel]);

  const handleNext = async () => {
    setclickedButton(true);
    setClickTime(clickTime + 1);
    if (activeStep < 1 && jump) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setclickedButton(false);
      scrollToTop();
      return;
    }
    if (activeStep === 1) {
      // 最后一步，create
      await dispatch(
        createSIInIdea({
          handlingCe: GeneralInformation.HandlingCE,
          journalCode: GeneralInformation.JournalCode,
          journalName: GeneralInformation.JournalName,
          ideaDate:
            GeneralInformation.IdeaDate !== null
              ? SIDateChangeFunction(GeneralInformation.IdeaDate)
              : '',
          siSource: GeneralInformation.SpecialIssueSource,
          specialIssueTitle: trimItemFilds(
            GeneralInformation.SpecialIssueTitle
          ),
          specialIssueType: GeneralInformation.SIPublicationFormat,
          stage: GeneralInformation.Stage,
          siRevenueModel: GeneralInformation.siRevenueModel,
        })
      );
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    setJump(true);
    scrollToTop();
    // setGeneralInformationError(false);
  };

  useEffect(() => {
    if ((createSIResult !== null) & (createSIResult !== '')) {
      if (createSIResult['code'] === 200) {
        if (createSIResult.data !== undefined) {
          navigate(`/simt/auth/siRelated/si/detail/${createSIResult.data}`);
          dispatch(setTextParam());
          dispatch(setEditType('createSI'));
          dispatch(openConfirm());
        }
      } else {
        dispatch(setTextParam());
        dispatch(setEditType('error'));
        dispatch(openConfirm());
      }
    }
  }, [createSIResult]);

  const handleStringtoList = value => {
    return value.split(',');
  };

  const IdeaProposalMandatoryList = handleStringtoList(
    localStorage.getItem('IdeaProposalMandatoryList')
  );

  // 检查是否点击了button
  const [clickTime, setClickTime] = useState(0);

  const [GeneralInformationError, setGeneralInformationError] = useState(false);
  //容器内部
  const container = (
    <div>
      {/* 需要跳转的页面，index为顺序 */}
      <TabPanel
        id='createSI_ideaAndProposalPage_generalInfomationPage_tabpanel'
        value={activeStep}
        index={0}
        sx={{ padding: '0px' }}
      >
        <GeneralInformationForm
          id='createIdeaAndProposalGeneralInformationPage'
          data-selenium-id='SIPage_CreateAndUpdateSI_IdeaAndProposalPage_MainPage-GeneralInformationPage'
          MandatoryList={IdeaProposalMandatoryList}
          GeneralInformation={GeneralInformation}
          setGeneralInformation={setGeneralInformation}
          clickedButton={clickedButton}
          clickTime={clickTime}
          setHasError={setGeneralInformationError}
          setJump={setJump}
        />
      </TabPanel>
      <TabPanel id='createSI_ideaAndProposalPage_reviewPage_tabpanel' value={activeStep} index={1} sx={{ padding: '0px' }}>
        <ReviewPage
          id='createIdeaAndProposalReviewAndCreatePage'
          data-selenium-id='SIPage_CreateAndUpdateSI_IdeaAndProposalPage_MainPage-ReviewAndCreatePage'
          reviewData={reviewData}
          setValue={() => {
            // This page will not use this function
          }}
          isOA={isOA}
        />
      </TabPanel>
    </div>
  );

  const actionBar = (
    <CreateAndUpdateFooter
      id='createSI_ideaAndProposalPage_mainPage_actionbar'
      activeStep={activeStep}
      lastStep={1}
      handleBack={handleBack}
      handleNextOrCreate={handleNext}
      Err={GeneralInformationError}
    />
  );

  const stepper = (
    <CreateAndUpdateHeader
      id='createSI_ideaAndProposalPage_mainPage_header'
      activeStep={activeStep}
      steps={steps}
    />
  );

  return (
    <MainPageFramework
      id='createSI_ideaAndProposalPage_mainPage'
      actionBar={actionBar}
      stepper={stepper}
    >
      <CreateAndUpdateMain
        id='createSI_ideaAndProposalPage_mainPage_content'
        container={container}
      />
    </MainPageFramework>
  );
}

export default IdeaAndProposalPage;

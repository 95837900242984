import moment from 'moment/moment';
import { setSnackbarMessageAndOpen } from '../actions/SnackbarAction';
import { SEVERITIES } from '../modules/ConfirmSnackBar/CommonSnackBar';
import { useEffect, useRef, useState } from 'react';
import responseToMessageIdConfig from '../configs/responseToMessageIdConfig';
import store from '../index';
import { axiosResponseEmptyInstance } from './axios-response';
import { tr } from 'date-fns/locale';

export const formatDateString = x => (x ? moment(x).utc().format('yyyy-MM-DD') : '');
export const formatDatetimeString = x =>
  x ? moment(x).utc().format('yyyy-MM-DD HH:mm:ss') : '';

export function getFileExtension(filename) {
  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex === -1 || dotIndex === filename.length - 1) {
    return ''; // 没有找到点或者点在最后一个字符的位置，返回空字符串
  }
  return filename.slice(dotIndex);
}

/**
 * 从后端拿到下载流之后，下载文件
 * @param {*} dataFlow 下载流
 * @param {*} fileName 下载的文件名
 */
export function downloadFile(dataFlow, fileName) {
  if (dataFlow?.code === 500) {
    store.dispatch(
      setSnackbarMessageAndOpen('common.failToDownload', {}, SEVERITIES.error)
    );
  }
  let blob;
  if (fileName.split('.')[1] === 'xls' || fileName.split('.')[1] === 'xlsx') {
    blob = new Blob([dataFlow], {
      type: 'application/vnd.ms-excel;charset=UTF-8',
    });
  }else if (fileName.split('.')[1] === 'docx') {
    blob = new Blob([dataFlow], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
  } else {
    blob = new Blob(
      [
        new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
        dataFlow,
      ],
      {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
    );
  }
  if ('download' in document.createElement('a')) {
    // 非IE下载
    const elink = document.createElement('a');
    elink.download = fileName;
    elink.style.display = 'none';
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放 URL对象
    document.body.removeChild(elink);
  }
}

/**
 * 根据传入的statusCode展示底部的snackbar
 * example:
 *    dispatch(handleDefaultErrorPopup(404));
 *      --> 底部弹窗显示：404 NOT FOUND ERROR occurred.
 *
 * 如需正常显示，需要在en.js中加入相应的声明，在common中，格式为errorXXXOcc，XXX是HTTP状态码
 *    Occ后缀意味着"xxx error occurred."，是个完整的句子
 * @param statusCode  当前支持404，400，500和未知错误，字符串、int都可以
 * @returns {(function(*): void)|*} 返回dispatch，需要dispatch(handleDef....(statusCode))
 */
export function handleDefaultErrorPopup(statusCode) {
  return dispatch => {
    switch (statusCode) {
      case 404:
        dispatch(
          setSnackbarMessageAndOpen('common.error404Occ', {}, SEVERITIES.error)
        );
        break;
      case 400:
        dispatch(
          setSnackbarMessageAndOpen('common.error400Occ', {}, SEVERITIES.error)
        );
        break;
      case 500:
        dispatch(
          setSnackbarMessageAndOpen('common.error500Occ', {}, SEVERITIES.error)
        );
        break;
      default:
        dispatch(
          setSnackbarMessageAndOpen(
            'common.errorundefinedOcc',
            {},
            SEVERITIES.error
          )
        );
    }
  };
}

/**
 * 传入err对象，判断这个err是否是超时timeout error
 * @param err error对象
 * @returns {boolean|*}   若是timeout则返回true，否则返回false
 */
export function isTimeoutError(err) {
  return (
    err.code === 'ECONNABORTED' ||
    err.message === 'Network Error' ||
    err.message?.includes('timeout')
  );
}

/**
 * 防抖函数
 * @param {*} fn 执行函数
 * @param {*} ms 延迟秒数
 * @returns
 */
export function debounce(fn, ms) {
  let timeId;
  return function () {
    timeId && clearTimeout(timeId);
    timeId = setTimeout(() => {
      fn.apply(this, arguments);
    }, ms);
  };
}

/**
 * 节流函数
 * @param {*} fn 执行函数
 * @param {*} ms 延迟秒数
 * @returns
 */
export function throttle(fn, ms) {
  let timeId;
  return function () {
    if (!timeId) {
      timeId = setTimeout(() => {
        timeId = null;
        fn.apply(this, arguments);
      }, ms);
    }
  };
}

/**
 * 删除查询字符串里的不必要空格
 * @param {*} str 原字符串
 */
export function blankTrim(str) {
  if (str === '') {
    return str;
  }
  let strList = str.split(',');
  let resultList = strList[0].trim();
  for (let i = 1; i < strList.length; i++) {
    resultList = resultList + ',' + strList[i].trim();
  }
  return resultList;
}

/**
 * 深拷贝
 * @param {*} obj
 * @param {*} newObj
 * @returns
 */
export function deepClone(obj, newObj) {
  var newObj = newObj || {};
  for (let key in obj) {
    if (obj[key] !== null) {
      if (typeof obj[key] === 'object') {
        newObj[key] = obj[key].constructor === Array ? [] : {};
        deepClone(obj[key], newObj[key]);
      } else {
        newObj[key] = obj[key];
      }
    } else {
      newObj[key] = null;
    }
  }
  return newObj;
}

/**
 * 清除datetime中的time部分
 * @param date
 */
export function clearTime(date) {
  const date2 = new Date(date);
  if (date !== null) {
    date2.setHours(0);
    date2.setMinutes(0);
    date2.setSeconds(0);
    date2.setMilliseconds(0);
    return date2;
  }
  return null;
}

const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useEffectDebugger = (
  effectHook,
  dependencies,
  dependencyNames = []
) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    console.warn('[use-effect-debugger] ', changedDeps);
  }

  useEffect(effectHook, dependencies);
};

export function isValidDate(date) {
  return !(
    date === null ||
    date === '' ||
    date === 'DateEmpty' ||
    date === 'Invalid Date' ||
    new Date(date) + '' === 'Invalid Date'
  ) ;
}

/**
 * 将标准时间转换为UTC时间
 * @param {*} date
 * @returns
 */
export function turnDateToUTC(date) {
  if (!isValidDate(date)) {
    return '';
  }
  return new Date(date).toISOString();
}

export function turnDateToUTCForSubmission(date) {
  if (!isValidDate(date)) {
    return '';
  }
  let date2 = new Date(date);
  date2.setDate(date2.getDate() + 1);
  date2.setSeconds(date2.getSeconds() - 1);
  return date2.toISOString();
}

export function getUTCToDate(date) {
  if (!isValidDate(date)) {
    return '';
  }
  let DateDate = new Date(date).toISOString();
  let date2 = DateDate.split('T')[0];
  //console.log(date2)
  date2 = new Date(date2);
  let offSet = getUTCTimeoffSet();
  offSet = offSet > 0 ? 0 : 1;
  // console.log(offSet,value)
  date2.setDate(date2.getDate() + offSet);
  //console.log(date2.toLocaleDateString())
  return date2;
}

export function getUTCTimeoffSet() {
  return new Date().getTimezoneOffset() / -60;
}

export function getUTCTimeoffSetMins() {
  return new Date().getTimezoneOffset() / -1;
}

export function getUTCZeroTimeOffset(date) {
  if (!isValidDate(date)) {
    return date;
  }
  let value = new Date(date.toLocaleDateString()); //当日0点
  let offSet = getUTCTimeoffSet();
  let offSet2 =
    offSet < 0 ? 24 * 60 + getUTCTimeoffSetMins() : getUTCTimeoffSetMins();
  // console.log(offSet,value)
  value.setMinutes(value.getMinutes() + offSet2);
  // console.log(value)
  return value;
}

export function matchUTCTime(date) {
  if (!isValidDate(date)) {
    return date;
  }
  date = new Date(date);
  let value = new Date(date.toLocaleDateString()); //当日0点
  let offSet = getUTCTimeoffSet();
  offSet = offSet > 0 ? 0 : 1;
  // console.log(offSet,value)
  value.setDate(value.getDate() + offSet);
  // console.log(value)
  return value;
}

export function DeadPassJuedge(date) {
  if (!isValidDate(date)) {
    return date;
  }
  date = new Date(date);
  let value = new Date(date.toLocaleDateString()); //当日0点
  value.setDate(value.getDate() + 1);
  return value;
}

export function backMatchUTCTime(date) {
  if (!isValidDate(date)) {
    return date;
  }
  date = new Date(date);
  let value = new Date(date.toLocaleDateString()); //当日0点
  let offSet2 = getUTCTimeoffSetMins();
  value.setMinutes(value.getMinutes() + offSet2);
  return value;
}

/**
 * 延迟函数
 * @param delay 延迟毫秒
 * @return {Promise<unknown>}
 */
export function sleep(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

/**
 * 滑动到顶部
 */
export function scrollToTop(element) {
  const container = element ? element : window;

  setTimeout(function () {
    container.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, 20);
}

/**
 * @param {*} link
 * @returns
 */
export function handleLinkWithoutProtocol(link) {
  if (typeof link !== 'string') {
    return link;
  }
  if (link.startsWith('https://') || link.startsWith('http://')) {
    return link;
  }
  return 'https://' + link;
}

/**
 * 根据后端返回的code，返回前端需要展示的提示框的id
 *
 * 注：该函数的case未来可能会改成配置文件的形式，所以大家先不要往里面添加内容
 *
 * 另外，目前已发现部分后端返回的报错虽然code相同，但是message却是不同的，
 * 所以该函数也会扩展为支持用后端返回的message判断的
 *
 * @param code 需要是整数类型，后端返回过来的code直接传入即可
 * @return {string|null}   如果是已知的code类型，则会返回相应的intl模块的id（包括嵌套字段），如果未知，则会返回null
 */
export function backendCode2FrontendMsgId(code) {
  switch (code) {
    case 14094:
      return 'siDetail.journalLinkErr';
    case 14095:
      return 'siDetail.siHTMLLinkErr';
    default:
      return null;
  }
}

export function paramsEncode(params) {
  let tmpParams = {};
  for (let i in params) {
    if (params[i] === undefined) {
      continue;
    }
    tmpParams[i] = encodeURIComponent(params[i]);
  }
  return tmpParams;
}

export function transferUTCDate(date) {
  if (!isValidDate(date)) {
    return date;
  }
  date = new Date(date);
  let utcDate = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  );
  return new Date(utcDate).toISOString();
}

export function transferSubmissionUTCDate(date) {
  if (!isValidDate(date)) {
    return date;
  }
  date = new Date(date);
  let utcDate = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59,
    0
  );
  return new Date(utcDate).toISOString();
}

export function turnDateToUTCForSubmissionByString(date) {
  if (!isValidDate(date)) {
    return date;
  }

  let year = new Date(date).getFullYear();
  let month = new Date(date).getMonth() + 1;
  let day = new Date(date).getDate();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  return '' + year + '-' + month + '-' + day + 'T23:59:59.000Z';
}

export function turnDateToUTCByString(date) {
  if (!isValidDate(date)) {
    return date;
  }

  let year = new Date(date).getFullYear();
  let month = new Date(date).getMonth() + 1;
  let day = new Date(date).getDate();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  return '' + year + '-' + month + '-' + day + 'T00:00:00.000Z';
}

export const SIDateChangeFunction = date => {
  let value = transferUTCDate(date);
  if (value === '') {
    value = turnDateToUTCByString(date);
  }
  return value;
};

export const SIDateChangeFunction2 = date => {
  if (!isValidDate(date)) {
    return date;
  }

  let dt = new Date(date);
  return `${dt.asUTCDateString()}T00:00:00.000Z`;
};

export const SISubmissionDeadlineDateChangeFunction = date => {
  let value = transferSubmissionUTCDate(date);
  if (value === '') {
    value = turnDateToUTCForSubmissionByString(date);
  }
  return value;
};

export const SISubmissionDeadlineDateChangeFunction2 = date => {
  if (!isValidDate(date)) {
    return date;
  }

  let dt = new Date(date);
  return `${dt.asUTCDateString()}T23:59:59.000Z`;
};

export const extractEmailFromList = emailList => {
  return emailList.map(value => {
    return extractEmail(value);
  });
};

export const extractEmail = email => {
  if (email.at(-1) !== ')') {
    return email;
  } else {
    const reg = /.*\(([^\()]*)\)$/;
    return email.match(reg)[1];
  }
};

export const extractAttachmentId = files => {
  let res = [];
  for (const file of files) {
    res.push(file['id']);
  }
  return res;
};

export const extractAndTransformUsername = value => {
  if (value.at(-1) !== ')') {
    return value;
  }
  try {
    const reg = /^(.*)(\([^\()]*\))$/;
    let username = value.match(reg)[1].trim();
    let email = value.match(reg)[2];
    const index = username.lastIndexOf(' ');
    if (index != -1) {
      return (
        username.substr(0, index) + ',' + username.substr(index) + ' ' + email
      );
    }
    return value;
  } catch (e) {
    return value;
  }
};

/**
 * 直接通过response或error对象快速获取需要展示的messageId（对应到国际化）
 * @param {object} res response或error对象
 * @returns 对应到国际化的文件key的id
 */
export function getMessageIdFromResponse(res) {
  if (res == null) {
    console.error(
      'You have passed in a null or undefined `res` object. No message will be shown in this case'
    );
  } else if (res.code !== 200) {
    // code 不是200，说明后端并未捕获到这个问题，可能是404，500之类的，也可能是undefined（比如timeout）
    if (isTimeoutError(res)) {
      return 'common.errorTimeoutOcc'; // 返回timeout的错误id
    }
  }
  if (res.data) {
    // 是200，说明后端捕获了这个异常，且data不为undefined
    if (res.data.code === 200) {
      // 暂不支持对200处理，200只能通过message去判断，所以先给出一个warning
      console.warn(
        'You provided a response with code 200, which is not supported to process automatically'
      );
      return;
    }
    // 此时需要使用res.data.code或者res.data.message去获得id
    let messageId =
      responseToMessageIdConfig[res.data.code + ''] ??
      responseToMessageIdConfig[res.data.message];
    if (messageId == null) {
      // 仍然为空
      console.warn(
        'You have not define which error message to show. Will fall back to `common.errorundefinedOcc`. The raw response is: ',
        JSON.stringify(res)
      );
      messageId = 'common.errorundefinedOcc';
    }
    return messageId;
  }
  return 'common.errorundefinedOcc'; // 返回未知错误的id
}

// todo 需要优化此方法
export function handleErrorPopupAuto(
  res,
  dispatch,
  provideArgs = {},
  severity = SEVERITIES.error
) {
  let messageId = getMessageIdFromResponse(res);
  if (messageId) {
    // 可能为null/undefined
    dispatch(setSnackbarMessageAndOpen(messageId, provideArgs, severity));
  }
}

// return window size in real-time
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    /** @type {number} */
    width: 0,
    /** @type {number} */
    height: 0,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
/**
 * @param {string | undefined | null} itemFilds
 * @returns
 */
export function trimItemFilds(itemFilds) {
  if (itemFilds === '' || itemFilds === null || itemFilds === undefined)
    return '';
  let newItemFilds = itemFilds.trim();
  let regEx = /\s+/g;
  return newItemFilds.replace(regEx, ' ').trim();
}

export const trimStrArr = strArr => {
  let newStrArr = [];
  for (const element of strArr) {
    newStrArr.push(trimItemFilds(element));
  }
  return newStrArr;
};

// replace all punctuation and whitespace with space
export function replacePunctuationAndWhitespaceWithSpace(str) {
    // \p{P} matches all punctuation characters
    // \p{S} matches all symbols
    // \p{Z} matches all separators
    // \p{C} matches all control characters
  return str.replace(/[\p{P}\p{S}\p{Z}\p{C}]/gu, ' ');
}

/**
 * 用于创建这样一类 action：
 * - 它的 data 中有一个 value 字段，表示操作的数据值
 * - 还有一个 status 字段，一般用于包含网络请求的异步 disptach 中，
 *
 * 例如将 pendingString 设为 "downloading" ，则返回的 actionCreator
 * 的 status 参数将被约束为 "idle" | "downloading" | "success" | "error"
 * 从而精确观察一个包含网络请求的异步 dispatch 过程的各个阶段。
 *
 * 可以通过指定 defaultValue 的值来确定 value 的类型，
 * defaultValue 可以是 nullable 的，但如果确实希望可为 null，
 * 又不希望丢失类型信息，可以通过形如 @type {OtherType | null}
 * 的 JSDoc 语法初始化 defaultValue 的值
 *
 * @type {import("@/pages/SIPPage/TransformToSI").ActionWithNetworkStatusCreatorFactory}
 **/
export const actionWithNetworkStatusCreatorFactory = (
  actionType,
  pendingString,
  defaultValue
) => {
  return (status, value = null) => {
    return {
      type: actionType,
      data: {
        status: status,
        value: value === null ? defaultValue : value,
      },
    };
  };
};

/**
 * 传入一个 fake data 值，与模拟的延迟，异步返回一个 axios response 对象
 * 用于模拟与后端对接的成功情况
 * @type {import("./axios-response").FakeAxiosSuccessResponse}
 */
export const fakeAxiosSuccessResponse = async (fakeData, delayTime = 2000) => {
  await sleep(delayTime);
  return {
    ...axiosResponseEmptyInstance,
    data: {
      ...axiosResponseEmptyInstance.data,
      data: fakeData,
    },
  };
};
/**
 * 传入一个 fake data 值，与模拟的延迟，异步返回一个 axios response 对象
 * 用于模拟与后端对接的任意情况
 * @type {import("./axios-response").FakeAxiosUniversalResponse}
 */
export const fakeAxiosUniversalResponse = async (
  fakeData,
  delayTime = 2000,
  code = 200,
  message = ''
) => {
  await sleep(delayTime);
  return {
    ...axiosResponseEmptyInstance,
    data: {
      code: code,
      message: message,
      data: fakeData,
    },
  };
};

/**
 * 去除字符串数组中的重复元素
 * @param {string[]} stringArray
 */
export const getUniqueStringArray = stringArray => {
  return stringArray.filter(
    (item, index) => stringArray.indexOf(item) === index
  );
};

/**
 * 用于 useEffect 中判断是不是第一次渲染
 * @returns false 表示还没有挂载， true 表明已经挂载
 */
export const useIsMount = () => {
  const isMountRef = useRef(false);
  useEffect(() => {
    isMountRef.current = true;
  }, []);
  return isMountRef.current;
};

export const handleGEName = geValue => {
  if (typeof geValue === 'string') {
    // console.log('qqq');
    // console.log(geValue);
    let splitList = geValue.toString().split('(');
    let name = '';
    for (let j = 0; j < splitList.length - 1; j++) {
      if (j == 0) {
        name = name + splitList[j];
      } else {
        name = name + '(' + splitList[j];
      }
    }
    let tLN = name.split(',')[0];
    let tFN = name.split(',')[1];

    let temail = splitList[splitList.length - 1].split(')')[0];

    return {
      lastName: tLN,
      firstName: tFN,
      primaryEmail: temail,
      email: temail,
      fullName: name,
    };
  } else {
    let geNameAndEmail = geValue.geNameAndEmail;
    let alertGeFlagType = geValue.alertGeFlagType;
    let splitList = geNameAndEmail.split('(');
    let geCode = geValue?.geCode??null;
    let name = '';
    for (let j = 0; j < splitList.length - 1; j++) {
      if (j == 0) {
        name = name + splitList[j];
      } else {
        name = name + '(' + splitList[j];
      }
    }
    let tLN = name.split(',')[0];
    let tFN = name.split(',')[1];

    let temail = splitList[splitList.length - 1].split(')')[0];

    return {
      lastName: tLN,
      firstName: tFN,
      primaryEmail: temail,
      email: temail,
      fullName: name,
      alertGeFlagType,
      geCode,
    };
  }
};

export const handleFileterJournalName = journalName => {
  let splitList = journalName.split('(');
  let name = '';
  for (let j = 0; j < splitList.length - 1; j++) {
    if (j === 0) {
      name = name + splitList[j];
    } else {
      name = name + '(' + splitList[j];
    }
  }

  let code = splitList[splitList.length - 1].split(')')[0];

  return { journalName: name, journalCode: code };
};

//pattern for link format
export const patternLink =
  /^(https?:\/\/){0,1}(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)$/;

export const getFormattedMonthYear = selectedMonthDate => {
  if (!selectedMonthDate) 
    return '';
  const monthDateArray = selectedMonthDate.split('-');
  const MONTH = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return `${MONTH[monthDateArray[1] - 1]}${monthDateArray[0]}`;
};

/**
 * judge is selectDate >= cutOffDate, if true, report is over cutOffDate
 * @param {string} selectDate "YYYY-MM"
 * @param {string} cutOffDate "YYYY-MM"
 */
export const isReportOverCutOffDate = (selectDate, cutOffDate) => {
  try{
    const selectDateArray = selectDate.split('-');
    const cutOffDateArray = cutOffDate.split('-');
    return (
      parseInt(selectDateArray[0]) > parseInt(cutOffDateArray[0]) ||
      (parseInt(selectDateArray[0]) === parseInt(cutOffDateArray[0]) &&
        parseInt(selectDateArray[1]) >= parseInt(cutOffDateArray[1]))
    );
  }catch(e){
    console.log('cutOffDate exception: ' + e.message);
    return true;
  }
};

// convert search string to an object
export const getURLSearch = () => {
  const params = location.search;
  if (!params) return {};
  let paramsStr = params.split('?')[1];
  let paramsArr = paramsStr.split('&');
  let paramsObj = {};
  for (let i = 0; i < paramsArr.length; i++) {
    let key = paramsArr[i].split('=')[0];
    let value = paramsArr[i].split('=')[1];
    paramsObj[key] = value;
  }
  return paramsObj;
};

import React from 'react';
import { Box, IconButton, Stack, Typography, Link} from '@mui/material';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import moment from 'moment';

export const TitleBlock = props => {
  const { title, SIPCode } = props;
  return (
    <Box
      data-selenium-id='SIP_AcceptPage_Submodule-TitleBlock-Box'
      paddingX='32px'
      sx={{
        backgroundColor: '#fff',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        paddingTop: '19px',
        paddingBottom: '43px',
        '& .MuiTypography-root.title-primary': {
          fontWeight: 400,
          fontSize: '24px',
          lineHeight: '24px',
          color: '#262E35',
        },
        '& .MuiTypography-root.title-sub': {
          fontSize: '14px',
          lineHeight: '20px',
          color: '#154AB6',
          '& span': {
            fontWeight: 600,
            marginLeft: '8px',
          },
        },
      }}
    >
      <Stack
        spacing={'8px'}
        data-selenium-id='SIP_AcceptPage_Submodule-TitleBlock-Stack'
      >
        <Typography
          className='title-primary'
          data-selenium-id='SIP_AcceptPage_Submodule-TitleBlock-PrimaryTypography'
        >
          {title}
        </Typography>
        <Link
          id={`ssipCodeLink`}
          data-selenium-id={`SSIPPageP_AcceptPage_CustomComponents-Block-SipCodeLink`}
          href={`../auth/sipRelated/sip/detail/${SIPCode}`}
          color='#154AB6'
          sx={{
            textDecoration: 'none',
          }}
        >
          <Box>
            <Typography
              className='title-sub title-sip-code'
              data-selenium-id='SIP_AcceptPage_Submodule-TitleBlock-SubTypography'
            >
              SIP Code:<span>{SIPCode}</span>
            </Typography>
          </Box>
        </Link>
      </Stack>
    </Box>
  );
};

import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Checkbox,
  Link,
  TableCell,
  Typography,
  Stack,
  Grid,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  catchSelect,
  handleCATPageChangeAction,
  handleChangeCATPageSize,
  setCATOrderBy,
  setSearchContent,
  addSelected,
  cancelSelectAll,
  removeSelected,
  setIsAllSelected,
  selectAll,
} from '@/actions/SIMT-SI/CatJournal/CATJournalAction';

import {
  MyTableHeader,
  StyledTableCell,
  TableFooter2,
} from '@/components/DataTable';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { StyledAutocomplete } from '@/modules/SelectBox';

import {
  deepClone,
  formatDateString,
  getUTCToDate,
} from '@/utils/commonUtils';
import { CommonDialog } from '@/modules/AlertDialog';
import {
  refreshData,
} from '@/actions/CommonAction';
import { ShowForPermission } from '@/components/PermissionControl/ShowForPermission';
import TableSkeleton from '../../../../components/TableSkeleton';
import moment from 'moment';
import CheckboxIcon from '@/assets/CatOverviewUnchecked.svg';
import CheckboxCheckedIcon from '@/assets/CatOverviewChecked.svg';
import CATSearchRow from './CATSearchRow/CATSearchRow';
import CatEditIcon from '../../../../assets/CatOverviewEditIcon.svg';
import { CatCommentsDialog } from './CATCommentsDialog';
import './index.css';
import UnselectedHoverCheckbox from '../../../../assets/UnselectedHoverCheckbox.svg';
import SelectedHoverCheckbox from '../../../../assets/SelectedHoverCheckbox.svg';
import CATReasonDialog from './CATReasonDialog';
import * as React from 'react';
import { MulLineTooltipIfShow } from '@/componentsphase2/CustomTooltip';
import { useNavigate } from 'react-router-dom';
import NoDataMessage from '@/componentsphase2/NoDataMessage';
import { TableBox2 } from '@/pages/Report/ReportSpecificComponents/SpecialColorTableHeader';
import {
  createJournalByCode, deleteCATJournal,
  getCatCodeByFilter,
  getCATJournalList,
  getJournalByCode, updateCATJournal
} from "@/actions/SIMT-SI/CatJournal";
import {getCE, getScam} from "@/actions/SIMT-SI/SpecialIssue/SpecialIssueSystemAdmin";
import {PERMISSIONS} from "@/constant/permission";

function BoldCell(props) {
  const { data, align, style, children, sticky, stickyPX, ...others } = props;
  const Style = {
    display: 'table-cell',
    position: sticky ? 'sticky' : '',
    left: sticky ? stickyPX : '',
    zIndex: sticky ? 5 : '',
    fontWeight: 400,
    fontSize: '14px',
    color: 'inherit',
    padding: '0px 24px',
    background: 'inherit',
    lineHeight: '20px',
    [`&.${tableCellClasses.root}`]: {
      height: '44px',
      padding: '0x 24px',
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: '1px solid #DFE4E8',
    },
    ...style,
  };

  return (
    <StyledTableCell
      sx={Style}
      align={align}
      data-selenium-id='CATJournal_Overview-Table-StyledTableCell1'
      {...others}
    >
      {data || children}
    </StyledTableCell>
  );
}

const CommonCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  color: 'inherit',
  padding: '0px 24px',
  background: 'inherit',
  lineHeight: '20px',
  position: 'relative',
  [`&.${tableCellClasses.root}`]: {
    height: '44px',
    padding: '0px 24px',
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: '1px solid #DFE4E8',
  },
}));

const NonBorderTextField = styled(TextField)(({ theme }) => ({
  color: '#747474',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '& .MuiInputBase-input': {
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
  },
}));

function sleep(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

function CATTableBase(props, ref) {
  const Intl = useIntl();
  const dispatch = useDispatch();
  const [openJHCE, setOpenJHCE] = useState(false);
  const [openScam, setOpenScam] = useState(false);
  const [loading, setLoading] = useState(false);
  const [journalCode, setJournalCode] = useState();
  const [optDate, setOptDate] = useState();
  const [devModel, setDevModel] = useState();
  const [journalCE, setJournalCE] = useState();
  const [journalScam, setJournalScam] = useState();
  const [CEData, setCEData] = useState([]);
  const [ScamData, setScamData] = useState([]);
  const [handlingCE, setHandlingCE] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [searchCE, setSearchCE] = useState('');
  const [searchScam, setSearchScam] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState({
    journalCode: '',
    journalName: '',
  });
  const [CATTableData, setCATTableData] = useState({});
  const [JournalData, setJournalData] = useState([]);
  const [searchJournal, setSearchJournal] = useState('');
  const [preCATData, setPreCATData] = useState();
  const [editIndex, setEditIndex] = useState();
  const [firstLock, setFirstLock] = useState(true);
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [commentsOpen1, setCommentsOpen1] = useState(false);
  const [commentsOpen2, setCommentsOpen2] = useState(false);
  //打开comments编辑栏
  const [editComments, setEditComments] = useState(false);
  //切换comments只读模式和编辑模式
  const [editModel, setEditModel] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [editButtonDisabled, setEditButtonDisabled] = useState(false);
  const [thisCode, setThisCode] = useState('');

  const {
    JourListResult,
    orderByType,
    page,
    pageSize,
    CATResult,
    searchCATContent,
    refresh,
    CEResult,
    ScamResult,
    journalCreateResult,
    selected,
    catFilterSelected,
    siderOpen,
    allResult,
    batchUpdateMonitor,
  } = useSelector(state => {
    return {
      orderByType: state.CATJournal.orderByType,
      CATResult: state.CATJournal.CATData,
      allResult: state.CATJournal.allResult,
      page: state.CATJournal.page,
      pageSize: state.CATJournal.pageSize,
      searchCATContent: state.CATJournal.searchCATContent,
      refresh: state.Common.refresh,
      CEResult: state.SI.CE, //CE下拉框数据
      ScamResult: state.SI.Scam, //Scam下拉框数据
      JourListResult: state.CATJournal.journalData, //journal下拉框数据
      journalCreateResult: state.CATJournal.journalCreateResult,
      selected: state.CATJournal.selectedList,
      /** @type {import("./CATSearchRow/journal-filter-components/JournalFilterPopup").CatFilterSelectedState} */
      catFilterSelected: state.CATJournal.catFilterSelected,
      siderOpen: state.UserManage.siderOpen,
      batchUpdateMonitor: state.CATJournal.batchUpdateMonitor,
    };
  });
  const nav = useNavigate();
  useEffect(() => {
    var result = CEResult;
    if (result != null && result != undefined && result != 'error') {
      setCEData(result);
    }
  }, [CEResult]);
  useEffect(() => {
    var result = ScamResult;
    if (result != null && result != undefined && result != 'error') {
      setScamData(result);
    }
  }, [ScamResult]);

  useEffect(() => {
    var result = JourListResult;
    if (result != null && result != undefined && result != 'error') {
      setJournalData(result);
    }
  }, [JourListResult]);

  useEffect(() => {
    var result = CATResult;
    // console.log('CATresult', CATResult);
    if (result != null && result != undefined && result != 'error') {
      setCATTableData(new Object(result));
    }
  }, [CATResult]);

  //延迟获取CE值
  useEffect(() => {
    let active = true;

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        dispatch(getCE(searchCE));
        // setCEData1(CEData)
      }
    })();

    return () => {
      active = false;
    };
  }, [searchCE]);
  //延迟获取Scam list值
  useEffect(() => {
    let active = true;

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        dispatch(getScam(searchScam));
        // setCEData1(CEData)
      }
    })();

    return () => {
      active = false;
    };
  }, [searchScam]);

  useEffect(() => {
    dispatch(getJournalByCode(searchJournal, 1, 100));
  }, [searchJournal]);

  useEffect(() => {
    let newObj = {};
    if (
      editIndex === undefined ||
      CATResult?.result === undefined ||
      CATResult?.result === null ||
      CATResult?.count === undefined
    ) {
      return;
    }
    setPreCATData(deepClone(CATResult?.result[editIndex], newObj));
  }, [editIndex]);

  /**
   * 发起获取CAT Journal的数据的请求
   */
  useEffect(() => {
    if (!firstLock) {
      dispatch(handleCATPageChangeAction(0));
      dispatch(setCATOrderBy('DESC', 'lastUpdateTime'));
    } else {
      setFirstLock(false);
    }
  }, [dispatch, refresh, journalCreateResult]);

  /**
   * 发起获取CAT Journal的数据的请求
   */
  useEffect(() => {
    if (page === 0) {
      setTimeout(() => {
        dispatch(handleCATPageChangeAction(1));
      }, 1000);
    } else {
      setLoading(true);
      async function fetchData() {
        /**
         *
         * @param {(string | null)[]} from
         * @param {(string | null)[]} to
         */
        const getDateStringFromFromAndTo = (from, to) => {
          const fromDate = from.length > 0 ? from[0] : '';
          const toDate = to.length > 0 ? to[0] : '';
          if (
            (fromDate === null || fromDate === '') &&
            (toDate === null || toDate === '')
          )
            return null;
          return `${fromDate || ''};${toDate || ''}`;
        };

        /**
         * @param {(Date | null)[]} dateObj
         */
        const getFormattedDateStr = dateObj => {
          if (dateObj.length === 0) return null;
          if (dateObj[0] === null) return null;
          const momentDate = moment(dateObj[0]);
          if (!momentDate.isValid()) return null;
          return momentDate.format('YYYY-MM-DD');
        };
        // todo unfinisheed
        // await dispatch(getCatCodeByFilter());
        const filterDto = {
          pageNum: page,
          pageSize: pageSize,
          orderField: orderByType.orderBy,
          orderType: orderByType.order,
          keywords: searchCATContent,
          journalCode: catFilterSelected.journalCode.join(';') || null,
          journalName: catFilterSelected.journalName.join(';') || null,
          status: catFilterSelected.status.join(';') || null,
          optInDate: {
            start: getFormattedDateStr(catFilterSelected.optInDateFrom) || null,
            end: getFormattedDateStr(catFilterSelected.optInDateTo) || null,
          },
          optOutDate: {
            start:
              getFormattedDateStr(catFilterSelected.optOutDateFrom) || null,
            end: getFormattedDateStr(catFilterSelected.optOutDateTo) || null,
          },
          developmentModel:
            catFilterSelected.developmentModel.join(';') || null,
          journalHandlingCe:
            catFilterSelected.journalHandlingCe
              .map(item => {
                const [, email] = item.split(':');
                return email;
              })
              .join(';') || null,
          scam:
            catFilterSelected.scam
              .map(item => {
                const [, email] = item.split(':');
                return email;
              })
              .join(';') || null,
          catGroup: catFilterSelected.catGroup.join(';') || null,
          pdPublisher: catFilterSelected.pdPublisher.join(';') || null,
          pdManager: catFilterSelected.pdManager.join(';') || null,
          pdDirector: catFilterSelected.pdDirector.join(';') || null,
          ppPublisher: catFilterSelected.ppPublisher.join(';') || null,
          ppManager: catFilterSelected.ppManager.join(';') || null,
          ppDirector: catFilterSelected.ppDirector.join(';') || null,
          subjectGroup: catFilterSelected.subjectGroup.join(';') || null,
          businessDivision:
            catFilterSelected.businessDivision.join(';') || null,
          revenueModel: catFilterSelected.revenueModel.join(';') || null,
        };
        const filter = {
          orderField: orderByType.orderBy,
          orderType: orderByType.order,
          keywords: searchCATContent,
          journalCode: catFilterSelected.journalCode || [],
          journalName: catFilterSelected.journalName || [],
          status: catFilterSelected.status || [],
          optInDate: {
            start: getFormattedDateStr(catFilterSelected.optInDateFrom) || null,
            end: getFormattedDateStr(catFilterSelected.optInDateTo) || null,
          },
          optOutDate: {
            start:
              getFormattedDateStr(catFilterSelected.optOutDateFrom) || null,
            end: getFormattedDateStr(catFilterSelected.optOutDateTo) || null,
          },
          developmentModel: catFilterSelected.developmentModel || [],
          journalHandlingCe:
            catFilterSelected.journalHandlingCe.map(item => {
              const [, email] = item.split(':');
              return email;
            }) || [],
          scam:
            catFilterSelected.scam.map(item => {
              console.log(item);
              const [, email] = item.split(':');
              return email;
            }) || [],
          catGroup: catFilterSelected.catGroup || [],
          pdPublisher: catFilterSelected.pdPublisher || [],
          pdManager: catFilterSelected.pdManager || [],
          pdDirector: catFilterSelected.pdDirector || [],
          ppPublisher: catFilterSelected.ppPublisher || [],
          ppManager: catFilterSelected.ppManager || [],
          ppDirector: catFilterSelected.ppDirector || [],
          subjectGroup: catFilterSelected.subjectGroup || [],
          businessDivision: catFilterSelected.businessDivision || [],
          revenueModel: catFilterSelected.revenueModel || [],
        };
        await dispatch(getCATJournalList(filterDto));
        dispatch(getCatCodeByFilter(filterDto));
        setLoading(false);
      }
      fetchData();
      setEditIndex(undefined);
    }
  }, [
    dispatch,
    page,
    pageSize,
    orderByType,
    searchCATContent,
    catFilterSelected,
    batchUpdateMonitor,
    // Only finish batchUpdate operatation, the value of batchUpdateMonitor will change
  ]);

  function createRow(
    id,
    label,
    isOrder = true,
    minWidth = '110px',
    maxWidth = '220px',
    show = true,
    stick = undefined,
    stickPX = '72px'
  ) {
    return { id, label, isOrder, minWidth, maxWidth, show, stick, stickPX };
  }

  const headItems = [
    createRow(
      'journalCode',
      Intl.messages['catJournal.journalCode'],
      true,
      '175px',
      '175px',
      true,
      true,
      '72px'
    ),
    createRow(
      'journalName',
      Intl.messages['catJournal.journalName'],
      true,
      '257px',
      '257px',
      true,
      true,
      '247px'
    ),
    createRow(
      'status',
      Intl.messages['catJournal.status'],
      true,
      '170px',
      '170px',
      true
    ),
    createRow(
      'devModel',
      Intl.messages['catJournal.devModel'],
      true,
      '223px',
      '223px',
      true
    ),
    createRow(
      'JHCE',
      Intl.messages['catJournal.scal'],
      true,
      '230px',
      '230px',
      true
    ),
    createRow(
      'scam',
      Intl.messages['catJournal.scam'],
      true,
      '230px',
      '230px',
      true
    ),
    createRow(
      'comments',
      Intl.messages['catJournal.comments'],
      true,
      '561px',
      '561px',
      true
    ),
    createRow(
      'catGroup',
      Intl.messages['catJournal.catGroup'],
      true,
      '180px',
      '180px',
      true
    ),
    createRow(
      'pdPublisher',
      Intl.messages['catJournal.pdPublisher'],
      true,
      '229px',
      '229px',
      true
    ),
    createRow(
      'pdManager',
      Intl.messages['catJournal.pdManager'],
      true,
      '229px',
      '229px',
      true
    ),
    createRow(
      'pdDirector',
      Intl.messages['catJournal.pdDirector'],
      true,
      '229px',
      '229px',
      true
    ),
    createRow(
      'ppPublisher',
      Intl.messages['catJournal.ppPublisher'],
      true,
      '229px',
      '229px',
      true
    ),
    createRow(
      'ppManager',
      Intl.messages['catJournal.ppManager'],
      true,
      '229px',
      '229px',
      true
    ),
    createRow(
      'ppDirector',
      Intl.messages['catJournal.ppDirector'],
      true,
      '229px',
      '229px',
      true
    ),
    createRow(
      'subjectGroup',
      Intl.messages['catJournal.subjectGroup'],
      true,
      '180px',
      '180px',
      true
    ),
    createRow(
      'businessDivision',
      Intl.messages['catJournal.businessDivision'],
      true,
      '221px',
      '221px',
      true
    ),
    createRow(
      'revenueModel',
      Intl.messages['catJournal.revenueModel'],
      true,
      '190px',
      '190px',
      true
    ),
    createRow(
      'optInDate',
      Intl.messages['catJournal.optInDate'],
      true,
      '282px',
      '282px',
      true
    ),
    createRow(
      'optInReason',
      Intl.messages['catJournal.optInReason'],
      true,
      '523px',
      '523px',
      true
    ),
    createRow(
      'optOutDate',
      Intl.messages['catJournal.optOutDate'],
      true,
      '282px',
      '282px',
      true
    ),
    createRow(
      'optOutReason',
      Intl.messages['catJournal.optOutReason'],
      true,
      '523px',
      '523px',
      true
    ),
    createRow(
      'lastUpdateTime',
      Intl.messages['catJournal.lastUpdateTime'],
      true,
      '199px',
      '199px',
      true
    ),
  ];

  //用来改变表格的排序顺序

  const handleRequestSort = (_event, orderBy) => {
    let newOrder;
    if (orderByType.orderBy === orderBy) {
      // 如果当前的排序字段没有变化，则切换正反序
      newOrder = orderByType.order === 'desc' ? 'asc' : 'desc';
    } else {
      // 如果当前排序字段发生变化，则直接按照新的字段升序
      newOrder = 'asc';
    }
    //const isAsc = orderByType.by === property && orderByType.order === "asc";
    // console.log(orderBy);
    dispatch(setCATOrderBy(newOrder, orderBy));
  };

  // todo unfinished
  // const handleSelectAll = () => {
  //   console.log(',,,,,,,', allResult);
  //   console.log('123132',CATResult.result);
  //   for (const catData of allResult) {
  //     console.log('catData',catData);
  //     dispatch(addSelected(catData));
  //   }
  //   dispatch(setIsAllSelected(true));
  // };

  // const handleUnselectAll = () => {
  //   for (const catData of allResult) {
  //     dispatch(removeSelected(catData.journalCode));
  //   }
  //   dispatch(setIsAllSelected(false));
  // };

  //选择
  const handleSelectAll = () => {
    // 选中当前展示的所有数据
    // for (const catData of CATResult.result) {
    //   可参考GEAction
    // dispatch(addSelected(catData));
    // }
    // dispatch(setIsAllSelected(true));
    dispatch(selectAll(allResult));
  };

  const handleUnselectAll = () => {
    // 取消选中当前的所有数据
    // for (const catData of CATResult.result) {
    //   dispatch(removeSelected(catData.journalCode));
    // }
    // dispatch(setIsAllSelected(false));
    dispatch(cancelSelectAll());
  };

  const handleClick = projectCode => {
    // console.log(projectCode.journalCode);
    const selectedIndex = selected.indexOf(projectCode.journalCode);
    if (selectedIndex > -1) {
      dispatch(removeSelected(projectCode.journalCode));
    } else {
      dispatch(addSelected(projectCode));
    }
  };

  //改变每页的数量
  const handlePerPageChange = e => {
    dispatch(handleChangeCATPageSize(Number(e.target.value)));
  };

  //改变页码
  const handlePageChange = (_event, newPage) => {
    dispatch(handleCATPageChangeAction(newPage));
  };

  /**
   * 处理对话框点击删除事件
   */
  const handleDelete = async () => {
    // dispatch(
    //   deleteCATJournal(deleteInfo.journalCode, deleteInfo.journalName, 1)
    // );
    setDialogOpen(false);
    setTimeout(() => {
      dispatch(refreshData());
    }, 1000);
  };

  const handleCeClick = (newValue, index) => {
    setJournalCE(newValue);
    let temp = {
      result: CATTableData.result,
      count: CATTableData.count,
    };
    temp.result[index].handlingCe = newValue;
    temp.result[index].handlingCe === null
      ? (temp.result[index].handlingCe.group = '')
      : (temp.result[index].handlingCe.group = newValue.groupName);
    setCATTableData(temp);
  };
  const handleScamClick = (newValue, index) => {
    setJournalScam(newValue);
    let temp = {
      result: CATTableData.result,
      count: CATTableData.count,
    };
    temp.result[index].scam = newValue;
    temp.result[index].scam === null
      ? (temp.result[index].scam.group = '')
      : (temp.result[index].scam.group = newValue.groupName);
    setCATTableData(temp);
  };

  const resetJournalCode = () => {
    let temp = {
      result: CATTableData.result,
      count: CATTableData.count,
    };
    temp.result[editIndex].journalName = preCATData.journalName;
    temp.result[editIndex].jpm = preCATData.jpm;
    temp.result[editIndex].editorialDirector = preCATData.editorialDirector;
    temp.result[editIndex].subjectGroup = preCATData.subjectGroup;
    temp.result[editIndex].businessDivision = preCATData.businessDivision;
    temp.result[editIndex].revenueModel = preCATData.revenueModel;
    setCATTableData(temp);
  };

  const reset = () => {};

  const resetHandlingCe = () => {
    let temp = {
      result: CATTableData.result,
      count: CATTableData.count,
    };
    temp.result[editIndex].handlingCe = preCATData.handlingCe;
    setCATTableData(temp);
  };

  useEffect(() => {
    if (
      (CATResult.result === null || CATResult.result?.length === 0) &&
      page !== 1
    ) {
      // just a workaround when exceeding the max page
      dispatch(handleCATPageChangeAction(1));
      return;
    }
    // if (selected?.length === 0) {
    //   dispatch(setIsAllSelected(false));
    //   return;
    // }
    // 判断新的Data是否全部选中，然后更新全选选择框的状态
    // let isAllSelectedTemp = true;
    // for (const catItem of CATResult.result) {
    //   if (selected.indexOf(catItem.journalCode) === -1) {
    //     isAllSelectedTemp = false;
    //     break;
    //   }
    // }
    //
    // dispatch(setIsAllSelected(isAllSelectedTemp));
  }, [CATResult, selected]);

  //ViewMore
  const handleViewMoreOpen = reason => {
    setCommentsOpen2(true);
    setTextValue(reason);
  };
  const handleOptInOpen = reason => {
    setCommentsOpen1(true);
    setTextValue(reason);
  };
  const [hover, setHover] = useState(false);
  const [indexs, setIndexs] = useState(0);

  const containerBoxRef = useRef(null);

  const [ct, setCt] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setCt(ct + 1);
    }, 100);
  }, [CATTableData]);

  return (
    <Box mt={2} data-selenium-id='CATJournal_Overview-Table-Box'>
      <Box id='TableBox' data-selenium-id='CATJournal_Overview-Table-Box-Box'>
        <Grid item md={3} alignItems='center' justifyContent='center'>
          {/* <span id="tableFooterSelectedText" style={{ fontSize: "small", textAlign: 'center' }}> */}
          <Stack
            direction='row'
            justifyContent='start'
            alignItems='center'
            spacing={1}
            sx={{ marginBottom: '6px' }}
          >
            <span
              style={{
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '18px',
                color: '#262E35',
              }}
            >
              {/* <FormattedMessage
                id='table.selectedText'
                values={{ num: selected.length }}
              /> */}
              Selected Item(s):
            </span>
            <span
              style={{
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '18px',
                color: '#262E35',
              }}
            >
              {selected?.length}
            </span>
          </Stack>
          {/* </span> */}
        </Grid>
        <TableBox2
          dataList={[CATTableData, loading]}
          tableKey={'CATJournalOverview'}
          footerHeight={0}
          containerType={TableContainer}
          containerSelector={'#cat-journal-table-container'}
          viewTag={'Table'}
          delay={300}
          sx={{
            overflowX: 'hidden',
            overflowY: 'hidden',
            position: 'relative',
            borderRadius: '8px 8px 0px 0px',
            border: '1px solid #DFE4E8',
            width: '100%',
            padding: 0,
            '& .scroll-bar-container-box': {
              right: '3px',
              width: '15px',
              top: '3px',
              bottom: '3px',
            },
          }}
        >
          <TableContainer
            ref={containerBoxRef}
            id='cat-journal-table-container'
            data-selenium-id='CATJournal_Overview-Table-Container'
            className='table-screen-size'
            sx={{
              border: '1px solid #DFE4E8',
              borderRadius: '8px 8px 0px 0px',
              maxHeight: 'calc(100vh - 407px)',
              width: siderOpen
                ? 'calc(100vw - 131px - 158px)'
                : 'calc(100vw - 132px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar:vertical': {
                width: 0,
              },
              '&::-webkit-scrollbar-thumb:vertical': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Table
              stickyHeader
              id='cat-journal-table'
              data-selenium-id='CATJournal_Overview-Table-Table'
              aria-label='customized table'
              size='small'
              sx={{
                letterSpacing: '0.01em',
                color: '#262E35',
              }}
            >
              <MyTableHeader
                order={orderByType.order}
                orderBy={orderByType.orderBy}
                onRequestSort={handleRequestSort}
                items={headItems}
                // showColumns={showColumns}
                handleSelectAll={handleSelectAll}
                handleUnselectAll={handleUnselectAll}
                backgroundColor='#F1F3F5'
                color='#596A7C'
                CheckboxColor='wileyBlue2'
                letterSpacing='0.08em'
                checkboxIcon={<CheckboxIcon />}
                checkboxCheckedIcon={<CheckboxCheckedIcon />}
                hasCheckbox={true}
                hasCheckboxStick={true}
                selected={selected}
                allNum={allResult?.length}
              />

              <TableBody
                id='cat-journal-table-body'
                data-selenium-id='CATJournal_Overview-Table-Body'
              >
                {<CATSearchRow></CATSearchRow>}
                {!loading &&
                  CATTableData.result !== undefined &&
                  CATTableData.result.map((row, index) => {
                    const isItemSelected =
                      selected.indexOf(row.journalCode) > -1;
                    return (
                      <HoverTableRow
                        id={`catJournalTableRow${index}`}
                        data-selenium-id={`CatJournal-TableRow-${index}`}
                        key={row.journalCode}
                        handleDelete={() => {
                          setDeleteInfo(row);
                          setDialogOpen(true);
                        }}
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                        sx={{
                          //color: row.status === 'Opt-out' ? '#98A7B6' : '#262E35',
                          color: '#262E35',
                        }}
                      >
                        <BoldCell align='left' sticky={true} stickyPX={0}>
                          <Checkbox
                            id={`catOverviewTableBodyCheckbox${row.siCode}`}
                            data-selenium-id={`CatJournal-TableBodyCheckbox-${row.siCode}`}
                            onClick={handleClick.bind(this, row)}
                            color='wileyBlue2'
                            checked={isItemSelected}
                            icon={
                              hover && indexs === index ? (
                                <UnselectedHoverCheckbox />
                              ) : (
                                <CheckboxIcon />
                              )
                            }
                            sx={{ padding: '3px' }}
                            checkedIcon={
                              hover && indexs === index ? (
                                <SelectedHoverCheckbox />
                              ) : (
                                <CheckboxCheckedIcon />
                              )
                            }
                            disableRipple
                            onMouseEnter={() => {
                              setHover(true);
                              setIndexs(index);
                            }}
                            onMouseLeave={() => {
                              setHover(false);
                              setIndexs(index);
                            }}
                          />
                        </BoldCell>
                        {/* Journal Code */}
                        <BoldCell
                          id={`catJournalTableJournalCode${index}`}
                          data-selenium-id={`CatJournal-TableJournalCode-${index}`}
                          align='left'
                          sticky={true}
                          stickyPX={72}
                          onClick={() => {
                            nav(
                              `/simt/auth/journalRelated/cat/detail/${row.journalCode}`
                            );
                          }}
                          style={{
                            'font-family': 'Open Sans',
                            'font-size': '14px',
                            'font-weight': '600',
                            'line-height': '20px',
                            'letter-spacing': '0.01em',
                            'text-align': 'left',
                            color: '#154AB6',
                            cursor: 'pointer',
                            '&:hover': {
                              'font-weight': '700',
                            },
                          }}
                        >
                          {row.journalCode}
                        </BoldCell>
                        {/* Journal Name */}
                        <BoldCell
                          id={`catJournalTableJournalName${index}`}
                          data-selenium-id={`CatJournal-TableJournalName-${index}`}
                          align='left'
                          sticky={true}
                          stickyPX={247}
                          onClick={() => {
                            nav(
                              `/simt/auth/journalRelated/cat/detail/${row.journalCode}`
                            );
                          }}
                        >
                          <MulLineTooltipIfShow
                            id={`CatJournal-TableStatus-TableRow${index}-journalName`}
                            outerRef={containerBoxRef}
                            line={2}
                            label={row.journalName}
                            labelSx={{
                              'font-family': 'Open Sans',
                              'font-size': '14px',
                              'font-weight': '600',
                              'line-height': '20px',
                              'letter-spacing': '0.01em',
                              'text-align': 'left',
                              color: '#154AB6',
                              cursor: 'pointer',
                              '&:hover': {
                                'font-weight': '700',
                              },
                            }}
                          />
                        </BoldCell>
                        {/* Status */}
                        <BoldCell
                          id={`catJournalTableStatus${index}`}
                          data-selenium-id={`CatJournal-TableStatus-${index}`}
                          align='left'
                          sx={{ color: '#262E35s' }}
                        >
                          {row.status ? row.status : '-'}
                        </BoldCell>
                        {/* Development Model */}
                        <CommonCell
                          id={`catJournalTableDevelopmentModel${index}`}
                          data-selenium-id={`CatJournal-TableDevelopmentModel-${index}`}
                          align='left'
                        >
                          <EditableCell
                            outerRef={containerBoxRef}
                            type={'developmentModel'}
                            info={row.developmentModel}
                            setinit={() => {
                              setDevModel(row.developmentModel);
                            }}
                            reset={reset}
                            update={() => {
                              dispatch(
                                updateCATJournal({
                                  journalCode: preCATData.journalCode,
                                  developmentModel: devModel,
                                  handlingCeEmail: preCATData.handlingCe?.email,
                                  scamEmail: preCATData.scam?.email,
                                  optInDate: preCATData.optInDate,
                                })
                              );
                            }}
                            editIndex={editIndex}
                            setEditIndex={setEditIndex}
                            index={index}
                            error={() => false}
                          >
                            <Select
                              id={`catJournalTableModelSelect${index}`}
                              data-selenium-id={`CatJournal-TableModelSelect-${index}`}
                              value={devModel}
                              onChange={e => {
                                setDevModel(e.target.value);
                              }}
                              sx={{
                                color: '#747474',
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: 0,
                                },
                                '& .MuiInputBase-input': {
                                  paddingTop: '0px',
                                  paddingBottom: '0px',
                                },
                              }}
                            >
                              <MenuItem
                                id={`catJournalTableSICommissioningItem${index}`}
                                data-selenium-id={`CatJournal-TableSICommissioningItem-${index}`}
                                value={'SI Commissioning'}
                              >
                                SI Commissioning
                              </MenuItem>
                              <MenuItem
                                id={`catJournalTableBasicManagementItem${index}`}
                                data-selenium-id={`CatJournal-TableBasicManagementItem-${index}`}
                                value={'Basic Management'}
                              >
                                Basic Management
                              </MenuItem>
                              <MenuItem
                                id={`catJournalTableOnHoldItem${index}`}
                                data-selenium-id={`CatJournal-TableOnHoldItem-${index}`}
                                value={'On Hold'}
                              >
                                On Hold
                              </MenuItem>
                            </Select>
                          </EditableCell>
                        </CommonCell>
                        {/* Journal Handling CE */}
                        <CommonCell
                          id={`catJournalTableHandlingCE${index}`}
                          data-selenium-id={`CatJournal-TableHandlingCE-${index}`}
                          align='left'
                        >
                          <EditableCell
                            type={'username'}
                            outerRef={containerBoxRef}
                            info={row.handlingCe?.username}
                            setinit={() => {
                              if (row.handlingCe !== null) {
                                setJournalCE({
                                  username: row.handlingCe.username,
                                  email: row.handlingCe.email,
                                });
                              } else {
                                setJournalCE({
                                  username: 'null',
                                  email: 'null',
                                });
                              }
                            }}
                            reset={resetHandlingCe}
                            update={() => {
                              dispatch(
                                updateCATJournal({
                                  journalCode: preCATData.journalCode,
                                  developmentModel: preCATData.developmentModel,
                                  handlingCeEmail: journalCE.email,
                                  scamEmail: preCATData.scam?.email,
                                  optInDate: preCATData.optInDate,
                                })
                              );
                            }}
                            editIndex={editIndex}
                            setEditIndex={setEditIndex}
                            index={index}
                            error={() => {
                              if (journalCE === null) {
                                return true;
                              } else {
                                return false;
                              }
                            }}
                          >
                            <StyledAutocomplete
                              open={openJHCE}
                              id={`catJournalTableHandlingCEEditInput${index}`}
                              data-selenium-id={`CatJournal-TableHandlingCEEditInput-${index}`}
                              sx={{
                                display: 'inline-block',
                                '& input': {
                                  width: 200,
                                  bgcolor: 'background.paper',
                                  color: theme =>
                                    theme.palette.getContrastText(
                                      theme.palette.background.paper
                                    ),
                                },
                              }}
                              options={CEData}
                              filterOptions={x => {
                                return x;
                              }}
                              getOptionLabel={option =>
                                option.username + '(' + option.email + ')'
                              }
                              renderOption={(params, option, {}) => {
                                return (
                                  <li
                                    {...params}
                                    style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      height: 30,
                                      width: 'auto',
                                      padding: 8,
                                      background:
                                        row.handlingCe !== null &&
                                        row.handlingCe.email === option.email
                                          ? '#f0eeee'
                                          : 'white',
                                    }}
                                  >
                                    <Box
                                      onClick={() => {
                                        return handleCeClick(option, index);
                                      }}
                                      value={option}
                                    >
                                      <Tooltip title={option.email}>
                                        <span>{option.username}</span>
                                      </Tooltip>
                                    </Box>
                                  </li>
                                );
                              }}
                              getOptionDisabled={option => {
                                CATTableData.result.map(row => {
                                  if (row.handlingCe !== null) {
                                    return (
                                      option.email === row.handlingCe.email
                                    );
                                  }
                                });
                              }}
                              value={journalCE}
                              onChange={(event, newValue) => {
                                setJournalCE(newValue);
                                let temp = {
                                  result: CATTableData.result,
                                  count: CATTableData.count,
                                };
                                temp.result[index].handlingCe = newValue;
                                setCATTableData(temp);
                              }}
                              onOpen={() => {
                                setOpenJHCE(true);
                              }}
                              onClose={() => {
                                setOpenJHCE(false);
                              }}
                              renderInput={params => (
                                <NonBorderTextField
                                  placeholder='Search Journal by SCA Lead'
                                  onChange={e => {
                                    setSearchCE(e.target.value);
                                  }}
                                  sx={{ width: '200px' }}
                                  {...params}
                                />
                              )}
                            />
                          </EditableCell>
                        </CommonCell>
                        {/* Journal Handling CE */}
                        <CommonCell
                          id={`catJournalTableSCAM${index}`}
                          data-selenium-id={`CatJournal-TableSCAM-${index}`}
                          align='left'
                        >
                          <EditableCell
                            type={'scam'}
                            outerRef={containerBoxRef}
                            info={row.scam?.username}
                            setinit={() => {
                              if (row.scam !== null) {
                                setJournalScam({
                                  username: row.scam.username,
                                  email: row.scam.email,
                                });
                              } else {
                                setJournalScam({
                                  username: 'null',
                                  email: 'null',
                                });
                              }
                            }}
                            reset={resetHandlingCe}
                            update={() => {
                              dispatch(
                                updateCATJournal({
                                  journalCode: preCATData.journalCode,
                                  developmentModel: preCATData.developmentModel,
                                  handlingCeEmail: preCATData.handlingCe?.email,
                                  scamEmail: journalScam.email,
                                  optInDate: preCATData.optInDate,
                                })
                              );
                            }}
                            editIndex={editIndex}
                            setEditIndex={setEditIndex}
                            index={index}
                            error={() => {
                              if (journalScam === null) {
                                return true;
                              } else {
                                return false;
                              }
                            }}
                          >
                            <StyledAutocomplete
                              open={openScam}
                              id={`catJournalTableSCAMEditInput${index}`}
                              data-selenium-id={`CatJournal-TableSCAMEditInput-${index}`}
                              sx={{
                                display: 'inline-block',
                                '& input': {
                                  width: 200,
                                  bgcolor: 'background.paper',
                                  color: theme =>
                                    theme.palette.getContrastText(
                                      theme.palette.background.paper
                                    ),
                                },
                              }}
                              options={ScamData}
                              filterOptions={x => {
                                return x;
                              }}
                              getOptionLabel={option =>
                                option.username + '(' + option.email + ')'
                              }
                              renderOption={(params, option, {}) => {
                                return (
                                  <li
                                    {...params}
                                    style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      height: 30,
                                      width: 'auto',
                                      padding: 8,
                                      background:
                                        row.scam !== null &&
                                        row.scam.email === option.email
                                          ? '#f0eeee'
                                          : 'white',
                                    }}
                                  >
                                    <Box
                                      onClick={() => {
                                        return handleScamClick(option, index);
                                      }}
                                      value={option}
                                    >
                                      <Tooltip title={option.email}>
                                        <span>{option.username}</span>
                                      </Tooltip>
                                    </Box>
                                  </li>
                                );
                              }}
                              getOptionDisabled={option => {
                                CATTableData.result.map(row => {
                                  if (row.scam !== null) {
                                    return option.email === row.scam.email;
                                  }
                                });
                              }}
                              value={journalScam}
                              onChange={(event, newValue) => {
                                setJournalScam(newValue);
                                let temp = {
                                  result: CATTableData.result,
                                  count: CATTableData.count,
                                };
                                temp.result[index].scam = newValue;
                                setCATTableData(temp);
                              }}
                              onOpen={() => {
                                setOpenScam(true);
                              }}
                              onClose={() => {
                                setOpenScam(false);
                              }}
                              renderInput={params => (
                                <NonBorderTextField
                                  placeholder='Search Journal by SCA Manager'
                                  onChange={e => {
                                    setSearchScam(e.target.value);
                                  }}
                                  sx={{ width: '200px' }}
                                  {...params}
                                />
                              )}
                            />
                          </EditableCell>
                        </CommonCell>
                        {/* comments */}
                        <CommonCell
                          id={`catJournalTableComments${index}`}
                          data-selenium-id={`CatJournal-TableComments-${index}`}
                          align='left'
                        >
                          <EditableCell
                            info={row.comments}
                            index={index}
                            isComment={true}
                            setEditButtonDisabled={setEditButtonDisabled}
                            setEditComments={setEditComments}
                            setCommentsOpen={setCommentsOpen}
                            setTextValue={setTextValue}
                            setEditModel={setEditModel}
                            code={row.journalCode}
                            setThisCode={setThisCode}
                          ></EditableCell>
                        </CommonCell>
                        {/* CAT GROUP */}
                        <CommonCell
                          id={`catJournalTableHandlingCEGroup${index}`}
                          data-selenium-id={`CatJournal-TableHandlingCEGroup-${index}`}
                          align='left'
                        >
                          <MulLineTooltipIfShow
                            id={`CatJournal-Table-TableRow${index}-group`}
                            outerRef={containerBoxRef}
                            line={1}
                            label={row.handlingCe?.group}
                          />
                        </CommonCell>
                        {/* pdPublisher */}
                        <CommonCell
                          id={`catJournalTablepdPublisher${index}`}
                          data-selenium-id={`CatJournal-TablepdPublisher-${index}`}
                          align='left'
                        >
                          {row.pdPublisher}
                        </CommonCell>
                        {/* pdManager */}
                        <CommonCell
                          id={`catJournalTablepdManager${index}`}
                          data-selenium-id={`CatJournal-TablepdManager-${index}`}
                          align='left'
                        >
                          {row.pdManager}
                        </CommonCell>
                        {/* pdDirector */}
                        <CommonCell
                          id={`catJournalTablePdDirector${index}`}
                          data-selenium-id={`CatJournal-TablePdDirector-${index}`}
                          align='left'
                        >
                          {row.pdDirector}
                        </CommonCell>
                        {/* ppPublisher */}
                        <CommonCell
                          id={`catJournalTableppPublisher${index}`}
                          data-selenium-id={`CatJournal-TableppPublisher-${index}`}
                          align='left'
                        >
                          {row.ppPublisher}
                        </CommonCell>
                        {/* ppManager */}
                        <CommonCell
                          id={`catJournalTableppManager${index}`}
                          data-selenium-id={`CatJournal-TableppManager-${index}`}
                          align='left'
                        >
                          {row.ppManager}
                        </CommonCell>
                        {/* ppDirector */}
                        <CommonCell
                          id={`catJournalTableppDirector${index}`}
                          data-selenium-id={`CatJournal-TableppDirector-${index}`}
                          align='left'
                        >
                          {row.ppDirector}
                        </CommonCell>
                        {/* Subject Group */}
                        <CommonCell
                          id={`catJournalTableSubjectGroup${index}`}
                          data-selenium-id={`CatJournal-TableSubjectGroup-${index}`}
                          align='left'
                        >
                          {row.subjectGroup}
                        </CommonCell>
                        {/* Business Division */}
                        <CommonCell
                          id={`catJournalTableBusinessDivision${index}`}
                          data-selenium-id={`CatJournal-TableBusinessDivision-${index}`}
                          align='left'
                        >
                          <MulLineTooltipIfShow
                            id={`CatJournal-Table-TableRow${index}-businessDivision`}
                            outerRef={containerBoxRef}
                            line={1}
                            label={row.businessDivision}
                          />
                        </CommonCell>
                        {/* Revenue Model */}
                        <CommonCell
                          id={`catJournalTableRevenueModel${index}`}
                          data-selenium-id={`CatJournal-TableRevenueModel-${index}`}
                          align='left'
                        >
                          {row.revenueModel}
                        </CommonCell>
                        {/* Opt-in Date */}
                        <CommonCell
                          id={`catJournalTableOptInDate${index}`}
                          data-selenium-id={`CatJournal-TableOptInDate-${index}`}
                          align='left'
                        >
                          {row.optInDate != null
                            ? formatDateString(getUTCToDate(row.optInDate))
                            : '-'}
                          {/* <EditableCell
                            info={formatDateString(getUTCToDate(row.optInDate))}
                            setinit={() => {
                              setOptDate(getUTCToDate(row.optInDate));
                            }}
                            reset={reset}
                            update={() => {
                              if (
                                optDate === null ||
                                optDate === "" ||
                                optDate + "" === "Invalid Date" ||
                                SIDateChangeFunction(optDate) === null
                              ) {
                                return;
                              }
                              if (preCATData.handlingCe) {
                                dispatch(
                                  updateCATJournal({
                                    journalCode: preCATData.journalCode,
                                    developmentModel: preCATData.developmentModel,
                                    handlingCeEmail: preCATData.handlingCe.email,
                                    optInDate: SIDateChangeFunction(optDate),
                                  })
                                );
                                // console.log(preCATData.journalCode,preCATData.developmentModel,preCATData.handlingCe.email,optDate)
                              } else {
                                dispatch(
                                  updateCATJournal({
                                    journalCode: preCATData.journalCode,
                                    developmentModel: preCATData.developmentModel,
                                    handlingCeEmail: null,
                                    optInDate: SIDateChangeFunction(optDate),
                                  })
                                );
                              }
                            }}
                            editIndex={editIndex}
                            setEditIndex={setEditIndex}
                            index={index}
                            error={() => {
                              if (
                                optDate === null ||
                                optDate === "" ||
                                optDate + "" === "Invalid Date" ||
                                SIDateChangeFunction(optDate) === null
                              ) {
                                return true;
                              } else return false;
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                inputFormat="yyyy-MM-dd"
                                mask="____-__-__"
                                value={optDate}
                                onChange={(newDate) => {
                                  setOptDate(newDate);
                                }}
                                renderInput={(params) => (
                                  <NonBorderTextField {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </EditableCell> */}
                        </CommonCell>
                        {/* Opt-in Reason */}
                        <CommonCell
                          id={`catJournalTableOptInReason${index}`}
                          data-selenium-id={`CatJournal-TableOptInReason-${index}`}
                          align='left'
                        >
                          <Box
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <Box
                              sx={{
                                'overflow-x': 'hidden',
                                'overflow-y': 'hidden',
                                textOverflow: 'ellipsis',
                                height: '22px',
                                maxWidth: '399px',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                              }}
                            >
                              {row.optInReason
                                ? row.optInReason.length > 60
                                  ? row.optInReason.substring(0, 60) + '...'
                                  : row.optInReason
                                : '-'}
                            </Box>
                            {row.optInReason?.length > 60 ? (
                              <Box
                                fontSize={14}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                display='inline-block'
                              >
                                <Link
                                  component='button'
                                  id='optInReasonViewMore'
                                  data-selenium-id='CATJournal_Overview-Table-OptInReasonViewMore'
                                  onClick={handleOptInOpen.bind(
                                    this,
                                    row.optInReason
                                  )}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#154AB6',
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    gap: '4px',
                                    fontWeight: 600,
                                    '&:hover': {
                                      fontWeight: 700,
                                    },
                                  }}
                                >
                                  <Box>View more</Box>
                                </Link>
                              </Box>
                            ) : null}
                          </Box>
                        </CommonCell>

                        {/* Opt-out Date */}
                        <BoldCell
                          id={`catJournalTablePotOutDate${index}`}
                          data-selenium-id={`CatJournal-TablePotOutDate-${index}`}
                          align='left'
                        >
                          {row.optOutDate != null
                            ? formatDateString(getUTCToDate(row.optOutDate))
                            : '-'}
                        </BoldCell>
                        {/* Opt-out Reason */}
                        <CommonCell
                          id={`catJournalTableOptOutReason${index}`}
                          data-selenium-id={`CatJournal-TableOptOutReason-${index}`}
                          align='left'
                        >
                          <Box
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <Box
                              sx={{
                                'overflow-x': 'hidden',
                                'overflow-y': 'hidden',
                                textOverflow: 'ellipsis',
                                height: '22px',
                                maxWidth: '399px',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                              }}
                            >
                              {row.optOutReason
                                ? row.optOutReason.length > 60
                                  ? row.optOutReason.substring(0, 60) + '...'
                                  : row.optOutReason
                                : '-'}
                            </Box>
                            {row.optOutReason?.length > 60 ? (
                              <Box
                                fontSize={14}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                display='inline-block'
                              >
                                <Link
                                  component='button'
                                  id='optOutReasonViewMore'
                                  data-selenium-id='CATJournal_Overview-Table-OptOutReasonViewMore'
                                  onClick={handleViewMoreOpen.bind(
                                    this,
                                    row.optOutReason
                                  )}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#154AB6',
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    gap: '4px',
                                    fontWeight: 600,
                                    '&:hover': {
                                      fontWeight: 700,
                                    },
                                  }}
                                >
                                  <Box>View more</Box>
                                </Link>
                              </Box>
                            ) : null}
                          </Box>
                        </CommonCell>

                        {/* update time */}
                        <CommonCell
                          id={`catJournalTableLastUpdateTime${index}`}
                          data-selenium-id={`CatJournal-TableLastUpdateTime-${index}`}
                          align='left'
                        >
                          {moment
                            .utc(row.updatedTime)
                            .format('YYYY-MM-DD HH:mm:ss')}
                        </CommonCell>
                      </HoverTableRow>
                    );
                  })}
                {!loading &&
                  Array.isArray(CATTableData.result) &&
                  CATTableData.result.length === 0 && (
                    // <TableRow>
                    //   <TableCell
                    //     sx={{
                    //       position: 'sticky',
                    //       left: 0,
                    //       height: '128px',
                    //       boxSizing: 'border-box',
                    //       border: 'none',
                    //     }}
                    //   >
                    //     <Stack
                    //       sx={{
                    //         position: 'absolute',
                    //         width: '964px',
                    //         height: '128px',
                    //         top: 0,
                    //         left: 0,
                    //       }}
                    //       alignItems='center'
                    //       justifyContent={'center'}
                    //     >
                    //       <Typography>No Data to Show</Typography>
                    //     </Stack>
                    //   </TableCell>
                    // </TableRow>
                    <NoDataMessage
                      id='NoDataMessageAfterFiliter'
                      MarginTop={'5px'}
                      data-selenium-id='SIPage-SpecialIssueManagement-IssueTable-NoDataMessageAfterFiliter'
                      message={
                        'No results were found. Please adjust your filters and try again.'
                      }
                    ></NoDataMessage>
                  )}
              </TableBody>
              {loading && (
                <TableBody sx={{ backgroundColor: 'fff' }}>
                  <TableSkeleton num={9} colSpan={36} />
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </TableBox2>
        <Box
          id='cat-journal-table-footer-box'
          data-selenium-id='CATJournal_Overview-Table-FooterBox'
        >
          <Divider />

          <TableFooter2
            id='cr-contact-table-footer-box'
            data-selenium-id='CATJournal_Overview-Table-CrContactFooterBox'
            numSelected={selected?.length}
            Page={page}
            perPageNumber={pageSize}
            perPageList={[20, 50, 100]}
            count={CATTableData.count}
            handlePerPageChange={handlePerPageChange}
            handlePageChange={handlePageChange}
          />
        </Box>
      </Box>
      <CommonDialog
        title={`Delete CAT journal ${deleteInfo.journalCode}`}
        content={`Are you sure to delete ${deleteInfo.journalName} (${deleteInfo.journalCode})?`}
        alertOpen={dialogOpen}
        confirmText={'DELETE'}
        handleClose={() => {
          setDialogOpen(false);
        }}
        handleConfirm={handleDelete}
        data-selenium-id='CATJournal_Overview-Table-CommonDialog'
      />
      <CatCommentsDialog
        edit={editModel}
        setEdit={setEditModel}
        value={textValue}
        setValue={setTextValue}
        commentsOpen={commentsOpen}
        setCommentsOpen={setCommentsOpen}
        editButtonDisabled={editButtonDisabled}
        code={thisCode}
        data-selenium-id='CATJournal_Overview-Table-CatCommentsDialog'
      ></CatCommentsDialog>
      <CATReasonDialog
        value={textValue}
        setValue={setTextValue}
        commentsOpen={commentsOpen1}
        setCommentsOpen={setCommentsOpen1}
        title='Opt-In Reason'
      />
      <CATReasonDialog
        value={textValue}
        setValue={setTextValue}
        commentsOpen={commentsOpen2}
        setCommentsOpen={setCommentsOpen2}
        title='Opt-Out Reason'
      />
    </Box>
  );
}

const CATTable = React.forwardRef(CATTableBase);
export default CATTable;
/**
 * 表格行，使得移入出现删除按钮
 */

function HoverTableRow(props) {
  const { handleDelete } = props;

  const [deleteOpen, setDeleteOpen] = useState(false);

  //删除按钮出现
  const handleMouseEnter = () => {
    setDeleteOpen(true);
  };

  //删除按钮消失
  const handleMouseLeave = () => {
    setDeleteOpen(false);
  };

  return (
    <TableRow
      className='cat-tablerow'
      sx={{
        '.&.Mui-selected': {
          backgroundColor: '#FFFFFF',
        },
      }}
      {...props}
      data-selenium-id='CATJournal_Overview-Table-TableRow'
    >
      {props.children}
    </TableRow>
  );
}

/**
 * 可编辑的表格单元，使得移入出现编辑按钮
 * 在子元素下编写点击编辑后出现的编辑框
 * @param {*} info 需要展示的信息
 * @param {*} setinit 为编辑框设置初始值的函数
 */
function EditableCell(props) {
  const {
    outerRef,
    type = '',
    info,
    setinit,
    reset,
    update,
    editIndex,
    setEditIndex,
    index,
    error,
    isComment,
    editButtonDisabled,
    setCommentsOpen,
    setEditComments,
    setTextValue,
    setEditModel,
    setEditButtonDisabled,
    setThisCode,
    code,
  } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (editIndex != index) {
      setEditOpen(false);
      setIsEdit(false);
    }
  }, [editIndex]);

  //编辑按钮出现
  const openEditIcon = () => {
    setEditOpen(true);
  };

  //编辑按钮消失
  const closeEditIcon = () => {
    setEditOpen(false);
  };

  //改变显示状态至编辑状态
  const changeToEdit = () => {
    if (isComment === true) {
      setCommentsOpen(true);
      setEditComments(true);
      setTextValue(info);
      setEditModel(true);
      setThisCode(code);
      //console.log(code, '1111111111111111');
    } else {
      setEditIndex(index);
      setEditOpen(false);
      setinit();
      setIsEdit(true);
    }
  };

  //改变编辑状态至显示状态
  const changeOutEdit = () => {
    reset();
    setIsEdit(false);
  };

  const handleUpdate = () => {
    update();
    setIsEdit(false);
    setTimeout(() => {
      dispatch(refreshData());
    }, 1000);
  };
  const handleViewMoreOpen = () => {
    setCommentsOpen(true);
    setTextValue(info);
    setEditModel(false);
    setEditButtonDisabled(false);
    setThisCode(code);
    //console.log(editButtonDisabled, '22222222');
  };

  if (isEdit) {
    return (
      <Box
        display={'flex'}
        alignItems='center'
        data-selenium-id='CATJournal_Overview-Table-IsEditBox'
      >
        {props.children}
        <IconButton
          disableRipple
          id={`catJournalEditableCellCloseButton${index}`}
          data-selenium-id={`CatJournal-EditableCellCloseButton-${index}`}
          onClick={changeOutEdit}
          size='small'
        >
          <CloseIcon sx={{ fontSize: '14px', color: '#717171' }} />
        </IconButton>
        <IconButton
          disableRipple
          id={`catJournalEditableCellUpdateButton${index}`}
          data-selenium-id={`CatJournal-EditableCellUpdateButton-${index}`}
          onClick={handleUpdate}
          disabled={error()}
          size='small'
        >
          <CheckIcon sx={{ fontSize: '14px', color: '#717171' }} />
        </IconButton>
      </Box>
    );
  } else if (!isComment) {
    return (
      <Box
        onMouseEnter={openEditIcon}
        onMouseLeave={closeEditIcon}
        data-selenium-id='CATJournal_Overview-Table-NotIsCommentBox'
      >
        <Stack direction='row' justifyContent='flex-start' alignItems='center'>
          <MulLineTooltipIfShow
            id={`CatJournal-TableStatus-TableRow${index}-${type}`}
            outerRef={outerRef}
            line={2}
            label={info ? info : '-'}
          />
          <ShowForPermission permission={`${PERMISSIONS.JOURNAL_CAT_WRITE}`}>
            <IconButton
              disableRipple
              id={`catJournalEditableCellCloseButton${index}`}
              data-selenium-id={`CatJournal-EditableCellCloseButton-${index}`}
              sx={{
                ml: 2,
                display: editOpen ? 'inline-flex' : 'none',
                '&:hover': {
                  'svg path': {
                    stroke: '#262E35',
                  },
                },
              }}
              onClick={changeToEdit}
              size='small'
            >
              <CatEditIcon sx={{ fontSize: '14px', color: '#717171' }} />
            </IconButton>
          </ShowForPermission>
        </Stack>
      </Box>
    );
  } else {
    return (
      <Box
        onMouseEnter={openEditIcon}
        onMouseLeave={closeEditIcon}
        style={{ display: 'flex', flexDirection: 'row' }}
        data-selenium-id='CATJournal_Overview-Table-IsCommentBox'
      >
        <Stack direction='row' justifyContent='center' alignItems='center'>
          <Box
            sx={{
              'overflow-x': 'hidden',
              'overflow-y': 'hidden',
              textOverflow: 'ellipsis',
              height: '22px',
              maxWidth: '405px',
              minWidth: '1px',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              letterSpacing: '0em',
            }}
            data-selenium-id='CATJournal_Overview-Table-IsCommentBox-Box'
          >
            {info
              ? info?.length > 60
                ? info.substring(0, 60) + '...'
                : info
              : '-'}
          </Box>
          {info?.length > 60 ? (
            <Box
              fontSize={14}
              sx={{
                minWidth: '65px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              display='inline-block'
            >
              <Link
                component='button'
                onClick={handleViewMoreOpen}
                id='commentsViewMore'
                data-selenium-id='CATJournal_Overview-Table-CommentsViewMore'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#154AB6',
                  cursor: 'pointer',
                  textDecoration: 'none',
                  fontWeight: 600,
                  '&:hover': {
                    fontWeight: 700,
                  },
                  gap: '4px',
                }}
              >
                <Box>View more</Box>
              </Link>
            </Box>
          ) : null}
          <ShowForPermission permission={`${PERMISSIONS.JOURNAL_CAT_WRITE}`}>
            <IconButton
              disableRipple
              id={`catJournalEditableCellCloseButton${index}`}
              data-selenium-id={`CatJournal-EditableCellCloseButton-${index}`}
              sx={{
                ml: 1,
                display: editOpen ? 'inline-flex' : 'none',
                '&:hover': {
                  'svg path': {
                    stroke: '#262E35',
                  },
                },
              }}
              onClick={changeToEdit}
              size='small'
            >
              <CatEditIcon sx={{ fontSize: '14px', color: '#717171' }} />
            </IconButton>
          </ShowForPermission>
        </Stack>
      </Box>
    );
  }
}

import * as actions from '../actions/SIMT-SIP/SIP/SIPAction';
import {GET_SIPDETAIL_OBJECT_CHOICE_JOURNAL_DROPLIST_FOR_REVISE} from "../actions/SIMT-SIP/SIP/SIPAction";

const defaultEmail = {
  title: 'N/A',
  content: 'N/A',
  from: 'N/A',
  fromNameAndEmail: 'N/A',
  to: [],
  toNameAndEmail: [],
  copy: [],
  copyNameAndEmail: [],
  bcc: [],
  bccNameAndEmail: [],
  mailAttachments: [],
  mailAttachmentIds: null,
  requireAttachment: false,
};

const initState = {
  sipStatus: null,
  sipStatusLoading: false,
  object: null,
  geInformation: null,
  sipInformation: null,
  contentCommissioningApproaches: null,

  isAllSeleted: false,

  orderType: 'desc',
  orderField: 'sipCode',
  pageNum: 1,
  pageSize: 20,
  sipOverviewData: [],
  sipOverviewCodes: [],
  sipOverviewSeletedData: [],
  sipOverviewDataCount: 0,
  isSIPOverviewDataByPermission: false,
  currentDecisionType: '',
  decisionType: [],
  decisionConfig: [],
  assignToCESubmitionDecisionResult: '',

  /**
   * export 功能的状态
   * @type {"idle" | "openMenu" | "exporting" | "finish" | "error"} */
  exportState: 'idle',

  /**
   * export all 功能的状态
   * @type {"idle" | "openModal" | "exporting" | "finish" | "error"} */
  exportAllState: 'idle',

  sipDetailObjectChoiceJournalResult: null,
  sipDetailObjectChoiceJournalResultForRevise: null,
  sipDetailObjectPreferredSubjectAreaResult: null,
  sipDetailObjectEditResult: null,
  sipDetailObjectEditMonitor: false,

  potentialAuthorSelectedRow: -1,
  addedPotentialAuthor: {
    sipCode: null,
    name: null,
    affiliation: null,
    email: null,
    topic: null,
  },
  editPotentialAuthor: {
    authorId: null,
    sipCode: null,
    name: null,
    affiliation: null,
    email: null,
    topic: null,
  },
  deleteTargetAuthor: {
    authorId: null,
    name: null,
  },

  sipDetailGEInfomationCVIdResult: null,
  sipDetailGEInfomationCVIdMonitor: false,
  /** @type {import("../pages/SIPPage/RevisePage/CustomComponents/CustomButton").SIPDetailGEInfomationUploadCVIdStatus} */
  sipDetailGEInfomationUploadCVIdStatus: 'idle',
  /** @type {import("../pages/SIPPage/RevisePage/CustomComponents/CustomButton").SIPDetailGEInfomationDeleteCVIdStatus} */
  sipDetailGEInfomationDeleteCVIdStatus: 'idle',
  geInfomationCountryDroplistResult: [],
  geInfomationJobTitleDroplistResult: [],
  afterClickSave: false,
  authorNameErrorFlag: false,
  affiliationErrorFlag: false,
  emailErrorFlag: false,

  /** @type {import("../pages/SIPPage/RevisePage/RevisePage").SIPReviseData} */
  sipReviseData: {
    type: 'None',
  },
  sipEbmReviewData: null,
  sipEbmReviewLoading: true,
  /** @type {import("../pages/SIPPage/RevisePage/ProposerRevisePage").DownloadReviseDataStatus} */
  downloadReviseDataStatus: 'idle',
  /** @type {import("../pages/SIPPage/RevisePage/ProposerRevisePage").DownloadReviseDataErrorType} */
  downloadReviseDataErrorType: 'none',
  /** @type {import("../pages/SIPPage/RevisePage/ProposerRevisePage").UploadReviseDataStatus} */
  uploadReviseDataStatus: 'idle',
  /** @type {import("../pages/SIPPage/RevisePage/ProposerRevisePage").SaveReviseDataStatus} */
  saveReviseDataStatus: 'idle',
  /** @type {import("../pages/SIPPage/RevisePage/CustomComponents/CustomButton").CvToUpdate[]} */
  cvToUpdateList: [],

  addGEResult: null,
  addGEMonitor: false,
  deleteGEResult: null,
  deleteGEMonitor: false,
  editGEResult: null,
  editGEMonitor: false,

  emailWithdrawBody: null,
  emailWithdrawCode: null,
  emailRevisionBody: null,
  emailRevisionCode: null,
  editSIPInfomationResult: null,
  editSIPInfomationMonitor: false,
  updateSipShevelCode: null,

  reviseFrom: null,
  reviseTo: null,
  reviseSubject: null,
  reviseCc: null,
  reviseBcc: null,
  withdrawContent: null,
  emailAllInformation: defaultEmail,
  emailReturnCode: null,
  getEmailCode: null,

  acceptInformation: null,
  sipProcessLog: null,
  tier2JPMInfo: null,
  revisionDdl: [],
  extendDdl: null,
  declineCode: 0,
  acceptSubmitCode: 0,
  acceptShowCode: 0,
  sipEditingHistory: null,
  geEditingHistory: null,
  emailHistory: null,
  ateJournalDropList: [],
  userRejectReasonDropList: [],
  getApproveJournalList: [],
  /** @type {import("@/pages/SIPPage/TransformToSI").TransformToSiState} */
  transformToSiState: {
    geDiffOriginalData: [],
    getGeDiffOriginalDataStatus: 'idle',

    acquiredSiDetail: null,
    getAcquiredSiDetailStatus: 'idle',

    syncGeBetweenSipAndSimtStatus: 'idle',
    createGeForTransformResult: [],
  },
  checkIsNonCatJournal: '',
  cvUploadingProgress: 0,
  columnsSelected: {}, //overview columns selected 
  sipDetailAllMandatorySubmitOrNextPressed: false, // check sip detail mandatory for activate decision panel if next or submit has pressed
  sipSourceOptions: [],
  createSIPResult: 'UNDO',
  createSuccessAlert: false,
  aiAcquisitionCreateSuccessAlert: false,
  aiAcquisitionCreateSuccessSum: 1,
  users: {},
};

export default function SIPReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.UPDATE_EXTEND_DDL_VALUE: {
      return {
        ...state,
        extendDdl: action.data,
      };
    }
    case actions.AIACQUISITION_CREATE_SUCCESS: {
      return {
        ...state,
        aiAcquisitionCreateSuccessAlert: data.open,
        aiAcquisitionCreateSuccessSum: data.sum,
      };
    }
    case actions.CREATE_SUCCESS: {
      return {
        ...state,
        createSuccessAlert: data
      };
    }
    case actions.GET_SIP_DETAIL_ALL_MANDATORY_SUBMIT_OR_NEXT_PRESSED: {
      return {
        ...state,
        sipDetailAllMandatorySubmitOrNextPressed: data
      };
    }
    case actions.CREATE_SIP: {
      return {
        ...state,
        createSIPResult: data
      };
    }
    case actions.ASSIGN_TO_REJECT_SUBMISSION_DECISION: {
      return {
        ...state,
        assignToSubmittedResult: data,
      };
    }
    case actions.GET_ATE_JOURNAL_DROPLIST:
      return {
        ...state,
        ateJournalDropList: data,
      };
    case actions.GET_USER_REJECT_REASON_DROPLIST:
      return {
        ...state,
        userRejectReasonDropList: data,
      };
    case actions.GET_SIP_EDITING_HISTORY:
      return {
        ...state,
        sipEditingHistory: data,
      };
    case actions.GET_GE_EDITING_HISTORY:
      return {
        ...state,
        geEditingHistory: data,
      };
    case actions.GET_EMAIL_HISTORY:
      return {
        ...state,
        emailHistory: data,
      };
    case actions.Edit_SIP_INFOMATION:
      return {
        ...state,
        editSIPInfomationResult: data,
        editSIPInfomationMonitor: !state.editSIPInfomationMonitor,
      };
    case actions.Edit_GEINFOMATION:
      return {
        ...state,
        editGEResult: data,
        editGEMonitor: !state.editGEMonitor,
      };
    case actions.DELETE_GEINFOMATION:
      return {
        ...state,
        deleteGEResult: data,
        deleteGEMonitor: !state.deleteGEMonitor,
      };
    case actions.ADD_GEINFOMATION:
      return {
        ...state,
        addGEResult: data,
        addGEMonitor: !state.addGEMonitor,
      };
    case actions.GET_GEINFOMATION_JOB_TITLE_DROPLIST:
      return {
        ...state,
        geInfomationJobTitleDroplistResult: data,
      };
    case actions.GET_REVISION_DDL:
      return {
        ...state,
        revisionDdl: data,
      };
    case actions.GET_EXTEND_DDL:
      return {
        ...state,
        extendDdl: data,
      };
    case actions.GET_GEINFOMATION_COUNTRY_DROPLIST:
      return {
        ...state,
        geInfomationCountryDroplistResult: data,
      };
    case actions.UPLOAD_CV:
      return {
        ...state,
        sipDetailGEInfomationCVIdResult: data,
        sipDetailGEInfomationCVIdMonitor:
          !state.sipDetailGEInfomationCVIdMonitor,
      };
    case actions.SET_UPLOAD_CV_STATUS:
      return {
        ...state,
        sipDetailGEInfomationUploadCVIdStatus: data,
      };
    case actions.DELETE_CV:
      return {
        ...state,
        sipDetailGEInfomationCVIdResult: null,
        sipDetailGEInfomationDeleteCVIdStatus: data,
      };
    case actions.INIT_CV:
      return {
        ...state,
        sipDetailGEInfomationCVIdResult: data,
      };
    case actions.SIPDETAIL_OBJECT_EDIT:
      return {
        ...state,
        sipDetailObjectEditResult: data,

        sipDetailObjectEditMonitor: !state.sipDetailObjectEditMonitor,
      };
    case actions.GET_SIPDETAIL_OBJECT_CHOICE_JOURNAL_DROPLIST:
      return {
        ...state,
        sipDetailObjectChoiceJournalResult: data,
      };
    case actions.GET_SIPDETAIL_OBJECT_CHOICE_JOURNAL_DROPLIST_FOR_REVISE:
        return {
        ...state,
        sipDetailObjectChoiceJournalResultForRevise: data,
      };
    case actions.GET_SIPDETAIL_OBJECT_PREFERRED_SUBJECT_AREA_DROPLIST:
      return {
        ...state,
        sipDetailObjectPreferredSubjectAreaResult: data,
      };
    case actions.SET_IS_SIPOVERVIEWDATA_BY_PERMISSION:
      return {
        ...state,
        isSIPOverviewDataByPermission: data,
      };
    case actions.SET_PAGE_NUM:
      return {
        ...state,
        pageNum: data,
      };
    case actions.SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: data,
      };
    case actions.REMOVE_SELECTED_ALL:
      return {
        ...state,
        sipOverviewSeletedData: [],
      };
    case actions.REMOVE_SELECTED_ALL_AND_PAGE_NUM:
      return {
        ...state,
        sipOverviewSeletedData: [],
        pageNum: 1,
      };
    case actions.ADD_SELECTED_SIP:
      let newSelected = [];
      if (state.sipOverviewSeletedData.indexOf(data) > -1) {
        newSelected = state.sipOverviewSeletedData; // 保持不变
      } else {
        newSelected = [data, ...state.sipOverviewSeletedData];
      }
      return {
        ...state,
        sipOverviewSeletedData: newSelected,
      };
    case actions.ADD_SELECTED_SIP_BATCH:
      return {
        ...state,
        sipOverviewSeletedData: [...data],
      };
    case actions.REMOVE_SELECTED_SIP:
      return {
        ...state,
        sipOverviewSeletedData: state.sipOverviewSeletedData.filter(item => {
          return item !== data;
        }),
      };
    case actions.LOAD_SIP_DATA:
      return {
        ...state,
        sipOverviewData: data.result,
        sipOverviewDataCount: data.total,
      };
    case actions.LOAD_SIP_CODESBYDATA:
      return {
        ...state,
        sipOverviewCodes: data,
      };
    case actions.LOAD_SIP_CODES:
      return {
        ...state,
        sipOverviewCodes: data,
      };
    case actions.SET_ORDERBY:
      return {
        ...state,
        orderType: data.orderType,
        orderField: data.orderField,
      };
    case actions.SET_IS_ALL_SELECTED:
      return {
        ...state,
        isAllSeleted: data,
      };
    case actions.LOAD_SIP_STATUS:
      return {
        ...state,
        sipStatusLoading: true,
      };
    case actions.LOAD_SIP_STATUS_COMPLETE:
      return {
        ...state,
        sipStatusLoading: false,
      };
    case actions.GET_SIP_STATUS:
      return {
        ...state,
        sipStatus: data,
      };
    case actions.GET_OBJECT:
      return {
        ...state,
        object: data,
      };
    case actions.GET_GE_INFORMATION:
      return {
        ...state,
        geInformation: data,
      };
    case actions.GET_SIP_INFORMATION:
      return {
        ...state,
        sipInformation: data,
      };
    case actions.GET_CONTENT_COMMISSIONING_APPROACHES:
      return {
        ...state,
        contentCommissioningApproaches: data,
      };
    case actions.GET_EBM_REVIEW_COMMENTS:
      return {
        ...state,
        ebmList: data,
      };
    case actions.GET_DECISION_TYPE:
      return {
        ...state,
        decisionType: data,
      };
    case actions.GET_CURRENT_DECISION_TYPE:
      return {
        ...state,
        currentDecisionType: data,
      };
    case actions.GET_DECISION_CONFIG:
      return {
        ...state,
        decisionConfig: data,
      };
    case actions.ASSIGN_TO_CE_SUBMITION_DECISION:
      return {
        ...state,
        assignToCESubmitionDecisionResult: data,
      };
    case actions.SET_SIP_EXPORT_STATE:
      return {
        ...state,
        exportState: data,
      };
    case actions.SET_SIP_EXPORT_ALL_STATE:
      return {
        ...state,
        exportAllState: data,
      };
    case actions.SET_POTENTIAL_AUTHOR_SELECTED_ROW:
      return {
        ...state,
        potentialAuthorSelectedRow: data,
      };
    case actions.ADD_POTENTIAL_AUTHOR:
      return {
        ...state,
        addedPotentialAuthor: data,
      };
    case actions.EDIT_POTENTIAL_AUTHOR:
      return {
        ...state,
        editPotentialAuthor: data,
      };
    case actions.DELETE_POTENTIAL_AUTHOR:
      return {
        ...state,
        deleteTargetAuthor: data,
      };
    case actions.SET_CLICK_AFTER_SAVE:
      return {
        ...state,
        afterClickSave: data,
      };
    case actions.SET_AUTHOR_NAME_ERROR:
      return {
        ...state,
        authorNameErrorFlag: data,
      };
    case actions.SET_AFFILIATION_ERROR:
      return {
        ...state,
        affiliationErrorFlag: data,
      };
    case actions.SET_EMAIL_ERROR:
      return {
        ...state,
        emailErrorFlag: data,
      };
    case actions.GET_EMAIL_WITHDRAW_BODY:
      return {
        ...state,
        emailWithdrawBody: data,
      };
    case actions.GET_EMAIL_REVISION_BODY:
      return {
        ...state,
        emailRevisionBody: data,
      };
    case actions.SEND_EMAIWITHDRAWCODE:
      return {
        ...state,
        emailWithdrawCode: data,
      };
    case actions.SEND_EMAIREVISIONCODE:
      return {
        ...state,
        emailRevisionCode: data,
      };
    case actions.UPDATE_SIP_SHEVELD:
      return {
        ...state,
        updateSipShevelCode: data,
      };
    case actions.SET_SIP_REVISE_DATA:
      return {
        ...state,
        sipReviseData: data,
      };
    case actions.SET_SIP_EBM_REVIEW_DATA:
      return{
        ...state,
        sipEbmReviewData:data,
      };
    case actions.SET_SIP_EBM_REVIEW_LOADING:
      return{
        ...state,
        sipEbmReviewLoading: false,
      };
    case actions.SET_DOWNLOAD_REVISE_DATA_STATUS:
      return {
        ...state,
        downloadReviseDataStatus: data,
      };
    case actions.SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR:
      return {
        ...state,
        downloadReviseDataStatus: 'error',
        downloadReviseDataErrorType: data,
      };
    case actions.SET_UPLOAD_REVISE_DATA_STATUS:
      return {
        ...state,
        uploadReviseDataStatus: data,
      };
    case actions.SET_SAVING_REVISE_DATA_STATUS:
      return {
        ...state,
        saveReviseDataStatus: data,
      };
    case actions.ADD_CV_TO_UPDATE:
      const newCVToUpdateList = [data, ...state.cvToUpdateList];
      return {
        ...state,
        cvToUpdateList: newCVToUpdateList,
      };
    case actions.CLEAR_CV_TO_UPDATE:
      return {
        ...state,
        cvToUpdateList: [],
      };
    case actions.SET_REVISE_SUBJECT:
      return {
        ...state,
        reviseSubject: data,
      };
    case actions.SET_REVISE_FROM:
      return {
        ...state,
        reviseFrom: data,
      };
    case actions.SET_SEND_EMAIL_CODE:
      return {
        ...state,
        emailReturnCode: data,
      };
    case actions.SET_GET_EMAIL_CODE:
      return {
        ...state,
        getEmailCode: data,
      };
    case actions.SET_REVISE_TO:
      return {
        ...state,
        reviseTo: data,
      };
    case actions.SET_REVISE_CC:
      return {
        ...state,
        reviseCc: data,
      };
    case actions.SET_REVISE_BCC:
      return {
        ...state,
        reviseBcc: data,
      };
    case actions.SET_WITHDRAW_ALLCONTENT:
      return {
        ...state,
        withdrawContent: data,
      };
    case actions.SET_EMAIL_ALL:
      return {
        ...state,
        emailAllInformation: data || defaultEmail,
      };
    case actions.SET_ACCEPT_INFORMATION:
      return {
        ...state,
        acceptInformation: data,
      };
    case actions.LOAD_SIP_PROCESS:
      return {
        ...state,
        sipProcessLog: data,
      };
    case actions.GET_ALL_TITER2JPM_INFO:
      return {
        ...state,
        tier2JPMInfo: data,
      };
    case actions.SET_DECLINE_CODE:
      return {
        ...state,
        declineCode: data,
      };
    case actions.SET_ACCEPT_SUBMIT_CODE:
      return {
        ...state,
        acceptSubmitCode: data,
      };
    case actions.SET_ACCEPT_SHOW_CODE:
      return {
        ...state,
        acceptShowCode: data,
      };
    case actions.UPDATE_CV_UPLOADING_PROGRESS:
      return {
        ...state,
        cvUploadingProgress: data,
      };
    case actions.SET_TRANSFORM_TO_SI_GE_CHECK_INFO:
      return {
        ...state,
        transformToSiState: {
          ...state.transformToSiState,
          geDiffOriginalData:
            data.status === 'success'
              ? data.value
              : state.transformToSiState.geDiffOriginalData,
          getGeDiffOriginalDataStatus: data.status,
        },
      };
    case actions.SET_TRANSFORM_TO_SI_ACQUIRED_SI_INFO:
      return {
        ...state,
        transformToSiState: {
          ...state.transformToSiState,
          acquiredSiDetail: data.value,
          getAcquiredSiDetailStatus: data.status,
        },
      };
    case actions.SET_SYNC_GE_BETWEEN_SIP_AND_SIMT:
      return {
        ...state,
        transformToSiState: {
          ...state.transformToSiState,
          createGeForTransformResult: data.value,
          syncGeBetweenSipAndSimtStatus: data.status,
        },
      };
    case actions.GET_APPROVEJOURNALLIST:
      return {
        ...state,
        getApproveJournalList: data,
      };
    case actions.CHECK_SIP_NON_CAT_JOURANL:
      return {
        ...state,
        checkIsNonCatJournal: data,
      };
    case actions.CHANGE_SIP_OVERVIRW_COLUMNS:
      return {
        ...state,
        columnsSelected: data,
      };
    case actions.SET_SIP_SOURCE_OPTIONS:
      return {
        ...state,
        sipSourceOptions: data,
      };
    case actions.GET_SIP_USERS:
      const {users, permissionCode, scope} = data;
      return {
        ...state,
        users: {
          ...state.users,
          [`${scope}::${permissionCode}`]: users,
        }
      };
    default:
      return state;
  }
}

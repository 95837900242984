import {axios_instance_fileDownload} from "@/utils/axios_instance";
import {
    DOWNLOAD_PROPOSER_ALLFILE,
    DOWNLOAD_PROPOSER_SINGLEFILE,
    downloadFileOfNewStyle,
    downloadFileOfZip
} from "@/actions/SIMT-SI/GuestEditor/GEAction";
import Config from "@/configs/Config";

export function downloadSingleGEFile(filename, downloadName) {
    downloadName = downloadName || filename;
    return async dispatch => {
        const requestURL = `/sip/proposer/flag/downloadFile?filename=${filename}`;
        try {
            const res = await axios_instance_fileDownload.get(requestURL, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    authorization: localStorage.getItem('authorization'),
                },
            });
            downloadFileOfNewStyle(res.data, downloadName);
            dispatch({type: DOWNLOAD_PROPOSER_SINGLEFILE, data: true});
        } catch (err) {
            dispatch({type: DOWNLOAD_PROPOSER_SINGLEFILE, data: false});
        }
    };
}

export function downloadAllGEFile(proposerId, flagId) {
    console.log('222');
    return async dispatch => {
        try {
            let res = await fetch(
                `${Config.API_URL}/sip/proposer/flag/downloadZip?proposerId=${proposerId}&flagId=${flagId} `,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            let data = await res.blob();
            let x = res.headers
                .get('Content-Disposition')
                .split('=')[1]
                .split('-')[0];
            let geName;
            if (x.includes('+'))
                geName =
                    decodeURIComponent(x).split('+')[0] +
                    ' ' +
                    decodeURIComponent(x).split('+')[1];
            else geName = x;
            geName = geName.substring(1, geName.length);
            let y = res.headers.get('Content-Disposition').split('=')[1].split('-');
            let zipName = '';
            for (let i = 1; i < y.length; i++) zipName = zipName + '-' + y[i];
            zipName = zipName.substring(1, zipName.length - 1);
            downloadFileOfZip(data, geName + '-' + zipName);
            dispatch({type: DOWNLOAD_PROPOSER_ALLFILE, data: true});
        } catch (err) {
            dispatch({type: DOWNLOAD_PROPOSER_ALLFILE, data: false});
        }
    };
}
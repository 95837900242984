import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import defaultIcon from './VectorImage/MenuCollapsedButton/default.png'; 
import hoverIcon from './VectorImage/MenuCollapsedButton/hover.png'; 
import { changeSiderOpen } from '@/actions/SIMT-User/User/UserManageAction';
export default function MenuFolderButton(props) {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const siderOpen = useSelector(state => state.UserManage.siderOpen);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseDown = () => {
    setIsPressed(true);
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      style={{
        position:'absolute',
        top:'56px',
        left:'6px',
        display: 'flex',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        zIndex:10000,
      }}
      id="MenuFolderButton" 
      data-selenium-id='MenuFolderButton'
      onClick={()=>{
        dispatch(changeSiderOpen(!siderOpen));
      }}
    >
      {isHovered || isPressed ? (
        <img src={hoverIcon} alt="Hover Icon" style={{ width: '24px', height: '24px' }} />
      ) : (
        <img src={defaultIcon} alt="Default Icon" style={{ width: '24px', height: '24px' }} />
      )}
    </div>
  );
}

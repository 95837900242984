import {setSnackbarMessageAndOpen} from '../../SnackbarAction';
import {SEVERITIES} from '../../../modules/ConfirmSnackBar/CommonSnackBar';

export const UPLOAD_AT_XML = 'UPLOAD_AT_XML';
export const UPLOAD_FAIL_MESSAGE = 'UPLOAD_FAIL_MESSAGE';
export const SET_FISCAL_YEAR = 'SET_FISCAL_YEAR';
export const CLEAR_ANNUAL_TARGET = 'CLEAR_ANNUAL_TARGET';
export const DOWNLOAD_AT_FILE = 'DOWNLOAD_AT_FILE';
export const GET_AT_INFO = 'GET_AT_INFO';
export const EMPTY_FILE_ERROR = 'EMPTY_FILE_ERROR';

/**
 * 清空Annual Target相关的所有状态
 * @returns
 */
export function clear() {
  return dispatch => {
    dispatch({ type: CLEAR_ANNUAL_TARGET });
  };
}

/**
 * 设置FiscalYear
 * @param {int} fiscalYear
 * @returns
 */
export function setFiscalYear(fiscalYear) {
  return ({ type: SET_FISCAL_YEAR, data: fiscalYear });
}

//用户点击空的target时报错
export function emptyFileError(data) {
  return dispatch => {
    if (data) {
      dispatch(
        setSnackbarMessageAndOpen(
          'annualTarget.emptyFile',
          {},
          SEVERITIES.warning
        )
      );
    }
    dispatch({ type: EMPTY_FILE_ERROR, data: data });
  };
}

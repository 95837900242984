

import { ResponsiveButton } from '@/components/StyledButton/SIMTButton';
import ColumnsIconBlue from '@/assets/sipcolumnsIconBlue.svg';
import ColumnsIconWhite from '@/assets/columnsIconWhite.svg';
export default function ColumnsButton(props) {
  const {
    isPrimary = false,
    ...other
  } = props;
  return isPrimary ? (
    <ResponsiveButton
      id='option-button'
      data-selenium-id='SIPage_Overview-SIOverview-OptionsButton'
      // downBy={downBy}
      color='white'
      variant='contained'
      startIcon={<ColumnsIconBlue />}
      sx={{
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 12px !important',
        gap: '6px',
        // width: '96px',
        minWidth: '48px',
        height: '34px',
        /* Primary/500- */
        border: '1px solid #0052CC',
        borderRadius: '5px',

        /* Caption / Semi Bold */
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '18px',
        /* identical to box height, or 150% */
        textAlign: 'center',

        /* Primary/500- */
        color: '#0052CC',
        flex: 'none',
        order: 1,
        flexGrow: 0,
        '& .MuiButton-startIcon': {
          margin: 0,
        },
        '&:hover': {
          border: '1px solid #6D98EE',
          color: '#6D98EE',
          'svg path': {
            fill: '#6D98EE',
          },
        },
        '&:active': {
          background: '#E8EDF8',
          border: '1px solid #AEC6F6',
          color: '#6D98EE',
          'svg path': {
            fill: '#6D98EE',
          },
        },
      }}
      {...other}
    >
      Columns
    </ResponsiveButton>
  ) : (
    <ResponsiveButton
      id='option-button'
      data-selenium-id='SIPage_Overview-SIOverview-OptionsButton'
      // downBy={downBy}
      color='white'
      variant='contained'
      startIcon={<ColumnsIconWhite />}
      sx={{
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 12px !important',
        gap: '6px',
        // width: '96px',
        minWidth: '48px',
        height: '34px',
        /* Primary/500- */
        borderRadius: '5px',
        background: '#0052CC',
        /* Caption / Semi Bold */
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '18px',
        /* identical to box height, or 150% */
        textAlign: 'center',

        /* Primary/500- */
        color: '#FFFFFF',
        flex: 'none',
        order: 1,
        flexGrow: 0,
        '& .MuiButton-startIcon': {
          margin: 0,
        },
        '&:hover': {
          background: '#6D98EE',
        },
        '&:active': {
          background: '#113D95',
        },
      }}
      {...other}
    >
      Columns
    </ResponsiveButton>
  );

}
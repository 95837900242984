import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetArticleOAvsTotalChartDataWithStatus,
} from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import {replaceZero} from "@/pages/Report/utils/reportUtils";

// Sample data
// import { SampleBDData, SampleSGData } from './sampleData';

export default function useGetChartsDataHook(
  selectedDate,
  selectedSg,
  catagoryAttributeName,
  url,
  title
) {
  const dispatch = useDispatch();

  const { reportDataAPI } = useSelector(state => {
    return {
      reportDataAPI: state.ReportDiagram[title],
    };
  });

  let loading = null,
    error = null,
    data = null;

  if (typeof reportDataAPI !== 'undefined') {
    ({ loading, error, data } = reportDataAPI);
  }

  // use sample data
  // data = SampleBDData;
  // console.log('**data:', data);
  // data = SampleSGData;
  // loading = false;
  // error = null;

  replaceZero(data);
  // console.log('**sample data:', data);

  useEffect(() => {
    if (typeof reportDataAPI === 'undefined' && selectedDate !== null) {
      dispatch(
        GetArticleOAvsTotalChartDataWithStatus(
          url,
          title,
          selectedDate,
          catagoryAttributeName
        )
      );
    }
  }, [url, selectedDate, title, reportDataAPI]);

  const catagoriesFullList = useMemo(() => {
    if (data != undefined && data != null && data.length != 0) {
      return data.map(chartData => chartData[0]).slice(1);
    }
    return [];
  }, [data]);

  // console.log('**catagoriesFullList:', catagoriesFullList);

  const selectedDataForBar = useMemo(() => {
    if (data != undefined && data != null) {
      const headerLine = data.slice(0, 1);
      const dataLines = filterData(data, selectedSg);
      const lines = [...headerLine, ...dataLines];
      return lines;
    }
    return [];
  }, [data, selectedSg]);

  // console.log('**selectedDataForBar:', selectedDataForBar);

  return [catagoriesFullList, selectedDataForBar, data, loading, error];
}

function filterData(subjectGroupAllData, selectedSg) {
  let filteredData = subjectGroupAllData.filter(
    data => selectedSg.includes(data[0])
  );
  return filteredData;
}

import { axios_instance } from "@/utils/axios_instance";
import simpleDisplayError from "@/utils/simpleDisplayError";
import {
    CANCEL_EDIT,
    EXCUTE_PROCEED,
    SAVE_FOR_LATER,
    SET_CHECKLIST_VERSION,
    SET_SIP_CHECKLIST_DETAIL,
    SET_SNACKBAR_ID,
    SET_SNACKBAR_SHOW
} from "@/actions/SIMT-SIP/SIPChecklistSubmitted/SIPChecklistAction";

/**
 * @param {string | undefined} sipCode
 * @param {string} sipStage
 * @param {(e:boolean)=>void} resolve
 */
export function getSIPChecklistDetail(
    sipCode,
    sipStage = 'submitted',
    resolve = e => {
    }
) {
    return async dispatch => {
        //取得checklist
        const data = await axios_instance
            .get(`/api/v1/sip-service/special-issue-proposals/${sipCode}/checkLists`, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
                params:{
                    type:sipStage,
                }
            })
            .then(res => res.data);
        
        if (data.code === 200 && data.data !== null) {
            const detail = data.data;
            const geList = detail.geList || [];
            for (let ge of geList) {
                if (ge['flags']) {
                    ge['flagsBackup'] = [...ge['flags']];
                }
                ge['flagsEditingState'] = 'default';
            }
            dispatch({
                type: SET_SIP_CHECKLIST_DETAIL,
                data: detail,
            });
            resolve(true);
        } else if (data.data === null) {
            // dispatch(simpleDisplayError('This is a sip data directly added to the database without a corresponding checklist'));
        }
    };
}

export function getSIPChecklistVersionWithoutDisplay(
    sipCode,
    sipStage = 'submitted'
) {
    //这个函数专门用于处理浏览器关闭时的情况！
    return async dispatch => {
        const data = await axios_instance
            .get(`/api/v1/sip-service/special-issue-proposals/${sipCode}/checkLists`, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
                params:{
                    type:sipStage,
                }
            })
            .then(res => res.data);
        dispatch({
            type: SET_CHECKLIST_VERSION,
            data: data.data.version,
        });
    };
}

export function excuteEdit(sipCode, version, sipStage = 'submitted') {
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/checkLists`;
            const res = await axios_instance.put(url, {
                version,
            }, {
                params: {
                    version,
                    stage: sipStage,
                    type: 'start-edit'
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            dispatch({
                type: SET_SIP_CHECKLIST_DETAIL,
                data: res.data.data,
            });

            dispatch({ type: CANCEL_EDIT });
            // if (res.data.code === 200) {
            //   //Todo ......

            // } else {
            //   //Todo ......
            // }
        } catch (err) {
            dispatch(simpleDisplayError(err));
        }
    };
}

export function excuteEditWithoutDisplay(
    sipCode,
    version,
    sipStage = 'submitted'
) {
    //这个函数专门用于处理浏览器关闭时的情况！
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/checkLists`;
            const res = await axios_instance.put(url, {}, {
                params: {
                    version,
                    stage: sipStage,
                    type: 'start-edit'
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            //暂时不需要处理
        } catch (err) {
            //暂时不需要处理
        }
    };
}

export function excuteCancelEdit(sipCode, version, sipStage = 'submitted') {
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/checkLists`;
            const res = await axios_instance.put(url, {}, {
                params: {
                    stage: sipStage,
                    version: version,
                    type: 'cancel-edit',
                    action: 'cancel-edit',
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const { code, data } = res.data;

            if (code === 200) {
                //Todo ......
                dispatch({
                    type: SET_SIP_CHECKLIST_DETAIL,
                    data: data,
                });
                dispatch({ type: CANCEL_EDIT });
            } else {
                //Todo ......
            }
        } catch (err) {
            //Todo ......
        }
    };
}

export function excuteCancelEditWithoutDisplay(
    sipCode,
    version,
    sipStage = 'submitted'
) {
    //这个函数专门用于处理浏览器关闭时的情况！
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/checkLists`;
            const res = await axios_instance.put(url, {}, {
                params: {
                    stage: sipStage,
                    version: version,
                    type: 'cancel-edit',
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            //不处理响应结果
        } catch (err) {
            //不处理错误
        }
    };
}

export function excuteUndo(sipCode, version, sipStage = 'submitted') {
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/checkLists`;
            const res = await axios_instance.put(url, {}, {
                params: {
                    version,
                    stage: sipStage,
                    type: 'undo'
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            const { code, data } = res.data;
            if (code === 200) {
                //Todo ......
                dispatch({
                    type: SET_SIP_CHECKLIST_DETAIL,
                    data: data,
                });
                dispatch({ type: CANCEL_EDIT });
            } else if (code === 16079) {
                dispatch({
                    type: SET_SNACKBAR_SHOW,
                    data: true,
                });
                dispatch({
                    type: SET_SNACKBAR_ID,
                    data: 3,
                });
            } else if (code === 16067) {
                dispatch({
                    type: SET_SNACKBAR_SHOW,
                    data: true,
                });
                dispatch({
                    type: SET_SNACKBAR_ID,
                    data: 4,
                });
            }
        } catch (err) {
            //Todo ......
        }
    };
}

export function excuteProceed(SIPChecklistDetail, sipStage = 'submitted', sipCode) {
    //flag选中No时，flags清空。（后端的要求）
    SIPChecklistDetail.geList.forEach(ge => {
        ge.flags = ge?.newFlagAddition === 'Yes' ? ge.flags : [];
    });

    //"flags":[{"flagId":0,"flagName":"","comment":"","fileNames":[]}]
    //筛掉默认值，因为withdraw等情况下process，会不经过校验
    SIPChecklistDetail.geList.forEach(ge => {
        //遍历ge.flags，如果有flagId为0的，就把它删掉
        ge.flags = ge.flags.filter(flag => flag.flagId !== 0);
    });
    if (sipStage === 'initial') {
        SIPChecklistDetail['lastModifyUserInitial'] = SIPChecklistDetail['lastModifyUser'];
        SIPChecklistDetail['versionInitial'] = SIPChecklistDetail['version'];
        SIPChecklistDetail['lockedInitial'] = SIPChecklistDetail['locked'];
        SIPChecklistDetail['geListInitial'] = SIPChecklistDetail['geList'];
        delete SIPChecklistDetail['lastModifyUser'];
        delete SIPChecklistDetail['locked'];
        delete SIPChecklistDetail['geList'];
    }
    if (sipStage === 'submitted') {
        SIPChecklistDetail['geListSubmitted'] = SIPChecklistDetail['geList'];
        delete SIPChecklistDetail['geList'];
    }
    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/checkLists`;
            const res = await axios_instance.put(url, SIPChecklistDetail, {
                params: {
                    stage: sipStage,
                    type: 'proceed',
                    version: SIPChecklistDetail.version,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            if (res.data.code === 200) {
                dispatch({
                    type: EXCUTE_PROCEED,
                    data: res.data.message,
                });
            } else {
                dispatch(simpleDisplayError(res.data));
            }
        } catch (err) {
            dispatch(simpleDisplayError(err));
        }
    };
}

export function excuteSave(SIPChecklistDetail, sipStage = 'submitted', sipCode) {
    //"flags":[{"flagId":0,"flagName":"","comment":"","fileNames":[]}]
    //筛掉默认值
    SIPChecklistDetail.geList.forEach(ge => {
        if (
            ge.flags?.toString() ===
            [{ flagId: 0, flagName: '', comment: '', fileNames: [] }].toString()
        ) {
            ge.flags = [];
        }
    });

    if (sipStage === 'initial') {
        SIPChecklistDetail['lastModifyUserInitial'] = SIPChecklistDetail['lastModifyUser'];
        SIPChecklistDetail['versionInitial'] = SIPChecklistDetail['version'];
        SIPChecklistDetail['lockedInitial'] = SIPChecklistDetail['locked'];
        SIPChecklistDetail['geListInitial'] = SIPChecklistDetail['geList'];
        delete SIPChecklistDetail['lastModifyUser'];
        delete SIPChecklistDetail['locked'];
        delete SIPChecklistDetail['geList'];
    }
    if (sipStage === 'submitted') {
        SIPChecklistDetail['geListSubmitted'] = SIPChecklistDetail['geList'];
        delete SIPChecklistDetail['geList'];
    }

    return async dispatch => {
        try {
            const url = `/api/v1/sip-service/special-issue-proposals/${sipCode}/checkLists`;
            const res = await axios_instance.put(url, SIPChecklistDetail, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
                params: {
                    version: SIPChecklistDetail.version,
                    stage: sipStage,
                    type: 'save'
                },
            });

            if (res.data.code === 200) {
                //Todo ......

                const detail = res.data.data;
                const geList = detail.geList || [];
                for (let ge of geList) {
                    ge['flagsBackup'] = [...ge['flags']];
                    ge['flagsEditingState'] = 'default';
                }

                dispatch({
                    type: SAVE_FOR_LATER,
                    data: detail,
                });
            } else {
                dispatch(simpleDisplayError(res.data));
            }
        } catch (err) {
            // dispatch(simpleDisplayError(err));
            // 不止为何，请求成功后还有checklist state changed的error
        }
    };
}
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Tooltip,
  TableCell,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '../../../../components/DataTable';
import {
  StyledTableCellGray,
  StyledTableCellDisPurple,
  StyledTableRow, StyledTableCellDisGray, StyledTableCellNormal, TableBox, ReportNoData, TableBox2
} from "../../ReportSpecificComponents/SpecialColorTableHeader";
import '../../../../App.css';
import { findLongestLineSubstring, measureLongestWidth, measureWidth } from "../../../../utils/StringWidthMeasure";
import React from "react";
import { SimpleTooltip } from "../../../../componentsphase2/CustomTooltip";
import {GetArticleLastActionDate, getArticleOAvsTotalSGData} from "@/actions/SIMT-Report/Report/ReportArticle";

export function ArticlesOAvsTotalSG() {
  const { selectMonthDate, OAvsTotalSGTableData } = useSelector(state => {
    return {
      // selectMonthDate: state.Report.ArticlesOAvsTotalDate,
      selectMonthDate: state.Report.selectDate,
      OAvsTotalSGTableData: state.Report.ArticlesOAvsTotalSGData,
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    widthMap['subjectGroup'] = measureWidth(findLongestLineSubstring('Subject Group'), '18px Open Sans');

    const CUR_LEN = measureLongestWidth('Sub - APR', '18px Open Sans');
    const CUR_OA_LEN = measureLongestWidth('Sub OA - APR', '18px Open Sans');
    const OAP_LEN = measureLongestWidth('OA %', '18px Open Sans');
    const YTD_LEN = measureLongestWidth('Sub - YTD', '18px Open Sans');
    const YTD_OA_LEN = measureLongestWidth('Sub OA - YTD', '18px Open Sans');

    for (let type of ['sub','acc','pub']) {
      widthMap[`${type}-0`] = CUR_LEN;
      widthMap[`${type}-1`] = CUR_OA_LEN;
      widthMap[`${type}-2`] = OAP_LEN;
      widthMap[`${type}-3`] = YTD_LEN;
      widthMap[`${type}-4`] = YTD_OA_LEN;
      widthMap[`${type}-5`] = OAP_LEN;
    }

    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (OAvsTotalSGTableData && OAvsTotalSGTableData.length > 0){
      const maxMap = {};
      for (let i = 0; i < OAvsTotalSGTableData.length; i++) {
        let row = OAvsTotalSGTableData[i];
        let dataObj = {
          subjectGroup: row.sgAbbr ?? row.subjectGroup,

          'sub-0':  row.submittedArt ?? '0',
          'sub-1':  row.submittedArtOa ?? '0',
          'sub-2':  row.submittedArtOaPercentage ?? '0',
          'sub-3':  row.submittedArtYTD ?? '0',
          'sub-4':  row.submittedArtYTDOa ?? '0',
          'sub-5':  row.submittedArtOaYTDPercentage ?? '0',

          'acc-0':  row.acceptedArt ?? '0',
          'acc-1':  row.acceptedArt_Oa ?? '0',
          'acc-2':  row.acceptedArtOaPercentage ?? '0',
          'acc-3':  row.acceptedArtYTD ?? '0',
          'acc-4':  row.acceptedArtYTDOa ?? '0',
          'acc-5':  row.acceptedArtOaYTDPercentage ?? '0',

          'pub-0':  row.publishedArt ?? '0',
          'pub-1':  row.publishedArtOa ?? '0',
          'pub-2':  row.publishedArtOaPercentage ?? '0',
          'pub-3':  row.publishedArtYTD ?? '0',
          'pub-4':  row.publishedArtYTDOa ?? '0',
          'pub-5':  row.publishedArtOaYTDPercentage ?? '0',
        };
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [OAvsTotalSGTableData]);

  function calcGroupWidth(type){
    let sum = 0;
    for (let i of [0, 1, 2, 3, 4, 5]) {
      sum += Math.max((columnWidthMap[`${type}-${i}`]??0), columnMinWidthMap[`${type}-${i}`]) + 50;
    }
    return sum;
  }

  const [headers1, setHeader1] = useState([]);
  const [headers2, setHeader2] = useState([]);
  const [headers3, setHeader3] = useState([]);
  const dispatch = useDispatch();
  const monthList = [
    'JAN',
    'FEB',
    'MAR',
    ' APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    ' NOV',
    'DEC',
  ];

  useEffect(() => {
    if (selectMonthDate !== null) {
      var num = selectMonthDate.split('-')[1];
      var MonthString = monthList[num - 1];
      var tableHead1 = [
        {
          label: 'sub - ' + MonthString,
          id: 'sub-' + MonthString,
          minWidth: '70px',
          maxWidth: '70px',
        },
        {
          label: 'sub OA - ' + MonthString,
          id: 'sub-OA-' + MonthString,
          minWidth: '84px',
          maxWidth: '84px',
        },
        {
          label: 'OA %',
          id: 'OA%',
          minWidth: '40px',
          maxWidth: '40px',
        },
        {
          label: 'Sub - YTD',
          id: 'Sub-YTD',
          minWidth: '60px',
          maxWidth: '60px',
        },
        {
          label: 'Sub OA - YTD',
          id: 'Sub-OA-YTD',
          minWidth: '80px',
          maxWidth: '80px',
        },
        {
          label: 'OA %',
          id: 'OA1%',
          minWidth: '40px',
          maxWidth: '40px',
        },
      ];
      setHeader1(tableHead1);
      var tableHead2 = [
        {
          label: 'acc - ' + MonthString,
          id: 'acc-' + MonthString,
          minWidth: '60px',
          maxWidth: '60px',
        },
        {
          label: 'acc OA - ' + MonthString,
          id: 'acc-OA-' + MonthString,
          minWidth: '80px',
          maxWidth: '80px',
        },
        {
          label: 'OA %',
          id: 'OA%',
          minWidth: '40px',
          maxWidth: '40px',
        },
        {
          label: 'Acc - YTD',
          id: 'Acc-YTD',
          minWidth: '60px',
          maxWidth: '60px',
        },
        {
          label: 'Acc OA - YTD',
          id: 'Acc-OA-YTD',
          minWidth: '80px',
          maxWidth: '80px',
        },
        {
          label: 'OA %',
          id: 'OA1%',
          minWidth: '40px',
          maxWidth: '40px',
        },
      ];
      setHeader2(tableHead2);
      var tableHead3 = [
        {
          label: 'pub - ' + MonthString,
          id: 'pub-' + MonthString,
          minWidth: '70px',
          maxWidth: '70px',
        },
        {
          label: 'pub OA - ' + MonthString,
          id: 'pub-OA-' + MonthString,
          minWidth: '84px',
          maxWidth: '84px',
        },
        {
          label: 'OA %',
          id: 'OA%',
          minWidth: '40px',
          maxWidth: '40px',
        },
        {
          label: 'Pub - YTD',
          id: 'Pub-YTD',
          minWidth: '60px',
          maxWidth: '60px',
        },
        {
          label: 'Pub OA - YTD',
          id: 'Pub-OA-YTD',
          minWidth: '84px',
          maxWidth: '84px',
        },
        {
          label: 'OA %',
          id: 'OA1%',
          minWidth: '40px',
          maxWidth: '40px',
        },
      ];
      setHeader3(tableHead3);
      dispatch(getArticleOAvsTotalSGData(selectMonthDate));
      dispatch(GetArticleLastActionDate(selectMonthDate, false));
    }
  }, [selectMonthDate]);

  const headerChoose = index => {
    switch (index) {
      case 1:
        return headers1;
      case 2:
        return headers2;
      case 3:
        return headers3;
    }
  };

  const typeRank = ['','sub','acc','pub'];
  const displayHeader = i => {
    const type = typeRank[i];
    return headerChoose(i).map((item,index) => {
      if (item.id.indexOf('OA1%') !== -1) {
        return (
          <StyledTableCellDisGray
            data-selenium-id='Report_Articles-OAvsTotalSG-Header'
            align='left'
            key={item.id}
            sx={{
              // top: '52.5px',
              // minWidth: item.minWidth,
              // maxWidth: item.maxWidth,
              top: 42,
              width: `${Math.max((columnWidthMap[`${type}-${index}`]??0), columnMinWidthMap[`${type}-${index}`]) + 50 }px`,
            }}
            className='RightBottomBorder'
          >
            {item.label}
          </StyledTableCellDisGray>
        );
      } else {
        return (
          <StyledTableCellDisGray
            data-selenium-id='Report_Articles-OAvsTotalSG-Header'
            align='left'
            key={item.id}
            sx={{
              // top: '52.5px',
              // minWidth: item.minWidth,
              // maxWidth: item.maxWidth,
              top: 42,
              width: `${Math.max((columnWidthMap[`${type}-${index}`]??0), columnMinWidthMap[`${type}-${index}`]) + 50 }px`,
            }}
            className='BottomBorder'
          >
            {item.label}
          </StyledTableCellDisGray>
        );
      }
    });
  };

  const showDataSGTable = () => {
    if (OAvsTotalSGTableData === null || OAvsTotalSGTableData === undefined) {
      return [];
    } else {
      return OAvsTotalSGTableData;
    }
  };

  return (
    <TableBox2
      dataList={[OAvsTotalSGTableData]}
      tableKey={'Report_Articles_OAvsTotal-SGTable'}
      footerHeight={0}
    >
      <TableContainer
        id='reportArticlesOATSGTable'
        data-selenium-id='Report_Articles-OAvsTotalSG-SGTableContainer'
        sx={{ maxHeight: '560px' }}
      >
        <Table
          stickyHeader
          aria--label='sticky table'
          data-selenium-id='Report_Articles-OAvsTotalSG-SGTable'
          sx={{
            tableLayout: 'fixed',
            width: 'max-content',
            maxWidth: 'none',
          }}
        >
          <TableHead data-selenium-id='Report_Articles-OAvsTotalSG-SGTableHeader'>
            <TableRow data-selenium-id='Report_Articles-OAvsTotalSG-SGTableRow'>
              <StyledTableCellNormal
                sx={{
                  // minWidth: '140px'
                  width: `${Math.max((columnWidthMap['subjectGroup']??0), columnMinWidthMap['subjectGroup']) + 50 }px`,
                  position: 'sticky',
                  left: 0,
                  top: 0,
                  zIndex: 8,
                  backgroundColor: '#DFE4E8',
                }}
                className='RightBottomBorder'
                rowSpan={2}
                align='left'
                data-selenium-id='Report_Articles-OAvsTotalSG-SGTableRow-SubjectGroup'
              >
                Subject Group
              </StyledTableCellNormal>
              <StyledTableCellGray
                align='left'
                colSpan={6}
                className='RightBottomBorder ReportHeaderFirstLine'
                data-selenium-id='Report_Articles-OAvsTotalSG-SGTableRow-Submission'
                sx={{
                  width: `${calcGroupWidth('sub')}px`,
                }}
              >
                Submission
              </StyledTableCellGray>
              <StyledTableCellGray
                align='left'
                colSpan={6}
                className='RightBottomBorder ReportHeaderFirstLine'
                data-selenium-id='Report_Articles-OAvsTotalSG-SGTableRow-Acceptance'
                sx={{
                  width: `${calcGroupWidth('acc')}px`,
                }}
              >
                Acceptance
              </StyledTableCellGray>
              <StyledTableCellGray
                align='left'
                colSpan={6}
                className='RightBottomBorder'
                data-selenium-id='Report_Articles-OAvsTotalSG-SGTableRow-Publication'
                sx={{
                  width: `${calcGroupWidth('pub')}px`,
                }}
              >
                Publication
              </StyledTableCellGray>
            </TableRow>
            <TableRow>
              {displayHeader(1)}
              {displayHeader(2)}
              {displayHeader(3)}
            </TableRow>
          </TableHead>
          <TableBody data-selenium-id='Report_Articles-OAvsTotalSG-SGTableBody'>
            {OAvsTotalSGTableData != null &&
            OAvsTotalSGTableData != undefined &&
            OAvsTotalSGTableData.length != 0 ? (
              OAvsTotalSGTableData.map((row, index) => {
                if (row.subjectGroup === 'TOTAL') {
                  return (
                    <StyledTableRow>
                      <StyledTableCell
                        align='right'
                        className='RightBorder ReportTotalTextSize'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell1-${index}`}
                        sx={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 6,
                          backgroundColor: '#F1F3F5',
                        }}
                      >
                        <Box>Total</Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell2-${index}`}
                      >
                        <Box className='ReportNumberTextColor ReportTotalTextSize'>
                          {row.submittedArt === null ? '0' : row.submittedArt}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell3-${index}`}
                      >
                        <Box className='ReportNumberTextColor ReportTotalTextSize'>
                          {row.submittedArtOa === null
                            ? '0'
                            : row.submittedArtOa}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell4-${index}`}
                      >
                        <Box className='ReportNumberTextColor ReportTotalTextSize'>
                          {row.submittedArtOaPercentage}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell5-${index}`}
                      >
                        <Box className='ReportNumberTextColor ReportTotalTextSize'>
                          {row.submittedArtYTD === null
                            ? '0'
                            : row.submittedArtYTD}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell6-${index}`}
                      >
                        <Box className='ReportNumberTextColor ReportTotalTextSize'>
                          {row.submittedArtYTDOa === null
                            ? '0'
                            : row.submittedArtYTDOa}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportTotalTextSize'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell7-${index}`}
                      >
                        <Box className='ReportNumberTextColor'>
                          {row.submittedArtOaYTDPercentage}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell8-${index}`}
                      >
                        <Box className='ReportNumberTextColor ReportTotalTextSize'>
                          {row.acceptedArt === null ? '0' : row.acceptedArt}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell9-${index}`}
                      >
                        <Box className='ReportNumberTextColor ReportTotalTextSize'>
                          {row.acceptedArt_Oa === null
                            ? '0'
                            : row.acceptedArt_Oa}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell10-${index}`}
                      >
                        <Box className='ReportNumberTextColor ReportTotalTextSize'>
                          {row.acceptedArtOaPercentage}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell11-${index}`}
                      >
                        <Box className='ReportNumberTextColor ReportTotalTextSize'>
                          {row.acceptedArtYTD === null
                            ? '0'
                            : row.acceptedArtYTD}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell12-${index}`}
                      >
                        <Box className='ReportNumberTextColor ReportTotalTextSize'>
                          {row.acceptedArtYTDOa === null
                            ? '0'
                            : row.acceptedArtYTDOa}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportTotalTextSize'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell13-${index}`}
                      >
                        <Box className='ReportNumberTextColor'>
                          {row.acceptedArtOaYTDPercentage}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell14-${index}`}
                      >
                        <Box className='ReportNumberTextColor ReportTotalTextSize'>
                          {row.publishedArt === null ? '0' : row.publishedArt}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell15-${index}`}
                      >
                        <Box className='ReportNumberTextColor ReportTotalTextSize'>
                          {row.publishedArtOa === null
                            ? '0'
                            : row.publishedArtOa}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell16-${index}`}
                      >
                        <Box className='ReportNumberTextColor ReportTotalTextSize'>
                          {row.publishedArtOaPercentage}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell17-${index}`}
                      >
                        <Box className='ReportNumberTextColor ReportTotalTextSize'>
                          {row.publishedArtYTD === null
                            ? '0'
                            : row.publishedArtYTD}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell18-${index}`}
                      >
                        <Box className='ReportNumberTextColor ReportTotalTextSize'>
                          {row.publishedArtYTDOa === null
                            ? '0'
                            : row.publishedArtYTDOa}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportTotalTextSize'
                        data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell19-${index}`}
                      >
                        <Box className='ReportNumberTextColor'>
                          {row.publishedArtOaYTDPercentage}
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                }
                return (
                  <StyledTableRow>
                    <StyledTableCell
                      align='left'
                      className='RightBorder'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell1-${index}`}
                      sx={{
                        position: 'sticky',
                        left: 0,
                        zIndex: 6,
                        backgroundColor: '#F1F3F5',
                      }}
                    >
                      <SimpleTooltip title={row.subjectGroup}>
                          {row.sgAbbr ?? row.subjectGroup}
                        </SimpleTooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell2-${index}`}
                    >
                      {row.submittedArt === null ? '0' : row.submittedArt}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell3-${index}`}
                    >
                      {row.submittedArtOa === null ? '0' : row.submittedArtOa}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell4-${index}`}
                    >
                      {row.submittedArtOaPercentage}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell5-${index}`}
                    >
                      {row.submittedArtYTD === null ? '0' : row.submittedArtYTD}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell6-${index}`}
                    >
                      {row.submittedArtYTDOa === null
                        ? '0'
                        : row.submittedArtYTDOa}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='RightBorder'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell7-${index}`}
                    >
                      <div className='ReportNumberTextColor'>
                        {row.submittedArtOaYTDPercentage}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell8-${index}`}
                    >
                      {row.acceptedArt === null ? '0' : row.acceptedArt}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell9-${index}`}
                    >
                      {row.acceptedArt_Oa === null ? '0' : row.acceptedArt_Oa}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell10-${index}`}
                    >
                      {row.acceptedArtOaPercentage}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell11-${index}`}
                    >
                      {row.acceptedArtYTD === null ? '0' : row.acceptedArtYTD}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell12-${index}`}
                    >
                      {row.acceptedArtYTDOa === null
                        ? '0'
                        : row.acceptedArtYTDOa}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='RightBorder'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell13-${index}`}
                    >
                      <div className='ReportNumberTextColor'>
                        {row.acceptedArtOaYTDPercentage}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell14-${index}`}
                    >
                      {row.publishedArt === null ? '0' : row.publishedArt}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell15-${index}`}
                    >
                      {row.publishedArtOa === null ? '0' : row.publishedArtOa}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell16-${index}`}
                    >
                      {row.publishedArtOaPercentage}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell17-${index}`}
                    >
                      {row.publishedArtYTD === null ? '0' : row.publishedArtYTD}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='ReportNumberTextColor'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell18-${index}`}
                    >
                      {row.publishedArtYTDOa === null
                        ? '0'
                        : row.publishedArtYTDOa}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      className='RightBorder'
                      data-selenium-id={`Report_Articles-OAvsTotalSG-SGTableBody-TableCell19-${index}`}
                    >
                      <div className='ReportNumberTextColor'>
                        {row.publishedArtOaYTDPercentage}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <ReportNoData id='AcquiredAndActiveSiAnnualTrendsBDNoResultTableCell' />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TableBox2>
  );
}
export default ArticlesOAvsTotalSG;

import {
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
} from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import GEDetailCloseIcon from '@/assets/GEDetailCloseIcon.svg';
import CheckedRadioIcon from '@/assets/CheckedRadioIcon.svg';
import DefaultRadioIcon from '@/assets/DefaultRadioIcon.svg';
import {
  CancelButton,
  EditBtnAvailable,
  EditBtnDisabled,
} from './GEDetailButtonStyle';
import HideTextConflictDetail from './HideTextConflictDetail';
import { DownloadCvBlock } from './DownloadCvBlock';
import EditContent from './EditContent';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SIModal } from '@/modules/Modal';
import SimpleRadio from '@/componentsphase2/Input/SimpleRadio';
import { CustomTooltip } from '@/components/CustomTooltip';
import { SimpleTooltip } from '@/componentsphase2/CustomTooltip';
import { LoadingButton } from '@mui/lab';
import { useIntl } from 'react-intl';
import { GET_SIP_STATUS } from '../../../../actions/SIMT-SIP/SIP/SIPAction';
import {DeleteCV, EditGEInfomation, getGEInformation, getSIPStatus} from "@/actions/SIMT-SIP/SIP/SipDetail";
import {ChecklistStatus, extractHtmlId, FormAction, FormComponentProps, useForm} from "@/components/Form/form.d";
import simpleDisplayError from "@/utils/simpleDisplayError";

export default function GEDetailDialog(props) {
  const { isOpen, handleGEDetailClose, theGeInformation, Editable } = props;
  const [editOrShow, setEditOrShow] = useState(false); //true表示edit, false表示show
  // 用来存储数组，方便遍历
  const [theGeInformationNew, setTheGeInformationNew] = useState([]);
  const [id, setId] = useState(theGeInformation.id);
  const [firstName, setFirstName] = useState(theGeInformation.fullName);
  const [lastName, setLastName] = useState(theGeInformation.lastName);
  const [leadGe, setLeadGe] = useState(theGeInformation.leadGe);
  const [jobTitle, setJobTitle] = useState(theGeInformation.jobTitle);
  const [emailAddress, setEmailAddress] = useState(
    theGeInformation.emailAddress
  );
  const [department, setDepartment] = useState(theGeInformation.department);
  const [institution, setInstitution] = useState(theGeInformation.institution);
  const [orcidID, setOrcidID] = useState(theGeInformation.orcidID);
  const [country, setCountry] = useState(theGeInformation.country);
  const [researchGateId, setResearchGateId] = useState(
    theGeInformation.researchGateId
  );
  const [profileWebsite, setProfileWebsite] = useState(
    theGeInformation.profileWebsite
  );
  const [cv, setCv] = useState(theGeInformation.cv);
  const [cvSize,setCvSize] = useState(theGeInformation.cvSize);
  const [hasConflict, setHasConflict] = useState(theGeInformation.hasConflict);
  const [conflictDetail, setConflictDetail] = useState(
    theGeInformation.conflictDetail
  );

  const [editData, setEditData] = useState(null);
  const [editEmailFormatErr, setEditEmailFormatErr] = useState(false);
  const [webFormatErr, setWebFormatErr] = useState(false);
  const [orcIdFormatErr, setOrcIdFormatErr] = useState(false);

  const [isClick, setIsClick] = useState(false);

  const { sipCode } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    getSIPStatus(sipCode);
  }, []);

  const selector = state => {
    return {
      editGEResult: state.SIP.editGEResult,
      editGEMonitor: state.SIP.editGEMonitor,
      sipStage: state.SIP.sipStatus?.sipStage,
      currentDecisionType: state.SIP.currentDecisionType,
      sipVersion: state.SIP.sipStatus?.sipVersion,
      sipStatus: state.SIP.sipStatus,
      sipDetailAllMandatorySubmitOrNextPressed: state.SIP.sipDetailAllMandatorySubmitOrNextPressed,
      sipChecklistStatus: state.SIPChecklist.SIPChecklistDetail.status,
    };
  };
  const { editGEResult, editGEMonitor, sipStage, currentDecisionType, sipVersion, sipStatus, sipDetailAllMandatorySubmitOrNextPressed,sipChecklistStatus} = useSelector(selector);
  const intl = useIntl();

  function GEDetailDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle
        sx={{ m: 0, p: 0, pb: 3, width: '523px' }}
        {...other}
        data-selenium-id='SIP_DetailPage_GE-Dialog-Title'
      >
        {children}
        {onClose ? (
          <IconButton
            data-selenium-id='SIP_DetailPage_GE-Dialog-IconButton'
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 35,
              top: 32,
              padding: 0,
            }}
          >
            <GEDetailCloseIcon data-selenium-id='SIP_DetailPage_GE-Dialog-CloseIcon' />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  useEffect(() => {
    let theGeInformationNewArr = [];
    for (let i = 0; i < Object.keys(theGeInformation).length; i++) {
      switch (Object.keys(theGeInformation)[i]) {
        case 'id':
          break;
        case 'hasConflict':
          break;
        case 'conflictDetail':
          break;
        case 'researchKeywords':
          break;
        default:
          theGeInformationNewArr.push({
            name: Object.keys(theGeInformation)[i],
            value: theGeInformation[Object.keys(theGeInformation)[i]],
          });
      }
    }
    setTheGeInformationNew([...theGeInformationNewArr]);
  }, [theGeInformation]);

  const handleFieldsReplacement = (key, val) => {
    if (key === 'profileWebsite') {
      if (val) {
        return (
          <Box
            data-selenium-id='SIP_DetailPage_GE-Dialog-HandleFieldsReplacement-Box'
            sx={{
              cursor: 'pointer',
              color: '#154AB6',
              display: 'inline-block',
            }}
            onClick={() => {
              if (
                val.substr(0, 7).toLowerCase() === 'http://' ||
                val.substr(0, 8).toLowerCase() === 'https://'
              ) {
                window.open(val);
              } else {
                window.open('https://' + val);
              }
            }}
          >
            {val}
          </Box>
        );
      } else {
        return 'N/A';
      }
    } else if (key === 'cv') {
      const nativeFileName=val;
      if (val) {
        if(val.split('_').length>1) val=val.split('_')[1];
        return (
          <DownloadCvBlock fileName={val} nativeFileName={nativeFileName} hasName={true} fileSize={cvSize}></DownloadCvBlock>
        );
      } else {
        return 'N/A';
      }
    } else if (key === 'leadGe') {
      if (val === true) {
        return 'Yes';
      } else if (val === false) {
        return 'No';
      } else {
        return 'N/A';
      }
    } else if (key === 'department') {
      if (val) {
        return val;
      } else {
        return 'N/A';
      }
    } else if (key === 'orcidId') {
      if (val) {
        return val;
      } else {
        return 'N/A';
      }
    } else if (key === 'researchGateId') {
      if (val) {
        return val;
      } else {
        return 'N/A';
      }
    } else if (key === 'city') {
      if (val) {
        return val;
      } else {
        return 'N/A';
      }
    } else if (key === 'institution') {
      if (val) {
        return val;
      } else {
        return 'N/A';
      }
    } else if (key === 'country') {
      if (val) {
        return val;
      } else {
        return 'N/A';
      }
    } else {
      return val;
    }
  };
  const handleNameReplacement = key => {
    switch (key) {
      case 'firstName':
        return 'First Name';
      case 'lastName':
        return 'Last Name';
      case 'leadGe':
        return 'Lead GE';
      case 'jobTitle':
        return 'Title';
      case 'emailAddress':
        return 'Email Address';
      case 'department':
        return 'Department';
      case 'institution':
        return 'Institution';
      case 'city':
        return 'City';
      case 'country':
        return 'Country/Region';
      case 'orcidId':
        return 'ORCID ID';
      case 'researchGateId':
        return 'ResearchGate ID';
      case 'profileWebsite':
        return 'Profile Website';
      case 'cv':
        return 'CV';
      default:
        return 'N/A';
    }
  };

  const ErrBox = () => {
    let errText = '';
    if (editData) {
      if (editEmailFormatErr) {
        errText = 'A valid email address must be filled in.';
      } else if (
        isClick &&
        (editData.jobTitle === '' ||
          editData.firstName === '' ||
          editData.lastName === '' ||
          editData.leadGe === null ||
          editData.leadGe === undefined ||
          editData.emailAddress === '' ||
          (editData.hasConflict === true && (editData.conflictDetail === '' || editData.conflictDetail?.length > 10000)) ||
          ((editData.institution === '' ||
          editData.country === '' ||
          (editData.profileWebsite === '' && editData.cv === null) ||
          editData.hasConflict === null) &&
          (sipStage !== intl.messages['sipDetail.sipStageType.ideation'] ||
          currentDecisionType === intl.messages['sipDetail.decisionPanelElements.moveToInitialReview'])
          ))
      ) {
        errText = 'Please input all the mandatory fields!';
      } else if (webFormatErr&&isClick) {
        errText = 'The profile website must be a valid URL.';
      } 
      // else if (isClick && orcIdFormatErr && editData?.orcidId) {
      //   errText = 'Incorrect OCRID, please input like this way 0000-0000-0000-0000.';
      // }
      // if (editEmailFormatErr) {
      //   errText = 'A valid email address must be filled in.';
      // } else if (webFormatErr) {
      //   errText = 'The profile website must be a valid URL.';
      // } else if (
      //   isClick &&
      //   (editData.jobTitle === '' ||
      //     editData.firstName === '' ||
      //     editData.lastName === '' ||
      //     editData.emailAddress === '' ||
      //     editData.institution === '' ||
      //     editData.country === '' ||
      //     (editData.profileWebsite === '' && editData.cv === null) ||
      //     (editData.hasConflict && editData.conflictDetail === ''))
      // ) {
      //   errText = 'Please input all the mandatory fields!';
      // }
      else return <></>;
    }
    return (
      <Box
        data-selenium-id='SIP_DetailPage_GE-Dialog-ErrBox'
        sx={{
          height: '16px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '16px',
          color: '#EE5350',
          letterSpacing: '-0.084px',
          marginTop: '10px',
          display: errText === '' ? 'none' : 'block',
        }}
      >
        {errText}
      </Box>
    );
  };

  const handleSave = () => {
    setIsClick(true);
    setTimeout(() => {
      if (
        editEmailFormatErr ||
        orcIdFormatErr && editData?.orcidId || // CT7416
        webFormatErr&&editData?.profileWebsite ||  //CT-6662
        (editData &&
          (editData.jobTitle === '' ||
            editData.firstName === '' ||
            editData.lastName === '' ||
            editData.emailAddress === '' ||
            (editData.hasConflict === true && (editData.conflictDetail === '' || editData.conflictDetail?.length > 10000)) ||
            ((editData.institution === '' ||
            editData.country === '' ||
            (editData.profileWebsite === '' && editData.cv === null) ||
            editData.hasConflict === null) && 
            (sipStage !== intl.messages['sipDetail.sipStageType.ideation'] ||
            currentDecisionType === intl.messages['sipDetail.decisionPanelElements.moveToInitialReview'])
            )))
      ) {
        setSaving(false);
        return;
      }
      console.log("theGeInformation:", theGeInformation);
      const fetchData = async () => {
        await dispatch(
          EditGEInfomation({
            geId: theGeInformation.geId,
            id: theGeInformation.id,
            sipCode: sipCode,
            sipVersion: sipVersion,
            ...editData,
          })
        ).then((sipNewVersion) => {
          if (sipNewVersion) {
            let newSipStatus = {
              ...sipStatus,
              sipVersion: sipNewVersion
            };
            dispatch({ type: GET_SIP_STATUS, data: {
              ...newSipStatus
            }});
          }
        });
        setSaving(false);
      };
      fetchData();
      
    }, 300);
  };

  const [first, setFirst] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (first) {
      setFirst(false);
      setSaving(false);
      return;
    }
    if (editGEResult === 'OK') {
      if (theGeInformation.cv && editData.cv !== theGeInformation.cv) {
        dispatch(DeleteCV(theGeInformation.cv));
      }
      const fetchData = async () => {
        await dispatch(getGEInformation(sipCode));
        handleGEDetailClose();
        setSaving(false);
      };
      fetchData();
      
      
    }
  }, [editGEMonitor]);

  useEffect(() =>{
    if(editOrShow) {
      //  ideation 进行过decision后，发现GE信息有问题，对异常GE进行edit时。要直接显示报错，不要等点击save按钮后再显示
      setIsClick(sipDetailAllMandatorySubmitOrNextPressed && sipStage === intl.messages['sipDetail.sipStageType.ideation']);
    }
  }, [editOrShow]);

  return (
    <SIModal
      data-selenium-id='SIP_DetailPage_GE-HandleSave-Dialog'
      open={isOpen}
      handleClose={handleGEDetailClose}
      isRightModel={true}
      title='GE Detail'
      scroll={'paper'}
      titleElseElement={
        editOrShow ? (
          <Box data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions-Box'>
            <CancelButton
              id='cancelGE'
              data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions-Box-CancelGE'
              onClick={() => {
                if (editData.cv && editData.cv !== theGeInformation.cv) {
                  dispatch(DeleteCV(editData.cv));
                }
                setIsClick(false);
                handleGEDetailClose();
              }}
              variant='text'
            >
              Cancel
            </CancelButton>
            <LoadingButton
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                padding: '12px 18px',
                background: saving?'#6D98EE':'#0052CC',
                borderRadius: '5px',
                color: '#FFFFFF',
                width: '82px',
                height: '34px',
                textTransform: 'none',
                '&:hover':{
                  background: '#6D98EE',
                },
                '&:active':{
                  background: '#113D95',
                }
              }}
              disableElevation
              disableRipple
              onClick={() =>{
                setSaving(true);
                async function fetchData(){
                  // await delay(300);
                  await handleSave();
                  // setSaving(false);
                  
                  
                }
                fetchData();
                
              }}
              loading={saving}
              data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions-Box-EditBtnAvailable'
            >
              Save
            </LoadingButton>
          </Box>
        ) : Editable ? (
          <EditBtnAvailable
            data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions-EditBtnAvailable'
            onClick={() => {
              if (
                sipStage === 'Initial Review'&&
                (sipChecklistStatus === ChecklistStatus.IN_PROGRESS ||
                sipChecklistStatus === ChecklistStatus.COMPLETED)
              ) {
                return dispatch(
                  simpleDisplayError(
                    'Failed to modify SIP because checklist is in editing or completed.'
                  )
                );
              }

              setEditOrShow(true);
            }}
          >
            Edit
          </EditBtnAvailable>
        ) : (
          <EditBtnDisabled
            disabled={true}
            data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions-EditBtnDisabled'
          >
            Edit
          </EditBtnDisabled>
        )
      }
    >
      {editOrShow ? (<ErrBox data-selenium-id='SIP_DetailPage_GE-Dialog-ErrBox' />):(<></>)}
      <DialogContent
        sx={{ p: 0, pb: '16px' }}
        data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content'
      >
        {editOrShow ? (
          <Box data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box1'>
            {/* <ErrBox></ErrBox> */}{/* CT-6662 */}
            <EditContent
              value={theGeInformation}
              setValue={setEditData}
              setEmailAddressFormatErr={setEditEmailFormatErr}
              setWebFormatErr={setWebFormatErr}
              webFormatErr={webFormatErr}
              orcIdFormatErr={orcIdFormatErr}
              setOrcIdFormatErr={setOrcIdFormatErr}
              isClick={isClick}
            ></EditContent>
          </Box>
        ) : (
          <Stack
            data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2'
            width='100%'
            direction='row'
            flexWrap='wrap'
          >
            {theGeInformationNew ? (
              theGeInformationNew.map((item, index) => (
                handleNameReplacement(item.name)!=='N/A'&&<Stack
                  data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-Stack'
                  // spacing={2.5}
                  key={item.name + index}
                  pb={1}
                  width={'50%'}
                >
                  <Box
                    data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-InnerBox1'
                    sx={{
                      width: '140px',
                      height: '20px',
                      fontFamily: 'Open Sans',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#98A7B6',
                      letterSpacing: '1%',
                    }}
                  >
                    {handleNameReplacement(item.name)}
                  </Box>
                  <Box
                    data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-InnerBox2'
                    sx={{
                      width: '259px',
                      height: '32px',
                      fontFamily: 'Open Sans',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#262E35',
                      alignSelf: 'stretch',
                    }}
                  >
                    {item.name === 'cv' &&
                      handleFieldsReplacement(item.name, item.value)}
                    {item.name === 'profileWebsite' && (
                      <Box
                        id='profileWebsiteBox'
                        data-selenium-id='SIP_DetailPage_GE-Dialog-ProfileWebsiteBox'
                        sx={{
                          height: '24px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        <CustomTooltip
                          title={item.value}
                          data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-Stack-Tooltip'
                          sx={{ translate: '0 12px' }}
                          placement='top'
                        >
                          {handleFieldsReplacement(item.name, item.value)}
                        </CustomTooltip>
                      </Box>
                    )}
                    {item.name !== 'cv' &&
                      item.name !== 'profileWebsite' &&
                      handleFieldsReplacement(item.name, item.value)}
                  </Box>
                </Stack>
              ))
            ) : (
              <></>
            )}
            <Divider
              data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-Divider'
              sx={{
                borderColor: '#F1F3F5',
                margin: '8px 0px 16px 0px',
                width: '100%',
              }}
            />
            <Stack
              direction='column'
              spacing={0.5}
              data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-ColumnStack'
            >
              <Box
                data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-ColumnBox'
                sx={{
                  width: '213px',
                  height: '20px',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#98A7B6',
                  letterSpacing: '1%',
                }}
              >
                Conflict of Interest Declaration:
              </Box>
              <Box
                data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-ColumnBox-Subtitle'
                sx={{
                  height: '20px',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '120%',
                  color: '#596A7C',
                  letterSpacing: '1%',
                }}
              >
                Please declare whether the current Guest Editor has any conflicts of interest or not:
              </Box>
              <FormControl
                id='hasConflict'
                data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-HasConflict'
              >
                <RadioGroup
                  data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-RadioGroup'
                  aria-labelledby='hasConflict'
                  defaultValue={hasConflict}
                  row
                >
                  <FormControlLabel
                    data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-FormControlLabel2'
                    value='true'
                    sx={{ height: '32px', alignContent: 'center' }}
                    control={
                      // <Radio

                      //   sx={{ width: '40px', height: '32px' }}
                      //   icon={
                      //     <DefaultRadioIcon data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-DefaultRadioIcon'></DefaultRadioIcon>
                      //   }
                      //   checkedIcon={
                      //     <CheckedRadioIcon data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-CheckedRadioIconn'></CheckedRadioIcon>
                      //   }
                      // />
                      <SimpleRadio
                        data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-FormControlLabel2-Radio'
                        disabled={true}
                      ></SimpleRadio>
                    }
                    label={
                      <Box
                        data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-FormControlLabel2-Box'
                        sx={{
                          marginLeft: '-2px',
                          fontFamily: 'Open Sans',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '14px',
                          lineHeight: '17px',
                          color: '#262E35',
                          letterSpacing: '-1.1%',
                          marginRight: '45px'
                        }}
                      >
                        Yes
                      </Box>
                    }
                  />
                  <FormControlLabel
                    data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-FormControlLabel1'
                    value='false'
                    sx={{ height: '32px', alignContent: 'center' }}
                    control={
                      // <Radio
                      //   data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-Radio'
                      //   disabled={true}
                      //   sx={{ width: '40px', height: '32px' }}
                      //   icon={<DefaultRadioIcon></DefaultRadioIcon>}
                      //   checkedIcon={<CheckedRadioIcon></CheckedRadioIcon>}
                      // />
                      <SimpleRadio
                        data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-Radio'
                        disabled={true}
                        // sx={{ width: '40px', height: '32px' }}
                      />
                    }
                    label={
                      <Box
                        data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-TextBox'
                        sx={{
                          marginLeft: '-2px',
                          fontFamily: 'Open Sans',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          fontSize: '14px',
                          lineHeight: '17px',
                          color: '#262E35',
                          letterSpacing: '-1.1%',
                        }}
                      >
                        No
                      </Box>
                    }
                  />
                </RadioGroup>
              </FormControl>
              {hasConflict && (
                <HideTextConflictDetail data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-HideTextConflictDetail'>
                  {conflictDetail}
                </HideTextConflictDetail>
              )}
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions
        sx={{ padding: '12px 0px 20px 8px' }}
        data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions'
      ></DialogActions>
    </SIModal>
    // <Dialog
    //   data-selenium-id='SIP_DetailPage_GE-HandleSave-Dialog'
    //   open={isOpen}
    //   onClose={handleGEDetailClose}
    //   scroll={'paper'}
    //   sx={{
    //     marginTop: '48px',
    //     '& .MuiDialog-paper': {
    //       width: '593px',
    //       margin: 0,
    //       padding: '32px 35px 0px 35px',
    //       boxSizing: 'border-box',
    //     },
    //   }}
    // >
    //   <GEDetailDialogTitle
    //     id='GEDetailDialogTitle'
    //     data-selenium-id='SIP_DetailPage_GE-Dialog-Title'
    //     onClose={handleGEDetailClose}
    //   >
    //     <Box
    //       data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Box'
    //       sx={{
    //         width: '97px',
    //         height: '28px',
    //         fontFamily: 'Open Sans',
    //         fontStyle: 'normal',
    //         fontWeight: 600,
    //         fontSize: '22px',
    //         lineHeight: '125%',
    //         color: '#262E35',
    //       }}
    //     >
    //       GE Detail
    //     </Box>
    //     <ErrBox></ErrBox>
    //   </GEDetailDialogTitle>

    //   <DialogContent
    //     sx={{ p: 0, pb: '16px' }}
    //     data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content'
    //   >
    //     {editOrShow ? (
    //       <Box data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box1'>
    //         <EditContent
    //           value={theGeInformation}
    //           setValue={setEditData}
    //           setEmailAddressFormatErr={setEditEmailFormatErr}
    //           setWebFormatErr={setWebFormatErr}
    //           isClick={isClick}
    //         ></EditContent>
    //       </Box>
    //     ) : (
    //       <Box data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2'>
    //         {theGeInformationNew ? (
    //           theGeInformationNew.map((item, index) => (
    //             <Stack
    //               data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-Stack'
    //               direction='row'
    //               spacing={2.5}
    //               key={item.name + index}
    //               pb={1}
    //             >
    //               <Box
    //                 data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-InnerBox1'
    //                 sx={{
    //                   width: '140px',
    //                   height: '32px',
    //                   fontFamily: 'Open Sans',
    //                   fontStyle: 'normal',
    //                   fontWeight: 600,
    //                   fontSize: '16px',
    //                   lineHeight: '24px',
    //                   color: '#828282',
    //                 }}
    //               >
    //                 {handleNameReplacement(item.name)}
    //               </Box>
    //               <Box
    //                 data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-InnerBox2'
    //                 sx={{
    //                   width: '259px',
    //                   height: '32px',
    //                   fontFamily: 'Open Sans',
    //                   fontStyle: 'normal',
    //                   fontWeight: 400,
    //                   fontSize: '14px',
    //                   lineHeight: '24px',
    //                   color: '#262E35',
    //                   alignSelf: 'stretch',
    //                 }}
    //               >
    //                 {item.name === 'cv' &&
    //                   handleFieldsReplacement(item.name, item.value)}
    //                 {item.name === 'profileWebsite' && (
    //                   <Tooltip
    //                     title={item.value}
    //                     data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-Stack-Tooltip'
    //                   >
    //                     <Box
    //                       id='profileWebsiteBox'
    //                       data-selenium-id='SIP_DetailPage_GE-Dialog-ProfileWebsiteBox'
    //                       sx={{
    //                         height: '24px',
    //                         overflow: 'hidden',
    //                         textOverflow: 'ellipsis',
    //                         display: '-webkit-box',
    //                         WebkitLineClamp: 1,
    //                         WebkitBoxOrient: 'vertical',
    //                       }}
    //                     >
    //                       {handleFieldsReplacement(item.name, item.value)}
    //                     </Box>
    //                   </Tooltip>
    //                 )}
    //                 {item.name !== 'cv' &&
    //                   item.name !== 'profileWebsite' &&
    //                   handleFieldsReplacement(item.name, item.value)}
    //               </Box>
    //             </Stack>
    //           ))
    //         ) : (
    //           <></>
    //         )}
    //         <Divider
    //           data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-Divider'
    //           sx={{ borderColor: '#F1F3F5', margin: '8px 0px 16px 0px' }}
    //         />
    //         <Stack
    //           direction='column'
    //           spacing={1}
    //           data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-ColumnStack'
    //         >
    //           <Box
    //             data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-ColumnBox'
    //             sx={{
    //               width: '242px',
    //               height: '24px',
    //               fontFamily: 'Open Sans',
    //               fontStyle: 'normal',
    //               fontWeight: 600,
    //               fontSize: '16px',
    //               lineHeight: '24px',
    //               color: '#828282',
    //             }}
    //           >
    //             Conflict of Interest Declaration:
    //           </Box>
    //           <FormControl
    //             id='hasConflict'
    //             data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-HasConflict'
    //           >
    //             <RadioGroup
    //               data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-RadioGroup'
    //               aria-labelledby='hasConflict'
    //               defaultValue={hasConflict}
    //             >
    //               <FormControlLabel
    //                 data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-FormControlLabel1'
    //                 value='false'
    //                 sx={{ height: '32px', alignContent: 'center' }}
    //                 control={
    //                   <Radio
    //                     data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-Radio'
    //                     disabled={true}
    //                     sx={{ width: '40px', height: '32px' }}
    //                     icon={<DefaultRadioIcon></DefaultRadioIcon>}
    //                     checkedIcon={<CheckedRadioIcon></CheckedRadioIcon>}
    //                   />
    //                 }
    //                 label={
    //                   <Box
    //                     data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-TextBox'
    //                     sx={{
    //                       marginLeft: '-2px',
    //                       fontFamily: 'Open Sans',
    //                       fontStyle: 'normal',
    //                       fontWeight: 400,
    //                       fontSize: '14px',
    //                       lineHeight: '120%',
    //                       color: '#262E35',
    //                     }}
    //                   >
    //                     I declare that none of the Guest Editors have a conflict
    //                     of interest.
    //                   </Box>
    //                 }
    //               />
    //               <FormControlLabel
    //                 data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-FormControlLabel2'
    //                 value='true'
    //                 sx={{ height: '32px', alignContent: 'center' }}
    //                 control={
    //                   <Radio
    //                     data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-FormControlLabel2-Radio'
    //                     disabled={true}
    //                     sx={{ width: '40px', height: '32px' }}
    //                     icon={
    //                       <DefaultRadioIcon data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-DefaultRadioIcon'></DefaultRadioIcon>
    //                     }
    //                     checkedIcon={
    //                       <CheckedRadioIcon data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-CheckedRadioIconn'></CheckedRadioIcon>
    //                     }
    //                   />
    //                 }
    //                 label={
    //                   <Box
    //                     data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-FormControlLabel2-Box'
    //                     sx={{
    //                       marginLeft: '-2px',
    //                       fontFamily: 'Open Sans',
    //                       fontStyle: 'normal',
    //                       fontWeight: 400,
    //                       fontSize: '14px',
    //                       lineHeight: '120%',
    //                       color: '#262E35',
    //                     }}
    //                   >
    //                     One (or more) editors have a conflict of interest.
    //                   </Box>
    //                 }
    //               />
    //             </RadioGroup>
    //           </FormControl>
    //           {hasConflict && (
    //             <HideTextConflictDetail data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Content-Box2-HideTextConflictDetail'>
    //               {conflictDetail}
    //             </HideTextConflictDetail>
    //           )}
    //         </Stack>
    //       </Box>
    //     )}
    //   </DialogContent>
    //   <DialogActions
    //     sx={{ padding: '12px 0px 20px 8px' }}
    //     data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions'
    //   >
    //     {editOrShow ? (
    //       <Box data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions-Box'>
    //         <CancelButton
    //           id='cancelGE'
    //           data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions-Box-CancelGE'
    //           onClick={() => {
    //             if (editData.cv && editData.cv !== theGeInformation.cv) {
    //               dispatch(DeleteCV(editData.cv));
    //             }
    //             setIsClick(false);
    //             handleGEDetailClose();
    //           }}
    //           variant='text'
    //         >
    //           Cancel
    //         </CancelButton>
    //         <EditBtnAvailable
    //           onClick={handleSave}
    //           data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions-Box-EditBtnAvailable'
    //         >
    //           Save
    //         </EditBtnAvailable>
    //       </Box>
    //     ) : Editable ? (
    //       <EditBtnAvailable
    //         data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions-EditBtnAvailable'
    //         onClick={() => {
    //           setEditOrShow(true);
    //         }}
    //       >
    //         Edit
    //       </EditBtnAvailable>
    //     ) : (
    //       <EditBtnDisabled
    //         disabled={true}
    //         data-selenium-id='SIP_DetailPage_GE-Dialog-HandleSave-Actions-EditBtnDisabled'
    //       >
    //         Edit
    //       </EditBtnDisabled>
    //     )}
    //   </DialogActions>
    // </Dialog>
  );
}

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}
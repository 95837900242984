import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className || '' }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#596A7C',
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderTop: '6px solid #596A7C',
    marginBottom:'-6px !important',
    '::before': {
      display: 'none',
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#596A7C',
    borderRadius: '5px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    width: '256px',
    boxSizing: 'border-box',
    padding: '8px 16px',
    marginBottom: '2px !important',
  },
}));

/** constants for diagram status */
export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const VALID = 'VALID';
export const NO_DATA = 'NO_DATA';
export const NO_SELECTION_FROM_DROPDOWN_MENU =
  'NO_SELECTION_FROM_DROPDOWN_MENU';
export const NO_DISPLAYED_DATA = 'NO_DISPLAYED_DATA';
export const NO_DISPLAYED_DATA_FOR_SELECTED_STAGE =
  'NO_DISPLAYED_DATA_FOR_SELECTED_STAGE';
export const NEED_IMPLEMENT_METHOD = 'need implement in child class';
import {axios_instance} from "@/utils/axios_instance";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {
    FY_ARTICLE_GET_BASIC_DATA,
    FY_ARTICLE_GET_BUSINESS_DATA,
    FY_ARTICLE_GET_SUBJECT_GROUP_DATA
} from "@/actions/SIMT-Report/ReportArticle/ReportArticleAction";
import {FormattedMessage} from "react-intl";
import getAuth from "@/utils/getAuth";

/**
 * 获得FY Basic Table数据
 * @param page  页码
 * @param pageSize  一页显示多少
 * @param sortMethod  asc或desc
 * @param sortKey 依据什么排序
 * @param fiscalYear  财年
 * @param useFilters  filter obj
 * @returns {(function(*): Promise<void>)|*}
 */
export function getFYBasicData(
    page,
    pageSize,
    sortMethod,
    sortKey,
    fiscalYear,
    useFilters = {}
) {
    return async dispatch => {
        try {
            let res = await axios_instance.post(
                '/reports/article/fy-trends/basic',
                useFilters,
                {
                    params: {
                        limit: pageSize,
                        pageNo: page,
                        sortMethod: sortMethod,
                        selectedFY: fiscalYear,
                        sortKey: sortKey,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: FY_ARTICLE_GET_BASIC_DATA, data: res.data.data});
            } else {
                dispatch({
                    type: FY_ARTICLE_GET_BASIC_DATA,
                    data: {result: [], count: 0},
                });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'common.failToLoad',
                        {
                            value: 'Basic Table',
                        },
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({
                type: FY_ARTICLE_GET_BASIC_DATA,
                data: {result: [], count: 0},
            });
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.errorundefinedOcc',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

/**
 * 获得subject group 数据
 * @param fiscalYear 财年
 * @param isOA
 */
export function getFYSubjectData(fiscalYear, isOA) {
    return async dispatch => {
        try {
            let res = await axios_instance.get(
                `/reports/article/fy-trends/sg/${fiscalYear}`,
                {
                    params: {
                        isOA: isOA,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({
                    type: FY_ARTICLE_GET_SUBJECT_GROUP_DATA,
                    data: res.data.data,
                });
            } else {
                dispatch({type: FY_ARTICLE_GET_BUSINESS_DATA, data: []});
                dispatch(
                    setSnackbarMessageAndOpen(
                        'common.failToLoad',
                        {value: <FormattedMessage id='report.subjectGroup'/>},
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({type: FY_ARTICLE_GET_SUBJECT_GROUP_DATA, data: []});
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToLoad',
                    {value: <FormattedMessage id='report.subjectGroup'/>},
                    SEVERITIES.error
                )
            );
        }
    };
}

export function getFYBusinessData(fiscalYear, isOA) {
    return async dispatch => {
        try {
            let res = await axios_instance.get(
                `/reports/article/fy-trends/bd/${fiscalYear}`,
                {
                    params: {
                        isOA: isOA,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({type: FY_ARTICLE_GET_BUSINESS_DATA, data: res.data.data});
            } else {
                dispatch({type: FY_ARTICLE_GET_BUSINESS_DATA, data: []});
                dispatch(
                    setSnackbarMessageAndOpen(
                        'common.failToLoad',
                        {value: <FormattedMessage id='report.businessDivision'/>},
                        SEVERITIES.error
                    )
                );
            }
        } catch (err) {
            dispatch({type: FY_ARTICLE_GET_BUSINESS_DATA, data: []});
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToLoad',
                    {value: <FormattedMessage id='report.businessDivision'/>},
                    SEVERITIES.error
                )
            );
        }
    };
}
import {axios_instance} from "@/utils/axios_instance";
import {CREATE_GE, GET_GE} from "@/actions/SIMT-SI/SpecialIssue/SIAction";

export function getGE(value) {
    return async dispatch => {
        try {
            const res = await axios_instance.get('/guestEditor/getGuestEditor', {
                params: {
                    key: value,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const {code,data} = res.data;
            if (code === 200) {
                dispatch({type: GET_GE, data: data});
            }
        } catch {
        }
    };
}

export function createGE(firstName, lastName, email) {
    return async dispatch => {
        try {
            const url = '/api/v1/si-service/guest-editors';
            const result = await axios_instance.post(
                url,
                {
                    firstName: firstName,
                    lastName: lastName,
                    primaryEmail: email,
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (result.data.code === 200) {
                dispatch({
                    type: CREATE_GE,
                    data: {
                        firstName: firstName,
                        lastName: lastName,
                        primaryEmail: email,
                    },
                });
            } else {
                if (result.data.code === 500) {
                    if (result.data.message === 'the email address has been registered')
                        //判断是否是邮箱存在错误
                        dispatch({type: CREATE_GE, data: 'EmailExit'});
                }
                dispatch({type: CREATE_GE, data: 'error'});
            }
        } catch (err) {
            dispatch({type: CREATE_GE, data: 'error'});
        }
    };
}
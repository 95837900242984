import React, { useEffect, useState } from "react";
import { styled, useThemeProps } from '@mui/material/styles';
import { useLocaleText, useUtils } from '@mui/x-date-pickers/internals/hooks/useUtils';
import { PickersFadeTransitionGroup } from '@mui/x-date-pickers/CalendarPicker/PickersFadeTransitionGroup';
import {
  Stack,
  Button
} from '@mui/material';

import { PickersArrowSwitcher} from '@mui/x-date-pickers/internals/components/PickersArrowSwitcher';
import { getPickersCalendarHeaderUtilityClass } from '@mui/x-date-pickers/CalendarPicker/pickersCalendarHeaderClasses';
import { unstable_composeClasses as composeClasses } from '@mui/utils';
import { useControlled, unstable_useId as useId, useEventCallback } from '@mui/material/utils';
import {
  DayPicker,
  defaultReduceAnimations,
  parseNonNullablePickerDate,
  useDefaultDates
} from '@mui/x-date-pickers/internals';
import { useViews } from '@mui/x-date-pickers/internals/hooks/useViews';
import { useCalendarState } from '@mui/x-date-pickers/CalendarPicker/useCalendarState';
import { findClosestEnabledDate } from '@mui/x-date-pickers/internals/utils/date-utils';
import { MonthPicker, YearPicker } from '@mui/x-date-pickers';

import * as DateFnsUtils from 'date-fns';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

const PickersCalendarHeaderLabel = styled(Button)({
  variant: 'text',
  name: 'MuiPickersCalendarHeader',
  slot: 'Label',
});

const useUtilityClasses = (ownerState) => {
  const { classes } = ownerState;
  const slots = {
    root: ['root'],
    labelContainer: ['labelContainer'],
    label: ['label'],
    switchViewButton: ['switchViewButton'],
    switchViewIcon: ['switchViewIcon'],
  };
  return composeClasses(slots, getPickersCalendarHeaderUtilityClass, classes);
};

const PickersCalendarHeader = (inProps) => {
  const props = useThemeProps({ props: inProps, name: 'MuiPickersCalendarHeader' });
  const {
    components = {},
    componentsProps = {},
    currentMonth: month,
    leftArrowButtonText: leftArrowButtonTextProp,
    onMonthChange,
    onYearChange,
    rightArrowButtonText: rightArrowButtonTextProp,
    handleMonthListOpen,
    handleYearListOpen,
    openView,
  } = props;

  const ownerState = props;
  const classes = useUtilityClasses(props);

  const localeText = useLocaleText();

  const leftArrowButtonText = leftArrowButtonTextProp==null ? localeText.previousMonth: leftArrowButtonTextProp;
  const rightArrowButtonText = rightArrowButtonTextProp==null ? localeText.nextMonth: rightArrowButtonTextProp;

  const utils = useUtils();
  const selectNextMonth = () => {
    if(openView=='month'){
      onYearChange(utils.addYears(month,1));
    } else {
      onYearChange(utils.addYears(month,12));
    }
  };
  const selectPreviousMonth = () => {
    if(openView=='month'){
      onYearChange(utils.addYears(month,-1));
    } else {
      onYearChange(utils.addYears(month,-12));
    }
  };

  return (
    <PickersArrowSwitcher
    id='PickersArrowSwitcher'
      leftArrowButtonText={leftArrowButtonText}
      rightArrowButtonText={rightArrowButtonText}
      components={components}
      componentsProps={componentsProps}
      onLeftClick={selectPreviousMonth}
      onRightClick={selectNextMonth}
      isLeftDisabled={false}
      isRightDisabled={false}
      sx={{
        justifyContent: 'space-between',
        width: '100%',
        gap: '25px',
        '& span button':{
          textTransform: 'capitalize',
          fontSize: '14px',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '19px',
          leadingTrim: 'both',
          textEdge: 'cap',
          alignItems: 'center',
          textAlian: 'center',
          color: '#596A7C',
          ':hover':{
            background: '#F1F3F5',
            borderRadius: '4px',
          }
        }
      }}
    >
      <>
        {/*{openView==='day'?(<PickersCalendarHeaderLabel*/}
          {/*data-mui-test="calendar-month-and-year-text"*/}
          {/*ownerState={ownerState}*/}
          {/*className={classes.label}*/}
          {/*onClick={handleMonthListOpen}*/}
        {/*>*/}
          {/*{utils.format(month, 'month').substring(0,3)}*/}
        {/*</PickersCalendarHeaderLabel>):null}*/}
        <PickersCalendarHeaderLabel
        id='PickersCalendarHeaderLabel'
          data-mui-test="calendar-month-and-year-text"
          ownerState={ownerState}
          className={classes.label}
          onClick={handleYearListOpen}
        >
          {utils.format(month, 'year')}
        </PickersCalendarHeaderLabel>
      </>
    </PickersArrowSwitcher>);
};


const CalendarPickerViewTransitionContainer = styled(PickersFadeTransitionGroup, {
  name: 'MuiCalendarPicker',
  slot: 'ViewTransitionContainer',
  overridesResolver: (props, styles) => styles.viewTransitionContainer,
})({});

function useCalendarPickerDefaultizedProps(props, name,) {
  const utils = useUtils();
  const defaultDates = useDefaultDates();
  const themeProps = useThemeProps({
    props,
    name,
  });

  return {
    loading: false,
    disablePast: false,
    disableFuture: false,
    openTo: 'month',
    views: ['year', 'month'],
    reduceAnimations: defaultReduceAnimations,
    renderLoading: () => <span data-mui-test="loading-progress">...</span>,
    ...themeProps,
    minDate: parseNonNullablePickerDate(utils, themeProps.minDate, defaultDates.minDate),
    maxDate: parseNonNullablePickerDate(utils, themeProps.maxDate, defaultDates.maxDate),
  };
}

export const MonthCalendar = (inProps) => {
  const utils = useUtils();
  const id = useId();
  const props = useCalendarPickerDefaultizedProps(inProps, 'MuiCalendarPicker');

  const {
    autoFocus,
    onViewChange,
    disableFuture,
    disablePast,
    defaultCalendarMonth,
    onChange,
    onYearChange,
    onMonthChange,
    reduceAnimations,
    shouldDisableDate,
    shouldDisableMonth,
    shouldDisableYear,
    view,
    views,
    openTo,
    className,
    disabled,
    readOnly,
    minDate,
    maxDate,
    disableHighlightToday,
    focusedView,
    onFocusedViewChange,
    // excluding classes from `other` to avoid passing them down to children
    classes: providedClasses,
    ...other
  } = props;

  const inDate = props.date;

  const [date, setDate] = useState(inDate);
  const [month, setMonth] = useState(inDate || new Date());

  useEffect(() => {
    setDate(props.date);
  }, [props.date]);

  const { openView, setOpenView, openNext } = useViews({
    view,
    views,
    openTo,
    onChange,
    onViewChange,
  });

  const {
    calendarState,
    changeFocusedDay,
    changeMonth,
    handleChangeMonth,
    isDateDisabled,
    onMonthSwitchingAnimationEnd,
  } = useCalendarState({
    date,
    defaultCalendarMonth,
    reduceAnimations,
    onMonthChange,
    minDate,
    maxDate,
    shouldDisableDate,
    disablePast,
    disableFuture,
  });

  const handleDateMonthChange = React.useCallback(
    (newDate, selectionState) => {
      const startOfMonth = utils.startOfMonth(newDate);
      const endOfMonth = utils.endOfMonth(newDate);

      const closestEnabledDate = isDateDisabled(newDate)
        ? findClosestEnabledDate({
          utils,
          date: newDate,
          minDate: utils.isBefore(minDate, startOfMonth) ? startOfMonth : minDate,
          maxDate: utils.isAfter(maxDate, endOfMonth) ? endOfMonth : maxDate,
          disablePast,
          disableFuture,
          isDateDisabled,
        })
        : newDate;

      if (closestEnabledDate) {
        setDate(closestEnabledDate);
        setMonth(closestEnabledDate);
        onChange(closestEnabledDate, selectionState);
        if (onMonthChange!=null){
          onMonthChange(startOfMonth);
        }
      } else {
        openNext();
        changeMonth(startOfMonth);
      }

      changeFocusedDay(closestEnabledDate, true);
      // setOpenView('day');
    },
    [
      changeFocusedDay,
      disableFuture,
      disablePast,
      isDateDisabled,
      maxDate,
      minDate,
      onChange,
      onMonthChange,
      changeMonth,
      openNext,
      utils,
    ],
  );

  const handleChangeMonthX = (newDate, openDayView) =>{
    const startOfMonth = utils.startOfMonth(newDate);
    const endOfMonth = utils.endOfMonth(newDate);

    const closestEnabledDate = isDateDisabled(newDate)
      ? findClosestEnabledDate({
        utils,
        date: newDate,
        minDate: utils.isBefore(minDate, startOfMonth) ? startOfMonth : minDate,
        maxDate: utils.isAfter(maxDate, endOfMonth) ? endOfMonth : maxDate,
        disablePast,
        disableFuture,
        isDateDisabled,
      })
      : newDate;

    if (closestEnabledDate!=null){
      setMonth(closestEnabledDate);
      setDate(closestEnabledDate);
      changeMonth(closestEnabledDate);
      // if (openDayView){
      //   setOpenView('day');
      // }
      changeFocusedDay(closestEnabledDate, true);
      onChange(closestEnabledDate, true);
    }
  };

  const handleDateYearChange = React.useCallback(
    (newDate, selectionState) => {
      const startOfYear = utils.startOfYear(newDate);
      const endOfYear = utils.endOfYear(newDate);

      const closestEnabledDate = isDateDisabled(newDate)
        ? findClosestEnabledDate({
          utils,
          date: newDate,
          minDate: utils.isBefore(minDate, startOfYear) ? startOfYear : minDate,
          maxDate: utils.isAfter(maxDate, endOfYear) ? endOfYear : maxDate,
          disablePast,
          disableFuture,
          isDateDisabled,
        })
        : newDate;

      if (closestEnabledDate) {
        setDate(closestEnabledDate);
        setMonth(closestEnabledDate);
        onChange(closestEnabledDate, selectionState);
        if (onYearChange) {
          onYearChange(closestEnabledDate);
        }
      } else {
        openNext();
        changeMonth(startOfYear);
      }

      changeFocusedDay(closestEnabledDate, true);
      setOpenView('month');
    },
    [
      changeFocusedDay,
      disableFuture,
      disablePast,
      isDateDisabled,
      maxDate,
      minDate,
      onChange,
      onYearChange,
      openNext,
      utils,
      changeMonth,
    ],
  );

  const handleYearChange = (newDate, openMonthView) => {
    openMonthView = openMonthView || false;
    const startOfYear = utils.startOfYear(newDate);
    const endOfYear = utils.endOfYear(newDate);

    const closestEnabledDate = isDateDisabled(newDate)
      ? findClosestEnabledDate({
        utils,
        date: newDate,
        minDate: utils.isBefore(minDate, startOfYear) ? startOfYear : minDate,
        maxDate: utils.isAfter(maxDate, endOfYear) ? endOfYear : maxDate,
        disablePast,
        disableFuture,
        isDateDisabled,
      })
      : newDate;

    if (closestEnabledDate!=null){
      changeFocusedDay(closestEnabledDate, true);
      setMonth(closestEnabledDate);
      if (openMonthView){
        setOpenView('month');
      } else {
        handleYearListChange(closestEnabledDate);
      }
    }
  };

  React.useEffect(() => {
    if (date) {
      changeMonth(date);
    }
  }, [date]); // eslint-disable-line

  const ownerState = props;
  const classes = useUtilityClasses(ownerState);

  const baseDateValidationProps = {
    disablePast,
    disableFuture,
    maxDate,
    minDate,
  };

  // When disabled, limit the view to the selected date
  const minDateWithDisabled = (disabled && date) || minDate;
  const maxDateWithDisabled = (disabled && date) || maxDate;

  const currentYear = utils.getYear(month);
  const p = currentYear % 12;
  const startYearNumber = currentYear - p;

  const [startYearDate, setStartYearDate] = useState(new Date(startYearNumber, 0, 1));
  const [endYearDate, setEndYearDate] = useState(new Date(startYearNumber+11, 0, 1));

  const handleYearListChange = (date) =>{
    const year = utils.getYear(date || new Date());
    const p = year % 12;
    const sYearNumber = year - p;
    setStartYearDate(new Date(sYearNumber, 0, 1));
    setEndYearDate(new Date(sYearNumber+11, 0, 1));
    setMonth(date);
  };

  const commonViewProps = {
    disableHighlightToday,
    readOnly,
    disabled,
  };

  const gridLabelId = `${id}-grid-label`;

  const [internalFocusedView, setInternalFocusedView] = useControlled({
    name: 'DayPicker',
    state: 'focusedView',
    controlled: focusedView,
    default: autoFocus ? openView : null,
  });

  const hasFocus = internalFocusedView !== null;

  const handleFocusedViewChange = useEventCallback(
    (eventView) => (newHasFocus) => {
      if (onFocusedViewChange) {
        // Use the calendar or clock logic
        onFocusedViewChange(eventView)(newHasFocus);
        return;
      }
      // If alone, do the local modifications
      if (newHasFocus) {
        setInternalFocusedView(eventView);
      } else {
        setInternalFocusedView((prevView) => (prevView === eventView ? null : prevView));
      }
    },
  );

  const prevOpenViewRef = React.useRef(openView);
  React.useEffect(() => {
    // Set focus to the button when switching from a view to another
    if (prevOpenViewRef.current === openView) {
      return;
    }
    prevOpenViewRef.current = openView;
    handleFocusedViewChange(openView)(true);
  }, [openView, handleFocusedViewChange]);

  const handleMonthListOpen = ()=>{
    setOpenView('month');
  };

  const handleYearListOpen = ()=>{
    setOpenView('year');
  };

    const checkYearDisable = (year)=>{
      const startOfYear = utils.startOfYear(year);
      const endOfYear = utils.endOfYear(year);
      if (minDate && utils.isBefore(endOfYear,minDate)){
        return true;
      }
      if (maxDate && utils.isAfter(startOfYear,maxDate)){
        return true;
      }
      return false;
    };

  return (<Stack 
    id='pickerStack'
    className="MuiDateCalendar-root" 
    direction="column" 
    sx={{
    height: '274px',
    width: '252px',
    borderRadius: '0px',
    '& .MuiMonthPicker-root,.MuiYearPicker-root':{
      width: '252px',
      '& Button':{
        borderRadius: '4px',
        '&:hover':{
          backgroundColor: '#AEC6F6',
        },
        '.Mui-selected':{
          backgroundColor: '#154AB6',
        }
      }
    },
    '& .MuiDateCalendar-root': {
      width: '252px',
    },
    '& .MuiDayPicker-header':{
      '& .MuiTypography-root':{
        width: '36px',
        margin: 0,
      },
    },
    '& .MuiDayPicker-weekContainer': {
      width: '252px',
      margin: 0,
      '& .MuiButtonBase-root,.MuiPickersDay-root': {
        width: '36px',
        height: '36px',
        margin: 0,
      }
    },
  }}>
    <PickersCalendarHeader
      {...other}
      id='PickersCalendarHeader'
      views={views}
      openView={openView}
      currentMonth={month}
      onViewChange={setOpenView}
      onMonthChange={handleChangeMonthX}
      onYearChange={handleYearChange}
      minDate={minDateWithDisabled}
      maxDate={maxDateWithDisabled}
      disabled={disabled}
      disablePast={disablePast}
      disableFuture={disableFuture}
      reduceAnimations={reduceAnimations}
      labelId={gridLabelId}
      handleMonthListOpen={handleMonthListOpen}
      handleYearListOpen={handleYearListOpen}
    />
    <CalendarPickerViewTransitionContainer
    id='CalendarPickerViewTransitionContainer'
      reduceAnimations={reduceAnimations}
      className={classes.viewTransitionContainer}
      transKey={openView}
      ownerState={ownerState}
    >
      <div>
        {openView === 'year' && (
          <YearPicker
            {...other}
            //{...baseDateValidationProps}
            {...commonViewProps}
            id='YearPicker'
            maxDate={endYearDate}
            minDate={startYearDate}
            autoFocus={autoFocus}
            date={date}
            onChange={handleYearChange}
            // shouldDisableYear={shouldDisableYear}
            hasFocus={hasFocus}
            onFocusedViewChange={handleFocusedViewChange('year')}
            shouldDisableYear={checkYearDisable}
          />
        )}

        {openView === 'month' && (
          <MonthPicker
            {...baseDateValidationProps}
            {...commonViewProps}
            id='MonthPicker'
            autoFocus={autoFocus}
            hasFocus={hasFocus}
            className={className}
            date={month}
            minDate={minDateWithDisabled}
            maxDate={maxDateWithDisabled}
            onChange={handleChangeMonthX}
            shouldDisableMonth={shouldDisableMonth}
            onFocusedViewChange={handleFocusedViewChange('month')}
          />
        )}
      </div>
    </CalendarPickerViewTransitionContainer>
  </Stack>);
};

import { StrictMode } from 'react';
import FlaggedGESynchronizationAndVerificationTable, { radioGropListArray, radioGropList, FlaggedGESynchronizationAndVerificationTable as GETableString, email as emailString, geCode as geCodeString, verification } from '@/componentsphase2/FlaggedGESynchronizationAndVerificationTable';
import { useDispatch, useSelector } from 'react-redux';

import {getGeByFullname} from "@/actions/SIMT-SI/GuestEditor";

const geList = 'geList';


export default function GEproposerAndVerification ({ value = { fullname: '', email: null, geCode: '', index: 0 }, editing = false, FlaggedGETable, ...others }) {
  const { fullname, email, index, geCode } = value;

  const nowData = FlaggedGETable;

  const nowDataLength = nowData?.length;
  const isEditing = editing;
  return <StrictMode>
    <FlaggedGESynchronizationAndVerificationTable
      geDataResult={nowData?.map((item) => ({
        ...item,
        // [verification]: ((geCode === item?.[geCodeString]?.toString()) || (nowDataLength === 1)) ? radioGropList.Matched : ((!isEditing || geCode) ? radioGropList.Mismatched : undefined)
        [verification]: ((geCode === item?.[geCodeString]?.toString()) || (nowDataLength === 1)) ? radioGropList.Matched : (geCode ? radioGropList.Mismatched : undefined)
      }))}
    //   fetchData={()=>{}}
      editing={isEditing}
      {...others}
    />
  </StrictMode>;
}
import {FlaggedGESynchronizationAndVerificationTable} from '@/componentsphase2/FlaggedGESynchronizationAndVerificationTable';
import * as actions from '../actions/SIMT-SI/GuestEditor/GEAction';

const initState = {
  // Overview 页面数据
  geData: {
    result: [],
    count: 0,
  },
  geCodes: [],
  AssociatedSIList: {
    result: [],
    count: 0,
  },
  geCodeList: [],
  countryList: [],
  departmentList: [],
  instituteList: [],
  flagsList: [],
  upfileBack: '',
  GEBasicInformation: {},
  FlagData: [],
  selectedGE: new Set(),
  // Overview 表格属性
  page: 1,
  pageSize: 50,
  orderByType: {
    // 排序相关
    order: 'desc',
    orderBy: 'geCode', // 由于后端未返回geCode，所以用别的暂时替代
  },
  // 搜索的关键字
  searchGEContent: '',
  // filter的选择/使用等等
  filterOptions: {
    fullName: [],
    gender: [],
    primaryEmail: [],
    institute: [],
    country: [],
    associateJournal: [],
    pdPublisher: [],
    ppPublisher: [],
    subjectGroup: [],
    handlingCe: [],
    group: [],
    flags: [],
    geCode: [],
  },
  filterSelected: {
    fullName: [],
    gender: [],
    primaryEmail: [],
    institute: [],
    country: [],
    associateJournal: [],
    ppPublisher:[],
    pdPublisher:[],
    subjectGroup: [],
    handlingCe: [],
    group: [],
    flags: [],
    geCode: [],
    createDateFrom: null,
    createDateTo: null,
  },
  useFilter: {
    fullName: [],
    gender: [],
    primaryEmail: [],
    institute: [],
    country: [],
    associateJournal: [],
    ppPublisher: [],
    pdPublisher: [],
    subjectGroup: [],
    handlingCe: [],
    group: [],
    flags: [],
  },
  // 其他
  filterOpen: false, // filter开启or关闭
  reloadAllSelectionTrigger: 0,
  keywords: [],
  newKeyword: '',
  newKeywordExits: false,
  /** @type {import("@/pages/GEPage/Overview/CreateGE/CreateGEContainer").CreateNewItemStatus} */
  isNewKeywordGet: 'idle',
  newFlags: '',
  newFlagsExits: false,
  isNewFlagsGet: 'idle',
  newInstitute: '',
  newInstituteExits: false,
  /** @type {import("@/pages/GEPage/Overview/CreateGE/CreateGEContainer").CreateNewItemStatus} */
  isNewInstituteGet: 'idle',
  newDepartment: '',
  newDepartmentExits: false,
  /** @type {import("@/pages/GEPage/Overview/CreateGE/CreateGEContainer").CreateNewItemStatus} */
  isNewDepartmentGet: 'idle',
  isEmailMessage: '',
  isEmailMessageGet: false,
  geTitleConfigDropdownList: null,
  geGenderConfigDropdownList: null,
  // if to show the viewMore snackBar(in create ge, when primary email is duplicate)
  showViewMore: false,
  GEMergeState: 'init',
  GEMergeData: {
    One: {
      BasicInformation: {},
      Flag: [],
    },
    Two: {
      BasicInformation: {},
      Flag: [],
    },
  },
  [FlaggedGESynchronizationAndVerificationTable]:[],
  GEBasicInformationHistory: {
    count: 0,
    result: null,
  },
  FlagHistory: {
    count: 0,
    result: null,
  },
};

export default function GEReducer(state = initState, action) {
  const { type, data } = action;
  switch (type) {
    case actions.GET_GE_CODE: {
      return {
        ...state,
        geCodeList: data,
      };
    }
    case actions.GET_GE_TITLE_CONFIG: {
      return {
        ...state,
        geTitleConfigDropdownList: data,
      };
    }
    case actions.GET_GE_Gender_CONFIG: {
      return {
        ...state,
        geGenderConfigDropdownList: data,
      };
    }
    case actions.LOAD_GE: {
      return {
        ...state,
        geData: data,
      };
    }
    case actions.LOAD_GE_CODES:
      return {
        ...state,
        geCodes: data,
      };
    case actions.GET_FLAGLIST: {
      return {
        ...state,
        flagsList: data,
      };
    }
    case actions.LOAD_ASSOCIATEDSI: {
      return {
        ...state,
        AssociatedSIList: data,
      };
    }
    case actions.GET_COUNTRYLIST: {
      return {
        ...state,
        countryList: data,
      };
    }
    case actions.GET_DEPARTMENTLIST: {
      return {
        ...state,
        departmentList: data,
      };
    }
    case actions.GET_INSTITUTELIST: {
      return {
        ...state,
        instituteList: data,
      };
    }
    case actions.GET_BASICINFORMATION: {
      return {
        ...state,
        GEBasicInformation: data,
      };
    }
    case actions.GE_ORDER_BY:
      return {
        ...state,
        orderByType: data,
      };
    case actions.SET_GE_FILTER_OPEN: {
      return {
        ...state,
        filterOpen: data,
      };
    }
    case actions.SET_GE_SEARCH:
      return {
        ...state,
        searchGEContent: data,
      };
    case actions.CHANGE_GE_PAGE:
      return {
        ...state,
        page: data,
      };
    case actions.CHANGE_GE_PAGE_SIZE:
      return {
        ...state,
        pageSize: data,
        page: 1,
      };
    case actions.SELECT_BATCH_GE: {
      const tempSet = state.selectedGE;
      for (const geElement of data) {
        tempSet.add(geElement);
      }
      return {
        ...state,
        // selectedGE: new Set([...state.selectedGE, ...data])
        selectedGE: state.selectedGE, // Set集合的地址不变，好像不会触发表头全选框的重新渲染
        reloadAllSelectionTrigger: Math.random(), // 用一个随机数去触发GE Header选择框的刷新
      };
    }
    case actions.UNSELECT_BATCH_GE: {
      const tempSet = state.selectedGE;
      for (const geElement of data) {
        tempSet.delete(geElement);
      }
      return {
        ...state,
        selectedGE: tempSet,
        reloadAllSelectionTrigger: Math.random(), // 类似select
      };
    }
    case actions.GET_KEYWORDS: {
      return {
        ...state,
        keywords: data,
      };
    }
    case actions.GET_ALL_KEYWORDS: {
      return {
        ...state,
        allKeywords: data,
      };
    }
    case actions.CHECK_EMAIL: {
      return {
        ...state,
        isEmailMessage: data,
        isEmailMessageGet: !state.isEmailMessageGet,
      };
    }
    case actions.CREATE_KEYWORD: {
      return {
        ...state,
        newKeyword: data.value,
        isNewKeywordGet: data.status,
      };
    }
    case actions.CREATE_INSTITUTE: {
      return {
        ...state,
        newInstitute: data.value,
        isNewInstituteGet: data.status,
      };
    }
    case actions.CREATE_DEPARTMENT: {
      return {
        ...state,
        newDepartment: data.value,
        isNewDepartmentGet: data.status,
      };
    }
    case actions.CREATE_KEYWORD_EXITS: {
      return {
        ...state,
        newKeywordExits: data,
      };
    }
    case actions.CREATE_DEPARTMENT_EXITS: {
      return {
        ...state,
        newDepartmentExits: data,
      };
    }
    case actions.CREATE_INSTITUTE_EXITS: {
      return {
        ...state,
        newInstituteExits: data,
      };
    }
    case actions.LOAD_GE_FILTER_OPTIONS: {
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          [data.which]: data.data.result,
        },
      };
    }
    case actions.RESET_GE_FILTER: {
      return {
        ...state,
        filterSelected: {
          ...initState.filterSelected,
        },
      };
    }
    case actions.SET_GE_FILTERS: {
      return {
        ...state,
        useFilter: data,
      };
    }
    case actions.CLEAR_GE_USE_FILTER:
      return {
        ...state,
        useFilter: initState.useFilter,
      };
    case actions.SET_GE_FILTER_SELECTED:
      return {
        ...state,
        filterSelected: {
          ...state.filterSelected,
          [data.which]: data.data,
        },
      };
    case actions.SHOW_VIEW_MORE:
      return {
        ...state,
        showViewMore: data,
      };
    case actions.GET_FLAG: {
      return {
        ...state,
        FlagData: data,
      };
    }
    case actions.UP_EVIDENTFILE: {
      return {
        ...state,
        upfileBack: data,
      };
    }
    case actions.MERGE_START: {
      return {
        ...state,
        GEMergeState: 'start',
      };
    }

    case actions.MERGE_FINISH: {
      return {
        ...state,
        GEMergeState: data ? 'finished-success' : 'finished-failed',
      };
    }

    case actions.MERGE_BASIC_INFORMATION_ONE: {
      return {
        ...state,
        GEMergeData: {
          ...state.GEMergeData,
          One: {
            BasicInformation: data,
            Flag: state.GEMergeData.One.Flag,
          },
        },
      };
    }
    case actions.MERGE_BASIC_INFORMATION_TWO: {
      return {
        ...state,
        GEMergeData: {
          ...state.GEMergeData,
          Two: {
            BasicInformation: data,
            Flag: state.GEMergeData.Two.Flag,
          },
        },
      };
    }
    case actions.GET_MERGE_FLAG_ONE: {
      return {
        ...state,
        GEMergeData: {
          ...state.GEMergeData,
          One: {
            ...state.GEMergeData.One,
            Flag: data,
          },
        },
      };
    }
    case actions.MERGE_RESET: {
      return {
        ...state,
        GEMergeState: 'init',
        GEMergeData: {
          One: {
            BasicInformation: {},
            Flag: {},
          },
          Two: {
            BasicInformation: {},
            Flag: {},
          },
        },
      };
    }
    case actions.GET_MERGE_FLAG_TWO: {
      return {
        ...state,
        GEMergeData: {
          ...state.GEMergeData,
          Two: {
            ...state.GEMergeData.Two,
            Flag: data,
          },
        },
      };
    }
    case actions.GET_GEBASICINFORMATION_HISTORY:
      return {
        ...state,
        GEBasicInformationHistory: data,
      };
    case actions.GET_FLAG_HISTORY:
      return {
        ...state,
        FlagHistory: data,
    };
    case actions.SET_FLAGGEDGESYNCHRONIZATIONANDVERIFICATIONTABLE:{
      const/**@type {Array<import('@/componentsphase2/FlaggedGESynchronizationAndVerificationTable').GeDataType>}*/newArr=state?.[FlaggedGESynchronizationAndVerificationTable]?.concat();
      newArr[data?.index??0]=data?.value;
      return {
        ...state,
        [FlaggedGESynchronizationAndVerificationTable]:newArr
      };
    }
    default:
      return state;
  }
}

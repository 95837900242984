import styles from './index.module.scss';
import { useState } from 'react';
import Label from '../Label';
import AutocompleteArrowIcon from '@/componentsphase2/Autocomplete/SVGComponents/AutocompleteArrowIcon.svg';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import DatePicker from './DatePicker';
import MultiSelect from './MultiSelect';
import Email from './Email';
import Select from './Select';
import Assignee from '../../DecisionSection/Assignee';
import SingleSelect from "@/pages/SIPPage/SIPDetailPage/DecisionPanel/FormItem/SingleSelect";

function FormItem({
  id,
  componentKey,
  componentType,
  properties,
  onCommand,
  disabled,
  value,
  update,
  form,
  getPreSubmitForm = () => form,
}) {
  return componentType === 'button' ? (
    <Button
      className={styles.button}
      data-selenium-id='SIP_DetailPage_DecisionPanel-Button'
      variant={
        { outlined: 'outlined', contained: 'contained' }[properties.style] ||
        'contained'
      }
      onClick={() =>
        onCommand(properties.command, properties[properties.command])
      }
      disabled={
        ['next', 'alert', 'submit'].includes(properties.command) && disabled
      }
    >
      {properties.title}
    </Button>
  ) : componentType === 'email' ? (
    <Email
      properties={properties}
      form={form}
      getPreSubmitForm={getPreSubmitForm}
      update={update}
      onCommand={onCommand}
    />
  ) : (
    <Label name={properties.title} required={properties.required}>
      {componentType === 'select' && (
        <Select
          value={value}
          update={update}
          properties={properties}
          onCommand={onCommand}
        />
      )}
      {componentType === 'singleSelect' && (
          <SingleSelect
              value={value}
              update={update}
              properties={properties}
              onCommand={onCommand}
          />
      )}
      {componentType === 'textarea' && (
        <>
          <MyOnlyText
            data-selenium-id='SIP_DetailPage_DecisionSection-Textarea'
            multiline={true}
            width='420px'
            height='101px'
            value={value}
            setValue={val => {
              update(val);
            }}
            // isError={comments.length > 100000 && !focusComments}
            // errorMessage={'Please input no more than 100,000 characters.'}
            onFocus={() => {
              // setFoucusComments(true);
            }}
            onBlur={() => {
              // setFoucusComments(false);
            }}
            placeholder={properties.placeholder}
            TextFieldInputProps={{
              maxLength: properties.max || 100000,
            }}
            isError={value?.length > (properties.max || 100000)}
            errorMessage={`Please input no more than ${(properties.max || 100000).toLocaleString()} characters.`}
            minRows={5}
            maxRows={5}
          />

          <p className={styles.textareaCounter}>
            {formatNumber(value?.length || 0)}/
            {formatNumber(properties.max || 100000)}
          </p>
        </>
      )}
      {componentType === 'date' && <DatePicker value={value} update={update} />}
      {componentType === 'multiSelect' && (
        <MultiSelect
          value={value}
          update={update}
          properties={properties}
          onCommand={onCommand}
          onOpen={() => onCommand('higher')}
          onClose={() => onCommand('lower')}
        />
      )}
      {componentType === 'assignee' && (
        <div className={styles.assignee}>
          <Assignee assignees={value} setAssignees={update} label='' />
        </div>
      )}
    </Label>
  );
}

export default FormItem;

function formatNumber(num) {
  let numStr = num.toString();
  const regex = /\B(?=(\d{3})+(?!\d))/g;
  return numStr.replace(regex, ',');
}

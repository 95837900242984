import { BarChartOptions } from '@/pages/Report/chartUtils';
/**
 * overwrite the default configuration
 */
const BARCHART_TOP = 42;
const BARCHART_HEIGHT = 390;
const MAX_NUMBER_OF_GROUPS_OF_BARS = 16;
const MIN_LABLE_SIZES = [15, 10, 7, 5];
const MAX_LABEL_SIZES = [18, 12];

export class AAndAOverviewYTDMonthOption extends BarChartOptions {
  constructor(catagoryAttributeName, valueAttributeNames, formatMonth) {
    super();
    // overwrite the default properties
    this.barchartTop = BARCHART_TOP;
    this.barchartHeight = BARCHART_HEIGHT;
    this.catagoryAttributeName = catagoryAttributeName;
    this.valueAttributeNamesYTD = valueAttributeNames[0];
    this.valueAttributeNamesMonth = valueAttributeNames[1];
    this.formatMonth = formatMonth;
  }

  // overwrite the default methods
  getBarSeries() {
    return [
      {
        ...this.barSerieOption,
        barMaxWidth: 150,
        barMinWidth: 8,
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'inside',
              color: '#FFFFFF',
              fontSize: this.getLabelMinFontSize(),
              verticalAlign: 'left',
              lineHeight: 2,
              formatter: params => {
                let position;
                if (params.seriesIndex == 0)
                  position = this.valueAttributeNamesYTD;
                else position = this.valueAttributeNamesMonth;

                if (params.data[position] == 0) {
                  return '';
                }
                return params.data[position];
              },
            },

            borderColor: '#0094F0',
            borderWidth: 1,
          },
        },
        name: 'Acquired SI-YTD',
        encode: {
          x: this.valueAttributeNamesYTD,
          y: this.catagoryAttributeName,
        },
        color: '#0094F0',
      },
      {
        ...this.barSerieOption,
        barMaxWidth: 150,
        barMinWidth: 8,
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'inside',
              color: '#596A7C',
              // fontSize: 7,
              fontSize: this.getLabelMinFontSize(),
              verticalAlign: 'left',
              lineHeight: 2,
              formatter: params => {
                let position;
                if (params.seriesIndex == 0)
                  position = this.valueAttributeNamesYTD;
                else position = this.valueAttributeNamesMonth;

                if (params.data[position] == 0) {
                  return '';
                }
                return params.data[position];
              },
            },
            borderColor: '#92D4FF',
            borderWidth: 1,
          },
        },
        name: 'Acquired SI-' + this.formatMonth,
        encode: {
          x: this.valueAttributeNamesMonth,
          y: this.catagoryAttributeName,
        },
        color: '#92D4FF',
      },
    ];
  }

  getBarchartHeight() {
    return this.barchartHeight;
  }

  getTooltip() {
    return [
      {
        ...this.tooltip,
        formatter: params => {
          // console.log('overwrite tooltip');
          // console.log(params);
          let position;
          if (params.seriesIndex == 0) position = this.valueAttributeNamesYTD;
          else position = this.valueAttributeNamesMonth;
          let dataStr = `<div style="padding: 0; margin: 0;">
            <p style="font-size: 12px; font-weight: 700; color: #243C9C; line-height: 16px; margin: 0 0 2.5px 0;">${params.name}</p>
            <p style="font-size: 10px; font-weight: 400;color: #848484; line-height: 14px; margin: 0;">${params.seriesName}&nbsp;:&nbsp;${params.data[position]}</p>
          </div>`;
          return dataStr;
        },
      },
    ];
  }

  getXAxis() {
    let xAxis = super.getXAxis();
    xAxis.axisLabel.formatter = value => {
      return value;
    };
    return { ...xAxis };
  }

  // override parenet method
  getDataZoomInside() {
    const dataZoomInside = super.getDataZoomInside();
    return {
      ...dataZoomInside,
      start: undefined,
      end: undefined,
      startValue: 0,
      endValue: MAX_NUMBER_OF_GROUPS_OF_BARS - 1,
      maxValueSpan: MAX_NUMBER_OF_GROUPS_OF_BARS,
    };
  }

  getLabelMinFontSize() {
    const numberOfBars = this.dataForBar && this.dataForBar.length;

    let fontSize;

    if (numberOfBars < 7) {
      fontSize = MIN_LABLE_SIZES[0];
    } else if (numberOfBars < 12) {
      fontSize = MIN_LABLE_SIZES[1];
    } else if (numberOfBars < 17) {
      fontSize = MIN_LABLE_SIZES[2];
    } else {
      fontSize = MIN_LABLE_SIZES[3];
    }

    return fontSize;
  }

  // improvement point: how can the function be reused?
  getLabelMaxFontSize() {
    const numberOfBars = this.dataForBar && this.dataForBar.length;

    let fontSize;

    if (numberOfBars < 12) {
      fontSize = MAX_LABEL_SIZES[0];
    } else {
      fontSize = MAX_LABEL_SIZES[1];
    }

    return fontSize;
  }

  getLabelFontSize() {
    return [this.getLabelMinFontSize(), this.getLabelMaxFontSize()];
  }
}

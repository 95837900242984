import { Box } from '@mui/material';
import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import { MyFormHelperText } from '../../../../components/CreateAndUpdate/MyFormHelperText';
import {
  ErrVars,
  defaultVars,
} from '../../../../components/CreateAndUpdate/commonStyle';
import { useEffect } from 'react';
import { BootstrapSelect } from '../../../../components/CreateAndUpdate/MyOperationModule';
import { BootstrapTitle } from '../../../../components/CreateAndUpdate/MyTitle';
import ItemBlock from '../CGTComponents/ItemBlock';
import SelectBox from '../CGTComponents/SelectBox';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import CreateSISinple from '@/componentsphase2/Autocomplete/CreateSISinple';

function CreateSISelectBlock(props) {
  const {
    id,
    required,
    title,
    emptyErr,
    list,
    value,
    attribute,
    onChange,
    placeholder = 'Select a value',
  } = props;
  const [totalError, setTotalError] = React.useState(false);
  React.useEffect(() => {
    setTotalError(emptyErr || props.error);
  }, [props.error, emptyErr]);
  const getErrorText = () => {
    let helperText = '';
    if (props.error) {
      helperText = props.helperText;
    } else if (emptyErr) {
      helperText = 'This field is required.';
    }
    return helperText;
  };

  function processValue(value){
    if(value ===''){
      return null;
    }else if(typeof value === 'string'){
      let newValue = {id:-1};
      newValue[attribute] = value;
      return newValue;
    }else{
      return value;
    }
  }

  return (
    <ItemBlock
      id={id}
      title={title}
      required={required}
      isError={totalError}
      errorText={getErrorText()}
    >
      <CreateSISinple
        id={`${id}_selectBox`}
        value={processValue(value)}
        options={list}
        placeholder={placeholder}
        isErr={totalError}
        isEmpty={!processValue(value)}
        defaultQuery={true}
        getOptionLabel={option => option[attribute]}
        onChange={(e, newValue) => {
          let event = {
            target: {
              value: newValue[attribute],
            },
          };
          onChange(event,newValue);
        }}
        setError={setTotalError}
        readOnly={true}
      />
    </ItemBlock>
  );
}

export default CreateSISelectBlock;

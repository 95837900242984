import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  SIMTButton,
  SIMTLoadingButton,
} from '../../../../components/StyledButton/SIMTButton';
import EndButtonArrowUnfold from '@/assets/EndButtonArrowUnfold.svg';
import {
  CancelBtn,
  DecisionUnfoldBtn,
  DecisionUnfoldSavedBtn,
  defaultVars,
  ErrVars,
  NextBtn,
  SubmitBtn,
} from '@/pages/SIPPage/SIPDetailPage/DecisionSection/DecisionMyStyled';
import {
  Box,
  Collapse,
  Container,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  FormControl,
  FormHelperText,
} from '@mui/material';
// @ts-ignore
import SIPReviseSaveButtonIcon from '../../../../assets/SIPReviseSaveButtonIcon.svg';
import { MulSelectInput } from '@/componentsphase2/SelectBox/SelectInput';
// @ts-ignore
import SIPViewAllArrow from '@/assets/SIPViewAllArrow.svg';
import SIModal from '../../../../modules/Modal';
import {
  CustomTextArea,
  CustomTextFieldTypeOne,
  ItemBlock,
} from '../../SIPDetailPage/EditComponents';
import { DecisionTitle } from '../../SIPDetailPage/DecisionSection/DecisionTitle';
import { DecisionSelectBlock } from '../../SIPDetailPage/DecisionSection/DecisionSelectBlock';
import { DatePicker } from '../../SIPDetailPage/EditComponents';
import moment from 'moment';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import { useDispatch, useSelector } from 'react-redux';
import { GetPopperBottomContext } from '@/componentsphase2/Autocomplete/CommonContext';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import { useParams } from 'react-router-dom';
import {commonButtonContainerStyle, commonPopContainerStyle} from '../../commonStyle';
import {
    acceptSaveDraft, declineSIP,
    getAcceptSIPInformation,
    submitAcceptSIPInformation
} from "@/actions/SIMT-SIP/SIP/AcceptanceDecision";
export default function AcceptDecisionUnFold(props) {
    const { handleAgree,setDecisionIsFold,setIsSave } = props;
    const decisionTypeList = ['Agree', 'Decline'];
    const dispatch = useDispatch();
    
  const { token } = useParams();
    const handleDecline = () => {
      dispatch(declineSIP(token, declineReason,comments));
    };
  function hasValue(value) {
    if (value) {
      if (value instanceof Array) {
        // 数组情况，长度非0， 零号元素非空
        return value.length > 0 && value[0] && value[0] !== '';
      } else return value !== '';
    } else {
      return false;
    }
  };
  const DecisionContainer = {
    minWidth: '1500px',
    minHeight: '256px',
    position: 'sticky', // 外部需要有一个容器让他贴靠底部
    bottom: 0,
    boxSizing: 'border-box',
    // marginLeft: openPop ? '240px' : '83px',
    transition: 'all 0.15s linear',
    zIndex: '1200',
    // overflow: 'hidden'
  };
  const popContainer = commonPopContainerStyle;
  const buttonContainer = commonButtonContainerStyle;

  const Checklist = () => {
    return (
      <span
        style={{
          color: '#154AB6',
          cursor: 'pointer',
          marginLeft:decisionType === 'Decline' ? '825px' : '980px',
          fontFamily: 'Open Sans',
           fontSize: '14px',
          fontStyle: 'normal',
           fontWeight: '400',
        }}
      >
        Checklist
      </span>
    );
  };
  // const RetractionDatabase = () => {
  //   if (decisionType === 'Decline') {
  //     return (
  //       <span
  //         style={{
  //           color: '#154AB6',
  //           cursor: 'pointer',
  //           marginLeft:'24px',
  //           fontFamily: 'Open Sans',
  //            fontSize: '14px',
  //           fontStyle: 'normal',
  //            fontWeight: '400',
  //            whiteSpace: 'nowrap',
  //         }}
  //       >
  //         Retraction Database
  //       </span>
  //     ); }
    
  // };
  const handleNext =  () => {
    setIsNext(true);
    if (hasEmptyError) {
      return;
    }
    // setRevisionEmailOpen(true)
    setEmailPopUpOpen(true);
    setEmailLoading(true);
    dispatch(
      getEmailAllInformation(
        sipStage,
        decisionType,
        sipCode,
        comments,
        additionCondition
      )
    );
    setEmailLoading(false);
  };
  const [popperBottom, setPopperBottom] = useState(0);
  const handleDecisionTypeChange = (event, value) => {
    setDecisionType(value);
  };
  const [decisionType, setDecisionType] = useState(
    /** @type {DecisionType | ""} */('')
  );
  const [isOthersSelected, setIsOthersSelected] = useState(false);
  const COMMENT_LIMIT_LENGTH = 100000;
  const [comments, setComments] = useState('');
  const [commentError, setCommentError] = useState(false);
  const [temBoxHeight, setTemBoxHeight] = useState(0);
  const [declineReason, setDeclineReason] = useState([]);
  const [hasEmptyError, setHasEmptyError] = useState(false);
  const [focusComments, setFoucusComments] = useState(false);
  const [scaleToUse, setScaleToUse] = useState(1);
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      // const screenHeight = window.innerHeight;
      if ((screenWidth - 10) < 1300.0){
        setScaleToUse((screenWidth - 10) / 1300.0);
      }else {
        setScaleToUse(1);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const declineReasonDroplist = ['Not enough time to further edit this SI',"Journal doesn't meet my expectations","Others"];
  setIsSave(comments !== '' || decisionType !== '');

  return (
    <div style={{ ...DecisionContainer, ...props.style, ...(scaleToUse<1 && {
      transform: `scale(${scaleToUse}, ${scaleToUse})`,
      transformOrigin: 'left bottom',
    }),}}>
      <div style={popContainer}>
        {/* 下部一直在的Decision按钮 */}
        <div style={buttonContainer}>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              height: '100%',
            }}
          >
            <Stack
              direction='row'
              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-DownStack'
              spacing={3}
            >

{comments || decisionType ? (
              <DecisionUnfoldSavedBtn
                onClick={() => {
                  setDecisionIsFold(true);
                }}
                id='DecisionButton'
                data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-Button'
                variant='contained'
                endIcon={
                  <EndButtonArrowUnfold data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-EndButtonArrowUnfold'></EndButtonArrowUnfold>
                }
              >
                <FormattedMessage
                  id='siDetail.decision'
                  data-selenium-id='SIP_DetailPage_Section-Unfole-DownStack-Decision'
                />
              </DecisionUnfoldSavedBtn>
            ) : (
                <DecisionUnfoldBtn
                  onClick={() => {
                    setDecisionIsFold(true);
                  }}
                  id='DecisionButton'
                  data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-Button'
                  variant='contained'
                  endIcon={
                    <EndButtonArrowUnfold data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-EndButtonArrowUnfold'></EndButtonArrowUnfold>
                  }
                >
                  <FormattedMessage
                    id='siDetail.decision'
                    data-selenium-id='SIP_DetailPage_Section-Unfole-DownStack-Decision'
                  />
                </DecisionUnfoldBtn>)}

            </Stack>
          </div>
        </div>
        <Stack
          data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-InnerStack'
          direction='column'
          justifyContent='flex-start'
          sx={{ height: '100%' }}
        >
          {/* Decision头 */}
          <Stack
            data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-HeadStack'
            direction='row'
            justifyContent='space-between'
            sx={{ height: '38.6px', mt: '5.9px' }}
          >
            {/* 头部左边 */}
            <Stack
              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-LeftStack1'
              direction='row'
              justifyContent='flex-start'
              spacing={1}
              sx={{
                width: '301px',
                height: '24px',
                alignItems: 'center',
                padding: '0px',
              }}
            >
              <Box
                data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-Box1'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '66px',
                  height: '24px',
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#113D95',
                  textTransform: 'capitalize',
                }}
              >
                {' '}
                <FormattedMessage
                  id='siDetail.decision'
                  data-selenium-id='SIP_DetailPage_Section-Unfole-LeftStack-Decision'
                />
                {/* <Checklist  /> */}
                {/* <RetractionDatabase/> */}
              </Box> 

            </Stack>
          </Stack>

          {/* Decision表中间 */}
          <Stack
            data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-CenterStack'
            direction='row'
            flexWrap='wrap'
          >
            <GetPopperBottomContext.Provider
              value={{ setValue: setPopperBottom }}
            >
              {/* 左边 */}
              <Stack
                data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-CenterStack-Left'
                direction='column'
                justifyContent='flex-start'
                spacing={2}
                height={'120px'}
              >
                <Stack
                  data-selenium-id='SIP_DetailPage_DecisionSection-TypeBlock-Stack'
                  direction={'row'}
                  justifyContent='left'
                  alignItems='flex-start'
                  flexWrap='wrap'
                >
                  <DecisionTitle
                    id='DecisionType'
                    data-selenium-id='SIP_DetailPage_DecisionSection-TypeBlock-DecisionType'
                    title='Decision Type'
                    width='123px'
                  // paddingRight='23px'
                  ></DecisionTitle>

                  <Box
                    sx={{
                      width: '286px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      backgroundColor: '#FFFFFF',
                    
                    }}>
                    <SinpleAutocomplete
                      data-selenium-id='SIP_DetailPage_DecisionSection-TypeBlock-Value'
                      id='sinpleAutocompleteDecisionType'
                      placeholder='Select Decision Type'
                      options={decisionTypeList}
                      defaultValue=''
                      attribute='value'
                      onChange={handleDecisionTypeChange}
                      value={decisionType}
                      defaultQuery={true}
                      needClearInputValue={false}
                      readOnly={true}
                      sx={{'&.MuiAutocomplete-root.Mui-focused.MuiAutocomplete-hasPopupIcon': {marginTop:'.4q',}}}
                    />
                  </Box>
                </Stack>
                
                {decisionType === 'Decline' && (
                  <Stack
                    direction='row'
                    height='50px'
                    data-selenium-id='SIP_DetailPage_DecisionSection-Unfold-FormControl5-Stack'
                  >
                    <DecisionTitle
                      id='rejectTitle'
                      data-selenium-id='SIP_DetailPage_DecisionSection-Unfold-rejectTitle'
                      title='Decline Reason(s)'
                      width='123px'
                    />
                    <Box
                      sx={{
                        width: '286px',
                        height: '40px',
                        backgroundColor: '#FFFFFF',
                      }}
                    >

                      <MulSelectInput
                        id='JournalMutiSelect-AutoComplete'
                        width="286px"
                        value={declineReason}
                        options={declineReasonDroplist}
                        placeholder={'Select Decline Reason'}
                        showStartIcon={false}
                        disableNewItem={true}
                        disableCheckBox={false}
                        showPopupIcon={true}
                        onValueChange={(newValue) => {
                          setDeclineReason(newValue);

                          if (newValue.includes('Others')) setIsOthersSelected(true);
                          else setIsOthersSelected(false);
                          if (newValue.length === 0) setHasEmptyError(true);
                          else setHasEmptyError(false);
                        }}
                        sx={{
                          backgroundColor: '#ffffff'
                        }}
                      />
                    </Box>
                  </Stack>
                )}
              </Stack>
            </GetPopperBottomContext.Provider>
            <Stack
              data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-RightStack'
              direction={'row'}
              justifyContent='space-between'
              flexWrap='wrap'
              spacing={1}
               sx={{marginLeft:'187px'}}
            >

              <DecisionTitle
                id='Comments'
                data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-Comments'
                title='Comments'
                width='82px'
                paddingRight='8'
                isOthersSelected={isOthersSelected}
                decisionType={decisionType}
                
              ></DecisionTitle>
              <FormControl>
                
                <MyOnlyText
                  id='CommentsText'
                  data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-CommentsText'
                  multiline={true}
                  width='420px'
                  height='101px'
                  value={comments}
                  setValue={setComments}
                  isError={comments.length > 100000 && !focusComments}
                  errorMessage={'Please input no more than 100,000 characters.'}
                  onFocus={() =>{setFoucusComments(true);}}
                  onBlur={() =>{setFoucusComments(false);}}
                  minRows={5}
                  maxRows={5}
                />
                <FormHelperText
                  data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-FormHelperText'
                  sx={{
                    textAlign: 'right',
                    marginTop: comments.length > 100000 && !focusComments ? '-14px' : '4px',
                    fontSize: '12px',
                    lineHeight: '18px',
                    height: '15px',
                    color:
                      comments.length > 100000 ? '#DE350B' : '#596A7C',
                    marginBottom: '3px',
                  }}
                >
                  {comments.length}/100,000
                </FormHelperText>
              </FormControl>
              
            </Stack>
          </Stack>
        </Stack>
        <Stack
 direction='row'
 spacing={2}
 sx={{ height: '36px', mt: '16px',marginLeft:'1045px'}}
        >
          <NextBtn
          variant='contained'
          onClick={decisionType === 'Agree'?handleAgree:handleDecline}
          data-selenium-id='SIP_DetailPage_DecisionSection-Unfole-NextBtn'
          disabled={!hasValue(decisionType)||hasEmptyError||decisionType === 'Decline'&&isOthersSelected&&!hasValue(comments)||comments.length>100000}
        >
          Next
        </NextBtn>

        </Stack>

      </div>
      {/* 下部一直在的Decision按钮 */}
      <div style={{ ...buttonContainer, marginRight: '0' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            height: '100%',
          }}
        ></div>
      </div>
      <Box
        id='temBox'
        sx={{
          height: `${temBoxHeight}px`,
          width: '100%',
          backgroundColor: '#FAFAFA',
        }}
      />
    </div>
  );
}
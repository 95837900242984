import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { styled } from '@mui/system';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SystemLogHeaderTagCloseIcon from '@/assets/SystemLogHeaderTagCloseIcon.svg';
import SIDrawerAutocompletePupIconTypeOne from '@/assets/SIDrawerAutocompletePupIconTypeOne.svg';

function Tag(props) {
  const { label, onDelete, maxWidth = '250px', sx, ...other } = props;
  return (
    <Tooltip title={label} disableInteractive>
      <Chip
        label={label}
        variant='outlined'
        onDelete={onDelete}
        deleteIcon={<SystemLogHeaderTagCloseIcon />}
        sx={{
          '& .MuiButtonBase-root.MuiChip-root': {
            maxWidth: maxWidth,
            ...sx,
          },
        }}
        {...other}
      />
    </Tooltip>
  );
}

const StyledTag = styled(Tag)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '24px',
  lineHeight: '22px',
  backgroundColor: '#fafafa',
  border: '1px solid #e8e8e8',
  borderRadius: '2px',
  boxSizing: 'content-box',
  padding: '0 0px 0 0px',
  outline: 0,
  overflow: 'hidden',

  '&:focus': {
    borderColor: '#40a9ff',
    backgroundColor: '#e6f7ff',
  },
  '& span': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  '& svg': {
    fontSize: '12px',
    cursor: 'pointer',
    flexShrink: 0,
  },
}));

export const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiOutlinedInput-root': {
    width: '100%',
    background: '#FFFFFF',
    borderRadius: 4,
    boxSizing: 'border-box',
    padding: '4px 0px 4px 7px', //因为tag带有marginbottom， 为了中和其效果，这里的bottom要改小一点
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '0px 0px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #D8D9DA',
  },
  '& .MuiAutocomplete-popupIndicatorOpen': {
    transform: 'none', // 展开popup时不要翻转
  },
  '& .MuiAutocomplete-endAdornment': {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    top: '0',
    right: '8px',
  },
});

export default function SelectBoxWithFixedDropList(props) {
  const { data, id, placeholder, value, setSelected, ...other } = props;
  const [open, setOpen] = useState(false);

  return (
    <Box>
      <StyledAutocomplete
        disableCloseOnSelect
        disableListWrap
        disableClearable
        value={value}
        size='small'
        id={id}
        options={data ? data : []}
        openText='Search'
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(_event, value) => {
          setSelected(value);
        }}
        renderOption={(optionProps, option, { selected }) => (
          <li
            {...optionProps}
            style={{ height: '36px', lineHeight: '16px', fontSize: '14px' }}
          >
            <Checkbox
              key={id + '-' + option}
              icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
              checkedIcon={<CheckBoxIcon fontSize='small' />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <Tooltip
              title={option}
              disableInteractive
              leaveTouchDelay={0}
              followCursor={true}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textTransform: 'capitalize',
                }}
              >
                {option}
              </span>
            </Tooltip>
          </li>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            return (
              <StyledTag
                key={id + '-selectedLabel' + option}
                label={option}
                maxWidth='240px'
                {...getTagProps({ index })}
              />
            );
          })
        }
        renderInput={params => (
          <TextField
            {...params}
            placeholder={value.length !== 0 ? null : placeholder}
            variant='outlined'
            InputProps={{
              ...params.InputProps,
            }}
          ></TextField>
        )}
        popupIcon={
          <IconButton sx={{ width: '16px', height: '16px', padding: '0px' }}>
            <SIDrawerAutocompletePupIconTypeOne></SIDrawerAutocompletePupIconTypeOne>
          </IconButton>
        }
        {...other}
      />
    </Box>
  );
}

import styles from './index.module.scss';
import WileyLogo from '../../assets/wiley_logo.svg';

function Header() {
  return (
    <>
      <div className={styles['header']}>
        <div className={styles['layout']}>
          <div className={styles['title']}>
            <WileyLogo className={styles['logo']} />
            <span className={styles['sub-title']}>Special Issue Management Tool</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
import InviteEbmItem from './InviteEbmItem';
import { Button, Box, Stack, Typography } from '@mui/material';
import AddIcon from '@/assets/SIPPage/EbmPanel/AddIcon.svg';
import DeleteIcon from '@/assets/SIPPage/EbmPanel/DeleteIcon.svg';
import { useState, useEffect, useMemo } from 'react';
const MAX_EBM_INVITATION = 20; //TODO , this will be retrieved from the backend
export default function InviteEBMPage(props) {
  const {
    ebmListChange,
    existingEbmList,
    canDelete,
    handleInputError,
    isNextClick,
    emailRepeatIndex,
  } = props;
  const [newEbmAddList, setNewEbmAddList] = useState([]);
  useEffect(() => {
    ebmListChange(newEbmAddList);
  }, [newEbmAddList]);
  // 添加新的ebm
  const addNewEbmItem = () => {
    setNewEbmAddList([
      ...newEbmAddList,
      { firstName: '', lastName: '', email: '' },
    ]);
  };

  const modifyEbmItem = (index, newEbm) => {
    const updateEbm = [...newEbmAddList];
    updateEbm[index] = newEbm; // update the ebm by the index.
    setNewEbmAddList(updateEbm);
  };

  // 删除指定的ebm
  const deleteEbm = index => {
    console.log('before Delete', [...newEbmAddList], index);
    const updatedTodos = [...newEbmAddList];
    updatedTodos.splice(index, 1);
    console.log('after Delete', updatedTodos);
    setNewEbmAddList(updatedTodos);
  };
  const inviteEbmMaxNum = useMemo(() => {
    return MAX_EBM_INVITATION - existingEbmList.length;
  }, [existingEbmList]);

  return (
    <>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '20px',
          textAlign: 'left',
          position: 'sticky',
        }}
      >{`You can invite up to ${inviteEbmMaxNum} EBM(s) to provide comments`}</Typography>
      <Stack sx={{ gap: '12px' }}>
        {newEbmAddList.map((item, index) => (
          <Box>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              {`EBM ${index + 1}`}
              <Button
                sx={{ minWidth: '14px' }}
                data-selenium-id='SIP_DetailPage_DecisionSection-newEbmAddPopUp-DialogContent-Box'
                onClick={() => deleteEbm(index)}
              >
                {canDelete && <DeleteIcon />}
              </Button>
            </Stack>
            <InviteEbmItem
              key={index}
              index={index}
              value={item}
              handleDelete={deleteEbm}
              isNextClick={isNextClick}
              isEmailRepeat={emailRepeatIndex.includes(index)}
              onChange={e => {
                modifyEbmItem(index, e);
              }}
            />
          </Box>
        ))}
      </Stack>
      {inviteEbmMaxNum > 0 && (
        <Button
          size='medium'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '4px',
          }}
          onClick={addNewEbmItem}
        >
          <Box>Add a EBM</Box>
          <AddIcon />
        </Button>
      )}
    </>
  );
}

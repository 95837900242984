/**
 * 警告对话框
 */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import './index.css';
import { clear } from '../../actions/SIMT-User/User/UserAction';
import {
  resetRoleResult,

} from '../../actions/SIMT-User/Role/RoleManageAction';
import { useDispatch, useSelector } from 'react-redux';
import {
  openConfirm,
  setEditType,
  setPosition,
  setTextParam,
} from '../../actions/SIMT-User/Alert/AlertAction';
import { createHashHistory } from 'history';
import { FormattedMessage } from 'react-intl';
import { createRole, deleteRole, updateRole } from "@/actions/SIMT-User/Role";
import { editAdmin } from "@/actions/SIMT-User/User/SuperAdmin";
import { editUser } from "@/actions/SIMT-User/User/SystemAdmin";
import { sendActiveEmail } from "@/actions/SIMT-User/User/SystemAdmin";
import { deleteUser } from "@/actions/SIMT-User/User/SystemAdmin";

export function ErrAlertDialog(props) {
  const { open, handleErrClose, ErrMessage } = props;

  return (
    <Dialog open={open} maxWidth={false} onClose={handleErrClose}>
      <DialogTitle
        className='AlertFormDialogTitle'
        sx={{ padding: '14px 24px', fontSize: '24px', lineHeight: '36px' }}
      >
        Delete a role
      </DialogTitle>
      <DialogContent
        className='AlertFormDialogContent'
        sx={{ padding: '8px 24px', paddingTop: '8px' }}
      >
        <DialogContentText
          id='errAlertDialogContentText'
          sx={{ color: '#383838' }}
        >
          {ErrMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id='errAlertDialogOKButton'
          variant='text'
          color='darkBlue'
          className='AlertFormDialogActionButton'
          sx={{ fontWeight: '600', fontSize: '14px', lineHeight: '20px' }}
          onClick={handleErrClose}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function AlertDialog(props) {
  const { type, data, open, handleClose, fullName, userId = 0 } = props;
  const dispatch = useDispatch();
  const [errType, setErrType] = useState();
  const history = createHashHistory();

  const selector = state => {
    return {
      adminResult: state.User.editAdminResult,
      userResult: state.User.editUserResult,
      roleResult: state.Role.roleResult,
      roleLastOpe: state.Role.lastOperation,
    };
  };
  const { adminResult, userResult, roleResult, roleLastOpe } =
    useSelector(selector);

  useEffect(() => {
    // console.log(adminResult)
    if (adminResult || userResult) {
      if (adminResult === 'SIAssociated' || userResult === 'PreSIAssociated') {
        dispatch(setPosition('bottom', 'center'));
        dispatch(setTextParam('error,' + fullName));
        dispatch(setEditType(type));
        dispatch(openConfirm());
        handleClose();
        dispatch(clear());
      } else {
        dispatch(setPosition('bottom', 'center'));
        dispatch(setTextParam(fullName));
        dispatch(setEditType(type));
        dispatch(openConfirm());
        handleClose();
        dispatch(clear());
      }
    }
  }, [adminResult, userResult]);

  useEffect(() => {
    switch (roleLastOpe) {
      case 'createRole':
      case 'updateRole': {
        if (roleResult === true) {
          dispatch(setTextParam(fullName));
          dispatch(setEditType(type));
          dispatch(setPosition('bottom', 'center'));
          dispatch(openConfirm());
          handleClose();
          history.back();
        } else {
          dispatch(setPosition('bottom', 'center'));
          dispatch(setEditType('error'));
          dispatch(openConfirm());
          handleClose();
        }
        break;
      }
      case 'deleteRole': {
        if (roleResult === true) {
          dispatch(setPosition('bottom', 'center'));
          dispatch(setTextParam(fullName));
          dispatch(setEditType(type));
          dispatch(openConfirm());
          handleClose();
        } else {
          setErrType('deleteRoleErr');
        }
        break;
      }
      default: {
        break;
      }
    }
    dispatch(resetRoleResult());
  }, [roleResult]);

  const info = () => {
    switch (type) {
      case 'deleteAdmin':
        return `Are you sure you want to permanently remove a system admin?`;
      case 'resendInvitationToAdmin':
        return `Are you sure you want to proceed ?`;
      case 'resetAdminPass':
        return `Are you sure you want to proceed ?`;
      case 'unlockAdmin':
        return `Are you sure you want to proceed ?`;
      case 'InactiveAdmin':
        return `Are you sure you want to proceed ?`;
      case 'deleteRole':
        return 'Are you sure you want to proceed ?';
      case 'createRole':
        return 'Are you sure you want to proceed ?';
      case 'updateRole':
        return 'Are you sure you want to proceed ?';
      case 'unsavedUpdateRole':
        return 'Are you sure you want to leave the page?';
      default:
        return '';
    }
  };

  const title = () => {
    switch (type) {
      case 'deleteAdmin':
        return `Remove a system admin`;
      case 'resendInvitationToAdmin':
        return `Resend invitation link`;
      case 'resetAdminPass':
        return `Reset admin password`;
      case 'unlockAdmin':
        return `Unlock a system admin`;
      case 'InactiveAdmin':
        return `Inactive a system admin`;
      case 'deleteRole':
        return 'Delete a role';
      case 'createRole':
        return 'Create a role';
      case 'updateRole':
        return 'Update a role';
      case 'unsavedUpdateRole':
        return 'You have unsaved changes';
      default:
        return '';
    }
  };

  const method = (data, type, userId) => {
    switch (type) {
      case 'deleteAdmin':
        // return dispatch(editAdmin(data, type));
        return dispatch(editAdmin(userId, type));
      case 'deleteUser':
        return dispatch(deleteUser(userId));
      case 'resendInvitationToAdmin':
        // return dispatch(editAdmin(data, type));
        return dispatch(editAdmin(userId, type));
      case 'resetAdminPass':
        // return dispatch(editAdmin(data, type));
        return dispatch(editAdmin(userId, type));
      case 'resendInvitationToUser':
        return dispatch(sendActiveEmail(userId));
      case 'resetUserPass':
        return dispatch(sendActiveEmail(userId));
      case 'deleteRole':
        return dispatch(deleteRole(data));
      case 'createRole':
        return dispatch(createRole(data.roleName, data.right));
      case 'updateRole':
        return dispatch(
          updateRole(data.originalRoleName, data.roleName, data.right)
        );

      default:
        return '';
    }
  };

  const handleEdit = async () => {
    let result = await method(data, type);
    switch (type) {
      case 'unsavedUpdateRole': {
        handleClose();
        history.back();
        break;
      }
      case 'emailExists': {
        if (result === true) {
          handleClose();
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleErrClose = () => {
    handleClose();
    setErrType();
  };

  if (errType === 'deleteRoleErr') {
    return (
      <ErrAlertDialog
        open={open}
        handleErrClose={handleErrClose}
        ErrMessage='You cannot delete a role if there is an existing user under the role '
      />
    );
  } else {
    return (
      <Dialog open={open} maxWidth={false} onClose={handleClose}>
        <DialogTitle
          className='AlertFormDialogTitle'
          sx={{ padding: '14px 24px', fontSize: '24px', lineHeight: '36px' }}
        >
          {title()}
        </DialogTitle>
        <DialogContent
          className='AlertFormDialogContent'
          sx={{ padding: '8px 24px', paddingTop: '8px', overflow: 'hidden' }}
        >
          <DialogContentText
            id='alertDialogContentText'
            sx={{ color: '#383838' }}
          >
            {info()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id='alertDialogCancelButton'
            variant='text'
            color='darkBlue'
            className='AlertFormDialogActionButton'
            sx={{ fontWeight: '600', fontSize: '14px', lineHeight: '20px' }}
            onClick={handleClose}
          >
            CANCEL
          </Button>
          <Button
            id='alertDialogOKButton'
            variant='text'
            color='darkBlue'
            className='AlertFormDialogActionButton'
            sx={{ fontWeight: '600', fontSize: '14px', lineHeight: '20px' }}
            onClick={handleEdit}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export function CommonDialog(props) {
  const {
    id,
    title,
    content,
    alertOpen,
    handleClose,
    handleConfirm,
    confirmText,
  } = props;

  return (
    <Dialog
      open={alertOpen}
      maxWidth={false}
      onClose={handleClose}
      sx={{ zIndex: 1402 }}
    >
      <DialogTitle
        className='AlertFormDialogTitle'
        sx={{
          padding: '14px 24px',
          fontSize: '24px',
          lineHeight: '36px',
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{ padding: '8px 24px', paddingTop: '8px', height: 'auto' }}
      >
        <DialogContentText
          id={`${id ? id : ''}ContentText`}
          sx={{ color: '#383838' }}
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id={`${id ? id : ''}CancelButton`}
          variant='text'
          color='darkBlue'
          className='AlertFormDialogActionButton'
          sx={{ fontWeight: '600', fontSize: '14px', lineHeight: '20px' }}
          onClick={handleClose}
        >
          CANCEL
        </Button>
        <Button
          id={`${id ? id : ''}ConfirmButton`}
          variant='text'
          color='darkBlue'
          className='AlertFormDialogActionButton'
          sx={{ fontWeight: '600', fontSize: '14px', lineHeight: '20px' }}
          onClick={handleConfirm}
        >
          {confirmText ? confirmText : 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Tooltip,
  TableCell,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '../../../../components/DataTable';
import {
  StyledTableCellPurple,
  StyledTableCellDisPurple,
  StyledTableCellGreen,
  StyledTableCellDisGreen,
  TwoColorTableRowGreen,
  TwoColorTableRowPurple, StyledTableCellNormal, ReportNoData, TableBox2
} from "../../ReportSpecificComponents/SpecialColorTableHeader";
import '../../../../App.css';
import { findLongestLineSubstring, measureLongestWidth, measureWidth } from "../../../../utils/StringWidthMeasure";
import React from "react";
import { SimpleTooltip } from "../../../../componentsphase2/CustomTooltip";
import {GetArticleAnnualTrendsBusinessDivisionTableData} from "@/actions/SIMT-Report/Report/ReportArticle";

export default function BusinessDivisionTable(props) {
  const { isOA } = props;
  const [itemTable, setitemTable] = useState([]);
  const { selectMonthDate, AnnualTrendsBDDate } = useSelector(state => {
    return {
      // selectMonthDate: state.Report.ArticleAnnualTrendsDate,
      selectMonthDate: state.Report.selectDate,
      AnnualTrendsBDDate: state.Report.ArticleAnnualTrendsBusinessDivisionData,
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    widthMap['businessDivision'] = measureWidth(findLongestLineSubstring('Business Division'), '18px Open Sans');

    const FY_TD = measureLongestWidth('FY23 TD', '18px Open Sans');
    const FY_CMP = measureLongestWidth('FY22/ FY21 TD(%)', '18px Open Sans');

    for (let type of ['sub','acc','pub']) {
      widthMap[`${type}-0`] = FY_TD;
      widthMap[`${type}-1`] = FY_TD;
      widthMap[`${type}-2`] = FY_TD;
      widthMap[`${type}-3`] = FY_CMP;
      widthMap[`${type}-4`] = FY_CMP;
    }

    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (AnnualTrendsBDDate && AnnualTrendsBDDate.length > 0){
      const maxMap = {};
      for (let i = 0; i < AnnualTrendsBDDate.length; i++) {
        let row = AnnualTrendsBDDate[i];
        let dataObj = {
          businessDivision: row.businessDivision,

          'sub-0':  row.two_fy_ago_submitted ?? '0',
          'sub-1':  row.one_fy_ago_submitted ?? '0',
          'sub-2':  row.current_fy_submitted ?? '0',
          'sub-3':  row.submitted_ytd_1 ?? '0',
          'sub-4':  row.submitted_ytd_2 ?? '0',

          'acc-0':  row.two_fy_ago_accepted ?? '0',
          'acc-1':  row.one_fy_ago_accepted ?? '0',
          'acc-2':  row.current_fy_accepted ?? '0',
          'acc-3':  row.accepted_ytd_1 ?? '0',
          'acc-4':  row.accepted_ytd_2 ?? '0',

          'pub-0':  row.two_fy_ago_published ?? '0',
          'pub-1':  row.one_fy_ago_published ?? '0',
          'pub-2':  row.current_fy_published ?? '0',
          'pub-3':  row.published_ytd_1 ?? '0',
          'pub-4':  row.published_ytd_2 ?? '0',
        };
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [AnnualTrendsBDDate]);

  function calcGroupWidth(type){
    let sum = 0;
    for (let i of [0, 1, 2, 3, 4]) {
      sum += Math.max((columnWidthMap[`${type}-${i}`]??0), columnMinWidthMap[`${type}-${i}`]) + 50;
    }
    return sum;
  }

  const dispatch = useDispatch();

  const getData = async () => {
    await dispatch(
      GetArticleAnnualTrendsBusinessDivisionTableData(selectMonthDate, !isOA)
    );
  };

  useEffect(() => {
    if (selectMonthDate !== null && selectMonthDate !== undefined) {
      var beforeYearHeader, afterYearHeader, currentYearHeader;

      currentYearHeader = Number(selectMonthDate.split('-')[0].slice(-2));
      beforeYearHeader = (
        '' +
        (Number(selectMonthDate.split('-')[0]) - 1)
      ).slice(-2);
      afterYearHeader = (
        '' +
        (Number(selectMonthDate.split('-')[0]) + 1)
      ).slice(-2);
      if (selectMonthDate.split('-')[1] < 5) {
        currentYearHeader -= 1;
        beforeYearHeader -= 1;
        afterYearHeader -= 1;
      }
      var itemsChanged = [
        {
          id: 'FY' + beforeYearHeader + '' + 'TD',
          label: 'FY' + beforeYearHeader + '' + 'TD',
          maxWidth: '38px',
          minWidth: '30px',
        },
        {
          id: 'FY' + currentYearHeader + '' + 'TD',
          label: 'FY' + currentYearHeader + '' + 'TD',
          maxWidth: '38px',
          minWidth: '30px',
        },
        {
          id: 'FY' + afterYearHeader + '' + '' + 'TD',
          label: 'FY' + afterYearHeader + '' + '' + 'TD',
          maxWidth: '38px',
          minWidth: '30px',
        },
        {
          id: 'FY' + currentYearHeader + '/FY' + beforeYearHeader,
          label: 'FY' + currentYearHeader + '/FY' + beforeYearHeader,
          maxWidth: '165px',
          minWidth: '43px',
        },
        {
          id: 'FYTwo' + afterYearHeader + '/FYTwo' + currentYearHeader,
          label: 'FY' + afterYearHeader + '/FY' + currentYearHeader,
          maxWidth: '165px',
          minWidth: '65px',
        },
      ];
      getData();
      setitemTable(itemsChanged);
    }
  }, [selectMonthDate, isOA]);

  const showDataTableBD = () => {
    if (AnnualTrendsBDDate === null || AnnualTrendsBDDate === undefined) {
      return [];
    } else return AnnualTrendsBDDate;
  };

  const typeRank = ['sub','acc','pub'];
  //第二行的表头显示
  const displayPurpleHeader = (i) => {
    const type = typeRank[i];
    return itemTable.map((item, index) => {
      if (item.id.indexOf('/') !== -1) {
        if (item.id.indexOf('FYTwo') !== -1) {
          return (
            <StyledTableCellDisPurple
              data-selenium-id='Report_Articles-AunnalTrends-PurpleHeader-BottomBorder1'
              align='left'
              sx={{
                // top: '52.5px',
                // minWidth: item.minWidth,
                // maxWidth: item.maxWidth,
                top: 42,
                width: `${Math.max((columnWidthMap[`${type}-${index}`]??0), columnMinWidthMap[`${type}-${index}`]) + 50 }px`,
              }}
              key={item.id}
              className='RightBottomBorder'
            >
              {item.label} TD(%)
            </StyledTableCellDisPurple>
          );
        }
        return (
          <StyledTableCellDisPurple
            className='BottomBorder'
            data-selenium-id='Report_Articles-AunnalTrends-PurpleHeader-BottomBorder2'
            align='left'
            sx={{
              // top: '52.5px',
              // minWidth: item.minWidth,
              // maxWidth: item.maxWidth,
              top: 42,
              width: `${Math.max((columnWidthMap[`${type}-${index}`]??0), columnMinWidthMap[`${type}-${index}`]) + 50 }px`,
            }}
            key={item.id}
          >
            {item.label} TD(%)
          </StyledTableCellDisPurple>
        );
      } else {
        return (
          <StyledTableCellDisPurple
            align='left'
            sx={{
              // top: '52.5px',
              // minWidth: item.minWidth,
              // maxWidth: item.maxWidth,
              top: 42,
              width: `${Math.max((columnWidthMap[`${type}-${index}`]??0), columnMinWidthMap[`${type}-${index}`]) + 50 }px`,
            }}
            key={item.id}
            className='BottomBorder'
            data-selenium-id='Report_Articles-AunnalTrends-PurpleHeader-BottomBorder3'
          >
            {item.label}
          </StyledTableCellDisPurple>
        );
      }
    });
  };
  const displayGreenHeader = (i) => {
    const type = typeRank[i];
    return itemTable.map((item, index) => {
      if (item.id.indexOf('/') != -1) {
        if (item.id.indexOf('FYTwo') != -1) {
          return (
            <StyledTableCellDisGreen
              align='left'
              sx={{
                // top: '52.5px',
                // minWidth: item.minWidth,
                // maxWidth: item.maxWidth,
                top: 42,
                width: `${Math.max((columnWidthMap[`${type}-${index}`]??0), columnMinWidthMap[`${type}-${index}`]) + 50 }px`,
              }}
              key={item.id}
              className='RightBottomBorder'
              data-selenium-id='Report_Articles-AunnalTrends-GreenHeader-BottomBorder1'
            >
              {item.label} TD(%)
            </StyledTableCellDisGreen>
          );
        }
        return (
          <StyledTableCellDisGreen
            className='BottomBorder'
            align='left'
            sx={{
              // top: '52.5px',
              // minWidth: item.minWidth,
              // maxWidth: item.maxWidth,
              top: 42,
              width: `${Math.max((columnWidthMap[`${type}-${index}`]??0), columnMinWidthMap[`${type}-${index}`]) + 50 }px`,
            }}
            key={item.id}
            data-selenium-id='Report_Articles-AunnalTrends-GreenHeader-BottomBorder2'
          >
            {item.label} TD(%)
          </StyledTableCellDisGreen>
        );
      } else {
        return (
          <StyledTableCellDisGreen
            align='left'
            sx={{
              // top: '52.5px',
              // minWidth: item.minWidth,
              // maxWidth: item.maxWidth,
              top: 42,
              width: `${Math.max((columnWidthMap[`${type}-${index}`]??0), columnMinWidthMap[`${type}-${index}`]) + 50 }px`,
            }}
            key={item.id}
            className='BottomBorder'
            data-selenium-id='Report_Articles-AunnalTrends-GreenHeader-BottomBorder3'
          >
            {item.label}
          </StyledTableCellDisGreen>
        );
      }
    });
  };

  if (!isOA) {
    return (
      <TableBox2
        sx={{ height: '30' }}
        dataList={[AnnualTrendsBDDate]}
        tableKey={'Report_Articles-AunnalTrends-BDTable'}
        footerHeight={0}
      >
        <TableContainer
          id='reportArticlesAnnualTrendsBDTable'
          data-selenium-id='Report_Articles-AunnalTrends-BDTableContainer'
          sx={{ maxHeight: '560px' }}
        >
          <Table
            stickyHeader
            aria-label='sticky table'
            data-selenium-id='Report_Articles-AunnalTrends-BDTable'
            sx={{
              tableLayout: 'fixed',
              width: 'max-content',
              maxWidth: 'none',
            }}
          >
            <TableHead data-selenium-id='Report_Articles-AunnalTrends-BDTableHead'>
              <TableRow data-selenium-id='Report_Articles-AunnalTrends-BDTableRow'>
                <StyledTableCellNormal
                  rowSpan={2}
                  className='RightBottomBorder'
                  data-selenium-id='Report_Articles-AunnalTrends-BDTableRow-CellGreen-BusinessDivision'
                  sx={{
                    width: `${Math.max((columnWidthMap['businessDivision']??0), columnMinWidthMap['businessDivision']) + 50 }px`,
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                  }}
                >
                  Business Division
                </StyledTableCellNormal>
                <StyledTableCellGreen
                  colSpan={5}
                  align='left'
                  className='RightBottomBorder ReportHeaderFirstLine'
                  data-selenium-id='Report_Articles-AunnalTrends-BDTableRow-CellGreen-Submission'
                  sx={{
                    width: `${calcGroupWidth('sub')}px`,
                  }}
                >
                  Submission
                </StyledTableCellGreen>
                <StyledTableCellGreen
                  colSpan={5}
                  align='left'
                  className='RightBottomBorder ReportHeaderFirstLine'
                  data-selenium-id='Report_Articles-AunnalTrends-BDTableRow-CellGreen-Acceptance'
                  sx={{
                    width: `${calcGroupWidth('acc')}px`,
                  }}
                >
                  Acceptance
                </StyledTableCellGreen>
                <StyledTableCellGreen
                  colSpan={5}
                  align='left'
                  className='RightBottomBorder ReportHeaderFirstLine'
                  data-selenium-id='Report_Articles-AunnalTrends-BDTableRow-CellGreen-Published'
                  sx={{
                    width: `${calcGroupWidth('pub')}px`,
                  }}
                >
                  Published
                </StyledTableCellGreen>
              </TableRow>
              <TableRow>
                {displayGreenHeader(0)}
                {displayGreenHeader(1)}
                {displayGreenHeader(2)}
              </TableRow>
            </TableHead>
            <TableBody data-selenium-id='Report_Articles-AunnalTrends-TableBody'>
              {AnnualTrendsBDDate != null &&
              AnnualTrendsBDDate != undefined &&
              AnnualTrendsBDDate.length != 0 ? (
                AnnualTrendsBDDate.map((row, index) => {
                  if (row.businessDivision === 'Total') {
                    return (
                      <TwoColorTableRowGreen
                        key={index}
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-TwoColorTableRowGreen-${index}`}
                      >
                        <StyledTableCell
                          className='RightBorder ReportTotalTextSize'
                          align='left'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell1-${index}`}
                          sx={{
                            position: 'sticky',
                            left: 0,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                        >
                          <Tooltip
                            data-selenium-id={`Report_Articles-AunnalTrends-TableBody-Tooltip-${index}`}
                            title={row.businessDivision}
                            disableInteractive
                          >
                            <div
                              style={{
                                textOverflow: 'ellipsis',
                                // width: '140px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <Box>{row.businessDivision}</Box>
                            </div>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell2-${index}`}
                        >
                          <Box>
                            {' '}
                            {row.two_fy_ago_submitted === null
                              ? '0'
                              : row.two_fy_ago_submitted}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell3-${index}`}
                        >
                          <Box>
                            {row.one_fy_ago_submitted === null
                              ? '0'
                              : row.one_fy_ago_submitted}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell4-${index}`}
                        >
                          <Box>
                            {row.current_fy_submitted === null
                              ? '0'
                              : row.current_fy_submitted}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell5-${index}`}
                        >
                          <Box>{row.submitted_ytd_1}</Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell6-${index}`}
                        >
                          <Box>{row.submitted_ytd_2}</Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell7-${index}`}
                        >
                          <Box>
                            {row.two_fy_ago_accepted === null
                              ? '0'
                              : row.two_fy_ago_accepted}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell8-${index}`}
                        >
                          <Box>
                            {row.one_fy_ago_accepted === null
                              ? '0'
                              : row.one_fy_ago_accepted}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell9-${index}`}
                        >
                          <Box>
                            {row.current_fy_accepted === null
                              ? '0'
                              : row.current_fy_accepted}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell10-${index}`}
                        >
                          <Box>{row.accepted_ytd_1}</Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell11-${index}`}
                        >
                          <Box>{row.accepted_ytd_2}</Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell12-${index}`}
                        >
                          <Box>
                            {row.two_fy_ago_published === null
                              ? '0'
                              : row.two_fy_ago_published}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell13-${index}`}
                        >
                          <Box>
                            {row.one_fy_ago_published === null
                              ? '0'
                              : row.one_fy_ago_published}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell14-${index}`}
                        >
                          <Box>
                            {row.current_fy_published === null
                              ? '0'
                              : row.current_fy_published}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell15-${index}`}
                        >
                          <Box>{row.published_ytd_1}</Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell16-${index}`}
                        >
                          <Box>{row.published_ytd_2}</Box>
                        </StyledTableCell>
                      </TwoColorTableRowGreen>
                    );
                  }
                  return (
                    <TwoColorTableRowGreen key={index}>
                      <StyledTableCell
                        className='RightBorder'
                        align='left'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell17-${index}`}
                        sx={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 6,
                          backgroundColor: '#F1F3F5',
                        }}
                      >
                        <SimpleTooltip title={row.businessDivision}>
                            {row.businessDivision}
                          </SimpleTooltip>
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell18-${index}`}
                      >
                        {row.two_fy_ago_submitted === null
                          ? '0'
                          : row.two_fy_ago_submitted}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell19-${index}`}
                      >
                        {row.one_fy_ago_submitted === null
                          ? '0'
                          : row.one_fy_ago_submitted}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell20-${index}`}
                      >
                        {row.current_fy_submitted === null
                          ? '0'
                          : row.current_fy_submitted}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell21-${index}`}
                      >
                        {row.submitted_ytd_1}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell22-${index}`}
                      >
                        {row.submitted_ytd_2}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell23-${index}`}
                      >
                        {row.two_fy_ago_accepted === null
                          ? '0'
                          : row.two_fy_ago_accepted}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell24-${index}`}
                      >
                        {row.one_fy_ago_accepted === null
                          ? '0'
                          : row.one_fy_ago_accepted}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell25-${index}`}
                      >
                        {row.current_fy_accepted === null
                          ? '0'
                          : row.current_fy_accepted}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell26-${index}`}
                      >
                        {row.accepted_ytd_1}
                      </StyledTableCell>
                      <StyledTableCell
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell27-${index}`}
                        align='left'
                        className='RightBorder ReportNumberTextColor'
                      >
                        {row.accepted_ytd_2}
                      </StyledTableCell>
                      <StyledTableCell
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell28-${index}`}
                        align='left'
                        className='ReportNumberTextColor'
                      >
                        {row.two_fy_ago_published === null
                          ? '0'
                          : row.two_fy_ago_published}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell29-${index}`}
                      >
                        {row.one_fy_ago_published === null
                          ? '0'
                          : row.one_fy_ago_published}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell30-${index}`}
                      >
                        {row.current_fy_published === null
                          ? '0'
                          : row.current_fy_published}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell31-${index}`}
                      >
                        {row.published_ytd_1}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell32-${index}`}
                      >
                        {row.published_ytd_2}
                      </StyledTableCell>
                    </TwoColorTableRowGreen>
                  );
                })
              ) : (
                <ReportNoData id='AcquiredAndActiveSiAnnualTrendsBDNoResultTableCell' />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TableBox2>
    );
  } else {
    return (
      <TableBox2
        sx={{ height: '30' }}
        dataList={[AnnualTrendsBDDate]}
        tableKey={'Report_Articles-AunnalTrends-BDTable'}
        footerHeight={0}
      >
        <TableContainer
          id='reportArticlesAnnualTrendsDBTable'
          data-selenium-id='Report_Articles-AunnalTrends-BDTableContainer'
          sx={{ maxHeight: '560px' }}
        >
          <Table
            stickyHeader
            aria-label='sticky table'
            data-selenium-id='Report_Articles-AunnalTrends-BDTable'
            sx={{
              tableLayout: 'fixed',
              width: 'max-content',
              maxWidth: 'none',
            }}
          >
            <TableHead data-selenium-id='Report_Articles-AunnalTrends-BDTableHeader'>
              <TableRow data-selenium-id='Report_Articles-AunnalTrends-BDTableRow'>
                <StyledTableCellNormal
                  rowSpan={2}
                  className='RightBottomBorder'
                  data-selenium-id='Report_Articles-AunnalTrends-BDTableRow-CellPurple-BusinessDivision'
                  sx={{
                    width: `${Math.max((columnWidthMap['businessDivision']??0), columnMinWidthMap['businessDivision']) + 50 }px`,
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                  }}
                >
                  Business Division
                </StyledTableCellNormal>
                <StyledTableCellPurple
                  colSpan={5}
                  align='left'
                  className='RightBottomBorder ReportHeaderFirstLine'
                  data-selenium-id='Report_Articles-AunnalTrends-BDTableRow-CellPurple-Submission'
                  sx={{
                    width: `${calcGroupWidth('sub')}px`,
                  }}
                >
                  Submission
                </StyledTableCellPurple>
                <StyledTableCellPurple
                  colSpan={5}
                  align='left'
                  className='RightBottomBorder ReportHeaderFirstLine'
                  data-selenium-id='Report_Articles-AunnalTrends-BDTableRow-CellPurple-Acceptance'
                  sx={{
                    width: `${calcGroupWidth('acc')}px`,
                  }}
                >
                  Acceptance
                </StyledTableCellPurple>
                <StyledTableCellPurple
                  colSpan={5}
                  align='left'
                  className='RightBottomBorder ReportHeaderFirstLine'
                  data-selenium-id='Report_Articles-AunnalTrends-BDTableRow-CellPurple-Published'
                  sx={{
                    width: `${calcGroupWidth('pub')}px`,
                  }}
                >
                  Publication
                </StyledTableCellPurple>
              </TableRow>
              <TableRow>
                {displayPurpleHeader(0)}
                {displayPurpleHeader(1)}
                {displayPurpleHeader(2)}
              </TableRow>
            </TableHead>
            <TableBody>
              {AnnualTrendsBDDate != null &&
              AnnualTrendsBDDate != undefined &&
              AnnualTrendsBDDate.length != 0 ? (
                AnnualTrendsBDDate.map((row, index) => {
                  if (row.businessDivision === 'Total') {
                    return (
                      <TwoColorTableRowPurple key={index}>
                        <StyledTableCell
                          className='RightBorder ReportTotalTextSize'
                          align='left'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell33-${index}`}
                          sx={{
                            position: 'sticky',
                            left: 0,
                            zIndex: 6,
                            backgroundColor: '#F1F3F5',
                          }}
                        >
                          <SimpleTooltip title={row.businessDivision}>
                              <Box>{row.businessDivision}</Box>
                            </SimpleTooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell34-${index}`}
                        >
                          <Box>
                            {' '}
                            {row.two_fy_ago_submitted === null
                              ? '0'
                              : row.two_fy_ago_submitted}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell35-${index}`}
                        >
                          <Box>
                            {row.one_fy_ago_submitted === null
                              ? '0'
                              : row.one_fy_ago_submitted}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell36-${index}`}
                        >
                          <Box>
                            {row.current_fy_submitted === null
                              ? '0'
                              : row.current_fy_submitted}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell37-${index}`}
                        >
                          <Box>{row.submitted_ytd_1}</Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell38-${index}`}
                        >
                          <Box>{row.submitted_ytd_2}</Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell39-${index}`}
                        >
                          <Box>
                            {row.two_fy_ago_accepted === null
                              ? '0'
                              : row.two_fy_ago_accepted}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell40-${index}`}
                        >
                          <Box>
                            {row.one_fy_ago_accepted === null
                              ? '0'
                              : row.one_fy_ago_accepted}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell41-${index}`}
                        >
                          <Box>
                            {row.current_fy_accepted === null
                              ? '0'
                              : row.current_fy_accepted}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell42-${index}`}
                        >
                          <Box>{row.accepted_ytd_1}</Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell43-${index}`}
                        >
                          <Box>{row.accepted_ytd_2}</Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell44-${index}`}
                        >
                          <Box>
                            {row.two_fy_ago_published === null
                              ? '0'
                              : row.two_fy_ago_published}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell45-${index}`}
                        >
                          <Box>
                            {row.one_fy_ago_published === null
                              ? '0'
                              : row.one_fy_ago_published}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell46-${index}`}
                        >
                          <Box>
                            {row.current_fy_published === null
                              ? '0'
                              : row.current_fy_published}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='NoBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell47-${index}`}
                        >
                          <Box>{row.published_ytd_1}</Box>
                        </StyledTableCell>
                        <StyledTableCell
                          align='left'
                          className='RightBorder ReportNumberTextColor ReportTotalTextSize'
                          data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell48-${index}`}
                        >
                          <Box>{row.published_ytd_2}</Box>
                        </StyledTableCell>
                      </TwoColorTableRowPurple>
                    );
                  }
                  return (
                    <TwoColorTableRowPurple key={index}>
                      <StyledTableCell
                        className='RightBorder'
                        align='left'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell49-${index}`}
                        sx={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 6,
                          backgroundColor: '#F1F3F5',
                        }}
                      >
                        <SimpleTooltip title={row.businessDivision}>
                            {row.businessDivision}
                          </SimpleTooltip>
                      </StyledTableCell>
                      <StyledTableCell
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell50-${index}`}
                        align='left'
                        className='ReportNumberTextColor'
                      >
                        {row.two_fy_ago_submitted === null
                          ? '0'
                          : row.two_fy_ago_submitted}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell51-${index}`}
                      >
                        {row.one_fy_ago_submitted === null
                          ? '0'
                          : row.one_fy_ago_submitted}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell52-${index}`}
                      >
                        {row.current_fy_submitted === null
                          ? '0'
                          : row.current_fy_submitted}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell53-${index}`}
                      >
                        {row.submitted_ytd_1}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell54-${index}`}
                      >
                        {row.submitted_ytd_2}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell55-${index}`}
                      >
                        {row.two_fy_ago_accepted === null
                          ? '0'
                          : row.two_fy_ago_accepted}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell56-${index}`}
                      >
                        {row.one_fy_ago_accepted === null
                          ? '0'
                          : row.one_fy_ago_accepted}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell57-${index}`}
                      >
                        {row.current_fy_accepted === null
                          ? '0'
                          : row.current_fy_accepted}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell58-${index}`}
                      >
                        {row.accepted_ytd_1}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell59-${index}`}
                      >
                        {row.accepted_ytd_2}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell60-${index}`}
                      >
                        {row.two_fy_ago_published === null
                          ? '0'
                          : row.two_fy_ago_published}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell61-${index}`}
                      >
                        {row.one_fy_ago_published === null
                          ? '0'
                          : row.one_fy_ago_published}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell62-${index}`}
                      >
                        {row.current_fy_published === null
                          ? '0'
                          : row.current_fy_published}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell63-${index}`}
                      >
                        {row.published_ytd_1}
                      </StyledTableCell>
                      <StyledTableCell
                        align='left'
                        className='RightBorder ReportNumberTextColor'
                        data-selenium-id={`Report_Articles-AunnalTrends-TableBody-StyledTableCell64-${index}`}
                      >
                        {row.published_ytd_2}
                      </StyledTableCell>
                    </TwoColorTableRowPurple>
                  );
                })
              ) : (
                <ReportNoData id='AcquiredAndActiveSiAnnualTrendsBDNoResultTableCell' />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TableBox2>
    );
  }
}

import {axios_instance,} from '../../../utils/axios_instance';
import {setSnackbarMessageAndOpen} from '../../SnackbarAction';
import {SEVERITIES} from '../../../modules/ConfirmSnackBar/CommonSnackBar';
import {actionWithNetworkStatusCreatorFactory, fakeAxiosSuccessResponse,} from '../../../utils/commonUtils';
import {
  sipReviseDataFromJEInitialState,
  sipReviseDataInitialState,
} from '@/pages/SIPPage/RevisePage/ProposerRevisePage';
import {sipEditorReviewInitialValue} from '@/pages/SIPPage/RevisePage/EditorReviewPage';
import {jpmReviewDataInitialValue} from '@/pages/SIPPage/RevisePage/JPMReviewPage';

export const UPDATE_EXTEND_DDL_VALUE = 'UPDATE_EXTEND_DDL_VALUE';
export const LOAD_SIP_STATUS = 'LOAD_SIP_STATUS';
export const LOAD_SIP_STATUS_COMPLETE = 'LOAD_SIP_STATUS_COMPLETE';
export const GET_SIP_STATUS = 'GET_SIP_STATUS';
export const GET_OBJECT = 'GET_OBJECT';
export const GET_SIP_INFORMATION = 'GET_SIP_INFORMATION';
export const GET_CONTENT_COMMISSIONING_APPROACHES =
  'GET_CONTENT_COMMISSIONING_APPROACHES';
export const GET_EBM_REVIEW_COMMENTS = 'GET_EBM_REVIEW_COMMENTS';
export const GET_GE_INFORMATION = 'GET_GE_INFORMATION';

export const SET_IS_ALL_SELECTED = 'SET_IS_ALL_SELECTED';
export const SET_ORDERBY = 'SET_ORDERBY';
export const LOAD_SIP_DATA = 'LOAD_SIP_DATA';
export const LOAD_SIP_CODES = 'LOAD_SIP_CODES';
export const LOAD_SIP_CODESBYDATA = 'LOAD_SIP_CODESBYDATA';
export const REMOVE_SELECTED_SIP = 'REMOVE_SELECTED_SIP';
export const ADD_SELECTED_SIP = 'ADD_SELECTED_SIP';
export const ADD_SELECTED_SIP_BATCH = 'ADD_SELECTED_SIP_BATCH';
export const REMOVE_SELECTED_ALL = 'REMOVE_SELECTED_ALL';
export const REMOVE_SELECTED_ALL_AND_PAGE_NUM = 'REMOVE_SELECTED_ALL_AND_PAGE_NUM';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_PAGE_NUM = 'SET_PAGE_Num';
export const GET_CURRENT_DECISION_TYPE = 'GET_CURRENT_DECISION_TYPE';
export const SET_IS_SIPOVERVIEWDATA_BY_PERMISSION =
  'SET_IS_SIPOVERVIEWDATA_BY_PERMISSION';
  
export const GET_DECISION_TYPE = 'GET_DECISION_TYPE';
export const GET_DECISION_CONFIG = 'GET_DECISION_CONFIG';
export const ASSIGN_TO_CE_SUBMITION_DECISION =
  'ASSIGN_TO_CE_SUBMITION_DECISION';
export const SET_SIP_EXPORT_STATE = 'SET_SIP_EXPORT_STATE';
export const SET_SIP_EXPORT_ALL_STATE = 'SET_SIP_EXPORT_ALL_STATE';
export const SET_POTENTIAL_AUTHOR_SELECTED_ROW =
  'SET_POTENTIAL_AUTHOR_SELECTED_ROW';
export const DOWNLOAD_CV = 'DOWNLOAD_CV';

export const GET_SIPDETAIL_OBJECT_CHOICE_JOURNAL_DROPLIST = 'GET_SIPDETAIL_OBJECT_CHOICE_JOURNAL_DROPLIST';
export const GET_SIPDETAIL_OBJECT_CHOICE_JOURNAL_DROPLIST_FOR_REVISE = 'GET_SIPDETAIL_OBJECT_CHOICE_JOURNAL_DROPLIST_FOR_REVISE';
export const GET_SIPDETAIL_OBJECT_PREFERRED_SUBJECT_AREA_DROPLIST =
  'GET_SIPDETAIL_OBJECT_PREFERRED_SUBJECT_AREA_DROPLIST';
export const SIPDETAIL_OBJECT_EDIT = 'SIPDETAIL_OBJECT_EDIT';

export const ADD_POTENTIAL_AUTHOR = 'ADD_POTENTIAL_AUTHOR';
export const EDIT_POTENTIAL_AUTHOR = 'EDIT_POTENTIAL_AUTHOR';
export const DELETE_POTENTIAL_AUTHOR = 'DELETE_POTENTIAL_AUTHOR';
export const UPLOAD_CV = 'UPLOAD_CV';
export const DELETE_CV = 'DELETE_CV';
export const INIT_CV = 'INIT_CV';
export const GET_GEINFOMATION_COUNTRY_DROPLIST =
  'GET_GEINFOMATION_COUNTRY_DROPLIST';
export const GET_GEINFOMATION_JOB_TITLE_DROPLIST =
  'GET_GEINFOMATION_JOB_TITLE_DROPLIST';
export const GET_REVISION_DDL = 'GET_REVISION_DDL';
export const GET_EXTEND_DDL = 'GET_EXTEND_DDL';
export const SET_CLICK_AFTER_SAVE = 'SET_CLICK_AFTER_SAVE';
export const SET_AUTHOR_NAME_ERROR = 'SET_AUTHOR_NAME_ERROR';
export const SET_AFFILIATION_ERROR = 'SET_AFFILIATION_ERROR';
export const SET_EMAIL_ERROR = 'SET_EMAIL_ERROR';
export const ADD_GEINFOMATION = 'ADD_GEINFOMATION';
export const DELETE_GEINFOMATION = 'DELETE_GEINFOMATION';
export const Edit_GEINFOMATION = 'Edit_GEINFOMATION';

export const GET_EMAIL_WITHDRAW_BODY = 'GET_EMAIL_WITHDRAW_BODY';
export const GET_EMAIL_REVISION_BODY = 'GET_EMAIL_REVISION_BODY';
export const SEND_EMAIREVISIONCODE = 'SEND_EMAIREVISIONCODE';
export const SEND_EMAIWITHDRAWCODE = 'SEND_EMAIWITHDRAWCODE';
export const Edit_SIP_INFOMATION = 'Edit_SIP_INFOMATION';
export const UPDATE_SIP_SHEVELD = 'UPDATE_SIP_SHEVELD';

export const SET_SIP_REVISE_DATA = 'SET_SIP_REVISE_DATA';
export const SET_SIP_EBM_REVIEW_DATA = 'SET_SIP_EBM_REVIEW_DATA';
export const SET_SIP_EBM_REVIEW_LOADING = 'SET_SIP_EBM_REVIEW_LOADING';
export const SET_DOWNLOAD_REVISE_DATA_STATUS =
  'SET_DOWNLOAD_REVISE_DATA_STATUS';
export const SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR =
  'SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR';
export const SET_UPLOAD_REVISE_DATA_STATUS = 'SET_UPLOAD_REVISE_DATA_STATUS';
export const SET_SAVING_REVISE_DATA_STATUS = 'SET_SAVING_REVISE_DATA_STATUS';
export const ADD_CV_TO_UPDATE = 'ADD_CV_TO_UPDATE';
export const SET_UPLOAD_CV_STATUS = 'SET_UPLOAD_CV_STATUS';
export const CLEAR_CV_TO_UPDATE = 'CLEAR_CV_TO_UPDATE';

export const SET_REVISE_FROM = 'SET_REVISE_FROM';
export const SET_REVISE_TO = 'SET_REVISE_TO';
export const SET_REVISE_SUBJECT = 'SET_REVISE_SUBJECT';
export const SET_REVISE_CC = 'SET_REVISE_CC';
export const SET_REVISE_BCC = 'SET_REVISE_BCC';
export const SET_WITHDRAW_ALLCONTENT = 'SET_WITHDRAW_ALLCONTENT';
export const SET_EMAIL_ALL = 'SET_EMAIL_ALL';
export const SET_SEND_EMAIL_CODE = 'SET_SEND_EMAIL_CODE';

export const SET_ACCEPT_INFORMATION = 'SET_ACCEPT_INFORMATION';
export const SET_GET_EMAIL_CODE = 'SET_GET_EMAIL_CODE';

// SIP Process log
export const LOAD_SIP_PROCESS = 'LOAD_SIP_PROCESS';

export const GET_ALL_TITER2JPM_INFO = 'GET_ALL_TITER2JPM_INFO';

export const SET_DECLINE_CODE = 'SET_DECLINE_CODE';
export const SET_ACCEPT_SUBMIT_CODE = 'SET_ACCEPT_SUBMIT_CODE';
export const SET_ACCEPT_SHOW_CODE = 'SET_ACCEPT_SHOW_CODE';

export const GET_SIP_EDITING_HISTORY = 'GET_SIP_EDITING_HISTORY';

export const GET_GE_EDITING_HISTORY = 'GET_GE_EDITING_HISTORY';

export const GET_EMAIL_HISTORY = 'GET_EMAIL_HISTORY';

export const GET_ATE_JOURNAL_DROPLIST = 'GET_ATE_JOURNAL_DROPLIST';

export const GET_USER_REJECT_REASON_DROPLIST =
  'GET_USER_REJECT_REASON_DROPLIST';

export const GET_APPROVEJOURNALLIST = 'GET_APPROVEJOURNALLIST';

export const CHECK_SIP_NON_CAT_JOURANL = 'CHECK_SIP_NON_CAT_JOURANL';

export const UPDATE_CV_UPLOADING_PROGRESS = 'UPDATE_CV_UPLOADING_PROGRESS';

export const GET_SIP_DETAIL_ALL_MANDATORY_SUBMIT_OR_NEXT_PRESSED = 'GET_SIP_DETAIL_ALL_MANDATORY_SUBMIT_OR_NEXT_PRESSED';

export const SET_TRANSFORM_TO_SI_GE_CHECK_INFO = 'SET_TRANSFORM_TO_SI_GE_CHECK_INFO';

export const GET_SIP_USERS = 'GET_SIP_USERS';

export const setTransformToSiGeCheckInfo =
  actionWithNetworkStatusCreatorFactory(
    /** @type {"SET_TRANSFORM_TO_SI_GE_CHECK_INFO"} */ (
      SET_TRANSFORM_TO_SI_GE_CHECK_INFO
    ),
    'pending',
    /** @type {import("@/pages/SIPPage/TransformToSI/Submodule/TransformSteps/DuplicateGeCheck").GeDiffDataVO | null} */ (
      null
    )
  );
export const SET_TRANSFORM_TO_SI_ACQUIRED_SI_INFO =
  'SET_TRANSFORM_TO_SI_ACQUIRED_SI_INFO';
export const setTransformToSiAcquiredSiInfo =
  actionWithNetworkStatusCreatorFactory(
    /** @type {"SET_TRANSFORM_TO_SI_ACQUIRED_SI_INFO"} */ (
      SET_TRANSFORM_TO_SI_ACQUIRED_SI_INFO
    ),
    'pending',
    /** @type {import("@/pages/SIPPage/TransformToSI/Submodule/TransformSteps/AcquiredSiCreation").AcquiredSiDetailVO | null} */ (
      null
    )
  );

export const SET_SYNC_GE_BETWEEN_SIP_AND_SIMT =
  'SET_SYNC_GE_BETWEEN_SIP_AND_SIMT';
export const setSyncGeBwteenSipAndSimt = actionWithNetworkStatusCreatorFactory(
  /** @type {"SET_SYNC_GE_BETWEEN_SIP_AND_SIMT"} */ (
    SET_SYNC_GE_BETWEEN_SIP_AND_SIMT
  ),
  /** @type {import("@/pages/SIPPage/TransformToSI").SyncGeBetweenSipAndSimtStatus} */ (
    'pending'
  ),
  /** @type {import("@/pages/SIPPage/TransformToSI/Submodule/TransformSteps/DuplicateGeCheck").GeInformationForTransform[]} */ ([])
);

export const CHANGE_SIP_OVERVIRW_COLUMNS = 'CHANGE_SIP_OVERVIRW_COLUMNS';
export const SIP_CHECKLIST_NOT_PROCEEDED_CODE = 16070;
export const SIP_CHECKLIST_ERROR_CODE_ARR = [16070, 16071, 16067, 16069]; //https://app.apifox.com/link/project/3300174/apis/doc-3017082
export const SIP_VERSION_ERROR = 16097;
export const EMAIL_DOMAIN_ERROR = 16057;
export const SET_SIP_SOURCE_OPTIONS = 'SET_SIP_SOURCE_OPTIONS';
export const CREATE_SIP = 'CREATE_SIP';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const AIACQUISITION_CREATE_SUCCESS = 'AIACQUISITION_CREATE_SUCCESS';

export const setIsAllSeleted = isAllSeleted => ({
  type: SET_IS_ALL_SELECTED,
  data: isAllSeleted,
});

export const setOrderBy = (orderType, orderField) => ({
  type: SET_ORDERBY,
  data: { orderType: orderType, orderField: orderField },
});

export const setIsSIPOverviewDataByPermission =
  isSIPOverviewDataByPermission => ({
    type: SET_IS_SIPOVERVIEWDATA_BY_PERMISSION,
    data: isSIPOverviewDataByPermission,
  });

export const addSelectedAll = list => ({
  type: ADD_SELECTED_SIP_BATCH,
  data: list,
});

export const removeSelectedAll = () => ({
  type: REMOVE_SELECTED_ALL,
  data: null,
});

export const removeSelectedAllAndPageNumber = () => ({
  type: REMOVE_SELECTED_ALL_AND_PAGE_NUM,
  data: null,
});

export const removeSelected = position => ({
  type: REMOVE_SELECTED_SIP,
  data: position,
});

export const addSelected = sipCode => ({
  type: ADD_SELECTED_SIP,
  data: sipCode,
});

export const setPageSize = pageSize => ({
  type: SET_PAGE_SIZE,
  data: pageSize,
});

export const setPageNum = pageNum => ({
  type: SET_PAGE_NUM,
  data: pageNum,
});

export const setCurrentDecisionType = decisionType => ({
  type: GET_CURRENT_DECISION_TYPE,
  data: decisionType,
});


export const ASSIGN_TO_REJECT_SUBMISSION_DECISION =
  'ASSIGN_TO_REJECT_SUBMISSION_DECISION';

/**
 *
 * @param newExtendDdlValue - 当更新extendDdl时将修改页面的状态extendDdl
 */
export function updateExtendDdlValue(newExtendDdlValue) {
  return {
    type: UPDATE_EXTEND_DDL_VALUE,
    data: newExtendDdlValue,
  };
}

export const clearEmailAllInformation = () => {
  return dispatch => dispatch({ type: SET_EMAIL_ALL, data: null });
};

// 清除邮件的返回状态
export const clearSendEmailCode = () => {
  return async dispatch => {
    dispatch({ type: SET_SEND_EMAIL_CODE, data: null });
  };
};

export const clearGetEmailCode = () => {
  return async dispatch => {
    dispatch({ type: SET_GET_EMAIL_CODE, data: null });
  };
};

export const changeEditAuthor = potentialAuthor => ({
  type: EDIT_POTENTIAL_AUTHOR,
  data: potentialAuthor,
});

export const changeAddAuthor = potentialAuthor => ({
  type: ADD_POTENTIAL_AUTHOR,
  data: potentialAuthor,
});

export const changeDeleteAuthor = author => ({
  type: DELETE_POTENTIAL_AUTHOR,
  data: author,
});

export const initCV = id => ({
  type: INIT_CV,
  data: id,
});

export const clearSIPSheveledCode = () => {
  return async dispatch => {
    dispatch({ type: UPDATE_SIP_SHEVELD, data: 0 });
  };
};

/**
 * 通过 token 加载 SIP Revise 的数据
 * @param {string} token
 * @param {import("@/pages/SIPPage/RevisePage").RevisePageType} dataType
 * @param {"run" | "test"} mode
 * @returns {(dispatch: import("redux").Dispatch) => Promise<void>}
 */
export const loadSIPReviseData = (token, dataType, mode = 'run') => {
  /**
   * @typedef {import("../../../pages/SIPPage/RevisePage/ProposerRevisePage").DownloadReviseDataStatus} DownloadReviseDataStatus
   * @typedef {import("../../../pages/SIPPage/RevisePage/ProposerRevisePage").DownloadReviseDataErrorType} DownloadReviseDataErrorType
   **/

  const getUrl = () => {
    // switch (dataType) {
    //   case 'ProposerRevise':
    //     return `revise/sip/${token}`;
    //   case 'ProposerReviseFromJE':
    //     return `revise/fromJE/${token}`;
    //   case 'JPMReview':
    //     return `JPMresubmit/view/${token}`;
    //   case 'EditorReview':
    //     return `sip/editorReview/JE/${token}`;
    // }
    // return `revise/sip/${token}`;
    return `/api/v1/sip-service/special-issue-proposals/revise/${token}`;
  };

  const getType = () => {
    switch (dataType) {
      case 'ProposerRevise':
        return `revise`;
      case 'ProposerReviseFromJE':
        return `fromJe`;
      case 'JPMReview':
        return `jpmResubmit`;
      case 'EditorReview':
        return `jeView`;
    }
    return `revise`;
  };

  return async dispatch => {
    try {
      /** @type {import('@/utils/axios-response').AxiosResponse<any, number>} */
      const res = await axios_instance.get(getUrl(),{params:{category : getType()}});

      if (mode === 'test') res.data.code = 200;

      if (res.data.code === 200) {
        if (mode === 'test') {
          switch (dataType) {
            case 'ProposerRevise':
              dispatch({
                type: SET_SIP_REVISE_DATA,
                /** @type {import("@/pages/SIPPage/RevisePage").SIPReviseData} */
                data: { type: dataType, ...sipReviseDataInitialState },
              });
              break;
            case 'ProposerReviseFromJE':
              dispatch({
                type: SET_SIP_REVISE_DATA,
                /** @type {import("@/pages/SIPPage/RevisePage").SIPReviseData} */
                data: { type: dataType, ...sipReviseDataFromJEInitialState },
              });
              break;
            case 'JPMReview':
              dispatch({
                type: SET_SIP_REVISE_DATA,
                /** @type {import("@/pages/SIPPage/RevisePage").SIPReviseData} */
                data: { type: dataType, ...jpmReviewDataInitialValue },
              });
              break;
            case 'EditorReview':
              dispatch({
                type: SET_SIP_REVISE_DATA,
                /** @type {import("@/pages/SIPPage/RevisePage").SIPReviseData} */
                data: { type: dataType, ...sipEditorReviewInitialValue },
              });
              break;
          }
        } else {
          switch (dataType) {
            case 'ProposerRevise':
            case 'ProposerReviseFromJE':
            case 'JPMReview':
            case 'EditorReview':
              dispatch({
                type: SET_SIP_REVISE_DATA,
                /** @type {import("@/pages/SIPPage/RevisePage").SIPReviseData} */
                data: { type: dataType, ...res.data.data },
              });
              break;
          }
        }

        dispatch({
          type: SET_DOWNLOAD_REVISE_DATA_STATUS,
          /** @type {DownloadReviseDataStatus} */ data: 'success',
        });
      } else if (res.data.code === 16032 || res.data.code === 16043) {
        dispatch({
          type: SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR,
          /** @type {DownloadReviseDataErrorType} */ data: 'has been submitted',
        });
      } else if (res.data.code === 16033) {
        dispatch({
          type: SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR,
          /** @type {DownloadReviseDataErrorType} */ data: 'deadline pass',
        });
      } else if (res.data.code === 16030 || res.data.code === 16041) {
        dispatch({
          type: SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR,
          /** @type {DownloadReviseDataErrorType} */ data: 'path not find',
        });
      } else if (res.data.code === 16039 || res.data.code === 16044) {
        dispatch({
          type: SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR,
          /** @type {DownloadReviseDataErrorType} */ data: 'shelved',
        });
      } else if (res.data.code === 16112) {
        dispatch({
          type: SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR,
          /** @type {DownloadReviseDataErrorType} */ data: 'initial review revision cancelled',
        });
      } else {
        dispatch({
          type: SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR,
          /** @type {DownloadReviseDataErrorType} */ data: 'otherError',
        });
      }
    } catch (err) {
      dispatch({
        type: SET_DOWNLOAD_REVISE_DATA_STATUS_ERROR,
        /** @type {DownloadReviseDataErrorType} */ data: 'otherError',
      });
      console.error(err);
    }
  };
};

export function clearProcessLog() {
  return dispatch => {
    dispatch({ type: LOAD_SIP_PROCESS, data: null });
  };
}

// export function getJournalListWithName(value, pageNum = 1) {
//   return async dispatch => {
//     try {
//       let params = paramsEncode({
//         pageNum: pageNum,
//         pageSize: 100,
//         journalName:value
//       });

//       const res = await axios_instance.get('/api/v1/si-service/journals/managed/codes-and-names', {
//         params: params,
//         headers: {
//           authorization: localStorage.getItem('authorization'),
//         },
//       });
//       if (res.data.code === 200) {
//         if (pageNum > 1) {
//           dispatch({ type: GET_MOREJOURNALLIST, data: res.data.data });
//         } else {
//           dispatch({ type: GET_JOURNALLIST, data: res.data.data });
//           dispatch({ type: GET_MOREJOURNALLIST, data: [] });
//         }
//       }
//     } catch {}
//   };
// }
// export function getUserRejectReasonDropList(value, pageNum = 1) {
//   return async dispatch => {
//     try {
//       let params = paramsEncode({
//         name: value,
//         pageNum: pageNum,
//         pageSize: 100,
//       });

/** @type {import("@/pages/SIPPage/TransformToSI").SyncGeBetweenSipAndSimt} */
export const syncGeBetweenSipAndSimt = (geData, mode = 'release') => {
  const getUrl = (geCode) => {
    if (geData.opType === 'create') return '/api/v1/si-service/guest-editors';
    else return `/api/v1/si-service/guest-editors/${geCode}?requestType=edit-guest-editor`;
  };
  // console.log(
  //   '[SyncGeBetweenSipAndSimt Action] [geData, mode] = ',
  //   geData,
  //   mode
  // );

  /**
   * @param {string[]} keywords
   * @returns {Promise<import("@/pages/GEPage/Overview/CreateGE/CreateGEContainer").ResearchKeyword[]>}
   */
  const getFullKeywords = async keywords => {
    /** @type {import("@/utils/axios-response").AxiosResponse<import("@/pages/GEPage/Overview/CreateGE/CreateGEContainer").ResearchKeyword[]>} */
    const res =
      mode === 'release'
        ? await axios_instance.post(
            '/api/v1/si-service/guest-editors/keywords',
            {
              keywords: keywords??[],
            },
            {
              headers: {
                authorization: localStorage.getItem('authorization') || '',
              },
            }
          )
        : await fakeAxiosSuccessResponse(
            keywords.map((keyword, index) => ({ id: index, keyword: keyword }))
          );

    const data = res.data;

    if (data.code === 200) {
      return Array.isArray(data.data) ? data.data : [data.data];
    }

    throw `Failed to get full Keywords List`;
  };

  return async dispatch => {
    try {
      /** @type {import("@/utils/axios-response").AxiosResponse<null>[]} */
      const results =
        mode === 'release'
          ? await Promise.all(
              geData.data.map(async (_, index) => {
                if (geData.opType === 'create') {
                  const dataItem = geData.data[index];
                  /** @type {import("@/pages/SIPPage/TransformToSI").GuestEditorCreateDto} */
                  const dataToPost = {
                    ...dataItem,
                    researchKeywords: await getFullKeywords(dataItem.researchKeywords),
                    researchKeywordsList:dataItem.researchKeywords,
                  };
                  return axios_instance.post(getUrl(dataItem.geCode), dataToPost, {
                    headers: {
                      authorization:
                        localStorage.getItem('authorization') || '',
                    },
                  });
                }
                /** @type {import("@/pages/SIPPage/TransformToSI").ResearchKeywordsToStringArray<import("@/pages/SIPPage/TransformToSI").GuestEditorUpdateDto>} */
                const dataItem = geData.data[index];
                /** @type {import("@/pages/SIPPage/TransformToSI").ResearchKeywordsToStringArray<import("@/pages/SIPPage/TransformToSI").GuestEditorUpdateDto>} */
                const dataToPost = {
                  ...dataItem,
                  researchKeywords: (
                    await getFullKeywords(dataItem.researchKeywords)
                  ).map(item => item.keyword),
                };
                /** @type {import("@/pages/SIPPage/TransformToSI").GuestEditorCreateDto} */
                return axios_instance.put(getUrl(dataItem.geCode), dataToPost, {
                  headers: {
                    authorization: localStorage.getItem('authorization') || '',
                  },
                });
              })
            )
          : await Promise.all(
              geData.data.map(() => fakeAxiosSuccessResponse(null, 1000))
            );
      if (!results.some(result => result.data.code !== 200)) {
        if (geData.opType === 'create') {
          dispatch(setSyncGeBwteenSipAndSimt('createSuccess'));
        } else {
          dispatch(setSyncGeBwteenSipAndSimt('updateSuccess'));
        }
      } else {
        const failedRes = results.find(result => result.data.code !== 200);
        if (geData.opType === 'create') {
          dispatch(setSyncGeBwteenSipAndSimt('createError'));
          if (failedRes) {
            dispatch(
              setSnackbarMessageAndOpen(
                'gePage.createError',
                {
                  value: failedRes.data.message,
                },
                SEVERITIES.error
              )
            );
          }
        } else {
          dispatch(setSyncGeBwteenSipAndSimt('updateError'));
          if (failedRes) {
            dispatch(
              setSnackbarMessageAndOpen(
                'gePage.updateError',
                {
                  value: failedRes.data.message,
                },
                SEVERITIES.error
              )
            );
          }
        }
      }
    } catch (err) {
      if (geData.opType === 'create') {
        dispatch(setSyncGeBwteenSipAndSimt('createError'));
      } else {
        dispatch(setSyncGeBwteenSipAndSimt('updateError'));
      }
      dispatch(setSnackbarMessageAndOpen(`${err}`, {}, SEVERITIES.error));
      console.error(err);
    }
  };
};

export function clearCreateSIPState() {
  return async dispatch => {
    dispatch({ type: CREATE_SIP, data: 'UNDO' });
  };
}

export function setCreateSuccessAlert(open){
  return async dispatch => {
    dispatch({ type: CREATE_SUCCESS, data: open });
  };
}

export function setAIAcquisitionCreateSuccessAlert(open,sum){
  return async dispatch => {
    dispatch({ type: AIACQUISITION_CREATE_SUCCESS, data: {open:open,sum:sum} });
  };
}

export function setSipDetailAllMandatorySubmitOrNextPressed(flag){
  return async dispatch => {
    dispatch({ type: GET_SIP_DETAIL_ALL_MANDATORY_SUBMIT_OR_NEXT_PRESSED, data: flag});
  };
}

// /**
//  * @description 在Decision部分，下拉框选择后的判断
//  * @param {string} sipCode
//  * @param {(e:boolean)=>void?} resolve
//  */
// export const checkProposerOKForDecision=(sipCode='',resolve=_e=>{})=>axios_instance.get('/sip/detail/checkProposerOK',{params:{sipCode},headers: {
//   authorization: localStorage.getItem('authorization')??'',
// },}).then(
//   /**
//   * @param {import('axios').AxiosResponse<{readonly code?: number;readonly status?: number;readonly message?: string;readonly data:boolean;}>} res
//   */
//   res=>{
//   if(res?.status===200){
//     const data=res?.data;
//     if (
//       data?.code === 200 ||
//   data?.status === 200 ||
//   data?.message === 'OK'
//     ) {
//       const value=data?.data;
//       resolve(value);
//       return value;
//     }
//   }
//   alert('some error occur');
// }).catch(console.error);

import { TextField } from '@mui/material';
import { styled } from '@mui/system';
import MyTextField from '../../../../components/MyTextField';

export const CustomTextField = styled(MyTextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
    boxSizing: 'border-box',
    minHeight: '33px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#262E35',
    // padding: "0px",
    paddingLeft: '14px',
    paddingRight: '14px',
    '& fieldset, &.Mui-disabled fieldset': {
      border: '1px solid #DFE4E8',
    },
    '& input': {
      padding: 0,
    },
  },
}));

import { Stack, Typography, Box, IconButton } from '@mui/material';
// @ts-ignore
import OverwriteIcon from '@/assets/SIPPage/TransformToSI/OverwriteIcon.svg';
import React from 'react';
import styleModule from './CustomComponent.module.scss';

/**
 *
 * @param {import("./CustomBlock").TranformToSiItemBlockProps} props
 * @returns
 */
export const TranformToSiItemBlock = props => {
  const {
    title,
    titleBoxProps = {},
    content,
    contentBoxProps = {},
    sx,
    ...others
  } = props;

  const { sx: titleBoxSx, ...titleBoxOthers } = titleBoxProps;

  /**
   *
   * @param {string | number | boolean | string[] | null} value
   */
  const contentToString = value => {
    if (typeof content === 'string' || typeof content === 'number')
      return `${content}`;
    else if (typeof content === 'boolean') return content ? 'Yes' : 'No';
    else if (content === null) return '';
    return content.join(', ');
  };

  return (
    <Stack
      data-selenium-id='SIPPage_TransformToSl-CustomComponent-Block-Stack'
      direction={'row'}
      spacing={'68px'}
      sx={{
        width:'514px',
        '& .MuiTypography-root': {
          fontFamily: 'Open Sans',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          '&.TranformToSiItemBlock': {
            '&-title': {
              color: '#596A7C',
            },
            '&-content': {
              color: '#262E35',
              wordBreak: 'break-all',
            },
          },
        },
        ...sx,
      }}
      {...others}
    >
      <Box
        minWidth={'160px'}
        sx={{ flexShrink: 0, ...titleBoxSx }}
        {...titleBoxOthers}
      >
        <Typography
          className='TranformToSiItemBlock-title'
          data-selenium-id='SIPPage_TransformToSl-CustomComponent-Block-Box-TitleTypography'
        >
          {title}:
        </Typography>
      </Box>
      <Box
        {...contentBoxProps}
        className={styleModule.CustomComponentBlockStack}
      >
        <Typography
          className='TranformToSiItemBlock-content'
          data-selenium-id='SIPPage_TransformToSl-CustomComponent-Block-Box-ContentTypography'
        >
          {content}
        </Typography>
      </Box>
    </Stack>
  );
};

/**
 *
 * @param {import("./CustomBlock").GEInformationCheckItemBlockProps} props
 * @returns
 */
export const GEInformationCheckItemBlock = props => {
  const { handleMerge, title, content, diffHighlight } = props;
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <TranformToSiItemBlock
        title={title}
        content={content}
        className={`${
          diffHighlight ? 'GEInformationCheckItemBlock-diffHighlight' : ''
        }`}
        sx={{
          '&.GEInformationCheckItemBlock-diffHighlight .MuiTypography-root': {
            color: '#154AB6',
            fontWeight: 600,
          },
        }}
      />
      {diffHighlight && (
        <IconButton
          data-selenium-id='SIPPage_TransformToSl-CustomComponent-CheckItemBlock-IconButton'
          sx={{
            position: 'absolute',
            left: '-30px',
            top: '0px',
            color: 'transparent',
            padding:'0px',
            '&:hover': {
              //modified by HJX
              backgroundColor: 'transparent',
              '&>svg>path:first-child': {
                fill: '#113D95',
              },
            },
          }}
          onClick={handleMerge}
        >
          <OverwriteIcon data-selenium-id='SIPPage_TransformToSl-CustomComponent-CheckItemBlock-OverwriteIcon' />
        </IconButton>
      )}
    </Box>
  );
};

//The action to the user(resend, reset, delete)
import { axios_instance } from "@/utils/axios_instance";
import {
    BTACH_UPDATE_RESULT,
    CHECK_USER_EMAIL,
    CREATE_USER,
    EDIT_USER,
    GET_ALL_USER_IDS,
    GET_ALL_USER_INFO,
    INACTIVE_USER,
    UNLOCK_USER,
    UPDATE_USER
} from "@/actions/SIMT-User/User/UserAction";
import { paramsEncode } from "@/utils/commonUtils";
import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";

import simpleDisplayError from "@/utils/simpleDisplayError";
import { getRoleInfo } from "../Role";
const { stringify, parse } = JSON;
/**@typedef {import("redux").Dispatch} Dispatch*/
/**@typedef {import("./SystemAdmin").SystemAdminRequest} SystemAdminRequest*/
/**
 * @param {SystemAdminRequest} props
 */
export const getUserService = (props) =>
    /**
     * @param {Dispatch} dispatch 
     */
    dispatch => axios_instance.get('/api/v1/user-service/users', {
        params: { ...props, linkInfo: true, type: 'userOverview' },
        headers: {
            authorization: localStorage.getItem('authorization') ?? '',
        },
    }).then(
        /**
         * @param {import("@/utils/axios-response").AxiosResponse<import("./SystemAdmin").SystemAdminResponse>} res 
         */
        res => {
            const { code, data } = res?.data ?? {};
            if (res.status !== 200 || code !== 200) {
                return Promise.reject();
            }
            return data;
        }).catch(e => {
            console.error(e);
            simpleDisplayError(e)(dispatch);
        });
        
export const getHandlingCeUserService = (props) =>
    // To update: Use new API version
    /**
     * @param {Dispatch} dispatch
     */
    dispatch => axios_instance.get('/api/v1/user-service/users', {
        params: { ...props },
        headers: {
            authorization: localStorage.getItem('authorization') ?? '',
        },
    }).then(
        /**
         * @param {import("@/utils/axios-response").AxiosResponse<import("./SystemAdmin").SystemAdminResponse>} res
         */
        res => {
            const { code, data } = res?.data ?? {};
            if (res.status !== 200 || code !== 200) {
                return Promise.reject();
            }
            return data;
        }).catch(e => {
            console.error(e);
            simpleDisplayError(e)(dispatch);
        });

/**
 * @param {SystemAdminRequest} props
 */
export const/**@type {import('./SystemAdmin').getAllUserInfoByUserService}*/
    getAllUserInfoByUserService = (props) =>
        /**
         * @param {Dispatch} dispatch 
         */
        dispatch => getUserService(props)(dispatch).then(async data => {
            if (!data) {
                dispatch({ type: GET_ALL_USER_IDS, data: [] });
                dispatch({ type: GET_ALL_USER_INFO, data: null });
                return;
            }
            const { total, result, count } = data;
            // dispatch({ type: GET_ALL_USER_IDS, data: result?.map(e => e.userId) });
            const getRoleNumEqual = result?.filter(item => item.role).length === count;
            const key = 'RoleInfo';
            const sessionStorageGetRoleList = sessionStorage.getItem(key);
            const/**@type {import("./SystemAdmin").getRoleInfoResult}*/getRoleList = getRoleNumEqual ? [] : (sessionStorageGetRoleList ? parse(sessionStorageGetRoleList) : (await getRoleInfo(0, 0, 'roleName', 'asc')(dispatch).then(e => {
                const { result } = e;
                sessionStorage.setItem(key, stringify(result));
                return result;
            }).catch(console.error)));
            const adapterData = {
                ...data,
                count: total,
                result: result?.map(item => {
                    const { username, hasJournal, hasOptInJournal, hasSi, hasSip, role, roleId, activeStatus } = item;
                    return ({
                        ...item,
                        fullName: username,
                        hasJournal: hasJournal ?? 0,
                        hasOptInJournal: hasOptInJournal ?? 0,
                        hasSi: hasSi ?? 0,
                        hasSip: hasSip ?? 0,
                        // lock
                        role: role ?? getRoleList.find(item => item.roleId === roleId)?.roleName,
                        status: Number(activeStatus) ? 'Active' : 'Inactive',
                    });
                })
            };
            dispatch({ type: GET_ALL_USER_INFO, data: adapterData });
            return adapterData;
        }).catch((e) => { dispatch({ type: GET_ALL_USER_IDS, data: [] }); dispatch({ type: GET_ALL_USER_INFO, data: null }); console.error(e); });

/**
 * @param {SystemAdminRequest} props
 */
export const checkUserEmailByUserService = (props) =>
    /**
     * @param {Dispatch} dispatch 
     */
    dispatch => getUserService(props)(dispatch).then(data => {
        const checkUserEmail = Boolean(data?.count);
        dispatch({
            type: CHECK_USER_EMAIL,
            data: checkUserEmail,
        });
        return checkUserEmail;
    }).catch(() => {
        dispatch({
            type: CHECK_USER_EMAIL,
            data: false,
        });
    });
// export function editUser(email, type) {
//     return async dispatch => {
//         try {
//             let url;
//             switch (type) {
//                 case 'deleteUser': //remove specified user
//                     url = `/system/users/deleteNormalUsers`;
//                     break;
//                 case 'resendInvitationToUser': //Send activation emails to specified users
//                     url = `/system/users/resendActiveMail`;
//                     break;
//                 case 'resetUserPass':
//                     url = `/system/users/resetNormalUser`;
//                     break;
//                 default:
//                     url = null;
//                     break;
//             }

//             const res = await axios_instance.get(url, {
//                 params: {
//                     email: email,
//                 },
//                 headers: {
//                     authorization: localStorage.getItem('authorization'),
//                 },
//             });

//             if (res.data.code === 200) {
//                 dispatch({ type: EDIT_USER, data: true });
//                 // dispatch(setSnackbarMessageAndOpen("user.success", {}, SEVERITIES.success));
//             } else if (
//                 res.data.code === 500 &&
//                 (res.data.message === 'SIAssociated' ||
//                     res.data.message == 'PreSIAssociated')
//             ) {
//                 dispatch({ type: EDIT_USER, data: 'SIAssociated' });
//             } else {
//                 dispatch({ type: EDIT_USER, data: false });
//             }
//         } catch (err) {
//             dispatch({ type: EDIT_USER, data: err.response.data.msg });
//         }
//     };
// }
//delete a user by userId
//old api:Get /system/users/deleteNormalUsers
export function deleteUser(userId) {
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/users/${userId}`;
            const res = await axios_instance.delete(url, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                    fullName: 'Tao, HUang'
                },
            });
            if (res.data.code === 200) {
                dispatch({ type: EDIT_USER, data: true });
                // dispatch(setSnackbarMessageAndOpen("user.success", {}, SEVERITIES.success));
            } else if (
                res.data.code === 500 &&
                (res.data.message === 'SIAssociated' ||
                    res.data.message == 'PreSIAssociated')
            ) {
                dispatch({ type: EDIT_USER, data: 'SIAssociated' });
            } else {
                dispatch({ type: EDIT_USER, data: false });
            }
        } catch (err) {
            dispatch({ type: EDIT_USER, data: err.response?.data.msg });
        }
    };
}
//system send active mail to normal user by userId
//old api:Get /system/users/resendActiveMail
export function sendActiveEmail(userId) {
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/users/${userId}/active`;
            const res = await axios_instance.put(url, null, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                    fullName: 'Tao, HUang'
                },
            });
            if (res.data.code === 200) {
                dispatch({ type: EDIT_USER, data: true });
                // dispatch(setSnackbarMessageAndOpen("user.success", {}, SEVERITIES.success));
            } else if (
                res.data.code === 500 &&
                (res.data.message === 'SIAssociated' ||
                    res.data.message == 'PreSIAssociated')
            ) {
                dispatch({ type: EDIT_USER, data: 'SIAssociated' });
            } else {
                dispatch({ type: EDIT_USER, data: false });
            }
        } catch (err) {
            dispatch({ type: EDIT_USER, data: err.response?.data.msg });
        }
    };
}
//Get all user information
export function getAllUserInfo(
    page,
    pageSize,
    username,
    role,
    group,
    accStatus,
    order,
    orderBy
) {
    let params = paramsEncode({
        pageNum: page,
        pageSize: pageSize,
        name: username,
        roleName: role,
        group: group,
        activeStatus: accStatus,
        orderField: orderBy,
        orderBy: order,
    });
    return async dispatch => {
        try {
            const res = await axios_instance.get('/system/users', {
                params: params,
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            if (res.data.code === 200) {
                dispatch({ type: GET_ALL_USER_INFO, data: res.data.data });
            } else {
                dispatch({ type: GET_ALL_USER_INFO, data: null });
            }
        } catch (err) {
            dispatch({ type: GET_ALL_USER_INFO, data: err.response.data.msg });
        }
    };
} //Get all user information
export function getAllUserInfoIds(
    username,
    role,
    group,
    accStatus
) {
    let params = {
        fullName: username,
        roleId: role,
        group: group,
        active: accStatus === 1 ? true : accStatus === 0 ? false : undefined,
        ids: true,
    };
    return async dispatch => {
        try {
            const res = await axios_instance.get('/api/v1/user-service/users', {
                params: params,
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            if (res.data.code === 200) {
                dispatch({ type: GET_ALL_USER_IDS, data: res.data.data });
            } else {
                dispatch({ type: GET_ALL_USER_IDS, data: [] });
            }
        } catch (err) {
            dispatch({ type: GET_ALL_USER_IDS, data: [] });
        }
    };
}
// //Create a new user
// export function createUser(FirstName, LastName, email, Group, Role) {
//     return async dispatch => {
//         try {
//             let url = '/system/users/createNormalUser';

//             const result = await axios_instance.post(
//                 url,
//                 {
//                     firstName: FirstName,
//                     lastName: LastName,
//                     email: email,
//                     group: Group,
//                     roleId: Role,
//                 },
//                 {
//                     headers: {
//                         Authorization: localStorage.getItem('authorization'),
//                     },
//                 }
//             );

//             if (result.data.code === 200) {
//                 dispatch({ type: CREATE_USER, data: 'true' });
//                 dispatch(
//                     setSnackbarMessageAndOpen(
//                         'user.createSucc',
//                         { fullname: `${LastName}, ${FirstName}` },
//                         SEVERITIES.success
//                     )
//                 );
//             } else {
//                 if (result.data.code === 500) {
//                     if (result.data.message === 'the email address has been registered')
//                         //判断是否是邮箱存在错误
//                         dispatch({ type: CREATE_USER, data: 'EmailExit' });
//                 } else {
//                     dispatch({ type: CREATE_USER, data: 'error' });
//                 }
//             }
//         } catch (err) {
//             dispatch({ type: CREATE_USER, data: err.response.data.msg });
//         }
//     };
// }
//system admin creat normal user
//old api:Post /system/users/createNormalUser
export function createUser(FirstName, LastName, email, Group, Role) {
    return async dispatch => {
        try {
            const url = '/api/v1/user-service/users';

            const result = await axios_instance.post(
                url,
                {
                    firstName: FirstName,
                    lastName: LastName,
                    email: email,
                    group: Group,
                    roleId: Role,
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                        fullname: 'Tao, HUang'
                    },
                }
            );

            if (result.data.code === 200) {
                dispatch({ type: CREATE_USER, data: 'true' });
                dispatch(
                    setSnackbarMessageAndOpen(
                        'user.createSucc',
                        { fullname: `${LastName}, ${FirstName}` },
                        SEVERITIES.success
                    )
                );
            } else {
                if (result.data.code === 500) {
                    if (result.data.message === 'the email address has been registered')
                        //判断是否是邮箱存在错误
                        dispatch({ type: CREATE_USER, data: 'EmailExit' });
                } else {
                    dispatch({ type: CREATE_USER, data: 'error' });
                }
            }
        } catch (err) {
            dispatch({ type: CREATE_USER, data: err.response.data.msg });
        }
    };
}
//Update a user
export function updateUser(FirstName = '', LastName = '', email = '', Group = 0, RoleId = 0, roleName = '', userId = 0) {
    return async dispatch => {
        try {
            let url = `/api/v1/user-service/users/${userId}`;
            let result = await axios_instance.put(
                url,
                {
                    firstName: FirstName,
                    lastName: LastName,
                    email: email,
                    // group: Group,
                    roleId: Number(RoleId),
                    "userId": Number(userId),
                    "name": `${LastName}, ${FirstName}`,
                    roleName,
                    // "isResetPassword": true,
                    // "roleId": {
                    //   "id": Number(RoleId),
                    //   "name": roleName,
                    // "permissions": [
                    //   "string"
                    // ]
                    // },
                    "groupId": Number(Group)
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );

            if (result.data.code === 200) {
                dispatch({ type: UPDATE_USER, data: 'true' });
            } else {
                if (result.data.code === 500) {
                    if (result.data.message === 'EmailExit')
                        //判断是否是邮箱存在错误
                        dispatch({ type: UPDATE_USER, data: 'EmailExit' });
                } else {
                    dispatch({ type: CREATE_USER, data: 'error' });
                }
            }
        } catch (err) {
            dispatch({ type: UPDATE_USER, data: err.response.data.msg });
        }
    };
}
//Inactive a user
// export function inactiveUser(email) {
//     return async dispatch => {
//         try {
//             let res = await axios_instance.get(
//                 `/system/users/inactivateNormalUser?email=` + email,
//                 {
//                     headers: {
//                         authorization: localStorage.getItem('authorization'),
//                     },
//                 }
//             );

//             if (res.data.code === 200) {
//                 dispatch({ type: INACTIVE_USER, data: 'OK' });
//             } else if (res.data.message === 'can not inactivate handling ce') {
//                 dispatch({ type: INACTIVE_USER, data: 'HEInactive' });
//             } else {
//                 dispatch({ type: INACTIVE_USER, data: false });
//             }
//         } catch (err) {
//             dispatch({ type: INACTIVE_USER, data: err.response.data.msg });
//         }
//     };
// }

//system admin inactivate a normal user by userId
//old api:Get /system/users/inactivateNormalUser
export function inactiveUser(userId) {
    return async dispatch => {
        try {
            const url = `/api/v1/user-service/users/${userId}/inactive`;
            let res = await axios_instance.put(
                url, null,
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                        fullName: 'Tao, HUang'
                    },
                }
            );

            if (res.data.code === 200) {
                dispatch({ type: INACTIVE_USER, data: 'OK' });
            } else if (res.data.message === 'can not inactivate handling ce') {
                dispatch({ type: INACTIVE_USER, data: 'HEInactive' });
            } else {
                dispatch({ type: INACTIVE_USER, data: false });
            }
        } catch (err) {
            console.log('err', err);
            dispatch({ type: INACTIVE_USER, data: err.data.message });
        }
    };
}
//Unlock a User
export function unlockUser(email) {
    return async dispatch => {
        try {
            let res = await axios_instance.get(
                `/system/users/unlockNormalUser?email=` + email,
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );

            if (res.data.code === 200) {
                dispatch({ type: UNLOCK_USER, data: true });
            } else {
                dispatch({ type: UNLOCK_USER, data: false });
            }
        } catch (err) {
            dispatch({ type: UNLOCK_USER, data: err.response.data.msg });
        }
    };
}

export function batchUpdateUser(users = '', role = '', group = '') {  //https://app.apifox.com/project/3824051/apis/api-138249620
    return async dispatch => {
        try {
            let url = 'api/v1/user-service/users';
            let res = await axios_instance.put(url, {
                userCodes: users.split(',').map(i => Number(i)),
                group: group,
                role: role,
            },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                });
            if (res.data.code === 200) {
                dispatch({ type: BTACH_UPDATE_RESULT, data: 'true' });
            } else {
                dispatch({ type: BTACH_UPDATE_RESULT, data: 'error' });
            }
        } catch (err) {
            dispatch({ type: BTACH_UPDATE_RESULT, data: err.response.data.msg });
        }
    };
}

/**创建User检查Email */
export function checkUserEmail(email) {
    return async dispatch => {
        try {
            let url = '/system/users/checkUserEmail?email=' + email;
            let res = await axios_instance.get(url, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code == 500 && res.data.message === 'Existing Email') {
                dispatch({
                    type: CHECK_USER_EMAIL,
                    data: true,
                });
            } else {
                dispatch({ type: CHECK_USER_EMAIL, data: false });
            }
        } catch (err) {
            // dispatch({ type: GET_ADMIN_INFO, data: err.response.data.msg });
        }
    };
}
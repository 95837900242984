import React from 'react';
import {
  Backdrop,
  backdropClasses,
  Box,
  Fade,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExportAllCloseIcon from '@/assets/ExportAllCloseIcon.svg';
import { styled } from '@mui/material/styles';

export const BlueBackdrop = styled(Backdrop)(() => ({
  [`&.${backdropClasses.root}`]: {
    background: ' rgba(17, 61,149, 0.5)',
    backdropFilter: 'blur(4px)',
  },
}));

const SIColumnsModalPro = props => {
  const {
    open = false, // 是否打开模态框
    handleClose = () => {}, // 关闭模态框的回调函数

    //title = '', // 模态框标题

    width = '600px', // 模态框宽度
    height = '600px', // 模态框高度
    backgroundColor = '#fff', // 背景颜色

    children, // 模态框的主体内容

    sx = {}, // 这个 sx 将被应用到 MUI 提供的 Modal 的 sx 中
    boxSx = {}, // 模态框最外层是一个 Box，boxSx 和其他非 sx 的 props 将被添加到这个 Box 中
    ...others
  } = props;

  const boxStyle = {
    padding: '0px 36px 24px 41px',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translate(0, -50%)',

    width: width,
    height: height,
    maxHeight: '100%',
    backgroundColor: backgroundColor,
    boxSizing: 'border-box',
    ...boxSx,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      components={{
        Backdrop: BlueBackdrop,
      }}
      slotProps={{
        backdrop: {
          onClick: null, // 禁用点击背景关闭 Modal
        },
      }}
      sx={{
        ...sx,
        zIndex: 1500,
      }}
    >
      <Fade in={open}>
        <Box
          className='SIModalPro-container'
          sx={{ width: '100%', ...boxStyle }}
          {...others}
        >
          <Stack sx={{ position: 'relative', height: '100%' }}>
            <Box>
            </Box>
            {children}
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SIColumnsModalPro;

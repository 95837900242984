import * as React from 'react';
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import PrintIcon from '@/assets/pointIcon.svg';
import Chip from '@mui/material/Chip';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import WarnOnlyJournalName from '@/assets/SIPPage/onlyJournalName.svg';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import ExpandIcon from '@/assets/ExpandIcon.svg';
import ExpandIcon_2 from '@/assets/ExpandIcon_2.svg';
import SimpleTooltip from '@/componentsphase2/SimpleTooltip';

export default function JournalScopeCheckBox(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const handleMouseEnter = index => {
    setHoveredItem(index);
  };
  const handleMouseLeave = () => {
    setHoveredItem(null);
  };
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const {
    journalName,
    PKGJournalLink, //不知是否带http(s)://开头，建议自行判断
    matchScore,
    ISSN,
    numberOfPublications, //自行判断是否>=5
    publications,
  } = props;
  const Accordion = styled(props => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    borderRadius: '5px',
  }));

  const AccordionSummary = styled(props => <MuiAccordionSummary {...props} />)(
    ({ theme }) => ({
      overflow: 'hidden',
      color: onlyJournalName
        ? 'var(--gray-600, #262E35)'
        : 'var(--primary-500, #154AB6)',
      textOverflow: 'ellipsis',
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      marginTop: '6px',
      marginLeft: '28px',
      textDecoration: 'none',
      flexDirection: 'row-reverse',
      height: '74px',
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
      '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
      },
    })
  );

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
  }));
  const onlyJournalName =
    PKGJournalLink === undefined &&
    matchScore === undefined &&
    ISSN === undefined &&
    numberOfPublications === undefined &&
    publications === undefined;
  const haveJournalName = journalName !== undefined;
  useEffect(() => {
    if (onlyJournalName || !haveJournalName) {
      setExpanded(false);
    }
  }, [expanded]);

  const summaryFirstStyle = {
    overflow: 'hidden',
    color: onlyJournalName
      ? 'var(--gray-600, #262E35)'
      : 'var(--primary-500, #154AB6)',
    textOverflow: 'ellipsis',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    height: '22px',
    marginTop: '6px',
    textDecoration: 'none',
  };
  const summarySecondStyle = {
    overflow: 'hidden',
    color: 'var(--gray-500, #596A7C)',
    textOverflow: 'ellipsis',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '120%',
    letterSspacing: '-0.154px',
    marginBottom: '6px',
    marginTop: '4.5px',
  };
  const detailTitleStyle = {
    color: 'var(--gray-600, #262E35)',
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
  };
  const detailTitleTagStyle = {
    color: 'var(--gray-500, #596A7C)',
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginLeft: '2px',
  };
  const linkStyle = {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    textDecoration: 'none',
    marginLeft: '4px',
    marginTop: '4px',
    paddingTop: '-52px',
  };
  const chipStyle = {
    borderRadius: '4px',
    border: '1px solid var(--gray-300, #BCC5CF)',
    background: 'var(--gray-50, #F6F7F8)',
    gap: '2px',
    marginLeft: '12px',
    marginTop: '6px',
    padding: '2px 0px',
    color: 'var(--gray-500, #596A7C)',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    display: 'flex',
    alignItems: 'center',
    height: '22px',
  };
  const haveNoJournalNameStyle = {
    color: 'var(--gray-500, #596A7C)',
    fontFamily: 'Open Sans',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    padding: '24px 256px',
  };

  const RowTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: 'var(--gray-500, #596A7C)',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      display: 'flex',
      padding: '8px 16px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderRadius: '5px',
      background: 'var(--gray-500, #596A7C)',
      width: '200 0px',
    },
  }));
  const tooltipText = ' Could not match journal. This may be a new journal.';
  const containerRef = useRef(null);
  const [scaleToUse, setScaleToUse] = useState(1);
  return (
    <div
      className='container'
      style={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '5px',
        minWidth: '624px',
        maxWidth: '920px',
        background: 'var(--shades-white, #FFF)',
        boxShadow: '0px 2px 8px 0px rgba(38, 46, 53, 0.10)',
        ...{
          transform: `scale(${scaleToUse})`,
          transformOrigin: 'left bottom',
        },
      }}
      className='container zoom-limited'
    >
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={onlyJournalName ? null : handleChange('panel1')}
      >
        <AccordionSummary aria-controls='panel1bh-content' id='panel1bh-header'>
          {haveJournalName && (
            <div
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '22px',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {!onlyJournalName && (
                    <a
                      href={PKGJournalLink}
                      style={summaryFirstStyle}
                      target='_blank'
                      onClick={event => event.stopPropagation()}
                    >
                      {journalName}
                    </a>
                  )}
                  {onlyJournalName && (
                    <div style={{ marginTop: '-13px' }}>{journalName}</div>
                  )}
                  {!onlyJournalName && (
                    <Chip label={`${matchScore}%`} sx={chipStyle} />
                  )}
                  {onlyJournalName && (
                    <SimpleTooltip
                      title={tooltipText}
                      arrow
                      placement='top'
                      sx={{
                        [`& .${tooltipClasses.arrow}`]: {
                          color: 'var(--gray-500, #596A7C)',
                        },
                        [`& .${tooltipClasses.tooltip}`]: {
                          display: 'flex',
                          padding: '8px 16px',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'stretch',
                          borderRadius: '5px',
                          background: 'var(--gray-500, #596A7C)',
                          width: '210px',
                          color: 'var(--shades-white, #FFF)',
                          textAlign: 'center',
                          fontFamily: 'Open Sans',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '20px',
                          marginBottom: '7px !important',
                        },
                      }}
                    >
                      <WarnOnlyJournalName
                        style={{ marginLeft: '12px', marginTop: '-10px' }}
                      />
                    </SimpleTooltip>
                  )}
                </div>
                {!onlyJournalName && haveJournalName && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'absolute',
                      right: '33px',
                    }}
                  >
                    {expanded ? (
                      <>
                        <ExpandIcon />
                      </>
                    ) : (
                      <ExpandIcon_2 />
                    )}
                  </div>
                )}
              </div>
              <Typography sx={{ overflow: summarySecondStyle }}>
                {ISSN}
              </Typography>
            </div>
          )}

          {!haveJournalName && (
            <div
              style={{
                marginTop: '-11px',
                marginLeft: '-40px',
                marginBottom: '-5px',
              }}
            >
              <Typography
                sx={{ overflow: haveNoJournalNameStyle, whiteSpace: 'nowrap' }}
              >
                {'The proposer(s) did not select any journal.'}
              </Typography>
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: 'flex',
              padding: '8px 12px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              borderRadius: '4px',
              background: 'var(--gray-100, #F1F3F5)',
              marginTop: '-24px',
              marginLeft: '34px',
              marginRight: '50px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '8px',
              }}
            >
              <Typography sx={{ overflow: detailTitleStyle }}>
                Relevant Publications
              </Typography>
              <Typography sx={{ overflow: detailTitleTagStyle }}>
                (
                {numberOfPublications >= 5
                  ? `Top 5 out of ${numberOfPublications} relevant publications`
                  : numberOfPublications}
                )
              </Typography>
            </div>
            {publications &&
              publications.length > 0 &&
              publications.slice(0, 5).map((item, index) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ flexShrink: 0 }}>
                    <PrintIcon />
                  </div>
                  <a
                    ref={containerRef}
                    key={index}
                    href={item.url}
                    style={{
                      ...linkStyle,
                      color:
                        hoveredItem === index
                          ? ' var(--primary-600, #113D95)'
                          : 'var(--primary-500, #154AB6)',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    target='_blank'
                  >
                    {((item.title?.length ?? 0) > 250) && (
                      <RowTooltip
                        title={item.title}
                        arrow
                        placement='top'
                        disable
                      >
                        {' '}
                        <div
                          style={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item.title}
                        </div>
                      </RowTooltip>
                    )}
                    {((item.title?.length ?? 0) <= 250) && (
                      <div
                        style={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {item?.title ?? 'No Title'}
                      </div>
                    )}
                  </a>
                </div>
              ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

import moment from "moment/moment";
import {axios_instance_fileDownload} from "@/utils/axios_instance";
import {downloadFile} from "@/actions/SIMT-SI/SpecialIssue/SIAction";
import {isTimeoutError} from "@/utils/commonUtils";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
import {EXPORT_REPORT} from "@/actions/SIMT-Report/Report/ReportAction";
import getAuth from "@/utils/getAuth";
import { getMoment } from "../getMoment";

/**
 * fy 导出excel
 * @param fiscalYear  财年
 * @returns {(function(*): Promise<void>)|*}
 */
export function fyExport(fiscalYear) {
    const url = 'reports/export/acquired-active/fy-trends/excel';
    return async dispatch => {
        const filename = `Report_Acquire_Active_FYTrends_${moment()
            .utc()
            .format('MMDDYYYY_HHmmss')}.xls`;
        try {
            let res = await axios_instance_fileDownload.get(url, {
                responseType: 'blob',
                params: {
                    fyYear: fiscalYear,
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    authorization: getAuth(),
                },
            });
            downloadFile(res.data, filename);
        } catch (err) {
            let reasonId;
            if (isTimeoutError(err)) {
                reasonId = 'common.errorTimeout';
            } else {
                reasonId = `common.error${err.status}`;
            }
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToExport',
                    {
                        value: filename,
                        reason: reasonId,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
}

export function fyExportWithFilenameFromBackEnd(fiscalYear) {
    const url = 'reports/export/acquired-active/fy-trends/excel';
    return async dispatch => {
        const filename = `Report_Acquire_Active_FYTrends_${moment()
            .utc()
            .format('MMDDYYYY_HHmmss')}.xls`;
        try {
            let res = await axios_instance_fileDownload.get(url, {
                responseType: 'blob',
                params: {
                    fyYear: fiscalYear,
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    authorization: getAuth(),
                },
            });
            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, '').replace(/["']/g, ''));
            } else {
                downloadFile(res.data, filename);
            }
        } catch (err) {
            let reasonId;
            if (isTimeoutError(err)) {
                reasonId = 'common.errorTimeout';
            } else {
                reasonId = `common.error${err.status}`;
            }
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToExport',
                    {
                        value: filename,
                        reason: reasonId,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
}

/**
 * fy 导出csv
 * @param fiscalYear  财年
 * @param tableType 表格名称，格式例如subject_group_table
 * @returns {(function(*): Promise<void>)|*}
 */
export function fyExportCSV(fiscalYear, tableType) {
    const url = 'reports/export/acquired-active/fy-trends/csv';
    return async dispatch => {
        const filename = `Report_Acquire_Active_FYTrends_${tableType}_${moment()
            .utc()
            .format('MMDDYYYY_HHmmss')}.csv`;
        try {
            let res = await axios_instance_fileDownload.get(url, {
                params: {
                    fyYear: fiscalYear,
                    type: tableType,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            downloadFile(res.data, filename);
        } catch (err) {
            let reasonId;
            if (isTimeoutError(err)) {
                reasonId = 'common.errorTimeout';
            } else {
                reasonId = `common.error${err.status}`;
            }
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToExport',
                    {
                        value: filename,
                        reason: reasonId,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
}

export function fyExportCSVWithFilenameFromBackEnd(fiscalYear, tableType) {
    const url = 'reports/export/acquired-active/fy-trends/csv';
    return async dispatch => {
        const filename = `Report_Acquire_Active_FYTrends_${tableType}_${moment()
            .utc()
            .format('MMDDYYYY_HHmmss')}.csv`;
        try {
            let res = await axios_instance_fileDownload.get(url, {
                params: {
                    fyYear: fiscalYear,
                    type: tableType,
                },
                headers: {
                    authorization: getAuth(),
                },
            });
            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, '').replace(/["']/g, ''));
            } else {
                downloadFile(res.data, filename);
            }
        } catch (err) {
            let reasonId;
            if (isTimeoutError(err)) {
                reasonId = 'common.errorTimeout';
            } else {
                reasonId = `common.error${err.status}`;
            }
            dispatch(
                setSnackbarMessageAndOpen(
                    'common.failToExport',
                    {
                        value: filename,
                        reason: reasonId,
                    },
                    SEVERITIES.error
                )
            );
        }
    };
}

export function exportOAvsTotlExcel(selectedDate) {
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/acquired-active/OaVsTotal/excel',
                {
                    responseType: 'blob',
                    params: {
                        selectedDate: selectedDate,
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        authorization: getAuth(),
                    },
                }
            );
            downloadFile(
                res.data,
                'Report_Acquire_Active_OA_VS_TOTAL_' +
                getMoment() +
                '.xlsx'
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportOAvsTotalExcelWithFilenameFromBackEnd(selectedDate) {
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/acquired-active/OaVsTotal/excel',
                {
                    responseType: 'blob',
                    params: {
                        selectedDate: selectedDate,
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        authorization: getAuth(),
                    },
                }
            );
            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
            } else {
                downloadFile(
                    res.data,
                    'Report_Acquire_Active_OA_VS_TOTAL_' +
                    getMoment() +
                    '.xlsx'
                );
            }
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportOAvsTotalCsv(selectedDate, type) {
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/acquired-active/OaVsTotal/csv',
                {
                    params: {
                        selectedDate: selectedDate,
                        type: type,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            downloadFile(
                res.data,
                'Report_Acquire_Active_OA_VS_TOTAL_' +
                type +
                '_' +
                getMoment() +
                '.csv'
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportOAvsTotalCsvWithFilenameFromBackEnd(selectedDate, type) {
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/acquired-active/OaVsTotal/csv',
                {
                    params: {
                        selectedDate: selectedDate,
                        type: type,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
            } else {
                downloadFile(
                    res.data,
                    'Report_Acquire_Active_OA_VS_TOTAL_' +
                    type +
                    '_' +
                    getMoment() + '.csv');
            }
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

/**
 * 以excel形式导出Annual Trends表
 * @param {*当前选择日期} selectAnnualDate
 * @returns
 */
export function exportAnnualTrendsExcel(selectAnnualDate) {
    var selectDateYear = selectAnnualDate.split('-')[0];
    var selectDateMonth = selectAnnualDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/acquired-active/annual-trends/excel',
                {
                    responseType: 'blob',
                    params: {
                        selectedDate: date,
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        authorization: getAuth(),
                    },
                }
            );
            downloadFile(
                res.data,
                'Report_Acquire_Active_ANNUAL_TRENDS_' +
                getMoment() +
                '.xlsx'
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportAnnualTrendsExcelWithFilenameFromBackEnd(selectAnnualDate) {
    var selectDateYear = selectAnnualDate.split('-')[0];
    var selectDateMonth = selectAnnualDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/acquired-active/annual-trends/excel',
                {
                    responseType: 'blob',
                    params: {
                        selectedDate: date,
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        authorization: getAuth(),
                    },
                }
            );
            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
            } else {
                downloadFile(
                    res.data,
                    'Report_Acquire_Active_ANNUAL_TRENDS_' +
                    getMoment() +
                    '.xlsx'
                );
            }
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

/**
 * 以csv形式导出Annual Trends表
 * @param {*当前选择的日期} selectAnnualDate
 * @param {*导出哪个表} type
 * @returns
 */
export function exportAnnualTrendsCsv(selectAnnualDate, type) {
    var selectDateYear = selectAnnualDate.split('-')[0];
    var selectDateMonth = selectAnnualDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);

    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/acquired-active/annual-trends/csv',
                {
                    params: {
                        selectedDate: date,
                        type: type,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            downloadFile(
                res.data,
                'Report_Acquire_Active_ANNUAL_TRENDS_' +
                type +
                '_' +
                getMoment() +
                '.csv'
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportAnnualTrendsCsvWithFilenameFromBackEnd(selectAnnualDate, type) {
    var selectDateYear = selectAnnualDate.split('-')[0];
    var selectDateMonth = selectAnnualDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);

    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/acquired-active/annual-trends/csv',
                {
                    params: {
                        selectedDate: date,
                        type: type,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
            } else {
                downloadFile(
                    res.data,
                    'Report_Acquire_Active_ANNUAL_TRENDS_' +
                    type +
                    '_' +
                    getMoment() +
                    '.csv'
                );
            }
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportArticlesAnnualTrendsExcel(selectedDate) {
    var selectDateYear = selectedDate.split('-')[0];
    var selectDateMonth = selectedDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/article/annual-trends/excel',
                {
                    responseType: 'blob',
                    params: {
                        selectedDate: date,
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        authorization: getAuth(),
                    },
                }
            );
            downloadFile(
                res.data,
                'Articles_Annual_Trends_' +
                getMoment() +
                '.xlsx'
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportArticlesAnnualTrendsExcelWithFilenameFromBackEnd(selectedDate) {
    var selectDateYear = selectedDate.split('-')[0];
    var selectDateMonth = selectedDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/article/annual-trends/excel',
                {
                    responseType: 'blob',
                    params: {
                        selectedDate: date,
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        authorization: getAuth(),
                    },
                }
            );
            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
            } else {
                downloadFile(
                    res.data,
                    'Articles_Annual_Trends_' +
                    getMoment() +
                    '.xlsx'
                );
            }
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportArticlesAnnualTrendsCsv(selectedDate, type) {
    var selectDateYear = selectedDate.split('-')[0];
    var selectDateMonth = selectedDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/article/annual-trends/csv',
                {
                    params: {
                        selectedDate: date,
                        type: type,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            downloadFile(
                res.data,
                'Articles_Annual_Trends_' +
                type +
                '_' +
                getMoment() +
                '.csv'
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportArticlesAnnualTrendsCsvWithFilenameFromBackEnd(selectedDate, type) {
    var selectDateYear = selectedDate.split('-')[0];
    var selectDateMonth = selectedDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/article/annual-trends/csv',
                {
                    params: {
                        selectedDate: date,
                        type: type,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
            } else {
                downloadFile(
                    res.data,
                    'Articles_Annual_Trends_' +
                    type +
                    '_' +
                    getMoment() +
                    '.csv'
                );
            }
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportArcticlesOAvsTotalCSV(selectedDate, type) {
    var selectDateYear = selectedDate.split('-')[0];
    var selectDateMonth = selectedDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);

    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/article/csv',
                {
                    params: {
                        selectedDate: date,
                        type: type,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            downloadFile(
                res.data,
                'Articles_OA_VS_TOTAL_' +
                type +
                '_' +
                getMoment() +
                '.csv'
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportArticlesOAvsTotalCSVWithFilenameFromBackEnd(selectedDate, type) {
    var selectDateYear = selectedDate.split('-')[0];
    var selectDateMonth = selectedDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);

    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/article/csv',
                {
                    params: {
                        selectedDate: date,
                        type: type,
                    },
                    headers: {
                        authorization: getAuth(),
                    },
                }
            );
            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
            } else {
                downloadFile(
                    res.data,
                    'Articles_OA_VS_TOTAL_' +
                    type +
                    '_' +
                    getMoment() +
                    '.csv'
                );
            }
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportArticlesOAvsTotalExcel(selectedDate) {
    var selectDateYear = selectedDate.split('-')[0];
    var selectDateMonth = selectedDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/article/excel',
                {
                    responseType: 'blob',
                    params: {
                        selectedDate: date,
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        authorization: getAuth(),
                    },
                }
            );
            downloadFile(
                res.data,
                'Articles_OA_VS_TOTAL_' +
                getMoment() +
                '.xlsx'
            );
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}

export function exportArticlesOAvsTotalExcelWithFilenameFromBackEnd(selectedDate) {
    var selectDateYear = selectedDate.split('-')[0];
    var selectDateMonth = selectedDate.split('-')[1];
    var date = Number(selectDateYear) * 100 + Number(selectDateMonth);
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get(
                '/reports/export/article/excel',
                {
                    responseType: 'blob',
                    params: {
                        selectedDate: date,
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        authorization: getAuth(),
                    },
                }
            );
            if (res.headers['content-disposition'] && res.headers['content-disposition'].includes('filename')) {
                downloadFile(res.data, res.headers['content-disposition'].split('filename=')[1].replace(/["']/g, ''));
            } else {
                downloadFile(
                    res.data,
                    'Articles_OA_VS_TOTAL_' +
                    getMoment() +
                    '.xlsx'
                );
            }
            dispatch({type: EXPORT_REPORT, data: true});
        } catch (err) {
            dispatch({type: EXPORT_REPORT, data: false});
        }
    };
}
import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  GetArticleOAvsTotalChartData,
  GetArticleOAvsTotalChartDataWithStatus,
} from '@/actions/SIMT-Report/ReportDiagram/ReportDiagramAction';
import {replaceZero} from "@/pages/Report/utils/reportUtils";

export default function useChartsDataHook(
  selectedDate = '2023-1',
  selectedSg,
  catagoryAttributeName,
  url,
  title
) {
  const dispatch = useDispatch();

  const { reportDataAPI } = useSelector(state => {
    return {
      reportDataAPI: state.ReportDiagram[title],
    };
  });

  let loading = null,
    error = null,
    data = null;

  if (typeof reportDataAPI !== 'undefined') {
    ({ loading, error, data } = reportDataAPI);
  }
  replaceZero(data);

  useEffect(() => {
    // if (!reportDataAPI && selectedDate !== null) {
    if (typeof reportDataAPI === 'undefined' && selectedDate !== null) {
      // dispatch(
      //   GetArticleOAvsTotalChartData(
      //     url,
      //     title,
      //     selectedDate,
      //     catagoryAttributeName
      //   )
      // );
      dispatch(
        GetArticleOAvsTotalChartDataWithStatus(
          url,
          title,
          selectedDate,
          catagoryAttributeName
        )
      );
    }
    // }, [reportDataAPI, selectedDate, title, ]);
  }, [url, selectedDate, title, reportDataAPI]);

  const catagoriesFullList = useMemo(() => {
    if (data != undefined && data != null && data.length != 0) {
      return data.map(chartData => chartData[0]).slice(1);
    }
    return [];
  }, [data]);

  const selectedDataForBar = useMemo(() => {
    if (data != undefined && data != null) {
      // if (selectedSg == undefined || selectedSg.length == 0) selectedSg = catagoriesFullList;
      return filterData(data, selectedSg, catagoryAttributeName);
    }
    return [];
  }, [data, selectedSg]);

  // return [catagoriesFullList, selectedDataForBar, data];
  return [catagoriesFullList, selectedDataForBar, data, loading, error];
}

function filterData(subjectGroupAllData, selectedSg, catagoryAttributeName) {
  let filteredData = subjectGroupAllData.filter(
    data => data[0] !== 'subjectGroup' && selectedSg.includes(data[0])
  );
  return filteredData;
}

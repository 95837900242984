import {
  Box,
  CircularProgress,
  createTheme,
  IconButton,
  Stack,
  ThemeProvider,
} from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import MarketChevronUp from '@/assets/MarketChevronUp.svg';
import SIDetailArrowDown from '@/assets/SIDetailArrowDown.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import MyOnlyText from '@/componentsphase2/MyOnlyText';
import SIPDetailObjectConserveIcon from '@/assets/SIPDetailObjectConserveIcon.svg';
import SIPDetailObjectCancelIcon from '@/assets/SIPDetailObjectCancelIcon.svg';
import TextFieldErrorIcon from '@/assets/TextFieldErrorIcon.svg';
import { useEffect, useMemo } from 'react';
import {
  CustomInputList,
  CustomTextArea,
  CustomTextField,
  CustomTextFieldTypeOne,
  ItemBlock,
} from '../EditComponents';

import { MiniFont } from '../../../../components/FontStyle';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SaveAndCancelButton from '@/componentsphase2/SIDetail/SaveAndCancelButton';
import { DateCalendar } from '@/components/DateRangePicker';
import { DatePicker } from '@/components/DateRangePicker';
import CustomDatePicker from '@/pages/SIPage/CreateAndUpdateSI/CGTComponents/CustomDatePicker';
import moment from 'moment';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import { GET_SIP_STATUS } from '../../../../actions/SIMT-SIP/SIP/SIPAction';
import {getSIPInformation, getSIPStatus} from "@/actions/SIMT-SIP/SIP/SipDetail";
import {EditSIPInfomation} from "@/actions/SIMT-SIP/SIP/SipInformation";
import {getSIPSourceOption} from "@/actions/SIMT-SIP/SIP/Config";
import {InputList} from "@/componentsphase2/InputList";
import {setSnackbarMessageAndOpen} from "@/actions/SnackbarAction";
import {SEVERITIES} from "@/modules/ConfirmSnackBar/CommonSnackBar";
const theme = createTheme({
  palette: {
    error: {
      main: '#EE5350',
    },
  },
});

export default function SIPInformationEditBlock(props) {
  const { setIsSIPInfomationEdit, sipStage } = props;
  const { sipCode } = useParams();

  const selector = state => {
    return {
      sipInformation: state.SIP.sipInformation,
      editSIPInfomationResult: state.SIP.editSIPInfomationResult,
      editSIPInfomationMonitor: state.SIP.editSIPInfomationMonitor,
      sipSourceOptions: state.SIP.sipSourceOptions,
      sipVersion: state.SIP.sipStatus?.sipVersion,
      sipStatus: state.SIP.sipStatus,
      openCallForPapers:state?.SIP?.contentCommissioningApproaches?.openCallForPapers??0,
      currentDecisionType:state?.SIP?.currentDecisionType,
    };
  };
  const {
    sipInformation,
    editSIPInfomationResult,
    editSIPInfomationMonitor,
    sipSourceOptions,
    sipVersion,
    sipStatus,
    openCallForPapers = 0,
    currentDecisionType = '',
  } = useSelector(selector);

  const intl = useIntl();
  const isSSIP = sipInformation?.sipType === 'Yes';
  const isUSIP = sipInformation?.sipType === 'No';
  const openCallForPapersIsYes = openCallForPapers == 1;
  // Case I (strict)
  const isSSIPStrict = isSSIP && 
    ((sipStage !== intl.messages['sipDetail.sipStageType.ideation'] 
      && sipStage !== intl.messages['sipDetail.sipStageType.revision']) 
      || currentDecisionType === intl.messages['sipDetail.decisionPanelElements.moveToSubmitted']);
  const isTextBlocksMandatory =  (isSSIPStrict && openCallForPapersIsYes) || isUSIP;
  const isFullValidation = isSSIPStrict || isUSIP;
  const [acceptanceDeadline, setAcceptanceDeadline] = useState(null);
  const [acceptanceDeadlineDS, setAcceptanceDeadlineDS] = useState(null);
  const [aimsAndScope, setAimsAndScope] = useState(null);
  const [epDate, setEpDate] = useState(null);
  const [epDateDS,setEpDateDS] = useState(null);
  const [fmSubmissionDeadline, setFmSubmissionDeadline] = useState(null);
  const [fmSubmissionDeadlineDS, setFmSubmissionDeadlineDS] = useState(null);
  const [keywords, setKeywords] = useState(null);
  const [notesForEditor, setNotesForEditor] = useState(null);
  const [references, setReferences] = useState(null);
  const [sipTitle, setSipTitle] = useState(null);
  const [sipSource, setSipSource] = useState(null);
  const [topics, setTopics] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFold, setIsFold] = useState(true);
  const [isClick, setIsClick] = useState(false);

  const dispatch = useDispatch();

  const [aimsAndScopeError, setAimsAndScopeError] = useState(false);
  const [keywordsError, setKeywordsError] = useState(false);
  const [notesForEditorError, setNotesForEditorError] = useState(false);
  const [referencesError, setReferencesError] = useState(null);
  const [topicsError, setTopicsError] = useState(null);
  const [error, setError] = useState(false);

  const [first, setFirst] = useState(true);

  useEffect(() => {
    getSIPStatus(sipCode);
  }, []);

  /***********************对编辑变量进行初始化********************** */
  const getSipSourceObjectFromSipSourceOptions = () => {
    for (let i = 0; i < sipSourceOptions?.length; i++) {
      const obj = sipSourceOptions[i];
      // 检查对象的属性a、b、c的值是否不为空
      if (obj && sipInformation.sipSource == obj?.value) {
        return obj;
      }
    }
    return {
      id: 999,
      type: 'Special Issue Source',
      key: '999',
      value: sipInformation.sipSource ? sipInformation.sipSource : '',
    };
  };
  const init = () => {
    setSipSource(getSipSourceObjectFromSipSourceOptions());
    setSipTitle(sipInformation.sipTitle ? sipInformation.sipTitle : '');
    setAimsAndScope(
      sipInformation.aimsAndScope ? sipInformation.aimsAndScope : ''
    );
    setTopics(
      sipInformation.topics && sipInformation.topics.length > 0
        ? sipInformation.topics
        : ['', '', '']
    );
    setKeywords(
      sipInformation.keywords && sipInformation.keywords.length > 0
        ? handleKeywords(sipInformation.keywords, false)
        : ['', '', '']
    );
    setReferences(
      sipInformation.references && sipInformation.references.length > 0
        ? handleReference(sipInformation.references, false)
        : ['']
    );
    setFmSubmissionDeadline(sipInformation.fmSubmissionDeadline);
    setAcceptanceDeadlineDS(sipInformation.acceptanceDeadline);
    setAcceptanceDeadline(sipInformation.acceptanceDeadline);
    setEpDateDS(sipInformation.epDate);
    setEpDate(sipInformation.epDate);
    setNotesForEditor(
      sipInformation.notesForEditor ? sipInformation.notesForEditor : ''
    );
  };

  useEffect(() => {
    // await dispatch(getSIPSourceOption());
    init();
  }, []);
  /***************************************************************** */

  /*********************处理结果***************************************************** */

  const handleKeywords = (keywords, ifBuild = false) => {
    let tmpKeyList = [];
    if (ifBuild) {
      for (var i in keywords) {
        if (keywords[i] === '') {
          continue;
        }
        tmpKeyList.push(keywords[i]);
      }
    } else {
      if (keywords.length < 3) {
        for (var i = 0; i < 3; i++) {
          if (i < keywords.length) {
            tmpKeyList.push(keywords[i]);
          } else {
            tmpKeyList.push('');
          }
        }
      } else {
        tmpKeyList = keywords;
      }
    }
    return tmpKeyList;
  };

  const handleReference = (references, ifBuild = false) => {
    let tmpReferenceList = [];
    if (ifBuild) {
      for (var i in references) {
        if (references[i] === '') {
          continue;
        }
        tmpReferenceList.push(references[i]);
      }
    } else {
      if (references.length < 1) {
        tmpReferenceList.push('');
      } else {
        tmpReferenceList = references;
      }
    }
    return tmpReferenceList;
  };

  const buildData = () => {
    return {
      sipCode: sipCode,
      sipTitle: sipTitle,
      aimsAndScope: aimsAndScope,
      topics: topics,
      keywords: handleKeywords(keywords, true),
      references: handleReference(references, true),
      fmSubmissionDeadline: fmSubmissionDeadline,
      acceptanceDeadline: acceptanceDeadline,
      epDate: epDate,
      notesForEditor: notesForEditor,
      sipSourceId: sipSource?.id,
      sipVersion: sipVersion,
    };
  };
  const [try1, setTry1] = useState(false);
  const [try2, setTry2] = useState(false);
  const [try3, setTry3] = useState(false);

  const handleEdit = () => {
    setIsClick(true);
    if (!error) {
      setIsLoading(true);
      dispatch(EditSIPInfomation(buildData())).then(sipNewVersion => {
        if (sipNewVersion) {
          const newSipStatus = {
            ...sipStatus,
            sipVersion: sipNewVersion,
          };
          dispatch({
            type: GET_SIP_STATUS,
            data: {
              ...newSipStatus,
            },
          });
        }
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  const judgeDateError = (value1, value2) => {
    return value1 !== null && value2 !== null && 
      value1 >= (typeof value2 === 'string' ? value2.slice(0, 10) : value2);
  };

  /**
   * To validate 3 dates: fmSubmissionDeadline, acceptanceDeadline, and epDate
   */
  const isFmSubmissionDeadlineValid = codeValue(fmSubmissionDeadline, try1);
  const isAcceptanceDeadlineValid = codeValue(acceptanceDeadline, try2);
  const isEpDateValid = codeValue(epDate, try3);

  const hasInvalidDateError = useMemo(() => {
    // In common
    if(isFullValidation && !isFmSubmissionDeadlineValid){
      return true;
    }
    // isUSIP
    if(isUSIP){
      return !isAcceptanceDeadlineValid || !isEpDateValid;
    }
  }, [isFmSubmissionDeadlineValid, isAcceptanceDeadlineValid, isEpDateValid, isUSIP]);

  const hasDateComparisionError = useMemo(() => {
    return judgeDateError(fmSubmissionDeadlineDS, epDateDS) ||
      judgeDateError(acceptanceDeadlineDS, epDateDS) ||
      judgeDateError(fmSubmissionDeadlineDS, acceptanceDeadlineDS);
  }, [judgeDateError, fmSubmissionDeadlineDS, acceptanceDeadlineDS, epDateDS]);


  useEffect(() => {
    let hasError = sipTitle === '' || sipTitle?.length > 400
      || referencesError 
      || notesForEditorError 
      || hasInvalidDateError
      || hasDateComparisionError
      || aimsAndScopeError 
      || topicsError 
      || keywordsError;
    setError(hasError);
  }, [
    sipTitle,
    referencesError,
    notesForEditorError,
    hasInvalidDateError,
    hasDateComparisionError,
    aimsAndScopeError,
    topicsError,
    keywordsError,
  ]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      return;
    }
    if (editSIPInfomationResult === 'OK') {
      const fetchData = async () => {
        await dispatch(getSIPInformation(sipCode));
      };
      fetchData();
      setIsLoading(false);
      setIsClick(false);
      setIsSIPInfomationEdit(false);
    } else {
      setIsLoading(false);
      setIsClick(false);
    }
  }, [editSIPInfomationMonitor]);
  /********************************************************************************* */

  const getMaxDate = date => {
    let today = new Date(date);
    today.setDate(today.getDate());
    return today;
  };
  const getMinDate = date => {
    let today = new Date(date);
    today.setDate(today.getDate());
    return today;
  };

  function codeValue(input, judge) {
    const date1 = new Date(input);
    if (input === null || input === undefined) {
      // console.log('no input');
      return '';
    }
    if (!judge) {
      // console.log('no judge',input, judge);
      return moment(date1).utc().format('YYYY-MM-DD');
    }
    const date = new Date(input);
    if (isNaN(date.valueOf())) {
      return 'Invalid Date';
    }
    // console.log('success',input, judge);
    return moment(date).utc().format('YYYY-MM-DD');
  }

  return (
    <Box
      data-selenium-id='SIP_DetailPage_InfomationEditBlock-Box'
      sx={{ overflowX: 'visible' }}
    >
      <Box
        sx={{ mb: '16px', mt: '32px', cursor: 'pointer' }}
        className='sipBlockTitleBox'
        data-selenium-id='SIP_DetailPage_InfomationEditBlock-TitleBox'
      >
        <Box
          data-selenium-id='SIP_DetailPage_InfomationEditBlock-TitleFoldBox'
          class='sipInfomationTitleFoldButton'
          onClick={() => {
            setIsFold(!isFold);
          }}
        >
          {isFold ? (
            <MarketChevronUp data-selenium-id='SIP_DetailPage_InfomationEditBlock-MarketChevronUp' />
          ) : (
            <SIDetailArrowDown />
          )}
        </Box>
        <Stack
          id='titleBoxLeft'
          data-selenium-id='SIP_DetailPage_InfomationEditBlock-TitleBoxLeft'
          spacing={1}
          direction='row'
          sx={{ cursor: 'default' }}
        >
          <Box>
            <FormattedMessage
              id='sipDetail.sipInformation'
              data-selenium-id='SIP_DetailPage_InfomationEditBlock-Detail'
            />
          </Box>

          {/* <IconButton
            id='sipDetailSIPInfomationCancelIcon'
            data-selenium-id='SIP_DetailPage_InfomationEditBlock-CancelIcon'
            sx={{ padding: '0px' }}
            onClick={() => {
              setIsSIPInfomationEdit(false);
            }}
          >
            <SIPDetailObjectCancelIcon />
          </IconButton>
          <IconButton
            id='sipDetailSIPInfomationConserveIcon'
            data-selenium-id='SIP_DetailPage_InfomationEditBlock-ConserveIcon'
            sx={{ padding: '0px' }}
            onClick={() => {
              handleEdit();
            }}
          >
            {isLoading ? (
              <CircularProgress
                size='16px'
                data-selenium-id='SIP_DetailPage_InfomationEditBlock-CircularProgress'
              />
            ) : (
              <SIPDetailObjectConserveIcon />
            )}
          </IconButton> */}
        </Stack>
      </Box>
      {isFold ? (
        <>
          <Stack
            direction='column'
            data-selenium-id='SIP_DetailPage_InfomationEditBlock-Stack'
          >
            {sipInformation.sipType === 'Yes' && (
              <ItemBlock
                title='SIP Source:'
                mandatory={true}
                sx={{ marginBottom: '16px', width: '100%' }}
              >
                <ThemeProvider
                  theme={theme}
                  data-selenium-id='SIP_DetailPage_InfomationEditBlock-Stack-ThemeProvider'
                >
                  <SinpleAutocomplete
                    id='sipDetailSIPSourceInput'
                    data-selenium-id='SIP_DetailPage_InfomationEditBlock-Stack-SourceInput'
                    value={sipSource}
                    options={sipSourceOptions}
                    isEmpty={!sipSource}
                    onChange={(event, newValue) => {
                      setSipSource(newValue);
                    }}
                    needKeyboardDelete={false}
                    defaultQuery={true}
                    placeholder={''}
                    getOptionLabel={o => o.value}
                    // config={config}
                    needClearInputValue={true}
                  ></SinpleAutocomplete>
                  {/* <MyOnlyText
                  id='sipDetailSIPTitleInput'
                  data-selenium-id='SIP_DetailPage_InfomationEditBlock-Stack-TitleInput'
                  value={sipTitle}
                  needEndAdornment={true}
                  alwaysHaveEndAdornment={true}
                  setValue={setSipTitle}
                  onChange={e => {
                    setSipTitle(e.target.value);
                  }}
                  handleIconButtonClick={
                    () => setSipTitle('')
                  }
                  // width={'100%'}
                  width={'860px'}
                  height={'33px'}
                  minRows={1}
                  maxRows={1}
                  placeholder='Please type the proposed Special Issue title.'
                  isError={isClick && sipTitle === ''}
                >
                </MyOnlyText>
                <SinpleAutocomplete
                id='preferredSubjectArea'
                data-selenium-id='SIP_DetailPage_ObjectEditBlock-PreferredSubjectArea'
                value={preferredSubjectArea}
                options={preferredSubjectAreaDropList}
                isEmpty={!preferredSubjectArea}
                onChange={(event,newValue) => {
                  setPreferredSubjectArea(newValue);
                }}
                needKeyboardDelete={false}
                loadOptionAction={name => getSIPDetailObjectPreferredSubjectAreaDropList(name)}
                placeholder={'Select the Preferred Subject Area'}
                getOptionLabel={o => o}
                config={config}
                needClearInputValue={true}
              ></SinpleAutocomplete> */}
                </ThemeProvider>
                {isClick && sipSource === '' ? (
                  <Box
                    sx={{ ...MiniFont, color: '#EE5350' }}
                    data-selenium-id='SIP_DetailPage_InfomationEditBlock-Stack-Box'
                  >
                    This field is required.
                  </Box>
                ) : (
                  <></>
                )}
              </ItemBlock>
            )}
            <ItemBlock
              title='SIP Title:'
              mandatory={true}
              sx={{ marginBottom: '16px', width: '100%' }}
            >
              <ThemeProvider
                theme={theme}
                data-selenium-id='SIP_DetailPage_InfomationEditBlock-Stack-ThemeProvider'
              >
                {/* <CustomTextFieldTypeOne
                  id='sipDetailSIPTitleInput'
                  data-selenium-id='SIP_DetailPage_InfomationEditBlock-Stack-TitleInput'
                  value={sipTitle}
                  onChange={e => {
                    setSipTitle(e.target.value);
                  }}
                  placeholder='Please type the proposed Special Issue title.'
                  error={isClick && sipTitle === ''}
                /> */}
                <MyOnlyText
                  id='sipDetailSIPTitleInput'
                  data-selenium-id='SIP_DetailPage_InfomationEditBlock-Stack-TitleInput'
                  value={sipTitle}
                  needEndAdornment={true}
                  alwaysHaveEndAdornment={true}
                  setValue={setSipTitle}
                  onChange={e => {
                    setSipTitle(e.target.value);
                  }}
                  handleIconButtonClick={() => setSipTitle('')}
                  // width={'100%'}
                  width={'860px'}
                  height={'33px'}
                  minRows={1}
                  maxRows={1}
                  placeholder='Please type the proposed Special Issue title.'
                  isError={isClick && (sipTitle === '' || sipTitle?.length > 400)}
                ></MyOnlyText>
              </ThemeProvider>
              {isClick &&  (sipTitle === '' || sipTitle?.length > 400) ? (
                <Box
                  sx={{ ...MiniFont, color: '#EE5350' }}
                  data-selenium-id='SIP_DetailPage_InfomationEditBlock-Stack-Box'
                >
                  {sipTitle === '' ? 'This field is required.': 'The maximum length for title is 400 characters.'}
                </Box>
              ) : (
                <></>
              )}
            </ItemBlock>

            {/* Aims and Scope */}
            <ItemBlock
              title='Aims and Scope:'
              mandatory={isTextBlocksMandatory}
              sx={{ marginBottom: '16px', width: '100%' }}
            >
              <CustomTextArea
                id='sipDetailAimsandScopeInput'
                data-selenium-id='SIP_DetailPage_InfomationEditBlock-Stack-AimsandScopeInput'
                value={aimsAndScope ? aimsAndScope : ''}
                setValue={setAimsAndScope}
                minRows={3}
                maxRows={7}
                placeholder='Please introduce the background and significance of your proposed Special Issue in 500 characters as a minimum.'
                needWordCount={false}
                hideWordCount={false}
                minLength={500}
                needEmptyCheck={isTextBlocksMandatory}
                errorMonitor={isTextBlocksMandatory && isClick}
                setError={!isTextBlocksMandatory ? () => { } : setAimsAndScopeError}
              />
            </ItemBlock>

            <ItemBlock
              title='Topics'
              mandatory={isTextBlocksMandatory}
              sx={{ marginBottom: '16px', width: '100%' }}
            >
              <CustomInputList
                id='sipDetailTopicsInput'
                promptTitle='topic'
                data-selenium-id='SIP_DetailPage_InfomationEditBlock-Stack-TopicsInput'
                value={topics ? topics : ['', '', '']}
                // sx={{width:'860px', height:'33px'}}
                setValue={setTopics}
                maxRows={2}
                minItems={3}
                maxItems={50}
                limitLength={255}
                errorMonitor={isTextBlocksMandatory && isClick}
                needEmptyCheck={true}
                snackbarMessage='sipDetail.edit.sipInformationTopicExceedErr'
                exceedErrText='The character count per topic should be no more than 255.'
                setError={!isTextBlocksMandatory ? () => { } : setTopicsError}
              />
            </ItemBlock>

            <ItemBlock
              title='Keywords'
              // mandatory={!isSSIP}
              mandatory={isTextBlocksMandatory}
              sx={{ marginBottom: '16px', width: '495' }}
            >
              <InputList
                  id='sipDetailKeywordsInput'
                  data-selenium-id='SIP_DetailPage_InfomationEditBlock-Stack-KeywordsInput'
                  value={keywords ? keywords : ['']}
                  setValue={setKeywords}
                  minItems={3}
                  maxItems={48}
                  setError={!isTextBlocksMandatory ? () => { } : setKeywordsError}
                  placeholder={'Please type the proposed keyword.'}
                  onItemExceed={() => {
                    dispatch(
                        setSnackbarMessageAndOpen('sipDetail.edit.sipInformationKeywordsExceedErr', {}, SEVERITIES.error)
                    );
                  }}
                  errorCheckers={[
                    {
                      enable: true,
                      check: (item) => item.length > 500,
                      message: 'The character count per keyword should be no more than 500.',
                    },
                    {
                      enable: true,
                      check: (item,valueList) => {
                        return item !== '' && valueList.indexOf(item) !== valueList.lastIndexOf(item);
                      },
                      message: 'Keywords should not be duplicated.',
                    },
                    {
                      enable: isTextBlocksMandatory && isClick,
                      check: (item,valueList) => valueList.filter(v => v !== '').length < 3,
                      message: 'At least 3 keywords must be inputted.'
                    }
                  ]}
              />
            </ItemBlock>

            <ItemBlock
              title='References:'
              mandatory={false}
              sx={{ marginBottom: '16px', width: '100%' }}
            >
              <CustomInputList
                id='sipDetailReferencesInput'
                promptTitle='reference'
                data-selenium-id='SIP_DetailPage_InfomationEditBlock-Stack-ReferencesInput'
                value={references ? references : ['']}
                setValue={setReferences}
                maxRows={2}
                minItems={1}
                maxItems={50}
                limitLength={1000}
                errorMonitor={isClick}
                needEmptyCheck={false}
                snackbarMessage='sipDetail.edit.sipInformationReferencesExceedErr'
                exceedErrText='The character count per reference should be no more than 1000.'
                setError={setReferencesError}
                bottomHelperText={
                  'For each text box, please fill in one reference item only. Click on "ADD" to add more reference items.'
                }
              />
            </ItemBlock>

            <ItemBlock
              title='Full Manuscript Submission Deadline:'
              // mandatory={!isSSIP}
              mandatory={isFullValidation}
              sx={{ marginBottom: '16px', width: '495px' }}
            >
              <Stack
                direction='column'
                alignItems='flex-start'
                justifyContent='center'
                spacing='2px'
                sx={{ width: '863px' }}
              >
                <CustomDatePicker
                  id={'fmSubmissionDeadline'}
                  placeholder={'yyyy-mm-dd'}
                  value={codeValue(fmSubmissionDeadline, try1)}
                  error={
                    // !isSSIP&&
                    isClick && codeValue(fmSubmissionDeadline, try1) === '' 
                    && isFullValidation
                  }
                  onChange={(ds,d) => {
                    setFmSubmissionDeadlineDS(ds);
                    setFmSubmissionDeadline(d);
                  }}
                  handleFocus={() => {}}
                  handleBlur={() => {}}
                  ifNoData={true}
                  setNoData={setTry1}
                  height={'32px'}
                />
                 {isClick && codeValue(fmSubmissionDeadline, try1) === '' && isFullValidation ? (
                  <Stack
                    direction='row'
                    spacing='2px'
                    alignItems='flex-Start'
                    justifyContent='flex-start'
                    sx={{
                      maxWidth: '100%',
                    }}
                  >
                    <Stack
                      alignItems='center'
                      justifyContent='center'
                      sx={{
                        marginTop: '2px',
                      }}
                    >
                      <TextFieldErrorIcon />
                    </Stack>
                    <Box
                      sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        color: '#EE5350',
                        letterSpacing: '-0.084px',
                      }}
                    >
                        Please enter this field.
                    </Box>
                  </Stack>
                ) : null}
              </Stack>
            </ItemBlock>

            <ItemBlock
              title='Acceptance Deadline:'
              mandatory={!isSSIP}
              sx={{ marginBottom: '16px', width: '495px' }}
            >
              <Stack
                direction='column'
                alignItems='flex-start'
                justifyContent='center'
                spacing='2px'
                sx={{ width: '863px' }}
              >
                <CustomDatePicker
                  id={'acceptanceDeadline'}
                  placeholder={'yyyy-mm-dd'}
                  value={codeValue(acceptanceDeadline, try2)}
                  onChange={(ds,d) => {
                    setAcceptanceDeadlineDS(ds);
                    setAcceptanceDeadline(d);
                  }}
                  minDateStr={
                    fmSubmissionDeadline
                      ? codeValue(getMinDate(fmSubmissionDeadline), true)
                      : undefined
                  }
                  error={
                    ((!isSSIP && isClick &&
                      (codeValue(acceptanceDeadline, try2) === '')) ||judgeDateError(fmSubmissionDeadlineDS,acceptanceDeadlineDS))
                  }
                  handleFocus={() => {}}
                  handleBlur={() => {}}
                  ifNoData={true}
                  setNoData={setTry2}
                  height={'32px'}
                />
                {(!isSSIP && isClick &&
                      (codeValue(acceptanceDeadline, try2) === '')) ||judgeDateError(fmSubmissionDeadlineDS,acceptanceDeadlineDS)? (
                  <Stack
                    direction='row'
                    spacing='2px'
                    alignItems='flex-Start'
                    justifyContent='flex-start'
                    sx={{
                      maxWidth: '100%',
                    }}
                  >
                    <Stack
                      alignItems='center'
                      justifyContent='center'
                      sx={{
                        marginTop: '2px',
                      }}
                    >
                      <TextFieldErrorIcon />
                    </Stack>
                    <Box
                      sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        color: '#EE5350',
                        letterSpacing: '-0.084px',
                      }}
                    >
                      {codeValue(acceptanceDeadline, try2) === ''
                        ? 'Please enter this field.'
                        : 'This date should be after Submission Deadline.'}
                    </Box>
                  </Stack>
                ) : null}
              </Stack>
            </ItemBlock>

            <ItemBlock
              title='Expected Publication Date:'
              mandatory={!isSSIP}
              sx={{ marginBottom: '16px', width: '495px' }}
            >
              <Stack
                direction='column'
                alignItems='flex-start'
                justifyContent='center'
                spacing='2px'
                sx={{ width: '863px' }}
              >
                <CustomDatePicker
                  id={'epDate'}
                  placeholder={'yyyy-mm-dd'}
                  value={codeValue(epDate, try3)}
                  error={
                   ( !isSSIP&&isClick &&
                    (codeValue(epDate, try3) === '')) ||  (judgeDateError(acceptanceDeadlineDS,epDateDS))|| judgeDateError(fmSubmissionDeadlineDS,epDateDS)
                  }
                  onChange={(ds,d) => {
                    setEpDateDS(ds);
                    setEpDate(d);
                  }}
                  minDateStr={
                    acceptanceDeadline
                      ? codeValue(getMinDate(acceptanceDeadline), true)
                      : undefined
                  }
                  handleFocus={() => {}}
                  handleBlur={() => {}}
                  ifNoData={true}
                  setNoData={setTry3}
                  height={'32px'}
                />
                { (!isSSIP&&isClick &&
                    (codeValue(epDate, try3) === '')) || judgeDateError(fmSubmissionDeadlineDS,epDateDS)|| (judgeDateError(acceptanceDeadlineDS,epDateDS)) ? (
                  <Stack
                    direction='row'
                    spacing='2px'
                    alignItems='flex-Start'
                    justifyContent='flex-start'
                    sx={{
                      maxWidth: '100%',
                    }}
                  >
                    <Stack
                      alignItems='center'
                      justifyContent='center'
                      sx={{
                        marginTop: '2px',
                      }}
                    >
                      <TextFieldErrorIcon />
                    </Stack>
                    <Box
                      sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        color: '#EE5350',
                        letterSpacing: '-0.084px',
                      }}
                    >
                      {codeValue(epDate, try3) === ''
                        ? 'Please enter this field.'
                        : 'This date should be after Submission Deadline and Acceptance Deadline.'}
                    </Box>
                  </Stack>
                ) : null}
              </Stack>
            </ItemBlock>
            <ItemBlock
              title='Notes for Editor:'
              sx={{ marginBottom: '16px', width: '100%' }}
            >
              <CustomTextArea
                id='sipDetailNotesforEditorInput'
                data-selenium-id='SIP_DetailPage_InfomationEditBlock-Stack-NotesforEditorInput'
                value={notesForEditor ? notesForEditor : ''}
                setValue={setNotesForEditor}
                minRows={7}
                maxRows={7}
                placeholder='Please leave any notes for editors’ consideration to evaluate your Special Issue proposal as needed.'
                setError={setNotesForEditorError}
              />
            </ItemBlock>
          </Stack>
          <SaveAndCancelButton
            id='SIPDetail_SIPInfomationBlock-TwoBtn'
            data-selenium-id='SIPDetail_SIPInfomation-TwoBtn'
            isSaving={isLoading}
            handleCancel={() => {
              setIsSIPInfomationEdit(false);
            }}
            handleSubmit={handleEdit}
          ></SaveAndCancelButton>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
}

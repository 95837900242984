import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**
 *
 * @param props permission必须传入，多个权限可用空格分开，包含任何一个权限都会被允许访问（OR）
 *              如需做到AND的权限控制，可以嵌套ShowForPermission
 * @returns {*|null}
 * @constructor
 */
const ShowForPermissionComponent = props => {
  const type = props.controlType ? props.controlType : 'show'; // default show type
  let couldShow = false;
  if (type === 'show') {
    for (const permissionItem of props.permission?.split(' ') ?? []) {
      if (props.userPermissions.includes(permissionItem)) {
        couldShow = true;
        break;
      }
    }
  } else {
    // type hide
    couldShow = true;
    for (const permissionItem of props.permission?.split(' ') ?? []) {
      if (props.userPermissions.includes(permissionItem)) {
        couldShow = false;
        break;
      }
    }
  }

  return couldShow ? props.children : null;
};

ShowForPermissionComponent.propTypes = {
  permission: PropTypes.string.isRequired,
  userPermissions: PropTypes.string.isRequired,
  controlType: PropTypes.string,
};

const mapStateToProps = () => ({
  // 参数可以写state，与redux store建立关系，但是没成功
  userPermissions: (localStorage.getItem('userPermissions') ?? '').split(','),
});

export const ShowForPermission = connect(mapStateToProps)(
  ShowForPermissionComponent
);

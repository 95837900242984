import { StrictMode, type ReactElement, type Component, type HTMLAttributes, useState, type ChangeEvent, useMemo, useCallback, useEffect, useRef, type CSSProperties, } from 'react';
import { bool, func, number, object, string, } from 'prop-types';
import SIModalPro from '@/modules/Modal/SIModal';
import styleModule from './_index.module.scss';
import { StateButton } from '@/componentsphase2/StateButton';
import GETable, { GeDataType, VerificationStyleEnum, calcFlaggedGESynchronizationAndVerificationStatus, createRow, geDataName, radioGropListArray, queryFlaggedGESynchronizationAndVerificationErrorType, FlaggedGESynchronizationAndVerificationErrorType, verification, radioGropList, type singleGeData, alertGeFlagType, } from '@/componentsphase2/FlaggedGESynchronizationAndVerificationTable';
import SimpleCheckBox from '@/componentsphase2/Input/SimpleCheckBox';
import { useBoolean, useUpdateEffect } from 'ahooks';
import { useDispatch } from 'react-redux';
import { getGeByFullnameWithCache } from '@/actions/SIMT-SI/GuestEditor/GEAction';
import { SEVERITIES } from '@/modules/ConfirmSnackBar/CommonSnackBar';
import { AlertBox, ButtonAlertBox, UntitledAlertBox } from '@/components/Alert/AlertBox';
import { Box, type SnackbarCloseReason } from '@mui/material';
import { unstable_batchedUpdates } from 'react-dom';
import {matchProposer} from "@/actions/SIMT-SI/GuestEditor/SipDetail";
import {getGeByFullname} from "@/actions/SIMT-SI/GuestEditor";
import {getGEInformation} from "@/actions/SIMT-SIP/SIP/SipDetail";
import {PERMISSIONS} from "@/constant/permission";
type RRN_<T> = Readonly<Required<NonNullable<T>>>;
type RRN_Boolean = RRN_<boolean>;
type RRN_String = RRN_<string>;
type RRN_Number = RRN_<number>;
// type RRN_Object = RRN_<object>;
type RRN_Component = new () => RRN_<Component>;
type RRN_ReactElementGenericity<T> = RRN_<ReactElement<T, RRN_Component>>;
// type anyReactElementGenericity = RRN_ReactElementGenericity<RRN_Object>;
export interface newSingleGeData extends singleGeData {
  readonly institution: RRN_String;
  readonly matchedEmailGeCode: RRN_String;
  readonly matchedNameGeCode: RRN_String;
  readonly emailAddress: RRN_String;
  readonly leadGe: RRN_String;
  readonly jobTitle: RRN_String;
  readonly country: RRN_String;
  readonly profileWebsite: RRN_String;
  readonly cv: RRN_String;
  readonly id: RRN_String;
  readonly geId: RRN_String;
  readonly matchedEmailGeFlagType: alertGeFlagType;
}
interface NewFlagMatchingModalProps extends HTMLAttributes<HTMLElement> {
  readonly open?: RRN_Boolean;
  readonly handleClose?: () => void;
  readonly theGeInformation?: newSingleGeData;
  readonly sipCode?: RRN_String;
  readonly index?: RRN_Number;
};
type RRN_NewFlagMatchingModalProps = RRN_<NewFlagMatchingModalProps>;
type ReactElementGenericity = RRN_ReactElementGenericity<RRN_NewFlagMatchingModalProps>;
// type ReadonlyArrayGeDataType = ReadonlyArray<GeDataType>;
const { freeze } = Object;
const fullNameHeader = createRow(geDataName.fullName, 'FULL NAME', false, '184px', '184px');
const instituteAndEmailHeader = [createRow(geDataName.institute, 'INSTITUTION'),
createRow(geDataName.email, geDataName.email.toUpperCase(), false, '184px', '184px'),];
const clearCache = () => window.caches?.keys()?.then(e => e.forEach(cacheName => window.caches?.delete(cacheName))).catch(console.error);
const { stringify } = JSON;
export const nameOrEmailChange = (item1: singleGeData, item2: singleGeData, checkMatchState: boolean = false) => {
  const onlyNameOrEmailChange = item1?.[geDataName.firstName] !== item2?.[geDataName.firstName] ||
    item1?.[geDataName.lastName] !== item2?.[geDataName.lastName] ||
    item1?.[geDataName.primaryEmail] !== item2?.[geDataName.primaryEmail] ||
    item1?.[geDataName.secondaryEmail] !== item2?.[geDataName.secondaryEmail] ||
    checkMatchState && (item1?.matchedEmailGeCode !== item2?.matchedEmailGeCode || item1?.matchedNameGeCode !== item2?.matchedNameGeCode);
  try {
    return onlyNameOrEmailChange || stringify(item1) !== stringify(item2);
  }
  catch {
    return onlyNameOrEmailChange;
  }
};
export default function NewFlagMatchingModal (props: Readonly<NonNullable<NewFlagMatchingModalProps>>): ReactElementGenericity {
  const {
    id = 'SIPPage_Detail-NewFlagMatchingModal',
    open = false,
    handleClose = () => { },
    theGeInformation = {
      geId: '',
      geCode: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      institution: '',
      matchedEmailGeCode: undefined,
      matchedNameGeCode: undefined,
    },
    sipCode = '',
    index = 0,
    ...others
  } = props;
  const { emailAddress: email = '', geId = '', institution = '', matchedEmailGeCode = undefined, matchedNameGeCode = undefined } = theGeInformation;
  if (!geId) return <StrictMode />;
  const fullName = `${theGeInformation?.lastName ?? ''}, ${theGeInformation?.firstName ?? ''}`;
  const GeCodeEqual = useCallback((geCode = '') => (matchedEmailGeCode === geCode) || (matchedNameGeCode === geCode), [matchedEmailGeCode, matchedNameGeCode]);
  const [geDataResult, setGeDataResult] = useState<ReadonlyArray<singleGeData>>([]);
  // const [needToMatched, setNeedToMatched] = useState(true);
  const isReadonly = Boolean((matchedEmailGeCode !== undefined && matchedEmailGeCode !== null) || (matchedNameGeCode !== undefined && matchedNameGeCode !== null));
  const [isEditing, { set: setIsEditable, setTrue: setIsEditableTrue, setFalse: setIsEditableFalse }] = useBoolean(!isReadonly);
  const needToMatched = geDataResult?.length > 1 || geDataResult?.[0]?.[geDataName.primaryEmail] !== email;
  // const needToMatched = useMemo(() => (geDataResult?.length > 1 || geDataResult?.[0]?.[geDataName.primaryEmail] !== email), [geDataResult?.length, email]);
  const needToMatchedAndIsEditing = needToMatched && isEditing;
  // const needToMatchedAndIsEditing = useMemo(() => needToMatched && isEditing, [needToMatched, isEditing]);
  const dispatch = useDispatch();
  useEffect(() => {
    setIsEditable(!isReadonly);
  }, [isReadonly]);
  const [radiosData, setRadiosData] = useState<radioGropListArray>([]);
  const [proceed, { setTrue: setProceedTrue, setFalse: setProceedFalse }] = useBoolean(false);
  const [checked, setChecked] = useState(false);
  const [errorTypeToAlert, setErrorTypeToAlert] = useState<FlaggedGESynchronizationAndVerificationErrorType | null>(null);
  const [buttonAlertOpen, setButtonAlertOpen] = useState(false);
  const geCodeWidth = needToMatched ? '160px' : '240px';
  const handleError = useCallback((errorType: FlaggedGESynchronizationAndVerificationErrorType) => unstable_batchedUpdates(() => {
    setChecked(false);
    setErrorTypeToAlert(errorType);
    setProceedTrue();
  }), []);
  const commonSwitch = useCallback((errorType: FlaggedGESynchronizationAndVerificationErrorType) => {
    switch (errorType) {
      case FlaggedGESynchronizationAndVerificationErrorType.notMatchedGE:
        handleError(FlaggedGESynchronizationAndVerificationErrorType.notMatchedGE);
        return;
      case FlaggedGESynchronizationAndVerificationErrorType.moreThanOneMatchedGE:
        handleError(FlaggedGESynchronizationAndVerificationErrorType.moreThanOneMatchedGE);
        return;
      default:
        break;
    }
  }, []);
  const tableErrorType = useMemo(() => queryFlaggedGESynchronizationAndVerificationErrorType(radiosData), [radiosData]);
  const tableIsNotError = useMemo(() => calcFlaggedGESynchronizationAndVerificationStatus(radiosData), [radiosData]);
  const isCheckBoxError = useMemo(() => proceed && tableIsNotError && !checked, [proceed, tableIsNotError, checked]);
  useUpdateEffect(() => { if (proceed && tableErrorType !== FlaggedGESynchronizationAndVerificationErrorType.notMatchedGE) setProceedFalse(); }, [radiosData, checked]);
  useEffect(() => window.addEventListener('openGE', clearCache), [email, geId, institution, matchedEmailGeCode, matchedNameGeCode, fullName]);
  useEffect(() => window.addEventListener('beforeunload', clearCache), []);
  const ref = useRef<HTMLParagraphElement>(null);
  // const top = useMemo(() => {
  //   const dis = ref.current?.getBoundingClientRect().y;
  //   return (dis ? (dis <= 24 ? 0 : dis - 24) : 0) + 12;
  // }, [geDataResult?.length, radiosData?.length,isEditing]);
  const top = (() => {
    const dis = ref.current?.getBoundingClientRect().y;
    return (dis ? (dis <= 24 ? 0 : dis - 24) : 0) + 12;
  })();
  const AlertBoxCommonProps = useMemo(() => freeze({
    handleClose: (_: Event, _reason: SnackbarCloseReason) => {
      // if (reason === 'clickaway') {
      //   return;
      // }
      setErrorTypeToAlert(null);
    },
    severity: SEVERITIES.warning,
    className: styleModule.alertBox,
    style: { top: `${top}px`, width: '546px' }
  }), [top]);
  const init = useCallback(() => unstable_batchedUpdates(() => {
    if (buttonAlertOpen) return;
    setChecked(false);
    setErrorTypeToAlert(null);
    setProceedFalse();
    setButtonAlertOpen(false);
    handleClose();
  }), [buttonAlertOpen]);
  // const getGeByFullname = useCallback(() => new Promise<GeDataType>(resolve => dispatch(
  //   getGeByFullnameWithCache(
  //     fullName, email, 0, false, resolve
  //   )
  // )), [fullName, email]);
  const refTable1 = useRef<HTMLDivElement>(null);
  const refTable2 = useRef<HTMLDivElement>(null);
  // useUpdateEffect(() => {
  //   setTimeout(() => {
  //     const dom = refTable2.current?.querySelectorAll<HTMLDivElement>('div > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiTableContainer-root > table > tbody > tr > td > div > div > div:only-child');
  //     if (!dom) return;
  //     dom.forEach((item) => {
  //       if (!item.childElementCount && (item instanceof HTMLDivElement && Object.prototype.toString.call(item) === '[object HTMLDivElement]' && item.constructor.toString() === 'function HTMLDivElement() { [native code] }' && typeof item === 'object') && item.scrollWidth > item.clientWidth && item.scrollWidth > item.offsetWidth) {
  //         const text = item.innerText.trim();
  //         if (text.length <= 18) return;
  //         item.innerText = `${text.slice(0, 15)}....${text.slice(15)}`;
  //       }
  //     });
  //   });
  // }, [geDataResult?.length]);
  // useEffect(() => window.onbeforeunload = () => { window.caches.keys().then(e => e.forEach(cacheName => window.caches.delete(cacheName))).catch(console.error); }, []);
  // useEffect(() => { window.caches.keys().then(e => e.forEach(cacheName => window.caches.delete(cacheName))).catch(console.error); }, []);
  const userPermissions = (localStorage.getItem('userPermissions') ?? '').trim().toLocaleLowerCase();
  return (
    <StrictMode>
      <SIModalPro
        open={open}
        id={id}
        data-selenium-id={id}
        needTitle={false}
        closeButtonSx={{
          top: 24,
          left: 'unset',
          right: 32,
          '&>svg>path': {
            fill: '#262E35',
          },
        }}
        width='924px'
        height='unset'
        containerSx={{
          padding: '24px 32px 20px',
        }}
        className={styleModule.NewFlagMatchingModal}
        handleClose={(_e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown' || buttonAlertOpen)
            return;
          init();
        }}
        {...others}
      >
        <StrictMode>
          <p className={styleModule.title} ref={ref}>New Flag Matching</p>
          <p className={styleModule.subtitle}>This GE is newly flagged with some issues after the "Initial Review" stage, due to the update of the GE database.</p>
          <p className={styleModule.tableTitle}>GE-Proposer Information</p>
          <GETable
            VerificationStyle={needToMatched ? VerificationStyleEnum.hidden : VerificationStyleEnum.none}
            headerItems={[
              { ...fullNameHeader },
              ...instituteAndEmailHeader,
            ]}
            geDataResult={[{ [geDataName.fullName]: fullName, [geDataName.institute]: institution, [geDataName.primaryEmail]: email }]}
            id='SIPPage_Detail-NewFlagMatchingModal1'
            ref={refTable1}
            {...(!needToMatched && { className: styleModule.tableFirstRow1 })}
          />
          <p className={styleModule.tableTitle}>SIMT GE Database Information</p>
          {needToMatchedAndIsEditing && <p className={styleModule.matchedTableTitle}>Please select only one matched GE or select all GE as mismatched </p>}
          <GETable
            geDataResult={geDataResult
              ?.map((item) => ({
                ...item,
                [verification]: (GeCodeEqual(item?.[geDataName.geCode]) ? radioGropList.Matched : ((isEditing || !needToMatched) ? undefined : radioGropList.Mismatched))
              }))
              // ?.map(item => ({
              //   ...item,
              //   [verification]: radioGropList.Matched
              // }))
            }
            setValue={e => setRadiosData(e ?? [])}
            VerificationStyle={needToMatched ? VerificationStyleEnum.show : VerificationStyleEnum.none}
            headerItems={[
              { ...fullNameHeader },
              { ...createRow(geDataName.geCode, 'GE CODE', false, geCodeWidth, geCodeWidth), hasFlag: true, },
              ...instituteAndEmailHeader,
            ]}
            editing={needToMatchedAndIsEditing}
            id='SIPPage_Detail-NewFlagMatchingModal2'
            fetchData={
              // () => new Promise<GeDataType>(resolve =>
              //   dispatch(
              //     getGeByFullnameWithCache(
              //       fullname, email, 0, resolve
              //     )
              //   )
              // ).catch(console.error)
              // async () => {
              //   dispatch(
              //     getGeByFullnameWithCache(
              //       fullname, email, 0
              //     )
              //   );
              // }
              // Promise.all([
              // , new Promise<RRN_Boolean>(resolve =>
              //   checkProposerGE(
              //     geId, resolve
              //   )
              //   )])
              () => new Promise<GeDataType>(resolve => dispatch(
                getGeByFullnameWithCache(
                  fullName, email, 0, true, resolve
                )
              )).then(res =>
                // unstable_batchedUpdates(() => {
                // setGeDataResult(res?.[0]);
                setGeDataResult(res)
                // setNeedToMatched(!res?.[1]);
                // })
              ).catch(console.error)
            }
            error={proceed && !tableIsNotError}
            singleError
            className={styleModule.tableFirstRow2}
            ref={refTable2}
            {...(!needToMatchedAndIsEditing && { style: { '--bgHeight': '42px', ...(!needToMatched && { '--textAlign': 'left' }) } as CSSProperties })}
          />
          <p className={styleModule.comfirmTitle}>Action Requiring</p>
          <Box className={styleModule.comfirmText}
            sx={{
              color: isCheckBoxError ? '#EE5350' : '#262E35',
              ...(isCheckBoxError && { fontWeight: '600' })
            }}
          ><SimpleCheckBox
              onClick={(e: ChangeEvent<HTMLInputElement>) => {
                if (!needToMatchedAndIsEditing || checked || !tableErrorType) {
                  setChecked(e.target.checked);
                  return;
                }
                commonSwitch(tableErrorType);
              }}
              {...(!isEditing ? { checked: true, disabled: true, className: styleModule.disabled } : { checked })}
              {...(isCheckBoxError && { className: styleModule.error })}
            />I acknowledge that the new flag(s) has been realized and considered into decision making.</Box>
          <StateButton
            titleName={!isEditing ? 'Edit' : 'Confirm'}
            isPrimary={true}
            themeSize={true}
            onClick={async () => {
              if (!isEditing) { setIsEditableTrue(); return; };
              if (!tableErrorType || !needToMatchedAndIsEditing) {
                if (!checked && needToMatchedAndIsEditing) {
                  setProceedTrue();
                  return;
                }
                const res = await Promise.all([await new Promise<GeDataType>(resolve => dispatch(getGEInformation(sipCode, resolve))), await new Promise<GeDataType>(resolve => dispatch(
                  getGeByFullname(
                    fullName, email, 0, true, resolve
                  )
                )),]).catch(console.error);
                if (!res) {
                  return;
                }
                const res1 = res?.[0]?.[index];
                const res2 = res?.[1];
                // if (stringify(res?.[0]?.[index]).toString().length !== stringify(theGeInformation).toString().length || stringify(res?.[1]).toString().length !== stringify(geDataResult).toString().length) {
                if (nameOrEmailChange(res1, theGeInformation) || res2?.length !== geDataResult?.length || res2?.some((item, index) => nameOrEmailChange(item, geDataResult?.[index]))) {
                  setButtonAlertOpen(true);
                  if (needToMatchedAndIsEditing) {
                    setProceedTrue();
                  }
                  return;
                }
                // if ((`${res1?.[geDataName.lastName]}, ${res1?.[geDataName.firstName]}`) !== fullName) {}
                clearCache();
                const matchedIndex = radiosData?.indexOf(radioGropList.Matched);
                await matchProposer(sipCode,matchedIndex === -1 ? (needToMatchedAndIsEditing ? '' : geDataResult?.[0]?.[geDataName.geCode]) : geDataResult?.[matchedIndex]?.[geDataName.geCode], geId, !needToMatchedAndIsEditing);
                setChecked(false);
                dispatch(getGEInformation(sipCode));
                setIsEditableFalse();
                init();
                return;
              }
              commonSwitch(tableErrorType);
            }}
            className={styleModule.comfirmButton}
            disabled={!(userPermissions.includes(PERMISSIONS.SIP_WRITE_JPA) || userPermissions.includes(PERMISSIONS.SIP_WRITE_HANDLING_CE)) 
            || buttonAlertOpen 
            || needToMatchedAndIsEditing && (proceed || !radiosData?.some(i => Boolean(i)) && radiosData?.length)
            || isEditing && !checked
          }
          />
        </StrictMode>
      </SIModalPro>
      {
        open && <StrictMode>
          <AlertBox
            open={!buttonAlertOpen && errorTypeToAlert === FlaggedGESynchronizationAndVerificationErrorType.notMatchedGE}
            title='Please check SIMT GE Database section before acknowledgement.'
            message='You can choose one SIMT GE as matched or all SIMT GE as mismatched.'
            autoHideDuration={4e3}
            {...AlertBoxCommonProps}
          />
          <ButtonAlertBox
            open={buttonAlertOpen}
            title='The connection between GE-Proposer and GE-in database is currently disrupted.'
            message='Please refresh the page to check  the most recent status update.'
            autoHideDuration={null}
            buttons={[
              {
                text: 'Refresh',
                onClick: () => location.reload(),
              },
            ]}
            position={{ vertical: 'top', horizontal: 'center' }}
            top={`${top}px`}
            {...AlertBoxCommonProps}
          />
          {(() => {
            const UntitledAlertBoxOpen = open && !buttonAlertOpen && (errorTypeToAlert === FlaggedGESynchronizationAndVerificationErrorType.moreThanOneMatchedGE || isCheckBoxError);
            return <UntitledAlertBox
              open={UntitledAlertBoxOpen}
              {...(UntitledAlertBoxOpen && { message: isCheckBoxError ? 'Please confirm the required action.' : 'You cannot match more than one SIMT GE to a proposer' })}
              {...AlertBoxCommonProps}
            />;
          })()}
        </StrictMode>}
    </StrictMode>
  );
}
NewFlagMatchingModal.prototype = freeze({
  open: bool,
  handleClose: func,
  id: string,
  sipCode: string,
  theGeInformation: object,
  index: number,
});
NewFlagMatchingModal.defaultProps = freeze({
  open: false,
  handleClose: () => { },
  id: 'SIPPage_Detail-NewFlagMatchingModalModal',
  sipCode: '',
  theGeInformation: {},
  index: 0,
});

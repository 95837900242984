import EncryptedTextField from '@/pages/Login/NewLogin/Components/EncryptedTextField';
import { Typography, Button, Box, Snackbar, useRadioGroup } from '@mui/material';
import styled from '@emotion/styled';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useRevalidator } from 'react-router-dom';
import { NewDesignedMain } from '../../../components/Main';
import { clear } from '@/actions/SIMT-User/Login/LoginAction';
import { setSnackbarMessageAndOpen } from '../../../actions/SnackbarAction';
import { SEVERITIES } from '../../../modules/ConfirmSnackBar/CommonSnackBar';
import ChangePasswordError from '@/assets/LoginAsset/ChangePasswordError.svg';
import PasswordUnmatched from '@/assets/LoginAsset/PasswordUnmatch.svg';
import { UntitledAlertBox } from '@/components/Alert/AlertBox';
import { set } from 'date-fns';
import { updatePass } from "@/actions/SIMT-User/Login/User";
import { logout } from "@/actions/SIMT-User/Login";
import { resetPassFirstTime } from "@/actions/SIMT-User/Login/User";
import {getUserId} from '@/utils/getUserId';
const PasswordTextField = styled(EncryptedTextField)({
	width: '400px',
	height: '40px',
	'& .MuiInputBase-root': {
		backgroundColor: '#FFF',
	},
});

const ChangePasswordTitleTypography = styled(Typography)({
	color: 'var(--gray-500, #596A7C)',
	leadingTrim: 'both',
	textEdge: 'cap',
	fontSize: '20px',
	fontFamily: 'Open Sans',
	fontStyle: 'normal',
	fontWeight: '800',
	lineHeight: '125%',
	letterSpacing: '0.2px',
});

const InfoTypography = styled(Typography)({
	color: 'var(--gray-300, #BCC5CF)',
	leadingTrim: 'both',
	textEdge: 'cap',
	fontSize: '14px',
	fontFamily: 'Open Sans',
	fontStyle: 'normal',
	fontWeight: '400',
	lineHeight: '20px',
});

const LabelTypography = styled(Typography)({
	color: 'var(--gray-500, #596A7C)',
	leadingTrim: 'both',
	textEdge: 'cap',
	fontSize: '16px',
	fontFamily: 'Open Sans',
	fontWeight: '600',
	lineHeight: '125%',
});

const WarningTextTypography = styled(Typography)`
  color: var(--error-600, #ee5350);
  font-size: 14px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const ResetButton = styled(Button)({
	borderRadius: '5px',
	background: 'var(--primary-500, #0052CC)',
	color: 'var(--shades-white, #FFF)',
	textAlign: 'center',
	fontSize: '18px',
	fontFamily: 'Open Sans',
	fontWeight: '600',
	lineHeight: '18px',
	width: '400px',
	height: '40px',
	padding: '8px 12px',
	gap: '6px',
	'&:hover': {
		background: 'var(--primary-300, #6D98EE)',
	},

	'&:focus': {
		background: 'var(--primary-600, #113D95)',
	},

	'&:disabled': {
		background: 'var(--gray-200, #DFE4E8)',
	},
});

const ChangePasswordWindow = props => {
	const boxWidth = 682;
	const boxHeight = 535;

	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [newPasswordHelperTextShow, setNewPasswordHelperTextShow] =
		useState(false);
	const [confirmPasswordHelperTextShow, setConfirmPasswordHelperTextShow] =
		useState(false);
	const [currentPasswordHelperTextShow, setCurrentPasswordHelperTextShow] =
		useState(false);

	const navigator = useNavigate();
	const selector = state => {
		return {
			updatePassReturn: state.Login.resetPass,
			updatePassNotificationsTrigger:
				state.Login.updatePassNotificationsTrigger,
			
		};
	};
	const { updatePassReturn, updatePassNotificationsTrigger } =
		useSelector(selector);
	const dispatch = useDispatch();

	const [alertShow, setAlertShow] = useState(false);

	useEffect(() => {
		// console.log(updatePassReturn, updatePassNotificationsTrigger);
		if (updatePassReturn === true) {
			handleUpdatePass();
		} else {
			if (updatePassNotificationsTrigger !== 0) {
				setAlertShow(true);
				setCurrentPasswordHelperTextShow(true);
			}
		}
		return () => {
			dispatch(clear());
		};
	}, [updatePassReturn, updatePassNotificationsTrigger]);

	const ErrorAlert = () => {
		return (
			<Snackbar
				open={alertShow}
				autoHideDuration={6000}
				onClose={() => setAlertShow(false)}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				style={{ top: '76px', right: '20px' }}
			>
				<PasswordUnmatched />
			</Snackbar>
		);
	};

	const handleUpdatePass = () => {
		if (updatePassReturn) {
			dispatch(
				setSnackbarMessageAndOpen(
					'Update the password successfully,Please login again.',
					{},
					SEVERITIES.success
				)
			);
			setTimeout(() => {
				dispatch(logout());
				navigator('/simt/auth/index');
			}, 500);
		}
	};

	function testPassword(password) {
		//判断密码至少由3种字符组成：1.数字 2 小写字母 3 大写字母 4 特殊字符
		var regNumber = new RegExp(/.*[0-9]+.*/);
		var regUpper = new RegExp(/.*[A-Z]+.*/);
		var regLower = new RegExp(/.*[a-z]+.*/);
		var regSymbol = new RegExp(/.*[\x21-\x2f,\x3a-\x40\x5b-\x60\x7b-\x7e]+.*/);
		var count = 0;
		if (regNumber.test(password)) {
			count++;
		}
		if (regUpper.test(password)) {
			count++;
		}
		if (regLower.test(password)) {
			count++;
		}
		if (regSymbol.test(password)) {
			count++;
		}
		//判断密码位数必须大于8位
		if (password.length < 8 || password.length > 32 || count < 3) {
			return false;
		} else {
			return true;
		}
	}

	// const testPassword = password => {
	//   const passwordRegex =
	//     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
	//   return passwordRegex.test(password);
	// };

	const testConfirmPassword = (password, confirmPassword) => {
		return password === confirmPassword;
	};

	const handleSubmit = () => {
		let flag = true;
		setCurrentPasswordHelperTextShow(false);
		if (!testPassword(newPassword)) {
			setNewPasswordHelperTextShow(true);
			flag = false;
		} else {
			setNewPasswordHelperTextShow(false);
		}
		if (!testConfirmPassword(newPassword, confirmPassword)) {
			setConfirmPasswordHelperTextShow(true);
			flag = false;
		} else {
			setConfirmPasswordHelperTextShow(false);
		}
		if (!flag) {
			return;
		}
		dispatch((resetPassFirstTime(getUserId(),currentPassword, newPassword)));
	};

	return (
		<Box
			style={{
				width: boxWidth,
				height: boxHeight,
				flexShrink: 0,
				borderRadius: '5px',
				background: 'var(--shades-white, #FFF)',
				boxShadow: '0px 6px 12px 0px rgba(38, 46, 53, 0.12)',
				paddingTop: '35px',
				paddingBottom: '40px',
				paddingLeft: '141px',
			}}
			data-selenium-id='AuthPage_NewUpdatePassword-Window'
		>
			<div
				style={{}}
				data-selenium-id='AuthPage_NewUpdatePassword-ChangePasswordTitleTypography'
			>
				<ChangePasswordTitleTypography>
					Change Password
				</ChangePasswordTitleTypography>
			</div>
			<div
				style={{ marginTop: '12px' }}
				data-selenium-id='AuthPage_NewUpdatePassword-InfoTypography'
			>
				<InfoTypography>
					Password length must be at least 8 characters long and no more than 32 characters long, including 3 of the
					following: uppercase letters, lowercase letters, digits, and special
					characters.
				</InfoTypography>
			</div>
			<div
				style={{ marginTop: '35px' }}
				data-selenium-id='AuthPage_NewUpdatePassword-OldPassword'
			>
				<LabelTypography>Current Password</LabelTypography>
				<div style={{ height: '8px' }} />
				<EncryptedTextField
					width='400px'
					height='40px'
					value={currentPassword}
					setValue={setCurrentPassword}
					isError={currentPasswordHelperTextShow}
					onFocus={() => {
						setCurrentPasswordHelperTextShow(false);
					}}
				/>
			</div>
			<div
				style={{ marginTop: '18px' }}
				data-selenium-id='AuthPage_NewUpdatePassword-NewPassword'
			>
				<LabelTypography>New Password</LabelTypography>
				<div style={{ height: '8px' }} />
				<EncryptedTextField
					width='400px'
					height='40px'
					value={newPassword}
					setValue={setNewPassword}
					isError={newPasswordHelperTextShow}
					onFocus={() => {
						setNewPasswordHelperTextShow(false);
					}}
				/>
			</div>
			<div
				style={{ marginTop: '4px' }}
				data-selenium-id='AuthPage_NewUpdatePassword-ErrorAlert'
			>
				{newPasswordHelperTextShow ? (
					<WarningTextTypography style={{ maxWidth: '400px' }} >
						The length of password should be at least 8 characters,
						including
						at least 3 types of uppercase letter, lower case letter, digit, and
						special character.
					</WarningTextTypography>
				) : (
					<div></div>
				)}
			</div>
			<div
				style={{ marginTop: '18px' }}
				data-selenium-id='AuthPage_NewUpdatePassword-ConfirmPassword'
			>
				<LabelTypography>Confirm Password</LabelTypography>
				<div style={{ height: '8px' }} />
				<EncryptedTextField
					width='400px'
					height='40px'
					value={confirmPassword}
					setValue={setConfirmPassword}
					isError={confirmPasswordHelperTextShow}
					onFocus={() => {
						setConfirmPasswordHelperTextShow(false);
					}}
				/>
			</div>
			<div
				style={{ marginTop: '4px' }}
				data-selenium-id='AuthPage_NewUpdatePassword-ErrorAlert'
			>
				{confirmPasswordHelperTextShow ? (
					<WarningTextTypography>
						The passwords you entered don't match. Please try again.
					</WarningTextTypography>
				) : (
					<div style={{ height: '20px' }}></div>
				)}
			</div>
			<div
				style={{ marginTop: newPasswordHelperTextShow ? '10px' : '45px' }}
				data-selenium-id='AuthPage_NewUpdatePassword-ResetButton'
			>
				<ResetButton
					onClick={handleSubmit}
					disabled={newPasswordHelperTextShow || confirmPasswordHelperTextShow || currentPasswordHelperTextShow}
				>
					RESET PASSWORD
				</ResetButton>
			</div>
			<ErrorAlert />
		</Box>
	);
};

const UpdatePasswordPage = props => {
	const selector = state => {
		return {
			sidebarOpen: state.UserManage.siderOpen,
		};
	};
	const { sidebarOpen } = useSelector(selector);

	const [scaleX, setScaleX] = useState(1);
	const [scaleY, setScaleY] = useState(1);
	const originWidth = 1440;
	const originHeight = 800;

	useEffect(() => {
		const handleResize = () => {
			const screenWidth = window.innerWidth;
			const screenHeight = window.innerHeight;
			const newScaleX = screenWidth / originWidth;
			const newScaleY = screenHeight / originHeight;
			setScaleX(Math.max(newScaleX, 1));
			setScaleY(Math.max(newScaleY, 1));
		};
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<NewDesignedMain open={sidebarOpen}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '80vh',
				}}
				data-selenium-id='AuthPage_NewUpdatePasswordPage'
			>
				<ChangePasswordWindow />
			</div>
		</NewDesignedMain>
	);
};

export default UpdatePasswordPage;

// @ts-check
import React, { useEffect, useState } from 'react';
import { Box, Stack, Collapse, Typography } from '@mui/material';
import { SectionTitleBlock } from '../CustomComponents/CustomBlock';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import LabelAndValue from '../../../../components/LabelAndValue';
import {
  ItemBlock,
  SIPDetailSelectBox,
} from '../../SIPDetailPage/EditComponents';
import { useParams } from 'react-router-dom';
import { MiniFont } from '@/components/FontStyle';
import { SectionContainer } from '../CustomComponents/CustomContainer';
import { ItemBlockShowText } from '../CustomComponents/CustomTypography';
import SinpleAutocomplete from '@/componentsphase2/Autocomplete/SinpleAutocomplete';
import { getSIPDetailObjectChoiceJournalDropList } from "@/actions/SIMT-SIP/SIP/SipDetail";
import { getATEJournalDropList, getATEJournalDropListforSSIP } from "@/actions/SIMT-SIP/SIP/Journal";

/**
 *
 * @param {import("./ObjectSection").SSIPObjectEditSectionProps} props
 * @returns
 */
export const SSIPObjectEditSection = props => {
  const {
    value: {
      journal1Name,
      journal1Code,
      journalHomePage,
      authorGuidelines,
    },
    setValue: setSipObject,
    showErrorMessage = false,
    setError = () => { },
    isSSIP,
    state,
  } = props;
  const selector = state => {
    return {
      ateJournalDropList:
        state.SIP.ateJournalDropList,
      sipDetailObjectPreferredSubjectAreaResult:
        state.SIP.sipDetailObjectPreferredSubjectAreaResult,
      sipDetailObjectEditResult: state.SIP.sipDetailObjectEditResult,
      sipDetailObjectEditMonitor: state.SIP.sipDetailObjectEditMonitor,
    };
  };
  const {
    ateJournalDropList,
    sipDetailObjectPreferredSubjectAreaResult,
  } = useSelector(selector);

  /**
   * @typedef {import("../ProposerRevisePage").SIPProposerReviseData} SIPReviseData
   */

  const [preferredSubjectAreaError, setPreferredSubjectAreaError] =
    useState(false);

  useEffect(() => {

    setPreferredSubjectAreaError(
      journal1Name === '' || journal1Name === null
    );
  }, [journal1Name]);

  useEffect(() => {
    setError(preferredSubjectAreaError);
  }, [preferredSubjectAreaError]);

  const [preferredSubjectAreaDropList, setPreferredSubjectAreaDropList] =
    useState([]);
  const [choiceJournalDropList, setChoiceJournalDropList] = useState([]);

  useEffect(() => {
    setChoiceJournalDropList(
      ateJournalDropList
        ? ateJournalDropList
        : []
    );
  }, [ateJournalDropList]);

  useEffect(() => {
    setPreferredSubjectAreaDropList(
      sipDetailObjectPreferredSubjectAreaResult
        ? sipDetailObjectPreferredSubjectAreaResult
        : []
    );
  }, [sipDetailObjectPreferredSubjectAreaResult]);


  const { token } = useParams();

  /**
   * @type {import("./ObjectSection").HandleObjectChange}
   */
  const handleObjectChange = newValue => {
    setSipObject(current => ({
      ...current,
      [newValue.key]: newValue.value,
    }));
  };
  /**
   *
   * @param {string} name
   * @returns
   */
  const getJounralNameByToken = name => {
    return getATEJournalDropListforSSIP(
      name,
      `/api/v1/sip-service/special-issue-proposals/revise/${token}/field-metadata`,
      true
    );
  };
  /**
   *
   * @param {string} name
   * @returns
   */
  const getSIPDetailObjectChoiceJournalDropListByToken = name => {
    return getSIPDetailObjectChoiceJournalDropList(
      name,
      `/api/v1/sip-service/special-issue-proposals/revise${token}/field-metadata`,
      {},
      true
    );
  };
  useEffect(() => {
    handleObjectChange({
      key: 'choiceJournal1st',
      value: choiceJournalDropList.length === 0 ? journal1Code : choiceJournalDropList.filter((item) => item.journalName === journal1Name)?.[0]?.journalCode ?? '',
    });
  }, [journal1Name]);
  return (
    <SectionContainer
      title='Object'
      data-selenium-id='SSIP_RevisePage_Section-Object-Edit-Container'
    >
      <Stack
        data-selenium-id='SSIP_RevisePage_Section-Object-Edit-Stack'
        spacing={2}
        className='ObjectSection-container'
        sx={{
          paddingTop: '6.5px',
          '&.ObjectSection-container .MuiOutlinedInput-root .MuiAutocomplete-input':
          {
            paddingY: 0,
          },
          // marginTop:'-7px',
        }}
      >
        <ItemBlock
          title='Journal Name:'
          mandatory={true}
          sx={{ marginTop: '0px!important', height: state === 'edit' ? '20px!important' : '20px', width: '513px', marginBottom: '6.5px' }}
        >
          {state === 'edit' ? <>
            <Box sx={{ marginTop: '-6.5px' }}>
              <SinpleAutocomplete
                id='SIPRevisejournal1Name'
                data-selenium-id='SSIP_RevisePage_Section-Object-Edit-journal1Name'
                value={journal1Name}
                options={choiceJournalDropList.map(v => v.journalName)}
                isEmpty={false}
                onChange={(_event, newValue) => {
                  if (Array.isArray(newValue)) return;
                  handleObjectChange({
                    key: 'journal1Name',
                    value: newValue || '',
                  });
                }}
                needKeyboardDelete={false}
                loadOptionAction={name => getJounralNameByToken(name)}
                placeholder={''}
                needClearInputValue={true}
                sx={{ innerHeight: '33px', marginBottom: '6.5px' }}
                isSSIP={true}
              ></SinpleAutocomplete>
            </Box>
            {preferredSubjectAreaError && showErrorMessage && (
              <Box
                sx={{ ...MiniFont, color: '#EE5350' }}
                data-selenium-id='SSIP_RevisePage_Section-Object-Edit-TipBox'
              >
                This field is required.
              </Box>
            )}</> :
            <Typography sx={{ paddingTop: '0px', height: '20px', fontFamily: 'Open Sans', fontWeight: 400, fontSize: '14px', lineHeight: '20px', verticalAlign: 'center', color: '#262E35' }}>
              {journal1Name}
            </Typography>}
        </ItemBlock>
        <ItemBlock title='Journal Homepage:' sx={{ marginTop: '8px!important', height: '20px!important', width: '852px' }}>
          <Typography sx={{ paddingTop: '0px', height: '20px', fontFamily: 'Open Sans', fontWeight: 400, fontSize: '14px', lineHeight: '20px', verticalAlign: 'center', color: state === 'edit' ? '#BCC5CF' : '#262E35' }}>
            {choiceJournalDropList.filter((item) => item.journalName === journal1Name)?.[0]?.journalHomePage ?? 'Automatically filled after entering Journal Code/Name'}
          </Typography>
        </ItemBlock>
        <ItemBlock title='Author Guidelines:' sx={{ marginTop: '8px!important', height: '20px!important', width: '852px' }}>
          <Typography sx={{ paddingTop: '0px', height: '20px', fontFamily: 'Open Sans', fontWeight: 400, fontSize: '14px', lineHeight: '20px', verticalAlign: 'center', color: state === 'edit' ? '#BCC5CF' : '#262E35' }}>
            {choiceJournalDropList.filter((item) => item.journalName === journal1Name)?.[0]?.authorGuidelines ?? 'Automatically filled after entering Journal Code/Name'}
          </Typography>
        </ItemBlock>
      </Stack>
    </SectionContainer>
  );
};

/**
 *
 * @param {import("./ObjectSection").ObjectShowSectionProps} props
 * @returns
 */
export const SSIPObjectShowSection = props => {
  const { value } = props;
  const getObjectSectionSurplusItems = () => {
    switch (value.type) {
      case 'ProposerRevise':
        return (
          <>
            {/* <ItemBlock title={`1st Choice Journal:`}>
              <ItemBlockShowText data-selenium-id='SSIP_RevisePage_Section-Object-Show-ChoiceJournalText1'>
                {value.sipObject.choiceJournal1st}
              </ItemBlockShowText>
            </ItemBlock>
            <ItemBlock title={`2nd Choice Journal:`}>
              <ItemBlockShowText data-selenium-id='SSIP_RevisePage_Section-Object-Show-ChoiceJournalText2'>
                {value.sipObject.choiceJournal1st}
              </ItemBlockShowText>
            </ItemBlock>
            <ItemBlock title={`3rd Choice Journal:`}>
              <ItemBlockShowText data-selenium-id='SSIP_RevisePage_Section-Object-Show-ChoiceJournalText3'>
                {value.sipObject.choiceJournal3rd}
              </ItemBlockShowText>
            </ItemBlock> */}
          </>
        );
      case 'JPMReview':
        // return value.sipObject.recommendedJournals.map(
        //   (recommendedJournal, index) => (
        //     <ItemBlock title={`Recommended Journal ${index + 1}:`} key={index}>
        //       <ItemBlockShowText data-selenium-id='SSIP_RevisePage_Section-Object-Show-JPMItemBlockText'>
        //         {recommendedJournal}
        //       </ItemBlockShowText>
        //     </ItemBlock>
        //   )
        // );
        return <ItemBlock title={`Journal Code:`}>
          <ItemBlockShowText data-selenium-id='SSIP_RevisePage_Section-Object-Show-ChoiceJournalText3'>
            {value.sipObject?.journal1Code || 'N/A'}
          </ItemBlockShowText>
        </ItemBlock>;
      case 'EditorReview':
        return <></>;
      case 'ProposalEditorReview':
        return (
          // <ItemBlock title={`Accepted Journal Name:`}>
          //   <ItemBlockShowText data-selenium-id='SSIP_RevisePage_Section-Object-Show-ProposalEditorItemText'>
          //     {value.sipObject.acceptedJournal1Name}
          //   </ItemBlockShowText>
          // </ItemBlock>
          <></>
        );
    }

    return <></>;
  };

  return (
    <SectionContainer
      title='Object'
      data-selenium-id='SSIP_RevisePage_Section-Object-Show-Container'
    >
      <Stack
        spacing='8px'
        data-selenium-id='SSIP_RevisePage_Section-Object-Show-Stack'
      >
        {getObjectSectionSurplusItems()}
        <ItemBlock title='Journal Name:'>
          <ItemBlockShowText data-selenium-id='SSIP_RevisePage_Section-Object-Show-JournalNameText'>
            {value.sipObject?.journal1Name || 'N/A'}
          </ItemBlockShowText>
        </ItemBlock>
        <ItemBlock title='Journal Homepage:'>
          <ItemBlockShowText data-selenium-id='SSIP_RevisePage_Section-Object-Show-JournalHomepageText'>
            {value.sipObject?.journal1HomePage || 'N/A'}
          </ItemBlockShowText>
        </ItemBlock>
        <ItemBlock title='Author Guidelines:'>
          <ItemBlockShowText data-selenium-id='SSIP_RevisePage_Section-Object-Show-AuthorGuidelinesText'>
            {value.sipObject?.journal1AuthorGuidelines || 'N/A'}
          </ItemBlockShowText>
        </ItemBlock>
      </Stack>
    </SectionContainer>
  );
};

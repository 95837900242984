/**
 * Huang Tao
 */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Grid,
  Tooltip,
  TableCell,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from '@/components/DataTable';
import {
  StyledTableCellPurple,
  StyledTableCellDisPurple,
  StyledTableCellGreen,
  StyledTableCellDisGreen,
  StyledTableRow, TableBox, TableLabel, ReportNoData, TableBox2
} from "../../ReportSpecificComponents/SpecialColorTableHeader";
import { StyledSwitchTwoColor } from '../../ReportSpecificComponents/SpecialColorSwitch';
import { findLongestLineSubstring, measureWidth } from "../../../../utils/StringWidthMeasure";
import {GetArticleOAPCETableData, GetArticleOverviewCETableData} from "@/actions/SIMT-Report/Report/ReportArticle";

export function ArticleOverviewCETable(props) {
  const { ifNoResult } = props;

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { currentDate, tableMonth, table3Data } = useSelector(state => {
    return {
      // currentDate: state.Report.selectedArticleDate,
      currentDate: state.Report.selectDate,
      tableMonth: state.Report.articleOAPCETableMonth,
      table3Data: state.Report.articleOAPCETableData,
    };
  });

  const [columnWidthMap, setColumnWidthMap] = useState({});
  const [columnMinWidthMap, setColumnMinWidthMap] = useState({});

  useEffect(() => {
    const widthMap = {};
    widthMap['catGroup'] = measureWidth(findLongestLineSubstring('CAT Group'), '18px Open Sans');
    widthMap['handlingCe'] = measureWidth(findLongestLineSubstring('Handling CE'), '18px Open Sans');

    const CUR_LEN = measureWidth(findLongestLineSubstring('Total Publication'), '18px Open Sans');
    const YTD_LEN = measureWidth(findLongestLineSubstring('OA Publication'), '18px Open Sans');
    const OAP_LEN = measureWidth(findLongestLineSubstring('OA%'), '18px Open Sans');

    for (let type of ['sub','acc']) {
      widthMap[`${type}-0`] = CUR_LEN;
      widthMap[`${type}-1`] = YTD_LEN;
      widthMap[`${type}-2`] = OAP_LEN;
    }

    setColumnMinWidthMap(widthMap);
  }, []);

  useEffect(() => {
    if (table3Data != null && table3Data.count > 0 ){
      const maxMap = {};
      for (let i = 0; i < table3Data['result'].length; i++) {
        let row = table3Data['result'][i];
        let dataObj = {
          catGroup: row.catGroup,
          handlingCe: row.handlingCe,

          'sub-0':  row.totalPublishedArt ?? '0',
          'sub-1':  row.oaPublishedArt ?? '0',
          'sub-2':  row.oaPublishedArtPercentage ?? '0',

          'acc-0':  row.totalPublishedArtYtd ?? '0',
          'acc-1':  row.oaPublishedArtYtd ?? '0',
          'acc-2':  row.oaPublishedArtYtdPercentage ?? '0',
        };
        for (let key in dataObj) {
          if (dataObj.hasOwnProperty(key)) {
            let value = `${dataObj[key]}`;
            let maxLength = maxMap[key]?.length ?? 0;
            if (value.length > maxLength){
              maxMap[key] = value;
            }
          }
        }
      }

      const widthMap = {};
      for (let key in maxMap) {
        if (maxMap.hasOwnProperty(key)) {
          widthMap[key] = measureWidth(maxMap[key], '18px Open Sans');
        }
      }

      setColumnWidthMap(widthMap);
    }
  }, [table3Data]);

  function calcGroupWidth(type){
    let sum = 0;
    for (let i of [0,1,2]) {
      sum += Math.max((columnWidthMap[`${type}-${i}`]??0), columnMinWidthMap[`${type}-${i}`]) + 50;
    }

    return sum;
  }

  useEffect(() => {
    async function getData(currentDate) {
      if (currentDate != null) {
        if (currentDate != null) {
          setIsLoading(true);
          setTimeout(() => {
            dispatch(GetArticleOAPCETableData(currentDate, ));
          }, 2000);
        }
      }
    }
    getData(currentDate);
  }, [currentDate]);

  useEffect(() => {
    setIsLoading(false);
  }, [table3Data]);

  const Subitems = [
    {
      id: 'Sub-MMM',
      label: 'Total Publication',
      maxWidth: '113px',
      minWidth: '113px',
      isOrder: false,
    },
    {
      id: 'Sub-YTD',
      label: 'OA Publication',
      maxWidth: '113px',
      minWidth: '113px',
      isOrder: false,
    },
    {
      id: '%Against YTD Target',
      label: 'OA%',
      maxWidth: '113px',
      minWidth: '113px',
      isOrder: false,
    },
  ];
  const Accitems = [
    {
      id: 'Acc-MMM',
      label: 'Total Publication',
      maxWidth: '113px',
      minWidth: '113px',
      isOrder: false,
    },
    {
      id: 'Acc-YTD',
      label: 'OA Publication',
      maxWidth: '113px',
      minWidth: '113px',
      isOrder: false,
    },
    {
      id: '%Against YTD Target',
      label: 'OA%',
      maxWidth: '113px',
      minWidth: '113px',
      isOrder: false,
    },
  ];
  {/*const Pubitems = [
    {
      id: 'Pub-MMM',
      label: 'Pub - ',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: 'Pub-YTD',
      label: 'Pub - YTD',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: '%Against YTD Target',
      label: '% Against YTD Target',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
    {
      id: '%Against YTD Stretch target',
      label: '% Against YTD Stretch target',
      maxWidth: '50px',
      minWidth: '50px',
      isOrder: false,
    },
  ];*/}

  const showDataTableSG = table3Data => {
    if (table3Data === undefined || table3Data === null) {
      return [];
    } else return table3Data;
  };

  //total字体加深
  function DisplayTooltip(props) {
    const { ifTotal } = props;
    if (ifTotal === 'Total' || ifTotal == 'Subtotal')
      return (
        <div >
          {ifTotal}
        </div>
      );
    else {
      return <div align='left'>{ifTotal}</div>;
    }
  }

  const [hover, setHover] = useState(false);
  const [indexs, setIndexs] = useState(0);
  const [hovera, setHovera] = useState(false);
  const [indexsa, setIndexsa] = useState(0);

  return (
    <>
      <TableLabel>CE/CAT</TableLabel>
      <TableBox2
        id='BoxTable2'
        data-selenium-id='Report_Articles_Overview-CETableGrid-BoxTable2'
        dataList={[table3Data]}
        tableKey={'Report_Articles-OAP-CETable'}
        footerHeight={0}
      >
        <TableContainer
          id='reportArticlesOverviewCETable'
          data-selenium-id='Report_Articles_OPA-CETable'
          sx={{
            maxHeight: '600px',
            border: '1px solid #DFE4E8',
            borderRadius: '8px',
            // width: '990px',
            width: '100%',
          }}
        >
          <Table stickyHeader aria-label='sticky table'
                 sx={{
                   tableLayout: 'fixed',
                   width: 'max-content',
                   maxWidth: 'none',
                 }}
          >
            <TableHead data-selenium-id='Report_Articles_OPA-CETableGrid-TableHeader'>
              <TableRow data-selenium-id='Report_Articles_OPA-CETableGrid-TableRow'>
                <TableCell
                  align='left'
                  rowSpan={2}
                  sx={{
                    //有问题，实际宽度更宽
                    // width: '140px',
                    // minWidth: '140px',
                    // maxWidth: '140px',
                    // height: '102px',
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    fontWeight: 600,
                    // lineHeight: '18px',
                    // letterSpacing: '0.08em',
                    // padding: '12px 24px',
                    // color: '#596A7C',
                    // background: '#DFE4E8',
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                    width: `${Math.max((columnWidthMap['catGroup']??0), columnMinWidthMap['catGroup']) + 50 }px`,
                    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                  }}
                  //style={{ backgroundColor: !OASwitch ? '#96DCA9' : '#CCD4FF' }}
                  //className='BottomBorder'
                  data-selenium-id='Report_Articles_OPA-CETableGrid-TableHeader-CATGroup'
                >
                  CAT GROUP
                </TableCell>
                <TableCell
                  align='left'
                  rowSpan={2}
                  sx={{
                    // width: '161px',
                    // minWidth: '161px',
                    // maxWidth: '161px',
                    // height: '102px',
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    fontWeight: 600,
                    // lineHeight: '18px',
                    // letterSpacing: '0.08em',
                    // padding: '12px 24px',
                    // color: '#596A7C',
                    // background: '#DFE4E8',
                    width: `${Math.max((columnWidthMap['handlingCe']??0), columnMinWidthMap['handlingCe']) + 50 }px`,
                    position: 'sticky',
                    left: `${Math.max((columnWidthMap['catGroup']??0), columnMinWidthMap['catGroup']) + 50 }px`,
                    top: 0,
                    zIndex: 8,
                    backgroundColor: '#DFE4E8',
                    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                  }}
                  //style={{ backgroundColor: !OASwitch ? '#96DCA9' : '#CCD4FF' }}
                  //className='RightBottomBorder'
                  data-selenium-id='Report_Articles_OPA-CETableGrid-TableHeader-HandingCE'
                >
                  HANDLING CE
                </TableCell>
                <TableCell
                  align='left'
                  className='ReportHeaderFirstLine'
                  colSpan={3}
                  sx={{
                    padding: '12px 0px 12px 24px',
                    height: '42px',
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '18px',
                    letterSpacing: '0.08em',
                    color: '#FFFFFF',
                    background: '#98A7B6',
                    borderLeftWidth: '2px',
                    borderLeftStyle: 'solid',
                    borderLeftColor: '#FFFFFF',
                    width: `${calcGroupWidth('sub')}px`,
                    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                  }}
                  //className='RightBottomBorder'
                  //style={{ backgroundColor: !OASwitch ? '#96DCA9' : '#CCD4FF' }}
                  data-selenium-id='Report_Articles_OPA-CETableGrid-TableHeader-Submission'
                >
                  PUBLICATION IN {tableMonth}
                </TableCell>
                <TableCell
                  align='left'
                  className='ReportHeaderFirstLine'
                  colSpan={3}
                  sx={{
                    padding: '12px 0px 12px 24px',
                    height: '42px',
                    fontFamily: 'Open Sans',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '18px',
                    letterSpacing: '0.08em',
                    color: '#FFFFFF',
                    background: '#98A7B6',
                    borderLeftWidth: '2px',
                    borderLeftStyle: 'solid',
                    borderLeftColor: '#FFFFFF',
                    width: `${calcGroupWidth('acc')}px`,
                    boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                  }}
                  //className='RightBottomBorder'
                  //style={{ backgroundColor: !OASwitch ? '#96DCA9' : '#CCD4FF' }}
                  data-selenium-id='Report_Articles_OPA-CETableGrid-TableHeader-Acceptance'
                >
                  PUBLICATION YTD
                </TableCell>
                {/*<StyledTableCellPurple
                  align='left'
                  colSpan={!OASwitch ? 4 : 3}
                  className='RightBottomBorder'
                  style={{ backgroundColor: !OASwitch ? '#96DCA9' : '#CCD4FF' }}
                  data-selenium-id='Report_Articles_Overview-CETableGrid-TableHeader-Publication'
                >
                  Publication
                </StyledTableCellPurple>*/}
              </TableRow>
              <TableRow>
                {Subitems.map((item, index) =>
                  <TableCell
                    align='left'
                    key={item.id}
                    sx={{
                      top: 42.8,
                      // maxWidth: item.maxWidth,
                      // minWidth: item.minWidth,
                      // width: '113px',
                      width: `${Math.max((columnWidthMap[`sub-${index}`]??0), columnMinWidthMap[`sub-${index}`]) + 50 }px`,
                      height: '60px',
                      padding: '12px 24px',
                      fontFamily: 'Open Sans',
                      fontSize: '12px',
                      fontWeight: 600,
                      lineHeight: '18px',
                      letterSpacing: '0px',
                      color: '#596A7C',
                      background: '#F1F3F5',
                      borderLeftWidth: item.id === 'Sub-MMM' ? '2px' : null,
                      borderLeftStyle: item.id === 'Sub-MMM' ? 'solid' : null,
                      borderLeftColor: item.id === 'Sub-MMM' ? '#FFFFFF' : null,
                      boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                    }}
                  /*className={
                    (!OASwitch &&
                      item.id === '%Against YTD Stretch target') ||
                    (OASwitch && item.id === '%Against YTD Target')
                      ? 'RightBottomBorder'
                      : 'BottomBorder'
                  }
                  style={{
                    backgroundColor: !OASwitch ? '#abe3ba' : '#d6ddff',
                  }}*/
                  >
                    {item.label}
                  </TableCell>
                )}
                {Accitems.map((item, index) =>
                  <TableCell
                    align='left'
                    key={item.id}
                    sx={{
                      top: 42.8,
                      height: '60px',
                      padding: '12px 24px',
                      // width: '113px',
                      // maxWidth: item.maxWidth,
                      // minWidth: item.minWidth,
                      width: `${Math.max((columnWidthMap[`acc-${index}`]??0), columnMinWidthMap[`acc-${index}`]) + 50 }px`,
                      fontFamily: 'Open Sans',
                      fontSize: '12px',
                      fontWeight: 600,
                      lineHeight: '18px',
                      letterSpacing: '0px',
                      color: '#596A7C',
                      background: '#F1F3F5',
                      borderLeftWidth: item.id === 'Acc-MMM' ? '2px' : null,
                      borderLeftStyle: item.id === 'Acc-MMM' ? 'solid' : null,
                      borderLeftColor: item.id === 'Acc-MMM' ? '#FFFFFF' : null,
                      boxShadow: '0px -1px 0px 0px rgba(203, 213, 225, 1) inset',
                    }}
                  /*className={
                    (!OASwitch &&
                      item.id === '%Against YTD Stretch target') ||
                    (OASwitch && item.id === '%Against YTD Target')
                      ? 'RightBottomBorder'
                      : 'BottomBorder'
                  }
                  style={{
                    backgroundColor: !OASwitch ? '#abe3ba' : '#d6ddff',
                  }}*/
                  >
                    {item.label}
                  </TableCell>
                )}
                {/*{Subitems.map(item =>
                  OASwitch && item.id === '%Against YTD Stretch target' ? (
                    <></>
                  ) : (
                    <StyledTableCellDisPurple
                      align='right'
                      key={item.id}
                      sx={{
                        top: 52.5,
                        maxWidth: item.maxWidth,
                        minWidth: item.minWidth,
                      }}
                      className={
                        (!OASwitch &&
                          item.id === '%Against YTD Stretch target') ||
                        (OASwitch && item.id === '%Against YTD Target')
                          ? 'RightBottomBorder'
                          : 'BottomBorder'
                      }
                      style={{
                        backgroundColor: !OASwitch ? '#abe3ba' : '#d6ddff',
                      }}
                    >
                      {item.id === 'Sub-MMM' ||
                      item.id === 'Acc-MMM' ||
                      item.id === 'Pub-MMM'
                        ? item.label + tableMonth
                        : item.label}
                    </StyledTableCellDisPurple>
                  )
                )}
                {Accitems.map(item =>
                  OASwitch && item.id === '%Against YTD Stretch target' ? (
                    <></>
                  ) : (
                    <StyledTableCellDisPurple
                      align='right'
                      key={item.id}
                      sx={{
                        top: 52.5,
                        maxWidth: item.maxWidth,
                        minWidth: item.minWidth,
                      }}
                      className={
                        (!OASwitch &&
                          item.id === '%Against YTD Stretch target') ||
                        (OASwitch && item.id === '%Against YTD Target')
                          ? 'RightBottomBorder'
                          : 'BottomBorder'
                      }
                      style={{
                        backgroundColor: !OASwitch ? '#abe3ba' : '#d6ddff',
                      }}
                    >
                      {item.id === 'Sub-MMM' ||
                      item.id === 'Acc-MMM' ||
                      item.id === 'Pub-MMM'
                        ? item.label + tableMonth
                        : item.label}
                    </StyledTableCellDisPurple>
                  )
                )}
                {Pubitems.map((item, index) =>
                  OASwitch && item.id === '%Against YTD Stretch target' ? (
                    <></>
                  ) : (
                    <StyledTableCellDisPurple
                      align='right'
                      key={item.id}
                      sx={{
                        top: 52.5,
                        maxWidth: item.maxWidth,
                        minWidth: item.minWidth,
                      }}
                      className={
                        (!OASwitch &&
                          item.id === '%Against YTD Stretch target') ||
                        (OASwitch && item.id === '%Against YTD Target')
                          ? 'RightBottomBorder'
                          : 'BottomBorder'
                      }
                      style={{
                        backgroundColor: !OASwitch ? '#abe3ba' : '#d6ddff',
                      }}
                    >
                      {item.id === 'Sub-MMM' ||
                      item.id === 'Acc-MMM' ||
                      item.id === 'Pub-MMM'
                        ? item.label + tableMonth
                        : item.label}
                    </StyledTableCellDisPurple>
                  )
                    )}*/}
              </TableRow>
            </TableHead>
            <TableBody data-selenium-id='Report_Articles_OPA-CETableGrid-TableBody'>
              {!isLoading &&
                table3Data != null &&
                table3Data.count > 0 ? (
                  table3Data['result']?.map((row, index) => {
                  if (
                    row.handlingCe === 'Total' ||
                    row.handlingCe === 'Subtotal'
                  ) {
                    return (
                      <>
                        <TableRow
                          sx={{
                            height: '64px',
                            background: '#FFFFFF',
                            '&:hover':{
                              background: '#E8EDFB',
                            }
                          }}
                          onMouseEnter={() => {
                            setHover(true);
                            setIndexs(index);
                          }}
                          onMouseLeave={() => {
                            setHover(false);
                            setIndexs(index);
                          }}
                        >
                          <TableCell
                            align='right'
                            sx={{
                              position: 'sticky',
                              left: 0,
                              zIndex: 6,
                              background: hover && indexs === index ?'#E8EDFB':'#F1F3F5',
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '22.5px',
                              color: '#262E35',
                              padding: '12px 24px',
                            }}
                            data-selenium-id={`Report_Articles_OPA-CETableGrid-TableBody-Cell1-${index}`}
                          >
                            {/*<DisplayTooltip ifTotal={''} />*/}
                          </TableCell>
                          <TableCell
                            align='right'
                            sx={{
                              position: 'sticky',
                              left:  `${Math.max((columnWidthMap['catGroup']??0), columnMinWidthMap['catGroup']) + 50 }px`,
                              zIndex: 6,
                              background: hover && indexs === index ?'#E8EDFB':'#F1F3F5',
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '22.5px',
                              color: '#262E35',
                              padding: '12px 24px',
                            }}
                            //className='RightBorder ReportTotalTextSize'
                            data-selenium-id={`Report_Articles_OPA-CETableGrid-TableBody-Cell2-${index}`}
                          >
                            <DisplayTooltip
                              ifTotal={row.handlingCe}
                            />
                          </TableCell>
                          <TableCell
                            align='left'
                            sx={{
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '22.5px',
                              color: '#262E35',
                              padding: '12px 24px',
                            }}
                            //className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id={`Report_Articles_Overview-CETableGrid-TableBody-Cell3-${index}`}
                          >

                              {row.totalPublishedArt}

                          </TableCell>
                          <TableCell
                            align='left'
                            sx={{
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '22.5px',
                              color: '#262E35',
                              padding: '12px 24px',
                            }}
                            //className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id={`Report_Articles_OPA-CETableGrid-TableBody-Cell4-${index}`}
                          >

                              {row.oaPublishedArt}

                          </TableCell>
                          <TableCell
                            align='left'
                            sx={{
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '22.5px',
                              color: '#262E35',
                              padding: '12px 24px',
                            }}
                            data-selenium-id={`Report_Articles_OPA-CETableGrid-TableBody-Cell5-${index}`}
                          /*className={
                            OASwitch
                              ? 'RightBorder ReportNumberTextColor ReportTotalTextSize'
                              : 'NoBorder ReportNumberTextColor ReportTotalTextSize'
                          }*/
                          >

                              {' '}
                              {row.oaPublishedArtPercentage}

                          </TableCell>

                          <TableCell
                            align='left'
                            sx={{
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '22.5px',
                              color: '#262E35',
                              padding: '12px 24px',
                            }}
                            //className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id={`Report_Articles_OPA-CETableGrid-TableBody-Cell7-${index}`}
                          >

                              {' '}
                              {row.totalPublishedArtYtd}

                          </TableCell>
                          <TableCell
                            align='left'
                            sx={{
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '22.5px',
                              color: '#262E35',
                              padding: '12px 24px',
                            }}
                            //className='ReportNumberTextColor ReportTotalTextSize'
                            data-selenium-id={`Report_Articles_OPA-CETableGrid-TableBody-Cell8-${index}`}
                          >

                              {' '}
                              {row.oaPublishedArtYtd}

                          </TableCell>
                          <TableCell
                            align='left'
                            sx={{
                              fontSize: '18px',
                              fontWeight: 600,
                              lineHeight: '22.5px',
                              color: '#262E35',
                              padding: '12px 24px',
                            }}
                            data-selenium-id={`Report_Articles_OPA-CETableGrid-TableBody-Cell9-${index}`}
                          /*className={
                            OASwitch
                              ? 'RightBorder ReportNumberTextColor ReportTotalTextSize'
                              : 'NoBorder ReportNumberTextColor ReportTotalTextSize'
                          }*/
                          >

                              {' '}
                              {row.oaPublishedArtYtdPercentage}

                          </TableCell>

                        </TableRow>
                      </>
                    );
                  }
                  return (
                    <TableRow
                      sx={{
                        height: '64px',
                        background: '#FFFFFF',
                        '&:hover':{
                          background: '#E8EDFB',
                        }
                      }}
                      onMouseEnter={() => {
                        setHovera(true);
                        setIndexsa(index);
                      }}
                      onMouseLeave={() => {
                        setHovera(false);
                        setIndexsa(index);
                      }}
                    >
                      <TableCell
                        data-selenium-id={`Report_Articles_OPA-CETableGrid-TableBody-Cell15-${index}`}
                        align='left'
                        sx={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 6,
                          background: hovera && indexsa === index ?'#E8EDFB':'#F1F3F5',
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                      >
                        {/*<DisplayTooltip
                          ifTotal={
                            row.handlingCeEmail == 'SubTotal'
                              ? ''
                              : row.catGroup
                          }
                        />*/}
                        {/*<SimpleTooltip title={row.catGroup}>
                            {row.catGroup}
                          </SimpleTooltip> */}
                          <div
                            style={{
                              textOverflow: 'ellipsis',
                              // width: '90px',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                          {row.catGroup}
                          </div>
                      </TableCell>
                      <TableCell
                        data-selenium-id={`Report_Articles_OPA-CETableGrid-TableBody-Cell16-${index}`}
                        align='left'
                        sx={{
                          position: 'sticky',
                          left: `${Math.max((columnWidthMap['catGroup']??0), columnMinWidthMap['catGroup']) + 50 }px`,
                          zIndex: 6,
                          background: hovera && indexsa === index ?'#E8EDFB':'#F1F3F5',
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}

                        //className='RightBorder'
                      >
                        {/*<TextLabel
                          text={
                            row.handlingCeEmail == 'SubTotal'
                              ? row.handlingCeEmail
                              : row.handlingCeFullName
                          }
                          TooltipText={
                            row.handlingCeEmail == 'SubTotal'
                              ? false
                              : row.handlingCeEmail
                          }
                        />*/}
                        {row.handlingCe}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles_OPA-CETableGrid-TableBody-Cell17-${index}`}
                      >
                        {row.totalPublishedArt}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='ReportNumberTextColor'
                      >
                        {row.oaPublishedArt}
                      </TableCell>
                      <TableCell
                        data-selenium-id={`Report_Articles_OPA-CETableGrid-TableBody-Cell18-${index}`}
                        align='left'
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        /*className={
                          OASwitch
                            ? 'RightBorder ReportNumberTextColor'
                            : 'NoBorder ReportNumberTextColor'
                        }*/
                      >
                        {' '}
                        {row.oaPublishedArtPercentage}
                      </TableCell>

                      <TableCell
                        data-selenium-id={`Report_Articles_OPA-CETableGrid-TableBody-Cell20-${index}`}
                        align='left'
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='ReportNumberTextColor'
                      >
                        {' '}
                        {row.totalPublishedArtYtd}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        //className='ReportNumberTextColor'
                        data-selenium-id={`Report_Articles_OPA-CETableGrid-TableBody-Cell21-${index}`}
                      >
                        {' '}
                        {row.oaPublishedArtYtd}
                      </TableCell>
                      <TableCell
                        align='left'
                        sx={{
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '0em',
                          color: '#262E35',
                          padding: '12px 24px',
                        }}
                        data-selenium-id={`Report_Articles_OPA-CETableGrid-TableBody-Cell22-${index}`}
                        /*className={
                          OASwitch
                            ? 'RightBorder ReportNumberTextColor'
                            : 'NoBorder ReportNumberTextColor'
                        }*/
                      >
                        {' '}
                        {row.oaPublishedArtYtdPercentage}
                      </TableCell>


                    </TableRow>
                  );
                })
                ): !isLoading && (
                  <ReportNoData id='AcquiredAndActiveSiAnnualTrendsBDNoResultTableCell' />
                )}
              {isLoading && (
                <Box
                  sx={{
                    width: 'auto',
                    marginTop: '150px',
                    marginLeft: '50%',
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TableBox2>
    </>
  );
}
// export default SubjectGroupTable

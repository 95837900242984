import React from 'react';

import { FormControl, Box } from '@mui/material';

import { BootstrapDatePicker } from '../../../../components/CreateAndUpdate/MyOperationModule';

import { MyFormHelperText } from '../../../../components/CreateAndUpdate/MyFormHelperText';
import { BootstrapTitle } from '../../../../components/CreateAndUpdate/MyTitle';
import ItemBlock from '../CGTComponents/ItemBlock';
import moment from 'moment';
import CustomDatePicker from '../CGTComponents/CustomDatePicker';
import { DatePicker } from '@/components/DateRangePicker';

export default function CreateSIDatePickerBlock(props) {
  const {
    id,
    title,
    required,
    placeholder,
    emptyErr,
    value,
    minDate,
    maxDate,
    defaultValue,
    handleChange,
  } = props;
  const [totalError, setTotalError] = React.useState(false);

  React.useEffect(() => {
    setTotalError(props.error || emptyErr);
  }, [props.error, emptyErr]);

  const getErrorText = () => {
    let helperText = '';
    if (props.error) {
      helperText = props.helperText;
    } else {
      helperText = 'This field is required.';
    }
    return helperText;
  };

  function codeValue(input) {
    if (input === null || input === undefined) {
      return '';
    }

    const date = new Date(input);

    if (isNaN(date.valueOf())) {
      return '';
    }

    return moment(date).utc().format('YYYY-MM-DD');
  }

  function decodeValue(input) {
    if (input === '' || input === null) {
      return null;
    }
    const [year, month, day] = input.split('-');
    let date = moment().utc().year(year).month(parseInt(month) - 1).date(day).hour(0).minute(0).second(0).millisecond(0).toDate();
    return date;
  }

  return (
    <ItemBlock
      id={id}
      title={title}
      required={required}
      isError={totalError}
      errorText={getErrorText()}
    >
      <CustomDatePicker
        id={id}
        placeholder={placeholder}
        value={codeValue(value)}
        onChange={newValue => {
          handleChange(decodeValue(newValue));
        }}
        maxDateStr={codeValue(maxDate)}
        minDateStr={codeValue(minDate)}
        error={totalError}
        defaultValue={codeValue(defaultValue)}
        handleFocus={()=>{
          setTotalError(false);
        }}
        handleBlur={()=>{
          setTotalError(props.error || emptyErr);
        }}
        needClear={true}
        needPadding={false}
      />
      {/* <DatePicker
        width='100%'
        minHeight='40px'
        placeholder={placeholder}
        value={codeValue(value)}
        minDate={minDate}
        maxDate={maxDate}
        handleDateChange={ds => {
          handleChange(decodeValue(ds));
        }}
      /> */}
    </ItemBlock>
  );
}

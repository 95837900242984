/**
 * @author LiuXin
 * @date 2022/3/5
 * @description 这个组件为描述组件，形式为key:value展示，冒号对齐，按照column方向向下排列
 * @param 参数传递为需要展示的对象数组
 */

import { Link, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';

function Description(props) {
  const { infoObj, isShelved, label } = props;
  const marginRight = label === 'gePageLeftList' ? '59px' : '0px';
  const normalValueColor = isShelved ? '#828282' : '#505050';
  const alertValueColor = '#A94442';
  // const keyStyle = {
  //   width: "40%",
  //   textAlign: "right",
  //   fontStyle: "normal",
  //   fontWeight: "600",
  //   fontSize: "16px",
  //   lineHeight: "24px",
  //   color: "#828282",
  //   marginRight: "20px",
  //   wordWrap: "break-word",
  // };

  const keyStyle = {
    width: '155px',
    height: '24px',
    textAlign: 'right',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#828282',
    flex: 'none',
    order: 0,
    alignSelf: 'stretch',
    flexGrow: 0,
    marginRight: '20px',
  };

  const normalValueStyle = {
    width: '100%',
    textAlign: 'left',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: normalValueColor,
    wordWrap: 'break-word',
  };
  const alertValueStyle = {
    width: '100%',
    textAlign: 'left',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: alertValueColor,
    wordWrap: 'break-word',
  };

  function judgeValueFormat(key, value) {
    // console.log(key,infoObj)
    if (key === 'Deadline Passed' && value === 'Yes') {
      return <Box sx={alertValueStyle}>{value}</Box>;
    }

    if (
      typeof key === 'string' &&
      (key.indexOf('website') !== -1 ||
        key.indexOf('Link') !== -1 ||
        key.indexOf('URL') !== -1)
    ) {
      var url = value;
      if (typeof value === 'string' && value.indexOf('http') === -1) {
        url = 'http://' + value;
      }
      return (
        <Link href={url} sx={normalValueStyle} color='inherit'>
          {value}
        </Link>
      );
    }
    return <Box sx={normalValueStyle}>{value}</Box>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 'calc((100% - 59px)/2)',
        marginRight: marginRight,
      }}
      style={{
        'overflow-x': 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {infoObj.map((item, index) => {
        return item.hasTop ? (
          <Tooltip
            title={
              item.Top !== null && item.Top !== undefined
                ? item.Top
                : judgeValueFormat(item.key, item.value)
            }
          >
            <Box
              id={`${label}${index}`}
              sx={{ width: '100%', display: 'flex', marginBottom: '16px' }}
            >
              <Box sx={keyStyle}>{item.key}:</Box>
              <Box sx={{ width: 'calc(100% - 175px)' }}>
                {judgeValueFormat(item.key, item.value)}
              </Box>
            </Box>
          </Tooltip>
        ) : (
          <Box
            id={`${label}${index}`}
            sx={{ width: '100%', display: 'flex', marginBottom: '16px' }}
          >
            <Box sx={keyStyle}>{item.key}:</Box>
            <Box sx={{ width: 'calc(100% - 169px)' }}>
              {judgeValueFormat(item.key, item.value)}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

export default Description;

const initial_review = 'initial review';
const Submitted = 'Submitted';
//sipStatus or sipStage
export default function isSubmittedOrInitialReview (status: string) {
  if (!status?.trim() || !(typeof status === 'string') || status?.trim()?.length < 2) {
    return false;
  }
  return (status.trim().charAt(0).toUpperCase() + status.trim().slice(1).toLowerCase()) === Submitted || status.trim().toLowerCase() === initial_review;
}
export const isSubmitted = (status: string) => {
  const trimStatus = status?.trim();
  if (!trimStatus || !(typeof status === 'string') || trimStatus?.length < 2) {
    return false;
  }
  return (trimStatus?.charAt(0)?.toUpperCase() + trimStatus?.slice(1)?.toLowerCase()) === Submitted;
};
export const isProceeded = (status: string) => {
  const trimStatus = status?.trim();
  if (!trimStatus || !(typeof status === 'string') || trimStatus?.length < 2) {
    return false;
  }
  return (trimStatus?.charAt(0)?.toUpperCase() + trimStatus?.slice(1)?.toLowerCase()) === 'Completed';
};

export const isArchived = (status: string) => {
  const trimStatus = status?.trim();
  if (!trimStatus || !(typeof status === 'string') || trimStatus?.length < 2) {
    return false;
  }
  return (trimStatus?.charAt(0)?.toUpperCase() + trimStatus?.slice(1)?.toLowerCase()) === 'Archived';
};

export function isInitialReview (status: string) {
  if (!status?.trim() || !(typeof status === 'string') || status?.trim()?.length < 2) {
    return false;
  }
  return status.trim().toLowerCase() === initial_review;
}
export const needToComfirmShow = (status: string) => {
  const trimStatus = status?.trim()?.toLowerCase();
  if (!trimStatus || !(typeof status === 'string') || trimStatus?.length < 2) {
    return false;
  }
  return trimStatus === 'jpm inquiry' || trimStatus === 'publisher inquiry' || trimStatus === 'editor review' || trimStatus.replaceAll(' ', '') === 'complete-accept' || trimStatus === 'mutual accept';
};
export const isWithdrawOrReject = (status: string) => {
  const trimStatus = status?.trim()?.toLowerCase();
  if (!trimStatus || !(typeof status === 'string') || trimStatus?.length < 2) {
    return false;
  }
  return trimStatus === 'withdraw' || trimStatus === 'reject';
};
export const isTransferBackToJPA = (status: string) => {
  const trimStatus = status?.trim()?.toLowerCase();
  if (!trimStatus || !(typeof status === 'string') || trimStatus?.length < 2) {
    return false;
  }
  return trimStatus === 'transfer%20back%20to%20jpa';
};
export const isCompleteAcquire = (status: string) => {
  if (!(Object.prototype.toString.call(status) === '[object String]' && status?.constructor === String && typeof status === 'string')) return false;
  const trimStatus = status?.trim()?.toLowerCase();
  if (!trimStatus || trimStatus?.length < 2) {
    return false;
  }
  return trimStatus === 'complete - acquire';
};